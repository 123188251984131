import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { Avatar, Button, Form } from 'components'

import {
  StyledBox,
  StyledButtonContainer,
  StyledFirstColumn,
  StyledNotesRow,
  StyledSecondColumn,
} from './ChildObservationStyled'

const ChildObservation = ({
  childObservation,
  handleSubmit,
  hasParentAppAccess,
  isMontessori,
  observationStatesDropdown,
  onChangeEvent,
  onLinkFramework,
  onNextSteps,
  onRemoveChild,
  onSubmit,
}) => {
  const {
    child,
    linkToFrameworks,
    linkToNextSteps,
    sharedWithParents,
  } = childObservation
  const { firstName, id, photo, surname } = child

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <StyledBox>
        <StyledFirstColumn>
          <Avatar
            avatarSize="medium"
            initials={[firstName, surname]}
            src={photo}
            title={`${firstName} ${surname}`}
          />
          {isMontessori && (
            <Field
              component={Form.Select}
              name="montessoriActivityState"
              options={observationStatesDropdown}
              onChange={onChangeEvent}
            />
          )}
          {hasParentAppAccess && (
            <Field
              component={Form.Checkbox}
              label="Share on parent app"
              name="sharedWithParents"
              value={sharedWithParents?.checked}
              onChange={onChangeEvent}
            />
          )}
        </StyledFirstColumn>
        <StyledSecondColumn>
          <StyledButtonContainer>
            <Button
              icon={linkToFrameworks ? 'check' : ''}
              label="Link to framework"
              size="small"
              onClick={() => onLinkFramework(childObservation)}
            />
            <Button
              icon={linkToNextSteps ? 'check' : ''}
              label="Set next steps"
              size="small"
              onClick={() => onNextSteps(childObservation)}
            />
            <Button
              hierarchy="tertiary"
              icon="close"
              size="small"
              onClick={() => onRemoveChild(null, id)}
            />
          </StyledButtonContainer>
          <StyledNotesRow>
            <Form.Row mobileFull>
              <Field
                component={Form.TextAreaField}
                maxCharacters={5000}
                maxHeight="500px"
                maxRows={20}
                name="comments"
                placeholder="Observation notes"
                autosize
                onBlur={onChangeEvent}
              />
            </Form.Row>
          </StyledNotesRow>
        </StyledSecondColumn>
      </StyledBox>
    </Form>
  )
}

export default reduxForm()(ChildObservation)
