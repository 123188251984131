import styled from 'styled-components'

export const StyledCreditNoteLink = styled.div`
  a {
    text-decoration: underline;
    color: inherit;
  }  
`
export const StyledDescriptionContainer = styled.div`
  font-size: 14px;
  color: #979797;
`
