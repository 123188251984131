import {
  CLEAR_AGED_DEBT_CHILDREN,
  LIST_AGED_DEBT_CHILDREN,
  LIST_AGED_DEBT_CHILDREN_FAILED,
  LIST_AGED_DEBT_CHILDREN_SUCCESS,
  SEND_AGED_DEBT_REPORT,
  SEND_AGED_DEBT_REPORT_FAILED,
  SEND_AGED_DEBT_REPORT_SUCCESS,
} from './constants'

export const list = (params, mergeResult, onSuccess) => ({ childApiClient }) => (dispatch) => {
  dispatch({ payload: null, type: LIST_AGED_DEBT_CHILDREN })

  childApiClient.list(params).then(
    (data) => {
      dispatch({
        mergeResult,
        payload: data,
        type: LIST_AGED_DEBT_CHILDREN_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: LIST_AGED_DEBT_CHILDREN_FAILED,
      })
    },
  )
}

export const clear = () => ({
  type: CLEAR_AGED_DEBT_CHILDREN,
})

export const sendReport = (params, onSuccess) => ({ agedDebtApiClient }) => (dispatch) => {
  dispatch({ params, type: SEND_AGED_DEBT_REPORT })

  agedDebtApiClient.sendReport(params).then(
    (data) => {
      dispatch({
        payload: data,
        type: SEND_AGED_DEBT_REPORT_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    () => dispatch({
      type: SEND_AGED_DEBT_REPORT_FAILED,
    }),
  )
}

