import i18n from 'translations'

export const CHILD_LEAVE_TYPES_COLOURS = {
  ABSENCE: '#EF6C8F',
  HOLIDAY: '#F7B944',
}

export const GENDER = {
  FEMALE: 'f',
  MALE: 'm',
  OTHER: 'o',
}

export const GENDER_DROPDOWN = [
  {
    label: i18n.t('module:Children:Constants:GenderOptions:maleLabel'),
    value: GENDER.MALE,
  },
  {
    label: i18n.t('module:Children:Constants:GenderOptions:femaleLabel'),
    value: GENDER.FEMALE,
  },
]

export const GENDER_DROPDOWN_WITH_OTHER = [
  {
    label: i18n.t('module:Children:Constants:GenderOptions:maleLabel'),
    value: GENDER.MALE,
  },
  {
    label: i18n.t('module:Children:Constants:GenderOptions:femaleLabel'),
    value: GENDER.FEMALE,
  },
  {
    label: i18n.t('module:Children:Constants:GenderOptions:otherLabel'),
    value: GENDER.OTHER,
  },
]

export const DIETARY_EATS_MEAT = 'EATS_MEAT'
export const DIETARY_NO_PREFERENCE = 'NO_PREFERENCE'
export const DIETARY_OTHER = 'OTHER'
export const DIETARY_VEGAN = 'VEGAN'
export const DIETARY_VEGETARIAN = 'VEGETARIAN'
export const DIETARY_PESCATARIAN = 'PESCATARIAN'

export const DIETARY_REQUIREMENTS_OPTIONS = [
  {
    label: i18n.t('module:Children:Constants:DietaryRequirementsOptions:noPreferenceLabel'),
    value: DIETARY_NO_PREFERENCE,
  },
  {
    label: i18n.t('module:Children:Constants:DietaryRequirementsOptions:pescatarianLabel'),
    value: DIETARY_PESCATARIAN,
  },
  {
    label: i18n.t('module:Children:Constants:DietaryRequirementsOptions:vegetarianLabel'),
    value: DIETARY_VEGETARIAN,
  },
  {
    label: i18n.t('module:Children:Constants:DietaryRequirementsOptions:veganLabel'),
    value: DIETARY_VEGAN,
  },
  {
    label: i18n.t('global:Other'),
    value: DIETARY_OTHER,
  },
]

export const DIETARY_COLORS = {
  ADDITIONAL_REQUIREMENTS: '#FFA600',
  ALLERGIES: '#ec1d24',
  DIETARY_NOT_SPECIFIED: '#9b9b9b',
  EATS_MEAT: '#36a9e0',
  VEGAN: '#b19cd9',
  VEGETARIAN: '#6FDD7C',
}

export const ALLERGY_TYPES = {
  HAS_ALLERGY: 'HAS_ALLERGY',
  NOT_SPECIFIED: 'NOT_SPECIFIED',
  NO_ALLERGY: 'NO_ALLERGY',
}

export const ALLERGY_OPTIONS = [
  {
    label: i18n.t('module:Children:Constants:DietaryAllergiesOptions:hasAllergyLabel'),
    value: ALLERGY_TYPES.HAS_ALLERGY,
  },
  {
    label: i18n.t('module:Children:Constants:DietaryAllergiesOptions:noAllergyLabel'),
    value: ALLERGY_TYPES.NO_ALLERGY,
  },
  {
    label: i18n.t('module:Children:Constants:DietaryAllergiesOptions:unknownAllergyLabel'),
    value: ALLERGY_TYPES.NOT_SPECIFIED,
  },
]

const MAX_AGE = 150

export const AGE_IN_MONTHS_OPTIONS = Array.from(new Array(MAX_AGE), (el, index) => ({
  label: index + 1,
  value: index + 1,
}))

export const CHILD_TYPES_OPTIONS = [
  {
    label: i18n.t('module:Children:Constants:ChildrenTypesOptions:currentChildrenLabel'),
    value: false,
  },
  {
    label: i18n.t('module:Children:Constants:ChildrenTypesOptions:archivedChildrenLabel'),
    value: true,
  },
]

export const CHILD_AGES_OPTIONS = [
  {
    ageRange: {
      from: 0,
      to: 23,
    },
    label: i18n.t('module:Children:Constants:ChildAgesOptions:under2'),
    value: 'under_2',
  },
  {
    ageRange: {
      from: 24,
      to: 35,
    },
    label: i18n.t('module:Children:Constants:ChildAgesOptions:2years'),
    value: '2_years',
  },
  {
    ageRange: {
      from: 36,
      to: 47,
    },
    label: i18n.t('module:Children:Constants:ChildAgesOptions:3years'),
    value: '3_years',
  },
  {
    ageRange: {
      from: 48,
      to: 59,
    },
    label: i18n.t('module:Children:Constants:ChildAgesOptions:4years'),
    value: '4_years',
  },
  {
    ageRange: {
      from: 60,
    },
    label: i18n.t('module:Children:Constants:ChildAgesOptions:5years'),
    value: '5_years',
  },
]

export const REPORT_TYPES = {
  LEAVERS: 'leavers',
  PHOTO_PERMISSIONS: 'photo_permissions',
  STARTERS: 'starters',
}

export const DRINKING_METHOD = {
  BOTTLE: 'bottle',
}

export const TOILET_TRAINED = {
  NAPPIES: 'nappies',
}

export const CHILD_PREVIEW_TYPES = {
  absence: {
    icon: 'absence',
    iconColor: CHILD_LEAVE_TYPES_COLOURS.ABSENCE,
    tooltip: i18n.t('global:Absent'),
  },
  extraSession: {
    icon: 'extra-session',
    iconColor: '#42ACE3',
    tooltip: i18n.t('global:ExtraSession'),
  },
  holiday: {
    icon: 'holiday',
    iconColor: CHILD_LEAVE_TYPES_COLOURS.HOLIDAY,
    tooltip: i18n.t('global:Holiday'),
  },
  session: {
    icon: 'check',
    iconColor: '#80C28F',
    tooltip: i18n.t('global:Session'),
  },
}

export const FAMILY_POSITIONS_OPTIONS = [
  {
    label: i18n.t('components:ChildForm:FamilyInfoForm:PositionInFamily:options:eldest'),
    value: 'Eldest',
  },
  {
    label: i18n.t('components:ChildForm:FamilyInfoForm:PositionInFamily:options:middle'),
    value: 'Middle',
  },
  {
    label: i18n.t('components:ChildForm:FamilyInfoForm:PositionInFamily:options:youngest'),
    value: 'Youngest',
  },
  {
    label: i18n.t('components:ChildForm:FamilyInfoForm:PositionInFamily:options:onlyChild'),
    value: 'Only child',
  },
  {
    label: i18n.t('components:ChildForm:FamilyInfoForm:PositionInFamily:options:twin'),
    value: 'Twin',
  },
  {
    label: i18n.t('components:ChildForm:FamilyInfoForm:PositionInFamily:options:multiple'),
    value: 'Multiple',
  },
]

export const TRAVEL_PERMISSIONS = {
  CAN_GO_TO_EXTERNAL_OUTGOINGS: 'canGoToExternalOutgoings',
  CAN_TAKE_THE_BUS: 'canTakeTheBus',
}

export const APPLY_PERMISSIONS = {
  FACE_PAINT: 'facePaint',
  NAPPY_CREAM: 'nappyCream',
  PATCHES: 'patches',
  SUN_CREAM: 'sunCream',
}

export const CHILD_CHANGELOG_SECTIONS = {
  DEVELOPMENT: 'development',
  ENROLMENT: 'enrolment',
  FAMILY: 'family',
  GENERAL: 'general',
  HEALTH: 'health',
  LIKES: 'likes',
  PERMISSIONS: 'permissions',
}

export const CHANGELOG_MADE_BY_OPTIONS_OPTIONS = [
  {
    label: i18n.t('module:Children:Child:Add:Development:title'),
    value: CHILD_CHANGELOG_SECTIONS.DEVELOPMENT,
  },
  {
    label: i18n.t('module:Children:Child:Add:EnrolmentDetails:title'),
    value: CHILD_CHANGELOG_SECTIONS.ENROLMENT,
  },
  {
    label: i18n.t('module:Children:Child:Add:Family:title'),
    value: CHILD_CHANGELOG_SECTIONS.FAMILY,
  },
  {
    label: i18n.t('module:Children:Child:Add:GeneralInformation:title'),
    value: CHILD_CHANGELOG_SECTIONS.GENERAL,
  },
  {
    label: i18n.t('module:Children:Child:Add:HealthAndDiet:title'),
    value: CHILD_CHANGELOG_SECTIONS.HEALTH,
  },
  {
    label: i18n.t('module:Children:Child:Add:LikesDislikes:title'),
    value: CHILD_CHANGELOG_SECTIONS.LIKES,
  },
  {
    label: i18n.t('module:Children:Child:Add:Permissions:title'),
    value: CHILD_CHANGELOG_SECTIONS.PERMISSIONS,
  },
]

export const CHANGELOG_MADE_BY = {
  NURSERY: 'member',
  PARENT: 'parent',
}

export const CHANGELOG_MADE_BY_OPTIONS = [
  {
    label: i18n.t('services:Child:changelogs:madeByOptions:nursery'),
    value: CHANGELOG_MADE_BY.NURSERY,
  },
  {
    label: i18n.t('services:Child:changelogs:madeByOptions:parents'),
    value: CHANGELOG_MADE_BY.PARENT,
  },
]

export const TYPE_CONTACT_FORM = {
  EXISTING: 'EXISTING',
  NEW: 'NEW',
}

export const TYPE_CONTACT_FORM_OPTIONS = [
  {
    label: i18n.t('module:Children:Child:Contacts:Form:existingContact'),
    value: TYPE_CONTACT_FORM.EXISTING,
  },
  {
    label: i18n.t('module:Children:Child:Contacts:Form:newContact'),
    value: TYPE_CONTACT_FORM.NEW,
  },
]
