export const GET_NURSERY_SESSION = 'NURSERY_SESSIONS/GET_NURSERY_SESSION'
export const GET_NURSERY_SESSION_SUCCESS = 'NURSERY_SESSIONS/GET_NURSERY_SESSION_SUCCESS'
export const GET_NURSERY_SESSION_FAILED = 'NURSERY_SESSIONS/GET_NURSERY_SESSION_FAILED'

export const CREATE_NURSERY_SESSION = 'NURSERY_SESSIONS/CREATE_NURSERY_SESSION'
export const CREATE_NURSERY_SESSION_SUCCESS = 'NURSERY_SESSIONS/CREATE_NURSERY_SESSION_SUCCESS'
export const CREATE_NURSERY_SESSION_FAILED = 'NURSERY_SESSIONS/CREATE_NURSERY_SESSION_FAILED'

export const UPDATE_NURSERY_SESSION = 'NURSERY_SESSIONS/UPDATE_NURSERY_SESSION'
export const UPDATE_NURSERY_SESSION_SUCCESS = 'NURSERY_SESSIONS/UPDATE_NURSERY_SESSION_SUCCESS'
export const UPDATE_NURSERY_SESSION_FAILED = 'NURSERY_SESSIONS/UPDATE_NURSERY_SESSION_FAILED'

export const ARCHIVE_NURSERY_SESSION = 'NURSERY_SESSIONS/ARCHIVE_NURSERY_SESSION'
export const ARCHIVE_NURSERY_SESSION_SUCCESS = 'NURSERY_SESSIONS/ARCHIVE_NURSERY_SESSION_SUCCESS'
export const ARCHIVE_NURSERY_SESSION_FAILED = 'NURSERY_SESSIONS/ARCHIVE_NURSERY_SESSION_FAILED'

export const CLEAR_NURSERY_SESSION = 'NURSERY_SESSIONS/CLEAR_NURSERY_SESSION'
