import React, { useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'

import { ITEM_STATUS_FILTERS } from 'services/product/nurseryItem/constants'

import { typeByObject } from 'utils/typescript'

import { withAppService, withAppServiceProps } from 'services/app'
import { withShellService, withShellServiceProps } from 'services/shell'
import { withNurseryItemService, withNurseryItemServiceProps } from 'services/product/nurseryItem'
import { withPaginationUtils, withPaginationUtilsProps } from 'services/utils/pagination'
import { withRouteUtilsProps, withRouterUtils } from 'services/utils/router'
import { withRouter, withRouterProps } from 'services/router'

import i18n from 'translations'

import ManagementItemsView from './ManagementItemsView'

type ManagementItemsContainerProps = withAppServiceProps
  & withNurseryItemServiceProps
  & withPaginationUtilsProps
  & withRouteUtilsProps
  & withShellServiceProps
  & withRouterProps

const mapState = (state, {
  appSelectors,
  nurseryItemListState,
  nurseryItemSelectors,
}) => ({
  errorMessages: appSelectors.getErrorMessages(nurseryItemListState),
  isFetching: appSelectors.getIsFetching(nurseryItemListState),
  records: nurseryItemSelectors.getNurseryItemListDataState(state),
  totalResults: appSelectors.getTotalResults(nurseryItemListState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const NURSERY_FUNDING_GROUPS = {
  read: [
    'productPrice',
    'productPriceChange',
    'productPriceChange.prices',
    'nurseryItemProduct',
    'nurseryItemProduct.priceChanges',
  ],
}

export interface ManagementItemsFilters {
  status?: typeByObject<typeof ITEM_STATUS_FILTERS>
}

const ManagementItemsContainer: React.FC<ManagementItemsContainerProps & PropsFromRedux> = ({
  errorMessages,
  isFetching,
  location,
  nurseryItemActions,
  nurseryItemSelectors,
  paginationUtils,
  records,
  setLocationQuery,
  shellActions,
  totalResults,
}) => {
  const { getPaginationDetails, onPageChange, page } = paginationUtils
  const { query } = location

  const [filters, setFilters] = useState<ManagementItemsFilters>({
    status: query?.status || ITEM_STATUS_FILTERS.ACTIVE,
  })

  const fetch = () => {
    const criteria = nurseryItemSelectors.getCriteria(filters)

    setLocationQuery({ page, ...filters })

    const params = {
      criteria,
      groups: NURSERY_FUNDING_GROUPS,
      page,
    }

    nurseryItemActions.list({
      params,
    })
  }

  useEffect(() => {
    shellActions.setRouteTitle({
      name: 'MANAGEMENT.FINANCE_SETTINGS.ITEMS',
      title: i18n.t('module:Management:Finance:Item:title'),
    })

    return () => {
      nurseryItemActions.clearList()
    }
  }, [])

  useEffect(fetch, [page, filters])

  const handleFiltersChange = (filterName: keyof ManagementItemsFilters) => (filterValue) => {
    onPageChange()(1)
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: filterValue,
    }))
  }

  return (
    <ManagementItemsView
      errorMessages={errorMessages}
      filters={filters}
      isFetching={isFetching}
      pagination={getPaginationDetails(totalResults)}
      records={records}
      onFilterChange={handleFiltersChange}
    />
  )
}

const enhance = compose(
  withAppService,
  withNurseryItemService,
  withPaginationUtils,
  withRouter,
  withRouterUtils,
  withShellService,
  connector,
)

export default enhance(ManagementItemsContainer)
