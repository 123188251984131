import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { BasicViewProps, Option } from 'constants/models'
import { LIST_STATUS_FILTERS_DROPDOWN } from 'constants/filters'
import { PaginationProps } from 'components/Pagination/Pagination'
import { ONE_OFF_BOOKING_TYPE_OPTIONS } from 'services/booking/childProducts/constants'

import { generateRoute } from 'utils/routing'

import { STATUSES_DROPDOWN } from 'services/legacy/invoices/constants'

import {
  Button,
  Callout,
  DatePicker,
  EmptyState,
  Form,
  Page,
  Pagination,
  SearchBar,
  Section,
  Select,
  Spinner,
  Table,
  Toolbar,
} from 'components'
import { ValueType } from 'components/Select/Select'

import i18n from 'translations'

import { TABLE_COLUMNS } from './constants'
import { TableDataProps } from './ListHelpers'
import { StatusOptionType, TypeOption } from './ListContainer'

interface ListViewProps extends BasicViewProps, PaginationProps {
  childId?: number
  childProducts: TableDataProps[]
  dateRange: string[]
  invoiceStatus: Option
  onDateRangeChange: (dateRange: moment.Moment[]) => void
  onInvoiceStatusChange: (invoiceStatus: Option) => void
  onSearchChange: (value: string) => void
  onStatusChange: (status: StatusOptionType) => void
  onTypeChange: (type: TypeOption) => void
  search?: string
  status: StatusOptionType
  type: TypeOption
}

const ListView: React.FC<ListViewProps> = ({
  childId,
  childProducts,
  dateRange,
  errorMessages,
  invoiceStatus,
  isLoading,
  onDateRangeChange,
  onInvoiceStatusChange,
  onPageChange,
  onSearchChange,
  onStatusChange,
  onTypeChange,
  page,
  pageCount,
  perPage,
  search,
  status,
  totalResults,
  type,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!childProducts?.length) {
      return (
        <EmptyState
          actions={(
            <Button
              icon="plus"
              label={i18n.t('module:Finance:OneOffBookings:EmptyState:addButtonLabel')}
              to={
                childId
                  ? generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.ONE_OFF_BOOKINGS.ADD', { childId })
                  : generateRoute('FINANCE.ONE_OFF_BOOKINGS.ADD')
              }
            />
          )}
          icon="sessions"
          text1={i18n.t('module:Finance:OneOffBookings:EmptyState:text1')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={childId ? _.filter(TABLE_COLUMNS, ({ field }) => 'child' !== field) : TABLE_COLUMNS}
          data={childProducts}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {!childId && (
        <SearchBar
          placeholder={i18n.t('module:Finance:OneOffBookings:Filters:Search:label')}
          value={search}
          onChange={onSearchChange}
        />
      )}
      <Page.Section
        actions={(
          <Section.Actions primary={[{ to: childId
            ? generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.ONE_OFF_BOOKINGS.ADD', { childId })
            : generateRoute('FINANCE.ONE_OFF_BOOKINGS.ADD') }]}
          />
      )}
        title={i18n.t('module:Finance:OneOffBookings:title')}
      >
        <Callout content={errorMessages} error />
        <Toolbar>
          <Toolbar.Group>
            <Toolbar.Item>
              <Form.Row
                label={i18n.t('module:Finance:OneOffBookings:Filters:Date:label')}
                verticalLabel
              >
                <DatePicker
                  value={dateRange}
                  clearable
                  range
                  onChange={onDateRangeChange}
                />
              </Form.Row>
            </Toolbar.Item>
            <Toolbar.Item>
              <Form.Row
                label={i18n.t('global:Type')}
                verticalLabel
              >
                <Select<ValueType<TypeOption>>
                  options={ONE_OFF_BOOKING_TYPE_OPTIONS}
                  value={type}
                  clearable
                  onChange={onTypeChange}
                />
              </Form.Row>
            </Toolbar.Item>
            <Toolbar.Item>
              <Form.Row
                label={i18n.t('module:Finance:OneOffBookings:Filters:Status:label')}
                verticalLabel
              >
                <Select<ValueType<StatusOptionType>>
                  options={LIST_STATUS_FILTERS_DROPDOWN}
                  value={status}
                  onChange={onStatusChange}
                />
              </Form.Row>
            </Toolbar.Item>
            <Toolbar.Item>
              <Form.Row
                label={i18n.t('module:Finance:OneOffBookings:Filters:InvoiceStatus:label')}
                verticalLabel
              >
                <Select<ValueType>
                  options={STATUSES_DROPDOWN}
                  value={invoiceStatus}
                  clearable
                  onChange={onInvoiceStatusChange}
                />
              </Form.Row>
            </Toolbar.Item>
          </Toolbar.Group>
        </Toolbar>
        {renderContent()}
      </Page.Section>
    </React.Fragment>
  )
}

export default ListView
