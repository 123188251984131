import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { DEFAULT_DATE_FORMAT, DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT } from 'constants/date'
import { ABSENCE_TYPE } from 'module/Register/constants'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withChildRegistersService } from 'services/legacy/childRegisters'
import { withChildAbsencesService } from 'services/legacy/childAbsences'
import { withModalService } from 'services/utils/modal'
import { withRegisterService } from 'services/legacy/register'
import { withStaffLogsService } from 'services/legacy/staffLogs'
import { withRouterUtils } from 'services/utils/router'
import { withRouter } from 'services/router'

import i18n from 'translations'

import { getPayload, getStaffLogs, getUpdatedSignIns } from 'module/Register/helpers'

import * as helpers from './helpers'

import ChildAttendanceView from './ChildAttendanceView'

const STAFF_LOGS_GROUPS = {
  read: [
    'staffLog.childRegister',
    'staffLog.staff',
    'user',
    'user.details',
  ],
}
const TYPE_OPTIONS = [{ label: 'Present', value: 1 }, { label: 'Absent', value: 0 }]

class ChildAttendanceContainer extends Component {
  constructor(props) {
    super(props)

    const { location } = props
    const { query } = location
    const { from, to, type } = query

    const monthBeforeToday = moment(new Date())
      .subtract(1, 'months')
      .toDate()

    const today = new Date()
    const dateRangeFrom = from ? moment(from, DEFAULT_DATE_FORMAT).toDate() : monthBeforeToday
    const dateRangeTo = to ? moment(to, DEFAULT_DATE_FORMAT).toDate() : today

    this.state = {
      filters: {
        dateRange: [dateRangeFrom, dateRangeTo],
        type: type ? _.find(TYPE_OPTIONS, ({ value }) => value === parseInt(type)) : null,
      },
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    this.clear()
  }

  fetch = () => {
    const { params, registerActions } = this.props
    const { filters } = this.state
    const { childId } = params

    const criteria = helpers.getCriteria(filters)
    const listParams = {
      criteria,
      includes: ['sessionsAttended'],
      order: 'entryDate DESC',
    }

    registerActions.listByChild(childId, listParams)
  }

  clear = () => {
    const { registerActions } = this.props

    registerActions.clear()
  }

  setFiltersSuccess = () => {
    const { setLocationQuery } = this.props
    const { filters } = this.state
    const { dateRange, type } = filters

    this.fetch()

    setLocationQuery({
      from: dateRange[0] ? moment(dateRange[0]).format(DEFAULT_DATE_FORMAT) : null,
      to: dateRange[1] ? moment(dateRange[1]).format(DEFAULT_DATE_FORMAT) : null,
      type: type ? type.value : null,
    })
  }

  setFilters = (filters) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          ...filters,
        },
      }),
      this.setFiltersSuccess,
    )
  }

  saveRegisterItem = (childId, id, newValues, cb) => {
    const { registerActions, registerState } = this.props

    const register = registerState.data
    const payload = getPayload(register, childId, id, newValues)

    if (id) {
      return registerActions.update(childId, id, payload, cb, true)
    }

    return registerActions.create(childId, payload, cb)
  }

  handleGetStaffLogsSuccess = (child, absence) => (staffLogs) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.REGISTER_STAFF_LOG, {
      absence,
      child,
      staffLogs: staffLogs ? getStaffLogs(staffLogs.data) : null,
    })
  }

  handleDateRangeChange = (dateRange) => {
    this.setFilters({ dateRange })
  }

  handleTypeChange = (type) => {
    this.setFilters({ type })
  }

  handleItemSignInClick = (childId, id) => {
    const { registerState } = this.props

    const newSignInItem = {
      signedInAt: new Date(),
    }

    const signIns = getUpdatedSignIns(registerState.data, childId, id, newSignInItem, true)

    const newValues = {
      present: true,
      signIns,
    }

    this.saveRegisterItem(childId, id, newValues)
  }

  handleItemSignOutClick = (childId, id, signInItem) => {
    const { registerState } = this.props

    const newSignInItem = {
      id: signInItem.id,
      signedInAt: new Date(signInItem.signedInAt),
      signedOutAt: new Date(),
    }

    const signIns = getUpdatedSignIns(registerState.data, childId, id, newSignInItem, true)

    const newValues = {
      present: true,
      signIns,
    }

    this.saveRegisterItem(childId, id, newValues)
  }

  handleItemSaveClick = (childId, id, newValues, cb) => {
    this.saveRegisterItem(childId, id, newValues, cb)
  }

  handleItemPanelSaveClick = (childId, id, newValues, cb) => {
    this.saveRegisterItem(childId, id, newValues, cb)
  }

  handleItemStaffLogClick = (id, child, absence) => {
    const { modalActions, modalConsts, staffLogsActions, staffLogsSelectors } = this.props

    modalActions.show(modalConsts.TYPES.REGISTER_STAFF_LOG, { isLoading: true })

    const criteria = staffLogsSelectors.getListCriteria({ registerId: id })

    staffLogsActions.list({
      onSuccess: this.handleGetStaffLogsSuccess(child, absence),
      params: {
        criteria,
        groups: STAFF_LOGS_GROUPS,
      },
    })
  }

  handleItemResetConfirmClick = (childId, id) => {
    const { childRegistersActions } = this.props

    childRegistersActions.update({
      body: { present: null },
      onSuccess: this.fetch,
      params: [id, {}],
    })
  }

  handleItemResetClick = (child, id) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.REGISTER_RESET, {
      child,
      onConfirm: () => this.handleItemResetConfirmClick(child.id, id),
    })
  }

  handleItemDeleteConfirmClick = (childId, absenceId) => {
    const { childAbsencesActions } = this.props

    childAbsencesActions.remove({
      onSuccess: this.fetch,
      params: [absenceId],
    })
  }

  handleItemAbsenceDeleteClick = (child, absence) => {
    const { modalActions, modalConsts } = this.props
    const { endDate, startDate } = absence

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('global:Delete'),
      onConfirm: () => this.handleItemDeleteConfirmClick(child.id, absence.id),
      text: i18n.t('module:Children:Child:About:Absences:DeleteLeave:Confirm:dateRange', {
        endDate: moment(endDate).format(DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT),
        firstName: child.firstName,
        startDate: moment(startDate).format(DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT),
        type: ABSENCE_TYPE.ABSENCE === absence.absenceType ? i18n.t('global:Absence') : i18n.t('global:Holiday'),
      }),
    })
  }

  handleItemAbsenceEditClick = (child, absenceId) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CHILD_LEAVE, {
      child,
      id: absenceId,
      isEdit: true,
      onSuccess: this.fetch,
    }, {
      enableMultipleModal: true,
    })
  }

  handleItemAbsenceClick = (child, date) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CHILD_LEAVE, {
      child,
      date,
      isChildContext: true,
      onSuccess: this.fetch,
    }, {
      enableMultipleModal: true,
    })
  }

  render() {
    const { child, errorMessages, isFetching, registerState } = this.props
    const { filters } = this.state

    const register = registerState.data
    const isLoading = isFetching || !register || !child
    const isEmpty = registerState.isEmpty

    return (
      <ChildAttendanceView
        child={child}
        errorMessages={errorMessages}
        filters={filters}
        isEmpty={isEmpty}
        isLoading={isLoading}
        register={register}
        typeOptions={TYPE_OPTIONS}
        onDateRangeChange={this.handleDateRangeChange}
        onItemAbsenceClick={this.handleItemAbsenceClick}
        onItemAbsenceDeleteClick={this.handleItemAbsenceDeleteClick}
        onItemAbsenceEditClick={this.handleItemAbsenceEditClick}
        onItemPanelSaveClick={this.handleItemPanelSaveClick}
        onItemResetClick={this.handleItemResetClick}
        onItemSaveClick={this.handleItemSaveClick}
        onItemSignInClick={this.handleItemSignInClick}
        onItemSignOutClick={this.handleItemSignOutClick}
        onItemStaffLogClick={this.handleItemStaffLogClick}
        onTypeChange={this.handleTypeChange}
      />
    )
  }
}

const mapState = (state, { appSelectors, childSelectors, childSingleState, registerState }) => ({
  child: childSelectors.getChildSelector(state),
  errorMessages: appSelectors.getErrorMessages(registerState, childSingleState),
  isFetching: appSelectors.getIsFetching(registerState, childSingleState),
})

const enhance = compose(
  withAppService,
  withChildService,
  withChildRegistersService,
  withChildAbsencesService,
  withModalService,
  withRegisterService,
  withStaffLogsService,
  withRouterUtils,
  connect(mapState),
  withRouter,
)

export default enhance(ChildAttendanceContainer)
