import i18n from 'translations'

import DraftInvoiceWidgetContainer from './DraftInvoiceWidgetContainer'

export const WIDGET_NAME = {
  component: DraftInvoiceWidgetContainer,
  id: 'DRAFT_INVOICE_WIDGET',
  label: i18n.t('module:Dashboard:Widgets:DraftInvoiceWidget:titleInSettings'),
}

export default DraftInvoiceWidgetContainer
