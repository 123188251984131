import _ from 'lodash'

import { Request } from 'constants/http'

import { isPlainObject } from 'utils/data'

const collectionRegex = /^(\S*)(\.)(\d)(\.)(\S*)$/
const isCollectionString = (str) => collectionRegex.test(str)

const normalizeCollectionString = (object) => {
  if (isCollectionString(object)) {
    return object.replace(collectionRegex, (match, p1, p2, p3, p4, p5) => [p1, '[', p3, '].', p5].join(''))
  }

  if (isPlainObject(object)) {
    return Object.keys(object).reduce((copy, key) => {
      const value = object[key]
      const newCopy = { ...copy }

      if (isPlainObject(value)) {
        newCopy[normalizeCollectionString(key)] = normalizeCollectionString(value)
      }

      if (_.isString(value) || _.isNumber(value) || _.isArray(value)) {
        newCopy[normalizeCollectionString(key)] = value
      }

      return newCopy
    }, {})
  }

  if (Array.isArray(object)) {
    return object.map(normalizeCollectionString)
  }

  return object
}

const NormalizeCollectionErrorsMiddleware = async (request: Request, next) => {
  const response = await next(request)

  if (response.ok) {
    return response
  }

  const json = response.json

  // eslint-disable-next-line func-names
  response.json = async function () {
    return normalizeCollectionString(await json.call(this))
  }

  return response
}

export default NormalizeCollectionErrorsMiddleware

