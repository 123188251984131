import React from 'react'

import { Callout, Form, ModalBox, Spinner } from 'components'

import i18n from 'translations'

const EnrolChildModalView = ({
  enquiry,
  errorMessages,
  isFetching,
  isSubmitting,
  onCancelClick,
  onEnrolChildClick,
  onMarkAsEnrolledClick,
}) => {
  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    const { childName } = enquiry

    return (
      <React.Fragment>
        {i18n.t('module:Modals:Enquiry:EnrolChild:enrolMessage', { childName })}
        <Form.FooterActions
          secondary={{
            isLoading: isSubmitting,
            label: i18n.t('module:Modals:Enquiry:EnrolChild:markAsEnrolledLabel'),
            onClick: onMarkAsEnrolledClick,
          }}
          submitLabel={i18n.t('module:Modals:Enquiry:EnrolChild:enrolLabel')}
          onCancelClick={onCancelClick}
          onSubmitClick={onEnrolChildClick}
        />
      </React.Fragment>
    )
  }

  return (
    <ModalBox
      maxWidth={650}
      title={i18n.t('module:Modals:Enquiry:EnrolChild:title')}
      autoHeight
      onCloseClick={onCancelClick}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </ModalBox>
  )
}

export default EnrolChildModalView
