import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

interface StyledContainerProps {
  $autoHeight?: boolean
  $iosPolyfill?: boolean
  $maxWidth?: number
}

export const StyledContainer = styled.div<StyledContainerProps>`
  flex: 1;
  min-height: ${({ $autoHeight }) => ($autoHeight ? 'min-content' : ' 400px')};
  max-height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  
  ${({ $maxWidth }) => $maxWidth && `
    max-width: ${$maxWidth}px;
  `}
  
  @media(max-width: 820px) {
    max-width: calc(100vw - 20px);
  }
  
  ${({ $iosPolyfill }) => $iosPolyfill && `
    /* stylelint-disable */
    min-height: -webkit-fill-available;
    /* stylelint-enable */
  `}
`

export const StyledHeaderContainer = styled.header`
  background: ${getBrandingColor('primary-color')};
  padding: 10px 20px;
  border-top: 2px solid ${getBrandingColor('primary-color')};
  border-right: 2px solid ${getBrandingColor('primary-color')};
  border-left: 2px solid ${getBrandingColor('primary-color')};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${NEUTRAL_COLOURS.WHITE};
  align-items: center;
`

export const StyledHeaderTitle = styled.h3`
  line-height: 1;
  margin: 0;
  font-size: 24px;
  font-weight: 600;
`

interface StyledContentProps {
  $iosPolyfill?: boolean
  $noHeader?: boolean
  $scrollToBody?: boolean
}

export const StyledContent = styled.div<StyledContentProps>`
  background: ${NEUTRAL_COLOURS.WHITE};
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid ${getBrandingColor('primary-color')};
  border-right: 2px solid ${getBrandingColor('primary-color')};
  border-left: 2px solid ${getBrandingColor('primary-color')};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  
  ${({ $iosPolyfill }) => $iosPolyfill && `
    display: block;
  `}

  ${({ $scrollToBody }) => !$scrollToBody && `
    overflow: auto;
  `}

  ${({ $noHeader }) => $noHeader && `
    border-radius: 20px;
    border-top: 2px solid ${getBrandingColor('primary-color')};
  `}
  
  @media(max-width: 480px) {
    padding: 10px;
  }
`

export const StyledScrollContent = styled.div`
  overflow: auto;
  max-height: 70vh;
  padding: 10px;
  margin: -10px;
`
