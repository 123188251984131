import _ from 'lodash'

import i18n from 'translations'

export const NURSERY_TABLE_COLUMNS = [
  {
    align: 'left',
    field: 'child',
    title: _.upperFirst(i18n.t('global:child')),
    width: '200px',
  },
  {
    field: 'number',
    title: i18n.t('module:Finance:Reports:BadDebt:Table:Header:invoiceNumber'),
  },
  {
    field: 'total',
    title: i18n.t('module:Finance:Reports:BadDebt:Table:Header:invoiceAmount'),
  },
  {
    field: 'badDebtAmount',
    title: i18n.t('module:Finance:Reports:BadDebt:Table:Header:badDebtAmount'),
  },
  {
    field: 'issueDate',
    title: i18n.t('module:Finance:Reports:BadDebt:Table:Header:invoiceDate'),
  },
  {
    field: 'badDebtDate',
    title: i18n.t('module:Finance:Reports:BadDebt:Table:Header:dateMarked'),
  },
  {
    field: 'badDebtReason',
    title: i18n.t('module:Finance:Reports:BadDebt:Table:Header:reason'),
  },
]

export const ORGANIZATION_TABLE_COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'name',
    title: _.upperFirst(i18n.t('global:site')),
  },
  {
    field: 'count',
    title: i18n.t('module:Finance:Reports:BadDebt:Organization:Table:Header:invoices'),
  },
  {
    field: 'amount',
    title: i18n.t('module:Finance:Reports:BadDebt:Organization:Table:Header:badDebtAmount'),
  },
]

export const DATE_TYPE_OPTIONS = [
  {
    label: i18n.t('module:Finance:Reports:BadDebt:DateTypeOptions:dateMarked'),
    value: 'badDebtDate',
  },
  {
    label: i18n.t('module:Finance:Reports:BadDebt:DateTypeOptions:invoiceDate'),
    value: 'issueDate',
  },
]
