import moment from 'moment'
import _ from 'lodash'

import React from 'react'

import { DIETARY_COLORS } from 'services/legacy/child/constants'
import { INJURIES } from 'services/legacy/injuries/constants'

import { getAllergyType } from 'services/legacy/child/helpers'

import { CircleIcon, DateString, DefinitionList, GridLayout, MediaPicker, SvgInteractive, Typography } from 'components'

import i18n from 'translations'

import image from 'assets/images/body-map.svg'

const ChildDetails = ({
  data: {
    accidentDetails,
    child,
    description,
    firstAider,
    media,
    otherFirstAider,
    riskAssessment,
  },
  injuryPreview: {
    date,
    location,
    type,
  },
  showInjuryPreviewDetail,
}) => {
  const { birthDate, information, profile } = child
  const { medicalInformation } = profile
  const { allergies, allergiesDetails } = information
  const { bodyMapCoordinates, injuryDescription, injuryType, treatment } = accidentDetails || { }

  return (
    <div>
      <GridLayout>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item column>
            <DefinitionList
              margin="0 0 30px 0"
              vertical
            >
              <DefinitionList.Item
                label={i18n.t('global:Child:DOB')}
                value={(
                  <DateString date={birthDate} />
                )}
              />
            </DefinitionList>
          </GridLayout.Item>
          <GridLayout.Item column>
            <DefinitionList
              margin="0 0 30px 0"
              vertical
            >
              <DefinitionList.Item
                label={_.upperFirst(i18n.t('global:room'))}
                value={(child.class && child.class.name) || (child.nurseryClass && child.nurseryClass.name) || '-'}
              />
            </DefinitionList>
          </GridLayout.Item>
          <GridLayout.Item
            padding="0px 15px"
            column
          >
            <DefinitionList
              margin="0 0 30px 0"
              vertical
            >
              <DefinitionList.Item
                label={_.upperFirst(i18n.t('global:medicationInformation'))}
                value={medicalInformation || '-'}
              />
            </DefinitionList>
          </GridLayout.Item>
          <GridLayout.Item
            padding="0px 15px"
            column
          >
            <DefinitionList
              margin="0 0 30px 0"
              vertical
            >
              <DefinitionList.Item
                label={(
                  <CircleIcon
                    background={DIETARY_COLORS.allergies}
                    icon="allergies"
                    label={_.upperFirst(i18n.t('global:allergies'))}
                    size={20}
                  />
                )}
                value={getAllergyType(allergies, allergiesDetails)}
              />
            </DefinitionList>
          </GridLayout.Item>
        </GridLayout.Group>
      </GridLayout>
      {showInjuryPreviewDetail && (
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item column>
            <DefinitionList
              margin="0 0 30px 0"
              vertical
            >
              <DefinitionList.Item
                label={_.upperFirst(i18n.t('global:location'))}
                value={location || '-'}
                whiteSpace="pre-wrap"
                wordBreak="break-word"
              />
            </DefinitionList>
          </GridLayout.Item>
          <GridLayout.Item column>
            <DefinitionList
              margin="0 0 30px 0"
              vertical
            >
              <DefinitionList.Item
                label={i18n.t('module:Injury:Preview:Details:dateLabel', { type: type.toLowerCase() })}
                value={date ? moment(date).format('DD/MM/YYYY') : '-'}
              />
            </DefinitionList>
          </GridLayout.Item>
          <GridLayout.Item column>
            <DefinitionList
              margin="0 0 30px 0"
              vertical
            >
              <DefinitionList.Item
                label={i18n.t('module:Injury:Preview:Details:timeLabel', { type: type.toLowerCase() })}
                value={date ? moment(date).format('HH:mm') : '-'}
              />
            </DefinitionList>
          </GridLayout.Item>
          <GridLayout.Item />
        </GridLayout.Group>
      )}
      <GridLayout>
        <GridLayout.Group>
          <DefinitionList margin="10px 0 0 0" vertical>
            <DefinitionList.Item
              label={(
                <Typography fontSize="20" bold>
                  {type === INJURIES.INCIDENT
                    ? i18n.t('module:Injury:Preview:Details:incidentDetailsLabel')
                    : i18n.t('module:Injury:Preview:Details:accidentDetailsLabel')}
                </Typography>
              )}
            />
          </DefinitionList>
        </GridLayout.Group>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item>
            <DefinitionList margin="0 0 10px 0">
              <DefinitionList.Item
                label={i18n.t('global:Description')}
                value={description || '-'}
                whiteSpace="pre-wrap"
                wordBreak="break-word"
              />
            </DefinitionList>
          </GridLayout.Item>
        </GridLayout.Group>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item>
            <DefinitionList margin="0 0 30px 0">
              <DefinitionList.Item
                label={i18n.t('module:Injury:Preview:Details:riskAssessmentLabel')}
                value={riskAssessment || '-'}
                whiteSpace="pre-wrap"
                wordBreak="break-word"
              />
            </DefinitionList>
          </GridLayout.Item>
        </GridLayout.Group>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item>
            <DefinitionList margin="10px 0 0 0">
              <DefinitionList.Item
                label={(
                  <Typography fontSize="20" bold>
                    {i18n.t('module:Safeguarding:Fields:InjuryDetails:label')}
                  </Typography>
                )}
              />
            </DefinitionList>
          </GridLayout.Item>
        </GridLayout.Group>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item>
            <DefinitionList margin="0 0 10px 0">
              <DefinitionList.Item
                label={i18n.t('module:Safeguarding:Fields:Injury:label')}
                value={_.upperFirst(injuryType) || '-'}
                whiteSpace="normal"
              />
            </DefinitionList>
          </GridLayout.Item>
        </GridLayout.Group>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item>
            <DefinitionList margin="0 0 10px 0">
              <DefinitionList.Item
                label={i18n.t('module:Safeguarding:Fields:InjuryDescription:label')}
                value={injuryDescription || '-'}
                whiteSpace="pre-wrap"
                wordBreak="break-word"
              />
            </DefinitionList>
          </GridLayout.Item>
        </GridLayout.Group>
        {bodyMapCoordinates?.length ? (
          <React.Fragment>
            <GridLayout.Group noVerticalMargin>
              <GridLayout.Item>
                <DefinitionList vertical>
                  <DefinitionList.Item label={i18n.t('module:Safeguarding:Fields:BodyMap:label')} />
                </DefinitionList>
              </GridLayout.Item>
              <GridLayout.Item />
            </GridLayout.Group>
            <div style={{ cursor: 'not-allowed' }}>
              <GridLayout.Group noVerticalMargin>
                <GridLayout.Item>
                  <SvgInteractive
                    defaultValues={bodyMapCoordinates}
                    image={image}
                    svgViewBox="0 0 924 535"
                    readOnly
                  />
                </GridLayout.Item>
              </GridLayout.Group>
            </div>
          </React.Fragment>
        ) : null }
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item>
            <DefinitionList>
              <DefinitionList.Item
                label={i18n.t('module:Injury:Preview:Details:attachedMediaLabel')}
                value={(media?.length)
                  ? <MediaPicker value={media} disableSticky disableTagging preview />
                  : '-'}
              />
            </DefinitionList>
          </GridLayout.Item>
        </GridLayout.Group>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item>
            <DefinitionList margin="15px 0 0 0">
              <DefinitionList.Item
                label={(
                  <Typography fontSize="20" bold>
                    {i18n.t('module:Injury:Preview:Details:treatment')}
                  </Typography>
                )}
              />
            </DefinitionList>
          </GridLayout.Item>
        </GridLayout.Group>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item>
            <DefinitionList margin="0 0 10px 0">
              <DefinitionList.Item
                label={i18n.t('module:Injury:Preview:Details:firstAidByLabel')}
                value={firstAider ? firstAider.name : otherFirstAider}
                whiteSpace="normal"
              />
            </DefinitionList>
          </GridLayout.Item>
        </GridLayout.Group>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item>
            <DefinitionList margin="0 0 10px 0">
              <DefinitionList.Item
                label={i18n.t('module:Injury:Preview:Details:treatmentDetailsLabel')}
                value={treatment || '-'}
                whiteSpace="pre-wrap"
                wordBreak="break-word"
              />
            </DefinitionList>
          </GridLayout.Item>
        </GridLayout.Group>
      </GridLayout>
    </div>
  )
}

export default ChildDetails
