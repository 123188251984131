import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledContainer = styled.div`
  display: flex;
  position: relative;

  & + & {
    margin-top: 16px;
  }
`

export const StyledTimeContainer = styled.div`
  margin-right: 16px;
  position: relative;
`

export const StyledTime = styled.div`
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${getBrandingColor('primary-color')};
  border-radius: 50%;
  color: ${NEUTRAL_COLOURS.WHITE};
  font-size: 16px;
`

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const StyledContent = styled.div`
  display: flex;
  background: #F7F7F7;
  border-radius: 10px;
  min-height: 46px;
  padding: 8px 12px;
`

export const StyledContentUserContainer = styled.div`
  margin-right: 12px;
`

export const StyledContentTextContainer = styled.div`
  line-height: 20px;
  padding-top: 5px;
  width: 100%;
`

export const StyledPolyfill = styled.div`
  background: ${NEUTRAL_COLOURS.WHITE};
  position: absolute;
  bottom: 0;
  top: 46px;
  left: 0;
  right: 0;
`
