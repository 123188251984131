import i18n from 'translations'

export const PROGRESS_TRACKER_TYPES = {
  AREA_OF_LEARNING: 'area-of-learning',
  TERMLY_PROGRESS: 'aspect',
}

export const COMPARISON_REPORT_TYPES = {
  ETHNICITY: 'ethnicity',
  EYPP: 'eypp',
  GENDER: 'gender',
  SUMMER_BORN: 'summer-born-children',
}

export const PROGRESS_TRACKER_TYPES_DROPDOWN = [
  {
    label: i18n.t('services:CohortTracking:typesOfProgressTracker:areaOfLearning'),
    value: PROGRESS_TRACKER_TYPES.AREA_OF_LEARNING,
  },
  {
    label: i18n.t('services:CohortTracking:typesOfProgressTracker:termlyProgress'),
    value: PROGRESS_TRACKER_TYPES.TERMLY_PROGRESS,
  },
]

export const COMPARISON_REPORT_TYPES_DROPDOWN = [
  {
    label: i18n.t('services:CohortTracking:typesOfComparisonReport:genderComparison'),
    value: COMPARISON_REPORT_TYPES.GENDER,
  },
  {
    label: i18n.t('services:CohortTracking:typesOfComparisonReport:ethnicityComparison'),
    value: COMPARISON_REPORT_TYPES.ETHNICITY,
  },
  {
    label: i18n.t('services:CohortTracking:typesOfComparisonReport:eyppComparison'),
    value: COMPARISON_REPORT_TYPES.EYPP,
  },
  {
    label: i18n.t('services:CohortTracking:typesOfComparisonReport:summerBornComparison'),
    value: COMPARISON_REPORT_TYPES.SUMMER_BORN,
  },
]

const TYPES_OF_COHORT_TRACKING = {
  AREA_OF_LEARNING: 'AREA_OF_LEARNING_BREAKDOWN',
  ASPECT: 'ASPECT_BREAKDOWN',
  ETHNICITY: 'ETHNICITY_COMPARISON',
  EYPP: 'EYPP_COMPARISON',
  GENDER: 'GENDER_COMPARISON',
  SUMMER_BORN: 'SUMMER_BORN_COMPARISON',
}

export const TYPES_OF_COHORT_TRACKING_DROPDOWN = [
  {
    hrefPrefix: 'area-of-learning',
    isProgressTracker: true,
    label: i18n.t('services:CohortTracking:typesOfProgressTracker:areaOfLearning'),
    value: TYPES_OF_COHORT_TRACKING.AREA_OF_LEARNING,
  },
  {
    hrefPrefix: 'gender',
    label: i18n.t('services:CohortTracking:typesOfComparisonReport:genderComparison'),
    value: TYPES_OF_COHORT_TRACKING.GENDER,
  },
  {
    hrefPrefix: 'ethnicity',
    label: i18n.t('services:CohortTracking:typesOfComparisonReport:ethnicityComparison'),
    value: TYPES_OF_COHORT_TRACKING.ETHNICITY,
  },
  {
    hrefPrefix: 'eypp',
    label: i18n.t('services:CohortTracking:typesOfComparisonReport:eyppComparison'),
    value: TYPES_OF_COHORT_TRACKING.EYPP,
  },
  {
    hrefPrefix: 'summer-born-children',
    label: i18n.t('services:CohortTracking:typesOfComparisonReport:summerBornComparison'),
    value: TYPES_OF_COHORT_TRACKING.SUMMER_BORN,
  },
  {
    hrefPrefix: 'aspect',
    isProgressTracker: true,
    label: i18n.t('services:CohortTracking:typesOfProgressTracker:termlyProgress'),
    value: TYPES_OF_COHORT_TRACKING.ASPECT,
  },
]

export const AGES_MAP = [
  {
    id: 'under_2',
    label: i18n.t('services:CohortTracking:agesMap:under2s'),
    value: { from: 0, to: 23 },
  },
  {
    id: 'year_2',
    label: i18n.t('services:CohortTracking:agesMap:yearsOld', { age: 2 }),
    value: { from: 24, to: 35 },
  },
  {
    id: 'year_3',
    label: i18n.t('services:CohortTracking:agesMap:yearsOld', { age: 3 }),
    value: { from: 36, to: 47 },
  },
  {
    id: 'year_4',
    label: i18n.t('services:CohortTracking:agesMap:yearsOld', { age: 4 }),
    value: { from: 48, to: 59 },
  },
  {
    id: 'year_5',
    label: i18n.t('services:CohortTracking:agesMap:yearsOld', { age: 5 }),
    value: { from: 60 },
  },
]

export const FILTERS_MAP = [
  {
    label: i18n.t('services:CohortTracking:childExtraFields:specialEducationalNeeds'),
    shortLabel: i18n.t('services:CohortTracking:childExtraFields:specialEducationalNeedsShort'),
    value: 'specialEducationalNeeds',
  },
  {
    label: i18n.t('services:CohortTracking:childExtraFields:englishAsAnAdditionalLanguage'),
    shortLabel: i18n.t('services:CohortTracking:childExtraFields:englishAsAnAdditionalLanguageShort'),
    value: 'englishAsAdditionalLanguage',
  },
  {
    label: i18n.t('services:CohortTracking:childExtraFields:doesReceiveEyppFunding'),
    shortLabel: i18n.t('services:CohortTracking:childExtraFields:doesReceiveEyppFundingShort'),
    value: 'doesReceiveEYPPFunding',
  },
  {
    label: i18n.t('services:CohortTracking:childExtraFields:doesReceiveTwoYearFunding'),
    shortLabel: i18n.t('services:CohortTracking:childExtraFields:doesReceiveTwoYearFundingShort'),
    value: 'doesReceiveTwoYearFunding',
  },
  {
    label: i18n.t('services:CohortTracking:childExtraFields:childOfArmedForces'),
    shortLabel: i18n.t('services:CohortTracking:childExtraFields:childOfArmedForcesShort'),
    value: 'parentsAreServingArmedForcesPersonnel',
  },
  {
    label: i18n.t('services:CohortTracking:childExtraFields:summerBorn'),
    shortLabel: i18n.t('services:CohortTracking:childExtraFields:summerBornShort'),
    value: 'summerBornChildren',
  },
]
