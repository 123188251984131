import React from 'react'

import { Spinner } from 'components'

import { REGISTER_ACTION_TYPES, REGISTER_COLORS } from '../../../constants'

import RegisterItemMainActions from '../RegisterItemMainActions'
import RegisterItemAction from '../RegisterItemAction'

import RegisterItemContentSignin from './RegisterItemContentSignin'
import RegisterItemContentEditSignin from './RegisterItemContentEditSignin'
import RegisterItemContentComment from './RegisterItemContentComment'
import RegisterItemContentAbsence from './RegisterItemContentAbsence'

import { StyledContainer, StyledLoadingContainer } from './RegisterItemContentStyled'

const RegisterItemContent = ({
  absence,
  editSignIns,
  expandType,
  isAddingExtraTimeIn,
  isEditing,
  isFutureDate,
  isLoading,
  isSubmitting,
  oldComments,
  onAbsenceActionClick,
  onCommentActionClick,
  onSignIn,
  onSignOut,
  onTimeChange,
  present,
  signIns,
  signInsIndex,
  size,
}) => {
  const { SIGN_IN, SIGN_OUT } = REGISTER_ACTION_TYPES

  const isSignInLoading = expandType === SIGN_IN && isSubmitting
  const isSignOutLoading = expandType === SIGN_OUT && isSubmitting

  const renderEditSignin = (index) => {
    const editSignIn = editSignIns && editSignIns[index]

    if (!editSignIn) {
      return null
    }

    return (
      <RegisterItemContentEditSignin
        index={index}
        signIn={editSignIn}
        size={size}
        onTimeChange={onTimeChange}
      />
    )
  }

  const renderFirstSignIn = () => {
    const firstSignin = (signIns && signIns.length && signIns[0]) || {}

    return (
      <RegisterItemContentSignin
        index={0}
        isFutureDate={isFutureDate}
        isSignInLoading={isSignInLoading}
        isSignOutLoading={isSignOutLoading}
        signIn={firstSignin}
        signInsIndex={signInsIndex}
        size={size}
        onSignIn={onSignIn}
        onSignOut={onSignOut}
      />
    )
  }

  const renderSecondSignIn = () => {
    if (isAddingExtraTimeIn && signIns && 1 === signIns.length) {
      return (
        <RegisterItemMainActions.ItemWrapper>
          <RegisterItemMainActions.Item size={size}>
            <RegisterItemAction
              color={REGISTER_COLORS.timeIn}
              icon="check"
              isLoading={isSignInLoading}
              title="Time in"
              onClick={onSignIn(1)}
            />
          </RegisterItemMainActions.Item>
        </RegisterItemMainActions.ItemWrapper>
      )
    }

    const secondSignIn = signIns && signIns[1]

    if (!secondSignIn) {
      return null
    }

    return (
      <RegisterItemContentSignin
        index={1}
        isFutureDate={isFutureDate}
        isSignInLoading={isSignInLoading}
        isSignOutLoading={isSignOutLoading}
        signIn={secondSignIn}
        signInsIndex={signInsIndex}
        size={size}
        onSignIn={onSignIn}
        onSignOut={onSignOut}
      />
    )
  }

  const renderSignInsSmall = (index) => {
    if (absence) {
      return null
    }

    if (isEditing) {
      if (!editSignIns || 0 === editSignIns.length) {
        return null
      }

      return renderEditSignin(index)
    }

    return 0 === index ? renderFirstSignIn() : renderSecondSignIn()
  }

  const renderBottomContainer = () => {
    const bottomContainer = renderSignInsSmall(1)

    if (!bottomContainer) {
      return null
    }

    return bottomContainer
  }

  const renderMainActions = () => {
    const secondSignInComponent = renderBottomContainer()
    const hasSecondSignin = !!secondSignInComponent

    return (
      <RegisterItemMainActions isEditing={isEditing} showSeparator={hasSecondSignin} size={size}>
        <RegisterItemMainActions.ItemWrapper>
          {renderSignInsSmall(0)}
        </RegisterItemMainActions.ItemWrapper>
        <RegisterItemMainActions.ItemWrapper>
          {secondSignInComponent}
          <RegisterItemContentComment
            absence={absence}
            expandType={expandType}
            oldComments={oldComments}
            present={present}
            size={size}
            onCommentActionClick={onCommentActionClick}
          />
          <RegisterItemContentAbsence
            absence={absence}
            expandType={expandType}
            present={present}
            signIns={signIns}
            size={size}
            onAbsenceActionClick={onAbsenceActionClick}
          />
        </RegisterItemMainActions.ItemWrapper>
      </RegisterItemMainActions>
    )
  }

  if (isLoading) {
    return (
      <StyledLoadingContainer>
        <Spinner />
      </StyledLoadingContainer>
    )
  }

  return (
    <StyledContainer size={size}>
      {renderMainActions()}
    </StyledContainer>
  )
}

export default RegisterItemContent
