import React from 'react'
import i18n from 'translations'

import { Field, Section, Toolbar } from 'components'

import {
  InvoiceDateSettings,
  InvoiceDetailRightComponent,
  InvoiceDueDateSettings,
  InvoiceNoteSettings,
  InvoicePaymentReminderSettings,
  InvoiceRadioSettings,
  InvoiceWeeksDaysSettings,
} from './components'
import { extraItemsCalculationOptions, invoiceDetailOptions, sessionCalculationOptions } from './constants'
import { StyledOptionsContainer } from './InvoiceSettingStyled'

const InvoiceSettingV2 = ({
  description,
  disableEdit,
  hideAutoCalculateStartDate,
  hideAutomationOptions,
  hideGlobalOption,
  hideInvoiceNumberOptions,
  hidePaymentReminders,
  invoiceNumberSettingsConfig,
  invoiceNumberSettingsData,
  invoicesSettings,
  onSubmit,
  orgInvoicesSettings,
  paymentReminderTags,
  title,
}) => {
  const {
    autocalculationStartDate,
    dueDate,
    dueDateAppliedFromGlobal,
    extraItemsCalculation,
    extraItemsCalculationAppliedFromGlobal,
    invoiceLevelDetailDisplay,
    invoiceLevelDetailDisplayAppliedFromGlobal,
    invoiceNotesAppliedFromGlobal,
    notes,
    paymentReminders,
    sessionCalculation,
    sessionCalculationAppliedFromGlobal,
    sessionCalculationDays,
    sessionCalculationWeeks,
    sessionCalculationWeeksAndDaysAppliedFromGlobal,
    statementOfAccountNotesAppliedFromGlobal,
  } = invoicesSettings || {}

  const {
    editButtonLink,
    subtitle,
    title: invoiceNumberSettingsTitle,
  } = invoiceNumberSettingsConfig || {}

  const {
    creditNoteNumberDescription,
    invoiceNumberDescription,
    receiptNumberDescription,
  } = invoiceNumberSettingsData || {}

  const {
    dueDate: orgDueDate,
    extraItemsCalculation: orgExtraItemsCalculation,
    invoiceLevelDetailDisplay: orgInvoiceLevelDetailDisplay,
    notes: orgNotes,
    paymentReminders: orgPaymentReminders,
    sessionCalculation: orgSessionCalculation,
    sessionCalculationDays: orgSessionCalculationDays,
    sessionCalculationWeeks: orgSessionCalculationWeeks,
  } = orgInvoicesSettings || {}

  return (
    <React.Fragment>
      {!hideAutomationOptions && !hideAutoCalculateStartDate && (
        <Section title={title}>
          <Toolbar>
            <Toolbar.Group>
              <Toolbar.Item>{description}</Toolbar.Item>
            </Toolbar.Group>
          </Toolbar>
          <InvoiceDateSettings
            caption={i18n.t('components:Settings:InvoiceSettings:AutoCalculationDate:caption')}
            disableEdit={disableEdit}
            fields={{
              global: {},
              item: {
                id: 'autocalculationStartDate',
                value: autocalculationStartDate,
              },
            }}
            hideGlobalOption
            onSubmit={onSubmit}
          />
        </Section>
      )}
      {!hideAutomationOptions && (
        <Section title={i18n.t('components:Settings:InvoiceSettings:FeesCalculations:title')}>
          <StyledOptionsContainer>
            <InvoiceRadioSettings
              caption={i18n.t('components:Settings:InvoiceSettings:SessionCalculation:caption')}
              disableEdit={disableEdit}
              fields={{
                global: {
                  id: 'sessionCalculationAppliedFromGlobal',
                  value: sessionCalculationAppliedFromGlobal,
                },
                item: {
                  id: 'sessionCalculation',
                  orgValue: orgSessionCalculation,
                  value: sessionCalculation,
                },
              }}
              hideGlobalOption={hideGlobalOption}
              options={sessionCalculationOptions}
              onSubmit={onSubmit}
            />
            <InvoiceRadioSettings
              caption={i18n.t('components:Settings:InvoiceSettings:ExtraItems:caption')}
              disableEdit={disableEdit}
              fields={{
                global: {
                  id: 'extraItemsCalculationAppliedFromGlobal',
                  value: extraItemsCalculationAppliedFromGlobal,
                },
                item: {
                  id: 'extraItemsCalculation',
                  value: extraItemsCalculation,
                },
              }}
              hideGlobalOption={hideGlobalOption}
              options={extraItemsCalculationOptions}
              orgValue={orgExtraItemsCalculation}
              onSubmit={onSubmit}
            />
            <InvoiceWeeksDaysSettings
              caption={
                i18n.t('components:Settings:InvoiceSettings:SessionCalculationWeekDays:caption')
              }
              disableEdit={disableEdit}
              fields={{
                days: {
                  id: 'sessionCalculationDays',
                  orgValue: orgSessionCalculationDays,
                  value: sessionCalculationDays,
                },
                global: {
                  id: 'sessionCalculationWeeksAndDaysAppliedFromGlobal',
                  value: sessionCalculationWeeksAndDaysAppliedFromGlobal,
                },
                item: {
                  id: 'sessionsWeeksDays',
                },
                weeks: {
                  id: 'sessionCalculationWeeks',
                  orgValue: orgSessionCalculationWeeks,
                  value: sessionCalculationWeeks,
                },
              }}
              hideGlobalOption={hideGlobalOption}
              tooltip={i18n.t('components:Settings:InvoiceSettings:SessionCalculationWeekDays:tooltip')}
              onSubmit={onSubmit}
            />
          </StyledOptionsContainer>
        </Section>
      )}
      {!hideInvoiceNumberOptions && (
        <Section
          actions={editButtonLink && <Section.Actions tertiary={{ to: editButtonLink }} />}
          title={invoiceNumberSettingsTitle}
        >
          {subtitle && (
            <p>
              {subtitle}
            </p>
          )}
          <Field.InlineEdit description={invoiceNumberDescription} label="Next invoice numbers from" />
          <Field.InlineEdit description={receiptNumberDescription} label="Next receipt numbers from" />
          <Field.InlineEdit description={creditNoteNumberDescription} label="Next credit note numbers from" />
        </Section>
      )}
      <Section title={i18n.t('components:Settings:InvoiceSettings:InvoiceDisplay:title')}>
        <InvoiceRadioSettings
          caption={i18n.t('components:Settings:InvoiceSettings:InvoiceDetail:caption')}
          disableEdit={disableEdit}
          fields={{
            global: {
              id: 'invoiceLevelDetailDisplayAppliedFromGlobal',
              value: invoiceLevelDetailDisplayAppliedFromGlobal,
            },
            item: {
              id: 'invoiceLevelDetailDisplay',
              orgValue: orgInvoiceLevelDetailDisplay,
              value: invoiceLevelDetailDisplay,
            },
          }}
          hideGlobalOption={hideGlobalOption}
          options={invoiceDetailOptions}
          rightComponent={(input) => <InvoiceDetailRightComponent {...input} />}
          onSubmit={onSubmit}
        />
      </Section>
      <Section title={i18n.t('components:Settings:InvoiceSettings:InvoiceNotesAndReminders:title')}>
        <StyledOptionsContainer>
          <InvoiceNoteSettings
            caption={i18n.t('components:Settings:InvoiceSettings:InvoiceNote:caption')}
            disableEdit={disableEdit}
            fields={{
              global: {
                id: 'invoiceNotesAppliedFromGlobal',
                value: invoiceNotesAppliedFromGlobal,
              },
              item: {
                id: 'notes',
                orgValue: orgNotes,
                value: notes,
              },
            }}
            hideGlobalOption={hideGlobalOption}
            noteId="INVOICE_NOTES"
            onSubmit={onSubmit}
          />
          <InvoiceNoteSettings
            caption={i18n.t('components:Settings:InvoiceSettings:StatementOfAccount:caption')}
            disableEdit={disableEdit}
            fields={{
              global: {
                id: 'statementOfAccountNotesAppliedFromGlobal',
                value: statementOfAccountNotesAppliedFromGlobal,
              },
              item: {
                id: 'notes',
                orgValue: orgNotes,
                value: notes,
              },
            }}
            hideGlobalOption={hideGlobalOption}
            noteId="STATEMENT_OF_ACCOUNT_NOTES"
            onSubmit={onSubmit}
          />
          <InvoiceDueDateSettings
            caption={i18n.t('components:Settings:InvoiceSettings:InvoiceDueDate:caption')}
            disableEdit={disableEdit}
            fields={{
              global: {
                id: 'dueDateAppliedFromGlobal',
                value: dueDateAppliedFromGlobal,
              },
              item: {
                id: 'dueDate',
                orgValue: orgDueDate,
                value: dueDate,
              },
            }}
            hideGlobalOption={hideGlobalOption}
            tooltip={i18n.t('components:Settings:InvoiceSettings:InvoiceDueDate:tooltip')}
            onSubmit={onSubmit}
          />
          {!hidePaymentReminders && (
            <InvoicePaymentReminderSettings
              disableEdit={disableEdit}
              fields={{
                global: {
                  id: 'paymentRemindersAppliedFromGlobal',
                  value: false,
                },
                item: {
                  id: 'paymentReminders',
                  orgValue: orgPaymentReminders,
                  value: paymentReminders,
                },
              }}
              paymentReminderTags={paymentReminderTags}
              hideGlobalOption
              onSubmit={onSubmit}
            />
          )}
        </StyledOptionsContainer>
      </Section>
    </React.Fragment>
  )
}

export default InvoiceSettingV2
