import {
  CLEAR_NURSERY_EXTRA_ITEM,
  CREATE_NURSERY_EXTRA_ITEM,
  CREATE_NURSERY_EXTRA_ITEM_FAILED,
  CREATE_NURSERY_EXTRA_ITEM_SUCCESS,
  GET_NURSERY_EXTRA_ITEM,
  GET_NURSERY_EXTRA_ITEM_FAILED,
  GET_NURSERY_EXTRA_ITEM_SUCCESS,
  UPDATE_NURSERY_EXTRA_ITEM,
  UPDATE_NURSERY_EXTRA_ITEM_FAILED,
  UPDATE_NURSERY_EXTRA_ITEM_SUCCESS,
} from './actions'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case GET_NURSERY_EXTRA_ITEM:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_NURSERY_EXTRA_ITEM_SUCCESS:
      return { ...state, data: payload.data, isFetching: false }
    case GET_NURSERY_EXTRA_ITEM_FAILED:
      return { ...state, error, isFetching: false }
    case CREATE_NURSERY_EXTRA_ITEM:
      return { ...state, error: initialState.error, isSubmitting: true }
    case CREATE_NURSERY_EXTRA_ITEM_SUCCESS:
      return { ...state, data: payload.data, isSubmitting: false }
    case CREATE_NURSERY_EXTRA_ITEM_FAILED:
      return { ...state, error, isSubmitting: false }
    case UPDATE_NURSERY_EXTRA_ITEM:
      return { ...state, error: initialState.error, isSubmitting: true }
    case UPDATE_NURSERY_EXTRA_ITEM_SUCCESS:
      return { ...state, data: payload.data, isSubmitting: false }
    case UPDATE_NURSERY_EXTRA_ITEM_FAILED:
      return { ...state, error, isSubmitting: false }
    case CLEAR_NURSERY_EXTRA_ITEM:
      return initialState
    default:
      return state
  }
}
