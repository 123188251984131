export const getInitials = (name: string) => {
  if (!name) {
    return ''
  }

  const words = name.trim().replace(/[^\w\s]/gi, '').toUpperCase().split(' ')

  if (1 === words.length) {
    return words[0][0]
  }

  if (words[words.length - 1][0]) {
    return `${words[0][0]}${words[words.length - 1][0]}`
  }

  return words[0][0]
}

export const getOrdinalNumber = (number: number) => {
  const suffix = ['th', 'st', 'nd', 'rd']
  const modulo100 = number % 100

  return number + (suffix[(modulo100 - 20) % 10] || suffix[modulo100] || suffix[0])
}
