import React, { PropsWithChildren } from 'react'

import { StyledMiddleDetails } from './ProfileHeaderStyled'

const ProfileHeaderDetailsMiddle: React.FC<PropsWithChildren<any>> = ({ children }) => (
  <StyledMiddleDetails>
    {children}
  </StyledMiddleDetails>
)

export default ProfileHeaderDetailsMiddle
