import _ from 'lodash'
import moment from 'moment'
import { flatten, nest } from 'utils/flatnest'
import { compose } from 'recompose'

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'
import { getYearOptions } from 'utils/date'

import { withAppService } from 'services/app'
import { withRouterUtils } from 'services/utils/router'
import { withModalService } from 'services/utils/modal'
import { withInvoicesForecastService } from 'services/legacy/invoices'
import { getForecastingExportPayload } from 'services/legacy/child/helpers'
import { withRouter } from 'services/router'

import i18n from 'translations'

import FinanceReportAnnualRevenueView from './FinanceReportAnnualRevenueView'

const GROUPS = {
  read: [
    'forecastStatistics',
    'forecastStatistics.forecast',
    'forecastStatistics.invoices',
    'statistics.invoice.summary',
    'statistics.invoice.items',
    'statistics.invoice.income',
  ],
}

class FinanceReportAnnualRevenueContainer extends Component {
  constructor(props) {
    super(props)

    const { location } = props
    const { query = {} } = location
    const { year: queryYear } = nest(query)

    const yearsOptions = getYearOptions(2)

    this.state = {
      year: yearsOptions.find((yearOption) => yearOption.value === (queryYear || moment().format('YYYY'))),
      yearsOptions,
    }
  }

  componentDidMount() {
    this.fetchRevenue()
  }

  fetchRevenue = () => {
    const {
      invoicesForecastActions,
      invoicesForecastSelectors,
      setLocationQuery,
    } = this.props
    const { year } = this.state

    const { dateRange, value } = year

    setLocationQuery(flatten({ year: value }))

    const chartCriteria = invoicesForecastSelectors.getCriteria({ date: dateRange })

    const chartApiParams = {
      criteria: chartCriteria,
      groups: GROUPS,
    }

    invoicesForecastActions.getForecast(chartApiParams)
  }

  handleYearChange = (year) => {
    this.setState({ year }, this.fetchRevenue)
  }

  handleExportModalClick = (body, onSuccessCb) => {
    const { invoicesForecastActions } = this.props
    const { year } = this.state

    const { dateRange } = year
    const { criteria } = body

    const recipients = _.find(criteria, { field: 'membership' })

    const payload = getForecastingExportPayload({
      endDate: dateRange.before,
      recipients,
      startDate: dateRange.after,
    })

    invoicesForecastActions.sendAnnualRevenueReport(payload, onSuccessCb)
  }

  handleSendReport = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.EXPORT_REPORT, {
      onSendReport: this.handleExportModalClick,
      title: i18n.t('module:Finance:Reports:AnnualRevenue:sendPopupTitle'),
      userLabel: i18n.t('module:Modals:ExportReportModal:User:Label:export'),
    })
  }

  render() {
    const {
      chart,
      isFetching,
      isFinanceV3Enabled,
      revenueData,
    } = this.props
    const { year, yearsOptions } = this.state

    return (
      <FinanceReportAnnualRevenueView
        chart={chart}
        isFetching={isFetching}
        isFinanceV3Enabled={isFinanceV3Enabled}
        revenueData={revenueData}
        year={year}
        yearsOptions={yearsOptions}
        onSendReport={this.handleSendReport}
        onYearChange={this.handleYearChange}
      />
    )
  }
}

FinanceReportAnnualRevenueContainer.authParams = {
  flags: [FEATURE_FLAGS.FINANCE_AUTOMATION],
  roles: [ROLES.SUPER_ADMIN, ROLES.NURSERY_ADMIN, ROLES.NURSERY_MANAGER, ROLES.ORGANIZATION_FINANCE_ADMIN],
}

const mapState = (state, { appSelectors, invoicesForecastSelectors, invoicesForecastState }) => ({
  chart: invoicesForecastSelectors.getChartData(state),
  isFetching: appSelectors.getIsFetching(invoicesForecastState),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
  revenueData: invoicesForecastSelectors.getForecastingReportTableDataSelector(state),
})

const enhance = compose(
  withAppService,
  withInvoicesForecastService,
  withModalService,
  withRouterUtils,
  connect(mapState),
  withRouter,
)

export default enhance(FinanceReportAnnualRevenueContainer)
