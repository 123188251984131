import { generateBasicActions } from 'utils/service'

import {
  CLEAR_LIST_OF_FRAMEWORK_MONTESSORI_PROGRESS,
  LIST_OF_FRAMEWORK_MONTESSORI_PROGRESS,
  LIST_OF_FRAMEWORK_MONTESSORI_PROGRESS_FAILED,
  LIST_OF_FRAMEWORK_MONTESSORI_PROGRESS_SUCCESS,
  REMOVE_PROGRESS_MONTESSORI_EVENT,
  REMOVE_PROGRESS_MONTESSORI_EVENT_FAILED,
  REMOVE_PROGRESS_MONTESSORI_EVENT_SUCCESS,
  UPDATE_BATCH_FRAMEWORK_MONTESSORI_PROGRESS,
  UPDATE_BATCH_FRAMEWORK_MONTESSORI_PROGRESS_FAILED,
  UPDATE_BATCH_FRAMEWORK_MONTESSORI_PROGRESS_SUCCESS,
  UPDATE_SINGLE_MONTESSORI_PROGRESS,
} from './constants'

export const listOfMontessoriProgress = (options = {}) => ({ childFrameworkProgressApiClient }) => (
  generateBasicActions.list({
    constants: {
      failed: LIST_OF_FRAMEWORK_MONTESSORI_PROGRESS_FAILED,
      init: LIST_OF_FRAMEWORK_MONTESSORI_PROGRESS,
      success: LIST_OF_FRAMEWORK_MONTESSORI_PROGRESS_SUCCESS,
    },
    options,
    service: childFrameworkProgressApiClient,
    serviceMethod: 'listOfMontessoriProgress',
  })
)

export const batchMontessoriUpdate = (options = {}) => ({ childFrameworkProgressApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_BATCH_FRAMEWORK_MONTESSORI_PROGRESS_FAILED,
      init: UPDATE_BATCH_FRAMEWORK_MONTESSORI_PROGRESS,
      success: UPDATE_BATCH_FRAMEWORK_MONTESSORI_PROGRESS_SUCCESS,
    },
    options,
    service: childFrameworkProgressApiClient,
    serviceMethod: 'batchMontessoriUpdate',
  })
)

export const updateSingleMontessoriProgress = (payload) => ({
  payload,
  type: UPDATE_SINGLE_MONTESSORI_PROGRESS,
})

export const removeEventMontessori = (options = {}) => ({ childFrameworkProgressApiClient }) => (
  generateBasicActions.remove({
    constants: {
      failed: REMOVE_PROGRESS_MONTESSORI_EVENT_FAILED,
      init: REMOVE_PROGRESS_MONTESSORI_EVENT,
      success: REMOVE_PROGRESS_MONTESSORI_EVENT_SUCCESS,
    },
    options,
    service: childFrameworkProgressApiClient,
    serviceMethod: 'removeMontessoriEvent',
  })
)

export const clearMontessori = () => ({
  type: CLEAR_LIST_OF_FRAMEWORK_MONTESSORI_PROGRESS,
})
