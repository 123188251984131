import {
  ADD_BOTTLE_TO_LIST,
  CLEAR_DAILY_DIARY_BOTTLES,
  GET_DAILY_DIARY_BOTTLES,
  GET_DAILY_DIARY_BOTTLES_FAILED,
  GET_DAILY_DIARY_BOTTLES_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ dailyDiaryBottlesApiClient }) => (dispatch) => {
  const { mergeResult, params } = options

  dispatch({
    type: GET_DAILY_DIARY_BOTTLES,
  })

  dailyDiaryBottlesApiClient.list(params).then(
    (response) => {
      dispatch({
        mergeResult,
        payload: response,
        type: GET_DAILY_DIARY_BOTTLES_SUCCESS,
      })
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: GET_DAILY_DIARY_BOTTLES_FAILED,
      })
    },
  )
}

export const addToList = (payload) => ({
  payload,
  type: ADD_BOTTLE_TO_LIST,
})

export const clearList = () => ({
  type: CLEAR_DAILY_DIARY_BOTTLES,
})
