import { ROLES } from 'constants/security'
import _ from 'lodash'

import { flatten, nest } from 'utils/flatnest'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { MENU_ITEMS_CATEGORY, MENU_ITEMS_STATUS } from 'services/legacy/foodTypes/constants'

import { withAppService } from 'services/app'
import { withFoodTypesService } from 'services/legacy/foodTypes'
import { withModalService } from 'services/utils/modal'
import { withPaginationUtils } from 'services/utils/pagination'
import { withSnackbarService } from 'services/utils/snackbar'
import { withSortingUtils } from 'services/utils/sorting'

import { getIsMealsContext, getTransPrefix, handleArchiveClick } from '../ManagementMenuItemsHelpers'
import MenuItemsListView from './MenuItemsListView'

class MenuItemsListContainer extends Component {
  constructor(props) {
    super(props)

    const { location } = this.props
    const { pathname, query } = location

    const { search, status } = nest(query)

    const isMealsContext = getIsMealsContext(pathname)

    this.state = {
      filters: {
        search,
        status: status || MENU_ITEMS_STATUS.ACTIVE,
      },
      isMealsContext,
      prefix: getTransPrefix(isMealsContext),
      selected: [],
      selectedAll: false,
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { foodTypesActions } = this.props

    foodTypesActions.clearList()
    foodTypesActions.clearBatch()
  }

  fetch = () => {
    const {
      foodTypesActions,
      foodTypesSelectors,
      paginationUtils,
      setLocationQuery,
      sortingUtils,
    } = this.props
    const { filters, isMealsContext } = this.state
    const { page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    setLocationQuery(flatten(filters))

    const criteria = foodTypesSelectors.getListCriteria({
      ...filters,
      category: isMealsContext ? MENU_ITEMS_CATEGORY.MEAL : MENU_ITEMS_CATEGORY.SNACK,
    })

    return foodTypesActions.list({
      params: [{
        criteria,
        order: { sortField, sortOrder },
        page,
      }],
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleSortChange = (key) => {
    const { sortingUtils } = this.props
    const { onSortChange } = sortingUtils

    onSortChange(this.handlePageChange(1))(key)
  }

  handleStatusChange = (status) => {
    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          status: status?.value,
        },
      }),
      () => this.handlePageChange(1),
    )
  }

  handleSearchChange = (search) => {
    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          search,
        },
      }),
      () => this.handlePageChange(1),
    )
  }

  handleCheckboxClick = (itemId) => {
    this.setState((prevState) => ({
      selected: _.xor(prevState.selected, [itemId]),
      selectedAll: false,
    }))
  }

  handleSelectAll = () => {
    const { data } = this.props

    this.setState((prevState) => ({
      selected: !prevState.selectedAll
        ? _.map(data, ({ id }) => id)
        : [],
      selectedAll: !prevState.selectedAll,
    }))
  }

  handleArchiveMenuItemsSuccess = () => {
    this.setState({
      selected: [],
      selectedAll: false,
    }, () => this.handlePageChange(1))
  }

  handleArchiveClick = () => {
    const {
      data,
      foodTypesActions,
      modalActions,
      modalConsts,
      snackbarActions,
    } = this.props
    const { filters, prefix, selected } = this.state
    const { status } = filters

    const isArchived = MENU_ITEMS_STATUS.ARCHIVED === status
    const isSingleRecord = 1 === selected.length
    const name = isSingleRecord ? _.find(data, ({ id }) => selected[0] === id).name : ''

    return handleArchiveClick({
      foodTypesActions,
      isArchived,
      isSingleRecord,
      modalActions,
      modalConsts,
      name,
      onSuccess: this.handleArchiveMenuItemsSuccess,
      prefix,
      selected,
      snackbarActions,
    })
  }

  render() {
    const {
      data,
      errorMessages,
      isFetching,
      isSubmitting,
      paginationUtils,
      sortingUtils,
      totalResults,
    } = this.props
    const {
      filters,
      isMealsContext,
      prefix,
      selected,
      selectedAll,
    } = this.state
    const { search, status } = filters
    const { getPageCount, page, perPage } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const pageCount = getPageCount(totalResults)
    const isLoading = isFetching || isSubmitting

    return (
      <MenuItemsListView
        data={data}
        errorMessages={errorMessages}
        isLoading={isLoading}
        isMealsContext={isMealsContext}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        prefix={prefix}
        search={search}
        selected={selected}
        selectedAll={selectedAll}
        sortField={sortField}
        sortOrder={sortOrder}
        status={status}
        totalResults={totalResults}
        onArchiveClick={this.handleArchiveClick}
        onCheckboxClick={this.handleCheckboxClick}
        onPageChange={this.handlePageChange}
        onSearchChange={this.handleSearchChange}
        onSelectAll={this.handleSelectAll}
        onSortChange={this.handleSortChange}
        onStatusChange={this.handleStatusChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  foodTypesBatchState,
  foodTypesListState,
  foodTypesSelectors,
}) => ({
  data: foodTypesSelectors.getFoodTypesData(state),
  errorMessages: appSelectors.getErrorMessages(foodTypesBatchState, foodTypesListState),
  isFetching: appSelectors.getIsFetching(foodTypesListState),
  isSubmitting: appSelectors.getIsSubmitting(foodTypesBatchState),
  totalResults: appSelectors.getTotalResults(foodTypesListState),
})

MenuItemsListContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

const enhance = compose(
  withAppService,
  withFoodTypesService,
  withModalService,
  withPaginationUtils,
  withSnackbarService,
  withSortingUtils,
  connect(mapState),
)

export default enhance(MenuItemsListContainer)
