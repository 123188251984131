import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { stopSubmit } from 'redux-form'

import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withDailyDiaryBottlesService } from 'services/legacy/dailyDiaryBottles'
import { withModalService } from 'services/utils/modal'

import BottleTabView from './BottleTabView'
import { BOTTLE_TAB_FORM } from './components/BottleTabForm'

const GROUPS = {
  read: ['bottleFeedRecord'],
}

class BottleTabContainer extends Component {
  handleSubmitFailed = (response) => {
    const { injectValidation } = this.props
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    return injectValidation(errors)
  }

  handleSubmitSuccess = ({ data }) => {
    const { dailyDiaryBottlesListWithoutRecordsState, onSuccess } = this.props
    const { data: withoutRecords } = dailyDiaryBottlesListWithoutRecordsState

    const records = _.map(data, ({ child, record }) => ({
      data: {
        ...record,
        child: {
          id: child.id,
        },
      },
      record: _.find(withoutRecords, ({ childRegister }) => childRegister.child.id === child.id),
    }))

    onSuccess({ records })
  }

  handleSubmit = (values) => {
    const { dailyDiaryBottlesActions, dailyDiaryBottlesSelectors, selectedChildren } = this.props
    const body = dailyDiaryBottlesSelectors.getValuesForm(values)

    const criteria = _.map(selectedChildren, (child, index) => ({
      field: `id[${index}]`,
      value: child.id,
    }))

    dailyDiaryBottlesActions.addRecord({
      body,
      onFailed: this.handleSubmitFailed,
      onSuccess: this.handleSubmitSuccess,
      params: [{
        criteria,
        groups: GROUPS,
      }],
    })
  }

  render() {
    const { date, getInitialValues, isSubmitting, onCancel, selectedChildren } = this.props

    const initialValues = getInitialValues({ date })

    return (
      <BottleTabView
        initialValues={initialValues}
        isSubmitting={isSubmitting}
        selectedChildren={selectedChildren}
        onCancel={onCancel}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  injectValidation: (params) => stopSubmit(BOTTLE_TAB_FORM, params),
}

const mapState = (state, {
  appSelectors,
  dailyDiaryBottlesSelectors,
  dailyDiaryBottlesSingleState,
}) => ({
  getInitialValues: (params) => dailyDiaryBottlesSelectors.getInitialValues(params),
  isSubmitting: appSelectors.getIsSubmitting(
    dailyDiaryBottlesSingleState,
  ),
})

const enhance = compose(
  withAppService,
  withDailyDiaryBottlesService,
  withModalService,
  connect(mapState, mapDispatch),
)

export default enhance(BottleTabContainer)
