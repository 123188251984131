import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'

import { TextField, Typography } from 'components'

import i18n from 'translations'

export const getTableData = ({
  childrenList,
  codeReferences,
  onBlur,
  onChange,
  onFocus,
}) => {
  if (!childrenList?.length) {
    return null
  }

  return _.map(childrenList, (child) => {
    const {
      ageMonths,
      codeReference,
      displayName,
      id,
      nurseryClass,
      startDate,
    } = child

    return {
      age: i18n.t('global:ageMonths', { ageMonths }),
      child: (
        <Typography wordBreak="break-word">
          {displayName}
        </Typography>
      ),
      codeReference: (
        <TextField
          error={codeReferences?.[id]?.error}
          placeholder={i18n.t('global:Child:referenceCode')}
          type="text"
          value={codeReferences?.[id]?.edited ? codeReferences[id].value : codeReference}
          onBlur={(e) => onBlur(e, id, displayName)}
          onChange={(e) => onChange(e, id)}
          onFocus={onFocus}
        />
      ),
      room: nurseryClass?.name,
      startDate: moment(startDate).format(DISPLAY_SHORT_DATE_FORMAT),
    }
  })
}
