export const CREATE_NEWSLETTERS = 'CREATE_NEWSLETTERS'
export const CREATE_NEWSLETTERS_SUCCESS = 'CREATE_NEWSLETTERS_SUCCESS'
export const CREATE_NEWSLETTERS_FAILED = 'CREATE_NEWSLETTERS_FAILED'

export const GET_NEWSLETTERS = 'GET_NEWSLETTERS'
export const GET_NEWSLETTERS_SUCCESS = 'GET_NEWSLETTERS_SUCCESS'
export const GET_NEWSLETTERS_FAILED = 'GET_NEWSLETTERS_FAILED'

export const UPDATE_NEWSLETTERS = 'UPDATE_NEWSLETTERS'
export const UPDATE_NEWSLETTERS_SUCCESS = 'UPDATE_NEWSLETTERS_SUCCESS'
export const UPDATE_NEWSLETTERS_FAILED = 'UPDATE_NEWSLETTERS_FAILED'

export const REMOVE_NEWSLETTERS = 'REMOVE_NEWSLETTERS'
export const REMOVE_NEWSLETTERS_SUCCESS = 'REMOVE_NEWSLETTERS_SUCCESS'
export const REMOVE_NEWSLETTERS_FAILED = 'REMOVE_NEWSLETTERS_FAILED'

export const CLEAR_NEWSLETTERS_SINGLE = 'CLEAR_NEWSLETTERS_SINGLE'
