import styled from 'styled-components'

import { Link } from 'react-router'

import { ZINDEX_ORDER } from 'constants/layout'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  z-index: ${ZINDEX_ORDER.VARIOUS_PAGES};
  padding: 20px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${NEUTRAL_COLOURS.WHITE};
  
  @media(max-width: 520px) {
    h2 {
      font-size: 26px;
      margin-bottom: 15px;
    }
    
    h5 {
      font-size: 20px;
      margin-top: 10px;
    }
    
    h3 {
      font-size: 22px;
    }
  }
`

export const StyledLogo = styled.img`
  max-width: 150px;
  max-height: 40px;
`

export const StyledLink = styled(Link)`
  width: 150px;
  height: 40px;
  margin-bottom: 60px;
`

export const StyledBabyImage = styled.div`
  width: 250px;
  height: 250px;
  margin-bottom: 20px;
  position: relative;
  
  .cls-4 {
    fill: ${getBrandingColor('primary-color')};
    stroke: ${getBrandingColor('secondary-color')};
  }
`

export const StyledCustomLogo = styled.div`
  position: absolute;
  left: 94px;
  bottom: 52px;
  background: #FFF;
  width: 60px;
  height: 58px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 50px;
    max-height: 50px;
    object-fit: cover;
    margin: 1px 0 0 -2px;
  }
  
  @media(max-width: 520px) {
    left: 106px;
    bottom: 47px;
    width: 60px;
    height: 60px;

    img {
      max-width: 45px;
      max-height: 50px;
    }
  }
`
