import React from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { getIsLoggedIn } from 'utils/auth/selectors'

import AccessForbiddenView from './AccessForbiddenView'

const mapState = (state) => ({
  isLoggedIn: getIsLoggedIn(state),
})

const connector = connect(mapState)

export type PropsFromRedux = ConnectedProps<typeof connector>

const AccessForbiddenContainer: React.FC<PropsFromRedux> = ({ isLoggedIn }) => (
  <AccessForbiddenView
    isLoggedIn={isLoggedIn}
  />
)

const enhance = compose(connector)

export default enhance(AccessForbiddenContainer)
