import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withEnquiryReportsService } from 'services/legacy/enquiries/reports'
import { withPaginationUtils } from 'services/utils/pagination'
import { withSecurityService } from 'services/security'
import { withRouter } from 'services/router'

import { useBasicReport } from 'hooks/reports'

import { DatePicker, Form, InfiniteDropdowns, ReportView, Toolbar } from 'components'

import i18n from 'translations'

import { getStatistics, getTableData } from './ViewingPerformanceHelper'
import { GROUP_TABLE_COLUMNS, NURSERY_TABLE_COLUMNS } from './constants'
import BreakdownSelect, { BREAKDOWN_OPTIONS } from '../components/BreakdownSelect'

const VIEWING_GROUPS = {
  read: [
    'membership',
    'nursery',
  ],
}

const ViewingPerformanceContainer = ({
  appProperties,
  enquiryReportsActions,
  enquiryReportsSelectors,
  errorMessages,
  isFetching,
  isOrganizationContext,
  isStatisticsFetching,
  location,
  paginationUtils,
  setLocationQuery,
  statisticsData,
  totalResults: tableTotalResult,
  viewingPerformanceData,
}) => {
  const {
    breakdown,
    dateRange,
    nursery,
    onBreakdownChange,
    onDateChange,
    onNurseryChange,
    onPageChange,
    page,
    pageCount,
    perPage,
    statistics,
    tableColumns,
    tableData,
    totalResults,
  } = useBasicReport({
    actions: {
      clearChildren: enquiryReportsActions.clearViewingPerformance,
      clearNurseries: enquiryReportsActions.clearViewingPerformance,
      clearStatistics: enquiryReportsActions.clearViewingPerformanceStatistics,
      getChildren: enquiryReportsActions.getViewingPerformance,
      getNurseries: enquiryReportsActions.getViewingPerformance,
      getStatistics: enquiryReportsActions.getViewingPerformanceStatistics,
    },
    breakdownOptions: BREAKDOWN_OPTIONS,
    childrenApiGroups: VIEWING_GROUPS,
    childrenData: viewingPerformanceData,
    childrenTotalResults: tableTotalResult,
    errorMessages,
    getColumns: () => (isOrganizationContext ? GROUP_TABLE_COLUMNS : NURSERY_TABLE_COLUMNS),
    getStatistics,
    getTableData: (props) => getTableData({ ...props, appProperties }),
    isOrganizationContext,
    location,
    nurseriesApiGroups: VIEWING_GROUPS,
    nurseriesData: viewingPerformanceData,
    nurseriesTotalResults: tableTotalResult,
    paginationUtils,
    selectors: {
      getCriteria: enquiryReportsSelectors.getViewingPerformanceCriteria,
    },
    setLocationQuery,
    statisticsData,
  })

  return (
    <ReportView.DetailPage
      errorMessages={errorMessages}
      filters={(
        <Toolbar bottomLine>
          <Toolbar.Group>
            {isOrganizationContext && (
              <Toolbar.Item>
                <Form.Row
                  label={i18n.t('module:Enquiries:Report:ViewingPerformance:Filters:Site:label')}
                  verticalLabel
                >
                  <InfiniteDropdowns.Nurseries
                    value={nursery}
                    onChange={onNurseryChange}
                  />
                </Form.Row>
              </Toolbar.Item>
            )}
            <Toolbar.Item>
              <Form.Row
                label={i18n.t('module:Enquiries:Report:ViewingPerformance:Filters:ViewingDate:label')}
                verticalLabel
              >
                <DatePicker
                  clearable={false}
                  value={dateRange}
                  range
                  onChange={onDateChange}
                />
              </Form.Row>
            </Toolbar.Item>
            <Toolbar.Item>
              <Form.Row
                label={i18n.t('module:Enquiries:Report:ViewingPerformance:Filters:Breakdown:label')}
                verticalLabel
              >
                <BreakdownSelect
                  value={breakdown}
                  onChange={onBreakdownChange}
                />
              </Form.Row>
            </Toolbar.Item>
          </Toolbar.Group>
        </Toolbar>
      )}
      isOrganizationContext={isOrganizationContext}
      isPageLoading={isFetching && isStatisticsFetching && 1 === page}
      isStatisticsLoading={isStatisticsFetching}
      isTableLoading={isFetching}
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      statistics={statistics}
      tableColumns={tableColumns}
      tableData={tableData}
      title={i18n.t('module:Enquiries:Report:ViewingPerformance:title')}
      totalResults={totalResults}
      hideExport
      onPageChange={onPageChange}
    />
  )
}

const mapState = (state, {
  appSelectors,
  enquiryReportsSelectors,
  enquiryReportsViewingPerformanceState,
  enquiryReportsViewingPerformanceStatisticsState,
  securitySelectors,
}) => ({
  appProperties: appSelectors.getApplicationProperties(state),
  errorMessages: appSelectors.getErrorMessages(enquiryReportsViewingPerformanceState),
  isFetching: appSelectors.getIsFetching(enquiryReportsViewingPerformanceState),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
  isStatisticsFetching: appSelectors.getIsFetching(enquiryReportsViewingPerformanceStatisticsState),
  statisticsData: enquiryReportsSelectors.getEnquiryReportsViewingPerformanceStatisticsDataSelector(state),
  totalResults: appSelectors.getTotalResults(enquiryReportsViewingPerformanceState),
  viewingPerformanceData: enquiryReportsSelectors.getEnquiryReportsViewingPerformanceDataSelector(state),
})

const enhance = compose(
  withRouter,
  withAppService,
  withEnquiryReportsService,
  withPaginationUtils,
  withSecurityService,
  connect(mapState),
)

export default enhance(ViewingPerformanceContainer)
