import * as listActions from './list/actions'
import * as listMontessoriActions from './listMontessori/actions'
import * as historyActions from './history/actions'
import * as historyMontessori from './historyMontessori/actions'

export default {
  ...historyActions,
  ...historyMontessori,
  ...listActions,
  ...listMontessoriActions,
}
