import { ROLES } from 'constants/security'

const ManagementLearningWrapper = ({ children }) => children

ManagementLearningWrapper.authParams = {
  roles: [
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.SUPER_ADMIN,
  ],
}

export default ManagementLearningWrapper
