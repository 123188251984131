import i18n from 'translations'

import FormativeReportsCreate from 'module/Learning/FormativeReports/FormativeReportsCreate'
import FormativeReportsList from 'module/Learning/FormativeReports/FormativeReportsList'
import FormativeReportsPreview from 'module/Learning/FormativeReports/FormativeReportsPreview'
import Learning from 'module/Learning/index'
import NextStepsList from 'module/Learning/NextSteps/NextStepsList'
import ObservationsAdd from 'module/Learning/Observations/ObservationsAdd'
import ObservationsEdit from 'module/Learning/Observations/ObservationsEdit'
import ObservationsList from 'module/Learning/Observations/ObservationsList'
import ObservationsReview from 'module/Learning/Observations/ObservationsReview'
import ObservationsWrapper from 'module/Learning/Observations'
import ObservationFrameworks from 'module/Learning/Observations/ObservationFrameworks'
import ObservationNextSteps from 'module/Learning/Observations/ObservationNextSteps'
import ObservationTracker from 'module/Learning/ObservationTracker'
import ProgressTrackerGenerator from 'module/Learning/CohortTracking/ProgressTrackerGenerator'
import ComparisonReportGenerator from 'module/Learning/CohortTracking/ComparisonReportGenerator'
import ProgressTrackerReport from 'module/Learning/CohortTracking/ProgressTrackerReport'
import ComparisonReport from 'module/Learning/CohortTracking/ComparisonReport'
import CohortTrackingList from 'module/Learning/CohortTracking/CohortTrackingList'
import CohortTrackingWrapper from 'module/Learning/CohortTracking/CohortTrackingWrapper'

/* eslint max-len:0 */
export const ROUTE_NAMES = {
  'LEARNING.COHORT_TRACKING': 'LEARNING.COHORT_TRACKING',
  'LEARNING.COHORT_TRACKING.COMPARISON_REPORT': 'LEARNING.COHORT_TRACKING.COMPARISON_REPORT',
  'LEARNING.COHORT_TRACKING.COMPARISON_REPORT.PREVIEW': 'LEARNING.COHORT_TRACKING.COMPARISON_REPORT.PREVIEW',
  'LEARNING.COHORT_TRACKING.COMPARISON_REPORT.REPORT': 'LEARNING.COHORT_TRACKING.COMPARISON_REPORT.REPORT',
  'LEARNING.COHORT_TRACKING.PROGRESS_TRACKER': 'LEARNING.COHORT_TRACKING.PROGRESS_TRACKER',
  'LEARNING.COHORT_TRACKING.PROGRESS_TRACKER.PREVIEW': 'LEARNING.COHORT_TRACKING.PROGRESS_TRACKER.PREVIEW',
  'LEARNING.COHORT_TRACKING.PROGRESS_TRACKER.REPORT': 'LEARNING.COHORT_TRACKING.PROGRESS_TRACKER.REPORT',
  'LEARNING.FORMATIVE_REPORTS': 'LEARNING.FORMATIVE_REPORTS',
  'LEARNING.FORMATIVE_REPORTS.CREATE': 'LEARNING.FORMATIVE_REPORTS.CREATE',
  'LEARNING.FORMATIVE_REPORTS.PREVIEW': 'LEARNING.FORMATIVE_REPORTS.PREVIEW',
  'LEARNING.INDEX': 'LEARNING.INDEX',
  'LEARNING.NEXT_STEPS': 'LEARNING.NEXT_STEPS',
  'LEARNING.OBSERVATIONS': 'LEARNING.OBSERVATIONS',
  'LEARNING.OBSERVATIONS.ADD': 'LEARNING.OBSERVATIONS.ADD',
  'LEARNING.OBSERVATIONS.ADD_FROM_HOME': 'LEARNING.OBSERVATIONS.ADD_FROM_HOME',
  'LEARNING.OBSERVATIONS.EDIT': 'LEARNING.OBSERVATIONS.EDIT',
  'LEARNING.OBSERVATIONS.EDIT.FRAMEWORKS': 'LEARNING.OBSERVATIONS.EDIT.FRAMEWORKS',
  'LEARNING.OBSERVATIONS.EDIT.NEXT_STEPS': 'LEARNING.OBSERVATIONS.EDIT.NEXT_STEPS',
  'LEARNING.OBSERVATIONS.OBSERVATION_TRACKER': 'LEARNING.OBSERVATIONS.OBSERVATION_TRACKER',
  'LEARNING.OBSERVATIONS.REVIEW': 'LEARNING.OBSERVATIONS.REVIEW',
}

const LearningRouting = {
  childRoutes: [
    {
      childRoutes: [
        {
          indexRoute: {
            component: ObservationsEdit,
          },
          name: ROUTE_NAMES['LEARNING.OBSERVATIONS.ADD'],
          path: 'add',
          title: 'Make observation',
        },
        {
          component: ObservationsAdd,
          name: ROUTE_NAMES['LEARNING.OBSERVATIONS.ADD_FROM_HOME'],
          path: 'add-from-home-observation/:homeObservationId',
          title: 'Add home observation',
        },
        {
          childRoutes: [
            {
              childRoutes: [
                {
                  childRoutes: [
                    {
                      component: ObservationFrameworks,
                      name: ROUTE_NAMES['LEARNING.OBSERVATIONS.EDIT.FRAMEWORKS'],
                      path: 'frameworks',
                      title: i18n.t('module:Learning:Observations:ObservationFrameworks:title'),
                    },
                    {
                      component: ObservationNextSteps,
                      name: ROUTE_NAMES['LEARNING.OBSERVATIONS.EDIT.NEXT_STEPS'],
                      path: 'next-steps',
                      title: i18n.t('module:Learning:Observations:ObservationNextSteps:title'),
                    },
                  ],
                  path: 'childObservation/:childObservationId',
                },
              ],
              indexRoute: {
                component: ObservationsEdit,
              },
              name: ROUTE_NAMES['LEARNING.OBSERVATIONS.EDIT'],
              path: 'edit',
              title: 'Edit observation',
            },
          ],
          indexRoute: {
            component: ObservationsReview,
          },
          name: ROUTE_NAMES['LEARNING.OBSERVATIONS.REVIEW'],
          path: ':observationId',
          title: 'Review observation',
        },
      ],
      component: ObservationsWrapper,
      indexRoute: {
        component: ObservationsList,
      },
      name: ROUTE_NAMES['LEARNING.OBSERVATIONS'],
      path: 'observations',
      title: i18n.t('module:Learning:Observations:title'),
    },
    {
      component: NextStepsList,
      name: ROUTE_NAMES['LEARNING.NEXT_STEPS'],
      path: 'next-steps',
      title: i18n.t('module:Learning:NextSteps:title'),
    },
    {
      childRoutes: [
        {
          childRoutes: [
            {
              indexRoute: {
                component: ComparisonReport,
              },
              name: ROUTE_NAMES['LEARNING.COHORT_TRACKING.COMPARISON_REPORT.PREVIEW'],
              path: 'preview',
              title: i18n.t('module:Learning:routing:preview'),
            },
            {
              indexRoute: {
                component: ComparisonReport,
              },
              name: ROUTE_NAMES['LEARNING.COHORT_TRACKING.COMPARISON_REPORT.REPORT'],
              path: 'report/:id',
              title: i18n.t('module:Learning:routing:preview'),
            },
          ],
          indexRoute: {
            component: ComparisonReportGenerator,
          },
          name: ROUTE_NAMES['LEARNING.COHORT_TRACKING.COMPARISON_REPORT'],
          path: 'comparison-report',
          title: i18n.t('module:Learning:routing:comparisonReport'),
        },
        {
          childRoutes: [
            {
              indexRoute: {
                component: ProgressTrackerReport,
              },
              name: ROUTE_NAMES['LEARNING.COHORT_TRACKING.PROGRESS_TRACKER.PREVIEW'],
              path: 'preview',
              title: i18n.t('module:Learning:routing:preview'),
            },
            {
              indexRoute: {
                component: ProgressTrackerReport,
              },
              name: ROUTE_NAMES['LEARNING.COHORT_TRACKING.PROGRESS_TRACKER.REPORT'],
              path: 'report/:id',
              title: i18n.t('module:Learning:routing:preview'),
            },
          ],
          indexRoute: {
            component: ProgressTrackerGenerator,
          },
          name: ROUTE_NAMES['LEARNING.COHORT_TRACKING.PROGRESS_TRACKER'],
          path: 'progress-tracker',
          title: i18n.t('module:Learning:routing:progressTracker'),
        },
      ],
      component: CohortTrackingWrapper,
      indexRoute: {
        component: CohortTrackingList,
      },
      name: ROUTE_NAMES['LEARNING.COHORT_TRACKING'],
      path: 'cohort-tracking',
      title: i18n.t('module:Learning:routing:cohortTracking'),
    },
    {
      component: ObservationTracker,
      name: ROUTE_NAMES['LEARNING.OBSERVATIONS.OBSERVATION_TRACKER'],
      path: 'observation-tracker',
      title: i18n.t('module:Learning:ObservationTracker:title'),
    },
    {
      childRoutes: [
        {
          component: FormativeReportsCreate,
          name: ROUTE_NAMES['LEARNING.FORMATIVE_REPORTS.CREATE'],
          path: 'create',
          title: i18n.t('module:Learning:FormativeReports:FormativeReportsCreate:title'),
        },
        {
          component: FormativeReportsPreview,
          name: ROUTE_NAMES['LEARNING.FORMATIVE_REPORTS.PREVIEW'],
          path: ':formativeReportId/preview',
          title: i18n.t('module:Learning:FormativeReports:FormativeReportsPreview:title'),
        },
      ],
      indexRoute: {
        component: FormativeReportsList,
      },
      name: ROUTE_NAMES['LEARNING.FORMATIVE_REPORTS'],
      path: 'formative-reports',
      title: i18n.t('module:Learning:FormativeReports:title'),
    },
  ],
  component: Learning,
  name: ROUTE_NAMES['LEARNING.INDEX'],
  path: 'learning',
  title: i18n.t('module:Learning:routing:learning'),
}

LearningRouting.ROUTE_NAMES = ROUTE_NAMES

export default LearningRouting
