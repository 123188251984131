export const GET_CHILD_DISCOUNT = 'GET_CHILD_DISCOUNT'
export const GET_CHILD_DISCOUNT_SUCCESS = 'GET_CHILD_DISCOUNT_SUCCESS'
export const GET_CHILD_DISCOUNT_FAILED = 'GET_CHILD_DISCOUNT_FAILED'

export const CREATE_CHILD_DISCOUNT = 'CREATE_CHILD_DISCOUNT'
export const CREATE_CHILD_DISCOUNT_SUCCESS = 'CREATE_CHILD_DISCOUNT_SUCCESS'
export const CREATE_CHILD_DISCOUNT_FAILED = 'CREATE_CHILD_DISCOUNT_FAILED'

export const UPDATE_CHILD_DISCOUNT = 'UPDATE_CHILD_DISCOUNT'
export const UPDATE_CHILD_DISCOUNT_SUCCESS = 'UPDATE_CHILD_DISCOUNT_SUCCESS'
export const UPDATE_CHILD_DISCOUNT_FAILED = 'UPDATE_CHILD_DISCOUNT_FAILED'

export const CLEAR_CHILD_DISCOUNT = 'CLEAR_CHILD_DISCOUNT'
