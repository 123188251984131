import _ from 'lodash'

import React, { useEffect } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'
import { formValueSelector } from 'redux-form'

import auth from 'utils/auth'

import { withAppService, withAppServiceProps } from 'services/app'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withPaginationUtils, withPaginationUtilsProps } from 'services/utils/pagination'
import { withSecurityService, withSecurityServiceProps } from 'services/security'
import { withRouter, withRouterProps } from 'services/router'
import { withRouteUtilsProps } from 'services/utils/router'
import {
  withChildService,
  withChildServiceProps,
  withLegacyChildBankDetailsService,
  withLegacyChildBankDetailsServiceProps,
} from 'services/legacy/child'

import { getInitialValues } from './ChildFinanceAddContactHelpers'
import ChildFinanceAddContactView from './ChildFinanceAddContactView'
import { CHILD_FINANCE_ADD_CONTACT_FORM } from './components/ChildFinanceAddContactForm'

const CHILD_GROUPS = {
  read: [
    'child.carerChildRelations',
    'carerChildRelation.carer',
    'carerChildRelation.details',
    'carer',
    'carer.address',
    'carer.details',
  ],
}
const CHILD_BANK_DETAILS_GROUPS = {
  read: [
    'child.childBankDetail',
    'childBankDetail',
    'childBankDetail.paymentContact',
    'childBankDetail.invoiceRecipients',
    'childBankDetail.fundingLoopContact',
    'childBankDetail.notes',
    'child.carerChildRelations',
    'carerChildRelation.carer',
    'carerChildRelation.details',
    'carer',
    'carer.address',
    'carer.details',
  ],
}

type ChildFinanceAddContactContainerProps = withAppServiceProps
  & withSecurityServiceProps
  & withChildServiceProps
  & withRouteUtilsProps
  & withRouterProps
  & withPaginationUtilsProps
  & withModalServiceProps
  & withLegacyChildBankDetailsServiceProps

const mapState = (state, { appSelectors, childBankDetailsState, childSelectors, childSingleState }) => ({
  child: childSelectors.getChildSelector(state),
  childBankDetailsState: childSelectors.getContactDetailsSelector(state),
  contactData: childSelectors.getContactDetailsSelector(state),
  errorMessages: appSelectors.getErrorMessages(childSingleState, childBankDetailsState),
  isCustomEmail: formValueSelector(CHILD_FINANCE_ADD_CONTACT_FORM)(state, 'isCustomEmail'),
  isEdit: childSelectors.isBankDetailsExists(state),
  isEmailEdited: childSelectors.isContactEditedSelector(state),
  isFetching: appSelectors.getIsFetching(childSingleState, childBankDetailsState),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
  isSubmitting: appSelectors.getIsSubmitting(childBankDetailsState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const ChildFinanceAddContactContainer: React.FC<ChildFinanceAddContactContainerProps & PropsFromRedux> = ({
  child,
  childActions,
  contactData,
  errorMessages,
  isCustomEmail,
  isEdit,
  isEmailEdited,
  isFetching,
  isFinanceV3Enabled,
  isSubmitting,
  legacyChildBankDetailsActions,
  legacyChildBankDetailsSelectors,
  navigate,
  params,
}) => {
  useEffect(() => {
    childActions.get({
      params: [params.childId, {
        groups: CHILD_GROUPS,
      }],
    })

    childActions.getBankDetails({
      params: [params.childId, {
        groups: CHILD_BANK_DETAILS_GROUPS,
      }],
    })

    return () => {
      childActions.clearBankDetails()
    }
  }, [])

  const handleSubmitSuccess = () => {
    navigate(`/children/${params.childId}/finance/invoicing`)
  }

  const handleSubmit = (fields) => {
    const payload = legacyChildBankDetailsSelectors.getContactPayload(fields)

    if (isEdit || isEmailEdited) {
      return legacyChildBankDetailsActions.update(params.childId, payload, handleSubmitSuccess)
    }

    return legacyChildBankDetailsActions.create(params.childId, payload, handleSubmitSuccess)
  }

  const handleDeleteItemClick = (fields, index) => {
    fields.remove(index)
  }

  const carerList = _.orderBy(child?.carerChildRelations, ['carer.id'], ['asc'])
  const initialValues = getInitialValues(contactData, carerList, isEmailEdited)
  const isLoading = isFetching || !child

  return (
    <ChildFinanceAddContactView
      carerList={carerList}
      child={child}
      errorMessages={errorMessages}
      initialValues={initialValues}
      isCustomEmail={isCustomEmail}
      isFinanceV3Enabled={isFinanceV3Enabled}
      isLoading={isLoading}
      isSubmitting={isSubmitting}
      onDeleteItemClick={handleDeleteItemClick}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withPaginationUtils,
  withSecurityService,
  withChildService,
  withLegacyChildBankDetailsService,
  connector,
)

export default enhance(ChildFinanceAddContactContainer)
