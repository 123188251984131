import _ from 'lodash'

import { createSelector } from 'reselect'

import { LIST_STATUS_FILTERS } from 'constants/filters'

import i18n from 'translations'
import { NurseryConsumablesRootState } from '../models'

export const getNurseryConsumablesSelector = (state: NurseryConsumablesRootState) => state.nurseryConsumables

export const getNurseryConsumablesListSelector = createSelector(
  [getNurseryConsumablesSelector],
  (state) => state.list.data,
)

export const getCriteria = (filters) => {
  const { active } = filters || {}

  const criteria = []

  if (active && active !== LIST_STATUS_FILTERS.ALL) {
    criteria.push({
      field: 'archived',
      value: active === LIST_STATUS_FILTERS.ARCHIVED,
    })
  }

  return criteria
}

export const getConsumablesOption = ({ archived, id, name }) => ({
  label: `${name}${archived ? ` (${i18n.t('global:archived')})` : ''}`,
  value: id,
})

export const getConsumablesListDropdown = createSelector(
  [getNurseryConsumablesListSelector],
  (data) => {
    if (!data) {
      return null
    }

    return _.map(data, getConsumablesOption)
  },
)
