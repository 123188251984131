import React from 'react'
import { Field } from 'redux-form'

import { DEPOSIT_TYPES } from 'services/legacy/deposits/constants'

import { isRequired } from 'utils/fieldValidation'

import { Form } from 'components'

import i18n from 'translations'

import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

interface DepositFormFieldsProps {
  childId?: string
  includeUsedDepositIds?: number[]
  isReadOnly?: boolean
  skipPendingDepositIds?: number[]
}

export interface DepositFormProps {
  deposit?: string
  total?: number
}

const DepositFormFields = ({
  childId,
  includeUsedDepositIds,
  isReadOnly,
  skipPendingDepositIds,
}: DepositFormFieldsProps): JSX.Element => (
  <React.Fragment>
    <Form.Row
      label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Deposit:label')}
      width={{ field: '400px' }}
      required
    >
      <Field
        childId={childId}
        component={Form.InfiniteDropdowns.Deposits}
        disabled={isReadOnly}
        includeUsedDepositIds={includeUsedDepositIds}
        name="deposit"
        placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Deposit:placeholder')}
        skipPendingDepositIds={skipPendingDepositIds}
        type={DEPOSIT_TYPES.DEPOSIT}
        validate={isRequired}
        pendingOnly
      />
    </Form.Row>
    <Form.Row
      label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Amount:label')}
      width={{ field: '150px' }}
      required
    >
      <SubdomainCurrencyProvider>
        {({ currencySymbol }) => (
          <Field
            component={Form.TextField}
            name="total"
            placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Amount:placeholder')}
            prefix={currencySymbol}
            prefixWidth="30px"
            type="number"
            validate={isRequired}
            disabled
          />
        )}
      </SubdomainCurrencyProvider>
    </Form.Row>
  </React.Fragment>
)

export default DepositFormFields
