import moment from 'moment'
import _ from 'lodash'

import React from 'react'

import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'

import { Avatar, DateString, DetailsBreakdown, FormatText, Typography } from 'components'

import i18n from 'translations'

const StaffDetailsBreakdown = ({
  editLink,
  isStaffingEnabled,
  membership,
  showCenteredPhoto,
  showHeader,
  showNurseries,
  showPrimaryContactHeader,
}) => {
  const {
    birthDate,
    email,
    firstName,
    membershipProfile,
    nurseries,
    photo,
    surname,
    username,
  } = membership || {}

  const {
    address,
    bio,
    dbsCheck,
    expert,
    mobile,
    payrollNumber,
    postCode,
    qualification,
    telephone,
  } = membershipProfile || {}

  const { certificateId, reviewBy, reviewDate } = dbsCheck || {}

  const renderCenteredPhoto = () => showCenteredPhoto && photo && (
    <DetailsBreakdown.Item>
      <Avatar
        avatarSize="big"
        src={_.isString(photo) ? photo : photo.value}
        withoutOptimization
      />
    </DetailsBreakdown.Item>
  )

  const renderPrimaryContactHeader = () => showPrimaryContactHeader && (
    <DetailsBreakdown.Header>
      <Typography fontSize={28} fontWeight={300}>
        {i18n.t('module:Nurseries:Add:PrimaryContactDetails:title')}
      </Typography>
    </DetailsBreakdown.Header>
  )

  const renderHeader = () => showHeader && (
    <DetailsBreakdown.Header edit={{ to: editLink }}>
      <Typography fontSize={28} fontWeight={300}>
        {i18n.t('module:Staff:StaffDetails:generalInformation')}
      </Typography>
    </DetailsBreakdown.Header>
  )

  const renderNurseries = () => showNurseries && (
    <DetailsBreakdown.Item
      label={i18n.t('module:Staff:StaffAdd:Fields:Nurseries:label')}
      value={_.join(_.map(nurseries, (nursery) => nursery.name), ', ')}
    />
  )

  return (
    <DetailsBreakdown>
      <DetailsBreakdown.Section>
        {renderHeader()}
        {renderPrimaryContactHeader()}
        {renderCenteredPhoto()}
        <DetailsBreakdown.Item
          label={i18n.t('module:Staff:StaffAdd:Fields:Name:label')}
          value={`${firstName || ''} ${surname || ''}`}
        />
        <DetailsBreakdown.Item
          label={i18n.t('module:Staff:StaffAdd:Fields:Username:label')}
          value={username}
        />
        <DetailsBreakdown.Item
          label={i18n.t('module:Staff:StaffAdd:Fields:Email:placeholder')}
          value={<a href={`mailto:${email}`}>{email}</a>}
        />
        {renderNurseries()}
        <DetailsBreakdown.Item
          label={i18n.t('module:Staff:StaffAdd:Fields:BirthDate:label')}
          value={<DateString date={birthDate} />}
        />
        <DetailsBreakdown.Item
          label={i18n.t('module:Staff:StaffAdd:Fields:PayrollNumber:label')}
          value={payrollNumber}
        />
        <DetailsBreakdown.Item
          label={i18n.t('module:Staff:StaffAdd:Fields:Qualification:label')}
          value={qualification}
        />
        {isStaffingEnabled && (
          <DetailsBreakdown.Item
            label={i18n.t('module:Staff:StaffAdd:Fields:OneThirteenQualification:label')}
            value={expert ? i18n.t('global:Yes') : i18n.t('global:No')}
          />
        )}
        <DetailsBreakdown.Item
          label={i18n.t('module:Staff:StaffAdd:Fields:Address:label')}
          value={<FormatText lines={[address, postCode]} />}
        />
        <DetailsBreakdown.Item
          label={i18n.t('module:Staff:StaffAdd:Fields:Telephone:placeholder')}
          value={telephone}
        />
        <DetailsBreakdown.Item
          label={i18n.t('module:Staff:StaffAdd:Fields:Mobile:placeholder')}
          value={mobile}
        />
        <DetailsBreakdown.Item
          label={i18n.t('module:Staff:StaffAdd:Fields:DbsCertificate:label')}
          value={(
            <FormatText
              lines={[
                certificateId,
                reviewBy,
                reviewDate && moment(reviewDate).format(DISPLAY_SHORT_DATE_FORMAT),
              ]}
            />
          )}
        />
        <DetailsBreakdown.Item
          label={i18n.t('module:Staff:StaffAdd:Fields:Bio:label')}
          value={bio}
        />
      </DetailsBreakdown.Section>
    </DetailsBreakdown>
  )
}

export default StaffDetailsBreakdown
