import React from 'react'
import { Link } from 'react-router'

import { Typography } from 'components'

import { StyledChildProfileLink, StyledComponent, StyledTitle } from './MissingInformationDietaryStyled'

const MissingInformationDietary = ({ childAddGranted = true, linkLabel, titleLabel, to }) => (
  <StyledComponent>
    <StyledTitle>
      <Typography bold>
        {titleLabel}
      </Typography>
    </StyledTitle>
    {childAddGranted && (
      <Typography>
        <Link to={to}>
          <StyledChildProfileLink>
            {linkLabel}
          </StyledChildProfileLink>
        </Link>
      </Typography>
    )}
  </StyledComponent>
)

export default MissingInformationDietary
