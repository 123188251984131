import _ from 'lodash'

import { createSelector } from 'reselect'

import { isMontessori } from 'services/security/nursery/selectors'

import i18n from 'translations'

import { ALL_OPTION, FRAMEWORK_TYPE_EYFS, FRAMEWORK_TYPE_MONTESSORI } from '../constants'

const getFrameworks = (state) => state.frameworks

export const getFrameworksListState = createSelector([getFrameworks], (state) => state.list)

export const getFrameworksListDataState = createSelector([getFrameworksListState], (state) => state.data)

export const getFilteredFrameworkRawListData = (applicable, data) => _.filter(data, ({ structure }) => (
  applicable ? _.find(structure?.applicable, (item) => item === applicable) : true
))

export const getFilteredFrameworkListData = (data) => _.map(data,
  ({ default: defaultFramework, id, name, type }) => ({
    defaultFramework,
    label: name,
    type,
    value: id,
  }))

export const getFilteredFrameworksRawList = (applicable) => createSelector(
  [getFrameworksListDataState],
  (state) => getFilteredFrameworkRawListData(applicable, state),
)

export const getFilteredFrameworksList = (applicable) => createSelector(
  [getFilteredFrameworksRawList(applicable)],
  (state) => getFilteredFrameworkListData(state),
)

export const getFrameworksListOptions = createSelector([getFrameworksListDataState], (state) => _.map(
  state,
  ({ id, name }) => ({
    label: name,
    value: id,
  }),
))

export const getFilteredFrameworksWithMontessoriList = (applicable) => createSelector(
  [getFilteredFrameworksList(applicable), isMontessori],
  (frameworks, isNurseryMontessori) => {
    const result = [...frameworks]

    if (isNurseryMontessori) {
      result.push({
        label: i18n.t('global:Montessori'),
        value: FRAMEWORK_TYPE_MONTESSORI,
      })
    }

    return result
  },
)

export const getFrameworkAreasList = (frameworkId) => createSelector([getFrameworksListState], (state) => (
  _.map(_.get(_.find(state.data, ({ id }) => frameworkId === id), 'areas', []), (item) => ({
    label: item.name,
    value: item.id,
  }))
))

export const getAreaDropdownOptions = createSelector(
  [getFrameworksListDataState],
  (listState) => {
    if (!listState) {
      return null
    }

    const framework = _.find(listState, { type: 'EYFS' })

    if (!framework) {
      return null
    }

    const { areas } = framework

    return _.map(areas, ({ groups, id, name }) => {
      const groupOptions = [
        ALL_OPTION,
        ..._.map(groups, ({ id: groupId, name: groupName }) => ({ label: groupName, value: groupId })),
      ]

      return {
        groupOptions,
        label: name,
        value: id,
      }
    })
  },
)

export const getAgeBandOptions = createSelector(
  [getFrameworksListDataState],
  (frameworks) => {
    if (!frameworks) {
      return null
    }

    const framework = _.find(frameworks, { type: 'EYFS' })

    if (!framework) {
      return null
    }

    const { areas } = framework
    const { groups: observationGroup } = areas[0]
    const { categories } = observationGroup[0]

    return _.map(
      categories,
      ({ fromMonths, id, name, toMonths }) => ({
        fromMonths,
        label: name,
        toMonths,
        value: id,
      }),
    )
  },
)

export const getFrameworkAreasOfLearningDropdown = createSelector(
  [getFrameworksListDataState],
  (frameworks) => {
    const areas = _.get(
      _.find(frameworks, ({ type }) => FRAMEWORK_TYPE_EYFS === type),
      'areas',
    )

    return _.map(areas, ({ groups, id, name }) => ({
      label: name,
      options: _.map(groups, (group) => ({
        label: group.name,
        value: group.id,
      })),
      value: id,
    }))
  },
)

export const getFrameworkAreasOfLearningWithAllDropdown = createSelector(
  [getFrameworkAreasOfLearningDropdown],
  (areasOfLearning) => ([
    { label: i18n.t('global:All'), value: 0 },
    ...areasOfLearning,
  ]),
)

export const formatTermKey = (termKey) => {
  const data = termKey.split('_')

  return `${_.upperFirst(String(data[1]).toLowerCase())} ${data[0]}`
}

