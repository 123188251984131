import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { UPDATE_ABSENCE_REASON_EVENT } from 'services/nurseries/constants'

import { generateRoute } from 'utils/routing'
import eventBus from 'utils/eventBus'

import { withAbsenceReasonsService } from 'services/legacy/absenceReasons'
import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withRouter } from 'services/router'

import i18n from 'translations'

import ChildAbsenceReasonsAddView from './ChildAbsenceReasonsAddView'

class ChildAbsenceReasonsAddContainer extends Component {
  constructor(props) {
    super(props)

    const { params } = props
    const { itemId } = params

    this.state = {
      isEdit: !!itemId,
    }
  }

  componentDidMount() {
    const { absenceReasonsActions, params } = this.props
    const { isEdit } = this.state
    const { itemId } = params

    if (isEdit) {
      absenceReasonsActions.get({
        params: [itemId],
      })
    }
  }

  componentWillUnmount() {
    const { absenceReasonsActions } = this.props

    absenceReasonsActions.clear()
  }

  handleSubmitSuccess = () => {
    const { navigate } = this.props

    eventBus.dispatch(UPDATE_ABSENCE_REASON_EVENT)

    navigate(generateRoute('MANAGEMENT.LEAVE_TYPES.CHILD_ABSENCE_REASONS'))
  }

  handleSubmit = (fields) => {
    const { absenceReasonsActions, absenceReasonsSelectors, params } = this.props
    const { isEdit } = this.state
    const { itemId } = params

    const body = absenceReasonsSelectors.getPayload(fields)

    if (isEdit) {
      return absenceReasonsActions.update({
        body,
        onSuccess: this.handleSubmitSuccess,
        params: [itemId, {}],
      })
    }

    return absenceReasonsActions.create({
      body,
      onSuccess: this.handleSubmitSuccess,
      params: [{}],
    })
  }

  handleArchiveChildAbsenceReason = (archive) => {
    const { absenceReasonsActions, params } = this.props
    const { itemId } = params

    return absenceReasonsActions.update({
      body: { archived: archive },
      onSuccess: this.handleSubmitSuccess,
      params: [itemId, {}],
    })
  }

  handleArchiveClick = (archive) => () => {
    const { modalActions, modalConsts } = this.props

    return modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: archive ? i18n.t('global:Archive') : i18n.t('global:Unarchive'),
      icon: archive ? 'archive' : 'unarchive',
      onConfirm: () => this.handleArchiveChildAbsenceReason(archive),
      text: archive
        ? i18n.t('module:Management:LeaveTypes:Child:Edit:Modal:archive')
        : i18n.t('module:Management:LeaveTypes:Child:Edit:Modal:unarchive'),
    })
  }

  render() {
    const {
      data,
      errorMessages,
      initialValues,
      isFetching,
      isSubmitting,
    } = this.props
    const { isEdit } = this.state

    return (
      <ChildAbsenceReasonsAddView
        data={data}
        errorMessages={errorMessages}
        initialValues={initialValues}
        isEdit={isEdit}
        isFetching={isFetching}
        isSubmitting={isSubmitting}
        onArchiveClick={this.handleArchiveClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  absenceReasonsSelectors,
  absenceReasonsSingleState,
  appSelectors,
}) => ({
  data: absenceReasonsSelectors.getAbsenceReasonsSingleSelector(state),
  errorMessages: appSelectors.getErrorMessages(absenceReasonsSingleState),
  initialValues: absenceReasonsSelectors.getInitialValues(state),
  isFetching: appSelectors.getIsFetching(absenceReasonsSingleState),
  isSubmitting: appSelectors.getIsSubmitting(absenceReasonsSingleState),
})

const enhance = compose(
  withAbsenceReasonsService,
  withAppService,
  withModalService,
  withRouter,
  connect(mapState),
)

export default enhance(ChildAbsenceReasonsAddContainer)
