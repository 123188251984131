import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { EVENTS, logEvent } from 'analytics'

import { constants as invoicesConstants, withInvoicesService } from 'services/legacy/invoices'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withNurseriesService } from 'services/nurseries'
import { withInvoicePaymentsService } from 'services/legacy/invoicePayments'
import { withModalService } from 'services/utils/modal'
import { withRouter } from 'services/router'

import withInvoiceSendHandlers from 'module/Finance/withInvoiceSendHandlers'

import i18n from 'translations'

import ChildFinanceInvoicesPreviewView from './ChildFinanceInvoicesPreviewView'

const NURSERY_INVOICE_SETTINGS_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings.invoice',
    'nurseryInvoiceSettings',
    'nurseryInvoiceSettings.numbers',
    'invoiceNumbers',
    'nursery.organizationSettings',
    'organizationSettings',
    'organizationSettings.invoice',
    'organizationInvoiceSettings',
  ],
}

const INVOICE_PREVIEW_GROUPS = {
  read: [
    'invoicePreview.creditNotes',
    'creditNote',
    'invoice.sendLogs',
    'invoice.badDebt',
    'baseInvoice',
    'invoicePreview.rounded',
  ],
}

const CHILD_BANK_DETAILS_GROUPS = {
  read: [
    'child.childBankDetail',
    'childBankDetail',
    'childBankDetail.paymentContact',
    'childBankDetail.notes',
    'child.carerChildRelations',
    'carerChildRelation.carer',
    'child.carerChildRelations', // This group will maintain the child setup contact count
    'carer',
    'carer.address',
  ],
}

const CHILD_FINANCE_DETAILS_GROUPS = {
  read: [
    'child.finance',
    'child.carerChildRelations', // This group will maintain the child setup contact count
    'carerChildRelation.carer',
  ],
}

const CHILD_GROUPS = {
  read: [
    'child.carerChildRelations', // This group will maintain the child setup contact count
    'carerChildRelation.carer',
  ],
}

const PULLING_INTERVAL = 5000

class FinanceInvoicesPreviewContainer extends Component {
  constructor(props) {
    super(props)

    this.interval = null
  }

  componentDidMount() {
    const { childActions, nurseriesActions, nurseryOptions, params } = this.props
    const { childId } = params

    childActions.get({
      onSuccess: () => {
        childActions.getFinanceDetails({
          params: [+childId, {
            groups: CHILD_FINANCE_DETAILS_GROUPS,
          }],
        })

        childActions.getBankDetails({
          params: [+childId, {
            groups: CHILD_BANK_DETAILS_GROUPS,
          }],
        })
      },
      params: [+childId, {
        groups: CHILD_GROUPS,
      }],
    })

    nurseriesActions.get(nurseryOptions.id, {
      params: { groups: NURSERY_INVOICE_SETTINGS_GROUPS },
    })

    this.fetch()

    logEvent(EVENTS.INVOICE_PREVIEW_PAGE_VIEWED)
  }

  async componentWillUnmount() {
    const { childActions, invoicesActions } = this.props

    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
    }

    childActions.clearBankDetails()
    childActions.clearFinanceDetails()
    await invoicesActions.clearPreview()
  }

  componentDidUpdate(previousProps) {
    const { invoicePreview: previousInvoicePreview } = previousProps
    const { invoicePreview } = this.props

    const { invoice: previousInvoice } = previousInvoicePreview || {}
    const { invoice } = invoicePreview || {}

    const { status: previousInvoiceStatus } = previousInvoice || {}
    const { status } = invoice || {}

    if (previousInvoiceStatus !== status && invoicesConstants.STATUS_TYPES.SENDING === status) {
      this.interval = setInterval(this.fetchSendingInvoice, PULLING_INTERVAL)

      return
    }

    if (invoicesConstants.STATUS_TYPES.SENDING !== status && this.interval) {
      clearInterval(this.interval)
      this.interval = null
    }
  }

  fetchSendingInvoice = () => {
    const { invoicePreview, invoicesActions } = this.props
    const { invoice } = invoicePreview
    const { id } = invoice

    invoicesActions.autoRefresh(id, { groups: { read: ['invoice.sendLogs'] } })
  }

  fetch = () => {
    const { invoicesActions, params } = this.props
    const { invoiceId } = params

    invoicesActions.getPreview(
      invoiceId,
      { groups: INVOICE_PREVIEW_GROUPS },
    )
  }

  redirectToContact = () => {
    const { child, navigate } = this.props

    navigate(`/children/${child.id}/finance/contact`)
  }

  send = (newInvoiceNumber, modalErrorCallback) => {
    const { invoicePreview, invoicesActions, modalActions, modalConsts, params } = this.props
    const { invoice } = invoicePreview
    const { number, status } = invoice

    if (number) {
      modalActions.show(modalConsts.TYPES.ALERT, {
        text: i18n.t('module:Children:Child:Finance:Invoice:Add:Modals:sendingInvoice'),
      })
    }

    const apiParams = { groups: { read: ['invoice.sendLogs'] } }

    invoicesActions.update(
      params.invoiceId,
      { number: newInvoiceNumber, sendInvoice: true },
      apiParams,
      () => this.handleSendSuccess({
        eventType: status === invoicesConstants.STATUS_TYPES.DRAFT
          ? EVENTS.INVOICE_SEND_SUBMITTED
          : EVENTS.INVOICE_RESEND_SUBMITTED,
      }),
      (error) => this.handleSendConflict(error, modalErrorCallback),
    )
  }

  handleSendButtonClick = () => {
    const {
      child,
      contactData: { paymentEmail },
      invoicePreview,
      invoicesHelpers,
      invoicesSelectors,
      modalActions,
      modalConsts,
      sendHandlers,
    } = this.props
    const { firstName, id: childId } = child || {}
    const { invoice, isDraft } = invoicePreview
    const { endDate, number, startDate, status } = invoice

    const fieldErrors = invoicesHelpers.getMandatoryFieldError({
      invoicePeriod: [startDate, endDate],
      ...invoice,
    })

    if (fieldErrors) {
      const text = invoicesSelectors.createErrorText({
        code: invoicesConstants.ERROR_CODES.MISSING_FIELDS_VALIDATION,
        extra: fieldErrors,
      })

      modalActions.show(modalConsts.TYPES.ALERT, {
        icon: 'warning',
        label: i18n.t('global:ok'),
        text,
      })

      return
    }

    logEvent(
      status === invoicesConstants.STATUS_TYPES.DRAFT
        ? EVENTS.INVOICE_SEND_BTN_CLICKED
        : EVENTS.INVOICE_RESEND_BTN_CLICKED,
      { context: 'child profile invoice preview' },
    )

    sendHandlers.handleSendButtonClick({
      childId,
      firstName,
      invoiceNumber: number,
      isDraft,
      onConfirm: this.send,
      onMissingPaymentContact: this.redirectToContact,
      paymentEmail,
      showInvoiceNumberPopup: true,
    })
  }

  handleSendSuccess = ({
    eventType,
    text = i18n.t('module:Children:Child:Finance:Invoice:Add:Modals:sentInvoice'),
  }) => {
    const { modalActions, modalConsts } = this.props

    logEvent(eventType, { context: 'child profile invoice preview' })

    modalActions.hideAll()
    modalActions.show(modalConsts.TYPES.ALERT, { text })
  }

  handleSendConflict = (errorMessage, modalErrorCallback) => {
    const { sendHandlers } = this.props
    const { code } = errorMessage

    if (modalErrorCallback) {
      modalErrorCallback(errorMessage)

      return
    }

    sendHandlers.handleSendConflict({
      errorMessage,
      onClickCallback: (
        code === invoicesConstants.ERROR_CODES.MISSING_PAYMENT_CONTACT
          ? this.redirectToContact
          : undefined
      ),
    })
  }

  markAsSent = (newInvoiceNumber, modalErrorCallback) => {
    const { invoicesActions, params } = this.props

    invoicesActions.update(
      params.invoiceId,
      { markAsSent: true, number: newInvoiceNumber },
      {},
      () => this.handleSendSuccess({
        eventType: EVENTS.INVOICE_MARK_SENT_SUCCESSFUL,
        text: i18n.t('module:Children:Child:Finance:Invoice:Add:Modals:markAsSent'),
      }),
      (error) => this.handleSendConflict(error, modalErrorCallback),
    )
  }

  handleMarkAsSentClick = () => {
    const {
      child,
      invoicePreview,
      invoicesHelpers,
      invoicesSelectors,
      modalActions,
      modalConsts,
      sendHandlers,
    } = this.props
    const { firstName, id: childId } = child || {}
    const { invoice } = invoicePreview
    const { endDate, number, startDate } = invoice

    const fieldErrors = invoicesHelpers.getMandatoryFieldError({
      invoicePeriod: [startDate, endDate],
      ...invoice,
    })

    if (fieldErrors) {
      const text = invoicesSelectors.createErrorText({
        code: invoicesConstants.ERROR_CODES.MISSING_FIELDS_VALIDATION,
        extra: fieldErrors,
      })

      modalActions.show(modalConsts.TYPES.ALERT, {
        icon: 'warning',
        label: i18n.t('global:ok'),
        text,
      })

      return
    }

    sendHandlers.handleMarkAsSentClick({
      childId,
      firstName,
      invoiceNumber: number,
      onConfirm: this.markAsSent,
      showInvoiceNumberPopup: true,
    })

    logEvent(EVENTS.INVOICE_MARK_SENT_CLICKED, { context: 'child profile invoice preview' })
  }

  download = () => {
    const { invoicesActions, params } = this.props
    const { invoiceId } = params

    invoicesActions.download(invoiceId)

    logEvent(EVENTS.INVOICE_DOWNLOADED)
  }

  handleDownloadButtonClick = () => {
    this.download()
  }

  handleRepeatClick = () => {
    const { child, navigate, params } = this.props
    const { invoiceId } = params

    logEvent(EVENTS.INVOICE_REPEAT_BTN_CLICKED)

    return navigate(`${generateRoute('CHILDREN.CHILD.FINANCE.INVOICE.ADD', {
      childId: child.id,
    })}?repeat=${invoiceId}`)
  }

  handleEditBadDebtClick = () => {
    const { invoicePreview, modalActions, modalConsts } = this.props
    const { invoice } = invoicePreview

    modalActions.show(modalConsts.TYPES.BAD_DEBT, { invoice })
  }

  unmarkBadDebt = () => {
    const {
      invoicePreview,
      invoicesActions,
      invoicesSelectors,
    } = this.props
    const { invoice } = invoicePreview

    const { id } = invoice

    const apiParams = {
      groups: { write: ['invoice.badDebt'] },
    }
    const payload = invoicesSelectors.getBadDebtPayload({ unmark: true })

    invoicesActions.update(id, payload, apiParams, () => logEvent(EVENTS.INVOICE_UNMARK_BAD_DEBT_SUBMITTED))
  }

  handleMarkAsBadDebtClick = () => {
    const { invoicePreview, modalActions, modalConsts } = this.props
    const { invoice } = invoicePreview
    const { status } = invoice

    if (invoicesConstants.STATUS_TYPES.BAD_DEBT === status) {
      logEvent(EVENTS.INVOICE_UNMARK_BAD_DEBT_BTN_CLICKED)

      modalActions.show(modalConsts.TYPES.CONFIRM, {
        confirmButtonLabel: 'Ok',
        icon: 'warning',
        onConfirm: this.unmarkBadDebt,
        text: (
          <React.Fragment>
            {i18n.t('module:Children:Child:Finance:Invoice:Add:Modals:markAsBadDebt1')}
            <br />
            <br />
            {i18n.t('module:Children:Child:Finance:Invoice:Add:Modals:markAsBadDebt2')}
          </React.Fragment>
        ),
      })

      return
    }

    logEvent(EVENTS.INVOICE_MARK_BAD_DEBT_BTN_CLICKED)

    this.handleEditBadDebtClick()
  }

  handleIssueCreditNote = () => {
    const { child, invoicePreview, modalActions, modalConsts, navigate } = this.props
    const { invoice } = invoicePreview

    modalActions.show(modalConsts.TYPES.ISSUE_CREDIT_NOTE, {
      invoice,
      onConfirm: () => {
        navigate(`/children/${child.id}/finance/invoicing`)
      },
    })

    logEvent(EVENTS.INVOICE_ISSUE_CREDIT_NOTE_CLICKED)
  }

  render() {
    const {
      child,
      childFinanceDetails,
      errorMessages,
      invoicePreview,
      invoicesPreviewState,
    } = this.props

    const isLoading = invoicesPreviewState.isFetching || !invoicePreview

    return (
      <ChildFinanceInvoicesPreviewView
        child={child}
        childFinanceDetails={childFinanceDetails}
        errorMessages={errorMessages}
        invoicePreview={invoicePreview}
        isLoading={isLoading}
        onDownloadButtonClick={this.handleDownloadButtonClick}
        onEditBadDebtClick={this.handleEditBadDebtClick}
        onIssueCreditNote={this.handleIssueCreditNote}
        onMarkAsBadDebtClick={this.handleMarkAsBadDebtClick}
        onMarkAsSentClick={this.handleMarkAsSentClick}
        onRepeatClick={this.handleRepeatClick}
        onSendButtonClick={this.handleSendButtonClick}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childSelectors,
  invoicesPreviewState,
  invoicesSelectors,
  params,
}) => ({
  child: childSelectors.getChildSelector(state),
  childFinanceDetails: childSelectors.getFinanceDetailsSelector(state),
  contactData: childSelectors.getContactDetailsSelector(state),
  errorMessages: appSelectors.getErrorMessages(invoicesPreviewState),
  invoicePreview: invoicesSelectors.getInvoiceFormattedPreviewSelector(state),
  isFetching: appSelectors.getIsFetching(invoicesPreviewState),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const enhance = compose(
  withAppService,
  withChildService,
  withRouter,
  withNurseriesService,
  withInvoicePaymentsService,
  withInvoicesService,
  withModalService,
  connect(mapState),
  withInvoiceSendHandlers,
)

export default enhance(FinanceInvoicesPreviewContainer)
