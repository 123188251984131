import moment from 'moment'

import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { ALL_ROOMS } from 'services/legacy/rooms/constants'

import { isEndTimeGreaterThanStartTime, isRequired } from 'utils/fieldValidation'

import { Form } from 'components'

import i18n from 'translations'

export const EXCEPTIONAL_UNAVAILABLE_TIMES_MODAL_FORM = 'ExceptionalUnavailableTimesModalForm'

const ExceptionalUnavailableTimesModalForm = ({
  handleSubmit,
  isSubmitting,
  onCloseClick,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row
      label={i18n.t('global:Date')}
      verticalLabel
    >
      <Form.Row.Item>
        <Field
          component={Form.DatePicker}
          disabledDays={[{
            before: moment().toDate(),
          }]}
          name="date"
          validate={isRequired}
        />
      </Form.Row.Item>
    </Form.Row>
    <Form.Row
      label={i18n.t('global:Time')}
      multipleFieldsInARow
      verticalLabel
    >
      <Form.Row.Item>
        <Field
          clearable={false}
          component={Form.TimePicker}
          name="startTime"
          placeholder={i18n.t('global:startTime')}
          validate={isRequired}
        />
      </Form.Row.Item>
      <Form.Row.TextItem text={'-'} />
      <Form.Row.Item>
        <Field
          clearable={false}
          component={Form.TimePicker}
          name="endTime"
          placeholder={i18n.t('global:endTime')}
          validate={isRequired}
        />
      </Form.Row.Item>
    </Form.Row>
    <Form.Row
      label={i18n.t('module:Modals:RequestableExtraSessions:ExceptionalUnavailableTimes:teachingRoom')}
      verticalLabel
    >
      <Form.Row.Item>
        <Field
          component={Form.InfiniteDropdowns.Rooms}
          extraOptions={[ALL_ROOMS]}
          name="nurseryClass"
          validate={isRequired}
        />
      </Form.Row.Item>
    </Form.Row>
    <Form.FooterActions
      isSubmitting={isSubmitting}
      submitLabel={i18n.t('global:Save')}
      onCancelClick={onCloseClick}
    />
  </Form>
)

const validate = (fields) => {
  const errors = {}

  errors.endTime = isEndTimeGreaterThanStartTime(fields)

  return errors
}

export default reduxForm({
  form: EXCEPTIONAL_UNAVAILABLE_TIMES_MODAL_FORM,
  validate,
})(ExceptionalUnavailableTimesModalForm)
