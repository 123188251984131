import _ from 'lodash'

import React, { useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'

import { BasicModalProps } from 'modals'

import { withPaginationUtils, withPaginationUtilsProps } from 'services/utils/pagination'
import { withNurseriesService, withNurseriesServiceProps } from 'services/nurseries'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withAppService, withAppServiceProps } from 'services/app'
import { withMembershipsService, withMembershipsServiceProps } from 'services/legacy/memberships'

import AccessToLikesAndCommentsModalView from './AccessToLikesAndCommentsModalView'

const mapState = (state, { appSelectors, membershipsListState, membershipsSelectors, params }) => ({
  isFetching: appSelectors.getIsFetching(membershipsListState),
  memberships: membershipsSelectors.getMembershipsListDataSelector(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  totalResults: appSelectors.getTotalResults(membershipsListState),
})

const connector = connect(mapState)

export interface AccessToLikesAndCommentsModalProps {
  onSuccess: () => void
  selectedMembersInitial: number[]
}

type PropsFromRedux = ConnectedProps<typeof connector>

type AccessToLikesAndCommentsModalContainerFullProps = AccessToLikesAndCommentsModalProps
  & BasicModalProps
  & PropsFromRedux
  & withModalServiceProps
  & withAppServiceProps
  & withMembershipsServiceProps
  & withNurseriesServiceProps
  & withPaginationUtilsProps

const GROUPS = {
  read: [
    'membership.details',
  ],
}

const AccessToLikesAndCommentsModalContainer: React.FC<AccessToLikesAndCommentsModalContainerFullProps> = ({
  hideModal,
  isFetching,
  memberships,
  membershipsActions,
  membershipsSelectors,
  nurseriesActions,
  nurseryOptions,
  onSuccess,
  paginationUtils,
  selectedMembersInitial,
  totalResults,
}) => {
  const { getPageCount, onPageChange, page, setPageLocationQuery } = paginationUtils
  const pageCount = getPageCount(totalResults)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [selectedAll, setSelectedAll] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [selectedMembers, setSelectedMembers] = useState<number[]>(selectedMembersInitial || [])

  const handleMembershipsListSuccess = () => {
    if (selectedAll) {
      setSelectedMembers(_.map(memberships, ({ id }) => id))
    }
  }

  const fetch = () => {
    const criteria = membershipsSelectors.getMembershipsListCriteria({ search })

    return membershipsActions.list({
      mergeResult: 1 !== page,
      onSuccess: handleMembershipsListSuccess,
      params: {
        criteria,
        groups: GROUPS,
        order: 'id ASC',
        page,
      },
    })
  }

  useEffect(() => {
    fetch()
  }, [search, page])

  useEffect(() => {
    setPageLocationQuery(false)
  }, [])

  const handleCloseClick = () => {
    hideModal()
  }

  const handlePageChange = (newPage) => {
    onPageChange()(newPage)
  }

  const handleChangeSearch = (newSearch) => {
    setSearch(newSearch)
    onPageChange()(1)
  }

  const handleSelectAll = () => {
    setSelectedAll(!selectedAll)
    setSelectedMembers(!selectedAll ? _.map(memberships, ({ id }) => id) : [])
  }

  const handleSubmitFailed = () => {
    setIsSubmitting(false)
  }

  const handleSubmitSuccess = () => {
    handleCloseClick()
    onSuccess()
  }

  const handleSubmit = () => {
    const body = {
      likeAndCommentMembers: _.map(selectedMembers, (id) => ({
        id,
      })),
    }

    setIsSubmitting(true)

    nurseriesActions.updateLikesAndCommentsMembers({
      body,
      onFailed: handleSubmitFailed,
      onSuccess: handleSubmitSuccess,
      params: [nurseryOptions.id],
      silent: true,
    })
  }

  const handleChangeSelectedMembers = (id) => {
    let updatedSelectedMembers = []

    if (_.find(selectedMembers, (i) => i === id)) {
      updatedSelectedMembers = _.filter(selectedMembers, (i) => i !== id)
    } else {
      updatedSelectedMembers = [
        ...selectedMembers,
        id,
      ]
    }

    setSelectedMembers(updatedSelectedMembers)
  }

  return (
    <AccessToLikesAndCommentsModalView
      isFetching={isFetching}
      isSubmitting={isSubmitting}
      memberships={memberships}
      page={page}
      pageCount={pageCount}
      search={search}
      selectedAll={selectedAll}
      selectedMembers={selectedMembers}
      onChangeSearch={handleChangeSearch}
      onChangeSelectedMembers={handleChangeSelectedMembers}
      onCloseClick={handleCloseClick}
      onPageChange={handlePageChange}
      onSelectAll={handleSelectAll}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withAppService,
  withNurseriesService,
  withMembershipsService,
  withModalService,
  withPaginationUtils,
  connector,
)

export default enhance(AccessToLikesAndCommentsModalContainer)
