import {
  CLEAR_LEAVE_TYPES_LIST,
  LIST_LEAVE_TYPES,
  LIST_LEAVE_TYPES_FAILED,
  LIST_LEAVE_TYPES_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ leaveTypesApiClient }) => async (dispatch) => {
  const { params = {} } = options

  dispatch({
    params,
    type: LIST_LEAVE_TYPES,
  })

  await leaveTypesApiClient.list(params).then(
    (response) => {
      dispatch({
        payload: response,
        type: LIST_LEAVE_TYPES_SUCCESS,
      })
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: LIST_LEAVE_TYPES_FAILED,
      })
    },
  )
}

export const clearList = () => ({
  type: CLEAR_LEAVE_TYPES_LIST,
})
