import React from 'react'

import { Link } from 'react-router'

import { generateRoute } from 'utils/routing'

import {
  Banner,
  Button,
  Checkbox,
  InfiniteDropdowns,
  InfiniteScroll,
  Page,
  Spinner,
  Toolbar,
  Typography,
} from 'components'

import { BulkInvoicesTable, ErrorSummary } from './components'

const FinanceGenerateInvoicesPreviewView = ({
  childList,
  disableSaveButton,
  errors,
  hasMore,
  isLoading,
  onAmountClick,
  onChildSelected,
  onMarkAsSentClick,
  onNextFetch,
  onRoomChange,
  onSaveAndSendClick,
  onSaveAsDraftClick,
  onSelectAll,
  period,
  room,
  selected,
  selectedAll,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <InfiniteScroll
        dataLength={childList ? childList.length : 0}
        hasMore={hasMore}
        next={onNextFetch}
      >
        <BulkInvoicesTable
          childList={childList}
          period={period}
          selected={selected}
          onAmountClick={onAmountClick}
          onChildSelected={onChildSelected}
        />
      </InfiniteScroll>
    )
  }

  return (
    <Page.Section
      isLoading={isLoading}
      title="Generated invoices"
    >
      <p>
        {'Invoices you have just generated will show up here. Select an invoice to preview the details. '
          .concat('You can select and bulk send the invoices in the draft column. ')
          .concat('The draft invoices can be edited from the child’s account.')}
      </p>
      <p>
        <Banner.Info>
          {'For invoices that are currently ‘Sending...’ this may take a few moments... '}
          {'Please check the updated sending status from the'}
          <Link to={generateRoute('FINANCE.INVOICES')}>
            <Typography bold primary>&nbsp;invoices page</Typography>
          </Link>
          .
        </Banner.Info>
      </p>
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <InfiniteDropdowns.Rooms
              disabled={!disableSaveButton}
              value={room}
              onChange={onRoomChange}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      <ErrorSummary errors={errors} />
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <Checkbox
              label="Select all generated batch invoices"
              value={selectedAll}
              onChange={onSelectAll}
            />
          </Toolbar.Item>
        </Toolbar.Group>
        <Toolbar.Group>
          <Toolbar.Item>
            <Button
              disabled={disableSaveButton}
              hierarchy="tertiary"
              icon="save-as-draft"
              label="Save as draft"
              negativeMargins
              onClick={onSaveAsDraftClick}
            />
          </Toolbar.Item>
          <Toolbar.Item>
            <Button
              disabled={disableSaveButton}
              hierarchy="tertiary"
              icon="mark-as-sent"
              label="Mark as sent"
              negativeMargins
              onClick={onMarkAsSentClick}
            />
          </Toolbar.Item>
          <Toolbar.Item>
            <Button
              disabled={disableSaveButton}
              hierarchy="tertiary"
              icon="send"
              label="Send"
              negativeMargins
              onClick={onSaveAndSendClick}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      {renderContent()}
    </Page.Section>
  )
}

export default FinanceGenerateInvoicesPreviewView
