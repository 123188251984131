import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledPanelContainer = styled.section`
  display: inline-block;
  border-radius: 8px;
  background-color: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  overflow: hidden;
`

export const StyledPanelHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  overflow: hidden;
  padding: 5px 16px;
`

export const StyledPanelBody = styled.article`
  padding: 15px;
  display: grid;
  grid-gap: 18px;
`

export const StyledPanelItem = styled.section`
  display: block;
`

