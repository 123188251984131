import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'

const getNurseryIntegrationsPayoutState = (state: RootState) => state.nurseryIntegrations.single

export const getPayout = createSelector(
  [getNurseryIntegrationsPayoutState],
  ({ data }) => data,
)
