import React from 'react'
import { Field, FieldArray, reduxForm } from 'redux-form'

import { FOOD_CATEGORY, FOOD_TYPE } from 'services/legacy/foodMenu/constants'

import { isRequired } from 'utils/fieldValidation'

import { Button, Callout, Form, Space, Typography } from 'components'

import i18n from 'translations'

import {
  StyledActions,
  StyledColumnContainer,
  StyledFormRow,
  StyledRemoveButton,
  StyledTitle,
} from './FoodMenuFormStyled'

export const FOOD_MENU_FORM = 'FOOD_MENU_FORM'

const FoodRow = ({
  fields,
  foodCategory,
  foodType,
  form,
  formValues,
  i,
  isSubmitting,
  item,
}) => {
  const rawValue = formValues(form)[foodType][i]?.meal
  const name = `${item}.meal`

  return (
    <StyledFormRow key={i}>
      <Form.Row
        margin="0 0 10px 0"
        verticalLabel
      >
        <Field
          component={Form.InfiniteDropdowns.MenuItems}
          disabled={isSubmitting}
          fieldName={name}
          formName={form}
          maxMenuHeight={200}
          menuItemCategory={foodCategory.value}
          name={name}
          rawValue={rawValue}
          validate={isRequired}
          width={260}
          creatable
        />
      </Form.Row>
      <StyledRemoveButton>
        <Form.RemoveRowButton
          disabled={isSubmitting}
          trash
          onClick={() => fields.remove(i)}
        />
      </StyledRemoveButton>
    </StyledFormRow>
  )
}

const renderRows = ({
  fields,
  foodCategory,
  foodType,
  form,
  formValues,
  isSubmitting,
}) => (
  <React.Fragment>
    {fields.map((item, i) => (
      <FoodRow
        fields={fields}
        foodCategory={foodCategory}
        foodType={foodType}
        form={form}
        formValues={formValues}
        i={i}
        isSubmitting={isSubmitting}
        item={item}
      />
    ))}
    <Button.ActionButton
      disabled={isSubmitting}
      label={i18n.t('global:Add')}
      onClick={() => fields.push({})}
    />
  </React.Fragment>
)

const FoodMenuForm = ({
  errorMessages,
  foodCategory,
  form,
  formValues,
  handleSubmit,
  isSubmitting,
  onSubmit,
  session,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Callout content={errorMessages} error />
    <StyledColumnContainer>
      <div>
        <StyledTitle>
          {i18n.t('module:DailyDiary:Foods:foodMenu:GeneralMenu')}
        </StyledTitle>
        <FieldArray
          component={renderRows}
          name={FOOD_CATEGORY.SNACK.value === foodCategory.value ? FOOD_TYPE.SNACKS : FOOD_TYPE.MEATS}
          props={{
            foodCategory,
            foodType: FOOD_CATEGORY.SNACK.value === foodCategory.value ? FOOD_TYPE.SNACKS : FOOD_TYPE.MEATS,
            form,
            formValues,
            isSubmitting,
          }}
        />
        <Space space="18px" />
        <Form.Row
          label={(
            <Typography fontSize={14} bold>
              {i18n.t('global:Time')}
            </Typography>
          )}
          verticalLabel
        >
          <Field
            component={Form.TimePicker}
            name="date"
            validate={isRequired}
          />
        </Form.Row>
      </div>
      {FOOD_CATEGORY.SNACK.value !== foodCategory.value && (
        <div>
          <StyledTitle>
            {i18n.t('module:DailyDiary:Foods:foodMenu:VegetarianMenu')}
          </StyledTitle>
          <FieldArray
            component={renderRows}
            name={FOOD_TYPE.VEGGIES}
            props={{
              foodCategory,
              foodType: FOOD_TYPE.VEGGIES,
              form,
              formValues,
              isSubmitting,
            }}
          />
        </div>
      )}
    </StyledColumnContainer>
    <StyledActions>
      <Button
        isLoading={isSubmitting}
        label={`${i18n.t('global:Set')} ${session.label}`}
        negativeMargins
        submit
      />
    </StyledActions>
  </Form>
)

export default reduxForm()(FoodMenuForm)
