import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'
import { Membership } from 'services/legacy/memberships/models'
import { NurserySettingsLikesAndComments } from 'services/nurseries/models'

export const getNurseryContext = (state: RootState) => state.nurseryContext.common.data

const getNurserySettings = createSelector(
  [getNurseryContext],
  (nursery) => nursery?.nurserySettings,
)

export const getNurseryOpeningDays = createSelector(
  [getNurserySettings],
  (nursery) => nursery?.openingDays,
)

export const getSessionExpireTimeEnabled = createSelector([getNurserySettings], (state) => (
  state?.security.sessionExpireTimeEnabled
))

export const getSessionExpireTime = createSelector([getNurserySettings], (state) => (
  state?.security.sessionExpireTime
))

export const getNurseryStripeIntegration = createSelector(
  [getNurseryContext],
  (state) => state?.stripeNurseryIntegration,
)

export const getMembersWithAccessToLikesAndComments = createSelector(
  [getNurseryContext],
  (state): Membership[] => (
    state?.likeAndCommentMembers || []
  ),
)

export const getNurseryLikeAndCommentSettings = createSelector(
  [getNurserySettings],
  (nurserySettings): NurserySettingsLikesAndComments | null => {
    if (!nurserySettings?.likeAndComment) {
      return null
    }

    return nurserySettings.likeAndComment
  },
)

export const getAnnualizedInvoiceSettings = createSelector([getNurserySettings], (state) => (
  state?.invoice?.annualisedInvoiceSettings
))
