import color from 'color'

import defaultBranding from 'constants/defaultBranding'

import { contrastingColor } from 'utils/colors'

type brandingColorTypes =
  'primary-color'
  | 'secondary-color'
  | 'tertiary-color'
  | 'quaternary-color'
  | 'quinary-color'
  | 'senary-color'
  | 'primary-color-contrasted'
  | 'primary-color-lighten-10'
  | 'primary-color-lighten-20'
  | 'primary-color-rgba-1'
  | 'primary-color-rgba-2'

export const getBrandingColor = (name: brandingColorTypes) => (
  document.documentElement.style.getPropertyValue(`--${name}`) || undefined
)

export const injectCustomBranding = (subdomainIsBranded, parameters) => {
  let params = parameters

  if (!subdomainIsBranded) {
    params = defaultBranding
  }

  document.documentElement.style.setProperty('--primary-color', params.primaryColor)
  document.documentElement.style.setProperty('--secondary-color', params.secondaryColor)
  document.documentElement.style.setProperty('--tertiary-color', params.tertiaryColor)
  document.documentElement.style.setProperty('--quaternary-color', params.quaternaryColor)
  document.documentElement.style.setProperty('--quinary-color', params.quinaryColor)
  document.documentElement.style.setProperty('--senary-color', params.senaryColor)

  document.documentElement.style.setProperty('--primary-color-contrasted', contrastingColor(params.primaryColor))

  document.documentElement.style.setProperty(
    '--primary-color-lighten-10',
    color(params.primaryColor).lighten(0.2).hex(),
  )

  document.documentElement.style.setProperty(
    '--primary-color-lighten-20',
    color(params.primaryColor).lighten(0.2).hex(),
  )

  for (let i = 1; 3 >= i; i += 1) {
    document.documentElement.style.setProperty(
      `--primary-color-rgba-${i}`,
      color(params.primaryColor).alpha(+`0.${i}`).rgb().string(),
    )
  }
}
