import React from 'react'

import { Banner, Callout, Page, Section, Spinner } from 'components'

import i18n from 'translations'

import AddChildSessionsForm from './components/AddChildSessionsForm'

const ChildSessionsAddView = ({
  SessionCalculationAccess,
  allocationPeriodOptions,
  child,
  errorMessages,
  initialValues,
  isEdit,
  isFormLoading,
  isInvoiceAssigned,
  isLoading,
  nurserySessionsOptions,
  onAddItemClick,
  onDeleteButtonClick,
  onDeleteItemClick,
  onSubmit,
  openingDays,
  sessionCalculationOptions,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <Section
        actions={isEdit && (
          <Section.Actions options={[{ onClick: onDeleteButtonClick, type: 'delete' }]} />
        )}
        title={isEdit
          ? i18n.t('module:Children:Child:BookingPattern:Sessions:Add:editTitle')
          : i18n.t('module:Children:Child:BookingPattern:Sessions:Add:addTitle')}
      >
        {isEdit && isInvoiceAssigned && (
          <Banner.Warning>
            {i18n.t('module:Children:Child:BookingPattern:Sessions:Add:invoiceExistsWarningText')}
          </Banner.Warning>
        )}
        <AddChildSessionsForm
          SessionCalculationAccess={SessionCalculationAccess}
          allocationPeriodOptions={allocationPeriodOptions}
          childId={child.id}
          initialValues={initialValues}
          isInvoiceAssigned={isInvoiceAssigned}
          isLoading={isFormLoading}
          nurserySessionsOptions={nurserySessionsOptions}
          openingDays={openingDays}
          sessionCalculationOptions={sessionCalculationOptions}
          onAddItemClick={onAddItemClick}
          onDeleteItemClick={onDeleteItemClick}
          onSubmit={onSubmit}
        />
      </Section>
    )
  }

  return (
    <Page isLoading={isLoading}>
      <Page.Content>
        <Callout content={errorMessages} error />
        {renderContent()}
      </Page.Content>
    </Page>
  )
}

export default ChildSessionsAddView
