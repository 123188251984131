import { FEATURE_FLAGS, ROLES } from 'constants/security'

const SettingsInvoiceNumbersWrapper = ({ children }) => children

SettingsInvoiceNumbersWrapper.authParams = {
  flags: [FEATURE_FLAGS.FINANCE_AUTOMATION, FEATURE_FLAGS.FINANCE_MVP],
  roles: [ROLES.ORGANIZATION_DIRECTOR, ROLES.ORGANIZATION_FINANCE_ADMIN],
}

export default SettingsInvoiceNumbersWrapper
