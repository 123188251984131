import _ from 'lodash'
import { createSelector } from 'reselect'

const getHomeObservationsState = (state) => state.homeObservations

export const getHomeObservationsSingleState = createSelector([getHomeObservationsState], (state) => state.single)

export const getHomeObservationsSingleDataState = createSelector(
  [getHomeObservationsSingleState],
  (state) => state?.data,
)

export const getInitialValuesFromHomeObservation = (homeObservation) => {
  const media = _.map(homeObservation.media, (hoMedia) => {
    const children = homeObservation.child?.information?.canBePhotographed ? [homeObservation.child] : undefined

    return {
      ...hoMedia,
      children,
    }
  })

  return {
    basicForm: {
      homeObservation: homeObservation.id,
      media,
      observationDate: homeObservation.createdAt,
    },
    childrenForms: [
      {
        child: {
          id: homeObservation.child.id,
        },
        comments: `${homeObservation.carer.fullName}:\n${homeObservation.comments || 'No comment added.'}`,
      },
    ],
  }
}
