import React from 'react'

import { ACTIVITY_TYPE } from 'services/legacy/enquiries/activities/constants'

import { Callout, ModalBox, Spinner } from 'components'

import i18n from 'translations'

import ActivityDetailAdd from 'module/Enquiries/Detail/ActivityDetail/ActivityDetailAdd'

const ViewingEditModalView = ({
  enquiryId,
  errorMessages,
  isFetching,
  onCancelClick,
  onSubmitSuccess,
  showAroundId,
  showAroundInitialValues,
}) => {
  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    return (
      <ActivityDetailAdd
        enquiryId={enquiryId}
        formName="addEnquiryViewingModal"
        id={showAroundId}
        initialValues={showAroundInitialValues}
        notePlaceholder={i18n.t('module:Enquiries:List:Detail:Activity:Entry:Tabs:Viewing:Note:placeholder')}
        submitButtonLabel={i18n.t('global:Save')}
        type={ACTIVITY_TYPE.VIEWING.value}
        isInlineEditMode
        showDelete
        onCancelClick={onCancelClick}
        onSuccess={onSubmitSuccess}
      />
    )
  }

  return (
    <ModalBox
      title={i18n.t('module:Modals:Enquiry:ViewingEdit:title')}
      autoHeight
      onCloseClick={onCancelClick}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </ModalBox>
  )
}

export default ViewingEditModalView
