import _ from 'lodash'

import React from 'react'

import invoicesConstants from 'services/legacy/invoices/constants'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { Avatar, Currency, DataTable, DateString, EllipsisWithTooltip } from 'components'

import i18n from 'translations'

import { StyledTitle, StyledValue } from './StatisticsTableStyled'

const DISPLAY_NAME_WIDTH = 128

const StatisticsTable = ({
  isDepositEnabled,
  invoices,
  isFinanceV3Enabled,
  statistics = {},
}) => {
  const renderFirstPart = (isHeader) => (
    <React.Fragment>
      <DataTable.Column noBorder>
        <DataTable.Row width={150} gray>
          <StyledTitle>
            {i18n.t('global:Child:childRefCode')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && _.map(invoices, ({ child: { codeReference }, id }) => (
          <DataTable.Row
            key={`${id}_codeReference`}
            width={150}
            gray
          >
            <StyledValue>
              {codeReference}
            </StyledValue>
          </DataTable.Row>
        ))}
        {!isHeader && <DataTable.Row background={NEUTRAL_COLOURS.GRAY_TERTIARY} width={150} />}
      </DataTable.Column>
      <DataTable.Column noBorder>
        <DataTable.Row background={NEUTRAL_COLOURS.WHITE} width={170}>
          <StyledTitle>
            {i18n.t('global:Finance:InvoiceNumber')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && _.map(invoices, ({ id, number }) => (
          <DataTable.Row key={`${id}_number`} width={170}>
            <StyledValue>
              {number}
            </StyledValue>
          </DataTable.Row>
        ))}
        {!isHeader && <DataTable.Row background={NEUTRAL_COLOURS.GRAY_TERTIARY} width={170} />}
      </DataTable.Column>
      <DataTable.Column noBorder>
        <DataTable.Row width={170} borderTransparent gray>
          <StyledTitle>
            {i18n.t('global:Finance:InvoiceName')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && _.map(invoices, ({ displayName, id }) => (
          <DataTable.Row
            align="left"
            key={`${id}_name`}
            width={170}
            borderTransparent
            gray
          >
            <EllipsisWithTooltip text={displayName} width={DISPLAY_NAME_WIDTH} />
          </DataTable.Row>
        ))}
        {!isHeader && <DataTable.Row background={NEUTRAL_COLOURS.GRAY_TERTIARY} width={170} />}
      </DataTable.Column>
      <DataTable.Column noBorder>
        <DataTable.Row background={NEUTRAL_COLOURS.WHITE} width={130}>
          <StyledTitle>
            {i18n.t('global:Finance:InvoiceDate')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && _.map(invoices, ({ id, issueDate }) => (
          <DataTable.Row
            key={`${id}_issueDate`}
            width={130}
            borderTransparent
          >
            <StyledValue>
              <DateString date={issueDate} />
            </StyledValue>
          </DataTable.Row>
        ))}
        {!isHeader && <DataTable.Row background={NEUTRAL_COLOURS.GRAY_TERTIARY} width={130} />}
      </DataTable.Column>
      <DataTable.Column noBorder>
        <DataTable.Row width={130} gray>
          <StyledTitle>
            {i18n.t('global:Finance:CreatedDate')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && _.map(invoices, ({ id, processedDate, status }) => (
          <DataTable.Row
            key={`${id}_processedDate`}
            width={130}
            borderTransparent
            gray
          >
            <StyledValue>
              {status === invoicesConstants.STATUS_TYPES.DRAFT ? '-' : <DateString date={processedDate} />}
            </StyledValue>
          </DataTable.Row>
        ))}
        {!isHeader && <DataTable.Row background={NEUTRAL_COLOURS.GRAY_TERTIARY} width={130} />}
      </DataTable.Column>
      {isFinanceV3Enabled && (
        <DataTable.Column>
          <DataTable.Row background={NEUTRAL_COLOURS.WHITE} width={130}>
            <StyledTitle>
              {i18n.t('global:Finance:Packages')}
            </StyledTitle>
          </DataTable.Row>
          {!isHeader && _.map(invoices, ({ id, invoiceStatistics }) => invoiceStatistics && (
            <DataTable.Row key={id} width={130}>
              <StyledValue>
                { invoiceStatistics.totalPackages ? (<Currency value={invoiceStatistics.totalPackages} />) : '-'}
              </StyledValue>
            </DataTable.Row>
          ))}
          {!isHeader && (
            <DataTable.Row background={NEUTRAL_COLOURS.GRAY_TERTIARY} width={130}>
              <StyledTitle>
                { statistics?.totalPackages ? (<Currency value={statistics?.totalPackages} />) : '-'}
              </StyledTitle>
            </DataTable.Row>
          )}
        </DataTable.Column>
      )}
      <DataTable.Column noBorder={isFinanceV3Enabled}>
        <DataTable.Row width={130} gray>
          <StyledTitle>
            {isFinanceV3Enabled ? i18n.t('global:Finance:RegularSessions') : i18n.t('global:Finance:Sessions')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && _.map(invoices, ({ id, invoiceStatistics }) => invoiceStatistics && (
          <DataTable.Row gray={isFinanceV3Enabled} key={id} width={130}>
            <StyledValue>
              <Currency
                value={invoiceStatistics.totalSessions}
              />
            </StyledValue>
          </DataTable.Row>
        ))}
        {!isHeader && (
          <DataTable.Row background={NEUTRAL_COLOURS.GRAY_TERTIARY} width={130}>
            <StyledTitle>
              <Currency
                value={statistics?.totalSessions}
              />
            </StyledTitle>
          </DataTable.Row>
        )}
      </DataTable.Column>
      <DataTable.Column noBorder>
        <DataTable.Row width={130} gray>
          <StyledTitle>
            {isFinanceV3Enabled ? i18n.t('global:Finance:RegularConsumables') : i18n.t('global:Finance:Consumables')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && _.map(invoices, ({ id, invoiceStatistics }) => invoiceStatistics && (
          <DataTable.Row gray={!isFinanceV3Enabled} key={id} width={130}>
            <StyledValue>
              <Currency
                value={invoiceStatistics.totalConsumables}
              />
            </StyledValue>
          </DataTable.Row>
        ))}
        {!isHeader && (
          <DataTable.Row background={NEUTRAL_COLOURS.GRAY_TERTIARY} width={130}>
            <StyledTitle>
              <Currency
                value={statistics?.totalConsumables}
              />
            </StyledTitle>
          </DataTable.Row>
        )}
      </DataTable.Column>
      {isFinanceV3Enabled && (
        <DataTable.Column noBorder>
          <DataTable.Row width={130} gray>
            <StyledTitle>
              {i18n.t('global:Finance:RegularItems')}
            </StyledTitle>
          </DataTable.Row>
          {!isHeader && _.map(invoices, ({ id, invoiceStatistics }) => invoiceStatistics && (
            <DataTable.Row key={id} width={130} gray>
              <StyledValue>
                <Currency
                  value={invoiceStatistics.totalRegularItems}
                />
              </StyledValue>
            </DataTable.Row>
          ))}
          {!isHeader && (
            <DataTable.Row background={NEUTRAL_COLOURS.GRAY_TERTIARY} width={130}>
              <StyledTitle>
                <Currency
                  value={statistics?.totalRegularItems}
                />
              </StyledTitle>
            </DataTable.Row>
          )}
        </DataTable.Column>
      )}
      <DataTable.Column noBorder>
        <DataTable.Row background={NEUTRAL_COLOURS.WHITE} width={130}>
          <StyledTitle>
            {i18n.t('global:Finance:ExtraItems')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && _.map(invoices, ({ id, invoiceStatistics }) => invoiceStatistics && (
          <DataTable.Row key={id} width={130}>
            <StyledValue>
              <Currency
                value={invoiceStatistics.totalExtraItems}
              />
            </StyledValue>
          </DataTable.Row>
        ))}
        {!isHeader && (
          <DataTable.Row background={NEUTRAL_COLOURS.GRAY_TERTIARY} width={130}>
            <StyledTitle>
              <Currency
                value={statistics?.totalExtraItems}
              />
            </StyledTitle>
          </DataTable.Row>
        )}
      </DataTable.Column>
      <DataTable.Column noBorder>
        <DataTable.Row width={130} gray>
          <StyledTitle>
            {i18n.t('global:Finance:ExtraSessions')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && _.map(invoices, ({ id, invoiceStatistics }) => invoiceStatistics && (
          <DataTable.Row key={id} width={130} gray>
            <StyledValue>
              <Currency
                value={invoiceStatistics.totalExtraSessions}
              />
            </StyledValue>
          </DataTable.Row>
        ))}
        {!isHeader && (
          <DataTable.Row background={NEUTRAL_COLOURS.GRAY_TERTIARY} width={130}>
            <StyledTitle>
              <Currency
                value={statistics?.totalExtraSessions}
              />
            </StyledTitle>
          </DataTable.Row>
        )}
      </DataTable.Column>
      {isFinanceV3Enabled && (
        <DataTable.Column noBorder>
          <DataTable.Row background={NEUTRAL_COLOURS.WHITE} width={130}>
            <StyledTitle>
              {i18n.t('global:Finance:ExtraConsumables')}
            </StyledTitle>
          </DataTable.Row>
          {!isHeader && _.map(invoices, ({ id, invoiceStatistics }) => invoiceStatistics && (
            <DataTable.Row key={id} width={130}>
              <StyledValue>
                <Currency
                  value={invoiceStatistics.totalExtraConsumables}
                />
              </StyledValue>
            </DataTable.Row>
          ))}
          {!isHeader && (
            <DataTable.Row background={NEUTRAL_COLOURS.GRAY_TERTIARY} width={130}>
              <StyledTitle>
                <Currency
                  value={statistics?.totalExtraConsumables}
                />
              </StyledTitle>
            </DataTable.Row>
          )}
        </DataTable.Column>
      )}
      <DataTable.Column noBorder>
        <DataTable.Row width={130} gray>
          <StyledTitle>
            {i18n.t('global:Finance:Other')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && _.map(invoices, ({ id, invoiceStatistics }) => invoiceStatistics && (
          <DataTable.Row gray={isFinanceV3Enabled} key={id} width={130}>
            <StyledValue>
              <Currency
                value={invoiceStatistics.totalOther}
              />
            </StyledValue>
          </DataTable.Row>
        ))}
        {!isHeader && (
          <DataTable.Row background={NEUTRAL_COLOURS.GRAY_TERTIARY} width={130}>
            <StyledTitle>
              <Currency
                value={statistics?.totalOther}
              />
            </StyledTitle>
          </DataTable.Row>
        )}
      </DataTable.Column>
      <DataTable.Column noBorder>
        <DataTable.Row width={130} gray>
          <StyledTitle>
            {i18n.t('global:Finance:FundingDeficit')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && _.map(invoices, ({ id, invoiceStatistics }) => invoiceStatistics && (
          <DataTable.Row gray={!isFinanceV3Enabled} key={id} width={130}>
            <StyledValue>
              <Currency
                value={invoiceStatistics.totalFundingDeficit}
              />
            </StyledValue>
          </DataTable.Row>
        ))}
        {!isHeader && (
          <DataTable.Row background={NEUTRAL_COLOURS.GRAY_TERTIARY} width={130}>
            <StyledTitle>
              <Currency
                value={statistics?.totalFundingDeficit}
              />
            </StyledTitle>
          </DataTable.Row>
        )}
      </DataTable.Column>
      <DataTable.Column noBorder>
        <DataTable.Row width={130} gray>
          <StyledTitle>
            {i18n.t('global:Finance:FundingDeducted')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && _.map(invoices, ({ id, invoiceStatistics }) => invoiceStatistics && (
          <DataTable.Row gray={isFinanceV3Enabled} key={id} width={130}>
            <StyledValue>
              <Currency
                value={invoiceStatistics.totalFundingDeducted}
              />
            </StyledValue>
          </DataTable.Row>
        ))}
        {!isHeader && (
          <DataTable.Row background={NEUTRAL_COLOURS.GRAY_TERTIARY} width={130}>
            <StyledTitle>
              <Currency
                value={statistics?.totalFundingDeducted}
              />
            </StyledTitle>
          </DataTable.Row>
        )}
      </DataTable.Column>
      <DataTable.Column noBorder>
        <DataTable.Row width={130} gray>
          <StyledTitle>
            {i18n.t('global:Finance:Discounts')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && _.map(invoices, ({ id, invoiceStatistics }) => invoiceStatistics && (
          <DataTable.Row gray={!isFinanceV3Enabled} key={id} width={130}>
            <StyledValue>
              <Currency
                value={invoiceStatistics.totalDiscount}
              />
            </StyledValue>
          </DataTable.Row>
        ))}
        {!isHeader && (
          <DataTable.Row background={NEUTRAL_COLOURS.GRAY_TERTIARY} width={130}>
            <StyledTitle>
              <Currency
                value={statistics?.totalDiscount}
              />
            </StyledTitle>
          </DataTable.Row>
        )}
      </DataTable.Column>
      <DataTable.Column noBorder noHidden>
        <DataTable.Row width={130} gray>
          <StyledTitle>
            {i18n.t('global:Finance:FeeIncome')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && _.map(invoices, ({ id, invoiceStatistics }) => invoiceStatistics && (
          <DataTable.Row gray={isFinanceV3Enabled} key={id} width={130}>
            <StyledValue>
              <Currency
                value={invoiceStatistics.totalInvoices}
              />
            </StyledValue>
          </DataTable.Row>
        ))}
        {!isHeader && (
          <DataTable.Row background={NEUTRAL_COLOURS.GRAY_TERTIARY} width={130}>
            <StyledTitle>
              <Currency
                value={statistics?.totalInvoices}
              />
            </StyledTitle>
          </DataTable.Row>
        )}
      </DataTable.Column>
    </React.Fragment>
  )

  const renderSecondPart = (isHeader) => (
    <React.Fragment>
      <DataTable.Column noBorder>
        <DataTable.Row width={130} gray>
          <StyledTitle>
            {i18n.t('global:Finance:FundingIncome')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && _.map(invoices, ({ id, invoiceStatistics }) => invoiceStatistics && (
          <DataTable.Row key={id} width={130} gray>
            <StyledValue>
              <Currency
                value={invoiceStatistics.totalFundingIncome}
              />
            </StyledValue>
          </DataTable.Row>
        ))}
        {!isHeader && (
          <DataTable.Row background={NEUTRAL_COLOURS.GRAY_TERTIARY} width={130}>
            <StyledTitle>
              <Currency
                value={statistics?.totalFundingIncome}
              />
            </StyledTitle>
          </DataTable.Row>
        )}
      </DataTable.Column>
      <DataTable.Column noBorder>
        <DataTable.Row background={NEUTRAL_COLOURS.WHITE} width={130}>
          <StyledTitle senary>
            {i18n.t('global:Finance:TotalIncome')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && _.map(invoices, ({ id, invoiceStatistics }) => invoiceStatistics && (
          <DataTable.Row key={id} width={130}>
            <StyledValue senary>
              <Currency
                value={invoiceStatistics.totalIncome}
              />
            </StyledValue>
          </DataTable.Row>
        ))}
        {!isHeader && (
          <DataTable.Row background={NEUTRAL_COLOURS.GRAY_TERTIARY} width={130}>
            <StyledTitle>
              <Currency
                value={statistics?.totalIncome}
              />
            </StyledTitle>
          </DataTable.Row>
        )}
      </DataTable.Column>
      {isDepositEnabled && (
        <React.Fragment>
          <DataTable.Column noBorder>
            <DataTable.Row width={130} gray>
              <StyledTitle>
                {i18n.t('global:Finance:Deposit')}
              </StyledTitle>
            </DataTable.Row>
            {!isHeader && _.map(invoices, ({ id, invoiceStatistics }) => invoiceStatistics && (
              <DataTable.Row key={id} width={130} gray>
                <StyledValue>
                  <Currency
                    value={invoiceStatistics.totalDeposits}
                  />
                </StyledValue>
              </DataTable.Row>
            ))}
            {!isHeader && (
              <DataTable.Row background={NEUTRAL_COLOURS.GRAY_TERTIARY} width={130}>
                <StyledTitle>
                  <Currency
                    value={statistics?.totalDeposits}
                  />
                </StyledTitle>
              </DataTable.Row>
            )}
          </DataTable.Column>
          <DataTable.Column noBorder>
            <DataTable.Row background={NEUTRAL_COLOURS.WHITE} width={130}>
              <StyledTitle>
                {i18n.t('global:Finance:DepositRefund')}
              </StyledTitle>
            </DataTable.Row>
            {!isHeader && _.map(invoices, ({ id, invoiceStatistics }) => invoiceStatistics && (
              <DataTable.Row key={id} width={130}>
                <StyledValue>
                  <Currency
                    value={invoiceStatistics.totalRefundDeposits}
                  />
                </StyledValue>
              </DataTable.Row>
            ))}
            {!isHeader && (
              <DataTable.Row background={NEUTRAL_COLOURS.GRAY_TERTIARY} width={130}>
                <StyledTitle>
                  <Currency
                    value={statistics?.totalRefundDeposits}
                  />
                </StyledTitle>
              </DataTable.Row>
            )}
          </DataTable.Column>
        </React.Fragment>
      )}
      <DataTable.Column noBorder>
        <DataTable.Row width={130} gray>
          <StyledTitle>
            {i18n.t('global:Finance:UnpaidAmount')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && _.map(invoices, ({ id, invoiceStatistics }) => invoiceStatistics && (
          <DataTable.Row key={id} width={130} gray>
            <StyledValue red={0 < invoiceStatistics.totalOutstanding}>
              <Currency
                value={invoiceStatistics.totalOutstanding}
              />
            </StyledValue>
          </DataTable.Row>
        ))}
        {!isHeader && (
          <DataTable.Row background={NEUTRAL_COLOURS.GRAY_TERTIARY} width={130}>
            <StyledTitle>
              <StyledValue red={0 < statistics?.totalOutstanding}>
                <Currency
                  value={statistics?.totalOutstanding}
                />
              </StyledValue>
            </StyledTitle>
          </DataTable.Row>
        )}
      </DataTable.Column>
      <DataTable.Column noBorder>
        <DataTable.Row background={NEUTRAL_COLOURS.WHITE} width={130}>
          <StyledTitle>
            {i18n.t('global:Finance:Status')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && _.map(invoices, ({ id, status }) => (
          <DataTable.Row key={`${id}_status`} width={130}>
            <StyledValue>
              {invoicesConstants.STATUSES[status]}
            </StyledValue>
          </DataTable.Row>
        ))}
        {!isHeader && (
          <DataTable.Row background={NEUTRAL_COLOURS.GRAY_TERTIARY} width={130} />
        )}
      </DataTable.Column>
    </React.Fragment>
  )

  return (
    <div>
      <DataTable>
        <DataTable.Head borderRight>
          <DataTable.Row stickyElement>
            <StyledTitle>
              {_.upperFirst(i18n.t('global:child'))}
            </StyledTitle>
          </DataTable.Row>
          {_.map(invoices, ({ child: { firstName, photo, surname }, id }) => (
            <DataTable.Row key={id}>
              <Avatar
                avatarSize="small"
                initials={[firstName, surname]}
                src={photo}
                title={`${firstName} ${surname}`}
                primary
              />
            </DataTable.Row>
          ))}
          <DataTable.Row background={NEUTRAL_COLOURS.GRAY_TERTIARY}>
            <strong>
              {i18n.t('global:Finance:Total')}
            </strong>
          </DataTable.Row>
        </DataTable.Head>
        <DataTable.ContentScrollable
          data={[
            {
              data: renderFirstPart(),
              stickyHeader: () => [renderFirstPart(true), (
                <DataTable.Column noBorder>
                  <DataTable.Row background={NEUTRAL_COLOURS.WHITE} width={130}>
                    <StyledTitle>
                      {i18n.t('global:Finance:FeeIncome')}
                    </StyledTitle>
                  </DataTable.Row>
                </DataTable.Column>
              )],
            },
            {
              data: renderSecondPart(),
              stickyHeader: () => [renderSecondPart(true)],
            },
          ]}
          enableCustomScrollbar
        />
      </DataTable>
    </div>
  )
}

export default StatisticsTable
