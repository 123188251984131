/* eslint-disable no-plusplus */
import _ from 'lodash'
import { flatten } from 'utils/flatnest'

import React from 'react'

import colors, { NEUTRAL_COLOURS } from 'constants/colors'
import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { generateRoute } from 'utils/routing'
import { convertToPercent } from 'utils/data'

import { Hyperlink, ReportView, Typography } from 'components'

import i18n from 'translations'

import { NURSERY_TABLE_COLUMNS, ORGANIZATION_TABLE_COLUMNS } from './constants'
import { BREAKDOWN_TYPE } from '../components/BreakdownSelect'

export const getColumns = (isOrganizationContext) => {
  if (isOrganizationContext) {
    return ORGANIZATION_TABLE_COLUMNS
  }

  return NURSERY_TABLE_COLUMNS
}

const renderLabel = (total, value) => (
  <React.Fragment>
    {value}
    {' '}
    <Typography color={NEUTRAL_COLOURS.GRAY} fontSize="inherit" variant="span">
      {`(${convertToPercent(total, value)}%)`}
    </Typography>
  </React.Fragment>
)

export const getChartData = ({ data, hasError, isOrganizationContext }) => {
  const finalData = isOrganizationContext ? data : data?.[0]

  if (!finalData || hasError) {
    return null
  }

  return [
    {
      color: colors.standardPieChartColors[0],
      id: i18n.t('module:Enquiries:Report:ConversionRate:DataLabels:won'),
      percentage: convertToPercent(finalData.total, finalData.win),
      percentageLabel: renderLabel(finalData.total, finalData.win),
      value: finalData.win,
    },
    {
      color: colors.standardPieChartColors[1],
      id: i18n.t('module:Enquiries:Report:ConversionRate:DataLabels:lost'),
      percentage: convertToPercent(finalData.total, finalData.lost),
      percentageLabel: renderLabel(finalData.total, finalData.lost),
      value: finalData.lost,
    },
    {
      color: colors.standardPieChartColors[4],
      id: i18n.t('module:Enquiries:Report:ConversionRate:DataLabels:open'),
      percentage: convertToPercent(finalData.total, finalData.open),
      percentageLabel: renderLabel(finalData.total, finalData.open),
      value: finalData.open,
    },
  ]
}

const getNurseryLink = (nursery, appProperties, queryString) => (
  <ReportView.NurseryLink
    nursery={nursery}
    queryString={queryString}
    routeKey="ENQUIRIES.REPORT.CONVERSION_RATE"
  />
)

const getTableRow = ({ id, percentage, stage, value }) => ({
  id,
  percentage,
  stage: stage?.to
    ? (
      <Hyperlink
        target="_blank"
        to={stage.to}
        primary
      >
        {stage.label}
      </Hyperlink>
    )
    : stage,
  value,
})

export const getTableData = ({
  appProperties,
  data,
  enrolledStatusId,
  filters,
  hasError,
  isOrganizationContext,
  lostStatusId,
}) => {
  if (!data?.length || hasError) {
    return null
  }

  const queryString = new URLSearchParams(flatten({
    date: filters?.date ? {
      after: filters.date.after.format(DEFAULT_DATE_FORMAT),
      before: filters.date.before.format(DEFAULT_DATE_FORMAT),
    } : undefined,
  })).toString()

  if (isOrganizationContext) {
    if (filters?.breakdown === BREAKDOWN_TYPE.PERCENTAGE) {
      return _.map(data, ({ lost, nursery, open, total, win }) => ({
        lost: lost ? `${convertToPercent(total, lost)}%` : null,
        nurseryName: getNurseryLink(nursery, appProperties, queryString),
        open: open ? `${convertToPercent(total, open)}%` : null,
        total: '100%',
        win: win ? `${convertToPercent(total, win)}%` : null,
      }))
    }

    return _.map(data, ({ nursery, ...rest }) => ({
      nurseryName: getNurseryLink(nursery, appProperties, queryString),
      ...rest,
    }))
  }

  let index = 0

  const nurseryQueryString = new URLSearchParams(flatten({
    enquiryDate: filters?.date ? {
      after: filters.date.after.format(DEFAULT_DATE_FORMAT),
      before: filters.date.before.format(DEFAULT_DATE_FORMAT),
    } : undefined,
  })).toString()

  return [
    getTableRow({
      id: index++,
      percentage: '100%',
      stage: i18n.t('global:All'),
      value: data[0].total,
    }),
    getTableRow({
      id: index++,
      percentage: `${convertToPercent(data[0].total, data[0].win)}%`,
      stage: {
        label: i18n.t('module:Enquiries:Report:ConversionRate:DataLabels:won'),
        to: `${generateRoute('ENQUIRIES.LIST')}?${nurseryQueryString}&status=${enrolledStatusId}`,
      },
      value: data[0].win,
    }),
    getTableRow({
      id: index++,
      percentage: `${convertToPercent(data[0].total, data[0].lost)}%`,
      stage: {
        label: i18n.t('module:Enquiries:Report:ConversionRate:DataLabels:lost'),
        to: `${generateRoute('ENQUIRIES.LIST')}?${nurseryQueryString}&status=${lostStatusId}`,
      },
      value: data[0].lost,
    }),
    getTableRow({
      id: index++,
      percentage: `${convertToPercent(data[0].total, data[0].open)}%`,
      stage: {
        label: i18n.t('module:Enquiries:Report:ConversionRate:DataLabels:open'),
        to: `${generateRoute('ENQUIRIES.LIST')}?${nurseryQueryString}`,
      },
      value: data[0].open,
    }),
  ]
}
