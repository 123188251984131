import styled from 'styled-components'

export const StyledTimeBox = styled.div`
  display: inline-grid;
  grid-column-gap: 8px;
  grid-template-columns: 66px min-content;
  align-items: center;
  margin-top: 8px;
  white-space: nowrap;
`
