import { createSelector } from 'reselect'

const getRequestedExtraSessionsAvailabilityStatisticsSelector = (state) => (
  state.requestedExtraSessions.availabilityStatistics
)

export const getRequestedExtraSessionsAvailabilityStatistics = createSelector(
  [getRequestedExtraSessionsAvailabilityStatisticsSelector],
  (state) => state.data,
)
