import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as enquiryViewingsSelectors from './selectors'

const withEnquiryViewingsService = (WrappedComponent) => {
  const mapState = ({ enquiryViewings }) => ({ enquiryViewings })

  const mapDispatch = injectActions('enquiryViewingsActions', actions)

  const Component = ({ enquiryViewings, enquiryViewingsActions, ...other }) => (
    <WrappedComponent
      enquiryViewingsActions={enquiryViewingsActions}
      enquiryViewingsListState={enquiryViewings.list}
      enquiryViewingsSelectors={enquiryViewingsSelectors}
      enquiryViewingsSingleState={enquiryViewings.single}
      {...other}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withEnquiryViewingsService
