import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import * as actions from './statistics/actions'
import * as selectors from './statistics/selectors'

const withNurseriesStatisticsService = (WrappedComponent) => {
  const mapState = ({ nurseries }) => ({
    nurseries,
  })

  const mapDispatch = injectActions('statisticsActions', actions)

  const Component = (props) => {
    const { nurseries, statisticsActions, ...others } = props

    const nurseriesStatisticsActions = statisticsActions
    const nurseriesStatisticsState = nurseries.statistics
    const nurseriesStatisticsSelectors = selectors

    return (
      <WrappedComponent
        nurseriesStatisticsActions={nurseriesStatisticsActions}
        nurseriesStatisticsSelectors={nurseriesStatisticsSelectors}
        nurseriesStatisticsState={nurseriesStatisticsState}
        {...others}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withNurseriesStatisticsService
