import _ from 'lodash'

import { SUPPORTED_FILE_TYPES } from 'constants/mimetypes'

const isTypeOfMimeType = (type: string, fileTypes) => (
  _.find(fileTypes, ({ mimeTypes }) => _.includes(mimeTypes, type))
)

export const isImageMimeType = (type: string) => isTypeOfMimeType(type, SUPPORTED_FILE_TYPES.IMAGES)

export const isVideoMimeType = (type: string) => isTypeOfMimeType(type, SUPPORTED_FILE_TYPES.VIDEO)

export const isPdfMimeType = (type: string) => isTypeOfMimeType(type, SUPPORTED_FILE_TYPES.PDF)

export const isDocumentMimeType = (type: string) => isTypeOfMimeType(type, SUPPORTED_FILE_TYPES.DOCUMENTS)
