import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { Form } from 'components'

import i18n from 'translations'

export const MENU_ITEMS_ADD_FORM = 'MenuItemsAddForm'

const MenuItemsAddForm = ({
  cancelLink,
  disabled,
  handleSubmit,
  isEdit,
  isSubmitting,
  onSubmit,
  prefix,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row label={i18n.t(prefix.concat('Form:itemLabel'))}>
      <Form.Row.FlexItem flex="0 0 400px" mobileFull>
        <Field
          component={Form.TextField}
          disabled={disabled}
          name="name"
          placeholder={i18n.t(prefix.concat('Form:itemLabel'))}
          validate={isRequired}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.FooterActions
      cancelLink={cancelLink}
      isSubmitting={isSubmitting}
      submitDisabled={disabled}
      submitLabel={isEdit ? i18n.t('global:Update') : i18n.t('global:Save')}
    />
  </Form>
)

export default reduxForm({ form: MENU_ITEMS_ADD_FORM })(MenuItemsAddForm)
