import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { Type } from 'services/booking/periods/models'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withModalService } from 'services/utils/modal'
import { withNurseriesService } from 'services/nurseries'
import { withChildSessionsService } from 'services/legacy/childSessions'
import { withRouter } from 'services/router'
import { withPeriodsService } from 'services/booking/periods'

import i18n from 'translations'

import ChildSessionsAdd from 'module/Children/Child/ChildBookingPattern/ChildSessions/ChildSessionsAdd'

import ChildSessionsCurrentView from './ChildSessionsCurrentView'

const NURSERY_SETTINGS_GROUPS = { read: ['nursery.settings', 'nurserySettings'] }

class ChildSessionsCurrentContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      attendancePeriodExists: false,
    }
  }

  componentDidMount() {
    const {
      childSessionsActions,
      childSessionsSelectors,
      nurseriesActions,
      nurseryOptions,
      params,
      periodsActions,
      periodsSelectors,
    } = this.props
    const { childId } = params

    const apiParams = { groups: NURSERY_SETTINGS_GROUPS }

    nurseriesActions.get(nurseryOptions.id, {
      params: apiParams,
    })

    const criteria = childSessionsSelectors.getCriteria({ archived: 0 })

    childSessionsActions.groupedByList(childId, {
      criteria,
      includes: ['attendancePeriod', 'invoiced', 'fundingAssigned'],
    })

    const attendanceCriteria = periodsSelectors.getCriteria({
      archived: 0,
      type: Type.attendance,
    })

    periodsActions.list({
      onSuccess: this.handleGetPeriodsSuccess,
      onlyData: true,
      params: {
        criteria: attendanceCriteria,
        limit: 0,
      },
    })
  }

  handleGetPeriodsSuccess = (response) => {
    this.setState({ attendancePeriodExists: !!response?.meta?.total_results })
  }

  handleAddSessionClick = () => {
    const { child, modalActions, modalConsts, navigate } = this.props
    const { attendancePeriodExists } = this.state

    if (!attendancePeriodExists) {
      modalActions.show(modalConsts.TYPES.CONFIRM, {
        confirmButtonLabel: i18n.t('global:Setup'),
        icon: 'warning',
        onConfirm: () => navigate(generateRoute('MANAGEMENT.ATTENDANCE_PERIODS')),
        text: i18n.t('module:Children:Child:BookingPattern:Sessions:Add:attendancePeriodSetupPopup'),
      })

      return
    }

    navigate(generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS.ADD', { childId: child?.id }))
  }

  render() {
    const {
      authAccessMap,
      child,
      childSessions,
      childSessionsListState,
      errorMessages,
      isFetching,
      isOpeningDaysEmpty,
      nurseriesConstants,
    } = this.props

    const childCurrentSessions = childSessions?.current
    const childFutureSessions = childSessions?.future

    const isCurrentEmpty = childSessionsListState.isCurrentEmpty
    const isFutureEmpty = childSessionsListState.isFutureEmpty

    return (
      <ChildSessionsCurrentView
        authAccessMap={authAccessMap}
        child={child}
        childCurrentSessions={childCurrentSessions}
        childFutureSessions={childFutureSessions}
        errorMessages={errorMessages}
        isCurrentEmpty={isCurrentEmpty}
        isFutureEmpty={isFutureEmpty}
        isLoading={isFetching}
        isOpeningDaysEmpty={isOpeningDaysEmpty}
        nurseriesConstants={nurseriesConstants}
        onAddSessionClick={this.handleAddSessionClick}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childSelectors,
  childSessionsListState,
  childSessionsSelectors,
  nurseriesSelectors,
  params,
}) => ({
  authAccessMap: {
    module: {
      ChildSessionsAdd: auth.SELECTORS.getComponentIsAuthorised(state, ChildSessionsAdd),
    },
  },
  child: childSelectors.getChildSelector(state),
  childSessions: childSessionsSelectors.getChildSessionsListSelectors(state),
  errorMessages: appSelectors.getErrorMessages(childSessionsListState),
  isFetching: appSelectors.getIsFetching(childSessionsListState),
  isOpeningDaysEmpty: nurseriesSelectors.isOpeningDaysEmpty(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const enhance = compose(
  withRouter,
  withAppService,
  withChildService,
  withModalService,
  withNurseriesService,
  withChildSessionsService,
  withPeriodsService,
  connect(mapState),
)

export default enhance(ChildSessionsCurrentContainer)
