import styled from 'styled-components'

import layout from 'constants/layout'

export const StyledContainer = styled.div`
  height: 38px;
  border-radius: ${layout.borderRadius};
  background: #E2E0E0;
  padding: 4px 20px;
  text-align: center;
`

export const StyledTitle = styled.h6`
  font-size: 12px;
  color: #8F8F8F;
  margin: 0;
  padding: 0;
`

export const StyledValue = styled.h5`
  color: #565656;
  margin: 0;
  padding: 0;
`
