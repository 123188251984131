import React from 'react'

import { ModalBox } from 'components'

import ExportReportForm from './components/ExportReportForm'

const ExportReportModalView = ({
  includeDeputyManager,
  initialValues,
  isProcessing,
  keyWorkers,
  onCloseClick,
  onSubmit,
  title,
  userLabel,
}) => (
  <ModalBox
    title={title}
    autoHeight
    onCloseClick={onCloseClick}
  >
    <ExportReportForm
      includeDeputyManager={includeDeputyManager}
      initialValues={initialValues}
      isProcessing={isProcessing}
      keyWorkers={keyWorkers}
      userLabel={userLabel}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    />
  </ModalBox>
)

export default ExportReportModalView
