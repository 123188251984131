import _ from 'lodash'

import React from 'react'

import { ACCESS_LOGS_STATUSES } from 'services/legacy/authenticationLogs/constants'

import { DateString, EmptyState, InfiniteScroll, Page, Pagination, Spinner, Table } from 'components'

import i18n from 'translations'

export const ACCESS_LOGS_COLUMNS = [
  {
    align: 'left',
    field: 'time',
    title: _.upperFirst(i18n.t('global:time')),
  },
  {
    align: 'left',
    field: 'log',
    title: i18n.t('module:Management:Security:AccessLogsList:log'),
  },
]

const AccessLogsListView = ({
  accessLogs,
  isFetching,
  onPageChange,
  page,
  pageCount,
  totalResults,
}) => {
  const renderItem = (item) => {
    const { date, deviceToken, email, id, status, user } = item

    let log = ''

    if (null === user || ACCESS_LOGS_STATUSES.SUCCESS !== status) {
      log = i18n.t('module:Management:Security:AccessLogsList:attemptToLoginWithEmail', { email })
    } else if (!deviceToken) {
      log = i18n.t('module:Management:Security:AccessLogsList:loggedInOnAnUnapprovedDevice', {
        email: user.email,
        name: user.name,
      })
    } else {
      log = i18n.t('module:Management:Security:AccessLogsList:loggedInOnDevice', {
        device: deviceToken && deviceToken.name,
        email: user.email,
        name: user.name,
      })
    }

    return ({
      key: id,
      log,
      time: (
        <DateString date={date} relative />
      ),
    })
  }

  const renderContent = () => {
    if (isFetching && 1 === page) {
      return (
        <Spinner />
      )
    }

    if (!isFetching && (accessLogs && !accessLogs.length)) {
      return (
        <EmptyState
          icon="access-logs"
          text1={i18n.t('module:Management:Security:AccessLogsList:nothingFound')}
        />
      )
    }

    return (
      <React.Fragment>
        <Pagination
          totalResults={totalResults}
        />
        <InfiniteScroll
          dataLength={accessLogs ? accessLogs.length : 0}
          hasMore={page < pageCount}
          next={() => onPageChange((+page) + 1)}
        >
          <Table
            columns={ACCESS_LOGS_COLUMNS}
            data={_.map(accessLogs, renderItem)}
          />
        </InfiniteScroll>
      </React.Fragment>
    )
  }

  return (
    <Page.Section
      isLoading={isFetching && 1 === page}
      title={i18n.t('module:Management:Security:AccessLogsList:title')}
    >
      {renderContent()}
    </Page.Section>
  )
}

export default AccessLogsListView
