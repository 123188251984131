import _ from 'lodash'
import { createSelector } from 'reselect'

const getRelationsState = (state) => state.relations

export const getRelationsListState = createSelector(
  [getRelationsState],
  (state) => state.list.data,
)

export const getRelationsOptionsDropdownData = createSelector(
  [getRelationsListState],
  (relations) => {
    if (!relations?.length) {
      return null
    }

    return _.map(relations, ({ fullName, id, photo }) => ({
      avatar: photo,
      label: fullName,
      value: id,
    }))
  },
)

export const getListCriteria = (params) => {
  const { search } = params
  const criteria = []

  if (search) {
    criteria.push({
      field: 'name',
      value: `%${search}%`,
    })
  }

  return criteria
}
