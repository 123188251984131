import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { millisecondsToHoursAndMinutesString } from 'utils/date'

import { Typography } from 'components'

import i18n from 'translations'

import ShiftLabel from '../ShiftLabel'

import { StyledTimeBox } from './UserTableRowSummaryStyled'

const UserTableRowSummary = ({ label, time, type }) => (
  <StyledTimeBox>
    <ShiftLabel type={type} center>
      {label}
    </ShiftLabel>
    <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
      {millisecondsToHoursAndMinutesString(time) || `0${i18n.t('global:hourShortcut')}`}
    </Typography>
  </StyledTimeBox>
)

export default UserTableRowSummary
