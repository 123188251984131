import DataTable from './DataTable'
import DataTableHead from './DataTableHead'
import DataTableContent from './DataTableContent'
import DataTableContentScrollable from './DataTableContentScrollable'
import DataTableColumn from './DataTableColumn'
import DataTableRow from './DataTableRow'
import DataTableGroupHeader from './DataTableGroupHeader'
import DataTableSortable from './DataTableSortable'

DataTable.Head = DataTableHead
DataTable.Content = DataTableContent
DataTable.ContentScrollable = DataTableContentScrollable
DataTable.Column = DataTableColumn
DataTable.Row = DataTableRow
DataTable.GroupHeader = DataTableGroupHeader
DataTable.Sortable = DataTableSortable

export default DataTable
