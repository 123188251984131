import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withRouter } from 'services/router'

import ChildSessionsView from './ChildSessionsView'

const ChildSessionsContainer = ({
  children,
  isFetching: isLoading,
  params,
  router,
}) => {
  const { childId } = params

  return (
    <ChildSessionsView childId={childId} isLoading={isLoading} router={router}>
      {children}
    </ChildSessionsView>
  )
}

const mapState = (state, { appSelectors, childSingleState }) => ({
  isFetching: appSelectors.getIsFetching(childSingleState),
})

const enhance = compose(
  withRouter,
  withAppService,
  withChildService,
  connect(mapState),
)

export default enhance(ChildSessionsContainer)
