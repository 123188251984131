import React from 'react'

import { generateRoute } from 'utils/routing'

import { Section, Typography } from 'components'
import NurseriesListTable from 'module/Settings/components/NurseriesListTable'

import i18n from 'translations'

const SettingsNurseryView = ({
  isFetching,
  nurseries,
  onPageChange,
  page,
  pageCount,
  perPage,
  totalResults,
}) => (
  <Section title={i18n.t('module:Settings:Nursery:title')}>
    <Typography margin="20px 0">
      {i18n.t('module:Settings:Nursery:subtitle')}
    </Typography>
    <NurseriesListTable
      getActionRouting={({ id }) => generateRoute('SETTINGS.NURSERY.ITEM', { nurseryId: id })}
      isFetching={isFetching}
      noDataIcon="nursery"
      nurseries={nurseries}
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  </Section>
)

export default SettingsNurseryView
