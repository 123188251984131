import { combineReducers } from 'redux'

import items from './items/reducer'
import montessoriItems from './montessoriItems/reducer'
import list from './list/reducer'
import periods from './periods/reducer'
import single from './single/reducer'
import terms from './terms/reducer'

export default combineReducers({
  items,
  list,
  montessoriItems,
  periods,
  single,
  terms,
})
