export const GET_FORMATIVE_REPORTS = 'GET_FORMATIVE_REPORTS'
export const GET_FORMATIVE_REPORTS_SUCCESS = 'GET_FORMATIVE_REPORTS_SUCCESS'
export const GET_FORMATIVE_REPORTS_FAILED = 'GET_FORMATIVE_REPORTS_FAILED'

export const CREATE_FORMATIVE_REPORTS = 'CREATE_FORMATIVE_REPORTS'
export const CREATE_FORMATIVE_REPORTS_SUCCESS = 'CREATE_FORMATIVE_REPORTS_SUCCESS'
export const CREATE_FORMATIVE_REPORTS_FAILED = 'CREATE_FORMATIVE_REPORTS_FAILED'

export const EXPORT_FORMATIVE_REPORTS = 'EXPORT_FORMATIVE_REPORTS'
export const EXPORT_FORMATIVE_REPORTS_SUCCESS = 'EXPORT_FORMATIVE_REPORTS_SUCCESS'
export const EXPORT_FORMATIVE_REPORTS_FAILED = 'EXPORT_FORMATIVE_REPORTS_FAILED'

export const UPDATE_FORMATIVE_REPORTS = 'UPDATE_FORMATIVE_REPORTS'
export const UPDATE_FORMATIVE_REPORTS_SUCCESS = 'UPDATE_FORMATIVE_REPORTS_SUCCESS'
export const UPDATE_FORMATIVE_REPORTS_FAILED = 'UPDATE_FORMATIVE_REPORTS_FAILED'

export const CREATE_FORMATIVE_REPORTS_AGE_BANDS = 'CREATE_FORMATIVE_REPORTS_AGE_BANDS'
export const CREATE_FORMATIVE_REPORTS_AGE_BANDS_SUCCESS = 'CREATE_FORMATIVE_REPORTS_AGE_BANDS_SUCCESS'
export const CREATE_FORMATIVE_REPORTS_AGE_BANDS_FAILED = 'CREATE_FORMATIVE_REPORTS_AGE_BANDS_FAILED'

export const UPDATE_FORMATIVE_REPORTS_AGE_BANDS = 'UPDATE_FORMATIVE_REPORTS_AGE_BANDS'
export const UPDATE_FORMATIVE_REPORTS_AGE_BANDS_SUCCESS = 'UPDATE_FORMATIVE_REPORTS_AGE_BANDS_SUCCESS'
export const UPDATE_FORMATIVE_REPORTS_AGE_BANDS_FAILED = 'UPDATE_FORMATIVE_REPORTS_AGE_BANDS_FAILED'

export const CREATE_FORMATIVE_REPORTS_COMMENTS = 'CREATE_FORMATIVE_REPORTS_COMMENTS'
export const CREATE_FORMATIVE_REPORTS_COMMENTS_SUCCESS = 'CREATE_FORMATIVE_REPORTS_COMMENTS_SUCCESS'
export const CREATE_FORMATIVE_REPORTS_COMMENTS_FAILED = 'CREATE_FORMATIVE_REPORTS_COMMENTS_FAILED'

export const UPDATE_FORMATIVE_REPORTS_COMMENTS = 'UPDATE_FORMATIVE_REPORTS_COMMENTS'
export const UPDATE_FORMATIVE_REPORTS_COMMENTS_SUCCESS = 'UPDATE_FORMATIVE_REPORTS_COMMENTS_SUCCESS'
export const UPDATE_FORMATIVE_REPORTS_COMMENTS_FAILED = 'UPDATE_FORMATIVE_REPORTS_COMMENTS_FAILED'

export const REMOVE_FORMATIVE_REPORTS = 'REMOVE_FORMATIVE_REPORTS'
export const REMOVE_FORMATIVE_REPORTS_SUCCESS = 'REMOVE_FORMATIVE_REPORTS_SUCCESS'
export const REMOVE_FORMATIVE_REPORTS_FAILED = 'REMOVE_FORMATIVE_REPORTS_FAILED'

export const CLEAR_FORMATIVE_REPORTS = 'CLEAR_FORMATIVE_REPORTS'
