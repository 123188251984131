import React, { Component } from 'react'
import { compose } from 'recompose'

import { toInt } from 'utils/data'

import { Field } from 'components'

import withInvoiceSettingsComponent from '../withInvoiceSettingsComponent'
import InvoiceDueDateSettingsForm from './InvoiceDueDateSettingsForm'
import InvoiceActionButton from '../InvoiceActionButton'

const FORM_NAME_PREFIX = 'InvoiceRadioSettingsForm'

class InvoiceDueDateSettings extends Component {
  constructor(props) {
    super(props)

    const { fields: { item: { id } } } = props

    this.state = {
      formName: `${FORM_NAME_PREFIX}_${id}`,
    }
  }

  handleSubmit = (fields) => {
    const { fields: localFields, onSubmit } = this.props
    const { global } = localFields
    const { id: globalId } = global

    const dueDate = fields.dueDate ? toInt(fields.dueDate) : 0

    onSubmit({
      dueDate,
      [globalId]: fields[globalId],
    })
  }

  handleGlobalOptionClick = (globalValue) => {
    const { fields: { item: { id, orgValue } }, updateOrgValue } = this.props

    const { formName } = this.state

    if (globalValue) {
      updateOrgValue(formName, id, orgValue)
    }
  }

  render() {
    const {
      caption,
      disableEdit,
      fields: { global, item },
      hideGlobalOption,
      isEdit,
      isGlobalOptionOn,
      isSubmitting,
      onCancelClick,
      onEditClick,
    } = this.props
    const { id, value } = item
    const { id: globalId, value: globalValue } = global

    const initialValues = { [globalId]: globalValue, [id]: value }
    const label = value ? `${value} days` : null

    return (
      <Field.InlineEdit
        actions={(
          <InvoiceActionButton
            disableEdit={disableEdit}
            formRef={this.myForm}
            isEditMode={isEdit}
            onCancelClick={onCancelClick}
            onClick={onEditClick}
          />
        )}
        description={label}
        editableComponent={(
          <InvoiceDueDateSettingsForm
            form={`${FORM_NAME_PREFIX}_${id}`}
            globalId={globalId}
            hideGlobalOption={hideGlobalOption}
            id={id}
            initialValues={initialValues}
            isGlobalOptionOn={isGlobalOptionOn}
            ref={(ref) => { this.myForm = ref }}
            onGlobalOptionClick={this.handleGlobalOptionClick}
            onSubmit={this.handleSubmit}
          />
        )}
        isEditMode={isEdit}
        isLoading={isSubmitting}
        label={caption}
      />
    )
  }
}

const enhance = compose(withInvoiceSettingsComponent(FORM_NAME_PREFIX))

export default enhance(InvoiceDueDateSettings)
