import React from 'react'

import { ModalBox } from 'components'

import i18n from 'translations'

import FileDetailsModalForm from './components/FileDetailsModalForm'

const FileDetailsModalView = ({
  onCloseClick,
  ...rest
}) => (
  <ModalBox
    maxWidth={620}
    title={i18n.t('module:Modals:FileDetailsModal:title')}
    onCloseClick={onCloseClick}
  >
    <FileDetailsModalForm
      onCloseClick={onCloseClick}
      {...rest}
    />
  </ModalBox>
)

export default FileDetailsModalView
