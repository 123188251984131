import _ from 'lodash'

import React from 'react'

import { DefinitionList, Section, TimeString, Toolbar } from 'components'

import i18n from 'translations'

import {
  StyledContainer,
  StyledDayContainer,
  StyledItemContainer,
  StyledPlanContainer,
  StyledPlanGroupContainer,
  StyledPlanItemContainer,
  StyledSessionContainer,
  StyledSessionContentContainer,
  StyledSessionTextContainer,
} from './ChildSessionsListStyled'

const ChildSessionsList = ({
  attendancePeriodText,
  child,
  childSession,
  displayAddAction,
  sectionVariant,
  title,
}) => {
  const { formattedPlans, id } = childSession
  const { id: childId } = child || {}

  const to = `/children/${childId}/booking-pattern/sessions/${id}/edit`

  const renderPlan = ({ archived, endTime, sessionName, startTime }, i) => (
    <StyledPlanContainer key={_.kebabCase(i)}>
      <StyledPlanGroupContainer>
        <StyledPlanItemContainer highlighted>
          <TimeString date={startTime} local={false} />
          <span>
            &nbsp;-&nbsp;
          </span>
          <TimeString date={endTime} local={false} />
        </StyledPlanItemContainer>
      </StyledPlanGroupContainer>
      <StyledPlanGroupContainer>
        <StyledPlanItemContainer>
          {`${sessionName}${archived ? ' (archived)' : ''}`}
        </StyledPlanItemContainer>
      </StyledPlanGroupContainer>
    </StyledPlanContainer>
  )

  const renderSessions = (plans) => {
    if (!plans.length) {
      return (
        <StyledSessionTextContainer>
          No sessions
        </StyledSessionTextContainer>
      )
    }

    return _.map(plans, renderPlan)
  }

  const renderDay = ({ dayOfWeek, plans }, i) => (
    <StyledItemContainer key={_.kebabCase(i)}>
      <StyledDayContainer>
        {dayOfWeek.toUpperCase().substring(0, 3)}
      </StyledDayContainer>
      <StyledSessionContentContainer>
        {renderSessions(plans)}
      </StyledSessionContentContainer>
    </StyledItemContainer>
  )

  return (
    <StyledContainer>
      <Section
        actions={displayAddAction && <Section.Actions tertiary={{ to }} />}
        title={title}
        variant={sectionVariant}
      >
        <Toolbar>
          <Toolbar.Group>
            <Toolbar.Item>
              <DefinitionList>
                <DefinitionList.Item
                  label={i18n.t('module:Children:Child:BookingPattern:Sessions:Add:attendancePeriod')}
                  value={attendancePeriodText}
                  horizontal
                />
              </DefinitionList>
            </Toolbar.Item>
          </Toolbar.Group>
        </Toolbar>
        <StyledSessionContainer>
          {_.map(formattedPlans, renderDay)}
        </StyledSessionContainer>
      </Section>
    </StyledContainer>
  )
}

export default ChildSessionsList
