import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { DEFAULT_DATE_FORMAT, DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT } from 'constants/date'
import { ABSENCE_TYPE } from 'module/Register/constants'
import { SHOW_SYNC_BAR_EVENT } from 'module/Shell/components/NetworkConnectionBar/NetworkConnectionBar'
import { FEATURE_FLAGS, ROLES } from 'constants/security'

import { EVENTS, logEvent } from 'analytics'

import { isSameDay, isToday } from 'utils/date'
import { mapValuesAsync } from 'utils/data'
import auth from 'utils/auth'
import eventBus from 'utils/eventBus'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withRegisterService } from 'services/legacy/register'
import { withChildRegistersService } from 'services/legacy/childRegisters'
import { withChildAbsencesService } from 'services/legacy/childAbsences'
import { withStaffLogsService } from 'services/legacy/staffLogs'
import { withSnackbarService } from 'services/utils/snackbar'

import { withRouterUtils } from 'services/utils/router'
import { hasOnlyRoomLeaderOrTeacherAccess } from 'services/security/selectors'

import i18n from 'translations'

import RegisterView from './RegisterView'
import {
  getCurrentDateTime,
  getFilteredData,
  getNewEntryDate,
  getPayload,
  getStaffLogs,
  getUpdatedSignIns,
  removeId,
} from './helpers'

const STAFF_LOGS_GROUPS = {
  read: [
    'staffLog.childRegister',
    'staffLog.staff',
    'user',
    'user.details',
  ],
}

class RegisterContainer extends Component {
  constructor(props) {
    super(props)

    const { query } = props.location

    const room = query.room || null
    const session = query.session || null
    const status = query.status || null

    this.state = {
      date: getNewEntryDate(query.date),
      room,
      session,
      status,
    }
  }

  offlineAction = () => {
    this.handleChangeOfflineMode(true)
  }

  onlineAction = () => {
    this.handleChangeOfflineMode(false)
  }

  componentDidMount() {
    logEvent(EVENTS.REGISTER_PAGE_VIEWED)
    window.addEventListener('offline', this.offlineAction)
    window.addEventListener('online', this.onlineAction)

    this.fetch(true)
  }

  componentWillUnmount() {
    const { isOffline, registerActions } = this.props

    window.removeEventListener('offline', this.offlineAction)
    window.removeEventListener('online', this.onlineAction)

    if (!isOffline) {
      registerActions.clear()
    }
  }

  fetch = (init) => {
    const { globalRegisterList, isOffline, registerActions, registerSelectors } = this.props
    const { date } = this.state

    const criteria = registerSelectors.getCriteria(this.state)

    if (init && isOffline) {
      logEvent(EVENTS.REGISTER_OFFLINE_ENABLE_OFFLINE_MODE)

      return registerActions.importGlobalRegister(
        globalRegisterList,
        isToday(moment(date, DEFAULT_DATE_FORMAT)),
      )
    }

    return registerActions.list({ criteria })
  }

  handleChangeOfflineMode = (isOffline) => {
    const { OfflineModeEnabled, globalRegisterList, registerActions, setLocationQuery } = this.props
    const { date } = this.state

    if (!OfflineModeEnabled) {
      return null
    }

    if (!isOffline) {
      logEvent(EVENTS.REGISTER_DISABLE_OFFLINE_MODE)

      return this.syncData()
    }

    logEvent(EVENTS.REGISTER_ENABLE_OFFLINE_MODE)

    registerActions.importGlobalRegister(
      globalRegisterList,
      isToday(moment(date, DEFAULT_DATE_FORMAT)),
    )

    const { offlineTodayData } = this.props

    if (!offlineTodayData) {
      setLocationQuery({
        date: moment().format(DEFAULT_DATE_FORMAT),
        room: null,
        session: null,
        status: null,
      })

      this.setState({
        date: moment(),
        room: null,
        session: null,
        status: null,
      })
    }

    return false
  }

  syncData = async () => {
    const { childAbsencesActions, childRegistersActions, offlineActions, registerActions } = this.props

    if (!_.keys(offlineActions).length) {
      return false
    }

    logEvent(EVENTS.REGISTER_SYNC_OFFLINE_ACTIONS)

    eventBus.dispatch(SHOW_SYNC_BAR_EVENT, true)

    await Promise.all(_.map(offlineActions, (data) => (
      mapValuesAsync(data, async ({ absence: absenceGroup, basic }, childId) => {
        const { absence, body, params, toEdit, toRemove } = absenceGroup || {}
        const { id, payload, toReset, toResetAndUpdate } = basic || {}
        let blockBasicAction = false

        const absencePart = async () => {
          if (toReset) {
            blockBasicAction = true

            await childRegistersActions.update({
              body: { present: null },
              onFailed: this.handleSubmitFailed,
              params: [id, {}],
            })
          }

          if (toRemove) {
            return childAbsencesActions.remove({
              params: [absence.id],
            })
          }

          if (toEdit) {
            return childAbsencesActions.update({
              body,
              params,
            })
          }

          return childAbsencesActions.create({
            body,
            params,
          })
        }

        const basicPart = async () => {
          if (toReset || toResetAndUpdate) {
            await childRegistersActions.update({
              body: { present: null },
              onFailed: this.handleSubmitFailed,
              params: [id, {}],
            })

            if (!toResetAndUpdate) {
              return true
            }
          }

          const finalPayload = removeId(payload)

          if (id) {
            return registerActions.update(+childId, id, finalPayload)
          }

          return registerActions.create(+childId, finalPayload)
        }

        if (absenceGroup) {
          await absencePart()
        }

        if (basic && !blockBasicAction) {
          basicPart()
        }
      })
    )))

    registerActions.clearOfflineActions()
    eventBus.dispatch(SHOW_SYNC_BAR_EVENT, false)

    return null
  }

  saveRegisterItem = (childId, id, newValues, cb) => {
    const { isOffline, offlineActions, registerActions, registerState } = this.props
    const { date } = this.state

    const payload = getPayload(registerState.data, childId, null, newValues, date)

    if (isOffline) {
      const prevElement = offlineActions?.[date]?.[childId]?.basic

      registerActions.updateOfflineAction({
        ...offlineActions,
        [date]: {
          ...(offlineActions?.[date] || {}),
          [childId]: {
            ...(offlineActions?.[date]?.[childId] || {}),
            basic: {
              id: prevElement?.id || id,
              payload: {
                ...(prevElement?.payload || {}),
                ...payload,
              },
              toResetAndUpdate: prevElement?.toResetAndUpdate || prevElement?.toReset,
            },
          },
        },
      })

      cb?.()

      if (id) {
        return registerActions.offlineUpdate({
          childId,
          id,
          payload,
        })
      }

      return registerActions.offlineCreate(childId, payload)
    }

    if (id) {
      return registerActions.update(childId, id, payload, cb)
    }

    return registerActions.create(childId, payload, cb)
  }

  handleItemSaveClick = (childId, id, newValues, cb) => {
    this.saveRegisterItem(childId, id, newValues, cb)
  }

  handleGetStaffLogsSuccess = (child, absence) => (staffLogs) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.REGISTER_STAFF_LOG, {
      absence,
      child,
      staffLogs: staffLogs ? getStaffLogs(staffLogs.data) : null,
    })
  }

  handleItemStaffLogClick = (id, child, absence) => {
    const { modalActions, modalConsts, staffLogsActions, staffLogsSelectors } = this.props

    logEvent(EVENTS.REGISTER_STAFF_LOG_VIEWED)

    if (id) {
      modalActions.show(modalConsts.TYPES.REGISTER_STAFF_LOG, {
        isLoading: true,
      })

      const criteria = staffLogsSelectors.getListCriteria({ registerId: id })

      staffLogsActions.list({
        onSuccess: this.handleGetStaffLogsSuccess(child, absence),
        params: {
          criteria,
          groups: STAFF_LOGS_GROUPS,
        },
      })
    } else {
      this.handleGetStaffLogsSuccess(child, absence)()
    }
  }

  handleItemSignInClick = (childId, id) => {
    const { registerState } = this.props
    const { date } = this.state

    const signedInAt = getCurrentDateTime(date)
    const newSignInItem = { signedInAt }

    const signIns = getUpdatedSignIns(registerState.data, childId, null, newSignInItem)
    const newValues = { present: true, signIns }

    logEvent(EVENTS.REGISTER_TIME_IN_BTN_CLICKED)

    this.saveRegisterItem(childId, id, newValues)
  }

  handleItemSignOutClick = (childId, id, signInItem) => {
    const { registerState } = this.props
    const { date } = this.state

    const newSignInItem = {
      id: signInItem.id,
      signedInAt: moment(signInItem.signedInAt).toDate(),
      signedOutAt: getCurrentDateTime(date),
    }

    const signIns = getUpdatedSignIns(registerState.data, childId, null, newSignInItem)

    const newValues = {
      present: true,
      signIns,
    }

    logEvent(EVENTS.REGISTER_TIME_OUT_BTN_CLICKED)

    this.saveRegisterItem(childId, id, newValues)
  }

  handleExtraSessionsSuccess = () => {
    logEvent(EVENTS.REGISTER_EXTRA_SESSION_ADDED, { context: 'register' })

    this.fetch()
  }

  handleAddExtraSessionClick = () => {
    const { modalActions, modalConsts } = this.props
    const { date } = this.state

    logEvent(EVENTS.REGISTER_EXTRA_SESSION_BTN_CLICKED)

    modalActions.show(modalConsts.TYPES.REGISTER_EXTRA_SESSION, {
      entryDate: date,
      onSuccess: this.handleExtraSessionsSuccess,
    })
  }

  setDate = (date) => {
    const { isOffline, registerActions, setLocationQuery } = this.props

    const callback = () => {
      if (!isOffline) {
        return this.fetch()
      }

      setLocationQuery({
        date: moment().format(DEFAULT_DATE_FORMAT),
        room: null,
        session: null,
      })

      this.setState({
        date: moment(),
        room: null,
        session: null,
      })

      return registerActions.loadTodayData()
    }

    this.setState({ date: getNewEntryDate(date) }, callback)

    setLocationQuery({ date: moment(date).format(DEFAULT_DATE_FORMAT) })
  }

  handleEntryDateChange = (date) => {
    logEvent(EVENTS.REGISTER_FILTER_USED, { type: 'date' })

    this.setDate(date)
  }

  handleRoomChange = (room) => {
    const { setLocationQuery } = this.props

    this.setState(
      (prevState) => ({
        ...prevState,
        room: room ? room.value : null,
      }),
      this.fetch,
    )

    logEvent(EVENTS.REGISTER_FILTER_USED, { type: 'room' })

    setLocationQuery({ room: room ? room.value : null })
  }

  handleSessionChange = (session) => {
    const { setLocationQuery } = this.props

    this.setState(
      (prevState) => ({
        ...prevState,
        session: session ? session.value : null,
      }),
      this.fetch,
    )

    logEvent(EVENTS.REGISTER_FILTER_USED, { type: 'session' })

    setLocationQuery({ session: session ? session.value : null })
  }

  handleStatusChange = (status) => {
    const { setLocationQuery } = this.props

    this.setState(
      (prevState) => ({
        ...prevState,
        status: status ? status.value : null,
      }),
    )

    logEvent(EVENTS.REGISTER_FILTER_USED, { type: 'status' })

    setLocationQuery({ status: status ? status.value : null })
  }

  handleItemResetSuccess = (childId, id) => (response) => {
    const { registerActions } = this.props
    const { date } = this.state

    logEvent(EVENTS.REGISTER_RESET)

    registerActions.resetListItem(childId, id, response.data, { entryDate: date })
  }

  handleItemResetConfirmClick = (childId, id) => {
    const { childRegistersActions, isOffline, offlineActions, registerActions } = this.props
    const { date } = this.state

    if (isOffline) {
      const prevElement = offlineActions?.[date]?.[childId]?.basic

      registerActions.updateOfflineAction({
        ...offlineActions,
        [date]: {
          ...(offlineActions?.[date] || {}),
          [childId]: {
            ...(offlineActions?.[date]?.[childId] || {}),
            basic: {
              id: prevElement?.id || id,
              toReset: true,
            },
          },
        },
      })

      return registerActions.resetListItem(childId, id, { id }, { entryDate: date })
    }

    return childRegistersActions.update({
      body: { present: null },
      onFailed: this.handleSubmitFailed,
      onSuccess: this.handleItemResetSuccess(childId, id),
      params: [id, {}],
    })
  }

  handleItemResetClick = (child, id) => {
    const { modalActions, modalConsts } = this.props

    logEvent(EVENTS.REGISTER_RESET_BTN_CLICKED)

    modalActions.show(modalConsts.TYPES.REGISTER_RESET, {
      child,
      onConfirm: () => this.handleItemResetConfirmClick(child.id, id),
    })
  }

  handleEditAbsenceSuccess = (childId) => (response) => {
    const { registerActions } = this.props
    const { date } = this.state
    const { endDate, startDate } = response.data

    if (moment(date).isSameOrAfter(startDate, 'date') && moment(date).isSameOrBefore(endDate, 'date')) {
      return registerActions.addOrUpdateAbsenceToList(childId, response.data, { entryDate: date })
    }

    return registerActions.removeAbsenceFromList(childId, { entryDate: date })
  }

  handleAbsenceSuccessForOfflineMode = (child) => (payload) => {
    const { offlineActions, registerActions } = this.props
    const { date } = this.state
    const { endDate, startDate } = payload.body

    registerActions.updateOfflineAction({
      ...offlineActions,
      [date]: {
        ...(offlineActions?.[date] || {}),
        [child.id]: {
          ...(offlineActions?.[date]?.[child.id] || {}),
          absence: payload,
        },
      },
    })

    if (moment(date).isSameOrAfter(startDate, 'date') && moment(date).isSameOrBefore(endDate, 'date')) {
      return registerActions.addOrUpdateAbsenceToList(child.id, payload.body, { entryDate: date })
    }

    return registerActions.removeAbsenceFromList(child.id, { entryDate: date })
  }

  handleItemAbsenceEditClick = (child, absenceId) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CHILD_LEAVE, {
      child,
      id: absenceId,
      isEdit: true,
      onSubmitOfflineMode: this.handleAbsenceSuccessForOfflineMode(child),
      onSuccess: this.handleEditAbsenceSuccess(child.id),
    }, {
      enableMultipleModal: true,
    })
  }

  handleDownloadButtonClick = () => {
    const { modalActions, modalConsts } = this.props

    logEvent(EVENTS.REGISTER_DOWNLOAD_BTN_CLICKED)

    modalActions.show(modalConsts.TYPES.DOWNLOAD_REGISTER)
  }

  handleCreateAbsenceSuccess = (response) => {
    const { registerActions } = this.props
    const { date } = this.state
    const { absenceType, child, endDate, startDate } = response.data

    logEvent(EVENTS.REGISTER_ABSENT_ADDED, { type: absenceType })

    const formattedStartDate = moment(startDate).format(DEFAULT_DATE_FORMAT)
    const formattedEndDate = moment(endDate).format(DEFAULT_DATE_FORMAT)

    if (date >= formattedStartDate && date <= formattedEndDate) {
      registerActions.addOrUpdateAbsenceToList(child.id, response.data, { entryDate: date })
    }
  }

  handleItemAbsenceClick = (child) => {
    const { modalActions, modalConsts } = this.props
    const { date } = this.state

    logEvent(EVENTS.REGISTER_ABSENT_BTN_CLICKED)

    modalActions.show(modalConsts.TYPES.CHILD_LEAVE, {
      child,
      date,
      onSubmitOfflineMode: this.handleAbsenceSuccessForOfflineMode(child),
      onSuccess: this.handleCreateAbsenceSuccess,
    }, {
      enableMultipleModal: true,
    })
  }

  handleItemAbsenceDeleteSuccess = (child, absence) => {
    const { registerActions, registerState, snackbarActions } = this.props
    const { date } = this.state

    snackbarActions.show({
      message: i18n.t('module:Children:Child:About:Absences:DeleteLeave:snackbar', {
        firstName: child.firstName,
        type: ABSENCE_TYPE.ABSENCE === absence.absenceType ? i18n.t('global:Absence') : i18n.t('global:Holiday'),
      }),
    })

    const registerItem = _.find(registerState.data, { id: child.id })
    const isChildExpectedInRegister = registerItem
      ? registerItem.extraSessions?.length || registerItem.sessions?.[0]?.plans?.length
      : false

    if (!isChildExpectedInRegister) {
      return registerActions.removeItemFromList(child.id, { entryDate: date })
    }

    return registerActions.removeAbsenceFromList(child.id, { entryDate: date })
  }

  handleItemDeleteConfirmClick = (child, absence) => {
    const { childAbsencesActions, isOffline, offlineActions, registerActions } = this.props
    const { date } = this.state

    if (!isOffline) {
      return childAbsencesActions.remove({
        onSuccess: () => this.handleItemAbsenceDeleteSuccess(child, absence),
        params: [absence.id],
      })
    }

    const absenceItem = offlineActions?.[date]?.[child.id].absence

    this.handleItemAbsenceDeleteSuccess(child, absence)

    if (absenceItem && false === absenceItem.toEdit) {
      const childActions = offlineActions?.[date]?.[child.id]
      delete childActions.absence

      return registerActions.updateOfflineAction({
        ...offlineActions,
        [date]: {
          ...(offlineActions?.[date] || {}),
          [child.id]: childActions,
        },
      })
    }

    return registerActions.updateOfflineAction({
      ...offlineActions,
      [date]: {
        ...(offlineActions?.[date] || {}),
        [child.id]: {
          ...(offlineActions?.[date]?.[child.id] || {}),
          absence: {
            ...absenceItem,
            absence,
            toRemove: true,
          },
        },
      },
    })
  }

  handleItemAbsenceDeleteClick = (child, absence, onResetExpandType) => {
    const { modalActions, modalConsts } = this.props
    const { endDate, startDate } = absence
    const suffix = isSameDay(startDate, endDate) ? 'oneDay' : 'dateRange'

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('global:Delete'),
      onConfirm: () => {
        onResetExpandType()
        this.handleItemDeleteConfirmClick(child, absence)
      },
      text: i18n.t(`module:Children:Child:About:Absences:DeleteLeave:Confirm:${suffix}`, {
        endDate: moment(endDate).format(DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT),
        firstName: child.firstName,
        startDate: moment(startDate).format(DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT),
        type: ABSENCE_TYPE.ABSENCE === absence.absenceType ? i18n.t('global:Absence') : i18n.t('global:Holiday'),
      }),
    })
  }

  render() {
    const {
      ExtraSessionsIsReadOnly,
      attendanceSummary,
      errorMessages,
      isFetching,
      isFinanceV3Enabled,
      isOffline,
      offlineTodayData,
      registerState,
    } = this.props
    const { date, room, session, status } = this.state

    const registerList = registerState.data
    const entryDate = isOffline
      ? moment(date, DEFAULT_DATE_FORMAT)
      : _.find(registerState.meta?.criteria || [], { field: 'entryDate' })?.value
    const isHoliday = registerState?.meta?.holiday
    const isNurseryClosed = registerState?.meta?.nursery_closed
    const isEmpty = registerState.isEmpty && !room && !session && !status
    const isLoading = isFetching || (!isHoliday && !registerList) || !!errorMessages
    const showWidget = attendanceSummary && !isLoading
    const filteredData = getFilteredData(registerList, status)

    return (
      <RegisterView
        ExtraSessionsIsReadOnly={ExtraSessionsIsReadOnly}
        attendanceSummary={attendanceSummary}
        date={date}
        entryDate={entryDate}
        errorMessages={errorMessages}
        isEmpty={isEmpty}
        isFinanceV3Enabled={isFinanceV3Enabled}
        isHoliday={isHoliday}
        isLoading={isLoading}
        isNurseryClosed={isNurseryClosed}
        isOffline={isOffline}
        offlineTodayData={offlineTodayData}
        registerList={filteredData}
        room={room}
        session={session}
        showWidget={showWidget}
        status={status}
        onAddExtraSessionClick={this.handleAddExtraSessionClick}
        onDateChange={this.handleEntryDateChange}
        onDownloadButtonClick={this.handleDownloadButtonClick}
        onItemAbsenceClick={this.handleItemAbsenceClick}
        onItemAbsenceDeleteClick={this.handleItemAbsenceDeleteClick}
        onItemAbsenceEditClick={this.handleItemAbsenceEditClick}
        onItemResetClick={this.handleItemResetClick}
        onItemSaveClick={this.handleItemSaveClick}
        onItemSignInClick={this.handleItemSignInClick}
        onItemSignOutClick={this.handleItemSignOutClick}
        onItemStaffLogClick={this.handleItemStaffLogClick}
        onRoomChange={this.handleRoomChange}
        onSessionChange={this.handleSessionChange}
        onStatusChange={this.handleStatusChange}
      />
    )
  }
}

RegisterContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.ROOM_LEADER,
    ROLES.SENIOR_TEACHER,
    ROLES.TEACHER,
  ],
}

const mapState = (state, {
  appSelectors,
  registerSelectors,
  registerState,
}) => ({
  ExtraSessionsIsReadOnly: hasOnlyRoomLeaderOrTeacherAccess(state),
  OfflineModeEnabled: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.OFFLINE_MODE],
  }),
  attendanceSummary: registerSelectors.getAttendanceSummary(state),
  errorMessages: appSelectors.getErrorMessages(registerState),
  globalRegisterList: registerSelectors.getRegisterGlobalList(state),
  isFetching: appSelectors.getIsFetching(registerState),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
  isOffline: appSelectors.getAppIsOfflineAndFFIsEnabled(state),
  offlineActions: registerSelectors.getRegisterOfflineActions(state),
  offlineTodayData: registerSelectors.getOfflineDataToday(state),
})

const enhance = compose(
  withAppService,
  withModalService,
  withRegisterService,
  withChildRegistersService,
  withChildAbsencesService,
  withStaffLogsService,
  withSnackbarService,
  withRouterUtils,
  connect(mapState),
)

export default enhance(RegisterContainer)
