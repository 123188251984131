export const CREATE_CARER = 'CREATE_CARER'
export const CREATE_CARER_SUCCESS = 'CREATE_CARER_SUCCESS'
export const CREATE_CARER_FAILED = 'CREATE_CARER_FAILED'

export const GET_CARER = 'GET_CARER'
export const GET_CARER_SUCCESS = 'GET_CARER_SUCCESS'
export const GET_CARER_FAILED = 'GET_CARER_FAILED'

export const UPDATE_CARER = 'UPDATE_CARER'
export const UPDATE_CARER_SUCCESS = 'UPDATE_CARER_SUCCESS'
export const UPDATE_CARER_FAILED = 'UPDATE_CARER_FAILED'

export const REMOVE_CARER = 'REMOVE_CARER'
export const REMOVE_CARER_SUCCESS = 'REMOVE_CARER_SUCCESS'
export const REMOVE_CARER_FAILED = 'REMOVE_CARER_FAILED'

export const RESEND_PARENT_APP_INVITATION = 'RESEND_PARENT_APP_INVITATION'
export const RESEND_PARENT_APP_INVITATION_SUCCESS = 'RESEND_PARENT_APP_INVITATION_SUCCESS'
export const RESEND_PARENT_APP_INVITATION_FAILED = 'RESEND_PARENT_APP_INVITATION_FAILED'

export const CLEAR_CARER = 'CLEAR_CARER'
