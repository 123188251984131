import Checkbox from '@material-ui/core/Checkbox'
import { createTheme } from '@material-ui/core/styles'
import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

interface StyledLabelContainerProps {
  $centered?: boolean
  $disabled?: boolean
  $padding?: string
}

export const theme = createTheme({
  overrides: {
    MuiCheckbox: {
      colorPrimary: {
        '&$checked': {
          color: getBrandingColor('primary-color'),
        },
      },
      root: {
        color: NEUTRAL_COLOURS.GRAY,
        margin: '-9px',
      },
    },
  },
})

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledCheckbox = styled(Checkbox)``

export const StyledLabelContainer = styled.label<StyledLabelContainerProps>`
  display: inline-flex;
  align-items: center;
  gap: 12px;
  ${({ $centered }) => $centered && 'justify-content: center;'}
  ${({ $padding }) => $padding && `padding: ${$padding};`}
  ${({ $disabled }) => `cursor: ${$disabled ? 'not-allowed' : 'pointer'};`}
`

export const StyledSpacer = styled.div`
  flex: 1;
`
