import { createSelector } from 'reselect'

const getRegisterStatistics = (state) => state.registerStatistics.statistics

export const getRegisterStatisticsData = createSelector(
  [getRegisterStatistics],
  ({ data }) => ({
    attending: data ? data.totalInTheNursery : null,
    expected: data ? data.totalScheduled : null,
  }),
)
