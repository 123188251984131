import { BasicSingleInitialState, getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import { CLEAR_USER, GET_USER, GET_USER_FAILED, GET_USER_SUCCESS } from './constants'

const initialState = getBasicSingleInitialState

export interface UsersSingleState extends BasicSingleInitialState {}

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_USER,
    itemFailed: GET_USER_FAILED,
    itemInit: GET_USER,
    itemSuccess: GET_USER_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
