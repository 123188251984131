import _ from 'lodash'

import React from 'react'

import { StyledBadgeContainer, StyledContainer, StyledItemContainer, StyledTimeContainer } from './OpeningDaysStyled'

const OpeningDays = ({ openingDays }) => (
  <StyledContainer>
    {_.map(openingDays, (openingDay, i) => {
      const { description, label } = openingDay

      return (
        <StyledItemContainer key={_.kebabCase(i)}>
          <StyledBadgeContainer>
            {label}
          </StyledBadgeContainer>
          <StyledTimeContainer>
            {description}
          </StyledTimeContainer>
        </StyledItemContainer>
      )
    })}
  </StyledContainer>
)

export default OpeningDays
