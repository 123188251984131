import _ from 'lodash'

import { Property } from 'csstype'

import React, { PropsWithChildren } from 'react'

import { CursorTypes } from 'constants/css'
import { noop } from 'constants/global'

import { getBrandingColor } from 'utils/branding'

// eslint-disable-next-line import/extensions, import/no-unresolved
import 'assets/fonts/icons/webfont.css'

import { StyledContainer } from './IconStyled'

export interface IconProps {
  color?: string
  cursor?: CursorTypes
  height?: number
  icon: IconType
  justify?: Property.JustifyContent
  onClick?: (e: Event) => void
}

const Icon: React.FC<PropsWithChildren<IconProps>> = ({
  color = getBrandingColor('primary-color'),
  cursor = 'inherit',
  height = 30,
  icon,
  onClick = noop,
  justify,
}) => {
  const style = {
    color,
    cursor,
    fontSize: 30,
  }

  if (!_.isNaN(height)) {
    style.fontSize = height
  }

  return (
    <StyledContainer $justify={justify} onClick={onClick}>
      <div
        className={`webfont webfont-${icon}`}
        style={style}
      />
    </StyledContainer>
  )
}

export default Icon
