import _ from 'lodash'

import React from 'react'

import { generateRoute } from 'utils/routing'

import { Button, Currency, DateString } from 'components'

import { NURSERY_DISCOUNT_TYPES } from 'services/product/nurseryDiscountsV3/constants'

export const getTableData = (childDiscounts, childId) => {
  if (!childDiscounts || !childDiscounts.length) {
    return []
  }

  return _.map(childDiscounts, (childDiscount) => {
    const { archived, createdAt, id, nurseryDiscount } = childDiscount
    const { description, type, value } = nurseryDiscount || childDiscount

    return {
      createdAt: <DateString date={createdAt} />,
      description: `${description}${archived ? ' (archived)' : ''}`,
      discount: NURSERY_DISCOUNT_TYPES.PERCENTAGE === type ? `${value}%` : <Currency value={value} />,
      rowToButton: (
        <Button.TableAction
          to={generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.DISCOUNTS.EDIT', { childId, id })}
          edit
        />
      ),
    }
  })
}
