import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { Form, Typography } from 'components'

import i18n from 'translations'

import { ALL_CHILDREN } from '../EmergencyContactListsModalContainer'

export const EMERGENCY_CONTACTS_LIST_FORM = 'EmergencyContactsListForm'

const EmergencyContactsListForm = ({ handleSubmit, isProcessing, onCloseClick, onSubmit }) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row label={i18n.t('module:Modals:EmergencyContacts:selectAllOrSpecific')} verticalLabel>
      <Field
        component={Form.InfiniteDropdowns.Rooms}
        extraOptions={[ALL_CHILDREN]}
        name="room"
        searchable={false}
      />
    </Form.Row>
    <Form.Row label={i18n.t('module:Modals:global:selectAManagerOrAdministrator')} verticalLabel>
      <Field
        component={Form.InfiniteDropdowns.OrganizationMemberships}
        name="membership"
        placeholder="Select a manager or administrator to send the export to"
        searchable={false}
        validate={isRequired}
      />
    </Form.Row>
    <Form.Row verticalLabel>
      <Field
        component={Form.Checkbox}
        label={i18n.t('module:Modals:EmergencyContacts:parentalResponsibility')}
        name="parentalResponsibility"
      />
    </Form.Row>
    <Typography fontSize="14">
      NOTE: If selected it will only export contacts with parental responsibility.
    </Typography>
    <Form.FooterActions
      isSubmitting={isProcessing}
      submitLabel={i18n.t('global:Export')}
      onCancelClick={onCloseClick}
    />
  </Form>
)

export default reduxForm({ form: 'EmergencyContactsListForm' })(EmergencyContactsListForm)
