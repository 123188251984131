import React, { useState } from 'react'

import SessionSetImpactModalView from './SessionSetImpactModalView'

const SessionSetImpactModalContainer = ({
  autoCreditNotingEnabled,
  childFirstName,
  currentMode,
  currentSession,
  hideModal,
  onSave,
  otherImpactedSession,
}) => {
  const [isSubmitting, setIsSubmitting] = useState()

  const handleSave = () => {
    setIsSubmitting(true)

    hideModal()

    onSave(() => setIsSubmitting(false))
  }

  return (
    <SessionSetImpactModalView
      autoCreditNotingEnabled={autoCreditNotingEnabled}
      childFirstName={childFirstName}
      currentMode={currentMode}
      currentSession={currentSession}
      isSubmitting={isSubmitting}
      otherImpactedSession={otherImpactedSession}
      onCloseClick={hideModal}
      onSave={handleSave}
    />
  )
}

export default SessionSetImpactModalContainer
