import styled from 'styled-components'

import { Link } from 'react-router'

import layout from 'constants/layout'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledContainer = styled.div`
  padding: 5px 8px;
  display: flex;
  align-items: center;
  min-height: 67px;
`

export const StyledLinkContainer = styled(Link)`
  padding: 5px 8px;
  display: flex;
  align-items: center;
  min-height: 67px;
`

export const StyledWidgetListContainer = styled.div`
  margin: -10px;
  
  & > ${StyledLinkContainer}:nth-child(odd),
  & > ${StyledContainer}:nth-child(odd) {
    background-color: ${getBrandingColor('senary-color')};
  }
  
  & > ${StyledLinkContainer}:nth-child(even),
  & > ${StyledContainer}:nth-child(even) {
    background-color: ${getBrandingColor('primary-color')};
  }
  
  ${({ reverse }) => reverse && `
    & > ${StyledLinkContainer}:nth-child(odd),
    & > ${StyledContainer}:nth-child(odd) {
      background-color: ${getBrandingColor('primary-color')};
    }
    
    & > ${StyledLinkContainer}:nth-child(even),
    & > ${StyledContainer}:nth-child(even) {
      background-color: ${getBrandingColor('senary-color')};
    }
  `}
`

export const StyledChild = styled.div`
  display: flex;
  flex: 0;
  margin-right: 10px;
`

export const StyledDetail = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`

export const StyledRightColumn = styled.div`
  display: flex;
  flex: 0;
  justify-content: flex-start;
  padding-left: 15px;
  color: ${NEUTRAL_COLOURS.WHITE};
  font-family: ${layout.fontFamily};
  font-size: 20px;
  font-weight: 400;
`

export const StyledNavImage = styled.div`
  display: flex;
  flex: 0;
  padding: 0 10px 0 15px;
`
