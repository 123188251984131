import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class NewsApiClient extends ServiceBase {
  get = (id, params = {}) => this.request({
    path: `/v2/newsletters/${id}?${getQueryStringV2(params)}`,
  })

  list = (params = {}) => this.request({
    path: `/v2/newsletters?${getQueryStringV2(params)}`,
  })

  create = (body, params = {}) => this.request({
    body,
    method: 'POST',
    path: `/v2/newsletters?${getQueryStringV2(params)}`,
  })

  update = (id, payload, params = {}) => this.request({
    body: payload,
    method: 'PUT',
    path: `/v2/newsletters/${id}?${getQueryStringV2(params)}`,
  })
}

export default NewsApiClient
