import _ from 'lodash'

import { mergeData } from 'utils/data'

import {
  ADD_NAPPY_TO_LIST,
  CLEAR_DAILY_DIARY_NAPPIES,
  GET_DAILY_DIARY_NAPPIES,
  GET_DAILY_DIARY_NAPPIES_FAILED,
  GET_DAILY_DIARY_NAPPIES_SUCCESS,
} from './constants'

import { REMOVE_NAPPIE_SUCCESS, UPDATE_NAPPIE_SUCCESS } from '../single/constants'

import { addDiaryNappyRecord, removeDiaryNappyRecord, updateDiaryNappyRecord } from './helpers'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: {},
}

export default (state = initialState, { error, id, mergeResult, payload, silent, type }) => {
  switch (type) {
    case GET_DAILY_DIARY_NAPPIES:
      return { ...state, error: initialState.error, isFetching: !silent }
    case GET_DAILY_DIARY_NAPPIES_SUCCESS:
      return {
        ...state,
        data: mergeData(state.data, payload.data, mergeResult),
        isFetching: false,
        meta: payload.meta,
      }
    case GET_DAILY_DIARY_NAPPIES_FAILED:
      return { ...state, error, isFetching: false }
    case ADD_NAPPY_TO_LIST: {
      let result = { ...state }

      _.each(payload, (record) => {
        result = addDiaryNappyRecord(result, record)
      })

      return result
    }
    case UPDATE_NAPPIE_SUCCESS:
      return updateDiaryNappyRecord(state, payload)
    case REMOVE_NAPPIE_SUCCESS:
      return removeDiaryNappyRecord(state, id)
    case CLEAR_DAILY_DIARY_NAPPIES:
      return initialState
    default:
      return state
  }
}
