export const GET_NURSERY_FUNDING = 'GET_NURSERY_FUNDING'
export const GET_NURSERY_FUNDING_SUCCESS = 'GET_NURSERY_FUNDING_SUCCESS'
export const GET_NURSERY_FUNDING_FAILED = 'GET_NURSERY_FUNDING_FAILED'

export const CREATE_NURSERY_FUNDING = 'CREATE_NURSERY_FUNDING'
export const CREATE_NURSERY_FUNDING_SUCCESS = 'CREATE_NURSERY_FUNDING_SUCCESS'
export const CREATE_NURSERY_FUNDING_FAILED = 'CREATE_NURSERY_FUNDING_FAILED'

export const UPDATE_NURSERY_FUNDING = 'UPDATE_NURSERY_FUNDING'
export const UPDATE_NURSERY_FUNDING_SUCCESS = 'UPDATE_NURSERY_FUNDING_SUCCESS'
export const UPDATE_NURSERY_FUNDING_FAILED = 'UPDATE_NURSERY_FUNDING_FAILED'

export const ARCHIVE_NURSERY_FUNDING = 'ARCHIVE_NURSERY_FUNDING'
export const ARCHIVE_NURSERY_FUNDING_SUCCESS = 'ARCHIVE_NURSERY_FUNDING_SUCCESS'
export const ARCHIVE_NURSERY_FUNDING_FAILED = 'ARCHIVE_NURSERY_FUNDING_FAILED'

export const CLEAR_NURSERY_FUNDING = 'CLEAR_NURSERY_FUNDING'
