import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledTableContainer = styled.div`
  margin-top: 30px;
`

export const StyledTitle = styled.strong`
  min-width: 100px;
  display: inline-block;
  
  ${({ senary }) => senary && `
    color: ${getBrandingColor('senary-color')};
  `}
`

export const StyledValue = styled.div`
  display: block;
  
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
`

export const StyledValueContainer = styled.div`
  display: flex;
  flex-direction: column;

  & ${StyledValue} ~ ${StyledValue} {
    margin-top: 3px;
  }
`
