import { RESEND_INVITATION, RESEND_INVITATION_FAILED, RESEND_INVITATION_SUCCESS } from './constants'
import { CLEAR_MEMBERSHIP } from '../single/constants'

const initialState = {
  error: null,
  isProcessing: false,
  sent: false,
}

export default (state = initialState, { error, type }) => {
  switch (type) {
    case RESEND_INVITATION:
      return { ...state, error: initialState.error, isProcessing: true, sent: false }
    case RESEND_INVITATION_SUCCESS:
      return { ...state, isProcessing: false, sent: true }
    case RESEND_INVITATION_FAILED:
      return { ...state, error, isProcessing: false, sent: false }
    case CLEAR_MEMBERSHIP:
      return initialState
    default:
      return state
  }
}
