import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'

import { withAppService } from 'services/app'
import { withEnquiryStatusTypesService } from 'services/legacy/enquiries'

import { Select } from 'components'

import withInfiniteDropdown from './withInfiniteDropdown'

class EnquiryStatusTypesDropdown extends Component {
  componentDidMount() {
    const { enquiryStatusTypesActions, infiniteDropdownHelpers, value } = this.props

    if (!_.isArray(value)) {
      return infiniteDropdownHelpers.handleComponentDidMount({
        action: () => enquiryStatusTypesActions.getDropdownItem({
          onFailed: this.notFoundValue,
          onSuccess: infiniteDropdownHelpers.handleComponentDidMountOnSuccess,
          params: [value?.value || value],
        }),
        value,
      })
    }

    return null
  }

  handleLoadMoreElements = async (phrase) => {
    const { enquiryStatusTypesActions, enquiryStatusTypesSelectors, infiniteDropdownHelpers, limit } = this.props

    return infiniteDropdownHelpers.handleLoadMoreElements({
      listAction: ({ newPage, newSearchPhrase, result }) => enquiryStatusTypesActions.dropdownList({
        onSuccess: (response) => infiniteDropdownHelpers.handleLoadMoreElementsOnSuccess({
          extraFields: ['code'],
          newPage,
          newSearchPhrase,
          response,
          result,
        }),
        params: {
          criteria: enquiryStatusTypesSelectors.getCriteria({ name: newSearchPhrase }),
          limit,
          order: {
            sortField: 'ordering',
            sortOrder: 'ASC',
          },
          page: newPage,
        },
      }),
      phrase,
    })
  }

  notFoundValue = () => {
    const { onChange } = this.props

    onChange(null)
  }

  handleChange = (e) => {
    const { infiniteDropdownHelpers, onChange } = this.props

    infiniteDropdownHelpers.handleOnChange(e, () => onChange(e))
  }

  render() {
    const {
      disabled,
      infiniteDropdownState,
      placeholder,
      rawValue,
      value,
      ...rest
    } = this.props
    const { cacheUniq, options, selectedValue } = infiniteDropdownState

    return (
      <Select
        {...rest}
        cacheUniq={cacheUniq}
        disabled={disabled}
        loadOptions={this.handleLoadMoreElements}
        options={options}
        placeholder={placeholder}
        rawValue={rawValue || selectedValue || value}
        value={value}
        searchable
        onChange={this.handleChange}
      />
    )
  }
}

const enhance = compose(
  withAppService,
  withEnquiryStatusTypesService,
  withInfiniteDropdown,
)

export default enhance(EnquiryStatusTypesDropdown)
