import { combineReducers } from 'redux'

import batch from './batch/reducer'
import list from './list/reducer'
import single from './single/reducer'

export default combineReducers({
  batch,
  list,
  single,
})
