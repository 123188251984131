import _ from 'lodash'
import moment from 'moment'
import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { toFloat } from 'utils/data'

import constants from '../constants'

export const getInvoiceList = (state) => state.invoices.list.data
export const getInvoiceListMeta = (state) => state.invoices.list.meta

const getDisplayName = (name, status, type, invoice = {}) => {
  if (status === constants.STATUS_TYPES.CANCELLED) {
    return `${name} (C)`
  }

  if (status === constants.STATUS_TYPES.BAD_DEBT) {
    return `${name} (B)`
  }

  if (type === constants.INVOICE_TYPES.CREDIT_NOTE) {
    const { name: creditNoteRefInvoiceName, number: creditNoteRefInvoiceNumber } = invoice

    return `CN-${creditNoteRefInvoiceName} (${creditNoteRefInvoiceNumber})`
  }

  return name
}

export const getInvoiceListSelector = createSelector(
  [getInvoiceList],
  (state) => {
    if (!state) {
      return null
    }

    const mapInvoices = (item) => {
      const { invoice, name, paymentsTotal, status, total, type } = item
      const { STATUS_TYPES } = constants

      const isDraft = !status || status === STATUS_TYPES.DRAFT

      const isAllowedForPayment = status !== STATUS_TYPES.DRAFT && !!total
      const outstandingBalance = toFloat(total) - toFloat(paymentsTotal)

      const displayName = getDisplayName(name, status, type, invoice)

      return {
        ...item,
        displayName,
        isAllowedForPayment,
        isDraft,
        outstandingBalance,
      }
    }

    return _.map(state, mapInvoices)
  },
)

export const getInvoiceListMetaSelector = createSelector(
  [getInvoiceListMeta],
  (meta) => {
    if (!meta) {
      return null
    }

    return {
      totalResults: meta.total_results,
    }
  },
)

export const getCriteriaSelector = createSelector(
  [(filters) => filters],
  (filters) => {
    if (!filters) {
      return null
    }

    const {
      child,
      dateType = constants.INVOICE_DATE_TYPES.issueDate,
      endDate,
      invoiceEndDate,
      invoiceIds,
      invoiceStartDate,
      not,
      startDate,
      status,
      type,
    } = filters

    const criteria = []

    if (startDate) {
      criteria.push({
        comparator: 'after',
        field: dateType,
        value: moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      })
    }

    if (endDate) {
      criteria.push({
        comparator: 'before',
        field: dateType,
        value: moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      })
    }

    if (invoiceStartDate) {
      criteria.push({
        comparator: 'after',
        field: 'endDate',
        value: moment(invoiceStartDate).format(DEFAULT_DATE_FORMAT),
      })
    }

    if (invoiceEndDate) {
      criteria.push({
        comparator: 'before',
        field: 'startDate',
        value: moment(invoiceEndDate).format(DEFAULT_DATE_FORMAT),
      })
    }

    if (status) {
      criteria.push({
        field: 'status',
        value: status,
      })
    }

    if (child) {
      criteria.push({
        field: 'child',
        value: child,
      })
    }

    if (invoiceIds && invoiceIds.length) {
      _.forEach(invoiceIds, (invoiceId) => {
        criteria.push({
          field: 'id[]',
          value: invoiceId,
        })
      })
    }

    if (type) {
      criteria.push({
        field: 'type',
        value: type,
      })
    }

    if (not) {
      const { status: statusNotValue } = not

      if (statusNotValue) {
        if (_.isArray(statusNotValue)) {
          _.each(statusNotValue, (value) => {
            criteria.push({
              field: 'not[status][]',
              value,
            })
          })
        } else {
          criteria.push({
            field: 'not[status]',
            value: statusNotValue,
          })
        }
      }
    }

    return criteria
  },
)

export const getBadDebtCriteriaSelector = createSelector(
  [(filters) => filters],
  (filters) => {
    if (!filters) {
      return null
    }

    const { badDebtDate, child, dateType, room } = filters
    const { after, before } = badDebtDate
    const { STATUS_TYPES } = constants

    const criteria = [
      {
        field: 'status',
        value: STATUS_TYPES.BAD_DEBT,
      },
      {
        field: 'type',
        value: 'invoice',
      },
    ]

    if (after) {
      criteria.push({
        comparator: 'after',
        field: dateType,
        value: moment(after).format(DEFAULT_DATE_FORMAT),
      })
    }

    if (before) {
      criteria.push({
        comparator: 'before',
        field: dateType,
        value: moment(before).format(DEFAULT_DATE_FORMAT),
      })
    }

    if (room) {
      criteria.push({
        field: 'child.nurseryClass',
        value: room,
      })
    }

    if (child) {
      criteria.push({
        field: 'child',
        value: child,
      })
    }

    return criteria
  },
)

export const getRevenueCriteria = (filters) => {
  const {
    date,
    dateType,
    nursery,
    status,
  } = filters || {}

  const criteria = []

  if (date) {
    const { INVOICE_DATE_TYPES } = constants
    const { after, before } = date
    const field = dateType || INVOICE_DATE_TYPES.processedDate

    if (after) {
      criteria.push({
        comparator: 'after',
        field,
        value: field === INVOICE_DATE_TYPES.processedDate
          ? moment(after).startOf('day').toISOString()
          : moment(after).format(DEFAULT_DATE_FORMAT),
      })
    }

    if (before) {
      criteria.push({
        comparator: 'before',
        field,
        value: field === INVOICE_DATE_TYPES.processedDate
          ? moment(before).endOf('day').toISOString()
          : moment(before).format(DEFAULT_DATE_FORMAT),
      })
    }
  }

  if (status && status !== constants.STATUS_TYPES.ALL) {
    criteria.push({
      field: 'status',
      value: status,
    })
  } else {
    criteria.push({
      field: 'not[status]',
      value: constants.STATUS_TYPES.DRAFT,
    })
  }

  if (nursery) {
    criteria.push({
      field: 'child.nursery',
      value: nursery,
    })
  }

  return criteria
}

export const getAccountingCriteriaSelector = createSelector(
  [(filters) => filters],
  (filters) => {
    if (!filters) {
      return null
    }

    const { child, creditNotes, dateRange, invoices, sendTo } = filters

    const criteria = []

    if (dateRange?.length) {
      const [startDate, endDate] = dateRange

      criteria.push(
        {
          field: 'processedDate[after]',
          value: startDate.format(DEFAULT_DATE_FORMAT),
        },
        {
          field: 'processedDate[before]',
          value: endDate.format(DEFAULT_DATE_FORMAT),
        },
      )
    }

    if (sendTo) {
      criteria.push({
        field: 'membership',
        value: sendTo.value,
      })
    }

    if (child && 0 !== child.value) {
      criteria.push({
        field: 'child',
        value: child.value,
      })
    }

    if (creditNotes && !invoices) {
      criteria.push({
        field: 'type[]',
        value: 'creditNote',
      })
    }

    if (invoices && !creditNotes) {
      criteria.push({
        field: 'type[]',
        value: 'invoice',
      })
    }

    return criteria
  },
)

// TODO: immediate solution that we will fix if that edge case happen
export const hasOnlyDraftInvoices = createSelector(
  [getInvoiceListSelector],
  (invoiceList) => {
    if (!invoiceList?.length) {
      return false
    }

    return !_.some(invoiceList, ({ isDraft }) => !isDraft)
  },
)
