import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class monthlyFteChangeReportApiClient extends ServiceBase {
  getNurseries = (params) => this.request({
    path: `/v2/nurseries/statistics/monthly_fte_change?${getQueryStringV2(params)}`,
  })

  getStatistics = (params) => this.request({
    path: `/v2/statistics/monthly_fte_change?${getQueryStringV2(params)}`,
  })
}

export default monthlyFteChangeReportApiClient
