import {
  ADD_ACTIVITY,
  ADD_ACTIVITY_FAILED,
  ADD_ACTIVITY_SUCCESS,
  REMOVE_ACTIVITY,
  REMOVE_ACTIVITY_FAILED,
  REMOVE_ACTIVITY_SUCCESS,
  SET_TEMPORARY_ACTIVITY_ID,
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_FAILED,
  UPDATE_ACTIVITY_SUCCESS,
} from './constants'

const initialState = {
  isSubmitting: false,
  temporaryActivityId: null,
}

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case ADD_ACTIVITY:
    case REMOVE_ACTIVITY:
    case UPDATE_ACTIVITY:
      return { ...state, isSubmitting: true }
    case REMOVE_ACTIVITY_FAILED:
    case REMOVE_ACTIVITY_SUCCESS:
    case ADD_ACTIVITY_FAILED:
    case ADD_ACTIVITY_SUCCESS:
    case UPDATE_ACTIVITY_FAILED:
    case UPDATE_ACTIVITY_SUCCESS:
      return { ...state, isSubmitting: false }
    case SET_TEMPORARY_ACTIVITY_ID: {
      return { ...state, temporaryActivityId: payload }
    }
    default:
      return state
  }
}
