import { objectToQueryString } from 'utils/api'

const REGEXP_BLOB_URL = /^blob:.+\/[\w-]{36,}(?:#.+)?$/

export const getImageExist = (uri) => new Promise((resolve, reject) => {
  const image = new window.Image()

  image.onload = () => resolve(image)
  // eslint-disable-next-line prefer-promise-reject-errors
  image.onerror = () => reject(false)
  image.src = uri
})

export const getOptimizedImage = (src, settings = {}) => {
  const { height, max = false, width } = settings as any
  const params = {
    height,
    width,
  } as any

  if (1 < window.devicePixelRatio) {
    params.width = width * 2
    params.height = height * 2
  }

  if (max) {
    params.max = true
  }

  if (-1 < src?.indexOf('?')) {
    return `${src}&${objectToQueryString(params)}`
  }

  return `${src}?${objectToQueryString(params)}`
}

export const isBlobUrl = (url) => REGEXP_BLOB_URL.test(url)

export const convertBlobToBase64 = (blob) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onerror = reject
  reader.onload = () => resolve(reader.result)
  reader.readAsDataURL(blob)
})
