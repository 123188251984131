import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { REGISTER_STATUS } from 'module/Register/constants'

import RegisterActivity from './components/RegisterActivity'

export const getFilteredData = (registerList, status) => {
  if (!status) {
    return registerList
  }

  const filteredRegisterList = _.filter(registerList, (res) => {
    if (!res.registers?.length) {
      return false
    }

    if (REGISTER_STATUS.ABSENT === status) {
      return !!res.registers[0].absence
    }

    const lastSignIns = res.registers[0].signIns?.[res.registers[0].signIns.length - 1]

    if (!lastSignIns) {
      return false
    }

    if (REGISTER_STATUS.TIMED_IN === status) {
      return lastSignIns.signedInAt && !lastSignIns.signedOutAt
    }

    if (REGISTER_STATUS.TIMED_OUT === status) {
      return lastSignIns.signedInAt && lastSignIns.signedOutAt
    }

    return true
  })

  return filteredRegisterList
}

export const getUpdatedSignIns = (registerList, childId, itemId, newSignInItem, isChildRegister) => {
  const childRegisterItemId = isChildRegister ? itemId : childId
  const childRegisterItem = registerList.find(({ id }) => id === childRegisterItemId)

  if (!childRegisterItem) {
    return null
  }

  const getRegister = (item) => {
    if (itemId) {
      return item
    }

    const { registers } = item

    return registers && 0 < registers.length ? registers[0] : null
  }

  const register = getRegister(childRegisterItem)
  const signIns = register ? register.signIns : []

  if (!newSignInItem.id) {
    const formattedSignIns = _.map(signIns, (signIn) => {
      const { id, signedInAt, signedOutAt } = signIn

      return {
        id,
        signedInAt: signedInAt ? moment(signedInAt).toDate() : null,
        signedOutAt: signedOutAt ? moment(signedOutAt).toDate() : null,
      }
    })

    return _.concat(formattedSignIns, newSignInItem)
  }

  return _.map(signIns, (signIn) => {
    const { id, signedInAt, signedOutAt } = signIn

    if (id === newSignInItem.id) {
      const { signedInAt: newSignedInAt, signedOutAt: newSignedOutAt } = newSignInItem

      return {
        id,
        signedInAt: newSignedInAt ? moment(newSignedInAt).toDate() : null,
        signedOutAt: newSignedOutAt ? moment(newSignedOutAt).toDate() : null,
      }
    }

    return {
      id,
      signedInAt: signedInAt ? moment(signedInAt).toDate() : null,
      signedOutAt: signedOutAt ? moment(signedOutAt).toDate() : null,
    }
  })
}

const getSignIns = (signIns) => _.map(signIns, (item) => {
  const { id, signedInAt, signedOutAt } = item

  return {
    id,
    signedInAt: signedInAt ? moment(signedInAt).toDate() : null,
    signedOutAt: signedOutAt ? moment(signedOutAt).toDate() : null,
  }
})

export const getPayload = (registerList, childId, id, newValues, newEntryDate) => {
  const childRegisterItemId = id || childId
  const childRegisterItem = registerList.find((item) => item.id === childRegisterItemId)

  if (!childRegisterItem) {
    return null
  }

  const getRegister = (item) => {
    if (id) {
      return item
    }

    const { registers } = item

    return registers && 0 < registers.length ? registers[0] : null
  }

  const register = getRegister(childRegisterItem)

  const entryDate = register ? register.entryDate : null
  const present = register ? register.present : null
  const comments = register ? register.comments : null
  const signIns = register ? register.signIns : null
  const absence = register ? register.absence : null

  const getEntryDate = () => {
    if (entryDate) {
      return moment(entryDate).format('YYYY-MM-DD')
    }

    if (newEntryDate) {
      return moment(newEntryDate).format('YYYY-MM-DD')
    }

    return new Date()
  }

  return {
    absence,
    comments: comments || '',
    entryDate: getEntryDate(),
    present,
    signIns: getSignIns(signIns),
    ...newValues,
  }
}

export const getStaffLogs = (staffLogs) => _.map(staffLogs, (item) => {
  const { actionPerformed, id, loggedAt, staff } = item

  return {
    date: moment.utc(loggedAt).toDate(),
    id,
    staff,
    text: <RegisterActivity actionPerformed={actionPerformed} staff={staff} />,
  }
})

export const getNewEntryDate = (date) => moment(date).format('YYYY-MM-DD')

export const getCurrentDateTime = (date) => {
  const selectedDate = moment(date)
  const currentDate = moment()

  return selectedDate.set({
    hour: currentDate.get('hour'),
    minute: currentDate.get('minute'),
    second: currentDate.get('second'),
  }).toDate()
}

export const getExtraSessionStartAndEndTime = (extraSession) => {
  if (!extraSession) {
    return null
  }

  const { endTime, nurserySession, startTime } = extraSession

  if (startTime && endTime) {
    return { endTime, startTime }
  }

  const { endTime: sessionEndTime, startTime: sessionStartTime } = nurserySession

  return { endTime: sessionEndTime, startTime: sessionStartTime }
}

export const removeId = (payload) => ({
  ...payload,
  signIns: _.map(payload.signIns, (item) => {
    const newItem = { ...item }

    if (_.isString(newItem.id)) {
      delete newItem.id
    }

    return newItem
  }),
})
