import React from 'react'
import { WrappedFieldProps } from 'redux-form'

import { DraggableListProps } from 'components/DraggableList/DraggableList'

import { DraggableList } from 'components'

type FormDraggableListFullProps = DraggableListProps & WrappedFieldProps

const FormDraggableList: React.FC<FormDraggableListFullProps> = ({
  input: { onChange, value },
  ...rest
}) => (
  <DraggableList
    {...rest}
    items={value}
    onChange={onChange}
  />
)

export default FormDraggableList
