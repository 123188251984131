import styled from 'styled-components'
import color from 'color'

import { Link } from 'react-router'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

import { StyledSpinnerAnimation } from 'components/Spinner/SpinnerStyled'

export const StyledButton = styled.div`
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  margin: 0 8px;
`

export const StyledSpinnerWrapper = styled.div`
  ${StyledSpinnerAnimation} {
    &:before {
      border-color: ${getBrandingColor('senary-color')} !important;
    }
  }
`

export const StyledBadge = styled.div`
  position: absolute;
  right: -6px;
  top: -3px;
  z-index: 10;
`

export const StyledUploadBox = styled.div`
  width: 350px;
  max-height: 460px;
  display: grid;
  grid-template-rows: 55px auto 48px;
`

export const StyledUploadBoxHeader = styled.div`
  padding: 0 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};
`

export const StyledUploadBoxContent = styled.div`
  flex: 1;
  overflow: auto;
  max-height: 358px;
`

export const StyledUploadBoxFooter = styled.div`
  padding: 0 15px;
  display: flex;
  align-items: center;
  border-top: 1px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};
`

export const StyledFileSectionHeader = styled.header`
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 10px 20px;
  grid-column-gap: 5px;
  transition: .4s;
  background: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};

  &:hover {
    background: ${color(getBrandingColor('primary-color')).alpha(0.08).rgb().string()};
  }
`

export const StyledFileBox = styled.div`
  padding: 10px 20px;
  display: grid;
  grid-template-columns: 24px auto 18px;
  grid-column-gap: 15px;
  min-height: 80px;
  align-items: center;
  border-bottom: 1px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  
  ${({ isUploaded }) => isUploaded && `
    &:hover {
      background: ${color(getBrandingColor('primary-color')).alpha(0.08).rgb().string()};
    }
  `}
`

export const StyledFileDetails = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding-right: 5px;
`

export const StyledUploadBar = styled.div`
  position: relative;
  height: 4px;
  border-radius: 8px;
  background: ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  margin: 7px 0;
  width: 100%;
`

export const StyledUploadProgress = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 8px;
  background: ${NEUTRAL_COLOURS.SUCCESS};
  transition: .4s;
  
  ${({ percentage }) => percentage && `
    width: ${percentage}%;
  `}
`

export const StyledLink = styled(Link)`
  color: inherit;
  
  &:hover, &:focus {
    color: inherit;
  }
`

export const StyledEmptySpinner = styled.div`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: ${color(getBrandingColor('senary-color')).alpha(0.15).rgb().string()} !important;
  position: relative;
  margin-left: 2px;
  
  &:before {
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    background: ${color(getBrandingColor('primary-color')).mix(color(NEUTRAL_COLOURS.WHITE), 0.1).string()};
    content: '';
  }
`
