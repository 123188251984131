import React from 'react'

import { convertFromSecondsToMinutes } from 'utils/date'

import { CircleIcon, Spinner, Tooltip } from 'components'
import InlineEditableField from 'module/Management/components/InlineEditableField'

import i18n from 'translations'

import SessionTimeoutForm, { SESSION_TIMEOUT_FORM } from './SessionTimeoutForm'

import { SecuritySettingsProps } from '../../GeneralSecuritySettingsView'
import { StyledTitleContainer } from './SessionTimeoutStyled'

interface SessionTimeoutProps {
  isFetching?: boolean
  isInheritedFromOrganization?: boolean
  onSubmit: (formValues) => void
  values?: SecuritySettingsProps
}

const SessionTimeout: React.FC<SessionTimeoutProps> = ({
  isFetching,
  isInheritedFromOrganization,
  onSubmit,
  values,
}) => {
  const initialValues = {
    sessionExpireTime: values?.sessionExpireTime
      ? convertFromSecondsToMinutes(values.sessionExpireTime)
      : null,
    sessionExpireTimeEnabled: values?.sessionExpireTimeEnabled,
  }
  const description = values?.sessionExpireTimeEnabled
    ? (
      <React.Fragment>
        {i18n.t('module:Management:Security:GeneralSecuritySettings:SessionTimeout:timeoutLabelPrefix')}
        {' '}
        {convertFromSecondsToMinutes(values?.sessionExpireTime)}
        {' '}
        {i18n.t('module:Management:Security:GeneralSecuritySettings:SessionTimeout:timeoutLabelSuffix')}
      </React.Fragment>
    )
    : i18n.t('global:Off')

  return (
    <InlineEditableField
      description={isFetching ? (
        <Spinner position="flex-start" />
      ) : description}
      editableComponent={(callback, isSubmitting) => (
        <SessionTimeoutForm
          initialValues={initialValues}
          isSubmitting={isSubmitting}
          onSubmit={callback}
        />
      )}
      formName={SESSION_TIMEOUT_FORM}
      hideActions={isInheritedFromOrganization}
      title={(
        <StyledTitleContainer>
          {i18n.t('module:Management:Security:GeneralSecuritySettings:SessionTimeout:label')}
          <Tooltip
            title={i18n.t('module:Management:Security:GeneralSecuritySettings:SessionTimeout:tooltip')}
            inline
          >
            <CircleIcon
              cursor="pointer"
              icon="info"
              iconSize={16}
              size={24}
            />
          </Tooltip>
        </StyledTitleContainer>
      )}
      value={values}
      enableHandleFailed
      onSubmit={onSubmit}
    />
  )
}

export default SessionTimeout

