import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { change, getFormValues, stopSubmit } from 'redux-form'

import { STAFF_MODULE_TYPE } from 'services/legacy/memberships/constants'

import { generateRoute } from 'utils/routing'

import { EVENTS, logEvent } from 'analytics'

import { withMembershipsLeavesService } from 'services/legacy/membershipsLeaves'
import { withMembershipsService } from 'services/legacy/memberships'
import { withModalService } from 'services/utils/modal'
import { withRouter } from 'services/router'

import withStaffExportModal from 'module/Modals/Staff/withStaffExportModal'

import { BREAKDOWN, REPORT_TYPE, REPORT_TYPE_OPTIONS } from './constants'
import { EXPORT_STAFF_LEAVE_MODAL_FORM } from './components/ExportModalForm'
import ExportModalView from './ExportModalView'

const GROUPS = {
  read: [
    'membershipShift.membershipShiftTimes',
    'membershipShiftSettings',
    'membershipShiftTime',
    'membershipShiftTime.class',
    'membership.shifts',
  ],
}

class ExportModalContainer extends Component {
  componentWillUnmount() {
    const { location: { pathname }, membershipsActions } = this.props

    if (pathname === generateRoute('STAFF.LEAVE')) {
      membershipsActions.clear()
    }
  }

  handleBreakdownChange = (e) => {
    const { changeFieldValue, staffExportHelpers } = this.props

    return staffExportHelpers.handleBreakdownChange({
      BREAKDOWN,
      changeFieldValue,
      value: e.target.value,
    })
  }

  handleReportDateChange = (e) => {
    const { changeFieldValue, staffExportHelpers } = this.props

    return staffExportHelpers.handleReportDateChange({
      changeFieldValue,
      value: e.target.value,
    })
  }

  handleSendReport = (values) => {
    const {
      membershipsLeavesSelectors,
      staffExportHelpers,
      stopSubmitForm,
    } = this.props

    const { breakdown, reportType, sendTo } = values

    if (_.find(REPORT_TYPE_OPTIONS, { value: REPORT_TYPE.SICKNESS }) === reportType) {
      const criteria = membershipsLeavesSelectors.getSicknessExportCriteria(values)

      const body = {
        criteria,
      }

      return staffExportHelpers.handleSendReport({
        moduleType: REPORT_TYPE.SICKNESS,
        onSuccess: () => logEvent(EVENTS.STAFF_LEAVE_EXPORTED),
        params: body,
        sendTo,
        stopSubmitForm,
        type: 'sickness-total',
      })
    }

    const reportDatesRange = staffExportHelpers.getDateRange(values)

    const criteria = membershipsLeavesSelectors.getLeaveExportCriteria({
      ...values,
      reportDatesRange,
    })

    const body = {
      criteria,
      groups: GROUPS,
    }

    return staffExportHelpers.handleSendReport({
      moduleType: STAFF_MODULE_TYPE.LEAVE,
      onSuccess: () => logEvent(EVENTS.STAFF_LEAVE_EXPORTED),
      params: body,
      sendTo,
      stopSubmitForm,
      type: breakdown,
    })
  }

  handleReportTypeChange = () => {
    const { changeFieldValue } = this.props

    return changeFieldValue('reportDatesRange', [moment().startOf('month'), moment()])
  }

  render() {
    const { formValues, membership, staffExportHelpers, staffExportState } = this.props
    const { isExporting } = staffExportState

    const initialValues = {
      ...staffExportHelpers.getInitialValues({ REPORT_TYPE_OPTIONS, breakdown: BREAKDOWN.DAILY }),
      staffMembers: membership ? {
        email: membership.email,
        label: membership.name,
        value: membership.id,
      } : null,
    }

    return (
      <ExportModalView
        formValues={formValues}
        initialValues={initialValues}
        isProcessing={isExporting}
        onBreakdownChange={this.handleBreakdownChange}
        onCloseClick={staffExportHelpers.handleCloseClick}
        onReportDateChange={this.handleReportDateChange}
        onReportTypeChange={this.handleReportTypeChange}
        onSubmit={this.handleSendReport}
      />
    )
  }
}

const mapState = (state) => ({
  formValues: getFormValues(EXPORT_STAFF_LEAVE_MODAL_FORM)(state),
})

const mapDispatch = {
  changeFieldValue: (field, value) => change(EXPORT_STAFF_LEAVE_MODAL_FORM, field, value),
  stopSubmitForm: (errors) => stopSubmit(EXPORT_STAFF_LEAVE_MODAL_FORM, errors),
}

const enhance = compose(
  withModalService,
  withMembershipsLeavesService,
  withMembershipsService,
  withRouter,
  withStaffExportModal,
  connect(mapState, mapDispatch),
)

export default enhance(ExportModalContainer)
