import moment from 'moment'
import _ from 'lodash'

import { createSelector } from 'reselect'

import { NEXT_STEPS_FILTERS } from '../constants'

const getNextSteps = (state) => state.nextSteps

export const getNextStepsListState = createSelector([getNextSteps], (state) => state.list)

export const getNextStepsListDataState = createSelector([getNextStepsListState], (state) => state.data)

export const getNextStepsListMetaState = createSelector([getNextStepsListState], (state) => state.meta)

export const getNextStepsListWithMontessori = createSelector(
  [getNextStepsListDataState],
  (data) => _.map(data, (item) => {
    const { comments, frameworkAreas, frameworkName, montessoriActivity } = item

    return ({
      ...item,
      comments: montessoriActivity ? montessoriActivity?.name : comments,
      frameworkAreas: montessoriActivity ? [{ name: frameworkName }] : frameworkAreas,
    })
  }),
)

export const getNextStepsListCriteria = (filters) => {
  const { childId, date, isChildArchived, keyPerson, room, search, type } = filters

  const criteria = [{
    field: 'order[childObservation.observation.observationDate]',
    value: 'DESC',
  }]

  const [from, to] = date || []

  if (from && to) {
    criteria.push({
      comparator: 'after',
      field: 'childObservation.observation.observationDate',
      value: moment(from).startOf('day').toISOString(),
    }, {
      comparator: 'before',
      field: 'childObservation.observation.observationDate',
      value: moment(to).endOf('day').toISOString(),
    })
  }

  if (room) {
    criteria.push({
      field: 'childObservation.child.nurseryClass',
      value: room,
    })
  }

  if (keyPerson) {
    criteria.push({
      field: 'childObservation.child.keyWorkers[]',
      value: keyPerson,
    })
  }

  if (NEXT_STEPS_FILTERS.WORKING_ON === type) {
    criteria.push({
      field: 'achievedAt[exists]',
      value: 0,
    })
  }

  if (NEXT_STEPS_FILTERS.ACHIEVED === type) {
    criteria.push({
      field: 'achievedAt[exists]',
      value: 1,
    }, {
      field: 'nextObservation[exists]',
      value: 1,
    })
  }

  if (NEXT_STEPS_FILTERS.ACHIEVED_NO_OBSERVATION === type) {
    criteria.push({
      field: 'achievedAt[exists]',
      value: 1,
    }, {
      field: 'nextObservation[exists]',
      value: 0,
    })
  }

  if (search) {
    criteria.push({
      field: 'concatenable',
      value: search,
    })
  }

  if (childId) {
    criteria.push({
      field: 'childObservation.child',
      value: childId,
    })
  }

  if (!childId) {
    criteria.push({
      field: 'childObservation.child.isArchived',
      value: !!isChildArchived,
    })
  }

  return criteria
}
