export const CHILD_FUNDING_STATISTICS = 'CHILD_FUNDING_STATISTICS'
export const CHILD_FUNDING_STATISTICS_SUCCESS = 'CHILD_FUNDING_STATISTICS_SUCCESS'
export const CHILD_FUNDING_STATISTICS_FAILED = 'CHILD_FUNDING_STATISTICS_FAILED'

export const CLEAR_CHILD_FUNDING_STATISTICS = 'CLEAR_CHILD_FUNDING_STATISTICS'

export const getChildFundingStatistics = (params) => ({ childFundingApiClient }) => (dispatch) => {
  dispatch({
    params,
    type: CHILD_FUNDING_STATISTICS,
  })

  childFundingApiClient.statistics(params).then(
    (data) => {
      dispatch({
        payload: data,
        type: CHILD_FUNDING_STATISTICS_SUCCESS,
      })
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: CHILD_FUNDING_STATISTICS_FAILED,
      })
    },
  )
}

export const clearChildFundingStatistics = () => ({
  type: CLEAR_CHILD_FUNDING_STATISTICS,
})
