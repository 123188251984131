import styled from 'styled-components'

export const StyledItemContainer = styled.div`
  ${({ width }) => width && `width: ${width};`}
`

export const StyledGroupContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;

  & ${StyledItemContainer} ~ ${StyledItemContainer} {
    margin-left: 10px;
  }
`

export const StyledContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  
  & ${StyledGroupContainer} ~ ${StyledGroupContainer} {
    margin-left: 20px;
  }
`

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    margin-top: 10px;  
  }
`

export const StyledErrorContainer = styled.div`
  display: flex;
  padding-top: 5px;
`
