import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { StyledHeader, StyledSearchWrapper } from 'components/Conversations/ConversationsStyled'

export const StyledChannelListMobileWrapper = styled.div`
  display: none;
  
  @media(max-width: 700px) {
    display: block;
  }
`

export const StyledChannelListMobile = styled.div`
  background: ${NEUTRAL_COLOURS.WHITE};
  border-right: 2px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  padding: 16px;
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100%;
  
  ${StyledHeader} {
    display: grid;
    grid-row-gap: 0;
    grid-template-rows: 80px auto;
    margin-top: -16px;
    align-items: center;
  }
  
  ${StyledSearchWrapper} {
    margin-top: -10px;
  }
`
