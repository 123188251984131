import { flatten, nest } from 'utils/flatnest'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { LIST_STATUS_FILTERS } from 'constants/filters'
import { ROLES } from 'constants/security'

import { withAbsenceReasonsService } from 'services/legacy/absenceReasons'
import { withAppService } from 'services/app'
import { withPaginationUtils } from 'services/utils/pagination'

import { getTableData } from './ChildAbsenceReasonsHelpers'
import ChildAbsenceReasonsView from './ChildAbsenceReasonsView'

class ChildAbsenceReasonsContainer extends Component {
  constructor(props) {
    super(props)

    const { query } = props.location
    const { status } = nest(query)

    this.state = {
      filters: {
        status: status || LIST_STATUS_FILTERS.ACTIVE,
      },
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { absenceReasonsActions } = this.props

    absenceReasonsActions.clearList()
  }

  fetch = () => {
    const {
      absenceReasonsActions,
      absenceReasonsSelectors,
      paginationUtils,
      setLocationQuery,
    } = this.props
    const { filters } = this.state
    const { page } = paginationUtils

    const criteria = absenceReasonsSelectors.getListCriteria(filters)

    setLocationQuery(flatten(filters))

    absenceReasonsActions.list({
      params: {
        criteria,
        order: {
          sortField: 'name',
          sortOrder: 'asc',
        },
        page,
      },
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleStatusChange = (status) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          status: status?.value,
        },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  render() {
    const {
      data,
      errorMessages,
      isFetching,
      paginationUtils,
      totalResults,
    } = this.props
    const { filters } = this.state
    const { status } = filters
    const { getPageCount, page, perPage } = paginationUtils

    const pageCount = getPageCount(totalResults)
    const tableData = getTableData(data)

    return (
      <ChildAbsenceReasonsView
        data={tableData}
        errorMessages={errorMessages}
        isFetching={isFetching}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        status={status}
        totalResults={totalResults}
        onPageChange={this.handlePageChange}
        onStatusChange={this.handleStatusChange}
      />
    )
  }
}

ChildAbsenceReasonsContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

const mapState = (state, {
  absenceReasonsListState,
  absenceReasonsSelectors,
  appSelectors,
}) => ({
  data: absenceReasonsSelectors.getAbsenceReasonsListDataSelector(state),
  errorMessages: appSelectors.getErrorMessages(absenceReasonsListState),
  isFetching: appSelectors.getIsFetching(absenceReasonsListState),
  totalResults: appSelectors.getTotalResults(absenceReasonsListState),
})

const enhance = compose(
  withAbsenceReasonsService,
  withAppService,
  withPaginationUtils,
  connect(mapState),
)

export default enhance(ChildAbsenceReasonsContainer)
