import React from 'react'
import { reduxForm } from 'redux-form'

import { Form, InfiniteScroll, Section, Spinner, Table } from 'components'

import i18n from 'translations'

import FeatureFlagsFormSection from 'module/Organisations/OrganisationsAdd/components/FeatureFlagsFormSection'

import OpeningTimesFormSection from './OpeningTimesFormSection'
import FeesSection from './FeesSection'
import NurseryForm from '../../components/NurseryForm'

export const NURSERIES_EDIT_FORM = 'NurseriesEditForm'
const COLUMNS = [
  {
    align: 'left',
    field: 'name',
    title: i18n.t('global:Name'),
  },
  {
    field: 'switch',
    width: '10%',
  },
]

const NurseriesEditForm = ({
  cancelButtonLink,
  formValues,
  frameworkPagination,
  frameworks,
  handleSubmit,
  isAdministrationContext,
  isArchived,
  isOrganizationContext,
  isSubmitting,
  nursery,
  nurserySettings,
  onApplyAll,
  onDisableClick,
  onFeatureFlagChange,
  onFrameworkPageChange,
  onSubmit,
  showFooter = true,
  showFramework,
}) => {
  const { isLoading, page, pageCount } = frameworkPagination
  const isOrganizationNursery = !!nursery?.organization
  const isFinanceLocked = nursery?.nurserySettings?.financeLocked

  const renderOpeningTimesSection = () => isOrganizationContext && (
    <OpeningTimesFormSection
      onApplyAll={onApplyAll}
    />
  )

  const renderFrameworkSection = () => {
    const renderFrameworkContent = () => {
      if (isLoading) {
        return <Spinner />
      }

      return (
        <InfiniteScroll
          dataLength={frameworks?.length || 0}
          hasMore={page < pageCount}
          next={() => onFrameworkPageChange((+page) + 1)}
        >
          <Table
            columns={COLUMNS}
            data={frameworks}
            visualType="flat"
          />
        </InfiniteScroll>
      )
    }

    return (
      <React.Fragment>
        <Section
          title={i18n.t('module:Management:Learning:Curriculums:title')}
        />
        {renderFrameworkContent()}
      </React.Fragment>
    )
  }

  const renderAdministrationSections = () => {
    if (!isAdministrationContext) {
      return null
    }

    return (
      <React.Fragment>
        <FeesSection
          nursery={nursery}
        />
        <FeatureFlagsFormSection
          formValues={formValues}
          nursery={nursery}
          onFeatureFlagChange={onFeatureFlagChange}
        />
        {showFramework && renderFrameworkSection()}
      </React.Fragment>
    )
  }

  const renderForm = () => (
    <React.Fragment>
      <NurseryForm
        isAdministrationContext={isAdministrationContext}
        isFinanceLocked={isFinanceLocked}
        isOrganizationNursery={isOrganizationNursery}
        nurserySettings={nurserySettings}
        isEditingContext
      />
      {renderOpeningTimesSection()}
      {renderAdministrationSections()}
    </React.Fragment>
  )

  const renderFooter = () => showFooter && (
    <Form.FooterActions
      cancelLink={cancelButtonLink}
      isSubmitting={isSubmitting}
      secondary={{
        isLoading: isSubmitting,
        label: isArchived
          ? i18n.t('module:Nurseries:Edit:Enable:label')
          : i18n.t('module:Nurseries:Edit:Disable:label'),
        onClick: onDisableClick,
      }}
      submitLabel={i18n.t('module:Nurseries:Edit:Update:label')}
    />
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {renderForm()}
      {renderFooter()}
    </Form>
  )
}

export default reduxForm({ form: NURSERIES_EDIT_FORM })(NurseriesEditForm)
