import _ from 'lodash'
import React from 'react'

import { getBrandingColor } from 'utils/branding'

import { StyledProgress, StyledProgressContainer, StyledProgressText } from './WeeklySchedulerStyled'

import { getHourDiffBetweenTime, isTimeRangesOverlapping } from './utils/dateUtils'
import { CELL_WIDTH, ROW_HEADER_WIDTH } from './constants'

const WeeklySchedulerProgress = ({
  onItemClick,
  progress,
  startTime,
}) => {
  const {
    backgroundColor = getBrandingColor('primary-color'),
    color,
    disabled,
    name,
    startTime: progressStartTime,
    endTime: progressEndTime,
    nestedProgressList,
  } = progress

  const offsetHours = getHourDiffBetweenTime(startTime, progressStartTime)
  const progressHourDifference = getHourDiffBetweenTime(progressStartTime, progressEndTime)

  const left = (offsetHours * CELL_WIDTH) + ROW_HEADER_WIDTH
  const total = progressHourDifference * CELL_WIDTH

  const timeRangesOverlapping = isTimeRangesOverlapping(nestedProgressList)
  const maxNestedProgressCount = nestedProgressList ? nestedProgressList.length : 0

  const renderNestedProgress = ({
    backgroundColor: nestedBackgroundColor = getBrandingColor('secondary-color'),
    endTime: nestedEndTime,
    name: nestedName,
    startTime: nestedStartTime,
  }, index) => {
    const nestedHourDifference = getHourDiffBetweenTime(nestedStartTime, nestedEndTime)
    const nestedOffsetHours = getHourDiffBetweenTime(progressStartTime, nestedStartTime)

    const nestedLeft = nestedOffsetHours * CELL_WIDTH
    const nestedWidth = nestedHourDifference * CELL_WIDTH

    return (
      <React.Fragment>
        <StyledProgress
          background={nestedBackgroundColor}
          key={index}
          left={nestedLeft}
          top={timeRangesOverlapping ? ((index + 1) * 50) : null}
          width={nestedWidth}
        />
        <StyledProgressText
          left={nestedLeft}
          opacity={0.5}
          top={timeRangesOverlapping ? ((index + 1) * 50) : null}
          width={nestedWidth}
        >
          {nestedName}
        </StyledProgressText>
      </React.Fragment>
    )
  }

  return (
    <StyledProgressContainer
      clickable={!disabled}
      height={timeRangesOverlapping ? ((maxNestedProgressCount + 1) * 50) : null}
      left={left}
      top={10}
      width={total}
      onClick={!disabled ? onItemClick : null}
    >
      <StyledProgress
        background={backgroundColor}
        height={timeRangesOverlapping ? ((maxNestedProgressCount + 1) * 50) : null}
        width={total}
      />
      {_.map(nestedProgressList, renderNestedProgress)}
      <StyledProgressText
        color={color}
        width={total}
      >
        {name}
      </StyledProgressText>
    </StyledProgressContainer>
  )
}

export default WeeklySchedulerProgress
