import moment from 'moment'
import styled from 'styled-components'
import color from 'color'

import { AVAILABLE_DAYS, SHIFT_MAIN_TYPES, WORK_SHIFT_TYPES } from 'services/legacy/membershipRegisters/constants'
import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'
import { hour24InMilliseconds } from 'constants/date'

import { getBrandingColor } from 'utils/branding'

import { StyledCallout } from 'components/Callout/CalloutStyled'

export const DAY_RECORD_COLOURS = {
  [SHIFT_MAIN_TYPES.WORK]: {
    background: '#DDF1FA',
    border: NEUTRAL_COLOURS.BLUE,
  },
  [SHIFT_MAIN_TYPES.LEAVE]: {
    background: '#FEF4D6',
    border: '#F6BA36',
  },
  BREAK: '#84D0F1',
}

export const StyledDayBox = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  margin-right: 8px;
  margin-bottom: 8px;
  border: 2px solid ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  background: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  padding: 10px 15px;
  cursor: pointer;
  
  ${({ isActive }) => isActive && `
    background: ${color(getBrandingColor('primary-color')).alpha(0.2).rgb().string()};
    border-color: ${getBrandingColor('primary-color')};
  `}
`

export const StyledDayListContainer = styled.div`
  margin: 15px 0;
  display: grid;
  grid-template-columns: repeat(${AVAILABLE_DAYS}, 14.28%);
  position: relative;
  
  @media(max-width: 980px) {
    grid-template-columns: repeat(4, 25%);
  }
    
  @media(max-width: 640px) {
    grid-template-columns: repeat(2, 50%);
  }
      
  @media(max-width: 420px) {
    grid-template-columns: auto;
  }
  
  &:hover {
    z-index: 280;
  }
`

export const StyledDayTimelineWrapper = styled.div`
  padding: 10px 0;
  position: absolute;  
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  
  ${StyledCallout} {
    text-align: left;
    padding: 12px 8px;
  }
`

export const StyledDayItemWrapper = styled.div`
  position: absolute;
  z-index: 10;
  overflow: hidden;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 8px;
  text-align: left;
  height: 32px;

  ${({ mainType }) => SHIFT_MAIN_TYPES.WORK === mainType && `
    background: ${DAY_RECORD_COLOURS[mainType].background};
    border-left: 2px solid ${DAY_RECORD_COLOURS[mainType].border};
  `}
  
  ${({ type }) => SHIFT_MAIN_TYPES.LEAVE === type && `
    background: ${DAY_RECORD_COLOURS[type].background};
    border-left: 2px solid ${DAY_RECORD_COLOURS[type].border};
  `}

  ${({ type }) => WORK_SHIFT_TYPES.OVERTIME === type && `
    border-left-color: ${FLAG_COLOURS.ERROR};
    color: ${FLAG_COLOURS.ERROR};
  `}
    
  ${({ type }) => WORK_SHIFT_TYPES.BREAK === type && `
    border-left: none;
    border-radius: 0;
    background: ${DAY_RECORD_COLOURS.BREAK};
    background: repeating-linear-gradient(
      -45deg, 
      transparent, 
      transparent 4px, 
      ${DAY_RECORD_COLOURS.BREAK} 4px, 
      ${DAY_RECORD_COLOURS.BREAK} 8px
    );
  `}

  ${({ hasContent }) => hasContent && `
    background: transparent;
    border-left: none;
    padding-left: 9px;
    z-index: 21;
  `}

  ${({ hasBreak }) => hasBreak && `
    margin-top: -20px;
  `}
    
  ${({ endTime, hourWidth, maxValue, minValue, minuteWidth, startTime }) => {
    const finalHourWidth = hourWidth
    const finalMinuteWidth = minuteWidth

    let left = (+moment(startTime).format('H') - minValue) * finalHourWidth
    let right = (+moment(endTime).format('H') - minValue) * finalHourWidth

    if (moment(startTime).valueOf() >= hour24InMilliseconds) {
      left = (maxValue - 1 - minValue) * finalHourWidth
    }

    if (moment(endTime).valueOf() >= hour24InMilliseconds) {
      right = (maxValue - 1 - minValue) * finalHourWidth
    }

    left += +moment(startTime).format('m') * finalMinuteWidth
    right += +moment(endTime).format('m') * finalMinuteWidth

    return `
      left: ${left}%;
      right: ${right}%;
      width: ${right - left}%;
    `
  }}
  
  ${({ type }) => WORK_SHIFT_TYPES.BREAK === type && `
    z-index: 20;
  `}
`

export const StyledTotalBreakWrapper = styled.div`
  position: absolute;
  z-index: 10;
  overflow: hidden;
  padding-left: 9px;
  text-align: left;
  margin-top: 40px;
  
  ${({ hourWidth, maxValue, minValue, minuteWidth, startTime }) => {
    const finalHourWidth = hourWidth
    const finalMinuteWidth = minuteWidth

    let left = (+moment(startTime).format('H') - minValue) * finalHourWidth

    if (moment(startTime).valueOf() >= hour24InMilliseconds) {
      left = (maxValue - 1 - minValue) * finalHourWidth
    }

    left += +moment(startTime).format('m') * finalMinuteWidth

    return `
      left: ${left}%;
    `
  }}
`

export const StyledHourLine = styled.div`
  position: absolute;
  z-index: 5;
  background: ${color(NEUTRAL_COLOURS.GRAY_TERTIARY).alpha(0.8).rgb().string()};
  top: 0;
  bottom: 0;
  width: 1px;
  
  ${({ hourWidth, index }) => `
    left: ${hourWidth * index}%;
  `}
  
  ${({ index }) => !index && `
    opacity: 0;
  `}
  
  ${({ time }) => time && `
    display: flex;
    align-items: center;
    text-indent: -15px;
  `}
`

export const StyledShiftsWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 10px;
  align-items: center;
  
  ${({ first, hasLeaves, hasShifts, hasTotalBreak, second }) => {
    if (first) {
      if (hasTotalBreak && hasLeaves) {
        return `
          top: 0;
          bottom: 40%;
        `
      }

      if (!hasTotalBreak && hasLeaves) {
        return `
          top: 0%;
          bottom: 50%;
        `
      }

      return `
        top: 0;
        bottom: 0;
      `
    }

    if (second) {
      if (hasShifts && hasTotalBreak) {
        return `
          top: 60%;
          bottom: 0;
        `
      }

      if (!hasTotalBreak) {
        return `
          top: 50%;
          bottom: 0;
        `
      }

      return `
        top: 0;
        bottom: 0;
      `
    }

    return ''
  }}
`
