export const GET_TRANSACTIONS_LIST = 'GET_TRANSACTIONS_LIST'
export const GET_TRANSACTIONS_LIST_SUCCESS = 'GET_TRANSACTIONS_LIST_SUCCESS'
export const GET_TRANSACTIONS_LIST_FAILED = 'GET_TRANSACTIONS_LIST_FAILED'
export const SEND_TRANSACTIONS_LIST = 'SEND_TRANSACTIONS_LIST'
export const SEND_TRANSACTIONS_LIST_SUCCESS = 'SEND_TRANSACTIONS_LIST_SUCCESS'
export const SEND_TRANSACTIONS_LIST_FAILED = 'SEND_TRANSACTIONS_LIST_FAILED'
export const DOWNLOAD_TRANSACTIONS_LIST = 'DOWNLOAD_TRANSACTIONS_LIST'
export const DOWNLOAD_TRANSACTIONS_LIST_SUCCESS = 'DOWNLOAD_TRANSACTIONS_LIST_SUCCESS'
export const DOWNLOAD_TRANSACTIONS_LIST_FAILED = 'DOWNLOAD_TRANSACTIONS_LIST_FAILED'

export const CLEAR_TRANSACTIONS_LIST = 'CLEAR_TRANSACTIONS_LIST'
