import React from 'react'
import { Field } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { Form } from 'components'

import i18n from 'translations'

interface ItemFormFieldsProps {
  isInvoiced: boolean
}

const ItemFormFields = ({ isInvoiced }: ItemFormFieldsProps) => (
  <React.Fragment>
    <Form.Row label={i18n.t('global:Item')} required verticalLabel>
      <Form.Row.FlexItem flex="0 0 300px" mobileFull>
        <Field
          component={Form.InfiniteDropdowns.NurseryItemsV3}
          disabled={isInvoiced}
          name="item"
          validate={isRequired}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.Row label={i18n.t('global:Quantity')} required verticalLabel>
      <Form.Row.FlexItem flex="0 0 300px" mobileFull>
        <Field
          component={Form.TextField}
          disabled={isInvoiced}
          name="quantity"
          type="number"
          validate={isRequired}
          withoutDecimal
        />
      </Form.Row.FlexItem>
    </Form.Row>
  </React.Fragment>
)

export default ItemFormFields
