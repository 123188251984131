import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

import { Callout, Form } from 'components'

import i18n from 'translations'

const AddExtraItemsForm = ({ error, feeCalculationOptions, handleSubmit, isLoading, nurseryOptions, onSubmit }) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <SubdomainCurrencyProvider>
      {({ currencySymbol }) => (
        <React.Fragment>
          <Callout content={error} error />
          <Form.Row label="Extra item">
            <Form.Row.FlexItem flex="0 0 200px">
              <Field
                component={Form.TextField}
                name="name"
                placeholder="Enter name of extra item"
                validate={isRequired}
              />
            </Form.Row.FlexItem>
          </Form.Row>
          <Form.Row label="Cost">
            <Form.Row.FlexItem flex="0 0 120px">
              <Field
                component={Form.TextField}
                name="unitPrice"
                placeholder={currencySymbol}
                step="any"
                type="number"
                validate={isRequired}
              />
            </Form.Row.FlexItem>
          </Form.Row>
          <Form.Row label="Default fee calculations for this extra item for invoices.">
            <Form.Row.FlexItem>
              <Field
                component={Form.RadioGroup}
                name="feeCalculations"
                options={feeCalculationOptions}
                validate={isRequired}
              />
            </Form.Row.FlexItem>
          </Form.Row>
          <Form.FooterActions
            cancelLink={`${nurseryOptions.prefix}/finance-settings/items`}
            isSubmitting={isLoading}
            submitLabel={i18n.t('global:Save')}
          />
        </React.Fragment>
      )}
    </SubdomainCurrencyProvider>
  </Form>
)

export default reduxForm({ form: 'AddExtraItemsForm' })(AddExtraItemsForm)
