export const GET_LEAVE_TYPE = 'GET_LEAVE_TYPE'
export const GET_LEAVE_TYPE_SUCCESS = 'GET_LEAVE_TYPE_SUCCESS'
export const GET_LEAVE_TYPE_FAILED = 'GET_LEAVE_TYPE_FAILED'

export const CREATE_LEAVE_TYPE = 'CREATE_LEAVE_TYPE'
export const CREATE_LEAVE_TYPE_SUCCESS = 'CREATE_LEAVE_TYPE_SUCCESS'
export const CREATE_LEAVE_TYPE_FAILED = 'CREATE_LEAVE_TYPE_FAILED'

export const UPDATE_LEAVE_TYPE = 'UPDATE_LEAVE_TYPE'
export const UPDATE_LEAVE_TYPE_SUCCESS = 'UPDATE_LEAVE_TYPE_SUCCESS'
export const UPDATE_LEAVE_TYPE_FAILED = 'UPDATE_LEAVE_TYPE_FAILED'

export const CLEAR_LEAVE_TYPE = 'CLEAR_LEAVE_TYPE'
