import { createSelector } from 'reselect'

import * as constants from '../constants'

const getRooms = (state) => state.organizationSessions

export const getSessionListSelector = createSelector(
  [getRooms],
  (state) => {
    if (!state) {
      return null
    }

    return state.list
  },
)

export const getSessionListDataSelector = createSelector(
  [getSessionListSelector],
  (state) => {
    if (!state) {
      return null
    }

    return state.data
  },
)

export const getCriteriaSelector = createSelector(
  [(filters) => filters],
  (filters) => {
    const { statusFilter } = filters || {}

    const criteria = []

    if (statusFilter && statusFilter !== constants.ORG_SESSIONS_FILTER.ALL) {
      criteria.push({
        field: 'archived',
        value: statusFilter === constants.ORG_SESSIONS_FILTER.ARCHIVED ? 1 : 0,
      })
    }

    return criteria
  },
)
