import {
  ADD_INVOICE_ITEM,
  CLEAR_INVOICE_PREVIEW,
  GET_INVOICE_PREVIEW,
  GET_INVOICE_PREVIEW_FAILED,
  GET_INVOICE_PREVIEW_SUCCESS,
  REMOVE_INVOICE_ITEM,
  REPEAT_INVOICE_PREVIEW,
  REPEAT_INVOICE_PREVIEW_FAILED,
  REPEAT_INVOICE_PREVIEW_SUCCESS,
  SET_INITIAL_VALUES,
} from './constants'

export const getPreview = (id, params, onSuccess) => ({ invoicesApiClient }) => (dispatch) => {
  dispatch({
    id,
    type: GET_INVOICE_PREVIEW,
  })

  invoicesApiClient.getPreview(id, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: GET_INVOICE_PREVIEW_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: GET_INVOICE_PREVIEW_FAILED,
      })
    },
  )
}

export const repeatPreview = (id, params, defaultValues, onSuccess) => ({ invoicesApiClient }) => (dispatch) => {
  dispatch({
    id,
    type: REPEAT_INVOICE_PREVIEW,
  })

  invoicesApiClient.getPreview(id, params).then(
    (data) => {
      dispatch({
        payload: {
          ...data,
          defaultValues,
        },
        type: REPEAT_INVOICE_PREVIEW_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: REPEAT_INVOICE_PREVIEW_FAILED,
      })
    },
  )
}

export const addItem = (item, totalDifference, oldTotal, isNotOriginalType, isFinanceV3Enabled) => ({
  isFinanceV3Enabled,
  isNotOriginalType,
  item,
  oldTotal,
  totalDifference,
  type: ADD_INVOICE_ITEM,
})

export const removeItem = (key, totalDifference) => ({
  key,
  totalDifference,
  type: REMOVE_INVOICE_ITEM,
})

export const setInitialValues = (invoiceSettings) => ({
  invoiceSettings,
  type: SET_INITIAL_VALUES,
})

export const clearPreview = () => ({
  type: CLEAR_INVOICE_PREVIEW,
})
