import moment from 'moment'

import React from 'react'
import { Link } from 'react-router'

import { generateRoute } from 'utils/routing'

import { constants as invoicesConstants } from 'services/legacy/invoices'

import { Currency, DateString, Icon, LoadingDots, Spinner, Table, Typography } from 'components'

import ErrorInfo from '../../ErrorInfo'
import {
  StyledAmountContainer,
  StyledDateRangeContainer,
  StyledInvoiceSendingSubTextContainer,
  StyledInvoiceSentContainer,
  StyledInvoiceSentSubTextContainer,
  StyledInvoiceTypeContainer,
  StyledStatus,
  StyledTotalContainer,
} from './EstimatedInvoiceTotalCellStyled'

const EstimatedInvoiceTotalCell = ({
  child,
  generatedInvoiceType,
  hasError,
  hasItems,
  invoiceId,
  onAmountClick,
  period,
  sentAt,
  value,
}) => {
  const renderContent = () => {
    if (!hasError && (null === value || value === undefined)) {
      return <Spinner />
    }

    const { STATUS_TYPES } = invoicesConstants

    if (!hasError && generatedInvoiceType === STATUS_TYPES.SENDING) {
      return (
        <StyledStatus fontSize={16} marginTop={0}>
          Sending
          <LoadingDots />
          <StyledInvoiceSendingSubTextContainer>
            {'Check '}
            <Link to={generateRoute('FINANCE.INVOICES')}>
              <Typography bold primary>here</Typography>
            </Link>
            {' for a status update'}
          </StyledInvoiceSendingSubTextContainer>
        </StyledStatus>
      )
    }

    if (!hasError && generatedInvoiceType === STATUS_TYPES.DRAFT) {
      const { after, before } = period

      return (
        <div>
          <StyledTotalContainer hasError={hasError}>
            <StyledAmountContainer onClick={onAmountClick(child, invoiceId)}>
              <Currency value={value} />
              <StyledInvoiceTypeContainer>
                  &nbsp;(Saved as draft)
              </StyledInvoiceTypeContainer>
            </StyledAmountContainer>
          </StyledTotalContainer>
          <StyledDateRangeContainer>
            <DateString date={after} />
            &nbsp;-&nbsp;
            <DateString date={before} />
          </StyledDateRangeContainer>
        </div>
      )
    }

    if (!hasError && generatedInvoiceType) {
      return (
        <StyledInvoiceSentContainer>
          Invoice Sent
          <StyledInvoiceSentSubTextContainer>
            <Icon height={15} icon="check" />
            {`Sent at ${moment(sentAt).format('HH:mm')}`}
          </StyledInvoiceSentSubTextContainer>
        </StyledInvoiceSentContainer>
      )
    }

    const { after, before } = period

    const renderTotal = () => {
      if (!hasItems) {
        return 'No items to invoice'
      }

      return (
        <StyledAmountContainer onClick={onAmountClick(child.id)}>
          <Currency value={value} />
        </StyledAmountContainer>
      )
    }

    return (
      <div>
        <StyledTotalContainer hasError={hasError}>
          {hasError && <ErrorInfo />}
          {renderTotal()}
        </StyledTotalContainer>
        <StyledDateRangeContainer>
          <DateString date={after} />
            &nbsp;-&nbsp;
          <DateString date={before} />
        </StyledDateRangeContainer>
      </div>
    )
  }

  return (
    <Table.Td align="left">
      {renderContent()}
    </Table.Td>
  )
}

export default EstimatedInvoiceTotalCell
