import styled from 'styled-components'
import color from 'color'

import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'
import { LEAVE_SHIFT_TYPES, LEAVE_TYPES_COLOURS } from 'services/legacy/membershipsLeaves/constants'

import { getBrandingColor } from 'utils/branding'

import { StyledButtonWrapper } from 'components/DropdownMenu/DropdownMenuStyled'

import { StyledScrollbar } from 'styled/abstract'

export const StyledLeaveBoxWrapper = styled.div`
  display: grid;
  width: 48px;
  height: 100%;

  & > div {
    height: 100%;
    
    & > ${StyledButtonWrapper} {
      height: 100%;
    }
  }
`

export const StyledLeaveBox = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;

  &:hover {
    opacity: .8;
  }

  ${({ code }) => code && `
    background: ${LEAVE_TYPES_COLOURS[code]?.basic || LEAVE_TYPES_COLOURS[LEAVE_SHIFT_TYPES.OTHER].basic};
  `}
  
  ${({ smallerLetterSpacing }) => smallerLetterSpacing && `
    letter-spacing: -1.5px;
  `}

  ${({ borderTop }) => borderTop && `
    border-top: ${borderTop};
  `}
`

export const StyledAddLog = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${color(getBrandingColor('primary-color')).alpha(0.1).rgb().string()};
  transition: .1s;
  opacity: 0;
  cursor: pointer;
  
  &:hover {
    opacity: 1;
  }
`

export const StyledCircle = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  
  ${({ isToday }) => isToday && `
    background: ${FLAG_COLOURS.INFO};
    position: relative;
    
    &:before {
      position: absolute;
      content: '';
      left: -1px;
      right: -1px;
      bottom: -7px;
      height: 33px;
      background: ${color(FLAG_COLOURS.INFO).mix(color(NEUTRAL_COLOURS.WHITE), 0.8).rgb().string()};
      z-index: -1;
    }
        
    &:after {
      position: absolute;
      content: '';
      left: -1px;
      right: -1px;
      bottom: -8px;
      height: 2px;
      background: ${color(FLAG_COLOURS.INFO).mix(color(NEUTRAL_COLOURS.WHITE), 0.8).rgb().string()};
      z-index: 1;
    }
    
    p {
      color: ${NEUTRAL_COLOURS.WHITE} !important;
    }
  `}
`

export const StyledBorderWrapper = styled.div`
  ${({ leftBorder }) => leftBorder && `
    border-left: 2px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};  
  `}
  
  ${({ topBorder }) => topBorder && `
    border-top: 2px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};  
  `}  

  ${({ bottomBorder }) => bottomBorder && `
    border-bottom: 2px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};  
  `}
  
  ${({ rightBorder }) => rightBorder && `
    border-right: 2px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};  
  `}
`

export const StyledLeaveDetails = styled.div`
  padding: 8px 22px;
  grid-column-gap: 12px;
`

export const StyledShiftTimesWrapper = styled(StyledScrollbar)`
  max-height: 200px;
  overflow-y: auto;
`

export const StyledTitle = styled.p`
  /* stylelint-disable */
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 16px !important;
  margin: 0;
  width: 110px;
  /* stylelint-enable */
`

export const StyledStickyHeaderWrapper = styled.div`
  background: #FFF;
  display: flex;
  position: relative;
  z-index: -1;
`
