import _ from 'lodash'
import color from 'color'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'
import { nFormatter } from 'utils/data'

export const getColumnChartConfig = (
  categories,
  config,
  data,
  enableLegends,
  isCurrencyValue,
  onChartPointClick,
  currencyNurserySymbol,
  getFormattedCurrencyValue,
) => _.merge({
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'column',
  },
  credits: {
    enabled: false,
  },
  legend: {
    backgroundColor: '#F5F5F5',
    borderRadius: 10,
    enabled: enableLegends,
    itemStyle: {
      fontSize: '13px',
      fontWeight: 600,
    },
    margin: 30,
    padding: 15,
    verticalAlign: 'top',
  },
  plotOptions: {
    column: {
      color: color(getBrandingColor('primary-color')).alpha(0.8).rgb().string(),
      dataLabels: {
        style: {
          color: getBrandingColor('secondary-color'),
          fontWeight: 600,
        },
      },
      point: {
        events: {
          click() {
            if (onChartPointClick) {
              onChartPointClick(this)
            }
          },
        },
      },
      stacking: 'normal',
    },
    series: {
      animation: false,
      cursor: 'pointer',
      states: {
        select: {
          borderColor: 'transparent',
          color: getBrandingColor('primary-color'),
        },
      },
    },
  },
  series: data,
  subtitle: {
    text: null,
  },
  title: {
    text: null,
  },
  tooltip: {
    backgroundColor: NEUTRAL_COLOURS.BASIC,
    borderRadius: 14,
    borderWidth: 0,
    color: NEUTRAL_COLOURS.WHITE,
    headerFormat: `
      <p style="font-size:16px;font-weight:600;margin-bottom:10px;">
        {point.key}
      </p>
    `,
    padding: 10,
    pointFormatter: function () { // eslint-disable-line
      let finalValue = this.y

      if (isCurrencyValue) {
        finalValue = getFormattedCurrencyValue(this.y)
      }

      const legendColorSpan = `<span style="color:${this.color};">\u25CF</span>`

      return `
        <p style="color:transparent;font-size:2px">.</p>
        <br />
        ${enableLegends ? legendColorSpan : ''}
        ${this.series.name}: <span style="font-weight:600">${finalValue}</span>
      `
    },
    shadow: false,
    shared: true,
    style: {
      color: NEUTRAL_COLOURS.WHITE,
      fontFamily: 'Gill Sans',
      fontSize: 16,
    },
    valueDecimals: 2,
    valuePrefix: currencyNurserySymbol,
  },
  xAxis: {
    categories,
    labels: {
      style: {
        color: '#666666',
        font: '14px Gill Sans, sans-serif',
      },
    },
    lineColor: '#CCD6EB',
    tickColor: '#CCD6EB',
    tickWidth: 1,
    tickmarkPlacement: 'between',
  },
  yAxis: {
    gridLineColor: '#E9E9E9',
    gridLineWidth: 1,
    labels: {
      formatter: function() { // eslint-disable-line
        return `${0 > this.value ? '-' : ''}${currencyNurserySymbol}${nFormatter(Math.abs(this.value), 2)}`
      },
      style: {
        font: '13px Gill Sans, sans-serif',
      },
    },
    stackLabels: {
      enabled: true,
      formatter() {
        if (!isCurrencyValue) {
          return this.total
        }

        return getFormattedCurrencyValue(this.total)
      },
    },
    title: {
      text: null,
    },
  },
}, config)
