import { NEUTRAL_COLOURS } from 'constants/colors'

import i18n from 'translations'

export const OBSERVATION_FEEDBACK_STATUSES = {
  CHANGES_MADE: {
    color: '#008998',
    label: i18n.t('module:Learning:Observations:ObservationsFeedback:statusChangesMade'),
  },
  CHANGES_REQUESTED: {
    color: NEUTRAL_COLOURS.SALMON,
    label: i18n.t('module:Learning:Observations:ObservationsFeedback:statusChangesRequested'),
  },
}
