import React from 'react'

import { ModalBox } from 'components'

import i18n from 'translations'

import RequestedExtraSessionModalForm from './components/RequestedExtraSessionModalForm'

const RequestedExtraSessionModalView = ({
  isApproveAction,
  isSubmitting,
  onCloseClick,
  onSubmit,
}) => (
  <ModalBox
    maxWidth={600}
    title={isApproveAction
      ? i18n.t('module:Modals:RequestedExtraSession:Title:approve')
      : i18n.t('module:Modals:RequestedExtraSession:Title:decline')}
    autoHeight
    onCloseClick={onCloseClick}
  >
    <RequestedExtraSessionModalForm
      isApproveAction={isApproveAction}
      isSubmitting={isSubmitting}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    />
  </ModalBox>
)

export default RequestedExtraSessionModalView
