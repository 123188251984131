import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { FEATURE_FLAGS } from 'constants/security'

import auth from 'utils/auth'

import { EVENTS, logEvent } from 'analytics'

import { withAppService } from 'services/app'
import { withShellService } from 'services/shell'
import { withMembershipsService } from 'services/legacy/memberships'
import { withSecurityService } from 'services/security'

import withStaffHoc from 'module/Staff/withStaffHoc'

import StaffDetailsView from './StaffDetailsView'

const GROUPS_WITH_NURSERIES = {
  read: [
    'membership.details',
    'membership.nurseries',
    'nursery',
    'membership.primaryManager',
    'membership.profile',
    'membership.profile.dbsCheck',
  ],
}

const GROUPS = {
  read: [
    'membership.details',
    'membership.primaryManager',
    'membership.profile',
    'membership.profile.dbsCheck',
  ],
}

class StaffDetailsContainer extends Component {
  componentDidMount() {
    const {
      isAreaManager,
      isMyDetailsContext,
      isOrganizationContext,
      membershipsActions,
      params: { userId },
    } = this.props

    logEvent(EVENTS.STAFF_PREVIEW_PAGE_VIEWED)

    const groups = isOrganizationContext && isAreaManager
      ? GROUPS_WITH_NURSERIES
      : GROUPS

    if (userId) {
      return membershipsActions.get(userId, {
        params: { groups },
      })
    }

    if (isMyDetailsContext) {
      return membershipsActions.getMe({
        params: { groups },
      })
    }

    return null
  }

  render() {
    const {
      authAccessMap: { section: { StaffingEnabled } },
      isAreaManager,
      isFetching,
      isMyDetailsContext,
      membership,
    } = this.props

    return (
      <StaffDetailsView
        isAreaManager={isAreaManager}
        isFetching={isFetching}
        isMyDetailsContext={isMyDetailsContext}
        isStaffingEnabled={StaffingEnabled}
        membership={membership}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  membershipsSelectors,
  membershipsSingleState,
  securitySelectors,
}) => ({
  authAccessMap: {
    section: {
      StaffingEnabled: auth.SELECTORS.getIsAuthorised(state, {
        flags: [FEATURE_FLAGS.STAFF_REGISTER],
      }),
    },
  },
  isAreaManager: membershipsSelectors.getIsAreaManager(state),
  isFetching: appSelectors.getIsFetching(membershipsSingleState),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
  membership: membershipsSelectors.getMembershipDataSelector(state),
})

const enhance = compose(
  withAppService,
  withMembershipsService,
  withShellService,
  withSecurityService,
  withStaffHoc,
  connect(mapState),
)

export default enhance(StaffDetailsContainer)
