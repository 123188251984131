/* eslint-disable max-len */
/* eslint-disable import-newlines/enforce */

import i18n from 'translations'

import withFinanceGateway from 'module/Finance/withFinanceGateway'

import SettingsWrapper from 'module/Settings/index'
import SettingsNursery from 'module/Settings/SettingsNursery'
import NewFinance from 'module/Settings/NewFinance'
import SettingsNurseryItem from 'module/Settings/SettingsNursery/SettingsNurseryItem'
import SettingsNurseryItemEdit from 'module/Settings/SettingsNursery/SettingsNurseryItemEdit'
import SettingsSessionsWrapper from 'module/Settings/SettingsSessions/SettingsSessionsWrapper'
import SettingsSessions from 'module/Settings/SettingsSessions/v2'
import SessionsListV3 from 'module/Settings/SettingsSessions/v3/SessionsList'
import SettingsSessionsAdd from 'module/Settings/SettingsSessions/v2/SettingsSessionsAdd'
import SettingsSessionsItem from 'module/Settings/SettingsSessions/v2/SettingsSessionsItem'
import SettingsInvoicesWrapper from 'module/Settings/SettingsInvoices/SettingsInvoicesWrapper'
import SettingsInvoices from 'module/Settings/SettingsInvoices'
import SettingsInvoicesGlobalEdit from 'module/Settings/SettingsInvoices/SettingsInvoicesGlobalEdit'
import SettingsInvoicesDetailsV2 from 'module/Settings/SettingsInvoices/SettingsInvoicesDetails/V2'
import SettingsInvoicesDetailsV3 from 'module/Settings/SettingsInvoices/SettingsInvoicesDetails/V3'
import SettingsInvoicesItemEditBank from 'module/Settings/SettingsInvoices/SettingsInvoicesItemEditBank'
import SettingsInvoicesItemDetailsV2 from 'module/Settings/SettingsInvoices/SettingsInvoicesItemDetails/V2'
import SettingsInvoicesItemDetailsV3 from 'module/Settings/SettingsInvoices/SettingsInvoicesItemDetails/V3'
import SettingsInvoiceNumbersWrapper from 'module/Settings/SettingsInvoiceNumbers/SettingsInvoiceNumbersWrapper'
import SettingsInvoiceNumbers from 'module/Settings/SettingsInvoiceNumbers'
import SettingsInvoiceNumbersEdit from 'module/Settings/SettingsInvoiceNumbers/SettingsInvoiceNumbersEdit'
import SettingsInvoiceNumbersGlobalEdit from 'module/Settings/SettingsInvoiceNumbers/SettingsInvoiceNumbersGlobalEdit'
import SettingsInvoiceNumbersItem from 'module/Settings/SettingsInvoiceNumbers/SettingsInvoiceNumbersItem'
import SettingsInvoiceNumbersItemEdit
  from 'module/Settings/SettingsInvoiceNumbers/SettingsInvoiceNumbersItem/SettingsInvoiceNumbersItemEdit' // eslint-disable-line
import SecurityWrapper from 'module/Settings/Security/SecurityWrapper'
import SecurityContainer from 'module/Settings/Security'
import SecurityGlobalEdit from 'module/Settings/Security/GlobalEdit'
import GeneralSecurityItem from 'module/Management/Security/GeneralSecuritySettings'
import SessionsV3 from 'module/Management/ManagementFinanceSettings/Sessions/V3'
import ManagementSessionsV3Add from 'module/Management/ManagementFinanceSettings/Sessions/V3/Add/AddContainer'
import SettingsInvoicesV3EditAnnualised from './SettingsInvoices/SettingsInvoicesV3EditAnnualised'
import SettingsInvoicesV3EditItemAnnualised from './SettingsInvoices/SettingsInvoicesV3EditItemAnnualised'

export const ROUTE_NAMES = {
  'SETTINGS.INDEX': 'SETTINGS.INDEX',
  'SETTINGS.INVOICES': 'SETTINGS.INVOICES',
  'SETTINGS.INVOICES.ANNUALISED_EDIT': 'SETTINGS.INVOICES.ANNUALISED_EDIT',
  'SETTINGS.INVOICES.ANNUALISED_ITEM_EDIT': 'SETTINGS.INVOICES.ANNUALISED_ITEM_EDIT',
  'SETTINGS.INVOICES.DETAILS': 'SETTINGS.INVOICES.DETAILS',
  'SETTINGS.INVOICES.EDIT_BANK': 'SETTINGS.INVOICES.EDIT_BANK',
  'SETTINGS.INVOICES.GLOBAL_EDIT': 'SETTINGS.INVOICES.GLOBAL_EDIT',
  'SETTINGS.INVOICES.VIEW': 'SETTINGS.INVOICES.VIEW',
  'SETTINGS.INVOICE_NUMBERS': 'SETTINGS.INVOICE_NUMBERS',
  'SETTINGS.INVOICE_NUMBERS.EDIT': 'SETTINGS.INVOICE_NUMBERS.EDIT',
  'SETTINGS.INVOICE_NUMBERS.GLOBAL_EDIT': 'SETTINGS.INVOICE_NUMBERS.GLOBAL_EDIT',
  'SETTINGS.INVOICE_NUMBERS.ITEM': 'SETTINGS.INVOICE_NUMBERS.ITEM',
  'SETTINGS.INVOICE_NUMBERS.ITEM_EDIT': 'SETTINGS.INVOICE_NUMBERS.ITEM_EDIT',
  'SETTINGS.NEW_FINANCE': 'SETTINGS.NEW_FINANCE',
  'SETTINGS.NURSERY': 'SETTINGS.NURSERY',
  'SETTINGS.NURSERY.ITEM': 'SETTINGS.NURSERY.ITEM',
  'SETTINGS.NURSERY.ITEM.EDIT': 'SETTINGS.NURSERY.ITEM.EDIT',
  'SETTINGS.SECURITY.GLOBAL_EDIT': 'SETTINGS.SECURITY.GLOBAL_EDIT',
  'SETTINGS.SECURITY.INDEX': 'SETTINGS.SECURITY.INDEX',
  'SETTINGS.SECURITY.ITEM': 'SETTINGS.SECURITY.ITEM',
  'SETTINGS.SESSIONS': 'SETTINGS.SESSIONS',
  'SETTINGS.SESSIONS.ADD': 'SETTINGS.SESSIONS.ADD',
  'SETTINGS.SESSIONS.ITEM': 'SETTINGS.SESSIONS.ITEM',
  'SETTINGS.SESSIONS.ITEM.EDIT': 'SETTINGS.SESSIONS.ITEM.EDIT',
  'SETTINGS.SESSIONS.NURSERY': 'SETTINGS.SESSIONS.NURSERY',
  'SETTINGS.SESSIONS.NURSERY.SESSIONS.ADD': 'SETTINGS.SESSIONS.NURSERY.SESSIONS.ADD',
  'SETTINGS.SESSIONS.NURSERY.SESSIONS.EDIT': 'SETTINGS.SESSIONS.NURSERY.SESSIONS.EDIT',
  'SETTINGS.TERMS': 'SETTINGS.TERMS',
  'SETTINGS.TERMS.SINGLE': 'SETTINGS.TERMS.SINGLE',
  'SETTINGS.TERMS.SINGLE.ADD': 'SETTINGS.TERMS.SINGLE.ADD',
  'SETTINGS.TERMS.SINGLE.EDIT_ITEM': 'SETTINGS.TERMS.SINGLE.EDIT_ITEM',
  'SETTINGS.TERMS.SINGLE.OPEN_PERIOD': 'SETTINGS.TERMS.SINGLE.OPEN_PERIOD',
}

const SettingsRouting = {
  childRoutes: [
    {
      childRoutes: [
        {
          childRoutes: [
            {
              component: SettingsNurseryItemEdit,
              name: ROUTE_NAMES['SETTINGS.NURSERY.ITEM.EDIT'],
              path: 'edit',
              title: 'Edit',
            },
          ],
          indexRoute: {
            component: SettingsNurseryItem,
          },
          name: ROUTE_NAMES['SETTINGS.NURSERY.ITEM'],
          path: ':nurseryId',
          title: 'Nursery preview',
        },
      ],
      indexRoute: {
        component: SettingsNursery,
      },
      name: ROUTE_NAMES['SETTINGS.NURSERY'],
      path: 'nursery',
      title: 'Nursery information',
    },
    {
      indexRoute: {
        component: NewFinance,
      },
      name: ROUTE_NAMES['SETTINGS.NEW_FINANCE'],
      path: 'new-finance',
      title: i18n.t('module:Management:Finance:NewFinance:title'),
    },
    {
      childRoutes: [
        {
          childRoutes: [
            {
              component: ManagementSessionsV3Add,
              name: ROUTE_NAMES['SETTINGS.SESSIONS.NURSERY.SESSIONS.ADD'],
              path: 'session/add',
              title: i18n.t('module:Management:Finance:Sessions:Add:title'),
            },
            {
              component: ManagementSessionsV3Add,
              name: ROUTE_NAMES['SETTINGS.SESSIONS.NURSERY.SESSIONS.EDIT'],
              path: 'session/:sessionId/edit',
              title: i18n.t('module:Management:Finance:Sessions:Edit:title'),
            },
          ],
          indexRoute: {
            component: SessionsV3,
          },
          name: ROUTE_NAMES['SETTINGS.SESSIONS.NURSERY'],
          path: 'nursery/:nurseryId',
        },
        {
          component: SettingsSessionsAdd,
          name: ROUTE_NAMES['SETTINGS.SESSIONS.ADD'],
          path: 'add',
          title: 'Add a session',
        },
        {
          childRoutes: [
            {
              component: SettingsSessionsAdd,
              name: ROUTE_NAMES['SETTINGS.SESSIONS.ITEM.EDIT'],
              path: 'edit',
              title: 'Edit a session',
            },
          ],
          indexRoute: {
            component: SettingsSessionsItem,
          },
          name: ROUTE_NAMES['SETTINGS.SESSIONS.ITEM'],
          path: ':sessionId',
          title: 'Session details',
        },
      ],
      component: SettingsSessionsWrapper,
      indexRoute: {
        component: withFinanceGateway({ V2: SettingsSessions, V3: SessionsListV3 }),
      },
      name: ROUTE_NAMES['SETTINGS.SESSIONS'],
      path: 'sessions',
      title: 'Sessions',
    },
    {
      childRoutes: [
        {
          component: withFinanceGateway({ V2: SettingsInvoicesDetailsV2, V3: SettingsInvoicesDetailsV3 }),
          name: ROUTE_NAMES['SETTINGS.INVOICES.VIEW'],
          path: 'view',
          title: 'Your default invoice settings',
        },
        {
          component: SettingsInvoicesGlobalEdit,
          name: ROUTE_NAMES['SETTINGS.INVOICES.GLOBAL_EDIT'],
          path: 'global/edit',
          title: 'Use global settings',
        },
        {
          component: withFinanceGateway({ V2: SettingsInvoicesItemDetailsV2, V3: SettingsInvoicesItemDetailsV3 }),
          name: ROUTE_NAMES['SETTINGS.INVOICES.DETAILS'],
          path: ':nurseryId/view',
          title: 'Invoices details',
        },
        {
          component: SettingsInvoicesItemEditBank,
          name: ROUTE_NAMES['SETTINGS.INVOICES.EDIT_BANK'],
          path: ':nurseryId/bank/edit',
          title: 'Edit bank',
        },
        {
          component: SettingsInvoicesV3EditItemAnnualised,
          name: ROUTE_NAMES['SETTINGS.INVOICES.ANNUALISED_ITEM_EDIT'],
          path: ':nurseryId/annualised/edit',
          title: i18n.t('module:Management:Finance:Annualised:title'),
        },
        {
          component: SettingsInvoicesV3EditAnnualised,
          name: ROUTE_NAMES['SETTINGS.INVOICES.ANNUALISED_EDIT'],
          path: 'annualised/edit',
          title: i18n.t('module:Management:Finance:Annualised:title'),
        },
      ],
      component: SettingsInvoicesWrapper,
      indexRoute: {
        component: SettingsInvoices,
      },
      name: ROUTE_NAMES['SETTINGS.INVOICES'],
      path: 'invoices',
      title: 'Invoices',
    },
    {
      childRoutes: [
        {
          component: SettingsInvoiceNumbersEdit,
          name: ROUTE_NAMES['SETTINGS.INVOICE_NUMBERS.EDIT'],
          path: 'edit',
          title: 'Edit',
        },
        {
          component: SettingsInvoiceNumbersGlobalEdit,
          name: ROUTE_NAMES['SETTINGS.INVOICE_NUMBERS.GLOBAL_EDIT'],
          path: 'global/edit',
          title: 'Use global settings',
        },
        {
          component: SettingsInvoiceNumbersItem,
          name: ROUTE_NAMES['SETTINGS.INVOICE_NUMBERS.ITEM'],
          path: ':nurseryId/view',
          title: 'View',
        },
        {
          component: SettingsInvoiceNumbersItemEdit,
          name: ROUTE_NAMES['SETTINGS.INVOICE_NUMBERS.ITEM_EDIT'],
          path: ':nurseryId/edit',
          title: 'Edit',
        },
      ],
      component: SettingsInvoiceNumbersWrapper,
      indexRoute: {
        component: SettingsInvoiceNumbers,
      },
      name: ROUTE_NAMES['SETTINGS.INVOICE_NUMBERS'],
      path: 'invoice-numbers',
      title: 'Invoice numbers',
    },
    {
      childRoutes: [
        {
          component: SecurityGlobalEdit,
          name: ROUTE_NAMES['SETTINGS.SECURITY.GLOBAL_EDIT'],
          path: 'global/edit',
          title: i18n.t('module:Settings:Common:globalSettingsEditLabel'),
        },
        {
          component: GeneralSecurityItem,
          name: ROUTE_NAMES['SETTINGS.SECURITY.ITEM'],
          path: ':nurseryId',
          title: i18n.t('module:Settings:Security:Item:routeTitle'),
        },
      ],
      component: SecurityWrapper,
      indexRoute: {
        component: SecurityContainer,
      },
      name: ROUTE_NAMES['SETTINGS.SECURITY.INDEX'],
      path: 'security',
      title: i18n.t('module:Settings:Security:title'),
    },
  ],
  component: SettingsWrapper,
  name: ROUTE_NAMES['SETTINGS.INDEX'],
  path: 'settings',
  title: 'Settings',
}

SettingsRouting.ROUTE_NAMES = ROUTE_NAMES

export default SettingsRouting
