import _ from 'lodash'

import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'
import { IntegrationFeeModel, STRIPE_INTEGRATION_TYPE } from 'services/integrationFees/constants'

const getIntegrationFeesState = (state: RootState) => state.integrationFees

export const getDefaultIntegrationFees = createSelector([getIntegrationFeesState], (state) => (
  _.filter(state?.list?.data, (item: IntegrationFeeModel) => item.default)
))

export const getCustomIntegrationFees = createSelector([getIntegrationFeesState], (state) => (
  _.filter(state?.list?.data, (item: IntegrationFeeModel) => !item.default)
))

export const getIntegrationFeesListCriteria = (params) => {
  const { nursery } = params
  const criteria: any[] = [{
    field: 'type',
    value: STRIPE_INTEGRATION_TYPE,
  }]

  if (nursery) {
    criteria.push({
      field: 'nursery',
      value: +nursery,
    })
  }

  return criteria
}
