import {
  CREATE_FOOD_MENU,
  CREATE_FOOD_MENU_FAILED,
  CREATE_FOOD_MENU_SUCCESS,
  UPDATE_FOOD_MENU,
  UPDATE_FOOD_MENU_FAILED,
  UPDATE_FOOD_MENU_SUCCESS,
} from './constants'

export const create = (params, onSuccess, onFailed) => ({ foodMenuApiClient }) => (dispatch) => {
  dispatch({
    type: CREATE_FOOD_MENU,
  })

  foodMenuApiClient.create(params).then(
    (response) => {
      dispatch({
        payload: response,
        type: CREATE_FOOD_MENU_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: CREATE_FOOD_MENU_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const update = (id, params, onSuccess, onFailed) => ({ foodMenuApiClient }) => (dispatch) => {
  dispatch({
    type: UPDATE_FOOD_MENU,
  })

  foodMenuApiClient.update(id, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: UPDATE_FOOD_MENU_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: UPDATE_FOOD_MENU_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}
