import _ from 'lodash'

import React, { memo } from 'react'
import isEqual from 'react-fast-compare'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { CircleIcon, Tooltip, Typography } from 'components'

import i18n from 'translations'

import { getGroupedTimes } from 'module/RequestedExtraSessions/RequestedExtraSessionsHelpers'

import TooltipStatistic from '../TooltipStatistic'

import { StyledTooltipContentGrid } from './StaffRequiredTooltipStyled'
import { StyledTooltipWrapper, StyledWrapper } from '../../RequestedExtraSessionsListStyled'

const StaffRequiredTooltip = ({ staffRequiredStatistics }) => {
  const { times } = staffRequiredStatistics || {}
  const groupedTimes = getGroupedTimes(times)
  const indexes = _.map(groupedTimes, (_value, index) => +index)

  const renderTitle = () => (
    <StyledTooltipWrapper>
      <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} margin="0 0 5px">
        {i18n.t('module:Parent:RequestedSessions:List:staffTooltip:additionalStaffRequired')}
      </Typography>
      {_.map(indexes, (number) => !_.isEmpty(groupedTimes[number]) && (
        <StyledTooltipContentGrid key={number}>
          <Typography fontSize={14} margin="0 0 5px" variant="span" bold>
            {i18n.t('module:Parent:RequestedSessions:List:staffTooltip:staffMembers', { number })}
          </Typography>
          <TooltipStatistic times={groupedTimes[number]} />
        </StyledTooltipContentGrid>
      ))}
    </StyledTooltipWrapper>
  )

  const renderStaffRequiredHighestNumber = () => {
    const max = _.max(indexes)

    return max ? `+${max}` : '0'
  }

  return (
    <StyledWrapper>
      <Typography margin="0 10px 0 0" variant="span">
        {renderStaffRequiredHighestNumber()}
      </Typography>
      {times?.length ? (
        <Tooltip
          placement="bottom-center"
          title={renderTitle()}
          type="white"
          inline
        >
          <CircleIcon
            cursor="pointer"
            icon="info"
            iconSize={12}
            size={24}
          />
        </Tooltip>
      ) : null}
    </StyledWrapper>
  )
}

export default memo(StaffRequiredTooltip, isEqual)
