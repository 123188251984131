import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'
import { AuthenticationCommonState } from 'services/authentication/common/reducer'

import { RootState } from 'core/reducers'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as authenticationSelectors from './selectors'

export interface withAuthenticationServiceProps {
  authenticationActions: typeof actions
  authenticationCommonState: AuthenticationCommonState
  authenticationSelectors: typeof authenticationSelectors
}

const withAuthenticationService = <P extends withAuthenticationServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ authentication }: RootState) => ({ authentication })

  const mapDispatch = injectActions('authenticationActions', actions)

  const Component = ({ authentication, authenticationActions, ...others }) => (
    <WrappedComponent
      authenticationActions={authenticationActions}
      authenticationCommonState={authentication.common}
      authenticationSelectors={authenticationSelectors}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withAuthenticationService
