import i18n from 'translations'

export const CHILD_EXTRA_ITEMS_FILTERS = {
  ACTIVE: 'ACTIVE',
  ALL: 'ALL',
  ARCHIVED: 'ARCHIVED',
}

export const CHILD_EXTRA_ITEMS_FILTER_OPTIONS = [
  { label: i18n.t('global:Active'), value: CHILD_EXTRA_ITEMS_FILTERS.ACTIVE },
  { label: i18n.t('global:All'), value: CHILD_EXTRA_ITEMS_FILTERS.ALL },
  { label: i18n.t('global:Archived'), value: CHILD_EXTRA_ITEMS_FILTERS.ARCHIVED },
]

export const CHILD_EXTRA_ITEMS_TYPES = {
  MONTHLY: 'monthly',
  ONE_OFF: 'oneOff',
  WEEKLY: 'weekly',
}

export const CHILD_EXTRA_ITEMS_TYPE_OPTIONS = [
  {
    label: 'One-off',
    value: CHILD_EXTRA_ITEMS_TYPES.ONE_OFF,
  },
  {
    label: 'Weekly',
    value: CHILD_EXTRA_ITEMS_TYPES.WEEKLY,
  },
  {
    label: 'Monthly',
    value: CHILD_EXTRA_ITEMS_TYPES.MONTHLY,
  },
]
