import { generateBasicActions } from 'utils/service'

import {
  CLEAR_BATCH_FOOD_TYPES,
  UPDATE_BATCH_FOOD_TYPES,
  UPDATE_BATCH_FOOD_TYPES_FAILED,
  UPDATE_BATCH_FOOD_TYPES_SUCCESS,
} from './constants'

export const updateBatch = (options = {}) => ({ foodTypesApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_BATCH_FOOD_TYPES_FAILED,
      init: UPDATE_BATCH_FOOD_TYPES,
      success: UPDATE_BATCH_FOOD_TYPES_SUCCESS,
    },
    options,
    service: foodTypesApiClient,
    serviceMethod: 'updateBatch',
  })
)

export const clearBatch = () => ({
  type: CLEAR_BATCH_FOOD_TYPES,
})
