import moment from 'moment'

import React from 'react'

import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'
import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'
import { IconProps } from 'components/Icon/Icon'
import { AvatarProps } from 'components/Avatar'

import { Avatar, CircleIcon, Typography } from 'components'

import {
  StyledIconWrapper,
  StyledItemActionContainer,
  StyledListItemContainer,
  StyledListItemContent,
  StyledListItemContentContainer,
  StyledListItemInfo,
  StyledListItemWrapperContainer,
} from './ConversationsStyled'

interface ConversationsListItemProps {
  actions: React.ReactNode
  avatarProps?: AvatarProps
  content: string | number | React.ReactNode
  iconProps?: IconProps
  isActive?: boolean
  isUnread?: boolean
  lastConversationDate?: Date | string
  onClick?: () => void
  to?: string
}

const ConversationsListItem: React.FC<ConversationsListItemProps> = ({
  actions,
  avatarProps,
  content,
  iconProps,
  isActive,
  isUnread,
  lastConversationDate,
  onClick,
  to,
}) => {
  const renderTimeStamp = () => {
    if (!lastConversationDate) {
      return null
    }

    if (1 < moment().diff(lastConversationDate, 'month', true)) {
      return moment(lastConversationDate).format(DISPLAY_SHORT_DATE_FORMAT)
    }

    return moment(lastConversationDate).fromNow()
  }

  return (
    <StyledListItemWrapperContainer>
      <StyledListItemContainer
        isActive={isActive}
        to={to}
        onClick={onClick}
      >
        <Avatar
          avatarSize="medium_big"
          {...avatarProps}
          iconProps={iconProps}
        />
        <StyledListItemContentContainer $isUnread={isUnread}>
          <StyledListItemContent>
            {content}
          </StyledListItemContent>
          {lastConversationDate && (
            <StyledListItemInfo>
              <Typography
                align="right"
                color={isUnread ? FLAG_COLOURS.INFO : NEUTRAL_COLOURS.GRAY}
                fontSize={14}
                minWidth={65}
                ellipsis
                nowrap
              >
                {renderTimeStamp()}
              </Typography>
              {isUnread && (
                <StyledIconWrapper>
                  <CircleIcon
                    background={FLAG_COLOURS.INFO}
                    size={12}
                  />
                </StyledIconWrapper>
              )}
            </StyledListItemInfo>
          )}
        </StyledListItemContentContainer>
      </StyledListItemContainer>
      {actions && (
        <StyledItemActionContainer>
          {actions}
        </StyledItemActionContainer>
      )}
    </StyledListItemWrapperContainer>
  )
}

export default ConversationsListItem
