import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'

import RequestableExtraSessionsWrapper from './RequestableExtraSessions/RequestableExtraSessionsWrapper'
import BookingAbsence from './BookingAbsence'
import ManagementParentAppView from './ManagementParentAppView'
import ParentCommunicationContainer from './ParentCommunication/ParentCommunicationContainer'

const ManagementParentAppContainer = ({
  BookingAbsenceGranted,
  ParentCommunicationGranted,
  RequestableExtraSessionsGranted,
}) => (
  <ManagementParentAppView
    BookingAbsenceGranted={BookingAbsenceGranted}
    ParentCommunicationGranted={ParentCommunicationGranted}
    RequestableExtraSessionsGranted={RequestableExtraSessionsGranted}
  />
)

ManagementParentAppContainer.authParams = {
  flags: [FEATURE_FLAGS.HAS_PARENT_APP_ACCESS, FEATURE_FLAGS.PARENT_BOOKING_CHILD_LIVES],
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

const mapState = (state) => ({
  BookingAbsenceGranted: auth.SELECTORS.getComponentIsAuthorised(state, BookingAbsence),
  ParentCommunicationGranted: auth.SELECTORS.getComponentIsAuthorised(state, ParentCommunicationContainer),
  RequestableExtraSessionsGranted: auth.SELECTORS.getComponentIsAuthorised(state, RequestableExtraSessionsWrapper),
})

const enhance = compose(
  connect(mapState),
)

export default enhance(ManagementParentAppContainer)
