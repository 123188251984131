import React from 'react'

import { generateRoute } from 'utils/routing'

import { Callout, EmptyState, Page, Pagination, Section, Select, Spinner, Table, Toolbar } from 'components'

import i18n from 'translations'

const ChildFundingView = ({
  child,
  errorMessages,
  fundingFilterOptions,
  isEmpty,
  isLoading,
  onPageChange,
  onSortChange,
  onStatusFilterChange,
  page,
  pageCount,
  perPage,
  sortField,
  sortOrder,
  status,
  tableData,
  totalResults,
}) => {
  const { firstName: childFirstName, id: childId } = child

  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (isEmpty) {
      return (
        <EmptyState
          icon="funding"
          text1={i18n.t('module:Children:Child:Funding:List:emptyStateText1', { childFirstName })}
          text2={i18n.t('module:Children:Child:Funding:List:emptyStateText2', { childFirstName })}
        />
      )
    }

    return (
      <div>
        {renderPagination()}
        <Table
          columns={[
            {
              align: 'left',
              field: 'fundingType',
              sortKey: 'funding.type.name',
              sortable: true,
              title: i18n.t('module:Children:Child:Funding:List:Table:Headers:fundingType'),
            },
            {
              field: 'period',
              sortKey: 'startDate',
              sortable: true,
              title: i18n.t('module:Children:Child:Funding:List:Table:Headers:period'),
            },
            {
              field: 'hourlyRate',
              sortable: true,
              title: i18n.t('module:Children:Child:Funding:List:Table:Headers:hourlyRate'),
            },
            {
              field: 'totalHours',
              sortable: true,
              title: i18n.t('module:Children:Child:Funding:List:Table:Headers:totalHours'),
            },
            {
              field: 'total',
              sortable: true,
              title: i18n.t('module:Children:Child:Funding:List:Table:Headers:totalFundingValue'),
            },
            {
              align: 'center',
              field: 'rowToButton',
            },
          ]}
          data={tableData}
          sortField={sortField}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </div>
    )
  }

  const actions = (
    <Section.Actions
      primary={[{
        label: i18n.t('module:Children:Child:Funding:List:assignFundingLabel'),
        to: generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.FUNDING.ADD', { childId }),
      }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title={i18n.t('module:Children:Child:Funding:List:title')}
    >
      <Callout content={errorMessages} error />
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <Select
              options={fundingFilterOptions}
              placeholder={i18n.t('module:Children:Child:Funding:List:statusDropdownPlaceholder')}
              searchable={false}
              value={status}
              onChange={onStatusFilterChange}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      {renderContent()}
    </Page.Section>
  )
}

export default ChildFundingView
