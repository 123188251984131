import React from 'react'
import isEqual from 'react-fast-compare'

import { InfiniteScroll, Table } from 'components'

import i18n from 'translations'

import { StyledTableWrapper } from './ExceptionalUnavailableTimesTableStyled'

const COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'date',
    sortKey: 'date',
    sortable: true,
    title: i18n.t('global:Date'),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'time',
    sortKey: 'startTime',
    sortable: true,
    title: i18n.t('global:Time'),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'nurseryClass',
    sortKey: 'nurseryClass.name',
    sortable: true,
    title: i18n.t('global:Room'),
  },
  {
    align: 'right',
    field: 'options',
  },
]

const ExceptionalUnavailableTimesTable = ({
  data,
  isFetching,
  onPageChange,
  onSortChange,
  page,
  pageCount,
  sortField,
  sortOrder,
}) => (
  <StyledTableWrapper id="exceptionalUnavailableTimesScrollableTarget">
    <InfiniteScroll
      dataLength={data?.length || 0}
      hasMore={page < pageCount}
      isFetching={isFetching}
      next={() => onPageChange((+page) + 1)}
      scrollableTarget="exceptionalUnavailableTimesScrollableTarget"
      lightSpinner
    >
      <Table
        columns={COLUMNS}
        data={data}
        sortField={sortField}
        sortOrder={sortOrder}
        visualType="transparent"
        noMargin
        onSortChange={onSortChange}
      />
    </InfiniteScroll>
  </StyledTableWrapper>
)

export default React.memo(ExceptionalUnavailableTimesTable, isEqual)
