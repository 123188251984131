import React from 'react'

import { generateRoute } from 'utils/routing'

import { Callout, EmptyState, Page, Pagination, Section, Select, Spinner, Table, Toolbar } from 'components'

import i18n from 'translations'

const ManagementPaymentTypesView = ({
  errorMessages,
  isEmpty,
  isLoading,
  onPageChange,
  onStatusFilterChange,
  page,
  pageCount,
  perPage,
  statusFilter,
  statusFilterOptions,
  tableData,
  totalResults,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('global:pagination:entries')}
      titleSingular={i18n.t('global:pagination:entry')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderToolbar = () => (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <Select
            clearable={false}
            options={statusFilterOptions}
            placeholder={i18n.t('global:All')}
            searchable={false}
            value={statusFilter}
            onChange={onStatusFilterChange}
          />
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (isEmpty) {
      return (
        <EmptyState
          icon="plus"
          text1={i18n.t('module:Management:ManagementPaymentTypes:emptyStateTitle')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={[
            {
              align: 'left',
              field: 'name',
              title: i18n.t('module:Management:ManagementPaymentTypes:tableTitle'),
            },
            {
              field: 'rowToButton',
            },
          ]}
          data={tableData}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  return (
    <Page.Section
      actions={(
        <Section.Actions primary={[{ to: generateRoute('MANAGEMENT.FINANCE_SETTINGS.PAYMENT_TYPES.ADD') }]} />
      )}
      isLoading={isLoading}
      title={i18n.t('module:Management:ManagementPaymentTypes:title')}
    >
      <Callout content={errorMessages} error />
      {renderToolbar()}
      {renderContent()}
    </Page.Section>
  )
}

export default ManagementPaymentTypesView
