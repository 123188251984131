import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as carersSelectors from './selectors'

const withCarersService = (WrappedComponent) => {
  const mapState = ({ carers }) => ({ carers })

  const mapDispatch = injectActions('carersActions', actions)

  const Component = ({ carers, carersActions, ...others }) => (
    <WrappedComponent
      carersActions={carersActions}
      carersListState={carers.list}
      carersSelectors={carersSelectors}
      carersSingleState={carers.single || {}}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withCarersService
