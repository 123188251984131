import {
  CLEAR_FOOD_MENU_LIST,
  GET_FOOD_MENU_LIST,
  GET_FOOD_MENU_LIST_FAILED,
  GET_FOOD_MENU_LIST_SUCCESS,
} from './constants'

import { CREATE_FOOD_MENU_SUCCESS, UPDATE_FOOD_MENU_SUCCESS } from '../single/constants'

import { addFoodMenu, updateFoodMenu } from './helpers'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: {},
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case GET_FOOD_MENU_LIST:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_FOOD_MENU_LIST_SUCCESS:
      return {
        data: payload.data,
        error: null,
        isFetching: false,
        meta: payload.meta,
      }
    case GET_FOOD_MENU_LIST_FAILED:
      return { ...state, error, isFetching: false }
    case CREATE_FOOD_MENU_SUCCESS:
      return addFoodMenu(state, payload.data)
    case UPDATE_FOOD_MENU_SUCCESS:
      return updateFoodMenu(state, payload.data)
    case CLEAR_FOOD_MENU_LIST:
      return initialState
    default:
      return state
  }
}
