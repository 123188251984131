import i18n from 'translations'

export const CHILD_ADHOC_SESSIONS_FILTER = {
  ACTIVE: 'ACTIVE',
  ALL: 'ALL',
  ARCHIVED: 'ARCHIVED',
}

export const CHILD_ADHOC_SESSIONS_OPTIONS = [
  { label: i18n.t('global:Active'), value: CHILD_ADHOC_SESSIONS_FILTER.ACTIVE },
  { label: i18n.t('global:All'), value: CHILD_ADHOC_SESSIONS_FILTER.ALL },
  { label: i18n.t('global:Archived'), value: CHILD_ADHOC_SESSIONS_FILTER.ARCHIVED },
]

export const SESSION_TYPE = {
  NURSERY: 'nursery',
  PARENT: 'parent',
}
