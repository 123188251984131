import React from 'react'

import { TextField, Typography } from 'components'

import i18n from 'translations'

const FormPasswordInput = ({
  input: { onChange, value },
  meta: { error, submitFailed, valid },
  ...rest
}) => (
  <React.Fragment>
    <TextField
      {...rest}
      error={submitFailed && error}
      type="password"
      valid={!(submitFailed && !valid)}
      value={value}
      onChange={onChange}
    />
    <Typography fontSize="12" margin="10px 0 0">
      {i18n.t('components:PasswordInput:text')}
    </Typography>
  </React.Fragment>
)

export default FormPasswordInput
