import {
  CLEAR_CHILD_REGISTERS_STATISTICS,
  GET_CHILD_REGISTERS_STATISTICS,
  GET_CHILD_REGISTERS_STATISTICS_FAILED,
  GET_CHILD_REGISTERS_STATISTICS_SUCCESS,
} from './constants'

export const listStatistics = (options = {}) => ({ childRegistersApiClient }) => (dispatch) => {
  const { mergeResult, onSuccess, params } = options

  dispatch({
    type: GET_CHILD_REGISTERS_STATISTICS,
  })

  childRegistersApiClient.list(params).then(
    (data) => {
      dispatch({
        mergeResult,
        payload: data,
        type: GET_CHILD_REGISTERS_STATISTICS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: GET_CHILD_REGISTERS_STATISTICS_FAILED,
      })
    },
  )
}

export const clearListStatistics = () => ({
  type: CLEAR_CHILD_REGISTERS_STATISTICS,
})
