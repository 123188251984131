import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change, getFormValues } from 'redux-form'

import { CHILD_EXTRA_ITEMS_TYPE_OPTIONS } from 'services/legacy/childExtraItems/constants'

import { hasMoreRecords } from 'utils/pagination'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withNurseryExtraItemsService } from 'services/legacy/nurseryExtraItems'
import { withChildExtraItemsService } from 'services/legacy/childExtraItems'
import { withNurseriesService } from 'services/nurseries'
import { withRouter } from 'services/router'

import { formattedNurseryExtraItemOptions } from 'services/legacy/nurseryExtraItems/list/selectors'

import ChildExtraItemsAddView from './ChildExtraItemsAddView'
import { FORM_NAME } from './components/ChildExtraItemsAddForm'

const NURSERY_INVOICE_SETTINGS_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings.invoice',
    'nurseryInvoiceSettings',
  ],
}

const CHILD_EXTRA_ITEMS_GROUPS = {
  read: [
    'childExtraItem.extraItem',
    'extraItem',
    'childExtraItem.attendancePeriod',
    'period',
  ],
}

class ChildExtraItemsAddContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      extraItemsPage: 1,
      oldSearchedPhrase: null,
    }
  }

  componentDidMount() {
    const {
      childExtraItemsActions,
      isEdit,
      nurseriesActions,
      nurseryOptions,
      params,
    } = this.props

    const nurseryApiParams = { groups: NURSERY_INVOICE_SETTINGS_GROUPS }

    nurseriesActions.get(nurseryOptions.id, {
      params: nurseryApiParams,
    })

    if (isEdit) {
      childExtraItemsActions.get(params.id, { groups: CHILD_EXTRA_ITEMS_GROUPS })
    }
  }

  componentDidUpdate(prevProps) {
    const { extraItem, feeCalculationOptions, updateField } = this.props

    if (extraItem && (!prevProps.extraItem || (prevProps.extraItem.value !== extraItem.value))) {
      const option = _.find(feeCalculationOptions, { value: extraItem.feeCalculations })

      updateField('feeCalculations', option)
    }
  }

  componentWillUnmount() {
    const { childExtraItemsActions, nurseryExtraItemsActions } = this.props

    childExtraItemsActions.clearSingle()
    nurseryExtraItemsActions.clearList()
  }

  goToExtraItems = () => {
    const { navigate, params } = this.props

    navigate(`/children/${params.childId}/booking-pattern/extra-items`)
  }

  handleSubmitSuccess = () => {
    this.goToExtraItems()
  }

  handleSubmit = (fields) => {
    const { childExtraItemsActions, childExtraItemsSelectors, isEdit, params } = this.props
    const { childId, id } = params

    const payload = childExtraItemsSelectors.getPayloadSelector({ ...fields, childId })

    if (isEdit) {
      return childExtraItemsActions.update(id, payload, { groups: CHILD_EXTRA_ITEMS_GROUPS }, this.handleSubmitSuccess)
    }

    return childExtraItemsActions.create(payload, { groups: CHILD_EXTRA_ITEMS_GROUPS }, this.handleSubmitSuccess)
  }

  archiveExtraItem = (archived, type) => () => {
    const { childExtraItemsActions, childExtraItemsSelectors, params } = this.props
    const { id } = params

    const apiParams = {}
    const archivePayload = childExtraItemsSelectors.getArchivedPayloadSelector({ archived, type })

    return childExtraItemsActions.update(id, archivePayload, apiParams, this.handleSubmitSuccess)
  }

  handleArchiveClick = (archived, type) => () => {
    const { modalActions, modalConsts } = this.props

    const label = archived ? 'archive' : 'unarchive'

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: label,
      icon: label,
      onConfirm: this.archiveExtraItem(archived, type),
      text: (
        <div>
          {`Are you sure you want to ${label} this child extra items?`}
          <br />
          You can unarchive it at any time.
        </div>
      ),
    })
  }

  handleLoadMoreExtraItems = async (phrase) => {
    const { nurseryExtraItemsActions, nurseryExtraItemsSelectors } = this.props
    const { extraItemsPage, oldSearchedPhrase } = this.state

    let newSearchPhrase = oldSearchedPhrase
    let newPage = extraItemsPage

    if (oldSearchedPhrase !== phrase) {
      nurseryExtraItemsActions.clearList()

      newSearchPhrase = phrase
      newPage = 1
    }

    let result = {}

    const nurseryExtraItemsCriteria = nurseryExtraItemsSelectors.getCriteriaSelector({ phrase: newSearchPhrase })

    await nurseryExtraItemsActions.list({
      mergeResult: true,
      onSuccess: ({ data, meta }) => {
        const { nurseryExtraItemsOptions } = this.props
        const newNurseryExtraItemsOptions = formattedNurseryExtraItemOptions(data)

        result = {
          hasMore: hasMoreRecords(meta),
          options: [...(nurseryExtraItemsOptions || []), ...(newNurseryExtraItemsOptions || [])],
        }

        this.setState({
          extraItemsPage: newPage + 1,
          oldSearchedPhrase: newSearchPhrase,
        })
      },
      params: {
        criteria: nurseryExtraItemsCriteria,
        page: newPage,
      },
    })

    return result
  }

  render() {
    const {
      cost,
      description,
      disableEndDate,
      errorMessages,
      feeCalculationOptions,
      formValues,
      initialValues,
      isArchived,
      isEdit,
      isFetching,
      isSubmitting,
      params,
      showDateRange,
      showFeeCalculation,
      showQuantity,
    } = this.props
    const { childId } = params

    const isLoading = isFetching || (isEdit && !initialValues)

    return (
      <ChildExtraItemsAddView
        childId={childId}
        cost={cost}
        description={description}
        disableEndDate={disableEndDate}
        errorMessages={errorMessages}
        feeCalculationOptions={feeCalculationOptions}
        formValues={formValues}
        frequencyOptions={CHILD_EXTRA_ITEMS_TYPE_OPTIONS}
        initialValues={initialValues}
        isArchived={isArchived}
        isEdit={isEdit}
        isFormLoading={isSubmitting}
        isLoading={isLoading}
        showDateRange={showDateRange}
        showFeeCalculation={showFeeCalculation}
        showQuantity={showQuantity}
        onArchiveClick={this.handleArchiveClick}
        onLoadMoreExtraItems={this.handleLoadMoreExtraItems}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childExtraItemsSelectors,
  childExtraItemsSingleState,
  nurseryExtraItemsSelectors,
  params,
}) => ({
  cost: childExtraItemsSelectors.getCost(FORM_NAME)(state),
  description: childExtraItemsSelectors.getDescription(FORM_NAME)(state),
  disableEndDate: childExtraItemsSelectors.getEndDateDisabledFlag(FORM_NAME)(state),
  errorMessages: appSelectors.getErrorMessages(childExtraItemsSingleState),
  extraItem: childExtraItemsSelectors.getExtraItem(FORM_NAME)(state),
  feeCalculationOptions: nurseryExtraItemsSelectors.getFeeCalculationsOptions(state),
  formValues: getFormValues(FORM_NAME)(state),
  initialValues: childExtraItemsSelectors.getInitialValues(state),
  isArchived: childExtraItemsSelectors.isArchivedSelector(state),
  isEdit: !!params.id,
  isFetching: appSelectors.getIsFetching(childExtraItemsSingleState),
  isSubmitting: appSelectors.getIsSubmitting(childExtraItemsSingleState),
  nurseryExtraItemsOptions: nurseryExtraItemsSelectors.getNurseryExtraItemsOptionsSelector(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  showDateRange: childExtraItemsSelectors.getDateRangeFlag(FORM_NAME)(state),
  showFeeCalculation: childExtraItemsSelectors.getFeeCalculationFlag(FORM_NAME)(state),
  showQuantity: childExtraItemsSelectors.getQuantityFlag(FORM_NAME)(state),
})

const mapDispatch = {
  updateField: (fieldName, fieldValue) => change(FORM_NAME, fieldName, fieldValue),
}

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withNurseryExtraItemsService,
  withChildExtraItemsService,
  withNurseriesService,
  connect(mapState, mapDispatch),
)

export default enhance(ChildExtraItemsAddContainer)
