import i18n from 'translations'

import ReportsWrapper from 'module/Organization/Reports/index'
import GroupRevenue from 'module/Organization/Reports/GroupRevenue'
import Forecasting from 'module/Organization/Reports/Forecasting'
import AnnualRevenue from 'module/Organization/Reports/AnnualRevenue'
import ChildrenSignedUp from 'module/Reports/ChildrenSignedUp'
import ChildrenStarting from 'module/Reports/ChildrenStarting'
import ChildrenLeaving from 'module/Reports/ChildrenLeaving'
import EnquiryConversionRate from 'module/Enquiries/Report/ConversionRate'
import EnquiryPerformance from 'module/Enquiries/Report/Performance'
import EnquiryViewingPerformance from 'module/Enquiries/Report/ViewingPerformance'
import FinanceBalance from 'module/Finance/FinanceReport/Balance'
import FinanceRevenuePerAge from 'module/Finance/FinanceReport/RevenuePerAge'
import FinanceAgedDebt from 'module/Finance/FinanceReport/AgedDebt'
import FinanceRevenuePerRoom from 'module/Finance/FinanceReport/RevenuePerRoom'
import FinanceCreditNotes from 'module/Finance/FinanceReport/CreditNotes'
import FinanceBadDebt from 'module/Finance/FinanceReport/BadDebt'
import FinanceFundingV2 from 'module/Finance/FinanceReport/Funding/V2'
import FinanceFailedRecurringPayment from 'module/Finance/FinanceReport/FailedRecurringPayment'
import FinanceActiveRecurringPayment from 'module/Finance/FinanceReport/ActiveRecurringPayment'
import MonthlyFteChange from 'module/Reports/MonthlyFteChange'

import FinanceDeposits from './Deposits'
import BadDebtWrapper from './BadDebt/BadDebtWrapper'

export const ROUTE_NAMES = {
  'ORGANIZATION_REPORTS.ACTIVE_RECURRING_PAYMENT': 'ORGANIZATION_REPORTS.ACTIVE_RECURRING_PAYMENT',
  'ORGANIZATION_REPORTS.AGED_DEBT': 'ORGANIZATION_REPORTS.AGED_DEBT',
  'ORGANIZATION_REPORTS.ANNUAL_REVENUE': 'ORGANIZATION_REPORTS.ANNUAL_REVENUE',
  'ORGANIZATION_REPORTS.BAD_DEBT': 'ORGANIZATION_REPORTS.BAD_DEBT',
  'ORGANIZATION_REPORTS.BALANCE': 'ORGANIZATION_REPORTS.BALANCE',
  'ORGANIZATION_REPORTS.CREDIT_NOTES': 'ORGANIZATION_REPORTS.CREDIT_NOTES',
  'ORGANIZATION_REPORTS.DEPOSITS': 'ORGANIZATION_REPORTS.DEPOSITS',
  'ORGANIZATION_REPORTS.ENQUIRIES_CONVERSION_RATE': 'ORGANIZATION_REPORTS.ENQUIRIES_CONVERSION_RATE',
  'ORGANIZATION_REPORTS.ENQUIRIES_PERFORMANCE': 'ORGANIZATION_REPORTS.ENQUIRIES_PERFORMANCE',
  'ORGANIZATION_REPORTS.ENQUIRIES_VIEWING_PERFORMANCE': 'ORGANIZATION_REPORTS.ENQUIRIES_VIEWING_PERFORMANCE',
  'ORGANIZATION_REPORTS.FAILED_RECURRING_PAYMENT': 'ORGANIZATION_REPORTS.FAILED_RECURRING_PAYMENT',
  'ORGANIZATION_REPORTS.FORECASTING': 'ORGANIZATION_REPORTS.FORECASTING',
  'ORGANIZATION_REPORTS.FUNDING': 'ORGANIZATION_REPORTS.FUNDING',
  'ORGANIZATION_REPORTS.GROUP_REVENUE': 'ORGANIZATION_REPORTS.GROUP_REVENUE',
  'ORGANIZATION_REPORTS.INDEX': 'ORGANIZATION_REPORTS.INDEX',
  'ORGANIZATION_REPORTS.MONTHLY_FTE_CHANGE': 'ORGANIZATION_REPORTS.MONTHLY_FTE_CHANGE',
  'ORGANIZATION_REPORTS.NURSERIES_LEAVING': 'ORGANIZATION_REPORTS.NURSERIES_LEAVING',
  'ORGANIZATION_REPORTS.NURSERIES_SIGNED_UP': 'ORGANIZATION_REPORTS.NURSERIES_SIGNED_UP',
  'ORGANIZATION_REPORTS.NURSERIES_STARTING': 'ORGANIZATION_REPORTS.NURSERIES_STARTING',
  'ORGANIZATION_REPORTS.REVENUE_PER_AGE': 'ORGANIZATION_REPORTS.REVENUE_PER_AGE',
  'ORGANIZATION_REPORTS.REVENUE_PER_ROOM': 'ORGANIZATION_REPORTS.REVENUE_PER_ROOM',
}

const OrganizationReportsRouting = {
  childRoutes: [
    {
      component: GroupRevenue,
      name: ROUTE_NAMES['ORGANIZATION_REPORTS.GROUP_REVENUE'],
      path: 'group-revenue',
      title: i18n.t('module:Organization:Reports:GroupRevenue:title'),
    },
    {
      component: AnnualRevenue,
      name: ROUTE_NAMES['ORGANIZATION_REPORTS.ANNUAL_REVENUE'],
      path: 'annual-revenue',
      title: i18n.t('module:Organization:Reports:AnnualRevenue:title'),
    },
    {
      component: Forecasting,
      name: ROUTE_NAMES['ORGANIZATION_REPORTS.FORECASTING'],
      path: 'forecasting',
      title: i18n.t('module:Organization:Reports:Forecasting:title'),
    },
    {
      component: EnquiryConversionRate,
      name: ROUTE_NAMES['ORGANIZATION_REPORTS.ENQUIRIES_CONVERSION_RATE'],
      path: 'enquiries-conversion-rate',
      title: i18n.t('module:Enquiries:Report:ConversionRate:Organization:title'),
    },
    {
      component: EnquiryPerformance,
      name: ROUTE_NAMES['ORGANIZATION_REPORTS.ENQUIRIES_PERFORMANCE'],
      path: 'enquiries-performance',
      title: i18n.t('module:Enquiries:Report:Performance:Organization:title'),
    },
    {
      component: EnquiryViewingPerformance,
      name: ROUTE_NAMES['ORGANIZATION_REPORTS.ENQUIRIES_VIEWING_PERFORMANCE'],
      path: 'enquiries-viewing-performance',
      title: i18n.t('module:Enquiries:Report:ViewingPerformance:Organization:title'),
    },
    {
      component: FinanceAgedDebt,
      name: ROUTE_NAMES['ORGANIZATION_REPORTS.AGED_DEBT'],
      path: 'age-debt',
      title: i18n.t('module:Finance:Reports:AgedDebt:Organization:title'),
    },
    {
      component: BadDebtWrapper,
      indexRoute: {
        component: FinanceBadDebt,
      },
      name: ROUTE_NAMES['ORGANIZATION_REPORTS.BAD_DEBT'],
      path: 'bad-debt',
      title: i18n.t('module:Finance:Reports:BadDebt:Organization:title'),
    },
    {
      component: FinanceBalance,
      name: ROUTE_NAMES['ORGANIZATION_REPORTS.BALANCE'],
      path: 'balance',
      title: i18n.t('module:Finance:Reports:Balances:Organization:title'),
    },
    {
      component: FinanceCreditNotes,
      name: ROUTE_NAMES['ORGANIZATION_REPORTS.CREDIT_NOTES'],
      path: 'credit-notes',
      title: i18n.t('module:Finance:Reports:CreditNotes:Organization:title'),
    },
    {
      component: FinanceDeposits,
      name: ROUTE_NAMES['ORGANIZATION_REPORTS.DEPOSITS'],
      path: 'deposits',
      title: i18n.t('module:Finance:Reports:Deposits:Organization:title'),
    },
    {
      component: FinanceFundingV2,
      name: ROUTE_NAMES['ORGANIZATION_REPORTS.FUNDING'],
      path: 'funding',
      title: i18n.t('module:Finance:Reports:Funding:Organization:title'),
    },
    {
      component: FinanceRevenuePerAge,
      name: ROUTE_NAMES['ORGANIZATION_REPORTS.REVENUE_PER_AGE'],
      path: 'revenue-per-age',
      title: i18n.t('module:Finance:Reports:RevenuePerAge:Organization:title'),
    },
    {
      component: FinanceRevenuePerRoom,
      name: ROUTE_NAMES['ORGANIZATION_REPORTS.REVENUE_PER_ROOM'],
      path: 'revenue-per-room',
      title: i18n.t('module:Finance:Reports:RevenuePerRoom:Organization:title'),
    },
    {
      component: ChildrenSignedUp,
      name: ROUTE_NAMES['ORGANIZATION_REPORTS.NURSERIES_SIGNED_UP'],
      path: 'nurseries-signed-up',
      title: i18n.t('module:Reports:ChildrenSignedUp:Organization:title'),
    },
    {
      component: ChildrenStarting,
      name: ROUTE_NAMES['ORGANIZATION_REPORTS.NURSERIES_STARTING'],
      path: 'nurseries-starting',
      title: i18n.t('module:Reports:ChildrenStarting:Organization:title'),
    },
    {
      component: ChildrenLeaving,
      name: ROUTE_NAMES['ORGANIZATION_REPORTS.NURSERIES_LEAVING'],
      path: 'nurseries-leaving',
      title: i18n.t('module:Reports:ChildrenLeaving:Organization:title'),
    },
    {
      component: MonthlyFteChange,
      name: ROUTE_NAMES['ORGANIZATION_REPORTS.MONTHLY_FTE_CHANGE'],
      path: 'monthly-fte-change',
      title: i18n.t('module:Reports:MonthlyFteChange:Organization:title'),
    },
    {
      component: FinanceFailedRecurringPayment,
      name: ROUTE_NAMES['ORGANIZATION_REPORTS.FAILED_RECURRING_PAYMENT'],
      path: 'failed-recurring-payment',
      title: i18n.t('module:Finance:Reports:FailedRecurringPayment:title'),
    },
    {
      component: FinanceActiveRecurringPayment,
      name: ROUTE_NAMES['ORGANIZATION_REPORTS.ACTIVE_RECURRING_PAYMENT'],
      path: 'active-recurring-payment',
      title: i18n.t('module:Finance:Reports:ActiveRecurringPayment:title'),
    },
  ],
  indexRoute: {
    component: ReportsWrapper,
  },
  name: ROUTE_NAMES['ORGANIZATION_REPORTS.INDEX'],
  path: 'organization/reports',
  title: 'Reports',
}

OrganizationReportsRouting.ROUTE_NAMES = ROUTE_NAMES

export default OrganizationReportsRouting
