import moment from 'moment'
import _ from 'lodash'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { ABSENCE_TYPE, ABSENCE_TYPE_OPTIONS } from 'module/Register/constants'

import { getChildSelector } from 'services/legacy/child/single/selectors'

export const getChildAbsencesSelector = (state) => state.childAbsences

export const getChildAbsencesSingleSelector = createSelector(
  [getChildAbsencesSelector],
  (state) => state.single.data,
)

export const getInitialValues = (childProps, date) => createSelector(
  [getChildAbsencesSingleSelector, getChildSelector],
  (data, childData) => {
    if (!data) {
      const childDetails = childProps || childData

      return {
        child: {
          avatar: childDetails.photo,
          label: childDetails.name,
          value: childDetails.id,
        },
        date: [moment(date), moment(date)],
      }
    }

    const {
      absenceReason,
      absenceType,
      child,
      comments,
      endDate,
      startDate,
    } = data || {}

    return {
      ...(absenceReason ? {
        absenceReason: {
          label: absenceReason.name,
          value: absenceReason.id,
        },
      } : {}),
      absenceType: _.find(ABSENCE_TYPE_OPTIONS, ['value', absenceType]),
      child: {
        avatar: child.photo,
        label: child.name,
        value: child.id,
      },
      comments,
      date: [startDate, endDate],
    }
  },
)

export const getPayload = (formValues) => {
  if (!formValues) {
    return null
  }

  const {
    absenceReason,
    absenceType,
    child,
    comments,
    date,
    overrideOverlapping,
  } = formValues

  const [startDate, endDate] = date

  return {
    ...(ABSENCE_TYPE.ABSENCE === absenceType.value && absenceReason ? {
      absenceReason: {
        id: absenceReason.value,
      },
    } : {}),
    absenceType: absenceType.value,
    child: {
      id: child.value,
    },
    comments,
    endDate: moment(endDate).format(DEFAULT_DATE_FORMAT),
    overrideOverlapping,
    startDate: moment(startDate).format(DEFAULT_DATE_FORMAT),
  }
}
