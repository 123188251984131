import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class ChildrenStartingReportApiClient extends ServiceBase {
  getChildrenStarting = (params) => this.request({
    path: `/v2/children/statistics/starters?${getQueryStringV2(params)}`,
  })

  getNurseriesStarting = (params) => this.request({
    path: `/v2/nurseries/statistics/starters?${getQueryStringV2(params)}`,
  })

  getStatistics = (params) => this.request({
    path: `/v2/statistics/starters?${getQueryStringV2(params)}`,
  })
}

export default ChildrenStartingReportApiClient
