import React from 'react'

import { noop } from 'constants/global'

import { Page, SearchBar, Section, Spinner } from 'components'

import i18n from 'translations'

import NurseryFilesListForm from './components/NurseryFilesListForm'
import { StyledCustomPageWrapper } from './NurseryFilesListStyled'

const NurseryFilesListView = ({
  isFetching,
  onSearchChange,
  pagination,
  ...rest
}) => {
  const renderContent = () => {
    if (isFetching && 1 === pagination.page) {
      return (
        <Spinner />
      )
    }

    return (
      <NurseryFilesListForm
        isFetching={isFetching}
        pagination={pagination}
        onSubmit={noop}
        {...rest}
      />
    )
  }

  return (
    <Page isLoading={isFetching && 1 === pagination.page}>
      <Page.Content>
        <StyledCustomPageWrapper>
          <Section title={i18n.t('module:NurseryFiles:title')} noMargin>
            <SearchBar
              placeholder={i18n.t('global:searchByFileName')}
              variant="standard"
              noBackground
              onChange={onSearchChange}
            />
            {renderContent()}
          </Section>
        </StyledCustomPageWrapper>
      </Page.Content>
    </Page>
  )
}

export default NurseryFilesListView
