import { compose } from 'recompose'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import { withShellService } from 'services/shell'
import { withRouter } from 'services/router'

import i18n from 'translations'

import EnquiriesWrapperView from './EnquiriesWrapperView'

import List from './List'
import Reports from './Report'

class EnquiriesWrapperContainer extends Component {
  componentDidMount() {
    const { isEnquiryListGranted, isEnquiryReportsGranted, shellActions } = this.props

    return shellActions.setSubMenu([
      {
        hidden: !isEnquiryListGranted,
        icon: 'enquiries',
        label: i18n.t('module:Enquiries:List:title'),
        to: generateRoute('ENQUIRIES.LIST'),
      },
      {
        icon: 'enquiry-show-around',
        label: i18n.t('module:Enquiries:Viewings:title'),
        to: generateRoute('ENQUIRIES.VIEWINGS'),
      },
      {
        hidden: !isEnquiryReportsGranted,
        icon: 'reports',
        label: i18n.t('module:Enquiries:Report:title'),
        to: generateRoute('ENQUIRIES.REPORT'),
      },
    ])
  }

  shouldComponentUpdate(nextProps) {
    const { location: { pathname }, router } = nextProps

    if (generateRoute('ENQUIRIES.INDEX') === pathname) {
      router.replace(generateRoute('ENQUIRIES.LIST'))
    }

    return true
  }

  componentWillUnmount() {
    const { shellActions } = this.props

    shellActions.clearSubMenu()
  }

  render() {
    const { children } = this.props

    return (
      <EnquiriesWrapperView>
        {children}
      </EnquiriesWrapperView>
    )
  }
}

EnquiriesWrapperContainer.authParams = {
  flags: [FEATURE_FLAGS.ENQUIRIES],
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

const mapState = (state) => ({
  isEnquiryListGranted: auth.SELECTORS.getComponentIsAuthorised(state, List),
  isEnquiryReportsGranted: auth.SELECTORS.getComponentIsAuthorised(state, Reports),
})

const enhance = compose(
  withRouter,
  withShellService,
  connect(mapState),
)

export default enhance(EnquiriesWrapperContainer)
