import _ from 'lodash'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { getNurseryPublicUrl } from 'services/nurseries/single/selectors/single'

import { Avatar, Button, Typography } from 'components'

import i18n from 'translations'

export const getTableData = (data, properties) => {
  if (!data?.length) {
    return null
  }

  return _.map(data, (child) => {
    const {
      ageMonths,
      codeReference,
      firstName,
      id,
      isArchived,
      name,
      nursery,
      photo,
      surname,
    } = child
    const linkUrl = `${getNurseryPublicUrl(nursery, properties)}${generateRoute('CHILDREN.CHILD.ABOUT.PROFILE', {
      childId: id,
    })}`

    return {
      codeReference,
      name: (
        <Avatar
          direction="horizontal"
          initials={[firstName, surname]}
          isArchived={isArchived}
          src={photo}
          subTitle={(
            <Typography color={NEUTRAL_COLOURS.BASIC}>
              {i18n.t('global:ageMonths', { ageMonths })}
            </Typography>
          )}
          target="_blank"
          title={(
            <Typography hoverOpacity={0.5} primary>
              {name}
            </Typography>
          )}
          to={linkUrl}
        />
      ),
      nursery: nursery.name,
      rowToButton: (
        <Button.TableAction
          target="_blank"
          to={linkUrl}
        />
      ),
    }
  })
}
