import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { DEPOSIT_TYPES } from 'services/legacy/deposits/constants'

import { isRequired } from 'utils/fieldValidation'
import { toFloat } from 'utils/data'

import invoiceConstants from 'services/legacy/invoices/constants'

import { Banner, Form } from 'components'

import i18n from 'translations'

export const FORM_NAME = 'ADD_ITEM_MODAL_FORM'

const AddInvoiceItemModalForm = ({
  childAdhocSessionOptions,
  childFundingOptions,
  childId,
  handleSubmit,
  includeUsedDepositIds,
  isDepositSelected,
  isExtraItemSelected,
  isExtraSessionSelected,
  isFundingDeficit,
  isFundingSelected,
  isItemEdit,
  isLegacyItemSelected,
  isLoading,
  isReadOnly,
  isRefundDepositSelected,
  lineItemOptions,
  onCancelClick,
  onSubmit,
  showCost,
  skipPendingDepositIds,
}) => {
  const renderFormFields = () => {
    if (isExtraSessionSelected) {
      return (
        <React.Fragment>
          <Form.Row
            label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:ExtraSession:label')}
            required
          >
            <Form.Row.FlexItem flex="0 0 400px">
              <Field
                clearable={false}
                component={Form.Select}
                disabled={isReadOnly}
                maxMenuHeight={150}
                name="childExtraSession"
                options={childAdhocSessionOptions}
                placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:ExtraSession:placeholder')}
                searchable={false}
                validate={isRequired}
              />
            </Form.Row.FlexItem>
          </Form.Row>
          <Form.Row
            label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:TotalCost:label')}
            required
          >
            <Form.Row.FlexItem flex="0 0 150px">
              <Field
                component={Form.TextField}
                name="total"
                placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:TotalCost:placeholder')}
                type="number"
                validate={isRequired}
                disabled
              />
            </Form.Row.FlexItem>
          </Form.Row>
        </React.Fragment>
      )
    }

    if (isFundingSelected) {
      return (
        <React.Fragment>
          {!isFundingDeficit && (
            <Form.Row
              label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Funding:label')}
            >
              <Form.Row.FlexItem flex="0 0 400px">
                <Field
                  clearable={false}
                  component={Form.Select}
                  disabled={isReadOnly}
                  name="childFunding"
                  options={childFundingOptions}
                  placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Funding:placeholder')}
                  searchable={false}
                  validate={isRequired}
                />
              </Form.Row.FlexItem>
            </Form.Row>
          )}
          {!isFundingDeficit && (
            <Form.Row
              label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:HourlyRate:label')}
              required
            >
              <Form.Row.FlexItem flex="0 0 150px">
                <Field
                  component={Form.TextField}
                  name="unitPrice"
                  placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:HourlyRate:placeholder')}
                  type="number"
                  validate={isRequired}
                  disabled
                />
              </Form.Row.FlexItem>
            </Form.Row>
          )}
          {!isFundingDeficit && (
            <Form.Row
              label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:TotalHours:label')}
              required
            >
              <Form.Row.FlexItem flex="0 0 150px">
                <Field
                  component={Form.TextField}
                  disabled={isReadOnly}
                  name="quantity"
                  placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:TotalHours:placeholder')}
                  type="number"
                  validate={isRequired}
                />
              </Form.Row.FlexItem>
            </Form.Row>
          )}
          <Form.Row
            label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:TotalCost:label')}
          >
            <Form.Row.FlexItem flex="0 0 150px">
              <Field
                component={Form.TextField}
                disabled={isReadOnly}
                name="total"
                placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:TotalCost:placeholder')}
                type="number"
                validate={isRequired}
              />
            </Form.Row.FlexItem>
          </Form.Row>
        </React.Fragment>
      )
    }

    if (isExtraItemSelected) {
      return (
        <React.Fragment>
          <Form.Row
            label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:ExtraItem:label')}
            required
          >
            <Form.Row.FlexItem flex="0 0 400px">
              <Field
                childId={childId}
                component={Form.InfiniteDropdowns.ChildExtraItems}
                disabled={isReadOnly}
                extraFields={['frequency', 'quantity', 'total', 'unitPrice']}
                maxMenuHeight={150}
                name="childExtraItemProjections"
                placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:ExtraItem:placeholder')}
                validate={isRequired}
                multi
              />
            </Form.Row.FlexItem>
          </Form.Row>
          <Form.Row
            label={i18n.t('global:Description')}
            required
          >
            <Form.Row.FlexItem flex="0 0 400px">
              <Field
                component={Form.TextField}
                disabled={isReadOnly}
                name="name"
                placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Description:placeholder')}
                validate={isRequired}
              />
            </Form.Row.FlexItem>
          </Form.Row>
        </React.Fragment>
      )
    }

    if (isDepositSelected) {
      return (
        <React.Fragment>
          <Form.Row
            label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Deposit:label')}
            required
          >
            <Form.Row.FlexItem flex="0 0 400px">
              <Field
                childId={childId}
                component={Form.InfiniteDropdowns.Deposits}
                disabled={isReadOnly}
                includeUsedDepositIds={includeUsedDepositIds}
                name="deposit"
                placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Deposit:placeholder')}
                skipPendingDepositIds={skipPendingDepositIds}
                type={DEPOSIT_TYPES.DEPOSIT}
                validate={isRequired}
                pendingOnly
              />
            </Form.Row.FlexItem>
          </Form.Row>
          <Form.Row
            label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Amount:label')}
            required
          >
            <Form.Row.FlexItem flex="0 0 150px">
              <Field
                component={Form.TextField}
                name="total"
                placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Amount:placeholder')}
                type="number"
                validate={isRequired}
                disabled
              />
            </Form.Row.FlexItem>
          </Form.Row>
        </React.Fragment>
      )
    }

    if (isRefundDepositSelected) {
      return (
        <React.Fragment>
          <Form.Row
            label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:RefundDeposit:label')}
            required
          >
            <Form.Row.FlexItem flex="0 0 400px">
              <Field
                childId={childId}
                component={Form.InfiniteDropdowns.Deposits}
                disabled={isReadOnly}
                maxMenuHeight={150}
                name="deposit"
                placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:RefundDeposit:placeholder')}
                type={DEPOSIT_TYPES.REFUND}
                validate={isRequired}
                pendingOnly
              />
            </Form.Row.FlexItem>
          </Form.Row>
          <Form.Row
            label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Amount:label')}
            required
          >
            <Form.Row.FlexItem flex="0 0 150px">
              <Field
                component={Form.TextField}
                name="total"
                placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Amount:placeholder')}
                type="number"
                validate={isRequired}
                disabled
              />
            </Form.Row.FlexItem>
          </Form.Row>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <Form.Row
          label={i18n.t('global:Description')}
          required
        >
          <Form.Row.FlexItem flex="0 0 400px">
            <Field
              component={Form.TextField}
              disabled={isReadOnly}
              name="name"
              placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Description:placeholder')}
              validate={isRequired}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        {showCost && (
          <Form.Row
            label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Cost:label')}
          >
            <Form.Row.FlexItem flex="0 0 150px">
              <Field
                component={Form.TextField}
                disabled={isReadOnly}
                name="unitPrice"
                placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Cost:placeholder')}
                type="number"
              />
            </Form.Row.FlexItem>
          </Form.Row>
        )}
        {showCost && (
          <Form.Row
            label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Quantity:label')}
          >
            <Form.Row.FlexItem flex="0 0 150px">
              <Field
                component={Form.TextField}
                disabled={isReadOnly}
                name="quantity"
                placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Quantity:placeholder')}
                type="number"
              />
            </Form.Row.FlexItem>
          </Form.Row>
        )}
        <Form.Row
          label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:TotalCost:label')}
          required
        >
          <Form.Row.FlexItem flex="0 0 150px">
            <Field
              component={Form.TextField}
              disabled={isReadOnly}
              name="total"
              placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:TotalCost:placeholder')}
              type="number"
              validate={isRequired}
            />
          </Form.Row.FlexItem>
          {!showCost && !isReadOnly && (
            <Form.Row.Item>
              <Banner.Info>The value entered will be deducted from the invoice total.</Banner.Info>
            </Form.Row.Item>
          )}
        </Form.Row>
      </React.Fragment>
    )
  }

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Field
        component={Form.TextField}
        name="id"
        type="hidden"
      />
      <Form.Row
        label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:ItemType:label')}
        required
      >
        <Form.Row.Item>
          <Field
            clearable={false}
            component={Form.Select}
            disabled={isReadOnly || isLegacyItemSelected}
            maxMenuHeight={200}
            name="type"
            options={lineItemOptions}
            placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:ItemType:placeholder')}
            searchable={false}
            validate={isRequired}
          />
        </Form.Row.Item>
      </Form.Row>
      {renderFormFields()}
      <Form.FooterActions
        isSubmitting={isLoading}
        submitDisabled={isReadOnly}
        submitLabel={isItemEdit ? i18n.t('global:Update') : i18n.t('global:AddItem')}
        onCancelClick={onCancelClick}
      />
    </Form>
  )
}

export const validate = (values, { isFundingSelected }) => {
  const { childFunding, total, type } = values
  const { amountUsed = 0, total: fundingTotal = 0 } = childFunding || {}
  const amountRemaining = toFloat(fundingTotal) - toFloat(amountUsed)
  const currentTotal = toFloat(total)

  const errors = {}

  if (isFundingSelected && currentTotal && amountRemaining < currentTotal) {
    errors.total = i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:fundingTotalErrorText')
  }

  if (type?.value === invoiceConstants.LINE_ITEM_TYPES.DISCOUNT && total && 0 > total) {
    errors.total = i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:negativeCostErrorText')
  }

  return errors
}

export default reduxForm({ form: FORM_NAME, validate })(AddInvoiceItemModalForm)
