import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as financeFundingReportSelectors from './selectors'

const withFinanceFundingReportService = (WrappedComponent) => {
  const mapState = ({ financeFundingReport }) => ({ financeFundingReport })

  const mapDispatch = injectActions('financeFundingReportActions', actions)

  const Component = ({ financeFundingReport, financeFundingReportActions, ...others }) => (
    <WrappedComponent
      financeFundingReportActions={financeFundingReportActions}
      financeFundingReportChildrenState={financeFundingReport.children}
      financeFundingReportNurseriesState={financeFundingReport.nurseries}
      financeFundingReportSelectors={financeFundingReportSelectors}
      financeFundingReportStatisticsState={financeFundingReport.statistics}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withFinanceFundingReportService
