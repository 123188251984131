import React from 'react'

import { Banner, Page, Section, Space, Spinner, Switch, Typography } from 'components'

import i18n from 'translations'

import { StyledFinanceSubWrapper } from './ManagementNewFinanceStyled'

interface ManagementNewFinanceViewProps {
  isFetching: boolean
  isOrganizationContext?: boolean
  newFinanceEnabled?: boolean
  onChangeNewFinance: (value?: boolean) => void
}

const ManagementNewFinanceView: React.FC<ManagementNewFinanceViewProps> = ({
  isFetching,
  isOrganizationContext,
  newFinanceEnabled,
  onChangeNewFinance,
}) => {
  const renderSwitch = () => (
    <React.Fragment>
      <Typography margin="20px 0">
        {i18n.t('module:Management:Finance:NewFinance:body:newFinanceSwitchLabel')}
      </Typography>
      <Switch
        value={newFinanceEnabled}
        simple
        onChange={onChangeNewFinance}
      />
    </React.Fragment>
  )

  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    if (isOrganizationContext) {
      return (
        <React.Fragment>
          <Typography>
            {i18n.t('module:Management:Finance:NewFinance:body:isOrganisationBody:subtitleFirstLine')}
          </Typography>
          <StyledFinanceSubWrapper>
            <ol type="1">
              <li>
                {i18n.t('module:Management:Finance:NewFinance:body:isOrganisationBody:li:firstLine')}
              </li>
              <li>
                {i18n.t('module:Management:Finance:NewFinance:body:isOrganisationBody:li:secondLine')}
              </li>
              <li>
                {i18n.t('module:Management:Finance:NewFinance:body:isOrganisationBody:li:thirdLine')}
              </li>
              <li>...</li>
              <li>
                {i18n.t('module:Management:Finance:NewFinance:body:isOrganisationBody:li:fifthLine')}
              </li>
              <li>
                {i18n.t('module:Management:Finance:NewFinance:body:isOrganisationBody:li:sixthLine')}
              </li>
            </ol>
          </StyledFinanceSubWrapper>
          <Typography>
            {i18n.t('module:Management:Finance:NewFinance:body:isOrganisationBody:subtitleSecondLine')}
          </Typography>
          <Space space="20px" />
          <Banner.Warning>
            <Space space="20px" />
            <Typography variant="h5">
              {i18n.t('module:Management:Finance:NewFinance:body:notes:title')}
            </Typography>
            <ul>
              <li>
                {i18n.t('module:Management:Finance:NewFinance:body:isOrganisationBody:notes:firstLi')}
              </li>
              <li>
                {i18n.t('module:Management:Finance:NewFinance:body:isOrganisationBody:notes:secondLi')}
              </li>
            </ul>
            <Space space="40px" />
          </Banner.Warning>
          {renderSwitch()}
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <Typography>{i18n.t('module:Management:Finance:NewFinance:body:subtitleFirstLine')}</Typography>
        <Typography>{i18n.t('module:Management:Finance:NewFinance:body:subtitleSecondLine')}</Typography>
        <StyledFinanceSubWrapper>
          <ol type="1">
            <li>{i18n.t('module:Management:Finance:NewFinance:body:li:firstLine')}</li>
            <li>{i18n.t('module:Management:Finance:NewFinance:body:li:secondLine')}</li>
            <li>{i18n.t('module:Management:Finance:NewFinance:body:li:thirdLine')}</li>
            <li>...</li>
          </ol>
        </StyledFinanceSubWrapper>
        <Banner.Warning>
          <Space space="20px" />
          <Typography variant="h5">
            {i18n.t('module:Management:Finance:NewFinance:body:notes:title')}
          </Typography>
          <p>{i18n.t('module:Management:Finance:NewFinance:body:notes:subtitleFirstLine')}</p>
          <Space space="40px" />
        </Banner.Warning>
        {renderSwitch()}
      </React.Fragment>
    )
  }

  if (isOrganizationContext) {
    return (
      <Section title={i18n.t('module:Management:Finance:NewFinance:title')}>
        {renderContent()}
      </Section>
    )
  }

  return (
    <Page.Section title={i18n.t('module:Management:Finance:NewFinance:title')}>
      {renderContent()}
    </Page.Section>
  )
}

export default ManagementNewFinanceView
