import { Moment } from 'moment'

import { Nullable } from 'constants/models'
import { Period } from 'services/booking/periods/models'

import { BasicListInitialState, BasicSingleInitialState } from 'utils/reducer'

export enum ActionType {
  create = 'create',
  delete = 'delete',
  update = 'update'
}

export interface PeriodTime {
  endDate?: Date | Moment | string
  id?: number
  period?: Period
  startDate?: Date | Moment | string
}

export interface PeriodTimesListState extends BasicListInitialState {
  data: Nullable<PeriodTime[]>
}

export interface PeriodTimesSingleState extends BasicSingleInitialState {
  data: PeriodTime
}

export interface PeriodTimesState {
  list: PeriodTimesListState
  single: PeriodTimesSingleState
}

export interface PeriodTimesRootState {
  periodTimes: PeriodTimesState
}
