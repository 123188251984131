import _ from 'lodash'

import { mergeData } from 'utils/data'

import {
  BIRTHDAYS_LIST_NEXT_YEAR,
  BIRTHDAYS_LIST_NEXT_YEAR_FAILED,
  BIRTHDAYS_LIST_NEXT_YEAR_SUCCESS,
  CLEAR_BIRTHDAYS_LIST,
} from './constants'

const initialState = {
  data: [],
  error: null,
  isFetching: false,
  meta: null,
}

export default (state = initialState, { error, mergeResult, payload, type }) => {
  switch (type) {
    case BIRTHDAYS_LIST_NEXT_YEAR:
      return { ...state, error: initialState.error, isFetching: true }
    case BIRTHDAYS_LIST_NEXT_YEAR_SUCCESS:
      return {
        ...state,
        data: _.uniqBy(mergeData(state.data, payload.data, mergeResult), ({ id }) => id),
        isFetching: false,
        meta: payload.meta,
      }
    case BIRTHDAYS_LIST_NEXT_YEAR_FAILED:
      return { ...state, error, isFetching: false }
    case CLEAR_BIRTHDAYS_LIST:
      return initialState
    default:
      return state
  }
}
