import React from 'react'

import { ModalBox } from 'components'

import i18n from 'translations'

import FormativeReportsExportForm from './components/FormativeReportsExportForm'

const FormativeReportsExportModalView = ({
  child,
  isSubmitting,
  labelsOptions,
  onCloseClick,
  onSubmit,
}) => (
  <ModalBox
    title={i18n.t('module:Modals:FormativeReportsExportModal:title')}
    autoHeight
    onCloseClick={onCloseClick}
  >
    <FormativeReportsExportForm
      child={child}
      isSubmitting={isSubmitting}
      labelsOptions={labelsOptions}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    />
  </ModalBox>
)

export default FormativeReportsExportModalView
