import React, { useEffect } from 'react'
import { compose } from 'recompose'

import { SelectProps } from 'components/Select/Select'
import { DropdownProps } from 'components/InfiniteDropdowns/InfiniteDropdownsModel'

import { withNurseryDiscountsV3Service, withNurseryDiscountsV3ServiceProps } from 'services/product/nurseryDiscountsV3'

import { Select } from 'components'

import i18n from 'translations'

// eslint-disable-next-line import-newlines/enforce, max-len
import withInfiniteDropdownHelpers, { withInfiniteDropdownHelpersProps } from '../../legacy/InfiniteDropdowns/withInfiniteDropdown'

const GROUPS = {
  read: [
    'nurseryDiscountProduct',
    'nurseryDiscountProduct.settings',
    'nurseryDiscountProductSettings',
  ],
}

export type NurseryDiscountsV3DropdownProps = withInfiniteDropdownHelpersProps
  & withNurseryDiscountsV3ServiceProps
  & SelectProps
  & DropdownProps

const NurseryDiscountsV3Dropdown: React.FC<NurseryDiscountsV3DropdownProps> = ({
  criteria,
  extraFields = [],
  infiniteDropdownHelpers,
  nurseryDiscountsV3Actions,
  onChange,
  value,
  ...rest
}) => {
  useEffect(() => {
    infiniteDropdownHelpers.handleComponentDidMount({
      action: () => nurseryDiscountsV3Actions.get({
        onFailed: () => onChange(null),
        onSuccess: infiniteDropdownHelpers.handleComponentDidMountOnSuccess,
        onlyData: true,
        params: [(value as any)?.value || value],
      }),
      value,
    })
  }, [])

  const listAction = ({ newPage, newSearchPhrase, ...others }) => {
    const criteriaFinal = [
      ...(criteria || []),
      {
        field: 'archived',
        value: 0,
      },
    ]

    if (newSearchPhrase) {
      criteriaFinal.push({
        field: 'name',
        value: newSearchPhrase,
      })
    }

    return nurseryDiscountsV3Actions.dropdownList({
      onSuccess: infiniteDropdownHelpers.handleLoadMoreElementsOnSuccessV2({
        extraFields,
        extraOptionsOnEnd: true,
        newPage,
        newSearchPhrase,
        ...others,
      }),
      params: {
        criteria: criteriaFinal,
        groups: GROUPS,
        page: newPage,
      },
    })
  }

  return (
    <Select
      {...infiniteDropdownHelpers.getSelectRestParams({ onChange, value, ...rest })}
      loadOptions={infiniteDropdownHelpers.handleLoadMoreElementsV2({ listAction })}
      placeholder={i18n.t('components:InfiniteDropdowns:NurseryDiscountsV3:placeholder')}
    />
  )
}

const enhance = compose(
  withNurseryDiscountsV3Service,
  withInfiniteDropdownHelpers,
)

export default enhance(NurseryDiscountsV3Dropdown)
