import React, { PropsWithChildren } from 'react'

import { StyledBottomBarContainer, StyledPlaceFiller } from './PageStyled'

const PageBottomBar: React.FC<PropsWithChildren<any>> = ({ children }) => (
  <React.Fragment>
    <StyledPlaceFiller />
    <StyledBottomBarContainer>
      {children}
    </StyledBottomBarContainer>
  </React.Fragment>
)

export default PageBottomBar
