import React, { useEffect } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'
import { ModalType } from 'modals'

import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withAppService, withAppServiceProps } from 'services/app'
import {
  withNurseryConsumablesV3Service,
  withNurseryConsumablesV3ServiceProps,
} from 'services/product/nurseryConsumablesV3'
import { withRouter, withRouterProps } from 'services/router'

import i18n from 'translations'

import ManagementConsumablesV3AddView from './ManagementConsumablesV3AddView'

const CONSUMABLES_GROUP = {
  read: [
    'nurseryConsumableProduct.settings',
    'nurseryConsumableProductSettings',
  ],
}

type ManagementConsumablesAddV3ContainerProps = withAppServiceProps
  & withNurseryConsumablesV3ServiceProps
  & withModalServiceProps
  & withRouterProps

const mapState = (state: RootState, {
  appSelectors,
  nurseryConsumablesV3Selectors,
  nurseryConsumablesV3SingleState,
  params,
}: ManagementConsumablesAddV3ContainerProps) => ({
  consumable: nurseryConsumablesV3Selectors.getNurseryConsumablesSingleSelector(state),
  errorMessages: appSelectors.getErrorMessages(nurseryConsumablesV3SingleState),
  isArchived: nurseryConsumablesV3Selectors.isArchived(state),
  isEdit: !!params.itemId,
  isFetching: appSelectors.getIsFetching(nurseryConsumablesV3SingleState),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const ManagementConsumablesV3AddContainer: React.FC<ManagementConsumablesAddV3ContainerProps & PropsFromRedux> = ({
  consumable,
  errorMessages,
  isArchived,
  isFetching,
  modalActions,
  navigate,
  nurseryConsumablesV3Actions,
  nurseryConsumablesV3Selectors,
  nurseryConsumablesV3SingleState,
  nurseryOptions,
  params,
}) => {
  const { itemId } = params
  const isEdit = !!itemId

  useEffect(() => {
    if (itemId) {
      nurseryConsumablesV3Actions.get({
        params: [itemId, { groups: CONSUMABLES_GROUP }],
      })
    }

    return () => {
      nurseryConsumablesV3Actions.clear()
    }
  }, [itemId])

  const isFormLoading = nurseryConsumablesV3SingleState.isSubmitting
  const initialValues = isEdit ? nurseryConsumablesV3Selectors.getFormInitialValues(consumable) : {}

  const goToConsumables = () => {
    navigate(`${nurseryOptions.prefix}/finance-settings/consumables`)
  }

  const archiveConsumable = (archived) => () => {
    const payload = nurseryConsumablesV3Selectors.getArchivePayload({ archived })

    return nurseryConsumablesV3Actions.update({
      body: payload,
      onSuccess: goToConsumables,
      params: [itemId],
    })
  }

  const handleArchiveClick = (archived) => () => {
    const text = archived
      ? i18n.t('module:Management:Finance:Consumables:Form:archiveItem')
      : i18n.t('module:Management:Finance:Consumables:Form:unarchiveItem')

    modalActions.show<ModalType.CONFIRM>(ModalType.CONFIRM, {
      confirmButtonLabel: archived ? i18n.t('global:Archive') : i18n.t('global:Unarchive'),
      icon: archived ? 'archive' : 'unarchive',
      onConfirm: archiveConsumable(archived),
      text,
    })
  }

  const handleSubmit = (fields) => {
    const body = nurseryConsumablesV3Selectors.getPayload(fields)

    if (isEdit) {
      modalActions.show<ModalType.CONFIRM>(ModalType.CONFIRM, {
        icon: 'warning',
        onConfirm: () => nurseryConsumablesV3Actions.update({
          body,
          onSuccess: () => goToConsumables(),
          params: [itemId],
        }),
        text: i18n.t('module:Management:Finance:Consumables:Form:submitConfirmContent'),
      })

      return
    }

    nurseryConsumablesV3Actions.create({
      body,
      onSuccess: () => goToConsumables(),
      params: {},
    })
  }

  return (
    <ManagementConsumablesV3AddView
      errorMessages={errorMessages}
      initialValues={initialValues}
      isArchived={isArchived}
      isEdit={isEdit}
      isFormLoading={isFormLoading}
      isLoading={isFetching}
      nurseryOptions={nurseryOptions}
      onArchiveClick={handleArchiveClick}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withRouter,
  withModalService,
  withAppService,
  withNurseryConsumablesV3Service,
  connector,
)

export default enhance(ManagementConsumablesV3AddContainer)
