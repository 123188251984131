import moment from 'moment'
import { flatten, nest } from 'utils/flatnest'
import { compose } from 'recompose'

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { FEATURE_FLAGS, ROLES } from 'constants/security'
import invoicesConstants from 'services/legacy/invoices/constants'

import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withNurseriesService } from 'services/nurseries'
import { withInvoicesService } from 'services/legacy/invoices'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withRouter } from 'services/router'

import GroupRevenueView from './GroupRevenueView'

const GROUPS = {
  read: [
    'nurseryInvoiceStatistics',
    'statistics.invoice.income',
    'statistics.invoice.items',
    'statistics.invoice.summary',
  ],
}
const DATE_FORMAT = 'YYYY-MM-DD'

export const CHART_NURSERIES_LIMIT = 15

class GroupRevenueContainer extends Component {
  constructor(props) {
    super(props)

    const { query } = props.location
    const { date, dateType, nursery } = nest(query)

    const after = date && date.after ? moment(date.after) : moment().startOf('month')
    const before = date && date.before ? moment(date.before) : moment().endOf('month')

    this.state = {
      filters: {
        date: {
          after: after.format(DATE_FORMAT),
          before: before.format(DATE_FORMAT),
        },
        dateType: dateType || invoicesConstants.INVOICE_DATE_TYPES.processedDate,
        invoiceStatus: invoicesConstants.STATUS_TYPES.DRAFT,
        nursery,
      },
      isLoading: false,
      nurseriesDropdown: [],
      page: 1,
      previousStatistics: null,
      statistics: null,
    }
  }

  componentDidMount() {
    const { nurseriesActions } = this.props

    nurseriesActions.clear()

    this.getNurseriesDropdownList(1, () => {
      const { nurseriesDropdown } = this.props

      this.setState({ nurseriesDropdown }, () => {
        /**
         * NOTE: after recursive operation all nurseries needed for the dropdown filter are stored in the local
         * state of the component that is why we can clear the redux store to fetch the nurseries for report table
         */
        nurseriesActions.clear()

        this.fetch()
      })
    })
  }

  componentWillUnmount() {
    const { nurseriesActions } = this.props

    nurseriesActions.clear()
  }

  fetch = () => {
    const {
      invoicesActions,
      invoicesSelectors,
      nurseriesActions,
      nurseriesSelectors,
      setLocationQuery,
    } = this.props
    const { filters } = this.state

    setLocationQuery(flatten(filters))

    const nurseriesCriteria = nurseriesSelectors.getCriteria(filters, true)
    const nurseriesParams = {
      criteria: nurseriesCriteria,
      groups: GROUPS,
    }

    const statisticsCriteria = invoicesSelectors.getRevenueCriteria(filters)
    const statisticsParams = {
      criteria: statisticsCriteria,
      groups: GROUPS,
    }

    nurseriesActions.clear()

    this.setState({ isLoading: false })
    this.getNurseriesList(nurseriesParams)

    invoicesActions.getStatistics(statisticsParams, ({ data: statistics }) => {
      this.setState({ statistics })

      const previousFilters = nurseriesSelectors.getPreviousPeriodDatesFilters(filters)
      const previousStatisticsCriteria = invoicesSelectors.getRevenueCriteria(previousFilters)
      const previousStatisticsParams = {
        criteria: previousStatisticsCriteria,
        groups: GROUPS,
      }

      /**
       * NOTE: we have to fetch the same previous period statistics to display them on mobile version of the page
       */
      invoicesActions.getStatistics(previousStatisticsParams, ({ data: previousStatistics }) => {
        this.setState({ previousStatistics })
      })
    })
  }

  getNurseriesDropdownList = (page, onSuccess) => {
    const { nurseriesActions } = this.props

    nurseriesActions.list({
      onSuccess: ({ meta: { limit, start, total_results: totalResults } }) => {
        if (start * limit < totalResults) {
          this.getNurseriesDropdownList(page + 1, onSuccess)
        } else {
          onSuccess()
        }
      },
      params: { page },
      recurrency: true,
    })
  }

  getNurseriesList = (params, page) => {
    const { nurseriesActions } = this.props
    const apiParams = {
      ...params,
      page: page || 1,
    }

    nurseriesActions.list({
      onSuccess: ({ meta: { limit, start, total_results: totalResults } }) => {
        if (start * limit < totalResults) {
          this.getNurseriesList(params, start + 1)
        } else {
          this.setState({ isLoading: true })
        }
      },
      params: apiParams,
      recurrency: true,
    })
  }

  handleDateChange = (dateRange) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    const [after, before] = dateRange
    const date = {
      after: after ? moment(after).format(DATE_FORMAT) : moment().startOf('month').add('year', -1).format(DATE_FORMAT),
      before: before ? moment(before).format(DATE_FORMAT) : moment().endOf('month').format(DATE_FORMAT),
    }

    this.setState((prevState) => ({
      filters: { ...prevState.filters, date },
    }), onPageChange(this.fetch)(1))
  }

  handleDateTypeChange = (dateType) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prevState) => ({
      filters: { ...prevState.filters, dateType: dateType && dateType.value },
    }), onPageChange(this.fetch)(1))
  }

  handleNurseryChange = (nursery) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prevState) => ({
      filters: { ...prevState.filters, nursery: nursery && nursery.value },
    }), onPageChange(this.fetch)(1))
  }

  handleSendReport = () => {
    const { modalActions, modalConsts, nurseriesActions, nurseriesSelectors } = this.props
    const { filters } = this.state

    const nurseriesCriteria = nurseriesSelectors.getCriteria(filters, true)

    modalActions.show(modalConsts.TYPES.EXPORT_REPORT, {
      filters: { ...filters, type: 'invoice' },
      onSendReport: (body, onSuccess, onFailed) => nurseriesActions.exportGroupRevenue(
        { ...body, criteria: [...body.criteria, ...nurseriesCriteria], groups: GROUPS },
        onSuccess,
        onFailed,
      ),
      title: 'Export your group revenue report',
      userLabel: 'Select a organisation user to send the export to',
    })
  }

  handleChangePage = (direction) => {
    const { nurseries } = this.props
    const { page } = this.state

    if (0 > direction && 1 === page) {
      return this.setState({ page: Math.ceil(nurseries.length / CHART_NURSERIES_LIMIT) })
    }

    if (nurseries.length > (CHART_NURSERIES_LIMIT * page)) {
      return this.setState({ page: page + direction })
    }

    return this.setState({ page: 1 })
  }

  render() {
    const { isDepositEnabled, isFinanceV3Enabled, nurseries, nurseriesChart } = this.props
    const { filters, isLoading, nurseriesDropdown, page, previousStatistics, statistics } = this.state
    const { date: { after, before }, dateType, nursery } = filters

    const dateRange = [after, before]
    const parsedFilters = new URLSearchParams(flatten({
      date: filters.date,
      dateType: filters.dateType,
    })).toString()

    return (
      <GroupRevenueView
        dateRange={dateRange}
        dateType={dateType}
        dateTypeOptions={invoicesConstants.INVOICE_DATE_OPTIONS}
        isDepositEnabled={isDepositEnabled}
        isFinanceV3Enabled={isFinanceV3Enabled}
        isLoading={!isLoading}
        nurseries={nurseries}
        nurseriesChart={nurseriesChart}
        nurseriesDropdown={nurseriesDropdown}
        nursery={nursery}
        page={page}
        parsedFilters={parsedFilters}
        previousStatistics={previousStatistics}
        statistics={statistics}
        onChangePage={this.handleChangePage}
        onChartPointClick={this.handleChartPointClick}
        onDateChange={this.handleDateChange}
        onDateTypeChange={this.handleDateTypeChange}
        onNurseryChange={this.handleNurseryChange}
        onSendReport={this.handleSendReport}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  invoicesStatisticsState,
  nurseriesListState,
  nurseriesSelectors,
}) => ({
  isDepositEnabled: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.DEPOSITS],
  }),
  isFetching: appSelectors.getIsFetching(nurseriesListState, invoicesStatisticsState),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
  nurseries: nurseriesSelectors.getOrganizationNurseriesDataWithPublicUrl(state),
  nurseriesChart: nurseriesSelectors.getOrganizationNurseriesDataForChartSelector(state),
  nurseriesDropdown: nurseriesSelectors.getOrganizationNurseriesDataSelectorDropdown(state),
})

GroupRevenueContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
  ],
}

const enhance = compose(
  withAppService,
  withInvoicesService,
  withPaginationUtils,
  withRouter,
  withRouterUtils,
  withModalService,
  withNurseriesService,
  connect(mapState),
)

export default enhance(GroupRevenueContainer)
