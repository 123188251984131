import i18n from 'translations'

export const COLUMNS = [
  {
    align: 'left',
    field: 'name',
    title: i18n.t('module:Organization:Children:TableHeaders:children'),
  },
  {
    field: 'codeReference',
    title: i18n.t('module:Organization:Children:TableHeaders:childReference'),
  },
  {
    align: 'left',
    field: 'nursery',
    title: i18n.t('module:Organization:Children:TableHeaders:nursery'),
  },
  {
    field: 'rowToButton',
  },
]
