import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { STATUS_TYPES } from 'services/legacy/invoices/constants'

import { getChildSelector } from 'services/legacy/child/single/selectors'

import { DEPOSIT_REFUND_USED, DEPOSIT_TYPES, DEPOSIT_USED } from '../constants'

const getDepositsSingleState = (state) => state.deposits.single

export const getDepositsSingleStateDataSelector = createSelector([getDepositsSingleState], (state) => state.data)

export const getDepositsSingleStateFormattedDataSelector = createSelector(
  [getDepositsSingleStateDataSelector],
  (data) => {
    if (!data) {
      return null
    }

    return {
      ...data,
      invoices: _.orderBy(data?.invoices, ['id'], ['desc']),
    }
  },
)

export const getInitialValues = (childId) => createSelector(
  [getDepositsSingleStateDataSelector, getChildSelector],
  (data, childData) => {
    if (!data) {
      return ({
        child: childId ? {
          avatar: childData?.photo,
          label: childData?.name,
          value: childData?.id,
        } : undefined,
        date: moment(),
        used: DEPOSIT_USED.ADD_DEPOSIT_TO_NEXT_INVOICE,
      })
    }

    const {
      amount,
      child,
      date,
      description,
      id,
      used,
    } = data

    return ({
      amount,
      child: {
        avatar: child?.photo,
        label: child?.name,
        value: child?.id,
      },
      date: moment(date),
      description,
      id,
      used: used ? DEPOSIT_USED.ADD_DEPOSIT_TO_NEXT_INVOICE : DEPOSIT_USED.MARK_AS_PAID,
    })
  },
)

export const getRefundInitialValues = () => ({
  used: DEPOSIT_REFUND_USED.ADD_REFUND_TO_NEXT_INVOICE,
})

export const getPayload = ({ isEdit, values }) => {
  if (!values) {
    return null
  }

  const {
    amount,
    child,
    date,
    description,
    used,
  } = values

  if (isEdit) {
    return ({
      amount: +amount,
      date: moment(date).format(DEFAULT_DATE_FORMAT),
      description,
      used: DEPOSIT_USED.ADD_DEPOSIT_TO_NEXT_INVOICE === used,
    })
  }

  return ({
    amount: +amount,
    child: {
      id: child.value || child,
    },
    date: moment(date).format(DEFAULT_DATE_FORMAT),
    description,
    type: DEPOSIT_TYPES.DEPOSIT,
    used: DEPOSIT_USED.ADD_DEPOSIT_TO_NEXT_INVOICE === used,
  })
}

export const getRefundPayload = ({ originalDepositId, values }) => {
  if (!values) {
    return null
  }

  const { used } = values

  return ({
    date: moment().format(DEFAULT_DATE_FORMAT),
    originalDeposit: { id: originalDepositId },
    type: DEPOSIT_TYPES.REFUND,
    used: DEPOSIT_REFUND_USED.ADD_REFUND_TO_NEXT_INVOICE === used,
  })
}

export const getIsRefund = (type) => DEPOSIT_TYPES.REFUND === type

const getIsDepositTypeRefund = createSelector([getDepositsSingleStateDataSelector], (data) => {
  if (!data) {
    return null
  }

  const { type } = data

  return getIsRefund(type)
})

const getIsDepositRefunded = createSelector([getDepositsSingleStateDataSelector], (data) => {
  if (!data) {
    return null
  }

  const { refundDeposit } = data

  return !!refundDeposit
})

export const getIsDepositInvoiced = createSelector([getDepositsSingleStateDataSelector], (data) => {
  if (!data) {
    return null
  }

  const { invoices } = data

  return 0 < invoices?.length
})

const getIsDepositPaid = createSelector([getDepositsSingleStateDataSelector], (data) => {
  if (!data) {
    return null
  }

  const { lastInvoice, used } = data

  return !used || STATUS_TYPES.PAID === lastInvoice?.status
})

export const getIsDepositEditingDisabled = createSelector([
  getIsDepositRefunded,
  getIsDepositInvoiced,
], (
  isDepositRefunded,
  isDepositInvoiced,
) => isDepositRefunded || isDepositInvoiced)

export const getIsDepositDeletingDisabled = createSelector([
  getIsDepositRefunded,
  getIsDepositInvoiced,
], (
  isDepositRefunded,
  isDepositInvoiced,
) => isDepositRefunded || isDepositInvoiced)

export const getIsDepositRefundingDisabled = createSelector([
  getIsDepositRefunded,
  getIsDepositPaid,
], (
  isDepositRefunded,
  isDepositPaid,
) => isDepositRefunded || !isDepositPaid)

export const getIsDepositRefundInvoiced = createSelector([
  getIsDepositTypeRefund,
  getIsDepositInvoiced,
], (
  isRefund,
  isDepositInvoiced,
) => isRefund && isDepositInvoiced)
