import { CLEAR_CONTRACTS, LIST_CONTRACTS, LIST_CONTRACTS_FAILED, LIST_CONTRACTS_SUCCESS } from './constants'

export const listForMembership = (id, options = {}) => ({ contractsApiClient }) => (dispatch) => {
  const { mergeResult, onFailed, onSuccess, params = {} } = options

  dispatch({ type: LIST_CONTRACTS })

  contractsApiClient.listForMembership(id, params).then(
    (response) => {
      dispatch({
        mergeResult,
        payload: response,
        type: LIST_CONTRACTS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: LIST_CONTRACTS_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const list = (options = {}) => ({ contractsApiClient }) => (dispatch) => {
  const { mergeResult, onFailed, onSuccess, params = {} } = options

  dispatch({ type: LIST_CONTRACTS })

  contractsApiClient.list(params).then(
    (response) => {
      dispatch({
        mergeResult,
        payload: response,
        type: LIST_CONTRACTS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: LIST_CONTRACTS_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const clearList = () => ({
  type: CLEAR_CONTRACTS,
})
