import React from 'react'

import { Page } from 'components'

import TimeWidget from 'module/Dashboard/Widgets/TimeWidget'

import WidgetContainer from '../components/WidgetContainer'

const AdminDashboardView = () => (
  <Page alwaysExpanded>
    <Page.Content>
      <WidgetContainer>
        <TimeWidget />
      </WidgetContainer>
    </Page.Content>
  </Page>
)

export default AdminDashboardView
