import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import {
  CLEAR_EXCEPTIONAL_UNAVAILABLE_TIMES_LIST,
  LIST_EXCEPTIONAL_UNAVAILABLE_TIMES,
  LIST_EXCEPTIONAL_UNAVAILABLE_TIMES_FAILED,
  LIST_EXCEPTIONAL_UNAVAILABLE_TIMES_SUCCESS,
} from './constants'

const initialState = getBasicListInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_EXCEPTIONAL_UNAVAILABLE_TIMES_LIST,
    failed: LIST_EXCEPTIONAL_UNAVAILABLE_TIMES_FAILED,
    init: LIST_EXCEPTIONAL_UNAVAILABLE_TIMES,
    success: LIST_EXCEPTIONAL_UNAVAILABLE_TIMES_SUCCESS,
  }, initialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
