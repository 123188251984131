import { generateBasicActions } from 'utils/service'

import {
  CLEAR_HOME_OBSERVATION,
  GET_HOME_OBSERVATION,
  GET_HOME_OBSERVATION_FAILED,
  GET_HOME_OBSERVATION_SUCCESS,
} from './constants'

export const getHomeObservation = (options = {}) => ({ homeObservationsApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_HOME_OBSERVATION_FAILED,
    init: GET_HOME_OBSERVATION,
    success: GET_HOME_OBSERVATION_SUCCESS,
  },
  options,
  service: homeObservationsApiClient,
  serviceMethod: 'get',
})

export const clearHomeObservation = () => ({
  payload: null,
  type: CLEAR_HOME_OBSERVATION,
})
