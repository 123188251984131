import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledComponent = styled.div``

export const StyledTitle = styled.div`
  display: flex;
  margin-bottom: 6px;

  & > div {
    margin-right: 8px;
  }
`

export const StyledChildProfileLink = styled.span`
  text-decoration: none;
  color: ${getBrandingColor('primary-color')}
`
