import i18n from 'translations'

import { EXPORT_FOR } from './constants'

export const getExportForOptions = (enableExtendedExport) => ([
  {
    label: i18n.t('module:Modals:InvoicesExport:ExportFor:general'),
    value: EXPORT_FOR.GENERAL,
  },
  {
    label: i18n.t('module:Modals:InvoicesExport:ExportFor:xero'),
    value: EXPORT_FOR.XERO,
  },
  {
    label: i18n.t('module:Modals:InvoicesExport:ExportFor:sage'),
    value: EXPORT_FOR.SAGE,
  },
  ...(enableExtendedExport ? [{
    label: i18n.t('module:Modals:InvoicesExport:ExportFor:quickbooks'),
    value: EXPORT_FOR.QUICKBOOKS,
  }] : []),
])
