import _ from 'lodash'

import { UPDATE_NEXT_MONTESSORI_ACTIVITIES_SUCCESS } from 'services/legacy/nextMontessoriActivities/single/constants'

import { mergeData } from 'utils/data'

import { CLEAR_NEXT_STEPS, GET_NEXT_STEPS, GET_NEXT_STEPS_FAILED, GET_NEXT_STEPS_SUCCESS } from './constants'
import { UPDATE_NEXT_STEP_SUCCESS } from '../single/constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: null,
}

export default (state = initialState, { error, mergeResult, payload, type }) => {
  switch (type) {
    case GET_NEXT_STEPS:
      return {
        ...state,
        error: initialState.error,
        isFetching: true,
      }
    case GET_NEXT_STEPS_SUCCESS:
      return {
        ...state,
        data: _.uniqBy(mergeData(state.data, payload.data, mergeResult), ({ id }) => id),
        isFetching: false,
        meta: payload.meta,
      }
    case GET_NEXT_STEPS_FAILED:
      return {
        ...state,
        error,
        isFetching: false,
      }
    case UPDATE_NEXT_MONTESSORI_ACTIVITIES_SUCCESS:
      return {
        ...state,
        data: _.map(state.data, (item) => {
          const { id } = item

          if (id === payload.data.id) {
            return payload.data
          }

          return item
        }),
      }
    case UPDATE_NEXT_STEP_SUCCESS:
      return {
        ...state,
        data: _.map(state.data, (item) => {
          const { id } = item

          if (id === payload.data.id) {
            return payload.data
          }

          return item
        }),
      }
    case CLEAR_NEXT_STEPS:
      return initialState
    default:
      return state
  }
}
