import _ from 'lodash'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import invoiceConstants from 'services/legacy/invoices/constants'

import { Currency, EmptyState, Typography } from 'components'
import Widget from 'module/Dashboard/components/Widget'

import SeeMoreListItem from '../../components/SeeMoreListItem'

import { StyledInvoiceCountContainer } from './DraftInvoiceWidgetStyled'

const DraftInvoiceWidgetView = ({
  invoiceList,
  isLoading,
}) => {
  const status = invoiceConstants.STATUS_TYPES.DRAFT

  const renderSeeMore = () => {
    if (!invoiceList || !invoiceList.length) {
      return null
    }

    return (
      <Widget.List.Row>
        <SeeMoreListItem to={`/management/finance/invoices${status ? `?status=${status}` : ''}`} />
      </Widget.List.Row>
    )
  }

  const renderListItem = (item, index) => {
    const { child, id, name, number, total } = item
    const { firstName, id: childId, surname } = child

    const to = `children/${childId}/finance/invoices/${id}`

    const renderName = () => {
      if (!firstName && !surname) {
        return null
      }

      return (
        <React.Fragment>
          {firstName || ''}
          {' '}
          {surname || ''}
        </React.Fragment>
      )
    }

    return (
      <Widget.List.Row
        childList={[child]}
        index={index}
        key={id}
        leftColumn={(
          <React.Fragment>
            <Typography
              color={NEUTRAL_COLOURS.WHITE}
              ellipsis
            >
              {renderName()}
            </Typography>
            <Typography
              color={NEUTRAL_COLOURS.WHITE}
              ellipsis
            >
              {name}
              {number && <br />}
              {number}
            </Typography>
          </React.Fragment>
        )}
        rightColumn={(
          <Currency value={total} />
        )}
        to={to}
      />
    )
  }

  const renderEmptyState = (icon, text1, text2) => (
    <EmptyState
      icon={icon}
      text1={(
        <React.Fragment>
          {text1}
          <br />
          {text2}
        </React.Fragment>
      )}
      iconComponentSmallMargin
    />
  )

  const renderContent = () => {
    if (!invoiceList || !invoiceList.length) {
      return renderEmptyState('invoices', 'You’re all caught up!', 'There are no draft invoices.')
    }

    return (
      <Widget.List>
        {_.map(invoiceList, renderListItem)}
        {renderSeeMore()}
      </Widget.List>
    )
  }

  const renderHeader = () => {
    if (!invoiceList || !invoiceList.length) {
      return 'Draft invoices'
    }

    return (
      <React.Fragment>
        Draft invoices
        <StyledInvoiceCountContainer>
          {invoiceList.length}
        </StyledInvoiceCountContainer>
      </React.Fragment>
    )
  }

  return (
    <Widget>
      <Widget.Header>
        {renderHeader()}
      </Widget.Header>
      <Widget.Content
        isLoading={isLoading}
      >
        {renderContent()}
      </Widget.Content>
    </Widget>
  )
}

export default DraftInvoiceWidgetView
