import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const WIDTH_CHART = 320
export const HEIGHT_CHART = 320

export const StyledContainer = styled.div`
  display: grid;
  justify-content: center;
  grid-gap: 15px;
  grid-template-columns: ${WIDTH_CHART}px auto;
  
  @media(max-width: 820px) {
    grid-gap: 25px;
    grid-template-columns: auto;
    text-align: center;
  }
`

export const StyledChartContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  @media(max-width: 820px) {
    align-items: center;
  }
`

export const StyledChart = styled.div`
  height: ${HEIGHT_CHART}px;
  width: ${WIDTH_CHART}px;
  
  @media(max-width: 380px) {
    height: 200px;
    width: 200px;
  }
    
  & > div {
    margin-top: -20px;
  }
`

export const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${NEUTRAL_COLOURS.SILVER};
  border: 1px solid ${NEUTRAL_COLOURS.SILVER};
  border-radius: 10px;
  padding: 10px 20px;
  text-align: center;
  
  @media(max-width: 680px) {
    width: 100%;
  }
`

export const StyledSubtitleContainer = styled.div`
  font-size: 20px;
  font-weight: 600;
`

export const StyledLegendContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-rows: repeat(3, auto);
  grid-auto-flow: column;
  height: min-content;
  align-self: center;

  @media(max-width: 1070px) {
    grid-template-columns: repeat(2, auto);
  }
  
  @media(max-width: 820px) {
    grid-template-columns: repeat(3, auto);
  }
  
  @media(max-width: 780px) {
    grid-template-columns: repeat(2, auto);
  }
  
  @media(max-width: 570px) {
    grid-template-columns: repeat(1, auto);
  }
`

interface StyledLegendProps {
  $withSecondRow: boolean
}

export const StyledLegend = styled.div<StyledLegendProps>`
  display: grid;
  background-color: ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  border: 1px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  border-radius: 10px;
  padding: 10px 20px;
  min-width: 200px;
  min-height: 85px;
  text-align: center;
  
  ${({ $withSecondRow }) => $withSecondRow && `
    grid-template-rows: auto auto;
    grid-gap: 10px;
  `}
`

export const StyledItemTopContainer = styled.div`
  display: grid;
  grid-template-columns: 20px auto;
  grid-gap: 10px;
  justify-content: center;
  align-items: center;
`

export const StyledTooltipContainer = styled.div`
  background: rgb(77, 77, 77);
  color: rgb(255, 255, 255);
  font-size: 16px;
  border-radius: 12px;
  box-shadow: rgb(0 0 0 / 25%) 0 2px 10px 0;
  padding: 12px 16px;
`

export const StyledTooltipTitleContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const StyledTooltipTitle = styled.span`
  font-weight: 600;
`

export const StyledTooltipValue = styled.span`
  font-weight: 600;
`
