import styled from 'styled-components'

import { StyledRightContainer } from 'components/Field/InlineEdit/InlineEditStyled'

export const StyledVisibleBody = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 150px 100px 130px auto;
  margin: 0 -25px;
  
  @media(max-width: 900px) {
    grid-template-columns: auto;
    grid-row-gap: 30px;
  }
  
  @media(max-width: 600px) {
    margin: 0;
    margin-top: -10px;
  }
`

export const StyledFieldWrapper = styled.div`
  @media(max-width: 480px) {
    ${StyledRightContainer} {
      margin-left: 0;
      text-align: right;
    }
  }
`
