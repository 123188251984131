import React, { useState } from 'react'

import { FLAG_COLOURS } from 'constants/colors'

import { Button, Icon, Typography } from 'components'

import i18n from 'translations'

import { StyledContainer, StyledContentContainer } from './AlertBarStyled'

const AlertBar = ({
  icon,
  message,
  onCloseClick,
  title,
  iconComponent,
  url,
  buttonColor,
  color = FLAG_COLOURS.INFO,
}) => {
  const [hide, setHide] = useState(false)

  const handleCloseClick = () => {
    setHide(true)

    onCloseClick?.()
  }

  return (
    <StyledContainer color={color} hide={hide}>
      <StyledContentContainer>
        {icon && <Icon color={color} height={16} icon={icon} />}
        {iconComponent}
        <div>
          {title && (
            <Typography margin="0 0 5px 0" variant="h6">
              {title}
              {': '}
            </Typography>
          )}
          <Typography fontSize={16}>
            {message}
            {url && (
              <a href={url} rel="noreferrer noopener" target="_blank">
                <Typography fontSize={15} bold underline>
                  {i18n.t('module:Shell:AlertBar:learnMore')}
                </Typography>
              </a>
            )}
          </Typography>
        </div>
        <Button
          color={buttonColor || color}
          hierarchy="tertiary"
          icon="close"
          size="small"
          negativeMargins
          onClick={handleCloseClick}
        />
      </StyledContentContainer>
    </StyledContainer>
  )
}

export default AlertBar
