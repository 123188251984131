import moment from 'moment'
import _ from 'lodash'

import React from 'react'
import { compose } from 'recompose'
import { Field, FieldArray, reduxForm } from 'redux-form'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { STAFF_ENTRY_MAPPINGS, WORKLOG_RECORD_DAY_TYPE } from 'services/legacy/membershipRegisters/constants'

import { isSameDay, millisecondsToHoursAndMinutesString } from 'utils/date'

import { isRequired } from 'utils/fieldValidation'

import { Button, DateString, EmptyState, Form, Space, Table, Typography } from 'components'
import { ShiftLabel } from 'module/Staff/components'

import i18n from 'translations'

import StaffWorklogPreviewFormEditRow from './StaffWorklogPreviewFormEditRow'
import { StyledRequiredChar, StyledShiftLabel } from './StaffWorklogPreviewFormStyled'

export const STAFF_WORKLOG_PREVIEW_FORM = 'StaffWorklogPreviewForm'

const renderEditRow = (formEntries, onOngoingEntryEndClick, onUpdateEntryTotalTime) => (
  fieldName,
  index,
  actions,
) => (
  <StaffWorklogPreviewFormEditRow
    actions={actions}
    fieldName={fieldName}
    formEntries={formEntries}
    index={index}
    onOngoingEntryEndClick={onOngoingEntryEndClick}
    onUpdateEntryTotalTime={onUpdateEntryTotalTime}
  />
)

const renderEditVersionItem = ({
  fields,
  formEntries,
  onOngoingEntryEndClick,
  onUpdateEntryTotalTime,
}) => fields.map(renderEditRow(
  formEntries,
  onOngoingEntryEndClick,
  onUpdateEntryTotalTime,
))

const StaffWorklogPreviewForm = ({
  editMode,
  formEntries,
  handleSubmit,
  isEmpty,
  isSubmitting,
  memberDetails,
  onChangeEditMode,
  onClockOutButtonClick,
  onEndButtonClick,
  onOngoingEntryEndClick,
  onSubmit,
  onUpdateEntryTotalTime,
  previousRecord,
  record,
  selectedDate,
  worklogDayType,
}) => {
  const { date, entries } = record || {}
  const { ongoing: previousOngoing } = previousRecord || {}
  const showCommentField = editMode && !isEmpty
  const { name } = memberDetails || {}

  const renderSignOutAt = ({ entryEndDate, id, isSameDayEntry, signInAt, signOutAt }) => {
    if (signOutAt) {
      const endTime = signOutAt.format('HH:mm')

      if (!isSameDayEntry) {
        return (
          <React.Fragment>
            {endTime}
            {'  '}
            <Typography color={NEUTRAL_COLOURS.GRAY} inline>
              {<DateString date={entryEndDate} />}
            </Typography>
          </React.Fragment>
        )
      }

      return endTime
    }

    return (
      <Button
        hierarchy="secondary"
        label={i18n.t('global:End')}
        size="small"
        negativeMargins
        onClick={() => onEndButtonClick(id, date, signInAt)}
      />
    )
  }

  const renderTableHeader = () => (
    <Table.Tr>
      <Table.Th align="left" width="15%">
        {i18n.t('module:Staff:StaffWorklogPreview:activity')}
      </Table.Th>
      <Table.Th align="left" width="14%">
        {i18n.t('module:Staff:StaffWorklogPreview:startTime')}
      </Table.Th>
      <Table.Th align="left" width="14%">
        {i18n.t('module:Staff:StaffWorklogPreview:endTime')}
      </Table.Th>
      <Table.Th align="left" width="15%">
        {i18n.t('module:Staff:StaffWorklogPreview:totalTime')}
      </Table.Th>
      <Table.Th align="left" width="20%">
        {_.upperFirst(i18n.t('global:location'))}
      </Table.Th>
      {editMode && (
        <Table.Th width="5%" />
      )}
    </Table.Tr>
  )

  const renderPreviewVersionItem = (entry) => {
    const {
      entryEndDate,
      id,
      isSameDayEntry,
      nurseryClass,
      signInAt,
      signInAtOriginal,
      signOutAt,
      totalTime,
      type,
    } = entry
    const { label: roomName = '-' } = nurseryClass || {}
    const { label } = STAFF_ENTRY_MAPPINGS[type] || {}

    return (
      <Table.Tr>
        <Table.Td align="left">
          <StyledShiftLabel>
            <ShiftLabel type={type}>
              {label}
            </ShiftLabel>
          </StyledShiftLabel>
        </Table.Td>
        <Table.Td align="left">
          {signInAt.format('HH:mm')}
        </Table.Td>
        <Table.Td align="left">
          {renderSignOutAt({ entryEndDate, id, isSameDayEntry, signInAt: signInAtOriginal, signOutAt })}
        </Table.Td>
        <Table.Td align="left">
          {millisecondsToHoursAndMinutesString(totalTime)}
        </Table.Td>
        <Table.Td align="left">
          {roomName}
        </Table.Td>
      </Table.Tr>
    )
  }

  const renderTableRows = () => {
    if (editMode) {
      return (
        <FieldArray
          component={renderEditVersionItem}
          name="entries"
          props={{ formEntries, onOngoingEntryEndClick, onUpdateEntryTotalTime }}
        />
      )
    }

    return _.map(entries, renderPreviewVersionItem)
  }

  const renderComment = () => (
    <React.Fragment>
      <Space space="40px" />
      <Form.Row
        label={(
          <React.Fragment>
            {i18n.t('global:Comment')}
            <StyledRequiredChar>
              *
            </StyledRequiredChar>
            <Space margin="0 0 0 5px" inline />
            <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
              {`(${i18n.t('module:Staff:StaffWorklogPreview:commentSubLabel')})`}
            </Typography>
          </React.Fragment>
        )}
        verticalLabel
      >
        <Form.Row.FlexItem>
          <Field
            component={Form.TextAreaField}
            name="changeComment"
            placeholder={i18n.t('module:Staff:StaffWorklogPreview:commentPlaceholder')}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
    </React.Fragment>
  )

  const renderContent = () => {
    if (editMode && !formEntries?.length) {
      return (
        <EmptyState
          icon="staff-rota"
          padding="80px 0"
          text1={i18n.t('module:Staff:StaffWorklogPreview:EmptyState:default')}
        />
      )
    }

    if (!editMode && isEmpty && previousOngoing) {
      return (
        <EmptyState
          actions={(
            <Button
              label="Clock out"
              onClick={onClockOutButtonClick}
            />
          )}
          icon="staff-rota"
          padding="80px 0"
          text1={
            isSameDay(selectedDate, moment())
              ? i18n.t('module:Staff:StaffWorklogPreview:EmptyState:ongoingInBetweenDay', { name })
              : i18n.t('module:Staff:StaffWorklogPreview:EmptyState:ongoingLastDay', { name })
          }
          iconComponentSmallMargin
        />
      )
    }

    if (!editMode && isEmpty) {
      return (
        <EmptyState
          icon="staff-rota"
          padding="80px 0"
          text1={
            worklogDayType === WORKLOG_RECORD_DAY_TYPE.IN_BETWEEN_DAY
              ? i18n.t('module:Staff:StaffWorklogPreview:EmptyState:notOngoingInBetweenDay')
              : i18n.t('module:Staff:StaffWorklogPreview:EmptyState:notOngoingLastDay')
          }
        />
      )
    }

    return (
      <Table visualType="gray">
        {renderTableHeader()}
        {renderTableRows()}
      </Table>
    )
  }

  const renderFooter = () => {
    if (!editMode || (isEmpty && !formEntries?.length)) {
      return null
    }

    return (
      <React.Fragment>
        {showCommentField && renderComment()}
        <Form.FooterActions
          isSubmitting={isSubmitting}
          submitLabel={i18n.t('global:Save')}
          onCancelClick={onChangeEditMode}
        />
      </React.Fragment>
    )
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {renderContent()}
      {renderFooter()}
    </Form>
  )
}

const enhance = compose(reduxForm({ form: STAFF_WORKLOG_PREVIEW_FORM }))

export default enhance(StaffWorklogPreviewForm)
