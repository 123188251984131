import * as filesActions from './files/actions'
import * as listActions from './list/actions'
import * as dropdownListActions from './dropdownList/actions'
import * as likeAndCommentsMembersActions from './likeAndCommentsMembers/actions'
import * as singleActions from './single/actions'
import * as stripeIntegrationActions from './stripeIntegration/actions'
import * as rotaStatistics from './rotaStatistics/actions'
import * as publicActions from './public/actions'

export default {
  ...filesActions,
  ...listActions,
  ...dropdownListActions,
  ...likeAndCommentsMembersActions,
  ...singleActions,
  ...stripeIntegrationActions,
  ...rotaStatistics,
  ...publicActions,
}
