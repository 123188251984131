export const GET_ROOM_MOVE = 'GET_ROOM_MOVE'
export const GET_ROOM_MOVE_SUCCESS = 'GET_ROOM_MOVE_SUCCESS'
export const GET_ROOM_MOVE_FAILED = 'GET_ROOM_MOVE_FAILED'

export const CREATE_ROOM_MOVE = 'CREATE_ROOM_MOVE'
export const CREATE_ROOM_MOVE_SUCCESS = 'CREATE_ROOM_MOVE_SUCCESS'
export const CREATE_ROOM_MOVE_FAILED = 'CREATE_ROOM_MOVE_FAILED'

export const UPDATE_ROOM_MOVE = 'UPDATE_ROOM_MOVE'
export const UPDATE_ROOM_MOVE_SUCCESS = 'UPDATE_ROOM_MOVE_SUCCESS'
export const UPDATE_ROOM_MOVE_FAILED = 'UPDATE_ROOM_MOVE_FAILED'

export const DELETE_ROOM_MOVE = 'DELETE_ROOM_MOVE'
export const DELETE_ROOM_MOVE_SUCCESS = 'DELETE_ROOM_MOVE_SUCCESS'
export const DELETE_ROOM_MOVE_FAILED = 'DELETE_ROOM_MOVE_FAILED'

export const CLEAR_ROOM_MOVE = 'CLEAR_ROOM'
