import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as relationsSelectors from './selectors'

const withRelationsService = (WrappedComponent) => {
  const mapState = ({ relations }) => ({ relations })

  const mapDispatch = injectActions('relationsActions', actions)

  const Component = ({ relations, relationsActions, ...others }) => (
    <WrappedComponent
      relationsActions={relationsActions}
      relationsListState={relations.list}
      relationsSelectors={relationsSelectors}
      relationsSingleState={relations.single || {}}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withRelationsService
