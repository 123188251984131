import {
  ADD_FOOD,
  ADD_FOOD_FAILED,
  ADD_FOOD_SUCCESS,
  REMOVE_FOOD,
  REMOVE_FOOD_FAILED,
  REMOVE_FOOD_SUCCESS,
  UPDATE_FOOD,
  UPDATE_FOOD_FAILED,
  UPDATE_FOOD_SUCCESS,
} from './constants'

export const addRecord = (body, onSuccess, onFailed) => ({ dailyDiaryFoodsApiClient }) => (dispatch) => {
  dispatch({ type: ADD_FOOD })

  dailyDiaryFoodsApiClient.addRecord(body).then(
    ({ data }) => {
      dispatch({ payload: data, type: ADD_FOOD_SUCCESS })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => {
      dispatch({ type: ADD_FOOD_FAILED })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const updateRecord = (id, body, onSuccess, onFailed) => ({ dailyDiaryFoodsApiClient }) => (dispatch) => {
  dispatch({ type: UPDATE_FOOD })

  dailyDiaryFoodsApiClient.updateRecord(id, body).then(
    ({ data }) => {
      dispatch({ payload: data, type: UPDATE_FOOD_SUCCESS })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: UPDATE_FOOD_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const removeRecord = (options = {}) => ({ dailyDiaryFoodsApiClient }) => (dispatch) => {
  const { id, onSuccess } = options

  dispatch({ id, type: REMOVE_FOOD })

  dailyDiaryFoodsApiClient.removeRecord(id).then(
    () => {
      dispatch({ id, type: REMOVE_FOOD_SUCCESS })

      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: REMOVE_FOOD_FAILED,
      })
    },
  )
}
