import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withModalService } from 'services/utils/modal'
import { withAppService } from 'services/app'
import { withNurseryExtraItemsService } from 'services/legacy/nurseryExtraItems'
import { withNurseriesService } from 'services/nurseries'
import { withRouter } from 'services/router'

import ManagementExtraItemsAddView from './ManagementExtraItemsAddView'

const NURSERY_EXTRA_ITEMS_GROUPS = {
  read: ['nurseryExtraItem.nursery'],
}

const NURSERY_INVOICE_SETTINGS_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings.invoice',
    'nurseryInvoiceSettings',
  ],
}

class ManagementExtraItemsAddContainer extends Component {
  componentDidMount() {
    const {
      isEdit,
      nurseriesActions,
      nurseryExtraItemsActions,
      nurseryOptions,
      params,
    } = this.props
    const { itemId } = params

    const nurseryApiParams = { groups: NURSERY_INVOICE_SETTINGS_GROUPS }

    nurseriesActions.get(nurseryOptions.id, {
      params: nurseryApiParams,
    })

    if (isEdit) {
      nurseryExtraItemsActions.get(itemId)
    }
  }

  componentWillUnmount() {
    const { nurseriesActions, nurseryExtraItemsActions } = this.props

    nurseriesActions.clearSingle()
    nurseryExtraItemsActions.clear()
  }

  goToExtraItems = () => {
    const { navigate, nurseryOptions } = this.props

    navigate(`${nurseryOptions.prefix}/finance-settings/items`)
  }

  handleSubmitSuccess = () => {
    this.goToExtraItems()
  }

  handleSubmit = (fields) => {
    const { isEdit, nurseryExtraItemsActions, nurseryExtraItemsSelectors, params } = this.props
    const { itemId } = params

    const payload = nurseryExtraItemsSelectors.getPayload(fields)
    const apiParams = { groups: NURSERY_EXTRA_ITEMS_GROUPS }

    if (isEdit) {
      return nurseryExtraItemsActions.update(itemId, payload, apiParams, this.handleSubmitSuccess)
    }

    return nurseryExtraItemsActions.create(payload, apiParams, this.handleSubmitSuccess)
  }

  archiveExtraItem = (archived) => () => {
    const { nurseryExtraItemsActions, nurseryExtraItemsSelectors, params } = this.props
    const { itemId } = params

    const payload = nurseryExtraItemsSelectors.getArchivePayload({ archived })

    return nurseryExtraItemsActions.update(itemId, payload, {}, this.handleSubmitSuccess)
  }

  handleArchiveClick = (archived) => () => {
    const { modalActions, modalConsts } = this.props

    const label = archived ? 'archive' : 'unarchive'
    const text = archived
      ? `Are you sure you want to ${label} this extra item? `
        .concat('Please note: this extra item already allocated to children will not be affected.')
      : 'Are you sure you want to unarchive this extra item ? You can archive it again at any time.'

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: label,
      icon: label,
      onConfirm: this.archiveExtraItem(archived),
      text,
    })
  }

  render() {
    const {
      feeCalculationOptions,
      initialValues,
      isEdit,
      isFetching,
      nurseryExtraItemsSingleState,
      nurseryOptions,
    } = this.props

    const isFormLoading = nurseryExtraItemsSingleState.isSubmitting
    const isArchived = nurseryExtraItemsSingleState.data ? nurseryExtraItemsSingleState.data.archived : null

    return (
      <ManagementExtraItemsAddView
        feeCalculationOptions={feeCalculationOptions}
        initialValues={initialValues}
        isArchived={isArchived}
        isEdit={isEdit}
        isFormLoading={isFormLoading}
        isLoading={isFetching}
        nurseryOptions={nurseryOptions}
        onArchiveClick={this.handleArchiveClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseriesSelectors,
  nurseryExtraItemsSelectors,
  nurseryExtraItemsSingleState,
  params,
}) => ({
  errorMessages: appSelectors.getErrorMessages(nurseryExtraItemsSingleState),
  feeCalculationOptions: nurseryExtraItemsSelectors.getFeeCalculationsOptions(state),
  initialValues: nurseryExtraItemsSelectors.getInitialValues(!!params.itemId)(state),
  isEdit: !!params.itemId,
  isFetching: appSelectors.getIsFetching(nurseryExtraItemsSingleState),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  sessionCalculationWeeksAndDays: nurseriesSelectors.getSessionCalculationWeeksAndDays(state),
})

const enhance = compose(
  withRouter,
  withModalService,
  withAppService,
  withNurseryExtraItemsService,
  withNurseriesService,
  connect(mapState),
)

export default enhance(ManagementExtraItemsAddContainer)
