import _ from 'lodash'

import React from 'react'
import { Link } from 'react-router'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'
import { generateRoute } from 'utils/routing'

import { Currency, DataTable, Typography } from 'components'

import i18n from 'translations'

import { StyledTitle, StyledValue } from './StatisticsTableStyled'

const StatisticsTable = ({
  isDepositEnabled,
  isFinanceV3Enabled,
  nurseries,
  parsedFilters,
  statistics = {},
}) => {
  const renderHeaderPart = (key, gray) => (
    <React.Fragment>
      {_.map(nurseries, ({ id, invoiceStatistics }) => invoiceStatistics && (
        <DataTable.Row gray={gray} key={id} width={130}>
          <Currency value={invoiceStatistics[key]} />
        </DataTable.Row>
      ))}
      <DataTable.Row background="#EEE" width={130}>
        <StyledTitle>
          <Currency value={statistics?.[key]} />
        </StyledTitle>
      </DataTable.Row>
    </React.Fragment>
  )

  const renderFirstPart = (isHeader) => (
    <React.Fragment>
      {isFinanceV3Enabled && (
        <DataTable.Column noBorder>
          <DataTable.Row width={130} gray>
            <StyledTitle>
              {i18n.t('global:Finance:Packages')}
            </StyledTitle>
          </DataTable.Row>
          {!isHeader && renderHeaderPart('totalPackages', isFinanceV3Enabled)}
        </DataTable.Column>
      )}
      <DataTable.Column noBorder={!isFinanceV3Enabled}>
        <DataTable.Row gray={!isFinanceV3Enabled} width={130}>
          <StyledTitle>
            {isFinanceV3Enabled ? i18n.t('global:Finance:RegularSessions') : i18n.t('global:Finance:Sessions')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && renderHeaderPart('totalSessions', !isFinanceV3Enabled)}
      </DataTable.Column>
      <DataTable.Column>
        <DataTable.Row gray={!isFinanceV3Enabled} width={130}>
          <StyledTitle>
            {isFinanceV3Enabled ? i18n.t('global:Finance:RegularConsumables') : i18n.t('global:Finance:Consumables')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && renderHeaderPart('totalConsumables', isFinanceV3Enabled)}
      </DataTable.Column>
      {isFinanceV3Enabled && (
        <DataTable.Column>
          <DataTable.Row background={NEUTRAL_COLOURS.WHITE} width={130}>
            <StyledTitle>
              {i18n.t('global:Finance:RegularItems')}
            </StyledTitle>
          </DataTable.Row>
          {!isHeader && renderHeaderPart('totalRegularItems')}
        </DataTable.Column>
      )}
      <DataTable.Column>
        <DataTable.Row width={130} gray>
          <StyledTitle>
            {i18n.t('global:Finance:ExtraItems')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && renderHeaderPart('totalExtraItems', true)}
      </DataTable.Column>
      <DataTable.Column>
        <DataTable.Row background={NEUTRAL_COLOURS.WHITE} width={130}>
          <StyledTitle>
            {i18n.t('global:Finance:ExtraSessions')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && renderHeaderPart('totalExtraSessions')}
      </DataTable.Column>
      {isFinanceV3Enabled && (
        <DataTable.Column>
          <DataTable.Row width={130} gray>
            <StyledTitle>
              {i18n.t('global:Finance:ExtraConsumables')}
            </StyledTitle>
          </DataTable.Row>
          {!isHeader && renderHeaderPart('totalExtraConsumables', true)}
        </DataTable.Column>
      )}
      <DataTable.Column>
        <DataTable.Row gray={!isFinanceV3Enabled} width={130}>
          <StyledTitle>
            {i18n.t('global:Finance:Other')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && renderHeaderPart('totalOther', !isFinanceV3Enabled)}
      </DataTable.Column>
      <DataTable.Column>
        <DataTable.Row gray={!isFinanceV3Enabled} width={130}>
          <StyledTitle>
            {i18n.t('global:Finance:FundingDeficit')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && renderHeaderPart('totalFundingDeficit', isFinanceV3Enabled)}
      </DataTable.Column>
      <DataTable.Column>
        <DataTable.Row gray={!isFinanceV3Enabled} width={130}>
          <StyledTitle>
            {i18n.t('global:Finance:FundingDeducted')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && renderHeaderPart('totalFundingDeducted', !isFinanceV3Enabled)}
      </DataTable.Column>
      <DataTable.Column>
        <DataTable.Row gray={!isFinanceV3Enabled} width={130}>
          <StyledTitle>
            {i18n.t('global:Finance:Discounts')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && renderHeaderPart('totalDiscount', isFinanceV3Enabled)}
      </DataTable.Column>
      <DataTable.Column noHidden>
        <DataTable.Row gray={!isFinanceV3Enabled} width={130}>
          <StyledTitle>
            {i18n.t('global:Finance:FeeIncome')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && renderHeaderPart('totalInvoices', !isFinanceV3Enabled)}
      </DataTable.Column>
    </React.Fragment>
  )

  const renderSecondPart = (isHeader) => (
    <React.Fragment>
      <DataTable.Column noBorder>
        <DataTable.Row width={130} gray>
          <StyledTitle>
            {i18n.t('global:Finance:FundingIncome')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && renderHeaderPart('totalFundingIncome', true)}
      </DataTable.Column>
      <DataTable.Column>
        <DataTable.Row background={NEUTRAL_COLOURS.WHITE} width={130}>
          <StyledTitle senary>
            {i18n.t('global:Finance:TotalIncome')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && (
          <React.Fragment>
            {_.map(nurseries, ({ id, invoiceStatistics }) => invoiceStatistics && (
              <DataTable.Row key={id} width={130}>
                <StyledValue senary>
                  <Currency value={invoiceStatistics.totalIncome} />
                </StyledValue>
              </DataTable.Row>
            ))}
            <DataTable.Row background="#EEE" width={130}>
              <StyledTitle>
                <Currency value={statistics?.totalIncome} />
              </StyledTitle>
            </DataTable.Row>
          </React.Fragment>
        )}
      </DataTable.Column>
      {isDepositEnabled && (
        <React.Fragment>
          <DataTable.Column>
            <DataTable.Row background={NEUTRAL_COLOURS.WHITE} width={130}>
              <StyledTitle senary>
                {i18n.t('global:Finance:Deposits')}
              </StyledTitle>
            </DataTable.Row>
            {!isHeader && (
              <React.Fragment>
                {_.map(nurseries, ({ id, invoiceStatistics }) => invoiceStatistics && (
                  <DataTable.Row key={id} width={130}>
                    <StyledValue senary>
                      <Currency value={invoiceStatistics.totalDeposits} />
                    </StyledValue>
                  </DataTable.Row>
                ))}
                <DataTable.Row background="#EEE" width={130}>
                  <StyledTitle>
                    <Currency value={statistics?.totalDeposits} />
                  </StyledTitle>
                </DataTable.Row>
              </React.Fragment>
            )}
          </DataTable.Column>
          <DataTable.Column>
            <DataTable.Row background={NEUTRAL_COLOURS.WHITE} width={130}>
              <StyledTitle senary>
                {i18n.t('global:Finance:DepositRefunds')}
              </StyledTitle>
            </DataTable.Row>
            {!isHeader && (
              <React.Fragment>
                {_.map(nurseries, ({ id, invoiceStatistics }) => invoiceStatistics && (
                  <DataTable.Row key={id} width={130}>
                    <StyledValue senary>
                      <Currency value={invoiceStatistics.totalRefundDeposits} />
                    </StyledValue>
                  </DataTable.Row>
                ))}
                <DataTable.Row background="#EEE" width={130}>
                  <StyledTitle>
                    <Currency value={statistics?.totalRefundDeposits} />
                  </StyledTitle>
                </DataTable.Row>
              </React.Fragment>
            )}
          </DataTable.Column>
        </React.Fragment>
      )}
      <DataTable.Column>
        <DataTable.Row width={130} gray>
          <StyledTitle>
            {i18n.t('global:Finance:UnpaidAmount')}
          </StyledTitle>
        </DataTable.Row>
        {!isHeader && (
          <React.Fragment>
            {_.map(nurseries, ({ id, invoiceStatistics }) => invoiceStatistics && (
              <DataTable.Row key={id} width={130} gray>
                <StyledValue red={0 < invoiceStatistics.totalOutstanding}>
                  <Currency value={invoiceStatistics.totalOutstanding} />
                </StyledValue>
              </DataTable.Row>
            ))}
            <DataTable.Row background="#EEE" width={130}>
              <StyledTitle>
                <StyledValue red={0 < statistics?.totalOutstanding}>
                  <Currency value={statistics?.totalOutstanding} />
                </StyledValue>
              </StyledTitle>
            </DataTable.Row>
          </React.Fragment>
        )}
      </DataTable.Column>
    </React.Fragment>
  )

  return (
    <div>
      <DataTable>
        <DataTable.Head borderRight>
          <DataTable.Row stickyElement>
            <StyledTitle>
              {i18n.t('global:Finance:site')}
            </StyledTitle>
          </DataTable.Row>
          {_.map(nurseries, ({ name, publicUrl }) => (
            <DataTable.Row>
              <Link
                target="_blank"
                to={`${publicUrl}${generateRoute('FINANCE.REPORT.DETAILED_REVENUE')}?${parsedFilters}`}
              >
                <Typography color={getBrandingColor('primary-color')} fontSize={14} bold>
                  {name}
                </Typography>
              </Link>
            </DataTable.Row>
          ))}
          <DataTable.Row background="#EEE">
            <strong>
              {i18n.t('global:Finance:Total')}
            </strong>
          </DataTable.Row>
        </DataTable.Head>
        <DataTable.ContentScrollable
          data={[
            {
              data: renderFirstPart(),
              stickyHeader: () => [renderFirstPart(true), (
                <DataTable.Column noHidden>
                  <DataTable.Row width={130} gray>
                    <StyledTitle>
                      {i18n.t('global:Finance:FeeIncome')}
                    </StyledTitle>
                  </DataTable.Row>
                </DataTable.Column>
              )],
            },
            {
              data: renderSecondPart(),
              stickyHeader: () => [renderSecondPart(true)],
            },
          ]}
          enableCustomScrollbar
        />
      </DataTable>
    </div>
  )
}

export default StatisticsTable
