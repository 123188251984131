export const CREATE_NEXT_STEP = 'CREATE_NEXT_STEP'
export const CREATE_NEXT_STEP_SUCCESS = 'CREATE_NEXT_STEP_SUCCESS'
export const CREATE_NEXT_STEP_FAILED = 'CREATE_NEXT_STEP_FAILED'

export const GET_NEXT_STEP = 'GET_NEXT_STEP'
export const GET_NEXT_STEP_SUCCESS = 'GET_NEXT_STEP_SUCCESS'
export const GET_NEXT_STEP_FAILED = 'GET_NEXT_STEP_FAILED'

export const REMOVE_NEXT_STEP = 'REMOVE_NEXT_STEP'
export const REMOVE_NEXT_STEP_SUCCESS = 'REMOVE_NEXT_STEP_SUCCESS'
export const REMOVE_NEXT_STEP_FAILED = 'REMOVE_NEXT_STEP_FAILED'

export const UPDATE_NEXT_STEP = 'UPDATE_NEXT_STEP'
export const UPDATE_NEXT_STEP_SUCCESS = 'UPDATE_NEXT_STEP_SUCCESS'
export const UPDATE_NEXT_STEP_FAILED = 'UPDATE_NEXT_STEP_FAILED'

export const CLEAR_NEXT_STEP = 'CLEAR_NEXT_STEP'
