import React from 'react'

import { generateRoute } from 'utils/routing'

import { Page, Section, Spinner } from 'components'

import i18n from 'translations'

import NurseryDetailsBreakdown from '../components/NurseryDetailsBreakdown'

const NurseriesPreviewView = ({
  featureFlags,
  isAdministrationContext,
  isFetching,
  isOrganizationContext,
  nursery,
  nurseryPublicUrl,
}) => {
  const renderDetails = () => (
    <NurseryDetailsBreakdown
      featureFlags={featureFlags}
      nursery={nursery}
      nurseryPublicUrl={nurseryPublicUrl}
      showFeatureFlags={isAdministrationContext}
      showFrameworks={isAdministrationContext}
      showOpeningTimes={isOrganizationContext}
      showFooter
      showPrimaryContact
    />
  )

  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    return renderDetails()
  }

  const actions = (
    <Section.Actions tertiary={{ to: generateRoute('NURSERIES.EDIT', { nurseryId: nursery?.id }) }} />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isFetching}
      title={i18n.t('module:Nurseries:Preview:title')}
    >
      {renderContent()}
    </Page.Section>
  )
}

export default NurseriesPreviewView
