import React from 'react'

import { Callout, Page, Section, Spinner } from 'components'

import i18n from 'translations'

import ManagementPaymentTypesAddForm from './components/ManagementPaymentTypesAddForm'

const ManagementPaymentTypesAddView = ({
  errorMessages,
  initialValues,
  isArchived,
  isEdit,
  isFormLoading,
  isLoading,
  onArchiveClick,
  onSubmit,
}) => {
  const title = isEdit
    ? i18n.t('module:Management:ManagementPaymentTypes:routeEditTitle')
    : i18n.t('module:Management:ManagementPaymentTypes:routeAddTitle')

  const renderContent = () => {
    if (isLoading || (isEdit && !initialValues)) {
      return <Spinner />
    }

    return (
      <ManagementPaymentTypesAddForm
        initialValues={initialValues}
        isLoading={isFormLoading}
        onSubmit={onSubmit}
      />
    )
  }

  return (
    <Page.Section
      actions={isEdit && !isLoading && (
        <Section.Actions
          options={[{
            onClick: onArchiveClick(!isArchived),
            type: isArchived ? 'unarchive' : 'archive',
          }]}
        />
      )}
      isLoading={isLoading || (isEdit && !initialValues)}
      title={title}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default ManagementPaymentTypesAddView
