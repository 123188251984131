import _ from 'lodash'

import React from 'react'
import SelectLibrary from 'react-select'
import SelectLegacyLibrary from 'react-select-blossom'
import Creatable from 'react-select/creatable'
import { AsyncPaginate, withAsyncPaginate } from 'react-select-async-paginate'

const CreatableAsyncPaginate = withAsyncPaginate(Creatable)

export const Select = ({ creatable, loadOptions, reduceOptions, ...rest }) => {
  const { options } = rest

  if (loadOptions) {
    if (creatable) {
      const params = { ...rest }

      delete params.options

      return (
        <CreatableAsyncPaginate
          debounceTimeout={300}
          loadOptions={loadOptions}
          reduceOptions={reduceOptions}
          {...params}
        />
      )
    }

    return (
      <AsyncPaginate
        debounceTimeout={300}
        loadOptions={loadOptions}
        reduceOptions={reduceOptions}
        {...rest}
      />
    )
  }

  if (creatable) {
    return (
      <Creatable {...rest} />
    )
  }

  if (_.find(options, ({ options: subOptions }) => _.isArray(subOptions))) {
    return (
      <SelectLegacyLibrary {...rest} />
    )
  }

  return (
    <SelectLibrary {...rest} />
  )
}
