import { createSelector } from 'reselect'

export const getNurseryPaymentTypesSelector = (state) => state.nurseryPaymentTypes

export const getNurseryPaymentTypesSingleSelector = createSelector(
  [getNurseryPaymentTypesSelector],
  (state) => state.single.data,
)

export const getInitialValues = createSelector(
  [getNurseryPaymentTypesSingleSelector],
  (paymentType) => {
    if (!paymentType) {
      return null
    }

    const { name } = paymentType || {}

    return { name }
  },
)

export const getIsSubmitting = createSelector(
  [getNurseryPaymentTypesSelector],
  (state) => state.single.isSubmitting,
)

export const isArchived = createSelector(
  [getNurseryPaymentTypesSingleSelector],
  (paymentType) => {
    if (!paymentType) {
      return null
    }

    return paymentType.archived
  },
)
