import _ from 'lodash'

import { createSelector } from 'reselect'
import { formValueSelector } from 'redux-form'

const getBankDetails = (state) => state.childBankDetails

export const getBankDetailsSelector = createSelector(
  [getBankDetails],
  (state) => (state && state.data && state.data.childBankDetail ? state.data.childBankDetail : null),
)

export const isBankDetailsExists = createSelector(
  [getBankDetailsSelector],
  (childBankDetail) => childBankDetail && childBankDetail.id,
)

export const isBankEditingSelector = createSelector([getBankDetailsSelector], (state) => {
  if (!state) {
    return false
  }

  return !!state.accountName || !!state.accountNumber || !!state.notes || !!state.sortCode || !!state.bankName
})

export const isNotesEditingSelector = createSelector([getBankDetailsSelector], (state) => state && state.id)

export const isContactEditedSelector = createSelector([getBankDetailsSelector], (state) => {
  if (!state) {
    return false
  }

  return !!state.invoiceRecipients || !!state.paymentContact || !!state.paymentEmail
})

export const getContactDetailsSelector = createSelector([getBankDetailsSelector], (bankDetails) => {
  if (!bankDetails) {
    return false
  }

  const { fundingLoopContact, invoiceRecipients, isCustomEmail, paymentContact, paymentEmail } = bankDetails

  return { fundingLoopContact, invoiceRecipients, isCustomEmail, paymentContact, paymentEmail }
})

export const isChildPaymentContactExists = createSelector(
  [isBankEditingSelector, isContactEditedSelector],
  (isBankEdited, isContactEdited) => isBankEdited || isContactEdited,
)

export const getContactPayload = (fields) => {
  if (!fields) {
    return null
  }

  const { carerId, fundingLoopContact, invoiceRecipients, isCustomEmail, paymentEmail } = fields

  const selectedRecipients = _.filter(invoiceRecipients, ({ carer }) => carer && carer.value !== carerId.value)

  return {
    carerId: carerId?.value,
    fundingLoopContactId: fundingLoopContact?.value || null,
    invoiceRecipients: _.map(selectedRecipients, (item) => item.carer.value),
    paymentEmail: isCustomEmail ? paymentEmail : null,
  }
}

const formSelector = (formName) => formValueSelector(formName)

const carerIdSelectors = (formName) => (state) => formSelector(formName)(state, 'carerId')

const invoiceRecipientsSelector = (formName) => (state) => (
  formSelector(formName)(state, 'invoiceRecipients')
)

export const getSelectedAdditionalCount = (formName) => createSelector(
  [carerIdSelectors(formName), invoiceRecipientsSelector(formName)],
  (carerId, invoiceRecipients) => {
    if (!invoiceRecipients || !invoiceRecipients.length) {
      return null
    }

    const filteredRecipients = (
      carerId
        ? _.filter(invoiceRecipients, (item) => carerId !== item.carer.id)
        : invoiceRecipients
    )

    return _.filter(filteredRecipients, (item) => item.checked).length
  },
)

export const getChildBankNotes = createSelector(
  [getBankDetailsSelector],
  (childBankDetail) => childBankDetail && childBankDetail.notes,
)
