import React from 'react'

import { Avatar, DefinitionList, ModalBox, Typography } from 'components'

import i18n from 'translations'

import { StyledContainerMoveFrom } from './EditRoomMoveModalStyled'
import EditRoomMoveForm from './EditRoomMoveForm'

const EditRoomMoveModalView = ({
  StaffingEnabled,
  childMove,
  initialValues,
  isProcessing,
  onChangeMoveTo,
  onCloseClick,
  onSubmit,
  roomsWithLeaving,
  selectedMoveTo,
  showWarning,
}) => {
  const { child, fromNurseryClass } = childMove
  const { ageMonths, firstName, photo, surname } = child

  return (
    <ModalBox
      title={i18n.t('module:Modals:EditRoomMoveModal:title')}
      autoHeight
      onCloseClick={onCloseClick}
    >
      <div>
        <Avatar
          avatarSize="medium"
          initials={[firstName, surname]}
          src={photo}
          subTitle={i18n.t('global:ageMonths', { ageMonths })}
          title={(
            <Typography fontSize={14} primary>
              {`${firstName} ${surname}`}
            </Typography>
          )}
        />
        {fromNurseryClass && (
          <StyledContainerMoveFrom>
            <DefinitionList>
              <DefinitionList.Item
                label={i18n.t('module:Modals:EditRoomMoveModal:MoveFrom:label')}
                value={fromNurseryClass.name}
              />
            </DefinitionList>
          </StyledContainerMoveFrom>
        )}
      </div>
      <EditRoomMoveForm
        StaffingEnabled={StaffingEnabled}
        childMove={childMove}
        initialValues={initialValues}
        isProcessing={isProcessing}
        roomsWithLeaving={roomsWithLeaving}
        selectedMoveTo={selectedMoveTo}
        showWarning={showWarning}
        onChangeMoveTo={onChangeMoveTo}
        onCloseClick={onCloseClick}
        onSubmit={onSubmit}
      />
    </ModalBox>
  )
}

export default EditRoomMoveModalView
