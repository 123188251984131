import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withRegisterService } from 'services/legacy/register'

import TodayAllergiesWidgetView from './TodayAllergiesWidgetView'

class TodayAllergiesWidgetContainer extends Component {
  componentDidMount() {
    this.getAllergyChildrenList()
  }

  componentWillUnmount() {
    const { registerActions } = this.props

    registerActions.clear()
  }

  getAllergyChildrenList = () => {
    const { registerActions, registerSelectors } = this.props

    const criteria = registerSelectors.getRegisterChildrenCriteria({
      allergy: 1,
      entryDate: moment().format('YYYY-MM-DD'),
      present: 1,
    })

    registerActions.list({
      criteria,
      includes: ['legacyInformation'],
      limit: 0,
    })
  }

  render() {
    const { children, isFetching } = this.props

    return (
      <TodayAllergiesWidgetView
        allergyChildren={children}
        isLoading={isFetching}
      />
    )
  }
}

const mapState = (state, { appSelectors, registerSelectors, registerState }) => ({
  children: registerSelectors.getRegisterDataSelector(state),
  isFetching: appSelectors.getIsFetching(registerState),
})

const enhance = compose(
  withAppService,
  withRegisterService,
  connect(mapState),
)

export default enhance(TodayAllergiesWidgetContainer)
