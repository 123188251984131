import * as badDebtSelectors from './badDebt/selectors'
import * as badDebtStatisticsSelectors from './badDebtStatistics/selectors'
import * as batchSelectors from './batch/selectors'
import * as listSelectors from './list/selectors'
import * as previewSelectors from './preview/selectors'
import * as singleSelectors from './single/selectors'
import * as statisticsSelectors from './statistics/selectors'

export default {
  ...badDebtSelectors,
  ...badDebtStatisticsSelectors,
  ...batchSelectors,
  ...listSelectors,
  ...previewSelectors,
  ...singleSelectors,
  ...statisticsSelectors,
}
