import { generateBasicActions } from 'utils/service'

import {
  CLEAR_CHILD_NOTE,
  CREATE_CHILD_NOTE,
  CREATE_CHILD_NOTE_FAILED,
  CREATE_CHILD_NOTE_SUCCESS,
  GET_CHILD_NOTE,
  GET_CHILD_NOTE_FAILED,
  GET_CHILD_NOTE_SUCCESS,
  REMOVE_CHILD_NOTE,
  REMOVE_CHILD_NOTE_FAILED,
  REMOVE_CHILD_NOTE_SUCCESS,
  UPDATE_CHILD_NOTE,
  UPDATE_CHILD_NOTE_FAILED,
  UPDATE_CHILD_NOTE_SUCCESS,
} from './constants'

export const get = (options = {}) => ({ childNotesApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: GET_CHILD_NOTE_FAILED,
      init: GET_CHILD_NOTE,
      success: GET_CHILD_NOTE_SUCCESS,
    },
    options,
    service: childNotesApiClient,
    serviceMethod: 'get',
  })
)

export const create = (options = {}) => ({ childNotesApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: CREATE_CHILD_NOTE_FAILED,
      init: CREATE_CHILD_NOTE,
      success: CREATE_CHILD_NOTE_SUCCESS,
    },
    options,
    service: childNotesApiClient,
    serviceMethod: 'create',
  })
)

export const update = (options = {}) => ({ childNotesApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_CHILD_NOTE_FAILED,
      init: UPDATE_CHILD_NOTE,
      success: UPDATE_CHILD_NOTE_SUCCESS,
    },
    options,
    service: childNotesApiClient,
    serviceMethod: 'update',
  })
)

export const remove = (options = {}) => ({ childNotesApiClient }) => (
  generateBasicActions.remove({
    constants: {
      failed: REMOVE_CHILD_NOTE_FAILED,
      init: REMOVE_CHILD_NOTE,
      success: REMOVE_CHILD_NOTE_SUCCESS,
    },
    options,
    service: childNotesApiClient,
    serviceMethod: 'remove',
  })
)

export const clear = () => ({
  type: CLEAR_CHILD_NOTE,
})
