import i18n from 'translations'

import { ADMIN_LIST_COLUMNS, ORGANIZATION_LIST_COLUMNS, USERS_LIST_COLUMNS } from './constants'

export const getActionLabel = (isAdministrationContext, isOrganizationContext) => {
  if (isAdministrationContext) {
    return i18n.t('module:Staff:StaffLists:addAdministrator')
  }

  if (isOrganizationContext) {
    return i18n.t('module:Staff:StaffLists:addOrganizationStaff')
  }

  return i18n.t('module:Staff:StaffLists:addStaffMember')
}

export const getTableColumns = (isAdministrationContext, isOrganizationContext) => {
  if (isAdministrationContext) {
    return ADMIN_LIST_COLUMNS
  }

  if (isOrganizationContext) {
    return ORGANIZATION_LIST_COLUMNS
  }

  return USERS_LIST_COLUMNS
}
