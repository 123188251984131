import React from 'react'

import { generateRoute } from 'utils/routing'

import { Page, ReportView, Section, Typography } from 'components'

import i18n from 'translations'

const OccupancyDashboardView = () => {
  const items = [
    {
      description: i18n.t('module:Occupancy:Dashboard:Report:description'),
      title: i18n.t('module:Occupancy:Dashboard:Report:title'),
      to: generateRoute('OCCUPANCY.GENERATOR'),
    },
    {
      description: i18n.t('module:Occupancy:Dashboard:OccupancyPlanner:description'),
      title: i18n.t('module:Occupancy:Dashboard:OccupancyPlanner:title'),
      to: `${generateRoute('OCCUPANCY.GENERATOR')}?overview=true`,
    },
  ]

  return (
    <Page>
      <Page.Content>
        <Section title="Occupancy">
          <Typography margin="20px 0">
            {i18n.t('module:Occupancy:Dashboard:subtitle')}
          </Typography>
          <ReportView
            items={items}
          />
        </Section>
      </Page.Content>
    </Page>
  )
}

export default OccupancyDashboardView
