import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledLoadingDot = styled.span`
  display: block;
`

export const StyledContainer = styled.span`
  & ${StyledLoadingDot} {
    background: ${getBrandingColor('primary-color')};
    display: inline-block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    margin-right: 2px;
    animation: wave 1.3s linear infinite;

    &:nth-child(1) {
      margin-left: 3px;
    }

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -.9s;
    }
  }

  @keyframes wave {
    0%, 60%, 100% {
      transform: initial;
    }

    30% {
      transform: translateY(-7px);
    }
  }
`
