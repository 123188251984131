import moment from 'moment'

import React, { PropsWithChildren } from 'react'

import { DateString } from 'components'

import i18n from 'translations'

import {
  StyledLastEdited,
  StyledLeftDetails,
  StyledLeftDetailsSubtitle,
  StyledLeftDetailsTitle,
  StyledSubtitle,
} from './ProfileHeaderStyled'

interface ProfileHeaderDetailsLeftProps {
  lastEdited: moment.Moment | number
  subtitle: string
  title: string
}

const ProfileHeaderDetailsLeft: React.FC<PropsWithChildren<ProfileHeaderDetailsLeftProps>> = ({
  lastEdited,
  subtitle,
  title,
}) => (
  <StyledLeftDetails>
    <StyledLeftDetailsTitle>
      {title}
    </StyledLeftDetailsTitle>
    <StyledLeftDetailsSubtitle>
      <StyledSubtitle>
        {subtitle}
      </StyledSubtitle>
      {lastEdited && (
        <StyledLastEdited>
          {`${i18n.t('global:lastEdited')}: `}
          <DateString
            date={lastEdited}
            relative
          />
        </StyledLastEdited>
      )}
    </StyledLeftDetailsSubtitle>
  </StyledLeftDetails>
)

export default ProfileHeaderDetailsLeft
