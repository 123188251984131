import styled from 'styled-components'

export const StyledContent = styled.div`
  width: 260px;
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 10px;
  text-align: center;
  
  @media(max-width: 600px) {
    width: auto;
  }
`
