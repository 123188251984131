import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledGridContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledRow = styled.div`
  border: 1px solid ${NEUTRAL_COLOURS.WHITE};
  flex: 1;
  padding: 10px 14px;
  align-items: flex-start;
  display: flex; 
  
  & > div {
    width: 100%;
  }
  
  ${({ minHeight }) => minHeight && `
    min-height: ${minHeight}px;
  `}
    
  ${({ paddingTop }) => paddingTop && `
    padding-top: ${paddingTop}px;
  `}
`

export const StyledColumn = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 4fr 5fr 5fr;
  
  ${({ isColumnName }) => !isColumnName && `
    grid-template-columns: 1fr 1fr;
  `}
  
  ${StyledRow}:nth-child(2n + 1) {
    background: #F6F6F6;
  }
  
  &:nth-of-type(1) {
    ${StyledRow} {
      border-left: none;
    }
  }
  
  &:nth-last-of-type(1) {
    ${StyledRow} {
      border-right: none;
    }
  }
  
  ${StyledRow}:nth-of-type(1) {
    border-top-width: 2px;
  }
`

export const StyledTitle = styled.strong`
  display: block;
  padding-bottom: 5px;
`

export const StyledColumnsWrapper = styled.div`
  display: grid;  
  grid-column-gap: 20px;
  grid-template-columns: 1fr 1fr;
  
  ${({ oneColumns }) => oneColumns && `
    grid-template-columns: 1fr;
  `}
  
  @media(max-width: 880px) {
    grid-template-columns: 1fr;
  }
`

export const StyledAreaTabs = styled.div`
  font-size: 14px;
`

export const StyledPreviewWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
`

export const StyledPreviewAreaWrapper = styled.div`
  margin-bottom: 15px;
`

export const StyledPreviewArea = styled.div`
  font-size: 19px;
  font-weight: 600;
  padding: 15px 20px;
  border-left: 10px solid ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  
  ${({ color }) => color && `
    border-left-color: ${color};
  `}
`

export const StyledPreviewGroups = styled.div`
  margin: 20px 0 30px 30px;
`

export const StyledCompletedStatementContainer = styled.div`
  padding: 20px 20px 0;
`

export const StyledItemTitle = styled.strong`
  color: #A0A0A0;
`

export const StyledItem = styled.div`
  margin-top: 10px;
`

export const StyledItemContainer = styled.div`
  margin-bottom: 25px;  
`

export const StyledTabTitle = styled.div`
  max-height: 36px;
`
