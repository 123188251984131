import styled from 'styled-components'

export const StyledColumnContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
  
  @media(max-width: 720px) {
    grid-template-columns: 1fr;
    grid-column: 1;
  }
`

export const StyledFormRow = styled.div`
  display: grid;
  grid-template-columns: 260px auto;
  grid-column-gap: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  
  @media(max-width: 480px) {
    grid-template-columns: 180px auto;
    
    .Select__control {
      min-width: 180px;
    }
  }
`

export const StyledTitle = styled.div`
  font-weight: 600;
  margin-bottom: 10px;
`

export const StyledActions = styled.div`
  margin-top: 35px;
  margin-bottom: -20px;
`

export const StyledRemoveButton = styled.div`
  margin-top: 5px;
`
