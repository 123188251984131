import styled from 'styled-components'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

interface StyledChildrenSliderProps {
  $marginBottom?: boolean
}

export const StyledChildrenSlider = styled.div<StyledChildrenSliderProps>`
  background: #F7F7F7;

  .slick-track {
    margin-left: inherit;
    margin-right: inherit;
  }

  .slick-arrow {
    display: none !important;
  }

  ${({ $marginBottom }) => $marginBottom && `
    margin-bottom: 25px;
  `}
`

interface StyledSlideProps {
  $isSelected?: boolean
}

export const StyledSlide = styled.div<StyledSlideProps>`
  display: flex;
  text-align: center;
  flex-direction: column;
  padding: 20px 10px;
  height: 100%;
  width: 100%;

  ${({ onClick }) => onClick && `
    cursor: pointer;
  `}

  ${({ $isSelected }) => $isSelected && `
    background: ${getBrandingColor('primary-color')};
    color: ${NEUTRAL_COLOURS.WHITE};
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      background: ${getBrandingColor('primary-color')};
      height: 100%;
    }
  `}
  
  * {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export const StyledIconContainer = styled.div`
  margin-bottom: 4px;
  margin-top: -8px;
`

export const StyledCanBePhotographed = styled.div`
  margin-top: 10px;
  position: relative;
  display: flex;
  padding: 5px;
  justify-content: center;
`
