import { generateBasicActions } from 'utils/service'

import {
  CLEAR_ABSENCE_REASON,
  CREATE_ABSENCE_REASON,
  CREATE_ABSENCE_REASON_FAILED,
  CREATE_ABSENCE_REASON_SUCCESS,
  GET_ABSENCE_REASON,
  GET_ABSENCE_REASON_FAILED,
  GET_ABSENCE_REASON_SUCCESS,
  REMOVE_ABSENCE_REASON,
  REMOVE_ABSENCE_REASON_FAILED,
  REMOVE_ABSENCE_REASON_SUCCESS,
  UPDATE_ABSENCE_REASON,
  UPDATE_ABSENCE_REASON_FAILED,
  UPDATE_ABSENCE_REASON_SUCCESS,
} from './constants'

export const get = (options = {}) => ({ childAbsencesApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_ABSENCE_REASON_FAILED,
    init: GET_ABSENCE_REASON,
    success: GET_ABSENCE_REASON_SUCCESS,
  },
  options,
  service: childAbsencesApiClient,
  serviceMethod: 'get',
})

export const create = (options = {}) => ({ childAbsencesApiClient }) => generateBasicActions.create({
  constants: {
    failed: CREATE_ABSENCE_REASON_FAILED,
    init: CREATE_ABSENCE_REASON,
    success: CREATE_ABSENCE_REASON_SUCCESS,
  },
  options,
  service: childAbsencesApiClient,
  serviceMethod: 'create',
})

export const update = (options = {}) => ({ childAbsencesApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_ABSENCE_REASON_FAILED,
    init: UPDATE_ABSENCE_REASON,
    success: UPDATE_ABSENCE_REASON_SUCCESS,
  },
  options,
  service: childAbsencesApiClient,
  serviceMethod: 'update',
})

export const remove = (options = {}) => ({ childAbsencesApiClient }) => generateBasicActions.remove({
  constants: {
    failed: REMOVE_ABSENCE_REASON_FAILED,
    init: REMOVE_ABSENCE_REASON,
    success: REMOVE_ABSENCE_REASON_SUCCESS,
  },
  options,
  service: childAbsencesApiClient,
  serviceMethod: 'remove',
})

export const clear = () => ({
  type: CLEAR_ABSENCE_REASON,
})
