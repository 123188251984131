import moment from 'moment'

import React from 'react'

import { Label, TimePicker } from 'components'

import { StyledLabelContainer, StyledTimePickerWrapper } from './RegisterItemTimeFieldStyled'

const RegisterItemTimeField = ({ color, label, onChange, value }) => (
  <div>
    <StyledLabelContainer>
      <Label color={color} text={label} />
    </StyledLabelContainer>
    <StyledTimePickerWrapper>
      <TimePicker value={moment(value)} onChange={onChange} />
    </StyledTimePickerWrapper>
  </div>
)

export default RegisterItemTimeField
