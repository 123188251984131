import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withRelationsService } from 'services/legacy/relations'

import { Select } from 'components'

import i18n from 'translations'

import withInfiniteDropdownHelpers from './withInfiniteDropdown'

class RelationsDropdown extends Component {
  componentWillUnmount() {
    const { relationsActions } = this.props

    relationsActions.clear()
  }

  handleLoadMoreElements = async (phrase) => {
    const {
      infiniteDropdownHelpers,
      limit,
      relationsActions,
      relationsSelectors,
    } = this.props

    return infiniteDropdownHelpers.handleLoadMoreElements({
      clearAction: relationsActions.clear,
      listAction: ({ newPage, newSearchPhrase, result }) => relationsActions.dropdownList({
        onSuccess: (response) => {
          const { data, meta } = response

          const newData = _.map(data, (item) => ({
            ...item,
            id: item.code,
          }))

          const newResponse = {
            data: newData,
            meta,
          }

          return infiniteDropdownHelpers.handleLoadMoreElementsOnSuccess({
            newPage,
            newSearchPhrase,
            response: newResponse,
            result,
          })
        },
        onlyData: true,
        params: {
          criteria: relationsSelectors.getListCriteria({ search: phrase }),
          limit,
          order: {
            sortField: 'name',
            sortOrder: 'asc',
          },
          page: newPage,
        },
      }),
      phrase,
    })
  }

  handleChange = (e) => {
    const { infiniteDropdownHelpers, onChange } = this.props

    infiniteDropdownHelpers.handleOnChange(e, () => onChange(e))
  }

  render() {
    const {
      clearable,
      disabled,
      error,
      infiniteDropdownState,
      multi,
      placeholder,
      rawValue,
      value,
      ...rest
    } = this.props
    const { cacheUniq, options, selectedValue } = infiniteDropdownState

    return (
      <Select
        {...rest}
        cacheUniq={cacheUniq}
        clearable={clearable}
        disabled={disabled}
        error={error}
        loadOptions={this.handleLoadMoreElements}
        multi={multi}
        options={options}
        placeholder={placeholder || i18n.t('module:Children:Child:Contacts:Form:chooseContact')}
        rawValue={rawValue || selectedValue || value}
        value={value}
        searchable
        onChange={this.handleChange}
      />
    )
  }
}

const mapState = (state, { appSelectors, relationsListState }) => ({
  isFetching: appSelectors.getIsFetching(relationsListState),
})

const enhance = compose(
  withAppService,
  withRelationsService,
  withInfiniteDropdownHelpers,
  connect(mapState),
)

export default enhance(RelationsDropdown)
