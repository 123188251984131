import React from 'react'

import { ModalBox, Spinner } from 'components'

import i18n from 'translations'

import AddInvoiceItemModalV3Form, { AddInvoiceItemModalV3FormValues } from './components/AddInvoiceItemModalV3Form'

export interface LineItemOptionProps {
  label: string
  nonCustomType?: string[]
  order: number
  previewType: string
  showCost: boolean
  value: string
}

interface AddInvoiceItemModalV3ViewProps {
  childId?: string
  includeUsedDepositIds?: number[]
  initialValues?: AddInvoiceItemModalV3FormValues
  isItemEdit?: boolean
  isReadOnly?: boolean
  lineItemOptions?: LineItemOptionProps[]
  onCancelClick?: () => void
  onSubmit?: (values: AddInvoiceItemModalV3FormValues) => void
  skipPendingDepositIds?: number[]
}

const AddInvoiceItemModalV3View = ({
  childId,
  includeUsedDepositIds,
  initialValues,
  isItemEdit,
  isReadOnly,
  lineItemOptions,
  onCancelClick,
  onSubmit,
  skipPendingDepositIds,
}: AddInvoiceItemModalV3ViewProps): JSX.Element => {
  const title = isItemEdit
    ? i18n.t('module:Modals:Invoice:AddInvoiceItemModal:editTitle')
    : i18n.t('module:Modals:Invoice:AddInvoiceItemModal:addTitle')

  const renderContent = () => {
    if (!initialValues) {
      return <Spinner />
    }

    return (
      <AddInvoiceItemModalV3Form
        childId={childId}
        includeUsedDepositIds={includeUsedDepositIds}
        initialValues={initialValues}
        isItemEdit={isItemEdit}
        isReadOnly={isReadOnly}
        lineItemOptions={lineItemOptions}
        skipPendingDepositIds={skipPendingDepositIds}
        onCancelClick={onCancelClick}
        onSubmit={onSubmit}
      />
    )
  }

  return (
    <ModalBox
      scrollToBody={false}
      title={title}
      iosPolyfill
      onCloseClick={onCancelClick}
    >
      {renderContent()}
    </ModalBox>
  )
}

export default AddInvoiceItemModalV3View
