import React from 'react'

import { Chart } from 'components'

const FinanceReportFundingChart = ({ chart }) => {
  if (!chart) {
    return null
  }

  const { data, subtitle, title } = chart

  return (
    <Chart.Pie
      chartSeries={{ data }}
      data={data}
      enableArcLabels={false}
      legendValueField="amount"
      subtitle={subtitle}
      title={title}
    />
  )
}

export default FinanceReportFundingChart
