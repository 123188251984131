import { findDomain } from 'services/subdomain/single/selectors'
import CookieIdentityService from 'core/services/CookieIdentityService'

import { properties } from 'app-config'

import {
  CLEAR_PIN_LOGIN,
  CREATE_PIN,
  CREATE_PIN_FAILED,
  CREATE_PIN_SUCCESS,
  PIN_LOGIN,
  PIN_LOGIN_FAILED,
  PIN_LOGIN_SUCCESS,
} from './constants'

export const createPin = (payload, token, options = {}) => ({ authApiClient }) => (dispatch) => {
  const { onFailed, onSuccess } = options

  dispatch({
    type: CREATE_PIN,
  })

  authApiClient.createPin(payload, token).then(
    (response) => {
      dispatch({
        payload: response,
        type: CREATE_PIN_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: CREATE_PIN_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const pinLogin = (payload, options = {}) => ({ authApiClient }) => async (dispatch) => {
  const { onFailed, onSuccess } = options

  dispatch({
    payload,
    type: PIN_LOGIN,
  })

  try {
    const loginResponse = await authApiClient.pinLogin(payload)

    const { data } = loginResponse || {}
    const { token } = data || {}

    const profile = await authApiClient.me(token, { groups: { read: [
      'membership.details',
      'nursery',
      'membership.nursery',
      'nursery.organization',
      'organization.details',
      'membership.user',
    ] } })

    CookieIdentityService.setIdentity({ token }, {
      domain: findDomain(),
      key: properties.pinIdentityKey,
    })

    const response = { profile: profile?.data, token }

    dispatch({
      payload: response,
      type: PIN_LOGIN_SUCCESS,
    })

    if (onSuccess) {
      onSuccess(response)
    }
  } catch ({ response }) {
    dispatch({
      error: response,
      type: PIN_LOGIN_FAILED,
    })

    if (onFailed) {
      onFailed(response)
    }
  }
}

export const clearPinLogin = () => ({
  type: CLEAR_PIN_LOGIN,
})
