import React from 'react'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { FLAG_COLOURS } from 'constants/colors'

import { isRequired } from 'utils/fieldValidation'

import {
  ALLERGY_OPTIONS,
  ALLERGY_TYPES,
  DIETARY_NO_PREFERENCE,
  DIETARY_OTHER,
  DIETARY_REQUIREMENTS_OPTIONS,
} from 'services/legacy/child/constants'

import { Callout, Form, Section } from 'components'

import i18n from 'translations'

import { CHILD_ADD_FORM } from './constants'
import ChildFormFooter from './ChildFormFooter'

const TRANS_PREFIX = 'module:Children:Child:Add:HealthAndDiet'

const HealthAndDietInformationForm = ({
  allergies,
  childId,
  dietaryReq,
  handleSubmit,
  isChildArchived,
  isEditMode,
  isSubmitting,
  onArchiveClick,
  onPreviousStageClick,
  permissionToArchive,
}) => {
  const renderForm = () => {
    const renderHealth = () => (
      <Section title={i18n.t(`${TRANS_PREFIX}:Subsections:Health:title`)}>
        <Form.Row label>
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              label={i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:CanReceiveMedicine:label`)}
              name="information.canReceiveMedicine"
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row
          label={i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:MedicalInformation:label`)}
        >
          <Form.Row.FlexItem mobileFull>
            <Field
              component={Form.TextAreaField}
              name="profile.medicalInformation"
              placeholder={i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:MedicalInformation:placeholder`)}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row label>
          <Form.Row.FlexItem>
            <Callout
              color={FLAG_COLOURS.INFO}
              content={(
                <React.Fragment>
                  {i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:MedicalInformationInfo:title`)}
                  <ul type="i">
                    <li>
                      {i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:MedicalInformationInfo:message1`)}
                    </li>
                    <li>
                      {i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:MedicalInformationInfo:message2`)}
                    </li>
                    <li>
                      {i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:MedicalInformationInfo:message3`)}
                    </li>
                    <li>
                      {i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:MedicalInformationInfo:message4`)}
                    </li>
                    <li>
                      {i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:MedicalInformationInfo:message5`)}
                    </li>
                  </ul>
                </React.Fragment>
              )}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row
          label={i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:Allergies:label`)}
        >
          <Form.Row.Item>
            <Field
              component={Form.Select}
              name="information.allergies"
              options={ALLERGY_OPTIONS}
              placeholder={i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:Allergies:placeholder`)}
            />
          </Form.Row.Item>
        </Form.Row>
        {ALLERGY_TYPES.HAS_ALLERGY === allergies?.value && (
          <Form.Row
            label={i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:AllergiesDetails:label`)}
            required
          >
            <Form.Row.FlexItem mobileFull>
              <Field
                component={Form.TextAreaField}
                name="information.allergiesDetails"
                placeholder={i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:AllergiesDetails:placeholder`)}
                validate={isRequired}
              />
            </Form.Row.FlexItem>
          </Form.Row>
        )}
        <Form.Row autoHeight label>
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              label={i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:CanConsumeFoodWithNutTraces:label`)}
              name="information.canConsumeFoodWithNutTraces"
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row autoHeight label>
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              label={i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:Patches:label`)}
              name="information.patches"
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row autoHeight label>
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              label={i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:SunCream:label`)}
              name="information.sunCream"
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row autoHeight label>
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              label={i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:NappyCream:label`)}
              name="information.nappyCream"
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row label>
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              label={i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:FacePaint:label`)}
              name="information.facePaint"
            />
          </Form.Row.Item>
        </Form.Row>
      </Section>
    )

    const renderDiet = () => (
      <Section title={i18n.t(`${TRANS_PREFIX}:Subsections:Diet:title`)}>
        <Form.Row
          label={i18n.t(`${TRANS_PREFIX}:Subsections:Diet:Form:DietaryPreference:label`)}
          required
        >
          <Form.Row.Item>
            <Field
              component={Form.Select}
              name="information.dietaryReq"
              options={DIETARY_REQUIREMENTS_OPTIONS}
              placeholder={i18n.t(`${TRANS_PREFIX}:Subsections:Diet:Form:DietaryPreference:placeholder`)}
              validate={isRequired}
            />
          </Form.Row.Item>
        </Form.Row>
        {DIETARY_NO_PREFERENCE !== dietaryReq?.value && (
          <Form.Row
            label={i18n.t(`${TRANS_PREFIX}:Subsections:Diet:Form:AdditionalNotes:label`)}
            required={DIETARY_OTHER === dietaryReq?.value}
          >
            <Form.Row.FlexItem mobileFull>
              <Field
                component={Form.TextAreaField}
                name="information.dietaryReqDesc"
                placeholder={i18n.t(`${TRANS_PREFIX}:Subsections:Diet:Form:AdditionalNotes:placeholder`)}
                validate={DIETARY_OTHER === dietaryReq?.value && isRequired}
              />
            </Form.Row.FlexItem>
          </Form.Row>
        )}
        <Form.Row
          label={i18n.t(`${TRANS_PREFIX}:Subsections:Diet:Form:FussyEater:label`)}
        >
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              name="profile.isFussyEater"
            />
          </Form.Row.Item>
        </Form.Row>
      </Section>
    )

    return (
      <React.Fragment>
        {renderHealth()}
        {renderDiet()}
      </React.Fragment>
    )
  }

  const renderFooter = () => (
    <ChildFormFooter
      childId={childId}
      isChildArchived={isChildArchived}
      isEditMode={isEditMode}
      isSubmitting={isSubmitting}
      permissionToArchive={permissionToArchive}
      onArchiveClick={onArchiveClick}
      onPreviousStageClick={onPreviousStageClick}
    />
  )

  return (
    <Form onSubmit={handleSubmit}>
      {renderForm()}
      {renderFooter()}
    </Form>
  )
}

const mapState = (state) => ({
  allergies: formValueSelector(CHILD_ADD_FORM)(state, 'information.allergies'),
  dietaryReq: formValueSelector(CHILD_ADD_FORM)(state, 'information.dietaryReq'),
})

const enhance = compose(
  reduxForm({ destroyOnUnmount: false, form: CHILD_ADD_FORM }),
  connect(mapState),
)

export default enhance(HealthAndDietInformationForm)
