import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { Button, Currency, EmptyState, Section, Space, Spinner, StickyFooter, Table, Typography } from 'components'

import i18n from 'translations'

import CreditNoteInvoicesForm from './components/CreditNoteInvoicesForm'

const TRANS_PREFIX = 'module:Children:Child:BookingPattern:AutoCreditNoting:CreditNoteInvoices'

const CreditNoteInvoicesView = ({
  childId,
  childInvoices,
  hasOnlyDraftInvoices,
  initialValues,
  isLoading,
  isSubmitting,
  onContinueClick,
  onInvoiceItemClick,
  onSubmit,
}) => {
  if (isLoading) {
    return <Spinner />
  }

  const renderRow = ({
    displayName,
    endDate,
    id: invoiceId,
    isDraft,
    issueDate,
    number,
    startDate,
    total,
  }) => (
    <Table.Tr>
      <Table.Td align="left">
        <Typography>
          <Typography
            cursor="pointer"
            margin="0 0 4px"
            underline
            onClick={() => onInvoiceItemClick(childId, invoiceId)}
          >
            {isDraft && `(${_.upperFirst(i18n.t('global:draft'))}) `}
            {displayName}
          </Typography>
          <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
            {moment(startDate).format(DISPLAY_SHORT_DATE_FORMAT)}
            {' - '}
            {moment(endDate).format(DISPLAY_SHORT_DATE_FORMAT)}
          </Typography>
        </Typography>
      </Table.Td>
      <Table.Td align="left">
        {number || '-'}
      </Table.Td>
      <Table.Td align="left">
        {moment(issueDate).format(DISPLAY_SHORT_DATE_FORMAT)}
      </Table.Td>
      <Table.Td align="left">
        <Currency value={total} />
      </Table.Td>
    </Table.Tr>
  )

  const renderContent = () => {
    if (!childInvoices?.length) {
      return (
        <EmptyState
          icon="invoices"
          iconSize={80}
          text1={i18n.t(`${TRANS_PREFIX}:emptyStateText`)}
        />
      )
    }

    return (
      <React.Fragment>
        <Typography>
          {i18n.t(`${TRANS_PREFIX}:subtitle`)}
        </Typography>
        <Space space="10px" />
        <Table visualType="transparent">
          <Table.Tr>
            <Table.Th align="left">
              {i18n.t(`${TRANS_PREFIX}:Table:Header:invoice`)}
            </Table.Th>
            <Table.Th align="left">
              {i18n.t(`${TRANS_PREFIX}:Table:Header:invoiceNumber`)}
            </Table.Th>
            <Table.Th align="left">
              {i18n.t(`${TRANS_PREFIX}:Table:Header:invoiceDate`)}
            </Table.Th>
            <Table.Th align="left">
              {i18n.t(`${TRANS_PREFIX}:Table:Header:invoiceTotal`)}
            </Table.Th>
            <Table.Th />
          </Table.Tr>
          {_.map(childInvoices, renderRow)}
        </Table>
        <CreditNoteInvoicesForm
          hasOnlyDraftInvoices={hasOnlyDraftInvoices}
          initialValues={initialValues}
          onSubmit={onSubmit}
        />
      </React.Fragment>
    )
  }

  const renderFooter = () => (
    <StickyFooter>
      <Button
        hierarchy="tertiary"
        label={i18n.t('global:Cancel')}
        to={generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS', { childId })}
        negativeMargins
      />
      <StickyFooter.Flex />
      <Button
        isLoading={isSubmitting}
        label={i18n.t('global:continue')}
        negativeMargins
        onClick={onContinueClick}
      />
    </StickyFooter>
  )

  return (
    <Section title={i18n.t(`${TRANS_PREFIX}:title`)}>
      {renderContent()}
      {renderFooter()}
    </Section>
  )
}

export default CreditNoteInvoicesView
