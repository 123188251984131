import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { ROLES } from 'constants/security'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'

import ManagementNurseryView from './ManagementNurseryView'

const groups = {
  read: [
    'nursery.primaryContact',
    'membership.details',
    'membership.primaryManager',
    'nursery.details',
    'nursery.organization',
    'nursery.settings',
    'nurserySettings.localeDetails',
    'nurserySettings',
    'organization',
  ],
}

class ManagementNurseryContainer extends Component {
  componentDidMount() {
    const { nurseriesActions, nurseryOptions } = this.props

    nurseriesActions.get(nurseryOptions.id, {
      params: { groups },
    })
  }

  componentWillUnmount() {
    const { nurseriesActions } = this.props

    nurseriesActions.clearSingle()
  }

  render() {
    const { errorMessages, isFetching, nurseryData } = this.props

    const isLoading = isFetching || !nurseryData

    return (
      <ManagementNurseryView
        errorMessages={errorMessages}
        isLoading={isLoading}
        nursery={nurseryData}
      />
    )
  }
}

ManagementNurseryContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

const mapState = (state, { appSelectors, nurseriesSelectors, nurseriesSingleState, params }) => ({
  errorMessages: appSelectors.getErrorMessages(nurseriesSingleState),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState),
  nurseryData: nurseriesSelectors.getNurseryData(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const enhance = compose(
  withAppService,
  withNurseriesService,
  connect(mapState),
)

export default enhance(ManagementNurseryContainer)
