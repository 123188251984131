import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_RELATION,
  CREATE_RELATION,
  CREATE_RELATION_FAILED,
  CREATE_RELATION_SUCCESS,
  GET_RELATION,
  GET_RELATION_FAILED,
  GET_RELATION_SUCCESS,
  REMOVE_RELATION,
  REMOVE_RELATION_FAILED,
  REMOVE_RELATION_SUCCESS,
  UPDATE_RELATION,
  UPDATE_RELATION_FAILED,
  UPDATE_RELATION_SUCCESS,
} from './constants'

export default (...props) => {
  const [state = getBasicSingleInitialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_RELATION,
    createFailed: CREATE_RELATION_FAILED,
    createInit: CREATE_RELATION,
    createSuccess: CREATE_RELATION_SUCCESS,
    itemFailed: GET_RELATION_FAILED,
    itemInit: GET_RELATION,
    itemSuccess: GET_RELATION_SUCCESS,
    removeFailed: REMOVE_RELATION_FAILED,
    removeInit: REMOVE_RELATION,
    removeSuccess: REMOVE_RELATION_SUCCESS,
    updateFailed: UPDATE_RELATION_FAILED,
    updateInit: UPDATE_RELATION,
    updateSuccess: UPDATE_RELATION_SUCCESS,
  }, getBasicSingleInitialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
