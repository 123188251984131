import ServiceBase from 'services/ServiceBase'

class ChildNotesApiClient extends ServiceBase {
  list = (childId) => this.request({
    path: `/children/${childId}/notes`,
  })

  get = (childId, noteId) => this.request({
    path: `/children/${childId}/notes/${noteId}`,
  })

  create = (childId, body) => this.request({
    body,
    method: 'POST',
    path: `/children/${childId}/notes`,
  })

  update = (childId, noteId, body) => this.request({
    body,
    method: 'PATCH',
    path: `/children/${childId}/notes/${noteId}`,
  })

  remove = (childId, noteId) => this.request({
    method: 'DELETE',
    path: `/children/${childId}/notes/${noteId}`,
  })
}

export default ChildNotesApiClient
