import moment from 'moment'

import React, { useMemo } from 'react'

import { Callout, Currency, DefinitionList, GridLayout, ModalBox, Spinner } from 'components'

import IssueCreditNoteModalForm from './components/IssueCreditNoteModalForm'

const IssueCreditNoteModalView = ({
  creditNoteIsFetching,
  creditNoteNumber,
  errorMessages,
  invoice,
  onCloseClick,
  onSubmit,
}) => {
  const { issueDate: invoiceIssueDate, name, total } = invoice

  const issueDate = useMemo(
    () => (moment().valueOf() < moment(invoiceIssueDate).valueOf() ? moment(invoiceIssueDate) : moment()),
    [invoiceIssueDate],
  )

  const renderContent = () => (
    <div>
      <Callout content={errorMessages} error />
      <GridLayout>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item>
            <DefinitionList margin="0 0 10px 0" vertical>
              <DefinitionList.Item label="Invoice" value={name} />
              <DefinitionList.Item label="Invoice total" value={<Currency value={total} />} />
            </DefinitionList>
          </GridLayout.Item>
          <GridLayout.Item>
            <DefinitionList margin="0 0 10px 0" vertical>
              <DefinitionList.Item label="Credit note number" value={creditNoteNumber} />
            </DefinitionList>
          </GridLayout.Item>
        </GridLayout.Group>
      </GridLayout>
      <IssueCreditNoteModalForm
        creditNoteIsFetching={creditNoteIsFetching}
        initialValues={{ issueDate }}
        invoice={invoice}
        onCloseClick={onCloseClick}
        onSubmit={onSubmit}
      />
    </div>
  )

  return (
    <ModalBox
      title="Issue credit note"
      autoHeight
      onCloseClick={onCloseClick}
    >
      {
        !creditNoteNumber
          ? (
            <Spinner />
          )
          : renderContent()
      }
    </ModalBox>
  )
}

export default IssueCreditNoteModalView
