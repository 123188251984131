export const LIST_CHILD_BULK_FUNDING = 'LIST_CHILD_BULK_FUNDING'
export const LIST_CHILD_BULK_FUNDING_SUCCESS = 'LIST_CHILD_BULK_FUNDING_SUCCESS'
export const LIST_CHILD_BULK_FUNDING_FAILED = 'LIST_CHILD_BULK_FUNDING_FAILED'

export const CREATE_CHILD_BULK_FUNDING = 'CREATE_CHILD_BULK_FUNDING'
export const CREATE_CHILD_BULK_FUNDING_SUCCESS = 'CREATE_CHILD_BULK_FUNDING_SUCCESS'
export const CREATE_CHILD_BULK_FUNDING_FAILED = 'CREATE_CHILD_BULK_FUNDING_FAILED'

export const UPDATE_CHILD_BULK_FUNDING = 'UPDATE_CHILD_BULK_FUNDING'
export const UPDATE_CHILD_BULK_FUNDING_SUCCESS = 'UPDATE_CHILD_BULK_FUNDING_SUCCESS'
export const UPDATE_CHILD_BULK_FUNDING_FAILED = 'UPDATE_CHILD_BULK_FUNDING_FAILED'

export const CLEAR_CHILD_BULK_FUNDING = 'CLEAR_CHILD_BULK_FUNDING'
