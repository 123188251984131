import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Callout, ExternalPage, Icon, Spinner, Typography } from 'components'

import i18n from 'translations'

import { StyledButton, StyledContentWrapper, StyledCopyWrapper, StyledWrapper } from './EnquiryPublicFormSuccessStyled'

const EnquiryPublicFormSuccessView = ({
  enquiryPublicUrl,
  errorMessages,
  isLoading,
  subdomainData,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <StyledContentWrapper>
        <Icon
          color={NEUTRAL_COLOURS.SUCCESS}
          height={64}
          icon="circle-tick-secondary"
          width={64}
        />
        <StyledCopyWrapper>
          <Typography
            fontSize={24}
            margin="16px 0 0"
            bold
          >
            {i18n.t('module:EnquiryPublicFormSuccess:title')}
          </Typography>
          <Typography margin="16px 0 8px">{i18n.t('module:EnquiryPublicFormSuccess:subtitleFirstLine')}</Typography>
          <Typography>{i18n.t('module:EnquiryPublicFormSuccess:subtitleSecondLine')}</Typography>
          {enquiryPublicUrl && (
            <StyledButton
              label={i18n.t('global:returnToPreviousWebsite')}
              target="_self"
              to={enquiryPublicUrl}
              primary
            />
          )}
        </StyledCopyWrapper>
      </StyledContentWrapper>
    )
  }

  return (
    <StyledWrapper>
      <ExternalPage.NurseryHeader subdomainData={subdomainData} />
      <Callout content={errorMessages} error />
      {renderContent()}
      <ExternalPage.PoweredByBlossomFooter />
    </StyledWrapper>
  )
}

export default EnquiryPublicFormSuccessView
