import _ from 'lodash'

import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'
import { reset } from 'redux-form'

import { COMMENT_TYPE } from 'services/comments/constants'
import { Comment } from 'services/comments/models'

import { typeByObject } from 'utils/typescript'

import { withLikesAndCommentsService, withLikesAndCommentsServiceProps } from 'services/likesAndComments'
import { withCommentsService, withCommentsServiceProps } from 'services/comments'
import { withSecurityService, withSecurityServiceProps } from 'services/security'
import { withPaginationUtils, withPaginationUtilsProps } from 'services/utils/pagination'

import { Banner, Button, EmptyState, Space, Spinner, Typography } from 'components'

import i18n from 'translations'

import CommentsForm, { COMMENTS_FORM, CommentsFormValues } from './components/CommentsForm/CommentsForm'
import CommentItem from './components/CommentItem'
import { StyledButtonWrapper, StyledWrapper } from './CommentsStyled'

const COMMENTS_GROUPS = {
  read: [
    'like',
    'author',
    'comment',
    'comment.counter',
    'comment.userLike',
    'comment.author',
    'counter',
  ],
}

interface CommentsProps {
  commentType: typeByObject<typeof COMMENT_TYPE>
  gray?: boolean
  objectId: number
  onAddedNewComment: () => void
  wrapper?: (children: React.ReactNode) => any
}

const mapState = (state, { likesAndCommentsSelectors, securitySelectors }) => ({
  authUser: securitySelectors.getAuthUser(state),
  hasAccessToLikesAndComments: likesAndCommentsSelectors.hasAccessToLikesAndComments(state),
  hasAccessToLikesAndCommentsSettings: likesAndCommentsSelectors.hasAccessToLikesAndCommentsSettings(state),
})

const mapDispatch = {
  resetForm: (objectId) => reset(`${COMMENTS_FORM}_${objectId}`),
}

const PAGE_LIMIT = 5

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type CommentsFullProps = CommentsProps
  & withCommentsServiceProps
  & withLikesAndCommentsServiceProps
  & withPaginationUtilsProps
  & withSecurityServiceProps
  & PropsFromRedux

const Comments: React.FC<CommentsFullProps> = ({
  authUser,
  commentType,
  commentsActions,
  gray,
  hasAccessToLikesAndComments,
  hasAccessToLikesAndCommentsSettings,
  objectId,
  onAddedNewComment,
  paginationUtils,
  resetForm,
  wrapper,
}) => {
  const [totalResults, setTotalResults] = useState<number>(0)
  const [comments, setComments] = useState<Comment[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const { getPageCount, onPageChange, page, setPageLocationQuery, setPerPage } = paginationUtils
  const pageCount = getPageCount(totalResults)

  useEffect(() => {
    setPageLocationQuery(false)
    setPerPage(PAGE_LIMIT)
  }, [])

  const getCommentsSuccess = (newPage: number) => (response) => {
    const { data, meta } = response

    setTotalResults(meta.total_results)
    setIsFetching(false)
    setComments([
      ...(1 === newPage ? [] : comments),
      ..._.filter(data, ({ type }) => commentType === type),
    ])
  }

  const getCommentsLists = (newPage) => {
    const criteria = [
      {
        field: 'objectId',
        value: objectId,
      },
      {
        field: 'type',
        value: commentType,
      },
    ]

    commentsActions.list({
      onSuccess: getCommentsSuccess(newPage),
      onlyData: true,
      params: {
        criteria,
        groups: COMMENTS_GROUPS,
        limit: PAGE_LIMIT,
        page: newPage,
      },
    })
  }

  const handlePageChange = (newPage) => {
    setIsFetching(true)
    onPageChange()(newPage)
    getCommentsLists(newPage)
  }

  useEffect(() => {
    if (!isInitialized && hasAccessToLikesAndComments) {
      setIsInitialized(true)
      getCommentsLists(1)
    }
  }, [hasAccessToLikesAndComments, isInitialized, totalResults])

  const onCreateCommentSuccess = (response) => {
    const { data } = response

    resetForm(objectId)
    setIsSubmitting(false)
    setComments([
      data,
      ...comments,
    ])

    onAddedNewComment?.()
  }

  const onCreateNewComment = (values: CommentsFormValues) => {
    setIsSubmitting(true)

    const body = {
      comment: values.comment?.trim(),
      objectId,
      type: commentType,
    } as any

    commentsActions.create({
      body,
      onSuccess: onCreateCommentSuccess,
      params: {
        groups: COMMENTS_GROUPS,
      },
    })
  }

  const renderComments = () => {
    if (isFetching && 1 === page) {
      return (
        <Spinner />
      )
    }

    if (!isFetching && !comments.length) {
      return (
        <EmptyState
          icon="comment"
          text1={i18n.t('module:LikesAndComments:notFound')}
        />
      )
    }

    return (
      <React.Fragment>
        {_.map(comments, (comment) => (
          <CommentItem
            hasAccessToLikesAndCommentsSettings={hasAccessToLikesAndCommentsSettings}
            item={comment}
            key={comment.id}
          />
        ))}
        {page < pageCount && (
          <StyledButtonWrapper>
            <Button
              hierarchy="tertiary"
              isLoading={isFetching}
              label={i18n.t('global:showMore')}
              onClick={() => handlePageChange((+page) + 1)}
            />
          </StyledButtonWrapper>
        )}
      </React.Fragment>
    )
  }

  const renderBody = (
    <StyledWrapper $gray={gray}>
      <Typography fontSize={20} bold>
        {i18n.t('module:LikesAndComments:comments')}
      </Typography>
      {hasAccessToLikesAndCommentsSettings && (
        <CommentsForm
          authUser={authUser}
          form={`${COMMENTS_FORM}_${objectId}`}
          isSubmitting={isSubmitting}
          onSubmit={onCreateNewComment}
        />
      )}
      {!hasAccessToLikesAndCommentsSettings && (
        <React.Fragment>
          <Space space="20px" />
          <Banner.Warning>
            {i18n.t('module:LikesAndComments:featureDeactivated')}
          </Banner.Warning>
          <Space space="20px" />
        </React.Fragment>
      )}
      {renderComments()}
    </StyledWrapper>
  )

  if (!hasAccessToLikesAndComments || (!hasAccessToLikesAndCommentsSettings && !totalResults)) {
    return null
  }

  if (wrapper) {
    return wrapper(renderBody)
  }

  return renderBody
}

const enhance = compose(
  withCommentsService,
  withLikesAndCommentsService,
  withPaginationUtils,
  withSecurityService,
  connector,
)

export default enhance(Comments)
