import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class CreditNotesApiClient extends ServiceBase {
  list = (params) => this.request({
    path: `/v2/credit_notes/statistics/credit_note?${getQueryStringV2(params)}`,
  })

  get = (id, params) => this.request({
    path: `/v2/credit_notes/${id}?${getQueryStringV2(params)}`,
  })

  create = (payload) => this.request({
    body: payload,
    method: 'POST',
    path: '/v2/credit_notes',
  })

  createBulk = (payload, params) => this.request({
    body: payload,
    method: 'POST',
    path: `/v2/bulk/credit_notes?${getQueryStringV2(params)}`,
  })

  send = (id) => this.request({
    method: 'POST',
    path: `/v2/credit_notes/${id}/send`,
  })

  download = (id) => this.request({
    download: true,
    method: 'GET',
    path: `/v2/credit_notes/${id}/download`,
  })

  statistics = (params) => this.request({
    path: `/v2/statistics/credit_note?${getQueryStringV2(params)}`,
  })
}

export default CreditNotesApiClient
