import React from 'react'
import { WrappedFieldProps } from 'redux-form'

import { FieldError } from 'components'

type FormErrorFullProps = WrappedFieldProps

const FormError: React.FC<FormErrorFullProps> = ({
  meta: { error, submitFailed },
}) => (
  <FieldError
    error={submitFailed && error}
  />
)

export default FormError
