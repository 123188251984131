import { generateBasicActions } from 'utils/service'

import {
  CLEAR_FINANCE_REPORTS_NURSERIES_DEPOSITS,
  FINANCE_REPORTS_NURSERIES_DEPOSITS,
  FINANCE_REPORTS_NURSERIES_DEPOSITS_FAILED,
  FINANCE_REPORTS_NURSERIES_DEPOSITS_SUCCESS,
} from './constants'

export const getNurseries = (options = {}) => ({ financeDepositsReportApiClient }) => generateBasicActions.list({
  constants: {
    failed: FINANCE_REPORTS_NURSERIES_DEPOSITS_FAILED,
    init: FINANCE_REPORTS_NURSERIES_DEPOSITS,
    success: FINANCE_REPORTS_NURSERIES_DEPOSITS_SUCCESS,
  },
  options,
  service: financeDepositsReportApiClient,
  serviceMethod: 'getNurseries',
})

export const clearNurseries = () => ({
  type: CLEAR_FINANCE_REPORTS_NURSERIES_DEPOSITS,
})
