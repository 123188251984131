import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledDefault = styled.div`
  border-radius: 12px;
  padding: 3px 15px;
  margin-right: 5px;
  font-size: 14px;
  display: inline-block;
  text-align: left;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis; 
  white-space: nowrap;
  background-color: ${NEUTRAL_COLOURS.GRAY_SECONDARY};
  margin-left: 15px;
`

export const StyledSpinnerWrapper = styled.div`
  margin-left: 15px;

  & > div {
    width: fit-content;
  }
`

export const StyledName = styled.div`
  display: flex;
  align-items: center;
`
