import React, { PropsWithChildren } from 'react'

import FieldTopLabel from './FieldTopLabel'
import InlineEdit from './InlineEdit'
import InlineEditActions from './InlineEditActions'

interface FieldRoot extends React.FC<PropsWithChildren<any>> {
  InlineEdit?: typeof InlineEdit
  InlineEditActions?: typeof InlineEditActions
  TopLabel?: typeof FieldTopLabel
}

const Field: FieldRoot = ({ children }) => children as any

export default Field
