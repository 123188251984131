import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import { EditChildNoteFormValues } from 'services/childNotes/models'

import { isRequired } from 'utils/fieldValidation'

import { Button, FooterActions, Form, Space } from 'components'

import i18n from 'translations'
import { generateRoute } from 'utils/routing'

interface EditChildNoteFormProps {
  childId: number
  isEditContext: boolean
  isSubmitting?: boolean
  onSubmit: (values: EditChildNoteFormValues) => any
}

const EDIT_CHILD_NOTE_FORM = 'EditChildNoteForm'

type EditChildNoteFormFullProps = InjectedFormProps<{}, EditChildNoteFormProps> & EditChildNoteFormProps

const EditChildNoteForm: React.FC<EditChildNoteFormFullProps> = ({
  childId,
  handleSubmit,
  isEditContext,
  isSubmitting,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Space space="20px" />
    <Form.Row
      label={i18n.t('module:Children:Child:Notes:Edit:title')}
      margin="0 0 10px"
      width={{ field: '400px' }}
    >
      <Field
        component={Form.TextField}
        disabled={isSubmitting}
        name="title"
        placeholder={i18n.t('module:Children:Child:Notes:Edit:title')}
        validate={isRequired}
      />
    </Form.Row>
    <Form.Row
      label={i18n.t('global:Date')}
      margin="0 0 10px"
      required
    >
      <Field
        component={Form.DatePicker}
        disabled={isSubmitting}
        name="date"
        validate={isRequired}
      />
    </Form.Row>
    <Form.Row
      label={i18n.t('module:Children:Child:Notes:Edit:enterNotesHere')}
      margin="0 0 10px"
      width={{ field: '600px' }}
      required
    >
      <Field
        component={Form.TextAreaField}
        disabled={isSubmitting}
        name="content"
        placeholder={i18n.t('module:Children:Child:Notes:Edit:enterNotesHere')}
        validate={isRequired}
        autosize
      />
    </Form.Row>
    <FooterActions spaceBetween>
      <FooterActions.Group>
        <FooterActions.Item>
          <Button
            hierarchy="tertiary"
            isLoading={isSubmitting}
            label={i18n.t('global:Cancel')}
            to={generateRoute('CHILDREN.CHILD.NOTES', { childId })}
          />
        </FooterActions.Item>
      </FooterActions.Group>
      <FooterActions.Group>
        <FooterActions.Item>
          <Button
            isLoading={isSubmitting}
            label={isEditContext
              ? i18n.t('module:Children:Child:Notes:Edit:titleEdit')
              : i18n.t('module:Children:Child:Notes:Edit:titleAdd')}
            submit
          />
        </FooterActions.Item>
      </FooterActions.Group>
    </FooterActions>
  </Form>
)

export default reduxForm<{}, EditChildNoteFormProps>({ form: EDIT_CHILD_NOTE_FORM })(EditChildNoteForm)
