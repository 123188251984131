import { getBasicListReducers, getBasicSingleInitialState } from 'utils/reducer'

import {
  CLEAR_AGE_BANDS,
  GET_FRAMEWORK_AGE_BANDS,
  GET_FRAMEWORK_AGE_BANDS_FAILED,
  GET_FRAMEWORK_AGE_BANDS_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_AGE_BANDS,
    failed: GET_FRAMEWORK_AGE_BANDS_FAILED,
    init: GET_FRAMEWORK_AGE_BANDS,
    success: GET_FRAMEWORK_AGE_BANDS_SUCCESS,
  }, initialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
