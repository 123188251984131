import _ from 'lodash'
import i18n from 'i18next'
import markdownJsx from 'i18next-markdown-jsx-plugin'

import React from 'react'
import { Trans, initReactI18next } from 'react-i18next'

// eslint-disable-next-line import/extensions
import en from './en'

i18n.use(initReactI18next)
  .use(markdownJsx)
  .init({
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
    react: {
      transSupportBasicHtmlNodes: false,
    },
    resources: {
      en,
    },
  })

const clonedi18n = _.cloneDeep(i18n) as any

clonedi18n.t = (key, options, ...props): any => {
  const { components, ...rest } = options || {}

  if (components) {
    return (
      <Trans
        components={components}
        i18nKey={key}
        values={rest}
      />
    ) as any
  }

  return i18n.t(key, options, ...props) as any
}

export default clonedi18n
