import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledItem = styled.div`
  margin-bottom: 25px;  
`

export const StyledHeader = styled.div`
  border-bottom: 1px dashed ${NEUTRAL_COLOURS.GRAY_SECONDARY};
  padding: 15px 0;
  margin-bottom: 15px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr auto auto;
`

export const StyledContent = styled.div`
  margin-bottom: 25px;
  display: grid;
  grid-row-gap: 10px;  
`

export const StyledField = styled.div`
  display: grid;
  grid-template-columns: 300px auto;
  grid-column-gap: 25px;
  margin-bottom: 15px;
  align-items: center;

  @media(max-width: 750px) {
    grid-template-columns: auto;
    
    &:not(:last-child) {
      margin-bottom: 35px;
    }
    
    & > p {
      font-weight: 600;
    }
  }
`

export const StyledRightColumn = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-gap: 15px;
    
  @media(max-width: 750px) {
    margin-top: 10px;
    grid-column-gap: 5px;
  }
`

export const StyledValue = styled.div`
  padding: 4px 8px;
  background: rgba(238, 251, 238, .7);
  border-radius: 4px;
  display: table;
  font-size: 14px;
  height: min-content;
  
  ${({ old }) => old && `
    background: rgba(251, 225, 226, .5);
    text-decoration: line-through;
  `}
`
