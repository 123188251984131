import moment from 'moment'
import _ from 'lodash'

import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { NONE_VALUE, OBSERVATION_STATES } from 'services/legacy/frameworks/constants'

import { isRequired } from 'utils/fieldValidation'

import { Button, FooterActions, Form } from 'components'
import ProgressLabel from 'module/Learning/Observations/components/ProgressLabel'

import i18n from 'translations'

import {
  StyledLastProgress,
  StyledRadioGroupWrapper,
  StyledRadioWrapper,
  StyledWrapper,
} from './FrameworkSelectProgressModalFormStyled'

export const FRAMEWORK_SELECT_PROGRESS_FORM = 'FrameworkSelectProgressForm'

const FrameworkSelectProgressModalForm = ({
  handleSubmit,
  isFrameworkMontessori,
  isSubmitting,
  lastProgress,
  noDescription,
  onCloseClick,
  onSubmit,
  progressLevels,
  renderProgressHistory,
  renderStatementInfo,
}) => {
  let options = [
    {
      name: i18n.t('module:Modals:FrameworkSelectProgressModal:none'),
      value: NONE_VALUE,
    },
    ..._.map(OBSERVATION_STATES, (item) => item),
  ]

  if (!isFrameworkMontessori) {
    options = _.map(progressLevels, (item) => ({
      ...item,
      value: item.id,
    }))
  }

  const renderRadioItem = (checkbox, item) => {
    if (isFrameworkMontessori) {
      return (
        <StyledRadioWrapper key={item.id} withLastProgress={lastProgress?.state === item.value}>
          <div>
            <ProgressLabel fontSize={16} value={item} />
          </div>
          {lastProgress?.state === item.value && (
            <StyledLastProgress>
              {i18n.t('module:Modals:FrameworkSelectProgressModal:lastProgress')}
            </StyledLastProgress>
          )}
          {checkbox}
        </StyledRadioWrapper>
      )
    }

    return (
      <StyledRadioWrapper key={item.id} withLastProgress={lastProgress?.progressLevel?.id === item.id}>
        <div>
          <ProgressLabel fontSize={16} value={item} />
        </div>
        {lastProgress?.progressLevel?.id === item.id && (
          <StyledLastProgress>
            {i18n.t('module:Modals:FrameworkSelectProgressModal:lastProgress')}
          </StyledLastProgress>
        )}
        {checkbox}
      </StyledRadioWrapper>
    )
  }

  const renderBody = () => (
    <StyledRadioGroupWrapper>
      <Form.Row verticalLabel>
        <Form.Row.FlexItem flex="1" mobileFull>
          <Field
            component={Form.RadioGroup}
            name="state"
            options={options}
            renderItem={renderRadioItem}
          />
        </Form.Row.FlexItem>
      </Form.Row>
    </StyledRadioGroupWrapper>
  )

  const renderFooter = () => (
    <FooterActions spaceBetween>
      <FooterActions.Group>
        <FooterActions.Item>
          <Button
            disabled={isSubmitting}
            hierarchy="tertiary"
            label={i18n.t('global:cancel')}
            negativeMargins
            onClick={onCloseClick}
          />
        </FooterActions.Item>
      </FooterActions.Group>
      <FooterActions.Group>
        <FooterActions.Item>
          <Field
            component={Form.DatePicker}
            disabledDays={[{
              after: moment().toDate(),
            }]}
            name="date"
            validate={isRequired}
          />
        </FooterActions.Item>
        <FooterActions.Item>
          <Button
            isLoading={isSubmitting}
            label={i18n.t('global:Save')}
            negativeMargins
            submit
          />
        </FooterActions.Item>
      </FooterActions.Group>
    </FooterActions>
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <StyledWrapper noDescription={noDescription}>
        {renderStatementInfo()}
        {renderBody()}
        {renderProgressHistory()}
        {renderFooter()}
      </StyledWrapper>
    </Form>
  )
}

export default reduxForm({ form: FRAMEWORK_SELECT_PROGRESS_FORM })(FrameworkSelectProgressModalForm)
