import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as selectors from './selectors'

const withChildFrameworkProgressService = (WrappedComponent) => {
  const mapState = ({ childFrameworkProgress }) => ({ childFrameworkProgress })

  const mapDispatch = injectActions('childFrameworkProgressActions', actions)

  const Component = ({ childFrameworkProgress, childFrameworkProgressActions, ...other }) => (
    <WrappedComponent
      childFrameworkProgressActions={childFrameworkProgressActions}
      childFrameworkProgressHistoryMontessoriState={childFrameworkProgress.historyMontessori}
      childFrameworkProgressHistoryState={childFrameworkProgress.history}
      childFrameworkProgressListMontessoriState={childFrameworkProgress.listMontessori}
      childFrameworkProgressListState={childFrameworkProgress.list}
      childFrameworkProgressSelectors={selectors}
      {...other}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withChildFrameworkProgressService
