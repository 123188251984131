import _ from 'lodash'
import { flatten } from 'utils/flatnest'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { Currency, ReportView } from 'components'

import i18n from 'translations'

import { NURSERY_TABLE_COLUMNS } from './constants'

export const getColumns = (isOrganizationContext, data) => {
  if (isOrganizationContext) {
    return [
      {
        align: 'left',
        alignTh: 'left',
        field: 'name',
        title: _.upperFirst(i18n.t('global:site')),
      },
      ..._.map(data?.[0]?.results, ({ label }, index) => ({
        field: `field-${index}`,
        title: label,
      })),
    ]
  }

  return NURSERY_TABLE_COLUMNS
}

const getNurseryLink = (nursery, appProperties, queryString) => (
  <ReportView.NurseryLink
    nursery={nursery}
    queryString={queryString}
    routeKey="FINANCE.REPORT.REVENUE_PER_AGE"
  />
)

export const getTableData = ({ appProperties, data, filters, hasError, isOrganizationContext }) => {
  if (hasError) {
    return null
  }

  if (!isOrganizationContext) {
    if (!data) {
      return null
    }

    return _.map(data.results, ({ label, total }) => ({
      ageGroup: label,
      revenue: <Currency value={total} />,
    }))
  }

  if (!data?.length) {
    return null
  }

  const queryString = new URLSearchParams(flatten({
    date: filters?.date ? {
      after: filters.date.after.format(DEFAULT_DATE_FORMAT),
      before: filters.date.before.format(DEFAULT_DATE_FORMAT),
    } : undefined,
  })).toString()

  return _.map(data, ({
    resource,
    results,
  }) => ({
    name: getNurseryLink(resource, appProperties, queryString),
    ..._.reduce(results, (result, item, index) => ({
      ...result,
      [`field-${index}`]: item.total,
    }), {}),
  }))
}

export const getStatistics = ({ data, hasError, isOrganizationContext }) => {
  if (!data || !isOrganizationContext || hasError) {
    return null
  }

  return _.map(data.results, ({ label, total }) => ({
    label,
    value: <Currency value={total} />,
  }))
}
