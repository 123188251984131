import React from 'react'

import { FLAG_COLOURS } from 'constants/colors'

import { Callout } from '../index'

const BannerError = ({ children, ...rest }) => (
  <Callout
    {...rest}
    color={FLAG_COLOURS.ERROR}
    content={children}
    icon="warning"
    iconVariant="circle"
  />
)

export default BannerError
