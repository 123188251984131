import { Nullable } from 'constants/models'
import { Nursery } from 'services/nurseries/models'

import { BasicListInitialState, BasicSingleInitialState } from 'utils/reducer'

import i18n from 'translations'

export const CLOSURE_DAY_TYPE_FILTER = {
  ACTIVE: 'ACTIVE',
  ALL: 'ALL',
  ARCHIVED: 'ARCHIVED',
}

export const CLOSURE_DAY_TYPE_FILTER_OPTIONS = [
  { label: i18n.t('global:Active'), value: CLOSURE_DAY_TYPE_FILTER.ACTIVE },
  { label: i18n.t('global:All'), value: CLOSURE_DAY_TYPE_FILTER.ALL },
  { label: i18n.t('global:Archived'), value: CLOSURE_DAY_TYPE_FILTER.ARCHIVED },
]

export enum ChargeForEnum {
  EXTRA_ITEM = 'extra_item',
  EXTRA_SESSION = 'extra_session',
  EXTRA_SESSION_CONSUMABLE = 'extra_session_consumable',
  FUNDING_DEFICIT = 'funding_deficit',
  ONE_OFF_DISCOUNT = 'one_off_discount',
  REGULAR_DISCOUNT = 'regular_discount',
  REGULAR_ITEMS = 'regular_items',
  REGULAR_SESSION = 'regular_session',
  REGULAR_SESSION_CONSUMABLE = 'regular_session_consumable'
}

export const ChargeForLabel = {
  [ChargeForEnum.EXTRA_ITEM]: i18n.t('services:ClosureDayTypes:ChargeFor.extraItems'),
  [ChargeForEnum.EXTRA_SESSION]: i18n.t('services:ClosureDayTypes:ChargeFor.extraSession'),
  [ChargeForEnum.EXTRA_SESSION_CONSUMABLE]: i18n.t('services:ClosureDayTypes:ChargeFor.extraSessionConsumable'),
  [ChargeForEnum.FUNDING_DEFICIT]: i18n.t('services:ClosureDayTypes:ChargeFor.fundingDeficit'),
  [ChargeForEnum.ONE_OFF_DISCOUNT]: i18n.t('services:ClosureDayTypes:ChargeFor.oneOfDiscount'),
  [ChargeForEnum.REGULAR_DISCOUNT]: i18n.t('services:ClosureDayTypes:ChargeFor.regularDiscount'),
  [ChargeForEnum.REGULAR_ITEMS]: i18n.t('services:ClosureDayTypes:ChargeFor.regularItems'),
  [ChargeForEnum.REGULAR_SESSION]: i18n.t('services:ClosureDayTypes:ChargeFor.regularSession'),
  [ChargeForEnum.REGULAR_SESSION_CONSUMABLE]: i18n.t('services:ClosureDayTypes:ChargeFor.regularConsumables'),
}

export interface ClosureDayType {
  archived?: boolean
  archivedAt?: Date
  chargeFor?: `${ChargeForEnum}`[]
  id?: number
  name?: string
  nursery?: Nursery
}

export interface ClosureDayTypesListState extends BasicListInitialState {
  data: Nullable<ClosureDayType[]>
}

export interface ClosureDayTypesSingleState extends BasicSingleInitialState {
  data: ClosureDayType
}

export interface ClosureDayTypesState {
  list: ClosureDayTypesListState
  single: ClosureDayTypesSingleState
}

export interface ClosureDayTypesRootState {
  closureDayTypes: ClosureDayTypesState
}
