import { generateBasicActions } from 'utils/service'

import {
  CLEAR_FOOD_TYPE,
  CREATE_FOOD_TYPE,
  CREATE_FOOD_TYPE_FAILED,
  CREATE_FOOD_TYPE_SUCCESS,
  GET_FOOD_TYPE,
  GET_FOOD_TYPE_FAILED,
  GET_FOOD_TYPE_SUCCESS,
  UPDATE_FOOD_TYPE,
  UPDATE_FOOD_TYPE_FAILED,
  UPDATE_FOOD_TYPE_SUCCESS,
} from './constants'

export const create = (options = {}) => ({ foodTypesApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: CREATE_FOOD_TYPE_FAILED,
      init: CREATE_FOOD_TYPE,
      success: CREATE_FOOD_TYPE_SUCCESS,
    },
    options,
    service: foodTypesApiClient,
    serviceMethod: 'create',
  })
)

export const get = (options = {}) => ({ foodTypesApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: GET_FOOD_TYPE_FAILED,
      init: GET_FOOD_TYPE,
      success: GET_FOOD_TYPE_SUCCESS,
    },
    options,
    service: foodTypesApiClient,
    serviceMethod: 'get',
  })
)

export const update = (options = {}) => ({ foodTypesApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_FOOD_TYPE_FAILED,
      init: UPDATE_FOOD_TYPE,
      success: UPDATE_FOOD_TYPE_SUCCESS,
    },
    options,
    service: foodTypesApiClient,
    serviceMethod: 'update',
  })
)

export const clear = () => ({
  type: CLEAR_FOOD_TYPE,
})
