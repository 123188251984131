export const CREATE_NEWSLETTER_RECIPIENT = 'CREATE_NEWSLETTER_RECIPIENT'
export const CREATE_NEWSLETTER_RECIPIENT_SUCCESS = 'CREATE_NEWSLETTER_RECIPIENT_SUCCESS'
export const CREATE_NEWSLETTER_RECIPIENT_FAILED = 'CREATE_NEWSLETTER_RECIPIENT_FAILED'

export const GET_NEWSLETTER_RECIPIENT = 'GET_NEWSLETTER_RECIPIENT'
export const GET_NEWSLETTER_RECIPIENT_SUCCESS = 'GET_NEWSLETTER_RECIPIENT_SUCCESS'
export const GET_NEWSLETTER_RECIPIENT_FAILED = 'GET_NEWSLETTER_RECIPIENT_FAILED'

export const UPDATE_NEWSLETTER_RECIPIENT = 'UPDATE_NEWSLETTER_RECIPIENT'
export const UPDATE_NEWSLETTER_RECIPIENT_SUCCESS = 'UPDATE_NEWSLETTER_RECIPIENT_SUCCESS'
export const UPDATE_NEWSLETTER_RECIPIENT_FAILED = 'UPDATE_NEWSLETTER_RECIPIENT_FAILED'

export const CLEAR_NEWSLETTER_RECIPIENT_SINGLE = 'CLEAR_NEWSLETTER_RECIPIENT_SINGLE'
