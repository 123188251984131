import { createSelector } from 'reselect'

import { FEATURE_FLAGS } from 'constants/security'

import { getAuthUserFeatureFlags } from '../user/selectors'

export const hasParentAppAccessSelector = createSelector(
  [getAuthUserFeatureFlags],
  (authUserFeatureFlags) => 0 <= authUserFeatureFlags.indexOf(FEATURE_FLAGS.HAS_PARENT_APP_ACCESS),
)

export const hasHalfTermEnabled = createSelector(
  [getAuthUserFeatureFlags],
  (authUserFeatureFlags) => 0 <= authUserFeatureFlags.indexOf(FEATURE_FLAGS.HALF_TERM_ENABLED),
)
