import React from 'react'
import { WrappedFieldProps } from 'redux-form'

import { TimePickerProps } from 'components/TimePicker/TimePicker'

import { TimePicker } from 'components'

type FormTimePickerFullProps = TimePickerProps & WrappedFieldProps

const FormTimePicker: React.FC<FormTimePickerFullProps> = ({
  input: { onChange, value },
  meta: { error, submitFailed },
  ...rest
}) => (
  <TimePicker
    {...rest}
    error={submitFailed && error}
    value={value}
    onChange={onChange}
  />
)

export default FormTimePicker
