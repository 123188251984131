import { createSelector } from 'reselect'

const getAuth = (state) => state.auth

export const getAuthRegisterData = createSelector([getAuth], (state) => state.register.data)

export const getAuthRegisterToken = createSelector([getAuthRegisterData], (authData) => authData?.token)

export const getAuthRegisterProfile = createSelector([getAuthRegisterData], (authData) => authData?.profile)

export const getAuthRegisterProfileUser = createSelector([getAuthRegisterProfile], (profile) => profile?.user)
