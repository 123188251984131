import _ from 'lodash'

import React, { PropsWithChildren } from 'react'
import MasonryComponent, { MasonryOptions } from 'react-masonry-component'

interface MasonryProps {
  options?: MasonryOptions
}

const Masonry: React.FC<PropsWithChildren<MasonryProps>> = (props) => {
  const { children, options } = props

  return (
    // @ts-ignore
    <MasonryComponent
      className="masonryGrid"
      options={{
        gutter: 20,
        transitionDuration: '.2s',
        ...options,
      }}
    >
      {_.isArray(children) ? children : [children]}
    </MasonryComponent>
  )
}

export default Masonry
