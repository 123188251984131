import { createSelector } from 'reselect'

const getChildFrameworkProgress = (state) => state.childFrameworkProgress

export const getChildFrameworkProgressListState = createSelector([getChildFrameworkProgress], (state) => state.list)

export const getChildFrameworkProgressListDataState = createSelector(
  [getChildFrameworkProgressListState],
  (state) => state.data,
)
