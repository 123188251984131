import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import { CLEAR_LIST_ENQUIRIES, LIST_ENQUIRIES, LIST_ENQUIRIES_FAILED, LIST_ENQUIRIES_SUCCESS } from './constants'

export default (...props) => {
  const [state = getBasicListInitialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_LIST_ENQUIRIES,
    failed: LIST_ENQUIRIES_FAILED,
    init: LIST_ENQUIRIES,
    success: LIST_ENQUIRIES_SUCCESS,
  }, getBasicListInitialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
