export const GET_INTEGRATION_FEE = 'INTEGRATION_FEES/GET_INTEGRATION_FEE'
export const GET_INTEGRATION_FEE_FAILED = 'INTEGRATION_FEES/GET_INTEGRATION_FEE_FAILED'
export const GET_INTEGRATION_FEE_SUCCESS = 'INTEGRATION_FEES/GET_INTEGRATION_FEE_SUCCESS'

export const CREATE_INTEGRATION_FEE = 'INTEGRATION_FEES/CREATE_INTEGRATION_FEE'
export const CREATE_INTEGRATION_FEE_FAILED = 'INTEGRATION_FEES/CREATE_INTEGRATION_FEE_FAILED'
export const CREATE_INTEGRATION_FEE_SUCCESS = 'INTEGRATION_FEES/CREATE_INTEGRATION_FEE_SUCCESS'

export const UPDATE_INTEGRATION_FEE = 'INTEGRATION_FEES/UPDATE_INTEGRATION_FEE'
export const UPDATE_INTEGRATION_FEE_FAILED = 'INTEGRATION_FEES/UPDATE_INTEGRATION_FEE_FAILED'
export const UPDATE_INTEGRATION_FEE_SUCCESS = 'INTEGRATION_FEES/UPDATE_INTEGRATION_FEE_SUCCESS'

export const REMOVE_INTEGRATION_FEE = 'INTEGRATION_FEES/REMOVE_INTEGRATION_FEE'
export const REMOVE_INTEGRATION_FEE_FAILED = 'INTEGRATION_FEES/REMOVE_INTEGRATION_FEE_FAILED'
export const REMOVE_INTEGRATION_FEE_SUCCESS = 'INTEGRATION_FEES/REMOVE_INTEGRATION_FEE_SUCCESS'

export const CLEAR_INTEGRATION_FEE = 'INTEGRATION_FEES/CLEAR_INTEGRATION_FEE'
