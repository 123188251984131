import styled from 'styled-components'

import layout from 'constants/layout'

export const StyledSnackbarContainer = styled.div`
  * {
    font-family: ${layout.fontFamily} !important;
    font-size: 16px;
    box-shadow: none !important;
  }
  
  .webfont {
    font-family: 'webfont', sans-serif !important;
    cursor: pointer !important;
  }
  
  .MuiSnackbarContent-root {
    ${({ background }) => background && `
        background-color: ${background} !important;
    `};
  }
`

export const StyledSpinnerWrapper = styled.div`
  padding-right: 3px;  
`
