import _ from 'lodash'
import moment from 'moment'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { ALL_ROOMS } from 'services/legacy/rooms/constants'

export const getInitialValues = ({ data, nurserySettings }) => {
  const { openingDays, requestExtraSession } = nurserySettings || {}
  const { minRequestNoticeDays } = requestExtraSession || {}

  const day = moment().add(minRequestNoticeDays, 'days')
  const dayOfWeek = _.lowerCase(moment(day).format('dddd'))
  const nurseryDay = _.find(openingDays, { day: dayOfWeek })
  const { endTime: nurseryEndTime, startTime: nurseryStartTime } = nurseryDay || {}

  if (!data) {
    return ({
      date: moment(day).format(DEFAULT_DATE_FORMAT),
      endTime: moment(nurseryEndTime).utc(),
      nurseryClass: ALL_ROOMS,
      startTime: moment(nurseryStartTime).utc(),
    })
  }

  const {
    date,
    endTime,
    nurseryClass,
    startTime,
  } = data

  return ({
    date: moment(date).format(DEFAULT_DATE_FORMAT),
    endTime: moment(endTime).utc(),
    nurseryClass: nurseryClass?.id ? { value: nurseryClass.id } : ALL_ROOMS,
    startTime: moment(startTime).utc(),
  })
}
