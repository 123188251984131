import { GET_DIETARY_STATISTICS, GET_DIETARY_STATISTICS_FAILED, GET_DIETARY_STATISTICS_SUCCESS } from './constants'

export const getDietaryStatistics = (params, cb) => ({ childApiClient }) => (dispatch) => {
  dispatch({ payload: null, type: GET_DIETARY_STATISTICS })

  childApiClient.getDietaryStatistics(params).then(
    (data) => {
      dispatch({
        payload: data,
        type: GET_DIETARY_STATISTICS_SUCCESS,
      })

      if (cb) {
        cb(data)
      }
    },
    () => {
      dispatch({
        type: GET_DIETARY_STATISTICS_FAILED,
      })
    },
  )
}
