import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class DailyDiaryNappiesApiClient extends ServiceBase {
  list = (params) => this.request({
    path: `/v2/child_registers/diary_records/nappy_change_records?${getQueryStringV2(params)}`,
  })

  addRecord = (params, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/bulk/nappy_change_records?${getQueryStringV2(params)}`,
  })

  updateRecord = (id, body) => this.request({
    body,
    method: 'PATCH',
    path: `/nappy-change-records/${id}`,
  })

  removeRecord = (id) => this.request({
    method: 'DELETE',
    path: `/nappy-change-records/${id}`,
  })
}

export default DailyDiaryNappiesApiClient
