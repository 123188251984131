import _ from 'lodash'

import { ChargeForEnum } from 'services/closureDayTypes/models'

import { FormFields } from './models'

export const getInitialValues = (data): FormFields => {
  if (!data) {
    return null
  }

  const { chargeFor, name } = data

  return {
    chargeFor: {
      [ChargeForEnum.EXTRA_ITEM]: _.includes(chargeFor, ChargeForEnum.EXTRA_ITEM),
      [ChargeForEnum.EXTRA_SESSION]: _.includes(chargeFor, ChargeForEnum.EXTRA_SESSION),
      [ChargeForEnum.EXTRA_SESSION_CONSUMABLE]: _.includes(chargeFor, ChargeForEnum.EXTRA_SESSION_CONSUMABLE),
      [ChargeForEnum.FUNDING_DEFICIT]: _.includes(chargeFor, ChargeForEnum.FUNDING_DEFICIT),
      [ChargeForEnum.ONE_OFF_DISCOUNT]: _.includes(chargeFor, ChargeForEnum.ONE_OFF_DISCOUNT),
      [ChargeForEnum.REGULAR_DISCOUNT]: _.includes(chargeFor, ChargeForEnum.REGULAR_DISCOUNT),
      [ChargeForEnum.REGULAR_ITEMS]: _.includes(chargeFor, ChargeForEnum.REGULAR_ITEMS),
      [ChargeForEnum.REGULAR_SESSION]: _.includes(chargeFor, ChargeForEnum.REGULAR_SESSION),
      [ChargeForEnum.REGULAR_SESSION_CONSUMABLE]: _.includes(chargeFor, ChargeForEnum.REGULAR_SESSION_CONSUMABLE),
    },
    name,
  }
}
