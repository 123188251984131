import React from 'react'

import StaffHeader from './components/StaffHeader'

const StaffView = ({
  authAccessMap,
  children,
  isMyDetailsContext,
  isNurseryContext,
  membership,
}) => (
  <React.Fragment>
    <StaffHeader
      authAccessMap={authAccessMap}
      isMyDetailsContext={isMyDetailsContext}
      isNurseryContext={isNurseryContext}
      membership={membership}
    />
    {children}
  </React.Fragment>
)

export default StaffView
