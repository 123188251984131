import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  GET_CHILD_PRODUCT_PRICE_CALCULATOR,
  GET_CHILD_PRODUCT_PRICE_CALCULATOR_FAILED,
  GET_CHILD_PRODUCT_PRICE_CALCULATOR_SUCCESS,
} from './constants'

export const getProductPriceCalculator = (options: BasicActionOptionsProps) => ({ childApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: GET_CHILD_PRODUCT_PRICE_CALCULATOR_FAILED,
      init: GET_CHILD_PRODUCT_PRICE_CALCULATOR,
      success: GET_CHILD_PRODUCT_PRICE_CALCULATOR_SUCCESS,
    },
    options,
    service: childApiClient,
    serviceMethod: 'getProductPriceCalculator',
  })
)
