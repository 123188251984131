import React from 'react'
import { reduxForm } from 'redux-form'

import { generateRoute } from 'utils/routing'

import { Callout, Form } from 'components'

import i18n from 'translations'
import DiscountFormFields from './DiscountFormFields'

export const FORM_NAME = 'AddDiscountForm'

const AddDiscountForm = ({
  discountTypeOptions,
  error,
  formValues,
  handleSubmit,
  isEdit,
  isLoading,
  isPercentageSelected,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Callout content={error} error />
    <DiscountFormFields
      discountTypeOptions={discountTypeOptions}
      formValues={formValues}
      isEdit={isEdit}
      isPercentageSelected={isPercentageSelected}
    />
    <Form.FooterActions
      cancelLink={generateRoute('MANAGEMENT.FINANCE_SETTINGS.DISCOUNTS')}
      isSubmitting={isLoading}
      submitLabel={i18n.t('global:Save')}
    />
  </Form>
)

export default reduxForm({ form: FORM_NAME })(AddDiscountForm)
