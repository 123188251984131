import moment from 'moment'
import _ from 'lodash'

import React from 'react'
import { Field } from 'redux-form'

import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'

import {
  ACTUAL_TYPE_OPTIONS,
  PLANNED_TYPE_OPTIONS,
  WORK_SHIFT_TYPES,
} from 'services/legacy/membershipRegisters/constants'
import { RATIO_OPTIONS } from 'services/legacy/membershipsShifts/constants'

import { isRequired, isRequiredTime, isValidInteger } from 'utils/fieldValidation'

import { Button, DropdownMenu, Form, Typography } from 'components'

import i18n from 'translations'

import { SHIFT_MODAL_MODE } from '../../ShiftModalContainer'

import { StyledCharWrapper, StyledDay, StyledEnd, StyledRow, StyledRowWrapper } from './ShiftModalFormStyled'

const ShiftModalFormFieldArrayItem = ({
  dateElement,
  dayShifts,
  disablePastWeek,
  field,
  fields,
  index,
  isPastWeek,
  isQualifiedExpert,
  isSubmitting,
  item,
  memorizedSummaryTimes,
  mode,
  onChangeField,
  onChangeRecord,
  onChangeType,
  onCopyToAll,
  onSetEndTime,
  onShowChangeLog,
  roomsOptions,
  selectedDate,
  weekIndex,
}) => {
  const { endDateInFuture, endTime, id: shiftId, location, ongoing, startTime, type } = field
  const finalType = (type?.value || type)
  let timeDiff = null

  if (startTime && endTime) {
    timeDiff = moment(moment(endTime)).diff(moment(startTime))
  }

  if (0 > timeDiff) {
    onChangeField(`membershipShiftTimes.${dateElement}.${index}.endTime`, null)
  }

  const renderDate = () => (
    <StyledDay>
      <Typography transform="uppercase" variant="span" bold>
        {moment(selectedDate[0]).add('day', weekIndex).format('ddd')}
      </Typography>
      {' '}
      <Typography fontSize={17} variant="span">
        {moment(selectedDate[0]).add('day', weekIndex).format('DD')}
      </Typography>
    </StyledDay>
  )

  return (
    <React.Fragment>
      <StyledRowWrapper mode={mode}>
        {0 === index
          ? renderDate(selectedDate[0], weekIndex)
          : (
            <StyledDay noBorder />
          )}
        <StyledRow>
          <Field
            component={Form.Select}
            disabled={(isPastWeek && disablePastWeek) || isSubmitting}
            name={`${item}.type`}
            options={mode === SHIFT_MODAL_MODE.PLANNED ? PLANNED_TYPE_OPTIONS : ACTUAL_TYPE_OPTIONS}
            validate={(
              _.isUndefined(startTime)
              && _.isUndefined(endTime)
              && _.isUndefined(location)
                ? null
                : isRequired
            )}
            visualType="modern"
            onChange={(value) => {
              onChangeRecord(dateElement)
              onChangeType(value, dateElement, index)
            }}
          />
        </StyledRow>
        <StyledRow>
          <Field
            component={Form.Select}
            disabled={(isPastWeek && disablePastWeek) || isSubmitting || (
              mode === SHIFT_MODAL_MODE.PLANNED && (
                WORK_SHIFT_TYPES.BREAK === finalType || WORK_SHIFT_TYPES.BREAK_DURATION === finalType
              )
            )}
            name={`${item}.location`}
            options={roomsOptions}
            validate={finalType && (
              WORK_SHIFT_TYPES.CONTRACTED === finalType
              || WORK_SHIFT_TYPES.OVERTIME === finalType
            ) ? isRequired : null}
            visualType="modern"
            onChange={() => onChangeRecord(dateElement)}
          />
        </StyledRow>
        {WORK_SHIFT_TYPES.BREAK_DURATION === finalType ? (
          <StyledRow colspan={2}>
            <Field
              component={Form.TextField}
              disabled={(isPastWeek && disablePastWeek) || isSubmitting}
              name={`${item}.duration`}
              prefixWidth="12px"
              suffix={(
                <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} padding="0 5px 0" variant="span">
                  {i18n.t('global:minutes')}
                </Typography>
              )}
              suffixWidth="60px"
              type="number"
              validate={isValidInteger}
              visualType="modern"
              onChange={() => onChangeRecord(dateElement)}
            />
          </StyledRow>
        ) : (
          <React.Fragment>
            <StyledRow>
              <Field
                component={Form.TimePicker}
                disabled={(isPastWeek && disablePastWeek) || isSubmitting}
                name={`${item}.startTime`}
                placeholder={_.upperFirst(i18n.t('global:start'))}
                validate={finalType ? isRequired : null}
                visualType="modern"
                onChange={() => onChangeRecord(dateElement)}
              />
            </StyledRow>
            <StyledRow>
              {mode === SHIFT_MODAL_MODE.ACTUAL && !endTime && startTime && shiftId
                ? (
                  <StyledEnd>
                    <Button
                      hierarchy="tertiary"
                      label={i18n.t('module:Modals:Staff:Shift:end')}
                      size="small"
                      negativeMargins
                      onClick={() => onSetEndTime(dateElement, index)}
                    />
                  </StyledEnd>
                )
                : (
                  <StyledCharWrapper showChar={endDateInFuture}>
                    <Field
                      component={Form.TimePicker}
                      disabled={(isPastWeek && disablePastWeek) || isSubmitting}
                      name={`${item}.endTime`}
                      placeholder={_.upperFirst(i18n.t('global:end'))}
                      validate={finalType && mode === SHIFT_MODAL_MODE.PLANNED ? isRequiredTime : null}
                      visualType="modern"
                      onChange={() => onChangeRecord(dateElement)}
                    />
                  </StyledCharWrapper>
                )}
            </StyledRow>
          </React.Fragment>
        )}
        {mode === SHIFT_MODAL_MODE.PLANNED && (
          <StyledRow>
            <Field
              component={Form.Select}
              disabled={
                  (isPastWeek && disablePastWeek)
                  || isSubmitting
                  || WORK_SHIFT_TYPES.BREAK === finalType
                  || WORK_SHIFT_TYPES.BREAK_DURATION === finalType
                  || !isQualifiedExpert
                }
              name={`${item}.ratio`}
              options={RATIO_OPTIONS}
              visualType="modern"
              onChange={() => onChangeRecord(dateElement)}
            />
          </StyledRow>
        )}
        {0 === index
          ? (
            <StyledDay white>
              <Typography align="right" bold>
                {ongoing && !endTime
                  ? i18n.t('module:Modals:Staff:Shift:ongoing')
                  : memorizedSummaryTimes}
              </Typography>
            </StyledDay>
          )
          : (
            <StyledDay noBorder white />
          )}
        <StyledRow border center>
          <DropdownMenu
            disabled={(isPastWeek && disablePastWeek) || isSubmitting}
            small
          >
            <DropdownMenu.Item
              icon="plus"
              label={i18n.t('module:Modals:Staff:Shift:addRow')}
              onClick={() => fields.push({})}
            />
            {mode === SHIFT_MODAL_MODE.PLANNED && (
              <DropdownMenu.Item
                icon="repeat"
                label={i18n.t('module:Modals:Staff:Shift:copyToAll')}
                onClick={() => onCopyToAll(dayShifts)}
              />
            )}
            {mode === SHIFT_MODAL_MODE.ACTUAL && (
              <DropdownMenu.Item
                icon="change-log"
                label={i18n.t('module:Modals:Staff:Shift:changeLog')}
                onClick={() => onShowChangeLog({
                  date: dateElement,
                })}
              />
            )}
            <DropdownMenu.Divider />
            <DropdownMenu.Item
              icon="trash"
              label={i18n.t('global:Delete')}
              onClick={() => {
                fields.remove(index)

                if (0 === fields.length - 1) {
                  fields.push({})
                }
              }}
            />
          </DropdownMenu>
        </StyledRow>
      </StyledRowWrapper>
      {endDateInFuture && (
        <StyledRowWrapper endDateVersion>
          <StyledDay noBorder />
          <StyledRow border yellow>
            <Typography color={FLAG_COLOURS.ERROR} variant="span">
              {'* '}
            </Typography>
            {WORK_SHIFT_TYPES.BREAK === finalType
              ? i18n.t('module:Modals:Staff:Shift:breakEndDate')
              : i18n.t('module:Modals:Staff:Shift:workEndDate')}
          </StyledRow>
          <StyledRow noBorder yellow>
            <Field
              component={Form.DatePicker}
              name={`${item}.endTime`}
              visualType="modern"
            />
          </StyledRow>
          <StyledRow yellow />
        </StyledRowWrapper>
      )}
    </React.Fragment>
  )
}

export default React.memo(ShiftModalFormFieldArrayItem)
