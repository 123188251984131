import React, { PropsWithChildren } from 'react'

import { StyledColumn } from './DataTableStyled'

interface DataTableColumnProps {
  borderRight?: string
  noBorder?: boolean
  noHidden?: boolean
}

const DataTableColumn: React.FC<PropsWithChildren<DataTableColumnProps>> = ({
  borderRight,
  children,
  noBorder,
  noHidden,
}) => (
  <StyledColumn
    $borderRight={borderRight}
    $noBorder={noBorder}
    $noHidden={noHidden}
  >
    {children}
  </StyledColumn>
)

export default DataTableColumn
