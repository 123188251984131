import React, { PropsWithChildren } from 'react'

import { Spinner } from 'components'

import InlineEditActions from '../InlineEditActions'
import {
  StyledContainer,
  StyledContentContainer,
  StyledLabelContainer,
  StyledLeftContainer,
  StyledRightContainer,
} from './InlineEditStyled'

interface InlineEditRoot<T> extends React.FC<PropsWithChildren<T>> {
  Actions?: typeof InlineEditActions
}

interface InlineEditProps {
  actions?: React.ReactNode
  description?: string | React.ReactNode
  editableComponent?: React.ReactNode
  inline?: boolean
  isEditMode?: boolean
  isLoading?: boolean
  label?: string | React.ReactNode
  noBorder?: boolean
  noPadding?: boolean
  padding?: string
  verticalCenter?: boolean
}

const InlineEdit: InlineEditRoot<InlineEditProps> = ({
  actions,
  description,
  editableComponent,
  inline,
  isEditMode,
  isLoading,
  label,
  noBorder,
  noPadding,
  padding,
  verticalCenter,
}) => (
  <StyledContainer
    $noBorder={noBorder}
    $noPadding={noPadding}
    $padding={padding}
    $verticalCenter={verticalCenter}
  >
    <StyledLeftContainer $inline={inline}>
      {label && (
        <StyledLabelContainer $inline={inline}>
          {label}
        </StyledLabelContainer>
      )}
      <StyledContentContainer $inline={inline}>
        {(isEditMode || isLoading)
          ? editableComponent
          : description}
      </StyledContentContainer>
    </StyledLeftContainer>
    {actions && (
      <StyledRightContainer>
        {isLoading ? <Spinner /> : actions}
      </StyledRightContainer>
    )}
  </StyledContainer>
)

export default InlineEdit
