import { combineReducers } from 'redux'

import common, { AuthenticationCommonState } from './common/reducer'

export default combineReducers({
  common,
})

export interface AuthenticationState {
  common: AuthenticationCommonState
}
