import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

import { StyledLoader } from 'components/InfiniteScroll/InfiniteScrollStyled'

export const StyledChildrenContainer = styled.div`
  background: ${getBrandingColor('primary-color')};
  padding: 10px;
  min-height: calc(100% - 60px);
  overflow-y: scroll;

  & > div > div {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 5px));
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
  
  ${StyledLoader} {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`

export const StyledChildBox = styled.div`
  background: ${getBrandingColor('senary-color')};
  padding: 5px;
  max-width: 100%;
  overflow-x: hidden;
`

export const StyledEmptyState = styled.div`
  & > div {
      grid-template-rows: auto auto;
      grid-template-columns: auto !important;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 20px 0;
  }
`
