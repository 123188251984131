import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledStatistics = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 15px;
  margin: 15px 0 20px;
`

export const StyledStatisticsBox = styled.div`
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 15px;
  background: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
`
