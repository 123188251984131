import _ from 'lodash'
import moment from 'moment'

import React, { useMemo } from 'react'

import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'

import { getFormattedChildSessions } from 'services/legacy/childSessions/selectors'

import { Button, DateString, Section, Space } from 'components'

import i18n from 'translations'

import ChildSessionsList from 'module/Children/Child/ChildBookingPattern/ChildSessions/components/ChildSessionsList'

import ReviewItem from './ReviewItem'

const ReviewEnrolmentDetails = ({
  enableSessions,
  formValues,
  isAllYearRoundSelected,
  onSetStage,
  openingDays,
}) => {
  const {
    nurseryClass,
    registeredAt,
    session,
    startDate,
  } = formValues

  const formattedSessions = useMemo(() => {
    const finalPlans = _.reduce(session?.plans, (result, value, key) => {
      const newResult = { ...result }

      newResult[key] = _.filter(value, (item) => !_.isEmpty(item))

      return newResult
    }, {})

    return getFormattedChildSessions({
      formattedOpeningDays: openingDays,
      grouped: finalPlans,
      isFormContext: true,
    })
  }, [openingDays, session])

  const { attendancePeriod, endDate: sessionEndDate, isOngoing, startDate: sessionStartDate } = session || {}

  const startDateText = moment(sessionStartDate).format(DISPLAY_SHORT_DATE_FORMAT)
  const endDateText = isOngoing
    ? _.upperFirst(i18n.t('global:ongoing'))
    : moment(sessionEndDate).format(DISPLAY_SHORT_DATE_FORMAT)
  const title = `${startDateText} - ${endDateText}`

  return (
    <Section
      actions={(
        <Button
          hierarchy="tertiary"
          label={i18n.t('global:Edit')}
          onClick={() => onSetStage(2)}
        />
      )}
      title={i18n.t('module:Children:Child:Add:EnrolmentDetails:title')}
    >
      <Section
        title={i18n.t('module:Children:Child:Add:EnrolmentDetails:Sections:attendance')}
        variant="small"
      >
        <ReviewItem
          label={i18n.t('module:Children:Child:Add:EnrolmentDetails:Subsections:Attendance:Form:StartDate:label')}
          value={<DateString date={startDate} />}
        />
        <ReviewItem
          label={i18n.t(
            'module:Children:Child:Add:EnrolmentDetails:Subsections:Attendance:Form:RegistrationDate:label',
          )}
          value={<DateString date={registeredAt} />}
        />
        <ReviewItem
          label={i18n.t('module:Children:Child:Add:EnrolmentDetails:Subsections:Attendance:Form:Room:label')}
          value={nurseryClass?.label}
        />
      </Section>
      <Space margin="0 0 25px" />
      <Section
        title={i18n.t('global:Session')}
        variant="small"
      >
        {enableSessions
          ? (
            <ChildSessionsList
              attendancePeriodText={`${attendancePeriod?.name}${attendancePeriod?.isArchived ? ' (archived)' : ''}`}
              childSession={{ formattedPlans: formattedSessions }}
              sectionVariant="small"
              showAllocationPeriod={isAllYearRoundSelected}
              title={title}
            />
          ) : i18n.t('module:Children:Child:Add:EnrolmentDetails:Subsections:Sessions:emptyStatePreviewText')}
      </Section>
    </Section>
  )
}

export default ReviewEnrolmentDetails
