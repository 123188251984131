import _ from 'lodash'

import { createSelector } from 'reselect'

import { RolesDetails } from 'constants/security'

const getAuth = (state) => state.auth

export const getAuthPinData = createSelector([getAuth], (state) => state.pin.data)

export const getAuthPinAuthToken = createSelector([getAuthPinData], (authData) => authData?.token)

export const getAuthPinProfile = createSelector([getAuthPinData], (authData) => {
  if (!authData) {
    return null
  }

  const { profile } = authData
  const { roles } = profile || {}
  const roleLabels = _.map(roles, (role) => RolesDetails[role]?.label)

  return {
    ...authData.profile,
    roleLabels: roleLabels.join(', '),
  }
})
