import React from 'react'

import { BasicModalProps } from 'modals'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { Button, MinimalModalBox } from 'components'

import { StyledContent, StyledFooter } from './AlertModalStyled'

export interface AlertModalProps {
  hideButton?: boolean
  icon?: IconType
  label?: string | React.ReactNode
  onClick?: () => void
  text?: string | React.ReactNode
}

const AlertModal: React.FC<BasicModalProps & AlertModalProps> = ({
  hideButton,
  hideModal,
  icon,
  label = 'OK',
  onClick,
  text,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick()
    }

    if (hideModal) {
      hideModal()
    }
  }

  return (
    <MinimalModalBox>
      {icon && <MinimalModalBox.TopIcon color={NEUTRAL_COLOURS.WHITE} icon={icon} />}
      <StyledContent>
        {text}
      </StyledContent>
      {!hideButton && (
        <StyledFooter>
          <Button
            label={label}
            onClick={handleClick}
          />
        </StyledFooter>
      )}
    </MinimalModalBox>
  )
}

export default AlertModal
