import { createSelector } from 'reselect'

import { hasOnlySeniorTeacherOrTeacherAccess } from 'services/security/selectors'

export const getRelationsValuesForm = ({
  address,
  email,
  firstName,
  mobile,
  occupation,
  photo,
  postCode,
  surname,
  telephone,
  title,
  workTelephone,
}) => ({
  address,
  email,
  firstName,
  mobile,
  occupation,
  photo,
  postCode,
  surname,
  telephone,
  title: title ? title.value : null,
  workTelephone,
})

export const isGrantedEditSelector = createSelector(
  [hasOnlySeniorTeacherOrTeacherAccess],
  (isSeniorTeacherOrTeacher) => !isSeniorTeacherOrTeacher,
)
