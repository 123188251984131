import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledContainer = styled.div`
  ul {
    display: flex;
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
  }

  li {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${NEUTRAL_COLOURS.BASIC};
    margin-left: 3px;
    margin-right: 3px;
    font-weight: 600;

    a {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${NEUTRAL_COLOURS.BASIC};
      cursor: pointer;
    }

    &.break {
      width: auto;
    }

    &.selected {
      a {
        color: ${getBrandingColor('tertiary-color')};
      }
    }
  }
`

export const StyledDetails = styled.div`
  padding-left: 6px;
  height: 100%;
  display: flex;
  align-items: flex-end;
  min-height: 35px;
`
