import React, { PropsWithChildren } from 'react'

import { Button } from 'components'

import ModalBoxScrollContent from './ModalBoxScrollContent'
import {
  StyledContainer,
  StyledContent,
  StyledHeader,
  StyledHeaderContainer,
  StyledHeaderTitle,
} from './ModalBoxStyled'

interface ModalBoxProps {
  autoHeight?: boolean
  iosPolyfill?: boolean
  maxWidth?: number
  noHeader?: boolean
  onCloseClick?: () => void
  scrollToBody?: boolean
  title?: string
}

interface ModalBoxRoot extends React.FC<PropsWithChildren<ModalBoxProps>> {
  ScrollContent?: typeof ModalBoxScrollContent
}

const ModalBox: ModalBoxRoot = ({
  autoHeight,
  children,
  iosPolyfill,
  maxWidth = 800,
  noHeader,
  onCloseClick,
  scrollToBody = true,
  title,
}) => {
  const renderHeader = () => (
    <StyledHeaderContainer>
      <StyledHeader>
        <StyledHeaderTitle>
          {title}
        </StyledHeaderTitle>
        <Button
          hierarchy="tertiary"
          icon="close"
          inverted
          onClick={onCloseClick}
        />
      </StyledHeader>
    </StyledHeaderContainer>
  )

  return (
    <StyledContainer $autoHeight={autoHeight} $iosPolyfill={iosPolyfill} $maxWidth={maxWidth}>
      {!noHeader && renderHeader()}
      <StyledContent
        $iosPolyfill={iosPolyfill}
        $noHeader={noHeader}
        $scrollToBody={scrollToBody}
      >
        {children}
      </StyledContent>
    </StyledContainer>
  )
}

export default ModalBox
