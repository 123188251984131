import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'
import { PeriodType, Type } from 'services/booking/periods/models'

import { generateRoute } from 'utils/routing'

import { withAppService, withAppServiceProps } from 'services/app'
import { withPeriodsService, withPeriodsServiceProps } from 'services/booking/periods'
import { withRouter, withRouterProps } from 'services/router'
import { withSnackbarService, withSnackbarServiceProps } from 'services/utils/snackbar'

import i18n from 'translations'

import AddView from './AddView'
import { getInitialValues } from './AddHelper'
import { FormFields } from './models'

type AddContainerProps = withAppServiceProps
  & withPeriodsServiceProps
  & withRouterProps
  & withSnackbarServiceProps

const mapState = (state: RootState, {
  appSelectors,
  periodsSingleState,
}: AddContainerProps) => ({
  errorMessages: appSelectors.getErrorMessages(periodsSingleState),
  isFetching: appSelectors.getIsFetching(periodsSingleState),
  isSubmitting: appSelectors.getIsSubmitting(periodsSingleState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const AddContainer: React.FC<AddContainerProps & PropsFromRedux> = ({
  errorMessages,
  isFetching,
  isSubmitting,
  navigate,
  params,
  periodsActions,
  snackbarActions,
}) => {
  const [initialValues, setInitialValues] = useState<FormFields>(null)

  useEffect(() => {
    if (params.id) {
      periodsActions.get({
        onSuccess: (response) => setInitialValues(getInitialValues(response.data)),
        params: [params.id],
      })
    } else {
      setInitialValues({
        periodType: PeriodType.ALL_YEAR_ROUND,
      })
    }
  }, [])

  const handleSubmitSuccess = ({ data }) => {
    const { id, periodType } = data

    const mode = params.id ? i18n.t('global:updated') : i18n.t('global:created')

    snackbarActions.show({
      message: i18n.t('module:Management:AttendancePeriods:Add:snackbarLabel', { mode }),
    })

    if (periodType === PeriodType.TERM_DATES) {
      return navigate(generateRoute('MANAGEMENT.ATTENDANCE_PERIODS.SET_TERM_DATES', { id }))
    }

    return navigate(generateRoute('MANAGEMENT.ATTENDANCE_PERIODS'))
  }

  const handleSubmit = (fields) => {
    if (params.id) {
      return periodsActions.update({
        body: { ...fields },
        onSuccess: handleSubmitSuccess,
        params: [params.id],
      })
    }

    return periodsActions.create({
      body: { ...fields, type: Type.attendance },
      onSuccess: handleSubmitSuccess,
    })
  }

  return (
    <AddView
      errorMessages={errorMessages}
      initialValues={initialValues}
      isEdit={!!params.id}
      isLoading={isFetching}
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withAppService,
  withRouter,
  withPeriodsService,
  withSnackbarService,
  connector,
)

export default enhance(AddContainer)
