import React, { useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'
import { change, formValueSelector } from 'redux-form'

import { RootState } from 'core/reducers'
import { Nursery } from 'services/nurseries/models'
import { withRouteUtilsProps } from 'services/utils/router'

import { generateRoute } from 'utils/routing'

import { withAppService, withAppServiceProps } from 'services/app'
import { withNurseriesService, withNurseriesServiceProps } from 'services/nurseries'
import { withRouter, withRouterProps } from 'services/router'

import {
  INVOICE_ANNUALISED_SETTING_FORM,
} from 'module/Settings/components/InvoiceAnnualisedSettingForm/InvoiceAnnualisedSettingForm'
import { InitialValuesResponseProps, getInitialValues } from './helpers'
import ManagementInvoicesV3EditAnnualisedView from './ManagementInvoicesV3EditAnnualisedView'

const groups = {
  read: [
    'nursery.settings',
    'nurserySettings.invoice',
    'nurseryInvoiceSettings',
    'nurseryInvoiceSettings.numbers',
    'invoiceNumbers',
    'nursery.organizationSettings',
    'organizationSettings',
    'organizationSettings.invoice',
    'organizationInvoiceSettings',
    'nurseryInvoiceSettings.annualisedInvoiceSettings',
    'annualisedInvoiceSettings',
    'organizationInvoiceSettings.annualisedInvoiceSettings',
  ],
}

type ManagementInvoicesV3EditAnnualisedContainerProps = withAppServiceProps
  & withRouteUtilsProps
  & withRouterProps
  & withNurseriesServiceProps

const mapState = (state: RootState, {
  appSelectors,
  nurseriesSingleState,
  params,
}: ManagementInvoicesV3EditAnnualisedContainerProps) => ({
  annualisedInvoiceSettingsAppliedFromGlobal: formValueSelector(INVOICE_ANNUALISED_SETTING_FORM)(
    state,
    'annualisedInvoiceSettingsAppliedFromGlobal',
  ),
  errorMessages: appSelectors.getErrorMessages(nurseriesSingleState),
  isSubmitting: appSelectors.getIsSubmitting(nurseriesSingleState),
  // @ts-ignore
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const mapDispatch = {
  updateField: (fieldName, fieldValue) => change(INVOICE_ANNUALISED_SETTING_FORM, fieldName, fieldValue),
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

const ManagementInvoicesV3EditAnnualisedContainer: React.FC<
  ManagementInvoicesV3EditAnnualisedContainerProps
  & PropsFromRedux
> = ({
  annualisedInvoiceSettingsAppliedFromGlobal,
  errorMessages,
  isSubmitting,
  navigate,
  nurseriesActions,
  nurseriesSelectors,
  nurseryOptions,
  updateField,
}) => {
  const [initialValues, setInitialValues] = useState<InitialValuesResponseProps>()
  const [nurserySettingsData, setNurserySettingsData] = useState<Nursery>()
  const [nurseryOrganizationSettingsData, setOrganizationNurserySettingsData] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const handleGetNurseriesSuccess = (response) => {
    setNurserySettingsData(response?.data?.nurserySettings)
    setOrganizationNurserySettingsData(response?.data?.organizationSettings)
    setInitialValues(getInitialValues(response))
    setIsLoading(false)
  }

  useEffect(() => {
    nurseriesActions.get(nurseryOptions.id, {
      onSuccess: handleGetNurseriesSuccess,
      onlyData: true,
      params: { groups },
    })
  }, [])

  useEffect(() => {
    if (annualisedInvoiceSettingsAppliedFromGlobal) {
      updateField('weeks', nurseryOrganizationSettingsData?.invoice?.annualisedInvoiceSettings?.weeks)
      updateField('months', nurseryOrganizationSettingsData?.invoice?.annualisedInvoiceSettings?.months)
      updateField(
        'allowOverwrite',
        nurseryOrganizationSettingsData?.invoice?.annualisedInvoiceSettings?.allowOverwriteOnChildLevel,
      )
    }
  }, [annualisedInvoiceSettingsAppliedFromGlobal])

  const handleSubmitSuccess = () => {
    navigate(generateRoute('MANAGEMENT.FINANCE_SETTINGS.INVOICES'))
  }

  const handleSubmit = (fields) => {
    const payload = nurseriesSelectors.getAnnualisedPayload(fields, nurserySettingsData)

    nurseriesActions.update(nurseryOptions.id, {
      onSuccess: handleSubmitSuccess,
      payload,
    })
  }

  return (
    <ManagementInvoicesV3EditAnnualisedView
      cancelLink={generateRoute('MANAGEMENT.FINANCE_SETTINGS.INVOICES')}
      errorMessages={errorMessages}
      initialValues={initialValues}
      isFormLoading={isSubmitting}
      isLoading={isLoading}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withRouter,
  withAppService,
  withNurseriesService,
  connector,
)

export default enhance(ManagementInvoicesV3EditAnnualisedContainer)
