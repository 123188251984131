import React, { PropsWithChildren } from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Button, Icon } from 'components'

import {
  StyledActionColumn,
  StyledBorderColumn,
  StyledChildColumn,
  StyledContent,
  StyledContentColumn,
  StyledExtraTitle,
  StyledIconContainer,
  StyledRow,
  StyledSubContent,
  StyledTitle,
  StyledTitleContainer,
  StyledVerticalText,
} from './ListStandardStyled'

interface ListStandardRowProps {
  borderColor?: string
  borderTitle?: string
  content?: React.ReactNode
  contentFull?: boolean
  contentNoPadding?: boolean
  customContent?: React.ReactNode
  extraTitle?: string
  extraTitleColor?: string
  leftColumn?: React.ReactNode
  subContent?: React.ReactNode
  title?: string | React.ReactNode
  titleActions?: React.ReactNode
  titleIcon?: IconType
  titleIconColor?: string
  to?: string
}

const ListStandardRow: React.FC<PropsWithChildren<ListStandardRowProps>> = ({
  borderColor = '#D1D1D1',
  borderTitle,
  content,
  contentFull,
  contentNoPadding,
  customContent,
  extraTitle,
  extraTitleColor,
  leftColumn,
  subContent,
  title,
  titleActions,
  titleIcon,
  titleIconColor,
  to,
}) => (
  <StyledRow
    $customContent={customContent}
    $existActionColumn={!!to}
    $existBorderColumn={!!borderTitle}
    $existLeftColumn={!!leftColumn}
    $onlyContentExists={!!content && !to && !borderTitle}
  >
    {borderTitle && (
      <StyledBorderColumn
        $background={borderColor}
        $noPadding
      >
        <StyledVerticalText>
          {borderTitle}
        </StyledVerticalText>
      </StyledBorderColumn>
    )}
    {leftColumn && (
      <StyledChildColumn $background={NEUTRAL_COLOURS.SILVER}>
        {leftColumn}
      </StyledChildColumn>
    )}
    {customContent || (
      <React.Fragment>
        <StyledContentColumn
          $noPadding={contentNoPadding}
          $noAlign
        >
          {(title || titleIcon || titleActions || extraTitle) && (
            <StyledTitleContainer $withIcon={titleIcon}>
              {title && (
                <StyledTitle>
                  {title}
                </StyledTitle>
              )}
              {titleIcon && (
                <StyledIconContainer>
                  <Icon
                    color={titleIconColor}
                    height={18}
                    icon={titleIcon.toLowerCase() as IconType}
                  />
                </StyledIconContainer>
              )}
              {titleActions}
              <StyledExtraTitle $color={extraTitleColor}>
                {extraTitle}
              </StyledExtraTitle>
            </StyledTitleContainer>
          )}
          <StyledContent $contentFull={contentFull}>
            {content}
          </StyledContent>
          <StyledSubContent $mainContentExists={!!content}>
            {subContent}
          </StyledSubContent>
        </StyledContentColumn>
        {to && (
          <StyledActionColumn>
            <Button
              icon="chevron-right"
              to={to}
            />
          </StyledActionColumn>
        )}
      </React.Fragment>
    )}
  </StyledRow>
)

export default ListStandardRow
