import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import {
  CLEAR_ABSENCE_REASONS_LIST,
  LIST_ABSENCE_REASONS,
  LIST_ABSENCE_REASONS_FAILED,
  LIST_ABSENCE_REASONS_SUCCESS,
} from './constants'

const initialState = {
  ...getBasicListInitialState,
  isFetching: true,
}

export default (...props) => {
  const [state = initialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_ABSENCE_REASONS_LIST,
    failed: LIST_ABSENCE_REASONS_FAILED,
    init: LIST_ABSENCE_REASONS,
    success: LIST_ABSENCE_REASONS_SUCCESS,
  }, initialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
