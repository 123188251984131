import styled from 'styled-components'

export const StyledContentContainer = styled.section`
  display: grid;
  grid-row-gap: 20px;
`

export const StyledSessionContainer = styled.section`
  display: grid;
  grid-gap: 10px;
`
