import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { EVENTS, logEvent } from 'analytics'

import { isFutureDay, isSameDay } from 'utils/date'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'

import { Button, DropdownMenu, Spinner } from 'components'

import i18n from 'translations'

import { ABSENCE_TYPE, REGISTER_ACTION_TYPES } from '../../constants'
import { getAbsencePayload, getFormattedSignIns } from './helpers'

import {
  StyledActionsContainer,
  StyledContainer,
  StyledDetailContainer,
  StyledEditIconContainer,
  StyledTopContainer,
  StyledWrapper,
} from './RegisterItemStyled'

import RegisterItemError from './RegisterItemError'
import RegisterItemProfile from './RegisterItemProfile'
import RegisterItemContent from './RegisterItemContent'
import RegisterItemPanel from './RegisterItemPanel'

class RegisterItem extends Component {
  constructor(props) {
    super(props)

    const { absence, comments, date, registerDate } = props

    this.state = {
      absence,
      comments,
      editSignIns: null,
      errors: {},
      expandType: null,
      isAddingExtraTimeIn: false,
      isEditing: false,
      isFutureDate: isFutureDay(registerDate || date),
      signInsIndex: null,
    }
  }

  componentDidUpdate(prevProps) {
    const { date, registerDate } = this.props
    const { date: prevDate, registerDate: prevRegisterDate } = prevProps

    if (!isSameDay(prevRegisterDate || prevDate, registerDate || date)) {
      this.setState({
        isFutureDate: isFutureDay(registerDate || date),
      })
    }
  }

  updatePropsToState = () => {
    const { absence, comments } = this.props

    this.setState({ absence, comments })
  }

  handleCommentActionClick = () => {
    const { COMMENT } = REGISTER_ACTION_TYPES

    logEvent(EVENTS.REGISTER_COMMENT_BTN_CLICKED)

    this.updatePropsToState()

    this.setState((prevState) => ({
      expandType: prevState?.expandType === COMMENT ? null : COMMENT,
    }))
  }

  getAbsenceType = ({ absence, expandType }) => {
    if (null === expandType && !absence) {
      return {
        absenceType: ABSENCE_TYPE.ABSENCE,
      }
    }

    return absence
  }

  handleAbsenceActionClick = () => {
    const { absence, child, date, onAbsenceClick } = this.props

    const { ABSENCE } = REGISTER_ACTION_TYPES

    this.updatePropsToState()

    if (absence) {
      this.setState((prevState) => ({
        absence: this.getAbsenceType(prevState),
        expandType: prevState?.expandType === ABSENCE ? null : ABSENCE,
      }))

      return
    }

    onAbsenceClick(child, date)
  }

  handleTimeChange = (fieldName, index) => (value) => {
    this.setState((prevState) => ({
      editSignIns: prevState.editSignIns.map((editSignIn, editIndex) => {
        if (editIndex === index) {
          return {
            ...editSignIn,
            [fieldName]: new Date(value),
          }
        }

        return editSignIn
      }),
    }))
  }

  handleSuccessEdit = () => {
    this.setState({
      editSignIns: null,
      expandType: null,
      isEditing: false,
    })
  }

  handleTickButtonClick = () => {
    const { child, id, onSaveClick } = this.props
    const { absence, comments, editSignIns } = this.state

    const newValues = {
      absence: getAbsencePayload(absence),
      comments,
      signIns: editSignIns,
    }

    onSaveClick(child.id, id, newValues, this.handleSuccessEdit)
  }

  handleEditButtonClick = () => {
    const { absence, child, onAbsenceEditClick, present, signIns } = this.props
    const { comments } = this.state

    logEvent(EVENTS.REGISTER_ENTRY_EDIT_BTN_CLICKED)

    if (false === present) {
      onAbsenceEditClick(child, absence.id)

      return
    }

    const { ABSENCE, COMMENT } = REGISTER_ACTION_TYPES

    this.updatePropsToState()

    const getExpandType = () => {
      if (null === present && !comments) {
        return null
      }

      if (comments) {
        return COMMENT
      }

      if (!present) {
        return ABSENCE
      }

      return null
    }

    this.setState({
      editSignIns: getFormattedSignIns(signIns),
      expandType: getExpandType(),
      isEditing: true,
    })
  }

  handlePanelCancelClick = () => {
    const { absence, comments } = this.props

    this.setState({ absence, comments, expandType: null })
  }

  handleCommentsChange = (e) => {
    const { expandType } = this.state
    const { COMMENT } = REGISTER_ACTION_TYPES

    const comments = e.target.value

    if (expandType === COMMENT) {
      this.setState({ comments })
    } else {
      this.setState((prevState) => ({
        absence: {
          ...prevState.absence,
          comments,
        },
      }))
    }
  }

  handleSuccess = () => {
    logEvent(EVENTS.REGISTER_ENTRY_SAVED)

    this.setState({ expandType: null })
  }

  handlePanelSaveClick = () => {
    const { child, date, id, onSaveClick, present, registerDate } = this.props
    const { absence, comments, expandType } = this.state
    const { ABSENCE } = REGISTER_ACTION_TYPES

    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        absenceEndDate: null,
        comments: null,
      },
    }))

    const newValues = {
      absence: getAbsencePayload(absence, registerDate || date),
      comments,
      present: expandType === ABSENCE ? false : present,
    }

    onSaveClick(child.id, id, newValues, this.handleSuccess)
  }

  handleStaffLogClick = () => {
    const { absence, child, id, onStaffLogClick } = this.props

    onStaffLogClick(id, child, absence)
  }

  handleAbsenceDeleteClick = () => {
    const { absence, child, onAbsenceDeleteClick } = this.props

    onAbsenceDeleteClick(child, absence, this.handleResetExpandType)
  }

  handleResetExpandType = () => {
    this.setState({ expandType: null })
  }

  handleSignIn = (index) => () => {
    const { child, id, onSignInClick } = this.props
    const { SIGN_IN } = REGISTER_ACTION_TYPES

    this.setState({
      expandType: SIGN_IN,
      signInsIndex: index,
    })

    onSignInClick(child.id, id)
  }

  handleSignOut = (signInItem, index) => () => {
    const { child, id, onSignOutClick } = this.props
    const { SIGN_OUT } = REGISTER_ACTION_TYPES

    this.setState({
      expandType: SIGN_OUT,
      signInsIndex: index,
    })

    onSignOutClick(child.id, id, signInItem)
  }

  handleAddExtraTimeInClick = () => {
    this.setState({
      isAddingExtraTimeIn: true,
    })
  }

  handleAbsenceTypeChange = (option) => {
    this.setState((prevState) => ({
      absence: {
        ...prevState.absence,
        absenceType: option ? option.value : null,
      },
    }))
  }

  handleAbsenceReasonChange = (option) => {
    this.setState((prevState) => ({
      absence: {
        ...prevState.absence,
        reason: option ? option.value : null,
      },
    }))
  }

  getDateRangeValidation = (startDate, endDate) => (
    startDate > endDate
      ? i18n.t('module:Register:dateRangeValidation')
      : ''
  )

  handleAbsenceStartDateChange = (startDate) => {
    const { absence } = this.state

    const absenceStartDate = moment(startDate)
    const absenceEndDate = moment(absence.endDate)

    const absenceEndDateError = this.getDateRangeValidation(absenceStartDate, absenceEndDate)

    this.setState((prevState) => ({
      absence: {
        ...prevState.absence,
        startDate,
      },
      errors: {
        ...prevState.errors,
        absenceEndDate: absenceEndDateError,
      },
    }))
  }

  handleAbsenceEndDateChange = (endDate) => {
    const { absence } = this.props

    const absenceStartDate = moment(absence?.startDate)
    const absenceEndDate = moment(endDate)

    const absenceEndDateError = this.getDateRangeValidation(absenceStartDate, absenceEndDate)

    this.setState((prevState) => ({
      absence: {
        ...prevState.absence,
        endDate,
      },
      errors: {
        ...prevState.errors,
        absenceEndDate: absenceEndDateError,
      },
    }))
  }

  handleResetClick = () => {
    const { child, id, onResetClick } = this.props

    // FIXME: why do I need expandType here?
    this.setState({
      absence: null,
      comments: null,
      expandType: null,
      isAddingExtraTimeIn: false,
      isEditing: false,
    })

    if (onResetClick) {
      onResetClick(child, id)
    }
  }

  renderEditButton = () => {
    const { comments, isSubmitting, present, signIns } = this.props
    const { isEditing } = this.state

    if (isEditing) {
      if (isSubmitting) {
        return <Spinner />
      }

      return (
        <Button
          hierarchy="secondary"
          icon="check"
          onClick={this.handleTickButtonClick}
        />
      )
    }

    if ((undefined === present || null === present) && !signIns && !comments) {
      return null
    }

    return (
      <Button
        hierarchy="secondary"
        icon="edit"
        onClick={this.handleEditButtonClick}
      />
    )
  }

  renderActions = () => {
    const { absence, isOffline, signIns } = this.props
    const { isFutureDate } = this.state

    return (
      <DropdownMenu>
        {!isFutureDate && signIns && 1 === signIns.length && signIns[0].signedInAt && signIns[0].signedOutAt && (
          <DropdownMenu.Item
            icon="plus"
            label={i18n.t('module:Register:DropdownOptions:TimeIn:label')}
            onClick={this.handleAddExtraTimeInClick}
          />
        )}
        <DropdownMenu.Item
          disabled={isOffline}
          icon="contact"
          label={i18n.t('module:Register:DropdownOptions:StaffLog:label')}
          onClick={this.handleStaffLogClick}
        />
        {absence && (
          <DropdownMenu.Item
            icon="trash"
            label={absence?.absenceType === ABSENCE_TYPE.ABSENCE
              ? i18n.t('module:Register:DropdownOptions:DeleteAbsence:label')
              : i18n.t('module:Register:DropdownOptions:DeleteHoliday:label')}
            onClick={this.handleAbsenceDeleteClick}
          />
        )}
        {!absence && signIns && (
          <DropdownMenu.Item
            type="reset"
            onClick={this.handleResetClick}
          />
        )}
      </DropdownMenu>
    )
  }

  render() {
    const {
      absence,
      absence: oldAbsence,
      absenceTypeConts,
      absenceTypeOptions,
      child,
      comments: oldComments,
      date,
      errorMessage,
      extraSessions,
      isLoading,
      isOffline,
      isSubmitting,
      present,
      signIns,
      size,
      subtitle,
    } = this.props
    const {
      comments,
      editSignIns,
      errors,
      expandType,
      isAddingExtraTimeIn,
      isEditing,
      isFutureDate,
      signInsIndex,
    } = this.state

    const hasItemExtraSessions = extraSessions && 0 < extraSessions.length

    return (
      <StyledWrapper>
        <StyledContainer>
          <RegisterItemError errorMessage={errorMessage} />
          <StyledTopContainer hasError={!!errorMessage}>
            <StyledDetailContainer>
              <RegisterItemProfile
                absence={oldAbsence}
                child={child}
                date={date}
                hasExtraSessions={hasItemExtraSessions}
                isOffline={isOffline}
                size={size}
                subtitle={subtitle}
              />
              <RegisterItemContent
                absence={oldAbsence}
                editSignIns={editSignIns}
                expandType={expandType}
                isAddingExtraTimeIn={isAddingExtraTimeIn}
                isEditing={isEditing}
                isFutureDate={isFutureDate}
                isLoading={isLoading}
                isSubmitting={isSubmitting}
                oldComments={oldComments}
                present={present}
                signIns={signIns}
                signInsIndex={signInsIndex}
                size={size}
                onAbsenceActionClick={this.handleAbsenceActionClick}
                onCommentActionClick={this.handleCommentActionClick}
                onSignIn={this.handleSignIn}
                onSignOut={this.handleSignOut}
                onTimeChange={this.handleTimeChange}
              />
            </StyledDetailContainer>
            <StyledActionsContainer>
              {this.renderActions()}
            </StyledActionsContainer>
            <StyledEditIconContainer>
              {this.renderEditButton()}
            </StyledEditIconContainer>
          </StyledTopContainer>
          <RegisterItemPanel
            absence={absence}
            absenceTypeConts={absenceTypeConts}
            absenceTypeOptions={absenceTypeOptions}
            comments={comments}
            errors={errors}
            expandType={expandType}
            hasComment={!!oldComments}
            isEditing={isEditing}
            isLoading={isSubmitting}
            oldAbsence={oldAbsence}
            onAbsenceEndDateChange={this.handleAbsenceEndDateChange}
            onAbsenceReasonChange={this.handleAbsenceReasonChange}
            onAbsenceStartDateChange={this.handleAbsenceStartDateChange}
            onAbsenceTypeChange={this.handleAbsenceTypeChange}
            onCancelClick={this.handlePanelCancelClick}
            onCommentsChange={this.handleCommentsChange}
            onSaveClick={this.handlePanelSaveClick}
          />
        </StyledContainer>
      </StyledWrapper>
    )
  }
}

const mapState = (state, { appSelectors }) => ({
  isOffline: appSelectors.getAppIsOfflineAndFFIsEnabled(state),
})

const enhance = compose(
  withAppService,
  withModalService,
  connect(mapState),
)

export default enhance(RegisterItem)
