import styled from 'styled-components'

export const StyledWrapper = styled.div`
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  margin-bottom: 5px;
`

export const StyledTimesWrapper = styled.div`
  margin-left: 3px;
`
