import React from 'react'

import { Callout, Page, Spinner } from 'components'

import i18n from 'translations'

import StaffAddForm from './components/StaffAddForm'
import ResendInvitationBanner from './components/ResendInvitationBanner'
import { StyledResendInvitationComponent } from './StaffAddStyled'

const StaffAddView = ({
  cancelLink,
  errorMessages,
  formValues,
  initialValues,
  invitation,
  isCreatingContext,
  isFetching,
  isMe,
  isMembershipAccepted,
  isMembershipArchived,
  isMyDetailsContext,
  isNurseryContext,
  isOrganizationContext,
  isPrimaryManager,
  isStaffingEnabled,
  isSubmitting,
  onArchive,
  onLoadMoreNurseries,
  onNameChange,
  onResendInvitation,
  onSubmit,
  rolesOptions,
  showNurseriesSelect,
  superAdminCreation,
  titlesOptions,
}) => {
  const renderResendInvitationBanner = () => !isMe && (
    <StyledResendInvitationComponent>
      <ResendInvitationBanner
        invitation={invitation}
        membershipIsAccepted={isMembershipAccepted}
        onClick={onResendInvitation}
      />
    </StyledResendInvitationComponent>
  )

  const renderForm = () => (
    <StaffAddForm
      cancelLink={cancelLink}
      formValues={formValues}
      initialValues={initialValues}
      isCreatingContext={isCreatingContext}
      isMe={isMe}
      isMembershipArchived={isMembershipArchived}
      isNurseryContext={isNurseryContext}
      isPrimaryManager={isPrimaryManager}
      isStaffingEnabled={isStaffingEnabled}
      isSubmitting={isSubmitting}
      rolesOptions={rolesOptions}
      showNurseriesSelect={showNurseriesSelect}
      superAdminCreation={superAdminCreation}
      titlesOptions={titlesOptions}
      onArchive={onArchive}
      onLoadMoreNurseries={onLoadMoreNurseries}
      onNameChange={onNameChange}
      onSubmit={onSubmit}
    />
  )

  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    return (
      <React.Fragment>
        {renderResendInvitationBanner()}
        {renderForm()}
      </React.Fragment>
    )
  }

  const getSectionTitle = () => {
    if (isMyDetailsContext) {
      return i18n.t('module:Staff:StaffAdd:Title:editProfile')
    }

    if (isOrganizationContext) {
      return isCreatingContext
        ? i18n.t('module:Staff:StaffAdd:Title:addOrganizationStaff')
        : i18n.t('module:Staff:StaffAdd:Title:editOrganizationStaff')
    }

    return isCreatingContext
      ? i18n.t('module:Staff:StaffAdd:Title:addStaff')
      : i18n.t('module:Staff:StaffAdd:Title:editStaff')
  }

  return (
    <Page.Section
      isLoading={isFetching}
      title={getSectionTitle()}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default StaffAddView
