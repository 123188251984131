import { FEATURE_FLAGS, ROLES } from 'constants/security'

const ChildDiscountsWrapper = ({ children }) => children

ChildDiscountsWrapper.authParams = {
  allowFinanceF3: false,
  flags: [FEATURE_FLAGS.FINANCE_AUTOMATION],
  roles: [
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.SUPER_ADMIN,
  ],
}

export default ChildDiscountsWrapper
