import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import layout from 'constants/layout'
import { NEUTRAL_COLOURS } from 'constants/colors'
import { HEADER_PINNED_EVENT, IS_HEADER_PINNED_LS } from 'services/shell/constants'

import { getBrandingColor } from 'utils/branding'
import eventBus from 'utils/eventBus'
import { typeByObject } from 'utils/typescript'

import { withShellService } from 'services/shell'
import { withModalService } from 'services/utils/modal'

import { Icon } from 'components'

import {
  StyledChevron,
  StyledContent,
  StyledCurtain,
  StyledHeader,
  StyledIcon,
  StyledMenu,
  StyledMenuContent,
  StyledMenuHeader,
  StyledTitle,
} from './DrawerStyled'

export const DRAWER_POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
}

interface DrawerProps {
  backgroundContent?: string
  backgroundHeader?: string
  closeContent?: boolean
  content: (isOpen: boolean, closeSidebar: (sidebarIsOpen: boolean) => void) => React.ReactNode
  defaultOpen?: boolean
  disableAutoOpen?: boolean
  header: () => React.ReactNode
  heightStickyFooter: number
  hidden?: boolean
  icon?: IconType
  position: typeByObject<typeof DRAWER_POSITION>
  title?: string
  widthOpen?: number
  withoutBackdrop?: boolean
}

interface DrawerState {
  isHeaderPinned: number
  sidebarIsOpen: boolean
  topSidebarPosition: number
}

class Drawer extends Component<DrawerProps, DrawerState> {
  private header: HTMLDivElement

  constructor(props) {
    super(props)

    this.state = {
      isHeaderPinned: +window.localStorage.getItem(IS_HEADER_PINNED_LS),
      sidebarIsOpen: props.defaultOpen || this.shouldSidebarBeOpen(),
      topSidebarPosition: 0,
    }
  }

  componentDidMount() {
    const { disableAutoOpen } = this.props
    this.calculateHeightOfHeader()

    if (!disableAutoOpen) {
      window.addEventListener('resize', this.openSidebarForDesktop)
    }

    eventBus.on(HEADER_PINNED_EVENT, this.onUpdateIsHeaderPinned)
    window.addEventListener('resize', this.calculateHeightOfHeader)
    window.addEventListener('scroll', this.calculateHeightOfHeader)
  }

  componentWillUnmount() {
    const { disableAutoOpen } = this.props

    if (!disableAutoOpen) {
      window.removeEventListener('resize', this.openSidebarForDesktop)
    }

    eventBus.remove(HEADER_PINNED_EVENT, this.onUpdateIsHeaderPinned)
    window.removeEventListener('resize', this.calculateHeightOfHeader)
    window.removeEventListener('scroll', this.calculateHeightOfHeader)
  }

  shouldSidebarBeOpen = () => layout.breakpointFullMenu <= window.innerWidth

  onUpdateIsHeaderPinned = () => {
    this.setState({
      isHeaderPinned: +window.localStorage.getItem(IS_HEADER_PINNED_LS),
    })
  }

  openSidebarForDesktop = () => {
    const { sidebarIsOpen } = this.state

    if (this.shouldSidebarBeOpen()) {
      document.body.style.overflow = 'auto'
    }

    if (!this.shouldSidebarBeOpen() && sidebarIsOpen) {
      document.body.style.overflow = 'hidden'
    }

    if (!sidebarIsOpen && this.shouldSidebarBeOpen()) {
      this.setState({ sidebarIsOpen: true })
    }
  }

  calculateHeightOfHeader = () => {
    setTimeout(() => {
      if (this.header) {
        const { height, top } = this.header.getBoundingClientRect()

        this.setState({ topSidebarPosition: top + height })
      }
    })
  }

  handleChangeSidebar = (sidebarIsOpen) => {
    const { withoutBackdrop } = this.props

    if (sidebarIsOpen && !withoutBackdrop && !this.shouldSidebarBeOpen()) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    this.setState({ sidebarIsOpen })
  }

  handleCloseSidebar = (sidebarIsOpen) => {
    if (!sidebarIsOpen && this.shouldSidebarBeOpen()) {
      return this.handleChangeSidebar(true)
    }

    return this.handleChangeSidebar(sidebarIsOpen)
  }

  render() {
    const {
      backgroundContent = getBrandingColor('primary-color'),
      backgroundHeader = getBrandingColor('senary-color'),
      closeContent,
      content,
      icon,
      header,
      heightStickyFooter,
      hidden,
      position,
      title,
      widthOpen = 200,
      withoutBackdrop,
    } = this.props
    const { isHeaderPinned, sidebarIsOpen, topSidebarPosition } = this.state

    const renderHeader = () => {
      if (!header) {
        return (
          <StyledHeader $isHeaderPinned={isHeaderPinned}>
            <div ref={(e) => { this.header = e }} />
          </StyledHeader>
        )
      }

      return (
        <StyledHeader $isHeaderPinned={isHeaderPinned}>
          <div ref={(e) => { this.header = e }}>
            {header()}
          </div>
        </StyledHeader>
      )
    }

    const renderContent = () => {
      let chevronPosition = sidebarIsOpen ? 'left' : 'right'
      if (position === DRAWER_POSITION.RIGHT) {
        chevronPosition = sidebarIsOpen ? 'right' : 'left'
      }

      return (
        <React.Fragment>
          {sidebarIsOpen && !withoutBackdrop && (
            <StyledCurtain
              $top={topSidebarPosition}
              onClick={() => this.handleChangeSidebar(false)}
            />
          )}
          <StyledMenu
            $closeContent={closeContent}
            $open={sidebarIsOpen}
            $position={position}
            $top={topSidebarPosition}
            $widthOpen={widthOpen}
          >
            <StyledMenuHeader
              $background={backgroundHeader}
              $open={sidebarIsOpen}
              $position={position}
              onClick={() => this.handleChangeSidebar(!sidebarIsOpen)}
            >
              {title && sidebarIsOpen && (
                <StyledTitle $position={position}>
                  {title}
                </StyledTitle>
              )}
              {icon && (
                <StyledIcon $position={position}>
                  <Icon
                    color={NEUTRAL_COLOURS.WHITE}
                    height={19}
                    icon={icon}
                  />
                </StyledIcon>
              )}
              <StyledChevron $position={position}>
                <Icon
                  color={NEUTRAL_COLOURS.WHITE}
                  height={14}
                  icon={`chevron-${chevronPosition}` as IconType}
                />
              </StyledChevron>
            </StyledMenuHeader>
            <StyledMenuContent
              $background={backgroundContent}
              $closeContent={closeContent}
              $open={sidebarIsOpen}
              $paddingBottom={heightStickyFooter}
              $position={position}
            >
              {content && content(sidebarIsOpen, this.handleCloseSidebar)}
            </StyledMenuContent>
          </StyledMenu>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        {renderHeader()}
        {!hidden && (
          <StyledContent>
            {renderContent()}
          </StyledContent>
        )}
      </React.Fragment>
    )
  }
}

const mapState = (state, { shellSelectors }) => ({
  heightStickyFooter: shellSelectors.getStickyFooterHeight(state),
})

const enhance = compose(
  withShellService,
  withModalService,
  connect(mapState),
)

export default enhance(Drawer)
