import moment from 'moment'
import _ from 'lodash'

import { createSelector } from 'reselect'

import { DAILY_DIARY_TYPE } from '../constants'

const getChildDailyDiary = (state) => state.childDailyDiary

export const getChildDailyDiaryListSelector = createSelector(
  [getChildDailyDiary],
  (state) => {
    if (!state) {
      return null
    }

    return state.list
  },
)

export const getChildDailyDiaryListDataSelector = createSelector(
  [getChildDailyDiaryListSelector],
  (state) => state.data,
)

export const sortDiaryTimelineRecords = (data) => {
  const getRecordTime = ({ record, timestamp, type }) => {
    if (DAILY_DIARY_TYPE.SLEEP === type) {
      return moment(record.recordedAt)
        .startOf('day')
        .add(record.startTime, 'milliseconds')
        .toDate()
    }

    return moment(timestamp).toDate()
  }

  const records = _.map(data, (record) => ({
    ...record,
    time: getRecordTime(record),
  }))

  return _.sortBy(records, 'time')
}

export const getSortedChildDailyDiaryListSelector = createSelector(
  [getChildDailyDiaryListDataSelector],
  (data) => sortDiaryTimelineRecords(data),
)
