import Staff from 'module/Staff/Staff'
import StaffList from 'module/Staff/StaffList'
import StaffWrapper from 'module/Staff/StaffWrapperContainer'
import StaffWorklog from 'module/Staff/StaffWorklog/StaffWorklogList'
import StaffWorklogPreview from 'module/Staff/StaffWorklog/StaffWorklogPreview'
import StaffAdd from 'module/Staff/StaffAdd'
import StaffDetails from 'module/Staff/StaffDetails'
import StaffLeaveDetails from 'module/Staff/StaffLeaveDetails'
import StaffFileUploads from 'module/Staff/StaffFileUploads'
import StaffRegisterWrapper from 'module/Staff/StaffRegisterWrapper'
import StaffRota from 'module/Staff/StaffRota'
import StaffLeave from 'module/Staff/StaffLeave'
import StaffAttendance from 'module/Staff/StaffAttendance'
import StaffContractsWrapper from 'module/Staff/StaffContracts'
import StaffContractsAdd from 'module/Staff/StaffContracts/StaffContractsAdd'
import StaffContractsList from 'module/Staff/StaffContracts/StaffContractsList'
import StaffContractsPreview from 'module/Staff/StaffContracts/StaffContractsPreview'
import NurseryRota from 'module/Organization/NurseryRota'

import i18n from 'translations'

export const ROUTE_NAMES = {
  'STAFF.ADD': 'STAFF.ADD',
  'STAFF.ATTENDANCE': 'STAFF.ATTENDANCE',
  'STAFF.INDEX': 'STAFF.INDEX',
  'STAFF.LEAVE': 'STAFF.LEAVE',
  'STAFF.LIST': 'STAFF.LIST',
  'STAFF.ORGANIZATION_NURSERY_ROTA': 'STAFF.ORGANIZATION_NURSERY_ROTA',
  'STAFF.PROFILE': 'STAFF.PROFILE',
  'STAFF.PROFILE.ABOUT': 'STAFF.PROFILE.ABOUT',
  'STAFF.PROFILE.CONTRACTS': 'STAFF.PROFILE.CONTRACTS',
  'STAFF.PROFILE.CONTRACTS.ADD': 'STAFF.PROFILE.CONTRACTS.ADD',
  'STAFF.PROFILE.CONTRACTS.EDIT': 'STAFF.PROFILE.CONTRACTS.EDIT',
  'STAFF.PROFILE.CONTRACTS.LIST': 'STAFF.PROFILE.CONTRACTS.LIST',
  'STAFF.PROFILE.CONTRACTS.PREVIEW': 'STAFF.PROFILE.CONTRACTS.PREVIEW',
  'STAFF.PROFILE.EDIT': 'STAFF.PROFILE.EDIT',
  'STAFF.PROFILE.FILES': 'STAFF.PROFILE.FILES',
  'STAFF.PROFILE.LEAVE': 'STAFF.PROFILE.LEAVE',
  'STAFF.REGISTER': 'STAFF.REGISTER',
  'STAFF.ROTA': 'STAFF.ROTA',
  'STAFF.WORKLOG': 'STAFF.WORKLOG',
  'STAFF.WORKLOG_PREVIEW': 'STAFF.WORKLOG_PREVIEW',
}

const StaffRouting = {
  childRoutes: [
    {
      component: StaffLeave,
      name: ROUTE_NAMES['STAFF.LEAVE'],
      path: 'leave',
      title: i18n.t('module:Staff:StaffLeave:title'),
    },
    {
      component: StaffRota,
      name: ROUTE_NAMES['STAFF.ROTA'],
      path: 'rota',
      title: i18n.t('module:Staff:StaffRota:title'),
    },
    {
      component: StaffAttendance,
      name: ROUTE_NAMES['STAFF.ATTENDANCE'],
      path: 'attendance',
      title: i18n.t('module:Staff:StaffAttendance:title'),
    },
    {
      childRoutes: [
        {
          component: StaffWorklogPreview,
          name: ROUTE_NAMES['STAFF.WORKLOG_PREVIEW'],
          path: ':membershipId/:startDate/:endDate/preview',
          title: i18n.t('module:Staff:StaffWorklogPreview:title'),
        },
      ],
      indexRoute: {
        component: StaffWorklog,
      },
      name: ROUTE_NAMES['STAFF.WORKLOG'],
      path: 'worklog',
      title: i18n.t('module:Staff:StaffWorklog:title'),
    },
    {
      childRoutes: [
        {
          childRoutes: [
            {
              component: StaffAdd,
              name: ROUTE_NAMES['STAFF.PROFILE.EDIT'],
              path: 'edit',
              title: i18n.t('module:Staff:StaffAdd:Title:editStaff'),
            },
            {
              component: StaffDetails,
              name: ROUTE_NAMES['STAFF.PROFILE.ABOUT'],
              path: 'about',
              title: i18n.t('module:Staff:StaffDetails:Title:staffMemberDetails'),
            },
            {
              childRoutes: [
                {
                  component: StaffContractsList,
                  name: ROUTE_NAMES['STAFF.PROFILE.CONTRACTS.LIST'],
                  path: 'list',
                  title: i18n.t('module:Staff:StaffContracts:List:title'),
                },
                {
                  component: StaffContractsAdd,
                  name: ROUTE_NAMES['STAFF.PROFILE.CONTRACTS.ADD'],
                  path: 'add',
                  title: i18n.t('module:Staff:StaffContracts:Add:title'),
                },
                {
                  component: StaffContractsAdd,
                  name: ROUTE_NAMES['STAFF.PROFILE.CONTRACTS.EDIT'],
                  path: ':contractId/edit',
                  title: i18n.t('module:Staff:StaffContracts:Edit:title'),
                },
                {
                  component: StaffContractsPreview,
                  name: ROUTE_NAMES['STAFF.PROFILE.CONTRACTS.PREVIEW'],
                  path: ':contractId/preview',
                  title: i18n.t('module:Staff:StaffContracts:Preview:title'),
                },
              ],
              component: StaffContractsWrapper,
              name: ROUTE_NAMES['STAFF.PROFILE.CONTRACTS'],
              path: 'contracts',
              title: i18n.t('module:Staff:StaffContracts:title'),
            },
            {
              component: StaffLeaveDetails,
              name: ROUTE_NAMES['STAFF.PROFILE.LEAVE'],
              path: 'leave',
              title: i18n.t('module:Staff:SubMenu:leave'),
            },
            {
              component: StaffFileUploads,
              name: ROUTE_NAMES['STAFF.PROFILE.FILES'],
              path: 'files',
              title: i18n.t('services:Files:title'),
            },
          ],
          component: Staff,
          name: ROUTE_NAMES['STAFF.PROFILE'],
          path: ':userId',
        },
      ],
      indexRoute: {
        component: StaffList,
      },
      name: ROUTE_NAMES['STAFF.LIST'],
      path: 'list',
      title: i18n.t('module:Staff:StaffLists:staffList'),
    },
    {
      component: StaffAdd,
      name: ROUTE_NAMES['STAFF.ADD'],
      path: 'add',
      title: i18n.t('module:Staff:StaffAdd:Title:addStaff'),
    },
    {
      component: StaffRegisterWrapper,
      name: ROUTE_NAMES['STAFF.REGISTER'],
      path: 'register',
      title: i18n.t('module:Staff:StaffRegister:title'),
    },
    {
      component: NurseryRota,
      name: ROUTE_NAMES['STAFF.ORGANIZATION_NURSERY_ROTA'],
      path: 'nursery-rota',
      title: i18n.t('module:Organization:NurseryRota:breadcrumbTitle'),
    },
  ],
  component: StaffWrapper,
  name: ROUTE_NAMES['STAFF.INDEX'],
  path: 'staff',
  title: i18n.t('module:Staff:StaffLists:title'),
}

StaffRouting.ROUTE_NAMES = ROUTE_NAMES

export default StaffRouting
