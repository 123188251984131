import _ from 'lodash'

export const updateCalculatedInvoice = (state, childId, payload) => {
  if (!payload) {
    return state
  }

  const childItem = _.find(state.data, { id: childId })
  const childItemIndex = _.findIndex(state.data, { id: childId })

  if (childItem) {
    const { data } = payload
    const { preview } = data
    const { invoice } = preview
    const { items, total } = invoice

    return {
      ...state,
      data: _.map(state.data, (item, index) => {
        if (index === childItemIndex) {
          return {
            ...item,
            estimatedInvoice: {
              hasItems: !!items?.length,
              total,
            },
          }
        }

        return item
      }),
    }
  }

  return state
}

export const updateChildList = (state, payload, refreshData) => {
  const newChildren = _.map(payload.data, (child) => ({ ...child, bulkGenerated: false }))

  if (!state.data || refreshData) {
    return newChildren
  }

  return [
    ...state.data,
    ...newChildren,
  ]
}

export const updatePreviousInvoices = (state, childId, payload) => {
  if (!payload) {
    return state
  }

  const childItemIndex = _.findIndex(state.data, { id: childId })

  if (0 <= childItemIndex) {
    return {
      ...state,
      data: _.map(state.data, (item, index) => {
        if (index === childItemIndex) {
          return {
            ...item,
            previousInvoices: payload.data,
          }
        }

        return item
      }),
    }
  }

  return state
}

export const getMappedResponse = (payload) => {
  const updatedResponse = _.map(payload.data, (item) => {
    const { invoice = {} } = item
    const { status = {} } = invoice

    return {
      ...item,
      bulkGenerated: true,
      generatedInvoiceType: status,
    }
  })

  return _.keyBy(updatedResponse, 'child.id')
}

export const updateBulkInvoices = (state, payload) => {
  if (!payload) {
    return state
  }

  return {
    ...state,
    response: {
      ...state.response,
      ...getMappedResponse(payload),
    },
  }
}

export const updateCalculatedInvoiceException = (state, childId, error) => {
  if (!error) {
    return state
  }

  const child = _.find(state.data, { id: childId })

  return {
    ...state,
    response: {
      ...state.response,
      [childId]: {
        child,
        exception: { error },
      },
    },
  }
}
