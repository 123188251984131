import React, { useEffect } from 'react'
import { compose } from 'recompose'

import { SelectProps } from 'components/Select/Select'
import { InfinityDropdownProps } from 'components/InfiniteDropdowns/InfiniteDropdownsModel'

import { withNurseryItemService, withNurseryItemServiceProps } from 'services/product/nurseryItem'

import { Select } from 'components'

import i18n from 'translations'

// eslint-disable-next-line import-newlines/enforce, max-len
import withInfiniteDropdownHelpers, { withInfiniteDropdownHelpersProps } from '../../legacy/InfiniteDropdowns/withInfiniteDropdown'

const GROUPS = {
  read: [
    'nurseryItemProduct',
    'nurseryItemProduct.priceChanges',
    'productPrice',
    'productPriceChange',
    'productPriceChange.prices',
  ],
}

export type NurseryItemsDropdownProps = withInfiniteDropdownHelpersProps
  & withNurseryItemServiceProps
  & SelectProps
  & InfinityDropdownProps

const NurseryItemsV3Dropdown: React.FC<NurseryItemsDropdownProps> = ({
  extraFields = [],
  infiniteDropdownHelpers,
  nurseryItemActions,
  onChange,
  value,
  ...rest
}) => {
  useEffect(() => {
    infiniteDropdownHelpers.handleComponentDidMount({
      action: () => nurseryItemActions.get({
        onFailed: () => onChange(null),
        onSuccess: infiniteDropdownHelpers.handleComponentDidMountOnSuccess,
        onlyData: true,
        params: [(value as any)?.value || value],
      }),
      value,
    })
  }, [])

  const listAction = ({ newPage, newSearchPhrase, ...others }) => {
    const criteria = [
      {
        field: 'archived',
        value: 0,
      },
    ]

    if (newSearchPhrase) {
      criteria.push({
        field: 'name',
        value: newSearchPhrase,
      })
    }

    return nurseryItemActions.dropdownList({
      onSuccess: infiniteDropdownHelpers.handleLoadMoreElementsOnSuccessV2({
        extraFields,
        newPage,
        newSearchPhrase,
        ...others,
      }),
      params: {
        criteria,
        groups: GROUPS,
        page: newPage,
      },
    })
  }

  return (
    <Select
      {...infiniteDropdownHelpers.getSelectRestParams({ onChange, value, ...rest })}
      loadOptions={infiniteDropdownHelpers.handleLoadMoreElementsV2({ listAction })}
      placeholder={i18n.t('components:InfiniteDropdowns:NurseryItemsV3:placeholder')}
    />
  )
}

const enhance = compose(
  withNurseryItemService,
  withInfiniteDropdownHelpers,
)

export default enhance(NurseryItemsV3Dropdown)
