import _ from 'lodash'

import React from 'react'

import { Avatar, BlockedIcon, InfiniteScroll, ModalBox, Spinner, Typography } from 'components'

import i18n from 'translations'

import {
  StyledChild,
  StyledContent,
  StyledIcons,
  StyledSubTitle,
  StyledWrapper,
} from './ActivitiesStatisticsModalStyled'

const ActivitiesStatisticsModalView = ({
  isFetching,
  onCloseClick,
  onPageChange,
  page,
  pageCount,
  records,
}) => {
  const renderItem = ({
    child: { firstName, id, information, photo, surname },
    childRegisterDiaryRecordStatistics: { activityRecordsCount },
  }) => (
    <StyledChild
      key={id}
    >
      <Avatar
        direction="vertical"
        initials={[firstName, surname]}
        src={photo}
        subTitle={(
          <StyledSubTitle>
            <Typography
              margin="5px 0 10px"
              bold
              primary
            >
              {activityRecordsCount}
            </Typography>
            <StyledIcons>
              {!information?.canBePhotographed && (
                <BlockedIcon
                  color="#575756"
                  icon="camera"
                  title={i18n.t('global:noPermissionInPhotos')}
                />
              )}
              {!information?.canBeTaggedInGroupPhotos && (
                <BlockedIcon
                  color="#575756"
                  icon="group"
                  title={i18n.t('global:noPermissionInGroupPhotos')}
                />
              )}
            </StyledIcons>
          </StyledSubTitle>
        )}
        title={`${firstName} ${surname}`}
      />
    </StyledChild>
  )

  const renderContent = () => {
    if (isFetching && 1 === page) {
      return (
        <Spinner />
      )
    }

    return (
      <StyledContent
        id="scrollableDiv"
      >
        <InfiniteScroll
          dataLength={records ? records.length : 0}
          hasMore={page < pageCount}
          next={() => onPageChange((+page) + 1)}
          scrollableTarget="scrollableDiv"
        >
          <StyledWrapper>
            {_.map(records, renderItem)}
          </StyledWrapper>
        </InfiniteScroll>
      </StyledContent>
    )
  }

  return (
    <ModalBox
      title={i18n.t('module:Modals:ActivitiesStatistics:title')}
      autoHeight
      onCloseClick={onCloseClick}
    >
      {renderContent()}
    </ModalBox>
  )
}

export default ActivitiesStatisticsModalView
