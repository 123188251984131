import { createSelector } from 'reselect'

const getChildFrameworkProgress = (state) => state.childFrameworkProgress

export const getChildFrameworkProgressHistoryMontessoriState = createSelector(
  [getChildFrameworkProgress],
  (state) => state.historyMontessori,
)

export const getChildFrameworkProgressHistoryMontessoriDataState = createSelector(
  [getChildFrameworkProgressHistoryMontessoriState],
  (state) => state.data,
)
