import styled from 'styled-components'

import colors, { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledSwitchContainer = styled.div`
  position: relative;
  width: 100px;
  height: 40px;
  border-radius: 20px;
  border: .5px solid #CCC;
  transition: background-color 100ms ease-out;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 5px;
`

export const StyledToggleContainer = styled.div`
  position: absolute;
  width: 47px;
  height: 36px;
  background-color: ${({ value }) => (value ? colors.finance.credit : colors.finance.debit)};
  border-radius: 18px;
  transition: transform 200ms ease-in-out;
  transform: ${({ value }) => (value ? 'translateX(50px)' : 'translateX(0px)')};
  z-index: 3;
  border: 1px solid ${({ value }) => (value ? colors.finance.credit : colors.finance.debit)};
  left: 1px;
  top: 1px;
`

export const StyledDrCrContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  font-size: 13px;
  justify-content: space-between;
  align-items: center;
  z-index: 4;
`

export const StyledDrCrLabel = styled.div`
  ${({ isActive }) => isActive && `color: ${NEUTRAL_COLOURS.SILVER};`}
  display: flex;
  flex: 1;
  justify-content: center;
`
