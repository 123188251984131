import { GET_CHILD_SUCCESS } from 'services/legacy/child/single/constants'
import { LIST_CHILD_SESSIONS_SETUP_SUCCESS } from 'services/legacy/childSessions/list/actions'
import { CREATE_CHILD_SESSION_SUCCESS } from 'services/legacy/childSessions/single/actions'
import { LIST_CHILD_MOVES_SETUP_SUCCESS } from 'services/legacy/childMoves/list/constants'
import { UPDATE_CHILD_MOVE_SUCCESS } from 'services/legacy/childMoves/single/constants'

const initialState = {
  contacts: 0,
  roomMoves: 0,
  sessions: 0,
}

export default (state = initialState, { body, payload, type }) => {
  switch (type) {
    case GET_CHILD_SUCCESS: {
      const { data } = payload
      const { carerChildRelations } = data || {}

      return {
        ...state,
        contacts: carerChildRelations?.length,
      }
    }

    case LIST_CHILD_SESSIONS_SETUP_SUCCESS: {
      const { meta } = payload
      const { total_results: totalResults } = meta

      return {
        ...state,
        sessions: totalResults,
      }
    }

    case CREATE_CHILD_SESSION_SUCCESS: {
      return {
        ...state,
        sessions: state.sessions + 1,
      }
    }

    case LIST_CHILD_MOVES_SETUP_SUCCESS: {
      const { meta } = payload
      const { total_results: totalResults } = meta

      return {
        ...state,
        roomMoves: totalResults,
      }
    }

    case UPDATE_CHILD_MOVE_SUCCESS: {
      const { confirmedAt } = body || {}

      if (confirmedAt === undefined) {
        return state
      }

      return {
        ...state,
        roomMoves: confirmedAt ? state.roomMoves + 1 : state.roomMoves - 1,
      }
    }

    default:
      return state
  }
}
