import _ from 'lodash'
import moment from 'moment'
import { v4 } from 'uuid'

import { toFloat } from 'utils/data'

import nurseriesConstants from 'services/nurseries/constants'
import { FUNDING_LINE_ITEM_TYPES } from 'services/nurseryFunding/constants'
import { ChildProductTypes } from 'services/booking/childProducts/constants'

import { getNumberWithPrefix } from 'services/nurseries/single/selectors/settings'

import constants from '../constants'
import { getTotal } from './selectors'
import { getDueDate } from '../single/selectors'

const { INVOICE_PREVIEW_ITEM_TYPE, INVOICE_PREVIEW_UNIT_TYPES, LINE_ITEM_TYPES } = constants
const { INVOICE_LEVEL_DETAIL_DISPLAY } = nurseriesConstants
const { GROUPED_ITEM_TYPES_WITHOUT_COST_BREAKDOWN } = INVOICE_LEVEL_DETAIL_DISPLAY

const addUpdateHeader = (existingPreviewItems, newPreviewItem, totalDifference) => {
  const headerElement = _.find(existingPreviewItems, {
    label: newPreviewItem.label,
    type: INVOICE_PREVIEW_ITEM_TYPE.HEADER,
  })

  if (headerElement) {
    headerElement.total += totalDifference

    return _.map(existingPreviewItems, (previewItem) => {
      if (INVOICE_PREVIEW_ITEM_TYPE.HEADER === previewItem.type && previewItem.label === newPreviewItem.label) {
        return headerElement
      }

      return previewItem
    })
  }

  const newHeaderElement = {
    label: newPreviewItem.label,
    total: newPreviewItem.invoiceItem.total,
    type: INVOICE_PREVIEW_ITEM_TYPE.HEADER,
    unit: newPreviewItem.unit,
    visible: true,
  }

  return [...existingPreviewItems, newHeaderElement]
}

const addNewPreviewItem = (type, existingPreviewItems, newPreviewItem, totalDifference) => {
  if (type === GROUPED_ITEM_TYPES_WITHOUT_COST_BREAKDOWN) {
    const newItems = addUpdateHeader(existingPreviewItems, newPreviewItem, totalDifference)

    const lastIndex = _.findLastIndex(newItems, { label: newPreviewItem.label }) + 1

    newItems.splice(lastIndex, 0, newPreviewItem)

    return newItems
  }

  return [...existingPreviewItems, newPreviewItem]
}

const removePreviewItem = (type, existingPreviewItems, key) => {
  if (type === GROUPED_ITEM_TYPES_WITHOUT_COST_BREAKDOWN) {
    const previewItem = _.find(
      existingPreviewItems,
      ({ invoiceItem, type: previewType }) => (
        INVOICE_PREVIEW_ITEM_TYPE.ITEM === previewType
        && (invoiceItem?.id?.toString() === key || invoiceItem?.uid === key)
      ),
    )

    const newItems = addUpdateHeader(existingPreviewItems, previewItem, -previewItem.invoiceItem.total)

    return _.filter(
      newItems,
      ({ invoiceItem, total, type: previewType }) => (
        !(
          invoiceItem?.id?.toString() === key
          || invoiceItem?.uid === key
          || (INVOICE_PREVIEW_ITEM_TYPE.HEADER === previewType && 0 === total)
        )
      ),
    )
  }

  return _.filter(
    existingPreviewItems,
    ({ invoiceItem }) => !(invoiceItem?.id?.toString() === key || invoiceItem?.uid === key),
  )
}

const updateStandardItems = (items, key, newItem) => _.map(
  items,
  (invoiceItem) => (
    invoiceItem?.id?.toString() === key || invoiceItem?.uid === key
      ? newItem
      : invoiceItem
  ),
)

const updatePreviewItem = (
  type,
  existingPreviewItems,
  updatedPreviewItem,
  totalDifference,
  key,
  oldTotal,
  isNotOriginalType,
) => {
  if (type === GROUPED_ITEM_TYPES_WITHOUT_COST_BREAKDOWN) {
    if (isNotOriginalType) {
      const newItems = removePreviewItem(type, existingPreviewItems, key, oldTotal)

      return addNewPreviewItem(type, newItems, updatedPreviewItem, updatedPreviewItem.invoiceItem.total)
    }

    const newItems = addUpdateHeader(existingPreviewItems, updatedPreviewItem, totalDifference)

    return _.map(
      newItems,
      (previewItem) => (
        previewItem?.invoiceItem?.id?.toString() === key || previewItem?.invoiceItem?.uid === key
          ? updatedPreviewItem
          : previewItem
      ),
    )
  }

  return _.map(
    existingPreviewItems,
    (previewItem) => (
      previewItem.invoiceItem.id?.toString() === key || previewItem.invoiceItem.uid === key
        ? updatedPreviewItem
        : previewItem
    ),
  )
}

export const updateItem = (state, item, totalDifference, oldTotal, isNotOriginalType) => {
  const {
    childExtraItemProjections,
    childExtraSession,
    childFunding,
    deposit,
    id,
    name,
    quantity,
    total,
    type,
    uid,
    unitPrice,
  } = item
  const getName = () => {
    if (childExtraSession) {
      return childExtraSession.label
    }

    if (childFunding) {
      return childFunding.label
    }

    if (deposit) {
      return deposit.label
    }

    return name
  }

  const getUnit = () => {
    if (FUNDING_LINE_ITEM_TYPES.NUMBER_OF_HOURS === childFunding?.fundingLineItemDisplay) {
      return INVOICE_PREVIEW_UNIT_TYPES.HOURS
    }

    return INVOICE_PREVIEW_UNIT_TYPES.CURRENCY
  }

  const getuid = () => {
    if (id) {
      return undefined
    }

    if (uid) {
      return uid
    }

    return v4()
  }

  const getUnitPrice = () => {
    if (LINE_ITEM_TYPES.DEPOSIT === type.value) {
      return +total
    }

    if (LINE_ITEM_TYPES.REFUND_DEPOSIT === type.value) {
      return -total
    }

    if (!unitPrice) {
      return undefined
    }

    if (LINE_ITEM_TYPES.FUNDING === type.value) {
      return -unitPrice
    }

    return +unitPrice
  }

  const getPreviewQuantity = () => {
    if (
      !quantity
      || LINE_ITEM_TYPES.FUNDING === type.value
      || LINE_ITEM_TYPES.DISCOUNT === type.value
    ) {
      return undefined
    }

    return +quantity
  }

  const getInvoiceQuantity = () => {
    if (LINE_ITEM_TYPES.DEPOSIT === type.value
      || LINE_ITEM_TYPES.REFUND_DEPOSIT === type.value) {
      return 1
    }

    return quantity ? +quantity : undefined
  }

  const getPreviewTotal = (newTotal, newUnit, newInvoiceQuantity) => {
    if (LINE_ITEM_TYPES.FUNDING === type.value && INVOICE_PREVIEW_UNIT_TYPES.HOURS === newUnit) {
      return newInvoiceQuantity
    }

    return newTotal
  }

  const key = id?.toString() || uid
  const isGrouped = state.data.type === GROUPED_ITEM_TYPES_WITHOUT_COST_BREAKDOWN
  const newuid = getuid() // to identify the new item
  const newName = getName()
  const newUnit = getUnit()
  const newInvoiceQuantity = getInvoiceQuantity()
  const newPreviewQuantity = getPreviewQuantity()
  const newTotal = getTotal(total, type)
  const newPreviewTotal = getPreviewTotal(newTotal, newUnit, newInvoiceQuantity)
  const newUnitPrice = getUnitPrice(unitPrice, type)

  const newItem = {
    ...item,
    childExtraItemProjections: childExtraItemProjections?.length
      ? _.map(childExtraItemProjections, ({ value }) => ({ id: value }))
      : undefined,
    childExtraSession: childExtraSession
      ? { id: childExtraSession.value }
      : undefined,
    childFunding: childFunding
      ? { id: childFunding.value, type: childFunding.type }
      : undefined,
    deposit: deposit
      ? {
        amount: deposit.amount,
        description: deposit.label,
        id: deposit.value,
      }
      : undefined,
    name: newName,
    quantity: newInvoiceQuantity,
    total: newTotal,
    type: type.value,
    uid: newuid,
    unitPrice: newUnitPrice,
  }

  const newPreviewItem = {
    invoiceItem: {
      id,
      total: newTotal,
      type: type.value,
      uid: newuid,
    },
    label: type.previewType,
    name: newName,
    quantity: newPreviewQuantity,
    total: isGrouped ? null : newPreviewTotal,
    type: INVOICE_PREVIEW_ITEM_TYPE.ITEM,
    unit: newUnit,
    unitPrice: !childFunding ? newUnitPrice : undefined,
    visible: true,
  }

  if (key) {
    return {
      ...state,
      data: {
        ...state.data,
        invoice: {
          ...state.data.invoice,
          items: updateStandardItems(state.data.invoice.items, key, newItem),
          total: toFloat(state.data.invoice.total) + totalDifference,
        },
        items: updatePreviewItem(
          state.data.type,
          state.data.items,
          newPreviewItem,
          totalDifference,
          key,
          oldTotal,
          isNotOriginalType,
        ),
      },
    }
  }

  return {
    ...state,
    data: {
      ...state.data,
      invoice: {
        ...state.data.invoice,
        items: [...state.data.invoice.items, newItem],
        total: (toFloat(state.data.invoice.total) || 0) + (totalDifference || 0),
      },
      items: addNewPreviewItem(state.data.type, state.data.items, newPreviewItem, totalDifference),
    },
  }
}

export const updateItemV3 = (state, item, totalDifference, oldTotal, isNotOriginalType) => {
  const {
    childExtraItemProjections,
    childFunding,
    customExtraItemChildProduct,
    customExtraSessionChildProduct,
    customOneOffDiscountChildProduct,
    customOneOffFundingChildProduct,
    deposit,
    id,
    name,
    quantity,
    total,
    type,
    uid,
    unitPrice,
  } = item

  const getName = () => {
    if (customExtraSessionChildProduct) {
      return customExtraSessionChildProduct.label
    }

    if (customExtraItemChildProduct) {
      return customExtraItemChildProduct.label
    }

    if (customOneOffDiscountChildProduct) {
      return customOneOffDiscountChildProduct.label
    }

    if (customOneOffFundingChildProduct) {
      return customOneOffFundingChildProduct.label
    }

    if (childFunding) {
      return childFunding.label
    }

    if (deposit) {
      return deposit.label
    }

    return name
  }

  const getUnit = () => {
    if (FUNDING_LINE_ITEM_TYPES.NUMBER_OF_HOURS === childFunding?.fundingLineItemDisplay) {
      return INVOICE_PREVIEW_UNIT_TYPES.HOURS
    }

    return INVOICE_PREVIEW_UNIT_TYPES.CURRENCY
  }

  const getuid = () => {
    if (id) {
      return undefined
    }

    if (uid) {
      return uid
    }

    return v4()
  }

  const getUnitPrice = () => {
    if (LINE_ITEM_TYPES.DEPOSIT === type.value) {
      return +total
    }

    if (LINE_ITEM_TYPES.REFUND_DEPOSIT === type.value) {
      return -total
    }

    if (!unitPrice) {
      return undefined
    }

    if (LINE_ITEM_TYPES.FUNDING === type.value) {
      return -unitPrice
    }

    return +unitPrice
  }

  const getPreviewQuantity = () => {
    if (
      !quantity
      || LINE_ITEM_TYPES.FUNDING === type.value
      || LINE_ITEM_TYPES.DISCOUNT === type.value
    ) {
      return undefined
    }

    return +quantity
  }

  const getInvoiceQuantity = () => {
    if (LINE_ITEM_TYPES.DEPOSIT === type.value
      || LINE_ITEM_TYPES.REFUND_DEPOSIT === type.value) {
      return 1
    }

    return quantity ? +quantity : undefined
  }

  const getPreviewTotal = (newTotal, newUnit, newInvoiceQuantity) => {
    if (LINE_ITEM_TYPES.FUNDING === type.value && INVOICE_PREVIEW_UNIT_TYPES.HOURS === newUnit) {
      return newInvoiceQuantity
    }

    return newTotal
  }

  const getChildProduct = () => {
    if (customExtraSessionChildProduct) {
      return {
        id: customExtraSessionChildProduct?.value,
        type: customExtraSessionChildProduct?.type,
      }
    }

    if (customExtraItemChildProduct) {
      return {
        id: customExtraItemChildProduct?.value,
        type: customExtraItemChildProduct?.type,
      }
    }

    if (customOneOffDiscountChildProduct) {
      return {
        id: customOneOffDiscountChildProduct?.value,
        type: customOneOffDiscountChildProduct?.type,
      }
    }

    if (customOneOffFundingChildProduct) {
      return {
        id: customOneOffFundingChildProduct?.value,
        type: customOneOffFundingChildProduct?.type,
      }
    }

    return undefined
  }

  const key = id?.toString() || uid
  const isGrouped = state.data.type === GROUPED_ITEM_TYPES_WITHOUT_COST_BREAKDOWN
  const newuid = getuid() // to identify the new item
  const newName = getName()
  const newUnit = getUnit()
  const newInvoiceQuantity = getInvoiceQuantity()
  const newPreviewQuantity = getPreviewQuantity()
  const newTotal = getTotal(total, type)
  const newPreviewTotal = getPreviewTotal(newTotal, newUnit, newInvoiceQuantity)
  const newUnitPrice = getUnitPrice(unitPrice, type)

  const newItem = {
    ...item,
    childExtraItemProjections: childExtraItemProjections?.length
      ? _.map(childExtraItemProjections, ({ value }) => ({ id: value }))
      : undefined,
    // childExtraSession: childExtraSession
    //   ? { id: childExtraSession.value }
    //   : undefined,
    childFunding: childFunding
      ? { id: childFunding.value, type: childFunding.type }
      : undefined,
    childProduct: getChildProduct(),
    deposit: deposit
      ? {
        amount: deposit.amount,
        description: deposit.label,
        id: deposit.value,
      }
      : undefined,
    name: newName,
    quantity: newInvoiceQuantity,
    total: newTotal,
    type: ChildProductTypes.ONE_OFF_NO_DEDUCT_FUNDING === customOneOffFundingChildProduct?.type
      ? LINE_ITEM_TYPES.ONE_OFF_NO_DEDUCT_FUNDING
      : type.value,
    uid: newuid,
    unitPrice: newUnitPrice,
  }

  const newPreviewItem = {
    invoiceItem: {
      id,
      total: newTotal,
      type: ChildProductTypes.ONE_OFF_NO_DEDUCT_FUNDING === customOneOffFundingChildProduct?.type
        ? LINE_ITEM_TYPES.ONE_OFF_NO_DEDUCT_FUNDING
        : type.value,
      uid: newuid,
    },
    label: type.previewType,
    name: newName,
    quantity: newPreviewQuantity,
    total: isGrouped ? null : newPreviewTotal,
    type: INVOICE_PREVIEW_ITEM_TYPE.ITEM,
    unit: newUnit,
    unitPrice: !childFunding ? newUnitPrice : undefined,
    visible: true,
  }

  if (key) {
    return {
      ...state,
      data: {
        ...state.data,
        invoice: {
          ...state.data.invoice,
          items: updateStandardItems(state.data.invoice.items, key, newItem),
          total: toFloat(state.data.invoice.total) + totalDifference,
        },
        items: updatePreviewItem(
          state.data.type,
          state.data.items,
          newPreviewItem,
          totalDifference,
          key,
          oldTotal,
          isNotOriginalType,
        ),
      },
    }
  }

  return {
    ...state,
    data: {
      ...state.data,
      invoice: {
        ...state.data.invoice,
        items: [...state.data.invoice.items, newItem],
        total: (toFloat(state.data.invoice.total) || 0) + (totalDifference || 0),
      },
      items: addNewPreviewItem(state.data.type, state.data.items, newPreviewItem, totalDifference),
    },
  }
}

export const removeItem = (state, key, totalDifference) => ({
  ...state,
  data: {
    ...state.data,
    invoice: {
      ...state.data.invoice,
      items: _.filter(state.data.invoice.items, ({ id, uid }) => !(id?.toString() === key || uid === key)),
      total: toFloat(state.data.invoice.total) + totalDifference,
    },
    items: removePreviewItem(state.data.type, state.data.items, key, totalDifference),
  },
})

export const updateAutoCalculatedItems = (state, invoicePreview) => {
  const { invoice, items: invoicePreviewItems } = invoicePreview
  const { items } = invoice

  const newInvoiceItems = _.map(items, (item) => ({ ...item, uid: item.uid?.toString() }))

  const newInvoicePreviewItems = _.map(invoicePreviewItems, (item) => ({
    ...item,
    invoiceItem: {
      ...item.invoiceItem,
      uid: item.invoiceItem?.uid?.toString(),
    },
  }))

  return {
    ...state,
    data: {
      ...invoicePreview,
      invoice: {
        ...invoice,
        items: newInvoiceItems,
        status: undefined,
      },
      items: newInvoicePreviewItems,
    },
  }
}

export const updateRepeatInvoice = (state, payload) => {
  const { dueDate, invoiceNumber, invoiceNumberPrefix } = payload.defaultValues

  const ignoreItemTypes = [
    LINE_ITEM_TYPES.EXTRA_ITEM,
    LINE_ITEM_TYPES.EXTRA_ITEM_CHILD_PRODUCT,
    LINE_ITEM_TYPES.EXTRA_SESSION,
    LINE_ITEM_TYPES.EXTRA_SESSION_CHILD_PRODUCT,
  ]

  const acceptableItems = _.filter(payload.data.items, ({ type }) => !ignoreItemTypes.includes(type))
  const acceptableInvoiceItems = _.filter(payload.data.invoice.items, ({ type }) => !ignoreItemTypes.includes(type))

  return {
    ...state,
    data: {
      ...payload.data,
      invoice: {
        ...payload.data.invoice,
        dueDate: getDueDate({ dueDays: dueDate, issueDate: new Date() }),
        endDate: undefined,
        id: undefined,
        invoicePeriod: undefined,
        issueDate: new Date(),
        items: _.map(acceptableInvoiceItems, (item) => ({
          ...item,
          id: undefined,
          subItems: item.subItems ? _.map(item.subItems, (subItem) => ({ ...subItem, id: null })) : undefined,
          uid: item.id.toString(),
        })),
        name: undefined,
        number: getNumberWithPrefix(invoiceNumberPrefix, invoiceNumber),
        startDate: undefined,
      },
      items: _.map(acceptableItems, (item) => ({
        ...item,
        invoiceItem: {
          ...item.invoiceItem,
          id: undefined,
          uid: item.invoiceItem?.id?.toString(),
        },
      })),
    },
    isFetching: false,
  }
}

export const setInitialValues = (initialState, invoiceSettings) => {
  const { dueDate, invoiceLevelDetailDisplay, invoiceNumber, invoiceNumberPrefix } = invoiceSettings

  return {
    ...initialState,
    data: {
      invoice: {
        dueDate: getDueDate({ dueDays: dueDate, issueDate: new Date() }),
        issueDate: new Date(),
        items: [],
        number: getNumberWithPrefix(invoiceNumberPrefix, invoiceNumber),
      },
      items: [],
      type: invoiceLevelDetailDisplay,
    },
  }
}

export const findDropdownOption = (item, options) => {
  if (!item || !options || !options.length) {
    return undefined
  }

  return _.find(options, { value: item.id })
}

export const getChildExtraItemProjections = (
  childExtraItemProjections,
  childExtraItemProjectionsOptions,
  isReadOnly,
  name,
) => {
  if (isReadOnly && childExtraItemProjections) {
    return _.map(childExtraItemProjections, ({ date, id }) => ({
      label: `${moment(date).format('DD/MM/YYYY')} - ${name}`,
      value: id,
    }))
  }

  return _.map(childExtraItemProjections, (item) => findDropdownOption(item, childExtraItemProjectionsOptions) || item)
}

export const getFundingLineItemDisplaySettings = (reference) => {
  if (
    !reference
    || !reference.funding
    || !reference.funding.settings
    || !reference.funding.settings.invoiceLineItemDisplay
  ) {
    return null
  }

  return reference.funding.settings.invoiceLineItemDisplay
}

export const getChildExtraSessionDropdownValue = (childExtraSession, adhocSessionOptions, isReadOnly, name) => {
  if (isReadOnly && childExtraSession) {
    return {
      id: childExtraSession?.id,
      label: name,
    }
  }

  return findDropdownOption(childExtraSession, adhocSessionOptions) || childExtraSession
}

export const getChildFundingDropdownValue = (childFunding, fundingOptions, isReadOnly, name) => {
  if (isReadOnly && childFunding) {
    return {
      id: childFunding?.id,
      label: name,
    }
  }

  return findDropdownOption(childFunding, fundingOptions) || childFunding
}
