import i18n from 'translations'

import MonthlyIncomeWidgetContainer from './MonthlyIncomeWidgetContainer'

export const WIDGET_NAME = {
  component: MonthlyIncomeWidgetContainer,
  id: 'MONTHLY_INCOME_WIDGET',
  label: i18n.t('module:Dashboard:Widgets:MonthlyIncomeWidget:titleInSettings'),
}

export default MonthlyIncomeWidgetContainer
