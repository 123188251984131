import _ from 'lodash'
import moment from 'moment'
import { flatten, nest } from 'utils/flatnest'
import { compose } from 'recompose'

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'
import { getYearOptions } from 'utils/date'

import { withAppService } from 'services/app'
import { withRouterUtils } from 'services/utils/router'
import { withPaginationUtils } from 'services/utils/pagination'
import { withModalService } from 'services/utils/modal'
import { withInvoicesForecastService } from 'services/legacy/invoices'
import { withNurseriesService } from 'services/nurseries'
import { getForecastingExportPayload } from 'services/legacy/child/helpers'
import { withRouter } from 'services/router'

import i18n from 'translations'

import ForecastingView from './ForecastingView'

const CHART_GROUPS = {
  read: [
    'forecastStatistics.forecast',
    'forecastStatistics.invoices',
    'statistics.invoice.items',
    'statistics.invoice.income',
  ],
}
const TABLE_GROUPS = {
  read: [
    'nurseryMonthlyForecastStatistics',
    'forecastStatistics',
    'forecastStatistics.forecast',
    'forecastStatistics.invoices',
    'statistics.invoice.summary',
    'statistics.invoice.items',
    'statistics.invoice.income',
  ],
}

class ForecastingContainer extends Component {
  constructor(props) {
    super(props)

    const { location, paginationUtils } = props
    const { query = {} } = location
    const { year: queryYear } = nest(query)
    const { setPageLocationQuery } = paginationUtils

    const yearsOptions = getYearOptions(1)

    this.state = {
      year: this.getYearOption(queryYear, yearsOptions) || this.getYearOption(moment().format('YYYY'), yearsOptions),
      yearsOptions,
    }

    setPageLocationQuery(false)
  }

  getYearOption = (value, yearsOptions) => (value ? _.find(yearsOptions, { value }) : undefined)

  componentDidMount() {
    this.fetchForecast()
  }

  componentWillUnmount() {
    const { invoicesForecastActions, nurseriesActions } = this.props

    invoicesForecastActions.clearInvoicesForecast()
    nurseriesActions.clear()
  }

  fetchForecast = (forecastParams = {}) => {
    const { mergeChildren = true, withoutChartData = false } = forecastParams
    const {
      invoicesForecastActions,
      invoicesForecastSelectors,
      nurseriesActions,
      nurseriesSelectors,
      paginationUtils,
      setLocationQuery,
    } = this.props
    const { year } = this.state

    const { dateRange, value } = year

    setLocationQuery(flatten({ year: value }))

    if (!withoutChartData) {
      const chartCriteria = invoicesForecastSelectors.getCriteria({ date: dateRange })

      const chartApiParams = {
        criteria: chartCriteria,
        groups: CHART_GROUPS,
      }

      invoicesForecastActions.getForecast(chartApiParams)
    }

    const tableCriteria = nurseriesSelectors.getForecastCriteria({ date: dateRange })
    const { page } = paginationUtils

    const tableApiParams = {
      criteria: tableCriteria,
      groups: TABLE_GROUPS,
      page,
    }

    nurseriesActions.list({
      params: tableApiParams,
      recurrency: mergeChildren,
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(() => this.fetchForecast({ withoutChartData: true }))(page)
  }

  handleYearChange = (year) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState({ year }, () => onPageChange(() => this.fetchForecast({ mergeChildren: false }))(1))
  }

  handleExportModalClick = (body, onSuccessCb) => {
    const { nurseriesActions } = this.props
    const { year } = this.state

    const { dateRange } = year
    const { criteria } = body

    const recipients = _.find(criteria, { field: 'membership' })

    const payload = getForecastingExportPayload({
      endDate: dateRange.before,
      recipients,
      startDate: dateRange.after,
    })

    nurseriesActions.sendForecastReport(payload, onSuccessCb)
  }

  handleSendReport = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.EXPORT_REPORT, {
      onSendReport: this.handleExportModalClick,
      title: i18n.t('module:Organization:Reports:Forecasting:sendPopupTitle'),
      userLabel: i18n.t('module:Modals:ExportReportModal:User:Label:export'),
    })
  }

  render() {
    const {
      chart,
      forecastTableData,
      isFetchingChartData,
      isFetchingTableData,
      isFinanceV3Enabled,
      nurseriesListState,
      paginationUtils,
      statisticsData,
    } = this.props
    const { year, yearsOptions } = this.state

    const { getPageCount, page } = paginationUtils

    const pageCount = getPageCount(nurseriesListState.meta.total_results)

    return (
      <ForecastingView
        chart={chart}
        forecastData={forecastTableData}
        isFetchingChartData={isFetchingChartData}
        isFetchingTableData={isFetchingTableData}
        isFinanceV3Enabled={isFinanceV3Enabled}
        page={page}
        pageCount={pageCount}
        statisticsData={statisticsData}
        year={year}
        yearsOptions={yearsOptions}
        onPageChange={this.handlePageChange}
        onSendReport={this.handleSendReport}
        onYearChange={this.handleYearChange}
      />
    )
  }
}

ForecastingContainer.authParams = {
  flags: [FEATURE_FLAGS.FINANCE_AUTOMATION],
  organizationContext: true,
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
  ],
}

const mapState = (state, {
  appSelectors,
  invoicesForecastSelectors,
  invoicesForecastState,
  nurseriesListState,
  nurseriesSelectors,
}) => ({
  chart: invoicesForecastSelectors.getChartData(state),
  forecastTableData: nurseriesSelectors.getForecastingReportTableDataSelector(state),
  isFetchingChartData: appSelectors.getIsFetching(invoicesForecastState),
  isFetchingTableData: appSelectors.getIsFetching(nurseriesListState),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
  statisticsData: invoicesForecastSelectors.getForecastingReportTableDataSelector(state),
})

ForecastingContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
  ],
}

const enhance = compose(
  withAppService,
  withNurseriesService,
  withInvoicesForecastService,
  withPaginationUtils,
  withModalService,
  withRouterUtils,
  withRouter,
  connect(mapState),
)

export default enhance(ForecastingContainer)
