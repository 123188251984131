import {
  CLEAR_CHILDREN_SESSION,
  LIST_CHILDREN_SESSION,
  LIST_CHILDREN_SESSION_FAILED,
  LIST_CHILDREN_SESSION_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ childrenSessionsApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params } = options

  dispatch({ type: LIST_CHILDREN_SESSION })

  childrenSessionsApiClient.list(params).then(
    (response) => {
      dispatch({
        payload: response,
        type: LIST_CHILDREN_SESSION_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: LIST_CHILDREN_SESSION_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const clear = () => ({
  type: CLEAR_CHILDREN_SESSION,
})
