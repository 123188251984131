import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledWrapper = styled.div`
  background: ${getBrandingColor('tertiary-color')};
  color: ${NEUTRAL_COLOURS.WHITE};
  padding: 20px;
  border-radius: 22px;
  max-width: 600px;
  min-width: 250px;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
  
  p {
    margin: 0;
  }

  p + p {
    margin-top: 10px;
  }
`

export const StyledTopIcon = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
