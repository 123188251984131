import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { Button, Form, Space } from 'components'

import i18n from 'translations'

export const RESET_PASSWORD_FORM = 'RESET_PASSWORD_FORM'

interface ResetPasswordFormProps {
  isFetching?: boolean
  onSubmit: (values: any) => void
}

type ResetPasswordFormFullProps = InjectedFormProps<{}, ResetPasswordFormProps> & ResetPasswordFormProps

export interface ResetPasswordFormValues {
  identifier: string
  password: string
}

const ResetPasswordForm: React.FC<ResetPasswordFormFullProps> = ({
  handleSubmit,
  isFetching,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Space space="20px" />
    <Form.Row margin="0 0 10px" width={{ field: '100%' }}>
      <Field
        component={Form.TextField}
        disabled={isFetching}
        name="email"
        placeholder={i18n.t('module:Authentication:ResetPassword:email')}
        type="email"
        validate={isRequired}
      />
    </Form.Row>
    <Button
      isLoading={isFetching}
      label={i18n.t('module:Authentication:ResetPassword:requestPasswordReset')}
      fullWidth
      submit
    />
  </Form>
)

export default reduxForm<{}, ResetPasswordFormProps>({ form: RESET_PASSWORD_FORM })(ResetPasswordForm)
