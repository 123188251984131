import { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { FEATURE_FLAGS } from 'constants/security'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import { EVENTS, logEvent } from 'analytics'

import { withModalService } from 'services/utils/modal'
import { withRouterUtils } from 'services/utils/router'

import StaffList from '../StaffList'
import StaffRota from '../StaffRota'

const LS_KEY = 'HIDE_STAFF_REGISTER_POPUP'

class StaffRegisterWrapper extends Component {
  componentDidMount() {
    const { modalActions, modalConsts } = this.props

    logEvent(EVENTS.STAFF_REGISTER_MODE_CLICKED)

    const hidePopup = JSON.parse(window.localStorage.getItem(LS_KEY) || 0)

    if (!hidePopup) {
      return modalActions.show(modalConsts.TYPES.ENTER_STAFF_REGISTER, {
        onCancelClick: this.handleCancelClick,
        onContinueClick: this.handleContinueClick,
      })
    }

    return this.redirectToStaffRegister()
  }

  handleContinueClick = ({ doNotShowNextTime }) => {
    window.localStorage.setItem(LS_KEY, doNotShowNextTime ? 1 : 0)

    this.redirectToStaffRegister()
  }

  handleCancelClick = () => {
    const {
      authAccessMap: { module: { StaffList: isStaffListGranted, StaffRota: isStaffRotaGranted } },
      modalActions,
      navigate,
    } = this.props

    modalActions.hide()

    if (isStaffListGranted) {
      navigate(generateRoute('STAFF.LIST'))

      return
    }

    if (isStaffRotaGranted) {
      navigate(generateRoute('STAFF.ROTA'))

      return
    }

    navigate(generateRoute('ACCOUNT.INDEX'))
  }

  redirectToStaffRegister = () => {
    const { modalActions, router } = this.props

    logEvent(EVENTS.STAFF_REGISTER_MODE_ENABLED)
    modalActions.hide()
    router.replace(generateRoute('STAFF_REGISTER.INDEX'))
  }

  render() {
    return null
  }
}

StaffRegisterWrapper.authParams = {
  flags: [FEATURE_FLAGS.STAFF_REGISTER],
  nurseryContext: true,
}

const mapState = (state) => ({
  authAccessMap: {
    module: {
      StaffList: auth.SELECTORS.getComponentIsAuthorised(state, StaffList),
      StaffRota: auth.SELECTORS.getComponentIsAuthorised(state, StaffRota),
    },
  },
})

const enhance = compose(
  withModalService,
  withRouterUtils,
  connect(mapState),
)

export default enhance(StaffRegisterWrapper)
