import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

const getDailyDiariesListSelector = (state) => state.newDailyDiary.list

export const getDailyDiariesListDataSelector = createSelector([getDailyDiariesListSelector], (state) => state.data)

export const getDailyDiariesListCriteria = (params) => {
  const { date, room } = params || {}

  const criteria = []

  if (date) {
    criteria.push({
      field: 'day[after]',
      value: moment(date).format(DEFAULT_DATE_FORMAT),
    }, {
      field: 'day[before]',
      value: moment(date).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (room) {
    criteria.push({
      field: 'child.nurseryClass',
      value: room,
    })
  }

  return criteria
}

export const getDailyDiariesSendCriteria = (params) => {
  const { all, dailyDiaries, date, room, skipIds } = params || {}

  const criteria = []

  if (date) {
    criteria.push({
      field: 'day[after]',
      value: moment(date).format(DEFAULT_DATE_FORMAT),
    }, {
      field: 'day[before]',
      value: moment(date).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (room) {
    criteria.push({
      field: 'child.nurseryClass',
      value: room,
    })
  }

  if (!all && !skipIds?.length && 0 < dailyDiaries?.length) {
    _.forEach(dailyDiaries, (dailyDiary) => {
      criteria.push({
        field: 'id[]',
        value: dailyDiary,
      })
    })
  }

  if (!all && !dailyDiaries?.length && 0 < skipIds?.length) {
    _.forEach(skipIds, (id) => {
      criteria.push({
        field: 'not[id][]',
        value: id,
      })
    })
  }

  return criteria
}
