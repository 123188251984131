import { FormFields } from './models'

export const getInitialValues = (data): FormFields => {
  if (!data) {
    return null
  }

  const { name, periodType } = data

  return {
    name,
    periodType,
  }
}
