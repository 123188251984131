import _ from 'lodash'

import React from 'react'

import { RolesDetails } from 'constants/security'
import { NEUTRAL_COLOURS } from 'constants/colors'

import {
  Avatar,
  Button,
  Checkbox,
  EmptyState,
  FooterActions,
  InfiniteScroll,
  ModalBox,
  SearchBar,
  Space,
  Spinner,
  Table,
  Typography,
} from 'components'

import i18n from 'translations'

import { StyledModalWrapper, StyledTableWrapper } from './AccessToLikesAndCommentsModalStyled'

const AccessToLikesAndCommentsModalView = ({
  isFetching,
  isSubmitting,
  memberships,
  onChangeSearch,
  onChangeSelectedMembers,
  onCloseClick,
  onPageChange,
  onSelectAll,
  onSubmit,
  page,
  pageCount,
  search,
  selectedAll,
  selectedMembers,
}) => {
  const renderTable = () => {
    if (isFetching && 1 === page) {
      return (
        <div>
          <Space space="20px" />
          <Spinner />
        </div>
      )
    }

    if (!memberships?.length && !isFetching) {
      return (
        <EmptyState
          icon="group"
          text1={i18n.t('module:Modals:AccessToLikesAndCommentsModal:noStaffMembersFound')}
        />
      )
    }

    return (
      <StyledTableWrapper id="scrollableDiv">
        <InfiniteScroll
          dataLength={memberships ? memberships.length : 0}
          hasMore={page < pageCount}
          next={() => onPageChange((+page) + 1)}
          scrollableTarget="scrollableDiv"
        >
          <Table visualType="transparent">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>
                  <Checkbox
                    disabled={isSubmitting}
                    padding="0 0 0 10px"
                    value={selectedAll}
                    onChange={onSelectAll}
                  />
                </Table.Th>
                <Table.Th align="left">
                  <Typography color={NEUTRAL_COLOURS.BASIC} bold>
                    {i18n.t('module:Modals:AccessToLikesAndCommentsModal:staffMember')}
                  </Typography>
                </Table.Th>
                <Table.Th align="left">
                  <Typography color={NEUTRAL_COLOURS.BASIC} bold>
                    {_.upperFirst(i18n.t('global:Role'))}
                  </Typography>
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {_.map(memberships, ({ id, name, photo, roles }) => {
                const rolesLabels = _.map(roles, (role) => RolesDetails[role]?.label)

                return (
                  <Table.Tr key={id} style={{ height: '70px' }}>
                    <Table.Td style={{ width: '60px' }}>
                      <Checkbox
                        disabled={isSubmitting}
                        padding="0 0 0 10px"
                        value={!!_.find(selectedMembers, (i) => i === id)}
                        onChange={() => onChangeSelectedMembers(id)}
                      />
                    </Table.Td>
                    <Table.Td align="left">
                      <Avatar
                        avatarSize="medium"
                        initials={name}
                        src={photo}
                        title={name}
                      />
                    </Table.Td>
                    <Table.Td align="left">
                      {rolesLabels}
                    </Table.Td>
                  </Table.Tr>
                )
              })}
            </Table.Tbody>
          </Table>
        </InfiniteScroll>
      </StyledTableWrapper>
    )
  }

  return (
    <ModalBox
      title={i18n.t('module:Modals:AccessToLikesAndCommentsModal:title')}
      onCloseClick={onCloseClick}
    >
      <StyledModalWrapper>
        <SearchBar
          disabled={isFetching}
          placeholder={i18n.t('module:Modals:AccessToLikesAndCommentsModal:search')}
          value={search}
          variant="standard"
          noBackground
          onChange={onChangeSearch}
        />
        {renderTable()}
        <FooterActions spaceBetween>
          <FooterActions.Group>
            <FooterActions.Item>
              <Button
                disabled={isSubmitting}
                hierarchy="tertiary"
                label={i18n.t('global:cancel')}
                negativeMargins
                onClick={onCloseClick}
              />
            </FooterActions.Item>
          </FooterActions.Group>
          <FooterActions.Group>
            <FooterActions.Item>
              <Button
                disabled={isFetching}
                isLoading={isSubmitting}
                label={i18n.t('global:Save')}
                negativeMargins
                onClick={onSubmit}
              />
            </FooterActions.Item>
          </FooterActions.Group>
        </FooterActions>
      </StyledModalWrapper>
    </ModalBox>
  )
}

export default AccessToLikesAndCommentsModalView
