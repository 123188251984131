import React from 'react'

import { BasicViewProps, Nullable } from 'constants/models'

import { generateRoute } from 'utils/routing'

import { Callout, Page, Section, Spinner } from 'components'

import i18n from 'translations'

import { FormFields } from './models'
import AddForm from './components/AddForm'

export interface AddViewProps extends BasicViewProps {
  initialValues: Nullable<FormFields>
}

const AddView: React.FC<AddViewProps> = ({
  errorMessages,
  initialValues,
  isEdit,
  isLoading,
  isSubmitting,
  onSubmit,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <AddForm
        cancelLink={generateRoute('MANAGEMENT.ATTENDANCE_PERIODS')}
        initialValues={initialValues}
        isEdit={isEdit}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      />
    )
  }

  return (
    <Page>
      <Page.Content>
        <Callout content={errorMessages} error />
        <Section
          title={i18n.t(isEdit
            ? 'module:Management:AttendancePeriods:Add:editTitle'
            : 'module:Management:AttendancePeriods:Add:title')}
        >
          {renderContent()}
        </Section>
      </Page.Content>
    </Page>
  )
}

export default AddView
