import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'

import { NURSERY_DISCOUNTS_FILTER, OTHER } from 'services/product/nurseryDiscountsV3/constants'

import { hasMoreRecords } from 'utils/pagination'

import { withAppService } from 'services/app'
import { withNurseryDiscountsService } from 'services/legacy/nurseryDiscounts'

import { Select } from 'components'

import i18n from 'translations'

import withInfiniteDropdown from './withInfiniteDropdown'

class NurseryDiscountsDropdown extends Component {
  componentDidMount() {
    const { infiniteDropdownHelpers, nurseryDiscountsActions, value } = this.props

    if (!_.isArray(value)) {
      return infiniteDropdownHelpers.handleComponentDidMount({
        action: () => nurseryDiscountsActions.getDropdownItem({
          onFailed: this.notFoundValue,
          onSuccess: infiniteDropdownHelpers.handleComponentDidMountOnSuccess,
          params: [value?.value || value],
        }),
        value,
      })
    }

    return null
  }

  handleLoadMoreElements = async (phrase) => {
    const {
      extraFields,
      infiniteDropdownHelpers,
      nurseryDiscountsActions,
      nurseryDiscountsSelectors,
    } = this.props

    return infiniteDropdownHelpers.handleLoadMoreElements({
      clearAction: nurseryDiscountsActions.clearDropdownList,
      listAction: ({ newPage, newSearchPhrase, result }) => nurseryDiscountsActions.dropdownList({
        onSuccess: (response) => {
          const { data, meta } = response

          const newData = _.map(data, (item) => ({
            ...item,
            description: nurseryDiscountsSelectors.getDropdownDescription(item),
          }))

          if (
            !hasMoreRecords(meta)
            && (
              !newSearchPhrase
              || _.includes(i18n.t('global:other'), _.lowerCase(newSearchPhrase))
            )
          ) {
            newData.push({
              description: _.upperFirst(i18n.t('global:other')),
              id: OTHER,
            })
          }

          const newResponse = {
            data: newData,
            meta,
          }

          infiniteDropdownHelpers.handleLoadMoreElementsOnSuccess({
            extraFields,
            newPage,
            newSearchPhrase,
            response: newResponse,
            result,
          })
        },
        onlyData: true,
        params: {
          criteria: nurseryDiscountsSelectors.getCriteriaSelector({
            search: newSearchPhrase,
            statusFilter: NURSERY_DISCOUNTS_FILTER.ACTIVE,
          }),
          page: newPage,
        },
      }),
      phrase,
    })
  }

  notFoundValue = () => {
    const { onChange } = this.props

    onChange(null)
  }

  handleChange = (e) => {
    const { infiniteDropdownHelpers, onChange } = this.props

    infiniteDropdownHelpers.handleOnChange(e, () => onChange(e))
  }

  render() {
    const {
      disabled,
      infiniteDropdownState,
      placeholder,
      rawValue,
      value,
      ...rest
    } = this.props
    const { cacheUniq, options, selectedValue } = infiniteDropdownState

    return (
      <Select
        {...rest}
        cacheUniq={cacheUniq}
        disabled={disabled}
        loadOptions={this.handleLoadMoreElements}
        options={options}
        placeholder={placeholder || i18n.t('global:Finance:Discount')}
        rawValue={rawValue || selectedValue || value}
        value={value}
        searchable
        onChange={this.handleChange}
      />
    )
  }
}

const enhance = compose(
  withAppService,
  withNurseryDiscountsService,
  withInfiniteDropdown,
)

export default enhance(NurseryDiscountsDropdown)
