import React from 'react'

import WidgetContainer from 'module/Dashboard/components/WidgetContainer'

const NurseryList = ({ children }) => (
  <WidgetContainer>
    {children}
  </WidgetContainer>
)

export default NurseryList
