import _ from 'lodash'
import { createSelector } from 'reselect'

import { toInt } from 'utils/data'

import { GLOBAL_NUMBER_SETTINGS_OPTIONS } from 'services/organization/constants'

import { getOrganizationInvoiceSettings } from './invoiceSettingsSelectors'

export const getInvoiceNumberSettings = createSelector(
  [getOrganizationInvoiceSettings],
  (orgSettings) => {
    if (!orgSettings || !orgSettings.invoiceNumbers) {
      return null
    }

    return orgSettings.invoiceNumbers
  },
)

export const getInvoiceNumberWithDescriptions = createSelector(
  [getInvoiceNumberSettings],
  (organizationInvoiceNumbersSettings) => {
    if (!organizationInvoiceNumbersSettings) {
      return null
    }

    const {
      creditNoteNumber,
      creditNoteNumberPrefix = '',
      invoiceNumber,
      invoiceNumberPrefix = '',
      receiptNumber,
      receiptNumberPrefix = '',
    } = organizationInvoiceNumbersSettings

    const getDescription = (prefix, number, pad = null) => {
      if (!number) {
        return prefix
      }

      // NOTE: https://blossomeducational.atlassian.net/browse/BLOSS-962
      // const formatedNumber = number.toString().padStart(6, '0')
      const formatedNumber = pad ? number.toString().padStart(6, '0') : number.toString()

      if (!prefix) {
        return formatedNumber
      }

      return `${prefix}-${formatedNumber}`
    }

    const invoiceNumberDescription = getDescription(invoiceNumberPrefix, invoiceNumber)
    const receiptNumberDescription = getDescription(receiptNumberPrefix, receiptNumber)
    const creditNoteNumberDescription = getDescription(creditNoteNumberPrefix, creditNoteNumber)

    return {
      ...organizationInvoiceNumbersSettings,
      creditNoteNumberDescription,
      invoiceNumberDescription,
      receiptNumberDescription,
    }
  },
)

export const getGlobalNumberType = createSelector(
  [getInvoiceNumberSettings],
  (orgInvoiceNumbersSettings) => {
    if (!orgInvoiceNumbersSettings || !orgInvoiceNumbersSettings.invoiceNumbersSequencingType) {
      return null
    }

    return orgInvoiceNumbersSettings.invoiceNumbersSequencingType
  },
)

export const getGlobalNumberTypeDescription = createSelector(
  [getGlobalNumberType],
  (globalNumberType) => {
    if (!globalNumberType) {
      return null
    }

    const settingOption = _.find(GLOBAL_NUMBER_SETTINGS_OPTIONS, { value: globalNumberType })

    if (!settingOption) {
      return null
    }

    return settingOption.label
  },
)

export const getInvoiceNumberSettingsPayload = createSelector(
  [(values) => values],
  (values) => {
    const { fields, organizationSingleState } = values || {}
    const { organizationSettings } = organizationSingleState.data || {}

    if (!fields) {
      return null
    }

    const { invoice } = organizationSettings
    const { invoiceNumbers } = invoice

    const {
      creditNoteNumber,
      invoiceNumber,
      receiptNumber,
    } = invoiceNumbers

    const {
      creditNoteNumber: fieldCreditNoteNumber,
      invoiceNumber: fieldInvoiceNumber,
      receiptNumber: fieldReceiptNumber,
    } = fields

    return {
      organizationSettings: {
        ...organizationSettings,
        invoice: {
          ...invoice,
          invoiceNumbers: {
            ...invoiceNumbers,
            ...fields,
            creditNoteNumber: toInt(fieldCreditNoteNumber) || creditNoteNumber,
            invoiceNumber: toInt(fieldInvoiceNumber) || invoiceNumber,
            receiptNumber: toInt(fieldReceiptNumber) || receiptNumber,
          },
        },
      },
    }
  },
)
