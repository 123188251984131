import styled from 'styled-components'

import layout from 'constants/layout'

export const StyledContainer = styled.ul`
  min-width: 260px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
  grid-column-gap: 10px;
  border-radius: ${layout.borderRadius};
  padding: 10px 0;
  margin-bottom: 25px;
  background: #F7F7F7;
  overflow: hidden;
  
  @media(max-width: 1024px) {
    grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
  }
  
  @media(max-width: 740px) {
    grid-template-columns: repeat(auto-fit, minmax(33.33%, 1fr));
  }
  
  @media(max-width: 525px) {
    grid-template-columns: none;
  }
`

export const StyledRow = styled.li`
  padding: 4px 15px;
  display: grid;
  grid-column: span 1;
  grid-template-columns: 20px auto;
  grid-column-gap: 10px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledName = styled.span`
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden; 
`
