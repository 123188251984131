import { REFERENCE_ASYNC_PAGE_TYPES } from 'services/legacy/upload/constants'

export const getNurseryFileValuesForm = (values) => {
  const { mimeType, name, nursery, size, url } = values
  const body = {
    type: REFERENCE_ASYNC_PAGE_TYPES.NURSERY,
  }

  if (name) {
    body.name = name
  }

  if (nursery) {
    body.nursery = {
      id: nursery,
    }
  }

  if (mimeType || url) {
    body.file = {
      mimeType,
      size,
      url,
    }
  }

  return body
}
