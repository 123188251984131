import React, { useEffect, useRef, useState } from 'react'

import { IS_LOGOUT_CALLED, IS_STAY_LOGGED_IN_CALLED } from './constants'

import SessionTimeoutModalView from './SessionTimeoutModalView'

interface SessionTimeoutModalContainerProps {
  hideModal?: () => void
  onLogoutClick?: () => void
  onStayLoggedInClick?: () => void
  promptTimeout?: number
}

const SessionTimeoutModalContainer: React.FC<SessionTimeoutModalContainerProps> = ({
  hideModal,
  onLogoutClick,
  onStayLoggedInClick,
  promptTimeout,
}) => {
  const interval = useRef(null)
  const [remainingTime, setRemainingTime] = useState(promptTimeout)

  const handleLogout = () => {
    clearInterval(interval.current)
    onLogoutClick()
    hideModal()
  }

  const handleStayLoggedIn = () => {
    clearInterval(interval.current)
    onStayLoggedInClick()
    hideModal()
  }

  const handleLogoutClick = () => {
    window.localStorage.setItem(IS_LOGOUT_CALLED, '1')
    handleLogout()
  }

  const handleStayLoggedInClick = () => {
    window.localStorage.setItem(IS_STAY_LOGGED_IN_CALLED, '1')
    handleStayLoggedIn()
  }

  const handleLocalStorageEvent = (event) => {
    if (event.storageArea !== localStorage) return

    // To to listen when logout button click from other tab
    if (IS_LOGOUT_CALLED === event.key && 1 === +event.newValue) {
      handleLogout()
      window.localStorage.setItem(IS_LOGOUT_CALLED, '0')
      return
    }

    // To to listen when stay logged in button click from other tab
    if (IS_STAY_LOGGED_IN_CALLED === event.key && 1 === +event.newValue) {
      handleStayLoggedIn()
      window.localStorage.setItem(IS_STAY_LOGGED_IN_CALLED, '0')
    }
  }

  useEffect(() => {
    window.addEventListener('storage', handleLocalStorageEvent)

    return () => {
      window.removeEventListener('storage', handleLocalStorageEvent)
    }
  }, [])

  useEffect(() => {
    if (promptTimeout) {
      let elapsedTime = 0

      // Display counter
      interval.current = setInterval(() => {
        elapsedTime += 1

        setRemainingTime(promptTimeout - elapsedTime)

        if (elapsedTime >= promptTimeout) {
          clearInterval(interval.current)
          onLogoutClick()
        }
      }, 1000)
    }

    return () => {
      clearInterval(interval.current)
    }
  }, [promptTimeout])

  return (
    <SessionTimeoutModalView
      remainingTime={remainingTime}
      onCloseClick={handleStayLoggedInClick}
      onLogoutClick={handleLogoutClick}
      onStayLoggedInClick={handleStayLoggedInClick}
    />
  )
}

export default SessionTimeoutModalContainer
