import { generateBasicActions } from 'utils/service'

import {
  CLEAR_FINANCE_REPORTS_CHILDREN_REVENUE_PER_ROOM,
  FINANCE_REPORTS_CHILDREN_REVENUE_PER_ROOM,
  FINANCE_REPORTS_CHILDREN_REVENUE_PER_ROOM_FAILED,
  FINANCE_REPORTS_CHILDREN_REVENUE_PER_ROOM_SUCCESS,
} from './constants'

export const getChildren = (options = {}) => ({ financeRevenuePerRoomReportApiClient }) => generateBasicActions.list({
  constants: {
    failed: FINANCE_REPORTS_CHILDREN_REVENUE_PER_ROOM_FAILED,
    init: FINANCE_REPORTS_CHILDREN_REVENUE_PER_ROOM,
    success: FINANCE_REPORTS_CHILDREN_REVENUE_PER_ROOM_SUCCESS,
  },
  options,
  service: financeRevenuePerRoomReportApiClient,
  serviceMethod: 'getChildren',
})

export const clearChildren = () => ({
  type: CLEAR_FINANCE_REPORTS_CHILDREN_REVENUE_PER_ROOM,
})
