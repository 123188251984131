import { ROLES } from 'constants/security'

const BadDebtWrapper = ({ children }) => children

BadDebtWrapper.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
  ],
}

export default BadDebtWrapper
