import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as authSelectors from './selectors'

const withAuthService = (WrappedComponent) => {
  const mapState = ({ auth }) => ({ auth })

  const mapDispatch = injectActions('authActions', actions)

  const Component = ({ auth, authActions, ...others }) => (
    <WrappedComponent
      authActions={authActions}
      authAuthenticationState={auth.register}
      authPinAuthenticationState={auth.pin}
      authSelectors={authSelectors}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withAuthService
