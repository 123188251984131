import React from 'react'

import { generateRoute } from 'utils/routing'

import { Button } from 'components'
import { ChargeForLabel } from 'services/closureDayTypes/models'

export interface TableDataProps {
  chargeable: string
  name: string
  rowToButton: JSX.Element
}

const getChargeableString = (chargeFor) => {
  if (!chargeFor?.length) {
    return null
  }

  const chargeForLabels = chargeFor.map((item) => ChargeForLabel[item])

  return chargeForLabels.join(', ')
}

export const getTableData = ({ data }): TableDataProps[] => (
  data?.map(({ archived, chargeFor, id, name }) => ({
    chargeable: getChargeableString(chargeFor) || '-',
    name: `${name}${archived ? ' (archived)' : ''}`,
    rowToButton: (
      <Button.TableAction
        to={generateRoute('MANAGEMENT.CLOSURE_DAYS.CLOSURE_DAY_TYPES.EDIT', { closureDayTypeId: id })}
        edit
      />
    ),
  }))
)

