import React from 'react'

import { SIGNATURE_COLLECTING_TYPES } from 'services/legacy/injuries/constants'

import { Spinner } from 'components'

import { SignatureCollectionOption, SignatureDigitalForm, SignatureHeader, SignatureOnPaperForm } from './components'

const InjurySignatureView = ({
  disableSignature,
  injuryPreview,
  injurySignaturesPreview,
  isFetching,
  isHomeAccidents,
  isSubmitting,
  onAddCarerClick,
  onAddSignatureClick,
  onAddStaffMemberClick,
  onChangeCarer,
  onChangeOtherCarer,
  onChangeStaffMember,
  onCollectingChangeButtonClick,
  onCollectingTypeChange,
  onOnPaperDocumentChange,
  onOnPaperMarkAllAsSignedClick,
  onOnPaperSignatureToggleChange,
  showCollectionChangeTypeForm,
}) => {
  const { signatureCollectingType } = injurySignaturesPreview || {}

  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    return (
      <React.Fragment>
        {showCollectionChangeTypeForm && (
          <SignatureCollectionOption
            disableSignature={disableSignature}
            injurySignaturesPreview={injurySignaturesPreview}
            isSubmitting={isSubmitting}
            onChange={onCollectingTypeChange}
          />
        )}
        {!showCollectionChangeTypeForm && (
          <React.Fragment>
            <SignatureHeader
              disableSignature={disableSignature}
              injuryPreview={injuryPreview}
              injurySignaturesPreview={injurySignaturesPreview}
              onChangeClick={onCollectingChangeButtonClick}
            />
            {signatureCollectingType === SIGNATURE_COLLECTING_TYPES.ON_PAPER
              ? (
                <SignatureOnPaperForm
                  disableSignature={disableSignature}
                  injuryPreview={injuryPreview}
                  injurySignaturesPreview={injurySignaturesPreview}
                  isHomeAccidents={isHomeAccidents}
                  isSubmitting={isSubmitting}
                  onAddCarerClick={onAddCarerClick}
                  onAddStaffMemberClick={onAddStaffMemberClick}
                  onChangeCarer={onChangeCarer}
                  onChangeOtherCarer={onChangeOtherCarer}
                  onChangeStaffMember={onChangeStaffMember}
                  onDocumentChange={onOnPaperDocumentChange}
                  onOnPaperMarkAllAsSignedClick={onOnPaperMarkAllAsSignedClick}
                  onSignatureToggleChange={onOnPaperSignatureToggleChange}
                />
              ) : (
                <SignatureDigitalForm
                  disableSignature={disableSignature}
                  injurySignaturesPreview={injurySignaturesPreview}
                  isHomeAccidents={isHomeAccidents}
                  onAddCarerClick={onAddCarerClick}
                  onAddSignatureClick={onAddSignatureClick}
                  onAddStaffMemberClick={onAddStaffMemberClick}
                  onChangeCarer={onChangeCarer}
                  onChangeOtherCarer={onChangeOtherCarer}
                  onChangeStaffMember={onChangeStaffMember}
                />
              )}
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  return renderContent()
}

export default InjurySignatureView
