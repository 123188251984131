import React from 'react'

import { generateRoute } from 'utils/routing'

import {
  Button,
  Callout,
  DatePicker,
  EmptyState,
  Page,
  Pagination,
  Section,
  Select,
  Spinner,
  Table,
  Toolbar,
} from 'components'

import i18n from 'translations'

const ChildExtraItemsView = ({
  child,
  dateRange,
  errorMessages,
  isEmpty,
  isLoading,
  isNurseryExtraItemsEmpty,
  onDateRangeChange,
  onPageChange,
  onSortChange,
  onStatusFilterChange,
  page,
  pageCount,
  perPage,
  sortField,
  sortOrder,
  status,
  statusOptions,
  tableData,
  totalResults,
}) => {
  const { firstName: childfirstName, id: childId } = child

  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('module:Children:Child:BookingPattern:ExtraItems:extraItems')}
      titleSingular={i18n.t('module:Children:Child:BookingPattern:ExtraItems:extraItem')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!isNurseryExtraItemsEmpty) {
      return (
        <EmptyState
          actions={(
            <Button
              label="Add nursery extra item"
              to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.ITEMS')}
            />
          )}
          icon="plus"
          text1="There are no extra items defined in your nursery. Add extra item to allocate to the child."
        />
      )
    }

    if (isEmpty) {
      return (
        <EmptyState
          icon="plus"
          text1={`Does ${childfirstName} have any extra items?`}
          text2={`You can allocate items for ${childfirstName} here to show up on invoices.`}
        />
      )
    }

    return (
      <div>
        {renderPagination()}
        <Table
          columns={[
            {
              align: 'left',
              field: 'startDate',
              sortable: true,
              title: 'Date',
            },

            {
              field: 'name',
              sortable: true,
              title: 'Extra item',
            },
            {
              field: 'unitPrice',
              sortable: true,
              title: 'Cost',
            },
            {
              field: 'quantity',
              sortable: true,
              title: 'Qty',
            },
            {
              field: 'total',
              sortable: true,
              title: 'Total',
            },
            {
              field: 'type',
              sortable: true,
              title: 'Frequency',
            },
            {
              align: 'center',
              field: 'rowToButton',
            },
          ]}
          data={tableData}
          sortField={sortField}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </div>
    )
  }

  const actions = isNurseryExtraItemsEmpty && (
    <Section.Actions
      primary={[{ to: generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_ITEMS.ADD', { childId }) }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title="Extra items"
    >
      <Callout content={errorMessages} error />
      {isNurseryExtraItemsEmpty && (
        <Toolbar>
          <Toolbar.Group>
            <Toolbar.Item>
              <DatePicker
                value={dateRange}
                clearable
                range
                onChange={onDateRangeChange}
              />
            </Toolbar.Item>
            <Toolbar.Item>
              <Select
                clearable={false}
                options={statusOptions}
                placeholder="Status"
                searchable={false}
                value={status}
                onChange={onStatusFilterChange}
              />
            </Toolbar.Item>
          </Toolbar.Group>
        </Toolbar>
      )}
      {renderContent()}
    </Page.Section>
  )
}

export default ChildExtraItemsView
