import { generateBasicActions } from 'utils/service'

import {
  CLEAR_NURSERY_PUBLIC_SETTINGS,
  GET_NURSERY_PUBLIC_SETTINGS,
  GET_NURSERY_PUBLIC_SETTINGS_FAILED,
  GET_NURSERY_PUBLIC_SETTINGS_SUCCESS,
} from './constants'

export const getNurseryPublicSettings = (options = {}) => ({ nurseriesApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_NURSERY_PUBLIC_SETTINGS_FAILED,
    init: GET_NURSERY_PUBLIC_SETTINGS,
    success: GET_NURSERY_PUBLIC_SETTINGS_SUCCESS,
  },
  options,
  service: nurseriesApiClient,
  serviceMethod: 'getNurseryPublicSettings',
})

export const clearNurseryPublicSettings = () => ({
  type: CLEAR_NURSERY_PUBLIC_SETTINGS,
})
