import _ from 'lodash'

export const findDiaryFoodRecord = (state, data) => {
  const childRecord = _.find(state.data, ({ childRegister }) => childRegister.child.id === data.child.id)

  const { comments, id, items, recordedAt, type } = data

  return {
    childRecord,
    newDiaryRecord: {
      id,
      items,
      record: {
        comments,
        recordedAt,
      },
      type,
    },
  }
}

export const addDiaryFoodRecord = (state, data) => {
  const { childRecord, newDiaryRecord } = findDiaryFoodRecord(state, data)

  if (!childRecord) {
    return { ...state }
  }

  return {
    ...state,
    data: _.map(state.data, (item) => {
      if (childRecord.childRegister.id === item.childRegister.id) {
        return {
          ...item,
          diaryRecords: item.diaryRecords.length
            ? [...item.diaryRecords, newDiaryRecord]
            : [newDiaryRecord],
        }
      }

      return item
    }),
  }
}

export const updateDiaryFoodRecord = (state, data) => {
  const { childRecord, newDiaryRecord } = findDiaryFoodRecord(state, data)

  if (!childRecord) {
    return { ...state }
  }

  return {
    ...state,
    data: _.map(state.data, (item) => ({
      ...item,
      diaryRecords: _.map(item.diaryRecords, (diaryRecord) => {
        if (childRecord.childRegister.id === item.childRegister.id && diaryRecord.id === data.id) {
          return newDiaryRecord
        }

        return diaryRecord
      }),
    })),
  }
}

export const removeDiaryFoodRecord = (state, id) => ({
  ...state,
  data: _.map(state.data, (item) => ({
    ...item,
    diaryRecords: _.filter(
      item.diaryRecords,
      (diaryRecord) => (id !== diaryRecord.id),
    ),
  })),
})
