import _ from 'lodash'
import { nest } from 'utils/flatnest'

import { getBackendErrors } from 'utils/backendErrors'

import i18n from 'translations'

import {
  CLEAR_OCCUPANCY_REPORT,
  GET_OCCUPANCY_REPORT,
  GET_OCCUPANCY_REPORT_FAILED,
  GET_OCCUPANCY_REPORT_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  isFetching: false,
  isOverviewReport: false,
  meta: {
    // eslint-disable-next-line camelcase
    total_results: null,
  },
}

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_OCCUPANCY_REPORT:
      return { ...state, isFetching: true }
    case GET_OCCUPANCY_REPORT_SUCCESS: {
      const { forWeek, params, response } = payload

      const date = _.get(_.find(params.criteria, ({ comparator }) => 'date' === comparator), 'value')

      return {
        ...state,
        data: [...(state.data || []), ..._.map(response.data, (item) => ({ ...item, date }))],
        isFetching: false,
        isOverviewReport: !!forWeek,
        meta: response.meta,
      }
    }
    case GET_OCCUPANCY_REPORT_FAILED: {
      const { forWeek, params, response, room } = payload
      const { id: roomId } = room || {}

      const { extra } = response
      const extraErrors = nest(extra)

      if (extraErrors?.root?.length && (extraErrors.root[0].capacity || extraErrors.root[0].staffRequired)) {
        return {
          ...state,
          error: {
            message: i18n.t('services:Occupancy:genericValidationErrorMessage'),
          },
          isFetching: false,
        }
      }

      if (!roomId) {
        return {
          ...state,
          error: response,
          isFetching: false,
        }
      }

      let validation = null

      if (response) {
        validation = getBackendErrors(response)
      }

      if (forWeek) {
        const date = _.get(_.find(params.criteria, ({ comparator }) => 'date' === comparator), 'value')

        return {
          ...state,
          data: {
            ...state.data,
            [date]: {
              ...state.data[date],
              [roomId]: {
                error: true,
                validation,
              },
            },
          },
          isFetching: false,
        }
      }

      return {
        ...state,
        data: {
          ...state.data,
          [roomId]: {
            error: true,
            validation,
          },
        },
        isFetching: false,
      }
    }
    case CLEAR_OCCUPANCY_REPORT:
      return initialState
    default:
      return state
  }
}

