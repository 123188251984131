import React from 'react'

import { StyledReactModal } from './ModalStyled'

const Modal = ({
  children,
  onRequestClose,
  open,
  overlayClassName,
  style,
}) => (
  <StyledReactModal
    ariaHideApp={false}
    contentLabel="Modal"
    isOpen={open}
    overlayClassName={overlayClassName}
    style={style}
    onRequestClose={onRequestClose}
  >
    {children}
  </StyledReactModal>
)

export default Modal
