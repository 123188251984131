import styled from 'styled-components'

export const StyledDataPreviewContainer = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 35px;
  flex-direction: column;
`

export const StyledActions = styled.div`
  display: flex;
  align-items: center;
  
  & > div {
    margin-left: 5px;
  }
`

interface StyledContentProps {
  $noPadding?: boolean
  $whiteSpace?: string
  $withBackground?: boolean
}

export const StyledContent = styled.div<StyledContentProps>`
  padding: 15px 0;
  word-break: break-word;

  ${({ $withBackground }) => $withBackground && `
    background: #ebebed;
    padding: 15px;
  `}
  
  ${({ $whiteSpace }) => $whiteSpace && `
    white-space: ${$whiteSpace};
  `}

  ${({ $noPadding }) => $noPadding && `
    padding: 0;
  `}
`

export const StyledHeader = styled.header`
  display: flex;
  margin-bottom: 7px;
`

export const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  flex: 1;
`
