import React from 'react'

import Typography from 'components/Typography'

import i18n from 'translations'

export const AMOUNT_DRUNK_MARKS = {
  0: {
    label: (
      <Typography
        variant="small"
        bold
      >
        {`0${i18n.t('global:oz')}`}
      </Typography>
    ),
  },
  10: {
    label: (
      <Typography
        variant="small"
        bold
      >
        {`10${i18n.t('global:oz')}`}
      </Typography>
    ),
  },
}
