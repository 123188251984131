import React from 'react'

import { TYPES } from 'components/DropdownMenu/constants'

import { Callout, Page, Section, Spinner } from 'components'

import i18n from 'translations'

import ManagementConsumablesV3AddForm, {
  ManagementConsumablesV3AddFormValues,
} from './components/ManagementConsumablesV3AddForm'

interface ManagementConsumablesV3AddViewProps {
  errorMessages: string[]
  initialValues?: ManagementConsumablesV3AddFormValues
  isArchived: boolean
  isEdit: boolean
  isFormLoading: boolean
  isLoading: boolean
  nurseryOptions: {
    id: any
    prefix: string
  }
  onArchiveClick: (archived: boolean) => any
  onSubmit: (values: ManagementConsumablesV3AddFormValues) => void
}

const ManagementConsumablesV3AddView: React.FC<ManagementConsumablesV3AddViewProps> = ({
  errorMessages,
  initialValues,
  isArchived,
  isEdit,
  isFormLoading,
  isLoading,
  nurseryOptions,
  onArchiveClick,
  onSubmit,
}) => {
  const renderContent = () => {
    if (isLoading || (isEdit && !initialValues)) {
      return (
        <Spinner />
      )
    }

    return (
      <ManagementConsumablesV3AddForm
        initialValues={initialValues}
        isLoading={isFormLoading}
        nurseryOptions={nurseryOptions}
        onSubmit={onSubmit}
      />
    )
  }

  const actions = isEdit && !isLoading && (
    <Section.Actions
      options={[{
        onClick: onArchiveClick(!isArchived),
        type: isArchived ? TYPES.UNARCHIVE : TYPES.ARCHIVE,
      }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title={isEdit
        ? i18n.t('module:Management:Finance:Consumables:Edit:title')
        : i18n.t('module:Management:Finance:Consumables:Add:title')}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default ManagementConsumablesV3AddView
