export const CLEAR_MEMBERSHIP_REGISTER = 'CLEAR_MEMBERSHIP_REGISTER'

export const GET_MEMBERSHIP_REGISTER = 'GET_MEMBERSHIP_REGISTER'
export const GET_MEMBERSHIP_REGISTER_FAILED = 'GET_MEMBERSHIP_REGISTER_FAILED'
export const GET_MEMBERSHIP_REGISTER_SUCCESS = 'GET_MEMBERSHIP_REGISTER_SUCCESS'

export const CREATE_MEMBERSHIP_REGISTER = 'CREATE_MEMBERSHIP_REGISTER'
export const CREATE_MEMBERSHIP_REGISTER_FAILED = 'CREATE_MEMBERSHIP_REGISTER_FAILED'
export const CREATE_MEMBERSHIP_REGISTER_SUCCESS = 'CREATE_MEMBERSHIP_REGISTER_SUCCESS'

export const UPDATE_MEMBERSHIP_REGISTER = 'UPDATE_MEMBERSHIP_REGISTER'
export const UPDATE_MEMBERSHIP_REGISTER_FAILED = 'UPDATE_MEMBERSHIP_REGISTER_FAILED'
export const UPDATE_MEMBERSHIP_REGISTER_SUCCESS = 'UPDATE_MEMBERSHIP_REGISTER_SUCCESS'

export const CREATE_MEMBERSHIP_REGISTER_ENTRY = 'CREATE_MEMBERSHIP_REGISTER_ENTRY'
export const CREATE_MEMBERSHIP_REGISTER_ENTRY_FAILED = 'CREATE_MEMBERSHIP_REGISTER_ENTRY_FAILED'
export const CREATE_MEMBERSHIP_REGISTER_ENTRY_SUCCESS = 'CREATE_MEMBERSHIP_REGISTER_ENTRY_SUCCESS'

export const UPDATE_MEMBERSHIP_REGISTER_ENTRY = 'UPDATE_MEMBERSHIP_REGISTER_ENTRY'
export const UPDATE_MEMBERSHIP_REGISTER_ENTRY_FAILED = 'UPDATE_MEMBERSHIP_REGISTER_ENTRY_FAILED'
export const UPDATE_MEMBERSHIP_REGISTER_ENTRY_SUCCESS = 'UPDATE_MEMBERSHIP_REGISTER_ENTRY_SUCCESS'

export const UPDATE_BATCH_MEMBERSHIP_REGISTER = 'UPDATE_BATCH_MEMBERSHIP_REGISTER'
export const UPDATE_BATCH_MEMBERSHIP_REGISTER_FAILED = 'UPDATE_BATCH_MEMBERSHIP_REGISTER_FAILED'
export const UPDATE_BATCH_MEMBERSHIP_REGISTER_SUCCESS = 'UPDATE_BATCH_MEMBERSHIP_REGISTER_SUCCESS'

export const UPDATE_MEMBERSHIP_REGISTER_MANUALLY = 'UPDATE_MEMBERSHIP_REGISTER_MANUALLY'
export const SELECTED_MEMBERSHIP_REGISTER_STATUS = 'SELECTED_MEMBERSHIP_REGISTER_STATUS'
