import i18n from 'translations'

import NurseryFilesList from './NurseryFilesList'

export const ROUTE_NAMES = {
  'NURSERY_FILES.LIST': 'NURSERY_FILES.LIST',
}

const NurseryFilesRouting = {
  component: NurseryFilesList,
  name: ROUTE_NAMES['NURSERY_FILES.LIST'],
  path: 'nursery-files',
  title: i18n.t('module:NurseryFiles:title'),
}

NurseryFilesRouting.ROUTE_NAMES = ROUTE_NAMES

export default NurseryFilesRouting
