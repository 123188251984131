import React, { useEffect } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'
import { withRouterProps } from 'services/router/constants'

import { convertFromMinutesToSeconds } from 'utils/date'

import { withAppService, withAppServiceProps } from 'services/app'
import { withNurseriesService, withNurseriesServiceProps } from 'services/nurseries'
import { withSecurityService, withSecurityServiceProps } from 'services/security'

import GeneralSecuritySettingsView from './GeneralSecuritySettingsView'

const NURSERY_SECURITY_SETTINGS_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings',
    'nurserySettings.security',
    'nurserySecuritySettings',
    'nursery.organization',
    'organization.organizationSettings',
    'organizationSettings',
    'organizationSettings.security',
    'organizationSecuritySettings',
  ],
}

type GeneralSecuritySettingsContainerProps = withNurseriesServiceProps
  & withAppServiceProps
  & withSecurityServiceProps
  & withRouterProps

const mapState = (state: RootState, {
  appSelectors,
  nurseriesSelectors,
  nurseriesSingleState,
  params,
  securitySelectors,
}: GeneralSecuritySettingsContainerProps) => ({
  errorMessages: appSelectors.getErrorMessages(nurseriesSingleState),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState),
  isInheritedFromOrganization: nurseriesSelectors.isSecuritySettingsInheritedFromOrganization(state),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
  nurseryName: nurseriesSelectors.getNurseryName(state),
  // @ts-ignore
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  nurserySecuritySettings: nurseriesSelectors.getNurserySecuritySettings(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const GeneralSecuritySettingsContainer: React.FC<GeneralSecuritySettingsContainerProps & PropsFromRedux> = ({
  isFetching,
  isInheritedFromOrganization,
  isOrganizationContext,
  nurseriesActions,
  nurseriesSelectors,
  nurseriesSingleState,
  nurseryName,
  nurseryOptions,
  nurserySecuritySettings,
}) => {
  useEffect(() => {
    nurseriesActions.get(nurseryOptions.id, {
      params: { groups: NURSERY_SECURITY_SETTINGS_GROUPS },
    })

    return () => {
      nurseriesActions.clearSingle()
    }
  }, [])

  const handleSessionTimeoutSubmit = (fields) => (successCallback, failedCallback = () => {}) => {
    const payload = nurseriesSelectors.getNurserySettingsPayload({
      fields: {
        security: {
          sessionExpireTime: convertFromMinutesToSeconds(fields.sessionExpireTime),
          sessionExpireTimeEnabled: fields.sessionExpireTimeEnabled,
        },
      },
      nurseriesSingleState,
    })

    nurseriesActions.update(nurseryOptions.id, {
      onFailed: failedCallback,
      onSuccess: successCallback,
      params: { groups: NURSERY_SECURITY_SETTINGS_GROUPS },
      payload,
    })
  }

  return (
    <GeneralSecuritySettingsView
      isFetching={isFetching}
      isInheritedFromOrganization={isInheritedFromOrganization}
      isOrganizationContext={isOrganizationContext}
      nurseryName={nurseryName}
      securitySettings={nurserySecuritySettings}
      onSessionTimeoutSubmit={handleSessionTimeoutSubmit}
    />
  )
}

const enhance = compose(
  withAppService,
  withNurseriesService,
  withSecurityService,
  connector,
)

export default enhance(GeneralSecuritySettingsContainer)
