import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { AGE_IN_MONTHS_OPTIONS, CHILD_TYPES_OPTIONS } from 'services/legacy/child/constants'
import { ROLES } from 'constants/security'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withPaginationUtils } from 'services/utils/pagination'

import { getTableData } from './helpers'

import ChildrenListView from './ChildrenListView'

const CHILDREN_GROUPS = {
  read: [
    'nursery',
    'child.nursery',
  ],
}

class ChildrenListContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filters: {
        fromAge: null,
        isArchived: CHILD_TYPES_OPTIONS[0].value,
        nursery: null,
        search: null,
        toAge: null,
      },
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { childActions } = this.props

    childActions.clear()
  }

  fetch = () => {
    const {
      childActions,
      childSelectors,
      paginationUtils,
    } = this.props
    const { page } = paginationUtils
    const { filters } = this.state
    const { fromAge, isArchived, nursery, search, toAge } = filters

    childActions.list({
      params: {
        criteria: childSelectors.getCriteria({
          ageRange: {
            from: fromAge?.value,
            to: toAge?.value,
          },
          displayName: search,
          isArchived,
          nursery,
        }),
        groups: CHILDREN_GROUPS,
        page,
      },
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleSearchChange = (search) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        search,
      },
    }), () => onPageChange(this.fetch)(1))
  }

  handleAgeChange = (key) => (value) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [key]: value,
      },
    }), () => onPageChange(this.fetch)(1))
  }

  handleChangeNursery = (nursery) => {
    const { paginationUtils: { onPageChange } } = this.props

    this.setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        nursery: nursery?.value,
      },
    }), () => onPageChange(this.fetch)(1))
  }

  handleChildTypeFilterChange = (isArchived) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        isArchived: isArchived?.value,
      },
    }), () => onPageChange(this.fetch)(1))
  }

  render() {
    const {
      appProperties,
      childrenList,
      errorMessages,
      isFetching,
      paginationUtils,
      totalResults,
    } = this.props
    const { getPageCount, page, perPage } = paginationUtils
    const { filters } = this.state
    const { fromAge, isArchived, nursery, toAge } = filters
    const pageCount = getPageCount(totalResults)
    const tableData = getTableData(childrenList, appProperties)
    const isEmpty = !childrenList?.length

    return (
      <ChildrenListView
        ageMonthsOptions={AGE_IN_MONTHS_OPTIONS}
        childType={isArchived}
        childTypeOptions={CHILD_TYPES_OPTIONS}
        errorMessages={errorMessages}
        fromAge={fromAge}
        isEmpty={isEmpty}
        isLoading={isFetching}
        nursery={nursery}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        tableData={tableData}
        toAge={toAge}
        totalResults={totalResults}
        onAgeChange={this.handleAgeChange}
        onChangeNursery={this.handleChangeNursery}
        onChildTypeFilterChange={this.handleChildTypeFilterChange}
        onPageChange={this.handlePageChange}
        onSearchChange={this.handleSearchChange}
      />
    )
  }
}

ChildrenListContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
  ],
}

const mapState = (state, {
  appSelectors,
  childListState,
  childSelectors,
}) => ({
  appProperties: appSelectors.getApplicationProperties(state),
  childrenList: childSelectors.getChildListDataSelector(state),
  errorMessages: appSelectors.getErrorMessages(childListState),
  isFetching: appSelectors.getIsFetching(childListState),
  totalResults: childSelectors.getChildListMetaSelectorTotalResults(state),
})

const enhance = compose(
  withAppService,
  withPaginationUtils,
  withChildService,
  connect(mapState),
)

export default enhance(ChildrenListContainer)
