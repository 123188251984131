import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { CAPABILITIES_TYPES, DISCONNECT_CONDITIONS, STRIPE_STATUSES } from 'services/nurseryIntegrations/constants'
import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withNurseryContextService } from 'services/nurseryContext'
import { withNurseryIntegrationsService } from 'services/nurseryIntegrations'
import { withModalService } from 'services/utils/modal'
import { withSnackbarService } from 'services/utils/snackbar'
import { withShellService } from 'services/shell'
import { withRouter } from 'services/router'

import { Typography } from 'components'

import i18n from 'translations'

import ManagementPaymentGatewayView, { CHARGE_TYPES_FOR } from './ManagementPaymentGatewayView'

const NURSERY_SETTINGS_GROUPS = {
  read: [
    'capability',
    'nurseryIntegration',
    'nurseryIntegrations.stripe',
    'nurseryIntegration.capabilities',
    'nurseryIntegration.links',
  ],
}

const STRIPE_INTEGRATION_GROUPS = {
  read: [
    'capability',
    'nurseryIntegration',
    'nurseryIntegration.capabilities',
  ],
}

const CREATE_STRIPE_INTEGRATION_GROUPS = {
  read: [
    'nurseryIntegration.links',
  ],
}

const GROUPS = {
  read: [
    'nurseryIntegration',
  ],
}

class ManagementPaymentGatewayContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      [CHARGE_TYPES_FOR.BACS]: null,
      capabilitiesEnabled: {
        [CAPABILITIES_TYPES.AMERICAN_EXPRESS]: false,
        [CAPABILITIES_TYPES.BACS]: false,
        [CAPABILITIES_TYPES.CARD]: false,
      },
      [CHARGE_TYPES_FOR.CARD]: null,
      initialized: false,
      isFetching: true,
      isFetchingCapability: false,
      isProcessing: false,
      isProcessingChargeTypeFor: {
        [CHARGE_TYPES_FOR.BACS]: false,
        [CHARGE_TYPES_FOR.CARD]: false,
      },
      isRemovingProcessing: false,
    }
  }

  componentDidMount() {
    this.getNurserySettings()
  }

  componentWillUnmount() {
    const { nurseryIntegrationsActions } = this.props

    nurseryIntegrationsActions.clearStripeIntegration()
  }

  getNurserySettings = (callback) => {
    const { nurseryContextActions, nurseryOptions } = this.props

    nurseryContextActions.getSettings({
      onSuccess: (response) => {
        callback?.()
        this.getNurserySettingsSuccess(response)
      },
      params: [nurseryOptions.id, {
        groups: NURSERY_SETTINGS_GROUPS,
      }],
    })
  }

  getNurserySettingsSuccess = ({ data }) => {
    const { stripeNurseryIntegration } = data || {}
    const { bacsChargeDay, cardChargeDay } = stripeNurseryIntegration || {}

    if (stripeNurseryIntegration) {
      this.setState({
        bacsChargeDay,
        cardChargeDay,
        initialized: true,
      })

      setTimeout(() => {
        this.getStripeIntegrationSuccess()
      })
    } else {
      this.setState({
        isFetching: false,
      })

      if (bacsChargeDay && cardChargeDay) {
        this.setState({
          bacsChargeDay,
          cardChargeDay,
        })
      }
    }
  }

  getStripeIntegrationSuccess = () => {
    const { nurseryStripeNurseryIntegration } = this.props
    const { capabilitiesEnabled } = this.state

    const { amexEnabled, capabilities } = nurseryStripeNurseryIntegration || {}
    const result = {}

    this.setState({ isFetching: false })

    if (amexEnabled) {
      result[CAPABILITIES_TYPES.AMERICAN_EXPRESS] = true
    }

    if (!nurseryStripeNurseryIntegration) {
      return null
    }

    _.each(capabilities, ({ code, enabled }) => {
      _.each(_.values(CAPABILITIES_TYPES), (capability) => {
        if (capability === code) {
          result[capability] = enabled
        }
      })
    })

    return this.setState({
      capabilitiesEnabled: {
        ...capabilitiesEnabled,
        ...result,
      },
    })
  }

  handleConnectWithStripeSuccess = (response) => {
    const { data } = response
    const { accountLink } = data

    window.location.replace(accountLink)
  }

  handleConnectWithStripe = () => {
    const { nurseryIntegrationsActions, nurseryStripeNurseryIntegration } = this.props
    const { isProcessing } = this.state

    if (!isProcessing) {
      this.setState({ isProcessing: true })

      if (STRIPE_STATUSES.NOT_ONBOARDED === nurseryStripeNurseryIntegration?.status) {
        return window.location.replace(nurseryStripeNurseryIntegration.accountLink)
      }

      return nurseryIntegrationsActions.createStripeIntegration({
        body: {},
        onSuccess: this.handleConnectWithStripeSuccess,
        params: {
          groups: CREATE_STRIPE_INTEGRATION_GROUPS,
        },
      })
    }

    return null
  }

  handleDisconnectStripeSuccess = () => {
    const { nurseryContextActions, snackbarActions } = this.props

    this.getNurserySettings(nurseryContextActions.removeStripeIntegration)
    this.setState({
      initialized: false,
      isRemovingProcessing: false,
    })

    snackbarActions.show({
      message: i18n.t('module:Management:Finance:PaymentGateway:disconnectSuccess'),
    })
  }

  handleCannotDisconnectStripe = () => {
    const { modalActions, modalConsts } = this.props

    this.setState({
      isRemovingProcessing: false,
    })

    modalActions.show(modalConsts.TYPES.ALERT, {
      icon: 'warning',
      text: (
        <React.Fragment>
          <Typography variant="h5">
            {i18n.t('module:Management:Finance:PaymentGateway:cannotDisconnectStripeModal:title')}
          </Typography>
          <Typography margin="10px 0" bold>
            {i18n.t('module:Management:Finance:PaymentGateway:cannotDisconnectStripeModal:1')}
          </Typography>
          <Typography margin="15px 0 10px" bold>
            {i18n.t('module:Management:Finance:PaymentGateway:cannotDisconnectStripeModal:2')}
          </Typography>
          <Typography margin="0">
            {'1. '}
            {i18n.t('module:Management:Finance:PaymentGateway:cannotDisconnectStripeModal:3')}
          </Typography>
          <Typography margin="0">
            {'2. '}
            {i18n.t('module:Management:Finance:PaymentGateway:cannotDisconnectStripeModal:4')}
          </Typography>
        </React.Fragment>
      ),
    })
  }

  handleDisconnectStripeConfirmed = () => {
    const { nurseryIntegrationsActions, nurseryStripeNurseryIntegration } = this.props

    this.setState({
      isRemovingProcessing: true,
    })

    nurseryIntegrationsActions.removeIntegration({
      onFailed: this.handleCannotDisconnectStripe,
      onSuccess: this.handleDisconnectStripeSuccess,
      params: [nurseryStripeNurseryIntegration.id],
    })
  }

  handleDisconnectStripe = async () => {
    const { modalActions, modalConsts, nurseryIntegrationsActions, nurseryStripeNurseryIntegration } = this.props

    this.setState({
      isRemovingProcessing: true,
    })

    const conditions = await nurseryIntegrationsActions.getDisconnectConditions({
      params: [nurseryStripeNurseryIntegration.id],
    })

    const conditionsAreNotSatisfied = _.find(DISCONNECT_CONDITIONS, ({ value }) => (
      !conditions?.data[value]
    ))

    this.setState({
      isRemovingProcessing: false,
    })

    if (conditionsAreNotSatisfied) {
      return modalActions.show(modalConsts.TYPES.STRIPE_DISCONNECT_CONDITIONS, {
        conditions: conditions.data,
      })
    }

    return this.handleDisconnectStripeConfirm()
  }

  handleDisconnectStripeConfirm = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('module:Management:Finance:PaymentGateway:disconnectStripeModal:disconnect'),
      icon: 'warning',
      onConfirm: this.handleDisconnectStripeConfirmed,
      text: (
        <React.Fragment>
          <Typography variant="h5">
            {i18n.t('module:Management:Finance:PaymentGateway:disconnectStripeModal:title')}
          </Typography>
          <Typography margin="10px 0" bold>
            {i18n.t('module:Management:Finance:PaymentGateway:disconnectStripeModal:1')}
          </Typography>
          <Typography margin="0">
            {'- '}
            {i18n.t('module:Management:Finance:PaymentGateway:disconnectStripeModal:2')}
          </Typography>
          <Typography margin="0">
            {'- '}
            {i18n.t('module:Management:Finance:PaymentGateway:disconnectStripeModal:3')}
          </Typography>
          <Typography margin="25px 0 0">
            {i18n.t('module:Management:Finance:PaymentGateway:disconnectStripeModal:4')}
          </Typography>
        </React.Fragment>
      ),
    })
  }

  handleChangeChargeTypeSuccess = (chargeTypeFor) => {
    const { snackbarActions } = this.props

    this.setState((prevState) => ({
      isProcessingChargeTypeFor: {
        ...prevState.isProcessingChargeTypeFor,
        [chargeTypeFor]: false,
      },
    }))

    snackbarActions.show({
      message: i18n.t('module:Management:Finance:PaymentGateway:directDebitDateChanged'),
    })
  }

  handleChangeChargeTypeConfirmed = (value, chargeTypeFor) => {
    const { nurseryIntegrationsActions, nurseryStripeNurseryIntegration } = this.props

    this.setState((prevState) => ({
      [chargeTypeFor]: value,
      isProcessingChargeTypeFor: {
        ...prevState.isProcessingChargeTypeFor,
        [chargeTypeFor]: true,
      },
    }))

    const body = {
      [chargeTypeFor]: value.value,
    }

    nurseryIntegrationsActions.updateIntegration({
      body,
      onSuccess: () => this.handleChangeChargeTypeSuccess(chargeTypeFor),
      params: [nurseryStripeNurseryIntegration.id, {
        groups: GROUPS,
      }],
    })
  }

  handleChangeChargeType = (value, chargeTypeFor) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      onConfirm: () => this.handleChangeChargeTypeConfirmed(value, chargeTypeFor),
      text: (
        <React.Fragment>
          <Typography bold>
            {CHARGE_TYPES_FOR.BACS === chargeTypeFor
              ? i18n.t('module:Management:Finance:PaymentGateway:changeBACSPaymentDate')
              : i18n.t('module:Management:Finance:PaymentGateway:changeCardPaymentDate')}
          </Typography>
          <Typography>
            {CHARGE_TYPES_FOR.BACS === chargeTypeFor
              ? i18n.t('module:Management:Finance:PaymentGateway:changePaymentDateBACS', {
                day: value.label,
              })
              : i18n.t('module:Management:Finance:PaymentGateway:changePaymentDate_1', {
                day: value.label,
              })}
          </Typography>
          <Typography>
            {i18n.t('module:Management:Finance:PaymentGateway:changePaymentDate_2')}
          </Typography>
        </React.Fragment>
      ),
    })
  }

  handleChangeCapabilityFailed = (response, value, capability) => {
    const { modalActions, modalConsts } = this.props

    if (response?.message) {
      modalActions.show(modalConsts.TYPES.ALERT, {
        text: response.message,
      })
    }

    this.setState((prevState) => ({
      ...prevState,
      capabilitiesEnabled: {
        ...prevState.capabilitiesEnabled,
        [capability]: !value,
      },
      isFetchingCapability: false,
    }))
  }

  updateStripeNurseryIntegration = (response, value, capability) => {
    const { nurseriesActions, nurseryStripeNurseryIntegration } = this.props

    nurseriesActions.getNurseryStripeIntegration({
      onSuccess: () => this.handleChangeCapabilitySuccess(response, value, capability),
      params: [nurseryStripeNurseryIntegration.id, {
        groups: STRIPE_INTEGRATION_GROUPS,
      }],
    })
  }

  handleChangeCapabilitySuccess = (response, value, capability) => {
    const { snackbarActions } = this.props
    let finalValue = value

    if (!response.data.enabled) {
      finalValue = false
    }

    this.setState((prevState) => ({
      ...prevState,
      capabilitiesEnabled: {
        ...prevState.capabilitiesEnabled,
        [capability]: finalValue,
      },
      isFetchingCapability: false,
    }))

    const prefix = 'module:Management:Finance:PaymentGateway:enableOrDisableSnackbar'

    snackbarActions.show({
      message: i18n.t(`${prefix}:text`, {
        status: finalValue ? i18n.t(`${prefix}:enabled`) : i18n.t(`${prefix}:disabled`),
        type: i18n.t(`${prefix}:capabilities:${capability}`),
      }),
    })
  }

  handleChangeAmericanExpressSuccess = (value) => {
    const { snackbarActions } = this.props

    this.setState((prevState) => ({
      ...prevState,
      isFetchingCapability: false,
    }))

    const prefix = 'module:Management:Finance:PaymentGateway:enableOrDisableSnackbar'

    snackbarActions.show({
      message: i18n.t(`${prefix}:text`, {
        status: value ? i18n.t(`${prefix}:enabled`) : i18n.t(`${prefix}:disabled`),
        type: i18n.t(`${prefix}:capabilities:${CAPABILITIES_TYPES.AMERICAN_EXPRESS}`),
      }),
    })
  }

  handleChangeAmericanExpress = (value) => {
    const { nurseryIntegrationsActions, nurseryStripeNurseryIntegration } = this.props

    this.setState((prevState) => ({
      ...prevState,
      capabilitiesEnabled: {
        ...prevState.capabilitiesEnabled,
        [CAPABILITIES_TYPES.AMERICAN_EXPRESS]: value,
      },
      isFetchingCapability: true,
    }))

    nurseryIntegrationsActions.updateIntegration({
      body: { amexEnabled: value },
      onSuccess: () => this.handleChangeAmericanExpressSuccess(value),
      params: [nurseryStripeNurseryIntegration.id, {
        groups: GROUPS,
      }],
    })
  }

  handleChangeCapabilityConfirmed = (value, capability) => {
    const { nurseryIntegrationsActions, nurseryStripeNurseryIntegration } = this.props
    const { capabilities } = nurseryStripeNurseryIntegration

    const body = {
      enabled: value,
    }

    const capabilityId = _.get(_.find(capabilities, ({ code }) => (
      capability === code
    )), 'id')

    this.setState((prevState) => ({
      ...prevState,
      capabilitiesEnabled: {
        ...prevState.capabilitiesEnabled,
        [capability]: value,
      },
      isFetchingCapability: true,
    }))

    nurseryIntegrationsActions.updateStripeCapability({
      body,
      onFailed: (response) => this.handleChangeCapabilityFailed(response, value, capability),
      onSuccess: (response) => this.updateStripeNurseryIntegration(response, value, capability),
      params: [capabilityId],
    })
  }

  handleChangeCapability = (capability) => () => {
    const { modalActions, modalConsts } = this.props
    const { capabilitiesEnabled } = this.state

    const value = !capabilitiesEnabled[capability]

    if (capability === CAPABILITIES_TYPES.AMERICAN_EXPRESS) {
      return this.handleChangeAmericanExpress(value)
    }

    let title = ''
    let subtitle = ''

    if (value) {
      title = `${i18n.t('module:Management:Finance:PaymentGateway:enableOrDisableModal:Enable')} `
    } else {
      title = `${i18n.t('module:Management:Finance:PaymentGateway:enableOrDisableModal:Disable')} `
    }

    switch (capability) {
      case CAPABILITIES_TYPES.BACS: {
        title += `${i18n.t('module:Management:Finance:PaymentGateway:enableOrDisableModal:BACS:title')}?`

        if (value) {
          subtitle = (
            <React.Fragment>
              <Typography margin="10px 0 20px" bold>
                {i18n.t('module:Management:Finance:PaymentGateway:enableOrDisableModal:BACS:enable_1')}
              </Typography>
              <Typography>
                {i18n.t('module:Management:Finance:PaymentGateway:enableOrDisableModal:newsletter')}
              </Typography>
            </React.Fragment>
          )
        } else {
          subtitle = (
            <React.Fragment>
              <Typography margin="10px 0 20px" bold>
                {i18n.t('module:Management:Finance:PaymentGateway:enableOrDisableModal:BACS:disable_1')}
              </Typography>
              <Typography>
                {i18n.t('module:Management:Finance:PaymentGateway:enableOrDisableModal:BACS:disable_2')}
              </Typography>
              <Typography>
                {i18n.t('module:Management:Finance:PaymentGateway:enableOrDisableModal:newsletter')}
              </Typography>
            </React.Fragment>
          )
        }

        break
      }
      case CAPABILITIES_TYPES.CARD: {
        title += `${i18n.t('module:Management:Finance:PaymentGateway:enableOrDisableModal:Card:title')}?`

        if (value) {
          subtitle = (
            <Typography margin="10px 0 0">
              {i18n.t('module:Management:Finance:PaymentGateway:enableOrDisableModal:Card:enable_1')}
            </Typography>
          )
        } else {
          subtitle = (
            <Typography margin="10px 0 0">
              {i18n.t('module:Management:Finance:PaymentGateway:enableOrDisableModal:Card:disable_1')}
            </Typography>
          )
        }

        break
      }
      default:
        break
    }

    return modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: (
        i18n.t(`module:Management:Finance:PaymentGateway:enableOrDisableModal:${value ? 'Enable' : 'Disable'}`)
      ),
      onConfirm: () => this.handleChangeCapabilityConfirmed(value, capability),
      text: (
        <React.Fragment>
          <Typography variant="h6">
            {title}
          </Typography>
          {subtitle}
        </React.Fragment>
      ),
    })
  }

  render() {
    const { isBacsCapabilityEnabled, nurseryStripeNurseryIntegration, subdomainCurrency } = this.props
    const {
      bacsChargeDay,
      capabilitiesEnabled,
      cardChargeDay,
      initialized,
      isFetching,
      isFetchingCapability,
      isProcessing,
      isProcessingChargeTypeFor,
      isRemovingProcessing,
    } = this.state

    return (
      <ManagementPaymentGatewayView
        bacsChargeDay={bacsChargeDay}
        capabilitiesEnabled={capabilitiesEnabled}
        cardChargeDay={cardChargeDay}
        initialized={initialized}
        isBacsCapabilityEnabled={isBacsCapabilityEnabled}
        isFetching={isFetching}
        isFetchingCapability={isFetchingCapability}
        isProcessing={isProcessing}
        isProcessingChargeTypeFor={isProcessingChargeTypeFor}
        isRemovingProcessing={isRemovingProcessing}
        stripeNurseryIntegration={nurseryStripeNurseryIntegration}
        subdomainCurrency={subdomainCurrency}
        onChangeCapability={this.handleChangeCapability}
        onChangeChargeType={this.handleChangeChargeType}
        onConnectWithStripe={this.handleConnectWithStripe}
        onDisconnectStripe={this.handleDisconnectStripe}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseryContextSelectors,
  nurseryIntegrationsStripeState,
  params,
  shellSelectors,
}) => ({
  errorMessages: appSelectors.getErrorMessages(nurseryIntegrationsStripeState),
  isBacsCapabilityEnabled: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.MOBILE_PAYMENTS_BACS_CAPABILITY],
  }),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  nurseryStripeNurseryIntegration: nurseryContextSelectors.getNurseryStripeIntegration(state),
  subdomainCurrency: shellSelectors.getSubdomainCurrency(state),
})

const enhance = compose(
  withAppService,
  withModalService,
  withNurseriesService,
  withNurseryContextService,
  withNurseryIntegrationsService,
  withRouter,
  withShellService,
  withSnackbarService,
  connect(mapState),
)

ManagementPaymentGatewayContainer.authParams = {
  antiRoles: [
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
  ],
  flags: [FEATURE_FLAGS.MOBILE_PAYMENTS],
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

export default enhance(ManagementPaymentGatewayContainer)
