import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledLabel = styled.div`
  border-radius: 4px;
  color: #FFF;
  font-size: 14px;
  padding: 2px 6px;
  background: ${NEUTRAL_COLOURS.GRAY};
`
