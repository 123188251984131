import React from 'react'

import { Callout, Section } from 'components'

import i18n from 'translations'

import AddBacsDetailsForm from './components/AddBacsDetailsForm'

interface AddBacsDetailsViewProps {
  childId: string
  errorMessages?: string[]
  initialValues?: any
  isStripeType: boolean
  isSubmitting: boolean
  onSubmit: (fields) => void
}

const AddBacsDetailsView: React.FC<AddBacsDetailsViewProps> = ({
  childId,
  errorMessages,
  initialValues,
  isStripeType,
  isSubmitting,
  onSubmit,
}) => (
  <Section title={i18n.t('module:Children:Child:Finance:BacsDetails:title')}>
    <Callout content={errorMessages} error />
    <AddBacsDetailsForm
      childId={childId}
      initialValues={initialValues}
      isLoading={isSubmitting}
      isStripeType={isStripeType}
      onSubmit={onSubmit}
    />
  </Section>
)

export default AddBacsDetailsView
