import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import colors, { NEUTRAL_COLOURS } from 'constants/colors'

import { Button, DataTable, Typography } from 'components'

import i18n from 'translations'

import {
  StyledFooter,
  StyledLegend,
  StyledLegendContainer,
  StyledLegendIcon,
  StyledLegendItem,
  StyledOverviewContainer,
  StyledTitle,
  StyledValue,
} from './OverviewReportStyled'

const OverviewReport = ({
  onLoadMoreElements,
  overviewNextPageIsLoading,
  reportData,
  reportHeader,
  rooms,
}) => {
  const renderDateColumn = () => (
    <React.Fragment>
      <DataTable.Row noBorder stickyElement />
      <DataTable.Row noBorder stickyElement />
      <DataTable.Row>
        <DataTable.Column />
      </DataTable.Row>
      {_.map(reportData, (data, firstDayOfWeek) => (
        <DataTable.Row key={moment(firstDayOfWeek).format('x')}>
          {moment(firstDayOfWeek).startOf('week').format('DD/MM/YY')}
          {' - '}
          {moment(firstDayOfWeek).endOf('week').format('DD/MM/YY')}
        </DataTable.Row>
      ))}
    </React.Fragment>
  )

  const renderRow = (data, weekNumber, room, weekDate) => (
    <DataTable.Row key={`${room.id}_${weekDate}_${weekNumber}`} noPadding>
      {/* eslint-disable-next-line no-nested-ternary */}
      {data?.[room.id]?.periods?.[weekNumber]?.closed ? (
        <DataTable.Column noBorder>
          <DataTable.Row>
            <span>Closed</span>
          </DataTable.Row>
        </DataTable.Column>
      ) : (
        data?.[room.id]?.periods?.[weekNumber]?.breakdowns
          ? _.map(data[room.id].periods[weekNumber].breakdowns, ({ label, result: { availableCapacity } }, index) => (
            <DataTable.Column key={`${room.id}_${weekDate}_${weekNumber}_${label}`} noBorder>
              <DataTable.Row gray={0 === index % 2} width={64}>
                <StyledValue
                  aqua={5 <= availableCapacity}
                  orange={5 > availableCapacity && 0 < availableCapacity}
                  pink={0 > availableCapacity}
                >
                  {availableCapacity}
                  {data[room.id].periods[weekNumber].closed}
                </StyledValue>
              </DataTable.Row>
            </DataTable.Column>
          )) : (
            <React.Fragment>
              <DataTable.Column noBorder>
                <DataTable.Row gray />
              </DataTable.Column>
              <DataTable.Column noBorder>
                <DataTable.Row />
              </DataTable.Column>
            </React.Fragment>
          )
      )}
    </DataTable.Row>
  )

  const renderContent = () => _.map(rooms, (room) => (
    <DataTable.Column key={room.id}>
      <DataTable.Row noBorder>
        <Typography variant="div" bold>
          {room.name}
          <Typography color={colors.gray} fontSize={14} padding="2px 0 0" variant="div">
            {i18n.t('global:ageMonths', { ageMonths: `${room.startAgeInMonths}-${room.endAgeInMonths}` })}
          </Typography>
        </Typography>
      </DataTable.Row>
      <DataTable.Row full noBorder noPadding>
        {_.map(reportHeader, ({ label }, weekNumber) => (
          <DataTable.Column>
            <DataTable.Row noBorder>
              <strong>
                {label?.split(' ')[0]}
              </strong>
            </DataTable.Row>
            <DataTable.Row noPadding>
              <DataTable.Column>
                <DataTable.Row width={64} gray>
                  AM
                </DataTable.Row>
              </DataTable.Column>
              <DataTable.Column noBorder>
                <DataTable.Row width={64}>
                  PM
                </DataTable.Row>
              </DataTable.Column>
            </DataTable.Row>
            {_.map(reportData, (data, key) => renderRow(data, weekNumber, room, key))}
          </DataTable.Column>
        ))}
      </DataTable.Row>
    </DataTable.Column>
  ))

  const renderLegend = () => (
    <StyledLegendContainer>
      <StyledLegend>
        <StyledLegendItem>
          <StyledLegendIcon aqua />
          <StyledTitle>
            ≥ 5 free spaces
          </StyledTitle>
        </StyledLegendItem>
        <StyledLegendItem>
          <StyledLegendIcon orange />
          <StyledTitle>
            {'< 5 free spaces'}
          </StyledTitle>
        </StyledLegendItem>
        <StyledLegendItem>
          <StyledLegendIcon pink />
          <StyledTitle>
            Over capacity
          </StyledTitle>
        </StyledLegendItem>
      </StyledLegend>
    </StyledLegendContainer>
  )

  const renderFooter = () => !overviewNextPageIsLoading && (
    <StyledFooter>
      <Button
        label="View more"
        onClick={onLoadMoreElements}
      />
    </StyledFooter>
  )

  return (
    <StyledOverviewContainer>
      {renderLegend()}
      <DataTable>
        <DataTable.Head>
          {renderDateColumn()}
        </DataTable.Head>
        <DataTable.ContentScrollable
          data={[{
            data: renderContent(),
            stickyHeader: () => [_.map(rooms, () => (
              <DataTable.Column>
                <DataTable.Row full noBorder noPadding>
                  {_.map(reportHeader, ({ label }) => (
                    <DataTable.Column>
                      <DataTable.Row background={NEUTRAL_COLOURS.WHITE} noBorder>
                        <strong>
                          {label?.split(' ')[0]}
                        </strong>
                      </DataTable.Row>
                      <DataTable.Row noPadding>
                        <DataTable.Column>
                          <DataTable.Row width={64} gray>
                            AM
                          </DataTable.Row>
                        </DataTable.Column>
                        <DataTable.Column noBorder>
                          <DataTable.Row background={NEUTRAL_COLOURS.WHITE} width={64}>
                            PM
                          </DataTable.Row>
                        </DataTable.Column>
                      </DataTable.Row>
                    </DataTable.Column>
                  ))}
                </DataTable.Row>
              </DataTable.Column>
            ))],
          }]}
          enableCustomScrollbar
        />
      </DataTable>
      {renderFooter()}
    </StyledOverviewContainer>
  )
}

export default OverviewReport
