import _ from 'lodash'
import { createSelector } from 'reselect'

const getDailyDiaryNappies = (state) => state.dailyDiaryNappies

export const getDailyDiaryNappiesListWithoutRecords = createSelector(
  [getDailyDiaryNappies],
  (state) => state.listWithoutRecords,
)

export const getDailyDiaryNappiesListWithoutRecordsData = createSelector(
  [getDailyDiaryNappiesListWithoutRecords],
  (state) => state.data,
)

export const getDailyDiaryNappiesListWithoutRecordsMeta = createSelector(
  [getDailyDiaryNappiesListWithoutRecords],
  (state) => state.meta,
)

export const getChildrenDailyDiaryNappiesListWithoutRecordsData = createSelector(
  [getDailyDiaryNappiesListWithoutRecordsData],
  (items) => _.map(items, ({ childRegister: { child } }) => child),
)

export const hasDailyDiaryNappiesListWithoutRecordsData = createSelector(
  [getDailyDiaryNappiesListWithoutRecordsMeta],
  (meta) => meta?.total_results > 0, // eslint-disable-line
)
