import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Avatar, Typography } from 'components'

import { StyledAvatarContainer } from './PinProfileAvatarStyled'

const PinProfileAvatar = ({
  firstName,
  name,
  photo,
  surname,
}) => (
  <StyledAvatarContainer>
    <Avatar
      avatarSize="medium"
      gap={15}
      initials={[firstName, surname]}
      src={photo}
      title={(
        <Typography color={NEUTRAL_COLOURS.BASIC} variant="span" bold>
          {name}
        </Typography>
      )}
    />
  </StyledAvatarContainer>
)

export default PinProfileAvatar
