import React, { PropsWithChildren } from 'react'

import { Icon } from 'components'

import {
  StyledActionsContainer,
  StyledContainer,
  StyledContent,
  StyledIconContainer,
  StyledIconImage,
} from './EmptyStateStyled'

interface EmptyStateProps {
  actions?: React.ReactNode
  icon?: IconType
  iconColor?: string
  iconComponent?: React.ReactNode
  iconComponentSmallMargin?: boolean
  iconSize?: number
  image?: string
  padding?: string
  text1?: React.ReactElement | string
  text2?: string
  text3?: string
}

const EmptyState: React.FC<PropsWithChildren<EmptyStateProps>> = ({
  actions,
  icon,
  iconColor,
  iconComponent,
  iconComponentSmallMargin,
  iconSize = 60,
  image,
  padding,
  text1,
  text2,
  text3,
}) => {
  const renderIconImage = () => {
    if (iconComponent) {
      return iconComponent
    }

    if (icon) {
      return <Icon color={iconColor || '#CCC'} height={iconSize} icon={icon} />
    }

    return <StyledIconImage alt="" src={image} />
  }

  if (!icon && !image && !iconComponent) {
    return null
  }

  const renderActions = () => {
    if (!actions) {
      return null
    }

    return (
      <StyledActionsContainer>
        {actions}
      </StyledActionsContainer>
    )
  }

  return (
    <StyledContainer $padding={padding}>
      <StyledIconContainer $iconComponentSmallMargin={iconComponentSmallMargin}>
        {renderIconImage()}
      </StyledIconContainer>
      <StyledContent>
        {text1 && (
          <div>
            {text1}
          </div>
        )}
        {text2 && (
          <p>
            {text2}
            {text3 && text3}
          </p>
        )}
      </StyledContent>
      {renderActions()}
    </StyledContainer>
  )
}

export default EmptyState
