import _ from 'lodash'

import React, { PropsWithChildren } from 'react'

import {
  StyledContent,
  StyledEmptyState,
  StyledFooter,
  StyledItem,
  StyledLeftColumn,
  StyledLists,
  StyledRow,
} from './ListAdvancedStyled'

interface ListAdvancedRowProps {
  footer?: boolean
  itemComponent?: (item: any) => React.ReactNode
  items?: any[]
  leftColumn?: React.ReactNode
  noItems?: string | React.ReactNode
  withoutItemWrapper?: boolean
}

const ListAdvancedRow: React.FC<PropsWithChildren<ListAdvancedRowProps>> = ({
  footer,
  itemComponent,
  items,
  leftColumn,
  noItems,
  withoutItemWrapper,
}) => (
  <StyledRow>
    <StyledLeftColumn>
      {leftColumn}
    </StyledLeftColumn>
    <StyledContent>
      {items?.length ? (
        <StyledLists>
          {withoutItemWrapper && _.map(items, itemComponent)}
          {!withoutItemWrapper && _.map(items, (item) => (
            <StyledItem>
              {itemComponent(item)}
            </StyledItem>
          ))}
        </StyledLists>
      ) : (
        <StyledEmptyState>
          {noItems}
        </StyledEmptyState>
      )}
      <StyledFooter>
        {footer}
      </StyledFooter>
    </StyledContent>
  </StyledRow>
)

export default ListAdvancedRow
