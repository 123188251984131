import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledCommentWrapper = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-gap: 8px;
  margin-bottom: 12px;
`

export const StyledComment = styled.div`
  border-radius: 6px;
  padding: 16px 16px 12px;
  background: ${NEUTRAL_COLOURS.GRAY_TERTIARY};
`

export const StyledCommentHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;  
`

interface StyledFooterProps {
  $noPadding: boolean
}

export const StyledFooter = styled.div<StyledFooterProps>`
  padding-top: 10px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 15px auto 1fr;
  align-items: center;
  
  ${({ $noPadding }) => $noPadding && `
    padding-top: 0;
  `}
`
