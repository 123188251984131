import { createSelector } from 'reselect'

const getNurseryPublic = (state) => state.nurseries.publicNursery

export const getNurseryPublicData = createSelector(
  [getNurseryPublic],
  (publicNursery) => publicNursery.data,
)

export const getPublicNurseryEnquiryUrl = createSelector(
  [getNurseryPublicData],
  (data) => data?.find((item) => item.enquiry)?.enquiry?.redirectUrl,
)
