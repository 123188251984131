import ServiceBase from 'services/ServiceBase'

class BalanceAdjustmentsApiClient extends ServiceBase {
  get = (id) => this.request({
    path: `/v2/balance_adjustments/${id}`,
  })

  create = (payload) => this.request({
    body: payload,
    method: 'POST',
    path: '/v2/balance_adjustments',
  })
}

export default BalanceAdjustmentsApiClient
