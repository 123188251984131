import React from 'react'

import i18n from 'translations'

import {
  StyledContainer,
  StyledContent,
  StyledContentContainer,
  StyledHexagon,
  StyledLogo,
  StyledLogoContainer,
  StyledPrimaryText,
} from './HeaderStyled'

const Header = ({ dashboardIcon, dashboardTitle }) => (
  <StyledContainer>
    <StyledLogoContainer>
      <StyledHexagon src="/images/hexagon.svg" />
      <StyledLogo alt={dashboardTitle} src={dashboardIcon} />
    </StyledLogoContainer>
    <StyledContentContainer>
      <StyledContent>
        <StyledPrimaryText>
          {i18n.t('module:Management:GettingStarted:headerTitle1')}
        </StyledPrimaryText>
        {i18n.t('module:Management:GettingStarted:headerTitle2')}
      </StyledContent>
    </StyledContentContainer>
  </StyledContainer>
)

export default Header
