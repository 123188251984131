import moment from 'moment'
import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change, getFormValues, stopSubmit } from 'redux-form'

import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withDailyDiarySleepsService } from 'services/legacy/dailyDiarySleeps'
import { withModalService } from 'services/utils/modal'

import SleepsTabView from './SleepsTabView'
import { SLEEPS_TAB_FORM } from './components/SleepsTabForm'

const GROUPS = {
  read: ['sleepRecord'],
}

class SleepsTabContainer extends Component {
  handleChangeDidNotSleep = () => {
    const { changeFieldValue } = this.props

    changeFieldValue('endTime', null)
  }

  handleSubmitFailed = (response) => {
    const { injectValidation } = this.props
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    return injectValidation(errors)
  }

  handleSubmitSuccess = ({ data }) => {
    const { dailyDiarySleepsListWithoutRecordsState, onSuccess } = this.props
    const { data: withoutRecords } = dailyDiarySleepsListWithoutRecordsState

    const records = _.map(data, ({ child, record }) => ({
      data: {
        ...record,
        child: {
          id: child.id,
        },
      },
      record: _.find(withoutRecords, ({ childRegister }) => childRegister.child.id === child.id),
    }))

    onSuccess({ records })
  }

  handleSubmit = (values) => {
    const { dailyDiarySleepsActions, dailyDiarySleepsSelectors, date, selectedChildren } = this.props
    const body = dailyDiarySleepsSelectors.getValuesForm(values)
    const recordedAt = moment(date)
      .set('hour', moment().format('HH'))
      .set('minute', moment().format('mm'))
      .toISOString()
    const criteria = _.map(selectedChildren, (child, index) => ({
      field: `id[${index}]`,
      value: child.id,
    }))

    dailyDiarySleepsActions.addRecord({
      body: {
        recordedAt,
        ...body,
      },
      onFailed: this.handleSubmitFailed,
      onSuccess: this.handleSubmitSuccess,
      params: [{
        criteria,
        groups: GROUPS,
      }],
    })
  }

  render() {
    const {
      dailyDiarySleepsSelectors,
      formValues,
      isSubmitting,
      onCancel,
      selectedChildren,
    } = this.props

    const initialValues = dailyDiarySleepsSelectors.getInitialValues({
      startTime: moment(),
    })

    return (
      <SleepsTabView
        formValues={formValues}
        initialValues={initialValues}
        isSubmitting={isSubmitting}
        selectedChildren={selectedChildren}
        onCancel={onCancel}
        onChangeDidNotSleep={this.handleChangeDidNotSleep}
        onRemoveChild={this.handleRemoveChild}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  changeFieldValue: (field, value) => change(SLEEPS_TAB_FORM, field, value),
  injectValidation: (params) => stopSubmit(SLEEPS_TAB_FORM, params),
}

const mapState = (state, {
  appSelectors,
  dailyDiarySleepsSingleState,
}) => ({
  formValues: getFormValues(SLEEPS_TAB_FORM)(state),
  isSubmitting: appSelectors.getIsSubmitting(
    dailyDiarySleepsSingleState,
  ),
})

const enhance = compose(
  withAppService,
  withDailyDiarySleepsService,
  withModalService,
  connect(mapState, mapDispatch),
)

export default enhance(SleepsTabContainer)
