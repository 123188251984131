import React from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { getIsLoggedIn } from 'utils/auth/selectors'

import MissingView from './MissingView'

const mapState = (state) => ({
  isLoggedIn: getIsLoggedIn(state),
})

const connector = connect(mapState)

export type PropsFromRedux = ConnectedProps<typeof connector>

const MissingContainer: React.FC<PropsFromRedux> = ({ isLoggedIn }) => (
  <MissingView
    isLoggedIn={isLoggedIn}
  />
)

const enhance = compose(connector)

export default enhance(MissingContainer)
