import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { VIEWING_STATUS_OPTIONS } from '../constants'

const getEnquiryViewings = (state) => state.enquiryViewings

export const getEnquiryViewingsSingleSelector = createSelector(
  [getEnquiryViewings],
  (state) => state?.single,
)

export const getEnquiryViewingsSingleInitialValues = (showAroundData) => {
  if (!showAroundData) {
    return null
  }

  const {
    assignedPerson,
    note,
    showAroundDate,
    status,
  } = showAroundData

  return {
    assignedPerson: assignedPerson ? {
      label: assignedPerson?.name,
      value: assignedPerson?.id,
    } : undefined,
    note,
    showAroundDate,
    showAroundTime: moment(showAroundDate),
    status: _.find(VIEWING_STATUS_OPTIONS, { value: status }),
  }
}

export const getPayload = (formValues) => {
  if (!formValues) {
    return null
  }

  const {
    assignedPerson,
    enquiryId,
    note,
    showAroundDate,
    showAroundTime,
    status,
  } = formValues

  return {
    assignedPerson: assignedPerson ? { id: assignedPerson?.value } : undefined,
    enquiry: {
      id: +enquiryId,
    },
    note,
    showAroundDate: moment(showAroundDate)
      .set({
        hour: showAroundTime.get('hour'),
        minute: showAroundTime.get('minute'),
        second: showAroundTime.get('second'),
      })
      .toISOString(),
    status: status?.value,
  }
}
