import React from 'react'

import { StyledItemContainer } from './RegisterListStyled'

const RegisterListItem = ({ children }) => (
  <StyledItemContainer>
    {children}
  </StyledItemContainer>
)

export default RegisterListItem
