import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { generateRoute } from 'utils/routing'

import { FORMATIVE_REPORTS_STATUSES } from 'services/legacy/formativeReports/constants'

import {
  Avatar,
  Callout,
  Checkbox,
  DefinitionList,
  NameArchived,
  Page,
  Section,
  Spinner,
  Toolbar,
  Typography,
} from 'components'

import i18n from 'translations'

import FormativeReportsPreviewForm from './components/FormativeReportsPreviewForm'
import { StyledToolbar } from './FormativeReportsPreviewStyled'

const FormativeReportsPreviewView = ({
  errorMessages,
  formativeReport,
  initialized,
  isChildContext,
  isFetching,
  isFetchingNurserySettings,
  isGrantedToEditFormativeReport,
  onRemoveClick,
  onSendClick,
  onSharedWithParentsClick,
  onSubmit,
  sharedWithParents,
  ...rest
}) => {
  const actions = (
    <Section.Actions
      options={[
        {
          auth: FORMATIVE_REPORTS_STATUSES.APPROVED.value === formativeReport?.status,
          onClick: onSendClick,
          type: 'send',
        },
        {
          auth: isGrantedToEditFormativeReport,
          onClick: onRemoveClick,
          type: 'delete',
        }]}
    />
  )

  const renderChildDetails = () => {
    const { child, childSnapshot, dateTo } = formativeReport || {}
    const { birthDate, keyWorkers = [], nurseryClass } = childSnapshot || {}
    const { firstName, id, name, photo, surname } = child || {}

    const keyPerson = keyWorkers && keyWorkers.length
      ? (
        _.map(keyWorkers,
          ({ fullName }, i) => `${fullName}${i !== keyWorkers.length - 1 ? ', ' : ''}`,
        )
      )
      : '---'

    const renderBasicInfo = () => (
      <Toolbar.Group>
        <Toolbar.Item justifyContentOnMobile="flex-start">
          <DefinitionList
            margin="0 50px 20px 0"
            vertical
          >
            <DefinitionList.Item
              label={_.upperFirst(i18n.t('global:age'))}
              value={i18n.t('global:ageMonths', { ageMonths: moment(dateTo).diff(birthDate, 'months') })}
            />
          </DefinitionList>
        </Toolbar.Item>
        <Toolbar.Item justifyContentOnMobile="flex-start">
          <DefinitionList
            margin="0 50px 20px 0"
            vertical
          >
            <DefinitionList.Item
              label={_.startCase(i18n.t('global:keyPerson'))}
              value={keyPerson}
            />
          </DefinitionList>
        </Toolbar.Item>
        <Toolbar.Item justifyContentOnMobile="flex-start">
          <DefinitionList
            margin="0 0 20px 0"
            vertical
          >
            <DefinitionList.Item
              label={_.upperFirst(i18n.t('global:room'))}
              value={<NameArchived defaultValue="---" item={nurseryClass} />}
            />
          </DefinitionList>
        </Toolbar.Item>
      </Toolbar.Group>
    )

    const renderShareOnParentApp = () => (
      <Toolbar.Group>
        <Toolbar.Item>
          <Checkbox
            label={i18n.t('module:Learning:FormativeReports:FormativeReportsPreview:shareOnParentApp')}
            value={sharedWithParents}
            zIndex={1}
            onChange={onSharedWithParentsClick}
          />
        </Toolbar.Item>
      </Toolbar.Group>
    )

    const renderAvatar = () => (
      <Toolbar.Group>
        <Avatar
          initials={[firstName, surname]}
          src={photo}
          title={(
            <Typography bold>
              {name}
            </Typography>
          )}
          to={generateRoute('CHILDREN.CHILD', { childId: id })}
        />
      </Toolbar.Group>
    )

    const renderChildDetailsForChildPage = () => (
      <Toolbar bottomLine>
        {renderBasicInfo()}
        {renderShareOnParentApp()}
      </Toolbar>
    )

    const renderChildDetailsForLearningAndApprovalsPage = () => (
      <React.Fragment>
        <StyledToolbar>
          <Toolbar>
            {renderAvatar()}
            {renderShareOnParentApp()}
          </Toolbar>
        </StyledToolbar>
        <Toolbar bottomLine>
          {renderBasicInfo()}
        </Toolbar>
      </React.Fragment>
    )

    return isChildContext ? renderChildDetailsForChildPage() : renderChildDetailsForLearningAndApprovalsPage()
  }

  const renderContent = () => {
    if ((!initialized && isFetching) || isFetchingNurserySettings || !formativeReport) {
      return (
        <Spinner />
      )
    }

    return (
      <React.Fragment>
        {renderChildDetails()}
        <FormativeReportsPreviewForm
          formativeReport={formativeReport}
          isGrantedToEditFormativeReport={isGrantedToEditFormativeReport}
          onSingleFieldSubmit={onSubmit}
          onSubmit={() => {}}
          {...rest}
        />
      </React.Fragment>
    )
  }

  return (
    <Page.Section
      actions={!isFetching && isGrantedToEditFormativeReport && actions}
      isLoading={(!initialized && isFetching) || isFetchingNurserySettings || !formativeReport}
      title={i18n.t('module:Learning:FormativeReports:FormativeReportsPreview:title')}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default FormativeReportsPreviewView
