import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as montessoriCategoriesSelectors from './selectors'

const withMontessoriCategoriesService = (WrappedComponent) => {
  const mapState = ({ montessoriCategories }) => ({ montessoriCategories })

  const mapDispatch = injectActions('montessoriCategoriesActions', actions)

  const Component = ({ montessoriCategories, montessoriCategoriesActions, ...others }) => (
    <WrappedComponent
      montessoriCategoriesActions={montessoriCategoriesActions}
      montessoriCategoriesListState={montessoriCategories.list}
      montessoriCategoriesSelectors={montessoriCategoriesSelectors}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withMontessoriCategoriesService
