import { generateBasicActions } from 'utils/service'

import {
  CLEAR_CHILD_CARER_RELATION,
  CREATE_CHILD_CARER_RELATION,
  CREATE_CHILD_CARER_RELATION_FAILED,
  CREATE_CHILD_CARER_RELATION_SUCCESS,
  REMOVE_CHILD_CARER_RELATION,
  REMOVE_CHILD_CARER_RELATION_FAILED,
  REMOVE_CHILD_CARER_RELATION_SUCCESS,
  UPDATE_CHILD_CARER_RELATION,
  UPDATE_CHILD_CARER_RELATION_FAILED,
  UPDATE_CHILD_CARER_RELATION_SUCCESS,
} from './constants'

export const create = (options = {}) => ({ carerChildRelationsApiClient }) => generateBasicActions.create({
  constants: {
    failed: CREATE_CHILD_CARER_RELATION_FAILED,
    init: CREATE_CHILD_CARER_RELATION,
    success: CREATE_CHILD_CARER_RELATION_SUCCESS,
  },
  options,
  service: carerChildRelationsApiClient,
  serviceMethod: 'create',
})

export const update = (options = {}) => ({ carerChildRelationsApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_CHILD_CARER_RELATION_FAILED,
    init: UPDATE_CHILD_CARER_RELATION,
    success: UPDATE_CHILD_CARER_RELATION_SUCCESS,
  },
  options,
  service: carerChildRelationsApiClient,
  serviceMethod: 'update',
})

export const remove = (options = {}) => ({ carerChildRelationsApiClient }) => generateBasicActions.remove({
  constants: {
    failed: REMOVE_CHILD_CARER_RELATION_FAILED,
    init: REMOVE_CHILD_CARER_RELATION,
    success: REMOVE_CHILD_CARER_RELATION_SUCCESS,
  },
  options,
  service: carerChildRelationsApiClient,
  serviceMethod: 'remove',
})

export const clear = () => ({
  type: CLEAR_CHILD_CARER_RELATION,
})
