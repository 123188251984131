import { ROLES } from 'constants/security'

import * as nurserySelectors from 'services/security/selectors'

const ManagementPaymentTypesWrapper = ({ children }) => children

ManagementPaymentTypesWrapper.authParams = (state) => {
  const isOrganisationNursery = nurserySelectors.isOrganisationNursery(state.authentication)

  if (isOrganisationNursery) {
    return {
      roles: [
        ROLES.ORGANIZATION_DIRECTOR,
        ROLES.ORGANIZATION_NATIONAL_ADMIN,
        ROLES.ORGANIZATION_FINANCE_ADMIN,
        ROLES.ORGANIZATION_LINE_MANAGER,
        ROLES.NURSERY_MANAGER,
        ROLES.NURSERY_ADMIN,
        ROLES.SUPER_ADMIN,
      ],
    }
  }

  return {
    roles: [ROLES.NURSERY_MANAGER, ROLES.NURSERY_ADMIN, ROLES.SUPER_ADMIN],
  }
}

export default ManagementPaymentTypesWrapper
