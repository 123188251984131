import _ from 'lodash'
import { Snackbar } from '@material-ui/core'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { getBrandingColor } from 'utils/branding'

import { withSnackbarService } from 'services/utils/snackbar'

import { Button, Spinner } from 'components'

import { StyledSnackbarContainer, StyledSpinnerWrapper } from './SnackbarRootStyled'

const SNACKBAR_VARIANT = {
  DEFAULT: {
    background: '#313131',
    value: 'default',
  },
  ERROR: {
    background: '#e74c3c',
    value: 'error',
  },
  TERTIARY: {
    background: getBrandingColor('tertiary-color'),
    value: 'tertiary',
  },
}

class SnackbarRoot extends Component {
  constructor(props) {
    super(props)

    this.intervalHandler = null
  }

  shouldComponentUpdate(nextProps) {
    const { snackbar, snackbarActions } = nextProps

    if (!_.isNull(snackbar) && _.isNull(this.intervalHandler) && false !== snackbar.autoDismiss) {
      this.intervalHandler = setInterval(
        snackbarActions.update,
        1000,
      )
    }

    if (_.isNull(snackbar) && this.intervalHandler) {
      clearInterval(
        this.intervalHandler,
      )

      this.intervalHandler = null
    }

    return true
  }

  componentWillUnmount() {
    clearInterval(
      this.intervalHandler,
    )
  }

  handleClose = () => {
    const { snackbarActions } = this.props

    snackbarActions.close()
  }

  render() {
    const { snackbar } = this.props
    const {
      anchorOrigin,
      spinnerType,
      message,
      variant = SNACKBAR_VARIANT.DEFAULT.value,
    } = snackbar || {}

    if (!snackbar) {
      return null
    }

    return (
      <StyledSnackbarContainer
        background={
          _.get(_.find(SNACKBAR_VARIANT, ({ value }) => variant === value), 'background')
        }
      >
        <Snackbar
          action={spinnerType
            ? (
              <StyledSpinnerWrapper>
                <Spinner size="standard" light />
              </StyledSpinnerWrapper>
            )
            : (
              <Button
                hierarchy="tertiary"
                icon="close"
                size="small"
                inverted
                negativeMargins
                onClick={this.handleClose}
              />
            )}
          anchorOrigin={anchorOrigin || {
            horizontal: 'right',
            vertical: 'bottom',
          }}
          message={_.isFunction(message) ? message(this.handleClose) : message}
          open
          onClose={this.handleClose}
        />
      </StyledSnackbarContainer>
    )
  }
}

const mapState = (state, { snackbarSelectors }) => ({
  snackbar: snackbarSelectors.getSnackbar(state),
})

const enhance = compose(
  withSnackbarService,
  connect(mapState),
)

export default enhance(SnackbarRoot)
