import React from 'react'

import { StyledTitleContainer } from './PlanViewStyled'

const PlanViewTitle = ({ title }) => (
  <StyledTitleContainer>
    {title}
  </StyledTitleContainer>
)

export default PlanViewTitle
