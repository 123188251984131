import _ from 'lodash'

import React, { useMemo } from 'react'

import * as OccupancyConstants from 'services/legacy/occupancy/constants'

import { DataTable } from 'components'

import ReportChildPreviewContent from './ReportChildPreviewContent'
import { getContentWidth } from './ReportContentHelper'
import { StyledTimeLabel, StyledValue } from './ReportContentStyled'

const ReportContent = ({
  expandedRooms,
  filters,
  isStaffingEnabled,
  reportChart,
  reportData,
  reportHeader,
  rooms,
}) => {
  const { absences, breakdown, extraSessions, holidays, type } = filters

  const isAmPmBreakdown = breakdown === OccupancyConstants.OCCUPANCY_REQUEST_BREAKDOWN.AM_PM
  const contentWidth = useMemo(() => getContentWidth(isAmPmBreakdown, type, breakdown), [breakdown, type])

  const renderClosedRow = (marginBottom) => (
    <DataTable.Row marginBottom={marginBottom} darkGray noPadding>
      Closed
    </DataTable.Row>
  )

  const renderStaffRequirements = (isClosed, breakdowns, nurseryClassData) => {
    if (isStaffingEnabled) {
      return null
    }

    if (isClosed) {
      return renderClosedRow()
    }

    return (
      <DataTable.Row noPadding>
        {_.map(breakdowns, ({ result }, i) => (
          <DataTable.Column key={`staffRequired_${i}`} noBorder>
            <DataTable.Row gray={0 === i % 2}>
              <StyledValue
                red={nurseryClassData.staffRequired < result.staffRequired}
              >
                {result.staffRequired}
              </StyledValue>
            </DataTable.Row>
          </DataTable.Column>
        ))}
      </DataTable.Row>
    )
  }

  const renderWeeklyBody = ({ breakdowns, children, closed, isExpanded, nurseryClassData }) => (
    <React.Fragment>
      {closed ? renderClosedRow() : (
        <DataTable.Row noPadding>
          {_.map(breakdowns, ({ result }, i) => (
            <DataTable.Column key={`children_${i}`} noBorder>
              <DataTable.Row gray={0 === i % 2}>
                <StyledValue
                  red={nurseryClassData.capacity < result.totalChildren}
                >
                  {result.totalChildren}
                </StyledValue>
              </DataTable.Row>
            </DataTable.Column>
          ))}
        </DataTable.Row>
      )}
      {isExpanded && (
        <React.Fragment>
          {_.map(children, ({ id: childId, rowCount, rowHeight }) => (closed ? renderClosedRow() : (
            <DataTable.Row
              height={isAmPmBreakdown && rowHeight}
              noPadding
            >
              {_.map(breakdowns, ({ result }, i) => (
                <DataTable.Column key={`absences_${i}`} noBorder>
                  <DataTable.Row
                    alignItems={
                      isAmPmBreakdown
                      && 1 < rowCount
                      && 'flex-start'
                    }
                    gray={0 === i % 2}
                    height={isAmPmBreakdown && rowHeight}
                  >
                    <ReportChildPreviewContent
                      breakdown={breakdown}
                      childId={childId}
                      childList={result.children}
                    />
                  </DataTable.Row>
                </DataTable.Column>
              ))}
            </DataTable.Row>
          )))}
          {closed ? renderClosedRow() : (
            <DataTable.Row noPadding>
              {_.map(breakdowns, ({ result }, i) => (
                <DataTable.Column key={`predicted_${i}`} noBorder>
                  <DataTable.Row gray={0 === i % 2}>
                    <StyledValue gray>
                      {`${result.totalPredictedChildren}`}
                    </StyledValue>
                  </DataTable.Row>
                </DataTable.Column>
              ))}
            </DataTable.Row>
          )}
          {absences && (
            closed ? renderClosedRow() : (
              <DataTable.Row noPadding>
                {_.map(breakdowns, ({ result }, i) => (
                  <DataTable.Column key={`absences_${i}`} noBorder>
                    <DataTable.Row gray={0 === i % 2}>
                      <StyledValue>
                        {`${result.totalAbsences}`}
                      </StyledValue>
                    </DataTable.Row>
                  </DataTable.Column>
                ))}
              </DataTable.Row>
            )
          )}
          {holidays && (
            closed ? renderClosedRow() : (
              <DataTable.Row noPadding>
                {_.map(breakdowns, ({ result }, i) => (
                  <DataTable.Column key={`holidays_${i}`} noBorder>
                    <DataTable.Row gray={0 === i % 2}>
                      <StyledValue>
                        {`${result.totalHolidays}`}
                      </StyledValue>
                    </DataTable.Row>
                  </DataTable.Column>
                ))}
              </DataTable.Row>
            )
          )}
          {extraSessions && (
            closed ? renderClosedRow() : (
              <DataTable.Row noPadding>
                {_.map(breakdowns, ({ result }, i) => (
                  <DataTable.Column key={`extraSessions_${i}`} noBorder>
                    <DataTable.Row gray={0 === i % 2}>
                      <StyledValue>
                        {`${result.totalExtraSessions}`}
                      </StyledValue>
                    </DataTable.Row>
                  </DataTable.Column>
                ))}
              </DataTable.Row>
            )
          )}
        </React.Fragment>
      )}
      {closed ? renderClosedRow() : (
        <DataTable.Row noPadding>
          {_.map(breakdowns, ({ result }, i) => (
            <DataTable.Column key={`availableCapacity_${i}`} noBorder>
              <DataTable.Row gray={0 === i % 2}>
                <StyledValue
                  blue={0 < result.availableCapacity}
                  gray={0 === result.availableCapacity}
                  redBackground={0 > result.availableCapacity}
                >
                  {Math.abs(result.availableCapacity)}
                </StyledValue>
              </DataTable.Row>
            </DataTable.Column>
          ))}
        </DataTable.Row>
      )}
      {renderStaffRequirements(closed, breakdowns, nurseryClassData)}
      {closed ? renderClosedRow(true) : (
        <DataTable.Row marginBottom noPadding>
          {_.map(breakdowns, ({ result }, i) => (
            <DataTable.Column key={`fte_${i}`} noBorder>
              <DataTable.Row gray={0 === i % 2}>
                <StyledValue>
                  {`${result.fte}%`}
                </StyledValue>
              </DataTable.Row>
            </DataTable.Column>
          ))}
        </DataTable.Row>
      )}
    </React.Fragment>
  )

  const renderMonthlyBody = (breakdowns, closed) => {
    if (closed) {
      return renderClosedRow(true)
    }

    return (
      <DataTable.Row marginBottom noPadding>
        {_.map(breakdowns, ({ result }, i) => (
          <DataTable.Column key={`fte_${i}`} noBorder>
            <DataTable.Row gray={0 === i % 2}>
              <StyledValue>
                {`${result.fte}%`}
              </StyledValue>
            </DataTable.Row>
          </DataTable.Column>
        ))}
      </DataTable.Row>
    )
  }

  const renderYearlyBody = (result, closed) => {
    if (closed) {
      return renderClosedRow(true)
    }

    return (
      <DataTable.Row marginBottom noPadding>
        <DataTable.Column noBorder>
          <DataTable.Row>
            <StyledValue>
              {result && `${result.fte}%`}
            </StyledValue>
          </DataTable.Row>
        </DataTable.Column>
      </DataTable.Row>
    )
  }

  const renderSummary = (index, closed) => {
    const { data } = reportChart

    if (type === OccupancyConstants.OCCUPANCY_REQUEST_TYPE.WEEKLY || !data?.length) {
      return null
    }

    return (
      <React.Fragment>
        <DataTable.Row borderTransparent noPadding quinary />
        {closed ? renderClosedRow(true) : (
          <DataTable.Row marginBottom>
            {`${data[index]}%`}
          </DataTable.Row>
        )}
      </React.Fragment>
    )
  }

  const renderNurseryClass = (data, room, dayNumber) => {
    const { children, error, periods } = data
    const { id: nurseryClassId } = room

    const isExpanded = expandedRooms[+nurseryClassId]

    if (error) {
      return (
        <React.Fragment key={`${nurseryClassId}_${dayNumber}`}>
          <DataTable.Row borderTransparent noPadding quinary />
          <DataTable.Row marginBottom>
            -
          </DataTable.Row>
        </React.Fragment>
      )
    }

    const { breakdowns, closed, result } = periods[dayNumber]

    return (
      <React.Fragment key={`${nurseryClassId}_${dayNumber}`}>
        <DataTable.Row borderTransparent quinary />
        {type === OccupancyConstants.OCCUPANCY_REQUEST_TYPE.WEEKLY
          && renderWeeklyBody({ breakdowns, children, closed, isExpanded, nurseryClassData: room })}
        {type === OccupancyConstants.OCCUPANCY_REQUEST_TYPE.MONTHLY && renderMonthlyBody(breakdowns, closed)}
        {type === OccupancyConstants.OCCUPANCY_REQUEST_TYPE.YEARLY && renderYearlyBody(result, closed)}
      </React.Fragment>
    )
  }

  const renderSlide = (day, dayNumber) => {
    const { breakdowns, closed, label } = day

    return (
      <DataTable.Column key={`${label}_${dayNumber}`}>
        <DataTable.Row noBorder>
          <strong>
            {label}
          </strong>
        </DataTable.Row>
        {breakdowns.length ? (
          <DataTable.Row noBorder noPadding>
            {_.map(breakdowns, (breakdownData, i) => (
              <DataTable.Column key={breakdownData.label} noBorder>
                <DataTable.Row
                  gray={0 === i % 2}
                  width={contentWidth}
                  noBorder
                >
                  <StyledTimeLabel>
                    {breakdownData.label}
                  </StyledTimeLabel>
                </DataTable.Row>
              </DataTable.Column>
            ))}
          </DataTable.Row>
        ) : null}
        {renderSummary(dayNumber, closed)}
        {_.map(rooms, (room) => {
          const data = reportData[room.id]

          return renderNurseryClass(data, room, dayNumber)
        })}
      </DataTable.Column>
    )
  }

  return _.map(reportHeader, renderSlide)
}

export default ReportContent
