import styled from 'styled-components'

import layout from 'constants/layout'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    width: 100%;
  }
`

export const StyledDiscountContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledAmountItem = styled.div`
  display: grid;
  grid-template-columns: 0fr 1fr;
  align-items: center;
  gap: 15px;
`
