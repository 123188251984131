import React, { PropsWithChildren } from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Typography } from 'components'

import { StyledPanelItem } from './PanelStyled'

interface PanelItemProps {
  label: string
  value: string
  valueVariant?: string
}

const PanelItem: React.FC<PropsWithChildren<PanelItemProps>> = ({ label, value, valueVariant }) => (
  <StyledPanelItem>
    <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} margin="0 0 5px">
      {label}
    </Typography>
    <Typography variant={valueVariant}>
      {value}
    </Typography>
  </StyledPanelItem>
)

export default PanelItem
