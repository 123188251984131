import _ from 'lodash'
import { nest } from 'utils/flatnest'

import { GENDER_DROPDOWN } from 'services/legacy/child/constants'

import { AGES_MAP, FILTERS_MAP } from './constants'

export * from './list/selectors'
export * from './progressTrackerSingle/selectors'
export * from './comparisonReportSingle/selectors'

export const getFilterParams = (filters) => {
  const { age, ethnicity, gender, keyWorker, nurseryClass, ...rest } = filters || {}
  const result = {}

  if (ethnicity) {
    result.ethnicity = ethnicity?.value
  }

  if (keyWorker) {
    result.keyWorker = keyWorker?.value
  }

  if (nurseryClass) {
    result.nurseryClass = nurseryClass?.value
  }

  if (age?.value) {
    result.age = age?.value
  }

  if (gender?.length) {
    result.gender = _.map(gender, ({ value }) => value)
  }

  if (rest) {
    _.forEach(FILTERS_MAP, ({ value }) => {
      if (rest[value]) {
        result[value] = true
      }
    })
  }

  return result
}

export const getCohortTrackingReportsPayload = (params, filterLegacyLabel, actionPlan) => {
  const { ethnicity, filters, framework, frameworkArea, frameworkGroup, term } = nest(params)
  const {
    age,
    ethnicity: ethnicityFilter,
    gender,
    keyWorker,
    nurseryClass,
    ...restFilters
  } = filters || {}
  const payload = {}

  if (frameworkArea) {
    payload.frameworkArea = +frameworkArea
  }

  if (frameworkGroup) {
    payload.frameworkGroup = +frameworkGroup
  }

  if (ethnicity || ethnicityFilter) {
    payload.ethnicity = +ethnicity || +ethnicityFilter
  }

  if (framework) {
    payload.framework = +framework
  }

  if (term) {
    payload.term = term
  }

  if (1 === gender?.length) {
    payload.gender = gender[0]
  }

  if (age?.from) {
    payload.ageFrom = +age.from
  }

  if (age?.to) {
    payload.ageTo = +age.to
  }

  if (keyWorker) {
    payload.keyWorker = +keyWorker
  }

  if (nurseryClass) {
    payload.nurseryClass = +nurseryClass
  }

  if (actionPlan) {
    payload.actionPlan = actionPlan
  }

  _.each(restFilters, (filter, key) => {
    payload[key] = true
  })

  if (filterLegacyLabel) {
    payload.filters = filterLegacyLabel
  }

  return payload
}

export const generateFiltersLabel = (report, params) => {
  let labelFilters = ''

  _.each(FILTERS_MAP, ({ shortLabel, value }) => {
    if (report.filters?.[value]) {
      labelFilters += `${shortLabel}, `
    }
  })

  const ageOption = _.find(AGES_MAP, ({ value }) => (
    // eslint-disable-next-line no-unsafe-optional-chaining
    value.from === +report.filters?.ageFrom && value.to === +report.filters?.ageTo
  ))

  if (ageOption) {
    labelFilters += `${ageOption.label}, `
  }

  if (params.nurseryClass && report.filters?.nurseryClass) {
    labelFilters += `${params.nurseryClass.name}, `
  }

  if (report.filters?.gender) {
    labelFilters += `${_.get(_.find(GENDER_DROPDOWN, ({ value }) => report.filters.gender === value), 'label', '')}, `
  }

  if (params.keyWorker && report.filters?.keyWorker) {
    labelFilters += `${params.keyWorker.fullName || params.keyWorker.name}, `
  }

  if (params.ethnicities?.length && report.filters?.ethnicity) {
    const finalEthnicity = _.find(params.ethnicities, ({ value }) => value === +report.filters.ethnicity)

    if (finalEthnicity) {
      labelFilters += `${finalEthnicity.label}, `
    }
  }

  return labelFilters.slice(0, -2) || '-'
}

export const getFrameworkGroupsOptions = (frameworkDetails) => {
  if (!frameworkDetails?.structure?.cohort?.comparison?.group?.visible) {
    return _.map(frameworkDetails?.areas, ({ groups }) => ({
      label: groups[0].name,
      value: groups[0].id,
    }))
  }

  return _.map(frameworkDetails?.areas, (area) => ({
    label: area.name,
    options: _.map(area.groups, ({ id, name }) => ({
      label: name || area.name,
      value: id,
    })),
  }))
}
