import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { isTimeBetweenStartAndEndTime } from 'utils/date'

import { Avatar, EmptyState, ModalBox, Spinner, Table, Typography } from 'components'

import i18n from 'translations'

import { StyledStatistics, StyledStatisticsBox } from './ChildrenExpectedModalStyled'

export const TABLE_COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'child',
    title: _.upperFirst(i18n.t('global:child')),
    width: '40%',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'age',
    title: _.upperFirst(i18n.t('global:age')),
    width: '20%',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'session',
    title: _.upperFirst(i18n.t('global:session')),
    width: '50%',
  },
]

const ChildrenExpectedModalView = ({
  breakdown,
  data,
  isFetching,
  onCloseClick,
}) => {
  const { time } = data
  const {
    children,
    childrenExpected,
    staffExpected,
    staffRequired,
  } = breakdown

  const renderHeader = () => (
    <React.Fragment>
      <Typography fontSize={20}>
        {i18n.t('global:Time')}
        {': '}
        <Typography variant="span" bold>
          {moment(time).format('HH:mm')}
        </Typography>
      </Typography>
      <StyledStatistics>
        <StyledStatisticsBox>
          <Typography fontSize={14}>
            {i18n.t('module:Modals:Staff:Rota:ChildrenExpected:childrenExpectedIn')}
          </Typography>
          <Typography margin="8px 0 0" bold>
            {childrenExpected || 0}
          </Typography>
        </StyledStatisticsBox>
        <StyledStatisticsBox>
          <Typography fontSize={14}>
            {i18n.t('module:Modals:Staff:Rota:ChildrenExpected:staffExpectedIn')}
          </Typography>
          <Typography margin="8px 0 0" bold>
            {staffExpected || 0}
          </Typography>
        </StyledStatisticsBox>
        <StyledStatisticsBox>
          <Typography fontSize={14}>
            {i18n.t('module:Modals:Staff:Rota:ChildrenExpected:staffRequired')}
          </Typography>
          <Typography margin="8px 0 0" bold>
            {staffRequired || 0}
          </Typography>
        </StyledStatisticsBox>
      </StyledStatistics>
    </React.Fragment>
  )

  const renderRow = (item) => {
    const { child, childExtraSessions, childSessionPlans } = item
    const { ageMonths, firstName, photo, surname } = child

    const resultSession = _.find([...childSessionPlans, ...childExtraSessions], ({
      endTime,
      nurserySession,
      session,
      startTime,
    }) => {
      const { endTime: sessionEndTime, startTime: sessionStartTime } = session || {}
      const { endTime: nurserySessionEndTime, startTime: nurserySessionStartTime } = nurserySession || {}

      if (nurserySession && nurserySessionStartTime && nurserySessionEndTime) {
        return (
          isTimeBetweenStartAndEndTime(nurserySessionStartTime, nurserySessionEndTime, time)
        )
      }

      if (!sessionStartTime && !sessionEndTime) {
        return (
          isTimeBetweenStartAndEndTime(startTime, endTime, time)
        )
      }

      return (
        isTimeBetweenStartAndEndTime(sessionStartTime, sessionEndTime, time)
      )
    })

    if (resultSession?.nurserySession) {
      resultSession.session = resultSession.nurserySession
    }

    const { session } = resultSession || {}
    let sessionTimes = {}

    if (!session?.startTime && !session?.endTime) {
      sessionTimes = {
        endTime: resultSession?.endTime,
        startTime: resultSession?.startTime,
      }
    } else {
      sessionTimes = {
        endTime: session.endTime,
        startTime: session.startTime,
      }
    }

    const renderSessionTimes = () => {
      if (!sessionTimes?.startTime && !sessionTimes?.endTime) {
        return '-'
      }

      return (
        <React.Fragment>
          {moment(sessionTimes?.startTime).utc().format('HH:mm')}
          {' - '}
          {moment(sessionTimes?.endTime).utc().format('HH:mm')}
        </React.Fragment>
      )
    }

    return ({
      age: `${ageMonths} ${i18n.t('global:months')}`,
      child: (
        <Avatar
          avatarSize="small"
          initials={[firstName, surname]}
          src={photo}
          title={`${firstName} ${surname}`}
        />
      ),
      session: (
        <Typography>
          {renderSessionTimes()}
          {session?.name ? (
            <Typography color={NEUTRAL_COLOURS.GRAY} margin="0 0 0 5px" variant="span">
              {'('}
              {session.name}
              {')'}
            </Typography>
          ) : null}
        </Typography>
      ),
    })
  }

  const renderTable = () => {
    if (!children?.length) {
      return (
        <EmptyState
          icon="children"
          text1={i18n.t('module:Modals:Staff:Rota:ChildrenExpected:notFound')}
        />
      )
    }

    return (
      <Table
        columns={TABLE_COLUMNS}
        data={_.map(children, renderRow)}
        visualType="border"
      />
    )
  }

  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    return (
      <React.Fragment>
        {renderHeader()}
        {renderTable()}
      </React.Fragment>
    )
  }

  return (
    <ModalBox
      scrollToBody={false}
      title={i18n.t('module:Modals:Staff:Rota:ChildrenExpected:title')}
      onCloseClick={onCloseClick}
    >
      {renderContent()}
    </ModalBox>
  )
}

export default ChildrenExpectedModalView
