import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { INVOICE_LINE_ITEM_DISPLAY_DROPDOWN } from 'services/nurseryConsumables/models'

import { Callout, Form } from 'components'

import i18n from 'translations'

export const MANAGEMENT_CONSUMABLES_ADD_FORM = 'ManagementConsumablesAddForm'

const ManagementConsumablesV2AddForm = ({
  error,
  handleSubmit,
  isLoading,
  nurseryOptions,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Callout content={error} error />
    <Form.Row label="Consumable name" width={{ field: '300px' }} required>
      <Field
        component={Form.TextField}
        name="name"
        placeholder="Consumable name"
        validate={isRequired}
      />
    </Form.Row>
    <Form.Row label="Consumables line item on invoices" required>
      <Field
        component={Form.RadioGroup}
        name="settings.invoiceLineItemDisplay"
        options={INVOICE_LINE_ITEM_DISPLAY_DROPDOWN}
        validate={isRequired}
      />
    </Form.Row>
    <Form.FooterActions
      cancelLink={`${nurseryOptions.prefix}/finance-settings/consumables`}
      isSubmitting={isLoading}
      submitLabel={i18n.t('global:Save')}
    />
  </Form>
)

export default reduxForm({ form: MANAGEMENT_CONSUMABLES_ADD_FORM })(ManagementConsumablesV2AddForm)
