import React from 'react'

import { generateRoute } from 'utils/routing'

import { Callout, Field, NurseryLogo, Section, Spinner, Typography } from 'components'
import OpeningDays from 'module/Settings/components/OpeningDays'

import i18n from 'translations'

import { getOpeningDays } from 'module/Management/ManagementNursery/helpers'

const SettingsNurseryItemView = ({ errorMessages, isLoading, nursery }) => {
  if (isLoading) {
    return <Spinner />
  }

  const {
    address,
    id,
    isMontessori,
    logo,
    name,
    nurserySettings,
    ofstedId,
    organization,
    postCode,
    subdomain,
    telephone,
  } = nursery
  const { openingDays, startWeekFrom } = nurserySettings

  const newOpeningDays = getOpeningDays(openingDays)

  const addressWithPostCode = (
    <div>
      <div>
        {address}
      </div>
      <div>
        {postCode}
      </div>
    </div>
  )

  const actions = <Section.Actions tertiary={{ to: generateRoute('SETTINGS.NURSERY.ITEM.EDIT', { nurseryId: id }) }} />

  return (
    <Section
      actions={actions}
      title={name}
    >
      <Callout content={errorMessages} error />
      <Typography margin="20px 0">
        {i18n.t('module:Management:Nursery:subtitle')}
      </Typography>
      <div>
        <Field.InlineEdit
          description={name}
          label={i18n.t('module:Management:Nursery:nurseryName')}
        />
        <Field.InlineEdit
          description={(
            organization?.value || organization?.id
              ? (organization?.label || organization?.name)
              : i18n.t('module:Organisations:Add:Form:independentNursery')
          )}
          label={i18n.t('global:Organisation')}
        />
        <Field.InlineEdit
          description={subdomain}
          label={i18n.t('module:Management:Nursery:subdomain')}
        />
        <Field.InlineEdit
          description={<NurseryLogo alt="nursery-logo" logo={logo} />}
          label={i18n.t('module:Management:Nursery:logo')}
        />
        <Field.InlineEdit
          description={nurserySettings?.countryName || nurserySettings?.locale?.label}
          label={i18n.t('module:Nurseries:Preview:country')}
        />
        <Field.InlineEdit
          description={nurserySettings?.currencyName || nurserySettings?.currency?.label}
          label={i18n.t('module:Nurseries:Preview:currency')}
        />
        <Field.InlineEdit
          description={addressWithPostCode}
          label={i18n.t('module:Management:Nursery:address')}
        />
        <Field.InlineEdit
          description={telephone}
          label={i18n.t('module:Management:Nursery:telephone')}
        />
        <Field.InlineEdit
          description={isMontessori ? i18n.t('global:yes') : i18n.t('global:no')}
          label={i18n.t('module:Management:Nursery:montessori')}
        />
        <Field.InlineEdit
          description={ofstedId || '-'}
          label={i18n.t('module:Management:Nursery:ofstedIdentifier')}
        />
        <Field.InlineEdit
          description={openingDays && <OpeningDays openingDays={newOpeningDays} />}
          label={i18n.t('module:Management:Nursery:openingTimes')}
        />
        <Field.InlineEdit
          description={startWeekFrom || '-'}
          label={i18n.t('module:Management:Nursery:startWeekFrom')}
        />
      </div>
    </Section>
  )
}

export default SettingsNurseryItemView
