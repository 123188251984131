import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withPaginationUtils } from 'services/utils/pagination'

import SettingsNurseryView from './SettingsNurseryView'

class SettingsNurseryContainer extends Component {
  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { nurseriesActions } = this.props

    nurseriesActions.clear()
  }

  fetch = () => {
    const {
      nurseriesActions,
      paginationUtils: { page },
    } = this.props

    nurseriesActions.list({
      params: { page },
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  render() {
    const {
      isFetching,
      nurseries,
      paginationUtils: { getPageCount, page, perPage },
      totalResults,
    } = this.props

    const pageCount = getPageCount(totalResults)

    return (
      <SettingsNurseryView
        isFetching={isFetching}
        nurseries={nurseries}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        totalResults={totalResults}
        onPageChange={this.handlePageChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseriesListState,
  nurseriesSelectors,
}) => ({
  isFetching: appSelectors.getIsFetching(nurseriesListState),
  nurseries: nurseriesSelectors.getOrganizationNurseriesDataSelector(state),
  totalResults: appSelectors.getTotalResults(nurseriesListState),
})

const enhance = compose(
  withAppService,
  withNurseriesService,
  withPaginationUtils,
  connect(mapState),
)

export default enhance(SettingsNurseryContainer)
