import _ from 'lodash'

import React, { PropsWithChildren } from 'react'

import { BUTTON_HIERARCHY } from 'components/Button/constants'
import { DropdownMenuItemProps } from 'components/DropdownMenu/DropdownMenuItem'

import { typeByObject } from 'utils/typescript'

import { Button, DropdownMenu, Toolbar } from 'components'

import i18n from 'translations'

interface RenderButtonProps {
  defaultIcon?: IconType
  defaultLabel?: string
  hierarchy?: typeByObject<typeof BUTTON_HIERARCHY>
  item?: any
}

interface SectionActionItem {
  disabled?: boolean
  icon?: IconType
  isLoading?: boolean
  label?: string
  noIcon?: boolean
  onClick?: () => void
  to?: string
}

interface SectionActionsProps {
  disabled?: boolean
  options?: DropdownMenuItemProps[]
  primary?: SectionActionItem[]
  secondary?: SectionActionItem
  tertiary?: SectionActionItem
}

const SectionActions: React.FC<PropsWithChildren<SectionActionsProps>> = ({
  disabled,
  options,
  primary,
  secondary,
  tertiary,
}) => {
  const renderDropdownMenuItem = (item) => {
    if (false === item?.auth) {
      return null
    }

    if (item?.type) {
      return (
        <DropdownMenu.Item
          key={_.kebabCase(item?.label)}
          to={item?.to}
          type={item.type}
          onClick={item?.onClick}
        />
      )
    }

    return (
      <DropdownMenu.Item
        icon={item?.icon}
        key={_.kebabCase(item?.label)}
        label={item?.label}
        to={item?.to}
        onClick={item?.onClick}
      />
    )
  }

  const renderAdditionalOptions = () => options && !_.every(options, ['auth', false]) && (
    <Toolbar.Item>
      <DropdownMenu disabled={disabled}>
        {_.map(options, renderDropdownMenuItem)}
      </DropdownMenu>
    </Toolbar.Item>
  )

  const renderButton = ({ defaultIcon, defaultLabel, hierarchy, item }: RenderButtonProps) => false !== item?.auth && (
    <Toolbar.Item key={_.kebabCase(item?.label)}>
      <Button
        disabled={item?.disabled}
        hierarchy={hierarchy}
        icon={item?.noIcon ? null : item?.icon || defaultIcon}
        isLoading={item?.isLoading}
        label={item?.label || defaultLabel}
        to={item?.to}
        negativeMargins
        onClick={item?.onClick}
      />
    </Toolbar.Item>
  )

  const renderTertiaryButton = () => tertiary && renderButton({
    defaultLabel: i18n.t('global:Edit'),
    hierarchy: 'tertiary',
    item: tertiary,
  })

  const renderSecondaryButton = () => secondary && renderButton({
    hierarchy: 'secondary',
    item: secondary,
  })

  const renderPrimaryButton = (item) => renderButton({
    defaultIcon: 'plus',
    defaultLabel: i18n.t('global:Create'),
    hierarchy: 'primary',
    item,
  })

  const renderPrimaryButtons = () => primary && _.map(primary, renderPrimaryButton)

  return (
    <Toolbar noMargin>
      <Toolbar.Group>
        {renderTertiaryButton()}
        {renderSecondaryButton()}
        {renderPrimaryButtons()}
        {renderAdditionalOptions()}
      </Toolbar.Group>
    </Toolbar>
  )
}

export default SectionActions
