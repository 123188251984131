import { BasicListInitialState, BasicSingleInitialState } from 'utils/reducer'

import { createTypeFromEnumValues } from 'utils/typescript'

export enum ProductPriceGroupsType {
  NURSERY_AGE = 'nurseryAge',
  NURSERY_DEFAULT = 'nurseryDefault'
}

export interface ProductPriceGroups {
  archived?: boolean
  archivedAt?: Date
  endAge: number
  id?: number
  startAge: number
  type: createTypeFromEnumValues<ProductPriceGroupsType>
}

export interface ProductPriceGroupsSingleState extends BasicSingleInitialState {
  data: ProductPriceGroups
}

export interface ProductPriceGroupsListState extends BasicListInitialState {
  data: ProductPriceGroups[]
}

export interface ProductPriceGroupsState {
  list: ProductPriceGroupsListState
  single: ProductPriceGroupsSingleState
}

export interface ProductPriceGroupsRootState {
  productPriceGroups: ProductPriceGroupsState
}
