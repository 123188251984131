import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_MILK_REPORT_LIST,
  EXPORT_MILK_REPORT,
  EXPORT_MILK_REPORT_FAILED,
  EXPORT_MILK_REPORT_SUCCESS,
  GET_MILK_REPORT_LIST,
  GET_MILK_REPORT_LIST_FAILED,
  GET_MILK_REPORT_LIST_SUCCESS,
} from './constants'

export const list = (options: BasicActionOptionsProps) => ({ milkReportApiClient }) => (
  generateBasicActions.list({
    constants: {
      failed: GET_MILK_REPORT_LIST_FAILED,
      init: GET_MILK_REPORT_LIST,
      success: GET_MILK_REPORT_LIST_SUCCESS,
    },
    options,
    service: milkReportApiClient,
    serviceMethod: 'list',
  })
)

export const exportMilkReport = (options = {}) => ({ milkReportApiClient }) => generateBasicActions.list({
  constants: {
    failed: EXPORT_MILK_REPORT_FAILED,
    init: EXPORT_MILK_REPORT,
    success: EXPORT_MILK_REPORT_SUCCESS,
  },
  options,
  service: milkReportApiClient,
  serviceMethod: 'export',
})

export const clearList = () => ({
  type: CLEAR_MILK_REPORT_LIST,
})
