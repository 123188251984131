import React from 'react'

import colors from 'constants/colors'

import { NEWS_STATUSES, TAB_NAMES } from 'services/legacy/newsletters/constants'

import { getBrandingColor } from 'utils/branding'
import { generateRoute } from 'utils/routing'

import { DateString, DropdownMenu, Typography } from 'components'

import i18n from 'translations'

import { FilterDescription } from '../../../components'
import {
  StyledContentBottomContainer,
  StyledContentContainer,
  StyledItemContainer,
  StyledItemGrid,
  StyledItemWrapper,
  StyledLink,
  StyledRightItemContainer,
} from './NewsListItemStyled'

const NewsListItem = ({ item, onDeleteNewsItem, style }) => {
  if (!item) {
    return (
      <StyledItemWrapper style={style}>
        <StyledItemContainer>{i18n.t('module:Newsletters:List:loadingItemText')}</StyledItemContainer>
      </StyledItemWrapper>
    )
  }

  const { displayFilters, id, status, title, updatedAt } = item

  return (
    <StyledItemWrapper style={style}>
      <StyledItemContainer>
        <StyledItemGrid>
          <StyledContentContainer>
            <StyledLink
              to={generateRoute(
                NEWS_STATUSES.DRAFT === status
                  ? 'NEWSLETTERS.EDIT'
                  : 'NEWSLETTERS.PREVIEW',
                { newsId: id },
              )}
            >
              <Typography variant="h6">
                {NEWS_STATUSES.DRAFT === status && (
                  <Typography color={getBrandingColor('tertiary-color')} padding="0 5px 0 0" variant="span" bold>
                    {i18n.t('module:Newsletters:Preview:titleSecond')}
                  </Typography>
                )}
                {title}
              </Typography>
            </StyledLink>
            <StyledContentBottomContainer>
              <Typography color={colors.gray}>
                <FilterDescription
                  displayFilters={displayFilters}
                  hideLink={NEWS_STATUSES.DRAFT === status}
                  to={`${generateRoute('NEWSLETTERS.PREVIEW', { newsId: id })}?tab=${TAB_NAMES.RECIPIENTS}`}
                />
              </Typography>
            </StyledContentBottomContainer>
          </StyledContentContainer>
          <StyledRightItemContainer>
            <DateString date={updatedAt} />
          </StyledRightItemContainer>
          <StyledRightItemContainer>
            <DropdownMenu small>
              {NEWS_STATUSES.DRAFT === status && (
                <DropdownMenu.Item
                  label={i18n.t('module:Newsletters:List:editPostButtonText')}
                  to={generateRoute('NEWSLETTERS.EDIT', { newsId: id })}
                />
              )}
              {NEWS_STATUSES.DRAFT !== status && (
                <DropdownMenu.Item
                  label={i18n.t('module:Newsletters:List:previewPostButtonText')}
                  to={generateRoute('NEWSLETTERS.PREVIEW', { newsId: id })}
                />
              )}
              <DropdownMenu.Item label={i18n.t('global:Remove')} onClick={() => onDeleteNewsItem(id)} />
            </DropdownMenu>
          </StyledRightItemContainer>
        </StyledItemGrid>
      </StyledItemContainer>
    </StyledItemWrapper>
  )
}

export default NewsListItem
