import {
  CREATE_FOOD_MENU,
  CREATE_FOOD_MENU_FAILED,
  CREATE_FOOD_MENU_SUCCESS,
  UPDATE_FOOD_MENU,
  UPDATE_FOOD_MENU_FAILED,
  UPDATE_FOOD_MENU_SUCCESS,
} from './constants'

const initialState = {
  error: null,
  isSubmitting: false,
}

export default (state = initialState, { error, type }) => {
  switch (type) {
    case UPDATE_FOOD_MENU:
    case CREATE_FOOD_MENU:
      return { isSubmitting: true }
    case UPDATE_FOOD_MENU_SUCCESS:
    case CREATE_FOOD_MENU_SUCCESS:
      return { isSubmitting: false }
    case UPDATE_FOOD_MENU_FAILED:
    case CREATE_FOOD_MENU_FAILED:
      return { error, isSubmitting: false }
    default:
      return state
  }
}
