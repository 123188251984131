import React from 'react'

import { getBrandingColor } from 'utils/branding'

import { StyledCircle, StyledContainer, StyledLabel } from './CreditNoteIconStyled'

const CreditNoteIcon = ({ label }) => (
  <StyledContainer>
    <StyledCircle
      background={getBrandingColor('tertiary-color')}
    >
      CN
    </StyledCircle>
    {label && (
      <StyledLabel>
        {label}
      </StyledLabel>
    )}
  </StyledContainer>
)

export default CreditNoteIcon
