import { combineReducers } from 'redux'

import list from './list/reducer'
import progressTrackerSingle from './progressTrackerSingle/reducer'
import comparisonReportSingle from './comparisonReportSingle/reducer'

export default combineReducers({
  comparisonReportSingle,
  list,
  progressTrackerSingle,
})
