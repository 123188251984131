import Page from './Page'
import PageBottomBar from './PageBottomBar'
import PageContent from './PageContent'
import PageSection from './PageSection'

Page.BottomBar = PageBottomBar
Page.Content = PageContent
Page.Section = PageSection

export default Page
