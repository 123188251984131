import styled from 'styled-components'

import colors, { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'

import { typeByObject } from 'utils/typescript'

export const VISUAL_TYPE = {
  MODERN: 'modern',
}

interface StyledInputContainerProps {
  $valid?: boolean
  $visualType?: typeByObject<typeof VISUAL_TYPE>
}

export const StyledInputContainer = styled.div<StyledInputContainerProps>`
  position: relative;

  ${({ $valid, $visualType }) => (false === $valid && VISUAL_TYPE.MODERN === $visualType && `
    z-index: 20;
  `)};
`

interface StyledInputProps {
  $disabled?: boolean
  $hasIcon?: boolean
  $paddingLeft?: string
  $paddingRight?: string
  $valid?: boolean
  $visualType?: typeByObject<typeof VISUAL_TYPE>
}

export const StyledInput = styled.input<StyledInputProps>`
  display: block;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ $valid }) => (false === $valid ? FLAG_COLOURS.ERROR : colors.inputBorderColor)};
  border-radius: 4px;
  height: 42px;
  line-height: 20px;
  width: 100%;
  padding: 10px;
  padding-right: ${({ $hasIcon }) => ($hasIcon ? '40px' : '10px')};
  font-size: 14px;

  ${({ $paddingRight }) => $paddingRight && `
    padding-right: ${$paddingRight};
  `}

  ${({ $paddingLeft }) => $paddingLeft && `
    padding-left: ${$paddingLeft};
  `}

  ${({ $disabled }) => $disabled && `
    background-color: ${colors.disabled};
    cursor: not-allowed;
    
    &::-webkit-input-placeholder {
      color: #CCC;
    }
  `};

  ${({ $visualType }) => VISUAL_TYPE.MODERN === $visualType && `
    border-style: solid;
    border-width: 2px;
    border-color: ${({ $valid }) => (false === $valid ? FLAG_COLOURS.ERROR : NEUTRAL_COLOURS.GRAY_TERTIARY)};
    border-radius: 0;
    height: auto;
  `}
`

export const StyledIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledPrefix = styled.div`
  position: absolute;
  top: 0;
  left: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledSuffix = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
