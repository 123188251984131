import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { PAYOUTS_STATUSES } from 'services/nurseryIntegrations/constants'

import {
  Callout,
  CircleIcon,
  Currency,
  InfiniteScroll,
  Page,
  Pagination,
  Section,
  Space,
  Spinner,
  Table,
  Typography,
} from 'components'

import i18n from 'translations'

import { TABLE_COLUMNS } from './constants'
import { StyledField, StyledStatus } from './DetailStyled'

interface DetailViewProps {
  errorMessages?: string[]
  isFetching?: boolean
  isTransactionsFetching?: boolean
  onExportClick?: () => void
  onPageChange: () => void
  payoutData?: any
  payoutTransactionsData?: any
  payoutTransactionsHasMore?: boolean
  payoutTransactionsTotalResults?: number
  tableData?: any
}

const DetailView: React.FC<DetailViewProps> = ({
  errorMessages,
  isFetching,
  isTransactionsFetching,
  onExportClick,
  onPageChange,
  payoutData,
  payoutTransactionsData,
  payoutTransactionsHasMore,
  payoutTransactionsTotalResults,
  tableData,
}) => {
  const renderTable = () => {
    if (isTransactionsFetching && !payoutTransactionsHasMore) {
      return (
        <Spinner />
      )
    }

    return (
      <React.Fragment>
        <Space space="60px" />
        <Typography bold>
          {i18n.t('module:Finance:Payouts:Detail:transactions')}
        </Typography>
        <Pagination totalResults={payoutTransactionsTotalResults} />
        <InfiniteScroll
          dataLength={payoutTransactionsData?.length || 0}
          hasMore={payoutTransactionsHasMore}
          next={onPageChange}
        >
          <Table
            columns={TABLE_COLUMNS}
            data={tableData}
            visualType="transparent"
          />
        </InfiniteScroll>
      </React.Fragment>
    )
  }

  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    const status = PAYOUTS_STATUSES[_.toUpper(payoutData?.status)] || {}

    return (
      <React.Fragment>
        <Typography fontSize={18} margin="0 0 25px" bold>
          {i18n.t('module:Finance:Payouts:Detail:payoutDetails')}
        </Typography>
        <StyledField>
          <Typography bold>
            {i18n.t('module:Finance:Payouts:Detail:arrival')}
          </Typography>
          <div>
            {moment(payoutData?.arrival).format('DD MMM YYYY')}
          </div>
        </StyledField>
        <StyledField>
          <Typography bold>
            {_.upperFirst(i18n.t('global:status'))}
          </Typography>
          <StyledStatus>
            <CircleIcon
              background={status?.color}
              size={12}
            />
            {status?.label}
          </StyledStatus>
        </StyledField>
        <StyledField>
          <Typography bold>
            {_.upperFirst(i18n.t('global:amount'))}
          </Typography>
          <div>
            <Currency value={payoutData?.amount} />
          </div>
        </StyledField>
        <StyledField>
          <Typography bold>
            {i18n.t('module:Finance:Payouts:Detail:stripeFees')}
          </Typography>
          <div>
            <Currency value={payoutData?.fee} />
          </div>
        </StyledField>
        {renderTable()}
      </React.Fragment>
    )
  }

  return (
    <Page.Section
      actions={<Section.Actions options={[{ onClick: onExportClick, type: 'export' }]} />}
      isLoading={isFetching}
      subtitle={<Currency fontSize={22} value={payoutData?.amount} />}
      title={i18n.t('global:Finance:Payout')}
    >
      <Callout content={errorMessages} />
      {renderContent()}
    </Page.Section>
  )
}

export default DetailView
