import _ from 'lodash'

import React from 'react'

import { GENDER_DROPDOWN } from 'services/legacy/child/constants'

import { Avatar, DateString, DetailsBreakdown, FormatText, NameArchived } from 'components'

import i18n from 'translations'

import { CHILD_EDIT_FORM_ROUTE_TYPE } from '../../../constants'

import BreakdownHeader from './BreakdownHeader'
import BreakdownItem from './BreakdownItem'

const GeneralBreakdown = ({
  child,
  isFormContext,
  permissionToEdit,
}) => {
  const {
    birthDate,
    codeReference,
    displayName,
    ethnicity,
    firstName,
    gender,
    id,
    information,
    middleNames,
    photo,
    surname,
  } = child
  const {
    address,
    postcode,
  } = information || {}

  return (
    <DetailsBreakdown marginBottom>
      <DetailsBreakdown.Section>
        <BreakdownHeader
          childFormRouteType={CHILD_EDIT_FORM_ROUTE_TYPE.GENERAL_INFO}
          childId={id}
          isFormContext={isFormContext}
          permissionToEdit={permissionToEdit}
          title={i18n.t('module:Children:Child:Add:GeneralInformation:title')}
        />
        {isFormContext && (
          <BreakdownItem
            label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:Photo:label')}
            padding="15px 0"
            value={(
              <Avatar
                avatarSize="big"
                initials={[firstName, surname]}
                src={photo?.value}
                withoutOptimization
              />
            )}
            withoutBorder
          />
        )}
        {!isFormContext && (
          <BreakdownItem
            label={i18n.t('global:Name')}
            padding="15px 0"
            value={<NameArchived item={child} />}
            withoutBorder
          />
        )}
        {isFormContext && (
          <React.Fragment>
            <BreakdownItem
              label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:FirstName:label')}
              padding="15px 0"
              value={firstName}
              withoutBorder
            />
            <BreakdownItem
              label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:MiddleName:label')}
              padding="15px 0"
              value={middleNames}
              withoutBorder
            />
            <BreakdownItem
              label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:Surname:label')}
              padding="15px 0"
              value={surname}
              withoutBorder
            />
          </React.Fragment>
        )}
        <BreakdownItem
          label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:DisplayName:label')}
          padding="15px 0"
          value={displayName}
          withoutBorder
        />
        <BreakdownItem
          label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:DateOfBirth:label')}
          padding="15px 0"
          value={birthDate && <DateString date={birthDate} format="DD/MM/YYYY" />}
          withoutBorder
        />
        <BreakdownItem
          label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:Gender:label')}
          padding="15px 0"
          value={_.get(_.find(GENDER_DROPDOWN, ({ value }) => value === gender), 'label')}
          withoutBorder
        />
        <BreakdownItem
          label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:Ethnicity:label')}
          padding="15px 0"
          value={ethnicity?.label || ethnicity?.name}
          withoutBorder
        />
        <BreakdownItem
          label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:Address:label')}
          padding="15px 0"
          value={<FormatText lines={[address, postcode]} />}
          withoutBorder
        />
        <BreakdownItem
          label={i18n.t('global:Child:referenceCode')}
          padding="15px 0"
          value={codeReference}
          withoutBorder
        />
      </DetailsBreakdown.Section>
    </DetailsBreakdown>
  )
}

export default GeneralBreakdown
