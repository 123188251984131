import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'

import { Form } from 'components'

import i18n from 'translations'

export const MANAGEMENT_PAYMENT_TYPES_ADD_FORM = 'ManagementPaymentTypesAddForm'

const ManagementPaymentTypesAddForm = ({
  handleSubmit,
  isLoading,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row label={i18n.t('module:Management:ManagementPaymentTypes:tableTitle')}>
      <Form.Row.FlexItem flex="0 0 250px">
        <Field
          component={Form.TextField}
          name="name"
          placeholder={i18n.t('module:Management:ManagementPaymentTypes:tableTitle')}
          validate={isRequired}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.FooterActions
      cancelLink={generateRoute('MANAGEMENT.FINANCE_SETTINGS.PAYMENT_TYPES')}
      isSubmitting={isLoading}
      submitLabel={i18n.t('global:Save')}
    />
  </Form>
)

export default reduxForm({ form: MANAGEMENT_PAYMENT_TYPES_ADD_FORM })(ManagementPaymentTypesAddForm)
