import Button from './Button'
import ActionButton from './ActionButton'
import ButtonTableAction from './ButtonTableAction'
import BigButton from './BigButton'
import RadioButton from './RadioButton'

Button.ActionButton = ActionButton
Button.Big = BigButton
Button.RadioButton = RadioButton
Button.TableAction = ButtonTableAction

export default Button
