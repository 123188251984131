import React from 'react'

import { generateRoute } from 'utils/routing'

import { Button, FooterActions, Form } from 'components'

import i18n from 'translations'

const ChildFormFooter = ({
  childId,
  isChildArchived,
  isEditMode,
  isSubmitting,
  onArchiveClick,
  onPreviousStageClick,
  onSubmitClick,
  permissionToArchive,
  previousButtonLabel,
  submitLabel,
  withoutBackButton,
}) => {
  const renderAddChildFooter = () => (withoutBackButton ? (
    <FooterActions spaceBetween>
      <FooterActions.Flex />
      <FooterActions.Item>
        <Button
          label={i18n.t('global:Next')}
          negativeMargins
          submit
        />
      </FooterActions.Item>
    </FooterActions>
  ) : (
    <Form.FooterActions
      cancelLabel={previousButtonLabel || i18n.t('global:Back')}
      isSubmitting={isSubmitting}
      submitLabel={submitLabel || i18n.t('global:Next')}
      onCancelClick={onPreviousStageClick}
      onSubmitClick={onSubmitClick}
    />
  ))

  return isEditMode ? (
    <Form.FooterActions
      cancelLink={generateRoute('CHILDREN.CHILD.ABOUT.PROFILE', { childId })}
      isSubmitting={isSubmitting}
      secondary={{
        auth: permissionToArchive,
        label: isChildArchived
          ? i18n.t('global:Unarchive')
          : i18n.t('global:Archive'),
        onClick: onArchiveClick,
      }}
      submitLabel={i18n.t('global:Update')}
    />
  ) : renderAddChildFooter()
}

export default ChildFormFooter
