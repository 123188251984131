import _ from 'lodash'

import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { REFERENCE_ASYNC_PAGE_TYPES } from 'services/legacy/upload/constants'

import { Form } from 'components'

import { properties } from 'app-config'

import { StyledMontessoriFields } from './ObservationsEditFormStyled'

export const OBSERVATIONS_EDIT_FORM = 'ObservationsEditForm'

const ObservationsEditForm = ({
  handleSubmit,
  isMontessori,
  montessoriCategories,
  observation,
  onChangeEvent,
  onCheckProcessingFiles,
  onReloadMedia,
  onRemoveFile,
  onSubmit,
  onUpdateMedia,
  selectedChildren,
  totalResults,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row label="Date" verticalLabel>
      <Form.Row.FlexItem flex="0 0 200px" mobileFull>
        <Field
          component={Form.DatePicker}
          name="observationDate"
          clearable
          withCurrentTime
          onChange={onChangeEvent}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    {isMontessori && (
      <StyledMontessoriFields>
        <Form.Row label="Montessori activity" verticalLabel>
          <Form.Row.FlexItem flex="0 0 250px" mobileFull>
            <Field
              component={Form.Select}
              name="montessoriActivity"
              options={montessoriCategories}
              placeholder="Montessori activity"
              onChange={onChangeEvent}
            />
          </Form.Row.FlexItem>
          &nbsp;
          <Form.Row.FlexItem flex="0 0 300px" mobileFull>
            <Field
              component={Form.TextField}
              name="customMontessoriActivity"
              placeholder="...or type a custom activity"
              onBlur={onChangeEvent}
            />
          </Form.Row.FlexItem>
        </Form.Row>
      </StyledMontessoriFields>
    )}
    {1 < selectedChildren?.length && (
      <Form.Row label="General observation notes" verticalLabel>
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextAreaField}
            maxHeight="500px"
            maxRows={20}
            name="comments"
            placeholder="General observation notes"
            autosize
            onBlur={onChangeEvent}
          />
        </Form.Row.FlexItem>
      </Form.Row>
    )}
    {observation?.id ? (
      <Form.Row label="Attached media" verticalLabel>
        <Form.Row.FlexItem mobileFull>
          <Field
            childrenList={_.map(observation.childObservations, ({ child }) => child)}
            component={Form.MediaPicker}
            filesLimit={properties.upload.limits.observation}
            name="media"
            referencePage={[REFERENCE_ASYNC_PAGE_TYPES.OBSERVATION, observation.id]}
            totalResults={totalResults}
            onChange={onChangeEvent}
            onCheckProcessingFiles={onCheckProcessingFiles}
            onReloadMedia={onReloadMedia}
            onRemoveFile={onRemoveFile}
            onUpdateMedia={onUpdateMedia}
          />
        </Form.Row.FlexItem>
      </Form.Row>
    ) : null}
  </Form>
)

export default reduxForm({ form: OBSERVATIONS_EDIT_FORM })(ObservationsEditForm)
