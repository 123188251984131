import React, { useEffect } from 'react'
import { compose } from 'recompose'

import { SelectProps } from 'components/Select/Select'
import { InfinityDropdownProps } from 'components/InfiniteDropdowns/InfiniteDropdownsModel'

import { withEnquirySettingsService, withEnquirySettingsServiceProps } from 'services/legacy/enquiries'

import { Select } from 'components'

// eslint-disable-next-line import-newlines/enforce, max-len
import withInfiniteDropdownHelpers, { withInfiniteDropdownHelpersProps } from '../../legacy/InfiniteDropdowns/withInfiniteDropdown'

interface EnquirySettingsDropdownProps {
  type: string
}

export type EnquirySettingsDropdownFullProps = EnquirySettingsDropdownProps
  & withInfiniteDropdownHelpersProps
  & withEnquirySettingsServiceProps
  & SelectProps
  & InfinityDropdownProps

const EnquirySettingsDropdown: React.FC<EnquirySettingsDropdownFullProps> = ({
  extraFields = [],
  infiniteDropdownHelpers,
  enquirySettingsActions,
  enquirySettingsSelectors,
  onChange,
  value,
  type,
  ...rest
}) => {
  useEffect(() => {
    infiniteDropdownHelpers.handleComponentDidMount({
      action: () => enquirySettingsActions.getDropdownItem({
        onFailed: () => onChange(null),
        onSuccess: infiniteDropdownHelpers.handleComponentDidMountOnSuccess,
        onlyData: true,
        params: [(value as any)?.value || value],
      }),
      value,
    })
  }, [])

  const listAction = ({ newPage, newSearchPhrase, ...others }) => enquirySettingsActions.dropdownList({
    onSuccess: infiniteDropdownHelpers.handleLoadMoreElementsOnSuccessV2({
      extraFields,
      newPage,
      ...others,
    }),
    params: {
      criteria: enquirySettingsSelectors.getCriteria({ name: newSearchPhrase, type }),
      order: {
        sortField: 'name',
        sortOrder: 'ASC',
      },
      page: newPage,
    },
  })

  return (
    <Select
      {...infiniteDropdownHelpers.getSelectRestParams({ onChange, value, ...rest })}
      loadOptions={infiniteDropdownHelpers.handleLoadMoreElementsV2({ listAction })}
      searchable
    />
  )
}

const enhance = compose(
  withEnquirySettingsService,
  withInfiniteDropdownHelpers,
)

export default enhance(EnquirySettingsDropdown)
