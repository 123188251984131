import {
  CLEAR_REGISTER_USER_LOGIN,
  REGISTER_USER_LOGIN,
  REGISTER_USER_LOGIN_FAILED,
  REGISTER_USER_LOGIN_SUCCESS,
} from './constants'

// legacy login service used
export const loginStaffRegister = (identifier, password, options = {}) => ({
  authenticationApiClient,
}) => (dispatch) => {
  const { onSuccess } = options

  dispatch({
    type: REGISTER_USER_LOGIN,
  })

  authenticationApiClient.login({ identifier, password }).then(
    (response) => {
      dispatch({
        payload: response,
        type: REGISTER_USER_LOGIN_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: REGISTER_USER_LOGIN_FAILED,
      })
    },
  )
}

export const clearLoginStaffRegister = () => ({
  type: CLEAR_REGISTER_USER_LOGIN,
})

