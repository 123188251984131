import React from 'react'
import i18n from 'translations'

import { ModalBox } from 'components'
import InvoiceTable from 'module/Finance/components/InvoiceTable'

const DetailPreviewModalView = ({
  invoicePreview,
  onCloseClick,
  subTitle,
}) => (
  <ModalBox
    title={i18n.t('module:Modals:InvoiceDetailPreview:title')}
    autoHeight
    onCloseClick={onCloseClick}
  >
    <p>{subTitle}</p>
    <InvoiceTable
      invoicePreview={invoicePreview}
      preview
    />
  </ModalBox>
)

export default DetailPreviewModalView
