import moment from 'moment'

import React from 'react'

import { toInt } from 'utils/data'

import { Currency, ProgressBar } from 'components'

import {
  StyledContentContainer,
  StyledItemContainer,
  StyledItemGroup,
  StyledTag,
  StyledTitleContainer,
} from './ChildFundingSessionPanelStyled'

const ChildFundingSessionPanelItem = ({
  amountAllocated,
  amountInvoiced,
  endDate,
  hoursAllocated,
  hoursInvoiced,
  isOngoing,
  isSelected,
  onClick,
  showWeeklyAllocation,
  startDate,
  weeklyHours,
  weeklyHoursInvoiced,
}) => {
  const percent = toInt(((weeklyHoursInvoiced || 0) * 100) / (weeklyHours || 100))

  return (
    <StyledItemContainer isSelected={isSelected} onClick={onClick}>
      <StyledItemGroup>
        <StyledTitleContainer isSelected={isSelected}>
          <div>
            Session set
          </div>
          {isSelected && (
            <StyledTag>
              selected
            </StyledTag>
          )}
        </StyledTitleContainer>
        {
          `${moment(startDate).format('DD/MM/YYYY')} -
          ${
          isOngoing
            ? 'On-going'
            : moment(endDate).format('DD/MM/YYYY')
          }`
        }
      </StyledItemGroup>
      <StyledItemGroup>
        <StyledContentContainer>
          {`Funded hours invoiced: ${hoursInvoiced} hours`}
          <br />
          {'Funded amount invoiced: '}
          <Currency value={amountInvoiced} />
        </StyledContentContainer>
      </StyledItemGroup>
      <StyledItemGroup>
        <StyledContentContainer>
          {`Funded hours allocated: ${hoursAllocated} hours`}
          <br />
          {'Funded amount allocated: '}
          <Currency value={amountAllocated} />
        </StyledContentContainer>
      </StyledItemGroup>
      {showWeeklyAllocation && (
        <StyledItemGroup>
          <StyledContentContainer>
            {`${weeklyHoursInvoiced} / ${weeklyHours} hours allocated per week`}
            <ProgressBar isExceedAvailable={weeklyHoursInvoiced > weeklyHours} width={percent} />
          </StyledContentContainer>
        </StyledItemGroup>
      )}
    </StyledItemContainer>
  )
}

export default ChildFundingSessionPanelItem
