import { getQueryStringV2 } from 'utils/api'

import ServiceBase from 'services/ServiceBase'

class LikesApiClient extends ServiceBase {
  list = (params) => this.request({
    path: `/v2/likes?${getQueryStringV2(params)}`,
  })

  create = (body) => this.request({
    body,
    method: 'POST',
    path: '/v2/likes',
  })

  remove = (id) => this.request({
    method: 'DELETE',
    path: `/v2/likes/${id}`,
  })
}

export default LikesApiClient
