import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withRouter } from 'services/router'

import SettingsNurseryItemView from './SettingsNurseryItemView'

const groups = {
  read: [
    'nursery.settings',
    'nursery.organization',
    'organization',
    'nurserySettings',
    'nurserySettings.localeDetails',
    'nursery.primaryContact',
  ],
}

class SettingsNurseryItemContainer extends Component {
  componentDidMount() {
    const { nurseriesActions, params: { nurseryId } } = this.props

    nurseriesActions.get(nurseryId, {
      params: { groups },
    })
  }

  componentWillUnmount() {
    const { nurseriesActions } = this.props

    nurseriesActions.clearSingle()
  }

  render() {
    const { errorMessages, isFetching, nurseryData } = this.props

    const isLoading = isFetching || !nurseryData

    return (
      <SettingsNurseryItemView
        errorMessages={errorMessages}
        isLoading={isLoading}
        nursery={nurseryData}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseriesSelectors,
  nurseriesSingleState,
}) => ({
  errorMessages: appSelectors.getErrorMessages(nurseriesSingleState),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState),
  nurseryData: nurseriesSelectors.getNurseryData(state),
})

const enhance = compose(
  withAppService,
  withNurseriesService,
  connect(mapState),
  withRouter,
)

export default enhance(SettingsNurseryItemContainer)
