import _ from 'lodash'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { noop } from 'constants/global'
import { COMPARISON_REPORT_TYPES_DROPDOWN } from 'services/legacy/cohortTracking/constants'

import { generateRoute } from 'utils/routing'

import * as cohortTrackingSelectors from 'services/legacy/cohortTracking/selectors'

import {
  Button,
  DropdownMenu,
  EmptyState,
  Hyperlink,
  Page,
  Section,
  Space,
  Spinner,
  Toolbar,
  Typography,
} from 'components'
import ActionPlan from 'module/Learning/CohortTracking/components/ActionPlan'

import i18n from 'translations'

import ComparisonChart from './components/ComparisonChart'
import ComparisonTable from './components/ComparisonTable'
import { StyledFilterWrapper, StyledLegendItem, StyledRectangle, StyledToolbar } from './ComparisonReportStyled'

const ComparisonReportView = ({
  comparisonReportTableData,
  ethnicities,
  frameworksList,
  isFetching,
  itsUnsavedPreview,
  keyWorker,
  nurseryClass,
  onChangeActionPlan,
  onChangeFilters,
  onChangeSelectedProgressLevel,
  onFilterComparisonTable,
  onRemoveReport,
  onSaveReport,
  progressLevels,
  report,
  reportType,
  selectedProgressLevel,
  terms,
}) => {
  const generateFilters = () => {
    if (itsUnsavedPreview) {
      return cohortTrackingSelectors.generateFiltersLabel(report, {
        ethnicities,
        keyWorker,
        nurseryClass,
      })
    }

    return report.filters || '-'
  }

  const renderFilters = () => !isFetching && (
    <StyledFilterWrapper itsUnsavedPreview={itsUnsavedPreview}>
      {itsUnsavedPreview && (
        <Button
          label={i18n.t('global:Filters')}
          negativeMargins
          onClick={onChangeFilters}
        />
      )}
      <StyledToolbar>
        <div>
          <Typography fontSize={15} bold>
            {i18n.t('global:Framework')}
          </Typography>
          <Typography>
            {_.get(_.find(frameworksList, ({ id }) => id === report.framework?.id), 'type', '-')}
          </Typography>
        </div>
        <div>
          <Typography fontSize={15} bold>
            {i18n.t('module:Learning:CohortTracking:ComparisonReport:comparisonReportType')}
          </Typography>
          <Typography>
            {_.get(_.find(COMPARISON_REPORT_TYPES_DROPDOWN, ({ value }) => reportType === value), 'label')}
          </Typography>
        </div>
        <div>
          <Typography fontSize={15} bold>
            {i18n.t('global:Filters')}
          </Typography>
          <Typography maxWidth={500}>
            {generateFilters()}
          </Typography>
        </div>
      </StyledToolbar>
      {itsUnsavedPreview && (
        <Button
          label={i18n.t('global:Save')}
          negativeMargins
          onClick={onSaveReport}
        />
      )}
    </StyledFilterWrapper>
  )

  const renderToolbar = (expandPageButton) => (
    <Toolbar noMargin>
      <Toolbar.Group>
        <Toolbar.Item>
          {expandPageButton}
        </Toolbar.Item>
        {!itsUnsavedPreview && (
          <Toolbar.Item>
            <DropdownMenu>
              <DropdownMenu.Item
                type="delete"
                onClick={onRemoveReport}
              />
            </DropdownMenu>
          </Toolbar.Item>
        )}
      </Toolbar.Group>
    </Toolbar>
  )

  const renderLegend = () => (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <Typography color={NEUTRAL_COLOURS.GRAY} margin="20px 0">
            {i18n.t('module:Learning:CohortTracking:global:description')}
          </Typography>
        </Toolbar.Item>
      </Toolbar.Group>
      <Toolbar.Group>
        {_.map(progressLevels, (progressLevel) => (
          <Toolbar.Item key={progressLevel.id}>
            <StyledLegendItem>
              <StyledRectangle color={progressLevel.colour} />
              <Typography bold>
                {progressLevel.name}
              </Typography>
            </StyledLegendItem>
          </Toolbar.Item>
        ))}
      </Toolbar.Group>
    </Toolbar>
  )

  const renderContent = () => {
    if (isFetching) {
      return (
        <React.Fragment>
          <Space space="20px" />
          <Spinner />
          <Space space="20px" />
        </React.Fragment>
      )
    }

    if (!frameworksList?.length) {
      return (
        <EmptyState
          icon="cohort-tracking"
          text1={i18n.t('module:Learning:CohortTracking:ComparisonReportGenerator:noFrameworksList')}
          text2={(
            <Hyperlink to={generateRoute('MANAGEMENT.LEARNING.CURRICULUMS')} primary>
              {i18n.t('module:Learning:CohortTracking:global:goToSettings')}
            </Hyperlink>
          )}
        />
      )
    }

    if (!report?.data?.length) {
      return (
        <EmptyState
          icon="cohort-tracking"
          text1={i18n.t('module:Learning:CohortTracking:global:nothingFound')}
        />
      )
    }

    const frameworkDetails = _.find(frameworksList, ({ id }) => report.framework?.id === id)

    return (
      <React.Fragment>
        {renderLegend()}
        <ActionPlan
          defaultValue={report.actionPlan}
          initialValues={{ actionPlan: report.actionPlan }}
          itsUnsavedPreview={itsUnsavedPreview}
          onChangeActionPlan={onChangeActionPlan}
          onSubmit={noop}
        />
        <ComparisonChart
          frameworkDetails={frameworkDetails}
          progressLevels={progressLevels}
          records={report.data}
          report={report}
          reportType={reportType}
          terms={terms}
          onFilterComparisonTable={onFilterComparisonTable}
        />
        <ComparisonTable
          comparisonReportTableData={comparisonReportTableData}
          frameworkDetails={frameworkDetails}
          frameworkGroup={report.frameworkGroup}
          progressLevels={progressLevels}
          selectedProgressLevel={selectedProgressLevel}
          onChangeSelectedProgressLevel={onChangeSelectedProgressLevel}
        />
      </React.Fragment>
    )
  }

  return (
    <Page isLoading={isFetching}>
      {(expandPageButton) => (
        <Page.Content>
          <Section
            actions={renderToolbar(expandPageButton)}
            title={i18n.t('module:Learning:CohortTracking:ComparisonReport:title')}
          >
            {renderFilters()}
            {renderContent()}
          </Section>
        </Page.Content>
      )}
    </Page>
  )
}

export default ComparisonReportView
