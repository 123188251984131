import {
  CLEAR_ROOM_MOVE_LISTS,
  LIST_ROOM_MOVES,
  LIST_ROOM_MOVES_FAILED,
  LIST_ROOM_MOVES_SUCCESS,
  VALIDATE_ROOM_MOVES,
} from './constants'

export const list = (params, recurrency, onSuccess = () => {}) => ({ roomMoveApiClient }) => (dispatch) => {
  dispatch({
    type: LIST_ROOM_MOVES,
  })

  roomMoveApiClient.list(params).then(
    (response) => {
      dispatch({
        payload: { recurrency, response },
        type: LIST_ROOM_MOVES_SUCCESS,
      })
      onSuccess(response)
    },
    ({ response }) => dispatch({
      error: response,
      type: LIST_ROOM_MOVES_FAILED,
    }),
  )
}

export const clear = () => ({
  type: CLEAR_ROOM_MOVE_LISTS,
})

export const validateRoomMoves = () => ({
  type: VALIDATE_ROOM_MOVES,
})
