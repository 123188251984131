import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class MontessoriCategoriesApiClient extends ServiceBase {
  list = (params = {}) => this.request({
    path: `/v2/montessori_categories?${getQueryStringV2(params)}`,
  })
}

export default MontessoriCategoriesApiClient
