import _ from 'lodash'

import { generateRoute } from 'utils/routing'
import { getPercentage } from 'utils/data'

import { getNurseryPublicUrl } from 'services/nurseries/single/selectors/single'

import { Hyperlink } from 'components'

import i18n from 'translations'

import { BREAKDOWN_TYPE } from '../components/BreakdownSelect'

export const getTableData = ({ appProperties, data, filters, hasError, isOrganizationContext }) => {
  if (!data?.length || hasError) {
    return null
  }

  const displayPercentage = filters?.breakdown === BREAKDOWN_TYPE.PERCENTAGE

  return _.map(data, ({
    nursery,
    staff,
    viewingsAttended,
    viewingsThatLedToEnrollment,
    viewingsThatLedToLostEnquires,
    viewingsTotal,
    viewingsWithOpenEnquiryStatus,
  }) => {
    const baseRoute = generateRoute('STAFF.PROFILE.ABOUT', { userId: staff.id })

    return {
      nurseryName: nursery?.name,
      staffName: (
        <Hyperlink
          target={isOrganizationContext && '_blank'}
          to={`${getNurseryPublicUrl(nursery, appProperties)}${baseRoute}`}
          primary
        >
          {staff?.name}
        </Hyperlink>
      ),
      viewingsAttended,
      viewingsThatLedToEnrollment: displayPercentage
        ? `${getPercentage(viewingsTotal, viewingsThatLedToEnrollment)}%`
        : viewingsThatLedToEnrollment,
      viewingsThatLedToLostEnquires: displayPercentage
        ? `${getPercentage(viewingsTotal, viewingsThatLedToLostEnquires)}%`
        : viewingsThatLedToLostEnquires,
      viewingsWithOpenEnquiryStatus: displayPercentage
        ? `${getPercentage(viewingsTotal, viewingsWithOpenEnquiryStatus)}%`
        : viewingsWithOpenEnquiryStatus,
    }
  })
}

export const getStatistics = ({ data, filters, hasError, isOrganizationContext }) => {
  if (!data || !isOrganizationContext || hasError) {
    return null
  }

  const displayPercentage = filters?.breakdown === BREAKDOWN_TYPE.PERCENTAGE

  const {
    viewingsAttended,
    viewingsThatLedToEnrollment,
    viewingsThatLedToLostEnquires,
    viewingsTotal,
    viewingsWithOpenEnquiryStatus,
  } = data

  return [
    {
      label: i18n.t('module:Enquiries:Report:ViewingPerformance:Table:Header:viewingsAttended'),
      value: viewingsAttended,
    },
    {
      label: i18n.t('module:Enquiries:Report:ViewingPerformance:Table:Header:viewingsThatLedToEnrollment'),
      value: displayPercentage
        ? `${getPercentage(viewingsTotal, viewingsThatLedToEnrollment)}%`
        : viewingsThatLedToEnrollment,
    },
    {
      label: i18n.t('module:Enquiries:Report:ViewingPerformance:Table:Header:viewingsThatLedToLostEnquires'),
      value: displayPercentage
        ? `${getPercentage(viewingsTotal, viewingsThatLedToLostEnquires)}%`
        : viewingsThatLedToLostEnquires,
    },
    {
      label: i18n.t('module:Enquiries:Report:ViewingPerformance:Table:Header:viewingsWithOpenEnquiryStatus'),
      value: displayPercentage
        ? `${getPercentage(viewingsTotal, viewingsWithOpenEnquiryStatus)}%`
        : viewingsWithOpenEnquiryStatus,
    },
  ]
}
