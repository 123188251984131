import _ from 'lodash'
import { nest } from 'utils/flatnest'
import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { constants as invoicesConstants, withInvoicesService } from 'services/legacy/invoices'

import { EVENTS, logEvent } from 'analytics'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withNurseriesService } from 'services/nurseries'
import { withRouter } from 'services/router'

import i18n from 'translations'

import { withGenerateInvoicesService } from '../service'

import FinanceGenerateInvoicesView from './FinanceGenerateInvoicesPreviewView'
import { getChildren } from './helpers'

const LIMIT = 10

const INVOICE_AUTO_CALCULATE_GROUPS = {
  read: [
    'calculator.results',
    'calculator.result',
    'calculator.instructions',
    'calculator.preview',
    'invoicePreview',
    'invoicePreview.invoice',
    'invoice.items',
  ],
}

const INVOICE_UPDATE_GROUPS = { read: ['child', 'invoice'] }
const INVOICE_GROUPS = { read: ['invoice'] }

const NURSERY_INVOICE_SETTINGS_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings.invoice',
    'nurseryInvoiceSettings',
  ],
}

const PULLING_INTERVAL = 5000

class FinanceGenerateInvoicesPreviewContainer extends Component {
  constructor(props) {
    super(props)

    const { query } = props.location

    this.state = {
      displayLoader: true,
      filters: {
        room: null,
      },
      page: 1,
      query: nest(query),
      selected: [],
      selectedAll: false,
    }
  }

  componentDidMount() {
    const { nurseriesActions, nurseryOptions } = this.props

    this.interval = setInterval(this.updateInvoicesStatus, PULLING_INTERVAL)

    nurseriesActions.get(nurseryOptions.id, {
      params: { groups: NURSERY_INVOICE_SETTINGS_GROUPS },
    })
    this.fetch()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { activeChildren } = this.props
    const { selectedAll } = this.state

    if (selectedAll && activeChildren.length !== nextProps.activeChildren.length) {
      this.setState({ selected: nextProps.activeChildren })
    }
  }

  componentWillUnmount() {
    const { generateInvoicesActions } = this.props

    clearInterval(this.interval)
    generateInvoicesActions.clear()
  }

  updateInvoicesStatus = () => {
    const { generateInvoicesActions, invoicesActions, invoicesIdsBySendingStatus, invoicesSelectors } = this.props

    if (!invoicesIdsBySendingStatus || !invoicesIdsBySendingStatus.length) {
      return
    }

    const criteria = invoicesSelectors.getCriteriaSelector({ invoiceIds: invoicesIdsBySendingStatus })

    const params = {
      criteria,
      groups: INVOICE_UPDATE_GROUPS,
    }

    invoicesActions.list(params, false, ({ data }) => {
      generateInvoicesActions.updateBulkInvoices({
        data: _.map(data, ({ child, ...invoice }) => ({ child, invoice })),
      })
    })
  }

  getInvoicesDetails = ({ data }) => {
    const { generateInvoicesActions, generateInvoicesSelectors } = this.props
    const { query } = this.state
    const { period: { after: startDate, before: endDate } } = query

    const autoCalculateApiParams = {
      groups: INVOICE_AUTO_CALCULATE_GROUPS,
    }

    const body = generateInvoicesSelectors.getAutoCalculateBodySelector({ endDate, startDate })

    data.forEach(({ id }) => {
      const previousInvoicesApiParams = {
        criteria: generateInvoicesSelectors.getPreviousInvoicesCriteriaSelector({ childId: id }),
        groups: INVOICE_GROUPS,
        limit: 5,
        order: { sortField: 'startDate', sortOrder: 'DESC' },
        page: 1,
      }

      generateInvoicesActions.autoCalculateInvoice(id, autoCalculateApiParams, body)
      generateInvoicesActions.listInvoices(id, previousInvoicesApiParams)
    })
  }

  fetch = () => {
    const {
      generateInvoicesActions,
      generateInvoicesSelectors,
    } = this.props
    const { filters: { room }, page, query } = this.state

    const { getCriteria } = generateInvoicesSelectors
    const { children } = query

    const criteria = getCriteria({
      children: children.split(','),
      isArchived: false,
      room: room?.value,
    })

    const params = {
      criteria,
      groups: {},
      limit: LIMIT,
      page,
    }

    generateInvoicesActions.listChildren(params, this.getInvoicesDetails, 1 === page)
  }

  handleRoomChange = (room) => {
    this.setState(
      (prevState) => ({
        displayLoader: true,
        filters: {
          ...prevState.filters,
          room,
        },
        page: 1,
      }),
      () => this.fetch(),
    )
  }

  handleChildSelected = (childId) => {
    this.setState((prevState) => {
      const { selected } = prevState

      if (0 <= _.findIndex(selected, (id) => id === childId)) {
        return ({
          selected: _.reject(prevState.selected, (id) => id === childId),
          selectedAll: false,
        })
      }

      return ({
        selected: [...selected, childId],
        selectedAll: false,
      })
    })
  }

  handleSelectAll = () => {
    const { activeChildren } = this.props

    this.setState((prevState) => ({
      selected: !prevState.selectedAll ? activeChildren : [],
      selectedAll: !prevState.selectedAll,
    }))
  }

  handleNextFetch = () => {
    this.setState((prevState) => ({
      displayLoader: false,
      page: prevState.page + 1,
    }), this.fetch)
  }

  handleSuccess = (action) => {
    const { modalActions } = this.props
    const { MARK_AS_SEND } = invoicesConstants.SAVE_INVOICE_ACTION

    modalActions.hide()

    this.setState({
      selected: [],
      selectedAll: false,
    })

    if (!action) {
      logEvent(EVENTS.INVOICE_DRAFT_CREATED, { context: 'invoice bulk generation' })

      return
    }

    if (action === MARK_AS_SEND) {
      logEvent(EVENTS.INVOICE_MARK_SENT_SUCCESSFUL, { context: 'invoice bulk generation' })

      return
    }

    logEvent(EVENTS.INVOICE_SEND_SUBMITTED, { context: 'invoice bulk generation' })
  }

  handleFail = () => {
    const { modalActions } = this.props

    modalActions.hide()
  }

  saveEstimatedInvoice = (status, action) => () => {
    const { generateInvoicesActions, generateInvoicesSelectors, modalActions, modalConsts } = this.props
    const { filters: { room }, query, selected, selectedAll } = this.state
    const { MARK_AS_SEND, SEND } = invoicesConstants.SAVE_INVOICE_ACTION

    const { STATUS_TYPES: { SENT } } = invoicesConstants
    const { children: queryChildren, dueDate, issueDate, name, period: { after: startDate, before: endDate } } = query
    const { getCriteria } = generateInvoicesSelectors

    modalActions.show(modalConsts.TYPES.ALERT, {
      hideButton: true,
      icon: 'refresh',
      text: i18n.t('module:Finance:GenerateInvoices:updatingBulkInvoicesCopy'),
    })

    const payload = {
      dueDate,
      endDate,
      issueDate,
      markAsSent: SENT === status && MARK_AS_SEND === action ? true : undefined,
      name,
      sendInvoice: SENT === status && SEND === action ? true : undefined,
      startDate,
    }

    const children = getChildren(selectedAll, selected, queryChildren)

    const criteria = getCriteria({ children, room })

    const apiParams = {
      criteria,
      groups: INVOICE_UPDATE_GROUPS,
    }

    generateInvoicesActions.createBulkInvoices(
      payload,
      apiParams,
      status,
      () => this.handleSuccess(action),
      this.handleFail,
    )
  }

  handleSaveAsDraftClick = () => {
    const { modalActions, modalConsts } = this.props
    const { STATUS_TYPES } = invoicesConstants

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('global:saveAsDraft'),
      icon: 'check',
      onConfirm: this.saveEstimatedInvoice(STATUS_TYPES.DRAFT),
      text: i18n.t('module:Finance:GenerateInvoices:saveAsDraftInvoicesCopy'),
    })
  }

  handleSaveAndSendClick = () => {
    const { modalActions, modalConsts } = this.props
    const { STATUS_TYPES } = invoicesConstants

    logEvent(EVENTS.INVOICE_SEND_BTN_CLICKED, { context: 'invoice bulk generation' })

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('module:Finance:GenerateInvoices:saveAndSendPopupConfirmButtonLabel'),
      icon: 'send',
      onConfirm: this.saveEstimatedInvoice(STATUS_TYPES.SENT, invoicesConstants.SAVE_INVOICE_ACTION.SEND),
      text: i18n.t('module:Finance:GenerateInvoices:sentPopupText'),
    })
  }

  handleMarkAsSentClick = () => {
    const { modalActions, modalConsts } = this.props
    const { STATUS_TYPES } = invoicesConstants

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('global:MarkAsSent'),
      icon: 'mark-as-sent',
      onConfirm: this.saveEstimatedInvoice(STATUS_TYPES.SENT, invoicesConstants.SAVE_INVOICE_ACTION.MARK_AS_SEND),
      text: i18n.t('module:Finance:GenerateInvoices:markAsSentPopupText'),
    })

    logEvent(EVENTS.INVOICE_MARK_SENT_CLICKED, { context: 'invoice bulk generation' })
  }

  handleAmountClick = (childId, invoiceId) => () => {
    const { modalActions, modalConsts } = this.props
    const { query: autoCalculateParams } = this.state

    modalActions.show(modalConsts.TYPES.INVOICE_PREVIEW, {
      autoCalculateParams,
      childId,
      invoiceId,
    })
  }

  render() {
    const {
      childList,
      errors,
      generateInvoicesState,
      isFetching,
    } = this.props
    const { displayLoader, filters: { room }, query: { period }, selected, selectedAll } = this.state

    const hasMore = 0 < (generateInvoicesState.meta.total_results - (childList ? childList.length : 0))
    const isLoading = isFetching && displayLoader
    const disableSaveButton = !selectedAll && !selected.length

    return (
      <FinanceGenerateInvoicesView
        childList={childList}
        disableSaveButton={disableSaveButton}
        errors={errors}
        hasMore={hasMore}
        isLoading={isLoading}
        period={period}
        room={room}
        selected={selected}
        selectedAll={selectedAll}
        onAmountClick={this.handleAmountClick}
        onChildSelected={this.handleChildSelected}
        onMarkAsSentClick={this.handleMarkAsSentClick}
        onNextFetch={this.handleNextFetch}
        onRoomChange={this.handleRoomChange}
        onSaveAndSendClick={this.handleSaveAndSendClick}
        onSaveAsDraftClick={this.handleSaveAsDraftClick}
        onSelectAll={this.handleSelectAll}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  generateInvoicesSelectors,
  generateInvoicesState,
  nurseriesSelectors,
  params,
}) => ({
  activeChildren: generateInvoicesSelectors.getActiveChildren(state),
  childList: generateInvoicesSelectors.getFormattedInvoiceData(state),
  errors: generateInvoicesSelectors.getFormattedErrors(state),
  invoicesIdsBySendingStatus: generateInvoicesSelectors.getInvoicesIdsBySendingStatus(state),
  isFetching: appSelectors.getIsFetching(generateInvoicesState),
  isGroupedInvoice: nurseriesSelectors.isGroupedInvoice(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withNurseriesService,
  withInvoicesService,
  withGenerateInvoicesService,
  connect(mapState),
)

export default enhance(FinanceGenerateInvoicesPreviewContainer)

