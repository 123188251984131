import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import selectors from './selectors'

const withLeaveTypesService = (WrappedComponent) => {
  const mapState = ({ leaveTypes }) => ({ leaveTypes })

  const mapDispatch = injectActions('leaveTypesActions', actions)

  const Component = (props) => {
    const { leaveTypes, leaveTypesActions, ...other } = props

    return (
      <WrappedComponent
        leaveTypesActions={leaveTypesActions}
        leaveTypesListState={leaveTypes.list}
        leaveTypesSelectors={selectors}
        leaveTypesSingleState={leaveTypes.single}
        {...other}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withLeaveTypesService
