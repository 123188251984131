import {
  CLEAR_CHILD_EXTRA_ITEM_PROJECTIONS,
  LIST_GET_CHILD_EXTRA_ITEM_PROJECTIONS,
  LIST_GET_CHILD_EXTRA_ITEM_PROJECTIONS_FAILED,
  LIST_GET_CHILD_EXTRA_ITEM_PROJECTIONS_SUCCESS,
} from './actions'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: {
    // eslint-disable-next-line camelcase
    total_results: 0,
  },
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case LIST_GET_CHILD_EXTRA_ITEM_PROJECTIONS:
      return { ...state, error: initialState.error, isFetching: true }
    case LIST_GET_CHILD_EXTRA_ITEM_PROJECTIONS_SUCCESS:
      return { ...state, data: payload.data, error: initialState.error, isFetching: false }
    case LIST_GET_CHILD_EXTRA_ITEM_PROJECTIONS_FAILED:
      return { ...state, error, isFetching: false }
    case CLEAR_CHILD_EXTRA_ITEM_PROJECTIONS:
      return initialState
    default:
      return state
  }
}
