import { LIST_CHILD_DISCOUNTS, LIST_CHILD_DISCOUNTS_FAILED, LIST_CHILD_DISCOUNTS_SUCCESS } from './constants'

export const list = (params, mergeData, onSuccess) => ({ childDiscountsApiClient }) => (dispatch) => {
  dispatch({
    params,
    type: LIST_CHILD_DISCOUNTS,
  })

  childDiscountsApiClient.list(params).then(
    (data) => {
      dispatch({
        mergeData,
        payload: data,
        type: LIST_CHILD_DISCOUNTS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: LIST_CHILD_DISCOUNTS_FAILED,
      })
    },
  )
}
