export const getPayload = (fields) => {
  if (!fields) {
    return null
  }

  const { accountName,
    accountNumber,
    bankAccountNotes,
    bankName,
    carerId,
    sortCode,
    usualPaymentMethod,
  } = fields

  return {
    accountName,
    accountNumber,
    bankAccountNotes,
    bankName,
    carerId,
    sortCode,
    usualPaymentMethod: usualPaymentMethod?.value,
  }
}

export const getInitialValues = (childBankData) => {
  if (!childBankData) {
    return null
  }

  const {
    accountName,
    accountNumber,
    bankAccountNotes,
    bankName,
    paymentContact,
    sortCode,
    usualPaymentMethod,
  } = childBankData

  return {
    accountName,
    accountNumber,
    bankAccountNotes,
    bankName,
    carerId: paymentContact && paymentContact.carer ? paymentContact.carer.id : null,
    sortCode,
    usualPaymentMethod: usualPaymentMethod ? {
      label: usualPaymentMethod?.name,
      value: usualPaymentMethod?.id,
    } : null,
  }
}
