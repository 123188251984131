import _ from 'lodash'
import moment from 'moment'

import React from 'react'
import { Field, FieldArray, reduxForm } from 'redux-form'
import { compose } from 'recompose'

import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'
import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'
import { constants as invoicesConstants } from 'services/legacy/invoices'

import { isRequired } from 'utils/fieldValidation'

import { Currency, DropdownMenu, Form, Space, Table, Typography } from 'components'

import i18n from 'translations'

import Info from './Info'

export const RE_ISSUE_INVOICES_FORM = 'ReIssueInvoicesForm'
const TRANS_PREFIX = 'module:Children:Child:BookingPattern:AutoCreditNoting:ReIssueInvoices'

const renderRow = ({
  childId,
  onDeleteInvoiceClick,
  onEditInvoiceClick,
  onInvoiceItemClick,
}) => (
  fieldName,
  index,
  fields,
) => {
  const invoice = fields.get(index)

  const {
    displayName,
    endDate,
    hasError,
    hasNoItems,
    id: invoiceId,
    issueDate,
    items,
    origin,
    startDate,
    total,
  } = invoice

  const hasCustomDiscount = _.some(items, ({ type }) => type === invoicesConstants.LINE_ITEM_TYPES.DISCOUNT)

  const renderTotal = () => {
    if (hasNoItems) {
      return (
        <Info
          color={FLAG_COLOURS.ERROR}
          text={i18n.t(`${TRANS_PREFIX}:Table:Columns:Total:noItemCopy`)}
        />
      )
    }

    if (hasError) {
      return (
        <Info
          color={FLAG_COLOURS.ERROR}
          text={i18n.t(`${TRANS_PREFIX}:Table:Columns:Total:errorCopy`)}
        />
      )
    }

    return (
      <React.Fragment>
        <Currency value={total} />
        {hasCustomDiscount && (
          <React.Fragment>
            <Space space="5px" />
            <Info
              color={FLAG_COLOURS.INFO}
              text={i18n.t(`${TRANS_PREFIX}:Table:Columns:Total:discountReviewCopy`)}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  const renderAction = () => {
    if (hasNoItems || hasError) {
      return (
        <Typography color={NEUTRAL_COLOURS.GRAY}>
          {i18n.t(`${TRANS_PREFIX}:Table:Columns:Action:noItemCopy`)}
        </Typography>
      )
    }

    if (origin?.status === invoicesConstants.STATUS_TYPES.DRAFT) {
      return (
        <Typography color={NEUTRAL_COLOURS.GRAY}>
          {i18n.t(`${TRANS_PREFIX}:Table:Columns:Action:prevDraftCopy`)}
        </Typography>
      )
    }

    return (
      <Field
        clearable={false}
        component={Form.Select}
        name={`${fieldName}action`}
        options={invoicesConstants.SAVE_INVOICE_ACTION_DROPDOWN}
        validate={isRequired}
      />
    )
  }

  return (
    <Table.Tr>
      <Table.Td align="left">
        <Typography>
          <Typography
            cursor={hasNoItems || hasError ? 'default' : 'pointer'}
            underline={!(hasNoItems || hasError)}
            onClick={() => (hasNoItems || hasError ? ({}) : onInvoiceItemClick(childId, invoiceId))}
          >
            {origin?.status === invoicesConstants.STATUS_TYPES.DRAFT && `(${_.upperFirst(i18n.t('global:draft'))}) `}
            {displayName}
          </Typography>
          <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
            {moment(startDate).format(DISPLAY_SHORT_DATE_FORMAT)}
            {' - '}
            {moment(endDate).format(DISPLAY_SHORT_DATE_FORMAT)}
          </Typography>
        </Typography>
      </Table.Td>
      <Table.Td align="left">
        {!hasNoItems && !hasError && moment(issueDate).format(DISPLAY_SHORT_DATE_FORMAT)}
      </Table.Td>
      <Table.Td align="left">
        {renderTotal()}
      </Table.Td>
      <Table.Td align="left">
        {renderAction()}
      </Table.Td>
      <Table.Td>
        {!hasNoItems && !hasError && (
          <DropdownMenu small>
            <DropdownMenu.Item
              type="edit"
              onClick={() => onEditInvoiceClick(invoiceId)}
            />
            <DropdownMenu.Item
              type="remove"
              onClick={() => onDeleteInvoiceClick(invoiceId)}
            />
          </DropdownMenu>
        )}
      </Table.Td>
    </Table.Tr>
  )
}

const renderFieldArray = ({
  childId,
  fields,
  onDeleteInvoiceClick,
  onEditInvoiceClick,
  onInvoiceItemClick,
}) => fields.map(renderRow({
  childId,
  onDeleteInvoiceClick,
  onEditInvoiceClick,
  onInvoiceItemClick,
}))

const ReIssueInvoicesForm = ({
  childId,
  handleSubmit,
  onDeleteInvoiceClick,
  onEditInvoiceClick,
  onInvoiceItemClick,
  onSubmit,
}) => {
  const renderTableHeader = () => (
    <Table.Tr>
      <Table.Th align="left">
        {i18n.t(`${TRANS_PREFIX}:Table:Header:invoice`)}
      </Table.Th>
      <Table.Th align="left">
        {i18n.t(`${TRANS_PREFIX}:Table:Header:invoiceDate`)}
      </Table.Th>
      <Table.Th align="left">
        {i18n.t(`${TRANS_PREFIX}:Table:Header:invoiceTotal`)}
      </Table.Th>
      <Table.Th align="left">
        {i18n.t(`${TRANS_PREFIX}:Table:Header:action`)}
      </Table.Th>
      <Table.Th />
    </Table.Tr>
  )

  const renderTableRows = () => (
    <FieldArray
      component={renderFieldArray}
      name="invoices"
      props={{
        childId,
        onDeleteInvoiceClick,
        onEditInvoiceClick,
        onInvoiceItemClick,
      }}
    />
  )

  const renderTable = () => (
    <Table visualType="transparent">
      {renderTableHeader()}
      {renderTableRows()}
    </Table>
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {renderTable()}
    </Form>
  )
}

const enhance = compose(reduxForm({ form: RE_ISSUE_INVOICES_FORM }))

export default enhance(ReIssueInvoicesForm)
