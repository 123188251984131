import styled from 'styled-components'

export const StyledSpinnerWrapper = styled.div`
  display: grid;
  grid-column-gap: 7px;
  grid-template-columns: auto 20px 1fr;
  
  ${({ disableSpinner }) => disableSpinner && `
    grid-template-columns: auto;
    margin-bottom: 5px;
  `}
`
