import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { ROLES } from 'constants/security'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import { withShellService } from 'services/shell'
import { withRouter } from 'services/router'

import ObservationsWrapper from 'module/Learning/Observations/ObservationsWrapper'
import NextStepsWrapper from 'module/Learning/NextSteps/NextStepsWrapper'
import CohortTrackingWrapper from 'module/Learning/CohortTracking/CohortTrackingWrapper'

import i18n from 'translations'

import LearningWrapperView from './LearningWrapperView'

class LearningWrapperContainer extends Component {
  componentDidMount() {
    const {
      authAccessMap,
      shellActions,
    } = this.props

    shellActions.setSubMenu([
      {
        hidden: !authAccessMap.module.Observations,
        icon: 'observations',
        label: _.startCase(i18n.t('module:Learning:Observations:title')),
        to: '/learning/observations',
      },
      {
        hidden: !authAccessMap.module.NextSteps,
        icon: 'next-steps',
        label: _.startCase(i18n.t('module:Learning:NextSteps:title')),
        to: '/learning/next-steps',
      },
      {
        hidden: !authAccessMap.module.CohortTracking,
        icon: 'cohort-tracking',
        label: _.startCase(i18n.t('module:Learning:CohortTracking:title')),
        to: '/learning/cohort-tracking',
      },
      {
        hidden: !authAccessMap.module.CohortTracking,
        icon: 'observation-tracker',
        label: _.startCase(i18n.t('module:Learning:ObservationTracker:title')),
        premium: !authAccessMap.module.CohortTracking,
        to: '/learning/observation-tracker',
      },
      {
        icon: 'formative-reports',
        label: _.startCase(i18n.t('module:Learning:FormativeReports:title')),
        premium: true,
        to: '/learning/formative-reports',
      },
    ])
  }

  shouldComponentUpdate(nextProps) {
    const { location: { pathname }, navigate } = nextProps

    if (generateRoute('LEARNING.INDEX') === pathname) {
      navigate(generateRoute('LEARNING.OBSERVATIONS'))
    }

    return true
  }

  componentWillUnmount() {
    const { shellActions } = this.props

    shellActions.clearSubMenu()
  }

  render() {
    const { children } = this.props

    return (
      <LearningWrapperView>
        {children}
      </LearningWrapperView>
    )
  }
}

LearningWrapperContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.ROOM_LEADER,
    ROLES.SENIOR_TEACHER,
    ROLES.TEACHER,
  ],
}

const mapState = (state) => ({
  authAccessMap: {
    module: {
      CohortTracking: auth.SELECTORS.getComponentIsAuthorised(state, CohortTrackingWrapper),
      NextSteps: auth.SELECTORS.getComponentIsAuthorised(state, NextStepsWrapper),
      Observations: auth.SELECTORS.getComponentIsAuthorised(state, ObservationsWrapper),
    },
  },
})

const enhance = compose(
  withRouter,
  withShellService,
  connect(mapState),
)

export default enhance(LearningWrapperContainer)
