import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class LeaveNotesApiClient extends ServiceBase {
  create = (payload = {}, params = {}) => this.request({
    body: payload,
    method: 'POST',
    path: `/v2/leave_notes?${getQueryStringV2(params)}`,
  })

  list = (params = {}) => this.request({
    path: `/v2/leave_notes?${getQueryStringV2(params)}`,
  })
}

export default LeaveNotesApiClient
