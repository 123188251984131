export const INJURY_SIGNATURE_STEP_TYPES = {
  INFO: 'INFO',
  REPORT: 'REPORT',
  SIGNATURE: 'SIGNATURE',
}

export const INJURY_SIGNATURE_STEPS = [
  INJURY_SIGNATURE_STEP_TYPES.INFO,
  INJURY_SIGNATURE_STEP_TYPES.REPORT,
  INJURY_SIGNATURE_STEP_TYPES.SIGNATURE,
]
