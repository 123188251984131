import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withMembershipRegistersService } from 'services/legacy/membershipRegisters'
import { withModalService } from 'services/utils/modal'

import ChangeLogLegacyModalView from './ChangeLogLegacyModalView'

const GROUPS = {
  read: ['membership'],
}

class ChangeLogLegacyModalContainer extends Component {
  componentDidMount() {
    const { membershipRegistersActions, record } = this.props

    membershipRegistersActions.getChangelog(record?.id, {
      params: { groups: GROUPS },
    })
  }

  componentWillUnmount() {
    const { membershipRegistersActions } = this.props

    membershipRegistersActions.clearChangelog()
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  render() {
    const {
      changelogData,
      memberDetails,
      previousRecord,
      selectedDate,
      worklogDayType,
    } = this.props

    return (
      <ChangeLogLegacyModalView
        changelogData={changelogData}
        memberDetails={memberDetails}
        previousRecord={previousRecord}
        selectedDate={selectedDate}
        worklogDayType={worklogDayType}
        onCloseClick={this.handleCloseClick}
      />
    )
  }
}

const mapState = (state, { membershipRegistersSelectors }) => ({
  changelogData: membershipRegistersSelectors.getChangelogDataSelector(state),
})

const enhance = compose(
  withMembershipRegistersService,
  withModalService,
  connect(mapState),
)

export default enhance(ChangeLogLegacyModalContainer)
