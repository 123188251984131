import _ from 'lodash'
import { flatten, nest } from 'utils/flatnest'

import { compose } from 'recompose'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { change, getFormValues, initialize, stopSubmit } from 'redux-form'

import { FRAMEWORK_STRUCTURE_APPLICABLE } from 'services/legacy/frameworks/constants'
import { AGES_MAP, COMPARISON_REPORT_TYPES, FILTERS_MAP } from 'services/legacy/cohortTracking/constants'
import { generateRoute } from 'utils/routing'
import eventBus from 'utils/eventBus'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withCohortTrackingService } from 'services/legacy/cohortTracking'
import { withEthnicitiesService } from 'services/legacy/ethnicities'
import { withFormativeReportsService } from 'services/legacy/formativeReports'
import { withFrameworksService } from 'services/legacy/frameworks'
import { withRouter } from 'services/router'

import {
  COMPARISON_REPORT_GENERATOR_FORM,
} from './components/ComparisonReportGeneratorForm/ComparisonReportGeneratorForm'
import ComparisonReportGeneratorView from './ComparisonReportGeneratorView'

const FRAMEWORK_GROUPS = {
  read: [
    'framework.areas',
    'frameworkArea',
    'structure',
    'structure.cohort',
    'frameworkArea.groups',
    'frameworkGroup',
    'framework.default',
  ],
}

class ComparisonReportGeneratorContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      frameworkGroupsOptions: [],
      initialValues: {},
    }
  }

  async componentDidMount() {
    const {
      ethnicitiesActions,
      formativeReportsActions,
      frameworksActions,
    } = this.props

    this.injectInitialValues()

    ethnicitiesActions.list()
    frameworksActions.list({
      onSuccess: this.handleFrameworkSuccess,
      params: [{
        groups: FRAMEWORK_GROUPS,
      }],
    })
    formativeReportsActions.getTerms()
  }

  componentWillUnmount() {
    const {
      ethnicitiesActions,
      formativeReportsActions,
      frameworksActions,
    } = this.props

    formativeReportsActions.clearTerms()
    ethnicitiesActions.clear()
    frameworksActions.clear()
  }

  injectInitialValues = () => {
    const { router: { location: { query } } } = this.props
    const { params } = query || {}

    try {
      if (params) {
        const values = nest(JSON.parse(params))
        const location = _.assign({}, browserHistory.getCurrentLocation())
        location.search = ''
        location.query = {}

        browserHistory.push(location)

        if (values.type) {
          values.type = {
            value: values.type,
          }
        }

        if (values.framework) {
          values.framework = {
            value: +values.framework,
          }
        }

        if (values.term) {
          values.term = {
            value: values.term,
          }
        }

        if (values.frameworkGroup) {
          values.frameworkGroup = {
            value: +values.frameworkGroup,
          }
        }

        if (values.filters?.ethnicity) {
          values.filters.ethnicity = {
            value: +values.filters.ethnicity,
          }
        }

        if (values.filters?.keyWorker) {
          values.filters.keyWorker = {
            value: +values.filters.keyWorker,
          }
        }

        if (values.filters?.nurseryClass) {
          values.filters.nurseryClass = {
            value: +values.filters.nurseryClass,
          }
        }

        if (values?.filters?.age) {
          values.filters.age = _.find(AGES_MAP, ({ value }) => (
            value.from === +values.filters.age.from && value.to === +values.filters.age.to
          ))
        }

        if (values?.filters?.gender) {
          values.filters.gender = _.map(values.filters.gender, (value) => ({
            value,
          }))
        }

        _.forEach(FILTERS_MAP, ({ value }) => {
          if ('string' === typeof values?.filters?.[value]) {
            values.filters[value] = 'true' === values.filters[value].toLowerCase()
          }
        })

        this.setState({ initialValues: values })
      }
    } catch (e) { }
  }

  handleFrameworkSuccess = () => {
    const { changeFieldValue, cohortTrackingSelectors, frameworksList } = this.props
    const { initialValues } = this.state

    if (initialValues?.framework?.value) {
      const frameworkDetails = _.find(frameworksList, ({ id }) => initialValues?.framework?.value === id)

      return this.setState({
        frameworkGroupsOptions: cohortTrackingSelectors.getFrameworkGroupsOptions(frameworkDetails),
      })
    }

    const defaultFramework = _.find(frameworksList, 'default')?.id

    if (defaultFramework) {
      const frameworkDetails = _.find(frameworksList, ({ id }) => defaultFramework === id)

      changeFieldValue('framework', { value: defaultFramework })
      this.setState({
        frameworkGroupsOptions: cohortTrackingSelectors.getFrameworkGroupsOptions(frameworkDetails),
        initialValues: {
          ...initialValues,
          framework: { value: defaultFramework },
        },
      })
    }

    return null
  }

  handleResetFilters = (eventsArray) => () => {
    const { changeFieldValue } = this.props

    _.each(eventsArray, (eventName) => eventBus.dispatch(eventName))
    changeFieldValue('filters', {})
  }

  handleChangeComparisonType = () => {
    const { changeFieldValue } = this.props

    changeFieldValue('term', undefined)
    changeFieldValue('ethnicity', undefined)
    changeFieldValue('frameworkGroup', undefined)
  }

  handleChangeFramework = () => setTimeout(() => {
    const { changeFieldValue, cohortTrackingSelectors, formValues, frameworksList } = this.props

    const frameworkDetails = _.find(frameworksList, ({ id }) => formValues?.framework?.value === id)

    this.setState({
      frameworkGroupsOptions: cohortTrackingSelectors.getFrameworkGroupsOptions(frameworkDetails),
    })

    changeFieldValue('frameworkGroup', undefined)
  })

  handleSubmit = (values) => {
    const { cohortTrackingSelectors, navigate } = this.props
    const { ethnicity, filters, framework, frameworkGroup, term, type } = values

    const params = {
      filters: {},
      framework: framework?.value,
      frameworkGroup: frameworkGroup?.value,
      term: term?.value,
      type: type?.value,
    }

    if (COMPARISON_REPORT_TYPES.ETHNICITY === type?.value) {
      params.ethnicity = ethnicity.value
    }

    params.filters = cohortTrackingSelectors.getFilterParams(filters)

    if (!_.keys(params.filters)?.length) {
      delete params.filters
    }

    const queryParams = new URLSearchParams(flatten(params)).toString()

    return navigate(
      `${generateRoute(
        'LEARNING.COHORT_TRACKING.COMPARISON_REPORT.PREVIEW',
      )}?${queryParams}`,
    )
  }

  render() {
    const {
      ethnicities,
      formValues,
      frameworksList,
      frameworksOptions,
      isFetching,
      terms,
    } = this.props
    const { frameworkGroupsOptions, initialValues } = this.state

    return (
      <ComparisonReportGeneratorView
        ethnicities={ethnicities}
        formValues={formValues}
        frameworkGroupsOptions={frameworkGroupsOptions}
        frameworksList={frameworksList}
        frameworksOptions={frameworksOptions}
        initialValues={initialValues}
        isFetching={isFetching}
        terms={terms}
        onChangeComparisonType={this.handleChangeComparisonType}
        onChangeFramework={this.handleChangeFramework}
        onResetFilters={this.handleResetFilters}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  changeFieldValue: (field, value) => change(COMPARISON_REPORT_GENERATOR_FORM, field, value),
  initializeValues: (values) => initialize(COMPARISON_REPORT_GENERATOR_FORM, values),
  injectValidation: (errors) => stopSubmit(COMPARISON_REPORT_GENERATOR_FORM, errors),
}

const mapState = (state, {
  appSelectors,
  ethnicitiesSelectors,
  formativeReportsSelectors,
  formativeReportsTermsState,
  frameworksListState,
  frameworksSelectors,
}) => ({
  ethnicities: ethnicitiesSelectors.getEthnicitiesListDataDropdownSelector(state),
  formValues: getFormValues(COMPARISON_REPORT_GENERATOR_FORM)(state),
  frameworksList: frameworksSelectors.getFilteredFrameworksRawList(
    FRAMEWORK_STRUCTURE_APPLICABLE.COHORT_TRACKING,
  )(state),
  frameworksOptions: frameworksSelectors.getFilteredFrameworksList(
    FRAMEWORK_STRUCTURE_APPLICABLE.COHORT_TRACKING,
  )(state),
  isFetching: appSelectors.getIsFetching(frameworksListState, formativeReportsTermsState),
  terms: formativeReportsSelectors.getUsedTermsDropdownInFormativeReports(state),
})

const enhance = compose(
  withAppService,
  withCohortTrackingService,
  withEthnicitiesService,
  withFormativeReportsService,
  withFrameworksService,
  withRouter,
  withModalService,
  connect(mapState, mapDispatch),
)

export default enhance(ComparisonReportGeneratorContainer)
