import React from 'react'

import { Page, Section, Spinner } from 'components'

import AddExtraItemForm from './components/AddExtraItemForm'

const ManagementExtraItemsAddView = ({
  feeCalculationOptions,
  initialValues,
  isArchived,
  isEdit,
  isFormLoading,
  isLoading,
  nurseryOptions,
  onArchiveClick,
  onSubmit,
}) => {
  const renderContent = () => {
    if (isLoading || (isEdit && !initialValues)) {
      return (
        <Spinner />
      )
    }

    return (
      <AddExtraItemForm
        feeCalculationOptions={feeCalculationOptions}
        initialValues={initialValues}
        isLoading={isFormLoading}
        nurseryOptions={nurseryOptions}
        onSubmit={onSubmit}
      />
    )
  }

  const title = isEdit ? 'Edit a Item' : 'Add a Item'

  const actions = isEdit && (
    <Section.Actions
      options={[{
        onClick: onArchiveClick(!isArchived),
        type: isArchived ? 'unarchive' : 'archive',
      }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading || (isEdit && !initialValues)}
      title={title}
    >
      {renderContent()}
    </Page.Section>
  )
}

export default ManagementExtraItemsAddView
