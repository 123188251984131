import React, { PropsWithChildren } from 'react'

import FooterActionsFlex from './FooterActionsFlex'
import FooterActionsGroup from './FooterActionsGroup'
import FooterActionsItem from './FooterActionsItem'
import { StyledContainer } from './FooterActionsStyled'

interface FooterActionsRoot<T> extends React.FC<PropsWithChildren<T>> {
  Flex?: typeof FooterActionsFlex
  Group?: typeof FooterActionsGroup
  Item?: typeof FooterActionsItem
}

interface FooterActionsProps {
  full?: boolean
  noBorder?: boolean
  noMargin?: boolean
  noPadding?: boolean
  spaceBetween?: boolean
}

const FooterActions: FooterActionsRoot<FooterActionsProps> = ({
  children,
  full,
  noBorder,
  noMargin,
  noPadding,
  spaceBetween,
}) => (
  <StyledContainer
    $full={full}
    $noBorder={noBorder}
    $noMargin={noMargin}
    $noPadding={noPadding}
    $spaceBetween={spaceBetween}
  >
    {children}
  </StyledContainer>
)

export default FooterActions
