import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import { CLEAR_SINGLE_FRAMEWORK, GET_FRAMEWORK, GET_FRAMEWORK_FAILED, GET_FRAMEWORK_SUCCESS } from './constants'

const initialState = getBasicSingleInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicListReducer = getBasicSingleReducers({
    clear: CLEAR_SINGLE_FRAMEWORK,
    itemFailed: GET_FRAMEWORK_FAILED,
    itemInit: GET_FRAMEWORK,
    itemSuccess: GET_FRAMEWORK_SUCCESS,
  }, initialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
