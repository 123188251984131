import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import * as requestedExtraSessionsSelectors from './selectors'
import actions from './actions'

const withRequestedExtraSessionsService = (WrappedComponent) => {
  const mapState = ({ requestedExtraSessions }) => ({ requestedExtraSessions })

  const mapDispatch = injectActions('requestedExtraSessionsActions', actions)

  const Component = ({ requestedExtraSessions, requestedExtraSessionsActions, ...other }) => (
    <WrappedComponent
      exceptionalUnavailableTimesListState={requestedExtraSessions.exceptionalUnavailableTimesList}
      exceptionalUnavailableTimesSingleState={requestedExtraSessions.exceptionalUnavailableTimesSingle}
      regularUnavailableTimesBatchState={requestedExtraSessions.regularUnavailableTimesBatch}
      regularUnavailableTimesListState={requestedExtraSessions.regularUnavailableTimesList}
      requestedExtraSessionsActions={requestedExtraSessionsActions}
      requestedExtraSessionsAvailabilityStatisticsState={requestedExtraSessions.availabilityStatistics}
      requestedExtraSessionsCostStatisticsState={requestedExtraSessions.costStatistics}
      requestedExtraSessionsListState={requestedExtraSessions.list}
      requestedExtraSessionsSelectors={requestedExtraSessionsSelectors}
      requestedExtraSessionsSingleState={requestedExtraSessions.single}
      {...other}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withRequestedExtraSessionsService
