import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { TERM_CUSTOM, TYPE_OF_REPORT_DROPDOWN } from 'services/legacy/formativeReports/constants'

import { Form, Space } from 'components'

import i18n from 'translations'

import { StyledDatePicker } from '../FormativeReportsCreateStyled'

export const CHILD_LEARNING_JOURNEY_FORMATIVE_REPORTS_CREATE_FORM = 'FormativeReportsCreateForm'

const FormativeReportsCreateForm = ({
  cancelButtonLink,
  formValues,
  handleSubmit,
  isDisabled,
  isSubmitting,
  onSubmit,
  showForm,
  terms,
}) => {
  const renderForm = () => (
    <React.Fragment>
      <Form.Row label={i18n.t('module:Learning:FormativeReports:term')} required>
        <Form.Row.FlexItem flex="0 0 240px" mobileFull>
          <Field
            component={Form.Select}
            name="term"
            options={terms || []}
            placeholder={i18n.t('module:Learning:FormativeReports:FormativeReportsCreate:Form:selectTerm')}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
        {formValues?.term?.value === TERM_CUSTOM && (
          <React.Fragment>
            <Space margin="5px" inline />
            <Form.Row.FlexItem flex="0 0 240px" mobileFull>
              <Field
                component={Form.TextField}
                name="termCustom"
                placeholder={i18n.t('module:Learning:FormativeReports:FormativeReportsCreate:Form:enterCustomTerm')}
                validate={isRequired}
              />
              <Field
                component={Form.TextField}
                name="period.key"
                type="hidden"
              />
            </Form.Row.FlexItem>
          </React.Fragment>
        )}
      </Form.Row>
      <Form.Row label={i18n.t('module:Learning:FormativeReports:FormativeReportsCreate:Form:reportPeriod')} required>
        <Form.Row.FlexItem flex="0 0 240px" mobileFull>
          <StyledDatePicker>
            <Field
              component={Form.DatePicker}
              name="date"
              validate={isRequired}
              clearable
              range
            />
          </StyledDatePicker>
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:Learning:FormativeReports:FormativeReportsCreate:Form:typeOfReport')} required>
        <Form.Row.FlexItem flex="0 0 240px" mobileFull>
          <Field
            component={Form.Select}
            name="type"
            options={TYPE_OF_REPORT_DROPDOWN}
            placeholder={i18n.t('module:Learning:FormativeReports:FormativeReportsCreate:Form:typeOfReport')}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
    </React.Fragment>
  )

  const renderStickyFooter = () => (
    <Form.StickyFooter
      cancelLink={cancelButtonLink}
      isSubmitting={isSubmitting}
      submitDisabled={isDisabled}
      submitLabel={i18n.t('module:Learning:FormativeReports:FormativeReportsCreate:Form:createReport')}
    />
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {showForm && renderForm()}
      {renderStickyFooter()}
    </Form>
  )
}

export default reduxForm({ form: CHILD_LEARNING_JOURNEY_FORMATIVE_REPORTS_CREATE_FORM })(
  FormativeReportsCreateForm,
)
