import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'

const getNurserySessions = (state: RootState) => state.nurserySessions

export const getNurserySession = createSelector(
  [getNurserySessions],
  (state) => state.single.data,
)

export const isInheritedFromOrganizationSelector = createSelector(
  [getNurserySession],
  (nurserySession) => !!(nurserySession && nurserySession.organizationSession && nurserySession.organizationSession.id),
)
