import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { RootState } from 'core/reducers'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as childNotesSelectors from './selectors'
import { ChildNotesListState } from './list/reducer'
import { ChildNotesSingleState } from './single/reducer'

export interface withChildNotesServiceProps {
  childNotesActions: typeof actions
  childNotesListState: ChildNotesListState
  childNotesSelectors: any
  childNotesSingleState: ChildNotesSingleState
}

const withChildNotesService = <P extends withChildNotesServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ childNotes }: RootState) => ({ childNotes })

  const mapDispatch = injectActions('childNotesActions', actions)

  const Component = ({ childNotes, childNotesActions, ...others }) => (
    <WrappedComponent
      childNotesActions={childNotesActions}
      childNotesListState={childNotes.list}
      childNotesSelectors={childNotesSelectors}
      childNotesSingleState={childNotes.single}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withChildNotesService
