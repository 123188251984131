import { FEATURE_FLAGS, ROLES } from 'constants/security'

const RequestableExtraSessionsWrapper = ({ children }) => children

RequestableExtraSessionsWrapper.authParams = {
  flags: [FEATURE_FLAGS.REQUESTING_EXTRA_SESSIONS],
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

export default RequestableExtraSessionsWrapper
