import _ from 'lodash'
import { createSelector } from 'reselect'

import { convertFromMinutesToSeconds } from 'utils/date'

import { getOrganizationSettings } from './single'

import { GLOBAL_SECURITY_SETTINGS, GLOBAL_SECURITY_SETTINGS_OPTIONS } from '../../constants'

export const getSecuritySettings = createSelector(
  [getOrganizationSettings],
  (orgSettings) => {
    if (!orgSettings || !orgSettings.security) {
      return null
    }

    return orgSettings.security
  },
)

export const getSecurityIsGlobalInheritanceEnabled = createSelector(
  [getSecuritySettings],
  (securitySettings) => {
    if (!securitySettings) {
      return null
    }

    return securitySettings.globalInheritance
  },
)

export const getSecuritySessionExpireTimeSettings = createSelector(
  [getSecuritySettings],
  (securitySettings) => {
    if (!securitySettings) {
      return null
    }

    return securitySettings.sessionExpireTime
  },
)

export const getSecuritySessionExpireTimeEnabledSettings = createSelector(
  [getSecuritySettings],
  (securitySettings) => securitySettings?.sessionExpireTimeEnabled,
)

export const getGlobalSecurityOption = (globalInheritance) => _.find(GLOBAL_SECURITY_SETTINGS_OPTIONS, {
  value: globalInheritance
    ? GLOBAL_SECURITY_SETTINGS.ACROSS_ORGANIZATION
    : GLOBAL_SECURITY_SETTINGS.SEPARATELY_FOR_EACH_NURSERY,
})

export const getGlobalSecurityTypeDescription = createSelector(
  [getSecuritySettings],
  (securitySettings) => {
    if (!securitySettings) {
      return null
    }

    return getGlobalSecurityOption(securitySettings.globalInheritance)?.label
  },
)

export const getSecuritySettingsPayload = createSelector(
  [(values) => values],
  (values) => {
    const { fields, organizationSingleState } = values || {}
    const { organizationSettings } = organizationSingleState.data || {}

    if (!fields) {
      return null
    }

    const security = { ...organizationSettings.security }

    if (fields.globalSecuritySettingsTypes) {
      security.globalInheritance = fields.globalSecuritySettingsTypes === GLOBAL_SECURITY_SETTINGS.ACROSS_ORGANIZATION
    }

    if (null !== fields.sessionExpireTimeEnabled && undefined !== fields.sessionExpireTimeEnabled) {
      security.sessionExpireTimeEnabled = fields.sessionExpireTimeEnabled
      security.sessionExpireTime = fields.sessionExpireTime
        ? convertFromMinutesToSeconds(fields.sessionExpireTime)
        : null
    }

    return {
      organizationSettings: {
        ...organizationSettings,
        security: { ...security },
      },
    }
  },
)
