import { CONTEXT_ADMIN } from 'services/security/authorization/constants'

import { properties } from 'app-config'

import { GET_SUBDOMAIN, GET_SUBDOMAIN_FAILED, GET_SUBDOMAIN_SUCCESS } from './constants'

export const getSubdomain = (subdomain, { subdomainApiClient }, onSuccess, onFailed, params) => (dispatch) => {
  dispatch({
    payload: null,
    type: GET_SUBDOMAIN,
  })

  if (subdomain === properties.defaultSubdomain) {
    const data = { featureFlags: [], type: CONTEXT_ADMIN }

    dispatch({
      payload: { data },
      type: GET_SUBDOMAIN_SUCCESS,
    })

    if (onSuccess) {
      onSuccess(data)
    }

    return
  }

  subdomainApiClient.get(subdomain, params).then(
    (response) => {
      dispatch({
        payload: { data: { ...response.data }, meta: response.meta },
        type: GET_SUBDOMAIN_SUCCESS,
      })

      if (onSuccess) {
        const { data } = response

        onSuccess(data)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: GET_SUBDOMAIN_FAILED,
      })

      if (onFailed) {
        onFailed()
      }
    },
  )
}
