import _ from 'lodash'

import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { noop } from 'constants/global'

import { OBSERVATION_STATUSES } from 'services/legacy/observations/constants'
import { COMPLETED_STATE, FRAMEWORK_PROGRESS_LEVELS_TYPES } from 'services/legacy/frameworkProgressLevels/constants'

import { withChildObservationsService } from 'services/legacy/childObservations'
import { withSnackbarService } from 'services/utils/snackbar'
import { withChildFrameworkProgressService } from 'services/legacy/childFrameworkProgress'

import { Checkbox, Select, Space, Typography } from 'components'
import ProgressLabel from 'module/Learning/Observations/components/ProgressLabel'

import i18n from 'translations'

import { StyledItem, StyledItemAction, StyledItemDescription } from './FrameworkItemStyled'

const DELETE_STATE = 'delete'

const FrameworkItem = ({
  childFrameworkProgress,
  childFrameworkProgressActions,
  childObservation,
  childObservationsActions,
  frameworkDetails,
  groupBy,
  isFetchingChildFrameworkProgress,
  item,
  nurseryLearningSettings,
  observation,
  observationItemsActions,
  onChangeItemsDuringProcessing,
  progressAsCheckbox,
  progressLevels,
  showCompletedStatements,
  snackbarActions,
}) => {
  const [value, setValue] = useState(null)
  const [isProcessing, changeIsProcessing] = useState(false)
  const { description, id, name } = item
  const { useOnlySecureToMarkProgress } = nurseryLearningSettings
  const { frameworkEvents, id: childObservationId } = childObservation || {}
  const { structure } = frameworkDetails
  const { markOnlyCompleted } = structure

  useEffect(() => {
    const events = _.get(
      _.find(frameworkEvents, ({ framework }) => framework?.id === frameworkDetails.id),
      'events',
    )

    const valueFrameworkItem = _.find(events, ({ frameworkElement }) => (
      frameworkElement?.id === id
    ))

    if (valueFrameworkItem) {
      const { progressLevel } = valueFrameworkItem

      setValue(DELETE_STATE === progressLevel?.id ? null : progressLevel?.id)
    }

    if (valueFrameworkItem?.dummy) {
      observationItemsActions.removeManuallyObservationItem(valueFrameworkItem.id)
    }
  }, [id, frameworkEvents])

  const changeValueSuccess = (response, itemId, toRemove) => {
    const { data: [{ childObservationFrameworkEvent }] } = response || {}
    let data = {}

    if (toRemove) {
      data = {
        frameworkItem: data.frameworkItem,
        id: itemId,
        toRemove: true,
      }
    }

    changeIsProcessing(false)
    onChangeItemsDuringProcessing(false)
    childObservationsActions.manuallyUpdateObservationFrameworkProgress({
      framework: frameworkDetails.id,
      frameworkItem: id,
      linkedItems: childObservationFrameworkEvent.linkedItems,
      progressLevel: childObservationFrameworkEvent?.progressLevel,
    })

    if (OBSERVATION_STATUSES.APPROVED.value === observation.status) {
      childFrameworkProgressActions.updateSingleProgress(data)
    }

    snackbarActions.show({
      message: i18n.t('module:Learning:Observations:ObservationFrameworks:progressUpdated'),
    })
  }

  const onChange = (progressLevel) => {
    changeIsProcessing(true)
    onChangeItemsDuringProcessing(true)
    setValue(progressLevel || null)

    const body = [
      {
        childObservation: {
          id: childObservationId,
        },
        frameworkElement: {
          id,
        },

        type: groupBy || 'item',
      },
    ]

    if (!progressLevel) {
      body[0] = {
        ...body[0],
        state: DELETE_STATE,
      }
    } else {
      body[0] = {
        ...body[0],
        progressLevel: {
          id: progressLevel,
          type: FRAMEWORK_PROGRESS_LEVELS_TYPES.OBSERVATION_LINK,
        },
      }
    }

    childObservationsActions.updateObservationFrameworkProgress({
      body,
      onSuccess: changeValueSuccess,
    })
  }

  const colorState = _.get(
    _.find(progressLevels, (state) => value === state.value),
    'color',
  )
  const progress = childFrameworkProgress?.[id]

  if (!showCompletedStatements && COMPLETED_STATE === progress?.progressLevel?.state) {
    return null
  }

  const renderProgressLabel = () => {
    if (!progress || isFetchingChildFrameworkProgress || progressAsCheckbox) {
      return null
    }

    return (
      <React.Fragment>
        <Space space="10px" />
        <ProgressLabel
          value={progress?.progressLevel}
        />
      </React.Fragment>
    )
  }

  let finalStateOptions = _.map(progressLevels, (level) => ({
    label: level.name,
    value: level.id,
  }))

  const completedLevel = _.find(progressLevels, ({ state }) => state === COMPLETED_STATE)

  if (markOnlyCompleted && useOnlySecureToMarkProgress) {
    finalStateOptions = [
      {
        label: completedLevel.name,
        value: completedLevel.id,
      },
    ]
  }

  return (
    <StyledItem
      key={id}
      progressAsCheckbox={progressAsCheckbox}
      onClick={
        !isProcessing && progressAsCheckbox
          ? () => onChange(!value ? completedLevel.id : null)
          : null
      }
    >
      <StyledItemDescription>
        <Typography
          bold={colorState}
          color={colorState}
          fontSize={colorState ? 15 : 16}
          lineHeight={18}
        >
          {description || name}
        </Typography>
        {renderProgressLabel()}
      </StyledItemDescription>
      <StyledItemAction>
        {progressAsCheckbox
          ? (
            <Checkbox
              disabled={isProcessing}
              value={value}
              onChange={noop}
            />
          ) : (
            <Select
              disabled={isProcessing}
              options={finalStateOptions}
              placeholder={i18n.t('module:Learning:Observations:ObservationFrameworks:progress')}
              value={value}
              onChange={(e) => onChange(e?.value)}
            />
          )}
      </StyledItemAction>
    </StyledItem>
  )
}

const mapState = (state, {
  childFrameworkProgressSelectors,
}) => ({
  childFrameworkProgress: childFrameworkProgressSelectors.getChildFrameworkProgressListDataState(state),
})

const enhance = compose(
  withChildObservationsService,
  withChildFrameworkProgressService,
  withSnackbarService,
  connect(mapState),
)

export default enhance(FrameworkItem)
