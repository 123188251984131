import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'

import { CommentDrawer, InfiniteScroll } from 'components'

import i18n from 'translations'

import EntitlementNotesForm from './components/EntitlementNotesForm'

const EntitlementNotesView = ({
  formEnabled,
  isFetching,
  isSubmitting,
  leaveNotes,
  onAddNoteClick,
  onCancelClick,
  onPageChange,
  onSubmit,
  page,
  pageCount,
}) => {
  const renderNotesList = () => (
    <CommentDrawer.List
      isFetching={1 === page && isFetching}
      scrollableId="leaveNotesScrollableTarget"
    >
      <InfiniteScroll
        dataLength={leaveNotes?.length || 0}
        hasMore={page < pageCount}
        isFetching={isFetching}
        next={() => onPageChange((+page) + 1)}
        scrollableTarget="leaveNotesScrollableTarget"
        lightSpinner
      >
        {_.map(leaveNotes, ({ author, comment, createdAt, endDate, entitlementHours, startDate }) => (
          <CommentDrawer.List.Item
            avatars={[
              {
                author,
                label: i18n.t('module:Staff:StaffLeaveDetails:LeaveNotes:changeBy'),
              },
            ]}
            details={[
              {
                label: i18n.t('module:Staff:StaffLeaveDetails:LeaveNotes:holidayPeriod'),
                // eslint-disable-next-line max-len
                text: `${moment(startDate).format(DISPLAY_SHORT_DATE_FORMAT)} - ${moment(endDate).format(DISPLAY_SHORT_DATE_FORMAT)}`,
              },
              {
                label: i18n.t('module:Staff:StaffLeaveDetails:LeaveNotes:entitlementHours'),
                text: `${entitlementHours} ${i18n.t('global:hours')}`,
              },
              ...(comment ? [{
                label: i18n.t('module:Staff:StaffLeaveDetails:LeaveNotes:comments'),
                text: comment,
              }] : []),
            ]}
            header={{
              date: createdAt,
              text: i18n.t('module:Staff:StaffLeaveDetails:LeaveNotes:entitlementChange'),
            }}
            defaultIsOpen
          />
        ))}
      </InfiniteScroll>
    </CommentDrawer.List>
  )

  const renderFooter = () => (
    <CommentDrawer.Footer
      disabled={isFetching || isSubmitting}
      label={i18n.t('module:Staff:StaffLeaveDetails:LeaveNotes:addNote')}
      onClick={onAddNoteClick}
    />
  )

  const renderForm = () => (
    <CommentDrawer.Form>
      <EntitlementNotesForm
        initialValues={{
          holidayPeriod: [moment().utc(), moment().utc()],
        }}
        isSubmitting={isSubmitting}
        onCancelClick={onCancelClick}
        onSubmit={onSubmit}
      />
    </CommentDrawer.Form>
  )

  return (
    <CommentDrawer
      footer={renderFooter()}
      form={renderForm()}
      formEnabled={formEnabled}
      list={renderNotesList()}
      title={i18n.t('module:Staff:StaffLeaveDetails:LeaveNotes:title')}
    />
  )
}

export default EntitlementNotesView
