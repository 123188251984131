import _ from 'lodash'

import React from 'react'
import { Field, FieldArray } from 'redux-form'

import { DAYS_OPTIONS } from 'constants/sessionDays'

import { isRequired } from 'utils/fieldValidation'
import { getBrandingColor } from 'utils/branding'

import { Form, Section } from 'components'

import i18n from 'translations'

const renderOpeningDays = ({ fields, onApplyAll }) => fields.map((field, i) => {
  const fieldValues = fields.get(i)
  const { endTime, isChecked, label, startTime } = fieldValues
  const validate = isChecked && isRequired

  return (
    <Form.Row key={_.kebabCase(fieldValues.day)}>
      <Form.Row.FlexItem flex="0 0 150px">
        <Field
          component={Form.Checkbox}
          label={label}
          name={`${field}.isChecked`}
        />
      </Form.Row.FlexItem>
      <Form.Row.FlexItem flex="0">
        <Field
          component={Form.TimePicker}
          disabled={!isChecked}
          local={false}
          name={`${field}.startTime`}
          validate={validate}
        />
      </Form.Row.FlexItem>
      <Form.Row.TextItem text={i18n.t('global:To')} />
      <Form.Row.FlexItem flex="0">
        <Field
          component={Form.TimePicker}
          disabled={!isChecked}
          local={false}
          name={`${field}.endTime`}
          validate={validate}
        />
      </Form.Row.FlexItem>
      <Form.Row.TextItem
        color={getBrandingColor('primary-color')}
        cursor="pointer"
        disabled={!isChecked}
        text={i18n.t('module:Management:Nursery:applyToAll')}
        labelBold
        onClick={() => onApplyAll(startTime, endTime, fields)}
      />
    </Form.Row>
  )
})

const OpeningTimesFormSection = ({ onApplyAll }) => (
  <Section
    title={i18n.t('module:Management:Nursery:openingTimes')}
    variant="small"
  >
    <FieldArray
      component={renderOpeningDays}
      name="nurserySettings.openingDays"
      rerenderOnEveryChange
      onApplyAll={onApplyAll}
    />
    <Form.Row label={i18n.t('module:Management:Nursery:startWeekFrom')} required>
      <Form.Row.FlexItem flex="0 0 190px">
        <Field
          clearable={false}
          component={Form.Select}
          name="nurserySettings.startWeekFrom"
          options={DAYS_OPTIONS}
          placeholder={i18n.t('module:Management:Nursery:startWeekFrom')}
          searchable={false}
          validate={isRequired}
        />
      </Form.Row.FlexItem>
    </Form.Row>
  </Section>
)

export default OpeningTimesFormSection

