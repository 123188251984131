import { Property } from 'csstype'

import React, { PropsWithChildren } from 'react'

import { StyledTd } from './TableStyled'

interface TableTdProps {
  align?: Property.TextAlign
  background?: string
  borderColorLeft?: string
  borderColorRight?: string
  className?: string
  colSpan?: number
  empty?: boolean
  noBorder?: boolean
  onClick?: () => {}
  relative?: boolean
  style?: any
  verticalAlign?: Property.VerticalAlign
  whiteSpace?: Property.WhiteSpace
  width?: string
}

const TableTd: React.FC<PropsWithChildren<TableTdProps>> = ({
  align = 'center',
  background,
  borderColorLeft,
  borderColorRight,
  children,
  className,
  colSpan,
  empty,
  noBorder,
  onClick,
  relative,
  style,
  verticalAlign,
  whiteSpace,
  width,
}) => (
  <StyledTd
    $align={align}
    $background={background}
    $borderColorLeft={borderColorLeft}
    $borderColorRight={borderColorRight}
    $empty={empty}
    $noBorder={noBorder}
    $relative={relative}
    $verticalAlign={verticalAlign}
    $whiteSpace={whiteSpace}
    $width={width}
    className={className}
    colSpan={colSpan}
    style={style}
    verticalAlign={verticalAlign}
    whiteSpace={whiteSpace}
    width={width}
    onClick={onClick}
  >
    {children}
  </StyledTd>
)

export default TableTd
