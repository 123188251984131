import Dashboard from 'module/Dashboard'
import Shell from 'module/Shell'
import EnquiryPublicForm from 'module/EnquiryPublicForm'
import EnquiryPublicFormSuccess from 'module/EnquiryPublicForm/EnquiryPublicFormSuccess'

import AccountRouting from 'module/Account/AccountRouting'
import ApprovalsRouting from 'module/Approvals/ApprovalsRouting'
import AuthenticationRouting from 'module/Authentication/AuthenticationRouting'
import ChildrenRouting from 'module/Children/ChildrenRouting'
import DailyDiaryRouting from 'module/DailyDiary/DailyDiaryRouting'
import FinanceRouting from 'module/Finance/FinanceRouting'
import HelpRouting from 'module/Help/HelpRouting'
import LearningRouting from 'module/Learning/LearningRouting'
import SafeguardingRouting from 'module/Safeguarding/SafeguardingRouting'
import OccupancyRouting from 'module/Occupancy/OccupancyRouting'
import EnquiriesRouting from 'module/Enquiries/EnquiriesRouting'
import OrganizationReportsRouting from 'module/Organization/Reports/ReportsRouting'
import OrganizationChildrenRouting from 'module/Organization/Children/OrganizationChildrenRouting'
import RegisterRouting from 'module/Register/RegisterRouting'
import ReportsRouting from 'module/Reports/ReportsRouting'
import RoomsRouting from 'module/Rooms/RoomsRouting'
import MessagingRouting from 'module/Messaging/MessagingRouting'
import NotificationRouting from 'module/Notification/NotificationRouting'
import Missing from 'module/Various/Missing'
import NewslettersRouting from 'module/Newsletters/NewslettersRouting'
import NurseriesRouting from 'module/Nurseries/NurseriesRouting'
import OrganisationsRouting from 'module/Organisations/OrganisationsRouting'
import ManagementRouting from 'module/Management/ManagementRouting'
import ParentInboxRouting from 'module/ParentInbox/ParentInboxRouting'
import NurseryFilesRouting from 'module/NurseryFiles/NurseryFilesRouting'
import StaffRouting from 'module/Staff/StaffRouting'
import SettingsRouting from 'module/Settings/SettingsRouting'
import StaffRegisterRouting from 'module/StaffRegister/StaffRegisterRouting'
import VariousRouting from 'module/Various/VariousRouting'
import DiaryTimeline from '../module/DailyDiary/DiaryTimeline'

const ROUTE_NAMES = {
  DASHBOARD: 'DASHBOARD',
  ENQUIRY_PUBLIC_FORM: 'ENQUIRY_PUBLIC_FORM',
  ENQUIRY_PUBLIC_FORM_SUCCESS: 'ENQUIRY_PUBLIC_FORM_SUCCESS',
  MY_DAILY_DIARY: 'MY_DAILY_DIARY',
}

const MODULES = [
  AccountRouting,
  ApprovalsRouting,
  ChildrenRouting,
  DailyDiaryRouting,
  FinanceRouting,
  HelpRouting,
  LearningRouting,
  SafeguardingRouting,
  OccupancyRouting,
  EnquiriesRouting,
  OrganizationReportsRouting,
  OrganizationChildrenRouting,
  RegisterRouting,
  ReportsRouting,
  RoomsRouting,
  MessagingRouting,
  NotificationRouting,
  NewslettersRouting,
  NurseriesRouting,
  OrganisationsRouting,
  ManagementRouting,
  ParentInboxRouting,
  NurseryFilesRouting,
  StaffRouting,
  StaffRegisterRouting,
  SettingsRouting,
]

const Routing = {
  childRoutes: [
    {
      childRoutes: [
        ...AuthenticationRouting,
        ...VariousRouting,
        {
          childRoutes: MODULES,
          indexRoute: {
            component: Dashboard,
          },
          name: ROUTE_NAMES.DASHBOARD,
          noBreadcrumb: true,
          path: '/',
        },
        {
          component: DiaryTimeline,
          disableAuth: true,
          name: ROUTE_NAMES.MY_DAILY_DIARY,
          noBreadcrumb: true,
          path: 'my-daily-diary',
        },
        {
          childRoutes: [
            {
              component: EnquiryPublicFormSuccess,
              disableAuth: true,
              name: ROUTE_NAMES.ENQUIRY_PUBLIC_FORM_SUCCESS,
              noBreadcrumb: true,
              path: 'success/t=:token',
            },
          ],
          indexRoute: {
            component: EnquiryPublicForm,
            disableAuth: true,
            noBreadcrumb: true,
          },
          name: ROUTE_NAMES.ENQUIRY_PUBLIC_FORM,
          path: 'enquiry-form',
        },
        {
          component: Missing,
          disableAuth: true,
          noBreadcrumb: true,
          path: '*',
        },
      ],
      component: Shell,
      disableAuth: true,
      noBreadcrumb: true,
      path: '',
    },
  ],
  path: '',
} as any

Routing.ROUTE_NAMES = ROUTE_NAMES

export default Routing
