import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'recompose'

import { Form, Section, Toolbar } from 'components'

import { isRequired } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'

import { GLOBAL_SECURITY_SETTINGS_OPTIONS } from 'services/organization/constants'

import i18n from 'translations'

const GLOBAL_SECURITY_SETTINGS_EDIT_FORM = 'GlobalSecuritySettingsEditForm'

interface GlobalEditFormProps {
  handleSubmit: (e: any) => () => void
  isLoading?: boolean
  onSubmit: (fields: any) => void
}

const GlobalEditForm: React.FC<GlobalEditFormProps> = ({
  handleSubmit,
  isLoading,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Section title={i18n.t('module:Settings:Common:globalSettingsEditLabel')}>
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            {i18n.t('module:Settings:Security:GlobalSettings:label')}
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      <Form.Row>
        <Form.Row.FlexItem>
          <Field
            component={Form.RadioGroup}
            name="globalSecuritySettingsTypes"
            options={GLOBAL_SECURITY_SETTINGS_OPTIONS}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
    </Section>
    <Form.FooterActions
      cancelLink={generateRoute('SETTINGS.SECURITY.INDEX')}
      isSubmitting={isLoading}
      submitLabel={i18n.t('global:Save')}
    />
  </Form>
)

const enhance = compose<{}, GlobalEditFormProps>(reduxForm({ form: GLOBAL_SECURITY_SETTINGS_EDIT_FORM }))

export default enhance(GlobalEditForm)
