import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as foodMenuSelectors from './selectors'

const withFoodMenuService = (WrappedComponent) => {
  const mapState = ({ foodMenu }) => ({ foodMenu })

  const mapDispatch = injectActions('foodMenuActions', actions)

  const Component = ({ foodMenu, foodMenuActions, ...others }) => (
    <WrappedComponent
      foodMenuActions={foodMenuActions}
      foodMenuListState={foodMenu.list}
      foodMenuSelectors={foodMenuSelectors}
      foodMenuSingleState={foodMenu.single}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withFoodMenuService
