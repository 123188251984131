import {
  ADD_TOILET_TRAINING_TO_LIST,
  CLEAR_DAILY_DIARY_TOILET_TRAINING,
  GET_DAILY_DIARY_TOILET_TRAINING,
  GET_DAILY_DIARY_TOILET_TRAINING_FAILED,
  GET_DAILY_DIARY_TOILET_TRAINING_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ dailyDiaryToiletTrainingApiClient }) => (dispatch) => {
  const { mergeResult, params } = options

  dispatch({
    type: GET_DAILY_DIARY_TOILET_TRAINING,
  })

  dailyDiaryToiletTrainingApiClient.list(params).then(
    (response) => {
      dispatch({
        mergeResult,
        payload: response,
        type: GET_DAILY_DIARY_TOILET_TRAINING_SUCCESS,
      })
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: GET_DAILY_DIARY_TOILET_TRAINING_FAILED,
      })
    },
  )
}

export const addToList = (payload) => ({
  payload,
  type: ADD_TOILET_TRAINING_TO_LIST,
})

export const clearList = () => ({
  type: CLEAR_DAILY_DIARY_TOILET_TRAINING,
})
