import { createSelector } from 'reselect'

import * as constants from '../constants'
import { NurserySessionsRootState } from '../models'

const getNurserySessions = (state: NurserySessionsRootState) => state.nurserySessions

export const getNurserySessionsList = createSelector(
  [getNurserySessions],
  (state) => {
    if (!state.list.data?.length) {
      return null
    }

    return state.list
  },
)

export const getNurserySessionsListData = createSelector(
  [getNurserySessionsList],
  (state) => state?.data,
)

export const geNurserySessionsOptionsDropdownData = createSelector(
  [getNurserySessionsList],
  (state) => {
    if (!state.data?.length) {
      return null
    }

    return state.data.map(({ archived, id, name }) => ({
      label: `${name}${archived ? ' (archived)' : ''}`,
      value: id,
    }))
  },
)

export const getCriteria = (filters) => {
  const { search, statusFilter } = filters || {}

  const criteria = []

  if (statusFilter && statusFilter !== constants.NURSERY_SESSIONS_FILTER.ALL) {
    criteria.push({
      field: 'archived',
      value: statusFilter === constants.NURSERY_SESSIONS_FILTER.ARCHIVED ? 1 : 0,
    })
  }

  if (search) {
    criteria.push({
      field: 'name',
      value: search,
    })
  }

  return criteria
}
