import _ from 'lodash'

import React, { memo } from 'react'
import isEqual from 'react-fast-compare'

import { TimeString, Typography } from 'components'

import { StyledTimesWrapper, StyledWrapper } from './TooltipStatisticStyled'

const TooltipStatistic = ({ times, title }) => (times?.length ? (
  <StyledWrapper>
    {title && <Typography fontSize={14} variant="span" bold>{title}</Typography>}
    <StyledTimesWrapper>
      {_.map(
        times,
        ({ endTime, startTime }, key) => (
          <Typography fontSize={14} key={key}>
            <TimeString.Range end={endTime} local={false} start={startTime} />
          </Typography>
        ),
      )}
    </StyledTimesWrapper>
  </StyledWrapper>
) : null)

export default memo(TooltipStatistic, isEqual)
