import React, { PropsWithChildren, useEffect } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withShellService } from 'services/shell'

import { LayoutContainer } from 'components'

import { StyledFooterContainer, StyledStickyFooter } from './StickyFooterStyled'

interface StickyFooterProps {
  expanded?: boolean
  heightStickyFooter: number
  shellActions: any
}

const StickyFooter: React.FC<PropsWithChildren<StickyFooterProps>> = ({
  children,
  expanded,
  heightStickyFooter,
  shellActions,
}) => {
  useEffect(() => {
    shellActions.stickyFooterMounted()

    return () => {
      shellActions.stickyFooterUnmounted()
    }
  }, [])

  return (
    <StyledFooterContainer $elementHeight={heightStickyFooter}>
      <StyledStickyFooter data-name="StickyFooter">
        {expanded
          ? children
          : (
            <LayoutContainer>
              {children}
            </LayoutContainer>
          )}
      </StyledStickyFooter>
    </StyledFooterContainer>
  )
}

const mapState = (state, { shellSelectors }) => ({
  heightStickyFooter: shellSelectors.getStickyFooterHeight(state),
})

const enhance = compose(
  withShellService,
  connect(mapState),
)

export default enhance(StickyFooter)
