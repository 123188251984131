import _ from 'lodash'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { PAYOUT_TRANSACTION_TYPE_LABEL, PAYOUT_TYPES } from 'services/nurseryIntegrations/constants'

import { generateRoute } from 'utils/routing'

import { Avatar, Currency, DateString, Hyperlink, Typography } from 'components'

export const getTableData = ({ data, hasError }) => {
  if (!data?.length || hasError) {
    return null
  }

  return _.map(data, ({
    amount,
    child,
    fee = 0,
    id,
    net = 0,
    reference,
    type,
  }) => {
    const { firstName, photo, surname } = child || {}
    const { id: paymentId, number, paymentDate } = reference || {}

    if (type === PAYOUT_TYPES.PAYOUT) {
      return {
        child: '-',
        fee: '-',
        gross: '-',
        id,
        paymentDate: '-',
        reference: '-',
        total: (
          <Typography color={NEUTRAL_COLOURS.GRAY}>
            <Currency value={net} />
          </Typography>
        ),
        type: PAYOUT_TRANSACTION_TYPE_LABEL[type],
      }
    }

    return {
      child: child ? (
        <Avatar
          avatarSize="small"
          initials={[firstName, surname]}
          src={photo}
          title={`${firstName} ${surname}`}
        />
      ) : null,
      fee: <Typography color={NEUTRAL_COLOURS.GRAY}><Currency value={fee} /></Typography>,
      gross: <Currency value={amount} />,
      id,
      paymentDate: <DateString date={paymentDate} />,
      reference: (
        <Hyperlink to={generateRoute('FINANCE.PAYMENTS.DETAILS', { paymentId })} primary>
          {number}
        </Hyperlink>),
      total: (
        <Typography color={NEUTRAL_COLOURS.GRAY}>
          <Currency value={net} />
        </Typography>
      ),
      type: PAYOUT_TRANSACTION_TYPE_LABEL[type],
    }
  })
}
