import { createSelector } from 'reselect'

import { FEATURE_FLAGS } from 'constants/security'

import auth from 'utils/auth'

import appSelectors from 'services/app/selectors'

const getFinanceDetails = (state) => state.child.financeDetails

export const getFinanceDetailsSelector = createSelector(
  [getFinanceDetails],
  (state) => state?.data,
)

export const getCannotBePaidOverTheParentApp = createSelector(
  [getFinanceDetailsSelector, (state) => state],
  (childFinanceDetails, state) => {
    const { balance } = childFinanceDetails || {}

    const totalNurseryPaymentTypes = appSelectors.getTotalResults(state.nurseryPaymentTypes.list)
    const mobilePaymentsIsEnabled = auth.SELECTORS.getIsAuthorised(state, {
      flags: [FEATURE_FLAGS.MOBILE_PAYMENTS],
    })

    return (
      totalNurseryPaymentTypes
      && mobilePaymentsIsEnabled
      && -0.01 > balance && -0.5 < balance
    )
  },
)
