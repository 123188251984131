import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withNurseryPaymentTypesService } from 'services/legacy/nurseryPaymentTypes'
import { withRouter } from 'services/router'

import i18n from 'translations'

import ManagementPaymentTypesAddView from './ManagementPaymentTypesAddView'

class ManagementPaymentTypesAddContainer extends Component {
  componentDidMount() {
    const { isEdit, nurseryPaymentTypesActions, params } = this.props
    const { itemId } = params

    if (isEdit) {
      nurseryPaymentTypesActions.get({ params: [itemId] })
    }
  }

  componentWillUnmount() {
    const { nurseryPaymentTypesActions } = this.props

    nurseryPaymentTypesActions.clear()
  }

  handleSubmitSuccess = () => {
    const { navigate } = this.props

    navigate(generateRoute('MANAGEMENT.FINANCE_SETTINGS.PAYMENT_TYPES'))
  }

  handleSubmit = (fields) => {
    const { isEdit, nurseryPaymentTypesActions, params } = this.props
    const { itemId } = params

    if (isEdit) {
      return nurseryPaymentTypesActions.update({ body: fields, onSuccess: this.handleSubmitSuccess, params: [itemId] })
    }

    return nurseryPaymentTypesActions.create({ body: fields, onSuccess: this.handleSubmitSuccess, params: [] })
  }

  archivePaymentType = (archived) => () => {
    const { nurseryPaymentTypesActions, params } = this.props
    const { itemId } = params

    return nurseryPaymentTypesActions.update({
      body: { archived },
      onSuccess: this.handleSubmitSuccess,
      params: [itemId],
    })
  }

  handleArchiveClick = (archived) => () => {
    const { modalActions, modalConsts } = this.props

    const label = archived ? i18n.t('global:archive') : i18n.t('global:unarchive')
    const text = archived
      ? i18n.t('module:Management:ManagementPaymentTypes:archiveModalTitle')
      : i18n.t('module:Management:ManagementPaymentTypes:unarchiveModalTitle')

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: label,
      icon: label,
      onConfirm: this.archivePaymentType(archived),
      text,
    })
  }

  render() {
    const {
      errorMessages,
      initialValues,
      isArchived,
      isEdit,
      isFetching,
      isFormLoading,
    } = this.props

    return (
      <ManagementPaymentTypesAddView
        errorMessages={errorMessages}
        initialValues={initialValues}
        isArchived={isArchived}
        isEdit={isEdit}
        isFormLoading={isFormLoading}
        isLoading={isFetching}
        onArchiveClick={this.handleArchiveClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseryPaymentTypesSelectors,
  nurseryPaymentTypesSingleState,
  params,
}) => ({
  errorMessages: appSelectors.getErrorMessages(nurseryPaymentTypesSingleState),
  initialValues: nurseryPaymentTypesSelectors.getInitialValues(state),
  isArchived: nurseryPaymentTypesSelectors.isArchived(state),
  isEdit: !!params.itemId,
  isFetching: appSelectors.getIsFetching(nurseryPaymentTypesSingleState),
  isFormLoading: nurseryPaymentTypesSelectors.getIsSubmitting(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withNurseryPaymentTypesService,
  connect(mapState),
)

export default enhance(ManagementPaymentTypesAddContainer)
