import { BasicSingleInitialState, getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import { Subdomain } from '../models'
import { GET_SUBDOMAIN, GET_SUBDOMAIN_FAILED, GET_SUBDOMAIN_SUCCESS } from './constants'

const initialState = getBasicSingleInitialState

export interface SubdomainSingleState extends BasicSingleInitialState {
  data: Subdomain
}

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    itemFailed: GET_SUBDOMAIN_FAILED,
    itemInit: GET_SUBDOMAIN,
    itemSuccess: GET_SUBDOMAIN_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
