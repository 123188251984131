import _ from 'lodash'

export const getSelectAll = (selected, filteredChildrenList) => {
  if (filteredChildrenList && 0 === filteredChildrenList.length) {
    return false
  }

  const filteredChildrenListIds = _.map(filteredChildrenList, (item) => item.id)

  return _.every(filteredChildrenListIds, (item) => _.includes(selected, item))
}

export const getRoomFilterOptions = (childrenList) => {
  const mapChildrenList = (child) => ({
    archived: child.nurseryClass ? child.nurseryClass.archived : false,
    label: (child.nurseryClass ? child.nurseryClass.name : undefined),
    value: (child.nurseryClass ? child.nurseryClass.id : undefined),
  })

  const allOptions = _.map(childrenList, mapChildrenList)
  const nonEmptyOptions = _.filter(allOptions, (item) => item.value)
  const activeRoomsOptions = _.filter(nonEmptyOptions, ({ archived }) => !archived)

  return _.uniqBy(activeRoomsOptions, 'value')
}

export const getFilteredChildrenList = (childrenList, search, roomFilter) => {
  let items = childrenList

  if (search) {
    items = _.filter(items, (item) => {
      const text = item.fullName ? item.fullName.toLowerCase() : `${item.firstName} ${item.surname}`.toLowerCase()
      const query = search.toLowerCase()

      return text.includes(query)
    })
  }

  if (roomFilter) {
    items = _.filter(items, (item) => (
      (item.class && (item.class.id === roomFilter.value))
      || (item.nurseryClass && (item.nurseryClass.id === roomFilter.value))
    ))
  }

  return items
}

export const getSelectedChildren = (childrenList, selected, originalSelected) => {
  const finalChildrenList = [...childrenList, ...originalSelected]

  const updatedArrayGroupBy = _.groupBy(finalChildrenList, 'id')
  const updatedArrayMapped = _.map(
    updatedArrayGroupBy,
    (objs) => _.assignWith({}, ...objs, (val1, val2) => val1 || val2),
  )

  const selectedChildren = _.reduce(selected, (result, item) => {
    const child = _.find(updatedArrayMapped, ({ id }) => id === item)

    return [...result, child]
  }, [])

  return selectedChildren
}
