import moment from 'moment'

import React, { PropsWithChildren } from 'react'

import i18n from 'translations'

import {
  StyledByLabelContainer,
  StyledContainer,
  StyledDateContainer,
  StyledSaveLabelContainer,
  StyledSpinnerContainer,
} from './LastSaveDateTimeStyled'

interface LastSaveDateTimeProps {
  authorName?: string
  isLoading?: boolean
  lastSaveDateTime: moment.Moment | Date | number
}

const LastSaveDateTime: React.FC<PropsWithChildren<LastSaveDateTimeProps>> = ({
  authorName,
  isLoading,
  lastSaveDateTime,
}) => {
  if (isLoading) {
    return (
      <StyledContainer>
        <StyledSpinnerContainer>
          {i18n.t('components:LastSaveDateTime:saving')}
        </StyledSpinnerContainer>
      </StyledContainer>
    )
  }

  if (!lastSaveDateTime) {
    return null
  }

  const renderLastSavedContent = () => {
    const dateTimeString = moment(lastSaveDateTime).calendar({
      sameElse: () => 'DD/MM/YYYY [at] h:mm A',
    })

    if (authorName) {
      return (
        <StyledDateContainer>
          {dateTimeString}
          <StyledByLabelContainer>
            {i18n.t('components:LastSaveDateTime:by')}
          </StyledByLabelContainer>
          {authorName}
        </StyledDateContainer>
      )
    }

    return (
      <StyledDateContainer>
        {dateTimeString}
      </StyledDateContainer>
    )
  }

  return (
    <StyledContainer>
      <StyledSaveLabelContainer>
        {i18n.t('components:LastSaveDateTime:lastSaved')}
        {': '}
      </StyledSaveLabelContainer>
      {renderLastSavedContent()}
    </StyledContainer>
  )
}

export default LastSaveDateTime
