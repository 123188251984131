import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'

export interface withLikesServiceProps {
  likesActions: typeof actions
}

const withLikesService = <P extends withLikesServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapDispatch = injectActions('likesActions', actions)

  const Component = ({ likesActions, ...others }) => (
    <WrappedComponent
      likesActions={likesActions}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(null, mapDispatch)(Component)
}

export default withLikesService
