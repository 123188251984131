import _ from 'lodash'

import React, { PropsWithChildren } from 'react'

import { RECURRING_PAYMENT_METHOD_OPTIONS, STATUS_OPTIONS } from 'services/nurseryIntegrations/constants'
import { PaginationProps } from 'components/Pagination/Pagination'

import {
  Callout,
  EmptyState,
  Form,
  InfiniteDropdowns,
  Page,
  Pagination,
  Section,
  Select,
  Spinner,
  Table,
  Toolbar,
} from 'components'

import i18n from 'translations'

import { NURSERY_TABLE_COLUMNS, ORGANIZATION_TABLE_COLUMNS } from './constants'
import { OptionProps } from './ActiveRecurringPaymentContainer'

interface ActiveRecurringPaymentViewProps {
  errorMessages?: string[]
  isLoading?: boolean
  isOrganizationContext?: boolean
  nursery?: OptionProps
  onExportClick?: () => void
  onNurseryChange?: (nursery: OptionProps) => void
  onPaymentMethodChange?: (room: OptionProps) => void
  onRoomChange?: (room: OptionProps) => void
  onStatusChange: (room: OptionProps) => void
  paymentMethod?: OptionProps
  room?: OptionProps
  status: string
  tableData?: any
  title?: string
  totalResults?: number
}

type ActiveRecurringPaymentViewFullProps = ActiveRecurringPaymentViewProps
  & PaginationProps

const ActiveRecurringPaymentView: React.FC<PropsWithChildren<ActiveRecurringPaymentViewFullProps>> = ({
  errorMessages,
  isLoading,
  isOrganizationContext,
  nursery,
  onExportClick,
  onNurseryChange,
  onPageChange,
  onPaymentMethodChange,
  onRoomChange,
  onStatusChange,
  page,
  pageCount,
  paymentMethod,
  perPage,
  room,
  status,
  tableData,
  title,
  totalResults,
}) => {
  const renderPagination = () => (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (errorMessages) {
      return null
    }

    if (!isLoading && !tableData?.length) {
      return (
        <EmptyState
          icon="reports"
          text1={i18n.t('global:EmptyState:long')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={isOrganizationContext ? ORGANIZATION_TABLE_COLUMNS : NURSERY_TABLE_COLUMNS}
          data={tableData}
          minWidth={1450}
          visualType="transparent"
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const actions = <Section.Actions options={[{ onClick: onExportClick, type: 'export' }]} />

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title={title}
    >
      <Callout content={errorMessages} error />
      <Toolbar bottomLine>
        <Toolbar.Group>
          <Toolbar.Item>
            <Form.Row
              label={i18n.t('global:Room')}
              verticalLabel
            >
              <InfiniteDropdowns.Rooms
                placeholder={i18n.t('global:AllRooms')}
                value={room}
                onChange={onRoomChange}
              />
            </Form.Row>
          </Toolbar.Item>
          <Toolbar.Item>
            <Form.Row
              label={_.upperFirst(i18n.t('global:status'))}
              verticalLabel
            >
              <Select
                options={STATUS_OPTIONS}
                value={status}
                onChange={onStatusChange}
              />
            </Form.Row>
          </Toolbar.Item>
          <Toolbar.Item>
            <Form.Row
              label={i18n.t('module:Finance:Reports:ActiveRecurringPayment:Filters:PaymentMethod:label')}
              verticalLabel
            >
              <Select
                options={RECURRING_PAYMENT_METHOD_OPTIONS}
                value={paymentMethod}
                onChange={onPaymentMethodChange}
              />
            </Form.Row>
          </Toolbar.Item>
          {isOrganizationContext && (
            <Toolbar.Item>
              <Form.Row
                label={i18n.t('module:Finance:Reports:ActiveRecurringPayment:Filters:Site:label')}
                verticalLabel
              >
                <InfiniteDropdowns.Nurseries
                  value={nursery}
                  onChange={onNurseryChange}
                />
              </Form.Row>
            </Toolbar.Item>
          )}
        </Toolbar.Group>
      </Toolbar>
      {renderContent()}
    </Page.Section>
  )
}

export default ActiveRecurringPaymentView
