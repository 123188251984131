import { compose } from 'recompose'

import { withRouter } from 'services/router'

const NotificationWrapperContainer = ({ children }) => children

NotificationWrapperContainer.authParams = {
  nurseryContext: true,
}

const enhance = compose(
  withRouter,
)

export default enhance(NotificationWrapperContainer)
