import _ from 'lodash'

import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { RootState } from 'core/reducers'
import { SubMenuItem } from 'services/shell/constants'

import { ModalType } from 'modals'

import { withShellService, withShellServiceProps } from 'services/shell'
import { withModalService, withModalServiceProps } from 'services/utils/modal'

import { Drawer, MenuItem } from 'components'

import { StyledContent, StyledHeader, StyledMenuContent, StyledWrapper } from './SubMenuWrapperStyled'

interface SubMenuWrapperProps {
  header?: () => React.ReactNode
  isHeaderPinned: boolean
  subMenu: SubMenuItem[]
}

type SubMenuWrapperFullProps = SubMenuWrapperProps
  & withShellServiceProps
  & withModalServiceProps

const SubMenuWrapper = ({
  children,
  header,
  isHeaderPinned,
  modalActions,
  subMenu,
}) => {
  const handleShowPremiumModal = () => modalActions.show(ModalType.PREMIUM_PLAN)

  const renderItem = ({
    badgeValue,
    hidden,
    icon,
    isNew,
    label,
    premium,
    to,
  }: SubMenuItem, index, sidebarIsOpen, onCloseSidebar) => (
    <MenuItem
      badgeValue={badgeValue}
      hidden={hidden}
      hiddenTitle={!sidebarIsOpen}
      icon={icon}
      isNew={isNew}
      key={index}
      label={label}
      premium={premium}
      to={to}
      inline
      rightActive
      onChangeSidebar={onCloseSidebar}
      onShowPremiumModal={handleShowPremiumModal}
    />
  )

  const renderContent = (sidebarIsOpen, onCloseSidebar) => (
    <StyledMenuContent>
      {_.map(subMenu, (item, index) => renderItem(item, index, sidebarIsOpen, onCloseSidebar))}
    </StyledMenuContent>
  )

  return (
    <StyledWrapper>
      <StyledContent $subMenu={!!subMenu?.length}>
        <Drawer
          content={renderContent}
          header={() => header && (
            <StyledHeader $subMenu={!!subMenu?.length}>
              {header()}
            </StyledHeader>
          )}
          hidden={!subMenu}
          isHeaderPinned={isHeaderPinned}
          position="left"
        />
        {children}
      </StyledContent>
    </StyledWrapper>
  )
}

const mapState = (state: RootState, { shellSelectors }: SubMenuWrapperFullProps) => ({
  subMenu: shellSelectors.getSubMenu(state),
})

const enhance = compose(
  withShellService,
  withModalService,
  connect(mapState),
)

export default enhance(SubMenuWrapper)
