import React from 'react'

import { Field, Section } from 'components'

const InvoiceNumbersSettingView = ({
  creditNoteNumberDescription,
  editButtonLink,
  invoiceNumberDescription,
  receiptNumberDescription,
  subtitle,
  title,
}) => {
  const actions = editButtonLink && <Section.Actions tertiary={{ to: editButtonLink }} />

  return (
    <Section
      actions={actions}
      title={title}
    >
      {subtitle && (
        <p>
          {subtitle}
        </p>
      )}
      <Field.InlineEdit description={invoiceNumberDescription} label="Next invoice numbers from" />
      <Field.InlineEdit description={receiptNumberDescription} label="Next receipt numbers from" />
      <Field.InlineEdit description={creditNoteNumberDescription} label="Next credit note numbers from" />
    </Section>
  )
}

export default InvoiceNumbersSettingView
