import styled from 'styled-components'

export const StyledCell = styled.div`
  & > *:nth-child(2) {
    margin-top: 10px;
  }
`

export const StyledIcon = styled.div`
  height: 14px;
  display: flex;
  align-items: center;
  margin: 0 10px;
`
