import _ from 'lodash'

import { BasicSingleInitialState } from 'utils/reducer'

import { UPDATE_STRIPE_CAPABILITY_SUCCESS } from 'services/nurseryIntegrations/stripe/constants'
import { UPDATE_INTEGRATION_SUCCESS } from 'services/nurseryIntegrations/single/constants'

import {
  CLEAR_NURSERY,
  CREATE_NURSERY,
  CREATE_NURSERY_FAILED,
  CREATE_NURSERY_SUCCESS,
  GET_NURSERY,
  GET_NURSERY_FAILED,
  GET_NURSERY_FLAGS,
  GET_NURSERY_FLAGS_FAILED,
  GET_NURSERY_FLAGS_SUCCESS,
  GET_NURSERY_SUCCESS,
  UPDATE_NURSERY,
  UPDATE_NURSERY_FAILED,
  UPDATE_NURSERY_FLAG,
  UPDATE_NURSERY_FLAG_FAILED,
  UPDATE_NURSERY_FLAG_SUCCESS,
  UPDATE_NURSERY_SUCCESS,
} from './constants'

export interface NurseriesSingleState extends BasicSingleInitialState {
  flags?: any
  isFlagSubmitting?: boolean
}

const initialState: NurseriesSingleState = {
  data: null,
  error: null,
  flags: null,
  isFetching: false,
  isFlagSubmitting: false,
  isSubmitting: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case GET_NURSERY:
    case GET_NURSERY_FLAGS:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_NURSERY_SUCCESS:
      return { ...state, data: payload.data, isFetching: false }
    case GET_NURSERY_FLAGS_SUCCESS:
      return { ...state, flags: payload.data, isFetching: false }
    case GET_NURSERY_FAILED:
    case GET_NURSERY_FLAGS_FAILED:
      return { ...state, error, isFetching: false }
    case CREATE_NURSERY:
    case UPDATE_NURSERY:
      return { ...state, error: initialState.error, isSubmitting: true }
    case CREATE_NURSERY_SUCCESS:
    case UPDATE_NURSERY_SUCCESS:
      return { ...state, data: payload.data, isSubmitting: false }
    case CREATE_NURSERY_FAILED:
    case UPDATE_NURSERY_FAILED:
      return { ...state, error, isSubmitting: false }
    case UPDATE_NURSERY_FLAG:
      return { ...state, error: initialState.error, isFlagSubmitting: true }
    case UPDATE_NURSERY_FLAG_SUCCESS:
      return { ...state, isFlagSubmitting: false }
    case UPDATE_NURSERY_FLAG_FAILED:
      return { ...state, error, isFlagSubmitting: false }
    case UPDATE_INTEGRATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          stripeNurseryIntegration: {
            ...state.data.stripeNurseryIntegration,
            bacsChargeDay: payload.data.bacsChargeDay,
            bacsChargeType: payload.data.bacsChargeType,
            cardChargeDay: payload.data.cardChargeDay,
            cardChargeType: payload.data.cardChargeType,
            sepaChargeDay: payload.data.sepaChargeDay,
          },
        },
      }
    case UPDATE_STRIPE_CAPABILITY_SUCCESS: {
      const result = {
        ...state.data,
        stripeNurseryIntegration: {
          ...state.data.stripeNurseryIntegration,
          capabilities: _.map(state.data.stripeNurseryIntegration.capabilities, (item) => {
            if (payload.data.id === item.id) {
              return payload.data
            }

            return item
          }),
        },
      }

      return {
        ...state,
        data: result,
      }
    }
    case CLEAR_NURSERY:
      return initialState
    default:
      return state
  }
}
