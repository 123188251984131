import React, { useEffect } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'

import { withAppService, withAppServiceProps } from 'services/app'
import { withNurseriesService, withNurseriesServiceProps } from 'services/nurseries'
import { withPaginationUtils, withPaginationUtilsProps } from 'services/utils/pagination'

import SessionsListView from './SessionsListView'

type SessionsListContainerProps = withAppServiceProps
  & withNurseriesServiceProps
  & withPaginationUtilsProps

const mapState = (state, {
  appSelectors,
  nurseriesListState,
  nurseriesSelectors,
}) => ({
  isFetching: appSelectors.getIsFetching(nurseriesListState),
  records: nurseriesSelectors.getOrganizationNurseriesDataSelector(state),
  totalResults: appSelectors.getTotalResults(nurseriesListState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const SessionsListContainer: React.FC<SessionsListContainerProps & PropsFromRedux> = ({
  isFetching,
  nurseriesActions,
  paginationUtils,
  records,
  totalResults,
}) => {
  const { getPaginationDetails, onPageChange, page } = paginationUtils

  const fetch = () => {
    nurseriesActions.list({
      params: { page },
    })
  }

  useEffect(() => () => {
    nurseriesActions.clear()
  }, [])

  useEffect(fetch, [page])

  const handlePageChange = (newPage) => {
    onPageChange()(newPage)
  }

  return (
    <SessionsListView
      isFetching={isFetching}
      pagination={getPaginationDetails(totalResults)}
      records={records}
      onPageChange={handlePageChange}
    />
  )
}

const enhance = compose(
  withAppService,
  withNurseriesService,
  withPaginationUtils,
  connect(mapState),
)

export default enhance(SessionsListContainer)
