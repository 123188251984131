import React, { PropsWithChildren, forwardRef } from 'react'

import DataTable from './index'
import { StyledContent, StyledContentContainer } from './DataTableStyled'

interface DataTableContentProps {
  groupHeader?: React.ReactNode
  hideContent?: boolean
  width?: number
}

const DataTableContent: React.FC<PropsWithChildren<DataTableContentProps>> = forwardRef(({
  children,
  groupHeader,
  hideContent,
  width,
  ...rest
}, ref) => (
  <StyledContentContainer $width={width} {...rest} ref={ref}>
    {groupHeader && (
      <DataTable.GroupHeader>
        {groupHeader}
      </DataTable.GroupHeader>
    )}
    <StyledContent $hideContent={hideContent}>
      {children}
    </StyledContent>
  </StyledContentContainer>
))

export default DataTableContent
