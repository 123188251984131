import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_NURSERY_FUNDING_LIST_V3,
  GET_NURSERY_FUNDING_LIST_V3,
  GET_NURSERY_FUNDING_LIST_V3_FAILED,
  GET_NURSERY_FUNDING_LIST_V3_SUCCESS,
} from './constants'

export const list = (
  options: BasicActionOptionsProps,
) => ({ nurseryFundingV3ApiClient }) => generateBasicActions.list({
  constants: {
    failed: GET_NURSERY_FUNDING_LIST_V3_FAILED,
    init: GET_NURSERY_FUNDING_LIST_V3,
    success: GET_NURSERY_FUNDING_LIST_V3_SUCCESS,
  },
  options,
  service: nurseryFundingV3ApiClient,
  serviceMethod: 'list',
})

export const clearList = () => ({
  type: CLEAR_NURSERY_FUNDING_LIST_V3,
})
