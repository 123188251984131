import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as deviceTokensSelectors from './selectors'

const withDeviceTokensService = (WrappedComponent) => {
  const mapState = ({ deviceTokens }) => ({ deviceTokens })

  const mapDispatch = injectActions('deviceTokensActions', actions)

  const Component = ({ deviceTokens, deviceTokensActions, ...others }) => (
    <WrappedComponent
      deviceTokensActions={deviceTokensActions}
      deviceTokensListState={deviceTokens.list}
      deviceTokensSelectors={deviceTokensSelectors}
      deviceTokensSingleState={deviceTokens.single}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withDeviceTokensService
