import React from 'react'

import { Banner, Callout, Page, Section, Spinner, Typography } from 'components'

import i18n from 'translations'

import ChildAbsenceReasonsAddForm from './components/ChildAbsenceReasonsAddForm'

const ChildAbsenceReasonsAddView = ({
  data,
  errorMessages,
  initialValues,
  isEdit,
  isFetching,
  isSubmitting,
  onArchiveClick,
  onSubmit,
}) => {
  const { archived } = data || {}

  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    return (
      <ChildAbsenceReasonsAddForm
        initialValues={initialValues}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      />
    )
  }

  const actions = isEdit && !isFetching && (
    <Section.Actions
      options={[{
        onClick: onArchiveClick(!archived),
        type: archived ? 'unarchive' : 'archive',
      }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isFetching}
      title={isEdit
        ? i18n.t('module:Management:LeaveTypes:Child:Edit:title')
        : i18n.t('module:Management:LeaveTypes:Child:Add:title')}
    >
      <Callout content={errorMessages} error />
      {!isEdit && (
        <Typography margin="20px 0">
          {i18n.t('module:Management:LeaveTypes:Child:Add:subtitle')}
        </Typography>
      )}
      {isEdit && (
        <Banner.Info>
          {i18n.t('module:Management:LeaveTypes:Child:Edit:banner')}
        </Banner.Info>
      )}
      {renderContent()}
    </Page.Section>
  )
}

export default ChildAbsenceReasonsAddView
