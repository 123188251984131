import { combineReducers } from 'redux'

import list from './list/reducer'
import single from './single/reducer'
import batch from './batch/reducer'

export default combineReducers({
  batch,
  list,
  single,
})
