import { createSelector } from 'reselect'

export const getListSelector = (state) => state.requestedExtraSessions.list

export const getListCriteria = (filters, isStatisticsCriteria, isFinanceV3Enabled) => {
  const { endDate, session, startDate, status } = filters

  const criteria = []

  if (!isStatisticsCriteria) {
    criteria.push({
      field: 'type',
      value: 'parent',
    })
  }

  if (status) {
    criteria.push({
      field: 'status',
      value: status,
    })
  }

  if (endDate) {
    criteria.push({
      field: 'date[before]',
      value: endDate,
    })
  }

  if (startDate) {
    criteria.push({
      field: 'date[after]',
      value: startDate,
    })
  }

  if (session) {
    criteria.push({
      field: isFinanceV3Enabled ? 'nurserySessionProduct' : 'nurserySession',
      value: session,
    })
  }

  return criteria
}

export const getRequestedExtraSessionsList = createSelector(
  [getListSelector],
  (state) => state.data,
)
