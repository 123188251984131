import moment from 'moment'
import _ from 'lodash'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { change, getFormValues } from 'redux-form'

import { FRAMEWORK_STRUCTURE_APPLICABLE, FRAMEWORK_TYPE_MONTESSORI } from 'services/legacy/frameworks/constants'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withChildObservationsService } from 'services/legacy/childObservations'
import { withFrameworksService } from 'services/legacy/frameworks'
import { withNextStepsService } from 'services/legacy/nextSteps'
import { withMontessoriCategoriesService } from 'services/legacy/montessoriCategories'
import { withNextMontessoriActivitiesService } from 'services/legacy/nextMontessoriActivities'
import { withSecurityService } from 'services/security'
import { withSnackbarService } from 'services/utils/snackbar'
import { withModalService } from 'services/utils/modal'
import { withObservationsService } from 'services/legacy/observations'
import { withRouter } from 'services/router'

import i18n from 'translations'

import ObservationNextStepsView from './ObservationNextStepsView'
import { OBSERVATION_NEXT_STEPS_FORM } from './components/ObservationNextStepsForm/ObservationNextStepsForm'
import { OBSERVATIONS_PAGE_TYPE } from '../constants'
import { updateFrameworkData } from './ObservationNextStepsHelper'

const OBSERVATION_GROUPS = {
  read: [
    'observation.montessoriActivity',
    'montessoriActivity',
    'montessoriActivity.suggestedNextActivity',
  ],
}

const CHILD_OBSERVATION_GROUPS = {
  read: [
    'child',
    'childObservation',
    'childObservation.child',
    'childObservation.nextMontessoriActivities',
    'childObservation.nextSteps',
    'framework',
    'frameworkArea',
    'frameworkArea.framework',
    'montessoriActivity',
    'montessoriActivity.suggestedNextActivity',
    'nextMontessoriActivities',
    'nextMontessoriActivity.montessoriActivity',
    'nextStep',
    'nextStep.framework',
    'nextStep.frameworkAreas',
    'observation.childObservations',
    'observation.montessoriActivity',
  ],
}

const MONTESSORI_CATEGORIES_GROUPS = {
  read: [
    'montessoriCategory',
    'montessoriCategory.subcategories',
    'montessoriSubcategory.activities',
    'montessoriSubcategory',
    'montessoriActivity',
  ],
}

const FRAMEWORK_GROUPS = {
  read: [
    'framework.areas',
    'frameworkArea',
    'structure',
  ],
}

const MONTESSORI_ACTIVITIES_GROUPS = {
  read: [
    'montessoriActivity',
    'nextMontessoriActivity.montessoriActivity',
  ],
}

class ObservationNextStepsContainer extends Component {
  constructor(props) {
    super(props)

    const { query } = props.location

    this.state = {
      lastUpdatedAt: query?.lastUpdated ? moment(query.lastUpdated) : null,
      pageType: this.getPageType(),
    }
  }

  componentDidMount() {
    const {
      childObservationsActions,
      frameworksActions,
      isNurseryMontessori,
      location,
      montessoriCategoriesActions,
      navigate,
      observationsActions,
      params,
      router,
    } = this.props
    const { pageType } = this.state
    const { childObservationId, observationId } = params

    frameworksActions.list({
      params: [{
        groups: FRAMEWORK_GROUPS,
      }],
    })

    observationsActions.get({
      params: [observationId, {
        groups: OBSERVATION_GROUPS,
      }],
    })

    childObservationsActions.get({
      onFailed: () => (
        navigate(pageType === OBSERVATIONS_PAGE_TYPE.APPROVALS
          ? generateRoute('APPROVALS.OBSERVATIONS')
          : generateRoute('LEARNING.OBSERVATIONS'))
      ),
      params: [childObservationId, {
        groups: CHILD_OBSERVATION_GROUPS,
      }],
    })

    if (isNurseryMontessori) {
      montessoriCategoriesActions.list({
        params: [{
          groups: MONTESSORI_CATEGORIES_GROUPS,
        }],
      })
    }

    router.replace({
      pathname: location.pathname,
      query: '',
    })
  }

  componentWillUnmount() {
    const { frameworksActions, isNurseryMontessori, montessoriCategoriesActions } = this.props

    frameworksActions.clear()

    if (isNurseryMontessori) {
      montessoriCategoriesActions.clearList()
    }
  }

  getPageType = () => {
    const { location: { pathname }, params } = this.props

    if (generateRoute('APPROVALS.OBSERVATIONS.EDIT.NEXT_STEPS', params) === pathname) {
      return OBSERVATIONS_PAGE_TYPE.APPROVALS
    }

    return OBSERVATIONS_PAGE_TYPE.LEARNING
  }

  handleGetFrameworkAreas = (framework) => {
    const { getFrameworkAreasList } = this.props

    return getFrameworkAreasList(framework?.value)
  }

  handleAutoSaveSuccess = (onCreateSuccess, id, index) => {
    const { changeFieldValue, formValues, snackbarActions } = this.props
    const { nextSteps } = formValues

    nextSteps[index] = {
      ...nextSteps[index],
      id,
    }
    changeFieldValue('nextSteps', nextSteps)
    onCreateSuccess(id)

    this.setState({ lastUpdatedAt: moment() })

    snackbarActions.show({
      message: i18n.t('module:Learning:Observations:ObservationNextSteps:nextStepUpdated'),
    })
  }

  handleAutoSave = (nextStepId, index, onCreateSuccess) => {
    const {
      childObservation,
      nextMontessoriActivitiesActions,
      nextStepsActions,
    } = this.props

    return setTimeout(() => {
      const { formValues: changedFormValues } = this.props
      const values = changedFormValues?.nextSteps?.[index]
      const isFrameworkMontessori = FRAMEWORK_TYPE_MONTESSORI === values?.framework?.value
      const body = {}

      if (!isFrameworkMontessori) {
        if (values.framework?.value) {
          body.framework = {
            id: values.framework.value,
          }
        } else {
          body.framework = null
        }
      }

      if (values.frameworkAreas) {
        if (isFrameworkMontessori) {
          body.montessoriActivity = _.map(values.frameworkAreas, ({ value }) => ({ id: value }))[0]
        } else {
          body.frameworkAreas = _.map(values.frameworkAreas, ({ value }) => ({ id: value }))
        }
      }

      body.comments = values.comments

      if (!nextStepId) {
        body.childObservation = {
          id: childObservation.id,
        }

        if (isFrameworkMontessori) {
          if (body?.montessoriActivity?.length) {
            return nextMontessoriActivitiesActions.create({
              body,
              onSuccess: ({ data: { id } }) => this.handleAutoSaveSuccess(onCreateSuccess, id, index),
            })
          }

          return onCreateSuccess()
        }

        return nextStepsActions.create({
          body,
          onSuccess: ({ data: { id } }) => this.handleAutoSaveSuccess(onCreateSuccess, id, index),
        })
      }

      if (isFrameworkMontessori) {
        return nextMontessoriActivitiesActions.update({
          body,
          onSuccess: () => this.handleAutoSaveSuccess(onCreateSuccess),
          params: [nextStepId, {}],
        })
      }

      return nextStepsActions.update({
        body,
        onSuccess: () => this.handleAutoSaveSuccess(onCreateSuccess),
        params: [nextStepId, {}],
      })
    })
  }

  handleRemoveFrameworkSuccess = (index, onSuccess, isFrameworkMontessori) => {
    const { changeFieldValue, childObservationsActions, formValues, snackbarActions } = this.props
    const { nextSteps } = formValues

    nextSteps.splice(index, 1)

    changeFieldValue('nextSteps', nextSteps)
    onSuccess()

    if (isFrameworkMontessori) {
      childObservationsActions.updateMontessoriActivitiesInChildObservation([null, false, true])
    }

    snackbarActions.show({
      message: i18n.t('module:Learning:Observations:ObservationNextSteps:recordRemoved'),
    })
  }

  handleRemoveFrameworkAccepted = (id, index, isFrameworkMontessori, onSuccess) => {
    const { childObservation, childObservationsActions, nextStepsActions } = this.props

    if (isFrameworkMontessori) {
      return childObservationsActions.removeMontessoriActivities({
        onSuccess: () => this.handleRemoveFrameworkSuccess(index, onSuccess, isFrameworkMontessori),
        params: [childObservation.id],
      })
    }

    if (!id) {
      return this.handleRemoveFrameworkSuccess(index, onSuccess)
    }

    return nextStepsActions.remove({
      onSuccess: () => this.handleRemoveFrameworkSuccess(index, onSuccess),
      params: [id],
    })
  }

  handleRemoveFramework = (id, index, isFrameworkMontessori, onSuccess, onFailed) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'trash',
      onCancel: onFailed || onSuccess,
      onConfirm: () => this.handleRemoveFrameworkAccepted(id, index, isFrameworkMontessori, onSuccess),
      text: i18n.t('module:Learning:Observations:ObservationNextSteps:removeContent'),
    })
  }

  handleMontessoriFrameworkChange = (index, onSuccess) => setTimeout(async () => {
    const { childObservation, childObservationsActions, formValues, nextMontessoriActivitiesActions } = this.props
    const { nextMontessoriActivities: currentPersistedValues } = childObservation
    const { nextSteps } = formValues

    const currentValuesInForm = nextSteps[index]?.frameworkAreas

    const elementsToAdd = _.filter(currentValuesInForm, ({ value }) => (
      !_.find(currentPersistedValues, ({ montessoriActivity: { id: montessoriActivityId } }) => (
        montessoriActivityId === value
      ))
    ))
    const elementsToRemove = _.filter(currentPersistedValues, ({
      montessoriActivity: { id: montessoriActivityId },
    }) => (
      !_.find(currentValuesInForm, ({ value }) => (
        montessoriActivityId === value
      ))
    ))

    const body = {
      childObservation: {
        id: childObservation.id,
      },
    }

    await Promise.all(_.map(elementsToAdd, async (item) => {
      body.montessoriActivity = {
        id: item?.value,
      }

      await nextMontessoriActivitiesActions.create({
        body,
        onSuccess: (response) => (
          childObservationsActions.updateMontessoriActivitiesInChildObservation([response, true])
        ),
        params: [{
          groups: MONTESSORI_ACTIVITIES_GROUPS,
        }],
      })

      return item
    }))

    await Promise.all(_.map(elementsToRemove, async (item) => {
      await nextMontessoriActivitiesActions.remove({
        onSuccess: () => {
          childObservationsActions.updateMontessoriActivitiesInChildObservation([item, false])
        },
        params: [item.id, {}],
      })

      return item
    }))

    this.setState({ lastUpdatedAt: moment() })

    onSuccess()
  })

  handleFrameworkChange = ({
    index,
    newFramework,
    nextStepId,
    oldFramework,
    onSuccess,
  }) => {
    const {
      changeFieldValue,
      childObservation,
      childObservationsActions,
      formValues,
      nextStepsActions,
    } = this.props

    updateFrameworkData({
      changeFieldValue,
      childObservation,
      childObservationsActions,
      formValues,
      index,
      newFramework,
      nextStepId,
      nextStepsActions,
      oldFramework,
      onAutoSave: this.handleAutoSave,
      onSuccess,
    })
  }

  render() {
    const {
      childObservation,
      errorMessages,
      formValues,
      frameworksList,
      initialValues,
      isFetching,
      isNurseryMontessori,
      montessoriCategories,
      params,
    } = this.props
    const { lastUpdatedAt } = this.state
    const { child } = childObservation || {}
    const { observationId } = params

    return (
      <ObservationNextStepsView
        child={child}
        errorMessages={errorMessages}
        formValues={formValues}
        frameworksList={frameworksList}
        initialValues={initialValues}
        isFetching={isFetching}
        isNurseryMontessori={isNurseryMontessori}
        lastUpdatedAt={lastUpdatedAt}
        montessoriCategories={montessoriCategories}
        observationId={observationId}
        onAutoSave={this.handleAutoSave}
        onFrameworkChange={this.handleFrameworkChange}
        onGetFrameworkAreas={this.handleGetFrameworkAreas}
        onMontessoriFrameworkChange={this.handleMontessoriFrameworkChange}
        onRemoveFramework={this.handleRemoveFramework}
      />
    )
  }
}

const mapDispatch = {
  changeFieldValue: (field, value) => change(OBSERVATION_NEXT_STEPS_FORM, field, value),
}

const mapState = (state, {
  appSelectors,
  childObservationsSelectors,
  childObservationsSingleState,
  frameworksListState,
  frameworksSelectors,
  montessoriCategoriesListState,
  montessoriCategoriesSelectors,
  observationsSingleState,
  securitySelectors,
}) => ({
  childObservation: childObservationsSelectors.getChildObservationsSingleStateDataSelector(state),
  errorMessages: appSelectors.getErrorMessages(
    childObservationsSingleState,
    frameworksListState,
    montessoriCategoriesListState,
  ),
  formValues: getFormValues(OBSERVATION_NEXT_STEPS_FORM)(state),
  frameworksList: frameworksSelectors.getFilteredFrameworksList(FRAMEWORK_STRUCTURE_APPLICABLE.NEXT_STEPS)(state),
  getFrameworkAreasList: (framework) => frameworksSelectors.getFrameworkAreasList(framework)(state),
  initialValues: childObservationsSelectors.getObservationNextStepsInitialValues(state),
  isFetching: appSelectors.getIsFetching(
    childObservationsSingleState,
    frameworksListState,
    observationsSingleState,
    montessoriCategoriesListState,
  ),
  isNurseryMontessori: securitySelectors.isMontessori(state),
  montessoriCategories: montessoriCategoriesSelectors.getMontessoriCategoriesDropdownWithRecommended(state),
})

const enhance = compose(
  withAppService,
  withRouter,
  withChildObservationsService,
  withFrameworksService,
  withNextStepsService,
  withNextMontessoriActivitiesService,
  withModalService,
  withMontessoriCategoriesService,
  withObservationsService,
  withSecurityService,
  withSnackbarService,
  connect(mapState, mapDispatch),
)

export default enhance(ObservationNextStepsContainer)
