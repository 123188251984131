/* eslint-disable max-len */
export const GET_ACTIVE_RECURRING_PAYMENT = 'NURSERY_INTEGRATIONS/GET_ACTIVE_RECURRING_PAYMENT'
export const GET_ACTIVE_RECURRING_PAYMENT_SUCCESS = 'NURSERY_INTEGRATIONS/GET_ACTIVE_RECURRING_PAYMENT_SUCCESS'
export const GET_ACTIVE_RECURRING_PAYMENT_FAILED = 'NURSERY_INTEGRATIONS/GET_ACTIVE_RECURRING_PAYMENT_FAILED'

export const GET_ACTIVE_RECURRING_PAYMENT_EXPORT = 'NURSERY_INTEGRATIONS/GET_ACTIVE_RECURRING_PAYMENT_EXPORT'
export const GET_ACTIVE_RECURRING_PAYMENT_EXPORT_SUCCESS = 'NURSERY_INTEGRATIONS/GET_ACTIVE_RECURRING_PAYMENT_EXPORT_SUCCESS'
export const GET_ACTIVE_RECURRING_PAYMENT_EXPORT_FAILED = 'NURSERY_INTEGRATIONS/GET_ACTIVE_RECURRING_PAYMENT_EXPORT_FAILED'

export const CLEAR_ACTIVE_RECURRING_PAYMENT = 'NURSERY_INTEGRATIONS/CLEAR_ACTIVE_RECURRING_PAYMENT'
