import Form from './helpers/Form/Form'
import FormRemoveRowButton from './helpers/FormRemoveRowButton'
import FormRow from './helpers/FormRow/FormRow'
import FormRowFlexItem from './helpers/FormRow/FormRowFlexItem'
import FormRowItem from './helpers/FormRow/FormRowItem'
import FormRowTextItem from './helpers/FormRow/FormRowTextItem'
import FormFooterActions from './helpers/FormFooterActions'
import FormStickyFooter from './helpers/FormStickyFooter'
import FormDivider from './helpers/FormDivider/FormDivider'

import FormButtonGroup from './fields/FormButtonGroup'
import FormChildSession from '../legacy/Form/fields/FormChildSession'
import FormCheckbox from './fields/FormCheckbox'
import FormDatePicker from './fields/FormDatePicker'
import FormDateTimePicker from './fields/FormDateTimePicker'
import FormDraggableList from './fields/FormDraggableList'
import FormError from './fields/FormError'
import FormInfiniteDropdowns from './fields/FormInfiniteDropdowns'
import FormMediaPicker from './fields/FormMediaPicker'
import FormNurserySession from '../legacy/Form/fields/FormNurserySession'
import FormPasswordInput from '../legacy/Form/fields/FormPasswordInput'
import FormPinpad from './fields/FormPinpad'
import FormPhotoUpload from './fields/FormPhotoUpload'
import FormRadioUser from './fields/FormRadioUser'
import FormRadioGroup from '../legacy/Form/fields/FormRadioGroup'
import FormSelect from './fields/FormSelect'
import FormSlider from './fields/FormSlider'
import FormSwitch from './fields/FormSwitch'
import FormTemplateTextField from './fields/FormTemplateTextField'
import FormTextAreaField from './fields/FormTextAreaField'
import FormTextEditorField from './fields/FormTextEditorField'
import FormTextField from './fields/FormTextField'
import FormTimePicker from './fields/FormTimePicker'

Form.RemoveRowButton = FormRemoveRowButton
Form.Row = FormRow
Form.Row.FlexItem = FormRowFlexItem
Form.Row.Item = FormRowItem
Form.Row.TextItem = FormRowTextItem
Form.FooterActions = FormFooterActions
Form.StickyFooter = FormStickyFooter
Form.Divider = FormDivider

Form.ButtonGroup = FormButtonGroup
Form.ChildSession = FormChildSession
Form.Checkbox = FormCheckbox
Form.DatePicker = FormDatePicker
Form.DateTimePicker = FormDateTimePicker
Form.DraggableList = FormDraggableList
Form.InfiniteDropdowns = FormInfiniteDropdowns
Form.Error = FormError
Form.MediaPicker = FormMediaPicker
Form.NurserySession = FormNurserySession
Form.PasswordInput = FormPasswordInput
Form.Pinpad = FormPinpad
Form.PhotoUpload = FormPhotoUpload
Form.RadioUser = FormRadioUser
Form.RadioGroup = FormRadioGroup
Form.Select = FormSelect
Form.Slider = FormSlider
Form.Switch = FormSwitch
Form.TemplateTextField = FormTemplateTextField
Form.TextAreaField = FormTextAreaField
Form.TextEditorField = FormTextEditorField
Form.TextField = FormTextField
Form.TimePicker = FormTimePicker

export default Form
