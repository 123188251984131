import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { INVOICE_LINE_ITEM_DISPLAY_DROPDOWN } from 'services/product/nurseryConsumablesV3/models'

import { Callout, Form } from 'components'

import i18n from 'translations'

export const MANAGEMENT_CONSUMABLES_ADD_FORM = 'ManagementConsumablesAddForm'

export interface ManagementConsumablesV3AddFormValues {
  applicableBeforeFunding?: string
  consumables?: boolean
  extraItems?: boolean
  extraSessions?: boolean
  name?: string
  regularItems?: boolean
  sessions?: boolean
  type?: string
  value?: number
}

interface ManagementConsumablesV3AddFormProps {
  error?: any
  isLoading?: boolean
  nurseryOptions?: any
  onSubmit: (values: ManagementConsumablesV3AddFormValues) => void
}

type ManagementConsumablesV3AddFormFullProps = ManagementConsumablesV3AddFormProps
  & InjectedFormProps<{}, ManagementConsumablesV3AddFormProps>

const ManagementConsumablesV3AddForm: React.FC<ManagementConsumablesV3AddFormFullProps> = ({
  error,
  handleSubmit,
  isLoading,
  nurseryOptions,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Callout content={error} error />
    <Form.Row label="Consumable name" width={{ field: '300px' }} required>
      <Field
        component={Form.TextField}
        name="name"
        placeholder="Consumable name"
        validate={isRequired}
      />
    </Form.Row>
    <Form.Row label="Consumables line item on invoices" required>
      <Field
        component={Form.RadioGroup}
        name="settings.invoiceLineItemDisplay"
        options={INVOICE_LINE_ITEM_DISPLAY_DROPDOWN}
        validate={isRequired}
      />
    </Form.Row>
    <Form.FooterActions
      cancelLink={`${nurseryOptions.prefix}/finance-settings/consumables`}
      isSubmitting={isLoading}
      submitLabel={i18n.t('global:Save')}
    />
  </Form>
)

export default reduxForm<{}, ManagementConsumablesV3AddFormProps>({
  form: MANAGEMENT_CONSUMABLES_ADD_FORM,
})(ManagementConsumablesV3AddForm)
