import React from 'react'

import { StyledSubGroupContentContainer } from './PlanViewStyled'

const PlanViewSubGroupContent = ({ children }) => (
  <StyledSubGroupContentContainer>
    {children}
  </StyledSubGroupContentContainer>
)

export default PlanViewSubGroupContent
