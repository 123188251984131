import { compose } from 'recompose'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { STRIPE_STATUSES } from 'services/nurseryIntegrations/constants'
import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withNurseryIntegrationsService } from 'services/nurseryIntegrations'
import { withShellService } from 'services/shell'
import { withRouter } from 'services/router'

import i18n from 'translations'

import { isOrganisationNursery } from 'services/security/selectors'
import FinanceReport from './FinanceReport'
import FinanceWrapperView from './FinanceWrapperView'
import FinanceGenerateInvoices from './FinanceGenerateInvoices'
import FinancePayouts from './FinancePayouts'
import FinanceFunding from './FinanceFunding'
import RegularBookingsList from './RegularBookings/RegularBookingsList'
import DepositsWrapper from './Deposits/DepositsWrapper'

const GROUPS_NURSERY_SETTINGS = {
  read: [
    'nursery.settings',
    'nurserySettings.localeDetails',
    'nurseryIntegration',
    'nurseryIntegrations.stripe',
  ],
}

const FinanceWrapperContainer = ({
  DepositsGranted,
  FundingGranted,
  GenerateInvoicesGranted,
  PayoutsGranted,
  RegularBookings,
  ReportsGranted,
  children,
  isFinanceV3Enabled,
  location,
  navigate,
  nurseriesActions,
  nurseryIntegrationsActions,
  nurseryOptions,
  shellActions,
}) => {
  const { pathname } = location
  const [isPayoutSideMenuGranted, setIsPayoutSideMenuGranted] = useState(false)

  const setSideBarMenu = () => {
    shellActions.setSubMenu([
      {
        hidden: !RegularBookings,
        icon: 'time',
        label: i18n.t('module:Finance:RegularBookings:title'),
        to: generateRoute('FINANCE.REGULAR_BOOKINGS'),
      },
      {
        hidden: !isFinanceV3Enabled,
        icon: 'sessions',
        label: i18n.t('module:Finance:OneOffBookings:title'),
        to: generateRoute('FINANCE.ONE_OFF_BOOKINGS'),
      },
      {
        hidden: !DepositsGranted,
        icon: 'deposit',
        label: i18n.t('module:Finance:Deposits:title'),
        to: generateRoute('FINANCE.DEPOSITS'),
      },
      {
        hidden: !FundingGranted || isFinanceV3Enabled,
        icon: 'funding',
        label: i18n.t('module:Finance:Funding:title'),
        to: generateRoute('FINANCE.FUNDING'),
      },
      {
        icon: 'invoices',
        label: i18n.t('module:Finance:Invoices:title'),
        to: generateRoute('FINANCE.INVOICES'),
      },
      {
        hidden: !GenerateInvoicesGranted,
        icon: 'generate-invoices',
        label: i18n.t('module:Finance:GenerateInvoices:title'),
        to: generateRoute('FINANCE.BULK'),
      },
      {
        icon: 'payment-history',
        label: i18n.t('module:Finance:Payments:title'),
        to: generateRoute('FINANCE.PAYMENTS'),
      },
      {
        hidden: !(PayoutsGranted && isPayoutSideMenuGranted),
        icon: 'payment-types',
        label: i18n.t('module:Finance:Payouts:title'),
        to: generateRoute('FINANCE.PAYOUTS'),
      },
      {
        hidden: !ReportsGranted,
        icon: 'financial-reports',
        label: i18n.t('module:Finance:Reports:title'),
        to: generateRoute('FINANCE.REPORT'),
      },
    ])
  }

  const getPayoutsSuccess = (nurserySettings, payouts) => {
    setIsPayoutSideMenuGranted(
      (nurserySettings?.stripeNurseryIntegration
        && nurserySettings?.stripeNurseryIntegration?.status !== STRIPE_STATUSES.NOT_ONBOARDED
      ) || payouts?.length,
    )
  }

  const getNurserySettingsSuccess = (nurserySettings) => {
    nurseryIntegrationsActions.getPayouts({
      onSuccess: ({ data }) => getPayoutsSuccess(nurserySettings?.data, data),
    })
  }

  useEffect(() => {
    nurseriesActions.get(nurseryOptions.id, {
      onSuccess: getNurserySettingsSuccess,
      params: { groups: GROUPS_NURSERY_SETTINGS },
    })
  }, [])

  useEffect(() => {
    if (generateRoute('FINANCE.INDEX') === pathname) {
      navigate(generateRoute(RegularBookings ? 'FINANCE.REGULAR_BOOKINGS' : 'FINANCE.INVOICES'))
    }
  }, [pathname])

  useEffect(() => {
    setSideBarMenu()
  }, [
    DepositsGranted,
    FundingGranted,
    GenerateInvoicesGranted,
    PayoutsGranted,
    isPayoutSideMenuGranted,
    ReportsGranted,
    isFinanceV3Enabled,
  ])

  return (
    <FinanceWrapperView>
      {children}
    </FinanceWrapperView>
  )
}

const mapState = (state, { appSelectors, params }) => ({
  DepositsGranted: auth.SELECTORS.getComponentIsAuthorised(state, DepositsWrapper),
  FundingGranted: auth.SELECTORS.getComponentIsAuthorised(state, FinanceFunding),
  GenerateInvoicesGranted: auth.SELECTORS.getComponentIsAuthorised(state, FinanceGenerateInvoices),
  PayoutsGranted: auth.SELECTORS.getComponentIsAuthorised(state, FinancePayouts),
  RegularBookings: auth.SELECTORS.getComponentIsAuthorised(state, RegularBookingsList),
  ReportsGranted: auth.SELECTORS.getComponentIsAuthorised(state, FinanceReport),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

FinanceWrapperContainer.authParams = (state) => {
  if (isOrganisationNursery(state.authentication)) {
    return {
      antiRoles: [
        ROLES.ORGANIZATION_NATIONAL_ADMIN,
      ],
      flags: [FEATURE_FLAGS.FINANCE_AUTOMATION, FEATURE_FLAGS.FINANCE_MVP],
      roles: [
        ROLES.SUPER_ADMIN,
        ROLES.ORGANIZATION_DIRECTOR,
        ROLES.ORGANIZATION_FINANCE_ADMIN,
        ROLES.NURSERY_MANAGER,
        ROLES.NURSERY_ADMIN,
      ],
    }
  }

  return {
    flags: [FEATURE_FLAGS.FINANCE_AUTOMATION, FEATURE_FLAGS.FINANCE_MVP],
    roles: [
      ROLES.SUPER_ADMIN,
      ROLES.NURSERY_ADMIN,
      ROLES.NURSERY_MANAGER,
    ],
  }
}

const enhance = compose(
  withAppService,
  withNurseriesService,
  withNurseryIntegrationsService,
  withRouter,
  withShellService,
  connect(mapState),
)

export default enhance(FinanceWrapperContainer)
