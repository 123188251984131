import i18n from 'translations'

export const USERS_LIST_COLUMNS = [
  {
    align: 'left',
    field: 'user',
    title: i18n.t('global:StaffName'),
  },
  {
    align: 'left',
    field: 'roles',
    title: i18n.t('global:Permission'),
  },
  {
    align: 'left',
    field: 'jobTitle',
    title: i18n.t('global:JobTitle'),
  },
  {
    align: 'left',
    field: 'email',
    title: i18n.t('global:Email'),
  },
  {
    field: 'edit',
  },
]

export const ADMIN_LIST_COLUMNS = [
  {
    align: 'left',
    field: 'user',
    title: i18n.t('global:StaffName'),
  },
  {
    align: 'left',
    field: 'email',
    title: i18n.t('global:Email'),
  },
  {
    field: 'edit',
  },
]

export const ORGANIZATION_LIST_COLUMNS = [
  {
    align: 'left',
    field: 'user',
    title: i18n.t('global:Staff'),
  },
  {
    align: 'left',
    field: 'contact',
    title: i18n.t('global:Contact'),
  },
  {
    align: 'left',
    field: 'location',
    title: i18n.t('global:Location'),
  },
  {
    field: 'edit',
  },
]
