import styled from 'styled-components'
import color from 'color'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { SHIFT_MAIN_TYPES_COLOURS, STAFF_SHIFT_COLOURS } from 'services/legacy/membershipRegisters/constants'

export const StyledTimeCallout = styled.div`
  display: grid;
  border-radius: 8px;
  padding: 6px 6px 10px 8px;
  position: relative;

  ${({ noBottomMargin }) => !noBottomMargin && `
    margin-bottom: 5px;
  `}
  
  ${({ worklogType }) => worklogType && `
    background: ${STAFF_SHIFT_COLOURS[worklogType].background};
  `}

  ${({ type }) => type && `
    background: ${color(SHIFT_MAIN_TYPES_COLOURS[type]).mix(color('#FFF'), 0.7).rgb().string()};
  `}

  ${({ isOngoing }) => isOngoing && `
    margin-right: -6px;
    padding-right: 12px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  `}

  ${({ isOverlapping }) => isOverlapping && `
    border-left: none;
    margin-left: -4px;
    padding-left: 12px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  `}
`

export const StyledContent = styled.div`
  color: ${NEUTRAL_COLOURS.BASIC};  

  ${({ isOverlapping }) => isOverlapping && `
    visibility: hidden;
    padding-top: 28px;
  `}
`

export const StyledHeader = styled.div`
  border-radius: 14px;
  margin: 0 -1px 6px -3px;
  padding: 4px 8px;
  font-weight: 600;
  
  ${({ worklogType }) => worklogType && `
    background: ${STAFF_SHIFT_COLOURS[worklogType].header};
  `}
  
  ${({ type }) => type && `
    background: ${SHIFT_MAIN_TYPES_COLOURS[type]};
  `}
`
