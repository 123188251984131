import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'
import selectors from './selectors'
import { IntegrationFeesListState } from './list/reducer'
import { IntegrationFeesSingleState } from './single/reducer'

export interface withIntegrationFeesServiceProps {
  integrationFeesActions: typeof actions
  integrationFeesListState: IntegrationFeesListState
  integrationFeesSelectors: typeof selectors
  integrationFeesSingleState: IntegrationFeesSingleState
}

const withIntegrationFeesService = <P extends withIntegrationFeesServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ integrationFees }) => ({ integrationFees })

  const mapDispatch = injectActions('integrationFeesActions', actions)

  const Component = (props) => {
    const { integrationFees, integrationFeesActions, ...others } = props

    return (
      <WrappedComponent
        integrationFeesActions={integrationFeesActions}
        integrationFeesListState={integrationFees.list}
        integrationFeesSelectors={selectors}
        integrationFeesSingleState={integrationFees.single}
        {...others as P}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withIntegrationFeesService
