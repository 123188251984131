import styled from 'styled-components'

import { StyledBackgroundPath, StyledProgressPath } from 'components/Arc/ArcStyled'

export const StyledContainer = styled.div`
  display: grid;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  
  
  ${StyledBackgroundPath} {
    stroke-width: 22;
  }
  
  ${StyledProgressPath} {
    stroke-width: 22.5;
  }
`

export const StyledCountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -15px;
  margin-bottom: 15px;
`

export const StyledActiveCount = styled.span`
  font-size: 25px;
  font-weight: 600;
`

export const StyledTotalCount = styled.span`
  font-size: 20px;
`
