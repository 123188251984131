import React, { PropsWithChildren } from 'react'

import { Spinner } from 'components'

import {
  StyledItemContainer,
  StyledItemContent,
  StyledItemTitle,
  StyledItemValue,
  StyledSubtitle,
} from './TabFilterStyled'

interface TabFilterItemProps {
  isClicked?: boolean
  isDisabled?: boolean
  isLoading?: boolean
  onChange?: () => {}
  onClick?: () => {}
  subTitle?: string
  title: string
  value: string
}

const TabFilterItem: React.FC<PropsWithChildren<TabFilterItemProps>> = ({
  isClicked,
  isDisabled,
  isLoading,
  onChange,
  onClick,
  subTitle,
  title,
  value,
}) => (
  <StyledItemContainer
    $isClicked={isClicked}
    $isDisabled={isDisabled}
    $isLoading={isLoading}
    onChange={onChange}
    onClick={isLoading || isDisabled ? () => {} : onClick}
  >
    <StyledItemContent>
      <StyledItemTitle>
        {title}
      </StyledItemTitle>
      <StyledItemValue>
        {isLoading ? <Spinner /> : value}
      </StyledItemValue>
      {subTitle && (
        <StyledSubtitle>
          {subTitle}
        </StyledSubtitle>
      )}
    </StyledItemContent>
  </StyledItemContainer>
)

export default TabFilterItem
