import styled from 'styled-components'

export const StyledCustomFeeWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 160px);
  grid-gap: 15px;
  margin-left: -15px;
`

export const StyledCustomFormWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 160px);
  grid-gap: 15px;
  margin-left: -25px;
  margin-top: -12px;
`
