import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { GLOBAL_NUMBER_SETTINGS } from 'services/organization/constants'
import { FEATURE_FLAGS } from 'constants/security'

import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withPaymentReminderTagsService } from 'services/legacy/paymentReminderTags'

import ManagementInvoicesV3View from './ManagementInvoicesV3View'

const NURSERY_INVOICE_SETTINGS_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings.invoice',
    'nurseryInvoiceSettings',
    'nurseryInvoiceSettings.numbers',
    'nurseryInvoiceSettings.paymentReminders',
    'nurseryInvoiceSettings.accountCodes',
    'accountCodes',
    'nurseryInvoiceSettings.annualisedInvoiceSettings',
    'annualisedInvoiceSettings',
    'invoiceNumbers',
    'nursery.organizationSettings',
    'organizationSettings',
    'organizationSettings.invoice',
    'organizationInvoiceSettings',
    'organizationInvoiceSettings.numbers',
    'nursery.bankDetails',
    'nurserySettings.localeDetails',
  ],
}

class ManagementInvoicesV3Container extends Component {
  componentDidMount() {
    const { nurseriesActions, nurseryOptions, paymentReminderTagsActions } = this.props

    const nurseryApiParams = { groups: NURSERY_INVOICE_SETTINGS_GROUPS }

    nurseriesActions.get(nurseryOptions.id, {
      params: nurseryApiParams,
    })
    paymentReminderTagsActions.list()
  }

  componentWillUnmount() {
    const { nurseriesActions } = this.props

    nurseriesActions.clearSingle()
  }

  handleSubmit = (fields) => (successCallback, failedCallback) => {
    const {
      nurseriesActions,
      nurseriesSelectors,
      nurseriesSingleState,
      nurseryOptions,
    } = this.props

    const apiParams = { groups: NURSERY_INVOICE_SETTINGS_GROUPS }
    const payload = nurseriesSelectors.getPayload({
      fields,
      nurseriesSingleState,
    })

    nurseriesActions.update(nurseryOptions.id, {
      onFailed: failedCallback,
      onSuccess: successCallback,
      params: apiParams,
      payload,
    })
  }

  render() {
    const {
      FinanceAutomationGranted,
      bankDetails,
      errorMessages,
      globalNumberType,
      invoiceNumberSettings,
      isEditDisabled,
      isFetching,
      isGlobalEnabled,
      isUkCountry,
      nurseryInvoiceSettings,
      openPeriodData,
      organizationInvoiceSettings,
      paymentReminderTags,
    } = this.props
    const { openWeeks } = openPeriodData || {}

    const hideGlobalOption = !isGlobalEnabled
    const isInvoiceNumberReadonly = globalNumberType === GLOBAL_NUMBER_SETTINGS.ACROSS_ORGANIZATION

    return (
      <ManagementInvoicesV3View
        FinanceAutomationGranted={FinanceAutomationGranted}
        bankDetails={bankDetails}
        disableEdit={isEditDisabled}
        errorMessages={errorMessages}
        hideGlobalOption={hideGlobalOption}
        invoiceNumberSettings={invoiceNumberSettings}
        invoicesSettings={nurseryInvoiceSettings}
        isInvoiceNumberReadonly={isInvoiceNumberReadonly}
        isLoading={isFetching}
        isUkCountry={isUkCountry}
        openWeeks={openWeeks}
        orgInvoicesSettings={organizationInvoiceSettings}
        paymentReminderTags={paymentReminderTags}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseriesSelectors,
  nurseriesSingleState,
  params,
  paymentReminderTagsSelectors,
}) => ({
  FinanceAutomationGranted: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.FINANCE_AUTOMATION],
  }),
  bankDetails: nurseriesSelectors.getBankDetails(state),
  errorMessages: appSelectors.getErrorMessages(nurseriesSingleState),
  globalNumberType: nurseriesSelectors.getOrganizationGlobalNumberType(state),
  invoiceNumberSettings: nurseriesSelectors.getNurseryInvoiceNumberWithDescriptions(state),
  isEditDisabled: nurseriesSelectors.isGlobalSettingEditable(state),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState),
  isGlobalEnabled: nurseriesSelectors.isGlobalSettingCustomizable(state),
  isUkCountry: nurseriesSelectors.getIsUkCountry(state),
  nurseryInvoiceSettings: nurseriesSelectors.getNurseryInvoiceSettings(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  openPeriodData: nurseriesSelectors.getNurseryOpenPeriod(state),
  organizationGlobalType: nurseriesSelectors.getOrgGlobalSettingType(state),
  organizationInvoiceSettings: nurseriesSelectors.getOrganizationInvoiceSettings(state),
  paymentReminderTags: paymentReminderTagsSelectors.getPaymentReminderTagsSelector(state),
})

const enhance = compose(
  withAppService,
  withNurseriesService,
  withPaymentReminderTagsService,
  connect(mapState),
)

export default enhance(ManagementInvoicesV3Container)
