import React from 'react'

import { Arc, Box, Section } from 'components'

import {
  StyledAttendanceChart,
  StyledAttendanceGrid,
  StyledAttendanceTable,
  StyledDoubleColumn,
  StyledGrid,
  StyledTitle,
  StyledValue,
} from './ChildOverviewStyled'

const ChildOverview = ({ formativeReport }) => {
  const { childSnapshot } = formativeReport || {}
  const {
    attendance,
    doesReceiveEYPPFunding,
    doesReceiveTwoYearFunding,
    englishAsAdditionalLanguage,
    parentsAreServingArmedForcesPersonnel,
    specialEducationalNeeds,
  } = childSnapshot || {}
  const { totalAbsentDays, totalPresentDays } = attendance || {}
  const totalDays = totalPresentDays + totalAbsentDays

  return (
    <React.Fragment>
      <StyledDoubleColumn>
        <Section
          title="Attendance"
          variant="small"
          noBorder
          noMobileVersion
        >
          <Box
            withPadding
          >
            <StyledAttendanceGrid>
              <StyledAttendanceChart>
                <Arc
                  content={`${parseInt(0 < totalDays ? ((totalPresentDays / totalDays) * 100) : 0)}%`}
                  size={120}
                  total={totalDays}
                  value={totalPresentDays}
                />
              </StyledAttendanceChart>
              <StyledAttendanceTable>
                <StyledTitle>
                  Total days
                </StyledTitle>
                <StyledValue>
                  {totalDays}
                </StyledValue>
                <StyledTitle>
                  Present days
                </StyledTitle>
                <StyledValue>
                  {totalPresentDays}
                </StyledValue>
                <StyledTitle>
                  Absent days
                </StyledTitle>
                <StyledValue>
                  {totalAbsentDays}
                </StyledValue>
              </StyledAttendanceTable>
            </StyledAttendanceGrid>
          </Box>
        </Section>
      </StyledDoubleColumn>
      <StyledGrid>
        <Section
          title="English as an additional language"
          variant="small"
          noBorder
          noMobileVersion
        >
          <Box
            withPadding
          >
            <StyledValue>
              {englishAsAdditionalLanguage || 'No'}
            </StyledValue>
          </Box>
        </Section>
        <Section
          title="Special Educational Needs"
          variant="small"
          noBorder
          noMobileVersion
        >
          <Box
            withPadding
          >
            <StyledValue>
              {specialEducationalNeeds || 'No'}
            </StyledValue>
          </Box>
        </Section>
        <Section
          title="&nbsp;"
          variant="small"
          noBorder
          noMobileVersion
        >
          <Box
            withPadding
          >
            <StyledTitle>
              Early Years Pupil Premium
            </StyledTitle>
            <StyledValue withMargin>
              {doesReceiveEYPPFunding ? 'Yes' : 'No'}
            </StyledValue>
            <StyledTitle>
              Child of Armed forces
            </StyledTitle>
            <StyledValue withMargin>
              {parentsAreServingArmedForcesPersonnel ? 'Yes' : 'No'}
            </StyledValue>
            <StyledTitle>
              2 year funded
            </StyledTitle>
            <StyledValue>
              {doesReceiveTwoYearFunding ? 'Yes' : 'No'}
            </StyledValue>
          </Box>
        </Section>
      </StyledGrid>
    </React.Fragment>
  )
}

export default ChildOverview
