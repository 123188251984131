import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { DEFAULT_PER_PAGE } from 'constants/pagination'

import { withAppService } from 'services/app'
import { withDeviceService } from 'services/device'
import { withDeviceTokensService } from 'services/legacy/deviceTokens'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'

import AuthorisedDevicesListView from './AuthorisedDevicesListView'

class AuthorisedDevicesListContainer extends Component {
  constructor(props) {
    super(props)

    const { paginationUtils } = props
    const { setPageLocationQuery } = paginationUtils

    this.state = {
      search: null,
    }

    setPageLocationQuery(false)
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { deviceTokensActions } = this.props

    deviceTokensActions.clearList()
  }

  fetch = () => {
    const { deviceTokensActions, paginationUtils, setLocationQuery } = this.props
    const { search } = this.state
    const { page } = paginationUtils

    const criteria = []

    if (search) {
      criteria.push({
        comparator: 'like',
        field: 'name',
        value: `%${search}%`,
      })
    }

    setLocationQuery({ search })
    deviceTokensActions.list({
      criteria,
      limit: DEFAULT_PER_PAGE,
      order: 'createdAt DESC',
      page,
      start: (page - 1) * DEFAULT_PER_PAGE,
    }, 1 !== page)
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleSearchChange = (search) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState({ search }, () => setTimeout(() => {
      onPageChange(this.fetch)(1)
    }))
  }

  render() {
    const { deviceIdentity, deviceTokens, isFetching, paginationUtils, totalResults } = this.props
    const { getPageCount, page } = paginationUtils

    const pageCount = getPageCount(totalResults)

    return (
      <AuthorisedDevicesListView
        deviceIdentity={deviceIdentity}
        deviceTokens={deviceTokens}
        isFetching={isFetching}
        page={page}
        pageCount={pageCount}
        totalResults={totalResults}
        onPageChange={this.handlePageChange}
        onSearchChange={this.handleSearchChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  deviceSelectors,
  deviceTokensListState,
  deviceTokensSelectors,
}) => ({
  deviceIdentity: deviceSelectors.getDeviceIdentity(state),
  deviceTokens: deviceTokensSelectors.getDeviceTokensData(state),
  isFetching: appSelectors.getIsFetching(deviceTokensListState),
  totalResults: appSelectors.getTotalResults(deviceTokensListState),
})

const enhance = compose(
  withAppService,
  withDeviceService,
  withDeviceTokensService,
  withPaginationUtils,
  withRouterUtils,
  connect(mapState),
)

export default enhance(AuthorisedDevicesListContainer)
