import { generateBasicActions } from 'utils/service'

import {
  CLEAR_ROOM,
  CREATE_ROOM,
  CREATE_ROOM_FAILED,
  CREATE_ROOM_SUCCESS,
  GET_ROOM,
  GET_ROOM_FAILED,
  GET_ROOM_SUCCESS,
  UPDATE_ROOM,
  UPDATE_ROOM_FAILED,
  UPDATE_ROOM_SUCCESS,
} from './constants'

export const get = (options = {}) => ({ roomsApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_ROOM_FAILED,
    init: GET_ROOM,
    success: GET_ROOM_SUCCESS,
  },
  options,
  service: roomsApiClient,
  serviceMethod: 'get',
})

export const create = (options = {}) => ({ roomsApiClient }) => generateBasicActions.create({
  constants: {
    failed: CREATE_ROOM_FAILED,
    init: CREATE_ROOM,
    success: CREATE_ROOM_SUCCESS,
  },
  options,
  service: roomsApiClient,
  serviceMethod: 'create',
})

export const update = (options = {}) => ({ roomsApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_ROOM_FAILED,
    init: UPDATE_ROOM,
    success: UPDATE_ROOM_SUCCESS,
  },
  options,
  service: roomsApiClient,
  serviceMethod: 'update',
})

export const updateMessagingRecipients = (options = {}) => ({ roomsApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_ROOM_FAILED,
    init: UPDATE_ROOM,
    success: UPDATE_ROOM_SUCCESS,
  },
  options,
  service: roomsApiClient,
  serviceMethod: 'updateMessagingRecipients',
})

export const updateMemberships = (options = {}) => ({ roomsApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_ROOM_FAILED,
    init: UPDATE_ROOM,
    success: UPDATE_ROOM_SUCCESS,
  },
  options,
  service: roomsApiClient,
  serviceMethod: 'updateMemberships',
})

export const archive = (options = {}) => ({ roomsApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_ROOM_FAILED,
    init: UPDATE_ROOM,
    success: UPDATE_ROOM_SUCCESS,
  },
  options,
  service: roomsApiClient,
  serviceMethod: 'update',
})

export const clearSingle = () => ({
  type: CLEAR_ROOM,
})
