import React from 'react'

import { StyledItemWrapperContainer } from './RegisterItemMainActionsStyled'

const RegisterItemMainActions = ({ children }) => (
  <StyledItemWrapperContainer>
    {children}
  </StyledItemWrapperContainer>
)

export default RegisterItemMainActions
