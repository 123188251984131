import i18n from 'translations'

export const MENU_ITEMS_CATEGORY = {
  MEAL: 'MEAL',
  SNACK: 'SNACK',
}

export const MENU_ITEMS_CATEGORY_PLURAL = {
  MEALS: 'MEALS',
  SNACKS: 'SNACKS',
}

export const MENU_ITEMS_STATUS = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
}

export const MENU_ITEMS_STATUS_OPTIONS = [
  {
    label: i18n.t('global:Active'),
    value: MENU_ITEMS_STATUS.ACTIVE,
  },
  {
    label: i18n.t('global:Archived'),
    value: MENU_ITEMS_STATUS.ARCHIVED,
  },
]

export const ACTION_TYPE = {
  ADD: 'ADD',
  EDIT: 'EDIT',
}
