import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > div:not(:last-child) {
    margin-right: 10px;
  }
`

export const StyledSubject = styled.div`
  background: ${NEUTRAL_COLOURS.SILVER};
  border-radius: 100px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  margin: 3px 0;
`

export const StyledDot = styled.div`
  background: ${({ background }) => background};
  min-height: 10px;
  min-width: 10px;
  max-height: 10px;
  max-width: 10px;
  margin-right: 5px;
  border-radius: 100%;
`
