import React from 'react'

import { Button, Hyperlink, Typography } from 'components'

import { StyledItemContainer } from './ReportViewStyled'

interface ReportViewItemProps {
  description: string
  onClick?: any
  title: string
  to: string
}

const ReportViewItem: React.FC<ReportViewItemProps> = ({ description, onClick, title, to }) => (
  <StyledItemContainer>
    <Hyperlink to={to} primary onClick={onClick}>
      {title}
    </Hyperlink>
    <Typography color="inherit">
      {description}
    </Typography>
    <Button icon="chevron-right" to={to} onClick={onClick} />
  </StyledItemContainer>
)

export default ReportViewItem
