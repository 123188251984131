import { generateBasicActions } from 'utils/service'

import {
  CLEAR_ENQUIRIES_SINGLE,
  CREATE_ENQUIRIES,
  CREATE_ENQUIRIES_FAILED,
  CREATE_ENQUIRIES_SUCCESS,
  GET_ENQUIRIES,
  GET_ENQUIRIES_FAILED,
  GET_ENQUIRIES_SUCCESS,
  UPDATE_ENQUIRIES,
  UPDATE_ENQUIRIES_FAILED,
  UPDATE_ENQUIRIES_SUCCESS,
} from './constants'

export const create = (options = {}) => ({ enquiriesApiClient }) => generateBasicActions.create({
  constants: {
    failed: CREATE_ENQUIRIES_FAILED,
    init: CREATE_ENQUIRIES,
    success: CREATE_ENQUIRIES_SUCCESS,
  },
  options,
  service: enquiriesApiClient,
  serviceMethod: 'create',
})

export const get = (options = {}) => ({ enquiriesApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_ENQUIRIES_FAILED,
    init: GET_ENQUIRIES,
    success: GET_ENQUIRIES_SUCCESS,
  },
  options,
  service: enquiriesApiClient,
  serviceMethod: 'get',
})

export const update = (options = {}) => ({ enquiriesApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_ENQUIRIES_FAILED,
    init: UPDATE_ENQUIRIES,
    success: UPDATE_ENQUIRIES_SUCCESS,
  },
  options,
  service: enquiriesApiClient,
  serviceMethod: 'update',
})

export const clearSingle = () => ({
  type: CLEAR_ENQUIRIES_SINGLE,
})
