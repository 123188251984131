import styled from 'styled-components'

export const StyledMontessoriFields = styled.div`
  @media (max-width: 740px) {
    & > div > div:nth-of-type(2) {
      width: 100%;
      display: grid;
      flex-direction: column;
      grid-row-gap: 5px;  
    }
  }
`
