import _ from 'lodash'

import i18n from 'translations'

export const STATUS_TYPES = {
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  PENDING: 'PENDING',
}

export const STATUS_TYPES_OPTIONS = [
  {
    label: i18n.t('module:Parent:RequestedSessions:List:filters:status:approved'),
    value: STATUS_TYPES.APPROVED,
  },
  {
    label: i18n.t('module:Parent:RequestedSessions:List:filters:status:declined'),
    value: STATUS_TYPES.DECLINED,
  },
  {
    label: i18n.t('module:Parent:RequestedSessions:List:filters:status:pending'),
    value: STATUS_TYPES.PENDING,
  },
]

export const STATUS_TYPES_DROPDOWN = [
  ...STATUS_TYPES_OPTIONS,
  {
    label: i18n.t('module:Parent:RequestedSessions:List:filters:status:title'),
    value: '',
  },
]

export const ERROR_CODES = {
  AVAILABILITY_STAFF_REQUIREMENTS_CHANGED: 400511,
}

const prefix = 'module:Management:ParentApp:RequestableExtraSessions:UnavailableTimes:Regular:DaysOfWeekAbbr:'

const DAYS_OF_WEEK = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]

export const DAYS_MAP = _.reduce(
  DAYS_OF_WEEK,
  (result, dayOfWeek) => ({ ...result, [dayOfWeek]: i18n.t(prefix.concat(dayOfWeek)) }),
  {},
)

export const ACTIONS = {
  APPROVE: 'APPROVE',
  DECLINE: 'DECLINE',
}
