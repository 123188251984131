import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
`

export const StyledItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
`

export const StyledContentContainer = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 20px;
  align-items: center;
`

export const StyledEditIconContainer = styled.div`
  padding-left: 10px;
`

export const StyledReasonContentContainer = styled.div`
  display: flex;
  margin-top: 10px;
  flex-direction: column;
`

export const StyledReasonHeaderContainer = styled.div`
  font-weight: 600;
  font-size: 20px;
`
