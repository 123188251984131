import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as financeRevenuePerRoomReportSelectors from './selectors'

const withFinanceRevenuePerRoomReportService = (WrappedComponent) => {
  const mapState = ({ financeRevenuePerRoomReport }) => ({ financeRevenuePerRoomReport })

  const mapDispatch = injectActions('financeRevenuePerRoomReportActions', actions)

  const Component = ({ financeRevenuePerRoomReport, financeRevenuePerRoomReportActions, ...others }) => (
    <WrappedComponent
      financeRevenuePerRoomReportActions={financeRevenuePerRoomReportActions}
      financeRevenuePerRoomReportChildrenState={financeRevenuePerRoomReport.children}
      financeRevenuePerRoomReportNurseriesState={financeRevenuePerRoomReport.nurseries}
      financeRevenuePerRoomReportSelectors={financeRevenuePerRoomReportSelectors}
      financeRevenuePerRoomReportStatisticsState={financeRevenuePerRoomReport.statistics}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withFinanceRevenuePerRoomReportService
