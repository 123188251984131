import i18n from 'translations'

export enum LIST_STATUS_FILTERS {
  ACTIVE = 'ACTIVE',
  ALL = 'ALL',
  ARCHIVED = 'ARCHIVED',
}

export const LIST_STATUS_FILTERS_DROPDOWN = [
  {
    label: i18n.t('global:All'),
    value: LIST_STATUS_FILTERS.ALL,
  },
  {
    label: i18n.t('global:Active'),
    value: LIST_STATUS_FILTERS.ACTIVE,
  },
  {
    label: i18n.t('global:Archived'),
    value: LIST_STATUS_FILTERS.ARCHIVED,
  },
]
