import { combineReducers } from 'redux'

import changelog from './changelog/reducer'
import list from './list/reducer'
import listWorklogByDay from './listWorklogByDay/reducer'
import single from './single/reducer'
import statistics from './statistics/reducer'

export default combineReducers({
  changelog,
  list,
  listWorklogByDay,
  single,
  statistics,
})
