import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withCohortTrackingService } from 'services/legacy/cohortTracking'
import { withModalService } from 'services/utils/modal'
import { withFormativeReportsService } from 'services/legacy/formativeReports'
import { withRouterUtils } from 'services/utils/router'
import { withRouter } from 'services/router'

import CohortTrackingListView from './CohortTrackingListView'

class CohortTrackingListContainer extends Component {
  componentDidMount() {
    const { formativeReportsActions } = this.props

    this.fetch()
    formativeReportsActions.getTerms()
  }

  componentWillUnmount() {
    const { cohortTrackingActions, formativeReportsActions } = this.props

    cohortTrackingActions.clear()
    formativeReportsActions.clearTerms()
  }

  fetch = () => {
    const { cohortTrackingActions } = this.props

    cohortTrackingActions.list()
  }

  render() {
    const {
      isFetching,
      items,
      terms,
    } = this.props

    return (
      <CohortTrackingListView
        isFetching={isFetching}
        items={items}
        terms={terms}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  cohortTrackingListState,
  cohortTrackingSelectors,
  formativeReportsSelectors,
  formativeReportsTermsState,
}) => ({
  isFetching: appSelectors.getIsFetching(cohortTrackingListState, formativeReportsTermsState),
  items: cohortTrackingSelectors.getCohortTrackingListDataSelector(state),
  terms: formativeReportsSelectors.getUsedTermsDropdownInFormativeReports(state),
})

const enhance = compose(
  withAppService,
  withCohortTrackingService,
  withFormativeReportsService,
  withModalService,
  withRouter,
  withRouterUtils,
  connect(mapState),
)

export default enhance(CohortTrackingListContainer)
