import _ from 'lodash'

import React from 'react'

import { generateRoute } from 'utils/routing'

import { Button, Currency, DateString } from 'components'

export const getTableData = (adhocSessions, childId) => {
  if (!adhocSessions || !adhocSessions.length) {
    return []
  }

  return _.map(adhocSessions, ({ cost, id, name, takenDate }) => ({
    cost: <Currency value={cost} />,
    name,
    rowToButton: (
      <Button.TableAction
        to={generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_SESSIONS.EDIT', { childId, id })}
        edit
      />
    ),
    takenDate: <DateString date={takenDate} />,
  }))
}
