import styled from 'styled-components'

import colors from 'constants/colors'

interface StyledContainerProps {
  $background?: string
  $margin?: string
}

export const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: baseline;

  ${({ $background }) => $background && `
    background: ${$background};
    padding: 10px;
    border-radius: 8px;
  `}
  
  ${({ $margin }) => $margin && `
    margin: ${$margin};
  `}
`

export const StyledLabelContainer = styled.div`
  padding-right: 10px;
  text-transform: uppercase;
  font-size: 12px;
  color: ${colors.gray};
`

interface StyledAmountContainerProps {
  $color?: string
}

export const StyledAmountContainer = styled.div<StyledAmountContainerProps>`
  font-size: 24px;
  font-weight: 600;
  padding: 1px 8px;
  margin-bottom: 5px;
  
  ${({ $color }) => $color && `
    color: ${$color};
    border-left: 4px solid ${$color};
  `}
`
