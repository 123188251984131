import React, { Component } from 'react'
import { compose } from 'recompose'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withEnquiriesService, withEnquiryViewingsService } from 'services/legacy/enquiries'
import { withRouter } from 'services/router'

import i18n from 'translations'

import ExportModalView from './ExportModalView'

import { MODULE_TYPE } from './constants'

class ExportModalContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isExporting: false,
    }
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSubmitSuccess = (email) => {
    const { modalActions, modalConsts } = this.props

    this.setState({ isExporting: false })
    this.handleCloseClick()

    modalActions.show(modalConsts.TYPES.ALERT, {
      text: i18n.t('module:Modals:ExportReportModal:alert', { email }),
    })
  }

  exportEnquiry = (formValues) => {
    const { enquiriesActions, enquiriesSelectors } = this.props
    const { dateRange, membership } = formValues
    const [after, before] = dateRange

    this.setState({ isExporting: true })

    const criteria = enquiriesSelectors.getCriteria({
      enquiryDate: {
        after,
        before,
      },
      membership: membership?.value,
    })

    enquiriesActions.exportEnquiries({
      onSuccess: () => this.handleSubmitSuccess(membership?.email),
      params: [{ criteria }],
    })
  }

  exportViewing = (formValues) => {
    const { enquiryViewingsActions, enquiryViewingsSelectors } = this.props
    const { dateRange, membership } = formValues
    const [after, before] = dateRange

    this.setState({ isExporting: true })

    const criteria = enquiryViewingsSelectors.getCriteria({
      membership: membership?.value,
      showAroundDate: {
        after,
        before,
      },
    })

    enquiryViewingsActions.exportViewings({
      onSuccess: () => this.handleSubmitSuccess(membership?.email),
      params: [{ criteria }],
    })
  }

  handleSubmit = (formValues) => {
    const { moduleType } = this.props

    if (MODULE_TYPE.ENQUIRY === moduleType) {
      return this.exportEnquiry(formValues)
    }

    return this.exportViewing(formValues)
  }

  handleCancelClick = () => {
    const { onCancelClick } = this.props

    if (onCancelClick) {
      onCancelClick()
    }

    this.handleCloseClick()
  }

  render() {
    const { moduleType } = this.props
    const { isExporting } = this.state

    return (
      <ExportModalView
        isSubmitting={isExporting}
        moduleType={moduleType}
        onCancelClick={this.handleCancelClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withEnquiriesService,
  withEnquiryViewingsService,
)

export default enhance(ExportModalContainer)
