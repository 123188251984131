import _ from 'lodash'

import React from 'react'
import { Nursery } from 'services/nurseries/models'

import { NurserySession } from 'services/product/nurserySessionsV3/models'
import { TableColumn } from 'components/Table/Table'

import { generateRoute } from 'utils/routing'

import { Button, Currency, Grid, Icon, TimeString } from 'components'

import i18n from 'translations'

interface TableDataProps {
  costTwoYears: JSX.Element
  costTwoYearsAbove: JSX.Element
  costUnderTwo: JSX.Element
  edit: JSX.Element
  endTime: JSX.Element
  isHourly: JSX.Element
  name: string
  startTime: JSX.Element
}

export const getSessionsTableData = (
  sessions: NurserySession[],
  isOrganizationContext: boolean,
  nursery: Nursery,
): TableDataProps[] => {
  if (!sessions || !sessions.length) {
    return []
  }

  return _.map(sessions, (session) => {
    const {
      archived,
      costTwoYears,
      costTwoYearsAbove,
      costUnderTwo,
      endTime,
      hourly,
      id,
      name,
      startTime,
    } = session

    return {
      costTwoYears: <Currency value={costTwoYears} />,
      costTwoYearsAbove: <Currency value={costTwoYearsAbove} />,
      costUnderTwo: <Currency value={costUnderTwo} />,
      edit: <Button.TableAction
        to={isOrganizationContext
          ? generateRoute('SETTINGS.SESSIONS.NURSERY.SESSIONS.EDIT', {
            nurseryId: nursery?.id,
            sessionId: id,
          })
          : generateRoute('MANAGEMENT.FINANCE_SETTINGS.SESSIONS.EDIT', { sessionId: id })}
        edit
      />,
      endTime: <TimeString date={endTime} local={false} />,
      isHourly: hourly && (
        <Grid>
          <Grid.Item flex={{ desktop: 1 }} justifyContent={{ desktop: 'center' }}>
            <Icon icon="check" />
          </Grid.Item>
        </Grid>
      ),
      name: archived ? `${name} (${i18n.t('global:archived')})` : name,
      startTime: <TimeString date={startTime} local={false} />,
    }
  })
}

export const getTableColumns = (FinanceAutomationGranted) => {
  const COLUMNS: TableColumn[] = [
    {
      align: 'left',
      field: 'name',
      sortable: true,
      title: i18n.t('module:Management:Finance:SessionsV3:Table:Session:label'),
    },
    {
      field: 'startTime',
      sortable: true,
      title: i18n.t('module:Management:Finance:SessionsV3:Table:Start:label'),
      width: '100px',
    },
    {
      field: 'endTime',
      sortable: true,
      title: i18n.t('module:Management:Finance:SessionsV3:Table:Finish:label'),
      width: '100px',
    },
    {
      align: 'center',
      field: 'isHourly',
      title: i18n.t('module:Management:Finance:SessionsV3:Table:Hourly:label'),
      width: '100px',
    },
  ]

  if (FinanceAutomationGranted) {
    COLUMNS.concat([
      {
        field: 'costUnderTwo',
        title: i18n.t('module:Management:Finance:SessionsV3:Table:Under2s:label'),
      },
      {
        field: 'costTwoYears',
        title: i18n.t('module:Management:Finance:SessionsV3:Table:TwoYearOld:label'),
      },
      {
        field: 'costTwoYearsAbove',
        title: i18n.t('module:Management:Finance:SessionsV3:Table:ThreeToFiveYearOld:label'),
      },
    ])
  }

  COLUMNS.push({
    field: 'edit',
    width: '50px',
  })

  return COLUMNS
}
