import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'

import { Banner, Button, FooterActions, ModalBox, Section, Space, Typography } from 'components'

import i18n from 'translations'

const MAX_OTHER_SESSION_ITEM_COUNT = 3
const CURRENT_MODE = {
  CREATING: 'Create',
  DELETING: 'Delete',
  EDITING: 'Edit',
}

const SessionSetImpactModalView = ({
  autoCreditNotingEnabled,
  childFirstName,
  currentMode,
  currentSession,
  isSubmitting,
  onCloseClick,
  onSave,
  otherImpactedSession,
}) => {
  const { endDate, isOngoing, startDate } = currentSession
  const visibleOtherSessionItems = _.slice(otherImpactedSession, 0, MAX_OTHER_SESSION_ITEM_COUNT)

  const moreOtherSessionItemsCount = otherImpactedSession?.length
    ? otherImpactedSession.length - MAX_OTHER_SESSION_ITEM_COUNT
    : 0

  const getSessionStartAndEndDateString = (
    sessionStartDate,
    sessionEndDate,
    isSessionOngoing,
  ) => {
    const formattedStartDate = moment(sessionStartDate).format(DISPLAY_SHORT_DATE_FORMAT)
    const formattedEndDate = isSessionOngoing
      ? _.upperFirst(i18n.t('global:Ongoing'))
      : moment(sessionEndDate).format(DISPLAY_SHORT_DATE_FORMAT)

    return `${formattedStartDate} - ${formattedEndDate}`
  }

  const getHeader = () => {
    if (currentMode === CURRENT_MODE.DELETING) {
      if (autoCreditNotingEnabled) {
        return i18n.t('module:Modals:SessionSetImpact:deleteAcnHeader')
      }

      return i18n.t('module:Modals:SessionSetImpact:deleteHeader')
    }

    if (autoCreditNotingEnabled) {
      return i18n.t('module:Modals:SessionSetImpact:addEditAcnHeader')
    }

    return i18n.t('module:Modals:SessionSetImpact:addEditHeader')
  }

  const getConfirmButtonLabel = () => {
    if (currentMode === CURRENT_MODE.DELETING) {
      if (autoCreditNotingEnabled) {
        return i18n.t('global:DeleteAndContinue')
      }

      return i18n.t('global:Delete')
    }

    if (autoCreditNotingEnabled) {
      return i18n.t('global:SaveAndContinue')
    }

    return i18n.t('global:Save')
  }

  return (
    <ModalBox
      maxWidth={600}
      title={i18n.t('module:Modals:SessionSetImpact:title')}
      onCloseClick={onCloseClick}
    >
      <Section>
        <Typography variant="h4">
          {getHeader()}
        </Typography>
        <Space space="30px" />
        <Typography fontSize="20" bold>
          {i18n.t('module:Modals:SessionSetImpact:sessionSetLabel')}
        </Typography>
        <Space space="5px" />
        {getSessionStartAndEndDateString(startDate, endDate, isOngoing)}
        <Space space="40px" />
        <Typography fontSize="20" bold>
          {i18n.t('module:Modals:SessionSetImpact:impactOnInvoiceAndFundingText')}
        </Typography>
        <Space space="10px" />
        <Banner.Error>
          {i18n.t('module:Modals:SessionSetImpact:childImpactOnBannerText', { childFirstName })}
        </Banner.Error>
        {visibleOtherSessionItems?.length ? (
          <React.Fragment>
            <Space space="40px" />
            <Typography fontSize="20" bold>
              {i18n.t('module:Modals:SessionSetImpact:impactOnSessionSetsText')}
            </Typography>
            <Space space="10px" />
            <Banner.Error>
              {i18n.t('module:Modals:SessionSetImpact:sessionSetBannerText')}
            </Banner.Error>
            <Space space="10px" />
            <Typography bold>
              {i18n.t('module:Modals:SessionSetImpact:impactedSessionSetsText')}
            </Typography>
            {_.map(visibleOtherSessionItems, ({
              endDate: otherEndDate,
              isOngoing: otherIsOngoing,
              startDate: otherStartDate,
            }) => (
              <React.Fragment>
                <Space space="10px;" />
                {getSessionStartAndEndDateString(otherStartDate, otherEndDate, otherIsOngoing)}
              </React.Fragment>
            ))}
            {0 < moreOtherSessionItemsCount && (
              <React.Fragment>
                <Space space="10px;" />
                {i18n.t('module:Modals:SessionSetImpact:moreSessionSetItemsText', {
                  count: moreOtherSessionItemsCount,
                })}
              </React.Fragment>
            )}
          </React.Fragment>
        ) : null }
        <Space space="20px" />
        <FooterActions spaceBetween>
          <FooterActions.Item>
            <Button
              hierarchy="tertiary"
              label={i18n.t('global:Cancel')}
              negativeMargins
              onClick={onCloseClick}
            />
          </FooterActions.Item>
          <FooterActions.Item>
            <Button
              isLoading={isSubmitting}
              label={getConfirmButtonLabel()}
              negativeMargins
              onClick={onSave}
            />
          </FooterActions.Item>
        </FooterActions>
      </Section>
    </ModalBox>
  )
}

export default SessionSetImpactModalView
