import { initSchema } from './model'

// @ts-check
import { schema } from './schema';

const ModelAttributeTypes = {
  "BINARY": "binary",
  "BINARY_SET": "binarySet",
  "BOOL": "bool",
  "LIST": "list",
  "MAP": "map",
  "NUMBER": "number",
  "NUMBER_SET": "numberSet",
  "STRING": "string",
  "STRING_SET": "stringSet",
  "NULL": "_null"
};

const ChannelType = {
  "CHILD": "child"
};

const UserType = {
  "USER": "user",
  "PARENT": "parent"
};

const MessageType = {
  "USER": "user"
};

const NotificationTagEnum = {
  "ALL": "all",
  "TASK": "task",
  "REMINDER": "reminder",
  "UPDATE": "update",
};

const {
  Channel,
  Message,
  User,
  ChannelMember,
  Notification,
  NotificationTag,
  UserCounter,
  ModelChannelConnection,
  ModelChannelMemberConnection,
  ModelMessageConnection,
  ModelUserConnection,
  NotificationBody,
  NotificationBodyPlaceholder,
  NotificationData,
  ManyNotificationsMutation
} = initSchema(schema);

export {
  Channel,
  Message,
  User,
  ChannelMember,
  Notification,
  NotificationTag,
  UserCounter,
  UserType,
  MessageType,
  ChannelType,
  ModelAttributeTypes,
  NotificationTagEnum,
  ModelChannelConnection,
  ModelChannelMemberConnection,
  ModelMessageConnection,
  ModelUserConnection,
  NotificationBody,
  NotificationBodyPlaceholder,
  NotificationData,
  ManyNotificationsMutation
};
