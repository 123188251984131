import React, { PropsWithChildren } from 'react'

import i18n from 'translations'

import LegendItem from './LegendItem'
import { StyledLegend, StyledLegendTitle } from './LegendStyled'

interface LegendRoot<T> extends React.FC<PropsWithChildren<T>> {
  Item?: typeof LegendItem
}

interface LegendProps {
  noMarginBottom?: boolean
  title?: string
  withoutTitle?: boolean
}

const Legend: LegendRoot<LegendProps> = ({
  children,
  noMarginBottom,
  title = i18n.t('components:Legend:key'),
  withoutTitle,
}) => (
  <StyledLegend $noMarginBottom={noMarginBottom}>
    {!withoutTitle && (
      <StyledLegendTitle>
        {title}
        :
      </StyledLegendTitle>
    )}
    {children}
  </StyledLegend>
)

export default Legend
