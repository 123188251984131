import React from 'react'

import { SubMenuWrapper } from 'components'

const LearningWrapperView = ({ children }) => (
  <SubMenuWrapper>
    {children}
  </SubMenuWrapper>
)

export default LearningWrapperView
