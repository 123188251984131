import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledInvoiceCountContainer = styled.div`
  background: ${getBrandingColor('senary-color')};
  padding: 6px;
  font-size: 18px;
  line-height: 1;
  border-radius: 4px;
  text-align: center;
  margin-left: 10px;
`
