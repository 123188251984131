import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import colors from 'constants/colors'
import { OBSERVATION_STATUSES } from 'services/legacy/observations/constants'
import { FRAMEWORK_STRUCTURE_APPLICABLE } from 'services/legacy/frameworks/constants'
import { FRAMEWORK_PROGRESS_LEVELS_TYPES } from 'services/legacy/frameworkProgressLevels/constants'

import { generateRoute } from 'utils/routing'

import { EVENTS, logEvent } from 'analytics'

import { withAppService } from 'services/app'
import { withFrameworksService } from 'services/legacy/frameworks'
import { withModalService } from 'services/utils/modal'
import { withObservationsService } from 'services/legacy/observations'
import { withSecurityService } from 'services/security'
import { withNurseriesService } from 'services/nurseries'
import { withUploadService } from 'services/legacy/upload'
import { withFrameworkProgressLevelsService } from 'services/legacy/frameworkProgressLevels'
import { getObservationEventLogContext } from 'services/legacy/observations/helpers'
import { withRouter } from 'services/router'

import { MinimalModalBox } from 'components'

import i18n from 'translations'

import { OBSERVATIONS_PAGE_TYPE } from 'module/Learning/Observations/constants'

import ObservationsReviewView from './ObservationsReviewView'

const NURSERY_SETTINGS_GROUP = {
  read: [
    'nursery.settings',
    'nurserySettings.learning',
    'nurseryLearningSettings',
  ],
}

const FRAMEWORK_GROUPS = {
  read: [
    'framework.areas',
    'structure',
    'structure.observation',
    'structure.observationLinks',
    'frameworkArea',
    'frameworkArea.groups',
    'frameworkGroup',
    'frameworkGroup.categories',
    'frameworkCategory',
    'frameworkCategory.items',
    'frameworkItem',
    'frameworkItem.linkedItems',
  ],
}

const OBSERVATION_GROUPS = {
  read: [
    'child',
    'childObservation',
    'childObservation.child',
    'medium',
    'medium.children',
    'montessoriActivity',
    'observation.author',
    'observation.childObservations',
    'observation.media',
    'observation.montessoriActivity',
    'user',
    'user.details',
  ],
}

class ObservationsReviewContainer extends Component {
  constructor(props) {
    super(props)

    const { location: { pathname } } = props

    this.state = {
      activeChildIndex: 0,
      eventLogContext: getObservationEventLogContext(pathname),
      pageType: this.getPageType(),
    }
  }

  componentDidMount() {
    const {
      frameworkProgressLevelsActions,
      frameworksActions,
      nurseriesActions,
      nurseryOptions,
    } = this.props
    const { eventLogContext } = this.state

    const progressLevelsCriteria = [
      {
        field: 'type',
        value: FRAMEWORK_PROGRESS_LEVELS_TYPES.OBSERVATION_LINK,
      },
    ]

    frameworksActions.list({
      params: [{
        groups: FRAMEWORK_GROUPS,
      }],
    })

    nurseriesActions.get(nurseryOptions.id, {
      params: { groups: NURSERY_SETTINGS_GROUP },
    })

    this.fetch(true)

    frameworkProgressLevelsActions.list({
      params: {
        criteria: progressLevelsCriteria,
      },
      recursively: true,
    })

    logEvent(EVENTS.OBSERVATION_PREVIEW_PAGE_VIEWED, { context: eventLogContext })
  }

  componentWillUnmount() {
    const { frameworkProgressLevelsActions, observationsActions } = this.props

    frameworkProgressLevelsActions.clear()
    observationsActions.clear()
  }

  componentDidUpdate(prevProps) {
    const { params } = this.props
    const { params: prevParams } = prevProps
    const { observationId } = params
    const { observationId: prevObservationId } = prevParams

    if (
      prevObservationId
      && observationId
      && prevObservationId !== observationId
    ) {
      this.fetch()
    }
  }

  fetch = (isDidMount) => {
    const { location, observationsActions, params } = this.props
    const { observationId } = params
    const { query: { objectId } } = location

    observationsActions.get({
      onFailed: this.getRoute,
      onSuccess: (observation) => {
        // check observation
        if (isDidMount && objectId && observation.data?.childObservations) {
          const index = _.map(observation.data?.childObservations, (el) => el.id).indexOf(Number(objectId))
          this.setState({ activeChildIndex: index })
        }
      },
      params: [observationId, {
        groups: OBSERVATION_GROUPS,
      }],
    })
  }

  getPageType = () => {
    const { location: { pathname }, params: { observationId } } = this.props

    if (generateRoute('APPROVALS.OBSERVATIONS.REVIEW', { observationId }) === pathname) {
      return OBSERVATIONS_PAGE_TYPE.APPROVALS
    }

    return OBSERVATIONS_PAGE_TYPE.LEARNING
  }

  getRoute = () => {
    const { navigate } = this.props
    const { pageType } = this.state

    const isApprovalsContext = pageType === OBSERVATIONS_PAGE_TYPE.APPROVALS

    navigate(isApprovalsContext
      ? generateRoute('APPROVALS.OBSERVATIONS')
      : generateRoute('LEARNING.OBSERVATIONS'))
  }

  handleClickSlide = (activeChildIndex) => {
    this.setState({ activeChildIndex })
  }

  handleRemoveObservationSuccess = () => {
    const { observationsActions, params } = this.props
    const { eventLogContext } = this.state
    const { observationId } = params

    observationsActions.remove({
      onSuccess: () => {
        logEvent(EVENTS.OBSERVATION_DELETED, { context: eventLogContext })

        this.getRoute()
      },
      params: [observationId],
    })
  }

  handleRemoveObservation = () => {
    const { modalActions, modalConsts } = this.props
    const { eventLogContext } = this.state

    logEvent(EVENTS.OBSERVATION_DELETE_BTN_CLICKED, { context: eventLogContext })

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'trash',
      onConfirm: this.handleRemoveObservationSuccess,
      text: i18n.t('module:Learning:Observations:ObservationsReview:confirmRemoveObservation'),
    })
  }

  checkObservationIsEmpty = () => {
    const { observation } = this.props
    const {
      childObservations,
      comments,
      media,
    } = observation || {}

    return !childObservations?.length && !comments?.length && !media?.length
  }

  getApprovalRedirectionQuery = (query) => {
    const { fromAuthorId, fromDate, fromKeyPerson, fromPage, fromPerPage, fromRoom, fromStatus, ...other } = query

    return {
      authorId: fromAuthorId,
      date: fromDate,
      keyPerson: fromKeyPerson,
      page: fromPage,
      perPage: fromPerPage,
      room: fromRoom,
      status: fromStatus,
      ...other,
    }
  }

  getApprovalRedirectionPathname = (ref) => {
    const { location: { pathname }, observation, params: { observationId } } = this.props
    const { activeChildIndex } = this.state

    const currentObservation = _.find(observation?.childObservations, (item, index) => (
      index === activeChildIndex
    ))

    const PATHNAMES_MAP = {
      approvals: generateRoute('APPROVALS.INDEX'),
      'home-observations': (currentObservation
        ? generateRoute('CHILDREN.CHILD.LEARNING_JOURNEY.OBSERVATIONS.HOME_OBSERVATIONS', {
          childId: currentObservation.child.id,
        })
        : generateRoute('LEARNING.OBSERVATIONS')
      ),
    }

    if (pathname === generateRoute('APPROVALS.OBSERVATIONS.REVIEW', { observationId })) {
      return generateRoute('APPROVALS.OBSERVATIONS')
    }

    return PATHNAMES_MAP[ref] || generateRoute('LEARNING.OBSERVATIONS')
  }

  onReviewSuccess = () => {
    const { canApproveObservation, location, navigate } = this.props
    const { eventLogContext } = this.state
    const { query } = location

    if (canApproveObservation) {
      logEvent(EVENTS.OBSERVATION_APPROVED, { context: eventLogContext })
    }

    navigate({
      pathname: this.getApprovalRedirectionPathname(query.ref),
      query: this.getApprovalRedirectionQuery(query),
    })
  }

  onReviewFail = (error) => {
    const { modalActions, modalConsts } = this.props

    if (error?.response) {
      const { code, message } = error.response

      if (400 === code) {
        modalActions.show(modalConsts.TYPES.ALERT, {
          text: i18n.t('module:Learning:Observations:ObservationsReview:reviewFailMessage'),
        })

        return
      }

      modalActions.show(modalConsts.TYPES.ALERT, {
        text: message,
      })
    }
  }

  reviewRequest = () => {
    const { canApproveObservation, observationsActions, params } = this.props
    const { observationId } = params

    const body = canApproveObservation
      ? { status: OBSERVATION_STATUSES.APPROVED.value }
      : { completedAt: new Date(), status: OBSERVATION_STATUSES.PENDING_APPROVAL.value }

    return observationsActions.update({
      body,
      onFailed: this.onReviewFail,
      onSuccess: this.onReviewSuccess,
      params: [observationId, {}],
    })
  }

  showMissingGoldenMomentModal = () => {
    const { modalActions, modalConsts } = this.props

    const content = (
      <div>
        <MinimalModalBox.TopIcon
          color={colors.gold}
          height={50}
          icon="stars"
        />
        <p>
          {i18n.t('module:Learning:Observations:ObservationsReview:MissingGoldenMoment:1')}
          {' '}
          <strong>
            {i18n.t('module:Learning:Observations:ObservationsReview:MissingGoldenMoment:2')}
          </strong>
          .
        </p>
        <p>
          {i18n.t('module:Learning:Observations:ObservationsReview:MissingGoldenMoment:3')}
        </p>
      </div>
    )

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: _.upperFirst(i18n.t('global:approve')),
      content,
      onConfirm: this.reviewRequest,
    })
  }

  showGoldenMomentModal = () => {
    const { modalActions, modalConsts } = this.props

    const content = (
      <React.Fragment>
        <MinimalModalBox.TopIcon
          color={colors.gold}
          height={50}
          icon="stars"
        />
        <p>
          {i18n.t('module:Learning:Observations:ObservationsReview:ShowGoldenMoment:1')}
          {' '}
          <strong>
            {i18n.t('module:Learning:Observations:ObservationsReview:ShowGoldenMoment:2')}
          </strong>
          .
        </p>
        <p>
          {i18n.t('module:Learning:Observations:ObservationsReview:ShowGoldenMoment:3')}
        </p>
      </React.Fragment>
    )

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: _.upperFirst(i18n.t('global:approve')),
      content,
      onConfirm: this.reviewRequest,
    })
  }

  handleApproveButtonClick = () => {
    const { canApproveObservation, observation, shareObservationWithParents } = this.props
    const { eventLogContext } = this.state
    const { childObservations } = observation || {}

    logEvent(EVENTS.OBSERVATION_REVIEW_BTN_CLICKED, { context: eventLogContext })

    if (shareObservationWithParents) {
      const hasMissingGoldenMomentObservations = !!_.reject(
        childObservations,
        ({ sharedWithParents }) => sharedWithParents,
      ).length

      if (hasMissingGoldenMomentObservations && canApproveObservation) {
        this.showMissingGoldenMomentModal()

        return
      }
    } else {
      const hasGoldenMomentObservations = !!_.filter(
        childObservations,
        ({ sharedWithParents }) => sharedWithParents,
      ).length

      if (hasGoldenMomentObservations && canApproveObservation) {
        this.showGoldenMomentModal()

        return
      }
    }

    this.reviewRequest()
  }

  handleCheckProcessingFiles = async () => {
    const { observationsActions, params } = this.props
    const { observationId } = params

    await observationsActions.get({
      params: [observationId, {
        groups: OBSERVATION_GROUPS,
      }],
    })

    return null
  }

  render() {
    const {
      canApproveObservation,
      canEditObservation,
      canSendObservationForApproval,
      errorMessages,
      frameworksList,
      hasParentAppAccess,
      isFetching,
      isSubmitting,
      location,
      observation,
      progressLevels,
    } = this.props
    const { activeChildIndex, pageType } = this.state

    return (
      <ObservationsReviewView
        activeChildIndex={activeChildIndex}
        canApproveObservation={canApproveObservation}
        canEditObservation={canEditObservation}
        canSendObservationForApproval={canSendObservationForApproval}
        errorMessages={errorMessages}
        frameworksList={frameworksList}
        hasParentAppAccess={hasParentAppAccess}
        isEmpty={this.checkObservationIsEmpty()}
        isFetching={isFetching}
        isSubmitting={isSubmitting}
        location={location}
        observation={observation}
        pageType={pageType}
        progressLevels={progressLevels}
        onApproveButtonClick={this.handleApproveButtonClick}
        onCheckProcessingFiles={this.handleCheckProcessingFiles}
        onClickSlide={this.handleClickSlide}
        onRemoveObservation={this.handleRemoveObservation}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  frameworkProgressLevelsListState,
  frameworkProgressLevelsSelectors,
  frameworksListState,
  frameworksSelectors,
  nurseriesSelectors,
  observationsSelectors,
  observationsSingleState,
  params,
  securitySelectors,
  uploadSelectors,
}) => ({
  canApproveObservation: observationsSelectors.canApproveObservationSelector(state),
  canEditObservation: (observation) => observationsSelectors.canEditObservation(observation, state),
  canSendObservationForApproval: observationsSelectors.canSendObservationForApprovalSelector(state),
  errorMessages: appSelectors.getErrorMessages(observationsSingleState),
  frameworksList: frameworksSelectors.getFilteredFrameworksRawList(FRAMEWORK_STRUCTURE_APPLICABLE.OBSERVATION)(state),
  getFiles: (reference) => uploadSelectors.getFilesByReference(reference)(state),
  hasParentAppAccess: securitySelectors.hasParentAppAccessSelector(state),
  isFetching: appSelectors.getIsFetching(
    frameworksListState,
    frameworkProgressLevelsListState,
    observationsSingleState,
  ),
  isSubmitting: appSelectors.getIsSubmitting(observationsSingleState),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  observation: observationsSelectors.getObservationsSingleStateDataSelector(state),
  progressLevels: frameworkProgressLevelsSelectors.getFrameworkProgressLevels(state),
  shareObservationWithParents: nurseriesSelectors.getShareObservationWithParents(state),
})

const enhance = compose(
  withAppService,
  withFrameworksService,
  withFrameworkProgressLevelsService,
  withModalService,
  withNurseriesService,
  withObservationsService,
  withRouter,
  withSecurityService,
  withUploadService,
  connect(mapState),
)

export default enhance(ObservationsReviewContainer)

