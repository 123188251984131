import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { Form } from 'components'

import { isRequired } from 'utils/fieldValidation'

const InvoiceDateSettingsForm = ({
  globalId,
  handleSubmit,
  hideGlobalOption,
  id,
  isGlobalOptionOn,
  onGlobalOptionClick,
  onSubmit,
}) => (
  <Form flex={1} onSubmit={handleSubmit(onSubmit)}>
    {!hideGlobalOption && (
      <Form.Row>
        <Form.Row.Item>
          <Field
            component={Form.Switch}
            name={globalId}
            onChange={onGlobalOptionClick}
          />
        </Form.Row.Item>
        <Form.Row.Item>
          Use global settings
        </Form.Row.Item>
      </Form.Row>
    )}
    <Form.Row>
      <Form.Row.Item>
        <Field
          component={Form.DatePicker}
          disabled={isGlobalOptionOn}
          name={id}
          validate={isRequired}
          clearable
          local
        />
      </Form.Row.Item>
    </Form.Row>
  </Form>
)

export default reduxForm()(InvoiceDateSettingsForm)
