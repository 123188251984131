import styled from 'styled-components'

import layout from 'constants/layout'

export const StyledColumnContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 25px;
  
  @media(max-width: ${layout.mobileWidthResolution}px) {
    grid-template-columns: auto;
  }
`

export const StyledContainerForm = styled.div`
  padding-top: 10px;
  margin-bottom: 20px;
`
