import _ from 'lodash'

import React from 'react'

import colors from 'constants/colors'

import { generateRoute } from 'utils/routing'

import * as OccupancyConstants from 'services/legacy/occupancy/constants'

import { Avatar, DataTable, Icon } from 'components'

import {
  StyledAbsoluteContainer,
  StyledAbsoluteContent,
  StyledError,
  StyledExpandableCell,
  StyledHeaderLabel,
  StyledNurseryDetails,
  StyledNurseryDetailsItem,
} from './ReportHeaderStyled'

const ReportHeader = ({
  expandedRooms,
  filters,
  isOverviewReport,
  isStaffingEnabled,
  onChildrenRowClick,
  reportChart,
  reportData,
  rooms,
}) => {
  const { absences, breakdown, extraSessions, holidays, type } = filters
  const renderWeeklyHeader = (roomId, children, isExpanded) => (
    <React.Fragment>
      <DataTable.Row>
        <StyledExpandableCell onClick={() => onChildrenRowClick(roomId)}>
          <div>
            Children
          </div>
          <Icon
            color={colors.gray}
            height={20}
            icon={isExpanded ? 'chevron-down' : 'chevron-up'}
          />
        </StyledExpandableCell>
      </DataTable.Row>
      {isExpanded && (
        <React.Fragment>
          {_.map(children, ({ firstName, id, photo, rowHeight, surname }) => (
            <DataTable.Row
              height={breakdown === OccupancyConstants.OCCUPANCY_REQUEST_BREAKDOWN.AM_PM && rowHeight}
              width={180}
            >
              <Avatar
                avatarSize="small"
                borderWidth={1}
                initials={[firstName, surname]}
                src={photo}
                title={`${firstName} ${surname}`}
                to={generateRoute('CHILDREN.CHILD.ABOUT.PROFILE', { childId: id })}
              />
            </DataTable.Row>
          ))}
          <DataTable.Row>
            <StyledHeaderLabel gray>
              Predicted
            </StyledHeaderLabel>
          </DataTable.Row>
          {absences && (
            <DataTable.Row>
              Absences
            </DataTable.Row>
          )}
          {holidays && (
            <DataTable.Row>
              Holidays
            </DataTable.Row>
          )}
          {extraSessions && (
            <DataTable.Row>
              Extra sessions
            </DataTable.Row>
          )}
        </React.Fragment>
      )}
      <DataTable.Row>
        Available capacity
      </DataTable.Row>
      {!isStaffingEnabled && (
        <DataTable.Row>
          Staff required
        </DataTable.Row>
      )}
      <DataTable.Row marginBottom>
        FTE %
      </DataTable.Row>
    </React.Fragment>
  )

  const renderMonthlyHeader = () => (
    <DataTable.Row marginBottom>
      FTE %
    </DataTable.Row>
  )

  const renderYearlyHeader = () => (
    <DataTable.Row marginBottom>
      FTE %
    </DataTable.Row>
  )

  const renderHeadHeader = () => (
    <React.Fragment>
      <DataTable.Row noBorder stickyElement />
      {type !== OccupancyConstants.OCCUPANCY_REQUEST_TYPE.YEARLY && (
        <DataTable.Row noBorder stickyElement />
      )}
    </React.Fragment>
  )

  const renderSummaryHeader = () => {
    const { data } = reportChart

    if (
      type === OccupancyConstants.OCCUPANCY_REQUEST_TYPE.WEEKLY
      || isOverviewReport
      || !data?.length
    ) {
      return null
    }

    return (
      <React.Fragment>
        <DataTable.Row quinary>
          <strong>
            {type === OccupancyConstants.OCCUPANCY_REQUEST_TYPE.MONTHLY && 'Monthly Breakdown'}
            {type === OccupancyConstants.OCCUPANCY_REQUEST_TYPE.YEARLY && 'Yearly Breakdown'}
          </strong>
        </DataTable.Row>
        <DataTable.Row marginBottom />
      </React.Fragment>
    )
  }

  const renderNurseryClassHead = (room) => {
    const data = reportData[room.id]

    const { children, error, nurseryClass, validation } = data
    const { id: nurseryClassId } = nurseryClass

    const isExpanded = expandedRooms[nurseryClass.id]

    const renderNurseryDetails = () => {
      const renderDetails = (
        <StyledNurseryDetails>
          <StyledNurseryDetailsItem>
            {`Capacity: ${nurseryClass.capacity}`}
          </StyledNurseryDetailsItem>
          {!isStaffingEnabled && (
            <StyledNurseryDetailsItem>
              {`Ratio: 1:${nurseryClass.ratio}`}
            </StyledNurseryDetailsItem>
          )}
        </StyledNurseryDetails>
      )

      const renderErrorMessage = _.map(validation, (message) => (
        <StyledError key={message}>
          {message}
        </StyledError>
      ))

      return (
        <StyledAbsoluteContainer>
          <strong>
            {nurseryClass.name}
          </strong>
          <StyledAbsoluteContent column={error}>
            {error ? renderErrorMessage : renderDetails}
          </StyledAbsoluteContent>
        </StyledAbsoluteContainer>
      )
    }

    if (error) {
      return (
        <React.Fragment key={`errorClassHead_${nurseryClassId}`}>
          <DataTable.Row quinary>
            {renderNurseryDetails()}
          </DataTable.Row>
          <DataTable.Row marginBottom />
        </React.Fragment>
      )
    }

    return (
      <React.Fragment key={`classHead_${nurseryClassId}`}>
        <DataTable.Row quinary>
          {renderNurseryDetails()}
        </DataTable.Row>
        {type === OccupancyConstants.OCCUPANCY_REQUEST_TYPE.WEEKLY
          && renderWeeklyHeader(nurseryClass.id, children, isExpanded)}
        {type === OccupancyConstants.OCCUPANCY_REQUEST_TYPE.MONTHLY && renderMonthlyHeader()}
        {type === OccupancyConstants.OCCUPANCY_REQUEST_TYPE.YEARLY && renderYearlyHeader()}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {renderHeadHeader()}
      {renderSummaryHeader()}
      {_.map(rooms, renderNurseryClassHead)}
    </React.Fragment>
  )
}

export default ReportHeader
