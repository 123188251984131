import React from 'react'

import { generateRoute } from 'utils/routing'

import { Callout, Page, Spinner } from 'components'

import i18n from 'translations'

import OrganisationsAddForm from './components/OrganisationsAddForm'

const OrganisationsAddView = ({
  errorMessages,
  financeLocked,
  formValues,
  initialValues,
  isCreating,
  isFetching,
  isOrganisationArchived,
  isSubmitting,
  onDisableClick,
  onFeatureFlagChange,
  onNameChange,
  onSubmit,
}) => {
  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    return (
      <OrganisationsAddForm
        cancelButtonLink={generateRoute('ORGANISATIONS.INDEX')}
        financeLocked={financeLocked}
        formValues={formValues}
        initialValues={initialValues}
        isCreating={isCreating}
        isOrganisationArchived={isOrganisationArchived}
        isSubmitting={isSubmitting}
        onDisableClick={onDisableClick}
        onFeatureFlagChange={onFeatureFlagChange}
        onNameChange={onNameChange}
        onSubmit={onSubmit}
      />
    )
  }

  return (
    <Page.Section
      isLoading={isFetching}
      title={isCreating
        ? i18n.t('module:Organisations:Add:title')
        : i18n.t('module:Organisations:Edit:title')}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default OrganisationsAddView
