import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { StyledRowContainer } from 'components/Form/helpers/FormRow/FormRowStyled'
import { StyledContentContainer, StyledRightContainer } from 'components/Field/InlineEdit/InlineEditStyled'

export const StyledContainer = styled.div`
  background: ${NEUTRAL_COLOURS.GRAY_QUINARY};
  height: 100%;
`

export const StyledMainContent = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto auto;
  grid-column-gap: 10px;
  background: ${NEUTRAL_COLOURS.GRAY_QUINARY};
  padding: 24px 25px;
`

export const StyledComment = styled.div`
  padding: 16px 0;
  word-break: normal;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
`

export const StyledAchievedForm = styled.div`
  margin: 3px 0;

  ${StyledRowContainer} {
    margin: 0;
  }
  
  ${StyledRightContainer} {
    height: 40px;
    align-items: center;
  }
  
  ${StyledContentContainer} {
    margin-top: 0;
  }
`

export const StyledObservationDetails = styled.div`
  display: flex;
  align-items: flex-end;
`

export const StyledAvatar = styled.div`
  height: 100%;
  width: 100%;
  padding: 7px 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

export const StyledFrameworkAreas = styled.div`
  margin-top: -3px;
  padding-bottom: 21px;
`

export const StyledToolbar = styled.div`
  margin-bottom: -20px;

  ${({ isDateAchievedEditMode }) => isDateAchievedEditMode && `
    margin: -3px 0 -33px;
  `}
`
