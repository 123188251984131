import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'

export interface withCommentsServiceProps {
  commentsActions: typeof actions
}

const withCommentsService = <P extends withCommentsServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapDispatch = injectActions('commentsActions', actions)

  const Component = ({ commentsActions, ...others }) => (
    <WrappedComponent
      commentsActions={commentsActions}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(null, mapDispatch)(Component)
}

export default withCommentsService
