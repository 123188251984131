import {
  CLEAR_NEWSLETTER_RECIPIENT_SINGLE,
  CREATE_NEWSLETTER_RECIPIENT,
  CREATE_NEWSLETTER_RECIPIENT_FAILED,
  CREATE_NEWSLETTER_RECIPIENT_SUCCESS,
  GET_NEWSLETTER_RECIPIENT,
  GET_NEWSLETTER_RECIPIENT_FAILED,
  GET_NEWSLETTER_RECIPIENT_SUCCESS,
  UPDATE_NEWSLETTER_RECIPIENT,
  UPDATE_NEWSLETTER_RECIPIENT_FAILED,
  UPDATE_NEWSLETTER_RECIPIENT_SUCCESS,
} from './constants'

export const create = (
  body,
  params,
  onSuccess = () => { },
) => ({ newsletterRecipientsApiClient }) => (dispatch) => {
  dispatch({
    type: CREATE_NEWSLETTER_RECIPIENT,
  })

  newsletterRecipientsApiClient.create(body, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: CREATE_NEWSLETTER_RECIPIENT_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => dispatch({
      error: response,
      type: CREATE_NEWSLETTER_RECIPIENT_FAILED,
    }),
  )
}

export const get = (id, params, onSuccess = () => {}) => ({ newsletterRecipientsApiClient }) => (dispatch) => {
  dispatch({
    type: GET_NEWSLETTER_RECIPIENT,
  })

  newsletterRecipientsApiClient.get(id, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: GET_NEWSLETTER_RECIPIENT_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => dispatch({
      error: response,
      type: GET_NEWSLETTER_RECIPIENT_FAILED,
    }),
  )
}

export const update = (id, payload, params, onSuccess = () => {}, onFailed = () => {}) => ({
  newsletterRecipientsApiClient,
}) => (dispatch) => {
  dispatch({
    id,
    params,
    payload,
    type: UPDATE_NEWSLETTER_RECIPIENT,
  })

  newsletterRecipientsApiClient.update(id, payload, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: UPDATE_NEWSLETTER_RECIPIENT_SUCCESS,
      })

      onSuccess()
    },
    (error) => {
      dispatch({
        error: error.response,
        type: UPDATE_NEWSLETTER_RECIPIENT_FAILED,
      })

      onFailed()
    },
  )
}

export const clearSingle = () => ({
  type: CLEAR_NEWSLETTER_RECIPIENT_SINGLE,
})
