import { combineReducers } from 'redux'

import currencies, { NurseryIntegrationsCurrenciesState } from './currencies/reducer'
import list, { NurseryIntegrationsListState } from './list/reducer'
import single, { NurseryIntegrationsSingleState } from './single/reducer'
import statistics, { NurseryIntegrationsStatisticsState } from './statistics/reducer'
import stripe, { NurseryIntegrationsStripeState } from './stripe/reducer'
import failedRecurringPayment, {
  NurseryIntegrationsFailedRecurringPaymentState,
} from './failedRecurringPayment/reducer'
import payoutTransactions, { NurseryIntegrationsPayoutTransactionsState } from './payoutTransactions/reducer'
import activeRecurringPayment, {
  NurseryIntegrationsActiveRecurringPaymentState,
} from './activeRecurringPayment/reducer'

export default combineReducers({
  activeRecurringPayment,
  currencies,
  failedRecurringPayment,
  list,
  payoutTransactions,
  single,
  statistics,
  stripe,
})

export interface NurseryIntegrationsState {
  activeRecurringPayment: NurseryIntegrationsActiveRecurringPaymentState
  currencies: NurseryIntegrationsCurrenciesState
  failedRecurringPayment: NurseryIntegrationsFailedRecurringPaymentState
  list: NurseryIntegrationsListState
  payoutTransactions: NurseryIntegrationsPayoutTransactionsState
  single: NurseryIntegrationsSingleState
  statistics: NurseryIntegrationsStatisticsState
  stripe: NurseryIntegrationsStripeState
}
