import { compose } from 'recompose'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { ROLES } from 'constants/security'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import { withShellService } from 'services/shell'
import { withRouter } from 'services/router'

import i18n from 'translations'

import ManagementGeneralSettings from 'module/Management/ManagementGeneralSettings'
import ManagementBlossomboard from 'module/Management/ManagementBlossomboard'
import ManagementGettingStarted from 'module/Management/ManagementGettingStarted'
import ManagementNursery from 'module/Management/ManagementNursery'
import ManagementLeaveTypes from 'module/Management/ManagementLeaveTypes'
import ManagementParentApp from 'module/Management/ManagementParentApp'

import ManagementWrapperView from './ManagementWrapperView'
import ManagementFinanceSettings from './ManagementFinanceSettings'
import ManagementMenuItemsWrapper from './ManagementMenuItems/ManagementMenuItemsWrapper'
import ManagementEnquiriesSettingsWrapper from './EnquiriesSettings/EnquiriesSettingsWrapper'
import ManagementLearningWrapper from './ManagementLearning/ManagementLearningWrapper'
import AttendancePeriods from './AttendancePeriods'
import SecurityWrapper from './Security/SecurityWrapper'
import ClosureDays from './ClosureDays'

class ManagementWrapperContainer extends Component {
  componentDidMount() {
    const {
      AttendancePeriodsGranted,
      ManagementBlossomboardGranted,
      ManagementClosureDaysGranted,
      ManagementEnquiriesSettingsGranted,
      ManagementFinanceSettingsGranted,
      ManagementGeneralSettingsGranted,
      ManagementGettingStartedGranted,
      ManagementLearningGranted,
      ManagementLeaveTypesGranted,
      ManagementMenuItemsGranted,
      ManagementNurseryGranted,
      ManagementParentAppGranted,
      SecurityGranted,
      shellActions,
    } = this.props

    shellActions.setSubMenu([
      {
        hidden: !ManagementGettingStartedGranted,
        icon: 'getting-started',
        label: i18n.t('module:Management:GettingStarted:title'),
        to: generateRoute('MANAGEMENT.GETTING_STARTED'),
      },
      {
        hidden: !ManagementBlossomboardGranted,
        icon: 'blossomboard',
        label: i18n.t('module:Management:ManagementWrapper:Blossomboard'),
        to: generateRoute('MANAGEMENT.BLOSSOMBOARD'),
      },
      {
        hidden: !ManagementNurseryGranted,
        icon: 'nursery',
        label: i18n.t('module:Management:Nursery:nurseryInfo'),
        to: generateRoute('MANAGEMENT.NURSERY'),
      },
      {
        hidden: !ManagementClosureDaysGranted,
        icon: 'blocked',
        label: i18n.t('module:Management:ClosureDays:title'),
        to: generateRoute('MANAGEMENT.CLOSURE_DAYS'),
      },
      {
        hidden: !AttendancePeriodsGranted,
        icon: 'calendar',
        label: i18n.t('module:Management:AttendancePeriods:title'),
        to: generateRoute('MANAGEMENT.ATTENDANCE_PERIODS'),
      },
      {
        hidden: !ManagementFinanceSettingsGranted,
        icon: 'finance',
        label: i18n.t('module:Management:Finance:title'),
        to: generateRoute('MANAGEMENT.FINANCE_SETTINGS'),
      },
      {
        hidden: !ManagementLeaveTypesGranted,
        icon: 'staff',
        label: i18n.t('module:Management:LeaveTypes:title'),
        to: generateRoute('MANAGEMENT.LEAVE_TYPES'),
      },
      {
        hidden: !ManagementParentAppGranted,
        icon: 'parent',
        label: i18n.t('module:Management:ParentApp:Title:short'),
        to: generateRoute('MANAGEMENT.PARENT_APP'),
      },
      {
        hidden: !ManagementEnquiriesSettingsGranted,
        icon: 'enquiries',
        label: i18n.t('module:Management:Enquiries:shortTitle'),
        to: generateRoute('MANAGEMENT.ENQUIRIES_SETTINGS'),
      },
      {
        hidden: !ManagementMenuItemsGranted,
        icon: 'meals',
        label: i18n.t('module:Management:MenuItems:title'),
        to: generateRoute('MANAGEMENT.MENU_ITEMS'),
      },
      {
        hidden: !ManagementGeneralSettingsGranted,
        icon: 'general-settings',
        label: i18n.t('module:Management:General:title'),
        to: generateRoute('MANAGEMENT.GENERAL_SETTINGS'),
      },
      {
        hidden: !ManagementLearningGranted,
        icon: 'learning',
        label: i18n.t('module:Management:Learning:Title:short'),
        to: generateRoute('MANAGEMENT.LEARNING'),
      },
      {
        hidden: !SecurityGranted,
        icon: 'security',
        label: i18n.t('module:Management:Security:title'),
        to: generateRoute('MANAGEMENT.SECURITY.INDEX'),
      },
    ])
  }

  shouldComponentUpdate(nextProps) {
    const { ManagementGettingStartedGranted, location: { pathname }, navigate } = nextProps

    if (generateRoute('MANAGEMENT.INDEX') === pathname) {
      if (ManagementGettingStartedGranted) {
        navigate(generateRoute('MANAGEMENT.GETTING_STARTED'))
      } else {
        navigate(generateRoute('MANAGEMENT.BLOSSOMBOARD'))
      }
    }

    return true
  }

  componentWillUnmount() {
    const { shellActions } = this.props

    shellActions.clearSubMenu()
  }

  render() {
    const { children } = this.props

    return (
      <ManagementWrapperView>
        {children}
      </ManagementWrapperView>
    )
  }
}

ManagementWrapperContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.ROOM_LEADER,
    ROLES.SENIOR_TEACHER,
    ROLES.TEACHER,
  ],
}

const mapState = (state) => ({
  AttendancePeriodsGranted: auth.SELECTORS.getComponentIsAuthorised(state, AttendancePeriods),
  ManagementBlossomboardGranted: auth.SELECTORS.getComponentIsAuthorised(state, ManagementBlossomboard),
  ManagementClosureDaysGranted: auth.SELECTORS.getComponentIsAuthorised(state, ClosureDays),
  ManagementEnquiriesSettingsGranted: auth.SELECTORS.getComponentIsAuthorised(
    state,
    ManagementEnquiriesSettingsWrapper,
  ),
  ManagementFinanceSettingsGranted: auth.SELECTORS.getComponentIsAuthorised(state, ManagementFinanceSettings),
  ManagementGeneralSettingsGranted: auth.SELECTORS.getComponentIsAuthorised(state, ManagementGeneralSettings),
  ManagementGettingStartedGranted: auth.SELECTORS.getComponentIsAuthorised(state, ManagementGettingStarted),
  ManagementLearningGranted: auth.SELECTORS.getComponentIsAuthorised(state, ManagementLearningWrapper),
  ManagementLeaveTypesGranted: auth.SELECTORS.getComponentIsAuthorised(state, ManagementLeaveTypes),
  ManagementMenuItemsGranted: auth.SELECTORS.getComponentIsAuthorised(state, ManagementMenuItemsWrapper),
  ManagementNurseryGranted: auth.SELECTORS.getComponentIsAuthorised(state, ManagementNursery),
  ManagementParentAppGranted: auth.SELECTORS.getComponentIsAuthorised(state, ManagementParentApp),
  SecurityGranted: auth.SELECTORS.getComponentIsAuthorised(state, SecurityWrapper),
})

const enhance = compose(
  withRouter,
  withShellService,
  connect(mapState),
)

export default enhance(ManagementWrapperContainer)
