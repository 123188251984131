import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Callout, Spinner, Typography } from 'components'
import Settings from 'module/Settings/components'

import i18n from 'translations'

import { InitialValuesResponseProps } from './helpers'

interface SettingsInvoicesV3EditItemAnnualisedViewProps {
  cancelLink?: string
  errorMessages?: any[]
  initialValues?: InitialValuesResponseProps
  isFormLoading?: boolean
  isLoading?: boolean
  nurseryName?: string
  onSubmit?: (fields) => void
}

const SettingsInvoicesV3EditItemAnnualisedView: React.FC<SettingsInvoicesV3EditItemAnnualisedViewProps> = ({
  cancelLink,
  errorMessages,
  initialValues,
  isFormLoading,
  isLoading,
  nurseryName,
  onSubmit,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    const title = (
      <span>
        {i18n.t('module:Settings:Invoice:Annualized:title')}
        {' '}
        <Typography color={NEUTRAL_COLOURS.BLUE} fontSize="inherit" variant="span" bold>
          {nurseryName}
        </Typography>
      </span>
    )

    return (
      <Settings.InvoiceAnnualisedSettingForm
        cancelLink={cancelLink}
        initialValues={initialValues}
        isLoading={isFormLoading}
        title={title}
        onSubmit={onSubmit}
      />
    )
  }

  return (
    <React.Fragment>
      <Callout content={errorMessages} error />
      {renderContent()}
    </React.Fragment>
  )
}

export default SettingsInvoicesV3EditItemAnnualisedView
