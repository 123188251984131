import React, { PropsWithChildren } from 'react'

import { ORDER, SORT_ORDER } from 'constants/global'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { Icon } from 'components'

import { StyledSortableWrapper } from './DataTableStyled'

interface DataTableSortableProps {
  currentField: string
  currentOrder: ORDER
  name: string
  onChange: (name: string) => void
  sortKey?: string
}

const DataTableSortable: React.FC<PropsWithChildren<DataTableSortableProps>> = ({
  children,
  currentField,
  currentOrder,
  name,
  onChange,
  sortKey,
}) => {
  let icon = 'sort'

  if (currentField === (sortKey || name)) {
    icon = (currentOrder === SORT_ORDER.DESC) ? 'arrow-down' : 'arrow-up'
  }

  return (
    <StyledSortableWrapper>
      <div>
        {children}
      </div>
      <Icon
        color={NEUTRAL_COLOURS.GRAY}
        height={14}
        icon={icon as any}
        onClick={() => onChange(sortKey || name)}
      />
    </StyledSortableWrapper>
  )
}

export default DataTableSortable

