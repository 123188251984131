import styled from 'styled-components'

import colors, { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledRoomMove = styled.div`
  border-bottom: 1px dashed ${NEUTRAL_COLOURS.SILVER};

  @media(max-width: 600px) {
    padding-left: 10px;
  }
`

export const StyledFieldContainer = styled.div`
  transform: translateX(-10px);
  margin-top: -10px;
`

export const StyledMonthsRange = styled.p`
  margin: 6px 0 0 0;
`

export const StyledColumn = styled.div`
  padding-top: 10px;
  min-height: 65px;
  margin: -10px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const StyledParagraph = styled.p`
  margin: 0;
  margin-top: 5px;
  
  ${({ noMargin }) => noMargin && `
    margin: 0;
  `}
  
  ${({ invalid }) => invalid && `
    color: ${colors.remove}
  `}
`
