import React, { useState } from 'react'

import { SIGNATURE_COLLECTING_TYPES } from 'services/legacy/injuries/constants'

import { Button, Toolbar, Typography } from 'components'

import i18n from 'translations'

const SignatureCollectionOption = ({
  disableSignature,
  injurySignaturesPreview,
  isSubmitting,
  onChange,
}) => {
  const { signatureCollectingType } = injurySignaturesPreview || {}

  const [collectingType, setCollectingType] = useState(signatureCollectingType)

  const handleChange = (value) => {
    setCollectingType(value)

    onChange(value)
  }

  return (
    <React.Fragment>
      <Typography margin="0 0 5px" variant="h5">
        {i18n.t('module:Injury:Preview:Signatures:CollectingOption:title')}
      </Typography>
      <Typography fontSize={16} margin="0 0 30px">
        {i18n.t('module:Injury:Preview:Signatures:CollectingOption:subtitle')}
      </Typography>
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <Button.Big
              disabled={disableSignature}
              icon="sign-paper"
              iconHeight={100}
              isSelected={collectingType === SIGNATURE_COLLECTING_TYPES.ON_PAPER}
              isSubmitting={isSubmitting}
              label={i18n.t('module:Injury:Preview:Signatures:CollectingOption:onPaperLabel')}
              value={SIGNATURE_COLLECTING_TYPES.ON_PAPER}
              onChange={handleChange}
            />
          </Toolbar.Item>
          <Toolbar.Item>
            <Button.Big
              disabled={disableSignature}
              icon="sign-digital"
              iconHeight={130}
              isSelected={collectingType === SIGNATURE_COLLECTING_TYPES.DIGITALLY}
              isSubmitting={isSubmitting}
              label={i18n.t('module:Injury:Preview:Signatures:CollectingOption:digitalLabel')}
              value={SIGNATURE_COLLECTING_TYPES.DIGITALLY}
              onChange={handleChange}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
    </React.Fragment>
  )
}

export default SignatureCollectionOption
