import { Link } from 'react-router'

import styled from 'styled-components'

import colors from 'constants/colors'

export const StyledItemWrapper = styled.div`
  padding: 10px 5px;
`

export const StyledItemContainer = styled.div`
  border-radius: 10px;
  height: 80px;
  background: #F5F5F5;
  padding: 15px 0 15px 15px;
  box-shadow: 0 3px 9px 0 rgba(0,0,0,.2);
`

export const StyledItemGrid = styled.div`
  display: grid;
  grid-template-columns: auto 200px 100px;
  height: 100%;

  @media(max-width: 685px) {
    grid-template-columns: auto 100px 70px;
  }

  @media(max-width: 400px) {
    grid-template-columns: auto 90px 55px;
  }
`

export const StyledRightItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px dotted ${colors.lightGray};
`

export const StyledContentContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  padding-right: 10px;
`

export const StyledContentBottomContainer = styled.div`
  display: flex;
  align-items: flex-end;
`

export const StyledLink = styled(Link)`
  color: inherit;
  overflow: hidden;

  &> h6 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  &:hover {
    color: inherit;
  }
`
