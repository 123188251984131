import {
  CLEAR_MEMBERSHIP,
  CREATE_MEMBERSHIP,
  CREATE_MEMBERSHIP_FAILED,
  CREATE_MEMBERSHIP_SUCCESS,
  GET_MEMBERSHIP,
  GET_MEMBERSHIP_FAILED,
  GET_MEMBERSHIP_SUCCESS,
  SET_SELECTED_MEMBERSHIP,
  UPDATE_MEMBERSHIP,
  UPDATE_MEMBERSHIP_FAILED,
  UPDATE_MEMBERSHIP_PASSWORD,
  UPDATE_MEMBERSHIP_PASSWORD_FAILED,
  UPDATE_MEMBERSHIP_PASSWORD_SUCCESS,
  UPDATE_MEMBERSHIP_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case GET_MEMBERSHIP:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_MEMBERSHIP_SUCCESS:
      return { ...state, data: payload.data, isFetching: false }
    case GET_MEMBERSHIP_FAILED:
      return { ...state, error, isFetching: false }
    case CREATE_MEMBERSHIP:
    case UPDATE_MEMBERSHIP:
    case UPDATE_MEMBERSHIP_PASSWORD:
      return { ...state, error: initialState.error, isSubmitting: true }
    case CREATE_MEMBERSHIP_SUCCESS:
    case UPDATE_MEMBERSHIP_SUCCESS:
    case UPDATE_MEMBERSHIP_PASSWORD_SUCCESS:
      return { ...state, data: payload.data, isSubmitting: false }
    case CREATE_MEMBERSHIP_FAILED:
    case UPDATE_MEMBERSHIP_FAILED:
    case UPDATE_MEMBERSHIP_PASSWORD_FAILED:
      return { ...state, error, isSubmitting: false }
    case SET_SELECTED_MEMBERSHIP:
      return { ...state, data: payload }
    case CLEAR_MEMBERSHIP:
      return initialState
    default:
      return state
  }
}
