import _ from 'lodash'

import React from 'react'

import { getBrandingColor } from 'utils/branding'
import { generateRoute } from 'utils/routing'

import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

import {
  AmountSummary,
  Banner,
  Callout,
  DatePicker,
  EmptyState,
  Pagination,
  Section,
  Select,
  Spinner,
  Table,
  Toolbar,
} from 'components'
import CreditNoteIcon from 'module/Finance/components/CreditNoteIcon'

import i18n from 'translations'

const ChildFinanceDetailsInvoicesView = ({
  cannotBePaidOverTheParentApp,
  childFinanceDetails,
  childId,
  dateRange,
  errorMessages,
  invoices,
  isEmpty,
  isLoading,
  onDateChange,
  onPageChange,
  onSortChange,
  onStatusChange,
  onTypeChange,
  page,
  pageCount,
  perPage,
  sortField,
  sortOrder,
  status,
  statusOptions,
  totalResults,
  type,
  typeOptions,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderSummary = () => {
    if (!childFinanceDetails) {
      return null
    }

    const { balance, totalBadDebt, totalInvoices } = childFinanceDetails

    return (
      <React.Fragment>
        <Toolbar>
          <Toolbar.Group>
            <Toolbar.Item>
              <AmountSummary
                amount={Math.abs(balance)}
                color={getBrandingColor('tertiary-color')}
                label="Total outstanding"
                suffix={0 < balance && ' CR'}
              />
            </Toolbar.Item>
            <Toolbar.Item>
              <AmountSummary
                amount={totalInvoices}
                label="Total billed to date"
                margin="0 0 0 15px"
              />
            </Toolbar.Item>
            {0 < totalBadDebt && (
              <Toolbar.Item>
                <AmountSummary
                  amount={totalBadDebt}
                  color={getBrandingColor('quaternary-color')}
                  label="Bad debt"
                  margin="0 0 0 15px"
                />
              </Toolbar.Item>
            )}
          </Toolbar.Group>
        </Toolbar>
        {cannotBePaidOverTheParentApp && (
          <SubdomainCurrencyProvider>
            {({ getFormattedCurrencyValue }) => (
              <Banner.Info>
                {i18n.t('module:Children:Child:Finance:Invoicing:cannotBePaidOverTheParentApp', {
                  value: getFormattedCurrencyValue(0.50),
                })}
              </Banner.Info>
            )}
          </SubdomainCurrencyProvider>
        )}
      </React.Fragment>
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (isEmpty) {
      return (
        <EmptyState
          icon="invoice"
          text1="There are no invoices."
          text2="A history of all invoices created for this parent account will show here."
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={[
            {
              field: 'issueDate',
              sortable: true,
              title: 'Date',
            },
            {
              field: 'number',
              sortable: true,
              title: 'Ref number',
            },
            {
              align: 'left',
              field: 'name',
              sortable: true,
              title: 'Title',
            },
            {
              field: 'total',
              sortable: true,
              title: 'Amount',
            },
            {
              field: 'status',
              sortable: true,
              title: 'Status',
            },
            {
              field: 'totalOutstanding',
              sortable: true,
              title: 'Unpaid Amount',
            },
            {
              align: 'center',
              field: 'rowToButton',
            },
          ]}
          data={invoices}
          sortField={sortField}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const actions = (
    <Section.Actions
      primary={[{
        label: 'Invoice',
        to: generateRoute('CHILDREN.CHILD.FINANCE.INVOICE.ADD', { childId }),
      }, {
        label: 'Payment',
        to: generateRoute('CHILDREN.CHILD.FINANCE.PAYMENT', { childId }),
      }]}
    />
  )

  const renderToolbar = () => (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <DatePicker
            value={dateRange}
            clearable
            range
            onChange={onDateChange}
          />
        </Toolbar.Item>
        <Toolbar.Item>
          <Select
            options={statusOptions}
            placeholder="Select status"
            value={status}
            onChange={onStatusChange}
          />
        </Toolbar.Item>
        <Toolbar.Item>
          <Select
            options={typeOptions}
            placeholder="Select"
            value={type}
            onChange={onTypeChange}
          />
        </Toolbar.Item>
        <Toolbar.Item>
          {_.find(invoices, (invoice) => invoice.containCreditNotes) && (
            <CreditNoteIcon
              label="Credit note linked"
            />
          )}
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )

  return (
    <Section actions={actions} title="Invoices">
      <Callout content={errorMessages} error />
      {renderSummary()}
      {renderToolbar()}
      {renderContent()}
    </Section>
  )
}

export default ChildFinanceDetailsInvoicesView
