import { QueryPropsV2 } from 'constants/service'

import { getQueryStringV2 } from 'utils/api'

import ServiceBase from 'services/ServiceBase'

class childBookingApiClient extends ServiceBase {
  list = (params: QueryPropsV2) => this.request({
    path: `/v2/booking/child_bookings?${getQueryStringV2(params)}`,
  })

  get = (id: string, params: QueryPropsV2) => this.request({
    path: `/v2/booking/child_bookings/${id}?${getQueryStringV2(params)}`,
  })

  create = (body) => this.request({
    body,
    method: 'POST',
    path: '/v2/booking/child_bookings',
  })

  update = (id: string, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/booking/child_bookings/${id}`,
  })

  remove = (id: string) => this.request({
    method: 'DELETE',
    path: `/v2/booking/child_bookings/${id}`,
  })

  preview = (id: string, date: string, params: QueryPropsV2) => this.request({
    path: `/v2/booking/child_bookings/${id}/preview/${date}?${getQueryStringV2(params)}`,
  })
}

export default childBookingApiClient
