import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { ROLES, RolesDetails } from 'constants/security'

import { generateRoute } from 'utils/routing'

import { EVENTS, identifyAuthenticated, logEvent } from 'analytics'

import { withAppService } from 'services/app'
import { withSubdomainService } from 'services/subdomain'

import withStaffRegisterWrapper from '../withStaffRegisterWrapper'
import AuthenticationView from './AuthenticationView'

export const MEMBERSHIP_REGISTERS_GROUP = {
  read: [
    'membershipRegisterEntry.nurseryClass',
    'nurseryClass',
    'membership.pin',
    'membership.details',
  ],
}

class AuthenticationContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formError: null,
    }
  }

  componentDidMount() {
    const { memberDetails } = this.props

    if (!memberDetails) {
      this.fetchSingle()
    }
  }

  fetchSingle = () => {
    const {
      location,
      membershipRegistersActions,
      membershipRegistersSelectors,
    } = this.props
    const { query } = location || {}
    const { staff } = query || {}

    const criteria = membershipRegistersSelectors.getMembershipRegistersListCriteria({
      endDate: moment(),
      id: staff,
      startDate: moment(),
    })

    membershipRegistersActions.list({
      onSuccess: this.handleListSuccess,
      params: {
        criteria,
        groups: MEMBERSHIP_REGISTERS_GROUP,
      },
    })
  }

  handleListSuccess = ({ data }) => {
    const { membershipsActions } = this.props
    const item = data.length ? data[0] : null

    if (item) {
      membershipsActions.setSelectedStaff(item)
    }
  }

  handleSubmit = (pin) => {
    const { authActions, location, subdomainData } = this.props
    const { query } = location || {}
    const { staff } = query || {}

    this.setState({ formError: null })

    authActions.pinLogin({
      membership: {
        id: parseInt(staff),
      },
      pin,
    }, {
      onFailed: (response) => {
        logEvent(EVENTS.STAFF_REGISTER_PIN_AUTHENTICATED_FAILED)

        this.handlePinFailed(response)
      },
      onSuccess: (res) => {
        const { profile } = res
        // TODO: hack until the me v2 match v1 rsponse
        const userProfile = {
          ...res,
          featureFlags: subdomainData.featureFlags,
          user: {
            ...profile.user,
            isAdminUser: profile.roles.includes(RolesDetails[ROLES.SUPER_ADMIN]),
          },
        }

        identifyAuthenticated(userProfile)

        logEvent(EVENTS.STAFF_REGISTER_PIN_AUTHENTICATED)

        this.redirectToDashboard()
      },
    })
  }

  handlePinFailed = (response) => {
    const { message } = response || {}

    this.setState({ formError: message })
  }

  redirectToDashboard = () => {
    const { location, navigate } = this.props
    const { query } = location || {}
    const { staff } = query || {}

    navigate(generateRoute('STAFF_REGISTER.DASHBOARD', { staffId: staff }))
  }

  render() {
    const { hasPin, isAuthFetching, isFetching, memberDetails } = this.props
    const { formError } = this.state

    return (
      <AuthenticationView
        formError={formError}
        hasPin={hasPin}
        isAuthLoading={isAuthFetching}
        isFetching={isFetching}
        memberDetails={memberDetails}
        onChangeStaff={this.handleChangeStaff}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  authPinAuthenticationState,
  membershipRegistersListState,
  membershipsSelectors,
  subdomainSelectors,
}) => ({
  hasPin: membershipsSelectors.isPinSetForMembership(state),
  isAuthFetching: appSelectors.getIsFetching(authPinAuthenticationState),
  isFetching: appSelectors.getIsFetching(membershipRegistersListState),
  memberDetails: membershipsSelectors.getMembershipFormattedDataSelector(state),
  subdomainData: subdomainSelectors.getSubdomainInfo(state),
})

const enhance = compose(
  withAppService,
  withStaffRegisterWrapper,
  withSubdomainService,
  connect(mapState),
)

export default enhance(AuthenticationContainer)
