import {
  CLEAR_OBSERVATION_FEEDBACK_LISTS,
  LIST_OBSERVATION_FEEDBACKS,
  LIST_OBSERVATION_FEEDBACKS_FAILED,
  LIST_OBSERVATION_FEEDBACKS_SUCCESS,
} from './constants'

export const list = (params, onSuccess = () => {}) => ({ observationFeedbackApiClient }) => (dispatch) => {
  dispatch({
    type: LIST_OBSERVATION_FEEDBACKS,
  })

  observationFeedbackApiClient.list(params).then(
    (response) => {
      dispatch({
        payload: response,
        type: LIST_OBSERVATION_FEEDBACKS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => dispatch({
      error: response,
      type: LIST_OBSERVATION_FEEDBACKS_FAILED,
    }),
  )
}

export const clear = () => ({
  type: CLEAR_OBSERVATION_FEEDBACK_LISTS,
})
