export const LIST_CHILD_SESSIONS = 'LIST_CHILD_SESSIONS'
export const LIST_CHILD_SESSIONS_SUCCESS = 'LIST_CHILD_SESSIONS_SUCCESS'
export const LIST_CHILD_SESSIONS_FAILED = 'LIST_CHILD_SESSIONS_FAILED'

export const LIST_CHILD_SESSIONS_SETUP = 'LIST_CHILD_SESSIONS_SETUP'
export const LIST_CHILD_SESSIONS_SETUP_SUCCESS = 'LIST_CHILD_SESSIONS_SETUP_SUCCESS'
export const LIST_CHILD_SESSIONS_SETUP_FAILED = 'LIST_CHILD_SESSIONS_SETUP_FAILED'

export const CLEAR_CHILD_SESSION = 'CLEAR_CHILD_SESSION'

export const groupedByListChildSessions = (childId, params) => ({ childSessionsApiClient }) => (dispatch) => {
  dispatch({
    childId,
    type: LIST_CHILD_SESSIONS,
  })

  childSessionsApiClient.groupedByList(childId, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: LIST_CHILD_SESSIONS_SUCCESS,
      })
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: LIST_CHILD_SESSIONS_FAILED,
      })
    },
  )
}

export const listChildSessions = (childId, params, onSuccess) => ({ childSessionsApiClient }) => (dispatch) => {
  dispatch({
    childId,
    type: LIST_CHILD_SESSIONS,
  })

  childSessionsApiClient.list(childId, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: LIST_CHILD_SESSIONS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: LIST_CHILD_SESSIONS_FAILED,
      })
    },
  )
}

export const listChildSessionsSetupV3 = (childId, params) => ({ childBookingApiClient }) => (dispatch) => {
  dispatch({
    childId,
    type: LIST_CHILD_SESSIONS_SETUP,
  })

  childBookingApiClient.list(params).then(
    (data) => {
      dispatch({
        payload: data,
        type: LIST_CHILD_SESSIONS_SETUP_SUCCESS,
      })
    },
    (error) => {
      dispatch({
        error: error.response,
        type: LIST_CHILD_SESSIONS_SETUP_FAILED,
      })
    },
  )
}

export const listChildSessionsSetup = (childId, params) => ({ childSessionsApiClient }) => (dispatch) => {
  dispatch({
    childId,
    type: LIST_CHILD_SESSIONS_SETUP,
  })

  childSessionsApiClient.list(childId, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: LIST_CHILD_SESSIONS_SETUP_SUCCESS,
      })
    },
    (error) => {
      dispatch({
        error: error.response,
        type: LIST_CHILD_SESSIONS_SETUP_FAILED,
      })
    },
  )
}

export const clearChildSession = () => ({
  type: CLEAR_CHILD_SESSION,
})
