import * as listSelectors from './list/selectors'
import * as invoiceNumberSettingsSelectors from './single/selectors/invoiceNumberSettingsSelectors'
import * as invoiceSettingsSelectors from './single/selectors/invoiceSettingsSelectors'
import * as singleSelectors from './single/selectors/single'
import * as securitySelectors from './single/selectors/SecuritySettingsSelectors'

export default {
  ...invoiceNumberSettingsSelectors,
  ...invoiceSettingsSelectors,
  ...listSelectors,
  ...singleSelectors,
  ...securitySelectors,
}
