import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class ContractsApiClient extends ServiceBase {
  create = (payload = {}) => this.request({
    body: payload,
    method: 'POST',
    path: '/v2/contracts',
  })

  delete = (id) => this.request({
    method: 'DELETE',
    path: `/v2/contracts/${id}`,
  })

  get = (id, params = {}) => this.request({
    path: `/v2/contracts/${id}?${getQueryStringV2(params)}`,
  })

  list = (params = {}) => this.request({
    path: `/v2/contracts?${getQueryStringV2(params)}`,
  })

  listForMembership = (id, params = {}) => this.request({
    path: `/v2/memberships/${id}/contracts?${getQueryStringV2(params)}`,
  })

  update = (id, payload = {}, params = {}) => this.request({
    body: payload,
    method: 'PUT',
    path: `/v2/contracts/${id}?${getQueryStringV2(params)}`,
  })
}

export default ContractsApiClient
