import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class RoomMoveApiClient extends ServiceBase {
  list = (params = {}) => this.request({
    path: `/v2/classes-moves?${getQueryStringV2(params)}`,
  })

  get = (id, params = {}) => this.request({
    path: `/v2/classes-moves/${id}?${getQueryStringV2(params)}`,
  })

  create = (payload) => this.request({
    body: payload,
    method: 'POST',
    path: '/v2/classes-moves',
  })

  update = (id, payload) => this.request({
    body: payload,
    method: 'PUT',
    path: `/v2/classes-moves/${id}`,
  })

  remove = (id) => this.request({
    method: 'DELETE',
    path: `/v2/classes-moves/${id}`,
  })
}

export default RoomMoveApiClient
