import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { RolesDetails } from 'constants/security'
import { AVAILABLE_DAYS } from 'services/legacy/membershipRegisters/constants'

import {
  Avatar,
  DatePicker,
  EmptyState,
  InfiniteScroll,
  Page,
  Pagination,
  SearchBar,
  Space,
  Spinner,
  Table,
  Toolbar,
  Typography,
} from 'components'

import i18n from 'translations'

import Record from './components/Record'
import { StyledHeadTr, StyledPagination, StyledTableWrapper, StyledWeek } from './StaffAttendanceStyled'

export const COLUMNS_WIDTHS = {
  LEFT: 180,
  RIGHT: 83,
}

const StaffAttendanceView = ({
  date,
  dateRange,
  isFetching,
  onChangeDate,
  onChangePage,
  onEditShift,
  onSearchChange,
  page,
  pageCount,
  records,
  totalResults,
}) => {
  const renderHeader = () => (
    <React.Fragment>
      <SearchBar
        placeholder={i18n.t('module:Staff:StaffLists:searchStaff')}
        variant="standard"
        noBackground
        onChange={onSearchChange}
      />
      <Toolbar noMargin>
        <Toolbar.Group>
          <Toolbar.Item />
        </Toolbar.Group>
        <Toolbar.Group>
          <Toolbar.Item>
            <DatePicker
              type="week"
              value={dateRange}
              range
              onChange={onChangeDate}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
    </React.Fragment>
  )

  const renderProfile = (record) => {
    const { name, photo, roles } = record
    const rolesLabels = _.map(roles, (role) => RolesDetails[role]?.label)

    return (
      <Table.Td
        align="left"
        background={NEUTRAL_COLOURS.GRAY_QUATERNARY}
        verticalAlign="top"
        width={`${COLUMNS_WIDTHS.LEFT}px`}
      >
        <Avatar
          avatarSize="medium"
          initials={name}
          src={photo}
          subTitle={(
            <React.Fragment>
              <Space margin="2px" />
              <Typography
                color={NEUTRAL_COLOURS.GRAY}
                fontSize={14}
              >
                {rolesLabels}
              </Typography>
              <Space margin="2px" />
            </React.Fragment>
          )}
          title={(
            <Typography>
              {name}
            </Typography>
          )}
        />
      </Table.Td>
    )
  }

  const renderTableHeader = (isSticky) => (
    <StyledHeadTr isSticky={isSticky}>
      <Table.Th background={NEUTRAL_COLOURS.WHITE}>
        <StyledPagination>
          <Pagination
            titlePlural={i18n.t('module:Staff:StaffLists:staffMembers')}
            titleSingular={i18n.t('module:Staff:StaffLists:staffMember')}
            totalResults={totalResults}
          />
        </StyledPagination>
      </Table.Th>
      {_.times(AVAILABLE_DAYS, (index) => (
        <Table.Th align="left" background={NEUTRAL_COLOURS.WHITE} key={index}>
          <Typography transform="uppercase" variant="span" bold>
            {moment(date).add(index, 'days').format('ddd')}
          </Typography>
          <Space margin="0 5px 0 0" inline />
          <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={17} variant="span">
            {moment(date).add(index, 'days').format('DD')}
          </Typography>
        </Table.Th>
      ))}
    </StyledHeadTr>
  )

  const renderTbody = () => {
    if (!records?.length) {
      return (
        <Table.Tbody>
          <Table.Tr>
            <Table.Td colSpan={AVAILABLE_DAYS + 1}>
              <EmptyState
                icon="staff-worklog"
                text1={i18n.t('module:Staff:StaffAttendance:emptyState')}
              />
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      )
    }

    return (
      <Table.Tbody>
        {_.map(records, (record) => (
          <StyledWeek key={`record_${record.id}`}>
            {renderProfile(record)}
            {_.times(AVAILABLE_DAYS, (day) => (
              <Table.Td
                align="left"
                key={`record_${record.id}_${day}`}
                verticalAlign="top"
                width={`${COLUMNS_WIDTHS.RIGHT / AVAILABLE_DAYS}%`}
                onClick={() => onEditShift(record)}
              >
                <Record
                  date={moment(date).add(day, 'day').format(DEFAULT_DATE_FORMAT)}
                  record={record}
                />
              </Table.Td>
            ))}
          </StyledWeek>
        ))}
      </Table.Tbody>
    )
  }

  const renderContent = () => {
    if (isFetching && 1 === page) {
      return (
        <React.Fragment>
          <Space space="40px" />
          <Spinner />
        </React.Fragment>
      )
    }

    return (
      <InfiniteScroll
        dataLength={records?.length || 0}
        hasMore={page < pageCount}
        isFetching={isFetching}
        next={() => onChangePage((+page) + 1)}
      >
        <StyledTableWrapper>
          <Table
            minWidth="800"
            renderHeader={renderTableHeader}
            visualType="border"
          >
            {renderTbody()}
          </Table>
        </StyledTableWrapper>
      </InfiniteScroll>
    )
  }

  return (
    <Page.Section
      isLoading={isFetching && 1 === page}
      title={i18n.t('module:Staff:StaffAttendance:title')}
      noMargin
    >
      {renderHeader()}
      {renderContent()}
    </Page.Section>
  )
}

export default StaffAttendanceView
