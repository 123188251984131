import i18n from 'translations'

export const CHILD_DISCOUNTS_FILTER = {
  ACTIVE: 'ACTIVE',
  ALL: 'ALL',
  ARCHIVED: 'ARCHIVED',
}

export const CHILD_DISCOUNTS_FILTER_OPTIONS = [
  { label: i18n.t('global:Active'), value: CHILD_DISCOUNTS_FILTER.ACTIVE },
  { label: i18n.t('global:All'), value: CHILD_DISCOUNTS_FILTER.ALL },
  { label: i18n.t('global:Archived'), value: CHILD_DISCOUNTS_FILTER.ARCHIVED },
]

export const REGULAR = 'regular'
