import React from 'react'

import { noop } from 'constants/global'

import { Button, FooterActions, GridChildrenPicker, Spinner } from 'components'

import i18n from 'translations'

const ChildPickerTabView = ({
  childrenList,
  isFetching,
  onlyChildPicker,
  page,
  pageCount,
  room,
  selectedAll,
  selectedChildren = [],
  onCloseClick,
  onRoomChange,
  onSelectedAllChange,
  onSelectedChildrenChange,
  onNext,
}) => {
  if (isFetching && 1 === page) {
    return (
      <Spinner />
    )
  }

  return (
    <React.Fragment>
      <GridChildrenPicker
        isLoading={isFetching && 1 === page}
        options={childrenList}
        page={page}
        pageCount={pageCount}
        roomValue={room}
        selectedAll={selectedAll}
        value={selectedChildren}
        withEmptyState
        onChange={onSelectedChildrenChange}
        onPageChange={noop}
        onRoomChange={onRoomChange}
        onSelectAllClick={onSelectedAllChange}
      />
      <FooterActions spaceBetween>
        <FooterActions.Item>
          <Button
            hierarchy="tertiary"
            label={i18n.t('global:Cancel')}
            negativeMargins
            onClick={onCloseClick}
          />
        </FooterActions.Item>
        <FooterActions.Item>
          <Button
            disabled={!selectedChildren.length}
            label={onlyChildPicker ? i18n.t('global:Update') : i18n.t('global:Next')}
            negativeMargins
            onClick={onNext}
          />
        </FooterActions.Item>
      </FooterActions>
    </React.Fragment>
  )
}

export default ChildPickerTabView
