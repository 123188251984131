import React from 'react'
import { compose } from 'recompose'

import { withModalService } from 'services/utils/modal'

import PreviewLineItemModalView from './PreviewLineItemModalView'

const PreviewLineItemModalContainer = ({
  fundingLineItemOptions,
  fundingNamePreview = '3 year old (15 hours)',
  hideModal,
  isDeductedFromInvoice,
  isSubstractFundingSelected,
}) => (
  <PreviewLineItemModalView
    fundingLineItemOptions={fundingLineItemOptions}
    fundingNamePreview={fundingNamePreview}
    isDeductedFromInvoice={isDeductedFromInvoice}
    isSubstractFundingSelected={isSubstractFundingSelected}
    onCloseClick={hideModal}
  />
)

const enhance = compose(
  withModalService,
)

export default enhance(PreviewLineItemModalContainer)
