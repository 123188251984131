import styled from 'styled-components'
import { Editor } from '@tinymce/tinymce-react'

import colors, { FLAG_COLOURS } from 'constants/colors'

import layout from 'constants/layout'

require('tinymce/tinymce')

// Theme
require('tinymce/themes/silver/theme')
require('tinymce/icons/default/icons')

// Skins
require('tinymce/skins/ui/oxide/skin.min.css')

export const StyledInputContainer = styled.div`
  position: relative;
`

interface StyledTextEditorProps {
  $disabled?: boolean
  $valid?: boolean
}

// @ts-ignore: Unreachable code error
export const StyledTextEditor = styled(Editor)<StyledTextEditorProps>`
  max-width: 100%;
  display: block;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ $valid }) => (false === $valid ? FLAG_COLOURS.ERROR : colors.inputBorderColor)};
  border-radius: 4px;
  height: 140px;
  min-height: 140px;
  line-height: 20px;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  ${({ $disabled }) => $disabled && `background-color: ${colors.inputBorderColor}`};
  resize: none;
  
  @media(max-width: ${layout.mobileWidthResolution}px) {
    width: 100%;
  }
`
