import { CLOSE_SNACKBAR, SHOW_SNACKBAR, UPDATE_SNACKBAR } from './constants'

const initialState = {
  snackbar: null,
}

export interface SnackbarRootState {
  snackbar: any
  time?: number
}

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SHOW_SNACKBAR:
      return {
        snackbar: payload,
      }
    case CLOSE_SNACKBAR:
      return initialState
    case UPDATE_SNACKBAR: {
      const item = {
        ...state.snackbar,
        time: (state.snackbar ? (state.snackbar.time - 1000) : 0),
      }

      return {
        snackbar: 0 >= item.time ? null : item,
      }
    }
    default:
      return state
  }
}
