import _ from 'lodash'

import React, { PropsWithChildren, useState } from 'react'

import { Button, Tooltip } from 'components'

import TabsItem from './TabsItem'
import { StyledBodyContent, StyledTab, StyledTabWrapper, StyledTabs, StyledTabsContainer } from './TabsStyled'

interface TabsRoot<T> extends React.FC<PropsWithChildren<T>> {
  Item?: typeof TabsItem
}

interface TabsProps {
  buttonType?: boolean
  defaultPosition?: number | string
  fullHeight?: boolean
  fullWidth?: boolean
  gray?: boolean
  lazyLoadBody?: boolean
  noContentPadding?: boolean
  noMargin?: boolean
  onChange?: (value: number | string) => void
  value?: number | string
  variant?: 'standard' | 'scrollable' | 'fullWidth'
}

const Tabs: TabsRoot<TabsProps> = ({
  buttonType,
  children,
  defaultPosition,
  fullHeight,
  fullWidth = true,
  gray,
  lazyLoadBody,
  noContentPadding,
  noMargin,
  onChange,
  value,
  variant = 'scrollable',
}) => {
  const [position, setPosition] = useState(defaultPosition || 0)

  if (!children) {
    return null
  }

  let elements = []

  // @ts-ignore
  _.each(children, (item) => {
    if (_.isArray(item)) {
      _.each(item, (element) => {
        elements.push(element)
      })
    }

    elements.push(item)
  })

  elements = _.filter(elements, (e) => e?.props?.title)

  const scrollButtonComponent = (props) => {
    if (!props.disabled) {
      return (
        <Button
          hierarchy="tertiary"
          icon={'right' === props.direction ? 'chevron-right' : 'chevron-left'}
          margin="auto 0"
          size="small"
          {...props}
        />
      )
    }

    return null
  }

  const renderBody = () => {
    if (lazyLoadBody) {
      const currentItem = _.find(elements, ({ props }, index) => (
        props.name ? props.name === (value || position) : index === position
      ))

      if (!currentItem) {
        return null
      }

      return (
        <StyledBodyContent
          $fullHeight={fullHeight}
          $gray={gray}
          $noContentPadding={noContentPadding}
          $visible
        >
          {currentItem.props.children}
        </StyledBodyContent>
      )
    }

    return _.map(elements, ({ props }, index) => (
      <StyledBodyContent
        $fullHeight={fullHeight}
        $gray={gray}
        $noContentPadding={noContentPadding}
        $visible={props.name ? props.name === (value || position) : index === position}
        key={index}
      >
        {props.children}
      </StyledBodyContent>
    ))
  }

  return (
    <StyledTabsContainer $noMargin={noMargin}>
      <StyledTabs
        $buttonType={buttonType}
        $gray={gray}
        ScrollButtonComponent={scrollButtonComponent}
        scrollButtons="on"
        value={value || position}
        variant={variant}
        onChange={(event, newValue) => {
          setPosition(newValue)

          if (onChange) {
            onChange(newValue)
          }
        }}
      >
        {_.map(elements, ({ props: { disabled, minWidth, name, title, tooltip } }, index) => (
          <StyledTab
            $buttonType={buttonType}
            $itemFullWidth={fullWidth}
            $minWidth={minWidth}
            disabled={disabled}
            key={index}
            label={(
              <Tooltip title={tooltip}>
                <StyledTabWrapper>
                  {title}
                </StyledTabWrapper>
              </Tooltip>
            )}
            value={name}
            disableFocusRipple
            disableRipple
          />
        ))}
      </StyledTabs>
      {renderBody()}
    </StyledTabsContainer>
  )
}

export default Tabs
