import _ from 'lodash'

import React from 'react'

import { CHILD_EXTRA_ITEMS_TYPE_OPTIONS } from 'services/legacy/childExtraItems/constants'

import { generateRoute } from 'utils/routing'

import { Button, Currency, DateString } from 'components'

export const getTableData = (extraItems, childId) => {
  if (!extraItems || 0 === extraItems.length) {
    return []
  }

  return extraItems.map((item) => {
    const { id, name, quantity, startDate, total, type, unitPrice } = item

    const typeOption = _.find(CHILD_EXTRA_ITEMS_TYPE_OPTIONS, { value: type })

    return {
      id,
      name,
      quantity,
      rowToButton: (
        <Button.TableAction
          to={generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_ITEMS.EDIT', { childId, id })}
          edit
        />
      ),
      startDate: <DateString date={startDate} />,
      total: <Currency value={total} />,
      type: typeOption && typeOption.label,
      unitPrice: <Currency value={unitPrice} />,
    }
  })
}
