import _ from 'lodash'
import { createSelector } from 'reselect'

import * as constants from '../constants'

export const getChildDiscountsSelector = (state) => state.childDiscounts

export const getChildDiscountsListSelector = createSelector(
  [getChildDiscountsSelector],
  (state) => state.list.data,
)

export const getNurseryDiscountsOptionsSelector = createSelector(
  [getChildDiscountsListSelector],
  (discounts) => {
    if (!discounts || !discounts.length) {
      return null
    }

    return _.map(discounts, ({ id, name }) => ({
      label: name,
      value: id,
    }))
  },
)

export const getCriteriaSelector = createSelector(
  [((filters) => filters)],
  (filters) => {
    const {
      childId,
      status = constants.CHILD_DISCOUNTS_FILTER.ACTIVE,
    } = filters

    const criteria = []

    if (childId) {
      criteria.push({
        field: 'child[]',
        value: childId,
      })
    }

    if (status && status !== constants.CHILD_DISCOUNTS_FILTER.ALL) {
      criteria.push({
        field: 'archived',
        value: status === constants.CHILD_DISCOUNTS_FILTER.ARCHIVED,
      })
    }

    return criteria
  },
)

export const isChildDiscountsEmpty = createSelector(
  [getChildDiscountsListSelector],
  (discounts) => (!discounts || !discounts.length),
)
