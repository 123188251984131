import i18n from 'translations'

export const TYPES = {
  ARCHIVE: 'archive',
  DELETE: 'delete',
  DOWNLOAD: 'download',
  EDIT: 'edit',
  EXPORT: 'export',
  REMOVE: 'remove',
  RESEND: 'resend',
  RESET: 'reset',
  SEND: 'send',
  UNARCHIVE: 'unarchive',
} as const

interface PropType {
  icon: IconType
  label: string
  type: string
}

export const PROPS_OPTIONS = <PropType[]>[
  {
    icon: 'archive',
    label: i18n.t('global:Archive'),
    type: TYPES.ARCHIVE,
  },
  {
    icon: 'trash',
    label: i18n.t('global:Delete'),
    type: TYPES.DELETE,
  },
  {
    icon: 'download',
    label: i18n.t('global:Download'),
    type: TYPES.DOWNLOAD,
  },
  {
    icon: 'edit',
    label: i18n.t('global:Edit'),
    type: TYPES.EDIT,
  },
  {
    icon: 'export',
    label: i18n.t('global:Export'),
    type: TYPES.EXPORT,
  },
  {
    icon: 'trash',
    label: i18n.t('global:Remove'),
    type: TYPES.REMOVE,
  },
  {
    icon: 'resend',
    label: i18n.t('global:Resend'),
    type: TYPES.RESEND,
  },
  {
    icon: 'reset',
    label: i18n.t('global:Reset'),
    type: TYPES.RESET,
  },
  {
    icon: 'send',
    label: i18n.t('global:Send'),
    type: TYPES.SEND,
  },
  {
    icon: 'unarchive',
    label: i18n.t('global:Unarchive'),
    type: TYPES.UNARCHIVE,
  },
]
