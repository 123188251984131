import React from 'react'

import { Callout, EmptyState, Page, Pagination, Section, Select, Spinner, Table, Toolbar } from 'components'

import i18n from 'translations'
import { generateRoute } from 'utils/routing'

import { CLOSURE_DAY_TYPE_FILTER_OPTIONS } from 'services/closureDayTypes/models'
import { Option } from 'constants/models'
import { TableDataProps } from './ClosureDayTypesHelpers'

const TABLE_COLUMNS = [
  {
    align: 'left',
    field: 'name',
    sortable: true,
    title: i18n.t('module:Management:ClosureDays:ClosureDayTypes:List:Table:Header:Name:title'),
    width: '30%',
  },
  {
    align: 'left',
    field: 'chargeable',
    title: i18n.t('module:Management:ClosureDays:ClosureDayTypes:List:Table:Header:Chargeable:title'),
  },
]

interface ClosureDayTypesViewProps {
  errorMessages?: any[]
  isFinanceV3Enabled?: boolean
  isLoading?: boolean
  onPageChange?: (newPage: any) => void
  onSortChange?: (key: any) => void
  onStatusFilterChange?: (status: string) => void
  page?: number
  pageCount?: number
  perPage?: number
  sortField?: string
  sortOrder?: string
  statusFilter?: Option
  tableData?: TableDataProps[]
  totalResults?: number
}

const ClosureDayTypesView: React.FC<ClosureDayTypesViewProps> = ({
  errorMessages,
  isFinanceV3Enabled,
  isLoading,
  onPageChange,
  onSortChange,
  onStatusFilterChange,
  page,
  pageCount,
  perPage,
  sortField,
  sortOrder,
  statusFilter,
  tableData,
  totalResults,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!tableData?.length) {
      return (
        <EmptyState
          icon="discount"
          text1={i18n.t('module:Management:ClosureDays:ClosureDayTypes:List:emptyState')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={isFinanceV3Enabled ? [...TABLE_COLUMNS, { field: 'rowToButton' }] : TABLE_COLUMNS}
          data={tableData}
          sortField={sortField}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const actions = <Section.Actions primary={[{ to: generateRoute('MANAGEMENT.CLOSURE_DAYS.CLOSURE_DAY_TYPES.ADD') }]} />

  return (
    <Page>
      <Page.Content>
        <Callout content={errorMessages} error />
        <Section
          actions={isFinanceV3Enabled && actions}
          title={i18n.t('module:Management:ClosureDays:ClosureDayTypes:title')}
        >
          <Toolbar>
            <Toolbar.Group>
              <Toolbar.Item>
                <Select
                  clearable={false}
                  options={CLOSURE_DAY_TYPE_FILTER_OPTIONS}
                  searchable={false}
                  value={statusFilter}
                  onChange={onStatusFilterChange}
                />
              </Toolbar.Item>
            </Toolbar.Group>
          </Toolbar>
          {renderContent()}
        </Section>
      </Page.Content>
    </Page>
  )
}

export default ClosureDayTypesView
