import _ from 'lodash'
import styled from 'styled-components'
import { Property } from 'csstype'

import { noop } from 'constants/global'
import { CursorTypes } from 'constants/css'

import { typeByObject } from 'utils/typescript'

export const VARIANT_TAGS = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
  SMALL: 'small',
  SPAN: 'span',
}

export const ALIGN_OPTIONS = {
  CENTER: 'center',
  JUSTIFY: 'justify',
  LEFT: 'left',
  RIGHT: 'right',
}

interface StyledTypographyProps {
  align?: typeByObject<typeof ALIGN_OPTIONS>
  background?: string
  bold?: boolean
  color?: string
  cursor?: CursorTypes
  ellipsis?: boolean
  fontSize?: string | number
  fontWeight?: Property.FontWeight
  hoverColor?: string
  hoverOpacity?: number
  inline?: boolean
  italic?: boolean
  linkColorInherit?: boolean
  margin?: string
  maxWidth?: number
  medium?: boolean
  minWidth?: number
  nowrap?: boolean
  onClick?: () => {}
  opacity?: Property.Opacity
  overflow?: Property.Overflow
  padding?: string
  prewrap?: boolean
  primary?: boolean
  secondary?: boolean
  tertiary?: boolean
  transform?: Property.TextTransform
  underline?: Property.UserSelect
  variant?: typeByObject<typeof VARIANT_TAGS>
  width?: string | number
  wordBreak?: Property.WordBreak
}

export const StyledTypography = styled.p<StyledTypographyProps>`
  margin: 0;
  padding: 0;
  letter-spacing: normal;
  font-size: 16px;
  font-weight: 400;
  width: 100%;

  ${({ $background }) => $background && `
    background: ${$background};
  `}
  
  ${({ $inline }) => $inline && `
    display: inline;
  `}
  
  ${({ $linkColorInherit }) => $linkColorInherit && `
    & > a {
      color: inherit;
    }
  `}
  
  p {
    margin: 0;
    line-height: inherit;
  }
  
  ${({ $fontWeight }) => $fontWeight && `
    font-weight: ${$fontWeight};
  `}
  
  ${({ $color }) => $color && `
    color: ${$color};
  `}
  
  ${({ $bold }) => $bold && `
    font-weight: 600;
  `}

  ${({ $lineHeight }) => $lineHeight && `
    line-height: ${$lineHeight}px;
  `}

  ${({ $margin }) => $margin && `
    margin: ${$margin} !important;
  `}

  ${({ $padding }) => $padding && `
    padding: ${$padding};
  `}

  ${({ $align }) => ALIGN_OPTIONS.LEFT === $align && `
    text-align: left;
  `}
  
  ${({ $align }) => ALIGN_OPTIONS.CENTER === $align && `
    text-align: center;
  `}
  
  ${({ $align }) => ALIGN_OPTIONS.RIGHT === $align && `
    text-align: right;
  `}

  ${({ $align }) => ALIGN_OPTIONS.JUSTIFY === $align && `
    text-align: justify;
  `}
  
  ${({ $prewrap }) => $prewrap && `
    white-space: pre-wrap;
  `}

  ${({ $nowrap }) => $nowrap && `
    white-space: nowrap;
  `}
  
  ${({ onClick }) => onClick && onClick !== noop && `
    cursor: pointer;
  `}  

  ${({ $cursor }) => $cursor && `
    cursor: ${$cursor};
  `}
  
  ${({ $transform }) => $transform && `
    text-transform: ${$transform};
  `}

  ${({ $variant }) => VARIANT_TAGS.H1 === $variant && `
    font-size: 46px;
    font-weight: 600;
  `}
  
  ${({ $variant }) => VARIANT_TAGS.H2 === $variant && `
    font-size: 38px;
    font-weight: 600;
  `}
  
  ${({ $variant }) => VARIANT_TAGS.H3 === $variant && `
    font-size: 32px;
    font-weight: 600;
  `}
  
  ${({ $variant }) => VARIANT_TAGS.H4 === $variant && `
    font-size: 26px;
    font-weight: 600;
  `}
  
  ${({ $variant }) => VARIANT_TAGS.H5 === $variant && `
    font-size: 22px;
    font-weight: 600;
  `}
  
  ${({ $variant }) => VARIANT_TAGS.H6 === $variant && `
    font-size: 18px;
    font-weight: 600;
  `}
  
  ${({ $variant }) => VARIANT_TAGS.SMALL === $variant && `
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
  `}
  
  ${({ $variant }) => VARIANT_TAGS.SPAN === $variant && `
    display: inline-block;
    width: auto;
  `}
  
  ${({ $fontSize }) => $fontSize && 'inherit' !== $fontSize && `
    font-size: ${$fontSize}px;
  `}
  
  ${({ $fontSize }) => $fontSize && 'inherit' === $fontSize && `
    font-size: inherit;
  `}
  
  ${({ $wordBreak }) => $wordBreak && `
    word-break: ${$wordBreak};
  `}
  
  ${({ $italic }) => $italic && `
    font-style: italic;
  `}

  ${({ $opacity }) => !_.isUndefined($opacity) && `
    opacity: ${$opacity};
  `}

  ${({ $ellipsis }) => $ellipsis && `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
  
  ${({ $medium }) => $medium && `
    font-weight: 400;
  `}

  ${({ $overflow }) => $overflow && `
    overflow: ${$overflow};
  `}

  ${({ $width }) => $width && 'auto' !== $width && `
    width: ${_.isInteger($width) ? `${$width}px` : $width}; ;
  `}

  ${({ $minWidth }) => $minWidth && `
    min-width: ${$minWidth}px;
  `}

  ${({ $maxWidth }) => $maxWidth && `
    max-width: ${$maxWidth}px;
  `}

  ${({ $underline }) => $underline && `
    text-decoration: underline;
  `}
  
  ${({ $userSelect }) => $userSelect && `
    user-select: ${$userSelect};
  `}
  
  ${({ $hoverColor }) => $hoverColor && `
    transition: .2s;
  
    &:hover {
      color: ${$hoverColor};
    }
  `}

  ${({ $hoverOpacity }) => $hoverOpacity && `
    transition: .2s;
  
    &:hover {
      opacity: ${$hoverOpacity};
    }
  `}
`
