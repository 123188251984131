import { FEATURE_FLAGS } from 'constants/security'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { RootState } from 'core/reducers'

import { withSecurityService } from 'services/security'

import auth from 'utils/auth'

interface FianceGatewayProps {
  V2: any
  V3: any
}

const withFinanceGateway = <P extends FianceGatewayProps>({ V2, V3 }: P) => {
  const mapState = (state: RootState, { securitySelectors }) => ({
    isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
    isFinanceV3FFEnabled: auth.SELECTORS.getIsAuthorised(state, {
      flags: [FEATURE_FLAGS.FINANCE_V3],
    }),
    isOrganizationContext: securitySelectors.isOrganizationContext(state),
  })

  const Component = (props) => {
    const { isFinanceV3Enabled, isFinanceV3FFEnabled, isOrganizationContext } = props

    if (isFinanceV3Enabled || (isOrganizationContext && isFinanceV3FFEnabled)) {
      return <V3 {...props as P} />
    }

    return <V2 {...props as P} />
  }

  const enhance = compose(
    withSecurityService,
    connect(mapState),
  )

  return enhance(Component)
}

export default withFinanceGateway
