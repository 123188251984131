import { generateBasicActions } from 'utils/service'

import {
  GET_NURSERY_PAYMENT_TYPES_DROPDOWN_ITEM,
  GET_NURSERY_PAYMENT_TYPES_DROPDOWN_ITEM_FAILED,
  GET_NURSERY_PAYMENT_TYPES_DROPDOWN_ITEM_SUCCESS,
  NURSERY_PAYMENT_TYPES_DROPDOWN_LIST,
  NURSERY_PAYMENT_TYPES_DROPDOWN_LIST_FAILED,
  NURSERY_PAYMENT_TYPES_DROPDOWN_LIST_SUCCESS,
} from './constants'

export const dropdownList = (options = {}) => ({
  nurseryPaymentTypesApiClient,
}) => generateBasicActions.list({
  constants: {
    failed: NURSERY_PAYMENT_TYPES_DROPDOWN_LIST_FAILED,
    init: NURSERY_PAYMENT_TYPES_DROPDOWN_LIST,
    success: NURSERY_PAYMENT_TYPES_DROPDOWN_LIST_SUCCESS,
  },
  options,
  service: nurseryPaymentTypesApiClient,
  serviceMethod: 'list',
})

export const getDropdownItem = (options = {}) => ({ nurseryPaymentTypesApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_NURSERY_PAYMENT_TYPES_DROPDOWN_ITEM_FAILED,
    init: GET_NURSERY_PAYMENT_TYPES_DROPDOWN_ITEM,
    success: GET_NURSERY_PAYMENT_TYPES_DROPDOWN_ITEM_SUCCESS,
  },
  options,
  service: nurseryPaymentTypesApiClient,
  serviceMethod: 'get',
})
