import React from 'react'

import { Button, Typography } from 'components'

import i18n from 'translations'

import VariousPageWrapper from '../components/VariousPageWrapper'

interface AccessForbiddenViewProps {
  isLoggedIn: boolean
}

const AccessForbiddenView: React.FC<AccessForbiddenViewProps> = ({
  isLoggedIn,
}) => (
  <VariousPageWrapper>
    <Typography
      fontSize={18}
      margin="30px 0 20px 0"
      maxWidth={470}
      opacity="0.6"
    >
      {i18n.t('module:Various:AccessForbidden:text')}
    </Typography>
    {!isLoggedIn && (
      <Button
        label={i18n.t('module:Various:AccessForbidden:loginButtonText')}
        to="/login"
      />
    )}
    {isLoggedIn && (
      <Button
        label={i18n.t('module:Various:AccessForbidden:dashboardButtonText')}
        to="/"
      />
    )}
  </VariousPageWrapper>
)

export default AccessForbiddenView
