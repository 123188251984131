import { generateBasicActions } from 'utils/service'

import {
  CLEAR_RELATION,
  CREATE_RELATION,
  CREATE_RELATION_FAILED,
  CREATE_RELATION_SUCCESS,
  GET_RELATION,
  GET_RELATION_FAILED,
  GET_RELATION_SUCCESS,
  REMOVE_RELATION,
  REMOVE_RELATION_FAILED,
  REMOVE_RELATION_SUCCESS,
  UPDATE_RELATION,
  UPDATE_RELATION_FAILED,
  UPDATE_RELATION_SUCCESS,
} from './constants'

export const create = (options = {}) => ({ relationsApiClient }) => generateBasicActions.create({
  constants: {
    failed: CREATE_RELATION_FAILED,
    init: CREATE_RELATION,
    success: CREATE_RELATION_SUCCESS,
  },
  options,
  service: relationsApiClient,
  serviceMethod: 'create',
})

export const get = (options = {}) => ({ relationsApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_RELATION_FAILED,
    init: GET_RELATION,
    success: GET_RELATION_SUCCESS,
  },
  options,
  service: relationsApiClient,
  serviceMethod: 'get',
})

export const update = (options = {}) => ({ relationsApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_RELATION_FAILED,
    init: UPDATE_RELATION,
    success: UPDATE_RELATION_SUCCESS,
  },
  options,
  service: relationsApiClient,
  serviceMethod: 'update',
})

export const remove = (options = {}) => ({ relationsApiClient }) => generateBasicActions.remove({
  constants: {
    failed: REMOVE_RELATION_FAILED,
    init: REMOVE_RELATION,
    success: REMOVE_RELATION_SUCCESS,
  },
  options,
  service: relationsApiClient,
  serviceMethod: 'remove',
})

export const clearSingle = () => ({
  type: CLEAR_RELATION,
})
