import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledMontessoriTableWrapper = styled.div`
  margin: 25px 0;  
`

export const StyledSubCategory = styled.div`
  margin-bottom: 15px;
`

export const StyledSubCategoryHeader = styled.div`
  padding: 15px;
  color: ${NEUTRAL_COLOURS.WHITE};
  background: ${getBrandingColor('primary-color')};
`

export const StyledActivitiesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 33.33%);
  margin: 0 -2px;
  padding: 1px 0;
  
  @media(max-width: 850px) {
    grid-template-columns: repeat(2, 50%);
  }
  
  @media(max-width: 640px) {
    grid-template-columns: auto;
  }
`

export const StyledActivity = styled.div`
  border: 2px solid ${NEUTRAL_COLOURS.WHITE};
  background: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};  
`

export const StyledActivityButton = styled.div`
  transition: .2s;
  padding: 15px;
  cursor: pointer;
  height: 100%;
  
  &:hover {
    background: ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  }
`

export const StyledItemDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
  align-items: center;
  padding-top: 10px;
`
