import { Sync, SyncProvider } from '@blossomdev/sync'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { LOGIN_SUCCESSFUL, LOGOUT_SUCCESSFUL } from 'services/legacy/auth/constants'
import { ROLES, RolesDetails } from 'constants/security'

import eventBus from 'utils/eventBus'

import { isNurseryContext as isNurseryContextSelector } from 'services/security/selectors'

class SyncProviderWrapper extends Component {
  constructor(props) {
    super(props)

    const { authProfile, authToken } = props

    this.state = {
      authProfile,
      authToken,
    }
  }

  shouldComponentUpdate(nextProps) {
    const { subdomain } = this.props

    return nextProps.subdomain !== subdomain
  }

  handleLoginSuccess = (e) => {
    const { profile, token } = e.detail

    this.setState({
      authProfile: profile,
      authToken: token,
    }, this.forceUpdate.bind(this))
  }

  handleLogoutSuccess = async () => {
    await Sync.clear()
    this.setState({
      authProfile: null,
      authToken: null,
    }, this.forceUpdate.bind(this))
  }

  componentDidMount() {
    eventBus.on(LOGIN_SUCCESSFUL, this.handleLoginSuccess)
    eventBus.on(LOGOUT_SUCCESSFUL, this.handleLogoutSuccess)
  }

  componentWillUnmount() {
    eventBus.remove(LOGIN_SUCCESSFUL, this.handleLoginSuccess)
    eventBus.remove(LOGOUT_SUCCESSFUL, this.handleLogoutSuccess)
  }

  render() {
    const { children, isNurseryContext, subdomain } = this.props
    const { authProfile, authToken } = this.state

    const hasTeacherRole = authProfile?.roles?.includes(RolesDetails[ROLES.TEACHER].value)

    if (isNurseryContext && hasTeacherRole && authToken && subdomain) {
      return (
        <SyncProvider
          authentication={{
            subdomain,
            token: authToken,
          }}
        >
          {children}
        </SyncProvider>
      )
    }

    return children
  }
}

const mapState = (state) => ({
  isNurseryContext: isNurseryContextSelector(state),
})

const enhance = compose(
  connect(mapState),
)

export default enhance(SyncProviderWrapper)
