import ServiceBase from 'services/ServiceBase'

import { getQueryString } from 'utils/api'

class CohortTrackingApiClient extends ServiceBase {
  list = (params = {}) => this.request({
    path: `/cohort-tracking/saved-reports?${getQueryString(params)}`,
  })

  getProgressTrackerPreview = (type, params = {}) => this.request({
    path: `/cohort-tracking/${type}-breakdown/search?${getQueryString(params)}`,
  })

  getProgressTrackerReport = (type, id) => this.request({
    path: `/cohort-tracking/${type}-breakdown/${id}`,
  })

  removeProgressTracker = (type, id) => this.request({
    method: 'DELETE',
    path: `/cohort-tracking/${type}-breakdown/${id}`,
  })

  createProgressTracker = (type, body) => this.request({
    body,
    method: 'POST',
    path: `/cohort-tracking/${type}-breakdown`,
  })

  updateProgressTrackerActionPlan = (type, id, body) => this.request({
    body,
    method: 'PATCH',
    path: `/cohort-tracking/${type}-breakdown/${id}`,
  })

  getComparisonReportPreview = (type, params = {}) => this.request({
    path: `/cohort-tracking/${type}-comparison/search?${getQueryString(params)}`,
  })

  getComparisonReport = (type, id) => this.request({
    path: `/cohort-tracking/${type}-comparison/${id}`,
  })

  createComparisonReport = (type, body) => this.request({
    body,
    method: 'POST',
    path: `/cohort-tracking/${type}-comparison`,
  })

  removeComparisonReport = (type, id) => this.request({
    method: 'DELETE',
    path: `/cohort-tracking/${type}-comparison/${id}`,
  })

  updateComparisonReportActionPlan = (type, id, body) => this.request({
    body,
    method: 'PATCH',
    path: `/cohort-tracking/${type}-comparison/${id}`,
  })
}

export default CohortTrackingApiClient
