import React from 'react'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { Form } from 'components'

import i18n from 'translations'

export const SESSION_TIMEOUT_FORM = 'SESSION_TIMEOUT_FORM'

interface SessionTimeoutFormProps {
  handleSubmit: (e: any) => () => void
  isSubmitting?: boolean
  onSubmit: () => void
  sessionExpireTimeEnabled?: boolean
}

const SessionTimeoutForm: React.FC<SessionTimeoutFormProps> = ({
  handleSubmit,
  isSubmitting,
  onSubmit,
  sessionExpireTimeEnabled,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row>
      <Form.Row.FlexItem flex="0 0 50px" margin="-7px 0 0" mobileFull>
        <Field
          component={Form.Switch}
          disabled={isSubmitting}
          name="sessionExpireTimeEnabled"
        />
      </Form.Row.FlexItem>
      <Form.Row.TextItem
        margin="-7px 0 0"
        text={sessionExpireTimeEnabled ? i18n.t('global:On') : i18n.t('global:Off')}
      />
    </Form.Row>
    {sessionExpireTimeEnabled && (
      <Form.Row>
        <Form.Row.TextItem
          margin="0 0 0 -20px"
          text={i18n.t('module:Management:Security:GeneralSecuritySettings:SessionTimeout:timeoutLabelPrefix')}
        />
        <Form.Row.FlexItem flex="0 0 80px">
          <Field
            component={Form.TextField}
            name="sessionExpireTime"
            type="number"
          />
        </Form.Row.FlexItem>
        <Form.Row.TextItem
          text={i18n.t('module:Management:Security:GeneralSecuritySettings:SessionTimeout:timeoutLabelSuffix')}
        />
      </Form.Row>
    )}
  </Form>
)

const mapState = (state) => ({
  sessionExpireTimeEnabled: formValueSelector(SESSION_TIMEOUT_FORM)(state, 'sessionExpireTimeEnabled'),
})

const enhance = compose(
  reduxForm({ form: SESSION_TIMEOUT_FORM }),
  connect(mapState),
)

export default enhance(SessionTimeoutForm)
