import {
  CLEAR_COHORT_TRACKING_LISTS,
  LIST_COHORT_TRACKING,
  LIST_COHORT_TRACKING_FAILED,
  LIST_COHORT_TRACKING_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: {},
}

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case LIST_COHORT_TRACKING:
      return { ...state, error: initialState.error, isFetching: true }
    case LIST_COHORT_TRACKING_SUCCESS: {
      const { data, meta } = payload

      return {
        ...state,
        data,
        isFetching: false,
        meta,
      }
    }
    case LIST_COHORT_TRACKING_FAILED:
      return { ...state, error: payload.error, isFetching: false }
    case CLEAR_COHORT_TRACKING_LISTS:
      return initialState
    default:
      return state
  }
}
