import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledItemBox = styled.div`
  display: block;
  height: 100%;
  margin: -10px;
  padding: 4px;  
  transition: .2s;
`

export const StyledShiftTimesWrapper = styled.div`
  border-left: 2px solid ${NEUTRAL_COLOURS.GRAY};
  padding-left: 6px;
  margin-top: 15px;
  
  ${({ first }) => first && `
    margin-top: 5px;
  `}
`

export const StyledHeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`

export const StyledHeadTr = styled.tr`
  ${({ isSticky }) => isSticky && `
    th {
      border-bottom: 2px solid ${NEUTRAL_COLOURS.GRAY_SECONDARY};
      border-left: 2px solid ${NEUTRAL_COLOURS.GRAY_SECONDARY};
      border-right: 2px solid ${NEUTRAL_COLOURS.GRAY_SECONDARY};
      
      &:nth-of-type(1) {
        border-left: 1px solid ${NEUTRAL_COLOURS.GRAY_SECONDARY};
      }
      
      &:nth-last-of-type(1) {
        border-right: 1px solid ${NEUTRAL_COLOURS.GRAY_SECONDARY};
      }
    }
  `}
`
