import * as listActions from './list/actions'
import * as organizationListActions from './organizationList/actions'
import * as singleActions from './single/actions'
import * as resendInvitationActions from './resendInvitation/actions'
import * as filesActions from './files/actions'

export default {
  ...listActions,
  ...organizationListActions,
  ...singleActions,
  ...resendInvitationActions,
  ...filesActions,
}
