import React, { PropsWithChildren } from 'react'
import RcSlider, { SliderProps } from 'rc-slider'

import { StyledSliderContainer, StyledValue } from './SliderStyled'

export interface SliderFinalProps extends SliderProps {
  value?: number
  valueFormat?: (v: number) => string
}

const Slider: React.FC<PropsWithChildren<SliderFinalProps>> = ({
  onChange,
  value = 0,
  valueFormat,
  ...others
}) => (
  <StyledSliderContainer>
    <RcSlider
      value={value}
      onChange={onChange}
      {...others}
    />
    <StyledValue>
      {valueFormat ? valueFormat(value) : null}
    </StyledValue>
  </StyledSliderContainer>
)

export default Slider
