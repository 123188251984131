import _ from 'lodash'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { change, destroy, getFormInitialValues, stopSubmit } from 'redux-form'

import { EVENTS, logEvent } from 'analytics'

import { FUNDING_FEES_TYPES } from 'services/nurseryFunding/constants'
import { CHILD_FUNDING_FILTERS } from 'services/legacy/childFunding/constants'
import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'
import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withEthnicitiesService } from 'services/legacy/ethnicities'
import { withModalService } from 'services/utils/modal'
import { withSecurityService } from 'services/security'
import { withUploadService } from 'services/legacy/upload'
import { withChildFundingService } from 'services/legacy/childFunding'
import { withRouter } from 'services/router'

import i18n from 'translations'

import ChildEditView from './ChildEditView'
import { generatePopupMessage, getGroups, getInitialValues, getPayload } from './helpers'
import { CHILD_EDIT_FORM_ROUTE_MAPPING, CHILD_EDIT_FORM_TYPE } from '../constants'
import { CHILD_ADD_FORM } from '../components/ChildForms/constants'

class ChildEditContainer extends Component {
  constructor(props) {
    super(props)

    const { destroyForm, params } = props
    const { type } = params
    const newType = CHILD_EDIT_FORM_ROUTE_MAPPING[type]

    this.state = {
      child: null,
      customErrors: [],
      groups: getGroups(newType),
      initialValues: null,
      isFetching: false,
      type: newType,
    }

    destroyForm()
  }

  componentDidMount() {
    const { childActions, childFundingActions, childFundingHelpers, ethnicitiesActions, params } = this.props
    const { groups, type } = this.state
    const { childId } = params

    this.setState({ isFetching: true })

    if (type === CHILD_EDIT_FORM_TYPE.GENERAL_INFO) {
      ethnicitiesActions.list()
    }

    if (type === CHILD_EDIT_FORM_TYPE.GENERAL_INFO || type === CHILD_EDIT_FORM_TYPE.ENROLMENT_DETAILS) {
      const childFundingCriteria = childFundingHelpers.getCriteria({
        status: CHILD_FUNDING_FILTERS.ACTIVE,
        type: FUNDING_FEES_TYPES.MANUAL_HOURS,
      }, childId)

      childFundingActions.list({ criteria: childFundingCriteria, limit: 0 }, childId)
    }

    childActions.get({
      onFailed: () => this.setState({ isFetching: false }),
      onSuccess: this.setInitialValues,
      onlyData: true,
      params: [childId, {
        groups,
      }],
    })

    logEvent(EVENTS.CHILD_PROFILE_EDIT_PAGE_VIEWED, { context: type })
  }

  componentWillUnmount() {
    const { destroyForm, ethnicitiesActions } = this.props

    ethnicitiesActions.clear()
    destroyForm()
  }

  setInitialValues = ({ data }) => {
    const {
      childSelectors,
      languagesOptions,
      maritalStatusDropdownOptions,
    } = this.props
    const { type } = this.state

    const initialValues = getInitialValues({
      childSelectors,
      data,
      languagesOptions,
      maritalStatusDropdownOptions,
      type,
    })

    this.setState({ child: data, initialValues, isFetching: false })
  }

  uploadFile = async (file) => {
    const { uploadActions } = this.props

    return uploadActions.uploadFile(file)
  }

  handleSubmit = (formValues) => {
    const {
      OccupancyEnabled,
      formInitialValues,
      hasChildLevelManualFunding,
      modalActions,
      modalConsts,
    } = this.props
    const { type } = this.state

    if (type === CHILD_EDIT_FORM_TYPE.GENERAL_INFO || type === CHILD_EDIT_FORM_TYPE.ENROLMENT_DETAILS) {
      const popupMessage = generatePopupMessage(
        OccupancyEnabled,
        hasChildLevelManualFunding,
        type,
        formInitialValues,
        formValues,
      )

      if (popupMessage) {
        modalActions.show(modalConsts.TYPES.CONFIRM, {
          confirmButtonLabel: i18n.t('global:Confirm'),
          icon: 'warning',
          onConfirm: () => this.submit(formValues),
          text: popupMessage,
        })

        return
      }
    }

    this.submit(formValues)
  }

  childUpdateFailed = (response) => {
    const { injectValidation } = this.props
    const errors = getBackendErrors(response)
    const commonError = response?.extra?.[''] || response?.extra?.isDiffBetweenDateOfBirthAndLeavingDateInvalid

    if (commonError) {
      this.setState({ customErrors: [commonError] })
    }

    if (!errors) {
      return false
    }

    return injectValidation(errors)
  }

  submit = async (formValues) => {
    const {
      changeField,
      childActions,
      childSelectors,
      navigate,
      params,
    } = this.props
    const { type } = this.state
    const { childId } = params

    let body = getPayload({ childSelectors, formValues, type })

    if (false === body?.photo?.isUploaded) {
      const photo = await this.uploadFile(body.photo.file)

      changeField('photo', {
        isUploaded: true,
        value: photo,
      })

      body = {
        ...body,
        photo,
      }
    }

    if (!_.isString(body.photo) && body.photo?.value) {
      body.photo = body.photo.value
    }

    this.setState({ customErrors: [] })

    childActions.update({
      body,
      onFailed: this.childUpdateFailed,
      onSuccess: () => {
        logEvent(EVENTS.CHILD_PROFILE_UPDATED, { context: type })

        navigate(generateRoute('CHILDREN.CHILD.ABOUT.PROFILE', { childId }))
      },
      onlyData: true,
      params: [childId],
    })
  }

  handleArchiveClick = () => {
    const { modalActions, modalConsts } = this.props
    const { child } = this.state

    const action = child.isArchived ? i18n.t('global:unarchive') : i18n.t('global:archive')

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      onConfirm: this.handleArchive,
      text: i18n.t('module:Children:Child:Edit:deleteConfirmationMessage', {
        action,
        childName: child.name,
      }),
    })
  }

  handleArchive = () => {
    const { childActions, navigate, params } = this.props
    const { child } = this.state
    const { childId } = params

    childActions.update({
      body: { isArchived: !child.isArchived },
      onFailed: this.childUpdateFailed,
      onSuccess: () => navigate(generateRoute('CHILDREN.INDEX')),
      onlyData: true,
      params: [childId],
    })
  }

  render() {
    const {
      drinkingMethodsDropdownOptions,
      errorMessages,
      ethnicitiesOptions,
      hasOnlyManagementAccess,
      isSubmitting,
      languagesOptions,
      maritalStatusDropdownOptions,
      params: { childId },
      toiletTrainingDropdownOptions,
    } = this.props
    const { child, customErrors, initialValues, isFetching, type } = this.state

    const isChildArchived = child?.isArchived
    const finalErrors = [...customErrors, ...(errorMessages || [])]

    return (
      <ChildEditView
        childId={childId}
        drinkingMethodsDropdownOptions={drinkingMethodsDropdownOptions}
        errorMessages={finalErrors}
        ethnicitiesOptions={ethnicitiesOptions}
        initialValues={initialValues}
        isChildArchived={isChildArchived}
        isLoading={isFetching}
        isSubmitting={isSubmitting}
        languagesOptions={languagesOptions}
        maritalStatusDropdownOptions={maritalStatusDropdownOptions}
        permissionToArchive={hasOnlyManagementAccess}
        toiletTrainingDropdownOptions={toiletTrainingDropdownOptions}
        type={type}
        onArchiveClick={this.handleArchiveClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

ChildEditContainer.authParams = {
  roles: [
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.SUPER_ADMIN,
    ROLES.ROOM_LEADER,
  ],
}

const mapState = (state, {
  appSelectors,
  childFundingSelectors,
  childSingleState,
  ethnicitiesSelectors,
  securitySelectors,
}) => ({
  OccupancyEnabled: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.OCCUPANCY_ENABLED],
  }),
  drinkingMethodsDropdownOptions: appSelectors.getDrinkingMethodsDropdownOptions(state),
  errorMessages: appSelectors.getErrorMessages(childSingleState),
  ethnicitiesOptions: ethnicitiesSelectors.getEthnicitiesListDataDropdownSelector(state),
  formInitialValues: getFormInitialValues(CHILD_ADD_FORM)(state),
  hasChildLevelManualFunding: childFundingSelectors.hasChildLevelManualFunding(state),
  hasOnlyManagementAccess: securitySelectors.hasOnlyManagementAccess(state),
  isSubmitting: appSelectors.getIsSubmitting(childSingleState),
  languagesOptions: appSelectors.getLanguagesDropdownOptions(state),
  maritalStatusDropdownOptions: appSelectors.getMaritalStatusDropdownOptions(state),
  toiletTrainingDropdownOptions: appSelectors.getToiletTrainingDropdownOptions(state),
})

const mapDispatch = {
  changeField: (field, value) => change(CHILD_ADD_FORM, field, value),
  destroyForm: () => destroy(CHILD_ADD_FORM),
  injectValidation: (data) => stopSubmit(CHILD_ADD_FORM, data),
}

const enhance = compose(
  withRouter,
  withAppService,
  withChildService,
  withChildFundingService,
  withEthnicitiesService,
  withModalService,
  withSecurityService,
  withUploadService,
  connect(mapState, mapDispatch),
)

export default enhance(ChildEditContainer)

