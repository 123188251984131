import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Icon, Tooltip } from 'components'

import { StyledDayContainer } from './MultiRangeCalendarStyled'

interface MultiRangeCalendarDayProps {
  day?: any
  isClosed?: boolean
  tooltipTitle?: string
}
const MultiRangeCalendarDay: React.FC<MultiRangeCalendarDayProps> = ({ day, isClosed, tooltipTitle }) => {
  if (isClosed) {
    return (
      <Tooltip placement="top" title={tooltipTitle}>
        <StyledDayContainer>
          <Icon color={NEUTRAL_COLOURS.BASIC} height={18} icon="blocked" />
        </StyledDayContainer>
      </Tooltip>
    )
  }

  return <StyledDayContainer>{day.getDate()}</StyledDayContainer>
}

export default React.memo(MultiRangeCalendarDay)
