import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { RootState } from 'core/reducers'

import { injectActions } from 'utils/service'

import * as actions from './forecast/actions'
import * as selectors from './forecast/selectors'
import { InvoicesForecastState } from './forecast/reducer'

export interface withInvoicesForecastServiceProps {
  invoicesForecastActions: typeof actions
  invoicesForecastSelectors: typeof selectors
  invoicesForecastState: InvoicesForecastState
}

const withInvoicesForecastService = <P extends withInvoicesForecastServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ invoices }: RootState) => ({ invoices })

  const mapDispatch = injectActions('invoicesForecastActions', actions)

  const Component = (props) => {
    const { invoices, invoicesForecastActions, ...other } = props

    const invoicesForecastState = invoices.forecast
    const invoicesForecastSelectors = selectors

    return (
      <WrappedComponent
        invoicesForecastActions={invoicesForecastActions}
        invoicesForecastSelectors={invoicesForecastSelectors}
        invoicesForecastState={invoicesForecastState}
        {...other as P}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withInvoicesForecastService
