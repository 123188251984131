import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'

import { LEAVE_SHIFT_TYPES, LEAVE_TYPES_COLOURS } from 'services/legacy/membershipsLeaves/constants'

import { millisecondsToHoursAndMinutesString } from 'utils/date'

import {
  DatePicker,
  DropdownMenu,
  EmptyState,
  InfiniteScroll,
  Page,
  Pagination,
  Section,
  Spinner,
  Table,
  Toolbar,
  Tooltip,
  Typography,
} from 'components'

import i18n from 'translations'

import EntitlementNotes from './components/EntitlementNotes'
import { StyledDot, StyledTooltipContent, StyledTooltipContentRow, StyledType } from './StaffLeaveDetailsStyled'
import { getColumns } from './helpers'

const StaffLeaveDetailsView = ({
  authEntitlementNotes,
  date,
  hasPermissionToEdit,
  isFetching,
  onAddLeave,
  onChangeDate,
  onDeleteLeave,
  onEditLeave,
  onExportClick,
  onPageChange,
  page,
  pageCount,
  records,
  statistics,
  totalResults,
}) => {
  const renderRow = (item) => {
    const { dateFrom, dateTo, id, leaveShiftType, membershipShiftTimeStatistics } = item
    const { leave } = membershipShiftTimeStatistics.leave || {}

    return ({
      ...(hasPermissionToEdit && {
        action: (
          <DropdownMenu small>
            <DropdownMenu.Item
              icon="edit"
              label={i18n.t('module:Staff:StaffLeaveDetails:editLeave')}
              onClick={() => onEditLeave(item)}
            />
            <DropdownMenu.Item
              type="delete"
              onClick={() => onDeleteLeave(item)}
            />
          </DropdownMenu>
        ),
      }),
      date: (
        <React.Fragment>
          {moment(dateFrom).format(DISPLAY_SHORT_DATE_FORMAT)}
          {' - '}
          {moment(dateTo).format(DISPLAY_SHORT_DATE_FORMAT)}
        </React.Fragment>
      ),
      hours: (
        <React.Fragment>
          {millisecondsToHoursAndMinutesString(leave)}
        </React.Fragment>
      ),
      id,
      type: (
        <StyledType>
          <StyledDot
            backgroundColor={
              LEAVE_TYPES_COLOURS[leaveShiftType?.code]?.basic || LEAVE_TYPES_COLOURS[LEAVE_SHIFT_TYPES.OTHER].basic
            }
          />
          <Typography>
            {leaveShiftType?.name}
          </Typography>
        </StyledType>
      ),
    })
  }

  const renderOtherLeavesTooltipContent = () => (
    <StyledTooltipContent>
      {_.map(statistics?.others.data, (item) => (
        <StyledTooltipContentRow>
          <Typography>
            {item.name}
          </Typography>
          <Typography>
            {millisecondsToHoursAndMinutesString(parseInt(item.milliseconds))}
          </Typography>
        </StyledTooltipContentRow>
      ))}
    </StyledTooltipContent>
  )

  const renderHeader = () => (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <DatePicker
            value={date}
            range
            onChange={onChangeDate}
          />
        </Toolbar.Item>
      </Toolbar.Group>
      <Toolbar.Group>
        {statistics?.totalSick ? (
          <Toolbar.Item>
            <Typography margin="0 10px" variant="h5">
              {millisecondsToHoursAndMinutesString(statistics.totalSick, true)}
            </Typography>
            <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} margin="0 10px">
              {_.upperCase(i18n.t('module:Staff:StaffLeaveDetails:sickLeave'))}
            </Typography>
          </Toolbar.Item>
        ) : null}
        {statistics?.totalHoliday ? (
          <Toolbar.Item>
            <Typography margin="0 10px" variant="h5">
              {millisecondsToHoursAndMinutesString(statistics.totalHoliday, true)}
            </Typography>
            <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} margin="0 10px">
              {_.upperCase(i18n.t('module:Staff:StaffLeaveDetails:holidays'))}
            </Typography>
          </Toolbar.Item>
        ) : null}
        {statistics?.totalOther ? (
          <Tooltip
            placement="bottom-center"
            title={renderOtherLeavesTooltipContent()}
          >
            <Toolbar.Item>
              <Typography margin="0 0 0 10px" variant="h5">
                {millisecondsToHoursAndMinutesString(statistics.totalOther, true)}
              </Typography>
              <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} margin="0 10px">
                {_.upperCase(i18n.t('module:Staff:StaffLeaveDetails:otherLeave'))}
              </Typography>
            </Toolbar.Item>
          </Tooltip>
        ) : null}
      </Toolbar.Group>
    </Toolbar>
  )

  const renderPagination = () => !isFetching && (
    <Pagination
      totalResults={totalResults}
    />
  )

  const renderContent = () => {
    if (isFetching && 1 === page) {
      return (
        <Spinner />
      )
    }

    if (!isFetching && !records?.length) {
      return (
        <EmptyState
          icon="staff-leave"
          text1={i18n.t('module:Staff:StaffLeaveDetails:notFound')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <InfiniteScroll
          dataLength={records?.length || 0}
          hasMore={page < pageCount}
          isFetching={isFetching}
          next={() => onPageChange((+page) + 1)}
        >
          <Table
            columns={getColumns(hasPermissionToEdit)}
            data={_.map(records, renderRow)}
            minWidth={760}
            visualType="border"
          />
        </InfiniteScroll>
        {renderPagination()}
      </React.Fragment>
    )
  }

  const actions = hasPermissionToEdit && (
    <Section.Actions
      options={[{ onClick: onExportClick, type: 'export' }]}
      primary={[{
        label: i18n.t('module:Staff:StaffLeaveDetails:addLeave'),
        onClick: onAddLeave,
      }]}
    />
  )

  return (
    <React.Fragment>
      {authEntitlementNotes && <EntitlementNotes />}
      <Page.Section
        actions={actions}
        isLoading={isFetching && 1 === page}
        title={i18n.t('module:Staff:StaffLeaveDetails:title')}
      >
        {renderHeader()}
        {renderContent()}
      </Page.Section>
    </React.Fragment>
  )
}

export default StaffLeaveDetailsView
