export const GET_INVOICE = 'GET_INVOICE'
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS'
export const GET_INVOICE_FAILED = 'GET_INVOICE_FAILED'

export const CREATE_INVOICE = 'CREATE_INVOICE'
export const CREATE_INVOICE_SUCCESS = 'CREATE_INVOICE_SUCCESS'
export const CREATE_INVOICE_FAILED = 'CREATE_INVOICE_FAILED'

export const UPDATE_INVOICE = 'UPDATE_INVOICE'
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS'
export const UPDATE_INVOICE_FAILED = 'UPDATE_INVOICE_FAILED'

export const DELETE_INVOICE = 'DELETE_INVOICE'
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS'
export const DELETE_INVOICE_FAILED = 'DELETE_INVOICE_FAILED'

export const DOWNLOAD_INVOICE = 'DOWNLOAD_INVOICE'
export const DOWNLOAD_INVOICE_SUCCESS = 'DOWNLOAD_INVOICE_SUCCESS'
export const DOWNLOAD_INVOICE_FAILED = 'DOWNLOAD_INVOICE_FAILED'

export const AUTO_CALCULATE_INVOICE = 'AUTO_CALCULATE_INVOICE'
export const AUTO_CALCULATE_INVOICE_SUCCESS = 'AUTO_CALCULATE_INVOICE_SUCCESS'
export const AUTO_CALCULATE_INVOICE_FAILED = 'AUTO_CALCULATE_INVOICE_FAILED'

export const AUTO_REFRESH_INVOICE = 'AUTO_REFRESH_INVOICE'
export const AUTO_REFRESH_INVOICE_SUCCESS = 'AUTO_REFRESH_INVOICE_SUCCESS'
export const AUTO_REFRESH_INVOICE_FAILED = 'GET_INVOICE_FAILED'

export const CLEAR_INVOICE = 'CLEAR_INVOICE'
