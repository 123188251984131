import _ from 'lodash'

import { compose } from 'recompose'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { stopSubmit } from 'redux-form'

import { FEATURE_FLAGS } from 'constants/security'

import auth from 'utils/auth'
import { getBackendErrors } from 'utils/backendErrors'

import { withChildMovesService } from 'services/legacy/childMoves'
import { withModalService } from 'services/utils/modal'
import { withRoomsService } from 'services/legacy/rooms'

import EditRoomMoveModalView from './EditRoomMoveModalView'
import { EDIT_ROOM_MOVE_FORM } from './EditRoomMoveForm/EditRoomMoveForm'

class EditRoomMoveModalContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedMoveToRoom: props.childMove && props.childMove.toNurseryClass && props.childMove.toNurseryClass.id,
    }
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleUpdateChildMoveSuccess = () => {
    const { onConfirm } = this.props

    this.handleCloseClick()
    onConfirm()
  }

  handleUpdateChildMoveFailed = (response) => {
    const { injectValidation } = this.props
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    return injectValidation(EDIT_ROOM_MOVE_FORM, errors)
  }

  handleSubmit = (values) => {
    const { childMoveId, childMovesActions, childMovesSelectors } = this.props
    const body = childMovesSelectors.getChildMoveValuesForm(values)

    return childMovesActions.updateChildMove(
      childMoveId,
      body,
      this.handleUpdateChildMoveSuccess,
      this.handleUpdateChildMoveFailed,
    )
  }

  handleChangeMoveTo = ({ value }) => {
    this.setState({
      selectedMoveToRoom: value,
    })
  }

  render() {
    const {
      authAccessMap: { section: { StaffingEnabled } },
      childMoveId,
      childMoves,
      childMovesSelectors,
      isFetching,
      roomList,
      roomsDropdownWithLeaving,
      showWarning,
    } = this.props
    const { selectedMoveToRoom } = this.state

    const childMove = _.find(childMoves, ({ id }) => childMoveId === id)
    const initialValues = childMovesSelectors.getInitialValues(childMove)
    const selectedMoveTo = _.find(roomList, ({ id }) => id === selectedMoveToRoom)

    return (
      <EditRoomMoveModalView
        StaffingEnabled={StaffingEnabled}
        childMove={childMove}
        initialValues={initialValues}
        isProcessing={isFetching}
        roomsWithLeaving={roomsDropdownWithLeaving}
        selectedMoveTo={selectedMoveTo}
        showWarning={showWarning}
        onChangeMoveTo={this.handleChangeMoveTo}
        onCloseClick={this.handleCloseClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  injectValidation: (formName, data) => stopSubmit(formName, data),
}

const mapState = (state, { childMoveId, childMovesSelectors, roomsSelectors }) => ({
  authAccessMap: {
    section: {
      StaffingEnabled: auth.SELECTORS.getIsAuthorised(state, {
        flags: [FEATURE_FLAGS.STAFF_REGISTER],
      }),
    },
  },
  childMoves: childMovesSelectors.getChildMovesListDataSelector(state),
  isFetching: childMovesSelectors.getChildMovesSingleIsFetchingSelector(state),
  roomList: roomsSelectors.getRoomsListDataSelector(state),
  roomsDropdownWithLeaving: roomsSelectors.getRoomsOptionsDropdownWithLeavingData(state),
  showWarning: childMovesSelectors.getWarningVisibilityFlag(childMoveId, EDIT_ROOM_MOVE_FORM)(state),
})

const enhance = compose(
  withChildMovesService,
  withModalService,
  withRoomsService,
  connect(mapState, mapDispatch),
)

export default enhance(EditRoomMoveModalContainer)
