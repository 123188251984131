import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { TOILET_TRAINING_OPTIONS } from 'services/legacy/dailyDiaryToiletTraining/constants'

import { isRequired } from 'utils/fieldValidation'

import { Checkbox, Form, GridLayout } from 'components'

import i18n from 'translations'

export const TOILET_TRAINING_TAB_FORM = 'ToiletTrainingTabForm'

const ToiletTrainingTabForm = ({
  addNappyChange,
  handleSubmit,
  isSubmitting,
  onAddNappyChange,
  onCancel,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <GridLayout>
      <GridLayout.Group noVerticalMargin>
        <GridLayout.Item column>
          <Form.Row label={i18n.t('module:DailyDiary:Nappies:used')} verticalLabel>
            <Form.Row.Item>
              <Field
                component={Form.RadioGroup}
                disabled={isSubmitting}
                name="type"
                options={TOILET_TRAINING_OPTIONS}
                validate={isRequired}
              />
            </Form.Row.Item>
          </Form.Row>
        </GridLayout.Item>
        <GridLayout.Item
          flex="3"
          column
        >
          <Form.Row label={i18n.t('module:DailyDiary:Nappies:accident')} labelMargin="0px" verticalLabel>
            <Form.Row.Item>
              <Field
                component={Form.Switch}
                disabled={isSubmitting}
                name="accident"
              />
            </Form.Row.Item>
          </Form.Row>
          <Form.Row label={i18n.t('global:Time')} verticalLabel>
            <Form.Row.Item>
              <Field
                component={Form.TimePicker}
                disabled={isSubmitting}
                name="recordedAt"
                validate={isRequired}
              />
            </Form.Row.Item>
          </Form.Row>
        </GridLayout.Item>
      </GridLayout.Group>
    </GridLayout>
    <Form.Row label={i18n.t('module:DailyDiary:global:additionalNotes')} verticalLabel>
      <Form.Row.FlexItem flex="0 0 420px" mobileFull>
        <Field
          component={Form.TextAreaField}
          disabled={isSubmitting}
          height={120}
          maxCharacters={2000}
          name="comments"
          placeholder={i18n.t('module:DailyDiary:global:enterAdditionalNotesHere')}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.Row>
      <Form.Row.FlexItem flex="0 0 220px">
        <Checkbox
          label={i18n.t('module:DailyDiary:Nappies:addNappyFeed')}
          value={addNappyChange}
          onChange={onAddNappyChange}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.FooterActions
      isSubmitting={isSubmitting}
      onCancelClick={onCancel}
    />
  </Form>
)

export default reduxForm({ form: TOILET_TRAINING_TAB_FORM })(ToiletTrainingTabForm)
