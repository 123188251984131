import React, { PropsWithChildren } from 'react'

import { Button } from 'components'

import { ButtonProps } from './Button'

interface ButtonTableActionProps extends ButtonProps {
  edit?: boolean
}

const ButtonTableAction: React.FC<PropsWithChildren<ButtonTableActionProps>> = ({
  edit,
  icon = 'chevron-right',
  ...rest
}) => (
  <Button
    {...rest}
    hierarchy="secondary"
    icon={edit ? 'edit' : icon}
    size="small"
  />
)

export default ButtonTableAction
