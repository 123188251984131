import React from 'react'

import { StyledItemContainer } from './GridLayoutStyled'

const GridLayoutItem = ({
  alignItems,
  bottomMarginOnMobile,
  children,
  column,
  disableRwd,
  flex = 1,
  mobileFull,
  overflow,
  padding,
}) => (
  <StyledItemContainer
    $alignItems={alignItems}
    $bottomMarginOnMobile={bottomMarginOnMobile}
    $column={column}
    $disableRwd={disableRwd}
    $flex={flex}
    $mobileFull={mobileFull}
    $overflow={overflow}
    $padding={padding}
  >
    {children}
  </StyledItemContainer>
)

export default GridLayoutItem
