import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_SINGLE_DEVICE_TOKENS,
  CREATE_DEVICE_TOKENS,
  CREATE_DEVICE_TOKENS_FAILED,
  CREATE_DEVICE_TOKENS_SUCCESS,
  GET_DEVICE_TOKEN,
  GET_DEVICE_TOKEN_FAILED,
  GET_DEVICE_TOKEN_SUCCESS,
  INSTALL_DEVICE_TOKEN,
  INSTALL_DEVICE_TOKEN_FAILED,
  INSTALL_DEVICE_TOKEN_SUCCESS,
  REMOVE_DEVICE_TOKENS,
  REMOVE_DEVICE_TOKENS_FAILED,
  REMOVE_DEVICE_TOKENS_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export default (...props) => {
  const [state = initialState, { type }] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_SINGLE_DEVICE_TOKENS,
    createFailed: CREATE_DEVICE_TOKENS_FAILED,
    createInit: CREATE_DEVICE_TOKENS,
    createSuccess: CREATE_DEVICE_TOKENS_SUCCESS,
    itemFailed: GET_DEVICE_TOKEN_FAILED,
    itemInit: GET_DEVICE_TOKEN,
    itemSuccess: GET_DEVICE_TOKEN_SUCCESS,
    removeFailed: REMOVE_DEVICE_TOKENS_FAILED,
    removeInit: REMOVE_DEVICE_TOKENS,
    removeSuccess: REMOVE_DEVICE_TOKENS_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  if (type === INSTALL_DEVICE_TOKEN) {
    return {
      ...state,
      isSubmitting: true,
    }
  }

  if (type === INSTALL_DEVICE_TOKEN_FAILED || type === INSTALL_DEVICE_TOKEN_SUCCESS) {
    return {
      ...state,
      isSubmitting: true,
    }
  }

  return state
}
