import React from 'react'

import InlineEditableField from 'module/Management/components/InlineEditableField'

import i18n from 'translations'

import ShowChildOverViewOnFormativeReportsForm, {
  SHOW_CHILD_OVER_VIEW_ON_FORMATIVE_REPORTS_FORM,
} from './ShowChildOverViewOnFormativeReportsForm'

const ShowChildOverViewOnFormativeReports = ({ onSubmit, value }) => {
  const initialValues = { childOverviewSectionOnFormativeReportsVisible: value }

  return (
    <InlineEditableField
      editableComponent={(callback) => (
        <ShowChildOverViewOnFormativeReportsForm
          initialValues={initialValues}
          onSubmit={callback}
        />
      )}
      formName={SHOW_CHILD_OVER_VIEW_ON_FORMATIVE_REPORTS_FORM}
      title={i18n.t('module:Management:General:LearningTrackingReporting:ShowChildOverViewOnFormativeReports:title')}
      value={value}
      onSubmit={onSubmit}
    />
  )
}

export default ShowChildOverViewOnFormativeReports
