import React, { useEffect } from 'react'
import { compose } from 'recompose'

import { SelectProps } from 'components/Select/Select'
import { InfinityDropdownProps } from 'components/InfiniteDropdowns/InfiniteDropdownsModel'
import { PERIODS_TYPE } from 'services/booking/periods/constants'

import { withPeriodsService, withPeriodsServiceProps } from 'services/booking/periods'

import { Select } from 'components'

import i18n from 'translations'

import withInfiniteDropdownHelpers, {
  withInfiniteDropdownHelpersProps,
} from '../../legacy/InfiniteDropdowns/withInfiniteDropdown'

export type PeriodsDropdownProps = withInfiniteDropdownHelpersProps
  & withPeriodsServiceProps
  & SelectProps
  & InfinityDropdownProps

const PeriodsDropdown: React.FC<PeriodsDropdownProps> = ({
  extraFields = [],
  infiniteDropdownHelpers,
  periodsActions,
  onChange,
  value,
  ...rest
}) => {
  useEffect(() => {
    infiniteDropdownHelpers.handleComponentDidMount({
      action: () => periodsActions.get({
        onFailed: () => onChange(null),
        onSuccess: infiniteDropdownHelpers.handleComponentDidMountOnSuccess,
        onlyData: true,
        params: [(value as any)?.value || value],
      }),
      value,
    })
  }, [])

  const listAction = ({ newPage, ...others }) => {
    const criteria = [
      {
        field: 'archived',
        value: 0,
      },
      {
        field: 'type',
        value: PERIODS_TYPE.ATTENDANCE,
      },
    ]

    return periodsActions.dropdownList({
      onSuccess: infiniteDropdownHelpers.handleLoadMoreElementsOnSuccessV2({
        extraFields,
        newPage,
        ...others,
      }),
      params: {
        criteria,
        page: newPage,
      },
    })
  }

  return (
    <Select
      {...infiniteDropdownHelpers.getSelectRestParams({ onChange, value, ...rest })}
      loadOptions={infiniteDropdownHelpers.handleLoadMoreElementsV2({ listAction })}
      placeholder={i18n.t('components:InfiniteDropdowns:Periods:placeholder')}
    />
  )
}

const enhance = compose(
  withPeriodsService,
  withInfiniteDropdownHelpers,
)

export default enhance(PeriodsDropdown)
