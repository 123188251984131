import _ from 'lodash'

import React from 'react'

import { TimeString } from 'components'

export const getOpeningDays = (openingDays) => {
  if (!openingDays || !openingDays.length) {
    return null
  }

  const mapDays = (days) => ({
    ...days,
    description:
      days.isClosed
        ? 'Closed'
        : <TimeString.Range end={days.endTime} local={false} start={days.startTime} />,
  })

  return _.map(openingDays, mapDays)
}
