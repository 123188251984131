import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { RootState } from 'core/reducers'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as enquiriesSelectors from './selectors'

// Added temporary to compatible with TS
export interface withEnquiriesServiceProps {
  enquiriesActions: typeof actions
  enquiriesFormState: any
  enquiriesListState: any
  enquiriesSelectors: typeof enquiriesSelectors
  enquiriesSingleState: any
}

const withEnquiriesService = <P extends withEnquiriesServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ enquiries }: RootState) => ({ enquiries })

  const mapDispatch = injectActions('enquiriesActions', actions)

  const Component = ({ enquiries, enquiriesActions, ...other }) => (
    <WrappedComponent
      enquiriesActions={enquiriesActions}
      enquiriesFormState={enquiries.form}
      enquiriesListState={enquiries.list}
      enquiriesSelectors={enquiriesSelectors}
      enquiriesSingleState={enquiries.single}
      {...other as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withEnquiriesService
