import { generateBasicActions } from 'utils/service'

import {
  CLEAR_PERIOD_TIMES_LIST,
  CREATE_PERIOD_TIMES_BATCH,
  CREATE_PERIOD_TIMES_BATCH_FAILED,
  CREATE_PERIOD_TIMES_BATCH_SUCCESS,
  GET_PERIOD_TIMES_LIST,
  GET_PERIOD_TIMES_LIST_FAILED,
  GET_PERIOD_TIMES_LIST_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ periodTimesApiClient }) => (
  generateBasicActions.list({
    constants: {
      failed: GET_PERIOD_TIMES_LIST_FAILED,
      init: GET_PERIOD_TIMES_LIST,
      success: GET_PERIOD_TIMES_LIST_SUCCESS,
    },
    options,
    service: periodTimesApiClient,
    serviceMethod: 'list',
  })
)

export const batch = (options = {}) => ({ periodTimesApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: CREATE_PERIOD_TIMES_BATCH_FAILED,
      init: CREATE_PERIOD_TIMES_BATCH,
      success: CREATE_PERIOD_TIMES_BATCH_SUCCESS,
    },
    options,
    service: periodTimesApiClient,
    serviceMethod: 'batch',
  })
)

export const clearList = () => ({
  type: CLEAR_PERIOD_TIMES_LIST,
})
