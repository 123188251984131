import { combineReducers } from 'redux'

import root, { SnackbarRootState } from './root/reducer'

export default combineReducers({
  root,
})

export interface SnackbarState {
  root: SnackbarRootState
}
