import React from 'react'

import { ModalBox } from 'components'

import DietaryReportExportForm from './components/DietaryReportExportForm'

const DietaryReportExportModalView = ({
  initialValues,
  isProcessing,
  isReportTypeAllergiesAndDietaryPreferences,
  onCloseClick,
  onReportTypeChange,
  onShowAllOrSomeChildrenChange,
  onSubmit,
  title,
}) => (
  <ModalBox
    title={title}
    autoHeight
    onCloseClick={onCloseClick}
  >
    <DietaryReportExportForm
      initialValues={initialValues}
      isProcessing={isProcessing}
      isReportTypeAllergiesAndDietaryPreferences={isReportTypeAllergiesAndDietaryPreferences}
      onCloseClick={onCloseClick}
      onReportTypeChange={onReportTypeChange}
      onShowAllOrSomeChildrenChange={onShowAllOrSomeChildrenChange}
      onSubmit={onSubmit}
    />
  </ModalBox>
)

export default DietaryReportExportModalView
