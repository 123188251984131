import Staff from 'module/Staff/Staff'
import StaffAdd from 'module/Staff/StaffAdd'
import StaffContractsWrapper from 'module/Staff/StaffContracts'
import StaffContractsAdd from 'module/Staff/StaffContracts/StaffContractsAdd'
import StaffContractsList from 'module/Staff/StaffContracts/StaffContractsList'
import StaffContractsPreview from 'module/Staff/StaffContracts/StaffContractsPreview'
import StaffDetails from 'module/Staff/StaffDetails'
import StaffLeaveDetails from 'module/Staff/StaffLeaveDetails'
import StaffFileUploads from 'module/Staff/StaffFileUploads'

import i18n from 'translations'

export const ROUTE_NAMES = {
  'ACCOUNT.ABOUT': 'ACCOUNT.ABOUT',
  'ACCOUNT.CONTRACTS': 'ACCOUNT.CONTRACTS',
  'ACCOUNT.CONTRACTS.ADD': 'ACCOUNT.CONTRACTS.ADD',
  'ACCOUNT.CONTRACTS.EDIT': 'ACCOUNT.CONTRACTS.EDIT',
  'ACCOUNT.CONTRACTS.LIST': 'ACCOUNT.CONTRACTS.LIST',
  'ACCOUNT.CONTRACTS.PREVIEW': 'ACCOUNT.CONTRACTS.PREVIEW',
  'ACCOUNT.EDIT': 'ACCOUNT.EDIT',
  'ACCOUNT.FILES': 'ACCOUNT.FILES',
  'ACCOUNT.INDEX': 'ACCOUNT.INDEX',
  'ACCOUNT.LEAVE': 'ACCOUNT.LEAVE',
}

const AccountRouting = {
  childRoutes: [
    {
      component: StaffAdd,
      name: ROUTE_NAMES['ACCOUNT.EDIT'],
      path: 'edit',
      title: i18n.t('module:Account:Routing:edit'),
    },
    {
      component: StaffDetails,
      name: ROUTE_NAMES['ACCOUNT.ABOUT'],
      path: 'about',
      title: i18n.t('module:Staff:StaffDetails:Title:staffMemberDetails'),
    },
    {
      childRoutes: [
        {
          component: StaffContractsList,
          name: ROUTE_NAMES['ACCOUNT.CONTRACTS.LIST'],
          path: 'list',
          title: i18n.t('module:Staff:StaffContracts:List:title'),
        },
        {
          component: StaffContractsAdd,
          name: ROUTE_NAMES['ACCOUNT.CONTRACTS.ADD'],
          path: 'add',
          title: i18n.t('module:Staff:StaffContracts:Add:title'),
        },
        {
          component: StaffContractsAdd,
          name: ROUTE_NAMES['ACCOUNT.CONTRACTS.EDIT'],
          path: ':contractId/edit',
          title: i18n.t('module:Staff:StaffContracts:Edit:title'),
        },
        {
          component: StaffContractsPreview,
          name: ROUTE_NAMES['ACCOUNT.CONTRACTS.PREVIEW'],
          path: ':contractId/preview',
          title: i18n.t('module:Staff:StaffContracts:Preview:title'),
        },
      ],
      component: StaffContractsWrapper,
      name: ROUTE_NAMES['ACCOUNT.CONTRACTS'],
      path: 'contracts',
      title: i18n.t('module:Staff:StaffContracts:title'),
    },
    {
      component: StaffLeaveDetails,
      name: ROUTE_NAMES['ACCOUNT.LEAVE'],
      path: 'leave',
      title: i18n.t('module:Staff:SubMenu:leave'),
    },
    {
      component: StaffFileUploads,
      name: ROUTE_NAMES['ACCOUNT.FILES'],
      path: 'files',
      title: i18n.t('services:Files:title'),
    },
  ],
  component: Staff,
  name: ROUTE_NAMES['ACCOUNT.INDEX'],
  path: 'my-details',
  title: i18n.t('module:Account:Routing:index'),
}

AccountRouting.ROUTE_NAMES = ROUTE_NAMES

export default AccountRouting
