import _ from 'lodash'

import React from 'react'

import { NurseryItemProduct } from 'services/product/nurseryItem/models'
import { ITEM_FILTER_OPTIONS } from 'services/product/nurseryItem/constants'
import { PaginationDetails } from 'services/utils/pagination/constants'

import { generateRoute } from 'utils/routing'

import { getCurrentPriceFromPriceChanges } from 'services/product/selectors'

import {
  Button,
  Callout,
  Currency,
  EmptyState,
  Page,
  Pagination,
  Section,
  Select,
  Spinner,
  Table,
  Toolbar,
} from 'components'

import i18n from 'translations'

import { ManagementItemsFilters } from './ManagementItemsContainer'

const COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'name',
    sortable: true,
    title: i18n.t('module:Management:Finance:Item:List:items'),
  },
  {
    field: 'cost',
    title: i18n.t('module:Management:Finance:Item:List:cost'),
    width: '15%',
  },
  {
    align: 'center',
    field: 'action',
    width: '80px',
  },
]

interface ManagementItemListViewProps {
  errorMessages: string[]
  filters: ManagementItemsFilters
  isFetching: boolean
  onFilterChange: <K extends keyof ManagementItemsFilters>(key: K) => (value: ManagementItemsFilters[K]) => void
  pagination: PaginationDetails
  records: NurseryItemProduct[]
}

const ManagementItemListView: React.FC<ManagementItemListViewProps> = ({
  errorMessages,
  filters,
  isFetching,
  onFilterChange,
  pagination,
  records,
}) => {
  const renderPagination = () => !isFetching && (
    <Pagination {...pagination} />
  )

  const renderRecord = (record: NurseryItemProduct) => {
    const { archived, id, name, priceChanges } = record
    const price = getCurrentPriceFromPriceChanges(priceChanges)

    let finalName = name

    if (archived) {
      finalName = `${name} (${i18n.t('global:archived')})`
    }

    return ({
      action: (
        <Button
          hierarchy="tertiary"
          icon="edit"
          size="small"
          to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.ITEMS.EDIT', { itemId: id })}
          negativeMargins
        />
      ),
      cost: price ? <Currency value={price} /> : '-',
      name: finalName,
    })
  }

  const renderFilters = () => (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <Select
            clearable={false}
            options={ITEM_FILTER_OPTIONS}
            placeholder={_.upperFirst(i18n.t('global:status'))}
            searchable={false}
            value={filters.status}
            v2
            onChange={onFilterChange('status')}
          />
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )

  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    if (!isFetching && !records?.length) {
      return (
        <EmptyState
          icon="extra-session"
          text1={i18n.t('module:Management:Finance:Item:List:notFound')}
        />
      )
    }

    return (
      <div>
        {renderPagination()}
        <Table
          columns={COLUMNS}
          data={_.map(records, renderRecord)}
          minWidth={600}
        />
        {renderPagination()}
      </div>
    )
  }

  const actions = (
    <Section.Actions
      primary={[{ to: generateRoute('MANAGEMENT.FINANCE_SETTINGS.ITEMS.ADD') }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isFetching}
      title={i18n.t('module:Management:Finance:Item:title')}
    >
      <Callout content={errorMessages} error />
      {renderFilters()}
      {renderContent()}
    </Page.Section>
  )
}

export default ManagementItemListView
