import { flatten, nest } from 'utils/flatnest'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { NEWS_STATUSES, TYPE_OPTIONS } from 'services/legacy/newsletters/constants'

import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withRouterUtils } from 'services/utils/router'
import { withNewslettersService } from 'services/legacy/newsletters'
import { withModalService } from 'services/utils/modal'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouter } from 'services/router'

import i18n from 'translations'

import NewslettersListView from './NewslettersListView'
import NewslettersAdd from '../NewslettersAdd'

const NEWSLETTERS_GROUPS = { read: ['newsletter.filters', 'user'] }

class NewslettersListContainer extends Component {
  constructor(props) {
    super(props)

    const { location } = props
    const { query } = location
    const { date, search, sharedWith, type } = nest(query)

    const after = date?.after ? moment(date.after).format(DEFAULT_DATE_FORMAT) : undefined
    const before = date?.before ? moment(date.before).format(DEFAULT_DATE_FORMAT) : undefined

    this.state = {
      filters: {
        date: {
          after,
          before,
        },
        search,
        sharedWith,
        type: type || NEWS_STATUSES.ALL,
      },
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { newsActions } = this.props

    newsActions.clear()
  }

  fetch = () => {
    const { newsActions, newsSelectors, paginationUtils, setLocationQuery } = this.props
    const { filters } = this.state
    const { page, perPage } = paginationUtils

    setLocationQuery(flatten(filters))

    const criteria = newsSelectors.getNewsListCriteriaSelector(filters)

    const apiParams = {
      criteria,
      groups: NEWSLETTERS_GROUPS,
      limit: perPage,
      page,
    }

    newsActions.list(apiParams)
  }

  handleDateChange = (dateRange) => {
    const [after, before] = dateRange
    const date = {
      after: after ? moment(after).format(DEFAULT_DATE_FORMAT) : undefined,
      before: before ? moment(before).format(DEFAULT_DATE_FORMAT) : undefined,
    }

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          date,
        },
      }),
      this.fetch,
    )
  }

  handleSearchBarChange = (search) => {
    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, search },
      }),
      this.fetch,
    )
  }

  handleSharedWithChange = (sharedWith) => {
    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          sharedWith,
        },
      }),
      this.fetch,
    )
  }

  handleTypeChange = ({ value: type }) => {
    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, type },
      }),
      this.fetch,
    )
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  delete = (newsId) => {
    const { newsActions } = this.props

    return newsActions.remove(newsId, this.fetch)
  }

  handleDeleteNewsItem = (newsId) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'trash',
      onConfirm: () => this.delete(newsId),
      text: (
        <React.Fragment>
          {i18n.t('module:Newsletters:List:deleteText1')}
          <strong>{i18n.t('module:Newsletters:List:deleteText2')}</strong>
        </React.Fragment>
      ),
    })
  }

  render() {
    const { authAccessMap, isFetching, news, paginationUtils, totalResults } = this.props
    const { filters: { date, search, sharedWith, type } } = this.state
    const { getPageCount, page } = paginationUtils
    const pageCount = getPageCount(totalResults)

    const dateRange = [date.after, date.before]

    return (
      <NewslettersListView
        authAccessMap={authAccessMap}
        date={dateRange}
        isFetching={isFetching}
        news={news}
        page={page}
        pageCount={pageCount}
        searchBarValue={search}
        sharedWith={sharedWith}
        totalResults={totalResults}
        type={type}
        typeOptions={TYPE_OPTIONS}
        onDateChange={this.handleDateChange}
        onDeleteNewsItem={this.handleDeleteNewsItem}
        onPageChange={this.handlePageChange}
        onSearchBarChange={this.handleSearchBarChange}
        onSharedWithChange={this.handleSharedWithChange}
        onTypeChange={this.handleTypeChange}
      />
    )
  }
}

const mapState = (state, { appSelectors, newsListState, newsSelectors }) => ({
  authAccessMap: {
    module: {
      NewslettersAdd: auth.SELECTORS.getComponentIsAuthorised(state, NewslettersAdd),
    },
  },
  isFetching: appSelectors.getIsFetching(newsListState),
  news: newsSelectors.getNewsListFormattedDataSelector(state),
  totalResults: appSelectors.getTotalResults(newsListState),
})

const enhance = compose(
  withAppService,
  withRouterUtils,
  withPaginationUtils,
  withNewslettersService,
  withModalService,
  withRouter,
  connect(mapState),
)

export default enhance(NewslettersListContainer)
