import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

const getLeaveNoteSelector = (state) => state.leaveNotes.single

export const getLeaveNoteDataSelector = createSelector([getLeaveNoteSelector], (state) => state.data)

export const getInitialValues = createSelector(
  [getLeaveNoteDataSelector],
  (data) => data || null,
)

export const getPayload = (fields, membershipId) => {
  if (!fields) {
    return null
  }

  const {
    comment,
    entitlementHours,
    holidayPeriod,
  } = fields

  const [startDate, endDate] = holidayPeriod

  return {
    comment,
    endDate: moment(endDate).format(DEFAULT_DATE_FORMAT),
    entitlementHours: +entitlementHours,
    membership: {
      id: +membershipId,
    },
    startDate: moment(startDate).format(DEFAULT_DATE_FORMAT),
  }
}
