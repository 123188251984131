import i18n from 'translations'

export const getColumns = (authAccessMap) => {
  const { section: { PremiumSection, StaffingEnabled } } = authAccessMap

  if (PremiumSection && StaffingEnabled) {
    return [
      {
        align: 'left',
        field: 'name',
        sortable: true,
        title: i18n.t('module:Rooms:TableHeaders:roomName'),
      },
      {
        align: 'center',
        field: 'type',
        sortable: true,
        title: i18n.t('global:Type'),
      },
      {
        align: 'center',
        field: 'capacity',
        sortable: true,
        title: i18n.t('module:Rooms:TableHeaders:capacity'),
      },
      {
        align: 'center',
        field: 'startAgeInMonths',
        sortable: true,
        title: i18n.t('module:Rooms:TableHeaders:ageRange'),
      },
      {
        align: 'center',
        field: 'staffRequired',
        sortable: true,
        title: i18n.t('module:Rooms:TableHeaders:minStaffRequired'),
      },
      {
        field: 'edit',
      },
    ]
  }

  // NOTE: TO BE REMOVED AFTER STAFFING ENABLED TO EVERYONE
  if (PremiumSection && !StaffingEnabled) {
    return [
      {
        align: 'left',
        field: 'name',
        sortable: true,
        title: i18n.t('module:Rooms:TableHeaders:roomName'),
      },
      {
        align: 'center',
        field: 'capacity',
        sortable: true,
        title: i18n.t('module:Rooms:TableHeaders:capacity'),
      },
      {
        align: 'center',
        field: 'startAgeInMonths',
        sortable: true,
        title: i18n.t('module:Rooms:TableHeaders:ageRange'),
      },
      {
        align: 'center',
        field: 'ratio',
        sortable: true,
        title: i18n.t('module:Rooms:TableHeaders:ratio'),
      },
      {
        align: 'center',
        field: 'staffRequired',
        sortable: true,
        title: i18n.t('module:Rooms:TableHeaders:minStaffRequired'),
      },
      {
        field: 'edit',
      },
    ]
  }

  return [
    {
      align: 'left',
      field: 'name',
      sortable: true,
      title: i18n.t('module:Rooms:TableHeaders:roomName'),
    },
    {
      field: 'edit',
    },
  ]
}
