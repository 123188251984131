import React from 'react'
import i18n from 'translations'

import { generateRoute } from 'utils/routing'

import { Button, DefinitionList, EmptyState } from 'components'

import { StyledContainer, StyledContent } from './FinanceBankDetailsStyled'

const FinanceBankDetails = ({ bankData, childId, disabled, isEdit, isUkCountry }) => {
  if (!isEdit) {
    return (
      <EmptyState
        actions={(
          <Button
            disabled={disabled}
            label={i18n.t('module:Children:Child:Finance:BankDetails:EmptyState:buttonLabel')}
            to={!disabled && generateRoute('CHILDREN.CHILD.FINANCE.BANK', { childId })}
          />
        )}
        icon="bank"
        text1={i18n.t('module:Children:Child:Finance:BankDetails:EmptyState:text1')}
      />
    )
  }
  const { accountName, accountNumber, bankAccountNotes, bankName, sortCode, usualPaymentMethod } = bankData

  return (
    <StyledContainer>
      <StyledContent $overflow="hidden">
        <DefinitionList minLabelWidth="200px" vertical>
          <DefinitionList.Item
            label={i18n.t('module:Children:Child:Finance:BankDetails:accountNameLabel')}
            value={accountName}
            whiteSpace="pre-line"
            wordBreak="break-word"
            horizontal
          />
          <DefinitionList.Item
            label={i18n.t('module:Children:Child:Finance:BankDetails:accountNumberLabel')}
            value={accountNumber}
            horizontal
          />
          {isUkCountry && (
            <DefinitionList.Item
              label={i18n.t('module:Children:Child:Finance:BankDetails:sortCodeLabel')}
              value={sortCode || '-'}
              horizontal
            />
          )}
          {!isUkCountry && (
            <DefinitionList.Item
              label={i18n.t('module:Children:Child:Finance:BankDetails:notesLabel')}
              value={bankAccountNotes || '-'}
              whiteSpace="pre-line"
              wordBreak="break-word"
              horizontal
            />
          )}
          <DefinitionList.Item
            label={i18n.t('module:Children:Child:Finance:BankDetails:bankNameLabel')}
            value={bankName}
            whiteSpace="pre-line"
            wordBreak="break-word"
            horizontal
          />
          <DefinitionList.Item
            label={i18n.t('module:Children:Child:Finance:BankDetails:usualPaymentMethodLabel')}
            value={usualPaymentMethod?.name}
            horizontal
          />
        </DefinitionList>
      </StyledContent>
      <StyledContent $flex={0}>
        <Button
          hierarchy="tertiary"
          label={i18n.t('global:Edit')}
          size="small"
          to={generateRoute('CHILDREN.CHILD.FINANCE.BANK', { childId })}
          negativeMargins
        />
      </StyledContent>
    </StyledContainer>
  )
}

export default FinanceBankDetails
