import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { withAppService } from 'services/app'
import { withPaginationUtils } from 'services/utils/pagination'
import { withOrganizationService } from 'services/organization'
import { withNurseriesService } from 'services/nurseries'
import { getAuthOrganization } from 'services/security/selectors'

import SettingsInvoiceNumbersView from './SettingsInvoiceNumbersView'

const ORGANIZATION_INVOICE_NUMBER_SETTINGS_GROUPS = {
  read: [
    'organization.organizationSettings',
    'organizationSettings.invoice',
    'organizationInvoiceSettings.numbers',
    'invoiceNumbers',
  ],
}

class SettingsInvoiceNumbersContainer extends Component {
  componentDidMount() {
    const { authOrganization, organizationActions } = this.props

    const apiParams = { groups: ORGANIZATION_INVOICE_NUMBER_SETTINGS_GROUPS }

    organizationActions.get(authOrganization.id, { params: apiParams })
    this.fetch()
  }

  componentWillUnmount() {
    const { nurseriesActions, organizationActions } = this.props

    nurseriesActions.clear()
    organizationActions.clearSingle()
  }

  fetch = () => {
    const { nurseriesActions, paginationUtils: { page } } = this.props

    nurseriesActions.list({
      mergeResult: 1 !== page,
      params: { page },
      recurrency: true,
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  render() {
    const {
      errorMessages,
      globalNumberType,
      globalNumberTypeDescription,
      isFetching,
      isNurseriesEmpty,
      nurseries,
      organizationConstants,
      organizationInvoiceNumberSettings,
      paginationUtils,
      totalResults,
    } = this.props
    const { GLOBAL_NUMBER_SETTINGS } = organizationConstants
    const { getPageCount, page, perPage } = paginationUtils

    const pageCount = getPageCount(totalResults)
    const isAcrossOrganizationSelected = globalNumberType === GLOBAL_NUMBER_SETTINGS.ACROSS_ORGANIZATION

    return (
      <SettingsInvoiceNumbersView
        errorMessages={errorMessages}
        globalNumberTypeDescription={globalNumberTypeDescription}
        isAcrossOrganizationSelected={isAcrossOrganizationSelected}
        isEmpty={isNurseriesEmpty}
        isLoading={isFetching}
        nurseries={nurseries}
        organizationInvoiceNumberSettings={organizationInvoiceNumberSettings}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        totalResults={totalResults}
        onPageChange={this.handlePageChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseriesListState,
  nurseriesSelectors,
  organizationSelectors,
  organizationSingleState,
}) => ({
  authOrganization: getAuthOrganization(state),
  errorMessages: appSelectors.getErrorMessages(nurseriesListState, organizationSingleState),
  globalNumberType: organizationSelectors.getGlobalNumberType(state),
  globalNumberTypeDescription: organizationSelectors.getGlobalNumberTypeDescription(state),
  isFetching: appSelectors.getIsFetching(organizationSingleState, nurseriesListState),
  isNurseriesEmpty: nurseriesSelectors.isListEmpty(state),
  nurseries: nurseriesSelectors.getNurseriesListDataSelector(state),
  organizationInvoiceNumberSettings:
    organizationSelectors.getInvoiceNumberWithDescriptions(state),
  totalResults: appSelectors.getTotalResults(nurseriesListState),
})

const enhance = compose(
  withAppService,
  withNurseriesService,
  withOrganizationService,
  withPaginationUtils,
  connect(mapState),
)

export default enhance(SettingsInvoiceNumbersContainer)
