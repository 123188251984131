import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as frameworkProgressLevelsSelectors from './selectors'

const withFrameworkProgressLevelsService = (WrappedComponent) => {
  const mapState = ({ frameworkProgressLevels }) => ({ frameworkProgressLevels })

  const mapDispatch = injectActions('frameworkProgressLevelsActions', actions)

  const Component = ({ frameworkProgressLevels, frameworkProgressLevelsActions, ...other }) => (
    <WrappedComponent
      frameworkProgressLevelsActions={frameworkProgressLevelsActions}
      frameworkProgressLevelsListState={frameworkProgressLevels.list}
      frameworkProgressLevelsSelectors={frameworkProgressLevelsSelectors}
      {...other}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withFrameworkProgressLevelsService
