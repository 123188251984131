import Occupancy from 'module/Occupancy/index'
import OccupancyDashboard from 'module/Occupancy/OccupancyDashboard'
import OccupancyReportGenerator from 'module/Occupancy/OccupancyReportGenerator'
import OccupancyReport from 'module/Occupancy/OccupancyReport'

export const ROUTE_NAMES = {
  'OCCUPANCY.GENERATOR': 'OCCUPANCY.GENERATOR',
  'OCCUPANCY.INDEX': 'OCCUPANCY.INDEX',
  'OCCUPANCY.REPORT': 'OCCUPANCY.REPORT',
}

const OccupancyRouting = {
  childRoutes: [
    {
      component: OccupancyReportGenerator,
      name: ROUTE_NAMES['OCCUPANCY.GENERATOR'],
      path: 'generator',
    },
    {
      component: OccupancyReport,
      name: ROUTE_NAMES['OCCUPANCY.REPORT'],
      path: 'report',
      title: 'Occupancy report',
    },
  ],
  component: Occupancy,
  indexRoute: {
    component: OccupancyDashboard,
  },
  name: ROUTE_NAMES['OCCUPANCY.INDEX'],
  path: 'occupancy',
  title: 'Occupancy',
}

OccupancyRouting.ROUTE_NAMES = ROUTE_NAMES

export default OccupancyRouting
