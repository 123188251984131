import React from 'react'

import { Currency, Spinner } from 'components'

import {
  StyledContainer,
  StyledContainerSpinner,
  StyledHeader,
  StyledHeaderContainer,
  StyledHeaderGroup,
  StyledTitle,
  StyledTotal,
  StyledValue,
} from './IncomeDetailStyled'

const IncomeDetail = ({ statistics }) => {
  const { data, isLoading, monthName } = statistics
  const {
    totalFundingIncome,
    totalIncome,
    totalInvoices,
  } = data || {}

  const renderContent = () => {
    if (isLoading) {
      return (
        <StyledContainerSpinner>
          <Spinner light />
        </StyledContainerSpinner>
      )
    }

    return (
      <React.Fragment>
        <StyledTitle>
          {`${monthName} income`}
        </StyledTitle>
        <StyledTotal>
          <Currency value={totalIncome} />
        </StyledTotal>
        <StyledHeaderContainer>
          <StyledHeaderGroup>
            <StyledHeader>
              Invoiced income
            </StyledHeader>
            <StyledValue>
              <Currency value={totalInvoices} />
            </StyledValue>
          </StyledHeaderGroup>
          <StyledHeaderGroup>
            <StyledHeader>
              Funding income
            </StyledHeader>
            <StyledValue>
              <Currency value={totalFundingIncome} />
            </StyledValue>
          </StyledHeaderGroup>
        </StyledHeaderContainer>
      </React.Fragment>
    )
  }

  return (
    <StyledContainer>
      {renderContent()}
    </StyledContainer>
  )
}

export default IncomeDetail
