import _ from 'lodash'

import { TableColumn } from 'components/Table/Table'

import i18n from 'translations'

export const TABLE_COLUMNS_FIRST_PART: TableColumn[] = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'child',
    title: _.upperFirst(i18n.t('global:child')),
    width: '150px',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'codeReference',
    title: _.upperFirst(i18n.t('global:Child:childReferenceCode')),
    width: '200px',
  },
]

export const TABLE_COLUMNS_SECOND_PART: TableColumn[] = [
  {
    align: 'right',
    alignTh: 'right',
    field: 'balance',
    title: _.upperFirst(i18n.t('module:Finance:Reports:ActiveRecurringPayment:Table:Header:Balance:label')),
    width: '100px',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'status',
    title: _.upperFirst(i18n.t('global:status')),
    width: '120px',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'paymentMethod',
    title: i18n.t('module:Finance:Reports:ActiveRecurringPayment:Table:Header:PaymentMethod:label'),
    width: '150px',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'setupType',
    title: i18n.t('module:Finance:Reports:ActiveRecurringPayment:Table:Header:SetupType:label'),
    width: '240px',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'accountHolder',
    title: i18n.t('module:Finance:Reports:ActiveRecurringPayment:Table:Header:AccountHolder:label'),
    width: '150px',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'nextRunDate',
    title: i18n.t('module:Finance:Reports:ActiveRecurringPayment:Table:Header:NextRunDate:label'),
    width: '130px',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'estimatedCompletionDate',
    title: i18n.t('module:Finance:Reports:ActiveRecurringPayment:Table:Header:EstimatedCompletionDate:label'),
    tooltip: i18n.t('module:Finance:Reports:ActiveRecurringPayment:Table:Header:EstimatedCompletionDate:tooltip'),
    width: '250px',
  },
]

export const NURSERY_TABLE_COLUMNS: TableColumn[] = [
  ...TABLE_COLUMNS_FIRST_PART,
  ...TABLE_COLUMNS_SECOND_PART,
]

export const ORGANIZATION_TABLE_COLUMNS: TableColumn[] = [
  ...TABLE_COLUMNS_FIRST_PART,
  {
    align: 'left',
    alignTh: 'left',
    field: 'nursery',
    title: i18n.t('module:Finance:Reports:ActiveRecurringPayment:Table:Header:Site:label'),
    width: '150px',
  },
  ...TABLE_COLUMNS_SECOND_PART,
]
