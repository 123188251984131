import React from 'react'

import { DISPLAY_DATE_TIME_FORMAT } from 'constants/date'

import { constants as invoicesConstants } from 'services/legacy/invoices'

import { Avatar, DateString, Typography } from 'components'

import {
  StyledCellHeaderContainer,
  StyledCellValueContainer,
  StyledContainer,
  StyledInvoiceStatus,
  StyledInvoiceStatusValue,
  StyledItemContainer,
  StyledRowContainer,
  StyledTableContainer,
} from './InvoiceHeaderStyled'

const InvoiceHeader = ({ invoicePreview }) => {
  const { invoice } = invoicePreview
  const { child } = invoice
  const { ageMonths, firstName, photo, surname } = child
  const { dueDate, endDate, issueDate, number, sentAt, startDate, status } = invoice
  const statusLabel = invoicesConstants.STATUSES[status]

  const renderRow = (label, value) => (
    <StyledRowContainer>
      <StyledCellHeaderContainer>
        {label}
      </StyledCellHeaderContainer>
      <StyledCellValueContainer>
        {value}
      </StyledCellValueContainer>
    </StyledRowContainer>
  )

  return (
    <StyledContainer>
      <StyledItemContainer>
        <Avatar
          initials={[firstName, surname]}
          src={photo}
          subTitle={`${ageMonths} months`}
          title={(
            <Typography fontSize={14} primary>
              {`${firstName} ${surname}`}
            </Typography>
          )}
        />
        {status && (
          <StyledInvoiceStatus>
            Invoice status
            <StyledInvoiceStatusValue>
              {statusLabel}
            </StyledInvoiceStatusValue>
          </StyledInvoiceStatus>
        )}
      </StyledItemContainer>
      <StyledItemContainer>
        <StyledTableContainer>
          {renderRow('Date issued:', <DateString date={issueDate} />)}
          {renderRow('Due date:', <DateString date={dueDate} />)}
          {renderRow('Sent date:', sentAt ? <DateString date={sentAt} format={DISPLAY_DATE_TIME_FORMAT} /> : '-')}
          {renderRow(
            'Invoice period:',
            <div>
              <DateString date={startDate} />
              {' '}
              -
              {' '}
              <DateString date={endDate} />
            </div>,
          )}
          {renderRow('Invoice number:', number)}
        </StyledTableContainer>
      </StyledItemContainer>
    </StyledContainer>
  )
}

export default InvoiceHeader
