import React from 'react'

import { ModalBox } from 'components'

import { FUNDING_MODAL_COMPONENT } from './constants'

const FundingModalView = ({
  modalComponentType,
  onModalCancelClick,
  onModalCreateSuccess,
  title,
  ...props
}) => {
  const ModalComponent = FUNDING_MODAL_COMPONENT[modalComponentType]

  return (
    <ModalBox
      scrollToBody={false}
      title={title}
      iosPolyfill
      onCloseClick={onModalCancelClick}
    >
      <ModalComponent
        isInsideModal
        onModalCancelClick={onModalCancelClick}
        onModalCreateSuccess={onModalCreateSuccess}
        {...props}
      />
    </ModalBox>
  )
}

export default FundingModalView
