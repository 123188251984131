import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'

const getShell = (state: RootState) => state?.shell?.common

export const getSubMenu = createSelector([getShell], (state) => (state.hideSubMenu ? null : state.subMenu))

export const getRouteTitle = createSelector([getShell], (state) => (titleName) => state.routeTitles[titleName])

export const stickyFooterIsMounted = createSelector([getShell], (state) => state?.stickyFooterMounted)

export const getSubdomainCurrency = createSelector([getShell], (state) => state?.currency)

export const getSubdomainLocale = createSelector([getShell], (state) => state?.locale)

export const getStickyFooterHeight = createSelector([stickyFooterIsMounted], (isMounted) => isMounted && (
  document.querySelector<HTMLElement>('[data-name="StickyFooter"]')?.offsetHeight
))

export const getSubdomainCurrencySymbol = createSelector(
  [getSubdomainCurrency, getSubdomainLocale],
  (currency, locale) => {
    const finalLocale = locale?.replace('_', '-')

    return currency ? (0).toLocaleString(
      finalLocale,
      {
        currency,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        style: 'currency',
      },
    )?.replace(/\d/g, '').trim() : ''
  },
)
