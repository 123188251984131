import React from 'react'

import { generateRoute } from 'utils/routing'

import { Callout, EmptyState, Page, Pagination, Section, Select, Spinner, Table, Toolbar, Typography } from 'components'

const ManagementConsumablesV2View = ({
  active,
  activeOptions,
  columns,
  errorMessages,
  isLoading,
  onActiveFilterChange,
  onPageChange,
  page,
  pageCount,
  perPage,
  tableData,
  totalResults,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderToolbar = () => (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <Select
            options={activeOptions}
            placeholder="Active"
            searchable={false}
            value={active}
            onChange={onActiveFilterChange}
          />
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )

  const renderContent = () => {
    if (isLoading) {
      return (
        <Spinner />
      )
    }

    if (!tableData?.length) {
      const text = (
        <span>
          There are no consumables created.
          <br />
          Add consumables to show up when adding them to sessions.
        </span>
      )

      return (
        <EmptyState
          icon="consumables"
          text1={text}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={columns}
          data={tableData}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const actions = (
    <Section.Actions primary={[{ to: generateRoute('MANAGEMENT.FINANCE_SETTINGS.CONSUMABLES.ADD') }]} />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title="Consumables"
    >
      <Callout content={errorMessages} error />
      <Typography margin="20px 0">
        This is where you can set the consumables that can be added to sessions.
      </Typography>
      {renderToolbar()}
      {renderContent()}
    </Page.Section>
  )
}

export default ManagementConsumablesV2View
