import _ from 'lodash'

import { getCriteria } from '../list/selectors'

export const getDropdownFilterCriteria = (filters) => {
  const { includeUsedDepositIds, pendingOnly, skipPendingDepositIds, ...rest } = filters

  const criteria = getCriteria(rest)
  const dropdownCriteria = pendingOnly ? [
    {
      field: 'or[0][used][eq]',
      value: 1,
    },
    {
      field: 'or[1][invoiceItem.id][eq]',
      value: null,
    },
  ] : []

  if (filters?.depositId) {
    dropdownCriteria.push(...[
      {
        field: 'or[0][id][eq][]',
        value: filters.depositId,
      },
      {
        field: 'or[0][used][eq]',
        value: 1,
      },
      {
        field: 'or[1][invoiceItem.id][eq]',
        value: null,
      },
      {
        field: 'or[1][id][eq][]',
        value: filters.depositId,
      },
    ])
  }

  if (includeUsedDepositIds?.length) {
    _.forEach(includeUsedDepositIds, (id) => {
      dropdownCriteria.push(
        {
          field: 'or[0][id][eq][]',
          value: id,
        },
        {
          field: 'or[1][id][eq][]',
          value: id,
        },
      )
    })
  }

  if (skipPendingDepositIds?.length) {
    _.forEach(skipPendingDepositIds, (id, index) => {
      dropdownCriteria.push({
        field: `or[${2 + index}][id][neq][]`,
        value: id,
      })
    })
  }

  return [
    ...criteria,
    ...dropdownCriteria,
  ]
}

