import styled from 'styled-components'

export const StyledImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0 30px;

  img,
  .mediaPickerImage {
    display: block;
    max-width: 100%;
    max-height: 80px;

    @media (min-height: 600px) {
      width: auto !important;
      max-height: 160px;
      height: 160px !important;
    }

    @media (min-height: 750px) {
      width: auto !important;
      max-height: 200px;
      height: 200px !important;
    }

    @media (min-height: 900px) {
      width: auto !important;
      max-height: 300px;
      height: 300px !important;
    }
    
    @media (max-width: 640px) {
      width: auto !important;
      max-height: 200px;
      height: 200px !important;
    }
    
    @media (max-width: 440px) {
      width: auto !important;
      max-height: 160px;
      height: 160px !important;
    }
  }
`

export const StyledExtension = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;  
  height: 100%;
  margin-right: 20px;
`

export const StyledChildrenList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  
  @media(max-width: 840px) {
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media(max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media(max-width: 520px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const StyledImageWrapper = styled.div`
  position: relative;
`

export const StyledVideoIconWrapper = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  width: 50px;
  height: 32px;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`
