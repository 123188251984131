import _ from 'lodash'
import moment from 'moment'

import React, { useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'
import { change, formValueSelector, getFormInitialValues } from 'redux-form'

import { RootState } from 'core/reducers'
import { LIST_STATUS_FILTERS } from 'constants/filters'
import { ModalType } from 'modals'
import { FUNDING_FEES_TYPES } from 'services/nurseryFunding/constants'
import { ProductPriceGroupsType } from 'services/product/productPriceGroups/models'
import { FEATURE_FLAGS } from 'constants/security'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import { withAppService, withAppServiceProps } from 'services/app'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import {
  withNurseryConsumablesV3Service,
  withNurseryConsumablesV3ServiceProps,
} from 'services/product/nurseryConsumablesV3'
import { withNurserySessionsV3Service, withNurserySessionsV3ServiceProps } from 'services/product/nurserySessionsV3'
import { withNurseryFundingService, withNurseryFundingServiceProps } from 'services/nurseryFunding'
import { withSecurityService, withSecurityServiceProps } from 'services/security'
import { withProductPriceGroupsService, withProductPriceGroupsServiceProps } from 'services/product/productPriceGroups'
import { withRouter, withRouterProps } from 'services/router'
import { withNurseriesService, withNurseriesServiceProps } from 'services/nurseries'
import { withShellService, withShellServiceProps } from 'services/shell'

import { getInitialValues, getPayload } from 'services/product/nurserySessionsV3/single/selectors'

import i18n from 'translations'

import { ADD_NURSERY_SESSION_FORM } from './components/AddSessionForm'
import { NurseryFees, SessionAddFormValues } from './components/models'
import AddView from './AddView'

const NURSERY_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings',
    'nurserySettings.localeDetails',
  ],
}

const SESSIONS_GROUPS = {
  read: [
    'nurserySessionProduct.items',
    'nurserySessionConsumableProduct',
    'nurserySessionConsumableProduct.link',
    'nurserySessionConsumableProduct.settings',
    'nurserySessionConsumableProductSettings',
    'nurseryConsumableProduct',
    'nurserySessionProduct.priceChanges',
    'nurserySessionConsumableProduct.priceChanges',
    'productPriceChange',
    'productPriceChange.prices',
    'productPrice',
    'productPrice.priceGroup',
    'productPriceGroup',
  ],
}

interface AddContainerProps {
  formInitialValues: SessionAddFormValues
}

type AddContainerFullProps = withNurserySessionsV3ServiceProps
  & withAppServiceProps
  & withModalServiceProps
  & withNurseryConsumablesV3ServiceProps
  & withNurseryFundingServiceProps
  & withProductPriceGroupsServiceProps
  & withNurseriesServiceProps
  & withSecurityServiceProps
  & withShellServiceProps
  & withRouterProps
  & AddContainerProps

const mapState = (state: RootState, {
  appSelectors,
  nurseryConsumablesV3ListState,
  nurseryConsumablesV3Selectors,
  nurseryFundingSelectors,
  nurserySessionsV3Selectors,
  nurserySessionsV3SingleState,
  productPriceGroupsListState,
  productPriceGroupsSelectors,
  securitySelectors,
}: AddContainerFullProps) => ({
  FinanceAutomationGranted: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.FINANCE_AUTOMATION],
  }),
  consumables: nurseryConsumablesV3Selectors.getConsumablesListDropdown(state),
  errorMessages: appSelectors.getErrorMessages(nurserySessionsV3SingleState),
  formInitialValues: getFormInitialValues(ADD_NURSERY_SESSION_FORM)(state),
  hasNurseryLevelManualFunding: nurseryFundingSelectors.hasNurseryLevelManualFunding(state),
  isFetching: appSelectors.getIsFetching(
    nurseryConsumablesV3ListState,
    nurserySessionsV3SingleState,
    productPriceGroupsListState,
  ),
  isInheritedFromOrganization: nurserySessionsV3Selectors.isInheritedFromOrganizationSelector(state),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
  nurseryConsumablesExtraItems: formValueSelector(ADD_NURSERY_SESSION_FORM)(state, 'nurseryConsumablesExtraItems'),
  nurseryFees: formValueSelector(ADD_NURSERY_SESSION_FORM)(state, 'nurseryFees'),
  productPriceGroups: productPriceGroupsSelectors.getProductPriceGroupsListData(state),
})

const mapDispatch = {
  changeField: (field, value) => change(ADD_NURSERY_SESSION_FORM, field, value),
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

const AddContainer: React.FC<AddContainerFullProps & PropsFromRedux> = ({
  FinanceAutomationGranted,
  changeField,
  consumables,
  errorMessages,
  formInitialValues,
  hasNurseryLevelManualFunding,
  isFetching,
  isInheritedFromOrganization,
  isOrganizationContext,
  modalActions,
  navigate,
  nurseriesActions,
  nurseryConsumablesExtraItems,
  nurseryConsumablesV3Actions,
  nurseryConsumablesV3Selectors,
  nurseryFees,
  nurseryFundingActions,
  nurserySessionsV3Actions,
  nurserySessionsV3SingleState,
  params,
  productPriceGroups,
  productPriceGroupsActions,
  productPriceGroupsSelectors,
  shellActions,
}) => {
  const fromOrganizationContext = !!params.nurseryId
  const isEdit = !!params.sessionId
  const nurseryFeesLength = nurseryFees?.length || 1
  const [nursery, setNursery] = useState(null)
  const [initialized, setInitialized] = useState(false)

  const handleGetNurserySuccess = ({ data }) => {
    const { nurserySettings: { currency, locale } } = data || {}

    setNursery(data)
    setInitialized(true)
    shellActions.setRouteTitle({
      name: 'SETTINGS.SESSIONS.NURSERY',
      title: i18n.t('module:Management:Finance:SessionsV3:sessionsFor', { name: data.name }),
    })
    shellActions.setSettings({ currency, locale })
  }

  useEffect(() => {
    if (fromOrganizationContext && !initialized) {
      nurseriesActions.get(params.nurseryId, {
        onSuccess: handleGetNurserySuccess,
        onlyData: true,
        params: {
          groups: NURSERY_GROUPS,
        },
      })
    } else if (!initialized) {
      setInitialized(true)
    }
  }, [isOrganizationContext])

  useEffect(() => {
    if (initialized) {
      if (isEdit) {
        const criteria = []

        criteria.push({
          field: 'type',
          value: FUNDING_FEES_TYPES.MANUAL_HOURS,
        })

        nurseryFundingActions.list({
          params: [{
            criteria,
            limit: 0,
          }, (fromOrganizationContext && nursery) ? nursery.subdomain : null],
        })

        nurserySessionsV3Actions.get({
          params: [params.sessionId, {
            groups: SESSIONS_GROUPS,
          }, (fromOrganizationContext && nursery) ? nursery.subdomain : null],
        })
      }

      if (!isOrganizationContext) {
        nurseryConsumablesV3Actions.list({
          params: [{
            criteria: nurseryConsumablesV3Selectors.getCriteria({ active: LIST_STATUS_FILTERS.ACTIVE }),
          }, (fromOrganizationContext && nursery) ? nursery.subdomain : null],
        })
      }

      productPriceGroupsActions.list({
        params: [{
          criteria: productPriceGroupsSelectors.getCriteria({ type: ProductPriceGroupsType.NURSERY_AGE }),
        }, (fromOrganizationContext && nursery) ? nursery.subdomain : null],
      })
    }

    return () => {
      nurseryConsumablesV3Actions.clearList()
      nurserySessionsV3Actions.clear()
      nurseryFundingActions.clearList()
    }
  }, [initialized])

  useEffect(() => {
    if (1 < nurseryFeesLength) {
      changeField(`nurseryFees[${nurseryFees.length - 1}].endDate`, null)

      _.each<NurseryFees>(_.reverse(nurseryFees), (fees, index) => {
        if (fees?.startDate) {
          changeField(
            `nurseryFees[${(nurseryFees.length - 2) - index}].endDate`,
            moment(fees.startDate).subtract(1, 'days'),
          )
        }
      })
    } else {
      changeField('nurseryFees[0].endDate', null)
    }
  }, [nurseryFeesLength])

  useEffect(() => {
    _.each(nurseryConsumablesExtraItems, (item) => {
      _.each(nurseryFees, (nurseryFee, index) => {
        if (item?.consumable && _.isUndefined(nurseryFee[`consumable-${item.consumable.value}`]?.value)) {
          changeField(`nurseryFees[${index}].consumable-${item.consumable.value}.value`, 0)
        }
      })
    })
  }, [nurseryConsumablesExtraItems])

  const handleSubmitSuccess = () => {
    if (fromOrganizationContext) {
      return navigate(generateRoute('SETTINGS.SESSIONS.NURSERY', { nurseryId: nursery.id }))
    }

    return navigate(generateRoute('MANAGEMENT.FINANCE_SETTINGS.SESSIONS'))
  }

  const handleSubmit = (fields) => {
    const payload = getPayload(fields, productPriceGroups)

    if (isEdit) {
      const { endTime, sessionType, startTime } = fields
      const {
        endTime: initialEndTime,
        sessionType: initialSessionType,
        startTime: initialStartTime,
      } = formInitialValues

      if (hasNurseryLevelManualFunding && (
        startTime !== initialStartTime
        || endTime !== initialEndTime
        || sessionType !== initialSessionType
      )) {
        return modalActions.show<ModalType.CONFIRM>(ModalType.CONFIRM, {
          confirmButtonLabel: i18n.t('global:Update'),
          icon: 'warning',
          onConfirm: () => nurserySessionsV3Actions.update({
            body: payload,
            onSuccess: handleSubmitSuccess,
            params: [params.sessionId, (fromOrganizationContext && nursery) ? nursery?.subdomain : null],
          }),
          text: i18n.t('module:Management:Finance:Sessions:Add:fundingPopupCopy'),
        })
      }

      return nurserySessionsV3Actions.update({
        body: payload,
        onSuccess: handleSubmitSuccess,
        params: [params.sessionId, (fromOrganizationContext && nursery) ? nursery?.subdomain : null],
      })
    }

    return nurserySessionsV3Actions.create({
      body: payload,
      onSuccess: handleSubmitSuccess,
      params: [(fromOrganizationContext && nursery) ? nursery?.subdomain : null],
    })
  }

  const archiveSession = (archived) => {
    nurserySessionsV3Actions.update({
      body: { archived },
      onSuccess: handleSubmitSuccess,
      params: [params.sessionId, (fromOrganizationContext && nursery) ? nursery?.subdomain : null],
    })
  }

  const handleArchiveClick = (archived) => () => {
    const label = archived ? i18n.t('global:archive') : i18n.t('global:unarchive')
    const text = archived
      ? i18n.t('module:Management:Finance:Sessions:Add:archivePopupCopy')
      : i18n.t('module:Management:Finance:Sessions:Add:unarchivePopupCopy')

    modalActions.show<ModalType.CONFIRM>(ModalType.CONFIRM, {
      confirmButtonLabel: label,
      icon: label,
      onConfirm: () => archiveSession(archived),
      text,
    })
  }

  const handleAddFee = () => {
    _.each(productPriceGroups, ({ id }) => {
      changeField(`nurseryFees[${nurseryFees.length}].priceGroups-${id}.value`, 0)
    })
  }

  const session = nurserySessionsV3SingleState.data
  const initialValues = getInitialValues(session, isEdit, productPriceGroups)
  const isArchived = nurserySessionsV3SingleState.data && nurserySessionsV3SingleState.data.archived
  const isFormLoading = nurserySessionsV3SingleState.isSubmitting
  const cancelRoute = isOrganizationContext
    ? generateRoute('SETTINGS.SESSIONS.NURSERY', { nurseryId: params.nurseryId })
    : generateRoute('MANAGEMENT.FINANCE_SETTINGS.SESSIONS')

  return (
    <AddView
      FinanceAutomationGranted={FinanceAutomationGranted}
      cancelLink={cancelRoute}
      consumables={consumables}
      errorMessages={errorMessages}
      fromOrganizationContext={fromOrganizationContext}
      initialValues={initialValues}
      isArchived={isArchived}
      isEdit={isEdit}
      isFormLoading={isFormLoading}
      isInheritedFromOrganization={isInheritedFromOrganization}
      isLoading={isFetching}
      productPriceGroups={productPriceGroups}
      onAddFee={handleAddFee}
      onArchiveClick={handleArchiveClick}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withRouter,
  withAppService,
  withShellService,
  withNurserySessionsV3Service,
  withProductPriceGroupsService,
  withModalService,
  withNurseryConsumablesV3Service,
  withNurseryFundingService,
  withNurseriesService,
  withSecurityService,
  connector,
)

export default enhance(AddContainer)
