import { BasicListInitialState } from 'utils/reducer'

import {
  CLEAR_INVOICES_FORECAST,
  INVOICES_FORECAST,
  INVOICES_FORECAST_FAILED,
  INVOICES_FORECAST_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: {
    // eslint-disable-next-line camelcase
    total_results: null,
  },
}

export interface InvoicesForecastState extends BasicListInitialState {}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case INVOICES_FORECAST:
      return { ...state, error: initialState.error, isFetching: true }
    case INVOICES_FORECAST_SUCCESS:
      return { ...state, data: payload.data, isFetching: false, meta: payload.meta }
    case INVOICES_FORECAST_FAILED:
      return { ...state, error, isFetching: false }
    case CLEAR_INVOICES_FORECAST:
      return initialState
    default:
      return state
  }
}
