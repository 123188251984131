import moment from 'moment'
import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { DEFAULT_DATE_FORMAT, DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT } from 'constants/date'
import { ABSENCE_TYPE } from 'module/Register/constants'
import { CHILD_LEAVE_TYPE_OPTIONS } from 'services/legacy/childAbsences/constants'

import { isSameDay } from 'utils/date'

import { withAppService } from 'services/app'
import { withChildAbsencesService } from 'services/legacy/childAbsences'
import { withModalService } from 'services/utils/modal'
import { withPaginationUtils } from 'services/utils/pagination'
import { withSnackbarService } from 'services/utils/snackbar'
import { withRouter } from 'services/router'

import i18n from 'translations'

import ChildAbsencesView from './ChildAbsencesView'
import { getTableData } from './ChildAbsencesHelpers'

const GROUPS = {
  read: [
    'absenceReason',
    'child',
    'childAbsence.absenceReason',
    'childAbsence.child',
  ],
}

class ChildAbsencesContainer extends Component {
  constructor(props) {
    super(props)

    const { location } = props
    const { query } = location
    const { from, to, type } = query

    const dateRangeFrom = from
      ? moment(from, DEFAULT_DATE_FORMAT).toDate()
      : moment().startOf('year').format(DEFAULT_DATE_FORMAT)
    const dateRangeTo = to
      ? moment(to, DEFAULT_DATE_FORMAT).toDate()
      : moment().endOf('year').format(DEFAULT_DATE_FORMAT)

    this.state = {
      filters: {
        dateRange: [dateRangeFrom, dateRangeTo],
        type: type ? _.find(CHILD_LEAVE_TYPE_OPTIONS, ['value', type]) : null,
      },
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { childAbsencesActions } = this.props

    childAbsencesActions.clearList()
  }

  componentDidUpdate(prevProps) {
    const { params } = this.props
    const { params: prevParams } = prevProps
    const { childId } = params
    const { childId: prevChildId } = prevParams

    if (prevChildId && childId && prevChildId !== childId) {
      this.fetch()
    }
  }

  fetch = () => {
    const { childAbsencesActions, childAbsencesSelectors, paginationUtils, params } = this.props
    const { filters } = this.state
    const { page } = paginationUtils
    const { childId } = params

    const criteria = childAbsencesSelectors.getListCriteria({ ...filters, childId })

    childAbsencesActions.list({
      params: [{
        criteria,
        groups: GROUPS,
        order: {
          sortField: 'startDate',
          sortOrder: 'desc',
        },
        page,
      }],
    })
  }

  setFiltersSuccess = () => {
    const { setLocationQuery } = this.props
    const { filters } = this.state
    const { dateRange, type } = filters

    this.handlePageChange(1)

    setLocationQuery({
      from: dateRange[0] ? moment(dateRange[0]).format(DEFAULT_DATE_FORMAT) : null,
      to: dateRange[1] ? moment(dateRange[1]).format(DEFAULT_DATE_FORMAT) : null,
      type: type?.value || null,
    })
  }

  setFilters = (filters) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          ...filters,
        },
      }),
      this.setFiltersSuccess,
    )
  }

  handleDateRangeChange = (dateRange) => {
    this.setFilters({ dateRange })
  }

  handleTypeChange = (type) => {
    this.setFilters({ type })
  }

  handleAddLeaveClick = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CHILD_LEAVE, {
      isChildContext: true,
      onSuccess: () => this.handlePageChange(1),
    }, {
      enableMultipleModal: true,
    })
  }

  handleEditClick = (id) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CHILD_LEAVE, {
      id,
      isEdit: true,
      onSuccess: () => this.handlePageChange(1),
    }, {
      enableMultipleModal: true,
    })
  }

  handleDeleteLeaveSuccess = (item) => {
    const { snackbarActions } = this.props
    const { absenceType, child } = item

    this.handlePageChange(1)

    snackbarActions.show({
      message: i18n.t('module:Children:Child:About:Absences:DeleteLeave:snackbar', {
        firstName: child.firstName,
        type: ABSENCE_TYPE.ABSENCE === absenceType ? i18n.t('global:Absence') : i18n.t('global:Holiday'),
      }),
    })
  }

  handleDeleteLeave = ({ id, item }) => {
    const { childAbsencesActions } = this.props

    return childAbsencesActions.remove({
      onSuccess: () => this.handleDeleteLeaveSuccess(item),
      params: [id],
    })
  }

  handleDeleteClick = (id) => {
    const { data, modalActions, modalConsts } = this.props

    const item = _.find(data, ['id', id])
    const { absenceType, child, endDate, startDate } = item
    const suffix = isSameDay(startDate, endDate) ? 'oneDay' : 'dateRange'

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('global:Delete'),
      onConfirm: () => this.handleDeleteLeave({ id, item }),
      text: i18n.t(`module:Children:Child:About:Absences:DeleteLeave:Confirm:${suffix}`, {
        endDate: moment(endDate).format(DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT),
        firstName: child.firstName,
        startDate: moment(startDate).format(DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT),
        type: ABSENCE_TYPE.ABSENCE === absenceType ? i18n.t('global:Absence') : i18n.t('global:Holiday'),
      }),
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  render() {
    const {
      data,
      errorMessages,
      isFetching,
      paginationUtils,
      totalResults,
    } = this.props
    const { filters } = this.state
    const { getPageCount, page, perPage } = paginationUtils
    const { dateRange, type } = filters

    const pageCount = getPageCount(totalResults)
    const tableData = getTableData({
      data,
      onDeleteClick: this.handleDeleteClick,
      onEditClick: this.handleEditClick,
    })

    return (
      <ChildAbsencesView
        data={tableData}
        dateRange={dateRange}
        errorMessages={errorMessages}
        isFetching={isFetching}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        totalResults={totalResults}
        type={type}
        onAddLeaveClick={this.handleAddLeaveClick}
        onDateRangeChange={this.handleDateRangeChange}
        onPageChange={this.handlePageChange}
        onTypeChange={this.handleTypeChange}
      />
    )
  }
}

const mapState = (state, { appSelectors, childAbsencesListState, childAbsencesSelectors }) => ({
  data: childAbsencesSelectors.getChildAbsencesListDataSelector(state),
  errorMessages: appSelectors.getErrorMessages(childAbsencesListState),
  isFetching: appSelectors.getIsFetching(childAbsencesListState),
  totalResults: appSelectors.getTotalResults(childAbsencesListState),
})

const enhance = compose(
  withAppService,
  withChildAbsencesService,
  withModalService,
  withPaginationUtils,
  withSnackbarService,
  withRouter,
  connect(mapState),
)

export default enhance(ChildAbsencesContainer)
