import _ from 'lodash'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { MESSAGE_USER_TYPE } from 'services/legacy/messaging/constants'

import { Avatar, EmptyState, ModalBox, SearchBar, Space, Typography } from 'components'

import i18n from 'translations'

import { StyledAvatarContainer, StyledSectionWrapper } from './ConversationMembersModalStyled'

const ConversationMembersModalView = ({
  channel,
  channelMembers,
  onChangeSearch,
  onCloseClick,
  search,
}) => {
  const filteredMembers = _.filter(channelMembers, ({ member: { name } }) => (
    -1 < name?.toLowerCase()?.indexOf(search?.toLowerCase())
  ))

  const childContacts = _.filter(
    filteredMembers,
    ({ member: { type } }) => MESSAGE_USER_TYPE.PARENT === type,
  )
  const staffMembers = _.filter(
    filteredMembers,
    ({ member: { type } }) => MESSAGE_USER_TYPE.USER === type,
  )
  const originalChildContacts = _.filter(
    channelMembers,
    ({ member: { type } }) => MESSAGE_USER_TYPE.PARENT === type,
  )
  const originalStaffMembers = _.filter(
    channelMembers,
    ({ member: { type } }) => MESSAGE_USER_TYPE.USER === type,
  )

  const renderEmptyState = (
    <EmptyState
      icon="contact"
      text1={i18n.t('global:EmptyState:short')}
    />
  )

  const renderContact = ({ member }) => (
    <StyledAvatarContainer key={member?.id}>
      <Avatar
        avatarSize="medium_big"
        initials={member?.name.split(' ')}
        src={member.photo}
        title={(
          <Typography color={NEUTRAL_COLOURS.BASIC} variant="span">
            {member.name}
          </Typography>
        )}
      />
    </StyledAvatarContainer>
  )

  return (
    <ModalBox
      maxWidth={475}
      title={i18n.t('module:Modals:Messaging:ConversationMembers:title')}
      iosPolyfill
      onCloseClick={onCloseClick}
    >
      <Avatar
        avatarSize="medium_big"
        gap={15}
        initials={channel?.name.split(' ')}
        src={channel?.photo}
        title={(
          <Typography color={NEUTRAL_COLOURS.BASIC} fontSize={18} variant="span">
            {channel?.name}
          </Typography>
        )}
      />
      <Space space="5px" />
      <SearchBar
        placeholder={i18n.t('module:Modals:Messaging:ConversationMembers:searchPlaceholder')}
        variant="standard"
        noBackground
        onChange={onChangeSearch}
      />
      {((originalChildContacts?.length && !search) || !(!childContacts?.length && search)) ? (
        <React.Fragment>
          <Typography margin="25px 0 5px" bold>
            {i18n.t('module:Modals:Messaging:ConversationMembers:childContacts')}
            {` (${childContacts?.length || 0})`}
          </Typography>
          <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} margin="0 0 5px">
            {i18n.t('module:Modals:Messaging:ConversationMembers:childContactsDetails')}
          </Typography>
          <StyledSectionWrapper>
            {!childContacts?.length ? renderEmptyState : null}
            {_.map(childContacts, renderContact)}
          </StyledSectionWrapper>
        </React.Fragment>
      ) : null}
      {((originalStaffMembers?.length && !search) || !(!staffMembers?.length && search)) ? (
        <React.Fragment>
          <Typography margin="25px 0 5px" bold>
            {i18n.t('module:Modals:Messaging:ConversationMembers:staffMembers')}
            {` (${staffMembers?.length || 0})`}
          </Typography>
          <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} margin="0 0 5px">
            {i18n.t('module:Modals:Messaging:ConversationMembers:staffMembersDetails')}
          </Typography>
          <StyledSectionWrapper>
            {!staffMembers?.length ? renderEmptyState : null}
            {_.map(staffMembers, renderContact)}
          </StyledSectionWrapper>
        </React.Fragment>
      ) : null}
      {!childContacts?.length && !staffMembers?.length && search && renderEmptyState}
    </ModalBox>
  )
}

export default ConversationMembersModalView
