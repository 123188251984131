import styled from 'styled-components'

interface StyledContainerProps {
  $size?: number
}

export const StyledContainer = styled.div<StyledContainerProps>`
  position: relative;

  ${({ $size }) => $size && `
    width: ${$size}px;
    height: ${$size}px;
  `}
`

interface StyledBlockedIconProps {
  $size?: number
}

export const StyledBlockedIcon = styled.div<StyledBlockedIconProps>`
  position: relative;
  z-index: 10;

  ${({ $size }) => $size && `
    width: ${$size}px;
    height: ${$size}px;
  `}
`

export const StyledIcon = styled.div`
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`
