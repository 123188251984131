import _ from 'lodash'

import i18n from 'translations'

export const TABLE_COLUMNS = [
  {
    align: 'left',
    field: 'child',
    sortKey: 'firstName',
    sortable: true,
    title: _.upperFirst(i18n.t('global:child')),
  },
  {
    field: 'codeReference',
    title: i18n.t('global:Child:childRefCode'),
  },
  {
    field: '0-30',
    title: i18n.t('module:Finance:Reports:AgedDebt:Table:Header:0-30'),
  },
  {
    field: '31-60',
    title: i18n.t('module:Finance:Reports:AgedDebt:Table:Header:31-60'),
  },
  {
    field: '61-90',
    title: i18n.t('module:Finance:Reports:AgedDebt:Table:Header:61-90'),
  },
  {
    field: '91-120',
    title: i18n.t('module:Finance:Reports:AgedDebt:Table:Header:91-120'),
  },
  {
    field: '120+',
    title: i18n.t('module:Finance:Reports:AgedDebt:Table:Header:120+'),
  },
  {
    field: 'total',
    title: i18n.t('global:Finance:Total'),
  },
]

export const GROUP_TABLE_COLUMNS = [
  {
    align: 'left',
    field: 'name',
    sortable: true,
    title: _.upperFirst(i18n.t('global:site')),
  },
  {
    field: '0-30',
    title: i18n.t('module:Finance:Reports:AgedDebt:Table:Header:0-30'),
  },
  {
    field: '31-60',
    title: i18n.t('module:Finance:Reports:AgedDebt:Table:Header:31-60'),
  },
  {
    field: '61-90',
    title: i18n.t('module:Finance:Reports:AgedDebt:Table:Header:61-90'),
  },
  {
    field: '91-120',
    title: i18n.t('module:Finance:Reports:AgedDebt:Table:Header:91-120'),
  },
  {
    field: '120+',
    title: i18n.t('module:Finance:Reports:AgedDebt:Table:Header:120+'),
  },
  {
    field: 'total',
    title: i18n.t('global:Finance:Total'),
  },
]
