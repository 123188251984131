import { BasicListInitialState, getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import {
  CLEAR_NURSERY_DISCOUNTS_V3,
  LIST_NURSERY_DISCOUNTS_V3,
  LIST_NURSERY_DISCOUNTS_V3_FAILED,
  LIST_NURSERY_DISCOUNTS_V3_SUCCESS,
} from './constants'

const initialState = getBasicListInitialState

export interface NurseryDiscountsListState extends BasicListInitialState {}

export default (...props) => {
  const [state = initialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_NURSERY_DISCOUNTS_V3,
    failed: LIST_NURSERY_DISCOUNTS_V3_FAILED,
    init: LIST_NURSERY_DISCOUNTS_V3,
    success: LIST_NURSERY_DISCOUNTS_V3_SUCCESS,
  }, initialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
