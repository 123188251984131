export const REQUEST_EXTRA_SESSION_GROUPS = {
  read: [
    'requestExtraSession.nurserySession',
    'nurserySession',
    'requestExtraSession.requestedBy',
    'carer',
    'requestExtraSession.child',
    'child',
  ],
}

export const REQUEST_EXTRA_SESSION_GROUPS_V3 = {
  read: [
    'requestExtraSession.nurserySessionProduct',
    'nurserySessionProduct',
    'requestExtraSession.requestedBy',
    'carer',
    'requestExtraSession.child',
    'child',
  ],
}
