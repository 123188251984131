import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px 0;
`

export const StyledProgressPercentageText = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 18px;
  ${({ color }) => color && `color: ${color};`}
`
