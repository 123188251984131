import React from 'react'

import { Button } from 'components'

import i18n from 'translations'

import { StyledButtonWrapper, StyledContainer } from './FilterDescriptionStyled'

const FilterDescription = ({ displayFilters, hideLink, onClick, to }) => {
  const getLink = () => {
    if (hideLink || !displayFilters) {
      return null
    }

    return (
      <StyledButtonWrapper>
        <Button
          hierarchy="tertiary"
          label={i18n.t('global:ShowAll')}
          size="small"
          to={to}
          negativeMargins
          onClick={onClick}
        />
      </StyledButtonWrapper>
    )
  }

  return (
    <StyledContainer>
      {i18n.t('module:Newsletters:Common:toLabel')}
      <span>{displayFilters}</span>
      {getLink()}
    </StyledContainer>
  )
}

export default FilterDescription
