import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledRowContainer = styled.div`
  display: grid;
  grid-template-columns: 35% 65%;  
  
  @media(max-width: 680px) {
    display: flex;
    flex-direction: column;
  }
`

export const StyledRowInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background-color: ${NEUTRAL_COLOURS.SILVER};
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  
  @media(max-width: 680px) {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 0;
  }
`

export const StyledRowContentContainer = styled.div`
  padding: 10px;
  background-color: #F7F7F7;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  
  @media(max-width: 680px) {
    border-top-right-radius: 0;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }
`

export const StyledGroupContainer = styled.div`
  & ${StyledRowContainer} ~ ${StyledRowContainer} {
    margin-top: 20px;
  }
  margin-top: 30px;
`

export const StyledContainer = styled.div`
  & ${StyledGroupContainer} ~ ${StyledGroupContainer} {
    margin-top: 30px;
  }
  display: table;
  width: 100%;
`

export const StyledRowDivider = styled.div`
  padding: 20px 0;
  border-bottom: 1px dashed ${NEUTRAL_COLOURS.SILVER};
`
