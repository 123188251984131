import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import colors, { NEUTRAL_COLOURS } from 'constants/colors'

import {
  STAFF_REGISTER_FILTER_OPTIONS,
  STAFF_STATISTICS_KEY_MAPPING,
} from 'services/legacy/membershipRegisters/constants'

import {
  Avatar,
  EmptyState,
  InfiniteScroll,
  Page,
  Pagination,
  SearchBar,
  Space,
  Spinner,
  TabFilter,
  Typography,
} from 'components'

import i18n from 'translations'

import { StyledChildContainer, StyledItem, StyledTime } from './StaffRegisterListStyled'

const StaffRegisterListView = ({
  currentDate,
  deviceIdentity,
  isFetching,
  isStatisticsFetching,
  onPageChange,
  onSearchBarChange,
  onStaffClick,
  onSummaryFilterChange,
  page,
  pageCount,
  records,
  registerStatus,
  staffStatistics,
  totalResults,
}) => {
  const renderHeader = () => {
    if (!deviceIdentity) {
      return null
    }

    return (
      <React.Fragment>
        <SearchBar
          placeholder={i18n.t('module:StaffRegister:StaffRegisterList:searchForYourself')}
          variant="standard"
          noBackground
          onChange={onSearchBarChange}
        />
        <Space margin="15px 0 0" />
        <TabFilter>
          {_.map(STAFF_REGISTER_FILTER_OPTIONS, ({ label, value }) => {
            const apiKey = STAFF_STATISTICS_KEY_MAPPING[value]
            const totalValue = staffStatistics ? staffStatistics[apiKey] : 0

            return (
              <TabFilter.Item
                isClicked={registerStatus.includes(value)}
                isLoading={isStatisticsFetching}
                key={value}
                title={label}
                value={totalValue}
                onClick={() => onSummaryFilterChange(value)}
              />
            )
          })}
        </TabFilter>
      </React.Fragment>
    )
  }

  const renderDateTime = () => (
    <StyledTime>
      <Typography color={colors.gray} fontSize={22}>
        {moment(currentDate).format('ddd DD/MM/YYYY')}
      </Typography>
      <Typography fontSize={22} bold>
        {moment(currentDate).format('HH:mm')}
      </Typography>
    </StyledTime>
  )

  const renderItem = (item) => {
    const { firstName, id, lastEntryType, lastRoom, name, photo, surname } = item
    const { name: roomName } = lastRoom || {}
    const { label: entryTypeLabel } = lastEntryType || {}

    const subtitle = (
      <React.Fragment>
        <Typography fontSize={14} margin="2px 0 0" variant="div">
          {entryTypeLabel}
        </Typography>
        {roomName && (
          <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} margin="3px 0 0" variant="div">
            {roomName}
          </Typography>
        )}
      </React.Fragment>
    )

    return (
      <StyledItem
        key={id}
        onClick={() => onStaffClick(item)}
      >
        <Avatar
          direction="vertical"
          initials={[firstName, surname]}
          src={photo}
          subTitle={subtitle}
          title={(
            <Typography variant="span" bold ellipsis>
              {name}
            </Typography>
          )}
        />
      </StyledItem>
    )
  }

  const renderContent = () => {
    if (isFetching && 1 === page) {
      return (
        <Spinner />
      )
    }

    if (!deviceIdentity) {
      return (
        <EmptyState
          icon="authorised-devices"
          iconSize={100}
          padding="150px 0"
          text1={(
            <Typography margin="0 0 -15px 0" bold>
              {i18n.t('module:StaffRegister:StaffRegisterList:deviceTokenNotFound1')}
            </Typography>
          )}
          text2={i18n.t('module:StaffRegister:StaffRegisterList:deviceTokenNotFound2')}
        />
      )
    }

    if (!isFetching && (!records?.length)) {
      return (
        <EmptyState
          icon="staff"
          padding="150px 0"
          text1={i18n.t('module:StaffRegister:StaffRegisterList:notFoundRecords')}
        />
      )
    }

    return (
      <React.Fragment>
        <Pagination
          titlePlural={i18n.t('global:pagination:staffMembers')}
          titleSingular={i18n.t('global:pagination:staffMember')}
          totalResults={totalResults}
        />
        <br />
        <InfiniteScroll
          dataLength={records?.length || 0}
          hasMore={page < pageCount}
          isFetching={isFetching}
          next={() => onPageChange((+page) + 1)}
        >
          <StyledChildContainer>
            {_.map(records, renderItem)}
          </StyledChildContainer>
        </InfiniteScroll>
      </React.Fragment>
    )
  }

  return (
    <Page.Section
      actions={renderDateTime()}
      isLoading={isFetching && 1 === page}
      title={i18n.t('module:StaffRegister:StaffRegisterList:title')}
    >
      {renderHeader()}
      {renderContent()}
    </Page.Section>
  )
}

export default StaffRegisterListView
