import { createSelector } from 'reselect'

import { getAuthProfile } from '../user/selectors'

export const getAuthOrganization = createSelector([getAuthProfile], (authProfile) => {
  if (!authProfile || !authProfile.organization) {
    return null
  }

  return authProfile.organization
})
