import i18n from 'translations'

export const CONTACTS_TABLE_COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'name',
    title: i18n.t('module:Children:Child:Contacts:List:Name'),
    width: '30%',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'relation',
    title: i18n.t('module:Children:Child:Contacts:List:Relation'),
    width: '20%',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'parentalResponsibility',
    title: i18n.t('module:Children:Child:Contacts:List:ParentalResponsibility'),
    width: '20%',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'parentAppAccess',
    title: i18n.t('module:Children:Child:Contacts:List:ParentApp'),
    width: '20%',
  },
]

export const API_STATUS = {
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
}

export const STAGE_INDEXES = {
  CONTACT_INFO: 3,
  ENROLMENT_INFO: 2,
  GENERAL_INFO: 1,
}

export const STAGE_INDEXES_V3 = {
  CONTACT_INFO: 2,
  GENERAL_INFO: 1,
}
