import {
  CLEAR_REGISTER_STATISTICS,
  GET_REGISTER_STATISTICS,
  GET_REGISTER_STATISTICS_FAILED,
  GET_REGISTER_STATISTICS_SUCCESS,
} from './constants'

export const getRegisterStatistics = (date) => ({ registerStatisticsApiClient }) => (dispatch) => {
  dispatch({
    type: GET_REGISTER_STATISTICS,
  })

  registerStatisticsApiClient.get(date).then(
    (data) => {
      dispatch({
        payload: data,
        type: GET_REGISTER_STATISTICS_SUCCESS,
      })
    },
    (error) => {
      dispatch({
        error: error.response,
        type: GET_REGISTER_STATISTICS_FAILED,
      })
    },
  )
}

export const clearRegisterStatistics = () => ({
  type: CLEAR_REGISTER_STATISTICS,
})
