import styled from 'styled-components'

export const StyledChart = styled.div`
  margin: 0 20px;
  flex: 1;
  overflow: auto;
`

export const StyledChartContainer = styled.div`
  margin-bottom: 35px;
  display: flex;
  align-items: center;  
`
