import React from 'react'

import { StyledWidgetContainer } from './WidgetContainerStyled'

const WidgetContainer = ({ children }) => (
  <StyledWidgetContainer>
    {children}
  </StyledWidgetContainer>
)

export default WidgetContainer
