export const CREATE_DEPOSIT = 'CREATE_DEPOSIT'
export const CREATE_DEPOSIT_SUCCESS = 'CREATE_DEPOSIT_SUCCESS'
export const CREATE_DEPOSIT_FAILED = 'CREATE_DEPOSIT_FAILED'

export const GET_DEPOSIT = 'GET_DEPOSIT'
export const GET_DEPOSIT_SUCCESS = 'GET_DEPOSIT_SUCCESS'
export const GET_DEPOSIT_FAILED = 'GET_DEPOSIT_FAILED'

export const UPDATE_DEPOSIT = 'UPDATE_DEPOSIT'
export const UPDATE_DEPOSIT_SUCCESS = 'UPDATE_DEPOSIT_SUCCESS'
export const UPDATE_DEPOSIT_FAILED = 'UPDATE_DEPOSIT_FAILED'

export const REMOVE_DEPOSIT = 'REMOVE_DEPOSIT'
export const REMOVE_DEPOSIT_SUCCESS = 'REMOVE_DEPOSIT_SUCCESS'
export const REMOVE_DEPOSIT_FAILED = 'REMOVE_DEPOSIT_FAILED'

export const CLEAR_DEPOSIT = 'CLEAR_DEPOSIT'
