import { combineReducers } from 'redux'

import ageBands from './ageBands/reducer'
import list from './list/reducer'
import single from './single/reducer'
import legacyList from './legacyList/reducer'

export default combineReducers({
  ageBands,
  legacyList,
  list,
  single,
})
