import _ from 'lodash'

import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withEnquiryReportsService } from 'services/legacy/enquiries/reports'
import { withPaginationUtils } from 'services/utils/pagination'
import { withSecurityService } from 'services/security'
import { withRouter } from 'services/router'

import { useBasicReport } from 'hooks/reports'

import { Chart, DatePicker, Form, InfiniteDropdowns, ReportView, Select, Toolbar } from 'components'

import i18n from 'translations'

import { getChartData, getColumns, getTableData } from './PerformanceHelper'
import { BREAKDOWN_MODE_OPTIONS, BREAKDOWN_MODE_TYPE } from './constants'

const PerformanceContainer = ({
  enquiryReportsActions,
  enquiryReportsSelectors,
  errorMessages,
  isFetching,
  isOrganizationContext,
  location,
  paginationUtils,
  performanceData,
  setLocationQuery,
  totalResults: tableTotalResult,
}) => {
  const {
    breakdownMode,
    dateRange,
    nursery,
    onBreakdownModeChange,
    onDateChange,
    onNurseryChange,
    onPageChange,
    page,
    pageCount,
    perPage,
    statistics: chartData,
    tableColumns,
    tableData,
    totalResults,
  } = useBasicReport({
    actions: {
      clearChildren: enquiryReportsActions.clearPerformance,
      clearNurseries: enquiryReportsActions.clearPerformance,
      getChildren: enquiryReportsActions.getPerformance,
      getNurseries: enquiryReportsActions.getPerformance,
    },
    breakdownModeOptions: BREAKDOWN_MODE_OPTIONS,
    childrenData: performanceData,
    childrenTotalResults: tableTotalResult,
    defaultFilters: {
      breakdownMode: _.find(BREAKDOWN_MODE_OPTIONS, { value: BREAKDOWN_MODE_TYPE.MONTH }),
    },
    errorMessages,
    getColumns,
    getStatistics: getChartData,
    getTableData,
    isOrganizationContext,
    location,
    nurseriesData: performanceData,
    nurseriesTotalResults: tableTotalResult,
    paginationUtils,
    selectors: {
      getCriteria: enquiryReportsSelectors.getPerformanceCriteria,
    },
    setLocationQuery,
    statisticsData: performanceData,
  })

  const renderFilters = () => (
    <Toolbar bottomLine>
      <Toolbar.Group>
        {isOrganizationContext && (
          <Toolbar.Item>
            <Form.Row
              label={i18n.t('module:Enquiries:Report:Performance:Filters:Site:label')}
              verticalLabel
            >
              <InfiniteDropdowns.Nurseries
                value={nursery}
                onChange={onNurseryChange}
              />
            </Form.Row>
          </Toolbar.Item>
        )}
        <Toolbar.Item>
          <Form.Row
            label={i18n.t('module:Enquiries:Report:Performance:Filters:DateRange:label')}
            verticalLabel
          >
            <DatePicker
              clearable={false}
              type="month"
              value={dateRange}
              range
              onChange={onDateChange}
            />
          </Form.Row>
        </Toolbar.Item>
        <Toolbar.Item>
          <Form.Row
            label={i18n.t('module:Enquiries:Report:Performance:Filters:Breakdown:label')}
            verticalLabel
          >
            <Select
              clearable={false}
              options={BREAKDOWN_MODE_OPTIONS}
              value={breakdownMode}
              onChange={onBreakdownModeChange}
            />
          </Form.Row>
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )

  return (
    <ReportView.DetailPage
      chart={(
        <Chart.Column
          categories={chartData?.categories}
          data={chartData?.data}
          enableLegends
        />
      )}
      errorMessages={errorMessages}
      filters={renderFilters()}
      isOrganizationContext={isOrganizationContext}
      isPageLoading={isFetching && 1 === page}
      isTableLoading={isFetching}
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      tableColumns={tableColumns}
      tableData={tableData}
      title={i18n.t('module:Enquiries:Report:Performance:title')}
      totalResults={totalResults}
      hideExport
      onPageChange={onPageChange}
    />
  )
}

const mapState = (state, {
  appSelectors,
  enquiryReportsPerformanceState,
  enquiryReportsSelectors,
  securitySelectors,
}) => ({
  errorMessages: appSelectors.getErrorMessages(enquiryReportsPerformanceState),
  isFetching: appSelectors.getIsFetching(enquiryReportsPerformanceState),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
  performanceData: enquiryReportsSelectors.getEnquiryReportsPerformanceDataSelector(state),
  totalResults: appSelectors.getTotalResults(enquiryReportsPerformanceState),
})

const enhance = compose(
  withRouter,
  withAppService,
  withEnquiryReportsService,
  withPaginationUtils,
  withSecurityService,
  connect(mapState),
)

export default enhance(PerformanceContainer)
