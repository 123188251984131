import React from 'react'

import { Option } from 'constants/models'
import { TableColumn } from 'components/Table'
import { PaginationDetails } from 'services/utils/pagination/constants'

import { generateRoute } from 'utils/routing'

import { Callout, EmptyState, Page, Pagination, Section, Select, Spinner, Table, Toolbar, Typography } from 'components'

import i18n from 'translations'

const CONSUMABLES_COLUMNS: TableColumn[] = [
  {
    align: 'left',
    field: 'name',
    title: i18n.t('global:Finance:Consumable'),
  },
  {
    align: 'center',
    field: 'action',
    title: '',
    width: '100px',
  },
]

interface ManagementConsumablesV3ViewProps {
  active: Option
  activeOptions: Option[]
  errorMessages: string[]
  isLoading: boolean
  onActiveFilterChange: (value: Option) => void
  pagination: PaginationDetails
  tableData: any
}

const ManagementConsumablesV3View = ({
  active,
  activeOptions,
  errorMessages,
  isLoading,
  onActiveFilterChange,
  pagination,
  tableData,
}: ManagementConsumablesV3ViewProps): JSX.Element => {
  const renderPagination = () => !isLoading && (
    <Pagination
      {...pagination}
    />
  )

  const renderToolbar = () => (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <Select
            clearable={false}
            options={activeOptions}
            placeholder={i18n.t('global:Active')}
            searchable={false}
            value={active}
            onChange={onActiveFilterChange}
          />
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )

  const renderContent = () => {
    if (isLoading) {
      return (
        <Spinner />
      )
    }

    if (!tableData?.length) {
      const text = (
        <span>
          {i18n.t('module:Management:Finance:Consumables:emptyState1')}
          <br />
          {i18n.t('module:Management:Finance:Consumables:emptyState2')}
        </span>
      )

      return (
        <EmptyState
          icon="consumables"
          text1={text}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={CONSUMABLES_COLUMNS}
          data={tableData}
          minWidth={400}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const actions = (
    <Section.Actions primary={[{ to: generateRoute('MANAGEMENT.FINANCE_SETTINGS.CONSUMABLES.ADD') }]} />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title="Consumables"
    >
      <Callout content={errorMessages} error />
      <Typography margin="20px 0">
        {i18n.t('module:Management:Finance:Consumables:subtitle')}
      </Typography>
      {renderToolbar()}
      {renderContent()}
    </Page.Section>
  )
}

export default ManagementConsumablesV3View
