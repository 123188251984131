import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { FILE_TYPES } from 'constants/mimetypes'

import { isVideoMimeType } from 'utils/attachment'

import { MAX_FILE_AGE_BEFORE_MARKED_FAILED, UPLOADED_FILE_STATUS } from '../constants'

const getUploadState = (state = {}) => state.upload?.common

export const getFiles = createSelector([getUploadState], (state = {}) => state.files)

export const getFilesByReference = (reference) => createSelector([getFiles], (files = []) => {
  const [referenceType, referenceId] = reference || []

  return _.filter(files, ({ referencePage: [type, id] }) => (
    type === referenceType
    && id === referenceId
  ))
})

export const getReferencePagesStorage = createSelector([getUploadState], (state = {}) => state.referencePagesStorage)

export const getFilesGroupedByReference = (files) => _.flow(
  (items) => _.groupBy(items, (e) => e.referencePage),
  (items) => _.map(items, (values, name) => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const [type, id] = name?.split(',')

    return ({
      name: [type, +id],
      values: _.sortBy(values, ({ createdAt }) => moment(createdAt).format('x')).reverse(),
    })
  }),
)(files).reverse()

export const getFileFullName = ({ mimeType, name }) => (
  `${name}${_.get(_.find(FILE_TYPES, ({ mimeTypes }) => _.includes(mimeTypes, mimeType)), 'label')?.toLowerCase()}`
)

export const getFileById = (id) => createSelector([getFiles], (files = {}) => (
  _.find(files, ({ id: fileId }) => id === fileId)
))

export const getUploadingFilesInProgress = () => createSelector([getFiles], (files = {}) => (
  _.filter(files, ({ uploadingInProgress }) => uploadingInProgress)
))

export const getInitFileBody = (file) => ({
  fileName: file.name,
  mimeType: file.mimeType,
  size: file.size,
  type: file.referencePage[0],
  typeId: file.referencePage[1],
})

export const normalizeUploadedFileData = (file, sync) => {
  const {
    children,
    createdAt,
    description,
    id,
    itemFromBe,
    mimeType,
    name,
    size,
    status,
    thumbnail,
    url,
    videoEncoded,
    videoThumbnail,
  } = file

  const isVideoReady = isVideoMimeType(mimeType) && UPLOADED_FILE_STATUS.READY === status
  const playerMimeType = isVideoReady ? videoEncoded.mimeType : mimeType

  return {
    createdAt,
    description,
    file: isVideoReady ? videoEncoded.url : url,
    id,
    // NOTE: tmp solution for A/I double fetching the thumbnail on the list. It cause
    // downloading the full size media. We should use "itemFromBe" for that and it should
    // be set true for any sync actions. No time for retesting
    isSync: sync,
    isUploaded: true,
    itemFromBe: _.isUndefined(itemFromBe) ? true : itemFromBe,
    lambdaStatus: status,
    mimeType,
    name,
    playerMimeType,
    size,
    taggedChildren: children,
    thumbnail: sync ? (thumbnail || url) : videoThumbnail?.url || url,
  }
}

export const verifyMediaStatus = (item) => {
  if (
    UPLOADED_FILE_STATUS.UPLOADING === item.status
    && (
      moment().format('X') - moment(item.createdAt).format('X') >= MAX_FILE_AGE_BEFORE_MARKED_FAILED
    )
  ) {
    return {
      ...item,
      status: UPLOADED_FILE_STATUS.FAILED,
    }
  }

  return item
}
