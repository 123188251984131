import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { RootState } from 'core/reducers'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as periodTimesSelectors from './selectors'
import { PeriodTimesListState, PeriodTimesSingleState } from './models'

export interface withPeriodTimesServiceProps {
  periodTimesActions: typeof actions
  periodTimesListState: PeriodTimesListState
  periodTimesSelectors: typeof periodTimesSelectors
  periodTimesSingleState: PeriodTimesSingleState
}

const withPeriodTimesService = <P extends withPeriodTimesServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ periodTimes }: RootState) => ({ periodTimes })

  const mapDispatch = injectActions('periodTimesActions', actions)

  const Component = ({ periodTimes, periodTimesActions, ...others }) => (
    <WrappedComponent
      periodTimesActions={periodTimesActions}
      periodTimesListState={periodTimes.list}
      periodTimesSelectors={periodTimesSelectors}
      periodTimesSingleState={periodTimes.single}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withPeriodTimesService
