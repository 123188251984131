import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { generateRoute } from 'utils/routing'

import { EVENTS, logEvent } from 'analytics'

import { withAppService } from 'services/app'
import { withDeviceService } from 'services/device'
import { withMembershipsService } from 'services/legacy/memberships'
import { withMembershipRegistersService } from 'services/legacy/membershipRegisters'
import { withPaginationUtils } from 'services/utils/pagination'

import StaffRegisterListView from './StaffRegisterListView'

export const MEMBERSHIP_REGISTERS_GROUP = {
  read: [
    'membershipRegisterEntry.nurseryClass',
    'nurseryClass',
    'membership.pin',
    'membership.details',
  ],
}

class StaffRegisterListContainer extends Component {
  constructor(props) {
    super(props)

    const { paginationUtils } = props
    const { setPageLocationQuery } = paginationUtils

    this.state = {
      currentDate: moment(),
      filters: {
        endDate: moment(),
        registerStatus: [],
        searchBarText: null,
        startDate: moment(),
      },
    }

    this.timeHandler = null

    this.updateCurrentDate()

    setPageLocationQuery(false)
  }

  componentDidMount() {
    const { deviceIdentity } = this.props

    logEvent(EVENTS.STAFF_REGISTER_PAGE_VIEWED)

    if (deviceIdentity) {
      this.fetch()
    }
  }

  componentWillUnmount() {
    const { membershipRegistersActions } = this.props

    membershipRegistersActions.clearList()
    clearTimeout(this.timeHandler)
  }

  fetch = ({ updateStatistics = true } = {}) => {
    const {
      membershipRegistersActions,
      membershipRegistersSelectors,
      paginationUtils,
    } = this.props
    const { filters } = this.state

    const { page, perPage } = paginationUtils

    const criteria = membershipRegistersSelectors.getMembershipRegistersListCriteria({
      ...filters,
      nonTeaching: true,
    })

    membershipRegistersActions.list({
      mergeResult: 1 !== page,
      params: {
        criteria,
        groups: MEMBERSHIP_REGISTERS_GROUP,
        limit: perPage,
        page,
      },
    })

    if (updateStatistics && 1 === page) {
      const statisticsCriteria = membershipRegistersSelectors.getMembershipRegistersStatisticsCriteria(filters)

      membershipRegistersActions.getStatistics({
        params: {
          criteria: statisticsCriteria,
        },
      })
    }
  }

  updateCurrentDate = () => {
    this.timeHandler = setTimeout(() => {
      this.setState({
        currentDate: moment(),
      })

      this.updateCurrentDate()
    }, 30 * 1000)
  }

  handleSummaryFilterChange = (selectedItem) => {
    const { paginationUtils } = this.props
    const { filters } = this.state
    const { onPageChange } = paginationUtils
    const { registerStatus } = filters
    const index = registerStatus.indexOf(selectedItem)

    logEvent(EVENTS.STAFF_REGISTER_FILTER_USED, { type: 'summary tab' })

    if (-1 === index) {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          registerStatus: [...prevState.filters.registerStatus, selectedItem],
        },
      }), () => onPageChange(() => this.fetch({ updateStatistics: false }))(1))

      return
    }

    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        registerStatus: prevState.filters.registerStatus.filter((value) => value !== selectedItem),
      },
    }), () => onPageChange(() => this.fetch({ updateStatistics: false }))(1))
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleSearchBarChange = (searchBarText) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        searchBarText,
      },
    }), () => onPageChange(this.fetch)(1))

    logEvent(EVENTS.STAFF_REGISTER_FILTER_USED, { type: 'search' })
  }

  handleStaffClick = (item) => {
    const { membershipsActions, navigate } = this.props
    const { id } = item

    membershipsActions.setSelectedStaff(item)

    const { hasPin } = item

    if (hasPin) {
      return navigate(`${generateRoute('STAFF_REGISTER.AUTHENTICATION')}?staff=${id}`)
    }

    return navigate(`${generateRoute('STAFF_REGISTER.CREATE_PIN')}?staff=${id}`)
  }

  render() {
    const {
      deviceIdentity,
      isFetching,
      isStatisticsFetching,
      listTotalResult,
      paginationUtils,
      staffRegister,
      staffStatistics,
    } = this.props
    const { currentDate, filters } = this.state

    const { registerStatus } = filters
    const { getPageCount, page, perPage } = paginationUtils
    const pageCount = getPageCount(listTotalResult)

    return (
      <StaffRegisterListView
        currentDate={currentDate}
        deviceIdentity={deviceIdentity}
        isFetching={isFetching}
        isStatisticsFetching={isStatisticsFetching}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        records={staffRegister}
        registerStatus={registerStatus}
        staffStatistics={staffStatistics}
        totalResults={listTotalResult}
        onPageChange={this.handlePageChange}
        onSearchBarChange={this.handleSearchBarChange}
        onStaffClick={this.handleStaffClick}
        onSummaryFilterChange={this.handleSummaryFilterChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  deviceSelectors,
  membershipRegistersListState,
  membershipRegistersSelectors,
  membershipRegistersStatisticsState,
}) => ({
  deviceIdentity: deviceSelectors.getDeviceIdentity(state),
  isFetching: appSelectors.getIsFetching(membershipRegistersListState),
  isStatisticsFetching: appSelectors.getIsFetching(membershipRegistersStatisticsState),
  listTotalResult: membershipRegistersSelectors.getMembershipRegistersListTotalResultSelector(state),
  staffRegister: membershipRegistersSelectors.getMembershipRegistersFormattedListSelector(state),
  staffStatistics: membershipRegistersSelectors.getMembershipRegistersStatisticsDataSelector(state),
})

const enhance = compose(
  withAppService,
  withDeviceService,
  withMembershipsService,
  withMembershipRegistersService,
  withPaginationUtils,
  connect(mapState),
)

export default enhance(StaffRegisterListContainer)
