export const CREATE_ENQUIRY_ACTIVITIES = 'CREATE_ENQUIRY_ACTIVITIES'
export const CREATE_ENQUIRY_ACTIVITIES_SUCCESS = 'CREATE_ENQUIRY_ACTIVITIES_SUCCESS'
export const CREATE_ENQUIRY_ACTIVITIES_FAILED = 'CREATE_ENQUIRY_ACTIVITIES_FAILED'

export const GET_ENQUIRY_ACTIVITIES = 'GET_ENQUIRY_ACTIVITIES'
export const GET_ENQUIRY_ACTIVITIES_SUCCESS = 'GET_ENQUIRY_ACTIVITIES_SUCCESS'
export const GET_ENQUIRY_ACTIVITIES_FAILED = 'GET_ENQUIRY_ACTIVITIES_FAILED'

export const UPDATE_ENQUIRY_ACTIVITIES = 'UPDATE_ENQUIRY_ACTIVITIES'
export const UPDATE_ENQUIRY_ACTIVITIES_SUCCESS = 'UPDATE_ENQUIRY_ACTIVITIES_SUCCESS'
export const UPDATE_ENQUIRY_ACTIVITIES_FAILED = 'UPDATE_ENQUIRY_ACTIVITIES_FAILED'

export const REMOVE_ENQUIRY_ACTIVITIES = 'REMOVE_ENQUIRY_ACTIVITIES'
export const REMOVE_ENQUIRY_ACTIVITIES_SUCCESS = 'REMOVE_ENQUIRY_ACTIVITIES_SUCCESS'
export const REMOVE_ENQUIRY_ACTIVITIES_FAILED = 'REMOVE_ENQUIRY_ACTIVITIES_FAILED'

export const CLEAR_ENQUIRY_ACTIVITIES_SINGLE = 'CLEAR_ENQUIRY_ACTIVITIES_SINGLE'
