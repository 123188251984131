import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { getFormattedEntries, getStartAndEndDateFromTimeRange } from '../helpers'

const getListWorklogByDaySelector = (state) => state.membershipRegisters.listWorklogByDay

export const getListWorklogByDayDataSelector = createSelector([getListWorklogByDaySelector], (state) => state.data)

export const getListWorklogByDayMetaSelector = createSelector([getListWorklogByDaySelector], (state) => state.meta)

export const getListWorklogByPreviousDayOngoing = (currentDate, ownCurrentRegisters) => createSelector(
  [getListWorklogByDayDataSelector],
  (data) => {
    if (!data?.length && !ownCurrentRegisters) {
      return null
    }

    const currentRegisters = ownCurrentRegisters || data[0].currentRegisters

    const previousRecord = _.findLast(currentRegisters, ({ date }) => (
      moment(currentDate).format(DEFAULT_DATE_FORMAT) !== moment(date).format(DEFAULT_DATE_FORMAT)
    ))

    if (!previousRecord) {
      return null
    }

    const { date, entries } = previousRecord

    if (!entries?.length) {
      return previousRecord
    }

    const firstEntry = entries[0]
    const lastEntry = entries[entries.length - 1]

    const startTime = firstEntry.signInAt
    const endTime = lastEntry.signOutAt

    return {
      ...previousRecord,
      ...getStartAndEndDateFromTimeRange(date, startTime, endTime),
      entries: getFormattedEntries(date, entries),
    }
  },
)

export const getListWorklogByCurrentShifts = createSelector(
  [getListWorklogByDayDataSelector],
  (data) => {
    if (!data?.length) {
      return null
    }

    return _.filter(data[0].shifts, ({ membershipShiftTimes }) => !!membershipShiftTimes?.length)
  },
)
