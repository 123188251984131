import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { CriterionV2 } from 'constants/service'

import { ChildrenStartingReportCriteriaParamProps, ChildrenStartingReportRootState } from '../models'

const getChildrenStartingReport = (state: ChildrenStartingReportRootState) => state.childrenStartingReport

export const getChildrenStartingReportChildren = createSelector(
  [getChildrenStartingReport],
  (state) => state.children,
)

export const getChildrenStartingReportChildrenData = createSelector(
  [getChildrenStartingReportChildren],
  (state) => state.data,
)

export const getCriteria = ({
  ageRange,
  dateRange,
  room,
}: ChildrenStartingReportCriteriaParamProps): CriterionV2[] => {
  const criteria = []

  if (dateRange?.after && dateRange?.before) {
    criteria.push({
      field: 'startDate',
      value: moment(dateRange.after).format(DEFAULT_DATE_FORMAT),
    })

    criteria.push({
      field: 'endDate',
      value: moment(dateRange.before).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (room) {
    criteria.push({
      field: 'nurseryClass',
      value: room,
    })
  }

  if (ageRange) {
    const { from, to } = ageRange

    if (from) {
      criteria.push({
        field: 'birthEndDate',
        value: moment()
          .add(-from, 'months')
          .format(DEFAULT_DATE_FORMAT),
      })
    }

    if (to) {
      criteria.push({
        field: 'birthStartDate',
        value: moment()
          .add(-to, 'months')
          .format(DEFAULT_DATE_FORMAT),
      })
    }
  }

  return criteria
}

