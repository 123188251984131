import { combineReducers } from 'redux'

import list, { IntegrationFeesListState } from './list/reducer'
import single, { IntegrationFeesSingleState } from './single/reducer'

export default combineReducers({
  list,
  single,
})

export interface IntegrationFeesState {
  list: IntegrationFeesListState
  single: IntegrationFeesSingleState
}
