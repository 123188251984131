import _ from 'lodash'
import moment from 'moment'

import { addMillisecondsFromMidnight, isSameDay } from 'utils/date'

import { STAFF_ENTRY_TYPES, STAFF_REGISTER_STATUS } from './constants'

const HOURS_MINUTES_FORMAT = 'HH:mm'

export const findStaffStatus = (data) => {
  if (!data?.entries?.length || !data?.ongoing) {
    return STAFF_REGISTER_STATUS.CLOCKED_OUT
  }

  const { entries } = data
  const lastEntry = _.find(entries, ({ signOutAt }) => !signOutAt)
  const { signOutAt: lastSignOutAt, type: lastEntryType } = lastEntry

  if (lastEntryType === STAFF_ENTRY_TYPES.BREAK && !lastSignOutAt) {
    return STAFF_REGISTER_STATUS.ON_BREAK
  }

  if (lastEntryType === STAFF_ENTRY_TYPES.SIGN_IN && !lastSignOutAt) {
    return STAFF_REGISTER_STATUS.CLOCKED_IN
  }

  return STAFF_REGISTER_STATUS.CLOCKED_OUT
}

export const getStartAndEndDateFromTimeRange = (date, startTime, endTime) => ({
  endDate: endTime ? addMillisecondsFromMidnight(endTime, date).toDate() : moment().toDate(),
  startDate: addMillisecondsFromMidnight(startTime, date).toDate(),
})

export const getFormattedEntries = (date, entries) => _.map(entries, (item) => {
  const { nurseryClass, signInAt, signOutAt } = item
  const { endDate, startDate } = getStartAndEndDateFromTimeRange(date, signInAt, signOutAt)
  const { id, name } = nurseryClass || {}
  const isSameDayEntry = isSameDay(startDate, endDate)

  return {
    ...item,
    entryEndDate: endDate,
    entryStartDate: startDate,
    isSameDayEntry,
    nurseryClass: nurseryClass
      ? {
        label: name,
        value: id,
      } : undefined,
    signInAt: addMillisecondsFromMidnight(signInAt, startDate),
    signInAtOriginal: signInAt,
    signOutAt: signOutAt ? addMillisecondsFromMidnight(signOutAt, startDate) : null,
    signOutAtOriginal: signOutAt,
  }
})

export const getTimeRangeParts = (startDate, endDate) => {
  const hours = Math.round(endDate.diff(startDate, 'hours', true)) || 24
  const startTime = startDate.format(HOURS_MINUTES_FORMAT)
  const endTime = endDate.format(HOURS_MINUTES_FORMAT)

  return {
    endTime,
    hours,
    startTime,
  }
}

export const getTimeRangeString = (startDate, endDate) => {
  const { endTime, hours, startTime } = getTimeRangeParts(startDate, endDate)

  return `${hours}h (${startTime} - ${endTime})`
}

