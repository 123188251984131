import _ from 'lodash'

import { Currency } from 'components'

import i18n from 'translations'

import { NURSERY_TABLE_COLUMNS, ORGANIZATION_TABLE_COLUMNS } from './constants'

export const getColumns = (isOrganizationContext) => {
  if (isOrganizationContext) {
    return ORGANIZATION_TABLE_COLUMNS
  }

  return NURSERY_TABLE_COLUMNS
}

export const getTableData = ({ data, hasError }) => {
  if (!data?.length || hasError) {
    return null
  }

  return _.map(data, ({
    held,
    pendingDeposits,
    pendingRefunds,
    raised,
    refunded,
    resource,
  }) => ({
    held: <Currency value={held} />,
    name: resource.name,
    pending: <Currency value={pendingDeposits} />,
    pendingRefunds: <Currency value={pendingRefunds} />,
    raised: <Currency value={raised} />,
    refunded: <Currency value={refunded} />,
  }))
}

export const getStatistics = ({ data, hasError, isOrganizationContext }) => {
  if (!data || !isOrganizationContext || hasError) {
    return null
  }

  return [
    {
      label: i18n.t('module:Finance:Reports:Deposits:Organization:Statistics:totalRaised'),
      value: <Currency value={data.raised} />,
    },
    {
      label: i18n.t('module:Finance:Reports:Deposits:Organization:Statistics:totalHeld'),
      value: <Currency value={data.held} />,
    },
    {
      label: i18n.t('module:Finance:Reports:Deposits:Organization:Statistics:totalRefunded'),
      value: <Currency value={data.refunded} />,
    },
    {
      label: i18n.t('module:Finance:Reports:Deposits:Organization:Statistics:totalPending'),
      value: <Currency value={data.pendingDeposits} />,
    },
    {
      label: i18n.t('module:Finance:Reports:Deposits:Organization:Statistics:totalPendingRefunds'),
      value: <Currency value={data.pendingRefunds} />,
    },
  ]
}
