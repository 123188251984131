import _ from 'lodash'

import React from 'react'

import { Box, Tabs } from 'components'

import FoodMenuForm from './components/FoodMenuForm'

const FoodMenuView = ({
  date,
  errorMessages,
  foodCategory,
  formValues,
  getFormName,
  isSubmitting,
  onChangeTab,
  onSubmit,
  tabIndex,
}) => (
  <Box withPadding>
    <Tabs
      defaultPosition={tabIndex}
      buttonType
      noMargin
      onChange={onChangeTab}
    >
      {_.map(foodCategory.sessions, (session) => (
        <Tabs.Item
          key={session.value}
          title={session.label}
        >
          <FoodMenuForm
            errorMessages={errorMessages}
            foodCategory={foodCategory}
            form={getFormName({ category: foodCategory.value, date, session: session.value })}
            formValues={formValues}
            isSubmitting={isSubmitting}
            session={session}
            onSubmit={onSubmit}
          />
        </Tabs.Item>
      ))}
    </Tabs>
  </Box>
)

export default FoodMenuView
