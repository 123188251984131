import _ from 'lodash'

import React from 'react'

import { AnnualisedInvoiceSettings } from 'services/nurseries/models'

import { Field, Label, Section, Toolbar } from 'components'

import i18n from 'translations'
import { getMonthOptions } from 'utils/date'

const monthOptions = getMonthOptions()

interface AnnualisedSettingViewProps {
  annualisedInvoiceSettings?: AnnualisedInvoiceSettings
  disableEdit?: boolean
  editLink?: string
  subtitle?: string
  title?: string
}

const AnnualisedSettingView: React.FC<AnnualisedSettingViewProps> = ({
  annualisedInvoiceSettings,
  disableEdit,
  editLink,
  subtitle,
  title,
}) => {
  const {
    allowOverwriteOnChildLevel,
    excludedMonths,
    months,
    weeks,
  } = annualisedInvoiceSettings || {}

  const excludedMonthsDescriptions = _.join(_.map(
    excludedMonths,
    (monthNumber) => _.find(monthOptions, { value: monthNumber })?.label,
  ), ', ')

  return (
    <Section
      actions={!disableEdit && <Section.Actions tertiary={{ to: editLink }} />}
      title={title}
    >
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <Label text={subtitle} />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      <Field.InlineEdit
        description={`${weeks} ${i18n.t('global:weeks')} / ${months} ${i18n.t('global:months')}`}
        label={i18n.t('components:Settings:AnnualisedSetting:weekLabel')}
        padding="10px 0"
        noBorder
      />
      <Field.InlineEdit
        description={excludedMonthsDescriptions}
        label={i18n.t('components:Settings:AnnualisedSetting:excludedMonths')}
        padding="10px 0"
        noBorder
      />
      <Field.InlineEdit
        description={allowOverwriteOnChildLevel ? i18n.t('global:Yes') : i18n.t('global:No')}
        label={i18n.t('components:Settings:AnnualisedSetting:allowOverwrites')}
        padding="10px 0"
        noBorder
      />
    </Section>
  )
}

export default AnnualisedSettingView
