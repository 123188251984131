import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import Slider from 'react-slick'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Avatar, BlockedIcon, Button, Typography } from 'components'

import { StyledCanBePhotographed, StyledChildrenSlider, StyledIconContainer, StyledSlide } from './ChildrenSliderStyled'

const SLIDE_WIDTH = 150

interface ChildrenSliderProps {
  childrenList: any[]
  marginBottom?: boolean
  onClickSlide?: (i: number) => void
  onRemoveChild?: (e: Event, i: number) => void
  selectedSlideIndex?: boolean
  withCanBePhotographedLabel?: boolean
}

interface Slide {
  firstName?: string
  id?: number
  information?: {
    canBePhotographed?: boolean
    canBeTaggedInGroupPhotos?: boolean
  }
  photo?: string
  surname?: string
}

interface ChildrenSliderState {
  updateSlider: string
  visibleSlides: number
}

class ChildrenSlider extends Component<ChildrenSliderProps, ChildrenSliderState> {
  private sliderContainer: any

  private slider: any

  constructor(props) {
    super(props)

    this.state = {
      updateSlider: moment().format('x'),
      visibleSlides: 0,
    }

    this.sliderContainer = React.createRef()
    this.slider = React.createRef()
  }

  componentDidMount() {
    this.getVisibleSlidesForResolution()
    window.addEventListener('resize', this.getVisibleSlidesForResolution)
  }

  shouldComponentUpdate(nextProps) {
    const { updateSlider: oldUpdateSlider } = this.state
    const { selectedSlideIndex, updateSlider } = nextProps

    if (updateSlider > oldUpdateSlider) {
      this.slider.slickGoTo(selectedSlideIndex)
      this.setState({ updateSlider })
    }

    return true
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getVisibleSlidesForResolution)
  }

  getVisibleSlidesForResolution = () => {
    const visibleSlides = this.calculateLengthVisibleSlides()

    this.setState({
      visibleSlides,
    })
  }

  calculateLengthVisibleSlides = () => {
    const { width } = this.sliderContainer.getBoundingClientRect()

    return Math.round(width / SLIDE_WIDTH)
  }

  render() {
    const {
      childrenList,
      marginBottom,
      onClickSlide,
      onRemoveChild,
      selectedSlideIndex,
      withCanBePhotographedLabel,
    } = this.props
    const { visibleSlides } = this.state

    const getSlide = (params: Slide = {}, index) => {
      const {
        firstName,
        id,
        information,
        photo,
        surname,
      } = params
      const {
        canBePhotographed,
        canBeTaggedInGroupPhotos,
      } = information || {}

      return (
        <StyledSlide
          $isSelected={index === selectedSlideIndex}
          key={`${id}_${index}`}
          style={{ minHeight: (onRemoveChild ? 170 : 140) }}
          onClick={onClickSlide ? () => onClickSlide(index) : null}
        >
          {onRemoveChild && (
            <StyledIconContainer>
              <Button
                color="error"
                hierarchy="tertiary"
                icon="close"
                size="small"
                negativeMargins
                onClick={(e) => onRemoveChild(e, id)}
              />
            </StyledIconContainer>
          )}
          <Avatar
            direction="vertical"
            initials={[firstName, surname]}
            src={photo}
            title={(
              <Typography
                color={index !== selectedSlideIndex ? NEUTRAL_COLOURS.BASIC : NEUTRAL_COLOURS.WHITE}
                fontSize={14}
              >
                {`${firstName} ${surname}`}
              </Typography>
            )}
          />
          {withCanBePhotographedLabel && (
            <StyledCanBePhotographed>
              <BlockedIcon
                color={NEUTRAL_COLOURS.BASIC}
                hide={canBePhotographed && canBeTaggedInGroupPhotos}
                icon={canBePhotographed && !canBeTaggedInGroupPhotos ? 'group' : 'camera'}
              />
            </StyledCanBePhotographed>
          )}
        </StyledSlide>
      )
    }

    const options = {
      centerMode: false,
      infinite: false,
      slidesToShow: visibleSlides,
      swipeToSlide: true,
    }

    return (
      <div ref={(e) => { this.sliderContainer = e }}>
        <StyledChildrenSlider $marginBottom={marginBottom}>
          <Slider
            ref={(e) => { this.slider = e }}
            {...options}
          >
            {_.map(childrenList, getSlide)}
          </Slider>
        </StyledChildrenSlider>
      </div>
    )
  }
}

export default ChildrenSlider
