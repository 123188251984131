import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledContainer = styled.div`
  display: none;
  
  @media(max-width: 550px) {
    display: block;
  }
`

export const StyledBox = styled.div`
  display: grid;
  grid-template-columns: 70px auto;
  border-radius: 12px;
  margin-bottom: 10px;
`

export const StyledLeftColumn = styled.div`
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  padding: 10px 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ primary }) => primary && `
    background: ${getBrandingColor('primary-color')};
  `}  
  
  ${({ secondary }) => secondary && `
    background: ${getBrandingColor('secondary-color')};
  `}
`

export const StyledRightColumn = styled.div`
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 14px;
  background: #F7F7F7;
`

export const StyledDescription = styled.h5`
  color: #A0A0A0;
  margin: 0;
`

export const StyledValue = styled.h3`
  color: #4D4D4D;
  font-size: 20px;
  margin: 0;
`

export const StyledLastPeriod = styled.div`
  display: flex;
  margin-top: 2px;
`

export const StyledLastPeriodLabel = styled.div`
  margin-left: 5px;
  display: inline-block;
`

export const StyledLastPeriodValue = styled.span`
  display: grid;
  align-items: center;
  grid-template-columns: 12px auto;
  grid-column-gap: 6px;
  color: #8CBE91;
  
  ${({ red }) => red && `
    color: #D95A3F;
  `}
`
