import {
  CLEAR_MEMBERSHIP_REGISTER_CHANGELOG,
  GET_MEMBERSHIP_REGISTER_CHANGELOG,
  GET_MEMBERSHIP_REGISTER_CHANGELOG_FAILED,
  GET_MEMBERSHIP_REGISTER_CHANGELOG_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case GET_MEMBERSHIP_REGISTER_CHANGELOG:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_MEMBERSHIP_REGISTER_CHANGELOG_SUCCESS:
      return { ...state, data: payload?.data, isFetching: false }
    case GET_MEMBERSHIP_REGISTER_CHANGELOG_FAILED:
      return { ...state, error, isFetching: false }
    case CLEAR_MEMBERSHIP_REGISTER_CHANGELOG:
      return initialState
    default:
      return state
  }
}
