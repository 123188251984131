import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { ENQUIRY_STATUS_CODES } from 'services/legacy/enquiries/constants'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withEnquiriesService, withEnquiryStatusTypesService } from 'services/legacy/enquiries'
import { withRouter } from 'services/router'

import EnrolChildModalView from './EnrolChildModalView'

class EnrolChildModalContainer extends Component {
  componentDidMount() {
    const {
      enquiryStatusTypesActions,
      enquiryStatusTypesSelectors,
    } = this.props

    const criteria = enquiryStatusTypesSelectors.getCriteria({ code: ENQUIRY_STATUS_CODES.ENROLLED })

    enquiryStatusTypesActions.list({
      params: {
        criteria,
      },
    })
  }

  componentWillUnmount() {
    const { enquiryStatusTypesActions } = this.props

    enquiryStatusTypesActions.clear()
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleEnrolChildClick = () => {
    const { onEnrolChildClick } = this.props

    this.handleCloseClick()

    if (onEnrolChildClick) {
      onEnrolChildClick()
    }
  }

  handleMarkAsEnrolledClickSuccess = (enrolledStatusType) => {
    const { onMarkAsEnrolledSuccess } = this.props

    if (onMarkAsEnrolledSuccess) {
      onMarkAsEnrolledSuccess(enrolledStatusType)
    }

    this.handleCloseClick()
  }

  handleMarkAsEnrolledClick = () => {
    const { enquiriesActions, enquiry, enrolledStatusType } = this.props
    const { id } = enquiry

    enquiriesActions.update({
      body: { status: { id: enrolledStatusType?.id } },
      onSuccess: () => this.handleMarkAsEnrolledClickSuccess(enrolledStatusType),
      params: [id, {}],
    })
  }

  handleCancelClick = () => {
    const { onCancelClick } = this.props

    if (onCancelClick) {
      onCancelClick()
    }

    this.handleCloseClick()
  }

  render() {
    const {
      enquiry,
      errorMessages,
      isFetching,
      isSubmitting,
    } = this.props

    return (
      <EnrolChildModalView
        enquiry={enquiry}
        errorMessages={errorMessages}
        isFetching={isFetching}
        isSubmitting={isSubmitting}
        onCancelClick={this.handleCancelClick}
        onEnrolChildClick={this.handleEnrolChildClick}
        onMarkAsEnrolledClick={this.handleMarkAsEnrolledClick}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  enquiriesSelectors,
  enquiriesSingleState,
  enquiryStatusTypesListState,
  enquiryStatusTypesSelectors,
}) => ({
  enquiry: enquiriesSelectors.getEnquiriesSingleDataSelector(state),
  enrolledStatusType: enquiryStatusTypesSelectors.getEnrolledStatusTypeFromList(state),
  errorMessages: appSelectors.getErrorMessages(enquiriesSingleState),
  isFetching: appSelectors.getIsFetching(enquiryStatusTypesListState),
  isSubmitting: appSelectors.getIsSubmitting(enquiriesSingleState),
})

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withEnquiriesService,
  withEnquiryStatusTypesService,
  connect(mapState),
)

export default enhance(EnrolChildModalContainer)
