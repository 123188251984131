import React from 'react'

import i18n from 'translations'

import {
  Button,
  Checkbox,
  DatePicker,
  EmptyState,
  InfiniteDropdowns,
  Page,
  Pagination,
  Popover,
  SearchBar,
  Section,
  Select,
  Spinner,
  Toolbar,
} from 'components'

import { FundingTable } from './components'
import { StyledActionsContainer, StyledDropdownItemContainer } from './FinanceFundingStyled'

const FinanceFundingView = ({
  ageMonthsOptions,
  archiveFundingType,
  assignFundingType,
  date,
  fromAge,
  fundingStatus,
  fundingStatusOptions,
  fundingType,
  hasOtherFilters,
  isFinanceV3Enabled,
  isLoading,
  isSubmitting,
  onAgeChange,
  onAllocateChildFundingClick,
  onArchiveChildFundingClick,
  onArchiveFundingTypeChange,
  onArchiveFundingTypeClick,
  onArchiveFundingTypePopperClose,
  onAssignChildFundingClick,
  onAssignFundingTypeChange,
  onAssignFundingTypeClick,
  onAssignFundingTypePopperClose,
  onCreateFundingTypeClick,
  onDateChange,
  onEditChildFundingClick,
  onFundingStatusChange,
  onFundingTypeChange,
  onPageChange,
  onRoomChange,
  onSearchChange,
  onSelectAll,
  onSelectChild,
  onShowAllFundingClick,
  page,
  pageCount,
  perPage,
  room,
  selectAll,
  selectedChildren,
  tableData,
  toAge,
  totalResults,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const actions = (
    <Section.Actions
      primary={[{
        label: i18n.t('module:Finance:Funding:createFundingTypeButtonLabel'),
        onClick: onCreateFundingTypeClick,
      }]}
    />
  )

  const renderFilterBar = () => (
    <React.Fragment>
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <InfiniteDropdowns.Rooms
              value={room}
              searchable
              onChange={onRoomChange}
            />
          </Toolbar.Item>
          <Toolbar.Item>
            {isFinanceV3Enabled ? (
              <InfiniteDropdowns.NurseryFundingsV3
                placeholder={i18n.t('module:Finance:Funding:allFundingTypesPlaceholder')}
                value={fundingType}
                searchable
                onChange={onFundingTypeChange}
              />
            ) : (
              <InfiniteDropdowns.NurseryFunding
                placeholder={i18n.t('module:Finance:Funding:allFundingTypesPlaceholder')}
                value={fundingType}
                searchable
                onChange={onFundingTypeChange}
              />
            )}
          </Toolbar.Item>
          <Toolbar.Item>
            <Select
              options={fundingStatusOptions}
              placeholder={i18n.t('module:Finance:Funding:fundingStatusPlaceholder')}
              value={fundingStatus}
              onChange={onFundingStatusChange}
            />
          </Toolbar.Item>
          <Toolbar.Item>
            <DatePicker
              value={date}
              onChange={onDateChange}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      <Toolbar bottomLine>
        <Toolbar.Group>
          <Toolbar.Item>
            {i18n.t('module:Children:List:Filters:ageInMonthsLabel')}
          </Toolbar.Item>
          <Toolbar.Item>
            <Select
              options={ageMonthsOptions}
              placeholder={i18n.t('module:Children:List:Filters:ageInMonthsFromPlaceholder')}
              value={fromAge}
              width={100}
              onChange={onAgeChange('fromAge')}
            />
          </Toolbar.Item>
          <Toolbar.Item>{i18n.t('module:Children:List:Filters:ageInMonthsToLabel')}</Toolbar.Item>
          <Toolbar.Item>
            <Select
              options={ageMonthsOptions}
              placeholder={i18n.t('module:Children:List:Filters:ageInMonthsToPlaceholder')}
              value={toAge}
              width={100}
              onChange={onAgeChange('toAge')}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
    </React.Fragment>
  )

  const renderActions = () => (
    <StyledActionsContainer>
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <Checkbox
              label={i18n.t('global:selectAll')}
              value={selectAll}
              onChange={onSelectAll}
            />
          </Toolbar.Item>
          {selectedChildren?.length
            ? (
              <React.Fragment>
                <Toolbar.Item>
                  <Popover
                    button={(
                      <Button
                        hierarchy="tertiary"
                        icon="plus"
                        label={i18n.t('module:Finance:Funding:assignFundingTypeLabel')}
                        size="small"
                        negativeMargins
                      />
                    )}
                    disableCloseInside
                    onOutsideClick={onAssignFundingTypePopperClose}
                  >
                    <StyledDropdownItemContainer>
                      <InfiniteDropdowns.NurseryFunding
                        key="assignFundingType"
                        value={assignFundingType}
                        width={200}
                        searchable
                        onChange={onAssignFundingTypeChange}
                      />
                      <Button
                        disabled={!assignFundingType}
                        isLoading={isSubmitting}
                        label={i18n.t('module:Finance:Funding:assignFundingTypeLabel')}
                        margin="-4px 0"
                        size="small"
                        onClick={onAssignFundingTypeClick}
                      />
                    </StyledDropdownItemContainer>
                  </Popover>
                </Toolbar.Item>
                <Toolbar.Item>
                  <Popover
                    button={(
                      <Button
                        hierarchy="tertiary"
                        icon="archive"
                        label={i18n.t('module:Finance:Funding:archiveFundingTypeLabel')}
                        size="small"
                        negativeMargins
                      />
                    )}
                    disableCloseInside
                    onOutsideClick={onArchiveFundingTypePopperClose}
                  >
                    <StyledDropdownItemContainer>
                      <InfiniteDropdowns.NurseryFunding
                        key="archiveFundingType"
                        value={archiveFundingType}
                        width={200}
                        searchable
                        onChange={onArchiveFundingTypeChange}
                      />
                      <Button
                        disabled={!archiveFundingType}
                        isLoading={isSubmitting}
                        label={i18n.t('module:Finance:Funding:archiveFundingTypeLabel')}
                        margin="-4px 0"
                        size="small"
                        onClick={onArchiveFundingTypeClick}
                      />
                    </StyledDropdownItemContainer>
                  </Popover>
                </Toolbar.Item>
              </React.Fragment>
            )
            : null}
        </Toolbar.Group>
      </Toolbar>
    </StyledActionsContainer>
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!tableData?.length) {
      return (
        <EmptyState
          icon="funding"
          text1={hasOtherFilters
            ? i18n.t('module:Finance:Funding:emptyState1')
            : i18n.t('module:Finance:Funding:noChildrenEmptyState1')}
          text2={hasOtherFilters
            ? i18n.t('module:Finance:Funding:emptyState2')
            : i18n.t('module:Finance:Funding:noChildrenEmptyState2')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        {renderActions()}
        <FundingTable
          data={tableData}
          selectedChildren={selectedChildren}
          onAllocateChildFundingClick={onAllocateChildFundingClick}
          onArchiveChildFundingClick={onArchiveChildFundingClick}
          onAssignChildFundingClick={onAssignChildFundingClick}
          onEditChildFundingClick={onEditChildFundingClick}
          onSelectChild={onSelectChild}
          onShowAllFundingClick={onShowAllFundingClick}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <SearchBar
        placeholder={i18n.t('global:searchChild')}
        onChange={onSearchChange}
      />
      <Page.Section
        actions={actions}
        isLoading={isLoading}
        title={i18n.t('module:Finance:Funding:title')}
      >
        {renderFilterBar()}
        {renderContent()}
      </Page.Section>
    </React.Fragment>

  )
}

export default FinanceFundingView
