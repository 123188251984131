import React, { PropsWithChildren } from 'react'

import { StyledForm } from './CommentDrawerStyled'

const CommentDrawerForm: React.FC<PropsWithChildren<any>> = ({ children }) => (
  <StyledForm>
    {children}
  </StyledForm>
)

export default CommentDrawerForm
