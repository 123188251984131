import { generateBasicActions } from 'utils/service'

import {
  CLEAR_EXCEPTIONAL_UNAVAILABLE_TIME,
  CREATE_EXCEPTIONAL_UNAVAILABLE_TIME,
  CREATE_EXCEPTIONAL_UNAVAILABLE_TIME_FAILED,
  CREATE_EXCEPTIONAL_UNAVAILABLE_TIME_SUCCESS,
  DELETE_EXCEPTIONAL_UNAVAILABLE_TIME,
  DELETE_EXCEPTIONAL_UNAVAILABLE_TIME_FAILED,
  DELETE_EXCEPTIONAL_UNAVAILABLE_TIME_SUCCESS,
  GET_EXCEPTIONAL_UNAVAILABLE_TIME,
  GET_EXCEPTIONAL_UNAVAILABLE_TIME_FAILED,
  GET_EXCEPTIONAL_UNAVAILABLE_TIME_SUCCESS,
  UPDATE_EXCEPTIONAL_UNAVAILABLE_TIME,
  UPDATE_EXCEPTIONAL_UNAVAILABLE_TIME_FAILED,
  UPDATE_EXCEPTIONAL_UNAVAILABLE_TIME_SUCCESS,
} from './constants'

export const createExceptionalUnavailableTime = (options = {}) => ({ requestedExtraSessionsApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: CREATE_EXCEPTIONAL_UNAVAILABLE_TIME_FAILED,
      init: CREATE_EXCEPTIONAL_UNAVAILABLE_TIME,
      success: CREATE_EXCEPTIONAL_UNAVAILABLE_TIME_SUCCESS,
    },
    options,
    service: requestedExtraSessionsApiClient,
    serviceMethod: 'createExceptionalUnavailableTime',
  })
)

export const getExceptionalUnavailableTime = (options = {}) => ({ requestedExtraSessionsApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: GET_EXCEPTIONAL_UNAVAILABLE_TIME_FAILED,
      init: GET_EXCEPTIONAL_UNAVAILABLE_TIME,
      success: GET_EXCEPTIONAL_UNAVAILABLE_TIME_SUCCESS,
    },
    options,
    service: requestedExtraSessionsApiClient,
    serviceMethod: 'getExceptionalUnavailableTime',
  })
)

export const removeExceptionalUnavailableTime = (options = {}) => ({ requestedExtraSessionsApiClient }) => (
  generateBasicActions.remove({
    constants: {
      failed: DELETE_EXCEPTIONAL_UNAVAILABLE_TIME_FAILED,
      init: DELETE_EXCEPTIONAL_UNAVAILABLE_TIME,
      success: DELETE_EXCEPTIONAL_UNAVAILABLE_TIME_SUCCESS,
    },
    options,
    service: requestedExtraSessionsApiClient,
    serviceMethod: 'removeExceptionalUnavailableTime',
  })
)

export const updateExceptionalUnavailableTime = (options = {}) => ({ requestedExtraSessionsApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_EXCEPTIONAL_UNAVAILABLE_TIME_FAILED,
      init: UPDATE_EXCEPTIONAL_UNAVAILABLE_TIME,
      success: UPDATE_EXCEPTIONAL_UNAVAILABLE_TIME_SUCCESS,
    },
    options,
    service: requestedExtraSessionsApiClient,
    serviceMethod: 'updateExceptionalUnavailableTime',
  })
)

export const clearExceptionalUnavailableTime = () => ({
  type: CLEAR_EXCEPTIONAL_UNAVAILABLE_TIME,
})
