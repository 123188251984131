import _ from 'lodash'
import { flatten, nest } from 'utils/flatnest'

import { compose } from 'recompose'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { change, getFormValues, initialize, stopSubmit } from 'redux-form'

import { FRAMEWORK_STRUCTURE_APPLICABLE } from 'services/legacy/frameworks/constants'
import { AGES_MAP, FILTERS_MAP, PROGRESS_TRACKER_TYPES } from 'services/legacy/cohortTracking/constants'
import { generateRoute } from 'utils/routing'
import eventBus from 'utils/eventBus'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withEthnicitiesService } from 'services/legacy/ethnicities'
import { withFormativeReportsService } from 'services/legacy/formativeReports'
import { withFrameworksService } from 'services/legacy/frameworks'
import { withRouter } from 'services/router'

import { PROGRESS_TRACKER_GENERATOR_FORM } from './components/ProgressTrackerGeneratorForm/ProgressTrackerGeneratorForm'
import ProgressTrackerGeneratorView from './ProgressTrackerGeneratorView'

const FRAMEWORK_GROUPS = {
  read: [
    'framework.areas',
    'frameworkArea',
    'structure',
    'structure.cohort',
    'frameworkArea.groups',
    'frameworkGroup',
    'framework.default',
  ],
}

class ProgressTrackerGeneratorContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      initialValues: {},
    }
  }

  componentDidMount() {
    const {
      ethnicitiesActions,
      formativeReportsActions,
      frameworksActions,
    } = this.props

    this.injectInitialValues()

    ethnicitiesActions.list()
    frameworksActions.list({
      onSuccess: this.handleFrameworkSuccess,
      params: [{
        groups: FRAMEWORK_GROUPS,
      }],
    })
    formativeReportsActions.getTerms()
  }

  componentWillUnmount() {
    const {
      ethnicitiesActions,
      formativeReportsActions,
      frameworksActions,
    } = this.props

    formativeReportsActions.clearTerms()
    ethnicitiesActions.clear()
    frameworksActions.clear()
  }

  injectInitialValues = () => {
    const { router: { location: { query } } } = this.props
    const { params } = query || {}

    try {
      if (params) {
        const values = nest(JSON.parse(params))
        const location = _.assign({}, browserHistory.getCurrentLocation())
        location.search = ''
        location.query = {}

        browserHistory.push(location)

        if (values.type) {
          values.type = {
            value: values.type,
          }
        }

        if (values.framework) {
          values.framework = {
            value: +values.framework,
          }
        }

        if (values.term) {
          values.term = {
            value: values.term,
          }
        }

        if (values.frameworkArea) {
          values.frameworkArea = {
            value: +values.frameworkArea,
          }
        }

        if (values.filters?.ethnicity) {
          values.filters.ethnicity = {
            value: +values.filters.ethnicity,
          }
        }

        if (values.filters?.keyWorker) {
          values.filters.keyWorker = {
            value: +values.filters.keyWorker,
          }
        }

        if (values.filters?.nurseryClass) {
          values.filters.nurseryClass = {
            value: +values.filters.nurseryClass,
          }
        }

        if (values?.filters?.age) {
          values.filters.age = _.find(AGES_MAP, ({ value }) => (
            value.from === +values.filters.age.from && value.to === +values.filters.age.to
          ))
        }

        if (values?.filters?.gender) {
          values.filters.gender = _.map(values.filters.gender, (value) => ({
            value,
          }))
        }

        _.forEach(FILTERS_MAP, ({ value }) => {
          if ('string' === typeof values?.filters?.[value]) {
            values.filters[value] = 'true' === values.filters[value].toLowerCase()
          }
        })

        this.setState({ initialValues: values })
      }
    } catch (e) { }
  }

  handleFrameworkSuccess = () => {
    const { changeFieldValue, frameworksList } = this.props
    const { initialValues } = this.state

    const defaultFramework = _.find(frameworksList, 'default')?.id

    if (defaultFramework) {
      changeFieldValue('framework', { value: defaultFramework })
      this.setState({
        initialValues: {
          ...initialValues,
          framework: { value: defaultFramework },
        },
      })
    }
  }

  handleResetFilters = (eventsArray) => () => {
    const { changeFieldValue } = this.props

    _.each(eventsArray, (eventName) => eventBus.dispatch(eventName))
    changeFieldValue('filters', {})
  }

  handleChangeTrackerType = () => {
    const { changeFieldValue } = this.props

    changeFieldValue('term', undefined)
    changeFieldValue('frameworkArea', undefined)
  }

  handleChangeFramework = () => {
    const { changeFieldValue } = this.props

    changeFieldValue('frameworkArea', undefined)
  }

  handleSubmit = (values) => {
    const { navigate } = this.props
    const { filters, framework, frameworkArea, term, type } = values
    const { age, ethnicity, gender, keyWorker, nurseryClass, ...rest } = filters || {}

    const params = {
      filters: {},
      framework: framework?.value,
      type: type?.value,
    }

    if (type?.value === PROGRESS_TRACKER_TYPES.AREA_OF_LEARNING && term) {
      params.term = term?.value
    }

    if (type?.value === PROGRESS_TRACKER_TYPES.TERMLY_PROGRESS && frameworkArea) {
      params.frameworkArea = frameworkArea?.value
    }

    if (ethnicity) {
      params.filters.ethnicity = ethnicity?.value
    }

    if (keyWorker) {
      params.filters.keyWorker = keyWorker?.value
    }

    if (nurseryClass) {
      params.filters.nurseryClass = nurseryClass?.value
    }

    if (age?.value) {
      params.filters.age = age?.value
    }

    if (gender?.length) {
      params.filters.gender = _.map(gender, ({ value }) => value)
    }

    if (rest) {
      _.forEach(FILTERS_MAP, ({ value }) => {
        if (rest[value]) {
          params.filters[value] = true
        }
      })
    }

    if (!_.keys(params.filters)?.length) {
      delete params.filters
    }

    const queryParams = new URLSearchParams(flatten(params)).toString()

    return navigate(
      `${generateRoute(
        'LEARNING.COHORT_TRACKING.PROGRESS_TRACKER.PREVIEW',
      )}?${queryParams}`,
    )
  }

  render() {
    const {
      ethnicities,
      formValues,
      frameworksList,
      frameworksOptions,
      isFetching,
      terms,
    } = this.props
    const { initialValues } = this.state

    return (
      <ProgressTrackerGeneratorView
        ethnicities={ethnicities}
        formValues={formValues}
        frameworksList={frameworksList}
        frameworksOptions={frameworksOptions}
        initialValues={initialValues}
        isFetching={isFetching}
        terms={terms}
        onChangeFramework={this.handleChangeFramework}
        onChangeTrackerType={this.handleChangeTrackerType}
        onResetFilters={this.handleResetFilters}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  changeFieldValue: (field, value) => change(PROGRESS_TRACKER_GENERATOR_FORM, field, value),
  initializeValues: (values) => initialize(PROGRESS_TRACKER_GENERATOR_FORM, values),
  injectValidation: (errors) => stopSubmit(PROGRESS_TRACKER_GENERATOR_FORM, errors),
}

const mapState = (state, {
  appSelectors,
  ethnicitiesSelectors,
  formativeReportsSelectors,
  formativeReportsTermsState,
  frameworksListState,
  frameworksSelectors,
}) => ({
  ethnicities: ethnicitiesSelectors.getEthnicitiesListDataDropdownSelector(state),
  formValues: getFormValues(PROGRESS_TRACKER_GENERATOR_FORM)(state),
  frameworksList: frameworksSelectors.getFilteredFrameworksRawList(
    FRAMEWORK_STRUCTURE_APPLICABLE.COHORT_TRACKING,
  )(state),
  frameworksOptions: frameworksSelectors.getFilteredFrameworksList(
    FRAMEWORK_STRUCTURE_APPLICABLE.COHORT_TRACKING,
  )(state),
  isFetching: appSelectors.getIsFetching(frameworksListState, formativeReportsTermsState),
  terms: formativeReportsSelectors.getUsedTermsDropdownInFormativeReports(state),
})

const enhance = compose(
  withAppService,
  withEthnicitiesService,
  withFormativeReportsService,
  withFrameworksService,
  withRouter,
  withModalService,
  connect(mapState, mapDispatch),
)

export default enhance(ProgressTrackerGeneratorContainer)
