import React from 'react'

import { StyledSubContainer } from './PlanViewStyled'

const PlanViewSubGroup = ({ children }) => (
  <StyledSubContainer>
    {children}
  </StyledSubContainer>
)

export default PlanViewSubGroup
