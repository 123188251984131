import * as filesSelectors from './files/selectors'
import * as listSelectors from './list/selectors'
import * as singleSelectors from './single/selectors/single'
import * as singleSettingsSelectors from './single/selectors/settings'
import * as rotaStatisticsSelectors from './rotaStatistics/selectors'
import * as publicSelectors from './public/selectors'

export default {
  ...filesSelectors,
  ...listSelectors,
  ...singleSelectors,
  ...singleSettingsSelectors,
  ...rotaStatisticsSelectors,
  ...publicSelectors,
}
