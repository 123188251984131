import { Property } from 'csstype'
import React from 'react'

import { PaginationProps } from 'components/Pagination/Pagination'
import { TableColumn } from 'components/Table/Table'
import { OverallStatisticsPros } from 'components/OverallStatistics/OverallStatistics'

import {
  Callout,
  EmptyState,
  OverallStatistics,
  Page,
  Pagination,
  Section,
  Spinner,
  Table,
  Typography,
} from 'components'

import i18n from 'translations'

interface ReportViewDetailPageProps {
  chart?: React.ReactNode
  description?: string | React.ReactNode
  emptyState?: string | React.ReactNode
  errorMessages?: string | any[]
  filters?: React.ReactNode
  footer?: React.ReactNode
  hideExport?: boolean
  isChartLoading?: boolean
  isFinanceV3Enabled?: boolean
  isPageLoading?: boolean
  isStatisticsLoading?: boolean
  isTableLoading?: boolean
  minWidth?: number
  onExportClick?: () => void
  onFundingLoopExportClick?: () => void
  statisticsGap?: string
  tableColumns?: TableColumn[]
  tableData?: any
  tableLayout?: Property.TableLayout
  title?: string | React.ReactNode
  totalResults?: number
}

type ReportViewDetailPageFullProps = ReportViewDetailPageProps
  & PaginationProps
  & OverallStatisticsPros

const ReportViewDetailPage: React.FC<ReportViewDetailPageFullProps> = ({
  chart,
  description,
  emptyState,
  errorMessages,
  filters,
  footer,
  hideExport,
  isChartLoading,
  isFinanceV3Enabled,
  isPageLoading,
  isStatisticsLoading,
  isTableLoading,
  minWidth,
  onExportClick,
  onFundingLoopExportClick,
  onPageChange,
  page,
  pageCount,
  perPage,
  statistics,
  statisticsGap,
  tableColumns,
  tableData,
  tableLayout,
  title,
  totalResults,
}) => {
  const renderPagination = () => (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderTable = () => {
    if (isTableLoading) {
      return <Spinner />
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={tableColumns}
          data={tableData}
          minWidth={minWidth || 800}
          tableLayout={tableLayout}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const renderStatistics = () => {
    if (isStatisticsLoading) {
      return <Spinner />
    }

    if (!statistics) {
      return null
    }

    return (
      <OverallStatistics gap={statisticsGap} statistics={statistics} />
    )
  }

  const renderChart = () => {
    if (isChartLoading) {
      return <Spinner />
    }

    if (!chart) {
      return null
    }

    return chart
  }

  const renderContent = () => {
    if (isPageLoading) {
      return <Spinner />
    }

    if (errorMessages) {
      return null
    }

    if (
      !isPageLoading
      && !isTableLoading
      && !isStatisticsLoading
      && !isChartLoading
      && !tableData?.length
    ) {
      return emptyState || (
        <EmptyState
          icon="reports"
          text1={i18n.t('global:EmptyState:long')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderChart()}
        {renderStatistics()}
        {renderTable()}
        {footer}
      </React.Fragment>
    )
  }

  const actions = !hideExport && (
    <Section.Actions
      options={isFinanceV3Enabled ? [
        {
          icon: 'export',
          label: i18n.t('module:Modals:FundingLoopExportModal:generalExport'),
          onClick: onExportClick,
        },
        {
          icon: 'export',
          label: i18n.t('module:Modals:FundingLoopExportModal:Form:title'),
          onClick: onFundingLoopExportClick,
        },
      ] : [{ onClick: onExportClick, type: 'export' }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isPageLoading || isTableLoading || isStatisticsLoading || isChartLoading}
      title={title}
    >
      <Callout content={errorMessages} error />
      {description && (
        <Typography margin="0 0 20px">
          {description}
        </Typography>
      )}
      {filters}
      {renderContent()}
    </Page.Section>
  )
}

export default ReportViewDetailPage
