import _ from 'lodash'

import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Field, FieldArray, formValueSelector, reduxForm } from 'redux-form'

import { isEndTimeGreaterThanStartTime, isRequired, sessionFieldValidations } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'

import { Button, Callout, CircleIcon, Form, Grid, Section, Tooltip } from 'components'

import i18n from 'translations'

import ChildSessionsGrid from 'module/Children/Child/components/ChildSessionsGrid'

export const FORM_NAME = 'AddChildSessionsForm'

const renderChildSession = (isInvoiceAssigned, nurserySessionsOptions, onDeleteItemClick) => (
  childSession,
  index,
  fields,
) => (
  <ChildSessionsGrid.Item key={index}>
    <Field
      component={Form.ChildSession}
      disabled={isInvoiceAssigned}
      dropdownWidth="300px"
      name={childSession}
      options={nurserySessionsOptions}
      validate={isEndTimeGreaterThanStartTime}
      showHoursByDefault
      onDeleteClick={onDeleteItemClick(fields, index)}
    />
  </ChildSessionsGrid.Item>
)

const renderChildSessions = ({
  fields,
  isInvoiceAssigned,
  nurserySessionsOptions,
  onAddItemClick,
  onDeleteItemClick,
}) => (
  <ChildSessionsGrid>
    {fields.map(renderChildSession(isInvoiceAssigned, nurserySessionsOptions, onDeleteItemClick))}
    <ChildSessionsGrid.ButtonItem>
      <Button.ActionButton
        disabled={isInvoiceAssigned}
        label={i18n.t('module:Children:Child:BookingPattern:Sessions:Add:listActionButtonText')}
        onClick={onAddItemClick(fields)}
      />
    </ChildSessionsGrid.ButtonItem>
  </ChildSessionsGrid>
)

const AddChildSessionsForm = ({
  SessionCalculationAccess,
  childId,
  error,
  handleSubmit,
  isInvoiceAssigned,
  isLoading,
  isOngoing,
  nurserySessionsOptions,
  onAddItemClick,
  onDeleteItemClick,
  onSubmit,
  openingDays,
  sessionCalculationOptions,
}) => {
  const renderDay = (day) => (
    <Section key={_.kebabCase(day)} title={_.startCase(day)}>
      <FieldArray
        component={renderChildSessions}
        name={`plans.${day}`}
        props={{
          isInvoiceAssigned,
          nurserySessionsOptions,
          onAddItemClick,
          onDeleteItemClick,
        }}
      />
    </Section>
  )

  return (
    <React.Fragment>
      <Callout content={error} error />
      <Section title={i18n.t('module:Children:Child:BookingPattern:Sessions:Add:dateSectionTitle')}>
        <Form.Row label={_.upperFirst(i18n.t('global:startDate'))}>
          <Form.Row.Item>
            <Field
              component={Form.DatePicker}
              name="startDate"
              placeholder={_.upperFirst(i18n.t('global:startDate'))}
              validate={isRequired}
              clearable
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row label={_.upperFirst(i18n.t('global:endDate'))}>
          <Form.Row.Item>
            <Field
              component={Form.DatePicker}
              disabled={isOngoing}
              name="endDate"
              placeholder={_.upperFirst(i18n.t('global:endDate'))}
              clearable
            />
          </Form.Row.Item>
          <Form.Row.TextItem text={i18n.t('global:or')} />
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              label={i18n.t('module:Children:Child:BookingPattern:Sessions:Add:ongoingSession')}
              name="isOngoing"
              tooltip={i18n.t('module:Children:Child:BookingPattern:Sessions:Add:ongoingSessionTooltip')}
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row
          label={i18n.t('module:Children:Child:BookingPattern:Sessions:Add:attendancePeriod')}
          width={{ field: '100%' }}
        >
          <Grid>
            <Grid.Item width={{ desktop: '300px', mobile: 'calc(100% - 50px)' }}>
              <Field
                component={Form.InfiniteDropdowns.Periods}
                disabled={isInvoiceAssigned}
                name="attendancePeriod"
                placeholder={i18n.t('module:Children:Child:BookingPattern:Sessions:Add:attendancePeriod')}
                validate={[isRequired]}
              />
            </Grid.Item>
            <Grid.Item alignItems={{ desktop: 'center' }}>
              <Tooltip
                title={i18n.t('module:Children:Child:BookingPattern:Sessions:Add:attendancePeriodTooltip')}
              >
                <CircleIcon
                  cursor="pointer"
                  icon="info"
                  iconSize={16}
                  size={24}
                />
              </Tooltip>
            </Grid.Item>
          </Grid>
        </Form.Row>
      </Section>
      {SessionCalculationAccess && (
        <Section title="Fee calculation for sessions">
          <Form.Row>
            <Form.Row.FlexItem>
              <Field
                component={Form.RadioGroup}
                disabled={isInvoiceAssigned}
                name="sessionCalculation"
                options={sessionCalculationOptions}
                validate={isRequired}
              />
            </Form.Row.FlexItem>
          </Form.Row>
        </Section>
      )}
      <Section>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {_.map(openingDays, renderDay)}
          <Form.FooterActions
            cancelLink={generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS', { childId })}
            isSubmitting={isLoading}
            submitLabel={i18n.t('global:Save')}
          />
        </Form>
      </Section>
    </React.Fragment>
  )
}

const mapState = (state) => ({
  isOngoing: formValueSelector(FORM_NAME)(state, 'isOngoing'),
})

const enhance = compose(
  reduxForm({ form: FORM_NAME, validate: sessionFieldValidations }),
  connect(mapState),
)

export default enhance(AddChildSessionsForm)
