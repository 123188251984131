import { createSelector } from 'reselect'

import { MENU_ITEMS_STATUS } from '../constants'

const getFoodTypes = (state) => state.foodTypes

export const getFoodTypesData = createSelector([getFoodTypes], (state) => state.list.data)

export const getFoodTypesMeta = createSelector([getFoodTypes], (state) => state.list.meta)

export const getListCriteria = ({ category, search, status }) => {
  const criteria = []

  if (category) {
    criteria.push({
      field: 'category',
      value: category,
    })
  }

  if (MENU_ITEMS_STATUS.ARCHIVED === status || MENU_ITEMS_STATUS.ACTIVE === status) {
    criteria.push({
      field: 'archived',
      value: Number(status === MENU_ITEMS_STATUS.ARCHIVED),
    })
  }

  if (search) {
    criteria.push({
      field: 'name',
      value: search,
    })
  }

  return criteria
}
