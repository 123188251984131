import i18n from 'translations'

export const EATING_TYPES = {
  BREAKFAST: 'BREAKFAST',
  DINNER: 'DINNER',
  LUNCH: 'LUNCH',
  SNACK_AM: 'SNACK_AM',
  SNACK_PM: 'SNACK_PM',
}

export const FOOD_TYPE = {
  MEATS: 'MEATS',
  SNACKS: 'SNACKS',
  VEGGIES: 'VEGGIES',
}

export const FOOD_CATEGORY = {
  MEAL: {
    sessions: [
      {
        id: 0,
        label: i18n.t('services:FoodMenu:foodCategory:Breakfast'),
        startTime: 0,
        type: EATING_TYPES.BREAKFAST,
        usualTime: 28800000,
        value: EATING_TYPES.BREAKFAST,
      },
      {
        id: 1,
        label: i18n.t('services:FoodMenu:foodCategory:Lunch'),
        startTime: 43200000,
        type: EATING_TYPES.LUNCH,
        usualTime: 43200000,
        value: EATING_TYPES.LUNCH,
      },
      {
        id: 2,
        label: i18n.t('services:FoodMenu:foodCategory:Tea'),
        startTime: 64800000,
        type: EATING_TYPES.DINNER,
        usualTime: 64800000,
        value: EATING_TYPES.DINNER,
      },
    ],
    value: 'MEAL',
  },
  SNACK: {
    sessions: [
      {
        id: 0,
        label: i18n.t('services:FoodMenu:foodCategory:AM'),
        startTime: 0,
        type: EATING_TYPES.SNACK_AM,
        usualTime: 36000000,
        value: EATING_TYPES.SNACK_AM,
      },
      {
        id: 1,
        label: i18n.t('services:FoodMenu:foodCategory:PM'),
        startTime: 43200000,
        type: EATING_TYPES.SNACK_PM,
        usualTime: 52200000,
        value: EATING_TYPES.SNACK_PM,
      },
    ],
    value: 'SNACK',
  },
}

export const FOOD_AMOUNT = {
  ALL: 'ALL',
  MOST: 'MOST',
  NONE: 'NONE',
  OVER: 'OVER',
  SOME: 'SOME',
}

export const FOOD_AMOUNT_DROPDOWN = [
  {
    label: i18n.t('module:DailyDiary:Foods:foodAmount:none'),
    value: FOOD_AMOUNT.NONE,
  },
  {
    label: i18n.t('module:DailyDiary:Foods:foodAmount:some'),
    value: FOOD_AMOUNT.SOME,
  },
  {
    label: i18n.t('module:DailyDiary:Foods:foodAmount:most'),
    value: FOOD_AMOUNT.MOST,
  },
  {
    label: i18n.t('module:DailyDiary:Foods:foodAmount:all'),
    value: FOOD_AMOUNT.ALL,
  },
  {
    label: i18n.t('module:DailyDiary:Foods:foodAmount:over'),
    value: FOOD_AMOUNT.OVER,
  },
]
