import _ from 'lodash'

import React from 'react'

import {
  Callout,
  DatePicker,
  EmptyState,
  Form,
  InfiniteDropdowns,
  Page,
  Pagination,
  SearchBar,
  Section,
  Space,
  Spinner,
  Toolbar,
} from 'components'

import i18n from 'translations'

import FinancePaymentsTable from './components/FinancePaymentsTable'

const FinancePaymentsView = ({
  addPaymentRoute,
  dateRange,
  errorMessages,
  isChildContext,
  isEmpty,
  isLoading,
  onDateRangeChange,
  onExportClick,
  onImportClick,
  onPageChange,
  onPaymentTypeChange,
  onRoomChange,
  onSearchPhraseChange,
  onSortChange,
  page,
  pageCount,
  paymentType,
  payments,
  perPage,
  room,
  sortField,
  sortOrder,
  totalResults,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('module:Finance:Payments:payments')}
      titleSingular={i18n.t('module:Finance:Payments:payment')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (isEmpty) {
      return (
        <EmptyState
          icon="invoice"
          text1={i18n.t('module:Finance:Payments:emptyState')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <FinancePaymentsTable
          isChildContext={isChildContext}
          payments={payments}
          sortField={sortField}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const renderHeader = () => (
    <React.Fragment>
      {!isChildContext && (
        <React.Fragment>
          <SearchBar
            placeholder={i18n.t('global:searchChild')}
            variant="standard"
            noBackground
            onChange={onSearchPhraseChange}
          />
          <Space margin="0 0 10px" />
        </React.Fragment>
      )}
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <Form.Row label={i18n.t('global:dateRange')} verticalLabel>
              <DatePicker
                value={dateRange}
                clearable
                range
                onChange={onDateRangeChange}
              />
            </Form.Row>
          </Toolbar.Item>
          {!isChildContext
            && (
              <Toolbar.Item>
                <Form.Row label={i18n.t('global:Room')} verticalLabel>
                  <InfiniteDropdowns.Rooms
                    value={room}
                    onChange={onRoomChange}
                  />
                </Form.Row>
              </Toolbar.Item>
            )}
          <Toolbar.Item>
            <Form.Row label={_.upperFirst(i18n.t('module:Management:ManagementPaymentTypes:tableTitle'))} verticalLabel>
              <InfiniteDropdowns.PaymentTypes
                value={paymentType}
                onChange={onPaymentTypeChange}
              />
            </Form.Row>
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
    </React.Fragment>
  )

  const actions = (
    <Section.Actions
      options={[{
        auth: !isChildContext,
        onClick: onExportClick,
        type: 'export',
      }]}
      primary={[{
        label: _.upperFirst(i18n.t('module:Finance:Payments:payment')),
        to: addPaymentRoute,
      }]}
      secondary={{
        label: i18n.t('global:import'),
        onClick: onImportClick,
      }}
    />
  )

  const renderChildPage = () => (
    <React.Fragment>
      <Callout content={errorMessages} error />
      <Section
        actions={actions}
        title={i18n.t('module:Children:Child:Finance:PaymentHistory:title')}
      >
        {renderHeader()}
        {renderContent()}
      </Section>
    </React.Fragment>
  )

  const renderFinancePage = () => (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title={i18n.t('module:Finance:Payments:title')}
    >
      <Callout content={errorMessages} error />
      {renderHeader()}
      {renderContent()}
    </Page.Section>
  )

  return isChildContext ? renderChildPage() : renderFinancePage()
}

export default FinancePaymentsView
