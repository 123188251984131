import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledTextContainer = styled.div`
  align-items: center;
  margin-bottom: 15px;

  & > p {
    margin: 0;

    > span {
      color: ${getBrandingColor('primary-color')};
    }
  }
  
  & > p ~ p {
    margin-top: 20px;
  }
`

export const StyledRowContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-column-gap: 25px;
  align-items: start;
  line-height: 24px;

  :first-child {
    margin-bottom: 50px;  
  }
  
  @media(max-width: 550px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      
      :first-child {
        margin-bottom: 20px;  
      }
  }
`

export const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
  display: block;
`
