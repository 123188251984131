import React, { useEffect } from 'react'
import { Field, FieldArray, FormSection, getFormValues, reduxForm } from 'redux-form'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { TYPE_CONTACT_FORM, TYPE_CONTACT_FORM_OPTIONS } from 'services/legacy/child/constants'

import { isRequired } from 'utils/fieldValidation'

import { Button, Form, InfiniteDropdowns, Section } from 'components'

import i18n from 'translations'

import { CHILD_ADD_FORM } from '../../components/ChildForms/constants'
import ChildContactsMainForm from '../../ChildContacts/ChildContactsAdd/components/ChildContactsMainForm'

const renderRow = ({
  fields,
  formValues,
  item,
  onChangeExistingContact,
  onDeleteItemClick,
  onTypeChange,
  rowIndex,
  selectedContacts,
  userTitles,
}) => (
  <Section
    actions={0 !== rowIndex && (
      <Section.Actions
        options={[{
          onClick: onDeleteItemClick(fields, rowIndex),
          type: 'remove',
        }]}
      />
    )}
    title={i18n.t('module:Children:Child:Add:Contacts:Section:title', { number: rowIndex + 1 })}
  >
    <FormSection name={item}>
      <Form.Row>
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.RadioGroup}
            name="type"
            options={TYPE_CONTACT_FORM_OPTIONS}
            validate={isRequired}
            onChange={onTypeChange(rowIndex)}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      {TYPE_CONTACT_FORM.EXISTING === formValues?.contacts?.[rowIndex]?.type && (
        <Form.Row label={i18n.t('module:Children:Child:Contacts:Form:chooseContact')} required>
          <Form.Row.FlexItem>
            <InfiniteDropdowns.Carers
              placeholder={i18n.t('module:Children:Child:Contacts:Form:chooseContact')}
              value={selectedContacts[rowIndex]}
              withChildRelation
              onChange={onChangeExistingContact(rowIndex)}
            />
          </Form.Row.FlexItem>
        </Form.Row>
      )}
      <ChildContactsMainForm
        child={formValues}
        formValues={formValues?.contacts?.[rowIndex]}
        isEmailDisabled={formValues?.contacts?.[rowIndex]?.emailDisabled}
        userTitles={userTitles}
      />
    </FormSection>
  </Section>
)

const renderContacts = ({
  fields,
  formValues,
  onAddItemClick,
  onChangeExistingContact,
  onDeleteItemClick,
  onTypeChange,
  selectedContacts,
  userTitles,
}) => (
  <React.Fragment>
    {fields.map((item, rowIndex) => renderRow({
      fields,
      formValues,
      item,
      onChangeExistingContact,
      onDeleteItemClick,
      onTypeChange,
      rowIndex,
      selectedContacts,
      userTitles,
    }))}
    <Button.ActionButton
      label={i18n.t('module:Children:Child:Contacts:Form:addContact')}
      onClick={onAddItemClick(fields)}
    />
  </React.Fragment>
)

const ContactsForm = ({
  cancelLink,
  formValues,
  handleSubmit,
  isSubmitting,
  onAddContactClick,
  onChangeExistingContact,
  onDeleteContactClick,
  onPreviousStageClick,
  onTypeChange,
  selectedContacts,
  userTitles,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const renderFooter = () => (
    <Form.FooterActions
      cancelLink={cancelLink}
      isSubmitting={isSubmitting}
      secondary={{
        label: i18n.t('global:Previous'),
        onClick: onPreviousStageClick,
      }}
      submitLabel={i18n.t('global:Next')}
    />
  )

  return (
    <Form onSubmit={handleSubmit}>
      <FieldArray
        component={renderContacts}
        formValues={formValues}
        isSubmitting={isSubmitting}
        name="contacts"
        selectedContacts={selectedContacts}
        userTitles={userTitles}
        onAddItemClick={onAddContactClick}
        onChangeExistingContact={onChangeExistingContact}
        onDeleteItemClick={onDeleteContactClick}
        onTypeChange={onTypeChange}
      />
      {renderFooter()}
    </Form>
  )
}

const mapState = (state) => ({
  formValues: getFormValues(CHILD_ADD_FORM)(state),
})

const enhance = compose(
  reduxForm({ destroyOnUnmount: false, form: CHILD_ADD_FORM }),
  connect(mapState),
)

export default enhance(ContactsForm)

