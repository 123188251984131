import React from 'react'
import { Link } from 'react-router'

import { generateRoute } from 'utils/routing'

import { Callout, InfiniteDropdowns, Page, Typography } from 'components'

import i18n from 'translations'

import { FORMATIVE_REPORTS_PAGE_TYPE } from 'module/Learning/FormativeReports/constants'

import FormativeReportsCreateForm from './components/FormativeReportsCreateForm'

const FormativeReportsCreateView = ({
  child,
  childId,
  errorMessages,
  formValues,
  initialValues,
  isSubmitting,
  onChildChange,
  onSubmit,
  pageType,
  terms,
}) => {
  const isChildContext = pageType === FORMATIVE_REPORTS_PAGE_TYPE.CHILD

  const renderChildrenDropdown = () => (
    <InfiniteDropdowns.Children
      value={child}
      onChange={onChildChange}
    />
  )

  const renderMessage = () => (
    <Typography margin="20px 0">
      {i18n.t('module:Learning:FormativeReports:FormativeReportsCreate:subtitle:part1')}
      {' '}
      <Link
        to={generateRoute('CHILDREN.CHILD.LEARNING_JOURNEY.FRAMEWORK', { childId })}
      >
        {i18n.t('module:Learning:FormativeReports:FormativeReportsCreate:subtitle:part2')}
      </Link>
      {' '}
      {i18n.t('module:Learning:FormativeReports:FormativeReportsCreate:subtitle:part3')}
    </Typography>
  )

  const renderForm = () => (
    <FormativeReportsCreateForm
      cancelButtonLink={
        isChildContext
          ? generateRoute('CHILDREN.CHILD.LEARNING_JOURNEY.FRAMEWORK', { childId })
          : generateRoute('LEARNING.FORMATIVE_REPORTS')
      }
      formValues={formValues}
      initialValues={initialValues}
      isDisabled={!childId}
      isSubmitting={isSubmitting}
      showForm={isChildContext || childId}
      terms={terms}
      onSubmit={onSubmit}
    />
  )

  return (
    <Page.Section
      title={i18n.t('module:Learning:FormativeReports:FormativeReportsCreate:title')}
    >
      <Callout content={errorMessages} error />
      {!isChildContext && renderChildrenDropdown()}
      {childId && renderMessage()}
      {renderForm()}
    </Page.Section>
  )
}

export default FormativeReportsCreateView
