import React, { PropsWithChildren } from 'react'

import { StyledHead } from './DataTableStyled'

interface DataTableHeadProps {
  borderRight?: boolean
  width?: number
}

const DataTableHead: React.FC<PropsWithChildren<DataTableHeadProps>> = ({ borderRight, children, width }) => (
  <StyledHead $width={width} borderRight={borderRight}>
    {children}
  </StyledHead>
)

export default DataTableHead
