import React, { PropsWithChildren } from 'react'

import { StyledContainer } from './FieldErrorStyled'

interface FieldErrorProps {
  error: string | React.ReactNode
}

const FieldError: React.FC<PropsWithChildren<FieldErrorProps>> = ({ error }) => {
  if (!error) {
    return null
  }

  return (
    <StyledContainer data-field-error>
      {error}
    </StyledContainer>
  )
}

export default FieldError
