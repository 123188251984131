import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import { MilkReportListState, MilkReportRootState } from './models'
import actions from './actions'
import * as milkReportSelectors from './selectors'

export interface withMilkReportServiceProps {
  milkReportActions: typeof actions
  milkReportListState: MilkReportListState
  milkReportSelectors: typeof milkReportSelectors
}

const withMilkReportService = <P extends withMilkReportServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ milkReport }: MilkReportRootState) => ({ milkReport })

  const mapDispatch = injectActions('milkReportActions', actions)

  const Component = ({ milkReport, milkReportActions, ...others }) => (
    <WrappedComponent
      milkReportActions={milkReportActions}
      milkReportListState={milkReport.list}
      milkReportSelectors={milkReportSelectors}
      milkReportSingleState={milkReport.single}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withMilkReportService
