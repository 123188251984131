import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { initialize } from 'redux-form'

import {
  ACTIVITY_TYPE,
  ACTIVITY_TYPES_MAP,
  ENTRY_TAB_NAMES,
  LIST_TAB_NAMES,
  LIST_TAB_TYPE_MAPPING,
  LIST_TAB_WITH_ENTRY_MAPPING,
} from 'services/legacy/enquiries/activities/constants'
import { VIEWING_STATUSES, VIEWING_STATUS_OPTIONS } from 'services/legacy/enquiries/viewings/constants'
import { ENQUIRY_STATUS_CODES } from 'services/legacy/enquiries/constants'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withEnquiriesService, withEnquiryActivitiesService } from 'services/legacy/enquiries'
import { withPaginationUtils } from 'services/utils/pagination'
import { withSnackbarService } from 'services/utils/snackbar'
import { withRouter } from 'services/router'

import i18n from 'translations'

import { ACTIVITY_MAPPING } from './constants'

import ActivityDetailView from './ActivityDetailView'
import { getApiGroups } from './ActivityDetailHelpers'

const DEFAULT_ACTIVITY_SORT = [
  {
    sortField: 'activityDate',
    sortOrder: 'DESC',
  },
  {
    sortField: 'id',
    sortOrder: 'DESC',
  },
]
const ENQUIRY_GROUPS = {
  read: [
    'enquiry.status',
    'enquiryStatusType',
    'enquiry.data',
    'enquiry.nurseryClass',
    'nurseryClass',
    'enquiry.sessionPlans',
    'nurserySession',
    'enquiry.child',
  ],
}

const INITIAL_STATE = {
  entryTab: ENTRY_TAB_NAMES.NOTE,
  listTab: LIST_TAB_NAMES.ALL,
  oldStatus: null,
  status: null,
}

class ActivityDetailContainer extends Component {
  constructor(props) {
    super(props)

    const { paginationUtils: { setPageLocationQuery }, params } = props
    const { enquiryId } = params

    this.state = {
      enquiryId,
      ...INITIAL_STATE,
    }

    setPageLocationQuery(false)
  }

  componentDidMount() {
    this.fetch()
    this.initializeFormValues()
  }

  componentWillUnmount() {
    const { enquiriesActions, enquiryActivitiesActions } = this.props

    enquiryActivitiesActions.clear()
    enquiryActivitiesActions.clearSingle()
    enquiriesActions.clearSingle()
  }

  componentDidUpdate(prevProps) {
    const { params } = this.props
    const { params: prevParams } = prevProps
    const { enquiryId } = params
    const { enquiryId: prevEnquiryId } = prevParams

    if (prevEnquiryId && enquiryId && prevEnquiryId !== enquiryId) {
      this.setState({
        enquiryId,
        ...INITIAL_STATE,
      }, () => {
        this.fetch()
        this.initializeFormValues()
      })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { enquiry } = nextProps
    const { status } = prevState

    if (enquiry?.status && !status) {
      const { status: enquiryStatus } = enquiry
      const { code, id: value, name: label } = enquiryStatus

      return { status: { code, label, value } }
    }

    return null
  }

  initializeFormValues = () => {
    const { initializeValues } = this.props

    const activityInitialValues = {
      activityDate: moment(),
      activityTime: moment(),
    }

    const viewingInitialValues = {
      showAroundDate: moment(),
      showAroundTime: moment(),
      status: _.find(VIEWING_STATUS_OPTIONS, { value: VIEWING_STATUSES.BOOKED }),
    }

    initializeValues(ACTIVITY_MAPPING[ACTIVITY_TYPE.NOTE.value].formName, activityInitialValues)
    initializeValues(ACTIVITY_MAPPING[ACTIVITY_TYPE.EMAIL.value].formName, activityInitialValues)
    initializeValues(ACTIVITY_MAPPING[ACTIVITY_TYPE.CALL.value].formName, activityInitialValues)
    initializeValues(ACTIVITY_MAPPING[ACTIVITY_TYPE.VIEWING.value].formName, viewingInitialValues)
  }

  fetch = () => {
    const { enquiryActivitiesActions, enquiryActivitiesSelectors, paginationUtils } = this.props
    const { enquiryId, listTab } = this.state
    const { page } = paginationUtils

    const criteria = enquiryActivitiesSelectors.getCriteria({
      enquiryId,
      type: LIST_TAB_TYPE_MAPPING[listTab],
    })

    enquiryActivitiesActions.list({
      mergeResult: 1 !== page,
      params: [{
        criteria,
        groups: getApiGroups(LIST_TAB_TYPE_MAPPING[listTab]),
        order: DEFAULT_ACTIVITY_SORT,
        page,
      }],
    })
  }

  fetchList = (forceRefetch) => {
    const { entryTab, listTab } = this.state

    if (
      listTab === LIST_TAB_NAMES.ALL
      || LIST_TAB_WITH_ENTRY_MAPPING[listTab] === entryTab
      || forceRefetch
    ) {
      this.handlePageChange(1)
    }
  }

  deleteEnquiry = () => {
    const { enquiriesActions, navigate } = this.props
    const { enquiryId } = this.state

    enquiriesActions.update({
      body: { archived: true },
      onSuccess: () => navigate(generateRoute('ENQUIRIES.LIST')),
      params: [enquiryId, {}],
    })
  }

  handleDeleteClick = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('global:Delete'),
      icon: 'trash',
      onConfirm: this.deleteEnquiry,
      text: i18n.t('module:Enquiries:List:Detail:deleteMessage'),
    })
  }

  handleChangeEntryTab = (entryTab) => {
    this.setState({ entryTab })
  }

  handleChangeListTab = (listTab) => {
    this.setState({ listTab }, () => this.handlePageChange(1))
  }

  handleActivitySubmitSuccess = ({ isEditMode, type }) => {
    const { snackbarActions } = this.props

    snackbarActions.show({
      message: i18n.t('module:Enquiries:List:Detail:Activity:Entry:SnackbarMessage:message', {
        action: isEditMode
          ? i18n.t('module:Enquiries:List:Detail:Activity:Entry:SnackbarMessage:Actions:edited')
          : i18n.t('module:Enquiries:List:Detail:Activity:Entry:SnackbarMessage:Actions:created'),
        type: ACTIVITY_TYPES_MAP[type],
      }),
    })

    this.fetchList(isEditMode)
    this.initializeFormValues()
  }

  handleRemoveActivitySuccess = (type) => {
    const { snackbarActions } = this.props

    snackbarActions.show({
      message: i18n.t('module:Enquiries:List:Detail:Activity:Entry:SnackbarMessage:message', {
        action: i18n.t('module:Enquiries:List:Detail:Activity:Entry:SnackbarMessage:Actions:deleted'),
        type: ACTIVITY_TYPES_MAP[type],
      }),
    })

    this.fetchList(true)
  }

  deleteActivity = ({ id, type }) => {
    const { enquiryActivitiesActions } = this.props

    enquiryActivitiesActions.remove({
      body: { archived: true },
      onSuccess: () => this.handleRemoveActivitySuccess(type),
      params: [id, {}],
    })
  }

  handleDeleteActivityClick = ({ id, type }) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('global:Delete'),
      icon: 'trash',
      onConfirm: () => this.deleteActivity({ id, type }),
      text: i18n.t('module:Enquiries:List:Detail:Activity:List:confirmRemoveActivity'),
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleStatusChangeCancelClick = () => {
    const { oldStatus } = this.state

    this.setState({ oldStatus: null, status: oldStatus })
  }

  handleStatusChange = (status) => {
    const { enquiriesActions, enquiry, modalActions, modalConsts } = this.props
    const { enquiryId, status: oldStatus } = this.state

    const { child } = enquiry || {}
    const { id: childId } = child || {}
    const { code, value: statusId } = status

    this.setState({ oldStatus, status })

    if ([ENQUIRY_STATUS_CODES.LOST_ENQUIRY, ENQUIRY_STATUS_CODES.NO_SPACE_AVAILABLE].includes(code)) {
      modalActions.show(modalConsts.TYPES.ENQUIRY_STATUS_CHANGE, {
        onCancelClick: this.handleStatusChangeCancelClick,
        onStatusUpdatedSuccess: this.handleUpdateData,
        status,
      })

      return
    }

    if (ENQUIRY_STATUS_CODES.ENROLLED === code && !childId) {
      modalActions.show(modalConsts.TYPES.ENQUIRY_ENROL_CHILD, {
        onCancelClick: this.handleStatusChangeCancelClick,
        onEnrolChildClick: this.handleEnrolChildClick,
        onMarkAsEnrolledSuccess: this.handleUpdateData,
      })

      return
    }

    enquiriesActions.update({
      body: { status: { id: statusId } },
      onFailed: this.handleStatusChangeCancelClick,
      onSuccess: this.handleUpdateData,
      params: [enquiryId, {}],
    })
  }

  handleUpdateData = () => {
    const { enquiriesActions, params } = this.props
    const { enquiryId } = params

    enquiriesActions.get({
      params: [enquiryId, {
        groups: ENQUIRY_GROUPS,
      }],
    })
    this.fetchList(true)
  }

  handleEnrolChildClick = () => {
    const { enquiryId } = this.state
    const { navigate } = this.props

    navigate(`${generateRoute('CHILDREN.ADD')}?enquiryId=${enquiryId}`)
  }

  handleEnrolClick = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.ENQUIRY_ENROL_CHILD, {
      onEnrolChildClick: this.handleEnrolChildClick,
      onMarkAsEnrolledSuccess: this.handleMarkAsEnrolledSuccess,
    })
  }

  handleMarkAsEnrolledSuccess = ({ code, id, name }) => {
    this.setState({ status: { code, label: name, value: id } })
    this.handleUpdateData()
  }

  render() {
    const { enquiry, enquiryActivitiesList, isFetching, paginationUtils, totalResults } = this.props
    const { enquiryId, entryTab, listTab, status } = this.state

    const { getPageCount, page } = paginationUtils
    const pageCount = getPageCount(totalResults)

    return (
      <ActivityDetailView
        enquiry={enquiry}
        enquiryActivitiesList={enquiryActivitiesList}
        enquiryId={enquiryId}
        entryTab={entryTab}
        isFetching={isFetching}
        listTab={listTab}
        page={page}
        pageCount={pageCount}
        status={status}
        onActivitySubmitSuccess={this.handleActivitySubmitSuccess}
        onChangeEntryTab={this.handleChangeEntryTab}
        onChangeListTab={this.handleChangeListTab}
        onDeleteActivityClick={this.handleDeleteActivityClick}
        onDeleteClick={this.handleDeleteClick}
        onEnrolClick={this.handleEnrolClick}
        onPageChange={this.handlePageChange}
        onStatusChange={this.handleStatusChange}
      />
    )
  }
}

const mapDispatch = {
  initializeValues: (formName, values) => initialize(formName, values),
}

const mapState = (state, {
  appSelectors,
  enquiriesSelectors,
  enquiryActivitiesListState,
  enquiryActivitiesSelectors,
}) => ({
  enquiry: enquiriesSelectors.getEnquiriesSingleDataSelector(state),
  enquiryActivitiesList: enquiryActivitiesSelectors.getEnquiryActivitiesFormattedListDataSelector(state),
  errorMessages: appSelectors.getErrorMessages(enquiryActivitiesListState),
  isFetching: appSelectors.getIsFetching(enquiryActivitiesListState),
  totalResults: appSelectors.getTotalResults(enquiryActivitiesListState),
})

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withPaginationUtils,
  withEnquiryActivitiesService,
  withEnquiriesService,
  withSnackbarService,
  connect(mapState, mapDispatch),
)

export default enhance(ActivityDetailContainer)
