import React from 'react'

import colors, { NEUTRAL_COLOURS } from 'constants/colors'

import { DateString, Icon } from 'components'

import { StyledItemContainer, StyledItemContentContainer } from './ChildFundingWeekDaysButtonsStyled'

const ChildFundingWeekDaysButton = ({
  endDate,
  id,
  isActive,
  isFullyAllocated,
  isPartialWeek,
  isSelected,
  onClick,
  startDate,
}) => {
  const getIconColor = () => {
    if (isPartialWeek) {
      return colors.gold
    }

    if (isFullyAllocated) {
      return NEUTRAL_COLOURS.GREEN
    }

    return colors.gray
  }

  return (
    <StyledItemContainer id={id} isActive={isActive} isSelected={isSelected} onClick={isActive && onClick}>
      <Icon color={getIconColor()} height={20} icon="confirmed" />
      <StyledItemContentContainer>
        <DateString date={startDate} />
        <br />
        <DateString date={endDate} />
      </StyledItemContentContainer>
    </StyledItemContainer>
  )
}

export default ChildFundingWeekDaysButton
