import browser from 'browser-detect'

import React, { PropsWithChildren } from 'react'

import { BROWSERS } from 'constants/browsers'

import { StyledContainer, StyledVideo } from './VideoPlayerStyled'

interface VideoPlayerProps {
  id?: string
  mimeType?: string
  poster?: string
  src?: string
}

const VideoPlayer: React.FC<PropsWithChildren<VideoPlayerProps>> = ({ id, mimeType, poster, src }) => {
  const { name } = browser()

  return (
    <StyledContainer>
      <StyledVideo
        controlsList="nodownload noplaybackrate"
        muted={(name === BROWSERS.SAFARI)}
        poster={poster}
        autoPlay
        controls
        disablePictureInPicture
      >
        <source key={id} src={src} type={mimeType} />
      </StyledVideo>
    </StyledContainer>
  )
}

export default VideoPlayer
