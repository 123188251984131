import React from 'react'

import { StyledWidgetPart } from './WidgetPartStyled'

const WidgetPart = ({ children }) => (
  <StyledWidgetPart>
    {children}
  </StyledWidgetPart>
)

export default WidgetPart
