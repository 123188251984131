import * as currenciesSelectors from './currencies/selectors'
import * as statisticsSelectors from './statistics/selectors'
import * as listSelectors from './list/selectors'
import * as singleSelectors from './single/selectors'
import * as stripeSelectors from './stripe/selectors'
import * as failedRecurringPaymentSelector from './failedRecurringPayment/selectors'
import * as activeRecurringPaymentSelector from './activeRecurringPayment/selectors'
import * as payoutTransactions from './payoutTransactions/selectors'

export default {
  ...activeRecurringPaymentSelector,
  ...currenciesSelectors,
  ...statisticsSelectors,
  ...listSelectors,
  ...singleSelectors,
  ...stripeSelectors,
  ...payoutTransactions,
  ...failedRecurringPaymentSelector,
}
