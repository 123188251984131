import {
  CLEAR_IMPORT_PAYMENTS,
  DOWNLOAD_PAYMENTS_TEMPLATE,
  DOWNLOAD_PAYMENTS_TEMPLATE_FAILED,
  DOWNLOAD_PAYMENTS_TEMPLATE_SUCCESS,
  IMPORT_PAYMENTS,
  IMPORT_PAYMENTS_FAILED,
  IMPORT_PAYMENTS_SUCCESS,
} from './constants'

export const importPayments = (options = {}) => ({ invoicePaymentsApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {}, payload = {} } = options

  dispatch({
    type: IMPORT_PAYMENTS,
  })

  invoicePaymentsApiClient.import(payload, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: IMPORT_PAYMENTS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: IMPORT_PAYMENTS_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const downloadTemplate = (options = {}) => ({ invoicePaymentsApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {} } = options

  dispatch({
    type: DOWNLOAD_PAYMENTS_TEMPLATE,
  })

  invoicePaymentsApiClient.downloadTemplate(params).then(
    (response) => {
      dispatch({
        payload: response,
        type: DOWNLOAD_PAYMENTS_TEMPLATE_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: DOWNLOAD_PAYMENTS_TEMPLATE_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const clearImportPayments = () => ({
  type: CLEAR_IMPORT_PAYMENTS,
})
