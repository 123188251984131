import * as badDebtActions from './badDebt/actions'
import * as badDebtStatisticsActions from './badDebtStatistics/actions'
import * as batchActions from './batch/actions'
import * as listActions from './list/actions'
import * as singleActions from './single/actions'
import * as statisticsActions from './statistics/actions'
import * as previewActions from './preview/actions'

export default {
  ...badDebtStatisticsActions,
  ...badDebtActions,
  ...batchActions,
  ...listActions,
  ...singleActions,
  ...statisticsActions,
  ...previewActions,
}
