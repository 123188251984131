import ServiceBase from 'services/ServiceBase'

import { getQueryString } from 'utils/api'

class FoodMenuApiClient extends ServiceBase {
  list = (params) => this.request({
    path: `/food-menus?${getQueryString(params)}`,
  })

  create = (body) => this.request({
    body,
    method: 'POST',
    path: '/food-menus',
  })

  update = (id, body) => this.request({
    body,
    method: 'PATCH',
    path: `/food-menus/${id}`,
  })
}

export default FoodMenuApiClient
