import _ from 'lodash'

import React from 'react'

import { OTHER_OPTION } from 'services/users/constants'
import {
  HA_SIGNATURE_LABEL_MAPPINGS,
  HA_SIGNATURE_TYPES,
  SIGNATURE_LABEL_MAPPINGS,
  SIGNATURE_TYPES,
} from 'services/legacy/injuries/constants'

import { Button, FieldError, Form, InfiniteDropdowns, Space, TextField } from 'components'

import i18n from 'translations'

import SignatureList from '../SignatureList'
import SignatureDigitalFormContent from './SignatureDigitalFormContent'
import { StyledActionButtonContainer, StyledParentSignatureContainer } from './SignatureDigitalFormStyled'
import { StyledContentErrorContainer } from '../SignatureOnPaperForm/SignatureOnPaperFormStyled'

const SignatureDigitalFormParentSignature = ({
  disableSignature,
  isHomeAccidents,
  onAddCarerClick,
  onAddSignatureClick,
  onChangeCarer,
  onChangeOtherCarer,
  parentSignatures,
}) => {
  if (!parentSignatures?.length) {
    return null
  }

  const groupedSignatures = _.groupBy(parentSignatures, 'owner.child.id')
  const title = isHomeAccidents
    ? HA_SIGNATURE_LABEL_MAPPINGS[HA_SIGNATURE_TYPES.PARENT]
    : SIGNATURE_LABEL_MAPPINGS[SIGNATURE_TYPES.PARENT]

  const carerIds = []
  _.filter(parentSignatures, ({ carer }) => {
    if (carer?.id || carer?.value) {
      carerIds.push(carer?.id || carer?.value)
    }
  })

  return (
    <React.Fragment>
      {!isHomeAccidents && <SignatureList.RowDivider />}
      <StyledParentSignatureContainer isDisplay={isHomeAccidents}>
        <SignatureList.Group title={title}>
          {_.map(groupedSignatures, (childSignatureItems) => {
            const { owner } = childSignatureItems[0]
            const { child } = owner

            return (
              <React.Fragment>
                {_.map(childSignatureItems, (signatureItem) => {
                  const {
                    additionalData,
                    carer,
                    disabled,
                    error,
                    id,
                    signature,
                    signedAt,
                    uid,
                  } = signatureItem
                  const { label } = additionalData || {}
                  const { url } = signature || {}
                  const isOtherOptionExistsWithValue = _.some(parentSignatures,
                    (item) => uid === item.uid && item.carer?.value === OTHER_OPTION.value && item.otherCarer,
                  )

                  return (
                    <SignatureList.Row
                      content={(
                        <SignatureDigitalFormContent
                          disableAddButton={carer?.value === OTHER_OPTION.value
                            ? !isOtherOptionExistsWithValue
                            : (!carer || error)}
                          disabledItem={disabled}
                          signatureId={id}
                          signatureUid={uid}
                          signedAt={signedAt}
                          url={url}
                          onAddSignatureClick={onAddSignatureClick}
                        />
                    )}
                      key={id || uid}
                      label={label}
                      value={(
                        <React.Fragment>
                          <InfiniteDropdowns.Carers
                            childId={child.id}
                            disabled={!!url}
                            emptySearchNoOptionsMessage={i18n.t(
                              'module:Injury:Preview:Signatures:Digital:contactEmptySearchNoOptionsMessage',
                            )}
                            // extraOptions={[OTHER_OPTION]}
                            maxMenuHeight={150}
                            placeholder={i18n.t('module:Injury:Preview:Signatures:Digital:contactPlaceholder')}
                            skipIds={carerIds}
                            value={carer}
                            parentalResponsibilityOnly
                            onChange={(option) => onChangeCarer(signatureItem, option)}
                          />
                          <StyledContentErrorContainer>
                            <FieldError error={error?.extra?.injury} />
                          </StyledContentErrorContainer>
                          {carer?.value === OTHER_OPTION.value && (
                            <Form.Row margin="5px 0 5px" width={{ field: '100%' }}>
                              <TextField
                                disabled={!!url}
                                placeholder={` ${i18n.t('global:Other')} ${label}`}
                                value={signatureItem?.otherCarer}
                                onChange={(event) => onChangeOtherCarer(signatureItem, event.target.value)}
                              />
                            </Form.Row>
                          )}
                        </React.Fragment>
                    )}
                    />
                  )
                })}
                <StyledActionButtonContainer>
                  <Button.ActionButton
                    disabled={disableSignature}
                    label={i18n.t('module:Injury:Preview:Signatures:Digital:parentAddContactLabel', {
                      name: child?.firstName,
                    })}
                    onClick={() => onAddCarerClick(child)}
                  />
                </StyledActionButtonContainer>
              </React.Fragment>
            )
          })}
        </SignatureList.Group>
      </StyledParentSignatureContainer>
      {!isHomeAccidents && <Space space="70px" />}
    </React.Fragment>
  )
}

export default SignatureDigitalFormParentSignature
