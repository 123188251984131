import styled from 'styled-components'

import colors from 'constants/colors'

export const StyledTooltipContainer = styled.div`
  display: flex;

  & > div {
    margin-left: 5px;
    min-width: 24px;
  }
`

export const StyledCountContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
`

export const StyledActiveCount = styled.span`
  font-size: 24px;
`

export const StyledTotalCount = styled.span`
  font-size: 16px;
  color: ${colors.gray};
`

export const StyledTooltipWrapper = styled.div`
  ${({ $isOffline }) => $isOffline && `
    width: 100%;
    height: 100%;
    position: relative;
  
    &:after {
      content: '';
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  `}
`
