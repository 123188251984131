import React from 'react'

import { StyledContainer } from './WidgetStyled'

const Widget = ({ children, part }) => (
  <StyledContainer part={part}>
    {children}
  </StyledContainer>
)

export default Widget
