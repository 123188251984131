import React from 'react'

import { Button, FooterActions, Typography } from 'components'

import i18n from 'translations'

import InfoContentItem from './InfoContentItem'
import { StyledContent, StyledContentContainer } from './InfoStyled'

const Info = ({ onContinueClick }) => (
  <React.Fragment>
    <StyledContentContainer>
      <Typography variant="h4">
        {i18n.t('module:Modals:InjurySignature:Info:subtitle')}
      </Typography>
      <StyledContent>
        <InfoContentItem
          columnIndex={1}
          icon="contact"
          text={i18n.t('module:Modals:InjurySignature:Info:contentText1')}
        />
        <InfoContentItem
          columnIndex={2}
          icon="reports"
          text={i18n.t('module:Modals:InjurySignature:Info:contentText2')}
        />
        <InfoContentItem
          columnIndex={3}
          icon="refresh"
          text={i18n.t('module:Modals:InjurySignature:Info:contentText3')}
        />
      </StyledContent>
    </StyledContentContainer>
    <FooterActions>
      <FooterActions.Item>
        <Button
          label={i18n.t('global:Continue')}
          negativeMargins
          onClick={onContinueClick}
        />
      </FooterActions.Item>
    </FooterActions>
  </React.Fragment>
)

export default Info
