import styled from 'styled-components'

import { StyledContainer } from 'components/Table/TableStyled'

export const StyledTableWrapper = styled.div`
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  
  ${StyledContainer} {
    margin-left: 0;
    margin-right: 0;
  }
`

export const StyledModalWrapper = styled.div`
  display: grid;
  grid-template-rows: 60px 1fr auto;
  height: 100%;
`
