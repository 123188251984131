export const GET_CHILD_FUNDING_SUMMARY_CALCULATOR = 'CHILD/GET_CHILD_FUNDING_SUMMARY_CALCULATOR'
export const GET_CHILD_FUNDING_SUMMARY_CALCULATOR_SUCCESS = 'CHILD/GET_CHILD_FUNDING_SUMMARY_CALCULATOR_SUCCESS'
export const GET_CHILD_FUNDING_SUMMARY_CALCULATOR_FAILED = 'CHILD/GET_CHILD_FUNDING_SUMMARY_CALCULATOR_FAILED'

export const CLEAR_CHILD_FUNDING_SUMMARY_CALCULATOR = 'CHILD/CLEAR_CHILD_FUNDING_SUMMARY_CALCULATOR'

interface FundingTypicalWeekAllocations {
  actualAllocation: number
  alternate: number
  maxAllocation: number
}

export interface FundingSummary {
  fundingAllocated: number
  fundingAllocatedTotal: number
  fundingInvoiced: number
  fundingTypicalWeekAllocations: FundingTypicalWeekAllocations[]
  name: string
}
