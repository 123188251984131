import _ from 'lodash'

import { createSelector } from 'reselect'

import { getInvoiceListSelector } from '../list/selectors'

import constants from '../constants'

const NO_DATA_ERROR_CODE = 400001

export const getBatchInvoicesData = (state) => state.invoices.batch.data

export const getBatchInvoicesMeta = (state) => state.invoices.batch.meta

const getDisplayName = (name, status, type, invoice = {}) => {
  if (status === constants.STATUS_TYPES.CANCELLED) {
    return `${name} (C)`
  }

  if (status === constants.STATUS_TYPES.BAD_DEBT) {
    return `${name} (B)`
  }

  if (type === constants.INVOICE_TYPES.CREDIT_NOTE) {
    const { name: creditNoteRefInvoiceName, number: creditNoteRefInvoiceNumber } = invoice

    return `CN-${creditNoteRefInvoiceName} (${creditNoteRefInvoiceNumber})`
  }

  return name
}

export const mapBatchInvoices = (item) => {
  const { exception, invoice: invoiceRoot } = item
  const { invoice, name, status, type } = invoiceRoot
  const { STATUS_TYPES } = constants

  const isDraft = !status || status === STATUS_TYPES.DRAFT
  const displayName = getDisplayName(name, status, type, invoice)

  return {
    displayName,
    ...invoiceRoot,
    exception,
    isDraft,
  }
}

export const getInvoiceBatchSelector = createSelector(
  [getBatchInvoicesData],
  (state) => {
    if (!state?.length) {
      return null
    }

    return _.map(state, mapBatchInvoices)
  },
)

export const getBatchInvoiceItem = (invoiceList) => (invoice, index) => {
  const { exception } = invoice
  const { error } = exception || {}
  const { code: errorCode } = error || {}
  const prevInvoice = invoiceList ? invoiceList[index] : null

  const action = _.find(constants.SAVE_INVOICE_ACTION_DROPDOWN, {
    value: constants.SAVE_INVOICE_ACTION.KEEP_AS_DRAFT,
  })

  return {
    ...invoice,
    action,
    hasError: errorCode && NO_DATA_ERROR_CODE !== errorCode,
    hasNoItems: errorCode && NO_DATA_ERROR_CODE === errorCode,
    origin: {
      status: prevInvoice?.status === constants.STATUS_TYPES.DRAFT
        ? constants.STATUS_TYPES.DRAFT
        : null,
    },
  }
}

export const getBatchInitialValues = createSelector(
  [getInvoiceBatchSelector, getInvoiceListSelector],
  (state, invoiceList) => {
    if (!state) {
      return null
    }

    return {
      invoices: _.map(state, getBatchInvoiceItem(invoiceList)),
    }
  },
)

export const getBatchUpdatePayload = (fields) => {
  if (!fields) {
    return null
  }

  const nonDraftInvoices = _.filter(fields.invoices, ({ action, hasError, hasNoItems }) => {
    if (hasNoItems || hasError) {
      return false
    }

    return action.value !== constants.SAVE_INVOICE_ACTION.KEEP_AS_DRAFT
  })

  if (!nonDraftInvoices?.length) {
    return null
  }

  return _.map(nonDraftInvoices, ({ action, id }) => ({
    id,
    [constants.SAVE_BATCH_INVOICE_ACTION_MAPPING[action.value]]: true,
  }))
}

export const getBatchInvoiceErrors = createSelector(
  [getBatchInvoicesData],
  (state) => {
    if (!state?.length) {
      return null
    }

    return _.filter(state, ({ exception }) => {
      const { error } = exception || {}
      const { code: errorCode } = error || {}

      return errorCode && NO_DATA_ERROR_CODE !== errorCode
    })
  },
)

export const getBatchInvoicesTotal = createSelector(
  [getInvoiceBatchSelector],
  (state) => {
    if (!state) {
      return null
    }

    return _.reduce(
      _.map(state, ({ total }) => total),
      (invoicesTotal, invoiceTotal) => invoicesTotal + invoiceTotal,
      0,
    )
  },
)
