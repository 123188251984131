import React from 'react'

import { Callout, EmptyState, Page, Pagination, Section, Select, Spinner, Table, Toolbar } from 'components'

import i18n from 'translations'

const ManagementExtraItemsView = ({
  error,
  extraItems,
  isEmpty,
  isLoading,
  nurseryOptions,
  onPageChange,
  onSortChange,
  onStatusFilterChange,
  page,
  pageCount,
  perPage,
  sortField,
  sortOrder,
  statusFilter,
  statusFilterOptions,
  totalResults,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('module:Children:Child:BookingPattern:ExtraItems:extraItems')}
      titleSingular={i18n.t('module:Children:Child:BookingPattern:ExtraItems:extraItem')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (isEmpty) {
      return (
        <EmptyState
          icon="plus"
          text1="Add extra items here to show up on invoices"
        />
      )
    }

    return (
      <React.Fragment>
        <p>
          These are the extra items for your nursery. You can allocate them to children from the child profile.
        </p>
        {renderPagination()}
        <Table
          columns={[
            {
              align: 'left',
              field: 'name',
              sortable: true,
              title: 'Extra item',
            },
            {
              field: 'unitPrice',
              sortable: true,
              title: 'Cost',
            },
            {
              field: 'feeCalculations',
              sortable: true,
              title: 'Invoice calculation',
            },
            {
              field: 'rowToButton',
            },
          ]}
          data={extraItems}
          sortField={sortField}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const actions = (
    <Section.Actions primary={[{ to: `${nurseryOptions.prefix}/finance-settings/items/add` }]} />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title="Extra items"
    >
      <Callout content={error} error />
      <Toolbar noMargin>
        <Toolbar.Group>
          <Toolbar.Item>
            <Select
              clearable={false}
              options={statusFilterOptions}
              searchable={false}
              value={statusFilter}
              onChange={onStatusFilterChange}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      {renderContent()}
    </Page.Section>
  )
}

export default ManagementExtraItemsView
