import React from 'react'

import { Callout, EmptyState, Form, InfiniteDropdowns, Page, Section, Spinner, Toolbar, Typography } from 'components'

import i18n from 'translations'

import AgedDebtReportTable from './components/AgedDebtReportTable'
import AgedDebtReportChart from './components/AgedDebtReportChart'

const AgedDebtView = ({
  ageDebtChartData,
  child,
  errorMessages,
  isEmpty,
  isLoading,
  isOrganizationContext,
  isTableLoading,
  onChartPointClick,
  onChildChange,
  onPageChange,
  onRoomChange,
  onSendReport,
  onSortChange,
  page,
  pageCount,
  room,
  sortField,
  sortOrder,
  tableData,
  totalResults,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (isEmpty) {
      return (
        <EmptyState
          icon="invoice-late"
          text1={i18n.t('module:Finance:Reports:AgedDebt:EmptyState:text1')}
          text2={i18n.t('module:Finance:Reports:AgedDebt:EmptyState:text2')}
        />
      )
    }

    return (
      <React.Fragment>
        <AgedDebtReportChart
          ageDebtChartData={ageDebtChartData}
          reflow
          onChartPointClick={onChartPointClick}
        />
        <AgedDebtReportTable
          data={tableData}
          isLoading={isTableLoading}
          isOrganizationContext={isOrganizationContext}
          page={page}
          pageCount={pageCount}
          sortField={sortField}
          sortOrder={sortOrder}
          totalResults={totalResults}
          onPageChange={onPageChange}
          onSortChange={onSortChange}
        />
      </React.Fragment>
    )
  }

  const actions = !isOrganizationContext && !isEmpty && (
    <Section.Actions options={[{ onClick: onSendReport, type: 'export' }]} />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title={i18n.t('module:Finance:Reports:AgedDebt:title')}
    >
      <Callout content={errorMessages} error />
      {!isOrganizationContext && (
        <Typography margin="20px 0">
          {i18n.t('module:Finance:Reports:AgedDebt:subtitle')}
        </Typography>
      )}
      <Toolbar bottomLine>
        <Toolbar.Group>
          {!isOrganizationContext && (
            <React.Fragment>
              <Toolbar.Item>
                <Form.Row
                  label={i18n.t('module:Finance:Reports:AgedDebt:Filters:Rooms:label')}
                  verticalLabel
                >
                  <InfiniteDropdowns.Rooms
                    placeholder={i18n.t('module:Finance:Reports:AgedDebt:Filters:Rooms:placeholder')}
                    value={room}
                    onChange={onRoomChange}
                  />
                </Form.Row>
              </Toolbar.Item>
              <Toolbar.Item>
                <Form.Row
                  label={i18n.t('module:Finance:Reports:AgedDebt:Filters:Child:label')}
                  verticalLabel
                >
                  <InfiniteDropdowns.Children
                    value={child}
                    onChange={onChildChange}
                  />
                </Form.Row>
              </Toolbar.Item>
            </React.Fragment>
          )}
        </Toolbar.Group>
      </Toolbar>
      {renderContent()}
    </Page.Section>
  )
}

export default AgedDebtView
