export const LIST_CHILD_FUNDING_SESSION_SET = 'LIST_CHILD_FUNDING_SESSION_SET'
export const LIST_CHILD_FUNDING_SESSION_SET_SUCCESS = 'LIST_CHILD_FUNDING_SESSION_SET_SUCCESS'
export const LIST_CHILD_FUNDING_SESSION_SET_FAILED = 'LIST_CHILD_FUNDING_SESSION_SET_FAILED'
export const CLEAR_CHILD_FUNDING_SESSION_SET = 'CLEAR_CHILD_FUNDING_SESSION_SET'
export const UPDATE_CHILD_FUNDING_SESSION_SET_LIST = 'UPDATE_CHILD_FUNDING_SESSION_SET_LIST'

export const listChildFundingSessionSet = (params, onSuccess, mergeResult) => ({
  childFundingSessionSetApiClient,
}) => async (dispatch) => {
  dispatch({
    params,
    type: LIST_CHILD_FUNDING_SESSION_SET,
  })

  await childFundingSessionSetApiClient.list(params).then(
    (data) => {
      dispatch({
        mergeResult,
        payload: data,
        type: LIST_CHILD_FUNDING_SESSION_SET_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: LIST_CHILD_FUNDING_SESSION_SET_FAILED,
      })
    },
  )
}

export const updateChildFundingSessionSetList = (data) => ({
  payload: data,
  type: UPDATE_CHILD_FUNDING_SESSION_SET_LIST,
})

export const clearChildFundingSessionSet = () => ({
  type: CLEAR_CHILD_FUNDING_SESSION_SET,
})
