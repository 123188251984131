import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { FUNDING_MODAL_TYPE } from 'module/Modals/FundingModal/constants'
import { CHILD_FUNDING_FILTERS } from 'services/legacy/childFunding/constants'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withChildFundingService } from 'services/legacy/childFunding'
import { withRouter } from 'services/router'

import i18n from 'translations'

import ReAllocateFundingView from './ReAllocateFundingView'

const CHILD_FUNDING_GROUPS = {
  read: [
    'childFunding.funding',
    'nurseryFunding',
    'nurseryFunding.fundingType',
    'fundingType',
  ],
}

class ReAllocateFundingContainer extends Component {
  constructor(props) {
    super(props)

    const { location } = props
    const { query } = location
    const { endDate, startDate } = query

    this.state = {
      endDate,
      startDate,
    }
  }

  componentDidMount() {
    const { childFundingActions, childFundingHelpers, childId } = this.props
    const { endDate, startDate } = this.state

    const params = {
      criteria: childFundingHelpers.getCriteria({
        child: childId,
        endDate,
        startDate,
        status: CHILD_FUNDING_FILTERS.ACTIVE,
      }),
      groups: CHILD_FUNDING_GROUPS,
      order: { sortField: 'startDate', sortOrder: 'DESC' },
    }

    childFundingActions.list(params)
  }

  handleSaveAllocationSuccess = (response) => {
    const { childFundingActions } = this.props

    childFundingActions.updateList(response)
  }

  handleAllocateChildFundingClick = (childId, childFundingId) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.FUNDING, {
      modalChildProps: { childFundingId, childId, onSaveAllocationSuccess: this.handleSaveAllocationSuccess },
      modalComponentType: FUNDING_MODAL_TYPE.ALLOCATE_CHILD_FUNDING,
      title: i18n.t('module:Children:Child:BookingPattern:Funding:Allocation:title'),
    }, {
      enableMultipleModal: true,
    })
  }

  handleContinueClick = () => {
    const { childFundingData, onContinueClick } = this.props

    const minRecord = _.minBy(childFundingData, ({ startDate }) => startDate)
    const maxRecord = _.maxBy(childFundingData, ({ endDate }) => endDate)

    onContinueClick(minRecord?.startDate, maxRecord?.endDate)
  }

  render() {
    const {
      childFundingData,
      childId,
      isFetching,
    } = this.props

    return (
      <ReAllocateFundingView
        childFundingData={childFundingData}
        childId={childId}
        isLoading={isFetching}
        onAllocateChildFundingClick={this.handleAllocateChildFundingClick}
        onContinueClick={this.handleContinueClick}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childFundingListState,
  childFundingSelectors,
}) => ({
  childFundingData: childFundingSelectors.getFormattedListData(state),
  errorMessages: appSelectors.getErrorMessages(childFundingListState),
  isFetching: appSelectors.getIsFetching(childFundingListState),
})

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withChildFundingService,
  connect(mapState),
)

export default enhance(ReAllocateFundingContainer)
