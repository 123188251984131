import React from 'react'

import PlanView from 'module/Finance/components/PlanView'

import i18n from 'translations'

const FinanceCurrentPlanAdhocSessions = ({ childAdhocSessionCount }) => {
  if (!childAdhocSessionCount) {
    return (
      <PlanView>
        <PlanView.Icon icon="extra-sessions" />
        <PlanView.Content>
          <PlanView.SubTitle
            subtitle={i18n.t('module:Children:Child:Finance:PlanView:Summary:extraSessionsEmptyState')}
          />
        </PlanView.Content>
      </PlanView>
    )
  }

  return (
    <PlanView>
      <PlanView.Icon icon="extra-sessions" />
      <PlanView.Content>
        <PlanView.Item>
          <PlanView.Highlight>
            {`${childAdhocSessionCount} x`}
            &nbsp;
          </PlanView.Highlight>
          {i18n.t('module:Children:Child:Finance:PlanView:Summary:extraSessions')}
        </PlanView.Item>
      </PlanView.Content>
    </PlanView>
  )
}

export default FinanceCurrentPlanAdhocSessions
