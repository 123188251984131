import React, { PropsWithChildren } from 'react'

import { StyledContainer, StyledLoadingDot } from './LoadingDotsStyled'

const LoadingDots: React.FC<PropsWithChildren<any>> = () => (
  <StyledContainer>
    <StyledLoadingDot />
    <StyledLoadingDot />
    <StyledLoadingDot />
  </StyledContainer>
)

export default LoadingDots
