import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import {
  CHILDREN_LEAVING_REPORT_STATISTICS,
  CHILDREN_LEAVING_REPORT_STATISTICS_FAILED,
  CHILDREN_LEAVING_REPORT_STATISTICS_SUCCESS,
  CLEAR_CHILDREN_LEAVING_REPORT_STATISTICS,
} from './constants'

export default (...props) => {
  const [state = getBasicListInitialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_CHILDREN_LEAVING_REPORT_STATISTICS,
    failed: CHILDREN_LEAVING_REPORT_STATISTICS_FAILED,
    init: CHILDREN_LEAVING_REPORT_STATISTICS,
    success: CHILDREN_LEAVING_REPORT_STATISTICS_SUCCESS,
  }, getBasicListInitialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}

