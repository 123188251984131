import { SubmissionError } from 'redux-form'

export const GET_SESSION = 'GET_SESSION'
export const GET_SESSION_SUCCESS = 'GET_SESSION_SUCCESS'
export const GET_SESSION_FAILED = 'GET_SESSION_FAILED'

export const CREATE_SESSION = 'CREATE_SESSION'
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS'
export const CREATE_SESSION_FAILED = 'CREATE_SESSION_FAILED'

export const UPDATE_SESSION = 'UPDATE_SESSION'
export const UPDATE_SESSION_SUCCESS = 'UPDATE_SESSION_SUCCESS'
export const UPDATE_SESSION_FAILED = 'UPDATE_SESSION_FAILED'

export const ARCHIVE_SESSION = 'ARCHIVE_SESSION'
export const ARCHIVE_SESSION_SUCCESS = 'ARCHIVE_SESSION_SUCCESS'
export const ARCHIVE_SESSION_FAILED = 'ARCHIVE_SESSION_FAILED'

export const CLEAR_SESSION = 'CLEAR_SESSION'

export const getSession = (id, onSuccess) => ({ organizationSessionsApiClient }) => (dispatch) => {
  dispatch({
    id,
    type: GET_SESSION,
  })

  organizationSessionsApiClient.get(id).then(
    (data) => {
      dispatch({
        payload: data,
        type: GET_SESSION_SUCCESS,
      })

      onSuccess?.(data)
    },
    (error) => {
      dispatch({
        error: error.response,
        type: GET_SESSION_FAILED,
      })
    },
  )
}

export const createSession = (payload, onSuccess) => ({ organizationSessionsApiClient }) => (dispatch) => {
  dispatch({
    payload,
    type: CREATE_SESSION,
  })

  return organizationSessionsApiClient.create(payload).then(
    (data) => {
      dispatch({
        payload: data,
        type: CREATE_SESSION_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: CREATE_SESSION_FAILED,
      })

      throw new SubmissionError({
        ...error.response.extra,
        _error: error.response.message,
      })
    },
  )
}

export const updateSession = (id, payload, onSuccess) => ({ organizationSessionsApiClient }) => (dispatch) => {
  dispatch({
    id,
    payload,
    type: UPDATE_SESSION,
  })

  organizationSessionsApiClient.update(id, payload).then(
    (data) => {
      dispatch({
        payload: data,
        type: UPDATE_SESSION_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: UPDATE_SESSION_FAILED,
      })

      throw new SubmissionError({
        ...error.response.extra,
        _error: error.response.message,
      })
    },
  )
}

export const archiveSession = (
  id,
  payload,
  onSuccess,
  onConflict,
) => ({ organizationSessionsApiClient }) => (dispatch) => {
  dispatch({
    id,
    payload,
    type: ARCHIVE_SESSION,
  })

  organizationSessionsApiClient.archive(id, payload).then(
    (data) => {
      dispatch({
        payload: data,
        type: ARCHIVE_SESSION_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      const showConflict = error.response && error.response.code && 409 === error.response.code

      if (showConflict) {
        onConflict(error.response)

        dispatch({
          type: ARCHIVE_SESSION_FAILED,
        })
      } else {
        dispatch({
          error: error.response,
          type: ARCHIVE_SESSION_FAILED,
        })
      }
    },
  )
}

export const clearSession = () => ({
  type: CLEAR_SESSION,
})
