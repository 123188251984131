import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

interface StyledActiveChildrenItemsCountProps {
  $active?: boolean
}

export const StyledActiveChildrenItemsCount = styled.span<StyledActiveChildrenItemsCountProps>`
  margin-left: 5px;

  ${({ $active }) => $active && `
    font-weight: 600;
    color: ${getBrandingColor('tertiary-color')};
  `}
`

export const StyledSpinnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 20px;
`

export const StyledItemsContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-column-gap: 35px;
  grid-template-columns: repeat(3, 1fr);
  
  @media(max-width: 780px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media(max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const StyledScrollableElement = styled.div`
  max-height: 240px;
  overflow-y: auto;
  margin-top: 10px;
  flex: 1;
  padding: 10px;
`
