import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import {
  CLEAR_LIST_OF_PROGRESS_MONTESSORI_HISTORY,
  LIST_OF_PROGRESS_MONTESSORI_HISTORY,
  LIST_OF_PROGRESS_MONTESSORI_HISTORY_FAILED,
  LIST_OF_PROGRESS_MONTESSORI_HISTORY_SUCCESS,
} from './constants'

export default (...props) => {
  const [state = getBasicListInitialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_LIST_OF_PROGRESS_MONTESSORI_HISTORY,
    failed: LIST_OF_PROGRESS_MONTESSORI_HISTORY_FAILED,
    init: LIST_OF_PROGRESS_MONTESSORI_HISTORY,
    success: LIST_OF_PROGRESS_MONTESSORI_HISTORY_SUCCESS,
  }, getBasicListInitialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
