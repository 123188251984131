import { createSelector } from 'reselect'

import { ChildBacsDetail, ChildBacsDetailsRootState } from '../models'

const getChildBacsDetailsState = (state: ChildBacsDetailsRootState) => state?.childBacsDetails

export const getChildBacsDetails = createSelector([getChildBacsDetailsState], (state) => state?.single?.data)

export const getBody = (fields): ChildBacsDetail => {
  const {
    accountHolder,
    accountNotes,
    accountNumber,
    bankName,
    chargeDay,
    enabled,
    sortCode,
  } = fields

  if (!enabled) {
    return {
      enabled,
    }
  }

  return {
    accountHolder,
    accountNotes,
    accountNumber,
    bankName,
    chargeDay: chargeDay?.value,
    enabled,
    sortCode,
  }
}
