import React, { PropsWithChildren } from 'react'

import CollapseRow from './CollapseRow'
import { StyledCollapseWrapper } from './CollapseStyled'

interface CollapseRoot extends React.FC<PropsWithChildren<any>> {
  Row?: typeof CollapseRow
}

const Collapse: CollapseRoot = ({ children }) => (
  <StyledCollapseWrapper>
    {children}
  </StyledCollapseWrapper>
)

export default Collapse
