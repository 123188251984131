import React from 'react'

import { ENQUIRY_VIEWING_FILTER_STATUS_OPTIONS } from 'services/legacy/enquiries/constants'

import { generateRoute } from 'utils/routing'

import {
  DatePicker,
  EmptyState,
  Form,
  InfiniteDropdowns,
  Page,
  Pagination,
  SearchBar,
  Section,
  Select,
  Space,
  Spinner,
  Table,
  Toolbar,
} from 'components'

import i18n from 'translations'

const COLUMNS = [
  {
    align: 'left',
    field: 'enquiryDate',
    sortable: true,
    title: i18n.t('module:Enquiries:List:Table:Header:enquiryDate'),
  },
  {
    align: 'left',
    field: 'contactName',
    sortable: true,
    title: i18n.t('module:Enquiries:List:Table:Header:contact'),
  },
  {
    align: 'left',
    field: 'childName',
    sortable: true,
    title: i18n.t('module:Enquiries:List:Table:Header:child'),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'birthDate',
    sortKey: 'data.child.birthDate',
    sortable: true,
    title: i18n.t('global:Child:DOB'),
  },
  {
    align: 'left',
    field: 'startDate',
    sortable: true,
    title: i18n.t('module:Enquiries:List:Table:Header:startDate'),
  },
  {
    align: 'left',
    field: 'weeklyValue',
    title: i18n.t('module:Enquiries:List:Table:Header:weeklyValue'),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'viewingCount',
    title: i18n.t('module:Enquiries:List:Table:Header:viewingCount'),
  },
  {
    align: 'left',
    field: 'status',
    sortable: true,
    title: i18n.t('module:Enquiries:List:Table:Header:status'),
  },
  {
    field: 'edit',
  },
]

const ListView = ({
  enquiries,
  enquiryDate,
  isLoading,
  onEnquiryDateChange,
  onExportClick,
  onPageChange,
  onSearchChange,
  onSortChange,
  onStartDateChange,
  onStatusChange,
  onViewingStatusChange,
  page,
  pageCount,
  perPage,
  search,
  sortField,
  sortOrder,
  startDate,
  status,
  totalResults,
  viewingStatus,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('module:Enquiries:List:enquiries')}
      titleSingular={i18n.t('module:Enquiries:List:enquiry')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const actions = (
    <Section.Actions
      options={[{ onClick: onExportClick, type: 'export' }]}
      primary={[{
        label: i18n.t('module:Enquiries:List:Add:addTitle'),
        to: generateRoute('ENQUIRIES.ADD'),
      }]}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!isLoading && !enquiries?.length) {
      return (
        <EmptyState
          icon="enquiries"
          text1={i18n.t('module:Enquiries:List:emptyState')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={COLUMNS}
          data={enquiries}
          sortField={sortField}
          sortOrder={sortOrder}
          visualType="transparent"
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const renderHeader = () => (
    <React.Fragment>
      <SearchBar
        placeholder={i18n.t('module:Enquiries:List:searchPlaceholder')}
        value={search}
        variant="standard"
        noBackground
        onChange={onSearchChange}
      />
      <Space margin="0 0 10px" />
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <Form.Row label={i18n.t('module:Enquiries:List:Filters:EnquiryDate:label')} verticalLabel>
              <DatePicker value={enquiryDate} clearable range onChange={onEnquiryDateChange} />
            </Form.Row>
          </Toolbar.Item>
          <Toolbar.Item>
            <Form.Row label={i18n.t('module:Enquiries:List:Filters:StartDate:label')} verticalLabel>
              <DatePicker value={startDate} clearable range onChange={onStartDateChange} />
            </Form.Row>
          </Toolbar.Item>
          <Toolbar.Item>
            <Form.Row label={i18n.t('module:Enquiries:List:Filters:Status:label')} verticalLabel>
              <InfiniteDropdowns.EnquiryStatusTypes
                placeholder={i18n.t('module:Enquiries:List:Filters:Status:placeholder')}
                value={status}
                onChange={onStatusChange}
              />
            </Form.Row>
          </Toolbar.Item>
          <Toolbar.Item>
            <Form.Row label={i18n.t('module:Enquiries:List:Filters:ViewingStatus:label')} verticalLabel>
              <Select
                options={ENQUIRY_VIEWING_FILTER_STATUS_OPTIONS}
                placeholder={i18n.t('module:Enquiries:List:Filters:ViewingStatus:placeholder')}
                value={viewingStatus}
                onChange={onViewingStatusChange}
              />
            </Form.Row>
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
    </React.Fragment>
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title={i18n.t('module:Enquiries:List:title')}
    >
      {renderHeader()}
      {renderContent()}
    </Page.Section>
  )
}

export default ListView
