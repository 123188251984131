import ServiceBase from 'services/ServiceBase'

import { getQueryString, getQueryStringV2 } from 'utils/api'

class DailyDiaryApiClient extends ServiceBase {
  list = (params) => this.request({
    path: `/v2/daily_diaries?${getQueryStringV2(params)}`,
  })

  send = (params) => this.request({
    method: 'PUT',
    path: `/v2/daily_diaries/send?${getQueryStringV2(params)}`,
  })

  getMyDailyDiary = (params, token) => this.request({
    credentials: 'include',
    path: `/my-daily-diary?${getQueryString(params)}`,
    token,
  })
}

export default DailyDiaryApiClient
