import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_ORGANISATIONS_DROPDOWN_LIST,
  ORGANISATIONS_DROPDOWN_LIST,
  ORGANISATIONS_DROPDOWN_LIST_FAILED,
  ORGANISATIONS_DROPDOWN_LIST_SUCCESS,
} from './constants'

export const dropdownList = (options: BasicActionOptionsProps) => ({ organizationApiClient }) => (
  generateBasicActions.list({
    constants: {
      failed: ORGANISATIONS_DROPDOWN_LIST_FAILED,
      init: ORGANISATIONS_DROPDOWN_LIST,
      success: ORGANISATIONS_DROPDOWN_LIST_SUCCESS,
    },
    options,
    service: organizationApiClient,
    serviceMethod: 'list',
  })
)

export const clearDropdownList = () => ({
  type: CLEAR_ORGANISATIONS_DROPDOWN_LIST,
})
