import i18n from 'translations'

import BirthdaysWidget from './BirthdaysWidgetContainer'

export const WIDGET_NAME = {
  component: BirthdaysWidget,
  id: 'BIRTHDAYS_WIDGET',
  label: i18n.t('module:Dashboard:Widgets:BirthdaysWidget:titleInSettings'),
}

export default BirthdaysWidget
