import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledRowCohortTracking = styled.div`
  display: grid;
  grid-template-columns: 180px 140px auto;
  grid-template-areas: 'type author details';
  height: 100%;
  
  @media(max-width: 1000px) {
    grid-template-columns: 1fr 2fr;
    grid-template-areas: 'type author'
      'type details';
  }
  
  @media(max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    display: flex;
    flex-direction: column;
  }
`

export const StyledLeftColumn = styled.div`
  background: ${getBrandingColor('primary-color')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${NEUTRAL_COLOURS.WHITE};
  font-size: 18px;
  padding: 20px;
  text-align: center;
  line-height: 26px;
  grid-area: type;
`

export const StyledMiddleColumn = styled.div`
  padding: 20px 0 20px 20px;
  
  @media(max-width: 1000px) {
    padding-bottom: 0;
  }
`

export const StyledAuthor = styled.div`
  margin: 0 0 20px;
`

export const StyledDate = styled.div`
  margin: 0 0 20px;
  
  @media(max-width: 1000px) {
    margin: 0;
  }
`

export const StyledMiddleContent = styled.div`
  border-right: 3px solid ${NEUTRAL_COLOURS.WHITE};
  width: 100%;
  height: 100%;
  min-width: 120px;
  padding-right: 20px;
  margin-right: 20px;
  
  @media(max-width: 1000px) {
    border-right: 0;
    margin-right: 0;
    padding-right: 0;
  }
`

export const StyledActionPlan = styled.div`
  color: ${getBrandingColor('tertiary-color')};
  display: flex;
  align-items: center;
  
  & > div {
    margin-right: 5px;
  }
`

export const StyledRightColumn = styled.div`
  padding: 20px;
  display: grid;
  grid-row-gap: 15px;
  grid-template-rows: auto auto;
`

export const StyledRightColumnTop = styled.div`
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: auto auto auto;
  
  @media(max-width: 760px) {
    grid-row-gap: 15px;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
  }
`

export const StyledCustomRow = styled.div`
  & > article {
    grid-template-areas: 'content action';
  }
`
