import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledSmallText = styled.span`
  font-size: .75em;
  font-weight: 400;
`

interface StyledContainerProps {
  $bold?: boolean
  $color?: string
  $fontSize?: number
  $highlight?: boolean
}

export const StyledContainer = styled.span<StyledContainerProps>`
  display: inline-flex;
  align-items: baseline;
  justify-content: center;
    
  ${({ $highlight }) => $highlight && `
    color: ${getBrandingColor('tertiary-color')};
    font-weight: 600;
  `}  
  
  ${({ $color }) => $color && `
    color: ${$color};
  `}
  
  ${({ $bold }) => $bold && `
    font-weight: 600;
    
    ${StyledSmallText} {
      font-weight: 600;    
    }
  `}

  ${({ $fontSize }) => $fontSize && `
    font-size: ${$fontSize}px;
  `}
`
