import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'
import { RolesDetails } from 'constants/security'
import { WORKLOG_RECORD_DAY_TYPE } from 'services/legacy/membershipRegisters/constants'

import { Accordion, Avatar, Banner, EmptyState, ModalBox, Space, Spinner, Table, Typography } from 'components'

import i18n from 'translations'

import PreviousRecordBanner from 'module/Staff/components/PreviousRecordBanner'

import { StyledHeader, StyledItemWrapper, StyledTable } from './ChangeLogModalStyled'

const COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'type',
    title: i18n.t('global:Type'),
    width: '40%',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'oldValue',
    title: i18n.t('module:Modals:Staff:Worklog:ChangeLog:oldValue'),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'newValue',
    title: i18n.t('module:Modals:Staff:Worklog:ChangeLog:newValue'),
  },
]

const ChangeLogModalView = ({
  changelogData,
  isFetching,
  memberDetails,
  onCloseClick,
  previousRecord,
  selectedDate,
  worklogDayType,
}) => {
  const renderItem = (item) => {
    const {
      data,
      loggedAt,
      membership,
    } = item

    const {
      firstName,
      name,
      photo,
      roles,
      surname = '',
    } = membership || {}

    const {
      entries = [],
      changeComment,
      summary,
    } = data || {}

    return (
      <Accordion.Item
        header={(
          <StyledItemWrapper>
            <Avatar
              avatarSize="medium"
              gap={15}
              initials={[firstName, surname]}
              src={photo}
              subTitle={(
                <Typography color={NEUTRAL_COLOURS.GRAY}>
                  {_.join(_.map(roles, (role) => RolesDetails[role]?.label), ', ')}
                </Typography>
              )}
              title={(
                <Typography bold>
                  {name}
                </Typography>
              )}
            />
            <Typography color={NEUTRAL_COLOURS.GRAY}>
              {`Changes: ${summary}`}
            </Typography>
            <Typography>
              {moment(loggedAt).format('ddd, DD/MM/YYYY, HH:mm')}
            </Typography>
          </StyledItemWrapper>
        )}
      >
        <StyledTable>
          <Table
            columns={COLUMNS}
            data={entries}
            visualType="border"
            noMargin
          />
        </StyledTable>
        <Space space="20px" />
        <Typography margin="0 0 7px 0" bold>
          {i18n.t('global:Comment')}
        </Typography>
        <Typography>
          {changeComment || '-'}
        </Typography>
      </Accordion.Item>
    )
  }

  const renderHeader = () => {
    const {
      firstName,
      name,
      photo,
      roles,
      surname = '',
    } = memberDetails || {}

    const rolesLabels = _.map(roles, (role) => RolesDetails[role]?.label)

    return (
      <StyledHeader>
        <Avatar
          gap={15}
          initials={[firstName, surname]}
          src={photo}
          subTitle={(
            <Typography color={NEUTRAL_COLOURS.GRAY}>
              {rolesLabels}
            </Typography>
          )}
          title={(
            <Typography fontSize={24} bold>
              {name}
            </Typography>
          )}
        />
        <Typography fontSize={20} variant="span" bold>
          {moment(selectedDate).format('dddd')}
          {', '}
          <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={20} variant="span" medium>
            {moment(selectedDate).format(DISPLAY_SHORT_DATE_FORMAT)}
          </Typography>
          <Space space="10px" />
        </Typography>
      </StyledHeader>
    )
  }

  const renderPreviousRecordBannerText = () => {
    const { firstName, id: membershipId } = memberDetails || {}
    const { endDate: previousEndDate, startDate: previousStartDate } = previousRecord || {}

    return (
      <PreviousRecordBanner
        firstName={firstName}
        membershipId={membershipId}
        previousEndDate={previousEndDate}
        previousStartDate={previousStartDate}
        selectedDate={selectedDate}
        worklogDayType={worklogDayType}
      />
    )
  }

  const renderPreviousRecordBanner = () => worklogDayType !== WORKLOG_RECORD_DAY_TYPE.SAME_DAY && (
    <Banner.Info>
      {renderPreviousRecordBannerText()}
    </Banner.Info>
  )

  const renderContent = () => {
    if (!changelogData?.length) {
      return (
        <EmptyState
          icon="staff-rota"
          padding="40px 0"
          text1={i18n.t('module:Modals:Staff:Worklog:ChangeLog:emptyState')}
        />
      )
    }

    return (
      <Accordion>
        {_.map(changelogData, renderItem)}
      </Accordion>
    )
  }

  const renderWrapperContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    return (
      <React.Fragment>
        {renderHeader()}
        {renderPreviousRecordBanner()}
        {renderContent()}
      </React.Fragment>
    )
  }

  return (
    <ModalBox
      title={i18n.t('module:Modals:Staff:Worklog:ChangeLog:title')}
      onCloseClick={onCloseClick}
    >
      {renderWrapperContent()}
    </ModalBox>
  )
}

export default ChangeLogModalView
