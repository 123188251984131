import _ from 'lodash'
import moment from 'moment'

import React, { useMemo } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { CHILD_AGES_OPTIONS } from 'services/legacy/child/constants'
import { ROLES } from 'constants/security'

import { getYearOptions } from 'utils/date'

import { withAppService } from 'services/app'
import { withMonthlyFteChangeReportService } from 'services/legacy/monthlyFteChangeReport'
import { withPaginationUtils } from 'services/utils/pagination'
import { withSecurityService } from 'services/security'
import { withRouter } from 'services/router'

import { useBasicReport } from 'hooks/reports'

import { DatePicker, Form, ReportView, Select, Toolbar } from 'components'

import i18n from 'translations'

import { getColumns, getStatistics, getTableData } from './MonthlyFteChangeHelper'

const NURSERIES_MONTHLY_FTE_GROUPS = {
  read: [
    'nursery',
  ],
}

const MonthlyFteChangeContainer = ({
  errorMessages,
  isFetching,
  isOrganizationContext,
  isStatisticsFetching,
  location,
  monthlyFteChangeReportActions,
  monthlyFteChangeReportSelectors,
  nurseriesData,
  nurseriesTotalResults,
  paginationUtils,
  setLocationQuery,
  statisticsData: childrenData,
  statisticsData,
}) => {
  const yearOptions = useMemo(() => getYearOptions(4, { hideFutureYears: true }), [])
  const {
    date,
    onDateChange,
    onPageChange,
    onYearChange,
    page,
    pageCount,
    perPage,
    tableColumns,
    tableData,
    totalResults,
    year,
  } = useBasicReport({
    actions: {
      clearChildren: monthlyFteChangeReportActions.clearStatistics,
      clearNurseries: monthlyFteChangeReportActions.clearNurseries,
      getChildren: monthlyFteChangeReportActions.getStatistics,
      getNurseries: monthlyFteChangeReportActions.getNurseries,
    },
    ageOptions: CHILD_AGES_OPTIONS,
    childrenData,
    childrenTotalResults: childrenData?.length,
    defaultFilters: {
      year: !isOrganizationContext ? yearOptions[yearOptions.length - 1] : undefined,
    },
    errorMessages,
    getColumns,
    getStatistics,
    getTableData,
    isDateRangeFilter: false,
    isOrganizationContext,
    location,
    nurseriesApiGroups: NURSERIES_MONTHLY_FTE_GROUPS,
    nurseriesData,
    nurseriesTotalResults,
    paginationUtils,
    selectors: monthlyFteChangeReportSelectors,
    setLocationQuery,
    statisticsData,
    yearOptions,
  })

  return (
    <ReportView.DetailPage
      errorMessages={errorMessages}
      filters={(
        <Toolbar bottomLine>
          <Toolbar.Group>
            {!isOrganizationContext && (
              <Toolbar.Group>
                <Toolbar.Item>
                  <Select
                    clearable={false}
                    options={yearOptions}
                    searchable={false}
                    value={year}
                    onChange={onYearChange}
                  />
                </Toolbar.Item>
              </Toolbar.Group>
            )}
            {isOrganizationContext && (
              <Toolbar.Item>
                <Form.Row
                  label={_.upperFirst(i18n.t('global:month'))}
                  verticalLabel
                >
                  <DatePicker
                    clearable={false}
                    disabledDays={[{
                      after: moment().endOf('day').toDate(),
                    }]}
                    type="month"
                    value={date}
                    onChange={onDateChange}
                  />
                </Form.Row>
              </Toolbar.Item>
            )}
          </Toolbar.Group>
        </Toolbar>
      )}
      hasPageContentFromParent={!isOrganizationContext}
      isOrganizationContext={isOrganizationContext}
      isPageLoading={isFetching && isStatisticsFetching && 1 === page}
      isTableLoading={isFetching || isStatisticsFetching}
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      tableColumns={tableColumns}
      tableData={tableData}
      title={i18n.t('module:Reports:MonthlyFteChange:title')}
      totalResults={totalResults}
      hideExport
      onPageChange={onPageChange}
    />
  )
}

const mapState = (state, {
  appSelectors,
  monthlyFteChangeReportNurseriesState,
  monthlyFteChangeReportSelectors,
  monthlyFteChangeReportStatisticsState,
  securitySelectors,
}) => ({
  errorMessages: appSelectors.getErrorMessages(
    monthlyFteChangeReportNurseriesState,
    monthlyFteChangeReportStatisticsState,
  ),
  isFetching: appSelectors.getIsFetching(monthlyFteChangeReportNurseriesState),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
  isStatisticsFetching: appSelectors.getIsFetching(monthlyFteChangeReportStatisticsState),
  nurseriesData: monthlyFteChangeReportSelectors.getMonthlyFteChangeReportNurseriesData(state),
  nurseriesTotalResults: appSelectors.getTotalResults(monthlyFteChangeReportNurseriesState),
  statisticsData: monthlyFteChangeReportSelectors.getMonthlyFteChangeReportStatisticsData(state),
})

const enhance = compose(
  withRouter,
  withAppService,
  withMonthlyFteChangeReportService,
  withPaginationUtils,
  withSecurityService,
  connect(mapState),
)

MonthlyFteChangeContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

export default enhance(MonthlyFteChangeContainer)
