import { createSelector } from 'reselect'

import i18n from 'translations'

export const getMembershipInvitationSelector = (state) => state.memberships.invitation

export const getMembershipInvitationErrorSelector = createSelector([getMembershipInvitationSelector], (invitation) => {
  if (invitation.error) {
    return invitation.error.message
      ? invitation.error.message.charAt(0).toUpperCase() + invitation.error.message.slice(1)
      : i18n.t('module:Staff:StaffAdd:ResendInvitation:error')
  }

  return null
})
