import { generateBasicActions } from 'utils/service'

import {
  CLEAR_ABSENCE_REASONS_LIST,
  LIST_ABSENCE_REASONS,
  LIST_ABSENCE_REASONS_FAILED,
  LIST_ABSENCE_REASONS_SUCCESS,
} from './constants'

export const list = (options = {}) => ({
  childAbsencesApiClient,
}) => generateBasicActions.list({
  constants: {
    failed: LIST_ABSENCE_REASONS_FAILED,
    init: LIST_ABSENCE_REASONS,
    success: LIST_ABSENCE_REASONS_SUCCESS,
  },
  options,
  service: childAbsencesApiClient,
  serviceMethod: 'list',
})

export const clearList = () => ({
  type: CLEAR_ABSENCE_REASONS_LIST,
})
