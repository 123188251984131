import ColumnChart from './ColumnChart'
import LineChart from './LineChart'
import PieChart from './PieChart'

interface ChartType {
  Column?: typeof ColumnChart
  Line?: typeof LineChart
  Pie?: typeof PieChart
}

const Chart: ChartType = () => null

Chart.Column = ColumnChart
Chart.Line = LineChart
Chart.Pie = PieChart

export default Chart
