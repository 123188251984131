import moment from 'moment'
import _ from 'lodash'

import React from 'react'

import { DEFAULT_DATE_FORMAT, WEEK_DAYS } from 'constants/date'
import { RolesDetails } from 'constants/security'
import { NEUTRAL_COLOURS } from 'constants/colors'
import { SHIFT_MAIN_TYPES } from 'services/legacy/membershipRegisters/constants'
import { CONTRACT_TYPE_OPTIONS_SHORT } from 'services/legacy/contracts/constants'

import {
  Avatar,
  DatePicker,
  EmptyState,
  InfiniteScroll,
  Page,
  SearchBar,
  Section,
  Space,
  Spinner,
  Table,
  Toolbar,
  Typography,
} from 'components'

import i18n from 'translations'

import { UserTableRowSummary } from 'module/Staff/components'
import { RecordContent } from './components'

import { StyledHeadTr, StyledTableWrapper } from './StaffWorklogStyled'

const StaffWorklogView = ({
  dateRange,
  isFetching,
  nurseryData,
  onExportClick,
  onPageChange,
  onSearchChange,
  onTableCellClick,
  onWeekChange,
  page,
  pageCount,
  records,
  search,
  totalResults,
  week,
}) => {
  const { nurserySettings } = nurseryData || {}
  const { formattedOpeningDays } = nurserySettings || {}

  const renderGrandTotals = (record) => {
    if (!record) {
      return null
    }

    const recordStatistics = {
      leave: 0,
      work: record.workedMilliseconds || 0,
    }

    const { leaves } = record
    recordStatistics.leave = _.reduce(
      leaves,
      (total, { endTime, startTime }) => total + (endTime - startTime),
      0,
    )

    return (
      <React.Fragment>
        {recordStatistics.work ? (
          <UserTableRowSummary
            label={i18n.t('services:MembershipRegisters:mainTypes:work')}
            time={recordStatistics.work}
            type={SHIFT_MAIN_TYPES.WORK}
          />
        ) : null}
        {recordStatistics.leave ? (
          <UserTableRowSummary
            label={i18n.t('services:MembershipRegisters:mainTypes:leave')}
            time={recordStatistics.leave}
            type={SHIFT_MAIN_TYPES.LEAVE}
          />
        ) : null}
      </React.Fragment>
    )
  }

  const renderHeader = () => (
    <React.Fragment>
      <SearchBar
        placeholder={i18n.t('module:Staff:StaffWorklog:searchForYourself')}
        value={search}
        variant="standard"
        noBackground
        onChange={onSearchChange}
      />
      <Space space="5px" />
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <DatePicker
              type="week"
              value={dateRange}
              range
              onChange={onWeekChange}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
    </React.Fragment>
  )

  const renderTableHeader = (isSticky) => (
    <StyledHeadTr isSticky={isSticky}>
      <Table.Th align="left" background={NEUTRAL_COLOURS.WHITE} width="15%">
        <React.Fragment>
          <Typography>
            &nbsp;
          </Typography>
          <Typography fontSize={14}>
            {`${totalResults} ${i18n.t('global:staffMember')}`}
          </Typography>
        </React.Fragment>
      </Table.Th>
      {_.map(_.values(WEEK_DAYS), (weekName, index) => (
        <Table.Th align="left" background={NEUTRAL_COLOURS.WHITE} key={`header_${weekName}`} width="12%">
          <React.Fragment>
            <Typography transform="uppercase" variant="span" bold>
              {moment(week).add(index, 'days').format('ddd')}
            </Typography>
            <Space margin="0 5px 0 0" inline />
            <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={17} variant="span">
              {moment(week).add(index, 'days').format('DD')}
            </Typography>
            {!formattedOpeningDays?.includes(
              moment(week).add(index, 'days').format('dddd').toLowerCase(),
            ) ? (
              <React.Fragment>
                <Space margin="3px 0 0" />
                <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
                  {i18n.t('module:Staff:StaffWorklog:nurseryClosed')}
                </Typography>
              </React.Fragment>
              ) : (
                <Space margin="19px 0 0" />
              )}
          </React.Fragment>
        </Table.Th>
      ))}
    </StyledHeadTr>
  )

  const renderTableRows = () => {
    const renderRows = () => _.map(records, (record) => {
      const {
        currentContract,
        currentRegisters,
        firstName,
        id,
        leaves,
        name,
        photo,
        roles,
        surname,
      } = record
      const { type: contractType } = currentContract || {}

      const rolesLabels = _.map(roles, (role) => RolesDetails[role]?.label)
      const contractTypeLabel = _.find(CONTRACT_TYPE_OPTIONS_SHORT, { value: contractType })?.label

      return (
        <Table.Tr key={id}>
          <Table.Td
            align="left"
            background={NEUTRAL_COLOURS.GRAY_QUATERNARY}
            style={{ cursor: 'default', padding: '10px' }}
            verticalAlign="top"
          >
            <Avatar
              avatarSize="medium"
              gap={15}
              initials={[firstName, surname]}
              src={photo}
              subTitle={(
                <React.Fragment>
                  <Space margin="2px" />
                  <Typography
                    color={NEUTRAL_COLOURS.GRAY}
                    fontSize={14}
                  >
                    {rolesLabels}
                  </Typography>
                  <Space margin="2px" />
                  <Typography
                    color={NEUTRAL_COLOURS.GRAY}
                    fontSize={14}
                  >
                    {contractTypeLabel}
                  </Typography>
                </React.Fragment>
              )}
              title={(
                <Typography>
                  {name}
                </Typography>
              )}
            />
            {renderGrandTotals(record)}
          </Table.Td>
          {_.map(_.values(WEEK_DAYS), (weekName, index) => {
            const currentDate = moment(week).add(index, 'days').toDate()

            const worklogItems = _.filter(currentRegisters, ({ endDate, startDate }) => ((
              moment(startDate).format(DEFAULT_DATE_FORMAT) <= moment(currentDate).format(DEFAULT_DATE_FORMAT)
              && moment(endDate).format(DEFAULT_DATE_FORMAT) >= moment(currentDate).format(DEFAULT_DATE_FORMAT)
            )))

            const minDate = _.minBy(worklogItems, 'startDate')?.startDate || currentDate
            const endDate = moment(currentDate).format(DEFAULT_DATE_FORMAT)
            const startDate = moment(minDate).format(DEFAULT_DATE_FORMAT)
            const isFutureDay = endDate > moment().format(DEFAULT_DATE_FORMAT)

            return (
              <Table.Td
                align="left"
                borderColorLeft={NEUTRAL_COLOURS.GRAY_TERTIARY}
                borderColorRight={WEEK_DAYS.SUNDAY === weekName
                  ? NEUTRAL_COLOURS.GRAY_SECONDARY
                  : NEUTRAL_COLOURS.GRAY_TERTIARY}
                style={{ cursor: isFutureDay ? 'no-drop' : 'pointer' }}
                verticalAlign="top"
                onClick={!isFutureDay ? () => onTableCellClick({ endDate, membershipId: id, startDate }) : null}
              >
                <RecordContent
                  currentDate={currentDate}
                  leaves={leaves}
                  week={week}
                  worklogItems={worklogItems}
                />
              </Table.Td>
            )
          })}
        </Table.Tr>
      )
    })

    return (
      <tbody>
        {renderRows()}
      </tbody>
    )
  }

  const renderTable = () => (
    <StyledTableWrapper>
      <Table
        renderHeader={renderTableHeader}
        visualType="border"
      >
        {renderTableRows()}
      </Table>
    </StyledTableWrapper>
  )

  const renderContent = () => {
    if (isFetching && 1 === page) {
      return (
        <Spinner />
      )
    }

    if (!isFetching && !records?.length) {
      return (
        <EmptyState
          icon="staff"
          text1={i18n.t('module:Staff:StaffWorklog:staffEmptyResults')}
        />
      )
    }

    return (
      <InfiniteScroll
        dataLength={records ? records.length : 0}
        hasMore={page < pageCount}
        next={() => onPageChange((+page) + 1)}
      >
        {renderTable()}
      </InfiniteScroll>
    )
  }

  const actions = <Section.Actions options={[{ onClick: onExportClick, type: 'export' }]} />

  return (
    <Page.Section
      actions={actions}
      isLoading={isFetching && 1 === page}
      title={i18n.t('module:Staff:StaffWorklog:title')}
    >
      {renderHeader()}
      {renderContent()}
    </Page.Section>
  )
}

export default StaffWorklogView
