import React from 'react'

import { Callout, Page, Section, Spinner } from 'components'

import AddDiscountForm from './components/AddDiscountForm'

const ManagementDiscountAddV2View = ({
  discountTypeOptions,
  errorMessages,
  formValues,
  initialValues,
  isArchived,
  isEdit,
  isFormLoading,
  isLoading,
  isPercentageSelected,
  nurseryOptions,
  onArchiveClick,
  onSubmit,
}) => {
  const renderContent = () => {
    if (isLoading || (isEdit && !initialValues)) {
      return (
        <Spinner />
      )
    }

    return (
      <AddDiscountForm
        discountTypeOptions={discountTypeOptions}
        formValues={formValues}
        initialValues={initialValues}
        isEdit={isEdit}
        isLoading={isFormLoading}
        isPercentageSelected={isPercentageSelected}
        nurseryOptions={nurseryOptions}
        onSubmit={onSubmit}
      />
    )
  }

  const title = isEdit ? 'Edit a discount' : 'Add a discount'

  const actions = isEdit && (
    <Section.Actions
      options={[{
        onClick: onArchiveClick(!isArchived),
        type: isArchived ? 'unarchive' : 'archive',
      }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading || (isEdit && !initialValues)}
      title={title}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default ManagementDiscountAddV2View
