import moment from 'moment'
import _ from 'lodash'

import React from 'react'
import { Field, FieldArray, reduxForm } from 'redux-form'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { AVAILABLE_DAYS } from 'services/legacy/membershipRegisters/constants'

import { isRequired } from 'utils/fieldValidation'

import { Banner, Button, CircleIcon, DropdownMenu, FooterActions, Form, Space, Tooltip, Typography } from 'components'

import i18n from 'translations'

import { SHIFT_MODAL_MODE } from '../../ShiftModalContainer'
import ShiftModalFormExtraFields from './ShiftModalFormExtraFields'
import ShiftModalFormFieldArray from './ShiftModalFormFieldArray'

import {
  StyledCustomTable,
  StyledCustomTableWrapper,
  StyledDay,
  StyledRowExtraWrapper,
  StyledRowHeader,
  StyledRowWrapper,
  StyledTooltipContainer,
} from './ShiftModalFormStyled'

export const STAFF_ROTA_SHIFT_MODAL_FORM = 'ShiftModalForm'

const renderDate = (date, weekIndex) => (
  <StyledDay>
    <Typography transform="uppercase" variant="span" bold>
      {moment(date).add('day', weekIndex).format('ddd')}
    </Typography>
    {' '}
    <Typography fontSize={17} variant="span">
      {moment(date).add('day', weekIndex).format('DD')}
    </Typography>
  </StyledDay>
)

const ShiftModalForm = ({
  attendanceView,
  contracts,
  currentRegisters,
  date: selectedDate,
  disablePastWeek,
  formValues,
  getPreviousRecord,
  handleSubmit,
  isEditMode,
  isQualifiedExpert,
  isSubmitting,
  leaves,
  memberDetails,
  mode,
  onChangeField,
  onChangeRecord,
  onChangeType,
  onCloseClick,
  onCopyToAll,
  onDeleteAll,
  onSetEndTime,
  onShowChangeLog,
  onSubmit,
  roomsOptions,
  shiftTimesErrors,
  statistics,
}) => {
  const isPastWeek = (() => {
    if (mode === SHIFT_MODAL_MODE.ACTUAL) {
      return false
    }

    const date = selectedDate?.[0]

    return !(
      (
        moment(date).week() >= moment().week()
        && moment(date).year() === moment().year()
      ) || moment(date).year() > moment().year()
    )
  })()

  const renderTable = () => (
    <StyledCustomTableWrapper>
      {_.times(AVAILABLE_DAYS, (weekIndex) => {
        const date = moment(selectedDate[0]).add('day', weekIndex).format(DEFAULT_DATE_FORMAT)
        const isFuture = 0 < moment(date).diff(moment(moment().format(DEFAULT_DATE_FORMAT)), 'days')

        return (
          <StyledCustomTable key={weekIndex}>
            {!weekIndex && (
              <StyledRowWrapper mode={mode}>
                <StyledRowHeader>
                  <Typography>
                    {_.upperFirst(i18n.t('global:day'))}
                  </Typography>
                </StyledRowHeader>
                <StyledRowHeader>
                  <Typography>
                    {_.upperFirst(i18n.t('global:activity'))}
                  </Typography>
                </StyledRowHeader>
                <StyledRowHeader>
                  <Typography>
                    {_.upperFirst(i18n.t('global:location'))}
                  </Typography>
                </StyledRowHeader>
                <StyledRowHeader colspan={2}>
                  <Typography>
                    {_.upperFirst(i18n.t('global:Duration'))}
                  </Typography>
                </StyledRowHeader>
                {mode === SHIFT_MODAL_MODE.PLANNED && (
                  <StyledRowHeader>
                    <StyledTooltipContainer>
                      <Tooltip
                        placement="bottom"
                        title={i18n.t('module:Modals:Staff:Shift:staffChildRatioTooltip')}
                      >
                        <CircleIcon
                          cursor="pointer"
                          icon="info"
                          iconSize={14}
                          size={20}
                        />
                      </Tooltip>
                      {i18n.t('module:Modals:Staff:Shift:staffChildRatio')}
                    </StyledTooltipContainer>
                  </StyledRowHeader>
                )}
                <StyledRowHeader>
                  <Typography align="right">
                    {i18n.t('module:Modals:Staff:Shift:workTotal')}
                  </Typography>
                </StyledRowHeader>
                <StyledRowHeader center>
                  <DropdownMenu
                    disabled={(isPastWeek && disablePastWeek) || isSubmitting}
                    small
                  >
                    <DropdownMenu.Item
                      icon="trash"
                      label={i18n.t('global:deleteAll')}
                      onClick={onDeleteAll}
                    />
                  </DropdownMenu>
                </StyledRowHeader>
              </StyledRowWrapper>
            )}
            {isFuture && mode === SHIFT_MODAL_MODE.ACTUAL
              ? (
                <StyledRowExtraWrapper>
                  {renderDate(selectedDate[0], weekIndex)}
                  <StyledDay>
                    {i18n.t('module:Modals:Staff:Shift:cannotAddToFutureDays')}
                  </StyledDay>
                </StyledRowExtraWrapper>
              )
              : (
                <FieldArray
                  component={ShiftModalFormFieldArray}
                  name={`membershipShiftTimes.${date}`}
                  props={{
                    date,
                    disablePastWeek,
                    isPastWeek,
                    isQualifiedExpert,
                    isSubmitting,
                    mode,
                    onChangeField,
                    onChangeRecord,
                    onChangeType,
                    onCopyToAll,
                    onSetEndTime,
                    onShowChangeLog,
                    roomsOptions,
                    selectedDate,
                    weekIndex,
                  }}
                />
              )}
            <ShiftModalFormExtraFields
              attendanceView={attendanceView}
              contracts={contracts}
              currentRegisters={currentRegisters}
              date={date}
              formValues={formValues}
              getPreviousRecord={getPreviousRecord}
              leaves={leaves}
              memberDetails={memberDetails}
              mode={mode}
              shiftTimesErrors={shiftTimesErrors}
              statistics={statistics}
            />
          </StyledCustomTable>
        )
      })}
    </StyledCustomTableWrapper>
  )

  const renderContent = () => (
    <React.Fragment>
      {isPastWeek && disablePastWeek && (
        <Banner.Warning margin="-10px 0 10px">
          {i18n.t('module:Staff:StaffRota:pastWeekBanner')}
        </Banner.Warning>
      )}
      {renderTable()}
      {mode === SHIFT_MODAL_MODE.ACTUAL && isEditMode && (
        <React.Fragment>
          <Space space="35px" />
          <Form.Row
            label={(
              <React.Fragment>
                <Typography variant="span" bold>
                  {i18n.t('module:Modals:Staff:Shift:reason')}
                </Typography>
                <Space margin="0 2.5px" inline />
                <Typography color={NEUTRAL_COLOURS.GRAY} variant="span">
                  {'('}
                  {i18n.t('module:Modals:Staff:Shift:reasonSubtitle')}
                  {')'}
                </Typography>
              </React.Fragment>
            )}
            required
            verticalLabel
          >
            <Form.Row.FlexItem flex="1">
              <Field
                component={Form.TextAreaField}
                disabled={isSubmitting}
                name="reason"
                placeholder={i18n.t('module:Modals:Staff:Shift:reasonPlaceholder')}
                validate={isRequired}
              />
            </Form.Row.FlexItem>
          </Form.Row>
        </React.Fragment>
      )}
    </React.Fragment>
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {renderContent()}
      <FooterActions spaceBetween>
        <FooterActions.Item>
          <Button
            disabled={isSubmitting}
            hierarchy="tertiary"
            label={i18n.t('global:cancel')}
            negativeMargins
            onClick={onCloseClick}
          />
        </FooterActions.Item>
        <FooterActions.Flex />
        <React.Fragment>
          {!attendanceView && (
            <FooterActions.Item>
              <Field
                component={Form.Checkbox}
                disabled={(isPastWeek && disablePastWeek) || isSubmitting}
                label={i18n.t('module:Modals:Staff:Shift:copyToOtherWeeks')}
                name="copy"
              />
            </FooterActions.Item>
          )}
          <FooterActions.Item>
            <Button
              disabled={isPastWeek && disablePastWeek}
              isLoading={isSubmitting}
              label={isEditMode || attendanceView
                ? i18n.t('global:Update')
                : i18n.t('module:Modals:Staff:Shift:assignShift')}
              negativeMargins
              submit
            />
          </FooterActions.Item>
        </React.Fragment>
      </FooterActions>
    </Form>
  )
}

export default reduxForm({ form: STAFF_ROTA_SHIFT_MODAL_FORM })(ShiftModalForm)
