import React, { useMemo } from 'react'

import { generateRoute } from 'utils/routing'

import { EmptyState, Page, Pagination, Section, Spinner, Table } from 'components'

import i18n from 'translations'

import { getColumns, getTransPrefix } from './helpers'

import { TYPE_TO_ROUTE_URL_MAPPINGS } from './constants'

const CommonView = ({
  enquirySettings,
  isLoading,
  onPageChange,
  onSortChange,
  page,
  pageCount,
  perPage,
  sortField,
  sortOrder,
  totalResults,
  type,
}) => {
  const columns = useMemo(() => getColumns(type), [type])

  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t(`${getTransPrefix(type)}:plural`)}
      titleSingular={i18n.t(`${getTransPrefix(type)}:singular`)}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const actions = (
    <Section.Actions
      primary={[{
        label: i18n.t('global:Create'),
        to: generateRoute('MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_COMMON.ADD', {
          type: TYPE_TO_ROUTE_URL_MAPPINGS[type],
        }),
      }]}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!isLoading && !enquirySettings?.length) {
      return (
        <EmptyState
          icon="enquiries"
          text1={i18n.t('module:Enquiries:List:emptyState')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={columns}
          data={enquirySettings}
          sortField={sortField}
          sortOrder={sortOrder}
          visualType="transparent"
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title={i18n.t(`${getTransPrefix(type)}:title`)}
    >
      {renderContent()}
    </Page.Section>
  )
}

export default CommonView
