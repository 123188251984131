import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import { NurseryItemProduct } from '../models'

import {
  CLEAR_NURSERY_ITEM_SINGLE,
  CREATE_NURSERY_ITEM,
  CREATE_NURSERY_ITEM_FAILED,
  CREATE_NURSERY_ITEM_SUCCESS,
  GET_NURSERY_ITEM,
  GET_NURSERY_ITEM_FAILED,
  GET_NURSERY_ITEM_SUCCESS,
  UPDATE_NURSERY_ITEM,
  UPDATE_NURSERY_ITEM_FAILED,
  UPDATE_NURSERY_ITEM_SUCCESS,
} from './constants'

export const get = (options: BasicActionOptionsProps) => ({ nurseryItemApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: GET_NURSERY_ITEM_FAILED,
      init: GET_NURSERY_ITEM,
      success: GET_NURSERY_ITEM_SUCCESS,
    },
    options,
    service: nurseryItemApiClient,
    serviceMethod: 'get',
  })
)

export const create = (
  options: BasicActionOptionsProps<NurseryItemProduct>,
) => ({ nurseryItemApiClient }) => generateBasicActions.create({
  constants: {
    failed: CREATE_NURSERY_ITEM_FAILED,
    init: CREATE_NURSERY_ITEM,
    success: CREATE_NURSERY_ITEM_SUCCESS,
  },
  options,
  service: nurseryItemApiClient,
  serviceMethod: 'create',
})

export const update = (
  options: BasicActionOptionsProps<NurseryItemProduct>,
) => ({ nurseryItemApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_NURSERY_ITEM_FAILED,
    init: UPDATE_NURSERY_ITEM,
    success: UPDATE_NURSERY_ITEM_SUCCESS,
  },
  options,
  service: nurseryItemApiClient,
  serviceMethod: 'update',
})

export const clear = () => ({
  type: CLEAR_NURSERY_ITEM_SINGLE,
})
