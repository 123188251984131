export const GET_ACTIVITY = 'GET_ACTIVITY'
export const GET_ACTIVITY_SUCCESS = 'GET_ACTIVITY_SUCCESS'
export const GET_ACTIVITY_FAILED = 'GET_ACTIVITY_FAILED'

export const ADD_ACTIVITY = 'ADD_ACTIVITY'
export const ADD_ACTIVITY_SUCCESS = 'ADD_ACTIVITY_SUCCESS'
export const ADD_ACTIVITY_FAILED = 'ADD_ACTIVITY_FAILED'

export const REMOVE_ACTIVITY = 'REMOVE_ACTIVITY'
export const REMOVE_ACTIVITY_SUCCESS = 'REMOVE_ACTIVITY_SUCCESS'
export const REMOVE_ACTIVITY_FAILED = 'REMOVE_ACTIVITY_FAILED'

export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY'
export const UPDATE_ACTIVITY_SUCCESS = 'UPDATE_ACTIVITY_SUCCESS'
export const UPDATE_ACTIVITY_FAILED = 'UPDATE_ACTIVITY_FAILED'

export const SET_TEMPORARY_ACTIVITY_ID = 'SET_TEMPORARY_ACTIVITY_ID'
