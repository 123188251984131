import _ from 'lodash'

import { INTERVAL_PERIOD_TYPE, INTERVAL_PERIOD_TYPE_OPTIONS } from './constants'

const getValue = (value) => {
  if (!value || !value.length) {
    return [{}]
  }

  return _.map(value, ({ intervalPeriodDays, message }) => {
    const intervalPeriodTypeOption = _.find(INTERVAL_PERIOD_TYPE_OPTIONS, {
      value: 0 < intervalPeriodDays ? INTERVAL_PERIOD_TYPE.BEFORE : INTERVAL_PERIOD_TYPE.AFTER,
    })

    return {
      intervalPeriodDays: Math.abs(intervalPeriodDays),
      intervalPeriodType: intervalPeriodTypeOption,
      message,
    }
  })
}

export const getInitialValues = ({ globalId, globalValue, id, value }) => ({
  [globalId]: globalValue,
  [id]: getValue(value),
})

export const getPayload = (reminders) => _.map(reminders, (reminder) => {
  const { intervalPeriodDays, intervalPeriodType, message } = reminder

  return {
    intervalPeriodDays: (
      intervalPeriodType.value === INTERVAL_PERIOD_TYPE.AFTER
        ? `-${intervalPeriodDays}`
        : `+${intervalPeriodDays}`
    ),
    message,
  }
})
