import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as financeBalanceReportSelectors from './selectors'

const withFinanceBalanceReportService = (WrappedComponent) => {
  const mapState = ({ financeBalanceReport }) => ({ financeBalanceReport })

  const mapDispatch = injectActions('financeBalanceReportActions', actions)

  const Component = ({ financeBalanceReport, financeBalanceReportActions, ...others }) => (
    <WrappedComponent
      financeBalanceReportActions={financeBalanceReportActions}
      financeBalanceReportChildrenState={financeBalanceReport.children}
      financeBalanceReportNurseriesState={financeBalanceReport.nurseries}
      financeBalanceReportSelectors={financeBalanceReportSelectors}
      financeBalanceReportStatisticsState={financeBalanceReport.statistics}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withFinanceBalanceReportService
