import _ from 'lodash'

import React from 'react'

import { ABSENCE_TYPE, ABSENCE_TYPE_OPTIONS } from 'module/Register/constants'

import { Callout, EmptyState, Page, Spinner, TimeString } from 'components'

import RegisterList from 'module/Register/components/RegisterList'
import RegisterItem from 'module/Register/components/RegisterItem'

import ChildAttendanceHeader from './components/ChildAttendanceHeader'

const ChildAttendanceView = ({
  child,
  errorMessages,
  filters,
  isEmpty,
  isLoading,
  onDateRangeChange,
  onItemAbsenceClick,
  onItemAbsenceDeleteClick,
  onItemAbsenceEditClick,
  onItemPanelSaveClick,
  onItemResetClick,
  onItemSaveClick,
  onItemSignInClick,
  onItemSignOutClick,
  onItemStaffLogClick,
  onTypeChange,
  register,
  typeOptions,
}) => {
  const getItemSubtitle = (sessionsAttended) => {
    if (!sessionsAttended) {
      return null
    }

    return _.map(sessionsAttended, (item) => {
      const { endTime, nurserySession, startTime } = item
      const { id } = nurserySession

      return (
        <div key={id}>
          <TimeString date={startTime} local={false} />
          <span>
            {' '}
            -
            {' '}
          </span>
          <TimeString date={endTime} local={false} />
        </div>
      )
    })
  }

  const renderItem = (item) => {
    const {
      absence,
      comments,
      entryDate,
      errorMessage,
      id,
      isLoading: isItemLoading,
      isSubmitting,
      present,
      sessionsAttended,
      signIns,
    } = item

    const subtitle = getItemSubtitle(sessionsAttended)

    return (
      <RegisterList.Item key={id}>
        <RegisterItem
          absence={absence}
          absenceTypeConts={ABSENCE_TYPE}
          absenceTypeOptions={ABSENCE_TYPE_OPTIONS}
          child={child}
          comments={comments}
          date={entryDate}
          errorMessage={errorMessage}
          id={id}
          isLoading={isItemLoading}
          isSubmitting={isSubmitting}
          present={present}
          signIns={signIns}
          size="small"
          subtitle={subtitle}
          onAbsenceClick={onItemAbsenceClick}
          onAbsenceDeleteClick={onItemAbsenceDeleteClick}
          onAbsenceEditClick={onItemAbsenceEditClick}
          onPanelSaveClick={onItemPanelSaveClick}
          onResetClick={onItemResetClick}
          onSaveClick={onItemSaveClick}
          onSignInClick={onItemSignInClick}
          onSignOutClick={onItemSignOutClick}
          onStaffLogClick={onItemStaffLogClick}
        />
      </RegisterList.Item>
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (isEmpty) {
      return (
        <EmptyState
          icon="circle-tick"
          text1="There are no attendance records for the date range selected. Add register entries to view attendance."
        />
      )
    }

    return (
      <RegisterList>
        {_.map(register, renderItem)}
      </RegisterList>
    )
  }

  return (
    <Page.Section
      isLoading={isLoading}
      title="Attendance"
    >
      <Callout content={errorMessages} error />
      <ChildAttendanceHeader
        filters={filters}
        typeOptions={typeOptions}
        onDateRangeChange={onDateRangeChange}
        onTypeChange={onTypeChange}
      />
      {renderContent()}
    </Page.Section>
  )
}

export default ChildAttendanceView
