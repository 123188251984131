import { ACTIVITY_TYPE, LIST_TAB_NAMES } from 'services/legacy/enquiries/activities/constants'

import i18n from 'translations'

export const NOTE_ADD_FORM = 'NOTE_ADD_FORM'
export const EMAIL_ADD_FORM = 'EMAIL_ADD_FORM'
export const CALL_ADD_FORM = 'CALL_ADD_FORM'
export const VIEWING_ADD_FORM = 'VIEWING_ADD_FORM'

export const ACTIVITY_MAPPING = {
  [ACTIVITY_TYPE.CALL.value]: {
    formName: CALL_ADD_FORM,
    icon: 'enquiry-call',
    subtitle: i18n.t('module:Enquiries:List:Detail:Activity:List:ActivitySubtitle:call'),
  },
  [ACTIVITY_TYPE.EMAIL.value]: {
    formName: EMAIL_ADD_FORM,
    icon: 'enquiry-email',
    subtitle: i18n.t('module:Enquiries:List:Detail:Activity:List:ActivitySubtitle:email'),
  },
  [ACTIVITY_TYPE.NOTE.value]: {
    formName: NOTE_ADD_FORM,
    icon: 'enquiry-note',
    subtitle: i18n.t('module:Enquiries:List:Detail:Activity:List:ActivitySubtitle:note'),
  },
  [ACTIVITY_TYPE.VIEWING.value]: {
    formName: VIEWING_ADD_FORM,
    icon: 'enquiry-show-around',
  },
  [ACTIVITY_TYPE.STATUS_CHANGE.value]: {
    icon: 'enquiry-change-status',
  },
  [ACTIVITY_TYPE.CREATE.value]: {
    icon: 'enquiry-created',
  },
}

export const LIST_TAB_MAPPINGS = {
  [LIST_TAB_NAMES.CALLS]: {
    emptyState: i18n.t('module:Enquiries:List:Detail:Activity:List:Tabs:Calls:emptyState'),
    icon: 'enquiry-call',
  },
  [LIST_TAB_NAMES.EMAILS]: {
    emptyState: i18n.t('module:Enquiries:List:Detail:Activity:List:Tabs:Emails:emptyState'),
    icon: 'enquiry-email',
  },
  [LIST_TAB_NAMES.NOTES]: {
    emptyState: i18n.t('module:Enquiries:List:Detail:Activity:List:Tabs:Notes:emptyState'),
    icon: 'enquiry-note',
  },
  [LIST_TAB_NAMES.VIEWINGS]: {
    emptyState: i18n.t('module:Enquiries:List:Detail:Activity:List:Tabs:Viewings:emptyState'),
    icon: 'enquiry-show-around',
  },
}
