import React from 'react'

import { NEWS_STATUSES, TAB_NAMES } from 'services/legacy/newsletters/constants'

import { Page, Section, Spinner, Tabs } from 'components'

import i18n from 'translations'

import Overview from './components/Overview'
import Recipients from './components/Recipients'

const PreviewNewsletterView = ({
  isFetching,
  isTableDataFetching,
  nursery,
  onAddNewRecipients,
  onChangeTab,
  onGoToRecipients,
  onPageChange,
  onRemoveClick,
  onRemoveItemClick,
  onResendClick,
  onSearchChange,
  onSortChange,
  page,
  pageCount,
  previewData,
  recipientsTableData,
  recipientsTableTotalResults,
  sortField,
  sortOrder,
  tab,
}) => {
  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    const { status, title } = previewData || {}

    const actions = <Section.Actions options={[{ onClick: onRemoveClick, type: 'remove' }]} />

    return (
      <Section
        actions={actions}
        title={title}
        titleSecond={NEWS_STATUSES.DRAFT === status && i18n.t('module:Newsletters:Preview:titleSecond')}
      >
        <Tabs
          defaultPosition={tab}
          fullWidth={false}
          value={tab}
          onChange={onChangeTab}
        >
          <Tabs.Item name={TAB_NAMES.OVERVIEW} title={i18n.t('module:Newsletters:Preview:Overview:title')}>
            <Overview
              nursery={nursery}
              previewData={previewData}
              onGoToRecipients={onGoToRecipients}
            />
          </Tabs.Item>
          <Tabs.Item name={TAB_NAMES.RECIPIENTS} title={i18n.t('module:Newsletters:Preview:Recipients:title')}>
            <Recipients
              isFetching={isTableDataFetching}
              page={page}
              pageCount={pageCount}
              sortField={sortField}
              sortOrder={sortOrder}
              tableData={recipientsTableData}
              totalResults={recipientsTableTotalResults}
              onAddNewRecipients={onAddNewRecipients}
              onPageChange={onPageChange}
              onRemoveItemClick={onRemoveItemClick}
              onResendClick={onResendClick}
              onSearchChange={onSearchChange}
              onSortChange={onSortChange}
            />
          </Tabs.Item>
        </Tabs>
      </Section>
    )
  }

  return (
    <Page isLoading={isFetching}>
      <Page.Content>
        {renderContent()}
      </Page.Content>
    </Page>
  )
}

export default PreviewNewsletterView
