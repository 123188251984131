import React from 'react'
import { reduxForm } from 'redux-form'

import { Form } from 'components'

import FrameworkBulkMarkFields from './FrameworkBulkMarkFields'
import FrameworkBulkMarkPreview from './FrameworkBulkMarkPreview'

export const FRAMEWORK_BULK_MARK_FORM = 'FrameworkBulkMarkForm'

const FrameworkBulkMarkForm = ({
  ageBandOptions,
  areaOptions,
  currentStep,
  currentValues,
  groupOptions,
  handleSubmit,
  isLoading,
  itemsCount,
  onCloseClick,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    {!currentStep
      ? (
        <FrameworkBulkMarkFields
          ageBandOptions={ageBandOptions}
          areaOptions={areaOptions}
          groupOptions={groupOptions}
        />
      ) : (
        <FrameworkBulkMarkPreview
          currentValues={currentValues}
          itemsCount={itemsCount}
        />
      )}
    <Form.FooterActions
      cancelLabel={!currentStep ? 'Cancel' : 'Back'}
      isSubmitting={isLoading}
      submitLabel={!currentStep ? 'Next' : 'Bulk mark'}
      onCancelClick={onCloseClick}
    />
  </Form>
)

export default reduxForm({ form: FRAMEWORK_BULK_MARK_FORM })(FrameworkBulkMarkForm)
