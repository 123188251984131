import styled from 'styled-components'

import layout from 'constants/layout'

export const StyledToolbarContainer = styled.div`
  margin-left: -10px;
`

export const StyledRowContainer = styled.div`
  @media(max-width: ${layout.mobileWidthResolution}px) {
    width: 100%;
  }
`
