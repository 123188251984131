import _ from 'lodash'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { change, getFormValues } from 'redux-form'

import { ROLES } from 'constants/security'

import { withAppService } from 'services/app'
import { withUsersService } from 'services/users'
import { withSecurityService } from 'services/security'
import { withSnackbarService } from 'services/utils/snackbar'

import i18n from 'translations'

import ManagementBlossomboardView from './ManagementBlossomboardView'
import { MANAGEMENT_BLOSSOMBOARD_FORM } from './components/ManagementBlossomboardForm/ManagementBlossomboardForm'

class ManagementBlossomboardContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editMode: false,
    }
  }

  componentDidMount() {
    const { currentUser, usersActions } = this.props

    usersActions.getUserSettings(
      currentUser.user.id,
    )
  }

  changeEditMode = (editMode) => {
    this.setState({ editMode })
  }

  handleCancel = () => {
    const { changeValue, widgetsList } = this.props

    changeValue('settings', _.map(widgetsList, ({ enabled, widget }) => ({
      ...widget,
      enabled,
    })))

    this.setState({
      editMode: false,
    })
  }

  handleSubmitSuccess = () => setTimeout(() => {
    const { snackbarActions } = this.props

    this.setState({
      editMode: false,
    })

    snackbarActions.show({
      message: i18n.t('module:Management:ManagementBlossomboard:widgetSettingsSuccessfullyUpdated'),
    })
  })

  handleSubmit = (values) => {
    const { currentUser, nursery, userSettings, usersActions, usersSelectors } = this.props

    const body = usersSelectors.getUpdatedNurseryWidgetsValuesForm(
      values.settings,
      userSettings,
      nursery.id,
    )

    return usersActions.updateUserSettings(
      currentUser.user.id,
      body,
      this.handleSubmitSuccess,
    )
  }

  render() {
    const { formValues, isFetching, isSubmitting, widgetsList } = this.props
    const { editMode } = this.state

    return (
      <ManagementBlossomboardView
        editMode={editMode}
        formValues={formValues}
        isFetching={isFetching}
        isSubmitting={isSubmitting}
        widgets={widgetsList}
        onCancel={this.handleCancel}
        onEditMode={this.changeEditMode}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

ManagementBlossomboardContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.ROOM_LEADER,
    ROLES.SENIOR_TEACHER,
    ROLES.TEACHER,
  ],
}

const mapState = (state, { appSelectors, securitySelectors, usersSelectors, usersSettingsState }) => ({
  currentUser: securitySelectors.getAuthProfile(state),
  formValues: getFormValues(MANAGEMENT_BLOSSOMBOARD_FORM)(state),
  isFetching: appSelectors.getIsFetching(usersSettingsState),
  isSubmitting: appSelectors.getIsSubmitting(usersSettingsState),
  nursery: securitySelectors.getAuthNursery(state),
  userSettings: usersSelectors.getUserSettings(state),
  widgetsList: usersSelectors.getFullWidgetsList(state),
})

const mapDispatch = {
  changeValue: (field, value) => change(MANAGEMENT_BLOSSOMBOARD_FORM, field, value),
}

const enhance = compose(
  withAppService,
  withUsersService,
  withSnackbarService,
  withSecurityService,
  connect(mapState, mapDispatch),
)

export default enhance(ManagementBlossomboardContainer)
