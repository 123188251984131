import React from 'react'

import { WrappedComponentType } from 'constants/types'

import * as versionControlSelectors from './selectors'

export interface withVersionControlServiceProps {
  versionControlSelectors: typeof versionControlSelectors
}

const withModalService = <P extends withVersionControlServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const Component = ({ ...others }) => (
    <WrappedComponent
      versionControlSelectors={versionControlSelectors}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return Component
}

export default withModalService
