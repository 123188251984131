import { mergeData } from 'utils/data'

import {
  CREATE_BATCH_MEMBERSHIPS_SHIFTS,
  CREATE_BATCH_MEMBERSHIPS_SHIFTS_FAILED,
  CREATE_BATCH_MEMBERSHIPS_SHIFTS_SUCCESS,
  GET_MEMBERSHIPS_LIST_SHIFT,
  GET_MEMBERSHIPS_LIST_SHIFT_FAILED,
  GET_MEMBERSHIPS_LIST_SHIFT_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false,
}

export default (state = initialState, { error, mergeResult, payload, type }) => {
  switch (type) {
    case GET_MEMBERSHIPS_LIST_SHIFT:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_MEMBERSHIPS_LIST_SHIFT_SUCCESS:
      return {
        ...initialState,
        data: mergeData(state.data, payload.data, mergeResult),
        isFetching: false,
        meta: payload.meta,
      }
    case GET_MEMBERSHIPS_LIST_SHIFT_FAILED:
      return { ...state, error, isFetching: false }
    case CREATE_BATCH_MEMBERSHIPS_SHIFTS:
      return { ...state, isSubmitting: true }
    case CREATE_BATCH_MEMBERSHIPS_SHIFTS_SUCCESS:
    case CREATE_BATCH_MEMBERSHIPS_SHIFTS_FAILED:
      return { ...state, isSubmitting: false }
    default:
      return state
  }
}
