import _ from 'lodash'
import moment from 'moment'
import parse from 'html-react-parser'

import React from 'react'

import { NEWS_STATUSES } from 'services/legacy/newsletters/constants'

import { GridLayout, MediaPicker, Section, Toolbar } from 'components'

import i18n from 'translations'

import { StyledContentContainer } from './OverviewStyled'
import { AvatarWithLabel, FilterDescription } from '../../../components'

const Overview = ({ nursery, onGoToRecipients, previewData }) => {
  const { author, content, createdAt, displayFilters, newsletterMedia, status } = previewData || {}
  const { name, photo } = author || {}
  const { logo, name: nurseryName } = nursery || {}

  return (
    <React.Fragment>
      <Toolbar bottomLine>
        <Toolbar.Group fullWidth>
          <Toolbar.Item>
            <GridLayout>
              <GridLayout.Group noVerticalMargin>
                <GridLayout.Item>
                  <AvatarWithLabel
                    initials={nurseryName && nurseryName.split(' ')}
                    label={i18n.t('module:Newsletters:Preview:Overview:fromLabel')}
                    name={nurseryName}
                    src={logo}
                  />
                </GridLayout.Item>
                <GridLayout.Item flex="none">
                  <AvatarWithLabel
                    initials={name && name.split(' ')}
                    label={i18n.t('module:Newsletters:Preview:Overview:authorLabel')}
                    name={name}
                    src={photo}
                  />
                </GridLayout.Item>
              </GridLayout.Group>
              <GridLayout.Group noVerticalMargin>
                <GridLayout.Item>
                  <FilterDescription
                    displayFilters={displayFilters}
                    onClick={onGoToRecipients}
                  />
                </GridLayout.Item>
                <GridLayout.Item alignItems="center" flex="none">
                  {NEWS_STATUSES.PUBLISHED === status
                    && `${i18n.t('module:Newsletters:Preview:Overview:published')}: ${moment(createdAt).format(
                      'DD/MM/YYYY, HH:mm',
                    )}`}
                </GridLayout.Item>
              </GridLayout.Group>
            </GridLayout>
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      <Toolbar>
        <Toolbar.Group fullWidth>
          <Toolbar.Item>
            <GridLayout>
              <GridLayout.Group noVerticalMargin>
                <GridLayout.Item>
                  {content && <StyledContentContainer>{parse(content)}</StyledContentContainer>}
                </GridLayout.Item>
              </GridLayout.Group>
            </GridLayout>
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      {newsletterMedia?.length ? (
        <Section title={i18n.t('module:Newsletters:Preview:Overview:attachedMediaTitle')}>
          <MediaPicker
            value={_.map(newsletterMedia, ({ medium }) => medium)}
            disableSticky
            disableTagging
            preview
          />
        </Section>
      ) : null}
    </React.Fragment>
  )
}

export default Overview
