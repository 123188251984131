import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { Form } from 'components'

import i18n from 'translations'

export const FORMATIVE_REPORTS_SEND_FORM = 'FormativeReportsSendForm'

const FormativeReportsSendForm = ({
  child,
  handleSubmit,
  isLoading,
  onCloseClick,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row label={i18n.t('module:Modals:FormativeReportsSendModal:Form:SendToParents:label')}>
      <Form.Row.FlexItem>
        <Field
          childId={child?.id}
          component={Form.InfiniteDropdowns.Carers}
          name="carers"
          placeholder={i18n.t('module:Modals:FormativeReportsSendModal:Form:SendToParents:placeholder')}
          multi
          parentalResponsibilityOnly
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.Row label={i18n.t('module:Modals:FormativeReportsSendModal:Form:SendToStaff:label')}>
      <Form.Row.FlexItem>
        <Field
          component={Form.InfiniteDropdowns.Memberships}
          name="memberships"
          placeholder={i18n.t('module:Modals:FormativeReportsSendModal:Form:SendToStaff:placeholder')}
          multi
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.FooterActions
      isSubmitting={isLoading}
      submitLabel={i18n.t('global:Send')}
      onCancelClick={onCloseClick}
    />
  </Form>
)

const validate = (values) => {
  const errors = {}
  if (values) {
    const { memberships = [], carers = [] } = values || {}

    if (!memberships?.length && !carers?.length) {
      errors.carers = ' '
      errors.memberships = i18n.t('module:Modals:FormativeReportsSendModal:Form:validationMessage')
    }
  }
  return errors
}

export default reduxForm({ form: FORMATIVE_REPORTS_SEND_FORM, validate })(FormativeReportsSendForm)
