import { createTypeFromEnumValues } from 'utils/typescript'

import { BasicListInitialState, BasicSingleInitialState } from 'utils/reducer'

import i18n from 'translations'

export enum INVOICE_LINE_ITEM_DISPLAY {
  INCLUDE_IN_SESSION = 'INCLUDE_IN_SESSION',
  SEPARATELY_FROM_SESSION = 'SEPARATELY_FROM_SESSION',
}

export const INVOICE_LINE_ITEM_DISPLAY_DROPDOWN = [
  {
    label: i18n.t('module:Management:Finance:Consumables:Add:InvoiceLineItemDisplay:IncludeInSession:label'),
    tooltip: i18n.t('module:Management:Finance:Consumables:Add:InvoiceLineItemDisplay:IncludeInSession:tooltip'),
    value: INVOICE_LINE_ITEM_DISPLAY.INCLUDE_IN_SESSION,
  },
  {
    label: i18n.t('module:Management:Finance:Consumables:Add:InvoiceLineItemDisplay:SeparatelyFromSession:label'),
    tooltip: i18n.t('module:Management:Finance:Consumables:Add:InvoiceLineItemDisplay:SeparatelyFromSession:tooltip'),
    value: INVOICE_LINE_ITEM_DISPLAY.SEPARATELY_FROM_SESSION,
  },
]

interface NurseryConsumableSettings {
  invoiceLineItemDisplay: createTypeFromEnumValues<INVOICE_LINE_ITEM_DISPLAY>
}

export interface NurseryConsumable {
  archived?: boolean
  archivedAt?: Date
  createdAt?: Date
  id?: number
  name?: string
  settings: NurseryConsumableSettings
  updatedAt?: Date
}

export interface NurseryConsumablesSingleState extends BasicSingleInitialState {
  data: NurseryConsumable
}

export interface NurseryConsumablesListState extends BasicListInitialState {
  data: NurseryConsumable[]
}

export interface NurseryConsumablesState {
  list: NurseryConsumablesListState
  single: NurseryConsumablesSingleState
}

export interface NurseryConsumablesRootState {
  nurseryConsumables: NurseryConsumablesState
}
