import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { DEFAULT_DATE_FORMAT, DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'
import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'
import { addMillisecondsFromMidnight } from 'utils/date'

import { EVENTS, logEvent } from 'analytics'

import { withAppService } from 'services/app'
import { withMembershipsLeavesService } from 'services/legacy/membershipsLeaves'
import { withMembershipsShiftsService } from 'services/legacy/membershipsShifts'
import { withModalService } from 'services/utils/modal'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'

import i18n from 'translations'

import StaffLeaveView from './StaffLeaveView'

export const DATE_FORMAT = 'MM-YYYY'

const GROUPS = {
  read: [
    'membershipShift.membershipShiftTimeStatistics',
    'membershipShift.membershipShiftTimes',
    'membershipShiftSettings',
    'membershipShiftTime',
    'membershipShiftTime.class',
    'membership.details',
    'membershipShift.leaveShiftType',
    'leaveShiftType',
  ],
}

class StaffLeaveContainer extends Component {
  constructor(props) {
    super(props)

    const { date, type, withLeave } = props.location.query

    this.state = {
      filters: {
        date: moment(date, DATE_FORMAT).isValid() ? moment(date, DATE_FORMAT) : moment(),
        search: null,
        type,
        withLeave: +withLeave || 0,
      },
    }
  }

  componentDidMount() {
    this.fetch()
  }

  fetch = (forcePage) => {
    const { membershipsLeavesActions, membershipsShiftsSelectors, paginationUtils, setLocationQuery } = this.props
    const { filters } = this.state
    const { date, search, type, withLeave } = filters
    const { page } = paginationUtils

    const copyFilters = { ...filters }
    delete copyFilters.search

    setLocationQuery({
      ...copyFilters,
      date: moment(filters.date, DATE_FORMAT).format(DATE_FORMAT),
    }, ['page'])

    const criteria = membershipsShiftsSelectors.getListCriteria({
      archived: false,
      dateFrom: moment(date, DATE_FORMAT).startOf('month').format(DEFAULT_DATE_FORMAT),
      dateTo: moment(date, DATE_FORMAT).endOf('month').format(DEFAULT_DATE_FORMAT),
      exists: !!type,
      leaveShiftType: type,
      search,
      withLeave,
    })

    membershipsLeavesActions.list({
      mergeResult: 1 !== (_.isNumber(forcePage) ? forcePage : page),
      params: {
        criteria,
        groups: GROUPS,
        page: _.isNumber(forcePage) ? forcePage : page,
      },
    })
  }

  handleChangeDate = (date) => {
    const { paginationUtils: { onPageChange } } = this.props

    this.setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        date,
      },
    }), () => onPageChange(this.fetch)(1))
  }

  handleChangeType = (type) => {
    const { paginationUtils: { onPageChange } } = this.props

    this.setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        type: type?.value,
      },
    }), () => onPageChange(this.fetch)(1))
  }

  handleChangeSearch = (search) => {
    const { paginationUtils: { onPageChange } } = this.props

    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        search,
      },
    }), () => onPageChange(this.fetch)(1))
  }

  handleChangeWithLeave = (withLeave) => {
    const { paginationUtils: { onPageChange } } = this.props

    this.setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        withLeave: +withLeave,
      },
    }), () => onPageChange(this.fetch)(1))
  }

  handleAddLeave = (membership, date) => {
    const { modalActions, modalConsts } = this.props

    logEvent(EVENTS.STAFF_LEAVE_ADD_BTN_CLICKED, { context: 'staff leave list' })

    const initialValues = {
      type: null,
    }

    if (membership) {
      initialValues.membership = {
        avatar: membership?.photo,
        label: membership?.name,
        value: membership?.id,
      }
    }

    if (date) {
      initialValues.date = [moment(date, DATE_FORMAT), moment(date, DATE_FORMAT)]
    }

    modalActions.show(modalConsts.TYPES.STAFF_LEAVE_ADD, {
      initialValues,
      onSuccess: () => {
        logEvent(EVENTS.STAFF_LEAVE_ADDED, { context: 'staff leave list' })

        this.handleChangePage(1)
      },
    })
  }

  handleChangePage = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleDeleteLeaveConfirmed = (leave) => {
    const { membershipsShiftsActions, modalActions, modalConsts, navigate } = this.props
    const { dateFrom, dateTo, id } = leave

    membershipsShiftsActions.remove(id, {
      onSuccess: () => this.handleChangePage(1),
    })

    const isGreaterOrSameThanToday = (
      moment(dateFrom).isSameOrAfter(moment(), 'days')
      || (moment(dateFrom).isSameOrBefore(moment(), 'days') && moment(dateTo).isSameOrAfter(moment(), 'days'))
    )

    if (isGreaterOrSameThanToday) {
      modalActions.show(modalConsts.TYPES.CONFIRM, {
        cancelButtonLabel: i18n.t('global:Skip'),
        confirmButtonLabel: i18n.t('module:Staff:StaffLeave:goToRota'),
        icon: 'warning',
        onConfirm: () => navigate(generateRoute('STAFF.ROTA')),
        text: i18n.t('module:Staff:StaffLeave:impactTheRota'),
      })
    }
  }

  handleDeleteLeave = (leave) => {
    const { modalActions, modalConsts } = this.props
    const { dateFrom, dateTo } = leave

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'trash',
      onConfirm: () => this.handleDeleteLeaveConfirmed(leave),
      text: `
        ${i18n.t('module:Staff:StaffLeave:deleteLeave')} 
        ${moment(dateFrom).format(DISPLAY_SHORT_DATE_FORMAT)} - ${moment(dateTo).format(DISPLAY_SHORT_DATE_FORMAT)}
      `,
    })
  }

  handleEditLeave = (membership, leave) => {
    const { modalActions, modalConsts } = this.props
    const { dateFrom, dateTo, leaveShiftType, membershipShiftTimes: membershipShiftTimesRaw, type } = leave
    const membershipShiftTimes = {}
    const daysDiffs = moment(dateTo).startOf('day')
      .diff(moment(dateFrom).startOf('day'), 'days') + 1

    _.each(membershipShiftTimesRaw, ({ day, endTime, id, startTime }) => {
      const date = moment(day).format(DEFAULT_DATE_FORMAT)

      if (!membershipShiftTimes[date]?.length) {
        membershipShiftTimes[date] = []
      }

      const item = {
        endTime: addMillisecondsFromMidnight(endTime, day),
        id,
        startTime: addMillisecondsFromMidnight(startTime, day),
      }

      membershipShiftTimes[date].push(item)
    })

    _.times(daysDiffs, (index) => {
      const date = moment(dateFrom).add(index, 'day').format(DEFAULT_DATE_FORMAT)

      if (!membershipShiftTimes[date]?.length) {
        membershipShiftTimes[date] = [{}]
      }
    })

    const initialValues = {
      date: [moment(dateFrom), moment(dateTo)],
      leaveShiftType: {
        label: leaveShiftType.name,
        value: leaveShiftType.id,
      },
      membership: {
        avatar: membership.photo,
        label: membership.name,
        value: membership.id,
      },
      membershipShiftTimes,
      type,
    }

    modalActions.show(modalConsts.TYPES.STAFF_LEAVE_ADD, {
      initialValues,
      leave,
      onSuccess: () => this.handleChangePage(1),
    })
  }

  handleExportClick = () => {
    const { modalActions, modalConsts } = this.props

    logEvent(EVENTS.STAFF_LEAVE_EXPORT_BTN_CLICKED, { context: 'staff leave list' })

    modalActions.show(modalConsts.TYPES.STAFF_LEAVE_EXPORT)
  }

  render() {
    const {
      StaffLeaveAddGranted,
      isFetching,
      paginationUtils,
      records,
      totalResults,
    } = this.props
    const { filters } = this.state
    const { date, type, withLeave } = filters
    const { getPageCount, page } = paginationUtils

    const pageCount = getPageCount(totalResults)

    return (
      <StaffLeaveView
        StaffLeaveAddGranted={StaffLeaveAddGranted}
        date={date}
        isFetching={isFetching}
        page={page}
        pageCount={pageCount}
        records={records}
        type={type}
        withLeave={withLeave}
        onAddLeave={this.handleAddLeave}
        onChangeDate={this.handleChangeDate}
        onChangePage={this.handleChangePage}
        onChangeSearch={this.handleChangeSearch}
        onChangeType={this.handleChangeType}
        onChangeWithLeave={this.handleChangeWithLeave}
        onDeleteLeave={this.handleDeleteLeave}
        onEditLeave={this.handleEditLeave}
        onExportClick={this.handleExportClick}
      />
    )
  }
}

StaffLeaveContainer.authParams = {
  flags: [FEATURE_FLAGS.STAFF_REGISTER],
  nurseryContext: true,
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

const mapState = (state, { appSelectors, membershipsLeavesListState, membershipsLeavesSelectors }) => ({
  StaffLeaveAddGranted: auth.SELECTORS.getIsAuthorised(state, {
    roles: [
      ROLES.SUPER_ADMIN,
      ROLES.ORGANIZATION_DIRECTOR,
      ROLES.ORGANIZATION_NATIONAL_ADMIN,
      ROLES.ORGANIZATION_FINANCE_ADMIN,
      ROLES.ORGANIZATION_LINE_MANAGER,
      ROLES.NURSERY_MANAGER,
      ROLES.NURSERY_ADMIN,
    ],
  }),
  isFetching: appSelectors.getIsFetching(
    membershipsLeavesListState,
  ),
  records: membershipsLeavesSelectors.getMembershipsLeavesListDataSelector(state),
  totalResults: appSelectors.getTotalResults(membershipsLeavesListState),
})

const enhance = compose(
  withAppService,
  withMembershipsLeavesService,
  withMembershipsShiftsService,
  withModalService,
  withPaginationUtils,
  withRouterUtils,
  connect(mapState),
)

export default enhance(StaffLeaveContainer)
