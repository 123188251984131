import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledHistoryItemWrapper = styled.div`
  padding: 7.5px 15px;

  ${({ last }) => !last && `
    border-bottom: 1px dashed ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  `}
`

export const StyledHistoryProgressDetailRow = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 30px auto;
  
  ${({ last }) => !last && `
    margin-bottom: 20px;
  `}
`

export const StyledStepsContainer = styled.div`
  margin: 5px -2.5px;
`

export const StyledStep = styled.div`
  background: ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  border-radius: 12px;
  padding: 7px 12px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  margin: 2.5px;
  display: inline-block;
`
