import styled from 'styled-components'

import { Link } from 'react-router'

import layout from 'constants/layout'

export const StyledSnackbarContainer = styled.div`
  * {
    font-family: ${layout.fontFamily} !important;
    font-size: 16px;
    box-shadow: none !important;
  }
  
  .webfont {
    font-family: 'webfont', sans-serif !important;
    cursor: pointer !important;
  }
  
  .MuiSnackbar-anchorOriginTopCenter {
    top: 0;
  }
  
  .MuiSnackbarContent-root {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    min-width: 0;
  }
`

export const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 20px auto;
  grid-gap: 10px;
  align-items: center;
`

export const StyledLink = styled(Link)`
  text-decoration: underline;
`
