import _ from 'lodash'

import React from 'react'

import { DEPOSIT_TYPE_OPTIONS } from 'services/legacy/deposits/constants'

import { generateRoute } from 'utils/routing'

import { getIsRefund } from 'services/legacy/deposits/single/selectors'

import { Avatar, Button, Currency, DateString } from 'components'

import i18n from 'translations'

import InvoiceStatus from '../components/InvoiceStatus'

const getEditLink = ({ id: depositId, params, type }) => {
  const { childId } = params

  const isRefund = getIsRefund(type)

  if (childId) {
    return isRefund
      ? generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.DEPOSITS.REFUND', { childId, depositId })
      : generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.DEPOSITS.EDIT', { childId, depositId })
  }

  return isRefund
    ? generateRoute('FINANCE.DEPOSITS.REFUND', { depositId })
    : generateRoute('FINANCE.DEPOSITS.EDIT', { depositId })
}

export const getTableData = ({ deposits, params }) => {
  if (!deposits?.length) {
    return null
  }

  return _.map(deposits, ({ resource: {
    amount,
    child,
    date,
    id,
    lastInvoice,
    originalDeposit,
    type,
    used,
    ...rest
  } }) => ({
    ...rest,
    amount: amount ? <Currency value={amount} /> : '-',
    child: (
      <Avatar
        avatarSize="small"
        initials={[child?.firstName, child?.surname]}
        src={child?.photo}
        title={`${child?.firstName} ${child?.surname}`}
        to={generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.DEPOSITS', { childId: child?.id })}
        primary
      />
    ),
    date: <DateString date={date} />,
    edit: (
      <Button.TableAction
        to={() => getEditLink({ id, params, type })}
      />
    ),
    invoiceStatus: (
      <InvoiceStatus
        childId={child?.id}
        lastInvoice={lastInvoice}
        originalDeposit={originalDeposit}
        type={type}
        used={used}
      />
    ),
    type: _.find(DEPOSIT_TYPE_OPTIONS, ['value', type])?.label,
  }))
}

export const getColumns = (isChildContext) => {
  const columns = [
    {
      align: 'left',
      field: 'date',
      sortable: true,
      title: i18n.t('module:Finance:Deposits:List:Table:Header:date'),
    },
    {
      align: 'left',
      field: 'type',
      sortable: true,
      title: i18n.t('module:Finance:Deposits:List:Table:Header:type'),
    },
    {
      align: 'left',
      field: 'description',
      sortable: true,
      title: i18n.t('global:Description'),
    },
    {
      align: 'right',
      field: 'amount',
      sortable: true,
      title: i18n.t('module:Finance:Deposits:List:Table:Header:amount'),
    },
    {
      align: 'left',
      field: 'invoiceStatus',
      title: i18n.t('module:Finance:Deposits:List:Table:Header:invoiceStatus'),
    },
    {
      field: 'edit',
    },
  ]

  if (!isChildContext) {
    columns.unshift({
      align: 'left',
      field: 'child',
      sortable: true,
      title: i18n.t('module:Finance:Deposits:List:Table:Header:child'),
    })
  }

  return columns
}
