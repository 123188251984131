import styled from 'styled-components'

export const StyledAutosizeInput = styled.div`
  position: relative;
`

export const StyledInput = styled.input`
  appearance: none;
  position: absolute;
  border: none;
  padding: 0;
  margin: 0;

  ${({ disabled }) => disabled && `
    cursor: not-allowed;

    &::-webkit-input-placeholder {
      color: #CCC;
    }
  `};
`

export const StyledSizer = styled.span`
  pointer-events: none;
  opacity: 0;
  display: inline-block;

  &:before {
    content: '\7C\a0\a0';
    display: inline-block;
    width: 0;
  }
`
