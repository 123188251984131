import React from 'react'

import { BasicViewProps } from 'constants/models'
import { NurseryAcademicYear } from 'services/nurseryAcademicYears/models'

import { generateRoute } from 'utils/routing'

import { Callout, EmptyState, Page, Pagination, Section, Spinner, Table } from 'components'
import { TableColumn } from 'components/Table'
import { PaginationProps } from 'components/Pagination/Pagination'
import { TableProps } from 'components/Table/Table'

import i18n from 'translations'

const COLUMNS: TableColumn[] = [
  {
    align: 'left',
    field: 'name',
    sortable: true,
    title: i18n.t('module:Management:ClosureDays:ClosureDates:Preview:Table:Header:closureDates'),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'startDate',
    title: i18n.t('module:Management:ClosureDays:ClosureDates:Preview:Table:Header:startDate'),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'endDate',
    title: i18n.t('module:Management:ClosureDays:ClosureDates:Preview:Table:Header:endDate'),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'closureDayType',
    title: i18n.t('module:Management:ClosureDays:ClosureDates:Preview:Table:Header:closureDayType'),
  },
]

interface PreviewViewProps extends BasicViewProps, PaginationProps, TableProps {
  nurseryAcademicYear?: NurseryAcademicYear
}

const PreviewView = ({
  errorMessages,
  isLoading,
  nurseryAcademicYear,
  onPageChange,
  onSortChange,
  page,
  pageCount,
  perPage,
  sortField,
  sortOrder,
  tableData,
  totalResults,
}: PreviewViewProps) => {
  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('global:pagination:entries')}
      titleSingular={i18n.t('global:pagination:entry')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!isLoading && !tableData?.length) {
      return (
        <EmptyState
          icon="blocked"
          text1={i18n.t('module:Management:ClosureDays:ClosureDates:emptyState')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={COLUMNS}
          data={tableData}
          sortField={sortField}
          sortOrder={sortOrder}
          visualType="transparent"
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const titlePrefix = i18n.t('module:Management:ClosureDays:ClosureDates:Add:Form:AcademicYear:label')

  return (
    <Page>
      <Page.Content>
        <Callout content={errorMessages} error />
        <Section
          actions={(
            <Section.Actions
              tertiary={{
                label: i18n.t('global:Edit'),
                to: generateRoute('MANAGEMENT.CLOSURE_DAYS.CLOSURE_DATES.EDIT', {
                  nurseryAcademicYearId: nurseryAcademicYear?.id,
                }),
              }}
            />
          )}
          title={`${titlePrefix} ${nurseryAcademicYear?.name}`}
        >
          {renderContent()}
        </Section>
      </Page.Content>
    </Page>
  )
}

export default PreviewView
