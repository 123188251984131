import React from 'react'

import { ModalBox } from 'components'

import i18n from 'translations'

import FundingLoopExportModalForm, { FundingLoopExportModalFormValues } from './components/FundingLoopExportModalForm'

interface FundingLoopExportModalViewProps {
  initialValues?: FundingLoopExportModalFormValues
  isSubmitting: boolean
  onCancelClick?: () => void
  onSubmit: (fields: FundingLoopExportModalFormValues) => void
}

const FundingLoopExportModalView: React.FC<FundingLoopExportModalViewProps> = ({
  initialValues,
  isSubmitting,
  onCancelClick,
  onSubmit,
}) => (
  <ModalBox
    maxWidth={400}
    title={i18n.t('module:Modals:FundingLoopExportModal:Form:title')}
    autoHeight
    onCloseClick={onCancelClick}
  >
    <FundingLoopExportModalForm
      initialValues={initialValues}
      isSubmitting={isSubmitting}
      onCancelClick={onCancelClick}
      onSubmit={onSubmit}
    />
  </ModalBox>
)

export default FundingLoopExportModalView
