import {
  AGED_DEBT_STATISTICS,
  AGED_DEBT_STATISTICS_FAILED,
  AGED_DEBT_STATISTICS_SUCCESS,
  CLEAR_AGED_DEBT_STATISTICS,
} from './constants'

export const getStatistics = (params, onSuccess) => ({ agedDebtApiClient }) => (dispatch) => {
  dispatch({
    type: AGED_DEBT_STATISTICS,
  })

  agedDebtApiClient.getStatistics(params).then(
    (data) => {
      dispatch({
        payload: data,
        type: AGED_DEBT_STATISTICS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => dispatch({
      error: response,
      type: AGED_DEBT_STATISTICS_FAILED,
    }),
  )
}

export const clearStatistics = () => ({
  type: CLEAR_AGED_DEBT_STATISTICS,
})
