import React from 'react'

import { UNASSIGNED_OPTION } from 'constants/dropdown'

import { EmptyState, InfiniteDropdowns, Page, Pagination, Section, Spinner, Table, Toolbar } from 'components'

import i18n from 'translations'

const KeyPersonsReportView = ({
  columns,
  isLoading,
  keyWorker = null,
  onKeyWorkerChange,
  onPageChange,
  onSendReport,
  onTableSortChange,
  page,
  pageCount,
  perPage,
  reports,
  sortField,
  sortOrder,
  totalResults,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('global:pagination:children')}
      titleSingular={i18n.t('global:pagination:child')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!reports || !reports.length) {
      if (keyWorker && 0 < +keyWorker) {
        return (
          <EmptyState
            icon="no-kp-assigned"
            text1="There are no children assigned to this Key Person."
          />
        )
      }

      return (
        <EmptyState
          icon="no-children"
          text1="There are no unassigned children."
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={columns}
          data={reports}
          sortField={sortField}
          sortOrder={sortOrder}
          onSortChange={onTableSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const actions = <Section.Actions options={[{ onClick: onSendReport, type: 'export' }]} />

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title="Key person list"
    >
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <InfiniteDropdowns.Users
              extraOptions={[UNASSIGNED_OPTION]}
              placeholder="Select worker"
              value={keyWorker}
              onChange={onKeyWorkerChange}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      {renderContent()}
    </Page.Section>
  )
}

export default KeyPersonsReportView
