import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { RootState } from 'core/reducers'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as periodsSelectors from './selectors'
import { PeriodsListState, PeriodsSingleState } from './models'

export interface withPeriodsServiceProps {
  periodsActions: typeof actions
  periodsListState: PeriodsListState
  periodsSelectors: typeof periodsSelectors
  periodsSingleState: PeriodsSingleState
}

const withPeriodsService = <P extends withPeriodsServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ periods }: RootState) => ({ periods })

  const mapDispatch = injectActions('periodsActions', actions)

  const Component = ({ periods, periodsActions, ...others }) => (
    <WrappedComponent
      periodsActions={periodsActions}
      periodsListState={periods.list}
      periodsSelectors={periodsSelectors}
      periodsSingleState={periods.single}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withPeriodsService
