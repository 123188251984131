import { Property } from 'csstype'

import React, { PropsWithChildren } from 'react'

import { VISUAL_TYPE } from 'components/Table/Table'

import { typeByObject } from 'utils/typescript'

import { CircleIcon, Tooltip } from 'components'

import { StyledTh, StyledTooltipWrapper } from './TableStyled'

interface TableThProps {
  align?: Property.TextAlign
  background?: string
  colSpan?: number
  isStickyVersion?: boolean
  onClick?: () => void
  style?: React.StyleHTMLAttributes<any>
  tooltip?: string
  visualType?: typeByObject<typeof VISUAL_TYPE>
  width?: string
}

const TableTh: React.FC<PropsWithChildren<TableThProps>> = ({
  align,
  background,
  children,
  colSpan,
  isStickyVersion,
  onClick,
  style,
  tooltip,
  visualType,
  width,
}) => {
  if (tooltip) {
    return (
      <StyledTh
        $align={align}
        $background={background}
        $isStickyVersion={isStickyVersion}
        $visualType={visualType}
        $width={width}
        colSpan={colSpan}
        style={style}
        onClick={onClick}
      >
        <StyledTooltipWrapper>
          <div>
            {children}
          </div>
          <Tooltip title={tooltip}>
            <CircleIcon
              cursor="pointer"
              icon="info"
              iconSize={16}
              size={24}
            />
          </Tooltip>
        </StyledTooltipWrapper>
      </StyledTh>
    )
  }

  return (
    <StyledTh
      $align={align}
      $background={background}
      $isStickyVersion={isStickyVersion}
      $visualType={visualType}
      $width={width}
      colSpan={colSpan}
      style={style}
      onClick={onClick}
    >
      {children}
    </StyledTh>
  )
}

export default TableTh
