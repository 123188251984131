import i18n from 'translations'

export const DOWNLOAD_PAYMENTS_TEMPLATE = 'DOWNLOAD_PAYMENTS_TEMPLATE'
export const DOWNLOAD_PAYMENTS_TEMPLATE_FAILED = 'DOWNLOAD_PAYMENTS_TEMPLATE_FAILED'
export const DOWNLOAD_PAYMENTS_TEMPLATE_SUCCESS = 'DOWNLOAD_PAYMENTS_TEMPLATE_SUCCESS'

export const IMPORT_PAYMENTS = 'IMPORT_PAYMENTS'
export const IMPORT_PAYMENTS_FAILED = 'IMPORT_PAYMENTS_FAILED'
export const IMPORT_PAYMENTS_SUCCESS = 'IMPORT_PAYMENTS_SUCCESS'

export const CLEAR_IMPORT_PAYMENTS = 'CLEAR_IMPORT_PAYMENTS'

export const ERROR_CODES = {
  AMOUNT_NEGATIVE: 400508,
  AMOUNT_WRONG_FORMAT: 400509,
  BILL_PAYER: 400510,
  CHILD_REFERENCE: 400505,
  DUPLICATE_WARNING: 100502,
  EXISTING_PAYMENTS: 100501,
  MANDATORY_FIELD: 400504,
  MANDATORY_FIELDS: 400503,
  PAYMENT_DATE: 400507,
  PAYMENT_METHOD: 400506,
  TABLE_HEADERS: 400502,
}

const FIELD_NAMES = {
  AMOUNT: 'amount',
  CHILD_REF: 'child.codeReference',
  PAYMENT_DATE: 'paymentDate',
  PAYMENT_TYPE: 'paymentType.name',
  SEND_RECEIPT: 'sendReceipt',
}

export const FIELD_NAMES_OPTIONS = [
  {
    label: i18n.t('module:Modals:PaymentsImport:Errors:FieldNames:amount'),
    value: FIELD_NAMES.AMOUNT,
  },
  {
    label: i18n.t('module:Modals:PaymentsImport:Errors:FieldNames:childRef'),
    value: FIELD_NAMES.CHILD_REF,
  },
  {
    label: i18n.t('module:Modals:PaymentsImport:Errors:FieldNames:paymentDate'),
    value: FIELD_NAMES.PAYMENT_DATE,
  },
  {
    label: i18n.t('module:Modals:PaymentsImport:Errors:FieldNames:paymentType'),
    value: FIELD_NAMES.PAYMENT_TYPE,
  },
  {
    label: i18n.t('module:Modals:PaymentsImport:Errors:FieldNames:sendReceipt'),
    value: FIELD_NAMES.SEND_RECEIPT,
  },
]
