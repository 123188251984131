import { CUSTOM_HEADERS, Request } from 'constants/http'

import { reportVersionMismatch } from 'services/versionControl/common/actions'

const VersionControlMiddleware = (currentVersion, dispatch) => async (request: Request, next) => {
  const response = await next(request)
  const responseVersion = response.headers.get(CUSTOM_HEADERS.VERSION)

  if (responseVersion && responseVersion?.toString() !== currentVersion.toString()) {
    const channel = new BroadcastChannel('sw-messages')

    channel.postMessage({ type: 'CLEAR_DROPDOWN_ITEMS_CACHE' })

    dispatch(reportVersionMismatch())
  }

  return response
}

export default VersionControlMiddleware
