import moment from 'moment'

import React from 'react'

import colors, { NEUTRAL_COLOURS } from 'constants/colors'

import { Avatar, Space, Typography } from 'components'

import { StyledHeader, StyledTime } from './StaffHeaderStyled'

const StaffHeader = ({
  currentDate,
  staffProfile,
}) => {
  const { firstName, name, photo, roleLabels, surname } = staffProfile || {}

  return (
    <StyledHeader>
      <Avatar
        gap={15}
        initials={[firstName, surname]}
        src={photo}
        subTitle={(
          <React.Fragment>
            <Space space="5px" />
            <Typography color={NEUTRAL_COLOURS.GRAY} variant="span">
              {roleLabels}
            </Typography>
          </React.Fragment>
        )}
        title={(
          <Typography fontSize={24} variant="span" bold>
            {name}
          </Typography>
        )}
      />
      <StyledTime>
        <Typography color={colors.gray} fontSize={22}>
          {moment(currentDate).format('ddd DD/MM/YYYY')}
        </Typography>
        <Typography fontSize={22} bold>
          {moment(currentDate).format('HH:mm')}
        </Typography>
      </StyledTime>
    </StyledHeader>
  )
}

export default StaffHeader
