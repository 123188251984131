import _ from 'lodash'

import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'

const getNurseries = (state: RootState) => state.nurseries

export const getNurseriesStatistics = createSelector(
  [getNurseries],
  (state) => state.statistics && state.statistics.data,
)

export const getStatistics = createSelector(
  [getNurseriesStatistics],
  (statistics) => {
    if (!statistics) {
      return null
    }

    const {
      nurseryBasicStatistics = {},
      nurseryFinanceStatistics = {},
      nurseryOperationStatistics = {},
    } = statistics

    return {
      ...nurseryBasicStatistics,
      ...nurseryFinanceStatistics,
      ...nurseryOperationStatistics,
    }
  },
)

export const getStatisticsSummary = createSelector(
  [getNurseriesStatistics],
  (statistics) => {
    if (!statistics) {
      return null
    }

    const {
      nurseryBasicStatistics = {},
      nurseryFinanceStatistics = {},
      nurseryOperationStatistics = {},
    } = statistics

    const {
      children,
      frameworks,
      nurseryInformation,
      parents,
      rooms,
      sessions,
      staff,
      termAndClosureDates,
    } = nurseryBasicStatistics

    const {
      consumables,
      funding,
      invoiceNumberSequence,
      invoiceSettings,
    } = nurseryFinanceStatistics

    const {
      authorisedDevices,
      confirmedRoomMoves,
      defaultRoomMoves,
    } = nurseryOperationStatistics

    const keysCount = (
      _.keys(nurseryBasicStatistics).length
      + _.keys(nurseryFinanceStatistics).length
      + _.keys(nurseryOperationStatistics).length
    )
    const completeCount = keysCount - _.filter({
      ...nurseryBasicStatistics,
      ...nurseryFinanceStatistics,
      ...nurseryOperationStatistics,
    }, (value) => !value).length
    const completePercentage = Math.floor((completeCount * 100) / keysCount)

    return {
      completePercentage,
      nurseryBasicStatistics: (
        !!children
        && !!frameworks
        && !!nurseryInformation
        && !!parents
        && !!rooms
        && !!sessions
        && !!staff
        && !!termAndClosureDates
      ),
      nurseryFinanceStatistics: !!consumables && !!funding && !!invoiceNumberSequence && !!invoiceSettings,
      nurseryOperationStatistics: !!authorisedDevices && !!confirmedRoomMoves && !!defaultRoomMoves,
    }
  },
)
