import React, { PropsWithChildren } from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

import { Drawer } from 'components'

import DetailsHeader from './CommentDrawerDetailsHeader'
import Footer from './CommentDrawerFooter'
import Form from './CommentDrawerForm'
import List from './CommentDrawerList'
import { StyledContainer } from './CommentDrawerStyled'

interface CommentDrawerRoot<T> extends React.FC<PropsWithChildren<T>> {
  DetailsHeader?: typeof DetailsHeader
  Footer?: typeof Footer
  Form?: typeof Form
  List?: typeof List
}

interface CommentDrawerProps{
  defaultOpen?: boolean
  footer?: React.ReactNode
  form?: React.ReactNode
  formEnabled?: boolean
  icon?: IconType
  list?: React.ReactNode
  title?: string
}

const CommentDrawer: CommentDrawerRoot<CommentDrawerProps> = ({
  defaultOpen,
  footer,
  form,
  formEnabled,
  icon = 'feedback',
  list,
  title,
}) => {
  const renderList = () => (
    <StyledContainer>
      {list}
      {footer}
    </StyledContainer>
  )
  const renderContent = () => (formEnabled ? form : renderList())

  return (
    <Drawer
      backgroundContent={NEUTRAL_COLOURS.WHITE}
      backgroundHeader={getBrandingColor('tertiary-color')}
      content={renderContent}
      defaultOpen={defaultOpen}
      icon={icon}
      position="right"
      title={title}
      widthOpen={340}
      closeContent
      disableAutoOpen
      withoutBackdrop
    />
  )
}

export default CommentDrawer
