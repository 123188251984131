import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { ROLES } from 'constants/security'

import auth from 'utils/auth'

import { withRouter } from 'services/router'
import { withNurseryContextService } from 'services/nurseryContext'

import ManagementFinanceSettingsView from './ManagementFinanceSettingsView'
import ManagementConsumablesWrapper from './ManagementConsumables/ManagementConsumablesWrapper'
import ManagementDiscountsWrapper from './ManagementDiscounts/v2/ManagementDiscountsV2Wrapper'
import ManagementItemsWrapper from './ManagementItems/ManagementItemsWrapper'
import ManagementFundingWrapper from './ManagementFunding/ManagementFundingWrapper'
import ManagementInvoicesV3Wrapper from './ManagementInvoices/V3/ManagementInvoicesV3Wrapper'
import ManagementPaymentTypesWrapper from './ManagementPaymentTypes/ManagementPaymentTypesWrapper'
// import ManagementNewFinanceContainer from './ManagementNewFinance/ManagementNewFinanceContainer'
import ManagementPaymentGateway from './ManagementPaymentGateway'
import ManagementAccountCodes from './ManagementAccountCodes'
import ManagementReferenceCodes from './ManagementReferenceCodes'
import ManagementSessions from './Sessions/V2'

const ManagementFinanceSettingsContainer = ({
  ManagementAccountCodesGranted,
  ManagementConsumablesGranted,
  ManagementDiscountsGranted,
  ManagementExtraItemsGranted,
  ManagementFundingGranted,
  ManagementInvoicesGranted,
  ManagementNewFinanceGranted,
  ManagementPaymentGatewayGranted,
  ManagementPaymentTypesGranted,
  ManagementReferenceCodesGranted,
  ManagementSessionsGranted,
  isFinanceV3Enabled,
  params,
}) => (
  <ManagementFinanceSettingsView
    ManagementAccountCodesGranted={ManagementAccountCodesGranted}
    ManagementConsumablesGranted={ManagementConsumablesGranted}
    ManagementDiscountsGranted={ManagementDiscountsGranted}
    ManagementExtraItemsGranted={ManagementExtraItemsGranted}
    ManagementFundingGranted={ManagementFundingGranted}
    ManagementInvoicesGranted={ManagementInvoicesGranted}
    ManagementNewFinanceGranted={ManagementNewFinanceGranted}
    ManagementPaymentGatewayGranted={ManagementPaymentGatewayGranted}
    ManagementPaymentTypesGranted={ManagementPaymentTypesGranted}
    ManagementReferenceCodesGranted={ManagementReferenceCodesGranted}
    ManagementSessionsGranted={ManagementSessionsGranted}
    isFinanceV3Enabled={isFinanceV3Enabled}
    params={params}
  />
)

ManagementFinanceSettingsContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

const mapState = (state) => ({
  ManagementAccountCodesGranted: auth.SELECTORS.getComponentIsAuthorised(state, ManagementAccountCodes),
  ManagementConsumablesGranted: auth.SELECTORS.getComponentIsAuthorised(state, ManagementConsumablesWrapper),
  ManagementDiscountsGranted: auth.SELECTORS.getComponentIsAuthorised(state, ManagementDiscountsWrapper),
  ManagementExtraItemsGranted: auth.SELECTORS.getComponentIsAuthorised(state, ManagementItemsWrapper),
  ManagementFundingGranted: auth.SELECTORS.getComponentIsAuthorised(state, ManagementFundingWrapper),
  ManagementInvoicesGranted: auth.SELECTORS.getComponentIsAuthorised(state, ManagementInvoicesV3Wrapper),
  // NOTE: This is to hide new finance toggle for MVP version
  ManagementNewFinanceGranted: false,
  // ManagementNewFinanceGranted: (
  //   !nurseryContextSelectors.getNurseryContext(state)?.organization?.newFinanceEnabled
  //   && (
  //     nurseryContextSelectors.getNurseryContext(state)?.newFinanceEnabled
  //     || auth.SELECTORS.getComponentIsAuthorised(state, ManagementNewFinanceContainer)
  //   )
  // ),
  ManagementPaymentGatewayGranted: auth.SELECTORS.getComponentIsAuthorised(state, ManagementPaymentGateway),
  ManagementPaymentTypesGranted: auth.SELECTORS.getComponentIsAuthorised(state, ManagementPaymentTypesWrapper),
  ManagementReferenceCodesGranted: auth.SELECTORS.getComponentIsAuthorised(state, ManagementReferenceCodes),
  ManagementSessionsGranted: auth.SELECTORS.getComponentIsAuthorised(state, ManagementSessions),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
})

const enhance = compose(
  withRouter,
  withNurseryContextService,
  connect(mapState),
)

export default enhance(ManagementFinanceSettingsContainer)
