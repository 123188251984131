import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as formativeReportsSelectors from './selectors'

const withFormativeReportsService = (WrappedComponent) => {
  const mapState = ({ formativeReports }) => ({ formativeReports })

  const mapDispatch = injectActions('formativeReportsActions', actions)

  const Component = ({ formativeReports, formativeReportsActions, ...others }) => (
    <WrappedComponent
      formativeReportsActions={formativeReportsActions}
      formativeReportsItemsState={formativeReports.items}
      formativeReportsListState={formativeReports.list}
      formativeReportsSelectors={formativeReportsSelectors}
      formativeReportsSingleState={formativeReports.single}
      formativeReportsState={formativeReports}
      formativeReportsTermsState={formativeReports.terms}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withFormativeReportsService

