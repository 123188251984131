import {
  CLEAR_ROOM_MOVE_LISTS,
  LIST_ROOM_MOVES,
  LIST_ROOM_MOVES_FAILED,
  LIST_ROOM_MOVES_SUCCESS,
  VALIDATE_ROOM_MOVES,
} from './constants'
import { validateRoomMove } from '../helpers'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: null,
}

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case LIST_ROOM_MOVES:
      return { ...state, error: initialState.error, isFetching: true }
    case LIST_ROOM_MOVES_SUCCESS: {
      const { recurrency, response: { data, meta } } = payload

      return {
        ...state,
        data: recurrency ? [...state.data || [], ...data] : data,
        isFetching: false,
        meta,
      }
    }
    case VALIDATE_ROOM_MOVES:
      return { ...state, data: validateRoomMove(state.data) }
    case LIST_ROOM_MOVES_FAILED:
      return { ...state, error: payload.error, isFetching: false }
    case CLEAR_ROOM_MOVE_LISTS:
      return initialState
    default:
      return state
  }
}
