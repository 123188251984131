export const LIST_GET_CHILD_ADHOC_SESSIONS = 'LIST_GET_CHILD_ADHOC_SESSIONS'
export const LIST_GET_CHILD_ADHOC_SESSIONS_SUCCESS = 'LIST_GET_CHILD_ADHOC_SESSIONS_SUCCESS'
export const LIST_GET_CHILD_ADHOC_SESSIONS_FAILED = 'LIST_GET_CHILD_ADHOC_SESSIONS_FAILED'

export const CLEAR_CHILD_ADHOC_SESSION_LIST = 'CLEAR_CHILD_ADHOC_SESSION_LIST'

export const listAdhocSessions = (
  childId,
  params,
  v2,
  mergeResult,
  onSuccess,
) => ({ childAdhocSessionsApiClient }) => (dispatch) => {
  dispatch({
    childId,
    type: LIST_GET_CHILD_ADHOC_SESSIONS,
  })
  childAdhocSessionsApiClient.list(childId, params, v2).then(
    (data) => {
      dispatch({
        mergeResult,
        payload: data,
        type: LIST_GET_CHILD_ADHOC_SESSIONS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: LIST_GET_CHILD_ADHOC_SESSIONS_FAILED,
      })
    },
  )
}

export const clearAdhocSession = () => ({
  type: CLEAR_CHILD_ADHOC_SESSION_LIST,
})
