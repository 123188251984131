import React from 'react'

import { BasicViewProps } from 'constants/models'

import { generateRoute } from 'utils/routing'

import { Callout, EmptyState, Page, Pagination, Section, Spinner, Table } from 'components'
import { PaginationProps } from 'components/Pagination/Pagination'
import { TableProps } from 'components/Table/Table'

import i18n from 'translations'
import { TableColumn } from 'components/Table'

const COLUMNS: TableColumn[] = [
  {
    align: 'left',
    field: 'academicYear',
    sortKey: 'name',
    sortable: true,
    title: i18n.t('module:Management:ClosureDays:ClosureDates:Table:Header:academicYear'),
  },
  {
    field: 'edit',
    width: '80px',
  },
]

interface ClosureDateViewProps extends BasicViewProps, PaginationProps, TableProps {}

const ClosureDatesView: React.FC<ClosureDateViewProps> = ({
  errorMessages,
  isLoading,
  onPageChange,
  onSortChange,
  page,
  pageCount,
  perPage,
  sortField,
  sortOrder,
  tableData,
  totalResults,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('global:pagination:entries')}
      titleSingular={i18n.t('global:pagination:entry')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!isLoading && !tableData?.length) {
      return (
        <EmptyState
          icon="enquiries"
          text1={i18n.t('module:Management:ClosureDays:ClosureDates:emptyState')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={COLUMNS}
          data={tableData}
          minWidth={0}
          sortField={sortField}
          sortOrder={sortOrder}
          visualType="transparent"
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  return (
    <Page>
      <Page.Content>
        <Callout content={errorMessages} error />
        <Section
          actions={(
            <Section.Actions
              primary={[{
                label: i18n.t('global:Add'),
                to: generateRoute('MANAGEMENT.CLOSURE_DAYS.CLOSURE_DATES.ADD'),
              }]}
            />
          )}
          title={i18n.t('module:Management:ClosureDays:ClosureDates:title')}
        >
          {renderContent()}
        </Section>
      </Page.Content>
    </Page>
  )
}

export default ClosureDatesView
