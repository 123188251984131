import _ from 'lodash'

import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'
import { WrappedComponentType } from 'constants/types'
import { Option } from 'constants/models'
import { ROLES } from 'constants/security'

import auth from 'utils/auth'

import { withAppService, withAppServiceProps } from 'services/app'
import { withPaginationUtils, withPaginationUtilsProps } from 'services/utils/pagination'
import { withSortUtilsProps, withSortingUtils } from 'services/utils/sorting'
import { withClosureDayTypesService, withClosureDayTypesServiceProps } from 'services/closureDayTypes'
import { withRouteUtilsProps, withRouterUtils } from 'services/utils/router'
import { withRouter, withRouterProps } from 'services/router'

import { CLOSURE_DAY_TYPE_FILTER, CLOSURE_DAY_TYPE_FILTER_OPTIONS } from 'services/closureDayTypes/models'
import { getTableData } from './ClosureDayTypesHelpers'
import ClosureDayTypesView from './ClosureDayTypesView'

type ClosureDayTypesContainerProps = withAppServiceProps
  & withPaginationUtilsProps
  & withSortUtilsProps
  & withRouteUtilsProps
  & withRouterProps
  & withClosureDayTypesServiceProps

const mapState = (state: RootState, {
  appSelectors,
  closureDayTypesListState,
  closureDayTypesSelectors,
}: ClosureDayTypesContainerProps) => ({
  closureTypeList: closureDayTypesSelectors.getClosureDayTypesList(state),
  errorMessages: appSelectors.getErrorMessages(closureDayTypesListState),
  isFetching: appSelectors.getIsFetching(closureDayTypesListState),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
  totalResults: appSelectors.getTotalResults(closureDayTypesListState),
})

const connector = connect(mapState)

  type PropsFromRedux = ConnectedProps<typeof connector>

const ClosureDayTypesContainer: WrappedComponentType<ClosureDayTypesContainerProps & PropsFromRedux> = ({
  closureDayTypesActions,
  closureDayTypesListState,
  closureDayTypesSelectors,
  errorMessages,
  isFetching,
  isFinanceV3Enabled,
  location,
  paginationUtils,
  setLocationQuery,
  sortingUtils,
  totalResults,
}) => {
  const [statusFilter, setStatusFilter] = useState<Option>(_.find(CLOSURE_DAY_TYPE_FILTER_OPTIONS, {
    value: location.query?.statusFilter || CLOSURE_DAY_TYPE_FILTER.ACTIVE,
  }))

  const { onSortChange, sortField, sortOrder } = sortingUtils
  const { getPageCount, page, perPage } = paginationUtils
  const pageCount = getPageCount(totalResults)

  const fetchData = () => {
    const criteria = closureDayTypesSelectors.getCriteria({ statusFilter })

    closureDayTypesActions.list({
      params: [{
        criteria,
        order: {
          sortField,
          sortOrder,
        },
      }],
    })

    setLocationQuery({ statusFilter: statusFilter?.value })
  }

  useEffect(() => {
    fetchData()

    return () => {
      closureDayTypesActions.clearList()
    }
  }, [statusFilter])

  const handlePageChange = (newPage) => {
    const { onPageChange } = paginationUtils

    onPageChange(fetchData)(newPage)
  }

  const handleSortChange = (key) => {
    onSortChange(() => handlePageChange(1))(key)
  }

  const handleStatusFilter = (status) => {
    setStatusFilter(status)
  }

  const tableData = getTableData(closureDayTypesListState)

  return (
    <ClosureDayTypesView
      errorMessages={errorMessages}
      isFinanceV3Enabled={isFinanceV3Enabled}
      isLoading={isFetching}
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      sortField={sortField}
      sortOrder={sortOrder}
      statusFilter={statusFilter}
      tableData={tableData}
      totalResults={totalResults}
      onPageChange={handlePageChange}
      onSortChange={handleSortChange}
      onStatusFilterChange={handleStatusFilter}
    />
  )
}

ClosureDayTypesContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

const enhance = compose(
  withAppService,
  withRouter,
  withRouterUtils,
  withPaginationUtils,
  withSortingUtils,
  withClosureDayTypesService,
  connector,
)

export default enhance(ClosureDayTypesContainer)
