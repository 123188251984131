import React from 'react'

import { generateRoute } from 'utils/routing'

import { Callout, CircleIcon, Field, NurseryLogo, Page, Section, Spinner, Tooltip, Typography } from 'components'
import OpeningDays from 'module/Settings/components/OpeningDays'

import i18n from 'translations'

import { getOpeningDays } from './helpers'
import { StyledTotalOpenWeeksWrapper } from './ManagementNurseryStyled'

const ManagementNurseryView = ({ errorMessages, isLoading, nursery }) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    const {
      address,
      email,
      logo,
      name,
      nurserySettings,
      ofstedId,
      organization,
      postCode,
      primaryContact,
      subdomain,
      telephone,
    } = nursery

    const { openWeeks, openingDays, startWeekFrom } = nurserySettings

    const newOpeningDays = getOpeningDays(openingDays)

    const addressWithPostCode = (
      <div>
        <div>
          {address}
        </div>
        <div>
          {postCode}
        </div>
      </div>
    )

    const openWeeksWithTooltip = (
      openWeeks
        ? (
          <StyledTotalOpenWeeksWrapper>
            <div>
              {openWeeks}
              {' '}
              {i18n.t('global:weeks')}
            </div>
            <Tooltip
              placement="bottom-center"
              title={i18n.t('module:Management:Nursery:totalOpenWeeksTooltip')}
            >
              <CircleIcon
                cursor="pointer"
                icon="info"
                iconSize={16}
                size={24}
              />
            </Tooltip>
          </StyledTotalOpenWeeksWrapper>
        )
        : '-'
    )

    return (
      <div>
        <Field.InlineEdit
          description={name}
          label={i18n.t('module:Management:Nursery:nurseryName')}
        />
        <Field.InlineEdit
          description={(
            organization?.name || i18n.t('module:Organisations:Add:Form:independentNursery')
          )}
          label={i18n.t('module:Nurseries:Preview:organisation')}
        />
        <Field.InlineEdit
          description={subdomain}
          label={i18n.t('module:Management:Nursery:subdomain')}
        />
        <Field.InlineEdit
          description={<NurseryLogo alt="nursery-logo" logo={logo} />}
          label={i18n.t('module:Management:Nursery:logo')}
        />
        <Field.InlineEdit
          description={nurserySettings?.countryName || nurserySettings?.locale?.label}
          label={i18n.t('module:Nurseries:Preview:country')}
        />
        <Field.InlineEdit
          description={nurserySettings?.currencyName || nurserySettings?.currency?.label}
          label={i18n.t('module:Nurseries:Preview:currency')}
        />
        <Field.InlineEdit
          description={addressWithPostCode}
          label={i18n.t('module:Management:Nursery:address')}
        />
        <Field.InlineEdit
          description={telephone}
          label={i18n.t('module:Management:Nursery:telephone')}
        />
        <Field.InlineEdit
          description={ofstedId || '-'}
          label={i18n.t('module:Management:Nursery:ofstedIdentifier')}
        />
        <Field.InlineEdit
          description={email || '-'}
          label={i18n.t('module:Management:Nursery:emailAddress')}
        />
        <Field.InlineEdit
          description={primaryContact?.fullName}
          label={i18n.t('module:Management:Nursery:primaryManager')}
        />
        <Field.InlineEdit
          description={openingDays && <OpeningDays openingDays={newOpeningDays} />}
          label={i18n.t('module:Management:Nursery:openingTimes')}
        />
        <Field.InlineEdit
          description={startWeekFrom || '-'}
          label={i18n.t('module:Management:Nursery:startWeekFrom')}
        />
        <Field.InlineEdit
          description={openWeeksWithTooltip}
          label={i18n.t('module:Management:Nursery:totalOpenWeeks')}
        />
      </div>
    )
  }

  const actions = <Section.Actions tertiary={{ to: generateRoute('MANAGEMENT.NURSERY.EDIT') }} />

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title={i18n.t('module:Management:Nursery:title')}
    >
      <Callout content={errorMessages} error />
      <Typography margin="20px 0">
        {i18n.t('module:Management:Nursery:subtitle')}
      </Typography>
      {renderContent()}
    </Page.Section>
  )
}

export default ManagementNurseryView
