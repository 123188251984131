import React from 'react'

import colors from 'constants/colors'

import { CircleIcon } from 'components'

import { StyledName, StyledRow } from './RoomListsStyled'

const RoomListsRow = ({ containMovesFrom, invalid, name }) => {
  const renderIcon = () => {
    if (invalid) {
      return (
        <CircleIcon
          background={colors.remove}
          icon="exclamation-mark"
          size={20}
        />
      )
    }

    if (containMovesFrom) {
      return (
        <CircleIcon
          background="#6FDD7C"
          icon="check"
          size={20}
        />
      )
    }

    return (
      <CircleIcon
        background="#D5D5D5"
        icon="close"
        size={20}
      />
    )
  }

  return (
    <StyledRow>
      {renderIcon()}
      <StyledName>
        {name}
      </StyledName>
    </StyledRow>
  )
}

export default RoomListsRow
