import React from 'react'

import { Select } from 'components'

import i18n from 'translations'

import { BREAKDOWN_OPTIONS } from './constants'

const BreakdownSelect = ({ onChange, value }) => (
  <Select
    clearable={false}
    options={BREAKDOWN_OPTIONS}
    placeholder={i18n.t('module:Organization:Reports:Components:Breakdown:placeholder')}
    value={value}
    onChange={onChange}
  />
)

export default BreakdownSelect
