export const UPDATE_BATCH_FRAMEWORK_MONTESSORI_PROGRESS = 'UPDATE_BATCH_FRAMEWORK_MONTESSORI_PROGRESS'
export const UPDATE_BATCH_FRAMEWORK_MONTESSORI_PROGRESS_SUCCESS = 'UPDATE_BATCH_FRAMEWORK_MONTESSORI_PROGRESS_SUCCESS'
export const UPDATE_BATCH_FRAMEWORK_MONTESSORI_PROGRESS_FAILED = 'UPDATE_BATCH_FRAMEWORK_MONTESSORI_PROGRESS_FAILED'

export const UPDATE_SINGLE_MONTESSORI_PROGRESS = 'UPDATE_SINGLE_MONTESSORI_PROGRESS'

export const LIST_OF_FRAMEWORK_MONTESSORI_PROGRESS = 'LIST_OF_FRAMEWORK_MONTESSORI_PROGRESS'
export const LIST_OF_FRAMEWORK_MONTESSORI_PROGRESS_SUCCESS = 'LIST_OF_FRAMEWORK_MONTESSORI_PROGRESS_SUCCESS'
export const LIST_OF_FRAMEWORK_MONTESSORI_PROGRESS_FAILED = 'LIST_OF_FRAMEWORK_MONTESSORI_PROGRESS_FAILED'

export const REMOVE_PROGRESS_MONTESSORI_EVENT = 'REMOVE_PROGRESS_MONTESSORI_EVENT'
export const REMOVE_PROGRESS_MONTESSORI_EVENT_SUCCESS = 'REMOVE_PROGRESS_MONTESSORI_EVENT_SUCCESS'
export const REMOVE_PROGRESS_MONTESSORI_EVENT_FAILED = 'REMOVE_PROGRESS_MONTESSORI_EVENT_FAILED'

export const CLEAR_LIST_OF_FRAMEWORK_MONTESSORI_PROGRESS = 'CLEAR_LIST_OF_FRAMEWORK_MONTESSORI_PROGRESS'
