import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { FOOD_CATEGORY, FOOD_TYPE } from 'services/legacy/foodMenu/constants'

const getFoodTypesSingleSelector = (state) => state.foodTypes.single

export const getFoodTypesSingleDataSelector = createSelector([getFoodTypesSingleSelector], (state) => state.data)

export const getCriteria = (params, category, session, oldItems = []) => {
  const {
    date,
    ...types
  } = params

  const criteria = {
    date: moment(date).toDate(),
    items: [],
  }

  _.each(FOOD_TYPE, (type) => {
    _.each(types[type], ({ meal }) => {
      const categoryName = FOOD_TYPE.SNACKS === type ? `${category}-${session}` : `${category}-${type}-${session}`

      criteria.items.push({
        category: category && session && categoryName,
        foodType: meal && meal.value,
        meal: meal && meal.label,
      })
    })
  })

  let preparedOldItems = _.isArray(oldItems) ? [...oldItems] : []

  _.remove(preparedOldItems, (item) => {
    if (item.category.split('-')[0] === FOOD_CATEGORY.MEAL.value) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [itemCategory, itemType, itemSession] = item.category.split('-')

      return (
        itemCategory === category
        && itemSession === session
      )
    }

    if (item.category.split('-')[0] === FOOD_CATEGORY.SNACK.value) {
      const [itemCategory, itemSession] = item.category.split('-')

      return (
        itemCategory === category
        && itemSession === session
      )
    }

    return true
  })

  preparedOldItems = _.map(preparedOldItems, ({ category: itemCategory, foodType, meal }) => ({
    category: itemCategory,
    foodType: foodType && foodType.id,
    meal,
  }))

  criteria.items = [
    ...preparedOldItems,
    ...criteria.items,
  ]

  return criteria
}

export const getInitialValues = createSelector(
  [getFoodTypesSingleDataSelector],
  (data) => {
    if (!data) {
      return null
    }

    return { name: data.name }
  },
)
