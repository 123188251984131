import _ from 'lodash'
import userflow from 'userflow.js'

import { FEATURE_FLAGS, RolesDetails } from 'constants/security'
import { Profile } from 'services/authentication/models'
import { Subdomain } from 'services/subdomain/models'

const NURSERY_GROUP_PREFIX = 'Nursery: '
const ORGANISATION_GROUP_PREFIX = 'Organization: '
const ROLE_GROUP_PREFIX = 'Role: '

export const setRoleGroupForUserflow = (roles) => {
  const highestRole = _.maxBy(
    _.filter(_.values(RolesDetails), ({ value }) => _.includes(roles, value)),
    ({ roleHierarchy }) => roleHierarchy,
  )

  const roleName = `${ROLE_GROUP_PREFIX}${highestRole.value}`

  if (userflow.isIdentified()) {
    userflow.group(roleName, { name: roleName })
  }
}

export const installUserFlow = (subdomain: Subdomain, profile: Profile) => {
  const { featureFlags, nursery, roles, user } = profile || {}

  if (!subdomain?.userflowToken || !_.includes(featureFlags, FEATURE_FLAGS.USERFLOW)) {
    return
  }

  userflow.init(subdomain.userflowToken)
  userflow.identify(String(user.id), {
    name: user.username,
  })
  userflow.setResourceCenterLauncherHidden(true)

  if (nursery?.subdomain) {
    const nurseryName = `${NURSERY_GROUP_PREFIX}${nursery.subdomain}`

    userflow.group(nurseryName, {
      id: nursery.id,
      name: nurseryName,
    })
  }

  if (nursery?.organization?.name) {
    const organizationName = `${ORGANISATION_GROUP_PREFIX}${nursery.organization.name}`

    userflow.group(organizationName, {
      id: nursery.organization.id,
      name: organizationName,
    })
  }

  setRoleGroupForUserflow(roles)
}
