import _ from 'lodash'
import { compose } from 'recompose'

import React, { Component } from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { DropdownMenu, Icon } from 'components'

import { withShellService } from 'services/shell'
import { withAppService } from 'services/app'
import { withRouter } from 'services/router'

import { StyledBreadcrumb, StyledButton, StyledItem } from './BreadcrumbStyled'

class Breadcrumb extends Component {
  constructor(props) {
    super(props)

    this.state = {
      items: [],
      mobileVersion: false,
      oldContentWidth: 0,
    }
  }

  componentDidMount() {
    this.generateBreadcrumb(this.props)

    window.addEventListener('resize', this.calculateResponsive)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.generateBreadcrumb(nextProps)

    return true
  }

  calculateResponsive = () => {
    const { mobileVersion } = this.state

    if (!this.breadcrumbNode) {
      return false
    }

    const breadcrumbContent = this.breadcrumbNode.querySelector('ul')

    if (!this.breadcrumbNode) {
      return false
    }

    if (!breadcrumbContent) {
      const { oldContentWidth } = this.state

      if (!(85 > window.innerWidth - oldContentWidth) && mobileVersion) {
        this.setState({ mobileVersion: false })
      }

      return true
    }

    const { width: contentWidth } = breadcrumbContent.getBoundingClientRect()

    if (85 > window.innerWidth - contentWidth && !mobileVersion) {
      this.setState({ mobileVersion: true, oldContentWidth: contentWidth })
    }

    return true
  }

  searchRecursive = (route, searchName, previous = [], position, routesLength) => {
    if (!route) {
      return null
    }

    const current = [...previous]
    current.push(route)

    if (route.name === searchName && position === routesLength - 1) {
      this.setState({
        items: current,
      }, () => {
        this.calculateResponsive()
        this.forceUpdate()
      })
    }

    return _.each(route.childRoutes, (childRoute) => {
      this.searchRecursive(childRoute, searchName, current, position, routesLength)
    })
  }

  generateBreadcrumb = (props) => {
    const { Routing } = require('core')
    const { routes } = props

    const correctRoutes = _.filter(routes, ({ name }) => name)

    return _.each(correctRoutes, (route, key) => (
      this.searchRecursive(Routing, route.name, [], key, correctRoutes.length)
    ))
  }

  render() {
    const { getRouteTitle, params } = this.props
    const { mobileVersion } = this.state
    let { items } = this.state

    if (!items.length) {
      return null
    }

    if (items[items.length - 1].noBreadcrumb) {
      return null
    }

    items = _.filter(items, ({ name, skip }) => name && !skip)

    return (
      <div>
        <div ref={(e) => { this.breadcrumbNode = e }}>
          {!mobileVersion && (
            <StyledBreadcrumb hidden={mobileVersion}>
              {_.map(items, ({ name, title }) => (
                <StyledItem key={name}>
                  <Link to={generateRoute(name, params)}>
                    {title || getRouteTitle(name)}
                  </Link>
                </StyledItem>
              ))}
            </StyledBreadcrumb>
          )}
        </div>
        {mobileVersion && (
          <StyledBreadcrumb>
            {_.map(items, ({ name, title }, i) => {
              if (1 === i) {
                return (
                  <StyledItem key={`${name}`}>
                    <DropdownMenu
                      button={(
                        <StyledButton>
                          <Icon
                            color={NEUTRAL_COLOURS.BASIC}
                            height={14}
                            icon="dots"
                          />
                        </StyledButton>
                      )}
                      placement="bottom-center"
                    >
                      {_.map(items, (item, index) => (0 !== index && items.length - 1 !== index) && (
                        <DropdownMenu.Item
                          label={item.title || getRouteTitle(item.name)}
                          to={generateRoute(item.name, params)}
                        />
                      ))}
                    </DropdownMenu>
                  </StyledItem>
                )
              }

              return (0 === i || items.length - 1 === i) && (
                <StyledItem key={name}>
                  <Link to={generateRoute(name, params)}>
                    {title || getRouteTitle(name)}
                  </Link>
                </StyledItem>
              )
            })}
          </StyledBreadcrumb>
        )}
      </div>
    )
  }
}

const mapState = (state, { shellSelectors }) => ({
  getRouteTitle: (name) => shellSelectors.getRouteTitle(state)(name),
})

const enhance = compose(
  withAppService,
  withRouter,
  withShellService,
  connect(mapState),
)

export default enhance(Breadcrumb)

