import _ from 'lodash'
import moment from 'moment'

import { compose } from 'recompose'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, getFormValues } from 'redux-form'

import * as OccupancyConstants from 'services/legacy/occupancy/constants'

import { withOccupancyService } from 'services/legacy/occupancy'
import { withShellService } from 'services/shell'
import { withRouter } from 'services/router'

import i18n from 'translations'

import OccupancyReportGeneratorView from './OccupancyReportGeneratorView'
import { OCCUPANCY_REPORT_GENERATOR_FORM } from './components/OccupancyReportGeneratorForm'

const DEFAULT_TIME = moment(43200000)

class OccupancyReportContainer extends Component {
  componentDidMount() {
    const { occupancyActions, shellActions } = this.props

    shellActions.setRouteTitle({
      name: 'OCCUPANCY.GENERATOR',
      title: this.isOverviewGenerator()
        ? i18n.t('module:Occupancy:Dashboard:OccupancyPlanner:title')
        : i18n.t('module:Occupancy:Dashboard:Report:title'),
    })

    occupancyActions.clear()
  }

  shouldComponentUpdate(nextProps) {
    const { changeFieldValue, formValues: oldFormValues } = this.props
    const { formValues = {} } = nextProps

    const overviewValue = _.find(OccupancyConstants.OCCUPANCY_TYPE_DROPDOWN, (option) => (
      option.value === OccupancyConstants.OCCUPANCY_REQUEST_TYPE.OVERVIEW
    ))

    if (this.isOverviewGenerator() && oldFormValues && oldFormValues.type !== overviewValue) {
      changeFieldValue('type', overviewValue)
    }

    if (oldFormValues && !_.isEqual(formValues.type, oldFormValues.type)) {
      changeFieldValue('breakdown', null)
      changeFieldValue('splitAt', DEFAULT_TIME)

      if (formValues.type?.value === OccupancyConstants.OCCUPANCY_REQUEST_TYPE.MONTHLY) {
        changeFieldValue('breakdown', _.find(OccupancyConstants.OCCUPANCY_REQUEST_BREAKDOWN_DROPDOWN, ({ value }) => (
          value === OccupancyConstants.OCCUPANCY_REQUEST_BREAKDOWN.AM_PM
        )))
      }
    }

    if (oldFormValues && !_.isEqual(formValues.breakdown, oldFormValues.breakdown)) {
      changeFieldValue('splitAt', DEFAULT_TIME)
    }

    return true
  }

  componentWillUnmount() {
    const { occupancyActions } = this.props

    occupancyActions.clear()
  }

  isOverviewGenerator = () => {
    const { location } = this.props
    const { query } = location
    const { overview } = query

    return !!overview
  }

  handleSubmit = (fields) => {
    const { navigate, occupancyActions, occupancySelectors } = this.props
    occupancyActions.clear()

    const params = occupancySelectors.getReportParams(fields)

    navigate(`/occupancy/report?${params}`)
  }

  render() {
    const {
      formValues,
      initialValues,
    } = this.props

    return (
      <OccupancyReportGeneratorView
        formValues={formValues}
        initialValues={initialValues}
        isOverviewGenerator={this.isOverviewGenerator()}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  changeFieldValue: (field, value) => change(OCCUPANCY_REPORT_GENERATOR_FORM, field, value),
}

const mapState = (state, { location: { query }, occupancySelectors }) => ({
  formValues: getFormValues(OCCUPANCY_REPORT_GENERATOR_FORM)(state),
  initialValues: occupancySelectors.getInitialValuesForReportGenerator(query),
})

const enhance = compose(
  withRouter,
  withOccupancyService,
  withShellService,
  connect(mapState, mapDispatch),
)

export default enhance(OccupancyReportContainer)
