import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'

import { mergeData } from 'utils/data'

import { withChildService } from 'services/legacy/child'

import ChildrenSearchWidgetView from './ChildrenSearchWidgetView'

const LIMIT_PAGE = 20

class ChildrenSearchWidget extends Component {
  constructor(props) {
    super(props)

    this.state = {
      childList: [],
      childListMeta: {},
      isFetching: false,
      page: 0,
      searchedValue: null,
    }
  }

  componentDidMount() {
    this.getChildrenList()
  }

  componentWillUnmount() {
    const { childActions } = this.props

    childActions.clear()
  }

  handleGetChildrenSuccess = (response, reset) => {
    const { data, meta } = response
    const { childList, page } = this.state

    this.setState({
      childList: reset ? data : _.uniqBy(mergeData(childList, data, true), ({ id }) => id),
      childListMeta: meta,
      isFetching: false,
      page: page + 1,
    })
  }

  getChildrenList = (reset) => {
    const { childActions } = this.props
    const { page, searchedValue } = this.state
    const criteria = [{ field: 'isArchived', value: false }]

    if (searchedValue) {
      criteria.push({
        field: 'displayName',
        value: searchedValue,
      })
    }

    const params = {
      criteria,
      limit: LIMIT_PAGE,
      order: {
        sortField: 'displayName',
        sortOrder: 'ASC',
      },
      page: page + 1,
    }

    this.setState({ isFetching: true })
    childActions.list({
      mergeResult: true,
      onSuccess: (response) => this.handleGetChildrenSuccess(response, reset),
      params,
    })
  }

  handleChangeSearch = (searchedValue) => {
    const { searchedValue: prevSearchedValue } = this.state
    const { childActions } = this.props

    childActions.clear()

    this.setState({
      page: 0,
      searchedValue,
    }, () => this.getChildrenList(prevSearchedValue !== searchedValue))
  }

  render() {
    const { childList, childListMeta, isFetching, page, searchedValue } = this.state

    const isLoading = isFetching && 0 === page && null === searchedValue
    const isLoadingSearchedPages = isFetching && 0 === page && null !== searchedValue

    return (
      <ChildrenSearchWidgetView
        childList={childList}
        childListMeta={childListMeta}
        isLoading={isLoading}
        isLoadingSearchedPages={isLoadingSearchedPages}
        onChangeSearch={this.handleChangeSearch}
        onLoadNextPage={this.getChildrenList}
      />
    )
  }
}

const enhance = compose(
  withChildService,
)

export default enhance(ChildrenSearchWidget)
