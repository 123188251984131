import React, { Component } from 'react'
import { compose } from 'recompose'

import { toInt } from 'utils/data'

import { Field } from 'components'

import withInvoiceSettingsComponent from '../withInvoiceSettingsComponent'
import InvoiceWeeksDaysSettingsForm from './InvoiceWeeksDaysSettingsForm'
import InvoiceActionButton from '../InvoiceActionButton'

const FORM_NAME_PREFIX = 'InvoiceWeeksDaysSettingsForm'

class InvoiceWeeksDaysSettings extends Component {
  constructor(props) {
    super(props)

    const { fields: { item: { id } } } = props

    this.state = {
      formName: `${FORM_NAME_PREFIX}_${id}`,
    }
  }

  handleSubmit = (fields) => {
    const { fields: localFields, onSubmit } = this.props
    const { days: { id: daysId }, weeks: { id: weeksId } } = localFields

    onSubmit({
      ...fields,
      [daysId]: fields[daysId] ? toInt(fields[daysId]) : 0,
      [weeksId]: fields[weeksId] ? toInt(fields[weeksId]) : 0,
    })
  }

  handleGlobalOptionClick = (globalValue) => {
    const { fields: { days, weeks }, updateOrgValue } = this.props
    const { id: weeksId, orgValue: weeksOrgValue } = weeks
    const { id: daysId, orgValue: daysOrgValue } = days

    const { formName } = this.state

    if (globalValue) {
      updateOrgValue(formName, weeksId, weeksOrgValue)
      updateOrgValue(formName, daysId, daysOrgValue)
    }
  }

  render() {
    const {
      caption,
      disableEdit,
      fields: { days, global, item, weeks },
      hideGlobalOption,
      isEdit,
      isGlobalOptionOn,
      isSubmitting,
      onCancelClick,
      onEditClick,
    } = this.props
    const { id } = item
    const { id: globalId, value: globalValue } = global
    const { id: weeksId, value: weeksValue } = weeks
    const { id: daysId, value: daysValue } = days

    const initialValues = { [daysId]: daysValue, [globalId]: globalValue, [weeksId]: weeksValue }
    const label = `${weeksValue} weeks and ${daysValue} days`

    return (
      <Field.InlineEdit
        actions={(
          <InvoiceActionButton
            disableEdit={disableEdit}
            formRef={this.myForm}
            isEditMode={isEdit}
            onCancelClick={onCancelClick}
            onClick={onEditClick}
          />
        )}
        description={label}
        editableComponent={(
          <InvoiceWeeksDaysSettingsForm
            daysId={daysId}
            form={`${FORM_NAME_PREFIX}_${id}`}
            globalId={globalId}
            hideGlobalOption={hideGlobalOption}
            initialValues={initialValues}
            isGlobalOptionOn={isGlobalOptionOn}
            ref={(ref) => { this.myForm = ref }}
            weeksId={weeksId}
            onGlobalOptionClick={this.handleGlobalOptionClick}
            onSubmit={this.handleSubmit}
          />
        )}
        isEditMode={isEdit}
        isLoading={isSubmitting}
        label={caption}
      />
    )
  }
}

const enhance = compose(withInvoiceSettingsComponent(FORM_NAME_PREFIX))

export default enhance(InvoiceWeeksDaysSettings)
