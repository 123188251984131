import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  width: 100%;
`

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: ${({ $flex = 1 }) => $flex};
`
