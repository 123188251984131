import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { Form } from 'components'

import i18n from 'translations'

const FinanceNotesForm = ({
  handleSubmit,
  onSubmit,
}) => (
  <Form noValidate onSubmit={handleSubmit(onSubmit)}>
    <Form.Row>
      <Form.Row.FlexItem>
        <Field
          component={Form.TextAreaField}
          name="notes"
          placeholder={i18n.t('module:Children:Child:Finance:Notes:Add:placeholder')}
        />
      </Form.Row.FlexItem>
    </Form.Row>
  </Form>
)

export default reduxForm()(FinanceNotesForm)
