import moment from 'moment'

import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'
import { DEFAULT_DATE_FORMAT } from 'constants/date'

const getNurseryIntegrationsFailedRecurringPaymentState = (
  state: RootState,
) => state.nurseryIntegrations.failedRecurringPayment

export const getFailedRecurringPaymentMeta = createSelector(
  [getNurseryIntegrationsFailedRecurringPaymentState],
  ({ meta }) => meta,
)

export const getFailedRecurringPayment = createSelector(
  [getNurseryIntegrationsFailedRecurringPaymentState],
  ({ data }) => data,
)

export const getFailedRecurringPaymentNextPageId = createSelector(
  [getFailedRecurringPaymentMeta],
  (meta) => meta.next_page,
)

export const getFailedRecurringPaymentHasMore = createSelector(
  [getFailedRecurringPaymentMeta],
  (meta) => meta.has_more,
)

export const getFailedRecurringPaymentCriteria = ({ dateRange, nursery, paymentMethod, room }) => {
  const criteria = []

  if (dateRange) {
    const [after, before] = dateRange

    if (after) {
      criteria.push({
        comparator: 'after',
        field: 'paymentRunDate',
        value: moment(after).format(DEFAULT_DATE_FORMAT),
      })
    }

    if (before) {
      criteria.push({
        comparator: 'before',
        field: 'paymentRunDate',
        value: moment(before).format(DEFAULT_DATE_FORMAT),
      })
    }
  }

  if (room) {
    criteria.push({
      field: 'nurseryClass',
      value: room?.value || room,
    })
  }

  if (paymentMethod) {
    criteria.push({
      field: 'paymentMethod',
      value: paymentMethod?.value || paymentMethod,
    })
  }

  if (nursery) {
    criteria.push({
      field: 'nursery',
      value: nursery?.value || nursery,
    })
  }

  return criteria
}
