import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { OTHER_OPTION } from 'services/users/constants'
import { SIGNATURE_COLLECTING_TYPES, SIGNATURE_TYPES } from 'services/legacy/injuries/constants'

import { generateRoute } from 'utils/routing'

import { withInjuriesService } from 'services/legacy/injuries'
import { withModalService } from 'services/utils/modal'
import { withRouter } from 'services/router'

import InjurySignatureView from './InjurySignatureView'

const SIGNATURE_GROUPS = {
  read: [
    'injurySignature',
    'injurySignature.injury',
    'injury',
    'injury.injurySignatureStatistics',
    'injurySignature.carer',
    'carer',
  ],
}

const HA_SIGNATURE_GROUPS = {
  read: [
    'injury.injurySignatureStatistics',
    'injury.signatures',
    'injurySignature',
    'injurySignature.signature',
    'medium',
    'injurySignature.owner',
    'injurySignature.carer',
    'carer',
    'injured.child',
    'child',
    'injured.firstAider',
    'user',
  ],
}

class InjurySignatureContainer extends Component {
  constructor(props) {
    super(props)

    const { injuryPreview, location } = props
    const { pathname } = location
    const { signatureCollectingType: collectingType } = injuryPreview

    this.state = {
      collectingType,
      isHomeAccidents: pathname.includes(generateRoute('SAFEGUARDING.HOME_ACCIDENTS.LIST')),
      showCollectionChangeTypeForm: !collectingType,
    }
  }

  componentWillUnmount() {
    const { modalActions } = this.props

    modalActions.hide()
  }

  handleOnPaperInjuryUpdateFailed = () => {
    const { modalActions, modalConsts, signaturesValidationErrorMsg, validationErrorMsg } = this.props

    modalActions.show(modalConsts.TYPES.ALERT, {
      text: validationErrorMsg || signaturesValidationErrorMsg,
    })
  }

  handleCollectingTypeChangeSuccess = ({ data }, isAddDefaultStaff = true) => {
    const { injuriesActions } = this.props
    const { injurySignatureStatistics, signatureCollectingType, signatures, status } = data
    const { isHomeAccidents } = this.state

    injuriesActions.updateStatus(status)
    injuriesActions.updateSignatureCollectingType(signatureCollectingType)
    injuriesActions.updateSignatureCount({ newSignatureStatistics: injurySignatureStatistics })
    injuriesActions.updateSignatureStatistics({ newSignatureStatistics: injurySignatureStatistics, status })

    this.setState({ showCollectionChangeTypeForm: false })

    if (isHomeAccidents && isAddDefaultStaff) {
      const staffSignatures = _.filter(signatures, (item) => (item.type === SIGNATURE_TYPES.WITNESS))

      this.handleAddDefaultStaffMemberClick(staffSignatures.length)
    }
  }

  handleCollectingTypeChange = (collectingType) => {
    const { injuriesActions, injuriesSelectors, params } = this.props
    const { injuryId } = params

    const apiParams = {
      groups: injuriesSelectors.getSignatureSerializationGroups(collectingType),
    }

    this.setState({ collectingType })

    injuriesActions.updateSignatures(injuryId, {
      body: { signatureCollectingType: collectingType },
      onFailed: this.handleOnPaperInjuryUpdateFailed,
      onSuccess: this.handleCollectingTypeChangeSuccess,
      params: apiParams,
      updateInjuryRecord: true,
    })
  }

  handleCollectingChangeButtonClick = () => {
    this.setState({ showCollectionChangeTypeForm: true })
  }

  handleAddDefaultStaffMemberClick = (staffLength) => {
    const { injuriesActions } = this.props
    const isRemoveSignature = true

    for (let i = 0; (2 - staffLength) > i; i += 1) {
      injuriesActions.addStaffMemberItem({ owner: {} }, isRemoveSignature)
    }
  }

  handleSignatureChangeSuccess = ({ data }) => {
    const { injuriesActions, injuryPreview } = this.props
    const { collectingType } = this.state
    const { status: oldStatus } = injuryPreview

    const { id: signatureId, injury = {} } = data
    const { injurySignatureStatistics, status } = injury

    if (status && oldStatus !== status) {
      injuriesActions.updateStatus(status)
    }

    if (signatureId && collectingType === SIGNATURE_COLLECTING_TYPES.ON_PAPER) {
      injuriesActions.toggleOnPaperSignatureItemValue(signatureId)
    }

    if (status) {
      injuriesActions.updateSignatureCount({ newSignatureStatistics: injurySignatureStatistics, signatureData: data })
      injuriesActions.updateSignatureStatistics({ newSignatureStatistics: injurySignatureStatistics, status })
    }
  }

  handleOnPaperSignatureToggleChange = (toggleValue, signatureItem) => {
    const { injuriesActions, injuriesSelectors, params } = this.props
    const { collectingType, isHomeAccidents } = this.state
    const { injuryId } = params
    const { id, uid } = signatureItem

    if (uid && !id) {
      const body = injuriesSelectors.getCreateSignatureCarerPayload({
        injury: injuryId,
        owner: signatureItem.owner.id,
        type: SIGNATURE_TYPES.PARENT,
      })

      const carerBody = {
        ...body,
        carer: null,
        otherCarer: signatureItem.carer?.otherCarer,
        signedOnPaper: toggleValue,
      }

      injuriesActions.createSignatureId(signatureItem, {
        body: carerBody,
        onSuccess: this.handleSignatureChangeSuccess,
        params: { groups: HA_SIGNATURE_GROUPS },
      })
    } else {
      const body = injuriesSelectors.getSignaturePayload({
        signatureCollectingType: collectingType,
        signatureItem,
        toggleValue,
      })

      const carerBody = OTHER_OPTION.value === signatureItem.carer?.value
        ? { ...body, carer: null, otherCarer: signatureItem.carer?.otherCarer }
        : { ...body, carer: { id: signatureItem.carer?.value }, otherCarer: null }

      injuriesActions.updateSignature(id, {
        body: isHomeAccidents ? carerBody : body,
        onSuccess: this.handleSignatureChangeSuccess,
        params: { groups: SIGNATURE_GROUPS },
      })
    }
  }

  handleOnPaperDocumentChangeSuccess = (signatureDocuments) => {
    const { injuriesActions } = this.props

    injuriesActions.updateSignatureDocuments(signatureDocuments)
  }

  handleOnPaperDocumentChange = (signatureDocuments) => {
    const { injuriesActions, params } = this.props
    const { injuryId } = params

    const finalSignatureDocuments = _.map(signatureDocuments, (item) => {
      const newItem = { ...item }

      newItem.children = []

      if (false === newItem.itemFromBe) {
        delete newItem.id
      } else {
        delete newItem.createdAt
      }

      delete newItem.itemFromBe
      delete newItem.thumbnail
      delete newItem.name

      if (newItem.createdAt?._isAMomentObject) {
        newItem.createdAt = moment(newItem.createdAt).format()
      }

      return newItem
    })

    injuriesActions.updateSignatures(injuryId, {
      body: {
        signatureDocuments: finalSignatureDocuments,
      },
      onFailed: this.handleOnPaperInjuryUpdateFailed,
      onSuccess: () => this.handleOnPaperDocumentChangeSuccess(signatureDocuments),
    })
  }

  handleAddSignatureClick = (signatureId, signatureUid) => {
    const { modalActions, modalConsts, params } = this.props
    const { injuryId } = params

    modalActions.show(modalConsts.TYPES.INJURY_SIGNATURE, {
      injuryId,
      onSuccess: this.handleSignatureChangeSuccess,
      signatureId,
      signatureUid,
    }, {
      backgroundFilter: {
        type: 'blur',
        value: '30px',
      },
    })
  }

  handleAddCarerClick = (child) => {
    const { injuriesActions } = this.props
    const { isHomeAccidents } = this.state

    injuriesActions.addCarerItem(child, isHomeAccidents)
  }

  handleAddStaffMemberClick = (staff) => {
    const { injuriesActions } = this.props

    injuriesActions.addStaffMemberItem({ ...staff, owner: {} })
  }

  handleChangeCarer = (item, option) => {
    const { injuriesActions, injuriesSelectors, injuriesSingleState, params } = this.props
    const { collectingType, isHomeAccidents } = this.state
    const { injuryId } = params

    const { injurySignatureStatistics } = injuriesSingleState?.data || {}

    injuriesActions.updateCarer(item, option)

    if (option && OTHER_OPTION.value !== option.value && isHomeAccidents) {
      if (item.id) {
        const body = { carer: { id: option.value } }
        injuriesActions.updateSignature(item.id, { body })
      } else {
        const body = injuriesSelectors.getCreateSignatureCarerPayload({
          carer: option.value,
          injury: injuryId,
          owner: item.owner.id,
          type: SIGNATURE_TYPES.PARENT,
        })

        injuriesActions.createSignatureId(item, {
          body,
          params: { groups: HA_SIGNATURE_GROUPS },
        })

        if (collectingType === SIGNATURE_COLLECTING_TYPES.DIGITALLY) {
          injuriesActions.updateSingleSignatureStatistics({
            newSignatureStatistics: {
              ...injurySignatureStatistics,
              parent: {
                ...(injurySignatureStatistics?.parent || {}),
                all: (injurySignatureStatistics?.parent?.all || 0) + 1,
              },
            },
          })
        }
      }
    }
  }

  handleOnPaperUpdateSignatureIdFailed = () => {
    const { modalActions, modalConsts, signaturesValidationErrorMsg, validationErrorMsg } = this.props

    modalActions.show(modalConsts.TYPES.ALERT, {
      text: validationErrorMsg || signaturesValidationErrorMsg,
    })
  }

  handleChangeStaffMember = (filteredStaffMember, item, option) => {
    const { injuriesActions, injuriesSelectors, params } = this.props
    const { injuryId } = params

    injuriesActions.updateStaffMember(item, option)

    if (option && injuryId) {
      const body = injuriesSelectors.getStaffMembers(filteredStaffMember, item, option)

      const bodyItem = _.filter(body, (sItem) => (null !== sItem))

      injuriesActions.updateSignatureId(item, injuryId, {
        body: { witnesses: bodyItem },
        onFailed: this.handleOnPaperUpdateSignatureIdFailed,
        onSuccess: (response) => this.handleCollectingTypeChangeSuccess(response, false),
        params: { groups: HA_SIGNATURE_GROUPS },
      })
    }
  }

  handleChangeOtherCarer = (item, value) => {
    const { injuriesActions } = this.props

    injuriesActions.updateOtherCarer(item, value)
  }

  handleOnPaperMarkAllAsSignedClickSuccess = ({ data }) => {
    const { injuriesActions } = this.props
    const { injurySignatureStatistics, status } = data

    injuriesActions.updateStatus(status)
    injuriesActions.updateSignatureCount({ newSignatureStatistics: injurySignatureStatistics })
    injuriesActions.updateSignatureStatistics({ newSignatureStatistics: injurySignatureStatistics, status })
  }

  handleOnPaperMarkAllAsSignedClick = (toggleValue) => {
    const { injuriesActions, injuriesSelectors, params } = this.props
    const { injuryId } = params

    const body = { markAllOnPaperInjurySignaturesAsSigned: toggleValue }

    const apiParams = {
      groups: injuriesSelectors.getSignatureSerializationGroups(SIGNATURE_COLLECTING_TYPES.ON_PAPER),
    }

    injuriesActions.updateSignatures(injuryId, {
      body,
      onFailed: this.handleOnPaperInjuryUpdateFailed,
      onSuccess: this.handleOnPaperMarkAllAsSignedClickSuccess,
      params: apiParams,
      updateInjuryRecord: true,
    })
  }

  render() {
    const { disableSignature, injuryPreview, injurySignaturesPreview, isFetching, isSubmitting } = this.props
    const { isHomeAccidents, showCollectionChangeTypeForm } = this.state

    return (
      <InjurySignatureView
        disableSignature={disableSignature}
        injuryPreview={injuryPreview}
        injurySignaturesPreview={injurySignaturesPreview}
        isFetching={isFetching}
        isHomeAccidents={isHomeAccidents}
        isSubmitting={isSubmitting}
        showCollectionChangeTypeForm={showCollectionChangeTypeForm}
        onAddCarerClick={this.handleAddCarerClick}
        onAddSignatureClick={this.handleAddSignatureClick}
        onAddStaffMemberClick={this.handleAddStaffMemberClick}
        onChangeCarer={this.handleChangeCarer}
        onChangeOtherCarer={this.handleChangeOtherCarer}
        onChangeStaffMember={this.handleChangeStaffMember}
        onCollectingChangeButtonClick={this.handleCollectingChangeButtonClick}
        onCollectingTypeChange={this.handleCollectingTypeChange}
        onOnPaperDocumentChange={this.handleOnPaperDocumentChange}
        onOnPaperMarkAllAsSignedClick={this.handleOnPaperMarkAllAsSignedClick}
        onOnPaperSignatureToggleChange={this.handleOnPaperSignatureToggleChange}
      />
    )
  }
}

const mapState = (state, { injuriesSelectors }) => ({
  disableSignature: injuriesSelectors.isGrantedToUpdateSignature(state),
  injuryPreview: injuriesSelectors.getPreview(state),
  injurySignaturesPreview: injuriesSelectors.getSignaturesPreview(state),
  isFetching: injuriesSelectors.injurySignatureIsFetching(state),
  isSubmitting: injuriesSelectors.injurySignatureIsSubmitting(state),
  signaturesValidationErrorMsg: injuriesSelectors.getSignaturesValidationErrorMsg(state),
  validationErrorMsg: injuriesSelectors.getValidationErrorMsg(state),
})

const enhance = compose(
  withRouter,
  withInjuriesService,
  withModalService,
  connect(mapState),
)

export default enhance(InjurySignatureContainer)
