import { withStyles } from '@material-ui/core/styles'

import React, { PropsWithChildren } from 'react'
import MaterialTooltip, { TooltipProps as TooltipMaterialProps } from '@material-ui/core/Tooltip'

import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'

import { typeByObject } from 'utils/typescript'

import { StyledWrapper } from './TooltipStyled'

const TOOLTIP_TYPE = {
  ERROR: 'error',
  STANDARD: 'standard',
  WHITE: 'white',
} as const

const getColor = (type) => {
  if (TOOLTIP_TYPE.WHITE === type) {
    return NEUTRAL_COLOURS.BASIC
  }

  return NEUTRAL_COLOURS.WHITE
}

const getBackgroundColor = (type) => {
  if (TOOLTIP_TYPE.ERROR === type) {
    return FLAG_COLOURS.ERROR
  }

  if (TOOLTIP_TYPE.WHITE === type) {
    return NEUTRAL_COLOURS.WHITE
  }

  return NEUTRAL_COLOURS.BASIC
}

const MaterialTooltipStyled = withStyles(() => ({
  arrow: ({ type }: { type: typeByObject<typeof TOOLTIP_TYPE> }) => ({
    color: getBackgroundColor(type),
  }),
  tooltip: ({ type }) => ({
    backgroundColor: getBackgroundColor(type),
    borderRadius: 12,
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.25)',
    color: getColor(type),
    padding: 12,
  }),
}))(MaterialTooltip)

interface TooltipProps {
  enterTouchDelay?: number
  fullHeight?: boolean
  inline?: boolean
  placement?: TooltipMaterialProps['placement']
  title: any
  type?: typeByObject<typeof TOOLTIP_TYPE>
}

const Tooltip: React.FC<PropsWithChildren<TooltipProps>> = ({
  children,
  enterTouchDelay,
  fullHeight,
  inline,
  placement,
  title,
  type = TOOLTIP_TYPE.STANDARD,
}) => {
  if (!title) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <React.Fragment>
        {children}
      </React.Fragment>
    )
  }

  return (
    <MaterialTooltipStyled
      enterTouchDelay={enterTouchDelay}
      placement={placement}
      title={title}
      type={type}
      arrow
    >
      <StyledWrapper $fullHeight={fullHeight} $inline={inline}>
        {children}
      </StyledWrapper>
    </MaterialTooltipStyled>
  )
}

export default Tooltip
