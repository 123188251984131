import React, { PropsWithChildren } from 'react'

import { StyledTd } from './TableStyled'

interface TableBlankTdProps {
  colSpan?: number
}

const TableBlankTd: React.FC<PropsWithChildren<TableBlankTdProps>> = ({ children, colSpan }) => (
  <StyledTd $colSpan={colSpan} $empty>
    {children}
  </StyledTd>
)

export default TableBlankTd
