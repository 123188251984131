import React, { useEffect } from 'react'

import { FLAG_COLOURS } from 'constants/colors'

import { CircleIcon } from 'components'

import { StyledBadge } from './CountBadgeStyled'

const MAX_COUNT = 20

const CountBadge = ({ onOverflow, unreadCount }) => {
  const overflow = MAX_COUNT < unreadCount

  useEffect(() => {
    onOverflow(overflow)
  }, [overflow])

  if (!unreadCount) {
    return null
  }

  return (
    <StyledBadge>
      <CircleIcon
        background={FLAG_COLOURS.ERROR}
        letter={overflow ? `${MAX_COUNT}+` : unreadCount}
        size={18}
      />
    </StyledBadge>
  )
}

export default CountBadge
