import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'
import React from 'react'
import { formValueSelector } from 'redux-form'

import { toFloat } from 'utils/data'

import { getNurseryFundingTypesOptionsSelectors } from 'services/nurseryFunding/list/selectors'
import { getTermCloserItem, isDateExistBetweenDateRange } from 'services/legacy/childFundingSessionSet/list/selectors'

import { Currency } from 'components'

export const getChildFundingSessionSetSelectors = (state) => state.childFundingSessionSet

export const getSingleDataSelectors = createSelector(
  [getChildFundingSessionSetSelectors],
  (state) => state.single.data,
)

export const getItemPreviewDataSelectors = createSelector(
  [getSingleDataSelectors, getNurseryFundingTypesOptionsSelectors],
  (singleData, nurseryFundingTypesOptions) => {
    if (!singleData) {
      return null
    }

    const {
      archived,
      endDate,
      funding: { id: fundingType },
      hourlyRate,
      hoursUsed,
      id,
      startDate,
      total,
      totalHours,
    } = singleData

    const fundingTypesOption = _.find(nurseryFundingTypesOptions, { value: fundingType })

    return {
      fundingType: fundingTypesOption ? `${fundingTypesOption.label}${archived ? ' (Archived)' : ''}` : null,
      hourlyRate: hourlyRate ? <Currency value={hourlyRate} /> : null,
      hoursUsed,
      id,
      period: `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`,
      total: <Currency value={total} />,
      totalAmount: toFloat(total) || 0,
      totalHours,
    }
  },
)

export const getItemAllocationDataSelectors = createSelector(
  [getSingleDataSelectors],
  (singleData) => {
    if (!singleData) {
      return null
    }

    const {
      amountUsed,
      hoursUsed,
      total,
      totalHours,
    } = singleData

    return {
      amountUsed,
      hoursUsed,
      total,
      totalHours,
    }
  },
)

export const isArchivedSelector = createSelector(
  [getSingleDataSelectors],
  (singleData) => {
    if (!singleData) {
      return null
    }

    const { archived } = singleData

    return archived
  },
)

const formSelector = (formName) => formValueSelector(formName)

const fundingTypeSelector = (formName) => (state) => formSelector(formName)(state, 'fundingType')
const hourlyRateSelector = (formName) => (state) => formSelector(formName)(state, 'hourlyRate')
const hoursPerWeekSelector = (formName) => (state) => formSelector(formName)(state, 'settings.hoursPerWeek')
const maxHoursPerDaySelector = (formName) => (state) => formSelector(formName)(state, 'settings.maxHoursPerDay')
const totalHoursSelector = (formName) => (state) => formSelector(formName)(state, 'totalHours')

export const getFundingType = (formName) => createSelector(
  [fundingTypeSelector(formName)],
  (fundingType) => {
    if (!fundingType) {
      return undefined
    }

    return fundingType
  },
)

export const getHourlyRate = (formName) => createSelector(
  [hourlyRateSelector(formName)],
  (hourlyRate) => {
    if (!hourlyRate) {
      return undefined
    }

    return hourlyRate
  },
)

export const getHoursPerWeek = (formName) => createSelector(
  [hoursPerWeekSelector(formName)],
  (hoursPerWeek) => {
    if (!hoursPerWeek) {
      return undefined
    }

    return hoursPerWeek
  },
)

export const getMaxHoursPerDay = (formName) => createSelector(
  [maxHoursPerDaySelector(formName)],
  (maxHoursPerDay) => {
    if (!maxHoursPerDay) {
      return undefined
    }

    return maxHoursPerDay
  },
)

export const getTotalHours = (formName) => createSelector(
  [totalHoursSelector(formName)],
  (totalHours) => {
    if (!totalHours) {
      return undefined
    }

    return totalHours
  },
)

export const getAllowOverrideHoursPerWeek = (formName) => createSelector(
  [getHoursPerWeek(formName)],
  (hoursPerWeek) => {
    if (!hoursPerWeek) {
      return null
    }

    const { allowOverride } = hoursPerWeek

    return allowOverride
  },
)

export const getAllowOverrideMaxHoursPerDay = (formName) => createSelector(
  [getMaxHoursPerDay(formName)],
  (maxHoursPerDay) => {
    if (!maxHoursPerDay) {
      return null
    }

    const { allowOverride } = maxHoursPerDay

    return allowOverride
  },
)

export const getPayload = createSelector(
  [(params) => params],
  (params) => {
    if (!params) {
      return null
    }

    const { fields, selectedTimeLine, sessionSet } = params
    const { date, progress: { childSessionPlanId } } = selectedTimeLine
    const { allocations } = sessionSet

    const currentAllocation = fields.allocations && fields.allocations.length
      ? {
        childSessionPlan: {
          id: childSessionPlanId,
        },
        date: moment(date).format('YYYY-MM-DD'),
        times: fields.allocations,
      }
      : undefined

    const restAllocations = _.reject(allocations, ({ childSessionPlan, date: sessionSetDate }) => (
      childSessionPlan.id === childSessionPlanId
      && moment(sessionSetDate).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')
    ))

    // NOTE: converted date because in normalize date middleware we convert YYYY-MM-DD string
    // into js date which adding 00:00:00 as time and that leads to Timezone issue
    // Here while sending the old allocation we just need to pass 'YYYY-MM-DD'
    const finalRestAllocations = _.map(restAllocations, (restAllocation) => ({
      ...restAllocation,
      date: moment(restAllocation.date).format('YYYY-MM-DD'),
    }))

    if (!currentAllocation && (!finalRestAllocations || !finalRestAllocations.length)) {
      return {
        allocations: [],
      }
    }

    if (!currentAllocation) {
      return {
        allocations: [
          ...finalRestAllocations,
        ],
      }
    }

    return {
      allocations: [
        currentAllocation,
        ...finalRestAllocations,
      ],
    }
  },
)

export const getInitialValues = createSelector(
  [(selectedTimeLine) => selectedTimeLine],
  (timeLineData) => {
    if (!timeLineData) {
      return null
    }

    return {
      allocations: _.map(
        _.filter(timeLineData.progress.nestedProgressList, { currentFunding: true }),
        ({ endTimeUnix, startTimeUnix }) => ({
          endTime: endTimeUnix,
          startTime: startTimeUnix,
        }),
      ),
    }
  },
)

export const getPayloadsForApplyFunding = createSelector(
  [(params) => params],
  (params) => {
    if (!params) {
      return null
    }

    const {
      childFundingSessionSets,
      childFundingSingleState,
      periodTimesListState,
      selectedWeekDays,
      weekDays,
    } = params

    const { fundingEndDate, sessions, weekEndDate, weekStartDate } = selectedWeekDays

    const { data: childFunding } = childFundingSingleState
    const { funding } = childFunding || {}
    const { settings } = funding || {}
    const { excludedPeriods } = settings || {}

    const { data: periodTimes } = periodTimesListState

    // Get sessions set to get allocations from selected weeks session id
    const sessionSet = _.find(childFundingSessionSets, (childSessionSet) => (
      _.includes(_.map(selectedWeekDays.sessions, ({ id }) => id), childSessionSet.session.id)
    ))

    if (!sessionSet) {
      return null
    }

    // Get allocations for session set and create template of selected weeks funding allocation
    const { allocations } = sessionSet

    const allocationTemplate = _.filter(allocations, ({ date }) => (
      moment(date).startOf('day').toDate() >= moment(weekStartDate).startOf('day').toDate()
      && moment(date).startOf('day').toDate() <= moment(weekEndDate).startOf('day').toDate()
    ))

    // Get the list of future session week days
    // That may includes multiple sessions
    const futureSessionStartDate = moment(weekEndDate).add('d', 1).toDate()
    const { endDate: futureSessionEndDate = fundingEndDate } = sessions[0]

    const futureSessionWeekDays = _.filter(weekDays, ({ weekEndDate: endDate, weekStartDate: startDate }) => (
      startDate <= futureSessionEndDate && endDate >= futureSessionStartDate
    ))

    // Assign funding allocation to future session weekdays
    const futureFundingAllocations = []

    _.forEach(futureSessionWeekDays, ({ weekEndDate: futureWeekEndDate, weekStartDate: futureWeekStartDate }) => {
      // Get min date between session and week end date, when there is two sessions for a single/last week
      const minEndDate = _.min([fundingEndDate, futureSessionEndDate, futureWeekEndDate])

      let currentDate = moment(futureWeekStartDate)

      while (currentDate <= minEndDate) {
        const isFundingExcluded = isDateExistBetweenDateRange(currentDate, excludedPeriods)
        const termClosureItem = getTermCloserItem(currentDate, periodTimes)

        const { isHolidaysChargeable } = termClosureItem || {}
        const hideTimeLine = termClosureItem && !isHolidaysChargeable

        if (!isFundingExcluded && !hideTimeLine) {
          const dayOfWeek = currentDate.format('dddd')

          const filteredAllocationTemplate = _.filter(
            allocationTemplate,
            ({ date }) => moment(date).format('dddd') === dayOfWeek,
          )

          futureFundingAllocations.push(..._.map(filteredAllocationTemplate, (allocation) => ({ // eslint-disable-line
            ...allocation,
            date: currentDate.format('YYYY-MM-DD'),
          })))
        }

        currentDate = moment(currentDate).add('d', 1)
      }
    })

    const previousWeeksFundingAllocation = _.filter(
      allocations,
      ({ date }) => moment(date).startOf('day').toDate() <= moment(weekEndDate).startOf('day').valueOf(),
    )

    const previousWeeksFundingAllocationFormatted = _.map(previousWeeksFundingAllocation, (allocation) => ({
      ...allocation,
      date: moment(allocation.date).format('YYYY-MM-DD'),
    }))

    return {
      allocations: [...futureFundingAllocations, ...previousWeeksFundingAllocationFormatted],
    }
  },
)

export const getFieldValidationSummary = createSelector(
  [(error) => error],
  (error) => {
    if (!error || !error.extra) {
      return null
    }

    let errorSummary = null

    _.forEach(error.extra, (errorExtraItem) => {
      if (errorExtraItem && !errorSummary) {
        errorSummary = errorExtraItem
      }
    })

    return {
      _error: errorSummary,
    }
  },
)
