import styled from 'styled-components'

import layout from 'constants/layout'

export const StyledContainer = styled.div`
  @media(max-width: 900px) {
    & > div > div {
      flex-direction: column;
    }
  }
`

export const StyledDraggableBox = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  border-radius: ${layout.borderRadius};
  padding: 10px 15px;
  background: #F7F7F7;
  align-items: center;
  grid-column-gap: 10px;
  min-height: 44px;
  transition: .4s;
  
  ${({ editMode }) => editMode && `
    grid-template-columns: 24px auto 48px;
  `}
`

export const StyledDraggableBoxContainer = styled.div`
  padding-bottom: 8px;
`

export const StyledDragg = styled.div`
  cursor: grab;
  transform: translateY(2.5px);
`
