import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as dailyDiaryFoodsSelectors from './selectors'

const withDailyDiaryFoodsService = (WrappedComponent) => {
  const mapState = ({ dailyDiaryFoods }) => ({ dailyDiaryFoods })

  const mapDispatch = injectActions('dailyDiaryFoodsActions', actions)

  const Component = ({ dailyDiaryFoods, dailyDiaryFoodsActions, ...others }) => (
    <WrappedComponent
      dailyDiaryFoodsActions={dailyDiaryFoodsActions}
      dailyDiaryFoodsListState={dailyDiaryFoods.list}
      dailyDiaryFoodsSelectors={dailyDiaryFoodsSelectors}
      dailyDiaryFoodsSingleState={dailyDiaryFoods.single}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withDailyDiaryFoodsService
