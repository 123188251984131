import { Property } from 'csstype'

import React, { PropsWithChildren } from 'react'

import { StyledRowFlexItem } from './FormRowStyled'

interface FormRowFlexItemProps {
  align?: Property.TextAlign
  flex?: string
  margin?: string
  mobileFull?: boolean
  noPadding?: boolean
}

const FormRowFlexItem: React.FC<PropsWithChildren<FormRowFlexItemProps>> = ({
  align,
  children,
  flex = 1,
  margin,
  mobileFull,
  noPadding,
}) => (
  <StyledRowFlexItem
    $align={align}
    $flex={flex}
    $margin={margin}
    $mobileFull={mobileFull}
    $noPadding={noPadding}
  >
    {children}
  </StyledRowFlexItem>
)

export default FormRowFlexItem
