import React from 'react'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { Form, Section } from 'components'

import i18n from 'translations'

import { CHILD_ADD_FORM } from './constants'
import ChildFormFooter from './ChildFormFooter'

const TRANS_PREFIX = 'module:Children:Child:Add:Development'

const DevelopmentInformationForm = ({
  childId,
  daytimeSleepingBehavior,
  drinkingMethodsDropdownOptions,
  englishAsAdditionalLanguage,
  handleSubmit,
  isChildArchived,
  isEditMode,
  isSubmitting,
  onArchiveClick,
  onPreviousStageClick,
  permissionToArchive,
  specialEducationalNeeds,
  toiletTrainingDropdownOptions,
}) => {
  const renderForm = () => {
    const renderSkills = () => (
      <Section title={i18n.t(`${TRANS_PREFIX}:Subsections:Skills:title`)}>
        <Form.Row
          label={i18n.t(`${TRANS_PREFIX}:Subsections:Skills:Form:Toileting:label`)}
        >
          <Form.Row.Item>
            <Field
              component={Form.RadioGroup}
              name="information.toiletTrained"
              options={toiletTrainingDropdownOptions}
              buttonStyle
              horizontal
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row
          label={i18n.t(`${TRANS_PREFIX}:Subsections:Skills:Form:DrinkingMethods:label`)}
        >
          <Form.Row.Item>
            <Field
              component={Form.RadioGroup}
              name="information.drinkingMethod"
              options={drinkingMethodsDropdownOptions}
              buttonStyle
              horizontal
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row
          label={i18n.t(`${TRANS_PREFIX}:Subsections:Skills:Form:SpecialEducation:label`)}
          autoHeight
        >
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              name="profile.specialEducationalNeeds"
            />
          </Form.Row.Item>
          {specialEducationalNeeds && (
            <Form.Row.Item margin="0 0 0 10px">
              <Field
                component={Form.TextField}
                name="profile.specialEducationalNeedsDetails"
                placeholder={
                  i18n.t(`${TRANS_PREFIX}:Subsections:Skills:Form:SpecialEducationDetails:placeholder`)
                }
              />
            </Form.Row.Item>
          )}
        </Form.Row>
        <Form.Row
          label={i18n.t(`${TRANS_PREFIX}:Subsections:Skills:Form:EnglishAdditionalLanguage:label`)}
          autoHeight
        >
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              name="profile.englishAsAdditionalLanguage"
            />
          </Form.Row.Item>
          {englishAsAdditionalLanguage && (
            <Form.Row.Item margin="0 0 0 10px">
              <Field
                component={Form.TextField}
                name="profile.englishAsAdditionalLanguageDetails"
                placeholder={
                  i18n.t(`${TRANS_PREFIX}:Subsections:Skills:Form:EnglishAdditionalLanguageDetails:placeholder`)
                }
              />
            </Form.Row.Item>
          )}
        </Form.Row>
        <Form.Row
          label={i18n.t(`${TRANS_PREFIX}:Subsections:Skills:Form:LanguageCapability:label`)}
        >
          <Form.Row.FlexItem mobileFull>
            <Field
              component={Form.TextAreaField}
              name="profile.languageCapability"
              placeholder={i18n.t(`${TRANS_PREFIX}:Subsections:Skills:Form:LanguageCapability:placeholder`)}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row
          label={i18n.t(`${TRANS_PREFIX}:Subsections:Skills:Form:MovementCapability:label`)}
        >
          <Form.Row.FlexItem mobileFull>
            <Field
              component={Form.TextAreaField}
              name="profile.movementCapability"
              placeholder={i18n.t(`${TRANS_PREFIX}:Subsections:Skills:Form:MovementCapability:placeholder`)}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row
          label={i18n.t(`${TRANS_PREFIX}:Subsections:Skills:Form:ChildBehavior:label`)}
        >
          <Form.Row.FlexItem mobileFull>
            <Field
              component={Form.TextAreaField}
              name="profile.childBehavior"
              placeholder={i18n.t(`${TRANS_PREFIX}:Subsections:Skills:Form:ChildBehavior:placeholder`)}
            />
          </Form.Row.FlexItem>
        </Form.Row>
      </Section>
    )

    const renderRoutine = () => (
      <Section title={i18n.t(`${TRANS_PREFIX}:Subsections:Routine:title`)}>
        <Form.Row
          label={i18n.t(`${TRANS_PREFIX}:Subsections:Routine:Form:DayTimeSleep:label`)}
          autoHeight
        >
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              name="information.daytimeSleepingBehavior"
            />
          </Form.Row.Item>
          {daytimeSleepingBehavior && (
            <Form.Row.Item margin="0 0 0 10px">
              <Field
                component={Form.TextField}
                name="information.daytimeSleepingBehaviorDetails"
                placeholder={
                  i18n.t(`${TRANS_PREFIX}:Subsections:Routine:Form:DayTimeSleepDetails:placeholder`)
                }
              />
            </Form.Row.Item>
          )}
        </Form.Row>
        <Form.Row
          label={i18n.t(`${TRANS_PREFIX}:Subsections:Routine:Form:Routine:label`)}
        >
          <Form.Row.FlexItem mobileFull>
            <Field
              component={Form.TextAreaField}
              name="profile.routine"
              placeholder={i18n.t(`${TRANS_PREFIX}:Subsections:Routine:Form:Routine:placeholder`)}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row
          label={i18n.t(`${TRANS_PREFIX}:Subsections:Routine:Form:AttendsOtherSettings:label`)}
        >
          <Form.Row.FlexItem mobileFull>
            <Field
              component={Form.TextAreaField}
              name="profile.attendsOtherSetting"
              placeholder={i18n.t(`${TRANS_PREFIX}:Subsections:Routine:Form:AttendsOtherSettings:placeholder`)}
            />
          </Form.Row.FlexItem>
        </Form.Row>
      </Section>
    )

    return (
      <React.Fragment>
        {renderSkills()}
        {renderRoutine()}
      </React.Fragment>
    )
  }

  const renderFooter = () => (
    <ChildFormFooter
      childId={childId}
      isChildArchived={isChildArchived}
      isEditMode={isEditMode}
      isSubmitting={isSubmitting}
      permissionToArchive={permissionToArchive}
      onArchiveClick={onArchiveClick}
      onPreviousStageClick={onPreviousStageClick}
    />
  )

  return (
    <Form onSubmit={handleSubmit}>
      {renderForm()}
      {renderFooter()}
    </Form>
  )
}

const mapState = (state) => ({
  daytimeSleepingBehavior: formValueSelector(CHILD_ADD_FORM)(state, 'information.daytimeSleepingBehavior'),
  englishAsAdditionalLanguage: formValueSelector(CHILD_ADD_FORM)(state, 'profile.englishAsAdditionalLanguage'),
  specialEducationalNeeds: formValueSelector(CHILD_ADD_FORM)(state, 'profile.specialEducationalNeeds'),
})

const enhance = compose(
  reduxForm({ destroyOnUnmount: false, form: CHILD_ADD_FORM }),
  connect(mapState),
)

export default enhance(DevelopmentInformationForm)
