import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as transactionsSelectors from './selectors'

const withTransactionsService = (WrappedComponent) => {
  const mapState = ({ transactions }) => ({ transactions })

  const mapDispatch = injectActions('transactionsActions', actions)

  const Component = ({ transactions, transactionsActions, ...others }) => (
    <WrappedComponent
      transactionsActions={transactionsActions}
      transactionsListState={transactions.list}
      transactionsSelectors={transactionsSelectors}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withTransactionsService
