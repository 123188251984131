import { generateBasicActions } from 'utils/service'

import {
  CLEAR_LEGACY_FRAMEWORKS,
  LEGACY_LIST_FRAMEWORKS,
  LEGACY_LIST_FRAMEWORKS_FAILED,
  LEGACY_LIST_FRAMEWORKS_SUCCESS,
} from './constants'

export const legacyList = (options = {}) => ({ frameworksApiClient }) => generateBasicActions.list({
  constants: {
    failed: LEGACY_LIST_FRAMEWORKS_FAILED,
    init: LEGACY_LIST_FRAMEWORKS,
    success: LEGACY_LIST_FRAMEWORKS_SUCCESS,
  },
  options,
  service: frameworksApiClient,
  serviceMethod: 'legacyList',
})

export const clearLegacyList = () => ({
  type: CLEAR_LEGACY_FRAMEWORKS,
})
