import {
  CLEAR_LEAVE_TYPE,
  CREATE_LEAVE_TYPE,
  CREATE_LEAVE_TYPE_FAILED,
  CREATE_LEAVE_TYPE_SUCCESS,
  GET_LEAVE_TYPE,
  GET_LEAVE_TYPE_FAILED,
  GET_LEAVE_TYPE_SUCCESS,
  UPDATE_LEAVE_TYPE,
  UPDATE_LEAVE_TYPE_FAILED,
  UPDATE_LEAVE_TYPE_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case GET_LEAVE_TYPE:
      return {
        ...state,
        error: initialState.error,
        isFetching: true,
      }
    case GET_LEAVE_TYPE_SUCCESS:
      return {
        ...state,
        data: payload.data,
        isFetching: false,
      }
    case GET_LEAVE_TYPE_FAILED:
      return {
        ...state,
        error,
        isFetching: false,
      }
    case CREATE_LEAVE_TYPE:
    case UPDATE_LEAVE_TYPE:
      return {
        ...state,
        error: initialState.error,
        isSubmitting: true,
      }
    case CREATE_LEAVE_TYPE_SUCCESS:
    case UPDATE_LEAVE_TYPE_SUCCESS:
      return {
        ...state,
        data: payload.data,
        isSubmitting: false,
      }
    case CREATE_LEAVE_TYPE_FAILED:
    case UPDATE_LEAVE_TYPE_FAILED:
      return {
        ...state,
        error,
        isSubmitting: false,
      }
    case CLEAR_LEAVE_TYPE:
      return initialState
    default:
      return state
  }
}
