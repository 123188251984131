import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as securitySelectors from './selectors'

export interface withSecurityServiceProps {
  securityActions: typeof actions
  securitySelectors: typeof securitySelectors
}

const withSecurityService = (WrappedComponent) => {
  const mapDispatch = injectActions('securityActions', actions)

  const Component = ({ securityActions, ...other }) => (
    <WrappedComponent
      securityActions={securityActions}
      securitySelectors={securitySelectors}
      {...other}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(null, mapDispatch)(Component)
}

export default withSecurityService
