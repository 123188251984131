import _ from 'lodash'

import React from 'react'

import { generateRoute } from 'utils/routing'

import { NurseryDiscount, NurseryDiscountTypesV3 } from 'services/product/nurseryDiscountsV3/constants'

import { Button, Currency } from 'components'

import i18n from 'translations'

export interface DiscountListTableDataProps {
  discount?: string | JSX.Element
  name?: string
  rowToButton?: JSX.Element
}

export const getTableData = (discountsList: NurseryDiscount[]): DiscountListTableDataProps[] => {
  if (!discountsList?.length) {
    return null
  }

  return _.map<NurseryDiscount, DiscountListTableDataProps>(discountsList, ({
    archived,
    id,
    name,
    settings,
    type,
  }) => ({
    discount: NurseryDiscountTypesV3.PERCENTAGE === type
      ? `${settings.value / 100}%` : <Currency value={settings.value / 100} />,
    name: `${name}${archived ? ` (${i18n.t('global:archived')})` : ''}`,
    rowToButton: (
      <Button.TableAction
        to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.DISCOUNTS.EDIT', { itemId: id })}
        edit
      />
    ),
  }))
}
