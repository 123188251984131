import moment from 'moment'

import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'
import { AUTH_TYPE_DIRECT } from 'services/device/constants'

import { isRequired } from 'utils/fieldValidation'

import { Button, FooterActions, Form, Section, Typography } from 'components'

import i18n from 'translations'

export const ADD_DEVICE_FORM = 'EditDeviceForm'

const EditDeviceForm = ({
  deviceItem,
  handleSubmit,
  isSubmitting,
  onSubmit,
}) => {
  const renderPinDetails = () => {
    if (deviceItem?.pin.usedAt) {
      return (
        <Typography color={NEUTRAL_COLOURS.GREEN}>
          {i18n.t('module:Management:Security:AuthorisedDevices:EditDevice:pinAuthorised')}
        </Typography>
      )
    }

    if (moment().unix() > moment(deviceItem?.pin.expiresAt).unix()) {
      return (
        <Typography color={FLAG_COLOURS.ERROR}>
          {i18n.t('module:Management:Security:AuthorisedDevices:EditDevice:pinExpired')}
        </Typography>
      )
    }

    return (
      <Typography color={FLAG_COLOURS.ERROR}>
        {deviceItem?.pin.digits}
        {' - '}
        {i18n.t('module:Management:Security:AuthorisedDevices:EditDevice:pinNotAuthorised')}
      </Typography>
    )
  }

  const renderDirectForm = () => (
    <React.Fragment>
      <Form.Row
        label={i18n.t('module:Management:Security:AuthorisedDevices:global:name')}
        width={{ field: '400px' }}
        required
      >
        <Field
          component={Form.TextField}
          disabled={isSubmitting}
          name="name"
          placeholder={i18n.t('module:Management:Security:AuthorisedDevices:global:name')}
          validate={isRequired}
        />
      </Form.Row>
      <FooterActions noBorder>
        <FooterActions.Group>
          <FooterActions.Item>
            <Button
              isLoading={isSubmitting}
              label={i18n.t('module:Management:Security:AuthorisedDevices:EditDevice:updateDevice')}
              submit
            />
          </FooterActions.Item>
        </FooterActions.Group>
      </FooterActions>
    </React.Fragment>
  )

  const renderPinForm = () => (
    <React.Fragment>
      <Form.Row
        label={i18n.t('module:Management:Security:AuthorisedDevices:global:name')}
        width={{ field: '400px' }}
        required
      >
        <Field
          component={Form.TextField}
          disabled={isSubmitting}
          name="name"
          placeholder={i18n.t('module:Management:Security:AuthorisedDevices:global:name')}
          validate={isRequired}
        />
      </Form.Row>
      <Form.Row label={i18n.t('module:Management:Security:AuthorisedDevices:EditDevice:dateAuthorised')}>
        <Typography padding="11.5px 0">
          {moment(deviceItem?.createdAt).calendar()}
        </Typography>
      </Form.Row>
      <Form.Row label={i18n.t('module:Management:Security:AuthorisedDevices:EditDevice:staffMember')}>
        <Typography padding="11.5px 0">
          {deviceItem?.pin.user.name}
        </Typography>
      </Form.Row>
      <Form.Row label={i18n.t('module:Management:Security:AuthorisedDevices:EditDevice:authorisationPin')}>
        <Typography padding="11.5px 0">
          {renderPinDetails()}
        </Typography>
      </Form.Row>
      <Section title={i18n.t('module:Management:Security:AuthorisedDevices:EditDevice:generateANewPin')}>
        <Form.Row
          label={i18n.t('module:Management:Security:AuthorisedDevices:global:staffMember')}
          width={{ field: '300px' }}
          required
        >
          <Field
            component={Form.InfiniteDropdowns.Users}
            disabled={isSubmitting}
            name="user"
            placeholder={i18n.t('module:Management:Security:AuthorisedDevices:global:staffMember')}
            validate={isRequired}
          />
        </Form.Row>
      </Section>
      <FooterActions noBorder>
        <FooterActions.Group>
          <FooterActions.Item>
            <Button
              isLoading={isSubmitting}
              label={i18n.t('module:Management:Security:AuthorisedDevices:EditDevice:regeneratePin')}
              submit
            />
          </FooterActions.Item>
        </FooterActions.Group>
      </FooterActions>
    </React.Fragment>
  )

  const renderContent = () => {
    if (AUTH_TYPE_DIRECT === deviceItem?.method) {
      return renderDirectForm()
    }

    return renderPinForm()
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {renderContent()}
    </Form>
  )
}

export default reduxForm({
  form: ADD_DEVICE_FORM,
})(EditDeviceForm)
