import React, { useEffect } from 'react'

const SnowEffect = () => {
  useEffect(() => {
    let embedimSnow = document.getElementById('embedim--snow')

    setTimeout(() => {
      /* https://embed.im/snow */
      if (!embedimSnow) {
        const embRand = (a, b) => Math.floor(Math.random() * (b - a + 1)) + a

        // eslint-disable-next-line max-len
        let embCSS = '.embedim-snow{position: absolute;width: 10px;height: 10px;background: white;border-radius: 50%;margin-top:-10px}'
        let embHTML = ''
        // eslint-disable-next-line no-plusplus
        for (let i = 1; 200 > i; i++) {
          embHTML += '<i class="embedim-snow"></i>'
          const rndX = (embRand(0, 1000000) * 0.0001)
          const rndO = embRand(-100000, 100000) * 0.0001
          const rndT = (embRand(3, 8) * 10).toFixed(2)
          const rndS = (embRand(0, 10000) * 0.0001).toFixed(2)
          // eslint-disable-next-line no-useless-concat, max-len
          embCSS += `.embedim-snow:nth-child(${i}){` + `opacity:${(embRand(1, 10000) * 0.0001).toFixed(2)};` + `transform:translate(${rndX.toFixed(2)}vw,-10px) scale(${rndS});` + `animation:fall-${i} ${embRand(10, 30)}s -${embRand(0, 30)}s linear infinite` + '}' + `@keyframes fall-${i}{${rndT}%{` + `transform:translate(${(rndX + rndO).toFixed(2)}vw,${rndT}vh) scale(${rndS})` + '}' + 'to{' + `transform:translate(${(rndX + (rndO / 2)).toFixed(2)}vw, 105vh) scale(${rndS})` + '}' + '}'
        }
        embedimSnow = document.createElement('div')
        embedimSnow.id = 'embedim--snow'
        // eslint-disable-next-line max-len
        embedimSnow.innerHTML = `<style>#embedim--snow{position:fixed;left:0;top:0;bottom:0;width:100vw;height:100vh;overflow:hidden;z-index:9999999;pointer-events:none}${embCSS}</style>${embHTML}`
        document.body.appendChild(embedimSnow)
      }
    }, 1000)

    return () => {
      if (embedimSnow) {
        embedimSnow.remove()
      }
    }
  }, [])

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <React.Fragment />
  )
}

export default SnowEffect
