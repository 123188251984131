import {
  CLEAR_USER_SETTINGS,
  GET_USER_SETTINGS,
  GET_USER_SETTINGS_FAILED,
  GET_USER_SETTINGS_SUCCESS,
  UPDATE_USER_SETTINGS,
  UPDATE_USER_SETTINGS_FAILED,
  UPDATE_USER_SETTINGS_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  isFetching: true,
  isSubmitting: false,
}

export interface UsersSettingsState {
  data: any
  isFetching: boolean
  isSubmitting: boolean
}

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_USER_SETTINGS:
      return {
        ...state,
        isFetching: true,
      }
    case GET_USER_SETTINGS_SUCCESS: {
      const { userSettings } = payload || {}

      return {
        ...state,
        data: userSettings,
        isFetching: false,
      }
    }
    case GET_USER_SETTINGS_FAILED:
      return {
        ...state,
        isFetching: false,
      }
    case UPDATE_USER_SETTINGS:
      return {
        ...state,
        isSubmitting: true,
      }
    case UPDATE_USER_SETTINGS_SUCCESS: {
      const { data: { userSettings } } = payload

      return {
        ...state,
        data: userSettings,
        isSubmitting: false,
      }
    }
    case UPDATE_USER_SETTINGS_FAILED:
      return {
        ...state,
        isSubmitting: false,
      }
    case CLEAR_USER_SETTINGS:
      return initialState
    default:
      return state
  }
}
