import _ from 'lodash'

import React, { useEffect, useMemo, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'
import { Option } from 'constants/models'
import { Period, Type } from 'services/booking/periods/models'
import { withRouterProps } from 'services/router/constants'
import { ROLES } from 'constants/security'

import { withAppService, withAppServiceProps } from 'services/app'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withPaginationUtils, withPaginationUtilsProps } from 'services/utils/pagination'
import { withSortUtilsProps, withSortingUtils } from 'services/utils/sorting'
import { withPeriodsService, withPeriodsServiceProps } from 'services/booking/periods'
import { withSnackbarService, withSnackbarServiceProps } from 'services/utils/snackbar'

import { ModalType } from 'modals'

import i18n from 'translations'

import AttendancePeriodsView from './AttendancePeriodsView'
import { ATTENDANCE_PERIOD_TYPE_OPTIONS, STATUS_FILTER, STATUS_OPTIONS } from './constant'
import { getTableData } from './helpers'

const PERIOD_GROUPS = {
  read: [
    'period.date',
  ],
}

const DEFAULT_SORT = {
  sortField: 'name',
  sortOrder: 'ASC',
}

type AttendanceContainerProps = withAppServiceProps
  & withPaginationUtilsProps
  & withSortUtilsProps
  & withPeriodsServiceProps
  & withRouterProps
  & withModalServiceProps
  & withSnackbarServiceProps

const mapState = (state: RootState, {
  appSelectors,
  periodsListState,
  periodsSelectors,
}: AttendanceContainerProps) => ({
  errorMessages: appSelectors.getErrorMessages(periodsListState),
  isFetching: appSelectors.getIsFetching(periodsListState),
  periods: periodsSelectors.getPeriodsList(state),
  totalResults: appSelectors.getTotalResults(periodsListState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const AttendancePeriodsContainer: React.FC<AttendanceContainerProps & PropsFromRedux> = ({
  errorMessages,
  isFetching,
  location,
  modalActions,
  paginationUtils,
  params,
  periods,
  periodsActions,
  periodsSelectors,
  setLocationQuery,
  snackbarActions,
  sortingUtils,
  totalResults,
}) => {
  const [isInitialized, setIsInitialized] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState<Option>(_.find(STATUS_OPTIONS, {
    value: location?.query?.status || STATUS_FILTER.ACTIVE,
  }))

  const { getPageCount, page, perPage } = paginationUtils
  const { onSortChange, sortField, sortOrder } = sortingUtils

  const handleStatusChange = (option: Option) => {
    setSelectedStatus(option)
  }

  const getArchivedValue = () => {
    if (selectedStatus.value === STATUS_FILTER.ALL) {
      return null
    }

    return selectedStatus.value === STATUS_FILTER.ACTIVE ? 0 : 1
  }

  const fetchData = () => {
    setLocationQuery({ status: selectedStatus.value })

    const archived = getArchivedValue()
    const criteria = periodsSelectors.getCriteria({
      archived,
      nurseryAcademicYearId: params.nurseryAcademicYearId,
      type: Type.attendance,
    })

    periodsActions.list({
      params: {
        criteria,
        groups: PERIOD_GROUPS,
        order: {
          sortField: sortField || DEFAULT_SORT.sortField,
          sortOrder: sortField ? sortOrder : DEFAULT_SORT.sortOrder,
        },
        page,
      },
    })
  }

  useEffect(() => {
    if (!isInitialized) {
      fetchData()
      setIsInitialized(true)
    }
  }, [])

  useEffect(() => {
    if (isInitialized) {
      fetchData()
    }
  }, [selectedStatus.value, page])

  useEffect(() => {
    if (isInitialized) {
      const { onPageChange } = paginationUtils

      if (1 === page) {
        fetchData()

        return
      }

      onPageChange()(1)
    }
  }, [sortField, sortOrder])

  const archiveAttendancePeriod = (id: number, archived: boolean) => {
    const mode = archived ? i18n.t('global:archived') : i18n.t('global:unarchived')

    periodsActions.update({
      body: { archived },
      onSuccess: () => {
        snackbarActions.show({
          message: i18n.t('module:Management:AttendancePeriods:Add:snackbarLabel', { mode }),
        })

        fetchData()
      },
      params: [id],
    })
  }

  const handleArchiveClick = (item: Period, archived: boolean) => {
    const label = archived ? i18n.t('global:archive') : i18n.t('global:unarchive')
    const periodTypeOption = _.find(ATTENDANCE_PERIOD_TYPE_OPTIONS, { value: item.periodType })

    const text = archived
      ? i18n.t('module:Management:AttendancePeriods:archivePopupCopy', { periodType: periodTypeOption?.label })
      : i18n.t('module:Management:AttendancePeriods:unarchivePopupCopy', { periodType: periodTypeOption?.label })

    modalActions.show<ModalType.CONFIRM>(ModalType.CONFIRM, {
      confirmButtonLabel: label,
      icon: label,
      onConfirm: () => archiveAttendancePeriod(item.id, archived),
      text,
    })
  }

  const handlePageChange = (newPage) => {
    const { onPageChange } = paginationUtils

    onPageChange()(newPage)
  }

  const handleSortChange = (key) => {
    onSortChange()(key)
  }

  const tableData = useMemo(() => getTableData(periods, handleArchiveClick), [periods])
  const pageCount = getPageCount(totalResults)

  return (
    <AttendancePeriodsView
      errorMessages={errorMessages}
      isLoading={isFetching}
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      selectedStatus={selectedStatus}
      sortField={sortField}
      sortOrder={sortOrder}
      tableData={tableData}
      totalResults={totalResults}
      onPageChange={handlePageChange}
      onSortChange={handleSortChange}
      onStatusChange={handleStatusChange}
    />
  )
}

// @ts-ignore
AttendancePeriodsContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.NURSERY_MANAGER,
  ],
}

const enhance = compose(
  withAppService,
  withModalService,
  withPaginationUtils,
  withSortingUtils,
  withPeriodsService,
  withSnackbarService,
  connector,
)

export default enhance(AttendancePeriodsContainer)

