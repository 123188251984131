import { Property } from 'csstype'
import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { ZINDEX_ORDER } from 'constants/layout'

import { getBrandingColor } from 'utils/branding'

export const StyledWrapper = styled.div`
  overflow: hidden;
`

interface StyledContainerProps {
  $minWidth?: number
}

export const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  position: relative;
  min-width: ${({ $minWidth }) => `${$minWidth}px`};
  overflow: hidden;
  
  .slick-slider {
    max-width: 100%;
  }
`

interface StyledHeadProps {
  $borderRight?: boolean
  $width?: number
}

export const StyledHead = styled.div<StyledHeadProps>`
  min-width: 180px;
  
  ${({ $width }) => $width && `
    min-width: ${$width}px;
  `}

  ${({ $borderRight }) => $borderRight && `
    border-right: 1px solid #adadad;
  `}
  
  @media(max-width: 480px) {
    min-width: 130px;
    overflow: hidden;
  }
`

export const StyledGroupHeader = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`

interface StyledColumnProps {
  $borderRight?: boolean
  $noBorder?: boolean
  $noHidden?: boolean
}

export const StyledColumn = styled.div<StyledColumnProps>`
  flex: 1;
  border-left: 1px solid #ADAEAE;
  height: 100%;
  
  ${({ $noBorder }) => $noBorder && `
    border-left: transparent;
  `}
  
  ${({ $noHidden }) => $noHidden && `
    display: block !important;
  `}

  ${({ $borderRight }) => $borderRight && `
    border-right: 1px solid #ADAEAE;
  `}
`

interface StyledContentContainerProps {
  $width?: number
}

export const StyledContentContainer = styled.div<StyledContentContainerProps>`
  ${({ $width }) => $width && `
    min-width: ${$width}px;
    
    @media(max-width: 550px) {
      width: auto;
    }
  `}
`

interface StyledRowProps {
  $alignItems?: Property.AlignItems
  $background?: string
  $border?: boolean
  $borderTransparent?: boolean
  $column?: boolean
  $darkGray?: boolean
  $full?: boolean
  $gray?: boolean
  $height?: number
  $marginBottom?: boolean
  $minWidth?: number
  $noBorder?: boolean
  $noPadding?: boolean
  $padding?: string
  $quinary?: boolean
  $rows?: number
  $textAlign?: Property.TextAlign
  $width?: number
}

export const StyledRow = styled.div<StyledRowProps>`
  min-width: 64px;
  padding: 0 14px;
  height: 44px;
  border-bottom: 1px solid #DCDCDC;
  display: flex;
  align-items: center;
  
  ${({ $justifyContent }) => $justifyContent && `
    justify-content: ${$justifyContent} !important;
  `}

  ${({ $rows }) => $rows && `
    height: ${$rows * 44}px;
  `}

  ${({ $noPadding }) => $noPadding && `
    padding: 0;
  `}

  ${({ $noBorder }) => $noBorder && `
    border-bottom-color: transparent;
  `}
  
  ${({ $quinary }) => $quinary && `
    border-bottom-color: transparent;
    background-color: ${getBrandingColor('quinary-color')};
  `}
  
  ${({ $gray }) => $gray && `
    background: #FAFAFA;
  `}
  
  ${({ $darkGray }) => $darkGray && `
    background: #ECECEC;
  `}

  ${({ $width }) => $width && `
    width: ${$width}px;
  `}

  ${({ $minWidth }) => $minWidth && `
    min-width: ${$minWidth}px;
  `}
  
  ${({ $background }) => $background && `
    background: ${$background};
  `}

  ${({ $padding }) => $padding && `
    padding: ${$padding};
  `}

  ${({ $isSticky }) => $isSticky && `
    position: fixed;
    background: ${NEUTRAL_COLOURS.WHITE};
    z-index: ${ZINDEX_ORDER.STICKY_TABLE};
    justify-content: flex-start;
    overflow: hidden;
    min-width: 0;
  `}
  
  ${({ $borderTransparent }) => $borderTransparent && `
    position: relative;
    
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -1px;
      bottom: 0;
      width: 1px;
      background: ${getBrandingColor('quinary-color')};
    }
  `}
  
  ${({ $marginBottom }) => $marginBottom && `
    margin-bottom: 25px;
    position: relative;
    
    &:before, &:after {
      content: '';
      position: absolute;
      bottom: -26px;
      left: -1px;
      height: 25px;
      width: 1px;
      background: ${NEUTRAL_COLOURS.WHITE};
    }
    
    &:after {
      left: auto;
      right: -1px;
    }
  `}
  
  ${({ $full }) => $full && `
    height: 100%;
  `}
  
  ${({ $fullWidth }) => $fullWidth && `
    width: 100%;
  `}

  ${({ $border }) => $border && `
    border: ${$border};
  `}

  & > ${StyledColumn}:nth-of-type(1) {
    border-left: none;
  }

  ${({ $height }) => $height && `
    height: ${$height}px;
  `}

  ${({ $alignItems }) => $alignItems && `
    align-items: ${$alignItems};
  `}
  
  ${({ $column }) => $column && `
    flex-direction: column;
  `}

  ${({ $textAlign }) => $textAlign && `
    text-align: ${$textAlign} !important;
  `}  

  ${({ onClick }) => onClick && `
    &:hover {
      cursor: pointer;
    }
  `}
`

interface StyledContentProps {
  $hideContent?: boolean
}

export const StyledContent = styled.div<StyledContentProps>`
  flex: 1;
  display: flex;
  
  & ${StyledRow} {
    text-align: center;
    justify-content: center;
  }
  
  ${({ $hideContent }) => $hideContent && `
    & > ${StyledColumn} {
      display: none;
    }
  `}
`

export const StyledSeparator = styled.div`
  min-width: 30px;
  padding: 10px 0;
  background: #EDEDED;
  text-align: center;
  cursor: pointer;
`

export const StyledContentScrollable = styled.div`
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  display: flex;
  position: relative;
`

interface StyledCustomScrollbarWrapperProps {
  $isVisible?: boolean
  $width?: number
}

export const StyledCustomScrollbarWrapper = styled.div<StyledCustomScrollbarWrapperProps>`
  position: fixed;
  height: 16px;
  bottom: 0;
  overflow-x: scroll;
  opacity: 1;

  ${({ $isVisible }) => $isVisible && `
    opacity: 0 !important;
  `}
  
  ${({ $width }) => $width && `
    width: ${$width}px;
  `}
`

interface StyledImitationContentWidthProps {
  $width?: number
}

export const StyledImitationContentWidth = styled.div<StyledImitationContentWidthProps>`
  height: 1px;

  ${({ $width }) => $width && `
    width: ${$width}px;
  `}
`

export const StyledEndOfContent = styled.div`
  position: absolute;
  bottom: 0;
  opacity: 0;
  height: 1px;
  width: 100%;
`

export const StyledStickyHeader = styled.div`
  position: fixed;
  display: none;
  overflow: hidden;
  z-index: ${ZINDEX_ORDER.STICKY_TABLE};
  contain: content;
`

export const StyledAbsoluteWrapper = styled.div`
  position: absolute;
  contain: content;
  left: 0;
  right: 0;
`

export const StyledStickyHeaderContainer = styled.div`
  display: flex;  
`

export const StyledSortableWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 20px;
  grid-gap: 10px;
  align-items: center;
`
