import React from 'react'

import { ModalBox } from 'components'

import SelectFormatModalForm from './components/SelectFormatModalForm'

const SelectFormatModalView = ({
  formatOptions,
  onCloseClick,
  onSubmit,
  title = 'Select format type',
}) => (
  <ModalBox
    title={title}
    autoHeight
    onCloseClick={onCloseClick}
  >
    <SelectFormatModalForm
      formatOptions={formatOptions}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    />
  </ModalBox>
)

export default SelectFormatModalView
