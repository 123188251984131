import { bindActionCreators } from 'redux'

import { hasMoreRecords } from 'utils/pagination'

import {
  CLEAR_REGULAR_UNAVAILABLE_TIMES_LIST,
  LIST_REGULAR_UNAVAILABLE_TIMES,
  LIST_REGULAR_UNAVAILABLE_TIMES_FAILED,
  LIST_REGULAR_UNAVAILABLE_TIMES_SUCCESS,
} from './constants'

export const listRegularUnavailableTimes = (
  options = {},
) => ({ requestedExtraSessionsApiClient }) => async (dispatch) => {
  const { mergeResult, onFailed, onSuccess, params } = options

  dispatch({ type: LIST_REGULAR_UNAVAILABLE_TIMES })

  await requestedExtraSessionsApiClient.listRegularUnavailableTimes(params).then(
    (response) => {
      dispatch({
        mergeResult,
        payload: { done: true, response },
        type: LIST_REGULAR_UNAVAILABLE_TIMES_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: LIST_REGULAR_UNAVAILABLE_TIMES_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const listRegularUnavailableTimesRecurrency = (
  options = {},
) => ({ requestedExtraSessionsApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params } = options

  dispatch({ type: LIST_REGULAR_UNAVAILABLE_TIMES })

  return requestedExtraSessionsApiClient.listRegularUnavailableTimes(params).then(
    (response) => {
      const { meta } = response

      if (hasMoreRecords(meta)) {
        dispatch({
          mergeResult: 1 !== meta.start,
          payload: { response },
          type: LIST_REGULAR_UNAVAILABLE_TIMES_SUCCESS,
        })

        return bindActionCreators(listRegularUnavailableTimesRecurrency, dispatch)({
          onFailed,
          onSuccess,
          params: { ...params, page: (meta.start + 1) },
        })
      }

      dispatch({
        mergeResult: 1 !== meta.start,
        payload: { done: true, response },
        type: LIST_REGULAR_UNAVAILABLE_TIMES_SUCCESS,
      })

      if (onSuccess) {
        return onSuccess(response)
      }

      return null
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: LIST_REGULAR_UNAVAILABLE_TIMES_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const clearRegularUnavailableTimesList = () => ({
  type: CLEAR_REGULAR_UNAVAILABLE_TIMES_LIST,
})
