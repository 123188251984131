import { Request } from 'constants/http'

import { isPlainObject } from 'utils/data'

const normalizeNullProperties = (object) => {
  if (isPlainObject(object)) {
    return Object.keys(object).reduce((copy, key) => {
      const value = object[key]
      const newCopy = { ...copy }

      if (null === value) {
        return newCopy
      }

      newCopy[key] = normalizeNullProperties(value)

      return newCopy
    }, {})
  }

  if (Array.isArray(object)) {
    return object.map((item) => normalizeNullProperties(item))
  }

  return object
}

// If this is an object of some kind then iterate its properties and return an object without empty strings
const RemoveNullResponseValuesMiddleware = async (request: Request, next) => {
  const response = await next(request)
  const json = response.json

  // eslint-disable-next-line func-names
  response.json = async function () {
    return normalizeNullProperties(await json.call(this))
  }

  return response
}

export default RemoveNullResponseValuesMiddleware

