import { generateBasicActions } from 'utils/service'

import { CLEAR_LIST_FOOD_TYPES, LIST_FOOD_TYPES, LIST_FOOD_TYPES_FAILED, LIST_FOOD_TYPES_SUCCESS } from './constants'

export const list = (options = {}) => ({ foodTypesApiClient }) => generateBasicActions.list({
  constants: {
    failed: LIST_FOOD_TYPES_FAILED,
    init: LIST_FOOD_TYPES,
    success: LIST_FOOD_TYPES_SUCCESS,
  },
  options,
  service: foodTypesApiClient,
  serviceMethod: 'list',
})

export const clearList = () => ({
  type: CLEAR_LIST_FOOD_TYPES,
})
