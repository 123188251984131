import { mergeData } from 'utils/data'

import { CLEAR_LEAVE_NOTES, LIST_LEAVE_NOTES, LIST_LEAVE_NOTES_FAILED, LIST_LEAVE_NOTES_SUCCESS } from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: null,
}

export default (state = initialState, { error, mergeResult, payload, type }) => {
  switch (type) {
    case LIST_LEAVE_NOTES:
      return { ...state, error: initialState.error, isFetching: true }
    case LIST_LEAVE_NOTES_SUCCESS:
      return {
        ...initialState,
        data: mergeData(state.data, payload.data, mergeResult),
        isFetching: false,
        meta: payload.meta,
      }
    case LIST_LEAVE_NOTES_FAILED:
      return { ...state, error, isFetching: false }
    case CLEAR_LEAVE_NOTES:
      return initialState
    default:
      return state
  }
}
