import i18n from 'translations'

export const TOILET_TRAINING_TYPES = {
  POTTY: 'POTTY',
  TOILET: 'TOILET',
}

export const TOILET_TRAINING_OPTIONS = [
  {
    label: i18n.t('services:DailyDiaryNappies:toiletTrainingOptions:Potty'),
    value: TOILET_TRAINING_TYPES.POTTY,
  },
  {
    label: i18n.t('services:DailyDiaryNappies:toiletTrainingOptions:Toilet'),
    value: TOILET_TRAINING_TYPES.TOILET,
  },
]
