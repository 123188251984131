import React from 'react'

import RegisterItemMainActions from '../RegisterItemMainActions'
import RegisterItemAction from '../RegisterItemAction'

import { REGISTER_ACTION_TYPES, REGISTER_COLORS } from '../../../constants'

const RegisterItemContentComment = ({
  absence,
  expandType,
  oldComments,
  onCommentActionClick,
  present,
  size,
}) => {
  if (absence || false === present) {
    return null
  }

  const { COMMENT } = REGISTER_ACTION_TYPES

  if (oldComments && expandType === COMMENT) {
    return (
      <RegisterItemMainActions.Item size={size}>
        <RegisterItemAction
          color={REGISTER_COLORS.comment}
          hasData={!!oldComments}
          icon="chevron-up"
          title="Hide"
          onClick={onCommentActionClick}
        />
      </RegisterItemMainActions.Item>
    )
  }

  return (
    <RegisterItemMainActions.Item size={size}>
      <RegisterItemAction
        badge={!!oldComments}
        badgeColor={REGISTER_COLORS.badge}
        color={REGISTER_COLORS.comment}
        hasData={!!oldComments || expandType === COMMENT}
        icon="comment"
        title={oldComments ? 'Comment details' : 'Comment'}
        onClick={onCommentActionClick}
      />
    </RegisterItemMainActions.Item>
  )
}

export default RegisterItemContentComment
