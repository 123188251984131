import moment from 'moment'
import _ from 'lodash'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { isToday } from 'utils/date'

import { CLEAR_OFFLINE_ACTIONS, LIST_REGISTERS_GLOBAL_SUCCESS, UPDATE_OFFLINE_ACTION } from './constants'
import { CREATE_REGISTER, CREATE_REGISTER_SUCCESS, UPDATE_REGISTER, UPDATE_REGISTER_SUCCESS } from '../single/constants'
import {
  ADD_OR_UPDATE_ABSENCE_TO_LIST,
  REMOVE_ABSENCE_FROM_LIST,
  REMOVE_ITEM_FROM_LIST,
  RESET_LIST_ITEM,
} from '../list/constants'

import {
  addOrUpdateAbsenceToList,
  getUpdateRegisterData,
  getUpdateRegisterSuccessData,
  removeAbsenceFromList,
  removeItemFromList,
  resetListItem,
} from '../helpers'

const initialState = {
  data: null,
  offlineActions: {},
}

export default (state = initialState, {
  absenceResponse,
  childId,
  id,
  isChildRegister,
  payload,
  resetResponse,
  type,
}) => {
  switch (type) {
    case LIST_REGISTERS_GLOBAL_SUCCESS: {
      return {
        ...state,
        ...payload,
        data: _.map(payload.data, (item) => ({
          ...item,
          date: moment().format('x'),
        })),
      }
    }
    case UPDATE_OFFLINE_ACTION: {
      return {
        ...state,
        offlineActions: payload,
      }
    }
    case CLEAR_OFFLINE_ACTIONS: {
      return {
        ...state,
        offlineActions: {},
      }
    }
    case CREATE_REGISTER:
      if (!isToday(moment(payload.entryDate, DEFAULT_DATE_FORMAT))) {
        return {
          ...state,
        }
      }

      return {
        ...state,
        data: getUpdateRegisterData(state, childId, id),
      }
    case CREATE_REGISTER_SUCCESS:
      if (!isToday(moment(payload.entryDate, DEFAULT_DATE_FORMAT))) {
        return {
          ...state,
        }
      }

      return {
        ...state,
        data: getUpdateRegisterSuccessData(state, childId, id, payload, isChildRegister),
      }
    case UPDATE_REGISTER:
      if (!isToday(moment(payload.entryDate, DEFAULT_DATE_FORMAT))) {
        return {
          ...state,
        }
      }

      return {
        ...state,
        data: getUpdateRegisterData(state, childId, id),
      }
    case UPDATE_REGISTER_SUCCESS:
      if (!isToday(moment(payload.entryDate, DEFAULT_DATE_FORMAT))) {
        return {
          ...state,
        }
      }

      return {
        ...state,
        data: getUpdateRegisterSuccessData(state, childId, id, payload, isChildRegister),
      }
    case ADD_OR_UPDATE_ABSENCE_TO_LIST:
      if (!isToday(moment(payload.entryDate, DEFAULT_DATE_FORMAT))) {
        return {
          ...state,
        }
      }

      return {
        ...state,
        data: addOrUpdateAbsenceToList(state, childId, absenceResponse),
      }
    case REMOVE_ABSENCE_FROM_LIST:
      if (!isToday(moment(payload.entryDate, DEFAULT_DATE_FORMAT))) {
        return {
          ...state,
        }
      }

      return {
        ...state,
        data: removeAbsenceFromList(state, childId),
      }
    case RESET_LIST_ITEM:
      if (!isToday(moment(payload.entryDate, DEFAULT_DATE_FORMAT))) {
        return {
          ...state,
        }
      }

      return {
        ...state,
        data: resetListItem(state, childId, id, resetResponse),
      }
    case REMOVE_ITEM_FROM_LIST:
      if (!isToday(moment(payload.entryDate, DEFAULT_DATE_FORMAT))) {
        return {
          ...state,
        }
      }

      return {
        ...state,
        data: removeItemFromList(state, childId),
      }
    default:
      return state
  }
}
