import styled from 'styled-components'

import colors, { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledContainer = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  margin-bottom: 15px;
  border-bottom: 1px dashed ${NEUTRAL_COLOURS.SILVER};
  transition: .4s;
`

export const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: ${NEUTRAL_COLOURS.SILVER};
  color: ${colors.remove};
  font-size: 14px;
  font-weight: 600;
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;
  }
`

export const StyledErrorItemContainer = styled.div`
  display: flex;
`

export const StyledErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  & ${StyledErrorItemContainer} ~ ${StyledErrorItemContainer} {
    margin-top: 15px;
  }
`

export const StyledErrorAvatarContainer = styled.div`
  margin-right: 10px;
`

export const StyledErrorTextContainer = styled.div`
  color: ${colors.gray};
  font-size: 14px;
`

export const StyledChildNameContainer = styled.div`
  color: ${getBrandingColor('primary-color')};
  font-weight: 600;
  margin-bottom: 3px;
`
