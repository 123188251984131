import color from 'color'
import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { DiaryType } from './DiaryItem'

export const StyledWrapper = styled.div`
  background: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  box-shadow: 0 1px 6px rgba(0, 0, 0, .1);
  border-radius: 12px;
  margin-bottom: 12px;
`

export const StyledWrapperContent = styled.div`
  display: grid;
  padding: 24px;
  align-items: center;
  grid-column-gap: 24px;
  grid-template-columns: 40px 40px auto;
  
  ${({ $hasAccessToLikesAndComments }) => $hasAccessToLikesAndComments && `
    grid-template-columns: 40px 40px auto 160px 60px 30px;
  `}
`

interface StyledIconWrapperProps {
  $type: DiaryType
}

export const StyledIconWrapper = styled.div<StyledIconWrapperProps>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  ${({ $type }) => $type && `
    background: ${color($type.color).alpha(0.3).string()};
  `}
`

export const StyledHr = styled.div`
  height: 1px;
  background: ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  margin: 0 24px 32px;
`

export const StyledMediaPickerWrapper = styled.div`
  padding: 0 20px 5px 85px;
  margin-top: -20px;
  text-align: left;
  
  table {
    th:nth-of-type(1) {
      width: 75%;
    }
    
    th:nth-of-type(2) {
      width: 25%;
    }
  }
`
