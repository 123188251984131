import _ from 'lodash'

import React from 'react'
import { compose } from 'recompose'
import { Field, FieldArray, reduxForm } from 'redux-form'

import ImagesSize from 'constants/imagesSize'

import { isEmailValid, isRequired, isValidPhotoUploadType } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'
import { getBrandingColor } from 'utils/branding'

import { Banner, CircleIcon, Form, Section, Space, Tooltip, Typography } from 'components'

import i18n from 'translations'
import { INDEPENDENT_NURSERY_OPTION } from 'services/nurseries/constants'

export const MANAGEMENT_NURSERY_EDIT_FORM = 'ManagementNurseryEditForm'

const renderOpeningDays = ({ fields, onApplyAll }) => fields.map((field, i) => {
  const fieldValues = fields.get(i)
  const { endTime, isChecked, label, startTime } = fieldValues
  const validate = isChecked && isRequired

  return (
    <Form.Row key={_.kebabCase(fieldValues.day)}>
      <Form.Row.FlexItem flex="0 0 150px">
        <Field
          component={Form.Checkbox}
          label={label}
          name={`${field}.isChecked`}
        />
      </Form.Row.FlexItem>
      <Form.Row.FlexItem flex="0">
        <Field
          component={Form.TimePicker}
          disabled={!isChecked}
          local={false}
          name={`${field}.startTime`}
          validate={validate}
        />
      </Form.Row.FlexItem>
      <Form.Row.TextItem text={i18n.t('global:To')} />
      <Form.Row.FlexItem flex="0">
        <Field
          component={Form.TimePicker}
          disabled={!isChecked}
          local={false}
          name={`${field}.endTime`}
          validate={validate}
        />
      </Form.Row.FlexItem>
      <Form.Row.TextItem
        color={getBrandingColor('primary-color')}
        cursor="pointer"
        disabled={!isChecked}
        text={i18n.t('module:Management:Nursery:applyToAll')}
        labelBold
        onClick={() => onApplyAll(startTime, endTime, fields)}
      />
    </Form.Row>
  )
})

const ManagementNurseryEditForm = ({
  cancelLink = generateRoute('MANAGEMENT.NURSERY'),
  daysOptions,
  financeLocked,
  handleSubmit,
  isLoading,
  isOrganizationNursery,
  showPrimaryManager,
  onSubmit,
  onApplyAll,
}) => {
  const currencyIsDisabled = isOrganizationNursery || financeLocked

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Section title={i18n.t('module:Management:Nursery:title')}>
        <Typography margin="20px 0">
          {i18n.t('module:Management:Nursery:subtitle')}
        </Typography>
        <Form.Row label={i18n.t('module:Management:Nursery:nurseryName')} required>
          <Form.Row.FlexItem flex="0 0 400px">
            <Field
              component={Form.TextField}
              name="name"
              placeholder={i18n.t('module:Management:Nursery:nurseryName')}
              validate={isRequired}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row label={i18n.t('module:Organisations:Add:Form:organisation')}>
          <Form.Row.FlexItem mobileFull>
            <Field
              clearable={false}
              component={Form.InfiniteDropdowns.Organisations}
              extraOptions={[INDEPENDENT_NURSERY_OPTION]}
              name="organization"
              placeholder={i18n.t('module:Organisations:Add:Form:organisation')}
              disabled
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row label={i18n.t('module:Management:Nursery:logo')}>
          <Form.Row.FlexItem flex="0 0 400px">
            <Field
              component={Form.PhotoUpload}
              imageSize={ImagesSize.NURSERY_LOGO}
              name="logo"
              validate={isValidPhotoUploadType}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row
          label={i18n.t('module:Organisations:Add:Form:country')}
          required
        >
          <Form.Row.FlexItem mobileFull>
            <Field
              clearable={false}
              component={Form.InfiniteDropdowns.SupportedLocale}
              name="nurserySettings.locale"
              placeholder={i18n.t('module:Organisations:Add:Form:country')}
              validate={[isRequired]}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row
          label={i18n.t('module:Organisations:Add:Form:currency')}
          required={!currencyIsDisabled}
        >
          <Form.Row.FlexItem mobileFull>
            <Field
              clearable={false}
              component={Form.InfiniteDropdowns.SupportedCurrency}
              disabled={currencyIsDisabled}
              name="nurserySettings.currency"
              placeholder={i18n.t('module:Organisations:Add:Form:currency')}
              validate={currencyIsDisabled ? [] : [isRequired]}
            />
            <Space space="10px" />
            <Banner.Info>
              {isOrganizationNursery
                ? i18n.t('module:Organisations:Add:Form:nurseryHaveToOperateInTheSameCountry')
                : i18n.t('module:Organisations:Add:Form:currencyCannotBeChangedIndividualNursery')}
            </Banner.Info>
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row label={i18n.t('module:Management:Nursery:address')}>
          <Form.Row.FlexItem flex="0 0 400px">
            <Field
              component={Form.TextAreaField}
              name="address"
              placeholder={i18n.t('module:Management:Nursery:address')}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row label={i18n.t('module:Management:Nursery:postcode')}>
          <Form.Row.FlexItem flex="0 0 400px">
            <Field
              component={Form.TextField}
              name="postCode"
              placeholder={i18n.t('module:Management:Nursery:postcode')}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row label={i18n.t('module:Management:Nursery:telephone')} required>
          <Form.Row.FlexItem flex="0 0 400px">
            <Field
              component={Form.TextField}
              name="telephone"
              placeholder={i18n.t('module:Management:Nursery:telephone')}
              validate={isRequired}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row label={i18n.t('module:Management:Nursery:ofstedIdentifier')}>
          <Form.Row.FlexItem flex="0 0 400px">
            <Field
              component={Form.TextField}
              name="ofstedId"
              placeholder={i18n.t('module:Management:Nursery:ofstedIdentifier')}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row label={i18n.t('module:Management:Nursery:emailAddress')}>
          <Form.Row.FlexItem flex="0 0 400px">
            <Field
              component={Form.TextField}
              name="email"
              placeholder={i18n.t('module:Management:Nursery:emailAddress')}
              validate={isEmailValid}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        {showPrimaryManager && (
          <Form.Row label={i18n.t('module:Management:Nursery:primaryManager')} required>
            <Form.Row.FlexItem flex="0 0 400px">
              <Field
                component={Form.InfiniteDropdowns.OrganizationMemberships}
                name="primaryContact"
                placeholder={i18n.t('module:Management:Nursery:primaryManager')}
                validate={isRequired}
              />
            </Form.Row.FlexItem>
          </Form.Row>
        )}
      </Section>
      <Section title={i18n.t('module:Management:Nursery:openingTimes')}>
        <FieldArray
          component={renderOpeningDays}
          name="nurserySettings.openingDays"
          rerenderOnEveryChange
          onApplyAll={onApplyAll}
        />
        <Form.Row label={i18n.t('module:Management:Nursery:startWeekFrom')} width={{ field: '190px' }} required>
          <Field
            clearable={false}
            component={Form.Select}
            name="nurserySettings.startWeekFrom"
            options={daysOptions}
            placeholder={i18n.t('module:Management:Nursery:startWeekFrom')}
            searchable={false}
            validate={isRequired}
          />
        </Form.Row>
        <Form.Row label={i18n.t('module:Management:Nursery:totalOpenWeeks')}>
          <Form.Row.FlexItem flex="0 0 100px">
            <Field
              component={Form.TextField}
              name="nurserySettings.openWeeks"
              placeholder={i18n.t('module:Management:Nursery:totalOpenWeeks')}
              type="number"
            />
          </Form.Row.FlexItem>
          <Space margin="0 10px 0 0" />
          <Form.Row.Item>
            <Tooltip
              placement="bottom-center"
              title={i18n.t('module:Management:Nursery:totalOpenWeeksTooltip')}
            >
              <CircleIcon
                cursor="pointer"
                icon="info"
                iconSize={16}
                size={24}
              />
            </Tooltip>
          </Form.Row.Item>
        </Form.Row>
      </Section>
      <Form.FooterActions
        cancelLink={cancelLink}
        isSubmitting={isLoading}
        submitLabel={i18n.t('global:Save')}
      />
    </Form>
  )
}

const enhance = compose(reduxForm({ form: MANAGEMENT_NURSERY_EDIT_FORM }))

export default enhance(ManagementNurseryEditForm)
