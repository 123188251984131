import { generateBasicActions } from 'utils/service'

import {
  CLEAR_FINANCE_REPORTS_CHILDREN_CREDIT_NOTES,
  FINANCE_REPORTS_CHILDREN_CREDIT_NOTES,
  FINANCE_REPORTS_CHILDREN_CREDIT_NOTES_FAILED,
  FINANCE_REPORTS_CHILDREN_CREDIT_NOTES_SUCCESS,
  FINANCE_REPORTS_CREDIT_NOTES_EXPORT,
  FINANCE_REPORTS_CREDIT_NOTES_EXPORT_FAILED,
  FINANCE_REPORTS_CREDIT_NOTES_EXPORT_SUCCESS,
} from './constants'

export const getChildren = (options = {}) => ({ financeCreditNotesReportApiClient }) => generateBasicActions.list({
  constants: {
    failed: FINANCE_REPORTS_CHILDREN_CREDIT_NOTES_FAILED,
    init: FINANCE_REPORTS_CHILDREN_CREDIT_NOTES,
    success: FINANCE_REPORTS_CHILDREN_CREDIT_NOTES_SUCCESS,
  },
  options,
  service: financeCreditNotesReportApiClient,
  serviceMethod: 'getChildren',
})

export const exportReport = (options = {}) => ({ financeFundingReportApiClient }) => generateBasicActions.list({
  constants: {
    failed: FINANCE_REPORTS_CREDIT_NOTES_EXPORT_FAILED,
    init: FINANCE_REPORTS_CREDIT_NOTES_EXPORT,
    success: FINANCE_REPORTS_CREDIT_NOTES_EXPORT_SUCCESS,
  },
  options,
  service: financeFundingReportApiClient,
  serviceMethod: 'export',
})

export const clearChildren = () => ({
  type: CLEAR_FINANCE_REPORTS_CHILDREN_CREDIT_NOTES,
})
