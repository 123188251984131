import _ from 'lodash'

import React, { useMemo, useState } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import { withModalService } from 'services/utils/modal'

import i18n from 'translations'

import ChildrenLeaving from './ChildrenLeaving'
import ChildrenSignedUp from './ChildrenSignedUp'
import ChildrenStarting from './ChildrenStarting'
import DietaryReport from './DietaryReport'
import KeyPersonsReport from './KeyPersonsReport'
import MonthlyFteChange from './MonthlyFteChange'
import NurseryMilk from './NurseryMilk'
import PhotoPermissionsReport from './PhotoPermissionsReport'
import ReportsView from './ReportsView'

const ReportsContainer = ({
  childrenLeavingReportReportGranted,
  childrenSignedUpReportReportGranted,
  childrenStartingReportGranted,
  dietaryReportGranted,
  emergencyReportGranted,
  keyPersonsReportGranted,
  leaversReportGranted,
  modalActions,
  modalConsts,
  monthlyFteChangeReportGranted,
  nurseryMilkReportGranted,
  photoPermissionsReportGranted,
  startersReportGranted,
}) => {
  const [search, setSearch] = useState('')

  const handleOpenEmergencyModal = () => modalActions.show(modalConsts.TYPES.EMERGENCY_CONTACT_LISTS)

  const items = useMemo(() => {
    const reportItems = [
      {
        description: i18n.t('module:Reports:EmergencyContact:description'),
        hidden: !emergencyReportGranted,
        onClick: handleOpenEmergencyModal,
        title: i18n.t('module:Reports:EmergencyContact:title'),
      },
      {
        description: i18n.t('module:Reports:KeyPerson:description'),
        hidden: !keyPersonsReportGranted,
        title: i18n.t('module:Reports:KeyPerson:title'),
        to: generateRoute('REPORTS.KEY_PERSON_LIST'),
      },
      {
        description: i18n.t('module:Reports:Dietary:description'),
        hidden: !dietaryReportGranted,
        title: _.upperFirst(i18n.t('module:Reports:Dietary:title')),
        to: generateRoute('REPORTS.DIETARY_LIST'),
      },
      {
        description: i18n.t('module:Reports:PhotoPermissions:description'),
        hidden: !photoPermissionsReportGranted,
        title: _.upperFirst(i18n.t('module:Reports:PhotoPermissions:title')),
        to: generateRoute('REPORTS.PHOTO_PERMISSIONS_LIST'),
      },
      {
        description: i18n.t('module:Reports:ChildrenSignedUp:description'),
        hidden: !childrenSignedUpReportReportGranted,
        title: _.upperFirst(i18n.t('module:Reports:ChildrenSignedUp:title')),
        to: generateRoute('REPORTS.CHILDREN_SIGNED_UP'),
      },
      {
        description: i18n.t('module:Reports:ChildrenStarting:description'),
        hidden: !childrenStartingReportGranted,
        title: _.upperFirst(i18n.t('module:Reports:ChildrenStarting:title')),
        to: generateRoute('REPORTS.CHILDREN_STARTING'),
      },
      {
        description: i18n.t('module:Reports:ChildrenLeaving:description'),
        hidden: !childrenLeavingReportReportGranted,
        title: _.upperFirst(i18n.t('module:Reports:ChildrenLeaving:title')),
        to: generateRoute('REPORTS.CHILDREN_LEAVING'),
      },
      {
        description: i18n.t('module:Reports:MonthlyFteChange:description'),
        hidden: !monthlyFteChangeReportGranted,
        title: _.upperFirst(i18n.t('module:Reports:MonthlyFteChange:title')),
        to: generateRoute('REPORTS.MONTHLY_FTE_CHANGE'),
      },
      {
        description: i18n.t('module:Reports:NurseryMilk:summaryDescription'),
        hidden: !nurseryMilkReportGranted,
        title: i18n.t('module:Reports:NurseryMilk:title'),
        to: generateRoute('REPORTS.NURSERY_MILK'),
      },
    ]

    if (search) {
      return _.filter(reportItems, ({ description, title }) => (
        title.toLowerCase().includes(search.toLowerCase())
        || description.toLowerCase().includes(search.toLowerCase())
      ))
    }

    return reportItems
  }, [
    childrenLeavingReportReportGranted,
    childrenSignedUpReportReportGranted,
    childrenStartingReportGranted,
    dietaryReportGranted,
    emergencyReportGranted,
    keyPersonsReportGranted,
    leaversReportGranted,
    monthlyFteChangeReportGranted,
    nurseryMilkReportGranted,
    photoPermissionsReportGranted,
    search,
    startersReportGranted,
  ])

  return (
    <ReportsView
      items={items}
      onSearchChange={setSearch}
    />
  )
}

ReportsContainer.authParams = {
  flags: [FEATURE_FLAGS.LJ_REPORTS],
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.ROOM_LEADER,
    ROLES.SENIOR_TEACHER,
    ROLES.TEACHER,
  ],
}

const mapState = (state) => ({
  childrenLeavingReportReportGranted: auth.SELECTORS.getComponentIsAuthorised(state, ChildrenLeaving),
  childrenSignedUpReportReportGranted: auth.SELECTORS.getComponentIsAuthorised(state, ChildrenSignedUp),
  childrenStartingReportGranted: auth.SELECTORS.getComponentIsAuthorised(state, ChildrenStarting),
  dietaryReportGranted: auth.SELECTORS.getComponentIsAuthorised(state, DietaryReport),
  emergencyReportGranted: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.LJ_REPORTS],
    roles: [
      ROLES.SUPER_ADMIN,
      ROLES.ORGANIZATION_NATIONAL_ADMIN,
      ROLES.ORGANIZATION_FINANCE_ADMIN,
      ROLES.ORGANIZATION_DIRECTOR,
      ROLES.ORGANIZATION_LINE_MANAGER,
      ROLES.DEPUTY_MANAGER,
      ROLES.NURSERY_MANAGER,
      ROLES.NURSERY_ADMIN,
    ],
  }),
  keyPersonsReportGranted: auth.SELECTORS.getComponentIsAuthorised(state, KeyPersonsReport),
  monthlyFteChangeReportGranted: auth.SELECTORS.getComponentIsAuthorised(state, MonthlyFteChange),
  nurseryMilkReportGranted: auth.SELECTORS.getComponentIsAuthorised(state, NurseryMilk),
  photoPermissionsReportGranted: auth.SELECTORS.getComponentIsAuthorised(state, PhotoPermissionsReport),
})

const enhance = compose(
  withModalService,
  connect(mapState),
)

export default enhance(ReportsContainer)
