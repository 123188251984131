export const CLEAR_NURSERY_EXTRA_ITEMS_LIST = 'CLEAR_NURSERY_EXTRA_ITEMS_LIST'
export const LIST_NURSERY_EXTRA_ITEMS = 'LIST_NURSERY_EXTRA_ITEMS'
export const LIST_NURSERY_EXTRA_ITEMS_SUCCESS = 'LIST_NURSERY_EXTRA_ITEMS_SUCCESS'
export const LIST_NURSERY_EXTRA_ITEMS_FAILED = 'LIST_NURSERY_EXTRA_ITEMS_FAILED'

export const listExtraItems = (options = {}) => ({ nurseryExtraItemsApiClient }) => async (dispatch) => {
  const { mergeResult, onSuccess, params = {} } = options

  dispatch({
    params,
    type: LIST_NURSERY_EXTRA_ITEMS,
  })

  await nurseryExtraItemsApiClient.list(params).then(
    (response) => {
      dispatch({
        mergeResult,
        payload: response,
        type: LIST_NURSERY_EXTRA_ITEMS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: LIST_NURSERY_EXTRA_ITEMS_FAILED,
      })
    },
  )
}

export const clearList = () => ({
  type: CLEAR_NURSERY_EXTRA_ITEMS_LIST,
})
