import React from 'react'

import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

import { ModalBox } from 'components'

import PreviewOptions from './components/PreviewOptions'

const PreviewInvoiceDeductionModalView = ({ onCloseClick, previewOptions }) => (
  <ModalBox
    title="Funding deduction on invoices"
    onCloseClick={onCloseClick}
  >
    <SubdomainCurrencyProvider>
      {({ getFormattedCurrencyValue }) => (
        <PreviewOptions previewOptions={previewOptions(getFormattedCurrencyValue)} />
      )}
    </SubdomainCurrencyProvider>
  </ModalBox>
)

export default PreviewInvoiceDeductionModalView
