import { generateBasicActions } from 'utils/service'

import {
  CLEAR_COMPARISON_SINGLE,
  CREATE_COMPARISON_REPORT,
  CREATE_COMPARISON_REPORT_FAILED,
  CREATE_COMPARISON_REPORT_SUCCESS,
  GET_COMPARISON_PREVIEW,
  GET_COMPARISON_PREVIEW_FAILED,
  GET_COMPARISON_PREVIEW_SUCCESS,
  GET_COMPARISON_REPORT,
  GET_COMPARISON_REPORT_FAILED,
  GET_COMPARISON_REPORT_SUCCESS,
  REMOVE_COMPARISON_REPORT,
  REMOVE_COMPARISON_REPORT_FAILED,
  REMOVE_COMPARISON_REPORT_SUCCESS,
  UPDATE_COMPARISON_ACTION_PLAN,
  UPDATE_COMPARISON_ACTION_PLAN_FAILED,
  UPDATE_COMPARISON_ACTION_PLAN_SUCCESS,
} from './constants'

export const getComparisonReportPreview = (options = {}) => ({ cohortTrackingApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_COMPARISON_PREVIEW_FAILED,
    init: GET_COMPARISON_PREVIEW,
    success: GET_COMPARISON_PREVIEW_SUCCESS,
  },
  options,
  service: cohortTrackingApiClient,
  serviceMethod: 'getComparisonReportPreview',
})

export const getComparisonReport = (options = {}) => ({ cohortTrackingApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_COMPARISON_REPORT_FAILED,
    init: GET_COMPARISON_REPORT,
    success: GET_COMPARISON_REPORT_SUCCESS,
  },
  options,
  service: cohortTrackingApiClient,
  serviceMethod: 'getComparisonReport',
})

export const createComparisonReport = (options = {}) => ({ cohortTrackingApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: CREATE_COMPARISON_REPORT_FAILED,
      init: CREATE_COMPARISON_REPORT,
      success: CREATE_COMPARISON_REPORT_SUCCESS,
    },
    options,
    service: cohortTrackingApiClient,
    serviceMethod: 'createComparisonReport',
  })
)

export const clearComparisonSingle = () => ({
  type: CLEAR_COMPARISON_SINGLE,
})

export const updateComparisonReportActionPlan = (options = {}) => ({ cohortTrackingApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_COMPARISON_ACTION_PLAN_FAILED,
      init: UPDATE_COMPARISON_ACTION_PLAN,
      success: UPDATE_COMPARISON_ACTION_PLAN_SUCCESS,
    },
    options,
    service: cohortTrackingApiClient,
    serviceMethod: 'updateComparisonReportActionPlan',
  })
)

export const removeComparisonReport = (options = {}) => ({ cohortTrackingApiClient }) => (
  generateBasicActions.remove({
    constants: {
      failed: REMOVE_COMPARISON_REPORT_FAILED,
      init: REMOVE_COMPARISON_REPORT,
      success: REMOVE_COMPARISON_REPORT_SUCCESS,
    },
    options,
    service: cohortTrackingApiClient,
    serviceMethod: 'removeComparisonReport',
  })
)
