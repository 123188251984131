import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'
import selectors from './selectors'
import { ChildFundingsListState } from './constants'

export interface withChildFundingsServiceProps {
  childFundingsActions: typeof actions
  childFundingsListState: ChildFundingsListState
  childFundingsSelectors: typeof selectors
}

const withChildFundingsService = <P extends withChildFundingsServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ childFundings }) => ({ childFundings })

  const mapDispatch = injectActions('childFundingsActions', actions)

  const Component = ({ childFundings, childFundingsActions, ...other }) => (
    <WrappedComponent
      childFundingsActions={childFundingsActions}
      childFundingsListState={childFundings.list}
      childFundingsSelectors={selectors}
      {...other as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withChildFundingsService
