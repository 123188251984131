import moment from 'moment'

import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router'

import { isRequired } from 'utils/fieldValidation'

import { Button, FooterActions, Form, Section, Tooltip, Typography } from 'components'

import i18n from 'translations'

import StaffForm from 'module/Staff/components/StaffForm'

export const STAFF_ADD_FORM = 'StaffAddForm'

const StaffAddForm = ({
  cancelLink,
  formValues,
  handleSubmit,
  isCreatingContext,
  isMe,
  isMembershipArchived,
  isNurseryContext,
  isPrimaryManager,
  isStaffingEnabled,
  isSubmitting,
  onArchive,
  onLoadMoreNurseries,
  onNameChange,
  onSubmit,
  rolesOptions,
  showNurseriesSelect,
  superAdminCreation,
  titlesOptions,
}) => {
  const isPrimaryManagerInNurseryContext = isPrimaryManager && isNurseryContext

  const renderPasswordFields = () => isMe && (
    <React.Fragment>
      <Form.Divider />
      <Section
        title={i18n.t('module:Staff:StaffAdd:ChangePasswordSection:title')}
        variant="small"
        noBorder
        noMargin
      >
        <Form.Row>
          <Typography variant="small">
            {i18n.t('module:Staff:StaffAdd:Fields:CurrentPassword:text')}
          </Typography>
        </Form.Row>
        <Form.Row
          label={i18n.t('module:Staff:StaffAdd:Fields:CurrentPassword:label')}
        >
          <Form.Row.FlexItem flex="0 0 240px" mobileFull>
            <Field
              component={Form.TextField}
              name="currentPassword"
              placeholder={i18n.t('module:Staff:StaffAdd:Fields:CurrentPassword:label')}
              type="password"
            />
            <Typography margin="10px 0 0" primary>
              <Link to="/login/reset">
                {i18n.t('module:Staff:StaffAdd:Fields:CurrentPassword:resetPasswordLink')}
              </Link>
            </Typography>
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Divider />
        <Form.Row>
          <Typography variant="small">
            {i18n.t('module:Staff:StaffAdd:Fields:Password:text')}
          </Typography>
        </Form.Row>
        <Form.Row
          label={i18n.t('module:Staff:StaffAdd:Fields:Password:label')}
        >
          <Form.Row.FlexItem flex="0 0 240px" mobileFull>
            <Field
              component={Form.PasswordInput}
              name="password"
              placeholder={i18n.t('module:Staff:StaffAdd:Fields:Password:placeholder')}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row
          label={i18n.t('module:Staff:StaffAdd:Fields:ConfirmPassword:label')}
        >
          <Form.Row.FlexItem flex="0 0 240px" mobileFull>
            <Field
              component={Form.TextField}
              name="confirmPassword"
              placeholder={i18n.t('module:Staff:StaffAdd:Fields:ConfirmPassword:label')}
              type="password"
            />
          </Form.Row.FlexItem>
        </Form.Row>
      </Section>
    </React.Fragment>
  )

  const renderRolesSelect = () => !isMe && (
    <Form.Row
      label={i18n.t('global:Permission')}
      required
    >
      <Form.Row.FlexItem flex="0 0 240px" mobileFull>
        <Tooltip
          placement="top"
          title={isPrimaryManagerInNurseryContext
            ? i18n.t('module:Staff:StaffAdd:SystemOwnerTooltip:rolesDropdown')
            : ''}
        >
          <Field
            clearable={false}
            component={Form.Select}
            disabled={isPrimaryManagerInNurseryContext}
            name="role"
            options={!isPrimaryManagerInNurseryContext
              ? rolesOptions
              : [{ label: i18n.t('module:Management:Nursery:primaryManager') }]}
            placeholder={i18n.t('global:Permission')}
            validate={isRequired}
          />
        </Tooltip>
      </Form.Row.FlexItem>
    </Form.Row>
  )

  const renderForm = () => (
    <React.Fragment>
      {renderRolesSelect()}
      <StaffForm
        isCreatingContext={isCreatingContext}
        isMe={isMe}
        isStaffingEnabled={isStaffingEnabled}
        showNurseriesSelect={showNurseriesSelect}
        titlesOptions={titlesOptions}
        onLoadMoreNurseries={onLoadMoreNurseries}
        onNameChange={onNameChange}
      />
      {renderPasswordFields()}
    </React.Fragment>
  )

  const renderArchiveButton = () => !isCreatingContext && !isMe && (
    <FooterActions.Item>
      <Tooltip
        placement="top"
        title={isPrimaryManager
          ? i18n.t('module:Staff:StaffAdd:SystemOwnerTooltip:deactivateButton')
          : ''}
      >
        <Button
          disabled={isPrimaryManager || isSubmitting}
          hierarchy="secondary"
          label={isMembershipArchived
            ? i18n.t('module:Staff:StaffAdd:Footer:ArchiveButton:label', {
              action: i18n.t('global:activate'),
            })
            : i18n.t('module:Staff:StaffAdd:Footer:ArchiveButton:label', {
              action: i18n.t('global:deactivate'),
            })}
          negativeMargins
          onClick={onArchive}
        />
      </Tooltip>
    </FooterActions.Item>
  )

  const renderCreatingText = () => isCreatingContext && (
    <FooterActions.Item>
      <Typography variant="span">
        {i18n.t('module:Staff:StaffAdd:Footer:creatingText', {
          user: superAdminCreation
            ? i18n.t('module:Staff:StaffAdd:Footer:SubmitButton:Create:administrator')
            : formValues?.role?.label?.toLowerCase() || i18n.t('module:Staff:StaffLists:staffMember'),
        })}
      </Typography>
    </FooterActions.Item>
  )

  const renderFooter = () => (
    <FooterActions spaceBetween>
      <FooterActions.Group>
        <FooterActions.Item>
          <Button
            disabled={isSubmitting}
            hierarchy="tertiary"
            label={i18n.t('global:Cancel')}
            to={cancelLink}
            negativeMargins
          />
        </FooterActions.Item>
      </FooterActions.Group>
      <FooterActions.Group>
        {renderArchiveButton()}
        {renderCreatingText()}
        <FooterActions.Item>
          <Button
            isLoading={isSubmitting}
            label={isCreatingContext
              ? i18n.t('module:Staff:StaffAdd:Footer:SubmitButton:Create:label', {
                user: superAdminCreation
                  ? i18n.t('module:Staff:StaffAdd:Footer:SubmitButton:Create:administrator')
                  : formValues?.role?.label || '',
              }).trim()
              : i18n.t('module:Staff:StaffAdd:Footer:SubmitButton:Update:label')}
            negativeMargins
            submit
          />
        </FooterActions.Item>
      </FooterActions.Group>
    </FooterActions>
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {renderForm()}
      {renderFooter()}
    </Form>
  )
}

export const validate = (values) => {
  const errors = {}

  if (values) {
    const {
      confirmPassword,
      currentPassword,
      membershipProfile,
      password,
    } = values || {}

    const { dbsCheck } = membershipProfile || {}

    const {
      certificateId,
      reviewBy,
      reviewDate,
    } = dbsCheck || {}

    let certificateIdError = null
    let reviewByError = null
    let reviewDateError = null

    if (password !== confirmPassword) {
      errors.confirmPassword = i18n.t('module:Staff:StaffAdd:Errors:confirmPassword')
    }

    if (password && 8 > password.length) {
      errors.password = i18n.t('module:Staff:StaffAdd:Errors:password')
    }

    if (!currentPassword && password && confirmPassword) {
      errors.currentPassword = i18n.t('module:Staff:StaffAdd:Errors:currentPassword')
    }

    if ((reviewBy || reviewDate) && !certificateId) {
      certificateIdError = i18n.t('module:Staff:StaffAdd:Errors:dbsCertificateId')
    }

    if ((certificateId || reviewDate) && !reviewBy) {
      reviewByError = i18n.t('module:Staff:StaffAdd:Errors:dbsReviewBy')
    }

    if ((certificateId || reviewBy) && (!reviewDate || !moment(reviewDate).isValid())) {
      reviewDateError = i18n.t('module:Staff:StaffAdd:Errors:dbsReviewDate')
    }

    const dbsCheckErrors = certificateIdError || reviewByError || reviewDateError
      ? {
        certificateId: certificateIdError,
        reviewBy: reviewByError,
        reviewDate: reviewDateError,
      } : null
    const membershipProfileErrors = dbsCheckErrors
      ? { dbsCheck: dbsCheckErrors }
      : null

    if (membershipProfileErrors) {
      errors.membershipProfile = membershipProfileErrors
    }
  }

  return errors
}

export default reduxForm({ form: STAFF_ADD_FORM, validate })(StaffAddForm)
