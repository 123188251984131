import styled from 'styled-components'

export const StyledContainer = styled.section`
  display: flex;
  gap: ${({ $gap = '100px' }) => $gap};
  margin: 20px 0;
`

export const StyledItemContainer = styled.article`
  display: grid;
  grid-gap: 10px;
`
