import styled from 'styled-components'

export const StyledLabelContainer = styled.div`
  margin-bottom: 5px;
`

export const StyledTimePickerWrapper = styled.div`
  .rc-time-picker-input {
    width: 90px;
  }
`
