import _ from 'lodash'

import { createSelector } from 'reselect'
import { formValueSelector } from 'redux-form'

import { toInt } from 'utils/data'

import { ApplicableValues, OTHER } from 'services/product/nurseryDiscountsV3/constants'
import nurseryDiscountsSelectors from 'services/legacy/nurseryDiscounts/selectors'

import i18n from 'translations'

import { REGULAR } from '../constants'

export const getChildDiscountsSelector = (state) => state.childDiscounts

export const getChildDiscountsSingleSelector = createSelector(
  [getChildDiscountsSelector],
  (state) => state.single.data,
)

export const getInitialValues = (isEdit) => createSelector(
  [getChildDiscountsSingleSelector],
  (discount) => {
    if (!isEdit) {
      return nurseryDiscountsSelectors.getDefaultFormValues()
    }

    if (!discount) {
      return null
    }

    const { nurseryDiscount } = discount

    if (nurseryDiscount) {
      const { applicable, id, type } = nurseryDiscount

      return {
        nurseryDiscount: {
          applicable,
          label: nurseryDiscountsSelectors.getDropdownDescription(nurseryDiscount),
          type,
          value: id,
        },
      }
    }

    return {
      ...nurseryDiscountsSelectors.getFormInitialValues(discount),
      nurseryDiscount: {
        label: _.upperFirst(i18n.t('global:other')),
        value: OTHER,
      },
    }
  },
)

const formSelector = (formName) => formValueSelector(formName)

export const nurseryDiscountSelector = (formName) => (state) => formSelector(formName)(state, 'nurseryDiscount')
export const typeSelector = (formName) => (state) => formSelector(formName)(state, 'type')

export const isOtherSelected = (formName) => createSelector(
  [nurseryDiscountSelector(formName)],
  (nurseryDiscountOption) => {
    if (!nurseryDiscountOption) {
      return null
    }

    const { value } = nurseryDiscountOption

    return OTHER === value
  },
)

const getApplicableLabel = (applicableValue) => {
  if (ApplicableValues.EXTRA_SESSIONS === applicableValue) {
    return 'extra sessions'
  }

  if (ApplicableValues.CONSUMABLES === applicableValue) {
    return 'consumables'
  }

  if (ApplicableValues.EXTRA_ITEMS === applicableValue) {
    return 'extra items'
  }

  return 'fees'
}

export const getDiscountDescription = (applicable) => {
  if (!applicable || !applicable.length) {
    return null
  }

  const applicableLabels = _.map(applicable, (value) => getApplicableLabel(value))

  return `(Applied to ${applicableLabels.join(', ')})`
}

export const getNurseryDiscountOptionDescription = (formName) => createSelector(
  [nurseryDiscountSelector(formName)],
  (nurseryDiscountOption) => {
    if (
      !nurseryDiscountOption
      || !nurseryDiscountOption.applicable
      || !nurseryDiscountOption.applicable.length
    ) {
      return null
    }

    const { applicable } = nurseryDiscountOption

    return getDiscountDescription(applicable)
  },
)

export const getPayload = createSelector(
  [(fields) => fields],
  (fields) => {
    if (!fields) {
      return null
    }

    const { childId, nurseryDiscount } = fields
    const { type, value } = nurseryDiscount
    const numericChildId = toInt(childId)

    if (OTHER === value) {
      return {
        ...nurseryDiscountsSelectors.getPayload(fields),
        child: { id: numericChildId },
      }
    }

    return {
      child: { id: numericChildId },
      nurseryDiscount: { id: value, type },
      type: REGULAR,
    }
  },
)

export const getArchivePayload = createSelector(
  [((fields) => fields)],
  (fields) => {
    if (!fields) {
      return null
    }

    const { archived, nurseryDiscount, type } = fields
    const { value } = nurseryDiscount

    if (OTHER !== value) {
      return {
        archived,
        type: REGULAR,
      }
    }

    return {
      archived,
      type,
    }
  },
)

export const isArchivedSelector = createSelector(
  [getChildDiscountsSingleSelector],
  (singleData) => {
    if (!singleData) {
      return null
    }

    const { archived } = singleData

    return archived
  },
)
