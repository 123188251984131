import _ from 'lodash'

import React from 'react'
import { Link } from 'react-router'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { FILTERS_MAP, PROGRESS_TRACKER_TYPES } from 'services/legacy/cohortTracking/constants'

import { generateRoute } from 'utils/routing'

import { CircleIcon, DataTable, Tooltip, Typography } from 'components'

import i18n from 'translations'

import { StyledAreaPlaceholder, StyledName } from './ProgressTrackerTableStyled'

const ProgressTrackerTable = ({
  expanded,
  frameworkDetails,
  latestTerms,
  records,
  report,
  reportType,
  terms,
}) => {
  const { areas, structure } = frameworkDetails || {}
  const { cohort } = structure || {}

  const renderAgeMonthSnapshot = ({ child, processed, report: recordReport }) => {
    if (PROGRESS_TRACKER_TYPES.TERMLY_PROGRESS === reportType) {
      if (!processed?.childSnapshots) {
        return child.ageMonths
      }

      const term = _.find([...terms].reverse(), ({ value }) => (
        processed.childSnapshots[value]?.ageMonths
      ))

      return term && processed.childSnapshots[term.value]?.ageMonths
    }

    return recordReport.childSnapshot.ageMonths
  }

  const renderAgeMonths = () => (
    <DataTable.Column noBorder>
      {(cohort.progress.group.visible || PROGRESS_TRACKER_TYPES.TERMLY_PROGRESS === reportType) && (
        <DataTable.Row
          border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
          height="50"
        />
      )}
      <DataTable.Row
        border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
        height="50"
        width="90"
        column
      >
        <Typography fontSize={13} bold>
          {i18n.t('module:Learning:CohortTracking:ProgressTrackerReport:ProgressTrackerTable:ageMonths')}
        </Typography>
      </DataTable.Row>
      {_.map(records, (record) => (
        <DataTable.Row
          background={NEUTRAL_COLOURS.GRAY_QUATERNARY}
          border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
          height="50"
          key={`ageMonths_${record.child.id}`}
        >
          <Typography color={NEUTRAL_COLOURS.BASIC} fontSize={16}>
            {renderAgeMonthSnapshot(record)}
          </Typography>
        </DataTable.Row>
      ))}
    </DataTable.Column>
  )

  const renderAspectRow = (record, frameworkGroup, term) => {
    const { reports } = record
    const reportForTerm = _.find(reports, (item) => item.term === term.value) || {}
    const ageBand = _.find(reportForTerm.ageBands, (item) => item.frameworkGroup.id === frameworkGroup.id)

    return (
      <DataTable.Row
        background={ageBand?.progressLevel?.colour || NEUTRAL_COLOURS.GRAY_QUATERNARY}
        border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
        height="50"
        key={`${term.value}_${frameworkGroup.id}_${record.child.id}`}
        width="120"
      >
        <Typography fontSize={12} bold>
          {ageBand?.range
          || i18n.t('module:Learning:CohortTracking:ProgressTrackerReport:ProgressTrackerTable:noReport')}
        </Typography>
      </DataTable.Row>
    )
  }

  const renderAreaOfLearningRow = (record, frameworkGroup) => {
    const { report: { ageBands } } = record

    const ageBand = _.find(ageBands, ({ frameworkGroup: { id } }) => frameworkGroup?.id === id)

    return (
      <DataTable.Row
        background={ageBand?.progressLevel?.colour || NEUTRAL_COLOURS.GRAY_QUATERNARY}
        border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
        height="50"
        width="120"
      >
        <Typography
          fontSize={15}
          bold
        >
          {ageBand?.range || i18n.t('module:Learning:CohortTracking:ProgressTrackerReport:ProgressTrackerTable:na')}
        </Typography>
      </DataTable.Row>
    )
  }

  const renderAspect = () => {
    const frameworkArea = _.find(frameworkDetails.areas, ({ id }) => id === report.frameworkArea.id)
    const { groups } = frameworkArea

    return _.map(groups, (frameworkGroup) => (
      _.map(latestTerms, (term, index) => (
        <DataTable.Column key={`header_${frameworkGroup.id}_${term.value}`} noBorder>
          <DataTable.Row
            background={NEUTRAL_COLOURS.GRAY_QUATERNARY}
            border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
            height="50"
            style={{ position: 'relative' }}
            width="120"
          >
            {!index ? (
              <StyledAreaPlaceholder length={latestTerms.length}>
                <Typography color={frameworkArea.colour} fontSize={15} bold>
                  {frameworkGroup.name}
                </Typography>
              </StyledAreaPlaceholder>
            ) : null}
          </DataTable.Row>
          <DataTable.Row
            background={NEUTRAL_COLOURS.GRAY_QUATERNARY}
            border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
            height="50"
            padding="0 10px"
            width="120"
            column
          >
            <Typography fontSize={11} transform="uppercase" bold>
              {term.label}
            </Typography>
          </DataTable.Row>
          {_.map(records, (record) => renderAspectRow(record, frameworkGroup, term))}
        </DataTable.Column>
      ))
    ))
  }

  const renderAreaOfLearning = () => {
    if (!cohort.progress.group.visible) {
      return _.map(areas, ({ colour, groups, id: areaId, name, shortName }) => (
        <DataTable.Column key={`header_${areaId.id}`} noBorder>
          <DataTable.Row
            background={NEUTRAL_COLOURS.GRAY_QUATERNARY}
            border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
            height="50"
            width="120"
          >
            <Tooltip title={groups[0].name}>
              <Typography color={colour} fontSize={15} bold>
                {shortName || name}
              </Typography>
            </Tooltip>
          </DataTable.Row>
          {_.map(records, (record) => renderAreaOfLearningRow(record, groups[0]))}
        </DataTable.Column>
      ))
    }

    return _.map(areas, ({ colour, groups, name, shortName }) => (
      _.map(groups, (group, index) => (
        <DataTable.Column key={`header_${group.id}`} noBorder>
          <DataTable.Row
            background={NEUTRAL_COLOURS.GRAY_QUATERNARY}
            border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
            height="50"
            style={{ position: 'relative' }}
            width="120"
          >
            {!index ? (
              <StyledAreaPlaceholder length={groups.length}>
                <Tooltip title={shortName !== name ? name : null}>
                  <Typography color={colour} fontSize={15} bold>
                    {shortName || name}
                  </Typography>
                </Tooltip>
              </StyledAreaPlaceholder>
            ) : null}
          </DataTable.Row>
          <DataTable.Row
            background={NEUTRAL_COLOURS.GRAY_QUATERNARY}
            border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
            height="50"
            padding="0 10px"
            width="120"
            column
          >
            <Tooltip title={group.name}>
              <Typography color={colour} fontSize={15} bold>
                {group.acronym}
              </Typography>
            </Tooltip>
          </DataTable.Row>
          {_.map(records, (record) => renderAreaOfLearningRow(record, group))}
        </DataTable.Column>
      ))
    ))
  }

  const renderChildrenCells = () => _.map(records, ({ child }) => {
    const { id, name } = child
    const childFilters = _.map(
      _.filter(FILTERS_MAP, (filter) => child[filter.value] && 'No' !== child[filter.value]),
      (filter) => filter.label,
    )

    return (
      <DataTable.Row
        background={NEUTRAL_COLOURS.GRAY_QUATERNARY}
        border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
        height="50"
        key={id}
      >
        <Link to={generateRoute('CHILDREN.CHILD.ABOUT', { childId: id })}>
          <StyledName>
            {childFilters?.length ? (
              <Tooltip
                title={
                  _.map(childFilters, (text) => (
                    <Typography key={text}>
                      &bull;
                      {' '}
                      {text}
                    </Typography>
                  ))
                }
              >
                <CircleIcon
                  cursor="pointer"
                  icon="info"
                  iconSize={12}
                  size={20}
                />
              </Tooltip>
            ) : <div />}
            <Typography color={NEUTRAL_COLOURS.BASIC} fontSize={15} bold>
              {name}
            </Typography>
          </StyledName>
        </Link>
      </DataTable.Row>
    )
  })

  return (
    <DataTable>
      <DataTable.Head>
        {(cohort.progress.group.visible || PROGRESS_TRACKER_TYPES.TERMLY_PROGRESS === reportType) && (
          <DataTable.Row
            border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
            height="50"
          />
        )}
        <DataTable.Row
          border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
          height="50"
        >
          <Typography fontSize={13} bold>
            {i18n.t('module:Learning:CohortTracking:ProgressTrackerReport:ProgressTrackerTable:name')}
          </Typography>
        </DataTable.Row>
        {renderChildrenCells()}
      </DataTable.Head>
      <DataTable.ContentScrollable
        data={[
          renderAgeMonths(),
          ...[PROGRESS_TRACKER_TYPES.AREA_OF_LEARNING === reportType ? renderAreaOfLearning() : renderAspect()],
        ]}
        enableCustomScrollbar={!expanded}
        hideSeparator
      />
    </DataTable>
  )
}

export default ProgressTrackerTable
