import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as dailyDiarySleepsSelectors from './selectors'

const withDailyDiarySleepsService = (WrappedComponent) => {
  const mapState = ({ dailyDiarySleeps }) => ({ dailyDiarySleeps })

  const mapDispatch = injectActions('dailyDiarySleepsActions', actions)

  const Component = ({ dailyDiarySleeps, dailyDiarySleepsActions, ...others }) => (
    <WrappedComponent
      dailyDiarySleepsActions={dailyDiarySleepsActions}
      dailyDiarySleepsListState={dailyDiarySleeps.list}
      dailyDiarySleepsListWithoutRecordsState={dailyDiarySleeps.listWithoutRecords}
      dailyDiarySleepsSelectors={dailyDiarySleepsSelectors}
      dailyDiarySleepsSingleState={dailyDiarySleeps.single}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withDailyDiarySleepsService
