import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withRouter } from 'services/router'

import ManagementInvoicesV3EditBankView from './ManagementInvoicesV3EditBankView'

const groups = {
  read: ['nursery.bankDetails', 'nursery.settings', 'nurserySettings.localeDetails'],
  write: ['nursery.bankDetails'],
}

class ManagementInvoicesV3EditBankContainer extends Component {
  componentDidMount() {
    const { nurseriesActions, nurseryOptions } = this.props

    const nurseryApiParams = { groups }

    nurseriesActions.get(nurseryOptions.id, {
      params: nurseryApiParams,
    })
  }

  handleSubmitSuccess = () => {
    const { navigate } = this.props

    navigate('/management/finance-settings/invoices')
  }

  handleSubmit = (fields) => {
    const { nurseriesActions, nurseryOptions } = this.props

    nurseriesActions.update(nurseryOptions.id, {
      onSuccess: this.handleSubmitSuccess,
      params: { groups },
      payload: fields,
    })
  }

  render() {
    const {
      bankDetails,
      errorMessages,
      isFetching,
      isUkCountry,
      nurseriesSingleState,
      nurseryOptions,
    } = this.props
    const { id } = nurseryOptions

    const initialValues = bankDetails
    const isLoading = isFetching
    const isFormLoading = nurseriesSingleState.isSubmitting

    return (
      <ManagementInvoicesV3EditBankView
        cancelLink="/management/finance-settings/invoices"
        errorMessages={errorMessages}
        initialValues={initialValues}
        isFormLoading={isFormLoading}
        isLoading={isLoading}
        isUkCountry={isUkCountry}
        nurseryId={id}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, { appSelectors, nurseriesSelectors, nurseriesSingleState, params }) => ({
  bankDetails: nurseriesSelectors.getBankDetails(state),
  errorMessages: appSelectors.getErrorMessages(nurseriesSingleState),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState),
  isUkCountry: nurseriesSelectors.getIsUkCountry(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const enhance = compose(
  withRouter,
  withAppService,
  withNurseriesService,
  connect(mapState),
)

export default enhance(ManagementInvoicesV3EditBankContainer)
