import Credentials from './Credentials'
import ChooseNewPin from './ChooseNewPin'
import ChangeLocation from './ChangeLocation'
import Dashboard from './Dashboard'
import ChooseNewPinSuccess from './ChooseNewPinSuccess'
import StaffRegisterList from './StaffRegisterList'
import StaffRegisterWrapperContainer from './StaffRegisterWrapperContainer'
import Authentication from './Authentication'

export const ROUTE_NAMES = {
  'STAFF_REGISTER.AUTHENTICATION': 'STAFF_REGISTER.AUTHENTICATION',
  'STAFF_REGISTER.CREATE_PIN': 'STAFF_REGISTER.CREATE_PIN',
  'STAFF_REGISTER.CREATE_PIN.CHOOSE_NEW_PIN': 'STAFF_REGISTER.CREATE_PIN.CHOOSE_NEW_PIN',
  'STAFF_REGISTER.CREATE_PIN.CHOOSE_NEW_PIN.SUCCESS': 'STAFF_REGISTER.CREATE_PIN.CHOOSE_NEW_PIN.SUCCESS',
  'STAFF_REGISTER.DASHBOARD': 'STAFF_REGISTER.DASHBOARD',
  'STAFF_REGISTER.DASHBOARD.CHANGE_LOCATION': 'STAFF_REGISTER.DASHBOARD.CHANGE_LOCATION',
  'STAFF_REGISTER.FORGOT_PIN': 'STAFF_REGISTER.FORGOT_PIN',
  'STAFF_REGISTER.FORGOT_PIN.CHOOSE_NEW_PIN': 'STAFF_REGISTER.FORGOT_PIN.CHOOSE_NEW_PIN',
  'STAFF_REGISTER.FORGOT_PIN.CHOOSE_NEW_PIN.SUCCESS': 'STAFF_REGISTER.FORGOT_PIN.CHOOSE_NEW_PIN.SUCCESS',
  'STAFF_REGISTER.INDEX': 'STAFF_REGISTER.INDEX',
  'STAFF_REGISTER.LIST': 'STAFF_REGISTER.LIST',
}

const StaffRegisterRouting = {
  childRoutes: [
    {
      component: StaffRegisterList,
      disableAuth: true,
      enableUnauthorizedMainHeader: true,
      name: ROUTE_NAMES['STAFF_REGISTER.LIST'],
      noBreadcrumb: true,
      path: 'list',
    },
    {
      component: Authentication,
      disableAuth: true,
      enableUnauthorizedMainHeader: true,
      name: ROUTE_NAMES['STAFF_REGISTER.AUTHENTICATION'],
      noBreadcrumb: true,
      path: 'authentication',
    },
    {
      childRoutes: [
        {
          childRoutes: [
            {
              component: ChooseNewPinSuccess,
              disableAuth: true,
              enableUnauthorizedMainHeader: true,
              name: ROUTE_NAMES['STAFF_REGISTER.CREATE_PIN.CHOOSE_NEW_PIN.SUCCESS'],
              noBreadcrumb: true,
              path: 'success',
            },
          ],
          indexRoute: {
            component: ChooseNewPin,
            disableAuth: true,
            enableUnauthorizedMainHeader: true,
          },
          name: ROUTE_NAMES['STAFF_REGISTER.CREATE_PIN.CHOOSE_NEW_PIN'],
          noBreadcrumb: true,
          path: 'choose-new-pin',
        },
      ],
      indexRoute: {
        component: Credentials,
        disableAuth: true,
        enableUnauthorizedMainHeader: true,
      },
      name: ROUTE_NAMES['STAFF_REGISTER.CREATE_PIN'],
      noBreadcrumb: true,
      path: 'create-pin',
    },
    {
      childRoutes: [
        {
          childRoutes: [
            {
              component: ChooseNewPinSuccess,
              disableAuth: true,
              enableUnauthorizedMainHeader: true,
              name: ROUTE_NAMES['STAFF_REGISTER.FORGOT_PIN.CHOOSE_NEW_PIN.SUCCESS'],
              noBreadcrumb: true,
              path: 'success',
            },
          ],
          indexRoute: {
            component: ChooseNewPin,
            disableAuth: true,
            enableUnauthorizedMainHeader: true,
          },
          name: ROUTE_NAMES['STAFF_REGISTER.FORGOT_PIN.CHOOSE_NEW_PIN'],
          noBreadcrumb: true,
          path: 'choose-new-pin',
        },
      ],
      indexRoute: {
        component: Credentials,
        disableAuth: true,
        enableUnauthorizedMainHeader: true,
      },
      name: ROUTE_NAMES['STAFF_REGISTER.FORGOT_PIN'],
      noBreadcrumb: true,
      path: 'forgot-pin',
    },
    {
      childRoutes: [
        {
          component: ChangeLocation,
          disableAuth: true,
          enableUnauthorizedMainHeader: true,
          name: ROUTE_NAMES['STAFF_REGISTER.DASHBOARD.CHANGE_LOCATION'],
          noBreadcrumb: true,
          path: 'change-location',
        },
      ],
      indexRoute: {
        component: Dashboard,
        disableAuth: true,
        enableUnauthorizedMainHeader: true,
      },
      name: ROUTE_NAMES['STAFF_REGISTER.DASHBOARD'],
      noBreadcrumb: true,
      path: ':staffId/dashboard',
    },
  ],
  component: StaffRegisterWrapperContainer,
  disableAuth: true,
  enableUnauthorizedMainHeader: true,
  name: ROUTE_NAMES['STAFF_REGISTER.INDEX'],
  noBreadcrumb: true,
  path: 'staff-register',

}

StaffRegisterRouting.ROUTE_NAMES = ROUTE_NAMES

export default StaffRegisterRouting
