import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { Form, Typography } from 'components'

import i18n from 'translations'

export const ADD_FEEDBACK_FORM = 'AddFeedbackForm'

const AddFeedbackForm = ({
  handleSubmit,
  header,
  isSubmitting,
  onCancelClick,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <div className="scrollableForm">
      {header}
      <Form.Row
        label={i18n.t('module:Learning:Observations:ObservationsFeedback:comments')}
        verticalLabel
      >
        <Form.Row.FlexItem flex="1" mobileFull>
          <Field
            component={Form.TextAreaField}
            name="comment"
            placeholder={i18n.t('module:Learning:Observations:ObservationsFeedback:enterComments')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Typography fontSize="14px" margin="0 0 10px 0">
        {i18n.t('module:Learning:Observations:ObservationsFeedback:improvements')}
      </Typography>
      <Form.Row
        margin="0 0 10px 0"
        autoHeight
        verticalLabel
      >
        <Form.Row.FlexItem flex="1" mobileFull>
          <Field
            component={Form.Checkbox}
            label={i18n.t('module:Learning:Observations:ObservationsFeedback:Improvements:observationDetails')}
            name="improvements.observationDetails"
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row margin="0 0 10px 0" autoHeight verticalLabel>
        <Form.Row.FlexItem flex="1" mobileFull>
          <Field
            component={Form.Checkbox}
            label={i18n.t('module:Learning:Observations:ObservationsFeedback:Improvements:spellingAndGrammar')}
            name="improvements.spellingAndGrammar"
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row margin="0 0 10px 0" autoHeight verticalLabel>
        <Form.Row.FlexItem flex="1" mobileFull>
          <Field
            component={Form.Checkbox}
            label={i18n.t('module:Learning:Observations:ObservationsFeedback:Improvements:linksToFramework')}
            name="improvements.linksToEyfs"
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row margin="0 0 10px 0" autoHeight verticalLabel>
        <Form.Row.FlexItem flex="1" mobileFull>
          <Field
            component={Form.Checkbox}
            label={i18n.t('module:Learning:Observations:ObservationsFeedback:Improvements:nextSteps')}
            name="improvements.nextSteps"
          />
        </Form.Row.FlexItem>
      </Form.Row>
    </div>
    <Form.FooterActions
      isSubmitting={isSubmitting}
      submitLabel={i18n.t('global:Send')}
      noBorder
      noMargin
      onCancelClick={onCancelClick}
    />
  </Form>
)

export default reduxForm({ form: ADD_FEEDBACK_FORM })(AddFeedbackForm)
