import _ from 'lodash'

import React from 'react'

import { convertBlobToBase64 } from 'utils/image'

import { Avatar } from 'components'

import i18n from 'translations'

export const getContactsTableData = (contacts) => {
  if (!contacts?.length) {
    return null
  }

  return Promise.all(_.map(contacts, async (contact) => {
    const {
      firstName,
      hasAccessToChildInParentApp,
      hasParentalResponsibility,
      photo,
      relation,
      surname,
    } = contact

    let finalPhoto = null

    if (photo?.isUploaded) {
      finalPhoto = photo.value
    } else if (photo?.file) {
      finalPhoto = await convertBlobToBase64(photo.file)
    } else {
      finalPhoto = photo
    }

    return {
      name: (
        <Avatar
          initials={[firstName, surname]}
          src={finalPhoto}
          title={`${firstName} ${surname}`}
          titleSize={16}
          withoutOptimization={!photo?.isUploaded}
          primary
        />
      ),
      parentAppAccess: hasAccessToChildInParentApp ? i18n.t('module:Children:Child:Contacts:List:Access') : '-',
      parentalResponsibility: hasParentalResponsibility ? i18n.t('global:Yes') : '-',
      relation: relation?.label || '-',
    }
  }))
}

const getError = (response) => (
  _.join(_.map(response.error.extra, (value, key) => `${_.startCase(key)}: ${value}`), <br />)
)

export const getPopupErrorMessage = (apiResponse) => {
  const { childApiResponse, contactsApiResponse, sessionApiResponse } = apiResponse
  const errors = []

  if (childApiResponse.error) {
    errors.push(getError(childApiResponse))
  }

  if (sessionApiResponse.error) {
    errors.push(getError(sessionApiResponse))
  }

  _.forEach(contactsApiResponse, (contactResponse) => {
    if (contactResponse.error) {
      errors.push(getError(contactResponse))
    }
  })

  return (
    <React.Fragment>
      {_.join(errors, <br />)}
    </React.Fragment>
  )
}
