import _ from 'lodash'
import { v4 } from 'uuid'

import { OTHER_OPTION } from 'services/users/constants'

import { HA_SIGNATURE_TYPES, SIGNATURE_TYPES } from '../constants'

export const addStaff = (state, staff, isRemoveSignature) => {
  const newItem = {
    isRemoveSignature,
    owner: staff?.owner,
    signedDigitally: false,
    signedOnPaper: false,
    type: HA_SIGNATURE_TYPES.STAFF_MEMBER,
    uid: v4(),
  }

  return {
    ...state,
    data: {
      ...state.data,
      signatures: [...state.data.signatures, newItem],
    },
  }
}

export const addCarer = (state, child, isHomeAccidents) => {
  const { data } = state
  const { signatures } = data
  const { id: childId } = child

  const primaryCarer = _.find(signatures, ({ additionalType, owner, type }) => {
    if (SIGNATURE_TYPES.PARENT !== type && 'PRIMARY' !== additionalType) {
      return false
    }

    return owner.child.id === childId
  })

  if (!primaryCarer) {
    return state
  }

  const {
    owner,
    signedDigitally,
    signedOnPaper,
    type,
  } = primaryCarer

  const newItem = {
    otherCarer: '',
    owner,
    signedDigitally: !isHomeAccidents ? signedDigitally : false,
    signedOnPaper: !isHomeAccidents ? signedOnPaper : false,
    type,
    uid: v4(),
  }

  return {
    ...state,
    data: {
      ...state.data,
      signatures: [...state.data.signatures, newItem],
    },
  }
}

export const updateCarerDropdown = (state, item, option) => ({
  ...state,
  data: {
    ...state.data,
    signatures: _.map(state.data.signatures, (signature) => {
      if (item.id === signature.id && item.uid === signature.uid) {
        if (signature.carer?.value === OTHER_OPTION.value && !option) {
          return {
            ...signature,
            carer: null,
            otherCarer: undefined,
          }
        }

        return {
          ...signature,
          carer: option,
        }
      }

      return signature
    }),
  },
})

export const updateOtherCarerDropdown = (state, item, value) => ({
  ...state,
  data: {
    ...state.data,
    signatures: _.map(state.data.signatures, (signature) => {
      if (item.id === signature.id && item.uid === signature.uid) {
        return {
          ...signature,
          otherCarer: value,
        }
      }

      return signature
    }),
  },
})

export const updateSignatureId = (state, item, response) => ({
  ...state,
  data: {
    ...state.data,
    signatures: _.map(state.data.signatures, (signature) => {
      if (item.id === signature.id && item.uid === signature.uid) {
        const lastElement = response.data.signatures[
          Object.keys(response.data.signatures)[Object.keys(response.data.signatures).length - 1]
        ].id

        return {
          ...signature,
          id: lastElement,
        }
      }

      return signature
    }),
  },
})

export const updateStaffDropdown = (state, item, option) => ({
  ...state,
  data: {
    ...state.data,
    signatures: _.map(state.data.signatures, (signature) => {
      if (item.id === signature.id && item.uid === signature.uid) {
        return {
          ...signature,
          owner: { email: option?.email, id: option?.value, name: option?.label },
        }
      }

      return signature
    }),
  },
})

export const createInjurySignatureForCarer = (state, item, response) => ({
  ...state,
  data: {
    ...state.data,
    signatures: _.map(state.data.signatures, (signature) => {
      if (item.id === signature.id && item.uid === signature.uid) {
        return {
          ...signature,
          error: null,
          id: response.data.id,
        }
      }

      return signature
    }),
  },
})

export const updateNewCarer = (state, data, uid) => ({
  ...state,
  data: {
    ...state.data,
    signatures: _.map(state.data.signatures, (signature) => {
      if (signature.uid && uid === signature.uid) {
        return data
      }

      return signature
    }),
  },
})

export const updateNewStaffMember = (state, data, uid) => ({
  ...state,
  data: {
    ...state.data,
    signatures: _.map(state.data.signatures, (signature) => {
      if (signature.uid && uid === signature.uid) {
        return data
      }

      return signature
    }),
  },
})

export const createInjurySignatureForCarerError = (state, error, uid) => ({
  ...state,
  data: {
    ...state.data,
    signatures: _.map(state.data.signatures, (signature) => {
      if ((signature.uid && uid === signature.uid) || (signature.id && uid === signature.id)) {
        return {
          ...signature,
          error,
        }
      }

      return signature
    }),
  },
})

export const updateNewCarerError = (state, error, uid) => ({
  ...state,
  data: {
    ...state.data,
    signatures: _.map(state.data.signatures, (signature) => {
      if (signature.uid && uid === signature.uid) {
        return {
          ...signature,
          error,
        }
      }

      return signature
    }),
  },
})

export const updateSignatureIdError = (state, error, uid) => ({
  ...state,
  data: {
    ...state.data,
    signatures: _.map(state.data.signatures, (signature) => {
      if (signature.uid && uid === signature.uid) {
        return {
          ...signature,
          error,
        }
      }

      return signature
    }),
  },
})

export const updateNewStaffError = (state, error, uid) => ({
  ...state,
  data: {
    ...state.data,
    signatures: _.map(state.data.signatures, (signature) => {
      if (signature.uid && uid === signature.uid) {
        return {
          ...signature,
          error,
        }
      }

      return signature
    }),
  },
})

export const removeCarer = (state, signatureId, uid) => ({
  ...state,
  data: {
    ...state.data,
    signatures: _.filter(state.data.signatures, (signature) => {
      if (uid) {
        return signature.uid !== uid
      }

      return signature.id !== signatureId
    }),
  },
})

export const removeStaff = (state, signatureId, uid) => ({
  ...state,
  data: {
    ...state.data,
    signatures: _.filter(state.data.signatures, (signature) => {
      if (uid) {
        return signature.uid !== uid
      }

      return signature.id !== signatureId
    }),
  },
})

export const updateSignature = (state, payload, updateToSignatureList) => ({
  ...state,
  data: {
    ...state.data,
    signatures: _.map(state.data.signatures, (signature) => {
      if (signature.id === payload.data.id) {
        if (updateToSignatureList) {
          return payload.data
        }

        return {
          ...signature,
          isSubmitting: false,
        }
      }

      return signature
    }),
  },
})

export const updateSignatureStatistics = (state, newSignatureStatistics, status) => ({
  ...state,
  data: {
    ...state.data,
    injurySignatureStatistics: newSignatureStatistics,
    status: status || state.data?.status,
  },
})

