import React from 'react'
import { Field, FormSection } from 'redux-form'

import { Option } from 'constants/models'
import { NurseryDiscountSettings, NurseryDiscountTypesV3, OTHER } from 'services/product/nurseryDiscountsV3/constants'

import { getCriteriaSelector } from 'services/product/nurseryDiscountsV3/list/selectors'

import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

import { isRequired } from 'utils/fieldValidation'
import { createTypeFromEnumValues } from 'utils/typescript'

import { Form, TextField } from 'components'

import i18n from 'translations'

export interface DiscountItemOption extends Option {
  settings?: NurseryDiscountSettings
  type?: createTypeFromEnumValues<NurseryDiscountTypesV3>
}

interface DiscountFormFieldProps {
  allowOverride: boolean
  isInvoiced?: boolean
  item: DiscountItemOption
  type: createTypeFromEnumValues<NurseryDiscountTypesV3>
}

export const OTHER_OPTION = {
  label: i18n.t('global:Other'),
  value: OTHER,
}

const criteria = getCriteriaSelector({ type: NurseryDiscountTypesV3.AMOUNT })

const getDiscountValue = (item: DiscountItemOption) => {
  if (!item) {
    return null
  }

  const { type } = item

  if (type === NurseryDiscountTypesV3.PERCENTAGE) {
    return (
      <TextField
        prefix="%"
        prefixWidth="30px"
        value={item?.settings?.value}
        disabled
      />
    )
  }

  return (
    <SubdomainCurrencyProvider>
      {({ currencySymbol }) => (
        <TextField
          prefix={currencySymbol}
          prefixWidth="30px"
          value={item?.settings?.value ? item.settings.value / 100 : 0}
          disabled
        />
      )}
    </SubdomainCurrencyProvider>
  )
}

const DiscountFormField: React.FC<DiscountFormFieldProps> = ({ allowOverride, isInvoiced, item, type }) => (
  <React.Fragment>
    <Form.Row label={i18n.t('global:Item')} required verticalLabel>
      <Form.Row.FlexItem flex="0 0 300px" mobileFull>
        <Field
          component={Form.InfiniteDropdowns.NurseryDiscountsV3}
          criteria={criteria}
          disabled={isInvoiced}
          extraFields={['settings.value', 'settings.allowOverride', 'type']}
          extraOptions={[OTHER_OPTION]}
          name="item"
          validate={isRequired}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    {!allowOverride && item && item.value !== OTHER && (
      <Form.Row label={i18n.t('global:Value')} width={{ field: '140px' }} verticalLabel>
        {getDiscountValue(item)}
      </Form.Row>
    )}
    {item && item.value === OTHER && (
      <FormSection name="discountForm">
        <Form.Row
          label={i18n.t('module:Finance:OneOffBookings:Form:Discount:otherName')}
          width={{ field: '250px' }}
          required
          verticalLabel
        >
          <Field
            component={Form.TextField}
            disabled={isInvoiced}
            name="name"
            placeholder={i18n.t('module:Management:Finance:Discounts:discountName')}
            validate={isRequired}
          />
        </Form.Row>
        <SubdomainCurrencyProvider>
          {({ currencySymbol }) => (
            <Form.Row
              label={i18n.t('module:Finance:OneOffBookings:Form:Discount:value')}
              width={{ field: '120px' }}
              required
              verticalLabel
            >
              <Field
                component={Form.TextField}
                disabled={isInvoiced}
                name="value"
                placeholder={type !== NurseryDiscountTypesV3.PERCENTAGE ? '0.00' : '%'}
                prefix={currencySymbol}
                prefixWidth="30px"
                step="any"
                type="number"
                validate={isRequired}
              />
            </Form.Row>
          )}
        </SubdomainCurrencyProvider>
      </FormSection>
    )}
  </React.Fragment>
)

export default DiscountFormField
