import React from 'react'

import i18n from 'translations'

import { StyledLabel } from './ArchivedStyled'

const Archived = () => (
  <StyledLabel>
    {i18n.t('global:Archived')}
  </StyledLabel>
)

export default Archived
