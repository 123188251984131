import styled from 'styled-components'

export const StyledFormWrapper = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr auto;
  grid-gap: 8px;
  margin: 20px 0 25px;
  align-items: flex-start;

  button {
    margin: 0;
  }
`

export const StyledTextAreaField = styled.div`
  textarea {
    border-radius: 25px;
    padding: 10px 20px;
    min-height: 0;
  }
`
