import moment from 'moment'
import _ from 'lodash'

import { createSelector } from 'reselect'

import { hoursAndMinutesToMilliseconds } from 'utils/date'

import { ALL_ROOMS } from 'services/legacy/rooms/constants'

import { getNurserySettings } from 'services/nurseries/single/selectors/single'
import { getRegularUnavailableTimesListData } from '../list/selectors'

export const getRegularUnavailableTimesInitialValues = createSelector(
  [getRegularUnavailableTimesListData, getNurserySettings],
  (data, nurserySettings) => {
    if (!data) {
      return _.reduce(nurserySettings?.openingDays, (result, item) => ({
        ...result,
        [item.day]: [{}],
      }), [])
    }

    const days = _.reduce(nurserySettings?.openingDays, (result, item) => ({
      ...result,
      [item.day]: [],
    }), [])

    _.forEach(data, ({ day, endTime, nurseryClass, startTime }) => {
      if (days?.[day]) {
        days[day].push({
          endTime: moment(endTime).utc(),
          nurseryClass: nurseryClass?.id ? { value: nurseryClass.id } : ALL_ROOMS,
          startTime: moment(startTime).utc(),
        })
      }
    })

    _.forEach(days, (day) => {
      if (!day.length) {
        day.push({})
      }
    })

    return days
  },
)

export const getRegularUnavailableTimesPayload = (fields) => {
  if (!fields) {
    return null
  }

  const result = []

  _.forEach(fields, (value, key) => (
    _.forEach(value, (item) => {
      if (!_.isEmpty(item)) {
        const { endTime, nurseryClass, startTime } = item

        result.push({
          day: key,
          endTime: hoursAndMinutesToMilliseconds(endTime),
          nurseryClass: nurseryClass?.value ? {
            id: nurseryClass.value,
          } : undefined,
          startTime: hoursAndMinutesToMilliseconds(startTime),
        })
      }
    })
  ))

  return result
}
