import React from 'react'
import { connect } from 'react-redux'

import actions from './actions'
import * as helpers from './helpers'
import selectors from './selectors'

const withChildFundingService = (WrappedComponent) => {
  const mapState = ({ childFunding }) => ({ childFunding })

  const mapDispatch = {
    clear: actions.clearChildFunding,
    clearSingle: actions.clearSingleChildFunding,
    create: actions.createChildFunding,
    exportReport: actions.exportReport,
    get: actions.getChildFunding,
    getStatistics: actions.getChildFundingStatistics,
    list: actions.listChildFunding,
    remove: actions.deleteChildFunding,
    update: actions.updateChildFunding,
    updateList: actions.updateChildFundingList,
  }

  const Component = (props) => {
    const {
      childFunding,
      clear,
      clearSingle,
      create,
      exportReport,
      get,
      getStatistics,
      list,
      remove,
      update,
      updateList,
      ...other
    } = props

    const childFundingListState = {
      ...childFunding.list,
      isEmpty: childFunding.list.data && 0 === childFunding.list.data.length,
    }

    const getAllChildFundings = (params, page = 1) => {
      list({ ...params, page }, 1 < page, ({ meta }) => {
        const { limit, start } = meta
        const totalResults = meta.total_results

        if (start * limit < totalResults) {
          getAllChildFundings(params, page + 1)
        }
      })
    }

    const childFundingSingleState = childFunding.single
    const childFundingStatisticsState = childFunding.statistics
    const childFundingActions = {
      clear,
      clearSingle,
      create,
      exportReport,
      get,
      getStatistics,
      list,
      remove,
      update,
      updateList,
    }
    const childFundingHelpers = { ...helpers, getAllChildFundings }
    const childFundingSelectors = selectors

    return (
      <WrappedComponent
        childFundingActions={childFundingActions}
        childFundingHelpers={childFundingHelpers}
        childFundingListState={childFundingListState}
        childFundingSelectors={childFundingSelectors}
        childFundingSingleState={childFundingSingleState}
        childFundingStatisticsState={childFundingStatisticsState}
        {...other}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withChildFundingService
