import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { RootState } from 'core/reducers'

import { injectActions } from 'utils/service'

import actions from './actions'
import selectors from './selectors'
import { NurseryConsumablesListState, NurseryConsumablesSingleState } from './models'

export interface withNurseryConsumablesV3ServiceProps {
  nurseryConsumablesV3Actions: typeof actions
  nurseryConsumablesV3ListState: NurseryConsumablesListState
  nurseryConsumablesV3Selectors: typeof selectors
  nurseryConsumablesV3SingleState: NurseryConsumablesSingleState
}

const withNurseryConsumablesV3Service = <P extends withNurseryConsumablesV3ServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ nurseryConsumablesV3 }: RootState) => ({ nurseryConsumablesV3 })

  const mapDispatch = injectActions('nurseryConsumablesV3Actions', actions)

  const Component = ({ nurseryConsumablesV3, nurseryConsumablesV3Actions, ...others }) => (
    <WrappedComponent
      nurseryConsumablesV3Actions={nurseryConsumablesV3Actions}
      nurseryConsumablesV3ListState={nurseryConsumablesV3.list}
      nurseryConsumablesV3Selectors={selectors}
      nurseryConsumablesV3SingleState={nurseryConsumablesV3.single}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withNurseryConsumablesV3Service
