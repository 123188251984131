import React from 'react'

import { ALLERGY_TYPES, DIETARY_NO_PREFERENCE } from 'services/legacy/child/constants'

import { Button, DateString, Section, Space } from 'components'

import i18n from 'translations'

import ReviewItem from './ReviewItem'

const ReviewGeneralInformation = ({ formValues, isFinanceV3Enabled, onSetStage }) => {
  const {
    birthDate,
    codeReference,
    displayName,
    firstName,
    gender,
    information,
    nurseryClass,
    registeredAt,
    startDate,
    surname,
  } = formValues

  return (
    <Section
      actions={(
        <Button
          hierarchy="tertiary"
          label={i18n.t('global:Edit')}
          onClick={() => onSetStage(1)}
        />
      )}
      title={i18n.t('module:Children:Child:Add:GeneralInformation:title')}
    >
      <Section
        title={i18n.t('module:Children:Child:Add:GeneralInformation:Sections:personalInformation')}
        variant="small"
      >
        <ReviewItem label={i18n.t('global:Name')} value={`${firstName} ${surname}`} />
        <ReviewItem
          label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:DisplayName:label')}
          value={displayName}
        />
        <ReviewItem
          label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:Gender:label')}
          value={gender?.label}
        />
        <ReviewItem
          label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:DateOfBirth:label')}
          value={<DateString date={birthDate} />}
        />
        <ReviewItem
          label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:Address:label')}
          value={information?.address}
        />
        <ReviewItem
          label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:ReferenceCode:label')}
          value={codeReference}
        />
      </Section>
      <Space margin="0 0 25px" />
      <Section
        title={i18n.t('module:Children:Child:Add:GeneralInformation:Sections:allergiesPreferences')}
        variant="small"
      >
        <ReviewItem
          label={i18n.t('module:Children:Child:Add:HealthAndDiet:Subsections:Diet:Form:DietaryPreference:label')}
          value={information?.dietaryReq?.label}
        />
        {information?.dietaryReq && DIETARY_NO_PREFERENCE !== information?.dietaryReq?.value && (
          <ReviewItem
            label={i18n.t('module:Children:Child:Add:HealthAndDiet:Subsections:Diet:Form:AdditionalNotes:label')}
            value={information?.dietaryReqDesc}
          />
        )}
        <ReviewItem
          label={i18n.t('module:Children:Child:Add:HealthAndDiet:Subsections:Health:Form:Allergies:label')}
          value={information?.allergies?.label}
        />
        {ALLERGY_TYPES.HAS_ALLERGY === information?.allergies?.value && (
          <ReviewItem
            label={i18n.t('module:Children:Child:Add:HealthAndDiet:Subsections:Health:Form:AllergiesDetails:label')}
            value={information?.allergiesDetails}
          />
        )}
        <ReviewItem
          label={
            i18n.t('module:Children:Child:Add:HealthAndDiet:Subsections:Health:Form:CanConsumeFoodWithNutTraces:label')
          }
          value={information?.canConsumeFoodWithNutTraces ? i18n.t('global:Yes') : i18n.t('global:No')}
        />
      </Section>
      {isFinanceV3Enabled && (
        <React.Fragment>
          <Space margin="0 0 25px" />
          <Section
            title={i18n.t('module:Children:Child:Add:EnrolmentDetails:Sections:attendance')}
            variant="small"
          >
            <ReviewItem
              label={i18n.t('module:Children:Child:Add:EnrolmentDetails:Subsections:Attendance:Form:StartDate:label')}
              value={<DateString date={startDate} />}
            />
            <ReviewItem
              label={i18n.t(
                'module:Children:Child:Add:EnrolmentDetails:Subsections:Attendance:Form:RegistrationDate:label',
              )}
              value={<DateString date={registeredAt} />}
            />
            <ReviewItem
              label={i18n.t('module:Children:Child:Add:EnrolmentDetails:Subsections:Attendance:Form:Room:label')}
              value={nurseryClass?.label}
            />
          </Section>
        </React.Fragment>
      )}
    </Section>
  )
}

export default ReviewGeneralInformation

