import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_DEPOSIT,
  CREATE_DEPOSIT,
  CREATE_DEPOSIT_FAILED,
  CREATE_DEPOSIT_SUCCESS,
  GET_DEPOSIT,
  GET_DEPOSIT_FAILED,
  GET_DEPOSIT_SUCCESS,
  REMOVE_DEPOSIT,
  REMOVE_DEPOSIT_FAILED,
  REMOVE_DEPOSIT_SUCCESS,
  UPDATE_DEPOSIT,
  UPDATE_DEPOSIT_FAILED,
  UPDATE_DEPOSIT_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_DEPOSIT,
    createFailed: CREATE_DEPOSIT_FAILED,
    createInit: CREATE_DEPOSIT,
    createSuccess: CREATE_DEPOSIT_SUCCESS,
    itemFailed: GET_DEPOSIT_FAILED,
    itemInit: GET_DEPOSIT,
    itemSuccess: GET_DEPOSIT_SUCCESS,
    removeFailed: REMOVE_DEPOSIT_FAILED,
    removeInit: REMOVE_DEPOSIT,
    removeSuccess: REMOVE_DEPOSIT_SUCCESS,
    updateFailed: UPDATE_DEPOSIT_FAILED,
    updateInit: UPDATE_DEPOSIT,
    updateSuccess: UPDATE_DEPOSIT_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
