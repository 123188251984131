import Messaging from 'module/Messaging/index'
import MessagingList from 'module/Messaging/MessagingList'

export const ROUTE_NAMES = {
  'MESSAGING.CHANNEL': 'MESSAGING.CHANNEL',
  'MESSAGING.CHANNEL_NEW': 'MESSAGING.CHANNEL_NEW',
  'MESSAGING.INDEX': 'MESSAGING.INDEX',
}

const MessagingRouting = {
  childRoutes: [
    {
      component: MessagingList,
      name: ROUTE_NAMES['MESSAGING.CHANNEL'],
      path: 'channel/:channelId',
    },
    {
      component: MessagingList,
      name: ROUTE_NAMES['MESSAGING.CHANNEL_NEW'],
      path: 'channel/new',
    },
  ],
  component: Messaging,
  indexRoute: {
    component: MessagingList,
  },
  name: ROUTE_NAMES['MESSAGING.INDEX'],
  path: 'messages',
}

MessagingRouting.ROUTE_NAMES = ROUTE_NAMES

export default MessagingRouting
