import {
  CLEAR_CHILD_ADHOC_SESSION_LIST,
  LIST_GET_CHILD_ADHOC_SESSIONS,
  LIST_GET_CHILD_ADHOC_SESSIONS_FAILED,
  LIST_GET_CHILD_ADHOC_SESSIONS_SUCCESS,
} from './actions'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: {},
}

export default (state = initialState, { error, mergeResult, payload, type }) => {
  switch (type) {
    case LIST_GET_CHILD_ADHOC_SESSIONS:
      return { ...state, error: initialState.error, isFetching: true }
    case LIST_GET_CHILD_ADHOC_SESSIONS_SUCCESS:
      return {
        ...state,
        data: !mergeResult || !state.data ? payload.data : [...state.data, ...payload.data],
        error: initialState.error,
        isFetching: false,
        meta: payload.meta,
      }
    case LIST_GET_CHILD_ADHOC_SESSIONS_FAILED:
      return { ...state, error, isFetching: false }
    case CLEAR_CHILD_ADHOC_SESSION_LIST:
      return initialState
    default:
      return state
  }
}
