import { FLAG_COLOURS } from 'constants/colors'

import i18n from 'translations'

export const PAGE = {
  DUPLICATES: 'DUPLICATES',
  ERRORS: 'ERRORS',
  INTRO: 'INTRO',
}

const AMOUNT_NEGATIVE = {
  color: FLAG_COLOURS.ERROR,
  text: i18n.t('module:Modals:PaymentsImport:Errors:AmountNegative:text'),
  title: i18n.t('module:Modals:PaymentsImport:Errors:AmountNegative:title'),
}

const AMOUNT_WRONG_FORMAT = {
  color: FLAG_COLOURS.ERROR,
  text: i18n.t('module:Modals:PaymentsImport:Errors:AmountWrongFormat:text'),
  title: i18n.t('module:Modals:PaymentsImport:Errors:AmountWrongFormat:title'),
}

const BILL_PAYER = {
  color: FLAG_COLOURS.ERROR,
  text: i18n.t('module:Modals:PaymentsImport:Errors:BillPayer:text'),
  title: i18n.t('module:Modals:PaymentsImport:Errors:BillPayer:title'),
}

const CHILD_REFERENCE = {
  color: FLAG_COLOURS.ERROR,
  text: i18n.t('module:Modals:PaymentsImport:Errors:ChildReference:text'),
  title: i18n.t('module:Modals:PaymentsImport:Errors:ChildReference:title'),
}

const DUPLICATE_WARNING = {
  color: FLAG_COLOURS.WARNING,
  text: i18n.t('module:Modals:PaymentsImport:Duplicates:Duplicates:text'),
  title: i18n.t('module:Modals:PaymentsImport:Duplicates:Duplicates:title'),
}

const EXISTING_PAYMENTS = {
  color: FLAG_COLOURS.WARNING,
  text: i18n.t('module:Modals:PaymentsImport:Duplicates:ExistingPayments:text'),
  title: i18n.t('module:Modals:PaymentsImport:Duplicates:ExistingPayments:title'),
}

const MANDATORY_FIELD = {
  color: FLAG_COLOURS.ERROR,
  text: i18n.t('module:Modals:PaymentsImport:Errors:MandatoryField:text'),
  title: i18n.t('module:Modals:PaymentsImport:Errors:MandatoryField:title'),
}

const PAYMENT_DATE = {
  color: FLAG_COLOURS.ERROR,
  text: i18n.t('module:Modals:PaymentsImport:Errors:PaymentDate:text'),
  title: i18n.t('module:Modals:PaymentsImport:Errors:PaymentDate:title'),
}

const PAYMENT_METHOD = {
  color: FLAG_COLOURS.ERROR,
  text: i18n.t('module:Modals:PaymentsImport:Errors:PaymentMethod:text'),
  title: i18n.t('module:Modals:PaymentsImport:Errors:PaymentMethod:title'),
}

const TABLE_HEADERS = {
  color: FLAG_COLOURS.ERROR,
  text: i18n.t('module:Modals:PaymentsImport:Errors:TableHeaders:text'),
  title: i18n.t('module:Modals:PaymentsImport:Errors:TableHeaders:title'),
}

const DUPLICATES = {
  DUPLICATE_WARNING,
  EXISTING_PAYMENTS,
}

export const ERRORS = {
  AMOUNT_NEGATIVE,
  AMOUNT_WRONG_FORMAT,
  BILL_PAYER,
  CHILD_REFERENCE,
  MANDATORY_FIELD,
  PAYMENT_DATE,
  PAYMENT_METHOD,
  TABLE_HEADERS,
  ...DUPLICATES,
}
