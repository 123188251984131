import _ from 'lodash'

import { mergeData } from 'utils/data'

import {
  CLEAR_NEWSLETTER_RECIPIENT_LIST,
  DELETE_NEWSLETTER_RECIPIENT,
  DELETE_NEWSLETTER_RECIPIENT_FAILED,
  DELETE_NEWSLETTER_RECIPIENT_SUCCESS,
  GET_NEWSLETTER_RECIPIENT_LIST,
  GET_NEWSLETTER_RECIPIENT_LIST_FAILED,
  GET_NEWSLETTER_RECIPIENT_LIST_SUCCESS,
  RESEND_NEWSLETTER_RECIPIENT,
  RESEND_NEWSLETTER_RECIPIENT_FAILED,
  RESEND_NEWSLETTER_RECIPIENT_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: {
    // eslint-disable-next-line camelcase
    total_results: null,
  },
}

export default (state = initialState, { mergeResult, payload, recipientId, type }) => {
  switch (type) {
    case GET_NEWSLETTER_RECIPIENT_LIST:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_NEWSLETTER_RECIPIENT_LIST_SUCCESS:
      return {
        ...state,
        data: mergeData(state.data, payload.data, mergeResult),
        isFetching: false,
        meta: payload.meta,
      }
    case GET_NEWSLETTER_RECIPIENT_LIST_FAILED:
      return { ...state, error: payload.error, isFetching: false }
    case RESEND_NEWSLETTER_RECIPIENT:
      return {
        ...state,
        data: _.map(state.data, (item) => ({
          ...item,
          isResending: recipientId === item.id || item.isResending,
        })),
        error: initialState.error,
      }
    case RESEND_NEWSLETTER_RECIPIENT_FAILED:
    case RESEND_NEWSLETTER_RECIPIENT_SUCCESS:
      return {
        ...state,
        data: _.map(state.data, (item) => ({
          ...item,
          isResending: recipientId === item.id ? false : item.isResending,
          isResent: recipientId === item.id ? true : item.isResent,
        })),
        error: initialState.error,
      }
    case DELETE_NEWSLETTER_RECIPIENT:
      return {
        ...state,
        data: _.map(state.data, (item) => ({
          ...item,
          isDeleting: recipientId === item.id || item.isDeleting,
        })),
        error: initialState.error,
      }
    case DELETE_NEWSLETTER_RECIPIENT_SUCCESS:
      return {
        ...state,
        data: _.filter(state.data, ({ id }) => id !== recipientId),
        error: initialState.error,
        meta: {
          // eslint-disable-next-line camelcase
          total_results: state.meta.total_results - 1,
        },
      }
    case DELETE_NEWSLETTER_RECIPIENT_FAILED:
      return { ...state, error: payload.error, isFetching: false }
    case CLEAR_NEWSLETTER_RECIPIENT_LIST:
      return initialState
    default:
      return state
  }
}
