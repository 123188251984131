import React from 'react'

import { Callout, Page, Section, Spinner } from 'components'

import AddForm from './components/AddForm'

const AddView = ({
  data,
  deleteDisabled,
  errorMessages,
  isChildContext,
  isEdit,
  isFetching,
  onDeleteClick,
  title,
  ...rest
}) => {
  const renderContent = () => {
    if (isFetching || (isEdit && !data)) {
      return <Spinner />
    }

    return (
      <AddForm
        {...rest}
        data={data}
        isChildContext={isChildContext}
        isEdit={isEdit}
      />
    )
  }

  const actions = isEdit && !deleteDisabled && (
    <Section.Actions
      options={[{
        onClick: onDeleteClick,
        type: 'delete',
      }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isFetching || (isEdit && !data)}
      title={title}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default AddView
