import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import selectors from './selectors'

const withNurseryDiscountsService = (WrappedComponent) => {
  const mapState = ({ nurseryDiscounts }) => ({ nurseryDiscounts })

  const mapDispatch = injectActions('discountActions', actions)

  const Component = (props) => {
    const { discountActions, nurseryDiscounts, ...other } = props

    const nurseryDiscountsListState = nurseryDiscounts.list
    const nurseryDiscountsSingleState = nurseryDiscounts.single
    const nurseryDiscountsActions = discountActions
    const nurseryDiscountsSelectors = selectors

    return (
      <WrappedComponent
        nurseryDiscountsActions={nurseryDiscountsActions}
        nurseryDiscountsListState={nurseryDiscountsListState}
        nurseryDiscountsSelectors={nurseryDiscountsSelectors}
        nurseryDiscountsSingleState={nurseryDiscountsSingleState}
        {...other}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withNurseryDiscountsService
