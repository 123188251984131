import _ from 'lodash'

import React from 'react'

import { getInitialValues } from 'services/legacy/dailyDiaryActivities/single/selectors'

import { Button, EmptyState, InfiniteScroll, List, Pagination, Spinner, Toolbar, Typography } from 'components'

import i18n from 'translations'

import ActivityRecord from './components/ActivityRecord'
import { ACTIVITY_RECORD_EDIT_FORM } from './components/ActivityRecord/ActivityRecord'
import ActivitiesStatistics from './components/ActivitiesStatistics'
import { StyledPageDescription } from './ActivitiesStyled'

const ActivitiesView = ({
  isFetching,
  isOffline,
  isSubmitting,
  onAddRecord,
  onPageChange,
  onRemoveRecord,
  onUpdateRecord,
  page,
  pageCount,
  records,
  registers,
  totalResults,
}) => {
  if (isFetching && 1 === page) {
    if (isOffline) {
      return (
        <EmptyState
          icon="offline"
          text1={i18n.t('global:youAreOffline')}
        />
      )
    }

    return (
      <Spinner />
    )
  }

  const renderPagination = () => !isFetching && (
    <Pagination
      totalResults={totalResults}
    />
  )

  const renderHeader = () => {
    if (registers && !registers.length) {
      return null
    }

    return (
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <StyledPageDescription>
              <Typography>
                {i18n.t('module:DailyDiary:Activities:listInfo')}
              </Typography>
            </StyledPageDescription>
          </Toolbar.Item>
        </Toolbar.Group>
        <Toolbar.Group>
          <Toolbar.Item>
            <Button
              disabled={isSubmitting}
              icon="plus"
              isLoading={isFetching}
              label={i18n.t('module:DailyDiary:Activities:activity')}
              negativeMargins
              onClick={() => onAddRecord(null)}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
    )
  }

  const renderItem = (item) => {
    const { children, id } = item

    return (
      <ActivityRecord
        childArray={children}
        form={`${ACTIVITY_RECORD_EDIT_FORM}_${id}`}
        initialValues={getInitialValues(item)}
        item={item}
        key={id}
        onRemoveRecord={onRemoveRecord}
        onSubmit={onUpdateRecord}
      />
    )
  }

  const renderContent = () => {
    if (!isFetching && (records && !records.length)) {
      let text = null
      let icon = 'daily-diary'

      if (registers && !registers.length) {
        text = i18n.t('module:DailyDiary:global:noChildrenAttending')
      } else if (records && !records.length) {
        text = i18n.t('module:DailyDiary:global:noAddedItems')
        icon = 'activities'
      }

      return (
        <EmptyState
          icon={icon}
          text1={text}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <InfiniteScroll
          dataLength={records?.length || 0}
          hasMore={page < pageCount}
          isFetching={isFetching}
          next={() => onPageChange((+page) + 1)}
        >
          <List.Standard>
            {_.map(records, renderItem)}
          </List.Standard>
        </InfiniteScroll>
        {renderPagination()}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {renderHeader()}
      <ActivitiesStatistics />
      {renderContent()}
    </React.Fragment>
  )
}

export default ActivitiesView
