import i18n from 'translations'

export const CHILD_FUNDING_FILTERS = {
  ACTIVE: 'ACTIVE',
  ALL: 'ALL',
  ARCHIVED: 'ARCHIVED',
}

export const CHILD_FUNDING_FILTER_OPTIONS = [
  { label: i18n.t('global:Active'), value: CHILD_FUNDING_FILTERS.ACTIVE },
  { label: 'All funding', value: CHILD_FUNDING_FILTERS.ALL },
  { label: i18n.t('global:Archived'), value: CHILD_FUNDING_FILTERS.ARCHIVED },
]

export const CHILD_AGES_DROPDOWN = [
  {
    id: '1_year',
    label: '1 year',
    value: {
      from: 12,
      to: 24,
    },
  },
  {
    id: '2_year',
    label: '2 year',
    value: {
      from: 24,
      to: 36,
    },
  },
  {
    id: '3_year',
    label: '3 year',
    value: {
      from: 36,
      to: 48,
    },
  },
  {
    id: '4_year',
    label: '4 year',
    value: {
      from: 48,
      to: 60,
    },
  },
  {
    id: '5_year',
    label: '+5 years',
    value: {
      from: 60,
    },
  },
]
