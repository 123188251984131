import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withHomeObservationsService } from 'services/legacy/homeObservations'
import { withSecurityService } from 'services/security'
import { withLikesAndCommentsService } from 'services/likesAndComments'
import { withRouterUtils } from 'services/utils/router'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouter } from 'services/router'

import HomeObservationsListView from './HomeObservationsListView'

const GROUPS = {
  read: [
    'child',
    'carer',
    'like',
    'homeObservation.carer',
    'homeObservation.child',
    'homeObservation.observations',
  ],
}

const USER_LIKES_GROUPS = {
  read: [
    'homeObservation.userLike',
    'homeObservation.counter',
  ],
}

class HomeObservationsListContainer extends Component {
  constructor(props) {
    super(props)

    const {
      currentUser,
      currentUserIsSuperAdmin,
      location: { query },
    } = props

    let keyWorker = null

    if (!this.isChildContext()) {
      if (query?.keyWorker) {
        keyWorker = query.keyWorker
      } else if (currentUser && !currentUserIsSuperAdmin) {
        keyWorker = currentUser.id
      }
    }

    this.state = {
      filters: {
        keyWorker,
      },
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { homeObservationsActions } = this.props

    homeObservationsActions.clearListHomeObservations()
  }

  isChildContext = () => {
    const { params: { childId } } = this.props

    return !!childId
  }

  fetch = () => {
    const {
      hasAccessToLikesAndComments,
      homeObservationsActions,
      homeObservationsSelectors: { getCriteria },
      paginationUtils,
      params: { childId },
    } = this.props
    const { filters } = this.state
    const { page } = paginationUtils

    const criteria = getCriteria({
      ...filters,
      childId: this.isChildContext() && childId,
      isArchived: false,
    })

    homeObservationsActions.listHomeObservations({
      mergeResult: 1 !== page,
      params: [{
        criteria,
        groups: hasAccessToLikesAndComments
          ? { read: [...GROUPS.read, ...USER_LIKES_GROUPS.read] }
          : GROUPS,
        order: {
          sortField: 'id',
          sortOrder: 'DESC',
        },
        page,
      }],
    })
  }

  handleKeyWorkerChange = (keyWorker) => {
    const { paginationUtils, setLocationQuery } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          keyWorker: keyWorker?.value,
        },
      }),
      onPageChange(this.fetch)(1),
    )

    setLocationQuery({
      keyWorker: keyWorker?.value,
      page: 1,
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  render() {
    const {
      hasAccessToLikesAndComments,
      hasAccessToLikesAndCommentsSettings,
      homeObservations,
      isFetching,
      paginationUtils,
      params: { childId },
      router,
      totalResults,
    } = this.props
    const { filters } = this.state
    const { keyWorker } = filters
    const { getPageCount, page, perPage } = paginationUtils
    const pageCount = getPageCount(totalResults)

    const isLoading = isFetching || !homeObservations
    const isEmpty = homeObservations && !homeObservations.length

    return (
      <HomeObservationsListView
        childContext={this.isChildContext()}
        childId={childId}
        hasAccessToLikesAndComments={hasAccessToLikesAndComments}
        hasAccessToLikesAndCommentsSettings={hasAccessToLikesAndCommentsSettings}
        homeObservations={homeObservations}
        isEmpty={isEmpty}
        isLoading={isLoading}
        keyWorker={keyWorker}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        router={router}
        totalResults={totalResults}
        onKeyWorkerChange={this.handleKeyWorkerChange}
        onPageChange={this.handlePageChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  homeObservationsListState,
  homeObservationsSelectors,
  likesAndCommentsSelectors,
  securitySelectors,
}) => ({
  currentUser: securitySelectors.getAuthUser(state),
  currentUserIsSuperAdmin: securitySelectors.isSuperAdmin(state),
  hasAccessToLikesAndComments: likesAndCommentsSelectors.hasAccessToLikesAndComments(state),
  hasAccessToLikesAndCommentsSettings: likesAndCommentsSelectors.hasAccessToLikesAndCommentsSettings(state),
  homeObservations: homeObservationsSelectors.getHomeObservationsListDataState(state),
  isFetching: appSelectors.getIsFetching(homeObservationsListState),
  totalResults: appSelectors.getTotalResults(homeObservationsListState),
})

const enhance = compose(
  withAppService,
  withHomeObservationsService,
  withLikesAndCommentsService,
  withPaginationUtils,
  withRouter,
  withRouterUtils,
  withSecurityService,
  connect(mapState),
)

export default enhance(HomeObservationsListContainer)
