import _ from 'lodash'
import i18n from 'translations'

const COMMON_COLUMNS = [
  {
    field: 'starters',
    title: i18n.t('module:Reports:MonthlyFteChange:Table:Header:starters'),
  },
  {
    field: 'leavers',
    title: i18n.t('module:Reports:MonthlyFteChange:Table:Header:leavers'),
  },
  {
    field: 'existingIncrease',
    title: i18n.t('module:Reports:MonthlyFteChange:Table:Header:existingIncrease'),
  },
  {
    field: 'existingDecrease',
    title: i18n.t('module:Reports:MonthlyFteChange:Table:Header:existingDecrease'),
  },
  {
    field: 'netChange',
    title: i18n.t('module:Reports:MonthlyFteChange:Table:Header:netChange'),
  },
  {
    field: 'monthlyTotal',
    title: i18n.t('module:Reports:MonthlyFteChange:Table:Header:monthlyTotal'),
  },
]

export const NURSERY_TABLE_COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'date',
    title: _.upperFirst(i18n.t('global:months')),
    width: '15%',
  },
  ...COMMON_COLUMNS,
]

export const ORGANIZATION_TABLE_COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'name',
    title: _.upperFirst(i18n.t('global:site')),
  },
  ...COMMON_COLUMNS,
]
