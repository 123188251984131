import _ from 'lodash'
import { flatten, nest } from 'utils/flatnest'

import React, { useEffect, useMemo, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { ROLES } from 'constants/security'

import { WrappedComponentType } from 'constants/types'
import { RootState } from 'core/reducers'
import { ModalType } from 'modals'

import { withAppService, withAppServiceProps } from 'services/app'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withNurseryIntegrationsService, withNurseryIntegrationsServiceProps } from 'services/nurseryIntegrations'
import { withSecurityService, withSecurityServiceProps } from 'services/security'
import { withRouteUtilsProps, withRouterUtils } from 'services/utils/router'
import { withPaginationUtils, withPaginationUtilsProps } from 'services/utils/pagination'
import { withRouter, withRouterProps } from 'services/router'

import i18n from 'translations'

import { getTableData } from './ActiveRecurringPaymentHelpers'
import ActiveRecurringPaymentView from './ActiveRecurringPaymentView'

const ACTIVE_RECURRING_PAYMENT_NURSERY_LEVEL_GROUPS = {
  read: [
    'activeRecurringPaymentRun.child',
    'child',
    'child.finance',
  ],
}

const ACTIVE_RECURRING_PAYMENT_GROUP_LEVEL_GROUPS = {
  read: [
    ...ACTIVE_RECURRING_PAYMENT_NURSERY_LEVEL_GROUPS.read,
    'child.nursery',
    'nursery',
  ],
}

type ActiveRecurringPaymentProps = withAppServiceProps
  & withModalServiceProps
  & withNurseryIntegrationsServiceProps
  & withSecurityServiceProps
  & withRouteUtilsProps
  & withPaginationUtilsProps
  & withRouterProps

const mapState = (state: RootState, {
  appSelectors,
  nurseryIntegrationsActiveRecurringPaymentState,
  nurseryIntegrationsSelectors,
  securitySelectors,
}: ActiveRecurringPaymentProps) => ({
  activeRecurringPaymentData: nurseryIntegrationsSelectors.getActiveRecurringPayment(state),
  activeRecurringPaymentTotalResults: appSelectors.getTotalResults(nurseryIntegrationsActiveRecurringPaymentState),
  errorMessages: appSelectors.getErrorMessages(nurseryIntegrationsActiveRecurringPaymentState),
  isFetching: appSelectors.getIsFetching(nurseryIntegrationsActiveRecurringPaymentState),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

export type OptionProps = {
  label: string
  value: string | number
} | string | any

const ActiveRecurringPaymentContainer: WrappedComponentType<ActiveRecurringPaymentProps & PropsFromRedux> = ({
  activeRecurringPaymentData,
  activeRecurringPaymentTotalResults,
  errorMessages,
  isFetching,
  isOrganizationContext,
  location,
  modalActions,
  nurseryIntegrationsActions,
  nurseryIntegrationsSelectors,
  paginationUtils,
  setLocationQuery,
}) => {
  const { getPageCount, page, perPage } = paginationUtils
  const pageCount = getPageCount(activeRecurringPaymentTotalResults)

  const [initialDataFetched, setInitialDataFetched] = useState(false)

  const [room, setRoom] = useState<OptionProps>(() => {
    const { query = {} } = location
    const { room: queryRoom } = nest(query)

    return queryRoom
  })
  const [status, setStatus] = useState<OptionProps>(() => {
    const { query = {} } = location
    const { status: queryStatus } = nest(query)

    return queryStatus
  })
  const [paymentMethod, setPaymentMethod] = useState<OptionProps>(() => {
    const { query = {} } = location
    const { paymentMethod: queryPaymentMethod } = nest(query)

    return queryPaymentMethod
  })
  const [nursery, setNursery] = useState<OptionProps>(() => {
    const { query = {} } = location
    const { nursery: queryNursery } = nest(query)

    return queryNursery
  })

  const tableData = useMemo(
    () => getTableData({
      data: activeRecurringPaymentData,
      hasError: !!errorMessages,
      isOrganizationContext,
    }),
    [activeRecurringPaymentData, errorMessages],
  )

  const fetchData = () => {
    setLocationQuery(flatten({
      nursery: nursery?.value || nursery,
      paymentMethod: paymentMethod?.value || paymentMethod,
      room: room?.value || room,
      status: status?.value || status,
    }))

    const criteria = nurseryIntegrationsSelectors.getActiveRecurringPaymentCriteria({
      nursery,
      paymentMethod,
      room,
      status,
    })

    nurseryIntegrationsActions.getActiveRecurringPayment({
      onSuccess: () => setInitialDataFetched(true),
      params: {
        criteria,
        groups: isOrganizationContext
          ? ACTIVE_RECURRING_PAYMENT_GROUP_LEVEL_GROUPS
          : ACTIVE_RECURRING_PAYMENT_NURSERY_LEVEL_GROUPS,
        page,
      },
    })
  }

  useEffect(() => {
    fetchData()

    return () => {
      nurseryIntegrationsActions.clearActiveRecurringPayment()
    }
  }, [])

  const handlePageChange = (newPage) => {
    const { onPageChange } = paginationUtils

    onPageChange()(newPage)
  }

  useEffect(() => {
    if (initialDataFetched) {
      if (1 === page) {
        fetchData()
      } else {
        handlePageChange(1)
      }
    }
  }, [room, paymentMethod, nursery, status])

  useEffect(() => {
    if (initialDataFetched) {
      fetchData()
    }
  }, [page])

  const handleExportModalClick = (body, onSuccess, onFailed) => {
    const criteria = nurseryIntegrationsSelectors.getActiveRecurringPaymentCriteria({
      nursery,
      paymentMethod,
      room,
      status,
    })

    nurseryIntegrationsActions.exportActiveRecurringPayment({
      onFailed,
      onSuccess,
      params: { ...body, criteria: _.uniqBy([...body.criteria, ...criteria], ({ field }) => field) },
    })
  }

  const handleExportClick = () => {
    modalActions.show<ModalType.EXPORT_REPORT>(ModalType.EXPORT_REPORT, {
      onSendReport: handleExportModalClick,
      title: i18n.t('module:Finance:Reports:ActiveRecurringPayment:Export:title'),
      userLabel: i18n.t('module:Modals:ExportReportModal:User:Label:export'),
    })
  }

  return (
    <ActiveRecurringPaymentView
      errorMessages={errorMessages}
      isLoading={isFetching}
      isOrganizationContext={isOrganizationContext}
      nursery={nursery}
      page={page}
      pageCount={pageCount}
      paymentMethod={paymentMethod}
      perPage={perPage}
      room={room}
      status={status}
      tableData={tableData}
      title={i18n.t('module:Finance:Reports:ActiveRecurringPayment:title')}
      totalResults={activeRecurringPaymentTotalResults}
      onExportClick={handleExportClick}
      onNurseryChange={setNursery}
      onPageChange={handlePageChange}
      onPaymentMethodChange={setPaymentMethod}
      onRoomChange={setRoom}
      onStatusChange={setStatus}
    />
  )
}

ActiveRecurringPaymentContainer.authParams = {
  antiRoles: [
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
  ],
}

const enhance = compose(
  withRouter,
  withRouterUtils,
  withAppService,
  withPaginationUtils,
  withNurseryIntegrationsService,
  withModalService,
  withSecurityService,
  connector,
)

export default enhance(ActiveRecurringPaymentContainer)

