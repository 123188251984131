import _ from 'lodash'
import moment from 'moment'

import React, { useEffect } from 'react'

import { scrollToElement } from 'utils/dom'

import { StyledContainer } from './ChildFundingWeekDaysButtonsStyled'

import ChildFundingWeekDaysButton from './ChildFundingWeekDaysButton'

const ChildFundingWeekDaysButtons = ({
  onItemClick,
  selectedWeekDays,
  weekDays,
}) => {
  const { weekStartDate: selectedWeekStartDate } = selectedWeekDays || {}
  const hasWeekDays = !!(weekDays && weekDays.length)

  const handleItemClick = (weekDay) => () => {
    onItemClick(weekDay)
  }

  useEffect(() => {
    if (selectedWeekStartDate && hasWeekDays) {
      scrollToElement(document.getElementById(`WEEK_DAY_${moment(selectedWeekStartDate).valueOf()}`))
    }
  }, [hasWeekDays, selectedWeekStartDate])

  return (
    <StyledContainer>
      {_.map(weekDays, (weekDay) => {
        const {
          isActive,
          isFullyAllocated,
          isPartialWeek,
          isSelected,
          weekEndDate,
          weekStartDate,
        } = weekDay

        return (
          <ChildFundingWeekDaysButton
            endDate={weekEndDate}
            id={`WEEK_DAY_${moment(weekStartDate).valueOf()}`}
            isActive={isActive}
            isFullyAllocated={isFullyAllocated}
            isPartialWeek={isPartialWeek}
            isSelected={isSelected}
            key={moment(weekStartDate).valueOf()}
            startDate={weekStartDate}
            onClick={handleItemClick(weekDay)}
          />
        )
      })}
    </StyledContainer>
  )
}

export default ChildFundingWeekDaysButtons
