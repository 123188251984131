import React from 'react'

import { DetailsBreakdown } from 'components'

import i18n from 'translations'

import { CHILD_EDIT_FORM_ROUTE_TYPE } from '../../../constants'

import BreakdownHeader from './BreakdownHeader'
import BreakdownItem from './BreakdownItem'

const TRANS_PREFIX = 'module:Children:Child:Add:LikesDislikes'

const LikesDislikesBreakdown = ({
  child,
  isFormContext,
  permissionToEdit,
}) => {
  const {
    id,
    profile,
  } = child
  const {
    booksPreference,
    foodsPreference,
    otherPreference,
    toysPreference,
  } = profile || {}

  return (
    <DetailsBreakdown marginBottom>
      <DetailsBreakdown.Section>
        <BreakdownHeader
          childFormRouteType={CHILD_EDIT_FORM_ROUTE_TYPE.LIKES_DISLIKES_INFO}
          childId={id}
          isFormContext={isFormContext}
          permissionToEdit={permissionToEdit}
          title={i18n.t(`${TRANS_PREFIX}:title`)}
        />
        <BreakdownItem
          label={i18n.t(`${TRANS_PREFIX}:Form:Toys:label`)}
          padding="15px 0"
          value={toysPreference}
          withoutBorder
        />
        <BreakdownItem
          label={i18n.t(`${TRANS_PREFIX}:Form:Books:label`)}
          padding="15px 0"
          value={booksPreference}
          withoutBorder
        />
        <BreakdownItem
          label={i18n.t(`${TRANS_PREFIX}:Form:Food:label`)}
          padding="15px 0"
          value={foodsPreference}
          withoutBorder
        />
        <BreakdownItem
          label={i18n.t(`${TRANS_PREFIX}:Form:Interests:label`)}
          padding="15px 0"
          value={otherPreference}
          withoutBorder
        />
      </DetailsBreakdown.Section>
    </DetailsBreakdown>
  )
}

export default LikesDislikesBreakdown
