import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { withAppService } from 'services/app'
import { withOrganizationService } from 'services/organization'
import { getAuthOrganization } from 'services/security/selectors'
import { withRouter } from 'services/router'

import SettingsInvoiceNumbersGlobalEditView from './SettingsInvoiceNumbersGlobalEditView'

const ORGANIZATION_INVOICE_NUMBER_SETTINGS_GROUPS = {
  read: [
    'organization.organizationSettings',
    'organizationSettings.invoice',
    'organizationInvoiceSettings',
    'organizationInvoiceSettings.numbers',
    'invoiceNumbers',
  ],
}

class SettingsInvoiceNumbersGlobalEditContainer extends Component {
  componentDidMount() {
    const { authOrganization, organizationActions } = this.props

    const apiParams = { groups: ORGANIZATION_INVOICE_NUMBER_SETTINGS_GROUPS }

    organizationActions.get(authOrganization.id, { params: apiParams })
  }

  UNSAFE_componentWillMount() {
    const { organizationActions } = this.props

    organizationActions.clearSingle()
  }

  handleSuccess = () => {
    const { navigate } = this.props

    navigate('/settings/invoice-numbers')
  }

  handleSubmit = (fields) => {
    const {
      authOrganization,
      organizationActions,
      organizationSelectors,
      organizationSingleState,
    } = this.props

    const apiParams = { groups: ORGANIZATION_INVOICE_NUMBER_SETTINGS_GROUPS }
    const payload = organizationSelectors.getInvoiceNumberSettingsPayload({
      fields,
      organizationSingleState,
    })

    organizationActions.update(authOrganization.id, {
      onSuccess: this.handleSuccess,
      params: apiParams,
      payload,
    })
  }

  render() {
    const { errorMessages, globalNumberType, isFetching, organizationConstants, organizationSingleState } = this.props

    const globalNumberSettingsOptions = organizationConstants.GLOBAL_NUMBER_SETTINGS_OPTIONS
    const initialValues = globalNumberType ? { invoiceNumbersSequencingType: globalNumberType } : null
    const isLoading = isFetching
    const isFormLoading = organizationSingleState.isSubmitting

    return (
      <SettingsInvoiceNumbersGlobalEditView
        errorMessages={errorMessages}
        globalNumberSettingsOptions={globalNumberSettingsOptions}
        initialValues={initialValues}
        isFormLoading={isFormLoading}
        isLoading={isLoading}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, { appSelectors, organizationSelectors, organizationSingleState }) => ({
  authOrganization: getAuthOrganization(state),
  errorMessages: appSelectors.getErrorMessages(organizationSingleState),
  globalNumberType: organizationSelectors.getGlobalNumberType(state),
  isFetching: appSelectors.getIsFetching(organizationSingleState),
})

const enhance = compose(
  withRouter,
  withAppService,
  withOrganizationService,
  connect(mapState),
)

export default enhance(SettingsInvoiceNumbersGlobalEditContainer)
