import React from 'react'

import { Typography } from 'components'

const MediaPickerLabel = ({
  label,
  subLabel1,
  subLabel2,
}) => (
  <Typography align="center">
    {label && (
      <Typography fontSize={18} bold>
        {label}
      </Typography>
    )}
    {subLabel1 && (
      <Typography padding="0">
        {subLabel1}
      </Typography>
    )}
    {subLabel2 && (
      <Typography>
        {subLabel2}
      </Typography>
    )}
  </Typography>
)

export default MediaPickerLabel
