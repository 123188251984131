import _ from 'lodash'

import React from 'react'

import { Avatar, Checkbox, Table, Typography } from 'components'

import EstimatedInvoiceTotalCell from './EstimatedInvoiceTotalCell'
import PreviousInvoicesTotalCells from './PreviousInvoicesTotalCells'
import { StyledTableWrapper } from './BulkInvoicesTableStyled'

const BulkInvoicesTable = ({
  childList,
  onAmountClick,
  onChildSelected,
  period,
  selected,
}) => {
  const renderRow = (item) => {
    const {
      bulkGenerated,
      estimatedInvoice,
      firstName,
      generatedInvoiceType,
      hasError,
      id,
      photo,
      previousInvoices,
      sentAt,
      surname,
    } = item

    const { hasItems, invoiceId: estimatedInvoiceId, total: estimatedInvoiceTotal } = estimatedInvoice || {}

    const disabled = (bulkGenerated && !hasError) || !hasItems
    const checked = !disabled && (0 <= _.find(selected, (childId) => childId === id))

    return (
      <Table.Tr key={id}>
        <Table.Td>
          <Checkbox
            disabled={disabled}
            value={checked}
            onChange={() => onChildSelected(id)}
          />
        </Table.Td>
        <Table.Td align="left">
          <Avatar
            avatarSize="medium"
            initials={[firstName, surname]}
            src={photo}
            title={(
              <Typography fontSize={14} primary>
                {`${firstName} ${surname}`}
              </Typography>
            )}
          />
        </Table.Td>
        <EstimatedInvoiceTotalCell
          child={item}
          generatedInvoiceType={generatedInvoiceType}
          hasError={hasError}
          hasItems={hasItems}
          invoiceId={estimatedInvoiceId}
          period={period}
          sentAt={sentAt}
          value={estimatedInvoiceTotal}
          onAmountClick={onAmountClick}
        />
        <PreviousInvoicesTotalCells child={item} invoices={previousInvoices} onAmountClick={onAmountClick} />
      </Table.Tr>
    )
  }

  const renderContent = () => {
    if (!childList || !childList.length) {
      return (
        <Table.Tr>
          <Table.Td colSpan={7}>
            No children with generated invoices.
          </Table.Td>
        </Table.Tr>
      )
    }

    return _.map(childList, renderRow)
  }

  return (
    <StyledTableWrapper>
      <Table>
        <Table.Tr>
          <Table.Th />
          <Table.Th>
            Child
          </Table.Th>
          <Table.Th>
            Generated batch invoice
          </Table.Th>
          <Table.Th colSpan={4}>
            Most recent invoices
          </Table.Th>
        </Table.Tr>
        {renderContent()}
      </Table>
    </StyledTableWrapper>
  )
}

export default BulkInvoicesTable
