import { generateBasicActions } from 'utils/service'

import {
  CLEAR_FINANCE_REPORTS_NURSERIES_FUNDING,
  FINANCE_REPORTS_NURSERIES_FUNDING,
  FINANCE_REPORTS_NURSERIES_FUNDING_FAILED,
  FINANCE_REPORTS_NURSERIES_FUNDING_SUCCESS,
} from './constants'

export const getNurseries = (options = {}) => ({ financeFundingReportApiClient }) => generateBasicActions.list({
  constants: {
    failed: FINANCE_REPORTS_NURSERIES_FUNDING_FAILED,
    init: FINANCE_REPORTS_NURSERIES_FUNDING,
    success: FINANCE_REPORTS_NURSERIES_FUNDING_SUCCESS,
  },
  options,
  service: financeFundingReportApiClient,
  serviceMethod: 'getNurseries',
})

export const clearNurseries = () => ({
  type: CLEAR_FINANCE_REPORTS_NURSERIES_FUNDING,
})
