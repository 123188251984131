import { QueryPropsV2 } from 'constants/service'

import { getQueryStringV2 } from 'utils/api'

import ServiceBase from 'services/ServiceBase'

class nurseryItemApiClient extends ServiceBase {
  list = (params: QueryPropsV2) => this.request({
    path: `/v2/product/nursery_items?${getQueryStringV2(params)}`,
  })

  get = (id: string, params: QueryPropsV2) => this.request({
    path: `/v2/product/nursery_items/${id}?${getQueryStringV2(params)}`,
  })

  create = (body) => this.request({
    body,
    method: 'POST',
    path: '/v2/product/nursery_items',
  })

  update = (id: string, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/product/nursery_items/${id}`,
  })
}

export default nurseryItemApiClient
