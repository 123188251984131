import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as leaveNotesSelectors from './selectors'

const withLeaveNotesService = (WrappedComponent) => {
  const mapState = ({ leaveNotes }) => ({ leaveNotes })

  const mapDispatch = injectActions('leaveNotesActions', actions)

  const Component = ({ leaveNotes, leaveNotesActions, ...others }) => (
    <WrappedComponent
      leaveNotesActions={leaveNotesActions}
      leaveNotesListState={leaveNotes.list}
      leaveNotesSelectors={leaveNotesSelectors}
      leaveNotesSingleState={leaveNotes.single}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withLeaveNotesService
