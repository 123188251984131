import { generateBasicActions } from 'utils/service'

import {
  REMOVE_MEMBERSHIP_FILE,
  REMOVE_MEMBERSHIP_FILE_FAILED,
  REMOVE_MEMBERSHIP_FILE_SUCCESS,
  UPDATE_MEMBERSHIP_FILE,
  UPDATE_MEMBERSHIP_FILE_FAILED,
  UPDATE_MEMBERSHIP_FILE_SUCCESS,
} from './constants'

export const removeMembershipFile = (options = {}) => ({ filesApiClient }) => generateBasicActions.remove({
  constants: {
    failed: REMOVE_MEMBERSHIP_FILE_FAILED,
    init: REMOVE_MEMBERSHIP_FILE,
    success: REMOVE_MEMBERSHIP_FILE_SUCCESS,
  },
  options,
  service: filesApiClient,
  serviceMethod: 'removeMembershipFile',
})

export const updateMembershipFile = (options = {}) => ({ filesApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_MEMBERSHIP_FILE_FAILED,
    init: UPDATE_MEMBERSHIP_FILE,
    success: UPDATE_MEMBERSHIP_FILE_SUCCESS,
  },
  options,
  service: filesApiClient,
  serviceMethod: 'updateMembershipFile',
})
