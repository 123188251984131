import React, { PropsWithChildren } from 'react'

import { CursorTypes } from 'constants/css'

import {
  StyledWhiteBox,
  StyledWhiteBoxChildren,
  StyledWhiteBoxLeft,
  StyledWhiteBoxRight,
  StyledWhiteBoxTitle,
  StyledWhiteBoxValue,
} from './ProfileHeaderStyled'

interface ProfileHeaderWhiteBoxProps {
  cursor?: CursorTypes
  left?: () => React.ReactNode
  title?: string
  value?: string
}

const ProfileHeaderWhiteBox: React.FC<PropsWithChildren<ProfileHeaderWhiteBoxProps>> = ({
  children,
  cursor,
  left,
  title,
  value,
}) => (
  <StyledWhiteBox>
    {left && (
      <StyledWhiteBoxLeft>
        {left()}
      </StyledWhiteBoxLeft>
    )}
    {(title || value) && (
      <StyledWhiteBoxRight>
        <StyledWhiteBoxTitle>
          {title}
        </StyledWhiteBoxTitle>
        <StyledWhiteBoxValue>
          {value}
        </StyledWhiteBoxValue>
      </StyledWhiteBoxRight>
    )}
    {children && (
      <StyledWhiteBoxChildren $cursor={cursor}>
        {children}
      </StyledWhiteBoxChildren>
    )}
  </StyledWhiteBox>
)

export default ProfileHeaderWhiteBox
