import i18n from 'translations'

import DailyDiary from './DailyDiaryContainer'
import Activities from './Activities'
import Bottles from './Bottles'
import Foods from './Foods'
import Nappies from './Nappies'
import Sleeps from './Sleeps'

export const ROUTE_NAMES = {
  'DAILY_DIARY.ACTIVITIES': 'DAILY_DIARY.ACTIVITIES',
  'DAILY_DIARY.BOTTLES': 'DAILY_DIARY.BOTTLES',
  'DAILY_DIARY.INDEX': 'DAILY_DIARY.INDEX',
  'DAILY_DIARY.MEALS': 'DAILY_DIARY.MEALS',
  'DAILY_DIARY.NAPPIES': 'DAILY_DIARY.NAPPIES',
  'DAILY_DIARY.SLEEPS': 'DAILY_DIARY.SLEEPS',
  'DAILY_DIARY.SNACKS': 'DAILY_DIARY.SNACKS',
}

const DailyDiaryRouting = {
  childRoutes: [
    {
      component: Foods,
      name: ROUTE_NAMES['DAILY_DIARY.MEALS'],
      path: 'meals',
      title: i18n.t('module:DailyDiary:routing:Meals'),
    },
    {
      component: Foods,
      name: ROUTE_NAMES['DAILY_DIARY.SNACKS'],
      path: 'snacks',
      title: i18n.t('module:DailyDiary:routing:Snacks'),
    },
    {
      component: Nappies,
      name: ROUTE_NAMES['DAILY_DIARY.NAPPIES'],
      path: 'nappies',
      title: i18n.t('module:DailyDiary:routing:Nappies'),
    },
    {
      component: Sleeps,
      name: ROUTE_NAMES['DAILY_DIARY.SLEEPS'],
      path: 'sleeps',
      title: i18n.t('module:DailyDiary:routing:Sleeps'),
    },
    {
      component: Activities,
      name: ROUTE_NAMES['DAILY_DIARY.ACTIVITIES'],
      path: 'activities',
      title: i18n.t('module:DailyDiary:routing:Activities'),
    },
    {
      component: Bottles,
      name: ROUTE_NAMES['DAILY_DIARY.BOTTLES'],
      path: 'bottles',
      title: i18n.t('module:DailyDiary:routing:Bottles'),
    },
  ],
  component: DailyDiary,
  name: ROUTE_NAMES['DAILY_DIARY.INDEX'],
  path: 'daily-diary',
  title: i18n.t('module:DailyDiary:routing:dailyDiary'),
}

DailyDiaryRouting.ROUTE_NAMES = ROUTE_NAMES

export default DailyDiaryRouting
