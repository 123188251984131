import React, { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'

import { properties } from 'app-config'
import { EVENTS, logEvent } from 'analytics'

const withIdleTimer = (WrappedComponent) => (props) => {
  const {
    authenticationActions,
    isOrganizationContext,
    navigate,
    organizationSessionExpireTime,
    organizationSessionExpireTimeEnabled,
    sessionExpireTime,
    sessionExpireTimeEnabled,
  } = props

  const [sessionExpireTimeEnabledLocal, setSessionExpireTimeEnabledLocal] = useState<boolean|null>(null)
  const [sessionExpireTimeLocal, setSessionExpireTimeLocal] = useState<number|null>(null)
  const [stayLoggedInEnabled, setStayLoggedInEnabled] = useState<boolean>(false)

  const handleIdle = () => {
    const { logout } = authenticationActions

    logEvent(EVENTS.AUTHENTICATION_SIGNED_OUT, { context: 'auto logout' })

    logout()

    navigate('/login?fromLogout')
  }

  const handleStayLoggedInClick = () => {
    setStayLoggedInEnabled(true)
  }

  const handlePrompt = () => {
    const { modalActions, modalConsts } = props

    modalActions.show(modalConsts.TYPES.SESSION_TIMEOUT, {
      onLogoutClick: handleIdle,
      onStayLoggedInClick: handleStayLoggedInClick,
      promptTimeout: properties.idlePromptTimeout / 1000,
    })
  }

  const { pause, start } = useIdleTimer({
    crossTab: true,
    name: 'user-inactivity-timer',
    onIdle: handleIdle,
    onPrompt: handlePrompt,
    promptTimeout: properties.idlePromptTimeout,
    startManually: true,
    stopOnIdle: true,
    timeout: sessionExpireTimeLocal - properties.idlePromptTimeout,
  })

  useEffect(() => {
    if (null !== sessionExpireTimeEnabled && sessionExpireTimeEnabled !== undefined && !isOrganizationContext) {
      setSessionExpireTimeEnabledLocal(sessionExpireTimeEnabled)
      setSessionExpireTimeLocal(sessionExpireTime * 1000)

      return
    }

    if (
      null !== organizationSessionExpireTimeEnabled
      && organizationSessionExpireTimeEnabled !== undefined
      && isOrganizationContext
    ) {
      setSessionExpireTimeEnabledLocal(organizationSessionExpireTimeEnabled)
      setSessionExpireTimeLocal(organizationSessionExpireTime * 1000)
    }
  }, [
    sessionExpireTimeEnabled,
    sessionExpireTime,
    organizationSessionExpireTimeEnabled,
    organizationSessionExpireTime,
  ])

  useEffect(() => {
    if ((null === sessionExpireTimeEnabled || sessionExpireTimeEnabled === undefined) && !isOrganizationContext) {
      return
    }

    if (
      (null === organizationSessionExpireTimeEnabled || organizationSessionExpireTimeEnabled === undefined)
      && isOrganizationContext
    ) {
      return
    }

    if (sessionExpireTimeEnabledLocal) {
      start()

      return
    }

    pause()
  }, [sessionExpireTimeEnabledLocal, sessionExpireTimeLocal])

  useEffect(() => {
    if (stayLoggedInEnabled) {
      start()
      setStayLoggedInEnabled(false)
    }
  }, [stayLoggedInEnabled])

  return <WrappedComponent {...props} />
}

export default withIdleTimer
