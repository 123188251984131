import styled from 'styled-components'
import { Property } from 'csstype'

import { NEUTRAL_COLOURS } from 'constants/colors'
import layout, { ZINDEX_ORDER } from 'constants/layout'

import { typeByObject } from 'utils/typescript'
import { getBrandingColor } from 'utils/branding'

import { VISUAL_TYPE } from './Table'

interface StyledContainerProps {
  $margin: string
  $maxWidth: number
  $minWidth: number
  $noMargin: boolean
  $tableLayout: Property.TableLayout
  $visualType: typeByObject<typeof VISUAL_TYPE>
  $width: string
}

interface StyledThProps {
  $align?: Property.TextAlign
  $background?: string
  $blank?: boolean
  $isStickyVersion?: boolean
  $visualType: typeByObject<typeof VISUAL_TYPE>
  $width?: string
}

interface StyledTdProps {
  $align: Property.TextAlign
  $background: string
  $borderColorLeft: string
  $borderColorRight: string
  $empty: boolean
  $noBorder: boolean
  $relative: boolean
  $verticalAlign: Property.VerticalAlign
  $whiteSpace: Property.WhiteSpace
}

interface StyledTableTrProps {
  $inactive?: boolean
}

interface StyledSortableThContentProps {
  $align?: Property.JustifyContent
}

interface StyledSortableThTitleContainerProps {
  $align?: Property.TextAlign
  $visualType?: typeByObject<typeof VISUAL_TYPE>
}

export const StyledSubTitle = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
`

export const StyledContainer = styled.div<StyledContainerProps>`
  margin: 20px 0;
  position: relative;
  
  table {
    width: 100%;
    border-collapse: collapse;
  }

  ${({ $noMargin }) => $noMargin && `
    margin: 0;
  `}
    
  ${({ $margin }) => $margin && `
    margin: ${$margin};
  `}

  ${({ $tableLayout }) => $tableLayout && `
    table {
      table-layout: ${$tableLayout};
    }
  `}  

  ${({ $width }) => $width && `
    & > table {
      width: ${$width};
      min-width: ${$width};
    }
  `}
  
  ${({ $minWidth }) => $minWidth && `
    overflow-x: auto;
  
    & > table {
      min-width: ${$minWidth}px;
    }
  `}
  
  ${({ $minWidth }) => $minWidth > layout.layoutWidth && `
    overflow-x: auto;

    & > table {
      min-width: ${$minWidth}px;
    }
  `}

  ${({ $disableOverflowPolyfill }) => $disableOverflowPolyfill && `
    overflow: unset;
  `}
  
  ${({ $visualType }) => VISUAL_TYPE.TRANSPARENT === $visualType && `
    display: block;
    margin: 20px 0;

    & th, td {
      border: none;
      border-bottom: 1px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};
    }
  
    & tr:nth-child(odd), tr:nth-child(even) {
      background: none;
    }
    
    & tr td:nth-of-type(1),
    & tr th:nth-of-type(1) {
      padding-left: 0;
    }
    
    & th {
      background: none;
      color: inherit;
      font-size: 16px;
      font-weight: 600;
      color: ${NEUTRAL_COLOURS.GRAY} !important;
    }

    & .webfont-sort-up, .webfont-sort-down {
      color: ${NEUTRAL_COLOURS.BASIC} !important;
    }
  `}

  ${({ $visualType }) => VISUAL_TYPE.TRANSPARENT_WITH_VERTICAL_BORDERS === $visualType && `
    display: block;

    & th {
      border: none;
      border-bottom: 2px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};
    }

    & td {
      border: 2px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};
    }

    & tr:nth-child(odd), tr:nth-child(even) {
      background: none;
    }

    & th {
      background: none;
      color: inherit;
      font-size: 16px;
      font-weight: 600;
      color: ${NEUTRAL_COLOURS.GRAY} !important;
    }

    & .webfont-sort-up, .webfont-sort-down {
      color: ${NEUTRAL_COLOURS.BASIC} !important;
    }
  `}

  ${({ $visualType }) => VISUAL_TYPE.GRAY === $visualType && `
    display: block;
  
    & td {
      padding: 18px 10px;
      border: none;
      background: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
      border-bottom: 8px solid ${NEUTRAL_COLOURS.WHITE};
    }
    
    & th {
      border: none;
    }
  
    & tr:nth-child(odd), tr:nth-child(even) {
      background: none;
    }

    & th {
      background: none;
      color: inherit;
      font-size: 16px;
      color: ${NEUTRAL_COLOURS.BASIC} !important;
    }
    
    ${StyledSubTitle} {
      color: ${NEUTRAL_COLOURS.GRAY};
    }
    
    & tbody tr td:nth-of-type(1) {
      padding-left: 20px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
        
    & tbody tr td:nth-last-of-type(1) {
      padding-right: 20px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    & thead tr th:nth-of-type(1) {
      padding-left: 20px;
    }

    & thead tr th:nth-last-of-type(1) {
      padding-right: 20px;
    }

    & .webfont-sort-up, .webfont-sort-down {
      color: ${NEUTRAL_COLOURS.BASIC} !important;
    }
  `}

  ${({ $visualType }) => VISUAL_TYPE.GRAY_ROUNDED === $visualType && `
    display: block;

    table {
      border-collapse: separate;
      border-spacing: 0 16px;
    }

    & td {
      padding: 18px 10px;
      background: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
      border: none;
      border-top: 1px solid ${NEUTRAL_COLOURS.WHITE};
      border-bottom: 1px solid ${NEUTRAL_COLOURS.WHITE};
    }

    & th {
      border: none;
    }

    & tr:nth-child(odd), tr:nth-child(even) {
      background: none;
    }

    & th {
      background: none;
      color: inherit;
      font-size: 16px;
      color: ${NEUTRAL_COLOURS.BASIC} !important;
    }

    ${StyledSubTitle} {
      color: ${NEUTRAL_COLOURS.GRAY};
    }

    & tbody tr td:first-of-type {
      padding-left: 20px;
      border-left: 1px solid ${NEUTRAL_COLOURS.WHITE};
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    & tbody tr td:last-of-type {
      padding-right: 20px;
      border-right: 1px solid ${NEUTRAL_COLOURS.WHITE};
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    & thead tr th:first-of-type {
      padding-left: 20px;
    }

    & thead tr th:last-of-type {
      padding-right: 20px;
    }

    & .webfont-sort-up, .webfont-sort-down {
      color: ${NEUTRAL_COLOURS.BASIC} !important;
    }
  `}

  ${({ $visualType }) => VISUAL_TYPE.BORDER === $visualType && `
    display: block;
  
    & td {
      border: 2px solid ${NEUTRAL_COLOURS.GRAY_SECONDARY};
    }
    
    & th {
      border: none;
    }
  
    & tr:nth-child(odd), tr:nth-child(even) {
      background: none;
    }

    & th {
      background: none;
      color: inherit;
      font-size: 16px;
      font-weight: 600;
      color: ${NEUTRAL_COLOURS.BASIC} !important;
    }
    
    ${StyledSubTitle} {
      color: ${NEUTRAL_COLOURS.GRAY};
    }

    & .webfont-sort-up, .webfont-sort-down {
      color: ${NEUTRAL_COLOURS.BASIC} !important;
    }
  `}
  
  ${({ $visualType }) => VISUAL_TYPE.FLAT === $visualType && `
    display: block;

    & th, td {
      border: none;
      border-bottom: 1px solid ${NEUTRAL_COLOURS.SILVER};
    }

    & tr:nth-child(odd), tr:nth-child(even) {
      background: none;
    }

    & th {
      background: none;
      color: inherit;
      font-size: 16px;
      font-weight: 600;
    }

    & th:nth-child(1) {
      text-align: left;
    }

    & .webfont-sort-up, .webfont-sort-down {
      color: ${NEUTRAL_COLOURS.BASIC} !important;
    }
  `}
`

export const StyledTh = styled.th<StyledThProps>`
  background: ${getBrandingColor('primary-color')};
  color: ${NEUTRAL_COLOURS.WHITE};
  font-weight: 400;
  padding: 10px;
  font-size: 16px;
  border: 2px solid ${NEUTRAL_COLOURS.WHITE};
    
  ${({ $align }) => $align && `
    text-align: ${$align};
  `}
  
  ${({ $blank }) => $blank && `
    background: none;
  `}

  ${({ $width }) => $width && `
    width: ${$width};
  `}

  ${({ $background }) => $background && `
    background: ${$background} !important;
  `}
  
  ${({ $isStickyVersion, $visualType }) => $isStickyVersion && VISUAL_TYPE.FLAT === $visualType && `
    background: #FFF !important;
  `}
  
  ${({ $isStickyVersion, $visualType }) => $isStickyVersion && VISUAL_TYPE.FLAT === $visualType && `
    background: #FFF !important;
  `}
`

export const StyledTooltipWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 24px;
  grid-gap: 8px;
  align-items: center;
`

export const StyledTd = styled.td<StyledTdProps>`
  padding: 10px;
  font-size: 16px;
  border: 2px solid ${NEUTRAL_COLOURS.WHITE};

  ${({ $verticalAlign }) => $verticalAlign && `
    vertical-align: ${$verticalAlign};
  `}

  ${({ $align }) => $align && `
    text-align: ${$align};
  `}

  ${({ $empty }) => $empty && `
    background: none;
  `}
  
  ${({ $relative }) => $relative && `
    position: relative;
  `}

  ${({ $background }) => $background && `
    background: ${$background};
  `}
  
  ${({ $borderColorLeft }) => $borderColorLeft && `
    border-left-color: ${$borderColorLeft} !important;
  `}
  
  ${({ $borderColorRight }) => $borderColorRight && `
    border-right-color: ${$borderColorRight} !important;
  `}  
  
  ${({ $noBorder }) => $noBorder && `
    border: none !important;
  `}

  ${({ $whiteSpace }) => $whiteSpace && `
    white-space: ${$whiteSpace};
  `}
`

export const StyledTableTr = styled.tr<StyledTableTrProps>`
  ${({ onClick }) => onClick && 'cursor: pointer;'};
  
  ${({ $inactive }) => $inactive && `
    td > span, td > div > span {
      opacity: 0.4;
    }
  `}

  &:nth-child(even) {
    background: #F7F7F7;
  }

  &:nth-child(odd) {
    background: ${NEUTRAL_COLOURS.SILVER};
  }
`

export const StyledTdSummary = styled.td`
  background: #CACCCB;
  text-align: right;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
  border: 2px solid ${NEUTRAL_COLOURS.WHITE};
`

export const StyledSortableThContent = styled.div<StyledSortableThContentProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ $align }) => $align && `justify-content: ${$align}`}
`

export const StyledSortableThTitleContainer = styled.div<StyledSortableThTitleContainerProps>`
  flex: 1;
  text-align: ${({ $align }) => $align};
  
  ${({ $visualType }) => VISUAL_TYPE.TRANSPARENT === $visualType && `
    flex: unset;
  `}
`

export const StyledSortableThIconContainer = styled.div`
  padding-left: 10px;
`

export const StyledSortableThDefaultIconContainer = styled.div`
  padding-left: 5px;
  opacity: .5;
  height: 15px;

  div ~ div {
    margin-top: -1px;
  }
`

export const StyledStickyHeaderWrapper = styled.div`
  position: fixed;
  transition: .2s transform;
  overflow: hidden;
  z-index: ${ZINDEX_ORDER.STICKY_TABLE};

  & + tbody {
    transform: translateY(-2px);
  }
`

export const StyledStickyHeader = styled.thead`
  display: table;
  
  ${({ $visualType }) => VISUAL_TYPE.TRANSPARENT === $visualType && `
    background: #FFF;

    & th {
      background: #FFF;
    }
  `}
`

export const StyledHeaderIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  & > div:nth-of-type(1) {
    margin-right: 8px;
  }
`

interface StyledCustomScrollbarWrapperProps {
  $isVisible?: boolean
  $width?: number
}

export const StyledCustomScrollbarWrapper = styled.div<StyledCustomScrollbarWrapperProps>`
  position: fixed;
  height: 16px;
  bottom: 0;
  overflow-x: scroll;
  opacity: 1;

  ${({ $hidden }) => $hidden && `
    opacity: 0 !important;
  `}

  ${({ $width }) => $width && `
    width: ${$width}px;
  `}
`

interface StyledImitationContentWidthProps {
  $width?: number
}

export const StyledImitationContentWidth = styled.div<StyledImitationContentWidthProps>`
  height: 1px;

  ${({ $width }) => $width && `
    width: ${$width}px;
  `}
`

export const StyledEndOfContent = styled.div`
  position: absolute;
  bottom: 0;
  opacity: 0;
  height: 1px;
  width: 100%;
`
