import styled from 'styled-components'

import { StyledRowContainer, StyledRowFlexItem } from 'components/Form/helpers/FormRow/FormRowStyled'

export const StyledFrameworkSelectWrapper = styled.div`
  @media(max-width: 890px) {
    ${StyledRowFlexItem} {
      flex: 0 0 275px;
    }
  }
  
  @media(max-width: 740px) {
    width: 100%;
  
    ${StyledRowFlexItem} {
      flex: 0 0 100%;
    }
  }
`

export const StyledTogglesWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;

  ${StyledRowContainer} {
    margin-left: 10px;
    display: grid;
    grid-template-columns: 48px 110px;
    grid-gap: 8px;
    align-items: center;
  
    div:nth-of-type(1) {
      order: 2;
    }
  }
  
  @media(max-width: 440px) {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }
`

export const StyledSwitchWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
`
