import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_NURSERY_SESSIONS_V3,
  LIST_NURSERY_SESSIONS_V3,
  LIST_NURSERY_SESSIONS_V3_FAILED,
  LIST_NURSERY_SESSIONS_V3_SUCCESS,
} from './constants'

export const list = (options: BasicActionOptionsProps) => ({ nurserySessionsV3ApiClient }) => (
  generateBasicActions.list({
    constants: {
      clear: CLEAR_NURSERY_SESSIONS_V3,
      failed: LIST_NURSERY_SESSIONS_V3_FAILED,
      init: LIST_NURSERY_SESSIONS_V3,
      success: LIST_NURSERY_SESSIONS_V3_SUCCESS,
    },
    options,
    service: nurserySessionsV3ApiClient,
    serviceMethod: 'list',
  })
)

export const clearList = () => ({
  type: CLEAR_NURSERY_SESSIONS_V3,
})
