import _ from 'lodash'

import { ALL_OPTIONS, ALL_VALUES } from './constants'

export const getSelectedOptions = (filters) => {
  if (!filters?.length) {
    return null
  }

  return _.map(filters, ({ id: filterId, type, [type]: { id, name } }) => ({
    filterId,
    label: name,
    type,
    value: id,
  }))
}

export const getFilterOptions = ({ allChildren, allStaff, filters }) => {
  const recipients = []

  if (allChildren) {
    recipients.push(_.find(ALL_OPTIONS, { value: ALL_VALUES.ALL_CHILDREN }))
  }

  if (allStaff) {
    recipients.push(_.find(ALL_OPTIONS, { value: ALL_VALUES.ALL_STAFF }))
  }

  const selectedOptions = getSelectedOptions(filters)

  if (selectedOptions?.length) {
    recipients.push(...selectedOptions)
  }

  return recipients
}
