import _ from 'lodash'

import React from 'react'

import { FLAG_COLOURS } from 'constants/colors'
import { UPLOADED_FILE_STATUS } from 'services/legacy/upload/constants'

import { isVideoMimeType } from 'utils/attachment'
import { formatBytes } from 'utils/data'

import { Spinner, Typography } from 'components'

import i18n from 'translations'

import { StyledSpinnerWrapper } from './FileDetailsStyled'

const FileDetails = ({ file, fileExist, isOffline, uploadedData }) => {
  const { canceled, inQueue, isUploaded, itemFromBe, lambdaStatus, mimeType, size } = file
  const isVideo = isVideoMimeType(mimeType)

  if (UPLOADED_FILE_STATUS.FAILED !== lambdaStatus && (
    (isVideo && (
      UPLOADED_FILE_STATUS.PROCESSING === lambdaStatus
      || UPLOADED_FILE_STATUS.UPLOADING === lambdaStatus
    ))
  )) {
    return (
      <StyledSpinnerWrapper>
        <Typography fontSize={14}>
          {`${i18n.t('global:Processing')}...`}
        </Typography>
        <Spinner size="small" />
      </StyledSpinnerWrapper>
    )
  }

  if (
    (UPLOADED_FILE_STATUS.FAILED === lambdaStatus && itemFromBe)
    || (!fileExist && itemFromBe)
    || canceled
  ) {
    return (
      <React.Fragment>
        <Typography color={FLAG_COLOURS.ERROR} bold>
          {i18n.t('components:MediaPicker:uploadError')}
        </Typography>
        {fileExist && !canceled && (
          <Typography color={FLAG_COLOURS.ERROR} fontSize={12} margin="5px 0 0">
            {i18n.t('components:MediaPicker:uploadErrorDetails')}
          </Typography>
        )}
        {canceled && (
          <Typography color={FLAG_COLOURS.ERROR} fontSize={12} margin="5px 0 0">
            {i18n.t('components:MediaPicker:sizeTooLarge')}
          </Typography>
        )}
      </React.Fragment>
    )
  }

  const renderInQueue = () => {
    if (inQueue) {
      return (
        <Typography>
          {i18n.t('components:MediaPicker:waiting')}
        </Typography>
      )
    }

    return (
      <React.Fragment>
        <Typography margin="0 0 3px">
          {_.upperFirst(i18n.t('global:uploading'))}
        </Typography>
        <Spinner size="small" />
      </React.Fragment>
    )
  }

  return isUploaded ? formatBytes(size) : (
    <React.Fragment>
      <StyledSpinnerWrapper disableSpinner={isOffline}>
        {isOffline ? (
          <Typography color={FLAG_COLOURS.ERROR} margin="5px 0 0">
            {i18n.t('components:MediaPicker:noInternet')}
          </Typography>
        ) : renderInQueue()}
      </StyledSpinnerWrapper>
      <Typography fontSize={14}>
        {`${formatBytes(uploadedData)} / ${formatBytes(size)}`}
      </Typography>
    </React.Fragment>
  )
}

export default FileDetails
