import React from 'react'

import { ProductPriceGroups } from 'services/product/productPriceGroups/models'
import { BasicViewProps } from 'constants/models'

import { Callout, Page, Spinner } from 'components'

import AddSessionForm from './components/AddSessionForm'

export interface AddViewProps extends BasicViewProps {
  FinanceAutomationGranted?: boolean
  cancelLink?: string
  consumables?: any
  fromOrganizationContext: boolean
  initialValues?: any
  isArchived?: boolean
  isFormLoading?: boolean
  isInheritedFromOrganization?: boolean
  onAddFee: () => any
  onArchiveClick?: (archived: boolean) => any
  productPriceGroups?: ProductPriceGroups[]
}

const AddView: React.FC<AddViewProps> = ({
  FinanceAutomationGranted,
  cancelLink,
  consumables,
  errorMessages,
  fromOrganizationContext,
  initialValues,
  isArchived,
  isEdit,
  isFormLoading,
  isInheritedFromOrganization,
  isLoading,
  onAddFee,
  onArchiveClick,
  onSubmit,
  productPriceGroups,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <AddSessionForm
        FinanceAutomationGranted={FinanceAutomationGranted}
        cancelLink={cancelLink}
        consumables={consumables}
        initialValues={initialValues}
        isArchived={isArchived}
        isEdit={isEdit}
        isFormLoading={isFormLoading}
        isInheritedFromOrganization={isInheritedFromOrganization}
        productPriceGroups={productPriceGroups}
        onAddFee={onAddFee}
        onArchiveClick={onArchiveClick}
        onSubmit={onSubmit}
      />
    )
  }

  if (fromOrganizationContext) {
    return (
      <React.Fragment>
        <Callout content={errorMessages} error />
        {renderContent()}
      </React.Fragment>
    )
  }

  return (
    <Page>
      <Page.Content>
        <Callout content={errorMessages} error />
        {renderContent()}
      </Page.Content>
    </Page>
  )
}

export default AddView
