import React, { useEffect } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { EditChildNoteFormValues } from 'services/childNotes/models'
import { ModalType } from 'modals'

import { generateRoute } from 'utils/routing'

import { withAppService, withAppServiceProps } from 'services/app'
import { withChildNotesService, withChildNotesServiceProps } from 'services/childNotes'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withRouter, withRouterProps } from 'services/router'

import i18n from 'translations'

import EditChildNoteView from './EditChildNoteView'

interface EditChildNoteContainerProps {
  initialValues?: EditChildNoteFormValues
  isFetching: boolean
  isSubmitting: boolean
  params: {
    childId: string
    noteId?: string
  }
}

type EditChildNoteContainerFullProps = EditChildNoteContainerProps
  & withChildNotesServiceProps
  & withAppServiceProps
  & withModalServiceProps
  & withRouterProps

const EditChildNoteContainer: React.FC<EditChildNoteContainerFullProps> = ({
  childNotesActions,
  childNotesSelectors,
  initialValues,
  isFetching,
  isSubmitting,
  modalActions,
  navigate,
  params,
}) => {
  const { childId, noteId } = params
  const isEditContext = !!noteId

  useEffect(() => {
    if (isEditContext) {
      childNotesActions.get({
        params: [childId, noteId],
      })
    }

    return () => {
      childNotesActions.clear()
    }
  }, [])

  const redirectToChildNotes = () => navigate(
    generateRoute('CHILDREN.CHILD.NOTES', { childId }),
  )

  const handleSubmit = (values: EditChildNoteFormValues) => {
    const body = childNotesSelectors.getChildNoteValuesForm(values)

    if (isEditContext) {
      return childNotesActions.update({
        body,
        onSuccess: redirectToChildNotes,
        params: [childId, noteId],
      })
    }

    return childNotesActions.create({
      body,
      onSuccess: redirectToChildNotes,
      params: [childId],
    })
  }

  const handleDeleteNoteConfirmed = () => {
    childNotesActions.remove({
      onSuccess: redirectToChildNotes,
      params: [childId, noteId],
    })
  }

  const handleDeleteNote = () => {
    modalActions.show<ModalType.CONFIRM>(ModalType.CONFIRM, {
      icon: 'trash',
      onConfirm: handleDeleteNoteConfirmed,
      text: i18n.t('module:Children:Child:Notes:Edit:confirmRemove'),
    })
  }

  return (
    <EditChildNoteView
      childId={+childId}
      initialValues={initialValues}
      isEditContext={isEditContext}
      isFetching={isFetching}
      isSubmitting={isSubmitting}
      onDeleteNote={handleDeleteNote}
      onSubmit={handleSubmit}
    />
  )
}

const mapState = (state, { appSelectors, childNotesSelectors, childNotesSingleState }) => ({
  initialValues: childNotesSelectors.getChildNoteInitialValues(state),
  isFetching: appSelectors.getIsFetching(childNotesSingleState),
  isSubmitting: appSelectors.getIsSubmitting(childNotesSingleState),
})

const enhance = compose(
  withAppService,
  withChildNotesService,
  withModalService,
  withRouter,
  connect(mapState),
)

export default enhance(EditChildNoteContainer)
