import styled from 'styled-components'

export const StyledTooltipWrapper = styled.div`
  ${({ $isOffline }) => $isOffline && `
    width: 100%;
    height: 100%;
    position: relative;
  
    &:after {
      content: '';
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  `}
`
