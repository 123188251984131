import styled from 'styled-components'

import layout from 'constants/layout'

interface StyledLayoutContainerProps {
  $expanded?: boolean
}

export const StyledLayoutContainer = styled.div<StyledLayoutContainerProps>`
  max-width: ${({ $expanded }) => ($expanded ? 'none' : `${layout.layoutWidth}px`)};
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex: 1;
  width: 100%;
`
