import React from 'react'

import { Callout, Page, Spinner } from 'components'
import Settings from 'module/Settings/components'

const ManagementInvoicesV3EditBankView = ({
  cancelLink,
  errorMessages,
  initialValues,
  isFormLoading,
  isLoading,
  isUkCountry,
  nurseryId,
  onSubmit,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <Settings.InvoiceBankSettingForm
        cancelLink={cancelLink}
        initialValues={initialValues}
        isLoading={isFormLoading}
        isUkCountry={isUkCountry}
        nurseryId={nurseryId}
        onSubmit={onSubmit}
      />
    )
  }

  return (
    <Page isLoading={isLoading}>
      <Page.Content>
        <Callout content={errorMessages} error />
        {renderContent()}
      </Page.Content>
    </Page>
  )
}

export default ManagementInvoicesV3EditBankView
