import _ from 'lodash'

import { createSelector } from 'reselect'

const getRoomMove = (state) => state.roomMove

export const getRoomsMoveListSelector = createSelector(
  [getRoomMove],
  (state) => {
    if (!state) {
      return null
    }

    return state.list
  },
)

export const getRoomsMoveListDataSelector = createSelector(
  [getRoomsMoveListSelector],
  ({ data }) => data,
)

export const getErrorMessage = createSelector(
  [getRoomsMoveListDataSelector],
  (roomMoves) => (
    _.find(roomMoves, ({ invalid }) => invalid)
      ? {
        error: true,
        message: `There are gaps between the age ranges of your default room moves. 
        Please adjust for more accurate occupancy reporting.`,
      }
      : {
        error: false,
        message: 'You have not set all of your default room moves.',
      }
  ),
)
