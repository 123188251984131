import _ from 'lodash'
import moment from 'moment'
import { createSelector } from 'reselect'

import { constants as invoicesConstants } from 'services/legacy/invoices'

const DATE_FORMAT = 'YYYY-MM-DD'

const getGeneratedInvoices = (state) => state.generateInvoices

export const getGeneratedInvoicesData = createSelector(
  [getGeneratedInvoices],
  (invoices) => invoices.data,
)

export const getGeneratedInvoicesResponse = createSelector(
  [getGeneratedInvoices],
  (invoices) => invoices.response,
)

export const getFormattedErrors = createSelector(
  [getGeneratedInvoicesResponse],
  (response) => {
    if (!response) {
      return null
    }

    return _.values(_.pickBy(response, (item) => !!item.exception))
  },
)

const getEstimatedInvoice = (estimatedInvoice, invoice) => {
  if (!invoice) {
    return estimatedInvoice
  }

  return {
    invoiceId: invoice.id,
    total: invoice.total,
  }
}

/*
This function will skip already generated estimated invoice on scroll down
logic behind is, we will get invoice id in response of each child on save as draft or save and sent
we will skip that invoice from previous invoices array
*/
const getPreviousInvoices = (invoice, previousInvoices) => {
  if (!invoice) {
    return previousInvoices
  }

  return _.filter(previousInvoices, (previousInvoice) => previousInvoice.id !== invoice.id)
}

export const getFormattedInvoiceData = createSelector(
  [getGeneratedInvoicesData, getGeneratedInvoicesResponse],
  (data, response) => {
    if (!response) {
      return data
    }

    return _.map(data, (item) => {
      const { id } = item

      const childResponse = response[id]

      if (!childResponse) {
        return item
      }

      const { estimatedInvoice, previousInvoices } = item
      const { bulkGenerated, generatedInvoiceType, invoice } = childResponse
      const { sentAt } = invoice || {}

      return {
        ...item,
        bulkGenerated,
        estimatedInvoice: getEstimatedInvoice(estimatedInvoice, invoice),
        generatedInvoiceType,
        hasError: !!childResponse.exception,
        previousInvoices: getPreviousInvoices(invoice, previousInvoices),
        sentAt,
      }
    })
  },
)

export const getActiveChildren = createSelector(
  [getFormattedInvoiceData],
  (invoices) => {
    if (!invoices || !invoices.length) {
      return []
    }

    const filteredInvoices = _.filter(invoices, ({ bulkGenerated, estimatedInvoice, hasError }) => {
      if (bulkGenerated && !hasError) {
        return false
      }

      return !(!estimatedInvoice || (!estimatedInvoice.total && 0 !== estimatedInvoice.total))
    })

    return _.map(filteredInvoices, ({ id }) => id)
  },
)

export const getCriteria = createSelector(
  [(filters) => filters],
  (filters) => {
    if (!filters) {
      return null
    }

    const { children, isArchived, room } = filters

    const criteria = []

    if (null !== isArchived && undefined !== isArchived) {
      criteria.push({
        field: 'isArchived',
        value: +isArchived,
      })
    }

    if (children && _.isArray(children)) {
      _.forEach(children, (childId) => {
        criteria.push({
          field: 'id[]',
          value: childId,
        })
      })
    }

    if (room) {
      criteria.push({
        field: 'nurseryClass',
        value: room,
      })
    }

    return criteria
  },
)

export const getAutoCalculateBodySelector = (filters) => {
  if (!filters) {
    return null
  }

  const { endDate, startDate } = filters

  const body = {}

  if (startDate) {
    body.startDate = moment(startDate).startOf('day').format(DATE_FORMAT)
  }

  if (endDate) {
    body.endDate = moment(endDate).endOf('day').format(DATE_FORMAT)
  }

  return body
}

export const getPreviousInvoicesCriteriaSelector = createSelector(
  [(filters) => filters],
  (filters) => {
    if (!filters) {
      return null
    }

    const { childId } = filters
    const { INVOICE_TYPES, STATUS_TYPES } = invoicesConstants

    const criteria = [
      {
        comparator: 'not',
        field: 'status',
        value: STATUS_TYPES.CANCELLED,
      },
      {
        field: 'type',
        value: INVOICE_TYPES.INVOICE,
      },
    ]

    if (childId) {
      criteria.push({
        field: 'child',
        value: childId,
      })
    }

    return criteria
  },
)

export const getInvoicesIdsBySendingStatus = createSelector(
  [getGeneratedInvoicesResponse],
  (response) => {
    if (!response) {
      return null
    }

    const { STATUS_TYPES } = invoicesConstants

    const filteredResponse = _.filter(response, ({ invoice }) => (
      invoice ? invoice.status === STATUS_TYPES.SENDING : false
    ))

    return _.map(filteredResponse, ({ invoice }) => invoice.id)
  },
)
