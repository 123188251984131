import { generateBasicActions } from 'utils/service'

import {
  CLEAR_LIST_HOME_OBSERVATIONS,
  LIST_HOME_OBSERVATIONS,
  LIST_HOME_OBSERVATIONS_FAILED,
  LIST_HOME_OBSERVATIONS_SUCCESS,
} from './constants'

export const listHomeObservations = (options = {}) => ({ homeObservationsApiClient }) => generateBasicActions.list({
  constants: {
    failed: LIST_HOME_OBSERVATIONS_FAILED,
    init: LIST_HOME_OBSERVATIONS,
    success: LIST_HOME_OBSERVATIONS_SUCCESS,
  },
  options,
  service: homeObservationsApiClient,
  serviceMethod: 'list',
})

export const clearListHomeObservations = () => ({
  type: CLEAR_LIST_HOME_OBSERVATIONS,
})
