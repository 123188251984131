import { combineReducers } from 'redux'

import list from './list/reducer'
import preview from './preview/reducer'
import single from './single/reducer'

export default combineReducers({
  list,
  preview,
  single,
})
