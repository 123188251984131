import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as monthlyFteChangeReportSelectors from './selectors'

const withMonthlyFteChangeReportService = (WrappedComponent) => {
  const mapState = ({ monthlyFteChangeReport }) => ({ monthlyFteChangeReport })

  const mapDispatch = injectActions('monthlyFteChangeReportActions', actions)

  const Component = ({ monthlyFteChangeReport, monthlyFteChangeReportActions, ...others }) => (
    <WrappedComponent
      monthlyFteChangeReportActions={monthlyFteChangeReportActions}
      monthlyFteChangeReportNurseriesState={monthlyFteChangeReport.nurseries}
      monthlyFteChangeReportSelectors={monthlyFteChangeReportSelectors}
      monthlyFteChangeReportStatisticsState={monthlyFteChangeReport.statistics}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withMonthlyFteChangeReportService
