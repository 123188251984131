import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledContentLayoutWrapper = styled.section`
  max-width: 860px;
  margin: 0 auto;
`

export const StyledActivityContent = styled.section`
  padding: 30px;
  background-color: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
`

export const StyledHeader = styled.header`
  display: inline-block;
  align-self: center;
  padding: 0 30px;
`
