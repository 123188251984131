import i18n from 'translations'

import { STATISTIC_TYPES } from 'services/legacy/deposits/constants'

export const STATUS_TYPES = {
  ALL: 'ALL',
  HELD: STATISTIC_TYPES.HELD,
}

export const STATUS_OPTIONS = [
  {
    label: i18n.t('module:Modals:DepositsExport:StatusOptions:all'),
    value: STATUS_TYPES.ALL,
  },
  {
    label: i18n.t('module:Modals:DepositsExport:StatusOptions:held'),
    value: STATUS_TYPES.HELD,
  },
]
