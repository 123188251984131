import styled from 'styled-components'
import { Property } from 'csstype'

interface StyledFormContainerProps {
  flex?: Property.Flex
}

export const StyledFormContainer = styled.form<StyledFormContainerProps>`
  ${({ flex }) => flex && `
    flex: ${flex};
  `}
`
