import React from 'react'

import { StyledHighlight } from './PlanViewStyled'

const PlanViewHighlight = ({ children, minWidth }) => (
  <StyledHighlight minWidth={minWidth}>
    {children}
  </StyledHighlight>
)

export default PlanViewHighlight
