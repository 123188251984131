import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'
import selectors from './selectors'
import { ChildBookingListState, ChildBookingSingleState } from './constants'

export interface withChildBookingServiceProps {
  childBookingActions: typeof actions
  childBookingListState: ChildBookingListState
  childBookingSelectors: typeof selectors
  childBookingSingleState: ChildBookingSingleState
}

const withChildBookingService = <P extends withChildBookingServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ childBooking }) => ({ childBooking })

  const mapDispatch = injectActions('childBookingActions', actions)

  const Component = ({ childBooking, childBookingActions, ...other }) => (
    <WrappedComponent
      childBookingActions={childBookingActions}
      childBookingListState={childBooking.list}
      childBookingPreviewState={childBooking.preview}
      childBookingSelectors={selectors}
      childBookingSingleState={childBooking.single}
      {...other as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withChildBookingService
