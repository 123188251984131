import React from 'react'
import { compose } from 'recompose'
import { RouteComponentProps } from 'react-router'

import { LOCATION_CHAR } from 'services/authentication/constants'

import { generateRoute } from 'utils/routing'

import { withRouter } from 'services/router'

import { Banner, Callout, Hyperlink, Space } from 'components'

import i18n from 'translations'

import BoxWrapper from '../components/BoxWrapper'
import LoginForm from './components/LoginForm'
import InstallDeviceForm from './components/InstallDeviceForm'

interface LoginViewProps {
  errorMessages: string[]
  fromLogout: boolean
  isFetching: boolean
  onLogin: (values: any) => void
  onLoginWithPin: (values: any) => void
  showDeviceFrom: boolean
}

type LoginViewFullProps = LoginViewProps & RouteComponentProps

const LoginView: React.FC<LoginViewFullProps> = ({
  errorMessages,
  fromLogout,
  isFetching,
  location,
  onGoToLogin,
  onLogin,
  onLoginWithPin,
  showDeviceFrom,
}) => {
  const renderBanners = () => (
    <React.Fragment>
      <Space margin="10px 0 0" />
      <Callout content={errorMessages} error />
      {LOCATION_CHAR.YOUR_PASSWORD_HAS_BEN_UPDATED === location.query.v && (
        <Banner.Warning>
          {i18n.t('module:Authentication:Login:yourPasswordHasBeenUpdated')}
        </Banner.Warning>
      )}
      {LOCATION_CHAR.WE_HAVE_SEND_EMAIL_TO_RESET_PASSWORD === location.query.v && (
        <Banner.Warning>
          {i18n.t('module:Authentication:Login:weveJustSentYou')}
        </Banner.Warning>
      )}
      {fromLogout && (
        <Banner.Info>
          {i18n.t('module:Authentication:Login:youHaveBeenLoggedOut')}
        </Banner.Info>
      )}
      <Space margin="0 0 -10px" />
    </React.Fragment>
  )

  const renderForm = () => {
    if (showDeviceFrom) {
      return (
        <InstallDeviceForm
          isFetching={isFetching}
          onGoToLogin={onGoToLogin}
          onSubmit={onLoginWithPin}
        />
      )
    }

    return (
      <LoginForm
        isFetching={isFetching}
        onSubmit={onLogin}
      />
    )
  }

  return (
    <BoxWrapper
      footer={!showDeviceFrom && (
        <Hyperlink to={generateRoute('AUTHENTICATION.RESET')} primary>
          {i18n.t('module:Authentication:Login:forgottenYourPassword')}
        </Hyperlink>
      )}
    >
      {renderBanners()}
      {renderForm()}
    </BoxWrapper>
  )
}

const enhance = compose(
  withRouter,
)

export default enhance(LoginView)
