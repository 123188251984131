import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import selectors from './selectors'

const withEnquiryStatusTypesService = (WrappedComponent) => {
  const mapState = ({ enquiryStatusTypes }) => ({ enquiryStatusTypes })

  const mapDispatch = injectActions('enquiryStatusTypesActions', actions)

  const Component = (props) => {
    const { enquiryStatusTypes, enquiryStatusTypesActions, ...other } = props

    return (
      <WrappedComponent
        enquiryStatusTypesActions={enquiryStatusTypesActions}
        enquiryStatusTypesListState={enquiryStatusTypes.list}
        enquiryStatusTypesSelectors={selectors}
        enquiryStatusTypesSingleState={enquiryStatusTypes.single}
        {...other}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withEnquiryStatusTypesService
