import { generateBasicActions } from 'utils/service'

import {
  CLEAR_FINANCE_REPORTS_NURSERIES_CREDIT_NOTES,
  FINANCE_REPORTS_NURSERIES_CREDIT_NOTES,
  FINANCE_REPORTS_NURSERIES_CREDIT_NOTES_FAILED,
  FINANCE_REPORTS_NURSERIES_CREDIT_NOTES_SUCCESS,
} from './constants'

export const getNurseries = (options = {}) => ({ financeCreditNotesReportApiClient }) => generateBasicActions.list({
  constants: {
    failed: FINANCE_REPORTS_NURSERIES_CREDIT_NOTES_FAILED,
    init: FINANCE_REPORTS_NURSERIES_CREDIT_NOTES,
    success: FINANCE_REPORTS_NURSERIES_CREDIT_NOTES_SUCCESS,
  },
  options,
  service: financeCreditNotesReportApiClient,
  serviceMethod: 'getNurseries',
})

export const clearNurseries = () => ({
  type: CLEAR_FINANCE_REPORTS_NURSERIES_CREDIT_NOTES,
})
