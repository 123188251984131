import _ from 'lodash'
import useMedia from 'use-media'

import React from 'react'

import { Avatar, BlockedIcon, Typography } from 'components'

import i18n from 'translations'

import {
  StyledAvatar,
  StyledChild,
  StyledIcons,
  StyledShowMore,
  StyledSubTitle,
  StyledTitleAvatar,
  StyledWrapper,
} from './ActivitiesStatisticsStyled'

const ActivitiesStatisticsView = ({
  onShowActivitiesStatistics,
  records,
  totalResults,
}) => {
  if (!records || !records?.length) {
    return null
  }

  let recordsAmount = 7

  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (useMedia({ maxWidth: '980px' })) {
    recordsAmount = 6
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (useMedia({ maxWidth: '840px' })) {
    recordsAmount = 5
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (useMedia({ maxWidth: '740px' })) {
    recordsAmount = 4
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (useMedia({ maxWidth: '640px' })) {
    recordsAmount = 3
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (useMedia({ maxWidth: '540px' })) {
    recordsAmount = 2
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (useMedia({ maxWidth: '440px' })) {
    recordsAmount = 1
  }

  return (
    <React.Fragment>
      <Typography
        margin="0 0 10px"
      >
        {i18n.t('module:DailyDiary:Activities:numberOfActivities')}
      </Typography>
      <StyledWrapper>
        {_.map(records?.slice(0, recordsAmount), ({
          child: { firstName, id, information, photo, surname },
          childRegisterDiaryRecordStatistics: { activityRecordsCount },
        }) => (
          <StyledChild
            key={id}
            recordsAmount={recordsAmount}
          >
            <Avatar
              direction="vertical"
              initials={[firstName, surname]}
              src={photo}
              subTitle={(
                <StyledSubTitle>
                  <Typography
                    margin="5px 0 10px"
                    bold
                    primary
                  >
                    {activityRecordsCount}
                  </Typography>
                  <StyledIcons>
                    {!information?.canBePhotographed && (
                      <BlockedIcon
                        color="#575756"
                        icon="camera"
                        title={i18n.t('global:noPermissionInPhotos')}
                      />
                    )}
                    {!information?.canBeTaggedInGroupPhotos && (
                      <BlockedIcon
                        color="#575756"
                        icon="group"
                        title={i18n.t('global:noPermissionInGroupPhotos')}
                      />
                    )}
                  </StyledIcons>
                </StyledSubTitle>
              )}
              title={`${firstName} ${surname}`}
            />
          </StyledChild>
        ))}
        {recordsAmount < totalResults && (
          <StyledShowMore
            recordsAmount={recordsAmount}
            onClick={() => onShowActivitiesStatistics(recordsAmount)}
          >
            <StyledAvatar>
              {`+${totalResults - recordsAmount}`}
            </StyledAvatar>
            <StyledTitleAvatar>
              {i18n.t('global:showMore')}
            </StyledTitleAvatar>
          </StyledShowMore>
        )}
      </StyledWrapper>
    </React.Fragment>
  )
}

export default ActivitiesStatisticsView
