import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledFutureSessionButtonContainer = styled.div`
  align-items: center;
  border-top: 1px dashed ${NEUTRAL_COLOURS.SILVER};
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding-top: 20px;
`
