import React from 'react'

import { Callout, ModalBox } from 'components'

import ObservationsExportForm from './components/ObservationsExportForm'

const ObservationsExportModalView = ({
  child,
  errorMessages,
  isKeyPersonsDisabled,
  isRoomsDisabled,
  isSubmitting,
  onCloseClick,
  onSubmit,
}) => (
  <ModalBox
    title="Export observations"
    autoHeight
    onCloseClick={onCloseClick}
  >
    <Callout content={errorMessages} error />
    <ObservationsExportForm
      child={child}
      isKeyPersonsDisabled={isKeyPersonsDisabled}
      isRoomsDisabled={isRoomsDisabled}
      isSubmitting={isSubmitting}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    />
  </ModalBox>
)

export default ObservationsExportModalView
