import _ from 'lodash'

import React, { PropsWithChildren } from 'react'
import browser from 'browser-detect'

import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'
import { typeByObject } from 'utils/typescript'

import { CircleIcon, Icon } from 'components'

import { ICON_VARIANT, StyledCallout, StyledContent, StyledIcon, StyledIconWrapper } from './CalloutStyled'

interface CalloutProps {
  color?: string
  content: string[] | string | React.ReactNode
  error?: boolean
  icon?: IconType
  iconOnTop?: boolean
  iconVariant?: typeByObject<typeof ICON_VARIANT>
  margin?: string
  noBottomMargin?: boolean
  noLeftBorder?: boolean
  transparent?: boolean
}

const Callout: React.FC<PropsWithChildren<CalloutProps>> = ({
  color = getBrandingColor('primary-color'),
  content,
  error,
  icon,
  iconOnTop,
  iconVariant = ICON_VARIANT.DEFAULT,
  margin,
  noBottomMargin,
  noLeftBorder,
  transparent,
}): any => {
  if (
    !content
    || (_.isObject(content) && !_.keys(content)?.length)
    || (_.isArray(content) && (!_.find(content, (i) => !!i) || _.isEmpty(_.find(content, (i) => !!i))))
  ) {
    return null
  }

  const { name } = browser()

  const renderIcon = () => (
    <StyledIconWrapper $iconOnTop={iconOnTop}>
      {iconVariant === ICON_VARIANT.CIRCLE
        ? (
          <CircleIcon
            background={error ? FLAG_COLOURS.ERROR : color}
            color={NEUTRAL_COLOURS.WHITE}
            icon={icon}
            iconSize={14}
            size={20}
          />
        ) : (
          <StyledIcon>
            <Icon
              color={error ? FLAG_COLOURS.ERROR : color}
              height={14}
              icon={icon}
            />
          </StyledIcon>
        )}
    </StyledIconWrapper>
  )

  const renderCallout = (message) => (
    <StyledCallout
      $color={error ? FLAG_COLOURS.ERROR : color}
      $hasIcon={!!icon}
      $iconVariant={iconVariant}
      $margin={margin}
      $noBottomMargin={noBottomMargin}
      $noLeftBorder={noLeftBorder}
      $transparent={transparent}
      data-callout-error={false}
    >
      {icon && renderIcon()}
      <StyledContent browserName={name}>
        {message}
      </StyledContent>
    </StyledCallout>
  )

  if (_.isArray(content) && error) {
    return _.map(content, (message) => renderCallout(message))
  }

  return renderCallout(content)
}

export default Callout
