import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_CHILD_REGISTERS,
  GET_CHILD_REGISTERS,
  GET_CHILD_REGISTERS_FAILED,
  GET_CHILD_REGISTERS_SUCCESS,
} from './constants'

export const list = (
  options: BasicActionOptionsProps,
) => ({ childRegistersApiClient }) => generateBasicActions.list({
  constants: {
    failed: GET_CHILD_REGISTERS_FAILED,
    init: GET_CHILD_REGISTERS,
    success: GET_CHILD_REGISTERS_SUCCESS,
  },
  options,
  service: childRegistersApiClient,
  serviceMethod: 'list',
})

export const clearList = () => ({
  type: CLEAR_CHILD_REGISTERS,
})
