import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as observationsSelectors from './selectors'

const withObservationsService = (WrappedComponent) => {
  const mapState = ({ observations }) => ({ observations })

  const mapDispatch = injectActions('observationsActions', actions)

  const Component = ({ observations, observationsActions, ...other }) => (
    <WrappedComponent
      observationsActions={observationsActions}
      observationsListState={observations.list}
      observationsSelectors={observationsSelectors}
      observationsSingleState={observations.single}
      {...other}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withObservationsService
