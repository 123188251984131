import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import selectors from './selectors'
import actions from './actions'
import { ChildBacsDetailsSingleState } from './models'

export interface withChildBacsDetailsServiceProps {
  childBacsDetailsActions: typeof actions
  childBacsDetailsSelectors: typeof selectors
  childBacsDetailsSingleState: ChildBacsDetailsSingleState
}

const withChildBacsDetailsService = <P extends withChildBacsDetailsServiceProps> (
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ childBacsDetails }) => ({ childBacsDetails })

  const mapDispatch = injectActions('childBacsDetailsActions', actions)

  const Component = ({ childBacsDetails, childBacsDetailsActions, ...other }) => (
    <WrappedComponent
      childBacsDetailsActions={childBacsDetailsActions}
      childBacsDetailsSelectors={selectors}
      childBacsDetailsSingleState={childBacsDetails.single}
      {...other as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withChildBacsDetailsService
