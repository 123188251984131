import React from 'react'

import { EmptyState, Spinner } from 'components'

import i18n from 'translations'

import FinanceCurrentPlanSessions from './FinanceCurrentPlanSessions'
import FinanceCurrentPlanAdhocSessions from './FinanceCurrentPlanAdhocSessions'
import FinanceCurrentPlanExtraItems from './FinanceCurrentPlanExtraItems'
import FinanceCurrentPlanFundings from './FinanceCurrentPlanFundings'
import FinanceCurrentPlanDiscounts from './FinanceCurrentPlanDiscounts'

import {
  StyledContainer,
  StyledContentContainer,
  StyledContentViewContainer,
  StyledFooterContainer,
} from './FinanceCurrentPlanSummaryStyled'

const FinanceCurrentPlanSummary = ({
  childAdhocSessionCount,
  childCurrentSessions,
  childDiscounts,
  childExtraItems,
  childFirstName,
  childFundings,
  isCurrentPlanLoading,
  onViewPlanDetailClick,
}) => {
  const renderContent = () => {
    if (isCurrentPlanLoading) {
      return <Spinner />
    }

    // NOTE: checking if data does not exist for any type
    if (
      (!childCurrentSessions || !childCurrentSessions.length)
      && !childAdhocSessionCount
      && (!childExtraItems || !childExtraItems.length)
      && (!childFundings || !childFundings.length)
    ) {
      return (
        <EmptyState
          icon="current-plan"
          text1={i18n.t('module:Children:Child:Finance:PlanView:Summary:emptyState', { childFirstName })}
        />
      )
    }

    return (
      <React.Fragment>
        <StyledContentContainer>
          <StyledContentViewContainer>
            <FinanceCurrentPlanSessions childSessions={childCurrentSessions} />
            <FinanceCurrentPlanAdhocSessions childAdhocSessionCount={childAdhocSessionCount} />
          </StyledContentViewContainer>
          <StyledContentViewContainer>
            <FinanceCurrentPlanExtraItems childExtraItems={childExtraItems} />
            <FinanceCurrentPlanDiscounts childDiscounts={childDiscounts} />
            <FinanceCurrentPlanFundings childFundings={childFundings} />
          </StyledContentViewContainer>
        </StyledContentContainer>
        <StyledFooterContainer onClick={onViewPlanDetailClick}>
          {i18n.t('module:Children:Child:Finance:PlanView:Summary:detailViewButtonLabel')}
        </StyledFooterContainer>
      </React.Fragment>
    )
  }

  return (
    <StyledContainer>
      {renderContent()}
    </StyledContainer>
  )
}

export default FinanceCurrentPlanSummary
