import _ from 'lodash'

import { getBrandingColor } from 'utils/branding'

import i18n from 'translations'

export const OBSERVATION_STATUSES = {
  APPROVED: {
    color: () => getBrandingColor('tertiary-color'),
    label: i18n.t('module:Approvals:Status:approved'),
    value: 'APPROVED',
  },
  CHANGES_REQUESTED: {
    color: () => '#FF927C',
    label: i18n.t('module:Approvals:Status:changesRequested'),
    value: 'CHANGES_REQUESTED',
  },
  DRAFT: {
    color: () => '#808080',
    label: _.upperFirst(i18n.t('global:draft')),
    value: 'DRAFT',
  },
  PENDING_APPROVAL: {
    color: () => getBrandingColor('primary-color'),
    label: i18n.t('module:Approvals:Status:pendingApproval'),
    value: 'PENDING_APPROVAL',
  },
}

export const OBSERVATION_STATUSES_OPTIONS = _.map(OBSERVATION_STATUSES, ({ label, value }) => ({
  label,
  value,
}))
