import React from 'react'

import { StyledContainer, StyledTitle, StyledValue } from './GrayBoxStyled'

const GrayBox = ({
  title,
  value,
}) => (
  <StyledContainer>
    <StyledTitle>
      {title}
    </StyledTitle>
    <StyledValue>
      {value}
    </StyledValue>
  </StyledContainer>
)

export default GrayBox
