import React from 'react'

import { Nursery } from 'services/nurseries/models'

import { generateRoute } from 'utils/routing'

import { SessionTimeout } from 'module/Management/Security/GeneralSecuritySettings/components'
import { SecuritySettingsProps } from 'module/Management/Security/GeneralSecuritySettings/GeneralSecuritySettingsView'

import { Callout, Field, Section, Spinner } from 'components'

import i18n from 'translations'
import NurseriesListTable from 'module/Settings/components/NurseriesListTable'

interface SecurityViewProps {
  errorMessages?: string[] | string
  globalSecurityTypeDescription?: string
  isGlobalInheritanceEnabled?: boolean
  isLoading?: boolean
  nurseries?: Nursery[]
  onEditClick?: () => void
  onPageChange?: (page: number) => void
  onSessionTimeoutSubmit?: (formValues: any) => void
  page?: number
  pageCount?: number
  perPage: number
  securitySettings?: SecuritySettingsProps
  totalResults: number
}

const SecurityView: React.FC<SecurityViewProps> = ({
  errorMessages,
  globalSecurityTypeDescription,
  isGlobalInheritanceEnabled,
  isLoading,
  nurseries,
  onEditClick,
  onPageChange,
  onSessionTimeoutSubmit,
  page,
  pageCount,
  perPage,
  securitySettings,
  totalResults,
}) => {
  if (isLoading && 1 === page) {
    return <Spinner />
  }

  return (
    <React.Fragment>
      <Callout content={errorMessages} error />
      <Section title={i18n.t('module:Settings:Security:title')}>
        <Field.InlineEdit
          actions={<Field.InlineEdit.Actions onEditClick={onEditClick} />}
          description={globalSecurityTypeDescription}
          label={i18n.t('module:Settings:Security:GlobalSettings:label')}
        />
      </Section>
      <Section
        title={isGlobalInheritanceEnabled
          ? i18n.t('module:Settings:Security:SessionTimeout:globalTitle')
          : i18n.t('module:Settings:Security:SessionTimeout:title')}
      >
        <SessionTimeout
          isFetching={isLoading}
          values={securitySettings}
          onSubmit={onSessionTimeoutSubmit}
        />
      </Section>
      {!isGlobalInheritanceEnabled && !!nurseries?.length && (
        <Section title={i18n.t('module:Settings:Security:NurseryList:title')}>
          <NurseriesListTable
            getActionRouting={({ id }) => generateRoute('SETTINGS.SECURITY.ITEM', { nurseryId: id })}
            isFetching={isLoading}
            noDataIcon="nursery"
            nurseries={nurseries}
            page={page}
            pageCount={pageCount}
            perPage={perPage}
            totalResults={totalResults}
            onPageChange={onPageChange}
          />
        </Section>
      )}
    </React.Fragment>
  )
}

export default SecurityView
