import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`

export const StyledContent = styled.div`
  padding: 10px 15px 0 15px;
  flex: 1;
  overflow-y: auto;
`

export const StyledForm = styled.div`
  height: 100%;
  padding-bottom: 10px;

  & form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  & .scrollableForm {
    flex: 1;
    padding: 10px 15px 0 15px;
    overflow-y: auto;
  }
`

export const StyledAvatarItem = styled.div`
  display: grid;
  column-gap: 10px;
  align-items: center;
  grid-template-columns: auto auto;
  margin-bottom: 10px;
`

export const StyledCollapseContent = styled.div`
  padding: 10px;
`

export const StyledListItemRow = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const StyledFooter = styled.div`
  padding: 0 15px 10px;
`
