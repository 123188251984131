export const INTERVAL_PERIOD_TYPE = {
  AFTER: 'AFTER',
  BEFORE: 'BEFORE',
}

export const INTERVAL_PERIOD_TYPE_OPTIONS = [
  {
    label: 'After',
    value: INTERVAL_PERIOD_TYPE.AFTER,
  },
  {
    label: 'Before',
    value: INTERVAL_PERIOD_TYPE.BEFORE,
  },
]
