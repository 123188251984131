import { mergeData } from 'utils/data'

import {
  CLEAR_NURSERY_EXTRA_ITEMS_LIST,
  LIST_NURSERY_EXTRA_ITEMS,
  LIST_NURSERY_EXTRA_ITEMS_FAILED,
  LIST_NURSERY_EXTRA_ITEMS_SUCCESS,
} from './actions'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: {},
}

export default (state = initialState, { error, mergeResult, payload, type }) => {
  switch (type) {
    case LIST_NURSERY_EXTRA_ITEMS:
      return { ...state, error: initialState.error, isFetching: true }
    case LIST_NURSERY_EXTRA_ITEMS_SUCCESS:
      return {
        ...state,
        data: mergeData(state.data, payload.data, mergeResult),
        isFetching: false,
        meta: payload.meta,
      }
    case LIST_NURSERY_EXTRA_ITEMS_FAILED:
      return { ...state, error, isFetching: false }
    case CLEAR_NURSERY_EXTRA_ITEMS_LIST:
      return initialState
    default:
      return state
  }
}
