import React, { PropsWithChildren } from 'react'

import { noop } from 'constants/global'

import { Button, FooterActions } from 'components'

import i18n from 'translations'

import { StyledFooter } from './CommentDrawerStyled'

interface CommentDrawerFooterProps {
  disabled?: boolean
  isLoading?: boolean
  label?: string
  onClick?: any
}

const CommentDrawerFooter: React.FC<PropsWithChildren<CommentDrawerFooterProps>> = ({
  disabled,
  isLoading,
  label = i18n.t('global:Add'),
  onClick = noop,
}) => (
  <StyledFooter>
    <FooterActions noBorder noMargin>
      <FooterActions.Group>
        <FooterActions.Item>
          <Button
            disabled={disabled}
            isLoading={isLoading}
            label={label as string}
            negativeMargins
            onClick={onClick}
          />
        </FooterActions.Item>
      </FooterActions.Group>
    </FooterActions>
  </StyledFooter>
)

export default CommentDrawerFooter
