import {
  AUTO_CALCULATE_BULK_INVOICE,
  AUTO_CALCULATE_BULK_INVOICE_FAILED,
  AUTO_CALCULATE_BULK_INVOICE_SUCCESS,
  BULK_INVOICE_CLEAR_LIST,
  BULK_INVOICE_LIST_CHILDREN,
  BULK_INVOICE_LIST_CHILDREN_FAILED,
  BULK_INVOICE_LIST_CHILDREN_SUCCESS,
  BULK_PREVIOUS_INVOICE_LIST,
  BULK_PREVIOUS_INVOICE_LIST_FAILED,
  BULK_PREVIOUS_INVOICE_LIST_SUCCESS,
  CREATE_BULK_INVOICES,
  CREATE_BULK_INVOICES_FAILED,
  CREATE_BULK_INVOICES_SUCCESS,
  UPDATE_BULK_INVOICES_LIST,
} from './constants'

import {
  updateBulkInvoices,
  updateCalculatedInvoice,
  updateCalculatedInvoiceException,
  updateChildList,
  updatePreviousInvoices,
} from './helpers'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false,
  meta: {},
  response: null,
}

export default (state = initialState, { childId, error, payload, refreshData, type }) => {
  switch (type) {
    case BULK_INVOICE_LIST_CHILDREN:
      return { ...state, error: initialState.error, isFetching: true }
    case BULK_INVOICE_LIST_CHILDREN_SUCCESS:
      return { ...state, data: updateChildList(state, payload, refreshData), isFetching: false, meta: payload.meta }
    case BULK_INVOICE_LIST_CHILDREN_FAILED:
      return { ...state, error, isFetching: false }
    case BULK_INVOICE_CLEAR_LIST:
      return initialState
    case AUTO_CALCULATE_BULK_INVOICE:
      return state
    case AUTO_CALCULATE_BULK_INVOICE_SUCCESS:
      return updateCalculatedInvoice(state, childId, payload)
    case AUTO_CALCULATE_BULK_INVOICE_FAILED:
      return updateCalculatedInvoiceException(state, childId, error)
    case BULK_PREVIOUS_INVOICE_LIST:
      return state
    case BULK_PREVIOUS_INVOICE_LIST_SUCCESS:
      return updatePreviousInvoices(state, childId, payload)
    case BULK_PREVIOUS_INVOICE_LIST_FAILED:
      return state
    case CREATE_BULK_INVOICES:
      return state
    case CREATE_BULK_INVOICES_SUCCESS:
    case UPDATE_BULK_INVOICES_LIST:
      return updateBulkInvoices(state, payload)
    case CREATE_BULK_INVOICES_FAILED:
      return state
    default:
      return state
  }
}
