import React, { PropsWithChildren } from 'react'

import ToolbarGroup from './ToolbarGroup'
import ToolbarItem from './ToolbarItem'
import { StyledContainer } from './ToolbarStyled'

interface ToolbarRoot<T> extends React.FC<PropsWithChildren<T>> {
  Group?: typeof ToolbarGroup
  Item?: typeof ToolbarItem
}

interface ToolbarProps {
  bottomLine?: boolean
  noMargin?: boolean
}

const Toolbar: ToolbarRoot<ToolbarProps> = ({
  bottomLine,
  children,
  noMargin,
}) => (
  <StyledContainer
    $bottomLine={bottomLine}
    $noMargin={noMargin}
  >
    {children}
  </StyledContainer>
)

export default Toolbar
