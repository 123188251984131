import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Currency, Icon } from 'components'

import {
  StyledBox,
  StyledContainer,
  StyledDescription,
  StyledLastPeriod,
  StyledLastPeriodLabel,
  StyledLastPeriodValue,
  StyledLeftColumn,
  StyledRightColumn,
  StyledValue,
} from './MobileStatisticsStyled'

const MobileStatistics = ({ previousStatistics, statistics }) => previousStatistics && statistics && (
  <StyledContainer>
    <StyledBox>
      <StyledLeftColumn primary>
        <Icon
          color={NEUTRAL_COLOURS.WHITE}
          height={34}
          icon="coin"
        />
      </StyledLeftColumn>
      <StyledRightColumn>
        <StyledDescription>
          Fee income
        </StyledDescription>
        <StyledValue>
          <Currency
            value={statistics.totalSessions}
          />
        </StyledValue>
        <StyledLastPeriod>
          <StyledLastPeriodValue
            red={0 > statistics.totalSessions - previousStatistics.totalSessions}
          >
            <Icon
              color={
                0 > statistics.totalSessions - previousStatistics.totalSessions ? '#D95A3F' : '#8CBE91'
              }
              height={12}
              icon={
                0 > statistics.totalSessions - previousStatistics.totalSessions ? 'arrow-down' : 'arrow-up'
              }
            />
            <Currency
              value={statistics.totalSessions - previousStatistics.totalSessions}
            />
          </StyledLastPeriodValue>
          <StyledLastPeriodLabel>
            vs last period
          </StyledLastPeriodLabel>
        </StyledLastPeriod>
      </StyledRightColumn>
    </StyledBox>
    <StyledBox>
      <StyledLeftColumn secondary>
        <Icon
          color={NEUTRAL_COLOURS.WHITE}
          height={34}
          icon="coin"
        />
      </StyledLeftColumn>
      <StyledRightColumn>
        <StyledDescription>
          Funding income
        </StyledDescription>
        <StyledValue>
          <Currency
            value={statistics.totalFundingIncome}
          />
        </StyledValue>
        <StyledLastPeriod>
          <StyledLastPeriodValue
            red={0 > statistics.totalFundingIncome - previousStatistics.totalFundingIncome}
          >
            <Icon
              color={
                0 > statistics.totalFundingIncome - previousStatistics.totalFundingIncome ? '#D95A3F' : '#8CBE91'
              }
              height={12}
              icon={
                0 > statistics.totalFundingIncome - previousStatistics.totalFundingIncome ? 'arrow-down' : 'arrow-up'
              }
            />
            <Currency
              value={statistics.totalFundingIncome - previousStatistics.totalFundingIncome}
            />
          </StyledLastPeriodValue>
          <StyledLastPeriodLabel>
            vs last period
          </StyledLastPeriodLabel>
        </StyledLastPeriod>
      </StyledRightColumn>
    </StyledBox>
  </StyledContainer>
)

export default MobileStatistics
