import React from 'react'
import { WrappedFieldProps } from 'redux-form'

import { TextAreaFieldProps } from 'components/TextAreaField/TextAreaField'

import { TextAreaField } from 'components'

type FormTextAreaFieldFullProps = TextAreaFieldProps & WrappedFieldProps

const FormTextAreaField: React.FC<FormTextAreaFieldFullProps> = ({
  input: { onBlur, onChange, value },
  meta: { error, submitFailed, valid },
  ...rest
}) => (
  <TextAreaField
    {...rest}
    error={submitFailed && error}
    valid={!(submitFailed && !valid)}
    value={value}
    onBlur={onBlur}
    onChange={onChange}
  />
)

export default FormTextAreaField
