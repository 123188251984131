import Cookie from 'js-cookie'

interface CookieAttributes {
  domain?: string
  expires?: number | Date
  path?: string
  sameSite?: 'strict' | 'Strict' | 'lax' | 'Lax' | 'none' | 'None'
  secure?: boolean
}

const get = (name: string): string => Cookie.get(name)

const set = (name: string, value: string, options: CookieAttributes): string => Cookie.set(name, value, options)

const remove = (name: string, options: CookieAttributes): string => Cookie.remove(name, options)

export default {
  get,
  remove,
  set,
}
