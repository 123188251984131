import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { ALL_VALUES, NEWS_STATUSES } from 'services/legacy/newsletters/constants'

import { getFilterOptions } from '../helpers'

const getNews = (state) => state.newsletters

export const getNewsListSelector = createSelector(
  [getNews],
  (state) => {
    if (!state) {
      return null
    }

    return state.list
  },
)

export const getNewsListDataSelector = createSelector(
  [getNewsListSelector],
  (state) => state.data,
)

export const getNewsListMetaSelector = createSelector(
  [getNewsListSelector],
  (state) => state.meta,
)

export const getNewsListFormattedDataSelector = createSelector(
  [getNewsListDataSelector],
  (newsletterslist) => _.map(newsletterslist, (newsletter) => {
    const { allChildren, allStaff, filters } = newsletter

    const displayFiltersOptions = getFilterOptions({
      allChildren,
      allStaff,
      filters,
    })

    const displayFilters = _.join(_.map(displayFiltersOptions, ({ label }) => label), ', ')

    return {
      ...newsletter,
      displayFilters,
    }
  }),
)

export const getNewsListCriteriaSelector = createSelector(
  [(filters) => filters],
  (filters) => {
    const criteria = []

    criteria.push({
      field: 'archived',
      value: 0,
    })

    if (filters) {
      const { date: { after, before }, search, sharedWith, type } = filters || {}

      if (after) {
        criteria.push({
          comparator: 'after',
          field: 'updatedAt',
          value: moment(after).startOf('day').utc().toISOString(),
        })
      }

      if (before) {
        criteria.push({
          comparator: 'before',
          field: 'updatedAt',
          value: moment(before).endOf('day').utc().toISOString(),
        })
      }

      if (type && type !== NEWS_STATUSES.ALL) {
        criteria.push({
          field: 'status',
          value: type,
        })
      }

      if (search) {
        criteria.push({
          field: 'title[]',
          value: search,
        })
      }

      if (sharedWith?.value) {
        if (ALL_VALUES.ALL_CHILDREN === sharedWith.value) {
          criteria.push({
            field: 'allChildren',
            value: true,
          })
        } else if (ALL_VALUES.ALL_STAFF === sharedWith.value) {
          criteria.push({
            field: 'allStaff',
            value: true,
          })
        } else {
          criteria.push({
            field: 'filters.class',
            value: sharedWith.value,
          })
        }
      }
    }

    return criteria
  },
)
