import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background-color: ${getBrandingColor('primary-color')};
  color: ${getBrandingColor('primary-color-contrasted')};
  border-radius: 4px;
  height: 100%;
  min-height: 208px;
  overflow-y: auto;

  ${({ isDisabled }) => isDisabled && `
    opacity: .5;
  `}
`

export const StyleHeader = styled.h2`
  margin: 5px 20px 0 20px;
  padding-bottom: 6px;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 100;
  letter-spacing: -1px;
  border-bottom: 1px solid rgba(255,255,255,.5);
`

export const StyledContactNumberContainer = styled.div`
  position: relative;
  margin-top: 6px; 
`

export const StyledContactNumber = styled.p`
  font-size: 18px;
  color: ${NEUTRAL_COLOURS.WHITE};
  text-align: center;
  width: 100%;
  display: block;
  margin: 0;
`

export const StyledEditButton = styled.div`
  position: absolute;
  top: -5px;
  right: 9px;
`

export const StyledUserContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${getBrandingColor('secondary-color')};
  padding: 10px;
  margin-top: 10px;
`

export const StyledUserProfile = styled.div`
  display: flex;
  margin-top: 10px;
`

export const StyledContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
`

export const StyledAddressContainer = styled.div`
  width: 45%;
  padding-left: 10px;
`

export const StyledTotalContainer = styled.div`
  width: 45%;
  text-align: center;
  margin-left: 5%;
  border-left: 1px solid rgba(255,255,255,5);
`

export const StyledChildTotalContainer = styled.div`
  padding-bottom: 10px;
  margin-left: 10px;
  border-bottom: 1px solid rgba(255,255,255,.5);
`

export const StyledStaffTotalContainer = styled.div`
  margin-top: 5px;
  margin-left: 10px;
`

export const StyledContentText = styled.span`
  display: block;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 1px;
`
