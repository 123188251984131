import {
  CLEAR_CHILD_BULK_FUNDING,
  CREATE_CHILD_BULK_FUNDING,
  CREATE_CHILD_BULK_FUNDING_FAILED,
  CREATE_CHILD_BULK_FUNDING_SUCCESS,
  LIST_CHILD_BULK_FUNDING,
  LIST_CHILD_BULK_FUNDING_FAILED,
  LIST_CHILD_BULK_FUNDING_SUCCESS,
  UPDATE_CHILD_BULK_FUNDING,
  UPDATE_CHILD_BULK_FUNDING_FAILED,
  UPDATE_CHILD_BULK_FUNDING_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ childBulkFundingApiClient }) => (dispatch) => {
  const { onSuccess, params, silent } = options

  dispatch({
    params,
    silent,
    type: LIST_CHILD_BULK_FUNDING,
  })

  childBulkFundingApiClient.list(params).then(
    (data) => {
      dispatch({
        payload: data,
        type: LIST_CHILD_BULK_FUNDING_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: LIST_CHILD_BULK_FUNDING_FAILED,
      })
    },
  )
}

export const createBulk = (options = {}) => ({ childBulkFundingApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params, payload } = options

  dispatch({
    params,
    payload,
    type: CREATE_CHILD_BULK_FUNDING,
  })

  return childBulkFundingApiClient.create(payload, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: CREATE_CHILD_BULK_FUNDING_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      const errorResponse = error.response

      dispatch({
        error: !errorResponse.extra ? errorResponse : null,
        type: CREATE_CHILD_BULK_FUNDING_FAILED,
      })

      if (onFailed) {
        onFailed(errorResponse)
      }
    },
  )
}

export const updateBulk = (options = {}) => ({ childBulkFundingApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params, payload } = options

  dispatch({
    params,
    payload,
    type: UPDATE_CHILD_BULK_FUNDING,
  })

  return childBulkFundingApiClient.update(payload, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: UPDATE_CHILD_BULK_FUNDING_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      const errorResponse = error.response

      dispatch({
        error: !errorResponse.extra ? errorResponse : null,
        type: UPDATE_CHILD_BULK_FUNDING_FAILED,
      })

      if (onFailed) {
        onFailed(errorResponse)
      }
    },
  )
}

export const clear = () => ({
  type: CLEAR_CHILD_BULK_FUNDING,
})
