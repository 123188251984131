import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  ADD_MANUALLY_NURSERY_FILES,
  CLEAR_NURSERY_FILES_LIST,
  GET_NURSERY_FILES_LIST,
  GET_NURSERY_FILES_LIST_FAILED,
  GET_NURSERY_FILES_LIST_SUCCESS,
  GET_NURSERY_FILES_TOTAL_RESULTS_LIST,
  GET_NURSERY_FILES_TOTAL_RESULTS_LIST_FAILED,
  GET_NURSERY_FILES_TOTAL_RESULTS_LIST_SUCCESS,
  REMOVE_FILE_FROM_LIST,
} from './constants'

export const listNurseryFiles = (
  options: BasicActionOptionsProps,
) => ({ nurseriesApiClient }) => generateBasicActions.list({
  constants: {
    failed: GET_NURSERY_FILES_LIST_FAILED,
    init: GET_NURSERY_FILES_LIST,
    success: GET_NURSERY_FILES_LIST_SUCCESS,
  },
  options,
  service: nurseriesApiClient,
  serviceMethod: 'listNurseryFiles',
})

export const listNurseryFilesForTotalResults = (
  options: BasicActionOptionsProps,
) => ({ nurseriesApiClient }) => generateBasicActions.list({
  constants: {
    failed: GET_NURSERY_FILES_TOTAL_RESULTS_LIST_FAILED,
    init: GET_NURSERY_FILES_TOTAL_RESULTS_LIST,
    success: GET_NURSERY_FILES_TOTAL_RESULTS_LIST_SUCCESS,
  },
  options,
  service: nurseriesApiClient,
  serviceMethod: 'listNurseryFiles',
})

export const clearFilesList = () => ({
  type: CLEAR_NURSERY_FILES_LIST,
})

export const addManuallyNurseryFiles = (files) => ({
  payload: files,
  type: ADD_MANUALLY_NURSERY_FILES,
})

export const removeFileFromList = (file) => ({
  payload: file,
  type: REMOVE_FILE_FROM_LIST,
})
