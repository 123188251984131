import _ from 'lodash'
import { flatten, nest } from 'utils/flatnest'

import React from 'react'
import { compose } from 'recompose'

import { WrappedComponentType } from 'constants/types'

import { withRouter } from 'services/router'

export interface withRouteUtilsProps {
  setLocationQuery: (query: any, paramsToRemove?: any[]) => void
}

const withRouterUtils = <P extends withRouteUtilsProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const Component = (props) => {
    const setLocationQuery = (query, paramsToRemove = []) => {
      const { location, router } = props

      // NOTE: we are doing nest and flat again to remove the element if the parent is null
      // for e.g. if we pass the key value like this for selected dropdown
      // sharedWith.label: "Baby room"
      // sharedWith.type: "class"
      // sharedWith.value: 150
      // and while clear we just have to pass `sharedWith: null`
      let allQueries = flatten({ ...nest(location.query), ...nest(query) })

      _.each(paramsToRemove, (key) => {
        delete allQueries[key]
      })

      allQueries = _.pickBy(allQueries, (value) => {
        if (_.isNull(value) || _.isUndefined(value) || '' === value) {
          return false
        }

        let parsedValue

        try {
          parsedValue = value || JSON.parse(value)
        } catch (e) {
          parsedValue = value
        }

        return (
          !_.isNull(parsedValue)
          && !(_.isArray(parsedValue)
            && _.filter(parsedValue, (e) => _.isNull(e) || _.isUndefined(e),
            ).length === parsedValue.length)
        )
      })

      router.replace({
        pathname: location.pathname,
        query: _.pickBy(allQueries, (item) => null !== item),
      })
    }

    return <WrappedComponent setLocationQuery={setLocationQuery} {...props} />
  }

  Component.authParams = WrappedComponent.authParams

  const enhance = compose(withRouter)

  return enhance(Component)
}

export default withRouterUtils
