import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { submit } from 'redux-form'

import invoiceConstants from 'services/legacy/invoices/constants'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withInvoicesService } from 'services/legacy/invoices'
import { withCreditNotesService } from 'services/legacy/creditNotes'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouter } from 'services/router'

import CreditNoteInvoicesView from './CreditNoteInvoicesView'
import { CREDIT_NOTE_INVOICE_FORM } from './components/CreditNoteInvoicesForm'

const GROUPS = {
  read: [
    'invoice',
    'invoice.child',
    'invoice.items',
    'invoiceItem',
  ],
}

class CreditNoteInvoicesContainer extends Component {
  constructor(props) {
    super(props)

    const { location, paginationUtils } = props
    const { query } = location
    const { endDate, startDate } = query
    const { setPageLocationQuery } = paginationUtils

    this.state = {
      endDate,
      startDate,
    }

    setPageLocationQuery(false)
  }

  componentDidMount() {
    this.fetch()
  }

  fetch = () => {
    const {
      childId,
      fundingEndDate,
      fundingStartDate,
      invoicesActions,
      invoicesSelectors,
      paginationUtils,
    } = this.props
    const { endDate, startDate } = this.state
    const { page } = paginationUtils

    const criteria = invoicesSelectors.getCriteriaSelector({
      child: childId,
      invoiceEndDate: fundingEndDate || endDate,
      invoiceStartDate: fundingStartDate || startDate,
      not: {
        status: [
          invoiceConstants.STATUS_TYPES.CANCELLED,
          invoiceConstants.STATUS_TYPES.BAD_DEBT,
        ],
      },
      type: invoiceConstants.INVOICE_TYPES.INVOICE,
    })

    const listParams = {
      criteria,
      groups: GROUPS,
      limit: 100,
      order: [
        { sortField: 'startDate', sortOrder: 'ASC' },
        { sortField: 'endDate', sortOrder: 'DESC' },
      ],
      page,
    }

    invoicesActions.list(listParams, 1 !== page)
  }

  handleInvoiceItemClick = (childId, invoiceId) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.INVOICE_PREVIEW, {
      childId,
      invoiceId,
    })
  }

  handleSubmit = (fields) => {
    const {
      childInvoices,
      creditNotesActions,
      creditNotesSelectors,
      hasOnlyDraftInvoices,
      onContinueClick,
    } = this.props

    if (!childInvoices?.length) {
      onContinueClick()

      return
    }

    const criteria = creditNotesSelectors.getCreditNoteBulkCriteria({
      invoiceIds: _.map(childInvoices, ({ id }) => id),
    })

    let body = null

    if (hasOnlyDraftInvoices) {
      body = {
        description: ' ',
        issueDate: new Date(),
      }
    } else {
      body = {
        ...fields,
        issueDate: fields.issueDate.toDate(),
      }
    }

    creditNotesActions.createBulk(body, {
      onSuccess: onContinueClick,
      params: {
        criteria,
      },
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleContinueClick = () => {
    const { childInvoices, onContinueClick, submitForm } = this.props

    if (!childInvoices?.length) {
      return onContinueClick()
    }

    return submitForm()
  }

  render() {
    const {
      childId,
      childInvoices,
      hasOnlyDraftInvoices,
      isFetching,
      isSubmitting,
    } = this.props

    const initialValues = {
      issueDate: moment(),
    }

    return (
      <CreditNoteInvoicesView
        childId={childId}
        childInvoices={childInvoices}
        hasOnlyDraftInvoices={hasOnlyDraftInvoices}
        initialValues={initialValues}
        isLoading={isFetching}
        isSubmitting={isSubmitting}
        onContinueClick={this.handleContinueClick}
        onInvoiceItemClick={this.handleInvoiceItemClick}
        onPageChange={this.handlePageChange}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  creditNotesSingleState,
  invoicesListState,
  invoicesSelectors,
}) => ({
  childInvoices: invoicesSelectors.getInvoiceListSelector(state),
  errorMessages: appSelectors.getErrorMessages(invoicesListState, creditNotesSingleState),
  hasOnlyDraftInvoices: invoicesSelectors.hasOnlyDraftInvoices(state),
  isFetching: appSelectors.getIsFetching(invoicesListState),
  isSubmitting: appSelectors.getIsSubmitting(creditNotesSingleState),
})

const mapDispatch = {
  submitForm: () => submit(CREDIT_NOTE_INVOICE_FORM),
}

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withInvoicesService,
  withCreditNotesService,
  withPaginationUtils,
  connect(mapState, mapDispatch),
)

export default enhance(CreditNoteInvoicesContainer)
