import {
  CLEAR_ORGANIZATION_MEMBERSHIPS,
  GET_ORGANIZATION_MEMBERSHIPS,
  GET_ORGANIZATION_MEMBERSHIPS_FAILED,
  GET_ORGANIZATION_MEMBERSHIPS_SUCCESS,
} from './constants'

export const listOrganizationMemberships = (options) => ({ membershipsApiClient }) => async (dispatch) => {
  const { mergeResult, onFailed, onSuccess, params } = options
  dispatch({ type: GET_ORGANIZATION_MEMBERSHIPS })

  await membershipsApiClient.listOrganizationMemberships(params).then(
    (response) => {
      dispatch({
        mergeResult,
        payload: response,
        type: GET_ORGANIZATION_MEMBERSHIPS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: GET_ORGANIZATION_MEMBERSHIPS_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const clearOrganizationList = () => ({
  type: CLEAR_ORGANIZATION_MEMBERSHIPS,
})
