import styled from 'styled-components'

import colors from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

import AutosizeInput from './components/AutosizeInput'

interface StyledContainerProps {
  $disabled?: boolean
  $valid?: boolean
}

export const StyledContainer = styled.div<StyledContainerProps>`
  align-items: center;
  flex: auto;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ $valid }) => (false === $valid ? getBrandingColor('tertiary-color') : colors.inputBorderColor)};
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  padding: 10px;
  padding-right: 10px;
  width: 100%;
  white-space: nowrap;
  display: inline-flex;

  ${({ $disabled }) => $disabled && `
    background-color: ${colors.disabled};
    cursor: not-allowed;
  `};
`

interface StyledAffixProps {
  $disabled?: boolean
}

export const StyledAffix = styled.span<StyledAffixProps>`
  color: #555;
  position: relative;

  ${({ $disabled }) => $disabled && `
    color: #959595;
  `};
`

interface StyledAutosizeInputProps {
  disabled?: boolean
}

export const StyledAutosizeInput = styled(AutosizeInput)<StyledAutosizeInputProps>`
  & input {
    appearance: none;
    background: none;
    border: none;
    padding: 0;
    width: 200%;

  ${({ disabled }) => disabled && `
    background-color: ${colors.disabled};
    cursor: not-allowed;
  `};
  }
`
