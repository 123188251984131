import { TableColumn } from 'components/Table/Table'

import i18n from 'translations'

export const TABLE_COLUMNS: TableColumn[] = [
  {
    align: 'left',
    field: 'entryDate',
    title: i18n.t('global:Date'),
    width: '30%',
  },
  {
    field: 'day',
    title: i18n.t('global:Day'),
    width: '30%',
  },
  {
    field: 'totalChildren',
    title: i18n.t('module:Reports:NurseryMilk:Table:Header:children'),
    width: '40%',
  },
]
