import _ from 'lodash'

import React, { useMemo } from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'

import { PROGRESS_TRACKER_TYPES, PROGRESS_TRACKER_TYPES_DROPDOWN } from 'services/legacy/cohortTracking/constants'
import ReportGeneratorFilters from 'module/Learning/CohortTracking/components/ReportGeneratorFilters'

import { Form, Section, Typography } from 'components'

import i18n from 'translations'

export const PROGRESS_TRACKER_GENERATOR_FORM = 'ProgressTrackerGeneratorForm'

const ProgressTrackerGeneratorForm = ({
  ethnicities,
  formValues,
  frameworksList,
  frameworksOptions,
  handleSubmit,
  onChangeFramework,
  onChangeTrackerType,
  onResetFilters,
  onSubmit,
  terms,
}) => {
  const frameworkDetails = useMemo(
    () => _.find(frameworksList, ({ id }) => formValues?.framework?.value === id),
    [formValues?.framework?.value, frameworksList],
  )

  const renderFields = (
    <Section title={i18n.t('module:Learning:CohortTracking:ProgressTrackerGenerator:title')}>
      <Typography fontSize={15} margin="0 0 5px 0" bold>
        {i18n.t('module:Learning:CohortTracking:ProgressTrackerGenerator:pleaseSelectReportToCreate')}
      </Typography>
      <Typography margin="0 0 20px 0">
        {i18n.t('module:Learning:CohortTracking:global:description')}
      </Typography>
      <Form.Row
        label={i18n.t('module:Learning:CohortTracking:ProgressTrackerGenerator:trackerType')}
        labelMargin="0 20px 0 0"
        required
      >
        <Form.Row.FlexItem flex="0 0 240px" mobileFull>
          <Field
            component={Form.Select}
            name="type"
            options={PROGRESS_TRACKER_TYPES_DROPDOWN}
            placeholder={i18n.t('module:Learning:CohortTracking:ProgressTrackerGenerator:trackerType')}
            validate={isRequired}
            onChange={onChangeTrackerType}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Learning:CohortTracking:global:framework')}
        labelMargin="0 20px 0 0"
        required
      >
        <Form.Row.FlexItem flex="0 0 340px" mobileFull>
          <Field
            component={Form.Select}
            name="framework"
            options={frameworksOptions}
            placeholder={i18n.t('module:Learning:CohortTracking:global:framework')}
            validate={isRequired}
            onChange={onChangeFramework}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      {formValues?.type?.value === PROGRESS_TRACKER_TYPES.AREA_OF_LEARNING && (
        <Form.Row
          label={i18n.t('module:Learning:CohortTracking:global:selectTerm')}
          labelMargin="0 20px 0 0"
          required
        >
          <Form.Row.FlexItem flex="0 0 240px" mobileFull>
            <Field
              component={Form.Select}
              name="term"
              options={terms || []}
              placeholder={i18n.t('module:Learning:CohortTracking:global:selectTerm')}
              validate={isRequired}
            />
          </Form.Row.FlexItem>
        </Form.Row>
      )}
      {formValues?.type?.value === PROGRESS_TRACKER_TYPES.TERMLY_PROGRESS && (
        <Form.Row
          label={i18n.t('module:Learning:CohortTracking:global:areaOfLearning')}
          labelMargin="0 20px 0 0"
          required
        >
          <Form.Row.FlexItem flex="0 0 300px" mobileFull>
            <Field
              component={Form.Select}
              disabled={!formValues.framework}
              name="frameworkArea"
              options={_.map(frameworkDetails?.areas, ({ id, name }) => ({
                label: name,
                value: id,
              }))}
              placeholder={i18n.t('module:Learning:CohortTracking:global:areaOfLearning')}
              validate={isRequired}
            />
          </Form.Row.FlexItem>
        </Form.Row>
      )}
    </Section>
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {renderFields}
      <ReportGeneratorFilters
        ethnicities={ethnicities}
        onResetFilters={onResetFilters}
      />
      <Form.StickyFooter
        cancelLink={generateRoute('LEARNING.COHORT_TRACKING')}
        submitLabel={i18n.t('global:Generate')}
      />
    </Form>
  )
}

export default reduxForm({
  form: PROGRESS_TRACKER_GENERATOR_FORM,
})(ProgressTrackerGeneratorForm)
