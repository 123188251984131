import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { StyledContainer } from 'components/Avatar/AvatarStyled'

export const StyledList = styled.div`
  margin: 10px 0;
`

export const StyledRow = styled.article`
  margin-bottom: 20px;
  display: grid;
  align-items: flex-start;
  grid-template-columns: 130px auto;
  grid-template-areas: 'left content';
  
  @media(max-width: 780px) {
    grid-template-columns: auto;
    grid-template-areas: unset;
    
    ${StyledContainer} {
      grid-template-columns: 55px auto;
      grid-column-gap: 5px;
    }
  }
`

export const StyledLeftColumn = styled.div`
  background: ${NEUTRAL_COLOURS.SILVER};
  justify-content: center;
  padding: 20px 15px;
  height: 100%;
`

export const StyledContent = styled.div`
  background: #F5F5F5;
  display: grid;
  grid-template-rows: 1fr auto;
`

export const StyledItem = styled.div`
  padding: 20px 0;
  border-bottom: 2px solid ${NEUTRAL_COLOURS.SILVER};
    
  @media(max-width: 480px) {
    padding: 10px 0;
  }
`

export const StyledLists = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  
  ${StyledItem}:nth-last-of-type(1) {
    border-bottom: 0;
  }
  
  @media(max-width: 480px) {
    padding: 0 10px;
  }
`

export const StyledFooter = styled.footer`
  padding: 20px;
  display: flex;
`

export const StyledEmptyState = styled.div`
  padding: 20px;
  margin-bottom: 10px;
  font-style: italic;
`
