import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'

import { getBrandingColor } from 'utils/branding'
import { generateRoute } from 'utils/routing'

import { TYPES_OF_COHORT_TRACKING_DROPDOWN } from 'services/legacy/cohortTracking/constants'

import { EmptyState, Icon, List, Page, Section, Spinner, Typography } from 'components'

import i18n from 'translations'

import {
  StyledActionPlan,
  StyledAuthor,
  StyledCustomRow,
  StyledDate,
  StyledLeftColumn,
  StyledMiddleColumn,
  StyledMiddleContent,
  StyledRightColumn,
  StyledRightColumnTop,
  StyledRowCohortTracking,
} from './CohortTrackingListStyled'

const CohortTrackingListView = ({
  isFetching,
  items,
  terms,
}) => {
  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    if (!items || !items.length) {
      return (
        <EmptyState
          icon="formative-reports"
          text1={i18n.t('module:Learning:CohortTracking:CohortTrackingList:noRecords')}
        />
      )
    }

    const renderRow = (item) => {
      const { author, date, entity, id } = item

      const isProgressTracker = _.get(
        _.find(TYPES_OF_COHORT_TRACKING_DROPDOWN, ({ value }) => value === item.type),
        'isProgressTracker',
      )

      const renderTerm = () => {
        if (entity.term) {
          const reportTerm = _.find(terms, ({ value }) => value === entity.term)

          if (reportTerm) {
            return reportTerm.label
          }

          return ''
        }

        return entity.frameworkArea.name
      }

      const renderFilterRow = (label, value) => (
        <div>
          <Typography color={getBrandingColor('primary-color')} margin="0 0 4px" bold>
            {label}
          </Typography>
          <Typography>
            {value}
          </Typography>
        </div>
      )

      const content = (
        <StyledRowCohortTracking>
          <StyledLeftColumn>
            {isProgressTracker && (
              <React.Fragment>
                {i18n.t('module:Learning:CohortTracking:global:progressTracker')}
                <br />
              </React.Fragment>
            )}
            {_.get(_.find(TYPES_OF_COHORT_TRACKING_DROPDOWN, ({ value }) => value === item.type), 'label')}
          </StyledLeftColumn>
          <StyledMiddleColumn>
            <StyledMiddleContent>
              <StyledDate>
                {moment(date).format(DISPLAY_SHORT_DATE_FORMAT)}
              </StyledDate>
              <StyledAuthor>
                {author?.name}
              </StyledAuthor>
              {entity?.actionPlan && (
                <StyledActionPlan>
                  <Icon
                    color={getBrandingColor('tertiary-color')}
                    height={14}
                    icon="check"
                  />
                  {i18n.t('module:Learning:CohortTracking:global:actionPlan')}
                </StyledActionPlan>
              )}
            </StyledMiddleContent>
          </StyledMiddleColumn>
          <StyledRightColumn>
            <StyledRightColumnTop>
              {renderFilterRow(
                i18n.t('global:Framework'),
                entity.framework?.name || '-',
              )}
              {renderFilterRow(
                entity.term
                  ? i18n.t('module:Learning:CohortTracking:CohortTrackingList:term')
                  : i18n.t('module:Learning:CohortTracking:CohortTrackingList:areaOfLearning'),
                renderTerm(),
              )}
              {renderFilterRow(
                i18n.t('module:Learning:CohortTracking:CohortTrackingList:area'),
                entity?.frameworkGroup?.name || entity.frameworkGroup?.area?.name || '-',
              )}
            </StyledRightColumnTop>
            <div>
              {renderFilterRow(
                i18n.t('global:Filters'),
                entity.filters || '-',
              )}
            </div>
          </StyledRightColumn>
        </StyledRowCohortTracking>
      )

      let finalHref = ''

      const hrefPrefix = _.get(
        _.find(TYPES_OF_COHORT_TRACKING_DROPDOWN, ({ value }) => value === item.type),
        'hrefPrefix',
      )

      if (isProgressTracker) {
        finalHref = `${generateRoute('LEARNING.COHORT_TRACKING.PROGRESS_TRACKER.REPORT', {
          id: entity.id,
        })}?type=${hrefPrefix}`
      } else {
        finalHref = `${generateRoute('LEARNING.COHORT_TRACKING.COMPARISON_REPORT.REPORT', {
          id: entity.id,
        })}?type=${hrefPrefix}`
      }

      return (
        <StyledCustomRow key={id}>
          <List.Standard.Row
            content={content}
            to={finalHref}
            contentFull
            contentNoPadding
          />
        </StyledCustomRow>
      )
    }

    return (
      <List.Standard>
        {_.map(items, renderRow)}
      </List.Standard>
    )
  }

  const actions = (
    <Section.Actions
      primary={[{
        disabled: !terms?.length,
        label: i18n.t('module:Learning:CohortTracking:CohortTrackingList:progressTracker'),
        noIcon: true,
        to: generateRoute('LEARNING.COHORT_TRACKING.PROGRESS_TRACKER'),
      }, {
        disabled: !terms?.length,
        label: i18n.t('module:Learning:CohortTracking:CohortTrackingList:comparisonReports'),
        noIcon: true,
        to: generateRoute('LEARNING.COHORT_TRACKING.COMPARISON_REPORT'),
      }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isFetching}
      title={i18n.t('module:Learning:CohortTracking:CohortTrackingList:title')}
    >
      {renderContent()}
    </Page.Section>
  )
}

export default CohortTrackingListView
