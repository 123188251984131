import React from 'react'

import { DateString } from 'components'

const DateStringRange = ({ endDate, startDate, ...rest }) => (
  <React.Fragment>
    <DateString date={startDate} {...rest} />
    {' - '}
    <DateString date={endDate} {...rest} />
  </React.Fragment>
)

export default DateStringRange
