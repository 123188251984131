import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouter } from 'services/router'

import NurseryAdd from 'module/Nurseries/NurseriesAdd'

import OrganizationDashboardView from './OrganizationDashboardView'

const GROUPS = {
  read: [
    'membership',
    'nursery.details',
    'nursery.extraInfo',
    'nursery.primaryContact',
    'nursery.statistics',
  ],
}

class OrganizationDashboardContainer extends Component {
  constructor(props) {
    super(props)

    const {
      paginationUtils: { setPageLocationQuery },
    } = props

    this.state = {
      filters: {
        search: null,
      },
    }

    setPageLocationQuery(false)
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { nurseriesActions } = this.props

    nurseriesActions.clear()
  }

  fetch = () => {
    const {
      nurseriesActions,
      nurseriesSelectors,
      paginationUtils: { page },
    } = this.props
    const { filters } = this.state

    const params = {
      criteria: nurseriesSelectors.getCriteria(filters),
      groups: GROUPS,
      page,
    }

    nurseriesActions.list({
      params,
      recurrency: 1 !== page,
    })
  }

  handleSearchChange = (search) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          search,
        },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleEditClick = (nurseryId) => {
    const { navigate } = this.props

    navigate(generateRoute('NURSERIES.EDIT', { nurseryId }))
  }

  render() {
    const {
      authAccessMap,
      isFetching,
      nurseries,
      paginationUtils,
      totalResults,
    } = this.props
    const { getPageCount, page } = paginationUtils

    const pageCount = getPageCount(totalResults)

    return (
      <OrganizationDashboardView
        authAccessMap={authAccessMap}
        isLoading={isFetching}
        nurseries={nurseries}
        page={page}
        pageCount={pageCount}
        onEditClick={this.handleEditClick}
        onPageChange={this.handlePageChange}
        onSearchChange={this.handleSearchChange}
      />
    )
  }
}

const mapState = (state, { appSelectors, nurseriesListState, nurseriesSelectors }) => ({
  authAccessMap: {
    module: {
      NurseryAdd: auth.SELECTORS.getComponentIsAuthorised(state, NurseryAdd),
    },
  },
  isFetching: appSelectors.getIsFetching(nurseriesListState),
  nurseries: nurseriesSelectors.getOrganizationNurseriesDataWithPublicUrl(state),
  totalResults: appSelectors.getTotalResults(nurseriesListState),
})

const enhance = compose(
  withAppService,
  withNurseriesService,
  withPaginationUtils,
  withRouter,
  connect(mapState),
)

export default enhance(OrganizationDashboardContainer)
