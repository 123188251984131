import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  GET_CHILD_FUNDING_SUMMARY_CALCULATOR,
  GET_CHILD_FUNDING_SUMMARY_CALCULATOR_FAILED,
  GET_CHILD_FUNDING_SUMMARY_CALCULATOR_SUCCESS,
} from './constants'

export const getFundingSummaryCalculator = (options: BasicActionOptionsProps) => ({ childApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: GET_CHILD_FUNDING_SUMMARY_CALCULATOR_FAILED,
      init: GET_CHILD_FUNDING_SUMMARY_CALCULATOR,
      success: GET_CHILD_FUNDING_SUMMARY_CALCULATOR_SUCCESS,
    },
    options,
    service: childApiClient,
    serviceMethod: 'getFundingSummaryCalculator',
  })
)
