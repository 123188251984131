import _ from 'lodash'
import { flatten } from 'utils/flatnest'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { Avatar, Currency, DateString, ReportView, Typography } from 'components'

import i18n from 'translations'

import { NURSERY_TABLE_COLUMNS, ORGANIZATION_TABLE_COLUMNS } from './constants'

export const getColumns = (isOrganizationContext) => {
  if (isOrganizationContext) {
    return ORGANIZATION_TABLE_COLUMNS
  }

  return NURSERY_TABLE_COLUMNS
}

export const getTableData = ({ data, filters, hasError, isOrganizationContext }) => {
  if (!data?.length || hasError) {
    return null
  }

  if (isOrganizationContext) {
    const queryString = new URLSearchParams(flatten({
      date: filters?.date ? {
        after: filters.date.after.format(DEFAULT_DATE_FORMAT),
        before: filters.date.before.format(DEFAULT_DATE_FORMAT),
      } : undefined,
      dateType: filters?.dateType,
    })).toString()

    return _.map(data, ({
      amount,
      count,
      resource,
    }) => ({
      amount: <Currency value={amount} />,
      count,
      name: (
        <ReportView.NurseryLink
          nursery={resource}
          queryString={queryString}
          routeKey="FINANCE.REPORT.BAD_DEBT"
        />
      ),
    }))
  }

  return _.map(data, ({ resource }) => {
    const { badDebtDate, badDebtReason, child, issueDate, number, total, totalBadDebt } = resource
    const { firstName, photo, surname } = child || {}

    return {
      badDebtAmount: <Currency value={totalBadDebt} />,
      badDebtDate: <DateString date={badDebtDate} />,
      badDebtReason: <Typography wordBreak="break-word">{badDebtReason}</Typography>,
      child: (
        <Avatar
          avatarSize="small"
          initials={[firstName, surname]}
          src={photo}
          title={`${firstName} ${surname}`}
          primary
        />
      ),
      issueDate: <DateString date={issueDate} />,
      number,
      total: <Currency value={total} />,
    }
  })
}

export const getStatistics = ({ data, hasError, isOrganizationContext }) => {
  if (!data || !isOrganizationContext || hasError) {
    return null
  }

  return [
    {
      label: i18n.t('module:Finance:Reports:BadDebt:Organization:Statistics:totalInvoices'),
      value: data.count,
    },
    {
      label: i18n.t('module:Finance:Reports:BadDebt:Organization:Statistics:totalBadDebtAmount'),
      value: <Currency value={data.amount} />,
    },
  ]
}
