import _ from 'lodash'
import moment from 'moment'
import { v4 } from 'uuid'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'

import { FEATURE_FLAGS } from 'constants/security'
import { FILE_TYPES, SUPPORTED_FILE_TYPES } from 'constants/mimetypes'
import { UPLOADED_FILE_STATUS, UPLOAD_DIRECTORY } from 'services/legacy/upload/constants'
import { OPEN_PREVIEW_PATH } from 'module/Shell/components/UploadRoot/UploadRoot'
import { noop } from 'constants/global'

import { isDocumentMimeType, isImageMimeType, isPdfMimeType } from 'utils/attachment'
import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withUploadService } from 'services/legacy/upload'
import { withSnackbarService } from 'services/utils/snackbar'
import { withRouter } from 'services/router'

import i18n from 'translations'

import { properties } from 'app-config'

import MediaPickerView from './MediaPickerView'

class MediaPicker extends Component {
  constructor(props) {
    super(props)

    let lightBoxIsOpen = false

    if (-1 < window.location?.search.indexOf(OPEN_PREVIEW_PATH)) {
      const location = _.assign({}, browserHistory.getCurrentLocation())
      const openPreview = location.query[OPEN_PREVIEW_PATH]?.split(',')

      delete location.query[OPEN_PREVIEW_PATH]
      location.search = ''

      const [referenceInstanceType, referenceInstanceId] = props.referencePage
      const [referenceType, referenceId] = openPreview

      if (referenceInstanceType === referenceType && referenceInstanceId === +referenceId) {
        lightBoxIsOpen = true

        browserHistory.push(location)
      }
    }

    this.state = {
      enabledCheckingProcessingFiles: false,
      isSyncProcessing: false,
      lightBoxCurrentImage: 0,
      lightBoxIsOpen,
    }

    this.handlerProcessingChecker = null
  }

  componentDidMount() {
    this.initCheckProcessingFiles()

    this.isUnmounted = false
  }

  componentDidUpdate() {
    const { onReloadMedia, uploadActions } = this.props
    const files = _.filter(this.getFiles(), ({ dummy, isUploaded }) => isUploaded && !dummy)

    if (files?.length) {
      _.each(files, (file) => {
        uploadActions.updateUploadFile(file.id, {
          dummy: true,
        })
      })

      onReloadMedia(files)
    }

    this.initCheckProcessingFiles()
  }

  componentWillUnmount() {
    this.isUnmounted = true

    clearInterval(this.handlerProcessingChecker)
    this.setState({ enabledCheckingProcessingFiles: false })
  }

  initCheckProcessingFiles = () => {
    const { value } = this.props
    const { enabledCheckingProcessingFiles } = this.state
    const processingFiles = _.filter(value, ({ status }) => (
      UPLOADED_FILE_STATUS.PROCESSING === status
      || UPLOADED_FILE_STATUS.UPLOADING === status
    ))

    if (processingFiles.length && !enabledCheckingProcessingFiles) {
      clearInterval(this.handlerProcessingChecker)

      this.setState({
        enabledCheckingProcessingFiles: true,
      }, this.checkProcessingFiles)
    }
  }

  checkProcessingFiles = async () => {
    const { isOffline, onCheckProcessingFiles, uploadWorker, value } = this.props
    const processingFiles = _.filter(value, ({ id, status }) => (
      UPLOADED_FILE_STATUS.PROCESSING === status
      || UPLOADED_FILE_STATUS.UPLOADING === status
    ) && id)

    if (!onCheckProcessingFiles) {
      return
    }

    if (isOffline && !this.isUnmounted) {
      this.handlerProcessingChecker = setTimeout(this.checkProcessingFiles, properties.upload.statusesDelay)

      return
    }

    const params = {
      params: {
        criteria: _.map(processingFiles, ({ id }) => ({
          field: 'id[]',
          value: id,
        })),
        groups: {
          read: ['medium.status'],
        },
      },
    }

    let statuses = {}

    uploadWorker.then(async (instance) => {
      statuses = await instance.checkProcessingFiles(JSON.parse(JSON.stringify(params)))

      const someFileIsReady = _.filter(statuses, ({ status }) => UPLOADED_FILE_STATUS.READY === status)

      if (someFileIsReady?.length) {
        const response = await onCheckProcessingFiles(someFileIsReady)

        const { value: newValue } = this.props
        const newProcessingFiles = _.filter(newValue, ({ status }) => (
          UPLOADED_FILE_STATUS.PROCESSING === status
          || UPLOADED_FILE_STATUS.UPLOADING === status
        ))

        if (!newProcessingFiles.length || null === response) {
          this.setState({
            enabledCheckingProcessingFiles: false,
          })

          clearInterval(this.handlerProcessingChecker)

          return
        }
      }

      if (!this.isUnmounted) {
        this.handlerProcessingChecker = setTimeout(this.checkProcessingFiles, properties.upload.statusesDelay)
      }
    })
  }

  handleLightBoxClose = () => {
    this.setState({ lightBoxCurrentImage: 0, lightBoxIsOpen: false })
  }

  handleLightBoxOpen = (id) => {
    const files = this.getCarouselFiles()
    const index = _.findIndex(files, ({ id: fileId }) => fileId === id)

    this.setState({ lightBoxCurrentImage: index, lightBoxIsOpen: true })
  }

  handleLightBoxClickNext = () => {
    const { lightBoxCurrentImage } = this.state
    const files = this.getCarouselFiles()

    if (files[lightBoxCurrentImage + 1]) {
      this.setState({ lightBoxCurrentImage: lightBoxCurrentImage + 1 })
    }
  }

  handleLightBoxClickPrev = () => {
    const { lightBoxCurrentImage } = this.state
    const files = this.getCarouselFiles()

    if (files[lightBoxCurrentImage - 1]) {
      this.setState({ lightBoxCurrentImage: lightBoxCurrentImage - 1 })
    }
  }

  handleFilesAdded = async (e) => {
    const {
      accept,
      filesLimit,
      noCloudUpload,
      onChange,
      referencePage,
      snackbarActions,
      sync,
      totalResults,
      uploadActions,
      value,
    } = this.props
    const files = []
    const incorrectFiles = []
    const acceptFileTypes = _.flattenDeep(accept || [SUPPORTED_FILE_TYPES.IMAGES, SUPPORTED_FILE_TYPES.VIDEO])

    let targetFiles = _.filter(e.target.files, (item) => {
      if (!item.size) {
        incorrectFiles.push(item)

        return false
      }

      return (
        !!_.find(acceptFileTypes, ({ mimeTypes }) => {
          if (_.isArray(mimeTypes)) {
            return _.find(mimeTypes, (subMimeType) => subMimeType === item.type)
          }

          return mimeTypes === item.type
        })
      )
    })

    const uploadingFiles = _.filter(this.getFiles(), ({ dummy }) => !dummy)
    // eslint-disable-next-line no-unsafe-optional-chaining
    const availableNumberOfFiles = filesLimit - totalResults - uploadingFiles?.length

    if (availableNumberOfFiles < targetFiles.length) {
      snackbarActions.show({
        message: i18n.t('components:MediaPicker:exceededMaxFiles', {
          maxOfFiles: filesLimit,
        }),
      })

      targetFiles = targetFiles.slice(0, availableNumberOfFiles)
    }

    if (incorrectFiles?.length) {
      snackbarActions.show({
        message: i18n.t('components:MediaPicker:invalidFilesHasBeenSkipped', {
          fileName: _.map(incorrectFiles, ({ name }) => name).join(', '),
        }),
      })
    }

    if (!targetFiles.length) {
      return false
    }

    if (sync) {
      this.setState({ isSyncProcessing: true })
    }

    // eslint-disable-next-line consistent-return
    await Promise.all(_.map(targetFiles, async (item) => {
      const file = item
      let mimeType = item.type || item.mimeType

      // NOTE: windows workaround. On Windows the CSV file is 'application/vnd.ms-excel' and if we upload such file
      // user will receive the XLS instead of CSV.
      if (_.endsWith(item.name, FILE_TYPES.CSV.extension)) {
        mimeType = FILE_TYPES.CSV.mimeTypes[0]
      }

      file.mimeType = mimeType

      let filename = item.name.split('.')
      filename.splice(-1, 1)
      filename = filename.join('.')
      filename = filename.replace(/\./g, '-').replace(/\s/g, '-')

      if (isImageMimeType(mimeType)) {
        file.url = URL.createObjectURL(file)
      }

      if (noCloudUpload) {
        return files.push({
          createdAt: moment(),
          file,
          id: v4(),
          itemFromBe: false,
          mimeType,
          name: filename,
          size: item.size,
          thumbnail: null,
        })
      }

      if (sync) {
        if (this.isUnmounted) {
          return null
        }

        // NOTE: the cloud director is needed only for upload/s3 V1 endpoints. The V2 has directory auto-detection
        // and decide on its own which directory upload the file. The V1 endpoint is used only for newsletter
        // accident/incident, avatars that is why there is no need to support video directory
        const directory = isDocumentMimeType(mimeType) ? UPLOAD_DIRECTORY.DOCUMENTS : UPLOAD_DIRECTORY.IMAGES
        const url = await uploadActions.uploadFile(file, filename, directory)

        files.push({
          createdAt: moment(),
          id: v4(),
          itemFromBe: false,
          mimeType,
          name: filename,
          size: item.size,
          thumbnail: null,
          url,
        })
      } else {
        uploadActions.addFile({
          file,
          id: v4(),
          mimeType,
          name: filename,
          referencePage,
          size: item.size,
          thumbnail: null,
        })
      }
    }))

    if (sync) {
      this.setState({ isSyncProcessing: false })

      onChange([
        ...(value || []),
        ...(files || []),
      ])
    }

    if (this.isUnmounted) {
      return null
    }

    return this.showMediaTagModal(files)
  }

  getFiles = () => {
    const { getFiles, referencePage } = this.props

    if (!referencePage) {
      return []
    }

    return getFiles(referencePage)
  }

  getCarouselFiles = () => {
    const { sorted, sync, uploadSelectors, value } = this.props

    const uploadedFiles = _.filter(value, ({ status }) => (
      UPLOADED_FILE_STATUS.UPLOADING !== status
      && UPLOADED_FILE_STATUS.PROCESSING !== status
      && UPLOADED_FILE_STATUS.FAILED !== status
    ))

    const files = _.filter([
      ..._.map(uploadedFiles, (file) => uploadSelectors.normalizeUploadedFileData(file, sync)),
    ], ({ mimeType }) => !isPdfMimeType(mimeType) && !isDocumentMimeType(mimeType))

    if (sorted) {
      return files
    }

    return _.sortBy(files, ({ createdAt }) => moment(createdAt).format('x')).reverse()
  }

  getTableFiles = () => {
    const { sorted, sync, uploadSelectors, value } = this.props

    const uploadingFiles = _.filter(this.getFiles(), ({ dummy }) => !dummy)
    const uploadedFiles = _.filter(value, (item) => (
      !_.find(uploadingFiles, ({ beId, id }) => (
        (beId === item.id || id === item.id)
      ))
    ))

    const finalUploadingFiles = uploadingFiles || []
    const finalUploadedFiles = _.map(
      uploadedFiles,
      (file) => uploadSelectors.normalizeUploadedFileData(file, sync),
    )

    let result = _.orderBy([
      ...finalUploadingFiles,
      ...finalUploadedFiles,
    ], ({ createdAt }) => moment(createdAt).format('x'))

    result = _.uniqBy(result, ({ id }) => id)

    if ('ASC' === sorted?.sortOrder) {
      return result
    }

    return result.reverse()
  }

  showMediaTagSuccess = ({ file: updatedFile, name, selectedChildren } = {}, updatedFiles) => {
    const { disableMediaTag, onChange, sorted, sync, value } = this.props

    if (!sync || (sync && disableMediaTag)) {
      return false
    }

    const files = _.uniqBy([
      ..._.map(updatedFiles, (file) => {
        const newFile = { ...file }

        if (file.id === updatedFile?.id) {
          newFile.name = name
          newFile.children = selectedChildren
        }

        return newFile
      }),
      ...(value || []),
    ], ({ id }) => id)

    const newValue = sorted ? files : _.sortBy(files, ({ createdAt }) => moment(createdAt).format('x'))

    return onChange(newValue)
  }

  showMediaTagModal = (files) => {
    const { childrenList, disableMediaTag, disableTagging, modalActions, modalConsts, sync, uploadActions } = this.props
    const notInitializedFiles = _.filter(this.getFiles(), ({ initialization: { onFe } }) => !onFe)

    if (disableMediaTag) {
      _.each(notInitializedFiles, (file) => {
        uploadActions.updateUploadFile(file.id, {
          initialization: {
            onFe: true,
          },
        })
      })

      return this.showMediaTagSuccess({}, files)
    }

    return modalActions.show(modalConsts.TYPES.MEDIA_TAG, {
      childrenList,
      disableTagging,
      files: sync ? files : _.filter(this.getFiles(), ({ initialization: { onFe } }) => !onFe),
      onRequestClose: (next) => {
        _.each(notInitializedFiles, (file) => {
          uploadActions.updateUploadFile(file.id, {
            initialization: {
              onFe: true,
            },
          })
        })

        next()
      },
      onSuccess: this.showMediaTagSuccess,
      sync,
      zIndex: 950,
    })
  }

  handleRemoveFileAccepted = (file) => {
    const { onChange, onRemoveFile, sync, uploadActions, value } = this.props
    const { id, itemFromBe } = file

    if (!itemFromBe) {
      uploadActions.markFileToRemove(id)
    }

    if (sync) {
      onChange(_.filter(value, ({ id: fileId }) => fileId !== id))

      if (onRemoveFile) {
        onRemoveFile()
      }

      return
    }

    onRemoveFile(file)
  }

  handleRemoveFile = (file) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('global:Delete'),
      icon: 'trash',
      onConfirm: () => this.handleRemoveFileAccepted(file),
      text: i18n.t('components:MediaPicker:deleteFile'),
    })
  }

  handleEditFileSuccess = ({ file: updatedFile, name, selectedChildren }, onSuccess) => {
    const { onChange, onUpdateMedia, sync, value } = this.props
    let currentItem = null

    const updatedValue = _.map(value, (item) => {
      const updatedItem = { ...item }

      if (item.id === updatedFile.id) {
        updatedItem.children = selectedChildren
        updatedItem.name = name

        currentItem = { ...updatedItem }
      }

      return updatedItem
    })

    if (sync) {
      onChange(updatedValue)
    }

    if (!sync && onUpdateMedia) {
      onUpdateMedia({
        ...updatedFile,
        children: selectedChildren,
        name,
      })
    }

    if (onSuccess) {
      onSuccess(currentItem)
    }
  }

  handleEditFile = (file, higherZIndex, onSuccess = noop) => {
    const { childrenList, disableTagging, modalActions, modalConsts, sync } = this.props

    modalActions.show(modalConsts.TYPES.MEDIA_TAG, {
      childrenList,
      disableTagging,
      files: [file],
      onSuccess: (response) => this.handleEditFileSuccess(response, onSuccess),
      sync,
      zIndex: higherZIndex && 1200,
    })
  }

  handleDownloadFileSuccess = (fileId) => {
    const { value } = this.props
    const file = _.find(value, ({ id }) => id === fileId)

    const link = document.createElement('a')
    link.setAttribute('target', '_blank')
    link.setAttribute('download', file.name)
    link.href = file.url
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  handleDownloadFile = (file) => {
    const { modalActions, modalConsts } = this.props
    const { id, mimeType, type } = file
    const isPdf = isPdfMimeType(mimeType || type)

    if (isPdf) {
      return this.handleDownloadFileSuccess(id)
    }

    return modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('global:Download'),
      onConfirm: () => this.handleDownloadFileSuccess(id),
      text: (
        <React.Fragment>
          {i18n.t('components:MediaPicker:downloadFile_first')}
          <br />
          {i18n.t('components:MediaPicker:downloadFile_second')}
        </React.Fragment>
      ),
    })
  }

  render() {
    const {
      accept,
      authAccessMap,
      buttonLabel,
      buttonSecondary,
      childrenList,
      disableSticky,
      disableTagging,
      disabled,
      editMode,
      emptyState,
      error,
      filesLimit,
      hideButtonWhenFileLimitExceeded,
      hideEditOption,
      hideStatistics,
      hideSupportedFileTypes,
      isFetching,
      isOffline,
      minWidth,
      noCloudUpload,
      onDescriptionChange,
      onParamsChange,
      onSortChange,
      pagination,
      preview,
      sectionMode,
      sorted,
      sync,
      tiledMode,
      totalResults,
      uploadDateColumn,
    } = this.props
    const { isSyncProcessing, lightBoxCurrentImage, lightBoxIsOpen } = this.state
    const { sortField, sortOrder } = sorted || {}
    const uploadingFiles = _.filter(this.getFiles(), ({ dummy }) => !dummy)
    // eslint-disable-next-line no-unsafe-optional-chaining
    const finalTotalResults = totalResults + uploadingFiles?.length

    return (
      <MediaPickerView
        accept={accept}
        authAccessMap={authAccessMap}
        buttonLabel={buttonLabel}
        buttonSecondary={buttonSecondary}
        carouselFiles={this.getCarouselFiles()}
        childrenList={childrenList}
        disableSticky={disableSticky}
        disableTagging={disableTagging}
        disabled={disabled}
        editMode={editMode}
        emptyState={emptyState}
        error={error}
        filesLimit={filesLimit}
        hideButtonWhenFileLimitExceeded={hideButtonWhenFileLimitExceeded}
        hideEditOption={hideEditOption}
        hideStatistics={hideStatistics}
        hideSupportedFileTypes={hideSupportedFileTypes}
        isFetching={isFetching}
        isOffline={isOffline}
        isSyncProcessing={sync && isSyncProcessing}
        lightBoxCurrentImage={lightBoxCurrentImage}
        lightBoxIsOpen={lightBoxIsOpen}
        minWidth={minWidth}
        noCloudUpload={noCloudUpload}
        pagination={pagination}
        preview={preview}
        sectionMode={sectionMode}
        sortField={sortField}
        sortOrder={sortOrder}
        tableFiles={this.getTableFiles()}
        tiledMode={tiledMode}
        totalResults={finalTotalResults}
        uploadDateColumn={uploadDateColumn}
        onDescriptionChange={onDescriptionChange}
        onDownloadFile={this.handleDownloadFile}
        onEditFile={this.handleEditFile}
        onFilesAdded={this.handleFilesAdded}
        onLightBoxClickNext={this.handleLightBoxClickNext}
        onLightBoxClickPrev={this.handleLightBoxClickPrev}
        onLightBoxClose={this.handleLightBoxClose}
        onLightBoxOpen={this.handleLightBoxOpen}
        onParamsChange={onParamsChange}
        onRemoveFile={this.handleRemoveFile}
        onSortChange={onSortChange}
      />
    )
  }
}

const mapState = (state, { appSelectors, uploadSelectors }) => ({
  authAccessMap: {
    section: {
      VideoEnabled: auth.SELECTORS.getIsAuthorised(state, {
        flags: [FEATURE_FLAGS.VIDEO_ENABLED],
      }),
    },
  },
  getFiles: (reference) => uploadSelectors.getFilesByReference(reference)(state),
  isOffline: appSelectors.getAppIsOffline(state),
})

const enhance = compose(
  withAppService,
  withModalService,
  withSnackbarService,
  withRouter,
  withUploadService,
  connect(mapState),
)

export default enhance(MediaPicker)
