import { SubmissionError } from 'redux-form'

export const GET_TERM = 'GET_TERM'
export const GET_TERM_SUCCESS = 'GET_TERM_SUCCESS'
export const GET_TERM_FAILED = 'GET_TERM_FAILED'

export const CREATE_TERM = 'CREATE_TERM'
export const CREATE_TERM_SUCCESS = 'CREATE_TERM_SUCCESS'
export const CREATE_TERM_FAILED = 'CREATE_TERM_FAILED'

export const UPDATE_TERM = 'UPDATE_TERM'
export const UPDATE_TERM_SUCCESS = 'UPDATE_TERM_SUCCESS'
export const UPDATE_TERM_FAILED = 'UPDATE_TERM_FAILED'

export const DELETE_TERM = 'DELETE_TERM'
export const DELETE_TERM_SUCCESS = 'DELETE_TERM_SUCCESS'
export const DELETE_TERM_FAILED = 'DELETE_TERM_FAILED'

export const CLEAR_TERM = 'CLEAR_TERM'

export const getTerm = (nurseryId, params) => ({ organizationTermsApiClient }) => (dispatch) => {
  dispatch({
    nurseryId,
    params,
    type: GET_TERM,
  })

  organizationTermsApiClient.list(nurseryId, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: GET_TERM_SUCCESS,
      })
    },
    (error) => {
      dispatch({
        error: error.response,
        type: GET_TERM_FAILED,
      })
    },
  )
}

export const updateTerm = (nurseryId, id, payload, onSuccess) => ({ organizationTermsApiClient }) => (dispatch) => {
  dispatch({
    id,
    nurseryId,
    payload,
    type: UPDATE_TERM,
  })

  organizationTermsApiClient.update(nurseryId, id, payload).then(
    (data) => {
      dispatch({
        payload: data,
        type: UPDATE_TERM_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: UPDATE_TERM_FAILED,
      })

      throw new SubmissionError({
        ...error.response.extra,
        _error: error.response.message,
      })
    },
  )
}

export const deleteTerm = (nurseryId, id, onSuccess) => ({ organizationTermsApiClient }) => (dispatch) => {
  dispatch({
    id,
    nurseryId,
    type: DELETE_TERM,
  })

  organizationTermsApiClient.delete(nurseryId, id).then(
    (data) => {
      dispatch({
        payload: data,
        type: DELETE_TERM_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: DELETE_TERM_FAILED,
      })
    },
  )
}

export const clearTerm = () => ({
  type: CLEAR_TERM,
})
