import { useEffect, useRef, useState } from 'react'

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    height: undefined,
    width: undefined,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }
    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

export const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

export const useI18nKey = (prefix) => {
  const generateI18nKey = (suffix) => `${prefix}:${suffix}`

  return { generateI18nKey }
}
