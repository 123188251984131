import ServiceBase from 'services/ServiceBase'

import { getQueryString } from 'utils/api'

class AuthenticationLogsApiClient extends ServiceBase {
  list = (params) => this.request({
    path: `/authentication-logs?${getQueryString(params)}`,
  })
}

export default AuthenticationLogsApiClient
