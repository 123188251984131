import _ from 'lodash'

import React from 'react'

import { FEATURE_FLAGS_OPTIONS } from 'services/legacy/featureFlags/constants'

import { DetailsBreakdown, Typography } from 'components'

import i18n from 'translations'

const FeatureFlagsSection = ({ featureFlags, isOrganizationContext }) => {
  let featureFlagsFinal = FEATURE_FLAGS_OPTIONS

  if (isOrganizationContext) {
    featureFlagsFinal = _.filter(FEATURE_FLAGS_OPTIONS, ({ hideForOrganization }) => !hideForOrganization)
  }

  const renderFeatureFlagsFields = () => _.map(featureFlagsFinal, (flag) => (
    <DetailsBreakdown.Item
      label={flag.label}
      value={_.find(featureFlags, ['flag', flag.value])?.enabled
        ? i18n.t('global:On')
        : i18n.t('global:Off')}
    />
  ))

  return (
    <DetailsBreakdown.Section>
      <DetailsBreakdown.Header>
        <Typography fontSize={20}>
          {_.upperFirst(i18n.t('global:featureFlags'))}
        </Typography>
      </DetailsBreakdown.Header>
      {renderFeatureFlagsFields()}
    </DetailsBreakdown.Section>
  )
}

export default FeatureFlagsSection
