import React, { Component } from 'react'
import { compose } from 'recompose'

import { withShellService } from 'services/shell'
import { withEnquiryStatusTypesService } from 'services/legacy/enquiries'

import DetailView from './DetailView'

class DetailContainer extends Component {
  componentDidMount() {
    const { shellActions } = this.props

    shellActions.hideSubMenu()
  }

  componentWillUnmount() {
    const { enquiryStatusTypesActions, shellActions } = this.props

    enquiryStatusTypesActions.clearDropdownList()
    shellActions.showSubMenu()
  }

  render() {
    return (
      <DetailView />
    )
  }
}

const enhance = compose(
  withShellService,
  withEnquiryStatusTypesService,
)

export default enhance(DetailContainer)

