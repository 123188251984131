import _ from 'lodash'
import { createSelector } from 'reselect'

const getDailyDiaryBottles = (state) => state.dailyDiaryBottles

export const getDailyDiaryBottlesListWithoutRecords = createSelector(
  [getDailyDiaryBottles],
  (state) => state.listWithoutRecords,
)

export const getDailyDiaryBottlesListWithoutRecordsData = createSelector(
  [getDailyDiaryBottlesListWithoutRecords],
  (state) => state.data,
)

export const getDailyDiaryBottlesListWithoutRecordsMeta = createSelector(
  [getDailyDiaryBottlesListWithoutRecords],
  (state) => state.meta,
)

export const getChildrenDailyDiaryBottlesListWithoutRecordsData = createSelector(
  [getDailyDiaryBottlesListWithoutRecordsData],
  (items) => _.map(items, ({ childRegister: { child } }) => child),
)

export const hasDailyDiaryBottlesListWithoutRecordsData = createSelector(
  [getDailyDiaryBottlesListWithoutRecordsMeta],
  (meta) => meta?.total_results > 0, // eslint-disable-line
)
