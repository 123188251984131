import { generateBasicActions } from 'utils/service'

import {
  CLEAR_REQUESTED_EXTRA_SESSIONS,
  LIST_REQUESTED_EXTRA_SESSIONS,
  LIST_REQUESTED_EXTRA_SESSIONS_FAILED,
  LIST_REQUESTED_EXTRA_SESSIONS_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ requestedExtraSessionsApiClient }) => generateBasicActions.list({
  constants: {
    failed: LIST_REQUESTED_EXTRA_SESSIONS_FAILED,
    init: LIST_REQUESTED_EXTRA_SESSIONS,
    success: LIST_REQUESTED_EXTRA_SESSIONS_SUCCESS,
  },
  options,
  service: requestedExtraSessionsApiClient,
  serviceMethod: 'list',
})

export const clearList = () => ({
  type: CLEAR_REQUESTED_EXTRA_SESSIONS,
})
