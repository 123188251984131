import _ from 'lodash'
import jwtDecode from 'jwt-decode'
import moment from 'moment'

import React from 'react'

import colors from 'constants/colors'
import { AUTH_TYPE_DIRECT } from 'services/device/constants'

import { generateRoute } from 'utils/routing'
import { getBrandingColor } from 'utils/branding'

import {
  Avatar,
  Button,
  DateString,
  EmptyState,
  Icon,
  InfiniteScroll,
  Page,
  Pagination,
  SearchBar,
  Section,
  Spinner,
  Table,
  Typography,
} from 'components'

import i18n from 'translations'

import { StyledAuthorisedDevices } from './AuthorisedDevicesListStyled'

export const DEVICE_TOKENS_COLUMNS = [
  {
    align: 'left',
    field: 'deviceName',
    title: i18n.t('module:Management:Security:AuthorisedDevices:AuthorisedDevicesList:deviceName'),
  },
  {
    align: 'left',
    field: 'dateAuthorised',
    title: i18n.t('module:Management:Security:AuthorisedDevices:AuthorisedDevicesList:dateAuthorised'),
  },
  {
    align: 'left',
    field: 'authorisedBy',
    title: i18n.t('module:Management:Security:AuthorisedDevices:AuthorisedDevicesList:authorisedBy'),
  },
  {
    align: 'left',
    field: 'authorisationMethod',
    title: i18n.t('module:Management:Security:AuthorisedDevices:AuthorisedDevicesList:authorisationMethod'),
  },
  {
    field: 'edit',
  },
]

const AuthorisedDevicesListView = ({
  deviceIdentity,
  deviceTokens,
  isFetching,
  onPageChange,
  onSearchChange,
  page,
  pageCount,
  totalResults,
}) => {
  const renderAuthorisationMethod = (item) => {
    const { method, pin = {} } = item

    if (method === AUTH_TYPE_DIRECT) {
      return i18n.t('module:Management:Security:AuthorisedDevices:AuthorisedDevicesList:selfAuthorised')
    }

    if (moment().unix() > moment(pin.expiresAt).unix()) {
      return (
        <StyledAuthorisedDevices
          color={colors.remove}
        >
          <Icon
            color={colors.remove}
            height={22}
            icon="exclamation-mark"
          />
          {i18n.t('module:Management:Security:AuthorisedDevices:AuthorisedDevicesList:pinExpired')}
        </StyledAuthorisedDevices>
      )
    }

    if (pin.usedAt) {
      return (
        <StyledAuthorisedDevices
          color={getBrandingColor('primary-color')}
        >
          <Icon
            height={22}
            icon="check"
          />
          {i18n.t('module:Management:Security:AuthorisedDevices:AuthorisedDevicesList:pinAuthorised')}
        </StyledAuthorisedDevices>
      )
    }

    if (!pin.usedAt) {
      return `${i18n.t('module:Management:Security:AuthorisedDevices:AuthorisedDevicesList:validPin')}: ${pin.digits}`
    }

    return null
  }

  const renderItem = (item) => {
    const { author = {}, id, isDisabled, createdAt, name, token } = item
    const deviceTokenDet = deviceIdentity && jwtDecode(deviceIdentity)
    const awarded = token === deviceIdentity

    return ({
      authorisationMethod: (
        <Typography
          bold={awarded}
          variant="span"
        >
          {renderAuthorisationMethod(item)}
        </Typography>
      ),
      authorisedBy: (
        <Avatar
          avatarSize="medium"
          initials={author.initials && author.initials.split('')}
          src={author.photo}
          title={(
            <Typography
              bold={awarded}
              variant="span"
            >
              {author.name}
            </Typography>
          )}
        />
      ),
      dateAuthorised: (
        <Typography
          bold={awarded}
          variant="span"
        >
          <DateString date={createdAt} relative />
        </Typography>
      ),
      deviceName: (
        <Typography
          bold={awarded}
          variant="span"
        >
          {token === (deviceTokenDet && deviceTokenDet.tkn)
            ? `${name} (${i18n.t('module:Management:Security:AuthorisedDevices:AuthorisedDevicesList:thisDevice')})`
            : name}
        </Typography>
      ),
      edit: (
        <Button.TableAction
          to={generateRoute('MANAGEMENT.SECURITY.DEVICES.EDIT', { deviceId: id })}
          edit
        />
      ),
      inactive: isDisabled,
      key: id,
    })
  }

  const renderContent = () => {
    if (isFetching && 1 === page) {
      return (
        <Spinner />
      )
    }

    if (!isFetching && (deviceTokens && !deviceTokens.length)) {
      return (
        <EmptyState
          icon="authorised-devices"
          text1={i18n.t('module:Management:Security:AuthorisedDevices:AuthorisedDevicesList:nothingFound')}
        />
      )
    }

    return (
      <React.Fragment>
        <Pagination
          titlePlural={i18n.t('module:Management:Security:AuthorisedDevices:AuthorisedDevicesList:devices')}
          titleSingular={i18n.t('module:Management:Security:AuthorisedDevices:AuthorisedDevicesList:device')}
          totalResults={totalResults}
        />
        <InfiniteScroll
          dataLength={deviceTokens ? deviceTokens.length : 0}
          hasMore={page < pageCount}
          next={() => onPageChange((+page) + 1)}
        >
          <Table
            columns={DEVICE_TOKENS_COLUMNS}
            data={_.map(deviceTokens, renderItem)}
          />
        </InfiniteScroll>
      </React.Fragment>
    )
  }

  const actions = <Section.Actions primary={[{ to: generateRoute('MANAGEMENT.SECURITY.DEVICES.ADD') }]} />

  return (
    <Page.Section
      actions={actions}
      isLoading={isFetching && 1 === page}
      title={i18n.t('module:Management:Security:AuthorisedDevices:AuthorisedDevicesList:title')}
    >
      <SearchBar
        placeholder={i18n.t('module:Management:Security:AuthorisedDevices:AuthorisedDevicesList:searchDevice')}
        variant="standard"
        noBackground
        onChange={onSearchChange}
      />
      {renderContent()}
    </Page.Section>
  )
}

export default AuthorisedDevicesListView
