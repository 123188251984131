import React from 'react'

import { InfiniteScroll, Pagination, Table } from 'components'
import { GROUP_TABLE_COLUMNS, TABLE_COLUMNS } from './constants'

const AgedDebtReportTable = ({
  data,
  isOrganizationContext,
  noDataText,
  onPageChange,
  onSortChange,
  page,
  pageCount,
  sortField,
  sortOrder,
  totalResults,
}) => (
  <React.Fragment>
    <Pagination
      totalResults={totalResults}
    />
    <InfiniteScroll
      dataLength={data ? data.length : 0}
      hasMore={page < pageCount}
      next={() => onPageChange((+page) + 1)}
    >
      <Table
        columns={isOrganizationContext ? GROUP_TABLE_COLUMNS : TABLE_COLUMNS}
        data={data}
        noDataText={noDataText}
        sortField={sortField}
        sortOrder={sortOrder}
        sticky
        onSortChange={onSortChange}
      />
    </InfiniteScroll>
  </React.Fragment>
)

export default AgedDebtReportTable
