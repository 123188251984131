import { compose } from 'recompose'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { ROLES } from 'constants/security'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import { withShellService } from 'services/shell'
import { withRouter } from 'services/router'

import ApprovalsWrapperView from './ApprovalsWrapperView'
import InjuryApprovals from './InjuryApprovals'

class ApprovalsWrapperContainer extends Component {
  componentDidMount() {
    const { authAccessMap, shellActions } = this.props

    shellActions.setSubMenu([
      {
        icon: 'observations',
        label: 'Observations',
        to: '/approvals/observations',
      },
      {
        icon: 'formative-reports',
        label: 'Formative Reports',
        to: '/approvals/formative-reports',
      },
      {
        hidden: !authAccessMap.module.InjuryApprovals,
        icon: 'injury-records',
        label: 'Accidents & Incidents',
        to: '/approvals/injury',
      },
    ])
  }

  shouldComponentUpdate(nextProps) {
    const { location: { pathname }, navigate } = nextProps

    if (generateRoute('APPROVALS.INDEX') === pathname) {
      navigate(generateRoute('APPROVALS.OBSERVATIONS'))
    }

    return true
  }

  componentWillUnmount() {
    const { shellActions } = this.props

    shellActions.clearSubMenu()
  }

  render() {
    const { children } = this.props

    return (
      <ApprovalsWrapperView>
        {children}
      </ApprovalsWrapperView>
    )
  }
}

const mapState = (state) => ({
  authAccessMap: {
    module: {
      InjuryApprovals: auth.SELECTORS.getComponentIsAuthorised(state, InjuryApprovals),
    },
  },
})

ApprovalsWrapperContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.ROOM_LEADER,
  ],
}

const enhance = compose(
  withRouter,
  withShellService,
  connect(mapState),
)

export default enhance(ApprovalsWrapperContainer)
