import styled from 'styled-components'

export const StyledType = styled.div`
  display: grid;
  grid-template-columns: 24px auto;
  grid-column-gap: 5px;
  align-items: center;
`

export const StyledDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;

  ${({ backgroundColor }) => `
    background-color: ${backgroundColor};
  `}
`

export const StyledTooltipContent = styled.div`
  display: grid;
  grid-row-gap: 2px;
`

export const StyledTooltipContentRow = styled.div`
  display: grid;
  grid-template-columns: 130px auto;
  grid-column-gap: 10px;
`
