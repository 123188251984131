import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { stopSubmit } from 'redux-form'

import { COMPLETED_STATE, FRAMEWORK_PROGRESS_LEVELS_TYPES } from 'services/legacy/frameworkProgressLevels/constants'
import { NONE_VALUE } from 'services/legacy/frameworks/constants'

import { getBackendErrors } from 'utils/backendErrors'
import { generateRoute } from 'utils/routing'

import { withModalService } from 'services/utils/modal'
import { withAppService } from 'services/app'
import { withChildFrameworkProgressService } from 'services/legacy/childFrameworkProgress'
import { withFrameworkProgressLevelsService } from 'services/legacy/frameworkProgressLevels'
import { withRouter } from 'services/router'

import { Space } from 'components'

import i18n from 'translations'

import FrameworkSelectProgressModalView from './FrameworkSelectProgressModalView'
import {
  FRAMEWORK_SELECT_PROGRESS_FORM,
} from './components/FrameworkSelectProgressModalForm/FrameworkSelectProgressModalForm'

const CHILD_FRAMEWORK_PROGRESS_GROUPS = {
  read: [
    'childFrameworkItemEvent',
    'childFrameworkItemEvent.progressLevel',
    'childFrameworkItemEvent.frameworkItem',
    'frameworkItem.linkedItems',
    'frameworkProgressLevel',
  ],
}

const PROGRESS_HISTORY_GROUPS = {
  read: [
    'user',
    'user.details',
    'childFrameworkItemEvent.child',
    'childFrameworkItemEvent.frameworkItem',
    'childFrameworkItemEvent.observation',
    'childFrameworkItemEvent.author',
    'childFrameworkItemEvent.progressLevel',
    'observation',
    'observation.author',
    'childObservation',
    'childObservation.child',
    'childObservation.observationItems',
    'childObservation.nextMontessoriActivities',
    'childObservation.nextSteps',
    'frameworkProgressLevel',
    'observation.childObservations',
    'observationItem.frameworkItem',
    'observationItem',
    'montessoriActivity',
    'nextMontessoriActivity',
    'nextMontessoriActivity.montessoriActivity',
    'nextStep',
    'nextStep.frameworkAreas',
    'frameworkArea',
  ],
}

const PROGRESS_HISTORY_MONTESSORI_GROUPS = {
  read: [
    'user',
    'user.details',
    'childMontessoriActivityEvent.child',
    'childMontessoriActivityEvent.frameworkItem',
    'childMontessoriActivityEvent.observation',
    'childMontessoriActivityEvent.nextMontessoriActivities',
    'childMontessoriActivityEvent.author',
    'frameworkProgressLevel',
    'observation',
    'observation.author',
  ],
}

const CHILD_FRAMEWORK_MONTESSORI_PROGRESS_GROUPS = {
  read: [
    'childMontessoriActivityEvent',
    'childMontessoriActivityEvent.montessoriActivity',
  ],
}

class FrameworkSelectProgressModalContainer extends Component {
  componentDidMount() {
    const {
      childFrameworkProgressActions,
      childId,
      framework,
      frameworkProgressLevelsActions,
      isFrameworkMontessori,
      itemId,
    } = this.props

    const criteria = [
      {
        field: 'child',
        value: childId,
      },
      {
        field: isFrameworkMontessori ? 'montessoriActivity' : 'frameworkItem',
        value: itemId,
      },
    ]

    if (!isFrameworkMontessori) {
      const progressLevelsCriteria = [
        {
          field: 'framework',
          value: framework,
        },
        {
          field: 'type',
          value: FRAMEWORK_PROGRESS_LEVELS_TYPES.FRAMEWORK_PAGE,
        },
      ]

      frameworkProgressLevelsActions.list({
        params: {
          criteria: progressLevelsCriteria,
        },
        recursively: true,
      })
    }

    if (isFrameworkMontessori) {
      return childFrameworkProgressActions.getProgressHistoryMontessori({
        params: [{
          criteria,
          groups: PROGRESS_HISTORY_MONTESSORI_GROUPS,
        }],
      })
    }

    return childFrameworkProgressActions.getProgressHistory({
      params: [{
        criteria,
        groups: PROGRESS_HISTORY_GROUPS,
      }],
    })
  }

  componentWillUnmount() {
    const { childFrameworkProgressActions, frameworkProgressLevelsActions } = this.props

    childFrameworkProgressActions.clearProgressHistory()
    childFrameworkProgressActions.clearProgressHistoryMontessori()
    frameworkProgressLevelsActions.clear()
  }

  handleRemoveSuccess = () => {
    const { onRemoveSuccess } = this.props

    this.handleCloseClick()
    onRemoveSuccess()
  }

  handleRemoveAccepted = (item) => {
    const { childFrameworkProgressActions, isFrameworkMontessori } = this.props

    if (isFrameworkMontessori) {
      return childFrameworkProgressActions.removeEventMontessori({
        onSuccess: this.handleRemoveSuccess,
        params: [item.id],
      })
    }

    return childFrameworkProgressActions.removeEvent({
      onSuccess: this.handleRemoveSuccess,
      params: [item.id],
    })
  }

  handleRemove = (item) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('global:Delete'),
      icon: 'trash',
      onConfirm: () => this.handleRemoveAccepted(item),
      text: (
        <React.Fragment>
          {i18n.t('module:Modals:FrameworkSelectProgressModal:deleteModalContent')}
          <Space space="10px" />
          {i18n.t('module:Modals:FrameworkSelectProgressModal:deleteModalContent2')}
        </React.Fragment>
      ),
    })
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleViewConfirmed = (observationId) => {
    const { navigate } = this.props
    this.handleCloseClick()

    navigate(generateRoute('LEARNING.OBSERVATIONS.REVIEW', {
      observationId,
    }))
  }

  handleView = (observationId) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      cancelButtonLabel: i18n.t('module:Modals:FrameworkSelectProgressModal:stayOnPage'),
      confirmButtonLabel: i18n.t('global:Continue'),
      icon: 'warning',
      onConfirm: () => this.handleViewConfirmed(observationId),
      text: i18n.t('module:Modals:FrameworkSelectProgressModal:viewModalContent'),
    })
  }

  handleSubmitSuccess = (response) => {
    const { injectValidation, onSuccess } = this.props
    const error = _.find(response.data, (i) => i.exception?.error)

    if (error) {
      const errors = getBackendErrors(error?.exception?.error)

      return injectValidation(FRAMEWORK_SELECT_PROGRESS_FORM, errors)
    }

    this.handleCloseClick()

    return onSuccess()
  }

  handleSubmit = (values) => {
    const { childFrameworkProgressActions, childId, isFrameworkMontessori, itemId, progressLevels } = this.props
    const { date, state } = values
    let finalState = state

    if (!finalState) {
      finalState = _.get(
        _.find(progressLevels, (item) => item.default),
        'id',
      )
    }

    const body = [
      {
        child: {
          id: +childId,
        },
        date: moment(date).format(),
        [isFrameworkMontessori ? 'montessoriActivity' : 'frameworkItem']: {
          id: itemId,
        },
      },
    ]

    if (isFrameworkMontessori) {
      body[0] = {
        ...body[0],
        state: finalState || NONE_VALUE,
      }

      return childFrameworkProgressActions.batchMontessoriUpdate({
        body,
        onSuccess: this.handleSubmitSuccess,
        params: [{
          groups: CHILD_FRAMEWORK_MONTESSORI_PROGRESS_GROUPS,
        }],
      })
    }

    body[0] = {
      ...body[0],
      progressLevel: {
        id: finalState,
        type: FRAMEWORK_PROGRESS_LEVELS_TYPES.FRAMEWORK_PAGE,
      },
    }

    return childFrameworkProgressActions.batchUpdate({
      body,
      onSuccess: this.handleSubmitSuccess,
      params: [{
        groups: CHILD_FRAMEWORK_PROGRESS_GROUPS,
      }],
    })
  }

  render() {
    const {
      childFrameworkMontessoriProgress,
      childFrameworkProgress,
      childId,
      description,
      isFetching,
      isFetchingProgressHistory,
      isFrameworkMontessori,
      isSubmitting,
      itemId,
      onlyCompletedLevels,
      progressHistory,
      progressHistoryMontessori,
      progressLevels,
    } = this.props
    const lastProgress = isFrameworkMontessori
      ? childFrameworkMontessoriProgress?.[itemId]
      : childFrameworkProgress?.[itemId]

    const initialValues = {
      date: moment().startOf('day'),
      state: null,
    }

    let finalStateOptions = progressLevels

    if (onlyCompletedLevels) {
      finalStateOptions = _.filter(progressLevels, ({ state }) => state === COMPLETED_STATE)
    }

    return (
      <FrameworkSelectProgressModalView
        childId={childId}
        description={description}
        initialValues={initialValues}
        isFetching={isFetching}
        isFetchingProgressHistory={isFetchingProgressHistory}
        isFrameworkMontessori={isFrameworkMontessori}
        isSubmitting={isSubmitting}
        lastProgress={lastProgress}
        progressHistory={isFrameworkMontessori ? progressHistoryMontessori : progressHistory}
        progressLevels={finalStateOptions}
        onCloseClick={this.handleCloseClick}
        onRemove={this.handleRemove}
        onSubmit={this.handleSubmit}
        onView={this.handleView}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childFrameworkProgressHistoryMontessoriState,
  childFrameworkProgressHistoryState,
  childFrameworkProgressListMontessoriState,
  childFrameworkProgressListState,
  childFrameworkProgressSelectors,
  frameworkProgressLevelsListState,
  frameworkProgressLevelsSelectors,
}) => ({
  childFrameworkMontessoriProgress: (
    childFrameworkProgressSelectors.getChildFrameworkProgressListMontessoriDataState(state)
  ),
  childFrameworkProgress: childFrameworkProgressSelectors.getChildFrameworkProgressListDataState(state),
  isFetching: appSelectors.getIsFetching(
    frameworkProgressLevelsListState,
  ),
  isFetchingProgressHistory: appSelectors.getIsFetching(
    childFrameworkProgressHistoryMontessoriState,
    childFrameworkProgressHistoryState,
  ),
  isSubmitting: appSelectors.getIsSubmitting(
    childFrameworkProgressListMontessoriState,
    childFrameworkProgressListState,
  ),
  progressHistory: childFrameworkProgressSelectors.getChildFrameworkProgressHistoryDataState(state),
  progressHistoryMontessori: childFrameworkProgressSelectors.getChildFrameworkProgressHistoryMontessoriDataState(state),
  progressLevels: frameworkProgressLevelsSelectors.getFrameworkProgressLevels(state),
})

const mapDispatch = {
  injectValidation: (formName, data) => stopSubmit(formName, data),
}

const enhance = compose(
  withAppService,
  withChildFrameworkProgressService,
  withFrameworkProgressLevelsService,
  withModalService,
  withRouter,
  connect(mapState, mapDispatch),
)

export default enhance(FrameworkSelectProgressModalContainer)
