import ServiceBase from 'services/ServiceBase'
import { getQueryStringV2 } from 'utils/api'

class RequestedExtraSessionsApiClient extends ServiceBase {
  get = (id, params) => this.request({
    path: `/v2/request_extra_sessions/${id}?${getQueryStringV2(params)}`,
  })

  getAvailabilityStatistics = (params = {}) => this.request({
    path: `/v2/statistics/request_extra_sessions/staff_required_and_availability?${getQueryStringV2(params)}`,
  })

  getCostStatistics = (params = {}) => this.request({
    path: `/v2/statistics/request_extra_sessions/cost?${getQueryStringV2(params)}`,
  })

  list = (params = {}) => this.request({
    path: `/v2/request_extra_sessions?${getQueryStringV2(params)}`,
  })

  update = (id, params, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/request_extra_sessions/${id}?${getQueryStringV2(params)}`,
  })

  createExceptionalUnavailableTime = (params = {}, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/exceptional_unavailable_times?${getQueryStringV2(params)}`,
  })

  getExceptionalUnavailableTime = (id, params = {}) => this.request({
    path: `/v2/exceptional_unavailable_times/${id}?${getQueryStringV2(params)}`,
  })

  listExceptionalUnavailableTimes = (params = {}) => this.request({
    path: `/v2/exceptional_unavailable_times?${getQueryStringV2(params)}`,
  })

  removeExceptionalUnavailableTime = (id) => this.request({
    method: 'DELETE',
    path: `/v2/exceptional_unavailable_times/${id}`,
  })

  updateExceptionalUnavailableTime = (id, params = {}, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/exceptional_unavailable_times/${id}?${getQueryStringV2(params)}`,
  })

  listRegularUnavailableTimes = (params = {}) => this.request({
    path: `/v2/regular_unavailable_times?${getQueryStringV2(params)}`,
  })

  updateBatchRegularUnavailableTimes = (params = {}, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/batch/regular_unavailable_times?${getQueryStringV2(params)}`,
  })
}

export default RequestedExtraSessionsApiClient
