import { BasicListInitialState, getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import {
  CLEAR_FAILED_RECURRING_PAYMENT,
  GET_FAILED_RECURRING_PAYMENT,
  GET_FAILED_RECURRING_PAYMENT_FAILED,
  GET_FAILED_RECURRING_PAYMENT_SUCCESS,
} from './constants'

const initialState = getBasicListInitialState

export interface NurseryIntegrationsFailedRecurringPaymentState extends BasicListInitialState {}

export default (...props) => {
  const [state = initialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_FAILED_RECURRING_PAYMENT,
    failed: GET_FAILED_RECURRING_PAYMENT_FAILED,
    init: GET_FAILED_RECURRING_PAYMENT,
    success: GET_FAILED_RECURRING_PAYMENT_SUCCESS,
  }, initialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
