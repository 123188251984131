import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledContainer = styled.div`
 & > div > table > tbody {
    tr:nth-child(odd) {
      background: none;
    }

    tr:nth-child(even) {
      background: none;
    }

    tr:nth-child(1) {
      background: inherit;
    }

    tr > td {
      border: none;
      border-bottom: 1px solid ${NEUTRAL_COLOURS.SILVER};
    }    
  }
`

export const StyledFundingDetailsTableContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 10px;
  margin: 20px;
`

export const StyledDetailsTable = styled.table`
  && {
    margin-bottom: 20px;

    tr > td {
      border: none;
    }

    th, td {
      text-align: left;
      padding: 10px;
    }
  }
`

export const StyledDetailAllocationContainer = styled.div`
  && {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 8px;
    }
  }
`

export const StyledCurrentFundingCell = styled.div`
  display: flex;

  * ~ * {
    margin-left: 10px;
  }
`

export const StyledAllocationLabelContainer = styled.div`
  border-radius: 5px;
  overflow: hidden;
`
