import _ from 'lodash'
import moment from 'moment'
import color from 'color'

import { createSelector } from 'reselect'

import { getBrandingColor } from 'utils/branding'
import { convertTimeDuration, getHourDiffBetweenEpochTime, hoursAndMinutesToFloat } from 'utils/date'
import { toFloat } from 'utils/data'

import colors from 'constants/colors'
import childSessionConstants from 'services/legacy/childSessions/constants'

import { getNurseryData } from 'services/nurseries/single/selectors/single'
import {
  getSingleDataSelectors as getChildFundingSingleDataSelectors,
  getWeekDaysList,
} from 'services/legacy/childFunding/single/selectors'
import { getChildSessionsList } from 'services/legacy/childSessions/selectors'
import { getPeriodTimesListData } from 'services/periodTimes/selectors'

import i18n from 'translations'

import { REST_FUNDING_COLORS } from '../constants'

export const getChildFundingSessionSetSelectors = (state) => state.childFundingSessionSet

export const getListDataSelectors = createSelector(
  [getChildFundingSessionSetSelectors],
  (state) => state.list.data,
)

export const getListMetaSelectors = createSelector(
  [getChildFundingSessionSetSelectors],
  (state) => state.list.meta,
)

export const getCriteria = createSelector(
  [(filters) => filters],
  (filters) => {
    if (!filters) {
      return null
    }

    const { childFundingId, childId, fundingArchived, sessionArchived, sessions } = filters
    const criteria = []

    if (childId) {
      criteria.push({
        field: 'child',
        value: childId,
      })
    }

    if (childFundingId) {
      criteria.push({
        field: 'funding',
        value: childFundingId,
      })
    }

    if (sessions && sessions.length) {
      sessions.forEach((id) => {
        criteria.push({
          field: 'session[]',
          value: id,
        })
      })
    }

    if (sessionArchived !== undefined && null !== sessionArchived) {
      criteria.push({
        field: 'session.archived',
        value: sessionArchived,
      })
    }

    if (fundingArchived !== undefined && null !== fundingArchived) {
      criteria.push({
        field: 'funding.archived',
        value: fundingArchived,
      })
    }

    return criteria
  },
)

export const getSumOfAllocations = (allocations) => (
  toFloat(_.reduce(allocations, (hoursInvoiced, { times }) => {
    const daywiseHours = _.reduce(
      times,
      (totalHoursItem, { endTime, startTime }) => totalHoursItem + getHourDiffBetweenEpochTime(startTime, endTime),
      0,
    )

    return hoursInvoiced + daywiseHours
  }, 0), 2)
)

export const getOtherFunding = () => createSelector(
  [getChildFundingSingleDataSelectors, getListDataSelectors],
  (childFunding, childSessionSetList) => {
    if (!childFunding || !childSessionSetList || !childSessionSetList.length) {
      return null
    }

    const colorLength = REST_FUNDING_COLORS.length

    const restSessionSets = _.filter(
      childSessionSetList,
      ({ funding: { archived, id } }) => id !== childFunding.id && !archived,
    )
    const restFunding = _.map(restSessionSets, ({ funding }) => funding)
    const restUnionFunding = _.uniqBy(restFunding, ({ id }) => id)

    return _.map(restUnionFunding, (funding, index) => {
      const colorIndex = index % colorLength
      const backgroundColor = REST_FUNDING_COLORS[colorIndex]

      return {
        ...funding,
        backgroundColor,
      }
    })
  },
)

const getWeeklyHoursInvoiced = (selectedWeekDays, allocations) => {
  if (!selectedWeekDays || !allocations || !allocations.length) {
    return 0
  }

  const { weekEndDate, weekStartDate } = selectedWeekDays

  const selectedWeekAllocations = _.filter(allocations, ({ date }) => {
    const newDate = moment(date).startOf('day').toDate()

    return newDate >= weekStartDate && newDate <= weekEndDate
  })

  if (!selectedWeekAllocations || !selectedWeekAllocations.length) {
    return 0
  }

  return getSumOfAllocations(selectedWeekAllocations)
}

export const getSessionSets = (selectedSessionSet, selectedWeekDays) => createSelector(
  [getListDataSelectors, getChildFundingSingleDataSelectors, getChildSessionsList],
  (childSessionSetList, childFunding, childSessionsList) => {
    if (!childSessionSetList || !childFunding || !childSessionsList || !childSessionsList.length) {
      return null
    }

    const { hourlyRate, id: childFundingId, settings } = childFunding
    const { hoursPerWeek } = settings || {}
    const { hours: weeklyHours, minutes: weeklyMinutes } = hoursPerWeek || {}

    const currentFundingSessionSets = _.filter(childSessionSetList, { funding: { id: childFundingId } })

    return _.map(currentFundingSessionSets, (sessionSet) => {
      const { allocations } = sessionSet

      const { ALLOCATION_PERIOD_TEXT, SESSION_CALCULATION } = childSessionConstants

      const sessionDetail = _.find(childSessionsList, { id: sessionSet.session.id })
      const { allocationPeriod, sessionCalculation } = sessionDetail || {}

      const allocationPeriodText = ALLOCATION_PERIOD_TEXT[allocationPeriod]
      const sessionCalculationText = sessionCalculation === SESSION_CALCULATION.ACTUAL_SESSIONS ? 'Actuals' : 'Average'

      const hoursAllocated = getSumOfAllocations(allocations)
      const amountAllocated = hoursAllocated * hourlyRate

      const { session } = sessionSet
      const { id: selectedSessionSetId } = selectedSessionSet || {}

      return {
        ...sessionSet,
        isSelected: sessionSet.id === selectedSessionSetId,
        session: {
          ...session,
          amountAllocated,
          calculation: sessionCalculationText,
          hoursAllocated,
          type: allocationPeriodText,
          weeklyHours: hoursAndMinutesToFloat(weeklyHours, weeklyMinutes),
          weeklyHoursInvoiced: getWeeklyHoursInvoiced(selectedWeekDays, sessionSet.allocations),
        },
      }
    })
  },
)

const getSessionsOfWeekDays = (childSessionsList, weekStartDate, weekEndDate, fundingEndDate) => _.filter(
  childSessionsList,
  ({ endDate = fundingEndDate, startDate }) => weekStartDate <= endDate && weekEndDate >= startDate,
)

const getSessionSetOfWeekDays = (childSessionsSets, weekStartDate, weekEndDate, fundingEndDate) => _.find(
  childSessionsSets,
  ({ session: { endDate = fundingEndDate, startDate } }) => weekStartDate <= endDate && weekEndDate >= startDate,
)

export const getFundingWeekDaysList = (selectedWeekDays) => createSelector(
  [getWeekDaysList, getChildFundingSingleDataSelectors, getChildSessionsList, getListDataSelectors],
  (weekDaysList, childFunding, childSessionsList, childSessionSetList) => {
    if (
      !weekDaysList
      || !weekDaysList.length
      || !childFunding
      || !childSessionsList
      || !childSessionsList.length
    ) {
      return null
    }

    const { endDate: fundingEndDate, id: childFundingId, settings } = childFunding || {}
    const { hoursPerWeek } = settings || {}
    const { hours: weeklyHours, minutes: weeklyMinutes } = hoursPerWeek || {}

    const { id } = selectedWeekDays || {}
    const currentFundingSessionSets = _.filter(childSessionSetList, { funding: { id: childFundingId } })

    return _.map(weekDaysList, (weekDays) => {
      const { weekEndDate, weekStartDate } = weekDays

      const sessions = getSessionsOfWeekDays(childSessionsList, weekStartDate, weekEndDate, fundingEndDate)
      const sessionSet = 1 === sessions?.length
        ? getSessionSetOfWeekDays(currentFundingSessionSets, weekStartDate, weekEndDate, fundingEndDate)
        : null

      const hours = sessionSet ? hoursAndMinutesToFloat(weeklyHours, weeklyMinutes) : 0
      const hoursInvoiced = sessionSet
        ? getWeeklyHoursInvoiced(weekDays, sessionSet?.allocations)
        : 0

      return {
        ...weekDays,
        fundingEndDate,
        isActive: true,
        isFullyAllocated: sessionSet && hours === hoursInvoiced,
        isPartialWeek: 1 < sessions.length,
        isSelected: id === weekDays.id,
        sessions,
      }
    })
  },
)

const getNurseryStartAndEndTime = (nurseryDetail) => {
  if (
    !nurseryDetail
    || !nurseryDetail.nurserySettings
    || !nurseryDetail.nurserySettings.openingDays
    || !nurseryDetail.nurserySettings.openingDays.length
  ) {
    return {
      end: 0,
      start: 0,
    }
  }

  const { nurserySettings: { openingDays } } = nurseryDetail

  const startTime = _.maxBy(openingDays, 'startTime').startTime
  const endTime = _.maxBy(openingDays, 'endTime').endTime

  return {
    endTime: moment(endTime).utc().format('HH'),
    startTime: moment(startTime).utc().format('HH'),
  }
}

const injectSessionSetId = (plans, childSessionSetId) => _.map(plans, (plan) => ({
  ...plan,
  childSessionSetId,
}))

const getSessionPlan = (selectedWeekDays, childSessionSetList, fundingEndDate) => {
  const { sessions, weekEndDate, weekStartDate } = selectedWeekDays

  if (!sessions || !sessions.length) {
    return {}
  }

  // NOTE: if weekdays have singleLegacy session
  if (1 === sessions.length) {
    const childSessionSet = _.find(childSessionSetList, (sessionSet) => sessionSet.session.id === sessions[0].id)

    if (!childSessionSet) {
      return {}
    }

    return _.groupBy(injectSessionSetId(sessions[0].plans, childSessionSet.id), 'dayOfWeek')
  }

  // NOTE: if weekdays have multiple session
  const plans = []
  let currentDate = new Date(weekStartDate)

  while (currentDate <= weekEndDate) {
    const dayOfWeek = moment(currentDate).format('dddd').toLowerCase()
    const session = _.find(sessions, ({ endDate = fundingEndDate, startDate }) => currentDate >= startDate && currentDate <= endDate) // eslint-disable-line

    if (session) {
      const sessionPlans = _.filter(session.plans, { dayOfWeek })

      if (sessionPlans && sessionPlans.length) {
        const childSessionSet = _.find(childSessionSetList, (sessionSet) => sessionSet.session.id === session.id)

        if (childSessionSet) {
          plans.push(...injectSessionSetId(sessionPlans, childSessionSet.id))
        }
      }
    }

    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1))
  }

  return _.groupBy(plans, 'dayOfWeek')
}

const getAllocation = (date, childSessionSetList, childSessionSetId, childSessionPlanId, otherFunding) => {
  // Get allocation for selected funding
  const childSessionSet = _.find(childSessionSetList, { id: childSessionSetId })

  const { allocations } = childSessionSet

  const currentFundingAllocation = _.find(allocations, (allocation) => (
    moment(allocation.date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')
    && allocation.childSessionPlan.id === childSessionPlanId
  ))

  // Get allocation other then current funding
  const restChildSessionSet = _.filter(childSessionSetList, ({ id }) => id !== childSessionSetId)

  const restFundingAllocation = _.map(restChildSessionSet, ({ allocations: restAllocations, funding }) => {
    const { id: restFundingId } = funding

    const restFunding = _.find(otherFunding, { id: restFundingId })
    const { backgroundColor } = restFunding || {}

    const restAllocation = _.find(restAllocations, (allocation) => (
      moment(allocation.date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')
      && allocation.childSessionPlan.id === childSessionPlanId
    ))

    if (!restAllocation) {
      return null
    }

    return {
      ...restAllocation,
      times: _.map(restAllocation.times, (allocation) => ({ ...allocation, backgroundColor })),
    }
  })

  return {
    current: currentFundingAllocation,
    rest: restFundingAllocation,
  }
}

const mapAllocationTimes = (isConflictWithSelectedSessionsSet, currentFunding) => ({
  backgroundColor,
  endTime,
  name,
  startTime,
}) => ({
  backgroundColor: isConflictWithSelectedSessionsSet ? color(backgroundColor).fade(0.8).string() : backgroundColor,
  currentFunding,
  endTime: convertTimeDuration(moment(endTime).utc().format('HH:mm'), null, 'hours'),
  endTimeUnix: endTime,
  name,
  startTime: convertTimeDuration(moment(startTime).utc().format('HH:mm'), null, 'hours'),
  startTimeUnix: startTime,
})

const getNestedProgressListFromAllocation = (allocation, isConflictWithSelectedSessionsSet) => {
  if (!allocation) {
    return undefined
  }

  const nestedAllocations = []

  const { current = {}, rest = {} } = allocation

  if (current && current.times && current.times.length) {
    nestedAllocations.push(..._.map(
      current.times,
      mapAllocationTimes(isConflictWithSelectedSessionsSet, true),
    ))
  }

  _.forEach(rest, (restItem) => {
    if (restItem) {
      nestedAllocations.push(..._.map(
        restItem.times,
        mapAllocationTimes(isConflictWithSelectedSessionsSet, false),
      ))
    }
  })

  return nestedAllocations
}

export const isDateExistBetweenDateRange = (date, dateRange) => {
  if (!dateRange || !dateRange.length) {
    return null
  }

  return _.some(dateRange, ({ finishDate, startDate }) => (
    date.format('YYYY-MM-DD') >= moment(startDate).format('YYYY-MM-DD')
    && date.format('YYYY-MM-DD') <= moment(finishDate).format('YYYY-MM-DD')
  ))
}

export const getTermCloserItem = (date, periodTimes) => {
  if (!periodTimes.length) {
    return null
  }

  return _.find(periodTimes, ({ endDate, startDate }) => (
    date.format('YYYY-MM-DD') >= moment(startDate).format('YYYY-MM-DD')
    && date.format('YYYY-MM-DD') <= moment(endDate).format('YYYY-MM-DD')
  ))
}

const getTimeLineItemBackgroundColor = (disabled, isConflictWithSelectedSessionsSet) => {
  if (disabled) {
    return colors.lightGray
  }

  const backgroundColor = color(getBrandingColor('primary-color'))

  if (isConflictWithSelectedSessionsSet) {
    return backgroundColor.fade(0.7).string()
  }

  return backgroundColor.fade(0.1).string()
}

const getProgressList = ({
  childSessionSetList,
  currentDate,
  fundingStartDate,
  isClosed,
  isEmpty,
  nurseryStartAndEndTime = {},
  otherFunding,
  plans,
  selectedSessionSet,
}) => {
  if (isEmpty) {
    return []
  }

  if (isClosed) {
    return [{
      backgroundColor: getTimeLineItemBackgroundColor(true),
      disabled: true,
      endTime: +nurseryStartAndEndTime.endTime,
      name: i18n.t('global:Closed'),
      nestedProgressList: [],
      startTime: +nurseryStartAndEndTime.startTime,
      times: undefined,
    }]
  }

  return _.map(plans, ({ childSessionSetId, endTime, id, nurserySession, startTime }) => {
    const { name } = nurserySession
    const { id: selectedSessionSetId } = selectedSessionSet || {}

    const isConflictWithSelectedSessionsSet = childSessionSetId !== selectedSessionSetId

    const allocation = getAllocation(
      currentDate.toDate(),
      childSessionSetList,
      childSessionSetId,
      id,
      otherFunding,
    )
    const nestedProgressList = getNestedProgressListFromAllocation(allocation, isConflictWithSelectedSessionsSet)

    const disabled = moment(fundingStartDate).format('YYYYMMDD') > currentDate.format('YYYYMMDD')

    return {
      backgroundColor: getTimeLineItemBackgroundColor(disabled, isConflictWithSelectedSessionsSet),
      childSessionPlanId: id,
      childSessionSetId,
      disabled,
      endTime: convertTimeDuration(moment(endTime).utc().format('HH:mm'), null, 'hours'),
      name,
      nestedProgressList,
      startTime: convertTimeDuration(moment(startTime).utc().format('HH:mm'), null, 'hours'),
      times: allocation && allocation.current ? allocation.current.times : undefined,
    }
  })
}

const getDateRange = ({
  childSessionSetList,
  currentDate,
  excludedPeriods,
  fundingStartDate,
  groupedSessionPlans,
  nurseryStartAndEndTime,
  otherFunding,
  periodTimes,
  selectedSessionSet,
}) => {
  const currentDayOfWeek = currentDate.format('dddd').toLowerCase()
  const plans = groupedSessionPlans[currentDayOfWeek]
  const isDayExistsInPlan = Object.keys(groupedSessionPlans).some((weekDay) => weekDay === currentDayOfWeek)
  const isFundingExcluded = isDateExistBetweenDateRange(currentDate, excludedPeriods)
  const termClosureItem = getTermCloserItem(currentDate, periodTimes)

  const isClosed = !!termClosureItem
  const isEmpty = isFundingExcluded || !isDayExistsInPlan

  const progressList = getProgressList({
    childSessionSetList,
    currentDate,
    fundingStartDate,
    isClosed,
    isEmpty,
    nurseryStartAndEndTime,
    otherFunding,
    plans,
    selectedSessionSet,
  })

  return {
    date: currentDate.toDate(),
    isClosed,
    progressList,
  }
}

export const getTimeLineData = (selectedWeekDays, selectedTimeLine, selectedSessionSet = {}) => createSelector(
  [
    getListDataSelectors,
    getNurseryData,
    getChildFundingSingleDataSelectors,
    getPeriodTimesListData,
    getOtherFunding(),
  ],
  (childSessionSetList, nurseryDetail, childFunding, periodTimesListData, otherFunding = {}) => {
    if (
      !childSessionSetList
      || !childSessionSetList.length
      || !nurseryDetail
      || !selectedWeekDays
    ) {
      return null
    }

    const { endDate: fundingEndDate, funding, id: childFundingId, startDate: fundingStartDate } = childFunding || {}
    const { settings } = funding || {}
    const { excludedPeriods } = settings || {}
    const { nurserySettings } = nurseryDetail || {}

    const { formattedOpeningDays } = nurserySettings

    const { weekEndDate, weekStartDate } = selectedWeekDays

    const currentFundingSessionSets = _.filter(childSessionSetList, { funding: { id: childFundingId } })
    const groupedSessionPlans = getSessionPlan(selectedWeekDays, currentFundingSessionSets, fundingEndDate)
    const nurseryStartAndEndTime = getNurseryStartAndEndTime(nurseryDetail)
    const weekLastDayName = formattedOpeningDays[formattedOpeningDays.length - 1]

    const dateRange = []

    if (selectedTimeLine) {
      const { date } = selectedTimeLine

      const currentDateRange = getDateRange({
        childSessionSetList,
        currentDate: moment(date),
        excludedPeriods,
        fundingStartDate,
        groupedSessionPlans,
        nurseryStartAndEndTime,
        otherFunding,
        periodTimes: periodTimesListData,
        selectedSessionSet,
      })

      dateRange.push(currentDateRange)
    } else {
      const currentDate = moment(weekStartDate)

      while (currentDate <= moment(weekEndDate) || currentDate.format('dddd').toLowerCase() === weekLastDayName) {
        if (0 <= _.indexOf(formattedOpeningDays, currentDate.format('dddd').toLowerCase()) || dateRange.length) {
          const currentDateRange = getDateRange({
            childSessionSetList,
            currentDate,
            excludedPeriods,
            fundingStartDate,
            groupedSessionPlans,
            nurseryStartAndEndTime,
            otherFunding,
            periodTimes: periodTimesListData,
            selectedSessionSet,
          })

          dateRange.push(currentDateRange)
        }

        currentDate.add('d', 1)
      }
    }

    return {
      dateRange,
      endTime: nurseryStartAndEndTime.endTime,
      startTime: nurseryStartAndEndTime.startTime,
    }
  },
)

export const hasSessionSets = createSelector(
  [getListMetaSelectors],
  (sessionSetMeta) => !!(sessionSetMeta && sessionSetMeta.total_results),
)
