import moment from 'moment'
import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

import { Currency, Icon, Typography } from 'components'

import i18n from 'translations'

import { getHoursAndMinutes, getOverridableString } from 'services/nurseryFunding/single/selectors'

import {
  StyledContainer,
  StyledDateContainer,
  StyledFundingDetailGroup,
  StyledFundingDetailItem,
  StyledTitleContainer,
} from './ChildFundingDetailStyled'

const ChildFundingDetail = ({ childFunding }) => {
  const {
    amountAllocated,
    endDate,
    funding = {},
    hoursAllocated,
    settings,
    startDate,
    totalHours,
    total,
  } = childFunding || {}
  const { fundingType, label } = funding || {}
  const { name } = fundingType || {}
  const { hoursPerWeek, maxHoursPerDay } = settings || {}

  const hoursPerWeekHoursAndMinutes = getHoursAndMinutes(hoursPerWeek)
  const hoursPerWeekOverriddenString = getOverridableString(hoursPerWeek)

  const hoursPerDayHoursAndMinutes = getHoursAndMinutes(maxHoursPerDay)
  const hoursPerDayOverriddenString = getOverridableString(maxHoursPerDay)

  return (
    <StyledContainer>
      <StyledFundingDetailGroup>
        <StyledTitleContainer>
          <Icon height={20} icon="funding" />
          <div>
            {` ${label} - ${name}`}
          </div>
        </StyledTitleContainer>
        <StyledDateContainer>
          {`${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`}
        </StyledDateContainer>
      </StyledFundingDetailGroup>
      <StyledFundingDetailGroup color={getBrandingColor('primary-color')}>
        <StyledFundingDetailItem>
          <div>
            {i18n.t('module:Children:Child:BookingPattern:Funding:Allocation:FundingDetail:fundingHoursAllocated', {
              hoursAllocated,
              totalHours,
            })}
          </div>
          <Typography color={NEUTRAL_COLOURS.GRAY} variant="span" inline>
            {i18n.t('module:Children:Child:BookingPattern:Funding:Allocation:FundingDetail:maxHoursPerWeek')}
            {' : '}
            <Typography variant="span" bold>
              {hoursPerWeekHoursAndMinutes}
            </Typography>
            {hoursPerWeekOverriddenString && (
              <React.Fragment>
                {' '}
                <Typography variant="span">
                  {`(${hoursPerWeekOverriddenString})`}
                </Typography>
              </React.Fragment>
            )}
          </Typography>
        </StyledFundingDetailItem>
        <StyledFundingDetailItem>
          <div>
            {i18n.t('module:Children:Child:BookingPattern:Funding:Allocation:FundingDetail:TotalFundingValue')}
            <Currency value={amountAllocated} />
            {i18n.t('module:Children:Child:BookingPattern:Funding:Allocation:FundingDetail:TotalFundingValueDivider')}
            <Currency value={total} />
          </div>
          <Typography color={NEUTRAL_COLOURS.GRAY} variant="span" inline>
            {i18n.t('module:Children:Child:BookingPattern:Funding:Allocation:FundingDetail:maxHoursPerDay')}
            {' : '}
            <Typography variant="span" bold>
              {hoursPerDayHoursAndMinutes}
            </Typography>
            {hoursPerDayOverriddenString && (
              <React.Fragment>
                {' '}
                <Typography variant="span">
                  {`(${hoursPerDayOverriddenString})`}
                </Typography>
              </React.Fragment>
            )}
          </Typography>
        </StyledFundingDetailItem>
      </StyledFundingDetailGroup>
    </StyledContainer>
  )
}

export default ChildFundingDetail
