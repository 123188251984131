import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { FUNDING_FILTERS, FUNDING_FILTER_OPTIONS } from 'services/nurseryFunding/constants'

import { withAppService } from 'services/app'
import { withNurseryFundingService } from 'services/nurseryFunding'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withSortingUtils } from 'services/utils/sorting'

import ManagementFundingListV2View from './ManagementFundingListV2View'

const NURSERY_FUNDING_GROUPS = {
  read: [
    'nurseryFunding.settings',
    'nurseryFundingSettings',
    'nurseryFunding.nursery',
    'nurseryFunding.fundingType',
    'fundingType',
  ],
}

class ManagementFundingListV2Container extends Component {
  constructor(props) {
    super(props)

    const { status } = props.location.query

    this.state = {
      status: status || FUNDING_FILTERS.ACTIVE,
    }
  }

  componentDidMount() {
    const { sortingUtils } = this.props
    const { onSortChange } = sortingUtils

    onSortChange(this.fetch())
  }

  componentWillUnmount() {
    const { nurseryFundingActions } = this.props

    nurseryFundingActions.clearList()
  }

  fetch = () => {
    const {
      nurseryFundingActions,
      nurseryFundingHelpers,
      paginationUtils,
      setLocationQuery,
      sortingUtils,
    } = this.props
    const { status } = this.state
    const { page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const criteria = nurseryFundingHelpers.getCriteria({ status })

    setLocationQuery({ status })

    nurseryFundingActions.list({
      params: {
        criteria,
        groups: NURSERY_FUNDING_GROUPS,
        order: { sortField, sortOrder },
        page,
      },
    })
  }

  handleStatusFilterChange = (status) => {
    const { paginationUtils, sortingUtils } = this.props

    const { onPageChange } = paginationUtils
    const { onSortChange } = sortingUtils

    this.setState(
      (prevState) => ({
        ...prevState,
        status: status ? status.value : null,
      }),
      () => onSortChange(onPageChange(this.fetch)(1)),
    )
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleSortChange = (key) => {
    const { paginationUtils, sortingUtils } = this.props
    const { onSortChange } = sortingUtils
    const { onPageChange } = paginationUtils

    onSortChange(onPageChange(this.fetch)(1))(key)
  }

  render() {
    const {
      errorMessages,
      isFetching,
      nurseryFundingListState,
      nurseryFundingTableData,
      paginationUtils,
      sortingUtils,
    } = this.props
    const { status } = this.state
    const { getPageCount, page, perPage } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const isEmpty = nurseryFundingListState.isEmpty
    const isLoading = isFetching
    const fundingFilterOptions = FUNDING_FILTER_OPTIONS
    const pageCount = getPageCount(nurseryFundingListState.meta.total_results)

    return (
      <ManagementFundingListV2View
        errorMessages={errorMessages}
        fundingFilterOptions={fundingFilterOptions}
        isEmpty={isEmpty}
        isLoading={isLoading}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        sortField={sortField}
        sortOrder={sortOrder}
        status={status}
        tableData={nurseryFundingTableData}
        totalResults={nurseryFundingListState.meta.total_results}
        onPageChange={this.handlePageChange}
        onSortChange={this.handleSortChange}
        onStatusFilterChange={this.handleStatusFilterChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseryFundingListState,
  nurseryFundingSelectors,
}) => ({
  errorMessages: appSelectors.getErrorMessages(nurseryFundingListState),
  isFetching: appSelectors.getIsFetching(nurseryFundingListState),
  nurseryFundingTableData: nurseryFundingSelectors.getNurseryFundingListTableDataSelectors(state),
})

const enhance = compose(
  withAppService,
  withNurseryFundingService,
  withPaginationUtils,
  withRouterUtils,
  withSortingUtils,
  connect(mapState),
)

export default enhance(ManagementFundingListV2Container)
