import React, { PropsWithChildren } from 'react'

import { StyledLayoutContainer } from './LayoutContainerStyled'

interface LayoutContainerProps {
  expanded?: boolean
}

const LayoutContainer: React.FC<PropsWithChildren<LayoutContainerProps>> = ({ children, expanded }) => (
  <StyledLayoutContainer $expanded={expanded}>
    {children}
  </StyledLayoutContainer>
)

export default LayoutContainer
