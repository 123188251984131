import React, { PropsWithChildren, useState } from 'react'
import useCollapse from 'react-collapsed'

import { Icon } from 'components'

import { StyledAccordion, StyledContent, StyledHeader } from './AccordionStyled'

export interface AccordionItemProps {
  header: React.ReactNode
}

const AccordionItem: React.FC<PropsWithChildren<AccordionItemProps>> = ({
  children,
  header,
}) => {
  const [isExpanded, setExpanded] = useState(false)
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
  })

  return (
    <StyledAccordion>
      <StyledHeader
        $isOpen={isExpanded}
        {...getToggleProps({
          onClick: () => setExpanded((oldExpanded) => !oldExpanded),
        })}
      >
        <div>
          {header}
        </div>
        <Icon
          cursor="pointer"
          height={32}
          icon={isExpanded ? 'circle-chevron-up' : 'circle-chevron-down'}
        />
      </StyledHeader>
      <div {...getCollapseProps()}>
        <StyledContent>
          {children}
        </StyledContent>
      </div>
    </StyledAccordion>
  )
}

export default AccordionItem
