import React from 'react'

import { WrappedComponentType } from 'constants/types'

import * as selectors from './selectors'

export interface withLikesAndCommentsServiceProps {
  likesAndCommentsSelectors: typeof selectors
}

const withLikesAndCommentsService = <P extends withLikesAndCommentsServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const Component = ({ ...others }) => (
    <WrappedComponent
      likesAndCommentsSelectors={selectors}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return Component
}

export default withLikesAndCommentsService
