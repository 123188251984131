import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withEnquiryStatusTypesService } from 'services/legacy/enquiries'
import { withRouter } from 'services/router'

import StatusTypeMigrationView from './StatusTypeMigrationView'
import { ENQUIRY_STATUS_TYPE_MIGRATION_FORM } from './components/StatusTypeMigrationModalForm'

class StatusTypeMigrationContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isPreviewMode: false,
      newStage: null,
    }
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSubmitFailed = (response) => {
    const { injectValidation } = this.props
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    return injectValidation(ENQUIRY_STATUS_TYPE_MIGRATION_FORM, errors)
  }

  handleSubmitSuccess = () => {
    const { onMigrateSuccess } = this.props

    if (onMigrateSuccess) {
      onMigrateSuccess()
    }

    this.handleCloseClick()
  }

  handleMigrateClick = ({ newStage }) => {
    const { enquiryStatusType, enquiryStatusTypesActions } = this.props
    const { isPreviewMode } = this.state

    if (!isPreviewMode) {
      this.setState({ isPreviewMode: true, newStage })

      return
    }

    const { value } = newStage
    const { id } = enquiryStatusType

    enquiryStatusTypesActions.update({
      body: { archived: true, migrateStatus: { id: value } },
      onFailed: this.handleSubmitFailed,
      onSuccess: this.handleSubmitSuccess,
      params: [id, {}],
    })
  }

  handleCancelClick = () => {
    const { isPreviewMode } = this.state

    if (isPreviewMode) {
      this.setState({ isPreviewMode: false })

      return
    }

    this.handleCloseClick()
  }

  render() {
    const {
      enquiryStatusType,
      errorMessages,
      isFetching,
      isSubmitting,
    } = this.props
    const { isPreviewMode, newStage } = this.state

    return (
      <StatusTypeMigrationView
        enquiryStatusType={enquiryStatusType}
        errorMessages={errorMessages}
        isFetching={isFetching}
        isPreviewMode={isPreviewMode}
        isSubmitting={isSubmitting}
        newStage={newStage}
        onCancelClick={this.handleCancelClick}
        onCloseClick={this.handleCloseClick}
        onMigrateClick={this.handleMigrateClick}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  enquiryStatusTypesSelectors,
  enquiryStatusTypesSingleState,
}) => ({
  enquiryStatusType: enquiryStatusTypesSelectors.getEnquiryStatusTypesSingleDataSelector(state),
  errorMessages: appSelectors.getErrorMessages(enquiryStatusTypesSingleState),
  isSubmitting: appSelectors.getIsSubmitting(enquiryStatusTypesSingleState),
})

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withEnquiryStatusTypesService,
  connect(mapState),
)

export default enhance(StatusTypeMigrationContainer)
