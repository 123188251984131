import _ from 'lodash'

import React, { PropsWithChildren } from 'react'

import i18n from 'translations'

import ReportViewItem from './ReportViewItem'
import ReportViewDetailPage from './ReportViewDetailPage'
import ReportViewNurseryLink from './ReportViewNurseryLink'
import EmptyState from '../EmptyState'
import { StyledContainer } from './ReportViewStyled'

interface ReportViewRoot<T> extends React.FC<PropsWithChildren<T>> {
  DetailPage?: typeof ReportViewDetailPage
  Item?: typeof ReportViewItem
  NurseryLink?: typeof ReportViewNurseryLink
}

type ReportItem = {
  description: string
  hidden?: boolean
  onClick?: () => void
  title: string
  to?: string
}

interface ReportViewProps {
  items?: ReportItem[]
}

const ReportView: ReportViewRoot<ReportViewProps> = ({ items }) => {
  if (!items?.length) {
    return (
      <EmptyState
        icon="reports"
        text1={i18n.t('components:ReportView:emptyStateText')}
      />
    )
  }

  return (
    <StyledContainer>
      {_.map(items, ({ description, hidden, onClick, title, to }, index) => {
        if (hidden) {
          return null
        }

        return (
          <ReportViewItem
            description={description}
            key={index}
            title={title}
            to={to}
            onClick={onClick}
          />
        )
      })}
    </StyledContainer>
  )
}

export default ReportView
