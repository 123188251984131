import { combineReducers } from 'redux'

import list, { ChildNotesListState } from './list/reducer'
import single, { ChildNotesSingleState } from './single/reducer'

export default combineReducers({
  list,
  single,
})

export interface ChildNotesState {
  list: ChildNotesListState
  single: ChildNotesSingleState
}
