import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { RootState } from 'core/reducers'

import { injectActions } from 'utils/service'

import { NurseryAcademicYearsListState, NurseryAcademicYearsSingleState } from './models'
import actions from './actions'
import * as nurseryAcademicYearsSelectors from './selectors'

export interface withNurseryAcademicYearsServiceProps {
  nurseryAcademicYearsActions: typeof actions
  nurseryAcademicYearsListState: NurseryAcademicYearsListState
  nurseryAcademicYearsSelectors: typeof nurseryAcademicYearsSelectors
  nurseryAcademicYearsSingleState: NurseryAcademicYearsSingleState
}

const withNurseryAcademicYearsService = <P extends withNurseryAcademicYearsServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ nurseryAcademicYears }: RootState) => ({ nurseryAcademicYears })

  const mapDispatch = injectActions('nurseryAcademicYearsActions', actions)

  const Component = ({ nurseryAcademicYears, nurseryAcademicYearsActions, ...others }) => (
    <WrappedComponent
      nurseryAcademicYearsActions={nurseryAcademicYearsActions}
      nurseryAcademicYearsListState={nurseryAcademicYears.list}
      nurseryAcademicYearsSelectors={nurseryAcademicYearsSelectors}
      nurseryAcademicYearsSingleState={nurseryAcademicYears.single}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withNurseryAcademicYearsService
