import _ from 'lodash'
import { flatten, nest } from 'utils/flatnest'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { FEATURE_FLAGS, ROLES } from 'constants/security'
import { FORMATIVE_REPORTS_STATUSES } from 'services/legacy/formativeReports/constants'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withFormativeReportsService } from 'services/legacy/formativeReports'
import { withModalService } from 'services/utils/modal'
import { withRouter } from 'services/router'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'

import { FORMATIVE_REPORTS_PAGE_TYPE } from 'module/Learning/FormativeReports/constants'

import FormativeReportsListView from './FormativeReportsListView'

const FORMATIVE_LISTS_GROUP = {
  read: [
    'formativeReport',
    'formativeReport.child',
    'formativeReport.author',
    'child',
    'childSnapshot',
    'child.nurseryClass',
    'nurseryClass',
    'child.keyWorkers',
    'user',
    'user.details',
    'formativeReport.childSnapshot',
  ],
}

class FormativeReportsListContainer extends Component {
  constructor(props) {
    super(props)

    const { location: { query } } = props
    const { assessmentPeriod, reportType, room, status, term } = nest(query)

    this.state = {
      filters: {
        assessmentPeriod,
        reportType,
        room,
        searchPhrase: null,
        status,
        term,
      },
      pageType: this.getPageType(),
    }

    this.handleSearchPhrase = _.debounce(this.handleSearchPhrase, 300)
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { formativeReportsActions } = this.props

    formativeReportsActions.clearList()
  }

  getPageType = () => {
    const { location: { pathname } } = this.props

    if (generateRoute('APPROVALS.FORMATIVE_REPORTS') === pathname) {
      return FORMATIVE_REPORTS_PAGE_TYPE.APPROVALS
    }

    if (generateRoute('LEARNING.FORMATIVE_REPORTS') === pathname) {
      return FORMATIVE_REPORTS_PAGE_TYPE.LEARNING
    }

    return FORMATIVE_REPORTS_PAGE_TYPE.CHILD
  }

  fetch = () => {
    const {
      formativeReportsActions,
      formativeReportsSelectors,
      paginationUtils,
      params,
      setLocationQuery,
    } = this.props
    const { childId } = params
    const { filters, pageType } = this.state
    const { page, perPage } = paginationUtils

    setLocationQuery(flatten(filters))

    const criteria = formativeReportsSelectors.getCriteria(filters)

    if (pageType === FORMATIVE_REPORTS_PAGE_TYPE.CHILD) {
      criteria.push({
        field: 'child',
        value: childId,
      })
    }

    if (pageType === FORMATIVE_REPORTS_PAGE_TYPE.APPROVALS) {
      criteria.push({
        field: 'status',
        value: FORMATIVE_REPORTS_STATUSES.PENDING_APPROVAL.value,
      })
    }

    const parameters = {
      criteria,
      groups: FORMATIVE_LISTS_GROUP,
      limit: perPage,
      page,
    }

    formativeReportsActions.list(parameters)
  }

  handleTermChange = (term) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, assessmentPeriod: null, term: term?.value },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handleReportTypeChange = (reportType) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, reportType: reportType?.value },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handleRoomChange = (room) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, room: room?.value },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handleStatusChange = (status) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, status: status?.value },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handleSearchPhrase = (searchPhrase) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, searchPhrase },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleExportClick = () => {
    const { child, modalActions, modalConsts } = this.props
    const { pageType } = this.state

    modalActions.show(
      modalConsts.TYPES.FORMATIVE_REPORTS_EXPORT,
      pageType === FORMATIVE_REPORTS_PAGE_TYPE.CHILD && { child },
    )
  }

  render() {
    const {
      authAccessMap,
      formativeReportsListState: { meta },
      isFetching,
      items,
      paginationUtils,
      params,
      termOptions,
    } = this.props
    const { childId } = params
    const { filters, pageType } = this.state
    const { getPageCount, page, perPage } = paginationUtils
    const pageCount = getPageCount(meta.total_results)

    return (
      <FormativeReportsListView
        authAccessMap={authAccessMap}
        childId={childId}
        filters={filters}
        isFetching={isFetching}
        items={items}
        page={page}
        pageCount={pageCount}
        pageType={pageType}
        perPage={perPage}
        termOptions={termOptions}
        totalResults={meta.total_results}
        onExportClick={this.handleExportClick}
        onPageChange={this.handlePageChange}
        onReportTypeChange={this.handleReportTypeChange}
        onRoomChange={this.handleRoomChange}
        onSearchPhraseChange={this.handleSearchPhrase}
        onStatusChange={this.handleStatusChange}
        onTermChange={this.handleTermChange}
      />
    )
  }
}

FormativeReportsListContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.ROOM_LEADER,
    ROLES.SENIOR_TEACHER,
    ROLES.TEACHER,
  ],
}

const mapState = (state, {
  appSelectors,
  childSelectors,
  formativeReportsListState,
  formativeReportsSelectors,
  params,
}) => ({
  authAccessMap: {
    section: {
      pdfAutomation: auth.SELECTORS.getIsAuthorised(state, {
        flags: [FEATURE_FLAGS.PDF_AUTOMATION],
      }),
    },
  },
  child: childSelectors.getChildSelector(state),
  isFetching: appSelectors.getIsFetching(formativeReportsListState),
  items: formativeReportsSelectors.getFormativeReportsData(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  termOptions: formativeReportsSelectors.getTerms(state),
})

const enhance = compose(
  withAppService,
  withChildService,
  withFormativeReportsService,
  withModalService,
  withPaginationUtils,
  withRouter,
  withRouterUtils,
  connect(mapState),
)

export default enhance(FormativeReportsListContainer)
