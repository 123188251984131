import _ from 'lodash'

import { createSelector } from 'reselect'

import i18n from 'translations'

import { ENQUIRY_STATUS_CODES } from '../../constants'
import { VIEWING_EVENT_TYPES } from '../../viewings/constants'
import { ACTIVITY_TYPE } from '../constants'

const getEnquiryActivities = (state) => state.enquiryActivities

export const getEnquiryActivitiesListSelector = createSelector(
  [getEnquiryActivities],
  (state) => state?.list,
)

export const getEnquiryActivitiesListDataSelector = createSelector(
  [getEnquiryActivitiesListSelector],
  (state) => state.data,
)

const getViewingNote = (item) => {
  const { activityData = {}, event, type } = item
  const { note = {} } = activityData
  const { newValue: newNote, oldValue: oldNote } = note

  const viewingNote = {
    newNoteValue: null,
    oldNoteValue: null,
  }

  if (type === ACTIVITY_TYPE.VIEWING.value) {
    const newNoteLabel = i18n.t('module:Enquiries:List:Detail:Activity:List:ShowAround:additionalNotes')
    const oldNoteLabel = i18n.t('module:Enquiries:List:Detail:Activity:List:ShowAround:additionalPreviousNotes')

    if (
      VIEWING_EVENT_TYPES.UPDATE === event
      && (newNote !== oldNote)
    ) {
      viewingNote.newNoteValue = `${newNoteLabel}\n${newNote || '-'}`
      viewingNote.oldNoteValue = `(${oldNoteLabel} ${oldNote || '-'})`
    } else {
      viewingNote.newNoteValue = `${newNoteLabel}\n${newNote || '-'}`
    }
  }

  return viewingNote
}

export const getEnquiryActivitiesFormattedListDataSelector = createSelector(
  [getEnquiryActivitiesListDataSelector],
  (data) => {
    if (!data?.length) {
      return []
    }

    return _.map(data, (item) => {
      const { activityData = {} } = item
      const { status } = activityData

      const { newNoteValue, oldNoteValue } = getViewingNote(item)

      return {
        ...item,
        activityData: {
          ...activityData,
          newNoteValue,
          oldNoteValue,
        },
        isLostEnquiry: status?.newValue?.code === ENQUIRY_STATUS_CODES.LOST_ENQUIRY,
      }
    })
  },
)

export const getCriteria = (filters) => {
  const {
    archived = false,
    enquiryId,
    type,
  } = filters || {}

  const criteria = []

  if (null !== archived && undefined !== archived) {
    criteria.push({
      field: 'archived',
      value: +archived,
    })
  }

  if (enquiryId) {
    criteria.push({
      field: 'enquiry[]',
      value: enquiryId,
    })
  }

  if (type) {
    criteria.push({
      field: 'type',
      value: type,
    })
  }

  return criteria
}
