import { createSelector } from 'reselect'

import { ChildrenStartingReportRootState } from '../models'

const getChildrenStartingReport = (state: ChildrenStartingReportRootState) => state.childrenStartingReport

export const getChildrenStartingReportStatistics = createSelector(
  [getChildrenStartingReport],
  (state) => state.statistics,
)

export const getChildrenStartingReportStatisticsData = createSelector(
  [getChildrenStartingReportStatistics],
  (state) => state.data,
)
