import moment from 'moment'

import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { isRequired } from 'utils/fieldValidation'
import { addMillisecondsFromMidnight } from 'utils/date'

import { Form } from 'components'

import i18n from 'translations'

export const STAFF_WORKLOG_END_TIME_MODAL_FORM = 'StaffWorklogEndTimeModalForm'

const EndTimeModalForm = ({
  handleSubmit,
  isSubmitting,
  onCloseClick,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row
      label={i18n.t('module:Modals:Staff:Worklog:EndTime:endTime')}
      verticalLabel
    >
      <Form.Row.FlexItem flex="0 0 150px">
        <Field
          component={Form.TimePicker}
          name="signOutTime"
          validate={isRequired}
        />
      </Form.Row.FlexItem>
      <Form.Row.FlexItem flex="1">
        <Field
          component={Form.DatePicker}
          name="signOutDate"
          validate={isRequired}
          clearable
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.FooterActions
      isSubmitting={isSubmitting}
      submitLabel={i18n.t('module:Modals:Staff:Worklog:EndTime:end')}
      onCancelClick={onCloseClick}
    />
  </Form>
)

const validate = (fields, { date, signInAt }) => {
  const error = {}

  if (fields.signOutTime && fields.signOutDate) {
    const signOutAt = addMillisecondsFromMidnight(fields.signOutTime.valueOf(), fields.signOutDate)
    const startDateTime = moment(date).startOf('day')
    const signOutAtTime = signOutAt ? signOutAt.diff(startDateTime) : undefined

    if (signInAt > signOutAtTime) {
      if (moment(date).format(DEFAULT_DATE_FORMAT) > moment(fields.signOutDate).format(DEFAULT_DATE_FORMAT)) {
        error.signOutDate = i18n.t('module:Modals:Staff:Worklog:EndTime:Validation:isEndTimeGreaterThanStartTime')
      } else {
        error.signOutTime = i18n.t('module:Modals:Staff:Worklog:EndTime:Validation:isEndTimeGreaterThanStartTime')
      }
    }
  }

  return error
}

export default reduxForm({ form: STAFF_WORKLOG_END_TIME_MODAL_FORM, validate })(EndTimeModalForm)
