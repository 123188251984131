import React from 'react'

import { StyledContentContainer } from './PlanViewStyled'

const PlanViewContent = ({ children }) => (
  <StyledContentContainer>
    {children}
  </StyledContentContainer>
)

export default PlanViewContent
