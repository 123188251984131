import styled from 'styled-components'
import color from 'color'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledMessageWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: flex-end;
  padding: 0 32px 8px;

  ${({ deliveryError }) => deliveryError && `
    grid-template-columns: 24px auto;
    grid-column-gap: 8px;
  `}
  
  ${({ first }) => first && `
    padding-top: 16px;
  `}

  ${({ left }) => left && `
    justify-content: flex-start;
  `}
`

export const StyledErrorWrapper = styled.div`
  display: flex;
  align-items: center;  
`

export const StyledMessageContent = styled.div`
  max-width: 500px;
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 5px;
  justify-content: flex-end;
  
  ${({ first }) => first && `
    grid-template-rows: 16px auto;
  `}
`

export const StyledMessage = styled.div`
  background: ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  padding: 10px 14px 6px;
  border-radius: 12px;
  position: relative;

  ${({ last, left }) => last && !left && `
    border-bottom-right-radius: 0;

    &::after {
      position: absolute;
      content: '';
      border-left: 12px solid #ededed;
      border-top: 14px solid transparent;
      right: -12px;
      bottom: 0px;
    }
  `}
  
  ${({ left }) => !left && `
    background: ${color(NEUTRAL_COLOURS.BLUE).alpha(0.15).rgb().string()};
    
    &::after {
      border-left-color: ${color(NEUTRAL_COLOURS.BLUE).alpha(0.15).rgb().string()};
    }
  `}
  
  ${({ isMe }) => isMe && `
    background: ${color(getBrandingColor('primary-color')).alpha(0.15).rgb().string()};
    
    &::after {
      border-left-color: ${color(getBrandingColor('primary-color')).alpha(0.15).rgb().string()};
    }
  `}

  ${({ last, left }) => last && left && `
    border-bottom-left-radius: 0;

    &::after {
      position: absolute;
      content: '';
      border-right: 12px solid #ededed;
      border-top: 14px solid transparent;
      left: -12px;
      bottom: 0px;
    }
  `}
`
