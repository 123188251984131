import styled from 'styled-components'

export const StyledAuthorisedDevices = styled.div`
  display: flex;
  align-items: center;
  
  & > div:nth-of-type(1) {
    margin-right: 5px;
  }
  
  ${({ color }) => color && `
    color: ${color};
  `}
`
