import _ from 'lodash'

import React from 'react'

import nurseriesConstants from 'services/nurseries/constants'
import { invoiceDetailOptions } from 'module/Settings/components/InvoiceSetting/constants'

import i18n from 'translations'

import DetailPreviewModalView from './DetailPreviewModalView'
import mockData from './mockData'

const DetailPreviewModalContainer = ({
  hideModal,
  value,
}) => {
  const handleCloseClick = () => {
    hideModal()
  }

  const { INVOICE_LEVEL_DETAIL_DISPLAY } = nurseriesConstants

  const isGroupedInvoice = value === INVOICE_LEVEL_DETAIL_DISPLAY.GROUPED_ITEM_TYPES_WITHOUT_COST_BREAKDOWN
  const option = _.find(invoiceDetailOptions, { value })
  const subTitle = i18n.t('module:Modals:InvoiceDetailPreview:subTitle', {
    label: option.label,
    number: isGroupedInvoice ? 1 : 2,
  })

  return (
    <DetailPreviewModalView
      invoicePreview={isGroupedInvoice ? mockData.grouped : mockData.standard}
      subTitle={subTitle}
      onCloseClick={handleCloseClick}
    />
  )
}

export default DetailPreviewModalContainer
