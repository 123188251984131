import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as newsletterRecipientsSelectors from './selectors'

const withNewsletterRecipientsService = (WrappedComponent) => {
  const mapState = ({ newsletterRecipients }) => ({ newsletterRecipients })

  const mapDispatch = injectActions('newsletterRecipientsActions', actions)

  const Component = ({ newsletterRecipients, newsletterRecipientsActions, ...other }) => (
    <WrappedComponent
      newsletterRecipientsActions={newsletterRecipientsActions}
      newsletterRecipientsListState={newsletterRecipients.list}
      newsletterRecipientsSelectors={newsletterRecipientsSelectors}
      newsletterRecipientsSingleState={newsletterRecipients.single}
      {...other}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withNewsletterRecipientsService
