import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import { CLEAR_LIST_FOOD_TYPES, LIST_FOOD_TYPES, LIST_FOOD_TYPES_FAILED, LIST_FOOD_TYPES_SUCCESS } from './constants'

const initialState = getBasicListInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_LIST_FOOD_TYPES,
    failed: LIST_FOOD_TYPES_FAILED,
    init: LIST_FOOD_TYPES,
    success: LIST_FOOD_TYPES_SUCCESS,
  }, initialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
