import {
  CHILDREN_STARTING_REPORT_STATISTICS,
  CHILDREN_STARTING_REPORT_STATISTICS_FAILED,
  CHILDREN_STARTING_REPORT_STATISTICS_SUCCESS,
  CLEAR_CHILDREN_STARTING_REPORT_STATISTICS,
} from 'services/childrenStartingReport/statistics/constants'

import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

export default (...props) => {
  const [state = getBasicListInitialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_CHILDREN_STARTING_REPORT_STATISTICS,
    failed: CHILDREN_STARTING_REPORT_STATISTICS_FAILED,
    init: CHILDREN_STARTING_REPORT_STATISTICS,
    success: CHILDREN_STARTING_REPORT_STATISTICS_SUCCESS,
  }, getBasicListInitialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}

