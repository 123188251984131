import styled from 'styled-components'

import colors from 'constants/colors'

import { StyledItemContainer } from 'components/Toolbar/ToolbarStyled'

export const StyledContainer = styled.div`
  padding: 20px;
`

export const StyledBoxContainer = styled.div`
  min-height: 200px;
  width: 100%;
  position: relative;
  border: 1px solid ${colors.lightGray};
  border-radius: 5px;
  margin-top: 40px;
  display: flex;
`

export const StyledTextContainer = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export const StyledTextWrapper = styled.div`
  display: flex;
`

export const StyledUnderlineWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`

export const StyledUnderline = styled.hr`
  position: absolute;
  width: 90%;
  bottom: 15px;
  border: 0 none;
  border-top: 1px solid ${colors.lightGray};
  background: none;
  height: 0;
`

export const StyledSignaturePadContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`

export const StyledClearContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`

export const StyledCompleteContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
`

export const StyledToolbar = styled.div`
  @media(max-width: 740px) {
    ${StyledItemContainer} {
      & > p {
        text-align: left;
      }
      
      & > p:nth-last-of-type(1) {
        text-align: right;
      }
    }
  }
`
