import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { ACTIONS } from 'services/legacy/requestedExtraSessions/constants'

import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withRequestedExtraSessionsService } from 'services/legacy/requestedExtraSessions'

import { isNil } from 'lodash'
import RequestedExtraSessionsDetailsView from './RequestedExtraSessionsDetailsView'
import { REQUEST_EXTRA_SESSION_GROUPS, REQUEST_EXTRA_SESSION_GROUPS_V3 } from './constants'

class RequestedExtraSessionsDetailsContainer extends Component {
  componentDidMount() {
    const { isFinanceV3Enabled } = this.props

    if (!isNil(isFinanceV3Enabled)) {
      this.fetch()
    }
  }

  componentWillUnmount() {
    const { requestedExtraSessionsActions } = this.props

    requestedExtraSessionsActions.clear()
  }

  componentDidUpdate(prevProps) {
    const { isFinanceV3Enabled, params } = this.props
    const { isFinanceV3Enabled: prevIsFinanceV3Enabled, params: prevParams } = prevProps
    const { id } = params
    const { id: prevId } = prevParams

    if (
      (prevId && id && prevId !== id)
      || (isNil(prevIsFinanceV3Enabled) && !isNil(isFinanceV3Enabled))
    ) {
      this.fetch()
    }
  }

  fetch = () => {
    const { isFinanceV3Enabled, params: { id }, requestedExtraSessionsActions } = this.props

    requestedExtraSessionsActions.get({
      params: [id, {
        groups: isFinanceV3Enabled ? REQUEST_EXTRA_SESSION_GROUPS_V3 : REQUEST_EXTRA_SESSION_GROUPS,
      }],
    })
  }

  handleDeclineClick = () => {
    const { errorStatistics, modalActions, modalConsts, params: { id }, requestedExtraSession } = this.props
    const { statistics, status } = requestedExtraSession

    modalActions.show(modalConsts.TYPES.REQUESTED_EXTRA_SESSION, {
      action: ACTIONS.DECLINE,
      id,
      onSuccess: this.fetch,
      statistics: errorStatistics || statistics,
      status,
    })
  }

  handleApproveClick = () => {
    const { errorStatistics, modalActions, modalConsts, params: { id }, requestedExtraSession } = this.props
    const { statistics, status } = requestedExtraSession

    modalActions.show(modalConsts.TYPES.REQUESTED_EXTRA_SESSION, {
      action: ACTIONS.APPROVE,
      id,
      onSuccess: this.fetch,
      statistics: errorStatistics || statistics,
      status,
    })
  }

  render() {
    const {
      errorMessages,
      errorMessagesCustomCodes,
      errorStatistics,
      isAvailabilityStaffRequirementsChanged,
      isFetching,
      isFinanceV3Enabled,
      isSessionDetailsChanged,
      isSubmitting,
      requestedExtraSession,
    } = this.props

    if (isNil(isFinanceV3Enabled)) {
      return null
    }

    return (
      <RequestedExtraSessionsDetailsView
        data={requestedExtraSession}
        errorMessages={errorMessages}
        errorMessagesCustomCodes={errorMessagesCustomCodes}
        errorStatistics={errorStatistics}
        isAvailabilityStaffRequirementsChanged={isAvailabilityStaffRequirementsChanged}
        isFetching={isFetching}
        isFinanceV3Enabled={isFinanceV3Enabled}
        isSessionDetailsChanged={isSessionDetailsChanged}
        isSubmitting={isSubmitting}
        onApproveClick={this.handleApproveClick}
        onDeclineClick={this.handleDeclineClick}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  requestedExtraSessionsSelectors,
  requestedExtraSessionsSingleState,
}) => ({
  errorMessages: appSelectors.getErrorMessages(requestedExtraSessionsSingleState),
  errorStatistics: requestedExtraSessionsSelectors.getErrorStatistics(state),
  isAvailabilityStaffRequirementsChanged: requestedExtraSessionsSelectors.isAvailabilityStaffRequirementsChanged(state),
  isFetching: appSelectors.getIsFetching(requestedExtraSessionsSingleState),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
  isSessionDetailsChanged: requestedExtraSessionsSelectors.isSessionDetailsChanged(state),
  isSubmitting: appSelectors.getIsSubmitting(requestedExtraSessionsSingleState),
  requestedExtraSession: requestedExtraSessionsSelectors.getRequestedExtraSessionSingleDataSelector(state),
})

const enhance = compose(
  withAppService,
  withModalService,
  withRequestedExtraSessionsService,
  connect(mapState),
)

export default enhance(RequestedExtraSessionsDetailsContainer)
