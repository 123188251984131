export const GET_CHILD_SESSION = 'GET_CHILD_SESSION'
export const GET_CHILD_SESSION_SUCCESS = 'GET_CHILD_SESSION_SUCCESS'
export const GET_CHILD_SESSION_FAILED = 'GET_CHILD_SESSION_FAILED'

export const CREATE_CHILD_SESSION = 'CREATE_CHILD_SESSION'
export const CREATE_CHILD_SESSION_SUCCESS = 'CREATE_CHILD_SESSION_SUCCESS'
export const CREATE_CHILD_SESSION_FAILED = 'CREATE_CHILD_SESSION_FAILED'

export const UPDATE_CHILD_SESSION = 'UPDATE_CHILD_SESSION'
export const UPDATE_CHILD_SESSION_SUCCESS = 'UPDATE_CHILD_SESSION_SUCCESS'
export const UPDATE_CHILD_SESSION_FAILED = 'UPDATE_CHILD_SESSION_FAILED'

export const ARCHIVE_CHILD_SESSION = 'ARCHIVE_CHILD_SESSION'
export const ARCHIVE_CHILD_SESSION_SUCCESS = 'ARCHIVE_CHILD_SESSION_SUCCESS'
export const ARCHIVE_CHILD_SESSION_FAILED = 'ARCHIVE_CHILD_SESSION_FAILED'

export const CLEAR_CHILD_SINGLE_SESSION = 'CLEAR_CHILD_SINGLE_SESSION'

export const getChildSession = (childId, id, params, onSuccess) => ({ childSessionsApiClient }) => (dispatch) => {
  dispatch({
    childId,
    id,
    type: GET_CHILD_SESSION,
  })

  childSessionsApiClient.get(childId, id, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: GET_CHILD_SESSION_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: GET_CHILD_SESSION_FAILED,
      })
    },
  )
}

export const createChildSession = (
  childId,
  payload,
  onSuccess,
  onConflict,
  onFailed,
) => ({ childSessionsApiClient }) => (dispatch) => {
  dispatch({
    childId,
    payload,
    type: CREATE_CHILD_SESSION,
  })

  childSessionsApiClient.create(childId, payload).then(
    (data) => {
      dispatch({
        payload: data,
        type: CREATE_CHILD_SESSION_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    (error) => {
      onFailed?.(error)

      const showConflict = error.response && error.response.code && 409 === error.response.code

      if (showConflict) {
        onConflict(error.response)

        dispatch({
          type: CREATE_CHILD_SESSION_FAILED,
        })
      } else {
        dispatch({
          error: error.response,
          type: CREATE_CHILD_SESSION_FAILED,
        })
      }
    },
  )
}

export const updateChildSession = (
  childId,
  id,
  payload,
  onSuccess,
  onConflict,
  onFailed,
) => ({ childSessionsApiClient }) => (dispatch) => {
  dispatch({
    childId,
    id,
    payload,
    type: UPDATE_CHILD_SESSION,
  })

  childSessionsApiClient.update(childId, id, payload).then(
    (data) => {
      dispatch({
        payload: data,
        type: UPDATE_CHILD_SESSION_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    (error) => {
      onFailed?.(error)

      const showConflict = error.response && error.response.code && 409 === error.response.code

      if (showConflict) {
        onConflict(error.response)

        dispatch({
          type: UPDATE_CHILD_SESSION_FAILED,
        })
      } else {
        dispatch({
          error: error.response,
          type: UPDATE_CHILD_SESSION_FAILED,
        })
      }
    },
  )
}

export const clearChildSingleSession = () => ({
  type: CLEAR_CHILD_SINGLE_SESSION,
})
