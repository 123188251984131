import { TableColumn } from 'components/Table/Table'

import i18n from 'translations'

export const COLUMNS: TableColumn[] = [
  {
    align: 'left',
    field: 'name',
    sortable: true,
    title: i18n.t('global:Description'),
  },
  {
    field: 'discount',
    title: i18n.t('module:Management:Finance:Discounts:discountAmount'),
  },
  {
    field: 'rowToButton',
  },
]
