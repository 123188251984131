import color from 'color'
import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledItems = styled.div`
  ${({ horizontal }) => horizontal && `
    display: flex;
    flex-wrap: wrap;
  `}
`

export const StyledItemContainer = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: ${({ fullWidth, isRenderItem }) => (fullWidth || isRenderItem
    ? '1fr'
    : 'minmax(auto, 300px) 10px')};
  align-items: center;

  @media(max-width: 440px) {
    grid-template-columns: auto 10px;
    
    ${({ fullWidth, isRenderItem }) => (fullWidth || isRenderItem) && `
      grid-templates-columns: auto;
    `}
  }

  ${({ lastChild }) => !lastChild && `
     margin-bottom: 12px;
  `}
  
  ${({ horizontal }) => horizontal && `
    grid-template-columns: auto;
    margin-right: 25px;
    margin-bottom: 10px;
  `}

  ${({ buttonStyle, horizontal }) => horizontal && buttonStyle && `
    margin-right: 15px;
  `}
`

export const StyledButtonStyleWrapper = styled.div`
  padding: 10px 16px 10px 12px;
  border: 2px solid ${getBrandingColor('primary-color')};
  border-radius: 24px;
  text-transform: uppercase;
  color: ${getBrandingColor('primary-color')};
  font-weight: 600;
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  
  ${({ $checked }) => $checked && `
    background: ${color(getBrandingColor('primary-color')).alpha(0.2).rgb().string()};
  `}
`
