export const SELECT_CHILDREN_TYPES = {
  ALL: 'ALL',
  GROUP: 'GROUP',
}

export const SELECT_CHILDREN_OPTIONS = [
  {
    label: 'All children',
    value: SELECT_CHILDREN_TYPES.ALL,
  },
  {
    label: 'Select a group of children',
    value: SELECT_CHILDREN_TYPES.GROUP,
  },
]
