import React from 'react'

import { ORG_SESSIONS_FILTER_OPTIONS } from 'services/legacy/organizationSessions/constants'

import { generateRoute } from 'utils/routing'

import { Callout, EmptyState, Section, Select, Spinner, Table, Toolbar, Typography } from 'components'

import i18n from 'translations'

const SettingsSessions = ({
  SettingsSessionsAddGranted,
  errorMessages,
  isEmpty,
  isFinanceV3,
  isLoading,
  nursery,
  onStatusFilterChange,
  sessions,
  statusFilter,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (isEmpty) {
      return (
        <EmptyState
          icon="sessions"
          text1="Add sessions and fees to allocate them to all of your nurseries."
        />
      )
    }

    return (
      <Table
        columns={[
          {
            align: 'left',
            field: 'name',
            title: 'Session',
          },
          {
            field: 'startTime',
            title: 'Start',
          },
          {
            field: 'endTime',
            title: 'Finish',
          },
          {
            field: 'isHourly',
            title: 'Hourly',
          },
          {
            field: 'rowToButton',
          },
        ]}
        data={sessions}
      />
    )
  }

  const actions = SettingsSessionsAddGranted && (
    <Section.Actions primary={[{ to: generateRoute('SETTINGS.SESSIONS.ADD') }]} />
  )

  return (
    <Section
      actions={actions}
      title={isFinanceV3 && nursery
        ? i18n.t('module:Settings:Sessions:List:sessionsFor', { name: nursery?.name })
        : i18n.t('module:Settings:Sessions:List:sessions')}
    >
      <Callout content={errorMessages} error />
      {!isEmpty && (
        <Typography margin="20px 0">
          This is where you can set your sessions and fees for each of your nurseries. Your sessions are listed in the
          table below - click on a session to see and set the fees for each of your nurseries. Add a new session by
          tapping the button above.
        </Typography>
      )}
      {!isEmpty && (
        <Toolbar>
          <Toolbar.Group>
            <Toolbar.Item>
              <Select
                clearable={false}
                options={ORG_SESSIONS_FILTER_OPTIONS}
                searchable={false}
                value={statusFilter}
                onChange={onStatusFilterChange}
              />
            </Toolbar.Item>
          </Toolbar.Group>
        </Toolbar>
      )}
      {renderContent()}
    </Section>
  )
}

export default SettingsSessions
