import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import selectors from './selectors'

const withNurseryPaymentTypesService = (WrappedComponent) => {
  const mapState = ({ nurseryPaymentTypes }) => ({ nurseryPaymentTypes })

  const mapDispatch = injectActions('nurseryPaymentTypesActions', actions)

  const Component = (props) => {
    const { nurseryPaymentTypes, nurseryPaymentTypesActions, ...other } = props

    return (
      <WrappedComponent
        nurseryPaymentTypesActions={nurseryPaymentTypesActions}
        nurseryPaymentTypesListState={nurseryPaymentTypes.list}
        nurseryPaymentTypesSelectors={selectors}
        nurseryPaymentTypesSingleState={nurseryPaymentTypes.single}
        {...other}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withNurseryPaymentTypesService
