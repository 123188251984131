import { ROLES } from 'constants/security'

const SecurityWrapper = ({ children }) => children

SecurityWrapper.authParams = {
  roles: [
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
  ],
}

export default SecurityWrapper
