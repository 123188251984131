import React, { useState } from 'react'

const withDailyDiaryRecordHandlers = (WrappedComponent) => (props) => {
  const [isProcessing, setIsProcessing] = useState(false)

  const handleSubmitClick = (e, { onFailed }) => {
    const { handleSubmit, item, mediaFromValues, onSubmit } = props

    if (isProcessing) {
      return false
    }

    return handleSubmit((values) => {
      setIsProcessing(true)

      return onSubmit(
        item,
        values,
        (error) => {
          setIsProcessing(false)

          if (!error) {
            onFailed(false)
          }
        },
        mediaFromValues,
      )
    })(e)
  }

  const dailyDiaryRecordHelpers = {
    handleSubmitClick,
  }

  const dailyDiaryRecordState = {
    isProcessing,
  }

  return (
    <WrappedComponent
      dailyDiaryRecordHelpers={dailyDiaryRecordHelpers}
      dailyDiaryRecordState={dailyDiaryRecordState}
      {...props}
    />
  )
}

export default withDailyDiaryRecordHandlers
