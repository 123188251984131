import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as foodTypesSelectors from './selectors'

const withFoodTypesService = (WrappedComponent) => {
  const mapState = ({ foodTypes }) => ({ foodTypes })

  const mapDispatch = injectActions('foodTypesActions', actions)

  const Component = ({ foodTypes, foodTypesActions, ...others }) => (
    <WrappedComponent
      foodTypesActions={foodTypesActions}
      foodTypesBatchState={foodTypes.batch}
      foodTypesListState={foodTypes.list}
      foodTypesSelectors={foodTypesSelectors}
      foodTypesSingleState={foodTypes.single}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withFoodTypesService
