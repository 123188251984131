import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import {
  CLEAR_ENQUIRY_STATUS_TYPES_DROPDOWN_LIST,
  ENQUIRY_STATUS_TYPES_DROPDOWN_LIST,
  ENQUIRY_STATUS_TYPES_DROPDOWN_LIST_FAILED,
  ENQUIRY_STATUS_TYPES_DROPDOWN_LIST_SUCCESS,
} from './constants'

export default (...props) => {
  const [state = getBasicListInitialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_ENQUIRY_STATUS_TYPES_DROPDOWN_LIST,
    failed: ENQUIRY_STATUS_TYPES_DROPDOWN_LIST_FAILED,
    init: ENQUIRY_STATUS_TYPES_DROPDOWN_LIST,
    success: ENQUIRY_STATUS_TYPES_DROPDOWN_LIST_SUCCESS,
  }, getBasicListInitialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
