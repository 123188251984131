import _ from 'lodash'

import React from 'react'

import { Button, Spinner, Tooltip } from 'components'

import {
  StyledBadgeContainer,
  StyledButtonContainer,
  StyledContainer,
  StyledIconContainer,
  StyledTime,
  StyledTitle,
} from './RegisterItemActionStyled'

const RegisterItemAction = ({
  badge,
  badgeColor,
  color,
  disabled,
  hasData,
  icon,
  isLoading,
  onClick,
  time,
  title,
  tooltip,
}) => {
  const renderIcon = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (time) {
      return (
        <StyledTime color={color}>
          {time}
        </StyledTime>
      )
    }

    return (
      <StyledButtonContainer>
        <Button
          color={color}
          component={disabled ? 'div' : undefined}
          disabled={disabled}
          icon={icon}
        />
        {badge && <StyledBadgeContainer color={badgeColor} />}
      </StyledButtonContainer>
    )
  }

  const debounceHandleClick = _.debounce(onClick, 300, { leading: true, trailing: false })

  const handleClick = (e) => {
    e.persist()

    debounceHandleClick()
  }

  const renderAction = () => (
    <StyledContainer
      hasTime={!!time}
      isDisabled={disabled}
      onClick={!time && !isLoading && !disabled ? handleClick : null}
    >
      <StyledIconContainer>{renderIcon()}</StyledIconContainer>
      <StyledTitle color={color} hasData={!!hasData}>
        {title}
      </StyledTitle>
    </StyledContainer>
  )

  if (tooltip) {
    return (
      <Tooltip enterTouchDelay={0} placement="top" title={tooltip}>
        {renderAction()}
      </Tooltip>
    )
  }

  return renderAction()
}

export default RegisterItemAction
