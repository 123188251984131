import _ from 'lodash'

import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router'

import colors from 'constants/colors'
import { OBSERVATION_STATUSES } from 'services/legacy/observations/constants'
import { REFERENCE_ASYNC_PAGE_TYPES } from 'services/legacy/upload/constants'

import { generateRoute } from 'utils/routing'

import {
  Avatar,
  Button,
  Callout,
  ChildrenSlider,
  DataPreview,
  DateString,
  DropdownMenu,
  EmptyState,
  Icon,
  MediaPicker,
  Page,
  Space,
  Spinner,
  StickyFooter,
  Toolbar,
} from 'components'
import Comments from 'module/LikesAndComments/components/Comments'

import i18n from 'translations'

import { OBSERVATIONS_PAGE_TYPE } from 'module/Learning/Observations/constants'

import { COMMENT_TYPE } from 'services/comments/constants'
import {
  StyledAuthor,
  StyledGoldenMoment,
  StyledLearningJourneyButton,
  StyledLearningJourneyLabel,
  StyledStar,
} from './ObservationsReviewStyled'
import { ChildObservation } from './components'
import Feedback from '../components/Feedback'

const ObservationsReviewView = ({
  activeChildIndex,
  canApproveObservation,
  canEditObservation,
  canSendObservationForApproval,
  errorMessages,
  frameworksList,
  hasParentAppAccess,
  isEmpty,
  isFetching,
  isSubmitting,
  location,
  observation,
  onApproveButtonClick,
  onCheckProcessingFiles,
  onClickSlide,
  onRemoveObservation,
  pageType,
  progressLevels,
}) => {
  const commentsRef = useRef(null)
  const {
    author,
    childObservations,
    comments,
    customMontessoriActivity,
    id,
    media,
    montessoriActivity,
    observationDate,
    status,
  } = observation || {}

  useEffect(() => {
    setTimeout(() => {
      if ('comments' === location?.query?.section) {
        commentsRef?.current?.scrollIntoView()
      }
    }, 500)
  }, [observation])

  const renderChildDetails = () => {
    const {
      child,
      comments: childComment,
      id: childObservationId,
    } = childObservations?.[activeChildIndex] || {}

    const frameworkAction = (frameworkId) => canEditObservation(observation) && (
      <Button
        hierarchy="tertiary"
        label={i18n.t('global:Edit')}
        size="small"
        to={`${pageType === OBSERVATIONS_PAGE_TYPE.APPROVALS
          ? generateRoute('APPROVALS.OBSERVATIONS.EDIT.FRAMEWORKS', {
            childObservationId,
            observationId: id,
          })
          : generateRoute('LEARNING.OBSERVATIONS.EDIT.FRAMEWORKS', {
            childObservationId,
            observationId: id,
          })}?framework=${+frameworkId}`}
        negativeMargins
      />
    )

    return (
      <React.Fragment key={child?.id}>
        <ChildObservation
          actions={frameworkAction}
          canEditObservation={canEditObservation(observation)}
          childObservationId={childObservationId}
          customMontessoriActivity={customMontessoriActivity}
          frameworksList={frameworksList}
          montessoriActivity={montessoriActivity}
          pageType={pageType}
          progressLevels={progressLevels}
        >
          <DataPreview
            content={childComment}
            title={i18n.t('module:Learning:Observations:ObservationsReview:observationAdditionalSummaryTitle')}
            whiteSpace="pre-wrap"
            withBackground
          />
          <DataPreview
            content={media?.length && (
              <MediaPicker
                referencePage={[REFERENCE_ASYNC_PAGE_TYPES.OBSERVATION, id]}
                value={media}
                preview
                onCheckProcessingFiles={onCheckProcessingFiles}
                onReloadMedia={onCheckProcessingFiles}
              />
            )}
            title={i18n.t('module:Learning:Observations:ObservationsReview:media')}
          />
        </ChildObservation>
        <div ref={commentsRef}>
          <Comments
            commentType={COMMENT_TYPE.CHILD_OBSERVATION}
            objectId={childObservationId}
            wrapper={(children) => (
              <React.Fragment>
                <Space space="20px" />
                {children}
              </React.Fragment>
            )}
            gray
          />
        </div>
      </React.Fragment>
    )
  }

  const renderGoldenMoment = () => _.map(childObservations, ({ child, sharedWithParents }, index) => (
    index === activeChildIndex
    && sharedWithParents
    && (
      <StyledStar key={`goldenMoment_${child.id}`}>
        <Icon
          color={colors.gold}
          height={24}
          icon="star"
        />
        <StyledGoldenMoment>
          Golden Moment
        </StyledGoldenMoment>
      </StyledStar>
    )))

  const renderViewLearningJourneyButton = () => {
    if (!observation || OBSERVATION_STATUSES.APPROVED.value !== status) {
      return null
    }

    const currentObservation = _.find(childObservations, (item, i) => i === activeChildIndex)

    if (!currentObservation) {
      return null
    }

    const { fromPage, fromPageLimit } = location.query

    const learningJourneyTo = {
      pathname: generateRoute('CHILDREN.CHILD.LEARNING_JOURNEY.OBSERVATIONS', {
        childId: currentObservation.child.id,
      }),
      query: {
        limit: fromPageLimit,
        page: fromPage,
      },
    }

    return (
      <StyledLearningJourneyButton>
        <Link to={learningJourneyTo}>
          <Avatar
            initials={currentObservation.child.name}
            src={currentObservation.child.photo}
          />
          <StyledLearningJourneyLabel>
            View all observations
          </StyledLearningJourneyLabel>
        </Link>
      </StyledLearningJourneyButton>
    )
  }

  const renderFooter = () => {
    if (!canApproveObservation
      && !canSendObservationForApproval
      && (!observation || OBSERVATION_STATUSES.APPROVED.value !== status)
    ) {
      return null
    }

    return (
      <StickyFooter>
        {renderViewLearningJourneyButton()}
        <StickyFooter.Flex />
        {canApproveObservation && (
          <Button
            disabled={isSubmitting}
            isLoading={isSubmitting}
            label={i18n.t('module:Learning:Observations:ObservationsReview:approveObservationButtonLabel')}
            negativeMargins
            onClick={onApproveButtonClick}
          />
        )}
        {canSendObservationForApproval && (
          <Button
            disabled={isSubmitting}
            isLoading={isSubmitting}
            label={i18n.t('module:Learning:Observations:ObservationsReview:sendForApprovalButtonLabel')}
            negativeMargins
            onClick={onApproveButtonClick}
          />
        )}
      </StickyFooter>
    )
  }

  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    if (isEmpty) {
      return (
        <EmptyState
          icon="observations"
          text1={i18n.t('module:Learning:Observations:ObservationsReview:emptyStateMessage')}
        />
      )
    }

    return (
      <React.Fragment>
        <ChildrenSlider
          childrenList={_.map(childObservations, ({ child }) => child)}
          selectedSlideIndex={activeChildIndex}
          marginBottom
          onClickSlide={onClickSlide}
        />
        {hasParentAppAccess && renderGoldenMoment()}
        <DataPreview
          content={comments}
          title={i18n.t('module:Learning:Observations:ObservationsReview:observationSummaryTitle')}
          withBackground
        />
        {!childObservations?.length && media?.length && (
          <DataPreview
            content={(
              <MediaPicker
                value={media}
                preview
                onCheckProcessingFiles={onCheckProcessingFiles}
              />
            )}
            title={i18n.t('module:Learning:Observations:ObservationsReview:media')}
          />
        )}
        {_.map(childObservations, (data, index) => (
          index === activeChildIndex && renderChildDetails()
        ))}
        {renderFooter()}
      </React.Fragment>
    )
  }

  const actions = (
    <Toolbar noMargin>
      <Toolbar.Group>
        <Toolbar.Item>
          <StyledAuthor>
            {author && `${author.fullName} `}
            <DateString date={observationDate} relative />
          </StyledAuthor>
        </Toolbar.Item>
        {canEditObservation(observation) && (
          <Toolbar.Item>
            <DropdownMenu>
              <DropdownMenu.Item
                to={pageType === OBSERVATIONS_PAGE_TYPE.APPROVALS
                  ? generateRoute('APPROVALS.OBSERVATIONS.EDIT', { observationId: id })
                  : generateRoute('LEARNING.OBSERVATIONS.EDIT', { observationId: id })}
                type="edit"
              />
              <DropdownMenu.Item
                type="delete"
                onClick={onRemoveObservation}
              />
            </DropdownMenu>
          </Toolbar.Item>
        )}
      </Toolbar.Group>
    </Toolbar>
  )

  return (
    <React.Fragment>
      <Feedback observation={observation} />
      <Page.Section
        actions={!isFetching && actions}
        isLoading={isFetching}
        title={i18n.t('module:Learning:Observations:ObservationsReview:title')}
      >
        <Callout content={errorMessages} error />
        {renderContent()}
      </Page.Section>
    </React.Fragment>
  )
}

export default ObservationsReviewView
