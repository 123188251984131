import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

import {
  addMillisecondsFromMidnight,
  convertTimeDuration,
  getTimeDifference,
  millisecondsFromMidnight,
} from 'utils/date'

import { STAFF_ENTRY_TYPES, STAFF_REGISTER_STATUS_MAPPING, WORK_SHIFT_TYPES } from '../constants'
import { findStaffStatus, getFormattedEntries, getStartAndEndDateFromTimeRange } from '../helpers'

const getMembershipRegister = (state) => state.membershipRegisters.single

export const getMembershipRegisterSingleData = createSelector([getMembershipRegister], (state) => state.data)

export const getMembershipRegisterSingleStatus = createSelector(
  [getMembershipRegister],
  (state) => state.selectedStatus,
)

export const getMembershipRegisterSingleFormattedData = createSelector(
  [getMembershipRegisterSingleData],
  (data) => {
    const status = findStaffStatus(data)

    if (!data?.entries?.length || !data?.ongoing) {
      return {
        lastEntryType: STAFF_ENTRY_TYPES.SIGN_IN,
        status,
      }
    }

    const { entries } = data
    const lastEntry = _.find(entries, ({ signOutAt }) => !signOutAt)

    return {
      ...data,
      lastEntryType: lastEntry.type,
      status,
    }
  },
)

export const getCreateEntryPayload = ({ membershipRegisterData, nurseryClass, status }) => {
  const type = STAFF_REGISTER_STATUS_MAPPING[status]

  const basePayload = {
    nurseryClass: nurseryClass ? { id: nurseryClass.value } : undefined,
    signInAt: millisecondsFromMidnight(),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    type,
  }

  if (!membershipRegisterData) {
    return {
      ...basePayload,
      register: {
        date: moment().format(DEFAULT_DATE_FORMAT),
      },
    }
  }

  const { id } = membershipRegisterData

  return {
    ...basePayload,
    register: {
      id,
    },
  }
}

export const getUpdateEntryPayload = () => ({
  signOutAt: millisecondsFromMidnight(),
})

export const getMembershipRegisterSingleWorklogData = createSelector(
  [getMembershipRegisterSingleData],
  (data) => {
    if (!data) {
      return null
    }

    const { date, entries } = data

    if (!entries?.length) {
      return data
    }

    const firstEntry = entries[0]
    const lastEntry = entries[entries.length - 1]

    const startTime = firstEntry.signInAt
    const endTime = lastEntry.signOutAt

    return {
      ...data,
      ...getStartAndEndDateFromTimeRange(date, startTime, endTime),
      entries: getFormattedEntries(date, entries),
    }
  },
)

export const getMembershipRegisterSingleWorklogInitialValues = createSelector(
  [getMembershipRegisterSingleWorklogData],
  (data) => {
    if (!data) {
      return null
    }

    const { entries } = data

    if (!entries?.length) {
      return data
    }

    return { entries }
  },
)

export const getMembershipRegisterPayload = (fields) => {
  if (!fields) {
    return null
  }

  const { changeComment, date, entries, id, membershipId } = fields

  const basePayload = {
    changeComment,
    entries: _.map(entries, ({
      id: entryId,
      nurseryClass,
      signInAt,
      signOutAt,
      timezone,
      type,
    }) => {
      const startDateTime = moment(date).startOf('day')
      const signInAtTime = signInAt.diff(startDateTime)
      const signOutAtTime = signOutAt ? signOutAt.diff(startDateTime) : undefined

      return {
        id: entryId,
        nurseryClass: nurseryClass ? { id: nurseryClass.value } : undefined,
        signInAt: signInAtTime,
        signOutAt: signOutAtTime,
        timezone: timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
        type,
      }
    }),
  }

  // Edit
  if (id) {
    return basePayload
  }

  // Add
  return {
    ...basePayload,
    date: moment(date).format(DEFAULT_DATE_FORMAT),
    membership: { id: membershipId },
  }
}

export const updateMembershipRegisterEntryEndTime = createSelector(
  [(params) => params],
  (params) => {
    const {
      entries,
      entryId,
      signOutDate,
      signOutTime,
    } = params

    const signOutAt = addMillisecondsFromMidnight(signOutTime.valueOf(), signOutDate)

    return _.map(entries, (entry) => {
      const { id } = entry

      if (id === entryId) {
        return {
          ...entry,
          signOutAt,
        }
      }

      return entry
    })
  },
)

export const getMembershipRegisterPayloadWithNewEndtime = createSelector(
  [((params) => params)],
  (params) => {
    const {
      date,
      entries,
      entryId,
      id,
      signOutDate,
      signOutTime,
    } = params

    const updatedEntries = updateMembershipRegisterEntryEndTime({
      entries,
      entryId,
      signOutDate,
      signOutTime,
    })

    return getMembershipRegisterPayload({ date, entries: updatedEntries, id })
  },
)

export const getStaffAttendancePayload = (values, currentRegisters) => {
  const body = []
  const { membership, membershipShiftTimes, reason } = values

  _.each(membershipShiftTimes, (records, groupDate) => {
    const currentRegister = _.find(currentRegisters, ({ date }) => (
      moment(date).format(DEFAULT_DATE_FORMAT) === groupDate
    ))

    const item = {
      entries: [],
    }

    if (currentRegister?.id) {
      item.id = currentRegister.id
    } else {
      item.date = groupDate
      item.membership = {
        id: membership?.value,
      }
    }

    _.each(records, (record) => {
      if (!_.keys(record).length) {
        return
      }

      const { endDateInFuture, endTime, id, location, startTime, timezone, type } = record

      const entry = {
        signInAt: millisecondsFromMidnight(startTime),
        timezone: timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
        type: type?.value || type,
      }

      if (location?.value) {
        entry.nurseryClass = {
          id: location.value,
        }
      }

      if (id) {
        entry.id = id
      }

      if (endTime) {
        let finalSignOutAt = millisecondsFromMidnight(endTime)

        if (endDateInFuture) {
          const startDate = moment(groupDate, DEFAULT_DATE_FORMAT).startOf('day')
          const endDate = moment(endTime, DEFAULT_DATE_FORMAT).startOf('day')
          const diffDays = moment(endDate).diff(startDate, 'days')

          finalSignOutAt = finalSignOutAt.add(diffDays, 'days')
        }

        entry.signOutAt = finalSignOutAt
          .valueOf()
      }

      item.entries.push(entry)
    })

    if (currentRegister?.id && reason) {
      let changesDetected = false

      // eslint-disable-next-line consistent-return
      _.each(item.entries, (entry) => {
        const previousEntry = _.find(currentRegister.entries, ({ id }) => id === entry.id) || {}

        if (
          entry.signInAt !== previousEntry.signInAt
          || entry.signOutAt !== previousEntry.signOutAt
          || entry.signOutAt !== previousEntry.signOutAt
          || entry.type !== previousEntry.type
          || entry.nurseryClass?.id !== previousEntry.nurseryClass?.id
        ) {
          changesDetected = true

          return false
        }
      })

      if (changesDetected) {
        item.changeComment = reason
      }
    }

    if (item.entries?.length) {
      body.push(item)
    }
  })

  const currentRegistersIds = _.map(currentRegisters, ({ id }) => id)
  const bodyIds = _.map(body, ({ id }) => id)
  const removedShifts = _.filter(currentRegistersIds, (id) => !_.includes(bodyIds, id))

  _.each(removedShifts, (id) => {
    body.push({
      changeComment: reason,
      entries: [],
      id,
    })
  })

  return body
}

export const getContractedHoursTotalForPeriod = ({ endDate, membershipShiftTimes, startDate }) => {
  let contractedWorkSum = 0

  let currentDate = moment(startDate).format(DEFAULT_DATE_FORMAT)

  while (currentDate <= moment(endDate).format(DEFAULT_DATE_FORMAT)) {
    contractedWorkSum += _.sumBy(
      _.filter(membershipShiftTimes[currentDate], ({
        type,
      }) => type?.value === WORK_SHIFT_TYPES.CONTRACTED || type?.value === WORK_SHIFT_TYPES.OVERTIME),
      ({ endTime, startTime }) => getTimeDifference(startTime, endTime),
    )

    currentDate = moment(currentDate).add(1, 'day')
  }

  return contractedWorkSum
}

export const getBreakHoursTotalForPeriod = ({ endDate, membershipShiftTimes, startDate }) => {
  let breakSum = 0

  let currentDate = moment(startDate).format(DEFAULT_DATE_FORMAT)

  while (currentDate <= moment(endDate).format(DEFAULT_DATE_FORMAT)) {
    breakSum += _.sumBy(
      _.filter(membershipShiftTimes[currentDate], ({ type }) => type?.value === WORK_SHIFT_TYPES.BREAK),
      ({ endTime, startTime }) => getTimeDifference(startTime, endTime),
    )

    breakSum += _.sumBy(
      _.filter(membershipShiftTimes[currentDate], ({ type }) => type?.value === WORK_SHIFT_TYPES.BREAK_DURATION),
      ({ duration: breakDuration }) => convertTimeDuration(+breakDuration, 'minutes'),
    )

    currentDate = moment(currentDate).add(1, 'day')
  }

  return breakSum
}
