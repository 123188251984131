import moment from 'moment'

import React, { useEffect, useMemo, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'
import { Option } from 'constants/models'
import { Type } from 'services/booking/periods/models'
import { PeriodTime } from 'services/periodTimes/models'
import { Ranges } from 'components/MultiRangeCalendar/MultiRangeCalendar'

import { generateRoute } from 'utils/routing'

import { withAppService, withAppServiceProps } from 'services/app'
import { withPeriodsService, withPeriodsServiceProps } from 'services/booking/periods'
import { withPeriodTimesService, withPeriodTimesServiceProps } from 'services/periodTimes'
import { withRouter, withRouterProps } from 'services/router'
import { withSnackbarService, withSnackbarServiceProps } from 'services/utils/snackbar'

import i18n from 'translations'

import SetTermDatesView from './SetTermDatesView'
import { convertData, getDefaultStartDate, getErrorsFromResponse, getMonthOptions, getYearOptions } from './helpers'

const CLOSURE_DATES_GROUPS = {
  read: ['periodTime.period', 'period'],
}

type SetTermDatesProps = withAppServiceProps
  & withPeriodsServiceProps
  & withPeriodTimesServiceProps
  & withRouterProps
  & withSnackbarServiceProps

const mapState = (state: RootState, {
  appSelectors,
  periodTimesListState,
  periodsSelectors,
  periodsSingleState,
}: SetTermDatesProps) => ({
  errorMessages: appSelectors.getErrorMessages(periodsSingleState, periodTimesListState),
  period: periodsSelectors.getPeriod(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const SetTermDatesContainer: React.FC<SetTermDatesProps & PropsFromRedux> = ({
  errorMessages,
  navigate,
  params,
  period,
  periodTimesActions,
  periodTimesSelectors,
  periodsActions,
  snackbarActions,
}) => {
  const defaultStartDate = getDefaultStartDate()

  const [defaultRanges, setDefaultRanges] = useState<Ranges[]>([])
  const [selectedRanges, setSelectedRanges] = useState<Ranges[]>([])
  const [periodTimes, setPeriodsTime] = useState<PeriodTime[]>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [errors, setErrors] = useState<string>()
  const [closureDates, setClosureDates] = useState<PeriodTime[]>()

  const [selectedYear, setSelectedYear] = useState<Option>({
    label: defaultStartDate.year(),
    value: defaultStartDate.year(),
  })
  const [selectedMonth, setSelectedMonth] = useState<Option>({
    label: defaultStartDate.format('MMMM'),
    value: defaultStartDate.month() + 1,
  })
  const yearOptions = useMemo<Option[]>(() => getYearOptions(), [])
  const monthOptions = useMemo<Option[]>(() => getMonthOptions(), [])

  useEffect(() => {
    if (params.id) {
      setIsFetching(true)
      periodsActions.get({
        params: [params.id],
      })

      const periodTimesRecursive = (apiParams, apiData = []) => {
        periodTimesActions.list({
          onFailed: () => setIsFetching(false),
          onSuccess: ({ data, meta }) => {
            const finalData = [
              ...apiData,
              ...data,
            ]

            const { limit, start, total_results: totalResults } = meta || {}

            if (start * limit < totalResults) {
              periodTimesRecursive({
                ...apiParams,
                page: (apiParams.page || 1) + 1,
              }, finalData)
            } else {
              setPeriodsTime(finalData)
              setDefaultRanges(convertData(finalData))
              setIsFetching(false)
            }
          },
          onlyData: true,
          params: apiParams,
        })
      }

      const criteria = periodTimesSelectors.getCriteria({
        periodId: params.id,
      })

      periodTimesRecursive({ criteria })
    }
  }, [])

  useEffect(() => {
    const startDate = moment({ day: 1, month: +selectedMonth.value, year: +selectedYear.value })
    const endDate = moment(startDate).add(1, 'year').add(-1, 'day')

    const criteria = periodTimesSelectors.getCriteria({
      endDate,
      startDate,
      type: Type.closure,
    })

    periodTimesActions.list({
      onSuccess: ({ data }) => setClosureDates(data),
      onlyData: true,
      params: {
        criteria,
        groups: CLOSURE_DATES_GROUPS,
      },
    })
  }, [selectedYear.value, selectedMonth.value])

  const handleMultiRangeCalendarChange = (ranges: Ranges[]) => {
    setSelectedRanges(ranges || [])
  }

  const handleMonthChange = (month: Option) => {
    setSelectedMonth(month)
  }

  const handleYearChange = (year: Option) => {
    setSelectedYear(year)
  }

  const handleSubmitSuccess = ({ data }) => {
    setIsSubmitting(false)

    const hasError = data.some(({ exception }) => !!exception)

    if (!hasError) {
      snackbarActions.show({
        message: i18n.t('module:Management:AttendancePeriods:SetTermDates:snackbarLabel'),
      })

      navigate(generateRoute('MANAGEMENT.ATTENDANCE_PERIODS'))

      return
    }

    setErrors(getErrorsFromResponse(data))
  }

  const handleSubmit = () => {
    setIsSubmitting(true)
    setErrors('')

    const body = periodTimesSelectors.getBatchBody({
      period,
      periodTimes,
      selectedRanges,
    })

    periodTimesActions.batch({
      body,
      onFailed: () => setIsSubmitting(false),
      onSuccess: handleSubmitSuccess,
    })
  }

  return (
    <SetTermDatesView
      closureDates={closureDates}
      defaultRanges={defaultRanges}
      errorMessages={errorMessages || errors}
      isLoading={isFetching}
      isSubmitting={isSubmitting}
      monthOptions={monthOptions}
      period={period}
      selectedMonth={selectedMonth}
      selectedYear={selectedYear}
      yearOptions={yearOptions}
      onMonthChange={handleMonthChange}
      onMultiRangeCalendarChange={handleMultiRangeCalendarChange}
      onSubmit={handleSubmit}
      onYearChange={handleYearChange}
    />
  )
}

const enhance = compose(
  withAppService,
  withRouter,
  withPeriodsService,
  withPeriodTimesService,
  withSnackbarService,
  connector,
)

export default enhance(SetTermDatesContainer)
