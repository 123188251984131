import React, { useEffect } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'

import { withNurseriesService, withNurseriesServiceProps } from 'services/nurseries'
import { withNurseryContextService, withNurseryContextServiceProps } from 'services/nurseryContext'
import { withAppService, withAppServiceProps } from 'services/app'
import { withRouter, withRouterProps } from 'services/router'

import ReferenceCodesView from './ReferenceCodeGenerationView'
import { GROUPS_NURSERY_SETTINGS } from './Edit/EditContainer'

type ReferenceCodeGenerationContainerProps = withAppServiceProps
  & withRouterProps
  & withNurseriesServiceProps
  & withNurseryContextServiceProps

const mapState = (state: RootState, {
  appSelectors,
  nurseriesSelectors,
  nurseriesSingleState,
  params,
}: ReferenceCodeGenerationContainerProps) => ({
  errorMessages: appSelectors.getErrorMessages(nurseriesSingleState),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  referenceCodeGeneration: nurseriesSelectors.getReferenceCodeGeneration(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const ReferenceCodeGenerationContainer: React.FC<
  ReferenceCodeGenerationContainerProps & PropsFromRedux
> = ({
  isFetching,
  nurseriesActions,
  nurseryOptions,
  referenceCodeGeneration,
}) => {
  useEffect(() => {
    nurseriesActions.get(nurseryOptions?.id, {
      params: { groups: GROUPS_NURSERY_SETTINGS },
    })
  }, [])

  return (
    <ReferenceCodesView
      isFetching={isFetching}
      referenceCodeGeneration={referenceCodeGeneration}
    />
  )
}

const enhance = compose(
  withAppService,
  withNurseryContextService,
  withNurseriesService,
  withRouter,
  connector,
)

export default enhance(ReferenceCodeGenerationContainer)
