import React, { PropsWithChildren } from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { CursorTypes } from 'constants/css'

import { StyledContainer, StyledSmallTextContainer } from './LabelStyled'

export interface LabelProps {
  color?: string
  cursor?: CursorTypes
  disabled?: boolean
  labelBold?: boolean
  labelSize?: number
  onClick?: () => {}
  smallText?: string | React.ReactNode
  text: string
}

const Label: React.FC<PropsWithChildren<LabelProps>> = ({
  text,
  labelBold,
  labelSize,
  smallText,
  color = NEUTRAL_COLOURS.BASIC,
  onClick,
  cursor,
  disabled,
}) => (
  <StyledContainer
    $cursor={cursor}
    $disabled={disabled}
    $labelBold={labelBold}
    $labelColor={color}
    $labelSize={labelSize}
    onClick={onClick}
  >
    {text}
    {smallText && (
      <StyledSmallTextContainer>
        {smallText}
      </StyledSmallTextContainer>
    )}
  </StyledContainer>
)

export default Label
