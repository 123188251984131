import _ from 'lodash'
import { createSelector } from 'reselect'

import nurseriesConstants from 'services/nurseries/constants'
import { GLOBAL_SETTINGS } from 'services/organization/constants'
import {
  ASSESSMENT_PERIOD_CUSTOM_VALUE,
  FORMATIVE_REPORT_TRACKING_MODE,
} from 'services/legacy/formativeReports/constants'
import { ITEM_CATEGORIES } from 'module/Management/ManagementFinanceSettings/ManagementAccountCodes/constants'
import { InvoiceNumberSettings } from 'services/organization/models'

import { getNurseriesSingleDataSelector, getNurserySettings, getOrganizationSettings } from './single'

export const getNurserySecuritySettings = createSelector(
  [getNurserySettings],
  (nurserySettings) => {
    if (!nurserySettings?.security) {
      return null
    }

    return nurserySettings.security
  },
)

export const isSecuritySettingsInheritedFromOrganization = createSelector(
  [getNurseriesSingleDataSelector],
  (nurseryData) => !!(nurseryData?.organization?.organizationSettings?.security?.globalInheritance),
)

export const getNurseryInvoiceSettings = createSelector(
  [getNurserySettings],
  (nurserySettings) => {
    if (!nurserySettings || !nurserySettings.invoice) {
      return null
    }

    return nurserySettings.invoice
  },
)

export const getDueDate = createSelector(
  [getNurseryInvoiceSettings],
  (nurseryInvoiceSettings) => {
    if (!nurseryInvoiceSettings || !nurseryInvoiceSettings.dueDate) {
      return null
    }

    const { dueDate } = nurseryInvoiceSettings

    return dueDate
  },
)

export const isGroupedInvoice = createSelector(
  [getNurseryInvoiceSettings],
  (nurseryInvoiceSettings) => {
    if (!nurseryInvoiceSettings || !nurseryInvoiceSettings.invoiceLevelDetailDisplay) {
      return null
    }

    const { invoiceLevelDetailDisplay } = nurseryInvoiceSettings
    const { INVOICE_LEVEL_DETAIL_DISPLAY } = nurseriesConstants

    return invoiceLevelDetailDisplay === INVOICE_LEVEL_DETAIL_DISPLAY.GROUPED_ITEM_TYPES_WITHOUT_COST_BREAKDOWN
  },
)

export const isSessionAverageCostSelected = createSelector(
  [getNurseryInvoiceSettings],
  (nurseryInvoiceSettings) => {
    if (!nurseryInvoiceSettings || !nurseryInvoiceSettings.sessionCalculation) {
      return null
    }

    const { sessionCalculation } = nurseryInvoiceSettings
    const { SESSION_CALCULATION } = nurseriesConstants

    return sessionCalculation === SESSION_CALCULATION.AVERAGE_COST
  },
)

export const isExtraItemAverageCostSelected = createSelector(
  [getNurseryInvoiceSettings],
  (nurseryInvoiceSettings) => {
    if (!nurseryInvoiceSettings || !nurseryInvoiceSettings.extraItemsCalculation) {
      return null
    }

    const { extraItemsCalculation } = nurseryInvoiceSettings
    const { EXTRA_ITEMS_CALCULATION } = nurseriesConstants

    return extraItemsCalculation === EXTRA_ITEMS_CALCULATION.AVERAGE_COST
  },
)

export const getOrganizationInvoiceSettings = createSelector(
  [getOrganizationSettings],
  (organizationSettings) => {
    if (!organizationSettings || !organizationSettings.invoice) {
      return null
    }

    return organizationSettings.invoice
  },
)

export const getOrgGlobalSettingType = createSelector(
  [getOrganizationInvoiceSettings],
  (orgInvoiceSettings) => {
    if (!orgInvoiceSettings || !orgInvoiceSettings.globalInheritanceType) {
      return null
    }

    return orgInvoiceSettings.globalInheritanceType
  },
)

export const isGlobalSettingEditable = createSelector(
  [getOrgGlobalSettingType],
  (organizationGlobalType) => {
    if (!organizationGlobalType) {
      return false
    }

    return organizationGlobalType === GLOBAL_SETTINGS.APPLY_NON_CUSTOMIZABLE
  },
)

export const isGlobalSettingCustomizable = createSelector(
  [getOrgGlobalSettingType],
  (organizationGlobalType) => {
    if (!organizationGlobalType) {
      return false
    }

    return organizationGlobalType === GLOBAL_SETTINGS.APPLY_CUSTOMIZABLE
  },
)

export const getInvoiceNumberSettings = createSelector(
  [getOrganizationInvoiceSettings],
  (organizationInvoiceSettings) => {
    if (!organizationInvoiceSettings || !organizationInvoiceSettings.invoiceNumbers) {
      return {}
    }

    return organizationInvoiceSettings.invoiceNumbers
  },
)

export const getOrganizationGlobalNumberType = createSelector(
  [getInvoiceNumberSettings],
  (orgInvoiceNumbersSettings) => {
    if (!orgInvoiceNumbersSettings || !orgInvoiceNumbersSettings.invoiceNumbersSequencingType) {
      return null
    }

    return orgInvoiceNumbersSettings.invoiceNumbersSequencingType
  },
)

export const getNurseryInvoiceNumberSettings = createSelector(
  [getNurseryInvoiceSettings],
  (nurseryInvoiceSettings) => {
    if (!nurseryInvoiceSettings || !nurseryInvoiceSettings.invoiceNumbers) {
      return {}
    }

    return nurseryInvoiceSettings.invoiceNumbers
  },
)

export const getNumberWithPrefix = (prefix, number, pad = null) => {
  if (!number) {
    return prefix
  }

  // NOTE: https://blossomeducational.atlassian.net/browse/BLOSS-962
  // const formattedNumber = number.toString().padStart(6, '0')
  const formattedNumber = pad ? number.toString().padStart(6, '0') : number.toString()

  if (!prefix) {
    return formattedNumber
  }

  return `${prefix}-${formattedNumber}`
}

export interface NurseryInvoiceNumberWithDescriptionsProps extends InvoiceNumberSettings {
  creditNoteNumberDescription?: string
  invoiceNumberDescription?: string
  receiptNumberDescription?: string
}

export const getNurseryInvoiceNumberWithDescriptions = createSelector(
  [getNurseryInvoiceNumberSettings],
  (nurseryInvoiceNumbersSettings): NurseryInvoiceNumberWithDescriptionsProps => {
    if (!nurseryInvoiceNumbersSettings) {
      return {}
    }

    const {
      creditNoteNumber,
      creditNoteNumberPrefix = '',
      invoiceNumber,
      invoiceNumberPrefix = '',
      receiptNumber,
      receiptNumberPrefix = '',
    } = nurseryInvoiceNumbersSettings

    const invoiceNumberDescription = getNumberWithPrefix(invoiceNumberPrefix, invoiceNumber)
    const receiptNumberDescription = getNumberWithPrefix(receiptNumberPrefix, receiptNumber)
    const creditNoteNumberDescription = getNumberWithPrefix(creditNoteNumberPrefix, creditNoteNumber)

    return {
      ...nurseryInvoiceNumbersSettings,
      creditNoteNumberDescription,
      invoiceNumberDescription,
      receiptNumberDescription,
    }
  },
)

export const getSessionCalculationWeeksAndDays = createSelector(
  [getNurseryInvoiceSettings],
  (nurseryInvoiceSettings) => {
    if (
      !nurseryInvoiceSettings
      || (!nurseryInvoiceSettings.sessionCalculationWeeks && !nurseryInvoiceSettings.sessionCalculationDays)
    ) {
      return {
        days: 0,
        weeks: 0,
      }
    }

    const { sessionCalculationDays, sessionCalculationWeeks } = nurseryInvoiceSettings

    return {
      days: sessionCalculationDays || 0,
      weeks: sessionCalculationWeeks || 0,
    }
  },
)

export const getExtraItemCalculation = createSelector(
  [getNurseryInvoiceSettings],
  (nurseryInvoiceSettings) => {
    if (!nurseryInvoiceSettings || !nurseryInvoiceSettings.extraItemsCalculation) {
      return null
    }

    const { extraItemsCalculation } = nurseryInvoiceSettings

    return extraItemsCalculation
  },
)

export const getNurseryLearningSettings = createSelector(
  [getNurserySettings],
  (nurserySettings) => {
    if (!nurserySettings || !nurserySettings.learning) {
      return null
    }

    const copyNurserySettings = { ...nurserySettings }

    if (copyNurserySettings.learning && !copyNurserySettings.learning.formativeReportsTrackingMode) {
      copyNurserySettings.learning.formativeReportsTrackingMode = FORMATIVE_REPORT_TRACKING_MODE.MANUAL
    }

    return copyNurserySettings.learning
  },
)

export const getNurseryOccupancySettings = createSelector(
  [getNurserySettings],
  (nurserySettings) => {
    if (!nurserySettings?.occupancy) {
      return null
    }

    return nurserySettings.occupancy
  },
)

export const getNurseryStaffRotaSettings = createSelector(
  [getNurserySettings],
  (nurserySettings) => {
    if (!nurserySettings?.staffRota) {
      return null
    }

    return nurserySettings.staffRota
  },
)

export const getNurseryMessagingSettings = createSelector(
  [getNurserySettings],
  (nurserySettings) => {
    if (!nurserySettings?.messaging) {
      return null
    }

    return nurserySettings.messaging
  },
)

export const getShareObservationWithParents = createSelector(
  [getNurseryLearningSettings],
  (nurseryLearningSettings) => nurseryLearningSettings && nurseryLearningSettings.shareAllObservationsWithParents,
)

export const getAssessmentPeriodsDropdownWithoutCustom = createSelector(
  [getNurseryLearningSettings],
  (settings) => (settings && settings.assessmentPeriods && (
    _.map(settings.assessmentPeriods, ({ age, id, label }) => ({ age, id, label, value: id }) || {})
  )),
)

export const getAssessmentPeriodsDropdown = createSelector(
  [getAssessmentPeriodsDropdownWithoutCustom],
  (assessmentPeriods) => (assessmentPeriods && ([
    ...assessmentPeriods,
    ASSESSMENT_PERIOD_CUSTOM_VALUE,
  ])) || [],
)

export const getAssessmentPeriodsAsLabelValueDropdown = createSelector(
  [getAssessmentPeriodsDropdown],
  (assessmentPeriods) => _.map(assessmentPeriods, ({ label }) => ({
    label,
    value: label,
  })),
)

export const getAccountCodes = createSelector(
  [getNurseryInvoiceSettings],
  (nurseryInvoiceSettings) => {
    if (!nurseryInvoiceSettings || !nurseryInvoiceSettings.accountCodes) {
      return null
    }

    return nurseryInvoiceSettings.accountCodes
  },
)

export const getAccountCodesPayload = ({ accountCode, isFinanceV3Enabled, nurserySettings }) => {
  const accountCodes = {}
  _.forEach(ITEM_CATEGORIES(isFinanceV3Enabled), ({ value }) => {
    accountCodes[value] = nurserySettings?.invoice?.accountCodes?.[value] || ''
  })

  return {
    nurserySettings: {
      ...nurserySettings,
      invoice: {
        ...(nurserySettings?.invoice || {}),
        accountCodes: {
          ...accountCodes,
          ...accountCode,
        },
      },
    },
  }
}

export const getEnquiryPayload = ({ nurserySettings, payload }) => ({
  nurserySettings: {
    enquiry: {
      ...payload,
    },
    id: nurserySettings.id,
  },
})

export const getEnquirySettingsInitialValues = createSelector(
  [getNurserySettings],
  (nurserySettings) => {
    if (!nurserySettings || !nurserySettings.enquiry) {
      return null
    }

    const { customThankYouPage, redirectUrl, useCustomThankYouPage } = nurserySettings.enquiry

    return {
      customThankYouPage,
      redirectUrl,
      thankYouPageOption: useCustomThankYouPage
        ? nurseriesConstants.FORM_THANKYOU_PAGE_TYPE.CUSTOM
        : nurseriesConstants.FORM_THANKYOU_PAGE_TYPE.DEFAULT,
    }
  },
)

export const getNurseryRequestableExtraSessionsSettings = createSelector(
  [getNurserySettings],
  (nurserySettings) => {
    if (!nurserySettings?.requestExtraSession) {
      return null
    }

    return nurserySettings.requestExtraSession
  },
)

export const getEnableRequestableExtraSessionsPayload = ({ enable, nurserySettings }) => ({
  nurserySettings: {
    ...nurserySettings,
    requestExtraSession: {
      ...nurserySettings?.requestExtraSession,
      enable,
    },
  },
})

export const getRequestableExtraSessionsPayload = ({ nurserySettings, values }) => {
  const { requestExtraSession } = nurserySettings
  const {
    enable,
    holidaysAndSickLeaveAsAvailableSpaces,
    maxAdvanceRequestsDays,
    minRequestNoticeDays,
    notNeedAnAdditionalStaffMember,
  } = values

  return ({
    nurserySettings: {
      ...nurserySettings,
      requestExtraSession: {
        ...requestExtraSession,
        enable,
        holidaysAndSickLeaveAsAvailableSpaces,
        maxAdvanceRequestsDays: Number(maxAdvanceRequestsDays),
        minRequestNoticeDays: Number(minRequestNoticeDays),
        notNeedAnAdditionalStaffMember,
      },
    },
  })
}

export const getRequestableExtraSessionsInitialValues = ({ requestableExtraSessions, sessions }) => ({
  ...requestableExtraSessions,
  requestableSessions: sessions,
})

export const getAnnualisedPayload = (fields, nurserySettings) => {
  const {
    allowOverwrite,
    annualisedInvoiceSettingsAppliedFromGlobal,
    excludedMonths,
    months,
    weeks,
  } = fields

  return {
    nurserySettings: {
      id: nurserySettings.id,
      invoice: {
        ...nurserySettings.invoice,
        annualisedInvoiceSettings: {
          allowOverwriteOnChildLevel: allowOverwrite,
          excludedMonths: _.times(12 - (+months), (index) => excludedMonths[index].value),
          months: +months,
          weeks: +weeks,
        },
        annualisedInvoiceSettingsAppliedFromGlobal,
        invoiceNumbers: {
          ...nurserySettings.invoice.invoiceNumbers,
          creditNoteNumber: BigInt(nurserySettings.invoice.invoiceNumbers.creditNoteNumber),
          invoiceNumber: BigInt(nurserySettings.invoice.invoiceNumbers.invoiceNumber),
          receiptNumber: BigInt(nurserySettings.invoice.invoiceNumbers.receiptNumber),
        },
      },
    },
  }
}
