import i18n from 'translations'

import TopOutstandingAccountsWidget from './TopOutstandingAccountsWidgetContainer'

export const WIDGET_NAME = {
  component: TopOutstandingAccountsWidget,
  id: 'TOP_OUTSTANDING_ACCOUNTS_WIDGET',
  label: i18n.t('module:Dashboard:Widgets:TopOutstandingAccountsWidget:titleInSettings'),
}

export default TopOutstandingAccountsWidget
