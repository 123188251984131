import React from 'react'

import AdminDashboard from './AdminDashboard'
import NurseryDashboard from './NurseryDashboard'
import OrganizationDashboard from './OrganizationDashboard'

const DashboardView = ({
  hasOrganizationAccess,
  isAdministrationContext,
  isOrganizationContext,
  isSuperAdmin,
}) => {
  const renderContent = () => {
    if (isSuperAdmin && isAdministrationContext) {
      return (
        <AdminDashboard />
      )
    }

    if (isOrganizationContext && hasOrganizationAccess) {
      return (
        <OrganizationDashboard />
      )
    }

    return (
      <NurseryDashboard />
    )
  }

  return renderContent()
}

export default DashboardView
