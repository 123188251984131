import { generateBasicActions } from 'utils/service'

import {
  CLEAR_LIST_CHILD_OBSERVATIONS,
  LIST_CHILD_OBSERVATIONS,
  LIST_CHILD_OBSERVATIONS_FAILED,
  LIST_CHILD_OBSERVATIONS_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ childObservationsApiClient }) => generateBasicActions.list({
  constants: {
    failed: LIST_CHILD_OBSERVATIONS_FAILED,
    init: LIST_CHILD_OBSERVATIONS,
    success: LIST_CHILD_OBSERVATIONS_SUCCESS,
  },
  options,
  service: childObservationsApiClient,
  serviceMethod: 'list',
})

export const clearList = () => ({
  type: CLEAR_LIST_CHILD_OBSERVATIONS,
})
