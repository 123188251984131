import styled from 'styled-components'
import { Property } from 'csstype'

import { Link } from 'react-router'

import layout from 'constants/layout'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

interface StyledBoxContainerProps {
  $marginBottom?: number
  $withShadow?: boolean
}

export const StyledBoxContainer = styled.article<StyledBoxContainerProps>`
  display: flex;
  flex-direction: column;
  border-radius: ${layout.borderRadius};
  margin-bottom: 20px;
  
  ${({ $withShadow }) => $withShadow && `
    box-shadow: ${layout.shadow};
  `}
  
  ${({ $marginBottom }) => $marginBottom && `
    margin-bottom: ${$marginBottom}px;
  `}
`

interface StyledBoxLinkContainerProps {
  $marginBottom?: number
  $withShadow?: boolean
}

export const StyledBoxLinkContainer = styled(Link)<StyledBoxLinkContainerProps>`
  display: flex;
  flex-direction: column;
  border-radius: ${layout.borderRadius};
  margin-bottom: 20px;
  color: inherit;
  
  &:hover, &:focus {
    color: inherit;
  }
  
  ${({ $marginBottom }) => $marginBottom && `
    margin-bottom: ${$marginBottom}px;
  `}
  
  ${({ $withShadow }) => $withShadow && `
    box-shadow: ${layout.shadow};
  `}
`

interface StyledHeaderProps {
  $headerAlign?: Property.JustifyContent
  $headerBackground?: Property.Background
  $headerFontSize?: number
}

export const StyledHeader = styled.div<StyledHeaderProps>`
  padding: 14px 20px;
  display: flex;
  align-items: center;
  border-top-left-radius: ${layout.borderRadius};
  border-top-right-radius: ${layout.borderRadius};
  background: ${getBrandingColor('primary-color')};
  color: ${NEUTRAL_COLOURS.WHITE};
  font-weight: 600;
  transition: .1s;
  min-height: 52px;
  
  ${({ $headerAlign }) => $headerAlign && `
    justify-content: ${$headerAlign};
  `}
  
  ${({ $headerBackground }) => $headerBackground && `
    background: ${$headerBackground};
  `}

  ${({ $headerFontSize }) => $headerFontSize && `
    font-size: ${$headerFontSize}px;
  `}
`

interface StyledContentProps {
  $hasHeader?: boolean
  $white?: boolean
  $withPadding?: boolean
}

export const StyledContent = styled.div<StyledContentProps>`
  background: #F5F5F5;
  border-bottom-left-radius: ${layout.borderRadius};
  border-bottom-right-radius: ${layout.borderRadius};
  flex: 1;
  
  ${({ $white }) => $white && `
    background: ${NEUTRAL_COLOURS.WHITE};
  `}

  ${({ $hasHeader }) => !$hasHeader && `
    border-top-left-radius: ${layout.borderRadius};
    border-top-right-radius: ${layout.borderRadius};
  `}
  
  ${({ $withPadding }) => $withPadding && `
    padding: 20px;
    
    @media(max-width: 480px) {
      padding: 10px;
    }
  `}
`

export const StyledActions = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;

  * {
    font-size: 24px !important;
    height: 24px;
    color: ${NEUTRAL_COLOURS.WHITE} !important;
  }
`
