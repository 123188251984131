import React from 'react'
import i18n from 'translations'

import { generateRoute } from 'utils/routing'

import colors from 'constants/colors'

import { Callout, ConfirmedIcon, FolderList, Icon, Page, Section, Spinner } from 'components'

import ContentContainer from './components/ContentContainer'
import ProgressBarWithCopy from './components/ProgressBarWithCopy'
import TopTip from './components/TopTip'
import Header from './components/Header'

const ManagementGettingStartedView = ({
  ManagementConsumablesGranted,
  ManagementFundingGranted,
  OccupancyGranted,
  dashboardIcon,
  dashboardTitle,
  errorMessages,
  isLoading,
  statistics,
  statisticsSummary,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    const {
      authorisedDevices,
      children,
      confirmedRoomMoves,
      consumables,
      defaultRoomMoves,
      frameworks,
      funding,
      invoiceSettings,
      nurseryInformation,
      parents,
      rooms,
      sessions,
      staff,
      termAndClosureDates,
    } = statistics || {}

    const {
      completePercentage,
      nurseryBasicStatistics,
      nurseryFinanceStatistics,
      nurseryOperationStatistics,
    } = statisticsSummary || {}

    return (
      <ContentContainer>
        <Header dashboardIcon={dashboardIcon} dashboardTitle={dashboardTitle} />
        <ProgressBarWithCopy width={completePercentage || 0} />
        <TopTip />
        <Section
          actions={(<ConfirmedIcon isCompleted={nurseryBasicStatistics} />)}
          title={i18n.t('module:Management:GettingStarted:Basic:title')}
        >
          <FolderList>
            <FolderList.Item
              actions={(
                <ConfirmedIcon
                  isCompleted={nurseryInformation}
                  tooltip={i18n.t('module:Management:GettingStarted:Basic:Nursery:tooltip')}
                />
              )}
              badge={<Icon color={colors.gold} height={18} icon="star" />}
              icon="nursery"
              title={i18n.t('module:Management:GettingStarted:Basic:Nursery:title')}
              to={generateRoute('MANAGEMENT.NURSERY')}
            />
            <FolderList.Item
              actions={(
                <ConfirmedIcon
                  isCompleted={frameworks}
                  tooltip={i18n.t('module:Management:GettingStarted:Basic:Curriculum:tooltip')}
                />
              )}
              badge={<Icon color={colors.gold} height={18} icon="star" />}
              icon="framework"
              title={i18n.t('module:Management:GettingStarted:Basic:Curriculum:title')}
              to={generateRoute('MANAGEMENT.LEARNING.CURRICULUMS')}
            />
            <FolderList.Item
              actions={(
                <ConfirmedIcon
                  isCompleted={termAndClosureDates}
                  tooltip={i18n.t('module:Management:GettingStarted:Basic:NurseryClosureDates:tooltip')}
                />
              )}
              badge={<Icon color={colors.gold} height={18} icon="star" />}
              icon="blocked"
              title={i18n.t('module:Management:GettingStarted:Basic:NurseryClosureDates:title')}
              to={generateRoute('MANAGEMENT.CLOSURE_DAYS.CLOSURE_DATES')}
            />
            <FolderList.Item
              actions={(
                <ConfirmedIcon
                  isCompleted={termAndClosureDates}
                  tooltip={i18n.t('module:Management:GettingStarted:Basic:AttendancePeriods:tooltip')}
                />
              )}
              badge={<Icon color={colors.gold} height={18} icon="star" />}
              icon="calendar"
              title={i18n.t('module:Management:GettingStarted:Basic:AttendancePeriods:title')}
              to={generateRoute('MANAGEMENT.ATTENDANCE_PERIODS')}
            />
            <FolderList.Item
              actions={(
                <ConfirmedIcon
                  isCompleted={sessions}
                  tooltip={i18n.t('module:Management:GettingStarted:Basic:Sessions:tooltip')}
                />
              )}
              badge={<Icon color={colors.gold} height={18} icon="star" />}
              icon="sessions"
              title={i18n.t('module:Management:GettingStarted:Basic:Sessions:title')}
              to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.SESSIONS')}
            />
            <FolderList.Item
              actions={(
                <ConfirmedIcon
                  isCompleted={rooms}
                  tooltip={i18n.t('module:Management:GettingStarted:Basic:Rooms:tooltip')}
                />
              )}
              badge={<Icon color={colors.gold} height={18} icon="star" />}
              icon="rooms"
              title={i18n.t('module:Management:GettingStarted:Basic:Rooms:title')}
              to={generateRoute('ROOMS.LIST')}
            />
            <FolderList.Item
              actions={(
                <ConfirmedIcon
                  isCompleted={children}
                  tooltip={i18n.t('module:Management:GettingStarted:Basic:Child:tooltip')}
                />
              )}
              badge={<Icon color={colors.gold} height={18} icon="star" />}
              icon="children"
              title={i18n.t('module:Management:GettingStarted:Basic:Child:title')}
              to="/children"
            />
            <FolderList.Item
              actions={(
                <ConfirmedIcon
                  isCompleted={parents}
                  tooltip={i18n.t('module:Management:GettingStarted:Basic:Parent:tooltip')}
                />
              )}
              badge={<Icon color={colors.gold} height={18} icon="star" />}
              icon="parent"
              title={i18n.t('module:Management:GettingStarted:Basic:Parent:title')}
              to="/children"
            />
            <FolderList.Item
              actions={(
                <ConfirmedIcon
                  isCompleted={staff}
                  tooltip={i18n.t('module:Management:GettingStarted:Basic:Staff:tooltip')}
                />
              )}
              badge={<Icon color={colors.gold} height={18} icon="star" />}
              icon="staff"
              title={i18n.t('module:Management:GettingStarted:Basic:Staff:title')}
              to={generateRoute('STAFF.ADD')}
            />
          </FolderList>
        </Section>
        <Section
          actions={<ConfirmedIcon isCompleted={nurseryFinanceStatistics} />}
          title={i18n.t('module:Management:GettingStarted:Finance:title')}
        >
          <FolderList>
            <FolderList.Item
              actions={(
                <ConfirmedIcon
                  isCompleted={invoiceSettings}
                  tooltip={i18n.t('module:Management:GettingStarted:Finance:Invoice:tooltip')}
                />
                )}
              icon="invoices"
              title={i18n.t('module:Management:GettingStarted:Finance:Invoice:title')}
              to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.INVOICES')}
            />
            {ManagementConsumablesGranted && (
              <FolderList.Item
                actions={(
                  <ConfirmedIcon
                    isCompleted={consumables}
                    tooltip={i18n.t('module:Management:GettingStarted:Finance:Consumables:tooltip')}
                  />
              )}
                icon="consumables"
                title={i18n.t('module:Management:GettingStarted:Finance:Consumables:title')}
                to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.CONSUMABLES')}
              />
            )}
            {ManagementFundingGranted && (
              <FolderList.Item
                actions={(
                  <ConfirmedIcon
                    isCompleted={funding}
                    tooltip={i18n.t('module:Management:GettingStarted:Finance:Funding:tooltip')}
                  />
                )}
                icon="funding"
                title={i18n.t('module:Management:GettingStarted:Finance:Funding:title')}
                to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.FUNDING')}
              />

            )}
          </FolderList>
        </Section>
        <Section
          actions={<ConfirmedIcon isCompleted={nurseryOperationStatistics} />}
          title={i18n.t('module:Management:GettingStarted:Operation:title')}
        >
          <FolderList>
            {OccupancyGranted && (
              <FolderList.Item
                actions={(
                  <ConfirmedIcon
                    isCompleted={defaultRoomMoves}
                    tooltip={i18n.t('module:Management:GettingStarted:Operation:DefaultRoomMoves:tooltip')}
                  />
                )}
                icon="default-room-moves"
                title={i18n.t('module:Management:GettingStarted:Operation:DefaultRoomMoves:title')}
                to={generateRoute('ROOMS.DEFAULT_ROOM_MOVES')}
              />
            )}
            {OccupancyGranted && (
              <FolderList.Item
                actions={(
                  <ConfirmedIcon
                    isCompleted={confirmedRoomMoves}
                    tooltip={i18n.t('module:Management:GettingStarted:Operation:ConfirmRoomMove:tooltip')}
                  />
                )}
                icon="room-moves"
                title={i18n.t('module:Management:GettingStarted:Operation:ConfirmRoomMove:title')}
                to={generateRoute('ROOMS.ROOMS_MOVES')}
              />
            )}
            <FolderList.Item
              actions={(
                <ConfirmedIcon
                  isCompleted={authorisedDevices}
                  tooltip={i18n.t('module:Management:GettingStarted:Operation:AuthorisedDevices:tooltip')}
                />
              )}
              icon="authorised-devices"
              title={i18n.t('module:Management:GettingStarted:Operation:AuthorisedDevices:title')}
              to={generateRoute('MANAGEMENT.SECURITY.DEVICES')}
            />
          </FolderList>
        </Section>
      </ContentContainer>
    )
  }

  return (
    <Page.Section
      isLoading={isLoading}
      title={i18n.t('module:Management:GettingStarted:title')}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default ManagementGettingStartedView
