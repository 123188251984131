import moment from 'moment'

import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { compose } from 'recompose'

import { Option } from 'components/InfiniteDropdowns/InfiniteDropdownsModel'

import { isRequired, isRequiredDateRange } from 'utils/fieldValidation'
import { convertTimeDuration } from 'utils/date'

import { Form } from 'components'

import i18n from 'translations'

export const FUNDING_LOOP_EXPORT_MODAL_FORM = 'FundingLoopExportModalForm'

export interface FundingLoopExportModalFormValues {
  dateRange?: (moment.Moment | Date)[]
  dateRangeWeek?: (moment.Moment | Date)[]
  sendTo?: Option
}

interface FundingLoopExportModalFormProps {
  isSubmitting: boolean
  onCancelClick: () => void
  onSubmit: (fields: FundingLoopExportModalFormValues) => void
}

type FinalProps = InjectedFormProps<FundingLoopExportModalFormValues, FundingLoopExportModalFormProps>
  & FundingLoopExportModalFormProps

const FundingLoopExportModalForm: React.FC<FinalProps> = ({
  handleSubmit,
  isSubmitting,
  onCancelClick,
  onSubmit,
}) => {
  const renderForm = () => (
    <React.Fragment>
      <Form.Row
        label={i18n.t('module:Modals:FundingLoopExportModal:Form:FundingPeriod:label')}
        width={{ field: 'auto' }}
        verticalLabel
      >
        <Field
          component={Form.DatePicker}
          name="dateRange"
          validate={[isRequiredDateRange]}
          range
        />
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Modals:FundingLoopExportModal:Form:HeadCountWeek:label')}
        width={{ field: 'auto' }}
        verticalLabel
      >
        <Field
          component={Form.DatePicker}
          name="dateRangeWeek"
          type="week"
          hideNavigationButton
          range
        />
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Modals:ChildrenExport:sendTo')}
        width={{ field: 'auto' }}
        verticalLabel
      >
        <Field
          component={Form.InfiniteDropdowns.OrganizationMemberships}
          name="sendTo"
          placeholder={i18n.t('module:Modals:ExportReportModal:User:placeholder')}
          validate={isRequired}
        />
      </Form.Row>
    </React.Fragment>
  )

  const renderFooter = () => (
    <Form.FooterActions
      isSubmitting={isSubmitting}
      submitLabel={i18n.t('global:Export')}
      onCancelClick={onCancelClick}
    />
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {renderForm()}
      {renderFooter()}
    </Form>
  )
}

const validate = (fields) => {
  const errors = {} as any

  const { dateRange, dateRangeWeek } = fields

  if (dateRange) {
    const [startDate, endDate] = dateRange

    if (endDate - startDate > convertTimeDuration(1, 'years')) {
      errors.dateRange = i18n.t('module:Modals:InvoicesExport:DateRange:error')
    }
  }

  if (dateRange && dateRangeWeek) {
    const [startDate, endDate] = dateRange
    const [startDateWeek, endDateWeek] = dateRangeWeek

    const startDateFormat = moment(startDate).format('YYYY-MM-DD')
    const endDateFormat = moment(endDate).format('YYYY-MM-DD')
    const startDateWeekFormat = moment(startDateWeek).format('YYYY-MM-DD')
    const endDateWeekFormat = moment(endDateWeek).format('YYYY-MM-DD')

    const isValidStartDate = moment(startDateWeekFormat).isBetween(startDateFormat, endDateFormat, 'days', '[]')
    const isValidEndDate = moment(endDateWeekFormat).isBetween(startDateFormat, endDateFormat, 'days', '[]')

    if (!isValidStartDate || !isValidEndDate) {
      errors.dateRangeWeek = i18n.t('module:Modals:FundingLoopExportModal:Form:HeadCountWeek:error')
    }
  }

  return errors
}

const enhance = compose(
  reduxForm<FundingLoopExportModalFormValues, FundingLoopExportModalFormProps>({
    form: FUNDING_LOOP_EXPORT_MODAL_FORM,
    validate,
  }),
)

export default enhance(FundingLoopExportModalForm)
