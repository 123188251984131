import i18n from 'translations'

export const ACCESS_VIEW_TYPES = {
  ALL_CHILDREN: 'ALL_CHILDREN',
  ONLY_OWN_ROOMS: 'ONLY_OWN_ROOMS',
}

export const ACCESS_VIEW_OPTIONS = [
  {
    label: i18n.t('module:Management:Security:PractitionerChildAccess:AccessViewOptions:allChildren'),
    value: ACCESS_VIEW_TYPES.ALL_CHILDREN,
  },
  {
    label: i18n.t('module:Management:Security:PractitionerChildAccess:AccessViewOptions:onlyOwnRooms'),
    value: ACCESS_VIEW_TYPES.ONLY_OWN_ROOMS,
  },
]
