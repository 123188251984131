import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { convertTimeDuration, roundMilliseconds } from 'utils/date'

import { MEMBERSHIP_TYPE } from 'services/legacy/memberships/constants'
import { SHIFT_MAIN_TYPES, WORK_SHIFT_TYPES } from 'services/legacy/membershipRegisters/constants'
import { RATIO_VALUES } from '../constants'

const getMembershipsShiftsSingleSelector = (state) => state.membershipsShifts.single

export const getMembershipsShiftsSingleErrorSelector = createSelector(
  [getMembershipsShiftsSingleSelector],
  (state) => state.error,
)

export const getValuesForm = (values, isEditMode, initialValues) => {
  const body = {}
  const { date, leaveShiftType, membership, membershipShiftTimes } = values

  body.type = SHIFT_MAIN_TYPES.WORK

  if (leaveShiftType) {
    body.type = SHIFT_MAIN_TYPES.LEAVE
    body.leaveShiftType = {}
    body.leaveShiftType.id = leaveShiftType.value
  }

  if (_.isArray(date)) {
    const [dateFrom, dateTo] = date

    body.dateFrom = moment(dateFrom).format(DEFAULT_DATE_FORMAT)
    body.dateTo = moment(dateTo).format(DEFAULT_DATE_FORMAT)
  } else if (date) {
    body.dateFrom = moment(date).startOf('week').format(DEFAULT_DATE_FORMAT)
    body.dateTo = moment(date).endOf('week').format(DEFAULT_DATE_FORMAT)
  }

  if (membership) {
    body.membership = {
      id: membership?.value || membership,
      type: MEMBERSHIP_TYPE.NURSERY,
    }
  }

  body.membershipShiftTimes = []

  _.each(membershipShiftTimes, (dayItems, day) => {
    _.each(dayItems, (item) => {
      if (!_.keys(item).length || !moment(day).isValid()) {
        return
      }

      const { duration, endTime, id, location, ratio, startTime, type } = item

      const finalShiftType = type?.value || type

      if (finalShiftType === WORK_SHIFT_TYPES.BREAK_DURATION && !+duration) {
        return
      }

      let timeObject = {}

      if (finalShiftType === WORK_SHIFT_TYPES.BREAK_DURATION) {
        timeObject = {
          day,
          endTime: roundMilliseconds(convertTimeDuration(+duration, 'minutes')),
          type: leaveShiftType ? SHIFT_MAIN_TYPES.LEAVE : finalShiftType,
        }
      } else {
        timeObject = {
          day,
          endTime: roundMilliseconds(
            (moment(endTime).valueOf() - moment(endTime).startOf('day').valueOf()),
          ),
          startTime: roundMilliseconds(
            (moment(startTime).valueOf() - moment(startTime).startOf('day').valueOf()),
          ),
          type: leaveShiftType ? SHIFT_MAIN_TYPES.LEAVE : finalShiftType,
        }
      }

      if (id) {
        timeObject.id = id
      }

      if (ratio?.value || ratio) {
        timeObject.ratio = (ratio === RATIO_VALUES.STANDARD || ratio?.value === RATIO_VALUES.STANDARD)
          ? null
          : (ratio?.value || ratio)
      }

      if (location?.value || location) {
        timeObject.class = {
          id: location?.value || location,
        }
      }

      body.membershipShiftTimes.push(timeObject)
    })
  })

  if (isEditMode && initialValues) {
    body.membershipShiftTimes = _.map(body.membershipShiftTimes, (item) => {
      const newItem = { ...item }
      if (newItem.id) {
        let isTypeChanged = false

        _.each(initialValues.membershipShiftTimes, (dayItems) => {
          isTypeChanged = isTypeChanged || dayItems.some(({ id, type }) => item.id === id && item.type !== type.value)
        })

        if (isTypeChanged) {
          delete newItem.id
        }
      }
      return newItem
    })
  }

  return body
}

export const calculateEdgeTimesBasedOnStatistics = (statistics, day) => {
  if (!day) {
    return {}
  }

  const dayStatistics = statistics?.[moment(day).format(DEFAULT_DATE_FORMAT)]?.[0]

  if (!dayStatistics) {
    return {}
  }

  const { breakdowns } = dayStatistics
  const minStartTime = _.get(_.minBy(breakdowns, (i) => i.startTime), 'startTime')
  let maxEndTime = _.get(_.maxBy(breakdowns, (i) => i.endTime), 'endTime')

  if (+moment(maxEndTime).format('mm')) {
    maxEndTime = moment(maxEndTime).add(1, 'hour').set('minute', 0)
  }

  return ({
    from: +moment(minStartTime).utc().format('H'),
    to: +moment(maxEndTime).utc().format('H'),
  })
}
