import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class HomeObservationsApiClient extends ServiceBase {
  list = (params) => this.request({
    path: `/v2/home_observations?${getQueryStringV2(params)}`,
  })

  get = (id, params = {}) => this.request({
    path: `/v2/home_observations/${id}?${getQueryStringV2(params)}`,
  })
}

export default HomeObservationsApiClient
