import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_PERIOD,
  CREATE_PERIOD,
  CREATE_PERIOD_FAILED,
  CREATE_PERIOD_SUCCESS,
  GET_PERIOD,
  GET_PERIOD_FAILED,
  GET_PERIOD_SUCCESS,
  REMOVE_PERIOD,
  REMOVE_PERIOD_FAILED,
  REMOVE_PERIOD_SUCCESS,
  UPDATE_PERIOD,
  UPDATE_PERIOD_FAILED,
  UPDATE_PERIOD_SUCCESS,
} from './constants'

export const get = (options: BasicActionOptionsProps) => ({ periodsApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: GET_PERIOD_FAILED,
      init: GET_PERIOD,
      success: GET_PERIOD_SUCCESS,
    },
    options,
    service: periodsApiClient,
    serviceMethod: 'get',
  })
)

export const create = (options: BasicActionOptionsProps) => ({ periodsApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: CREATE_PERIOD_FAILED,
      init: CREATE_PERIOD,
      success: CREATE_PERIOD_SUCCESS,
    },
    options,
    service: periodsApiClient,
    serviceMethod: 'create',
  })
)

export const update = (options: BasicActionOptionsProps) => ({ periodsApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_PERIOD_FAILED,
      init: UPDATE_PERIOD,
      success: UPDATE_PERIOD_SUCCESS,
    },
    options,
    service: periodsApiClient,
    serviceMethod: 'update',
  })
)

export const remove = (options: BasicActionOptionsProps) => ({ periodsApiClient }) => (
  generateBasicActions.remove({
    constants: {
      failed: REMOVE_PERIOD_FAILED,
      init: REMOVE_PERIOD,
      success: REMOVE_PERIOD_SUCCESS,
    },
    options,
    service: periodsApiClient,
    serviceMethod: 'remove',
  })
)

export const clear = () => ({
  type: CLEAR_PERIOD,
})
