import {
  CLEAR_TRANSACTIONS_LIST,
  DOWNLOAD_TRANSACTIONS_LIST_FAILED,
  GET_TRANSACTIONS_LIST,
  GET_TRANSACTIONS_LIST_FAILED,
  GET_TRANSACTIONS_LIST_SUCCESS,
  SEND_TRANSACTIONS_LIST_FAILED,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: {},
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case GET_TRANSACTIONS_LIST:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_TRANSACTIONS_LIST_SUCCESS:
      return { ...state, data: payload.data, isFetching: false, meta: payload.meta }
    case GET_TRANSACTIONS_LIST_FAILED:
    case SEND_TRANSACTIONS_LIST_FAILED:
    case DOWNLOAD_TRANSACTIONS_LIST_FAILED:
      return { ...state, error, isFetching: false }
    case CLEAR_TRANSACTIONS_LIST:
      return initialState
    default:
      return state
  }
}
