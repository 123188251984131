import _ from 'lodash'

import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Field } from 'redux-form'

import {
  COMMENTS_TYPES,
  FORMATIVE_REPORT_TRACKING_MODE,
  TYPE_OF_TRACKING,
} from 'services/legacy/formativeReports/constants'
import { FRAMEWORK_PROGRESS_LEVELS_TYPES } from 'services/legacy/frameworkProgressLevels/constants'

import { withAppService } from 'services/app'
import { withFormativeReportsService } from 'services/legacy/formativeReports'
import { withFrameworkProgressLevelsService } from 'services/legacy/frameworkProgressLevels'
import { getFormattedItems, getFormattedPreviewData } from 'services/legacy/formativeReports/single/selectors'

import { Box, EmptyState, Form, Spinner, Tabs, Typography } from 'components'

import i18n from 'translations'
import {
  StyledAreaTabs,
  StyledColumn,
  StyledColumnsWrapper,
  StyledCompletedStatementContainer,
  StyledGridContainer,
  StyledItem,
  StyledItemContainer,
  StyledItemTitle,
  StyledPreviewArea,
  StyledPreviewAreaWrapper,
  StyledPreviewGroups,
  StyledPreviewWrapper,
  StyledRow,
  StyledTabTitle,
  StyledTitle,
} from './FrameworkDetailsStyled'

const i18nPrefix = 'module:Learning:FormativeReports:FormativeReportsPreview'

/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/destructuring-assignment */
const FrameworkDetails = ({
  formativeReport,
  framework,
  frameworkProgressLevelsActions,
  isFetching,
  isFetchingProgressLevels,
  items,
  onAttainmentLevelsChange,
  onCommentsBlur,
  onCommentsChange,
  onGetAgeBands,
  onGetFrameworkDetails,
  progressLevels,
  useOnlySecureToMarkProgress,
  visible,
}) => {
  if (!visible) {
    return null
  }

  const [selectedArea, changeSelectedArea] = useState(null)
  const [internalIsFetching, setInternalIsFetching] = useState(true)
  const [ageBands, setAgeBands] = useState(null)
  const [frameworkDetails, setFrameworkDetails] = useState(null)

  const getTypeOfProgressLevel = () => {
    const { period, trackingMode } = formativeReport

    if (
      FORMATIVE_REPORT_TRACKING_MODE.AUTOMATED === trackingMode
      || (
        FORMATIVE_REPORT_TRACKING_MODE.BOTH === trackingMode
        && TYPE_OF_TRACKING.ASSESSMENT === period.type
      )
    ) {
      return FRAMEWORK_PROGRESS_LEVELS_TYPES.FORMATIVE_REPORT_BEHAVIOUR_STATEMENT
    }

    return FRAMEWORK_PROGRESS_LEVELS_TYPES.FORMATIVE_REPORT_AGE_BAND
  }

  const onGetFrameworkSuccess = (response) => {
    const { areas, structure } = response

    setFrameworkDetails(response)
    changeSelectedArea(areas?.[0].id)

    if (structure.formativeReportGrading) {
      onGetAgeBands(framework.id, (result) => {
        setAgeBands(result)
        setInternalIsFetching(false)
      })
    } else {
      setInternalIsFetching(false)
    }
  }

  useEffect(() => {
    onGetFrameworkDetails(framework.id, onGetFrameworkSuccess)
    const progressLevelsCriteria = [
      {
        field: 'framework',
        value: framework.id,
      },
      {
        field: 'type',
        value: getTypeOfProgressLevel(),
      },
    ]

    frameworkProgressLevelsActions.list({
      params: {
        criteria: progressLevelsCriteria,
      },
      recursively: true,
    })
  }, [framework.id])

  useEffect(() => {
    if (_.isNull(selectedArea) && frameworkDetails?.areas?.[0]?.id) {
      changeSelectedArea(frameworkDetails.areas[0].id)
    }
  }, [frameworkDetails, selectedArea])

  if (isFetching || internalIsFetching) {
    return (
      <Spinner />
    )
  }

  const handleSubmit = () => {}

  const { areas, structure } = frameworkDetails || {}
  const {
    formativeReport: formativeReportStructure,
    formativeReportGrading,
    formativeReportShowCategoryName,
  } = structure || {}
  const areaData = _.find(areas, ({ id }) => selectedArea === id) || {}
  const { attainmentLevels, comments, period } = formativeReport
  const areaComments = _.filter(comments, ({ frameworkArea }) => (
    frameworkArea.id === areaData.id
  ))

  const fieldComment = _.find(areaComments, ({ type }) => COMMENTS_TYPES.COMPLETED === type) || {}
  const fieldCommentNextStep = _.find(areaComments, ({ type }) => COMMENTS_TYPES.WORKING === type) || {}
  const { groups } = areaData
  const uniqItems = _.uniqBy(
    items,
    ({ frameworkItem: { category: { group: { name } } } }) => name,
  )

  const {
    completedStatementsGroupedByGroup,
    statementsBeingWorkedOnByGroup,
  } = getFormattedItems(
    items,
    areaData.id,
  )

  const progressLevelsOptions = _.map(progressLevels, (item) => ({
    label: item.name,
    value: item.id,
  }))

  const renderGroup = (group, i) => (
    <StyledColumn
      isColumnName={structure?.formativeReport?.group?.visible && group.name}
      key={group.id}
    >
      {structure?.formativeReport?.group?.visible && group.name && (
        <StyledRow
          minHeight="50"
          paddingTop={!i ? 48 : 22}
        >
          <StyledTitle>
            {group.name}
          </StyledTitle>
        </StyledRow>
      )}
      {period.type === TYPE_OF_TRACKING.ASSESSMENT && !period.custom && (
        <StyledRow
          minHeight="50"
        >
          {attainmentLevels[group.id].progressLevel.name}
        </StyledRow>
      )}
      {(period.type === TYPE_OF_TRACKING.TERM || (
        period.type === TYPE_OF_TRACKING.ASSESSMENT
        && period.custom
      )) && (
        <StyledRow>
          <Form.Row
            label={!i ? `${i18n.t(`${i18nPrefix}:progressLevel`)}` : ''}
            margin="0px"
            verticalLabel
          >
            <Form.Row.FlexItem flex="1">
              <Field
                component={Form.Select}
                isLoading={isFetchingProgressLevels}
                name={`attainmentLevels.${group.id}.progressLevel`}
                options={progressLevelsOptions}
                placeholder={i18n.t(`${i18nPrefix}:progressLevel`)}
                onChange={() => onAttainmentLevelsChange(getTypeOfProgressLevel(), group)}
              />
            </Form.Row.FlexItem>
          </Form.Row>
        </StyledRow>
      )}
      {period.type === TYPE_OF_TRACKING.TERM && (
        <StyledRow>
          <Form.Row
            label={!i ? `${i18n.t(`${i18nPrefix}:ageGroup`)}` : ''}
            margin="0px"
            verticalLabel
          >
            <Form.Row.FlexItem flex="1">
              <Field
                component={Form.Select}
                name={`attainmentLevels.${group.id}.range`}
                options={ageBands}
                placeholder={i18n.t(`${i18nPrefix}:ageGroup`)}
                onChange={() => onAttainmentLevelsChange(getTypeOfProgressLevel(), group)}
              />
            </Form.Row.FlexItem>
          </Form.Row>
        </StyledRow>
      )}
    </StyledColumn>
  )

  const renderFrameworkGroups = () => (
    <Box
      headerAlign="center"
      headerBackground={areaData.colour}
      title={areaData.name}
    >
      <StyledGridContainer>
        {_.map(groups, renderGroup)}
      </StyledGridContainer>
    </Box>
  )

  const renderCategoryName = ({
    frameworkItem: { category: { fromMonths, name: categoryName, toMonths }, description, id },
  }) => {
    if (formativeReportShowCategoryName) {
      return (
        <StyledItem key={`frameworkItem_${id}`}>
          <Typography variant="span" bold>
            {'('}
            {categoryName}
            {')'}
          </Typography>
          {' '}
          {description}
        </StyledItem>
      )
    }

    return (
      <StyledItem key={`frameworkItem_${id}`}>
        {('ELG' === categoryName) ? (
          <strong>
            {'(ELG) '}
          </strong>
        ) : null}
        {('40-60+ months' === categoryName) ? (
          <strong>
            {`(40 - 60+ ${i18n.t('global:months')}) `}
          </strong>
        ) : null}
        {(Number.isInteger(fromMonths) && Number.isInteger(toMonths)) ? (
          <strong>
            {`(${fromMonths} - ${toMonths} ${i18n.t('global:months')}) `}
          </strong>
        ) : null}
        {description}
      </StyledItem>
    )
  }

  const renderFrameworkItems = ({ name, values }) => (
    <StyledItemContainer key={name}>
      <StyledItemTitle>
        {name}
      </StyledItemTitle>
      {_.map(values, renderCategoryName)}
    </StyledItemContainer>
  )

  const renderItems = () => (
    <StyledColumnsWrapper
      oneColumns={useOnlySecureToMarkProgress}
    >
      <Box
        headerBackground={areaData.colour}
        title={i18n.t(`${i18nPrefix}:CompletedStatements:title`)}
      >
        {completedStatementsGroupedByGroup.length
          ? (
            <StyledCompletedStatementContainer>
              {_.map(completedStatementsGroupedByGroup, renderFrameworkItems)}
            </StyledCompletedStatementContainer>
          ) : (
            <EmptyState
              icon="reports"
              text1={i18n.t(`${i18nPrefix}:CompletedStatements:emptyStateText`)}
            />
          )}
      </Box>
      {!useOnlySecureToMarkProgress
      && (
        <Box
          headerBackground={areaData.colour}
          title={i18n.t(`${i18nPrefix}:StatementsBeingWorkedOn:title`)}
        >
          {statementsBeingWorkedOnByGroup.length
            ? (
              <StyledCompletedStatementContainer>
                {_.map(statementsBeingWorkedOnByGroup, renderFrameworkItems)}
              </StyledCompletedStatementContainer>
            ) : (
              <EmptyState
                icon="reports"
                text1={i18n.t(`${i18nPrefix}:StatementsBeingWorkedOn:emptyStateText`)}
              />
            )}
        </Box>
      )}
    </StyledColumnsWrapper>
  )

  const renderTextareas = () => (
    <StyledColumnsWrapper>
      <Box
        headerBackground={areaData.colour}
        title={`${i18n.t(`${i18nPrefix}:Comments:title`)}`}
        withPadding
      >
        <Field
          component={Form.TextAreaField}
          name={`comments.${areaData.id}.${COMMENTS_TYPES.COMPLETED}`}
          placeholder={i18n.t(`${i18nPrefix}:Comments:placeholder`)}
          onBlur={() => onCommentsBlur(fieldComment.id, areaData, COMMENTS_TYPES.COMPLETED)}
          onChange={() => onCommentsChange(fieldComment.id, areaData, COMMENTS_TYPES.COMPLETED)}
        />
      </Box>
      <Box
        headerBackground={areaData.colour}
        title={`${i18n.t(`${i18nPrefix}:NextStepsForHome:title`)}`}
        withPadding
      >
        <Field
          component={Form.TextAreaField}
          name={`comments.${areaData.id}.${COMMENTS_TYPES.WORKING}`}
          placeholder={i18n.t(`${i18nPrefix}:NextStepsForHome:placeholder`)}
          onBlur={() => onCommentsBlur(fieldCommentNextStep.id, areaData, COMMENTS_TYPES.WORKING)}
          onChange={() => onCommentsChange(fieldCommentNextStep.id, areaData, COMMENTS_TYPES.WORKING)}
        />
      </Box>
    </StyledColumnsWrapper>
  )

  const renderTable = () => (
    <Form onSubmit={handleSubmit}>
      {renderFrameworkGroups()}
      {uniqItems?.length && formativeReportStructure?.item?.visible ? renderItems() : null}
      {renderTextareas()}
    </Form>
  )

  const renderAreaTabs = () => (
    <StyledAreaTabs>
      <Tabs
        defaultPosition={areas?.[0].id}
        fullWidth={false}
        onChange={changeSelectedArea}
      >
        {_.map(areas, (area) => (
          <Tabs.Item
            key={area.id}
            name={area.id}
            title={(
              <StyledTabTitle>
                {area.shortName || area.name}
              </StyledTabTitle>
            )}
          />
        ))}
      </Tabs>
    </StyledAreaTabs>
  )

  const renderPreview = () => {
    const formattedAreas = getFormattedPreviewData(areas, items)

    if (isFetching) {
      return (
        <Spinner />
      )
    }

    if (!formattedAreas?.length) {
      return (
        <EmptyState
          icon="formative-reports"
          text1={i18n.t('global:noDataFound')}
        />
      )
    }

    return (
      <StyledPreviewWrapper>
        {_.map(formattedAreas, (area) => (
          <StyledPreviewAreaWrapper key={area.id}>
            <StyledPreviewArea color={area.colour}>
              {area.name}
            </StyledPreviewArea>
            {_.map(area.groups, (group) => (
              <StyledPreviewGroups key={group.id}>
                {formativeReportStructure.group.visible && (
                  <Typography fontSize={17} margin="0 0 10px" bold>
                    {group.name}
                  </Typography>
                )}
                {_.map(group.categories, (category) => (
                  <React.Fragment key={category.id}>
                    {formativeReportStructure.item.visible && _.map(category.items, (item) => (
                      <Typography key={item.id} margin="10px 0 20px 0">
                        {item.description}
                      </Typography>
                    ))}
                  </React.Fragment>
                ))}
              </StyledPreviewGroups>
            ))}
          </StyledPreviewAreaWrapper>
        ))}
      </StyledPreviewWrapper>
    )
  }

  if (!formativeReportGrading) {
    return renderPreview()
  }

  return (
    <React.Fragment>
      {renderAreaTabs()}
      {renderTable()}
    </React.Fragment>
  )
}

const mapState = (state, {
  appSelectors,
  formativeReportsItemsState,
  formativeReportsSelectors,
  frameworkProgressLevelsListState,
  frameworkProgressLevelsSelectors,
}) => ({
  isFetching: appSelectors.getIsFetching(formativeReportsItemsState),
  isFetchingProgressLevels: appSelectors.getIsFetching(frameworkProgressLevelsListState),
  items: formativeReportsSelectors.getFormativeReportsListItems(state),
  progressLevels: frameworkProgressLevelsSelectors.getFrameworkProgressLevels(state),
})

const enhance = compose(
  withAppService,
  withFormativeReportsService,
  withFrameworkProgressLevelsService,
  connect(mapState),
)

export default enhance(FrameworkDetails)
