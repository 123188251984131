import { generateBasicActions } from 'utils/service'

import {
  CLEAR_LIST_NURSERY_DISCOUNTS,
  LIST_NURSERY_DISCOUNTS,
  LIST_NURSERY_DISCOUNTS_FAILED,
  LIST_NURSERY_DISCOUNTS_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ nurseryDiscountsApiClient }) => generateBasicActions.list({
  constants: {
    failed: LIST_NURSERY_DISCOUNTS_FAILED,
    init: LIST_NURSERY_DISCOUNTS,
    success: LIST_NURSERY_DISCOUNTS_SUCCESS,
  },
  options,
  service: nurseryDiscountsApiClient,
  serviceMethod: 'list',
})

export const clearList = () => ({
  type: CLEAR_LIST_NURSERY_DISCOUNTS,
})
