import { createSelector } from 'reselect'

import { LIST_STATUS_FILTERS } from 'constants/filters'

export const getNurseryPaymentTypesSelector = (state) => state.nurseryPaymentTypes

export const getNurseryPaymentTypesListDataSelector = createSelector(
  [getNurseryPaymentTypesSelector],
  (state) => state.list.data,
)

export const getListCriteria = (filters) => {
  const { statusFilter } = filters || {}

  const criteria = []

  if (statusFilter && statusFilter !== LIST_STATUS_FILTERS.ALL) {
    criteria.push({
      field: 'archived',
      value: statusFilter === LIST_STATUS_FILTERS.ARCHIVED,
    })
  }

  return criteria
}

