import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_CHILD_BANK_DETAILS,
  GET_CHILD_BANK_DETAILS,
  GET_CHILD_BANK_DETAILS_FAILED,
  GET_CHILD_BANK_DETAILS_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_CHILD_BANK_DETAILS,
    itemFailed: GET_CHILD_BANK_DETAILS_FAILED,
    itemInit: GET_CHILD_BANK_DETAILS,
    itemSuccess: GET_CHILD_BANK_DETAILS_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
