import { combineReducers } from 'redux'

import list from './list/reducer'
import listProjections from './listProjections/reducer'
import single from './single/reducer'

export default combineReducers({
  list,
  listProjections,
  single,
})
