import React, { PropsWithChildren } from 'react'

import { CircleIcon } from 'components'

import { StyledLegendItem, StyledLegendLabel, StyledText } from './LegendStyled'

interface LegendItemProps {
  background?: string
  color?: string
  icon?: IconType
  iconSize?: number
  label?: string
  roundedSquare?: boolean
  size?: number
  text?: string
}

const LegendItem: React.FC<PropsWithChildren<LegendItemProps>> = ({
  background,
  color,
  icon,
  iconSize,
  label,
  roundedSquare,
  size = 20,
  text,
}) => {
  const height = size * 0.6
  const iconSizeCalculated = 2 * Math.round(height / 2)

  return (
    <StyledLegendItem>
      {!text && (
        <CircleIcon
          background={background}
          color={color}
          icon={icon}
          iconSize={iconSize || iconSizeCalculated}
          roundedSquare={roundedSquare}
          size={size}
        />
      )}
      {text && (
        <StyledText $background={background}>
          {text}
        </StyledText>
      )}
      <StyledLegendLabel>
        {label}
      </StyledLegendLabel>
    </StyledLegendItem>
  )
}

export default LegendItem
