import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledLegendContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`

export const StyledTermTimeLegend = styled.div`
  height: 24px;
  width: 24px;
  background-color: ${getBrandingColor('primary-color-lighten-10')};
  flex: 0 0 24px;
  border: 1px solid ${getBrandingColor('primary-color-lighten-10')};
  border-radius: 2px;
`

export const StyledBannerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`
