import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledWidgetPart = styled.div`
  flex: 1;
  width: 100%;
  border-radius: 5px;
  overflow: auto;
  background: ${getBrandingColor('primary-color')};
  height: 100%;
`
