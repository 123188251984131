import React, { useMemo } from 'react'
import { Field } from 'redux-form'

import { ChildProductTypes } from 'services/booking/childProducts/constants'

import { isRequired } from 'utils/fieldValidation'

import { getCriteria } from 'services/booking/childProducts/list/selectors'

import { Form } from 'components'

import i18n from 'translations'

import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

const FUNDING_GROUPS = {
  read: [
    'oneOffChildProduct.product',
    'nurseryFundingProduct',
    'nurseryFundingProduct.settings',
    'nurseryFundingProductSettings',
  ],
}

interface FundingFormFieldsProps {
  childId?: string
  isReadOnly?: boolean
}

const FundingFormFields = ({
  childId,
  isReadOnly,
}: FundingFormFieldsProps): JSX.Element => {
  const criteria = useMemo(() => getCriteria({
    childId,
    invoiced: false,
    type: ChildProductTypes.ABSTRACT_ONE_OFF_FUNDING,
  }), [childId])

  return (
    <React.Fragment>
      <Form.Row
        label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Funding:label')}
        width={{ field: '400px' }}
        required
      >
        <Field
          childId={childId}
          component={Form.InfiniteDropdowns.ChildProducts}
          criteria={criteria}
          disabled={isReadOnly}
          extraFields={['type', 'product.settings.total']}
          groups={FUNDING_GROUPS}
          maxMenuHeight={150}
          name="customOneOffFundingChildProduct"
          placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Funding:placeholder')}
          validate={isRequired}
          pendingOnly
        />
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Amount:label')}
        width={{ field: '150px' }}
        required
      >
        <SubdomainCurrencyProvider>
          {({ currencySymbol }) => (
            <Field
              component={Form.TextField}
              name="total"
              placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Amount:placeholder')}
              prefix={currencySymbol}
              prefixWidth="30px"
              type="number"
              validate={isRequired}
              disabled
            />
          )}
        </SubdomainCurrencyProvider>
      </Form.Row>
    </React.Fragment>
  )
}
export default FundingFormFields
