import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_INTEGRATION_FEES_LIST,
  GET_INTEGRATION_FEES_LIST,
  GET_INTEGRATION_FEES_LIST_FAILED,
  GET_INTEGRATION_FEES_LIST_SUCCESS,
} from './constants'

export const getIntegrationFees = (
  options: BasicActionOptionsProps,
) => ({ integrationFeesApiClient }) => generateBasicActions.list({
  constants: {
    failed: GET_INTEGRATION_FEES_LIST_FAILED,
    init: GET_INTEGRATION_FEES_LIST,
    success: GET_INTEGRATION_FEES_LIST_SUCCESS,
  },
  options,
  service: integrationFeesApiClient,
  serviceMethod: 'getIntegrationFees',
})

export const clearIntegrationFees = () => ({
  type: CLEAR_INTEGRATION_FEES_LIST,
})
