export const CREATE_MEMBERSHIP_SHIFT = 'CREATE_MEMBERSHIP_SHIFT'
export const CREATE_MEMBERSHIP_SHIFT_FAILED = 'CREATE_MEMBERSHIP_SHIFT_FAILED'
export const CREATE_MEMBERSHIP_SHIFT_SUCCESS = 'CREATE_MEMBERSHIP_SHIFT_SUCCESS'

export const UPDATE_MEMBERSHIP_SHIFT = 'UPDATE_MEMBERSHIP_SHIFT'
export const UPDATE_MEMBERSHIP_SHIFT_FAILED = 'UPDATE_MEMBERSHIP_SHIFT_FAILED'
export const UPDATE_MEMBERSHIP_SHIFT_SUCCESS = 'UPDATE_MEMBERSHIP_SHIFT_SUCCESS'

export const REMOVE_MEMBERSHIP_SHIFT = 'REMOVE_MEMBERSHIP_SHIFT'
export const REMOVE_MEMBERSHIP_SHIFT_FAILED = 'REMOVE_MEMBERSHIP_SHIFT_FAILED'
export const REMOVE_MEMBERSHIP_SHIFT_SUCCESS = 'REMOVE_MEMBERSHIP_SHIFT_SUCCESS'

export const CLEAR_MEMBERSHIP_SHIFT = 'CLEAR_MEMBERSHIP_SHIFT'
