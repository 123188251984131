import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_PAYOUT,
  GET_PAYOUT,
  GET_PAYOUT_FAILED,
  GET_PAYOUT_SUCCESS,
  REMOVE_INTEGRATION,
  REMOVE_INTEGRATION_FAILED,
  REMOVE_INTEGRATION_SUCCESS,
  UPDATE_INTEGRATION,
  UPDATE_INTEGRATION_FAILED,
  UPDATE_INTEGRATION_SUCCESS,
} from './constants'

export const getPayout = (options = {}) => ({ nurseryIntegrationsApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: GET_PAYOUT_FAILED,
      init: GET_PAYOUT,
      success: GET_PAYOUT_SUCCESS,
    },
    options,
    service: nurseryIntegrationsApiClient,
    serviceMethod: 'getPayout',
  })
)

export const removeIntegration = (options: BasicActionOptionsProps) => ({ nurseryIntegrationsApiClient }) => (
  generateBasicActions.remove({
    constants: {
      failed: REMOVE_INTEGRATION_FAILED,
      init: REMOVE_INTEGRATION,
      success: REMOVE_INTEGRATION_SUCCESS,
    },
    options,
    service: nurseryIntegrationsApiClient,
    serviceMethod: 'removeIntegration',
  })
)

export const updateIntegration = (options: BasicActionOptionsProps) => ({ nurseryIntegrationsApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_INTEGRATION_FAILED,
      init: UPDATE_INTEGRATION,
      success: UPDATE_INTEGRATION_SUCCESS,
    },
    options,
    service: nurseryIntegrationsApiClient,
    serviceMethod: 'updateIntegration',
  })
)

export const clear = () => ({
  type: CLEAR_PAYOUT,
})
