import _ from 'lodash'

import React from 'react'

import { InfiniteDropdownsList } from 'components/InfiniteDropdowns'

interface InfiniteDropdownsProps {
  [key: string]: (props?: any) => any
}

const InfiniteDropdowns: InfiniteDropdownsProps = {}

_.forOwn(InfiniteDropdownsList, (Component, key) => {
  InfiniteDropdowns[key] = ({
    input: { onChange, value },
    meta: { error, submitFailed },
    ...rest
  }) => (
    <Component
      {...rest}
      error={submitFailed && error}
      value={value}
      onChange={onChange}
    />
  )
})

export default InfiniteDropdowns
