import styled from 'styled-components'
import colorMethod from 'color'

import colors from 'constants/colors'

interface StyledContainerProps {
  $backgroundColor?: string
  $height?: number
}

export const StyledContainer = styled.div<StyledContainerProps>`
  margin: 8px 0;
  width: 100%;
  
  ${({ $backgroundColor }) => $backgroundColor && `
    background: ${$backgroundColor};
  `}
  
  ${({ $height }) => $height && `
    border-radius: ${$height / 2}px;
  `}
`

interface StyledProgressFillContainerProps {
  $fillColor?: string
  $height?: number
  $isExceedAvailable?: boolean
  $width?: number
}

export const StyledProgressFillContainer = styled.div<StyledProgressFillContainerProps>`
  transition: width 500ms;
  background: ${({ $fillColor, $isExceedAvailable }) => {
    const backgroundColor = $isExceedAvailable ? colors.remove : $fillColor
    const lightenColor = colorMethod(backgroundColor).lighten(0.1).hex()

    return `
      repeating-linear-gradient(
        45deg,
        ${lightenColor},
        ${lightenColor} 10px,
        ${backgroundColor} 10px,
        ${backgroundColor} 20px
      )
    `
  }};
  
  ${({ $height }) => $height && `
    height: ${$height}px;
    border-radius: ${$height / 2}px;
  `}
  
  ${({ $width }) => null !== $width && undefined !== $width && `
    width: ${100 < $width ? 100 : $width}%;
  `}
`
