import {
  CLEAR_CHILD_DISCOUNT,
  CREATE_CHILD_DISCOUNT,
  CREATE_CHILD_DISCOUNT_FAILED,
  CREATE_CHILD_DISCOUNT_SUCCESS,
  GET_CHILD_DISCOUNT,
  GET_CHILD_DISCOUNT_FAILED,
  GET_CHILD_DISCOUNT_SUCCESS,
  UPDATE_CHILD_DISCOUNT,
  UPDATE_CHILD_DISCOUNT_FAILED,
  UPDATE_CHILD_DISCOUNT_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case GET_CHILD_DISCOUNT:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_CHILD_DISCOUNT_SUCCESS:
      return { ...state, data: payload.data, isFetching: false }
    case GET_CHILD_DISCOUNT_FAILED:
      return { ...state, error, isFetching: false }
    case CREATE_CHILD_DISCOUNT:
      return { ...state, error: initialState.error, isSubmitting: true }
    case CREATE_CHILD_DISCOUNT_SUCCESS:
      return { ...state, data: payload.data, isSubmitting: false }
    case CREATE_CHILD_DISCOUNT_FAILED:
      return { ...state, error, isSubmitting: false }
    case UPDATE_CHILD_DISCOUNT:
      return { ...state, error: initialState.error, isSubmitting: true }
    case UPDATE_CHILD_DISCOUNT_SUCCESS:
      return { ...state, data: payload.data, isSubmitting: false }
    case UPDATE_CHILD_DISCOUNT_FAILED:
      return { ...state, error, isSubmitting: false }
    case CLEAR_CHILD_DISCOUNT:
      return initialState
    default:
      return state
  }
}
