import React, { PropsWithChildren } from 'react'

import {
  StyledActions,
  StyledContent,
  StyledDataPreviewContainer,
  StyledHeader,
  StyledTitle,
} from './DataPreviewStyled'

interface DataPreviewProps {
  actions?: React.ReactNode
  content: string
  noPadding?: boolean
  title: string
  whiteSpace?: string
  withBackground?: boolean
}

const DataPreview: React.FC<PropsWithChildren<DataPreviewProps>> = ({
  actions,
  content,
  noPadding,
  title,
  whiteSpace,
  withBackground,
}) => {
  if (!content) {
    return null
  }

  return (
    <StyledDataPreviewContainer>
      <StyledHeader>
        <StyledTitle>
          {title}
        </StyledTitle>
        {actions && (
          <StyledActions>
            {actions}
          </StyledActions>
        )}
      </StyledHeader>
      <StyledContent
        $noPadding={noPadding}
        $whiteSpace={whiteSpace}
        $withBackground={withBackground}
      >
        {content}
      </StyledContent>
    </StyledDataPreviewContainer>
  )
}

export default DataPreview
