import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'

export const getChildBookingPreviewState = (state: RootState) => state.childBooking.preview

export const getChildBookingPreviewDataState = createSelector(
  [getChildBookingPreviewState],
  (state) => state.data,
)
