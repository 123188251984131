import _ from 'lodash'

import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { ENQUIRY_STATUS_CODES } from 'services/legacy/enquiries/constants'

import { withAppService } from 'services/app'
import { withEnquiryReportsService } from 'services/legacy/enquiries/reports'
import { withPaginationUtils } from 'services/utils/pagination'
import { withSecurityService } from 'services/security'
import { withEnquiryStatusTypesService } from 'services/legacy/enquiries'
import { withRouter } from 'services/router'

import { useBasicReport } from 'hooks/reports'

import { Chart, DatePicker, Form, ReportView, Toolbar } from 'components'

import i18n from 'translations'

import { getChartData, getColumns, getTableData } from './ConversionRateHelper'
import BreakdownSelect, { BREAKDOWN_OPTIONS } from '../components/BreakdownSelect'

const CONVERSION_RATE_GROUPS = {
  read: [
    'nursery',
  ],
}

const ConversionRateContainer = ({
  appProperties,
  conversionRatesData,
  conversionRatesStatisticsData,
  enquiryReportsActions,
  enquiryReportsSelectors,
  enquiryStatusTypesActions,
  enquiryStatusTypesSelectors,
  errorMessages,
  isFetching,
  isOrganizationContext,
  isStatisticsFetching,
  location,
  paginationUtils,
  setLocationQuery,
  totalResults: tableTotalResult,
}) => {
  const [enrolledStatusId, setEnrolledStatusId] = useState()
  const [lostStatusId, setLostStatusId] = useState()
  const [defaultTableProps, setDefaultTableProps] = useState()
  const [tableData, setTableData] = useState()

  useEffect(() => {
    const enquiryStatusProcess = async () => {
      if (!isOrganizationContext) {
        const criteria = enquiryStatusTypesSelectors.getCriteria({
          code: [ENQUIRY_STATUS_CODES.ENROLLED, ENQUIRY_STATUS_CODES.LOST_ENQUIRY],
        })

        await enquiryStatusTypesActions.list({
          onSuccess: (response) => {
            setEnrolledStatusId(_.find(response?.data, ({ code }) => code === ENQUIRY_STATUS_CODES.ENROLLED)?.id)
            setLostStatusId(_.find(response?.data, ({ code }) => code === ENQUIRY_STATUS_CODES.LOST_ENQUIRY)?.id)
          },
          onlyData: true,
          params: {
            criteria,
          },
        })
      }
    }

    enquiryStatusProcess()
  }, [])

  useEffect(() => {
    setTableData(getTableData({ ...defaultTableProps, appProperties, enrolledStatusId, lostStatusId }))
  }, [
    defaultTableProps,
    appProperties,
    enrolledStatusId,
    lostStatusId,
  ])

  const {
    breakdown,
    dateRange,
    onBreakdownChange,
    onDateChange,
    onPageChange,
    page,
    pageCount,
    perPage,
    statistics,
    tableColumns,
    totalResults,
  } = useBasicReport({
    actions: {
      clearChildren: enquiryReportsActions.clearConversionRate,
      clearNurseries: enquiryReportsActions.clearConversionRate,
      clearStatistics: enquiryReportsActions.clearConversionRateStatistics,
      getChildren: enquiryReportsActions.getConversionRate,
      getNurseries: enquiryReportsActions.getConversionRate,
      getStatistics: enquiryReportsActions.getConversionRateStatistics,
    },
    breakdownOptions: isOrganizationContext && BREAKDOWN_OPTIONS,
    callStatisticsAction: isOrganizationContext,
    childrenApiGroups: isOrganizationContext && CONVERSION_RATE_GROUPS,
    childrenData: conversionRatesData,
    childrenTotalResults: tableTotalResult,
    errorMessages,
    getColumns,
    getStatistics: getChartData,
    getTableData: setDefaultTableProps,
    isOrganizationContext,
    location,
    nurseriesApiGroups: isOrganizationContext && CONVERSION_RATE_GROUPS,
    nurseriesData: conversionRatesData,
    nurseriesTotalResults: tableTotalResult,
    paginationUtils,
    selectors: enquiryReportsSelectors,
    setLocationQuery,
    statisticsData: isOrganizationContext ? conversionRatesStatisticsData : conversionRatesData,
  })

  return (
    <ReportView.DetailPage
      chart={(
        <Chart.Pie
          chartSeries={{
            data: statistics,
          }}
          data={statistics}
          legendValueField="percentageLabel"
          subtitle={_.reduce(statistics, (total, item) => total + item.value, 0)}
          title={i18n.t('global:Finance:Total')}
        />
      )}
      description={isOrganizationContext
        ? i18n.t('module:Enquiries:Report:ConversionRate:subtitle')
        : i18n.t('module:Enquiries:Report:ConversionRate:Organization:subtitle')}
      errorMessages={errorMessages}
      filters={(
        <Toolbar bottomLine>
          <Toolbar.Group>
            <Toolbar.Item>
              <Form.Row
                label={i18n.t('module:Enquiries:Report:ConversionRate:Filters:EnquiryDate:label')}
                verticalLabel
              >
                <DatePicker
                  clearable={false}
                  value={dateRange}
                  range
                  onChange={onDateChange}
                />
              </Form.Row>
            </Toolbar.Item>
            {isOrganizationContext && (
              <Toolbar.Item>
                <Form.Row
                  label={i18n.t('module:Enquiries:Report:ConversionRate:Filters:Breakdown:label')}
                  verticalLabel
                >
                  <BreakdownSelect
                    value={breakdown}
                    onChange={onBreakdownChange}
                  />
                </Form.Row>
              </Toolbar.Item>
            )}
          </Toolbar.Group>
        </Toolbar>
      )}
      isChartLoading={isStatisticsFetching}
      isOrganizationContext={isOrganizationContext}
      isPageLoading={isFetching && isStatisticsFetching && 1 === page}
      isTableLoading={isFetching}
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      tableColumns={tableColumns}
      tableData={tableData}
      title={i18n.t('module:Enquiries:Report:ConversionRate:title')}
      totalResults={totalResults}
      hideExport
      onPageChange={onPageChange}
    />
  )
}

const mapState = (state, {
  appSelectors,
  enquiryReportsConversionRateState,
  enquiryReportsConversionRateStatisticsState,
  enquiryReportsSelectors,
  securitySelectors,
}) => ({
  appProperties: appSelectors.getApplicationProperties(state),
  conversionRatesData: enquiryReportsSelectors.getEnquiryReportsConversionRateDataSelector(state),
  conversionRatesStatisticsData: enquiryReportsSelectors.getEnquiryReportsConversionRateStatisticsDataSelector(state),
  errorMessages: appSelectors.getErrorMessages(enquiryReportsConversionRateState),
  isFetching: appSelectors.getIsFetching(enquiryReportsConversionRateState),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
  isStatisticsFetching: appSelectors.getIsFetching(enquiryReportsConversionRateStatisticsState),
  totalResults: appSelectors.getTotalResults(enquiryReportsConversionRateState),
})

const enhance = compose(
  withRouter,
  withAppService,
  withEnquiryReportsService,
  withEnquiryStatusTypesService,
  withPaginationUtils,
  withSecurityService,
  connect(mapState),
)

export default enhance(ConversionRateContainer)
