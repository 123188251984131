import { generateBasicActions } from 'utils/service'

import { UPDATE_REGISTER_REASON, UPDATE_REGISTER_REASON_FAILED, UPDATE_REGISTER_REASON_SUCCESS } from './constants'

export const update = (options = {}) => ({ childRegistersApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_REGISTER_REASON_FAILED,
    init: UPDATE_REGISTER_REASON,
    success: UPDATE_REGISTER_REASON_SUCCESS,
  },
  options,
  service: childRegistersApiClient,
  serviceMethod: 'update',
})
