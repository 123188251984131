import styled from 'styled-components'

import TextareaAutosize from 'react-textarea-autosize'

import colors, { FLAG_COLOURS } from 'constants/colors'
import layout from 'constants/layout'

export const StyledInputContainer = styled.div`
  position: relative;
`

interface StyledTextAreaProps {
  $disabled?: boolean
  $height?: number
  $maxHeight?: string
  $padding?: string
  $valid?: boolean
}

export const StyledTextArea = styled.textarea<StyledTextAreaProps>`
  max-width: 100%;
  display: block;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ $valid }) => (false === $valid ? FLAG_COLOURS.ERROR : colors.inputBorderColor)};
  border-radius: 4px;
  height: 140px;
  min-height: 140px;
  line-height: 20px;
  width: 100%;
  padding: ${({ $padding }) => $padding || '10px'};
  font-size: 14px;
  resize: none;
  max-height: ${({ $maxHeight }) => $maxHeight || '200px'};

  ${({ $disabled }) => $disabled && `
    background-color: ${colors.disabled};
    cursor: not-allowed;

    &::-webkit-input-placeholder {
      color: #CCC;
    }
  `};
  
  ${({ $height }) => $height && `
    height: ${$height}px;
    min-height: ${$height}px;
  `};
  
  @media(max-width: ${layout.mobileWidthResolution}px) {
    width: 100%;
  }
`

interface StyledAutosizeTextAreaProps {
  $disabled?: boolean
  $height?: number
  $padding?: string
  $valid?: boolean
  maxHeight?: string
}

export const StyledAutosizeTextArea = styled(TextareaAutosize)<StyledAutosizeTextAreaProps>`
  max-width: 100%;
  display: block;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ $valid }) => (false === $valid ? FLAG_COLOURS.ERROR : colors.inputBorderColor)};
  border-radius: 4px;
  height: 140px;
  min-height: 140px;
  line-height: 20px;
  width: 100%;
  padding: ${({ $padding }) => $padding || '10px'};
  font-size: 14px;
  resize: none;
  max-height: ${({ $maxHeight }) => $maxHeight || '200px'};

  ${({ $disabled }) => $disabled && `
    background-color: ${colors.disabled};
    cursor: not-allowed;

    &::-webkit-input-placeholder {
      color: #CCC;
    }
  `}
  
  ${({ $height }) => $height && `
    min-height: ${$height}px;
  `};
  
  @media(max-width: ${layout.mobileWidthResolution}px) {
    width: 100%;
  }
`
