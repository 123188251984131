import { generateBasicActions } from 'utils/service'

import {
  CLEAR_LIST_ENQUIRY_STATUS_TYPES,
  ENQUIRY_STATUS_TYPES_LIST,
  ENQUIRY_STATUS_TYPES_LIST_FAILED,
  ENQUIRY_STATUS_TYPES_LIST_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ enquiryStatusTypesApiClient }) => generateBasicActions.list({
  constants: {
    failed: ENQUIRY_STATUS_TYPES_LIST_FAILED,
    init: ENQUIRY_STATUS_TYPES_LIST,
    success: ENQUIRY_STATUS_TYPES_LIST_SUCCESS,
  },
  options,
  service: enquiryStatusTypesApiClient,
  serviceMethod: 'list',
})

export const clear = () => ({
  type: CLEAR_LIST_ENQUIRY_STATUS_TYPES,
})
