import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import auth from 'utils/auth'

import { ChildProductTypes, ONE_OFF_BOOKING_TYPE_OPTIONS } from 'services/booking/childProducts/constants'

import { withAppService } from 'services/app'
import { withChildAdhocSessionsService } from 'services/legacy/childAdhocSessions'
import { withChildProductsService } from 'services/booking/childProducts'

import RegisterExtraSessionModalView from './RegisterExtraSessionModalView'
import { getInitialValues, getPayload } from './helpers'

class RegisterExtraSessionModalContainer extends Component {
  handleRemoveField = (fields, index) => () => {
    fields.remove(index)
  }

  handleAddChild = (fields) => () => {
    fields.push({ child: null, sessions: [{}] })
  }

  handleAddSession = (fields) => () => {
    fields.push({})
  }

  handleSuccess = () => {
    const { hideModal, onSuccess } = this.props

    hideModal()

    if (onSuccess) {
      onSuccess()
    }
  }

  handleSubmit = async (fields) => {
    const {
      childAdhocSessionsActions,
      childProductsActions,
      childProductsSelectors,
      entryDate,
      isFinanceV3Enabled,
    } = this.props

    if (isFinanceV3Enabled) {
      const { children } = fields

      _.forEach(children, (childItem) => {
        const { child, sessions } = childItem

        _.forEach(sessions, async (sessionItem) => {
          const { endTime, startTime } = sessionItem
          const session = sessionItem.sessionId

          const payload = childProductsSelectors.getPayload(undefined, {
            child,
            date: entryDate,
            endTime,
            item: session,
            startTime,
            type: _.find(ONE_OFF_BOOKING_TYPE_OPTIONS, { value: ChildProductTypes.EXTRA_SESSION }),
          })

          await childProductsActions.create({
            body: payload,
            params: [{}],
          })
        })
      })

      this.handleSuccess()
    } else {
      const payload = getPayload(fields, entryDate)

      await childAdhocSessionsActions.createBulk(payload, this.handleSuccess)
    }
  }

  render() {
    const {
      childAdhocSessionsSingleState,
      errorMessages,
      hideModal,
      isFetching,
    } = this.props

    const isLoading = isFetching
    const isSubmitting = childAdhocSessionsSingleState.isSubmitting
    const initialValues = getInitialValues()

    return (
      <RegisterExtraSessionModalView
        errorMessages={errorMessages}
        initialValues={initialValues}
        isLoading={isLoading}
        isSubmitting={isSubmitting}
        onAddChild={this.handleAddChild}
        onAddSession={this.handleAddSession}
        onCloseClick={hideModal}
        onRemoveChild={this.handleRemoveField}
        onRemoveSession={this.handleRemoveField}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, { appSelectors, childAdhocSessionsSingleState }) => ({
  errorMessages: appSelectors.getErrorMessages(childAdhocSessionsSingleState),
  isFetching: appSelectors.getIsFetching(childAdhocSessionsSingleState),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
})

const enhance = compose(
  withAppService,
  withChildAdhocSessionsService,
  withChildProductsService,
  connect(mapState),
)

export default enhance(RegisterExtraSessionModalContainer)
