import { createSelector } from 'reselect'

import { INVOICE_LINE_ITEM_DISPLAY, NurseryConsumablesRootState } from '../models'

export const getNurseryConsumablesSelector = (state: NurseryConsumablesRootState) => state.nurseryConsumablesV3

export const getNurseryConsumablesSingleSelector = createSelector(
  [getNurseryConsumablesSelector],
  (state) => state.single.data,
)

export const getFormInitialValues = (consumable) => {
  const { name, settings } = consumable || {}

  return {
    name,
    settings: {
      invoiceLineItemDisplay: (
        (settings && settings.invoiceLineItemDisplay) || INVOICE_LINE_ITEM_DISPLAY.INCLUDE_IN_SESSION
      ),
    },
  }
}

export const getInitialValues = createSelector(
  [getNurseryConsumablesSingleSelector],
  (consumable) => {
    if (!consumable) {
      return null
    }

    return getFormInitialValues(consumable)
  },
)

export const getPayload = (fields) => {
  if (!fields) {
    return null
  }

  const { name, settings: { invoiceLineItemDisplay } } = fields

  return {
    name,
    settings: {
      invoiceLineItemDisplay: invoiceLineItemDisplay || INVOICE_LINE_ITEM_DISPLAY.INCLUDE_IN_SESSION,
    },
  }
}

export const getArchivePayload = (fields) => {
  if (!fields) {
    return null
  }

  const { archived } = fields

  return {
    archived,
  }
}

export const isArchived = createSelector(
  [getNurseryConsumablesSingleSelector],
  (consumable) => {
    if (!consumable) {
      return null
    }

    return consumable.archived
  },
)
