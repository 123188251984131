import _ from 'lodash'
import moment from 'moment'

export const isDateDisabled = (disabledDays, date, direction = 0) => {
  let response = false

  if (_.isArray(disabledDays)) {
    _.each(disabledDays, (part) => {
      if (part instanceof Date && moment(date).isSame(part)) {
        response = true
      }

      if (part?.before && moment(date).isBefore(moment(part?.before), 'day') && 1 > direction) {
        response = true
      }

      if (part?.after && moment(date).isAfter(moment(part?.after), 'day') && 1 <= direction) {
        response = true
      }
    })
  }

  return response
}

