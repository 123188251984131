import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'
import layout from 'constants/layout'

export const StyledFooterWrapper = styled.div`
  background-color: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  margin: 40px 0 0;
  padding: 20px 30px;
  display: grid;
  grid-template-columns: 1fr repeat(3, auto) 1fr;
  align-items: center;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    display: flex;
    flex-direction: column;
    grid-template-columns: none;
    justify-content: center;
  }
`

export const StyledBlossomImage = styled.img`
  width: 125px;
  height: 40px;
  object-fit: contain;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    margin-top: 10px;
  }
`
