import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledSessionContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ alignItems }) => alignItems && `
    align-items: ${alignItems};
  `}

  ${({ minWidth }) => minWidth && `
    min-width: ${minWidth}px;
  `}
`

export const StyledSessionRow = styled.div`
  flex: 1;
  margin-bottom: 5px;
  font-size: 14px;
  display: flex;
  white-space: nowrap;
`

export const StyledWeekDay = styled.div`
  background: #9B9B9B;
  padding: 2px 4px;
  color: ${NEUTRAL_COLOURS.WHITE};
  margin-right: 10px;
  width: 37px;
  text-align: center;
  border-radius: 4px;
  text-transform: capitalize;
`
