import i18n from 'translations'

export const LEAVE_OPTION = {
  label: i18n.t('services:Rooms:Leaving'),
  value: 0,
}

export const ROOM_FILTER = {
  ACTIVE: 'ACTIVE',
  ALL: 'ALL',
  ARCHIVED: 'ARCHIVED',
}

export const ROOM_FILTER_OPTIONS = [
  {
    label: i18n.t('global:Active'),
    value: ROOM_FILTER.ACTIVE,
  },
  {
    label: i18n.t('global:AllRooms'),
    value: ROOM_FILTER.ALL,
  },
  {
    label: i18n.t('global:Archived'),
    value: ROOM_FILTER.ARCHIVED,
  },
]

export const ROOM_TYPES = {
  ALL: 'all',
  NON_TEACHING: 'nonTeaching',
  OUTSIDE: 'outside',
  TEACHING: 'teaching',
}

export const ROOM_TYPES_OPTIONS = [
  {
    label: i18n.t('module:Rooms:RoomTypes:teaching'),
    value: ROOM_TYPES.TEACHING,
  },
  {
    label: i18n.t('module:Rooms:RoomTypes:nonTeaching'),
    value: ROOM_TYPES.NON_TEACHING,
  },
  {
    label: i18n.t('module:Rooms:RoomTypes:outside'),
    value: ROOM_TYPES.OUTSIDE,
  },
]

export const ROOM_TYPES_OPTIONS_WITH_ALL = [
  {
    label: i18n.t('module:Rooms:RoomTypes:all'),
    value: ROOM_TYPES.ALL,
  },
  ...ROOM_TYPES_OPTIONS,
]

export const ALL_ROOMS = {
  label: i18n.t('global:AllRooms'),
  value: 0,
}
