import _ from 'lodash'

import moment from 'moment'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import {
  Allocation,
  AllocationDefault,
  ChildProductRegularSession,
  ExcludedFundingReasons,
  ExcludedFundingReasonsType,
  RestFundingsDayAllocations,
} from 'services/booking/childBooking/constants'
import {
  OpenedAllocation,
} from 'modals/RegularBookings/AddFundingToRegularBookingsModal/AddFundingToRegularBookingsModalContainer'
import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { formatTime, isSameDay } from 'utils/date'

import { Banner, Button, Form, Grid, Popover, TimePicker, Tooltip, Typography } from 'components'

import i18n from 'translations'

import {
  StyledAllocation,
  StyledDayWrapper,
  StyledExcludedFundingBox,
  StyledFundingFormWrapper,
  StyledHoursTotalWrapper,
  StyledHoursWrapper,
  StyledRestFundingAllocation,
  StyledSession,
  StyledSessionDraft,
} from './AllocationBarStyled'

type AllocationBarProps = {
  allocateHoursWithinSessionTimesError?: boolean
  allocations?: Allocation[] | AllocationDefault[]
  date?: moment.Moment
  disabled?: boolean
  excludedFundingReasons?: ExcludedFundingReasons[]
  isDefault?: boolean
  isLoading?: boolean
  leftLabel?: string
  restFundingsDayAllocations?: RestFundingsDayAllocations[]
  sessions?: Allocation[] | AllocationDefault[]
  summary: string | React.ReactNode
} & (
    {
      isPreview?: false
      onCloseAllocationPopup: () => void
      onCreateAllocation: (session: ChildProductRegularSession, timeRange: any) => void
      onEditAllocation: (id: string, fieldName: string, fieldValue: any, timeIndex: number) => void
      onOpenAllocation: (openedAllocation: OpenedAllocation) => void
      onRemoveAllocation: (id: string) => void
      openedAllocation?: OpenedAllocation
    } | {
      isPreview?: true
      onCloseAllocationPopup?: undefined
      onCreateAllocation?: undefined
      onEditAllocation?: undefined
      onOpenAllocation?: undefined
      onRemoveAllocation?: undefined
      openedAllocation?: undefined
    }
  )

const AllocationBar: React.FC<AllocationBarProps> = ({
  allocateHoursWithinSessionTimesError,
  allocations,
  date,
  disabled,
  excludedFundingReasons,
  isDefault = false,
  isLoading,
  isPreview,
  leftLabel,
  onCloseAllocationPopup,
  onCreateAllocation,
  onEditAllocation,
  onOpenAllocation,
  onRemoveAllocation,
  openedAllocation,
  restFundingsDayAllocations,
  sessions,
  summary,
}) => {
  const {
    id: idOfOpenAllocation,
    isDefault: isOpenedDefaultAllocation,
    timeIndex: timeIndexOfOpenAllocation,
  } = openedAllocation || {}

  const fundingReason = _.find(excludedFundingReasons, (i) => (
    isSameDay(moment(i.date, DEFAULT_DATE_FORMAT), date)
  ))

  const renderAllocation = (allocation: AllocationDefault | Allocation) => {
    const { _extra, product, times } = allocation
    const { id } = _extra || {}
    const { product: subProduct } = product || {} as any
    const { name } = product as any

    return _.map(times, ({ endTime, startTime }, timeIndex) => {
      const openPopover = isDefault === isOpenedDefaultAllocation
        && id === idOfOpenAllocation
        && timeIndex === timeIndexOfOpenAllocation

      return (
        <Popover
          buttonWrapper={(props) => (
            <StyledAllocation
              $endTime={endTime}
              $startTime={startTime}
              {...props}
              onClick={() => !isPreview && onOpenAllocation({ id, isDefault, timeIndex })}
            >
              {!openPopover && (
                <Tooltip
                  placement="top"
                  title={(
                    <React.Fragment>
                      <Typography align="center">
                        {name}
                      </Typography>
                      <Typography align="center">
                        {moment(startTime, 'x').utc().format('HH:mm')}
                        {' - '}
                        {moment(endTime, 'x').utc().format('HH:mm')}
                      </Typography>
                    </React.Fragment>
                  )}
                />
              )}
            </StyledAllocation>
          )}
          disabled={disabled}
          isOpen={openPopover}
          key={id}
          placement="top-center"
          onOutsideClick={onCloseAllocationPopup}
        >
          <StyledFundingFormWrapper>
            <Grid>
              <Grid.Item alignItems={{ desktop: 'center' }} flex={{ desktop: 1 }}>
                <Typography fontSize={18} bold>
                  {name || subProduct?.name}
                </Typography>
              </Grid.Item>
              <Grid.Item alignItems={{ desktop: 'center' }} justifyContent={{ desktop: 'flex-end' }}>
                <Button
                  hierarchy="tertiary"
                  icon="trash"
                  size="small"
                  onClick={() => onRemoveAllocation(id)}
                />
              </Grid.Item>
            </Grid>
            {allocateHoursWithinSessionTimesError && (
              <Banner.Warning>
                {i18n.t('modals:AddFundingToRegularBookings:allocateHoursWithinSessionTimes')}
              </Banner.Warning>
            )}
            <Form.Row
              label={(
                i18n.t('module:Children:Child:BookingPattern:RegularBookings:components:AllocationBar:fundedHours')
              )}
              margin="0"
              verticalLabel
            >
              <Grid>
                <Grid.Item>
                  <TimePicker
                    clearable={false}
                    error={allocateHoursWithinSessionTimesError}
                    local={false}
                    value={startTime}
                    onChange={(value) => onEditAllocation(id, 'startTime', value, timeIndex)}
                  />
                </Grid.Item>
                <Grid.Item>
                  <TimePicker
                    clearable={false}
                    error={allocateHoursWithinSessionTimesError}
                    local={false}
                    value={endTime}
                    onChange={(value) => onEditAllocation(id, 'endTime', value, timeIndex)}
                  />
                </Grid.Item>
                <Grid.Item alignItems={{ desktop: 'center' }}>
                  <Typography fontSize={16}>
                    {formatTime(endTime - startTime)}
                  </Typography>
                </Grid.Item>
              </Grid>
            </Form.Row>
          </StyledFundingFormWrapper>
        </Popover>
      )
    })
  }

  const renderSession = (session: AllocationDefault | Allocation) => {
    const { product, times } = session
    const { id, name } = product as ChildProductRegularSession

    return _.map(times, ({ endTime, startTime }) => (
      <StyledSession
        $endTime={endTime}
        $startTime={startTime}
        key={id}
      >
        <Tooltip
          placement="top"
          title={(
            <React.Fragment>
              <Typography align="center">
                {name}
              </Typography>
              <Typography align="center">
                {moment(startTime, 'x').utc().format('HH:mm')}
                {' - '}
                {moment(endTime, 'x').utc().format('HH:mm')}
              </Typography>
            </React.Fragment>
          )}
        />
      </StyledSession>
    ))
  }

  const renderSessionDraft = ({ end, session, start }, index) => {
    const startTime = start * 1000 * 60
    const endTime = end * 1000 * 60
    const { product } = session
    const { product: subProduct } = product || {} as any

    return (
      <StyledSessionDraft
        $endTime={endTime}
        $startTime={startTime}
        key={`${start}_${end}_${index}`}
        onClick={() => !disabled && !isLoading && onCreateAllocation(session, { endTime, startTime })}
      >
        <Tooltip
          placement="top"
          title={(
            <React.Fragment>
              <Typography align="center">
                {subProduct?.name}
              </Typography>
              <Typography align="center">
                {moment(startTime, 'x').utc().format('HH:mm')}
                {' - '}
                {moment(endTime, 'x').utc().format('HH:mm')}
              </Typography>
            </React.Fragment>
          )}
        />
      </StyledSessionDraft>
    )
  }

  const renderRestFundingAllocation = (session: RestFundingsDayAllocations) => {
    const { product, times } = session
    const { id, name } = product as ChildProductRegularSession

    return _.map(times, ({ endTime, startTime }) => (
      <StyledRestFundingAllocation
        $endTime={endTime}
        $startTime={startTime}
        key={id}
      >
        <Tooltip
          placement="top"
          title={(
            <React.Fragment>
              <Typography align="center">
                {name}
              </Typography>
              <Typography align="center">
                {moment(startTime, 'x').utc().format('HH:mm')}
                {' - '}
                {moment(endTime, 'x').utc().format('HH:mm')}
              </Typography>
            </React.Fragment>
          )}
        />
      </StyledRestFundingAllocation>
    ))
  }

  const partOfSessionsToFill = []

  _.each(sessions, (session: AllocationDefault | Allocation) => {
    const { times } = session

    _.each(times, ({ endTime, startTime }) => {
      const sessionStartTime = startTime / 1000 / 60
      const sessionEndTime = endTime / 1000 / 60

      let sessionTimeRange = []

      for (let i = 0; i <= (sessionEndTime - sessionStartTime); i += 1) {
        sessionTimeRange[i] = sessionStartTime + i
      }

      const getTime = (time) => +moment(time).format('x') / 1000 / 60

      _.each(allocations, (allocation: AllocationDefault | Allocation) => {
        _.each(allocation.times, (time) => {
          for (let i = getTime(time.startTime); i <= getTime(time.endTime); i += 1) {
            // eslint-disable-next-line no-loop-func
            sessionTimeRange = _.filter(sessionTimeRange, (value, index) => (
              value !== i
              || (i === getTime(time.endTime) && sessionTimeRange[index + 1] === value + 1)
              || (i === getTime(time.startTime) && sessionTimeRange[index - 1] === value - 1)
            ))
          }
        })
      })

      _.each(restFundingsDayAllocations, (allocation: AllocationDefault | Allocation) => {
        _.each(allocation.times, (time) => {
          for (let i = getTime(time.startTime); i <= getTime(time.endTime); i += 1) {
            // eslint-disable-next-line no-loop-func
            sessionTimeRange = _.filter(sessionTimeRange, (value, index) => (
              value !== i
              || (i === getTime(time.endTime) && sessionTimeRange[index + 1] === value + 1)
              || (i === getTime(time.startTime) && sessionTimeRange[index - 1] === value - 1)
            ))
          }
        })
      })

      let timesData = []

      if (sessionTimeRange.length) {
        timesData = [sessionTimeRange[0]]
        _.each(sessionTimeRange, (value, key) => {
          if (sessionTimeRange[key + 1] && sessionTimeRange[key] + 1 !== sessionTimeRange[key + 1]) {
            timesData.push(sessionTimeRange[key])
            timesData.push(sessionTimeRange[key + 1])
          }
        })

        timesData.push(sessionTimeRange[sessionTimeRange.length - 1])
      }

      for (let i = 0; i < timesData.length; i += 2) {
        partOfSessionsToFill.push({
          end: timesData[i + 1],
          session,
          start: timesData[i],
        })
      }
    })
  })

  const renderExcludeFundingBox = (reason) => (
    <StyledExcludedFundingBox>
      <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
        {(
          reason === ExcludedFundingReasonsType.CLOSURE_DAYS_SESSION_CHARGEABLE
          || reason === ExcludedFundingReasonsType.CLOSURE_DAYS_SESSION_NOT_CHARGEABLE
        ) && i18n.t('modals:AddFundingToRegularBookings:closureDay')}
        {reason === ExcludedFundingReasonsType.TERM_TIME_HOLIDAYS
          && i18n.t('modals:AddFundingToRegularBookings:termTimeHoliday')}
        {reason === ExcludedFundingReasonsType.EXCLUSION_PERIODS
          && i18n.t('modals:AddFundingToRegularBookings:fundingExclusionPeriod')}
      </Typography>
    </StyledExcludedFundingBox>
  )

  return (
    <Grid spacing={20}>
      <Grid.Item
        alignItems={{ desktop: 'center' }}
        justifyContent={{ desktop: 'flex-end' }}
        width={{ desktop: '70px' }}
      >
        <StyledDayWrapper>
          <Typography align="right" fontSize={18} opacity={disabled ? 0.4 : 1} bold>
            {date ? moment(date).format('ddd') : leftLabel}
          </Typography>
          {date && !isDefault && (
            <Typography align="right" fontSize={14} opacity={disabled ? 0.4 : 1}>
              {moment(date).format('DD.MM')}
            </Typography>
          )}
        </StyledDayWrapper>
      </Grid.Item>
      <Grid.Item alignItems={{ desktop: 'baseline' }} flex={{ desktop: 1 }}>
        <StyledHoursTotalWrapper>
          <StyledHoursWrapper $disabled={disabled} $isLoading={isLoading}>
            {_.map(allocations, renderAllocation)}
            {_.map(sessions, renderSession)}
            {_.map(restFundingsDayAllocations, renderRestFundingAllocation)}
            {!isPreview && _.map(partOfSessionsToFill, renderSessionDraft)}
          </StyledHoursWrapper>
          {fundingReason && renderExcludeFundingBox(fundingReason.reason)}
          {allocations?.[0]?.redistributeRole && renderExcludeFundingBox(allocations[0].redistributeRole)}
        </StyledHoursTotalWrapper>
      </Grid.Item>
      <Grid.Item
        alignItems={{ desktop: 'baseline' }}
        width={{ desktop: '55px' }}
      >
        <Typography
          color={NEUTRAL_COLOURS.GRAY}
          fontSize={14}
          margin="3px 0 0 -10px"
          opacity={disabled ? 0.4 : 1}
          bold
          nowrap
        >
          {summary}
        </Typography>
      </Grid.Item>
    </Grid>
  )
}

export default AllocationBar
