import React from 'react'
import { connect } from 'react-redux'

import actions from './actions'
import selectors from './selectors'
import constants from './constants'

const withInvoicePaymentsService = (WrappedComponent) => {
  const mapState = ({ invoicePayments }) => ({ invoicePayments })

  const mapDispatch = {
    clear: actions.clearInvoicePayment,
    clearImportPayments: actions.clearImportPayments,
    clearSingle: actions.clearInvoicePaymentSingle,
    create: actions.createInvoicePayment,
    download: actions.downloadInvoicePayment,
    downloadTemplate: actions.downloadTemplate,
    exportInvoicePayments: actions.exportInvoicePayments,
    get: actions.getInvoicePayment,
    importPayments: actions.importPayments,
    list: actions.listInvoicePayments,
    remove: actions.deleteInvoicePayment,
    send: actions.sendInvoicePayment,
    update: actions.updateInvoicePayment,
  }

  const Component = (props) => {
    const {
      clear,
      clearImportPayments,
      clearSingle,
      create,
      download,
      downloadTemplate,
      exportInvoicePayments,
      get,
      importPayments,
      invoicePayments,
      list,
      remove,
      send,
      update,
      ...other
    } = props

    const invoicePaymentsListState = {
      ...invoicePayments.list,
      isEmpty: invoicePayments.list.data && 0 === invoicePayments.list.data.length,
    }
    const invoicePaymentsSingleState = invoicePayments.single
    const invoicePaymentsActions = {
      clear,
      clearImportPayments,
      clearSingle,
      create,
      download,
      downloadTemplate,
      export: exportInvoicePayments,
      get,
      importPayments,
      list,
      remove,
      send,
      update,
    }
    const invoicePaymentsSelectors = selectors
    const invoicePaymentsConstants = constants

    return (
      <WrappedComponent
        invoicePaymentsActions={invoicePaymentsActions}
        invoicePaymentsConstants={invoicePaymentsConstants}
        invoicePaymentsImportPaymentsState={invoicePayments.importPayments}
        invoicePaymentsListState={invoicePaymentsListState}
        invoicePaymentsSelectors={invoicePaymentsSelectors}
        invoicePaymentsSingleState={invoicePaymentsSingleState}
        {...other}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withInvoicePaymentsService
