import { generateBasicActions } from 'utils/service'

import {
  CLEAR_INVOICES_BAD_DEBT_TOTAL_STATISTICS,
  GET_INVOICES_BAD_DEBT_TOTAL_STATISTICS,
  GET_INVOICES_BAD_DEBT_TOTAL_STATISTICS_FAILED,
  GET_INVOICES_BAD_DEBT_TOTAL_STATISTICS_SUCCESS,
} from './constants'

export const getBadDebtTotalStatistics = (options = {}) => ({ invoicesApiClient }) => generateBasicActions.list({
  constants: {
    failed: GET_INVOICES_BAD_DEBT_TOTAL_STATISTICS_FAILED,
    init: GET_INVOICES_BAD_DEBT_TOTAL_STATISTICS,
    success: GET_INVOICES_BAD_DEBT_TOTAL_STATISTICS_SUCCESS,
  },
  options,
  service: invoicesApiClient,
  serviceMethod: 'getBadDebtTotalStatistics',
})

export const clear = () => ({
  type: CLEAR_INVOICES_BAD_DEBT_TOTAL_STATISTICS,
})
