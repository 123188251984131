import moment from 'moment'
import _ from 'lodash'

import React from 'react'

import { generateRoute } from 'utils/routing'

import { Button, ChildrenSlider, Form, LastSaveDateTime, Page, Spinner, StickyFooter } from 'components'

import i18n from 'translations'

import Feedback from '../components/Feedback'

import ObservationsEditForm from './components/ObservationsEditForm/ObservationsEditForm'
import ChildObservation from './components/ChildObservation'

import { SAVE_TYPE } from './ObservationsEditContainer'

import { StyledActionButtonContainer, StyledAdditionalObservationContainer } from './ObservationsEditStyled'

export const OBSERVATION_CHILD_FORM = 'OBSERVATION_CHILD_FORM'

const ObservationsEditView = ({
  autosaveProcessing,
  hasParentAppAccess,
  isAddMode,
  isFetching,
  isFromAdd,
  isMontessori,
  isSubmittingAsDraft,
  isSubmittingAsReview,
  montessoriCategories,
  observation,
  observationBasicFormInitialValues,
  observationChildrenFormInitialValues,
  observationStatesDropdown,
  onCheckProcessingFiles,
  onChildFormChange,
  onFormChange,
  onLinkFramework,
  onNextSteps,
  onReloadMedia,
  onRemoveChild,
  onRemoveFile,
  onSelectChild,
  onSubmit,
  onUpdateMedia,
  totalResults,
}) => {
  const renderContent = () => {
    if (isFromAdd && isFetching && isAddMode) {
      return null
    }

    if (isFetching) {
      return (
        <Spinner />
      )
    }

    const hasObservationIds = _.some(observation.childObservations, ({ id }) => !!id)

    return (
      <React.Fragment>
        <ChildrenSlider
          childrenList={_.map(observation.childObservations, ({ child }) => child)}
          withCanBePhotographedLabel
          onRemoveChild={onRemoveChild}
        />
        <StyledActionButtonContainer selectedChildren={!!observation.childObservations}>
          <Button.ActionButton
            label={i18n.t('module:Learning:Observations:ObservationsEdit:selectChildren')}
            onClick={onSelectChild}
          />
        </StyledActionButtonContainer>
        <ObservationsEditForm
          initialValues={observationBasicFormInitialValues}
          isMontessori={isMontessori}
          montessoriCategories={montessoriCategories}
          observation={observation}
          selectedChildren={observation.childObservations}
          totalResults={totalResults}
          onChangeEvent={onFormChange}
          onCheckProcessingFiles={onCheckProcessingFiles}
          onReloadMedia={onReloadMedia}
          onRemoveFile={onRemoveFile}
          onSubmit={() => { }}
          onUpdateMedia={onUpdateMedia}
        />
        {hasObservationIds ? (
          <Form.Row
            label={1 < observation.childObservations.length ? 'Additional Observations' : 'Observation'}
            verticalLabel
          >
            <StyledAdditionalObservationContainer>
              {_.map(observation.childObservations, (childObservation) => (
                <ChildObservation
                  childObservation={childObservation}
                  form={`${OBSERVATION_CHILD_FORM}_${childObservation.child.id}`}
                  hasParentAppAccess={hasParentAppAccess}
                  initialValues={observationChildrenFormInitialValues?.[childObservation.id] || null}
                  isMontessori={isMontessori}
                  key={childObservation.id}
                  observation={observation}
                  observationStatesDropdown={observationStatesDropdown}
                  onChangeEvent={() => onChildFormChange(childObservation.id, childObservation.child.id)}
                  onLinkFramework={onLinkFramework}
                  onNextSteps={onNextSteps}
                  onRemoveChild={onRemoveChild}
                  onSubmit={() => { }}
                />
              ))}
            </StyledAdditionalObservationContainer>
          </Form.Row>
        ) : null}
        <StickyFooter>
          <Button
            disabled={isSubmittingAsReview || isSubmittingAsDraft || autosaveProcessing}
            hierarchy="tertiary"
            label={i18n.t('global:Cancel')}
            to={observation?.id
              ? generateRoute('LEARNING.OBSERVATIONS.REVIEW', { observationId: observation.id })
              : generateRoute('LEARNING.OBSERVATIONS')}
            negativeMargins
          />
          {observation && observation.id && (
            <LastSaveDateTime
              authorName={observation?.author?.name}
              isLoading={isSubmittingAsDraft || isSubmittingAsReview || autosaveProcessing}
              lastSaveDateTime={observation && moment(observation.updatedAt)}
            />
          )}
          <StickyFooter.Flex />
          <Button
            disabled={isSubmittingAsReview || autosaveProcessing || isSubmittingAsDraft}
            hierarchy="secondary"
            isLoading={isSubmittingAsDraft}
            label={i18n.t('global:SaveDraft')}
            negativeMargins
            onClick={() => onSubmit(SAVE_TYPE.DRAFT)}
          />
          <Button
            disabled={isSubmittingAsDraft || autosaveProcessing || isSubmittingAsReview}
            isLoading={isSubmittingAsReview}
            label={i18n.t('module:Learning:Observations:ObservationsEdit:review')}
            negativeMargins
            onClick={() => onSubmit(SAVE_TYPE.REVIEW)}
          />
        </StickyFooter>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Feedback observation={observation} />
      <Page.Section
        isLoading={isFetching}
        title="Make observation"
      >
        {renderContent()}
      </Page.Section>
    </React.Fragment>
  )
}

export default ObservationsEditView
