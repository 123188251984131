import { generateBasicActions } from 'utils/service'

import {
  CLEAR_ENQUIRY_CONVERSION_RATE_STATISTICS,
  GET_ENQUIRY_CONVERSION_RATE_STATISTICS,
  GET_ENQUIRY_CONVERSION_RATE_STATISTICS_FAILED,
  GET_ENQUIRY_CONVERSION_RATE_STATISTICS_SUCCESS,
} from './constants'

export const getConversionRateStatistics = (options = {}) => ({ enquiryReportsApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_ENQUIRY_CONVERSION_RATE_STATISTICS_FAILED,
    init: GET_ENQUIRY_CONVERSION_RATE_STATISTICS,
    success: GET_ENQUIRY_CONVERSION_RATE_STATISTICS_SUCCESS,
  },
  options,
  service: enquiryReportsApiClient,
  serviceMethod: 'getConversionRateStatistics',
})

export const clearConversionRateStatistics = () => ({
  type: CLEAR_ENQUIRY_CONVERSION_RATE_STATISTICS,
})
