import _ from 'lodash'

export const getAuthParams = (Component, state) => (
  _.isFunction(Component.authParams) ? Component.authParams(state) : Component.authParams
)

export const checkArrayItems = (userItems = [], componentItems = []) => (
  componentItems.some((item) => _.includes(userItems, item))
)

export const getFailureComponent = (state, WrappedComponent) => {
  const authParams = getAuthParams(WrappedComponent, state)

  return authParams?.failureComponent ? authParams.failureComponent : null
}
