import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { EVENTS, logEvent } from 'analytics'

import { ROLES } from 'constants/security'
import { AGE_IN_MONTHS_OPTIONS, CHILD_TYPES_OPTIONS } from 'services/legacy/child/constants'

import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withModalService } from 'services/utils/modal'
import { withNurseriesService } from 'services/nurseries'
import { withPaginationUtils } from 'services/utils/pagination'

import ChildAddContainer from '../Child/ChildAdd'

import ChildrenListView from './ChildrenListView'

const NURSERY_GET_GROUPS = {
  read: [
    'nursery.details',
  ],
}

const CHILDREN_GROUPS = {
  read: [
    'child.archived',
  ],
}

class ChildrenListContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fromAge: null,
      isArchived: CHILD_TYPES_OPTIONS[0].value,
      page: 1,
      room: null,
      search: null,
      toAge: null,
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { childActions } = this.props

    childActions.clear()
  }

  fetch = () => {
    const { childActions, childSelectors } = this.props
    const { fromAge, isArchived, page, room, search, toAge } = this.state

    const params = {
      criteria: childSelectors.getCriteria({
        ageRange: {
          from: fromAge?.value,
          to: toAge?.value,
        },
        isArchived,
        room,
        search,
      }),
      groups: isArchived ? CHILDREN_GROUPS : undefined,
      page,
    }

    childActions.list({
      mergeResult: 1 !== page,
      params,
    })
  }

  handleChildTypeFilterChange = (isArchived) => {
    this.setState({
      isArchived: isArchived && isArchived.value,
      page: 1,
    }, this.fetch)
  }

  handleRoomFilterChange = (room) => {
    this.setState({
      page: 1,
      room: room && room.value,
    }, this.fetch)
  }

  handleSearchChange = (search) => {
    this.setState({
      page: 1,
      search,
    }, this.fetch)
  }

  handlePageChange = (page) => {
    this.setState({ page }, this.fetch)
  }

  handleAgeChange = (key) => (value) => {
    this.setState({ [key]: value, page: 1 }, this.fetch)
  }

  handleExportClick = () => {
    const { modalActions, modalConsts, nurseriesActions, nurseryOptions } = this.props

    logEvent(EVENTS.CHILD_LIST_EXPORT_BTN_CLICKED, { context: 'list' })

    nurseriesActions.get(nurseryOptions.id, {
      onSuccess: ({ data }) => {
        modalActions.show(modalConsts.TYPES.CHILDREN_EXPORT, { context: 'list', createdAt: data?.createdAt })
      },
      params: { groups: NURSERY_GET_GROUPS },
    })
  }

  render() {
    const { ChildAddAccess, childExportGranted, childListState, childrenList, isFetching, paginationUtils } = this.props
    const { fromAge, isArchived, page, room, search, toAge } = this.state
    const { getPageCount } = paginationUtils

    const pageCount = getPageCount(childListState.meta.total_results)
    const authAccessMap = { ChildAddAccess }

    return (
      <ChildrenListView
        ageMonthsOptions={AGE_IN_MONTHS_OPTIONS}
        authAccessMap={authAccessMap}
        childExportGranted={childExportGranted}
        childType={isArchived}
        childTypeOptions={CHILD_TYPES_OPTIONS}
        childrenList={childrenList}
        fromAge={fromAge}
        isFetching={isFetching}
        page={page}
        pageCount={pageCount}
        room={room}
        search={search}
        toAge={toAge}
        totalResults={childListState.meta.total_results}
        onAgeChange={this.handleAgeChange}
        onChildTypeFilterChange={this.handleChildTypeFilterChange}
        onExportClick={this.handleExportClick}
        onPageChange={this.handlePageChange}
        onRoomFilterChange={this.handleRoomFilterChange}
        onSearchChange={this.handleSearchChange}
      />
    )
  }
}

const mapState = (state, { appSelectors, childListState, childSelectors, params }) => ({
  ChildAddAccess: auth.SELECTORS.getComponentIsAuthorised(state, ChildAddContainer),
  childExportGranted: auth.SELECTORS.getIsAuthorised(state, {
    roles: [
      ROLES.SUPER_ADMIN,
      ROLES.ORGANIZATION_DIRECTOR,
      ROLES.ORGANIZATION_NATIONAL_ADMIN,
      ROLES.ORGANIZATION_FINANCE_ADMIN,
      ROLES.ORGANIZATION_LINE_MANAGER,
      ROLES.NURSERY_MANAGER,
      ROLES.NURSERY_ADMIN,
    ],
  }),
  childrenList: childSelectors.getChildListDataSelector(state),
  isFetching: appSelectors.getIsFetching(childListState),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const enhance = compose(
  withAppService,
  withChildService,
  withModalService,
  withNurseriesService,
  withPaginationUtils,
  connect(mapState),
)

export default enhance(ChildrenListContainer)
