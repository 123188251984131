import React, { PropsWithChildren } from 'react'
import ReactHighcharts from 'react-highcharts'

import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

import { getColumnChartConfig } from './ColumnChartHelpers'

interface ColumnChartProps {
  categories: string[]
  config?: any
  data: any
  enableLegends?: boolean
  isCurrencyValue?: boolean
  onChartPointClick?: (e: any) => {}
}

const ColumnChart: React.FC<PropsWithChildren<ColumnChartProps>> = ({
  categories,
  config,
  data,
  enableLegends = false,
  isCurrencyValue,
  onChartPointClick,
}) => (
  <SubdomainCurrencyProvider>
    {({ currencySymbol, getFormattedCurrencyValue }) => (
      <ReactHighcharts
        config={getColumnChartConfig(
          categories,
          config,
          data,
          enableLegends,
          isCurrencyValue,
          onChartPointClick,
          currencySymbol,
          getFormattedCurrencyValue,
        )}
      />
    )}
  </SubdomainCurrencyProvider>
)

export default ColumnChart
