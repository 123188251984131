import React from 'react'

import ImagesSize from 'constants/imagesSize'

import { isVideoMimeType } from 'utils/attachment'
import { getOptimizedImage } from 'utils/image'

import { VideoPlayer } from 'components'

import { StyledContainer, StyledImage } from './PreviewFileStyled'

const PreviewFile = ({ currentIndex, data, views }) => {
  const { file, id, playerMimeType, poster } = data
  const isVideo = isVideoMimeType(playerMimeType)
  const { id: viewId } = views[currentIndex]

  if (id !== viewId) {
    return null
  }

  if (isVideo) {
    return (
      <VideoPlayer id={id} mimeType={playerMimeType} poster={poster} src={file} />
    )
  }

  return (
    <StyledContainer>
      <StyledImage src={getOptimizedImage(file, ImagesSize.FULL_VIEW)} />
    </StyledContainer>
  )
}

export default PreviewFile
