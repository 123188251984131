import { flatten, nest } from 'utils/flatnest'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { MODULE_TYPE } from 'module/Modals/Enquiry/ExportModal/constants'
import { ENQUIRY_STATUS_CODES } from 'services/legacy/enquiries/constants'

import { getDateString } from 'utils/date'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withEnquiriesService } from 'services/legacy/enquiries'
import { withPaginationUtils } from 'services/utils/pagination'
import { withSortingUtils } from 'services/utils/sorting'
import { withRouter } from 'services/router'

import EnquiriesView from './ListView'
import { getEnquiries } from './ListHelper'

const ENQUIRY_GROUPS = {
  read: [
    'enquiry.status',
    'enquiryStatusType',
    'enquiry.showAroundCount',
    'enquiry.showAroundStatus',
    'enquiry.weeklyValue',
    'enquiry.data',
  ],
}

const DEFAULT_SORT = {
  sortField: 'enquiryDate',
  sortOrder: 'desc',
}

const DEFAULT_STATUS_FILTER = {
  not: {
    status: [
      ENQUIRY_STATUS_CODES.ENROLLED,
      ENQUIRY_STATUS_CODES.LOST_ENQUIRY,
    ],
  },
}

class ListContainer extends Component {
  constructor(props) {
    super(props)

    const { query } = props.location
    const { enquiryDate, search, startDate, status, viewingStatus } = nest(query)

    this.state = {
      enquiryDate: {
        after: enquiryDate?.after ? getDateString(enquiryDate.after) : undefined,
        before: enquiryDate?.before ? getDateString(enquiryDate.before) : undefined,
      },
      search,
      startDate: {
        after: startDate?.after ? getDateString(startDate.after) : undefined,
        before: startDate?.before ? getDateString(startDate.before) : undefined,
      },
      status,
      viewingStatus,
    }
  }

  componentDidMount() {
    this.fetch()
  }

  fetch = () => {
    const {
      enquiriesActions,
      enquiriesSelectors,
      paginationUtils,
      setLocationQuery,
      sortingUtils,
    } = this.props
    const {
      enquiryDate,
      search,
      startDate,
      status,
      viewingStatus,
    } = this.state

    const { page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const filters = {
      enquiryDate,
      search,
      startDate,
      status,
      viewingStatus,
    }

    setLocationQuery(flatten(filters))

    const criteria = enquiriesSelectors.getCriteria({
      ...filters,
      ...(status ? {} : DEFAULT_STATUS_FILTER),
    })

    const apiParams = {
      criteria,
      groups: ENQUIRY_GROUPS,
      order: {
        sortField: sortField || DEFAULT_SORT.sortField,
        sortOrder: sortField ? sortOrder : DEFAULT_SORT.sortOrder,
      },
      page,
    }

    enquiriesActions.list({
      params: [apiParams],
    })
  }

  componentWillUnmount() {
    const { enquiriesActions } = this.props

    enquiriesActions.clear()
  }

  handleSearchChange = (search) => {
    this.setState({ search }, () => this.handlePageChange(1))
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleEnquiryDateChange = (enquiryDate) => {
    this.setState({
      enquiryDate: {
        after: enquiryDate[0] ? getDateString(enquiryDate[0]) : undefined,
        before: enquiryDate[1] ? getDateString(enquiryDate[1]) : undefined,
      },
    }, () => this.handlePageChange(1))
  }

  handleStartDateChange = (startDate) => {
    this.setState({
      startDate: {
        after: startDate[0] ? getDateString(startDate[0]) : undefined,
        before: startDate[1] ? getDateString(startDate[1]) : undefined,
      },
    }, () => this.handlePageChange(1))
  }

  handleStatusChange = (status) => {
    this.setState({ status: status?.value }, () => this.handlePageChange(1))
  }

  handleViewingStatusChange = (viewingStatus) => {
    this.setState({ viewingStatus: viewingStatus?.value }, () => this.handlePageChange(1))
  }

  handleSortChange = (key) => {
    const { sortingUtils } = this.props
    const { onSortChange } = sortingUtils

    onSortChange(() => this.handlePageChange(1))(key)
  }

  handleExportClick = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.ENQUIRY_EXPORT, {
      moduleType: MODULE_TYPE.ENQUIRY,
    }, {
      enableMultipleModal: true,
    })
  }

  render() {
    const {
      enquiries,
      isFetching,
      paginationUtils,
      sortingUtils,
      totalResults,
    } = this.props
    const {
      enquiryDate,
      search,
      startDate,
      status,
      viewingStatus,
    } = this.state

    const { getPageCount, page, perPage } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const pageCount = getPageCount(totalResults)

    return (
      <EnquiriesView
        enquiries={getEnquiries(enquiries)}
        enquiryDate={[enquiryDate?.after, enquiryDate?.before]}
        isLoading={isFetching}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        search={search}
        sortField={sortField || DEFAULT_SORT.sortField}
        sortOrder={sortField ? sortOrder : DEFAULT_SORT.sortOrder}
        startDate={[startDate?.after, startDate?.before]}
        status={status}
        totalResults={totalResults}
        viewingStatus={viewingStatus}
        onEnquiryDateChange={this.handleEnquiryDateChange}
        onExportClick={this.handleExportClick}
        onPageChange={this.handlePageChange}
        onSearchChange={this.handleSearchChange}
        onSortChange={this.handleSortChange}
        onStartDateChange={this.handleStartDateChange}
        onStatusChange={this.handleStatusChange}
        onViewingStatusChange={this.handleViewingStatusChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  enquiriesListState,
  enquiriesSelectors,
}) => ({
  enquiries: enquiriesSelectors.getListDataSelector(state),
  errorMessages: appSelectors.getErrorMessages(enquiriesListState),
  isFetching: appSelectors.getIsFetching(enquiriesListState),
  totalResults: appSelectors.getTotalResults(enquiriesListState),
})

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withEnquiriesService,
  withPaginationUtils,
  withSortingUtils,
  connect(mapState),
)

export default enhance(ListContainer)
