import _ from 'lodash'

import React from 'react'

import colors, { FLAG_COLOURS } from 'constants/colors'

import { Avatar, Button, DateString, Icon, Spinner, Tooltip, Typography } from 'components'

import i18n from 'translations'

import { StyledAvatarContainer, StyledAvatarContentContainer, StyledConfirmed } from './RecipientsStyled'

const getResendComponent = ({ hasAccessParentApp, id, isResending, isResent, onResendClick, shouldReceiveEmail }) => {
  if (!hasAccessParentApp && !shouldReceiveEmail) {
    return (
      <Typography>
        {i18n.t('module:Newsletters:Preview:Recipients:noAccessToParentApp')}
      </Typography>
    )
  }

  if (isResent) {
    return (
      <StyledConfirmed>
        <Icon
          color={FLAG_COLOURS.SUCCESS}
          height={18}
          icon="confirmed"
        />
        <Typography color={FLAG_COLOURS.SUCCESS} margin="0 0 0 8px" variant="span">
          {i18n.t('module:Newsletters:Preview:Recipients:sentLabel')}
        </Typography>
      </StyledConfirmed>
    )
  }

  if (isResending) {
    return <Spinner />
  }

  return (
    <Tooltip
      placement="bottom"
      title={!shouldReceiveEmail && i18n.t('module:Newsletters:Preview:Recipients:noReceiveViaEmail')}
    >
      <Button
        disabled={!shouldReceiveEmail}
        hierarchy="tertiary"
        icon="resend"
        label={i18n.t('module:Newsletters:Preview:Recipients:resendLabel')}
        size="small"
        negativeMargins
        onClick={() => onResendClick(id)}
      />
    </Tooltip>
  )
}

export const getTableData = ({ onRemoveItemClick, onResendClick, tableData }) => {
  if (!tableData || !tableData.length) {
    return null
  }

  return _.map(tableData, ({
    hasAccessParentApp,
    id,
    initials,
    isDeleting,
    isResending,
    isResent,
    photo,
    sendDate,
    shouldReceiveEmail,
    subTitle,
    title,
  }) => ({
    name: (
      <StyledAvatarContainer>
        <Avatar avatarSize="small" initials={initials} src={photo} />
        <StyledAvatarContentContainer>
          <Typography>{title}</Typography>
          <Typography color={colors.gray} fontSize={14}>
            {subTitle}
          </Typography>
        </StyledAvatarContentContainer>
      </StyledAvatarContainer>
    ),
    remove: isDeleting ? (
      <Spinner />
    ) : (
      <Button
        color="error"
        hierarchy="tertiary"
        icon="close"
        size="small"
        negativeMargins
        onClick={() => onRemoveItemClick(id, title)}
      />
    ),
    resend: getResendComponent({
      hasAccessParentApp,
      id,
      isDeleting,
      isResending,
      isResent,
      onResendClick,
      shouldReceiveEmail,
    }),
    sendDate: <DateString date={sendDate} />,
  }))
}
