import styled from 'styled-components'

import layout from 'constants/layout'
import { getBrandingColor } from 'utils/branding'

export const StyledMenuBar = styled.div`
  display: flex;
  flex: 1;
  height: ${layout.menuBarHeight}px;
  justify-content: center;
  background: ${getBrandingColor('primary-color')} !important;
  
  @media(max-width: 580px) {
    display: none;
  }
`

export const StyledWrapperItems = styled.nav`
  display: flex;
  align-items: center;
`
