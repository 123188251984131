import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { REFERENCE_ASYNC_PAGE_TYPES } from 'services/legacy/upload/constants'

import { isRequired } from 'utils/fieldValidation'

import { Form, Spinner } from 'components'

import i18n from 'translations'

import { properties } from 'app-config'

export const ACTIVITY_TAB_FORM = 'ActivityTabForm'

const ActivityTabForm = ({
  activityId,
  handleSubmit,
  isLoading,
  isSubmitting,
  onCancel,
  onCheckProcessingFiles,
  onReloadMedia,
  onRemoveFile,
  onSubmit,
  onUpdateMedia,
  selectedChildren,
  totalResults,
}) => {
  if (isLoading) {
    return (
      <Spinner />
    )
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Row label={i18n.t('module:DailyDiary:Activities:activityName')} verticalLabel>
        <Form.Row.FlexItem flex="0 0 280px" mobileFull>
          <Field
            component={Form.TextField}
            disabled={isSubmitting}
            name="name"
            placeholder={i18n.t('module:DailyDiary:Activities:activityName')}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('global:Time')} verticalLabel>
        <Form.Row.Item>
          <Field
            component={Form.TimePicker}
            disabled={isSubmitting}
            name="recordedAt"
            validate={isRequired}
          />
        </Form.Row.Item>
      </Form.Row>
      <Form.Row label={i18n.t('module:DailyDiary:global:additionalNotes')} verticalLabel>
        <Form.Row.FlexItem flex="0 0 420px" mobileFull>
          <Field
            component={Form.TextAreaField}
            disabled={isSubmitting}
            height={120}
            maxCharacters={2000}
            name="comments"
            placeholder={i18n.t('module:DailyDiary:global:enterAdditionalNotesHere')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:DailyDiary:Activities:Media')} verticalLabel>
        <Form.Row.FlexItem mobileFull>
          <Field
            childrenList={selectedChildren}
            component={Form.MediaPicker}
            disabled={isSubmitting}
            filesLimit={properties.upload.limits.activity}
            name="media"
            referencePage={[REFERENCE_ASYNC_PAGE_TYPES.ACTIVITY, activityId]}
            totalResults={totalResults}
            onCheckProcessingFiles={onCheckProcessingFiles}
            onReloadMedia={onReloadMedia}
            onRemoveFile={onRemoveFile}
            onUpdateMedia={onUpdateMedia}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.FooterActions
        isSubmitting={isSubmitting}
        onCancelClick={onCancel}
      />
    </Form>
  )
}

export default reduxForm({ form: ACTIVITY_TAB_FORM })(ActivityTabForm)
