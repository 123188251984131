import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { generateRoute } from 'utils/routing'

import { INJURIES_STATUS, TAB_NAMES } from 'services/legacy/injuries/constants'

import { Avatar, DefinitionList, EmptyState, GridLayout, Page, Section, Spinner, Tabs, Typography } from 'components'

import i18n from 'translations'

import InjuryStickyFooter from './components/InjuryStickyFooter'
import InjurySignature from '../InjurySignature'
import ChildPreview from '../components/ChildPreview'
import HomeAccidentPreview from '../components/HomeAccidentPreview'

import { INJURY_PREVIEW_PAGE_TYPE } from './constants'

const InjurySingleRecordView = ({
  authAccessMap,
  childId,
  injuryPreview,
  isChildContext,
  isFetching,
  isGrantedEdit,
  isGrantedRequestForApproval,
  isHomeAccidents,
  isSubmittingUpdateStatus,
  onChangeTab,
  onRemove,
  onSendReport,
  onUpdateStatus,
  pageType,
  tab,
}) => {
  const {
    date,
    id,
    injured,
    location,
    otherSeniorInCharge,
    otherStaffSupervising,
    otherWitness,
    senior,
    signatureTotal,
    status,
    supervisor,
    type,
    witnesses,
  } = injuryPreview
  const { all = 0, signed = 0 } = signatureTotal
  const isApprovalsContext = pageType === INJURY_PREVIEW_PAGE_TYPE.APPROVALS

  const editHomeAccidents = isChildContext
    ? generateRoute('CHILDREN.CHILD.SAFEGUARDING.HOME_ACCIDENTS.SINGLE_EDIT', { childId, injuryId: id })
    : generateRoute('SAFEGUARDING.HOME_ACCIDENTS.SINGLE_EDIT', { injuryId: id })

  const addAccidentIncident = isChildContext
    ? generateRoute('CHILDREN.CHILD.SAFEGUARDING.INJURY.SINGLE_EDIT', { childId, injuryId: id })
    : generateRoute('SAFEGUARDING.INJURY.SINGLE_EDIT', { injuryId: id })

  const edit = isHomeAccidents ? editHomeAccidents : addAccidentIncident
  const child = injured?.length ? injured[0].child : ''

  const actions = (
    <Section.Actions
      options={[{
        auth: isGrantedEdit,
        to: isApprovalsContext
          ? generateRoute('APPROVALS.INJURY.EDIT', { injuryId: id })
          : edit,
        type: 'edit',
      }, {
        auth: injured?.length
          && status !== INJURIES_STATUS.DRAFT.value
          && status !== INJURIES_STATUS.PENDING_APPROVAL.value,
        onClick: onSendReport,
        type: 'export',
      }, {
        auth: isGrantedEdit,
        onClick: onRemove,
        type: 'remove',
      }]}
    />
  )

  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    if (!isFetching && injuryPreview && !injuryPreview.id) {
      return (
        <EmptyState
          text1={i18n.t('module:Injury:Preview:emptyStateText')}
        />
      )
    }

    const renderHomeAccidentPreview = () => {
      if (!injured.length) {
        return null
      }

      return (<HomeAccidentPreview injuryPreview={injuryPreview} />)
    }

    return (
      <React.Fragment>
        {isHomeAccidents && !isChildContext && (
          <DefinitionList
            margin="0 0 20px 10px"
            vertical
          >
            <Typography fontSize={18} margin="0 0 10px 10px" bold>
              {_.upperFirst(i18n.t('global:child'))}
            </Typography>
            <Avatar
              avatarSize="medium"
              direction="horizontal"
              initials={[child.firstName, child.surname]}
              src={child.photo}
              title={(
                <Typography>
                  {`${child.firstName || ''} ${child.surname || ''}`}
                </Typography>
            )}
            />
          </DefinitionList>
        )}
        <Tabs
          defaultPosition={tab}
          fullWidth={false}
          value={tab}
          lazyLoadBody
          onChange={onChangeTab}
        >
          <Tabs.Item
            name={TAB_NAMES.DETAILS}
            title={i18n.t('module:Injury:Preview:Details:title')}
          >
            <GridLayout>
              <GridLayout.Group noVerticalMargin>
                <GridLayout.Item column>
                  <DefinitionList
                    margin="0 0 30px 0"
                    vertical
                  >
                    <DefinitionList.Item
                      label={_.upperFirst(i18n.t('global:status'))}
                      size="big"
                      value={(
                        <Typography color={INJURIES_STATUS[status]?.color()}>
                          {INJURIES_STATUS[status]?.label}
                        </Typography>
                      )}
                    />
                  </DefinitionList>
                </GridLayout.Item>
                <GridLayout.Item column>
                  <DefinitionList
                    margin="0 0 30px 0"
                    vertical
                  >
                    <DefinitionList.Item
                      label={isHomeAccidents
                        ? i18n.t('module:Safeguarding:Fields:AccidentLocation:label')
                        : _.upperFirst(i18n.t('global:location'))}
                      value={location || '-'}
                      whiteSpace="pre-wrap"
                      wordBreak="break-word"
                    />
                  </DefinitionList>
                </GridLayout.Item>
                <GridLayout.Item column>
                  <DefinitionList
                    margin="0 0 30px 0"
                    vertical
                  >
                    <DefinitionList.Item
                      label={isHomeAccidents
                        ? i18n.t('module:Safeguarding:Fields:DateAccident:label')
                        : i18n.t('module:Injury:Preview:Details:dateLabel', { type: type.toLowerCase() })}
                      value={date ? moment(date).format('DD/MM/YYYY') : '-'}
                    />
                  </DefinitionList>
                </GridLayout.Item>
                <GridLayout.Item column>
                  <DefinitionList
                    margin="0 0 30px 0"
                    vertical
                  >
                    <DefinitionList.Item
                      label={isHomeAccidents
                        ? i18n.t('module:Safeguarding:Fields:TimeAccident:label')
                        : i18n.t('module:Injury:Preview:Details:timeLabel', { type: type.toLowerCase() })}
                      value={date ? moment(date).format('HH:mm') : '-'}
                    />
                  </DefinitionList>
                </GridLayout.Item>
              </GridLayout.Group>
              {!isHomeAccidents && (
                <GridLayout.Group noVerticalMargin>
                  <GridLayout.Item column>
                    <DefinitionList
                      margin="0 0 30px 0"
                      vertical
                    >
                      <DefinitionList.Item
                        label={i18n.t('module:Injury:Preview:Details:staffSupervisingLabel')}
                        value={supervisor?.name || otherStaffSupervising}
                      />
                    </DefinitionList>
                  </GridLayout.Item>
                  <GridLayout.Item column>
                    <DefinitionList
                      margin="0 0 30px 0"
                      vertical
                    >
                      <DefinitionList.Item
                        label={i18n.t('module:Injury:Preview:Details:seniorInChargeLabel')}
                        value={senior?.name || otherSeniorInCharge}
                      />
                    </DefinitionList>
                  </GridLayout.Item>
                  <GridLayout.Item column>
                    <DefinitionList
                      margin="0 0 30px 0"
                      vertical
                    >
                      <DefinitionList.Item
                        label={i18n.t('module:Injury:Preview:Details:witnessedByLabel')}
                        value={(
                          <Typography>
                            {witnesses && witnesses.length
                              ? _.map(witnesses, (e, index) => `${e.name}${index + 1 === witnesses.length ? '' : ', '}`)
                              : ''}
                            {otherWitness ? `, ${otherWitness}` : ''}
                          </Typography>
                        )}
                        whiteSpace="normal"
                      />
                    </DefinitionList>
                  </GridLayout.Item>
                  <GridLayout.Item />
                </GridLayout.Group>
              )}
            </GridLayout>
            {isHomeAccidents ? renderHomeAccidentPreview() : (<ChildPreview injuryPreview={injuryPreview} />)}
          </Tabs.Item>
          <Tabs.Item
            disabled={status === INJURIES_STATUS.DRAFT.value}
            name={TAB_NAMES.SIGNATURES}
            title={status === INJURIES_STATUS.DRAFT.value
              ? i18n.t('module:Injury:Preview:Signatures:title')
              : i18n.t('module:Injury:Preview:Signatures:titleWithCount', { all, signed })}
            tooltip={status === INJURIES_STATUS.DRAFT.value && (isHomeAccidents
              ? i18n.t('module:Safeguarding:Preview:Signatures:titleTooltip')
              : i18n.t('module:Injury:Preview:Signatures:titleTooltip'))}
          >
            <InjurySignature />
          </Tabs.Item>
        </Tabs>
        <InjuryStickyFooter
          authAccessMap={authAccessMap}
          isGrantedRequestForApproval={isGrantedRequestForApproval}
          isHomeAccidents={isHomeAccidents}
          isSubmittingUpdateStatus={isSubmittingUpdateStatus}
          status={status}
          onUpdateStatus={onUpdateStatus}
        />
      </React.Fragment>
    )
  }

  const pageTitle = () => !isFetching && (
    <Typography bold={isHomeAccidents} fontSize={22} margin="10px 0 0 " variant="p">
      {isHomeAccidents ? i18n.t('module:Safeguarding:List:previewTitle') : _.upperFirst(type)}
    </Typography>
  )

  return (
    <Page.Section
      actions={!isFetching && actions}
      isFetching={isFetching}
      title={pageTitle()}
    >
      {renderContent()}
    </Page.Section>
  )
}

export default InjurySingleRecordView
