import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

interface StyledHrProps {
  $color?: string
  $margin?: string
}

export const StyledHr = styled.div<StyledHrProps>`
  border-bottom: 1px dashed ${NEUTRAL_COLOURS.SILVER};
  
  ${({ $margin }) => $margin && `
    margin: ${$margin};
  `}

  ${({ $color }) => $color && `
    border-bottom-color: ${$color};
  `}
`
