import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as enquiryReportsSelectors from './selectors'

const withEnquiryReportsService = (WrappedComponent) => {
  const mapState = ({ enquiryReports }) => ({ enquiryReports })

  const mapDispatch = injectActions('enquiryReportsActions', actions)

  const Component = ({ enquiryReports, enquiryReportsActions, ...other }) => (
    <WrappedComponent
      enquiryReportsActions={enquiryReportsActions}
      enquiryReportsConversionRateState={enquiryReports.conversionRate}
      enquiryReportsConversionRateStatisticsState={enquiryReports.conversionRateStatistics}
      enquiryReportsPerformanceState={enquiryReports.performance}
      enquiryReportsSelectors={enquiryReportsSelectors}
      enquiryReportsViewingPerformanceState={enquiryReports.viewingPerformance}
      enquiryReportsViewingPerformanceStatisticsState={enquiryReports.viewingPerformanceStatistics}
      {...other}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withEnquiryReportsService
