import _ from 'lodash'
import { compose } from 'recompose'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, getFormValues, stopSubmit } from 'redux-form'

import { getBackendErrors } from 'utils/backendErrors'
import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withNewslettersService } from 'services/legacy/newsletters'
import { withSnackbarService } from 'services/utils/snackbar'
import { getAuthNursery, getAuthUser } from 'services/security/selectors'
import { withRouter } from 'services/router'

import i18n from 'translations'

import NewslettersAddView from './NewslettersAddView'
import { NEWSLETTERS_FORM } from './components/NewslettersAddForm'

const NEWSLETTERS_SINGLE_GROUPS = {
  read: [
    'medium',
    'newsletter.filters',
    'newsletter.media',
    'newsletter.author',
    'user',
  ],
}

class NewslettersAddContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      disableUnsavedPopup: false,
    }
  }

  componentDidMount() {
    const {
      newsActions,
      params,
      router,
    } = this.props
    const { newsId } = params

    if (newsId) {
      newsActions.get(newsId, { groups: NEWSLETTERS_SINGLE_GROUPS })
    }

    router.setRouteLeaveHook(router.routes[router.routes.length - 1], this.handleUnsavedPopup)
  }

  componentWillUnmount() {
    const { newsActions } = this.props

    newsActions.clearSingle()
  }

  handleUnsavedPopup = (nextLocation) => {
    const { modalActions, modalConsts } = this.props
    const { disableUnsavedPopup } = this.state

    if (disableUnsavedPopup) {
      return null
    }

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      cancelButtonLabel: i18n.t('global:Cancel'),
      confirmButtonLabel: i18n.t('global:Discard'),
      icon: 'trash',
      onConfirm: () => this.handleUnsavedPopupSuccess(nextLocation),
      text: i18n.t('module:Newsletters:Add:discardPost'),
    })

    return false
  }

  handleUnsavedPopupSuccess = (nextLocation) => {
    const { navigate } = this.props

    this.setState({ disableUnsavedPopup: true }, () => {
      navigate(nextLocation)
    })

    return true
  }

  redirectToPage = () => {
    const { navigate } = this.props

    this.setState({ disableUnsavedPopup: true }, () => {
      navigate(generateRoute('NEWSLETTERS.INDEX'))
    })
  }

  handleSubmitSuccess = () => {
    this.redirectToPage()
  }

  handleSubmitFailed = (response) => {
    const { injectValidation } = this.props
    const errors = getBackendErrors(response)
    if (!errors) {
      return false
    }

    return injectValidation(NEWSLETTERS_FORM, errors)
  }

  handleSubmitWrapper = (values, publish = false) => {
    const { news, newsActions, newsSelectors } = this.props

    const body = newsSelectors.getSingleNewsPayload({ ...values, publish })

    if (news?.id) {
      return newsActions.update(
        news.id,
        body,
        { groups: NEWSLETTERS_SINGLE_GROUPS },
        this.handleSubmitSuccess,
        this.handleSubmitFailed,
      )
    }

    return newsActions.create(
      body,
      { groups: NEWSLETTERS_SINGLE_GROUPS },
      this.handleSubmitSuccess,
      this.handleSubmitFailed,
    )
  }

  handleSubmit = (fields) => this.handleSubmitWrapper(fields)

  handlePublishClick = (publish = true) => {
    const { formValues, injectValidation, newsSelectors } = this.props

    const formErrors = newsSelectors.getPublishFormErrors(formValues)

    if (_.isEmpty(formErrors)) {
      return this.handleSubmitWrapper(formValues, !!publish)
    }

    return injectValidation(NEWSLETTERS_FORM, formErrors)
  }

  handleChangeMedia = (media) => {
    const { changeFieldValue, formValues, snackbarActions } = this.props

    if (formValues?.newsletterMedia?.length > media?.length) {
      snackbarActions.show({
        message: i18n.t('services:Files:fileRemoved'),
      })
    }

    changeFieldValue('newsletterMedia', media)
  }

  handleCancelClick = () => {
    const { navigate } = this.props

    navigate(generateRoute('NEWSLETTERS.INDEX'))
  }

  render() {
    const {
      currentUser,
      errorMessages,
      initialValues,
      isFetching,
      isSubmitting,
      news,
      nursery,
    } = this.props

    return (
      <NewslettersAddView
        currentUser={currentUser}
        errorMessages={errorMessages}
        initialValues={initialValues}
        isEdit={news && news.id}
        isFetching={isFetching}
        isSubmitting={isSubmitting}
        news={news}
        nursery={nursery}
        onCancelClick={this.handleCancelClick}
        onChangeMedia={this.handleChangeMedia}
        onDraftClick={() => this.handlePublishClick(false)}
        onPublishClick={this.handlePublishClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  changeFieldValue: (field, value) => change(NEWSLETTERS_FORM, field, value),
  injectValidation: (formName, errors) => stopSubmit(formName, errors),
}

const mapState = (state, { appSelectors, newsSelectors, newsSingleState }) => ({
  currentUser: getAuthUser(state),
  errorMessages: appSelectors.getErrorMessages(newsSingleState),
  formValues: getFormValues(NEWSLETTERS_FORM)(state),
  initialValues: newsSelectors.getSingleNewsInitialValuesSelectors(state),
  isFetching: appSelectors.getIsFetching(newsSingleState),
  isSubmitting: appSelectors.getIsSubmitting(newsSingleState),
  news: newsSelectors.getSingleNewsData(state),
  nursery: getAuthNursery(state),
})

const enhance = compose(
  withAppService,
  withModalService,
  withNewslettersService,
  withRouter,
  withSnackbarService,
  connect(mapState, mapDispatch),
)

export default enhance(NewslettersAddContainer)
