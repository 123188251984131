import React from 'react'

import { STATUSES, STATUS_TYPES } from 'services/legacy/invoices/constants'
import { Invoice } from 'services/legacy/invoices/models'
import { NurseryFundingProduct, NurseryFundingProductType } from 'services/product/nurseryFundingV3/constants'

import { generateRoute } from 'utils/routing'

import { Hyperlink } from 'components'

import i18n from 'translations'

interface InvoiceStatusProps {
  childId: number
  invoice: Invoice
  product: NurseryFundingProduct
}

const InvoiceStatus = ({
  childId,
  invoice,
  product,
}: InvoiceStatusProps): JSX.Element => {
  if (product?.type === NurseryFundingProductType.NURSERY_NO_DEDUCT_ONE_OFF_FUNDING) {
    return i18n.t('module:Finance:OneOffBookings:InvoiceStatus:nonDeductible')
  }

  if (invoice) {
    const { id, number, status } = invoice
    const invoiceLink = generateRoute('CHILDREN.CHILD.FINANCE.SINGLE_INVOICE', { childId, invoiceId: id })

    if (status === STATUS_TYPES.DRAFT) {
      return (
        <Hyperlink to={invoiceLink} primary>
          {STATUSES[STATUS_TYPES.DRAFT]}
        </Hyperlink>
      )
    }

    return (
      <React.Fragment>
        {STATUSES[status]}
        <Hyperlink to={invoiceLink} inline primary>
          {` (${number})`}
        </Hyperlink>
      </React.Fragment>
    )
  }

  return i18n.t('module:Finance:Deposits:List:Table:Column:InvoiceStatus:pendingInvoice')
}

export default InvoiceStatus
