import _ from 'lodash'

import React from 'react'
import Headroom from 'react-headroom'

import { MenuItem } from 'components'

import MenuSidebar from '../MenuSidebar'
import { StyledMenuBar, StyledWrapperItems } from './MenuBarStyled'

const MenuBar = ({
  authUser,
  dashboardIcon,
  dashboardTitle,
  isOffline,
  menuItems,
  menuItemsToShow,
  onChangeSidebar,
  onHelpClick,
  onLogout,
  onPin,
  onShowPremiumModal,
  onUnfix,
  onUnpin,
  sidebarIsOpen,
}) => {
  const renderContent = () => {
    let copyMenuItemsToShow = menuItemsToShow

    if (menuItems.length === menuItemsToShow + 1) {
      copyMenuItemsToShow += 1
    }

    return _.times(copyMenuItemsToShow, (i) => {
      const item = menuItems[i]

      if (!item) {
        return null
      }

      const {
        availableInOfflineMode,
        badgeValue,
        dashboardType,
        hidden,
        icon,
        isNew,
        label,
        premium,
        to,
      } = item

      if (dashboardType) {
        return (
          <MenuItem
            disabled={isOffline}
            key={i}
            label={dashboardTitle}
            logo={dashboardIcon}
            to="/"
            onChangeSidebar={onChangeSidebar}
          />
        )
      }

      return (
        <MenuItem
          badgeValue={badgeValue}
          disabled={isOffline && !availableInOfflineMode}
          hidden={hidden}
          icon={icon}
          isNew={isNew}
          key={i}
          label={label}
          premium={premium}
          to={to}
          onChangeSidebar={onChangeSidebar}
          onShowPremiumModal={onShowPremiumModal}
        />
      )
    })
  }

  const renderMoreItem = () => {
    if (menuItems.length <= menuItemsToShow + 1) {
      return null
    }

    return (
      <MenuItem
        disabled={isOffline}
        icon="more"
        label="More"
        onChangeSidebar={onChangeSidebar}
        onClick={() => onChangeSidebar(!sidebarIsOpen)}
      />
    )
  }

  return (
    <div>
      <Headroom
        onPin={onPin}
        onUnfix={onUnfix}
        onUnpin={onUnpin}
      >
        <StyledMenuBar>
          <StyledWrapperItems>
            {renderContent()}
            {renderMoreItem()}
          </StyledWrapperItems>
        </StyledMenuBar>
      </Headroom>
      <MenuSidebar
        authUser={authUser}
        dashboardIcon={dashboardIcon}
        dashboardTitle={dashboardTitle}
        isOffline={isOffline}
        items={menuItems.slice(menuItemsToShow, menuItems.length)}
        sidebarIsOpen={sidebarIsOpen}
        onChangeSidebar={onChangeSidebar}
        onHelpClick={onHelpClick}
        onLogout={onLogout}
        onShowPremiumModal={onShowPremiumModal}
      />
    </div>
  )
}

export default MenuBar
