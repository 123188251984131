import moment from 'moment'

import { Request } from 'constants/http'

import { isPlainObject } from 'utils/data'

const isISODateString = (str) => /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\+\d{2}:\d{2}$/.test(str)
const isDateString = (str) => /^\d{4}-[01]\d-[0-3]\d$/.test(str)

const normalizeDateProperties = (object) => {
  if (isISODateString(object) || isDateString(object)) {
    return moment(object).toDate()
  }

  if (isPlainObject(object)) {
    return Object.keys(object).reduce((copy, key) => {
      const newCopy = { ...copy }
      const value = object[key]

      newCopy[key] = normalizeDateProperties(value)

      return newCopy
    }, {})
  }

  if (Array.isArray(object)) {
    return object.map(normalizeDateProperties)
  }

  return object
}

const NormalizeDatesMiddleware = async (request: Request, next) => {
  const response = await next(request)
  const json = response.json

  // eslint-disable-next-line func-names
  response.json = async function () {
    return normalizeDateProperties(await json.call(this))
  }

  return response
}

export default NormalizeDatesMiddleware
