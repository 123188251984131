import { constants as invoicesConstants } from 'services/legacy/invoices'

import {
  CLEAR_TRANSACTIONS_LIST,
  DOWNLOAD_TRANSACTIONS_LIST,
  DOWNLOAD_TRANSACTIONS_LIST_FAILED,
  DOWNLOAD_TRANSACTIONS_LIST_SUCCESS,
  GET_TRANSACTIONS_LIST,
  GET_TRANSACTIONS_LIST_FAILED,
  GET_TRANSACTIONS_LIST_SUCCESS,
  SEND_TRANSACTIONS_LIST,
  SEND_TRANSACTIONS_LIST_FAILED,
  SEND_TRANSACTIONS_LIST_SUCCESS,
} from './constants'

export const list = (params, onSuccess) => ({ transactionsApiClient }) => (dispatch) => {
  dispatch({ type: GET_TRANSACTIONS_LIST })

  transactionsApiClient.list(params).then(
    (data) => {
      dispatch({ payload: data, type: GET_TRANSACTIONS_LIST_SUCCESS })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => dispatch({ payload: response, type: GET_TRANSACTIONS_LIST_FAILED }),
  )
}

export const download = (childId, params, onSuccess) => ({ transactionsApiClient }) => (dispatch) => {
  dispatch({ type: DOWNLOAD_TRANSACTIONS_LIST })

  transactionsApiClient.download(childId, params).then(
    () => {
      dispatch({ type: DOWNLOAD_TRANSACTIONS_LIST_SUCCESS })
      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => dispatch({ error: response, type: DOWNLOAD_TRANSACTIONS_LIST_FAILED }),
  )
}

export const send = (
  childId,
  params,
  onSuccess = () => { },
  onFailed = () => { },
) => ({ transactionsApiClient }) => (dispatch) => {
  dispatch({ type: SEND_TRANSACTIONS_LIST })

  transactionsApiClient.send(childId, params).then(
    () => {
      dispatch({ type: SEND_TRANSACTIONS_LIST_SUCCESS })
      onSuccess()
    },
    ({ response }) => {
      if (invoicesConstants.ERROR_CODES.MISSING_PAYMENT_CONTACT === response.code) {
        dispatch({ type: SEND_TRANSACTIONS_LIST_FAILED })
        onFailed(response)

        return
      }

      dispatch({ error: response, type: SEND_TRANSACTIONS_LIST_FAILED })
    },
  )
}
export const clearList = () => ({
  type: CLEAR_TRANSACTIONS_LIST,
})
