import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'recompose'

import { Form } from 'components'

import i18n from 'translations'

export const ROUNDING_RULES_FORM = 'ROUNDING_RULES_FORM'

const RoundingRulesForm = ({
  handleSubmit,
  isSubmitting,
  onSubmit,
  options,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row
      label={i18n.t(
        'module:Management:General:RotaAndOccupancy:RoundingRules:text',
        { value: '' },
      )}
    >
      <Form.Row.FlexItem flex="0 0 180px" mobileFull>
        <Field
          clearable={false}
          component={Form.Select}
          disabled={isSubmitting}
          name="roundingRules"
          options={options}
        />
      </Form.Row.FlexItem>
    </Form.Row>
  </Form>
)

const enhance = compose(reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: true,
  form: ROUNDING_RULES_FORM,
}))

export default enhance(RoundingRulesForm)
