import {
  CLEAR_OBSERVATION_FEEDBACK,
  CREATE_OBSERVATION_FEEDBACK,
  CREATE_OBSERVATION_FEEDBACK_FAILED,
  CREATE_OBSERVATION_FEEDBACK_SUCCESS,
  GET_OBSERVATION_FEEDBACK,
  GET_OBSERVATION_FEEDBACK_FAILED,
  GET_OBSERVATION_FEEDBACK_SUCCESS,
  UPDATE_OBSERVATION_FEEDBACK,
  UPDATE_OBSERVATION_FEEDBACK_FAILED,
  UPDATE_OBSERVATION_FEEDBACK_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case GET_OBSERVATION_FEEDBACK:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_OBSERVATION_FEEDBACK_SUCCESS:
      return { ...state, data: payload.data, isFetching: false }
    case GET_OBSERVATION_FEEDBACK_FAILED:
      return { ...state, error, isFetching: false }
    case CREATE_OBSERVATION_FEEDBACK:
    case UPDATE_OBSERVATION_FEEDBACK:
      return { ...state, error: initialState.error, isSubmitting: true }
    case CREATE_OBSERVATION_FEEDBACK_SUCCESS:
    case UPDATE_OBSERVATION_FEEDBACK_SUCCESS:
      return { ...state, data: payload.data, isSubmitting: false }
    case CREATE_OBSERVATION_FEEDBACK_FAILED:
    case UPDATE_OBSERVATION_FEEDBACK_FAILED:
      return { ...state, error, isSubmitting: false }
    case CLEAR_OBSERVATION_FEEDBACK:
      return initialState
    default:
      return state
  }
}
