import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getFormValues, stopSubmit } from 'redux-form'

import { OBSERVATION_STATUSES } from 'services/legacy/observations/constants'

import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withObservationFeedbackService } from 'services/legacy/observationFeedback'
import { withSecurityService } from 'services/security'
import { withRouter } from 'services/router'

import { ADD_FEEDBACK_FORM } from './components/AddFeedbackForm/AddFeedbackForm'
import FeedbackView from './FeedbackView'

const OBSERVATION_FEEDBACK_LIST_GROUPS = {
  read: [
    'observationFeedback.author',
    'observationFeedback.author.details',
    'observationFeedback.improvements',
    'observationFeedback.updatedBy',
    'user',
  ],
}

class FeedbackContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formEnabled: false,
    }
  }

  componentDidMount() {
    const {
      authUser,
      hasOnlySeniorTeacherOrTeacherAccess,
      observationFeedbackActions,
      observationFeedbackSelectors,
      params,
    } = this.props
    const { observationId } = params

    const parameters = {
      observation: observationId,
    }

    if (hasOnlySeniorTeacherOrTeacherAccess) {
      parameters.author = authUser.id
    }

    const criteria = observationFeedbackSelectors.getListCriteria(parameters)

    observationFeedbackActions.list({
      criteria,
      groups: OBSERVATION_FEEDBACK_LIST_GROUPS,
    })
  }

  enableForm = () => {
    this.setState({ formEnabled: true })
  }

  disableForm = () => {
    this.setState({ formEnabled: false })
  }

  handleSubmitFailed = (response) => {
    const { injectValidation } = this.props
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    return injectValidation(errors)
  }

  handleSubmitConfirmed = (body) => {
    const { observationFeedbackActions } = this.props

    observationFeedbackActions.createObservationFeedback(
      body,
      this.refreshFeedback,
      this.handleSubmitFailed,
    )
  }

  handleSubmit = () => {
    const {
      formValues,
      modalActions,
      modalConsts,
      observation,
      observationFeedbackSelectors,
    } = this.props
    const { author, id } = observation

    const body = observationFeedbackSelectors.getValuesForm(
      id,
      formValues(ADD_FEEDBACK_FORM),
    )

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: 'Send feedback',
      icon: 'feedback',
      onConfirm: () => this.handleSubmitConfirmed(body),
      text: `Are you sure you want to send this observation back to ${author.name} with your feedback.`,
    })
  }

  handleApproveFeedback = () => {
    const {
      hasOnlySeniorTeacherOrTeacherAccess,
      observation,
      observationFeedbackActions,
      observationFeedbackLists,
      observationFeedbackSelectors,
    } = this.props

    const newestObservationFeedback = _.maxBy(observationFeedbackLists, (item) => (
      moment(item.createdAt).format('x')
    ))

    const body = observationFeedbackSelectors.getUpdateCriteria({
      observation: {
        id: observation.id,
        status: hasOnlySeniorTeacherOrTeacherAccess ? (
          OBSERVATION_STATUSES.PENDING_APPROVAL.value
        ) : (
          OBSERVATION_STATUSES.APPROVED.value
        ),
      },
    })

    observationFeedbackActions.updateObservationFeedback(
      newestObservationFeedback.id,
      body,
      this.refreshFeedback,
      this.handleSubmitFailed,
    )
  }

  refreshFeedback = () => {
    this.setState({ formEnabled: false })

    const {
      authUser,
      hasOnlySeniorTeacherOrTeacherAccess,
      observationFeedbackActions,
      observationFeedbackSelectors,
      params,
    } = this.props
    const { observationId } = params

    const parameters = {
      observation: observationId,
    }

    if (hasOnlySeniorTeacherOrTeacherAccess) {
      parameters.author = authUser.id
    }

    const criteria = observationFeedbackSelectors.getListCriteria(parameters)

    observationFeedbackActions.list({
      criteria,
      groups: OBSERVATION_FEEDBACK_LIST_GROUPS,
      order: {
        sortField: 'createdAt',
        sortOrder: 'DESC',
      },
    })
  }

  render() {
    const {
      authUser,
      hasOnlySeniorTeacherOrTeacherAccess,
      isFetching,
      isSubmitting,
      observation,
      observationFeedbackLists,
    } = this.props
    const { formEnabled } = this.state

    return (
      <FeedbackView
        authUser={authUser}
        formEnabled={formEnabled}
        hasOnlySeniorTeacherOrTeacherAccess={hasOnlySeniorTeacherOrTeacherAccess}
        isFetching={isFetching}
        isSubmitting={isSubmitting}
        observation={observation}
        observationFeedbackLists={observationFeedbackLists}
        onAddFeedbackClick={this.enableForm}
        onApproveFeedback={this.handleApproveFeedback}
        onCancelClick={this.disableForm}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  injectValidation: (errors = {}) => stopSubmit(ADD_FEEDBACK_FORM, errors),
}

const mapState = (state, {
  appSelectors,
  observationFeedbackListState,
  observationFeedbackSelectors,
  observationFeedbackSingleState,
  securitySelectors,
}) => ({
  authUser: securitySelectors.getAuthUser(state),
  formValues: (formName) => getFormValues(formName)(state),
  hasOnlySeniorTeacherOrTeacherAccess: securitySelectors.hasOnlySeniorTeacherOrTeacherAccess(state),
  isFetching: appSelectors.getIsFetching(observationFeedbackListState),
  isSubmitting: appSelectors.getIsSubmitting(observationFeedbackSingleState),
  observationFeedbackLists: observationFeedbackSelectors.getObservationFeedbackList(state),
})

const enhance = compose(
  withAppService,
  withModalService,
  withObservationFeedbackService,
  withRouter,
  withSecurityService,
  connect(mapState, mapDispatch),
)

export default enhance(FeedbackContainer)
