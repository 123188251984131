import _ from 'lodash'
import { combineEpics } from 'redux-observable'

import * as uploadEpics from 'services/legacy/upload/epics'

export default combineEpics(
  ..._.values(
    uploadEpics,
  ),
)
