import { createSelector } from 'reselect'

const getEnquirySettings = (state) => state.enquirySettings

export const getEnquirySettingsSingleSelector = createSelector(
  [getEnquirySettings],
  (state) => state?.single,
)

export const getEnquirySettingsSingleDataSelector = createSelector(
  [getEnquirySettingsSingleSelector],
  (state) => state.data,
)

export const getInitialValues = (isEditMode) => createSelector(
  [getEnquirySettingsSingleDataSelector],
  (enquirySetting) => {
    if (!isEditMode || !enquirySetting) {
      return null
    }

    const { name } = enquirySetting

    return { name }
  },
)

