import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as childMovesSelectors from './selectors'

const withChildMovesService = (WrappedComponent) => {
  const mapState = ({ childMoves }) => ({ childMoves })

  const mapDispatch = injectActions('childMovesActions', actions)

  const Component = ({ childMovesActions, ...others }) => (
    <WrappedComponent
      childMovesActions={childMovesActions}
      childMovesSelectors={childMovesSelectors}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withChildMovesService
