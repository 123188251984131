import _ from 'lodash'

import React from 'react'

import { generateRoute } from 'utils/routing'

import { NURSERY_DISCOUNT_TYPES } from 'services/product/nurseryDiscountsV3/constants'

import { Button, Currency } from 'components'

export const getTableData = (discountsList) => {
  if (!discountsList || !discountsList.length) {
    return null
  }

  return _.map(discountsList, ({ archived, description, id, type, value }) => ({
    description: `${description}${archived ? ' (archived)' : ''}`,
    discount: NURSERY_DISCOUNT_TYPES.PERCENTAGE === type ? `${value}%` : <Currency value={value} />,
    rowToButton: (
      <Button.TableAction
        to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.DISCOUNTS.EDIT', { itemId: id })}
        edit
      />
    ),
  }))
}
