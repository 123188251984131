import React from 'react'

import { FLAG_COLOURS } from 'constants/colors'

import { Callout } from '../index'

const BannerSuccess = ({ children, ...rest }) => (
  <Callout
    {...rest}
    color={FLAG_COLOURS.SUCCESS}
    content={children}
    icon="check"
    iconVariant="circle"
  />
)

export default BannerSuccess
