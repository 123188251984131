import _ from 'lodash'

import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { Form, GridLayout, Icon, Section, Typography } from 'components'

import i18n from 'translations'

import {
  StyledContainer,
  StyledDragg,
  StyledDraggableBox,
  StyledDraggableBoxContainer,
} from './ManagementBlossomboardFormStyled'

export const MANAGEMENT_BLOSSOMBOARD_FORM = 'ManagementBlossomboardForm'

const ManagementBlossomboardForm = ({
  editMode,
  handleSubmit,
  isSubmitting,
  onCancel,
  onSubmit,
  widgets,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <StyledContainer>
      <GridLayout>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item>
            <Section
              title={i18n.t('module:Management:ManagementBlossomboard:yourWidgets')}
            >
              {!editMode && _.map(widgets, ({ enabled, widget }) => (
                <StyledDraggableBoxContainer>
                  <StyledDraggableBox
                    editMode={editMode}
                    key={widget.id}
                  >
                    {widget.label}
                    <Typography>
                      {enabled ? i18n.t('global:On') : i18n.t('global:Off')}
                    </Typography>
                  </StyledDraggableBox>
                </StyledDraggableBoxContainer>
              ))}
              {editMode && (
                <Field
                  component={Form.DraggableList}
                  itemComponent={(widget, index) => (
                    <StyledDraggableBoxContainer>
                      <StyledDraggableBox
                        editMode={editMode}
                        key={widget.id}
                      >
                        <StyledDragg>
                          <Icon
                            color="#D7D7D7"
                            height={18}
                            icon="menu"
                          />
                        </StyledDragg>
                        {widget.label}
                        <Field
                          component={Form.Switch}
                          name={`settings[${index}].enabled`}
                        />
                      </StyledDraggableBox>
                    </StyledDraggableBoxContainer>
                  )}
                  name="settings"
                />
              )}
            </Section>
          </GridLayout.Item>
          <GridLayout.Item />
        </GridLayout.Group>
      </GridLayout>
    </StyledContainer>
    {editMode && (
      <Form.FooterActions
        isSubmitting={isSubmitting}
        submitLabel={i18n.t('global:Save')}
        onCancelClick={onCancel}
      />
    )}
  </Form>
)

export default reduxForm({
  destroyOnUnmount: true,
  enableReinitialize: true,
  form: MANAGEMENT_BLOSSOMBOARD_FORM,
})(ManagementBlossomboardForm)
