import _ from 'lodash'
import { v4 } from 'uuid'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withEnquiryStatusTypesService } from 'services/legacy/enquiries'
import { withSnackbarService } from 'services/utils/snackbar'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouter } from 'services/router'

import i18n from 'translations'

import PipelineView from './PipelineView'

class PipelineContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      disableDragging: false,
      droppableId: v4(),
      enquiryStatusTypes: null,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { enquiryStatusTypes } = nextProps
    const { enquiryStatusTypes: enquiryStatusTypesState } = prevState

    if (enquiryStatusTypes?.length && !enquiryStatusTypesState) {
      return { enquiryStatusTypes }
    }

    return null
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { enquiryStatusTypesActions } = this.props

    enquiryStatusTypesActions.clear()
  }

  fetch = () => {
    const {
      enquiryStatusTypesActions,
      enquiryStatusTypesSelectors,
      paginationUtils,
    } = this.props

    const { page } = paginationUtils

    const criteria = enquiryStatusTypesSelectors.getCriteria()

    enquiryStatusTypesActions.list({
      params: [{
        criteria,
        order: {
          sortField: 'ordering',
          sortOrder: 'ASC',
        },
        page,
      }],
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleSuccess = () => {
    const { snackbarActions } = this.props

    snackbarActions.show({
      message: i18n.t('module:Management:Enquiries:EnquiryPipeline:orderUpdateMessage'),
    })

    this.setState({ disableDragging: false })
  }

  handleItemDragged = (reorderedItems, result) => {
    const { enquiryStatusTypesActions } = this.props

    const { draggableId } = result

    const item = _.find(reorderedItems, { draggableId })

    this.setState({ disableDragging: true, enquiryStatusTypes: reorderedItems })

    enquiryStatusTypesActions.update({
      body: { ordering: item.order },
      onSuccess: this.handleSuccess,
      params: [item.id, {}],
    })
  }

  render() {
    const {
      isFetching,
      paginationUtils,
      totalResults,
    } = this.props
    const { disableDragging, droppableId, enquiryStatusTypes } = this.state

    const { getPageCount, page } = paginationUtils

    const pageCount = getPageCount(totalResults)

    return (
      <PipelineView
        disableDragging={disableDragging}
        droppableId={droppableId}
        enquiryStatusTypes={enquiryStatusTypes}
        isLoading={isFetching}
        page={page}
        pageCount={pageCount}
        onItemDragged={this.handleItemDragged}
        onPageChange={this.handlePageChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  enquiryStatusTypesListState,
  enquiryStatusTypesSelectors,
}) => ({
  enquiryStatusTypes: enquiryStatusTypesSelectors.getEnquiryStatusTypesFormattedListDataSelector(state),
  errorMessages: appSelectors.getErrorMessages(enquiryStatusTypesListState),
  isFetching: appSelectors.getIsFetching(enquiryStatusTypesListState),
  totalResults: appSelectors.getTotalResults(enquiryStatusTypesListState),
})

const enhance = compose(
  withAppService,
  withRouter,
  withEnquiryStatusTypesService,
  withSnackbarService,
  withPaginationUtils,
  connect(mapState),
)

export default enhance(PipelineContainer)
