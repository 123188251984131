import React, { useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'
import { stopSubmit } from 'redux-form'

import { RootState } from 'core/reducers'
import { LOCATION_CHAR } from 'services/authentication/constants'
import { HEADER_BLOSSOM_ORIGIN, VALUE_BLOSSOM_ORIGIN_PARENT_APP } from 'constants/headers'

import { generateRoute } from 'utils/routing'

import { withAppService, withAppServiceProps } from 'services/app'
import { withAuthenticationService, withAuthenticationServiceProps } from 'services/authentication'
import { withDeviceService, withDeviceServiceProps } from 'services/device'
import { withShellService, withShellServiceProps } from 'services/shell'
import { withRouter, withRouterProps } from 'services/router'

import ResetPasswordView from './ResetPasswordView'

type ResetPasswordContainerFullProps = withAuthenticationServiceProps
  & withAppServiceProps
  & withDeviceServiceProps
  & withShellServiceProps
  & withRouterProps

const mapDispatch = {
  injectValidation: (formName, data) => stopSubmit(formName, data),
}

const mapState = (state: RootState, {
  appSelectors,
  authenticationCommonState,
  authenticationSelectors,
}: ResetPasswordContainerFullProps) => ({
  errorMessages: appSelectors.getErrorMessages(authenticationCommonState),
  isUserLoggedIn: authenticationSelectors.getIsUserLoggedIn(state),
})

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

const ResetPasswordContainer: React.FC<ResetPasswordContainerFullProps & PropsFromRedux> = ({
  authenticationActions,
  errorMessages,
  location,
  navigate,
  shellActions,
}) => {
  const [isParentPasswordResetSuccess, setParentPasswordResetSuccess] = useState<boolean>(false)
  const [isParentPasswordChangeSuccess, setParentPasswordChangeSuccess] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const resetToken = location.query.t
  const showChangePasswordForm = !!resetToken
  const isParentAppLink = location.pathname.startsWith('/parent/password')

  useEffect(() => {
    shellActions.setSettings({
      minimal: true,
    })
  }, [])

  const handleResetPasswordSuccess = () => {
    if (isParentAppLink) {
      return setParentPasswordResetSuccess(true)
    }

    return navigate(
      `/${generateRoute('AUTHENTICATION.LOGIN')}?v=${LOCATION_CHAR.WE_HAVE_SEND_EMAIL_TO_RESET_PASSWORD}`,
    )
  }

  const handleResetPassword = (body) => {
    setIsFetching(true)

    return authenticationActions.resetPassword({
      body,
      onFailed: () => setIsFetching(false),
      onSuccess: handleResetPasswordSuccess,
    })
  }

  const handleChangePasswordSuccess = () => {
    if (isParentAppLink) {
      return setParentPasswordChangeSuccess(true)
    }

    return navigate(`/${generateRoute(
      'AUTHENTICATION.LOGIN',
    )}?v=${LOCATION_CHAR.YOUR_PASSWORD_HAS_BEN_UPDATED}`)
  }

  const handleChangePassword = (values) => {
    const token = location.query.t
    let headers

    if (isParentAppLink) {
      headers = {
        [HEADER_BLOSSOM_ORIGIN]: [VALUE_BLOSSOM_ORIGIN_PARENT_APP],
      }
    }

    const body = {
      password: values.password,
      token,
    }

    setIsFetching(true)

    authenticationActions.changePassword({
      body,
      onFailed: () => setIsFetching(false),
      onSuccess: handleChangePasswordSuccess,
      params: [headers],
    })
  }

  return (
    <ResetPasswordView
      errorMessages={errorMessages}
      isFetching={isFetching}
      isParentAppLink={isParentAppLink}
      isParentPasswordChangeSuccess={isParentPasswordChangeSuccess}
      isParentPasswordResetSuccess={isParentPasswordResetSuccess}
      showChangePasswordForm={showChangePasswordForm}
      onChangePassword={handleChangePassword}
      onResetPassword={handleResetPassword}
    />
  )
}

const enhance = compose(
  withAppService,
  withAuthenticationService,
  withDeviceService,
  withRouter,
  withShellService,
  connect(mapState, mapDispatch),
)

export default enhance(ResetPasswordContainer)
