import _ from 'lodash'

import { BasicModalProps, ModalType } from 'modals'

import { HIDE_ALL_MODAL, HIDE_MODAL, SHOW_MODAL } from './constants'
import { ModalOptions } from '../constants'

type ModalSingleType = {
  modalOptions?: ModalOptions
  modalProps: BasicModalProps
  modalType: string | ModalType
}

export interface ModalCommonState {
  modalStack: ModalSingleType[]
  modalStackIsEnabled: boolean
}

const initialState = {
  modalStack: [],
  modalStackIsEnabled: false,
}

export default (state: ModalCommonState = initialState, { modalOptions, modalProps, modalType, type }) => {
  switch (type) {
    case SHOW_MODAL:
      return {
        modalStack: [
          ...(state.modalStackIsEnabled ? state.modalStack : []), {
            modalOptions,
            modalProps,
            modalType,
          },
        ],
        modalStackIsEnabled: state.modalStackIsEnabled
          ? true
          : !!(modalOptions?.enableMultipleModal),
      }
    case HIDE_MODAL:
      if (state.modalStackIsEnabled) {
        return {
          ...state,
          modalStack: 1 < state?.modalStack?.length
            ? _.slice(state.modalStack, 0, state.modalStack.length - 1)
            : [],
          modalStackIsEnabled: 1 < state?.modalStack?.length,
        }
      }
      return initialState
    case HIDE_ALL_MODAL:
      return initialState
    default:
      return state
  }
}
