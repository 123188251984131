import { BasicActionOptionsProps } from 'constants/service'

import {
  GET_NURSERIES_DROPDOWN_ITEM,
  GET_NURSERIES_DROPDOWN_ITEM_FAILED,
  GET_NURSERIES_DROPDOWN_ITEM_SUCCESS,
  NURSERIES_DROPDOWN_LIST,
  NURSERIES_DROPDOWN_LIST_FAILED,
  NURSERIES_DROPDOWN_LIST_SUCCESS,
} from './constants'

export const dropdownList = (options: BasicActionOptionsProps) => ({ nurseriesApiClient }) => async (dispatch) => {
  const { params = {}, onSuccess } = options

  dispatch({ payload: null, type: NURSERIES_DROPDOWN_LIST })

  await nurseriesApiClient.list(params).then(
    (response) => {
      dispatch({
        payload: response,
        type: NURSERIES_DROPDOWN_LIST_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: NURSERIES_DROPDOWN_LIST_FAILED,
      })
    },
  )
}

export const getDropdownItem = (
  id,
  options: BasicActionOptionsProps,
) => ({ nurseriesApiClient }) => async (dispatch) => {
  const { onFailed, onSuccess } = options

  dispatch({
    type: GET_NURSERIES_DROPDOWN_ITEM,
  })

  await nurseriesApiClient.get(id).then(
    (response) => {
      dispatch({
        type: GET_NURSERIES_DROPDOWN_ITEM_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        type: GET_NURSERIES_DROPDOWN_ITEM_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}
