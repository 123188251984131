import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_PERIOD_TIME,
  CREATE_PERIOD_TIME,
  CREATE_PERIOD_TIME_FAILED,
  CREATE_PERIOD_TIME_SUCCESS,
  GET_PERIOD_TIME,
  GET_PERIOD_TIME_FAILED,
  GET_PERIOD_TIME_SUCCESS,
  REMOVE_PERIOD_TIME,
  REMOVE_PERIOD_TIME_FAILED,
  REMOVE_PERIOD_TIME_SUCCESS,
  UPDATE_PERIOD_TIME,
  UPDATE_PERIOD_TIME_FAILED,
  UPDATE_PERIOD_TIME_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_PERIOD_TIME,
    createFailed: CREATE_PERIOD_TIME_FAILED,
    createInit: CREATE_PERIOD_TIME,
    createSuccess: CREATE_PERIOD_TIME_SUCCESS,
    itemFailed: GET_PERIOD_TIME_FAILED,
    itemInit: GET_PERIOD_TIME,
    itemSuccess: GET_PERIOD_TIME_SUCCESS,
    removeFailed: REMOVE_PERIOD_TIME_FAILED,
    removeInit: REMOVE_PERIOD_TIME,
    removeSuccess: REMOVE_PERIOD_TIME_SUCCESS,
    updateFailed: UPDATE_PERIOD_TIME_FAILED,
    updateInit: UPDATE_PERIOD_TIME,
    updateSuccess: UPDATE_PERIOD_TIME_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
