import _ from 'lodash'
import { flatten, nest } from 'utils/flatnest'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withPaginationUtils } from 'services/utils/pagination'
import { withNurseriesService } from 'services/nurseries'
import { withSecurityService } from 'services/security'

import { FEATURE_FLAGS_OPTIONS } from 'services/legacy/featureFlags/constants'

import i18n from 'translations'

import NurseriesListView from './NurseriesListView'
import NurseryAdd from '../NurseriesAdd'
import NurseryEdit from '../NurseriesEdit'

const GROUPS = {
  read: [
    'membership',
    'membership.details',
    'membership.profile',
    'nursery.details',
    'nursery.extraInfo',
    'nursery.statistics',
    'nursery.primaryContact',
  ],
}

const TYPE_OPTIONS = [
  {
    label: i18n.t('global:All'),
    value: -1,
  },
  {
    label: i18n.t('global:Active'),
    value: 0,
  },
  {
    label: i18n.t('global:Disabled'),
    value: 1,
  },
]

class NurseriesListContainer extends Component {
  constructor(props) {
    super(props)

    const {
      isOrganizationContext,
      location: { query },
      paginationUtils: { setPageLocationQuery },
    } = props

    const { featureFlags, search, type } = nest(query)

    this.state = {
      filters: {
        featureFlags,
        search,
        type: type || TYPE_OPTIONS[1].value,
      },
    }

    if (!isOrganizationContext) {
      setPageLocationQuery(false)
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { nurseriesActions } = this.props

    nurseriesActions.clear()
  }

  fetch = () => {
    const {
      isOrganizationContext,
      nurseriesActions,
      nurseriesSelectors,
      paginationUtils: { page },
      setLocationQuery,
    } = this.props
    const { filters } = this.state

    setLocationQuery(flatten(filters))

    const apiParams = {
      criteria: nurseriesSelectors.getCriteria(filters),
      groups: GROUPS,
      page,
    }

    nurseriesActions.list({
      params: apiParams,
      recurrency: 1 !== page && !isOrganizationContext,
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleTypeChange = (type) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        type: type ? type.value : null,
      },
    }), onPageChange(this.fetch)(1))
  }

  handleSearchChange = (search) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        search,
      },
    }), () => {
      const { paginationUtils } = this.props
      const { onPageChange } = paginationUtils

      onPageChange(this.fetch)(1)
    })
  }

  handleFeatureFlagChange = (featureFlags) => {
    const { paginationUtils: { onPageChange } } = this.props

    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        featureFlags: _.map(featureFlags, (flag) => flag.value),
      },
    }), onPageChange(this.fetch)(1))
  }

  render() {
    const {
      authAccessMap,
      isFetching,
      isOrganizationContext,
      nurseries,
      paginationUtils,
      totalResults,
    } = this.props
    const { filters } = this.state
    const { getPageCount, page, perPage } = paginationUtils

    const pageCount = getPageCount(totalResults)

    return (
      <NurseriesListView
        authAccessMap={authAccessMap}
        featureFlagOptions={FEATURE_FLAGS_OPTIONS}
        filters={filters}
        isFetching={isFetching}
        isOrganizationContext={isOrganizationContext}
        nurseries={nurseries}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        totalResults={totalResults}
        typeOptions={TYPE_OPTIONS}
        onFeatureFlagChange={this.handleFeatureFlagChange}
        onPageChange={this.handlePageChange}
        onSearchChange={this.handleSearchChange}
        onTypeChange={this.handleTypeChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseriesListState,
  nurseriesSelectors,
  securitySelectors,
}) => ({
  authAccessMap: {
    module: {
      NurseryAdd: auth.SELECTORS.getComponentIsAuthorised(state, NurseryAdd),
      NurseryEdit: auth.SELECTORS.getComponentIsAuthorised(state, NurseryEdit),
    },
  },
  isFetching: appSelectors.getIsFetching(nurseriesListState),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
  nurseries: nurseriesSelectors.getOrganizationNurseriesDataWithPublicUrl(state),
  totalResults: appSelectors.getTotalResults(nurseriesListState),
})

const enhance = compose(
  withAppService,
  withNurseriesService,
  withPaginationUtils,
  withSecurityService,
  connect(mapState),
)

export default enhance(NurseriesListContainer)
