import _ from 'lodash'
import { flatten } from 'utils/flatnest'

import React from 'react'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { toFloat } from 'utils/data'

import { Currency, DateString, ReportView } from 'components'

import i18n from 'translations'

import { NURSERY_TABLE_COLUMNS, ORGANIZATION_TABLE_COLUMNS } from './constants'

export const getColumns = (isOrganizationContext) => {
  if (isOrganizationContext) {
    return ORGANIZATION_TABLE_COLUMNS
  }

  return NURSERY_TABLE_COLUMNS
}

export const getTableData = ({ data, filters, hasError, isOrganizationContext }) => {
  if (!data?.length || hasError) {
    return null
  }

  const queryString = new URLSearchParams(flatten({
    age: filters?.age,
    date: filters?.date ? {
      after: filters.date.after.format(DEFAULT_DATE_FORMAT),
      before: filters.date.before.format(DEFAULT_DATE_FORMAT),
    } : undefined,
  })).toString()

  return _.map(data, ({
    depositPaid,
    depositRefunded,
    fte,
    leavers,
    resource,
    weeklyFee,
  }) => ({
    ageMonths: i18n.t('global:ageMonths', { ageMonths: resource.ageMonths }),
    codeReference: resource.codeReference,
    depositPaid: <Currency value={depositPaid} />,
    depositRefunded: <Currency value={depositRefunded} />,
    dob: <DateString date={resource.birthDate} />,
    fte: toFloat(fte),
    leavers,
    leavingDate: <DateString date={resource.leavingDate} />,
    name: isOrganizationContext
      ? (
        <ReportView.NurseryLink
          nursery={resource}
          queryString={queryString}
          routeKey="REPORTS.CHILDREN_LEAVING"
        />
      )
      : resource.name,
    registeredAt: <DateString date={resource.registeredAt} />,
    roomName: (
      <React.Fragment>
        {resource?.nurseryClass?.name}
        {resource?.nurseryClass?.archived ? ` (${i18n.t('global:archived')})` : null}
      </React.Fragment>
    ),
    startDate: <DateString date={resource.startDate} />,
    weeklyFee: <Currency value={weeklyFee} />,
  }))
}

export const getStatistics = ({ data, hasError, isOrganizationContext }) => {
  if (!data || hasError) {
    return null
  }

  if (isOrganizationContext) {
    return [
      {
        label: i18n.t('module:Reports:ChildrenLeaving:Statistics:totalLeavers'),
        value: data.leavers,
      },
      {
        label: i18n.t('module:Reports:Common:totalFte'),
        value: toFloat(data.fte),
      },
      {
        label: i18n.t('module:Reports:Common:totalWeeklyFee'),
        value: <Currency value={data.weeklyFee} />,
      },
    ]
  }

  return [
    {
      label: i18n.t('module:Reports:ChildrenLeaving:Statistics:totalLeavers'),
      value: data.leavers,
    },
    {
      label: i18n.t('module:Reports:Common:totalFte'),
      value: toFloat(data.fte),
    },
    {
      label: i18n.t('module:Reports:ChildrenLeaving:Statistics:totalDepositPaid'),
      value: <Currency value={data.depositPaid} />,
    },
    {
      label: i18n.t('module:Reports:ChildrenLeaving:Statistics:totalDepositRefunded'),
      value: <Currency value={data.depositRefunded} />,
    },
    {
      label: i18n.t('module:Reports:Common:totalWeeklyFee'),
      value: <Currency value={data.weeklyFee} />,
    },
  ]
}
