import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledAreaPlaceholder = styled.div`
  ${({ length }) => length && `
    width: ${length * 118}px;
    background: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`

export const StyledName = styled.div`
  display: grid;
  grid-column-gap: 12px;
  align-items: center;
  grid-template-columns: 20px auto;
`
