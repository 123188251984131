import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import * as deviceSelectors from './selectors'
import actions from './actions'

export interface withDeviceServiceProps {
  deviceActions: typeof actions
  deviceSelectors: typeof deviceSelectors
}

const withDeviceService = <P extends withDeviceServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapDispatch = injectActions('deviceActions', actions)

  const Component = ({ deviceActions, ...others }) => (
    <WrappedComponent
      deviceActions={deviceActions}
      deviceSelectors={deviceSelectors}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(null, mapDispatch)(Component)
}

export default withDeviceService
