import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 15px;
  align-items: center;
`

export const StyledContainer = styled.div`
  border-bottom: 1px dashed ${NEUTRAL_COLOURS.SILVER};
  margin-bottom: 40px;
`
