import i18n from 'translations'

import ObservationDiaryButtonsWidget from './ObservationDiaryButtonsWidgetContainer'

export const WIDGET_NAME = {
  component: ObservationDiaryButtonsWidget,
  id: 'OBSERVATION_DIARY_BUTTONS_WIDGET',
  label: i18n.t('module:Dashboard:Widgets:ObservationDiaryButtonsWidget:titleInSettings'),
}

export default ObservationDiaryButtonsWidget
