import React from 'react'

import { Button, Section, StickyFooter, Typography } from 'components'

import i18n from 'translations'

import { renderContent } from '../FinancePaymentsImportHelpers'

const ImportErrors = ({
  errors,
  onReturnToCsvUploadClick,
}) => {
  const renderFooter = () => (
    <StickyFooter>
      <StickyFooter.Flex />
      <Button
        label={i18n.t('module:Modals:PaymentsImport:Errors:returnToCsvUpload')}
        negativeMargins
        onClick={onReturnToCsvUploadClick}
      />
    </StickyFooter>
  )

  return (
    <Section
      title={i18n.t('module:Modals:PaymentsImport:Errors:title')}
      variant="small"
      noBorder
    >
      <Typography margin="0 0 40px">
        {i18n.t('module:Modals:PaymentsImport:Errors:subtitle')}
      </Typography>
      <div>
        {renderContent(errors)}
      </div>
      {renderFooter()}
    </Section>
  )
}

export default ImportErrors
