import _ from 'lodash'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { SHIFT_MAIN_TYPES, WORK_SHIFT_TYPES } from 'services/legacy/membershipRegisters/constants'
import { VIEW_MODE } from 'services/legacy/membershipsShifts/constants'

export const getRecordStatistics = (shifts, viewMode, day) => {
  const recordStatistics = {
    leave: 0,
    work: 0,
  }

  const workStatistics = {
    [WORK_SHIFT_TYPES.CONTRACTED]: 0,
    [WORK_SHIFT_TYPES.OVERTIME]: 0,
    [WORK_SHIFT_TYPES.BREAK]: 0,
    [WORK_SHIFT_TYPES.BREAK_DURATION]: 0,
  }

  const leaveStatistics = {}

  _.each(shifts, ({ dayMembershipShiftTimeStatistics, leaveShiftType, type: mainType }) => {
    let filteredDayStatistics = dayMembershipShiftTimeStatistics

    if (viewMode === VIEW_MODE.DAY) {
      filteredDayStatistics = _.pickBy(
        dayMembershipShiftTimeStatistics,
        (i, key) => key === day?.format(DEFAULT_DATE_FORMAT),
      )
    }

    return _.each(filteredDayStatistics, ({
      break: breakTime = 0,
      breakDuration = 0,
      breakExact = 0,
      contracted = 0,
      leave = 0,
      overtime = 0,
    }) => {
      if (SHIFT_MAIN_TYPES.WORK === mainType) {
        recordStatistics.work += (contracted + overtime - breakTime)
        workStatistics[WORK_SHIFT_TYPES.CONTRACTED] += contracted
        workStatistics[WORK_SHIFT_TYPES.OVERTIME] += overtime
        workStatistics[WORK_SHIFT_TYPES.BREAK] += breakExact
        workStatistics[WORK_SHIFT_TYPES.BREAK_DURATION] += breakDuration
      }

      if (SHIFT_MAIN_TYPES.LEAVE === mainType) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        recordStatistics.leave += leave?.leave

        _.each(leave, (value, leaveType) => {
          if (SHIFT_MAIN_TYPES.LEAVE === leaveType) {
            return
          }

          if (!leaveStatistics[leaveType]) {
            leaveStatistics[leaveType] = {
              name: leaveShiftType?.name,
              value,
            }
          } else {
            leaveStatistics[leaveType].value += value
          }
        })
      }
    })
  })

  return {
    leaveStatistics,
    recordStatistics,
    workStatistics,
  }
}
