import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withChildFundingService } from 'services/legacy/childFunding'
import { withChildFundingSessionSetService } from 'services/legacy/childFundingSessionSet'
import { withChildSessionsService } from 'services/legacy/childSessions'
import { withRouter } from 'services/router'

import ChildFundingItemView from './ChildFundingItemView'

const CHILD_FUNDING_GROUPS = {
  read: [
    'childFunding.child',
    'childFunding.funding',
    'nurseryFunding',
    'nurseryFunding.fundingType',
    'fundingType',
  ],
}

const CHILD_FUNDING_SESSION_SET_GROUPS = {
  read: [
    'childFundingSessionSet.child',
    'childFundingSessionSet.funding',
    'childFundingSessionSet.session',
    'childSession',
    'childFundingSessionSet.allocations',
    'nurseryFunding',
    'nurseryFunding.fundingType',
    'fundingType',
  ],
}

class ChildFundingItemContainer extends Component {
  componentDidMount() {
    const {
      childFundingActions,
      childFundingSessionSetActions,
      childFundingSessionSetSelectors,
      params,
    } = this.props

    const { childFundingId, childId } = params

    const apiParams = {
      groups: CHILD_FUNDING_GROUPS,
    }

    const sessionSetApiParams = {
      criteria: childFundingSessionSetSelectors.getCriteria({
        childFundingId,
        childId,
        fundingArchived: 0,
        sessionArchived: 0,
      }),
      groups: CHILD_FUNDING_SESSION_SET_GROUPS,
    }

    childFundingActions.get(childFundingId, apiParams)
    childFundingSessionSetActions.list(sessionSetApiParams, ({ data }) => {
      if (data && data.length) {
        this.fetchSessions(data.map((sessionSet) => sessionSet.session.id))
      }
    })
  }

  componentWillUnmount() {
    const { childFundingActions, childFundingSessionSetActions } = this.props

    childFundingActions.clearSingle()
    childFundingSessionSetActions.clear()
  }

  fetchSessions = (sessionIds) => {
    const { childSessionsActions, childSessionsSelectors, params } = this.props
    const { childId } = params

    const criteria = childSessionsSelectors.getCriteria({ archived: 0, ids: sessionIds })

    childSessionsActions.list(childId, {
      criteria,
      order: 'startDate',
    })
  }

  render() {
    const {
      childFundingAllocationData,
      childFundingPreviewData,
      childFundingSessionSets,
      errorMessages,
      isFetching,
      params,
    } = this.props

    const { childId } = params

    return (
      <ChildFundingItemView
        allocationData={childFundingAllocationData}
        childId={childId}
        errorMessages={errorMessages}
        isLoading={isFetching}
        previewData={childFundingPreviewData}
        sessionSets={childFundingSessionSets}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childFundingSelectors,
  childFundingSessionSetSelectors,
  childFundingSingleState,
}) => ({
  childFundingAllocationData: childFundingSelectors.getItemAllocationDataSelectors(state),
  childFundingPreviewData: childFundingSelectors.getItemPreviewDataSelectors(state),
  childFundingSessionSets: childFundingSessionSetSelectors.getSessionSets()(state),
  errorMessages: appSelectors.getErrorMessages(childFundingSingleState),
  isFetching: appSelectors.getIsFetching(childFundingSingleState),
})

const enhance = compose(
  withRouter,
  withAppService,
  withChildFundingService,
  withChildFundingSessionSetService,
  withChildSessionsService,
  connect(mapState),
)

export default enhance(ChildFundingItemContainer)
