import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'

const withMessagingService = (WrappedComponent) => {
  const mapState = ({ membershipsLeaves }) => ({ membershipsLeaves })

  const mapDispatch = injectActions('messagingActions', actions)

  const Component = ({ messagingActions, ...others }) => (
    <WrappedComponent
      messagingActions={messagingActions}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withMessagingService
