import {
  CLEAR_MEMBERSHIPS_LIST_LEAVES,
  GET_MEMBERSHIPS_LIST_LEAVES,
  GET_MEMBERSHIPS_LIST_LEAVES_FAILED,
  GET_MEMBERSHIPS_LIST_LEAVES_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ membershipsLeavesApiClient }) => async (dispatch) => {
  const { mergeResult, onFailed, onSuccess, onlyData, params } = options

  if (!onlyData) {
    dispatch({ type: GET_MEMBERSHIPS_LIST_LEAVES })
  }

  return membershipsLeavesApiClient.list(params).then(
    (response) => {
      if (!onlyData) {
        dispatch({
          mergeResult,
          payload: response,
          type: GET_MEMBERSHIPS_LIST_LEAVES_SUCCESS,
        })
      }

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      if (!onlyData) {
        dispatch({
          error: response,
          type: GET_MEMBERSHIPS_LIST_LEAVES_FAILED,
        })
      }

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const clearList = () => ({
  type: CLEAR_MEMBERSHIPS_LIST_LEAVES,
})
