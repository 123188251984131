import { generateBasicActions } from 'utils/service'

import {
  CLEAR_LIST_ENQUIRIES,
  EXPORT_ENQUIRIES,
  EXPORT_ENQUIRIES_FAILED,
  EXPORT_ENQUIRIES_SUCCESS,
  LIST_ENQUIRIES,
  LIST_ENQUIRIES_FAILED,
  LIST_ENQUIRIES_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ enquiriesApiClient }) => generateBasicActions.list({
  constants: {
    failed: LIST_ENQUIRIES_FAILED,
    init: LIST_ENQUIRIES,
    success: LIST_ENQUIRIES_SUCCESS,
  },
  options,
  service: enquiriesApiClient,
  serviceMethod: 'list',
})

export const exportEnquiries = (options = {}) => ({ enquiriesApiClient }) => generateBasicActions.list({
  constants: {
    failed: EXPORT_ENQUIRIES_FAILED,
    init: EXPORT_ENQUIRIES,
    success: EXPORT_ENQUIRIES_SUCCESS,
  },
  options: {
    ...options,
    onlyData: true,
  },
  service: enquiriesApiClient,
  serviceMethod: 'export',
})

export const clear = () => ({
  type: CLEAR_LIST_ENQUIRIES,
})
