import { combineReducers } from 'redux'

import importPayments from './import/reducer'
import list from './list/reducer'
import single from './single/reducer'

export default combineReducers({
  importPayments,
  list,
  single,
})
