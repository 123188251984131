import _ from 'lodash'

import React from 'react'

import { TimeString } from 'components'
import PlanView from 'module/Finance/components/PlanView'

import i18n from 'translations'

const PlanDetailSessions = ({ childSessions }) => {
  if (!childSessions || !childSessions.length) {
    return (
      <PlanView>
        <PlanView.Icon icon="sessions" />
        <PlanView.Content>
          <PlanView.SubTitle
            subtitle={i18n.t('module:Children:Child:Finance:PlanView:Summary:sessionEmptyState')}
          />
        </PlanView.Content>
      </PlanView>
    )
  }

  const renderDayPlans = ({ archived, endTime, sessionName, startTime }, index) => (
    <PlanView.Item key={_.kebabCase(index)}>
      <PlanView.Highlight minWidth={100}>
        <TimeString date={startTime} local={false} />
        <span>
          &nbsp;-&nbsp;
        </span>
        <TimeString date={endTime} local={false} />
        &nbsp;
      </PlanView.Highlight>
      {`${sessionName}${archived ? ' (archived)' : ''}`}
    </PlanView.Item>
  )

  const renderPlan = ({ dayOfWeek, plans }) => (
    <PlanView.SubGroup>
      <PlanView.Tag text={dayOfWeek.toUpperCase()[0]} />
      <PlanView.SubGroupContent>
        {_.map(plans, renderDayPlans)}
      </PlanView.SubGroupContent>
    </PlanView.SubGroup>
  )

  return (
    <PlanView>
      <PlanView.Icon icon="sessions" />
      <PlanView.Content>
        <PlanView.Title title="Sessions" />
        {_.map(childSessions, renderPlan)}
      </PlanView.Content>
    </PlanView>
  )
}

export default PlanDetailSessions
