import styled from 'styled-components'

export const StyledCarouselHeader = styled.header`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
`

export const StyledCarouselFooter = styled.footer`
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: auto auto;
  padding: 20px 0;
`

export const StyledFooterLeft = styled.div`
  padding-right: 25px;

  ${({ center }) => center && `
    display: flex;
    align-items: center;
  `}
`

export const StyledFooterRight = styled.div`
  display: flex;
  align-items: flex-end; 
  flex-direction: column;
  text-align: right;
`

export const StyledNavigation = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, auto);
`

export const StyledCarouselContainer = styled.div`
  max-width: 800px;
  padding: 0 20px;
  margin: 0 auto;

  .react-images__track {
    align-items: flex-start !important;
  }

  @media(max-width: 480px) {
    padding: 0 10px;
  }
`

