import _ from 'lodash'
import { nest } from 'utils/flatnest'

export const getFieldValidationFromError = (error, options) => {
  if (!error || !error.extra) {
    return null
  }

  const { doNotSplitKeys = false } = options || {}

  if (doNotSplitKeys) {
    return nest(error.extra)
  }

  return _.mapKeys(error.extra, (value, key) => {
    if (!value) {
      return null
    }

    return key.split('.').pop()
  })
}
