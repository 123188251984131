import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class InvoicePaymentsApiClient extends ServiceBase {
  list = (params = {}) => this.request({
    path: `/v2/payments?${getQueryStringV2(params)}`,
  })

  export = (params = {}) => this.request({
    path: `/v2/payments/report/payment?${getQueryStringV2(params)}`,
  })

  get = (paymentId, params = {}) => this.request({
    path: `/v2/payments/${paymentId}?${getQueryStringV2(params)}`,
  })

  create = (payload) => this.request({
    body: payload,
    method: 'POST',
    path: '/v2/payments',
  })

  delete = (paymentId) => this.request({
    method: 'DELETE',
    path: `/v2/payments/${paymentId}`,
  })

  download = (id) => this.request({
    download: true,
    path: `/v2/payments/${id}/download`,
  })

  import = (payload = {}, params = {}) => this.request({
    autoGeneratedHeaders: true,
    body: payload,
    method: 'POST',
    path: `/v2/payments/import/payment?${getQueryStringV2(params)}`,
  })

  send = (id) => this.request({
    method: 'POST',
    path: `/v2/payments/${id}/send`,
  })

  downloadTemplate = (params = {}) => this.request({
    downloadBlob: true,
    filename: params.filename,
    path: '/v2/payments/import/payment/template',
  })
}

export default InvoicePaymentsApiClient
