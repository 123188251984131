import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  
  @media(max-width: 1000px) {
    flex-direction: column;
  }
`

export const StyledContactDetail = styled.div`
  flex: 1;
  overflow: hidden;
  margin-bottom: 20px;
  
  @media(max-width: 1000px) {
    padding: 5px 5px;
    margin-bottom: 10px;
  }

  @media(max-width: 1024px) {
    padding: 5px 5px;
  }
`

export const StyledDivider = styled.div`
  display: flex;
  flex: 0;
  padding: 5px 15px;

  @media(max-width: 1000px) {
    padding: 5px 5px;
    margin-bottom: 10px;
  }

  @media(max-width: 1024px) {
    padding: 5px 5px;
  }
`

export const StyledOtherDetail = styled.div`
  flex: 1;
  padding: 5px 15px;
  overflow: hidden;

  @media(max-width: 1000px) {
    padding: 5px 5px;
    margin-bottom: 10px;
  }

  @media(max-width: 1024px) {
    padding: 5px 5px;
  }
`
