import _ from 'lodash'

import React from 'react'

import { generateRoute } from 'utils/routing'

import { Button, DataPreview, Typography } from 'components'

import i18n from 'translations'

import { OBSERVATIONS_PAGE_TYPE } from 'module/Learning/Observations/constants'
import NextStepsFrameworkSubject from 'module/Learning/NextSteps/components/NextStepsFrameworkSubject'

import { getData } from './NextStepsHelpers'
import { StyledComment, StyledNextStepWrapper } from './NextStepsStyled'

const NextSteps = ({
  canEditObservation,
  childObservationId,
  nextStepDetails,
  pageType,
}) => {
  const data = getData(nextStepDetails)

  const renderNextStep = (nextStep) => {
    const { comments, frameworkAreas, id } = nextStep

    return (
      <StyledNextStepWrapper key={id}>
        <StyledComment>
          <Typography variant="span">
            {comments || '-'}
          </Typography>
        </StyledComment>
        <NextStepsFrameworkSubject frameworkAreas={frameworkAreas} />
      </StyledNextStepWrapper>
    )
  }

  const renderNextSteps = () => (
    <DataPreview
      actions={canEditObservation && (
        <Button
          hierarchy="tertiary"
          label={i18n.t('global:Edit')}
          size="small"
          to={pageType === OBSERVATIONS_PAGE_TYPE.APPROVALS
            ? generateRoute('APPROVALS.OBSERVATIONS.EDIT.NEXT_STEPS', {
              childObservationId,
              observationId: childObservationId,
            })
            : generateRoute('LEARNING.OBSERVATIONS.EDIT.NEXT_STEPS', {
              childObservationId,
              observationId: childObservationId,
            })}
          negativeMargins
        />
      )}
      content={_.map(data, renderNextStep)}
      title={i18n.t('module:Learning:NextSteps:title')}
    />
  )

  return data?.length ? renderNextSteps() : null
}

export default NextSteps
