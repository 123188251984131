import React from 'react'

import {
  Callout,
  EmptyState,
  InfiniteDropdowns,
  Page,
  Pagination,
  SearchBar,
  Select,
  Spinner,
  Table,
  Toolbar,
} from 'components'

import i18n from 'translations'

import { COLUMNS } from './constants'

const ChildrenListView = ({
  ageMonthsOptions,
  childType,
  childTypeOptions,
  errorMessages,
  fromAge,
  isEmpty,
  isLoading,
  nursery,
  onAgeChange,
  onChangeNursery,
  onChildTypeFilterChange,
  onPageChange,
  onSearchChange,
  page,
  pageCount,
  perPage,
  tableData,
  toAge,
  totalResults,
}) => {
  const renderHeader = () => (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <InfiniteDropdowns.Nurseries
            value={nursery}
            onChange={onChangeNursery}
          />
        </Toolbar.Item>
        <Toolbar.Item>
          <Select
            clearable={false}
            options={childTypeOptions}
            placeholder={i18n.t('module:Children:List:Filters:childTypePlaceHolder')}
            value={childType}
            onChange={onChildTypeFilterChange}
          />
        </Toolbar.Item>
        <Toolbar.Item>{i18n.t('module:Children:List:Filters:ageInMonthsLabel')}</Toolbar.Item>
        <Toolbar.Item>
          <Select
            minWidth={100}
            options={ageMonthsOptions}
            placeholder={i18n.t('module:Children:List:Filters:ageInMonthsFromPlaceholder')}
            value={fromAge}
            onChange={onAgeChange('fromAge')}
          />
        </Toolbar.Item>
        <Toolbar.Item>{i18n.t('module:Children:List:Filters:ageInMonthsToLabel')}</Toolbar.Item>
        <Toolbar.Item>
          <Select
            minWidth={100}
            options={ageMonthsOptions}
            placeholder={i18n.t('module:Children:List:Filters:ageInMonthsToPlaceholder')}
            value={toAge}
            onChange={onAgeChange('toAge')}
          />
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )

  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('global:pagination:entries')}
      titleSingular={i18n.t('global:pagination:entry')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (isEmpty) {
      return (
        <EmptyState
          icon="children"
          text1={i18n.t('module:Children:List:emptyStateText')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={COLUMNS}
          data={tableData}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <SearchBar
        placeholder={i18n.t('module:Organization:Children:searchPlaceholder')}
        onChange={onSearchChange}
      />
      <Page.Section
        isLoading={isLoading}
        title={i18n.t('module:Organization:Children:title')}
      >
        <Callout content={errorMessages} error />
        {renderHeader()}
        {renderContent()}
      </Page.Section>
    </React.Fragment>

  )
}

export default ChildrenListView
