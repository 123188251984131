import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { getEnquiriesSingleDataSelector } from 'services/legacy/enquiries/single/selectors'
import { getNurseryData } from 'services/nurseries/single/selectors/single'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import {
  ALLERGY_OPTIONS,
  ALLERGY_TYPES,
  APPLY_PERMISSIONS,
  DIETARY_NO_PREFERENCE,
  DIETARY_REQUIREMENTS_OPTIONS,
  FAMILY_POSITIONS_OPTIONS,
  GENDER_DROPDOWN,
  TRAVEL_PERMISSIONS,
  TYPE_CONTACT_FORM,
} from 'services/legacy/child/constants'

import i18n from 'translations'

const ADDITIONAL_VALUE_PREFIX = 'Yes'

const getChildState = (state) => state.child.single

export const getChildSelector = createSelector([getChildState], (state) => state.data)

export const getCarerChildRelationsSelector = createSelector([getChildSelector], (state) => (
  state
    ? state.carerChildRelations
    : null
))

export const getChildIsFetching = createSelector([getChildState], (state) => state.isFetching)

const getTravelPermissions = ({ canGoToExternalOutgoings, canTakeTheBus }) => {
  const travelPermissions = []

  if (canTakeTheBus) {
    travelPermissions.push(TRAVEL_PERMISSIONS.CAN_TAKE_THE_BUS)
  }

  if (canGoToExternalOutgoings) {
    travelPermissions.push(TRAVEL_PERMISSIONS.CAN_GO_TO_EXTERNAL_OUTGOINGS)
  }

  return travelPermissions
}

const getApplyPermissions = ({
  facePaint,
  nappyCream,
  patches,
  sunCream,
}) => {
  const applyPermissions = []

  if (patches) {
    applyPermissions.push(APPLY_PERMISSIONS.PATCHES)
  }

  if (sunCream) {
    applyPermissions.push(APPLY_PERMISSIONS.SUN_CREAM)
  }

  if (nappyCream) {
    applyPermissions.push(APPLY_PERMISSIONS.NAPPY_CREAM)
  }

  if (facePaint) {
    applyPermissions.push(APPLY_PERMISSIONS.FACE_PAINT)
  }

  return applyPermissions
}

export const getAddChildPayload = (values) => {
  const {
    birthDate,
    codeReference,
    displayName,
    firstName,
    gender,
    information,
    middleNames,
    nurseryClass,
    photo,
    registeredAt,
    startDate,
    surname,
  } = values || {}
  const {
    address,
    allergies,
    allergiesDetails,
    canConsumeFoodWithNutTraces,
    dietaryReq,
    dietaryReqDesc,
    postcode,
  } = information || {}

  const body = {
    birthDate: birthDate ? moment(birthDate).format(DEFAULT_DATE_FORMAT) : undefined,
    canConsumeFoodWithNutTraces,
    codeReference,
    displayName,
    firstName,
    gender: gender?.value,
    information: {
      address,
      allergies: allergies?.value,
      dietaryReq: dietaryReq?.value,
      dietaryReqDesc: DIETARY_NO_PREFERENCE !== dietaryReq?.value ? dietaryReqDesc : undefined,
      postcode,
    },
    middleNames,
    nurseryClass: nurseryClass ? { id: nurseryClass?.value } : undefined,
    photo,
    registeredAt: registeredAt ? moment(registeredAt).format(DEFAULT_DATE_FORMAT) : undefined,
    startDate: startDate ? moment(startDate).format(DEFAULT_DATE_FORMAT) : undefined,
    surname,
  }

  if (allergiesDetails) {
    body.information.allergiesDetails = allergiesDetails
  }

  return body
}

export const getGeneralInformationPayload = (values) => {
  const {
    birthDate,
    codeReference,
    displayName,
    ethnicity,
    firstName,
    gender,
    information,
    middleNames,
    photo,
    surname,
  } = values || {}
  const {
    address,
    id: informationId,
    postcode,
  } = information || {}

  return {
    birthDate: birthDate ? moment(birthDate).format(DEFAULT_DATE_FORMAT) : undefined,
    codeReference,
    displayName,
    ethnicity: ethnicity ? { id: ethnicity?.value } : undefined,
    firstName,
    gender,
    information: {
      address,
      id: informationId,
      postcode,
    },
    middleNames,
    photo,
    surname,
  }
}

export const getFamilyInformationPayload = (values) => {
  const { profile } = values || {}
  const {
    familyMaritalStatus,
    familyPosition,
    familySpokenLanguage,
    id: profileId,
    parentsAreServingArmedForcesPersonnel,
    siblings,
  } = profile || {}

  return {
    profile: {
      familyMaritalStatus: familyMaritalStatus?.value,
      familyPosition: familyPosition?.value,
      familySpokenLanguage: familySpokenLanguage?.length
        ? _.join(_.map(familySpokenLanguage, ({ value }) => value), ',')
        : null,
      id: profileId,
      parentsAreServingArmedForcesPersonnel,
      siblings,
    },
  }
}

export const getLikesDislikesInformationPayload = (values) => {
  const { profile } = values || {}
  const {
    booksPreference,
    foodsPreference,
    id: profileId,
    otherPreference,
    toysPreference,
  } = profile || {}

  return {
    profile: {
      booksPreference,
      foodsPreference,
      id: profileId,
      otherPreference,
      toysPreference,
    },
  }
}

export const getEnrolmentDetailsPayload = (values) => {
  const {
    keyWorkers,
    leavingDate,
    nurseryClass,
    profile,
    registeredAt,
    startDate,
  } = values || {}
  const {
    doesReceiveEYPPFunding,
    doesReceiveTwoYearFunding,
    id: profileId,
  } = profile || {}

  return {
    keyWorkers: keyWorkers?.length ? _.map(keyWorkers, ({ value }) => ({ id: value })) : [],
    leavingDate: leavingDate ? moment(leavingDate).format(DEFAULT_DATE_FORMAT) : undefined,
    nurseryClass: nurseryClass ? { id: nurseryClass?.value } : undefined,
    profile: {
      doesReceiveEYPPFunding,
      doesReceiveTwoYearFunding,
      id: profileId,
    },
    registeredAt: registeredAt ? moment(registeredAt).format(DEFAULT_DATE_FORMAT) : undefined,
    startDate: startDate ? moment(startDate).format(DEFAULT_DATE_FORMAT) : undefined,
  }
}

export const getDevelopmentInformationPayload = (values) => {
  const { information, profile } = values || {}
  const {
    daytimeSleepingBehavior,
    daytimeSleepingBehaviorDetails,
    drinkingMethod,
    id: informationId,
    toiletTrained,
  } = information || {}
  const {
    attendsOtherSetting,
    childBehavior,
    englishAsAdditionalLanguage,
    englishAsAdditionalLanguageDetails,
    id: profileId,
    languageCapability,
    movementCapability,
    routine,
    specialEducationalNeeds,
    specialEducationalNeedsDetails,
  } = profile || {}

  let finalEnglishAsAdditionalLanguage = ''
  let finalSpecialEducationalNeeds = ''
  let finalDaytimeSleepingBehavior = ''

  if (englishAsAdditionalLanguage) {
    finalEnglishAsAdditionalLanguage = `${i18n.t('global:Yes')}`

    if (englishAsAdditionalLanguageDetails) {
      finalEnglishAsAdditionalLanguage += ` - ${englishAsAdditionalLanguageDetails}`
    }
  }

  if (specialEducationalNeeds) {
    finalSpecialEducationalNeeds = `${i18n.t('global:Yes')}`

    if (specialEducationalNeedsDetails) {
      finalSpecialEducationalNeeds += ` - ${specialEducationalNeedsDetails}`
    }
  }

  if (daytimeSleepingBehavior) {
    finalDaytimeSleepingBehavior = `${i18n.t('global:Yes')}`

    if (daytimeSleepingBehaviorDetails) {
      finalDaytimeSleepingBehavior += ` - ${daytimeSleepingBehaviorDetails}`
    }
  }

  return {
    information: {
      daytimeSleepingBehavior: finalDaytimeSleepingBehavior,
      drinkingMethod,
      id: informationId,
      toiletTrained,
    },
    profile: {
      attendsOtherSetting,
      childBehavior,
      englishAsAdditionalLanguage: finalEnglishAsAdditionalLanguage,
      id: profileId,
      languageCapability,
      movementCapability,
      routine,
      specialEducationalNeeds: finalSpecialEducationalNeeds,
    },
  }
}

export const getHealthAndDietInformationPayload = (values) => {
  const { information, profile } = values || {}
  const {
    id: profileId,
    isFussyEater,
    medicalInformation,
  } = profile || {}
  const {
    allergies,
    allergiesDetails,
    canConsumeFoodWithNutTraces,
    canReceiveMedicine,
    dietaryReq,
    dietaryReqDesc,
    facePaint,
    id: informationId,
    nappyCream,
    patches,
    sunCream,
  } = information || {}

  return {
    information: {
      allergies: allergies?.value,
      allergiesDetails: ALLERGY_TYPES.HAS_ALLERGY === allergies?.value ? allergiesDetails : undefined,
      applyPermissions: getApplyPermissions({
        facePaint,
        nappyCream,
        patches,
        sunCream,
      }),
      canConsumeFoodWithNutTraces,
      canReceiveMedicine,
      dietaryReq: dietaryReq?.value,
      dietaryReqDesc: DIETARY_NO_PREFERENCE !== dietaryReq?.value ? dietaryReqDesc : null,
      id: informationId,
    },
    profile: {
      id: profileId,
      isFussyEater,
      medicalInformation,
    },
  }
}

export const getPermissionsInformationPayload = (values) => {
  const { information } = values || {}
  const {
    canBePhotographed,
    canBeTaggedInGroupPhotos,
    canGoToExternalOutgoings,
    canTakeTheBus,
    closedSocialMediaPhotoSharingPermitted,
    id: informationId,
    otherMarketingMaterialPhotoSharingPermitted,
    socialMediaPhotoSharingPermitted,
    useOnSiteMediaPermitted,
  } = information || {}

  return {
    information: {
      canBePhotographed,
      canBeTaggedInGroupPhotos: canBePhotographed && canBeTaggedInGroupPhotos,
      closedSocialMediaPhotoSharingPermitted,
      id: informationId,
      otherMarketingMaterialPhotoSharingPermitted,
      socialMediaPhotoSharingPermitted,
      travelPermissions: getTravelPermissions({ canGoToExternalOutgoings, canTakeTheBus }),
      useOnSiteMediaPermitted,
    },
  }
}

export const getGeneralInformationInitialValues = ({ data }) => {
  const {
    birthDate,
    codeReference,
    displayName,
    ethnicity,
    firstName,
    gender,
    information,
    middleNames,
    photo,
    surname,
  } = data || {}
  const {
    address,
    id: informationId,
    postcode,
  } = information || {}

  return {
    birthDate: birthDate ? moment(birthDate) : undefined,
    codeReference,
    displayName,
    ethnicity: ethnicity ? { label: ethnicity.name, value: ethnicity.id } : undefined,
    firstName,
    gender,
    information: {
      address,
      id: informationId,
      postcode,
    },
    middleNames,
    photo: photo?.value || photo,
    surname,
  }
}

export const getFamilyInformationInitialValues = ({ data, options }) => {
  const { languagesOptions, maritalStatusDropdownOptions } = options

  const { profile } = data || {}
  const {
    familyMaritalStatus,
    familyPosition,
    familySpokenLanguage,
    id: profileId,
    parentsAreServingArmedForcesPersonnel,
    siblings,
  } = profile || {}

  const spokenLanguage = familySpokenLanguage?.length ? familySpokenLanguage.split(',') : null

  return {
    profile: {
      familyMaritalStatus: _.find(maritalStatusDropdownOptions, { value: familyMaritalStatus }),
      familyPosition: _.find(FAMILY_POSITIONS_OPTIONS, { value: familyPosition }),
      familySpokenLanguage: spokenLanguage?.length
        ? _.filter(
          _.map(spokenLanguage, (language) => _.find(languagesOptions, { value: language })),
          (option) => !!option,
        )
        : null,
      id: profileId,
      parentsAreServingArmedForcesPersonnel,
      siblings,
    },
  }
}

export const getLikesDislikesInformationInitialValues = ({ data }) => {
  const { profile } = data || {}
  const {
    booksPreference,
    foodsPreference,
    id: profileId,
    otherPreference,
    toysPreference,
  } = profile || {}

  return {
    profile: {
      booksPreference,
      foodsPreference,
      id: profileId,
      otherPreference,
      toysPreference,
    },
  }
}

export const getEnrolmentDetailsInitialValues = ({ data }) => {
  const {
    keyWorkers,
    leavingDate,
    nurseryClass,
    profile,
    registeredAt,
    startDate,
  } = data || {}
  const {
    doesReceiveEYPPFunding,
    doesReceiveTwoYearFunding,
    id: profileId,
  } = profile || {}

  return {
    keyWorkers: keyWorkers?.length ? _.map(keyWorkers, ({ id, name }) => ({ label: name, value: id })) : undefined,
    leavingDate: leavingDate ? moment(leavingDate) : undefined,
    nurseryClass: nurseryClass ? { label: nurseryClass.name, value: nurseryClass.id } : undefined,
    profile: {
      doesReceiveEYPPFunding,
      doesReceiveTwoYearFunding,
      id: profileId,
    },
    registeredAt: registeredAt ? moment(registeredAt) : undefined,
    startDate: startDate ? moment(startDate) : undefined,
  }
}

const getBooleanValuesFromString = (value) => {
  const index = _.indexOf(value || '', '-')

  if (-1 === index && value) {
    return {
      details: null,
      values: value,
    }
  }

  const values = 0 <= index ? value.slice(0, index) : null
  const details = 0 <= index ? value.slice(index + 1, value.length) : null

  return { details, values }
}

export const getDevelopmentInformationInitialValues = ({ data }) => {
  const { information, profile } = data || {}
  const {
    daytimeSleepingBehavior,
    drinkingMethod,
    id: informationId,
    toiletTrained,
  } = information || {}
  const {
    attendsOtherSetting,
    childBehavior,
    englishAsAdditionalLanguage,
    id: profileId,
    languageCapability,
    movementCapability,
    routine,
    specialEducationalNeeds,
  } = profile || {}

  const {
    details: specialEducationalNeedsDetails,
    values: specialEducationalNeedsValues,
  } = getBooleanValuesFromString(specialEducationalNeeds)
  const {
    details: englishAsAdditionalLanguageDetails,
    values: englishAsAdditionalLanguageValues,
  } = getBooleanValuesFromString(englishAsAdditionalLanguage)
  const {
    details: daytimeSleepingBehaviorDetails,
    values: daytimeSleepingBehaviorValues,
  } = getBooleanValuesFromString(daytimeSleepingBehavior)

  return {
    information: {
      daytimeSleepingBehavior: ADDITIONAL_VALUE_PREFIX === daytimeSleepingBehaviorValues?.trim(),
      daytimeSleepingBehaviorDetails: daytimeSleepingBehaviorDetails?.trim(),
      drinkingMethod,
      id: informationId,
      toiletTrained,
    },
    profile: {
      attendsOtherSetting,
      childBehavior,
      englishAsAdditionalLanguage: ADDITIONAL_VALUE_PREFIX === englishAsAdditionalLanguageValues?.trim(),
      englishAsAdditionalLanguageDetails: englishAsAdditionalLanguageDetails?.trim(),
      id: profileId,
      languageCapability,
      movementCapability,
      routine,
      specialEducationalNeeds: ADDITIONAL_VALUE_PREFIX === specialEducationalNeedsValues?.trim(),
      specialEducationalNeedsDetails: specialEducationalNeedsDetails?.trim(),
    },
  }
}

export const getHealthAndDietInformationInitialValues = ({ data }) => {
  const { information, profile } = data || {}
  const {
    id: profileId,
    isFussyEater,
    medicalInformation,
  } = profile || {}
  const {
    allergies,
    allergiesDetails,
    applyPermissions,
    canConsumeFoodWithNutTraces,
    canReceiveMedicine,
    dietaryReq,
    dietaryReqDesc,
    id: informationId,
  } = information || {}

  return {
    information: {
      allergies: _.find(ALLERGY_OPTIONS, { value: allergies }),
      allergiesDetails,
      canConsumeFoodWithNutTraces,
      canReceiveMedicine,
      dietaryReq: _.find(DIETARY_REQUIREMENTS_OPTIONS, { value: dietaryReq }),
      dietaryReqDesc,
      facePaint: applyPermissions.includes(APPLY_PERMISSIONS.FACE_PAINT),
      id: informationId,
      nappyCream: applyPermissions.includes(APPLY_PERMISSIONS.NAPPY_CREAM),
      patches: applyPermissions.includes(APPLY_PERMISSIONS.PATCHES),
      sunCream: applyPermissions.includes(APPLY_PERMISSIONS.SUN_CREAM),
    },
    profile: {
      id: profileId,
      isFussyEater,
      medicalInformation,
    },
  }
}

export const getPermissionsInformationInitialValues = ({ data }) => {
  const { information } = data || {}
  const {
    canBePhotographed,
    canBeTaggedInGroupPhotos,
    closedSocialMediaPhotoSharingPermitted,
    id: informationId,
    otherMarketingMaterialPhotoSharingPermitted,
    socialMediaPhotoSharingPermitted,
    travelPermissions,
    useOnSiteMediaPermitted,
  } = information || {}

  return {
    information: {
      canBePhotographed,
      canBeTaggedInGroupPhotos,
      canGoToExternalOutgoings: travelPermissions.includes(TRAVEL_PERMISSIONS.CAN_GO_TO_EXTERNAL_OUTGOINGS),
      canTakeTheBus: travelPermissions.includes(TRAVEL_PERMISSIONS.CAN_TAKE_THE_BUS),
      closedSocialMediaPhotoSharingPermitted,
      id: informationId,
      otherMarketingMaterialPhotoSharingPermitted,
      socialMediaPhotoSharingPermitted,
      useOnSiteMediaPermitted,
    },
  }
}

const getPlans = (formattedOpeningDays, grouped = {}) => {
  const mapPlan = (plan) => {
    const { endTime, session, startTime } = plan
    const { archived, endTime: sessionEndTime, id, isHourly, name, startTime: sessionStartTime } = session

    return {
      ...plan,
      endTime,
      sessionId: {
        endTime: sessionEndTime,
        isHourly,
        label: `${name}${archived ? ` (${i18n.t('global:archived')})` : ''}`,
        startTime: sessionStartTime,
        value: id,
      },
      startTime,
    }
  }

  return _.reduce(formattedOpeningDays, (result, openingDay) => {
    const newResult = { ...result }

    newResult[openingDay] = grouped[openingDay] ? grouped[openingDay].map(mapPlan) : [{}]

    return newResult
  }, {})
}

export const getInitialValuesFromEnquiries = createSelector(
  [getNurseryData, getEnquiriesSingleDataSelector],
  (nurseryDetail, enquiryData) => {
    if (!nurseryDetail || !enquiryData) {
      return null
    }

    const { nurserySettings } = nurseryDetail || {}
    const { formattedOpeningDays } = nurserySettings

    const { childName, data, enquiryDate, nurseryClass, sessionPlans, startDate } = enquiryData
    const { child, contact } = data

    const {
      birthDate,
      firstName,
      gender,
      surname,
    } = child || {}

    const {
      address,
      email: contactEmail,
      firstName: contactFirstName,
      postCode,
      relation,
      surname: contactSurname,
      telephone,
    } = contact || {}

    const formattedPlans = sessionPlans?.length
      ? getPlans(formattedOpeningDays, _.groupBy(sessionPlans, 'dayOfWeek'))
      : undefined

    return {
      birthDate: birthDate ? moment(birthDate).format(DEFAULT_DATE_FORMAT) : undefined,
      contacts: [
        {
          address,
          email: contactEmail,
          firstName: contactFirstName,
          postCode,
          relation: relation ? {
            label: relation,
            value: relation,
          } : undefined,
          surname: contactSurname,
          telephone,
          type: TYPE_CONTACT_FORM.NEW,
        },
      ],
      displayName: childName,
      firstName,
      gender: gender ? _.find(GENDER_DROPDOWN, { value: gender }) : undefined,
      information: {
        address,
        postcode: postCode,
      },
      nurseryClass: nurseryClass ? { label: nurseryClass.name, value: nurseryClass.id } : undefined,
      registeredAt: enquiryDate ? moment(enquiryDate) : undefined,
      session: formattedPlans
        ? { plans: formattedPlans }
        : undefined,
      startDate: startDate ? moment(startDate) : undefined,
      surname,
    }
  },
)

export const convertLegacyChildToV2 = (child) => {
  const {
    canBePhotographed,
    canBeTaggedInGroupPhotos,
    ...rest
  } = child

  return {
    ...rest,
    information: {
      canBePhotographed,
      canBeTaggedInGroupPhotos,
    },
  }
}
