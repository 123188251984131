import React, { PropsWithChildren } from 'react'

import DetailsBreakdownFooter from './DetailsBreakdownFooter'
import DetailsBreakdownHeader from './DetailsBreakdownHeader'
import DetailsBreakdownItem from './DetailsBreakdownItem'
import DetailsBreakdownSection from './DetailsBreakdownSection'
import { StyledContainer } from './DetailsBreakdownStyled'

interface DetailsBreakdownRoot<T> extends React.FC<PropsWithChildren<T>> {
  Footer?: typeof DetailsBreakdownFooter
  Header?: typeof DetailsBreakdownHeader
  Item?: typeof DetailsBreakdownItem
  Section?: typeof DetailsBreakdownSection
}

interface DetailsBreakdownProps {
  marginBottom?: boolean
}

const DetailsBreakdown: DetailsBreakdownRoot<DetailsBreakdownProps> = ({
  children,
  marginBottom,
}) => (
  <StyledContainer $marginBottom={marginBottom}>
    {children}
  </StyledContainer>
)

export default DetailsBreakdown
