import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  & > div ~ div {
    border-top: 1px dashed ${NEUTRAL_COLOURS.SILVER};
  }
`
