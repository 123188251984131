import i18n from 'translations'

import Notification from 'module/Notification'
import NotificationList from 'module/Notification/List'

export const ROUTE_NAMES = {
  'NOTIFICATION.INDEX': 'NOTIFICATION.INDEX',
}

const NotificationRouting = {
  component: Notification,
  indexRoute: {
    component: NotificationList,
  },
  name: ROUTE_NAMES['NOTIFICATION.INDEX'],
  path: 'notifications',
  title: i18n.t('module:Notifications:List:title'),
}

NotificationRouting.ROUTE_NAMES = ROUTE_NAMES

export default NotificationRouting
