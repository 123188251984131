import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { TYPES } from 'services/nurseryFunding/constants'

import { DEFAULT_DATE_FORMAT, DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'

import { FUNDING_ALLOCATION_LABELS, FUNDING_STATUS_TYPES } from '../constants'

export const getChildBulkFundingSelectors = (state) => state.childBulkFunding

export const getListDataSelectors = createSelector(
  [getChildBulkFundingSelectors],
  (state) => state.list.data,
)

export const getListMetaSelectors = createSelector(
  [getChildBulkFundingSelectors],
  (state) => state.list.meta,
)

export const getFormattedFunding = (childFunding) => {
  const { endDate, funding, startDate, type } = childFunding
  const { fundingType, label } = funding
  const { name } = fundingType
  const displayStartDate = moment(startDate).format(DISPLAY_SHORT_DATE_FORMAT)
  const displayEndDate = moment(endDate).format(DISPLAY_SHORT_DATE_FORMAT)

  return {
    ...childFunding,
    displayName: `${label} - ${name}`,
    fundingAllocationLabel: type === TYPES.MANUAL_HOURS
      ? FUNDING_ALLOCATION_LABELS.MANUAL
      : FUNDING_ALLOCATION_LABELS.AUTOMATIC,
    isManualType: type === TYPES.MANUAL_HOURS,
    period: `${displayStartDate} - ${displayEndDate}`,
  }
}

export const getTableData = createSelector(
  [getListDataSelectors],
  (listData) => {
    if (!listData?.length) {
      return null
    }

    return _.map(listData, (item) => ({
      ...item,
      currentChildFundings: _.map(item.currentChildFundings, getFormattedFunding),
      hasManualFunding: _.some(item.currentChildFundings, ({ type }) => type === TYPES.MANUAL_HOURS),
    }))
  },
)

export const getListCriteria = ({
  date,
  fromAge,
  fundingStatus,
  fundingType,
  room,
  search,
  toAge,
}) => {
  const criteria = [
    {
      field: 'isArchived',
      value: 0,
    },
    {
      field: 'currentChildFundings.archived',
      value: 0,
    },
  ]

  if (room) {
    criteria.push({
      field: 'nurseryClass',
      value: room,
    })
  }

  if (date) {
    criteria.push({
      comparator: 'before',
      field: 'currentChildFundings.startDate',
      value: moment(date).format(DEFAULT_DATE_FORMAT),
    })

    criteria.push({
      comparator: 'after',
      field: 'currentChildFundings.endDate',
      value: moment(date).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (fundingType) {
    criteria.push({
      field: 'currentChildFundings.funding',
      value: fundingType,
    })
  }

  if (fundingStatus) {
    switch (fundingStatus) {
      case FUNDING_STATUS_TYPES.FUNDING_ALLOCATED:
        criteria.push({
          comparator: 'currentChildFundings.hoursAllocated',
          field: 'same',
          value: 'currentChildFundings.totalHours',
        })
        break
      case FUNDING_STATUS_TYPES.ALLOCATION_NEEDED:
        criteria.push({
          comparator: 'currentChildFundings.hoursAllocated',
          field: 'notSame',
          value: 'currentChildFundings.totalHours',
        })
        break
      default:
        criteria.push({
          comparator: 'exists',
          field: 'currentChildFundings',
          value: 0,
        })
        break
    }
  }

  if (fromAge) {
    criteria.push({
      comparator: 'before',
      field: 'birthDate',
      value: moment()
        .add(-fromAge, 'months')
        .format(DEFAULT_DATE_FORMAT),
    })
  }

  if (toAge) {
    criteria.push({
      comparator: 'after',
      field: 'birthDate',
      value: moment()
        .add(-toAge, 'months')
        .format(DEFAULT_DATE_FORMAT),
    })
  }

  if (search) {
    criteria.push({
      field: 'concatenable',
      value: search,
    })
  }

  return criteria
}

export const getBulkCriteria = ({ children }) => {
  const criteria = []

  if (children && _.isArray(children)) {
    _.forEach(children, (childId) => {
      criteria.push({
        field: 'id[]',
        value: childId,
      })
    })
  }

  return criteria
}

export const getBulkPayload = ({ archived, fundingType }) => {
  if (!fundingType) {
    return null
  }

  const { type, value } = fundingType

  return {
    archived,
    funding: { id: value, type },
    type,
  }
}
