import styled from 'styled-components'

import { StyledCallout } from 'components/Callout/CalloutStyled'

export const StyledBannerWrapper = styled.div`
  margin-top: 2px;

  ${({ borderVersion }) => borderVersion && `
    margin: 0;
    
    ${StyledCallout} {
      border-left: 0 !important;
      border-radius: 0;
      margin: 0;
    }
  `}
`
