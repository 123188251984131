import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { stopSubmit } from 'redux-form'

import { LIST_STATUS_FILTERS } from 'constants/filters'
import { NURSERY_SESSIONS_FILTER } from 'services/nurserySessions/constants'
import { VIEWING_STATUSES, VIEWING_STATUS_OPTIONS } from 'services/legacy/enquiries/viewings/constants'
import { FEATURE_FLAGS } from 'constants/security'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'
import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withEnquiriesService, withEnquiryViewingsService } from 'services/legacy/enquiries'
import { withNurseriesService } from 'services/nurseries'
import { withChildSessionsService } from 'services/legacy/childSessions'
import { withNurserySessionsService } from 'services/nurserySessions'
import { withNurserySessionsV3Service } from 'services/product/nurserySessionsV3'
import { withRouter } from 'services/router'

import AddView from './AddView'
import { ENQUIRIES_ADD_FORM } from './components/AddForm'
import { ENQUIRY_GROUPS, ENQUIRY_GROUPS_V3 } from './constants'

const NURSERY_SESSIONS_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings',
  ],
}

const NURSERY_SESSIONS_V3_GROUPS = {
  read: [
    'nurserySessionProduct.priceChanges',
    'productPriceChange',
    'productPriceChange.prices',
    'productPrice',
    'productPrice.priceGroup',
    'productPriceGroup',
  ],
}

class AddContainer extends Component {
  constructor(props) {
    super(props)

    const { isFinanceV3Enabled, location, params } = props
    const { enquiryId } = params
    const { query } = location

    this.state = {
      enquiryId,
      groups: isFinanceV3Enabled ? ENQUIRY_GROUPS_V3 : ENQUIRY_GROUPS,
      isEditMode: !!enquiryId,
      section: query?.s,
    }
  }

  componentDidMount() {
    const {
      enquiriesActions,
      isFinanceV3Enabled,
      nurseriesActions,
      nurseryOptions,
      nurserySessionsActions,
      nurserySessionsSelectors,
      nurserySessionsV3Actions,
      nurserySessionsV3Selectors,
    } = this.props
    const { enquiryId, groups } = this.state

    nurseriesActions.get(nurseryOptions.id, { params: { groups: NURSERY_SESSIONS_GROUPS } })

    if (isFinanceV3Enabled) {
      const criteria = nurserySessionsV3Selectors.getCriteria({
        statusFilter: LIST_STATUS_FILTERS.ACTIVE,
      })

      nurserySessionsV3Actions.list({
        params: {
          criteria,
          groups: NURSERY_SESSIONS_V3_GROUPS,
        },
        recursively: true,
      })
    } else {
      nurserySessionsActions.list({
        params: {
          criteria: nurserySessionsSelectors.getCriteria({ statusFilter: NURSERY_SESSIONS_FILTER.ACTIVE }),
        },
        recursively: true,
      })
    }

    if (enquiryId) {
      enquiriesActions.get({
        params: [enquiryId, {
          groups,
        }],
      })
    }
  }

  componentWillUnmount() {
    const {
      enquiriesActions,
      enquiryViewingsActions,
      nurseriesActions,
      nurserySessionsActions,
      nurserySessionsV3Actions,
    } = this.props

    nurserySessionsActions.clear()
    nurserySessionsV3Actions.clearList()
    nurseriesActions.clearSingle()
    enquiriesActions.clear()
    enquiriesActions.clearSingle()
    enquiryViewingsActions.clear()
  }

  handleSubmitFailed = (response) => {
    const { injectValidation } = this.props
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    return injectValidation(ENQUIRIES_ADD_FORM, errors)
  }

  handleSubmitSuccess = ({ data }, formValues) => {
    const { enquiryViewingsActions, enquiryViewingsSelectors } = this.props
    const { assignedPerson, showAroundDate, showAroundTime } = formValues

    if (showAroundDate || showAroundTime || assignedPerson) {
      const body = enquiryViewingsSelectors.getPayload({
        ...formValues,
        enquiryId: data.id,
        status: _.find(VIEWING_STATUS_OPTIONS, { value: VIEWING_STATUSES.BOOKED }),
      })

      enquiryViewingsActions.create({
        body,
        onSuccess: this.redirect,
        params: [{}],
      })

      return
    }

    this.redirect()
  }

  redirect = () => {
    const { navigate } = this.props
    const { enquiryId, isEditMode } = this.state

    if (isEditMode) {
      navigate(generateRoute('ENQUIRIES.DETAIL', { enquiryId }))

      return
    }

    navigate(generateRoute('ENQUIRIES.LIST'))
  }

  handleSubmit = (formValues) => {
    const { enquiriesActions, enquiriesSelectors, isFinanceV3Enabled } = this.props
    const { enquiryId, isEditMode } = this.state

    const body = enquiriesSelectors.getPayload(formValues, isFinanceV3Enabled)

    if (isEditMode) {
      enquiriesActions.update({
        body,
        onFailed: this.handleSubmitFailed,
        onSuccess: this.redirect,
        params: [enquiryId, {}],
      })

      return
    }

    enquiriesActions.create({
      body,
      onFailed: this.handleSubmitFailed,
      onSuccess: (response) => this.handleSubmitSuccess(response, formValues),
      params: [{}],
    })
  }

  render() {
    const {
      OccupancyEnabled,
      errorMessages,
      initialValues,
      isFetching,
      isFinanceV3Enabled,
      isSubmitting,
      nursery,
      nurserySessionsOptions,
      nurserySessionsV3Options,
    } = this.props
    const { formattedOpeningDays } = nursery?.nurserySettings || {}
    const { enquiryId, isEditMode, section } = this.state

    return (
      <AddView
        OccupancyEnabled={OccupancyEnabled}
        enquiryId={enquiryId}
        errorMessages={errorMessages}
        initialValues={initialValues}
        isEditMode={isEditMode}
        isFinanceV3Enabled={isFinanceV3Enabled}
        isLoading={isFetching}
        isSubmitting={isSubmitting}
        nurserySessionsOptions={isFinanceV3Enabled ? nurserySessionsV3Options : nurserySessionsOptions}
        openingDays={formattedOpeningDays}
        section={section}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  injectValidation: (formName, params) => stopSubmit(formName, params),
}

const mapState = (state, {
  appSelectors,
  childSessionsSelectors,
  enquiriesSelectors,
  enquiriesSingleState,
  nurseriesSelectors,
  nurserySessionsV3Selectors,
  params,
}) => ({
  OccupancyEnabled: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.OCCUPANCY_ENABLED],
  }),
  errorMessages: appSelectors.getErrorMessages(enquiriesSingleState),
  initialValues: enquiriesSelectors.getInitialValues(!!params.enquiryId)(state),
  isFetching: appSelectors.getIsFetching(enquiriesSingleState),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
  isSubmitting: appSelectors.getIsSubmitting(enquiriesSingleState),
  nursery: nurseriesSelectors.getNurseryData(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  nurserySessionsOptions: childSessionsSelectors.getNurserySessionsOptions(state),
  nurserySessionsV3Options: nurserySessionsV3Selectors.getNurserySessionsV3Options(state),
})

const enhance = compose(
  withRouter,
  withAppService,
  withEnquiriesService,
  withEnquiryViewingsService,
  withNurseriesService,
  withChildSessionsService,
  withNurserySessionsService,
  withNurserySessionsV3Service,
  connect(mapState, mapDispatch),
)

export default enhance(AddContainer)
