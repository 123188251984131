import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class ObservationFeedbackApiClient extends ServiceBase {
  list = (params = {}) => this.request({
    path: `/v2/observation_feedbacks?${getQueryStringV2(params)}`,
  })

  get = (id, params = {}) => this.request({
    path: `/v2/observation_feedbacks/${id}?${getQueryStringV2(params)}`,
  })

  create = (payload) => this.request({
    body: payload,
    method: 'POST',
    path: '/v2/observation_feedbacks',
  })

  update = (id, payload) => this.request({
    body: payload,
    method: 'PUT',
    path: `/v2/observation_feedbacks/${id}`,
  })
}

export default ObservationFeedbackApiClient
