import moment from 'moment'

import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import colors from 'constants/colors'

import { Button, Tooltip, Typography } from 'components'

import i18n from 'translations'

import { StyledContentContainer, StyledSignatureContainer } from './SignatureDigitalFormStyled'

const SignatureDigitalFormContent = ({
  disableAddButton,
  disabledItem,
  onAddSignatureClick,
  signatureId,
  signatureUid,
  signedAt,
  url,
}) => {
  const renderImageContent = () => (
    <StyledSignatureContainer>
      <Typography padding="0 0 10px" variant="span">
        <Typography padding="0 10px 0" variant="span">
          {i18n.t('module:Injury:Preview:Signatures:Digital:signature')}
        </Typography>
        <Typography color={colors.gray} variant="span">
          {moment(signedAt).format('DD/MM/YYYY')}
        </Typography>
      </Typography>
      <LazyLoadImage
        alt="signature"
        effect="opacity"
        src={url}
      />
    </StyledSignatureContainer>
  )

  const renderAddButton = () => (
    <StyledSignatureContainer>
      <Tooltip title={disabledItem ? i18n.t('module:Injury:Preview:Signatures:approvalTooltip') : null}>
        <Button
          disabled={disabledItem || disableAddButton}
          icon="plus"
          label={i18n.t('module:Injury:Preview:Signatures:Digital:addButtonLabel')}
          onClick={() => onAddSignatureClick(signatureId, signatureUid)}
        />
      </Tooltip>
    </StyledSignatureContainer>
  )

  return (
    <StyledContentContainer>
      {url ? renderImageContent() : renderAddButton()}
    </StyledContentContainer>
  )
}

export default SignatureDigitalFormContent
