import i18n from 'translations'

export const NAPPY_WAS = {
  BOTH: 'BOTH',
  CLEAN: 'CLEAN',
  SOILED: 'SOILED',
  WET: 'WET',
}

export const NAPPY_WAS_OPTIONS = [
  {
    label: i18n.t('services:DailyDiaryNappies:nappyWasOptions:Clean'),
    value: NAPPY_WAS.CLEAN,
  },
  {
    label: i18n.t('services:DailyDiaryNappies:nappyWasOptions:Wet'),
    value: NAPPY_WAS.WET,
  },
  {
    label: i18n.t('services:DailyDiaryNappies:nappyWasOptions:Soiled'),
    value: NAPPY_WAS.SOILED,
  },
  {
    label: i18n.t('services:DailyDiaryNappies:nappyWasOptions:Both'),
    value: NAPPY_WAS.BOTH,
  },
]
