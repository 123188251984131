import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { components } from 'react-select'

import { withAppService } from 'services/app'
import { withSupportedLocaleService } from 'services/legacy/supportedLocale'

import { Select, Typography } from 'components'

import i18n from 'translations'

import withInfiniteDropdown from '../withInfiniteDropdown'
import { StyledOption } from './SupportedLocaleDropdownStyled'

const SingleValue = (item) => {
  const { data } = item
  const code = data.value?.split('_')?.[1]?.toLowerCase()

  return (
    <components.SingleValue {...item}>
      <StyledOption>
        <div
          className={`flags flags--${code}`}
        />
        <Typography>
          {data.label}
        </Typography>
      </StyledOption>
    </components.SingleValue>
  )
}

const Option = (item) => {
  const { data } = item
  const code = data.value?.split('_')?.[1]?.toLowerCase()

  return (
    <components.Option {...item}>
      <StyledOption>
        <div
          className={`flags flags--${code}`}
        />
        <Typography>
          {data.label}
        </Typography>
      </StyledOption>
    </components.Option>
  )
}

class SupportedLocaleDropdown extends Component {
  componentDidMount() {
    const { infiniteDropdownHelpers, supportedLocaleActions, value } = this.props

    supportedLocaleActions.list({
      onSuccess: (response) => {
        const newData = _.sortBy(
          _.map(response.data, ({ code, ...rest }) => ({
            ...rest,
            id: code,
          })),
          (item) => -item.frequent,
        )

        infiniteDropdownHelpers.handleLoadMoreElementsOnSuccess({
          newPage: 1,
          response: {
            data: newData,
          },
          result: {},
        })

        if (value) {
          let element = _.find(response.data, (item) => item.code === value)

          element = {
            ...element,
            id: element.code,
          }

          infiniteDropdownHelpers.handleComponentDidMountOnSuccess({ data: element })
        }
      },
    })
  }

  componentWillUnmount() {
    const { supportedLocaleActions } = this.props

    supportedLocaleActions.clear()
  }

  handleChange = (e) => {
    const { infiniteDropdownHelpers, onChange } = this.props

    infiniteDropdownHelpers.handleOnChange(e, () => onChange(e))
  }

  render() {
    const {
      disabled,
      infiniteDropdownState,
      isFetching,
      placeholder,
      rawValue,
      value,
      ...rest
    } = this.props
    const { cacheUniq, options, selectedValue } = infiniteDropdownState

    return (
      <Select
        {...rest}
        cacheUniq={cacheUniq}
        components={{
          Option,
          SingleValue,
        }}
        disabled={disabled || isFetching}
        options={_.orderBy(options, ({ frequent }) => frequent)}
        placeholder={placeholder || _.upperFirst(i18n.t('components:InfiniteDropdowns:SupportedLocale:placeholder'))}
        rawValue={rawValue || selectedValue || value}
        value={value}
        searchable
        onChange={this.handleChange}
      />
    )
  }
}

const mapState = (state, { appSelectors, supportedLocaleListState }) => ({
  isFetching: appSelectors.getIsFetching(supportedLocaleListState),
})

const enhance = compose(
  withAppService,
  withSupportedLocaleService,
  withInfiniteDropdown,
  connect(mapState),
)

export default enhance(SupportedLocaleDropdown)
