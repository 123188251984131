import { getQueryStringV2 } from 'utils/api'

import ServiceBase from 'services/ServiceBase'

class NurseryIntegrationsApiClient extends ServiceBase {
  getPayout = (id, params) => this.request({
    path: `/v2/integrations/payouts/${id}?${getQueryStringV2(params)}`,
  })

  getPayouts = (params) => this.request({
    path: `/v2/integrations/payouts?${getQueryStringV2(params)}`,
  })

  getPayoutStatistics = (params) => this.request({
    path: `/v2/integrations/payouts/statistics?${getQueryStringV2(params)}`,
  })

  payoutTransactions = (id, params) => this.request({
    path: `/v2/integrations/payouts/${id}/transactions?${getQueryStringV2(params)}`,
  })

  updateStripeCapability = (capabilities, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/integrations/stripe/capabilities/${capabilities}`,
  })

  createStripeIntegration = (params, body = {}) => this.request({
    body,
    method: 'POST',
    path: `/v2/nursery_integrations/stripe?${getQueryStringV2(params)}`,
  })

  removeIntegration = (id) => this.request({
    method: 'DELETE',
    path: `/v2/nursery_integrations/${id}`,
  })

  updateIntegration = (id, params, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/nursery_integrations/${id}?${getQueryStringV2(params)}`,
  })

  getPayoutCurrencies = () => this.request({
    path: '/v2/integrations/payouts/currencies',
  })

  getDisconnectConditions = (id) => this.request({
    path: `/v2/nursery_integrations/${id}/disconnect_conditions`,
  })

  failedRecurringPayment = (params) => this.request({
    path: `/v2/integrations/stripe/failed_recurring_payment_runs?${getQueryStringV2(params)}`,
  })

  exportFailedRecurringPayment = (params) => this.request({
    path: `/v2/export/integrations/stripe/failed_recurring_payment_runs?${getQueryStringV2(params)}`,
  })

  activeRecurringPayment = (params) => this.request({
    path: `/v2/integrations/children/active_recurring_payments?${getQueryStringV2(params)}`,
  })

  exportActiveRecurringPayment = (params) => this.request({
    path: `/v2/export/integrations/children/active_recurring_payments?${getQueryStringV2(params)}`,
  })

  exportPayoutTransactions = (id, params) => this.request({
    path: `/v2/export/integrations/payouts/${id}/transactions?${getQueryStringV2(params)}`,
  })
}

export default NurseryIntegrationsApiClient
