import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as supportedLocaleSelectors from './selectors'

const withSupportedLocaleService = (WrappedComponent) => {
  const mapState = ({ supportedLocale }) => ({ supportedLocale })

  const mapDispatch = injectActions('supportedLocaleActions', actions)

  const Component = ({ supportedLocale, supportedLocaleActions, ...others }) => (
    <WrappedComponent
      supportedLocaleActions={supportedLocaleActions}
      supportedLocaleListState={supportedLocale.list}
      supportedLocaleSelectors={supportedLocaleSelectors}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withSupportedLocaleService
