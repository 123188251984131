import React from 'react'

import { Button, Space, Spinner, Typography } from 'components'

import i18n from 'translations'

import CredentialsForm from './components/CredentialsForm'
import { BoxWrapper, PinProfileAvatar } from '../components'

const CredentialsView = ({
  errorMessage,
  isAuthFetching,
  isCreatePinPage,
  isFetching,
  memberDetails,
  onCancelButtonClick,
  onContinueButtonClick,
  onSubmit,
}) => {
  if (isFetching) {
    return <Spinner />
  }

  const { firstName, name, photo, surname } = memberDetails || {}

  const renderContent = () => (
    <React.Fragment>
      <Typography align="center" variant="h6" medium>
        {isCreatePinPage
          ? i18n.t('module:StaffRegister:global:createPin')
          : i18n.t('module:StaffRegister:global:forgotPin')}
      </Typography>
      <Space margin="10px 0 0" />
      <Typography align="center" variant="h5">
        {i18n.t('module:StaffRegister:Credentials:title')}
      </Typography>
      <Space margin="15px 0 0" />
      <PinProfileAvatar
        firstName={firstName}
        name={name}
        photo={photo}
        surname={surname}
      />
      {errorMessage && (
        <React.Fragment>
          <Space margin="15px 0 0" />
          <Typography tertiary>
            {errorMessage}
          </Typography>
        </React.Fragment>
      )}
      <Space margin="35px 0 0" />
      <CredentialsForm
        isFetching={isAuthFetching}
        onSubmit={onSubmit}
      />
    </React.Fragment>
  )

  return (
    <BoxWrapper
      footer={(
        <React.Fragment>
          <Button
            disabled={isAuthFetching}
            hierarchy="tertiary"
            label={i18n.t('global:Cancel')}
            negativeMargins
            onClick={onCancelButtonClick}
          />
          <Button
            isLoading={isAuthFetching}
            label={i18n.t('global:Continue')}
            negativeMargins
            onClick={onContinueButtonClick}
          />
        </React.Fragment>
      )}
    >
      {renderContent()}
    </BoxWrapper>
  )
}

export default CredentialsView
