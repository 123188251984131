import React from 'react'

import { StyledErrorContainer, StyledErrorMsgContainer } from './RegisterItemStyled'

const RegisterItemError = ({ errorMessage }) => {
  if (!errorMessage) {
    return null
  }

  return (
    <StyledErrorContainer>
      <StyledErrorMsgContainer>
        {errorMessage}
      </StyledErrorMsgContainer>
    </StyledErrorContainer>
  )
}

export default RegisterItemError
