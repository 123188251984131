import _ from 'lodash'
import { useChannelMembers, useCurrentChannel, useCurrentChannelMember, useSync } from '@blossomdev/sync'
import browser from 'browser-detect'

import React, { useCallback, useState } from 'react'

import { MESSAGE_USER_TYPE } from 'services/legacy/messaging/constants'

import { generateRoute } from 'utils/routing'

import ChannelList from 'module/Messaging/components/ChannelList'
import { Space, Spinner } from 'components'

import BottomBanners from './components/BottomBanners'
import Editor from './components/Editor'
import Messages from './components/Messages'
import MobileDrawer from './components/MobileDrawer'
import ChatHeader from './components/ChatHeader'
import { StyledChannelList, StyledMessagesWrapper, StyledPageWrapper } from './MessagingListStyled'

const MessagingListView = ({
  authUser,
  channelId,
  isMessagingEnabled,
  isNewChannelPage,
  location,
  onChildSelect,
  onCreateNewChannel,
  onLeaveChannel,
  onShowConversationMembers,
  onUnsubscribe,
}) => {
  const { createMessage } = useSync()
  const { currentChannel: channel } = useCurrentChannel(channelId, onCreateNewChannel, onUnsubscribe)
  const { channelMembers } = useChannelMembers(channel)
  const { currentChannelMember } = useCurrentChannelMember(channel)
  const [isEmptyState, setEmptyState] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const { unsubscribedAt } = currentChannelMember || {}
  const { name } = browser()
  const { pathname } = location
  const isMainMessagingPage = pathname === generateRoute('MESSAGING.INDEX')

  const numberOfParentsWithAccessToMessaging = !!_.filter(
    channelMembers,
    ({ member: { type }, unsubscribedAt: date }) => MESSAGE_USER_TYPE.PARENT === type && !date,
  ).length

  const onAddNewMessage = useCallback((message) => {
    const trimMessage = message.trim()

    if (!_.isEmpty(trimMessage)) {
      createMessage(channel, trimMessage)
    }
  }, [channel, createMessage])

  const handleInitialized = useCallback(() => {
    setInitialized(true)
  }, [])

  const renderRightBody = () => {
    if (isMainMessagingPage && !isEmptyState) {
      return (
        <React.Fragment>
          <Space space="20px" />
          <Spinner />
        </React.Fragment>
      )
    }

    if (isEmptyState) {
      return null
    }

    return (
      <StyledMessagesWrapper
        browserName={name}
      >
        <ChatHeader
          channel={channel}
          channelMembers={channelMembers}
          isNewChannelPage={isNewChannelPage}
          unsubscribedAt={unsubscribedAt}
          onChildSelect={onChildSelect}
          onLeaveChannel={onLeaveChannel}
          onShowConversationMembers={() => onShowConversationMembers(channel, channelMembers)}
        />
        <Messages
          authUser={authUser}
          channel={channel}
          channelId={channelId}
          isNewChannelPage={isNewChannelPage}
          onInitialized={handleInitialized}
        />
        {currentChannelMember && initialized && (
          <BottomBanners
            archivedAt={channel?.archivedAt}
            childName={channel?.name}
            isMessagingEnabled={isMessagingEnabled}
            isNewChannelPage={isNewChannelPage}
            numberOfParents={numberOfParentsWithAccessToMessaging}
            unsubscribedAt={unsubscribedAt}
          />
        )}
        {!unsubscribedAt
        && numberOfParentsWithAccessToMessaging
        && !channel?.archivedAt
        && isMessagingEnabled
        && (
          <Editor
            channelId={channelId}
            disabled={isNewChannelPage}
            onSubmit={onAddNewMessage}
          />
        )}
      </StyledMessagesWrapper>
    )
  }

  return (
    <StyledPageWrapper>
      <MobileDrawer
        channelId={channelId}
        isNewChannelPage={isNewChannelPage}
        onIsEmptyState={setEmptyState}
      />
      <StyledChannelList hide={isMainMessagingPage && !isEmptyState}>
        <ChannelList
          activeChannelId={channelId}
          isNewChannelPage={isNewChannelPage}
          expanded
          onIsEmptyState={setEmptyState}
        />
      </StyledChannelList>
      {renderRightBody()}
    </StyledPageWrapper>
  )
}

export default MessagingListView
