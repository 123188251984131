import { createSelector } from 'reselect'

import { getFilesInitialValues } from 'services/legacy/files/selectors'

const getNurseryState = (state) => state.nurseries

export const getNurseryFilesList = createSelector([getNurseryState], (state) => state.files)

export const getNurseryFilesListData = createSelector([getNurseryFilesList], (state) => state?.data)

export const getNurseryFilesInitialValuesSelector = createSelector(
  [getNurseryFilesListData],
  getFilesInitialValues,
)
