import { generateBasicActions } from 'utils/service'

import {
  CLEAR_FRAMEWORK_PROGRESS_LEVELS,
  LIST_FRAMEWORK_PROGRESS_LEVELS,
  LIST_FRAMEWORK_PROGRESS_LEVELS_FAILED,
  LIST_FRAMEWORK_PROGRESS_LEVELS_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ frameworkProgressLevelsApiClient }) => generateBasicActions.list({
  constants: {
    failed: LIST_FRAMEWORK_PROGRESS_LEVELS_FAILED,
    init: LIST_FRAMEWORK_PROGRESS_LEVELS,
    success: LIST_FRAMEWORK_PROGRESS_LEVELS_SUCCESS,
  },
  options,
  service: frameworkProgressLevelsApiClient,
  serviceMethod: 'list',
})

export const clear = () => ({
  type: CLEAR_FRAMEWORK_PROGRESS_LEVELS,
})
