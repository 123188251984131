import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledContainer = styled.div`
  margin-bottom: 50px;
`

export const StyledItemContainer = styled.div`
  background-color: #F7F7F7;
  border-radius: 15px;
  display: flex;
  min-height: 60px;
  overflow: hidden;
`

export const StyledSessionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  
  & ${StyledItemContainer} ~ ${StyledItemContainer} {
    margin-top: 10px;
  }
`

export const StyledDayContainer = styled.div`
  align-items: center;
  background-color: ${NEUTRAL_COLOURS.SILVER};
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  min-width: 80px;
`

export const StyledSessionTextContainer = styled.span`
  color: #949494;
`

export const StyledPlanItemContainer = styled.div`
  ${({ highlighted }) => highlighted && 'color: #00a3e6;'}
  display: flex;
  min-width: 100px;
`

export const StyledPlanGroupContainer = styled.div`
  display: flex;

  & ${StyledPlanItemContainer} ~ ${StyledPlanItemContainer} {
    margin-left: 20px;
  }
`

export const StyledPlanContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;

  & ${StyledPlanGroupContainer} ~ ${StyledPlanGroupContainer} {
    margin-left: 20px;

    @media (min-width: 600px) {
      margin-left: 0;
      margin-top: 5px;
    }
  }
`

export const StyledSessionContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 10px 15px;
  
  & ${StyledPlanContainer} ~ ${StyledPlanContainer} {
    border-top: 1px dashed #B3B3B3;
    margin-top: 7px;
    padding-top: 7px;
  }
`
