import i18n from 'translations'

export const ENTRY_TAB_NAMES = {
  CALL: 'CALL',
  EMAIL: 'EMAIL',
  NOTE: 'NOTE',
  VIEWING: 'VIEWING',
}

export const LIST_TAB_NAMES = {
  ALL: 'ALL',
  CALLS: 'CALLS',
  EMAILS: 'EMAILS',
  NOTES: 'NOTES',
  VIEWINGS: 'VIEWINGS',
}

export const ACTIVITY_TYPE = {
  CALL: { editable: true, value: 'call' },
  CREATE: { editable: false, value: 'create' },
  EMAIL: { editable: true, value: 'email' },
  NOTE: { editable: true, value: 'note' },
  STATUS_CHANGE: { editable: false, value: 'statusChange' },
  VIEWING: { editable: false, value: 'showAround' },
}

export const ACTIVITY_TYPES_MAP = {
  [ACTIVITY_TYPE.NOTE.value]: i18n.t('module:Enquiries:List:Detail:Activity:Entry:SnackbarMessage:Types:note'),
  [ACTIVITY_TYPE.EMAIL.value]: i18n.t('module:Enquiries:List:Detail:Activity:Entry:SnackbarMessage:Types:email'),
  [ACTIVITY_TYPE.CALL.value]: i18n.t('module:Enquiries:List:Detail:Activity:Entry:SnackbarMessage:Types:call'),
  [ACTIVITY_TYPE.VIEWING.value]: i18n.t(
    'module:Enquiries:List:Detail:Activity:Entry:SnackbarMessage:Types:viewing',
  ),
}

export const LIST_TAB_TYPE_MAPPING = {
  [LIST_TAB_NAMES.CALLS]: ACTIVITY_TYPE.CALL.value,
  [LIST_TAB_NAMES.EMAILS]: ACTIVITY_TYPE.EMAIL.value,
  [LIST_TAB_NAMES.NOTES]: ACTIVITY_TYPE.NOTE.value,
  [LIST_TAB_NAMES.VIEWINGS]: ACTIVITY_TYPE.VIEWING.value,
}

export const LIST_TAB_WITH_ENTRY_MAPPING = {
  [LIST_TAB_NAMES.CALLS]: ENTRY_TAB_NAMES.CALL,
  [LIST_TAB_NAMES.EMAILS]: ENTRY_TAB_NAMES.EMAIL,
  [LIST_TAB_NAMES.NOTES]: ENTRY_TAB_NAMES.NOTE,
  [LIST_TAB_NAMES.VIEWINGS]: ENTRY_TAB_NAMES.VIEWING,
}

export const ACTIVITY_EVENT_TYPE = {
  LOGGED_BY_MEMBER: 'loggedByMember',
  MIGRATE: 'migrate',
  SUBMITTED_BY_PARENT: 'submittedByParent',
}
