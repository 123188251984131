import i18n from 'translations'

export const ENQUIRY_REASON_LABELS = {
  Other: 'Other',
}

export const SOURCE_LABELS = {
  Other: 'Other',
}

export const ENQUIRY_STATUS_CODES = {
  ENROLLED: 'enrolled',
  LOST_ENQUIRY: 'lostEnquiry',
  NEW_ENQUIRY: 'newEnquiry',
  NO_SPACE_AVAILABLE: 'noSpaceAvailable',
}

export const ENQUIRY_VIEWING_FILTER_STATUSES = {
  BOOKED: 'BOOKED',
  NOT_BOOKED: 'NOT_BOOKED',
}

export const ENQUIRY_VIEWING_FILTER_STATUS_OPTIONS = [
  {
    label: i18n.t('services:Enquiries:ViewingStatusFilters:booked'),
    value: ENQUIRY_VIEWING_FILTER_STATUSES.BOOKED,
  },
  {
    label: i18n.t('services:Enquiries:ViewingStatusFilters:notBooked'),
    value: ENQUIRY_VIEWING_FILTER_STATUSES.NOT_BOOKED,
  },
]
