import React from 'react'

import { ROLES } from 'constants/security'

import ClosureDaysView from './ClosureDaysView'

const ClosureDaysContainer = () => <ClosureDaysView />

ClosureDaysContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

export default ClosureDaysContainer
