export const LIST_SESSIONS = 'LIST_SESSIONS'
export const LIST_SESSIONS_SUCCESS = 'LIST_SESSIONS_SUCCESS'
export const LIST_SESSIONS_FAILED = 'LIST_SESSIONS_FAILED'

export const listSessions = (params) => ({ organizationSessionsApiClient }) => (dispatch) => {
  dispatch({
    type: LIST_SESSIONS,
  })

  organizationSessionsApiClient.list(params).then(
    (data) => {
      dispatch({
        payload: data,
        type: LIST_SESSIONS_SUCCESS,
      })
    },
    (error) => {
      dispatch({
        error: error.response,
        type: LIST_SESSIONS_FAILED,
      })
    },
  )
}
