import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

const getMonthlyFteChangeReport = (state) => state.monthlyFteChangeReport

export const getMonthlyFteChangeReportNurseries = createSelector(
  [getMonthlyFteChangeReport],
  (state) => state.nurseries,
)

export const getMonthlyFteChangeReportNurseriesData = createSelector(
  [getMonthlyFteChangeReportNurseries],
  (state) => state.data,
)

export const getCriteria = ({ date, year }) => {
  const criteria = []

  if (year) {
    criteria.push({
      field: 'startDate',
      value: `${year}-01-01`,
    })

    const endDate = moment(`${year}-DEC-31`)

    criteria.push({
      field: 'endDate',
      value: moment().isBefore(endDate)
        ? moment().format(DEFAULT_DATE_FORMAT)
        : endDate.format(DEFAULT_DATE_FORMAT),
    })
  } else {
    criteria.push({
      field: 'startDate',
      value: moment(date).startOf('month').format(DEFAULT_DATE_FORMAT),
    })

    criteria.push({
      field: 'endDate',
      value: moment(date).endOf('month').format(DEFAULT_DATE_FORMAT),
    })
  }

  return criteria
}

