import moment from 'moment'

import React, { useCallback } from 'react'

import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

import { CircleIcon, DropdownMenu, Space, Typography } from 'components'

import i18n from 'translations'

import { StyledErrorWrapper, StyledMessage, StyledMessageContent, StyledMessageWrapper } from './MessageStyled'

const Message = ({
  author = {},
  content,
  createdAt,
  deliveryError,
  first,
  isMe,
  last,
  left,
  style,
}) => {
  const renderError = useCallback(() => {
    if (!deliveryError) {
      return null
    }

    return (
      <StyledErrorWrapper>
        <DropdownMenu
          button={(
            <CircleIcon
              background={FLAG_COLOURS.ERROR}
              icon="exclamation-mark"
              size={24}
            />
          )}
          placement="bottom-start"
        >
          <DropdownMenu.Item
            icon="reset"
            label={i18n.t('module:Messaging:MessagingList:components:Message:retry')}
          />
        </DropdownMenu>
      </StyledErrorWrapper>
    )
  }, [deliveryError])

  const getUsernameColor = useCallback(() => {
    if (isMe) {
      return getBrandingColor('primary-color')
    }

    if (left) {
      return NEUTRAL_COLOURS.GRAY
    }

    return NEUTRAL_COLOURS.BLUE
  }, [isMe, left])

  return (
    <StyledMessageWrapper
      deliveryError={deliveryError}
      first={first}
      left={left}
      style={style}
    >
      {renderError()}
      <StyledMessageContent first={first}>
        {first && (
          <Typography
            align={left ? 'left' : 'right'}
            color={getUsernameColor()}
            fontSize={14}
            margin="0 10px"
            width="auto"
            bold
          >
            {author.name}
          </Typography>
        )}
        <StyledMessage isMe={isMe} last={last} left={left}>
          <Typography overflow="hidden" wordBreak="break-word" prewrap>
            {content}
          </Typography>
          <Space space="2px" />
          <Typography align="right" color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
            {moment(createdAt).format('HH:mm')}
          </Typography>
        </StyledMessage>
      </StyledMessageContent>
    </StyledMessageWrapper>
  )
}

export default Message
