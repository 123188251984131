import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'recompose'

import { isRequired } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'

// eslint-disable-next-line max-len
import {
  DiscountFormFields,
} from 'module/Management/ManagementFinanceSettings/ManagementDiscounts/v2/ManagementDiscountsV2Add/components'

import { Form } from 'components'

import i18n from 'translations'

export const FORM_NAME = 'ChildDiscountsAddForm'

const ChildDiscountsAddForm = ({
  childDob,
  childId,
  discountTypeOptions,
  formValues,
  handleSubmit,
  isEdit,
  isLoading,
  isOtherSelected,
  isPercentageSelected,
  nurseryDiscountDescription,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Field component={Form.TextField} name="childDob" type="hidden" value={childDob} />
    <Form.Row label="Discount">
      <Form.Row.FlexItem flex="0 0 300px">
        <Field
          component={Form.InfiniteDropdowns.NurseryDiscounts}
          disabled={isEdit}
          extraFields={['applicable', 'type']}
          name="nurseryDiscount"
          validate={isRequired}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    {nurseryDiscountDescription && (
      <Form.Row label>
        <Form.Row.Item>
          {nurseryDiscountDescription}
        </Form.Row.Item>
      </Form.Row>
    )}
    {isOtherSelected && (
      <DiscountFormFields
        discountTypeOptions={discountTypeOptions}
        formValues={formValues}
        isEdit={isEdit}
        isPercentageSelected={isPercentageSelected}
      />
    )}
    <Form.FooterActions
      cancelLink={generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.DISCOUNTS', { childId })}
      isSubmitting={isLoading}
      submitLabel={i18n.t('global:Save')}
    />
  </Form>
)

const enhance = compose(reduxForm({ form: FORM_NAME }))

export default enhance(ChildDiscountsAddForm)
