import React, { PropsWithChildren } from 'react'

import { StyledTableTr } from './TableStyled'

interface TableTrProps {
  className?: string
  inactive?: boolean
  onClick?: () => {}
  style?: any
}

const TableTr: React.FC<PropsWithChildren<TableTrProps>> = ({
  children,
  className,
  inactive,
  onClick,
  style,
}) => (
  <StyledTableTr $inactive={inactive} className={className} style={style} onClick={onClick}>
    {children}
  </StyledTableTr>
)

export default TableTr
