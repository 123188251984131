import React, { useRef } from 'react'
import { useInView } from 'react-intersection-observer'

const ObserverLastUnreadMessage = ({ lastMessageRead, onView }) => {
  const elementRef = useRef()
  const [unreadMessageRef, lastMessageInView] = useInView({
    threshold: 0,
  })

  if (lastMessageInView) {
    onView()
  }

  return (
    <div ref={elementRef}>
      {!lastMessageRead && (
        <div ref={unreadMessageRef} />
      )}
    </div>
  )
}

export default ObserverLastUnreadMessage
