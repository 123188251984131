import _ from 'lodash'
import { createSelector } from 'reselect'

import { OBSERVATION_STATES } from 'services/legacy/frameworks/constants'

import i18n from 'translations'

const getMontessoriCategories = (state) => state.montessoriCategories

const getMontessoriCategoriesList = createSelector([getMontessoriCategories], (state) => state.list)

export const getMontessoriCategoriesListData = createSelector([getMontessoriCategoriesList], (state) => state.data)

export const getMontessoriCategoriesDropdown = createSelector([getMontessoriCategoriesListData], (data) => {
  let options = []

  _.each(data, (category) => {
    options = [
      ...options,
      {
        label: category.name,
        options: _.map(category.subcategories, (subcategory) => ({
          label: `${subcategory.name}`,
          options: _.map(subcategory.activities, (activity) => ({
            label: activity.name,
            value: activity.id,
          })),
        })),
      },
    ]
  })

  return options
})

const getObservationsSingleStateDataSelector = (state) => state.observations.single.data
const getChildObservationsSingleStateDataSelector = (state) => state.childObservations.single.data

export const getMontessoriCategoriesDropdownWithRecommended = createSelector([
  getMontessoriCategoriesDropdown,
  getObservationsSingleStateDataSelector,
  getChildObservationsSingleStateDataSelector,
], (
  options,
  observation,
  childObservation,
) => {
  const { montessoriActivityState } = childObservation || {}
  const { montessoriActivity } = observation || {}
  const { suggestedNextActivity } = montessoriActivity || {}
  const isMarkedAsSecure = OBSERVATION_STATES.SECURE.value === montessoriActivityState
  const showRecommended = (!isMarkedAsSecure && montessoriActivityState) || (isMarkedAsSecure && suggestedNextActivity)

  const recommendedOption = isMarkedAsSecure
    ? {
      label: suggestedNextActivity?.name,
      value: suggestedNextActivity?.id,
    } : {
      label: montessoriActivity?.name,
      value: montessoriActivity?.id,
    }

  return [
    ...(showRecommended ? [{
      label: i18n.t('module:Learning:Observations:ObservationNextSteps:recommendedActivity'),
      options: [recommendedOption],
    }] : []),
    ...options,
  ]
})
