import PlanView from './PlanView'
import PlanViewIcon from './PlanViewIcon'
import PlanViewContent from './PlanViewContent'
import PlanViewItem from './PlanViewItem'
import PlanViewHighlight from './PlanViewHighlight'
import PlanViewTitle from './PlanViewTitle'
import PlanViewSubTitle from './PlanViewSubTitle'
import PlanViewSubGroup from './PlanViewSubGroup'
import PlanViewSubGroupContent from './PlanViewSubGroupContent'
import PlanViewTag from './PlanViewTag'

PlanView.Icon = PlanViewIcon
PlanView.Content = PlanViewContent
PlanView.Item = PlanViewItem
PlanView.Highlight = PlanViewHighlight
PlanView.Title = PlanViewTitle
PlanView.SubTitle = PlanViewSubTitle
PlanView.SubGroup = PlanViewSubGroup
PlanView.SubGroupContent = PlanViewSubGroupContent
PlanView.Tag = PlanViewTag

export default PlanView
