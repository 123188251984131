export const CREATE_NURSERY = 'CREATE_NURSERY'
export const CREATE_NURSERY_SUCCESS = 'CREATE_NURSERY_SUCCESS'
export const CREATE_NURSERY_FAILED = 'CREATE_NURSERY_FAILED'

export const GET_NURSERY = 'GET_NURSERY'
export const GET_NURSERY_SUCCESS = 'GET_NURSERY_SUCCESS'
export const GET_NURSERY_FAILED = 'GET_NURSERY_FAILED'

export const GET_NURSERY_FLAGS = 'GET_NURSERY_FLAGS'
export const GET_NURSERY_FLAGS_SUCCESS = 'GET_NURSERY_FLAGS_SUCCESS'
export const GET_NURSERY_FLAGS_FAILED = 'GET_NURSERY_FLAGS_FAILED'

export const UPDATE_NURSERY = 'UPDATE_NURSERY'
export const UPDATE_NURSERY_SUCCESS = 'UPDATE_NURSERY_SUCCESS'
export const UPDATE_NURSERY_FAILED = 'UPDATE_NURSERY_FAILED'

export const UPDATE_NURSERY_FLAG = 'UPDATE_NURSERY_FLAG'
export const UPDATE_NURSERY_FLAG_SUCCESS = 'UPDATE_NURSERY_FLAG_SUCCESS'
export const UPDATE_NURSERY_FLAG_FAILED = 'UPDATE_NURSERY_FLAG_FAILED'

export const UPDATE_FINANCE_MIGRATION = 'UPDATE_FINANCE_MIGRATION'
export const UPDATE_FINANCE_MIGRATION_SUCCESS = 'UPDATE_FINANCE_MIGRATION_SUCCESS'
export const UPDATE_FINANCE_MIGRATION_FAILED = 'UPDATE_FINANCE_MIGRATION_FAILED'

export const UPDATE_CHILD_CODE_REFERENCE_SETTINGS = 'UPDATE_CHILD_CODE_REFERENCE_SETTINGS'
export const UPDATE_CHILD_CODE_REFERENCE_SETTINGS_SUCCESS = 'UPDATE_CHILD_CODE_REFERENCE_SETTINGS_SUCCESS'
export const UPDATE_CHILD_CODE_REFERENCE_SETTINGS_FAILED = 'UPDATE_CHILD_CODE_REFERENCE_SETTINGS_FAILED'

export const CLEAR_NURSERY = 'CLEAR_NURSERY'
