import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'

import { withChildObservationsService } from 'services/legacy/childObservations'
import { getFormattedFrameworkEvents } from 'services/legacy/frameworks/selectors'

import { DataPreview, Spinner } from 'components'

import Framework from '../Framework'
import NextSteps from '../NextSteps'

import { StyledMontessoriActivity } from './ChildObservationStyled'

const CHILD_OBSERVATION_GROUPS = {
  read: [
    'childObservation.frameworkEvents',
    'framework',
    'frameworkArea',
    'frameworkArea.groups',
    'frameworkGroup',
    'frameworkGroup.categories',
    'frameworkCategory',
    'frameworkCategory.items',
    'frameworkItem',
  ],
}

const CHILD_OBSERVATION_NEXT_STEP_GROUPS = {
  read: [
    'childObservation.nextMontessoriActivities',
    'childObservation.nextSteps',
    'nextMontessoriActivity',
    'nextMontessoriActivity.montessoriActivity',
    'montessoriActivity',
    'nextStep',
    'nextStep.frameworkAreas',
    'frameworkArea',
  ],
}

const ChildObservation = ({
  actions,
  canEditObservation,
  childObservationId,
  childObservationsActions,
  children,
  customMontessoriActivity,
  frameworksList,
  montessoriActivity,
  pageType,
  progressLevels,
}) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [frameworkDetails, setFrameworkDetails] = useState()
  const [nextStepDetails, setNextStepDetails] = useState()
  const [childObservationData, setChildObservationData] = useState()

  const getChildObservationDetailsSuccess = (response) => {
    const { data } = response
    const { frameworkEvents } = data
    const newDetails = getFormattedFrameworkEvents(frameworkEvents, frameworksList)

    setChildObservationData(data)
    setFrameworkDetails(newDetails)
  }

  const getNextStepDetailsSuccess = ({ data }) => {
    setNextStepDetails(data)
    setIsProcessing(false)
  }

  const getChildObservationDetails = () => {
    if (!frameworksList || isProcessing) {
      return
    }

    setIsProcessing(true)

    childObservationsActions.get({
      onSuccess: getChildObservationDetailsSuccess,
      onlyData: true,
      params: [childObservationId, {
        groups: CHILD_OBSERVATION_GROUPS,
      }],
    })

    childObservationsActions.get({
      onSuccess: getNextStepDetailsSuccess,
      onlyData: true,
      params: [childObservationId, {
        groups: CHILD_OBSERVATION_NEXT_STEP_GROUPS,
      }],
    })
  }

  useEffect(getChildObservationDetails, [childObservationId])

  const { montessoriActivityState } = childObservationData || {}

  const renderContent = () => {
    if (isProcessing) {
      return (
        <Spinner />
      )
    }

    return (
      <React.Fragment>
        <Framework
          actions={actions}
          details={frameworkDetails}
          progressLevels={progressLevels}
        />
        <NextSteps
          canEditObservation={canEditObservation}
          childObservationId={childObservationId}
          nextStepDetails={nextStepDetails}
          pageType={pageType}
        />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {(montessoriActivity || customMontessoriActivity) && (
        <DataPreview
          content={(
            <React.Fragment>
              {montessoriActivity ? montessoriActivity.name : customMontessoriActivity}
              {montessoriActivityState && (
                <React.Fragment>
                  &nbsp;(
                  <StyledMontessoriActivity
                    montessoriActivity={montessoriActivityState}
                  >
                    {montessoriActivityState}
                  </StyledMontessoriActivity>
                  )
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          title="Montessori Activity"
          withBackground
        />
      )}
      {children}
      {renderContent()}
    </React.Fragment>
  )
}

const enhance = compose(
  withChildObservationsService,
)

export default enhance(ChildObservation)
