import invoiceConstants from 'services/legacy/invoices/constants'

import i18n from 'translations'

export const getColspan = (preview, isGroupedInvoice) => {
  let baseColspan = 6

  if (preview) {
    baseColspan -= 1
  }

  if (isGroupedInvoice) {
    baseColspan -= 2
  }

  return baseColspan
}

export const getCarriedBroughtForwardDetail = ({
  amountDueDetails,
  balance = 0,
  creditCarried = 0,
  status = invoiceConstants.STATUS_TYPES.DRAFT,
  total,
}) => {
  if (0 === creditCarried && status !== invoiceConstants.STATUS_TYPES.DRAFT) {
    return null
  }

  if (0 === balance && status === invoiceConstants.STATUS_TYPES.DRAFT) {
    return null
  }

  if (0 < balance && 0 > total && status === invoiceConstants.STATUS_TYPES.DRAFT) {
    return null
  }

  const value = status === invoiceConstants.STATUS_TYPES.DRAFT ? balance : creditCarried

  if (0 === amountDueDetails.value && 0 > value) {
    return null
  }

  const getDisplayValue = () => {
    if (status === invoiceConstants.STATUS_TYPES.DRAFT) {
      return total < balance ? total : Math.abs(balance)
    }

    return 0 < creditCarried ? creditCarried : creditCarried * -1
  }

  const displayLabel = 0 < value
    ? i18n.t('components:Finance:InvoiceTable:creditBroughtForwardLabel')
    : i18n.t('components:Finance:InvoiceTable:debitBroughtForwardLabel')

  const displayValue = getDisplayValue()

  return {
    label: displayLabel,
    value: displayValue,
  }
}

export const getAmountDueDetail = ({
  amountDue,
  balance = 0,
  childBalance = 0,
  status = invoiceConstants.STATUS_TYPES.DRAFT,
  total,
}) => {
  let valueAfterInvoice = childBalance
  let amountDueValue = amountDue

  if (status === invoiceConstants.STATUS_TYPES.DRAFT) {
    valueAfterInvoice = balance - total

    amountDueValue = 0 < valueAfterInvoice ? 0 : Math.abs(valueAfterInvoice)
  }

  return {
    value: amountDueValue,
    valueAfterInvoice,
  }
}
