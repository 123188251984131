import _ from 'lodash'

import { addDiaryRecord, updateDiaryRecord } from 'services/legacy/dailyDiary/helpers'

export const findDiarySleepRecord = (state, data) => {
  const childRecord = _.find(state.data, ({ childRegister }) => (
    childRegister.child.id === data.child.id
  ))

  const { comments, didNotSleep, endTime, id, recordedAt, startTime } = data

  return {
    childRecord,
    newDiaryRecord: {
      didNotSleep,
      endTime,
      id,
      record: {
        comments,
        recordedAt,
      },
      startTime,
    },
  }
}

export const addDiarySleepRecord = (state, payload) => addDiaryRecord(state, payload, findDiarySleepRecord)

export const updateDiarySleepRecord = (state, data) => updateDiaryRecord(state, data, findDiarySleepRecord)

export const removeDiarySleepRecord = (state, id) => {
  const newData = _.map(state.data, (item) => ({
    ...item,
    diaryRecords: _.filter(item.diaryRecords, (diaryRecord) => diaryRecord.id !== id),
  }))

  const updatedData = _.filter(newData, ({ childRegister, diaryRecords }) => {
    if (
      childRegister.child.information.daytimeSleepingBehavior
      && childRegister.child.information.daytimeSleepingBehavior.includes('Yes')
    ) {
      return true
    }

    return diaryRecords?.length
  })

  return {
    ...state,
    // Remove child if do not have diaryRecords and except toilet trained
    data: updatedData,
    meta: {
      ...state.meta,
      total_results: updatedData.length, // eslint-disable-line
    },
  }
}
