import moment from 'moment'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { hoursAndMinutesToMilliseconds } from 'utils/date'

export const getExceptionalUnavailableTimesPayload = (fields) => {
  if (!fields) {
    return null
  }

  const {
    date,
    endTime,
    nurseryClass,
    startTime,
  } = fields

  return {
    date: moment(date).format(DEFAULT_DATE_FORMAT),
    endTime: hoursAndMinutesToMilliseconds(endTime),
    nurseryClass: nurseryClass?.value ? { id: nurseryClass.value } : undefined,
    startTime: hoursAndMinutesToMilliseconds(startTime),
  }
}
