import _ from 'lodash'

import { mergeData } from 'utils/data'

import {
  ADD_TOILET_TRAINING_TO_LIST,
  CLEAR_DAILY_DIARY_TOILET_TRAINING,
  GET_DAILY_DIARY_TOILET_TRAINING,
  GET_DAILY_DIARY_TOILET_TRAINING_FAILED,
  GET_DAILY_DIARY_TOILET_TRAINING_SUCCESS,
} from './constants'
import { REMOVE_TOILET_TRAINING_SUCCESS, UPDATE_TOILET_TRAINING_SUCCESS } from '../single/constants'

import {
  addDiaryToiletTrainingRecord,
  removeDiaryToiletTrainingRecord,
  updateDiaryToiletTrainingRecord,
} from './helpers'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: {},
}

export default (state = initialState, { error, id, mergeResult, payload, type }) => {
  switch (type) {
    case GET_DAILY_DIARY_TOILET_TRAINING:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_DAILY_DIARY_TOILET_TRAINING_SUCCESS:
      return {
        ...state,
        data: mergeData(state.data, payload.data, mergeResult),
        isFetching: false,
        meta: payload.meta,
      }
    case GET_DAILY_DIARY_TOILET_TRAINING_FAILED:
      return { ...state, error, isFetching: false }
    case ADD_TOILET_TRAINING_TO_LIST: {
      let result = { ...state }

      _.each(payload, (record) => {
        result = addDiaryToiletTrainingRecord(result, record)
      })

      return result
    }
    case UPDATE_TOILET_TRAINING_SUCCESS:
      return updateDiaryToiletTrainingRecord(state, payload)
    case REMOVE_TOILET_TRAINING_SUCCESS:
      return removeDiaryToiletTrainingRecord(state, id)
    case CLEAR_DAILY_DIARY_TOILET_TRAINING:
      return initialState
    default:
      return state
  }
}
