import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withDepositsService } from 'services/legacy/deposits'
import { withRouter } from 'services/router'

import i18n from 'translations'

import ExportModalView from './DepositsExportModalView'
import { STATUS_OPTIONS, STATUS_TYPES } from './constants'

const FORM_INITIAL_VALUES = {
  dateRange: [
    moment().startOf('month'),
    moment().endOf('month'),
  ],
  status: _.find(STATUS_OPTIONS, ({ value }) => value === STATUS_TYPES.ALL),
}

class DepositsExportModalContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isExporting: false,
    }
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSubmitSuccess = (email) => {
    const { modalActions, modalConsts } = this.props

    this.setState({ isExporting: false })
    this.handleCloseClick()

    modalActions.show(modalConsts.TYPES.ALERT, {
      text: i18n.t('module:Modals:ExportReportModal:alert', { email }),
    })
  }

  handleSubmit = (formValues) => {
    const { depositsActions, depositsSelectors } = this.props
    const { dateRange, membership, status } = formValues
    const [after, before] = dateRange

    this.setState({ isExporting: true })

    const criteria = depositsSelectors.getExportCriteria({
      date: {
        after,
        before,
      },
      membership: membership?.value,
      statisticsType: status === STATUS_TYPES.ALL ? undefined : status?.value,
    })

    depositsActions.exportDeposits({
      onSuccess: () => this.handleSubmitSuccess(membership?.email),
      onlyData: true,
      params: [{ criteria }],
    })
  }

  handleCancelClick = () => {
    const { onCancelClick } = this.props

    if (onCancelClick) {
      onCancelClick()
    }

    this.handleCloseClick()
  }

  render() {
    const { moduleType } = this.props
    const { isExporting } = this.state

    return (
      <ExportModalView
        initialValues={FORM_INITIAL_VALUES}
        isSubmitting={isExporting}
        moduleType={moduleType}
        onCancelClick={this.handleCancelClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withDepositsService,
  connect(null),
)

export default enhance(DepositsExportModalContainer)
