import { getQueryStringV2 } from 'utils/api'

import ServiceBase from 'services/ServiceBase'

class IntegrationFeesApiClient extends ServiceBase {
  getIntegrationFees = (params) => this.request({
    path: `/v2/integrations/integration_fees?${getQueryStringV2(params)}`,
  })

  get = (childId, noteId) => this.request({
    path: `/children/${childId}/notes/${noteId}`,
  })

  create = (body) => this.request({
    body,
    method: 'POST',
    path: '/v2/integrations/integration_fees',
  })

  update = (feeId, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/integrations/integration_fees/${feeId}`,
  })

  remove = (feeId) => this.request({
    method: 'DELETE',
    path: `/v2/integrations/integration_fees/${feeId}`,
  })
}

export default IntegrationFeesApiClient
