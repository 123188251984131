import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import {
  CLEAR_LIST_CHILD_OBSERVATIONS,
  LIST_CHILD_OBSERVATIONS,
  LIST_CHILD_OBSERVATIONS_FAILED,
  LIST_CHILD_OBSERVATIONS_SUCCESS,
} from './constants'

const initialState = getBasicListInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_LIST_CHILD_OBSERVATIONS,
    failed: LIST_CHILD_OBSERVATIONS_FAILED,
    init: LIST_CHILD_OBSERVATIONS,
    success: LIST_CHILD_OBSERVATIONS_SUCCESS,
  }, initialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
