import _ from 'lodash'

import i18n from 'translations'

import Reports from 'module/Reports'
import KeyPersonsReport from 'module/Reports/KeyPersonsReport'
import DietaryReport from 'module/Reports/DietaryReport'
import PhotoPermissionsReport from 'module/Reports/PhotoPermissionsReport'
import ChildrenSignedUp from './ChildrenSignedUp'
import ChildrenStarting from './ChildrenStarting'
import ChildrenLeaving from './ChildrenLeaving'
import MonthlyFteChange from './MonthlyFteChange'
import NurseryMilk from './NurseryMilk'

export const ROUTE_NAMES = {
  'REPORTS.CHILDREN_LEAVING': 'REPORTS.CHILDREN_LEAVING',
  'REPORTS.CHILDREN_SIGNED_UP': 'REPORTS.CHILDREN_SIGNED_UP',
  'REPORTS.CHILDREN_STARTING': 'REPORTS.CHILDREN_STARTING',
  'REPORTS.DASHBOARD': 'REPORTS.DASHBOARD',
  'REPORTS.DIETARY_LIST': 'REPORTS.DIETARY_LIST',
  'REPORTS.KEY_PERSON_LIST': 'REPORTS.KEY_PERSON_LIST',
  'REPORTS.MONTHLY_FTE_CHANGE': 'REPORTS.MONTHLY_FTE_CHANGE',
  'REPORTS.NURSERY_MILK': 'REPORTS.NURSERY_MILK',
  'REPORTS.PHOTO_PERMISSIONS_LIST': 'REPORTS.PHOTO_PERMISSIONS_LIST',
}

const ReportsRouting = {
  childRoutes: [
    {
      component: KeyPersonsReport,
      name: ROUTE_NAMES['REPORTS.KEY_PERSON_LIST'],
      path: 'key-persons',
      title: 'Key Person lists',
    },
    {
      component: DietaryReport,
      name: ROUTE_NAMES['REPORTS.DIETARY_LIST'],
      path: 'dietary',
      title: _.upperFirst(i18n.t('module:Reports:Dietary:title')),
    },
    {
      component: PhotoPermissionsReport,
      name: ROUTE_NAMES['REPORTS.PHOTO_PERMISSIONS_LIST'],
      path: 'photo-permissions',
      title: i18n.t(
        'module:Reports:reportTitle',
        { type: _.upperFirst(i18n.t('module:Reports:PhotoPermissions:title')) },
      ),
    },
    {
      component: ChildrenSignedUp,
      name: ROUTE_NAMES['REPORTS.CHILDREN_SIGNED_UP'],
      path: 'children-signed-up',
      title: _.upperFirst(i18n.t('module:Reports:ChildrenSignedUp:title')),
    },
    {
      component: ChildrenStarting,
      name: ROUTE_NAMES['REPORTS.CHILDREN_STARTING'],
      path: 'children-starting',
      title: _.upperFirst(i18n.t('module:Reports:ChildrenStarting:title')),
    },
    {
      component: ChildrenLeaving,
      name: ROUTE_NAMES['REPORTS.CHILDREN_LEAVING'],
      path: 'children-leaving',
      title: _.upperFirst(i18n.t('module:Reports:ChildrenLeaving:title')),
    },
    {
      component: MonthlyFteChange,
      name: ROUTE_NAMES['REPORTS.MONTHLY_FTE_CHANGE'],
      path: 'monthly-fte-change',
      title: _.upperFirst(i18n.t('module:Reports:MonthlyFteChange:title')),
    },
    {
      component: NurseryMilk,
      name: ROUTE_NAMES['REPORTS.NURSERY_MILK'],
      path: 'nursery-milk',
      title: i18n.t('module:Reports:NurseryMilk:title'),
    },
  ],
  indexRoute: {
    component: Reports,
  },
  name: ROUTE_NAMES['REPORTS.DASHBOARD'],
  path: 'reports',
  title: i18n.t('module:Reports:title'),
}

ReportsRouting.ROUTE_NAMES = ROUTE_NAMES

export default ReportsRouting
