import React from 'react'

import { DateString } from 'components'

import { ACTIVITIES_DESCRIPTION } from './constants'

import RegisterActivityName from '../RegisterActivityName'

const RegisterActivity = ({ actionPerformed, at, staff }) => {
  const { fullName } = staff

  const activity = ACTIVITIES_DESCRIPTION[actionPerformed]

  return (
    <span>
      {`${fullName} ${activity.label} `}
      <RegisterActivityName color={activity.color}>
        {activity.name}
      </RegisterActivityName>
      {at && (
        <span>
          {' '}
          on
          {' '}
          <DateString date={at} />
        </span>
      )}
    </span>
  )
}

export default RegisterActivity
