import * as AttendanceWidget from './AttendanceWidget'
import * as BirthdaysWidget from './BirthdaysWidget'
import * as ChildrenSearchWidget from './ChildrenSearchWidget'
import * as DraftInvoiceWidget from './DraftInvoiceWidget'
import * as MonthlyIncomeWidget from './MonthlyIncomeWidget'
import * as NextStepsWidget from './NextStepsWidget'
import * as ObservationDiaryButtonsWidget from './ObservationDiaryButtonsWidget'
import * as ObservationsWidget from './ObservationsWidget'
import * as TimeWidget from './TimeWidget'
import * as TodayAllergiesWidget from './TodayAllergiesWidget'
import * as TopOutstandingAccountsWidget from './TopOutstandingAccountsWidget'

/* eslint sort-keys-fix/sort-keys-fix:0 */
/* eslint sort-keys:0 */
export default {
  TimeWidget: TimeWidget.WIDGET_NAME,
  AttendanceWidget: AttendanceWidget.WIDGET_NAME,
  BirthdaysWidget: BirthdaysWidget.WIDGET_NAME,
  ChildrenSearchWidget: ChildrenSearchWidget.WIDGET_NAME,
  DraftInvoiceWidget: DraftInvoiceWidget.WIDGET_NAME,
  MonthlyIncomeWidget: MonthlyIncomeWidget.WIDGET_NAME,
  NextStepsWidget: NextStepsWidget.WIDGET_NAME,
  ObservationDiaryButtonsWidget: ObservationDiaryButtonsWidget.WIDGET_NAME,
  ObservationsWidget: ObservationsWidget.WIDGET_NAME,
  TodayAllergiesWidget: TodayAllergiesWidget.WIDGET_NAME,
  TopOutstandingAccountsWidget: TopOutstandingAccountsWidget.WIDGET_NAME,
}
