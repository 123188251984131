import _ from 'lodash'

import { addDiaryRecord, removeDiaryRecord, updateDiaryRecord } from 'services/legacy/dailyDiary/helpers'

export const findDiaryToiletTrainingRecord = (state, data) => {
  const childRecord = _.find(state.data, ({ childRegister }) => childRegister?.child?.id === data.child.id)
  const { accident, comments, id, recordedAt, type } = data

  return {
    childRecord,
    newDiaryRecord: {
      accident,
      id,
      record: {
        comments,
        recordedAt,
      },
      type,
    },
  }
}

export const addDiaryToiletTrainingRecord = (state, payload) => addDiaryRecord(
  state,
  payload,
  findDiaryToiletTrainingRecord,
)

export const updateDiaryToiletTrainingRecord = (state, data) => updateDiaryRecord(
  state,
  data,
  findDiaryToiletTrainingRecord,
)

export const removeDiaryToiletTrainingRecord = (state, id) => removeDiaryRecord(
  state,
  id,
)

export const mergeDailyDiaryRecords = (primaryList, secondaryList) => {
  const mergedRecords = [
    ..._.filter((primaryList || []), (item) => item.diaryRecords.length),
    ...(secondaryList || []),
  ]

  const groupedRecords = _.values(
    _.flow(
      (items) => _.groupBy(items, (item) => item.childRegister.child.id),
      (items) => items,
    )(mergedRecords),
  )

  return _.map(
    groupedRecords,
    (item) => {
      const diaryRecords = []

      _.each(
        item,
        (innerItems) => _.each(
          innerItems.diaryRecords,
          (diaryRecord) => diaryRecords.push(diaryRecord),
        ),
      )

      return {
        childRegister: item[0].childRegister,
        diaryRecords: _.sortBy(diaryRecords, (diaryRecord) => diaryRecord.record.recordedAt),
      }
    },
  )
}
