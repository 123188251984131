import React from 'react'

import InlineEditableField from 'module/Management/components/InlineEditableField'

import i18n from 'translations'

import EnableLikesAndCommentsForm, { ENABLE_LIKES_AND_COMMENTS_FORM } from './EnableLikesAndCommentsForm'

interface EnableLikesAndCommentsProps {
  onSubmit: (fields: any) => (successCallback: any, failedCallback?: () => void) => void
  value: boolean
}

const EnableLikesAndComments: React.FC<EnableLikesAndCommentsProps> = ({ onSubmit, value }) => {
  const initialValues = { enabled: value }

  return (
    <InlineEditableField
      editableComponent={(callback) => (
        <EnableLikesAndCommentsForm
          initialValues={initialValues}
          onSubmit={callback}
        />
      )}
      formName={ENABLE_LIKES_AND_COMMENTS_FORM}
      subtitle={i18n.t('module:Management:General:LikesAndComments:EnableLikesAndComments:subtitle')}
      title={i18n.t('module:Management:General:LikesAndComments:EnableLikesAndComments:title')}
      value={value}
      inline
      onSubmit={onSubmit}
    />
  )
}

export default EnableLikesAndComments
