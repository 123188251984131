import React from 'react'

import { Typography } from 'components'

import { StyledGroupContainer } from './SignatureListStyled'

const SignatureListGroup = ({ children, title }) => (
  <StyledGroupContainer>
    <Typography fontSize={18} padding="0 0 15px" bold>{title}</Typography>
    {children}
  </StyledGroupContainer>
)

export default SignatureListGroup
