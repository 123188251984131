import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change } from 'redux-form'

import { UPLOAD_DIRECTORY } from 'services/legacy/upload/constants'
import { DAYS_OPTIONS } from 'constants/sessionDays'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withUploadService } from 'services/legacy/upload'
import { withRouter } from 'services/router'

import * as managementNurseryEditHelpers from 'module/Management/ManagementNursery/ManagementNurseryEdit/helpers'
import {
  MANAGEMENT_NURSERY_EDIT_FORM,
} from 'module/Management/ManagementNursery/ManagementNurseryEdit/components/ManagementNurseryEditForm'

import SettingsNurseryItemEditView from './SettingsNurseryItemEditView'

const groups = {
  read: [
    'nursery.settings',
    'nursery.organization',
    'nurserySettings',
    'nurserySettings.localeDetails',
    'organization',
  ],
}

class SettingsNurseryItemEditContainer extends Component {
  componentDidMount() {
    const { nurseriesActions, params: { nurseryId } } = this.props

    nurseriesActions.get(nurseryId, {
      params: { groups },
    })
  }

  componentWillUnmount() {
    const { nurseriesActions } = this.props

    nurseriesActions.clearSingle()
  }

  getRedirectLink = () => {
    const { params: { nurseryId } } = this.props

    return generateRoute('SETTINGS.NURSERY.ITEM', { nurseryId })
  }

  handleSubmitSuccess = () => {
    const { navigate } = this.props

    navigate(this.getRedirectLink())
  }

  handleSubmit = async (fields) => {
    const { nurseriesActions, params, uploadActions } = this.props

    const logo = fields?.logo?.file && (
      await uploadActions.uploadFile(fields.logo.file, fields.logo.file.name, UPLOAD_DIRECTORY.PUBLIC_IMAGES)
    )
    const payload = managementNurseryEditHelpers.getPayload(fields, logo)
    const apiParams = { groups }

    nurseriesActions.update(params.nurseryId, {
      onSuccess: this.handleSubmitSuccess,
      params: apiParams,
      payload,
    })
  }

  handleApplyAll = (startTime = null, endTime = null, fields) => {
    const { changeFieldValue } = this.props

    fields.forEach((item) => {
      changeFieldValue(`${item}.startTime`, startTime)
      changeFieldValue(`${item}.endTime`, endTime)
    })
  }

  render() {
    const { errorMessages, isFetching, nurseriesSingleState } = this.props
    const nursery = nurseriesSingleState.data
    const { nurserySettings, organization } = nursery || {}

    const initialValues = managementNurseryEditHelpers.getInitialValues(nursery, DAYS_OPTIONS)
    const isLoading = isFetching || !nursery || !initialValues
    const isFormLoading = nurseriesSingleState.isSubmitting
    const cancelLink = this.getRedirectLink()

    return (
      <SettingsNurseryItemEditView
        cancelLink={cancelLink}
        daysOptions={DAYS_OPTIONS}
        errorMessages={errorMessages}
        financeLocked={nurserySettings?.financeLocked}
        initialValues={initialValues}
        isFormLoading={isFormLoading}
        isLoading={isLoading}
        isOrganizationNursery={!!organization}
        nursery={nursery}
        onApplyAll={this.handleApplyAll}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, { appSelectors, nurseriesSingleState }) => ({
  errorMessages: appSelectors.getErrorMessages(nurseriesSingleState),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState),
})

const mapDispatch = {
  changeFieldValue: (field, value) => change(MANAGEMENT_NURSERY_EDIT_FORM, field, value),
}

const enhance = compose(
  withAppService,
  withNurseriesService,
  withRouter,
  withUploadService,
  connect(mapState, mapDispatch),
)

export default enhance(SettingsNurseryItemEditContainer)
