import React from 'react'

import { INJURIES_STATUS } from 'services/legacy/injuries/constants'

import { Button, StickyFooter } from 'components'

import i18n from 'translations'

import { StyledCustomStickyFooterDesign } from './InjuryStickyFooterStyled'

const InjuryStickyFooter = ({
  authAccessMap,
  injuryPreview,
  isGrantedEdit,
  isGrantedSaveAndRequestForApproval,
  isGrantedSaveAsDraft,
  isHomeAccidents,
  isSubmittingAsApproved,
  isSubmittingAsDraft,
  onCancelClick,
  onSubmitAsApproved,
  onSubmitAsDraft,
  onSubmitAsPendingApproval,
}) => {
  if (!isGrantedEdit) {
    return null
  }

  return (
    <StyledCustomStickyFooterDesign>
      <StickyFooter>
        <Button
          hierarchy="tertiary"
          label={i18n.t('global:Cancel')}
          negativeMargins
          onClick={onCancelClick}
        />
        <StickyFooter.Flex />
        {isGrantedSaveAsDraft && (
          <Button
            disabled={isSubmittingAsApproved || isSubmittingAsDraft}
            hierarchy="secondary"
            isLoading={isSubmittingAsDraft}
            label={injuryPreview.status === INJURIES_STATUS.DRAFT.value
              ? i18n.t('module:Injury:Add:updateDraftButtonLabel')
              : i18n.t('module:Injury:Add:saveAsDraftButtonLabel')}
            negativeMargins
            onClick={onSubmitAsDraft}
          />
        )}
        {isHomeAccidents ? (
          <Button
            disabled={isSubmittingAsDraft}
            isLoading={isSubmittingAsApproved}
            label={(
              injuryPreview.status === INJURIES_STATUS.APPROVED_AND_SIGNED.value
                || injuryPreview.status === INJURIES_STATUS.SIGNATURES_NEEDED.value
            )
              ? i18n.t('global:Update')
              : i18n.t('global:Save')}
            negativeMargins
            onClick={onSubmitAsApproved}
          />
        ) : (
          <React.Fragment>
            {!authAccessMap.section.hasOnlySeniorTeacherOrTeacherAccess && (
              <Button
                disabled={isSubmittingAsDraft}
                isLoading={isSubmittingAsApproved}
                label={(
                  injuryPreview.status === INJURIES_STATUS.APPROVED_AND_SIGNED.value
                    || injuryPreview.status === INJURIES_STATUS.SIGNATURES_NEEDED.value
                )
                  ? i18n.t('global:Update')
                  : i18n.t('module:Injury:Add:saveAndApproveButtonLabel')}
                negativeMargins
                onClick={onSubmitAsApproved}
              />
            )}
            {isGrantedSaveAndRequestForApproval && (
              <Button
                isLoading={isSubmittingAsApproved}
                label={injuryPreview.status === INJURIES_STATUS.PENDING_APPROVAL.value
                  ? i18n.t('global:Update')
                  : i18n.t('module:Injury:Add:saveAndRequestButtonLabel')}
                negativeMargins
                onClick={onSubmitAsPendingApproval}
              />
            )}
          </React.Fragment>
        )}
      </StickyFooter>
    </StyledCustomStickyFooterDesign>
  )
}

export default InjuryStickyFooter
