import styled from 'styled-components'

import colors from 'constants/colors'
import layout from 'constants/layout'

export const StyledGrid = styled.div`
  margin-bottom: 25px;
`

export const StyledInfo = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  padding: 10px;
  background-color: ${colors.warningBanner.background};
  margin: -10px;
  margin-bottom: 10px;
  justify-content: center;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    margin-bottom: 0;
  }
`

export const StyledFooterBottom = styled.div`
  display: flex;

  & > div > div {
    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  @media(max-width: ${layout.mobileWidthResolution}px) {
    & > div,
    & > div > div {
      flex-direction: column;
      display: grid;
      grid-row-gap: 8px;
    }

    & > div > div {
      margin-right: 0;
    }
  }
`

export const StyledStickyFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
