import React from 'react'

import { Button, FooterActions, ModalBox, Space, Typography } from 'components'

import i18n from 'translations'

interface SessionTimeoutModalViewProps {
  onCloseClick?: () => void
  onLogoutClick?: () => void
  onStayLoggedInClick?: () => void
  remainingTime?: number
}

const SessionTimeoutModalView: React.FC<SessionTimeoutModalViewProps> = ({
  onCloseClick,
  onLogoutClick,
  onStayLoggedInClick,
  remainingTime,
}) => (
  <ModalBox
    maxWidth={350}
    title={i18n.t('module:Modals:SessionTimeout:title')}
    autoHeight
    onCloseClick={onCloseClick}
  >
    {i18n.t('module:Modals:SessionTimeout:timeoutMessage')}
    <Space space="10px" />
    <Typography variant="h4">
      {remainingTime}
      {' '}
      {i18n.t('module:Modals:SessionTimeout:remainingTimeSuffix')}
    </Typography>
    <Space space="20px" />
    <FooterActions spaceBetween>
      <FooterActions.Item>
        <Button
          hierarchy="tertiary"
          label={i18n.t('module:Modals:SessionTimeout:logoutButtonLabel')}
          negativeMargins
          onClick={onLogoutClick}
        />
      </FooterActions.Item>
      <FooterActions.Item>
        <Button
          label={i18n.t('module:Modals:SessionTimeout:stayLoggedInButtonLabel')}
          negativeMargins
          onClick={onStayLoggedInClick}
        />
      </FooterActions.Item>
    </FooterActions>
  </ModalBox>
)

export default SessionTimeoutModalView
