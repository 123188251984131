import React from 'react'
import { compose } from 'recompose'
import { Field, reduxForm } from 'redux-form'

import { isRequired, isValidBigInt } from 'utils/fieldValidation'

import { Form } from 'components'

import i18n from 'translations'

import { NumberSettingsFormRow } from './components'

const InvoiceNumbersSettingForm = ({
  cancelButtonLink,
  handleSubmit,
  isInvoiceNumberReadonly,
  isLoading,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <NumberSettingsFormRow
      component={(
        <Field
          component={Form.TextField}
          disabled={isInvoiceNumberReadonly}
          name="invoiceNumberPrefix"
          validate={isRequired}
        />
      )}
      label="Invoice numbers prefix"
      tooltip="All invoices generated will show the specified prefix."
    />
    <NumberSettingsFormRow
      component={(
        <Field
          component={Form.TextField}
          disabled={isInvoiceNumberReadonly}
          name="invoiceNumber"
          type="number"
          validate={[isRequired, isValidBigInt]}
        />
      )}
      label="Next invoice numbers from"
      tooltip="The next invoice generated will start from the number specified."
    />
    <Form.Divider />
    <NumberSettingsFormRow
      component={(
        <Field
          component={Form.TextField}
          disabled={isInvoiceNumberReadonly}
          name="receiptNumberPrefix"
          validate={isRequired}
        />
      )}
      label="Receipt numbers prefix"
      tooltip="All receipts generated will show the specified prefix."
    />
    <NumberSettingsFormRow
      component={(
        <Field
          component={Form.TextField}
          disabled={isInvoiceNumberReadonly}
          name="receiptNumber"
          type="number"
          validate={isRequired}
        />
      )}
      label="Next receipt numbers from"
      tooltip="The next receipts generated will start from the number specified."
    />
    <Form.Divider />
    <NumberSettingsFormRow
      component={(
        <Field
          component={Form.TextField}
          disabled={isInvoiceNumberReadonly}
          name="creditNoteNumberPrefix"
          validate={isRequired}
        />
      )}
      label="Credit note numbers prefix"
      tooltip="All credit notes issued will show the specified prefix."
    />
    <NumberSettingsFormRow
      component={(
        <Field
          component={Form.TextField}
          disabled={isInvoiceNumberReadonly}
          name="creditNoteNumber"
          type="number"
          validate={isRequired}
        />
      )}
      label="Next credit note numbers from"
      tooltip="The next credit note issued will start from the number specified."
    />
    <Form.FooterActions
      cancelLink={cancelButtonLink}
      isSubmitting={isLoading}
      submitDisabled={isInvoiceNumberReadonly}
      submitLabel={i18n.t('global:Save')}
    />
  </Form>
)

const enhance = compose(reduxForm({}))

export default enhance(InvoiceNumbersSettingForm)
