import styled from 'styled-components'

import colors from 'constants/colors'

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
`

export const StyledLeftContainer = styled.div`
  display: flex;
`

export const StyledIconContainer = styled.div`
  display: flex;
`

export const StyledTopTipCopyContainer = styled.div`
  color: ${colors.gold};
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  font-weight: 600;
`

export const StyledRightContainer = styled.div`
  display: flex;
  padding-left: 14px;
`
