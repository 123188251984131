import styled from 'styled-components'

import { StyledScrollbar } from 'styled/abstract'

export const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 15px;
  
  ${({ $reverted }) => $reverted && `
    grid-template-columns: auto 1fr;
  `}

  ${({ $small }) => $small && `
    align-items: center;
    grid-gap: 10px;
  `}
`

export const StyledPopover = styled.div`
  width: 240px;
`

export const StyledHeader = styled.div`
  display: grid;
  align-items: center;
  padding: 10px 10px 10px 20px;
`

export const StyledContent = styled(StyledScrollbar)`
  max-height: 240px;
  min-height: 30px;
  padding: 0 10px;
  margin: 0 10px;
  overflow-y: auto;
`

export const StyledItem = styled.div`
  margin-bottom: 15px;
`

export const StyledIconWrapper = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  
  ${({ $small }) => $small && `
    width: 15px;
    height: 15px;
  `}
`

export const StyledIconNotFilled = styled.svg`
  position: absolute;
  z-index: 1;
  
  ${({ $small }) => $small && `
    width: 15px;
    height: 15px;
  `}
`

export const StyledIconFilled = styled.svg`
  position: absolute;
  z-index: 2;
  animation: 1s like-heart-animation forwards;

  ${({ $small }) => $small && `
    width: 15px;
    height: 15px;
  `}
  
  ${({ $hidden }) => $hidden && `
    display: none;
  `}
  
  ${({ $hideAnimation }) => $hideAnimation && `
    opacity: 0 !important;
  `}
  
  @keyframes like-heart-animation {
    0% {
      opacity: 0;
      transform: scale(0);
    }

    15% {
      opacity: .9;
      transform: scale(1.2);
    }

    30% {
      transform: scale(.95);
    }

    45%,
    80% {
      opacity: .9;
      transform: scale(1);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
`
