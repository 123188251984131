import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired, isSortCodeValid, isValidBankAccountNumber } from 'utils/fieldValidation'

import { Form, Section } from 'components'

import i18n from 'translations'

const InvoiceBankSettingForm = ({
  cancelLink,
  handleSubmit,
  isLoading,
  isUkCountry,
  onSubmit,
  title = i18n.t('components:Settings:InvoiceBankSettings:defaultTitle'),
}) => (
  <Section title={title}>
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Row label={i18n.t('components:Settings:InvoiceBankSettings:accountName')} required>
        <Form.Row.FlexItem flex="0 0 200px">
          <Field
            component={Form.TextField}
            name="bankAccountName"
            placeholder={i18n.t('components:Settings:InvoiceBankSettings:accountName')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      {isUkCountry && (
        <Form.Row label={i18n.t('components:Settings:InvoiceBankSettings:sortCode')}>
          <Form.Row.FlexItem flex="0 0 100px">
            <Field
              component={Form.TextField}
              name="sortCode"
              placeholder={i18n.t('components:Settings:InvoiceBankSettings:sortCode')}
            />
          </Form.Row.FlexItem>
        </Form.Row>
      )}
      <Form.Row label={i18n.t('components:Settings:InvoiceBankSettings:accountNumber')} required>
        <Form.Row.FlexItem flex="0 0 150px">
          <Field
            component={Form.TextField}
            name="bankAccountNumber"
            placeholder={i18n.t('components:Settings:InvoiceBankSettings:accountNumber')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      {!isUkCountry && (
        <Form.Row label={i18n.t('components:Settings:InvoiceBankSettings:notes')}>
          <Form.Row.FlexItem flex="0 0 200px">
            <Field
              component={Form.TextAreaField}
              name="bankAccountNotes"
              placeholder={i18n.t('components:Settings:InvoiceBankSettings:notes')}
            />
          </Form.Row.FlexItem>
        </Form.Row>
      )}
      <Form.Row label={i18n.t('components:Settings:InvoiceBankSettings:bank')} required>
        <Form.Row.FlexItem flex="0 0 200px">
          <Field
            component={Form.TextField}
            name="bankName"
            placeholder={i18n.t('components:Settings:InvoiceBankSettings:bank')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.FooterActions
        cancelLink={cancelLink}
        isSubmitting={isLoading}
        submitLabel={i18n.t('global:Save')}
      />
    </Form>
  </Section>
)

const validate = (fields, { isUkCountry }) => {
  const errors = {}

  const { bankAccountName, bankAccountNotes, bankAccountNumber, bankName, sortCode } = fields

  let hasData = !!(bankAccountName || bankAccountNumber || bankName)

  if (isUkCountry) {
    hasData = hasData || !!sortCode
  } else {
    hasData = hasData || !!bankAccountNotes
  }

  if (hasData) {
    errors.bankAccountName = isRequired(bankAccountName)
    errors.bankAccountNumber = isRequired(bankAccountNumber)
      || (isUkCountry && isValidBankAccountNumber(bankAccountNumber))
    errors.bankName = isRequired(bankName)

    if (isUkCountry) {
      errors.sortCode = isSortCodeValid(sortCode)
    }
  }

  return errors
}

export default reduxForm({ form: 'InvoiceBankSettingForm', validate })(InvoiceBankSettingForm)
