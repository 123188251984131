export const GET_ABSENCE_REASON = 'GET_ABSENCE_REASON'
export const GET_ABSENCE_REASON_SUCCESS = 'GET_ABSENCE_REASON_SUCCESS'
export const GET_ABSENCE_REASON_FAILED = 'GET_ABSENCE_REASON_FAILED'

export const CREATE_ABSENCE_REASON = 'CREATE_ABSENCE_REASON'
export const CREATE_ABSENCE_REASON_SUCCESS = 'CREATE_ABSENCE_REASON_SUCCESS'
export const CREATE_ABSENCE_REASON_FAILED = 'CREATE_ABSENCE_REASON_FAILED'

export const UPDATE_ABSENCE_REASON = 'UPDATE_ABSENCE_REASON'
export const UPDATE_ABSENCE_REASON_SUCCESS = 'UPDATE_ABSENCE_REASON_SUCCESS'
export const UPDATE_ABSENCE_REASON_FAILED = 'UPDATE_ABSENCE_REASON_FAILED'

export const REMOVE_ABSENCE_REASON = 'REMOVE_ABSENCE_REASON'
export const REMOVE_ABSENCE_REASON_SUCCESS = 'REMOVE_ABSENCE_REASON_SUCCESS'
export const REMOVE_ABSENCE_REASON_FAILED = 'REMOVE_ABSENCE_REASON_FAILED'

export const CLEAR_ABSENCE_REASON = 'CLEAR_ABSENCE_REASON'
