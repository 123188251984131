import React from 'react'

import { DatePicker, InfiniteDropdowns, Page, Section, Select, Spinner, Toolbar, Typography } from 'components'

import i18n from 'translations'

import MobileStatistics from './components/MobileStatistics'
import StatisticsTable from './components/StatisticsTable'
import StatisticsChart from './components/StatisticsChart'

const GroupRevenueView = ({
  dateRange,
  dateType,
  dateTypeOptions,
  isDepositEnabled,
  isFinanceV3Enabled,
  isLoading,
  nurseries,
  nurseriesChart,
  nursery,
  onChangePage,
  onDateChange,
  onDateTypeChange,
  onNurseryChange,
  onSendReport,
  page,
  parsedFilters,
  previousStatistics,
  statistics,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <div>
        <MobileStatistics
          previousStatistics={previousStatistics}
          statistics={statistics}
        />
        <StatisticsChart
          nurseriesChart={nurseriesChart}
          page={page}
          onChangePage={onChangePage}
        />
        <StatisticsTable
          isDepositEnabled={isDepositEnabled}
          isFinanceV3Enabled={isFinanceV3Enabled}
          nurseries={nurseries}
          parsedFilters={parsedFilters}
          statistics={statistics}
        />
      </div>
    )
  }

  const actions = <Section.Actions options={[{ onClick: onSendReport, type: 'export' }]} />

  return (
    <Page alwaysExpanded>
      <Page.Content>
        <Section
          actions={actions}
          title={i18n.t('module:Organization:Reports:GroupRevenue:title')}
        >
          <Typography margin="0 0 20px">
            {i18n.t('module:Organization:Reports:GroupRevenue:reportSubTitle')}
          </Typography>
          <Toolbar>
            <Toolbar.Group>
              <Toolbar.Item>
                <Select
                  clearable={false}
                  options={dateTypeOptions}
                  placeholder={i18n.t('module:Organization:Reports:GroupRevenue:dateTypeFilterPlaceholder')}
                  searchable={false}
                  value={dateType}
                  onChange={onDateTypeChange}
                />
              </Toolbar.Item>
              <Toolbar.Item>
                <DatePicker
                  value={dateRange}
                  clearable
                  range
                  onChange={onDateChange}
                />
              </Toolbar.Item>
              <Toolbar.Item>
                <InfiniteDropdowns.Nurseries
                  placeholder={i18n.t('module:Organization:Reports:GroupRevenue:nurseriesFilterPlaceholder')}
                  value={nursery}
                  onChange={onNurseryChange}
                />
              </Toolbar.Item>
            </Toolbar.Group>
          </Toolbar>
          {renderContent()}
        </Section>
      </Page.Content>
    </Page>
  )
}

export default GroupRevenueView
