import _ from 'lodash'

import { BasicListInitialState, getBasicListInitialState, getBasicListReducers } from 'utils/reducer'
import { mergeData } from 'utils/data'

import { CLEAR_USERS_LIST, GET_USERS_LIST, GET_USERS_LIST_FAILED, GET_USERS_LIST_SUCCESS } from './constants'
import { GET_USER_SUCCESS } from '../single/constants'

const initialState = getBasicListInitialState

export interface UsersListState extends BasicListInitialState {}

export default (...props) => {
  const [state = initialState, { addToList, payload, type }] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_USERS_LIST,
    failed: GET_USERS_LIST_FAILED,
    init: GET_USERS_LIST,
    success: GET_USERS_LIST_SUCCESS,
  }, initialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  if (type === GET_USER_SUCCESS) {
    if (addToList) {
      return {
        ...state,
        data: _.uniqBy(mergeData(state.data, [payload?.data], true), ({ id }) => id),
      }
    }

    return {
      ...state,
    }
  }

  return state
}
