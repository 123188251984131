import _ from 'lodash'

import { compose } from 'recompose'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withDepositsService } from 'services/legacy/deposits'
import { withModalService } from 'services/utils/modal'
import { withSnackbarService } from 'services/utils/snackbar'
import { withRouter } from 'services/router'

import i18n from 'translations'

import DepositRefundModalView from './DepositRefundModalView'

class DepositRefundModalContainer extends Component {
  handleSubmitSuccess = () => {
    const { hideModal, navigate, params, snackbarActions } = this.props
    const { childId } = params

    const action = i18n.t('module:Finance:Deposits:Form:Snackbar:Action:created')
    const type = _.upperFirst(i18n.t('module:Finance:Deposits:Form:Type:depositRefund'))
    const route = childId
      ? generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.DEPOSITS', { childId })
      : generateRoute('FINANCE.DEPOSITS')

    hideModal()

    snackbarActions.show({
      message: i18n.t('module:Finance:Deposits:Form:Snackbar:message', { action, type }),
    })

    navigate(route)
  }

  handleSubmit = (values) => {
    const { depositsActions, depositsSelectors, originalDepositId } = this.props

    const body = depositsSelectors.getRefundPayload({ originalDepositId, values })

    depositsActions.create({
      body,
      onSuccess: this.handleSubmitSuccess,
      params: [{}],
    })
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  render() {
    const {
      errorMessages,
      initialValues,
      isSubmitting,
    } = this.props

    return (
      <DepositRefundModalView
        errorMessages={errorMessages}
        initialValues={initialValues}
        isSubmitting={isSubmitting}
        onCloseClick={this.handleCloseClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, { appSelectors, depositsSelectors, depositsSingleState }) => ({
  errorMessages: appSelectors.getErrorMessages(depositsSingleState),
  initialValues: depositsSelectors.getRefundInitialValues(),
  isSubmitting: appSelectors.getIsSubmitting(depositsSingleState),
})

const enhance = compose(
  withAppService,
  withDepositsService,
  withModalService,
  withRouter,
  withSnackbarService,
  connect(mapState),
)

export default enhance(DepositRefundModalContainer)
