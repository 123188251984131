import _ from 'lodash'
import moment from 'moment'

import React, { useMemo } from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { CONTRACT_TYPE_OPTIONS_SHORT } from 'services/legacy/contracts/constants'
import { STAFF_REGISTER_TYPES, WORK_SHIFT_TYPES } from 'services/legacy/membershipRegisters/constants'

import { convertTimeDuration, millisecondsToHoursAndMinutesString } from 'utils/date'

import {
  Button,
  Callout,
  DatePicker,
  Form,
  InfiniteDropdowns,
  ModalBox,
  Space,
  Spinner,
  Toolbar,
  Typography,
} from 'components'

import i18n from 'translations'

import { getAvailableBreakAllowances, getWorkingHours } from './components/ShiftModalForm/ShiftModalFormHelpers'
import { SHIFT_MODAL_MODE } from './ShiftModalContainer'
import ShiftModalForm from './components/ShiftModalForm'

const ShiftModalView = React.memo(({
  attendanceView,
  contracts,
  date,
  disablePastWeek,
  errorMessages,
  formValues,
  isEditMode,
  isFetching,
  isSubmitting,
  membership,
  mode,
  onChangeDate,
  onChangeMembership,
  onChangeModalMode,
  onCloseClick,
  ...rest
}) => {
  const renderForm = () => {
    if (isFetching) {
      return <Spinner />
    }

    if (!membership || !date) {
      return null
    }

    return (
      <ShiftModalForm
        attendanceView={attendanceView}
        contracts={contracts}
        date={date}
        disablePastWeek={disablePastWeek}
        formValues={formValues}
        isEditMode={isEditMode}
        isSubmitting={isSubmitting}
        membership={membership}
        mode={mode}
        onCloseClick={onCloseClick}
        {...rest}
      />
    )
  }

  const calculatedStatistics = useMemo(() => {
    let contractChanges = false
    const currentContract = contracts?.[0]

    if (1 < contracts?.length) {
      contractChanges = true
    }

    const contractTypeLabel = _.find(
      CONTRACT_TYPE_OPTIONS_SHORT,
      ({ value: contractType }) => contractType === currentContract?.type,
    )?.label

    let totalBreaks = 0
    let availableBreaks = 0

    const { membershipShiftTimes } = formValues || {}

    _.each(membershipShiftTimes, (dayItems, day) => {
      _.each(dayItems, ({ duration: breakDuration, endTime, startTime, type }) => {
        const finalType = type?.value || type

        if (
          (WORK_SHIFT_TYPES.BREAK === finalType || STAFF_REGISTER_TYPES.BREAK === finalType)
          && startTime
          && endTime
        ) {
          totalBreaks += moment(moment(endTime).set('seconds', 0)).diff(moment(startTime).set('seconds', 0))
        }

        if (WORK_SHIFT_TYPES.BREAK_DURATION === finalType && +breakDuration) {
          totalBreaks += convertTimeDuration(+breakDuration)
        }
      })

      const availableBreakAllowances = getAvailableBreakAllowances({ contracts, date: day, formValues })

      if (availableBreakAllowances?.length) {
        availableBreaks += _.sum(_.map(availableBreakAllowances, ({ time }) => +time))
      }
    })

    return {
      availableBreaks,
      contractChanges,
      contractTypeLabel,
      currentContract,
      totalBreaks,
    }
  }, [contracts, formValues])

  const renderContractInfo = (header) => (
    <Toolbar.Item>
      <Typography margin="0 40px 0 0" variant="div">
        <Typography margin="0 0 5px" variant="h6">
          {header}
        </Typography>
        <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} transform="uppercase">
          {i18n.t('module:Modals:Staff:Shift:contractType')}
        </Typography>
      </Typography>
    </Toolbar.Item>
  )

  const renderContactStatistics = () => {
    if (isFetching || !membership || !date) {
      return null
    }

    const {
      availableBreaks,
      contractChanges,
      contractTypeLabel,
      currentContract,
      totalBreaks,
    } = calculatedStatistics

    return (
      <Toolbar>
        <Toolbar.Group>
          {renderContractInfo(
            // eslint-disable-next-line no-nested-ternary
            currentContract
              ? (
                contractChanges
                  ? i18n.t('module:Modals:Staff:Shift:contractChanges')
                  : contractTypeLabel
              )
              : i18n.t('module:Modals:Staff:Shift:noContract'),
          )}
          <Toolbar.Item>
            <Typography margin="0 40px 0 0" variant="div">
              <Typography margin="0 0 5px" variant="h6">
                {getWorkingHours(
                  currentContract,
                  contractChanges,
                  formValues,
                  mode,
                )}
              </Typography>
              <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} transform="uppercase">
                {i18n.t('module:Modals:Staff:Shift:workingHours')}
              </Typography>
            </Typography>
          </Toolbar.Item>
          <Toolbar.Item>
            <Typography margin="0 10px 0 0" variant="div">
              <Typography margin="0 0 5px" variant="h6">
                {totalBreaks ? millisecondsToHoursAndMinutesString(totalBreaks) : `0${i18n.t('global:hourShortcut')}`}
                {availableBreaks ? ` / ${millisecondsToHoursAndMinutesString(availableBreaks)}` : ''}
              </Typography>
              <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} transform="uppercase">
                {i18n.t('module:Modals:Staff:Shift:break')}
              </Typography>
            </Typography>
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
    )
  }

  return (
    <ModalBox
      maxWidth="1000"
      scrollToBody={false}
      title={isEditMode
        ? i18n.t('module:Modals:Staff:Shift:editTitle')
        : i18n.t('module:Modals:Staff:Shift:title')}
      autoHeight
      onCloseClick={onCloseClick}
    >
      <Callout content={_.toArray(errorMessages)} error />
      {errorMessages && (
        <Space space="20px" />
      )}
      <Form.Row label={_.upperFirst(i18n.t('global:staffMember'))} verticalLabel>
        <Form.Row.FlexItem>
          <InfiniteDropdowns.Memberships
            disabled={isSubmitting || isFetching}
            extraFields={['accepted', 'email', 'membershipProfile']}
            extraParams={{
              disablePendingFilter: true,
              nonTeaching: true,
            }}
            groups={{ read: ['membership.profile', 'membership.details'] }}
            name="membership"
            placeholder={_.upperFirst(i18n.t('global:staffMember'))}
            value={membership}
            bodyTarget
            onChange={onChangeMembership}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <Form.Row label={_.upperFirst(i18n.t('global:week'))} verticalLabel>
              <DatePicker
                disabled={isSubmitting || isFetching}
                name="date"
                type="week"
                value={date}
                range
                onChange={onChangeDate}
              />
            </Form.Row>
          </Toolbar.Item>
          {attendanceView && (
            <React.Fragment>
              <Toolbar.Item>
                <Space space="5px" />
                <Button.RadioButton
                  isActive={mode === SHIFT_MODAL_MODE.ACTUAL}
                  label={i18n.t('module:Modals:Staff:Shift:actualHours')}
                  onClick={() => onChangeModalMode(SHIFT_MODAL_MODE.ACTUAL)}
                />
              </Toolbar.Item>
              <Toolbar.Item>
                <Space space="5px" />
                <Button.RadioButton
                  isActive={mode === SHIFT_MODAL_MODE.PLANNED}
                  label={i18n.t('module:Modals:Staff:Shift:plannedHours')}
                  onClick={() => onChangeModalMode(SHIFT_MODAL_MODE.PLANNED)}
                />
              </Toolbar.Item>
            </React.Fragment>
          )}
        </Toolbar.Group>
      </Toolbar>
      {renderContactStatistics()}
      {renderForm()}
    </ModalBox>
  )
})

export default ShiftModalView
