import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as dailyDiaryActivitiesSelectors from './selectors'

const withDailyDiaryActivitiesService = (WrappedComponent) => {
  const mapState = ({ dailyDiaryActivities }) => ({ dailyDiaryActivities })

  const mapDispatch = injectActions('dailyDiaryActivitiesActions', actions)

  const Component = ({ dailyDiaryActivities, dailyDiaryActivitiesActions, ...others }) => (
    <WrappedComponent
      dailyDiaryActivitiesActions={dailyDiaryActivitiesActions}
      dailyDiaryActivitiesListState={dailyDiaryActivities.list}
      dailyDiaryActivitiesSelectors={dailyDiaryActivitiesSelectors}
      dailyDiaryActivitiesSingleState={dailyDiaryActivities.single}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withDailyDiaryActivitiesService
