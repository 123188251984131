import React from 'react'

import { Page } from 'components'

import OccupancyReportGeneratorForm from './components/OccupancyReportGeneratorForm'

const OccupancyReportGeneratorView = ({
  formValues,
  initialValues,
  isOverviewGenerator,
  onSubmit,
}) => (
  <Page>
    <Page.Content>
      <OccupancyReportGeneratorForm
        formValues={formValues}
        initialValues={initialValues}
        isOverviewGenerator={isOverviewGenerator}
        onSubmit={onSubmit}
      />
    </Page.Content>
  </Page>
)

export default OccupancyReportGeneratorView
