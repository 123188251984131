import _ from 'lodash'

import React from 'react'
import { Field } from 'redux-form'

import { AGES_MAP, FILTERS_MAP } from 'services/legacy/cohortTracking/constants'
import { GENDER_DROPDOWN } from 'services/legacy/child/constants'

import { Form, Section, Toolbar } from 'components'

import i18n from 'translations'

import { StyledFilters } from './ReportGeneratorFiltersStyled'

const OVERLOAD_KEY_PERSON_VALUE_EVENT = 'EVENT/OVERLOAD_KEY_PERSON_VALUE_EVENT'
const OVERLOAD_NURSERY_CLASS_VALUE_EVENT = 'EVENT/OVERLOAD_NURSERY_CLASS_VALUE_EVENT'

const ReportGeneratorFilters = ({
  disableEthnicity,
  ethnicities,
  onResetFilters,
}) => (
  <Section
    actions={(
      <Section.Actions
        options={[{
          onClick: onResetFilters([
            OVERLOAD_KEY_PERSON_VALUE_EVENT,
            OVERLOAD_NURSERY_CLASS_VALUE_EVENT,
          ]),
          type: 'reset',
        }]}
      />
    )}
    title={i18n.t('module:Learning:CohortTracking:components:ReportGeneratorFilters:selectFilters')}
  >
    <StyledFilters>
      {_.map(FILTERS_MAP, ({ label, value }) => (
        <Field
          component={Form.Checkbox}
          label={label}
          name={`filters.${value}`}
        />
      ))}
    </StyledFilters>
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <Field
            component={Form.Select}
            name="filters.gender"
            options={GENDER_DROPDOWN}
            placeholder={i18n.t('module:Learning:CohortTracking:components:ReportGeneratorFilters:gender')}
            searchable={false}
            multi
          />
        </Toolbar.Item>
        {!disableEthnicity && (
          <Toolbar.Item>
            <Field
              component={Form.Select}
              name="filters.ethnicity"
              options={ethnicities}
              placeholder={i18n.t('module:Learning:CohortTracking:components:ReportGeneratorFilters:ethnicity')}
            />
          </Toolbar.Item>
        )}
      </Toolbar.Group>
    </Toolbar>
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <Field
            component={Form.Select}
            name="filters.age"
            options={AGES_MAP}
            placeholder={i18n.t('global:Age')}
          />
        </Toolbar.Item>
        <Toolbar.Item>
          <Field
            component={Form.InfiniteDropdowns.Rooms}
            name="filters.nurseryClass"
            overloadValueEventName={OVERLOAD_NURSERY_CLASS_VALUE_EVENT}
          />
        </Toolbar.Item>
        <Toolbar.Item>
          <Field
            component={Form.InfiniteDropdowns.Users}
            name="filters.keyWorker"
            overloadValueEventName={OVERLOAD_KEY_PERSON_VALUE_EVENT}
            placeholder={i18n.t('global:KeyPerson')}
          />
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  </Section>
)

export default ReportGeneratorFilters
