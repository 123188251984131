import { flatten, nest } from 'utils/flatnest'
import moment from 'moment'
import { compose } from 'recompose'

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { constants as invoicesConstants } from 'services/legacy/invoices'

import { EVENTS, logEvent } from 'analytics'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withTransactionsService } from 'services/legacy/transactions'
import { withModalService } from 'services/utils/modal'
import { withAgedDebtService } from 'services/legacy/agedDebt'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withSortingUtils } from 'services/utils/sorting'
import { withRouter } from 'services/router'

import withInvoiceSendHandlers from 'module/Finance/withInvoiceSendHandlers'

import ChildFinanceTransactionsView from './ChildFinanceTransactionsView'

const CHILD_STATISTICS_GROUPS = {
  read: [
    'childAgedDebtStatistics',
    'child.finance',
  ],
}

const CHILD_FINANCE_DETAILS_GROUPS = {
  read: [
    'child.finance',
  ],
}

const TRANSACTIONS_GROUPS = {
  read: [
    'balanceAdjustment',
    'balanceAdjustment.author',
    'creditNote',
    'creditNote.invoice',
    'invoice',
    'payment',
    'payment.paymentType',
    'transaction',
    'user',
  ],
}

const COLUMNS = [
  {
    align: 'left',
    field: 'documentDate',
    title: 'Date',
  },
  {
    align: 'left',
    field: 'number',
    title: 'Ref number',
  },
  {
    align: 'left',
    field: 'details',
    title: 'Details',
  },
  {
    field: 'debit',
    title: 'Debit',
  },
  {
    field: 'credit',
    title: 'Credit',
  },
  {
    align: 'left',
    field: 'balance',
    title: 'Balance',
  },
]

const AGEING_ANALYSIS_COLUMNS = [
  {
    field: '0-30',
    title: '0-30 days',
  },
  {
    field: '30-60',
    title: '30-60 days',
  },
  {
    field: '60-90',
    title: '60-90 days',
  },
  {
    field: '90-120',
    title: '90-120 days',
  },
  {
    field: '120',
    title: '+120 days',
  },
  {
    field: 'total',
    title: 'Total balance',
  },
]

class ChildFinanceTransactionsContainer extends Component {
  constructor(props) {
    super(props)

    const { query } = props.location
    const { documentDate } = nest(query)

    const after = documentDate && documentDate.after ? moment(documentDate.after) : moment().add('months', -3)
    const before = documentDate && documentDate.before ? moment(documentDate.before) : moment()

    this.state = {
      filters: {
        documentDate: {
          after: after.format(DEFAULT_DATE_FORMAT),
          before: before.format(DEFAULT_DATE_FORMAT),
        },
      },
    }
  }

  componentDidMount() {
    const {
      params: { childId },
      transactionsActions,
      transactionsSelectors,
    } = this.props

    logEvent(EVENTS.TRANSACTION_PAGE_VIEWED)

    this.fetchChildFinanceDetails()

    const criteria = transactionsSelectors.getCriteriaSelector({ child: childId })

    const parameters = {
      criteria,
      groups: TRANSACTIONS_GROUPS,
      limit: 1,
      order: {
        sortField: 'documentDate',
        sortOrder: 'DESC',
      },
      page: 1,
    }

    transactionsActions.list(parameters, ({ data }) => {
      if (data && data.length) {
        const { filters } = this.state
        const { documentDate } = filters
        const { documentDate: latestTransactionDate } = data[0]

        const latestDocumentDate = moment.max([moment(documentDate.before), moment(latestTransactionDate)])

        this.setState({
          filters: {
            ...filters,
            documentDate: {
              ...documentDate,
              before: moment(latestDocumentDate).format(DEFAULT_DATE_FORMAT),
            },
          },
        }, () => {
          this.fetch()
          this.getChildAgedDebtStatistics()
        })
      } else {
        this.fetch()
        this.getChildAgedDebtStatistics()
      }
    })
  }

  componentWillUnmount() {
    const { agedDebtActions, childActions, transactionsActions } = this.props

    childActions.clearFinanceDetails()
    transactionsActions.clearList()
    agedDebtActions.clear()
  }

  fetchChildFinanceDetails = () => {
    const {
      childActions,
      params: { childId },
    } = this.props

    childActions.getFinanceDetails({
      params: [childId, {
        groups: CHILD_FINANCE_DETAILS_GROUPS,
      }],
    })
  }

  getChildAgedDebtStatistics = () => {
    const { agedDebtActions, params } = this.props
    const { childId } = params

    const criteria = [{
      field: 'id',
      value: childId,
    }]

    const apiParams = {
      criteria,
      groups: CHILD_STATISTICS_GROUPS,
    }

    agedDebtActions.list(apiParams)
  }

  fetch = () => {
    const {
      paginationUtils,
      params,
      setLocationQuery,
      sortingUtils,
      transactionsActions,
      transactionsSelectors,
    } = this.props
    const { childId } = params
    const { filters } = this.state

    const { page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const criteria = transactionsSelectors.getCriteriaSelector({ ...filters, child: childId })

    setLocationQuery(flatten(filters))

    const parameters = {
      criteria,
      groups: TRANSACTIONS_GROUPS,
      order: {
        sortField: sortField || 'documentDate',
        sortOrder,
      },
      page,
    }

    transactionsActions.list(parameters)
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleSortChange = (key) => {
    const { paginationUtils, sortingUtils } = this.props
    const { onSortChange } = sortingUtils
    const { onPageChange } = paginationUtils

    onSortChange(onPageChange(this.fetch)(1))(key)
  }

  handleDateChange = (dateRange) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    const [after, before] = dateRange
    const documentDate = {
      after: after ? moment(after).format(DEFAULT_DATE_FORMAT) : undefined,
      before: before ? moment(before).format(DEFAULT_DATE_FORMAT) : undefined,
    }

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, documentDate },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handleSendSuccess = () => {
    const { modalActions, modalConsts } = this.props

    logEvent(EVENTS.TRANSACTION_SEND_SUBMITTED)

    modalActions.show(modalConsts.TYPES.ALERT, {
      icon: 'send',
      text: "Your statement of account has been sent to the specified recipients on the child's account.",
    })
  }

  submitDownloadClick = (format) => {
    const { params, transactionsActions, transactionsSelectors } = this.props
    const { filters: { documentDate: { after, before } } } = this.state
    const { childId } = params

    const criteria = transactionsSelectors.getCriteriaSelector({
      endDate: before,
      format,
      startDate: after,
    })
    const apiParams = { criteria }

    transactionsActions.download(childId, apiParams, () => logEvent(EVENTS.TRANSACTION_LIST_DOWNLOADED))
  }

  handleDownloadClick = () => {
    const { modalActions, modalConsts } = this.props

    logEvent(EVENTS.TRANSACTION_LIST_DOWNLOAD_BTN_CLICKED)

    modalActions.show(modalConsts.TYPES.SELECT_FORMAT, {
      onSubmit: this.submitDownloadClick,
      title: 'Download your statement of account',
    })
  }

  redirectToContact = () => {
    const { navigate, params } = this.props
    const { childId } = params

    navigate(`/children/${childId}/finance/contact`)
  }

  handleSendFailed = (errorMessage) => {
    const { sendHandlers } = this.props
    const { code } = errorMessage

    sendHandlers.handleSendConflict({
      errorMessage,
      onClickCallback: (
        code === invoicesConstants.ERROR_CODES.MISSING_PAYMENT_CONTACT
          ? this.redirectToContact
          : undefined
      ),
    })
  }

  confirmSendClick = () => {
    const { params, transactionsActions, transactionsSelectors } = this.props
    const { filters: { documentDate: { after, before } } } = this.state
    const { childId } = params

    const criteria = transactionsSelectors.getCriteriaSelector({
      endDate: before,
      format: 'pdf',
      startDate: after,
    })
    const apiParams = { criteria }

    transactionsActions.send(childId, apiParams, this.handleSendSuccess, this.handleSendFailed)
  }

  handleCreateBalanceAdjustmentSuccess = () => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(1)
    this.fetchChildFinanceDetails()
    this.getChildAgedDebtStatistics()
  }

  handleClickBalanceAdjustment = (item) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.BALANCE_ADJUSTMENT, {
      item,
      onCreateSuccess: this.handleCreateBalanceAdjustmentSuccess,
    })

    logEvent(EVENTS.BALANCE_ADJUSTMENT_ADD_BTN_CLICKED, { context: 'transactions list' })
  }

  handleSendClick = () => {
    const { modalActions, modalConsts } = this.props
    const { filters: { documentDate: { after, before } } } = this.state

    logEvent(EVENTS.TRANSACTION_SEND_CLICKED)

    const dateText = after && before
      ? `for the date range ${moment(after).format('DD/MM/YYYY')} - ${moment(before).format('DD/MM/YYYY')} `
      : ''

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: 'Send',
      icon: 'send',
      onConfirm: this.confirmSendClick,
      text: `Are you sure you want to send the statement of account ${dateText}
        to the specified recipients on the child's account?`,
    })
  }

  render() {
    const {
      ageDebtStatistics,
      childFinanceDetails,
      errorMessages,
      isFetching,
      paginationUtils,
      params,
      sortingUtils,
      totalResults,
      transactions,
    } = this.props
    const { filters } = this.state

    const { childId } = params
    const { documentDate: { after, before } } = filters
    const { getPageCount, page, perPage } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const pageCount = getPageCount(totalResults)
    const dateRange = [after, before]
    const isEmpty = !isFetching && transactions && !transactions.length

    return (
      <ChildFinanceTransactionsView
        ageDebtStatistics={ageDebtStatistics}
        ageingAnalysisColumns={AGEING_ANALYSIS_COLUMNS}
        childFinanceDetails={childFinanceDetails}
        childId={childId}
        columns={COLUMNS}
        dateRange={dateRange}
        errorMessages={errorMessages}
        isEmpty={isEmpty}
        isLoading={isFetching}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        sortField={sortField}
        sortOrder={sortOrder}
        totalResults={totalResults}
        transactions={transactions}
        onClickBalanceAdjustment={this.handleClickBalanceAdjustment}
        onDateChange={this.handleDateChange}
        onDownloadClick={this.handleDownloadClick}
        onPageChange={this.handlePageChange}
        onSendClick={this.handleSendClick}
        onShowBalanceAdjustmentModal={this.handleClickBalanceAdjustment}
        onSortChange={this.handleSortChange}
      />
    )
  }
}

const mapState = (state, {
  agedDebtListChildrenState,
  agedDebtSelectors,
  appSelectors,
  childFinanceDetailsState,
  childSelectors,
  transactionsListState,
  transactionsSelectors,
}) => ({
  ageDebtStatistics: agedDebtSelectors.getAgeDebtStatistics(state),
  childFinanceDetails: childSelectors.getFinanceDetailsSelector(state),
  errorMessages: appSelectors.getErrorMessages(transactionsListState),
  isFetching: appSelectors.getIsFetching(childFinanceDetailsState, agedDebtListChildrenState, transactionsListState),
  totalResults: transactionsSelectors.getTransactionsListMetaTotalResults(state),
  transactions: transactionsSelectors.getTransactionsListDataTable(state),
})

const enhance = compose(
  withAppService,
  withAgedDebtService,
  withChildService,
  withModalService,
  withPaginationUtils,
  withRouterUtils,
  withSortingUtils,
  withTransactionsService,
  connect(mapState),
  withInvoiceSendHandlers,
  withRouter,
)

export default enhance(ChildFinanceTransactionsContainer)
