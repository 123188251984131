import styled from 'styled-components'

import layout, { ZINDEX_ORDER } from 'constants/layout'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

import { typeByObject } from 'utils/typescript'
import { DRAWER_POSITION } from './Drawer'

interface StyledHeaderProps {
  $isHeaderPinned?: boolean
}

export const StyledHeader = styled.header<StyledHeaderProps>`
  position: sticky;
  top: ${({ $isHeaderPinned }) => ($isHeaderPinned
    ? `${layout.topBarHeight + layout.menuBarHeight}px`
    : `${layout.topBarHeight}px`)};
  z-index: ${ZINDEX_ORDER.DRAWER + 20};
  transition: all .2s ease-in-out 0s;

  @media(max-width: 700px) {
    position: relative;
    top: auto;
    z-index: 10;
  }
`

export const StyledContent = styled.div`
  position: relative;
  padding: 0 78px;

  @media(max-width: 1260px) {
    padding-right: 0;
  }
`

interface StyledMenuProps {
  $closeContent?: boolean
  $open?: boolean
  $position?: typeByObject<typeof DRAWER_POSITION>
  $top: number
  $widthOpen?: number
}

export const StyledMenu = styled.nav<StyledMenuProps>`
  position: fixed;
  left: 0;
  top: ${({ $top }) => $top}px;
  bottom: 0;
  width: ${({ $open, $widthOpen }) => ($open ? $widthOpen : 84)}px;
  z-index: ${ZINDEX_ORDER.DRAWER + 10};
  transition: .1s width;
  pointer-events: ${({ $closeContent, $open }) => (!$open && $closeContent ? 'none' : 'auto')};
  
  ${({ $position }) => $position === DRAWER_POSITION.RIGHT && `
    left: auto;
    right: 0;
  `}
`

interface StyledMenuContentProps {
  $background?: string
  $closeContent?: boolean
  $open?: boolean
  $paddingBottom?: number
  $position?: typeByObject<typeof DRAWER_POSITION>
}

export const StyledMenuContent = styled.div<StyledMenuContentProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${getBrandingColor('primary-color')};
  box-shadow: -4px 0 20px 0 rgba(89, 89, 89, .5);
  margin-right: 17px;
  overflow: auto;
  height: calc(100% - 33px);
  
  ${({ $position }) => $position === DRAWER_POSITION.RIGHT && `
    margin-right: 0;
    margin-left: 17px;
    box-shadow: 4px 0 20px 0 rgba(89, 89, 89, .5);
  `}
  
  ${({ $background }) => $background && `
    background: ${$background};
  `}
  
  ${({ $closeContent, $open }) => $closeContent && !$open && `
    box-shadow: none;
    transform: translateX(-100%);
  `}
  
  ${({ $closeContent, $open, $position }) => $closeContent && !$open && $position === DRAWER_POSITION.RIGHT && `
    transform: translateX(100%);
  `}
  
  ${({ $paddingBottom }) => $paddingBottom && `
    padding-bottom: ${$paddingBottom}px;
  `}
`

interface StyledMenuHeaderProps {
  $background?: string
  $open?: boolean
  $position?: typeByObject<typeof DRAWER_POSITION>
}

export const StyledMenuHeader = styled.header<StyledMenuHeaderProps>`
  background: ${getBrandingColor('senary-color')};
  height: 33px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 15px;
  cursor: pointer;
  pointer-events: auto;
  
  ${({ $position }) => $position === DRAWER_POSITION.RIGHT && `
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    flex-direction: row-reverse;
    justify-content: flex-start;
  `}  
  
  ${({ $open, $position }) => $open && $position === DRAWER_POSITION.RIGHT && `
    justify-content: flex-end;
  `}
  
  ${({ $background }) => $background && `
    background: ${$background};
  `}
`

interface StyledCurtainProps {
  $top?: boolean
}

export const StyledCurtain = styled.div<StyledCurtainProps>`
  position: fixed;
  left: 0;
  top: ${({ $top }) => $top}px;
  bottom: 0;
  right: 0;
  z-index: ${ZINDEX_ORDER.DRAWER};
  transition: .4s;
  background: rgba(0, 0, 0, .5);
  cursor: pointer;
  
  @media(min-width: ${layout.breakpointFullMenu}px) {
    display: none;
  }
`

interface StyledChevronProps {
  $position?: typeByObject<typeof DRAWER_POSITION>
}

export const StyledChevron = styled.div<StyledChevronProps>`
  margin-left: 10px;
  
  ${({ $position }) => $position === DRAWER_POSITION.RIGHT && `
    margin-right: 10px;
  `}
`

interface StyledIconProps {
  $position?: typeByObject<typeof DRAWER_POSITION>
}

export const StyledIcon = styled.div<StyledIconProps>`
  margin-right: 10px; 
  
  ${({ $position }) => $position === DRAWER_POSITION.RIGHT && `
    margin-left: 10px;
  `}
`

interface StyledTitleProps {
  $position?: typeByObject<typeof DRAWER_POSITION>
}

export const StyledTitle = styled.div<StyledTitleProps>`
  color: ${NEUTRAL_COLOURS.WHITE};
  margin-right: 10px;
  font-weight: 600;
  font-size: 15px;
  
  ${({ $position }) => $position === DRAWER_POSITION.RIGHT && `
    margin-left: 10px;
  `}
`

export const StyledStorybookWrapper = styled.div`
  position: relative;
  height: 600px;

  ${StyledMenu} {
    position: absolute;
    top: 0;
  }
  
  ${StyledCurtain} {
    position: absolute;
    top: 0;
  }

  ${StyledMenuContent} {
    height: 573px;
  }
`
