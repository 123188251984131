import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'
import layout from 'constants/layout'

import { Button } from 'components'

export const StyledFormLinkContentWrapper = styled.div`
  border-radius: 4px;
  margin-top: 13px;
  padding: 15px;
  background-color: ${NEUTRAL_COLOURS.GRAY_SECONDARY};

  & > p {
    word-break: break-word;
  }
`

export const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  margin-top: 20px;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    flex-direction: column;
    width: 100%;
  }
`

export const StyledCopyLinkButton = styled(Button)`
  margin-right: 16px;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    width: 100%;
  }
`

export const StyledPreviewButton = styled(Button)`
  @media(max-width: ${layout.mobileWidthResolution}px) {
    width: 100%;
  }
`
