import React from 'react'

import { StyledContainer } from './PlanViewStyled'

const PlanView = ({ children }) => (
  <StyledContainer>
    {children}
  </StyledContainer>
)

export default PlanView
