import React, { PropsWithChildren } from 'react'

import { StyledSection } from './DetailsBreakdownStyled'

interface DetailsBreakdownSectionProps {
  labelWidth?: number
}

const DetailsBreakdownSection: React.FC<PropsWithChildren<DetailsBreakdownSectionProps>> = ({
  children,
  labelWidth,
}) => (
  <StyledSection $labelWidth={labelWidth}>
    {children}
  </StyledSection>
)

export default DetailsBreakdownSection
