import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledWrapper = styled.div`
  display: flex;
  background: #F5F5F5;
`

export const StyledChild = styled.div`
  width: ${({ recordsAmount }) => 100 / (recordsAmount + 1)}%;
  padding: 25px 0;
`

export const StyledSubTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;  
`

export const StyledIcons = styled.div`
  display: flex;
`

export const StyledShowMore = styled.div`
  width: ${({ recordsAmount }) => 100 / (recordsAmount + 1)}%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 25px;
  cursor: pointer;
`

export const StyledAvatar = styled.div`
  width: 55px;
  height: 55px;
  min-width: 55px;
  min-height: 55px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: ${NEUTRAL_COLOURS.WHITE};
  font-weight: 600;
  background-color: ${getBrandingColor('tertiary-color')};
`

export const StyledTitleAvatar = styled.div`
  margin-top: 5px;
  font-weight: 600;
  font-size: 14px;
  text-decoration: underline;
`
