import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withModalService } from 'services/utils/modal'

import ModalRootView from './ModalRootView'

const ModalRootContainer = ({
  modalActions,
  modalStack,
}) => (
  <ModalRootView
    modalStack={modalStack}
    onHideModal={modalActions.hide}
  />
)

const mapState = (state, { modalSelectors }) => ({
  modalStack: modalSelectors.getModalStack(state),
})

const enhance = compose(withModalService, connect(mapState))

export default enhance(ModalRootContainer)
