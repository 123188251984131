import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_NURSERY_FUNDING_DROPDOWN_LIST,
  NURSERY_FUNDING_DROPDOWN_LIST,
  NURSERY_FUNDING_DROPDOWN_LIST_FAILED,
  NURSERY_FUNDING_DROPDOWN_LIST_SUCCESS,
} from './constants'

export const dropdownList = (options: BasicActionOptionsProps) => ({
  nurseryFundingApiClient,
}) => generateBasicActions.list({
  constants: {
    failed: NURSERY_FUNDING_DROPDOWN_LIST_FAILED,
    init: NURSERY_FUNDING_DROPDOWN_LIST,
    success: NURSERY_FUNDING_DROPDOWN_LIST_SUCCESS,
  },
  options,
  service: nurseryFundingApiClient,
  serviceMethod: 'list',
})

export const clearDropdownList = () => ({
  type: CLEAR_NURSERY_FUNDING_DROPDOWN_LIST,
})
