import {
  ADD_NAPPY_TO_LIST,
  CLEAR_DAILY_DIARY_NAPPIES,
  GET_DAILY_DIARY_NAPPIES,
  GET_DAILY_DIARY_NAPPIES_FAILED,
  GET_DAILY_DIARY_NAPPIES_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ dailyDiaryNappiesApiClient }) => (dispatch) => {
  const { mergeResult, params, silent } = options

  dispatch({
    silent,
    type: GET_DAILY_DIARY_NAPPIES,
  })

  dailyDiaryNappiesApiClient.list(params).then(
    (response) => {
      dispatch({
        mergeResult,
        payload: response,
        type: GET_DAILY_DIARY_NAPPIES_SUCCESS,
      })
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: GET_DAILY_DIARY_NAPPIES_FAILED,
      })
    },
  )
}

export const addToList = (payload) => ({
  payload,
  type: ADD_NAPPY_TO_LIST,
})

export const clearList = () => ({
  type: CLEAR_DAILY_DIARY_NAPPIES,
})
