import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class UsersApiClient extends ServiceBase {
  get = (id, params = {}) => this.request({
    path: `/v2/users/${id}?${getQueryStringV2(params)}`,
  })

  list = (params = {}) => this.request({
    path: `/v2/users?${getQueryStringV2(params)}`,
  })

  getUserSettings = (id) => this.request({
    path: `/v2/users/${id}/settings`,
  })

  updateUserSettings = (id, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/users/${id}/settings`,
  })
}

export default UsersApiClient
