import React, { PropsWithChildren } from 'react'

import colors from 'constants/colors'

import { Icon, Tooltip } from 'components'

import { StyledBlockedIcon, StyledContainer, StyledIcon } from './BlockedIconStyled'

const ICON_PROPORTION = 1.8

interface BlockedIconProps {
  color: string
  height?: number
  hide?: boolean
  icon: IconType
  title?: string
}

const BlockedIcon: React.FC<PropsWithChildren<BlockedIconProps>> = ({
  color,
  height = 16,
  hide,
  icon,
  title,
}) => {
  const size = height * ICON_PROPORTION

  return (
    <StyledContainer $size={size}>
      <Tooltip
        placement="bottom"
        title={title}
      >
        {!hide && (
          <StyledBlockedIcon $size={size}>
            <Icon
              color={colors.remove}
              height={size}
              icon="blocked"
            />
          </StyledBlockedIcon>
        )}
        <StyledIcon>
          <Icon
            color={color}
            height={height}
            icon={icon}
          />
        </StyledIcon>
      </Tooltip>
    </StyledContainer>
  )
}

export default BlockedIcon
