import { UPDATE_CHILD_MOVE, UPDATE_CHILD_MOVE_FAILED, UPDATE_CHILD_MOVE_SUCCESS } from './constants'

export const updateChildMove = (id, body, onSuccess, onFailed) => ({ childMovesApiClient }) => (dispatch) => {
  dispatch({
    type: UPDATE_CHILD_MOVE,
  })

  childMovesApiClient.update(id, body).then(
    (data) => {
      dispatch({
        body,
        payload: data,
        type: UPDATE_CHILD_MOVE_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => {
      dispatch({
        error: !onFailed ? response : null,
        type: UPDATE_CHILD_MOVE_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}
