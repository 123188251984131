import _ from 'lodash'
import { v4 } from 'uuid'

import React, { Component } from 'react'
import { compose } from 'recompose'

import { ALL_OPTIONS } from 'services/legacy/newsletters/constants'

import { hasMoreRecords } from 'utils/pagination'

import { withRoomsService } from 'services/legacy/rooms'

import { Select } from 'components'

import i18n from 'translations'

class GroupDropdown extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cacheUniq: v4(),
      hasMoreRooms: true,
      oldSearchedPhrase: null,
      page: 1,
    }
  }

  componentWillUnmount() {
    const { roomsActions } = this.props

    roomsActions.clear()
  }

  handleLoadMoreElements = async (phrase) => {
    const { limit, roomsActions, roomsSelectors } = this.props
    const { hasMoreRooms, oldSearchedPhrase, page } = this.state

    let newHasMoreRooms = hasMoreRooms
    let currentPage = page

    let roomResult = { options: [] }
    let allOptionResult = { options: [] }

    if (oldSearchedPhrase !== phrase) {
      roomsActions.clear()

      newHasMoreRooms = true
      currentPage = 1

      this.setState({ oldSearchedPhrase: phrase })
    }

    if (newHasMoreRooms) {
      await roomsActions.list({
        criteria: roomsSelectors.getCriteriaSelector({ phrase }),
        limit,
        page: currentPage,
      }, true, (response) => {
        const { data, meta } = response

        roomResult = {
          hasMore: hasMoreRecords(meta),
          options: [{
            label: i18n.t('global:Room'),
            options: _.map(data, ({ id, name }) => ({
              label: name,
              type: 'class',
              value: id,
            })),
          }],
        }

        this.setState({ cacheUniq: false, hasMoreRooms: roomResult.hasMore, page: currentPage + 1 })
      })
    }

    if (!newHasMoreRooms || !roomResult.hasMore) {
      allOptionResult = {
        options: [{
          label: i18n.t('module:Newsletters:Constants:allGroupOptionsHeader'),
          options: phrase ? _.filter(ALL_OPTIONS, ({ label }) => label.toLowerCase().includes(phrase)) : ALL_OPTIONS,
        }],
      }
    }

    return {
      hasMore: roomResult.hasMore,
      options: [...allOptionResult.options, ...roomResult.options],
    }
  }

  handleGroupOptions = (previousOptions, newOptions) => {
    const group = _.groupBy([...previousOptions, ...newOptions], 'label')

    return _.map(group, (value, label) => {
      const groupItemOptions = []

      _.forEach(value, ({ options }) => groupItemOptions.push(...options))

      return {
        label,
        options: groupItemOptions,
      }
    })
  }

  render() {
    const {
      input,
      meta,
      multi = true,
      onChange,
      placeholder,
      value,
    } = this.props
    const { onChange: inputOnChange, value: inputValue } = input || {}
    const { error, submitFailed, valid } = meta || {}
    const { cacheUniq } = this.state

    return (
      <Select
        cacheUniq={cacheUniq}
        error={submitFailed && error}
        loadOptions={this.handleLoadMoreElements}
        multi={multi}
        placeholder={placeholder || i18n.t('module:Newsletters:Common:groupDropdownPlaceholder')}
        reduceOptions={this.handleGroupOptions}
        valid={!(submitFailed && !valid)}
        value={value || inputValue}
        searchable
        onChange={onChange || inputOnChange}
      />
    )
  }
}

const enhance = compose(
  withRoomsService,
)

export default enhance(GroupDropdown)
