import React from 'react'

import { Callout, Section, Spinner } from 'components'

import i18n from 'translations'

import { FinancePaymentDetails } from './components'
import ChildFinanceDetailsInvoices from './ChildFinanceDetailsInvoices'

const ChildFinanceDetailsView = ({
  bankData,
  child,
  childAdhocSessionCount,
  childBacsDetails,
  childBankNotes,
  childCurrentSessions,
  childDiscounts,
  childExtraItems,
  childFundings,
  contactData,
  errorMessages,
  invoiceRecipients,
  isBankEditing,
  isCurrentPlanLoading,
  isFinanceV3Enabled,
  isLoading,
  isUkCountry,
  onSubmitNotes,
  onViewPlanDetailClick,
}) => {
  if (isLoading) {
    return <Spinner />
  }

  const { firstName, id } = child || {}

  return (
    <Section>
      <Callout content={errorMessages} error />
      <Section title={i18n.t('module:Children:Child:Finance:Invoicing:title')}>
        <FinancePaymentDetails
          bankData={bankData}
          childAdhocSessionCount={childAdhocSessionCount}
          childBacsDetails={childBacsDetails}
          childBankNotes={childBankNotes}
          childCurrentSessions={childCurrentSessions}
          childDiscounts={childDiscounts}
          childExtraItems={childExtraItems}
          childFirstName={firstName}
          childFundings={childFundings}
          childId={id}
          contactData={contactData}
          invoiceRecipients={invoiceRecipients}
          isBankEditing={isBankEditing}
          isCurrentPlanLoading={isCurrentPlanLoading}
          isFinanceV3Enabled={isFinanceV3Enabled}
          isUkCountry={isUkCountry}
          onSubmitNotes={onSubmitNotes}
          onViewPlanDetailClick={onViewPlanDetailClick}
        />
      </Section>
      <ChildFinanceDetailsInvoices />
    </Section>
  )
}

export default ChildFinanceDetailsView
