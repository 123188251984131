import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledFormRow = styled.div`
  display: grid;
  grid-template-columns: 20px auto 30px;
  grid-column-gap: 15px;
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 2px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  
  &:last-of-type {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`
