import React from 'react'

import { generateRoute } from 'utils/routing'

import { Callout, DefinitionList, Page, Section, Spinner } from 'components'

import { ChildFundingAllocatedWidget, ChildFundingSessionPanel } from '../components'

const ChildFundingItemView = ({
  allocationData,
  childId,
  errorMessages,
  isLoading,
  previewData,
  sessionSets,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    const {
      fundingType,
      hourlyRate,
      id,
      period,
      total,
      totalHours,
    } = previewData || {}

    const {
      amountUsed: widgetAmountUsed,
      hoursUsed: widgetHourUsed,
      total: widgetTotalAmount,
      totalHours: widgetTotalHours,
    } = allocationData || {}

    return (
      <React.Fragment>
        <Section
          actions={(
            <Section.Actions
              tertiary={{
                to: generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.FUNDING.EDIT', {
                  childFundingId: id,
                  childId,
                }),
              }}
            />
          )}
          title="Funding Allocation"
        >
          <DefinitionList margin="0 0 30px 0" vertical>
            <DefinitionList.Item
              value={(
                <ChildFundingAllocatedWidget
                  amountUsed={widgetAmountUsed}
                  hoursUsed={widgetHourUsed}
                  totalAmount={widgetTotalAmount}
                  totalHours={widgetTotalHours}
                />
              )}
              label
            />
            <DefinitionList.Item label="Funding type" value={fundingType} />
            <DefinitionList.Item label="Period" value={period} />
            <DefinitionList.Item label="Hourly rate" value={hourlyRate} />
            <DefinitionList.Item label="Total hours" value={totalHours} />
            <DefinitionList.Item label="Total funding value" value={total} />
          </DefinitionList>
        </Section>
        {sessionSets && sessionSets.length && (
          <Section
            actions={(
              <Section.Actions
                tertiary={{
                  to: generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.FUNDING.ALLOCATION', {
                    childFundingId: id,
                    childId,
                  }),
                }}
              />
            )}
            title="Allocation to sessions"
          >
            <ChildFundingSessionPanel
              sessionSets={sessionSets}
            />
          </Section>
        )}
      </React.Fragment>
    )
  }

  return (
    <Page isLoading={isLoading}>
      <Page.Content>
        <Callout content={errorMessages} error />
        {renderContent()}
      </Page.Content>
    </Page>
  )
}

export default ChildFundingItemView
