import React, { PropsWithChildren } from 'react'

import NurseryHeader from './NurseryHeader'
import PoweredByBlossomFooter from './PoweredByBlossomFooter'

interface ExternalPageRoot extends React.FC<PropsWithChildren<any>> {
  NurseryHeader?: typeof NurseryHeader
  PoweredByBlossomFooter?: typeof PoweredByBlossomFooter
}

const ExternalPage: ExternalPageRoot = ({ children }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <React.Fragment>
    {children}
  </React.Fragment>
)

export default ExternalPage
