import { flatten } from 'utils/flatnest'

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { ROLES } from 'constants/security'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withPaginationUtils } from 'services/utils/pagination'

import ChildProfileHistoryView from './ChildProfileHistoryView'

const GROUPS = {
  read: [
    'childLog',
    'childLog.ethnicity',
    'ethnicity',
  ],
}

const ChildProfileHistoryContainer = ({
  childActions,
  childLogs,
  drinkingMethods,
  errorMessages,
  isFetching,
  maritalStatuses,
  paginationUtils,
  params,
  setLocationQuery,
  toiletTrainingOptions,
  totalResults,
}) => {
  const { childId } = params
  const { onPageChange, setPageLocationQuery } = paginationUtils

  const [filters, setFilters] = useState({
    madeBy: null,
    section: null,
  })

  useEffect(() => {
    setPageLocationQuery(false)

    return () => {
      childActions.clearChildChangelogs()
    }
  }, [])

  const fetch = () => {
    const { madeBy, section } = filters
    const criteria = []

    if (madeBy?.value || madeBy) {
      criteria.push({
        field: 'type',
        value: madeBy?.value || madeBy,
      })
    }

    if (section?.value || section) {
      criteria.push({
        field: 'sections',
        value: section?.value || section,
      })
    }

    setLocationQuery(flatten({
      madeBy: madeBy?.value || madeBy,
      sections: section?.value || section,
    }))

    childActions.getChildChangelogs({
      mergeResult: 1 !== filters.page,
      params: [childId, {
        criteria,
        groups: GROUPS,
        page: filters.page,
      }],
    })
  }

  useEffect(fetch, [filters])
  useEffect(fetch, [childId])

  const handlePageChange = (page) => (
    onPageChange(fetch)(page)
  )

  const handleChangeMadeBy = (madeBy) => (
    onPageChange(setFilters((prev) => ({ ...prev, madeBy })))(1)
  )

  const handleChangeSection = (section) => (
    onPageChange(setFilters((prev) => ({ ...prev, section })))(1)
  )

  const { getPageCount, page } = paginationUtils
  const pageCount = getPageCount(totalResults)

  return (
    <ChildProfileHistoryView
      childLogs={childLogs}
      drinkingMethods={drinkingMethods}
      errorMessages={errorMessages}
      filters={filters}
      isFetching={isFetching}
      maritalStatuses={maritalStatuses}
      page={page}
      pageCount={pageCount}
      toiletTrainingOptions={toiletTrainingOptions}
      totalResults={totalResults}
      onChangeMadeBy={handleChangeMadeBy}
      onChangeSection={handleChangeSection}
      onPageChange={handlePageChange}
    />
  )
}

ChildProfileHistoryContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.DEPUTY_MANAGER,
    ROLES.ROOM_LEADER,
    ROLES.SENIOR_TEACHER,
    ROLES.TEACHER,
  ],
}

const mapState = (state, { appSelectors, childChangelogsState, childSelectors }) => ({
  childLogs: childSelectors.getChildChangeLogsSelector(state),
  drinkingMethods: appSelectors.getDrinkingMethods(state),
  errorMessages: appSelectors.getErrorMessages(childChangelogsState),
  isFetching: appSelectors.getIsFetching(childChangelogsState),
  maritalStatuses: appSelectors.getMaritalStatuses(state),
  toiletTrainingOptions: appSelectors.getToiletTrainingOptions(state),
  totalResults: appSelectors.getTotalResults(childChangelogsState),
})

const enhance = compose(
  withAppService,
  withChildService,
  withPaginationUtils,
  connect(mapState),
)

export default enhance(ChildProfileHistoryContainer)
