import _ from 'lodash'

import React from 'react'

import { SIGNATURE_TYPES } from 'services/legacy/injuries/constants'

import SignatureList from '../SignatureList'
import SignatureDigitalFormStaffSignatures from './SignatureDigitalFormStaffSignatures'
import SignatureDigitalFormParentSignature from './SignatureDigitalFormParentSignature'

const SignatureDigitalForm = ({
  disableSignature,
  injurySignaturesPreview,
  isHomeAccidents,
  onAddCarerClick,
  onAddSignatureClick,
  onAddStaffMemberClick,
  onChangeCarer,
  onChangeOtherCarer,
  onChangeStaffMember,
}) => {
  const { injurySignatureStatistics, signatures } = injurySignaturesPreview || {}

  const staffSignatures = _.filter(signatures, ({ type: itemType }) => itemType !== SIGNATURE_TYPES.PARENT)
  const parentSignatures = _.filter(signatures, ({ type: itemType }) => itemType === SIGNATURE_TYPES.PARENT)

  return (
    <SignatureList>
      <SignatureDigitalFormStaffSignatures
        disableSignature={disableSignature}
        injurySignatureStatistics={injurySignatureStatistics}
        isHomeAccidents={isHomeAccidents}
        staffSignatures={staffSignatures}
        onAddSignatureClick={onAddSignatureClick}
        onAddStaffMemberClick={onAddStaffMemberClick}
        onChangeStaffMember={onChangeStaffMember}
      />
      <SignatureDigitalFormParentSignature
        disableSignature={disableSignature}
        isHomeAccidents={isHomeAccidents}
        parentSignatures={parentSignatures}
        onAddCarerClick={onAddCarerClick}
        onAddSignatureClick={onAddSignatureClick}
        onChangeCarer={onChangeCarer}
        onChangeOtherCarer={onChangeOtherCarer}
      />
    </SignatureList>
  )
}

export default SignatureDigitalForm
