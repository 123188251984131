import _ from 'lodash'
import moment from 'moment'

import { toFloat, toInt } from 'utils/data'
import { hoursAndMinutesToFloat } from 'utils/date'

import { CHILD_AGES_DROPDOWN, CHILD_FUNDING_FILTERS } from './constants'

const getFormattedPerWeekValues = (perWeekValues) => {
  const { hours, minutes } = perWeekValues

  return {
    hours: toFloat(hours) || 0,
    minutes: toFloat(minutes) || 0,
  }
}

export const getPayload = (fields, childId) => {
  if (!fields) {
    return null
  }

  const {
    fundingType,
    hourlyRate,
    period: [startDate, endDate],
    totalHours,
    total,
    settings,
    ...otherFields
  } = fields
  const { type, value } = fundingType
  const { hoursPerWeek, maxHoursPerDay } = settings || {}
  const { hours, minutes } = totalHours || {}

  return {
    ...otherFields,
    child: { id: toInt(childId) },
    endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
    funding: { id: value, type },
    hourlyRate: toFloat(hourlyRate) || 0,
    period: undefined,
    settings: {
      ...settings,
      hoursPerWeek: hoursPerWeek ? getFormattedPerWeekValues(hoursPerWeek) : undefined,
      maxHoursPerDay: maxHoursPerDay ? getFormattedPerWeekValues(maxHoursPerDay) : undefined,
    },
    startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
    total: toFloat(total) || 0,
    totalHours: hoursAndMinutesToFloat(hours, minutes),
    type,
  }
}

export const getCriteria = (filters = {}, childId) => {
  if (!filters) {
    return null
  }

  const {
    age,
    child,
    endDate,
    fundingTypes,
    room,
    startDate,
    status,
    type,
  } = filters
  const ageRanges = _.find(CHILD_AGES_DROPDOWN, (e) => e.id === age)

  const criteria = []

  if (status && status !== CHILD_FUNDING_FILTERS.ALL) {
    criteria.push({
      field: 'archived',
      value: status === CHILD_FUNDING_FILTERS.ARCHIVED ? 1 : 0,
    })
  }

  if (room) {
    criteria.push({
      field: 'child.nurseryClass',
      value: room,
    })
  }

  if (ageRanges && ageRanges.value) {
    const { from, to } = ageRanges.value

    if (from) {
      criteria.push({
        comparator: 'before',
        field: 'child.birthDate',
        value: moment().add(-from, 'months').format('YYYY-MM-DD'),
      })
    }

    if (to) {
      criteria.push({
        comparator: 'after',
        field: 'child.birthDate',
        value: moment().add(-to, 'months').add(-1, 'day').format('YYYY-MM-DD'),
      })
    }
  }

  if (child || childId) {
    criteria.push({
      field: 'child',
      value: child || childId,
    })
  }

  if (fundingTypes && fundingTypes.length) {
    _.forEach(fundingTypes, (fundingType) => {
      criteria.push({
        field: 'funding[]',
        value: fundingType,
      })
    })
  }

  if (startDate) {
    criteria.push({
      comparator: 'after',
      field: 'endDate',
      value: startDate,
    })
  }

  if (endDate) {
    criteria.push({
      comparator: 'before',
      field: 'startDate',
      value: endDate,
    })
  }

  if (type) {
    criteria.push({
      field: 'type',
      value: type,
    })
  }

  return criteria
}

export const updateChildFundingDateFieldError = (errors) => {
  if (!errors || !(errors.startDate || errors.endDate || errors.totalHours)) {
    return errors
  }

  const { endDate, startDate, totalHours } = errors

  return {
    ...errors,
    invoicePeriod: [
      startDate ? `Start date: ${startDate} ` : null,
      endDate ? `End date: ${endDate}` : null,
    ],
    totalHours: {
      hours: totalHours,
    },
  }
}
