import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { change, getFormValues, stopSubmit } from 'redux-form'

import { getBackendErrors } from 'utils/backendErrors'
import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withContractsService } from 'services/legacy/contracts'
import { withMembershipsService } from 'services/legacy/memberships'
import { withModalService } from 'services/utils/modal'
import { withRouter } from 'services/router'

import i18n from 'translations'

import withStaffHoc from 'module/Staff/withStaffHoc'

import StaffContractsAddView from './StaffContractsAddView'
import { STAFF_CONTRACTS_FORM } from './components/StaffContractsAddForm'

const GROUPS = {
  read: [
    'contract.breakAllowances',
    'breakAllowance',
  ],
}

class StaffContractsAddContainer extends Component {
  constructor(props) {
    super(props)

    const {
      location: { pathname },
      params: { userId },
    } = props

    this.state = {
      isCreatingContext: pathname === generateRoute('STAFF.PROFILE.CONTRACTS.ADD', { userId })
        || pathname === generateRoute('ACCOUNT.CONTRACTS.ADD'),
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { contractsActions } = this.props

    contractsActions.clear()
  }

  fetch = () => {
    const {
      contractsActions,
      params: { contractId },
    } = this.props
    const { isCreatingContext } = this.state

    if (!isCreatingContext) {
      return contractsActions.get(contractId, {
        params: { groups: GROUPS },
      })
    }

    return null
  }

  handleSubmitFailed = (response) => {
    const { injectValidation } = this.props

    const errors = getBackendErrors(response || {})

    if (!errors) {
      return false
    }

    return injectValidation(errors)
  }

  handleSubmitSuccess = ({ data }) => {
    const {
      isMyDetailsContext,
      navigate,
      params: { userId },
    } = this.props

    return navigate(isMyDetailsContext
      ? generateRoute('ACCOUNT.CONTRACTS.PREVIEW', { contractId: data.id })
      : generateRoute('STAFF.PROFILE.CONTRACTS.PREVIEW', { contractId: data.id, userId }))
  }

  handleSubmit = (fields) => {
    const {
      contractsActions,
      contractsSelectors,
      membership,
      params: { contractId, userId },
    } = this.props
    const { isCreatingContext } = this.state

    if (isCreatingContext) {
      const payload = contractsSelectors.getPayload(fields, userId || membership?.id)

      return contractsActions.create({
        onFailed: this.handleSubmitFailed,
        onSuccess: this.handleSubmitSuccess,
        payload,
      })
    }

    const payload = contractsSelectors.getPayload(fields)

    return contractsActions.update(contractId, {
      onFailed: this.handleSubmitFailed,
      onSuccess: this.handleSubmitSuccess,
      payload,
    })
  }

  handleRemoveContract = () => {
    const {
      contractsActions,
      isMyDetailsContext,
      navigate,
      params: { contractId, userId },
    } = this.props

    contractsActions.remove(contractId, {
      onSuccess: () => navigate(isMyDetailsContext
        ? generateRoute('ACCOUNT.CONTRACTS')
        : generateRoute('STAFF.PROFILE.CONTRACTS', { userId })),
    })
  }

  handleRemoveClick = () => {
    const {
      hideModal,
      modalActions,
      modalConsts,
    } = this.props

    return modalActions.show(modalConsts.TYPES.CONFIRM, {
      cancelButtonLabel: i18n.t('global:Cancel'),
      confirmButtonLabel: i18n.t('global:Delete'),
      content: null,
      hideModal,
      icon: 'trash',
      onConfirm: this.handleRemoveContract,
      text: i18n.t('module:Staff:StaffContracts:Edit:removeContractConfirmation'),
    })
  }

  handleOngoingClick = (value) => {
    const { changeFieldValue } = this.props

    if (value) {
      changeFieldValue('endDate', null)
    }
  }

  render() {
    const {
      contractsSelectors,
      errorMessages,
      formValues,
      initialValues,
      isFetching,
      isMyDetailsContext,
      isSubmitting,
      params: { userId },
    } = this.props
    const {
      isCreatingContext,
    } = this.state

    const salaryPerMonth = contractsSelectors.getSalaryPerMonth(formValues?.salary)
    const cancelLink = isMyDetailsContext
      ? generateRoute('ACCOUNT.CONTRACTS')
      : generateRoute('STAFF.PROFILE.CONTRACTS', { userId })

    return (
      <StaffContractsAddView
        cancelLink={cancelLink}
        errorMessages={errorMessages}
        formValues={formValues}
        initialValues={initialValues}
        isCreatingContext={isCreatingContext}
        isFetching={isFetching}
        isSubmitting={isSubmitting}
        salaryPerMonth={salaryPerMonth}
        onOngoingClick={this.handleOngoingClick}
        onRemoveClick={this.handleRemoveClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  contractsSelectors,
  contractsSingleState,
  membershipsSelectors,
}) => ({
  data: contractsSelectors.getContractDataSelector(state),
  errorMessages: appSelectors.getErrorMessages(contractsSingleState),
  formValues: getFormValues(STAFF_CONTRACTS_FORM)(state),
  initialValues: contractsSelectors.getInitialValues(state),
  isFetching: appSelectors.getIsFetching(contractsSingleState),
  isSubmitting: appSelectors.getIsSubmitting(contractsSingleState),
  membership: membershipsSelectors.getMembershipDataSelector(state),
})

const mapDispatch = {
  changeFieldValue: (field, value) => change(STAFF_CONTRACTS_FORM, field, value),
  injectValidation: (errors) => stopSubmit(STAFF_CONTRACTS_FORM, errors),
}

const enhance = compose(
  withAppService,
  withContractsService,
  withMembershipsService,
  withModalService,
  withRouter,
  withStaffHoc,
  connect(mapState, mapDispatch),
)

export default enhance(StaffContractsAddContainer)
