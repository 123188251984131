import React from 'react'

import { Icon } from 'components'

import { StyledBadgeContainer } from './RegisterItemProfileStyled'
import { ABSENCE_TYPE, REGISTER_COLORS } from '../../../constants'

const RegisterItemProfileBadge = ({ absence, hasExtraSessions }) => {
  if (!absence && !hasExtraSessions) {
    return null
  }

  const getBadgeObject = () => {
    if (absence && absence.absenceType) {
      if (absence.absenceType === ABSENCE_TYPE.ABSENCE) {
        return {
          color: REGISTER_COLORS.absentBadge,
          icon: 'thermometer',
          initials: '',
        }
      }

      return {
        color: REGISTER_COLORS.holidayBadge,
        icon: 'sun',
        initials: '',
      }
    }

    return {
      color: REGISTER_COLORS.extraBadge,
      icon: '',
      initials: 'E',
    }
  }

  const iconObject = getBadgeObject()

  return (
    <StyledBadgeContainer color={iconObject.color}>
      {iconObject.icon ? <Icon color="#fff" height={18} icon={iconObject.icon} /> : iconObject.initials}
    </StyledBadgeContainer>
  )
}

export default RegisterItemProfileBadge
