import React, { Component } from 'react'
import { compose } from 'recompose'
import { stopSubmit } from 'redux-form'
import { connect } from 'react-redux'

import { getBackendErrors } from 'utils/backendErrors'

import { withModalService } from 'services/utils/modal'

import FileDetailsModalView from './FileDetailsModalView'
import { FILE_DETAILS_FORM } from './components/FileDetailsModalForm'

class FileDetailsModalContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isProcessing: false,
    }
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSubmitCallback = (response) => {
    const { injectValidation } = this.props

    this.setState({ isProcessing: false })

    if (null === response) {
      return this.handleCloseClick()
    }

    const errors = getBackendErrors(response)

    return injectValidation(errors)
  }

  handleSubmit = (values) => {
    const { onSubmit } = this.props

    this.setState({ isProcessing: true })

    onSubmit(this.handleSubmitCallback, values)
  }

  render() {
    const { initialValues } = this.props
    const { isProcessing } = this.state

    return (
      <FileDetailsModalView
        initialValues={initialValues}
        isProcessing={isProcessing}
        onCloseClick={this.handleCloseClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  injectValidation: (errors) => stopSubmit(FILE_DETAILS_FORM, errors),
}

const enhance = compose(
  withModalService,
  connect(null, mapDispatch),
)

export default enhance(FileDetailsModalContainer)
