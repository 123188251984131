import styled from 'styled-components'

import { StyledContainer } from 'components/Avatar/AvatarStyled'

export const StyledChildContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(6, 1fr);
  
  ${StyledContainer} {
    grid-template-rows: 55px 60px;
    align-items: flex-start;
  }
  
  @media(max-width: 1000px) {
    grid-template-columns: repeat(5, 1fr);
  }
  
  @media(max-width: 820px) {
    grid-template-columns: repeat(4, 1fr);
  }
  
  @media(max-width: 670px) {
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media(max-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media(max-width: 400px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const StyledChildRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
