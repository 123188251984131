import React from 'react'

import { Chart } from 'components'

import { StyledContainer } from './AgedDebtReportChartStyled'

const AgedDebtReportChart = ({ ageDebtChartData, onChartPointClick }) => {
  const { categories, chartData } = ageDebtChartData || {}

  return (
    <StyledContainer>
      <Chart.Column
        categories={categories}
        data={chartData}
        isCurrencyValue
        onChartPointClick={onChartPointClick}
      />
    </StyledContainer>
  )
}

export default AgedDebtReportChart
