import React from 'react'

import { FLAG_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { Callout, Hyperlink, Typography } from 'components'

import i18n from 'translations'

import BoxWrapper from '../components/BoxWrapper'
import SetPasswordForm from '../components/SetPasswordForm'
import ResetPasswordForm from './components/ResetPasswordForm'

interface ResetPasswordViewProps {
  errorMessages: string[]
  isFetching: boolean
  isParentAppLink: boolean
  isParentPasswordChangeSuccess: boolean
  isParentPasswordResetSuccess: boolean
  onChangePassword: (values: any) => void
  onResetPassword: (values: any) => void
  showChangePasswordForm: boolean
}

const ResetPasswordView: React.FC<ResetPasswordViewProps> = ({
  errorMessages,
  isFetching,
  isParentAppLink,
  isParentPasswordChangeSuccess,
  isParentPasswordResetSuccess,
  onChangePassword,
  onResetPassword,
  showChangePasswordForm,
}) => {
  const renderForm = () => {
    if (isParentPasswordResetSuccess || isParentPasswordChangeSuccess) {
      return (
        <Callout
          color={FLAG_COLOURS.INFO}
          content={(
            <Typography padding="5px">
              <Typography fontSize={15} bold>
                {i18n.t('module:Authentication:ChangePassword:youHaveSetANewPassword')}
              </Typography>
              <br />
              <Typography>
                {i18n.t('module:Authentication:ChangePassword:youHaveSetANewPasswordContent')}
              </Typography>
            </Typography>
          )}
        />
      )
    }

    if (showChangePasswordForm) {
      const submitLabel = isParentAppLink
        ? i18n.t('module:Authentication:ChangePassword:changePassword')
        : i18n.t('module:Authentication:ChangePassword:setNewPassword')

      return (
        <SetPasswordForm
          isFetching={isFetching}
          submitLabel={submitLabel}
          onSubmit={onChangePassword}
        />
      )
    }

    return (
      <ResetPasswordForm
        isFetching={isFetching}
        onSubmit={onResetPassword}
      />
    )
  }

  return (
    <BoxWrapper
      footer={!isParentPasswordChangeSuccess && (
        <Hyperlink to={`/${generateRoute('AUTHENTICATION.LOGIN')}`} primary>
          {i18n.t('module:Authentication:ResetPassword:signInWithYourPassword')}
        </Hyperlink>
      )}
    >
      <Callout
        content={errorMessages}
        margin="15px 0 -15px"
        error
      />
      {renderForm()}
    </BoxWrapper>
  )
}

export default ResetPasswordView
