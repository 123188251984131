import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledAlignCenterDiv = styled.div`
  display: flex;
  align-items: center;
`

export const StyledResponse = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const StyledPendingStatusDot = styled.div`
  min-height: 16px;
  min-width: 16px;
  background-color: ${NEUTRAL_COLOURS.BLUE};
  border-radius: 50%;
  margin-right: 10px;
`
