import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import { CLEAR_FRAMEWORKS, LIST_FRAMEWORKS, LIST_FRAMEWORKS_FAILED, LIST_FRAMEWORKS_SUCCESS } from './constants'

const initialState = getBasicListInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_FRAMEWORKS,
    failed: LIST_FRAMEWORKS_FAILED,
    init: LIST_FRAMEWORKS,
    success: LIST_FRAMEWORKS_SUCCESS,
  }, initialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
