import _ from 'lodash'
import moment from 'moment'

import React from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'
import { Field, FieldArray, InjectedFormProps, change, formValueSelector, reduxForm } from 'redux-form'

import { TYPES } from 'components/DropdownMenu/constants'

import { isRequired, isStartDateBeforeEndDate } from 'utils/fieldValidation'

import { Banner, Button, Callout, Form, Section, Typography } from 'components'

import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

import i18n from 'translations'

import { AddViewProps } from '../AddView'
import { CONSUMABLE_CHARGEABLE_OPTIONS, SESSION_TYPES, SESSION_TYPE_OPTIONS, SessionAddFormValues } from './models'
import {
  StyledFeesContainer,
  StyledFeesContentContainer,
  StyledFeesItemContainer,
  StyledFeesWrapperContainer,
  StyledTimePickerContainer,
} from './AddSessionFormStyled'

export const ADD_NURSERY_SESSION_FORM = 'AddNurserySessionForm'

const renderConsumableRow = (item, i, fields, consumables, isFixedSessionTypeSelected) => {
  const consumableRawValue = fields.get(i)?.consumable

  return (
    <Form.Row key={i} margin="0 0 5px" verticalLabel>
      <Form.Row.FlexItem flex="0 0 180px" margin="0 15px 0 0">
        <Field
          component={Form.Select}
          name={`${item}.consumable`}
          options={consumables}
          placeholder={i18n.t('module:Management:Finance:SessionsV3:Add:Consumables:Form:Consumable:placeholder')}
          rawValue={consumableRawValue}
          validate={isRequired}
        />
      </Form.Row.FlexItem>
      {!isFixedSessionTypeSelected && (
        <Form.Row.FlexItem flex="0 0 100px" margin="0 5px 0 0">
          <Field
            component={Form.Select}
            name={`${item}.chargeable`}
            options={CONSUMABLE_CHARGEABLE_OPTIONS}
            placeholder={i18n.t('module:Management:Finance:SessionsV3:Add:Consumables:Form:Chargeable:placeholder')}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      )}
      <Form.Row.FlexItem margin="0 0 5px">
        <Form.RemoveRowButton
          trash
          onClick={() => fields.remove(i)}
        />
      </Form.Row.FlexItem>
    </Form.Row>
  )
}

const renderConsumables = ({ consumables, fields, isFixedSessionTypeSelected }) => (
  <React.Fragment>
    <Form.Row margin="0">
      {fields.length ? (
        <Form.Row.FlexItem flex="0 0 180px" margin="0 15px 5px 0">
          <Typography fontSize={14} lineHeight={30}>
            {i18n.t('module:Management:Finance:SessionsV3:Add:Consumables:Form:Consumable:label')}
          </Typography>
        </Form.Row.FlexItem>
      ) : null}
      {!isFixedSessionTypeSelected && (
        <Form.Row.FlexItem flex="0 0 100px" margin="0 5px 5px 0">
          <Typography fontSize={14} lineHeight={30}>
            {i18n.t('module:Management:Finance:SessionsV3:Add:Consumables:Form:Chargeable:label')}
          </Typography>
        </Form.Row.FlexItem>
      )}
    </Form.Row>
    {fields.map((item, i) => renderConsumableRow(item, i, fields, consumables, isFixedSessionTypeSelected))}
    <Button.ActionButton
      label={i18n.t('module:Management:Finance:SessionsV3:Add:Consumables:addButtonLabel')}
      onClick={() => fields.push()}
    />
  </React.Fragment>
)

const renderFeesRow = ({
  fields,
  i,
  item,
  nurseryConsumablesExtraItems,
  onFeesStartDateChange,
  productPriceGroups,
}) => (
  <StyledFeesContentContainer>
    {1 < fields.length && (
      <React.Fragment>
        <StyledFeesItemContainer>
          <Field
            component={Form.DatePicker}
            disabled={0 === i}
            name={`${item}.startDate`}
            placeholder={0 === i ? '-' : _.upperFirst(i18n.t('global:startDate'))}
            onChange={onFeesStartDateChange(i)}
          />
        </StyledFeesItemContainer>
        <StyledFeesItemContainer>
          <Field
            component={Form.DatePicker}
            name={`${item}.endDate`}
            placeholder={i === fields.length - 1
              ? _.upperFirst(i18n.t('global:ongoing'))
              : _.upperFirst(i18n.t('global:endDate'))}
            disabled
          />
        </StyledFeesItemContainer>
      </React.Fragment>
    )}
    <SubdomainCurrencyProvider>
      {({ currencySymbol }) => (
        _.map(productPriceGroups, ({ id }) => (
          <StyledFeesItemContainer key={`${item}.priceGroups-${id}`}>
            <Field
              component={Form.TextField}
              name={`${item}.priceGroups-${id}.value`}
              placeholder={i18n.t('module:Management:Finance:SessionsV3:Add:Fees:Form:PriceGroups.placeholder')}
              prefix={currencySymbol}
              prefixWidth="30px"
              type="number"
              validate={isRequired}
            />
          </StyledFeesItemContainer>
        ))
      )}
    </SubdomainCurrencyProvider>
    {nurseryConsumablesExtraItems?.length
      ? _.map(nurseryConsumablesExtraItems, (consumableItem) => {
        const { consumable } = consumableItem || {}

        if (!consumable) {
          return null
        }

        return (
          <SubdomainCurrencyProvider>
            {({ currencySymbol }) => (
              <StyledFeesItemContainer key={`${item}.consumable-${consumable.value}`}>
                <Field
                  component={Form.TextField}
                  name={`${item}.consumable-${consumable.value}.value`}
                  placeholder={i18n.t('module:Management:Finance:SessionsV3:Add:Fees:Form:PriceGroups.placeholder')}
                  prefix={currencySymbol}
                  prefixWidth="30px"
                  type="number"
                  validate={isRequired}
                />
              </StyledFeesItemContainer>
            )}
          </SubdomainCurrencyProvider>
        )
      })
      : undefined}
    <StyledFeesItemContainer>
      <Form.RemoveRowButton
        disabled={0 === i}
        trash
        onClick={() => fields.remove(i)}
      />
    </StyledFeesItemContainer>
  </StyledFeesContentContainer>
)

const renderFees = ({
  fields,
  nurseryConsumablesExtraItems,
  onAddFee,
  onFeesStartDateChange,
  productPriceGroups,
}) => (
  <React.Fragment>
    <StyledFeesWrapperContainer>
      <StyledFeesContainer>
        <StyledFeesContentContainer>
          {1 < fields.length && (
            <React.Fragment>
              <StyledFeesItemContainer>
                <Typography fontSize={14} lineHeight={30} minWidth={170}>
                  {_.upperFirst(i18n.t('global:startDate'))}
                </Typography>
              </StyledFeesItemContainer>
              <StyledFeesItemContainer>
                <Typography fontSize={14} lineHeight={30} minWidth={170}>
                  {_.upperFirst(i18n.t('global:endDate'))}
                </Typography>
              </StyledFeesItemContainer>
            </React.Fragment>
          )}
          {_.map(productPriceGroups, ({ endAge, startAge }) => (
            <StyledFeesItemContainer>
              <Typography fontSize={14} lineHeight={30} minWidth={130}>
                {!endAge && startAge
                  ? i18n.t('module:Management:Finance:SessionsV3:Add:Fees:Form:PriceGroups.labelEnd', { startAge })
                  : i18n.t('module:Management:Finance:SessionsV3:Add:Fees:Form:PriceGroups.label', {
                    endAge,
                    startAge,
                  })}
              </Typography>
            </StyledFeesItemContainer>
          ))}
          {nurseryConsumablesExtraItems?.length
            ? _.map(nurseryConsumablesExtraItems, (consumableItem) => {
              const { consumable } = consumableItem || {}

              if (!consumable) {
                return null
              }

              return (
                <StyledFeesItemContainer key={consumable?.value}>
                  <Typography fontSize={14} lineHeight={30} minWidth={130}>
                    {consumable?.label}
                  </Typography>
                </StyledFeesItemContainer>
              )
            })
            : undefined}
        </StyledFeesContentContainer>
        {fields.map((item, i) => renderFeesRow({
          fields,
          i,
          item,
          nurseryConsumablesExtraItems,
          onFeesStartDateChange,
          productPriceGroups,
        }))}
      </StyledFeesContainer>
    </StyledFeesWrapperContainer>
    <Button.ActionButton
      label={i18n.t('module:Management:Finance:SessionsV3:Add:Fees:addButtonLabel')}
      onClick={onAddFee}
    />
  </React.Fragment>
)

const mapState = (state) => ({
  nurseryConsumablesExtraItems: formValueSelector(ADD_NURSERY_SESSION_FORM)(state, 'nurseryConsumablesExtraItems'),
  sessionType: formValueSelector(ADD_NURSERY_SESSION_FORM)(state, 'sessionType'),
})

const mapDispatch = {
  changeValue: (field, value) => change(ADD_NURSERY_SESSION_FORM, field, value),
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type AddSessionFormFullProps = InjectedFormProps<{} & AddViewProps> & AddViewProps & PropsFromRedux

const AddSessionForm: React.FC<AddSessionFormFullProps> = ({
  // FinanceAutomationGranted,
  cancelLink,
  changeValue,
  consumables,
  error,
  handleSubmit,
  isArchived,
  isEdit,
  isFormLoading,
  isInheritedFromOrganization,
  nurseryConsumablesExtraItems,
  onAddFee,
  onArchiveClick,
  onSubmit,
  productPriceGroups,
  sessionType,
}) => {
  const title = isEdit
    ? i18n.t('module:Management:Finance:SessionsV3:Add:editTitle')
    : i18n.t('module:Management:Finance:SessionsV3:Add:addTitle')
  const actions = isEdit && !isInheritedFromOrganization && (
    <Section.Actions
      options={[{
        onClick: onArchiveClick(!isArchived),
        type: isArchived ? TYPES.UNARCHIVE : TYPES.ARCHIVE,
      }]}
    />
  )

  const isFixedSessionTypeSelected = sessionType === SESSION_TYPES.FIXED_SESSION_TIME

  const handleFeesStartDateChange = (index) => (date) => {
    changeValue(`nurseryFees[${index - 1}].endDate`, moment(date).add(-1, 'day'))
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Callout content={error} error />
      <Section actions={actions} title={title}>
        {isInheritedFromOrganization
          && (
            <Form.Row>
              <Banner.Info>
                {i18n.t('module:Management:Finance:SessionsV3:Add:organizationInheritBanner1')}
                {i18n.t('module:Management:Finance:SessionsV3:Add:organizationInheritBanner2')}
              </Banner.Info>
            </Form.Row>
          )}
        <Form.Row
          label={i18n.t('module:Management:Finance:SessionsV3:Add:Form:SessionName:label')}
          width={{ field: '320px' }}
          verticalLabel
        >
          <Field
            component={Form.TextField}
            disabled={isInheritedFromOrganization}
            name="name"
            placeholder={i18n.t('module:Management:Finance:SessionsV3:Add:Form:SessionName:placeholder')}
            validate={isRequired}
          />
        </Form.Row>
        <Form.Row
          label={i18n.t('module:Management:Finance:SessionsV3:Add:Form:SessionType:label')}
          margin="0 0 10px"
          width={{ field: '100%' }}
          verticalLabel
        >
          <Form.Row.Item>
            <Field
              component={Form.RadioGroup}
              disabled={isEdit}
              name="sessionType"
              options={SESSION_TYPE_OPTIONS}
              validate={isRequired}
              buttonStyle
              horizontal
            />
          </Form.Row.Item>
        </Form.Row>
        {isFixedSessionTypeSelected && (
          <React.Fragment>
            <Form.Row margin="0 0 5px">
              <StyledTimePickerContainer>
                <Typography fontSize={14} lineHeight={30} variant="div">
                  {i18n.t('module:Management:Finance:SessionsV3:Add:Form:StartTime:label')}
                </Typography>
                <Typography fontSize={14} lineHeight={30} variant="div">
                  {i18n.t('module:Management:Finance:SessionsV3:Add:Form:FinishTime:label')}
                </Typography>
              </StyledTimePickerContainer>
            </Form.Row>
            <Form.Row>
              <StyledTimePickerContainer>
                <Field
                  component={Form.TimePicker}
                  disabled={isInheritedFromOrganization}
                  local={false}
                  name="startTime"
                  validate={isRequired}
                />
                <Field
                  component={Form.TimePicker}
                  disabled={isInheritedFromOrganization}
                  local={false}
                  name="endTime"
                  validate={isRequired}
                />
              </StyledTimePickerContainer>
            </Form.Row>
          </React.Fragment>
        )}
        {isEdit && (
          <Banner.Info>
            {i18n.t('module:Management:Finance:SessionsV3:Add:Form:Banner:label')}
          </Banner.Info>
        )}
      </Section>
      <Section title={i18n.t('module:Management:Finance:SessionsV3:Add:Consumables:title')}>
        {isInheritedFromOrganization ? (
          <Typography margin="20px 0">
            {i18n.t('module:Management:Finance:SessionsV3:Add:Consumables:organizationInheritBanner')}
          </Typography>
        ) : (
          <FieldArray
            component={renderConsumables}
            name="nurseryConsumablesExtraItems"
            props={{ consumables, isFixedSessionTypeSelected }}
          />
        )}
      </Section>
      <Section title={i18n.t('module:Management:Finance:SessionsV3:Add:Fees:title')}>
        <FieldArray
          component={renderFees}
          name="nurseryFees"
          props={{
            nurseryConsumablesExtraItems,
            onAddFee,
            onFeesStartDateChange: handleFeesStartDateChange,
            productPriceGroups,
          }}
        />
      </Section>
      <Form.FooterActions
        cancelLink={cancelLink}
        isSubmitting={isFormLoading}
        submitDisabled={isInheritedFromOrganization}
        submitLabel={isEdit ? i18n.t('global:Update') : i18n.t('global:Save')}
      />
    </Form>
  )
}

const validate = (fields: SessionAddFormValues) => {
  const errors = { endTime: null, nurseryConsumablesExtraItems: [], nurseryFees: [], startTime: null }

  if (fields?.nurseryFees?.length) {
    _.forEach(fields?.nurseryFees, (nurseryFee, index) => {
      if (0 !== index) {
        errors.nurseryFees[index] = {
          startDate: isRequired(nurseryFee?.startDate),
        }
      }

      errors.nurseryFees[index] = {
        startDate: isStartDateBeforeEndDate(nurseryFee.startDate, nurseryFee.endDate),
      }
    })
  }

  if (fields?.startTime && fields?.endTime && fields.endTime <= fields.startTime) {
    errors.endTime = i18n.t('utils:Validation:isEndTimeGreaterThanStartTime')
  }

  return errors
}

const enhance = compose(
  reduxForm<{}, AddViewProps>({ form: ADD_NURSERY_SESSION_FORM, validate }),
  connector,
)

export default enhance(AddSessionForm)
