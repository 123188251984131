import { generateBasicActions } from 'utils/service'

import {
  CLEAR_DEPOSITS_DROPDOWN_LIST,
  DROPDOWN_LIST_DEPOSITS,
  DROPDOWN_LIST_DEPOSITS_FAILED,
  DROPDOWN_LIST_DEPOSITS_SUCCESS,
} from './constants'

export const dropdownList = (options = {}) => ({ depositsApiClient }) => generateBasicActions.list({
  constants: {
    failed: DROPDOWN_LIST_DEPOSITS_FAILED,
    init: DROPDOWN_LIST_DEPOSITS,
    success: DROPDOWN_LIST_DEPOSITS_SUCCESS,
  },
  options,
  service: depositsApiClient,
  serviceMethod: 'list',
})

export const clearDropdownList = () => ({
  type: CLEAR_DEPOSITS_DROPDOWN_LIST,
})
