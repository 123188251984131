import { generateBasicActions } from 'utils/service'

import {
  CLEAR_PUBLIC_ENQUIRY_SOURCE_DROPDOWN_LIST,
  DROPDOWN_LIST_PUBLIC_ENQUIRY_SOURCE,
  DROPDOWN_LIST_PUBLIC_ENQUIRY_SOURCE_FAILED,
  DROPDOWN_LIST_PUBLIC_ENQUIRY_SOURCE_SUCCESS,
} from './constants'

export const dropdownListPublicEnquirySource = (options = {}) => ({
  enquirySettingsApiClient,
}) => generateBasicActions.list({
  constants: {
    failed: DROPDOWN_LIST_PUBLIC_ENQUIRY_SOURCE_FAILED,
    init: DROPDOWN_LIST_PUBLIC_ENQUIRY_SOURCE,
    success: DROPDOWN_LIST_PUBLIC_ENQUIRY_SOURCE_SUCCESS,
  },
  options,
  service: enquirySettingsApiClient,
  serviceMethod: 'listPublicEnquirySource',
})

export const clearDropdownListPublicEnquirySource = () => ({
  type: CLEAR_PUBLIC_ENQUIRY_SOURCE_DROPDOWN_LIST,
})
