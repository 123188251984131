import {
  CLEAR_LEAVE_STATISTICS,
  GET_LEAVE_STATISTICS,
  GET_LEAVE_STATISTICS_FAILED,
  GET_LEAVE_STATISTICS_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  isFetching: false,
}

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_LEAVE_STATISTICS:
      return { ...state, isFetching: true }
    case GET_LEAVE_STATISTICS_SUCCESS:
      return {
        ...initialState,
        data: payload,
        isFetching: false,
      }
    case GET_LEAVE_STATISTICS_FAILED:
      return { ...state, isFetching: false }
    case CLEAR_LEAVE_STATISTICS:
      return initialState
    default:
      return state
  }
}
