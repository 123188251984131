import { compose } from 'recompose'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { stopSubmit } from 'redux-form'

import { NEWS_STATUSES } from 'services/legacy/newsletters/constants'

import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withNewslettersService } from 'services/legacy/newsletters'

import AddNewRecipientsModalView from './AddNewRecipientsModalView'
import { ADD_NEW_RECIPIENTS_FORM } from './components/AddNewRecipientsModalForm'

const NEWSLETTERS_SINGLE_GROUPS = {
  read: ['newsletter.filters', 'newsletter.media', 'newsletter.author', 'user'],
}

class AddNewRecipientsModalContainer extends Component {
  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSubmitSuccess = () => {
    const { hideModal, onSuccess } = this.props

    hideModal()
    if (onSuccess) {
      onSuccess()
    }
  }

  handleSubmitFailed = (response) => {
    const { injectValidation } = this.props
    const errors = getBackendErrors(response)
    if (!errors) {
      return false
    }

    return injectValidation(ADD_NEW_RECIPIENTS_FORM, errors)
  }

  handleSubmit = (fields) => {
    const { news, newsActions, newsId, newsSelectors } = this.props
    const { status } = news

    const body = newsSelectors.getSingleNewsPayload({
      ...fields,
      publish: status === NEWS_STATUSES.PUBLISHED,
    })

    return newsActions.update(
      newsId,
      body,
      { groups: NEWSLETTERS_SINGLE_GROUPS },
      this.handleSubmitSuccess,
      this.handleSubmitFailed,
    )
  }

  render() {
    const {
      errorMessages,
      isSubmitting,
      news,
    } = this.props

    return (
      <AddNewRecipientsModalView
        errorMessages={errorMessages}
        isSubmitting={isSubmitting}
        news={news}
        onCloseClick={this.handleCloseClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  injectValidation: (formName, errors) => stopSubmit(formName, errors),
}

const mapState = (state, { appSelectors, newsSelectors, newsSingleState }) => ({
  errorMessages: appSelectors.getErrorMessages(newsSingleState),
  isSubmitting: appSelectors.getIsSubmitting(newsSingleState),
  news: newsSelectors.getSingleNewsData(state),
})

const enhance = compose(withAppService, withNewslettersService, connect(mapState, mapDispatch))

export default enhance(AddNewRecipientsModalContainer)
