import _ from 'lodash'
import moment from 'moment'

import React from 'react'
import { createSelector } from 'reselect'

import { toFloat, toInt } from 'utils/data'

import constants, { STATUS_TYPES } from '../constants'

const FIELD_MAP = {
  dueDate: 'Due date',
  endDate: 'End date',
  invoicePeriod: 'Invoice period',
  issueDate: 'Issue date',
  name: 'Invoice name',
  number: 'Invoice number',
  startDate: 'Start date',
}

const DATE_FORMAT = 'YYYY-MM-DD'

const ERROR_FORM_FIELDS = [
  'dueDate',
  'endDate',
  'issueDate',
  'number',
  'name',
  'startDate',
]

export const getInvoiceSingle = (state) => state.invoices.single.data
export const getInvoiceSingleError = (state) => state.invoices.single.error

export const getAutoCalculateBodySelector = createSelector(
  [(filters) => filters],
  (filters) => {
    if (!filters) {
      return null
    }

    const { endDate, startDate } = filters

    const body = {}

    if (startDate) {
      body.startDate = moment(startDate).startOf('day').format(DATE_FORMAT)
    }

    if (endDate) {
      body.endDate = moment(endDate).endOf('day').format(DATE_FORMAT)
    }

    return body
  },
)

export const getDueDate = createSelector(
  [(values) => values],
  (values) => {
    if (!values) {
      return null
    }

    const { dueDays, issueDate } = values

    return moment(issueDate).add('d', dueDays).toDate()
  },
)

export const getPayload = createSelector(
  [(invoice) => invoice],
  ({ childId, fields, markAsSent, sendInvoice }) => {
    if (!fields) {
      return null
    }

    const { dueDate, instructions, invoicePeriod, issueDate, items, name, number, status } = fields

    return {
      child: childId ? { id: toInt(childId) } : null,
      dueDate: dueDate ? moment(dueDate).format(DATE_FORMAT) : null,
      endDate: invoicePeriod && 2 === invoicePeriod.length && invoicePeriod[1]
        ? moment(invoicePeriod[1]).format(DATE_FORMAT)
        : null,
      instructions,
      issueDate: issueDate ? moment(issueDate).format(DATE_FORMAT) : null,
      items,
      markAsSent,
      name,
      number: sendInvoice || markAsSent || (status && status !== STATUS_TYPES.DRAFT) ? number : null,
      sendInvoice,
      startDate: invoicePeriod && 2 === invoicePeriod.length && invoicePeriod[0]
        ? moment(invoicePeriod[0]).format(DATE_FORMAT)
        : null,
    }
  },
)

export const getSendInvoiceText = createSelector(
  [(value) => value],
  ({ firstName, isDraft }) => {
    if (isDraft) {
      return 'Are you sure you want to save and send this invoice? '
        .concat(`Note: this invoice will be sent to all invoice recipients for ${firstName}.`)
    }

    return 'Are you sure you want to resend this invoice? '
      .concat(`Note: this invoice will be sent to all invoice recipients for ${firstName}.`)
  },
)

export const getBadDebtPayload = createSelector(
  [(values) => values],
  ({ fields, unmark }) => {
    if (unmark) {
      return {
        status: constants.STATUS_TYPES.SENT,
      }
    }

    const { badDebtDate, badDebtReason } = fields

    return {
      badDebtDate: badDebtDate ? moment(badDebtDate).format(DATE_FORMAT) : null,
      badDebtReason,
      status: constants.STATUS_TYPES.BAD_DEBT,
    }
  },
)

export const isFundingDeficit = (itemType) => {
  if (!itemType) {
    return null
  }

  const { AUTO_CALCULATION_ITEM_TYPE } = constants
  const { value } = itemType

  return value === AUTO_CALCULATION_ITEM_TYPE.FUNDING_DEFICIT
}

export const isExtraSessionSelected = (itemType) => {
  if (!itemType) {
    return null
  }

  const { AUTO_CALCULATION_ITEM_TYPE, LINE_ITEM_TYPES } = constants
  const { value } = itemType

  return value === LINE_ITEM_TYPES.EXTRA_SESSION || value === AUTO_CALCULATION_ITEM_TYPE.EXTRA_SESSIONS
}

export const isExtraItemSelected = (itemType) => {
  if (!itemType) {
    return null
  }

  const { AUTO_CALCULATION_ITEM_TYPE, LINE_ITEM_TYPES } = constants
  const { value } = itemType

  return value === LINE_ITEM_TYPES.EXTRA_ITEM || value === AUTO_CALCULATION_ITEM_TYPE.EXTRA_ITEMS
}

export const isFundingSelected = (itemType) => {
  if (!itemType) {
    return null
  }

  const { AUTO_CALCULATION_ITEM_TYPE, LINE_ITEM_TYPES } = constants
  const { value } = itemType

  return (
    value === LINE_ITEM_TYPES.FUNDING
    || value === AUTO_CALCULATION_ITEM_TYPE.FUNDING
    || value === AUTO_CALCULATION_ITEM_TYPE.FUNDING_DEFICIT
    || value === AUTO_CALCULATION_ITEM_TYPE.FUNDING_NO_DEDUCT
    || value === AUTO_CALCULATION_ITEM_TYPE.OMIT_FUNDING_DEDUCT
  )
}

export const isDepositSelected = (itemType) => {
  if (!itemType) {
    return null
  }

  const { AUTO_CALCULATION_ITEM_TYPE, LINE_ITEM_TYPES } = constants
  const { value } = itemType

  return value === LINE_ITEM_TYPES.DEPOSIT || value === AUTO_CALCULATION_ITEM_TYPE.DEPOSIT
}

export const isRefundDepositSelected = (itemType) => {
  if (!itemType) {
    return null
  }

  const { AUTO_CALCULATION_ITEM_TYPE, LINE_ITEM_TYPES } = constants
  const { value } = itemType

  return value === LINE_ITEM_TYPES.REFUND_DEPOSIT || value === AUTO_CALCULATION_ITEM_TYPE.REFUND_DEPOSIT
}

export const isLegacyItemSelected = (itemType) => {
  if (!itemType) {
    return null
  }

  const { AUTO_CALCULATION_ITEM_TYPE } = constants
  const { value } = itemType

  return (
    value === AUTO_CALCULATION_ITEM_TYPE.LEGACY_FUNDING
    || value === AUTO_CALCULATION_ITEM_TYPE.LEGACY_EXTRA_SESSION
    || value === AUTO_CALCULATION_ITEM_TYPE.LEGACY_EXTRA_ITEM
  )
}

export const getLineItemOptions = ({
  isDepositEnabled,
  isFinanceAutomationEnabled,
}) => {
  const { LINE_ITEM_OPTIONS, LINE_ITEM_TYPES } = constants

  const skipTypes = []

  if (!isFinanceAutomationEnabled) {
    skipTypes.push(LINE_ITEM_TYPES.EXTRA_ITEM)
    skipTypes.push(LINE_ITEM_TYPES.FUNDING)
  }

  if (!isDepositEnabled) {
    skipTypes.push(LINE_ITEM_TYPES.DEPOSIT)
    skipTypes.push(LINE_ITEM_TYPES.REFUND_DEPOSIT)
  }

  if (!skipTypes.length) {
    return LINE_ITEM_OPTIONS
  }

  return _.filter(LINE_ITEM_OPTIONS, (item) => (
    !skipTypes.includes(item.value)
  ))
}

export const getLineItemOptionsV3 = ({
  isDepositEnabled,
  isFinanceAutomationEnabled,
  readOnly,
}) => {
  const { LINE_ITEM_OPTIONS_V3, LINE_ITEM_TYPES, REGULAR_LINE_ITEM_OPTIONS_V3 } = constants

  let allItems = [...LINE_ITEM_OPTIONS_V3]

  if (readOnly) {
    allItems = LINE_ITEM_OPTIONS_V3.concat(REGULAR_LINE_ITEM_OPTIONS_V3)
  }

  const skipTypes = []

  if (!isFinanceAutomationEnabled) {
    skipTypes.push(LINE_ITEM_TYPES.EXTRA_ITEM)
    skipTypes.push(LINE_ITEM_TYPES.FUNDING)
  }

  if (!isDepositEnabled) {
    skipTypes.push(LINE_ITEM_TYPES.DEPOSIT)
    skipTypes.push(LINE_ITEM_TYPES.REFUND_DEPOSIT)
  }

  if (!skipTypes.length) {
    return allItems
  }

  return _.filter(allItems, (item) => (
    !skipTypes.includes(item.value)
  ))
}

export const getFundingAllocationValues = (funding = {}, quantity = 0, total = 0, initialValues = {}) => {
  const { amountUsed = 0, hoursUsed = 0, total: totalAmount, totalHours } = funding
  const { id, quantity: initialQuantity, total: initialTotal } = initialValues

  const currentAmountUsed = (amountUsed + (toFloat(total) || 0)) - (id ? initialTotal : 0)
  const currentHoursUsed = (hoursUsed + (toInt(quantity) || 0)) - (id ? initialQuantity : 0)

  return {
    amountUsed: currentAmountUsed,
    hoursUsed: currentHoursUsed,
    totalAmount,
    totalHours,
  }
}

export const isResponseConflict = (error) => error.response
    && error.response.code
    && (
      error.response.code === constants.ERROR_CODES.MISSING_FIELDS_VALIDATION
      || error.response.code === constants.ERROR_CODES.MISSING_PAYMENT_CONTACT
    )

export const getErrorMessages = createSelector(
  [getInvoiceSingleError],
  (error) => {
    if (!error) {
      return null
    }

    if (!error.extra) {
      return error.message
    }

    const filteredExtraErrors = _.omitBy(error.extra, (value, key) => ERROR_FORM_FIELDS.includes(key))

    return _.map(filteredExtraErrors, (value) => value)
  },
)

export const createErrorText = createSelector(
  [(values) => values],
  ({ code, extra }) => {
    if (constants.ERROR_CODES.MISSING_PAYMENT_CONTACT === code) {
      return (
        <div>
          <p>
            {'Your invoice has been saved. However, we could not find a primary email address to send the invoice to. '
              .concat('Please check the primary contact for this account.')}
          </p>
        </div>
      )
    }

    if (constants.ERROR_CODES.MISSING_FIELDS_VALIDATION === code) {
      const errorFields = _.map(extra, (value, key) => {
        const label = FIELD_MAP[key]

        return (
          <div key={key}>
            {`${label || key}: ${value}`}
          </div>
        )
      })

      return (
        <div>
          <p>
            This invoice has missing fields or invalid value. Please enter the following fields to send the invoice:
          </p>
          {errorFields}
        </div>
      )
    }

    return (
      <div>
        <p>
          Something went wrong, please contact the customer support.
        </p>
      </div>
    )
  },
)
