import { STATUS_OPTIONS_VALUES } from 'services/users/constants'

export const getUsersListCriteria = (params) => {
  const { search, status } = params
  const criteria = []

  if (search) {
    criteria.push({
      field: 'memberships.firstName',
      value: search,
    })
  }

  if (STATUS_OPTIONS_VALUES.ACTIVE === status) {
    criteria.push({
      field: 'isDisabled',
      value: 0,
    })

    criteria.push({
      field: 'membershipIsAccepted',
      value: 1,
    })
  }

  if (STATUS_OPTIONS_VALUES.DISABLED === status) {
    criteria.push({
      field: 'isDisabled',
      value: 1,
    })

    criteria.push({
      field: 'membershipIsAccepted',
      value: 1,
    })
  }

  if (STATUS_OPTIONS_VALUES.PENDING === status) {
    criteria.push({
      field: 'isDisabled',
      value: 1,
    })

    criteria.push({
      field: 'membershipIsAccepted',
      value: 0,
    })
  }

  return criteria
}
