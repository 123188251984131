import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import * as actions from './actions'
import * as selectors from './selectors'

const withGenerateInvoicesService = (WrappedComponent) => {
  const mapState = ({ generateInvoices }) => ({ generateInvoices })

  const mapDispatch = injectActions('generateInvoicesActions', actions)

  const Component = (props) => {
    const { generateInvoices, generateInvoicesActions, ...other } = props

    const generateInvoicesState = generateInvoices
    const generateInvoicesSelectors = selectors

    return (
      <WrappedComponent
        generateInvoicesActions={generateInvoicesActions}
        generateInvoicesSelectors={generateInvoicesSelectors}
        generateInvoicesState={generateInvoicesState}
        {...other}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withGenerateInvoicesService
