import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'

const getNurseryIntegrationsCurrenciesState = (state: RootState) => state.nurseryIntegrations.currencies

export const getNurseryIntegrationsCurrenciesList = createSelector(
  [getNurseryIntegrationsCurrenciesState],
  ({ data }) => data,
)
