import _ from 'lodash'

import {
  ADD_UPLOAD_FILE,
  CLEAR_UPLOADED_FILES,
  MARK_FILE_TO_REMOVE,
  MARK_UPLOADED_FILE_TO_REMOVE,
  UPDATE_REFERENCE_PAGES_STORAGE,
  UPDATE_UPLOAD_FILE,
} from './constants'
import { UPLOAD_DIRECTORY } from '../constants'

export const addFile = (file) => ({
  payload: file,
  type: ADD_UPLOAD_FILE,
})

export const updateUploadFile = (id, changes) => ({
  payload: { changes, id },
  type: UPDATE_UPLOAD_FILE,
})

export const updateReferencePage = (payload) => ({
  payload,
  type: UPDATE_REFERENCE_PAGES_STORAGE,
})

export const markFileToRemove = (payload) => ({
  payload,
  type: MARK_FILE_TO_REMOVE,
})

export const markUploadedFileToRemove = (payload) => ({
  payload,
  type: MARK_UPLOADED_FILE_TO_REMOVE,
})

export const clearUploadedFiles = () => ({
  type: CLEAR_UPLOADED_FILES,
})

export const uploadFile = (
  file,
  fileName = file.name,
  directory = UPLOAD_DIRECTORY.IMAGES,
  publicUrl = true,
) => ({ uploadApiClient }) => async () => {
  const bucketRequest = {
    directory,
    file_name: fileName, // eslint-disable-line camelcase
    file_type: file.mimeType || file.type, // eslint-disable-line camelcase
  }

  const { data } = await uploadApiClient.getUploadConfig(bucketRequest)
  const { config: { bucket: configBucket, endpoint }, payload, public_url: url } = data

  const s3endpoint = `https://${configBucket}.${endpoint}`
  const payloadUri = `${s3endpoint}/${payload.key}`
  const body = new FormData()

  _.each(payload, (value, key) => {
    body.append(key, value)
  })

  body.append('file', file)

  await window.fetch(s3endpoint, {
    body,
    method: 'POST',
  })

  if (publicUrl) {
    return url // eslint-disable-line camelcase
  }

  return payloadUri
}
