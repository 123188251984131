import _ from 'lodash'
import { flatten, nest } from 'utils/flatnest'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { FEATURE_FLAGS_OPTIONS } from 'services/legacy/featureFlags/constants'

import { withAppService } from 'services/app'
import { withOrganizationService } from 'services/organization'
import { withPaginationUtils } from 'services/utils/pagination'

import OrganisationsListView from './OrganisationsListView'
import { STATUS_OPTIONS } from './constants'

const GROUPS = { read: ['organization.numberOfNurseries', 'organization.details'] }

class OrganisationsListContainer extends Component {
  constructor(props) {
    super(props)

    const {
      location: { query },
      paginationUtils: { setPageLocationQuery },
    } = props

    const { featureFlags, search, status } = nest(query)

    this.state = {
      filters: {
        featureFlags,
        search,
        status: status || STATUS_OPTIONS[1].value,
      },
    }

    setPageLocationQuery(false)
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { organizationActions } = this.props

    organizationActions.clearList()
  }

  fetch = () => {
    const {
      organizationActions,
      organizationSelectors,
      paginationUtils: { page },
      setLocationQuery,
    } = this.props
    const { filters } = this.state

    setLocationQuery(flatten(filters))

    const apiParams = {
      criteria: organizationSelectors.getCriteria(filters),
      groups: GROUPS,
      page,
    }

    organizationActions.list({
      mergeResult: 1 !== page,
      params: apiParams,
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils: { onPageChange } } = this.props

    onPageChange(this.fetch)(page)
  }

  handleStatusChange = (status) => {
    const { paginationUtils: { onPageChange } } = this.props

    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        status: status.value,
      },
    }), onPageChange(this.fetch)(1))
  }

  handleFeatureFlagsChange = (featureFlags) => {
    const { paginationUtils: { onPageChange } } = this.props

    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        featureFlags: _.map(featureFlags, (flag) => flag.value),
      },
    }), onPageChange(this.fetch)(1))
  }

  handleSearchChange = (search) => {
    const { paginationUtils: { onPageChange } } = this.props

    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        search,
      },
    }), () => onPageChange(this.fetch)(1))
  }

  render() {
    const {
      isFetching,
      organisations,
      paginationUtils: { getPageCount, page },
      totalResults,
    } = this.props
    const { filters } = this.state

    const pageCount = getPageCount(totalResults)

    return (
      <OrganisationsListView
        featureFlagsOptions={FEATURE_FLAGS_OPTIONS}
        filters={filters}
        isFetching={isFetching}
        organisations={organisations}
        page={page}
        pageCount={pageCount}
        statusOptions={STATUS_OPTIONS}
        onFeatureFlagsChange={this.handleFeatureFlagsChange}
        onPageChange={this.handlePageChange}
        onSearchChange={this.handleSearchChange}
        onStatusChange={this.handleStatusChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  organizationListState,
  organizationSelectors,
}) => ({
  isFetching: appSelectors.getIsFetching(organizationListState),
  organisations: organizationSelectors.getOrganizationListTableSelector(state),
  totalResults: appSelectors.getTotalResults(organizationListState),
})

const enhance = compose(
  withAppService,
  withOrganizationService,
  withPaginationUtils,
  connect(mapState),
)

export default enhance(OrganisationsListContainer)
