import React from 'react'

import { Callout, Page, Spinner } from 'components'

import i18n from 'translations'

import StaffContractsPreviewDetailsBreakdown from './components/StaffContractsPreviewDetailsBreakdown'

const StaffContractsPreviewView = ({
  errorMessages,
  isFetching,
  ...rest
}) => {
  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    return (
      <StaffContractsPreviewDetailsBreakdown
        {...rest}
      />
    )
  }

  return (
    <Page.Section
      isLoading={isFetching}
      title={i18n.t('module:Staff:StaffContracts:Preview:title')}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default StaffContractsPreviewView
