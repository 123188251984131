import {
  CLEAR_PIN_LOGIN,
  CREATE_PIN,
  CREATE_PIN_FAILED,
  CREATE_PIN_SUCCESS,
  PIN_LOGIN,
  PIN_LOGIN_FAILED,
  PIN_LOGIN_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case PIN_LOGIN:
    case CREATE_PIN:
      return { ...state, error: initialState.error, isFetching: true }
    case PIN_LOGIN_SUCCESS:
      return { ...state, data: payload, isFetching: false }
    case PIN_LOGIN_FAILED:
      return { ...state, error, isFetching: false }
    case CREATE_PIN_SUCCESS:
    case CREATE_PIN_FAILED:
      return { ...state, isFetching: false }
    case CLEAR_PIN_LOGIN:
      return initialState
    default:
      return state
  }
}
