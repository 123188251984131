import React from 'react'

import { StyledItemContainer } from './ChildSessionsGridStyled'

const ChildSessionsGridItem = ({ children }) => (
  <StyledItemContainer>
    {children}
  </StyledItemContainer>
)

export default ChildSessionsGridItem
