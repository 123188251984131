import _ from 'lodash'
import { flatten } from 'utils/flatnest'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { Currency, ReportView, Typography } from 'components'

import i18n from 'translations'

import { NURSERY_TABLE_COLUMNS, ORGANIZATION_TABLE_COLUMNS } from './constants'

export const getColumns = (isOrganizationContext) => {
  if (isOrganizationContext) {
    return ORGANIZATION_TABLE_COLUMNS
  }

  return NURSERY_TABLE_COLUMNS
}

const getNurseryLink = (nursery, appProperties, queryString) => (
  <ReportView.NurseryLink
    nursery={nursery}
    queryString={queryString}
    routeKey="FINANCE.REPORT.BALANCE"
  />
)

export const getTableData = ({ appProperties, data, filters, hasError, isOrganizationContext }) => {
  if (!data?.length || hasError) {
    return null
  }

  const queryString = new URLSearchParams(flatten({
    date: filters?.date ? {
      after: filters.date.after.format(DEFAULT_DATE_FORMAT),
      before: filters.date.before.format(DEFAULT_DATE_FORMAT),
    } : undefined,
  })).toString()

  return _.map(data, ({
    balanceAdjustments,
    closingBalance,
    creditNotes,
    invoices,
    openingBalance,
    payments,
    resource,
  }) => ({
    balanceAdjustments: <Currency value={balanceAdjustments} />,
    closingBalance: <Currency value={closingBalance} />,
    creditNotes: <Currency value={creditNotes} />,
    invoices: <Currency value={invoices} />,
    name: isOrganizationContext
      ? getNurseryLink(resource, appProperties, queryString)
      : (
        <Typography wordBreak="break-word">
          {resource.name}
        </Typography>
      ),
    openingBalance: <Currency value={openingBalance} />,
    payments: <Currency value={payments} />,
  }))
}

export const getStatistics = ({ data, hasError, isOrganizationContext }) => {
  if (!data || !isOrganizationContext || hasError) {
    return null
  }

  return [
    {
      label: i18n.t('module:Finance:Reports:Balances:Table:Header:openingBalance'),
      value: <Currency value={data.openingBalance} />,
    },
    {
      label: i18n.t('module:Finance:Reports:Balances:Table:Header:invoices'),
      value: <Currency value={data.invoices} />,
    },
    {
      label: i18n.t('module:Finance:Reports:Balances:Table:Header:creditNotes'),
      value: <Currency value={data.creditNotes} />,
    },
    {
      label: i18n.t('module:Finance:Reports:Balances:Table:Header:balanceAdjustments'),
      value: <Currency value={data.balanceAdjustments} />,
    },
    {
      label: i18n.t('module:Finance:Reports:Balances:Table:Header:payments'),
      value: <Currency value={data.payments} />,
    },
    {
      label: i18n.t('module:Finance:Reports:Balances:Table:Header:closingBalance'),
      value: <Currency value={data.closingBalance} />,
    },
  ]
}
