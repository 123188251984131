import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { ALL_ROOMS } from 'services/legacy/rooms/constants'

import { isRequired } from 'utils/fieldValidation'

import { Banner, Form } from 'components'

import i18n from 'translations'

import { StyledContent, StyledForm } from './DownloadRegisterModalFormStyled'

export const DOWNLOAD_REGISTER_FORM = 'DownloadRegisterForm'

const ALL_SESSIONS = {
  label: i18n.t('module:Modals:DownloadRegister:allSessions'),
  value: 0,
}

const DownloadRegisterModalForm = ({
  handleSubmit,
  isFinanceV3Enabled,
  isProcessing,
  onCloseClick,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <StyledForm>
      <StyledContent>
        <Form.Row
          label={i18n.t('module:Modals:DownloadRegister:roomsLabel')}
          verticalLabel
        >
          <Form.Row.FlexItem flex="0 0 220px" mobileFull>
            <Field
              component={Form.InfiniteDropdowns.Rooms}
              extraOptions={[ALL_ROOMS]}
              name="room"
              placeholder={i18n.t('global:Room')}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row
          label={i18n.t('module:Modals:DownloadRegister:sessionsLabel')}
          verticalLabel
        >
          <Form.Row.FlexItem flex="0 0 420px" mobileFull>
            {isFinanceV3Enabled ? (
              <Field
                component={Form.InfiniteDropdowns.NurserySessionsV3}
                extraOptions={[ALL_SESSIONS]}
                name="session"
                placeholder={i18n.t('global:Session')}
              />
            ) : (
              <Field
                component={Form.InfiniteDropdowns.NurserySessions}
                extraOptions={[ALL_SESSIONS]}
                name="session"
                placeholder={i18n.t('global:Session')}
              />
            )}
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row
          label={i18n.t('module:Modals:DownloadRegister:dateRangeLabel')}
          verticalLabel
        >
          <Field
            component={Form.DatePicker}
            name="entryDate"
            type="week"
            validate={isRequired}
            clearable
            range
          />
        </Form.Row>
        <Form.Row verticalLabel>
          <Form.Row.FlexItem mobileFull>
            <Field
              component={Form.Checkbox}
              label={i18n.t('module:Modals:DownloadRegister:additionalRowsLabel')}
              name="multipleRows"
              type="checkbox"
            />
            <Form.Row.FlexItem mobileFull>
              <Field
                component={Form.Checkbox}
                label={i18n.t('module:Modals:DownloadRegister:includeCommentsLabel')}
                name="includeComments"
                type="checkbox"
              />
            </Form.Row.FlexItem>
          </Form.Row.FlexItem>
        </Form.Row>
        <Banner.Info>
          {i18n.t('module:Modals:DownloadRegister:banner')}
        </Banner.Info>
      </StyledContent>
      <Form.FooterActions
        isSubmitting={isProcessing}
        submitLabel={i18n.t('global:Download')}
        onCancelClick={onCloseClick}
      />
    </StyledForm>
  </Form>
)

export default reduxForm({ form: DOWNLOAD_REGISTER_FORM })(DownloadRegisterModalForm)
