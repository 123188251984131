import i18n from 'translations'

export const FEES_CALCULATION = {
  ACTUAL: 'ACTUAL',
  AVERAGE: 'AVERAGE',
}

export const NURSERY_EXTRA_ITEMS_FILTER = {
  ACTIVE: 'ACTIVE',
  ALL: 'ALL',
  ARCHIVED: 'ARCHIVED',
}

export const NURSERY_EXTRA_ITEMS_FILTER_OPTIONS = [
  { label: i18n.t('global:Active'), value: NURSERY_EXTRA_ITEMS_FILTER.ACTIVE },
  { label: i18n.t('global:All'), value: NURSERY_EXTRA_ITEMS_FILTER.ALL },
  { label: i18n.t('global:Archived'), value: NURSERY_EXTRA_ITEMS_FILTER.ARCHIVED },
]
