import 'polyfill'
import 'moment/locale/en-gb'

import React, { useEffect } from 'react'
import baseHistory from 'react-router/lib/browserHistory'
import { createRoot } from 'react-dom/client'
import { syncHistoryWithStore } from 'react-router-redux'

import { GET_ORGANISATION_SETTINGS_EVENT } from 'services/shell/constants'
import { GET_NURSERY_SUCCESS } from 'services/nurseries/single/constants'

import eventBus from 'utils/eventBus'
import { injectCustomBranding } from 'utils/branding'
import { generateRoute } from 'utils/routing'

import CookieIdentityService from 'core/services/CookieIdentityService'

import authenticationActions from 'services/authentication/actions'
import { getSubdomain } from 'services/subdomain/single/actions'
import * as subdomainSelectors from 'services/subdomain/selectors'

import { installSentry } from 'core/helpers/sentry'
import {
  configureSync,
  createStore,
  initializeMomentJS,
  iosPolyfill,
  loadMeData,
  loadNurseryData,
  setCookieIdentity,
  setDeviceIdentity,
  setHotReload,
} from 'core/helpers/global'

import { properties } from 'app-config'
import 'core/helpers/network'
import { GET_NURSERY_SETTINGS_SUCCESS } from 'services/nurseryContext/common/constants'

const SUBDOMAIN_GROUPS = {
  read: [
    'subdomain.details',
  ],
}

const preloaderElement = document.getElementById('preloader')
const tabTitleElement = document.getElementById('tabTitle')

if (module.hot) {
  module.hot.accept()
}

const bootstrapApplication = async () => {
  installSentry()
  initializeMomentJS()
  iosPolyfill()

  const { services, store } = await createStore()

  setHotReload(store)
  setDeviceIdentity(store)
  setCookieIdentity(store)

  const domainSuffix = subdomainSelectors.findDomainSuffix(subdomainSelectors.hostname)
  const subdomainName = subdomainSelectors.getEffectiveSubdomain(subdomainSelectors.hostname, domainSuffix)
  const history = syncHistoryWithStore(baseHistory, store)

  const bootstrap = ({ authProfile, authToken, customBranding, subdomain }: any) => {
    const App = require('./App').default

    return () => {
      const container = document.getElementById('root')
      const root = createRoot(container)

      if (!navigator.onLine) {
        // Only attendance page is supported in offline mode, therefore it is a redirect
        history.replace(generateRoute('REGISTER.INDEX'))
      }

      const AppBootstrap = () => {
        useEffect(() => {
          preloaderElement.setAttribute('hidden', '')
          tabTitleElement.innerText = customBranding ? customBranding.browserTitle : 'Blossom Educational'
        })

        return (
          <App
            authProfile={authProfile}
            authToken={authToken}
            history={history}
            store={store}
            subdomain={subdomain}
          />
        )
      }

      root.render(
        <AppBootstrap />,
      )
    }
  }

  if (CookieIdentityService.isIdentitySet(properties.identityKeyLegacy)) {
    window.location.reload()
    store.dispatch(authenticationActions.logout({ key: properties.identityKeyLegacy }))
  }

  const getSubdomainSuccess = async () => {
    const state = store.getState()
    const subdomain = subdomainSelectors.getSubdomainInfo(state)
    const customBranding = subdomainSelectors.getCustomBranding(state)

    configureSync(subdomain)
    injectCustomBranding(subdomainSelectors.subdomainIsBranded(store.getState()), customBranding)

    const existingIdentity = CookieIdentityService.getIdentity() || CookieIdentityService.getPinIdentity()

    if (existingIdentity) {
      // @ts-ignore
      const { token } = existingIdentity

      store.dispatch(authenticationActions.setIdentity({ token }))

      try {
        const profile = await loadMeData({ services, store, token })

        store.dispatch(authenticationActions.setIdentity({ profile }))

        if (profile?.nursery) {
          const nurserySingleResponse = await loadNurseryData({ nurseryId: profile?.nursery?.id, services, store })

          store.dispatch({ payload: nurserySingleResponse, type: GET_NURSERY_SUCCESS })
          store.dispatch({ payload: nurserySingleResponse, type: GET_NURSERY_SETTINGS_SUCCESS })
        }

        bootstrap({
          authProfile: profile,
          authToken: token,
          customBranding,
          subdomain,
        })()

        if (profile?.organization) {
          eventBus.dispatch(GET_ORGANISATION_SETTINGS_EVENT, profile.organization)
        }

        return false
      } catch (e) { }
    }

    return bootstrap({ customBranding, subdomain })()
  }

  const getSubdomainFailed = () => {
    const state = store.getState()
    const customBranding = subdomainSelectors.getCustomBranding(state)

    injectCustomBranding(subdomainSelectors.subdomainIsBranded(state), customBranding)
    bootstrap({ customBranding })()
  }

  if (!navigator.onLine) {
    return getSubdomainSuccess()
  }

  return getSubdomain(
    subdomainName,
    services,
    getSubdomainSuccess,
    getSubdomainFailed,
    { groups: SUBDOMAIN_GROUPS },
  )(store.dispatch)
}

try {
  bootstrapApplication().then()
} catch (err) {
  if ('production' === process.env.NODE_ENV) {
    preloaderElement.setAttribute('hidden', '')
  } else {
    throw err
  }
}
