import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledContainer = styled.div`
  padding: 40px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  @media(max-width: 520px) {
    h2 {
      font-size: 26px;
      margin-bottom: 15px;
    }
    
    h5 {
      font-size: 20px;
      margin-top: 10px;
    }
    
    h3 {
      font-size: 22px;
    }
  }
`

export const StyledLogo = styled.img`
  max-width: 240px;
  max-height: 100px;
  margin-bottom: 40px;
`

export const StyledLinkExpired = styled.div`
  margin-bottom: 55px;
  
  svg {
    width: 650px;
    height: 300px;
    
    *[id^='path310'],
    *[id^='path180'],
    *[id^='path172'],
    *[id^='path212'],
    *[id^='path204'],
    *[id^='path196'],
    *[id^='path220'],
    *[id^='path284'],
    *[id^='path236'],
    *[id^='path188'],
    *[id^='path326'] {
      fill: ${getBrandingColor('primary-color')} !important;
    }
    
    *[id^='path342'],
    *[id^='path358'] {
      fill: ${getBrandingColor('tertiary-color')} !important;
    }
    
    *[id^='path252'],
    *[id^='path220'] {
      stroke: ${getBrandingColor('primary-color')} !important;
    }
  }
  
  @media(max-width: 740px) {
    svg {
      width: 450px;
      height: 220px;
    }
  }
  
  @media(max-width: 520px) {
    svg {
      width: 280px;
      height: 100px;
    }
  }
`
