import ServiceBase from 'services/ServiceBase'

import { QueryPropsV2 } from 'constants/service'

import { getQueryStringV2 } from 'utils/api'

class NurseriesApiClient extends ServiceBase {
  create = (payload, params: QueryPropsV2) => this.request({
    body: payload,
    method: 'POST',
    path: `/v2/nurseries?${getQueryStringV2(params)}`,
  })

  exportGroupRevenue = (params: QueryPropsV2) => this.request({
    path: `/v2/nurseries/report/group-revenue?${getQueryStringV2(params)}`,
  })

  get = (id, params: QueryPropsV2, headers = {}) => this.request({
    headers,
    parseBigint: true,
    path: `/v2/nurseries/${id}?${getQueryStringV2(params)}`,
  })

  listFlags = (id: string, params: QueryPropsV2) => this.request({
    path: `/v2/nurseries/${id}/flags?${getQueryStringV2(params)}`,
  })

  list = (params: QueryPropsV2) => this.request({
    path: `/v2/nurseries?${getQueryStringV2(params)}`,
  })

  sendForecastReport = (payload) => this.request({
    body: payload,
    method: 'POST',
    path: '/v2/export/report/forecasting',
  })

  statistics = (params: QueryPropsV2) => this.request({
    path: `/v2/statistics/nursery?${getQueryStringV2(params)}`,
  })

  update = (id: string, payload, params: QueryPropsV2) => this.request({
    body: payload,
    method: 'PUT',
    path: `/v2/nurseries/${id}?${getQueryStringV2(params)}`,
  })

  updateFlag = (id: string, flag, payload, params: QueryPropsV2) => this.request({
    body: payload,
    method: 'PUT',
    path: `/v2/nurseries/${id}/flags/${flag}?${getQueryStringV2(params)}`,
  })

  updateNewFinanceMigration = (id: string, params: QueryPropsV2, customSubdomain, body) => this.request({
    body,
    customSubdomain,
    method: 'PUT',
    path: `/v2/nurseries/${id}/migrate_finance?${getQueryStringV2(params)}`,
  })

  updateChildCodeReferenceSettings = (id: string, params: QueryPropsV2, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/nurseries/${id}/child-code-reference-settings?${getQueryStringV2(params)}`,
  })

  rotaStatistics = (id: string, params: QueryPropsV2) => this.request({
    path: `/v2/nurseries/${id}/statistics/rota?${getQueryStringV2(params)}`,
  })

  listNurseryFiles = (id: string, params: QueryPropsV2) => this.request({
    path: `/v2/nurseries/${id}/files?${getQueryStringV2(params)}`,
  })

  getNurseryPublicSettings = (params = { token: '' }) => this.request({
    path: `/v2/nursery_public_settings?nt=${params.token}`,
  })

  getStripeIntegration = (id: string, params: QueryPropsV2) => this.request({
    path: `/v2/nursery_integrations/${id}?${getQueryStringV2(params)}`,
  })

  updateLikesAndCommentsMembers = (id: string, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/nurseries/${id}/like-and-comment-members`,
  })
}

export default NurseriesApiClient
