import React from 'react'

import { generateRoute } from 'utils/routing'

import { NURSERY_DISCOUNTS_FILTER_OPTIONS } from 'services/product/nurseryDiscountsV3/constants'

import { Callout, EmptyState, Page, Pagination, Section, Select, Spinner, Table, Toolbar, Typography } from 'components'

import i18n from 'translations'

const ManagementDiscountsV2View = ({
  discounts,
  errorMessages,
  isEmpty,
  isLoading,
  onPageChange,
  onSortChange,
  onStatusFilterChange,
  page,
  pageCount,
  perPage,
  sortField,
  sortOrder,
  statusFilter,
  totalResults,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('module:Children:Child:BookingPattern:Discounts:discounts')}
      titleSingular={i18n.t('module:Children:Child:BookingPattern:Discounts:discount')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (isEmpty) {
      return (
        <EmptyState
          icon="discount"
          text1="Add discounts here to show up on invoices."
        />
      )
    }

    const columns = [
      {
        align: 'left',
        field: 'description',
        sortable: true,
        title: 'Description',
      },
      {
        field: 'discount',
        title: 'Discount amount',
      },
      {
        field: 'rowToButton',
      },
    ]

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={columns}
          data={discounts}
          sortField={sortField}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const actions = <Section.Actions primary={[{ to: generateRoute('MANAGEMENT.FINANCE_SETTINGS.DISCOUNTS.ADD') }]} />

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title={i18n.t('module:Management:Finance:Discounts:yourDiscounts')}
    >
      <Callout content={errorMessages} error />
      <Typography margin="20px 0">
        <p>
          These are the discounts for your nursery. You can allocate them to children from the child profile.
        </p>
      </Typography>
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <Select
              clearable={false}
              options={NURSERY_DISCOUNTS_FILTER_OPTIONS}
              searchable={false}
              value={statusFilter}
              onChange={onStatusFilterChange}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      {renderContent()}
    </Page.Section>
  )
}

export default ManagementDiscountsV2View
