import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { RootState } from 'core/reducers'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as childrenStartingReportSelectors from './selectors'
import {
  ChildrenStartingReportChildrenState,
  ChildrenStartingReportNurseriesState,
  ChildrenStartingReportStatisticsState,
} from './models'

export interface withChildrenStartingReportServiceProps {
  childrenStartingReportActions: typeof actions
  childrenStartingReportChildrenState: ChildrenStartingReportChildrenState
  childrenStartingReportNurseriesState: ChildrenStartingReportNurseriesState
  childrenStartingReportSelectors: typeof childrenStartingReportSelectors
  childrenStartingReportStatisticsState: ChildrenStartingReportStatisticsState
}

const withChildrenStartingReportService = <P extends withChildrenStartingReportServiceProps>
  (WrappedComponent: WrappedComponentType<P>) => {
  const mapState = ({ childrenStartingReport }: RootState) => ({ childrenStartingReport })

  const mapDispatch = injectActions('childrenStartingReportActions', actions)

  const Component = ({ childrenStartingReport, childrenStartingReportActions, ...others }) => (
    <WrappedComponent
      childrenStartingReportActions={childrenStartingReportActions}
      childrenStartingReportChildrenState={childrenStartingReport.children}
      childrenStartingReportNurseriesState={childrenStartingReport.nurseries}
      childrenStartingReportSelectors={childrenStartingReportSelectors}
      childrenStartingReportStatisticsState={childrenStartingReport.statistics}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withChildrenStartingReportService
