import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Icon, Typography } from 'components'

import { properties } from 'app-config'

import { StyledContainer, StyledLogo } from './ReferralButtonStyled'

const ReferralButton = () => (
  <StyledContainer
    href={properties.referralLink}
    target="_blank"
  >
    <StyledLogo src="/images/amazon-logo.svg" />
    <div>
      <Typography variant="div" bold>
        Refer blossom
        {' '}
        <Typography margin="0 0 5px" variant="span">
          <Icon color={NEUTRAL_COLOURS.WHITE} height={11} icon="external-link" />
        </Typography>
      </Typography>
      <Typography fontSize={14}>
        Get a £200 voucher
      </Typography>
    </div>
  </StyledContainer>
)

export default ReferralButton
