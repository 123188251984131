import { Property } from 'csstype'

import React, { PropsWithChildren } from 'react'

import FormRemoveRowButton from '../FormRemoveRowButton'
import FormRow from '../FormRow/FormRow'
import FormFooterActions from '../FormFooterActions'
import FormChildSession from '../../../legacy/Form/fields/FormChildSession'
import FormButtonGroup from '../../fields/FormButtonGroup'
import FormCheckbox from '../../fields/FormCheckbox'
import FormDatePicker from '../../fields/FormDatePicker'
import FormDateTimePicker from '../../fields/FormDateTimePicker'
import FormDraggableList from '../../fields/FormDraggableList'
import FormInfiniteDropdowns from '../../fields/FormInfiniteDropdowns'
import FormError from '../../fields/FormError'
import FormMediaPicker from '../../fields/FormMediaPicker'
import FormNurserySession from '../../../legacy/Form/fields/FormNurserySession'
import FormPasswordInput from '../../../legacy/Form/fields/FormPasswordInput'
import FormPinpad from '../../fields/FormPinpad'
import FormPhotoUpload from '../../fields/FormPhotoUpload'
import FormRadioUser from '../../fields/FormRadioUser'
import FormRadioGroup from '../../../legacy/Form/fields/FormRadioGroup'
import FormSelect from '../../fields/FormSelect'
import FormSlider from '../../fields/FormSlider'
import FormStickyFooter from '../FormStickyFooter'
import FormSwitch from '../../fields/FormSwitch'
import FormTemplateTextField from '../../fields/FormTemplateTextField'
import FormTextAreaField from '../../fields/FormTextAreaField'
import FormTextEditorField from '../../fields/FormTextEditorField'
import FormTextField from '../../fields/FormTextField'
import FormTimePicker from '../../fields/FormTimePicker'
import FormDivider from '../FormDivider/FormDivider'
import { StyledFormContainer } from './FormStyled'

interface FormRoot<T> extends React.FC<T> {
  ButtonGroup?: typeof FormButtonGroup
  Checkbox?: typeof FormCheckbox
  ChildSession?: typeof FormChildSession
  DatePicker?: typeof FormDatePicker
  DateTimePicker?: typeof FormDateTimePicker
  Divider?: typeof FormDivider
  DraggableList?: typeof FormDraggableList
  Error?: typeof FormError
  FooterActions?: typeof FormFooterActions
  InfiniteDropdowns?: typeof FormInfiniteDropdowns
  MediaPicker?: typeof FormMediaPicker
  NurserySession?: typeof FormNurserySession
  PasswordInput?: typeof FormPasswordInput
  PhotoUpload?: typeof FormPhotoUpload
  Pinpad?: typeof FormPinpad
  RadioGroup?: typeof FormRadioGroup
  RadioUser?: typeof FormRadioUser
  RemoveRowButton?: typeof FormRemoveRowButton
  Row?: typeof FormRow
  Select?: typeof FormSelect
  Slider?: typeof FormSlider
  StickyFooter?: typeof FormStickyFooter
  Switch?: typeof FormSwitch
  TemplateTextField?: typeof FormTemplateTextField
  TextAreaField?: typeof FormTextAreaField
  TextEditorField?: typeof FormTextEditorField
  TextField?: typeof FormTextField
  TimePicker?: typeof FormTimePicker
}

interface FormProps {
  flex?: Property.Flex
  noValidate?: boolean
  onSubmit: () => void
}

const Form: FormRoot<PropsWithChildren<FormProps>> = ({
  children,
  flex,
  noValidate,
  onSubmit,
}) => (
  <StyledFormContainer
    flex={flex}
    noValidate={noValidate}
    onSubmit={onSubmit}
  >
    {children}
  </StyledFormContainer>
)

export default Form
