import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class OccupancyApiClient extends ServiceBase {
  report = (params = {}) => this.request({
    path: `/v2/occupancy?${getQueryStringV2(params)}`,
  })
}

export default OccupancyApiClient
