import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'

const getSnackbarState = (state: RootState) => state.snackbar && state.snackbar.root

export const getSnackbar = createSelector(
  [getSnackbarState],
  (state) => {
    if (!state) {
      return null
    }

    return state.snackbar
  },
)
