import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { typeByObject } from 'utils/typescript'
import { getBrandingColor } from 'utils/branding'

import { VARIANT } from './Section'

export const StyledSubtitleContainer = styled.span`
  padding-left: 15px;
  font-size: 15px;
  font-weight: 400;
`

interface StyledHeaderContainerProps {
  $noBorder?: boolean
  $noMobileVersion?: boolean
  $variant?: typeByObject<typeof VARIANT>
}

export const StyledHeaderContainer = styled.header<StyledHeaderContainerProps>`
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: 1px dashed ${NEUTRAL_COLOURS.SILVER};
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  min-height: ${({ $variant }) => ($variant === VARIANT.SMALL ? 40 : 60)}px;

  ${({ $noMobileVersion }) => !$noMobileVersion && `
    @media(max-width: 740px) {
      flex-direction: column;
      align-items: center;
      justify-content: end;
    }
  `}

  ${({ $noBorder }) => $noBorder && `
    border-bottom: none;
    margin-bottom: 5px;
  `}
`

interface StyledHeaderProps {
  $titleColor?: string
  $variant?: typeByObject<typeof VARIANT>
}

export const StyledHeader = styled.header<StyledHeaderProps>`
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  display: flex;
  align-items: baseline;
  
  ${({ $variant }) => $variant === VARIANT.SMALL && `
    font-size: 20px;
  `}
  
  ${({ $titleColor }) => $titleColor && `
    color: ${$titleColor};
  `}
`

interface StyledContainerProps {
  $noMargin?: boolean
  $secondLevel?: boolean
}

export const StyledContainer = styled.header<StyledContainerProps>`
  flex: 1;

  & + .container {
    margin-top: 50px;
  }
  
  ${({ $noMargin }) => $noMargin && `
    margin-top: 0;
  `}
  
  ${({ $secondLevel }) => $secondLevel && `
    & ${StyledHeader} {
      font-size: 18px;
      color: ${getBrandingColor('tertiary-color')};
      font-weight: 600;
    }
  `}
`

export const StyledTooltipContainer = styled.div`
  margin-left: 10px;
`

export const StyledActionsContainer = styled.div`
  @media(max-width: 740px) {
    margin-top: 20px;
  }
`

export const StyledDivider = styled.div`
  width: 1px;
  border-left: 1px dashed ${NEUTRAL_COLOURS.SILVER};
  height: 100%;
`

export const StyledTitleSecond = styled.span`
  font-weight: 400;
  padding-left: 5px;
`
