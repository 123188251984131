import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as dailyDiaryBottlesSelectors from './selectors'

const withDailyDiaryBottlesService = (WrappedComponent) => {
  const mapState = ({ dailyDiaryBottles }) => ({ dailyDiaryBottles })

  const mapDispatch = injectActions('dailyDiaryBottlesActions', actions)

  const Component = ({ dailyDiaryBottles, dailyDiaryBottlesActions, ...others }) => (
    <WrappedComponent
      dailyDiaryBottlesActions={dailyDiaryBottlesActions}
      dailyDiaryBottlesListState={dailyDiaryBottles.list}
      dailyDiaryBottlesListWithoutRecordsState={dailyDiaryBottles.listWithoutRecords}
      dailyDiaryBottlesSelectors={dailyDiaryBottlesSelectors}
      dailyDiaryBottlesSingleState={dailyDiaryBottles.single}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withDailyDiaryBottlesService
