import _ from 'lodash'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { browserHistory } from 'react-router'

import { FRAMEWORK_STRUCTURE_APPLICABLE } from 'services/legacy/frameworks/constants'
import { FRAMEWORK_PROGRESS_LEVELS_TYPES } from 'services/legacy/frameworkProgressLevels/constants'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withObservationsService } from 'services/legacy/observations'
import { withChildService } from 'services/legacy/child'
import { withChildObservationsService } from 'services/legacy/childObservations'
import { withFrameworksService } from 'services/legacy/frameworks'
import { withFrameworkProgressLevelsService } from 'services/legacy/frameworkProgressLevels'
import { withChildFrameworkProgressService } from 'services/legacy/childFrameworkProgress'
import { withNurseriesService } from 'services/nurseries'
import { withRouter } from 'services/router'

import ObservationFrameworksView from './ObservationFrameworksView'
import { OBSERVATIONS_PAGE_TYPE } from '../constants'

const CHILD_OBSERVATION_GROUPS = {
  read: [
    'child',
    'observation.montessoriActivity',
    'childObservation.child',
    'childObservation.frameworkEvents',
    'observation.childObservations',
  ],
}

const FRAMEWORK_ITEM_GROUPS = {
  read: [
    'framework.areas',
    'structure',
    'structure.observation',
    'structure.observationLinks',
    'frameworkArea',
    'frameworkArea.groups',
    'frameworkGroup',
    'frameworkGroup.categories',
    'frameworkCategory',
    'frameworkCategory.items',
    'frameworkItem',
    'frameworkItem.linkedItems',
  ],
}

const CHILD_FRAMEWORK_PROGRESS_GROUPS = {
  read: [
    'frameworkArea',
    'frameworkArea.groups',
    'frameworkGroup',
    'frameworkGroup.categories',
    'frameworkCategory',
    'frameworkCategory.items',
    'frameworkItem',
    'frameworkItem.progress',
    'frameworkProgressLevel',
    'progress',
    'childFrameworkItemEvent',
    'childFrameworkItemEvent.observation',
    'childFrameworkItemEvent.progressLevel',
  ],
}

const OBSERVATION_GROUPS = {
  read: [
    'observation.montessoriActivity',
  ],
}

export const FRAMEWORK_LIST_GROUPS = {
  read: [
    'framework.default',
    'framework.areas',
    'frameworkArea',
    'structure',
  ],
}

export const NURSERY_SETTINGS_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings.learning',
    'nurseryLearningSettings',
  ],
}

class ObservationFrameworksContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      downloadProgressAsFirstTime: false,
      downloadedObservation: {
        child: false,
        main: false,
      },
      existSuggestedLinks: false,
      existSuggestedLinksChecked: false,
      framework: null,
      itemsDuringProcessing: 0,
      onlyShowSuggestedLinks: false,
      pageType: this.getPageType(),
      showCompletedStatements: true,
    }
  }

  componentDidMount() {
    const {
      childObservationsActions,
      navigate,
      nurseriesActions,
      nurseryOptions,
      observationsActions,
      params,
    } = this.props
    const { pageType } = this.state
    const { childObservationId, observationId } = params

    childObservationsActions.get({
      onFailed: () => (
        navigate(pageType === OBSERVATIONS_PAGE_TYPE.APPROVALS
          ? generateRoute('APPROVALS.OBSERVATIONS')
          : generateRoute('LEARNING.OBSERVATIONS'))
      ),
      onSuccess: () => {
        const { downloadedObservation } = this.state

        if (downloadedObservation.child) {
          this.getFrameworksList()
        }

        this.setState({
          downloadedObservation: {
            ...downloadedObservation,
            main: true,
          },
        })
      },
      params: [childObservationId, {
        groups: CHILD_OBSERVATION_GROUPS,
      }],
    })

    nurseriesActions.get(nurseryOptions.id, {
      params: {
        groups: NURSERY_SETTINGS_GROUPS,
      },
    })

    observationsActions.get({
      onFailed: () => (
        navigate(pageType === OBSERVATIONS_PAGE_TYPE.APPROVALS
          ? generateRoute('APPROVALS.OBSERVATIONS')
          : generateRoute('LEARNING.OBSERVATIONS'))
      ),
      onSuccess: () => {
        const { downloadedObservation } = this.state

        if (downloadedObservation.main) {
          this.getFrameworksList()
        }

        this.setState({
          downloadedObservation: {
            ...downloadedObservation,
            child: true,
          },
        })
      },
      params: [observationId, {
        groups: OBSERVATION_GROUPS,
      }],
    })
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props
    const { location: prevLocation } = prevProps
    const { downloadProgressAsFirstTime, framework } = this.state

    if (
      (prevLocation?.query?.area !== undefined && !_.isEqual(prevLocation?.query?.area, location?.query?.area))
      || (!downloadProgressAsFirstTime && framework && location?.query?.area)
    ) {
      this.getChildFrameworkProgress()
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ downloadProgressAsFirstTime: true })
    }
  }

  componentWillUnmount() {
    const {
      childFrameworkProgressActions,
      childObservationsActions,
      frameworkProgressLevelsActions,
      frameworksActions,
      observationsActions,
    } = this.props

    childFrameworkProgressActions.clear()
    frameworksActions.clear()
    observationsActions.clear()
    childObservationsActions.clear()
    frameworkProgressLevelsActions.clear()
  }

  changeItemsDuringProcessing = (isProcessing) => {
    this.setState((prevState) => ({
      itemsDuringProcessing: prevState.itemsDuringProcessing + (isProcessing ? 1 : -1),
    }))
  }

  getFrameworksList = () => {
    const { frameworksActions } = this.props

    frameworksActions.list({
      onSuccess: this.handleGetFrameworksListSuccess,
      params: [{
        groups: FRAMEWORK_LIST_GROUPS,
      }],
    })
  }

  getChildFrameworkProgress = (data) => {
    const { childFrameworkProgressActions, childObservation, frameworksRawList, location } = this.props
    const { framework } = this.state
    const { child } = childObservation || {}
    const { id } = child || {}
    const { query } = location
    const { area } = query
    let finalArea = +area

    if (id && framework) {
      const finaleFrameworkRowList = data
        ? _.filter(data, ({ structure }) => (
          _.find(structure?.applicable, (item) => item === FRAMEWORK_STRUCTURE_APPLICABLE.OBSERVATION)
        )) : frameworksRawList

      const frameworkDetails = _.find(finaleFrameworkRowList, ({ id: frameworkId }) => frameworkId === framework)

      if (!_.find(frameworkDetails?.areas, (item) => item.id === +area)) {
        finalArea = frameworkDetails?.areas[0]?.id
      }

      if (finalArea) {
        childFrameworkProgressActions.listOfProgress({
          params: [id, framework, finalArea, {
            groups: CHILD_FRAMEWORK_PROGRESS_GROUPS,
          }],
        })
      }
    }
  }

  handleGetFrameworksListSuccess = (response) => {
    const { location } = this.props
    const { data } = response
    const { query } = location

    let framework = _.find(data, 'default')?.id || data?.[0]?.id

    if (_.find(data, ({ id }) => +query.framework === id)) {
      framework = +query.framework
    }

    if (framework) {
      this.setState({ framework }, () => {
        this.getFrameworkDetails()
        this.getChildFrameworkProgress(data)
      })
    }
  }

  getPageType = () => {
    const { location: { pathname }, params } = this.props

    if (generateRoute('APPROVALS.OBSERVATIONS.EDIT.FRAMEWORKS', params) === pathname) {
      return OBSERVATIONS_PAGE_TYPE.APPROVALS
    }

    return OBSERVATIONS_PAGE_TYPE.LEARNING
  }

  handleChangeOnlyShowSuggestedLinks = () => {
    const { onlyShowSuggestedLinks } = this.state

    this.setState({ onlyShowSuggestedLinks: !onlyShowSuggestedLinks }, this.getFrameworkDetails)
  }

  handleChangeShowCompletedStatements = () => {
    const { showCompletedStatements } = this.state

    this.setState({ showCompletedStatements: !showCompletedStatements })
  }

  getFrameworkProgressLevels = () => {
    const { frameworkProgressLevelsActions } = this.props
    const { framework } = this.state

    const progressLevelsCriteria = [
      {
        field: 'framework',
        value: framework,
      },
      {
        field: 'type',
        value: FRAMEWORK_PROGRESS_LEVELS_TYPES.OBSERVATION_LINK,
      },
    ]

    frameworkProgressLevelsActions.list({
      params: {
        criteria: progressLevelsCriteria,
      },
      recursively: true,
    })
  }

  getFrameworkDetailsAction = (criteria) => {
    const { frameworksActions } = this.props
    const { framework } = this.state

    this.getFrameworkProgressLevels()

    frameworksActions.get({
      params: [framework, {
        criteria,
        groups: FRAMEWORK_ITEM_GROUPS,
      }],
    })
  }

  getFrameworkDetails = () => {
    const { frameworksActions, observation } = this.props
    const { existSuggestedLinksChecked, framework, onlyShowSuggestedLinks } = this.state
    const { montessoriActivity } = observation

    let criteria = {}

    if (onlyShowSuggestedLinks && montessoriActivity?.id) {
      criteria = [
        {
          field: 'areas.groups.categories.items.montessoriActivities[]',
          value: montessoriActivity.id,
        },
      ]
    }

    if (!montessoriActivity?.id) {
      this.setState({ existSuggestedLinks: false })
    }

    const location = _.assign({}, browserHistory.getCurrentLocation())
    location.query = {
      ...location.query,
      framework,
    }

    browserHistory.push(location)

    if (!existSuggestedLinksChecked && montessoriActivity?.id) {
      this.setState({ existSuggestedLinksChecked: true })

      const criteriaSecond = [
        {
          field: 'areas.groups.categories.items.montessoriActivities[]',
          value: montessoriActivity.id,
        },
      ]

      frameworksActions.get({
        onFailed: () => {
          this.setState({ existSuggestedLinks: false })
          this.getFrameworkDetailsAction(criteria)
        },
        onSuccess: () => {
          criteria = [
            {
              field: 'areas.groups.categories.items.montessoriActivities[]',
              value: montessoriActivity.id,
            },
          ]

          this.setState({ existSuggestedLinks: true, onlyShowSuggestedLinks: true })
          this.getFrameworkDetailsAction(criteria)
        },
        onlyData: true,
        params: [framework, {
          criteria: criteriaSecond,
          groups: FRAMEWORK_ITEM_GROUPS,
        }],
      })
    } else {
      this.getFrameworkDetailsAction(criteria)
    }
  }

  handleChangeFramework = (framework) => {
    this.setState({
      existSuggestedLinksChecked: false,
      framework: framework?.value,
      onlyShowSuggestedLinks: false,
    }, this.getFrameworkDetails)
  }

  render() {
    const {
      childObservation,
      frameworkDetails,
      frameworksList,
      isFetching,
      isFetchingChildFrameworkProgress,
      isFetchingFramework,
      nurseryLearningSettings,
      observation,
      progressLevels,
    } = this.props
    const {
      existSuggestedLinks,
      framework,
      itemsDuringProcessing,
      onlyShowSuggestedLinks,
      showCompletedStatements,
    } = this.state

    return (
      <ObservationFrameworksView
        childObservation={childObservation}
        existSuggestedLinks={existSuggestedLinks}
        framework={framework}
        frameworkDetails={frameworkDetails}
        frameworksList={frameworksList}
        isFetching={isFetching}
        isFetchingChildFrameworkProgress={isFetchingChildFrameworkProgress}
        isFetchingFramework={isFetchingFramework}
        itemsDuringProcessing={itemsDuringProcessing}
        nurseryLearningSettings={nurseryLearningSettings}
        observation={observation}
        onlyShowSuggestedLinks={onlyShowSuggestedLinks}
        progressLevels={progressLevels}
        showCompletedStatements={showCompletedStatements}
        onChangeFramework={this.handleChangeFramework}
        onChangeItemsDuringProcessing={this.changeItemsDuringProcessing}
        onChangeOnlyShowSuggestedLinks={this.handleChangeOnlyShowSuggestedLinks}
        onChangeShowCompletedStatements={this.handleChangeShowCompletedStatements}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childFrameworkProgressListState,
  childObservationsSelectors,
  childObservationsSingleState,
  frameworkProgressLevelsListState,
  frameworkProgressLevelsSelectors,
  frameworksListState,
  frameworksSelectors,
  frameworksSingleState,
  nurseriesSelectors,
  nurseriesSingleState,
  observationsSelectors,
  observationsSingleState,
  params,
}) => ({
  childObservation: childObservationsSelectors.getChildObservationsSingleStateDataSelector(state),
  frameworkDetails: frameworksSelectors.getFrameworksSingleDataState(state),
  frameworksList: frameworksSelectors.getFilteredFrameworksList(FRAMEWORK_STRUCTURE_APPLICABLE.OBSERVATION)(state),
  frameworksRawList: frameworksSelectors.getFilteredFrameworksRawList(
    FRAMEWORK_STRUCTURE_APPLICABLE.OBSERVATION,
  )(state),
  isFetching: appSelectors.getIsFetching(
    nurseriesSingleState,
    frameworksListState,
    observationsSingleState,
    childObservationsSingleState,
  ),
  isFetchingChildFrameworkProgress: appSelectors.getIsFetching(childFrameworkProgressListState),
  isFetchingFramework: appSelectors.getIsFetching(
    frameworksSingleState,
    frameworkProgressLevelsListState,
  ),
  nurseryLearningSettings: nurseriesSelectors.getNurseryLearningSettings(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  observation: observationsSelectors.getObservation(state),
  progressLevels: frameworkProgressLevelsSelectors.getFrameworkProgressLevels(state),
})

const enhance = compose(
  withAppService,
  withChildService,
  withChildObservationsService,
  withChildFrameworkProgressService,
  withFrameworksService,
  withFrameworkProgressLevelsService,
  withNurseriesService,
  withObservationsService,
  withRouter,
  connect(mapState),
)

export default enhance(ObservationFrameworksContainer)
