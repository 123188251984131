import _ from 'lodash'

import { isRequired, isRequiredDateRange, isValidDateRange } from 'utils/fieldValidation'

// This type should move to form once it refactor to TS
// src/module/Children/Child/ChildFinance/ChildFinanceInvoice/components/ChildFinanceInvoiceForm.js
interface formValuesProps {
  dueDate?: string
  invoicePeriod: string[]
  invoicePeriodDropdown?: {
    endDate: string
    label: string
    startDate: string
    value: string
  }
  issueDate: string
  name: string
  number?: string
}

interface updateInvoiceDateFieldErrorProp {
  endDate?: string
  invoicePeriod: string[]
  startDate?: string
}

interface errorsProps {
  dueDate?: string
  invoicePeriod?: string
  issueDate?: string
  name?: string
}

const updateInvoiceDateFieldError = (errors: updateInvoiceDateFieldErrorProp): updateInvoiceDateFieldErrorProp => {
  if (!errors || !(errors.startDate || errors.endDate)) {
    return errors
  }

  const { endDate, startDate } = errors

  return {
    ...errors,
    invoicePeriod: [
      startDate ? `Start date: ${startDate} ` : null,
      endDate ? `End date: ${endDate}` : null],
  }
}

const getMandatoryFieldError = (formValues: formValuesProps): errorsProps => {
  if (!formValues) {
    return null
  }

  const { dueDate, invoicePeriod, issueDate, name } = formValues || {}

  const errors: errorsProps = { }

  errors.name = isRequired(name)
  errors.invoicePeriod = isRequiredDateRange(invoicePeriod) || isValidDateRange(invoicePeriod)
  errors.issueDate = isRequired(issueDate)
  errors.dueDate = isRequired(dueDate)

  if (_.some(errors, (error) => !!error)) {
    Object.keys(errors).forEach((key) => (!errors[key]) && delete errors[key])

    return errors
  }

  return null
}

export default {
  getMandatoryFieldError,
  updateInvoiceDateFieldError,
}
