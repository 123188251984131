import React from 'react'

import { STAFF_ENTRY_TYPES, STAFF_REGISTER_STATUS } from 'services/legacy/membershipRegisters/constants'

import { Callout, Page, Space, Typography } from 'components'

import i18n from 'translations'

import { StaffHeader } from '../components'

import ChangeLocationForm from './components/ChangeLocationForm'

const ChangeLocationView = ({
  currentDate,
  errorMessages,
  isLocationRequired,
  isSubmitting,
  onBackButtonClick,
  onSkipButtonClick,
  onSubmit,
  selectedStatus,
  staff,
  staffProfile,
}) => {
  const getTitle = () => {
    const { entries, lastEntryType, ongoing } = staff

    if (ongoing && lastEntryType === STAFF_ENTRY_TYPES.BREAK) {
      return i18n.t('module:StaffRegister:ChangeLocation:Title:endBreak')
    }

    if (selectedStatus === STAFF_REGISTER_STATUS.ON_BREAK) {
      return i18n.t('module:StaffRegister:ChangeLocation:Title:startBreak')
    }

    if (!ongoing || !entries?.length) {
      return i18n.t('module:StaffRegister:ChangeLocation:Title:clockIn')
    }

    return i18n.t('module:StaffRegister:ChangeLocation:Title:changeLocation')
  }

  return (
    <Page>
      <Page.Content>
        <Callout content={errorMessages} error />
        <StaffHeader
          currentDate={currentDate}
          staffProfile={staffProfile}
        />
        <React.Fragment>
          <Typography variant="h3">
            {getTitle()}
          </Typography>
          <Space space="20px" />
          <ChangeLocationForm
            isLocationRequired={isLocationRequired}
            isSubmitting={isSubmitting}
            onBackButtonClick={onBackButtonClick}
            onSkipButtonClick={onSkipButtonClick}
            onSubmit={onSubmit}
          />
        </React.Fragment>
      </Page.Content>
    </Page>
  )
}

export default ChangeLocationView
