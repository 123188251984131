import { generateBasicActions } from 'utils/service'

import {
  CLEAR_ENQUIRY_ACTIVITIES_SINGLE,
  CREATE_ENQUIRY_ACTIVITIES,
  CREATE_ENQUIRY_ACTIVITIES_FAILED,
  CREATE_ENQUIRY_ACTIVITIES_SUCCESS,
  GET_ENQUIRY_ACTIVITIES,
  GET_ENQUIRY_ACTIVITIES_FAILED,
  GET_ENQUIRY_ACTIVITIES_SUCCESS,
  REMOVE_ENQUIRY_ACTIVITIES,
  REMOVE_ENQUIRY_ACTIVITIES_FAILED,
  REMOVE_ENQUIRY_ACTIVITIES_SUCCESS,
  UPDATE_ENQUIRY_ACTIVITIES,
  UPDATE_ENQUIRY_ACTIVITIES_FAILED,
  UPDATE_ENQUIRY_ACTIVITIES_SUCCESS,
} from './constants'

export const create = (options = {}) => ({ enquiryActivitiesApiClient }) => generateBasicActions.create({
  constants: {
    failed: CREATE_ENQUIRY_ACTIVITIES_FAILED,
    init: CREATE_ENQUIRY_ACTIVITIES,
    success: CREATE_ENQUIRY_ACTIVITIES_SUCCESS,
  },
  options,
  service: enquiryActivitiesApiClient,
  serviceMethod: 'create',
})

export const get = (options = {}) => ({ enquiryActivitiesApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_ENQUIRY_ACTIVITIES_FAILED,
    init: GET_ENQUIRY_ACTIVITIES,
    success: GET_ENQUIRY_ACTIVITIES_SUCCESS,
  },
  options,
  service: enquiryActivitiesApiClient,
  serviceMethod: 'get',
})

export const update = (options = {}) => ({ enquiryActivitiesApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_ENQUIRY_ACTIVITIES_FAILED,
    init: UPDATE_ENQUIRY_ACTIVITIES,
    success: UPDATE_ENQUIRY_ACTIVITIES_SUCCESS,
  },
  options,
  service: enquiryActivitiesApiClient,
  serviceMethod: 'update',
})

export const remove = (options = {}) => ({ enquiryActivitiesApiClient }) => generateBasicActions.update({
  constants: {
    failed: REMOVE_ENQUIRY_ACTIVITIES_FAILED,
    init: REMOVE_ENQUIRY_ACTIVITIES,
    success: REMOVE_ENQUIRY_ACTIVITIES_SUCCESS,
  },
  options,
  service: enquiryActivitiesApiClient,
  serviceMethod: 'update',
})

export const clearSingle = () => ({
  type: CLEAR_ENQUIRY_ACTIVITIES_SINGLE,
})
