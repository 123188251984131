import moment from 'moment'
import _ from 'lodash'

import React from 'react'
import { Field, FieldArray, InjectedFormProps, reduxForm } from 'redux-form'

import { NurseryItemProduct } from 'services/product/nurseryItem/models'

import { isRequired } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'

import { Button, FooterActions, Form, Space, Typography } from 'components'
import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

import i18n from 'translations'

import { StyledPriceChangesItem, StyledPriceChangesWrapper, StyledRemoveButtonWrapper } from './ItemAddFormStyled'

export const ITEM_ADD_FORM = 'ItemAddForm'

interface ItemAddFormProps {
  isSubmitting?: boolean
  onChangeStartDate: (value: moment.Moment, index: number) => void
  onRemovePriceChangeRow: (index: number) => void
  onSubmit: (values: any) => void
}

type ItemAddFormFullProps = InjectedFormProps<{}, ItemAddFormProps> & ItemAddFormProps

export interface ItemAddFormValues extends NurseryItemProduct {}

const renderPriceChangeRow = ({ fields, i, isSubmitting, item, onChangeStartDate, onRemovePriceChangeRow }) => (
  <StyledPriceChangesItem $fieldsLength={fields.length} key={i}>
    {1 < fields.length && (
      <React.Fragment>
        <Form.Row
          label={0 === i && _.upperFirst(i18n.t('global:startDate'))}
          margin="0 0 5px 0"
          width={{ field: '100%' }}
          verticalLabel
        >
          <Field
            component={Form.DatePicker}
            disabled={isSubmitting || 0 === i}
            name={`${item}.startDate`}
            placeholder={0 === i ? '-' : _.upperFirst(i18n.t('global:startDate'))}
            validate={1 < fields.length && 0 !== i && isRequired}
            onChange={(value) => onChangeStartDate(value, i)}
          />
        </Form.Row>
        <Form.Row
          label={0 === i && _.upperFirst(i18n.t('global:endDate'))}
          margin="0 0 5px 0"
          width={{ field: '100%' }}
          verticalLabel
        >
          <Field
            component={Form.DatePicker}
            name={`${item}.endDate`}
            placeholder={
              fields.length - 1 === i
                ? i18n.t('module:Management:Finance:Item:Add:ongoing')
                : _.upperFirst(i18n.t('global:endDate'))
             }
            disabled
          />
        </Form.Row>

      </React.Fragment>
    )}
    <Form.Row
      label={0 === i && i18n.t('module:Management:Finance:Item:Add:fee')}
      margin="0 0 5px 0"
      width={{ field: '100%' }}
      verticalLabel
    >
      <SubdomainCurrencyProvider>
        {({ currencySymbol }) => (
          <Field
            component={Form.TextField}
            disabled={isSubmitting}
            name={`${item}.prices[0].price`}
            prefix={currencySymbol}
            prefixWidth="30px"
            type="number"
            validate={isRequired}
          />
        )}
      </SubdomainCurrencyProvider>
    </Form.Row>
    {1 < fields.length && (
      <StyledRemoveButtonWrapper $first={0 === i}>
        <Form.RemoveRowButton
          disabled={isSubmitting}
          trash
          onClick={() => {
            fields.remove(i)
            onRemovePriceChangeRow(i)
          }}
        />
      </StyledRemoveButtonWrapper>
    )}
  </StyledPriceChangesItem>
)

const renderPriceChanges = ({ fields, isSubmitting, onChangeStartDate, onRemovePriceChangeRow }) => (
  <React.Fragment>
    {fields.map((item, i) => renderPriceChangeRow({
      fields,
      i,
      isSubmitting,
      item,
      onChangeStartDate,
      onRemovePriceChangeRow,
    }))}
    <Button.ActionButton
      label={i18n.t('module:Management:Finance:Item:Add:schedulePriceChange')}
      onClick={() => fields.push({
        prices: [{
          price: 0,
        }],
      })}
    />
  </React.Fragment>
)

const ItemAddForm: React.FC<ItemAddFormFullProps> = ({
  handleSubmit,
  isSubmitting,
  onChangeStartDate,
  onRemovePriceChangeRow,
  onSubmit,
}) => {
  const renderFooter = () => (
    <FooterActions spaceBetween>
      <FooterActions.Group>
        <FooterActions.Item>
          <Button
            disabled={isSubmitting}
            hierarchy="tertiary"
            label={i18n.t('global:Cancel')}
            to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.ITEMS')}
          />
        </FooterActions.Item>
      </FooterActions.Group>
      <FooterActions.Group>
        <FooterActions.Item>
          <Button
            isLoading={isSubmitting}
            label={i18n.t('global:Save')}
            submit
          />
        </FooterActions.Item>
      </FooterActions.Group>
    </FooterActions>
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Row
        label={i18n.t('module:Management:Finance:Item:Add:itemName')}
        width={{ field: '300px' }}
      >
        <Field
          component={Form.TextField}
          disabled={isSubmitting}
          name="name"
          placeholder={i18n.t('module:Management:Finance:Item:Add:itemName')}
          validate={isRequired}
        />
      </Form.Row>
      <Space space="40px" />
      <Typography fontSize={20} bold>
        {i18n.t('module:Management:Finance:Item:Add:fees')}
      </Typography>
      <Space space="20px" />
      <StyledPriceChangesWrapper>
        <FieldArray
          component={renderPriceChanges}
          name="priceChanges"
          props={{ isSubmitting, onChangeStartDate, onRemovePriceChangeRow }}
        />
      </StyledPriceChangesWrapper>
      {renderFooter()}
    </Form>
  )
}

export default reduxForm<{}, ItemAddFormProps>({ form: ITEM_ADD_FORM })(ItemAddForm)
