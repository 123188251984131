import moment from 'moment'

import React, { PropsWithChildren } from 'react'

import { TimeString } from 'components'

interface TimeStringRangeProps {
  end: moment.Moment | number
  local?: boolean
  start: moment.Moment | number
}

const TimeStringRange: React.FC<PropsWithChildren<TimeStringRangeProps>> = ({ end, local = true, start }) => (
  <React.Fragment>
    <TimeString date={start} local={local} />
    {' - '}
    <TimeString date={end} local={local} />
  </React.Fragment>
)

export default TimeStringRange
