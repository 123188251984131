import color from 'color'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const contrastingColor = (
  value: string,
  dark: string = '#000',
  light: string = NEUTRAL_COLOURS.WHITE,
): string => (
  50 < color(value).lightness() ? dark : light
)
