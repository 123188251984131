export const ADD_NAPPIE = 'ADD_NAPPIE'
export const ADD_NAPPIE_SUCCESS = 'ADD_NAPPIE_SUCCESS'
export const ADD_NAPPIE_FAILED = 'ADD_NAPPIE_FAILED'

export const REMOVE_NAPPIE = 'REMOVE_NAPPIE'
export const REMOVE_NAPPIE_SUCCESS = 'REMOVE_NAPPIE_SUCCESS'
export const REMOVE_NAPPIE_FAILED = 'REMOVE_NAPPIE_FAILED'

export const UPDATE_NAPPIE = 'UPDATE_NAPPIE'
export const UPDATE_NAPPIE_SUCCESS = 'UPDATE_NAPPIE_SUCCESS'
export const UPDATE_NAPPIE_FAILED = 'UPDATE_NAPPIE_FAILED'
