import styled from 'styled-components'

interface StyledContainerProps {
  $bottom?: string
  $height?: string
  $icon?: string
  $marginLeft?: string
  $marginTop?: string
  $right?: string
  $width?: string
}

export const StyledContainer = styled.div<StyledContainerProps>`
  ${({ $icon }) => `background: url(/images/xmas-icons/${$icon}.svg) no-repeat;`}
  background-size: contain;
  position: absolute;    
  
  ${({ $width }) => $width && `
    width: ${$width};    
  `}
  
  ${({ $bottom }) => $bottom && `
    bottom: ${$bottom};    
  `}
  
  ${({ $height }) => $height && `
    height: ${$height};    
  `}
  
  ${({ $marginLeft }) => $marginLeft && `
    margin-left: ${$marginLeft};    
  `}  
  
  ${({ $right }) => $right && `
    right: ${$right};    
  `}  
  
  ${({ $marginTop }) => $marginTop && `
    margin-top: ${$marginTop};    
  `}  
`
