import { LIST_INJURY_RECORDS, LIST_INJURY_RECORDS_FAILED, LIST_INJURY_RECORDS_SUCCESS } from './constants'

export const list = (params) => ({ injuriesApiClient }) => (dispatch) => {
  dispatch({ type: LIST_INJURY_RECORDS })

  injuriesApiClient.list(params).then(
    (data) => dispatch({ payload: data, type: LIST_INJURY_RECORDS_SUCCESS }),
    (error) => dispatch({ payload: error, type: LIST_INJURY_RECORDS_FAILED }),
  )
}
