import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withShellService } from 'services/shell'
import { withEnquirySettingsService } from 'services/legacy/enquiries'
import { withPaginationUtils } from 'services/utils/pagination'
import { withSortingUtils } from 'services/utils/sorting'
import { withRouter } from 'services/router'

import i18n from 'translations'

import { ROUTE_URL_TYPE_MAPPINGS } from './constants'
import CommonView from './CommonView'
import { getEnquirySettings, getTransPrefix } from './helpers'

const DEFAULT_SORT = {
  sortField: 'name',
  sortOrder: 'ASC',
}

class CommonContainer extends Component {
  constructor(props) {
    super(props)

    const { params } = props
    const { type } = params

    this.state = {
      type: ROUTE_URL_TYPE_MAPPINGS[type],
    }
  }

  componentDidMount() {
    const { shellActions } = this.props
    const { type } = this.state

    shellActions.setRouteTitle({
      name: 'MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_COMMON.INDEX',
      title: i18n.t(`${getTransPrefix(type)}:title`),
    })

    this.fetch()
  }

  fetch = () => {
    const {
      enquirySettingsActions,
      enquirySettingsSelectors,
      paginationUtils,
      sortingUtils,
    } = this.props
    const { type } = this.state

    const { page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const criteria = enquirySettingsSelectors.getCriteria({ type })

    enquirySettingsActions.list({
      params: [{
        criteria,
        order: {
          sortField: sortField || DEFAULT_SORT.sortField,
          sortOrder: sortField ? sortOrder : DEFAULT_SORT.sortOrder,
        },
        page,
      }],
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleSortChange = (key) => {
    const { sortingUtils } = this.props
    const { onSortChange } = sortingUtils

    onSortChange(() => this.handlePageChange(1))(key)
  }

  render() {
    const {
      enquirySettingsList,
      isFetching,
      paginationUtils,
      sortingUtils,
      totalResults,
    } = this.props
    const { type } = this.state

    const { getPageCount, page, perPage } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const pageCount = getPageCount(totalResults)

    return (
      <CommonView
        enquirySettings={getEnquirySettings(enquirySettingsList, type)}
        isLoading={isFetching}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        sortField={sortField}
        sortOrder={sortOrder}
        totalResults={totalResults}
        type={type}
        onPageChange={this.handlePageChange}
        onSortChange={this.handleSortChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  enquirySettingsListState,
  enquirySettingsSelectors,
}) => ({
  enquirySettingsList: enquirySettingsSelectors.getEnquirySettingsListDataSelector(state),
  errorMessages: appSelectors.getErrorMessages(enquirySettingsListState),
  isFetching: appSelectors.getIsFetching(enquirySettingsListState),
  totalResults: appSelectors.getTotalResults(enquirySettingsListState),
})

const enhance = compose(
  withAppService,
  withRouter,
  withShellService,
  withEnquirySettingsService,
  withPaginationUtils,
  withSortingUtils,
  connect(mapState),
)

export default enhance(CommonContainer)
