import React from 'react'

import { Typography } from 'components'

import i18n from 'translations'

import { properties } from 'app-config'

import VariousPageWrapper from '../components/VariousPageWrapper'

const NotFoundView = () => {
  const number = properties.helpDeskNumber

  return (
    <VariousPageWrapper>
      <Typography
        fontSize={18}
        margin="30px 0 20px 0"
        maxWidth={470}
        opacity="0.6"
        primary
      >
        {i18n.t('module:Various:NotFound:text2')}
      </Typography>
      <Typography
        fontSize={20}
        primary
      >
        {i18n.t('module:Various:NotFound:text3')}
      </Typography>
      <Typography
        fontSize={24}
        margin="10px 0 25px"
        bold
        tertiary
      >
        <a href={`tel:${number}`}>{number}</a>
      </Typography>
    </VariousPageWrapper>
  )
}

export default NotFoundView
