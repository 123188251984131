import React from 'react'

import { INJURIES_STATUS, SIGNATURE_COLLECTING_LABELS } from 'services/legacy/injuries/constants'

import { DefinitionList, GridLayout, Typography } from 'components'

import i18n from 'translations'

import {
  StyledCollectingContainer,
  StyledContainer,
  StyledLink,
  StyledSignatureTotalContainer,
} from './SignatureHeaderStyled'

const SignatureHeader = ({
  disableSignature,
  injuryPreview,
  injurySignaturesPreview,
  onChangeClick,
}) => {
  const { signatureTotal, status } = injuryPreview
  const { signatureCollectingType } = injurySignaturesPreview || {}
  const { all, signed } = signatureTotal

  return (
    <StyledContainer>
      <GridLayout>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item column>
            <DefinitionList vertical>
              <DefinitionList.Item
                label={i18n.t('module:Injury:Preview:Details:statusLabel')}
                size="big"
                value={(
                  <Typography color={INJURIES_STATUS[status]?.color()}>
                    {INJURIES_STATUS[status]?.label}
                  </Typography>
                )}
              />
            </DefinitionList>
          </GridLayout.Item>
          <GridLayout.Item column>
            <DefinitionList vertical>
              <DefinitionList.Item
                label={(
                  <Typography fontSize={22} variant="span" bold>
                    {i18n.t('module:Injury:Preview:Signatures:CollectingOption:title')}
                  </Typography>
                )}
                value={(
                  <StyledCollectingContainer>
                    <Typography fontSize={26} variant="span" bold>
                      {SIGNATURE_COLLECTING_LABELS[signatureCollectingType]}
                    </Typography>
                    {!disableSignature && (
                      <StyledLink onClick={onChangeClick}>
                        {i18n.t('module:Injury:Preview:Signatures:CollectingOption:changeLinkLabel')}
                      </StyledLink>
                    )}
                  </StyledCollectingContainer>
                )}
              />
            </DefinitionList>
          </GridLayout.Item>
          <GridLayout.Item column>
            <StyledSignatureTotalContainer>
              <Typography fontSize={28} variant="span" bold>
                {signed}
              </Typography>
              &nbsp;
              <Typography fontSize={26} variant="span">
                {i18n.t('module:Injury:Preview:Signatures:CollectingOption:outOfLabel', {
                  all,
                })}
              </Typography>
            </StyledSignatureTotalContainer>
          </GridLayout.Item>
        </GridLayout.Group>
      </GridLayout>
    </StyledContainer>
  )
}

export default SignatureHeader
