import {
  CLEAR_HOME_OBSERVATION,
  GET_HOME_OBSERVATION,
  GET_HOME_OBSERVATION_FAILED,
  GET_HOME_OBSERVATION_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_HOME_OBSERVATION:
      return {
        ...state,
        error: null,
        isFetching: true,
      }
    case GET_HOME_OBSERVATION_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        error: null,
        isFetching: false,
      }
    case GET_HOME_OBSERVATION_FAILED:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      }
    case CLEAR_HOME_OBSERVATION: {
      return { ...initialState }
    }
    default:
      return state
  }
}
