import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withSubdomainService } from 'services/subdomain'
import { withRouter } from 'services/router'

import EnquiryPublicFormSuccessView from './EnquiryPublicFormSuccessView'

class EnquiryPublicFormSuccessContainer extends Component {
  componentDidMount() {
    const { nurseriesActions, params } = this.props
    const { token } = params

    nurseriesActions.getNurseryPublicSettings({
      params: [{ token }],
    })
  }

  render() {
    const { enquiryPublicUrl, errorMessages, isFetching, subdomainData } = this.props

    return (
      <EnquiryPublicFormSuccessView
        enquiryPublicUrl={enquiryPublicUrl}
        errorMessages={errorMessages}
        isLoading={isFetching}
        subdomainData={subdomainData}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseriesPublicState,
  nurseriesSelectors,
  subdomainSelectors,
}) => ({
  enquiryPublicUrl: nurseriesSelectors.getPublicNurseryEnquiryUrl(state),
  errorMessages: appSelectors.getErrorMessages(nurseriesPublicState),
  isFetching: appSelectors.getIsFetching(nurseriesPublicState),
  subdomainData: subdomainSelectors.getSubdomainInfo(state),
})

const enhance = compose(
  withAppService,
  withNurseriesService,
  withSubdomainService,
  withRouter,
  connect(mapState),
)

export default enhance(EnquiryPublicFormSuccessContainer)
