import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withContractsService } from 'services/legacy/contracts'
import { withMembershipsService } from 'services/legacy/memberships'
import { withPaginationUtils } from 'services/utils/pagination'

import withStaffHoc from 'module/Staff/withStaffHoc'

import StaffContractsListView from './StaffContractsListView'
import { getTableData } from './helpers'

class StaffContractsListContainer extends Component {
  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { contractsActions } = this.props

    contractsActions.clearList()
  }

  fetch = () => {
    const {
      contractsActions,
      membership,
      paginationUtils: { page },
      params: { userId },
    } = this.props

    contractsActions.listForMembership(userId || membership?.id, {
      params: { page },
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  render() {
    const {
      data,
      errorMessages,
      isFetching,
      isGrantedEdit,
      isMyDetailsContext,
      paginationUtils,
      params: { userId },
      totalResults,
    } = this.props
    const { getPageCount, page, perPage } = paginationUtils

    const pageCount = getPageCount(totalResults)
    const tableData = getTableData(data, isMyDetailsContext, userId)
    const addContractLink = isMyDetailsContext
      ? generateRoute('ACCOUNT.CONTRACTS.ADD')
      : generateRoute('STAFF.PROFILE.CONTRACTS.ADD', { userId })

    return (
      <StaffContractsListView
        addContractLink={addContractLink}
        data={tableData}
        errorMessages={errorMessages}
        isFetching={isFetching}
        isGrantedEdit={isGrantedEdit}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        totalResults={totalResults}
        onPageChange={this.handlePageChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  contractsListState,
  contractsSelectors,
  membershipsSelectors,
}) => ({
  data: contractsSelectors.getContractsDataSelector(state),
  errorMessages: appSelectors.getErrorMessages(contractsListState),
  isFetching: appSelectors.getIsFetching(contractsListState),
  isGrantedEdit: contractsSelectors.getIsGrantedEdit(state),
  membership: membershipsSelectors.getMembershipDataSelector(state),
  totalResults: appSelectors.getTotalResults(contractsListState),
})

const enhance = compose(
  withAppService,
  withContractsService,
  withMembershipsService,
  withPaginationUtils,
  withStaffHoc,
  connect(mapState),
)

export default enhance(StaffContractsListContainer)
