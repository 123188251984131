import { Property } from 'csstype'

import React, { PropsWithChildren } from 'react'

import { noop } from 'constants/global'
import { CursorTypes } from 'constants/css'

import { getBrandingColor } from 'utils/branding'
import { typeByObject } from 'utils/typescript'

import { ALIGN_OPTIONS, StyledTypography, VARIANT_TAGS } from './TypographyStyled'

export interface TypographyProps {
  align?: typeByObject<typeof ALIGN_OPTIONS>
  background?: string
  bold?: boolean
  children?: any
  color?: string
  cursor?: CursorTypes
  ellipsis?: boolean
  fontSize?: string | number
  fontWeight?: Property.FontWeight
  hoverColor?: string
  hoverOpacity?: number
  inline?: boolean
  italic?: boolean
  lineHeight?: number
  linkColorInherit?: boolean
  margin?: string
  maxWidth?: number
  medium?: boolean
  minWidth?: number
  nowrap?: boolean
  onClick?: () => void
  opacity?: Property.Opacity
  overflow?: Property.Overflow
  padding?: string
  prewrap?: boolean
  primary?: boolean
  secondary?: boolean
  tertiary?: boolean
  transform?: Property.TextTransform
  underline?: boolean
  userSelect?: Property.UserSelect
  variant?: typeByObject<typeof VARIANT_TAGS> | React.ElementType
  width?: string | number
  wordBreak?: Property.WordBreak
}

const Typography: React.FC<PropsWithChildren<TypographyProps>> = (props) => {
  const {
    align,
    background,
    bold,
    children,
    color,
    cursor,
    ellipsis,
    fontSize,
    fontWeight,
    hoverColor,
    hoverOpacity,
    inline,
    italic,
    lineHeight,
    linkColorInherit = true,
    margin,
    maxWidth,
    medium,
    minWidth,
    nowrap,
    onClick = noop,
    opacity,
    overflow,
    padding,
    prewrap,
    primary,
    secondary,
    tertiary,
    transform,
    underline,
    userSelect,
    variant = 'p',
    width,
    wordBreak,
  } = props

  let textColor = color

  if (primary) {
    textColor = getBrandingColor('primary-color')
  }

  if (secondary) {
    textColor = getBrandingColor('secondary-color')
  }

  if (tertiary) {
    textColor = getBrandingColor('tertiary-color')
  }

  return (
    <StyledTypography
      $align={align}
      $background={background}
      $bold={bold}
      $color={textColor}
      $cursor={cursor}
      $ellipsis={ellipsis}
      $fontSize={fontSize}
      $fontWeight={fontWeight}
      $hoverColor={hoverColor}
      $hoverOpacity={hoverOpacity}
      $inline={inline}
      $italic={italic}
      $lineHeight={lineHeight}
      $linkColorInherit={linkColorInherit}
      $margin={margin}
      $maxWidth={maxWidth}
      $medium={medium}
      $minWidth={minWidth}
      $nowrap={nowrap}
      $opacity={opacity}
      $overflow={overflow}
      $padding={padding}
      $prewrap={prewrap}
      $primary={primary}
      $secondary={secondary}
      $tertiary={tertiary}
      $transform={transform}
      $underline={underline}
      $userSelect={userSelect}
      $variant={variant}
      $width={width}
      $wordBreak={wordBreak}
      as={variant}
      linkColorInherit={linkColorInherit}
      variant={variant}
      onClick={onClick}
    >
      {children}
    </StyledTypography>
  )
}

export default Typography
