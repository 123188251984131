export const IS_HEADER_PINNED_LS = 'IS_HEADER_PINNED'

export const HEADER_PINNED_EVENT = 'EVENT/HEADER_PINNED_EVENT'

export const GET_ORGANISATION_SETTINGS_EVENT = 'EVENT/GET_ORGANISATION_SETTINGS_EVENT'

export type SubMenuItem = {
  badgeValue?: string | number
  hidden?: boolean
  icon: IconType
  isNew?: boolean
  label: string
  premium?: boolean
  to: string
}

export interface BasicShellSettings {
  currency?: string | null
  /**
   * Parameter to enabling or disabling sub menu
   */
  hideSubMenu?: boolean
  locale?: string | null
  /**
   * Minimal prop when is set as true then shows the site in a truncated version (without main menu)
   * Used on pages like login / reset password etc.
   */
  minimal?: boolean
  /**
   * List of route titles used in <Breadcrumb /> with custom properties like for example with child name
   * (where child name is get from API)
   */
  routeTitles?: any
  /**
   * Parameter is a true when <StickyFooter /> component is in used. This property is used to correct render UI.
   */
  stickyFooterMounted?: boolean
  /**
   * Parameter specifies whether to show modal about authorization devices after logging.
   */
  suppressDeviceWarning?: boolean
}
