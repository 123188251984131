import _ from 'lodash'
import { Property } from 'csstype'

import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'

import { SORT_ORDER } from 'constants/global'
import { IS_HEADER_PINNED_LS } from 'services/shell/constants'
import layout from 'constants/layout'

import { typeByObject } from 'utils/typescript'
import { useWindowSize } from 'utils/hooks'

import { Checkbox, Spinner, Typography } from 'components'

import i18n from 'translations'

import TableTr from './TableTr'
import TableTh from './TableTh'
import TableBlankTh from './TableBlankTh'
import TableSortableTh from './TableSortableTh'
import TableTd from './TableTd'
import TableBlankTd from './TableBlankTd'
import TableSummaryTd from './TableSummaryTd'
import TableTbody from './TableTbody'
import TableThead from './TableThead'
import {
  StyledContainer,
  StyledCustomScrollbarWrapper,
  StyledEndOfContent,
  StyledHeaderIconContainer,
  StyledImitationContentWidth,
  StyledStickyHeader,
  StyledStickyHeaderWrapper,
  StyledSubTitle,
} from './TableStyled'

export const VISUAL_TYPE = {
  BORDER: 'border',
  FLAT: 'flat',
  GRAY: 'gray',
  GRAY_ROUNDED: 'gray_rounded',
  NORMAL: 'normal',
  TRANSPARENT: 'transparent',
  TRANSPARENT_WITH_VERTICAL_BORDERS: 'transparentWithVerticalBorders',
}

export interface TableRoot<T> extends React.FC<PropsWithChildren<T>> {
  BlankTd?: typeof TableBlankTd
  BlankTh?: typeof TableBlankTh
  SortableTh?: typeof TableSortableTh
  SummaryTd?: typeof TableSummaryTd
  Tbody?: typeof TableTbody
  Td?: typeof TableTd
  Th?: typeof TableTh
  Thead?: typeof TableThead
  Tr?: typeof TableTr
}

export interface TableColumn {
  align?: string
  alignTh?: string
  background?: string
  field: string
  sortKey?: string
  sortable?: boolean
  subTitle?: string
  title?: string
  tooltip?: string
  width?: string
}

export interface TableProps {
  columns?: TableColumn[]
  data?: any
  disableOverflowPolyfill?: boolean
  disableSticky?: boolean
  isLoading?: boolean
  margin?: string
  maxWidth?: number
  minWidth?: number
  noDataText?: string
  noMargin?: boolean
  onSortChange?: (v: string) => void
  onTableSelect?: (v: number) => void
  onTableSelectAll?: () => void
  renderHeader?: (v?: boolean) => React.ReactNode
  selected?: number[]
  selectedAll?: boolean
  sortField?: string
  sortIconDown?: IconType
  sortIconHeight?: number
  sortIconUp?: IconType
  sortOrder?: typeByObject<typeof SORT_ORDER>
  tableLayout?: Property.TableLayout
  visualType?: typeByObject<typeof VISUAL_TYPE>
  width?: string
}

const Table: TableRoot<TableProps> = ({
  children,
  columns,
  data,
  disableSticky,
  sortField,
  sortOrder,
  selected,
  selectedAll,
  isLoading,
  margin,
  maxWidth,
  disableOverflowPolyfill,
  minWidth = 800,
  noDataText = i18n.t('components:Table:noData'),
  noMargin,
  renderHeader,
  tableLayout,
  visualType = VISUAL_TYPE.NORMAL,
  width,
  onSortChange,
  onTableSelectAll,
  onTableSelect,
  sortIconDown,
  sortIconUp,
  sortIconHeight,
}) => {
  const originalHeaderRef = useRef(null)
  const stickyHeaderRef = useRef(null)
  const tableRef = useRef(null)
  const containerRef = useRef(null)
  const endOfContentRef = useRef(null)
  const contentArtificialScrollableRef = useRef(null)
  const windowSize = useWindowSize()

  const [contentWidth, setContentWidth] = useState(0)
  const [originalScrollbarInView, setOriginalScrollbarInView] = useState(false)
  const [contentScrollWidth, setContentScrollWidth] = useState(0)

  const checkOriginalScrollbarIsInView = () => {
    const bounding = endOfContentRef.current?.getBoundingClientRect()
    const result = !!bounding
      && 0 <= bounding.top
      && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)

    setOriginalScrollbarInView(result)
  }

  useEffect(checkOriginalScrollbarIsInView, [])

  const calculateStickyPosition = () => {
    const tablePosition = tableRef.current?.getBoundingClientRect()
    const position = originalHeaderRef.current?.getBoundingClientRect()
    const isHeaderPinned = +window.localStorage.getItem(IS_HEADER_PINNED_LS)
    const menuBarHeight = (isHeaderPinned ? layout.menuBarHeight : 0)
    const profileHeaderTopPosition = document.querySelector('#profileHeaderBottom')?.getBoundingClientRect()?.top || 0
    const isOriginalHeaderInViewport = !!position
      && ((profileHeaderTopPosition || (menuBarHeight + layout.topBarHeight)) <= position.bottom)
      && 0 <= position.right
      && position.top <= document.documentElement.clientHeight
      && position.left <= document.documentElement.clientWidth
    const isTableInViewPort = (menuBarHeight + layout.topBarHeight) <= tablePosition.bottom

    checkOriginalScrollbarIsInView()

    if (
      !originalHeaderRef.current
      || isOriginalHeaderInViewport
      || position.top >= window.scrollY
      || !isTableInViewPort
    ) {
      if (stickyHeaderRef?.current?.style && 'hidden' !== stickyHeaderRef.current.style.visibility) {
        stickyHeaderRef.current.style.visibility = 'hidden'
      }

      return null
    }

    let show = false
    if ('visible' !== stickyHeaderRef?.current?.style?.visibility) {
      show = true
    }

    const originalThs = originalHeaderRef.current.querySelectorAll('th')
    const stickyThs = stickyHeaderRef.current.querySelectorAll('th')

    _.each(stickyThs, (th, index) => {
      // eslint-disable-next-line no-param-reassign
      th.style.width = `${originalThs[index].offsetWidth}px`
    })

    if (show && stickyHeaderRef?.current?.style) {
      stickyHeaderRef.current.style.visibility = 'visible'
      stickyHeaderRef.current.style.transform = 'translateY(-100%)'

      setTimeout(() => {
        if (stickyHeaderRef?.current?.style) {
          stickyHeaderRef.current.style.transform = 'translateY(0)'
        }
      }, 100)
    }

    stickyHeaderRef.current.style.top = `${
      profileHeaderTopPosition || (layout.topBarHeight + (isHeaderPinned ? layout.menuBarHeight : 0))
    }px`
    stickyHeaderRef.current.style.left = `${position.left + containerRef.current.scrollLeft}px`
    stickyHeaderRef.current.style.right = `${position.right + containerRef.current.scrollLeft}px`
    stickyHeaderRef.current.querySelector('thead').style.width = `${position.width}px`
    stickyHeaderRef.current.style.width = `${containerRef.current.offsetWidth}px`
    stickyHeaderRef.current.style.height = `${position.height}px`

    return false
  }

  useEffect(() => {
    if (!disableSticky) {
      window.addEventListener('scroll', calculateStickyPosition)
      window.addEventListener('resize', calculateStickyPosition)
      calculateStickyPosition()
    }

    return () => {
      if (!disableSticky) {
        window.removeEventListener('scroll', calculateStickyPosition)
        window.removeEventListener('resize', calculateStickyPosition)
      }
    }
  }, [disableSticky])

  const setScrollWidth = () => {
    setTimeout(() => {
      if (containerRef?.current) {
        const { offsetWidth } = containerRef.current || {}
        const contentElement = containerRef.current.querySelector('table')

        if (endOfContentRef?.current) {
          endOfContentRef.current.style.width = `${contentElement.offsetWidth}px`
        }

        setContentWidth(offsetWidth)
        setContentScrollWidth(contentElement.offsetWidth)
      }
    })
  }

  useEffect(setScrollWidth, [containerRef, windowSize])

  const getColumnIcon = (key) => {
    if (key === sortField && 'DESC' === sortOrder) {
      return sortIconDown || (VISUAL_TYPE.TRANSPARENT === visualType ? 'arrow-down' : 'sort-down')
    }

    if (key === sortField) {
      return sortIconUp || (VISUAL_TYPE.TRANSPARENT === visualType ? 'arrow-up' : 'sort-up')
    }

    return null
  }

  const renderColumn = (isStickyVersion) => (header, index) => {
    const {
      alignTh,
      background,
      field,
      headerIcon,
      sortKey,
      sortable,
      subTitle,
      title,
      tooltip,
      width: headerWidth,
    } = header

    if (sortable && field) {
      const key = sortKey || field
      const icon = getColumnIcon(key)

      return (
        <TableSortableTh
          align={alignTh}
          background={background}
          icon={icon}
          isStickyVersion={isStickyVersion}
          key={`${field}_${index}`}
          sortIconHeight={sortIconHeight}
          title={title}
          tooltip={tooltip}
          visualType={visualType}
          width={headerWidth}
          onClick={() => onSortChange(key)}
        />
      )
    }

    const renderColumnContent = () => {
      if (headerIcon) {
        return (
          <StyledHeaderIconContainer key={index}>
            {headerIcon}
            {title}
          </StyledHeaderIconContainer>
        )
      }

      if (subTitle) {
        return (
          <React.Fragment key={index}>
            <Typography bold>
              {title}
            </Typography>
            <StyledSubTitle>
              {subTitle}
            </StyledSubTitle>
          </React.Fragment>
        )
      }

      return title
    }

    return (
      <TableTh
        align={alignTh}
        background={background}
        isStickyVersion={isStickyVersion}
        key={index}
        tooltip={tooltip}
        visualType={visualType}
        width={headerWidth}
      >
        {renderColumnContent()}
      </TableTh>
    )
  }

  const renderDefaultHeader = (isStickyVersion) => (
    <TableTr>
      {selected && (
        <TableBlankTh>
          <Checkbox value={selectedAll} onChange={onTableSelectAll} />
        </TableBlankTh>
      )}
      {_.map(columns, renderColumn(isStickyVersion))}
    </TableTr>
  )

  const handleOriginalScroll = (e) => {
    if (stickyHeaderRef?.current) {
      stickyHeaderRef.current.scrollLeft = e.target.scrollLeft
    }

    if (contentArtificialScrollableRef?.current) {
      contentArtificialScrollableRef.current.scrollLeft = e.target.scrollLeft
    }
  }

  const handleArtificialScroll = (e) => {
    if (stickyHeaderRef?.current) {
      stickyHeaderRef.current.scrollLeft = e.target.scrollLeft
    }

    containerRef.current.scrollLeft = e.target.scrollLeft
  }

  const renderColumns = () => {
    if (!columns && !renderHeader) {
      return null
    }

    const isRenderHeaderFunction = _.isFunction(renderHeader)

    return (
      <React.Fragment>
        {isRenderHeaderFunction && (
          <thead ref={originalHeaderRef}>
            {renderHeader()}
          </thead>
        )}
        {columns && (
          <thead ref={originalHeaderRef}>
            {renderDefaultHeader(false)}
          </thead>
        )}
        {!disableSticky && (
          <StyledStickyHeaderWrapper ref={stickyHeaderRef}>
            <StyledStickyHeader $visualType={visualType}>
              {isRenderHeaderFunction ? renderHeader(true) : renderDefaultHeader(true)}
            </StyledStickyHeader>
          </StyledStickyHeaderWrapper>
        )}
      </React.Fragment>
    )
  }

  const renderDataRowItem = (row) => (column, index) => {
    const { field, verticalAlign = 'middle', ...rest } = column
    const content = row[field]

    return (
      <TableTd
        key={index}
        verticalAlign={verticalAlign}
        {...rest}
      >
        {content}
      </TableTd>
    )
  }

  const renderDataRow = (row, index) => {
    const { id, inactive, onClick } = row
    const checked = selectedAll || _.includes(selected, id)

    return (
      <TableTr
        inactive={inactive}
        key={id || index}
        onClick={onClick}
      >
        {selected && (
          <TableBlankTh>
            <Checkbox value={checked} onChange={() => onTableSelect(id)} />
          </TableBlankTh>
        )}
        {_.map(columns, renderDataRowItem(row))}
      </TableTr>
    )
  }

  const renderData = () => {
    if (!data) {
      return null
    }

    const colSpan = columns.length + (selected ? 1 : 0)

    if (isLoading) {
      return (
        <TableTr>
          <TableTd colSpan={colSpan}>
            <Spinner />
          </TableTd>
        </TableTr>
      )
    }

    if (!data.length) {
      return (
        <TableTr>
          <TableTd colSpan={colSpan}>
            {noDataText}
          </TableTd>
        </TableTr>
      )
    }

    return _.map(data, renderDataRow)
  }

  const renderContent = () => {
    if (!data) {
      return (
        <React.Fragment>
          {renderColumns()}
          {children}
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        {renderColumns()}
        <tbody>
          {renderData()}
        </tbody>
      </React.Fragment>
    )
  }

  return (
    <StyledContainer
      $disableOverflowPolyfill={disableOverflowPolyfill}
      $margin={margin}
      $maxWidth={maxWidth}
      $minWidth={minWidth}
      $noMargin={noMargin}
      $tableLayout={tableLayout}
      $visualType={visualType}
      $width={width}
      ref={containerRef}
      onScroll={handleOriginalScroll}
    >
      <table ref={tableRef}>
        {renderContent()}
      </table>
      {columns?.length && (
        <React.Fragment>
          <StyledEndOfContent ref={endOfContentRef} />
          <StyledCustomScrollbarWrapper
            $hidden={originalScrollbarInView || (contentWidth === contentScrollWidth)}
            $width={contentWidth}
            ref={contentArtificialScrollableRef}
            onScroll={handleArtificialScroll}
          >
            <StyledImitationContentWidth $width={contentScrollWidth} />
          </StyledCustomScrollbarWrapper>
        </React.Fragment>
      )}
    </StyledContainer>
  )
}

export default Table
