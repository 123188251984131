import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import { GET_LIKES_LIST, GET_LIKES_LIST_FAILED, GET_LIKES_LIST_SUCCESS } from './constants'

export const list = (options: BasicActionOptionsProps) => ({ likesApiClient }) => (
  generateBasicActions.list({
    constants: {
      failed: GET_LIKES_LIST_FAILED,
      init: GET_LIKES_LIST,
      success: GET_LIKES_LIST_SUCCESS,
    },
    options,
    service: likesApiClient,
    serviceMethod: 'list',
  })
)
