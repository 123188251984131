export const GET_NURSERY_EXTRA_ITEM = 'GET_NURSERY_EXTRA_ITEM'
export const GET_NURSERY_EXTRA_ITEM_SUCCESS = 'GET_NURSERY_EXTRA_ITEM_SUCCESS'
export const GET_NURSERY_EXTRA_ITEM_FAILED = 'GET_NURSERY_EXTRA_ITEM_FAILED'

export const CREATE_NURSERY_EXTRA_ITEM = 'CREATE_NURSERY_EXTRA_ITEM'
export const CREATE_NURSERY_EXTRA_ITEM_SUCCESS = 'CREATE_NURSERY_EXTRA_ITEM_SUCCESS'
export const CREATE_NURSERY_EXTRA_ITEM_FAILED = 'CREATE_NURSERY_EXTRA_ITEM_FAILED'

export const UPDATE_NURSERY_EXTRA_ITEM = 'UPDATE_NURSERY_EXTRA_ITEM'
export const UPDATE_NURSERY_EXTRA_ITEM_SUCCESS = 'UPDATE_NURSERY_EXTRA_ITEM_SUCCESS'
export const UPDATE_NURSERY_EXTRA_ITEM_FAILED = 'UPDATE_NURSERY_EXTRA_ITEM_FAILED'

export const CLEAR_NURSERY_EXTRA_ITEM = 'CLEAR_NURSERY_EXTRA_ITEM'

export const getExtraItem = (id, params) => ({ nurseryExtraItemsApiClient }) => (dispatch) => {
  dispatch({
    type: GET_NURSERY_EXTRA_ITEM,
  })

  nurseryExtraItemsApiClient.get(id, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: GET_NURSERY_EXTRA_ITEM_SUCCESS,
      })
    },
    (error) => {
      dispatch({
        error,
        type: GET_NURSERY_EXTRA_ITEM_FAILED,
      })
    },
  )
}

export const createExtraItem = (payload, params, onSuccess) => ({ nurseryExtraItemsApiClient }) => (dispatch) => {
  dispatch({
    params,
    payload,
    type: CREATE_NURSERY_EXTRA_ITEM,
  })

  return nurseryExtraItemsApiClient.create(payload, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: CREATE_NURSERY_EXTRA_ITEM_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: CREATE_NURSERY_EXTRA_ITEM_FAILED,
      })
    },
  )
}

export const updateExtraItem = (id, payload, params, onSuccess) => ({ nurseryExtraItemsApiClient }) => (dispatch) => {
  dispatch({
    id,
    params,
    payload,
    type: UPDATE_NURSERY_EXTRA_ITEM,
  })

  nurseryExtraItemsApiClient.update(id, payload, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: UPDATE_NURSERY_EXTRA_ITEM_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: UPDATE_NURSERY_EXTRA_ITEM_FAILED,
      })
    },
  )
}

export const clearExtraItem = () => ({
  type: CLEAR_NURSERY_EXTRA_ITEM,
})
