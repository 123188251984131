import React, { Component } from 'react'

import SelectFormatModalView from './SelectFormatModalView'

const FORMAT_OPTIONS = [
  {
    label: 'PDF',
    value: 'pdf',
  },
  {
    label: 'CSV',
    value: 'csv',
  },
]

class SelectFormatModalContainer extends Component {
  handleSubmit = ({ format: { value } }) => {
    const { onSubmit } = this.props

    this.handleCloseClick()
    onSubmit(value)
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  render() {
    const { title } = this.props

    return (
      <SelectFormatModalView
        formatOptions={FORMAT_OPTIONS}
        title={title}
        onCloseClick={this.handleCloseClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

export default SelectFormatModalContainer
