import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withChildDiscountsService } from 'services/legacy/childDiscounts'
import { CHILD_DISCOUNTS_FILTER, CHILD_DISCOUNTS_FILTER_OPTIONS } from 'services/legacy/childDiscounts/constants'

import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withSortingUtils } from 'services/utils/sorting'

import ChildDiscountsView from './ChildDiscountsView'
import { getTableData } from './ChildDiscountsHelpers'

const CHILD_DISCOUNTS_GROUPS = {
  read: [
    'childDiscount.nurseryDiscount',
    'childDiscount.child',
    'nurseryDiscount',
  ],
}

class ChildDiscountsContainer extends Component {
  constructor(props) {
    super(props)

    const { query } = props.location
    const { status } = query

    this.state = {
      status: status || CHILD_DISCOUNTS_FILTER.ACTIVE,
    }
  }

  componentDidMount() {
    this.fetch()
  }

  fetch = () => {
    const {
      childDiscountsActions,
      childDiscountsSelectors,
      paginationUtils,
      params,
      setLocationQuery,
      sortingUtils,
    } = this.props
    const { status } = this.state
    const { childId } = params
    const { page } = paginationUtils
    const { order } = sortingUtils

    const criteria = childDiscountsSelectors.getCriteriaSelector({ ...{ status }, childId })

    setLocationQuery({ status })

    const apiParams = {
      criteria,
      groups: CHILD_DISCOUNTS_GROUPS,
      order,
      page,
    }

    childDiscountsActions.list(apiParams)
  }

  handleStatusChange = (status) => {
    this.setState({ status: status && status.value }, this.fetch)
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleSortChange = (key) => {
    const { paginationUtils, sortingUtils } = this.props
    const { onSortChange } = sortingUtils
    const { onPageChange } = paginationUtils

    onSortChange(onPageChange(this.fetch)(1))(key)
  }

  render() {
    const {
      authAccessMap,
      child,
      childDiscounts,
      childDiscountsListState,
      errorMessages,
      isEmpty,
      isFetching,
      paginationUtils,
      params,
      sortingUtils,
    } = this.props
    const { status } = this.state
    const { getPageCount, page, perPage } = paginationUtils
    const { sortField, sortOrder } = sortingUtils
    const { childId } = params

    const isLoading = isFetching
    const pageCount = getPageCount(childDiscountsListState.meta.total_results)
    const tableData = getTableData(childDiscounts, childId)

    return (
      <ChildDiscountsView
        authAccessMap={authAccessMap}
        child={child}
        childDiscountsTableData={tableData}
        errorMessages={errorMessages}
        isEmpty={isEmpty}
        isLoading={isLoading}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        sortField={sortField}
        sortOrder={sortOrder}
        status={status}
        statusOptions={CHILD_DISCOUNTS_FILTER_OPTIONS}
        totalResults={childDiscountsListState.meta.total_results}
        onPageChange={this.handlePageChange}
        onSortChange={this.handleSortChange}
        onStatusChange={this.handleStatusChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childDiscountsListState,
  childDiscountsSelectors,
  childSelectors,
}) => ({
  child: childSelectors.getChildSelector(state),
  childDiscounts: childDiscountsSelectors.getChildDiscountsListSelector(state),
  errorMessages: appSelectors.getErrorMessages(childDiscountsListState),
  isEmpty: childDiscountsSelectors.isChildDiscountsEmpty(state),
  isFetching: appSelectors.getIsFetching(childDiscountsListState),
})

const enhance = compose(
  withAppService,
  withChildService,
  withChildDiscountsService,
  withPaginationUtils,
  withRouterUtils,
  withSortingUtils,
  connect(mapState),
)

export default enhance(ChildDiscountsContainer)
