import _ from 'lodash'
import moment, { Moment } from 'moment'
import { flatten, nest } from 'utils/flatnest'

import { useEffect, useMemo, useState } from 'react'

import { Option } from 'constants/models'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

interface OptionProps extends Option {
  ageRange?: any
}

type DateProps = {
  after?: Moment
  before?: Moment
} | any | undefined

interface GenericOptionsProps extends OptionProps {
  [key: string]: any
}

interface FilterProps {
  age: number
  date: DateProps
  year: string
}

export interface GetTableDataParamProps<D = undefined> {
  data: D
  filters?: FilterProps
  hasError?: boolean
  isOrganizationContext: boolean
}

interface useBasicReportProps {
  actions?: any
  ageOptions?: GenericOptionsProps[]
  allocationOptions?: GenericOptionsProps[]
  breakdownModeOptions?: GenericOptionsProps[]
  breakdownOptions?: GenericOptionsProps[]
  callStatisticsAction?: any
  childPolyfill?: boolean
  childrenApiGroups?: {}
  childrenData?: any
  childrenOrder?: {
    sortField: string
    sortOrder?: string
  }
  childrenTotalResults?: number
  dateTypeOptions?: GenericOptionsProps[]
  defaultFilters?: { [key: string]: any }
  errorMessages?: string | string[]
  getColumns?: any
  getStatistics?: any
  getTableData?: any
  isDateRangeFilter?: boolean
  isOrganizationContext?: boolean
  location?: any
  nurseriesApiGroups?: { [key: string]: any }
  nurseriesData?: any
  nurseriesTotalResults?: number
  nurseryOrder?: {
    sortField: string
    sortOrder?: string
  }
  paginationUtils?: any
  selectors?: any
  setLocationQuery?: any
  statisticsApiGroups?: { [key: string]: any }
  statisticsData?: any
  statusOptions?: GenericOptionsProps[]
  yearOptions?: GenericOptionsProps[]
}

const useBasicReport = ({
  actions,
  ageOptions,
  allocationOptions,
  breakdownModeOptions,
  breakdownOptions,
  callStatisticsAction,
  childPolyfill,
  childrenApiGroups,
  childrenData,
  childrenOrder,
  childrenTotalResults,
  dateTypeOptions,
  defaultFilters,
  errorMessages,
  getColumns,
  getStatistics,
  getTableData,
  isDateRangeFilter = true,
  isOrganizationContext,
  location,
  nurseriesApiGroups,
  nurseriesData,
  nurseriesTotalResults,
  nurseryOrder,
  paginationUtils,
  selectors,
  setLocationQuery,
  statisticsApiGroups,
  statisticsData,
  statusOptions,
  yearOptions,
}: useBasicReportProps) => {
  const [allocation, setAllocation] = useState<OptionProps>()
  const [date, setDate] = useState<DateProps>()
  const [room, setRoom] = useState<OptionProps>()
  const [age, setAge] = useState<OptionProps>()
  const [status, setStatus] = useState<OptionProps>()
  const [nursery, setNursery] = useState<OptionProps>()
  const [breakdown, setBreakdown] = useState<OptionProps>()
  const [breakdownMode, setBreakdownMode] = useState<OptionProps>()
  const [dateType, setDateType] = useState<OptionProps>()
  const [year, setYear] = useState<OptionProps>()
  const [author, setAuthor] = useState<OptionProps>()
  const [child, setChild] = useState<OptionProps>()
  const [fundingTypes, setFundingTypes] = useState<OptionProps | OptionProps[]>()

  const filterValues = useMemo(() => ({
    age: age?.value,
    allocation: allocation?.value,
    author: author?.value,
    breakdown: breakdown?.value,
    breakdownMode: breakdownMode?.value,
    child: child?.value,
    date,
    dateType: dateType?.value,
    fundingType: _.isArray(fundingTypes) ? _.map(fundingTypes, ({ value }) => value) : fundingTypes?.value,
    nursery,
    room: room?.value,
    status: status?.value,
    year: year?.value,
  }), [
    age,
    allocation,
    author,
    breakdown,
    breakdownMode,
    child,
    date,
    dateType,
    fundingTypes,
    nursery,
    room,
    status,
    year,
  ])

  const data = useMemo(() => childrenData || nurseriesData, [childrenData, nurseriesData])
  const totalResults = useMemo(() => childrenTotalResults || nurseriesTotalResults, [
    childrenTotalResults,
    nurseriesTotalResults,
  ])
  const dateRange = useMemo(() => (
    isDateRangeFilter && date
      ? [date.after, date.before]
      : []
  ), [date?.after, date?.before])

  const tableColumns = useMemo(() => getColumns(
    isOrganizationContext,
    data,
    statisticsData,
    filterValues,
  ), [data, statisticsData])
  const tableData = useMemo(() => getTableData({
    data,
    filters: filterValues,
    hasError: !!errorMessages,
    isOrganizationContext,
  }), [childrenData, nurseriesData, breakdown, isOrganizationContext])
  const statistics = useMemo(() => getStatistics({
    data: statisticsData,
    filters: filterValues,
    hasError: !!errorMessages,
    isOrganizationContext,
  }), [statisticsData, breakdown])

  const { getPageCount, page, perPage } = paginationUtils
  const pageCount = getPageCount(totalResults)

  const getDefaultFilters = () => {
    const hasDateDefaultValue = _.has(defaultFilters?.date, 'after') || _.has(defaultFilters?.date, 'before')
    const after = hasDateDefaultValue
      ? defaultFilters?.date?.after
      : moment(moment().endOf('month')).add(-1, 'year').add(1, 'day')
    const before = hasDateDefaultValue
      ? defaultFilters?.date?.before
      : moment().endOf('month')

    return {
      allocation: defaultFilters?.allocation,
      child: defaultFilters?.child,
      date: isDateRangeFilter ? {
        after,
        before,
      } : (defaultFilters?.date || moment()),
      status: defaultFilters?.status,
      year: defaultFilters?.year,
    }
  }

  // Initial load variables
  useEffect(() => {
    const { query = {} } = location
    const {
      age: queryAge,
      allocation: queryAllocation,
      author: queryAuthor,
      breakdown: queryBreakdown,
      breakdownMode: queryBreakdownMode,
      child: queryChild,
      date: queryDate,
      dateType: queryDateType,
      fundingTypes: queryFundingTypes,
      room: queryRoom,
      status: queryStatus,
      year: queryYear,
    } = nest(query)

    const finalDefaultFilters = getDefaultFilters()

    if (isDateRangeFilter) {
      const before = queryDate && queryDate.before ? moment(queryDate.before) : finalDefaultFilters.date.before
      const after = queryDate && queryDate.after ? moment(queryDate.after) : finalDefaultFilters.date.after

      setDate({ after, before })
    } else {
      const finalSingleDate = queryDate ? moment(queryDate) : finalDefaultFilters.date

      setDate(finalSingleDate)
    }

    if (queryAge || ageOptions?.length) {
      setAge(_.find(ageOptions, { value: queryAge }))
    }

    setRoom(queryRoom)

    if (queryBreakdown || breakdownOptions?.length) {
      setBreakdown(queryBreakdown ? _.find(breakdownOptions, { value: queryBreakdown }) : breakdownOptions[0])
    }

    if (queryBreakdownMode || defaultFilters?.breakdownMode || breakdownModeOptions?.length) {
      if (queryBreakdownMode) {
        setBreakdownMode(_.find(breakdownModeOptions, { value: queryBreakdownMode }))
      } else {
        setBreakdownMode(defaultFilters?.breakdownMode)
      }
    }

    if (queryDateType || dateTypeOptions?.length) {
      setDateType(queryDateType ? _.find(dateTypeOptions, { value: queryDateType }) : dateTypeOptions?.[0])
    }

    if (queryStatus || statusOptions?.length) {
      setStatus(_.find(statusOptions, { value: queryStatus }) || finalDefaultFilters?.status)
    }

    if (queryAllocation || allocationOptions?.length) {
      setAllocation(_.find(allocationOptions, { value: queryAllocation }) || finalDefaultFilters?.allocation)
    }

    if (queryYear || yearOptions?.length) {
      setYear(_.find(yearOptions, { value: queryYear }) || finalDefaultFilters?.year)
    }

    setAuthor(queryAuthor)
    setFundingTypes(queryFundingTypes)
    setChild(childPolyfill ? finalDefaultFilters.child : (queryChild || finalDefaultFilters.child))

    return () => {
      actions.clearChildren()
      actions.clearNurseries()

      if (actions.getStatistics) {
        actions.clearStatistics()
      }
    }
  }, [])

  // Call nursery context api's
  useEffect(() => {
    if (date || age || room || year) {
      setLocationQuery(flatten({
        age: age?.value,
        allocation: allocation?.value,
        author: author?.value,
        breakdownMode: breakdownMode?.value,
        child: child?.value,
        date: isDateRangeFilter ? {
          after: date?.after?.format(DEFAULT_DATE_FORMAT),
          before: date?.before?.format(DEFAULT_DATE_FORMAT),
        } : date.format(DEFAULT_DATE_FORMAT),
        dateType: dateType?.value,
        fundingTypes: _.isArray(fundingTypes) ? _.map(fundingTypes, ({ value }) => value) : fundingTypes?.value,
        nursery: nursery?.value,
        room: room?.value,
        status: status?.value,
        year: year?.value,
      }))

      const criteria = selectors.getCriteria({
        ageRange: age?.ageRange,
        allocation: allocation?.value,
        author: author?.value,
        breakdownMode: breakdownMode?.value,
        child: child?.value,
        date,
        dateRange: date,
        dateType: dateType?.value,
        fundingTypes: _.isArray(fundingTypes) ? _.map(fundingTypes, ({ value }) => value) : fundingTypes?.value,
        nursery,
        room: room?.value,
        status: status?.value,
        year: year?.value,
      })

      if (isOrganizationContext) {
        actions.getNurseries({
          params: [{
            criteria,
            groups: nurseriesApiGroups,
            order: nurseryOrder,
            page,
          }],
        })
      } else {
        actions.getChildren({
          params: [{
            criteria,
            groups: childrenApiGroups,
            order: childrenOrder,
            page,
          }],
        })
      }
    }
  }, [
    age,
    allocation,
    author,
    breakdownMode,
    child,
    date,
    dateType,
    fundingTypes,
    nursery,
    page,
    room,
    status,
    year,
  ])

  // Call organization context api's
  useEffect(() => {
    if (date && (isOrganizationContext || callStatisticsAction) && actions.getStatistics) {
      const getCriteria = selectors.getStatisticsCriteria || selectors.getCriteria

      const criteria = getCriteria({
        ageRange: age?.ageRange,
        allocation: allocation?.value,
        author: author?.value,
        breakdownMode: breakdownMode?.value,
        child: child?.value,
        date,
        dateRange: date,
        dateType: dateType?.value,
        fundingTypes: _.isArray(fundingTypes) ? _.map(fundingTypes, ({ value }) => value) : fundingTypes?.value,
        nursery,
        room: room?.value,
        status: status?.value,
        year: year?.value,
      })

      actions.getStatistics({
        params: [{
          criteria,
          groups: statisticsApiGroups,
        }],
      })
    }
  }, [
    age,
    allocation,
    author,
    breakdownMode,
    child,
    date,
    dateType,
    fundingTypes,
    nursery,
    room,
    status,
    year,
  ])

  useEffect(() => {
    setLocationQuery({
      breakdown: breakdown?.value,
    })
  }, [breakdown])

  const handleDateChange = (selectedDate: [Moment, Moment]) => {
    if (isDateRangeFilter) {
      setDate({
        after: selectedDate[0],
        before: selectedDate[1],
      })

      return
    }

    setDate(selectedDate)
  }

  const handlePageChange = (selectedPage, nextId) => {
    const { onPageChange } = paginationUtils

    onPageChange()(selectedPage, nextId)
  }

  const handleAgeChange = (option) => {
    setAge(option)
  }

  const handleRoomChange = (option) => {
    setRoom(option)
  }

  const handleNurseryChange = (option) => {
    setNursery(option)
  }

  const handleBreakdownChange = (option) => {
    setBreakdown(option)
  }

  const handleDateTypeChange = (option) => {
    setDateType(option)
  }

  const handleStatusChange = (option) => {
    setStatus(option)
  }

  const handleAllocationChange = (option) => {
    setAllocation(option)
  }

  const handleBreakdownModeChange = (option) => {
    setBreakdownMode(option)
  }

  const handleYearChange = (option) => {
    setYear(option)
  }

  const handleAuthorChange = (option) => {
    setAuthor(option)
  }

  const handleChildChange = (option) => {
    setChild(option)
  }

  const handleFundingTypes = (option) => {
    setFundingTypes(option)
  }

  return {
    age,
    allocation,
    author,
    breakdown,
    breakdownMode,
    child,
    date,
    dateRange,
    dateType,
    fundingTypes,
    nursery,
    onAgeChange: handleAgeChange,
    onAllocationChange: handleAllocationChange,
    onAuthorChange: handleAuthorChange,
    onBreakdownChange: handleBreakdownChange,
    onBreakdownModeChange: handleBreakdownModeChange,
    onChildChange: handleChildChange,
    onDateChange: handleDateChange,
    onDateTypeChange: handleDateTypeChange,
    onFundingTypesChange: handleFundingTypes,
    onNurseryChange: handleNurseryChange,
    onPageChange: handlePageChange,
    onRoomChange: handleRoomChange,
    onStatusChange: handleStatusChange,
    onYearChange: handleYearChange,
    page,
    pageCount,
    perPage,
    room,
    statistics,
    status,
    tableColumns,
    tableData,
    totalResults,
    year,
  }
}

export default useBasicReport
