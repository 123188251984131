import React from 'react'

import { Chart } from 'components'

const legendConfig = {
  backgroundColor: '#F5F5F5',
  borderRadius: 10,
  enabled: true,
  margin: 30,
  padding: 15,
  verticalAlign: 'top',
}

const config = {
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: false,
      },
    },
  },
  tooltip: {
    shared: true,
  },
}

const RevenueChart = ({ chart }) => {
  if (!chart) {
    return null
  }

  return (
    <Chart.Column
      categories={chart.categories}
      config={config}
      data={chart.data}
      legend={legendConfig}
      isCurrencyValue
    />
  )
}

export default RevenueChart
