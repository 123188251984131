import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { Form } from 'components'

import i18n from 'translations'

import { REPORT_TYPE_OPTIONS, SHOW_ALL_OR_SOME_CHILDREN_OPTIONS } from '../constants'

import { StyledForm } from './DietaryReportExportFormStyled'

export const DIETARY_REPORT_EXPORT_FORM = 'DietaryReportExportForm'

const DietaryReportExportForm = ({
  handleSubmit,
  isProcessing,
  isReportTypeAllergiesAndDietaryPreferences,
  onCloseClick,
  onReportTypeChange,
  onShowAllOrSomeChildrenChange,
  onSubmit,
}) => (
  <StyledForm>
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Row
        label={i18n.t('module:Modals:ExportReportModal:User:Label:pdf')}
        verticalLabel
      >
        <Field
          component={Form.InfiniteDropdowns.OrganizationMemberships}
          name="membership"
          placeholder={i18n.t('module:Modals:ExportReportModal:User:placeholder')}
          validate={isRequired}
        />
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Modals:ExportReportModal:selectRoom')}
        verticalLabel
      >
        <Field
          component={Form.InfiniteDropdowns.Rooms}
          name="nurseryClass"
        />
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Modals:DietaryReportExportModal:ReportType:label')}
        verticalLabel
      >
        <Form.Row.Item>
          <Field
            component={Form.RadioGroup}
            name="reportType"
            options={REPORT_TYPE_OPTIONS}
            validate={isRequired}
            onChange={onReportTypeChange}
          />
        </Form.Row.Item>
      </Form.Row>
      {isReportTypeAllergiesAndDietaryPreferences && (
        <Form.Row
          label={i18n.t('module:Modals:DietaryReportExportModal:ShowAllOrSomeChildren:label')}
          verticalLabel
        >
          <Form.Row.Item>
            <Field
              component={Form.RadioGroup}
              name="showAllOrSomeChildren"
              options={SHOW_ALL_OR_SOME_CHILDREN_OPTIONS}
              validate={isRequired}
              onChange={onShowAllOrSomeChildrenChange}
            />
          </Form.Row.Item>
        </Form.Row>
      )}
      <Form.FooterActions
        isSubmitting={isProcessing}
        submitLabel={i18n.t('global:Export')}
        onCancelClick={onCloseClick}
      />
    </Form>
  </StyledForm>
)

export default reduxForm({ form: DIETARY_REPORT_EXPORT_FORM })(DietaryReportExportForm)
