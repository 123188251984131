import { Property } from 'csstype'

import React, { PropsWithChildren } from 'react'

import { StyledGroupContainer } from './ToolbarStyled'

interface ToolbarGroupProps {
  alignItems?: Property.AlignItems
  fullWidth?: boolean
  justifyContent?: Property.JustifyContent
}

const ToolbarGroup: React.FC<PropsWithChildren<ToolbarGroupProps>> = ({
  alignItems,
  children,
  fullWidth,
  justifyContent,
}) => (
  <StyledGroupContainer
    $alignItems={alignItems}
    $fullWidth={fullWidth}
    $justifyContent={justifyContent}
  >
    {children}
  </StyledGroupContainer>
)

export default ToolbarGroup
