import { generateBasicActions } from 'utils/service'

import {
  ADD_FRAMEWORK_TO_LIST,
  CLEAR_NURSERY_FRAMEWORK_RELATIONS,
  LIST_NURSERY_FRAMEWORK_RELATIONS,
  LIST_NURSERY_FRAMEWORK_RELATIONS_FAILED,
  LIST_NURSERY_FRAMEWORK_RELATIONS_SUCCESS,
  REMOVE_FRAMEWORK_FROM_LIST,
} from './constants'

export const list = (options = {}) => ({ nurseryFrameworkRelationsApiClient }) => generateBasicActions.list({
  constants: {
    failed: LIST_NURSERY_FRAMEWORK_RELATIONS_FAILED,
    init: LIST_NURSERY_FRAMEWORK_RELATIONS,
    success: LIST_NURSERY_FRAMEWORK_RELATIONS_SUCCESS,
  },
  options,
  service: nurseryFrameworkRelationsApiClient,
  serviceMethod: 'list',
})

export const addToList = (data) => ({
  payload: { data },
  type: ADD_FRAMEWORK_TO_LIST,
})

export const removeFromList = (id) => ({
  payload: { id },
  type: REMOVE_FRAMEWORK_FROM_LIST,
})

export const clearList = () => ({
  type: CLEAR_NURSERY_FRAMEWORK_RELATIONS,
})
