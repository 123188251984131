import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Callout, Section, Spinner, Typography } from 'components'
import Settings from 'module/Settings/components'

export const FORM_NAME = 'EditInvoiceNumbersItemForm'

const SettingsInvoiceNumbersItemEditView = ({
  errorMessages,
  initialValues,
  isFormLoading,
  isLoading,
  nurseryId,
  nurseryName,
  onSubmit,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    const title = (
      <span>
        {'Edit invoice number sequencing '}
        <Typography color={NEUTRAL_COLOURS.BLUE} fontSize="inherit" variant="span" bold>
          {nurseryName}
        </Typography>
      </span>
    )

    return (
      <Section title={title}>
        <Settings.InvoiceNumbersSettingForm
          cancelButtonLink={`/settings/invoice-numbers/${nurseryId}/view`}
          form="EditInvoiceNumbersItemForm"
          initialValues={initialValues}
          isLoading={isFormLoading}
          onSubmit={onSubmit}
        />
      </Section>
    )
  }

  return (
    <div>
      <Callout content={errorMessages} error />
      {renderContent()}
    </div>
  )
}

export default SettingsInvoiceNumbersItemEditView
