import {
  CLEAR_MEMBERSHIP_REGISTERS_LIST,
  GET_MEMBERSHIP_REGISTERS_LIST,
  GET_MEMBERSHIP_REGISTERS_LIST_FAILED,
  GET_MEMBERSHIP_REGISTERS_LIST_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ membershipRegistersApiClient }) => (dispatch) => {
  const { mergeResult, onlyData, onFailed, onSuccess, params = {} } = options

  if (!onlyData) {
    dispatch({ type: GET_MEMBERSHIP_REGISTERS_LIST })
  }

  membershipRegistersApiClient.list(params).then(
    (response) => {
      if (!onlyData) {
        dispatch({
          mergeResult,
          payload: response,
          type: GET_MEMBERSHIP_REGISTERS_LIST_SUCCESS,
        })
      }

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      if (!onlyData) {
        dispatch({
          error: response,
          type: GET_MEMBERSHIP_REGISTERS_LIST_FAILED,
        })
      }

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const clearList = () => ({
  type: CLEAR_MEMBERSHIP_REGISTERS_LIST,
})
