import { Property } from 'csstype'

import React, { PropsWithChildren } from 'react'

import DefinitionListItem from './DefinitionListItem'
import { StyledContainer } from './DefinitionListStyled'

interface DefinitionListProps {
  fullWidth?: boolean
  labelWidth?: string
  margin?: string
  minLabelWidth?: string
  overflow?: Property.Overflow
  vertical?: boolean
}

interface DefinitionListRoot<T> extends React.FC<PropsWithChildren<T>> {
  Item?: typeof DefinitionListItem
}

const DefinitionList: DefinitionListRoot<DefinitionListProps> = ({
  children,
  fullWidth,
  labelWidth,
  margin,
  minLabelWidth,
  overflow,
  vertical,
}) => (
  <StyledContainer
    $fullWidth={fullWidth}
    $labelWidth={labelWidth}
    $margin={margin}
    $minLabelWidth={minLabelWidth}
    $overflow={overflow}
    $vertical={vertical}
  >
    {children}
  </StyledContainer>
)

export default DefinitionList
