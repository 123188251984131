import React from 'react'

import { Button, DetailsBreakdown, FormatText, NurseryLogo, Typography } from 'components'
import OpeningDays from 'module/Settings/components/OpeningDays'

import i18n from 'translations'

import FeatureFlagsSection from 'module/Organisations/OrganisationsPreview/components/FeatureFlagsSection'
import { getOpeningDays } from 'module/Management/ManagementNursery/helpers'

import FrameworkSection from './FrameworkSection'

const NurseryDetailsBreakdown = ({
  featureFlags,
  nursery,
  nurseryPublicUrl,
  organizationSuffix = '',
  showCenteredLogo,
  showFeatureFlags,
  showFooter,
  showFrameworks,
  showHeader,
  showOpeningTimes,
  showPrimaryContact,
}) => {
  const {
    address,
    archived,
    email,
    logo,
    name,
    nurserySettings,
    ofstedId,
    organization,
    postCode,
    primaryContact,
    subdomain,
    telephone,
  } = nursery || {}

  const { fullName } = primaryContact || {}

  const {
    openingDays,
    startWeekFrom,
  } = nurserySettings || {}

  const renderHeader = () => showHeader && (
    <DetailsBreakdown.Header>
      <Typography fontSize={28} fontWeight={300}>
        {i18n.t('module:Nurseries:Add:NurseryDetails:title')}
      </Typography>
    </DetailsBreakdown.Header>
  )

  const renderCenteredLogo = () => showCenteredLogo && logo && (
    <DetailsBreakdown.Item>
      <NurseryLogo
        alt={name}
        logo={logo?.value ? logo.value : logo}
      />
    </DetailsBreakdown.Item>
  )

  const renderLogo = () => !showCenteredLogo && (
    <DetailsBreakdown.Item
      label={i18n.t('global:Logo')}
      value={<NurseryLogo alt={name} logo={logo} />}
    />
  )

  const renderPrimaryContact = () => showPrimaryContact && (
    <DetailsBreakdown.Item
      label={i18n.t('module:Nurseries:Preview:primaryManager')}
      value={fullName}
    />
  )

  const renderFooter = () => showFooter && (
    <DetailsBreakdown.Footer>
      <Button
        label={i18n.t('module:Nurseries:Preview:viewSetting')}
        target="_blank"
        to={nurseryPublicUrl}
      />
    </DetailsBreakdown.Footer>
  )

  const renderFeatureFlagsSection = () => showFeatureFlags && (
    <FeatureFlagsSection featureFlags={featureFlags} />
  )

  const newOpeningDays = getOpeningDays(openingDays)

  const renderOpeningTimes = () => showOpeningTimes && (
    <React.Fragment>
      <DetailsBreakdown.Item
        label={i18n.t('module:Management:Nursery:openingTimes')}
        value={openingDays && <OpeningDays openingDays={newOpeningDays} />}
      />
      <DetailsBreakdown.Item
        label={i18n.t('module:Management:Nursery:startWeekFrom')}
        value={startWeekFrom || '-'}
      />
    </React.Fragment>
  )

  const renderMainSection = () => (
    <DetailsBreakdown.Section>
      {renderHeader()}
      {renderCenteredLogo()}
      <DetailsBreakdown.Item
        label={i18n.t('global:Name')}
        value={archived ? `${name} (${i18n.t('global:disabled')})` : name}
      />
      <DetailsBreakdown.Item
        label={i18n.t('global:Organisation')}
        value={(
          organization?.value || organization?.id
            ? (organization?.label || organization?.name)
            : i18n.t('module:Organisations:Add:Form:independentNursery')
        )}
      />
      <DetailsBreakdown.Item
        label={i18n.t('global:Subdomain')}
        value={subdomain && (subdomain + organizationSuffix)}
      />
      <DetailsBreakdown.Item
        label={i18n.t('module:Nurseries:Preview:country')}
        value={nurserySettings?.countryName || nurserySettings?.locale?.label}
      />
      <DetailsBreakdown.Item
        label={i18n.t('module:Nurseries:Preview:currency')}
        value={nurserySettings?.currencyName || nurserySettings?.currency?.label}
      />
      {renderLogo()}
      <DetailsBreakdown.Item
        label={i18n.t('module:Nurseries:Preview:ofstedId')}
        value={ofstedId}
      />
      <DetailsBreakdown.Item
        label={i18n.t('global:Email')}
        value={<a href={`mailto:${email}`}>{email}</a>}
      />
      <DetailsBreakdown.Item
        label={i18n.t('global:Address')}
        value={<FormatText lines={[address, postCode]} />}
      />
      <DetailsBreakdown.Item
        label={i18n.t('global:Telephone')}
        value={telephone}
      />
      {renderPrimaryContact()}
      {renderOpeningTimes()}
      {renderFooter()}
    </DetailsBreakdown.Section>
  )

  return (
    <DetailsBreakdown>
      {renderMainSection()}
      {renderFeatureFlagsSection()}
      {showFrameworks && <FrameworkSection />}
    </DetailsBreakdown>
  )
}

export default NurseryDetailsBreakdown
