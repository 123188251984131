import _ from 'lodash'

import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router'

import { isRequired } from 'utils/fieldValidation'

import { generateRoute } from 'utils/routing'
import { convertTimeDuration } from 'utils/date'

import { Banner, Form, Typography } from 'components'

import i18n from 'translations'

import { ALL_CHILDREN } from '../constants'
import { getExportForOptions } from '../helpers'

export const INVOICES_EXPORT_MODAL_FORM = 'InvoicesExportModalForm'

const ExportModalForm = ({
  enableExtendedExport,
  handleSubmit,
  isProcessing,
  onCloseClick,
  onSubmit,
}) => {
  const renderForm = () => (
    <React.Fragment>
      <Form.Row
        label={i18n.t('module:Modals:InvoicesExport:ExportFor:label')}
        verticalLabel
      >
        <Field
          component={Form.RadioGroup}
          name="exportFor"
          options={getExportForOptions(enableExtendedExport)}
          validate={isRequired}
        />
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Modals:InvoicesExport:DateRange:label')}
        verticalLabel
      >
        <Field
          component={Form.DatePicker}
          name="dateRange"
          validate={isRequired}
          range
        />
      </Form.Row>
      <Form.Row
        label={_.upperFirst(i18n.t('global:child'))}
        verticalLabel
      >
        <Field
          component={Form.InfiniteDropdowns.Children}
          extraOptions={[ALL_CHILDREN]}
          name="child"
          placeholder={_.upperFirst(i18n.t('global:child'))}
          validate={isRequired}
        />
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Modals:InvoicesExport:SendTo:label')}
        verticalLabel
      >
        <Field
          component={Form.InfiniteDropdowns.OrganizationMemberships}
          name="sendTo"
          placeholder={i18n.t('module:Modals:InvoicesExport:SendTo:label')}
          validate={isRequired}
        />
      </Form.Row>
      <Form.Row
        label={i18n.t('global:Export')}
        verticalLabel
      >
        <Field
          component={Form.Checkbox}
          label={i18n.t('module:Modals:InvoicesExport:Export:invoices')}
          name="invoices"
        />
      </Form.Row>
      <Form.Row>
        <Field
          component={Form.Checkbox}
          label={i18n.t('module:Modals:InvoicesExport:Export:creditNotes')}
          name="creditNotes"
        />
      </Form.Row>
    </React.Fragment>
  )

  const renderFooter = () => (
    <Form.FooterActions
      isSubmitting={isProcessing}
      submitLabel={i18n.t('module:Modals:InvoicesExport:sendExport')}
      onCancelClick={onCloseClick}
    />
  )

  const renderInvoicesSettingsLink = () => (
    <Banner.Info margin="0 0 20px">
      <Typography linkColorInherit={false}>
        {i18n.t('module:Modals:InvoicesExport:LinkToInvoicesSettings:text')}
        {' '}
        <Typography variant="span" primary>
          <Link to={generateRoute('MANAGEMENT.FINANCE_SETTINGS')} onClick={onCloseClick}>
            {i18n.t('module:Modals:InvoicesExport:LinkToInvoicesSettings:link')}
          </Link>
        </Typography>
        {'.'}
      </Typography>
    </Banner.Info>
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {renderInvoicesSettingsLink()}
      {renderForm()}
      {renderFooter()}
    </Form>
  )
}

const validate = (fields) => {
  const errors = {}

  const { creditNotes, dateRange, invoices } = fields || {}

  if (!invoices && !creditNotes) {
    errors.creditNotes = i18n.t('module:Modals:InvoicesExport:Export:error')
  }

  if (dateRange) {
    const [startDate, endDate] = dateRange

    if (endDate - startDate > convertTimeDuration(1, 'years')) {
      errors.dateRange = i18n.t('module:Modals:InvoicesExport:DateRange:error')
    }
  }

  return errors
}

export default reduxForm({ form: INVOICES_EXPORT_MODAL_FORM, validate })(ExportModalForm)
