import moment from 'moment'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { change, getFormValues, stopSubmit } from 'redux-form'

import { STAFF_MODULE_TYPE } from 'services/legacy/memberships/constants'
import { FILE_TYPES } from 'constants/mimetypes'
import { REPORT_DATES } from 'module/Modals/Staff/constants'

import { EVENTS, logEvent } from 'analytics'

import { withMembershipsService } from 'services/legacy/memberships'
import { withMembershipsShiftsService } from 'services/legacy/membershipsShifts'
import { withModalService } from 'services/utils/modal'

import withStaffExportModal from 'module/Modals/Staff/withStaffExportModal'

import { BREAKDOWN } from './constants'
import { STAFF_ROTA_EXPORT_MODAL_FORM } from './components/ExportModalForm'
import ExportModalView from './ExportModalView'

const GROUPS = {
  read: [
    'membershipShift',
    'membership.shifts',
    'membershipShift.membershipShiftTimes',
    'membershipShift.membershipShiftTimeStatistics',
    'membershipShift.dayMembershipShiftTimeStatistics',
    'membershipShiftTime',
    'membershipShiftTime.class',
  ],
}

class ExportModalContainer extends Component {
  componentWillUnmount() {
    const { membershipsActions } = this.props

    membershipsActions.clear()
  }

  handleBreakdownChange = (e) => {
    const { changeFieldValue, staffExportHelpers } = this.props

    return staffExportHelpers.handleBreakdownChange({
      BREAKDOWN,
      changeFieldValue,
      value: e.target.value,
    })
  }

  handleReportDateChange = (e) => {
    const { changeFieldValue, staffExportHelpers } = this.props

    return staffExportHelpers.handleReportDateChange({
      changeFieldValue,
      value: e.target.value,
    })
  }

  handleSendReport = (values) => {
    const {
      membershipsSelectors,
      membershipsShiftsActions,
      membershipsShiftsSelectors,
      staffExportHelpers,
      stopSubmitForm,
    } = this.props

    const { breakdown, fileFormat, sendOption, sendTo } = values

    if (FILE_TYPES.PDF.acceptHeader === fileFormat) {
      const payload = membershipsShiftsSelectors.getRotaPdfExportPayload(values)

      return staffExportHelpers.handleSendReportOther({
        action: membershipsShiftsActions.exportReport,
        onSuccess: () => logEvent(EVENTS.STAFF_ROTA_EXPORTED),
        payload,
        sendTo,
        stopSubmitForm,
        type: sendOption,
      })
    }

    const reportDatesRange = staffExportHelpers.getDateRange(values)

    const criteria = BREAKDOWN.PAYROLL === breakdown
      ? membershipsSelectors.getStaffPayrollCriteria({
        ...values,
        reportDatesRange,
      })
      : membershipsSelectors.getStaffRotaCriteria({
        ...values,
        reportDatesRange,
      })

    const body = {
      criteria,
      groups: GROUPS,
    }

    return staffExportHelpers.handleSendReport({
      moduleType: BREAKDOWN.PAYROLL === breakdown ? STAFF_MODULE_TYPE.PAYROLL : STAFF_MODULE_TYPE.ROTA,
      onSuccess: () => logEvent(EVENTS.STAFF_ROTA_EXPORTED),
      params: body,
      sendTo,
      stopSubmitForm,
      type: breakdown,
    })
  }

  handleFileFormatChange = (e) => {
    const { changeFieldValue, staffExportHelpers } = this.props

    if (FILE_TYPES.PDF.acceptHeader === e.target.value) {
      return staffExportHelpers.handleReportDateChange({
        changeFieldValue,
        value: REPORT_DATES.WEEK,
      })
    }

    return null
  }

  render() {
    const { date = moment(), formValues, staffExportHelpers, staffExportState } = this.props
    const { isExporting } = staffExportState

    const initialValues = {
      ...staffExportHelpers.getInitialValues({ breakdown: BREAKDOWN.DAILY }),
      reportDatesRange: [moment(date).startOf('week'), moment(date).endOf('week')],
    }

    return (
      <ExportModalView
        formValues={formValues}
        initialValues={initialValues}
        isProcessing={isExporting}
        onBreakdownChange={this.handleBreakdownChange}
        onCloseClick={staffExportHelpers.handleCloseClick}
        onFileFormatChange={this.handleFileFormatChange}
        onReportDateChange={this.handleReportDateChange}
        onSubmit={this.handleSendReport}
      />
    )
  }
}

const mapState = (state) => ({
  formValues: getFormValues(STAFF_ROTA_EXPORT_MODAL_FORM)(state),
})

const mapDispatch = {
  changeFieldValue: (field, value) => change(STAFF_ROTA_EXPORT_MODAL_FORM, field, value),
  stopSubmitForm: (errors) => stopSubmit(STAFF_ROTA_EXPORT_MODAL_FORM, errors),
}

const enhance = compose(
  withMembershipsService,
  withMembershipsShiftsService,
  withModalService,
  withStaffExportModal,
  connect(mapState, mapDispatch),
)

export default enhance(ExportModalContainer)
