import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change, getFormSubmitErrors, getFormValues, reduxForm } from 'redux-form'

import { FOOD_AMOUNT, FOOD_AMOUNT_DROPDOWN, FOOD_CATEGORY } from 'services/legacy/foodMenu/constants'
import { DAILY_DIARY_DATE_FORMAT } from 'services/legacy/dailyDiary/constants'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withFoodTypesService } from 'services/legacy/foodTypes'
import { withRouter } from 'services/router'

import FoodRecordView from './FoodRecordView'

export const FOOD_RECORD_EDIT_FORM = 'FoodRecordEditForm'

class FoodRecord extends Component {
  constructor(props) {
    super(props)

    const { item } = this.props
    const { diaryRecords } = item

    this.state = {
      errorMessage: null,
      isEditMode: !diaryRecords?.length,
      isProcessing: false,
    }
  }

  shouldComponentUpdate(nextProps) {
    const { item } = this.props

    if (!_.isEqual(item, nextProps.item)) {
      this.setState({
        isEditMode: !nextProps.item?.diaryRecords?.length,
      })
    }

    return true
  }

  getFoodCategory = () => {
    const { location: { pathname } } = this.props

    if (generateRoute('DAILY_DIARY.SNACKS') === pathname) {
      return FOOD_CATEGORY.SNACK
    }

    return FOOD_CATEGORY.MEAL
  }

  getFormName = ({ childId }) => {
    const { location: { query }, type } = this.props
    const { date = moment().format(DAILY_DIARY_DATE_FORMAT) } = query

    return `${FOOD_RECORD_EDIT_FORM}_${date}_${type}_${childId}`
  }

  handleEditMode = (isEditMode) => {
    const { item, onReinitializeFormValues } = this.props

    if (onReinitializeFormValues) {
      onReinitializeFormValues(item)
    }

    this.setState({ isEditMode })
  }

  handleSubmitClick = (e) => {
    const {
      handleSubmit,
      item,
      onSubmit,
    } = this.props
    const { isProcessing } = this.state

    if (isProcessing) {
      return false
    }

    return handleSubmit((values) => {
      this.setState({
        errorMessage: null,
        isProcessing: true,
      })

      return onSubmit(
        item,
        values,
        (error, errorMessage) => {
          this.setState({
            errorMessage,
            isProcessing: false,
          })

          if (!error) {
            this.handleEditMode(false)
          }
        },
      )
    })(e)
  }

  handleResetRecord = () => {
    const { changeFieldValue, item, onResetRecord } = this.props
    const { childRegister: { child }, diaryRecords } = item || {}
    const { id: childId } = child
    const amount = _.find(FOOD_AMOUNT_DROPDOWN, ({ value }) => FOOD_AMOUNT.ALL === value)

    changeFieldValue(this.getFormName({ childId }), 'recordedAt', moment())
    changeFieldValue(this.getFormName({ childId }), 'comments', '')
    changeFieldValue(this.getFormName({ childId }), 'items', [{
      amount,
    }])

    const { id } = diaryRecords?.[0] || {}

    if (id) {
      onResetRecord(item)
    }
  }

  render() {
    const { form, formValues, getFormErrors, item } = this.props
    const { errorMessage, isEditMode, isProcessing } = this.state
    const { childRegister: { child } } = item || {}
    const { id: childId } = child

    return (
      <FoodRecordView
        errorMessage={errorMessage}
        form={form}
        formErrors={getFormErrors(this.getFormName({ childId }))}
        formValues={formValues}
        isEditMode={isEditMode}
        isProcessing={isProcessing}
        item={item}
        onEditMode={this.handleEditMode}
        onGetFoodCategory={this.getFoodCategory}
        onResetRecord={this.handleResetRecord}
        onSubmit={this.handleSubmitClick}
      />
    )
  }
}

const mapDispatch = {
  changeFieldValue: (formName, field, value) => change(formName, field, value),
}

const mapState = (state) => ({
  formValues: (formName) => getFormValues(formName)(state),
  getFormErrors: (formName) => getFormSubmitErrors(formName)(state),
})

const enhance = compose(
  withAppService,
  withFoodTypesService,
  withRouter,
  connect(mapState, mapDispatch),
)

export default reduxForm()(
  enhance(FoodRecord),
)
