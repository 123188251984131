import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as enquiryActivitiesSelectors from './selectors'

const withEnquiryActivitiesService = (WrappedComponent) => {
  const mapState = ({ enquiryActivities }) => ({ enquiryActivities })

  const mapDispatch = injectActions('enquiryActivitiesActions', actions)

  const Component = ({ enquiryActivities, enquiryActivitiesActions, ...other }) => (
    <WrappedComponent
      enquiryActivitiesActions={enquiryActivitiesActions}
      enquiryActivitiesListState={enquiryActivities.list}
      enquiryActivitiesSelectors={enquiryActivitiesSelectors}
      enquiryActivitiesSingleState={enquiryActivities.single}
      {...other}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withEnquiryActivitiesService
