import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change, getFormInitialValues, getFormValues } from 'redux-form'

import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withPaginationUtils } from 'services/utils/pagination'
import { withModalService } from 'services/utils/modal'
import { withNurseriesService } from 'services/nurseries'
import { withOrganizationSessionsService } from 'services/legacy/organizationSessions'
import { withRouter } from 'services/router'

import i18n from 'translations'

import { getInitialValues, getPayload } from './helpers'

import SettingsSessionsAddView from './SettingsSessionsAddView'
import { FORM_NAME } from './components/AddSessionForm'

class SettingsSessionsAddContainer extends Component {
  constructor(props) {
    super(props)

    const { paginationUtils: { setPageLocationQuery } } = props

    setPageLocationQuery(false)

    this.state = {
      sameFeesForAll: false,
    }
  }

  componentDidMount() {
    const { isEdit, organizationSessionsActions, params } = this.props

    if (isEdit) {
      organizationSessionsActions.getSession(params.sessionId, this.fetch)

      return
    }

    this.fetch()
  }

  componentWillUnmount() {
    const { nurseriesActions, organizationSessionsActions } = this.props

    organizationSessionsActions.clearSession()
    nurseriesActions.clear()
  }

  fetch = () => {
    const { nurseriesActions, paginationUtils: { page } } = this.props

    nurseriesActions.list({
      mergeResult: 1 !== page,
      onSuccess: this.handleUpdateNurseries,
      params: { page },
      recurrency: true,
    })
  }

  handleUpdateNurseries = ({ data, meta: { limit, start } }) => {
    const { formValues, isEdit, organizationSessionsSingleState, updateField } = this.props
    const { sameFeesForAll } = this.state

    const session = organizationSessionsSingleState.data?.nurserySessions
    const startIndex = (start * limit) - limit

    _.forEach(data, ({ id }, index) => {
      const nurseryItem = { nurseryId: id }
      const rowIndex = startIndex + index

      if (sameFeesForAll && formValues?.nurserySessions?.length) {
        const { costTwoYears, costTwoYearsAbove, costUnderTwo } = formValues.nurserySessions[0]

        nurseryItem.costUnderTwo = costUnderTwo
        nurseryItem.costTwoYears = costTwoYears
        nurseryItem.costTwoYearsAbove = costTwoYearsAbove
      } else if (isEdit) {
        const nurserySession = _.find(session, ({ nursery }) => nursery.id === id)

        if (nurserySession) {
          const { costTwoYears, costTwoYearsAbove, costUnderTwo } = nurserySession

          nurseryItem.costUnderTwo = costUnderTwo || 0
          nurseryItem.costTwoYears = costTwoYears || 0
          nurseryItem.costTwoYearsAbove = costTwoYearsAbove || 0
        }
      }

      updateField(`nurserySessions[${rowIndex}]`, nurseryItem)
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleCreateSuccess = () => {
    const { navigate } = this.props

    navigate('/settings/sessions')
  }

  handleEditSuccess = () => {
    const { navigate, params } = this.props

    navigate(`/settings/sessions/${params.sessionId}`)
  }

  handleSubmit = (fields) => {
    const {
      formInitialValues,
      isEdit,
      modalActions,
      modalConsts,
      organizationSessionsActions,
      params,
    } = this.props
    const { sameFeesForAll } = this.state

    const payload = getPayload(fields, sameFeesForAll)

    if (isEdit) {
      const { endTime, isHourly, startTime } = fields
      const { endTime: initialEndTime, isHourly: initialIsHourly, startTime: initialStartTime } = formInitialValues

      if (startTime !== initialStartTime || endTime !== initialEndTime || isHourly !== initialIsHourly) {
        return modalActions.show(modalConsts.TYPES.CONFIRM, {
          confirmButtonLabel: 'Update',
          icon: 'warning',
          onConfirm: () => organizationSessionsActions.updateSession(params.sessionId, payload, this.handleEditSuccess),
          text: i18n.t('module:Settings:Sessions:Add:fundingPopupCopy'),
        })
      }

      return organizationSessionsActions.updateSession(params.sessionId, payload, this.handleEditSuccess)
    }

    return organizationSessionsActions.createSession(payload, this.handleCreateSuccess)
  }

  handleClearItemClick = (index) => () => {
    const { updateField } = this.props

    updateField(`nurserySessions[${index}].costUnderTwo`, null)
    updateField(`nurserySessions[${index}].costTwoYears`, null)
    updateField(`nurserySessions[${index}].costTwoYearsAbove`, null)
  }

  handleSameFeesForAllChange = () => {
    const { formValues, updateField } = this.props

    this.setState((prevState) => ({
      sameFeesForAll: !prevState.sameFeesForAll,
    }), () => {
      const { sameFeesForAll } = this.state

      if (sameFeesForAll && formValues.nurserySessions && formValues.nurserySessions.length) {
        const { costTwoYears, costTwoYearsAbove, costUnderTwo } = formValues.nurserySessions[0]

        for (let i = 0; i < formValues.nurserySessions.length; i += 1) {
          updateField(`nurserySessions[${i}].costUnderTwo`, costUnderTwo)
          updateField(`nurserySessions[${i}].costTwoYears`, costTwoYears)
          updateField(`nurserySessions[${i}].costTwoYearsAbove`, costTwoYearsAbove)
        }
      }
    })
  }

  archiveSession = (archived) => {
    const { organizationSessionsActions, params } = this.props

    return organizationSessionsActions.archiveSession(params.sessionId, { archived }, this.handleEditSuccess)
  }

  handleArchiveClick = (archived) => () => {
    const { modalActions, modalConsts } = this.props

    const label = archived ? 'archive' : 'unarchive'
    const text = archived
      ? i18n.t('module:Settings:Sessions:Add:archivePopupCopy')
      : i18n.t('module:Settings:Sessions:Add:unarchivePopupCopy')

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: label,
      icon: label,
      onConfirm: () => this.archiveSession(archived),
      text,
    })
  }

  render() {
    const {
      authAccessMap,
      errorMessages,
      formValues,
      isEdit,
      nurseriesListState,
      organizationSessionsSingleState,
      paginationUtils,
      totalResults,
    } = this.props
    const { sameFeesForAll } = this.state
    const { getPageCount, page } = paginationUtils

    const pageCount = getPageCount(totalResults)
    const nurseries = nurseriesListState.data
    const session = organizationSessionsSingleState.data
    const initialValues = getInitialValues(nurseries, isEdit, session, formValues)
    const isArchived = organizationSessionsSingleState.data && organizationSessionsSingleState.data.archived
    const isLoading = !initialValues
    const isFormLoading = organizationSessionsSingleState.isSubmitting

    return (
      <SettingsSessionsAddView
        authAccessMap={authAccessMap}
        errorMessages={errorMessages}
        initialValues={initialValues}
        isArchived={isArchived}
        isEdit={isEdit}
        isFormLoading={isFormLoading}
        isLoading={isLoading}
        nurseries={nurseries}
        page={page}
        pageCount={pageCount}
        sameFeesForAll={sameFeesForAll}
        onArchiveClick={this.handleArchiveClick}
        onClearItemClick={this.handleClearItemClick}
        onPageChange={this.handlePageChange}
        onSameFeesForAllChange={this.handleSameFeesForAllChange}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

SettingsSessionsAddContainer.authParams = {
  roles: [
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
  ],
}

const mapState = (state, { appSelectors, nurseriesListState, organizationSessionsSingleState, params }) => ({
  authAccessMap: {
    section: {
      FinanceAutomation: auth.SELECTORS.getIsAuthorised(state, {
        flags: [FEATURE_FLAGS.FINANCE_AUTOMATION],
      }),
    },
  },
  errorMessages: appSelectors.getErrorMessages(organizationSessionsSingleState),
  formInitialValues: getFormInitialValues(FORM_NAME)(state),
  formValues: getFormValues(FORM_NAME)(state),
  isEdit: !!params.sessionId,
  totalResults: appSelectors.getTotalResults(nurseriesListState),
})

const mapDispatch = {
  updateField: (fieldName, fieldValue) => change(FORM_NAME, fieldName, fieldValue),
}

const enhance = compose(
  withAppService,
  withModalService,
  withPaginationUtils,
  withNurseriesService,
  withOrganizationSessionsService,
  withRouter,
  connect(mapState, mapDispatch),
)

export default enhance(SettingsSessionsAddContainer)
