import styled from 'styled-components'

export const StyledDocumentWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ background }) => background && `
    background: ${background};
  `}  
`

export const StyledName = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
  grid-column-gap: 20px;
  align-items: center;
  word-break: break-all;
`

export const StyledIcon = styled.div`
  position: absolute;
  z-index: 30;
  height: 14px;
`

export const StyledFilePreview = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: #EEE;
  background-size: cover;
  background-position: center center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  & img {
    border-radius: 4px;
    position: relative;
    z-index: 10;
  }

  ${({ noCloudUpload }) => noCloudUpload && `
    cursor: default;
  `}

  ${({ notReadyToPreview }) => notReadyToPreview && `
    cursor: not-allowed;
  `}
  
  ${({ isVideo, notReadyToPreview }) => isVideo && !notReadyToPreview && `
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 4px;
      content: '';
      display: block;
      z-index: 20;
      background: rgba(0, 0, 0, .4);
    }
  `}
`
