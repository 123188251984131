import React from 'react'

import { StyledButtonItemContainer } from './ChildSessionsGridStyled'

const ChildSessionsGridButtonItem = ({ children }) => (
  <StyledButtonItemContainer>
    {children}
  </StyledButtonItemContainer>
)

export default ChildSessionsGridButtonItem
