import _ from 'lodash'

import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Field, formValueSelector, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'

import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

import { Button, Callout, Form, Grid, InfiniteScroll, Section, Switch, Typography } from 'components'

import i18n from 'translations'

export const FORM_NAME = 'AddSessionForm'

const AddSessionForm = ({
  authAccessMap,
  errorMessages,
  handleSubmit,
  isArchived,
  isEdit,
  isLoading,
  isTimeDisabled,
  nurseries,
  onArchiveClick,
  onClearItemClick,
  onPageChange,
  onSameFeesForAllChange,
  onSubmit,
  page,
  pageCount,
  sameFeesForAll,
}) => {
  const renderNursery = (nursery, index) => {
    const { id, name } = nursery

    return (
      <Form.Row key={id} label={name} margin="0 0 15px">
        <SubdomainCurrencyProvider>
          {({ currencySymbol }) => (
            <React.Fragment>
              <Field component="input" name={`nurserySessions[${index}].nurseryId`} type="hidden" />
              <Grid>
                <Grid.Item width={{ desktop: '120px' }}>
                  <Field
                    component={Form.TextField}
                    disabled={sameFeesForAll && index}
                    name={`nurserySessions[${index}].costUnderTwo`}
                    placeholder={currencySymbol}
                    step="any"
                    type="number"
                  />
                </Grid.Item>
                <Grid.Item width={{ desktop: '120px' }}>
                  <Field
                    component={Form.TextField}
                    disabled={sameFeesForAll && index}
                    name={`nurserySessions[${index}].costTwoYears`}
                    placeholder={currencySymbol}
                    step="any"
                    type="number"
                  />
                </Grid.Item>
                <Grid.Item width={{ desktop: '120px' }}>
                  <Field
                    component={Form.TextField}
                    disabled={sameFeesForAll && index}
                    name={`nurserySessions[${index}].costTwoYearsAbove`}
                    placeholder={currencySymbol}
                    step="any"
                    type="number"
                  />
                </Grid.Item>
              </Grid>
              {!(sameFeesForAll && index) && (
                <Form.Row.Item margin="0 0 5px">
                  <Button
                    color="gray"
                    hierarchy="tertiary"
                    icon="circle-close"
                    negativeVerticalMargins
                    onClick={onClearItemClick(index)}
                  />
                </Form.Row.Item>
              )}
            </React.Fragment>
          )}
        </SubdomainCurrencyProvider>
      </Form.Row>
    )
  }

  const title = isEdit ? 'Edit a session' : 'Add a session'
  const actions = isEdit && (
    <Section.Actions
      options={[{
        onClick: onArchiveClick(!isArchived),
        type: isArchived ? 'unarchive' : 'archive',
      }]}
    />
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Section actions={actions} title={title}>
        <Callout content={errorMessages} error />
        <Form.Row label="Session name">
          <Form.Row.FlexItem flex="0 0 360px">
            <Field component={Form.TextField} name="name" placeholder="Session name" validate={isRequired} />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row label="Start time">
          <Form.Row.FlexItem>
            <Field
              component={Form.TimePicker}
              disabled={isTimeDisabled}
              local={false}
              name="startTime"
              validate={!isTimeDisabled && isRequired}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row label="Finish time">
          <Form.Row.Item>
            <Field component={Form.TimePicker} disabled={isTimeDisabled} local={false} name="endTime" />
          </Form.Row.Item>
          <Form.Row.TextItem text="or" />
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              label="Hourly rate"
              name="isHourly"
              tooltip="Hourly rates allow you to flexibly allocate specific hours to children."
            />
          </Form.Row.Item>
        </Form.Row>
      </Section>
      {authAccessMap.section.FinanceAutomation
        && (
          <Section
            title="Enter session fees"
            tooltip={
              'Fees will be calculated based on children’s ages.'
                .concat('When a child turns 2 or 3 years old they will automatically move up an ')
                .concat('age-band for the following month’s fee invoice.')
            }
          >
            <Typography margin="20px 0">
              Entering your fees below will automatically allocate this session to the relevant nursery. Leave the boxes
              blank where the session does not apply to a nursery.
            </Typography>
            <Form.Row>
              <Form.Row.Item>
                <Switch value={sameFeesForAll} onChange={onSameFeesForAllChange} />
              </Form.Row.Item>
              <Form.Row.Item>
                <Typography margin="0 0 0 7px" inline>
                  Use the same fees for all nurseries.
                </Typography>
              </Form.Row.Item>
            </Form.Row>
            <Grid>
              <Grid.Item width={{ desktop: '120px' }} />
              <Grid.Item width={{ desktop: '120px' }}>
                <Typography fontSize={14}>
                  Under 2s
                </Typography>
              </Grid.Item>
              <Grid.Item width={{ desktop: '120px' }}>
                <Typography fontSize={14}>
                  2-3 year old
                </Typography>
              </Grid.Item>
              <Grid.Item width={{ desktop: '120px' }}>
                <Typography fontSize={14}>
                  3-5 year old
                </Typography>
              </Grid.Item>
            </Grid>
            <InfiniteScroll
              dataLength={nurseries?.length || 0}
              hasMore={page < pageCount}
              next={() => onPageChange((+page) + 1)}
            >
              {_.map(nurseries, renderNursery)}
            </InfiniteScroll>
          </Section>
        )}
      <Section
        title="Add consumables to this session"
        tooltip="Add consumables to this session"
      >
        <Typography margin="20px 0">
          {isEdit
            ? 'Sessions created on the organisation level can not have consumables added. '
              .concat('Please add separate sessions to your nursery, if you want to use consumables.')
            : 'When creating sessions on the organisation level you are unable to add consumables. '
              .concat('If your nurseries use consumables please let them create sessions for themselves separately.')}
        </Typography>
      </Section>
      <Form.FooterActions
        cancelLink={generateRoute('SETTINGS.SESSIONS')}
        isSubmitting={isLoading}
        submitLabel={i18n.t('global:Save')}
      />
    </Form>
  )
}

const validate = (fields) => {
  const errors = {}

  if (!fields.endTime && !fields.isHourly) {
    errors.endTime = 'Please enter Finish time or check Hourly rate.'
  }

  return errors
}

const mapState = (state) => ({
  isTimeDisabled: formValueSelector(FORM_NAME)(state, 'isHourly'),
})

const enhance = compose(
  reduxForm({ form: FORM_NAME, validate }),
  connect(mapState),
)

export default enhance(AddSessionForm)
