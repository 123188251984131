import { getQueryStringV2 } from 'utils/api'

import ServiceBase from 'services/ServiceBase'

class ChildrenSignedUpReportApiClient extends ServiceBase {
  getChildrenSignedUp = (params) => this.request({
    path: `/v2/children/statistics/signed_up?${getQueryStringV2(params)}`,
  })

  getNurseriesSignup = (params) => this.request({
    path: `/v2/nurseries/statistics/signed_up?${getQueryStringV2(params)}`,
  })

  getStatistics = (params) => this.request({
    path: `/v2/statistics/signed_up?${getQueryStringV2(params)}`,
  })
}

export default ChildrenSignedUpReportApiClient
