import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

import { Currency, DataTable, Typography } from 'components'

import i18n from 'translations'

import { StyledTableContainer, StyledTitle, StyledValue, StyledValueContainer } from './RevenueTableStyled'
import { COLUMNS, COLUMNS_V3 } from '../../constants'

const RevenueTable = ({ isFinanceV3Enabled, monthList, revenueData }) => {
  const renderColumn = (monthStartDate, i) => {
    const statistics = _.find(revenueData, ({ date }) => moment(date).format('YYYY-MM-DD') === monthStartDate)
    const { toBeInvoiced = {}, invoices = {} } = statistics || {}

    const formattedDate = moment(monthStartDate).format('YYYYMMDD')

    return _.map(isFinanceV3Enabled ? COLUMNS_V3 : COLUMNS, (item, columnIndex) => {
      const { highlightedTotal, total, value } = item

      const toBeInvoicedValue = toBeInvoiced[value]
      const invoiceValue = invoices[value]

      const toBeInvoicedDisplayValue = toBeInvoicedValue ? <Currency value={toBeInvoicedValue} /> : '-'
      const invoiceDisplayValue = invoiceValue ? (
        <Currency color={getBrandingColor('primary-color')} value={invoiceValue} />
      ) : (
        '-'
      )
      const key = `${formattedDate}_${value}_${columnIndex}`

      if (highlightedTotal) {
        return (
          <DataTable.Row background={getBrandingColor('primary-color-rgba-2')} key={key}>
            <Typography color={getBrandingColor('primary-color')} bold>
              {invoiceDisplayValue}
            </Typography>
          </DataTable.Row>
        )
      }

      if (total) {
        return (
          <DataTable.Row gray={!(i % 2)} key={key}>
            <StyledValueContainer>
              <StyledValue>
                <Typography bold>{invoiceDisplayValue}</Typography>
              </StyledValue>
              <StyledValue>
                <Typography bold>{toBeInvoicedDisplayValue}</Typography>
              </StyledValue>
            </StyledValueContainer>
          </DataTable.Row>
        )
      }

      return (
        <DataTable.Row gray={!(i % 2)} key={key}>
          <StyledValueContainer>
            <StyledValue>{invoiceDisplayValue}</StyledValue>
            <StyledValue>{toBeInvoicedDisplayValue}</StyledValue>
          </StyledValueContainer>
        </DataTable.Row>
      )
    })
  }

  const renderColumns = () => _.map(isFinanceV3Enabled ? COLUMNS_V3 : COLUMNS, ({
    highlightedTotal,
    label,
    total,
    value,
  }, i) => {
    const key = `${value}_${i}`

    if (highlightedTotal) {
      return (
        <DataTable.Row background={getBrandingColor('primary-color-rgba-2')} key={key}>
          <Typography color={getBrandingColor('primary-color')} bold>{label}</Typography>
        </DataTable.Row>
      )
    }

    if (total) {
      return (
        <DataTable.Row key={key}>
          <Typography bold>{label}</Typography>
        </DataTable.Row>
      )
    }

    return <DataTable.Row key={key}>{label}</DataTable.Row>
  })

  const renderMonth = (isHeader) => (monthStartDate, i) => (
    <DataTable.Column noBorder>
      <DataTable.Row background={(i % 2) && NEUTRAL_COLOURS.WHITE} gray={!(i % 2)}>
        <StyledTitle>{moment(monthStartDate).format('MMM YY')}</StyledTitle>
      </DataTable.Row>
      {!isHeader && renderColumn(monthStartDate, i)}
    </DataTable.Column>
  )

  return (
    <StyledTableContainer>
      <DataTable>
        <DataTable.Head borderRight>
          <DataTable.Row height={44} stickyElement>
            <StyledTitle>
              {i18n.t('module:Finance:Reports:AnnualRevenue:tableFirstColumnHeader')}
            </StyledTitle>
          </DataTable.Row>
          {renderColumns()}
        </DataTable.Head>
        <DataTable.ContentScrollable
          data={[{
            data: _.map(monthList, renderMonth()),
            stickyHeader: () => [_.map(monthList, renderMonth(true))],
          }]}
          enableCustomScrollbar
          hideSeparator
        />
      </DataTable>
    </StyledTableContainer>
  )
}

export default RevenueTable
