import _ from 'lodash'
import moment from 'moment'

import React, { useState } from 'react'
import { Field as FormField, reduxForm } from 'redux-form'

import colors from 'constants/colors'
import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'

import { isRequired } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'

import {
  ASSESSMENT_PERIOD_CUSTOM,
  FORMATIVE_REPORTS_STATUSES,
  TERM_CUSTOM,
  TYPE_OF_REPORT_DROPDOWN,
  TYPE_OF_TRACKING,
} from 'services/legacy/formativeReports/constants'

import {
  Button,
  Field,
  Form,
  Icon,
  LastSaveDateTime,
  LayoutContainer,
  Spinner,
  StickyFooter,
  Tabs,
  Typography,
} from 'components'

import i18n from 'translations'

import { TABS_TYPES } from 'module/Learning/FormativeReports/FormativeReportsPreview/constants'

import ChildOverview from './components/ChildOverview'
import Comments from './components/Comments'
import FrameworkDetails from './components/FrameworkDetails'
import Montessori from './components/Montessori'
import {
  StyledFooterBottom,
  StyledGrid,
  StyledInfo,
  StyledStickyFooterContainer,
} from './FormativeReportsPreviewFormStyled'

export const CHILD_LEARNING_JOURNEY_FORMATIVE_REPORTS_PREVIEW_FORM = 'FormativeReportsPreviewForm'

const FormativeReportsPreviewForm = ({
  activeTab,
  assessmentPeriods,
  displayChildOverview,
  downloadedTabsData,
  formValues,
  formativeReport,
  frameworksList,
  handleSubmit,
  isFetchingFrameworksList,
  isGrantedForSendingToPendingApproval,
  isGrantedToApprove,
  isGrantedToEditFormativeReport,
  isMontessori,
  isSubmitting,
  onAttainmentLevelsChange,
  onChangeStatus,
  onChangeTab,
  onCommentsBlur,
  onCommentsChange,
  onFormCommentsBlur,
  onFormCommentsChange,
  onGetAgeBands,
  onGetFrameworkDetails,
  onRestoreOldValue,
  onSave,
  onSingleFieldSubmit,
  onSubmit,
  terms,
  useOnlySecureToMarkProgress,
}) => {
  const { dateFrom, dateTo, period } = formativeReport || {}
  const { label, type } = period || {}

  const [periodIsEditable, setPeriodIsEditable] = useState(false)
  const [typeIsEditable, setTypeIsEditable] = useState(false)
  const [assessmentPeriodIsEditable, setAssessmentPeriodIsEditable] = useState(false)
  const [termPeriodIsEditable, setTermPeriodIsEditable] = useState(false)

  const renderAssessmentPeriod = () => (
    <Field.InlineEdit
      actions={isGrantedToEditFormativeReport && (
        <Field.InlineEdit.Actions
          isEdit={assessmentPeriodIsEditable}
          onCancelClick={() => {
            setAssessmentPeriodIsEditable(false)
            onRestoreOldValue('date')
          }}
          onEditClick={() => setAssessmentPeriodIsEditable(true)}
          onSaveClick={() => onSingleFieldSubmit(() => setAssessmentPeriodIsEditable(false))}
        />
      )}
      description={label}
      editableComponent={(
        <React.Fragment>
          <Form.Row.FlexItem mobileFull>
            <FormField
              component={Form.Select}
              name="period"
              options={assessmentPeriods}
              placeholder={
                i18n.t('module:Learning:FormativeReports:FormativeReportsCreate:Form:selectAssessmentPeriod')
              }
              validate={isRequired}
            />
          </Form.Row.FlexItem>
          {formValues && formValues.period && formValues.period.value === ASSESSMENT_PERIOD_CUSTOM && (
            <Form.Row.FlexItem mobileFull>
              <FormField
                component={Form.TextField}
                name="periodCustom"
                placeholder={
                  i18n.t('module:Learning:FormativeReports:FormativeReportsCreate:Form:enterCustomAssessmentPeriod')
                }
              />
            </Form.Row.FlexItem>
          )}
        </React.Fragment>
      )}
      isEditMode={assessmentPeriodIsEditable}
      isLoading={isSubmitting && assessmentPeriodIsEditable}
      label={i18n.t('module:Learning:FormativeReports:assessmentPeriod')}
    />
  )

  const renderTermPeriod = () => (
    <Field.InlineEdit
      actions={isGrantedToEditFormativeReport && (
        <Field.InlineEdit.Actions
          isEdit={termPeriodIsEditable}
          onCancelClick={() => {
            setTermPeriodIsEditable(false)
            onRestoreOldValue('term')
            onRestoreOldValue('termCustom')
          }}
          onEditClick={() => setTermPeriodIsEditable(true)}
          onSaveClick={() => onSingleFieldSubmit(() => setTermPeriodIsEditable(false))}
        />
      )}
      description={
        period.custom
          ? `${period.label} (${i18n.t('global:custom')})`
          : _.get(
            _.find(terms, ({ value }) => value === label),
            'label')
      }
      editableComponent={(
        <React.Fragment>
          <Form.Row.FlexItem mobileFull>
            <FormField
              component={Form.Select}
              name="term"
              options={terms}
              placeholder={i18n.t('module:Learning:FormativeReports:FormativeReportsCreate:Form:selectTerm')}
              validate={isRequired}
            />
          </Form.Row.FlexItem>
          {formValues && formValues.term && formValues.term.value === TERM_CUSTOM && (
            <Form.Row.FlexItem mobileFull>
              <FormField
                component={Form.TextField}
                name="termCustom"
                placeholder={i18n.t('module:Learning:FormativeReports:FormativeReportsCreate:Form:enterCustomTerm')}
              />
            </Form.Row.FlexItem>
          )}
        </React.Fragment>
      )}
      isEditMode={termPeriodIsEditable}
      isLoading={isSubmitting && termPeriodIsEditable}
      label={i18n.t('module:Learning:FormativeReports:term')}
    />
  )

  const renderToolbar = () => (
    <StyledGrid>
      <Field.InlineEdit
        actions={isGrantedToEditFormativeReport && (
          <Field.InlineEdit.Actions
            isEdit={periodIsEditable}
            onCancelClick={() => {
              setPeriodIsEditable(false)
              onRestoreOldValue('date')
            }}
            onEditClick={() => setPeriodIsEditable(true)}
            onSaveClick={() => onSingleFieldSubmit(() => setPeriodIsEditable(false))}
          />
        )}
        description={
          dateFrom && dateTo && (
            `${moment(dateFrom).format(DISPLAY_SHORT_DATE_FORMAT)}
               - 
             ${moment(dateTo).format(DISPLAY_SHORT_DATE_FORMAT)
            }`)
        }
        editableComponent={(
          <Form.Row.FlexItem mobileFull>
            <FormField
              component={Form.DatePicker}
              disabled={isSubmitting && periodIsEditable}
              name="date"
              clearable
              range
            />
          </Form.Row.FlexItem>
        )}
        isEditMode={periodIsEditable}
        isLoading={isSubmitting && periodIsEditable}
        label={i18n.t('module:Learning:FormativeReports:FormativeReportsCreate:Form:reportPeriod')}
      />
      <Field.InlineEdit
        actions={isGrantedToEditFormativeReport && (
          <Field.InlineEdit.Actions
            isEdit={typeIsEditable}
            onCancelClick={() => {
              setTypeIsEditable(false)
              onRestoreOldValue('type')
            }}
            onEditClick={() => setTypeIsEditable(true)}
            onSaveClick={() => onSingleFieldSubmit(() => setTypeIsEditable(false))}
          />
        )}
        description={
          formativeReport && _.get(
            _.find(TYPE_OF_REPORT_DROPDOWN, ({ value }) => value === formativeReport.type),
            'label')
        }
        editableComponent={(
          <Form.Row.FlexItem mobileFull>
            <FormField
              component={Form.Select}
              disabled={isSubmitting && typeIsEditable}
              name="type"
              options={TYPE_OF_REPORT_DROPDOWN}
              placeholder={i18n.t('module:Learning:FormativeReports:FormativeReportsCreate:Form:typeOfReport')}
              validate={isRequired}
            />
          </Form.Row.FlexItem>
        )}
        isEditMode={typeIsEditable}
        isLoading={isSubmitting && typeIsEditable}
        label={i18n.t('module:Learning:FormativeReports:FormativeReportsCreate:Form:typeOfReport')}
      />
      {type === TYPE_OF_TRACKING.ASSESSMENT && renderAssessmentPeriod()}
      {type === TYPE_OF_TRACKING.TERM && renderTermPeriod()}
    </StyledGrid>
  )

  const getSpinner = (isDownloaded, content) => {
    if (!isDownloaded) {
      return (
        <Spinner />
      )
    }

    return content
  }

  const renderTabs = () => {
    if (isFetchingFrameworksList) {
      return (
        <Spinner />
      )
    }

    return (
      <Tabs
        defaultPosition={displayChildOverview
          ? TABS_TYPES.CHILD_OVERVIEW
          : (frameworksList[0]?.id || TABS_TYPES.COMMENTS)}
        fullWidth={false}
        onChange={onChangeTab}
      >
        {displayChildOverview && (
          <Tabs.Item
            name={TABS_TYPES.CHILD_OVERVIEW}
            title={i18n.t('module:Learning:FormativeReports:FormativeReportsPreview:ChildOverview:title')}
          >
            {getSpinner(
              downloadedTabsData[TABS_TYPES.CHILD_OVERVIEW], (
                <ChildOverview
                  formativeReport={formativeReport}
                />
              ),
            )}
          </Tabs.Item>
        )}
        {_.map(frameworksList, (framework) => (
          <Tabs.Item
            key={`framework_${framework.id}`}
            name={framework.id}
            title={framework.type || framework.name}
          >
            {getSpinner(
              downloadedTabsData[TABS_TYPES.FRAMEWORK], (
                <FrameworkDetails
                  formativeReport={formativeReport}
                  framework={framework}
                  useOnlySecureToMarkProgress={useOnlySecureToMarkProgress}
                  visible={activeTab === framework.id}
                  onAttainmentLevelsChange={onAttainmentLevelsChange}
                  onCommentsBlur={onCommentsBlur}
                  onCommentsChange={onCommentsChange}
                  onGetAgeBands={onGetAgeBands}
                  onGetFrameworkDetails={onGetFrameworkDetails}
                />
              ),
            )}
          </Tabs.Item>
        ))}
        {isMontessori && (
          <Tabs.Item
            name={TABS_TYPES.MONTESSORI}
            title="Montessori"
          >
            {getSpinner(
              downloadedTabsData[TABS_TYPES.MONTESSORI], (
                <Montessori />
              ),
            )}
          </Tabs.Item>
        )}
        <Tabs.Item
          name={TABS_TYPES.COMMENTS}
          title={i18n.t('module:Learning:FormativeReports:FormativeReportsPreview:Comments:title')}
        >
          {getSpinner(
            downloadedTabsData[TABS_TYPES.COMMENTS], (
              <Comments
                isGrantedToEditFormativeReport={isGrantedToEditFormativeReport}
                onFormCommentsBlur={onFormCommentsBlur}
                onFormCommentsChange={onFormCommentsChange}
              />
            ),
          )}
        </Tabs.Item>
      </Tabs>
    )
  }

  const renderFooter = () => {
    if (!isGrantedToEditFormativeReport && !isGrantedToApprove && !isGrantedForSendingToPendingApproval) {
      return null
    }

    return (
      <StickyFooter expanded>
        <StyledStickyFooterContainer>
          <StyledInfo>
            <Icon
              color={colors.warningBanner.text}
              height={18}
              icon="exclamation-mark"
            />
            <Typography
              color={colors.warningBanner.text}
              fontSize={14}
              margin="0 0 0 5px"
              variant="span"
            >
              {i18n.t('module:Learning:FormativeReports:FormativeReportsPreview:alert')}
            </Typography>
          </StyledInfo>
          <StyledFooterBottom>
            <LayoutContainer>
              <Button
                disabled={isSubmitting}
                hierarchy="tertiary"
                label={i18n.t('global:Cancel')}
                to={generateRoute('LEARNING.FORMATIVE_REPORTS')}
                negativeMargins
              />
              {isGrantedToEditFormativeReport && (
                <LastSaveDateTime
                  authorName={formativeReport?.author?.name}
                  lastSaveDateTime={formativeReport && moment(formativeReport.updatedAt)}
                />
              )}
              <StickyFooter.Flex />
              {isGrantedToEditFormativeReport && (
                <Button
                  hierarchy={isGrantedToApprove || isGrantedForSendingToPendingApproval ? 'secondary' : 'primary'}
                  isLoading={isSubmitting}
                  label={_.upperFirst(i18n.t('global:save'))}
                  negativeMargins
                  onClick={onSave}
                />
              )}
              {isGrantedToApprove && (
                <Button
                  isLoading={isSubmitting}
                  label={_.upperFirst(i18n.t('global:approve'))}
                  negativeMargins
                  submit
                  onClick={() => onChangeStatus(FORMATIVE_REPORTS_STATUSES.APPROVED.value)}
                />
              )}
              {isGrantedForSendingToPendingApproval && (
                <Button
                  isLoading={isSubmitting}
                  label={_.upperFirst(i18n.t('global:sendForApproval'))}
                  negativeMargins
                  submit
                  onClick={() => onChangeStatus(FORMATIVE_REPORTS_STATUSES.PENDING_APPROVAL.value)}
                />
              )}
            </LayoutContainer>
          </StyledFooterBottom>
        </StyledStickyFooterContainer>
      </StickyFooter>
    )
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {renderToolbar()}
      {renderTabs()}
      {renderFooter()}
    </Form>
  )
}

export default reduxForm({
  form: CHILD_LEARNING_JOURNEY_FORMATIVE_REPORTS_PREVIEW_FORM,
})(FormativeReportsPreviewForm)
