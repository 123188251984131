import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change, destroy, getFormValues, isDirty as isDirtyForm } from 'redux-form'

import { EVENTS, logEvent } from 'analytics'

import { NURSERY_SESSIONS_FILTER } from 'services/nurserySessions/constants'
import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { TYPE_CONTACT_FORM } from 'services/legacy/child/constants'
import childSessionConstants from 'services/legacy/childSessions/constants'
import { NEUTRAL_COLOURS } from 'constants/colors'
import { FEATURE_FLAGS, ROLES } from 'constants/security'
import { Type } from 'services/booking/periods/models'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withChildSessionsService } from 'services/legacy/childSessions'
import { withCarersService } from 'services/legacy/carers'
import { withCarerChildRelationsService } from 'services/legacy/carerChildRelations'
import { withNurseriesService } from 'services/nurseries'
import { withNurserySessionsService } from 'services/nurserySessions'
import { withUploadService } from 'services/legacy/upload'
import { withEnquiriesService } from 'services/legacy/enquiries'
import { withPeriodsService } from 'services/booking/periods'
import { withModalService } from 'services/utils/modal'
import { withRouter } from 'services/router'

import i18n from 'translations'

import ChildAddView from './ChildAddView'

import { CHILD_ADD_FORM } from '../components/ChildForms/constants'
import { API_STATUS, STAGE_INDEXES } from './constants'
import { getPopupErrorMessage } from './helpers'

const ENQUIRY_GROUPS = {
  read: [
    'enquiry.data',
    'enquiry.nurseryClass',
    'nurseryClass',
    'enquiry.sessionPlans',
    'nurserySession',
  ],
}

const CHILD_SESSIONS_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings',
    'nurserySettings.invoice',
    'nurseryInvoiceSettings',
  ],
}

export const CARER_GROUPS = {
  read: [
    'carer.details',
    'carer.address',
    'carer.parentAccount',
    'parentAccount',
    'carer.carerChildRelations',
    'carerChildRelation.details',
    'carerChildRelation.child',
  ],
}

export const CARER_EMAIL_CHECK_GROUPS = {
  read: [
    'carer.details',
  ],
}

class ChildAddContainer extends Component {
  constructor(props) {
    super(props)

    const { location } = props
    const { query } = location || {}

    this.state = {
      cancelLink: query?.enquiryId
        ? generateRoute('ENQUIRIES.DETAIL', { enquiryId: query?.enquiryId })
        : generateRoute('CHILDREN.INDEX'),
      currentStage: 1,
      disableUnsavedPopup: false,
      enableSessions: false,
      enquiryId: query?.enquiryId,
      highestAvailableStage: 1,
      isSubmitting: false,
      periodOption: null,
      selectedContacts: {},
    }

    this.apiResponse = {
      childApiResponse: {},
      contactsApiResponse: [{}],
      sessionApiResponse: {},
    }
  }

  componentDidMount() {
    const {
      enquiriesActions,
      nurseriesActions,
      nurseryOptions,
      nurserySessionsActions,
      nurserySessionsSelectors,
      periodsActions,
      periodsSelectors,
      router,
    } = this.props
    const { enquiryId } = this.state

    if (enquiryId) {
      enquiriesActions.get({
        onSuccess: ({ data }) => this.setState({ enableSessions: !!data?.sessionPlans?.length }),
        params: [enquiryId, {
          groups: ENQUIRY_GROUPS,
        }],
      })
    }

    const apiParams = { groups: CHILD_SESSIONS_GROUPS }
    nurseriesActions.get(nurseryOptions.id, { params: apiParams })

    nurserySessionsActions.list({
      params: {
        criteria: nurserySessionsSelectors.getCriteria({ statusFilter: NURSERY_SESSIONS_FILTER.ACTIVE }),
      },
      recursively: true,
    })

    const criteria = periodsSelectors.getCriteria({
      archived: 0,
      type: Type.attendance,
    })

    periodsActions.list({
      onSuccess: (response) => {
        this.setState({
          periodOption: response?.data?.length ? {
            label: response?.data?.[0]?.name,
            value: response?.data?.[0]?.id,
          } : [],
        })
      },
      onlyData: true,
      params: {
        criteria,
        limit: 1,
        order: {
          sortField: 'name',
          sortOrder: 'ASC',
        },
      },
    })

    router.setRouteLeaveHook(router.routes[router.routes.length - 1], this.handleUnsavedPopup)
  }

  handleUnsavedPopup = (nextLocation) => {
    const { isDirty, modalActions, modalConsts } = this.props
    const { disableUnsavedPopup, enquiryId } = this.state

    const displayConfirmation = isDirty || enquiryId

    if (!displayConfirmation || disableUnsavedPopup) {
      this.unmountPage()

      return null
    }

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('global:Confirm'),
      icon: 'warning',
      onConfirm: () => this.handleUnsavedPopupSuccess(nextLocation),
      text: i18n.t('module:Children:Child:Add:LeavePopup:message'),
    })

    return false
  }

  handleUnsavedPopupSuccess = (nextLocation) => {
    const { navigate } = this.props

    this.setState({ disableUnsavedPopup: true }, () => {
      navigate(nextLocation)
    })

    return true
  }

  unmountPage = () => {
    const { destroyForm, enquiriesActions } = this.props

    enquiriesActions.clearSingle()
    destroyForm()
  }

  nextStage = () => {
    const { currentStage, highestAvailableStage } = this.state

    this.setState({
      highestAvailableStage: highestAvailableStage === currentStage
        ? currentStage + 1
        : highestAvailableStage,
    })

    this.setState({ currentStage: currentStage + 1 })
  }

  handlePreviousStage = () => {
    const { modalActions, modalConsts, navigate } = this.props
    const { currentStage } = this.state

    if (!(currentStage - 1)) {
      return modalActions.show(modalConsts.TYPES.CONFIRM, {
        confirmButtonLabel: i18n.t('global:Confirm'),
        icon: 'warning',
        onConfirm: () => navigate(generateRoute('CHILDREN.INDEX')),
        text: i18n.t('module:Children:Child:Add:LeavePopup:message'),
      })
    }

    return this.setState({ currentStage: currentStage - 1 })
  }

  handleSetStage = (currentStage) => {
    this.setState({ currentStage })
  }

  handleMoveToNextStage = (formValues) => {
    const { childSessionsSelectors, modalActions, modalConsts } = this.props
    const { currentStage, enableSessions } = this.state

    if (enableSessions && STAGE_INDEXES.ENROLMENT_INFO === currentStage) {
      const isSessionEmpty = childSessionsSelectors.isSessionsEmpty(formValues?.session)

      if (isSessionEmpty) {
        return modalActions.show(modalConsts.TYPES.ALERT, {
          icon: 'warning',
          text: i18n.t('module:Children:Child:BookingPattern:Sessions:Add:emptySessionCopy'),
        })
      }
    }

    return this.nextStage()
  }

  hasErrors = () => {
    const { childApiResponse, contactsApiResponse, sessionApiResponse } = this.apiResponse
    let errorsExists = false

    errorsExists = !!childApiResponse.error
    errorsExists = errorsExists || !!sessionApiResponse.error
    errorsExists = errorsExists || _.some(contactsApiResponse, ({ error }) => !!error)

    return errorsExists
  }

  displayErrorModal = () => {
    const { modalActions, modalConsts } = this.props

    if (this.hasErrors()) {
      const errors = getPopupErrorMessage(this.apiResponse)

      modalActions.show(modalConsts.TYPES.ALERT, {
        icon: 'warning',
        iconColor: NEUTRAL_COLOURS.WHITE,
        text: errors,
      })
    }
  }

  uploadFile = (file) => {
    const { uploadActions } = this.props

    return uploadActions.uploadFile(file)
  }

  handleSubmitFailed = (error) => {
    this.apiResponse.childApiResponse = {
      ...this.apiResponse.childApiResponse,
      error,
      status: API_STATUS.FAILED,
    }

    this.setState({ isSubmitting: false })
    this.displayErrorModal()
  }

  handleReviewSubmit = async (formValues) => {
    const {
      carersActions,
      carersSelectors,
      changeField,
      childActions,
      childSelectors,
      modalActions,
      modalConsts,
    } = this.props
    const { enquiryId, selectedContacts } = this.state
    const { childApiResponse, contactsApiResponse } = this.apiResponse
    const { contacts } = formValues

    this.setState({ isSubmitting: true })

    const generalInformation = childSelectors.getAddChildPayload(formValues)
    const enquiry = enquiryId ? { enquiry: { id: enquiryId } } : undefined

    let body = _.merge(
      enquiry,
      generalInformation,
    )

    if (false === body?.photo?.isUploaded) {
      const photo = await this.uploadFile(body.photo.file)

      changeField('photo', {
        isUploaded: true,
        value: photo,
      })

      body = {
        ...body,
        photo,
      }
    }

    const promiseArray = []

    _.forEach(contacts, ({ email }, index) => {
      const existingContactId = contactsApiResponse[index]?.id || selectedContacts[index]?.value

      if (!existingContactId && email) {
        promiseArray.push(new Promise((resolve, reject) => {
          carersActions.list({
            onFailed: reject,
            onSuccess: resolve,
            params: [{
              criteria: carersSelectors.getListCriteria({ search: email }),
              groups: CARER_EMAIL_CHECK_GROUPS,
              page: 1,
            }],
          })
        }))
      }
    })

    if (promiseArray.length) {
      const carerResponse = await Promise.all(promiseArray)

      const carerEmailExistsList = []

      _.forEach(carerResponse, (response) => {
        if (response.data.length) {
          carerEmailExistsList.push(response.data[0])
        }
      })

      if (carerEmailExistsList.length) {
        const emailIds = _.join(_.map(carerEmailExistsList, ({ email }) => email), ', ')

        this.setState({ isSubmitting: false })

        modalActions.show(modalConsts.TYPES.ALERT, {
          icon: 'warning',
          text: i18n.t('module:Children:Child:BookingPattern:Sessions:Add:carerEmailExistsCopy', { emailIds }),
        })

        return
      }
    }

    if (childApiResponse.id) {
      childActions.update({
        body,
        onFailed: this.handleSubmitFailed,
        onSuccess: this.handleSubmitSuccess(formValues),
        onlyData: true,
        params: [childApiResponse.id],
      })

      return
    }

    childActions.create({
      body,
      onFailed: this.handleSubmitFailed,
      onSuccess: this.handleSubmitSuccess(formValues),
      onlyData: true,
      params: [{}],
    })
  }

  handleContactSubmitSuccess = (response, values, childId, index) => {
    const {
      carerChildRelationsActions,
      carerChildRelationsSelectors,
    } = this.props
    const { contactsApiResponse } = this.apiResponse

    // eslint-disable-next-line no-unsafe-optional-chaining
    const carerId = +response?.data?.id

    const body = carerChildRelationsSelectors.getPayload({
      carerId,
      childId: +childId,
      isEditMode: !!contactsApiResponse[index]?.relationId,
      values,
    })

    const handleCarerChildRelationsSuccess = ({ data }) => {
      this.apiResponse.contactsApiResponse[index] = {
        id: carerId,
        relationId: data.id,
        status: API_STATUS.SUCCESS,
      }
    }

    if (contactsApiResponse[index]?.relationId) {
      return carerChildRelationsActions.update({
        body,
        onSuccess: handleCarerChildRelationsSuccess,
        params: [contactsApiResponse[index]?.relationId, {}],
      })
    }

    return carerChildRelationsActions.create({
      body,
      onSuccess: handleCarerChildRelationsSuccess,
      params: [{}],
    })
  }

  handleSessionsSubmitSuccess = (formValues, childId) => async ({ data }) => {
    const {
      carersActions,
      carersSelectors,
      hasAccessToCreateRegularBooking,
      isFinanceV3Enabled,
      modalActions,
      modalConsts,
      navigate,
    } = this.props
    const { selectedContacts } = this.state
    const { id } = data || {}
    const { contacts, firstName } = formValues
    const { contactsApiResponse } = this.apiResponse

    if (id) {
      this.apiResponse.sessionApiResponse = {
        id,
        status: API_STATUS.SUCCESS,
      }
    }

    this.apiResponse.contactsApiResponse = Array.from({ length: contacts.length }, () => ({}))

    _.forEach(contacts, async (contact, index) => {
      const body = carersSelectors.getCarersValuesForm(contact)

      if (false === body?.photo?.isUploaded) {
        body.photo = await this.uploadFile(body.photo.file)
      } else {
        body.photo = body?.photo?.value
      }

      try {
        const carerResponse = await new Promise((resolve, reject) => {
          const existingContactId = contactsApiResponse[index]?.id || selectedContacts[index]?.value

          if (existingContactId) {
            // eslint-disable-next-line no-promise-executor-return
            return carersActions.update({
              body,
              onFailed: reject,
              onSuccess: resolve,
              params: [existingContactId],
            })
          }

          // eslint-disable-next-line no-promise-executor-return
          return carersActions.create({
            body,
            onFailed: reject,
            onSuccess: resolve,
            params: [{}],
          })
        })

        await this.handleContactSubmitSuccess(
          carerResponse,
          contact,
          childId,
          index,
        )
      } catch (error) {
        this.apiResponse.contactsApiResponse[index] = {
          ...this.apiResponse.contactsApiResponse[index],
          error,
          status: API_STATUS.FAILED,
        }
      }
    })

    this.setState({ disableUnsavedPopup: true, isSubmitting: false }, () => {
      if (this.hasErrors()) {
        this.displayErrorModal()

        return
      }

      if (isFinanceV3Enabled && hasAccessToCreateRegularBooking) {
        const { enquiryId } = this.state

        modalActions.show(modalConsts.TYPES.CONFIRM, {
          cancelButtonLabel: i18n.t('global:Later'),
          confirmButtonLabel: i18n.t('module:Children:Child:Add:BookingPatternPopup:bookingPatternButtonLabel'),
          icon: 'warning',
          onCancel: () => this.handleRedirect({ redirectToChildList: true }),
          onConfirm: () => navigate(`${generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS.ADD', {
            childId,
          })}?enquiryId=${enquiryId}`),
          text: i18n.t('module:Children:Child:Add:BookingPatternPopup:message', { firstName }),
        })
      } else {
        this.handleRedirect({ redirectToChildList: true })
      }
    })
  }

  handleSessionsSubmitFailed = (error) => {
    this.apiResponse.sessionApiResponse = {
      ...this.apiResponse.sessionApiResponse,
      error,
      status: API_STATUS.FAILED,
    }

    this.displayErrorModal()
  }

  handleSubmitSuccess = (formValues) => ({ data }) => {
    const { childSessionsActions, childSessionsSelectors } = this.props
    const { enableSessions, enquiryId } = this.state
    const { id } = data
    const { session } = formValues
    const { sessionApiResponse } = this.apiResponse

    logEvent(EVENTS.CHILD_ENROLLED, { context: enquiryId ? 'enquiry' : 'main form' })

    this.apiResponse.childApiResponse = {
      id,
      status: API_STATUS.SUCCESS,
    }

    const isSessionEmpty = childSessionsSelectors.isSessionsEmpty(session)

    if (!enableSessions || isSessionEmpty) {
      this.handleSessionsSubmitSuccess(formValues, id)({})

      return
    }

    const payload = childSessionsSelectors.getPayload({
      ...session,
    })

    if (sessionApiResponse.id) {
      childSessionsActions.update(
        id,
        sessionApiResponse.id,
        payload,
        this.handleSessionsSubmitSuccess(formValues, id),
        null,
        this.handleSessionsSubmitFailed,
      )

      return
    }

    childSessionsActions.create(
      id,
      payload,
      this.handleSessionsSubmitSuccess(formValues, id),
      null,
      this.handleSessionsSubmitFailed,
    )
  }

  handleRedirect = ({ childId, redirectToChildList }) => {
    const { modalActions, navigate } = this.props

    modalActions.hide()

    if (redirectToChildList) {
      return navigate(generateRoute('CHILDREN.INDEX'))
    }

    return navigate(generateRoute('CHILDREN.CHILD.CONTACTS.ADD', { childId }))
  }

  handleStageChange = (stage) => {
    const { formSyncErrors, injectValidation } = this.props
    const { currentStage, highestAvailableStage } = this.state

    if (stage < currentStage) {
      return this.setState({ currentStage: stage })
    }

    if (stage <= highestAvailableStage) {
      if (!_.isEmpty(formSyncErrors)) {
        return injectValidation(formSyncErrors)
      }

      return this.setState({ currentStage: stage })
    }

    return null
  }

  handleAddSessionItemClick = (fields) => () => {
    fields.push({})
  }

  handleDeleteSessionItemClick = (fields, index) => () => {
    fields.remove(index)
  }

  handleDeleteContactSuccess = (fields, index) => () => {
    fields.remove(index)

    this.apiResponse.contactsApiResponse = _.filter(this.apiResponse.contactsApiResponse, (item, i) => i !== index)
  }

  handleDeleteContactClick = (fields, index) => () => {
    const { carerChildRelationsActions } = this.props
    const { contactsApiResponse } = this.apiResponse

    const existingResponse = contactsApiResponse[index]

    if (existingResponse?.status !== API_STATUS.SUCCESS) {
      this.handleDeleteContactSuccess(fields, index)()

      return
    }

    const { relationId } = existingResponse

    carerChildRelationsActions.remove({
      onSuccess: this.handleDeleteContactSuccess(fields, index),
      params: [relationId],
    })
  }

  handleAddContactClick = (fields) => () => {
    this.apiResponse.contactsApiResponse = [
      ...this.apiResponse.contactsApiResponse,
      {},
    ]

    fields.push({ type: TYPE_CONTACT_FORM.NEW })
  }

  handleContactTypeChange = (index) => (e) => {
    const { changeField } = this.props

    if (TYPE_CONTACT_FORM.NEW === e.target.value) {
      changeField(`contacts[${index}]`, { type: TYPE_CONTACT_FORM.NEW })
    }
  }

  fetchCarer = (carerId, index) => {
    const { carersActions, changeField, userTitles } = this.props

    carersActions.get({
      onSuccess: ({ data }) => {
        const relation = data.carerChildRelations[0] || {}

        const carerData = {
          ...data,
          ...relation,
          emailDisabled: !!data?.parentAccount?.lastLoginDate,
          relation: relation?.relation ? {
            label: relation.relation,
            value: relation.relation,
          } : null,
          title: _.find(userTitles, { value: data.title }),
          type: TYPE_CONTACT_FORM.EXISTING,
        }

        changeField(`contacts[${index}]`, carerData)
      },
      params: [carerId, {
        groups: CARER_GROUPS,
      }],
    })
  }

  handleChangeExistingContact = (index) => (option) => {
    this.setState((prevState) => ({
      selectedContacts: {
        ...prevState.selectedContacts,
        [index]: option,
      },
    }))

    if (option?.value) {
      this.fetchCarer(option.value, index)
    }
  }

  handleToggleSessionClick = () => {
    const { changeField } = this.props
    const { enableSessions } = this.state

    if (!enableSessions) {
      changeField('session', {
        sessionCalculation: childSessionConstants.SESSION_CALCULATION.ACTUAL_SESSIONS,
      })
    } else {
      changeField('session', undefined)
    }

    this.setState((prevState) => ({
      enableSessions: !prevState.enableSessions,
    }))
  }

  getInitialValues = () => {
    const {
      SessionCalculationAccess,
      childSessionsSelectors,
      initialValuesFromEnquiries,
      isSessionAverageCostSelected,
      nurserySettingsBase,
    } = this.props
    const { enquiryId, periodOption } = this.state

    const { nurserySettings } = nurserySettingsBase || {}
    const { formattedOpeningDays } = nurserySettings || {}

    const sessionInitialValues = childSessionsSelectors.getInitialValues({
      formattedOpeningDays,
      isSessionAverageCostSelected,
      periodOption,
      sessionCalculationEnabled: SessionCalculationAccess,
    })

    if (enquiryId) {
      if (!initialValuesFromEnquiries || !sessionInitialValues) {
        return null
      }

      return {
        contacts: [{ type: TYPE_CONTACT_FORM.NEW }],
        ...initialValuesFromEnquiries,
        session: {
          ...sessionInitialValues,
          ...initialValuesFromEnquiries?.session || {},
        },
      }
    }

    return {
      contacts: [{ type: TYPE_CONTACT_FORM.NEW }],
      registeredAt: moment().format(DEFAULT_DATE_FORMAT),
      session: sessionInitialValues ? { ...sessionInitialValues } : undefined,
    }
  }

  render() {
    const {
      SessionCalculationAccess,
      errorMessages,
      formValues,
      isAllYearRoundSelected,
      isFinanceV3Enabled,
      nurserySessionsOptions,
      nurserySettingsBase,
      sessionCalculationsOptions,
      userTitles,
    } = this.props
    const {
      cancelLink,
      currentStage,
      enableSessions,
      highestAvailableStage,
      isSubmitting,
      selectedContacts,
    } = this.state

    const { nurserySettings } = nurserySettingsBase || {}
    const { formattedOpeningDays } = nurserySettings || {}

    const initialValues = this.getInitialValues()

    return (
      <ChildAddView
        SessionCalculationAccess={SessionCalculationAccess}
        cancelLink={cancelLink}
        currentStage={currentStage}
        enableSessions={enableSessions}
        errorMessages={errorMessages}
        formValues={formValues}
        highestAvailableStage={highestAvailableStage}
        initialValues={initialValues}
        isAllYearRoundSelected={isAllYearRoundSelected}
        isFinanceV3Enabled={isFinanceV3Enabled}
        isSubmitting={isSubmitting}
        nurserySessionsOptions={nurserySessionsOptions}
        openingDays={formattedOpeningDays}
        selectedContacts={selectedContacts}
        sessionCalculationsOptions={sessionCalculationsOptions}
        userTitles={userTitles}
        onAddContactClick={this.handleAddContactClick}
        onAddSessionItemClick={this.handleAddSessionItemClick}
        onChangeExistingContact={this.handleChangeExistingContact}
        onContactTypeChange={this.handleContactTypeChange}
        onDeleteContactClick={this.handleDeleteContactClick}
        onDeleteSessionItemClick={this.handleDeleteSessionItemClick}
        onMoveToNextStage={this.handleMoveToNextStage}
        onPreviousStageClick={this.handlePreviousStage}
        onReviewSubmit={this.handleReviewSubmit}
        onSetStage={this.handleSetStage}
        onStageChange={this.handleStageChange}
        onToggleSessionClick={this.handleToggleSessionClick}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childSelectors,
  childSessionsSelectors,
  childSingleState,
  nurseriesSelectors,
  params,
}) => ({
  SessionCalculationAccess: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.FINANCE_AUTOMATION],
  }),
  errorMessages: appSelectors.getErrorMessages(childSingleState),
  formValues: getFormValues(CHILD_ADD_FORM)(state),
  hasAccessToCreateRegularBooking: auth.SELECTORS.getIsAuthorised(state, {
    roles: [
      ROLES.ORGANIZATION_DIRECTOR,
      ROLES.ORGANIZATION_NATIONAL_ADMIN,
      ROLES.ORGANIZATION_FINANCE_ADMIN,
      ROLES.ORGANIZATION_LINE_MANAGER,
      ROLES.DEPUTY_MANAGER,
      ROLES.NURSERY_MANAGER,
      ROLES.NURSERY_ADMIN,
      ROLES.SUPER_ADMIN,
    ],
  }),
  initialValuesFromEnquiries: childSelectors.getInitialValuesFromEnquiries(state),
  isAllYearRoundSelected: nurseriesSelectors.isAllYearRoundSelected(state),
  isDirty: isDirtyForm(CHILD_ADD_FORM)(state),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
  isSessionAverageCostSelected: nurseriesSelectors.isSessionAverageCostSelected(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  nurserySessionsOptions: childSessionsSelectors.getNurserySessionsOptions(state),
  nurserySettingsBase: nurseriesSelectors.getNurseryData(state),
  sessionCalculationsOptions: childSessionsSelectors.getSessionCalculationsOptions(state),
  userTitles: appSelectors.getUserTitlesOptions(state),
})

ChildAddContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.ROOM_LEADER,
  ],
}

const mapDispatch = {
  changeField: (field, value) => change(CHILD_ADD_FORM, field, value),
  destroyForm: () => destroy(CHILD_ADD_FORM),
}

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withChildService,
  withChildSessionsService,
  withCarersService,
  withCarerChildRelationsService,
  withNurseriesService,
  withNurserySessionsService,
  withPeriodsService,
  withUploadService,
  withEnquiriesService,
  connect(mapState, mapDispatch),
)

export default enhance(ChildAddContainer)
