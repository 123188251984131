import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withSubdomainService } from 'services/subdomain'

import DiaryLinkExpiredView from './DiaryLinkExpiredView'

const DiaryLinkExpiredContainer = ({ customLogo }) => (
  <DiaryLinkExpiredView
    customLogo={customLogo}
  />
)

const mapState = (state, { subdomainSelectors }) => ({
  customLogo: subdomainSelectors.getCustomLogo(state),
})

const enhance = compose(
  withSubdomainService,
  connect(mapState),
)

export default enhance(DiaryLinkExpiredContainer)
