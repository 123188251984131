import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'

import { ROTA_STATISTICS_TYPE } from 'services/legacy/membershipsShifts/constants'

import { removeDate } from 'utils/date'

import { withModalService } from 'services/utils/modal'
import { withMembershipsShiftsService } from 'services/legacy/membershipsShifts'

import ChildrenExpectedModalView from './ChildrenExpectedModalView'

const STATISTICS_GROUPS = {
  read: [
    'rotaStatistic.periods',
    'rotaStatisticPeriod',
    'rotaStatisticPeriod.breakdowns',
    'rotaStatisticBreakdown',
    'rotaStatisticBreakdown.children',
    'rotaChildResult.child',
    'child',
    'rotaChildResult.extraSessions',
    'rotaChildResult.childSessionPlans',
    'childExtraSession',
    'childExtraSession.nurserySession',
    'childrenSessionPlan',
    'childrenSessionPlan.session',
    'nurserySession',
  ],
}

class ChildrenExpectedModalContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      breakdown: {},
      isFetching: true,
    }
  }

  componentDidMount() {
    const { filters, membershipsShiftsActions, membershipsShiftsSelectors } = this.props
    const { date, room } = filters

    this.setState({ isFetching: true })

    const statisticsCriteria = membershipsShiftsSelectors.getStatisticsCriteria({
      dateFrom: date,
      room,
      type: ROTA_STATISTICS_TYPE.DAILY,
    })

    membershipsShiftsActions.getRotaStatistics({
      onSuccess: this.handleGetDailyStatisticsSuccess,
      onlyData: true,
      params: {
        criteria: statisticsCriteria,
        groups: STATISTICS_GROUPS,
      },
    })
  }

  handleGetDailyStatisticsSuccess = (response) => {
    const { data } = this.props
    let breakdown = {}

    if (!response.data.length) {
      this.setState({ breakdown, isFetching: false })
    }

    // NOTE: we only take the first array item because those are single day stats for the DAILY type
    const statistics = response.data[0].periods?.[0]
    const time = moment(removeDate(data.time, { utc: true })).valueOf()

    _.each(statistics.breakdowns, (item) => {
      const { endTime, startTime } = item

      if (startTime <= time && time <= endTime) {
        breakdown = item
      }
    })

    this.setState({ breakdown, isFetching: false })
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  render() {
    const { data } = this.props
    const { breakdown, isFetching } = this.state

    return (
      <ChildrenExpectedModalView
        breakdown={breakdown}
        data={data}
        isFetching={isFetching}
        onCloseClick={this.handleCloseClick}
      />
    )
  }
}

const enhance = compose(
  withModalService,
  withMembershipsShiftsService,
)

export default enhance(ChildrenExpectedModalContainer)
