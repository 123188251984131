import styled from 'styled-components'

import { BROWSERS } from 'constants/browsers'
import { FLAG_COLOURS } from 'constants/colors'

export const StyledEditorWrapper = styled.div`
  padding: 12px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 8px;
  align-items: flex-end;
  
  textarea {
    height: auto;
    min-height: auto;
    padding: 11px 18px;
    border-radius: 22.5px;

    ${({ browserName }) => browserName === BROWSERS.SAFARI && `
      height: 44px !important;
    `}
  }
`

export const StyledRightColumn = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

export const StyledInvalidLabel = styled.div`
  border-radius: 6px;
  background: ${FLAG_COLOURS.ERROR};
  padding: 4px 8px;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 5px;
  color: #FFF;
  display: flex;
  align-self: flex-end;
  position: absolute;
  top: -26px;
  right: 0;
`
