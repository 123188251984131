import _ from 'lodash'

import React from 'react'
import { Field, FieldArray, reduxForm } from 'redux-form'
import { compose } from 'recompose'

import { isRequired } from 'utils/fieldValidation'

import { Button, Form } from 'components'

import { INTERVAL_PERIOD_TYPE_OPTIONS } from './constants'

const renderTextArea = (isGlobalOptionOn, paymentReminderTags) => (item, index, fields) => {
  const { placeholder } = item

  return (
    <React.Fragment key={_.kebabCase(index)}>
      <Form.Row>
        <Form.Row.FlexItem flex="0 0 80px">
          <Field
            component={Form.TextField}
            disabled={isGlobalOptionOn}
            name={`${item}.intervalPeriodDays`}
            type="number"
            validate={isRequired}
          />
        </Form.Row.FlexItem>
        <Form.Row.TextItem text="days" />
        <Form.Row.FlexItem flex="0 0 80px">
          <Field
            component={Form.Select}
            disabled={isGlobalOptionOn}
            name={`${item}.intervalPeriodType`}
            options={INTERVAL_PERIOD_TYPE_OPTIONS}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
        <Form.Row.TextItem text="invoice due date" />
      </Form.Row>
      <Form.Row>
        <Form.Row.FlexItem>
          <Field
            component={Form.TextEditorField}
            mentionOptions={{ data: paymentReminderTags }}
            name={`${item}.message`}
            placeholder={placeholder}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
        {!isGlobalOptionOn && (
          <Form.Row.FlexItem flex="none">
            <Form.RemoveRowButton onClick={() => fields.remove(index)} />
          </Form.Row.FlexItem>
        )}
      </Form.Row>
    </React.Fragment>
  )
}

const renderTextAreaItem = ({ fields, isGlobalOptionOn, paymentReminderTags }) => (
  <div>
    {fields.map(renderTextArea(isGlobalOptionOn, paymentReminderTags))}
    {!isGlobalOptionOn && (
      <Form.Row>
        <Form.Row.Item>
          <Button.ActionButton label="Add reminder" onClick={() => fields.push({})} />
        </Form.Row.Item>
      </Form.Row>
    )}
  </div>
)

const InvoicePaymentReminderSettingsForm = ({
  globalId,
  handleSubmit,
  hideGlobalOption,
  id,
  isGlobalOptionOn,
  onGlobalOptionClick,
  onSubmit,
  paymentReminderTags,
}) => (
  <Form flex={1} onSubmit={handleSubmit(onSubmit)}>
    {!hideGlobalOption && (
      <Form.Row>
        <Form.Row.Item>
          <Field
            component={Form.Switch}
            name={globalId}
            onChange={onGlobalOptionClick}
          />
        </Form.Row.Item>
        <Form.Row.Item>
          Use global settings
        </Form.Row.Item>
      </Form.Row>
    )}
    <FieldArray
      component={renderTextAreaItem}
      isGlobalOptionOn={isGlobalOptionOn}
      name={id}
      paymentReminderTags={paymentReminderTags}
    />
  </Form>
)

const validate = (values) => {
  const errors = {
    paymentReminders: [],
  }

  const { paymentReminders } = values

  if (paymentReminders && paymentReminders.length) {
    _.forEach(paymentReminders, ({ intervalPeriodDays, intervalPeriodType, message }, i) => {
      if (message && intervalPeriodDays && intervalPeriodType) {
        errors.paymentReminders[i] = {}

        return
      }

      const reminderItemError = {}

      if (!message) {
        reminderItemError.message = 'This field is required.'
      }

      if (!intervalPeriodDays) {
        reminderItemError.intervalPeriodDays = 'This field is required.'
      }

      if (!intervalPeriodType) {
        reminderItemError.intervalPeriodType = 'This field is required.'
      }

      errors.paymentReminders[i] = reminderItemError
    })
  }

  return errors
}

const enhance = compose(reduxForm({ validate }))

export default enhance(InvoicePaymentReminderSettingsForm)
