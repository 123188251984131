import React from 'react'

import { ModalBox } from 'components'

import i18n from 'translations'

import ChildrenExportForm from './components/ChildrenExportForm'

const ChildrenExportModalView = ({
  onCloseClick,
  ...rest
}) => (
  <ModalBox
    title={i18n.t('module:Modals:ChildrenExport:title')}
    autoHeight
    onCloseClick={onCloseClick}
  >
    <ChildrenExportForm
      onCloseClick={onCloseClick}
      {...rest}
    />
  </ModalBox>
)

export default ChildrenExportModalView
