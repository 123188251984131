import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { GroupAvatars, List } from 'components'

import InjuryListItemContent from './InjuryListItemContent'

const InjuryListItem = ({
  accidentDetails,
  borderColor,
  borderTitle,
  childArray,
  childId,
  date,
  description,
  id,
  injurySignatureStatistics,
  isChildContext,
  isHomeAccidents,
  type,
}) => {
  const addHomeAccidentsButton = isChildContext
    ? generateRoute('CHILDREN.CHILD.SAFEGUARDING.HOME_ACCIDENTS.SINGLE_PREVIEW', { childId, injuryId: id })
    : generateRoute('SAFEGUARDING.HOME_ACCIDENTS.SINGLE_PREVIEW', { childId, injuryId: id })

  const addAccidentIncidentButton = isChildContext
    ? generateRoute('CHILDREN.CHILD.SAFEGUARDING.INJURY.SINGLE_PREVIEW', { childId, injuryId: id })
    : generateRoute('SAFEGUARDING.INJURY.SINGLE_PREVIEW', { childId, injuryId: id })

  return (
    <List.Standard.Row
      borderColor={borderColor}
      borderTitle={borderTitle}
      customContent={(
        <InjuryListItemContent
          accidentDetails={accidentDetails}
          date={date}
          description={description}
          injurySignatureStatistics={injurySignatureStatistics}
          isHomeAccidents={isHomeAccidents}
          to={isHomeAccidents ? addHomeAccidentsButton : addAccidentIncidentButton}
          type={type}
        />
      )}
      key={id}
      leftColumn={(
        <GroupAvatars
          backgroundColor={borderColor}
          borderColor={NEUTRAL_COLOURS.SILVER}
          persons={childArray}
          renderUrl={(element) => generateRoute('CHILDREN.CHILD', { childId: element.id })}
        />
      )}
    />
  )
}

export default InjuryListItem
