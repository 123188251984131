import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import {
  CLEAR_FRAMEWORK_PROGRESS_LEVELS,
  LIST_FRAMEWORK_PROGRESS_LEVELS,
  LIST_FRAMEWORK_PROGRESS_LEVELS_FAILED,
  LIST_FRAMEWORK_PROGRESS_LEVELS_SUCCESS,
} from './constants'

const initialState = getBasicListInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_FRAMEWORK_PROGRESS_LEVELS,
    failed: LIST_FRAMEWORK_PROGRESS_LEVELS_FAILED,
    init: LIST_FRAMEWORK_PROGRESS_LEVELS,
    success: LIST_FRAMEWORK_PROGRESS_LEVELS_SUCCESS,
  }, initialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
