import React, { PropsWithChildren } from 'react'

import { Typography } from 'components'

import { StyledContainer, StyledContent, StyledHeader } from './NumberedContentStyled'

interface NumberedContentItemProps {
  content: string | React.ReactNode
  title: string | React.ReactNode
}

const NumberedContentItem: React.FC<PropsWithChildren<NumberedContentItemProps>> = ({
  content,
  title,
}) => (
  <StyledContainer>
    <StyledHeader>
      <Typography bold>
        {title}
      </Typography>
    </StyledHeader>
    <StyledContent>
      {content}
    </StyledContent>
  </StyledContainer>
)

export default NumberedContentItem
