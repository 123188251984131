import ApprovalsWrapper from 'module/Approvals/index'
import FormativeReportsList from 'module/Learning/FormativeReports/FormativeReportsList'
import FormativeReportsPreview from 'module/Learning/FormativeReports/FormativeReportsPreview'
import InjuryApprovals from 'module/Approvals/InjuryApprovals'
import InjuryPreviewWrapperContainer from 'module/Approvals/InjuryApprovals/InjuryPreviewWrapper'
import InjurySingleRecord from 'module/Safeguarding/Injury/InjurySingleRecord'
import LogInjury from 'module/Safeguarding/Injury/LogInjury'
import ObservationsEdit from 'module/Learning/Observations/ObservationsEdit'
import ObservationsList from 'module/Learning/Observations/ObservationsList'
import ObservationsReview from 'module/Learning/Observations/ObservationsReview'
import ObservationFrameworks from 'module/Learning/Observations/ObservationFrameworks'
import ObservationNextSteps from 'module/Learning/Observations/ObservationNextSteps'

import i18n from 'translations'

export const ROUTE_NAMES = {
  'APPROVALS.FORMATIVE_REPORTS': 'APPROVALS.FORMATIVE_REPORTS',
  'APPROVALS.FORMATIVE_REPORTS.PREVIEW': 'APPROVALS.FORMATIVE_REPORTS.PREVIEW',
  'APPROVALS.INDEX': 'APPROVALS.INDEX',
  'APPROVALS.INJURY': 'APPROVALS.INJURY',
  'APPROVALS.INJURY.EDIT': 'APPROVALS.INJURY.EDIT',
  'APPROVALS.INJURY.PREVIEW': 'APPROVALS.INJURY.PREVIEW',
  'APPROVALS.OBSERVATIONS': 'APPROVALS.OBSERVATIONS',
  'APPROVALS.OBSERVATIONS.EDIT': 'APPROVALS.OBSERVATIONS.EDIT',
  'APPROVALS.OBSERVATIONS.EDIT.FRAMEWORKS': 'APPROVALS.OBSERVATIONS.EDIT.FRAMEWORKS',
  'APPROVALS.OBSERVATIONS.EDIT.NEXT_STEPS': 'APPROVALS.OBSERVATIONS.EDIT.NEXT_STEPS',
  'APPROVALS.OBSERVATIONS.REVIEW': 'APPROVALS.OBSERVATIONS.REVIEW',
}

const ApprovalsRouting = {
  childRoutes: [
    {
      childRoutes: [
        {
          childRoutes: [
            {
              childRoutes: [
                {
                  childRoutes: [
                    {
                      component: ObservationFrameworks,
                      name: ROUTE_NAMES['APPROVALS.OBSERVATIONS.EDIT.FRAMEWORKS'],
                      path: 'frameworks',
                      title: i18n.t('module:Approvals:Observations:Frameworks:title'),
                    },
                    {
                      component: ObservationNextSteps,
                      name: ROUTE_NAMES['APPROVALS.OBSERVATIONS.EDIT.NEXT_STEPS'],
                      path: 'next-steps',
                      title: i18n.t('module:Approvals:Observations:NextSteps:title'),
                    },
                  ],
                  path: 'children/:childObservationId',
                },
              ],
              indexRoute: {
                component: ObservationsEdit,
              },
              name: ROUTE_NAMES['APPROVALS.OBSERVATIONS.EDIT'],
              path: 'edit',
              title: i18n.t('module:Approvals:Observations:EditObservation:title'),
            },
          ],
          indexRoute: {
            component: ObservationsReview,
          },
          name: ROUTE_NAMES['APPROVALS.OBSERVATIONS.REVIEW'],
          path: ':observationId',
          title: i18n.t('module:Approvals:Observations:ReviewObservation:title'),
        },
      ],
      indexRoute: {
        component: ObservationsList,
      },
      name: ROUTE_NAMES['APPROVALS.OBSERVATIONS'],
      path: 'observations',
      title: i18n.t('module:Approvals:Observations:title'),
    },
    {
      childRoutes: [
        {
          component: FormativeReportsPreview,
          name: ROUTE_NAMES['APPROVALS.FORMATIVE_REPORTS.PREVIEW'],
          path: ':formativeReportId/preview',
          title: i18n.t('module:Approvals:FormativeReports:FormativeReportPreview:title'),
        },
      ],
      indexRoute: {
        component: FormativeReportsList,
      },
      name: ROUTE_NAMES['APPROVALS.FORMATIVE_REPORTS'],
      path: 'formative-reports',
      title: i18n.t('module:Approvals:FormativeReports:title'),
    },
    {
      childRoutes: [
        {
          childRoutes: [
            {
              component: LogInjury,
              name: ROUTE_NAMES['APPROVALS.INJURY.EDIT'],
              path: 'edit',
            },
          ],
          component: InjuryPreviewWrapperContainer,
          indexRoute: {
            component: InjurySingleRecord,
          },
          name: ROUTE_NAMES['APPROVALS.INJURY.PREVIEW'],
          path: ':injuryType/:injuryId/preview',
        },
      ],
      indexRoute: {
        component: InjuryApprovals,
      },
      name: ROUTE_NAMES['APPROVALS.INJURY'],
      path: 'injury',
      title: i18n.t('module:Approvals:AccidentsIncidents:title'),
    },
  ],
  component: ApprovalsWrapper,
  name: ROUTE_NAMES['APPROVALS.INDEX'],
  path: 'approvals',
  title: i18n.t('module:Approvals:title'),
}

ApprovalsRouting.ROUTE_NAMES = ROUTE_NAMES

export default ApprovalsRouting
