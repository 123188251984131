import _ from 'lodash'

import React from 'react'

import { TextField } from 'components'

import i18n from 'translations'

import { ITEM_CATEGORIES } from './constants'

export const getTableData = ({
  accountCodes,
  data,
  isFinanceV3Enabled,
  onBlur,
  onChange,
}) => {
  if (!data) {
    return null
  }

  return _.map((ITEM_CATEGORIES(isFinanceV3Enabled)), ({ label, value }) => ({
    accountCode: (
      <TextField
        error={accountCodes?.[value]?.error}
        placeholder={i18n.t('module:Management:Finance:AccountCodes:accountCodePlaceholder')}
        type="text"
        value={accountCodes?.[value]?.edited ? accountCodes?.[value].value : data?.[value]}
        onBlur={(e) => onBlur(e, value, label)}
        onChange={(e) => onChange(e, value)}
      />
    ),
    itemCategory: label,
  }))
}
