import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

import { StyledScrollbar } from 'styled/abstract'

export const StyledWrapper = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column;
`

interface StyledContentProps {
  $subMenu?: boolean
}

export const StyledContent = styled.div<StyledContentProps>`
  position: relative;
  
  ${({ $subMenu }) => $subMenu && `
    @media(max-width: 1260px) {
      padding-left: 67px;
      padding-right: 0;
    }
  `}
`

export const StyledMenuContent = styled(StyledScrollbar)`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${getBrandingColor('primary-color')};
  box-shadow: -4px 0 20px 0 rgba(89, 89, 89, .5);
  overflow: auto;
  height: calc(100% - 33px);
`

interface StyledHeaderProps {
  $subMenu?: boolean
}

export const StyledHeader = styled.div<StyledHeaderProps>`
  @media(max-width: 1260px) {
    margin: 0;

    ${({ $subMenu }) => $subMenu && `
      margin-left: -67px;
    `}
  }
`

