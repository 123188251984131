import _ from 'lodash'

import React from 'react'

import { Typography } from 'components'

import { StyledContainer, StyledItemContainer } from './OverallStatisticsStyled'

export interface StatisticProps {
  label: string
  value: string | number | JSX.Element
}

export interface OverallStatisticsPros {
  gap?: string
  statistics?: StatisticProps[]
}

const OverallStatistics: React.FC<OverallStatisticsPros> = ({ gap, statistics }) => (
  <StyledContainer $gap={gap}>
    {_.map(statistics, ({ label, value }, index) => (
      <StyledItemContainer key={index}>
        <Typography>{label}</Typography>
        <Typography fontSize={26} bold>{value}</Typography>
      </StyledItemContainer>
    ))}
  </StyledContainer>
)

export default OverallStatistics
