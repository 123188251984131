import styled from 'styled-components'

export const StyledContainer = styled.div`
  height: 440px;
  max-width: 760px;
  position: relative;
  margin: 0 auto;
  justify-content: center;
  overflow: hidden;
  display: flex;
  background: #000;
  background-size: cover;
`

export const StyledVideo = styled.video`
  height: 100%;
  width: 100%;
`
