export const LIKE_TYPE = {
  ACTIVITY: 'activity',
  BOTTLE: 'bottle',
  CHILD_OBSERVATION: 'childObservation',
  COMMENT: 'comment',
  EATING: 'eating',
  HOME_OBSERVATION: 'homeObsrv',
  NAPPY: 'nappy',
  OBSERVATION: 'observation',
  SIGN_IN: 'signIn',
  SIGN_OUT: 'signOut',
  SLEEP: 'sleep',
  TOILET: 'toilet',
}
