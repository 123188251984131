import _ from 'lodash'
import moment from 'moment'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { convertDecimalToInteger } from 'utils/data'

import { PriceChange } from './constants'

export const getPriceChangesBody = (priceChanges: PriceChange[]) => {
  const finalPriceChanges = []

  _.each(priceChanges, (priceChange, i) => {
    const { endDate, id, prices, startDate } = priceChange || {}
    const [{ id: priceId, price }] = prices || [{}]

    const result = {} as any

    if (prices?.length && _.isNumber(+prices[0].price)) {
      result.prices = [{
        id: priceId,
        price: convertDecimalToInteger(+price),
      }]
    }

    if (id) {
      result.id = id
    }

    if (endDate && priceChanges.length - 1 !== i) {
      result.endDate = moment(endDate).format(DEFAULT_DATE_FORMAT)
    }

    if (startDate && 0 !== i) {
      result.startDate = moment(startDate).format(DEFAULT_DATE_FORMAT)
    }

    if (_.keys(result).length) {
      finalPriceChanges.push(result)
    }
  })

  return finalPriceChanges
}

export const getPriceChangesInitialValues = (priceChanges: PriceChange[]) => {
  if (!priceChanges?.length) {
    return [{}]
  }

  return (
    _.map(priceChanges, (priceChange) => {
      const result = {} as any

      if (priceChange.id) {
        result.id = priceChange.id
      }

      if (priceChange.endDate) {
        result.endDate = moment(priceChange.endDate)
      }

      if (priceChange.startDate) {
        result.startDate = moment(priceChange.startDate)
      }

      if (priceChange?.prices?.[0]?.price) {
        result.prices = [{
          id: priceChange.prices?.[0].id,
          price: priceChange.prices[0].price / 100,
        }]
      }

      return result
    })
  )
}

export const getCurrentPriceFromPriceChanges = (
  priceChanges: PriceChange[],
  startDate?: moment.Moment,
  childAgeInMonths?: number,
): number | null => {
  if (!priceChanges?.length) {
    return null
  }

  if (1 === priceChanges?.length) {
    return priceChanges?.[0]?.prices?.[0]?.price ? priceChanges[0].prices[0].price / 100 : 0
  }

  const currentDate = startDate || moment()
  let foundPriceChange = null

  _.each(priceChanges, (priceChange: PriceChange) => {
    if ((priceChange.endDate && moment(currentDate).isSameOrBefore(priceChange.endDate, 'days'))
      && (priceChange.startDate && moment(currentDate).isSameOrAfter(priceChange.startDate, 'days'))) {
      foundPriceChange = priceChange
    }
  })

  if (null === foundPriceChange) {
    const firstPriceChange = _.first(priceChanges)
    const lastPriceChange = _.last(priceChanges)

    if (firstPriceChange.endDate && moment(currentDate).isSameOrBefore(firstPriceChange.endDate, 'days')) {
      foundPriceChange = firstPriceChange
    }

    if (lastPriceChange.startDate && moment(currentDate).isSameOrAfter(lastPriceChange.startDate, 'days')) {
      foundPriceChange = lastPriceChange
    }
  }

  if (childAgeInMonths && foundPriceChange?.prices?.length) {
    const { prices } = foundPriceChange

    const foundPrice = _.find(prices, ({ priceGroup }) => (
      (!priceGroup.startAge && priceGroup.endAge && childAgeInMonths <= priceGroup.endAge)
      || (priceGroup.startAge && !priceGroup.endAge && childAgeInMonths >= priceGroup.startAge)
      || (childAgeInMonths >= priceGroup.startAge && childAgeInMonths <= priceGroup.endAge)
    ))

    return foundPrice?.price ? foundPrice.price / 100 : null
  }

  return foundPriceChange?.prices?.[0]?.price ? foundPriceChange.prices[0].price / 100 : null
}
