import { generateBasicActions } from 'utils/service'

import {
  CLEAR_NURSERY_CONSUMABLE,
  CREATE_NURSERY_CONSUMABLE,
  CREATE_NURSERY_CONSUMABLE_FAILED,
  CREATE_NURSERY_CONSUMABLE_SUCCESS,
  GET_NURSERY_CONSUMABLE,
  GET_NURSERY_CONSUMABLE_FAILED,
  GET_NURSERY_CONSUMABLE_SUCCESS,
  UPDATE_NURSERY_CONSUMABLE,
  UPDATE_NURSERY_CONSUMABLE_FAILED,
  UPDATE_NURSERY_CONSUMABLE_SUCCESS,
} from './constants'

export const get = (options = {}) => ({ childProductsApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: GET_NURSERY_CONSUMABLE_FAILED,
      init: GET_NURSERY_CONSUMABLE,
      success: GET_NURSERY_CONSUMABLE_SUCCESS,
    },
    options,
    service: childProductsApiClient,
    serviceMethod: 'get',
  })
)

export const create = (options = {}) => ({ childProductsApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: CREATE_NURSERY_CONSUMABLE_FAILED,
      init: CREATE_NURSERY_CONSUMABLE,
      success: CREATE_NURSERY_CONSUMABLE_SUCCESS,
    },
    options,
    service: childProductsApiClient,
    serviceMethod: 'create',
  })
)

export const update = (options = {}) => ({ childProductsApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_NURSERY_CONSUMABLE_FAILED,
      init: UPDATE_NURSERY_CONSUMABLE,
      success: UPDATE_NURSERY_CONSUMABLE_SUCCESS,
    },
    options,
    service: childProductsApiClient,
    serviceMethod: 'update',
  })
)

export const clear = () => ({
  type: CLEAR_NURSERY_CONSUMABLE,
})
