import React from 'react'
import { WrappedFieldProps } from 'redux-form'

import { RadioUserProps } from 'components/RadioUser/RadioUser'

import { RadioUser } from 'components'

type FormRadioUserFullProps = RadioUserProps & WrappedFieldProps

const FormRadioUser: React.FC<FormRadioUserFullProps> = ({
  input: { checked, onChange, value },
  ...rest
}) => (
  <RadioUser
    {...rest}
    checked={checked}
    value={value}
    onChange={onChange}
  />
)

export default FormRadioUser
