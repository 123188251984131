import styled from 'styled-components'

export const StyledContent = styled.div`
  font-size: 20px;
  text-align: center;
  line-height: 1.5;
  max-width: 450px;
  margin: 0 auto;
`

export const StyledFooter = styled.footer`
  margin-top: 30px;
  display: flex;
  justify-content: center;  
`
