import {
  CLEAR_MEMBERSHIP_REGISTER_CHANGELOG,
  GET_MEMBERSHIP_REGISTER_CHANGELOG,
  GET_MEMBERSHIP_REGISTER_CHANGELOG_FAILED,
  GET_MEMBERSHIP_REGISTER_CHANGELOG_SUCCESS,
} from './constants'

export const getChangelog = (id, options = {}) => ({ membershipRegistersApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {} } = options

  dispatch({ type: GET_MEMBERSHIP_REGISTER_CHANGELOG })

  membershipRegistersApiClient.getChangelog(id, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: GET_MEMBERSHIP_REGISTER_CHANGELOG_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: GET_MEMBERSHIP_REGISTER_CHANGELOG_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const clearChangelog = () => ({
  type: CLEAR_MEMBERSHIP_REGISTER_CHANGELOG,
})
