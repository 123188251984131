import { flatten, nest } from 'utils/flatnest'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { CHILD_ADHOC_SESSIONS_FILTER, CHILD_ADHOC_SESSIONS_OPTIONS } from 'services/legacy/childAdhocSessions/constants'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withChildAdhocSessionsService } from 'services/legacy/childAdhocSessions'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withSortingUtils } from 'services/utils/sorting'
import { hasOnlyRoomLeaderOrTeacherAccess } from 'services/security/selectors'

import ChildAdhocSessionsView from './ChildAdhocSessionsView'
import { getTableData } from './helpers'

class ChildAdhocSessionsContainer extends Component {
  constructor(props) {
    super(props)

    const { query } = props.location
    const { date, status } = nest(query)

    const after = date?.after ? moment(date.after).format(DEFAULT_DATE_FORMAT) : undefined
    const before = date?.before ? moment(date.before).format(DEFAULT_DATE_FORMAT) : undefined

    this.state = {
      filters: {
        date: {
          after,
          before,
        },
        status: status || CHILD_ADHOC_SESSIONS_FILTER.ACTIVE,
      },
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { childAdhocSessionsActions } = this.props

    childAdhocSessionsActions.clear()
  }

  fetch = () => {
    const {
      childAdhocSessionsActions,
      childAdhocSessionsSelectors,
      paginationUtils,
      params,
      setLocationQuery,
      sortingUtils,
    } = this.props
    const { filters } = this.state

    const { page, perPage } = paginationUtils
    const { order } = sortingUtils

    const criteria = childAdhocSessionsSelectors.getCriteria(filters)

    setLocationQuery(flatten(filters))

    const apiParams = {
      criteria,
      limit: perPage,
      order,
      page,
      start: (page - 1) * perPage,
    }

    childAdhocSessionsActions.list(params.childId, apiParams)
  }

  handleDateRangeChange = (dateRange) => {
    const [after, before] = dateRange
    const date = {
      after: after ? moment(after).format(DEFAULT_DATE_FORMAT) : undefined,
      before: before ? moment(before).format(DEFAULT_DATE_FORMAT) : undefined,
    }

    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        date,
      },
    }), this.fetch)
  }

  handleStatusChange = (status) => {
    this.setState((prevState) => ({
      filters: { ...prevState.filters, status: status && status.value },
    }), this.fetch)
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleSortChange = (key) => {
    const { paginationUtils, sortingUtils } = this.props
    const { onSortChange } = sortingUtils
    const { onPageChange } = paginationUtils

    onSortChange(onPageChange(this.fetch)(1))(key)
  }

  render() {
    const {
      authAccessMap,
      child,
      childAdhocSessions,
      childAdhocSessionsListState,
      errorMessages,
      isFetching,
      paginationUtils,
      params,
      sortingUtils,
    } = this.props
    const { filters: { date: { after, before }, status } } = this.state
    const { getPageCount, page, perPage } = paginationUtils
    const { sortField, sortOrder } = sortingUtils
    const { childId } = params

    const isLoading = isFetching
    const isEmpty = childAdhocSessionsListState.isEmpty
    const dateRange = [after, before]
    const pageCount = getPageCount(childAdhocSessionsListState.meta.total_results)
    const tableData = getTableData(childAdhocSessions, childId)

    return (
      <ChildAdhocSessionsView
        authAccessMap={authAccessMap}
        child={child}
        childAdhocSessionsTableData={tableData}
        dateRange={dateRange}
        errorMessages={errorMessages}
        isEmpty={isEmpty}
        isLoading={isLoading}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        sortField={sortField}
        sortOrder={sortOrder}
        status={status}
        statusOptions={CHILD_ADHOC_SESSIONS_OPTIONS}
        totalResults={childAdhocSessionsListState.meta.total_results}
        onDateRangeChange={this.handleDateRangeChange}
        onPageChange={this.handlePageChange}
        onSortChange={this.handleSortChange}
        onStatusChange={this.handleStatusChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childAdhocSessionsListState,
  childAdhocSessionsSelectors,
  childSelectors,
}) => ({
  authAccessMap: {
    section: {
      ExtraSessionsIsReadOnly: hasOnlyRoomLeaderOrTeacherAccess(state),
    },
  },
  child: childSelectors.getChildSelector(state),
  childAdhocSessions: childAdhocSessionsSelectors.getFormattedAdhocSessions(state),
  errorMessages: appSelectors.getErrorMessages(childAdhocSessionsListState),
  isFetching: appSelectors.getIsFetching(childAdhocSessionsListState),
})

const enhance = compose(
  withAppService,
  withChildService,
  withChildAdhocSessionsService,
  withPaginationUtils,
  withRouterUtils,
  withSortingUtils,
  connect(mapState),
)

export default enhance(ChildAdhocSessionsContainer)
