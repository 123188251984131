import _ from 'lodash'

import { Option } from 'constants/models'
import { Organization } from 'services/organization/models'
import { getMonthOptions } from 'utils/date'

export interface InitialValuesResponseProps {
  allowOverwrite?: boolean
  excludedMonths?: Option[]
  months?: number
  weeks?: number
}

export const getInitialValues = (data?: Organization): InitialValuesResponseProps => {
  if (!data?.organizationSettings?.invoice?.annualisedInvoiceSettings) {
    return null
  }

  const { annualisedInvoiceSettings } = data.organizationSettings.invoice

  const {
    allowOverwriteOnChildLevel: allowOverwrite,
    excludedMonths,
    months,
    weeks,
  } = annualisedInvoiceSettings

  const monthOptions = getMonthOptions()

  return {
    allowOverwrite,
    excludedMonths: _.map(excludedMonths, (month) => _.find(monthOptions, { value: month })),
    months,
    weeks,
  }
}
