import moment from 'moment'

import React, { PropsWithChildren, useState } from 'react'
import RcTimePicker from 'rc-time-picker'

import { typeByObject } from 'utils/typescript'

import { FieldError, Icon } from 'components'

import 'rc-time-picker/assets/index.css'

import { StyledCloseIcon, StyledIcon, StyledTimePicker, StyledTimePickerWrapper } from './TimePickerStyled'

export const VISUAL_TYPE = {
  MODERN: 'modern',
  TRANSPARENT: 'transparent',
}

export interface TimePickerProps {
  clearable?: boolean
  disabled?: boolean
  disabledHours?: () => number[]
  error?: string | React.ReactNode
  local?: boolean
  onChange?: (date: moment.Moment | number) => void
  placeholder?: string
  value?: moment.Moment | number
  visualType?: typeByObject<typeof VISUAL_TYPE>
}

const TimePicker: React.FC<PropsWithChildren<TimePickerProps>> = ({
  clearable = true,
  disabled,
  disabledHours,
  error,
  local = true,
  onChange,
  placeholder,
  value,
  visualType,
}) => {
  const [open, setOpen] = useState(false)
  let finalValue = value

  if (false === local && value) {
    finalValue = moment(value, 'x').utc()
  }

  return (
    <StyledTimePickerWrapper>
      <StyledTimePicker $error={!!error} $visualType={visualType}>
        <RcTimePicker
          clearIcon={![VISUAL_TYPE.MODERN, VISUAL_TYPE.TRANSPARENT].includes(visualType) && clearable ? (
            <StyledCloseIcon $disabled={disabled}>
              <Icon
                height={22}
                icon="close"
              />
            </StyledCloseIcon>
          ) : (
            <div />
          )}
          defaultOpenValue={moment().set('minute', 0)}
          disabled={disabled}
          disabledHours={disabledHours}
         /* @ts-ignore */
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          inputIcon={VISUAL_TYPE.MODERN !== visualType ? (
            <StyledIcon
              $disabled={disabled}
            >
              <Icon
                cursor={disabled ? 'not-allowed' : 'pointer'}
                height={VISUAL_TYPE.TRANSPARENT === visualType ? 24 : 28}
                icon="clock"
              />
            </StyledIcon>
          ) : null}
          open={open}
          placeholder={placeholder || '--:--'}
          popupClassName="TimePickerPopup"
          showSecond={false}
          value={finalValue as any}
          onChange={(newValue) => {
            if (!newValue) {
              onChange(null)

              return
            }

            const finalNewValue = moment(newValue).set('seconds', 0)

            if (finalValue && finalNewValue && (finalNewValue.format('mm') !== moment(finalValue).format('mm'))) {
              setOpen(false)
            }

            if (false === local) {
              finalValue = finalNewValue.valueOf() - finalNewValue.startOf('day').valueOf() as any

              onChange(finalValue)

              return
            }

            onChange(finalNewValue)
          }}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
        />
      </StyledTimePicker>
      {VISUAL_TYPE.MODERN !== visualType && (
        <FieldError error={error} />
      )}
    </StyledTimePickerWrapper>
  )
}

export default TimePicker
