import React from 'react'

import { Form } from 'components'

import i18n from 'translations'

const ExportFooterActions = ({ isProcessing, onCloseClick }) => (
  <Form.FooterActions
    isSubmitting={isProcessing}
    submitLabel={i18n.t('global:Export')}
    onCancelClick={onCloseClick}
  />
)

export default ExportFooterActions
