export const LIST_REGISTERS = 'LIST_REGISTERS'
export const LIST_REGISTERS_SUCCESS = 'LIST_REGISTERS_SUCCESS'
export const LIST_REGISTERS_FAILED = 'LIST_REGISTERS_FAILED'

export const LIST_REGISTERS_BY_CHILD = 'LIST_REGISTERS_BY_CHILD'
export const LIST_REGISTERS_BY_CHILD_SUCCESS = 'LIST_REGISTERS_BY_CHILD_SUCCESS'
export const LIST_REGISTERS_BY_CHILD_FAILED = 'LIST_REGISTERS_BY_CHILD_FAILED'

export const REGISTER_DOWNLOAD = 'REGISTER_DOWNLOAD'
export const REGISTER_DOWNLOAD_SUCCESS = 'REGISTER_DOWNLOAD_SUCCESS'
export const REGISTER_DOWNLOAD_FAILED = 'REGISTER_DOWNLOAD_FAILED'

export const ADD_OR_UPDATE_ABSENCE_TO_LIST = 'ADD_OR_UPDATE_ABSENCE_TO_LIST'
export const REMOVE_ABSENCE_FROM_LIST = 'REMOVE_ABSENCE_FROM_LIST'
export const RESET_LIST_ITEM = 'RESET_LIST_ITEM'
export const REMOVE_ITEM_FROM_LIST = 'REMOVE_ITEM_FROM_LIST'

export const IMPORT_GLOBAL_LIST = 'REGISTER/IMPORT_GLOBAL_LIST'

export const LOAD_TODAY_DATA = 'REGISTER/LOAD_TODAY_DATA'
