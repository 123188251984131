import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'
import { withChildNotesServiceProps } from 'services/childNotes'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as membershipsSelectors from './selectors'

export interface withMembershipsServiceProps {
  membershipsActions: typeof actions
  membershipsFilesState: any
  membershipsInvitationState: any
  membershipsListState: any
  membershipsOrganizationListState: any
  membershipsSelectors: typeof membershipsSelectors
  membershipsSingleState: any
}

const withMembershipsService = <P extends withChildNotesServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ memberships }) => ({ memberships })

  const mapDispatch = injectActions('membershipsActions', actions)

  const Component = ({ memberships, membershipsActions, ...others }) => (
    <WrappedComponent
      membershipsActions={membershipsActions}
      membershipsFilesState={memberships.files}
      membershipsInvitationState={memberships.invitation}
      membershipsListState={memberships.list}
      membershipsOrganizationListState={memberships.organizationList}
      membershipsSelectors={membershipsSelectors}
      membershipsSingleState={memberships.single}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withMembershipsService
