import { createSelector } from 'reselect'

const getFinanceReports = (state) => state.financeRevenuePerAgeReport

export const getFinanceRevenuePerAgeReportNurseries = createSelector(
  [getFinanceReports],
  (state) => state.nurseries,
)

export const getFinanceRevenuePerAgeReportNurseriesData = createSelector(
  [getFinanceRevenuePerAgeReportNurseries],
  (state) => state.data,
)
