import React from 'react'

import { Section, Tabs } from 'components'

import i18n from 'translations'

import {
  BacsDetails,
  FinanceBankDetails,
  FinanceContactDetails,
  FinanceCurrentPlanSummary,
  FinanceNotes,
} from 'module/Children/Child/ChildFinance/ChildFinanceDetails/components'

import { StyledContactDetail, StyledContainer, StyledDivider, StyledOtherDetail } from './FinancePaymentDetailsStyled'

const FinancePaymentDetails = ({
  bankData,
  childAdhocSessionCount,
  childBacsDetails,
  childBankNotes,
  childCurrentSessions,
  childDiscounts,
  childExtraItems,
  childFirstName,
  childFundings,
  childId,
  contactData,
  invoiceRecipients,
  isBankEditing,
  isCurrentPlanLoading,
  isFinanceV3Enabled,
  isUkCountry,
  onSubmitNotes,
  onViewPlanDetailClick,
}) => (
  <StyledContainer>
    <StyledContactDetail>
      <FinanceContactDetails
        childId={childId}
        contactData={contactData}
        invoiceRecipients={invoiceRecipients}
        isFinanceV3Enabled={isFinanceV3Enabled}
      />
    </StyledContactDetail>
    <StyledDivider>
      <Section.Divider />
    </StyledDivider>
    <StyledOtherDetail>
      <Tabs>
        {!isFinanceV3Enabled && (
          <Tabs.Item minWidth="110px" title={i18n.t('module:Children:Child:Finance:PlanView:tabTitle')}>
            <FinanceCurrentPlanSummary
              childAdhocSessionCount={childAdhocSessionCount}
              childCurrentSessions={childCurrentSessions}
              childDiscounts={childDiscounts}
              childExtraItems={childExtraItems}
              childFirstName={childFirstName}
              childFundings={childFundings}
              isCurrentPlanLoading={isCurrentPlanLoading}
              onViewPlanDetailClick={onViewPlanDetailClick}
            />
          </Tabs.Item>
        )}
        <Tabs.Item minWidth="110px" title={i18n.t('module:Children:Child:Finance:Notes:tabTitle')}>
          <FinanceNotes
            childBankNotes={childBankNotes}
            childId={childId}
            onSubmit={onSubmitNotes}
          />
        </Tabs.Item>
        <Tabs.Item minWidth="110px" title={i18n.t('module:Children:Child:Finance:BankDetails:tabTitle')}>
          <FinanceBankDetails
            bankData={bankData}
            childId={childId}
            disabled={!contactData || (contactData && !contactData.paymentContact && !contactData.paymentEmail)}
            isEdit={isBankEditing}
            isUkCountry={isUkCountry}
          />
        </Tabs.Item>
        {isUkCountry && (
          <Tabs.Item minWidth="110px" title={i18n.t('module:Children:Child:Finance:BacsDetails:tabTitle')}>
            <BacsDetails
              childBacsDetails={childBacsDetails}
              childId={childId}
            />
          </Tabs.Item>
        )}
      </Tabs>
    </StyledOtherDetail>
  </StyledContainer>
)

export default FinancePaymentDetails
