import { BasicListInitialState, getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import { CLEAR_PAYOUTS_LIST, GET_PAYOUTS_LIST, GET_PAYOUTS_LIST_FAILED, GET_PAYOUTS_LIST_SUCCESS } from './constants'

const initialState = getBasicListInitialState

export interface NurseryIntegrationsListState extends BasicListInitialState {}

export default (...props) => {
  const [state = initialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_PAYOUTS_LIST,
    failed: GET_PAYOUTS_LIST_FAILED,
    init: GET_PAYOUTS_LIST,
    success: GET_PAYOUTS_LIST_SUCCESS,
  }, initialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
