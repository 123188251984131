import { RESEND_INVITATION, RESEND_INVITATION_FAILED, RESEND_INVITATION_SUCCESS } from './constants'

export const resendInvitation = (id, options = {}) => ({ membershipsApiClient }) => (dispatch) => {
  const { onFailed, onSuccess } = options

  dispatch({ type: RESEND_INVITATION })

  membershipsApiClient.resendInvitation(id).then(
    (response) => {
      dispatch({
        payload: response,
        type: RESEND_INVITATION_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: RESEND_INVITATION_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}
