import React, { PropsWithChildren } from 'react'

import { StyledTh } from './TableStyled'

interface TableBlankThProps {
  colSpan?: number
}

const TableBlankTh: React.FC<PropsWithChildren<TableBlankThProps>> = ({ children, colSpan }) => (
  <StyledTh $colSpan={colSpan} $blank>
    {children}
  </StyledTh>
)

export default TableBlankTh
