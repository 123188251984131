import React from 'react'

import { AUTH_TYPE_DIRECT } from 'services/device/constants'

import { generateRoute } from 'utils/routing'

import { Button, Typography } from 'components'

import i18n from 'translations'

import { StyledContent } from './AuthoriseDeviceContentStyled'

interface AuthoriseDeviceContentProps {
  onClose?: () => void
}

const AuthoriseDeviceContent: React.FC<AuthoriseDeviceContentProps> = ({ onClose }) => (
  <StyledContent>
    <Typography>
      {i18n.t('module:Shell:AuthoriseDeviceContent:content')}
    </Typography>
    <Button
      label={i18n.t('module:Shell:AuthoriseDeviceContent:authoriseDevice')}
      to={`${generateRoute('MANAGEMENT.SECURITY.DEVICES.ADD')}?m=${AUTH_TYPE_DIRECT}`}
      onClick={onClose}
    />
  </StyledContent>
)

export default AuthoriseDeviceContent
