import React from 'react'

import { Callout, Page, PageTabNav, Section, Spinner } from 'components'

const ChildSessionsView = ({
  childId,
  children,
  errorMessages,
  isLoading,
  router,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    const sessionUrl = `/children/${childId}/booking-pattern/sessions/view`

    return (
      <Section title="Sessions">
        <PageTabNav>
          <PageTabNav.Item
            isActive={router.isActive(sessionUrl, true)}
            label="Current Sessions"
            to={sessionUrl}
          />
          <PageTabNav.Item
            isActive={router.isActive(`${sessionUrl}/history`, true)}
            label="Session history"
            to={`${sessionUrl}/history`}
          />
        </PageTabNav>
        {children}
      </Section>
    )
  }

  return (
    <Page isLoading={isLoading}>
      <Page.Content>
        <Callout content={errorMessages} error />
        {renderContent()}
      </Page.Content>
    </Page>
  )
}

export default ChildSessionsView
