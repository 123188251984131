import _ from 'lodash'
import moment from 'moment'
import { createSelector } from 'reselect'

import { verifyMediaStatus } from 'services/legacy/upload/selectors'

const getDailyDiaryActivities = (state) => state.dailyDiaryActivities

export const getDailyDiaryActivitiesSingle = createSelector([getDailyDiaryActivities], (state) => state.single)

export const getTemporaryActivityId = createSelector(
  [getDailyDiaryActivitiesSingle],
  ({ temporaryActivityId }) => temporaryActivityId,
)

export const getInitialValues = (params) => {
  const initialValues = {}
  const { children, date, items, media, name, record } = params
  const { comments, recordedAt } = record || {}

  if (comments) {
    initialValues.comments = comments
  }

  if (name) {
    initialValues.name = name
  }

  if (children?.length) {
    initialValues.children = children
  }

  if (items && items.length) {
    initialValues.items = _.map(items, ({ food }) => ({
      food: food.id,
    }))
  }

  if (date) {
    initialValues.recordedAt = moment(date)
      .set('hour', moment(recordedAt).format('HH'))
      .set('minute', moment(recordedAt).format('mm'))
  } else {
    initialValues.recordedAt = moment(recordedAt)
  }

  if (media) {
    initialValues.media = _.map(
      _.map(media, (item) => ({
        ...item,
        isUploaded: false,
      })),
      verifyMediaStatus,
    )
  }

  return initialValues
}

export const getValuesForm = (values) => {
  const body = {}

  const { children, comments, date, media, name, recordedAt } = values

  body.name = name || ' '
  body.comments = comments || ''

  if (children && children.length) {
    const childrenList = _.filter(children, (child) => !!child && !!child.id)
    body.children = _.map(childrenList, ({ id }) => id)
  } else {
    body.children = []
  }

  if (date) {
    body.recordedAt = moment(date)
      .set('hour', moment(recordedAt).format('HH'))
      .set('minute', moment(recordedAt).format('mm'))
      .toISOString()
  } else {
    body.recordedAt = moment(recordedAt).toISOString()
  }

  if (media && media.length) {
    body.media = _.map(media, (item) => {
      const mediaBody = {}

      const {
        children: taggedChildren,
        id: mediaId,
        isUploaded,
        mimeType, name: fileName,
        size,
        type,
        url,
      } = item || {}

      if (_.isInteger(mediaId)) {
        mediaBody.id = mediaId
      }

      if (true === isUploaded) {
        mediaBody.isUploaded = true
      }

      if (url) {
        mediaBody.url = url
      }

      if (fileName) {
        mediaBody.name = fileName
      }

      if (size) {
        mediaBody.size = size
      }

      if (mimeType || type) {
        mediaBody.mimeType = mimeType || type
      }

      if (taggedChildren?.length) {
        mediaBody.children = _.map(taggedChildren, ({ id }) => id)
      } else {
        mediaBody.children = []
      }

      return mediaBody
    })
  } else {
    body.media = []
  }

  return body
}
