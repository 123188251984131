export const CLEAR_INJURY_SIGNATURES = 'CLEAR_INJURY_SIGNATURES'

export const GET_INJURY_SIGNATURES = 'GET_INJURY_SIGNATURES'
export const GET_INJURY_SIGNATURES_SUCCESS = 'GET_INJURY_SIGNATURES_SUCCESS'
export const GET_INJURY_SIGNATURES_FAILED = 'GET_INJURY_SIGNATURES_FAILED'

export const UPDATE_INJURY_SIGNATURES = 'UPDATE_INJURY_SIGNATURES'
export const UPDATE_INJURY_SIGNATURES_SUCCESS = 'UPDATE_INJURY_SIGNATURES_SUCCESS'
export const UPDATE_INJURY_SIGNATURES_FAILED = 'UPDATE_INJURY_SIGNATURES_FAILED'

export const UPDATE_INJURY_SIGNATURE_ID = 'UPDATE_INJURY_SIGNATURE_ID'
export const UPDATE_INJURY_SIGNATURE_ID_SUCCESS = 'UPDATE_INJURY_SIGNATURE_ID_SUCCESS'
export const UPDATE_INJURY_SIGNATURE_ID_FAILED = 'UPDATE_INJURY_SIGNATURE_ID_FAILED'

export const UPDATE_INJURY_SIGNATURE = 'UPDATE_INJURY_SIGNATURE'
export const UPDATE_INJURY_SIGNATURE_SUCCESS = 'UPDATE_INJURY_SIGNATURE_SUCCESS'
export const UPDATE_INJURY_SIGNATURE_FAILED = 'UPDATE_INJURY_SIGNATURE_FAILED'

export const CREATE_INJURY_SIGNATURE = 'CREATE_INJURY_SIGNATURE'
export const CREATE_INJURY_SIGNATURE_SUCCESS = 'CREATE_INJURY_SIGNATURE_SUCCESS'
export const CREATE_INJURY_SIGNATURE_FAILED = 'CREATE_INJURY_SIGNATURE_FAILED'

export const CREATE_INJURY_SIGNATURE_ID_SUCCESS = 'CREATE_INJURY_SIGNATURE_ID_SUCCESS'
export const CREATE_INJURY_SIGNATURE_ID_FAILED = 'CREATE_INJURY_SIGNATURE_FAILED'

export const REMOVE_INJURY_SIGNATURE = 'REMOVE_INJURY_SIGNATURE'
export const REMOVE_INJURY_SIGNATURE_SUCCESS = 'REMOVE_INJURY_SIGNATURE_SUCCESS'
export const REMOVE_INJURY_SIGNATURE_FAILED = 'REMOVE_INJURY_SIGNATURE_FAILED'

export const REMOVE_INJURY_SIGNATURE_STAFF_SUCCESS = 'REMOVE_INJURY_SIGNATURE_STAFF_SUCCESS'

export const ADD_INJURY_SIGNATURE_STAFF_MEMBER = 'ADD_INJURY_SIGNATURE_STAFF_MEMBER'
export const UPDATE_INJURY_SIGNATURE_STAFF_MEMBER = 'UPDATE_INJURY_SIGNATURE_STAFF_MEMBER'

export const ADD_INJURY_SIGNATURE_CARER = 'ADD_INJURY_SIGNATURE_CARER'
export const UPDATE_INJURY_SIGNATURE_CARER = 'UPDATE_INJURY_SIGNATURE_CARER'
export const UPDATE_INJURY_SIGNATURE_OTHER_CARER = 'UPDATE_INJURY_SIGNATURE_OTHER_CARER'
export const TOGGLE_ON_PAPER_SIGNATURE_ITEM_VALUE = 'TOGGLE_ON_PAPER_SIGNATURE_ITEM_VALUE'
export const UPDATE_INJURY_SIGNATURE_DOCUMENTS = 'UPDATE_INJURY_SIGNATURE_DOCUMENTS'
export const UPDATE_INJURY_SIGNATURE_MANUALLY = 'UPDATE_INJURY_SIGNATURE_MANUALLY'

export const UPDATE_INJURY_SIGNATURE_STATISTICS = 'UPDATE_INJURY_SIGNATURE_STATISTICS'
