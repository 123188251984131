import _ from 'lodash'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Typography } from 'components'

import i18n from 'translations'

export const renderCarers = (carerChildRelations) => {
  const carers = _.filter(carerChildRelations, ({ hasAccessToChildInParentApp, hasParentalResponsibility }) => (
    hasAccessToChildInParentApp && hasParentalResponsibility
  ))

  if (!carers?.length) {
    return (
      <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} ellipsis>
        {i18n.t('module:Messaging:MessagingList:components:ChildSelect:noContacts')}
      </Typography>
    )
  }

  return (
    <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} ellipsis>
      {_.map(carers, ({ carer, relation }, index) => (
        <React.Fragment key={index}>
          {carer?.fullName}
          {relation ? ` (${relation})` : ''}
          {index < carers.length - 1 ? ', ' : ''}
        </React.Fragment>
      ))}
    </Typography>
  )
}
