import React, { PropsWithChildren } from 'react'

import { StyledRightDetails } from './ProfileHeaderStyled'

const ProfileHeaderDetailsRight: React.FC<PropsWithChildren<any>> = ({ children }) => (
  <StyledRightDetails>
    {children}
  </StyledRightDetails>
)

export default ProfileHeaderDetailsRight
