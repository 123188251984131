import styled from 'styled-components'

import layout from 'constants/layout'
import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledLogOutButton = styled.button`
  display: flex;
  border: none;
  align-items: center;
  text-align: center;
  padding: 8px 12px;
  border-radius: ${layout.borderRadius};
  background: transparent;
  cursor: pointer;
  transition: .2s;
`

export const StyledLogoutLabel = styled.span`
  display: inline-block;
  margin-right: 10px;
  font-size: 18px;
  font-weight: 600;
  color: ${NEUTRAL_COLOURS.WHITE};
  white-space: nowrap;
`

export const StyledButton = styled.div`
  ${({ $full }) => $full && `
    flex: 1;
  `}

  ${({ $disabled }) => $disabled && `
    opacity: .4;
  `}
`

export const StyledLogoutWithHelpWrapper = styled.div`
  display: flex;
  align-items: center;
  
  ${({ hiddenOnMobile }) => hiddenOnMobile && `
    @media(max-width: 580px) {
      display: none;
    }
  `}
`
