import styled from 'styled-components'

import layout from 'constants/layout'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

// stylelint-disable no-duplicate-selectors
export const StyledSliderContainer = styled.div`
  position: relative;
  height: 43px;
  padding: 10px;
  
  .rc-slider {
    position: relative;
    border-radius: 6px;
    width: 160px;
    margin: 0 10px 10px;
    height: 30px;
    padding: 3px 0;
  }

  .rc-slider-rail {
    position: absolute;
    width: 100%;
    border-radius: 6px;
    height: 8px;
    background: #CCC;
  }
  
  .rc-slider-track {
    position: absolute;
    left: 0;
    border-radius: 6px;
    height: 8px;
    background: ${getBrandingColor('primary-color')};
  }
  
  .rc-slider-handle {
    position: absolute;
    width: 14px;
    height: 14px;
    margin-top: -5px;
    cursor: grab;
    border-radius: 50%;
    border: solid 2px #96DBFA;
    background-color: ${NEUTRAL_COLOURS.WHITE};
    touch-action: pan-x;
  }
  
  .rc-slider-handle:focus {
    border-color: #57C5F7;
    box-shadow: 0 0 0 5px #96DBFA;
    outline: none;
  }
  
  .rc-slider-handle-click-focused:focus {
    border-color: #96DBFA;
    box-shadow: unset;
  }
  
  .rc-slider-handle:hover {
    border-color: #57C5F7;
  }
  
  .rc-slider-handle:active {
    border-color: #57C5F7;
    box-shadow: 0 0 5px #57C5F7;
    cursor: grabbing;
  }
  
  .rc-slider-mark {
    position: absolute;
    top: 18px;
    left: 0;
    width: 100%;
    font-size: 12px;
  }
  
  .rc-slider-mark-text {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: #999;
  }
  
  .rc-slider-mark-text-active {
    color: #666;
  }
  
  .rc-slider-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
  }
  
  .rc-slider-disabled {
    background-color: #E9E9E9;
  }
  
  .rc-slider-disabled .rc-slider-track {
    background-color: #CCC;
  }
  
  .rc-slider-disabled .rc-slider-handle,
  .rc-slider-disabled .rc-slider-dot {
    border-color: #CCC;
    box-shadow: none;
    background-color: ${NEUTRAL_COLOURS.WHITE};
    cursor: not-allowed;
  }
  
  .rc-slider-disabled .rc-slider-mark-text,
  .rc-slider-disabled .rc-slider-dot {
    cursor: not-allowed !important;
  }
  
  .rc-slider-vertical {
    width: 14px;
    height: 100%;
    padding: 0 5px;
  }
  
  .rc-slider-vertical .rc-slider-rail {
    height: 100%;
    width: 4px;
  }
  
  .rc-slider-vertical .rc-slider-track {
    left: 5px;
    bottom: 0;
    width: 4px;
  }
  
  .rc-slider-vertical .rc-slider-handle {
    margin-left: -5px;
    touch-action: pan-y;
  }
  
  .rc-slider-vertical .rc-slider-mark {
    top: 0;
    left: 18px;
    height: 100%;
  }
  
  .rc-slider-vertical .rc-slider-step {
    height: 100%;
    width: 4px;
  }
  
  .rc-slider-vertical .rc-slider-dot {
    left: 2px;
    margin-bottom: -4px;
  }
  
  .rc-slider-vertical .rc-slider-dot:first-child {
    margin-bottom: -4px;
  }
  
  .rc-slider-vertical .rc-slider-dot:last-child {
    margin-bottom: -4px;
  }
  
  .rc-slider-tooltip-zoom-down-enter,
  .rc-slider-tooltip-zoom-down-appear {
    animation-duration: .3s;
    animation-fill-mode: both;
    display: block !important;
    animation-play-state: paused;
  }
  
  .rc-slider-tooltip-zoom-down-leave {
    animation-duration: .3s;
    animation-fill-mode: both;
    display: block !important;
    animation-play-state: paused;
  }
  
  .rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
  .rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
    animation-name: rcSliderTooltipZoomDownIn;
    animation-play-state: running;
  }
  
  .rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
    animation-name: rcSliderTooltipZoomDownOut;
    animation-play-state: running;
  }
  
  .rc-slider-tooltip-zoom-down-enter,
  .rc-slider-tooltip-zoom-down-appear {
    transform: scale(0, 0);
    animation-timing-function: cubic-bezier(.23, 1, .32, 1);
  }
  
  .rc-slider-tooltip-zoom-down-leave {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
  }
  
  @keyframes rcSliderTooltipZoomDownIn {
    0% {
      opacity: 0;
      transform-origin: 50% 100%;
      transform: scale(0, 0);
    }
    
    100% {
      transform-origin: 50% 100%;
      transform: scale(1, 1);
    }
  }
  
  @keyframes rcSliderTooltipZoomDownOut {
    0% {
      transform-origin: 50% 100%;
      transform: scale(1, 1);
    }
    
    100% {
      opacity: 0;
      transform-origin: 50% 100%;
      transform: scale(0, 0);
    }
  }
  
  .rc-slider-tooltip {
    position: absolute;
    left: -9999px;
    top: -9999px;
    visibility: visible;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  .rc-slider-tooltip * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  .rc-slider-tooltip-hidden {
    display: none;
  }
  
  .rc-slider-tooltip-placement-top {
    padding: 4px 0 8px 0;
  }
  
  .rc-slider-tooltip-inner {
    padding: 6px 2px;
    min-width: 24px;
    height: 24px;
    font-size: 12px;
    line-height: 1;
    color: ${NEUTRAL_COLOURS.WHITE};
    text-align: center;
    text-decoration: none;
    background-color: #6C6C6C;
    border-radius: 6px;
    box-shadow: 0 0 4px #D9D9D9;
  }
  
  .rc-slider-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  
  .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
    bottom: 4px;
    left: 50%;
    margin-left: -4px;
    border-width: 4px 4px 0;
    border-top-color: #6C6C6C;
  }
  
  .rc-slider-handle {
    border-color: ${getBrandingColor('primary-color')};
    width: 12px;
    height: 18px;
    border-radius: ${layout.borderRadius};
    
    &:hover, &:focus {
      border-color: ${getBrandingColor('secondary-color')};
      box-shadow: none;
    }
  }
  
  .rc-slider-disabled {
    background: transparent;
    
    .rc-slider-handle {
      border-color: #CCC;

      &:hover, &:focus {
        border-color: #CCC;
      }
    }
  }
  
  .rc-slider-dot {
    display: none;
  }
`

export const StyledValue = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
`
