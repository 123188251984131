import { VERSION_MISMATCH } from './constants'

export interface VersionControlCommonState {
  hasVersionMismatch: boolean
}

const initialState = {
  hasVersionMismatch: false,
}

export default (state: VersionControlCommonState = initialState, { type }) => {
  switch (type) {
    case VERSION_MISMATCH:
      return {
        ...state,
        hasVersionMismatch: true,
      }
    default:
      return state
  }
}
