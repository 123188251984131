import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { TERM_STARTING_POINT, TYPE_LABELS, TYPE_OF_TRACKING } from 'services/legacy/formativeReports/constants'

import { Toolbar } from 'components'

import i18n from 'translations'

import { StyledTable, StyledTd, StyledTh, StyledTr } from './ReportDetailStyled'

const ReportDetail = ({ report = {} }) => {
  const { dateFrom, dateTo, period: { label, type: periodType }, type } = report

  const labelFromValue = (value, swap = false) => {
    const convertToLabel = (v) => _.startCase(_.lowerCase(v))

    if (swap) {
      if (value !== TERM_STARTING_POINT) {
        const parts = value.split('_')

        if (2 < parts.length) {
          return convertToLabel(`${parts[1]}_${parts[0]}_${parts[2]}`)
        }

        if (1 < parts.length) {
          return convertToLabel(`${parts[1]}_${parts[0]}`)
        }
      }
    }

    return convertToLabel(value)
  }

  const renderLabel = () => {
    if (TYPE_OF_TRACKING.TERM === periodType) {
      return (
        <StyledTr>
          <StyledTh>
            {`${i18n.t('module:Learning:FormativeReports:term')}:`}
          </StyledTh>
          <StyledTd>
            {labelFromValue(label)}
          </StyledTd>
        </StyledTr>
      )
    }

    return (
      <StyledTr>
        <StyledTh>
          {`${i18n.t('module:Learning:FormativeReports:assessmentPeriod')}:`}
        </StyledTh>
        <StyledTd>
          {label}
        </StyledTd>
      </StyledTr>
    )
  }

  return (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <StyledTable>
            <StyledTr>
              <StyledTh>
                {`${i18n.t('module:Modals:FormativeReportsSendModal:Date:from')}:`}
              </StyledTh>
              <StyledTd>
                {moment(dateFrom).format('DD-MM-YYYY')}
              </StyledTd>
            </StyledTr>
            <StyledTr>
              <StyledTh>
                {`${i18n.t('module:Modals:FormativeReportsSendModal:Date:to')}:`}
              </StyledTh>
              <StyledTd>
                {moment(dateTo).format('DD-MM-YYYY')}
              </StyledTd>
            </StyledTr>
          </StyledTable>
        </Toolbar.Item>
        <Toolbar.Item>
          <StyledTable>
            {renderLabel()}
            <StyledTr>
              <StyledTh>
                {`${i18n.t('module:Learning:FormativeReports:reportType')}:`}
              </StyledTh>
              <StyledTd>
                {TYPE_LABELS[type]}
              </StyledTd>
            </StyledTr>
          </StyledTable>
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )
}

export default ReportDetail
