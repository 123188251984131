import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as financeCreditNotesReportSelectors from './selectors'

const withFinanceCreditNotesReportService = (WrappedComponent) => {
  const mapState = ({ financeCreditNotesReport }) => ({ financeCreditNotesReport })

  const mapDispatch = injectActions('financeCreditNotesReportActions', actions)

  const Component = ({ financeCreditNotesReport, financeCreditNotesReportActions, ...others }) => (
    <WrappedComponent
      financeCreditNotesReportActions={financeCreditNotesReportActions}
      financeCreditNotesReportChildrenState={financeCreditNotesReport.children}
      financeCreditNotesReportNurseriesState={financeCreditNotesReport.nurseries}
      financeCreditNotesReportSelectors={financeCreditNotesReportSelectors}
      financeCreditNotesReportStatisticsState={financeCreditNotesReport.statistics}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withFinanceCreditNotesReportService
