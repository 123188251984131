import _ from 'lodash'

import i18n from 'translations'

export const NURSERY_TABLE_COLUMNS = [
  {
    align: 'left',
    field: 'child',
    title: _.upperFirst(i18n.t('global:child')),
  },
  {
    align: 'left',
    field: 'fundingType',
    title: i18n.t('module:Finance:Reports:Funding:Table:Header:fundingType'),
  },
  {
    field: 'period',
    title: i18n.t('module:Finance:Reports:Funding:Table:Header:period'),
  },
  {
    field: 'hourlyRate',
    title: i18n.t('module:Finance:Reports:Funding:Table:Header:hourlyRate'),
  },
  {
    field: 'totalHours',
    title: i18n.t('module:Finance:Reports:Funding:Table:Header:totalHours'),
  },
  {
    field: 'total',
    title: i18n.t('module:Finance:Reports:Funding:Table:Header:totalFundingValue'),
  },
]

export const ORGANIZATION_TABLE_COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'name',
    title: _.upperFirst(i18n.t('global:site')),
  },
  {
    field: 'total',
    title: i18n.t('module:Finance:Reports:Funding:Organization:Table:Header:totalFundingAmount'),
  },
]
