import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { RootState } from 'core/reducers'

import { injectActions } from 'utils/service'
import { getWorker } from 'utils/workers'

import { properties } from 'app-config'

import { findDomainSuffix, getSubdomainId, hostname } from '../../subdomain/single/selectors'

// @ts-ignore
import Worker from './worker'
import actions from './actions'
import * as uploadSelectors from './selectors'

const domainSuffix = findDomainSuffix(hostname)
const apiUrl = properties.baseApiUri || `https://api${domainSuffix}`
const uploadWorker = getWorker(Worker, (i) => new i.UploadWorker())

export interface withUploadServiceProps {
  uploadActions: typeof actions
  uploadCommonState: any
  uploadSelectors: typeof uploadSelectors
  uploadWorker: any
}

const withUploadService = (WrappedComponent) => {
  const mapState = ({ authentication, upload, ...state }: RootState) => ({
    subdomain: getSubdomainId(state as any),
    token: authentication?.common?.token,
    upload,
  })

  const mapDispatch = injectActions('uploadActions', actions)

  const Component = ({ subdomain, token, upload, uploadActions, ...others }) => {
    useEffect(() => {
      uploadWorker?.then((instance) => {
        instance.addParams(JSON.parse(JSON.stringify({
          apiUrl,
          authorization: token,
          subdomain,
        })))
      })
    }, [subdomain, token])

    return (
      <WrappedComponent
        uploadActions={uploadActions}
        uploadCommonState={upload.common}
        uploadSelectors={uploadSelectors}
        uploadWorker={uploadWorker}
        {...others}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withUploadService
