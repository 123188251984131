export const LIST_FUNDING_TYPES = 'LIST_FUNDING_TYPES'
export const LIST_FUNDING_TYPES_SUCCESS = 'LIST_FUNDING_TYPES_SUCCESS'
export const LIST_FUNDING_TYPES_FAILED = 'LIST_FUNDING_TYPES_FAILED'
export const CLEAR_FUNDING_TYPES = 'CLEAR_FUNDING_TYPES'

const listFundingTypes = (fundingTypesApiClient) => () => (dispatch) => {
  dispatch({
    type: LIST_FUNDING_TYPES,
  })

  fundingTypesApiClient.list().then(
    (data) => {
      dispatch({
        payload: data,
        type: LIST_FUNDING_TYPES_SUCCESS,
      })
    },
    (error) => {
      dispatch({
        error: error.response,
        type: LIST_FUNDING_TYPES_FAILED,
      })
    },
  )
}

const clearFundingTypes = () => ({
  type: CLEAR_FUNDING_TYPES,
})

export default {
  clearFundingTypes,
  listFundingTypes,
}
