import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import selectors from './selectors'

const withAbsenceReasonsService = (WrappedComponent) => {
  const mapState = ({ absenceReasons }) => ({ absenceReasons })

  const mapDispatch = injectActions('absenceReasonsActions', actions)

  const Component = (props) => {
    const { absenceReasons, absenceReasonsActions, ...other } = props

    return (
      <WrappedComponent
        absenceReasonsActions={absenceReasonsActions}
        absenceReasonsListState={absenceReasons.list}
        absenceReasonsSelectors={selectors}
        absenceReasonsSingleState={absenceReasons.single}
        {...other}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withAbsenceReasonsService
