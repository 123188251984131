import React from 'react'
import { WrappedFieldProps } from 'redux-form'

import { TextFieldProps } from 'components/TextField/TextField'

import { TextField } from 'components'

type TextFieldFullProps = TextFieldProps & WrappedFieldProps

const FormTextField: React.FC<TextFieldFullProps> = ({
  input: { onBlur, onChange, value },
  meta: { error, submitFailed, valid },
  ...rest
}) => (
  <TextField
    {...rest}
    error={submitFailed && error}
    valid={!(submitFailed && !valid)}
    value={value}
    onBlur={onBlur}
    onChange={onChange}
  />
)

export default FormTextField
