import _ from 'lodash'

import React from 'react'

import { DataPreview, Typography } from 'components'

import {
  StyledAreaHeader,
  StyledAreaWrapper,
  StyledCategoryWrapper,
  StyledGroupHeader,
  StyledGroupsWrapper,
} from './FrameworkStyled'

const Framework = ({
  actions,
  details,
  progressLevels,
}) => {
  const renderCategories = (
    structure,
    progressAsCheckbox,
  ) => ({ items, name }) => _.map(items, ({ description, id, value }) => {
    const stateObject = _.find(progressLevels, (item) => value === item.id)

    return (
      <StyledCategoryWrapper categoryVisible={structure?.category?.visible} key={id}>
        {structure?.category?.visible && (
          <Typography>
            {name}
          </Typography>
        )}
        <Typography>
          {description}
        </Typography>
        {!progressAsCheckbox && (
          <Typography
            align="right"
            color={stateObject?.colour}
            fontSize={15}
            bold
            nowrap
          >
            {stateObject?.name}
          </Typography>
        )}
      </StyledCategoryWrapper>
    )
  })

  const renderGroups = (structure, progressAsCheckbox) => ({ categories, id, name }) => (
    <StyledGroupsWrapper key={`group_${id}`}>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {structure?.group?.visible && (
        <StyledGroupHeader>
          <Typography
            transform="uppercase"
            bold
          >
            {name}
          </Typography>
        </StyledGroupHeader>
      )}
      {_.map(categories, renderCategories(structure, progressAsCheckbox))}
    </StyledGroupsWrapper>
  )

  const renderAreas = (structure, progressAsCheckbox) => ({ colour, groups, id, name }) => (
    <StyledAreaWrapper key={`area_${id}`}>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {structure?.area?.visible && (
        <StyledAreaHeader color={colour}>
          <Typography transform="uppercase">
            {name}
          </Typography>
        </StyledAreaHeader>
      )}
      {_.map(groups, renderGroups(structure, progressAsCheckbox))}
    </StyledAreaWrapper>
  )

  const renderFrameworks = ({ areas, id, name, structure: { observation, progressAsCheckbox } }) => (
    <DataPreview
      actions={actions(id)}
      content={_.map(areas, renderAreas(observation, progressAsCheckbox))}
      key={`framework_${id}`}
      title={name}
      noPadding
    />
  )

  return _.map(details, renderFrameworks)
}

export default Framework
