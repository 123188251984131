import _ from 'lodash'

import { createSelector } from 'reselect'

import { STATUS_TYPES } from 'services/legacy/invoices/constants'

import i18n from 'translations'

const getAgedDebt = (state) => state.agedDebt

export const getAgedDebtStatisticsDataSelector = createSelector([getAgedDebt], (state) => state.statistics.data)

export const getAgedDebtStatisticsSelector = createSelector(
  [getAgedDebtStatisticsDataSelector],
  (agedDebt) => {
    if (!agedDebt) {
      return null
    }

    return agedDebt.agedDebts
  },
)

export const getAgedDebtStatisticsTotalSelector = createSelector(
  [getAgedDebtStatisticsDataSelector],
  (agedDebt) => {
    if (!agedDebt) {
      return null
    }

    return agedDebt.total
  },
)

export const getAgedDebtChartSummarySelector = createSelector(
  [getAgedDebtStatisticsSelector, getAgedDebtStatisticsTotalSelector],
  (agedDebts, total) => {
    if (!agedDebts || !total) {
      return null
    }

    return {
      categories: _.map(_.keys(agedDebts), (item) => `${item} ${_.lowerFirst(i18n.t('global:Days'))}`),
      series: _.values(agedDebts),
    }
  },
)

export const getAgedDebtReportCriteria = createSelector([(filters) => filters], (filters) => {
  const { child, room } = filters
  const criteria = [
    {
      field: 'invoices.status',
      value: STATUS_TYPES.OVERDUE,
    },
    {
      field: 'or[0][isArchived][eq]',
      value: 0,
    },
    {
      field: 'or[0][balance][lt]',
      value: 0,
    },
  ]

  if (child) {
    criteria.push({
      field: 'id',
      value: child,
    })
  }

  if (room) {
    criteria.push({
      field: 'nurseryClass',
      value: room,
    })
    criteria.push({
      field: 'child.nurseryClass',
      value: room,
    })
  }

  return criteria
})

export const getAgedDebtStatisticsCriteria = createSelector([(filters) => filters], (filters) => {
  const { child, room } = filters
  const criteria = []

  if (child) {
    criteria.push({
      field: 'child',
      value: child,
    })
  }

  if (room) {
    criteria.push({
      field: 'child.nurseryClass',
      value: room,
    })
  }

  return criteria
})
