import _ from 'lodash'

import { properties } from 'app-config'

export const getApplicationProperties = () => properties

export const getMaritalStatuses = () => _.mapValues(
  _.mapKeys(properties?.maritalStatusOptions, ([key]) => key),
  (values) => values[1],
)

export const getToiletTrainingOptions = () => _.mapValues(
  _.mapKeys(properties?.toilettingOptions, ([key]) => key),
  (values) => values[1],
)

export const getDrinkingMethods = () => _.mapValues(
  _.mapKeys(properties?.drinkingOptions, ([key]) => key),
  (values) => values[1],
)

export const getLanguagesDropdownOptions = () => _.map(properties?.languages, (language) => ({
  label: language,
  value: language,
}))

export const getMaritalStatusDropdownOptions = () => _.map(properties?.maritalStatusOptions, (maritalStatus) => ({
  label: maritalStatus[1],
  value: maritalStatus[0],
}))

export const getToiletTrainingDropdownOptions = () => _.map(properties?.toilettingOptions, (toilettingOptions) => ({
  label: toilettingOptions[1],
  value: toilettingOptions[0],
}))

export const getDrinkingMethodsDropdownOptions = () => _.map(properties?.drinkingOptions, (drinkingOptions) => ({
  label: drinkingOptions[1],
  value: drinkingOptions[0],
}))

export const getUserTitlesOptions = () => _.map(properties?.userTitles, (value) => ({
  label: value,
  value,
}))
