import * as Comlink from 'comlink'

export const getWorker = (Worker, proxyCallback) => {
  if ('storybook' === process.env.NODE_ENV) {
    // MOCKED WORKER FOR STORYBOOK
    return proxyCallback({
      UploadWorker: () => ({
        then: () => {},
      }),
    })
  }

  const worker = new Worker()
  const proxy = Comlink.wrap(worker)

  let proxyWorker = null

  try {
    proxyWorker = proxyCallback(proxy)

    return proxyWorker
  } catch (e) {
    return null
  }
}
