import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getFormValues, stopSubmit } from 'redux-form'

import { EVENTS, logEvent } from 'analytics'

import { withChildService } from 'services/legacy/child'
import { withModalService } from 'services/utils/modal'
import { withNurseriesService } from 'services/nurseries'

import i18n from 'translations'

import { ALL_CHILDREN } from './constants'
import { CHILDREN_EXPORT_FORM } from './components/ChildrenExportForm'
import ChildrenExportModalView from './ChildrenExportModalView'

class ChildrenExportModalContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      exportIsProcessed: false,
    }
  }

  componentWillUnmount() {
    const { nurseriesActions } = this.props

    nurseriesActions.clearSingle()
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSendReportSuccess = ({ email }) => () => {
    const { context, modalActions, modalConsts } = this.props

    this.setState({ exportIsProcessed: false })

    logEvent(EVENTS.CHILD_LIST_EXPORTED, { context })

    return modalActions.show(modalConsts.TYPES.ALERT, {
      text: i18n.t('module:Modals:ExportReportModal:alert', { email }),
    })
  }

  handleSendReportFailed = ({ message }) => {
    const { stopSubmitForm } = this.props

    this.setState({ exportIsProcessed: false })

    stopSubmitForm({ user: message })
  }

  handleSendReport = (values) => {
    const {
      childActions,
      childSelectors,
    } = this.props

    const criteria = childSelectors.getExportChildrenReportCriteriaSelector(values)

    const params = { criteria }

    return childActions.exportChildrenReport({
      onFailed: this.handleSendReportFailed,
      onSuccess: this.handleSendReportSuccess(values?.membership),
      params,
    })
  }

  render() {
    const { createdAt, formValues } = this.props
    const { exportIsProcessed } = this.state

    const initialValues = {
      child: ALL_CHILDREN,
      dateRange: [moment(createdAt), moment()],
    }

    return (
      <ChildrenExportModalView
        formValues={formValues}
        initialValues={initialValues}
        isProcessing={exportIsProcessed}
        onCloseClick={this.handleCloseClick}
        onSubmit={this.handleSendReport}
      />
    )
  }
}

const mapState = (state) => ({
  formValues: getFormValues(CHILDREN_EXPORT_FORM)(state),
})

const mapDispatch = {
  stopSubmitForm: (params) => stopSubmit(CHILDREN_EXPORT_FORM, params),
}

const enhance = compose(
  withChildService,
  withModalService,
  withNurseriesService,
  connect(mapState, mapDispatch),
)

export default enhance(ChildrenExportModalContainer)
