import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { TYPES } from 'components/DropdownMenu/constants'

import { generateRoute } from 'utils/routing'
import { getBrandingColor } from 'utils/branding'

import { Button, DateString, DropdownMenu, Space, Typography } from 'components'

import i18n from 'translations'

import { Period, PeriodType } from 'services/booking/periods/models'
import { getStartAndEndDateFromTimeRange } from 'services/legacy/membershipRegisters/helpers'
import { ATTENDANCE_PERIOD_TYPE_OPTIONS } from './constant'

export const getPeriodField = ({ endDate, periodType, startDate }) => {
  if (PeriodType.TERM_DATES === periodType) {
    if (startDate && getStartAndEndDateFromTimeRange) {
      return (
        <React.Fragment>
          <DateString.Range endDate={endDate} startDate={startDate} />
          {30 >= moment(endDate).diff(moment(), 'd') && (
            <React.Fragment>
              <Space space="10px" />
              <Typography color={getBrandingColor('quaternary-color')}>
                {i18n.t('module:Management:AttendancePeriods:planFutureDatesLabel')}
              </Typography>
            </React.Fragment>
          )}
        </React.Fragment>
      )
    }

    return (
      <Typography color={getBrandingColor('quaternary-color')}>
        {i18n.t('module:Management:AttendancePeriods:missingDatesLabel')}
      </Typography>
    )
  }

  return _.find(ATTENDANCE_PERIOD_TYPE_OPTIONS, { value: periodType })?.label
}

export const getTableData = (
  data?: Period[],
  onArchiveClick?: (item: Period, archived: boolean) => void,
) => _.map(data, (item) => {
  const { archived, endDate, id, name, periodType, startDate } = item

  return {
    id,
    name: `${name}${archived ? ` (${i18n.t('global:archived')})` : ''}`,
    options: (
      <DropdownMenu>
        <DropdownMenu.Item
          to={generateRoute('MANAGEMENT.ATTENDANCE_PERIODS.EDIT', { id })}
          type={TYPES.EDIT}
        />
        <DropdownMenu.Item
          type={archived ? TYPES.UNARCHIVE : TYPES.ARCHIVE}
          onClick={() => onArchiveClick(item, !archived)}
        />
      </DropdownMenu>
    ),
    period: getPeriodField({ endDate, periodType, startDate }),
    termDateAction: periodType === PeriodType.TERM_DATES && (
      <Button
        hierarchy="tertiary"
        label={i18n.t('module:Management:AttendancePeriods:Table:actionLabel')}
        to={generateRoute('MANAGEMENT.ATTENDANCE_PERIODS.SET_TERM_DATES', { id })}
      />
    ),
  }
})
