import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { Form } from 'components'

export const SHARE_OBSERVATION_WITH_PARENTS_FORM = 'learning_shareAllObservationsWithParents'

const ShareObservationWithParentsForm = ({
  handleSubmit,
  onSubmit,
}) => (
  <Form flex={1} onSubmit={handleSubmit(onSubmit)}>
    <Form.Row>
      <Form.Row.Item>
        <Field
          component={Form.Switch}
          name="shareAllObservationsWithParents"
        />
      </Form.Row.Item>
    </Form.Row>
  </Form>
)

export default reduxForm({
  form: SHARE_OBSERVATION_WITH_PARENTS_FORM,
})(ShareObservationWithParentsForm)
