import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'

import { Field } from 'components'

import withInvoiceSettingsComponent from '../withInvoiceSettingsComponent'
import InvoiceRadioSettingsForm from './InvoiceRadioSettingsForm'
import InvoiceActionButton from '../InvoiceActionButton'

const FORM_NAME_PREFIX = 'InvoiceRadioSettingsForm'

class InvoiceRadioSettings extends Component {
  constructor(props) {
    super(props)

    const { fields: { item: { id } } } = props

    this.state = {
      formName: `${FORM_NAME_PREFIX}_${id}`,
    }
  }

  handleSubmit = (fields) => {
    const { onSubmit } = this.props

    onSubmit(fields)
  }

  handleGlobalOptionClick = (globalValue) => {
    const { fields: { item: { id, orgValue } }, updateOrgValue } = this.props

    if (globalValue) {
      const { formName } = this.state

      updateOrgValue(formName, id, orgValue)
    }
  }

  render() {
    const {
      caption,
      disableEdit,
      fields: { global, item },
      hideGlobalOption,
      isEdit,
      isGlobalOptionOn,
      isSubmitting,
      onCancelClick,
      onEditClick,
      options,
      rightComponent,
    } = this.props
    const { formName } = this.state
    const { id, value } = item
    const { id: globalId, value: globalValue } = global

    const initialValues = { [globalId]: globalValue, [id]: value }

    const option = _.find(options, { value })
    const label = option ? option.label : null

    return (
      <Field.InlineEdit
        actions={(
          <InvoiceActionButton
            disableEdit={disableEdit}
            formRef={this.myForm}
            isEditMode={isEdit}
            onCancelClick={onCancelClick}
            onClick={onEditClick}
          />
        )}
        description={label}
        editableComponent={(
          <InvoiceRadioSettingsForm
            form={formName}
            globalId={globalId}
            hideGlobalOption={hideGlobalOption}
            id={id}
            initialValues={initialValues}
            isGlobalOptionOn={isGlobalOptionOn}
            options={options}
            ref={(ref) => { this.myForm = ref }}
            rightComponent={rightComponent}
            onGlobalOptionClick={this.handleGlobalOptionClick}
            onSubmit={this.handleSubmit}
          />
        )}
        isEditMode={isEdit}
        isLoading={isSubmitting}
        label={caption}
      />
    )
  }
}

const enhance = compose(withInvoiceSettingsComponent(FORM_NAME_PREFIX))

export default enhance(InvoiceRadioSettings)
