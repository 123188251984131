import { ACTIVITY_TYPE } from 'services/legacy/enquiries/activities/constants'

export const getApiGroups = (type) => {
  switch (type) {
    case ACTIVITY_TYPE.CALL.value:
      return {
        read: [
          'enquiryActivity.author',
          'membership',
        ],
      }
    case ACTIVITY_TYPE.EMAIL.value:
      return {
        read: [
          'enquiryActivity.author',
          'membership',
        ],
      }
    case ACTIVITY_TYPE.NOTE.value:
      return {
        read: [
          'enquiryActivity.author',
          'membership',
        ],
      }
    case ACTIVITY_TYPE.VIEWING.value:
      return {
        read: [
          'enquiryActivity.author',
          'membership',
          'enquiry.status',
          'enquiryStatusType',
          'enquiryActivity.showAround',
          'showAround',
          'showAround.assignedPerson',
          'showAround.status',
          'enquiryActivity.enquiry',
          'enquiryActivity.data',
          'enquirySettings',
        ],
      }
    default:
      return {
        read: [
          'enquiryActivity.author',
          'membership',
          'enquiry.status',
          'enquiryStatusType',
          'enquiryActivity.showAround',
          'showAround',
          'showAround.assignedPerson',
          'showAround.status',
          'enquiryActivity.enquiry',
          'enquiryActivity.data',
          'enquirySettings',
        ],
      }
  }
}
