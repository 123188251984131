import React, { useMemo } from 'react'
import { Field } from 'redux-form'

import { OneOffBookingTypes } from 'services/booking/childProducts/constants'

import { isRequired } from 'utils/fieldValidation'

import { getCriteria } from 'services/booking/childProducts/list/selectors'

import { Form } from 'components'

import i18n from 'translations'

import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

const EXTRA_SESSION_GROUPS = {
  read: [
    'oneOffChildProduct.product',
    'nurserySessionProduct',
    'nurserySessionProduct.items',
    'oneOffChildProduct.listDetails',
  ],
}

interface ExtraSessionFormFieldsProps {
  childId?: string
  isReadOnly?: boolean
}

export interface ExtraSessionFormProps {
  extraSession?: string
  total?: number
}

const ExtraSessionFormFields = ({
  childId,
  isReadOnly,
}: ExtraSessionFormFieldsProps): JSX.Element => {
  const criteria = useMemo(() => getCriteria({
    childId,
    invoiced: false,
    type: OneOffBookingTypes.EXTRA_SESSION,
  }), [childId])

  return (
    <React.Fragment>
      <Form.Row
        label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:ExtraSession:label')}
        width={{ field: '400px' }}
        required
      >
        <Field
          component={Form.InfiniteDropdowns.ChildProducts}
          criteria={criteria}
          disabled={isReadOnly}
          extraFields={['type', 'price', 'quantity']}
          groups={EXTRA_SESSION_GROUPS}
          maxMenuHeight={150}
          name="customExtraSessionChildProduct"
          placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:ExtraSession:placeholder')}
          validate={isRequired}
          pendingOnly
        />
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Amount:label')}
        width={{ field: '150px' }}
        required
      >
        <SubdomainCurrencyProvider>
          {({ currencySymbol }) => (
            <Field
              component={Form.TextField}
              name="total"
              placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Amount:placeholder')}
              prefix={currencySymbol}
              prefixWidth="30px"
              type="number"
              disabled
            />
          )}
        </SubdomainCurrencyProvider>
      </Form.Row>
    </React.Fragment>
  )
}

export default ExtraSessionFormFields
