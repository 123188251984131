import _ from 'lodash'
import Skeleton from '@mui/material/Skeleton'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { FundingSummary as FundingSummaryModel } from 'services/child/fundingSummaryCalculator/constants'

import { Box, Grid, Hr, Space, Typography } from 'components'

import i18n from 'translations'

const i18prefix = 'module:Children:Child:BookingPattern:RegularBookings:components:FundingSummary'

interface FundingSummaryProps {
  data: FundingSummaryModel[]
  isFetching: boolean
}

const FundingSummary: React.FC<FundingSummaryProps> = ({ data, isFetching }) => {
  if (isFetching) {
    return (
      <Box marginBottom={0} withPadding>
        <Skeleton sx={{ fontSize: '32px' }} variant="text" />
        <Space space="8px" />
        <Skeleton sx={{ fontSize: '22px' }} variant="text" />
        <Skeleton sx={{ fontSize: '22px' }} variant="text" />
        <Space space="10px" />
        <Skeleton sx={{ fontSize: '22px' }} variant="text" />
      </Box>
    )
  }

  if (!isFetching && !data?.length) {
    return null
  }

  return (
    <React.Fragment>
      {_.map(data, (item: FundingSummaryModel) => (
        <Box marginBottom={0} withPadding>
          <Typography fontSize={18} bold>
            {item.name}
          </Typography>
          <Space space="10px" />
          {_.map(item.fundingTypicalWeekAllocations, (weekAllocation) => (
            <Grid>
              <Grid.Item flex={{ desktop: 1 }}>
                {i18n.t(`${i18prefix}:fundingAllocatedForTypicalWeek`, { week: weekAllocation.alternate })}
              </Grid.Item>
              <Grid.Item flex={{ desktop: '1fr' }} textAlign={{ desktop: 'right' }}>
                {weekAllocation.actualAllocation}
                {' / '}
                {i18n.t('global:Hours', { value: weekAllocation.maxAllocation })}
              </Grid.Item>
            </Grid>
          ))}
          <Grid>
            <Grid.Item flex={{ desktop: 1 }}>
              {i18n.t(`${i18prefix}:fundingAllocatedForThisBookingPattern`)}
            </Grid.Item>
            <Grid.Item flex={{ desktop: '1fr' }} textAlign={{ desktop: 'right' }}>
              {i18n.t('global:Hours', { value: item.fundingAllocated })}
            </Grid.Item>
          </Grid>
          <Grid>
            <Grid.Item flex={{ desktop: 1 }}>
              <Typography bold>
                {i18n.t(`${i18prefix}:fundingAllocatedAcrossBookingPatterns`)}
              </Typography>
            </Grid.Item>
            <Grid.Item flex={{ desktop: '1fr' }} textAlign={{ desktop: 'right' }}>
              <Typography bold>
                {i18n.t('global:Hours', { value: item.fundingAllocatedTotal })}
              </Typography>
            </Grid.Item>
          </Grid>
          <Hr color={NEUTRAL_COLOURS.GRAY_TERTIARY} margin="10px 0" />
          <Grid>
            <Grid.Item flex={{ desktop: 1 }}>
              {i18n.t(`${i18prefix}:fundingInvoiced`)}
            </Grid.Item>
            <Grid.Item flex={{ desktop: '1fr' }} textAlign={{ desktop: 'right' }}>
              {i18n.t('global:Hours', { value: item.fundingInvoiced })}
            </Grid.Item>
          </Grid>
        </Box>
      ))}
    </React.Fragment>
  )
}

export default FundingSummary
