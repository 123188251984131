import React from 'react'
import isEqual from 'react-fast-compare'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Typography } from 'components'

import i18n from 'translations'

const ChannelLastMessage = ({ authUserUuid, channel, isArchived }) => {
  const { lastMessage } = channel

  const renderContent = () => {
    if (!lastMessage) {
      return (
        <Typography variant="span" italic>
          ---
        </Typography>
      )
    }

    return (
      <Typography variant="span" width={isArchived ? 61 : 128} ellipsis>
        <Typography fontSize={14} bold inline>
          {authUserUuid === lastMessage.author?.id
            ? i18n.t('module:Messaging:Channel:you')
            : lastMessage.author?.name}
        </Typography>
        {': '}
        {lastMessage.content}
      </Typography>
    )
  }

  return (
    <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
      {renderContent()}
    </Typography>
  )
}

export default React.memo(ChannelLastMessage, isEqual)
