import {
  ADD_ACTIVITY,
  ADD_ACTIVITY_FAILED,
  ADD_ACTIVITY_SUCCESS,
  GET_ACTIVITY,
  GET_ACTIVITY_FAILED,
  GET_ACTIVITY_SUCCESS,
  REMOVE_ACTIVITY,
  REMOVE_ACTIVITY_FAILED,
  REMOVE_ACTIVITY_SUCCESS,
  SET_TEMPORARY_ACTIVITY_ID,
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_FAILED,
  UPDATE_ACTIVITY_SUCCESS,
} from './constants'

export const getRecord = (id, options = {}) => ({ dailyDiaryActivitiesApiClient }) => async (dispatch) => {
  const { onSuccess, onlyData, silent } = options

  if (!onlyData) {
    dispatch({
      silent,
      type: GET_ACTIVITY,
    })
  }

  try {
    const response = await dailyDiaryActivitiesApiClient.getRecord(id)

    if (!onlyData) {
      dispatch({
        payload: response,
        type: GET_ACTIVITY_SUCCESS,
      })
    }

    if (onSuccess) {
      onSuccess(response)
    }

    return response
  } catch (error) {
    if (!onlyData) {
      dispatch({
        error,
        type: GET_ACTIVITY_FAILED,
      })
    }
  }

  return null
}

export const addRecord = (body, onSuccess, onFailed) => ({ dailyDiaryActivitiesApiClient }) => (dispatch) => {
  dispatch({ type: ADD_ACTIVITY })

  dailyDiaryActivitiesApiClient.addRecord(body).then(
    ({ data }) => {
      dispatch({ payload: data, type: ADD_ACTIVITY_SUCCESS })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: ADD_ACTIVITY_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const removeRecord = (id, onSuccess, onFailed) => ({ dailyDiaryActivitiesApiClient }) => (dispatch) => {
  dispatch({ id, type: REMOVE_ACTIVITY })

  dailyDiaryActivitiesApiClient.removeRecord(id).then(
    () => {
      dispatch({ id, type: REMOVE_ACTIVITY_SUCCESS })

      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: REMOVE_ACTIVITY_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const updateRecord = (id, body, onSuccess, onFailed) => ({ dailyDiaryActivitiesApiClient }) => (dispatch) => {
  dispatch({ type: UPDATE_ACTIVITY })

  dailyDiaryActivitiesApiClient.updateRecord(id, body).then(
    ({ data }) => {
      dispatch({ payload: data, type: UPDATE_ACTIVITY_SUCCESS })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: UPDATE_ACTIVITY_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const setTemporaryActivityId = (payload) => ({
  payload,
  type: SET_TEMPORARY_ACTIVITY_ID,
})
