import { NEUTRAL_COLOURS } from 'constants/colors'

import i18n from 'translations'

export const REGISTER_COLORS = {
  absence: '#fa6e90',
  absentBadge: '#50E3C2',
  badge: 'red',
  comment: '#36a9e0',
  extraBadge: '#36A9E0',
  extraTimeIn: '#B09BD8',
  holidayBadge: '#FFA600',
  staffLog: '#00A098',
  timeIn: '#77dd77',
  timeOut: NEUTRAL_COLOURS.SALMON,
}

export const REGISTER_ACTION_TYPES = {
  ABSENCE: 'ABSENCE',
  COMMENT: 'COMMENT',
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
}

export const REGISTER_ACTIVITIES = {
  ABSENCE: 'ABSENCE',
  ADDED_COMMENTS: 'ADDED_COMMENTS',
  ADDED_SIGN_IN: 'ADDED_SIGN_IN',
  ADDED_SIGN_OUT: 'ADDED_SIGN_OUT',
  EDITED_COMMENTS: 'EDITED_COMMENTS',
  EDITED_SIGN_IN: 'EDITED_SIGN_IN',
  EDITED_SIGN_OUT: 'EDITED_SIGN_OUT',
  HOLIDAY: 'HOLIDAY',
  REMOVED_SIGN_IN: 'REMOVED_SIGN_IN',
}

export const ABSENCE_TYPE = {
  ABSENCE: 'ABSENCE',
  HOLIDAY: 'HOLIDAY',
}

export const ABSENCE_TYPE_OPTIONS = [
  {
    label: 'Holiday',
    value: ABSENCE_TYPE.HOLIDAY,
  },
  {
    label: 'Absent',
    value: ABSENCE_TYPE.ABSENCE,
  },
]

export const REGISTER_STATUS = {
  ABSENT: 'ABSENT',
  TIMED_IN: 'TIMED_IN',
  TIMED_OUT: 'TIMED_OUT',
}

export const REGISTER_STATUS_OPTIONS = [
  {
    label: i18n.t('global:TimedIn'),
    value: REGISTER_STATUS.TIMED_IN,
  },
  {
    label: i18n.t('global:TimedOut'),
    value: REGISTER_STATUS.TIMED_OUT,
  },
  {
    label: i18n.t('global:Absent'),
    value: REGISTER_STATUS.ABSENT,
  },
]
