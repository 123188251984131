import _ from 'lodash'
import moment from 'moment'

import React, { useState } from 'react'
import Collapse from '@material-ui/core/Collapse'

import colors from 'constants/colors'

import { Avatar, Button, Checkbox, CircleIcon, Table, Typography } from 'components'

import i18n from 'translations'

import FundingTableDetailsTable from './FundingTableDetailsTable'
import FundingTableDetailAllocationCell from './FundingTableDetailAllocationCell'
import { StyledCurrentFundingCell } from './FundingTableStyled'

const TableExpandableRow = ({
  item,
  onAllocateChildFundingClick,
  onArchiveChildFundingClick,
  onAssignChildFundingClick,
  onEditChildFundingClick,
  onSelectChild,
  onShowAllFundingClick,
  selectedChildren,
}) => {
  const [expanded, setExpanded] = useState(false)

  const {
    ageMonths,
    birthDate,
    currentChildFundings,
    displayName,
    firstName,
    hasManualFunding,
    id: childId,
    photo,
    surname,
  } = item

  const checked = 0 <= _.find(selectedChildren, (selectedChildId) => childId === selectedChildId)

  const renderFundingCell = () => {
    if (1 === currentChildFundings?.length) {
      const {
        displayName: fundingDisplayName,
        fundingAllocationLabel,
        hoursAllocated,
        id: childFundingId,
        isManualType,
        totalHours,
      } = currentChildFundings[0]

      return (
        <React.Fragment>
          <Typography padding="0 0 8px">
            {fundingDisplayName}
          </Typography>
          <FundingTableDetailAllocationCell
            childFundingId={childFundingId}
            childId={childId}
            fundingAllocationLabel={fundingAllocationLabel}
            hoursAllocated={hoursAllocated}
            isManualType={isManualType}
            totalHours={totalHours}
            onAllocateChildFundingClick={onAllocateChildFundingClick}
          />
        </React.Fragment>
      )
    }

    return (
      <StyledCurrentFundingCell>
        {hasManualFunding && (
          <CircleIcon
            background={colors.gold}
            icon="exclamation-mark"
            size={18}
          />
        )}
        <div>
          {i18n.t('module:Finance:Funding:Table:numberOfFundingTypesLabel', {
            totalFundingTypes: currentChildFundings?.length || 0,
          })}
        </div>
      </StyledCurrentFundingCell>
    )
  }

  return (
    <React.Fragment>
      <Table.Tr>
        <Table.Td>
          <Checkbox
            value={checked}
            onChange={() => onSelectChild(childId)}
          />
        </Table.Td>
        <Table.Td align="left">
          <Avatar
            avatarSize="medium"
            initials={[firstName, surname]}
            src={photo}
            title={displayName}
          />
        </Table.Td>
        <Table.Td align="left">
          {`${i18n.t('global:ageMonths', { ageMonths })} (${moment(birthDate).format('DD/MM/YYYY')})`}
        </Table.Td>
        <Table.Td align="left">
          {renderFundingCell()}
        </Table.Td>
        <Table.Td>
          <Button.TableAction
            disabled={!currentChildFundings?.length}
            icon={expanded ? 'chevron-up' : 'chevron-down'}
            onClick={() => setExpanded(!expanded)}
          />
        </Table.Td>
      </Table.Tr>
      {currentChildFundings?.length
        ? (
          <Table.Tr>
            <Table.Td colSpan={5} style={{ border: 'none', padding: 0 }}>
              <Collapse in={expanded}>
                <FundingTableDetailsTable
                  childId={childId}
                  currentChildFundings={currentChildFundings}
                  onAllocateChildFundingClick={onAllocateChildFundingClick}
                  onArchiveChildFundingClick={onArchiveChildFundingClick}
                  onAssignChildFundingClick={onAssignChildFundingClick}
                  onEditChildFundingClick={onEditChildFundingClick}
                  onShowAllFundingClick={onShowAllFundingClick}
                />
              </Collapse>
            </Table.Td>
          </Table.Tr>
        )
        : null}
    </React.Fragment>
  )
}

export default TableExpandableRow
