import i18n from 'translations'

export const BREAKDOWN = {
  DAILY: 'worklog-daily',
  PAYROLL: 'payroll-actual',
  TOTAL: 'worklog-total',
  WEEKLY: 'worklog-weekly',
}

export const BREAKDOWN_OPTIONS = [
  {
    label: i18n.t('module:Modals:Staff:Export:Breakdown:daily'),
    value: BREAKDOWN.DAILY,
  },
  {
    label: i18n.t('module:Modals:Staff:Export:Breakdown:weekly'),
    value: BREAKDOWN.WEEKLY,
  },
  {
    label: i18n.t('module:Modals:Staff:Export:Breakdown:total'),
    value: BREAKDOWN.TOTAL,
  },
  {
    label: i18n.t('module:Modals:Staff:Export:Breakdown:Payroll:actuals'),
    value: BREAKDOWN.PAYROLL,
  },
]
