const ALLOCATION_PERIOD = {
  ALLOCATED_OVER_TERM: 'ALLOCATED_OVER_TERM',
  ALLOCATED_YEAR_ROUND: 'ALLOCATED_YEAR_ROUND',
}

const ALLOCATION_PERIOD_TEXT = {
  [ALLOCATION_PERIOD.ALLOCATED_OVER_TERM]: 'Term time',
  [ALLOCATION_PERIOD.ALLOCATED_YEAR_ROUND]: 'All year round',
}

const ALLOCATION_PERIOD_OPTIONS = [
  {
    label: ALLOCATION_PERIOD_TEXT[ALLOCATION_PERIOD.ALLOCATED_OVER_TERM],
    value: ALLOCATION_PERIOD.ALLOCATED_OVER_TERM,
  },
  {
    label: ALLOCATION_PERIOD_TEXT[ALLOCATION_PERIOD.ALLOCATED_YEAR_ROUND],
    value: ALLOCATION_PERIOD.ALLOCATED_YEAR_ROUND,
  },
]

const SESSION_CALCULATION = {
  ACTUAL_SESSIONS: 'ACTUAL_SESSIONS',
  AVERAGE_COST: 'AVERAGE_COST',
}

export default {
  ALLOCATION_PERIOD,
  ALLOCATION_PERIOD_OPTIONS,
  ALLOCATION_PERIOD_TEXT,
  SESSION_CALCULATION,
}
