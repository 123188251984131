import _ from 'lodash'

import React, { useMemo, useState } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import EnquiryReport from 'module/Enquiries/Report'

import i18n from 'translations'

import AnnualRevenue from './AnnualRevenue'
import Forecasting from './Forecasting'

import ReportsWrapperView from './ReportsWrapperView'

const ReportsWrapperContainer = ({
  GroupReportingEnabled,
  financialReportsRolesGranted,
  isAnnualRevenueGranted,
  isEnquiryReportGranted,
  isFinanceV3FFEnabled,
  isForecastingGranted,
}) => {
  const [search, setSearch] = useState('')

  const items = useMemo(() => {
    const reportItems = [
      {
        description: i18n.t('module:Reports:ChildrenSignedUp:Organization:description'),
        hidden: !GroupReportingEnabled,
        title: i18n.t('module:Reports:ChildrenSignedUp:Organization:title'),
        to: generateRoute('ORGANIZATION_REPORTS.NURSERIES_SIGNED_UP'),
      },
      {
        description: i18n.t('module:Reports:ChildrenStarting:Organization:description'),
        hidden: !GroupReportingEnabled,
        title: i18n.t('module:Reports:ChildrenStarting:Organization:title'),
        to: generateRoute('ORGANIZATION_REPORTS.NURSERIES_STARTING'),
      },
      {
        description: i18n.t('module:Reports:ChildrenLeaving:Organization:description'),
        hidden: !GroupReportingEnabled,
        title: i18n.t('module:Reports:ChildrenLeaving:Organization:title'),
        to: generateRoute('ORGANIZATION_REPORTS.NURSERIES_LEAVING'),
      },
      {
        description: i18n.t('module:Organization:Reports:GroupRevenue:description'),
        hidden: !financialReportsRolesGranted,
        title: i18n.t('module:Organization:Reports:GroupRevenue:title'),
        to: generateRoute('ORGANIZATION_REPORTS.GROUP_REVENUE'),
      },
      {
        description: i18n.t('module:Organization:Reports:AnnualRevenue:description'),
        hidden: !isAnnualRevenueGranted || !financialReportsRolesGranted,
        title: i18n.t('module:Organization:Reports:AnnualRevenue:title'),
        to: generateRoute('ORGANIZATION_REPORTS.ANNUAL_REVENUE'),
      },
      {
        description: i18n.t('module:Organization:Reports:Forecasting:description'),
        hidden: !isForecastingGranted || !financialReportsRolesGranted,
        title: i18n.t('module:Organization:Reports:Forecasting:title'),
        to: generateRoute('ORGANIZATION_REPORTS.FORECASTING'),
      },
      {
        description: i18n.t('module:Finance:Reports:AgedDebt:Organization:description'),
        hidden: !GroupReportingEnabled || !financialReportsRolesGranted,
        title: i18n.t('module:Finance:Reports:AgedDebt:Organization:title'),
        to: generateRoute('ORGANIZATION_REPORTS.AGED_DEBT'),
      },
      {
        description: i18n.t('module:Finance:Reports:BadDebt:Organization:description'),
        hidden: !GroupReportingEnabled || !financialReportsRolesGranted,
        title: i18n.t('module:Finance:Reports:BadDebt:Organization:title'),
        to: generateRoute('ORGANIZATION_REPORTS.BAD_DEBT'),
      },
      {
        description: i18n.t('module:Finance:Reports:Balances:Organization:description'),
        hidden: !GroupReportingEnabled || !financialReportsRolesGranted,
        title: i18n.t('module:Finance:Reports:Balances:Organization:title'),
        to: generateRoute('ORGANIZATION_REPORTS.BALANCE'),
      },
      {
        description: i18n.t('module:Finance:Reports:CreditNotes:Organization:description'),
        hidden: !GroupReportingEnabled || !financialReportsRolesGranted,
        title: i18n.t('module:Finance:Reports:CreditNotes:Organization:title'),
        to: generateRoute('ORGANIZATION_REPORTS.CREDIT_NOTES'),
      },
      {
        description: i18n.t('module:Finance:Reports:Deposits:Organization:description'),
        hidden: !GroupReportingEnabled || !financialReportsRolesGranted,
        title: i18n.t('module:Finance:Reports:Deposits:Organization:title'),
        to: generateRoute('ORGANIZATION_REPORTS.DEPOSITS'),
      },
      {
        description: i18n.t('module:Finance:Reports:Funding:Organization:description'),
        hidden: !GroupReportingEnabled || isFinanceV3FFEnabled,
        title: i18n.t('module:Finance:Reports:Funding:Organization:title'),
        to: generateRoute('ORGANIZATION_REPORTS.FUNDING'),
      },
      {
        description: i18n.t('module:Finance:Reports:RevenuePerAge:Organization:description'),
        hidden: !GroupReportingEnabled || !financialReportsRolesGranted,
        title: i18n.t('module:Finance:Reports:RevenuePerAge:Organization:title'),
        to: generateRoute('ORGANIZATION_REPORTS.REVENUE_PER_AGE'),
      },
      {
        description: i18n.t('module:Finance:Reports:RevenuePerRoom:Organization:description'),
        hidden: !GroupReportingEnabled || !financialReportsRolesGranted,
        title: i18n.t('module:Finance:Reports:RevenuePerRoom:Organization:title'),
        to: generateRoute('ORGANIZATION_REPORTS.REVENUE_PER_ROOM'),
      },
      {
        description: i18n.t('module:Enquiries:Report:ConversionRate:Organization:description'),
        hidden: !isEnquiryReportGranted,
        title: i18n.t('module:Enquiries:Report:ConversionRate:Organization:title'),
        to: generateRoute('ORGANIZATION_REPORTS.ENQUIRIES_CONVERSION_RATE'),
      },
      {
        description: i18n.t('module:Enquiries:Report:Performance:Organization:description'),
        hidden: !isEnquiryReportGranted,
        title: i18n.t('module:Enquiries:Report:Performance:Organization:title'),
        to: generateRoute('ORGANIZATION_REPORTS.ENQUIRIES_PERFORMANCE'),
      },
      {
        description: i18n.t('module:Enquiries:Report:ViewingPerformance:Organization:description'),
        hidden: !isEnquiryReportGranted,
        title: i18n.t('module:Enquiries:Report:ViewingPerformance:Organization:title'),
        to: generateRoute('ORGANIZATION_REPORTS.ENQUIRIES_VIEWING_PERFORMANCE'),
      },
      {
        description: i18n.t('module:Reports:MonthlyFteChange:Organization:description'),
        hidden: !isEnquiryReportGranted,
        title: i18n.t('module:Reports:MonthlyFteChange:Organization:title'),
        to: generateRoute('ORGANIZATION_REPORTS.MONTHLY_FTE_CHANGE'),
      },
      {
        description: i18n.t('module:Finance:Reports:FailedRecurringPayment:description'),
        hidden: !GroupReportingEnabled || !financialReportsRolesGranted,
        title: i18n.t('module:Finance:Reports:FailedRecurringPayment:title'),
        to: generateRoute('ORGANIZATION_REPORTS.FAILED_RECURRING_PAYMENT'),
      },
      {
        description: i18n.t('module:Finance:Reports:ActiveRecurringPayment:description'),
        hidden: !GroupReportingEnabled || !financialReportsRolesGranted,
        title: i18n.t('module:Finance:Reports:ActiveRecurringPayment:title'),
        to: generateRoute('ORGANIZATION_REPORTS.ACTIVE_RECURRING_PAYMENT'),
      },
    ]

    if (search) {
      return _.filter(reportItems, ({ description, title }) => (
        title.toLowerCase().includes(search.toLowerCase())
      || description.toLowerCase().includes(search.toLowerCase())
      ))
    }

    return reportItems
  }, [GroupReportingEnabled, isAnnualRevenueGranted, isEnquiryReportGranted, isForecastingGranted, search])

  return (
    <ReportsWrapperView items={items} onSearchChange={setSearch} />
  )
}

ReportsWrapperContainer.authParams = {
  flags: [
    FEATURE_FLAGS.FINANCE_MVP,
    FEATURE_FLAGS.FINANCE_AUTOMATION,
  ],
  organizationContext: true,
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
  ],
}

const mapState = (state) => ({
  GroupReportingEnabled: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.GROUP_REPORTING_V2],
  }),
  financialReportsRolesGranted: auth.SELECTORS.getIsAuthorised(state, {
    roles: [
      ROLES.SUPER_ADMIN,
      ROLES.ORGANIZATION_DIRECTOR,
    ],
  }),
  isAnnualRevenueGranted: auth.SELECTORS.getComponentIsAuthorised(state, AnnualRevenue),
  isEnquiryReportGranted: auth.SELECTORS.getComponentIsAuthorised(state, EnquiryReport),
  isFinanceV3FFEnabled: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.FINANCE_V3],
  }),
  isForecastingGranted: auth.SELECTORS.getComponentIsAuthorised(state, Forecasting),
})

const enhance = compose(
  connect(mapState),
)

export default enhance(ReportsWrapperContainer)
