import styled from 'styled-components'
import browser from 'browser-detect'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { BROWSERS } from 'constants/browsers'

export const StyledMessagesWrapper = styled.div`
  height: 100%;
  flex: 1;
  
  ${browser().name === BROWSERS.SAFARI && `
    height: calc(100vh - 80px - 152px - 69px);
    flex: 1 1 auto;
  
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      height: calc(100vh - 80px - 152px - 100px);
    }
  `}

  & div {
    /* stylelint-disable */
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: ${NEUTRAL_COLOURS.WHITE};
    }
    
    &::-webkit-scrollbar,
    &::-webkit-scrollbar {
      width: 8px;
      background-color: ${NEUTRAL_COLOURS.WHITE};
    }
    
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #D8D8D8;
    }
    /* stylelint-enable */
  }
`
