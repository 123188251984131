import _ from 'lodash'

import { mergeData } from 'utils/data'

import { CLEAR_ROOMS, LIST_ROOMS, LIST_ROOMS_FAILED, LIST_ROOMS_SUCCESS, VALIDATE_ROOMS } from './constants'
import { GET_ROOM_SUCCESS } from '../single/constants'

import { validateRooms } from '../helpers'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
}

export default (state = initialState, { addToList, error, mergeResult, payload, type }) => {
  switch (type) {
    case LIST_ROOMS:
      return { ...state, error: initialState.error, isFetching: true }
    case LIST_ROOMS_SUCCESS: {
      const { done, response: { data, meta } } = payload

      return {
        ...state,
        data: _.uniqBy(mergeData(state.data, data, mergeResult), ({ id }) => id),
        isFetching: !done,
        meta,
      }
    }
    case LIST_ROOMS_FAILED:
      return { ...state, error, isFetching: false }
    case GET_ROOM_SUCCESS:
      if (addToList) {
        return {
          ...state,
          data: _.uniqBy([
            ...(state?.data || []),
            payload?.data,
          ], ({ id }) => id),
        }
      }

      return {
        ...state,
      }
    case VALIDATE_ROOMS:
      return { ...state, data: validateRooms(state.data, payload) }
    case CLEAR_ROOMS:
      return initialState
    default:
      return state
  }
}
