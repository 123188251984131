import i18n from 'translations'

export const NURSERY_TABLE_COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'ageGroup',
    title: i18n.t('module:Finance:Reports:RevenuePerAge:Table:Header:ageGroup'),
  },
  {
    field: 'revenue',
    title: i18n.t('module:Finance:Reports:RevenuePerAge:Table:Header:revenue'),
  },
]
