import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class MembershipsLeavesApiClient extends ServiceBase {
  list = (params = {}) => this.request({
    method: 'GET',
    path: `/v2/memberships/leaves?${getQueryStringV2(params)}`,
  })

  getStatistics = (params = {}) => this.request({
    method: 'GET',
    path: `/v2/statistics/membership_leaves?${getQueryStringV2(params)}`,
  })
}

export default MembershipsLeavesApiClient
