import * as fundingCalculatorActions from '../../child/fundingCalculator/actions'
import * as fundingSummaryCalculatorActions from '../../child/fundingSummaryCalculator/actions'
import * as productPriceCalculatorActions from '../../child/productPriceCalculator/actions'
import * as bankDetailsActions from './bankDetails/actions'
import * as birthdaysListActions from './birthdaysList/actions'
import * as changelogsActions from './changelogs/actions'
import * as dietaryActions from './dietary/actions'
import * as filesActions from './files/actions'
import * as financeDetailsActions from './financeDetails/actions'
import * as dropdownListActions from './dropdownList/actions'
import * as listActions from './list/actions'
import * as singleActions from './single/actions'

export default {
  ...fundingCalculatorActions,
  ...fundingSummaryCalculatorActions,
  ...productPriceCalculatorActions,
  ...bankDetailsActions,
  ...birthdaysListActions,
  ...changelogsActions,
  ...dietaryActions,
  ...filesActions,
  ...financeDetailsActions,
  ...dropdownListActions,
  ...listActions,
  ...singleActions,
}
