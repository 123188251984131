import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_NURSERY_SESSIONS_DROPDOWN_LIST_V3,
  NURSERY_SESSIONS_DROPDOWN_LIST_V3,
  NURSERY_SESSIONS_DROPDOWN_LIST_V3_FAILED,
  NURSERY_SESSIONS_DROPDOWN_LIST_V3_SUCCESS,
} from './constants'

export const dropdownList = (options: BasicActionOptionsProps) => ({ nurserySessionsV3ApiClient }) => (
  generateBasicActions.list({
    constants: {
      failed: NURSERY_SESSIONS_DROPDOWN_LIST_V3_FAILED,
      init: NURSERY_SESSIONS_DROPDOWN_LIST_V3,
      success: NURSERY_SESSIONS_DROPDOWN_LIST_V3_SUCCESS,
    },
    options,
    service: nurserySessionsV3ApiClient,
    serviceMethod: 'list',
  })
)

export const clearDropdownList = () => ({
  type: CLEAR_NURSERY_SESSIONS_DROPDOWN_LIST_V3,
})
