import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import {
  CLEAR_REQUESTED_EXTRA_SESSIONS,
  LIST_REQUESTED_EXTRA_SESSIONS,
  LIST_REQUESTED_EXTRA_SESSIONS_FAILED,
  LIST_REQUESTED_EXTRA_SESSIONS_SUCCESS,
} from './constants'

const initialState = {
  ...getBasicListInitialState,
  isFetching: true,
}

export default (...props) => {
  const [state = initialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_REQUESTED_EXTRA_SESSIONS,
    failed: LIST_REQUESTED_EXTRA_SESSIONS_FAILED,
    init: LIST_REQUESTED_EXTRA_SESSIONS,
    success: LIST_REQUESTED_EXTRA_SESSIONS_SUCCESS,
  }, initialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
