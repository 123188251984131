import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledItemContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const StyledExtraFieldItemContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

export const StyledExtraFieldContainer = styled.div`
  padding: 20px 0 0 20px;

  & ${StyledExtraFieldItemContainer} ~ ${StyledExtraFieldItemContainer} {
    margin-top: 15px;
  }
`
export const StyledExtraFieldItemContentContainer = styled.div`
  flex: 1;
  margin-right: 20px
`
