import React from 'react'

import { generateRoute } from 'utils/routing'

import { EmptyState, Page, Pagination, Section, Select, Spinner, Table, Toolbar } from 'components'

import i18n from 'translations'

const RoomsView = ({
  columns,
  isLoading,
  onPageChange,
  onRoomFilterChange,
  onRoomTypeChange,
  onSortChange,
  page,
  pageCount,
  perPage,
  roomFilter,
  roomFilterOptions,
  roomType,
  roomTypeOptions,
  roomsTable,
  sortField,
  sortOrder,
  totalResults,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('module:Rooms:Rooms:rooms')}
      titleSingular={i18n.t('module:Rooms:Rooms:room')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!isLoading && !roomsTable?.length) {
      return (
        <EmptyState
          icon="rooms"
          text1={i18n.t('module:Rooms:RoomsList:emptyState')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={columns}
          data={roomsTable}
          sortField={sortField}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const actions = <Section.Actions primary={[{ to: generateRoute('ROOMS.ADD') }]} />

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title={i18n.t('global:Rooms')}
    >
      <Toolbar noMargin>
        <Toolbar.Group>
          <Toolbar.Item>
            <Select
              clearable={false}
              options={roomFilterOptions}
              searchable={false}
              value={roomFilter}
              onChange={onRoomFilterChange}
            />
          </Toolbar.Item>
          <Toolbar.Item>
            <Select
              clearable={false}
              options={roomTypeOptions}
              placeholder={i18n.t('global:Type')}
              searchable={false}
              value={roomType}
              onChange={onRoomTypeChange}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      {renderContent()}
    </Page.Section>
  )
}

export default RoomsView
