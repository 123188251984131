import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as membershipsShiftsSelectors from './selectors'

const withMembershipsShiftsService = (WrappedComponent) => {
  const mapState = ({ membershipsShifts }) => ({ membershipsShifts })

  const mapDispatch = injectActions('membershipsShiftsActions', actions)

  const Component = ({ membershipsShifts, membershipsShiftsActions, ...others }) => (
    <WrappedComponent
      membershipsShiftsActions={membershipsShiftsActions}
      membershipsShiftsListState={membershipsShifts.list}
      membershipsShiftsSelectors={membershipsShiftsSelectors}
      membershipsShiftsSingleState={membershipsShifts.single}
      membershipsShiftsStatisticsState={membershipsShifts.statistics}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withMembershipsShiftsService
