import React from 'react'
import { WrappedFieldProps } from 'redux-form'

import { CheckboxProps } from 'components/Checkbox/Checkbox'

import { Checkbox } from 'components'

export type FormCheckboxProps = CheckboxProps

const FormCheckbox: React.FC<FormCheckboxProps & WrappedFieldProps> = ({
  input: { onChange, value },
  meta: { error, submitFailed },
  ...rest
}) => (
  <Checkbox
    {...rest}
    error={submitFailed && error}
    value={value}
    onChange={onChange}
  />
)

export default FormCheckbox
