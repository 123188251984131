import { combineReducers } from 'redux'

import common, { ShellCommonState } from './common/reducer'

export default combineReducers({
  common,
})

export interface ShellState {
  common: ShellCommonState
}
