import React from 'react'

import { NurseryDiscount } from 'services/product/nurseryDiscountsV3/constants'

import { Callout, Page, Section, Spinner } from 'components'

import i18n from 'translations'

import AddDiscountForm, { AddDiscountFormValues } from './components/AddDiscountForm'

interface ManagementDiscountAddV3ViewProps {
  discount: NurseryDiscount
  errorMessages: string[]
  formValues?: AddDiscountFormValues
  initialValues?: AddDiscountFormValues
  isEdit: boolean
  isFormLoading: boolean
  isLoading: boolean
  onArchiveClick: (archived: boolean) => void
  onSubmit: (values: AddDiscountFormValues) => void
}

const ManagementDiscountAddV3View: React.FC<ManagementDiscountAddV3ViewProps> = ({
  discount,
  errorMessages,
  formValues,
  initialValues,
  isEdit,
  isFormLoading,
  isLoading,
  onArchiveClick,
  onSubmit,
}) => {
  const renderContent = () => {
    if (isLoading || (isEdit && !initialValues)) {
      return (
        <Spinner />
      )
    }

    return (
      <AddDiscountForm
        formValues={formValues}
        initialValues={initialValues}
        isEdit={isEdit}
        isLoading={isFormLoading}
        onSubmit={onSubmit}
      />
    )
  }

  const title = isEdit
    ? i18n.t('module:Management:Finance:Discounts:edit:title')
    : i18n.t('module:Management:Finance:Discounts:add:title')
  const typeIsArchived = discount?.archived ? 'unarchive' : 'archive'

  const actions = isEdit && (
    <Section.Actions
      options={[{
        onClick: () => onArchiveClick(!discount?.archived),
        type: typeIsArchived,
      }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title={title}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default ManagementDiscountAddV3View
