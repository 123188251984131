import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { Form, Hyperlink, Typography } from 'components'

import i18n from 'translations'

export const STATUS_CHANGE_COMMENT_FORM = 'STATUS_CHANGE_COMMENT_FORM'

const StatusChangeCommentForm = ({
  handleSubmit,
  isLostEnquiryStatus,
  isSubmitting,
  onCancelClick,
  onNurserySettingsClick,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    {isLostEnquiryStatus && (
      <Form.Row
        label={i18n.t('module:Modals:Enquiry:StatusChange:Form:LostReason:label')}
        required
        verticalLabel
      >
        <Form.Row.FlexItem>
          <Field
            component={Form.InfiniteDropdowns.EnquirySettings}
            name="lostReason"
            placeholder={i18n.t('module:Modals:Enquiry:StatusChange:Form:LostReason:placeholder')}
            type="lostReason"
            validate={isRequired}
          />
        </Form.Row.FlexItem>
        <Typography margin="10px 0 0" inline>
          {i18n.t(i18n.t('module:Modals:Enquiry:StatusChange:lostReasonSettingLinkMessage1'))}
          {' '}
          <Typography variant="span" primary>
            <Hyperlink onClick={onNurserySettingsClick}>
              {i18n.t('module:Modals:Enquiry:StatusChange:lostReasonSettingLinkMessage2')}
            </Hyperlink>
          </Typography>
        </Typography>
      </Form.Row>
    )}
    <Form.Row
      label={i18n.t('module:Modals:Enquiry:StatusChange:Form:AdditionalInformation:label')}
      verticalLabel
    >
      <Form.Row.FlexItem>
        <Field
          component={Form.TextAreaField}
          name="statusNote"
          placeholder={i18n.t('module:Modals:Enquiry:StatusChange:Form:AdditionalInformation:placeholder')}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.FooterActions
      isSubmitting={isSubmitting}
      submitLabel={i18n.t('module:Modals:Enquiry:StatusChange:saveLabel')}
      onCancelClick={onCancelClick}
    />
  </Form>
)

export default reduxForm({ form: STATUS_CHANGE_COMMENT_FORM })(StatusChangeCommentForm)
