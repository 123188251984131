export const NOTIFICATION_TYPES = {
  CHILD_OBSERVATION: 'childObsrv',
  CREATE_CHILD_LOG: 'createChildLog',
  DELETE_CHILD_ABSENCE: 'deleteChildAbsence',
  HOME_OBSERVATION: 'homeObsrv',
  NEW_CHILD_ABSENCE: 'newChildAbsence',
  NEW_COMMENT: 'newComment',
  NEW_ENQUIRY: 'newEnquiry',
  NEW_FORMATIVE_REPORT_TO_APPROVAL: 'newFormativeReportToApprove',
  NEW_HOME_OBSERVATION_TO_READ: 'newHomeObservationToRead',
  NEW_INJURY_TO_APPROVE: 'newInjuryToApprove',
  NEW_OBSERVATION_FEEDBACK: 'newObservationFeedback',
  NEW_OBSERVATION_TO_APPROVE: 'newObservationToApprove',
  NEW_REQUEST_EXTRA_SESSION: 'newRequestExtraSession',
  UPDATE_CHILD_ABSENCE: 'updateChildAbsence',
}

export const LIST_TAB_NAMES = {
  ALL: 'All',
  REMINDERS: 'Reminders',
  TASKS: 'Tasks',
  UPDATES: 'Updates',
}
