import React from 'react'

import { STAFF_REGISTER_STATUS } from 'services/legacy/membershipRegisters/constants'

import { Button, Callout, FooterActions, Page, Space, Spinner, Toolbar, Typography } from 'components'

import i18n from 'translations'

import { StaffHeader } from '../components'

import { StyledText } from './DashboardStyled'

const DashboardView = ({
  currentDate,
  errorMessages,
  isFetching,
  onCancelButtonClick,
  onStatusChange,
  staff,
  staffProfile,
}) => {
  const { status } = staff || {}

  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    return (
      <React.Fragment>
        <StyledText>
          <React.Fragment>
            <Typography variant="h3" inline medium>
              {i18n.t('module:StaffRegister:Dashboard:youAreCurrently')}
              {' '}
            </Typography>
            <Typography variant="h3" bold inline>
              {STAFF_REGISTER_STATUS.CLOCKED_OUT === status && (
                i18n.t('module:StaffRegister:Dashboard:clockedOut')
              )}
              {STAFF_REGISTER_STATUS.CLOCKED_IN === status && (
                i18n.t('module:StaffRegister:Dashboard:clockedIn')
              )}
              {STAFF_REGISTER_STATUS.ON_BREAK === status && (
                i18n.t('module:StaffRegister:Dashboard:onBreak')
              )}
            </Typography>
          </React.Fragment>
        </StyledText>
        <Space space="50px" />
        <Toolbar>
          <Toolbar.Group>
            {STAFF_REGISTER_STATUS.CLOCKED_OUT === status && (
              <Toolbar.Item>
                <Button.Big
                  icon="logout"
                  label={i18n.t('module:StaffRegister:Dashboard:ButtonLabels:clockIn')}
                  value={STAFF_REGISTER_STATUS.CLOCKED_IN}
                  onChange={onStatusChange}
                />
              </Toolbar.Item>
            )}
            {STAFF_REGISTER_STATUS.CLOCKED_IN === status && (
              <React.Fragment>
                <Toolbar.Item>
                  <Button.Big
                    icon="change-location"
                    label={i18n.t('module:StaffRegister:Dashboard:ButtonLabels:changeLocation')}
                    value={STAFF_REGISTER_STATUS.CLOCKED_IN}
                    onChange={onStatusChange}
                  />
                </Toolbar.Item>
                <Toolbar.Item>
                  <Button.Big
                    icon="break"
                    label={i18n.t('module:StaffRegister:Dashboard:ButtonLabels:startBreak')}
                    value={STAFF_REGISTER_STATUS.ON_BREAK}
                    onChange={onStatusChange}
                  />
                </Toolbar.Item>
                <Toolbar.Item>
                  <Button.Big
                    icon="exit"
                    label={i18n.t('module:StaffRegister:Dashboard:ButtonLabels:clockOut')}
                    value={STAFF_REGISTER_STATUS.CLOCKED_OUT}
                    onChange={onStatusChange}
                  />
                </Toolbar.Item>
              </React.Fragment>
            )}
            {STAFF_REGISTER_STATUS.ON_BREAK === status && (
              <Toolbar.Item>
                <Button.Big
                  icon="break"
                  label={i18n.t('module:StaffRegister:Dashboard:ButtonLabels:endBreak')}
                  value={STAFF_REGISTER_STATUS.CLOCKED_IN}
                  onChange={onStatusChange}
                />
              </Toolbar.Item>
            )}
          </Toolbar.Group>
        </Toolbar>
        <FooterActions spaceBetween>
          <FooterActions.Item>
            <Button
              hierarchy="tertiary"
              label={i18n.t('global:Cancel')}
              negativeMargins
              onClick={onCancelButtonClick}
            />
          </FooterActions.Item>
        </FooterActions>
      </React.Fragment>
    )
  }

  return (
    <Page isLoading={isFetching}>
      <Page.Content>
        <Callout content={errorMessages} error />
        <StaffHeader
          currentDate={currentDate}
          staffProfile={staffProfile}
        />
        {renderContent()}
      </Page.Content>
    </Page>
  )
}

export default DashboardView
