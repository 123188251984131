import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { Form } from 'components'

import i18n from 'translations'

export const CHANGE_LOCATION_FORM = 'ChangeLocationForm'

const ChangeLocationForm = ({
  handleSubmit,
  isLocationRequired,
  isSubmitting,
  onBackButtonClick,
  onSkipButtonClick,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row
      label={i18n.t('module:StaffRegister:ChangeLocation:chooseYourLocation')}
      required={isLocationRequired}
      verticalLabel
    >
      <Form.Row.FlexItem flex="0 0 320px" mobileFull>
        <Field
          component={Form.InfiniteDropdowns.Rooms}
          name="nurseryClass"
          placeholder={i18n.t('module:StaffRegister:ChangeLocation:chooseYourLocation')}
          validate={isRequired}
          allRoomTypes
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.FooterActions
      cancelLabel={i18n.t('global:Back')}
      isSubmitting={isSubmitting}
      secondary={{
        auth: !isLocationRequired,
        disabled: isSubmitting,
        label: i18n.t('global:Skip'),
        onClick: onSkipButtonClick,
      }}
      submitLabel={i18n.t('global:Confirm')}
      onCancelClick={onBackButtonClick}
    />
  </Form>
)

export default reduxForm({ form: CHANGE_LOCATION_FORM })(ChangeLocationForm)
