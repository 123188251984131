import { CLEAR_LEAVE_NOTES, LIST_LEAVE_NOTES, LIST_LEAVE_NOTES_FAILED, LIST_LEAVE_NOTES_SUCCESS } from './constants'

export const list = (options = {}) => ({ leaveNotesApiClient }) => (dispatch) => {
  const { mergeResult, onFailed, onSuccess, params = {} } = options

  dispatch({ type: LIST_LEAVE_NOTES })

  leaveNotesApiClient.list(params).then(
    (response) => {
      dispatch({
        mergeResult,
        payload: response,
        type: LIST_LEAVE_NOTES_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: LIST_LEAVE_NOTES_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const clearList = () => ({
  type: CLEAR_LEAVE_NOTES,
})
