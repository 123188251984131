import _ from 'lodash'

import { TableColumn } from 'components/Table/Table'

import i18n from 'translations'

export const TABLE_COLUMNS: TableColumn[] = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'date',
    title: i18n.t('global:Date'),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'child',
    title: _.upperFirst(i18n.t('global:child')),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'type',
    title: i18n.t('global:Type'),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'description',
    title: i18n.t('global:Description'),
  },
  {
    field: 'quantity',
    title: i18n.t('global:Quantity'),
  },
  {
    field: 'price',
    title: i18n.t('global:Price'),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'invoice',
    title: i18n.t('module:Finance:Deposits:List:Table:Header:invoiceStatus'),
  },
  { field: 'edit' },
]
