import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class ChildrenSessionsApiClient extends ServiceBase {
  list = (params = {}) => this.request({
    path: `/v2/children_sessions?${getQueryStringV2(params)}`,
  })

  update = (id, payload, params) => this.request({
    body: payload,
    method: 'PUT',
    path: `/v2/children_sessions/${id}?${getQueryStringV2(params)}`,
  })
}

export default ChildrenSessionsApiClient
