import { flatten, nest } from 'utils/flatnest'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { INJURIES_STATUS } from 'services/legacy/injuries/constants'

import { withInjuriesService } from 'services/legacy/injuries'
import { withModalService } from 'services/utils/modal'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouter } from 'services/router'

import InjuryApprovalsView from './InjuryApprovalsView'

const DATE_FORMAT = 'YYYY-MM-DD HH:mm'
const GROUPS = {
  read: [
    'child',
    'injury.injured',
    'injured',
    'injured.child',
    'injury.injurySignatureStatistics',
  ],
}

class InjuryApprovalsContainer extends Component {
  constructor(props) {
    super(props)

    const { query } = props.location
    const { date, supervisor } = nest(query)

    const after = date && date.after ? moment(date.after).format(DATE_FORMAT) : undefined
    const before = date && date.before ? moment(date.before).format(DATE_FORMAT) : undefined

    this.state = {
      filters: {
        date: {
          after,
          before,
        },
        status: INJURIES_STATUS.PENDING_APPROVAL.value,
        supervisor,
      },
    }
  }

  componentDidMount() {
    this.fetch()
  }

  fetch = () => {
    const { injuriesActions, injuriesSelectors, paginationUtils, setLocationQuery } = this.props
    const { filters } = this.state
    const { page, perPage } = paginationUtils

    setLocationQuery(flatten(filters))

    const criteria = injuriesSelectors.getCriteria(filters)
    const parameters = {
      criteria,
      groups: GROUPS,
      limit: perPage,
      page,
    }

    injuriesActions.list(parameters)
  }

  handleDateChange = (dateRange) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    const [after, before] = dateRange
    const date = {
      after: after ? moment(after).format(DATE_FORMAT) : undefined,
      before: before ? moment(before).format(DATE_FORMAT) : undefined,
    }

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, date },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handleSupervisorChange = (supervisor) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, supervisor: supervisor && supervisor.value },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  render() {
    const { injuriesListState: { isFetching, meta }, paginationUtils, records } = this.props
    const { filters } = this.state
    const { getPageCount, page, perPage } = paginationUtils
    const { date: { after, before }, supervisor } = filters

    const dateRange = [after, before]
    const pageCount = getPageCount(meta.total_results)

    return (
      <InjuryApprovalsView
        dateRange={dateRange}
        isLoading={isFetching}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        records={records}
        supervisor={supervisor}
        totalResults={meta.total_results}
        onDateChange={this.handleDateChange}
        onPageChange={this.handlePageChange}
        onRoomChange={this.handleRoomChange}
        onStatusChange={this.handleStatusChange}
        onSupervisorChange={this.handleSupervisorChange}
        onTypeChange={this.handleTypeChange}
      />
    )
  }
}

const mapState = (state, { injuriesSelectors }) => ({
  records: injuriesSelectors.getDataRowOfInjuriesListSelector(state),
})

const enhance = compose(
  withRouter,
  withInjuriesService,
  withPaginationUtils,
  withModalService,
  connect(mapState),
)

export default enhance(InjuryApprovalsContainer)
