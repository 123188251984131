import { createSelector } from 'reselect'

const getCarersState = (state) => state.staffLogs

export const getCarersListState = createSelector(
  [getCarersState],
  (state) => state.list.data,
)

export const getListCriteria = (params) => {
  const { registerId } = params
  const criteria = []

  if (registerId) {
    criteria.push({
      field: 'childRegister',
      value: registerId,
    })
  }

  return criteria
}
