import { generateBasicActions } from 'utils/service'

import {
  CLEAR_ENQUIRY_STATUS_TYPES_DROPDOWN_LIST,
  ENQUIRY_STATUS_TYPES_DROPDOWN_LIST,
  ENQUIRY_STATUS_TYPES_DROPDOWN_LIST_FAILED,
  ENQUIRY_STATUS_TYPES_DROPDOWN_LIST_SUCCESS,
  GET_ENQUIRY_STATUS_TYPES_DROPDOWN_ITEM,
  GET_ENQUIRY_STATUS_TYPES_DROPDOWN_ITEM_FAILED,
  GET_ENQUIRY_STATUS_TYPES_DROPDOWN_ITEM_SUCCESS,
} from './constants'

export const dropdownList = (options = {}) => ({
  enquiryStatusTypesApiClient,
}) => generateBasicActions.list({
  constants: {
    failed: ENQUIRY_STATUS_TYPES_DROPDOWN_LIST_FAILED,
    init: ENQUIRY_STATUS_TYPES_DROPDOWN_LIST,
    success: ENQUIRY_STATUS_TYPES_DROPDOWN_LIST_SUCCESS,
  },
  options,
  service: enquiryStatusTypesApiClient,
  serviceMethod: 'list',
})

export const getDropdownItem = (options = {}) => ({ enquiryStatusTypesApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_ENQUIRY_STATUS_TYPES_DROPDOWN_ITEM_FAILED,
    init: GET_ENQUIRY_STATUS_TYPES_DROPDOWN_ITEM,
    success: GET_ENQUIRY_STATUS_TYPES_DROPDOWN_ITEM_SUCCESS,
  },
  options,
  service: enquiryStatusTypesApiClient,
  serviceMethod: 'get',
})

export const clearDropdownList = () => ({
  type: CLEAR_ENQUIRY_STATUS_TYPES_DROPDOWN_LIST,
})
