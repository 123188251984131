import styled from 'styled-components'

export const StyledListContent = styled.div`
  @media(max-width: 740px) {
    margin-bottom: -25px;
    
    & > div > div {
      align-items: flex-start;
      
      & > div > div {
        margin-bottom: 15px !important;
      }
    }
  }
`
