import moment from 'moment'

import React from 'react'
import { compose } from 'recompose'

import { STAFF_ENTRY_TYPES } from 'services/legacy/membershipRegisters/constants'

import { EVENTS, logEvent } from 'analytics'

import { withAuthService } from 'services/legacy/auth'
import { withAuthenticationService } from 'services/authentication'
import { withMembershipsService } from 'services/legacy/memberships'
import { withMembershipRegistersService } from 'services/legacy/membershipRegisters'
import { withSnackbarService } from 'services/utils/snackbar'
import { findDomain } from 'services/subdomain/single/selectors'
import { withSecurityService } from 'services/security'
import { withRouter } from 'services/router'

import i18n from 'translations'

const withStaffRegisterWrapper = (WrappedComponent) => {
  const Component = (props) => {
    const clearStoreAndRedirectToStaffList = async (onClearStore) => {
      const {
        authActions,
        authenticationActions,
        membershipRegistersActions,
        membershipsActions,
        securityActions,
      } = props

      authActions.clearLoginStaffRegister()
      authActions.clearPinLogin()
      membershipsActions.clear()
      membershipRegistersActions.clear()
      authenticationActions.logout()

      securityActions.setAssetsAccessToken({ deviceAuthorization: true, domain: findDomain() })

      if (onClearStore) {
        onClearStore()
      }
    }

    const getRegisterTypeMessagePart = ({
      hasEntries,
      response,
    }) => {
      const { data } = response
      const { signInAt, signOutAt, type } = data

      if (type === STAFF_ENTRY_TYPES.BREAK) {
        // Break in
        if (!signOutAt) {
          logEvent(EVENTS.STAFF_REGISTER_BREAK_STARTED)

          return {
            message: i18n.t('module:StaffRegister:StaffRegisterList:SnackbarText:breakInAt'),
            time: signInAt,
          }
        }

        logEvent(EVENTS.STAFF_REGISTER_BREAK_ENDED)

        // Break out
        return {
          message: i18n.t('module:StaffRegister:StaffRegisterList:SnackbarText:breakOutAt'),
          time: signOutAt,
        }
      }

      // Clocked out
      if (type === STAFF_ENTRY_TYPES.SIGN_IN) {
        if (signOutAt) {
          logEvent(EVENTS.STAFF_REGISTER_CLOCKED_OUT)

          return {
            message: i18n.t('module:StaffRegister:StaffRegisterList:SnackbarText:clockedOutAt'),
            time: signOutAt,
          }
        }
      }

      // change location and clocked in
      if (hasEntries) {
        logEvent(EVENTS.STAFF_REGISTER_LOCATION_CHANGED)

        return {
          message: i18n.t('module:StaffRegister:StaffRegisterList:SnackbarText:changeLocationAt'),
          time: signInAt,
        }
      }

      logEvent(EVENTS.STAFF_REGISTER_CLOCKED_IN)

      return {
        message: i18n.t('module:StaffRegister:StaffRegisterList:SnackbarText:clockedInAt'),
        time: signInAt,
      }
    }

    const sendNotification = ({
      hasEntries,
      name,
      response,
    }) => {
      const { snackbarActions } = props

      const registerTypeMessagePart = getRegisterTypeMessagePart({
        hasEntries,
        response,
      })

      if (!registerTypeMessagePart) {
        return
      }

      const { message, time } = registerTypeMessagePart

      // NOTE: only in this place we use the utc to display correct entry time
      snackbarActions.show({
        message: `${name} ${message} ${moment(time).utc().format('HH:mm')}`,
      })
    }

    return (
      <WrappedComponent
        {...props}
        clearStoreAndRedirectToStaffList={clearStoreAndRedirectToStaffList}
        sendNotification={sendNotification}
      />
    )
  }

  const enhance = compose(
    withAuthenticationService,
    withRouter,
    withAuthService,
    withMembershipsService,
    withMembershipRegistersService,
    withSnackbarService,
    withSecurityService,
  )

  return enhance(Component)
}

export default withStaffRegisterWrapper
