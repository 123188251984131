import styled from 'styled-components'

import { StyledScrollbar } from 'styled/abstract'

export const StyledSectionWrapper = styled(StyledScrollbar)`
  max-height: 240px;
  overflow-y: scroll;
`

export const StyledAvatarContainer = styled.div`
  padding: 10px 0;  
`
