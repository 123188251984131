import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledTabsWrapper = styled.div`
  background: ${NEUTRAL_COLOURS.GRAY_QUINARY};
  
  .Mui-selected {
    background: ${NEUTRAL_COLOURS.GRAY_TERTIARY} !important;
  }
`

export const StyledStatementsWrapper = styled.div`
  padding: 20px;
  margin-top: 20px;
  background: ${NEUTRAL_COLOURS.GRAY_QUINARY};
  
  @media(max-width: 670px) {
    padding: 10px;
  }
`

export const StyledTitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 10px;
  align-items: center;
  line-height: 23px;
`

export const StyledRectangle = styled.div`
  width: 20px;
  height: 20px;
  
  ${({ color }) => color && `
    background-color: ${color};
  `}
`

export const StyledStatementRow = styled.div`
  display: grid;
  grid-template-columns: 100px auto;
  grid-column-gap: 5px;
  margin-bottom: 12px;
  
  ${({ oneColumn }) => oneColumn && `
    grid-template-columns: auto;
  `}
  
  @media(max-width: 670px) {
    flex-direction: column;
    grid-template-columns: auto;
    margin-bottom: 15px;
  }
`
