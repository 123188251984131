import React from 'react'

import { Callout, ModalBox } from 'components'

import i18n from 'translations'

import EndTimeModalForm from './components/EndTimeModalForm'

const EndTimeModalView = ({
  date,
  errorMessages,
  initialValues,
  isSubmitting,
  onCloseClick,
  onSubmit,
  signInAt,
}) => (
  <ModalBox
    maxWidth="400"
    title={i18n.t('module:Modals:Staff:Worklog:EndTime:title')}
    autoHeight
    onCloseClick={onCloseClick}
  >
    <Callout content={errorMessages} error />
    <EndTimeModalForm
      date={date}
      initialValues={initialValues}
      isSubmitting={isSubmitting}
      signInAt={signInAt}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    />
  </ModalBox>
)

export default EndTimeModalView
