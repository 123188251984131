import React from 'react'

import { ButtonProps } from 'components/Button/Button'

import { Button } from 'components'

import { StyledButtonContainer } from './FormRemoveRowButtonStyled'

interface FormRemoveRowButtonProps {
  onClick?: () => void
  trash?: boolean
}

type FormRemoveRowButtonFullProps = FormRemoveRowButtonProps
  & ButtonProps

const FormRemoveRowButton: React.FC<FormRemoveRowButtonFullProps> = ({ onClick, trash, ...rest }) => (
  <StyledButtonContainer>
    <Button
      {...rest}
      color={trash ? 'gray' : 'error'}
      hierarchy="tertiary"
      icon={trash ? 'trash' : 'close'}
      size="small"
      negativeVerticalMargins
      onClick={onClick}
    />
  </StyledButtonContainer>
)

export default FormRemoveRowButton
