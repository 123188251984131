import _ from 'lodash'

import { createSelector } from 'reselect'

import { PROGRESS_TRACKER_TYPES } from '../constants'

const getCohortTracking = (state) => state.cohortTracking

const getCohortTrackingProgressTrackerSingleSelector = createSelector(
  [getCohortTracking],
  (state) => state.progressTrackerSingle,
)

export const getProgressTrackerReport = createSelector(
  [getCohortTrackingProgressTrackerSingleSelector],
  (state) => state.data || [],
)

export const getFilteredRecords = (records, search, reportType) => {
  let finalRecords = _.filter(records, ({ child }) => new RegExp(search, 'i').test(child.name))

  if (PROGRESS_TRACKER_TYPES.TERMLY_PROGRESS === reportType) {
    finalRecords = _.filter(finalRecords, ({ reports }) => (
      _.some(reports, (report) => (
        _.some(report.ageBands, ({ progressLevel }) => progressLevel?.id)
      ))
    ))
  }

  return _.sortBy(finalRecords, ({ child }) => child.name)
}
