import _ from 'lodash'

import React, { useEffect } from 'react'
import { compose } from 'recompose'

import { SelectProps } from 'components/Select/Select'

import { withChildProductsService, withChildProductsServiceProps } from 'services/booking/childProducts'

import { Select } from 'components'

import i18n from 'translations'

import withInfiniteDropdownHelpers, {
  withInfiniteDropdownHelpersProps,
} from '../../legacy/InfiniteDropdowns/withInfiniteDropdown'
import { DropdownProps } from '../InfiniteDropdownsModel'

export type ChildProductsDropdownProps = withInfiniteDropdownHelpersProps
  & withChildProductsServiceProps
  & SelectProps
  & DropdownProps

const ChildProductsDropdown: React.FC<ChildProductsDropdownProps> = ({
  childProductsActions,
  criteria,
  extraFields,
  groups,
  infiniteDropdownHelpers,
  onChange,
  placeholder,
  value,
  ...rest
}) => {
  useEffect(() => {
    infiniteDropdownHelpers.handleComponentDidMount({
      action: () => childProductsActions.get({
        onFailed: () => onChange(null),
        onSuccess: infiniteDropdownHelpers.handleComponentDidMountOnSuccess,
        onlyData: true,
        params: [(value as any)?.value || value],
      }),
      value,
    })

    return () => {
      childProductsActions.clearList()
    }
  }, [])

  const listAction = ({ newPage, newSearchPhrase, result }) => {
    const criteriaFinal = [
      ...(criteria || []),
      {
        field: 'archived',
        value: 0,
      },
    ]

    if (newSearchPhrase) {
      criteriaFinal.push({
        field: 'name',
        value: newSearchPhrase,
      })
    }

    return childProductsActions.dropdownList({
      onSuccess: ({ data, meta }) => {
        const newData = _.map(data, ({ product, ...dropdownDataRest }) => {
          const { name } = product

          return {
            ...dropdownDataRest,
            name,
            product,
          }
        })

        const newResponse = { data: newData, meta }

        return infiniteDropdownHelpers.handleLoadMoreElementsOnSuccess({
          extraFields,
          newPage,
          newSearchPhrase,
          response: newResponse,
          result,
        })
      },
      onlyData: true,
      params: {
        criteria: criteriaFinal,
        groups,
        page: newPage,
      },
    })
  }

  return (
    <Select
      {...infiniteDropdownHelpers.getSelectRestParams({ onChange, value, ...rest })}
      loadOptions={infiniteDropdownHelpers.handleLoadMoreElementsV2({ listAction })}
      placeholder={placeholder || i18n.t('components:InfiniteDropdowns:ChildProducts:placeholder')}
    />
  )
}

const enhance = compose(
  withChildProductsService,
  withInfiniteDropdownHelpers,
)

export default enhance(ChildProductsDropdown)
