import { createSelector } from 'reselect'

const getCohortTracking = (state) => state.cohortTracking

export const getCohortTrackingListSelector = createSelector([getCohortTracking], (state) => state.list)

export const getCohortTrackingListDataSelector = createSelector(
  [getCohortTrackingListSelector],
  (state) => state.data || [],
)
