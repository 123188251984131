import React from 'react'
import { connect } from 'react-redux'

import actions from './actions'
import * as selectors from './selectors'
import constants from './constants'

const withChildSessionsService = (WrappedComponent) => {
  const mapState = ({ childSessions }) => ({ childSessions })

  const mapDispatch = {
    clear: actions.clearChildSession,
    clearSingle: actions.clearChildSingleSession,
    create: actions.createChildSession,
    get: actions.getChildSession,
    groupedByList: actions.groupedByListChildSessions,
    list: actions.listChildSessions,
    listSetup: actions.listChildSessionsSetup,
    listSetupV3: actions.listChildSessionsSetupV3,
    update: actions.updateChildSession,
  }

  const Component = (props) => {
    const {
      childSessions,
      clear,
      clearSingle,
      create,
      get,
      groupedByList,
      list,
      listSetup,
      listSetupV3,
      update,
      ...other
    } = props

    const childSessionsListState = {
      ...childSessions.list,
      isCurrentEmpty: !(childSessions.list.data
        && childSessions.list.data.current
        && 0 < childSessions.list.data.current.length
      ),
      isFutureEmpty: !(childSessions.list.data
        && childSessions.list.data.future
        && 0 < childSessions.list.data.future.length
      ),
      isPastEmpty: !(childSessions.list.data
        && childSessions.list.data.past
        && 0 < childSessions.list.data.past.length
      ),
    }
    const childSessionsSingleState = childSessions.single
    const childSessionsActions = {
      clear,
      clearSingle,
      create,
      get,
      groupedByList,
      list,
      listSetup,
      listSetupV3,
      update,
    }
    const childSessionsSelectors = selectors
    const childSessionsConstants = constants

    return (
      <WrappedComponent
        childSessionsActions={childSessionsActions}
        childSessionsConstants={childSessionsConstants}
        childSessionsListState={childSessionsListState}
        childSessionsSelectors={childSessionsSelectors}
        childSessionsSingleState={childSessionsSingleState}
        {...other}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withChildSessionsService
