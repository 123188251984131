import styled from 'styled-components'

export const StyledStatusContainer = styled.div`
  display: flex;
  align-items: center;
`

export const StyledTag = styled.span`
  display: inline-block;
  padding: 6px;
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin-right: 10px;
  height: 10px;
`
