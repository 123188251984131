import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class SubdomainApiClient extends ServiceBase {
  get = (subdomain, params) => this.request({
    deviceTokenNotRequired: true,
    path: `/v2/subdomain/${subdomain}?${getQueryStringV2(params)}`,
    public: true,
    subdomainNotRequired: true,
  })
}

export default SubdomainApiClient
