import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class ChildExtraItemsApiClient extends ServiceBase {
  list = (params) => this.request({
    path: `/v2/child_extra_items?${getQueryStringV2(params)}`,
  })

  listProjections = (params) => this.request({
    path: `/v2/child_extra_item_projections?${getQueryStringV2(params)}`,
  })

  get = (id, params) => this.request({
    path: `/v2/child_extra_items/${id}?${getQueryStringV2(params)}`,
  })

  create = (payload, params) => this.request({
    body: payload,
    method: 'POST',
    path: `/v2/child_extra_items?${getQueryStringV2(params)}`,
  })

  update = (id, payload, params) => this.request({
    body: payload,
    method: 'PUT',
    path: `/v2/child_extra_items/${id}?${getQueryStringV2(params)}`,
  })
}

export default ChildExtraItemsApiClient
