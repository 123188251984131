import React, { PropsWithChildren } from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

import { StyledContainer, StyledProgressFillContainer } from './ProgressBarStyled'

interface ProgressBarProps {
  backgroundColor?: string
  fillColor?: string
  height?: number
  isExceedAvailable?: boolean
  width?: number
}

const ProgressBar: React.FC<PropsWithChildren<ProgressBarProps>> = ({
  backgroundColor = NEUTRAL_COLOURS.WHITE,
  fillColor = getBrandingColor('secondary-color'),
  height = 16,
  isExceedAvailable,
  width = 100,
}) => (
  <StyledContainer
    $backgroundColor={backgroundColor}
    $height={height}
  >
    <StyledProgressFillContainer
      $fillColor={fillColor}
      $height={height}
      $isExceedAvailable={isExceedAvailable}
      $width={width}
    />
  </StyledContainer>
)

export default ProgressBar
