import { properties } from 'app-config'
import DeviceIdentityService from 'core/services/DeviceIdentityService'
import CookieIdentityService from 'core/services/CookieIdentityService'

import { ERROR_CODES, SET_ASSETS_ACCESS_TOKEN_FAILED, SET_ASSETS_ACCESS_TOKEN_SUCCESS } from './constants'

interface SetAssetTokenOptionsProps {
  deviceAuthorization?: boolean
  domain: any
}

// legacy login and device service used
export const setAssetsAccessToken = (options: SetAssetTokenOptionsProps) => () => (dispatch) => {
  const { deviceAuthorization = false, domain } = options || {}

  if (!deviceAuthorization) {
    dispatch({
      payload: {
        ...options,
        code: ERROR_CODES.NOT_SUPPORTED_ASSETS_TOKEN,
      },
      type: SET_ASSETS_ACCESS_TOKEN_FAILED,
    })

    return
  }

  const identity = DeviceIdentityService.getIdentity()

  if (!identity) {
    dispatch({
      payload: {
        ...options,
        code: ERROR_CODES.MISSING_DEVICE_IDENTITY,
      },
      type: SET_ASSETS_ACCESS_TOKEN_FAILED,
    })

    return
  }

  CookieIdentityService.setIdentity(identity, {
    domain,
    key: properties.assetsToken,
  })

  dispatch({
    payload: {
      ...options,
      identity,
    },
    type: SET_ASSETS_ACCESS_TOKEN_SUCCESS,
  })
}

