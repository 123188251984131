import {
  ADD_FOOD,
  ADD_FOOD_FAILED,
  ADD_FOOD_SUCCESS,
  UPDATE_FOOD,
  UPDATE_FOOD_FAILED,
  UPDATE_FOOD_SUCCESS,
} from './constants'

const initialState = {
  isSubmitting: false,
}

export default (state = initialState, { type }) => {
  switch (type) {
    case ADD_FOOD:
    case UPDATE_FOOD:
      return { ...state, isSubmitting: true }
    case ADD_FOOD_FAILED:
    case ADD_FOOD_SUCCESS:
    case UPDATE_FOOD_FAILED:
    case UPDATE_FOOD_SUCCESS:
      return { ...state, isSubmitting: false }
    default:
      return state
  }
}
