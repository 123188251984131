import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { PAYMENT_TYPE_CODE } from 'module/Management/ManagementFinanceSettings/ManagementPaymentTypes/constants'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withNurseryPaymentTypesService } from 'services/legacy/nurseryPaymentTypes'

import { Select } from 'components'

import i18n from 'translations'

import withInfiniteDropdown from './withInfiniteDropdown'

class PaymentTypesDropdown extends Component {
  componentDidMount() {
    const { infiniteDropdownHelpers, nurseryPaymentTypesActions, value } = this.props

    if (!_.isArray(value)) {
      return infiniteDropdownHelpers.handleComponentDidMount({
        action: () => nurseryPaymentTypesActions.getDropdownItem({
          onFailed: this.notFoundValue,
          onSuccess: infiniteDropdownHelpers.handleComponentDidMountOnSuccess,
          params: [value?.value || value],
        }),
        value,
      })
    }

    return null
  }

  handleLoadMoreElements = async (phrase) => {
    const {
      extraFields = ['code'],
      infiniteDropdownHelpers,
      nurseryPaymentTypesActions,
      nurseryPaymentTypesSelectors,
      limit,
      isUkCountry,
    } = this.props

    return infiniteDropdownHelpers.handleLoadMoreElements({
      clearAction: () => {},
      listAction: ({ newPage, newSearchPhrase, result }) => nurseryPaymentTypesActions.dropdownList({
        onSuccess: (response) => {
          const { data, meta } = response

          const newData = _.filter(data, (item) => PAYMENT_TYPE_CODE.BACS_DIRECT_DEBIT !== item.code)

          const newResponse = {
            data: isUkCountry ? data : newData,
            meta,
          }

          return infiniteDropdownHelpers.handleLoadMoreElementsOnSuccess({
            extraFields,
            newPage,
            newSearchPhrase,
            response: newResponse,
            result,
          })
        },
        params: {
          criteria: nurseryPaymentTypesSelectors.getDropdownListCriteria({ name: newSearchPhrase }),
          limit,
          page: newPage,
        },
      }),
      phrase,
    })
  }

  notFoundValue = () => {
    const { onChange } = this.props

    onChange(null)
  }

  handleChange = (e) => {
    const { infiniteDropdownHelpers, onChange } = this.props

    infiniteDropdownHelpers.handleOnChange(e, () => onChange(e))
  }

  render() {
    const {
      disabled,
      infiniteDropdownState,
      placeholder,
      rawValue,
      value,
      ...rest
    } = this.props
    const { cacheUniq, options, selectedValue } = infiniteDropdownState

    return (
      <Select
        {...rest}
        cacheUniq={cacheUniq}
        disabled={disabled}
        loadOptions={this.handleLoadMoreElements}
        options={options}
        placeholder={placeholder
         || _.upperFirst(i18n.t('module:Management:ManagementPaymentTypes:tableTitle'))}
        rawValue={rawValue || selectedValue || value}
        value={value}
        searchable
        onChange={this.handleChange}
      />
    )
  }
}

const mapState = (state, { nurseriesSelectors }) => ({
  isUkCountry: nurseriesSelectors.getIsUkCountry(state),
})

const enhance = compose(
  withAppService,
  withNurseriesService,
  withNurseryPaymentTypesService,
  withInfiniteDropdown,
  connect(mapState),
)

export default enhance(PaymentTypesDropdown)
