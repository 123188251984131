import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import { CLEAR_CARERS, LIST_CARERS, LIST_CARERS_FAILED, LIST_CARERS_SUCCESS } from './constants'

export default (...props) => {
  const [state = getBasicListInitialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_CARERS,
    failed: LIST_CARERS_FAILED,
    init: LIST_CARERS,
    success: LIST_CARERS_SUCCESS,
  }, getBasicListInitialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
