import i18n from 'translations'

export const GLOBAL_SETTINGS = {
  APPLY_CUSTOMIZABLE: 'APPLY_CUSTOMIZABLE',
  APPLY_NON_CUSTOMIZABLE: 'APPLY_NON_CUSTOMIZABLE',
  DO_NOT_APPLY: 'DO_NOT_APPLY',
}

/* eslint sort-keys:0 */
export const GLOBAL_SETTINGS_OPTIONS = [
  {
    label: i18n.t('module:Organisations:GlobalSettings:ApplyCustomizable:label'),
    value: GLOBAL_SETTINGS.APPLY_CUSTOMIZABLE,
  },
  {
    label: i18n.t('module:Organisations:GlobalSettings:ApplyNonCustomizable:label'),
    value: GLOBAL_SETTINGS.APPLY_NON_CUSTOMIZABLE,
  },
  {
    label: i18n.t('module:Organisations:GlobalSettings:DoNotApply:label'),
    value: GLOBAL_SETTINGS.DO_NOT_APPLY,
  },
]

export const GLOBAL_NUMBER_SETTINGS = {
  ACROSS_ORGANIZATION: 'ACROSS_ORGANIZATION',
  SEPARATELY_FOR_EACH_NURSERY: 'SEPARATELY_FOR_EACH_NURSERY',
}

export const GLOBAL_NUMBER_SETTINGS_OPTIONS = [
  {
    label: i18n.t('module:Organisations:GlobalNumberSettings:SeparatelyForEachNursery:label'),
    tooltip: i18n.t('module:Organisations:GlobalNumberSettings:SeparatelyForEachNursery:tooltip'),
    value: GLOBAL_NUMBER_SETTINGS.SEPARATELY_FOR_EACH_NURSERY,
  },
  {
    label: i18n.t('module:Organisations:GlobalNumberSettings:AcrossOrganization:label'),
    tooltip: i18n.t('module:Organisations:GlobalNumberSettings:AcrossOrganization:tooltip'),
    value: GLOBAL_NUMBER_SETTINGS.ACROSS_ORGANIZATION,
  },
]

export const GLOBAL_SECURITY_SETTINGS = {
  ACROSS_ORGANIZATION: 'ACROSS_ORGANIZATION',
  SEPARATELY_FOR_EACH_NURSERY: 'SEPARATELY_FOR_EACH_NURSERY',
}

export const GLOBAL_SECURITY_SETTINGS_OPTIONS = [
  {
    label: i18n.t('module:Settings:Security:GlobalSettings:SeparatelyForEachNursery:label'),
    tooltip: i18n.t('module:Settings:Security:GlobalSettings:SeparatelyForEachNursery:tooltip'),
    value: GLOBAL_SECURITY_SETTINGS.SEPARATELY_FOR_EACH_NURSERY,
  },
  {
    label: i18n.t('module:Settings:Security:GlobalSettings:AcrossOrganization:label'),
    tooltip: i18n.t('module:Settings:Security:GlobalSettings:AcrossOrganization:tooltip'),
    value: GLOBAL_SECURITY_SETTINGS.ACROSS_ORGANIZATION,
  },
]
