import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change, getFormValues, submit } from 'redux-form'

import { EVENTS, logEvent } from 'analytics'

import invoiceConstants from 'services/legacy/invoices/constants'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withInvoicesService } from 'services/legacy/invoices'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouter } from 'services/router'

import i18n from 'translations'

import ReIssueInvoicesView from './ReIssueInvoicesView'
import { RE_ISSUE_INVOICES_FORM } from './components/ReIssueInvoicesForm'

const TRANS_PREFIX = 'module:Children:Child:BookingPattern:AutoCreditNoting:ReIssueInvoices'

const GROUPS = {
  read: [
    'invoice',
    'invoice.child',
    'invoice.items',
  ],
}

const CUSTOM_BULK_INVOICE_ITEM_TYPES = [
  invoiceConstants.LINE_ITEM_TYPES.DISCOUNT,
  invoiceConstants.LINE_ITEM_TYPES.ITEM,
  invoiceConstants.LINE_ITEM_TYPES.OTHER,
]

class ReIssueInvoicesContainer extends Component {
  constructor(props) {
    super(props)

    const { paginationUtils } = props
    const { setPageLocationQuery } = paginationUtils

    setPageLocationQuery(false)
  }

  componentDidMount() {
    this.createInvoice()
  }

  componentWillUnmount() {
    const { invoicesActions } = this.props

    invoicesActions.clearBatch()
    invoicesActions.clearList()
  }

  getInvoiceItems = (items) => _.map(
    _.filter(items, ({ type }) => CUSTOM_BULK_INVOICE_ITEM_TYPES.includes(type)),
    ({
      name,
      quantity,
      total,
      type,
      unitPrice,
    }) => ({
      name,
      quantity,
      total,
      type,
      unitPrice,
    }),
  )

  createInvoice = () => {
    const {
      childInvoices,
      invoicesActions,
    } = this.props

    const payload = _.map(childInvoices, ({
      child: { id: childId },
      dueDate,
      endDate,
      issueDate,
      items,
      name,
      startDate,
    }) => ({
      child: { id: childId },
      dueDate,
      endDate,
      issueDate,
      items: this.getInvoiceItems(items),
      name,
      startDate,
    }))

    invoicesActions.createBatch(payload, {
      params: {
        groups: GROUPS,
      },
    })
  }

  handleInvoiceItemClick = (childId, invoiceId) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.INVOICE_PREVIEW, {
      childId,
      invoiceId,
    })
  }

  handleBatchUpdateSuccess = (response) => {
    const { onContinueClick } = this.props
    const { data } = response

    const hasExceptions = _.some(data, ({ exception }) => !!exception)

    if (!hasExceptions) {
      onContinueClick()
    }
  }

  updateBatch = (fields) => {
    const {
      invoicesActions,
      invoicesSelectors,
      onContinueClick,
    } = this.props

    const payload = invoicesSelectors.getBatchUpdatePayload(fields)

    if (!payload) {
      return onContinueClick()
    }

    return invoicesActions.updateBatch(payload, {
      onSuccess: this.handleBatchUpdateSuccess,
    })
  }

  handleSubmit = (fields) => {
    const {
      modalActions,
      modalConsts,
    } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'send',
      onConfirm: () => this.updateBatch(fields),
      text: i18n.t(`${TRANS_PREFIX}:continuePopupText`),
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleContinueClick = () => {
    const { initialValues, onContinueClick, submitForm } = this.props

    if (!initialValues?.invoices?.length) {
      return onContinueClick()
    }

    return submitForm()
  }

  handleDeleteSuccess = (invoiceId) => {
    const { formValues, invoicesActions, updateField } = this.props

    invoicesActions.removeBatchInvoice(invoiceId)

    logEvent(EVENTS.INVOICE_REMOVED, { context: 'auto credit noting' })

    updateField('invoices', _.reject(formValues.invoices, ({ id }) => id === invoiceId))
  }

  deleteInvoice = (invoiceId) => {
    const { invoicesActions } = this.props

    invoicesActions.remove(invoiceId, () => this.handleDeleteSuccess(invoiceId))
  }

  handleDeleteInvoiceClick = (invoiceId) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'trash',
      onConfirm: () => this.deleteInvoice(invoiceId),
      text: i18n.t(`${TRANS_PREFIX}:deleteInvoicePopupText`),
    })
  }

  handleAddInvoiceSaveSuccess = ({ data }) => {
    const { formValues, invoicesActions, invoicesSelectors, updateField } = this.props

    const newInvoiceItemFormValue = invoicesSelectors.getBatchInvoiceItem()(invoicesSelectors.mapBatchInvoices({
      invoice: data,
    }))

    invoicesActions.addBatchInvoice(data)

    updateField('invoices', [
      ...formValues.invoices,
      newInvoiceItemFormValue,
    ])
  }

  handleUpdateInvoiceSaveSuccess = ({ data }, invoiceId) => {
    const { formValues, invoicesActions, invoicesSelectors, updateField } = this.props

    const newInvoiceItemFormValue = invoicesSelectors.getBatchInvoiceItem()(invoicesSelectors.mapBatchInvoices({
      invoice: data,
    }))

    invoicesActions.updateBatchInvoice(data, invoiceId)

    updateField('invoices', _.map(formValues.invoices, (invoice) => {
      const { id } = invoice

      if (id === invoiceId) {
        return newInvoiceItemFormValue
      }

      return invoice
    }))
  }

  handleAddInvoiceClick = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.INVOICE_ADD, {
      onSaveSuccess: this.handleAddInvoiceSaveSuccess,
    }, {
      enableMultipleModal: true,
    })
  }

  handleEditInvoiceClick = (invoiceId) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.INVOICE_ADD, {
      invoiceId,
      onSaveSuccess: (response) => this.handleUpdateInvoiceSaveSuccess(response, invoiceId),
    }, {
      enableMultipleModal: true,
    })
  }

  render() {
    const {
      batchInvoiceErrors,
      batchInvoicesTotal,
      childId,
      initialValues,
      isSubmitting,
    } = this.props

    return (
      <ReIssueInvoicesView
        batchInvoiceErrors={batchInvoiceErrors}
        batchInvoicesTotal={batchInvoicesTotal}
        childId={childId}
        initialValues={initialValues}
        isSubmitting={isSubmitting}
        onAddInvoiceClick={this.handleAddInvoiceClick}
        onContinueClick={this.handleContinueClick}
        onDeleteInvoiceClick={this.handleDeleteInvoiceClick}
        onEditInvoiceClick={this.handleEditInvoiceClick}
        onInvoiceItemClick={this.handleInvoiceItemClick}
        onPageChange={this.handlePageChange}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  invoicesBatchState,
  invoicesSelectors,
}) => ({
  batchInvoiceErrors: invoicesSelectors.getBatchInvoiceErrors(state),
  batchInvoicesTotal: invoicesSelectors.getBatchInvoicesTotal(state),
  childInvoices: invoicesSelectors.getInvoiceListSelector(state),
  errorMessages: appSelectors.getErrorMessages(invoicesBatchState),
  formValues: getFormValues(RE_ISSUE_INVOICES_FORM)(state),
  initialValues: invoicesSelectors.getBatchInitialValues(state),
  isSubmitting: appSelectors.getIsSubmitting(invoicesBatchState),
})

const mapDispatch = {
  submitForm: () => submit(RE_ISSUE_INVOICES_FORM),
  updateField: (fieldName, fieldValue) => change(RE_ISSUE_INVOICES_FORM, fieldName, fieldValue),
}

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withInvoicesService,
  withPaginationUtils,
  connect(mapState, mapDispatch),
)

export default enhance(ReIssueInvoicesContainer)
