import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const StyledItemContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`

export const StyledTableContainer = styled.div`
  display: table;
  font-weight: 600;
`

export const StyledRowContainer = styled.div`
  display: table-row;
`

export const StyledCellHeaderContainer = styled.div`
  display: table-cell;
  height: 30px;
  padding-right: 20px;
`

export const StyledCellValueContainer = styled.div`
  display: table-cell;
  text-align: right;
`

export const StyledInvoiceStatus = styled.div`
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
`

export const StyledInvoiceStatusValue = styled.div`
  color: ${getBrandingColor('primary-color')};
  font-size: 18px;
  font-weight: 600;
`
