import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CHILDREN_SIGNUP_REPORT,
  CHILDREN_SIGNUP_REPORT_FAILED,
  CHILDREN_SIGNUP_REPORT_SUCCESS,
  CLEAR_CHILDREN_SIGNUP_REPORT,
} from './constants'

export const getChildren = (options: BasicActionOptionsProps) => ({
  childrenSignedUpReportApiClient,
}) => generateBasicActions.list({
  constants: {
    failed: CHILDREN_SIGNUP_REPORT_FAILED,
    init: CHILDREN_SIGNUP_REPORT,
    success: CHILDREN_SIGNUP_REPORT_SUCCESS,
  },
  options,
  service: childrenSignedUpReportApiClient,
  serviceMethod: 'getChildrenSignedUp',
})

export const clearChildren = () => ({
  type: CLEAR_CHILDREN_SIGNUP_REPORT,
})
