import { createSelector } from 'reselect'
import _ from 'lodash'
import { FRAMEWORK_PROGRESS_STATES } from '../../frameworks/constants'

const getFormativeReports = (state) => state.formativeReports

export const getFormativeReportsListMontessoriItems = createSelector(
  [getFormativeReports],
  (state) => {
    const montessoriItems = state.montessoriItems?.data || []

    let categories = _.flow(
      (items) => _.groupBy(items, (e) => e.activity.subcategory.category.name),
      (items) => _.map(items, (values, name) => ({ name, values })),
    )(montessoriItems)

    categories = _.map(categories, ({ name: categoryName, values: categoryValues }) => {
      let subcategories = _.flow(
        (items) => _.groupBy(items, (e) => e.activity.subcategory.name),
        (items) => _.map(items, (values, name) => ({ name, values })),
      )(categoryValues)

      subcategories = _.map(subcategories, ({ name: subCategoryName, values: subCategoryValues }) => {
        const secureItems = _.map(
          _.filter(
            subCategoryValues,
            ({ state: stateValue }) => stateValue === FRAMEWORK_PROGRESS_STATES.SECURE.value,
          ),
          ({ activity }) => ({ id: activity.id, name: activity.name }),
        )
        const workOnItems = _.map(
          _.filter(
            subCategoryValues,
            ({ state: stateValue }) => stateValue !== FRAMEWORK_PROGRESS_STATES.SECURE.value,
          ),
          ({ activity }) => ({ id: activity.id, name: activity.name }),
        )

        return {
          subCategoryName,
          values: {
            secureItems,
            workOnItems,
          },
        }
      })

      return {
        categoryName,
        subcategories,
      }
    })

    return categories
  },
)
