import React from 'react'
import { WrappedFieldProps } from 'redux-form'

import { PhotoUploadProps } from 'components/PhotoUpload/PhotoUpload'

import { PhotoUpload } from 'components'

type FormPhotoUploadFullProps = PhotoUploadProps & WrappedFieldProps

const FormPhotoUpload: React.FC<FormPhotoUploadFullProps> = ({
  input: { onChange, value },
  meta: { error, submitFailed },
  ...rest
}) => (
  <PhotoUpload
    {...rest}
    error={submitFailed && error}
    value={value && value.value ? value.value : value}
    onChange={onChange}
  />
)

export default FormPhotoUpload
