import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Field, change, formValueSelector, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { Avatar, Form, Typography } from 'components'

import i18n from 'translations'

import { ALL_CHILDREN, ALL_KEY_PERSONS } from '../ObservationsExportModalContainer'

export const FORM_NAME = 'ObservationsExportForm'

const ObservationsExportForm = ({
  allObservations,
  changeFieldValue,
  child,
  handleSubmit,
  isKeyPersonsDisabled,
  isRoomsDisabled,
  isSubmitting,
  onCloseClick,
  onSubmit,
}) => {
  const renderChildFilters = () => {
    if (child) {
      const { ageMonths, firstName, photo, surname } = child

      return (
        <Form.Row>
          <Form.Row.Item>
            <Avatar
              initials={[firstName, surname]}
              src={photo}
              subTitle={`${ageMonths} Months`}
              title={(
                <Typography fontSize={14} primary>
                  {`${firstName} ${surname}`}
                </Typography>
              )}
            />
          </Form.Row.Item>
        </Form.Row>
      )
    }

    return (
      <Form.Row label="Select all or a group of children" verticalLabel>
        <Form.Row.Item>
          <Field
            component={Form.InfiniteDropdowns.Rooms}
            disabled={isRoomsDisabled}
            extraOptions={[ALL_CHILDREN]}
            name="room"
            placeholder="Select room"
            validate={!isRoomsDisabled && isRequired}
          />
        </Form.Row.Item>
        <Form.Row.TextItem text="or" />
        <Form.Row.Item>
          <Field
            component={Form.InfiniteDropdowns.Users}
            disabled={isKeyPersonsDisabled}
            extraOptions={[ALL_KEY_PERSONS]}
            name="keyPerson"
            placeholder="Select key person"
            validate={!isKeyPersonsDisabled && isRequired}
          />
        </Form.Row.Item>
      </Form.Row>
    )
  }

  const handleAllObservationsChange = (value) => {
    if (value) {
      changeFieldValue('observationsDateRange', [undefined, undefined])
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {renderChildFilters()}
      <Form.Row label="Select a date range or all observations to download" verticalLabel>
        <Form.Row.Item>
          <Field
            component={Form.DatePicker}
            disabled={allObservations}
            name="observationsDateRange"
            clearable
            range
          />
        </Form.Row.Item>
        <Form.Row.TextItem text="or" />
        <Form.Row.Item>
          <Field
            component={Form.Checkbox}
            label="All observations"
            name="allObservations"
            onChange={handleAllObservationsChange}
          />
        </Form.Row.Item>
      </Form.Row>
      <Form.Row label="Select a manager or administrator to send the PDFs to" verticalLabel>
        <Form.Row.FlexItem>
          <Field
            component={Form.InfiniteDropdowns.OrganizationMemberships}
            name="memberships"
            placeholder="Select staff"
            validate={isRequired}
            multi
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Typography fontSize="14">
        <p>
          NOTE: Only approved observations can be generated into PDFs.
        </p>
      </Typography>
      <Form.FooterActions
        isSubmitting={isSubmitting}
        submitDisabled={isSubmitting}
        submitLabel={i18n.t('global:Export')}
        onCancelClick={onCloseClick}
      />
    </Form>
  )
}

const validate = (values) => {
  const errors = {}

  if (values) {
    const { allObservations, observationsDateRange = [] } = values
    const [before, after] = observationsDateRange

    if (!allObservations && (!before || !after)) {
      errors.observationsDateRange = 'At least one of the fields should be selected.'
    }
  }

  return errors
}

const mapState = (state) => ({
  allObservations: formValueSelector(FORM_NAME)(state, 'allObservations'),
})

const mapDispatch = {
  changeFieldValue: (field, value) => change(FORM_NAME, field, value),
}

const enhance = compose(
  reduxForm({ form: FORM_NAME, validate }),
  connect(mapState, mapDispatch),
)

export default enhance(ObservationsExportForm)
