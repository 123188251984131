import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { RootState } from 'core/reducers'

import { injectActions } from 'utils/service'

import actions from './actions'
import selectors from './selectors'
import { ChildProductsListState } from './list/reducer'
import { ChildProductsSingleState } from './single/reducer'

export interface withChildProductsServiceProps {
  childProductsActions: typeof actions
  childProductsListState: ChildProductsListState
  childProductsSelectors: typeof selectors
  childProductsSingleState: ChildProductsSingleState
}

const withChildProductsService = <P extends withChildProductsServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ childProducts }: RootState) => ({ childProducts })

  const mapDispatch = injectActions('childProductsActions', actions)

  const Component = ({ childProducts, childProductsActions, ...others }) => (
    <WrappedComponent
      childProductsActions={childProductsActions}
      childProductsListState={childProducts.list}
      childProductsSelectors={selectors}
      childProductsSingleState={childProducts.single}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withChildProductsService
