import React from 'react'
import { connect } from 'react-redux'

import { injectMultipleActions } from 'utils/service'

import * as dietaryActions from './dietary/actions'
import * as dietarySelectors from './dietary/selectors'

const withChildStatisticsService = (WrappedComponent) => {
  const mapState = ({ child }) => ({ child })

  const mapDispatch = injectMultipleActions([
    ['dietaryStatisticsActions', dietaryActions],
  ])

  const Component = (props) => {
    const { child, dietaryStatisticsActions, ...others } = props

    return (
      <WrappedComponent
        childStatisticsActions={dietaryStatisticsActions}
        childStatisticsDietaryState={child.dietary}
        childStatisticsSelectors={dietarySelectors}
        {...others}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withChildStatisticsService
