import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledSubTitleContainer = styled.div`
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  
  ${({ $inline }) => $inline && `
    color: ${NEUTRAL_COLOURS.GRAY};
  `}
`
