import {
  CLEAR_FORMATIVE_REPORTS_LISTS,
  LIST_FORMATIVE_REPORTS,
  LIST_FORMATIVE_REPORTS_FAILED,
  LIST_FORMATIVE_REPORTS_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: {},
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case LIST_FORMATIVE_REPORTS:
      return { ...state, error: initialState.error, isFetching: true }
    case LIST_FORMATIVE_REPORTS_SUCCESS: {
      const { data, meta } = payload

      return {
        ...state,
        data,
        isFetching: false,
        meta,
      }
    }
    case LIST_FORMATIVE_REPORTS_FAILED:
      return { ...state, error, isFetching: false }
    case CLEAR_FORMATIVE_REPORTS_LISTS:
      return initialState
    default:
      return state
  }
}
