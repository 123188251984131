import styled from 'styled-components'

import { OBSERVATION_STATES } from 'services/legacy/frameworks/constants'

export const StyledMontessoriActivity = styled.span`
  font-weight: 600;
  
  ${({ montessoriActivity }) => montessoriActivity === OBSERVATION_STATES.DEVELOPING.value && `
    color: ${OBSERVATION_STATES.DEVELOPING.colour};
  `}  
  
  ${({ montessoriActivity }) => montessoriActivity === OBSERVATION_STATES.EMERGING.value && `
    color: ${OBSERVATION_STATES.EMERGING.colour};
  `}
    
  ${({ montessoriActivity }) => montessoriActivity === OBSERVATION_STATES.SECURE.value && `
    color: ${OBSERVATION_STATES.SECURE.colour};
  `}
`
