import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

const getChildrenSessions = (state) => state.childrenSessions

export const getChildrenSessionsListState = createSelector([getChildrenSessions], (state) => state.list)

export const getChildrenSessionsListDataState = createSelector([getChildrenSessionsListState], (state) => state.data)

export const getChildrenSessionsListMetaState = createSelector([getChildrenSessionsListState], (state) => state.meta)

export const getOverlappingSessionsCriteria = createSelector(
  [(filters) => filters],
  (filters) => {
    const { childId, endDate, isOngoing, sessionId, startDate } = filters

    const criteria = [
      {
        field: 'or[0][endDate][gte]',
        value: moment(startDate).format(DEFAULT_DATE_FORMAT),
      },
      {
        field: 'or[0][isOngoing][eq]',
        value: 1,
      },
      {
        field: 'child',
        value: childId,
      },
      {
        field: 'archived',
        value: 0,
      },
    ]

    if (!isOngoing) {
      criteria.push({
        comparator: 'before',
        field: 'startDate',
        value: moment(endDate).format(DEFAULT_DATE_FORMAT),
      })
    }

    if (sessionId) {
      criteria.push({
        field: 'not[id]',
        value: sessionId,
      })
    }

    return criteria
  },
)
