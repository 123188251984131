import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { DRINKING_METHOD, TOILET_TRAINED } from 'services/legacy/child/constants'
import { DAILY_DIARY_LIST_GROUPS } from 'services/legacy/dailyDiary/constants'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withRoomsService } from 'services/legacy/rooms'
import { withRegisterService } from 'services/legacy/register'
import { withDailyDiaryBottlesService } from 'services/legacy/dailyDiaryBottles'
import { withDailyDiaryActivitiesService } from 'services/legacy/dailyDiaryActivities'
import { withDailyDiaryNappiesService } from 'services/legacy/dailyDiaryNappies'
import { withDailyDiarySleepsService } from 'services/legacy/dailyDiarySleeps'
import { withModalService } from 'services/utils/modal'
import { withRouterUtils } from 'services/utils/router'
import { withChildRegistersService } from 'services/legacy/childRegisters'

import ChildPickerTabView from './ChildPickerTabView'
import { DAILY_DIARY_RECORD_MODAL_VARIANTS } from '../DailyDiaryRecordModalContainer'

const CHILD_REGISTERS_GROUPS = {
  read: [
    'child',
    'child.profile',
    'child.nurseryClass',
    'nurseryClass',
    'childRegister.child',
    'childInformation',
    'childRegister.diaryRecordStatistics',
    'childRegisterDiaryRecordStatistics',
  ],
}

class ChildPickerTabContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filters: {
        room: null,
        selectedAll: ((
          props.childrenList?.length
          && props.selectedChildren?.length
          && props.childrenList?.length === props.selectedChildren?.length
        ) || false),
      },
      selectedChildren: props.selectedChildren || [],
    }
  }

  componentDidMount() {
    this.fetchChildren()
  }

  componentWillUnmount() {
    const { childActions, childRegistersActions } = this.props

    childActions.clear()
    childRegistersActions.clearList()
  }

  // Filter require to remove unregister client from the diary
  // https://sentry.io/organizations/blossom-educational-ltd/issues/1856263254/?project=182538
  updateSelectedChildren = () => setTimeout(() => {
    const { selectedChildren } = this.state
    const { childrenList } = this.props

    if (selectedChildren?.length) {
      this.setState({
        selectedChildren: _.filter(
          selectedChildren,
          (id) => _.some(childrenList, ({ id: childId }) => childId === id),
        ),
      })
    }
  })

  fetchChildren = () => {
    const {
      childRegistersActions,
      childRegistersSelectors,
      dailyDiaryBottlesActions,
      dailyDiaryBottlesSelectors,
      dailyDiaryNappiesActions,
      dailyDiaryNappiesSelectors,
      dailyDiarySleepsActions,
      dailyDiarySleepsSelectors,
      date,
      setLocationQuery,
      timeType,
      variant,
    } = this.props
    const { filters: { room } } = this.state

    setLocationQuery({}, ['page'])

    if (DAILY_DIARY_RECORD_MODAL_VARIANTS.BOTTLE === variant) {
      const criteria = dailyDiaryBottlesSelectors.getListCriteria({
        childrenWithoutRecords: true,
        entryDate: date,
        not: {
          drinkingMethod: DRINKING_METHOD.BOTTLE,
        },
        room,
        timeType,
      })

      dailyDiaryBottlesActions.childrenListWithoutRecords({
        params: {
          criteria,
          groups: DAILY_DIARY_LIST_GROUPS,
        },
        recursively: true,
      })
    }

    if (DAILY_DIARY_RECORD_MODAL_VARIANTS.NAPPY === variant) {
      const criteria = dailyDiaryNappiesSelectors.getListCriteria({
        childrenWithoutRecords: true,
        entryDate: date,
        not: {
          toiletTrained: TOILET_TRAINED.NAPPIES,
        },
        room,
        timeType,
      })

      dailyDiaryNappiesActions.childrenListWithoutRecords({
        params: {
          criteria,
          groups: DAILY_DIARY_LIST_GROUPS,
        },
        recursively: true,
      })
    }

    if (DAILY_DIARY_RECORD_MODAL_VARIANTS.SLEEP === variant) {
      const criteria = dailyDiarySleepsSelectors.getListCriteria({
        childrenWithoutRecords: true,
        entryDate: date,
        not: {
          daytimeSleepingBehavior: true,
        },
        room,
        timeType,
      })

      dailyDiarySleepsActions.childrenListWithoutRecords({
        params: {
          criteria,
          groups: DAILY_DIARY_LIST_GROUPS,
        },
        recursively: true,
      })
    }

    if (DAILY_DIARY_RECORD_MODAL_VARIANTS.ACTIVITY === variant) {
      const criteria = childRegistersSelectors.getListCriteria({ entryDate: date, room, timeType })

      childRegistersActions.list({
        onSuccess: this.updateSelectedChildren,
        params: {
          criteria,
          groups: CHILD_REGISTERS_GROUPS,
        },
        recursively: true,
      })
    }
  }

  handleRoomChange = (room) => {
    const { childrenList } = this.props
    const { selectedChildren } = this.state

    const filteredChildrenList = _.filter(childrenList, ({ nurseryClass: { id } }) => room?.value === id)
    let finalSelectedAll = !_.find(filteredChildrenList, ({ id }) => (
      !_.find(selectedChildren, (subId) => subId === id)
    ))

    if (!filteredChildrenList.length) {
      finalSelectedAll = false
    }

    this.setState((prev) => ({
      ...prev,
      filters: {
        room: room?.value,
        selectedAll: finalSelectedAll,
      },
    }))
  }

  handleSelectedAllChange = () => {
    const { childrenList } = this.props
    const { filters: { room } } = this.state

    let filteredChildrenList = childrenList

    if (room) {
      filteredChildrenList = _.filter(childrenList, ({ nurseryClass: { id } }) => room === id)
    }

    this.setState((prev) => {
      const newValue = !prev.filters.selectedAll

      let finalSelectedChildren = (
        newValue
          ? _.map(filteredChildrenList, ({ id }) => id)
          : []
      )

      if (room && prev.selectedChildren.length && newValue) {
        finalSelectedChildren = _.uniq([
          ...prev.selectedChildren,
          ..._.map(filteredChildrenList, ({ id }) => id),
        ])
      }

      if (room && prev.selectedChildren.length && !newValue) {
        finalSelectedChildren = _.filter(prev.selectedChildren, (subId) => (
          !_.find(filteredChildrenList, ({ id }) => subId === id)
        ))
      }

      return ({
        ...prev,
        filters: {
          ...prev.filters,
          selectedAll: newValue,
        },
        selectedChildren: finalSelectedChildren,
      })
    })
  }

  handleSelectedChildrenChange = (value) => {
    const { childrenList } = this.props
    const { filters } = this.state
    let { selectedAll } = filters

    if (selectedAll) {
      if (value.length !== childrenList?.length) {
        selectedAll = false
      }
    } else if (value.length === childrenList?.length) {
      selectedAll = true
    }

    this.setState({
      filters: {
        ...filters,
        selectedAll,
      },
      selectedChildren: value,
    })
  }

  handleNext = () => {
    const { childrenList, onNext } = this.props
    const { selectedChildren } = this.state

    onNext(_.map(
      selectedChildren,
      (id) => _.find(childrenList, (child) => id === child.id),
    ))
  }

  render() {
    const { childrenList, isFetching, onCloseClick, onlyChildPicker } = this.props
    const { filters: { room, selectedAll }, selectedChildren } = this.state

    const initialValues = {
      children: selectedChildren,
    }

    let filteredChildrenList = childrenList

    if (room) {
      filteredChildrenList = _.filter(childrenList, ({ nurseryClass: { id } }) => room === id)
    }

    return (
      <ChildPickerTabView
        childrenList={filteredChildrenList}
        initialValues={initialValues}
        isFetching={isFetching}
        onlyChildPicker={onlyChildPicker}
        room={room}
        selectedAll={selectedAll}
        selectedChildren={selectedChildren}
        onCloseClick={onCloseClick}
        onNext={this.handleNext}
        onRoomChange={this.handleRoomChange}
        onSelectedAllChange={this.handleSelectedAllChange}
        onSelectedChildrenChange={this.handleSelectedChildrenChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childRegistersListState,
  childRegistersSelectors,
  dailyDiaryBottlesListWithoutRecordsState,
  dailyDiaryBottlesSelectors,
  dailyDiaryNappiesListWithoutRecordsState,
  dailyDiaryNappiesSelectors,
  dailyDiarySleepsListWithoutRecordsState,
  dailyDiarySleepsSelectors,
  variant,
}) => ({
  childrenList: (() => {
    switch (variant) {
      case DAILY_DIARY_RECORD_MODAL_VARIANTS.BOTTLE: {
        return dailyDiaryBottlesSelectors.getChildrenDailyDiaryBottlesListWithoutRecordsData(state)
      }
      case DAILY_DIARY_RECORD_MODAL_VARIANTS.SLEEP: {
        return dailyDiarySleepsSelectors.getChildrenDailyDiarySleepsListWithoutRecordsData(state)
      }
      case DAILY_DIARY_RECORD_MODAL_VARIANTS.NAPPY: {
        return dailyDiaryNappiesSelectors.getChildrenDailyDiaryNappiesListWithoutRecordsData(state)
      }
      case DAILY_DIARY_RECORD_MODAL_VARIANTS.ACTIVITY: {
        return childRegistersSelectors.getChildRegistersListDataGroupedByChild(state)
      }
      default: {
        return []
      }
    }
  })(),
  isFetching: appSelectors.getIsFetching(
    dailyDiaryBottlesListWithoutRecordsState,
    dailyDiarySleepsListWithoutRecordsState,
    dailyDiaryNappiesListWithoutRecordsState,
    childRegistersListState,
  ),
  totalResults: (() => {
    switch (variant) {
      case DAILY_DIARY_RECORD_MODAL_VARIANTS.BOTTLE: {
        return appSelectors.getTotalResults(dailyDiaryBottlesListWithoutRecordsState)
      }
      case DAILY_DIARY_RECORD_MODAL_VARIANTS.SLEEP: {
        return appSelectors.getTotalResults(dailyDiarySleepsListWithoutRecordsState)
      }
      case DAILY_DIARY_RECORD_MODAL_VARIANTS.NAPPY: {
        return appSelectors.getTotalResults(dailyDiaryNappiesListWithoutRecordsState)
      }
      case DAILY_DIARY_RECORD_MODAL_VARIANTS.ACTIVITY: {
        return appSelectors.getTotalResults(childRegistersListState)
      }
      default: {
        return []
      }
    }
  })(),
})

const enhance = compose(
  withAppService,
  withChildService,
  withChildRegistersService,
  withModalService,
  withRegisterService,
  withRouterUtils,
  withRoomsService,
  withDailyDiaryBottlesService,
  withDailyDiaryNappiesService,
  withDailyDiarySleepsService,
  withDailyDiaryActivitiesService,
  connect(mapState),
)

export default enhance(ChildPickerTabContainer)
