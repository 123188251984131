import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired, isRequiredDateRange, isValidDateRange } from 'utils/fieldValidation'

import { Form } from 'components'

import i18n from 'translations'

export const ENTITLEMENT_NOTES_FORM = 'EntitlementNotesForm'

const EntitlementNotesForm = ({
  handleSubmit,
  isSubmitting,
  onCancelClick,
  onSubmit,
}) => {
  const renderForm = () => (
    <div className="scrollableForm">
      <Form.Row
        label={i18n.t('module:Staff:StaffLeaveDetails:LeaveNotes:holidayPeriod')}
        required
        verticalLabel
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.DatePicker}
            name="holidayPeriod"
            validate={[isRequiredDateRange, isValidDateRange]}
            range
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Staff:StaffLeaveDetails:LeaveNotes:entitlement')}
        required
        verticalLabel
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextField}
            min="0"
            name="entitlementHours"
            placeholder="0"
            suffix={i18n.t('global:hours')}
            suffixWidth="50px"
            type="number"
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('global:Comment')}
        verticalLabel
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextAreaField}
            name="comment"
            placeholder={i18n.t('global:Comment')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
    </div>
  )

  const renderFooter = () => (
    <Form.FooterActions
      isSubmitting={isSubmitting}
      submitLabel={i18n.t('module:Staff:StaffLeaveDetails:LeaveNotes:saveNote')}
      noBorder
      noMargin
      onCancelClick={onCancelClick}
    />
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {renderForm()}
      {renderFooter()}
    </Form>
  )
}

export default reduxForm({ form: ENTITLEMENT_NOTES_FORM })(EntitlementNotesForm)
