import moment from 'moment'

import React, { PropsWithChildren } from 'react'

import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'

import { Space, Typography } from 'components'

import i18n from 'translations'

import AvatarItem from './components/AvatarItem'

interface CommentDrawerDetailsHeaderProps {
  author?: {
    name?: string
    photo?: string
  }
  createdAt?: string
  label?: string
}

const CommentDrawerDetailsHeader: React.FC<PropsWithChildren<CommentDrawerDetailsHeaderProps>> = ({
  author,
  createdAt,
  label,
}) => (
  <React.Fragment>
    <AvatarItem
      author={author}
      label={label}
    />
    <Typography>
      {i18n.t('components:CommentDrawer:createdOn')}
      {' '}
      {moment(createdAt).format(DISPLAY_SHORT_DATE_FORMAT)}
    </Typography>
    <Space space="30px" />
  </React.Fragment>
)

export default CommentDrawerDetailsHeader
