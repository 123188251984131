import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import { CLEAR_PAYOUTS_LIST, GET_PAYOUTS_LIST, GET_PAYOUTS_LIST_FAILED, GET_PAYOUTS_LIST_SUCCESS } from './constants'

export const getPayouts = (
  options: BasicActionOptionsProps,
) => ({ nurseryIntegrationsApiClient }) => generateBasicActions.list({
  constants: {
    failed: GET_PAYOUTS_LIST_FAILED,
    init: GET_PAYOUTS_LIST,
    success: GET_PAYOUTS_LIST_SUCCESS,
  },
  options,
  service: nurseryIntegrationsApiClient,
  serviceMethod: 'getPayouts',
})

export const clearPayouts = () => ({
  type: CLEAR_PAYOUTS_LIST,
})
