import {
  CLEAR_CREDIT_NOTES_LIST,
  GET_CREDIT_NOTES_LIST,
  GET_CREDIT_NOTES_LIST_FAILED,
  GET_CREDIT_NOTES_LIST_SUCCESS,
} from './constants'

export const list = (params, mergeResult) => ({ creditNotesApiClient }) => (dispatch) => {
  dispatch({ type: GET_CREDIT_NOTES_LIST })

  creditNotesApiClient.list(params).then(
    (data) => dispatch({ mergeResult, payload: data, type: GET_CREDIT_NOTES_LIST_SUCCESS }),
    ({ response }) => dispatch({ payload: response, type: GET_CREDIT_NOTES_LIST_FAILED }),
  )
}

export const clearList = () => ({
  type: CLEAR_CREDIT_NOTES_LIST,
})
