import _ from 'lodash'

export const updateChildFundingAllocation = (state, payload) => {
  const { data } = state

  if (!data?.length) {
    return state
  }

  const { data: responseData } = payload
  const { child, funding } = responseData
  const { id: childId } = child

  return {
    ...state,
    data: _.map(state.data, (item) => {
      if (item.id === childId) {
        return {
          ...item,
          currentChildFundings: _.map(item.currentChildFundings, (childFunding) => {
            if (childFunding.id === funding.id) {
              return funding
            }

            return childFunding
          }),
        }
      }

      return item
    }),
  }
}

export const assignChildFunding = (state, payload) => {
  const { data } = state

  if (!data?.length) {
    return state
  }

  const { data: responseData } = payload
  const { child } = responseData
  const { id: childId } = child

  return {
    ...state,
    data: _.map(state.data, (item) => {
      if (item.id === childId) {
        return {
          ...item,
          currentChildFundings: [...item.currentChildFundings, responseData],
        }
      }

      return item
    }),
  }
}

export const updateChildFunding = (state, payload) => {
  const { data } = state

  if (!data?.length) {
    return state
  }

  const { data: responseData } = payload
  const { child } = responseData
  const { id: childId } = child

  return {
    ...state,
    data: _.map(state.data, (item) => {
      if (item.id === childId) {
        return {
          ...item,
          currentChildFundings: _.map(item.currentChildFundings, (childFunding) => {
            if (childFunding.id === responseData.id) {
              return responseData
            }

            return childFunding
          }),
        }
      }

      return item
    }),
  }
}
