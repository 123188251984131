import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import i18n from 'translations'

export const PASSWORD_STRENGTH_LEVELS = [
  {
    color: '#FF4D29',
    label: i18n.t('module:Authentication:components:PasswordStrength:weak'),
    percentage: 25,
    score: 32,
  },
  {
    color: '#FF7321',
    label: i18n.t('module:Authentication:components:PasswordStrength:fair'),
    percentage: 50,
    score: 45,
  },
  {
    color: '#0181CA',
    label: i18n.t('module:Authentication:components:PasswordStrength:good'),
    percentage: 75,
    score: 60,
  },
  {
    color: NEUTRAL_COLOURS.GREEN,
    label: i18n.t('module:Authentication:components:PasswordStrength:strong'),
    percentage: 100,
    score: 100,
  },
]

export const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 140px auto;
  grid-gap: 10px;
  align-items: center;
  margin-bottom: 15px;
`

export const StyledIndicatorWrapper = styled.div`
  background: ${NEUTRAL_COLOURS.GRAY_QUINARY};
  width: 140px;
  height: 25px;
  border: 1px solid ${NEUTRAL_COLOURS.GRAY_SECONDARY};
  position: relative;
`

export const StyledIndicatorValue = styled.div`
  position: absolute;
  transition: .3s;
  top: 0;
  bottom: 0;
  left: 0;

  ${({ $level }) => $level && `
    width: ${$level.percentage}%;
    background: ${$level.color};
  `}
`
