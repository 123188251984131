import React from 'react'

import { DateString, XMasIcon } from 'components'
import Widget from 'module/Dashboard/components/Widget'

import { StyledDateSpan, StyledTimeAndDate, StyledTimeSpan } from './TimeWidgetStyled'

const TimeWidget = ({ date }) => (
  <Widget>
    <XMasIcon height="40px" icon="snowflake" marginLeft="-240px" marginTop="-187px" width="46px" />
    <XMasIcon height="26px" icon="snowflake-variant-2" marginLeft="226px" marginTop="-168px" width="23px" />
    <StyledTimeAndDate>
      <StyledTimeSpan>
        <DateString date={date} format="LT" />
      </StyledTimeSpan>
      <StyledDateSpan>
        <DateString date={date} format="dddd, MMM Do YYYY" />
      </StyledDateSpan>
    </StyledTimeAndDate>
    <XMasIcon height="56px" icon="santa-sleigh" marginLeft="-5px" marginTop="178px" width="156px" />
  </Widget>
)

export default TimeWidget
