import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as childObservationsSelectors from './selectors'

const withChildObservationsService = (WrappedComponent) => {
  const mapState = ({ childObservations }) => ({ childObservations })

  const mapDispatch = injectActions('childObservationsActions', actions)

  const Component = ({ childObservations, childObservationsActions, ...other }) => (
    <WrappedComponent
      childObservationsActions={childObservationsActions}
      childObservationsListState={childObservations.list}
      childObservationsSelectors={childObservationsSelectors}
      childObservationsSingleState={childObservations.single}
      {...other}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withChildObservationsService
