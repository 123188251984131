import styled from 'styled-components'

export const StyledFilters = styled.div`
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: 260px auto;
  grid-gap: 10px;
  
  @media(max-width: 740px) {
    grid-template-columns: auto;
  }
`
