import React from 'react'

import { StyledRowContainer } from './GridLayoutStyled'

const GridLayoutGroup = ({
  children,
  disableRwd,
  noVerticalMargin,
  overflow,
}) => (
  <StyledRowContainer
    $disableRwd={disableRwd}
    $noVerticalMargin={noVerticalMargin}
    $overflow={overflow}
  >
    {children}
  </StyledRowContainer>
)

export default GridLayoutGroup
