import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change, formValueSelector, stopSubmit } from 'redux-form'

import { FEATURE_FLAGS } from 'constants/security'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withNurseriesService } from 'services/nurseries'
import { withRouterUtils } from 'services/utils/router'
import { withPaginationUtils } from 'services/utils/pagination'
import { getInvoicePeriodDropdown } from 'module/Finance/helpers'
import { withRouter } from 'services/router'

import i18n from 'translations'

import { SELECT_CHILDREN_OPTIONS, SELECT_CHILDREN_TYPES } from './constants'

import { getQueryString } from './helpers'

import FinanceGenerateInvoicesView from './FinanceGenerateInvoicesView'
import { GENERATE_INVOICE_FORM } from './components/GenerateInvoicesForm'

const INVOICE_PERIOD_OPTIONS = getInvoicePeriodDropdown()

const NURSERY_INVOICE_SETTINGS_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings.invoice',
    'nurseryInvoiceSettings',
  ],
}

class FinanceGenerateInvoicesContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      childrenPickerRoomValue: null,
      childrenPickerSelectedAll: false,
      childrenPickerValue: [],
      page: 1,
    }
  }

  componentDidMount() {
    const { nurseriesActions, nurseryOptions } = this.props

    nurseriesActions.get(nurseryOptions.id, {
      params: { groups: NURSERY_INVOICE_SETTINGS_GROUPS },
    })
  }

  componentDidUpdate(prevProps) {
    const { invoicePeriodDropdown, selectChildren, updateField } = this.props

    if (
      invoicePeriodDropdown
      && (!prevProps.invoicePeriodDropdown || prevProps.invoicePeriodDropdown.value !== invoicePeriodDropdown.value)
    ) {
      const { endDate, startDate } = invoicePeriodDropdown

      const invoicePeriod = [startDate, endDate]

      updateField('invoicePeriod', invoicePeriod)
    }

    /*
      when you click on select a group of children option
      load the first 20 children
    */
    if (
      selectChildren
      && (!prevProps.selectChildren || selectChildren !== prevProps.selectChildren)
      && SELECT_CHILDREN_TYPES.GROUP === selectChildren
    ) {
      this.setState({ page: 1 }, this.fetch) //eslint-disable-line
    }
  }

  componentWillUnmount() {
    const { childActions } = this.props

    childActions.clear()
  }

  fetch = (fetchParams = {}) => {
    const { childActions, childHelpers, childSelectors } = this.props
    const { childrenPickerRoomValue, page } = this.state
    const { getCriteria } = childSelectors

    const { callback, fetchAllChildren } = fetchParams

    const criteria = getCriteria({ room: childrenPickerRoomValue })
    const params = {
      criteria,
      groups: {},
      page,
    }

    if (fetchAllChildren) {
      childHelpers.getAllChildren(params, page, callback)

      return
    }

    childActions.list({ mergeResult: 1 !== page, params })
  }

  handleSubmit = (fields) => {
    const { injectValidation, navigate } = this.props
    const { childrenPickerValue } = this.state
    const { selectChildren } = fields

    if (SELECT_CHILDREN_TYPES.GROUP === selectChildren && !childrenPickerValue.length) {
      injectValidation(GENERATE_INVOICE_FORM, {
        selectChildren: i18n.t('module:Finance:GenerateInvoices:Form:selectChildren'),
      })

      return
    }

    const query = getQueryString(fields, childrenPickerValue)

    navigate({
      pathname: '/management/finance/bulk/preview',
      query,
    })
  }

  handleRoomChange = (room) => {
    this.setState(
      {
        childrenPickerRoomValue: room ? room.value : null,
        childrenPickerSelectedAll: false,
        childrenPickerValue: [],
        page: 1,
      },
      () => this.fetch(),
    )
  }

  handleChildrenPickerChange = (childrenPickerValue) => {
    this.setState({
      childrenPickerSelectedAll: false,
      childrenPickerValue,
    })
  }

  updateChildrenPickerSelectAll = () => {
    this.setState((prevState) => ({ childrenPickerSelectedAll: !prevState.childrenPickerSelectedAll }))
  }

  updateChildrenPickerValue = (data = []) => {
    const { childListState } = this.props

    const finalChildData = [
      ...(data || []),
      ...(childListState.data || []),
    ]

    this.setState((prevState) => ({
      childrenPickerValue: prevState.childrenPickerSelectedAll
        ? _.map(finalChildData, (child) => child.id)
        : [],
    }))
  }

  handleChildrenPickerSelectAll = () => {
    const { childListState, paginationUtils } = this.props
    const { childrenPickerSelectedAll, page } = this.state

    this.updateChildrenPickerSelectAll()

    if (!childrenPickerSelectedAll) {
      const { getPageCount } = paginationUtils
      const pageCount = getPageCount(childListState.meta.total_results)

      if (page !== pageCount) {
        this.setState({ page: +page + 1 }, () => this.fetch({
          callback: (data) => {
            this.updateChildrenPickerValue(data)
            this.setState({ page: pageCount })
          },
          fetchAllChildren: true,
        }))

        return
      }

      this.updateChildrenPickerValue()

      return
    }

    this.updateChildrenPickerValue()
  }

  handleIssueDateChange = (issueDate) => {
    const { nurseryInvoiceSettingsDueDate, updateField } = this.props

    const newDueDate = moment(issueDate)
      .add('d', nurseryInvoiceSettingsDueDate)
      .toDate()

    updateField('dueDate', newDueDate)
  }

  handlePageChange = (page) => {
    this.setState({ page }, this.fetch)
  }

  render() {
    const {
      childListState,
      errorMessages,
      invoicePeriodDropdown,
      isFetching,
      paginationUtils,
      selectChildren,
    } = this.props
    const { childrenPickerRoomValue, childrenPickerSelectedAll, childrenPickerValue, page } = this.state

    const { getPageCount } = paginationUtils

    const childrenPickerOptions = childListState.data
    const isChildrenPickerVisible = SELECT_CHILDREN_TYPES.GROUP === selectChildren
    const isLoading = isFetching

    const pageCount = getPageCount(childListState.meta.total_results)

    return (
      <FinanceGenerateInvoicesView
        childrenPickerOptions={childrenPickerOptions}
        childrenPickerRoomValue={childrenPickerRoomValue}
        childrenPickerSelectedAll={childrenPickerSelectedAll}
        childrenPickerValue={childrenPickerValue}
        errorMessages={errorMessages}
        invoicePeriodDropdown={invoicePeriodDropdown}
        invoicePeriodOptions={INVOICE_PERIOD_OPTIONS}
        isChildrenPickerVisible={isChildrenPickerVisible}
        isLoading={isLoading}
        page={page}
        pageCount={pageCount}
        selectChildrenOptions={SELECT_CHILDREN_OPTIONS}
        onChildrenPickerChange={this.handleChildrenPickerChange}
        onChildrenPickerSelectAll={this.handleChildrenPickerSelectAll}
        onIssueDateChange={this.handleIssueDateChange}
        onPageChange={this.handlePageChange}
        onRoomChange={this.handleRoomChange}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

FinanceGenerateInvoicesContainer.authParams = {
  flags: [FEATURE_FLAGS.FINANCE_AUTOMATION],
}

const mapState = (state, { appSelectors, childListState, nurseriesSelectors, params }) => ({
  errorMessages: appSelectors.getErrorMessages(childListState),
  invoicePeriodDropdown: formValueSelector(GENERATE_INVOICE_FORM)(state, 'invoicePeriodDropdown'),
  isFetching: appSelectors.getIsFetching(childListState),
  nurseryInvoiceSettingsDueDate: nurseriesSelectors.getDueDate(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  selectChildren: formValueSelector(GENERATE_INVOICE_FORM)(state, 'selectChildren'),
})

const mapDispatch = {
  injectValidation: (formName, data) => stopSubmit(formName, data),
  updateField: (fieldName, fieldValue) => change(GENERATE_INVOICE_FORM, fieldName, fieldValue),
}

const enhance = compose(
  withRouter,
  withAppService,
  withChildService,
  withPaginationUtils,
  withRouterUtils,
  withNurseriesService,
  connect(mapState, mapDispatch),
)

export default enhance(FinanceGenerateInvoicesContainer)
