import React, { PropsWithChildren } from 'react'

import { Button } from 'components'

import { ButtonProps } from '../Button'

const ActionButton: React.FC<PropsWithChildren<ButtonProps>> = ({
  edge,
  icon = 'plus',
  ...rest
}) => (
  <Button
    {...rest}
    edge={edge || 'start'}
    hierarchy="tertiary"
    icon={icon}
    size="small"
  />
)

export default ActionButton
