import styled from 'styled-components'

export const StyledSliderContainer = styled.div`
  width: 756px;
  
  @media(max-width: 810px) {
    width: calc(100vw - 100px);
  }
  
  @media(max-width: 480px) {
    width: calc(100vw - 75px);
  }
`
