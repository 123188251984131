import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change, getFormValues, isDirty, stopSubmit } from 'redux-form'

import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'
import { getBackendErrors } from 'utils/backendErrors'
import { getUsernameFromName } from 'utils/form'
import { generateRoute } from 'utils/routing'
import { hasMoreRecords } from 'utils/pagination'

import { EVENTS, logEvent } from 'analytics'

import { withAppService } from 'services/app'
import { withMembershipsService } from 'services/legacy/memberships'
import { withModalService } from 'services/utils/modal'
import { withNurseriesService } from 'services/nurseries'
import { withSecurityService } from 'services/security'
import { withUploadService } from 'services/legacy/upload'
import { withShellService } from 'services/shell'
import { withRouter } from 'services/router'

import { formattedNurseriesOptions } from 'services/nurseries/list/selectors'

import { Space } from 'components'

import i18n from 'translations'

import withStaffHoc from 'module/Staff/withStaffHoc'

import StaffAddView from './StaffAddView'
import { getAvailableRolesWithTooltip } from './helpers'
import { STAFF_ADD_FORM } from './components/StaffAddForm'

const GROUPS = {
  read: [
    'membership.details',
    'membership.nurseries',
    'nursery',
    'membership.primaryManager',
    'membership.profile',
    'membership.profile.dbsCheck',
    'membership.user',
  ],
}

class StaffAddContainer extends Component {
  constructor(props) {
    super(props)

    const {
      isMyDetailsContext,
      params: { userId },
    } = this.props

    this.state = {
      imageUploading: false,
      isCreatingContext: !userId && !isMyDetailsContext,
      nurseriesPage: 1,
    }
  }

  componentDidMount() {
    const {
      isMyDetailsContext,
      membershipsActions,
      params: { userId },
    } = this.props
    const { isCreatingContext } = this.state

    logEvent(isCreatingContext ? EVENTS.STAFF_ADD_BTN_CLICKED : EVENTS.STAFF_EDIT_PAGE_VIEWED)

    if (userId) {
      return membershipsActions.get(userId, {
        params: { groups: GROUPS },
      })
    }

    if (isMyDetailsContext) {
      return membershipsActions.getMe({
        params: { groups: GROUPS },
      })
    }

    return null
  }

  componentWillUnmount() {
    const {
      isMyDetailsContext,
      membershipsActions,
      params: { userId },
      shellActions,
    } = this.props

    if (!userId && !isMyDetailsContext) {
      shellActions.showSubMenu()
    }

    membershipsActions.clear()
  }

  showNurseriesSelect = () => {
    const { formValues, hasOrganizationAccess, isOrganizationContext, membership } = this.props

    return (
      hasOrganizationAccess
      && isOrganizationContext
      && (formValues?.role?.value === ROLES.ORGANIZATION_LINE_MANAGER
        || (membership?.roles?.includes(ROLES.ORGANIZATION_LINE_MANAGER)))
    )
  }

  uploadFile = async (body) => {
    const { uploadActions } = this.props

    this.setState({ imageUploading: true })

    const url = await uploadActions.uploadFile(body.photo.file)

    this.setState({ imageUploading: false })

    return url
  }

  handleArchive = () => {
    const {
      isMembershipArchived,
      membershipsActions,
      params: { userId },
    } = this.props

    logEvent(EVENTS.STAFF_DEACTIVATE_BTN_CLICKED)

    membershipsActions.update(userId, {
      onFailed: this.handleSubmitFailed,
      onSuccess: this.handleSubmitSuccess,
      payload: { archived: !isMembershipArchived },
    })
  }

  handleResendInvitation = () => {
    const { membership, membershipsActions } = this.props

    membershipsActions.resendInvitation(membership.id)
  }

  handleSubmit = async (fields) => {
    const {
      changeField,
      isAdministrationContext,
      isMe,
      membershipsActions,
      membershipsSelectors,
      params: { userId },
    } = this.props
    const { isCreatingContext } = this.state

    const payload = membershipsSelectors.getFormData(fields, isCreatingContext, isAdministrationContext)

    if (false === payload?.photo?.isUploaded) {
      payload.photo = await this.uploadFile(payload)

      changeField('photo', {
        isUploaded: true,
        value: payload.photo,
      })
    } else {
      payload.photo = payload?.photo?.value
    }

    if (isMe) {
      return membershipsActions.updateMe({
        onFailed: this.handleSubmitFailed,
        onSuccess: this.handleSubmitSuccess,
        params: { groups: GROUPS },
        payload,
      })
    }

    if (userId) {
      return membershipsActions.update(userId, {
        onFailed: this.handleSubmitFailed,
        onSuccess: this.handleSubmitSuccess,
        params: { groups: GROUPS },
        payload,
      })
    }

    return membershipsActions.create({
      onFailed: this.handleSubmitFailed,
      onSuccess: this.handleSubmitSuccess,
      payload,
    })
  }

  generateStaffRoute = () => {
    const { isMyDetailsContext, params: { userId } } = this.props
    const { isCreatingContext } = this.state

    if (isMyDetailsContext) {
      return generateRoute('ACCOUNT.ABOUT')
    }

    if (isCreatingContext) {
      return generateRoute('STAFF.LIST')
    }

    return generateRoute('STAFF.PROFILE.ABOUT', { userId })
  }

  handleSubmitSuccess = ({ data }) => {
    const {
      authAccessMap: { section: { StaffingEnabled } },
      hideModal,
      isNurseryContext,
      membershipsActions,
      modalActions,
      modalConsts,
      navigate,
    } = this.props
    const { isCreatingContext } = this.state

    logEvent(isCreatingContext ? EVENTS.STAFF_ADDED : EVENTS.STAFF_UPDATED)

    if (StaffingEnabled && isCreatingContext && isNurseryContext) {
      membershipsActions.clear()

      return modalActions.show(modalConsts.TYPES.CONFIRM, {
        cancelButtonLabel: i18n.t('global:Skip'),
        confirmButtonLabel: i18n.t('module:Staff:StaffAdd:OnSuccessfulCreation:addContract'),
        hideModal,
        icon: 'staff',
        onCancel: () => navigate(this.generateStaffRoute()),
        onConfirm: () => navigate(generateRoute('STAFF.PROFILE.CONTRACTS.ADD', { userId: data.id })),
        text: (
          <React.Fragment>
            {i18n.t('module:Staff:StaffAdd:OnSuccessfulCreation:staffMemberHasBeenAdded', { name: data.name })}
            <Space space="10px" />
            {i18n.t('module:Staff:StaffAdd:OnSuccessfulCreation:addContractInfo')}
          </React.Fragment>
        ),
      })
    }

    return navigate(this.generateStaffRoute())
  }

  handleSubmitFailed = (response) => {
    const { injectValidation } = this.props

    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    return injectValidation(STAFF_ADD_FORM, errors)
  }

  handleLoadMoreNurseries = async () => {
    const { nurseriesActions } = this.props
    const { nurseriesPage } = this.state

    let result = {}

    await nurseriesActions.list({
      onSuccess: ({ data, meta }) => {
        const { nurseriesOptions } = this.props
        const newNurseriesOptions = formattedNurseriesOptions(data)

        result = {
          hasMore: hasMoreRecords(meta),
          options: [...nurseriesOptions, ...newNurseriesOptions],
        }

        this.setState({ nurseriesPage: nurseriesPage + 1 })
      },
      params: { page: nurseriesPage },
      recurrency: true,
    })

    return result
  }

  handleNameChange = (fieldName, e) => {
    const { changeField, formValues, isUsernameDirty } = this.props

    const { firstName = '', surname = '', username } = formValues || {}

    if (!isUsernameDirty || getUsernameFromName(firstName + surname) === username) {
      const name = 'surname' === fieldName ? firstName + e.target.value : e.target.value + surname

      changeField('username', getUsernameFromName(name))
    }
  }

  render() {
    const {
      authAccessMap: { section: { StaffingEnabled } },
      availableRoles,
      errorMessages,
      formValues,
      initialValues,
      invitation,
      invitationError,
      isAdministrationContext,
      isFetching,
      isMe,
      isMembershipAccepted,
      isMembershipArchived,
      isMyDetailsContext,
      isNurseryContext,
      isOrganizationContext,
      isSubmitting,
      isSuperAdmin,
      membership,
      titlesOptions,
    } = this.props
    const {
      imageUploading,
      isCreatingContext,
    } = this.state

    const rolesOptions = getAvailableRolesWithTooltip(availableRoles)

    return (
      <StaffAddView
        cancelLink={this.generateStaffRoute()}
        errorMessages={errorMessages}
        formValues={formValues}
        initialValues={initialValues}
        invitation={{ ...invitation, error: invitationError }}
        isCreatingContext={isCreatingContext}
        isFetching={isFetching}
        isMe={isMe}
        isMembershipAccepted={isMembershipAccepted}
        isMembershipArchived={isMembershipArchived}
        isMyDetailsContext={isMyDetailsContext}
        isNurseryContext={isNurseryContext}
        isOrganizationContext={isOrganizationContext}
        isPrimaryManager={membership?.isPrimaryManager}
        isStaffingEnabled={StaffingEnabled}
        isSubmitting={isSubmitting || imageUploading}
        rolesOptions={rolesOptions}
        showNurseriesSelect={this.showNurseriesSelect()}
        superAdminCreation={isSuperAdmin && isAdministrationContext}
        titlesOptions={titlesOptions}
        onArchive={this.handleArchive}
        onLoadMoreNurseries={this.handleLoadMoreNurseries}
        onNameChange={this.handleNameChange}
        onResendInvitation={this.handleResendInvitation}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  membershipsSelectors,
  membershipsSingleState,
  nurseriesListState,
  nurseriesSelectors,
  securitySelectors,
}) => ({
  authAccessMap: {
    section: {
      PremiumSection: auth.SELECTORS.getIsAuthorised(state, {
        flags: [FEATURE_FLAGS.OCCUPANCY_ENABLED],
      }),
      StaffingEnabled: auth.SELECTORS.getIsAuthorised(state, {
        flags: [FEATURE_FLAGS.STAFF_REGISTER],
      }),
    },
  },
  authUser: securitySelectors.getAuthUser(state),
  availableRoles: securitySelectors.getAvailableRoles(state),
  errorMessages: appSelectors.getErrorMessages(membershipsSingleState, nurseriesListState),
  formValues: getFormValues(STAFF_ADD_FORM)(state),
  hasOrganizationAccess: securitySelectors.hasOrganizationAccess(state),
  initialValues: membershipsSelectors.getInitialValues(state),
  invitation: membershipsSelectors.getMembershipInvitationSelector(state),
  invitationError: membershipsSelectors.getMembershipInvitationErrorSelector(state),
  isAdministrationContext: securitySelectors.isAdministrationContext(state),
  isFetching: appSelectors.getIsFetching(membershipsSingleState),
  isMe: securitySelectors.getIsMe(state),
  isMembershipAccepted: membershipsSelectors.getMembershipIsAcceptedSelector(state),
  isMembershipArchived: membershipsSelectors.getMembershipIsArchivedSelector(state),
  isNurseryContext: securitySelectors.isNurseryContext(state),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
  isSubmitting: appSelectors.getIsSubmitting(membershipsSingleState),
  isSuperAdmin: securitySelectors.isSuperAdmin(state),
  isUsernameDirty: isDirty(STAFF_ADD_FORM)(state, ['username']),
  membership: membershipsSelectors.getMembershipDataSelector(state),
  nurseriesOptions: nurseriesSelectors.getOrganizationNurseriesOptions(state),
  titlesOptions: appSelectors.getUserTitlesOptions(state),
})

const mapDispatch = {
  changeField: (field, value) => change(STAFF_ADD_FORM, field, value),
  injectValidation: (formName, errors) => stopSubmit(formName, errors),
}

const enhance = compose(
  withAppService,
  withMembershipsService,
  withModalService,
  withNurseriesService,
  withRouter,
  withSecurityService,
  withUploadService,
  withShellService,
  withStaffHoc,
  connect(mapState, mapDispatch),
)

export default enhance(StaffAddContainer)
