import React from 'react'

import { Banner, Callout, Page, Section, Spinner, Typography } from 'components'

import i18n from 'translations'

import { getRoute } from '../ManagementMenuItemsHelpers'
import { getTitle } from './MenuItemsAddHelpers'
import MenuItemsAddForm from './components/MenuItemsAddForm'

const MenuItemsAddView = ({
  data,
  errorMessages,
  initialValues,
  isEdit,
  isFetching,
  isMealsContext,
  isSubmitting,
  isUsedInDiaryEntry,
  onArchiveClick,
  onSubmit,
  prefix,
}) => {
  const { archived } = data || {}

  const cancelLink = getRoute({ isMealsContext })
  const title = getTitle({ isEdit, prefix })

  const renderBanner = () => isEdit && isUsedInDiaryEntry && (
    <Banner.Info margin="0 0 25px">
      <Typography prewrap>
        {i18n.t(prefix.concat(archived ? 'Form:archiveBanner' : 'Form:banner'))}
      </Typography>
    </Banner.Info>
  )

  const renderContent = () => {
    if (isFetching || (isEdit && !data)) {
      return <Spinner />
    }

    return (
      <React.Fragment>
        {renderBanner()}
        <MenuItemsAddForm
          cancelLink={cancelLink}
          disabled={isUsedInDiaryEntry}
          initialValues={initialValues}
          isEdit={isEdit}
          isSubmitting={isSubmitting}
          prefix={prefix}
          onSubmit={onSubmit}
        />
      </React.Fragment>
    )
  }

  const actions = isEdit && !isFetching && data && (
    <Section.Actions
      options={[{
        onClick: onArchiveClick,
        type: archived ? 'unarchive' : 'archive',
      }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isFetching || (isEdit && !data)}
      title={title}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default MenuItemsAddView
