import _ from 'lodash'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { change, formValueSelector, getFormInitialValues, isDirty } from 'redux-form'

import { CHILD_FUNDING_FILTERS } from 'services/legacy/childFunding/constants'
import { FEATURE_FLAGS } from 'constants/security'

import { EVENTS, logEvent } from 'analytics'

import auth from 'utils/auth'
import { toFloat } from 'utils/data'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withNurseriesService } from 'services/nurseries'
import { withInvoicesService } from 'services/legacy/invoices'
import { withChildAdhocSessionsService } from 'services/legacy/childAdhocSessions'
import { withChildFundingService } from 'services/legacy/childFunding'
import { withChildExtraItemsService } from 'services/legacy/childExtraItems'

import i18n from 'translations'

import { FORM_NAME } from './components/AddInvoiceItemModalForm'

import AddInvoiceItemModalView from './AddInvoiceItemModalView'

const CHILD_FUNDING_GROUPS = {
  read: [
    'childFunding.child',
    'childFunding.funding',
    'nurseryFunding',
    'nurseryFunding.fundingType',
    'fundingType',
    'nurseryFunding.settings',
    'nurseryFundingSettings',
  ],
}

const CHILD_ADHOC_SESSIONS_GROUPS = {
  read: [
    'childExtraSession.nurserySession',
    'nurserySession',
  ],
}

class AddInvoiceItemModalContainer extends Component {
  componentDidMount() {
    const {
      childAdhocSessionIsInitialized,
      childAdhocSessionsActions,
      childAdhocSessionsHelpers,
      childFundingHelpers,
      childFundingIsInitialized,
      childId,
      isFinanceAutomationEnabled,
    } = this.props

    if (!childAdhocSessionIsInitialized) {
      const childAdhocSessionCriteria = childAdhocSessionsHelpers.getCriteria({
        archived: false,
        invoiceItem: false,
      }, childId)

      childAdhocSessionsActions.list(childId, {
        criteria: childAdhocSessionCriteria,
        groups: CHILD_ADHOC_SESSIONS_GROUPS,
      }, true)
    }

    if (!isFinanceAutomationEnabled) {
      return
    }

    if (!childFundingIsInitialized) {
      const filters = {
        status: CHILD_FUNDING_FILTERS.ACTIVE,
      }
      const childFundingCriteria = childFundingHelpers.getCriteria(filters, childId)

      childFundingHelpers.getAllChildFundings({ criteria: childFundingCriteria, groups: CHILD_FUNDING_GROUPS })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      cost,
      deposit,
      extraItem,
      extraSession,
      funding,
      isDirtyForm,
      quantity,
      updateField,
    } = this.props

    // NOTE: isDirty is to update total only when user change cost or quantity
    // should not recalculate total on edit mode initialization
    if (isDirtyForm) {
      if ((cost !== prevProps.cost || quantity !== prevProps.quantity) && cost && quantity) {
        this.updateTotal(cost, quantity)
      }

      if (extraSession && (!prevProps.extraSession || extraSession.value !== prevProps.extraSession.value)) {
        updateField('total', extraSession.cost)
      }

      if (funding && (!prevProps.funding || funding.value !== prevProps.funding.value)) {
        updateField('unitPrice', funding.hourlyRate)
      }

      if (extraItem && (!prevProps.extraItem || extraItem.length !== prevProps.extraItem.length)) {
        updateField('total', _.sumBy(extraItem, 'total'))
      }

      if (deposit && (!prevProps.deposit || deposit.value !== prevProps.deposit.value)) {
        updateField('total', Math.abs(deposit.amount))
      }
    }
  }

  updateTotal = (cost, quantity) => {
    const { updateField } = this.props

    const parsedCost = toFloat(cost) || 0
    const parsedQuantity = toFloat(quantity) || 0
    const total = toFloat(parsedCost * parsedQuantity)

    updateField('total', total)
  }

  handleSubmit = (fields) => {
    const { formInitialValues, hideModal, invoicesActions, invoicesSelectors, onAddItemSuccess } = this.props
    const { total, type } = formInitialValues || {}

    let updatedTotal = invoicesSelectors.getTotal(fields.total, fields.type)
    let isNotOriginalType = false

    if (total) {
      const initialValueTotal = invoicesSelectors.getTotal(total, type)
      const fieldTotal = invoicesSelectors.getTotal(fields.total, fields.type)

      updatedTotal = fieldTotal - initialValueTotal
      isNotOriginalType = type.value !== fields.type.value
    }

    invoicesActions.addItem(fields, updatedTotal, total, isNotOriginalType)

    onAddItemSuccess?.(fields)

    logEvent(EVENTS.INVOICE_MANUAL_ITEM_ADDED)

    hideModal()
  }

  deleteItem = () => {
    const {
      formInitialValues,
      hideModal,
      invoicesActions,
      invoicesSelectors,
      itemIndex,
    } = this.props

    const { total, type } = formInitialValues
    const updatedTotal = invoicesSelectors.getTotal(total, type)

    invoicesActions.removeItem(itemIndex.toString(), -updatedTotal)

    hideModal()
  }

  handleDeleteButtonClick = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'trash',
      onConfirm: this.deleteItem,
      text: i18n.t('module:Modals:Invoice:AddInvoiceItemModal:deleteItemPopupText'),
    })
  }

  handleCancelClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  render() {
    const {
      childAdhocSessionOptions,
      childFundingOptions,
      childId,
      errorMessages,
      formInitialValues,
      funding,
      includeUsedDepositIds,
      initialValues,
      invoicesSelectors,
      isDepositEnabled,
      isFinanceAutomationEnabled,
      isItemEdit,
      isReadOnly,
      itemType,
      quantity,
      skipPendingDepositIds,
      total,
    } = this.props

    const lineItemOptions = invoicesSelectors.getLineItemOptions({
      isDepositEnabled,
      isFinanceAutomationEnabled,
    })
    const fundingAllocation = invoicesSelectors.getFundingAllocationValues(funding, quantity, total, formInitialValues)

    return (
      <AddInvoiceItemModalView
        childAdhocSessionOptions={childAdhocSessionOptions}
        childFundingOptions={childFundingOptions}
        childId={childId}
        errorMessages={errorMessages}
        funding={fundingAllocation}
        includeUsedDepositIds={includeUsedDepositIds}
        initialValues={initialValues}
        invoicesSelectors={invoicesSelectors}
        isItemEdit={isItemEdit}
        isReadOnly={isReadOnly}
        itemType={itemType}
        lineItemOptions={lineItemOptions}
        skipPendingDepositIds={skipPendingDepositIds}
        onCancelClick={this.handleCancelClick}
        onDeleteButtonClick={this.handleDeleteButtonClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childAdhocSessionsListState,
  childAdhocSessionsSelectors,
  childExtraItemsListProjectionsState,
  childFundingListState,
  childFundingSelectors,
  invoiceId,
  invoicesPreviewState,
  invoicesSelectors,
  itemIndex,
  readOnly,
}) => ({
  childAdhocSessionIsInitialized: appSelectors.getIsInitialized(childAdhocSessionsListState),
  childAdhocSessionOptions: childAdhocSessionsSelectors.getAdhocSessionOptions(state),
  childExtraItemsIsInitialized: appSelectors.getIsInitialized(childExtraItemsListProjectionsState),
  childFundingIsInitialized: appSelectors.getIsInitialized(childFundingListState),
  childFundingOptions: childFundingSelectors.getDropdownOptions(state),
  cost: formValueSelector(FORM_NAME)(state, 'unitPrice'),
  deposit: formValueSelector(FORM_NAME)(state, 'deposit'),
  extraItem: formValueSelector(FORM_NAME)(state, 'childExtraItemProjections'),
  extraSession: formValueSelector(FORM_NAME)(state, 'childExtraSession'),
  formInitialValues: getFormInitialValues(FORM_NAME)(state),
  funding: formValueSelector(FORM_NAME)(state, 'childFunding'),
  initialValues: invoicesSelectors.getItemInitialValues(itemIndex, readOnly)(state),
  invoiceIsInitialized: appSelectors.getIsInitialized(invoicesPreviewState),
  isDepositEnabled: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.DEPOSITS],
  }),
  isDirtyForm: isDirty(FORM_NAME)(state),
  isEdit: !!invoiceId,
  isFinanceAutomationEnabled: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.FINANCE_AUTOMATION],
  }),
  isItemEdit: !!itemIndex,
  isReadOnly: readOnly,
  itemType: formValueSelector(FORM_NAME)(state, 'type'),
  quantity: formValueSelector(FORM_NAME)(state, 'quantity'),
  total: formValueSelector(FORM_NAME)(state, 'total'),
})

const mapDispatch = {
  updateField: (fieldName, fieldValue) => change(FORM_NAME, fieldName, fieldValue),
}

const enhance = compose(
  withAppService,
  withModalService,
  withNurseriesService,
  withInvoicesService,
  withChildAdhocSessionsService,
  withChildFundingService,
  withChildExtraItemsService,
  connect(mapState, mapDispatch),
)

export default enhance(AddInvoiceItemModalContainer)
