import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_PAYOUTS_LIST_STATISTICS,
  GET_PAYOUTS_LIST_STATISTICS,
  GET_PAYOUTS_LIST_STATISTICS_FAILED,
  GET_PAYOUTS_LIST_STATISTICS_SUCCESS,
} from './constants'

export const getPayoutsStatistics = (
  options: BasicActionOptionsProps,
) => ({ nurseryIntegrationsApiClient }) => generateBasicActions.list({
  constants: {
    failed: GET_PAYOUTS_LIST_STATISTICS_FAILED,
    init: GET_PAYOUTS_LIST_STATISTICS,
    success: GET_PAYOUTS_LIST_STATISTICS_SUCCESS,
  },
  options,
  service: nurseryIntegrationsApiClient,
  serviceMethod: 'getPayoutStatistics',
})

export const clearPayoutsStatistics = () => ({
  type: CLEAR_PAYOUTS_LIST_STATISTICS,
})
