import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Button, Icon } from 'components'

import {
  StyledButton,
  StyledLogOutButton,
  StyledLogoutLabel,
  StyledLogoutWithHelpWrapper,
} from './LogoutWithHelpStyled'

const LogoutWithHelp = ({
  authUser,
  full,
  hiddenOnMobile,
  isOffline,
  logoutLabel,
  onHelpClick,
  onLogout,
}) => {
  if (!authUser) {
    return null
  }

  const renderHelpButton = () => (
    <StyledButton $disabled={isOffline} $full={full}>
      <Button
        color="senary"
        disabled={isOffline}
        icon="help"
        size="small"
        disableElevation
        negativeVerticalMargins
        onClick={onHelpClick}
      />
    </StyledButton>
  )

  const renderLogoutButton = () => (
    <StyledLogOutButton
      onClick={onLogout}
    >
      <StyledLogoutLabel>
        {logoutLabel}
      </StyledLogoutLabel>
      <Icon
        color={NEUTRAL_COLOURS.WHITE}
        height={22}
        icon="logout"
      />
    </StyledLogOutButton>
  )

  return (
    <StyledLogoutWithHelpWrapper hiddenOnMobile={hiddenOnMobile}>
      {renderHelpButton()}
      {renderLogoutButton()}
    </StyledLogoutWithHelpWrapper>
  )
}

export default LogoutWithHelp
