import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_DAILY_DIARY_BOTTLES_WITHOUT_RECORDS,
  GET_DAILY_DIARY_BOTTLES_WITHOUT_RECORDS,
  GET_DAILY_DIARY_BOTTLES_WITHOUT_RECORDS_FAILED,
  GET_DAILY_DIARY_BOTTLES_WITHOUT_RECORDS_SUCCESS,
} from './constants'

export const childrenListWithoutRecords = (
  options: BasicActionOptionsProps,
) => ({ dailyDiaryBottlesApiClient }) => generateBasicActions.list({
  constants: {
    failed: GET_DAILY_DIARY_BOTTLES_WITHOUT_RECORDS_FAILED,
    init: GET_DAILY_DIARY_BOTTLES_WITHOUT_RECORDS,
    success: GET_DAILY_DIARY_BOTTLES_WITHOUT_RECORDS_SUCCESS,
  },
  options,
  service: dailyDiaryBottlesApiClient,
  serviceMethod: 'list',
})

export const clearListWithoutRecords = () => ({
  type: CLEAR_DAILY_DIARY_BOTTLES_WITHOUT_RECORDS,
})
