import React, { useEffect, useRef, useState } from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

import { Typography } from 'components'

import i18n from 'translations'

import { StyledTextWrapper } from './DescriptionStyled'

export const DESCRIPTION_LINE_HEIGHT = 18

const Description = ({ content }) => {
  const [collapsed, setCollapsed] = useState(false)
  const [hiddenButton, setHiddenButton] = useState(false)
  const descriptionWrapper = useRef(null)

  useEffect(() => {
    setCollapsed(false)

    setTimeout(() => {
      const divHeight = descriptionWrapper.current?.offsetHeight
      const lines = divHeight / DESCRIPTION_LINE_HEIGHT

      setCollapsed(true)

      setHiddenButton(1 === lines)
    })
  }, [descriptionWrapper, content])

  return (
    <div
      onClick={() => setCollapsed(!collapsed)}
    >
      <Typography
        color={NEUTRAL_COLOURS.GRAY}
        cursor={hiddenButton ? 'pointer' : 'default'}
        margin="-20px 0 0 0"
        padding="0 0 0 60px"
        variant="div"
      >
        <StyledTextWrapper collapsed={collapsed} ref={descriptionWrapper}>
          {content}
        </StyledTextWrapper>
      </Typography>
      {!hiddenButton && (
        <Typography
          color={getBrandingColor('primary-color')}
          cursor="pointer"
          margin="5px 0 0"
          padding="0 0 0 60px"
        >
          {collapsed ? i18n.t('global:More') : i18n.t('global:Hide')}
        </Typography>
      )}
    </div>
  )
}

export default Description
