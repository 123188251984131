import _ from 'lodash'

import React, { useCallback, useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'
import { formValueSelector, isValid as isValidForm, setSubmitFailed } from 'redux-form'

import { RootState } from 'core/reducers'
import { ModalType } from 'modals'
import { ChildProductPriceCalculatorDataState, ChildProductPriceCalculatorState } from 'services/child/models'
import { ChildProductTypes } from 'services/booking/childProducts/constants'
import { ROLES } from 'constants/security'
import { WrappedComponentType } from 'constants/types'
import { SESSION_TYPE } from 'services/legacy/childAdhocSessions/constants'

import { generateRoute } from 'utils/routing'

import { withAppService, withAppServiceProps } from 'services/app'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withChildService, withChildServiceProps } from 'services/legacy/child'
import { withChildProductsService, withChildProductsServiceProps } from 'services/booking/childProducts'
import { withSnackbarService, withSnackbarServiceProps } from 'services/utils/snackbar'
import { withRouter, withRouterProps } from 'services/router'

import i18n from 'translations'

import { getInitialValues } from './helpers'
import AddView from './AddView'
import { ADD_ONE_OFF_BOOKING_FORM, AddFormFieldProps } from './components/AddForm'

const ONE_OFF_BOOKING_GROUPS = {
  read: [
    'oneOffChildProduct.child',
    'child',
    'oneOffChildProduct.product',
    'oneOffChildProduct.invoice',
    'nurserySessionProduct',
    'nurseryItemProduct',
    'nurseryDiscountProduct',
    'nurseryFundingProduct',
    'oneOffChildProduct.settings',
    'oneOffChildProductSettings',
    'nurseryDiscountProduct.settings',
    'nurseryDiscountProductSettings',
    'oneOffChildProduct.price',
    'oneOffChildProduct.requestExtraSession',
  ],
}

type AddContainerProps =
  & withRouterProps
  & withAppServiceProps
  & withChildServiceProps
  & withModalServiceProps
  & withChildProductsServiceProps
  & withSnackbarServiceProps

const mapDispatch = {
  setSubmitFormFailed: (formName, fieldName) => setSubmitFailed(formName, fieldName),
}

const mapState = (state: RootState, {
  appSelectors,
  childProductsSingleState,
  childSelectors,
}: AddContainerProps) => ({
  child: childSelectors.getChildSelector(state),
  date: formValueSelector(ADD_ONE_OFF_BOOKING_FORM)(state, 'date'),
  endTime: formValueSelector(ADD_ONE_OFF_BOOKING_FORM)(state, 'endTime'),
  errorMessages: appSelectors.getErrorMessages(childProductsSingleState),
  includeConsumables: formValueSelector(ADD_ONE_OFF_BOOKING_FORM)(state, 'includeConsumables'),
  isSubmitting: appSelectors.getIsSubmitting(childProductsSingleState),
  isValid: isValidForm(ADD_ONE_OFF_BOOKING_FORM)(state),
  item: formValueSelector(ADD_ONE_OFF_BOOKING_FORM)(state, 'item'),
  quantity: formValueSelector(ADD_ONE_OFF_BOOKING_FORM)(state, 'quantity'),
  selectedChild: formValueSelector(ADD_ONE_OFF_BOOKING_FORM)(state, 'child'),
  startTime: formValueSelector(ADD_ONE_OFF_BOOKING_FORM)(state, 'startTime'),
  type: formValueSelector(ADD_ONE_OFF_BOOKING_FORM)(state, 'type'),
})

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

const AddContainer: WrappedComponentType<AddContainerProps & PropsFromRedux> = ({
  child,
  // @ts-ignore
  childActions,
  childProductsActions,
  childProductsSelectors,
  // @ts-ignore
  childSelectors,
  date,
  endTime,
  errorMessages,
  includeConsumables,
  isSubmitting,
  isValid,
  item,
  modalActions,
  navigate,
  params,
  quantity,
  selectedChild,
  setSubmitFormFailed,
  snackbarActions,
  startTime,
  type,
}) => {
  const { childId, oneOffBookingId } = params || {}

  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<AddFormFieldProps>()
  const [isArchived, setIsArchived] = useState<boolean>(false)
  const [isInvoiced, setIsInvoiced] = useState<boolean>(false)
  const [priceCalculator, setPriceCalculator] = useState<ChildProductPriceCalculatorDataState>()
  const [isFetchingPrice, setIsFetchingPrice] = useState<boolean>(false)
  const [callCalculatorApi, setCallCalculatorApi] = useState<boolean>(false)
  const [newQuantity, setNewQuantity] = useState<number>()
  const [linkedToRequestedExtraSession, setLinkedToRequestedExtraSession] = useState<boolean>(false)

  const updateNewQuantity = useCallback(_.debounce((qty) => {
    setNewQuantity(qty)
  }, 500), [])

  useEffect(() => {
    if (oneOffBookingId) {
      setIsFetching(true)

      childProductsActions.get({
        onSuccess: (response) => {
          setIsFetching(false)
          setIsArchived(response.data.archived)
          setInitialValues(getInitialValues(true, response))
          setIsInvoiced(!!response.data.invoice?.id)
          setPriceCalculator(response.data.price)
          setLinkedToRequestedExtraSession(response.data?.requestExtraSession?.type === SESSION_TYPE.PARENT)
        },
        onlyData: true,
        params: [oneOffBookingId, {
          groups: ONE_OFF_BOOKING_GROUPS,
        }],
      })
    } else {
      setInitialValues(getInitialValues(false, null, child))
    }

    return () => {
      childProductsActions.clear()
    }
  }, [])

  useEffect(() => {
    if (item && quantity) {
      if (type.value === ChildProductTypes.EXTRA_SESSION) {
        if (item?.hourly) {
          if (startTime && endTime) {
            setCallCalculatorApi(true)
          }
        } else {
          setCallCalculatorApi(true)
        }
      }

      if (type.value === ChildProductTypes.EXTRA_ITEM) {
        setCallCalculatorApi(true)
      }
    }
  }, [date, item, newQuantity, includeConsumables, startTime, endTime])

  useEffect(() => {
    if (undefined !== quantity) {
      updateNewQuantity(+quantity)
    }
  }, [quantity])

  useEffect(() => {
    setPriceCalculator(null)
  }, [type, item])

  useEffect(() => {
    if (callCalculatorApi) {
      if (startTime && endTime && endTime <= startTime) {
        setPriceCalculator(null)
        setCallCalculatorApi(false)
        setSubmitFormFailed(ADD_ONE_OFF_BOOKING_FORM, 'endTime')
      } else {
        setIsFetchingPrice(true)
        const body = childSelectors.getProductPriceCalculatorBody({
          date,
          endTime,
          includeConsumables,
          item,
          quantity,
          startTime,
          type,
        })

        childActions.getProductPriceCalculator({
          body,
          onSuccess: (response: ChildProductPriceCalculatorState) => {
            setPriceCalculator(response.data)
            setIsFetchingPrice(false)
          },
          params: [childId || selectedChild?.value || initialValues?.child?.value, {}],
        })

        setCallCalculatorApi(false)
      }
    }
  }, [callCalculatorApi])

  const goToList = () => {
    const route = childId
      ? generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.ONE_OFF_BOOKINGS', { childId })
      : generateRoute('FINANCE.ONE_OFF_BOOKINGS')

    navigate(route)
  }

  const handleCreateSuccess = () => {
    snackbarActions.show({
      message: i18n.t('module:Finance:OneOffBookings:Form:snackBarMessage', {
        action: oneOffBookingId ? i18n.t('global:Updated') : i18n.t('global:Created'),
      }),
    })

    goToList()
  }

  const handleSubmit = (fields) => {
    const payload = childProductsSelectors.getPayload(oneOffBookingId, fields)

    if (oneOffBookingId) {
      childProductsActions.update({
        body: payload,
        onSuccess: handleCreateSuccess,
        params: [oneOffBookingId],
      })

      return
    }

    childProductsActions.create({
      body: payload,
      onSuccess: handleCreateSuccess,
      params: [{}],
    })
  }

  const confirmArchiveClick = () => {
    childProductsActions.update({
      body: { archived: !isArchived },
      onSuccess: goToList(),
      params: [oneOffBookingId],
    })
  }

  const handleArchiveClick = () => {
    modalActions.show<ModalType.CONFIRM>(ModalType.CONFIRM, {
      icon: 'archive',
      onConfirm: confirmArchiveClick,
      text: i18n.t('module:Finance:OneOffBookings:Form:archiveConfirm', {
        action: isArchived ? i18n.t('unarchive') : i18n.t('archive'),
      }),
    })
  }

  return (
    <AddView
      childId={childId}
      errorMessages={errorMessages}
      initialValues={initialValues}
      isArchived={isArchived}
      isChildContext={!!childId}
      isEdit={!!oneOffBookingId}
      isFetchingPrice={isFetchingPrice}
      isInvoiced={isInvoiced}
      isLoading={isFetching}
      isSubmitting={isSubmitting}
      isValid={isValid}
      linkedToRequestedExtraSession={linkedToRequestedExtraSession}
      priceCalculator={priceCalculator}
      onArchiveClick={handleArchiveClick}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withAppService,
  withChildProductsService,
  withModalService,
  withRouter,
  withChildService,
  withSnackbarService,
  connector,
)

AddContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.DEPUTY_MANAGER,
  ],
}

export default enhance(AddContainer)
