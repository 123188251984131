import React from 'react'

import { Callout, Page, Section, Spinner } from 'components'

import i18n from 'translations'

import AddForm from './components/AddForm'

const AddView = ({
  errorMessages,
  isEditMode,
  isLoading,
  onDeleteClick,
  type,
  ...rest
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <AddForm
        isEditMode={isEditMode}
        type={type}
        {...rest}
      />
    )
  }

  const actions = isEditMode && (
    <Section.Actions
      options={[{
        onClick: onDeleteClick,
        type: 'delete',
      }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title={i18n.t(`module:Management:Enquiries:EnquiryPipeline:Add:${isEditMode ? 'editTitle' : 'addTitle'}`)}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default AddView
