import { LOCATION_CHANGE } from 'react-router-redux'

const initialState = {
  locationBeforeTransitions: null,
  navCount: 1,
  previousState: null,
}

export interface RouterCommonState {
  /**
   * This reducer will update the state with the most recent location history
   * has transitioned to. This may not be in sync with the router, particularly
   * if you have asynchronously-loaded routes, so reading from and relying on
   * this state is discouraged.
   */
  locationBeforeTransitions: any

  /**
   * For some reason first location change on page load on router is always POP
   */
  navCount: number
  previousState: any
}

export default (state = initialState, { payload, type }) => {
  if (type === LOCATION_CHANGE) {
    let previousState = null
    let updatedNavCount = state.navCount

    if (state.locationBeforeTransitions) {
      previousState = state.locationBeforeTransitions
    }

    if ('POP' === payload.action) {
      updatedNavCount = state.navCount - 1
    } else if ('PUSH' === payload.action && !/^\/login(\/\S)*$/.test(payload.pathname)) {
      updatedNavCount = state.navCount + 1
    }

    return {
      locationBeforeTransitions: payload,
      navCount: updatedNavCount,
      previousState,
    }
  }

  return state
}
