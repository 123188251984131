import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { ROLES } from 'constants/security'

import { withAppService } from 'services/app'
import { withFinanceDepositsReportService } from 'services/legacy/financeDepositsReport'
import { withPaginationUtils } from 'services/utils/pagination'
import { withSecurityService } from 'services/security'
import { withRouter } from 'services/router'

import { useBasicReport } from 'hooks/reports'

import { DatePicker, Form, ReportView, Toolbar } from 'components'

import i18n from 'translations'

import { getColumns, getStatistics, getTableData } from './DepositsHelper'

const CHILDREN_BALANCE_GROUPS = {
  read: [
    'child',
  ],
}

const NURSERIES_BALANCE_GROUPS = {
  read: [
    'nursery',
  ],
}

const DepositsContainer = ({
  childrenData,
  childrenTotalResults,
  errorMessages,
  financeDepositsReportActions,
  financeDepositsReportSelectors,
  isFetching,
  isOrganizationContext,
  isStatisticsFetching,
  location,
  nurseriesData,
  nurseriesTotalResults,
  paginationUtils,
  setLocationQuery,
  statisticsData,
}) => {
  const {
    dateRange,
    onDateChange,
    onPageChange,
    page,
    pageCount,
    perPage,
    statistics,
    tableColumns,
    tableData,
    totalResults,
  } = useBasicReport({
    actions: financeDepositsReportActions,
    childrenApiGroups: CHILDREN_BALANCE_GROUPS,
    childrenData,
    childrenTotalResults,
    errorMessages,
    getColumns,
    getStatistics,
    getTableData,
    isOrganizationContext,
    location,
    nurseriesApiGroups: NURSERIES_BALANCE_GROUPS,
    nurseriesData,
    nurseriesTotalResults,
    paginationUtils,
    selectors: financeDepositsReportSelectors,
    setLocationQuery,
    statisticsData,
  })

  return (
    <ReportView.DetailPage
      description={isOrganizationContext
        ? i18n.t('module:Finance:Reports:Deposits:Organization:description')
        : i18n.t('module:Finance:Reports:Deposits:description')}
      errorMessages={errorMessages}
      filters={(
        <Toolbar bottomLine>
          <Toolbar.Group>
            <Toolbar.Item>
              <Form.Row
                label={i18n.t('module:Finance:Reports:Deposits:Filters:DepositRefundDate:label')}
                verticalLabel
              >
                <DatePicker
                  value={dateRange}
                  clearable
                  range
                  onChange={onDateChange}
                />
              </Form.Row>
            </Toolbar.Item>
          </Toolbar.Group>
        </Toolbar>
      )}
      isOrganizationContext={isOrganizationContext}
      isPageLoading={isFetching && isStatisticsFetching && 1 === page}
      isStatisticsLoading={isStatisticsFetching}
      isTableLoading={isFetching}
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      statistics={statistics}
      statisticsGap="60px"
      tableColumns={tableColumns}
      tableData={tableData}
      title={i18n.t('module:Finance:Reports:Deposits:title')}
      totalResults={totalResults}
      hideExport
      onPageChange={onPageChange}
    />
  )
}

const mapState = (state, {
  appSelectors,
  financeDepositsReportChildrenState,
  financeDepositsReportNurseriesState,
  financeDepositsReportSelectors,
  financeDepositsReportStatisticsState,
  securitySelectors,
}) => ({
  childrenData: financeDepositsReportSelectors.getFinanceDepositsReportChildrenData(state),
  childrenTotalResults: appSelectors.getTotalResults(financeDepositsReportChildrenState),
  errorMessages: appSelectors.getErrorMessages(
    financeDepositsReportChildrenState,
    financeDepositsReportNurseriesState,
    financeDepositsReportStatisticsState,
  ),
  isFetching: appSelectors.getIsFetching(
    financeDepositsReportChildrenState,
    financeDepositsReportNurseriesState,
  ),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
  isStatisticsFetching: appSelectors.getIsFetching(financeDepositsReportStatisticsState),
  nurseriesData: financeDepositsReportSelectors.getFinanceDepositsReportNurseriesData(state),
  nurseriesTotalResults: appSelectors.getTotalResults(financeDepositsReportNurseriesState),
  statisticsData: financeDepositsReportSelectors.getFinanceDepositsReportStatisticsData(state),
})

DepositsContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
  ],
}

const enhance = compose(
  withRouter,
  withAppService,
  withFinanceDepositsReportService,
  withPaginationUtils,
  withSecurityService,
  connect(mapState),
)

export default enhance(DepositsContainer)
