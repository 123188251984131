import React, { useEffect } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { ERROR_CODES } from 'services/legacy/invoices/constants'

import { generateRoute } from 'utils/routing'

import { withInvoicePaymentsService } from 'services/legacy/invoicePayments'
import { withModalService } from 'services/utils/modal'
import { withAppService } from 'services/app'
import { withRouter } from 'services/router'

import i18n from 'translations'

import PaymentDetailsView from './PaymentDetailsView'

const GROUPS = {
  read: [
    'payment.timelineEntries',
    'payment.paymentDetails',
    'paymentDetails',
    'payment.child',
    'child',
  ],
}

const PaymentDetailsContainer = ({
  errorMessages,
  invoicePayment,
  invoicePaymentsActions,
  isFetching,
  modalActions,
  modalConsts,
  navigate,
  params,
}) => {
  const { paymentId } = params

  useEffect(() => {
    invoicePaymentsActions.get(paymentId, {
      groups: GROUPS,
    })

    return () => {
      invoicePaymentsActions.clearSingle()
    }
  }, [paymentId])

  const handleRemovePaymentSuccess = () => {
    modalActions.show(modalConsts.TYPES.ALERT, {
      text: i18n.t('module:Finance:Payments:Remove:successAlert'),
    })

    navigate(generateRoute('FINANCE.PAYMENTS'))
  }

  const handleRemovePaymentFailure = () => (
    modalActions.show(modalConsts.TYPES.ALERT, {
      text: i18n.t('module:Finance:Payments:Remove:failureAlert'),
    })
  )

  const redirectToContact = () => {
    navigate(`/children/${invoicePayment?.child?.id}/finance/contact`)
  }

  const handleSendPaymentFailed = (error) => {
    const { code, message } = error

    if (ERROR_CODES.MISSING_PAYMENT_CONTACT === code) {
      return modalActions.show(modalConsts.TYPES.CONFIRM, {
        confirmButtonLabel: i18n.t('global:AssignContact'),
        onConfirm: redirectToContact,
        text: i18n.t('module:Finance:Payments:Resend:missingContact', { childName: invoicePayment?.child?.name }),
      })
    }

    return modalActions.show(modalConsts.TYPES.ALERT, {
      text: message,
    })
  }

  const handleSendPaymentSuccess = () => {
    navigate(generateRoute('FINANCE.PAYMENTS'))

    modalActions.show(modalConsts.TYPES.ALERT, {
      text: i18n.t('module:Finance:Payments:Resend:successAlert'),
    })
  }

  const handleSendPayment = () => (
    invoicePaymentsActions.send(paymentId, handleSendPaymentSuccess, handleSendPaymentFailed)
  )

  const handleRemovePaymentConfirmed = () => (
    invoicePaymentsActions.remove(paymentId, handleRemovePaymentSuccess, handleRemovePaymentFailure)
  )

  const handlePaymentRemove = () => {
    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'trash',
      onConfirm: () => handleRemovePaymentConfirmed(paymentId),
      text: i18n.t('module:Finance:Payments:Remove:confirm'),
    })
  }

  const handleDownloadPayment = () => (
    invoicePaymentsActions.download(paymentId)
  )

  return (
    <PaymentDetailsView
      errorMessages={errorMessages}
      invoicePayment={invoicePayment}
      isFetching={isFetching}
      onDownloadPayment={handleDownloadPayment}
      onRemovePayment={handlePaymentRemove}
      onSendPayment={handleSendPayment}
    />
  )
}

const mapState = (state, {
  appSelectors,
  invoicePaymentsSelectors,
  invoicePaymentsSingleState,
}) => ({
  errorMessages: appSelectors.getErrorMessages(invoicePaymentsSingleState),
  invoicePayment: invoicePaymentsSelectors.getInvoicePaymentsSingleData(state),
  isFetching: appSelectors.getIsFetching(invoicePaymentsSingleState),
})

const enhance = compose(
  withAppService,
  withModalService,
  withInvoicePaymentsService,
  withRouter,
  connect(mapState),
)

export default enhance(PaymentDetailsContainer)
