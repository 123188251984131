import CommentDrawer from './CommentDrawer'
import CommentDrawerFooter from './CommentDrawerFooter'
import CommentDrawerForm from './CommentDrawerForm'
import CommentDrawerDetailsHeader from './CommentDrawerDetailsHeader'
import CommentDrawerList from './CommentDrawerList'
import CommentDrawerListItem from './CommentDrawerListItem'

CommentDrawer.Footer = CommentDrawerFooter
CommentDrawer.Form = CommentDrawerForm
CommentDrawer.DetailsHeader = CommentDrawerDetailsHeader
CommentDrawer.List = CommentDrawerList
CommentDrawer.List.Item = CommentDrawerListItem

export default CommentDrawer
