import { generateBasicActions } from 'utils/service'

import {
  CLEAR_FORMATIVE_REPORTS,
  CREATE_FORMATIVE_REPORTS,
  CREATE_FORMATIVE_REPORTS_AGE_BANDS,
  CREATE_FORMATIVE_REPORTS_AGE_BANDS_FAILED,
  CREATE_FORMATIVE_REPORTS_AGE_BANDS_SUCCESS,
  CREATE_FORMATIVE_REPORTS_COMMENTS,
  CREATE_FORMATIVE_REPORTS_COMMENTS_FAILED,
  CREATE_FORMATIVE_REPORTS_COMMENTS_SUCCESS,
  CREATE_FORMATIVE_REPORTS_FAILED,
  CREATE_FORMATIVE_REPORTS_SUCCESS,
  EXPORT_FORMATIVE_REPORTS,
  EXPORT_FORMATIVE_REPORTS_FAILED,
  EXPORT_FORMATIVE_REPORTS_SUCCESS,
  GET_FORMATIVE_REPORTS,
  GET_FORMATIVE_REPORTS_FAILED,
  GET_FORMATIVE_REPORTS_SUCCESS,
  REMOVE_FORMATIVE_REPORTS,
  REMOVE_FORMATIVE_REPORTS_FAILED,
  REMOVE_FORMATIVE_REPORTS_SUCCESS,
  UPDATE_FORMATIVE_REPORTS,
  UPDATE_FORMATIVE_REPORTS_AGE_BANDS,
  UPDATE_FORMATIVE_REPORTS_AGE_BANDS_FAILED,
  UPDATE_FORMATIVE_REPORTS_AGE_BANDS_SUCCESS,
  UPDATE_FORMATIVE_REPORTS_COMMENTS,
  UPDATE_FORMATIVE_REPORTS_COMMENTS_FAILED,
  UPDATE_FORMATIVE_REPORTS_COMMENTS_SUCCESS,
  UPDATE_FORMATIVE_REPORTS_FAILED,
  UPDATE_FORMATIVE_REPORTS_SUCCESS,
} from './constants'

export const exportReport = (params, onSuccess) => ({ formativeReportsApiClient }) => (dispatch) => {
  dispatch({ type: EXPORT_FORMATIVE_REPORTS })

  formativeReportsApiClient.exportReport(params).then(
    () => {
      dispatch({ type: EXPORT_FORMATIVE_REPORTS_SUCCESS })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: EXPORT_FORMATIVE_REPORTS_FAILED,
      })
    },
  )
}

export const getFormativeReport = (id, params, onSuccess, onFailed) => ({
  formativeReportsApiClient,
}) => (dispatch) => {
  dispatch({ id, type: GET_FORMATIVE_REPORTS })

  formativeReportsApiClient.get(id, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: GET_FORMATIVE_REPORTS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: GET_FORMATIVE_REPORTS_FAILED,
      })

      if (onFailed) {
        onFailed(error)
      }
    },
  )
}

export const createReport = (payload, onSuccess, onFailed) => ({ formativeReportsApiClient }) => (dispatch) => {
  dispatch({ type: CREATE_FORMATIVE_REPORTS })

  formativeReportsApiClient.createReport(payload).then(
    (response) => {
      dispatch({ type: CREATE_FORMATIVE_REPORTS_SUCCESS })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: CREATE_FORMATIVE_REPORTS_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const updateReport = (id, params, payload, onSuccess, onFailed) => ({
  formativeReportsApiClient,
}) => (dispatch) => {
  dispatch({ type: UPDATE_FORMATIVE_REPORTS })

  formativeReportsApiClient.updateReport(id, params, payload).then(
    (response) => {
      dispatch({ payload: response, type: UPDATE_FORMATIVE_REPORTS_SUCCESS })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: UPDATE_FORMATIVE_REPORTS_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const updateAgeBands = (options = {}) => ({ formativeReportsApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_FORMATIVE_REPORTS_AGE_BANDS_FAILED,
      init: UPDATE_FORMATIVE_REPORTS_AGE_BANDS,
      success: UPDATE_FORMATIVE_REPORTS_AGE_BANDS_SUCCESS,
    },
    options,
    service: formativeReportsApiClient,
    serviceMethod: 'updateAgeBands',
  })
)

export const createAgeBands = (options = {}) => ({ formativeReportsApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: CREATE_FORMATIVE_REPORTS_AGE_BANDS_FAILED,
      init: CREATE_FORMATIVE_REPORTS_AGE_BANDS,
      success: CREATE_FORMATIVE_REPORTS_AGE_BANDS_SUCCESS,
    },
    options,
    service: formativeReportsApiClient,
    serviceMethod: 'createAgeBands',
  })
)

export const createComment = (options = {}) => ({ formativeReportsApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: CREATE_FORMATIVE_REPORTS_COMMENTS_FAILED,
      init: CREATE_FORMATIVE_REPORTS_COMMENTS,
      success: CREATE_FORMATIVE_REPORTS_COMMENTS_SUCCESS,
    },
    options,
    service: formativeReportsApiClient,
    serviceMethod: 'createComment',
  })
)

export const updateComments = (id, payload, onSuccess, onFailed) => ({ formativeReportsApiClient }) => (dispatch) => {
  dispatch({ type: UPDATE_FORMATIVE_REPORTS_COMMENTS })

  formativeReportsApiClient.updateComments(id, payload).then(
    (response) => {
      dispatch({ payload: response, type: UPDATE_FORMATIVE_REPORTS_COMMENTS_SUCCESS })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: UPDATE_FORMATIVE_REPORTS_COMMENTS_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const remove = (id, onSuccess, onFailed) => ({ formativeReportsApiClient }) => (dispatch) => {
  dispatch({ type: REMOVE_FORMATIVE_REPORTS })

  formativeReportsApiClient.removeReport(id).then(
    (response) => {
      dispatch({ payload: response, type: REMOVE_FORMATIVE_REPORTS_SUCCESS })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: REMOVE_FORMATIVE_REPORTS_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const clear = () => ({
  type: CLEAR_FORMATIVE_REPORTS,
})
