import _ from 'lodash'
import moment from 'moment'
import { v4 } from 'uuid'
import { flatten } from 'utils/flatnest'

import React, { useCallback, useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'
import { change, getFormValues, stopSubmit } from 'redux-form'

import { BasicModalProps } from 'modals'
import {
  Allocation,
  AllocationDefault,
  AllocationTime,
  ChildProductRegularAllocatedFunding,
  ChildProductRegularSession,
  ExcludedFundingReasons,
  ExcludedFundingReasonsType,
  RedistributeRoleType,
} from 'services/booking/childBooking/constants'
import { NurseryFundingProductSettings, NurseryFundingProductType } from 'services/product/nurseryFundingV3/constants'
import { ChildProductTypes } from 'services/booking/childProducts/constants'
import { Option } from 'constants/models'
import { Child } from 'services/child/models'
import {
  REGULAR_BOOKINGS_ADD_FORM,
  RegularBookingsAddFormValues,
} from 'module/Children/Child/ChildBookingPattern/RegularBookings/RegularBookingsAdd/components/RegularBookingsAddForm/RegularBookingsAddForm' // eslint-disable-line
import { DEFAULT_DATE_FORMAT, DaysOfWeek } from 'constants/date'
import { FundingSummary } from 'services/child/fundingSummaryCalculator/constants'
import { PriceChange } from 'services/product/constants'

import { getBackendErrors } from 'utils/backendErrors'
import { getDatesBetweenDateRange, hoursAndMinutesToFloat, isSameDay } from 'utils/date'

import { withChildService, withChildServiceProps } from 'services/legacy/child'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withSnackbarService, withSnackbarServiceProps } from 'services/utils/snackbar'
import { withChildBookingService, withChildBookingServiceProps } from 'services/booking/childBooking'
import { withNurseryFundingV3Service, withNurseryFundingV3ServiceProps } from 'services/product/nurseryFundingV3'
import { getCurrentPriceFromPriceChanges } from 'services/product/selectors'
import { withRouter, withRouterProps } from 'services/router'

import i18n from 'translations'

import AddFundingToRegularBookingsModalView from './AddFundingToRegularBookingsModalView'
import {
  ADD_FUNDING_TO_REGULAR_BOOKINGS_MODAL_FORM,
  AddFundingToRegularBookingsModalFormValues,
} from './components/AddFundingToRegularBookingsModalForm'

const GROUPS = {
  read: [
    'regularChildProduct.product',
    'childFundingCalculationSummary',
    'nurserySessionProduct',
  ],
}

const NURSERY_FUNDING_GROUPS = {
  read: [
    'productPrice',
    'productPriceChange',
    'productPriceChange.prices',
    'nurseryFundingProduct.priceChanges',
    'nurseryFundingProduct.settings',
    'nurseryFundingProductSettings',
  ],
}

const SUMMARY_GROUPS = {
  read: [
    'childFundingSummary',
    'childFundingCalculationSummary',
  ],
}

export interface AddFundingToRegularBookingsModalProps {
  alternate: number
  child: Child
  editData?: ChildProductRegularAllocatedFunding
  endDate: moment.Moment
  onSuccess: (
    values: AddFundingToRegularBookingsModalFormValues,
    allocations: Allocation[],
    allocationsDefault: AllocationDefault[],
  ) => void
  sessions: ChildProductRegularSession[]
  startDate: moment.Moment
  totalAlternates: number
}

const mapDispatch = {
  changeField: (field, value) => change(ADD_FUNDING_TO_REGULAR_BOOKINGS_MODAL_FORM, field, value),
  injectValidation: (data) => stopSubmit(ADD_FUNDING_TO_REGULAR_BOOKINGS_MODAL_FORM, data),
}

const mapState = (state) => ({
  formValues: getFormValues(
    ADD_FUNDING_TO_REGULAR_BOOKINGS_MODAL_FORM,
  )(state) as AddFundingToRegularBookingsModalFormValues,
  formValuesWholeForm: getFormValues(REGULAR_BOOKINGS_ADD_FORM)(state) as RegularBookingsAddFormValues,
})

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type AddFundingToRegularBookingsModalContainerFullProps = PropsFromRedux
  & AddFundingToRegularBookingsModalProps
  & BasicModalProps
  & withModalServiceProps
  & withSnackbarServiceProps
  & withChildServiceProps
  & withChildBookingServiceProps
  & withNurseryFundingV3ServiceProps
  & withRouterProps

export interface OpenedAllocation {
  id: string
  isDefault: boolean
  timeIndex: number
}

const AddFundingToRegularBookingsModalContainer: React.FC<AddFundingToRegularBookingsModalContainerFullProps> = ({
  alternate,
  changeField,
  child,
  childActions,
  childBookingSelectors,
  editData,
  endDate,
  formValues,
  formValuesWholeForm,
  hideModal,
  injectValidation,
  nurseryFundingV3Actions,
  onSuccess,
  params: routeParams,
  sessions,
  snackbarActions,
  startDate,
  totalAlternates,
}) => {
  const [fundingAllocations, setFundingAllocations] = useState<Allocation[]>([])
  const [defaultFundingAllocations, setDefaultFundingAllocations] = useState<AllocationDefault[]>([])
  const [sessionAllocations, setSessionAllocations] = useState<AllocationDefault[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [errorMessages, setErrorMessages] = useState<string[] | null>()
  const [openedAllocation, setOpenedAllocation] = useState<OpenedAllocation | null>(null)
  const [initialValues, setInitialValues] = useState<AddFundingToRegularBookingsModalFormValues>(null)
  const [showRecalculateButton, setShowRecalculateButton] = useState<boolean>(false)
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const [isFetchingSummaryFunding, setIsFetchingSummaryFunding] = useState<boolean>(false)
  const [summaryFunding, setSummaryFunding] = useState<FundingSummary>(null)
  const [allocateHoursWithinSessionTimesError, setAllocateHoursWithinSessionTimesError] = useState<boolean>(false)
  const [formHoursPerWeek, setFormHoursPerWeek] = useState<number | null>()
  const [formHoursPerDay, setFormHoursPerDay] = useState<number | null>()
  const [excludedFundingReasons, setExcludedFundingReasons] = useState<ExcludedFundingReasons[]>([])

  const usedNurseryFundings = _.map(
    _.filter(formValuesWholeForm?.childProducts, ({ alternate: alternateWeek, type }) => (
      type === ChildProductTypes.REGULAR_ALLOCATED_FUNDING
    ) && alternate === alternateWeek),
    (item) => item.product.id,
  )

  const debounceUpdateDayState = useCallback(
    _.debounce((value, cb) => {
      cb(value)
    }, 500),
    [],
  )

  const debounceUpdateWeekState = useCallback(
    _.debounce((value, cb) => {
      cb(value)
    }, 500),
    [],
  )

  useEffect(() => {
    if (isInitialized) {
      debounceUpdateDayState(formValues?.settings?.hoursPerWeek, setFormHoursPerWeek)
      debounceUpdateWeekState(formValues?.settings?.hoursPerDay, setFormHoursPerDay)
    }
  }, [formValues?.settings?.hoursPerWeek, formValues?.settings?.hoursPerDay])

  const restFundings = _.filter(formValuesWholeForm.childProducts, ({ alternate: productAlternate, id, type }) => (
    alternate === productAlternate
    && type === ChildProductTypes.REGULAR_ALLOCATED_FUNDING
    && (editData ? editData.id !== id : true)
  ))

  useEffect(() => {
    const data = _.map(sessions, (session) => ({
      dayOfWeek: session.dayOfWeek,
      product: session,
      times: [{
        endTime: session.product.hourly ? session.endTime : session.product.endTime,
        startTime: session.product.hourly ? session.startTime : session.product.startTime,
      }],
    } as AllocationDefault))

    setSessionAllocations(data)
  }, [sessions])

  const getCalculatorDataSuccess = (response, resetRecalculateButton?: boolean) => {
    const { data } = response || {}

    if (data?.excludedFundingReasons?.length) {
      setExcludedFundingReasons(data.excludedFundingReasons)
    } else {
      setExcludedFundingReasons([])
    }

    if (resetRecalculateButton) {
      setShowRecalculateButton(false)
    }

    setIsFetching(false)

    setDefaultFundingAllocations(
      _.map(data.defaultAllocations, (item) => ({ ...item, _extra: { id: item.product.id } })),
    )
    setFundingAllocations(
      _.map(data.allocations, (item) => ({ ...item, _extra: { id: item.product.id } })),
    )
  }

  const getCalculatorDataFailed = (response) => {
    const errors = getBackendErrors(response)

    if (!errors) {
      return
    }

    if (errors?.childProduct) {
      injectValidation(errors.childProduct)
    }

    if (errors?.childBooking) {
      setErrorMessages(_.uniq(_.filter(flatten(errors.childBooking))))
    }
  }

  const handleGetFundingSummaryCalculatorSuccess = (response) => {
    setIsFetchingSummaryFunding(false)
    setSummaryFunding(response?.data?.results[0])
  }

  const getCalculatorBody = (params?: {
    forCalculator?: boolean
    forSummary?: boolean
    overrideDefaultAllocations?: AllocationDefault[]
    skipDefaultAllocation?: boolean
  }) => {
    const { forCalculator, forSummary, overrideDefaultAllocations, skipDefaultAllocation } = params || {}

    const childBooking = childBookingSelectors.getBody(formValuesWholeForm, child, forCalculator)
    const childProduct = childBookingSelectors.getFundingBody({
      allocations: forSummary ? fundingAllocations : [],
      alternate,
      defaultAllocations: skipDefaultAllocation && !forSummary
        ? []
        : overrideDefaultAllocations || defaultFundingAllocations,
      id: editData?.id as string,
      values: formValues,
    })

    if (editData?.id) {
      childBooking.childProducts = _.map(childBooking.childProducts, (product: ChildProductRegularAllocatedFunding) => {
        let updatedProduct = { ...product }

        if (updatedProduct.id === editData.id) {
          updatedProduct = {
            ...childProduct,
            settings: {
              ...childProduct.settings,
              migratedFunding: updatedProduct.settings.migratedFunding,
            },
          }
        }

        return updatedProduct
      })
    }

    if (routeParams.bookingId) {
      childBooking.id = +routeParams.bookingId
    }

    return {
      childBooking,
      childProduct,
    }
  }

  const getFundingSummary = (body) => (
    childActions.getFundingSummaryCalculator({
      body,
      onFailed: (response) => {
        setIsFetchingSummaryFunding(false)
        getCalculatorDataFailed(response)
      },
      onSuccess: handleGetFundingSummaryCalculatorSuccess,
      onlyData: true,
      params: [child.id, {
        groups: SUMMARY_GROUPS,
      }],
    })
  )

  const getCalculatorData = (params?: {
    onlySummary?: boolean
    overrideDefaultAllocations?: AllocationDefault[]
    skipDefaultAllocation?: boolean
  }, forCalculator = true) => {
    const { onlySummary, skipDefaultAllocation } = params || {}

    if (!formValues?.product) {
      return false
    }

    if (!onlySummary) {
      setIsFetching(true)
    }

    setIsFetchingSummaryFunding(true)
    setErrorMessages(null)

    if (onlySummary) {
      const body = getCalculatorBody({ ...params, forSummary: true })

      return getFundingSummary(body)
    }

    const body = getCalculatorBody({ ...params, forCalculator })

    return childActions.getChildFundingCalculator({
      body,
      onFailed: (response) => {
        setIsFetching(false)
        getCalculatorDataFailed(response)
      },
      onSuccess: (response) => getCalculatorDataSuccess(response, skipDefaultAllocation),
      onlyData: true,
      params: [child.id, {
        groups: GROUPS,
      }],
    })
  }

  useEffect(() => {
    getCalculatorData({ onlySummary: true })
  }, [fundingAllocations])

  const debounceGetCalculatorData = _.debounce(getCalculatorData, 500)

  useEffect(() => {
    if (isInitialized) {
      getCalculatorData({ onlySummary: true })
    }
  }, [formHoursPerDay, formHoursPerWeek])

  useEffect(() => {
    if (editData) {
      return
    }

    const handleListSuccess = ({ data }) => {
      let initValues = {
        settings: {
          customLocalAuthority: false,
          hourlyRate: 0,
          hoursPerDay: 10,
          hoursPerWeek: 0,
        },
        week: [
          moment(startDate).add(alternate - 1, 'week').startOf('week'),
          moment(startDate).add(alternate - 1, 'week').endOf('week'),
        ],
      } as any

      const firstNotUsedNurseryFunding = _.find(data, ({ id }) => !_.includes(usedNurseryFundings, id))

      if (firstNotUsedNurseryFunding) {
        const { priceChanges, settings } = firstNotUsedNurseryFunding || {}
        const { hoursPerWeek, maxHours, maxMinutes, minutesPerWeek } = settings || {}

        initValues = {
          product: {
            label: firstNotUsedNurseryFunding.name,
            priceChanges: firstNotUsedNurseryFunding.priceChanges,
            settings: firstNotUsedNurseryFunding.settings,
            type: firstNotUsedNurseryFunding.type,
            value: firstNotUsedNurseryFunding.id,
          },
          settings: {
            ...initValues.settings,
            hoursPerDay: hoursAndMinutesToFloat(maxHours, maxMinutes),
          },
          week: [
            moment(startDate).add(alternate - 1, 'week').startOf('week'),
            moment(startDate).add(alternate - 1, 'week').endOf('week'),
          ],
        }

        const price = getCurrentPriceFromPriceChanges(priceChanges, startDate)

        if (_.isNumber(price)) {
          initValues.settings.hourlyRate = price
        }

        if (hoursPerWeek || minutesPerWeek) {
          initValues.settings.hoursPerWeek = hoursPerWeek + Math.ceil(((minutesPerWeek || 0) / 60) * 100) / 100
        }

        setInitialValues(initValues)
      }
    }

    nurseryFundingV3Actions.list({
      onSuccess: handleListSuccess,
      onlyData: true,
      params: {
        criteria: [
          {
            field: 'archived',
            value: 0,
          },
          {
            field: 'type',
            value: [NurseryFundingProductType.NURSERY_REGULAR_FUNDING],
          },
        ],
        groups: NURSERY_FUNDING_GROUPS,
        limit: usedNurseryFundings?.length ? 20 : 1,
      },
    })
  }, [])

  useEffect(() => {
    if (!editData) {
      setIsInitialized(true)

      return
    }

    const { product, settings, type } = editData
    const {
      allocations: editAllocations,
      customLocalAuthority,
      defaultAllocations: editDefaultAllocations,
      hourlyRate,
      hoursPerDay,
      hoursPerWeek,
      migratedFunding,
    } = settings || {}

    if (product?.settings?.exclusionPeriods?.length) {
      const exclusionPeriodDates = []

      _.forEach(product.settings.exclusionPeriods, (exclusionPeriod) => {
        const exclusionDates = getDatesBetweenDateRange(
          moment(exclusionPeriod.startDate).format(DEFAULT_DATE_FORMAT),
          moment(exclusionPeriod.endDate).format(DEFAULT_DATE_FORMAT),
        )
        _.forEach(exclusionDates, (exclusionDate) => {
          exclusionPeriodDates.push({
            date: exclusionDate,
            reason: ExcludedFundingReasonsType.EXCLUSION_PERIODS,
          })
        })
      })

      setExcludedFundingReasons(exclusionPeriodDates)
    }

    type finalProductProps = Option & {
      priceChanges: PriceChange[]
      settings?: NurseryFundingProductSettings
      type?: NurseryFundingProductType.NURSERY_ALLOCATED_FUNDING
    }

    changeField('week', [
      moment(startDate).add(alternate - 1, 'week').startOf('week'),
      moment(startDate).add(alternate - 1, 'week').endOf('week'),
    ])

    const finalProduct: finalProductProps = {
      label: product.name,
      priceChanges: product.priceChanges,
      settings: product.settings,
      value: product.id,
    }

    if (ChildProductTypes.REGULAR_ALLOCATED_FUNDING === type) {
      finalProduct.type = NurseryFundingProductType.NURSERY_ALLOCATED_FUNDING
    }

    if (!_.isUndefined(customLocalAuthority)) {
      changeField('settings.customLocalAuthority', customLocalAuthority)
    }

    if (!_.isUndefined(hourlyRate)) {
      changeField('settings.hourlyRate', hourlyRate)
    }

    if (!_.isUndefined(hoursPerDay)) {
      changeField('settings.hoursPerDay', hoursPerDay)
    }

    if (!_.isUndefined(hoursPerWeek)) {
      changeField('settings.hoursPerWeek', hoursPerWeek)
    }

    if (!_.isUndefined(migratedFunding)) {
      changeField('settings.migratedFunding', migratedFunding)
    }

    if (editDefaultAllocations?.length) {
      setDefaultFundingAllocations(editDefaultAllocations as any)
    }

    if (editAllocations?.length) {
      setFundingAllocations(editAllocations as any)
    }

    changeField('product', finalProduct)

    setIsFetching(false)

    if (migratedFunding) {
      setShowRecalculateButton(true)
    }

    setTimeout(() => {
      setIsInitialized(true)
    })
  }, [editData])

  useEffect(() => {
    setSummaryFunding(null)
    debounceGetCalculatorData({
      onlySummary: !isInitialized,
      skipDefaultAllocation: true,
    })
  }, [formValues?.product])

  const handleCloseClick = () => {
    hideModal()
  }

  const handleSubmit = (values) => {
    onSuccess(values, fundingAllocations, defaultFundingAllocations)
    hideModal()
  }

  const handleCreateAllocation = (defaultAllocation: boolean) => (dayOfWeekOrDate: DaysOfWeek | moment.Moment) => (
    session: ChildProductRegularSession,
    timeRange: AllocationTime,
  ) => {
    const id = v4()
    let updatedId = null
    let newTimeIndex = null

    const fundings = defaultAllocation ? defaultFundingAllocations : fundingAllocations
    const updatedFundingAllocations = _.map(fundings, (allocation: Allocation | AllocationDefault) => {
      if (
        session.product.id === allocation.product?.id
        && (
          (defaultAllocation && (allocation as AllocationDefault).dayOfWeek === dayOfWeekOrDate)
          || (!defaultAllocation && isSameDay((allocation as Allocation).date, dayOfWeekOrDate))
        )
      ) {
        const updatedAllocation = { ...allocation }

        newTimeIndex = updatedAllocation.times.length
        updatedAllocation.times.push({
          endTime: timeRange.endTime,
          startTime: timeRange.startTime,
        })

        updatedId = updatedAllocation._extra.id

        return updatedAllocation
      }

      return allocation
    })

    if (updatedId) {
      setOpenedAllocation({
        id: updatedId,
        isDefault: defaultAllocation,
        timeIndex: newTimeIndex,
      })

      if (defaultAllocation) {
        setDefaultFundingAllocations([
          ...updatedFundingAllocations as AllocationDefault[],
        ])

        getCalculatorData({
          overrideDefaultAllocations: [
            ...updatedFundingAllocations as AllocationDefault[],
          ],
        })
        setShowRecalculateButton(true)
      } else {
        setFundingAllocations([
          ...updatedFundingAllocations as Allocation[],
        ])
      }

      return
    }

    const newFundingAllocation: AllocationDefault | Allocation = {
      _extra: {
        id: defaultAllocation ? session.product.id as string : id,
      },
      product: session.product as ChildProductRegularSession,
      times: [{
        endTime: timeRange.endTime,
        startTime: timeRange.startTime,
      }],
    }

    if (defaultAllocation) {
      (newFundingAllocation as AllocationDefault).dayOfWeek = dayOfWeekOrDate as DaysOfWeek

      setDefaultFundingAllocations([
        ...defaultFundingAllocations,
        newFundingAllocation,
      ] as AllocationDefault[])

      getCalculatorData({
        overrideDefaultAllocations: [
          ...defaultFundingAllocations,
          newFundingAllocation,
        ] as AllocationDefault[],
      })
      setShowRecalculateButton(true)
    } else {
      (newFundingAllocation as Allocation).date = dayOfWeekOrDate as moment.Moment
      (newFundingAllocation as Allocation).redistributeRole = RedistributeRoleType.MANUALLY

      setFundingAllocations([
        ...fundingAllocations,
        newFundingAllocation,
      ] as Allocation[])
    }

    setOpenedAllocation({
      id: defaultAllocation ? session.product.id as string : id,
      isDefault: defaultAllocation,
      timeIndex: 0,
    })
  }

  const handleEditAllocation = (defaultAllocation: boolean) => (id, fieldName, fieldValue, timeIndex) => {
    const fundings = defaultAllocation ? defaultFundingAllocations : fundingAllocations
    let hasError = false

    if (allocateHoursWithinSessionTimesError) {
      setAllocateHoursWithinSessionTimesError(false)
    }

    const updatedFundings = _.map(fundings, (fundingAllocation: Allocation | AllocationDefault) => {
      const updatedFunding = { ...fundingAllocation }

      if (id === fundingAllocation._extra.id) {
        if (
          'startTime' === fieldName && moment(fieldValue).isSameOrAfter(fundingAllocation.times[timeIndex]?.endTime)
        ) {
          snackbarActions.show({
            message: i18n.t('modals:AddFundingToRegularBookings:startTimeEarlier'),
          })
          hasError = true
          return updatedFunding
        }

        if (
          'endTime' === fieldName && moment(fieldValue).isSameOrBefore(fundingAllocation.times[timeIndex]?.startTime)
        ) {
          snackbarActions.show({
            message: i18n.t('modals:AddFundingToRegularBookings:endTimeLater'),
          })
          hasError = true
          return updatedFunding
        }

        updatedFunding.times[timeIndex][fieldName] = fieldValue
      }

      return updatedFunding
    })

    if (hasError) {
      return
    }

    if (defaultAllocation) {
      setDefaultFundingAllocations(updatedFundings as AllocationDefault[])
      getCalculatorData({ overrideDefaultAllocations: updatedFundings as AllocationDefault[] })
      setShowRecalculateButton(true)
    } else {
      setFundingAllocations(updatedFundings as Allocation[])
    }
  }

  const handleCloseAllocationPopup = () => {
    let showErrorBanner = false

    const sessionsGroupedByDayOfWeek = {}

    setAllocateHoursWithinSessionTimesError(false)

    _.each(sessionAllocations, (session: AllocationDefault) => {
      const times = []

      _.each(session.times, (time) => {
        times.push(time.startTime)
        times.push(time.endTime)
      })

      sessionsGroupedByDayOfWeek[session.dayOfWeek] = _.sortBy([
        ...(sessionsGroupedByDayOfWeek[session.dayOfWeek] || []),
        ...times,
      ])

      sessionsGroupedByDayOfWeek[session.dayOfWeek] = _.filter(
        sessionsGroupedByDayOfWeek[session.dayOfWeek],
        (v, e, a) => a.indexOf(v) === a.lastIndexOf(v),
      )
    })

    _.mapValues(sessionsGroupedByDayOfWeek, (item, dayOfWeek) => {
      const items = []
      for (let i = 0; i < sessionsGroupedByDayOfWeek[dayOfWeek].length - 1; i += 2) {
        items.push([
          sessionsGroupedByDayOfWeek[dayOfWeek][i],
          sessionsGroupedByDayOfWeek[dayOfWeek][i + 1],
        ])
      }

      sessionsGroupedByDayOfWeek[dayOfWeek] = items
    })

    _.each(defaultFundingAllocations, (fundingAllocation: AllocationDefault) => {
      _.each(fundingAllocation.times, (time) => {
        if (!_.find(sessionsGroupedByDayOfWeek[fundingAllocation.dayOfWeek], ([startTime, endTime]) => (
          moment(time.startTime, 'x').isSameOrAfter(moment(startTime, 'x'))
          && moment(time.endTime, 'x').isSameOrBefore(moment(endTime, 'x'))
        ))) {
          showErrorBanner = true
        }
      })
    })

    if (showErrorBanner) {
      setAllocateHoursWithinSessionTimesError(true)

      return
    }

    setOpenedAllocation(null)
  }

  const handleRemoveAllocation = (defaultAllocation: boolean) => (id: string) => {
    if (defaultAllocation) {
      const updatedDefaultFundings = _.filter(defaultFundingAllocations, (allocation) => allocation._extra.id !== id)

      setDefaultFundingAllocations(updatedDefaultFundings)
      setShowRecalculateButton(true)

      getCalculatorData({
        overrideDefaultAllocations: updatedDefaultFundings,
      })
    } else {
      const updatedFundings = _.filter(fundingAllocations, (allocation) => allocation._extra.id !== id)

      setFundingAllocations(updatedFundings)
    }

    setOpenedAllocation(null)
    setAllocateHoursWithinSessionTimesError(false)
  }

  const handleOpenAllocation = (value: OpenedAllocation) => {
    setOpenedAllocation(value)
  }

  const handleChangeHourlyRateType = (value) => {
    if (!value) {
      const { priceChanges } = formValues?.product || {}

      const price = getCurrentPriceFromPriceChanges(priceChanges, startDate)

      if (_.isNumber(price)) {
        changeField('settings.hourlyRate', price)
      }
    }

    changeField('settings.customLocalAuthority', value)
  }

  const handleChangeNurseryFunding = (value) => {
    const { priceChanges, settings } = value || {}
    const { hoursPerWeek, maxHours, maxMinutes, minutesPerWeek } = settings || {}

    const price = getCurrentPriceFromPriceChanges(priceChanges, startDate)

    if (_.isNumber(price)) {
      changeField('settings.hourlyRate', price)
    }

    if (hoursPerWeek || minutesPerWeek) {
      changeField('settings.hoursPerWeek', hoursPerWeek + Math.ceil(((minutesPerWeek || 0) / 60) * 100) / 100)
    }

    if (!_.isNil(maxHours) || !_.isNil(maxMinutes)) {
      changeField('settings.hoursPerDay', hoursAndMinutesToFloat(maxHours, maxMinutes))
    }
  }

  const handleApplyToCheapestHours = () => {
    if (isFetching) {
      return
    }

    getCalculatorData({
      skipDefaultAllocation: true,
    }, false)
  }

  const handleRemoveAllFundedHours = (isDefault: boolean) => {
    if (isDefault) {
      setDefaultFundingAllocations([])
      setFundingAllocations([])
      setShowRecalculateButton(true)

      return
    }

    const weekDay = moment(formValues?.week?.[0])
    const allocationsForWeekDay = _.filter(fundingAllocations, ({ date }) => !(
      moment(date).isSameOrBefore(weekDay.endOf('week'))
      && moment(date).isSameOrAfter(weekDay.startOf('week'))
    ))

    setFundingAllocations(allocationsForWeekDay)
  }

  return (
    <AddFundingToRegularBookingsModalView
      allocateHoursWithinSessionTimesError={allocateHoursWithinSessionTimesError}
      alternate={alternate}
      defaultFundingAllocations={defaultFundingAllocations}
      endDate={endDate}
      errorMessages={errorMessages}
      excludedFundingReasons={excludedFundingReasons}
      formValues={formValues}
      fundingAllocations={fundingAllocations}
      initialValues={initialValues}
      isEditMode={!!editData}
      isFetching={isFetching}
      isFetchingSummaryFunding={isFetchingSummaryFunding}
      migratedFunding={editData?.settings?.migratedFunding}
      openedAllocation={openedAllocation}
      restFundings={restFundings}
      sessionAllocations={sessionAllocations}
      showRecalculateButton={showRecalculateButton}
      startDate={startDate}
      summaryFunding={summaryFunding}
      totalAlternates={totalAlternates}
      usedNurseryFundings={usedNurseryFundings}
      onApplyToCheapestHours={handleApplyToCheapestHours}
      onChangeHourlyRateType={handleChangeHourlyRateType}
      onChangeNurseryFunding={handleChangeNurseryFunding}
      onChangeShowRecalculateButton={setShowRecalculateButton}
      onCloseAllocationPopup={handleCloseAllocationPopup}
      onCloseClick={handleCloseClick}
      onCreateAllocation={handleCreateAllocation(false)}
      onCreateDefaultAllocation={handleCreateAllocation(true)}
      onEditAllocation={handleEditAllocation}
      onOpenAllocation={handleOpenAllocation}
      onRemoveAllFundedHours={handleRemoveAllFundedHours}
      onRemoveAllocation={handleRemoveAllocation}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withChildService,
  withChildBookingService,
  withModalService,
  withRouter,
  withNurseryFundingV3Service,
  withSnackbarService,
  connector,
)

export default enhance(AddFundingToRegularBookingsModalContainer)
