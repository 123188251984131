import _ from 'lodash'

import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'

import i18n from 'translations'

import * as constants from '../constants'

const getNurserySessions = (state: RootState) => state.nurserySessionsV3

export const getNurserySessionsList = createSelector(
  [getNurserySessions],
  (state) => {
    if (!state.list.data?.length) {
      return null
    }

    return state.list
  },
)

export const getNurserySessionsListData = createSelector(
  [getNurserySessionsList],
  (state) => state?.data,
)

export const geNurserySessionsOptionsDropdownData = createSelector(
  [getNurserySessionsList],
  (state) => {
    if (!state.data?.length) {
      return null
    }

    return _.map(state.data, ({ archived, id, name }) => ({
      label: `${name}${archived ? ` (${i18n.t('global:archived')})` : ''}`,
      value: id,
    }))
  },
)

export const getCriteria = (filters, nurseryId = null) => {
  const { search, statusFilter } = filters || {}

  const criteria = []

  if (statusFilter && statusFilter !== constants.NURSERY_SESSIONS_FILTER.ALL) {
    criteria.push({
      field: 'archived',
      value: statusFilter === constants.NURSERY_SESSIONS_FILTER.ARCHIVED ? 1 : 0,
    })
  }

  if (search) {
    criteria.push({
      field: 'name',
      value: search,
    })
  }

  if (nurseryId) {
    criteria.push({
      field: 'nursery',
      value: nurseryId,
    })
  }

  return criteria
}

export const getNurserySessionsV3Options = createSelector(
  [getNurserySessionsList],
  (nurserySessionsList) => {
    if (!nurserySessionsList?.data?.length) {
      return null
    }

    return _.map(nurserySessionsList.data, ({ endTime, hourly, id, name, priceChanges, startTime }) => ({
      endTime,
      isHourly: hourly,
      label: name,
      priceChanges,
      startTime,
      value: id,
    }))
  },
)
