import _ from 'lodash'
import { useChannels } from '@blossomdev/sync'

import { debounceTime, map } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { compose } from 'recompose'

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'
import { useWindowSize } from 'utils/hooks'
import { getAuthenticationProfileUser } from 'utils/auth/selectors'

import { withChildService } from 'services/legacy/child'
import { withRouter } from 'services/router'

import { renderCarers } from 'module/Messaging/MessagingHelpers'

import { Avatar, Button, Conversations, EmptyState, Space, Spinner, Typography } from 'components'

import i18n from 'translations'

import Channel from '../Channel'
import Archived from '../Archived'
import { StyledChildRow, StyledSubtitle } from './ChannelListStyled'

const LIMIT = 20

export const ITEM_HEIGHT = 78

const LIST_CUSTOM_ELEMENTS = {
  CHILD: 'child',
  HEADER: 'header',
  LOAD_MORE: 'loadMore',
  NEW: 'new',
  SPINNER: 'spinner',
}

const onSearch$ = new Subject()

let loadPageIsInitialized = false

const GROUPS = {
  read: [
    'carer',
    'carerChildRelation',
    'carerChildRelation.carer',
    'child.carerChildRelations',
    'child.sync',
  ],
}
const ChannelList = ({
  activeChannelId,
  authUser,
  childActions,
  childSelectors,
  expanded,
  isEmptyStateInitialized,
  isNewChannelPage,
  location,
  mobile,
  navigate,
  onIsEmptyState,
  onRedirect,
  root,
  width,
}) => {
  const { channels, initialized } = useChannels()
  const [search, setSearch] = useState('')
  const [page, changePage] = useState(1)
  const [totalPages, changeTotalPages] = useState(1)
  const [childrenList, setChildrenList] = useState([])
  const { height: windowHeight, width: windowWidth } = useWindowSize()
  const [childrenListIsFetching, setChildrenListIsFetching] = useState(false)

  const { uuid: authUserUuid } = authUser
  const firstChannel = channels?.[0]
  const { pathname } = location
  const filteredChannels = _.filter(channels, ({ name }) => (!search || 3 > search?.length ? true : (
    -1 < name?.toLowerCase().indexOf(search?.toLowerCase())
  )))

  useEffect(() => {
    const isMainMessagingPage = pathname === generateRoute('MESSAGING.INDEX')

    if (!channels.length && isMainMessagingPage) {
      onIsEmptyState(true)
    }

    if (isMainMessagingPage && channels?.[0]?.channelID) {
      navigate(generateRoute('MESSAGING.CHANNEL', { channelId: channels?.[0].channelID }))
    }
  }, [channels, isEmptyStateInitialized, pathname, onIsEmptyState])

  const getChildrenListSuccess = (response) => {
    const { data, meta } = response
    const { start, total_results: totalResults } = meta

    changeTotalPages(Math.ceil(totalResults / LIMIT))
    setChildrenList((prevChildren) => (1 === start ? data : [...prevChildren, ...data]))
    loadPageIsInitialized = false
    setChildrenListIsFetching(false)
  }

  const getChildrenList = (finalPage, value = '', channelsList) => {
    changePage(finalPage)

    if (!value) {
      loadPageIsInitialized = false

      return null
    }

    if (1 === finalPage) {
      setChildrenListIsFetching(true)
    }

    const filteredUuid = _.filter(channelsList, ({ name }) => name.toLowerCase().includes(value.toLocaleLowerCase()))
    const uuid = _.map(filteredUuid, ({ id }) => id)

    const params = {
      criteria: childSelectors.getCriteria({
        isArchived: null,
        search: value,
        uuid,
      }),
      groups: GROUPS,
      limit: LIMIT,
      page: finalPage,
    }

    return childActions.dropdownList(
      params,
      1 !== finalPage,
      getChildrenListSuccess,
    )
  }

  const handlePageChange = () => {
    if (loadPageIsInitialized) {
      return false
    }

    loadPageIsInitialized = true

    return getChildrenList(page + 1, search, channels)
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const subscription = onSearch$.pipe(
      debounceTime(500),
      map((value) => {
        setSearch(value)

        if (3 <= value?.length) {
          changePage(1)
          changeTotalPages(1)
          getChildrenList(1, value, channels)
        }
      }),
    ).subscribe()

    return () => subscription.unsubscribe()
  }, [channels])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!search || 3 > search.length) {
      changePage(1)
      setChildrenList([])
    }
  }, [search])

  const to = firstChannel
    ? generateRoute('MESSAGING.CHANNEL', { channelId: firstChannel.id })
    : generateRoute('MESSAGING.INDEX')

  const renderChildRow = (child, style) => {
    const { archivedAt, carerChildRelations, firstName, id, photo, surname, uuid } = child

    return (
      <div
        key={id}
        style={style}
      >
        <StyledChildRow
          to={`${generateRoute('MESSAGING.CHANNEL', { channelId: uuid })}?childId=${id}`}
          onClick={() => {
            setSearch('')
            setChildrenList([])
            changePage(1)
          }}
        >
          <Avatar
            avatarSize="medium_big"
            initials={[firstName, surname]}
            src={photo}
            subTitle={(
              <StyledSubtitle isArchived={archivedAt}>
                {archivedAt && <Archived />}
                {renderCarers(carerChildRelations)}
              </StyledSubtitle>
            )}
            title={(
              <Typography color={NEUTRAL_COLOURS.BASIC} margin="0 0 7px" variant="span">
                {`${firstName} ${surname}`}
              </Typography>
            )}
          />
        </StyledChildRow>
      </div>
    )
  }

  if (!root && width && ((mobile && 700 < width) || (!mobile && 700 >= width))) {
    return null
  }

  let combinedList = filteredChannels

  if (childrenListIsFetching) {
    combinedList = [
      ...filteredChannels,
      {
        itemType: LIST_CUSTOM_ELEMENTS.HEADER,
      },
      {
        itemType: LIST_CUSTOM_ELEMENTS.SPINNER,
      },
    ]
  }

  if (!childrenList?.length && !childrenListIsFetching) {
    combinedList = filteredChannels
  }

  if (childrenList?.length && !childrenListIsFetching) {
    combinedList = [
      ...filteredChannels,
      {
        itemType: LIST_CUSTOM_ELEMENTS.HEADER,
      },
      ..._.map(childrenList, (item) => ({
        ...item,
        itemType: LIST_CUSTOM_ELEMENTS.CHILD,
      })),
    ]

    if (totalPages > page) {
      combinedList = [
        ...combinedList,
        {
          itemType: LIST_CUSTOM_ELEMENTS.LOAD_MORE,
        },
      ]
    }
  }

  if (isNewChannelPage) {
    combinedList = [
      {
        itemType: LIST_CUSTOM_ELEMENTS.NEW,
      },
      ...combinedList,
    ]
  }

  const renderRow = ({ index, key, style }) => {
    const item = combinedList[index]

    if (LIST_CUSTOM_ELEMENTS.HEADER === item.itemType) {
      return (
        <div key={key} style={style}>
          <Typography margin="25px 0 0 16px" width="auto" bold>
            {i18n.t('global:Children')}
          </Typography>
        </div>
      )
    }

    if (LIST_CUSTOM_ELEMENTS.SPINNER === item.itemType) {
      return (
        <div key={key} style={style}>
          <Spinner />
        </div>
      )
    }

    if (LIST_CUSTOM_ELEMENTS.NEW === item.itemType) {
      return (
        <Channel channel={firstChannel} isNewChannel={isNewChannelPage} />
      )
    }

    if (LIST_CUSTOM_ELEMENTS.CHILD === item.itemType) {
      return renderChildRow(item, style)
    }

    if (LIST_CUSTOM_ELEMENTS.LOAD_MORE === item.itemType) {
      handlePageChange()

      return null
    }

    return (
      <div key={item.id} style={style}>
        <Channel
          archivedAt={item?.archivedAt}
          authUserUuid={authUserUuid}
          channel={item}
          isActive={activeChannelId === item.id}
          lastMessage={item?.lastMessagePostedAt}
          onRedirect={onRedirect}
        />
      </div>
    )
  }

  const calculateRowHeight = ({ index }) => {
    const item = combinedList[index]

    if (item.itemType === LIST_CUSTOM_ELEMENTS.HEADER) {
      return 50
    }

    return ITEM_HEIGHT
  }

  const renderContent = () => {
    if (!initialized) {
      return (
        <React.Fragment>
          <Space space="15px" />
          <Spinner />
        </React.Fragment>
      )
    }

    if (!filteredChannels?.length && !childrenList?.length && !childrenListIsFetching) {
      return (
        <EmptyState
          icon="messaging"
          text1={i18n.t('module:Messaging:ChannelList:notFound')}
        />
      )
    }

    return (
      <Conversations.List
        calculateRowHeight={calculateRowHeight}
        itemsLength={combinedList.length}
        renderRow={renderRow}
      />
    )
  }

  const actions = (
    <React.Fragment>
      {!expanded && (
        <Button
          hierarchy="tertiary"
          icon="expand-view"
          size="small"
          to={to}
          negativeMargins
          onlyActiveOnIndex
          onClick={onRedirect}
        />
      )}
      <Button
        hierarchy="tertiary"
        icon="create-message"
        size="small"
        to={generateRoute('MESSAGING.CHANNEL_NEW')}
        negativeMargins
        onlyActiveOnIndex
        onClick={onRedirect}
      />
    </React.Fragment>
  )

  const renderBody = () => (
    <React.Fragment>
      <Conversations.Header
        actions={actions}
        searchBarProps={{
          disabled: !initialized,
          noBackground: true,
          onChange: (value) => {
            changePage(1)
            setChildrenList([])
            onSearch$.next(value)
            setSearch(value)
          },
          placeholder: i18n.t('module:Messaging:ChannelList:search'),
        }}
        title={i18n.t('module:Messaging:ChannelList:title')}
      />
      {renderContent()}
    </React.Fragment>
  )

  if (root) {
    return (
      <Conversations
        height={windowHeight}
        itemsLength={combinedList.length}
        width={560 < windowWidth ? 500 : 320}
      >
        {renderBody()}
      </Conversations>
    )
  }

  return renderBody()
}

const mapState = (state) => ({
  authUser: getAuthenticationProfileUser(state),
})

const enhance = compose(
  withChildService,
  withRouter,
  connect(mapState),
)

export default enhance(ChannelList)
