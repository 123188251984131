import styled from 'styled-components'

export const StyledFilterWrapper = styled.div`
  display: flex;
  
  ${({ itsUnsavedPreview }) => itsUnsavedPreview && `
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-column-gap: 25px;
  `}
  
  @media(max-width: 640px) {
    grid-template-columns: auto;
    grid-row-gap: 25px;
    justify-content: center;
    text-align: center;
  }
`

export const StyledToolbar = styled.div`
  padding-top: 3px;
  display: grid;
  grid-column-gap: 50px;
  grid-template-columns: auto auto auto 1fr;
  
  @media(max-width: 640px) {
    grid-template-columns: auto;
    justify-content: center;
    text-align: center;
    grid-row-gap: 25px;
  }
`

export const StyledLegendItem = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 10px;
  align-items: center;
  line-height: 23px;
  margin-left: 15px;
`

export const StyledRectangle = styled.div`
  width: 20px;
  height: 20px;
  
  ${({ color }) => color && `
    background-color: ${color};
  `}
`
