import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as nurseryFrameworkRelationsSelectors from './selectors'

const withNurseryFrameworkRelationsService = (WrappedComponent) => {
  const mapState = ({ nurseryFrameworkRelations }) => ({ nurseryFrameworkRelations })

  const mapDispatch = injectActions('nurseryFrameworkRelationsActions', actions)

  const Component = ({ nurseryFrameworkRelations, nurseryFrameworkRelationsActions, ...others }) => (
    <WrappedComponent
      nurseryFrameworkRelationsActions={nurseryFrameworkRelationsActions}
      nurseryFrameworkRelationsListState={nurseryFrameworkRelations.list}
      nurseryFrameworkRelationsSelectors={nurseryFrameworkRelationsSelectors}
      nurseryFrameworkRelationsSingleState={nurseryFrameworkRelations.single}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withNurseryFrameworkRelationsService
