import { generateBasicActions } from 'utils/service'

import {
  CLEAR_PUBLIC_ENQUIRY,
  CREATE_PUBLIC_ENQUIRY,
  CREATE_PUBLIC_ENQUIRY_FAILED,
  CREATE_PUBLIC_ENQUIRY_SUCCESS,
} from './constants'

export const createPublicEnquiry = (options = {}) => ({ enquiriesApiClient }) => generateBasicActions.create({
  constants: {
    failed: CREATE_PUBLIC_ENQUIRY_FAILED,
    init: CREATE_PUBLIC_ENQUIRY,
    success: CREATE_PUBLIC_ENQUIRY_SUCCESS,
  },
  options,
  service: enquiriesApiClient,
  serviceMethod: 'createPublicEnquiry',
})

export const clear = () => ({
  type: CLEAR_PUBLIC_ENQUIRY,
})
