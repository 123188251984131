import moment from 'moment'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { change, formValueSelector, getFormValues, isDirty, stopSubmit, submit } from 'redux-form'

import { EVENTS, logEvent } from 'analytics'

import { FEATURE_FLAGS } from 'constants/security'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withRouterUtils } from 'services/utils/router'
import { withChildService } from 'services/legacy/child'
import { withNurseriesService } from 'services/nurseries'
import { withInvoicesService } from 'services/legacy/invoices'
import { withChildAdhocSessionsService } from 'services/legacy/childAdhocSessions'
import { withChildFundingService } from 'services/legacy/childFunding'
import { withChildExtraItemsService } from 'services/legacy/childExtraItems'
import { withRouter } from 'services/router'

import withInvoiceSendHandlers from 'module/Finance/withInvoiceSendHandlers'
import { getInvoicePeriodDropdown } from 'module/Finance/helpers'

import i18n from 'translations'

import { CHILD_FINANCE_INVOICE_FORM } from './components/ChildFinanceInvoiceForm'
import ChildFinanceInvoiceView from './ChildFinanceInvoiceView'

const INVOICE_PERIOD_OPTIONS = getInvoicePeriodDropdown()

const NURSERY_INVOICE_SETTINGS_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings.invoice',
    'nurseryInvoiceSettings',
    'nurseryInvoiceSettings.numbers',
    'invoiceNumbers',
    'nursery.organizationSettings',
    'organizationSettings',
    'organizationSettings.invoice',
    'organizationInvoiceSettings',
  ],
}

const INVOICE_AUTO_CALCULATE_GROUPS = {
  read: [
    'invoice.subItems',
    'calculator.preview',
    'invoicePreview',
    'invoicePreview.invoice',
    'invoicePreview.items.invoiceItem',
    'invoice.items',
    'invoiceItem',
    'invoice.instructions',
    'invoiceItem.childProduct',
    'invoiceItem.instructions',
    'invoiceItem.reference',
    'childExtraItemProjection',
    'childExtraSession',
    'deposit',
  ],
}

const INVOICE_PREVIEW_GROUPS = {
  read: [
    'invoicePreview.creditNotes',
    'invoicePreview.items.invoiceItem',
    'invoice.items',
    'invoice.sendLogs',
    'invoiceItem',
    'invoiceItem.childFunding',
    'invoiceItem.childExtraItemProjections',
    'invoiceItem.childExtraSession',
    'invoiceItem.childProduct',
    'invoiceItem.reference',
    'invoiceItem.deposit',
    'deposit',
  ],
}

const CHILD_FINANCE_DETAILS_GROUPS = {
  read: [
    'child.finance',
  ],
}

const CHILD_BANK_DETAILS_GROUPS = {
  read: [
    'child.childBankDetail',
    'childBankDetail',
    'childBankDetail.paymentContact',
  ],
}

class ChildFinanceInvoiceContainer extends Component {
  constructor(props) {
    super(props)

    const { location } = props

    this.state = {
      initialValues: null,
      isInvoicePreviewCleared: false,
      repeatInvoiceId: location.query.repeat,
    }
  }

  componentDidMount() {
    const {
      childActions,
      invoiceId,
      invoicesActions,
      isEdit,
      location,
      nurseriesActions,
      nurseryOptions,
      params,
      router,
    } = this.props

    if (!location?.state?.redirectFromItem) {
      const { childId } = params

      childActions.getFinanceDetails({
        params: [childId, {
          groups: CHILD_FINANCE_DETAILS_GROUPS,
        }],
      })

      childActions.getBankDetails({
        params: [childId, {
          groups: CHILD_BANK_DETAILS_GROUPS,
        }],
      })

      nurseriesActions.get(nurseryOptions.id, {
        onSuccess: ({ data }) => this.handleNurseryGetSuccess(data),
        params: { groups: NURSERY_INVOICE_SETTINGS_GROUPS },
      })

      if (isEdit) {
        const apiParams = { groups: INVOICE_PREVIEW_GROUPS }

        invoicesActions.getPreview(invoiceId, apiParams)
      }
    }

    router.replace({ pathname: location.pathname, state: null })

    if (!isEdit) {
      logEvent(EVENTS.INVOICE_ADD_PAGE_VIEWED)
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { invoicePreview, invoicesSelectors } = props
    const { initialValues, isInvoicePreviewCleared } = state

    if (!invoicePreview && !isInvoicePreviewCleared) {
      return { isInvoicePreviewCleared: true }
    }

    if (isInvoicePreviewCleared && invoicePreview && !initialValues) {
      return { initialValues: invoicesSelectors.getInitialValues(invoicePreview) }
    }

    return null
  }

  componentDidUpdate(prevProps) {
    const { invoicePeriodDropdown, updateField } = this.props

    if (invoicePeriodDropdown && (
      !prevProps.invoicePeriodDropdown || prevProps.invoicePeriodDropdown.value !== invoicePeriodDropdown.value
    )) {
      const { endDate, startDate } = invoicePeriodDropdown

      const invoicePeriod = [startDate, endDate]

      updateField('invoicePeriod', invoicePeriod)
    }
  }

  componentWillUnmount() {
    const {
      childAdhocSessionsActions,
      childExtraItemsActions,
      childFundingActions,
      invoicesActions,
      nurseriesActions,
    } = this.props

    invoicesActions.clearPreview()
    invoicesActions.clear()
    nurseriesActions.clearSingle()
    childAdhocSessionsActions.clear()
    childExtraItemsActions.clearProjections()
    childFundingActions.clear()
  }

  handleNurseryGetSuccess = (data) => {
    const { invoicesActions, isEdit } = this.props
    const { repeatInvoiceId } = this.state

    const { nurserySettings } = data
    const { invoice } = nurserySettings
    const { dueDate, invoiceLevelDetailDisplay, invoiceNumbers } = invoice || {}
    const { invoiceNumber, invoiceNumberPrefix } = invoiceNumbers || {}

    if (repeatInvoiceId) {
      const apiParams = { groups: INVOICE_PREVIEW_GROUPS }

      invoicesActions.repeatPreview(repeatInvoiceId, apiParams, { dueDate })
    }

    if (!isEdit && !repeatInvoiceId) {
      invoicesActions.setInitialValues(
        { dueDate, invoiceLevelDetailDisplay, invoiceNumber, invoiceNumberPrefix },
      )
    }
  }

  goToInvoiceEdit = (invoiceId) => {
    const { navigate, params } = this.props
    const { childId } = params

    navigate(generateRoute('CHILDREN.CHILD.FINANCE.INVOICE.EDIT', { childId, invoiceId }))
  }

  handleAddLineItemClick = (item = {}) => {
    const { invoiceId, isFinanceV3Enabled, modalActions, modalConsts, params } = this.props
    const { childId } = params
    const { itemIndex } = item

    logEvent(EVENTS.INVOICE_ADD_MANUAL_ITEM_CLICKED)

    const modelType = isFinanceV3Enabled ? modalConsts.TYPES.INVOICE_ADD_ITEM_V3 : modalConsts.TYPES.INVOICE_ADD_ITEM

    modalActions.show(modelType, {
      childId,
      invoiceFormName: CHILD_FINANCE_INVOICE_FORM,
      invoiceId,
      itemIndex,
    }, {
      enableMultipleModal: true,
    })
  }

  handleSuccess = (response, isDraft) => {
    const { invoiceId, isEdit, isInsideModal, navigate, onModalSaveSuccess, params } = this.props
    const { childId } = params

    if (isInsideModal) {
      onModalSaveSuccess(response)

      return
    }

    if (isEdit) {
      if (isDraft) {
        logEvent(EVENTS.INVOICE_UPDATED, { context: 'child profile invoice form' })
      }

      navigate(generateRoute('CHILDREN.CHILD.FINANCE.SINGLE_INVOICE', { childId, invoiceId }))

      return
    }

    if (isDraft) {
      logEvent(EVENTS.INVOICE_DRAFT_CREATED, { context: 'child profile invoice form' })
    }

    navigate(generateRoute('CHILDREN.CHILD.FINANCE.INVOICING', { childId }))
  }

  submit = ({ conflictCallback, fields, markAsSent, sendInvoice, successCallback }) => {
    const { invoiceId, invoicesActions, invoicesSelectors, isEdit, params } = this.props
    const apiParams = {}

    if (isEdit) {
      invoicesActions.update(
        invoiceId,
        invoicesSelectors.getPayload({ childId: params.childId, fields, markAsSent, sendInvoice }),
        apiParams,
        successCallback,
        conflictCallback,
      )

      return
    }

    invoicesActions.create(
      invoicesSelectors.getPayload({ childId: params.childId, fields, markAsSent, sendInvoice }),
      apiParams,
      successCallback,
      conflictCallback,
    )
  }

  handleSubmit = (fields) => {
    const { invoicePreview } = this.props
    const { invoice = {} } = invoicePreview || {}

    this.submit({
      conflictCallback: this.handleSendConflict,
      fields: {
        ...fields,
        instructions: invoice.instructions,
        items: invoice.items,
      },
      successCallback: (response) => this.handleSuccess(response, true),
    })
  }

  deleteInvoice = () => {
    const { invoiceId, invoicesActions } = this.props

    invoicesActions.remove(invoiceId, this.handleDeleteSuccess)
  }

  handleDeleteSuccess = () => {
    const { navigate, params } = this.props
    const { childId } = params

    logEvent(EVENTS.INVOICE_REMOVED, { context: 'child profile' })

    navigate(generateRoute('CHILDREN.CHILD.FINANCE.INVOICING', { childId }))
  }

  handleDeleteClick = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'trash',
      onConfirm: this.deleteInvoice,
      text: i18n.t('module:Children:Child:Finance:Invoice:Add:Modals:deleteInvoice'),
    })
  }

  redirectToContact = () => {
    const { navigate, params } = this.props
    const { childId } = params

    navigate(generateRoute('CHILDREN.CHILD.FINANCE.CONTACT', { childId }))
  }

  send = (newInvoiceNumber, modalErrorCallback) => {
    const {
      contactDetails: { paymentEmail },
      formValues,
      invoicePreview,
      sendHelpers,
    } = this.props
    const { invoice = {} } = invoicePreview || {}

    if (!paymentEmail) {
      sendHelpers.displayMissingPaymentContactAlert(paymentEmail, this.redirectToContact)

      return
    }

    this.submit({
      conflictCallback: (error) => this.handleSendConflict(error, modalErrorCallback),
      fields: {
        ...formValues,
        instructions: invoice.instructions,
        items: invoice.items,
        number: newInvoiceNumber,
      },
      sendInvoice: true,
      successCallback: this.handleSendSuccess,
    })
  }

  handleSendButtonClick = () => {
    const {
      childFinanceDetails,
      contactDetails: { paymentEmail },
      formValues,
      invoicesHelpers,
      sendHandlers,
      stopSubmitForm,
    } = this.props
    const { number } = formValues
    const { firstName } = childFinanceDetails || {}

    const fieldErrors = invoicesHelpers.getMandatoryFieldError(formValues)

    if (fieldErrors) {
      stopSubmitForm(fieldErrors)

      return
    }

    logEvent(EVENTS.INVOICE_SEND_BTN_CLICKED, { context: 'child profile invoice form' })

    sendHandlers.handleSendButtonClick({
      firstName,
      invoiceNumber: number,
      isDraft: true,
      onConfirm: this.send,
      onMissingPaymentContact: this.redirectToContact,
      paymentEmail,
      showInvoiceNumberPopup: true,
    })
  }

  handleSendSuccess = ({ text = i18n.t('module:Children:Child:Finance:Invoice:Add:Modals:sentInvoice') }) => {
    const { modalActions, modalConsts } = this.props

    modalActions.hideAll()
    modalActions.show(modalConsts.TYPES.ALERT, { text })

    logEvent(EVENTS.INVOICE_SEND_SUBMITTED, { context: 'child profile invoice form' })

    this.handleSuccess()
  }

  handleSendConflict = (error, modalErrorCallback) => {
    const { appSelectors, invoicesHelpers, isEdit, modalActions, stopSubmitForm } = this.props

    if (isEdit) {
      modalErrorCallback(error)

      return
    }

    const fieldValidations = invoicesHelpers.updateInvoiceDateFieldError(
      appSelectors.getFieldValidationFromError(error),
    )

    modalActions.hideAll()
    stopSubmitForm(fieldValidations)
  }

  markAsSent = (newInvoiceNumber, modalErrorCallback) => {
    const {
      formValues,
      invoicePreview,
      modalActions,
      modalConsts,
      navigate,
      params,
    } = this.props
    const { invoice = {} } = invoicePreview || {}
    const { childId } = params

    const successAlert = (response) => {
      const { data: { id: invoiceId } } = response

      logEvent(EVENTS.INVOICE_MARK_SENT_SUCCESSFUL, { context: 'child profile invoice form' })

      navigate(generateRoute('CHILDREN.CHILD.FINANCE.SINGLE_INVOICE', { childId, invoiceId }))

      modalActions.hideAll()
      modalActions.show(modalConsts.TYPES.ALERT, {
        text: i18n.t('module:Children:Child:Finance:Invoice:Add:Modals:markAsSent'),
      })
    }

    this.submit({
      conflictCallback: (error) => this.handleSendConflict(error, modalErrorCallback),
      fields: {
        ...formValues,
        instructions: invoice.instructions,
        items: invoice.items,
        number: newInvoiceNumber,
      },
      markAsSent: true,
      successCallback: successAlert,
    })
  }

  handleMarkAsSentClick = () => {
    const { child, formValues, invoicesHelpers, sendHandlers, stopSubmitForm } = this.props
    const { firstName } = child || {}
    const { number } = formValues

    const fieldErrors = invoicesHelpers.getMandatoryFieldError(formValues)

    if (fieldErrors) {
      stopSubmitForm(fieldErrors)

      return
    }

    sendHandlers.handleMarkAsSentClick({
      firstName,
      invoiceNumber: number,
      isDraft: true,
      onConfirm: this.markAsSent,
      showInvoiceNumberPopup: true,
    })

    logEvent(EVENTS.INVOICE_MARK_SENT_CLICKED, { context: 'child profile invoice form' })
  }

  handleDownloadSuccess = (invoiceId) => {
    const { isEdit, modalActions } = this.props

    modalActions.hide()

    if (!isEdit) {
      this.goToInvoiceEdit(invoiceId)
    }
  }

  handleSaveAndDownloadSuccess = ({ data }) => {
    const { invoicesActions } = this.props
    const { id } = data

    invoicesActions.download(id, () => this.handleDownloadSuccess(id))
  }

  saveAndDownload = () => {
    const { formValues, invoicePreview, modalActions, modalConsts } = this.props
    const { invoice = {} } = invoicePreview || {}

    modalActions.show(modalConsts.TYPES.ALERT, {
      text: i18n.t('module:Children:Child:Finance:Invoice:Add:Modals:downloadingInvoice'),
    })

    this.submit({
      conflictCallback: this.handleSendConflict,
      fields: {
        ...formValues,
        instructions: invoice.instructions,
        items: invoice.items,
      },
      successCallback: this.handleSaveAndDownloadSuccess,
    })
  }

  handleDownloadButtonClick = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'download',
      onConfirm: this.saveAndDownload,
      text: i18n.t('module:Children:Child:Finance:Invoice:Add:Modals:downloadInvoice'),
    })
  }

  handleIssueDateChange = (issueDate) => {
    const { invoicesSelectors, nurseryInvoiceSettingsDueDate, updateField } = this.props

    const newDueDate = invoicesSelectors.getDueDate({ dueDays: nurseryInvoiceSettingsDueDate, issueDate })

    updateField('dueDate', newDueDate)
  }

  handleAutoCalculateSuccess = (response) => {
    const { modalActions, modalConsts } = this.props
    const { data } = response
    const { preview } = data
    const { items } = preview

    modalActions.hide()

    logEvent(EVENTS.INVOICE_AUTOCALCULATION_APPLIED)

    if (!items?.length) {
      modalActions.show(modalConsts.TYPES.ALERT, {
        text: i18n.t('module:Children:Child:Finance:Invoice:Add:Modals:noItemFound'),
      })
    }
  }

  autoCalculate = (startDate, endDate) => () => {
    const { invoicesActions, invoicesSelectors, modalActions, modalConsts, params } = this.props
    const { childId } = params

    const body = invoicesSelectors.getAutoCalculateBodySelector({ endDate, startDate })

    invoicesActions.autoCalculate({
      body,
      onFailed: this.handleSendConflict,
      onSuccess: this.handleAutoCalculateSuccess,
      params: [childId, { groups: INVOICE_AUTO_CALCULATE_GROUPS }],
    })

    modalActions.show(modalConsts.TYPES.ALERT, {
      hideButton: true,
      text: i18n.t('module:Children:Child:Finance:Invoice:Add:Modals:calculatingInvoice'),
    })
  }

  handleAutoCalculateClick = () => {
    const { formValues, modalActions, modalConsts } = this.props
    const { invoicePeriod } = formValues
    const [startDate, endDate] = invoicePeriod

    logEvent(EVENTS.INVOICE_AUTOCALCULATION_BTN_CLICKED)

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'auto-calculate',
      onConfirm: this.autoCalculate(startDate, endDate),
      text: (
        <div>
          {i18n.t('module:Children:Child:Finance:Invoice:Add:Modals:autoCalculateItems')}
          {' '}
          {`${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`}
        </div>
      ),
    })
  }

  handleEditLineItemClick = (item = {}) => {
    const { invoiceId, isFinanceV3Enabled, modalActions, modalConsts, params } = this.props
    const { childId } = params
    const { itemIndex, readOnly } = item

    const modelType = isFinanceV3Enabled ? modalConsts.TYPES.INVOICE_ADD_ITEM_V3 : modalConsts.TYPES.INVOICE_ADD_ITEM

    modalActions.show(modelType, {
      childId,
      invoiceFormName: CHILD_FINANCE_INVOICE_FORM,
      invoiceId,
      itemIndex,
      readOnly,
    }, {
      enableMultipleModal: true,
    })
  }

  removeItem = (itemIndex, total, type) => {
    const { invoicesActions, invoicesSelectors } = this.props

    const updatedTotal = invoicesSelectors.getTotal(total, type)

    invoicesActions.removeItem(itemIndex.toString(), -(updatedTotal ?? 0))
  }

  handleRemoveLineItemClick = ({ itemIndex, total, type }) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'trash',
      onConfirm: () => this.removeItem(itemIndex, total, type),
      text: 'Are you sure you want to delete this line item?',
    })
  }

  render() {
    const {
      authAccessMap,
      childFinanceDetails,
      errorMessages,
      formValues,
      invoiceId,
      invoicePreview,
      invoicesSingleState,
      isEdit,
      isFetching,
      isInsideModal,
      onModalCancelClick,
      submitForm,
    } = this.props
    const { initialValues, repeatInvoiceId } = this.state

    const { invoicePeriod } = formValues || {}
    const isSubmitting = invoicesSingleState.isSubmitting
    const isDraft = invoicePreview ? invoicePreview.isDraft : true
    const displayAutoCalculate = invoicePeriod && invoicePeriod[0] && invoicePeriod[1]

    return (
      <ChildFinanceInvoiceView
        authAccessMap={authAccessMap}
        child={childFinanceDetails}
        displayAutoCalculate={displayAutoCalculate}
        errorMessages={errorMessages}
        initialValues={initialValues}
        invoiceId={invoiceId}
        invoicePeriodOptions={INVOICE_PERIOD_OPTIONS}
        invoicePreview={invoicePreview}
        isDraft={isDraft}
        isEdit={isEdit}
        isInsideModal={isInsideModal}
        isLoading={isFetching}
        isSubmitting={isSubmitting}
        repeatInvoiceId={repeatInvoiceId}
        onAddLineItemClick={this.handleAddLineItemClick}
        onAutoCalculateClick={this.handleAutoCalculateClick}
        onDeleteClick={this.handleDeleteClick}
        onDownloadButtonClick={this.handleDownloadButtonClick}
        onEditLineItemClick={this.handleEditLineItemClick}
        onIssueDateChange={this.handleIssueDateChange}
        onMarkAsSentClick={this.handleMarkAsSentClick}
        onModalCancelClick={onModalCancelClick}
        onRemoveLineItemClick={this.handleRemoveLineItemClick}
        onSendButtonClick={this.handleSendButtonClick}
        onSubmit={this.handleSubmit}
        onSubmitForm={submitForm}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childFinanceDetailsState,
  childSelectors,
  invoiceId,
  invoicesPreviewState,
  invoicesSelectors,
  nurseriesSelectors,
  nurseriesSingleState,
  params,
}) => ({
  authAccessMap: {
    section: {
      FinanceAutomation: auth.SELECTORS.getIsAuthorised(state, {
        flags: [FEATURE_FLAGS.FINANCE_AUTOMATION],
      }),
    },
  },
  childFinanceDetails: childSelectors.getFinanceDetailsSelector(state),
  contactDetails: childSelectors.getContactDetailsSelector(state),
  errorMessages: invoicesSelectors.getErrorMessages(state),
  formValues: getFormValues(CHILD_FINANCE_INVOICE_FORM)(state),
  invoiceId: params.invoiceId || invoiceId,
  invoicePeriodDropdown: formValueSelector(CHILD_FINANCE_INVOICE_FORM)(state, 'invoicePeriodDropdown'),
  invoicePreview: invoicesSelectors.getInvoiceFormattedPreviewSelector(state),
  isDirty: isDirty(CHILD_FINANCE_INVOICE_FORM)(state),
  isEdit: !!(params.invoiceId || invoiceId),
  isFetching: appSelectors.getIsFetching(invoicesPreviewState, nurseriesSingleState, childFinanceDetailsState),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
  nurseryInvoiceSettingsDueDate: nurseriesSelectors.getDueDate(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const mapDispatch = {
  stopSubmitForm: (fieldErrors) => stopSubmit(CHILD_FINANCE_INVOICE_FORM, fieldErrors),
  submitForm: () => submit(CHILD_FINANCE_INVOICE_FORM),
  updateField: (fieldName, fieldValue) => change(CHILD_FINANCE_INVOICE_FORM, fieldName, fieldValue),
}

const enhance = compose(
  withAppService,
  withRouter,
  withModalService,
  withRouterUtils,
  withChildService,
  withNurseriesService,
  withInvoicesService,
  withChildAdhocSessionsService,
  withChildFundingService,
  withChildExtraItemsService,
  connect(mapState, mapDispatch),
  withInvoiceSendHandlers,
)

export default enhance(ChildFinanceInvoiceContainer)
