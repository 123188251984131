import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change, getFormSyncErrors, getFormValues, reset, stopSubmit } from 'redux-form'

import { ALL_ROOMS } from 'services/legacy/rooms/constants'

import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withRequestedExtraSessionsService } from 'services/legacy/requestedExtraSessions'
import { withRoomsService } from 'services/legacy/rooms'

import RegularUnavailableTimesModalView from './RegularUnavailableTimesModalView'
import { REGULAR_UNAVAILABLE_TIMES_MODAL_FORM } from './components/RegularUnavailableTimesModalForm'

const GROUPS = {
  read: ['unavailableTime'],
}

class RegularUnavailableTimesModalContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formErrors: [],
    }
  }

  componentDidMount() {
    const { roomsActions } = this.props

    const apiParams = {
      criteria: [{
        field: 'archived',
        value: 0,
      }, {
        field: 'type',
        value: 'teaching',
      }],
    }

    roomsActions.listRecurrency(apiParams)
  }

  componentWillUnmount() {
    const { resetForm, roomsActions } = this.props

    roomsActions.clear()
    resetForm()
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSubmitFailed = (response) => {
    const { injectValidation } = this.props
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    return injectValidation(errors)
  }

  handleSubmitSuccess = ({ data }) => {
    const { onSuccess } = this.props

    let errors = []

    _.forEach(data, ({ exception, unavailableTime }) => {
      if (exception) {
        const { day } = unavailableTime || {}

        _.forEach(exception.error.extra, (value, key) => {
          errors.push(`${_.upperFirst(day)}${key && ', '}${key}: ${value}`)
        })
      }
    })

    errors = _.uniq(errors)

    if (!_.isEmpty(errors)) {
      return this.setState({ formErrors: errors })
    }

    onSuccess()

    return this.handleCloseClick()
  }

  handleSubmit = (values) => {
    const {
      requestedExtraSessionsActions,
      requestedExtraSessionsSelectors,
    } = this.props

    const payload = requestedExtraSessionsSelectors.getRegularUnavailableTimesPayload(values)

    return requestedExtraSessionsActions.updateBatchRegularUnavailableTimes({
      body: payload,
      onFailed: this.handleSubmitFailed,
      onSuccess: this.handleSubmitSuccess,
      params: { groups: GROUPS },
    })
  }

  handleChangeField = (fieldName, value) => {
    const { changeFieldValue } = this.props

    changeFieldValue(fieldName, value)
  }

  handleDeleteAll = () => {
    const { formValues } = this.props

    _.forEach(formValues, (value, key) => {
      this.handleChangeField(key, [{}])
    })
  }

  render() {
    const {
      errorMessages,
      formSyncErrors,
      formValues,
      initialValues,
      isFetching,
      isSubmitting,
      openingDays,
      roomsOptions,
    } = this.props
    const { formErrors } = this.state

    return (
      <RegularUnavailableTimesModalView
        errorMessages={[
          ...(errorMessages || []),
          ...formErrors,
        ]}
        formErrors={formErrors}
        formSyncErrors={formSyncErrors}
        formValues={formValues}
        initialValues={initialValues}
        isFetching={isFetching}
        isSubmitting={isSubmitting}
        openingDays={openingDays}
        roomsOptions={[
          ALL_ROOMS,
          ...roomsOptions || [],
        ]}
        onCloseClick={this.handleCloseClick}
        onDeleteAll={this.handleDeleteAll}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  changeFieldValue: (field, value) => change(REGULAR_UNAVAILABLE_TIMES_MODAL_FORM, field, value),
  injectValidation: (data) => stopSubmit(REGULAR_UNAVAILABLE_TIMES_MODAL_FORM, data),
  resetForm: () => reset(REGULAR_UNAVAILABLE_TIMES_MODAL_FORM),
}

const mapState = (state, {
  appSelectors,
  regularUnavailableTimesBatchState,
  regularUnavailableTimesListState,
  requestedExtraSessionsSelectors,
  roomsSelectors,
}) => ({
  errorMessages: appSelectors.getErrorMessages(regularUnavailableTimesBatchState, regularUnavailableTimesListState),
  formSyncErrors: getFormSyncErrors(REGULAR_UNAVAILABLE_TIMES_MODAL_FORM)(state),
  formValues: getFormValues(REGULAR_UNAVAILABLE_TIMES_MODAL_FORM)(state),
  initialValues: requestedExtraSessionsSelectors.getRegularUnavailableTimesInitialValues(state),
  isFetching: appSelectors.getIsSubmitting(regularUnavailableTimesListState),
  isSubmitting: appSelectors.getIsSubmitting(regularUnavailableTimesBatchState),
  roomsOptions: roomsSelectors.getRoomsOptionsDropdownData(state),
})

const enhance = compose(
  withAppService,
  withModalService,
  withRequestedExtraSessionsService,
  withRoomsService,
  connect(mapState, mapDispatch),
)

export default enhance(RegularUnavailableTimesModalContainer)
