import React from 'react'

import { Page, Section } from 'components'

import i18n from 'translations'

import { PAGE } from './constants'
import { ImportDuplicates, ImportErrors, ImportIntro } from './components'

const FinancePaymentsImportView = ({
  currentPage,
  duplicates,
  errorMessages,
  errors,
  files,
  isProcessing,
  onCancelClick,
  onDownloadTemplateClick,
  onFilesChange,
  onImportClick,
  onRemoveFile,
  onReturnToCsvUploadClick,
}) => {
  const renderImportIntroPage = () => (
    <ImportIntro
      errorMessages={errorMessages}
      files={files}
      isProcessing={isProcessing}
      onCancelClick={onCancelClick}
      onDownloadTemplateClick={onDownloadTemplateClick}
      onFilesChange={onFilesChange}
      onImportClick={onImportClick}
      onRemoveFile={onRemoveFile}
    />
  )

  const renderImportErrorsPage = () => (
    <ImportErrors
      errors={errors}
      onReturnToCsvUploadClick={onReturnToCsvUploadClick}
    />
  )

  const renderImportDuplicatesPage = () => (
    <ImportDuplicates
      duplicates={duplicates}
      errorMessages={errorMessages}
      isProcessing={isProcessing}
      onCancelClick={onCancelClick}
      onImportClick={onImportClick}
    />
  )

  const renderContent = () => {
    switch (currentPage) {
      case PAGE.INTRO:
        return renderImportIntroPage()
      case PAGE.ERRORS:
        return renderImportErrorsPage()
      case PAGE.DUPLICATES:
        return renderImportDuplicatesPage()
      default:
        return null
    }
  }

  return (
    <Page>
      <Page.Content>
        <Section title={i18n.t('module:Modals:PaymentsImport:title')}>
          {renderContent()}
        </Section>
      </Page.Content>
    </Page>
  )
}

export default FinancePaymentsImportView
