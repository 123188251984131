import React, { useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'

import { RootState } from 'core/reducers'

import { generateRoute } from 'utils/routing'

import { withAppService, withAppServiceProps } from 'services/app'
import { withOrganizationService, withOrganizationServiceProps } from 'services/organization'
import { getAuthOrganization } from 'services/security/selectors'
import { withRouter, withRouterProps } from 'services/router'

import GlobalEditView from './GlobalEditView'

const ORGANIZATION_SECURITY_SETTINGS_GROUPS = {
  read: [
    'organization.organizationSettings',
    'organizationSettings',
    'organizationSettings.security',
    'organizationSecuritySettings',
  ],
}

type GlobalEditContainerProps = withOrganizationServiceProps & withAppServiceProps & withRouterProps

const mapState = (state: RootState, {
  appSelectors,
  organizationSelectors,
  organizationSingleState,
}: GlobalEditContainerProps) => ({
  authOrganization: getAuthOrganization(state),
  errorMessages: appSelectors.getErrorMessages(organizationSingleState),
  isFetching: appSelectors.getIsFetching(organizationSingleState),
  isGlobalInheritanceEnabled: organizationSelectors.getSecurityIsGlobalInheritanceEnabled(state),
  isSubmitting: appSelectors.getIsSubmitting(organizationSingleState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const GlobalEditContainer: React.FC<GlobalEditContainerProps & PropsFromRedux> = ({
  authOrganization,
  isFetching,
  isGlobalInheritanceEnabled,
  isSubmitting,
  navigate,
  organizationActions,
  organizationSelectors,
  organizationSingleState,
}) => {
  const [initialValues, setInitialValues] = useState(null)

  useEffect(() => {
    organizationActions.get(authOrganization.id, {
      params: {
        groups: ORGANIZATION_SECURITY_SETTINGS_GROUPS,
      },
    })

    return () => {
      organizationActions.clearSingle()
    }
  }, [])

  useEffect(() => {
    if (null !== isGlobalInheritanceEnabled && undefined !== isGlobalInheritanceEnabled) {
      setInitialValues({
        globalSecuritySettingsTypes: organizationSelectors.getGlobalSecurityOption(isGlobalInheritanceEnabled)?.value,
      })
    }
  }, [isGlobalInheritanceEnabled])

  const handleSuccess = () => {
    navigate(generateRoute('SETTINGS.SECURITY.INDEX'))
  }

  const handleSubmit = (fields) => {
    const payload = organizationSelectors.getSecuritySettingsPayload({
      fields,
      organizationSingleState,
    })

    organizationActions.update(authOrganization.id, {
      onSuccess: handleSuccess,
      params: {
        groups: ORGANIZATION_SECURITY_SETTINGS_GROUPS,
      },
      payload,
    })
  }

  return (
    <GlobalEditView
      initialValues={initialValues}
      isFormLoading={isSubmitting}
      isLoading={isFetching}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withRouter,
  withAppService,
  withOrganizationService,
  connector,
)

export default enhance(GlobalEditContainer)
