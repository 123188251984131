import _ from 'lodash'

export const addDiaryRecord = (state, payload, findDiaryRecordCallback) => {
  const { data, record } = payload
  const { childRecord, newDiaryRecord } = findDiaryRecordCallback(state, data)

  if (!childRecord) {
    const newChildRecord = {
      ...record,
      diaryRecords: [newDiaryRecord],
    }

    if (!newChildRecord.childRegister && data.child) {
      newChildRecord.childRegister = {}
      newChildRecord.childRegister.child = data.child
    }

    const newData = [...state.data]

    newData.splice(_.sortedIndexBy(newData, newChildRecord, 'childRegister.child.name'), 0, newChildRecord)

    return {
      ...state,
      data: newData,
      meta: {
        ...state.meta,
        total_results: (state.meta.total_results || 0) + 1, // eslint-disable-line
      },
    }
  }

  return {
    ...state,
    data: _.map(state.data, (item) => {
      if (childRecord.childRegister.id === item.childRegister.id) {
        return {
          ...item,
          diaryRecords: item.diaryRecords.length
            ? [...item.diaryRecords, newDiaryRecord]
            : [newDiaryRecord],
        }
      }

      return item
    }),
  }
}

export const updateDiaryRecord = (state, data, findDiaryRecordCallback) => {
  const { childRecord, newDiaryRecord } = findDiaryRecordCallback(state, data)

  if (!childRecord) {
    return { ...state }
  }

  return {
    ...state,
    data: _.map(state.data, (item) => ({
      ...item,
      diaryRecords: _.map(item.diaryRecords, (diaryRecord) => {
        if (childRecord.childRegister.id === item.childRegister.id && diaryRecord.id === data.id) {
          return newDiaryRecord
        }

        return diaryRecord
      }),
    })),
  }
}

export const removeDiaryRecord = (state, id, updatedDataCallback) => {
  const newData = _.map(state.data, (item) => ({
    ...item,
    diaryRecords: _.filter(item.diaryRecords, (diaryRecord) => diaryRecord.id !== id),
  }))

  const updatedData = updatedDataCallback ? updatedDataCallback(newData) : newData

  return {
    ...state,
    data: updatedData,
    meta: {
      ...state.meta,
      total_results: updatedData.length, // eslint-disable-line
    },
  }
}
