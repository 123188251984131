import React from 'react'

import { generateRoute } from 'utils/routing'

import { Button } from 'components'

import i18n from 'translations'

import { StyledLink, StyledReactLink } from './NurseriesListStyled'

export const renderItem = ({
  activeChildrenCount,
  archived,
  id,
  name,
  publicUrl,
}) => ({
  activeChildren: activeChildrenCount,
  domain: (
    <StyledLink
      href={publicUrl}
      target="_blank"
    >
      {i18n.t('module:Nurseries:List:manageThisNursery')}
    </StyledLink>
  ),
  edit: (
    <Button.TableAction
      to={generateRoute('NURSERIES.EDIT', { nurseryId: id })}
      edit
    />
  ),
  nursery: (
    <StyledReactLink
      to={generateRoute('NURSERIES.DETAILS', { nurseryId: id })}
    >
      {archived ? `${name} (${i18n.t('global:disabled')})` : name}
    </StyledReactLink>
  ),
})
