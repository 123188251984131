import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { FEATURE_FLAGS, ROLES } from 'constants/security'

import { EVENTS, logEvent } from 'analytics'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withMembershipRegistersService } from 'services/legacy/membershipRegisters'
import { withModalService } from 'services/utils/modal'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'

import StaffAttendanceView from './StaffAttendanceView'

const GROUPS = {
  read: [
    'membership',
    'membership.details',
    'membership.profile',
    'membership.shifts',
    'membershipRegister.entries',
    'membershipRegisterEntry',
    'membershipShift',
    'membershipShift.membershipShiftTimes',
    'membershipShiftTime',
    'membershipShift.leaveShiftType',
    'leaveShiftType',
  ],
}

const NURSERY_SETTINGS_GROUP = {
  read: [
    'nursery.settings',
    'nurserySettings',
    'nurserySettings.staffRota',
    'nurseryStaffRotaSettings',
  ],
}

class StaffAttendanceContainer extends Component {
  constructor(props) {
    super(props)

    const {
      location: { query: { date } },
      paginationUtils: { setPageLocationQuery },
    } = props

    this.state = {
      filters: {
        date: date ? moment(date, DEFAULT_DATE_FORMAT) : moment().startOf('week'),
        search: null,
      },
    }

    setPageLocationQuery(false)
  }

  componentDidMount() {
    const { nurseriesActions, nurseryOptions } = this.props

    nurseriesActions.get(nurseryOptions.id, {
      params: {
        groups: NURSERY_SETTINGS_GROUP,
      },
    })

    this.fetch()

    logEvent(EVENTS.STAFF_ATTENDNACE_PAGE_VIEWED)
  }

  componentWillUnmount() {
    const { membershipRegistersActions } = this.props

    membershipRegistersActions.clearList()
  }

  fetch = () => {
    const { membershipRegistersActions, membershipRegistersSelectors, paginationUtils, setLocationQuery } = this.props
    const { filters } = this.state
    const { date, search } = filters
    const { page } = paginationUtils

    setLocationQuery({
      ...filters,
      date: moment(filters.date).format(DEFAULT_DATE_FORMAT),
    })

    const criteria = membershipRegistersSelectors.getMembershipRegistersListCriteria({
      dateFromShiftTimes: moment(date).startOf('week').format(DEFAULT_DATE_FORMAT),
      dateToShiftTimes: moment(date).endOf('week').format(DEFAULT_DATE_FORMAT),
      endDate: moment(date).endOf('week').format(DEFAULT_DATE_FORMAT),
      searchBarText: search,
      startDate: moment(date).startOf('week').format(DEFAULT_DATE_FORMAT),
    })

    membershipRegistersActions.list({
      mergeResult: 1 !== page,
      params: {
        criteria,
        groups: GROUPS,
        page,
      },
    })
  }

  handleChangeDate = (date) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        date: date[0],
      },
    }), () => onPageChange(this.fetch)(1))

    logEvent(EVENTS.STAFF_ATTENDNACE_FILTER_USED, { type: 'date' })
  }

  handleSearchChange = (search) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        search,
      },
    }), () => onPageChange(this.fetch)(1))

    logEvent(EVENTS.STAFF_ATTENDNACE_FILTER_USED, { type: 'search' })
  }

  handleChangePage = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleEditShiftSuccess = (copy, payload) => {
    const { modalActions, modalConsts, paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(1)

    if (copy) {
      modalActions.show(modalConsts.TYPES.STAFF_ROTA_SHIFT_COPY, {
        payload,
      })
    }
  }

  handleEditShift = (record) => {
    const { modalActions, modalConsts, nurseryStaffRotaSettings } = this.props
    const { filters } = this.state
    const { date } = filters
    const { accepted, id: membership, membershipProfile, name } = record

    modalActions.show(modalConsts.TYPES.STAFF_ROTA_SHIFT, {
      attendanceView: true,
      disablePastWeek: !nurseryStaffRotaSettings?.pastRotaCanBeEdited,
      initialValues: {
        date: [moment(date).startOf('week'), moment(date).endOf('week')],
        membership: {
          accepted,
          label: name,
          membershipProfile,
          value: membership,
        },
      },
      onSuccess: this.handleEditShiftSuccess,
    }, {
      enableMultipleModal: true,
    })
  }

  render() {
    const {
      isFetching,
      paginationUtils,
      records,
      totalResults,
    } = this.props
    const { filters } = this.state
    const { date } = filters
    const { getPageCount, page } = paginationUtils
    const dateRange = [date, moment(date).endOf('week')]
    const pageCount = getPageCount(totalResults)

    return (
      <StaffAttendanceView
        date={date}
        dateRange={dateRange}
        isFetching={isFetching}
        page={page}
        pageCount={pageCount}
        records={records}
        totalResults={totalResults}
        onChangeDate={this.handleChangeDate}
        onChangePage={this.handleChangePage}
        onEditShift={this.handleEditShift}
        onSearchChange={this.handleSearchChange}
      />
    )
  }
}

StaffAttendanceContainer.authParams = {
  flags: [FEATURE_FLAGS.STAFF_REGISTER],
  nurseryContext: true,
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

const mapState = (state, {
  appSelectors,
  membershipRegistersListState,
  membershipRegistersSelectors,
  nurseriesSelectors,
  params,
}) => ({
  isFetching: appSelectors.getIsFetching(membershipRegistersListState),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  nurseryStaffRotaSettings: nurseriesSelectors.getNurseryStaffRotaSettings(state),
  records: membershipRegistersSelectors.getMembershipRegistersListDataSelector(state),
  totalResults: appSelectors.getTotalResults(membershipRegistersListState),
})

const enhance = compose(
  withAppService,
  withNurseriesService,
  withMembershipRegistersService,
  withModalService,
  withPaginationUtils,
  withRouterUtils,
  connect(mapState),
)

export default enhance(StaffAttendanceContainer)
