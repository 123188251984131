import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withModalService } from 'services/utils/modal'
import { withAppService } from 'services/app'
import { withNurseryConsumablesService } from 'services/nurseryConsumables'
import { withRouter } from 'services/router'

import ManagementConsumablesV2AddView from './ManagementConsumablesV2AddView'

const CONSUMABLES_GROUP = {
  read: [
    'nurseryConsumable',
    'nurseryConsumable.settings',
  ],
}

class ManagementConsumablesV2AddContainer extends Component {
  componentDidMount() {
    const { isEdit, nurseryConsumablesActions, params } = this.props
    const { itemId } = params

    if (isEdit) {
      nurseryConsumablesActions.get({
        params: [itemId, { groups: CONSUMABLES_GROUP }],
      })
    }
  }

  componentWillUnmount() {
    const { nurseryConsumablesActions } = this.props

    nurseryConsumablesActions.clear()
  }

  goToConsumables = () => {
    const { navigate, nurseryOptions } = this.props

    navigate(`${nurseryOptions.prefix}/finance-settings/consumables`)
  }

  handleSubmitSuccess = () => {
    this.goToConsumables()
  }

  handleSubmit = (fields) => {
    const {
      isEdit,
      modalActions,
      modalConsts,
      nurseryConsumablesActions,
      nurseryConsumablesSelectors,
      params,
    } = this.props
    const { itemId } = params

    const payload = nurseryConsumablesSelectors.getPayload(fields)
    if (isEdit) {
      return modalActions.show(modalConsts.TYPES.CONFIRM, {
        icon: 'warning',
        onConfirm: () => nurseryConsumablesActions.update({
          body: payload,
          onSuccess: this.handleSubmitSuccess,
          params: [itemId],
        }),
        text: `
          Are you sure you to update the consumables?
          Please note: removing any consumables will only affect uninvoiced sessions.
        `,
      })
    }

    return nurseryConsumablesActions.create({
      body: payload,
      onSuccess: this.handleSubmitSuccess,
      params: {},
    })
  }

  archiveConsumable = (archived) => () => {
    const { nurseryConsumablesActions, nurseryConsumablesSelectors, params, type } = this.props
    const { itemId } = params

    const payload = nurseryConsumablesSelectors.getArchivePayload({ archived, type })

    return nurseryConsumablesActions.update({
      body: payload,
      onSuccess: this.handleSubmitSuccess,
      params: [itemId],
    })
  }

  handleArchiveClick = (archived) => () => {
    const { modalActions, modalConsts } = this.props

    const label = archived ? 'archive' : 'unarchive'
    const text = archived
      ? (
        'Are you sure you want to archive this consumable? '
          .concat('Please note: past invoices showing this consumable will not be affected')
      )
      : (
        'Are you sure you want to unarchive this consumable? '
          .concat('You can archive it again at any time.')
      )

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: label,
      icon: label,
      onConfirm: this.archiveConsumable(archived),
      text,
    })
  }

  render() {
    const {
      consumable,
      errorMessages,
      isArchived,
      isEdit,
      isFetching,
      nurseryConsumablesSelectors,
      nurseryConsumablesSingleState,
      nurseryOptions,
    } = this.props

    const isFormLoading = nurseryConsumablesSingleState.isSubmitting
    const initialValues = nurseryConsumablesSelectors.getFormInitialValues(consumable)

    return (
      <ManagementConsumablesV2AddView
        errorMessages={errorMessages}
        initialValues={initialValues}
        isArchived={isArchived}
        isEdit={isEdit}
        isFormLoading={isFormLoading}
        isLoading={isFetching}
        nurseryOptions={nurseryOptions}
        onArchiveClick={this.handleArchiveClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseryConsumablesSelectors,
  nurseryConsumablesSingleState,
  params,
}) => ({
  consumable: nurseryConsumablesSelectors.getNurseryConsumablesSingleSelector(state),
  errorMessages: appSelectors.getErrorMessages(nurseryConsumablesSingleState),
  isArchived: nurseryConsumablesSelectors.isArchived(state),
  isEdit: !!params.itemId,
  isFetching: appSelectors.getIsFetching(nurseryConsumablesSingleState),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const enhance = compose(
  withRouter,
  withModalService,
  withAppService,
  withNurseryConsumablesService,
  connect(mapState),
)

export default enhance(ManagementConsumablesV2AddContainer)
