import _ from 'lodash'

import React, { useEffect, useRef } from 'react'
import { compose } from 'recompose'

import { FRAMEWORK_GROUPED_BY } from 'services/legacy/frameworks/constants'

import { getBrandingColor } from 'utils/branding'
import { generateRoute } from 'utils/routing'

import { withRouter } from 'services/router'

import { Spinner, Typography } from 'components'

import FrameworkItem from '../FrameworkItem'
import {
  StyledAreaGroupWrapper,
  StyledAreasSection,
  StyledButton,
  StyledButtonLabel,
  StyledCategoryHeader,
  StyledCategoryWrapper,
  StyledFrameworkTableWrapper,
  StyledGroupsSection,
} from './FrameworkTableStyled'

const AREA_PREFIX = 'area'
const GROUP_PREFIX = 'group'
export const FRAMEWORK_PREFIX = 'framework'

const FrameworkTable = ({
  childObservation,
  framework,
  frameworkDetails,
  groupedBy,
  isFetching,
  isFetchingChildFrameworkProgress,
  location,
  navigate,
  nurseryLearningSettings,
  observation,
  onChangeItemsDuringProcessing,
  params,
  progressLevels,
  showCompletedStatements,
}) => {
  const scrollWrapper = useRef(null)

  const { query: { area, group } } = location
  const { areas, structure } = frameworkDetails || {}
  const groups = _.get(_.find(areas, ({ id }) => id === +area), 'groups')
  const currentGroup = _.find(groups, ({ id }) => id === +group)
  const { categories } = currentGroup || {}

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const { ageMonths } = childObservation?.child || {}
    const categoryIndex = _.findIndex(categories, ({ fromMonths, toMonths }) => (
      ageMonths >= (fromMonths || ageMonths) && ageMonths <= (toMonths || ageMonths)
    ))

    // Weird error, template style corrupts eslint - we try this fix in future (after 07.2021)
    // eslint-disable-next-line prefer-template
    const header = scrollWrapper?.current?.querySelector('header:nth-of-type(' + (categoryIndex + 1) + ')')

    if (ageMonths && scrollWrapper?.current && header) {
      setTimeout(() => {
        scrollWrapper.current?.scrollTo({
          top: (header.offsetTop - scrollWrapper.current.offsetTop) || 0,
        })
      })
    }
  }, [childObservation?.child?.ageMonths, categories, scrollWrapper])

  if (isFetching || !frameworkDetails || _.isNull(framework)) {
    return (
      <Spinner />
    )
  }

  if (!area || !_.find(areas, ({ id }) => id === +area)) {
    const newArea = areas[0]
    const newGroup = newArea.groups[0]

    navigate(
      `${
        generateRoute('LEARNING.OBSERVATIONS.EDIT.FRAMEWORKS', params)
      }?${FRAMEWORK_PREFIX}=${framework}&${AREA_PREFIX}=${newArea.id}&${GROUP_PREFIX}=${newGroup.id}`,
    )
  } else if (!group || !_.find(groups, ({ id }) => id === +group)) {
    navigate(
      `${
        generateRoute('LEARNING.OBSERVATIONS.EDIT.FRAMEWORKS', params)
      }?${FRAMEWORK_PREFIX}=${framework}&${AREA_PREFIX}=${area}&${GROUP_PREFIX}=${groups[0].id}`,
    )
  }

  const renderItems = (items) => (
    <div>
      {_.map(items, (item) => {
        const { id } = item

        return (
          <FrameworkItem
            childObservation={childObservation}
            frameworkDetails={frameworkDetails}
            isFetchingChildFrameworkProgress={isFetchingChildFrameworkProgress}
            item={item}
            key={id}
            nurseryLearningSettings={nurseryLearningSettings}
            observation={observation}
            progressAsCheckbox={structure.progressAsCheckbox}
            progressLevels={progressLevels}
            showCompletedStatements={showCompletedStatements}
            onChangeItemsDuringProcessing={onChangeItemsDuringProcessing}
          />
        )
      })}
    </div>
  )

  const renderCategories = () => (
    <StyledCategoryWrapper ref={scrollWrapper}>
      {_.map(categories, ({ id, items, name }) => (
        <React.Fragment key={id}>
          {structure.observation?.category?.visible && (
            <StyledCategoryHeader>
              <Typography fontSize={18}>
                {name}
              </Typography>
            </StyledCategoryHeader>
          )}
          {renderItems(items)}
        </React.Fragment>
      ))}
    </StyledCategoryWrapper>
  )

  if (FRAMEWORK_GROUPED_BY.AREA === groupedBy) {
    return (
      <StyledAreaGroupWrapper>
        {_.map(areas, (item) => (
          <FrameworkItem
            childObservation={childObservation}
            frameworkDetails={frameworkDetails}
            groupBy={FRAMEWORK_GROUPED_BY.AREA}
            item={item}
            key={item.id}
            nurseryLearningSettings={nurseryLearningSettings}
            observation={observation}
            progressLevels={progressLevels}
            showCompletedStatements={showCompletedStatements}
            progressAsCheckbox
            onChangeItemsDuringProcessing={onChangeItemsDuringProcessing}
          />
        ))}
      </StyledAreaGroupWrapper>
    )
  }

  if (FRAMEWORK_GROUPED_BY.GROUP === groupedBy) {
    return (
      <StyledFrameworkTableWrapper areaVisible>
        <StyledAreasSection>
          {_.map(areas, ({ id, name, shortName }) => (
            <StyledButton
              color={id === +area && getBrandingColor('senary-color')}
              key={id}
              label={(
                <StyledButtonLabel>
                  {shortName || name}
                </StyledButtonLabel>
              )}
              size="large"
              disableElevation
              onClick={() => navigate(`${
                generateRoute('LEARNING.OBSERVATIONS.EDIT.FRAMEWORKS', params)
              }?${FRAMEWORK_PREFIX}=${framework}&${AREA_PREFIX}=${id}`)}
            />
          ))}
        </StyledAreasSection>
        <div>
          {_.map(groups, (item) => (
            <FrameworkItem
              childObservation={childObservation}
              frameworkDetails={frameworkDetails}
              groupBy={FRAMEWORK_GROUPED_BY.GROUP}
              item={item}
              key={item.id}
              nurseryLearningSettings={nurseryLearningSettings}
              observation={observation}
              progressLevels={progressLevels}
              showCompletedStatements={showCompletedStatements}
              progressAsCheckbox
              onChangeItemsDuringProcessing={onChangeItemsDuringProcessing}
            />
          ))}
        </div>
      </StyledFrameworkTableWrapper>
    )
  }

  return (
    <StyledFrameworkTableWrapper
      areaVisible={structure.observation?.area?.visible}
      groupVisible={structure.observation?.group?.visible}
    >
      {structure.observation?.area?.visible && (
        <StyledAreasSection>
          {_.map(areas, ({ id, name, shortName }) => (
            <StyledButton
              color={id === +area && getBrandingColor('senary-color')}
              key={id}
              label={(
                <StyledButtonLabel>
                  {shortName || name}
                </StyledButtonLabel>
              )}
              size="large"
              disableElevation
              onClick={() => navigate(`${
                generateRoute('LEARNING.OBSERVATIONS.EDIT.FRAMEWORKS', params)
              }?${FRAMEWORK_PREFIX}=${framework}&${AREA_PREFIX}=${id}`)}
            />
          ))}
        </StyledAreasSection>
      )}
      {structure.observation?.group?.visible && (
        <StyledGroupsSection>
          {_.map(groups, ({ id, name }) => (
            <StyledButton
              color={id === +group && getBrandingColor('senary-color')}
              key={id}
              label={name}
              size="large"
              disableElevation
              onClick={() => navigate(
                `${generateRoute(
                  'LEARNING.OBSERVATIONS.EDIT.FRAMEWORKS',
                  params,
                )}?${FRAMEWORK_PREFIX}=${framework}&${AREA_PREFIX}=${area}&${GROUP_PREFIX}=${id}`,
              )}
            />
          ))}
        </StyledGroupsSection>
      )}
      {renderCategories()}
    </StyledFrameworkTableWrapper>
  )
}

const enhance = compose(
  withRouter,
)

export default enhance(FrameworkTable)
