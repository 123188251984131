import React, { useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'

import { RootState } from 'core/reducers'

import { withAppService, withAppServiceProps } from 'services/app'
import { withAuthenticationService, withAuthenticationServiceProps } from 'services/authentication'
import { withDeviceService, withDeviceServiceProps } from 'services/device'
import { withShellService, withShellServiceProps } from 'services/shell'
import { withRouter, withRouterProps } from 'services/router'

import InvitationView from './InvitationView'

type InvitationContainerFullProps = withAuthenticationServiceProps
  & withAppServiceProps
  & withDeviceServiceProps
  & withShellServiceProps
  & withRouterProps

const mapState = (state: RootState, {
  appSelectors,
  authenticationCommonState,
  authenticationSelectors,
}: InvitationContainerFullProps) => ({
  errorMessages: appSelectors.getErrorMessages(authenticationCommonState),
  isUserLoggedIn: authenticationSelectors.getIsUserLoggedIn(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const REGISTER_STATUS = {
  NEEDS_PASSWORD: 'SET_PASSWORD',
  READY: 'READY',
}

const InvitationContainer: React.FC<InvitationContainerFullProps & PropsFromRedux> = ({
  authenticationActions,
  errorMessages,
  location,
  router,
  shellActions,
}) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [isLinkExpired, setIsLinkExpired] = useState<boolean>(false)
  const token = location.query.t

  const handleRegisterSuccess = (response) => {
    setIsInitialized(true)

    if (REGISTER_STATUS.READY === response?.status) {
      authenticationActions.logout()

      return router.replace('/')
    }

    if (REGISTER_STATUS.NEEDS_PASSWORD === response?.status) {
      return null
    }

    return setIsLinkExpired(true)
  }

  useEffect(() => {
    shellActions.setSettings({
      minimal: true,
    })

    authenticationActions.register({
      body: { token },
      onFailed: handleRegisterSuccess,
      onSuccess: handleRegisterSuccess,
    })
  }, [])

  const handleSubmit = (values) => {
    const { password } = values || {}
    const body = { password, token }

    setIsFetching(true)
    authenticationActions.register({
      body,
      onFailed: handleRegisterSuccess,
      onSuccess: handleRegisterSuccess,
    })
  }

  return (
    <InvitationView
      errorMessages={errorMessages}
      isFetching={isFetching}
      isInitialized={isInitialized}
      isLinkExpired={isLinkExpired}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withAppService,
  withAuthenticationService,
  withDeviceService,
  withRouter,
  withShellService,
  connect(mapState),
)

export default enhance(InvitationContainer)
