import React from 'react'
import { connect } from 'react-redux'
import { stopSubmit } from 'redux-form'

import { constants as invoicesConstants } from 'services/legacy/invoices'
import invoiceSelectors from 'services/legacy/invoices/selectors'

import i18n from 'translations'

const withInvoiceSendHandlers = (WrappedComponent) => {
  const Component = (props) => {
    const { modalActions, modalConsts } = props

    const displayMissingPaymentContactAlert = (paymentEmail, onMissingPaymentContact) => {
      if (onMissingPaymentContact && !paymentEmail) {
        modalActions.show(modalConsts.TYPES.ALERT, {
          label: i18n.t('module:Finance:InvoiceSendHandler.MissingPaymentEmailPopup:label'),
          onClick: onMissingPaymentContact,
          text: i18n.t('module:Finance:InvoiceSendHandler.MissingPaymentEmailPopup:text'),
        })

        return false
      }

      return true
    }

    const handleSendConflict = ({ errorMessage, onClickCallback }) => {
      const { code, extra } = errorMessage

      const text = invoiceSelectors.createErrorText({ code, extra })

      modalActions.show(modalConsts.TYPES.ALERT, {
        icon: 'warning',
        label: invoicesConstants.ERROR_CODES.MISSING_PAYMENT_CONTACT === code
          ? i18n.t('module:Finance:InvoiceSendHandler.MissingPaymentEmailPopup:label')
          : i18n.t('global:ok'),
        onClick: onClickCallback || undefined,
        text,
      })
    }

    const displayInvoiceNumberPopup = ({ childId, firstName, onConfirm }) => {
      modalActions.show(modalConsts.TYPES.SEND_DRAFT_INVOICE, {
        childId,
        firstName,
        onContinueClick: (newInvoiceNumber, errorCallback) => onConfirm(newInvoiceNumber, errorCallback),
      }, {
        enableMultipleModal: true,
      })
    }

    const handleSendButtonClick = ({
      childId,
      firstName,
      invoiceNumber,
      isDraft,
      onConfirm,
      onMissingPaymentContact,
      paymentEmail,
      showInvoiceNumberPopup = false,
    }) => {
      if (!displayMissingPaymentContactAlert(paymentEmail, onMissingPaymentContact)) {
        return
      }

      if (showInvoiceNumberPopup && !invoiceNumber) {
        displayInvoiceNumberPopup({ childId, firstName, onConfirm })

        return
      }

      const text = invoiceSelectors.getSendInvoiceText({ firstName, isDraft: isDraft ?? true })

      modalActions.show(modalConsts.TYPES.CONFIRM, {
        onConfirm: () => onConfirm(invoiceNumber),
        text,
      })
    }

    const handleMarkAsSentClick = ({
      childId,
      firstName,
      invoiceNumber,
      onConfirm,
      showInvoiceNumberPopup,
    }) => {
      if (showInvoiceNumberPopup && !invoiceNumber) {
        return displayInvoiceNumberPopup({ childId, firstName, onConfirm })
      }

      return onConfirm(invoiceNumber)
    }

    const sendHandlers = {
      handleMarkAsSentClick,
      handleSendButtonClick,
      handleSendConflict,
    }

    const sendHelpers = {
      displayMissingPaymentContactAlert,
    }

    return (
      <WrappedComponent
        sendHandlers={sendHandlers}
        sendHelpers={sendHelpers}
        {...props}
      />
    )
  }

  const mapDispatch = {
    injectValidation: (formName, errors) => stopSubmit(formName, errors),
  }

  return connect(null, mapDispatch)(Component)
}

export default withInvoiceSendHandlers
