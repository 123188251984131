import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { RootState } from 'core/reducers'

import { injectActions } from 'utils/service'

import actions from './actions'
import selectors from './selectors'
import { NurseryConsumablesListState } from './list/reducer'
import { NurseryConsumablesSingleState } from './single/reducer'

export interface withNurseryConsumablesServiceProps {
  nurseryConsumablesActions: typeof actions
  nurseryConsumablesListState: NurseryConsumablesListState
  nurseryConsumablesSelectors: typeof selectors
  nurseryConsumablesSingleState: NurseryConsumablesSingleState
}

const withNurseryConsumablesService = <P extends withNurseryConsumablesServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ nurseryConsumables }: RootState) => ({ nurseryConsumables })

  const mapDispatch = injectActions('nurseryConsumablesActions', actions)

  const Component = ({ nurseryConsumables, nurseryConsumablesActions, ...others }) => (
    <WrappedComponent
      nurseryConsumablesActions={nurseryConsumablesActions}
      nurseryConsumablesListState={nurseryConsumables.list}
      nurseryConsumablesSelectors={selectors}
      nurseryConsumablesSingleState={nurseryConsumables.single}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withNurseryConsumablesService
