import { generateBasicActions } from 'utils/service'

import {
  ADD_CHILDREN_TO_OBSERVATION,
  AUTOSAVE_OBSERVATION,
  AUTOSAVE_OBSERVATION_FAILED,
  AUTOSAVE_OBSERVATION_SUCCESS,
  CLEAR_OBSERVATION,
  CREATE_OBSERVATION,
  CREATE_OBSERVATION_FAILED,
  CREATE_OBSERVATION_SUCCESS,
  GET_OBSERVATION,
  GET_OBSERVATION_FAILED,
  GET_OBSERVATION_SUCCESS,
  REMOVE_CHILDREN_FROM_OBSERVATION,
  REMOVE_OBSERVATION,
  REMOVE_OBSERVATION_FAILED,
  REMOVE_OBSERVATION_SUCCESS,
  SET_NEW_OBSERVATION,
  UPDATE_DRAFT_OBSERVATION,
  UPDATE_DRAFT_OBSERVATION_FAILED,
  UPDATE_DRAFT_OBSERVATION_SUCCESS,
  UPDATE_OBSERVATION,
  UPDATE_OBSERVATION_FAILED,
  UPDATE_OBSERVATION_SUCCESS,
  UPDATE_REVIEW_OBSERVATION,
  UPDATE_REVIEW_OBSERVATION_FAILED,
  UPDATE_REVIEW_OBSERVATION_SUCCESS,
} from './constants'

export const create = (options = {}) => ({ observationsApiClient }) => generateBasicActions.create({
  constants: {
    failed: CREATE_OBSERVATION_FAILED,
    init: CREATE_OBSERVATION,
    success: CREATE_OBSERVATION_SUCCESS,
  },
  options,
  service: observationsApiClient,
  serviceMethod: 'create',
})

export const get = (options = {}) => ({ observationsApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_OBSERVATION_FAILED,
    init: GET_OBSERVATION,
    success: GET_OBSERVATION_SUCCESS,
  },
  options,
  service: observationsApiClient,
  serviceMethod: 'get',
})

export const remove = (options = {}) => ({ observationsApiClient }) => generateBasicActions.remove({
  constants: {
    failed: REMOVE_OBSERVATION_FAILED,
    init: REMOVE_OBSERVATION,
    success: REMOVE_OBSERVATION_SUCCESS,
  },
  options,
  service: observationsApiClient,
  serviceMethod: 'remove',
})

export const update = (options = {}) => ({ observationsApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_OBSERVATION_FAILED,
    init: UPDATE_OBSERVATION,
    success: UPDATE_OBSERVATION_SUCCESS,
  },
  options,
  service: observationsApiClient,
  serviceMethod: 'update',
})

export const updateDraft = (options = {}) => ({ observationsApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_DRAFT_OBSERVATION_FAILED,
    init: UPDATE_DRAFT_OBSERVATION,
    success: UPDATE_DRAFT_OBSERVATION_SUCCESS,
  },
  options,
  service: observationsApiClient,
  serviceMethod: 'update',
})

export const updateReview = (options = {}) => ({ observationsApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_REVIEW_OBSERVATION_FAILED,
    init: UPDATE_REVIEW_OBSERVATION,
    success: UPDATE_REVIEW_OBSERVATION_SUCCESS,
  },
  options,
  service: observationsApiClient,
  serviceMethod: 'update',
})

export const updateAutosave = (options = {}) => ({ observationsApiClient }) => generateBasicActions.update({
  constants: {
    failed: AUTOSAVE_OBSERVATION_FAILED,
    init: AUTOSAVE_OBSERVATION,
    success: AUTOSAVE_OBSERVATION_SUCCESS,
  },
  options,
  service: observationsApiClient,
  serviceMethod: 'update',
})

export const addChildrenToObservation = (payload) => ({
  payload,
  type: ADD_CHILDREN_TO_OBSERVATION,
})

export const setNewObservation = (observation) => () => (dispatch) => {
  dispatch({
    payload: observation,
    type: SET_NEW_OBSERVATION,
  })
}

export const removeChildFromObservation = (payload) => ({
  payload,
  type: REMOVE_CHILDREN_FROM_OBSERVATION,
})

export const clear = () => ({
  type: CLEAR_OBSERVATION,
})
