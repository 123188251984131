import React from 'react'

import RegularBookingsAddForm, {
  RegularBookingsAddFormProps,
  RegularBookingsAddFormValues,
} from './components/RegularBookingsAddForm/RegularBookingsAddForm'

interface RegularBookingsAddViewProps extends RegularBookingsAddFormProps {
  initialValues: RegularBookingsAddFormValues
}

const RegularBookingsAddView: React.FC<RegularBookingsAddViewProps> = ({ ...props }) => (
  <RegularBookingsAddForm {...props} />
)

export default RegularBookingsAddView

