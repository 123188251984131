import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'

import { Select } from 'components'

import i18n from 'translations'

import withInfiniteDropdownHelpers from './withInfiniteDropdown'

class ChildrenDropdown extends Component {
  componentDidMount() {
    const { childActions, infiniteDropdownHelpers, value } = this.props

    if (!_.isArray(value)) {
      return infiniteDropdownHelpers.handleComponentDidMount({
        action: () => childActions.get({
          addToList: true,
          onFailed: this.notFoundValue,
          onSuccess: infiniteDropdownHelpers.handleComponentDidMountOnSuccess,
          params: [value?.value || value],
        }),
        value,
      })
    }

    return null
  }

  componentWillUnmount() {
    const { childActions } = this.props

    childActions.clearDropdownList()
  }

  handleLoadMoreElements = async (phrase) => {
    const { childActions, childSelectors, infiniteDropdownHelpers, limit } = this.props

    return infiniteDropdownHelpers.handleLoadMoreElements({
      clearAction: childActions.clearDropdownList,
      listAction: ({ newPage, newSearchPhrase, result }) => childActions.dropdownList({
        criteria: childSelectors.getCriteria({ displayName: newSearchPhrase }),
        limit,
        page: newPage,
      }, true, (response) => infiniteDropdownHelpers.handleLoadMoreElementsOnSuccess({
        newPage,
        newSearchPhrase,
        response,
        result,
      })),
      phrase,
    })
  }

  notFoundValue = () => {
    const { onChange } = this.props

    onChange(null)
  }

  handleChange = (e) => {
    const { infiniteDropdownHelpers, onChange } = this.props

    infiniteDropdownHelpers.handleOnChange(e, () => onChange(e))
  }

  render() {
    const {
      disabled,
      infiniteDropdownState,
      placeholder,
      rawValue,
      value,
      ...rest
    } = this.props
    const { cacheUniq, options, selectedValue } = infiniteDropdownState

    return (
      <Select
        {...rest}
        cacheUniq={cacheUniq}
        disabled={disabled}
        loadOptions={this.handleLoadMoreElements}
        options={options}
        placeholder={placeholder || i18n.t('global:Children')}
        rawValue={rawValue || selectedValue || value}
        value={value}
        searchable
        withAvatars
        onChange={this.handleChange}
      />
    )
  }
}

const enhance = compose(
  withAppService,
  withChildService,
  withInfiniteDropdownHelpers,
)

export default enhance(ChildrenDropdown)
