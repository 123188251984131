import { generateBasicActions } from 'utils/service'

import {
  CLEAR_AGE_BANDS,
  GET_FRAMEWORK_AGE_BANDS,
  GET_FRAMEWORK_AGE_BANDS_FAILED,
  GET_FRAMEWORK_AGE_BANDS_SUCCESS,
} from './constants'

export const getAgeBands = (options = {}) => ({ frameworksApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_FRAMEWORK_AGE_BANDS_FAILED,
    init: GET_FRAMEWORK_AGE_BANDS,
    success: GET_FRAMEWORK_AGE_BANDS_SUCCESS,
  },
  options,
  service: frameworksApiClient,
  serviceMethod: 'getAgeBands',
})

export const clearAgeBands = () => ({
  type: CLEAR_AGE_BANDS,
})
