import { Request } from 'constants/http'

const RemoveRedundantQuestionMarkMiddleware = (request: Request, next) => {
  const newRequest = { ...request } as Request

  if ('?' === newRequest.path?.slice(-1)) {
    newRequest.path = newRequest.path.slice(0, newRequest.path.length - 1)
  }

  return next(newRequest)
}

export default RemoveRedundantQuestionMarkMiddleware
