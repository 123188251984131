import React from 'react'
import { WrappedFieldProps } from 'redux-form'

import { TemplateTextFieldProps } from 'components/TemplateTextField/TemplateTextField'

import { TemplateTextField } from 'components'

type FormTemplateTextFieldFullProps = TemplateTextFieldProps & WrappedFieldProps

const FormTemplateTextField: React.FC<FormTemplateTextFieldFullProps> = ({
  input: { onBlur, onChange, value },
  meta: { error, submitFailed, valid },
  ...rest
}) => (
  <TemplateTextField
    {...rest}
    error={submitFailed && error}
    valid={!(submitFailed && !valid)}
    value={value}
    onBlur={onBlur}
    onChange={onChange}
  />
)

export default FormTemplateTextField
