import { generateBasicActions } from 'utils/service'

import {
  ADD_OR_UPDATE_ABSENCE_TO_LIST,
  IMPORT_GLOBAL_LIST,
  LIST_REGISTERS,
  LIST_REGISTERS_BY_CHILD,
  LIST_REGISTERS_BY_CHILD_FAILED,
  LIST_REGISTERS_BY_CHILD_SUCCESS,
  LIST_REGISTERS_FAILED,
  LIST_REGISTERS_SUCCESS,
  LOAD_TODAY_DATA,
  REGISTER_DOWNLOAD,
  REGISTER_DOWNLOAD_FAILED,
  REGISTER_DOWNLOAD_SUCCESS,
  REMOVE_ABSENCE_FROM_LIST,
  REMOVE_ITEM_FROM_LIST,
  RESET_LIST_ITEM,
} from './constants'

export const list = (params, mergeResult, silent) => ({ registerApiClient }) => (dispatch) => {
  dispatch({
    silent,
    type: LIST_REGISTERS,
  })

  return registerApiClient.list(params).then(
    (data) => {
      dispatch({
        mergeResult,
        payload: data,
        type: LIST_REGISTERS_SUCCESS,
      })
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: LIST_REGISTERS_FAILED,
      })
    },
  )
}

export const listByChild = (childId, params) => ({ registerApiClient }) => (dispatch) => {
  dispatch({
    childId,
    params,
    type: LIST_REGISTERS_BY_CHILD,
  })

  registerApiClient.listByChild(childId, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: LIST_REGISTERS_BY_CHILD_SUCCESS,
      })
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: LIST_REGISTERS_BY_CHILD_FAILED,
      })
    },
  )
}

export const download = (options = {}) => ({ registerApiClient }) => generateBasicActions.get({
  constants: {
    failed: REGISTER_DOWNLOAD_FAILED,
    init: REGISTER_DOWNLOAD,
    success: REGISTER_DOWNLOAD_SUCCESS,
  },
  options,
  service: registerApiClient,
  serviceMethod: 'download',
})

export const addOrUpdateAbsenceToList = (childId, absenceResponse, payload) => () => (dispatch) => {
  dispatch({
    absenceResponse,
    childId,
    payload,
    type: ADD_OR_UPDATE_ABSENCE_TO_LIST,
  })
}

export const removeAbsenceFromList = (childId, payload) => () => (dispatch) => {
  dispatch({
    childId,
    payload,
    type: REMOVE_ABSENCE_FROM_LIST,
  })
}

export const resetListItem = (childId, id, resetResponse, payload) => () => (dispatch) => {
  dispatch({
    childId,
    id,
    payload,
    resetResponse,
    type: RESET_LIST_ITEM,
  })
}

export const removeItemFromList = (childId, payload) => () => (dispatch) => {
  dispatch({
    childId,
    payload,
    type: REMOVE_ITEM_FROM_LIST,
  })
}

export const importGlobalRegister = (payload, isToday) => ({
  payload: { isToday, payload },
  type: IMPORT_GLOBAL_LIST,
})

export const loadTodayData = () => ({
  type: LOAD_TODAY_DATA,
})
