import styled from 'styled-components'

import colors from 'constants/colors'

export const StyledTopLabelContainer = styled.div`
  margin-left: 5px;
  margin-bottom: 4px;
  color: #757575;
  font-size: 14px;
`

export const StyledTopLabelEmptyContainer = styled.div`
  height: 20px;
`

export const StyledRequiredChar = styled.span`
  color: ${colors.pink};
`
