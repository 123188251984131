import { generateBasicActions } from 'utils/service'

import {
  CLEAR_CHILD_OBSERVATION,
  GET_CHILD_OBSERVATION,
  GET_CHILD_OBSERVATION_FAILED,
  GET_CHILD_OBSERVATION_SUCCESS,
  REMOVE_CHILD_OBSERVATION,
  REMOVE_CHILD_OBSERVATION_FAILED,
  REMOVE_CHILD_OBSERVATION_SUCCESS,
  REMOVE_MONTESSORI_ACTIVITIES_IN_CHILD_OBSERVATION,
  REMOVE_MONTESSORI_ACTIVITIES_IN_CHILD_OBSERVATION_FAILED,
  REMOVE_MONTESSORI_ACTIVITIES_IN_CHILD_OBSERVATION_SUCCESS,
  UPDATE_CHILD_OBSERVATION,
  UPDATE_CHILD_OBSERVATION_FAILED,
  UPDATE_CHILD_OBSERVATION_SUCCESS,
  UPDATE_MONTESSORI_ACTIVITIES_IN_CHILD_OBSERVATION,
} from './constants'

export const get = (options = {}) => ({ childObservationsApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_CHILD_OBSERVATION_FAILED,
    init: GET_CHILD_OBSERVATION,
    success: GET_CHILD_OBSERVATION_SUCCESS,
  },
  options,
  service: childObservationsApiClient,
  serviceMethod: 'get',
})

export const remove = (options = {}) => ({ childObservationsApiClient }) => generateBasicActions.remove({
  constants: {
    failed: REMOVE_CHILD_OBSERVATION_FAILED,
    init: REMOVE_CHILD_OBSERVATION,
    success: REMOVE_CHILD_OBSERVATION_SUCCESS,
  },
  options,
  service: childObservationsApiClient,
  serviceMethod: 'remove',
})

export const removeMontessoriActivities = (options = {}) => ({ childObservationsApiClient }) => (
  generateBasicActions.remove({
    constants: {
      failed: REMOVE_MONTESSORI_ACTIVITIES_IN_CHILD_OBSERVATION_FAILED,
      init: REMOVE_MONTESSORI_ACTIVITIES_IN_CHILD_OBSERVATION,
      success: REMOVE_MONTESSORI_ACTIVITIES_IN_CHILD_OBSERVATION_SUCCESS,
    },
    options,
    service: childObservationsApiClient,
    serviceMethod: 'removeMontessoriActivities',
  })
)

export const update = (options = {}) => ({ childObservationsApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_CHILD_OBSERVATION_FAILED,
    init: UPDATE_CHILD_OBSERVATION,
    success: UPDATE_CHILD_OBSERVATION_SUCCESS,
  },
  options,
  service: childObservationsApiClient,
  serviceMethod: 'update',
})

export const clear = () => ({
  type: CLEAR_CHILD_OBSERVATION,
})

export const updateMontessoriActivitiesInChildObservation = (payload) => ({
  payload,
  type: UPDATE_MONTESSORI_ACTIVITIES_IN_CHILD_OBSERVATION,
})
