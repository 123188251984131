import _ from 'lodash'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getAllergyType } from 'services/legacy/child/helpers'

import { EmptyState, Icon, Typography, XMasIcon } from 'components'
import Widget from 'module/Dashboard/components/Widget'

import { StyledCountContainer } from './TodayAllergiesWidgetStyled'

const TodayAllergiesWidgetView = ({
  allergyChildren,
  isLoading,
}) => {
  const renderListItem = (child, index) => {
    const { firstName, id, surname } = child

    const renderName = () => {
      if (!firstName && !surname) {
        return null
      }

      return (
        <React.Fragment>
          {firstName || ''}
          {' '}
          {surname || ''}
        </React.Fragment>
      )
    }

    return (
      <Widget.List.Row
        childList={[child]}
        index={index}
        key={id}
        leftColumn={(
          <React.Fragment>
            <Typography
              color={NEUTRAL_COLOURS.WHITE}
              ellipsis
            >
              {renderName()}
            </Typography>
            <Typography
              color={NEUTRAL_COLOURS.WHITE}
              ellipsis
            >
              {getAllergyType(child.allergies, child.allergiesDetails)}
            </Typography>
          </React.Fragment>
        )}
      />
    )
  }

  const renderContent = () => {
    if (!allergyChildren || !allergyChildren.length) {
      return (
        <React.Fragment>
          <XMasIcon height="50px" icon="candy" marginLeft="35px" marginTop="62px" width="34px" />
          <EmptyState
            iconComponent={(
              <Icon
                color={NEUTRAL_COLOURS.WHITE}
                height={60}
                icon="allergies"
              />
          )}
            text1="There are no children with allergies today"
          />
          <XMasIcon height="30px" icon="circle-variant-1" marginLeft="240px" marginTop="71px" width="30px" />
          <XMasIcon height="19px" icon="circle-variant-3" marginLeft="295px" marginTop="42px" width="18px" />
        </React.Fragment>
      )
    }

    return (
      <Widget.List>
        {_.map(allergyChildren, renderListItem)}
      </Widget.List>
    )
  }

  const renderHeader = () => {
    if (!allergyChildren || !allergyChildren.length) {
      return 'Today\'s allergies'
    }

    return (
      <React.Fragment>
        {'Today\'s allergies'}
        <StyledCountContainer>
          {allergyChildren.length}
        </StyledCountContainer>
      </React.Fragment>
    )
  }

  return (
    <Widget>
      <Widget.Header>
        <XMasIcon height="25px" icon="snowflake-variant-3" marginLeft="-274px" width="25px" />
        {renderHeader()}
      </Widget.Header>
      <Widget.Content
        isLoading={isLoading}
      >
        {renderContent()}
      </Widget.Content>
    </Widget>
  )
}

export default TodayAllergiesWidgetView
