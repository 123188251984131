import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import {
  CLEAR_FORMATIVE_REPORT_MONTESSORI_ITEMS,
  GET_FORMATIVE_REPORT_MONTESSORI_ITEMS,
  GET_FORMATIVE_REPORT_MONTESSORI_ITEMS_FAILED,
  GET_FORMATIVE_REPORT_MONTESSORI_ITEMS_SUCCESS,
} from './constants'

export default (...props) => {
  const [state = getBasicListInitialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_FORMATIVE_REPORT_MONTESSORI_ITEMS,
    failed: GET_FORMATIVE_REPORT_MONTESSORI_ITEMS_FAILED,
    init: GET_FORMATIVE_REPORT_MONTESSORI_ITEMS,
    success: GET_FORMATIVE_REPORT_MONTESSORI_ITEMS_SUCCESS,
  }, getBasicListInitialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
