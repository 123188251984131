import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as childrenSignedUpReportSelectors from './selectors'
import {
  ChildrenSignedUpReportChildrenState,
  ChildrenSignedUpReportNurseriesState,
  ChildrenSignedUpReportRootState,
  ChildrenSignedUpReportStatisticsState,
} from './models'

export interface withChildrenSignedUpReportServiceProps {
  childrenSignedUpReportActions: typeof actions
  childrenSignedUpReportChildrenState: ChildrenSignedUpReportChildrenState
  childrenSignedUpReportNurseriesState: ChildrenSignedUpReportNurseriesState
  childrenSignedUpReportSelectors: typeof childrenSignedUpReportSelectors
  childrenSignedUpReportStatisticsState: ChildrenSignedUpReportStatisticsState
}

const withChildrenSignedUpReportService = <P extends withChildrenSignedUpReportServiceProps>
  (WrappedComponent: WrappedComponentType<P>) => {
  const mapState = ({ childrenSignedUpReport }: ChildrenSignedUpReportRootState) => ({ childrenSignedUpReport })

  const mapDispatch = injectActions('childrenSignedUpReportActions', actions)

  const Component = ({ childrenSignedUpReport, childrenSignedUpReportActions, ...others }) => (
    <WrappedComponent
      childrenSignedUpReportActions={childrenSignedUpReportActions}
      childrenSignedUpReportChildrenState={childrenSignedUpReport.children}
      childrenSignedUpReportNurseriesState={childrenSignedUpReport.nurseries}
      childrenSignedUpReportSelectors={childrenSignedUpReportSelectors}
      childrenSignedUpReportStatisticsState={childrenSignedUpReport.statistics}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withChildrenSignedUpReportService
