import { FRAMEWORK_TYPE_MONTESSORI } from 'services/legacy/frameworks/constants'

export const updateFrameworkData = ({
  changeFieldValue,
  childObservation,
  childObservationsActions,
  formValues,
  index,
  newFramework,
  nextStepId,
  nextStepsActions,
  oldFramework,
  onAutoSave,
  onSuccess,
}) => {
  const { nextSteps } = formValues

  const oldValue = oldFramework?.value
  const newValue = newFramework?.value
  const isOldFrameworkMontessori = oldValue === FRAMEWORK_TYPE_MONTESSORI
  const isNewFrameworkMontessori = newValue === FRAMEWORK_TYPE_MONTESSORI

  // To check if user select the same framework then do not clear the data
  if (oldValue && newValue && oldValue === newValue) {
    return onSuccess()
  }

  nextSteps[index] = {
    ...nextSteps[index],
    comments: null,
    frameworkAreas: [],
  }
  changeFieldValue('nextSteps', nextSteps)

  if (!oldValue && newValue && !isNewFrameworkMontessori) {
    return onAutoSave(nextStepId, index, onSuccess)
  }

  if (oldValue && newValue && !isOldFrameworkMontessori && !isNewFrameworkMontessori) {
    return onAutoSave(nextStepId, index, onSuccess)
  }

  if (oldValue && newValue && !isOldFrameworkMontessori && isNewFrameworkMontessori) {
    return nextStepsActions.remove({
      onSuccess,
      params: [nextStepId],
    })
  }

  if (oldValue && newValue && isOldFrameworkMontessori && !isNewFrameworkMontessori) {
    return childObservationsActions.removeMontessoriActivities({
      onSuccess: () => {
        childObservationsActions.updateMontessoriActivitiesInChildObservation([null, false, true])
        onAutoSave(null, index, onSuccess)
      },
      params: [childObservation.id],
    })
  }

  return onSuccess()
}
