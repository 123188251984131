import { createSelector } from 'reselect'

const getNurseryFrameworkRelationsSingleState = (state) => state.nurseryFrameworkRelations.single

export const getNurseryFrameworkRelationsSingleDataState = createSelector(
  [getNurseryFrameworkRelationsSingleState],
  (state) => state.data,
)

export const getPayload = ({ enabled, frameworkId, nurseryId }) => ({
  enabled,
  framework: { id: frameworkId },
  nursery: { id: nurseryId },
})
