import {
  CLEAR_SINGLE_CHILD_FUNDING_SESSION_SET,
  CREATE_CHILD_FUNDING_SESSION_SET,
  CREATE_CHILD_FUNDING_SESSION_SET_FAILED,
  CREATE_CHILD_FUNDING_SESSION_SET_SUCCESS,
  DELETE_CHILD_FUNDING_SESSION_SET,
  DELETE_CHILD_FUNDING_SESSION_SET_FAILED,
  DELETE_CHILD_FUNDING_SESSION_SET_SUCCESS,
  GET_CHILD_FUNDING_SESSION_SET,
  GET_CHILD_FUNDING_SESSION_SET_FAILED,
  GET_CHILD_FUNDING_SESSION_SET_SUCCESS,
  UPDATE_CHILD_FUNDING_SESSION_SET,
  UPDATE_CHILD_FUNDING_SESSION_SET_FAILED,
  UPDATE_CHILD_FUNDING_SESSION_SET_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case GET_CHILD_FUNDING_SESSION_SET:
    case DELETE_CHILD_FUNDING_SESSION_SET:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_CHILD_FUNDING_SESSION_SET_SUCCESS:
    case DELETE_CHILD_FUNDING_SESSION_SET_SUCCESS:
      return { ...state, data: payload && payload.data, isFetching: false }
    case GET_CHILD_FUNDING_SESSION_SET_FAILED:
    case DELETE_CHILD_FUNDING_SESSION_SET_FAILED:
      return { ...state, error, isFetching: false }
    case CREATE_CHILD_FUNDING_SESSION_SET:
      return { ...state, error: initialState.error, isSubmitting: true }
    case CREATE_CHILD_FUNDING_SESSION_SET_SUCCESS:
    case CREATE_CHILD_FUNDING_SESSION_SET_FAILED:
      return { ...state, error, isSubmitting: false }
    case UPDATE_CHILD_FUNDING_SESSION_SET:
      return { ...state, error: initialState.error, isSubmitting: true }
    case UPDATE_CHILD_FUNDING_SESSION_SET_SUCCESS:
    case UPDATE_CHILD_FUNDING_SESSION_SET_FAILED:
      return { ...state, error, isSubmitting: false }
    case CLEAR_SINGLE_CHILD_FUNDING_SESSION_SET:
      return initialState
    default:
      return state
  }
}
