import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import { CLEAR_USERS_LIST, GET_USERS_LIST, GET_USERS_LIST_FAILED, GET_USERS_LIST_SUCCESS } from './constants'

export const list = (options: BasicActionOptionsProps) => ({ usersApiClient }) => (
  generateBasicActions.list({
    constants: {
      failed: GET_USERS_LIST_FAILED,
      init: GET_USERS_LIST,
      success: GET_USERS_LIST_SUCCESS,
    },
    options,
    service: usersApiClient,
    serviceMethod: 'list',
  })
)

export const clearList = () => ({
  type: CLEAR_USERS_LIST,
})
