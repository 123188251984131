import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { generateRoute } from 'utils/routing'

import { STAFF_REGISTER_STATUS } from 'services/legacy/membershipRegisters/constants'

import { EVENTS, logEvent } from 'analytics'

import { withAppService } from 'services/app'

import withStaffRegisterWrapper from '../withStaffRegisterWrapper'
import DashboardView from './DashboardView'

export const MEMBERSHIP_REGISTER_GROUP = {
  read: [
    'membershipRegister.membership',
    'membership',
    'membershipRegister.entries',
    'membershipRegisterEntry',
  ],
}

class DashboardContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentDate: moment(),
    }

    this.timeHandler = null

    this.updateCurrentDate()
  }

  componentDidMount() {
    const { membershipRegistersActions, params } = this.props
    const { currentDate } = this.state

    const { staffId } = params || {}

    membershipRegistersActions.get(staffId, currentDate.format('YYYY-MM-DD'), {
      params: { groups: MEMBERSHIP_REGISTER_GROUP },
    })
  }

  componentWillUnmount() {
    clearTimeout(this.timeHandler)
  }

  updateCurrentDate = () => {
    this.timeHandler = setTimeout(() => {
      this.setState({
        currentDate: moment(),
      })

      this.updateCurrentDate()
    }, 30 * 1000)
  }

  handleSuccessEntry = (response) => {
    const { clearStoreAndRedirectToStaffList, router, sendNotification, staff, staffProfile } = this.props
    const { entries } = staff
    const { name } = staffProfile || {}

    sendNotification({
      hasEntries: !!entries?.length,
      name,
      response,
    })

    clearStoreAndRedirectToStaffList(() => router.replace(generateRoute('STAFF_REGISTER.LIST')))
  }

  updateLogEvent = (currentStatus, newStatus) => {
    if (currentStatus === STAFF_REGISTER_STATUS.CLOCKED_OUT && newStatus === STAFF_REGISTER_STATUS.CLOCKED_IN) {
      logEvent(EVENTS.STAFF_REGISTER_CLOCK_IN_CLICKED)
    }

    if (currentStatus === STAFF_REGISTER_STATUS.CLOCKED_IN && newStatus === STAFF_REGISTER_STATUS.CLOCKED_IN) {
      logEvent(EVENTS.STAFF_REGISTER_LOCATION_CHANGE_CLICKED)
    }
  }

  handleStatusChange = (status) => {
    const {
      membershipRegistersActions,
      membershipRegistersSelectors,
      staff,
    } = this.props
    const { entries, status: currentStatus } = staff || {}
    const lastEntry = entries?.length ? entries[entries.length - 1] : {}
    const { id: entryId } = lastEntry

    this.updateLogEvent(currentStatus, status)

    if (status === STAFF_REGISTER_STATUS.CLOCKED_OUT) {
      const payload = membershipRegistersSelectors.getUpdateEntryPayload()

      membershipRegistersActions.updateEntry(entryId, {
        onSuccess: this.handleSuccessEntry,
        payload,
      })

      return
    }

    membershipRegistersActions.setStatus(status)
    this.redirectChangeLocation()
  }

  redirectChangeLocation = () => {
    const { navigate, params } = this.props
    const { staffId } = params || {}

    navigate(generateRoute('STAFF_REGISTER.DASHBOARD.CHANGE_LOCATION', { staffId }))
  }

  handleCancelButtonClick = () => {
    const { clearStoreAndRedirectToStaffList, navigate } = this.props

    clearStoreAndRedirectToStaffList(() => navigate(generateRoute('STAFF_REGISTER.LIST')))
  }

  render() {
    const { errorMessages, isFetching, staff, staffProfile } = this.props
    const { currentDate } = this.state

    return (
      <DashboardView
        currentDate={currentDate}
        errorMessages={errorMessages}
        isFetching={isFetching}
        staff={staff}
        staffProfile={staffProfile}
        onCancelButtonClick={this.handleCancelButtonClick}
        onStatusChange={this.handleStatusChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  authSelectors,
  membershipRegistersSelectors,
  membershipRegistersSingleState,
}) => ({
  errorMessages: appSelectors.getErrorMessages(membershipRegistersSingleState),
  isFetching: appSelectors.getIsFetching(membershipRegistersSingleState),
  staff: membershipRegistersSelectors.getMembershipRegisterSingleFormattedData(state),
  staffProfile: authSelectors.getAuthPinProfile(state),
})

const enhance = compose(
  withAppService,
  withStaffRegisterWrapper,
  connect(mapState),
)

export default enhance(DashboardContainer)

