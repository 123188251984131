import _ from 'lodash'
import moment from 'moment'

import React from 'react'
import { Field, FieldArray, reduxForm } from 'redux-form'
import { compose } from 'recompose'

import { isRequired } from 'utils/fieldValidation'

import { Button, Callout, Form, Section } from 'components'

import i18n from 'translations'

export const FUNDING_ALLOCATION_FORM = 'FUNDING_ALLOCATION_FORM'

const renderRow = (item, i, fields, isSubmitting) => (
  <Form.Row key={_.kebabCase(i)}>
    <Form.Row.Item>
      <Field
        component={Form.TimePicker}
        local={false}
        name={`${item}.startTime`}
        validate={isRequired}
      />
    </Form.Row.Item>
    <Form.Row.TextItem text={i18n.t('global:To')} />
    <Form.Row.Item flex="0">
      <Field
        component={Form.TimePicker}
        local={false}
        name={`${item}.endTime`}
        validate={isRequired}
      />
    </Form.Row.Item>
    <Form.Row.Item>
      <Form.RemoveRowButton
        disabled={isSubmitting}
        trash
        onClick={() => fields.remove(i)}
      />
    </Form.Row.Item>
  </Form.Row>
)

const renderAllocations = ({ fields, isSubmitting }) => (
  <React.Fragment>
    {fields.map((item, i) => renderRow(item, i, fields, isSubmitting))}
    <Button.ActionButton
      disabled={isSubmitting}
      label={i18n.t('module:Children:Child:BookingPattern:Funding:Allocation:Form:addFundingTimeButtonLabel')}
      onClick={() => fields.push({})}
    />
  </React.Fragment>
)

const ChildFundingAllocationForm = ({
  error,
  handleSubmit,
  isSubmitting,
  onCancel,
  onSubmit,
  timeLineData,
}) => {
  const { progress } = timeLineData || {}
  const { endTime, name, startTime } = progress || {}

  const getTimeString = () => {
    const momentStartDate = moment().startOf('day').add(startTime, 'hours')
    const momentEndDate = moment().startOf('day').add(endTime, 'hours')
    const duration = moment.duration(momentEndDate.diff(momentStartDate))
    const hourDiff = duration.asHours()

    return `${momentStartDate.format('HH:mm')} - ${momentEndDate.format('HH:mm')} (${hourDiff} hours)`
  }

  return (
    <Section title={name}>
      <Callout content={error} error />
      <p>
        {getTimeString()}
      </p>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldArray
          component={renderAllocations}
          isSubmitting={isSubmitting}
          name="allocations"
        />
        <Form.FooterActions
          isSubmitting={isSubmitting}
          submitLabel={i18n.t('global:Save')}
          onCancelClick={onCancel}
        />
      </Form>
    </Section>
  )
}

const enhance = compose(reduxForm({ form: FUNDING_ALLOCATION_FORM }))

export default enhance(ChildFundingAllocationForm)
