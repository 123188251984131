import React from 'react'

import { INJURIES_STATUS } from 'services/legacy/injuries/constants'

import { Button, StickyFooter } from 'components'

import i18n from 'translations'

const InjuryStickyFooter = ({
  authAccessMap,
  isGrantedSaveAndRequestForApproval,
  isHomeAccidents,
  isSubmittingUpdateStatus,
  onUpdateStatus,
  status,
}) => {
  if (status === INJURIES_STATUS.SIGNATURES_NEEDED.value || status === INJURIES_STATUS.APPROVED_AND_SIGNED.value) {
    return null
  }

  const renderLeftButton = () => {
    if (
      authAccessMap.section.hasOnlySeniorTeacherOrTeacherAccess
      || (isHomeAccidents && status === INJURIES_STATUS.DRAFT.value)
    ) {
      return null
    }

    return (
      <Button
        isLoading={isSubmittingUpdateStatus}
        label={i18n.t('module:Injury:Preview:approveRecordLabel')}
        negativeMargins
        onClick={() => onUpdateStatus(INJURIES_STATUS.SIGNATURES_NEEDED.value)}
      />
    )
  }

  return (isGrantedSaveAndRequestForApproval || !authAccessMap.section.hasOnlySeniorTeacherOrTeacherAccess) && (
    <StickyFooter>
      <StickyFooter.Flex />
      {renderLeftButton()}
      {isGrantedSaveAndRequestForApproval && (
        <Button
          isLoading={isSubmittingUpdateStatus}
          label={i18n.t('module:Injury:Preview:requestForApprovalLabel')}
          negativeMargins
          onClick={() => onUpdateStatus(INJURIES_STATUS.PENDING_APPROVAL.value)}
        />
      )}
    </StickyFooter>
  )
}

export default InjuryStickyFooter
