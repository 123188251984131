import moment from 'moment'
import _ from 'lodash'

import React from 'react'
import { createSelector } from 'reselect'

import { DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT, DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { Avatar, Typography } from 'components'

import i18n from 'translations'

const getChildMoves = (state) => state.childMoves

export const getChildMovesListSelector = createSelector(
  [getChildMoves],
  (state) => {
    if (!state) {
      return null
    }

    return state.list
  },
)

export const getChildMovesIsFetchingSelector = createSelector(
  [getChildMovesListSelector],
  ({ isFetching }) => isFetching,
)

export const getChildMovesListDataSelector = createSelector(
  [getChildMovesListSelector],
  ({ data }) => {
    if (!data) {
      return null
    }

    return data
  },
)

export const getChildMovesTableListDataSelector = createSelector(
  [getChildMovesListDataSelector],
  (data) => _.map(data, (childMove) => {
    const {
      child,
      confirmedAt,
      fromNurseryClass,
      id,
      moveOnChildMonths,
      moveOnDate,
      toLeaving,
      toNurseryClass,
    } = childMove
    const { ageMonths, birthDate, firstName, photo, surname } = child

    const renderSubtitle = (subTitle) => (
      <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
        {subTitle}
      </Typography>
    )

    return {
      child: (
        <Avatar
          avatarSize="small"
          initials={[firstName, surname]}
          src={photo}
          subTitle={(
            renderSubtitle(
              `${i18n.t('global:ageMonths', { ageMonths })} (${moment(birthDate).format(DISPLAY_SHORT_DATE_FORMAT)})`)
          )}
          title={<Typography>{`${firstName} ${surname}`}</Typography>}
          primary
        />
      ),
      confirmedAt,
      fromNurseryClass: (
        <div>
          <Typography>{fromNurseryClass.name}</Typography>
          {fromNurseryClass
              && Number.isInteger(fromNurseryClass.startAgeInMonths)
              && Number.isInteger(fromNurseryClass.endAgeInMonths)
              && (
                renderSubtitle(i18n.t('global:ageMonths', {
                  ageMonths: `${fromNurseryClass.startAgeInMonths} - ${fromNurseryClass.endAgeInMonths}`,
                }))
              )}
        </div>
      ),
      id,
      moveOn: moveOnDate && moment(moveOnDate).format(DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT),
      moveOnChildMonths: (
        <div>
          {i18n.t('global:ageMonths', { ageMonths: moveOnChildMonths })}
        </div>
      ),
      toNurseryClass: toLeaving ? (
        <div>
          Leaving
        </div>
      ) : (
        <div>
          <Typography>{toNurseryClass?.name}</Typography>
          {toNurseryClass
              && Number.isInteger(toNurseryClass.startAgeInMonths)
              && Number.isInteger(toNurseryClass.endAgeInMonths)
              && (renderSubtitle(i18n.t('global:ageMonths', {
                ageMonths: `${toNurseryClass.startAgeInMonths}-${toNurseryClass.endAgeInMonths}`,
              })))}
        </div>
      ),
    }
  }),
)

export const getChildMovesListMetaSelector = createSelector(
  [getChildMovesListSelector],
  ({ meta }) => {
    if (!meta) {
      return {}
    }

    return {
      totalResults: meta.total_results,
    }
  },
)

const getFilters = (filters) => filters

export const getCriteriaSelector = createSelector(
  [getFilters],
  (filters) => {
    const { child, isArchived, moveOn, room, search } = filters
    const criteria = []

    if (room) {
      criteria.push({
        field: 'fromNurseryClass',
        value: room,
      })
    }

    if (moveOn) {
      const { after, before } = moveOn

      if (before) {
        criteria.push({
          comparator: 'before',
          field: 'moveOn',
          value: moment(before).set('hour', 23).set('minute', 59).toISOString(),
        })
      }

      if (after) {
        criteria.push({
          comparator: 'after',
          field: 'moveOn',
          value: moment(after).toISOString(),
        })
      }
    }

    if (child) {
      criteria.push({
        field: 'child',
        value: child,
      })
    }

    if (search) {
      criteria.push({
        field: 'concatenable',
        value: search,
      })
    }

    if (null !== isArchived && undefined !== isArchived) {
      criteria.push({
        field: 'child.isArchived',
        value: +isArchived,
      })
    }

    return criteria
  },
)

export const getInitialValues = (childMove = {}) => {
  const data = {}
  const { assignedTeachers, moveOnDate, toLeaving, toNurseryClass } = childMove

  if (toLeaving) {
    data.toNurseryClass = {
      label: 'Leaving',
      value: '',
    }
  } else if (toNurseryClass) {
    const { id, name } = childMove.toNurseryClass

    data.toNurseryClass = {
      label: name,
      value: id,
    }
  }

  if (assignedTeachers) {
    data.assignedTeachers = _.map(assignedTeachers, ({ id, name }) => ({
      label: name,
      value: id,
    }))
  }

  if (moveOnDate) {
    data.moveOn = moveOnDate
  }

  return data
}

export const hasFutureConfirmedRoomMovesSelector = (childMoveId) => createSelector(
  [getChildMovesListDataSelector],
  (childMovesList) => {
    if (!childMovesList || !childMovesList.length) {
      return false
    }

    const currentRoomMove = _.find(childMovesList, ({ id }) => id === childMoveId)
    const currentMoveOn = _.get(currentRoomMove, 'moveOn')

    const futureConfirmedRoomMoves = _.filter(
      childMovesList,
      ({ confirmedAt, moveOn }) => confirmedAt && moment(moveOn).unix() > moment(currentMoveOn).unix(),
    )

    return !!(futureConfirmedRoomMoves && futureConfirmedRoomMoves.length)
  },
)
