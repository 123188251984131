import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired, isSubdomainValid, isValidPhotoUploadType } from 'utils/fieldValidation'

import { Banner, Form, Space } from 'components'

import i18n from 'translations'

import FeatureFlagsFormSection from './FeatureFlagsFormSection'

export const ORGANISATIONS_ADD_FORM = 'OrganisationsAddForm'

const OrganisationsAddForm = ({
  cancelButtonLink,
  financeLocked,
  formValues,
  handleSubmit,
  isCreating,
  isOrganisationArchived,
  isSubmitting,
  onDisableClick,
  onFeatureFlagChange,
  onNameChange,
  onSubmit,
}) => {
  const renderFeatureFlagsSection = () => !isCreating && (
    <FeatureFlagsFormSection
      formValues={formValues}
      isOrganizationContext
      onFeatureFlagChange={onFeatureFlagChange}
    />
  )

  const renderForm = () => (
    <React.Fragment>
      <Form.Row
        label={i18n.t('module:Organisations:Add:Form:name')}
        required
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextField}
            name="name"
            placeholder={i18n.t('module:Organisations:Add:Form:name')}
            validate={isRequired}
            onChange={isCreating && onNameChange}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Organisations:Add:Form:subdomain')}
        required
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextField}
            name="subdomain"
            placeholder={i18n.t('module:Organisations:Add:Form:subdomain')}
            validate={[isRequired, isSubdomainValid]}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Organisations:Add:Form:country')}
        required
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            clearable={false}
            component={Form.InfiniteDropdowns.SupportedLocale}
            name="organizationSettings.locale"
            placeholder={i18n.t('module:Organisations:Add:Form:country')}
            validate={[isRequired]}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Organisations:Add:Form:currency')}
        required
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            clearable={false}
            component={Form.InfiniteDropdowns.SupportedCurrency}
            disabled={!isCreating && financeLocked}
            name="organizationSettings.currency"
            placeholder={i18n.t('module:Organisations:Add:Form:currency')}
            validate={[isRequired]}
          />
          <Space space="10px" />
          <Banner.Info>
            {i18n.t('module:Organisations:Add:Form:currencyCannotBeChanged')}
          </Banner.Info>
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:Organisations:Add:Form:logo')}>
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.PhotoUpload}
            name="logo"
            validate={isValidPhotoUploadType}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      {renderFeatureFlagsSection()}
    </React.Fragment>
  )

  const renderFooter = () => (
    <Form.FooterActions
      cancelLink={cancelButtonLink}
      isSubmitting={isSubmitting}
      secondary={{
        auth: !isCreating,
        isLoading: isSubmitting,
        label: isOrganisationArchived
          ? i18n.t('module:Organisations:Edit:Enable:label')
          : i18n.t('module:Organisations:Edit:Disable:label'),
        onClick: onDisableClick,
      }}
      submitLabel={isCreating
        ? i18n.t('module:Organisations:Add:title')
        : i18n.t('module:Organisations:Edit:Update:label')}
    />
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {renderForm()}
      {renderFooter()}
    </Form>
  )
}

export default reduxForm({ form: ORGANISATIONS_ADD_FORM })(OrganisationsAddForm)
