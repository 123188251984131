import React, { Component } from 'react'

import { FUNDING_MODAL_TYPE } from './constants'
import FundingModalView from './FundingModalView'

class FundingModalContainer extends Component {
  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleCreateSuccess = (response) => {
    const { onCreateSuccess } = this.props

    if (onCreateSuccess) {
      return onCreateSuccess(response)
    }

    return this.handleCloseClick()
  }

  render() {
    const { modalComponentType = FUNDING_MODAL_TYPE.ADD_NURSERY_FUNDING, title, ...props } = this.props

    return (
      <FundingModalView
        modalComponentType={modalComponentType}
        title={title}
        onModalCancelClick={this.handleCloseClick}
        onModalCreateSuccess={this.handleCreateSuccess}
        {...props}
      />
    )
  }
}

export default FundingModalContainer
