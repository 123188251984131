import React from 'react'

import { FLAG_COLOURS } from 'constants/colors'

import { Callout } from 'components'

const BannerWarning = ({ children, ...rest }) => (
  <Callout
    {...rest}
    color={FLAG_COLOURS.WARNING}
    content={children}
    icon="exclamation-mark"
    iconVariant="circle"
  />
)

export default BannerWarning
