import { CHANGE_DEVICE_IDENTITY, CHANGE_DEVICE_REAL_IDENTITY } from './constants'

const initialState = {
  deviceIdentity: null,
  deviceRealIdentity: null,
}

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case CHANGE_DEVICE_IDENTITY:
      return { ...state, deviceIdentity: payload }
    case CHANGE_DEVICE_REAL_IDENTITY:
      return { ...state, deviceRealIdentity: payload }
    default:
      return state
  }
}
