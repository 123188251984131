import React from 'react'
import i18n from 'translations'

import { getBrandingColor } from 'utils/branding'
import { generateRoute } from 'utils/routing'

import { Arc, ConfirmedIcon, FolderList, Popover, ProfileHeader, Section, Typography } from 'components'

import { StyledSetupContentContainer } from './ChildHeaderStyled'

const ChildHeaderSetup = ({
  authAccessMap,
  childId,
  hasChildMoves,
  hasRelations,
  hasSessions,
  isFinanceV3Enabled,
}) => {
  const total = authAccessMap.Occupancy ? 3 : 2
  const completed = +hasChildMoves + +hasRelations + +hasSessions
  const completedPercentage = Math.floor((completed * 100) / total)
  const completedPercentageCopy = `${completedPercentage}%`
  const completedColor = (
    50 < completedPercentage
      ? getBrandingColor('primary-color-lighten-20')
      : getBrandingColor('quaternary-color')
  )

  return (
    <Popover
      button={(
        <ProfileHeader.WhiteBox cursor="pointer">
          <Arc
            color={completedColor}
            content={completedPercentageCopy}
            contentFontSize={12}
            size={50}
            total={total}
            value={completed}
          />
        </ProfileHeader.WhiteBox>
      )}
      placement="bottom-end"
      zIndex={1000}
    >
      <StyledSetupContentContainer>
        <Section
          actions={(
            <Typography
              color={completedColor}
              fontSize={22}
              bold
            >
              {completedPercentageCopy}
            </Typography>
          )}
          title={i18n.t('module:Children:Child:Setup:title')}
          variant="small"
        >
          <FolderList noBorder smallPadding>
            <FolderList.Item
              actions={(
                <ConfirmedIcon
                  isCompleted={hasRelations}
                  tooltip={i18n.t('module:Children:Child:Setup:Contact:tooltip')}
                />
              )}
              icon="parent"
              title={i18n.t('module:Children:Child:Setup:Contact:title')}
              to={generateRoute('CHILDREN.CHILD.CONTACTS', { childId })}
            />
            <FolderList.Item
              actions={(
                <ConfirmedIcon
                  isCompleted={hasSessions}
                  tooltip={i18n.t('module:Children:Child:Setup:Session:tooltip')}
                />
              )}
              icon="sessions"
              title={i18n.t('module:Children:Child:Setup:Session:title')}
              to={isFinanceV3Enabled
                ? generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS.ADD', { childId })
                : generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS', { childId })}
            />
            {authAccessMap.Occupancy && (
              <FolderList.Item
                actions={(
                  <ConfirmedIcon
                    isCompleted={hasChildMoves}
                    tooltip={i18n.t('module:Children:Child:Setup:RoomMoves:tooltip')}
                  />
                )}
                icon="room-moves"
                title={i18n.t('module:Children:Child:Setup:RoomMoves:title')}
                to={generateRoute('CHILDREN.CHILD.ABOUT.ROOMS_MOVES', { childId })}
              />
            )}
          </FolderList>
        </Section>
      </StyledSetupContentContainer>
    </Popover>
  )
}

export default ChildHeaderSetup
