import { createSelector } from 'reselect'

const getHomeObservationsState = (state) => state.homeObservations

export const getHomeObservationsListState = createSelector([getHomeObservationsState], (state) => state.list)

export const getHomeObservationsListDataState = createSelector([getHomeObservationsListState], (state) => state?.data)

export const getCriteria = (filters) => {
  if (!filters) {
    return null
  }

  const { childId, isArchived, keyWorker } = filters

  const criteria = []

  if (keyWorker) {
    criteria.push({
      field: 'child.keyWorkers',
      value: keyWorker,
    })
  }

  if (childId) {
    criteria.push({
      field: 'child',
      value: +childId,
    })
  }

  if (null !== isArchived && undefined !== isArchived) {
    criteria.push({
      field: 'child.isArchived',
      value: +isArchived,
    })
  }

  return criteria
}
