import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { Form } from 'components'

import i18n from 'translations'

import { ALL_CHILDREN } from '../constants'

export const CHILDREN_EXPORT_FORM = 'ChildrenExportForm'

const ChildrenExportForm = ({
  formValues,
  handleSubmit,
  isProcessing,
  onCloseClick,
  onSubmit,
}) => {
  const renderForm = () => (
    <React.Fragment>
      <Form.Row
        label={i18n.t('global:Children')}
        verticalLabel
      >
        <Field
          clearable={false}
          component={Form.InfiniteDropdowns.Children}
          extraOptions={[ALL_CHILDREN]}
          name="child"
          placeholder={i18n.t('global:Children')}
          validate={isRequired}
        />
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Modals:ChildrenExport:sendTo')}
        verticalLabel
      >
        <Field
          clearable={false}
          component={Form.InfiniteDropdowns.OrganizationMemberships}
          name="membership"
          placeholder={i18n.t('module:Modals:ChildrenExport:sendTo')}
          validate={isRequired}
        />
      </Form.Row>
      {ALL_CHILDREN.value === formValues?.child?.value && (
        <React.Fragment>
          <Form.Row
            label={i18n.t('module:Modals:ChildrenExport:startDateBetween')}
            verticalLabel
          >
            <Field
              component={Form.DatePicker}
              name="dateRange"
              validate={isRequired}
              range
            />
          </Form.Row>
          <Form.Row
            label={i18n.t('module:Modals:ChildrenExport:archivedChildren')}
            verticalLabel
          >
            <Field
              component={Form.Switch}
              name="archived"
            />
          </Form.Row>
        </React.Fragment>
      )}
    </React.Fragment>
  )

  const renderFooter = () => (
    <Form.FooterActions
      isSubmitting={isProcessing}
      submitLabel={i18n.t('global:export')}
      onCancelClick={onCloseClick}
    />
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {renderForm()}
      {renderFooter()}
    </Form>
  )
}

export default reduxForm({ form: CHILDREN_EXPORT_FORM })(ChildrenExportForm)
