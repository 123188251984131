import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { RootState } from 'core/reducers'

import { injectActions } from 'utils/service'

import actions from './actions'
import helpers from './helpers'
import selectors from './selectors'
import constants from './constants'
import { InvoicesBadDebtState } from './badDebt/reducer'
import { InvoicesSingleState } from './single/reducer'
import { InvoicesListState } from './list/reducer'
import { InvoicesBatchState } from './batch/reducer'
import { InvoicesPreviewState } from './preview/reducer'
import { InvoicesStatisticsState } from './statistics/reducer'

export interface withInvoicesServiceProps {
  invoicesActions: typeof actions
  invoicesBadDebtState: InvoicesBadDebtState
  invoicesBatchState: InvoicesBatchState
  invoicesConstants: typeof constants
  invoicesHelpers: typeof helpers
  invoicesListState: InvoicesListState
  invoicesPreviewState: InvoicesPreviewState
  invoicesSelectors: typeof selectors
  invoicesSingleState: InvoicesSingleState
  invoicesStatisticsState: InvoicesStatisticsState
}

const withInvoicesService = <P extends withInvoicesServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ invoices }: RootState) => ({ invoices })

  const mapDispatch = injectActions('invoicesActions', actions)

  const Component = (props) => {
    const { invoices, invoicesActions, ...other } = props

    return (
      <WrappedComponent
        invoicesActions={invoicesActions}
        invoicesBadDebtState={invoices.badDebt}
        invoicesBatchState={invoices.batch}
        invoicesConstants={constants}
        invoicesHelpers={helpers}
        invoicesListState={{ ...invoices.list, isEmpty: invoices.list.data && !invoices.list.data.length }}
        invoicesPreviewState={invoices.preview}
        invoicesSelectors={selectors}
        invoicesSingleState={invoices.single}
        invoicesStatisticsState={invoices.statistics}
        {...other as P}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withInvoicesService
