import _ from 'lodash'

import React from 'react'
import { Field, FieldArray, reduxForm } from 'redux-form'

import { DAYS_MAP } from 'services/legacy/requestedExtraSessions/constants'

import { isRequired } from 'utils/fieldValidation'

import { Callout, DropdownMenu, Form, Spinner, Typography } from 'components'

import i18n from 'translations'

import {
  StyledCustomTable,
  StyledCustomTableWrapper,
  StyledDay,
  StyledRow,
  StyledRowHeader,
  StyledRowWrapper,
} from './RegularUnavailableTimesModalFormStyled'

export const REGULAR_UNAVAILABLE_TIMES_MODAL_FORM = 'RegularUnavailableTimesModalForm'

const renderRowContent = ({
  day,
  fields,
  formSyncErrors,
  formValues,
  isSubmitting,
  roomsOptions,
}) => (item, index) => {
  const { endTime, nurseryClass, startTime } = formValues?.[day]?.[index] || {}

  let rowErrors = []

  _.each(formSyncErrors?.[day]?.[index], (value) => {
    if ('string' === typeof value) {
      rowErrors.push(value)
    }
  })

  rowErrors = _.uniq(rowErrors)
  rowErrors = _.without(rowErrors, i18n.t('utils:Validation:isRequired'))

  return (
    <React.Fragment key={`${day}_${index}`}>
      <StyledRowWrapper>
        {!index
          ? (
            <StyledDay>
              <Typography transform="uppercase" variant="span" bold>
                {DAYS_MAP[day]}
              </Typography>
            </StyledDay>
          )
          : (
            <StyledDay noBorder />
          )}
        <StyledRow>
          <Field
            component={Form.Select}
            disabled={isSubmitting}
            name={`${item}.nurseryClass`}
            options={roomsOptions}
            validate={startTime || endTime ? isRequired : null}
            visualType="modern"
            fullWidth
          />
        </StyledRow>
        <StyledRow>
          <Field
            component={Form.TimePicker}
            disabled={isSubmitting}
            name={`${item}.startTime`}
            validate={endTime || nurseryClass ? isRequired : null}
            visualType="modern"
          />
        </StyledRow>
        <StyledRow>
          <Field
            component={Form.TimePicker}
            disabled={isSubmitting}
            name={`${item}.endTime`}
            validate={startTime || nurseryClass ? isRequired : null}
            visualType="modern"
          />
        </StyledRow>
        <StyledRow border center>
          <DropdownMenu
            disabled={isSubmitting}
            small
          >
            <DropdownMenu.Item
              icon="plus"
              label={i18n.t('module:Modals:Staff:Leave:Add:addRow')}
              onClick={() => fields.push({})}
            />
            <DropdownMenu.Divider />
            <DropdownMenu.Item
              type="delete"
              onClick={() => {
                fields.remove(index)

                if (0 === fields.length - 1) {
                  fields.push({})
                }
              }}
            />
          </DropdownMenu>
        </StyledRow>
      </StyledRowWrapper>
      <Callout
        content={rowErrors}
        icon="exclamation-mark"
        iconVariant="circle"
        error
      />
    </React.Fragment>
  )
}

const renderRow = (props) => {
  const {
    dayIndex,
    fields,
    openingDays,
  } = props

  const day = openingDays[dayIndex]

  return fields.map(renderRowContent({
    ...props,
    day,
  }))
}

const RegularUnavailableTimesModalForm = ({
  errorMessages,
  formErrors,
  formSyncErrors,
  formValues,
  handleSubmit,
  isFetching,
  isSubmitting,
  onCloseClick,
  onDeleteAll,
  onSubmit,
  openingDays,
  roomsOptions,
}) => {
  if (isFetching) {
    return (
      <Spinner />
    )
  }

  const renderTableHeader = () => (
    <StyledRowWrapper>
      <StyledRowHeader>
        <Typography>
          {_.upperFirst(i18n.t('global:day'))}
        </Typography>
      </StyledRowHeader>
      <StyledRowHeader>
        <Typography>
          {i18n.t('module:Modals:RequestableExtraSessions:ExceptionalUnavailableTimes:teachingRoom')}
        </Typography>
      </StyledRowHeader>
      <StyledRowHeader>
        <Typography>
          {_.upperFirst(i18n.t('global:start'))}
        </Typography>
      </StyledRowHeader>
      <StyledRowHeader>
        <Typography>
          {_.upperFirst(i18n.t('global:end'))}
        </Typography>
      </StyledRowHeader>
      <StyledRowHeader center>
        <DropdownMenu
          disabled={isSubmitting}
          small
        >
          <DropdownMenu.Item
            icon="trash"
            label={i18n.t('global:deleteAll')}
            onClick={onDeleteAll}
          />
        </DropdownMenu>
      </StyledRowHeader>
    </StyledRowWrapper>
  )

  const renderTable = () => (
    <StyledCustomTableWrapper>
      {_.times(openingDays.length, (dayIndex) => (
        <StyledCustomTable key={dayIndex}>
          {!dayIndex && renderTableHeader()}
          <FieldArray
            component={renderRow}
            name={openingDays[dayIndex]}
            props={{
              dayIndex,
              formErrors,
              formSyncErrors,
              formValues,
              isSubmitting,
              openingDays,
              roomsOptions,
            }}
          />
        </StyledCustomTable>
      ))}
    </StyledCustomTableWrapper>
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Callout content={errorMessages} error />
      {renderTable()}
      <Form.FooterActions
        isSubmitting={isSubmitting}
        submitLabel={i18n.t('global:Save')}
        onCancelClick={onCloseClick}
      />
    </Form>
  )
}

const validate = (fields) => {
  const errors = {}

  _.forEach(fields, (dayArray, day) => {
    errors[day] = errors?.[day] || {}
    _.forEach(dayArray, ({ endTime, startTime }, index) => {
      errors[day][index] = errors[day]?.[index] || {}

      if (startTime && endTime && endTime < startTime) {
        errors[day][index].endTime = i18n.t('utils:Validation:isEndTimeGreaterThanStartTime')
      }
    })
  })

  return errors
}

export default reduxForm({ form: REGULAR_UNAVAILABLE_TIMES_MODAL_FORM, validate })(RegularUnavailableTimesModalForm)
