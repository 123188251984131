import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'

import { withAppService } from 'services/app'
import { withLeaveTypesService } from 'services/legacy/leaveTypes'

import { Select } from 'components'

import i18n from 'translations'

import withInfiniteDropdown from './withInfiniteDropdown'

class LeaveTypesDropdown extends Component {
  componentDidMount() {
    const { infiniteDropdownHelpers, leaveTypesActions, value } = this.props

    if (!_.isArray(value)) {
      return infiniteDropdownHelpers.handleComponentDidMount({
        action: () => leaveTypesActions.getDropdownItem(value?.value || value, {
          onFailed: this.notFoundValue,
          onSuccess: infiniteDropdownHelpers.handleComponentDidMountOnSuccess,
        }),
        value,
      })
    }

    return null
  }

  handleLoadMoreElements = async (phrase) => {
    const {
      infiniteDropdownHelpers,
      leaveTypesActions,
      leaveTypesSelectors,
      limit,
    } = this.props

    return infiniteDropdownHelpers.handleLoadMoreElements({
      clearAction: () => {},
      listAction: ({ newPage, newSearchPhrase, result }) => leaveTypesActions.dropdownList({
        onSuccess: (response) => infiniteDropdownHelpers.handleLoadMoreElementsOnSuccess({
          newPage,
          newSearchPhrase,
          response,
          result,
        }),
        params: {
          criteria: leaveTypesSelectors.getDropdownListCriteria({
            archived: 0,
            name: newSearchPhrase,
          }),
          limit,
          page: newPage,
        },
      }),
      phrase,
    })
  }

  notFoundValue = () => {
    const { onChange } = this.props

    onChange(null)
  }

  handleChange = (e) => {
    const { infiniteDropdownHelpers, onChange } = this.props

    infiniteDropdownHelpers.handleOnChange(e, () => onChange(e))
  }

  render() {
    const {
      disabled,
      infiniteDropdownState,
      placeholder,
      rawValue,
      value,
      ...rest
    } = this.props
    const { cacheUniq, options, selectedValue } = infiniteDropdownState

    return (
      <Select
        {...rest}
        cacheUniq={cacheUniq}
        disabled={disabled}
        loadOptions={this.handleLoadMoreElements}
        options={options}
        placeholder={placeholder
         || i18n.t('components:InfiniteDropdowns:LeaveTypes:placeholder')}
        rawValue={rawValue || selectedValue || value}
        value={value}
        searchable
        onChange={this.handleChange}
      />
    )
  }
}

const enhance = compose(
  withAppService,
  withLeaveTypesService,
  withInfiniteDropdown,
)

export default enhance(LeaveTypesDropdown)
