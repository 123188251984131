import { generateBasicActions } from 'utils/service'

import {
  CLEAR_CLOSURE_DAY_TYPE,
  CREATE_CLOSURE_DAY_TYPE,
  CREATE_CLOSURE_DAY_TYPE_FAILED,
  CREATE_CLOSURE_DAY_TYPE_SUCCESS,
  GET_CLOSURE_DAY_TYPE,
  GET_CLOSURE_DAY_TYPE_FAILED,
  GET_CLOSURE_DAY_TYPE_SUCCESS,
  REMOVE_CLOSURE_DAY_TYPE,
  REMOVE_CLOSURE_DAY_TYPE_FAILED,
  REMOVE_CLOSURE_DAY_TYPE_SUCCESS,
  UPDATE_CLOSURE_DAY_TYPE,
  UPDATE_CLOSURE_DAY_TYPE_FAILED,
  UPDATE_CLOSURE_DAY_TYPE_SUCCESS,
} from './constants'

export const get = (options = {}) => ({ closureDayTypesApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: GET_CLOSURE_DAY_TYPE_FAILED,
      init: GET_CLOSURE_DAY_TYPE,
      success: GET_CLOSURE_DAY_TYPE_SUCCESS,
    },
    options,
    service: closureDayTypesApiClient,
    serviceMethod: 'get',
  })
)

export const create = (options = {}) => ({ closureDayTypesApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: CREATE_CLOSURE_DAY_TYPE_FAILED,
      init: CREATE_CLOSURE_DAY_TYPE,
      success: CREATE_CLOSURE_DAY_TYPE_SUCCESS,
    },
    options,
    service: closureDayTypesApiClient,
    serviceMethod: 'create',
  })
)

export const update = (options = {}) => ({ closureDayTypesApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_CLOSURE_DAY_TYPE_FAILED,
      init: UPDATE_CLOSURE_DAY_TYPE,
      success: UPDATE_CLOSURE_DAY_TYPE_SUCCESS,
    },
    options,
    service: closureDayTypesApiClient,
    serviceMethod: 'update',
  })
)

export const remove = (options = {}) => ({ closureDayTypesApiClient }) => (
  generateBasicActions.remove({
    constants: {
      failed: REMOVE_CLOSURE_DAY_TYPE_FAILED,
      init: REMOVE_CLOSURE_DAY_TYPE,
      success: REMOVE_CLOSURE_DAY_TYPE_SUCCESS,
    },
    options,
    service: closureDayTypesApiClient,
    serviceMethod: 'remove',
  })
)

export const clear = () => ({
  type: CLEAR_CLOSURE_DAY_TYPE,
})
