import i18n from 'translations'

export const ALLERGIES_AND_DIETARY_PREFERENCES = 'dietary-requirements-and-allergy'
export const ALLERGIES_ONLY = 'allergy'
export const ALL_CHILDREN = 'ALL_CHILDREN'
export const ONLY_CHILDREN_WITH_ALLERGIES_OR_DIETARY_PREFERENCES = 'ONLY_CHILDREN_WITH_ALLERGIES_OR_DIETARY_PREFERENCES'

export const REPORT_TYPE_OPTIONS = [
  {
    label: i18n.t('module:Modals:DietaryReportExportModal:ReportType:Options:allergiesAndDietary'),
    value: ALLERGIES_AND_DIETARY_PREFERENCES,
  },
  {
    label: i18n.t('module:Modals:DietaryReportExportModal:ReportType:Options:allergiesOnly'),
    value: ALLERGIES_ONLY,
  },
]

export const SHOW_ALL_OR_SOME_CHILDREN_OPTIONS = [
  {
    label: i18n.t('module:Modals:DietaryReportExportModal:ShowAllOrSomeChildren:Options:showAll'),
    value: ALL_CHILDREN,
  },
  {
    label: i18n.t('module:Modals:DietaryReportExportModal:ShowAllOrSomeChildren:Options:showSome'),
    value: ONLY_CHILDREN_WITH_ALLERGIES_OR_DIETARY_PREFERENCES,
  },
]
