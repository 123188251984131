import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { millisecondsToHoursAndMinutesString } from 'utils/date'

import { Banner, Typography } from 'components'

import { StyledBannerWrapper } from './LeaveBannerStyled'

const LeaveBanner = ({ borderVersion, date, formValues, leaves }) => {
  const renderLeavePart = (items, type) => {
    if (!items) {
      return null
    }

    const summary = millisecondsToHoursAndMinutesString(_.sumBy(items, ({ endTime, startTime }) => endTime - startTime))

    return (
      <Typography key={`${moment(date).format(DEFAULT_DATE_FORMAT)}_${type}`}>
        {type}
        {' '}
        {summary}
        {' '}
        {'('}
        {_.join(
          _.map(items, ({ endTime, startTime }) => (
            `${moment(startTime).utc().format('HH:mm')} - ${moment(endTime).utc().format('HH:mm')}`
          )),
          ', ',
        )}
        {')'}
      </Typography>
    )
  }

  const items = _.filter(leaves[date], ({ id }) => (
    !_.find(formValues, ({ id: formId }) => formId === id)
  ))

  if (!items?.length) {
    return null
  }

  const groupedItems = _.groupBy(items, 'leaveShiftType.name')

  return (
    <StyledBannerWrapper borderVersion={borderVersion}>
      <Banner.Info iconOnTop>
        {_.map(groupedItems, (groupedItem, type) => renderLeavePart(groupedItem, type))}
      </Banner.Info>
    </StyledBannerWrapper>
  )
}

export default LeaveBanner

