export const LIST_CHILDREN = 'LIST_CHILDREN'
export const LIST_CHILDREN_SUCCESS = 'LIST_CHILDREN_SUCCESS'
export const LIST_CHILDREN_FAILED = 'LIST_CHILDREN_FAILED'

export const CLEAR_LIST_CHILDREN = 'CLEAR_LIST_CHILDREN'

export const EXPORT_CHILDREN_REPORT = 'EXPORT_CHILDREN_REPORT'
export const EXPORT_CHILDREN_REPORT_SUCCESS = 'EXPORT_CHILDREN_REPORT_SUCCESS'
export const EXPORT_CHILDREN_REPORT_FAILED = 'EXPORT_CHILDREN_REPORT_FAILED'

export const EXPORT_FUNDING_REPORT = 'EXPORT_FUNDING_REPORT'
export const EXPORT_FUNDING_REPORT_SUCCESS = 'EXPORT_FUNDING_REPORT_SUCCESS'
export const EXPORT_FUNDING_REPORT_FAILED = 'EXPORT_FUNDING_REPORT_FAILED'

export const SEND_REPORT = 'SEND_REPORT'
export const SEND_REPORT_SUCCESS = 'SEND_REPORT_SUCCESS'
export const SEND_REPORT_FAILED = 'SEND_REPORT_FAILED'

export const SEND_FORMATIVE_REPORT = 'SEND_FORMATIVE_REPORT'
export const SEND_FORMATIVE_REPORT_SUCCESS = 'SEND_FORMATIVE_REPORT_SUCCESS'
export const SEND_FORMATIVE_REPORT_FAILED = 'SEND_FORMATIVE_REPORT_FAILED'
export const SEND_FORECAST_REPORT = 'SEND_FORECAST_REPORT'
export const SEND_FORECAST_REPORT_SUCCESS = 'SEND_FORECAST_REPORT_SUCCESS'
export const SEND_FORECAST_REPORT_FAILED = 'SEND_FORECAST_REPORT_FAILED'
