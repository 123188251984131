import { APP_IS_OFFLINE, APP_IS_ONLINE } from './constants'

export interface AppOfflineState {
  isOffline: boolean
}

const initialState = {
  isOffline: false,
}

export default (state: AppOfflineState = initialState, { type }) => {
  switch (type) {
    case APP_IS_ONLINE:
      return { ...state, isOffline: false }
    case APP_IS_OFFLINE:
      return { ...state, isOffline: true }
    default:
      return state
  }
}
