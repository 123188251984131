import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

const CHILD_DETAIL_COLUMN_WIDTH = 320

export const StyledPageWrapper = styled.div`
  display: flex;
`

export const StyledChildDetail = styled.div`
  background: ${NEUTRAL_COLOURS.WHITE};
  border-right: 2px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  padding: 0 16px 16px;
  height: 100%;
  width: ${CHILD_DETAIL_COLUMN_WIDTH}px;
`

export const StyledBodyContainer = styled.section`
  display: grid;
  grid-template-rows: 80px 1fr;
  height: 100%;
`

export const StyledActivityDetail = styled.section`
  flex: 1;
`

export const StyledHeader = styled.header`
  display: inline-block;
  align-self: center;
`

