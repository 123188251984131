import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { isSameDay, isToday } from 'utils/date'

import i18n from 'translations'

import { StyledBorder, StyledContent, StyledSeparator } from './SeparatorStyled'

const Separator = ({ date }) => {
  const getDate = () => {
    if (isToday(date)) {
      return _.upperFirst(i18n.t('global:today'))
    }

    if (isSameDay(date, moment().add(-1, 'days'))) {
      return _.upperFirst(i18n.t('global:yesterday'))
    }

    return moment(date).format('Do MMMM YYYY')
  }

  return (
    <StyledSeparator>
      <StyledBorder />
      <StyledContent>
        {getDate()}
      </StyledContent>
      <StyledBorder />
    </StyledSeparator>
  )
}

export default Separator
