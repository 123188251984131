import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class ChildRegistersApiClient extends ServiceBase {
  list = (params) => this.request({
    path: `/v2/child_registers?${getQueryStringV2(params)}`,
  })

  update = (registerId, params = {}, payload) => this.request({
    body: payload,
    method: 'PUT',
    path: `/v2/child_registers/${registerId}?${getQueryStringV2(params)}`,
  })
}

export default ChildRegistersApiClient
