import moment from 'moment'

import React, { useState } from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { LIKE_TYPE } from 'services/likes/constants'
import { AuthorType, Like as LikeModel } from 'services/likes/models'
import { Comment } from 'services/comments/models'

import { Avatar, Typography } from 'components'
import Like from 'module/LikesAndComments/components/Like/Like'

import i18n from 'translations'

import { StyledComment, StyledCommentHeader, StyledCommentWrapper, StyledFooter } from './CommentItemStyled'

interface CommentItemProps {
  hasAccessToLikesAndCommentsSettings: boolean
  item: Comment
}

const CommentItem: React.FC<CommentItemProps> = ({ hasAccessToLikesAndCommentsSettings, item }) => {
  const [itemLikeCountFinal, setItemLikeCountFinal] = useState<number>(item.likeCount)
  const [userLikeFinal, setUserLikeFinal] = useState<LikeModel>(item.userLike)

  const handleLikeChange = (direction, response) => {
    setItemLikeCountFinal(itemLikeCountFinal + (direction ? +1 : -1))
    setUserLikeFinal(direction ? response : null)
  }

  const renderAuthorName = () => {
    if (AuthorType.DEACTIVATED === item.author?.type) {
      return (
        <Typography color={NEUTRAL_COLOURS.GRAY} italic>
          {i18n.t('module:LikesAndComments:authorDeactivated')}
        </Typography>
      )
    }

    return (
      <Typography bold>
        {item.author?.fullName || '-'}
      </Typography>
    )
  }

  return (
    <StyledCommentWrapper key={item.id}>
      <Avatar avatarSize="medium" src={item.author?.photo} />
      <StyledComment>
        <StyledCommentHeader>
          {renderAuthorName()}
          <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
            {moment(item.createdAt).fromNow()}
          </Typography>
        </StyledCommentHeader>
        <Typography margin="10px 0" wordBreak="break-word" prewrap>
          {item.comment}
        </Typography>
        <StyledFooter $noPadding={!hasAccessToLikesAndCommentsSettings && !itemLikeCountFinal}>
          <Like
            commentType={item.type}
            likeCount={itemLikeCountFinal}
            likeType={LIKE_TYPE.COMMENT}
            objectId={item.id}
            preview={!hasAccessToLikesAndCommentsSettings}
            userLike={userLikeFinal}
            reverted
            small
            onChange={handleLikeChange}
          />
          <div />
        </StyledFooter>
      </StyledComment>
    </StyledCommentWrapper>
  )
}

export default CommentItem
