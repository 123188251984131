import {
  CLEAR_OBSERVATION_FEEDBACK_LISTS,
  LIST_OBSERVATION_FEEDBACKS,
  LIST_OBSERVATION_FEEDBACKS_FAILED,
  LIST_OBSERVATION_FEEDBACKS_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: null,
}

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case LIST_OBSERVATION_FEEDBACKS:
      return { ...state, error: initialState.error, isFetching: true }
    case LIST_OBSERVATION_FEEDBACKS_SUCCESS: {
      const { data, meta } = payload

      return {
        ...state,
        data,
        isFetching: false,
        meta,
      }
    }
    case LIST_OBSERVATION_FEEDBACKS_FAILED:
      return { ...state, error: payload?.error, isFetching: false }
    case CLEAR_OBSERVATION_FEEDBACK_LISTS:
      return initialState
    default:
      return state
  }
}
