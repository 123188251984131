import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Page, Section, Spinner, Typography } from 'components'

import i18n from 'translations'

import {
  AccessToLikesAndComments,
  EnableLikesAndComments,
  InstantMessenger,
  ShareObservationWithParents,
  StaffAssignedToRooms,
} from './components'
import { PropsFromRedux as ContainerPropsFromRedux } from './ParentCommunicationContainer'

interface ParentCommunicationViewProps {
  onReload: () => void
  onRoomListsReload: () => void
  onSubmit: (fields: any) => (successCallback: any, failedCallback?: () => void) => void
  roomsIsFetching: boolean
}

const ParentCommunicationView: React.FC<
  Omit<ContainerPropsFromRedux, 'nurseryOptions'>
  & ParentCommunicationViewProps
> = ({
  hasParentAppAccess,
  injectValidation,
  isFetching,
  isLikesAndCommentsEnabled,
  isMessagingEnabled,
  likeAndCommentMembers,
  nurseryLearningSettings,
  nurseryLikeAndCommentSettings,
  nurseryMessagingSettings,
  onReload,
  onRoomListsReload,
  onSubmit,
  rooms,
  roomsIsFetching,
  startSubmitForm,
}) => {
  const { shareAllObservationsWithParents } = nurseryLearningSettings || {}
  const { enabledForParents, roomsStaff } = nurseryMessagingSettings || {}
  const { enabled } = nurseryLikeAndCommentSettings || {}

  const renderSharingWithParentsSection = () => {
    if (!hasParentAppAccess) {
      return null
    }

    return (
      <Section
        title={i18n.t('module:Management:General:SharingWithParents:title')}
        noMargin
      >
        <ShareObservationWithParents
          value={shareAllObservationsWithParents}
          onSubmit={onSubmit}
        />
      </Section>
    )
  }

  const renderMessagingSection = () => {
    if (!isMessagingEnabled) {
      return null
    }

    return (
      <Section
        title={i18n.t('module:Management:General:Messaging:title')}
      >
        <InstantMessenger
          // @ts-ignore
          injectValidation={injectValidation}
          isFetching={isFetching}
          startSubmitForm={startSubmitForm}
          value={enabledForParents}
          onSubmit={onSubmit}
        />
      </Section>
    )
  }

  const renderLikesAndCommentsSection = () => {
    if (!isLikesAndCommentsEnabled) {
      return false
    }

    return (
      <Section
        title={i18n.t('module:Management:General:LikesAndComments:title')}
      >
        <EnableLikesAndComments
          value={enabled}
          onSubmit={onSubmit}
        />
        <AccessToLikesAndComments
          enabled={enabled}
          members={likeAndCommentMembers}
          onReload={onReload}
        />
      </Section>
    )
  }

  const renderStaffAssignedToRooms = () => {
    if (!(isMessagingEnabled || isLikesAndCommentsEnabled) || (!enabledForParents && !enabled)) {
      return null
    }

    return (
      <Section
        title={i18n.t('module:Management:General:Messaging:StaffAssignedToRooms:title')}
      >
        <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
          {i18n.t('module:Management:General:Messaging:StaffAssignedToRooms:subTitle')}
        </Typography>
        <StaffAssignedToRooms
          injectValidation={injectValidation}
          isFetching={isFetching}
          rooms={rooms}
          roomsIsFetching={roomsIsFetching}
          startSubmitForm={startSubmitForm}
          value={roomsStaff}
          onRoomListsReload={onRoomListsReload}
        />
      </Section>
    )
  }

  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    return (
      <React.Fragment>
        {renderSharingWithParentsSection()}
        {renderMessagingSection()}
        {renderLikesAndCommentsSection()}
        {renderStaffAssignedToRooms()}
      </React.Fragment>
    )
  }

  return (
    <Page>
      <Page.Content>
        <Section title={i18n.t('module:Management:ParentApp:ParentCommunication:title')}>
          {renderContent()}
        </Section>
      </Page.Content>
    </Page>
  )
}

export default ParentCommunicationView
