import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { generateRoute } from 'utils/routing'

import { Button, Callout, EmptyState, Section, Spinner } from 'components'

import ChildSessionsList from '../components/ChildSessionsList'

const ChildSessionsHistoryView = ({
  authAccessMap,
  child,
  childHistorySessions,
  errorMessages,
  isHistoryEmpty,
  isLoading,
  isOpeningDaysEmpty,
}) => {
  const renderChildSession = (childSession) => {
    const { attendancePeriod, endDate, id, isOngoing, startDate } = childSession

    const startDateText = moment(startDate).format('DD/MM/YYYY')
    const endDateText = isOngoing ? 'Ongoing' : moment(endDate).format('DD/MM/YYYY')
    const title = `${startDateText} - ${endDateText}`

    return (
      <ChildSessionsList
        attendancePeriodText={`${attendancePeriod?.name}${attendancePeriod?.isArchived ? ' (archived)' : ''}`}
        child={child}
        childSession={childSession}
        displayAddAction={authAccessMap.module.ChildSessionsAdd}
        key={id}
        title={title}
      />
    )
  }

  const renderHistorySessions = () => {
    if (isOpeningDaysEmpty) {
      return (
        <Section title="Sessions history">
          <EmptyState
            actions={(
              <Button
                label="Set opening days"
                to={generateRoute('MANAGEMENT.NURSERY')}
              />
            )}
            icon="sessions"
            text1="Please set your opening days in the nursery settings to add sessions."
          />
        </Section>
      )
    }

    if (isHistoryEmpty) {
      const { firstName } = child

      return (
        <Section title="Session history">
          <EmptyState
            icon="sessions"
            text1={`There are no sessions history for ${firstName}.`}
          />
        </Section>
      )
    }

    return (
      <Section title="Sessions history">
        {_.map(childHistorySessions, renderChildSession)}
      </Section>
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return renderHistorySessions()
  }

  return (
    <div>
      <Callout content={errorMessages} error />
      {renderContent()}
    </div>
  )
}

export default ChildSessionsHistoryView
