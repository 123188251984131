import color from 'color'
import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledContainer = styled.div`
  background: ${({ color: flagColor }) => color(flagColor).alpha(0.3).rgb().string()};
  color: ${NEUTRAL_COLOURS.BASIC};
  
  ${({ hide }) => hide && `
    display: none;
  `}
`

export const StyledContentContainer = styled.div`
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 4px 20px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 10px;
  align-items: center;

  & :not(:nth-child(1)) {
    align-self: center;
  }

  & h6, p {
    display: inline;
  }

  & a {
    :before {
      content: ' ';
    }
  }
`
