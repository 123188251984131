import moment from 'moment'

import { DaysOfWeek } from 'constants/date'
import { PriceChange } from 'services/product/constants'
import { BasicSingleInitialState } from 'utils/reducer'
import { createTypeFromEnumValues } from 'utils/typescript'
import { Child } from 'services/child/models'
import { ApplicableValues, NurseryDiscountTypesV3 } from 'services/product/nurseryDiscountsV3/constants'
import { ChildProductTypes } from 'services/booking/childProducts/constants'
import { NurseryFundingProductSettings } from 'services/product/nurseryFundingV3/constants'

import i18n from 'translations'

enum AttendancePeriodType {
  ALL_YEAR_ROUND = 'ALL_YEAR_ROUND',
  TERM_DATES = 'TERM_DATES',
}

export const REGULAR_DISCOUNT_TYPES = [
  {
    label: i18n.t('module:Management:Finance:Discounts:percentageDiscount'),
    value: ChildProductTypes.REGULAR_PERCENTAGE_DISCOUNT,
  },
  {
    label: i18n.t('module:Management:Finance:Discounts:fixedAmountDiscount'),
    value: ChildProductTypes.REGULAR_AMOUNT_DISCOUNT,
  },
]

interface ChildProductBasic {
  alternate: number
  dayOfWeek: DaysOfWeek
  id: string
}

export interface ChildProductRegularSession extends ChildProductBasic {
  // Temporary data not represent an object, but required to calculate various scenario
  _extra: {
    price?: number
  }
  endTime?: number
  name?: string
  product?: {
    endTime?: number
    hourly?: boolean
    id?: number | string
    name?: string
    startTime?: number
  }
  settings?: {
    consumablesIncluded?: boolean
  }
  startTime?: number
  type: ChildProductTypes.REGULAR_SESSION
}

export interface ChildProductExtraSession {
  endTime?: number
  name?: string
  product?: {
    endTime?: number
    hourly?: boolean
    id?: number | string
    name?: string
    startTime?: number
  }
  settings?: {
    consumablesIncluded?: boolean
  }
  startTime?: number
  type: ChildProductTypes.EXTRA_SESSION
}

export interface ChildProductRegularItem extends ChildProductBasic {
  _extra?: {
    price: any
  }
  product?: {
    id?: number
    name?: string
  }
  settings?: {
    quantity?: number
  }
  type: ChildProductTypes.REGULAR_ITEM
}

export interface ChildProductExtraItem {
  product?: {
    id?: number
    name?: string
  }
  settings?: {
    quantity?: number
  }
  type: ChildProductTypes.EXTRA_ITEM
}

export interface ChildProductRegularPercentageDiscount extends ChildProductBasic {
  _extra?: {
    price?: number
    value?: number
  }
  settings?: {
    applicable?: ApplicableValues[]
    applicableBeforeFunding?: boolean
    name?: string
    value?: number
  }
  type?: ChildProductTypes.REGULAR_PERCENTAGE_DISCOUNT
}

export interface ChildProductRegularAmountDiscount extends ChildProductBasic {
  _extra?: {
    price?: number
    value?: number
  }
  settings?: {
    name?: string
    value?: number
  }
  type?: ChildProductTypes.REGULAR_AMOUNT_DISCOUNT
}

export interface ChildProductRegularLinkDiscount extends ChildProductBasic {
  _extra?: {
    price?: number
    value?: number
  }
  product?: {
    id?: number | string
    name?: number | string
    type: NurseryDiscountTypesV3
  }
  settings?: {
    overwritten?: boolean
    value?: number
  }
  type?: ChildProductTypes.REGULAR_LINK_DISCOUNT
}

export type ChildProductDiscount = ChildProductRegularPercentageDiscount
  | ChildProductRegularAmountDiscount
  | ChildProductRegularLinkDiscount

export type ChildProductFundingProduct = {
  id?: number | string
  name?: string
  priceChanges?: PriceChange[]
  settings?: NurseryFundingProductSettings
}

export interface ChildProductFunding {
  alternate: number
  hoursPerWeekAllocated?: number
  id?: number | string
  product?: ChildProductFundingProduct
  settings?: {
    allocations?: Allocation[]
    customLocalAuthority?: boolean
    defaultAllocations?: AllocationDefault[]
    hourlyRate?: number
    hoursPerDay?: number
    hoursPerWeek?: number
    migratedFunding?: boolean
  }
}

export interface ChildProductRegularAllocatedFunding extends ChildProductFunding {
  type?: ChildProductTypes.REGULAR_ALLOCATED_FUNDING
}

export type ChildProduct = ChildProductRegularSession
  | ChildProductRegularItem
  | ChildProductRegularPercentageDiscount
  | ChildProductRegularAmountDiscount
  | ChildProductRegularLinkDiscount
  | ChildProductRegularAllocatedFunding

export interface AllocationTime {
  endTime: number
  startTime: number
}

export enum RedistributeRoleType {
  ACTUAL = 'actual',
  MANUALLY = 'manually',
}

interface AllocationBasic {
  _extra?: {
    id: string
  }
  product: ChildProduct
  redistributeRole?: 'actual' | 'manually'
  times: AllocationTime[]
}

export interface Allocation extends AllocationBasic {
  date?: string | moment.Moment
}

export interface AllocationDefault extends AllocationBasic {
  dayOfWeek?: DaysOfWeek
}

export type RestFundingsDayAllocations = AllocationBasic & {
  date?: string | moment.Moment
  dayOfWeek?: DaysOfWeek
  product?: ChildProductFundingProduct
}

export interface ChildBooking {
  alternates?: number
  archived?: boolean
  archivedAt?: Date
  attendancePeriod?: {
    archived?: boolean
    archivedAt?: Date
    createdAt?: Date
    id?: number
    name?: string
    periodType?: createTypeFromEnumValues<AttendancePeriodType>
    updatedAt?: Date
  }
  child?: Child
  childBookingPriceChanges?: any
  childProducts?: ChildProduct[]
  endDate?: string
  id?: number
  priceGroup?: {
    endAge?: number
    id?: number
    startAge?: number
  }
  settings?: {
    calculationMonths?: number
    calculationWeeks?: number
    excludedMonths?: number[]
    feeCalculation?: FeeCalculationType
    packageLineItemDeduction?: string
    packageLineItemDisplay?: string
    packageLineItemLevelOfDetail?: string
    packageLineItemName?: string
    packagePrice?: number
  }
  startDate?: string
}

export interface WeekDayPreviewItem {
  endTime: number
  id: string
  name: string
  price: number
  quantity: number
  startTime: number
  type: ChildProductTypes
}

export interface WeekDayPreview {
  dayOfWeek: DaysOfWeek
  items: WeekDayPreviewItem[]
  numberOfWeek: number
  price: number
}

export enum FeeCalculationType {
  ACTUAL = 'actual',
  ANNUALISED = 'annualise',
  MONTHLY_PACKAGE = 'monthly_package',
  WEEKLY_PACKAGE = 'weekly_package'
}

export interface ChildBookingPreviewSummaryPackage {
  actualPriceForTypicalWeek: number
  difference: number
  monthlyPackage: number
  months: number
  weeklyFixedPrice: number
  weeks: number
}

export interface ChildBookingPreviewSummaryAnnualised {
  monthlyAnnualisedTotal?: number
  totalForATypicalWeek?: number
}

export interface ChildBookingPreviewSummaryActual {
  estimatedWeeklyTotal?: number
}

export interface ChildBookingPreview {
  childBooking: ChildBooking
  summaryPreview: {
    type: FeeCalculationType
  } & ChildBookingPreviewSummaryPackage | ChildBookingPreviewSummaryAnnualised | ChildBookingPreviewSummaryActual
  weekDayPreviews: WeekDayPreview[]
}

export const FEE_CALCULATION_OPTIONS = [
  {
    label: i18n.t('services:Product:NurseryDiscounts:feeCalculation:actual'),
    value: FeeCalculationType.ACTUAL,
  },
  {
    label: i18n.t('services:Product:NurseryDiscounts:feeCalculation:annualised'),
    value: FeeCalculationType.ANNUALISED,
  },
  {
    label: i18n.t('services:Product:NurseryDiscounts:feeCalculation:monthlyPackage'),
    value: FeeCalculationType.MONTHLY_PACKAGE,
  },
  {
    label: i18n.t('services:Product:NurseryDiscounts:feeCalculation:weeklyPackage'),
    value: FeeCalculationType.WEEKLY_PACKAGE,
  },
]

export interface ChildBookingListState extends BasicSingleInitialState {
  data: ChildBooking[]
}

export interface ChildBookingSingleState extends BasicSingleInitialState {
  data: ChildBooking
}

export interface ChildBookingPreviewState extends BasicSingleInitialState {
  data: ChildBookingPreview
}

export interface ChildBookingState {
  list: ChildBookingListState
  preview: ChildBookingPreviewState
  single: ChildBookingSingleState
}

export interface ChildBookingRootState {
  childBooking: ChildBookingState
}

export enum ExcludedFundingReasonsType {
  CLOSURE_DAYS_SESSION_CHARGEABLE = 'closure_days_session_chargeable',
  CLOSURE_DAYS_SESSION_NOT_CHARGEABLE = 'closure_days_session_not_chargeable',
  EXCLUSION_PERIODS = 'exclusion_periods',
  TERM_TIME_HOLIDAYS = 'term_time_holidays'
}

export interface ExcludedFundingReasons {
  date: string
  reason: ExcludedFundingReasonsType
  type: string
}
