import { generateBasicActions } from 'utils/service'

import {
  REMOVE_ACTIVITY_MEDIA,
  REMOVE_ACTIVITY_MEDIA_FAILED,
  REMOVE_ACTIVITY_MEDIA_SUCCESS,
  UPDATE_ACTIVITY_MEDIA,
  UPDATE_ACTIVITY_MEDIA_FAILED,
  UPDATE_ACTIVITY_MEDIA_SUCCESS,
} from './constants'

export const removeActivityMedia = (options = {}) => ({
  dailyDiaryActivitiesApiClientV2,
}) => generateBasicActions.remove({
  constants: {
    failed: REMOVE_ACTIVITY_MEDIA_FAILED,
    init: REMOVE_ACTIVITY_MEDIA,
    success: REMOVE_ACTIVITY_MEDIA_SUCCESS,
  },
  options,
  service: dailyDiaryActivitiesApiClientV2,
  serviceMethod: 'removeActivityMedia',
})

export const updateActivityMedia = (options = {}) => ({
  dailyDiaryActivitiesApiClientV2,
}) => generateBasicActions.update({
  constants: {
    failed: UPDATE_ACTIVITY_MEDIA_FAILED,
    init: UPDATE_ACTIVITY_MEDIA,
    success: UPDATE_ACTIVITY_MEDIA_SUCCESS,
  },
  options,
  service: dailyDiaryActivitiesApiClientV2,
  serviceMethod: 'updateActivityMedia',
})
