import _ from 'lodash'

import { createSelector } from 'reselect'

const getEnquiryStatusTypes = (state) => state.enquiryStatusTypes

export const getEnquiryStatusTypesListSelector = createSelector(
  [getEnquiryStatusTypes],
  (state) => state?.list,
)

export const getEnquiryStatusTypesListDataSelector = createSelector(
  [getEnquiryStatusTypesListSelector],
  (state) => state.data,
)

export const getEnquiryStatusTypesFormattedListDataSelector = createSelector(
  [getEnquiryStatusTypesListDataSelector],
  (data) => _.map(data, (item) => ({
    ...item,
    draggableId: _.snakeCase(item.name),
  })),
)

export const getCriteria = (filters) => {
  const { archived = false, code, name } = filters || {}

  const criteria = []

  if (_.isArray(code)) {
    _.each(code, (item) => {
      criteria.push({
        field: 'code[]',
        value: item,
      })
    })
  }

  if (code && !_.isArray(code)) {
    criteria.push({
      field: 'code',
      value: code,
    })
  }

  if (null !== archived && undefined !== archived) {
    criteria.push({
      field: 'archived',
      value: +archived,
    })
  }

  if (name) {
    criteria.push({
      field: 'name',
      value: name,
    })
  }

  return criteria
}
