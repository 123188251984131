import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class staffLogsApiClient extends ServiceBase {
  list = (params = {}) => this.request({
    path: `/v2/staff_logs?${getQueryStringV2(params)}`,
  })
}

export default staffLogsApiClient
