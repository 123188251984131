import {
  CLEAR_REGISTER_USER_LOGIN,
  REGISTER_USER_LOGIN,
  REGISTER_USER_LOGIN_FAILED,
  REGISTER_USER_LOGIN_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case REGISTER_USER_LOGIN:
      return { ...state, error: initialState.error, isFetching: true }
    case REGISTER_USER_LOGIN_SUCCESS:
      return { ...state, data: payload.data, isFetching: false }
    case REGISTER_USER_LOGIN_FAILED:
      return { ...state, error, isFetching: false }
    case CLEAR_REGISTER_USER_LOGIN:
      return initialState
    default:
      return state
  }
}
