import moment from 'moment'
import { flatten, nest } from 'utils/flatnest'
import { compose } from 'recompose'

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { FEATURE_FLAGS } from 'constants/security'
import invoicesConstants from 'services/legacy/invoices/constants'

import auth from 'utils/auth'

import { withRouterUtils } from 'services/utils/router'
import { withPaginationUtils } from 'services/utils/pagination'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withNurseriesService } from 'services/nurseries'
import { withInvoicesService } from 'services/legacy/invoices'
import { withRouter } from 'services/router'

import FinanceReportDetailedRevenueView from './FinanceReportDetailedRevenueView'

const GROUPS = {
  read: [
    'child',
    'invoice',
    'creditNote.invoice',
    'invoiceInvoiceStatistics',
    'statistics.invoice.summary',
    'statistics.invoice.items',
    'statistics.invoice.income',
  ],
}
const GROUPS_STATISTICS = {
  read: [
    'invoiceInvoiceStatistics',
    'statistics.invoice.summary',
    'statistics.invoice.items',
    'statistics.invoice.income',
  ],
}
const DATE_FORMAT = 'YYYY-MM-DD'

class FinanceReportDetailedRevenueContainer extends Component {
  constructor(props) {
    super(props)

    const { location, paginationUtils } = props
    const { query } = location
    const { date, dateType, status } = nest(query)
    const { setPageLocationQuery } = paginationUtils

    const after = date && date.after ? moment(date.after) : moment().startOf('month')
    const before = date && date.before ? moment(date.before) : moment().endOf('month')

    this.state = {
      filters: {
        date: {
          after: after.format(DATE_FORMAT),
          before: before.format(DATE_FORMAT),
        },
        dateType: dateType || invoicesConstants.INVOICE_DATE_TYPES.issueDate,
        status,
      },
    }

    setPageLocationQuery(false)
  }

  componentDidMount() {
    this.fetch()
  }

  fetch = (withoutStatistics) => {
    const { invoicesActions, invoicesSelectors, paginationUtils, setLocationQuery } = this.props
    const { filters } = this.state

    setLocationQuery(flatten(filters))

    const criteria = invoicesSelectors.getRevenueCriteria(filters)
    const { page } = paginationUtils

    const apiParams = {
      criteria,
      groups: GROUPS,
      page,
    }

    const statisticsParams = {
      criteria,
      groups: GROUPS_STATISTICS,
    }

    invoicesActions.list(apiParams, 1 !== page)

    if (!withoutStatistics) {
      invoicesActions.getStatistics(statisticsParams)
    }
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(() => this.fetch(true))(page)
  }

  handleDateChange = (dateRange) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    const [after, before] = dateRange
    const date = {
      after: after ? moment(after).format(DATE_FORMAT) : undefined,
      before: before ? moment(before).format(DATE_FORMAT) : undefined,
    }

    this.setState((prevState) => ({
      filters: { ...prevState.filters, date },
    }), onPageChange(this.fetch)(1))
  }

  handleDateTypeChange = (dateType) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prevState) => ({
      filters: { ...prevState.filters, dateType: dateType && dateType.value },
    }), onPageChange(this.fetch)(1))
  }

  handleStatusChange = (status) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prevState) => ({
      filters: { ...prevState.filters, status: status && status.value },
    }), onPageChange(this.fetch)(1))
  }

  handleSendReport = () => {
    const { invoicesActions, invoicesSelectors, modalActions, modalConsts } = this.props
    const { filters } = this.state

    const criteria = invoicesSelectors.getRevenueCriteria(filters)

    modalActions.show(modalConsts.TYPES.EXPORT_REPORT, {
      filters: { ...filters },
      onSendReport: (body, onSuccess, onFailed) => invoicesActions.exportInvoice('detailed-revenue', {
        onFailed,
        onSuccess,
        params: { ...body, criteria: [...body.criteria, ...criteria], groups: GROUPS },
      }),
      title: 'Export your detailed revenue report',
      userLabel: 'Select a manager or administrator to send the export to',
    })
  }

  render() {
    const {
      invoices,
      invoicesListState,
      isDepositEnabled,
      isFetchingList,
      isFetchingStatistics,
      isFinanceV3Enabled,
      paginationUtils,
      statistics,
    } = this.props
    const { filters } = this.state
    const { date: { after, before }, dateType, status } = filters
    const { getPageCount, page } = paginationUtils
    const pageCount = getPageCount(invoicesListState.meta.total_results)

    const dateRange = [after, before]

    return (
      <FinanceReportDetailedRevenueView
        dateRange={dateRange}
        dateType={dateType}
        dateTypeOptions={invoicesConstants.INVOICE_DATE_OPTIONS}
        invoices={invoices}
        isDepositEnabled={isDepositEnabled}
        isFinanceV3Enabled={isFinanceV3Enabled}
        isLoading={isFetchingList}
        isLoadingStatistics={isFetchingStatistics}
        page={page}
        pageCount={pageCount}
        statistics={statistics}
        status={status}
        statusOptions={invoicesConstants.STATUSES_DROPDOWN}
        onChangePage={this.handleChangePage}
        onDateChange={this.handleDateChange}
        onDateTypeChange={this.handleDateTypeChange}
        onPageChange={this.handlePageChange}
        onSendReport={this.handleSendReport}
        onStatusChange={this.handleStatusChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  invoicesListState,
  invoicesSelectors,
  invoicesStatisticsState,
}) => ({
  invoices: invoicesSelectors.getInvoiceListSelector(state),
  isDepositEnabled: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.DEPOSITS],
  }),
  isFetchingList: appSelectors.getIsFetching(invoicesListState),
  isFetchingStatistics: appSelectors.getIsFetching(invoicesStatisticsState),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
  statistics: invoicesSelectors.getInvoicesStatistics(state),
})

const enhance = compose(
  withAppService,
  withInvoicesService,
  withPaginationUtils,
  withRouter,
  withRouterUtils,
  withModalService,
  withNurseriesService,
  connect(mapState),
)

export default enhance(FinanceReportDetailedRevenueContainer)
