import _ from 'lodash'

import React, { useCallback } from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { Currency, DateString, Panel, TimeString, Typography } from 'components'

import i18n from 'translations'

import { StyledSessionContainer } from '../../ChildDetailStyled'

const Booking = ({
  enquiry,
  isFinanceV3Enabled,
  openingDays,
}) => {
  const { id, nurseryClass, sessionPlans, sessionProductPlans, startDate, weeklyValue } = enquiry
  const plans = isFinanceV3Enabled ? sessionProductPlans : sessionPlans
  const formattedSessionPlans = _.groupBy(plans, 'dayOfWeek')

  const renderSessions = useCallback(() => {
    const sessionDetails = []

    _.forEach(openingDays, (day) => {
      if (formattedSessionPlans[day]) {
        const daySessions = formattedSessionPlans[day]

        sessionDetails.push(
          <div key={day}>
            <Typography>
              {_.upperFirst(day)}
            </Typography>
            {
              _.map(daySessions, ({ endTime, session, startTime }) => {
                const {
                  endTime: sessionEndTime,
                  id: sessionId,
                  name: sessionName,
                  startTime: sessionStartTime,
                } = session

                return (
                  <Typography key={sessionId} variant="div">
                    <Typography fontSize={14} margin="2px 0 0 0" variant="span">
                      <TimeString.Range
                        end={endTime || sessionEndTime}
                        local={false}
                        start={startTime || sessionStartTime}
                      />
                    </Typography>
                    <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} margin="0 0 0 10px" variant="span">
                      {sessionName}
                    </Typography>
                  </Typography>
                )
              })
            }
          </div>,
        )
      }
    })

    if (!sessionDetails?.length) {
      return null
    }

    return (
      <StyledSessionContainer>
        {sessionDetails}
      </StyledSessionContainer>
    )
  }, [formattedSessionPlans, openingDays])

  return (
    <Panel
      title={i18n.t('global:Booking')}
      to={`${generateRoute('ENQUIRIES.EDIT', { enquiryId: id })}?s=booking`}
    >
      <Panel.Item
        label={i18n.t('module:Enquiries:List:Add:BookingInformation:StartDate:label')}
        value={startDate && <DateString date={startDate} />}
      />
      <Panel.Item
        label={i18n.t('module:Enquiries:List:Add:BookingInformation:Room:label')}
        value={nurseryClass?.name}
      />
      <Panel.Item
        label={i18n.t('module:Enquiries:List:Add:SessionDetails:title')}
        value={renderSessions()}
        valueVariant="div"
      />
      <Panel.Item
        label={i18n.t('module:Enquiries:List:Add:BookingInformation:WeeklySessionValue:label')}
        value={(
          <Currency value={weeklyValue || 0} />
        )}
      />
    </Panel>
  )
}

export default Booking
