import _ from 'lodash'

import React from 'react'

import { RolesDetails } from 'constants/security'

import { ProfileHeader } from 'components'

import i18n from 'translations'

import { getMenuItems } from './helpers'

const StaffHeader = ({
  authAccessMap,
  isMyDetailsContext,
  isNurseryContext,
  membership,
}) => {
  const {
    accepted,
    archived,
    firstName,
    id,
    isPrimaryManager,
    name,
    photo,
    roles,
    surname,
    updatedAt,
  } = membership

  const getName = () => {
    if (!accepted && !archived) {
      return `${name} (${i18n.t('global:pending')})`
    }

    if (archived) {
      return `${name} (${i18n.t('global:archived')})`
    }

    return name
  }

  const renderDetails = () => (
    <ProfileHeader.Details>
      <ProfileHeader.Details.Left
        lastEdited={updatedAt}
        subtitle={
          isNurseryContext && isPrimaryManager
            ? i18n.t('module:Management:Nursery:primaryManager')
            : (0 < roles?.length
              && _.find(RolesDetails, (role) => role.value === roles[0])?.label) || i18n.t('global:roles:SUPER_ADMIN')
        }
        title={getName()}
      />
    </ProfileHeader.Details>
  )

  const menuItems = getMenuItems(authAccessMap, id, isMyDetailsContext)

  const renderMenu = () => <ProfileHeader.Menu menuItems={menuItems} />

  return (
    <ProfileHeader>
      <ProfileHeader.Top>
        <ProfileHeader.Avatar
          initials={[firstName, surname]}
          src={photo}
        />
        {renderDetails()}
      </ProfileHeader.Top>
      <ProfileHeader.Bottom>
        {renderMenu()}
      </ProfileHeader.Bottom>
    </ProfileHeader>
  )
}

export default StaffHeader
