import _ from 'lodash'
import moment from 'moment'

import React from 'react'
import isEqual from 'react-fast-compare'

import { DISPLAY_DATE_TIME_FORMAT, DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'
import { NEUTRAL_COLOURS } from 'constants/colors'
import { STATUS_TYPES, STATUS_TYPES_OPTIONS } from 'services/legacy/requestedExtraSessions/constants'

import {
  getAvailabilityStatisticData,
  getGroupedTimes,
} from 'module/RequestedExtraSessions/RequestedExtraSessionsHelpers'

import { Avatar, Currency, Icon, TimeString, Typography } from 'components'

import i18n from 'translations'

import { StyledAlignCenterDiv, StyledResponse } from '../RequestedExtraSessionsDetailsStyled'
import Detail from './Detail'

import { StyledAvatarContainer } from './RequestedExtraSessionsDetailsBreakdownStyled'

const RequestedExtraSessionsDetailsBreakdown = ({ data, errorStatistics }) => {
  const {
    cost,
    costSnapshot,
    createdAt,
    date,
    endTime,
    logs,
    note,
    nurseryClass,
    nurserySession,
    nurserySessionProduct,
    nurserySessionProductSnapshot,
    nurserySessionSnapshot,
    requestedBy,
    startTime,
    statistics,
  } = data || {}

  const finalNurserySession = nurserySession || nurserySessionProduct || {}
  const finalNurserySessionSnapshot = nurserySessionSnapshot || nurserySessionProductSnapshot || {}

  const { name: sessionName } = finalNurserySession
  const {
    endTime: endTimeSnapshot,
    name: nameSnapshot,
    startTime: startTimeSnapshot,
  } = finalNurserySessionSnapshot
  const {
    firstName: requesterFirstName,
    name: requesterName,
    photo: requesterPhoto,
    surname: requesterSurname,
  } = requestedBy || {}
  const {
    availabilityStatistics: errorAvailabilityStatistics,
    staffRequiredStatistics: errorStaffRequiredStatistics,
  } = errorStatistics || {}
  const {
    availabilityStatistics,
    staffRequiredStatistics,
  } = statistics || {}
  const {
    availabilityPercent,
  } = availabilityStatistics || {}
  const { times } = staffRequiredStatistics || {}
  const {
    availabilityPercent: errorAvailabilityPercent,
  } = errorAvailabilityStatistics || {}
  const { times: errorTimes } = errorStaffRequiredStatistics || {}
  const { name: roomName } = nurseryClass || {}
  const {
    color: availabilityColor,
    icon: availabilityIcon,
    label: availabilityLabel,
  } = getAvailabilityStatisticData(errorAvailabilityPercent ?? availabilityPercent)
  const groupedTimes = getGroupedTimes(errorTimes || times)
  const additionalStaffMembersNumbers = _.map(groupedTimes, (_value, index) => +index)

  const renderResponse = ({
    createdAt: responseDate,
    id: responseId,
    note: response,
    status: responseStatus,
  }) => STATUS_TYPES.PENDING !== responseStatus && (
    <StyledResponse key={responseId}>
      <Typography>
        <Typography variant="span">
          {_.find(STATUS_TYPES_OPTIONS, { value: responseStatus })?.label}
        </Typography>
        <Typography color={NEUTRAL_COLOURS.GRAY} margin="0 0 0 5px" variant="span">
          {moment(responseDate).format(DISPLAY_DATE_TIME_FORMAT)}
        </Typography>
      </Typography>
      <Typography color={NEUTRAL_COLOURS.GRAY} margin="10px 0 0">
        <Typography margin="0 5px 0 0" variant="span" bold>
          {STATUS_TYPES.APPROVED === responseStatus
            ? i18n.t('module:Parent:RequestedSessions:Details:Response:note')
            : i18n.t('module:Parent:RequestedSessions:Details:Response:reason')}
          {':'}
        </Typography>
        {response || '-'}
      </Typography>
    </StyledResponse>
  )

  const renderCurrentAndPreviousDetails = ({ current, isSame, previous }) => (
    <React.Fragment>
      {current}
      {!isSame
        && (
          <React.Fragment>
            {' ('}
            {i18n.t('module:Parent:RequestedSessions:Details:requested')}
            {' '}
            {previous}
            {')'}
          </React.Fragment>
        )}
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <Detail
        label={i18n.t('module:Parent:RequestedSessions:Details:Label:session')}
        value={renderCurrentAndPreviousDetails({
          current: sessionName,
          isSame: sessionName === nameSnapshot,
          previous: nameSnapshot,
        })}
        main
      />
      <Detail
        label={i18n.t('module:Parent:RequestedSessions:Details:Label:dateTime')}
        value={(
          <Typography color={NEUTRAL_COLOURS.GRAY}>
            {renderCurrentAndPreviousDetails({
              current: (
                <React.Fragment>
                  {moment(date).format(DISPLAY_SHORT_DATE_FORMAT)}
                  {' '}
                  <TimeString.Range end={endTime} local={false} start={startTime} />
                </React.Fragment>
              ),
              isSame: endTime === endTimeSnapshot && startTime === startTimeSnapshot,
              previous: (
                <TimeString.Range end={endTimeSnapshot} local={false} start={startTimeSnapshot} />
              ),
            })}
          </Typography>
        )}
      />
      <Detail
        label={i18n.t('module:Parent:RequestedSessions:Details:Label:cost')}
        value={(
          <Typography color={NEUTRAL_COLOURS.GRAY}>
            {renderCurrentAndPreviousDetails({
              current: <Currency value={cost} />,
              isSame: cost === costSnapshot,
              previous: <Currency value={costSnapshot} />,
            })}
          </Typography>
        )}
      />
      <Detail
        label={i18n.t('module:Parent:RequestedSessions:Details:Label:room')}
        value={(
          <Typography color={NEUTRAL_COLOURS.GRAY}>
            {roomName}
          </Typography>
        )}
      />
      <Detail
        label={i18n.t('module:Parent:RequestedSessions:Details:Label:requestedBy')}
        value={(
          <StyledAlignCenterDiv>
            <StyledAvatarContainer>
              <Avatar
                avatarSize="small"
                initials={[requesterFirstName, requesterSurname]}
                src={requesterPhoto}
                title={(
                  <Typography fontSize={14} ellipsis>
                    {requesterName}
                  </Typography>
                )}
              />
            </StyledAvatarContainer>
            <Typography color={NEUTRAL_COLOURS.GRAY} margin="0 0 0 5px" variant="span">
              {moment(createdAt).format(DISPLAY_DATE_TIME_FORMAT)}
            </Typography>
          </StyledAlignCenterDiv>
        )}
        main
      />
      <Detail
        label={i18n.t('module:Parent:RequestedSessions:Details:Label:availability')}
        value={(
          <StyledAlignCenterDiv>
            <Icon
              color={availabilityColor}
              height={14}
              icon={availabilityIcon}
            />
            <Typography margin="0 0 0 5px" variant="span">
              {availabilityLabel}
            </Typography>
          </StyledAlignCenterDiv>
        )}
        main
      />
      <Detail
        label={i18n.t('module:Parent:RequestedSessions:Details:Label:staffRequired')}
        value={(
          <Typography>
            {!errorTimes?.length && !times?.length
              && i18n.t('module:Parent:RequestedSessions:Details:StaffRequired:zero')}
          </Typography>
        )}
        main
      />
      {_.map(additionalStaffMembersNumbers, (number) => !_.isEmpty(groupedTimes[number]) && (
        <Detail
          label={i18n.t('module:Parent:RequestedSessions:Details:Label:staffMembers', { number })}
          value={_.map(groupedTimes[number], (item, index) => (
            <Typography color={NEUTRAL_COLOURS.GRAY} key={index}>
              <TimeString.Range end={item.endTime} local={false} start={item.startTime} />
            </Typography>
          ))}
        />
      ))}
      <Detail
        label={i18n.t('module:Parent:RequestedSessions:Details:Label:requestNote')}
        value={(
          <Typography color={NEUTRAL_COLOURS.GRAY}>
            {note}
          </Typography>
        )}
        main
      />
      {logs?.length ? (
        <Detail
          label={i18n.t('module:Parent:RequestedSessions:Details:Label:nurseryResponses')}
          value={_.map(logs, renderResponse)}
          main
        />
      ) : null}
    </React.Fragment>
  )
}

export default React.memo(RequestedExtraSessionsDetailsBreakdown, isEqual)
