import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { TYPE_CONTACT_FORM, TYPE_CONTACT_FORM_OPTIONS } from 'services/legacy/child/constants'

import { isRequired } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'

import { Button, Form, InfiniteDropdowns, StickyFooter } from 'components'

import i18n from 'translations'

import ChildContactsMainForm from './ChildContactsMainForm'

export const CHILD_CONTACTS_FORM = 'ChildContactsForm'

const ChildContactsForm = ({
  child,
  childId,
  formValues,
  handleSubmit,
  hasAccessToChildInParentApp,
  initialize,
  isEditContext,
  isEmailDisabled,
  isInvitationResending,
  isSubmitting,
  onChangeExistingContact,
  onResendParentAppInvitation,
  onSubmit,
  readOnly,
  selectedContact,
  userTitles,
}) => {
  const renderSearchContact = () => (
    <React.Fragment>
      <br />
      <Form.Row label={i18n.t('module:Children:Child:Contacts:Form:chooseContact')} required>
        <Form.Row.FlexItem>
          <InfiniteDropdowns.Carers
            placeholder={i18n.t('module:Children:Child:Contacts:Form:chooseContact')}
            value={selectedContact}
            withChildRelation
            onChange={onChangeExistingContact}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      {selectedContact && (
        <ChildContactsMainForm
          child={child}
          formValues={formValues}
          hasAccessToChildInParentApp={hasAccessToChildInParentApp}
          isEditContext={isEditContext}
          isEmailDisabled={isEmailDisabled}
          isInvitationResending={isInvitationResending}
          readOnly={readOnly}
          userTitles={userTitles}
          onResendParentAppInvitation={onResendParentAppInvitation}
        />
      )}
    </React.Fragment>
  )

  const renderContent = () => {
    if (isEditContext || TYPE_CONTACT_FORM.NEW === formValues?.type) {
      return (
        <ChildContactsMainForm
          child={child}
          formValues={formValues}
          hasAccessToChildInParentApp={hasAccessToChildInParentApp}
          isEditContext={isEditContext}
          isEmailDisabled={isEmailDisabled}
          isInvitationResending={isInvitationResending}
          readOnly={readOnly}
          userTitles={userTitles}
          onResendParentAppInvitation={onResendParentAppInvitation}
        />
      )
    }

    return renderSearchContact()
  }

  const handleTypeChange = (e) => {
    if (TYPE_CONTACT_FORM.NEW === e.target.value) {
      initialize({ type: TYPE_CONTACT_FORM.NEW })
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {!isEditContext && (
        <Form.Row>
          <Form.Row.FlexItem mobileFull>
            <Field
              component={Form.RadioGroup}
              disabled={readOnly}
              name="type"
              options={TYPE_CONTACT_FORM_OPTIONS}
              validate={isRequired}
              onChange={handleTypeChange}
            />
          </Form.Row.FlexItem>
        </Form.Row>
      )}
      {renderContent()}
      <StickyFooter>
        <Button
          disabled={isSubmitting}
          edge="start"
          hierarchy="tertiary"
          label={i18n.t('global:Cancel')}
          to={generateRoute('CHILDREN.CHILD.CONTACTS', { childId })}
          negativeMargins
        />
        <StickyFooter.Flex />
        {!readOnly && isEditContext && (
          <Button
            isLoading={isSubmitting}
            label={i18n.t('global:Update')}
            negativeMargins
            submit
          />
        )}
        {!isEditContext && (
          <Button
            isLoading={isSubmitting}
            label={i18n.t('module:Children:Child:Contacts:Form:addNewContact')}
            negativeMargins
            submit
          />
        )}
      </StickyFooter>
    </Form>
  )
}

export default reduxForm({ form: CHILD_CONTACTS_FORM })(ChildContactsForm)
