import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withEnquiryViewingsService } from 'services/legacy/enquiries'
import { withRouter } from 'services/router'

import ViewingEditModalView from './ViewingEditModalView'

class ViewingEditModalContainer extends Component {
  constructor(props) {
    super(props)

    const { enquiryViewingsSelectors, showAroundData } = props

    this.state = {
      showAroundInitialValues: enquiryViewingsSelectors.getEnquiryViewingsSingleInitialValues(showAroundData),
    }
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSubmitSuccess = () => {
    const { onSubmitSuccess } = this.props

    if (onSubmitSuccess) {
      onSubmitSuccess()
    }

    this.handleCloseClick()
  }

  handleCancelClick = () => {
    this.handleCloseClick()
  }

  render() {
    const {
      errorMessages,
      isFetching,
      showAroundData,
    } = this.props
    const {
      showAroundInitialValues,
    } = this.state
    const { enquiry, id: showAroundId } = showAroundData || {}
    const { id: enquiryId } = enquiry || {}

    return (
      <ViewingEditModalView
        enquiryId={enquiryId}
        errorMessages={errorMessages}
        isFetching={isFetching}
        showAroundId={showAroundId}
        showAroundInitialValues={showAroundInitialValues}
        onCancelClick={this.handleCancelClick}
        onSubmitSuccess={this.handleSubmitSuccess}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  enquiryViewingsSingleState,
}) => ({
  errorMessages: appSelectors.getErrorMessages(enquiryViewingsSingleState),
  isFetching: appSelectors.getIsFetching(enquiryViewingsSingleState),
})

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withEnquiryViewingsService,
  connect(mapState),
)

export default enhance(ViewingEditModalContainer)
