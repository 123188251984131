import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { addMillisecondsFromMidnight } from 'utils/date'

import {
  SHIFT_MAIN_TYPES,
  STAFF_REGISTER_STATUS,
  STAFF_REGISTER_STATUS_OPTIONS,
  WORKLOG_RECORD_DAY_TYPE,
} from '../constants'
import { findStaffStatus, getFormattedEntries } from '../helpers'

const getMembershipRegistersListSelector = (state) => state.membershipRegisters.list

export const getMembershipRegistersListDataSelector = createSelector(
  [getMembershipRegistersListSelector],
  (state) => state.data,
)

export const getMembershipRegistersListMetaSelector = createSelector(
  [getMembershipRegistersListSelector],
  (state) => state.meta,
)

export const getDropdownMembershipRegisters = createSelector(
  [getMembershipRegistersListDataSelector],
  (data) => _.map(
    data,
    (item) => ({
      avatar: item.photo,
      email: item.email,
      label: item.name,
      value: item.id,
    }),
  ),
)

export const getMembershipRegistersFormattedListSelector = createSelector(
  [getMembershipRegistersListDataSelector],
  (listData) => {
    if (!listData?.length) {
      return null
    }

    return _.map(listData, (item) => {
      const { currentRegisters } = item

      let lastRoom = null
      let lastEntryType = _.find(STAFF_REGISTER_STATUS_OPTIONS, { value: STAFF_REGISTER_STATUS.CLOCKED_OUT })

      if (currentRegisters?.length) {
        // To get last room and entry type to show in list
        const currentRegister = currentRegisters[currentRegisters.length - 1]
        const status = findStaffStatus(currentRegister)
        const { entries } = currentRegister

        lastEntryType = _.find(STAFF_REGISTER_STATUS_OPTIONS, { value: status })

        if (entries?.length && status !== STAFF_REGISTER_STATUS.CLOCKED_OUT) {
          const entry = entries[entries.length - 1]

          const { nurseryClass } = entry

          lastRoom = nurseryClass
        }
      }

      return {
        ...item,
        lastEntryType,
        lastRoom,
      }
    })
  },
)

export const getMembershipRegistersListTotalResultSelector = createSelector(
  [getMembershipRegistersListMetaSelector],
  (listMeta) => {
    if (!listMeta) {
      return null
    }

    return listMeta.total_results
  },
)

export const getMembershipRegistersListCriteria = (filters) => {
  const {
    accepted,
    archived,
    dateFromShiftTimes,
    dateToShiftTimes,
    endDate,
    id,
    registerStatus,
    searchBarText,
    startDate,
  } = filters

  const criteria = []

  if (_.isBoolean(accepted)) {
    criteria.push({
      field: 'accepted',
      value: +accepted,
    })
  }

  if (_.isBoolean(archived)) {
    criteria.push({
      field: 'archived',
      value: +archived,
    })
  }

  if (startDate) {
    criteria.push({
      comparator: 'after',
      field: 'currentRegisters.date',
      value: moment(startDate).format(DEFAULT_DATE_FORMAT),
    })

    criteria.push({
      comparator: 'after',
      field: 'shifts.dateTo',
      value: moment(startDate).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (endDate) {
    criteria.push({
      comparator: 'before',
      field: 'currentRegisters.date',
      value: moment(endDate).format(DEFAULT_DATE_FORMAT),
    })

    criteria.push({
      comparator: 'before',
      field: 'shifts.dateFrom',
      value: moment(endDate).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (dateFromShiftTimes) {
    criteria.push({
      comparator: 'after',
      field: 'shifts.membershipShiftTimes.day',
      value: dateFromShiftTimes,
    })
  }

  if (dateToShiftTimes) {
    criteria.push({
      comparator: 'before',
      field: 'shifts.membershipShiftTimes.day',
      value: dateToShiftTimes,
    })
  }

  if (searchBarText) {
    criteria.push({
      field: 'concatenable',
      value: searchBarText,
    })
  }

  if (id) {
    criteria.push({
      field: 'id',
      value: id,
    })
  }

  if (registerStatus?.length) {
    _.each(registerStatus, (status) => {
      criteria.push({
        field: 'registerStatus[]',
        value: status,
      })
    })
  }

  return criteria
}

const getTotalMilliseconds = (items, secondsKey) => _.reduce(
  items,
  (prevTotal, item) => prevTotal + (item[secondsKey] || 0),
  0,
)

const injectPeriods = (worklogs) => _.reduce(worklogs, (result, entries, key) => {
  const newResults = { ...result }

  if (!entries?.length) {
    newResults[key] = {
      entries: [],
    }

    return newResults
  }

  const firstEntry = entries[0]
  const lastEntry = entries[entries.length - 1]

  newResults[key] = {
    endDate: lastEntry.signOutAt ? lastEntry.signOutAt : moment(),
    entries,
    startDate: firstEntry.signInAt,
  }

  return newResults
}, {})

export const parseCurrentRegisters = (currentRegisters, item = {}) => ({
  ...item,
  breakMilliseconds: getTotalMilliseconds(currentRegisters, 'breakMilliseconds'),
  currentRegisters: _.map(currentRegisters, (register) => {
    const { date, entries } = register

    if (!entries?.length) {
      return register
    }

    const isAnyEntryOngoing = _.some(entries, ({ signOutAt }) => !signOutAt)
    const firstEntry = entries[0]
    const lastEntry = entries[entries.length - 1]
    const startTime = addMillisecondsFromMidnight(firstEntry.signInAt, date)
    const endTime = isAnyEntryOngoing ? null : lastEntry.signOutAt
    const formattedEntries = getFormattedEntries(date, entries)

    return {
      ...register,
      endDate: endTime ? addMillisecondsFromMidnight(endTime, date).toDate() : moment().toDate(),
      entries: formattedEntries,
      startDate: startTime.toDate(),
      worklog: injectPeriods(_.groupBy(formattedEntries, 'type')),
    }
  }),
  leaves: _.filter(item.shifts, ({ type }) => SHIFT_MAIN_TYPES.LEAVE === type),
  workedMilliseconds: getTotalMilliseconds(currentRegisters, 'workedMilliseconds'),
})

export const getMembershipRegistersWorklogListSelector = createSelector(
  [getMembershipRegistersListDataSelector],
  (listData) => {
    if (!listData?.length) {
      return null
    }

    return _.map(listData, (item) => {
      const { currentRegisters } = item

      return parseCurrentRegisters(currentRegisters, item)
    })
  },
)

export const getWorklogDayType = (previousRecord, selectedDate) => {
  const { endDate, ongoing, startDate } = previousRecord || {}

  if (!startDate || !selectedDate) {
    return WORKLOG_RECORD_DAY_TYPE.SAME_DAY
  }

  if (moment(startDate).format(DEFAULT_DATE_FORMAT) === moment(selectedDate).format(DEFAULT_DATE_FORMAT)) {
    return WORKLOG_RECORD_DAY_TYPE.SAME_DAY
  }

  if ((
    moment(startDate).format(DEFAULT_DATE_FORMAT) < moment(selectedDate).format(DEFAULT_DATE_FORMAT)
    && moment(endDate).format(DEFAULT_DATE_FORMAT) > moment(selectedDate).format(DEFAULT_DATE_FORMAT)
  ) || ongoing) {
    return WORKLOG_RECORD_DAY_TYPE.IN_BETWEEN_DAY
  }

  return WORKLOG_RECORD_DAY_TYPE.LAST_DAY
}
