import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_ORGANIZATION,
  CREATE_ORGANIZATION,
  CREATE_ORGANIZATION_FAILED,
  CREATE_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION,
  GET_ORGANIZATION_FAILED,
  GET_ORGANIZATION_FLAGS,
  GET_ORGANIZATION_FLAGS_FAILED,
  GET_ORGANIZATION_FLAGS_SUCCESS,
  GET_ORGANIZATION_SUCCESS,
  UPDATE_FINANCE_MIGRATION,
  UPDATE_FINANCE_MIGRATION_FAILED,
  UPDATE_FINANCE_MIGRATION_SUCCESS,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_FAILED,
  UPDATE_ORGANIZATION_FLAG,
  UPDATE_ORGANIZATION_FLAG_FAILED,
  UPDATE_ORGANIZATION_FLAG_SUCCESS,
  UPDATE_ORGANIZATION_SUCCESS,
} from './constants'

interface OrganizationSingleActions extends BasicActionOptionsProps {
  payload?: any
}

export const create = (options: OrganizationSingleActions = {}) => ({ organizationApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, payload = {} } = options

  dispatch({ type: CREATE_ORGANIZATION })

  organizationApiClient.create(payload).then(
    ({ data }) => {
      dispatch({
        payload: data,
        type: CREATE_ORGANIZATION_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: CREATE_ORGANIZATION_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const getV2 = (options: BasicActionOptionsProps) => ({ organizationApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: GET_ORGANIZATION_FAILED,
      init: GET_ORGANIZATION,
      success: GET_ORGANIZATION_SUCCESS,
    },
    options,
    service: organizationApiClient,
    serviceMethod: 'get',
  })
)

export const get = (
  organizationId,
  options: BasicActionOptionsProps,
) => ({ organizationApiClient }) => (dispatch) => {
  const { onSuccess, params = {} } = options

  dispatch({
    organizationId,
    params,
    type: GET_ORGANIZATION,
  })

  organizationApiClient.get(organizationId, params).then(
    ({ data }) => {
      dispatch({
        payload: data,
        type: GET_ORGANIZATION_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: GET_ORGANIZATION_FAILED,
      })
    },
  )
}

export const listFlags = (
  organizationId,
  options: BasicActionOptionsProps = {},
) => ({ organizationApiClient }) => (dispatch) => {
  const { onSuccess, params = {} } = options || {}

  dispatch({
    organizationId,
    params,
    type: GET_ORGANIZATION_FLAGS,
  })

  organizationApiClient.listFlags(organizationId, params).then(
    ({ data }) => {
      dispatch({
        payload: data,
        type: GET_ORGANIZATION_FLAGS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: GET_ORGANIZATION_FLAGS_FAILED,
      })
    },
  )
}

export const update = (
  organizationId,
  options: OrganizationSingleActions = {},
) => ({ organizationApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {}, payload = {} } = options

  dispatch({
    organizationId,
    params,
    payload,
    type: UPDATE_ORGANIZATION,
  })

  organizationApiClient.update(organizationId, params, payload).then(
    ({ data }) => {
      dispatch({
        payload: data,
        type: UPDATE_ORGANIZATION_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => {
      dispatch({
        error: !response.extra ? response : null,
        type: UPDATE_ORGANIZATION_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const updateFlag = (
  organizationId,
  flag,
  options: OrganizationSingleActions = {},
) => ({ organizationApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {}, payload = {} } = options

  dispatch({
    flag,
    organizationId,
    params,
    payload,
    type: UPDATE_ORGANIZATION_FLAG,
  })

  organizationApiClient.updateFlag(organizationId, flag, params, payload).then(
    ({ data }) => {
      dispatch({
        payload: data,
        type: UPDATE_ORGANIZATION_FLAG_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: UPDATE_ORGANIZATION_FLAG_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const updateFinanceMigration = (options: BasicActionOptionsProps) => ({
  organizationApiClient,
}) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_FINANCE_MIGRATION_FAILED,
      init: UPDATE_FINANCE_MIGRATION,
      success: UPDATE_FINANCE_MIGRATION_SUCCESS,
    },
    options,
    service: organizationApiClient,
    serviceMethod: 'updateNewFinanceMigration',
  })
)

export const clearSingle = () => ({
  type: CLEAR_ORGANIZATION,
})
