import moment from 'moment'

import React from 'react'
import { WrappedFieldProps } from 'redux-form'

import { DatePickerProps } from 'components/DatePicker/DatePicker'
import { TimePickerProps } from 'components/TimePicker/TimePicker'

import { DatePicker, FieldError, TimePicker } from 'components'

import { StyledDateTimePicker } from './FormDateTimePickerStyled'

type FormDateTimePickerFullProps = DatePickerProps & TimePickerProps & WrappedFieldProps & {
  onDateChange: (date: string | moment.Moment) => void
}

const FormDateTimePicker: React.FC<FormDateTimePickerFullProps> = ({
  input: { onChange, value },
  meta: { error },
  onDateChange,
  ...rest
}) => {
  const handleTimeChange = (time) => {
    if (!time) {
      const date = moment(value).format('LL')

      onChange(date)

      if (onDateChange) {
        onDateChange(date)
      }

      return
    }

    let date = moment()
    if (value) {
      date = moment(value)
    }

    const hour = +moment(time, 'x').format('HH')
    const minute = +moment(time, 'x').format('mm')

    date.minute(minute)
    date.hour(hour)

    onChange(date)

    if (onDateChange) {
      onDateChange(date)
    }
  }

  const handleDateChange = (date) => {
    if (!date) {
      onChange(date)

      if (onDateChange) {
        onDateChange(date)
      }

      return
    }

    let currentValue = moment()
    if (value) {
      currentValue = moment(value)
    }

    const day = +moment(date).format('DD')
    const month = +moment(date).format('MM')
    const year = +moment(date).format('YYYY')

    currentValue.year(year)
    currentValue.month(month - 1)
    currentValue.date(day)

    onChange(currentValue)

    if (onDateChange) {
      onDateChange(currentValue)
    }
  }

  return (
    <div>
      <StyledDateTimePicker>
        <DatePicker
          {...rest}
          value={value}
          onChange={handleDateChange}
        />
        <TimePicker
          {...rest}
          clearable={false}
          value={value}
          onChange={handleTimeChange}
        />
      </StyledDateTimePicker>
      <FieldError error={error} />
    </div>
  )
}

export default FormDateTimePicker
