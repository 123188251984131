import i18n from 'translations'

export const COLUMNS = [
  {
    highlightedTotal: true,
    label: i18n.t('global:Finance:TotalRevenue'),
    value: 'totalIncome',
  },
  {
    label: i18n.t('global:Finance:Sessions'),
    value: 'totalSessions',
  },
  {
    label: i18n.t('global:Finance:Consumables'),
    value: 'totalConsumables',
  },
  {
    label: i18n.t('global:Finance:ExtraItems'),
    value: 'totalExtraItems',
  },
  {
    label: i18n.t('global:Finance:ExtraSessions'),
    value: 'totalExtraSessions',
  },
  {
    label: i18n.t('global:Finance:Other'),
    value: 'totalOther',
  },
  {
    label: i18n.t('global:Finance:FundingDeficit'),
    value: 'totalFundingDeficit',
  },
  {
    label: i18n.t('global:Finance:FundingDeducted'),
    value: 'totalFundingDeducted',
  },
  {
    label: i18n.t('global:Finance:Discounts'),
    value: 'totalDiscount',
  },
  {
    label: i18n.t('global:Finance:FeeIncome'),
    total: true,
    value: 'totalInvoices',
  },
  {
    label: i18n.t('global:Finance:FundingIncome'),
    value: 'totalFundingIncome',
  },
  {
    label: i18n.t('global:Finance:TotalRevenue'),
    total: true,
    value: 'totalIncome',
  },
]

export const COLUMNS_V3 = [
  {
    highlightedTotal: true,
    label: i18n.t('global:Finance:TotalRevenue'),
    value: 'totalIncome',
  },
  {
    label: i18n.t('global:Finance:Packages'),
    value: 'totalPackages',
  },
  {
    label: i18n.t('global:Finance:RegularSessions'),
    value: 'totalSessions',
  },
  {
    label: i18n.t('global:Finance:RegularConsumables'),
    value: 'totalConsumables',
  },
  {
    label: i18n.t('global:Finance:RegularItems'),
    value: 'totalRegularItems',
  },
  {
    label: i18n.t('global:Finance:ExtraItems'),
    value: 'totalExtraItems',
  },
  {
    label: i18n.t('global:Finance:ExtraSessions'),
    value: 'totalExtraSessions',
  },
  {
    label: i18n.t('global:Finance:ExtraConsumables'),
    value: 'totalExtraConsumables',
  },
  {
    label: i18n.t('global:Finance:Other'),
    value: 'totalOther',
  },
  {
    label: i18n.t('global:Finance:FundingDeficit'),
    value: 'totalFundingDeficit',
  },
  {
    label: i18n.t('global:Finance:FundingDeducted'),
    value: 'totalFundingDeducted',
  },
  {
    label: i18n.t('global:Finance:Discounts'),
    value: 'totalDiscount',
  },
  {
    label: i18n.t('global:Finance:FeeIncome'),
    total: true,
    value: 'totalInvoices',
  },
  {
    label: i18n.t('global:Finance:FundingIncome'),
    value: 'totalFundingIncome',
  },
  {
    label: i18n.t('global:Finance:TotalRevenue'),
    total: true,
    value: 'totalIncome',
  },
]
