import _ from 'lodash'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { change, getFormValues, initialize, stopSubmit } from 'redux-form'

import { TYPE_CONTACT_FORM } from 'services/legacy/child/constants'
import { UPDATE_CHILD_CONTACT_SETUP } from 'module/Children/Child/ChildContainer'

import { getBackendErrors } from 'utils/backendErrors'
import { generateRoute } from 'utils/routing'
import eventBus from 'utils/eventBus'

import { withAppService } from 'services/app'
import { withCarerChildRelationsService } from 'services/legacy/carerChildRelations'
import { withCarersService } from 'services/legacy/carers'
import { withChildService } from 'services/legacy/child'
import { withModalService } from 'services/utils/modal'
import { withUploadService } from 'services/legacy/upload'
import { withRouter } from 'services/router'

import i18n from 'translations'

import ChildContactsAddView from './ChildContactsAddView'
import { CHILD_CONTACTS_FORM } from './components/ChildContactsForm'

export const CARER_GROUPS = {
  read: [
    'carer.details',
    'carer.address',
    'carer.parentAccount',
    'parentAccount',
    'carer.carerChildRelations',
    'carerChildRelation.details',
    'carerChildRelation.child',
  ],
}

class ChildContactsAddContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hasAccessToChildInParentApp: false,
      imageUploading: false,
      isEmailDisabled: false,
      relationId: null,
      selectedContact: null,
    }
  }

  componentDidMount() {
    const { params } = this.props
    const { carerId, childId } = params

    window.scrollTo(0, 0)

    if (!this.isEditContext()) {
      return
    }

    this.fetch(carerId, childId)
  }

  componentWillUnmount() {
    const { carerChildRelationsActions, carersActions } = this.props

    carerChildRelationsActions.clear()
    carersActions.clearSingle()
  }

  fetch = (carerId, childId, fromExistingContact) => {
    const { carersActions, initializeValues, userTitles } = this.props

    carersActions.get({
      onSuccess: ({ data }) => {
        let relation

        if (fromExistingContact) {
          relation = data.carerChildRelations[0]
        } else {
          relation = _.find(data.carerChildRelations, ({ child: { id } }) => (
            +id === +childId
          ))
        }

        const { id: relationId, ...restRelations } = relation || {}

        const carerData = {
          ...data,
          ...restRelations,
          relation: restRelations?.relation ? {
            label: restRelations.relation,
            value: restRelations.relation,
          } : null,
          title: _.find(userTitles, { value: data.title }),
          type: fromExistingContact ? TYPE_CONTACT_FORM.EXISTING : TYPE_CONTACT_FORM,
        }

        this.setState({
          hasAccessToChildInParentApp: !!relation?.hasAccessToChildInParentApp,
          isEmailDisabled: !!data?.parentAccount?.lastLoginDate,
          relationId,
        })

        initializeValues(carerData)
      },
      params: [carerId, {
        groups: CARER_GROUPS,
      }],
    })
  }

  isEditContext = () => {
    const { params: { carerId } } = this.props

    return !!carerId
  }

  handleGoNext = () => {
    const { navigate, params } = this.props
    const { childId } = params

    eventBus.dispatch(UPDATE_CHILD_CONTACT_SETUP)
    navigate(generateRoute('CHILDREN.CHILD.CONTACTS', { childId }))
  }

  handleSubmitSuccess = (response, values) => {
    const {
      carerChildRelationsActions,
      carerChildRelationsSelectors,
      params,
    } = this.props
    const { relationId, selectedContact } = this.state
    const { childId } = params

    // eslint-disable-next-line no-unsafe-optional-chaining
    let carerId = +params?.carerId

    if (!this.isEditContext()) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      carerId = +response?.data?.id
    }

    const body = carerChildRelationsSelectors.getPayload({
      carerId: carerId || selectedContact?.value,
      childId: +childId,
      isEditMode: this.isEditContext(),
      values,
    })

    if (this.isEditContext()) {
      return carerChildRelationsActions.update({
        body,
        onSuccess: this.handleGoNext,
        params: [relationId, {}],
      })
    }

    return carerChildRelationsActions.create({
      body,
      onSuccess: this.handleGoNext,
      params: [{}],
    })
  }

  handleSubmitFailed = (response) => {
    const { injectValidation } = this.props
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    return injectValidation(errors)
  }

  uploadFile = async (body) => {
    const { uploadActions } = this.props

    this.setState({ imageUploading: true })

    const url = await uploadActions.uploadFile(body.photo.file)

    this.setState({ imageUploading: false })

    return url
  }

  handleSubmit = async (values) => {
    const { carersActions, carersSelectors, changeValue, params: { carerId } } = this.props
    const { selectedContact } = this.state

    const body = carersSelectors.getCarersValuesForm(values)

    if (false === body?.photo?.isUploaded) {
      body.photo = await this.uploadFile(body)

      changeValue('photos', {
        isUploaded: true,
        value: body.photo,
      })
    } else {
      body.photo = body?.photo?.value
    }

    if (this.isEditContext() || selectedContact?.value) {
      return carersActions.update({
        body,
        onFailed: this.handleSubmitFailed,
        onSuccess: (response) => this.handleSubmitSuccess(response, values),
        params: [carerId || selectedContact?.value],
      })
    }

    return carersActions.create({
      body,
      onFailed: this.handleSubmitFailed,
      onSuccess: (response) => this.handleSubmitSuccess(response, values),
      params: [{}],
    })
  }

  handleChangeExistingContact = (option) => {
    const { initializeValues, params } = this.props
    const { childId } = params

    if (!option) {
      this.setState({
        isEmailDisabled: false,
        selectedContact: null,
      })

      return initializeValues({
        type: TYPE_CONTACT_FORM.EXISTING,
      })
    }

    const { modalActions, modalConsts } = this.props
    const { selectedContact } = this.state

    if (selectedContact) {
      return modalActions.show(modalConsts.TYPES.CONFIRM, {
        confirmButtonLabel: i18n.t('global:Continue'),
        onConfirm: () => {
          this.setState({ selectedContact: option })
          this.fetch(option.value, childId, true)
        },
        text: i18n.t('module:Children:Child:Contacts:Form:lostUnsavedChanges'),
      })
    }

    this.setState({ selectedContact: option })
    return this.fetch(option.value, childId, true)
  }

  handleRemoveCarerAccepted = () => {
    const { carerChildRelationsActions } = this.props
    const { relationId } = this.state

    carerChildRelationsActions.remove({
      onSuccess: this.handleGoNext,
      params: [relationId],
    })
  }

  handleRemoveCarer = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'trash',
      onConfirm: this.handleRemoveCarerAccepted,
      text: i18n.t('module:Children:Child:Contacts:Form:removeCarer'),
    })
  }

  handleResendParentAppInvitationResolve = (text) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.ALERT, { text })
  }

  handleResendParentAppInvitation = () => {
    const { carersActions, formValues, params: { carerId, childId } } = this.props

    return carersActions.resendParentAppInvitation(
      carerId,
      childId,
      {
        onFailed: () => this.handleResendParentAppInvitationResolve(
          i18n.t('module:Children:Child:Contacts:Form:resendInvitationModalErrorText'),
        ),
        onSuccess: () => this.handleResendParentAppInvitationResolve(
          i18n.t(
            'module:Children:Child:Contacts:Form:resendInvitationModalSuccessText',
            { carerEmail: formValues.email },
          ),
        ),
      },
    )
  }

  render() {
    const {
      child,
      errorMessages,
      formValues,
      isInvitationResending,
      isSubmitting,
      isUserGrantedEdit,
      params,
      userTitles,
    } = this.props
    const { childId } = params

    const { hasAccessToChildInParentApp, imageUploading, isEmailDisabled, selectedContact } = this.state

    const initialValues = {
      type: TYPE_CONTACT_FORM.NEW,
    }

    return (
      <ChildContactsAddView
        child={child}
        childId={childId}
        errorMessages={errorMessages}
        formValues={formValues}
        hasAccessToChildInParentApp={hasAccessToChildInParentApp}
        initialValues={initialValues}
        isEditContext={this.isEditContext()}
        isEmailDisabled={isEmailDisabled}
        isInvitationResending={isInvitationResending}
        isSubmitting={isSubmitting || imageUploading}
        isUserGrantedEdit={isUserGrantedEdit}
        selectedContact={selectedContact}
        userTitles={userTitles}
        onChangeExistingContact={this.handleChangeExistingContact}
        onRemoveCarer={this.handleRemoveCarer}
        onResendParentAppInvitation={this.handleResendParentAppInvitation}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  changeValue: (field, value) => change(CHILD_CONTACTS_FORM, field, value),
  initializeValues: (values) => initialize(CHILD_CONTACTS_FORM, values),
  injectValidation: (errors) => stopSubmit(CHILD_CONTACTS_FORM, errors),
}

const mapState = (state, {
  appSelectors,
  carerChildRelationsSingleState,
  carersSelectors,
  carersSingleState,
  childSelectors,
}) => ({
  child: childSelectors.getChildSelector(state),
  errorMessages: appSelectors.getErrorMessages(carersSingleState, carerChildRelationsSingleState),
  formValues: getFormValues(CHILD_CONTACTS_FORM)(state),
  isInvitationResending: carersSelectors.getIsInvitationResending(state),
  isSubmitting: appSelectors.getIsSubmitting(carersSingleState, carerChildRelationsSingleState),
  isUserGrantedEdit: carersSelectors.isGrantedEditSelector(state),
  userTitles: appSelectors.getUserTitlesOptions(state),
})

const enhance = compose(
  withAppService,
  withCarerChildRelationsService,
  withCarersService,
  withChildService,
  withModalService,
  withUploadService,
  withRouter,
  connect(mapState, mapDispatch),
)

export default enhance(ChildContactsAddContainer)
