import { generateBasicActions } from 'utils/service'

import {
  CLEAR_REQUESTED_EXTRA_SESSION,
  GET_REQUESTED_EXTRA_SESSION,
  GET_REQUESTED_EXTRA_SESSION_FAILED,
  GET_REQUESTED_EXTRA_SESSION_SUCCESS,
  UPDATE_REQUESTED_EXTRA_SESSION,
  UPDATE_REQUESTED_EXTRA_SESSION_FAILED,
  UPDATE_REQUESTED_EXTRA_SESSION_SUCCESS,
} from './constants'

export const get = (options = {}) => ({ requestedExtraSessionsApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: GET_REQUESTED_EXTRA_SESSION_FAILED,
      init: GET_REQUESTED_EXTRA_SESSION,
      success: GET_REQUESTED_EXTRA_SESSION_SUCCESS,
    },
    options,
    service: requestedExtraSessionsApiClient,
    serviceMethod: 'get',
  })
)

export const update = (options = {}) => ({ requestedExtraSessionsApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_REQUESTED_EXTRA_SESSION_FAILED,
      init: UPDATE_REQUESTED_EXTRA_SESSION,
      success: UPDATE_REQUESTED_EXTRA_SESSION_SUCCESS,
    },
    options,
    service: requestedExtraSessionsApiClient,
    serviceMethod: 'update',
  })
)

export const clear = () => ({
  type: CLEAR_REQUESTED_EXTRA_SESSION,
})
