import i18n from 'translations'

import { FLAG_COLOURS } from 'constants/colors'

export const STRIPE_STATUSES = {
  COMPLETED: 'COMPLETED',
  ENABLED: 'ENABLED',
  NOT_ONBOARDED: 'NOT_ONBOARDED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  RESTRICTED: 'RESTRICTED',
  RESTRICTED_SOON: 'RESTRICTED_SOON',
}

export const STRIPE_STATUSES_OPTIONS = [
  {
    background: '#cbf4c9',
    color: '#0e6245',
    label: i18n.t('module:Management:Finance:PaymentGateway:stripeStatuses:completed'),
    value: STRIPE_STATUSES.COMPLETED,
  },
  {
    background: '#fde2dd',
    color: '#a41c4e',
    label: i18n.t('module:Management:Finance:PaymentGateway:stripeStatuses:not_onboarded'),
    value: STRIPE_STATUSES.NOT_ONBOARDED,
  },
  {
    background: '#d6ecff',
    color: '#3d4eac',
    label: i18n.t('module:Management:Finance:PaymentGateway:stripeStatuses:pending'),
    value: STRIPE_STATUSES.PENDING,
  },
  {
    background: '#e3e8ee',
    color: '#4f566b',
    label: i18n.t('module:Management:Finance:PaymentGateway:stripeStatuses:rejected'),
    value: STRIPE_STATUSES.REJECTED,
  },
  {
    background: '#d6ecff',
    color: '#3d4eac',
    label: i18n.t('module:Management:Finance:PaymentGateway:stripeStatuses:enabled'),
    value: STRIPE_STATUSES.ENABLED,
  },
  {
    background: '#fde2dd',
    color: '#a41c4e',
    label: i18n.t('module:Management:Finance:PaymentGateway:stripeStatuses:restricted'),
    value: STRIPE_STATUSES.RESTRICTED,
  },
  {
    background: '#f8e5b9',
    color: '#983705',
    label: i18n.t('module:Management:Finance:PaymentGateway:stripeStatuses:restricted_soon'),
    value: STRIPE_STATUSES.RESTRICTED_SOON,
  },
]

export const CAPABILITIES_TYPES = {
  AMERICAN_EXPRESS: 'american_express',
  BACS: 'bacs_debit_payments',
  BACS_BASIC: 'bacs',
  CARD: 'card_payments',
  SEPA: 'sepa_debit_payments',
}

export const PAYOUTS_STATUSES = {
  CANCELED: {
    color: FLAG_COLOURS.WARNING,
    label: i18n.t('services:NurseryIntegrations:payoutsStatuses:canceled'),
    order: 4,
    value: 'canceled',
  },
  FAILED: {
    color: FLAG_COLOURS.ERROR,
    label: i18n.t('services:NurseryIntegrations:payoutsStatuses:failed'),
    order: 3,
    value: 'failed',
  },
  IN_TRANSIT: {
    color: FLAG_COLOURS.INFO,
    label: i18n.t('services:NurseryIntegrations:payoutsStatuses:inTransit'),
    order: 2,
    value: 'in_transit',
  },
  PAID: {
    color: FLAG_COLOURS.SUCCESS,
    label: i18n.t('services:NurseryIntegrations:payoutsStatuses:paid'),
    order: 5,
    value: 'paid',
  },
  PENDING: {
    color: FLAG_COLOURS.INFO,
    label: i18n.t('services:NurseryIntegrations:payoutsStatuses:pending'),
    order: 1,
    value: 'pending',
  },
}

export const DISCONNECT_CONDITIONS = [
  {
    label: i18n.t('module:Modals:StripeDisconnectConditions:conditions:allCapabilitiesDisabled'),
    value: 'allCapabilitiesDisabled',
  },
  {
    label: i18n.t('module:Modals:StripeDisconnectConditions:conditions:allPaymentsProcessed'),
    value: 'allPaymentsProcessed',
  },
  {
    label: i18n.t('module:Modals:StripeDisconnectConditions:conditions:allPayoutsProcessed'),
    value: 'allPayoutsProcessed',
  },
]

export const TYPES_OF_PAYOUT_FAILURES = [
  {
    label: i18n.t('services:NurseryIntegrations:payoutFailures:accountClosed'),
    value: 'account_closed',
  },
  {
    label: i18n.t('services:NurseryIntegrations:payoutFailures:accountFrozen'),
    value: 'account_frozen',
  },
  {
    label: i18n.t('services:NurseryIntegrations:payoutFailures:bankAccountRestricted'),
    value: 'bank_account_restricted',
  },
  {
    label: i18n.t('services:NurseryIntegrations:payoutFailures:bankOwnershipChanged'),
    value: 'bank_ownership_changed',
  },
  {
    label: i18n.t('services:NurseryIntegrations:payoutFailures:couldNotProcess'),
    value: 'could_not_process',
  },
  {
    label: i18n.t('services:NurseryIntegrations:payoutFailures:debitNotAuthorized'),
    value: 'debit_not_authorized',
  },
  {
    label: i18n.t('services:NurseryIntegrations:payoutFailures:declined'),
    value: 'declined',
  },
  {
    label: i18n.t('services:NurseryIntegrations:payoutFailures:insufficientFunds'),
    value: 'insufficient_funds',
  },
  {
    label: i18n.t('services:NurseryIntegrations:payoutFailures:invalidAccountNumber'),
    value: 'invalid_account_number',
  },
  {
    label: i18n.t('services:NurseryIntegrations:payoutFailures:incorrectAccountHolderName'),
    value: 'incorrect_account_holder_name',
  },
  {
    label: i18n.t('services:NurseryIntegrations:payoutFailures:incorrectAccountHolderAddress'),
    value: 'incorrect_account_holder_address',
  },
  {
    label: i18n.t('services:NurseryIntegrations:payoutFailures:incorrectAccountHolderTaxId'),
    value: 'incorrect_account_holder_tax_id',
  },
  {
    label: i18n.t('services:NurseryIntegrations:payoutFailures:invalidCurrency'),
    value: 'invalid_currency',
  },
  {
    label: i18n.t('services:NurseryIntegrations:payoutFailures:noAccount'),
    value: 'no_account',
  },
  {
    label: i18n.t('services:NurseryIntegrations:payoutFailures:unsupportedCard'),
    value: 'unsupported_card',
  },
]

export const RECURRING_PAYMENT_METHOD_TYPE = {
  BACS_DEBIT: 'bacs_debit',
  CARD: 'card',
}

export const RECURRING_PAYMENT_METHOD_LABEL = {
  [RECURRING_PAYMENT_METHOD_TYPE.CARD]:
    i18n.t('services:NurseryIntegrations:PaymentMethod:card'),
  [RECURRING_PAYMENT_METHOD_TYPE.BACS_DEBIT]:
    i18n.t('services:NurseryIntegrations:PaymentMethod:bacs_debit'),
}

export const RECURRING_PAYMENT_METHOD_OPTIONS = [
  {
    label: RECURRING_PAYMENT_METHOD_LABEL[RECURRING_PAYMENT_METHOD_TYPE.CARD],
    value: RECURRING_PAYMENT_METHOD_TYPE.CARD,
  },
  {
    label: RECURRING_PAYMENT_METHOD_LABEL[RECURRING_PAYMENT_METHOD_TYPE.BACS_DEBIT],
    value: RECURRING_PAYMENT_METHOD_TYPE.BACS_DEBIT,
  },
]

export const SETUP_TYPE = {
  STRIPE: 'stripe',
  custom: 'custom',
}

export const SETUP_TYPE_LABEL = {
  [SETUP_TYPE.STRIPE]:
    i18n.t('services:NurseryIntegrations:SetupType:stripe'),
  [SETUP_TYPE.custom]:
    i18n.t('services:NurseryIntegrations:SetupType:custom'),
}

export const STATUS_TYPE = {
  ACTIVE: 'active',
  NOT_ACTIVE: 'not-active',
}

export const STATUS_LABEL = {
  [STATUS_TYPE.ACTIVE]:
    i18n.t('services:NurseryIntegrations:Status:active'),
  [STATUS_TYPE.NOT_ACTIVE]:
    i18n.t('services:NurseryIntegrations:Status:notActive'),
}

export const STATUS_OPTIONS = [
  {
    label: STATUS_LABEL[STATUS_TYPE.ACTIVE],
    value: STATUS_TYPE.ACTIVE,
  },
  {
    label: STATUS_LABEL[STATUS_TYPE.NOT_ACTIVE],
    value: STATUS_TYPE.NOT_ACTIVE,
  },
]

export enum PAYOUT_TYPES {
  PAYMENT = 'payment',
  PAYOUT = 'payout',
}

export type PAYOUT_TRANSACTION_TYPE = Record<PAYOUT_TYPES, string>

export const PAYOUT_TRANSACTION_TYPE_LABEL: PAYOUT_TRANSACTION_TYPE = {
  [PAYOUT_TYPES.PAYMENT]:
    i18n.t('global:Finance:Payment'),
  [PAYOUT_TYPES.PAYOUT]:
  i18n.t('global:Finance:Payout'),
}
