import moment from 'moment'
import _ from 'lodash'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { getFormValues, initialize, stopSubmit } from 'redux-form'

import { FOOD_CATEGORY } from 'services/legacy/foodMenu/constants'
import { DAILY_DIARY_DATE_FORMAT } from 'services/legacy/dailyDiary/constants'

import { EVENTS, logEvent } from 'analytics'

import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withFoodMenuService } from 'services/legacy/foodMenu'
import { withFoodTypesService } from 'services/legacy/foodTypes'
import { withSnackbarService } from 'services/utils/snackbar'
import { withRouter } from 'services/router'

import i18n from 'translations'

import FoodMenuView from './FoodMenuView'
import { FOOD_MENU_FORM } from './components/FoodMenuForm/FoodMenuForm'

class FoodMenuContainer extends Component {
  constructor(props) {
    super(props)

    const { category } = this.props

    const foodCategory = FOOD_CATEGORY[category]

    this.state = {
      date: null,
      foodCategory,
      tabIndex: this.getDefaultTabIndex(foodCategory),
    }
  }

  componentDidMount() {
    const { foodTypesActions, onChangeSession } = this.props
    const { foodCategory } = this.state
    foodTypesActions.clearList()

    this.setState({
      tabIndex: this.getDefaultTabIndex(foodCategory),
    })

    onChangeSession(this.getDefaultTabIndex(foodCategory))
  }

  shouldComponentUpdate(nextProps) {
    const { location: { query: nextQuery } } = nextProps
    const { date } = this.state

    if (date !== nextQuery.date) {
      this.setState({ date: nextQuery.date }, () => {
        this.reinitializeForm()
      })
    }

    return true
  }

  getDefaultTabIndex = (foodCategory) => {
    let tabIndex = 0

    const currentTime = +moment(0, 'x')
      .set('hour', moment().get('hour'))
      .set('minute', moment().get('minute'))
      .format('x')

    const findIndex = (category) => (
      _.each(FOOD_CATEGORY[category].sessions, (session, index) => {
        if (currentTime > FOOD_CATEGORY.MEAL.sessions[index].startTime) {
          tabIndex = index
        }
      })
    )

    if (foodCategory.value === FOOD_CATEGORY.MEAL.value) {
      findIndex(FOOD_CATEGORY.MEAL.value)
    }

    if (foodCategory.value === FOOD_CATEGORY.SNACK.value) {
      findIndex(FOOD_CATEGORY.SNACK.value)
    }

    return tabIndex
  }

  handleUpdateFoodMenuSuccess = (values, criteria, isUpdate = false) => {
    const { onUpdateChildRecords, snackbarActions } = this.props

    onUpdateChildRecords(values)

    snackbarActions.show({
      message: isUpdate
        ? i18n.t('module:DailyDiary:Foods:foodMenu:menuUpdated')
        : i18n.t('module:DailyDiary:Foods:foodMenu:menuSaved'),
    })
  }

  handleUpdateFoodMenuFailed = (response) => {
    const { injectValidation, location: { query } } = this.props
    const { foodCategory, tabIndex } = this.state
    const { date = moment().format(DAILY_DIARY_DATE_FORMAT) } = query

    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    const category = foodCategory.value
    const session = foodCategory.sessions[tabIndex]

    return injectValidation(`${FOOD_MENU_FORM}_${date}_${category}_${session.value}`, errors)
  }

  handleSubmit = (values) => {
    const { foodMenu, foodMenuActions, foodTypesSelectors } = this.props
    const { foodCategory, tabIndex } = this.state
    const session = foodCategory.sessions[tabIndex]

    const criteria = foodTypesSelectors.getCriteria(
      values,
      foodCategory.value,
      session.value,
      foodMenu && foodMenu.items,
    )

    if (foodMenu?.id) {
      return foodMenuActions.update(
        foodMenu.id,
        criteria,
        () => this.handleUpdateFoodMenuSuccess(values, criteria, true),
        this.handleUpdateFoodMenuFailed,
      )
    }

    return foodMenuActions.create(
      criteria,
      () => this.handleUpdateFoodMenuSuccess(values, criteria),
      this.handleUpdateFoodMenuFailed,
    )
  }

  handleChangeTab = (tabIndex) => {
    const { foodTypesActions, onChangeSession } = this.props
    const { foodCategory } = this.state
    const { sessions, value } = foodCategory
    foodTypesActions.clearList()

    this.setState({
      tabIndex,
    }, () => {
      onChangeSession(tabIndex)

      const sessionType = sessions[tabIndex]

      logEvent(EVENTS.DIARY_FOOD_TAB_CHANGED, { category: value, type: sessionType.label })

      this.reinitializeForm()
    })
  }

  reinitializeForm = () => {
    const { getInitialValues, initializeValues } = this.props
    const { date = moment().format(DAILY_DIARY_DATE_FORMAT), foodCategory } = this.state

    const initialValues = getInitialValues(
      date,
      foodCategory.value,
      this.getSession(),
    )

    initializeValues(
      this.getFormName({
        category: foodCategory.value,
        date,
        session: this.getSession().value,
      }),
      initialValues,
    )
  }

  getFormName = ({ category, date, session }) => `${FOOD_MENU_FORM}_${date}_${category}_${session}`

  getSession = () => {
    const { foodCategory, tabIndex } = this.state

    return FOOD_CATEGORY[foodCategory.value].sessions[tabIndex]
  }

  render() {
    const {
      errorMessages,
      formValues,
      isSubmitting,
      location: { query: { date = moment().format(DAILY_DIARY_DATE_FORMAT) } },
    } = this.props
    const { foodCategory, tabIndex } = this.state

    return (
      <FoodMenuView
        date={date}
        errorMessages={errorMessages}
        foodCategory={foodCategory}
        formValues={formValues}
        getFormName={this.getFormName}
        isSubmitting={isSubmitting}
        tabIndex={tabIndex}
        onChangeTab={this.handleChangeTab}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  foodMenuSelectors,
  foodMenuSingleState,
  foodTypesSingleState,
}) => ({
  errorMessages: appSelectors.getErrorMessages(
    foodMenuSingleState,
  ),
  foodMenu: foodMenuSelectors.getFoodMenuData(state),
  formValues: (formName) => getFormValues(formName)(state),
  getInitialValues: (date, category, session) => foodMenuSelectors.getInitialValues(date, category, session)(state),
  isSubmitting: appSelectors.getIsSubmitting(
    foodMenuSingleState,
    foodTypesSingleState,
  ),
})

const mapDispatch = {
  initializeValues: (formName, values) => initialize(formName, values),
  injectValidation: (formName, data) => stopSubmit(formName, data),
}

const enhance = compose(
  withAppService,
  withFoodMenuService,
  withFoodTypesService,
  withRouter,
  withSnackbarService,
  connect(mapState, mapDispatch),
)

export default enhance(FoodMenuContainer)
