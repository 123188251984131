import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_NURSERIES_SIGNUP_REPORT,
  NURSERIES_SIGNUP_REPORT,
  NURSERIES_SIGNUP_REPORT_FAILED,
  NURSERIES_SIGNUP_REPORT_SUCCESS,
} from './constants'

export const getNurseries = (options: BasicActionOptionsProps) => ({
  childrenSignedUpReportApiClient,
}) => generateBasicActions.list({
  constants: {
    failed: NURSERIES_SIGNUP_REPORT_FAILED,
    init: NURSERIES_SIGNUP_REPORT,
    success: NURSERIES_SIGNUP_REPORT_SUCCESS,
  },
  options,
  service: childrenSignedUpReportApiClient,
  serviceMethod: 'getNurseriesSignup',
})

export const clearNurseries = () => ({
  type: CLEAR_NURSERIES_SIGNUP_REPORT,
})
