import _ from 'lodash'

import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import { UPDATE_NURSERY_FRAMEWORK_RELATION_SUCCESS } from '../single/constants'

import {
  ADD_FRAMEWORK_TO_LIST,
  CLEAR_NURSERY_FRAMEWORK_RELATIONS,
  LIST_NURSERY_FRAMEWORK_RELATIONS,
  LIST_NURSERY_FRAMEWORK_RELATIONS_FAILED,
  LIST_NURSERY_FRAMEWORK_RELATIONS_SUCCESS,
  REMOVE_FRAMEWORK_FROM_LIST,
} from './constants'

const initialState = getBasicListInitialState

export default (...props) => {
  const [state = initialState, { payload, type }] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_NURSERY_FRAMEWORK_RELATIONS,
    failed: LIST_NURSERY_FRAMEWORK_RELATIONS_FAILED,
    init: LIST_NURSERY_FRAMEWORK_RELATIONS,
    success: LIST_NURSERY_FRAMEWORK_RELATIONS_SUCCESS,
  }, initialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  if (type === UPDATE_NURSERY_FRAMEWORK_RELATION_SUCCESS) {
    return {
      ...state,
      data: _.map(state.data, (item) => {
        const { id } = item

        if (id === payload.data.id) {
          return payload.data
        }

        return item
      }),
    }
  }

  if (type === ADD_FRAMEWORK_TO_LIST) {
    const newData = [...(state?.data || [])]
    const newItem = payload?.data
    const indexOfItem = _.findIndex(state?.data, ({ id }) => newItem.id === id)

    if (-1 < indexOfItem) {
      newData[indexOfItem] = newItem

      return {
        ...state,
        data: newData,
      }
    }

    return {
      ...state,
      data: _.uniqBy([
        ...(state?.data || []),
        payload?.data,
      ], ({ id }) => id),
    }
  }

  if (type === REMOVE_FRAMEWORK_FROM_LIST) {
    return {
      ...state,
      data: _.filter(state.data, ({ id }) => id !== payload.id),
    }
  }

  return state
}
