import React from 'react'

import layoutConstants from 'constants/layout'

import { getBrandingColor } from 'utils/branding'
import { useWindowSize } from 'utils/hooks'

import { Drawer } from 'components'

import ChannelList from 'module/Messaging/components/ChannelList'

import { StyledChannelListMobile, StyledChannelListMobileWrapper } from './MobileDrawerStyled'

const MobileDrawer = ({
  channelId,
  isNewChannelPage,
  onIsEmptyState,
}) => {
  const { width } = useWindowSize()
  if (layoutConstants.mobileWidthResolution < width) {
    return null
  }

  return (
    <StyledChannelListMobileWrapper>
      <Drawer
        backgroundHeader={getBrandingColor('tertiary-color')}
        content={(isOpen, close) => (
          <StyledChannelListMobile>
            <ChannelList
              activeChannelId={channelId}
              isNewChannelPage={isNewChannelPage}
              expanded
              onIsEmptyState={onIsEmptyState}
              onRedirect={() => close(false)}
            />
          </StyledChannelListMobile>
        )}
        position="left"
        widthOpen={340}
        closeContent
        disableAutoOpen
        withoutBackdrop
      />
    </StyledChannelListMobileWrapper>
  )
}

export default React.memo(MobileDrawer)
