import { combineReducers } from 'redux'

import single, { SubdomainSingleState } from './single/reducer'

export default combineReducers({
  single,
})

export interface SubdomainState {
  single: SubdomainSingleState
}
