import _ from 'lodash'

import React from 'react'

import { OTHER_OPTION } from 'services/users/constants'
import { SUPPORTED_FILE_TYPES } from 'constants/mimetypes'
import {
  HA_SIGNATURE_LABEL_MAPPINGS,
  HA_SIGNATURE_TYPES,
  SIGNATURE_LABEL_MAPPINGS,
  SIGNATURE_TYPES,
} from 'services/legacy/injuries/constants'

import {
  Button,
  FieldError,
  Form,
  InfiniteDropdowns,
  MediaPicker,
  Spinner,
  Switch,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from 'components'

import i18n from 'translations'

import SignatureList from '../SignatureList'
import { StyledContentContainer, StyledContentErrorContainer, StyledContentWrapper } from './SignatureOnPaperFormStyled'
import {
  StyledActionButtonContainer,
  StyledParentSignatureContainer,
} from '../SignatureDigitalForm/SignatureDigitalFormStyled'

const SignatureOnPaperForm = ({
  disableSignature,
  injuryPreview,
  injurySignaturesPreview,
  isHomeAccidents,
  isSubmitting,
  onAddCarerClick,
  onAddStaffMemberClick,
  onChangeCarer,
  onChangeOtherCarer,
  onChangeStaffMember,
  onDocumentChange,
  onOnPaperMarkAllAsSignedClick,
  onSignatureToggleChange,
}) => {
  const { injurySignatureStatistics, signatureTotal } = injuryPreview
  const isParentSinged = 0 === injurySignatureStatistics.parent.signed
  const { signatureDocuments, signatures } = injurySignaturesPreview || {}
  const { all, signed } = signatureTotal

  const isAllSigned = all === signed

  const renderHomeAccidentLabel = (item) => {
    const { additionalData, type } = item
    const { name } = additionalData || {}

    if (type === SIGNATURE_TYPES.PARENT) {
      return name
    }

    return null
  }

  const renderHomeAccidentValue = (item, filteredStaffMember, carerIdsArr, ownerIdsArr) => {
    const { additionalData, carer, disabled, error, owner, type } = item
    const { child } = owner
    const { name } = additionalData || {}

    if (type === SIGNATURE_TYPES.PARENT) {
      return (
        <React.Fragment>
          <InfiniteDropdowns.Carers
            childId={child.id}
            disabled={disabled}
            emptySearchNoOptionsMessage={i18n.t(
              'module:Injury:Preview:Signatures:Digital:contactEmptySearchNoOptionsMessage',
            )}
            extraOptions={[OTHER_OPTION]}
            placeholder={i18n.t('module:Injury:Preview:Signatures:Digital:contactPlaceholder')}
            skipIds={carerIdsArr}
            value={carer}
            parentalResponsibilityOnly
            onChange={(option) => onChangeCarer(item, option)}
          />
          <StyledContentErrorContainer>
            <FieldError error={error?.extra?.injury} />
          </StyledContentErrorContainer>
          {carer?.value === OTHER_OPTION.value && (
            <Form.Row margin="5px 0 5px" width={{ field: '100%' }}>
              <TextField
                disabled={disabled}
                placeholder={` ${i18n.t('global:Other')} ${name}`}
                value={item?.otherCarer}
                onChange={(event) => onChangeOtherCarer(item, event.target.value)}
              />
            </Form.Row>
          )}
        </React.Fragment>
      )
    }

    return (
      <InfiniteDropdowns.Users
        disabled={isParentSinged}
        emptySearchNoOptionsMessage={i18n.t(
          'module:Injury:Preview:Signatures:Digital:contactEmptySearchNoOptionsMessage',
        )}
        placeholder={i18n.t('module:Safeguarding:Preview:Signatures:Digital:staffMemberPlaceholder')}
        skipIds={ownerIdsArr}
        value={owner.id !== undefined && owner}
        onChange={(option) => onChangeStaffMember(filteredStaffMember, item, option)}
      />
    )
  }

  const renderAddMoreButtonForParent = (item) => {
    const { owner, type } = item[0]
    const { child } = owner

    if (type === SIGNATURE_TYPES.PARENT) {
      return (
        <StyledActionButtonContainer>
          <Button.ActionButton
            disabled={disableSignature}
            label={i18n.t('module:Injury:Preview:Signatures:Digital:parentAddContactLabel', {
              name: child?.firstName,
            })}
            onClick={() => onAddCarerClick(child)}
          />
        </StyledActionButtonContainer>
      )
    }

    return null
  }

  const renderAddMoreButtonForStaff = (item) => (
    <StyledActionButtonContainer>
      <Button.ActionButton
        disabled={disableSignature}
        label={i18n.t('module:Safeguarding:Add:addStaffMember')}
        onClick={() => onAddStaffMemberClick(item)}
      />
    </StyledActionButtonContainer>
  )

  const renderSignature = (item, filteredStaffMember, carerIdsArr, ownerIdsArr) => {
    const {
      additionalData,
      carer,
      disabled,
      error,
      id,
      isSubmitting: isItemSubmitting,
      owner,
      signedOnPaper,
      type,
      uid,
    } = item

    const { label, name } = additionalData || {}
    const isOtherCarer = type === SIGNATURE_TYPES.PARENT && carer?.value === OTHER_OPTION.value && carer?.otherCarer
    const isSelected = type === SIGNATURE_TYPES.PARENT ? !carer : !owner?.id
    const switchLabel = signedOnPaper
      ? i18n.t('module:Injury:Preview:Signatures:OnPaper:signedLabel')
      : i18n.t('module:Injury:Preview:Signatures:OnPaper:notSignedLabel')

    return (
      <SignatureList.Row
        content={(
          <StyledContentWrapper>
            <StyledContentContainer>
              <Typography padding="0 10px 0 0" variant="span">
                {isItemSubmitting
                  ? <Spinner size="small" />
                  : switchLabel}
              </Typography>
              <Tooltip title={disabled ? i18n.t('module:Injury:Preview:Signatures:approvalTooltip') : null}>
                {isHomeAccidents ? (
                  <Switch
                    disabled={disabled || disableSignature || (
                      carer?.value === OTHER_OPTION.value ? !isOtherCarer : isSelected)
                      || (type === HA_SIGNATURE_TYPES.STAFF_MEMBER && isParentSinged) || error}
                    value={signedOnPaper}
                    onChange={(toggleValue) => onSignatureToggleChange(toggleValue, item)}
                  />
                ) : (
                  <Switch
                    disabled={disabled || disableSignature}
                    value={signedOnPaper}
                    onChange={(toggleValue) => onSignatureToggleChange(toggleValue, item)}
                  />
                )}
              </Tooltip>
            </StyledContentContainer>
            <StyledContentErrorContainer>
              <FieldError error={error?.extra?.signature ? error?.extra?.signature : error?.message} />
            </StyledContentErrorContainer>
          </StyledContentWrapper>
        )}
        key={id || uid}
        label={isHomeAccidents ? renderHomeAccidentLabel(item) : label}
        value={isHomeAccidents ? renderHomeAccidentValue(item, filteredStaffMember, carerIdsArr, ownerIdsArr) : name}
      />
    )
  }

  return (
    <React.Fragment>
      <Toolbar>
        <Toolbar.Group />
        <Toolbar.Group>
          {isSubmitting && (
            <Toolbar.Item>
              <Spinner size="small" />
            </Toolbar.Item>
          )}
          {!isHomeAccidents && (
            <Toolbar.Item>
              <Button
                disabled={isSubmitting}
                hierarchy="tertiary"
                icon="bulk-action"
                label={isAllSigned
                  ? i18n.t('module:Injury:Preview:Signatures:OnPaper:markAllAsNotSigned')
                  : i18n.t('module:Injury:Preview:Signatures:OnPaper:markAllAsSigned')}
                size="small"
                negativeMargins
                onClick={() => onOnPaperMarkAllAsSignedClick(!isAllSigned)}
              />
            </Toolbar.Item>
          )}
        </Toolbar.Group>
      </Toolbar>
      <SignatureList>
        {_.map(isHomeAccidents ? HA_SIGNATURE_LABEL_MAPPINGS : SIGNATURE_LABEL_MAPPINGS, (value, key) => {
          const filteredSignatures = _.filter(signatures, ({ type }) => type === key)

          if (!filteredSignatures?.length) {
            return null
          }

          const filteredStaffMember = _.filter(signatures, ({ type }) => type === HA_SIGNATURE_TYPES.STAFF_MEMBER)
          const filteredParent = _.filter(signatures, ({ type }) => type === HA_SIGNATURE_TYPES.PARENT)

          const carerIdsArr = []
          _.filter(filteredParent, ({ carer }) => {
            if (carer?.id) {
              carerIdsArr.push(carer?.id)
            }
          })

          const ownerIdsArr = []
          _.filter(filteredStaffMember, ({ owner }) => {
            if (owner?.id) {
              ownerIdsArr.push(owner?.id)
            }
          })

          return (
            <React.Fragment>
              {!isHomeAccidents && SIGNATURE_TYPES.PARENT === key && <SignatureList.RowDivider />}
              {isHomeAccidents && HA_SIGNATURE_TYPES.STAFF_MEMBER === key && <SignatureList.RowDivider />}
              <StyledParentSignatureContainer isDisplay={!isHomeAccidents && SIGNATURE_TYPES.STAFF_MEMBER === key}>
                <SignatureList.Group title={value}>
                  {_.map(filteredSignatures, (item) => renderSignature(
                    item,
                    filteredStaffMember,
                    carerIdsArr,
                    ownerIdsArr))}
                </SignatureList.Group>
                {isHomeAccidents && renderAddMoreButtonForParent(filteredSignatures)}
              </StyledParentSignatureContainer>
            </React.Fragment>
          )
        })}
        {isHomeAccidents && renderAddMoreButtonForStaff({ owner: { } })}
        <SignatureList.RowDivider />
      </SignatureList>
      <Typography fontSize={18} padding="20px 0" bold>
        {i18n.t('module:Injury:Preview:Signatures:OnPaper:uploadDocumentTitle')}
      </Typography>
      <MediaPicker
        accept={[SUPPORTED_FILE_TYPES.IMAGES, SUPPORTED_FILE_TYPES.PDF]}
        label={(
          <MediaPicker.Label
            label={i18n.t('module:Injury:Preview:Signatures:OnPaper:uploadImageButtonLabel')}
            subLabel1={i18n.t('module:Injury:Preview:Signatures:OnPaper:uploadImageButtonSubLabel1')}
            subLabel2={i18n.t('module:Injury:Preview:Signatures:OnPaper:uploadImageButtonSubLabel2')}
          />
        )}
        value={signatureDocuments}
        disableMediaTag
        disableTagging
        editMode
        sync
        tiledMode
        onChange={onDocumentChange}
      />
    </React.Fragment>
  )
}

export default SignatureOnPaperForm
