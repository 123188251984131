import React, { useEffect, useMemo } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'
import { ModalType } from 'modals'

import { withAppService, withAppServiceProps } from 'services/app'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withNurseryIntegrationsService, withNurseryIntegrationsServiceProps } from 'services/nurseryIntegrations'
import { withRouteUtilsProps, withRouterUtils } from 'services/utils/router'
import { withRouter, withRouterProps } from 'services/router'

import i18n from 'translations'

import { getTableData } from './DetailHelpers'
import DetailView from './DetailView'

const PAYOUT_TRANSACTIONS_GROUPS = {
  read: [
    'payoutTransaction.child',
    'child',
    'payoutTransaction.reference',
    'payment',
  ],
}

type DetailContainerProps = withAppServiceProps
  & withNurseryIntegrationsServiceProps
  & withRouteUtilsProps
  & withRouterProps
  & withModalServiceProps

const mapState = (state: RootState, {
  appSelectors,
  nurseryIntegrationsPayoutTransactionsState,
  nurseryIntegrationsSelectors,
  nurseryIntegrationsSingleState,
}: DetailContainerProps) => ({
  errorMessages: appSelectors.getErrorMessages(nurseryIntegrationsSingleState),
  isFetching: appSelectors.getIsFetching(nurseryIntegrationsSingleState),
  isTransactionsFetching: appSelectors.getIsFetching(nurseryIntegrationsPayoutTransactionsState),
  payoutData: nurseryIntegrationsSelectors.getPayout(state),
  payoutTransactionsData: nurseryIntegrationsSelectors.getPayoutTransactions(state),
  payoutTransactionsHasMore:
    nurseryIntegrationsSelectors.getPayoutTransactionsHasMore(state),
  payoutTransactionsNextPageId:
    nurseryIntegrationsSelectors.getPayoutTransactionsNextPageId(state),
  payoutTransactionsTotalResults: appSelectors.getTotalResults(nurseryIntegrationsPayoutTransactionsState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const DetailContainer: React.FC<DetailContainerProps & PropsFromRedux> = ({
  errorMessages,
  isFetching,
  isTransactionsFetching,
  modalActions,
  nurseryIntegrationsActions,
  params,
  payoutData,
  payoutTransactionsData,
  payoutTransactionsHasMore,
  payoutTransactionsNextPageId,
  payoutTransactionsTotalResults,
}) => {
  const tableData = useMemo(
    () => getTableData({ data: payoutTransactionsData, hasError: !!errorMessages }),
    [payoutTransactionsData, errorMessages],
  )

  const fetchTransactionData = () => {
    nurseryIntegrationsActions.getPayoutTransactions({
      mergeResult: true,
      params: [params.payoutId, {
        groups: PAYOUT_TRANSACTIONS_GROUPS,
        nextPage: payoutTransactionsNextPageId,
      }],
    })
  }

  useEffect(() => {
    nurseryIntegrationsActions.getPayout({
      onSuccess: fetchTransactionData,
      params: [params.payoutId],
    })

    return () => {
      nurseryIntegrationsActions.clearPayoutTransactions()
    }
  }, [])

  const handleExportModalClick = (body, onSuccess, onFailed) => {
    nurseryIntegrationsActions.exportPayoutTransactions({
      onFailed,
      onSuccess,
      params: [params.payoutId, { ...body, criteria: body.criteria }],
    })
  }

  const handleExportClick = () => {
    modalActions.show<ModalType.EXPORT_REPORT>(ModalType.EXPORT_REPORT, {
      onSendReport: handleExportModalClick,
      title: i18n.t('module:Finance:Payouts:Detail:Export:title'),
      userLabel: i18n.t('module:Modals:ExportReportModal:User:Label:export'),
    })
  }

  return (
    <DetailView
      errorMessages={errorMessages}
      isFetching={isFetching}
      isTransactionsFetching={isTransactionsFetching}
      payoutData={payoutData}
      payoutTransactionsData={payoutTransactionsData}
      payoutTransactionsHasMore={payoutTransactionsHasMore}
      payoutTransactionsTotalResults={payoutTransactionsTotalResults}
      tableData={tableData}
      onExportClick={handleExportClick}
      onPageChange={fetchTransactionData}
    />
  )
}

const enhance = compose(
  withRouter,
  withRouterUtils,
  withAppService,
  withModalService,
  withNurseryIntegrationsService,
  connector,
)

export default enhance(DetailContainer)
