import styled from 'styled-components'

import colors from 'constants/colors'
import constants from 'services/legacy/invoices/constants'

import { getBrandingColor } from 'utils/branding'

export const StyledTotalContainer = styled.div`
  display: flex;
  color: ${({ hasError }) => (hasError ? colors.remove : getBrandingColor('tertiary-color'))};
  font-weight: 600;
`

export const StyledDateRangeContainer = styled.div`
  color: ${colors.gray};
  margin-top: 4px;
  font-size: 14px;
`

export const StyledInvoiceTypeContainer = styled.span`
  color: ${colors.gray};
  font-size: 14px;
`

export const StyledInvoiceSentContainer = styled.div`
  color: ${getBrandingColor('primary-color')};
  font-weight: 600;
`

export const StyledInvoiceSentSubTextContainer = styled.div`
  display: flex;
  color: ${getBrandingColor('primary-color')};
  font-weight: 400;
  font-size: 14px;
  margin-top: 2px;
  align-items: center;

  & > div {
    margin-right: 5px;
  }
`

export const StyledAmountContainer = styled.div`
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
  }
`

export const StyledInvoiceSendingSubTextContainer = styled.div`
  color: ${colors.gray};
  margin-top: 5px;
  font-weight: 400; 

  & > a {
    color: ${getBrandingColor('primary-color')};
    text-decoration: underline;
    font-weight: 600;
  }
`

export const StyledStatus = styled.div`
  margin-top: ${({ marginTop = 10 }) => marginTop}px;
  font-size: ${({ fontSize = 20 }) => fontSize}px;
  font-weight: 600;
  line-height: 1;
  color: ${getBrandingColor('primary-color')};

  ${({ status }) => status && status === constants.STATUS_TYPES.CANCELED && `
    color: ${colors.gray};
  `}
`
