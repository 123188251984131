import _ from 'lodash'

import React from 'react'

import { ChildProductTypes } from 'services/booking/childProducts/constants'

import { Button, Currency, DropdownMenu, Hr, Space, Table, Typography } from 'components'

import i18n from 'translations'

import { RegularBookingsAddFormValues } from '../RegularBookingsAddForm'

const COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'fundingType',
    title: i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:fundingType'),
    width: '260px',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'localAuthorityHourlyRate',
    title: i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:localAuthorityHourlyRate'),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'hoursPerWeek',
    title: i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:hoursPerWeek'),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'maxHoursPerDay',
    title: i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:maxHoursPerDay'),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'claimableFromLocalAuthority',
    title: i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:claimableFromLocalAuthority'),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'fundingDeductedFromInvoice',
    title: i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:fundingDeductedFromInvoice'),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'actions',
  },
]

interface FundingProps {
  formValues: RegularBookingsAddFormValues
  isEdit: boolean
  isSubmitting: boolean
  onAddFunding: (index: number) => void
  onEditFunding: (id: string) => void
  onRemoveChildProduct: (id: string) => void
}

const Funding: React.FC<FundingProps> = ({
  formValues,
  isEdit,
  isSubmitting,
  onAddFunding,
  onEditFunding,
  onRemoveChildProduct,
}) => {
  const { alternates, childProducts } = formValues || {}

  const renderItem = (item) => {
    const { hoursPerWeekAllocated, id, product, settings, type } = item
    const hoursPerWeekSummary = _.sumBy(settings.defaultAllocations, ({ times }) => (
      _.sumBy(times, ({ endTime, startTime }) => endTime - startTime)
    ))

    return ({
      actions: (
        <DropdownMenu
          button={(
            <Button
              hierarchy="tertiary"
              icon="dots"
              size="small"
              negativeMargins
            />
          )}
          disabled={isSubmitting}
        >
          <DropdownMenu.Item type="edit" onClick={() => !isSubmitting && onEditFunding(id)} />
          <DropdownMenu.Item type="delete" onClick={() => onRemoveChildProduct(id)} />
        </DropdownMenu>
      ),
      claimableFromLocalAuthority: '',
      fundingDeductedFromInvoice: '',
      fundingType: product?.name,
      hoursPerWeek: i18n.t('global:Hours', {
        // eslint-disable-next-line no-nested-ternary
        value: isEdit && hoursPerWeekAllocated
          ? hoursPerWeekAllocated
          : (
            type === ChildProductTypes.REGULAR_ALLOCATED_FUNDING
              ? Math.round((hoursPerWeekSummary / 1000 / 60 / 60) * 100) / 100
              : (settings?.hoursPerWeek || 0)
          ),
      }),
      localAuthorityHourlyRate: (
        <Currency value={settings?.hourlyRate || 0} />
      ),
      maxHoursPerDay: i18n.t('global:Hours', { value: settings?.hoursPerDay || 0 }),
    })
  }

  const renderFundingWeek = (alternate) => {
    const fundings = _.filter(childProducts, (item) => (
      item.alternate === alternate + 1
      && (
        item.type === ChildProductTypes.REGULAR_ALLOCATED_FUNDING
      )
    ))

    return (
      <div key={alternate}>
        <Typography fontSize={18} bold>
          {1 < alternates
            // eslint-disable-next-line max-len
            ? `${i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:fundingForAlternatingWeek')} ${alternate + 1}`
            : i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:funding')}
        </Typography>
        <Space space="20px" />
        {fundings.length ? (
          <Table
            columns={COLUMNS}
            data={_.map(fundings, renderItem)}
            minWidth={940}
            visualType="transparent"
          />
        ) : null}
        <Space space="20px" />
        <Button
          disabled={isSubmitting}
          hierarchy="secondary"
          icon="plus"
          label={i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:addFundingType')}
          size="small"
          onClick={() => !isSubmitting && onAddFunding(alternate + 1)}
        />
        {alternates - 1 !== alternate && <Hr margin="20px 0" />}
      </div>
    )
  }

  return (
    <div>
      {_.times(alternates, renderFundingWeek)}
    </div>
  )
}

export default Funding
