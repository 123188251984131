export const AUTO_CALCULATE_BULK_INVOICE = 'AUTO_CALCULATE_BULK_INVOICE'
export const AUTO_CALCULATE_BULK_INVOICE_SUCCESS = 'AUTO_CALCULATE_BULK_INVOICE_SUCCESS'
export const AUTO_CALCULATE_BULK_INVOICE_FAILED = 'AUTO_CALCULATE_BULK_INVOICE_FAILED'

export const BULK_INVOICE_LIST_CHILDREN = 'BULK_INVOICE_LIST_CHILDREN'
export const BULK_INVOICE_LIST_CHILDREN_SUCCESS = 'BULK_INVOICE_LIST_CHILDREN_SUCCESS'
export const BULK_INVOICE_LIST_CHILDREN_FAILED = 'BULK_INVOICE_LIST_CHILDREN_FAILED'

export const BULK_PREVIOUS_INVOICE_LIST = 'BULK_PREVIOUS_INVOICE_LIST'
export const BULK_PREVIOUS_INVOICE_LIST_SUCCESS = 'BULK_PREVIOUS_INVOICE_LIST_SUCCESS'
export const BULK_PREVIOUS_INVOICE_LIST_FAILED = 'BULK_PREVIOUS_INVOICE_LIST_FAILED'

export const CREATE_BULK_INVOICES = 'CREATE_BULK_INVOICES'
export const CREATE_BULK_INVOICES_SUCCESS = 'CREATE_BULK_INVOICES_SUCCESS'
export const CREATE_BULK_INVOICES_FAILED = 'CREATE_BULK_INVOICES_FAILED'

export const UPDATE_BULK_INVOICES_LIST = 'UPDATE_BULK_INVOICES_LIST'

export const BULK_INVOICE_CLEAR_LIST = 'BULK_INVOICE_CLEAR_LIST'
