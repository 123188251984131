import moment from 'moment'
import i18n from 'translations'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change, getFormInitialValues, isDirty as isDirtyForm, stopSubmit } from 'redux-form'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { FUNDING_FEES_TYPES } from 'services/nurseryFunding/constants'

import { toFloat } from 'utils/data'
import { floatToHoursAndMinutes, hoursAndMinutesToFloat } from 'utils/date'
import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withChildService } from 'services/legacy/child'
import { withNurseryFundingService } from 'services/nurseryFunding'
import { withChildFundingService } from 'services/legacy/childFunding'
import { withRouter } from 'services/router'

import ChildFundingAddView from './ChildFundingAddView'
import { FORM_NAME } from './components/ChildFundingAddForm'

const CHILD_FUNDING_GROUPS = {
  read: [
    'childFunding.child',
    'childFunding.funding',
    'childFunding.settings',
    'childFundingSettings',
    'nurseryFunding',
    'nurseryFunding.settings',
    'nurseryFundingSettings',
    'nurseryFunding.nursery',
    'nurseryFunding.fundingType',
    'fundingType',
  ],
}

const NURSERY_FUNDING_GROUPS = {
  read: [
    'nurseryFunding.settings',
    'nurseryFundingSettings',
    'nurseryFunding.nursery',
    'nurseryFunding.fundingType',
    'fundingType',
  ],
}

class ChildFundingAddContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isInfoBlockVisible: false,
    }
  }

  componentDidMount() {
    const {
      childActions,
      childFundingActions,
      isEdit,
      isInsideModal,
      modalChildProps = {},
      nurseryFundingActions,
      params,
    } = this.props
    const { childFundingId = modalChildProps.childFundingId } = params

    nurseryFundingActions.list({
      params: {
        criteria: [{
          field: 'archived',
          value: false,
        }],
        groups: NURSERY_FUNDING_GROUPS,
      },
      recursively: true,
    })

    if (isEdit) {
      const apiParams = {
        groups: CHILD_FUNDING_GROUPS,
      }

      childFundingActions.get(childFundingId, apiParams)
    }

    if (isInsideModal) {
      const { childId } = modalChildProps

      childActions.get({ params: [childId] })
    }
  }

  componentWillUnmount() {
    const { childFundingActions, nurseryFundingActions } = this.props

    childFundingActions.clearSingle()
    nurseryFundingActions.clear()
  }

  componentDidUpdate(prevProps) {
    const { isDirty, hourlyRate, totalHours, fundingType = {} } = this.props
    const { fundingType: prevFundingType = {}, totalHours: prevHours } = prevProps
    const { hours: prevTotalHours, minutes: prevTotalMinutes } = prevHours || {}

    // NOTE: isDirty is to update total only when user change cost or quantity
    // should not recalculate total on edit mode initialization
    if (
      isDirty
      && (hourlyRate && totalHours)
      && (
        hourlyRate !== prevProps.hourlyRate
        || totalHours.hours !== prevTotalHours
        || totalHours.minutes !== prevTotalMinutes
      )
    ) {
      this.updateTotal(hourlyRate, totalHours)
    }

    // NOTE: To prefill fields in form when user select funding type
    if (isDirty && fundingType && (fundingType.value !== prevFundingType.value)) {
      this.updateForm(fundingType)
    }
  }

  updateForm = (fundingType) => {
    const { updateFormField } = this.props
    const { endDate, hourlyRate, hoursPerWeek, maxHoursPerDay, startDate, total, totalHours } = fundingType
    const totalHoursAndMinutes = floatToHoursAndMinutes(totalHours)

    updateFormField('period', [startDate, endDate])
    updateFormField('hourlyRate', hourlyRate)
    updateFormField('settings.hoursPerWeek', hoursPerWeek)
    updateFormField('settings.maxHoursPerDay', maxHoursPerDay)
    updateFormField('totalHours', totalHoursAndMinutes)
    updateFormField('total', total)
  }

  updateTotal = (hourlyRate, totalHours) => {
    const { updateFormField } = this.props
    const { hours, minutes = 0 } = totalHours

    const parsedHourlyRate = toFloat(hourlyRate) || 0
    const floatHours = hoursAndMinutesToFloat(hours, minutes)
    const total = toFloat(parsedHourlyRate * floatHours)

    updateFormField('total', total)
  }

  handleFail = (error) => {
    const { appSelectors, childFundingHelpers, stopSubmitForm } = this.props

    const fieldValidations = childFundingHelpers.updateChildFundingDateFieldError(
      appSelectors.getFieldValidationFromError(error),
    )

    stopSubmitForm(fieldValidations)
  }

  redirect = () => {
    const { isEdit, navigate, params } = this.props
    const { childFundingId, childId } = params

    navigate(
      isEdit
        ? generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.FUNDING.VIEW', { childFundingId, childId })
        : generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.FUNDING', { childId }),
    )
  }

  handleSuccess = (response) => {
    const { onModalCreateSuccess } = this.props

    if (onModalCreateSuccess) {
      return onModalCreateSuccess(response)
    }

    return this.redirect()
  }

  handleSubmit = (fields) => {
    const {
      isEdit,
      childFundingActions,
      childFundingHelpers,
      formInitialValues,
      modalActions,
      modalChildProps = {},
      modalConsts,
      params,
    } = this.props
    const { childId = modalChildProps.childId, childFundingId = modalChildProps.childFundingId } = params

    const payload = childFundingHelpers.getPayload(fields, childId)

    const apiParams = {
      groups: CHILD_FUNDING_GROUPS,
    }

    if (isEdit) {
      const { period = [], type } = fields
      const { period: initialPeriod = [] } = formInitialValues

      const [startDate, endDate] = period
      const [initialStartDate, initialEndDate] = initialPeriod

      if (type === FUNDING_FEES_TYPES.MANUAL_HOURS && (
        moment(initialStartDate).format(DEFAULT_DATE_FORMAT) !== moment(startDate).format(DEFAULT_DATE_FORMAT)
        || moment(initialEndDate).format(DEFAULT_DATE_FORMAT) !== moment(endDate).format(DEFAULT_DATE_FORMAT)
      )) {
        modalActions.show(modalConsts.TYPES.CONFIRM, {
          confirmButtonLabel: i18n.t('global:Update'),
          icon: 'warning',
          onConfirm: () => childFundingActions.update(
            childFundingId,
            payload,
            apiParams,
            this.handleSuccess,
            this.handleFail,
          ),
          text: i18n.t('module:Children:Child:BookingPattern:Funding:Add:fundingPopupCopy'),
        })

        return
      }

      childFundingActions.update(
        childFundingId,
        payload,
        apiParams,
        this.handleSuccess,
        this.handleFail,
      )
      return
    }

    childFundingActions.create(payload, apiParams, this.handleSuccess, this.handleFail)
  }

  handleCancelClick = () => {
    const { onModalCancelClick } = this.props

    if (onModalCancelClick) {
      return onModalCancelClick()
    }

    return this.redirect()
  }

  archiveFunding = (archived) => () => {
    const { childFundingActions, childFundingSingleState, params } = this.props
    const { childFundingId } = params
    const { data } = childFundingSingleState
    const { type } = data

    const apiParams = { groups: CHILD_FUNDING_GROUPS }
    const payload = { archived, type }

    return childFundingActions.update(childFundingId, payload, apiParams, this.handleSuccess)
  }

  handleArchiveClick = (archived) => () => {
    const { modalActions, modalConsts } = this.props

    const label = archived ? i18n.t('global:archive') : i18n.t('global:unarchive')

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: label,
      icon: label,
      onConfirm: this.archiveFunding(archived),
      text: archived
        ? i18n.t('module:Children:Child:BookingPattern:Funding:Add:archivePopupCopy')
        : i18n.t('module:Children:Child:BookingPattern:Funding:Add:unarchivePopupCopy'),
    })
  }

  handlePeriodChange = () => {
    const { updateFormField } = this.props

    this.setState({ isInfoBlockVisible: true })
    updateFormField('totalHours', null)
    updateFormField('total', null)
  }

  render() {
    const {
      allowOverrideHoursPerWeek,
      allowOverrideMaxHoursPerDay,
      child,
      childFundingAllocationData,
      childFundingSingleState,
      errorMessages,
      excludedPeriods,
      fundingTypesOptions,
      initialValues,
      isArchived,
      isEdit,
      isFetching,
      isInsideModal,
    } = this.props
    const { isInfoBlockVisible } = this.state

    const isLoading = isFetching
    const isFormLoading = childFundingSingleState.isSubmitting

    return (
      <ChildFundingAddView
        allocationData={childFundingAllocationData}
        allowOverrideHoursPerWeek={allowOverrideHoursPerWeek}
        allowOverrideMaxHoursPerDay={allowOverrideMaxHoursPerDay}
        child={child}
        errorMessages={errorMessages}
        excludedPeriods={excludedPeriods}
        fundingTypeOptions={fundingTypesOptions}
        initialValues={initialValues}
        isArchived={isArchived}
        isEdit={isEdit}
        isFormLoading={isFormLoading}
        isInfoBlockVisible={isInfoBlockVisible}
        isInsideModal={isInsideModal}
        isLoading={isLoading}
        onArchiveClick={this.handleArchiveClick}
        onCancelClick={this.handleCancelClick}
        onPeriodChange={this.handlePeriodChange}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseryFundingListState,
  modalChildProps = {},
  childFundingSelectors,
  childFundingSingleState,
  childSelectors,
  params,
}) => ({
  allowOverrideHoursPerWeek: childFundingSelectors.getAllowOverrideHoursPerWeek(FORM_NAME)(state),
  allowOverrideMaxHoursPerDay: childFundingSelectors.getAllowOverrideMaxHoursPerDay(FORM_NAME)(state),
  child: childSelectors.getChildSelector(state),
  childFundingAllocationData: childFundingSelectors.getItemAllocationDataSelectors(state),
  errorMessages: appSelectors.getErrorMessages(
    nurseryFundingListState,
    childFundingSingleState,
  ),
  excludedPeriods: childFundingSelectors.getNurseryExcludedPeriods(FORM_NAME)(state),
  formInitialValues: getFormInitialValues(FORM_NAME)(state),
  fundingType: childFundingSelectors.getFundingType(FORM_NAME)(state),
  fundingTypesOptions: childFundingSelectors.getNurseryFundingOptionsWithCurrentType(!!params.childFundingId)(state),
  hourlyRate: childFundingSelectors.getHourlyRate(FORM_NAME)(state),
  hoursPerWeek: childFundingSelectors.getHoursPerWeek(FORM_NAME)(state),
  initialValues: childFundingSelectors.getInitialValuesSelector(!!params.childFundingId)(state),
  isArchived: childFundingSelectors.isArchivedSelector(state),
  isDirty: isDirtyForm(FORM_NAME)(state),
  isEdit: !!(params.childFundingId || modalChildProps.childFundingId),
  isFetching: appSelectors.getIsFetching(nurseryFundingListState, childFundingSingleState),
  maxHoursPerDay: childFundingSelectors.getMaxHoursPerDay(FORM_NAME)(state),
  totalHours: childFundingSelectors.getTotalHours(FORM_NAME)(state),
})

const mapDispatch = {
  stopSubmitForm: (params) => stopSubmit(FORM_NAME, params),
  updateFormField: (fieldName, value) => change(FORM_NAME, fieldName, value),
}

const enhance = compose(
  withRouter,
  withAppService,
  withChildService,
  withModalService,
  withNurseryFundingService,
  withChildFundingService,
  connect(mapState, mapDispatch),
)

export default enhance(ChildFundingAddContainer)
