import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import {
  CLEAR_PERIOD_TIMES_LIST,
  GET_PERIOD_TIMES_LIST,
  GET_PERIOD_TIMES_LIST_FAILED,
  GET_PERIOD_TIMES_LIST_SUCCESS,
} from './constants'

const initialState = getBasicListInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_PERIOD_TIMES_LIST,
    failed: GET_PERIOD_TIMES_LIST_FAILED,
    init: GET_PERIOD_TIMES_LIST,
    success: GET_PERIOD_TIMES_LIST_SUCCESS,
  }, initialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
