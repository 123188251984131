import _ from 'lodash'

import React from 'react'

import { generateRoute } from 'utils/routing'

import { DatePicker, EmptyState, Page, Section, Spinner, Toolbar } from 'components'

import i18n from 'translations'

import DiaryItem from './components/DiaryItem'

const DiaryTimelineView = ({
  childDetails,
  date,
  hasAccessToLikesAndComments,
  hasAccessToLikesAndCommentsSettings,
  isFetching,
  onDateChange,
  parentContext,
  records,
}) => {
  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    if (!isFetching && (!records || !records.length)) {
      return (
        <EmptyState
          icon="daily-diary"
          text1={i18n.t('module:Children:Child:DailyDiary:notFound')}
        />
      )
    }

    return _.map(records, (item) => (
      <DiaryItem
        hasAccessToLikesAndComments={hasAccessToLikesAndComments}
        hasAccessToLikesAndCommentsSettings={hasAccessToLikesAndCommentsSettings}
        item={item}
        key={item.id}
      />
    ))
  }

  const actions = !parentContext && (
    <Section.Actions
      primary={[{
        label: i18n.t('module:Children:Child:DailyDiary:diaryEntry'),
        to: generateRoute('DAILY_DIARY.MEALS'),
      }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isFetching}
      title={
        parentContext
          ? i18n.t('module:Children:Child:DailyDiary:myDailyDiaryTitle', {
            child: childDetails?.name || records?.[0]?.record?.child?.name,
          })
          : i18n.t('module:Children:Child:DailyDiary:title')
      }
    >
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <DatePicker
              value={date}
              onChange={onDateChange}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      {renderContent()}
    </Page.Section>
  )
}

export default DiaryTimelineView
