import { createSelector } from 'reselect'

import { CriterionV2 } from 'constants/service'
import { RootState } from 'core/reducers'

import { createTypeFromEnumValues } from 'utils/typescript'

import { ProductPriceGroupsType } from '../models'

const getProductPriceGroups = (state: RootState) => state.productPriceGroups

export const getProductPriceGroupsList = createSelector(
  [getProductPriceGroups],
  (state) => {
    if (!state.list.data?.length) {
      return null
    }

    return state.list
  },
)

export const getProductPriceGroupsListData = createSelector(
  [getProductPriceGroupsList],
  (state) => state?.data,
)

interface GetCriteriaProps {
  type?: createTypeFromEnumValues<ProductPriceGroupsType>
}

export const getCriteria = ({ type }: GetCriteriaProps): CriterionV2[] => {
  const criteria = []

  if (type) {
    criteria.push({
      field: 'type',
      value: type,
    })
  }

  return criteria
}
