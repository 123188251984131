import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;

  & > span {
    max-width: 250px;
    margin: 0 20px 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media(max-width: 410px) {
      max-width: 50px;
    }
  }
`

export const StyledButtonWrapper = styled.div`
  margin-bottom: -3px;
`
