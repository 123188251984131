import _ from 'lodash'

import React from 'react'

import PlanView from 'module/Finance/components/PlanView'

import i18n from 'translations'

const MAX_VISIBLE_SESSIONS = 6

const FinanceCurrentPlanSessions = ({ childSessions }) => {
  if (!childSessions || !childSessions.length) {
    return (
      <PlanView>
        <PlanView.Icon icon="sessions" />
        <PlanView.Content>
          <PlanView.SubTitle
            subtitle={i18n.t('module:Children:Child:Finance:PlanView:Summary:sessionEmptyState')}
          />
        </PlanView.Content>
      </PlanView>
    )
  }

  const moreSessionCount = childSessions.length - MAX_VISIBLE_SESSIONS
  const visibleSessions = childSessions.slice(0, MAX_VISIBLE_SESSIONS)

  const renderSession = ({ id, name, sessionCount }) => (
    <PlanView.Item key={id}>
      <PlanView.Highlight>
        {`${sessionCount} x`}
          &nbsp;
      </PlanView.Highlight>
      {name}
    </PlanView.Item>
  )

  const renderMoreSessions = () => {
    if (0 >= moreSessionCount) {
      return null
    }

    return (
      <PlanView.Item>
        <PlanView.Highlight>
          {i18n.t('module:Children:Child:Finance:PlanView:Summary:more', { count: moreSessionCount })}
        &nbsp;
        </PlanView.Highlight>
        {i18n.t('module:Children:Child:Finance:PlanView:Summary:session')}
      </PlanView.Item>
    )
  }

  return (
    <PlanView>
      <PlanView.Icon icon="sessions" />
      <PlanView.Content>
        {_.map(visibleSessions, renderSession)}
        {renderMoreSessions()}
      </PlanView.Content>
    </PlanView>
  )
}

export default FinanceCurrentPlanSessions
