import i18n from 'translations'

export const VIEWING_STATUSES = {
  ATTENDED: 'attended',
  BOOKED: 'booked',
  CANCELLED_BY_NURSERY: 'cancelledByNursery',
  CANCELLED_BY_PARENT: 'cancelledByParent',
  NOSHOW: 'noShow',
}

export const VIEWING_EVENT_TYPES = {
  CREATE: 'create',
  REMOVE: 'remove',
  UPDATE: 'update',
}

export const VIEWING_STATUS_OPTIONS = [
  {
    label: i18n.t('services:Viewings:StatusOptions:booked'),
    value: VIEWING_STATUSES.BOOKED,
  },
  {
    label: i18n.t('services:Viewings:StatusOptions:attended'),
    value: VIEWING_STATUSES.ATTENDED,
  },
  {
    label: i18n.t('services:Viewings:StatusOptions:noShow'),
    value: VIEWING_STATUSES.NOSHOW,
  },
  {
    label: i18n.t('services:Viewings:StatusOptions:canceledByParent'),
    value: VIEWING_STATUSES.CANCELLED_BY_PARENT,
  },
  {
    label: i18n.t('services:Viewings:StatusOptions:canceledByNursery'),
    value: VIEWING_STATUSES.CANCELLED_BY_NURSERY,
  },
]
