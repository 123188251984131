export default {
  grouped: {
    creditNotes: [

    ],
    extras: {
      fundingDeductedFromInvoiceTotal: true,
    },
    invoice: {
      amountDue: 1458.58,
      child: {
        id: 985,
        name: 'Naksh Dhamecha',
      },
      creditCarried: 0,
      dueDate: '2019-08-10T00:00:00.000Z',
      endDate: '2019-07-31T00:00:00.000Z',
      id: 1054,
      issueDate: '2019-07-31T00:00:00.000Z',
      name: 'test',
      number: 'INVOICE_NO.-000256',
      paymentsTotal: 0,
      sendLogs: [

      ],
      sentAt: '2019-07-31T12:55:39.000Z',
      startDate: '2019-07-01T00:00:00.000Z',
      status: 'OVERDUE',
      total: 1458.58,
      totalOutstanding: 1458.58,
      type: 'invoice',
    },
    isDraft: false,
    isGrouped: true,
    items: [
      {
        description: null,
        editable: false,
        extras: [

        ],
        items: [

        ],
        label: 'Fees',
        name: '',
        previewType: 'fees',
        total: 1533.65,
        type: 'header',
        unit: 'CURRENCY',
        visible: true,
      },
      {
        description: null,
        editable: false,
        extras: [

        ],
        items: [
          {
            extras: [

            ],
            items: [

            ],
            label: 'consumable',
            name: 'JD Combine',
            type: 'item',
            unit: 'CURRENCY',
            visible: false,
          },
          {
            extras: [

            ],
            items: [

            ],
            label: 'consumable',
            name: 'JD Separate',
            type: 'item',
            unit: 'CURRENCY',
            visible: true,
          },
        ],
        label: 'Fees',
        name: '5 JD Sessions per week',
        previewType: 'fees',
        type: 'item',
        unit: 'CURRENCY',
        visible: true,
      },
      {
        isSubItem: true,
        label: 'Consumable',
        name: 'JD Combine',
        previewType: 'fees',
        type: 'item',
        unit: 'CURRENCY',
        visible: false,
      },
      {
        isSubItem: true,
        label: 'Consumable',
        name: 'JD Separate',
        previewType: 'fees',
        type: 'item',
        unit: 'CURRENCY',
        visible: true,
      },
      {
        description: null,
        editable: false,
        extras: [

        ],
        items: [

        ],
        label: 'Fees',
        name: '2 Hourly Sessions per week',
        previewType: 'fees',
        type: 'item',
        unit: 'CURRENCY',
        visible: true,
      },
      {
        description: null,
        editable: false,
        extras: [

        ],
        items: [

        ],
        label: 'Extra session',
        name: '',
        previewType: 'extraSession',
        total: 102,
        type: 'header',
        unit: 'CURRENCY',
        visible: true,
      },
      {
        description: null,
        editable: false,
        extras: [

        ],
        items: [

        ],
        label: 'Extra session',
        name: '29/07/2019 - Full Day ',
        previewType: 'extraSession',
        type: 'item',
        unit: 'CURRENCY',
        visible: true,
      },
      {
        description: null,
        editable: false,
        extras: [

        ],
        items: [

        ],
        label: 'Extra session',
        name: '29/07/2019 - PM Session',
        previewType: 'extraSession',
        type: 'item',
        unit: 'CURRENCY',
        visible: true,
      },
      {
        description: null,
        editable: false,
        extras: [

        ],
        items: [

        ],
        label: 'Extra item',
        name: '',
        previewType: 'extraItem',
        total: 109.9,
        type: 'header',
        unit: 'CURRENCY',
        visible: true,
      },
      {
        description: null,
        editable: false,
        extras: [

        ],
        items: [

        ],
        label: 'Extra item',
        name: '10 Spanish Class',
        previewType: 'extraItem',
        type: 'item',
        unit: 'CURRENCY',
        visible: true,
      },
      {
        description: null,
        editable: false,
        extras: [

        ],
        items: [

        ],
        label: 'Funding',
        name: '',
        previewType: 'funding',
        type: 'header',
        unit: 'CURRENCY',
        visible: true,
      },
      {
        description: null,
        editable: false,
        extras: [

        ],
        items: [

        ],
        label: 'Funding',
        name: 'Inclusion funding',
        previewType: 'funding',
        type: 'item',
        unit: 'CURRENCY',
        visible: true,
      },
      {
        description: null,
        editable: false,
        extras: [

        ],
        items: [

        ],
        label: 'Funding',
        name: 'EYPP funding stretched',
        previewType: 'funding',
        type: 'item',
        unit: 'HOURS',
        visible: true,
      },
      {
        description: null,
        editable: false,
        extras: [

        ],
        items: [

        ],
        label: 'Discount',
        name: '',
        previewType: 'discount',
        total: -166.37,
        type: 'header',
        unit: 'CURRENCY',
        visible: true,
      },
      {
        description: null,
        editable: false,
        extras: [

        ],
        items: [

        ],
        label: 'Discount',
        name: '100 Coupon',
        previewType: 'discount',
        type: 'item',
        unit: 'CURRENCY',
        visible: true,
      },
      {
        description: '(Applied to fees, extra sessions, extra items)',
        editable: false,
        extras: {
          applicable: [
            'sessions',
            'extra_sessions',
            'extra_items',
          ],
        },
        items: [

        ],
        label: 'Discount',
        name: '10% (10%)',
        previewType: 'discount',
        type: 'item',
        unit: 'CURRENCY',
        visible: true,
      },
    ],
    type: 'GROUPED_ITEM_TYPES_WITHOUT_COST_BREAKDOWN',
  },
  standard: {
    creditNotes: [

    ],
    extras: {
      fundingDeductedFromInvoiceTotal: true,
    },
    invoice: {
      amountDue: 1458.58,
      child: {
        id: 985,
        name: 'Naksh Dhamecha',
      },
      creditCarried: 0,
      dueDate: '2019-08-10T00:00:00.000Z',
      endDate: '2019-07-31T00:00:00.000Z',
      id: 1054,
      issueDate: '2019-07-31T00:00:00.000Z',
      name: 'test',
      number: 'INVOICE_NO.-000256',
      paymentsTotal: 0,
      sendLogs: [

      ],
      sentAt: '2019-07-31T12:55:39.000Z',
      startDate: '2019-07-01T00:00:00.000Z',
      status: 'OVERDUE',
      total: 1458.58,
      totalOutstanding: 1458.58,
      type: 'invoice',
    },
    isDraft: false,
    isGrouped: false,
    items: [
      {
        description: null,
        editable: false,
        extras: [

        ],
        items: [
          {
            extras: [

            ],
            items: [

            ],
            label: 'consumable',
            name: 'JD Combine',
            type: 'item',
            unit: 'CURRENCY',
            visible: false,
          },
          {
            extras: [

            ],
            items: [

            ],
            label: 'consumable',
            name: 'JD Separate',
            total: 433.33,
            type: 'item',
            unit: 'CURRENCY',
            visible: true,
          },
        ],
        label: 'Fees',
        name: '5 JD Sessions per week',
        previewType: 'fees',
        total: 823.33,
        type: 'item',
        unit: 'CURRENCY',
        visible: true,
      },
      {
        isSubItem: true,
        label: 'Consumable',
        name: 'JD Combine',
        previewType: 'fees',
        type: 'item',
        unit: 'CURRENCY',
        visible: false,
      },
      {
        isSubItem: true,
        label: 'Consumable',
        name: 'JD Separate',
        previewType: 'fees',
        total: 433.33,
        type: 'item',
        unit: 'CURRENCY',
        visible: true,
      },
      {
        description: null,
        editable: false,
        extras: [

        ],
        items: [

        ],
        label: 'Fees',
        name: '2 Hourly Sessions per week',
        previewType: 'fees',
        total: 276.99,
        type: 'item',
        unit: 'CURRENCY',
        visible: true,
      },
      {
        description: null,
        editable: false,
        extras: [

        ],
        items: [

        ],
        label: 'Extra session',
        name: '29/07/2019 - Full Day ',
        previewType: 'extraSession',
        total: 94,
        type: 'item',
        unit: 'CURRENCY',
        visible: true,
      },
      {
        description: null,
        editable: false,
        extras: [

        ],
        items: [

        ],
        label: 'Extra session',
        name: '29/07/2019 - PM Session',
        previewType: 'extraSession',
        total: 8,
        type: 'item',
        unit: 'CURRENCY',
        visible: true,
      },
      {
        description: null,
        editable: false,
        extras: [

        ],
        items: [

        ],
        label: 'Extra item',
        name: 'Spanish Class',
        previewType: 'extraItem',
        quantity: 10,
        total: 109.9,
        type: 'item',
        unit: 'CURRENCY',
        unitPrice: 10.99,
        visible: true,
      },
      {
        description: null,
        editable: false,
        extras: [

        ],
        items: [

        ],
        label: 'Funding',
        name: 'Inclusion funding',
        previewType: 'funding',
        type: 'item',
        unit: 'CURRENCY',
        visible: true,
      },
      {
        description: null,
        editable: false,
        extras: [

        ],
        items: [

        ],
        label: 'Funding',
        name: 'EYPP funding stretched',
        previewType: 'funding',
        total: 67,
        type: 'item',
        unit: 'HOURS',
        visible: true,
      },
      {
        description: null,
        editable: false,
        extras: [

        ],
        items: [

        ],
        label: 'Discount',
        name: '100 Coupon',
        previewType: 'discount',
        total: -100.6,
        type: 'item',
        unit: 'CURRENCY',
        visible: true,
      },
      {
        description: '(Applied to fees, extra sessions, extra items)',
        editable: false,
        extras: {
          applicable: [
            'sessions',
            'extra_sessions',
            'extra_items',
          ],
        },
        items: [

        ],
        label: 'Discount',
        name: '10% (10%)',
        previewType: 'discount',
        total: -65.77,
        type: 'item',
        unit: 'CURRENCY',
        visible: true,
      },
    ],
    type: 'ITEM_TYPES_WITH_COST_BREAKDOWN',
  },
}
