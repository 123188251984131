import _ from 'lodash'
import i18n from 'translations'

export const NURSERY_TABLE_COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'name',
    title: i18n.t('global:Room'),
  },
  {
    field: 'revenue',
    title: i18n.t('global:Finance:Revenue'),
  },
]

export const ORGANIZATION_TABLE_COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'name',
    title: _.upperFirst(i18n.t('global:site')),
  },
  {
    field: 'revenue',
    title: i18n.t('global:Finance:Revenue'),
  },
]
