import _ from 'lodash'

import { getBrandingColor } from 'utils/branding'

import i18n from 'translations'

export const INJURIES = {
  ACCIDENT: 'ACCIDENT',
  HOME_ACCIDENT: 'HOME_ACCIDENT',
  INCIDENT: 'INCIDENT',
}

export const INJURIES_STATUS = {
  APPROVED_AND_SIGNED: {
    color: () => getBrandingColor('tertiary-color'),
    label: i18n.t('module:Injury:Constants:Status:approvedAndSigned'),
    value: 'APPROVED_AND_SIGNED',
  },
  DRAFT: {
    color: () => '#808080',
    label: i18n.t('module:Injury:Constants:Status:draft'),
    value: 'DRAFT',
  },
  PENDING_APPROVAL: {
    color: () => getBrandingColor('primary-color'),
    label: i18n.t('module:Injury:Constants:Status:pendingForApproval'),
    value: 'PENDING_APPROVAL',
  },
  SIGNATURES_NEEDED: {
    color: () => '#F5A741',
    label: i18n.t('module:Injury:Constants:Status:signatureNeeded'),
    value: 'SIGNATURES_NEEDED',
  },
}

export const INJURIES_STATUS_OPTIONS = _.map(INJURIES_STATUS, ({ label, value }) => ({
  label,
  value,
}))

export const HOME_ACCIDENT_STATUS_OPTIONS = _.map(
  _.filter(INJURIES_STATUS, ({ value }) => value !== INJURIES_STATUS.PENDING_APPROVAL.value),
  ({ label, value }) => ({
    label,
    value,
  }))

export const TYPE_OPTIONS = [
  {
    label: i18n.t('module:Injury:Constants:Type:accidents'),
    value: 'ACCIDENT',
  },
  {
    label: i18n.t('module:Injury:Constants:Type:incidents'),
    value: 'INCIDENT',
  },
]

export const INJURY_TYPE_OPTIONS = [
  {
    label: i18n.t('module:Safeguarding:Constants:Type:bruises'),
    value: 'bruises',
  },
  {
    label: i18n.t('module:Safeguarding:Constants:Type:burns'),
    value: 'burns',
  },
  {
    label: i18n.t('module:Safeguarding:Constants:Type:cuts'),
    value: 'cuts',
  },
  {
    label: i18n.t('module:Safeguarding:Constants:Type:headBump'),
    value: 'head bump',
  },
  {
    label: i18n.t('module:Safeguarding:Constants:Type:scratches'),
    value: 'scratches',
  },
  {
    label: i18n.t('module:Safeguarding:Constants:Type:other'),
    value: 'other',
  },
]

export const SIGNATURE_TYPES = {
  FIRSTAID: 'firstAid',
  PARENT: 'parent',
  STAFF_MEMBER: 'staffMember',
  WITNESS: 'witness',
}

export const OTHER_SIGNATURE_TYPES = {
  OTHER_STAFF_MEMBER: 'otherStaffMember',
  OTHER_WITNESS: 'otherWitness',
}

export const SIGNATURE_LABEL_MAPPINGS = {
  [SIGNATURE_TYPES.PARENT]: _.upperFirst(i18n.t('global:parents')),
  [SIGNATURE_TYPES.STAFF_MEMBER]: _.upperFirst(i18n.t('global:staffMembers')),
  [SIGNATURE_TYPES.WITNESS]: _.upperFirst(i18n.t('global:witness')),
  [SIGNATURE_TYPES.FIRSTAID]: _.upperFirst(i18n.t('global:firstAid')),
}

export const HA_SIGNATURE_TYPES = {
  PARENT: 'parent',
  STAFF_MEMBER: 'witness',
}

export const HA_SIGNATURE_LABEL_MAPPINGS = {
  [HA_SIGNATURE_TYPES.PARENT]: _.upperFirst(i18n.t('global:parentsGuardian')),
  [HA_SIGNATURE_TYPES.STAFF_MEMBER]: _.upperFirst(i18n.t('global:staffMembers')),
}

export const SIGNATURE_COLLECTING_TYPES = {
  DIGITALLY: 'DIGITALLY',
  ON_PAPER: 'ON_PAPER',
}

export const SIGNATURE_COLLECTING_LABELS = {
  [SIGNATURE_COLLECTING_TYPES.DIGITALLY]: i18n.t('module:Injury:Constants:SignatureCollectingLabels:digitally'),
  [SIGNATURE_COLLECTING_TYPES.ON_PAPER]: i18n.t('module:Injury:Constants:SignatureCollectingLabels:onPaper'),
}

export const SIGNATURE_STAFF_MEMBER_TYPES = {
  SENIOR: 'SENIOR',
  SUPERVISOR: 'SUPERVISOR',
}

export const SIGNATURE_STAFF_MEMBER_LABEL_MAPPINGS = {
  [SIGNATURE_STAFF_MEMBER_TYPES.SENIOR]: i18n.t('module:Injury:Constants:SignatureStaffMemberLabels:senior'),
  [SIGNATURE_STAFF_MEMBER_TYPES.SUPERVISOR]: i18n.t('module:Injury:Constants:SignatureStaffMemberLabels:supervisor'),
}

export const SIGNATURE_ADDITIONAL_TYPES = {
  PRIMARY: 'PRIMARY',
}

export const TAB_NAMES = {
  DETAILS: 'DETAILS',
  SIGNATURES: 'SIGNATURES',
}
