import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import modalConsts from './constants'
import actions from './actions'
import * as modalSelectors from './selectors'

export interface withModalServiceProps {
  modalActions: typeof actions
  modalConsts: typeof modalConsts
  modalSelectors: typeof modalSelectors
}

const withModalService = <P extends withModalServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapDispatch = injectActions('modalActions', actions)

  const Component = ({ modalActions, ...others }) => (
    <WrappedComponent
      modalActions={modalActions}
      modalConsts={modalConsts}
      modalSelectors={modalSelectors}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(null, mapDispatch)(Component)
}

export default withModalService
