import _ from 'lodash'

import React, { useState } from 'react'

import { ChildrenSlider } from 'components'

import ChildDetails from './ChildDetails'
import { StyledChildProfileContainer } from './ChildPreviewStyled'

const ChildPreview = ({ childId, injuryPreview, showInjuryPreviewDetail, topLine = true }) => {
  const [activeChildIndex, setActiveChildIndex] = useState(0)

  const { injured } = injuryPreview

  if (!injured.length) {
    return null
  }

  let injuredList = injured

  if (childId) {
    injuredList = _.filter(injured, ({ child }) => child.id === childId)
  }

  return (
    <React.Fragment>
      <StyledChildProfileContainer topLine={topLine}>
        <ChildrenSlider
          childrenList={_.map(injuredList, (e) => e.child)}
          selectedSlideIndex={activeChildIndex}
          onClickSlide={(selectedChild) => setActiveChildIndex(selectedChild)}
        />
      </StyledChildProfileContainer>
      <ChildDetails
        data={injuredList[activeChildIndex]}
        injuryPreview={injuryPreview}
        showInjuryPreviewDetail={showInjuryPreviewDetail}
      />
    </React.Fragment>
  )
}

export default ChildPreview
