import { DAYS_OF_WEEK_LIST } from 'constants/date'
import _ from 'lodash'

import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import { REGULAR_DISCOUNT_TYPES } from 'services/booking/childBooking/constants'
import { ChildProductTypes } from 'services/booking/childProducts/constants'
import { Option } from 'constants/models'
// eslint-disable-next-line max-len, import-newlines/enforce
import { BEFORE_AFTER_TYPE_OPTIONS, BeforeAfterType } from 'module/Management/ManagementFinanceSettings/ManagementDiscounts/v3/ManagementDiscountsV3Add/components/constants'
import { NurseryDiscountTypesV3 } from 'services/product/nurseryDiscountsV3/constants'

import { isMaxLengthLessThan, isNumberLessOrEqualThan, isRequired } from 'utils/fieldValidation'

import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

import { Button, Currency, FooterActions, Form, ModalBox, Spinner } from 'components'

import i18n from 'translations'

export const OTHER_OPTION = {
  label: i18n.t('global:Other'),
  value: 0,
}

const WEEK_DAYS = _.times(7, (index) => ({
  label: i18n.t(`global:shortDayNames:${index + 1}`),
  value: index,
}))

const isMaxLengthLessThan250 = isMaxLengthLessThan(250)
const isNumberLessOrEqualThan99999 = isNumberLessOrEqualThan(99999)

export const ADD_DISCOUNT_TO_REGULAR_BOOKINGS_MODAL_FORM = 'AddDiscountToRegularBookingsModalForm'

export interface AddDiscountToRegularBookingsModalFormValues {
  applicable?: {
    consumables: boolean
    extraItems: boolean
    extraSessions: boolean
    sessions: boolean
  }
  applicableBeforeFunding?: BeforeAfterType
  daysApplicable?: number[]
  discount?: {
    label: string
    settings: {
      allowOverride: boolean
      applicable?: string[]
      applicableBeforeFunding?: boolean
      value?: number
    }
    type: NurseryDiscountTypesV3
    value: number
  }
  name?: string
  type?: ChildProductTypes
  value?: number
}

export interface AddDiscountToRegularBookingsModalFormProps {
  formValues?: AddDiscountToRegularBookingsModalFormValues
  hasAccessToViewPrice?: boolean
  isFetchingPrice: boolean
  nurseryOpeningDays?: any
  onChangeDiscount: (option: Option) => void
  onCloseClick?: () => void
  onSubmit: (values: any) => void
  price: number | null
}

type AddDiscountToRegularBookingsModalFormFullProps = InjectedFormProps<
  {},
  AddDiscountToRegularBookingsModalFormProps
> & AddDiscountToRegularBookingsModalFormProps

const AddDiscountToRegularBookingsModalForm: React.FC<AddDiscountToRegularBookingsModalFormFullProps> = ({
  formValues,
  handleSubmit,
  hasAccessToViewPrice,
  isFetchingPrice,
  nurseryOpeningDays,
  onChangeDiscount,
  onCloseClick,
  onSubmit,
  price,
}) => {
  const { discount, type } = formValues || {}
  const { value } = discount || {}
  const isOtherOption = value === OTHER_OPTION.value

  const renderFooter = () => (
    <FooterActions spaceBetween>
      <FooterActions.Group>
        <FooterActions.Item>
          <Button
            hierarchy="tertiary"
            label={i18n.t('global:cancel')}
            negativeMargins
            onClick={onCloseClick}
          />
        </FooterActions.Item>
      </FooterActions.Group>
      <FooterActions.Group>
        <FooterActions.Item>
          <Button
            disabled={isFetchingPrice}
            label={i18n.t('global:save')}
            negativeMargins
            submit
          />
        </FooterActions.Item>
      </FooterActions.Group>
    </FooterActions>
  )

  const renderOtherFields = () => (
    <React.Fragment>
      <Form.Row
        label={i18n.t('global:Description')}
        width={{ field: '320px' }}
        required
        verticalLabel
      >
        <Field
          component={Form.TextField}
          maxLength={250}
          name="name"
          placeholder={i18n.t('global:Description')}
          validate={[isRequired, isMaxLengthLessThan250]}
        />
      </Form.Row>
      <Form.Row
        label={i18n.t('modals:AddDiscountToRegularBookings:discountType')}
        required
        verticalLabel
      >
        <Field
          component={Form.RadioGroup}
          name="type"
          options={REGULAR_DISCOUNT_TYPES}
          placeholder={i18n.t('modals:AddDiscountToRegularBookings:discountType')}
          validate={isRequired}
        />
      </Form.Row>
      <Form.Row
        label={type === ChildProductTypes.REGULAR_AMOUNT_DISCOUNT
          ? i18n.t('modals:AddDiscountToRegularBookings:dailyDiscountValue')
          : i18n.t('modals:AddDiscountToRegularBookings:discountPercentage')}
        width={{ field: '120px' }}
        required
        verticalLabel
      >
        <SubdomainCurrencyProvider>
          {({ currencySymbol }) => (
            <Field
              component={Form.TextField}
              maxLength={5}
              name="value"
              placeholder={type === ChildProductTypes.REGULAR_AMOUNT_DISCOUNT ? 0.00 : 0}
              prefix={type === ChildProductTypes.REGULAR_AMOUNT_DISCOUNT ? currencySymbol : '%'}
              prefixWidth="30px"
              step="any"
              type="number"
              validate={[isRequired, isNumberLessOrEqualThan99999]}
            />
          )}
        </SubdomainCurrencyProvider>
      </Form.Row>
      {ChildProductTypes.REGULAR_PERCENTAGE_DISCOUNT === type && (
        <React.Fragment>
          <Form.Row>
            <Field
              component={Form.Checkbox}
              label={i18n.t('modals:AddDiscountToRegularBookings:regularSessions')}
              name="applicable.sessions"
            />
          </Form.Row>
          <Form.Row>
            <Field
              component={Form.Checkbox}
              label={i18n.t('modals:AddDiscountToRegularBookings:extraSessions')}
              name="applicable.extra_sessions"
            />
          </Form.Row>
          <Form.Row>
            <Field
              component={Form.Checkbox}
              label={i18n.t('modals:AddDiscountToRegularBookings:extraItems')}
              name="applicable.extra_items"
            />
          </Form.Row>
          <Form.Row>
            <Field
              component={Form.Checkbox}
              label={i18n.t('module:Management:Finance:AccountCodes:ItemCategories:regularItems')}
              name="applicable.regular_items"
            />
          </Form.Row>
          {formValues?.applicable?.sessions && (
            <Form.Row>
              <Field
                component={Form.Checkbox}
                label={i18n.t('modals:AddDiscountToRegularBookings:consumablesAddedToSessions')}
                name="applicable.consumables"
              />
            </Form.Row>
          )}
        </React.Fragment>
      )}
      {formValues?.type !== ChildProductTypes.REGULAR_AMOUNT_DISCOUNT && formValues?.applicable?.sessions && (
        <Form.Row
          label={i18n.t('module:Management:Finance:Discounts:Form:BeforeAfterFunding:label')}
          verticalLabel
        >
          <Field
            component={Form.RadioGroup}
            name="applicableBeforeFunding"
            options={BEFORE_AFTER_TYPE_OPTIONS}
            validate={isRequired}
            buttonStyle
            horizontal
          />
        </Form.Row>
      )}
    </React.Fragment>
  )

  const renderPrice = () => {
    if (isFetchingPrice) {
      return (
        <Spinner position="flex-start" size="small" />
      )
    }

    if (!_.isNumber(price)) {
      return '-'
    }

    return (
      <Currency value={price / 100} />
    )
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ModalBox.ScrollContent>
        <Form.Row
          label={i18n.t('modals:AddDiscountToRegularBookings:discountName')}
          width={{ field: '300px' }}
          required
          verticalLabel
        >
          <Field
            clearable={false}
            component={Form.InfiniteDropdowns.NurseryDiscountsV3}
            extraFields={['settings', 'type']}
            extraOptions={[OTHER_OPTION]}
            name="discount"
            placeholder={i18n.t('modals:AddDiscountToRegularBookings:discountName')}
            validate={isRequired}
            bodyTarget
            // @ts-ignore
            onChange={onChangeDiscount}
          />
        </Form.Row>
        <Form.Row
          label={i18n.t('modals:AddDiscountToRegularBookings:daysApplicable')}
          required
          verticalLabel
        >
          <Field
            component={Form.ButtonGroup}
            name="daysApplicable"
            options={_.map(WEEK_DAYS, (weekDay) => ({
              ...weekDay,
              disabled: !(
                _.find(nurseryOpeningDays, ({ day }) => day === DAYS_OF_WEEK_LIST[weekDay.value])
              ),
            }))}
            validate={isRequired}
            multi
          />
        </Form.Row>
        {isOtherOption && renderOtherFields()}
        {formValues?.discount?.type === NurseryDiscountTypesV3.PERCENTAGE && (
          <Form.Row
            label={i18n.t('modals:AddDiscountToRegularBookings:discountAppliedOnSessions')}
            verticalLabel
          >
            {formValues?.discount?.settings?.applicableBeforeFunding
              ? i18n.t('modals:AddDiscountToRegularBookings:beforeFunding')
              : i18n.t('modals:AddDiscountToRegularBookings:afterFunding')}
          </Form.Row>
        )}
        {!isOtherOption && hasAccessToViewPrice && (
          <Form.Row
            label={formValues?.discount?.type === NurseryDiscountTypesV3.AMOUNT
              ? i18n.t('modals:AddDiscountToRegularBookings:dailyDiscountValue')
              : i18n.t('modals:AddDiscountToRegularBookings:discountPercentage')}
            width={{ field: '120px' }}
            verticalLabel
          >
            <SubdomainCurrencyProvider>
              {({ currencySymbol }) => (
                <Field
                  component={Form.TextField}
                  disabled={!formValues?.discount?.settings?.allowOverride}
                  name="value"
                  placeholder={formValues?.discount?.type === NurseryDiscountTypesV3.AMOUNT ? '0.00' : '0'}
                  prefix={formValues?.discount?.type === NurseryDiscountTypesV3.AMOUNT ? currencySymbol : '%'}
                  prefixWidth="30px"
                />
              )}
            </SubdomainCurrencyProvider>
          </Form.Row>
        )}
        {hasAccessToViewPrice && (
          <Form.Row
            label={i18n.t('modals:AddDiscountToRegularBookings:weeklyValue')}
            verticalLabel
          >
            {renderPrice()}
          </Form.Row>
        )}
      </ModalBox.ScrollContent>
      {renderFooter()}
    </Form>
  )
}

export default reduxForm<{}, AddDiscountToRegularBookingsModalFormProps>({
  form: ADD_DISCOUNT_TO_REGULAR_BOOKINGS_MODAL_FORM,
})(AddDiscountToRegularBookingsModalForm)
