import _ from 'lodash'

import {
  CLEAR_CHILD_FUNDING_SESSION_SET,
  LIST_CHILD_FUNDING_SESSION_SET,
  LIST_CHILD_FUNDING_SESSION_SET_FAILED,
  LIST_CHILD_FUNDING_SESSION_SET_SUCCESS,
  UPDATE_CHILD_FUNDING_SESSION_SET_LIST,
} from './actions'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: {},
}

export default (state = initialState, { error, mergeResult, payload, type }) => {
  switch (type) {
    case LIST_CHILD_FUNDING_SESSION_SET:
      return { ...state, error: initialState.error, isFetching: true }
    case LIST_CHILD_FUNDING_SESSION_SET_SUCCESS:
      return { ...state,
        data: !mergeResult || !state.data ? payload.data : [...state.data, ...payload.data],
        isFetching: false,
        meta: payload.meta }
    case LIST_CHILD_FUNDING_SESSION_SET_FAILED:
      return { ...state, error, isFetching: false }

    case UPDATE_CHILD_FUNDING_SESSION_SET_LIST:
      return {
        ...state,
        data: [
          ..._.reject(state.data, (item) => item.id === payload.id),
          payload,
        ],
      }
    case CLEAR_CHILD_FUNDING_SESSION_SET:
      return initialState
    default:
      return state
  }
}
