import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledContainer = styled.div`
  width: 100%;
  min-height: 80px;
  padding: 15px;
  background: ${getBrandingColor('quinary-color')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`

export const StyledConfirmationMessage = styled.div`
  color: ${getBrandingColor('tertiary-color')};
  display: flex;
  align-items: center;
`
