import React from 'react'

import { generateRoute } from 'utils/routing'

import { FolderList, Page } from 'components'

import i18n from 'translations'

const ManagementLeaveTypesView = () => (
  <Page.Section title={i18n.t('module:Management:LeaveTypes:title')}>
    <FolderList>
      <FolderList.Item
        icon="children"
        title={i18n.t('module:Management:LeaveTypes:Child:title')}
        to={generateRoute('MANAGEMENT.LEAVE_TYPES.CHILD_ABSENCE_REASONS')}
      />
      <FolderList.Item
        icon="staff"
        title={i18n.t('module:Management:LeaveTypes:Staff:title')}
        to={generateRoute('MANAGEMENT.LEAVE_TYPES.STAFF_LEAVE_TYPES')}
      />
    </FolderList>
  </Page.Section>
)

export default ManagementLeaveTypesView
