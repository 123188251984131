import _ from 'lodash'
import moment from 'moment'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DateUtils } from 'react-day-picker'

import { DEFAULT_DATE_FORMAT, NUMBER_OF_MONTHS } from 'constants/date'

import { PeriodTime } from 'services/periodTimes/models'

import MultiRangeCalendarDay from './MultiRangeCalendarDay'
import { isSelectingFirstDay, splitRanges, updateRanges } from './MultiRangeCalendarHelper'
import { StyledCalendarContainer } from './MultiRangeCalendarStyled'
import MultiRangeCalendarDayPickerWrapper from './MultiRangeCalendarDayPickerWrapper'

export type Ranges = {
  from: any
  to: any
} | null

interface MultiRangeCalendarProps {
  closureDates?: PeriodTime[]
  defaultDateRanges?: Ranges[]
  month?: number
  onChange?: (selectedRanges: Ranges[]) => void
  year?: number
}

const MultiRangeCalendar: React.FC<MultiRangeCalendarProps> = ({
  closureDates,
  defaultDateRanges,
  month,
  onChange,
  year,
}) => {
  const [tempRange, setTempRange] = useState({ from: null, to: null })
  const [ranges, setRanges] = useState<Ranges[]>([])
  const [lastDayMouseEnter, setLastDayMouseEnter] = useState(null)
  const [tempUnSelectedRange, setTempUnSelectedRange] = useState({
    from: null,
    to: null,
  })
  const [lastDayUnselectedMouseEnter, setLastDayUnselectedMouseEnter] = useState(null)

  useEffect(() => {
    if (defaultDateRanges?.length && !ranges?.length) {
      setRanges(defaultDateRanges)
    }
  }, [defaultDateRanges])

  useEffect(() => {
    if (!!tempRange.from && !!tempRange.to) {
      const newRanges = updateRanges(tempRange, ranges)

      setRanges(newRanges)
    }
  }, [tempRange])

  useEffect(() => {
    if (!!tempUnSelectedRange.from && !!tempUnSelectedRange.to) {
      const newRanges = splitRanges(tempUnSelectedRange, ranges)

      setRanges(newRanges)
      setTempUnSelectedRange({ from: null, to: null })
      setLastDayUnselectedMouseEnter(null)
    }
  }, [tempUnSelectedRange])

  useEffect(() => {
    setTempRange({ from: null, to: null })
    setLastDayMouseEnter(null)
    onChange(ranges)
  }, [ranges])

  const handleDayClick = useCallback(
    (day, modifiers) => {
      const { selected } = modifiers

      const isDayInHoverRange = DateUtils.isDayInRange(day, {
        from: tempRange.from,
        to: lastDayMouseEnter,
      })

      // Clicking logic:
      // 1. startDate    -> selected: undefined, isDayInHoverRange: null
      // 2. endDate      -> selected: true,      isDayInHoverRange: true
      // 3. remove range -> selected: true,      isDayInHoverRange: null
      if (!selected || isDayInHoverRange) {
        setTempRange(DateUtils.addDayToRange(day, tempRange))

        return
      }

      const isDayInUnselectedHoverRange = DateUtils.isDayInRange(day, {
        from: tempUnSelectedRange.from,
        to: lastDayUnselectedMouseEnter,
      })

      if (selected || isDayInUnselectedHoverRange) {
        setTempUnSelectedRange(DateUtils.addDayToRange(day, tempUnSelectedRange))
      }
    },
    [lastDayMouseEnter, tempUnSelectedRange.from, tempUnSelectedRange.to],
  )

  const handleDayMouseEnter = useCallback(
    (day) => {
      const { from, to } = tempRange

      if (!isSelectingFirstDay(from, to)) {
        setLastDayMouseEnter(day)

        return
      }

      const { from: unselectedFrom, to: unSelectedTo } = tempUnSelectedRange

      if (!isSelectingFirstDay(unselectedFrom, unSelectedTo)) {
        setLastDayUnselectedMouseEnter(day)
      }
    },
    [tempRange.from, tempRange.to, tempUnSelectedRange.from, tempUnSelectedRange.to],
  )

  const getFromDate = () => {
    if (!tempRange.from && !lastDayMouseEnter) {
      return null
    }

    if (tempRange.from && !lastDayMouseEnter) {
      return tempRange.from
    }

    return DateUtils.isDayBefore(tempRange.from, lastDayMouseEnter)
      ? tempRange.from
      : lastDayMouseEnter
  }

  const getToDate = () => {
    if (!lastDayMouseEnter) {
      return null
    }

    return !DateUtils.isDayBefore(tempRange.from, lastDayMouseEnter)
      ? tempRange.from
      : lastDayMouseEnter
  }

  const modifiers = {
    alreadyInRange: (day) => ranges.some((r) => DateUtils.isDayInRange(day, r)),
    firstDayFromRange: (day) => ranges.some((r) => DateUtils.isSameDay(day, r.from)),
    from: getFromDate(),
    lastDayFromRange: (day) => ranges.some((r) => DateUtils.isSameDay(day, r.to)),
    to: getToDate(),
    unSelected: (day) => DateUtils.isDayInRange(day, {
      from: tempUnSelectedRange.from,
      to: tempUnSelectedRange.from ? lastDayUnselectedMouseEnter : null,
    }),
  }

  const selectedDays = useMemo(
    () => [
      { from: tempRange.from, to: lastDayMouseEnter },
      {
        from: tempUnSelectedRange.from,
        to: tempUnSelectedRange.from ? lastDayUnselectedMouseEnter : null,
      },
      ...ranges,
    ],
    [
      tempRange.from,
      tempRange.to,
      lastDayMouseEnter,
      tempUnSelectedRange.from,
      tempUnSelectedRange.to,
      lastDayUnselectedMouseEnter,
      ranges.length,
    ],
  )

  const renderDayComponent = (day: Date) => {
    if (!closureDates?.length) {
      return <MultiRangeCalendarDay day={day} />
    }

    const closureItem = _.find(closureDates, ({ endDate, startDate }) => (
      moment(day).format(DEFAULT_DATE_FORMAT) >= moment(startDate).format(DEFAULT_DATE_FORMAT)
      && moment(day).format(DEFAULT_DATE_FORMAT) <= moment(endDate).format(DEFAULT_DATE_FORMAT)
    ))

    if (!closureItem) {
      return <MultiRangeCalendarDay day={day} />
    }

    return <MultiRangeCalendarDay day={day} tooltipTitle={closureItem?.period?.name} isClosed />
  }

  return (
    <StyledCalendarContainer>
      {_.map([...Array(NUMBER_OF_MONTHS)], (dummy, index) => {
        const currentYear = year
        const currentMonth = month + index

        const startDate = new Date(currentYear, currentMonth, 1)
        const endDate = new Date(currentYear, currentMonth + 1, 0)

        const selectedDateForCurrentMonth = _.filter(selectedDays, ({ from, to }) => {
          if (!from && !to) {
            return false
          }

          return (
            (from >= startDate && from <= endDate) || (startDate >= from && startDate <= to)
          )
        })

        return (
          <MultiRangeCalendarDayPickerWrapper
            key={currentMonth}
            modifiers={modifiers}
            month={new Date(year, currentMonth)}
            renderDayComponent={renderDayComponent}
            selectedDays={selectedDateForCurrentMonth}
            onDayClick={handleDayClick}
            onDayMouseEnter={handleDayMouseEnter}
          />
        )
      })}
    </StyledCalendarContainer>
  )
}

export default MultiRangeCalendar
