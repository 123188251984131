import ReportView from './ReportView'
import ReportViewDetailPage from './ReportViewDetailPage'
import ReportViewItem from './ReportViewItem'
import ReportViewNurseryLink from './ReportViewNurseryLink'

ReportView.Item = ReportViewItem
ReportView.DetailPage = ReportViewDetailPage
ReportView.NurseryLink = ReportViewNurseryLink

export default ReportView
