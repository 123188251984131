import React, { useEffect } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'

import { RootState } from 'core/reducers'
import { withRouterProps } from 'services/router/constants'
import { withRouteUtilsProps } from 'services/utils/router'
import { FEATURE_FLAGS } from 'constants/security'

import auth from 'utils/auth'

import { withAppService, withAppServiceProps } from 'services/app'
import { withNurseriesService, withNurseriesServiceProps } from 'services/nurseries'

import SettingsInvoicesItemDetailsV3View from './SettingsInvoicesItemDetailsV3View'

const NURSERY_INVOICE_SETTINGS_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings.invoice',
    'nurseryInvoiceSettings',
    'nurseryInvoiceSettings.numbers',
    'invoiceNumbers',
    'nurseryInvoiceSettings.paymentReminders',
    'nurseryInvoiceSettings.accountCodes',
    'accountCodes',
    'nurseryInvoiceSettings.annualisedInvoiceSettings',
    'annualisedInvoiceSettings',
    'nursery.organizationSettings',
    'organizationSettings',
    'organizationSettings.invoice',
    'organizationInvoiceSettings',
    'nursery.bankDetails',
    'nurserySettings.localeDetails',
  ],
}

type SettingsInvoicesItemDetailsV3ContainerProps = withAppServiceProps
  & withRouteUtilsProps
  & withRouterProps
  & withNurseriesServiceProps

const mapState = (state: RootState, {
  appSelectors,
  nurseriesSelectors,
  nurseriesSingleState,
}: SettingsInvoicesItemDetailsV3ContainerProps) => ({
  FinanceAutomation: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.FINANCE_AUTOMATION],
  }),
  bankDetails: nurseriesSelectors.getBankDetails(state),
  errorMessages: appSelectors.getErrorMessages(nurseriesSingleState),
  isEditDisabled: nurseriesSelectors.isGlobalSettingEditable(state),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState),
  isGlobalEnabled: nurseriesSelectors.isGlobalSettingCustomizable(state),
  isUkCountry: nurseriesSelectors.getIsUkCountry(state),
  nurseryInvoiceSettings: nurseriesSelectors.getNurseryInvoiceSettings(state),
  nurseryName: nurseriesSelectors.getNurseryName(state),
  organizationInvoiceSettings: nurseriesSelectors.getOrganizationInvoiceSettings(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

export type SettingsInvoicesItemDetailsV3ContainerFullProps = SettingsInvoicesItemDetailsV3ContainerProps
  & PropsFromRedux

const SettingsInvoicesItemDetailsV3Container: React.FC<SettingsInvoicesItemDetailsV3ContainerFullProps> = ({
  FinanceAutomation,
  bankDetails,
  errorMessages,
  isEditDisabled,
  isFetching,
  isGlobalEnabled,
  isUkCountry,
  nurseriesActions,
  nurseriesSelectors,
  nurseriesSingleState,
  nurseryInvoiceSettings,
  nurseryName,
  organizationInvoiceSettings,
  params,
}) => {
  const hideGlobalOption = !isGlobalEnabled
  const { nurseryId } = params

  useEffect(() => {
    nurseriesActions.get(nurseryId, {
      params: { groups: NURSERY_INVOICE_SETTINGS_GROUPS },
    })

    return () => {
      nurseriesActions.clearSingle()
    }
  }, [])

  const handleSubmit = (fields) => (successCallback) => {
    const apiParams = { groups: NURSERY_INVOICE_SETTINGS_GROUPS }
    const payload = nurseriesSelectors.getPayload({
      fields,
      nurseriesSingleState,
    })

    nurseriesActions.update(nurseryId, {
      onSuccess: successCallback,
      params: apiParams,
      payload,
    })
  }

  return (
    <SettingsInvoicesItemDetailsV3View
      FinanceAutomation={FinanceAutomation}
      bankDetails={bankDetails}
      disableEdit={isEditDisabled}
      errorMessages={errorMessages}
      hideGlobalOption={hideGlobalOption}
      isLoading={isFetching}
      isUkCountry={isUkCountry}
      nurseryId={nurseryId}
      nurseryInvoiceSettings={nurseryInvoiceSettings}
      nurseryName={nurseryName}
      organizationInvoiceSettings={organizationInvoiceSettings}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withAppService,
  withNurseriesService,
  connector,
)

export default enhance(SettingsInvoicesItemDetailsV3Container)
