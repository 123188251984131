import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'
import { NEUTRAL_COLOURS } from 'constants/colors'
import { ALL_ROOMS } from 'services/legacy/rooms/constants'
import { DAYS_MAP } from 'services/legacy/requestedExtraSessions/constants'

import { DropdownMenu, TimeString, Typography } from 'components'

import i18n from 'translations'

import { StyledEmptyCell, StyledTableCell } from './UnavailableTimesStyled'

export const getExceptionalUnavailableTimesTableData = ({ data, onDeleteClick, onEditClick }) => {
  if (!data?.length) {
    return null
  }

  return _.map(data, ({ date, endTime, id, nurseryClass, startTime }) => ({
    date: moment(date).format(DISPLAY_SHORT_DATE_FORMAT),
    nurseryClass: nurseryClass?.name || ALL_ROOMS.label,
    options: (
      <DropdownMenu small>
        <DropdownMenu.Item
          type="edit"
          onClick={() => onEditClick(id)}
        />
        <DropdownMenu.Item
          type="delete"
          onClick={() => onDeleteClick(id)}
        />
      </DropdownMenu>
    ),
    time: <TimeString.Range end={endTime} local={false} start={startTime} />,
  }))
}

const renderItem = ({ endTime, id, nurseryClass, startTime }) => (
  <Typography key={id} variant="div">
    <Typography>
      <TimeString.Range end={endTime} local={false} start={startTime} />
    </Typography>
    <Typography color={NEUTRAL_COLOURS.GRAY}>
      {nurseryClass?.name || i18n.t('global:AllRooms')}
    </Typography>
  </Typography>
)

export const getRegularUnavailableTimesTableData = ({ data, openingDays }) => {
  if (!data?.length) {
    return [{ [openingDays?.[0]]: <StyledEmptyCell /> }]
  }

  const daysArray = _.reduce(openingDays, (result, day) => ({
    ...result,
    [day]: [],
  }), [])

  _.forEach(data, (item) => {
    const key = _.find(openingDays, (day) => item.day === day)

    if (key) {
      daysArray[key].push(item)
    }
  })

  return [
    _.reduce(openingDays, (result, day) => ({
      ...result,
      [day]: (
        <StyledTableCell>
          {_.map(daysArray[day], renderItem)}
        </StyledTableCell>
      ),
    }), {}),
  ]
}

export const getRegularUnavailableTimesTableColumns = (openingDays) => _.map(openingDays, (day) => ({
  align: 'left',
  alignTh: 'left',
  field: day,
  title: DAYS_MAP[day],
  verticalAlign: 'top',
  // eslint-disable-next-line no-unsafe-optional-chaining
  width: `${100 / openingDays?.length}%`,
}))
