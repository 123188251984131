import { createSelector } from 'reselect'

const getFinanceReports = (state) => state.financeDepositsReport

export const getFinanceDepositsReportNurseries = createSelector(
  [getFinanceReports],
  (state) => state.nurseries,
)

export const getFinanceDepositsReportNurseriesData = createSelector(
  [getFinanceDepositsReportNurseries],
  (state) => state.data,
)
