import React from 'react'

import { Callout, Spinner } from 'components'

import GlobalInvoiceNumberSettingsAddForm from './components/GlobalInvoiceNumberSettingsAddForm'

const SettingsInvoicesGlobalEditView = ({
  errorMessages,
  globalNumberSettingsOptions,
  initialValues,
  isFormLoading,
  isLoading,
  onSubmit,
}) => {
  if (isLoading) {
    return <Spinner />
  }

  return (
    <div>
      <Callout content={errorMessages} error />
      <GlobalInvoiceNumberSettingsAddForm
        globalNumberSettingsOptions={globalNumberSettingsOptions}
        initialValues={initialValues}
        isLoading={isFormLoading}
        onSubmit={onSubmit}
      />
    </div>
  )
}

export default SettingsInvoicesGlobalEditView
