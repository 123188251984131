import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CHILDREN_SIGNUP_REPORT_STATISTICS,
  CHILDREN_SIGNUP_REPORT_STATISTICS_FAILED,
  CHILDREN_SIGNUP_REPORT_STATISTICS_SUCCESS,
  CLEAR_CHILDREN_SIGNUP_REPORT_STATISTICS,
} from './constants'

export const getStatistics = (options: BasicActionOptionsProps) => ({
  childrenSignedUpReportApiClient,
}) => generateBasicActions.list({
  constants: {
    failed: CHILDREN_SIGNUP_REPORT_STATISTICS_FAILED,
    init: CHILDREN_SIGNUP_REPORT_STATISTICS,
    success: CHILDREN_SIGNUP_REPORT_STATISTICS_SUCCESS,
  },
  options,
  service: childrenSignedUpReportApiClient,
  serviceMethod: 'getStatistics',
})

export const clearStatistics = () => ({
  type: CLEAR_CHILDREN_SIGNUP_REPORT_STATISTICS,
})
