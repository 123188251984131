import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'
import layout from 'constants/layout'

import { StyledButton } from 'components/Button/ButtonStyled'

interface StyledContainerProps {
  $full?: boolean
  $noBorder?: boolean
  $noMargin?: boolean
  $noPadding?: boolean
  $spaceBetween?: boolean
}

export const StyledContainer = styled.div<StyledContainerProps>`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px dashed ${NEUTRAL_COLOURS.SILVER};
  display: flex;
  align-items: center;
  justify-content: ${({ $spaceBetween }) => ($spaceBetween ? 'space-between' : 'center')};
  
  ${({ $noBorder }) => $noBorder && `
    border-top: none;
  `}
  
  ${({ $noMargin }) => $noMargin && `
    margin-top: 0;
  `}
  
  ${({ $noPadding }) => $noPadding && `
    padding-top: 0;
  `}

  ${({ $full }) => $full && `
    width: 100%;
  `}
  
  @media(max-width: ${layout.mobileWidthResolution}px) {
    flex-direction: column;
    justify-content: unset;
    display: grid;
    grid-row-gap: 5px;
    width: 100%;
  }
`

export const StyledGroupContainer = styled.div`
  display: flex;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    flex-direction: column;
    justify-content: unset;
    display: grid;
    grid-row-gap: 5px;
    width: 100%;
  }
`

export const StyledItemContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  
  @media(max-width: ${layout.mobileWidthResolution}px) {
    width: 100%;
    padding: 0;
    
    & > * {
      width: 100%
    }

    ${StyledButton} {
      width: 100%
    }
  }
`

export const StyledFlex = styled.div`
  flex: 1;  
`
