import _ from 'lodash'

import { generateRoute } from 'utils/routing'

import i18n from 'translations'

// NOTE: originally it was in the mapStateToProps but the object literal was causing constant re-rendering.
// We were trying to use reselect, move to separate selectors but nothing helped. That's why
// properties are now top level state props
export const getAuthAccessMap = (params) => ({
  module: {
    ApprovalList: params.ApprovalList,
    Children: params.Children,
    CreateNursery: params.CreateNursery,
    DailyDiary: params.DailyDiary,
    Enquiries: params.Enquiries,
    Finance: params.Finance,
    Injury: params.Injury,
    Learning: params.Learning,
    Management: params.Management,
    Newsletters: params.Newsletters,
    NurseryFiles: params.NurseryFiles,
    Occupancy: params.Occupancy,
    OrganizationChildren: params.OrganizationChildren,
    OrganizationNurseryRota: params.OrganizationNurseryRota,
    OrganizationReports: params.OrganizationReports,
    ParentInbox: params.ParentInbox,
    Register: params.Register,
    Reports: params.Reports,
    RoomsWrapper: params.RoomsWrapper,
    Settings: params.Settings,
    Staff: {
      StaffLeave: params.StaffLeave,
      StaffList: params.StaffList,
      StaffRegister: params.StaffRegister,
      StaffRota: params.StaffRota,
      StaffWorklog: params.StaffWorklog,
    },
  },
  premiumModal: {
    Finance: params.PremiumFinance,
    Injury: params.PremiumInjury,
    Occupancy: params.PremiumOccupancy,
    Reports: params.PremiumReports,
  },
})

export const getMenuList = ({
  authAccessMap,
  showOrganizationMenu,
  showSuperAdminMenu,
}) => {
  let lists = [
    {
      dashboardType: true,
    },
    {
      hidden: !authAccessMap.module.Children,
      icon: 'children',
      label: 'Children',
      to: '/children',
    },
    {
      availableInOfflineMode: true,
      hidden: !authAccessMap.module.Register,
      icon: 'register',
      label: 'Register',
      to: '/attendance',
    },
    {
      hidden: !authAccessMap.module.ParentInbox,
      icon: 'parent',
      label: 'Parent Inbox',
      to: generateRoute('PARENT_INBOX.INDEX'),
    },
    {
      hidden: !authAccessMap.module.ApprovalList,
      icon: 'approvals',
      label: 'Approvals',
      to: '/approvals',
    },
    {
      hidden: !authAccessMap.module.Learning,
      icon: 'learning',
      label: 'Learning',
      to: '/learning',
    },
    {
      hidden: !authAccessMap.module.Finance,
      icon: 'finance',
      label: 'Finance',
      premium: authAccessMap.premiumModal.Finance,
      to: '/management/finance',
    },
    {
      hidden: !authAccessMap.module.DailyDiary,
      icon: 'daily-diary',
      label: 'Diary',
      to: '/daily-diary',
    },
    {
      hidden: !authAccessMap.module.Staff.StaffLeave
        && !authAccessMap.module.Staff.StaffList
        && !authAccessMap.module.Staff.StaffRegister
        && !authAccessMap.module.Staff.StaffRota
        && !authAccessMap.module.Staff.StaffWorklog,
      icon: 'staff',
      label: 'Staff',
      to: generateRoute('STAFF.INDEX'),
    },
    {
      hidden: !authAccessMap.module.RoomsWrapper,
      icon: 'rooms',
      label: 'Rooms',
      to: '/rooms/list',
    },
    {
      hidden: !authAccessMap.module.Reports,
      icon: 'reports',
      label: i18n.t('module:Reports:title'),
      premium: authAccessMap.premiumModal.Reports,
      to: '/reports',
    },
    {
      hidden: !authAccessMap.module.Newsletters,
      icon: 'news',
      label: 'News',
      to: '/newsletters',
    },
    {
      hidden: !authAccessMap.module.Injury,
      icon: 'safeguarding',
      label: 'Safeguarding',
      premium: authAccessMap.premiumModal.Injury,
      to: '/safeguarding/injury',
    },
    {
      hidden: !authAccessMap.module.Occupancy,
      icon: 'occupancy',
      label: 'Occupancy',
      premium: authAccessMap.premiumModal.Occupancy,
      to: '/occupancy',
    },
    {
      hidden: !authAccessMap.module.Enquiries,
      icon: 'enquiries',
      label: i18n.t('module:Enquiries:title'),
      to: '/enquiries',
    },
    {
      hidden: !authAccessMap.module.NurseryFiles,
      icon: 'attach-files',
      label: i18n.t('module:NurseryFiles:title'),
      to: generateRoute('NURSERY_FILES.LIST'),
    },
    {
      hidden: !authAccessMap.module.Management,
      icon: 'settings',
      label: 'Settings',
      to: '/management',
    },
    {
      icon: 'profile',
      label: 'My Profile',
      to: generateRoute('ACCOUNT.INDEX'),
    },
  ]

  if (showSuperAdminMenu) {
    lists = [
      {
        dashboardType: true,
      },
      {
        hidden: !authAccessMap.module.Staff,
        icon: 'group',
        label: 'Administrators',
        to: '/staff/list',
      },
      {
        icon: 'nursery',
        label: 'Nurseries',
        to: '/nurseries',
      },
      {
        icon: 'nursery',
        label: 'Organisations',
        to: '/organisations',
      },
    ]
  }

  if (showOrganizationMenu) {
    lists = [
      {
        dashboardType: true,
      },
      {
        hidden: !authAccessMap.module.Staff.StaffList
          && !authAccessMap.module.OrganizationNurseryRota,
        icon: 'staff',
        label: 'Staff',
        to: generateRoute('STAFF.INDEX'),
      },
      {
        hidden: !authAccessMap.module.OrganizationChildren,
        icon: 'children',
        label: 'Children',
        to: '/organization/children',
      },
      {
        hidden: !authAccessMap.module.OrganizationReports,
        icon: 'reports',
        label: 'Reports',
        to: generateRoute('ORGANIZATION_REPORTS.INDEX'),
      },
      {
        hidden: !authAccessMap.module.Settings,
        icon: 'settings',
        label: 'Settings',
        to: generateRoute('SETTINGS.INDEX'),
      },
    ]
  }

  return _.filter(lists, ({ hidden, premium }) => !hidden || premium)
}
