import moment from 'moment'
import _ from 'lodash'

import React, { useCallback, useEffect, useState } from 'react'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { NEUTRAL_COLOURS } from 'constants/colors'
import { AVAILABLE_DAYS } from 'services/legacy/membershipRegisters/constants'
import { VIEW_MODE } from 'services/legacy/membershipsShifts/constants'

import { millisecondsToHoursAndMinutesString } from 'utils/date'

import { Icon, Table, Typography } from 'components'

import i18n from 'translations'

import { COLUMNS_WIDTHS, HOUR_WIDTH_CONSTANT } from '../../StaffRotaView'
import { StyledSectionContainer, StyledStaffingFlag } from './StaffingFlagsStyled'

const StaffingFlags = ({
  date,
  day,
  earliestTime,
  latestTime,
  statistics,
  viewMode,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [arrowIsHidden, setArrowIsHidden] = useState(false)

  const renderCountStaffingFlags = useCallback((dayIndex) => {
    const dateDay = _.isNumber(dayIndex) ? moment(date).add(dayIndex, 'day') : moment(day)
    const stats = statistics[dateDay.format(DEFAULT_DATE_FORMAT)]?.[0]
    const { staffingFlags } = stats || {}

    return staffingFlags?.length
  }, [date, day, statistics])

  useEffect(() => {
    let totalCountStaffingFlags = 0
    _.times(AVAILABLE_DAYS, (dayIndex) => {
      totalCountStaffingFlags += renderCountStaffingFlags(dayIndex)
    })

    if (!totalCountStaffingFlags) {
      setArrowIsHidden(true)
    }
  }, [renderCountStaffingFlags, statistics])

  const renderStaffingFlagsItemsContent = (items, label) => (
    items?.length ? (
      <div>
        {VIEW_MODE.WEEK === viewMode && (
          <Typography margin="0 0 3px" bold>
            {label}
          </Typography>
        )}
        {_.map(items, ({ endTime, startTime }) => (
          <Typography>
            {moment(startTime).utc().format('HH:mm')}
            {' - '}
            {moment(endTime).utc().format('HH:mm')}
            {VIEW_MODE.DAY === viewMode && (
              <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} margin="0 0 0 5px" variant="span" bold>
                {millisecondsToHoursAndMinutesString(endTime - startTime)}
              </Typography>
            )}
          </Typography>
        ))}
      </div>
    ) : null
  )

  const renderStaffingFlagsContent = (dayIndex) => {
    const dateDay = _.isNumber(dayIndex) ? moment(date).add(dayIndex, 'day') : moment(day)
    const stats = statistics[dateDay.format(DEFAULT_DATE_FORMAT)]?.[0] || {}
    const staffingFlags = _.groupBy(stats?.staffingFlags, 'type')

    return (
      <StyledSectionContainer dayMode={VIEW_MODE.DAY === viewMode}>
        {renderStaffingFlagsItemsContent(
          staffingFlags?.understaffed,
          i18n.t('module:Staff:StaffRota:understaffed'),
        )}
        {renderStaffingFlagsItemsContent(
          staffingFlags?.overstaffed,
          i18n.t('module:Staff:StaffRota:overstaffed'),
        )}
      </StyledSectionContainer>
    )
  }

  const renderDayHeader = () => {
    const stats = statistics[moment(day).format(DEFAULT_DATE_FORMAT)]?.[0] || {}
    const staffingFlags = _.groupBy(stats?.staffingFlags, 'type')

    const summaryUnderstaffed = _.sumBy(staffingFlags?.understaffed, ({ endTime, startTime }) => endTime - startTime)
    const summaryOverstaffed = _.sumBy(staffingFlags?.overstaffed, ({ endTime, startTime }) => endTime - startTime)

    return (
      <StyledSectionContainer dayMode={VIEW_MODE.DAY === viewMode}>
        {staffingFlags?.understaffed?.length ? (
          <Typography margin="0 0 3px" bold>
            {i18n.t('module:Staff:StaffRota:understaffed')}
            <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} margin="0 0 0 5px" variant="span" bold>
              {millisecondsToHoursAndMinutesString(summaryUnderstaffed)}
            </Typography>
          </Typography>
        ) : null}
        {staffingFlags?.overstaffed?.length ? (
          <Typography margin="0 0 3px" bold>
            {i18n.t('module:Staff:StaffRota:overstaffed')}
            <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} margin="0 0 0 5px" variant="span" bold>
              {millisecondsToHoursAndMinutesString(summaryOverstaffed)}
            </Typography>
          </Typography>
        ) : null}
      </StyledSectionContainer>
    )
  }

  if (!renderCountStaffingFlags() && VIEW_MODE.DAY === viewMode) {
    return null
  }

  return (
    <React.Fragment>
      <Table.Tr>
        <Table.Td
          background={NEUTRAL_COLOURS.GRAY_QUATERNARY}
          width={`${COLUMNS_WIDTHS.LEFT}px`}
          onClick={!arrowIsHidden ? () => setIsExpanded(!isExpanded) : null}
        >
          <StyledStaffingFlag arrowIsHidden={arrowIsHidden}>
            <Typography align="left">
              {i18n.t('module:Staff:StaffRota:staffingFlags')}
            </Typography>
            {!arrowIsHidden && (
              <Icon
                height={18}
                icon={isExpanded ? 'chevron-up' : 'chevron-down'}
              />
            )}
          </StyledStaffingFlag>
        </Table.Td>
        {VIEW_MODE.WEEK === viewMode && _.times(AVAILABLE_DAYS, (dayIndex) => (
          <Table.Td
            align="left"
            background={NEUTRAL_COLOURS.GRAY_QUATERNARY}
            key={dayIndex}
            width={`${COLUMNS_WIDTHS.RIGHT / AVAILABLE_DAYS}%`}
          >
            <Typography>
              {renderCountStaffingFlags(dayIndex)}
            </Typography>
          </Table.Td>
        ))}
        {VIEW_MODE.DAY === viewMode && (
          <Table.Td
            align="left"
            background={NEUTRAL_COLOURS.GRAY_QUATERNARY}
            width={`${((latestTime - earliestTime) + 1) * HOUR_WIDTH_CONSTANT}px`}
          >
            {renderDayHeader()}
          </Table.Td>
        )}
      </Table.Tr>
      {isExpanded && !arrowIsHidden && (
        <Table.Tr>
          <Table.Td background={NEUTRAL_COLOURS.GRAY_QUATERNARY} />
          {VIEW_MODE.WEEK === viewMode && _.times(AVAILABLE_DAYS, (dayIndex) => (
            <Table.Td
              align="left"
              background={NEUTRAL_COLOURS.GRAY_QUATERNARY}
              key={dayIndex}
              verticalAlign="top"
              width={`${COLUMNS_WIDTHS.RIGHT / AVAILABLE_DAYS}%`}
            >
              {renderStaffingFlagsContent(dayIndex)}
            </Table.Td>
          ))}
          {VIEW_MODE.DAY === viewMode && (
            <Table.Td
              align="left"
              background={NEUTRAL_COLOURS.GRAY_QUATERNARY}
              verticalAlign="top"
            >
              {renderStaffingFlagsContent()}
            </Table.Td>
          )}
        </Table.Tr>
      )}
    </React.Fragment>
  )
}

export default StaffingFlags
