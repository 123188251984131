import React from 'react'
import { Field } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { Banner, Form } from 'components'

import i18n from 'translations'

import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

interface FeesItemFormFieldsProps {
  isReadOnly: boolean
  showCost: boolean
}

export interface FeesItemFormProps {
  name?: string
  quantity?: number
  total?: number
  unitPrice?: number
}

const FeesItemFormFields = ({
  isReadOnly,
  showCost,
}: FeesItemFormFieldsProps): JSX.Element => (
  <React.Fragment>
    <Form.Row
      label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Description:label')}
      width={{ field: '400px' }}
      required
    >
      <Field
        component={Form.TextField}
        disabled={isReadOnly}
        name="name"
        placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Description:placeholder')}
        validate={isRequired}
      />
    </Form.Row>
    {showCost && (
      <Form.Row
        label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Cost:label')}
        width={{ field: '150px' }}
      >
        <Field
          component={Form.TextField}
          disabled={isReadOnly}
          name="unitPrice"
          placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Cost:placeholder')}
          type="number"
        />
      </Form.Row>
    )}
    {showCost && (
      <Form.Row
        label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Quantity:label')}
        width={{ field: '150px' }}
      >
        <Field
          component={Form.TextField}
          disabled={isReadOnly}
          name="quantity"
          placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Quantity:placeholder')}
          type="number"
        />
      </Form.Row>
    )}
    <Form.Row
      label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:TotalCost:label')}
      width={{ field: '150px' }}
      required
    >
      <SubdomainCurrencyProvider>
        {({ currencySymbol }) => (
          <Field
            component={Form.TextField}
            disabled={isReadOnly}
            name="total"
            placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:TotalCost:placeholder')}
            prefix={currencySymbol}
            prefixWidth="30px"
            type="number"
            validate={isRequired}
          />
        )}
      </SubdomainCurrencyProvider>
      {!showCost && !isReadOnly && (
        <Form.Row.Item>
          <Banner.Info>{i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:feesItemBanner')}</Banner.Info>
        </Form.Row.Item>
      )}
    </Form.Row>
  </React.Fragment>
)

export default FeesItemFormFields
