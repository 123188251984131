import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledTableWrapper = styled.div`
  display: grid;
  grid-template-columns: 170px auto;
  min-width: 400px;
  
  @media(max-width: 660px) {
    grid-template-columns: 100px auto;
  }
  
  ${({ hideCategories }) => hideCategories && `
    grid-template-columns: auto;
    
    @media(max-width: 660px) {
      grid-template-columns: auto;
    }
  `}
`

export const StyledLeftColumn = styled.div`
  background: ${getBrandingColor('primary-color')};
  color: ${NEUTRAL_COLOURS.WHITE};
  text-align: center;
`

export const StyledColumn = styled.div`
  border-right: 2px solid ${NEUTRAL_COLOURS.WHITE};
  min-width: 260px;
  
  ${({ center }) => center && `
    text-align: center;
  `}

  ${({ last }) => last && `
    border-right: 0;
  `}
`

export const StyledContentColumn = styled.div`
  background: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  display: grid;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  
  ${({ length }) => length && `
    grid-template-columns: repeat(${length}, 1fr);
  `}
  
  ${({ hideScroll }) => hideScroll && `
    overflow: hidden;
  `}
  
  ${StyledColumn}:nth-last-of-type(1) {
    border-right: 0;
  }
`

export const StyledRow = styled.div`
  padding: 15px;
  border-bottom: 2px solid ${NEUTRAL_COLOURS.WHITE};
  
  ${({ header }) => header && `
    padding: 0 15px;
    display: flex;
    align-items: center;
    min-height: 60px;
  `}
`

export const StyledItem = styled.div`
  transition: .2s;
  padding: 10px;
  margin: -10px -10px 20px -10px;

  ${({ isLast }) => isLast && `
    margin-bottom: -10px;
  `}

  ${({ isEditable }) => isEditable && `
    cursor: pointer;
    
    &:hover {
      background: ${NEUTRAL_COLOURS.GRAY_TERTIARY};
    }
  `}
`

export const StyledItemDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
  align-items: center;
  padding-top: 10px;
`

export const StyledCustomScrollbarWrapper = styled.div`
  position: fixed;
  height: 16px;
  bottom: 0;
  overflow-x: scroll;
  
  ${({ isVisible }) => isVisible && `
    opacity: 0;
  `}
  
  ${({ width }) => width && `
    width: ${width}px;
  `}
`

export const StyledImitationContentWidth = styled.div`
  height: 1px;

  ${({ width }) => width && `
    width: ${width}px;
  `}
`

export const StyledEndOfContent = styled.div`
  position: absolute;
  bottom: 20px;
  opacity: 0;
  height: 10px;
  width: 100%;
  z-index: 100;
`
