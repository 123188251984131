import _ from 'lodash'

import { createSelector } from 'reselect'

import { sortDiaryTimelineRecords } from 'services/legacy/childDailyDiary/selectors'

const getDailyDiarySelector = (state) => state.newDailyDiary

export const getMyDailyDiarySelector = createSelector([getDailyDiarySelector], (state) => state.myDailyDiary)

export const getMyDailyDiaryDataSelector = createSelector(
  [getMyDailyDiarySelector],
  (state) => {
    if (!state || !state.data) {
      return null
    }

    const { child, records } = state.data

    const parsedRecords = _.map(records, (item) => ({
      ...item,
      record: {
        ...item.record,
        child,
      },
    }))

    return sortDiaryTimelineRecords(parsedRecords)
  },
)

export const getMyDailyDiaryChildDataSelector = createSelector(
  [getMyDailyDiarySelector],
  (state) => {
    if (!state?.data) {
      return null
    }

    const { child } = state.data

    return child
  },
)
