import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import {
  CLEAR_LIST_ENQUIRY_SETTINGS,
  LIST_ENQUIRY_SETTINGS,
  LIST_ENQUIRY_SETTINGS_FAILED,
  LIST_ENQUIRY_SETTINGS_SUCCESS,
} from './constants'

export default (...props) => {
  const [state = getBasicListInitialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_LIST_ENQUIRY_SETTINGS,
    failed: LIST_ENQUIRY_SETTINGS_FAILED,
    init: LIST_ENQUIRY_SETTINGS,
    success: LIST_ENQUIRY_SETTINGS_SUCCESS,
  }, getBasicListInitialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
