import React from 'react'

import { Banner, Button, Page, Section, Spinner } from 'components'

import i18n from 'translations'

import InjuryBasicForm from '../components/InjuryBasicForm'
import HomeAccidentForm from '../components/HomeAccidentForm'
import IncidentView from '../components/IncidentView'
import InjuryStickyFooter from './components/InjuryStickyFooter'
import { StyledActionButtonContainer } from './LogInjuryStyled'

const LogInjuryView = ({
  activeChildIndex,
  authAccessMap,
  basicFormInitialValues,
  childId,
  childrenFormInitialValues,
  childrenList,
  defaultBodyMapCoordinates,
  formValues,
  injuryPreview,
  injuryTypeOptions,
  isChangedCoordinate,
  isEditMode,
  isGrantedEdit,
  isGrantedSaveAndRequestForApproval,
  isGrantedSaveAsDraft,
  isHideBodyMap,
  isHomeAccidents,
  isLoading,
  isRemovedInProgress,
  isSubmittingAsApproved,
  isSubmittingAsDraft,
  onCancelClick,
  onChangeMedia,
  onClickCoordinates,
  onClickHideBodyMap,
  onClickSlide,
  onRemoveChild,
  onRemoveInjury,
  onSelectChild,
  onSubmitAsApproved,
  onSubmitAsDraft,
  onSubmitAsPendingApproval,
  selectedChildren,
  updateSlider,
}) => {
  if (isLoading || isRemovedInProgress) {
    return (
      <Spinner />
    )
  }

  const actions = isEditMode && (
    <Section.Actions
      options={[{
        onClick: onRemoveInjury,
        type: 'remove',
      }]}
    />
  )
  const editTitle = isHomeAccidents
    ? i18n.t('module:Safeguarding:Add:editTitle')
    : i18n.t('module:Injury:Add:editTitle', { type: injuryPreview?.type?.toLowerCase() })
  const addTitle = isHomeAccidents ? i18n.t('module:Safeguarding:Add:addTitle') : i18n.t('module:Injury:Add:addTitle')

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title={isEditMode ? editTitle : addTitle}
    >
      {injuryPreview.hasSignatures && (
        <Banner.Warning>
          {i18n.t('module:Injury:Add:removeSignatureChildContactWarning')}
        </Banner.Warning>
      )}
      {!isHomeAccidents && (
        <React.Fragment>
          <StyledActionButtonContainer textAlign={selectedChildren.length ? 'end' : 'start'}>
            <Button.ActionButton
              label={i18n.t('module:Injury:Add:addChildrenButtonLabel')}
              onClick={onSelectChild}
            />
          </StyledActionButtonContainer>
          <IncidentView
            activeChildIndex={activeChildIndex}
            childrenList={childrenList}
            formValues={formValues}
            initialValues={childrenFormInitialValues}
            injuryTypeOptions={injuryTypeOptions}
            isChangedCoordinate={isChangedCoordinate}
            isEditMode={isEditMode}
            isHideBodyMap={isHideBodyMap}
            selectedChildren={selectedChildren}
            updateSlider={updateSlider}
            onChangeMedia={onChangeMedia}
            onClickCoordinates={onClickCoordinates}
            onClickHideBodyMap={onClickHideBodyMap}
            onClickSlide={onClickSlide}
            onRemoveChild={onRemoveChild}
          />
          <InjuryBasicForm
            initialValues={basicFormInitialValues}
            onSubmit={() => {}}
          />
        </React.Fragment>
      )}
      {isHomeAccidents && (
        <HomeAccidentForm
          childId={childId}
          defaultBodyMapCoordinates={defaultBodyMapCoordinates}
          initialValues={basicFormInitialValues}
          injuryTypeOptions={injuryTypeOptions}
          isChangedCoordinate={isChangedCoordinate}
          isEditMode={isEditMode}
          isHideBodyMap={isHideBodyMap}
          onChangeMedia={onChangeMedia}
          onClickCoordinates={onClickCoordinates}
          onClickHideBodyMap={onClickHideBodyMap}
          onSubmit={() => {}}
        />
      )}
      <InjuryStickyFooter
        authAccessMap={authAccessMap}
        injuryPreview={injuryPreview}
        isGrantedEdit={isGrantedEdit}
        isGrantedSaveAndRequestForApproval={isGrantedSaveAndRequestForApproval}
        isGrantedSaveAsDraft={isGrantedSaveAsDraft}
        isHomeAccidents={isHomeAccidents}
        isSubmittingAsApproved={isSubmittingAsApproved}
        isSubmittingAsDraft={isSubmittingAsDraft}
        onCancelClick={onCancelClick}
        onSubmitAsApproved={onSubmitAsApproved}
        onSubmitAsDraft={onSubmitAsDraft}
        onSubmitAsPendingApproval={onSubmitAsPendingApproval}
      />
    </Page.Section>
  )
}

export default LogInjuryView
