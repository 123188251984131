import _ from 'lodash'

import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { Avatar, Form, Typography } from 'components'

import i18n from 'translations'

export const FORM_NAME = 'FormativeReportsExportForm'

const FormativeReportsExportForm = ({
  child,
  handleSubmit,
  isSubmitting,
  labelsOptions,
  onCloseClick,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    {child && (
      <Form.Row>
        <Form.Row.Item>
          <Avatar
            initials={[child.firstName, child.surname]}
            src={child.photo}
            subTitle={`${child.ageMonths} ${_.upperFirst(i18n.t('global:months'))}`}
            title={(
              <Typography fontSize={14} primary>
                {`${child.firstName} ${child.surname}`}
              </Typography>
            )}
          />
        </Form.Row.Item>
      </Form.Row>
    )}
    <Form.Row
      label={i18n.t('module:Modals:FormativeReportsExportModal:Form:SelectFormativeReports:label')}
      verticalLabel
    >
      <Form.Row.FlexItem>
        <Field
          component={Form.Select}
          name="label"
          options={labelsOptions}
          placeholder={i18n.t('module:Modals:FormativeReportsExportModal:Form:SelectFormativeReports:placeholder')}
          searchable={false}
          validate={isRequired}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.Row
      label={i18n.t('module:Modals:ExportReportModal:User:Label:pdf')}
      verticalLabel
    >
      <Form.Row.FlexItem>
        <Field
          clearable={false}
          component={Form.InfiniteDropdowns.OrganizationMemberships}
          name="memberships"
          placeholder={i18n.t('module:Modals:ExportReportModal:User:placeholder')}
          validate={isRequired}
          multi
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Typography margin="20px 0">
      {i18n.t('module:Modals:FormativeReportsExportModal:Form:note')}
    </Typography>
    <Form.FooterActions
      isSubmitting={isSubmitting}
      submitLabel={i18n.t('global:Export')}
      onCancelClick={onCloseClick}
    />
  </Form>
)

const validate = (values) => {
  const errors = {}

  if (values) {
    const { allFormativeReports, FormativeReportsDateRange = [] } = values
    const [before, after] = FormativeReportsDateRange

    if (!allFormativeReports && (!before || !after)) {
      errors.FormativeReportsDateRange = i18n.t('module:Modals:FormativeReportsExportModal:Form:validationMessage')
    }
  }

  return errors
}

export default reduxForm({ form: FORM_NAME, validate })(FormativeReportsExportForm)
