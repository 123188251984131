import React from 'react'

import { Table } from 'components'

import { getRegularUnavailableTimesTableColumns } from '../UnavailableTimesHelpers'

const RegularUnavailableTimesTable = ({ data, openingDays }) => (
  <Table
    columns={getRegularUnavailableTimesTableColumns(openingDays)}
    data={data}
    visualType="transparentWithVerticalBorders"
  />
)

export default RegularUnavailableTimesTable
