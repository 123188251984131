import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import * as agedDebtSelectors from './selectors'
import actions from './actions'

const withAgedDebtService = (WrappedComponent) => {
  const mapState = ({ agedDebt }) => ({ agedDebt })

  const mapDispatch = injectActions('agedDebtActions', actions)

  const Component = ({ agedDebt, agedDebtActions, ...other }) => (
    <WrappedComponent
      agedDebtActions={agedDebtActions}
      agedDebtListChildrenState={agedDebt.listChildren}
      agedDebtSelectors={agedDebtSelectors}
      agedDebtStatisticsState={agedDebt.statistics}
      {...other}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withAgedDebtService
