import moment from 'moment'
import _ from 'lodash'

import React, { Component } from 'react'
import { flatten } from 'utils/flatnest'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import { FRAMEWORK_STRUCTURE_APPLICABLE } from 'services/legacy/frameworks/constants'
import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { withPaginationUtils } from 'services/utils/pagination'
import { withAssessmentTrackerService } from 'services/legacy/assessmentTracker'
import { withFrameworksService } from 'services/legacy/frameworks'
import { withSecurityService } from 'services/security'
import { withAppService } from 'services/app'

import { getFilteredFrameworkListData, getFilteredFrameworkRawListData } from 'services/legacy/frameworks/selectors'
import ObservationTrackerView from './ObservationTrackerView'

export const FRAMEWORK_LIST_GROUPS = {
  read: [
    'framework.default',
    'structure',
  ],
}

export const ASSESSMENT_TRACKER_GROUPS = {
  read: [
    'assessmentTracker',
    'assessmentTracker.child',
    'child-legacy-mobile',
    'child.basic',
    'assessmentTracker.assessments',
    'assessmentTrackerCounter',
    'assessmentTrackerCounter.type',
  ],
}

const FRAMEWORK_ITEM_GROUPS = {
  read: [
    'framework.areas',
    'frameworkArea',
    'frameworkArea.groups',
    'frameworkGroup',
    'frameworkGroup.categories',
    'frameworkCategory',
    'frameworkCategory.items',
    'frameworkItem',
    'structure',
    'structure.observationLinks',
  ],
}

class ObservationTrackerContainer extends Component {
  constructor(props) {
    super(props)

    const {
      location: { query: { dateFrom, dateTo, framework, keyWorker, nurseryClass, search } },
      paginationUtils: { setPageLocationQuery },
    } = props

    this.state = {
      filters: {
        dateFrom: (dateFrom && moment(dateFrom).isValid())
          ? moment(dateFrom, DEFAULT_DATE_FORMAT)
          : moment().startOf('month'),
        dateTo: (dateTo && moment(dateTo).isValid())
          ? moment(dateTo, DEFAULT_DATE_FORMAT)
          : moment().endOf('month'),
        framework: +framework || null,
        keyWorker: +keyWorker || props.currentUser.id || null,
        nurseryClass: +nurseryClass || null,
        search: search || null,
      },
    }

    setPageLocationQuery(false)
  }

  componentDidMount() {
    const { frameworksActions } = this.props

    frameworksActions.list({
      onSuccess: this.handleGetFrameworksListSuccess,
      params: [{
        groups: FRAMEWORK_LIST_GROUPS,
      }],
    })
  }

  fetch = () => {
    const { assessmentTrackerActions, assessmentTrackerSelectors, paginationUtils, setLocationQuery } = this.props
    const { filters } = this.state
    const { page } = paginationUtils

    setLocationQuery({
      ...flatten(filters),
      dateFrom: filters?.dateFrom ? moment(filters.dateFrom).format(DEFAULT_DATE_FORMAT) : null,
      dateTo: filters?.dateTo ? moment(filters.dateTo).format(DEFAULT_DATE_FORMAT) : null,
    })

    const criteria = assessmentTrackerSelectors.getListCriteria(filters)

    assessmentTrackerActions.list({
      mergeResult: 1 !== page,
      params: [{
        criteria,
        groups: ASSESSMENT_TRACKER_GROUPS,
        page,
      }],
    })
  }

  handleGetFrameworksListSuccess = ({ data }) => {
    const { filters } = this.state

    const filteredFrameworkRawListData = getFilteredFrameworkRawListData(
      FRAMEWORK_STRUCTURE_APPLICABLE.OBSERVATION_TRACKER,
      data,
    )
    const filteredFrameworkListData = getFilteredFrameworkListData(filteredFrameworkRawListData)

    if (!filteredFrameworkListData.length) {
      return false
    }

    const defaultFramework = _.find(filteredFrameworkListData, 'defaultFramework')?.value

    if (!filters.framework) {
      return this.handleFrameworkChange({ value: defaultFramework || filteredFrameworkListData[0].value })
    }

    this.getFrameworkDetails()

    return this.fetch()
  }

  handleDateChange = (date) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        dateFrom: date[0],
        dateTo: date[1],
      },
    }), () => onPageChange(this.fetch)(1))
  }

  getFrameworkDetails = () => {
    const { frameworksActions } = this.props
    const { filters: { framework } } = this.state

    return frameworksActions.get({
      params: [framework, {
        groups: FRAMEWORK_ITEM_GROUPS,
      }],
    })
  }

  handleFrameworkChange = (framework) => {
    const { paginationUtils: { onPageChange } } = this.props

    this.setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        framework: framework?.value,
      },
    }), () => {
      this.getFrameworkDetails()

      return onPageChange(this.fetch)(1)
    })
  }

  handleNurseryClassChange = (nurseryClass) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        nurseryClass: nurseryClass?.value,
      },
    }), () => onPageChange(this.fetch)(1))
  }

  handleSearchPhase = (search) => {
    const { paginationUtils: { onPageChange } } = this.props

    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        search,
      },
    }), () => onPageChange(this.fetch)(1))
  }

  handleChangePage = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleKeyWorkerChange = (keyWorker) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        keyWorker: keyWorker?.value,
      },
    }), () => onPageChange(this.fetch)(1))
  }

  render() {
    const {
      frameworkDetails,
      frameworksList,
      isFetching,
      isFetchingFrameworksList,
      paginationUtils,
      records,
      totalResults,
    } = this.props
    const { filters } = this.state
    const { getPageCount, page } = paginationUtils
    const pageCount = getPageCount(totalResults)

    return (
      <ObservationTrackerView
        filters={filters}
        frameworkDetails={frameworkDetails}
        frameworksList={frameworksList}
        isFetching={isFetching}
        isFetchingFrameworksList={isFetchingFrameworksList}
        page={page}
        pageCount={pageCount}
        records={records}
        onChangePage={this.handleChangePage}
        onDateChange={this.handleDateChange}
        onFrameworkChange={this.handleFrameworkChange}
        onKeyWorkerChange={this.handleKeyWorkerChange}
        onNurseryClassChange={this.handleNurseryClassChange}
        onRefreshClick={this.fetch}
        onSearchPhase={this.handleSearchPhase}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  assessmentTrackerListState,
  assessmentTrackerSelectors,
  frameworksListState,
  frameworksSelectors,
  frameworksSingleState,
  securitySelectors,
}) => ({
  currentUser: securitySelectors.getAuthUser(state),
  frameworkDetails: frameworksSelectors.getFrameworksSingleDataState(state),
  frameworksList: frameworksSelectors.getFilteredFrameworksList(
    FRAMEWORK_STRUCTURE_APPLICABLE.OBSERVATION_TRACKER,
  )(state),
  isFetching: appSelectors.getIsFetching(assessmentTrackerListState, frameworksSingleState),
  isFetchingFrameworksList: appSelectors.getIsFetching(frameworksListState),
  records: assessmentTrackerSelectors.getAssessmentTrackerListDataState(state),
  totalResults: appSelectors.getTotalResults(assessmentTrackerListState),
})

const enhance = compose(
  withAppService,
  withAssessmentTrackerService,
  withFrameworksService,
  withPaginationUtils,
  withSecurityService,
  connect(mapState),
)

export default enhance(ObservationTrackerContainer)
