import _ from 'lodash'

import { createSelector } from 'reselect'

import { FRAMEWORK_TYPE_MONTESSORI } from 'services/legacy/frameworks/constants'

const getChildObservationSingleState = (state) => state.childObservations.single

export const getChildObservationsSingleStateDataSelector = createSelector(
  [getChildObservationSingleState],
  (state) => state.data,
)

export const getBody = (values) => {
  const { comments, montessoriActivityState, sharedWithParents } = values || {}
  const body = {
    comments: comments || null,
  }

  if (montessoriActivityState?.value) {
    body.montessoriActivityState = montessoriActivityState.value
  } else {
    body.montessoriActivityState = null
  }

  if (_.isBoolean(sharedWithParents)) {
    body.sharedWithParents = sharedWithParents
  }

  return body
}

export const getObservationNextStepsInitialValues = createSelector(
  [getChildObservationsSingleStateDataSelector],
  (childObservation) => {
    const { nextMontessoriActivities, nextSteps } = childObservation || {}

    if (!nextSteps?.length && !nextMontessoriActivities?.length) {
      return {
        nextSteps: [{}],
      }
    }

    const result = {
      nextSteps: [],
    }

    if (nextSteps?.length) {
      result.nextSteps = _.map(nextSteps, (item) => {
        const framework = item.framework || {}

        return ({
          comments: item.comments,
          framework: {
            label: framework.name,
            value: framework.id,
          },
          frameworkAreas: _.map(item.frameworkAreas, ({ id, name }) => ({
            label: name,
            value: id,
          })),
          id: item.id,
        })
      })
    }

    if (nextMontessoriActivities?.length) {
      result.nextSteps.push({
        framework: {
          value: FRAMEWORK_TYPE_MONTESSORI,
        },
        frameworkAreas: _.map(nextMontessoriActivities, ({ montessoriActivity: { id, name } }) => ({
          label: name,
          value: id,
        })),
      })
    }

    return result
  },
)
