import moment from 'moment'
import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { initialize, stopSubmit } from 'redux-form'

import { RESPONSE } from 'constants/http'
import { FOOD_AMOUNT, FOOD_AMOUNT_DROPDOWN, FOOD_CATEGORY } from 'services/legacy/foodMenu/constants'
import { DAILY_DIARY_DATE_FORMAT, DAILY_DIARY_LIST_GROUPS } from 'services/legacy/dailyDiary/constants'
import {
  DIETARY_EATS_MEAT,
  DIETARY_NO_PREFERENCE,
  DIETARY_OTHER,
  DIETARY_VEGETARIAN,
} from 'services/legacy/child/constants'

import { EVENTS, logEvent } from 'analytics'

import { generateRoute } from 'utils/routing'
import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withFoodMenuService } from 'services/legacy/foodMenu'
import { withModalService } from 'services/utils/modal'
import { withDailyDiaryFoodsService } from 'services/legacy/dailyDiaryFoods'
import { withRegisterService } from 'services/legacy/register'
import { withPaginationUtils } from 'services/utils/pagination'
import { withSnackbarService } from 'services/utils/snackbar'
import { withRouterUtils } from 'services/utils/router'
import { withRouter } from 'services/router'

import i18n from 'translations'

import FoodsView from './FoodsView'
import { FOOD_RECORD_EDIT_FORM } from './components/FoodRecord/FoodRecord'

const LIST_LIMIT = 10
const FOOD_MENU_INCLUDES = ['items', 'items.foodType']

class FoodsContainer extends Component {
  constructor(props) {
    super(props)

    const {
      paginationUtils: { setPageLocationQuery },
    } = props

    this.state = {
      foodMenuIsProgress: true,
      savedMenu: {},
      type: null,
    }

    setPageLocationQuery(false)
  }

  componentDidMount() {
    const { paginationUtils } = this.props
    const { type } = this.state
    const { setPerPage } = paginationUtils
    const category = this.getCurrentCategory()

    logEvent(EVENTS.DIARY_PAGE_VIEWED, { category, context: 'food', type })

    setPerPage(LIST_LIMIT)
    this.fetchFoodMenu(this.props)
  }

  shouldComponentUpdate(nextProps) {
    const { location: { query: nextQuery } } = nextProps
    const { location: { query } } = this.props

    if (
      query.date !== nextQuery.date
      || query.room !== nextQuery.room
      || query.timeType !== nextQuery.timeType
    ) {
      this.fetch(nextQuery)
      this.fetchFoodMenu(nextProps)
    }

    return true
  }

  componentWillUnmount() {
    const { dailyDiaryFoodsActions, foodMenuActions } = this.props

    foodMenuActions.clearList()
    dailyDiaryFoodsActions.clearList()
  }

  fetch = (nextQuery, silent, withoutSuccessCallback) => {
    const {
      dailyDiaryFoodsActions,
      dailyDiaryFoodsSelectors,
      location: { query },
      paginationUtils,
      setLocationQuery,
    } = this.props
    const { type } = this.state
    const { page } = paginationUtils
    const { date, room, timeType } = (nextQuery || query)

    const criteria = dailyDiaryFoodsSelectors.getListCriteria({
      ...(nextQuery || query),
      type,
    })

    setLocationQuery({ date, room, timeType })

    dailyDiaryFoodsActions.list({
      mergeResult: 1 !== page,
      onSuccess: (response) => !withoutSuccessCallback && this.handleUpdateChildRecords(null, true, response?.data),
      params: {
        criteria,
        groups: {
          read: [
            ...DAILY_DIARY_LIST_GROUPS.read,
            'child',
            'childInformation',
            'childRegisterDiaryRecordsResult.diaryRecords.items.food',
          ],
        },
        limit: LIST_LIMIT,
        page,
      },
      silent,
    })
  }

  fetchFoodMenu(props) {
    const {
      foodMenuActions,
      foodMenuSelectors,
      location: { query: { date } },
    } = props

    const criteria = foodMenuSelectors.getListCriteria({ date })

    foodMenuActions.list({
      onSuccess: () => {
        this.setState({
          foodMenuIsProgress: false,
        })
      },
      params: {
        criteria,
        includes: FOOD_MENU_INCLUDES,
        limit: 1,
      },
    })
  }

  handleUpdateRecordSuccess = (callback, isUpdate) => {
    const { snackbarActions } = this.props
    const { type } = this.state
    const category = this.getCurrentCategory()

    if (callback) {
      callback()
    }

    const eventName = isUpdate ? EVENTS.DIARY_ITEM_UPDATED : EVENTS.DIARY_ITEM_ADDED

    logEvent(eventName, { category, context: 'food', type })

    snackbarActions.show({
      message: i18n.t('module:DailyDiary:Foods:updatedSuccess', {
        name: this.getCurrentCategory() === FOOD_CATEGORY.MEAL.value
          ? i18n.t('module:DailyDiary:Foods:Meal')
          : i18n.t('module:DailyDiary:Foods:Snack'),
      }),
    })
  }

  handleUpdateRecordFailed = (response, record, callback) => {
    const { injectValidation } = this.props
    const { childRegister: { child } } = record
    const { id: childId } = child
    const errors = getBackendErrors(response)

    if (RESPONSE.HTTP_409_CONFLICT === response?.code) {
      this.fetch(null, true)

      return callback(true, response)
    }

    if (!errors) {
      return false
    }

    callback(true)

    return injectValidation(this.getFormName({ childId }), errors)
  }

  handleResetRecordSuccess = () => {
    const { snackbarActions } = this.props

    logEvent(EVENTS.DIARY_RESET_CONFIRMED, { context: 'foods' })

    snackbarActions.show({
      message: i18n.t('module:DailyDiary:Foods:recordReset'),
    })
  }

  handleResetRecord = (record) => {
    const { dailyDiaryFoodsActions } = this.props
    const { diaryRecords } = record

    const [{ id }] = diaryRecords

    logEvent(EVENTS.DIARY_RESET_BTN_CLICKED, { context: 'foods' })

    return dailyDiaryFoodsActions.removeRecord({
      id,
      onSuccess: this.handleResetRecordSuccess,
    })
  }

  handleUpdateRecord = (record, values, callback) => {
    const { dailyDiaryFoodsActions, dailyDiaryFoodsSelectors } = this.props
    const { type } = this.state
    const { childRegister: { child }, diaryRecords } = record
    const { id: childId } = child
    const category = this.getCurrentCategory()
    const body = dailyDiaryFoodsSelectors.getValuesForm({
      ...values,
      child: childId,
      type,
    })

    if (!diaryRecords?.length) {
      logEvent(EVENTS.DIARY_ADD_ITEM_BTN_CLICKED, { category, context: 'food', type })

      return dailyDiaryFoodsActions.addRecord(
        body,
        () => this.handleUpdateRecordSuccess(callback),
        (response) => this.handleUpdateRecordFailed(response, record, callback),
      )
    }

    const [{ id }] = diaryRecords

    logEvent(EVENTS.DIARY_EDIT_ITEM_BTN_CLICKED, { category, context: 'food', type })

    return dailyDiaryFoodsActions.updateRecord(
      id,
      body,
      () => this.handleUpdateRecordSuccess(callback, true),
      (response) => this.handleUpdateRecordFailed(response, record, callback),
    )
  }

  handleChangeSession = (session) => {
    const { paginationUtils } = this.props
    const { type } = this.state
    const { onPageChange } = paginationUtils

    const currentSession = FOOD_CATEGORY[this.getCurrentCategory()].sessions[session]

    if (type !== currentSession.value) {
      this.setState(
        { type: currentSession.value },
        () => onPageChange(() => this.fetch(null, false, true))(1),
      )
    }
  }

  getFormName = ({ childId }) => {
    const { location: { query } } = this.props
    const { date = moment().format(DAILY_DIARY_DATE_FORMAT) } = query
    const { type } = this.state

    return `${FOOD_RECORD_EDIT_FORM}_${date}_${type}_${childId}`
  }

  handleUpdateChildRecords = (values, fromState, newRecords = []) => {
    const { initializeValues, location: { query }, records } = this.props
    const { date = moment().format(DAILY_DIARY_DATE_FORMAT) } = query
    const { savedMenu, type } = this.state

    if (fromState && !savedMenu[date]?.[type]) {
      return null
    }

    const { MEATS, SNACKS, VEGGIES, date: stateDate } = (fromState ? savedMenu[date][type] : values)

    if (!fromState) {
      this.setState({
        savedMenu: {
          [date]: {
            ...savedMenu[date],
            [type]: values,
          },
        },
      })
    }

    const finalRecords = [...records, ...newRecords]

    return _.each(finalRecords, (record) => {
      const { childRegister: { child }, diaryRecords } = record || {}
      const { id: childId, information } = child
      const { dietaryReq } = information

      if (diaryRecords?.length || !dietaryReq) {
        return null
      }

      const formName = this.getFormName({ childId })
      const amount = _.find(FOOD_AMOUNT_DROPDOWN, ({ value }) => FOOD_AMOUNT.ALL === value)
      const items = []

      if (
        this.getCurrentCategory() === FOOD_CATEGORY.MEAL.value
        && [DIETARY_EATS_MEAT, DIETARY_NO_PREFERENCE, DIETARY_OTHER].includes(dietaryReq)
      ) {
        _.each(MEATS, ({ meal }) => {
          items.push({
            amount,
            food: meal,
          })
        })
      }

      if (this.getCurrentCategory() === FOOD_CATEGORY.MEAL.value && DIETARY_VEGETARIAN === dietaryReq) {
        _.each(VEGGIES, ({ meal }) => {
          items.push({
            amount,
            food: meal,
          })
        })
      }

      if (this.getCurrentCategory() === FOOD_CATEGORY.SNACK.value) {
        _.each(SNACKS, ({ meal }) => {
          items.push({
            amount,
            food: meal,
          })
        })
      }

      if (!items.length) {
        items.push({
          amount,
        })
      }

      return initializeValues(formName, {
        items,
        recordedAt: moment(stateDate, DAILY_DIARY_DATE_FORMAT),
      })
    })
  }

  getCurrentCategory = () => {
    const { location: { pathname } } = this.props

    if (generateRoute('DAILY_DIARY.MEALS') === pathname) {
      return FOOD_CATEGORY.MEAL.value
    }

    return FOOD_CATEGORY.SNACK.value
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(() => this.fetch(null, true, false))(page)
  }

  handleReinitializeFormValues = ({ formName, initialValues }) => {
    const { initializeValues } = this.props

    initializeValues(formName, initialValues)
  }

  render() {
    const {
      isFetching,
      isFetchingContent,
      isOffline,
      location: { query },
      paginationUtils,
      records,
      registers,
      totalResults,
    } = this.props
    const { foodMenuIsProgress, type } = this.state
    const { getPageCount, page } = paginationUtils
    const { date = moment().format(DAILY_DIARY_DATE_FORMAT) } = query
    const pageCount = getPageCount(totalResults)

    return (
      <FoodsView
        category={this.getCurrentCategory()}
        date={date}
        foodMenuIsProgress={foodMenuIsProgress}
        getFormName={this.getFormName}
        isFetching={isFetching}
        isFetchingContent={isFetchingContent}
        isOffline={isOffline}
        page={page}
        pageCount={pageCount}
        records={records}
        registers={registers}
        totalResults={totalResults}
        type={type}
        onChangeSession={this.handleChangeSession}
        onLoadMoreFoods={this.handleLoadMoreFoods}
        onPageChange={this.handlePageChange}
        onReinitializeFormValues={this.handleReinitializeFormValues}
        onResetRecord={this.handleResetRecord}
        onUpdateChildRecords={this.handleUpdateChildRecords}
        onUpdateRecord={this.handleUpdateRecord}
      />
    )
  }
}

const mapDispatch = {
  initializeValues: (formName, values) => initialize(formName, values),
  injectValidation: (formName, errors) => stopSubmit(formName, errors),
}

const mapState = (state, {
  appSelectors,
  dailyDiaryFoodsListState,
  dailyDiaryFoodsSelectors,
  registerSelectors,
  registerState,
}) => ({
  isFetching: appSelectors.getIsFetching(
    registerState,
  ),
  isFetchingContent: appSelectors.getIsFetching(
    dailyDiaryFoodsListState,
  ),
  isOffline: appSelectors.getAppIsOffline(state),
  records: dailyDiaryFoodsSelectors.getDailyDiaryFoodsListData(state),
  registers: registerSelectors.getRegisterDataSelector(state),
  totalResults: appSelectors.getTotalResults(dailyDiaryFoodsListState),
})

const enhance = compose(
  withAppService,
  withDailyDiaryFoodsService,
  withPaginationUtils,
  withFoodMenuService,
  withModalService,
  withRegisterService,
  withRouterUtils,
  withSnackbarService,
  withRouter,
  connect(mapState, mapDispatch),
)

export default enhance(FoodsContainer)
