import {
  CLEAR_FUNDING_TYPES,
  LIST_FUNDING_TYPES,
  LIST_FUNDING_TYPES_FAILED,
  LIST_FUNDING_TYPES_SUCCESS,
} from './actions'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case LIST_FUNDING_TYPES:
      return { ...state, error: initialState.error, isFetching: true }
    case LIST_FUNDING_TYPES_SUCCESS:
      return { ...state, data: payload.data, isFetching: false }
    case LIST_FUNDING_TYPES_FAILED:
      return { ...state, error, isFetching: false }
    case CLEAR_FUNDING_TYPES:
      return initialState
    default:
      return state
  }
}
