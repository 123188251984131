import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { components } from 'react-select'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { withAppService } from 'services/app'
import { withSupportedCurrencyService } from 'services/legacy/supportedCurrency'

import { Select, Typography } from 'components'

import i18n from 'translations'

import withInfiniteDropdown from '../withInfiniteDropdown'
import { StyledOption } from './SupportedCurrencyDropdownStyled'

const SingleValue = (item) => {
  const { data } = item

  return (
    <components.SingleValue {...item}>
      <StyledOption>
        <Typography color={NEUTRAL_COLOURS.BASIC}>
          <Typography fontSize={15} variant="span" bold>
            {data.value}
          </Typography>
          {' - '}
          <Typography variant="span">
            {data.label}
          </Typography>
        </Typography>
        <Typography color={NEUTRAL_COLOURS.BASIC}>
          {data.symbol}
        </Typography>
      </StyledOption>
    </components.SingleValue>
  )
}

const Option = (item) => {
  const { data } = item

  return (
    <components.Option {...item}>
      <StyledOption>
        <Typography>
          <Typography fontSize={15} variant="span" bold>
            {data.value}
          </Typography>
          {' - '}
          <Typography variant="span">
            {data.label}
          </Typography>
        </Typography>
        <Typography>
          {data.symbol}
        </Typography>
      </StyledOption>
    </components.Option>
  )
}

class SupportedCurrencyDropdown extends Component {
  componentDidMount() {
    const { infiniteDropdownHelpers, supportedCurrencyActions, value } = this.props

    supportedCurrencyActions.list({
      onSuccess: (response) => {
        const newData = _.sortBy(
          _.map(response.data, ({ code, ...rest }) => ({
            ...rest,
            id: code,
          })),
          (item) => -item.frequent,
        )

        infiniteDropdownHelpers.handleLoadMoreElementsOnSuccess({
          newPage: 1,
          response: {
            data: newData,
          },
          result: {},
        })

        if (value) {
          let element = _.find(response.data, (item) => item.code === value)

          element = {
            ...element,
            id: element.code,
          }

          infiniteDropdownHelpers.handleComponentDidMountOnSuccess({ data: element })
        }
      },
    })
  }

  componentWillUnmount() {
    const { supportedCurrencyActions } = this.props

    supportedCurrencyActions.clear()
  }

  handleChange = (e) => {
    const { infiniteDropdownHelpers, onChange } = this.props

    infiniteDropdownHelpers.handleOnChange(e, () => onChange(e))
  }

  render() {
    const {
      disabled,
      infiniteDropdownState,
      isFetching,
      placeholder,
      rawValue,
      value,
      ...rest
    } = this.props
    const { cacheUniq, options, selectedValue } = infiniteDropdownState

    return (
      <Select
        {...rest}
        cacheUniq={cacheUniq}
        components={{
          Option,
          SingleValue,
        }}
        disabled={disabled || isFetching}
        options={_.orderBy(options, ({ frequent }) => frequent)}
        placeholder={placeholder || _.upperFirst(i18n.t('components:InfiniteDropdowns:SupportedCurrency:placeholder'))}
        rawValue={rawValue || selectedValue || value}
        value={value}
        searchable
        onChange={this.handleChange}
      />
    )
  }
}

const mapState = (state, { appSelectors, supportedCurrencyListState }) => ({
  isFetching: appSelectors.getIsFetching(supportedCurrencyListState),
})

const enhance = compose(
  withAppService,
  withSupportedCurrencyService,
  withInfiniteDropdown,
  connect(mapState),
)

export default enhance(SupportedCurrencyDropdown)
