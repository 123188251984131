import i18n from 'translations'

import { getDateString } from 'utils/date'

import { CHILD_EDIT_FORM_TYPE } from '../constants'

export const getGroups = (type) => {
  switch (type) {
    case CHILD_EDIT_FORM_TYPE.GENERAL_INFO:
      return {
        read: [
          'child.extend',
          'childInformation',
          'child.ethnicity',
          'ethnicity',
        ],
      }
    case CHILD_EDIT_FORM_TYPE.FAMILY_INFO:
    case CHILD_EDIT_FORM_TYPE.LIKES_DISLIKES_INFO:
      return {
        read: [
          'childProfile',
          'child.extend',
        ],
      }
    case CHILD_EDIT_FORM_TYPE.ENROLMENT_DETAILS:
      return {
        read: [
          'child.keyWorkers',
          'user',
          'child.nurseryClass',
          'nurseryClass',
          'childProfile',
          'child.extend',
        ],
      }
    case CHILD_EDIT_FORM_TYPE.DEVELOPMENT_INFO:
    case CHILD_EDIT_FORM_TYPE.HEALTH_AND_DIET_INFO:
      return {
        read: [
          'childProfile',
          'childInformation',
          'child.extend',
        ],
      }
    case CHILD_EDIT_FORM_TYPE.PERMISSIONS_INFO:
      return {
        read: [
          'childInformation',
          'child.extend',
        ],
      }
    default:
      return null
  }
}

export const getPayload = ({
  childSelectors,
  formValues,
  type,
}) => {
  switch (type) {
    case CHILD_EDIT_FORM_TYPE.GENERAL_INFO:
      return childSelectors.getGeneralInformationPayload(formValues)
    case CHILD_EDIT_FORM_TYPE.FAMILY_INFO:
      return childSelectors.getFamilyInformationPayload(formValues)
    case CHILD_EDIT_FORM_TYPE.LIKES_DISLIKES_INFO:
      return childSelectors.getLikesDislikesInformationPayload(formValues)
    case CHILD_EDIT_FORM_TYPE.ENROLMENT_DETAILS:
      return childSelectors.getEnrolmentDetailsPayload(formValues)
    case CHILD_EDIT_FORM_TYPE.DEVELOPMENT_INFO:
      return childSelectors.getDevelopmentInformationPayload(formValues)
    case CHILD_EDIT_FORM_TYPE.HEALTH_AND_DIET_INFO:
      return childSelectors.getHealthAndDietInformationPayload(formValues)
    case CHILD_EDIT_FORM_TYPE.PERMISSIONS_INFO:
      return childSelectors.getPermissionsInformationPayload(formValues)
    default:
      return null
  }
}

export const getInitialValues = ({
  childSelectors,
  data,
  languagesOptions,
  maritalStatusDropdownOptions,
  type,
}) => {
  switch (type) {
    case CHILD_EDIT_FORM_TYPE.GENERAL_INFO:
      return childSelectors.getGeneralInformationInitialValues({ data })
    case CHILD_EDIT_FORM_TYPE.FAMILY_INFO:
      return childSelectors.getFamilyInformationInitialValues({
        data,
        options: {
          languagesOptions,
          maritalStatusDropdownOptions,
        },
      })
    case CHILD_EDIT_FORM_TYPE.LIKES_DISLIKES_INFO:
      return childSelectors.getLikesDislikesInformationInitialValues({ data })
    case CHILD_EDIT_FORM_TYPE.ENROLMENT_DETAILS:
      return childSelectors.getEnrolmentDetailsInitialValues({ data })
    case CHILD_EDIT_FORM_TYPE.DEVELOPMENT_INFO:
      return childSelectors.getDevelopmentInformationInitialValues({ data })
    case CHILD_EDIT_FORM_TYPE.HEALTH_AND_DIET_INFO:
      return childSelectors.getHealthAndDietInformationInitialValues({ data })
    case CHILD_EDIT_FORM_TYPE.PERMISSIONS_INFO:
      return childSelectors.getPermissionsInformationInitialValues({ data })
    default:
      return null
  }
}

export const getTitle = (type) => {
  switch (type) {
    case CHILD_EDIT_FORM_TYPE.GENERAL_INFO:
      return i18n.t('module:Children:Child:Add:GeneralInformation:title')
    case CHILD_EDIT_FORM_TYPE.FAMILY_INFO:
      return i18n.t('module:Children:Child:Add:Family:title')
    case CHILD_EDIT_FORM_TYPE.LIKES_DISLIKES_INFO:
      return i18n.t('module:Children:Child:Add:LikesDislikes:title')
    case CHILD_EDIT_FORM_TYPE.ENROLMENT_DETAILS:
      return i18n.t('module:Children:Child:Add:EnrolmentDetails:title')
    case CHILD_EDIT_FORM_TYPE.DEVELOPMENT_INFO:
      return i18n.t('module:Children:Child:Add:Development:title')
    case CHILD_EDIT_FORM_TYPE.HEALTH_AND_DIET_INFO:
      return i18n.t('module:Children:Child:Add:HealthAndDiet:title')
    case CHILD_EDIT_FORM_TYPE.PERMISSIONS_INFO:
      return i18n.t('module:Children:Child:Add:Permissions:title')
    default:
      return null
  }
}

export const generatePopupMessage = (
  occupancyEnabled,
  hasChildLevelManualFunding,
  type,
  formInitialValues,
  formValues,
) => {
  const transKeyPrefix = 'module:Children:Child:Edit:ConfirmMessageBeforeSave'

  if (
    type === CHILD_EDIT_FORM_TYPE.GENERAL_INFO
    && occupancyEnabled
    && getDateString(formInitialValues?.birthDate) !== getDateString(formValues?.birthDate)
  ) {
    return i18n.t(`${transKeyPrefix}:GeneralInformation:BirthDate:occupancy`)
  }

  const isStartOrLeaveDateChanged = (
    getDateString(formInitialValues?.startDate) !== getDateString(formValues?.startDate)
  || getDateString(formInitialValues?.leavingDate) !== getDateString(formValues?.leavingDate)
  )

  if (isStartOrLeaveDateChanged && hasChildLevelManualFunding && occupancyEnabled) {
    return (
      <div>
        {i18n.t(`${transKeyPrefix}:NurseryInformation:StartOrLeavingDate:OccupancyFunding:copy1`)}
        <br />
        {i18n.t(`${transKeyPrefix}:NurseryInformation:StartOrLeavingDate:OccupancyFunding:copy2`)}
        <br />
        {i18n.t(`${transKeyPrefix}:NurseryInformation:StartOrLeavingDate:OccupancyFunding:copy3`)}
      </div>
    )
  }

  if (isStartOrLeaveDateChanged && hasChildLevelManualFunding) {
    return i18n.t(`${transKeyPrefix}:NurseryInformation:StartOrLeavingDate:funding`)
  }

  if (isStartOrLeaveDateChanged && occupancyEnabled) {
    return i18n.t(`${transKeyPrefix}:NurseryInformation:StartOrLeavingDate:occupancy`)
  }

  return null
}
