import _ from 'lodash'

import React from 'react'
import { compose } from 'recompose'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { withModalService } from 'services/utils/modal'

import { Button, Spinner, Typography } from 'components'

import i18n from 'translations'

import { StyledAction, StyledFieldWrapper, StyledRoomRow, StyledRoomsList } from './StaffAssignedToRoomsStyled'

const StaffAssignedToRooms = ({
  isFetching,
  membershipRoles,
  modalActions,
  modalConsts,
  onRoomListsReload,
  rooms,
  roomsIsFetching,
  sourceModule,
}) => {
  const handleEdit = (room) => {
    modalActions.show(modalConsts.TYPES.ASSIGN_STAFF_MEMBERS, {
      membershipRoles,
      onRoomListsReload,
      room,
      sourceModule,
    })
  }

  const renderRow = (room) => {
    const members = 'PractitionerAccess' === sourceModule
      ? room?.classMemberships
      : room?.messagingRecipients

    return (
      <StyledRoomRow key={`room_${room.id}`}>
        <StyledAction>
          <Typography bold>
            {room.name}
          </Typography>
          <Button
            hierarchy="tertiary"
            label={i18n.t('global:Edit')}
            size="small"
            negativeMargins
            onClick={() => handleEdit(room)}
          />
        </StyledAction>
        <div>
          {members?.length
            ? _.map(_.sortBy(members, ({ name }) => _.toLower(name)), ({ name }) => name).join(', ')
            : (
              <Typography color={NEUTRAL_COLOURS.GRAY}>
                {i18n.t('module:Management:General:Messaging:StaffAssignedToRooms:noStaffAssigned')}
              </Typography>
            )}
        </div>
      </StyledRoomRow>
    )
  }

  const renderRoomsList = () => {
    if (isFetching || roomsIsFetching) {
      return (
        <Spinner />
      )
    }

    if (!isFetching && !roomsIsFetching && !rooms?.length) {
      return (
        <Typography italic>
          {i18n.t('module:Management:General:Messaging:StaffAssignedToRooms:notFoundAnyRooms')}
        </Typography>
      )
    }

    return (
      <StyledRoomsList>
        {_.map(rooms, renderRow)}
      </StyledRoomsList>
    )
  }

  return (
    <StyledFieldWrapper>
      {renderRoomsList()}
    </StyledFieldWrapper>
  )
}

const enhance = compose(
  withModalService,
)

export default enhance(StaffAssignedToRooms)
