import { nest } from 'utils/flatnest'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withSubdomainService } from 'services/subdomain'
import { withEnquiriesService } from 'services/legacy/enquiries'
import { withRouter } from 'services/router'

import EnquiryPublicForm from './EnquiryPublicForm'

class EnquiryPublicFormContainer extends Component {
  constructor(props) {
    super(props)

    const { query } = props.location
    const { t } = nest(query)

    this.state = {
      recaptchaToken: '',
      token: t,
    }
  }

  componentDidMount() {
    const { nurseriesActions } = this.props
    const { token } = this.state

    nurseriesActions.getNurseryPublicSettings({
      params: [{ token }],
    })
  }

  handleRecaptchaVerify = (recaptchaToken) => this.setState({ recaptchaToken })

  handleSubmitSuccess = () => {
    const { enquiryPublicData, navigate } = this.props
    const { token } = this.state
    const { enquiry } = enquiryPublicData?.[0] || {}
    const { customThankYouPage, useCustomThankYouPage } = enquiry || {}

    if (useCustomThankYouPage) {
      window.location.href = customThankYouPage

      return
    }

    navigate(generateRoute('ENQUIRY_PUBLIC_FORM_SUCCESS', { token }))
  }

  handleSubmit = (fields) => {
    const { enquiriesActions, enquiriesSelectors } = this.props
    const { recaptchaToken, token } = this.state

    if (recaptchaToken) {
      enquiriesActions.createPublicEnquiry({
        body: enquiriesSelectors.getPublicEnquiryRequestBody({ captcha: recaptchaToken, fields }),
        onSuccess: this.handleSubmitSuccess,
        params: [{ token }],
      })
    }
  }

  render() {
    const { errorMessages, isSubmitting, subdomainData } = this.props
    const { token } = this.state

    return (
      <EnquiryPublicForm
        errorMessages={errorMessages}
        isSubmitting={isSubmitting}
        subdomainData={subdomainData}
        token={token}
        onRecaptchaVerify={this.handleRecaptchaVerify}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  enquiriesFormState,
  nurseriesSelectors,
  subdomainSelectors,
}) => ({
  enquiryPublicData: nurseriesSelectors.getNurseryPublicData(state),
  errorMessages: appSelectors.getErrorMessages(enquiriesFormState),
  isSubmitting: appSelectors.getIsSubmitting(enquiriesFormState),
  subdomainData: subdomainSelectors.getSubdomainInfo(state),
})

const enhance = compose(
  withAppService,
  withEnquiriesService,
  withNurseriesService,
  withSubdomainService,
  withRouter,
  connect(mapState),
)

export default enhance(EnquiryPublicFormContainer)
