import i18n from 'translations'

import NextStepsWidget from './NextStepsWidgetContainer'

export const WIDGET_NAME = {
  component: NextStepsWidget,
  id: 'NEXT_STEPS_WIDGET',
  label: i18n.t('module:Dashboard:Widgets:NextStepsWidget:titleInSettings'),
}

export default NextStepsWidget
