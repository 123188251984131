import {
  GET_FORMATIVE_REPORTS_PERIODS,
  GET_FORMATIVE_REPORTS_PERIODS_FAILED,
  GET_FORMATIVE_REPORTS_PERIODS_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: null,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case GET_FORMATIVE_REPORTS_PERIODS:
      return {
        ...state,
        error: initialState.error,
        isFetching: true,
      }
    case GET_FORMATIVE_REPORTS_PERIODS_SUCCESS:
      return {
        ...state,
        data: payload.data,
        isFetching: false,
      }
    case GET_FORMATIVE_REPORTS_PERIODS_FAILED:
      return {
        ...state,
        error,
        isFetching: false,
      }
    default:
      return state
  }
}
