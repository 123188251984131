import _ from 'lodash'

import i18n from 'translations'

const TABLE_COLUMNS = [
  {
    field: 'openingCreditNote',
    title: i18n.t('module:Finance:Reports:Deposits:Table:Header:openingCreditNote'),
  },
  {
    field: 'invoices',
    title: i18n.t('module:Finance:Reports:Deposits:Table:Header:invoices'),
  },
  {
    field: 'deposits',
    title: i18n.t('module:Finance:Reports:Deposits:Table:Header:deposits'),
  },
  {
    field: 'creditNoteAdjustments',
    title: i18n.t('module:Finance:Reports:Deposits:Table:Header:creditNoteAdjustments'),
  },
  {
    field: 'payments',
    title: i18n.t('module:Finance:Reports:Deposits:Table:Header:payments'),
  },
  {
    field: 'closingCreditNote',
    title: i18n.t('module:Finance:Reports:Deposits:Table:Header:closingCreditNote'),
  },
]

export const NURSERY_TABLE_COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'name',
    title: _.upperFirst(i18n.t('global:child')),
  },
  ...TABLE_COLUMNS,
]

export const ORGANIZATION_TABLE_COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'name',
    title: _.upperFirst(i18n.t('global:site')),
  },
  {
    field: 'raised',
    title: i18n.t('module:Finance:Reports:Deposits:Organization:Table:Header:raised'),
  },
  {
    field: 'held',
    title: i18n.t('module:Finance:Reports:Deposits:Organization:Table:Header:held'),
  },
  {
    field: 'refunded',
    title: i18n.t('module:Finance:Reports:Deposits:Organization:Table:Header:refunded'),
  },
  {
    field: 'pending',
    title: i18n.t('module:Finance:Reports:Deposits:Organization:Table:Header:pending'),
  },
  {
    field: 'pendingRefunds',
    title: i18n.t('module:Finance:Reports:Deposits:Organization:Table:Header:pendingRefunds'),
  },
]
