import React, { useEffect, useRef, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'
import { isDirty as isDirtyForm, stopSubmit } from 'redux-form'

import { ModalType } from 'modals'
import { RootState } from 'core/reducers'
import { generateRoute } from 'utils/routing'
import { getBackendErrors } from 'utils/backendErrors'

import { withNurseriesService, withNurseriesServiceProps } from 'services/nurseries'
import { withAppService, withAppServiceProps } from 'services/app'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withRouter, withRouterProps } from 'services/router'
import { withSnackbarService, withSnackbarServiceProps } from 'services/utils/snackbar'

import i18n from 'translations'

import EditView from './EditView'
import { EDIT_REFERENCE_CODE_GENERATION_FORM } from './components/EditForm'
import { CODE_AUTOMATIC_GENERATION } from '../constants'

export const GROUPS_NURSERY_SETTINGS = {
  read: [
    'nursery.settings',
    'nurserySettings',
    'nurseryCodeReferenceGenerationSettings',
    'nurserySettings.referenceCodeGeneration',
  ],
}

type EditContainerProps = withAppServiceProps
  & withRouterProps
  & withModalServiceProps
  & withNurseriesServiceProps
  & withSnackbarServiceProps

const mapDispatch = {
  injectValidation: (formName, params) => stopSubmit(formName, params),
}

const mapState = (state: RootState, {
  appSelectors,
  nurseriesSelectors,
  nurseriesSingleState,
  params,
}: EditContainerProps) => ({
  errorMessages: appSelectors.getErrorMessages(nurseriesSingleState),
  initialValues: nurseriesSelectors.getReferenceCodeGenerationInitialValues(state),
  isDirty: isDirtyForm(EDIT_REFERENCE_CODE_GENERATION_FORM)(state),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  nurserySettings: nurseriesSelectors.getNurserySettings(state),
})

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

const EditContainer: React.FC<EditContainerProps & PropsFromRedux> = ({
  errorMessages,
  initialValues,
  injectValidation,
  isDirty,
  isFetching,
  modalActions,
  navigate,
  nurseriesActions,
  nurseryOptions,
  router,
}) => {
  const disableUnsavedPopup = useRef<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleUnsavedPopupSuccess = (nextLocation) => {
    disableUnsavedPopup.current = true
    navigate(nextLocation)

    return true
  }

  const handleUnsavedPopup = (nextLocation) => {
    if (disableUnsavedPopup.current) {
      return null
    }

    if (isDirty) {
      modalActions.show<ModalType.CONFIRM>(ModalType.CONFIRM, {
        cancelButtonLabel: i18n.t('global:Continue'),
        confirmButtonLabel: i18n.t('global:Discard'),
        icon: 'warning',
        onConfirm: () => handleUnsavedPopupSuccess(nextLocation),
        text: i18n.t('module:Management:Finance:ReferenceCodeGeneration:leavePopupMessage'),
      })
    }

    return !isDirty
  }

  useEffect(() => {
    router.setRouteLeaveHook(router.routes[router.routes.length - 1], handleUnsavedPopup)
  }, [isDirty])

  const handleUpdateFailed = (response = {}) => {
    setIsSubmitting(false)
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    const { codePrefix, codeReference, sequentialNumber } = errors

    return injectValidation(EDIT_REFERENCE_CODE_GENERATION_FORM, {
      codePrefix,
      sequentialNumber: sequentialNumber || codeReference,
    })
  }

  const handleSubmit = (fields) => {
    const { codePrefix, codePrefixEnabled, randomisedBySystem, sequentialNumber } = fields
    const codeAutomaticGeneration = randomisedBySystem === CODE_AUTOMATIC_GENERATION.RENDOMISED_BY_SYSTEM

    setIsSubmitting(true)

    const payload = {
      codePrefix: codePrefixEnabled ? codePrefix : undefined,
      codePrefixEnabled,
      randomisedBySystem: codeAutomaticGeneration,
      sequential: !codeAutomaticGeneration,
      sequentialNumber: !codeAutomaticGeneration ? +sequentialNumber : undefined,
    }

    nurseriesActions.updateChildCodeReferenceSettings({
      body: payload,
      onFailed: handleUpdateFailed,
      onSuccess: () => {
        setIsSubmitting(false)
        navigate(generateRoute('MANAGEMENT.FINANCE_SETTINGS.REFERENCE_CODE_GENERATION'))
      },
      params: [nurseryOptions.id, {
        groups: GROUPS_NURSERY_SETTINGS,
      }],
    })

    disableUnsavedPopup.current = true
  }

  return (
    <EditView
      errorMessages={errorMessages}
      initialValues={initialValues}
      isFetching={isFetching}
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withAppService,
  withRouter,
  withSnackbarService,
  withNurseriesService,
  withModalService,
  connector,
)

export default enhance(EditContainer)
