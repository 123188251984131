import { createSelector } from 'reselect'

export const getAbsenceReasonsSelector = (state) => state.absenceReasons

export const getAbsenceReasonsSingleSelector = createSelector(
  [getAbsenceReasonsSelector],
  (state) => state.single.data,
)

export const getInitialValues = createSelector(
  [getAbsenceReasonsSingleSelector],
  (data) => {
    if (!data) {
      return null
    }

    const { archived, editable, id, name } = data || {}

    return {
      archived,
      editable,
      id,
      name,
    }
  },
)

export const getPayload = (formValues) => {
  if (!formValues) {
    return null
  }

  const { name } = formValues

  return {
    name,
  }
}
