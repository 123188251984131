import React from 'react'

import { generateRoute } from 'utils/routing'

import { Callout, Page, Section, Spinner } from 'components'
import Settings from 'module/Settings/components'

export const FORM_NAME = 'EditInvoiceNumbersForm'

const ManagementInvoiceV3NumbersEditView = ({
  errorMessages,
  initialValues,
  isFormLoading,
  isInvoiceNumberReadonly,
  isLoading,
  onSubmit,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <Section title="Edit invoice number sequencing">
        <Settings.InvoiceNumbersSettingForm
          cancelButtonLink={generateRoute('MANAGEMENT.FINANCE_SETTINGS.INVOICES')}
          form={FORM_NAME}
          initialValues={initialValues}
          isInvoiceNumberReadonly={isInvoiceNumberReadonly}
          isLoading={isFormLoading}
          onSubmit={onSubmit}
        />
      </Section>
    )
  }

  return (
    <Page isLoading={isLoading}>
      <Page.Content>
        <Callout content={errorMessages} error />
        {renderContent()}
      </Page.Content>
    </Page>
  )
}

export default ManagementInvoiceV3NumbersEditView
