import ServiceBase from 'services/ServiceBase'

import { QueryPropsV2 } from 'constants/service'

import { getQueryStringV2 } from 'utils/api'

class NurserySessionsApiClient extends ServiceBase {
  list = (params: QueryPropsV2, customSubdomain) => this.request({
    customSubdomain,
    path: `/v2/product/nursery_sessions?${getQueryStringV2(params)}`,
  })

  get = (id: string, params: QueryPropsV2, customSubdomain) => this.request({
    customSubdomain,
    path: `/v2/product/nursery_sessions/${id}?${getQueryStringV2(params)}`,
  })

  create = (customSubdomain, body) => this.request({
    body,
    customSubdomain,
    method: 'POST',
    path: '/v2/product/nursery_sessions',
  })

  update = (id, customSubdomain, body) => this.request({
    body,
    customSubdomain,
    method: 'PUT',
    path: `/v2/product/nursery_sessions/${id}`,
  })

  updateBatch = (body) => this.request({
    body,
    method: 'PUT',
    path: '/v2/product/batch/nursery_sessions',
  })
}

export default NurserySessionsApiClient
