import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

const getFinanceReports = (state) => state.financeBadDebtReport

export const getFinanceBadDebtReportChildren = createSelector(
  [getFinanceReports],
  (state) => state.children,
)

export const getFinanceBadDebtReportChildrenData = createSelector(
  [getFinanceBadDebtReportChildren],
  (state) => state.data,
)

export const getCriteria = ({ child, dateRange, dateType, room }) => {
  const criteria = []

  if (dateRange?.after && dateRange?.before) {
    criteria.push({
      field: 'startDate',
      value: moment(dateRange.after).format(DEFAULT_DATE_FORMAT),
    })

    criteria.push({
      field: 'endDate',
      value: moment(dateRange.before).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (dateType) {
    criteria.push({
      field: 'dateField',
      value: dateType,
    })
  }

  if (room) {
    criteria.push({
      field: 'child.nurseryClass',
      value: room,
    })
  }

  if (child) {
    criteria.push({
      field: 'child',
      value: child,
    })
  }

  return criteria
}

