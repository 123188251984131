import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import { FLAG_COLOURS } from 'constants/colors'

import { isRequired } from 'utils/fieldValidation'

import { Button, Form, Space, Typography } from 'components'

import i18n from 'translations'

export const INSTALL_DEVICE_FORM = 'INSTALL_DEVICE_FORM'

interface InstallDeviceFormProps {
  isFetching?: boolean
  onGoToLogin: () => void
  onSubmit: (values: any) => void
}

type InstallDeviceFormFullProps = InjectedFormProps<{}, InstallDeviceFormProps> & InstallDeviceFormProps

export interface InstallDeviceFormValues {
  pin: string
}

const InstallDeviceForm: React.FC<InstallDeviceFormFullProps> = ({
  handleSubmit,
  isFetching,
  onGoToLogin,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Space space="40px" />
    <Typography color={FLAG_COLOURS.ERROR}>
      {i18n.t('module:Authentication:Login:pinMessage')}
    </Typography>
    <Space space="20px" />
    <Form.Row margin="0 0 10px" width={{ field: '100%' }}>
      <Field
        component={Form.TextField}
        disabled={isFetching}
        name="pin"
        placeholder={i18n.t('module:Authentication:Login:pin')}
        validate={isRequired}
      />
    </Form.Row>
    <Button
      isLoading={isFetching}
      label={i18n.t('module:Authentication:Login:authoriseDevice')}
      fullWidth
      submit
    />
    <Button
      hierarchy="tertiary"
      label={i18n.t('global:cancel')}
      fullWidth
      onClick={onGoToLogin}
    />
  </Form>
)

export default reduxForm<{}, InstallDeviceFormProps>({ form: INSTALL_DEVICE_FORM })(InstallDeviceForm)
