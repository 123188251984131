import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { Avatar, Button, Currency, DateString, Typography } from 'components'
import CreditNoteIcon from 'module/Finance/components/CreditNoteIcon'

import { constants } from 'services/legacy/invoices'

import { StyledName } from './FinanceInvoicesContainerStyled'

const DATE_TYPE = {
  CREATED_DATE: 'processedDate',
  ISSUE_DATE: 'issueDate',
}
export const getInvoices = (invoices) => {
  if (!invoices) {
    return null
  }

  const mapInvoice = (invoice) => {
    const {
      child,
      containCreditNotes,
      id,
      issueDate,
      name,
      number,
      processedDate,
      status,
      total,
      totalOutstanding,
      type,
    } = invoice
    const { childBankDetail, firstName, id: childId, photo, surname } = child
    const primaryBillPayer = childBankDetail?.paymentContact?.carer?.fullName

    let preparedName = name || '-'
    const isCreditNote = type === constants.INVOICE_TYPES.CREDIT_NOTE
    if (isCreditNote) {
      preparedName = 'Credit note issued'
    }

    if (containCreditNotes) {
      preparedName = (
        <StyledName>
          <span>
            {name}
          </span>
          <CreditNoteIcon />
        </StyledName>
      )
    }

    return {
      child: (
        <Avatar
          avatarSize="small"
          initials={[firstName, surname]}
          src={photo}
          title={(
            <Typography fontSize={14} ellipsis primary>
              {`${firstName} ${surname}`}
            </Typography>
          )}
          to={`/children/${childId}/finance/invoicing`}
        />
      ),
      containCreditNotes,
      id,
      inactive: !!containCreditNotes,
      issueDate: issueDate ? <DateString date={issueDate} /> : '-',
      name: preparedName,
      number: number ? (
        <span>
          {number}
        </span>
      ) : '-',
      primaryBillPayer: primaryBillPayer || '-',
      processedDate: status === constants.STATUS_TYPES.DRAFT ? '-' : <DateString date={processedDate} />,
      rowToButton: isCreditNote ? (
        <Button.TableAction
          to={generateRoute('CHILDREN.CHILD.FINANCE.CREDIT_NOTES', { childId, creditNoteId: id })}
        />
      ) : (
        <Button.TableAction
          to={generateRoute('CHILDREN.CHILD.FINANCE.SINGLE_INVOICE', { childId, invoiceId: id })}
        />
      ),
      status: status ? (
        <span>
          {constants.STATUSES[status]}
        </span>
      ) : '-',
      total: <Currency value={total} highlight />,
      totalOutstanding: (
        0 < totalOutstanding
          ? <Currency color={NEUTRAL_COLOURS.BASIC} value={totalOutstanding} highlight />
          : '-'
      ),
    }
  }

  return _.map(invoices, mapInvoice)
}

export const getStatusOptions = () => _.keys(constants.STATUSES).map((status) => ({
  label: constants.STATUSES[status],
  value: status,
}))

export const getCriteria = (filters) => {
  const { dateType, endDate, room, searchBarValue, startDate, status, type } = filters

  const criteria = []

  if (startDate) {
    criteria.push({
      comparator: 'after',
      field: dateType,
      value: dateType === DATE_TYPE.ISSUE_DATE
        ? moment(startDate).format('YYYY-MM-DD')
        : moment(startDate).startOf('day').toISOString(),
    })
  }

  // NOTE: if only start date is selected then it should filter on that singleLegacy date
  if (endDate || startDate) {
    criteria.push({
      comparator: 'before',
      field: dateType,
      value: dateType === DATE_TYPE.ISSUE_DATE
        ? moment(endDate || startDate).format('YYYY-MM-DD')
        : moment(endDate || startDate).endOf('day').toISOString(),
    })
  }

  if (room) {
    criteria.push({
      field: 'child.nurseryClass',
      value: room,
    })
  }

  if (status && status !== constants.STATUS_TYPES.ALL) {
    criteria.push({
      field: 'status',
      value: status,
    })
  }

  if (searchBarValue) {
    criteria.push({
      field: 'concatenable',
      value: searchBarValue,
    })
  }

  if (type) {
    criteria.push({
      field: 'type',
      value: type,
    })
  }

  return criteria
}
