import _ from 'lodash'

import { mergeData } from 'utils/data'
import { BasicSingleInitialState } from 'utils/reducer'

import {
  ADD_BATCH_INVOICE,
  CLEAR_BATCH_INVOICES,
  CREATE_BATCH_INVOICES,
  CREATE_BATCH_INVOICES_FAILED,
  CREATE_BATCH_INVOICES_SUCCESS,
  REMOVE_BATCH_INVOICE,
  UPDATE_BATCH_INVOICE,
  UPDATE_BATCH_INVOICES,
  UPDATE_BATCH_INVOICES_FAILED,
  UPDATE_BATCH_INVOICES_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false,
  meta: {},
}

export interface InvoicesBatchState extends BasicSingleInitialState {}

export default (state = initialState, { error, mergeResult, payload, type }) => {
  switch (type) {
    case CREATE_BATCH_INVOICES:
    case UPDATE_BATCH_INVOICES:
      return { ...state, error: initialState.error, isSubmitting: true }
    case CREATE_BATCH_INVOICES_SUCCESS:
    case UPDATE_BATCH_INVOICES_SUCCESS:
      return {
        ...state,
        data: mergeData(state.data, payload.data, mergeResult),
        isSubmitting: false,
        meta: payload.meta,
      }
    case CREATE_BATCH_INVOICES_FAILED:
    case UPDATE_BATCH_INVOICES_FAILED:
      return { ...state, error, isSubmitting: false }
    case CLEAR_BATCH_INVOICES:
      return initialState
    case ADD_BATCH_INVOICE:
      return {
        ...state,
        data: [
          ...state.data,
          { invoice: payload.invoice },
        ],
      }
    case UPDATE_BATCH_INVOICE:
      return {
        ...state,
        data: _.map(state.data, (item) => {
          const { invoice } = item
          const { id } = invoice

          if (payload.invoiceId === id) {
            return { invoice: payload.invoice }
          }

          return item
        }),
      }
    case REMOVE_BATCH_INVOICE:
      return {
        ...state,
        data: _.reject(state.data, ({ invoice: { id } }) => payload.invoiceId === id),
      }
    default:
      return state
  }
}
