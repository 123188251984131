import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_NURSERY_ACADEMIC_YEAR,
  CREATE_NURSERY_ACADEMIC_YEAR,
  CREATE_NURSERY_ACADEMIC_YEAR_FAILED,
  CREATE_NURSERY_ACADEMIC_YEAR_SUCCESS,
  GET_NURSERY_ACADEMIC_YEAR,
  GET_NURSERY_ACADEMIC_YEAR_FAILED,
  GET_NURSERY_ACADEMIC_YEAR_SUCCESS,
  REMOVE_NURSERY_ACADEMIC_YEAR,
  REMOVE_NURSERY_ACADEMIC_YEAR_FAILED,
  REMOVE_NURSERY_ACADEMIC_YEAR_SUCCESS,
  UPDATE_NURSERY_ACADEMIC_YEAR,
  UPDATE_NURSERY_ACADEMIC_YEAR_FAILED,
  UPDATE_NURSERY_ACADEMIC_YEAR_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_NURSERY_ACADEMIC_YEAR,
    createFailed: CREATE_NURSERY_ACADEMIC_YEAR_FAILED,
    createInit: CREATE_NURSERY_ACADEMIC_YEAR,
    createSuccess: CREATE_NURSERY_ACADEMIC_YEAR_SUCCESS,
    itemFailed: GET_NURSERY_ACADEMIC_YEAR_FAILED,
    itemInit: GET_NURSERY_ACADEMIC_YEAR,
    itemSuccess: GET_NURSERY_ACADEMIC_YEAR_SUCCESS,
    removeFailed: REMOVE_NURSERY_ACADEMIC_YEAR_FAILED,
    removeInit: REMOVE_NURSERY_ACADEMIC_YEAR,
    removeSuccess: REMOVE_NURSERY_ACADEMIC_YEAR_SUCCESS,
    updateFailed: UPDATE_NURSERY_ACADEMIC_YEAR_FAILED,
    updateInit: UPDATE_NURSERY_ACADEMIC_YEAR,
    updateSuccess: UPDATE_NURSERY_ACADEMIC_YEAR_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
