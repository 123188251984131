import React from 'react'

import i18n from 'translations'

import { Callout, Page, Spinner } from 'components'

import NewslettersAddForm from './components/NewslettersAddForm'

const NewslettersAddView = ({
  currentUser,
  errorMessages,
  groupDropdownOptions,
  individualOptions,
  initialValues,
  isEdit,
  isFetching,
  isSubmitting,
  news,
  nursery,
  onCancelClick,
  onChangeMedia,
  onDraftClick,
  onPublishClick,
  onSubmit,
}) => {
  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    return (
      <React.Fragment>
        <Callout content={errorMessages} error />
        <NewslettersAddForm
          currentUser={currentUser}
          groupDropdownOptions={groupDropdownOptions}
          individualOptions={individualOptions}
          initialValues={initialValues}
          isSubmitting={isSubmitting}
          news={news}
          nursery={nursery}
          onCancelClick={onCancelClick}
          onChangeMedia={onChangeMedia}
          onDraftClick={onDraftClick}
          onPublishClick={onPublishClick}
          onSubmit={onSubmit}
        />
      </React.Fragment>
    )
  }

  return (
    <Page.Section
      isLoading={isFetching}
      title={isEdit ? i18n.t('module:Newsletters:Add:editTitle') : i18n.t('module:Newsletters:Add:createTitle')}
    >
      {renderContent()}
    </Page.Section>
  )
}

export default NewslettersAddView
