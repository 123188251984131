import { generateBasicActions } from 'utils/service'

import {
  CLEAR_NURSERY_DISCOUNTS_DROPDOWN_LIST,
  GET_NURSERY_DISCOUNTS_DROPDOWN_ITEM,
  GET_NURSERY_DISCOUNTS_DROPDOWN_ITEM_FAILED,
  GET_NURSERY_DISCOUNTS_DROPDOWN_ITEM_SUCCESS,
  NURSERY_DISCOUNTS_DROPDOWN_LIST,
  NURSERY_DISCOUNTS_DROPDOWN_LIST_FAILED,
  NURSERY_DISCOUNTS_DROPDOWN_LIST_SUCCESS,
} from './constants'

export const dropdownList = (options = {}) => ({ nurseryDiscountsApiClient }) => generateBasicActions.list({
  constants: {
    failed: NURSERY_DISCOUNTS_DROPDOWN_LIST_FAILED,
    init: NURSERY_DISCOUNTS_DROPDOWN_LIST,
    success: NURSERY_DISCOUNTS_DROPDOWN_LIST_SUCCESS,
  },
  options,
  service: nurseryDiscountsApiClient,
  serviceMethod: 'list',
})

export const getDropdownItem = (options = {}) => ({ nurseryDiscountsApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_NURSERY_DISCOUNTS_DROPDOWN_ITEM_FAILED,
    init: GET_NURSERY_DISCOUNTS_DROPDOWN_ITEM,
    success: GET_NURSERY_DISCOUNTS_DROPDOWN_ITEM_SUCCESS,
  },
  options,
  service: nurseryDiscountsApiClient,
  serviceMethod: 'get',
})

export const clearDropdownList = () => ({
  type: CLEAR_NURSERY_DISCOUNTS_DROPDOWN_LIST,
})
