import _ from 'lodash'

import React from 'react'
import { createSelector } from 'reselect'

import { generateRoute } from 'utils/routing'

import { LEAVE_OPTION, ROOM_TYPES_OPTIONS } from 'services/legacy/rooms/constants'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { Button, Typography } from 'components'

import i18n from 'translations'

import * as constants from '../constants'

import { isTeachingRoom } from '../single/selectors'

const getRooms = (state) => state.newRooms

export const getRoomsListSelector = createSelector(
  [getRooms],
  (state) => {
    if (!state) {
      return null
    }

    return state.list
  },
)

export const getRoomsListDataSelector = createSelector(
  [getRoomsListSelector],
  (state) => {
    if (!state) {
      return null
    }

    return state.data
  },
)

export const getRoomsListMetaSelector = createSelector(
  [getRoomsListSelector],
  (state) => {
    if (!state || !state.meta) {
      return { totalResults: 0 }
    }

    return { totalResults: state.meta.total_results }
  },
)

export const getRoomName = (room) => {
  if (!room) {
    return null
  }

  const { archived, name } = room

  return `${name}${archived ? ' (archived)' : ''}`
}

export const getRoomsOptionsDropdownData = createSelector(
  [getRoomsListDataSelector],
  (rooms) => {
    if (!rooms || 0 < !rooms.length) {
      return null
    }

    return _.map(rooms, (room) => ({
      label: getRoomName(room),
      value: room.id,
    }))
  },
)

export const getRoomsOptionsDropdownWithLeavingData = createSelector(
  [getRoomsOptionsDropdownData],
  (rooms) => {
    const data = rooms && [...rooms]

    if (!data || !data.length) {
      return null
    }

    data.unshift(LEAVE_OPTION)

    return data
  },
)

export const getRoomsDropdownDataWithAllRooms = createSelector(
  [getRoomsOptionsDropdownData],
  (rooms = []) => {
    const data = rooms && [...rooms]

    const allRooms = {
      label: 'All rooms',
      value: '',
    }

    if (!data) {
      return [allRooms]
    }

    data.unshift(allRooms)

    return data
  },
)

export const getRoomsListTableSelector = createSelector(
  [getRoomsListDataSelector],
  (rooms) => {
    if (!rooms || 0 < !rooms.length) {
      return null
    }

    return _.map(rooms, (room) => {
      const {
        archived,
        capacity,
        endAgeInMonths,
        id,
        name,
        ratio,
        staffRequired,
        staffRequirements,
        startAgeInMonths,
        type,
      } = room

      const staffRequirementsTableValue = 0 < staffRequirements?.length ? i18n.t('global:Yes') : i18n.t('global:No')

      return {
        capacity: capacity || <Typography color={NEUTRAL_COLOURS.GRAY}>-</Typography>,
        edit: (
          <Button.TableAction
            to={generateRoute('ROOMS.EDIT', { id })}
            edit
          />
        ),
        id,
        name: `${name}${archived ? ` (${i18n.t('global:archived')})` : ''}`,
        ratio,
        staffRequired: isTeachingRoom(type)
          ? staffRequired
          : staffRequirementsTableValue,
        startAgeInMonths: (
          startAgeInMonths !== undefined && endAgeInMonths !== undefined
            ? (`${startAgeInMonths} - ${endAgeInMonths}`)
            : <Typography color={NEUTRAL_COLOURS.GRAY}>-</Typography>
        ),
        type: _.get(_.find(ROOM_TYPES_OPTIONS, ({ value }) => value === type), 'label'),
      }
    })
  },
)

const getFilters = (filters) => filters

export const getCriteriaSelector = createSelector(
  [getFilters],
  (filters) => {
    const {
      roomFilter = constants.ROOM_FILTER.ACTIVE,
      roomType = constants.ROOM_TYPES.TEACHING,
      phrase,
    } = filters || {}

    const criteria = []

    if (phrase) {
      criteria.push({
        field: 'name',
        value: phrase,
      })
    }

    if (roomFilter && roomFilter !== constants.ROOM_FILTER.ALL) {
      criteria.push({
        field: 'archived',
        value: roomFilter === constants.ROOM_FILTER.ARCHIVED ? 1 : 0,
      })
    }

    if (constants.ROOM_TYPES.ALL !== roomType) {
      criteria.push({
        field: 'type',
        value: roomType,
      })
    }

    return criteria
  },
)
