import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withMembershipsService } from 'services/legacy/memberships'

import { Select } from 'components'

import i18n from 'translations'

import withInfiniteDropdownHelpers from './withInfiniteDropdown'

class MembershipsDropdown extends Component {
  componentDidMount() {
    const { infiniteDropdownHelpers, membershipsActions, value } = this.props

    return infiniteDropdownHelpers.handleComponentDidMount({
      action: () => membershipsActions.get((value?.value || value), {
        addToList: true,
        onFailed: this.notFoundValue,
        onSuccess: infiniteDropdownHelpers.handleComponentDidMountOnSuccess,
      }),
      value,
    })
  }

  componentWillUnmount() {
    const { membershipsActions } = this.props

    membershipsActions.clearList()
  }

  handleLoadMoreElements = async (phrase) => {
    const {
      extraFields = ['email'],
      extraParams = {},
      groups,
      infiniteDropdownHelpers,
      limit,
      membershipsActions,
      membershipsSelectors,
    } = this.props

    if (extraParams.disablePendingFilter) {
      extraFields.push('accepted')
    }

    return infiniteDropdownHelpers.handleLoadMoreElements({
      clearAction: membershipsActions.clearList,
      listAction: ({ newPage, newSearchPhrase, result }) => membershipsActions.list({
        mergeResult: true,
        onSuccess: (response) => infiniteDropdownHelpers.handleLoadMoreElementsOnSuccess({
          extraFields,
          newPage,
          newSearchPhrase,
          response,
          result,
        }),
        params: {
          criteria: membershipsSelectors.getMembershipsListCriteria({
            search: newSearchPhrase,
            ...extraParams,
          }),
          groups,
          limit,
          page: newPage,
        },
      }),
      phrase,
    })
  }

  notFoundValue = () => {
    const { onChange } = this.props

    onChange(null)
  }

  handleChange = (e) => {
    const { infiniteDropdownHelpers, onChange } = this.props

    infiniteDropdownHelpers.handleOnChange(e, () => onChange(e))
  }

  render() {
    const {
      clearable,
      disabled,
      error,
      infiniteDropdownState,
      isFetching,
      multi,
      placeholder,
      rawValue,
      value,
      ...rest
    } = this.props
    const { cacheUniq, options, selectedValue } = infiniteDropdownState
    const pendingString = ` (${i18n.t('global:pending')})`

    if (false === value?.accepted && -1 === value?.label?.indexOf(pendingString)) {
      value.label = `${value.label} ${pendingString}`
    }

    return (
      <Select
        {...rest}
        cacheUniq={cacheUniq}
        clearable={clearable}
        disabled={disabled || isFetching}
        error={error}
        loadOptions={this.handleLoadMoreElements}
        multi={multi}
        options={options}
        placeholder={placeholder || i18n.t('global:KeyPerson')}
        rawValue={value && (rawValue || selectedValue || value)}
        value={value}
        searchable
        withAvatars
        onChange={this.handleChange}
      />
    )
  }
}

const mapState = (state, { appSelectors, membershipsSingleState }) => ({
  isFetching: appSelectors.getIsFetching(membershipsSingleState),
})

const enhance = compose(
  withAppService,
  withMembershipsService,
  withInfiniteDropdownHelpers,
  connect(mapState),
)

export default enhance(MembershipsDropdown)
