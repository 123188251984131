export const COMPLETED_STATE = 'COMPLETED'

export const WORKING_ON_STATE = 'WORKING_ON'

export const FRAMEWORK_PROGRESS_LEVELS_TYPES = {
  FORMATIVE_REPORT_AGE_BAND: 'formativeReportAgeBand',
  FORMATIVE_REPORT_BEHAVIOUR_STATEMENT: 'formativeReportBehaviourStatement',
  FRAMEWORK_PAGE: 'frameworkPage',
  OBSERVATION_LINK: 'observationLink',
}
