import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { NAPPY_WAS_OPTIONS } from 'services/legacy/dailyDiaryNappies/constants'

import { isRequired } from 'utils/fieldValidation'

import { Checkbox, Form, GridLayout } from 'components'

import i18n from 'translations'

export const NAPPIES_TAB_FORM = 'NappiesTabForm'

const NappiesTabForm = ({
  handleSubmit,
  isSubmitting,
  onCancel,
  onSubmit,
  onToiletTrainingChange,
  trackToiletTraining,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <GridLayout>
      <GridLayout.Group noVerticalMargin>
        <GridLayout.Item
          column
        >
          <Form.Row label={i18n.t('module:DailyDiary:Nappies:nappyWas')} verticalLabel>
            <Form.Row.Item>
              <br />
              <Field
                component={Form.RadioGroup}
                disabled={isSubmitting}
                name="type"
                options={NAPPY_WAS_OPTIONS}
                validate={isRequired}
              />
            </Form.Row.Item>
          </Form.Row>
        </GridLayout.Item>
        <GridLayout.Item
          flex="3"
          column
        >
          <Form.Row label={i18n.t('module:DailyDiary:Nappies:nappyRash')} verticalLabel>
            <Form.Row.Item>
              <Field
                component={Form.Switch}
                disabled={isSubmitting}
                name="nappyRash"
              />
            </Form.Row.Item>
          </Form.Row>
          <Form.Row label={i18n.t('module:DailyDiary:Nappies:creamApplied')} verticalLabel>
            <Form.Row.Item>
              <Field
                component={Form.Switch}
                disabled={isSubmitting}
                name="wasCreamApplied"
              />
            </Form.Row.Item>
          </Form.Row>
          <Form.Row label={i18n.t('global:Time')} verticalLabel>
            <Form.Row.Item>
              <Field
                component={Form.TimePicker}
                disabled={isSubmitting}
                name="recordedAt"
                validate={isRequired}
              />
            </Form.Row.Item>
          </Form.Row>
        </GridLayout.Item>
      </GridLayout.Group>
    </GridLayout>
    <Form.Row label={i18n.t('module:DailyDiary:global:additionalNotes')} verticalLabel>
      <Form.Row.FlexItem flex="0 0 420px" mobileFull>
        <Field
          component={Form.TextAreaField}
          disabled={isSubmitting}
          height={120}
          maxCharacters={2000}
          name="comments"
          placeholder={i18n.t('module:DailyDiary:global:enterAdditionalNotesHere')}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.Row>
      <Form.Row.FlexItem flex="0 0 220px">
        <Checkbox
          label={i18n.t('module:DailyDiary:Nappies:trackToiletTraining')}
          value={trackToiletTraining}
          onChange={onToiletTrainingChange}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.FooterActions
      isSubmitting={isSubmitting}
      onCancelClick={onCancel}
    />
  </Form>
)

export default reduxForm({ form: NAPPIES_TAB_FORM })(NappiesTabForm)
