import React, { PropsWithChildren } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import ImagesSize from 'constants/imagesSize'

import { getOptimizedImage } from 'utils/image'

interface NurseryLogoProps {
  alt: string
  logo: string
}

const NurseryLogo: React.FC<PropsWithChildren<NurseryLogoProps>> = ({ alt, logo }) => (logo ? (
  <LazyLoadImage
    alt={alt}
    effect="opacity"
    height={ImagesSize.NURSERY_LOGO.height}
    src={getOptimizedImage(logo, ImagesSize.NURSERY_LOGO)}
  />
) : null)

export default NurseryLogo
