import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledLink = styled.a`
  color: ${getBrandingColor('primary-color')};
  cursor: pointer;

  &:hover, &:focus {
    color: ${getBrandingColor('primary-color')};
  }
`

export const StyledSectionContent = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
`

export const StyledList = styled.ul`
  margin: 0;
  padding-left: 23px;
  margin-left: -30px;
`

export const StyledListItem = styled.li`
  padding-left: 7px;

  &:not(:last-child) {
    margin-bottom: 7px;
  }
`

export const StyledAlertBox = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`
