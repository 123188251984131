import _ from 'lodash'
import moment, { Moment } from 'moment'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'
import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { WrappedComponentType } from 'constants/types'
import { ROLES } from 'constants/security'

import { withAppService, withAppServiceProps } from 'services/app'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withMilkReportService, withMilkReportServiceProps } from 'services/milkReport'
import { withRouteUtilsProps, withRouterUtils } from 'services/utils/router'
import { withRouter, withRouterProps } from 'services/router'

import { DatePicker, Form, ReportView, TextField, Toolbar } from 'components'

import i18n from 'translations'

import { ModalType } from 'modals'
import { WithCurrencyUtilsProps, withCurrencyUtils } from 'services/utils/currency'
import { TABLE_COLUMNS } from './constants'
import { getTableData } from './NurseryMilkHelper'

type NurseryMilkContainerProps = withAppServiceProps
  & withModalServiceProps
  & withRouteUtilsProps
  & withRouterProps
  & withMilkReportServiceProps
  & WithCurrencyUtilsProps

const mapState = (state: RootState, {
  appSelectors,
  milkReportListState,
  milkReportSelectors,
}: NurseryMilkContainerProps) => ({
  errorMessages: appSelectors.getErrorMessages(milkReportListState),
  isFetching: appSelectors.getIsFetching(milkReportListState),
  milkReportEntries: milkReportSelectors.getEntries(state),
  totalChildren: milkReportSelectors.getTotalChildren(state),
  totalCost: milkReportSelectors.getTotalCost(state),
  totalMilk: milkReportSelectors.getTotalMilk(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const NurseryMilkContainer: WrappedComponentType<NurseryMilkContainerProps & PropsFromRedux> = ({
  currencyUtils,
  errorMessages,
  isFetching,
  location,
  milkReportActions,
  milkReportEntries,
  milkReportSelectors,
  modalActions,
  setLocationQuery,
  subdomainCurrency,
  subdomainLocale,
  totalChildren,
  totalCost,
  totalMilk,
}) => {
  const { query } = location

  const [date, setDate] = useState<Moment>(
    query.date
      ? moment(query.date, DEFAULT_DATE_FORMAT)
      : moment().startOf('month'),
  )
  const [unitPrice, setUnitPrice] = useState<number>(query.unitPrice || 0.40)
  const [unitsPerChild, setUnitsPerChild] = useState<number>(query.unitsPerChild || 0.333)
  const [isDateChanged, setIsDateChanged] = useState(true)
  const [currencySymbol, setCurrencySymbol] = useState<any>()

  useEffect(() => {
    setCurrencySymbol(currencyUtils.getCurrencySymbol())
  }, [subdomainCurrency, subdomainLocale])

  const fetchData = () => {
    setLocationQuery({
      date: date.format('YYYY-MM-DD'),
      unitPrice,
      unitsPerChild,
    })

    const criteria = milkReportSelectors.getCriteria({
      date,
      unitPrice: unitPrice || 0,
      unitsPerChild: unitsPerChild || 0,
    })

    milkReportActions.list({
      onSuccess: () => setIsDateChanged(false),
      params: { criteria },
    })

    return () => {
      milkReportActions.clearList()
    }
  }

  const fetchDataCallback = useCallback(_.debounce(fetchData, 500), [date, unitsPerChild, unitPrice])

  useEffect(() => {
    fetchDataCallback()
  }, [date, unitsPerChild, unitPrice])

  const statistics = useMemo(() => {
    if (isFetching) {
      return null
    }

    return [
      {
        label: i18n.t('module:Reports:NurseryMilk:Statistics:totalChildren'),
        value: totalChildren,
      },
      {
        label: i18n.t('module:Reports:NurseryMilk:Statistics:totalCost'),
        value: totalCost,
      },
      {
        label: i18n.t('module:Reports:NurseryMilk:Statistics:totalMilk'),
        value: totalMilk,
      },
    ]
  }, [totalCost, totalChildren])

  const handleExportModalClick = (body, onSuccessCb) => {
    const { criteria } = body

    const userCriteria = _.find(criteria, { field: 'membership' })
    const filterCriteria = milkReportSelectors.getCriteria({ date, unitPrice, unitsPerChild })

    milkReportActions.exportMilkReport({
      onSuccess: onSuccessCb,
      params: {
        criteria: [
          ...[userCriteria],
          ...filterCriteria,
        ],
      },
    })
  }

  const handleExportClick = () => {
    modalActions.show<ModalType.EXPORT_REPORT>(ModalType.EXPORT_REPORT, {
      includeDeputyManager: true,
      onSendReport: handleExportModalClick,
      title: i18n.t('module:Reports:NurseryMilk:Export:title'),
      userLabel: i18n.t('module:Reports:NurseryMilk:Export:description'),
    })
  }

  const handleDateChange = (selectedDate) => {
    setIsDateChanged(true)
    setDate(selectedDate as Moment)
  }

  const tableData = useMemo(() => getTableData(milkReportEntries), [milkReportEntries])

  const renderFilters = () => (
    <Toolbar bottomLine>
      <Toolbar.Group>
        <Toolbar.Item>
          <Form.Row
            label={_.upperFirst(i18n.t('global:month'))}
            verticalLabel
          >
            <DatePicker
              clearable={false}
              disabledDays={[{
                after: moment().startOf('month').toDate(),
              }]}
              type="month"
              value={date?.format(DEFAULT_DATE_FORMAT)}
              onChange={handleDateChange}
            />
          </Form.Row>
        </Toolbar.Item>
        <Toolbar.Item>
          <Form.Row
            label={i18n.t('module:Reports:NurseryMilk:Filters:UnitPrice:label')}
            verticalLabel
          >
            <TextField
              placeholder={i18n.t('module:Reports:NurseryMilk:Filters:UnitPrice:placeholder')}
              prefix={currencySymbol}
              prefixWidth="24px"
              step="0.01"
              type="number"
              value={unitPrice}
              onChange={(e) => setUnitPrice(e.target.value)}
            />
          </Form.Row>
        </Toolbar.Item>
        <Toolbar.Item>
          <Form.Row
            label={i18n.t('module:Reports:NurseryMilk:Filters:UnitsPerChild:label')}
            verticalLabel
          >
            <TextField
              placeholder={i18n.t('module:Reports:NurseryMilk:Filters:UnitsPerChild:placeholder')}
              type="number"
              value={unitsPerChild}
              onChange={(e) => setUnitsPerChild(e.target.value)}
            />
          </Form.Row>
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )

  return (
    <ReportView.DetailPage
      description={i18n.t('module:Reports:NurseryMilk:description')}
      errorMessages={errorMessages}
      filters={renderFilters()}
      isPageLoading={isDateChanged && isFetching}
      statistics={statistics}
      tableColumns={TABLE_COLUMNS}
      tableData={tableData}
      title={i18n.t('module:Reports:NurseryMilk:title')}
      totalResults={milkReportEntries?.length}
      onExportClick={handleExportClick}
    />
  )
}

const enhance = compose(
  withRouter,
  withAppService,
  withRouterUtils,
  withModalService,
  withMilkReportService,
  withCurrencyUtils,
  connector,
)

NurseryMilkContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

export default enhance(NurseryMilkContainer)
