import styled from 'styled-components'

import { StyledIconContainer } from 'components/CircleIcon/CircleIconStyled'

export const StyledBadge = styled.div`
  position: absolute;
  right: 2px;
  top: -3px;
  z-index: 10;
  letter-spacing: -.5px;
  
  ${StyledIconContainer} {
    padding: 0 5px;
    width: auto;
    border-radius: 26px;
    min-width: 18px;
  }
`
