export const GET_ORGANIZATION = 'GET_ORGANIZATION'
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS'
export const GET_ORGANIZATION_FAILED = 'GET_ORGANIZATION_FAILED'

export const GET_ORGANIZATION_FLAGS = 'GET_ORGANIZATION_FLAGS'
export const GET_ORGANIZATION_FLAGS_SUCCESS = 'GET_ORGANIZATION_FLAGS_SUCCESS'
export const GET_ORGANIZATION_FLAGS_FAILED = 'GET_ORGANIZATION_FLAGS_FAILED'

export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION'
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS'
export const UPDATE_ORGANIZATION_FAILED = 'UPDATE_ORGANIZATION_FAILED'

export const UPDATE_ORGANIZATION_FLAG = 'UPDATE_ORGANIZATION_FLAG'
export const UPDATE_ORGANIZATION_FLAG_SUCCESS = 'UPDATE_ORGANIZATION_FLAG_SUCCESS'
export const UPDATE_ORGANIZATION_FLAG_FAILED = 'UPDATE_ORGANIZATION_FLAG_FAILED'

export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION'
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS'
export const CREATE_ORGANIZATION_FAILED = 'CREATE_ORGANIZATION_FAILED'

export const UPDATE_FINANCE_MIGRATION = 'UPDATE_FINANCE_MIGRATION'
export const UPDATE_FINANCE_MIGRATION_SUCCESS = 'UPDATE_FINANCE_MIGRATION_SUCCESS'
export const UPDATE_FINANCE_MIGRATION_FAILED = 'UPDATE_FINANCE_MIGRATION_FAILED'

export const CLEAR_ORGANIZATION = 'CLEAR_ORGANIZATION'
