import _ from 'lodash'
import parse from 'html-react-parser'

import React, { Component } from 'react'
import { compose } from 'recompose'

import { Field } from 'components'

import withInvoiceSettingsComponent from '../withInvoiceSettingsComponent'
import InvoicePaymentReminderSettingsForm from './InvoicePaymentReminderSettingsForm'
import InvoiceActionButton from '../InvoiceActionButton'
import { getInitialValues, getPayload } from './InvoicePaymentReminderSettingsHelpers'
import InvoicePaymentReminderCaption from './components/InvoicePaymentReminderCaption'

const FORM_NAME_PREFIX = 'InvoicePaymentReminderSettingsForm'

class InvoicePaymentReminderSettings extends Component {
  constructor(props) {
    super(props)

    const { fields: { item } } = props
    const { id } = item

    this.state = {
      formName: `${FORM_NAME_PREFIX}_${id}`,
    }
  }

  handleSubmit = (fields) => {
    const { fields: { global, item }, onSubmit } = this.props
    const { id } = item
    const { id: globalId } = global

    const payload = {
      [globalId]: fields[globalId],
      [id]: getPayload(fields[id]),
    }

    onSubmit(payload)
  }

  handleGlobalOptionClick = (globalValue) => {
    const { fields: { item: { id, orgValue } }, updateOrgValue } = this.props
    const { formName } = this.state

    if (globalValue) {
      updateOrgValue(formName, id, orgValue)
    }
  }

  renderReminderLabel = ({ intervalPeriodDays, message }, index) => (
    <div key={_.kebabCase(index)}>
      {`Reminder ${index + 1}`}
      <br />
      {`${Math.abs(intervalPeriodDays)} days ${0 < intervalPeriodDays ? 'before' : 'after'} invoice due date`}
      <br />
      <div>
        {parse(message)}
      </div>
    </div>
  )

  renderLabel = (noteValues) => {
    if (!noteValues || !noteValues.length) {
      return null
    }

    return _.map(noteValues, this.renderReminderLabel)
  }

  render() {
    const {
      disableEdit,
      fields: { global, item },
      hideGlobalOption,
      isEdit,
      isGlobalOptionOn,
      isSubmitting,
      onCancelClick,
      onEditClick,
      paymentReminderTags,
    } = this.props
    const { formName } = this.state
    const { id, value } = item
    const { id: globalId, value: globalValue } = global

    const initialValues = getInitialValues({ globalId, globalValue, id, value })

    return (
      <Field.InlineEdit
        actions={(
          <InvoiceActionButton
            disableEdit={disableEdit}
            formRef={this.myForm}
            isEditMode={isEdit}
            onCancelClick={onCancelClick}
            onClick={onEditClick}
          />
        )}
        description={this.renderLabel(value)}
        editableComponent={(
          <InvoicePaymentReminderSettingsForm
            form={formName}
            globalId={globalId}
            hideGlobalOption={hideGlobalOption}
            id={id}
            initialValues={initialValues}
            isGlobalOptionOn={isGlobalOptionOn}
            paymentReminderTags={paymentReminderTags}
            ref={(ref) => { this.myForm = ref }}
            onGlobalOptionClick={this.handleGlobalOptionClick}
            onSubmit={this.handleSubmit}
          />
        )}
        isEditMode={isEdit}
        isLoading={isSubmitting}
        label={<InvoicePaymentReminderCaption isEdit={isEdit} paymentReminderTags={paymentReminderTags} />}
      />
    )
  }
}

const enhance = compose(withInvoiceSettingsComponent(FORM_NAME_PREFIX))

export default enhance(InvoicePaymentReminderSettings)
