import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withRouter } from 'services/router'

import { getFailureComponent } from './helpers'

import * as SELECTORS from './selectors'

export const authContainer = (WrappedComponent) => {
  const Component = ({ failureComponent, isAuthorised, isLoggedIn, navigate, ...other }) => {
    const MissingPage = require('module/Various/Missing').default

    if (!isLoggedIn) {
      navigate('/login')
    }

    if (isAuthorised) {
      return <WrappedComponent {...other} />
    }

    const FailureComponent = failureComponent || MissingPage

    return <FailureComponent {...other} />
  }

  const mapState = (state) => ({
    failureComponent: getFailureComponent(state, WrappedComponent),
    isAuthorised: SELECTORS.getComponentIsAuthorised(state, WrappedComponent),
    isLoggedIn: SELECTORS.getIsLoggedIn(state),
  })

  const enhance = compose(connect(mapState), withRouter)

  return enhance(Component)
}

export default { SELECTORS }
