import React, { useEffect, useState } from 'react'

import { Button, Section, Table } from 'components'

import i18n from 'translations'

import { CONTACTS_TABLE_COLUMNS } from '../../../constants'
import { getContactsTableData } from '../../../helpers'

const ReviewContacts = ({ formValues, isFinanceV3Enabled, onSetStage }) => {
  const { contacts } = formValues
  const [tableData, setTableData] = useState(null)

  useEffect(() => {
    const setTableDataAsync = async () => {
      setTableData(await getContactsTableData(contacts))
    }

    setTableDataAsync()
  }, [contacts])

  if (null === tableData) {
    return null
  }

  return (
    <Section
      actions={(
        <Button
          hierarchy="tertiary"
          label={i18n.t('global:Edit')}
          onClick={() => onSetStage(isFinanceV3Enabled ? 2 : 3)}
        />
      )}
      title={i18n.t('module:Children:Child:Add:Contacts:title')}
    >
      <Table
        columns={CONTACTS_TABLE_COLUMNS}
        data={tableData}
        visualType="transparent"
        disableSticky
      />
    </Section>
  )
}

export default ReviewContacts
