import { generateBasicActions } from 'utils/service'

import {
  REMOVE_CHILD_FILE,
  REMOVE_CHILD_FILE_FAILED,
  REMOVE_CHILD_FILE_SUCCESS,
  UPDATE_CHILD_FILE,
  UPDATE_CHILD_FILE_FAILED,
  UPDATE_CHILD_FILE_SUCCESS,
} from './constants'

export const removeChildFile = (options = {}) => ({ filesApiClient }) => generateBasicActions.remove({
  constants: {
    failed: REMOVE_CHILD_FILE_FAILED,
    init: REMOVE_CHILD_FILE,
    success: REMOVE_CHILD_FILE_SUCCESS,
  },
  options,
  service: filesApiClient,
  serviceMethod: 'removeChildFile',
})

export const updateChildFile = (options = {}) => ({ filesApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_CHILD_FILE_FAILED,
    init: UPDATE_CHILD_FILE,
    success: UPDATE_CHILD_FILE_SUCCESS,
  },
  options,
  service: filesApiClient,
  serviceMethod: 'updateChildFile',
})
