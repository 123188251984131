import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import {
  CLEAR_LEGACY_FRAMEWORKS,
  LEGACY_LIST_FRAMEWORKS,
  LEGACY_LIST_FRAMEWORKS_FAILED,
  LEGACY_LIST_FRAMEWORKS_SUCCESS,
} from './constants'

const initialState = getBasicListInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_LEGACY_FRAMEWORKS,
    failed: LEGACY_LIST_FRAMEWORKS_FAILED,
    init: LEGACY_LIST_FRAMEWORKS,
    success: LEGACY_LIST_FRAMEWORKS_SUCCESS,
  }, initialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
