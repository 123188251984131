import React from 'react'

import { Banner, Callout, Page, Spinner, Typography } from 'components'

import i18n from 'translations'

import CorePreferencesForm from './components/CorePreferencesForm'

const CorePreferencesView = ({
  errorMessages,
  isNurserySettingsFetching,
  isSessionsFetching,
  page,
  ...rest
}) => {
  const renderContent = () => {
    if (isNurserySettingsFetching || (isSessionsFetching && 1 === page)) {
      return <Spinner />
    }

    return (
      <React.Fragment>
        <Banner.Info>
          <Typography>
            {i18n.t('module:Management:ParentApp:RequestableExtraSessions:CorePreferences:banner')}
          </Typography>
        </Banner.Info>
        <CorePreferencesForm
          isSessionsFetching={isSessionsFetching}
          page={page}
          {...rest}
        />
      </React.Fragment>
    )
  }

  return (
    <Page.Section
      isLoading={isNurserySettingsFetching || (isSessionsFetching && 1 === page)}
      title={i18n.t('module:Management:ParentApp:RequestableExtraSessions:CorePreferences:title')}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default CorePreferencesView
