import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { stopSubmit } from 'redux-form'

import { generateRoute } from 'utils/routing'
import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withShellService } from 'services/shell'
import { withSnackbarService } from 'services/utils/snackbar'
import { withEnquirySettingsService } from 'services/legacy/enquiries'
import { withModalService } from 'services/utils/modal'
import { withRouter } from 'services/router'

import i18n from 'translations'

import { ROUTE_URL_TYPE_MAPPINGS, TYPE_TO_ROUTE_URL_MAPPINGS } from '../constants'
import { getTransPrefix } from '../helpers'
import { ENQUIRY_SETTINGS_ADD_FORM } from './AddConstants'
import AddView from './AddView'

class AddContainer extends Component {
  constructor(props) {
    super(props)

    const { params } = props
    const { enquirySettingId, type } = params

    this.state = {
      enquirySettingId,
      isEditMode: !!enquirySettingId,
      type: ROUTE_URL_TYPE_MAPPINGS[type],
    }
  }

  componentDidMount() {
    const { enquirySettingsActions, shellActions } = this.props
    const { enquirySettingId, isEditMode, type } = this.state

    shellActions.setRouteTitle({
      name: `MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_COMMON.${isEditMode ? 'EDIT' : 'ADD'}`,
      title: i18n.t(`${getTransPrefix(type)}:Add:${isEditMode ? 'editTitle' : 'addTitle'}`),
    })

    if (enquirySettingId) {
      enquirySettingsActions.get({
        params: [enquirySettingId],
      })
    }
  }

  componentWillUnmount() {
    const { enquirySettingsActions } = this.props

    enquirySettingsActions.clearSingle()
  }

  handleSubmitFailed = (response) => {
    const { injectValidation } = this.props
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    return injectValidation(ENQUIRY_SETTINGS_ADD_FORM, errors)
  }

  handleRedirect = () => {
    const { navigate } = this.props
    const { type } = this.state

    navigate(generateRoute('MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_COMMON.INDEX', {
      type: TYPE_TO_ROUTE_URL_MAPPINGS[type],
    }))
  }

  handleSuccessMessage = () => {
    const { snackbarActions } = this.props
    const { isEditMode, type } = this.state

    snackbarActions.show({
      message: i18n.t(`${getTransPrefix(type)}:Add:${isEditMode ? 'updateSuccessMessage' : 'createSuccessMessage'}`),
    })

    this.handleRedirect()
  }

  handleSubmit = ({ name }) => {
    const { enquirySettingsActions } = this.props
    const { enquirySettingId, isEditMode, type } = this.state

    const body = { name, type }

    if (isEditMode) {
      enquirySettingsActions.update({
        body,
        onFailed: this.handleSubmitFailed,
        onSuccess: this.handleSuccessMessage,
        params: [enquirySettingId, {}],
      })

      return
    }

    enquirySettingsActions.create({
      body,
      onFailed: this.handleSubmitFailed,
      onSuccess: this.handleSuccessMessage,
      params: [{}],
    })
  }

  handleDeleteSuccess = () => {
    const { snackbarActions } = this.props
    const { type } = this.state

    snackbarActions.show({
      message: i18n.t(`${getTransPrefix(type)}:Add:deleteSuccessMessage`),
    })

    this.handleRedirect()
  }

  removeItem = () => {
    const { enquirySettingsActions } = this.props
    const { enquirySettingId } = this.state

    return enquirySettingsActions.update({
      body: { archived: true },
      onSuccess: this.handleDeleteSuccess,
      params: [enquirySettingId, {}],
    })
  }

  handleDeleteClick = () => {
    const { modalActions, modalConsts } = this.props
    const { type } = this.state

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('global:Delete'),
      icon: 'trash',
      onConfirm: this.removeItem,
      text: (
        <React.Fragment>
          {i18n.t(`${getTransPrefix(type)}:deleteMessage1`)}
          <br />
          {i18n.t(`${getTransPrefix(type)}:deleteMessage2`)}
        </React.Fragment>
      ),
    })
  }

  render() {
    const {
      errorMessages,
      initialValues,
      isFetching,
      isSubmitting,
    } = this.props
    const { enquirySettingId, isEditMode, type } = this.state

    return (
      <AddView
        enquirySettingId={enquirySettingId}
        errorMessages={errorMessages}
        initialValues={initialValues}
        isEditMode={isEditMode}
        isLoading={isFetching}
        isSubmitting={isSubmitting}
        type={type}
        onDeleteClick={this.handleDeleteClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  injectValidation: (formName, params) => stopSubmit(formName, params),
}

const mapState = (state, {
  appSelectors,
  enquirySettingsSelectors,
  enquirySettingsSingleState,
  params,
}) => ({
  errorMessages: appSelectors.getErrorMessages(enquirySettingsSingleState),
  initialValues: enquirySettingsSelectors.getInitialValues(!!params.enquirySettingId)(state),
  isFetching: appSelectors.getIsFetching(enquirySettingsSingleState),
  isSubmitting: appSelectors.getIsSubmitting(enquirySettingsSingleState),
})

const enhance = compose(
  withRouter,
  withAppService,
  withShellService,
  withSnackbarService,
  withModalService,
  withEnquirySettingsService,
  connect(mapState, mapDispatch),
)

export default enhance(AddContainer)
