export const CREATE_ENQUIRY_SETTINGS = 'CREATE_ENQUIRY_SETTINGS'
export const CREATE_ENQUIRY_SETTINGS_SUCCESS = 'CREATE_ENQUIRY_SETTINGS_SUCCESS'
export const CREATE_ENQUIRY_SETTINGS_FAILED = 'CREATE_ENQUIRY_SETTINGS_FAILED'

export const GET_ENQUIRY_SETTINGS = 'GET_ENQUIRY_SETTINGS'
export const GET_ENQUIRY_SETTINGS_SUCCESS = 'GET_ENQUIRY_SETTINGS_SUCCESS'
export const GET_ENQUIRY_SETTINGS_FAILED = 'GET_ENQUIRY_SETTINGS_FAILED'

export const UPDATE_ENQUIRY_SETTINGS = 'UPDATE_ENQUIRY_SETTINGS'
export const UPDATE_ENQUIRY_SETTINGS_SUCCESS = 'UPDATE_ENQUIRY_SETTINGS_SUCCESS'
export const UPDATE_ENQUIRY_SETTINGS_FAILED = 'UPDATE_ENQUIRY_SETTINGS_FAILED'

export const CLEAR_ENQUIRY_SETTINGS_SINGLE = 'CLEAR_ENQUIRY_SETTINGS_SINGLE'
