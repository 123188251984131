import _ from 'lodash'

import React from 'react'

import { DateString } from 'components'
import PlanView from 'module/Finance/components/PlanView'

import i18n from 'translations'

const PlanDetailAdhocSessions = ({ childAdhocSessions }) => {
  if (!childAdhocSessions || !childAdhocSessions.length) {
    return (
      <PlanView>
        <PlanView.Icon icon="plus" />
        <PlanView.Content>
          <PlanView.SubTitle
            subtitle={i18n.t('module:Children:Child:Finance:PlanView:Summary:extraSessionsEmptyState')}
          />
        </PlanView.Content>
      </PlanView>
    )
  }

  const renderAdhocSession = ({ id, name, takenDate }) => (
    <PlanView.Item key={id}>
      <PlanView.Highlight minWidth={80}>
        <DateString date={takenDate} />
          &nbsp;
      </PlanView.Highlight>
      {name}
    </PlanView.Item>
  )

  return (
    <PlanView>
      <PlanView.Icon icon="sessions" />
      <PlanView.Content>
        <PlanView.Title title={i18n.t('module:Children:Child:Finance:PlanView:Summary:extraSessions')} />
        {_.map(childAdhocSessions, renderAdhocSession)}
      </PlanView.Content>
    </PlanView>
  )
}

export default PlanDetailAdhocSessions
