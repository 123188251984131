import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'recompose'

import { Form } from 'components'

export const EDITING_STAFF_ROTA_FORM = 'EDITING_STAFF_ROTA_FORM'

type SubmitFunctionType = {
  onSubmit?: (fields: any) => void
}

interface EditingStaffRotaFormProps {
  handleSubmit?: (submitFunc: SubmitFunctionType) => () => void
  isSubmitting?: boolean
  onSubmit?: SubmitFunctionType
  options?: {
    label: string
    value: string
  }[]
}

const EditingStaffRotaForm: React.FC<EditingStaffRotaFormProps> = ({
  handleSubmit,
  isSubmitting,
  onSubmit,
  options,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row>
      <Form.Row.FlexItem>
        <Field
          component={Form.RadioGroup}
          disabled={isSubmitting}
          name="editingStaffRota"
          options={options}
        />
      </Form.Row.FlexItem>
    </Form.Row>
  </Form>
)

const enhance = compose(reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: true,
  form: EDITING_STAFF_ROTA_FORM,
}))

export default enhance(EditingStaffRotaForm)
