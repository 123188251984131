import React from 'react'
import { ReactSVG } from 'react-svg'

import { generateRoute } from 'utils/routing'

import { Banner, Callout, Hyperlink, Space, Spinner, Typography } from 'components'

import i18n from 'translations'

import BoxWrapper from '../components/BoxWrapper'
import SetPasswordForm from '../components/SetPasswordForm'
import { StyledLink, StyledLinkExpired, StyledLinkExpiredContainer } from './InvitationStyled'

interface InvitationViewProps {
  errorMessages: string[]
  isFetching: boolean
  isInitialized: boolean
  isLinkExpired: boolean
  onSubmit: (values: any) => void
}

const LINKS = {
  childProtection: 'https://blossomeducational.com/child-protection-policy',
  security: 'https://blossomeducational.com/wp-content/uploads/2018/03/AWS-for-nurseries.pdf',
  terms: 'https://blossomeducational.com/wp-content/uploads/2018/05/Blossom-Educational-B-Conditions-v-18-May-2018.pdf',
}

const InvitationView: React.FC<InvitationViewProps> = ({
  errorMessages,
  isFetching,
  isInitialized,
  isLinkExpired,
  onSubmit,
}) => {
  const renderFooterText = () => (
    <Typography align="center" fontSize={12} lineHeight={18} padding="10px">
      {i18n.t('module:Authentication:Invitation:footerText', {
        components: [
          // @ts-ignore
          <StyledLink href={LINKS.terms} rel="noreferrer noopener" target="_blank" />,
          // @ts-ignore
          <StyledLink href={LINKS.childProtection} rel="noreferrer noopener" target="_blank" />,
          // @ts-ignore
          <StyledLink href={LINKS.security} rel="noreferrer noopener" target="_blank" />,
        ],
      })}
    </Typography>
  )

  if (!isInitialized) {
    return (
      <React.Fragment>
        <Space space="20px" />
        <Spinner />
      </React.Fragment>
    )
  }

  if (isLinkExpired) {
    return (
      <StyledLinkExpired>
        <StyledLinkExpiredContainer>
          <ReactSVG src="/images/link-expired.svg" />
        </StyledLinkExpiredContainer>
        <div>
          <Typography align="center" variant="h1" tertiary>
            {i18n.t('module:Authentication:Invitation:linkExpired1')}
          </Typography>
          <Space space="30px" />
          <Typography align="center" variant="h4">
            {i18n.t('module:Authentication:Invitation:linkExpired2')}
          </Typography>
          <Space space="10px" />
          <Typography align="center" variant="h6">
            {i18n.t('module:Authentication:Invitation:linkExpired3')}
          </Typography>
        </div>
      </StyledLinkExpired>
    )
  }

  return (
    <BoxWrapper
      footer={(
        <Hyperlink to={generateRoute('AUTHENTICATION.LOGIN')} primary>
          {i18n.t('module:Authentication:Invitation:alreadyGotALogin')}
        </Hyperlink>
      )}
    >
      <Callout content={errorMessages} error />
      <Banner.Info margin="15px 0 -5px">
        {i18n.t('module:Authentication:Invitation:thankYouForJoining')}
      </Banner.Info>
      <SetPasswordForm
        footerText={renderFooterText()}
        isFetching={isFetching}
        submitLabel={i18n.t('module:Authentication:Invitation:register')}
        onSubmit={onSubmit}
      />
    </BoxWrapper>
  )
}

export default InvitationView
