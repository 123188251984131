import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { ROLES } from 'constants/security'

import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withCarersService } from 'services/legacy/carers'
import { withPaginationUtils } from 'services/utils/pagination'

import { getTableData } from './helpers'

import ChildContactsListView from './ChildContactsListView'

const CARER_GROUPS = {
  read: [
    'carer.carerChildRelations',
    'carerChildRelation.details',
    'carerChildRelation.child',
  ],
}

class ChildContactsListContainer extends Component {
  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { carersActions } = this.props

    carersActions.clear()
  }

  fetch = () => {
    const {
      carersActions,
      carersSelectors,
      paginationUtils,
      params,
    } = this.props
    const { childId } = params
    const { page } = paginationUtils

    carersActions.list({
      params: [{
        criteria: carersSelectors.getListCriteria({ childId }),
        groups: CARER_GROUPS,
        page,
      }],
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  render() {
    const {
      authAccessMap,
      carers,
      errorMessages,
      isFetching,
      paginationUtils,
      params,
      totalResults,
    } = this.props
    const { getPageCount, page, perPage } = paginationUtils
    const { childId } = params

    const pageCount = getPageCount(totalResults)
    const tableData = getTableData(carers, +childId)

    return (
      <ChildContactsListView
        authAccessMap={authAccessMap}
        childId={+childId}
        errorMessages={errorMessages}
        isEmpty={!totalResults}
        isFetching={isFetching}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        tableData={tableData}
        totalResults={totalResults}
        onPageChange={this.handlePageChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  carersListState,
  carersSelectors,
}) => ({
  authAccessMap: {
    module: {
      ChildContactsAdd: auth.SELECTORS.getIsAuthorised(state, {
        roles: [
          ROLES.SUPER_ADMIN,
          ROLES.ORGANIZATION_DIRECTOR,
          ROLES.ORGANIZATION_NATIONAL_ADMIN,
          ROLES.ORGANIZATION_LINE_MANAGER,
          ROLES.DEPUTY_MANAGER,
          ROLES.NURSERY_MANAGER,
          ROLES.ROOM_LEADER,
        ],
      }),
    },
  },
  carers: carersSelectors.getCarersListState(state),
  errorMessages: appSelectors.getErrorMessages(carersListState),
  isFetching: appSelectors.getIsFetching(carersListState),
  totalResults: appSelectors.getTotalResults(carersListState),
})

const enhance = compose(
  withAppService,
  withCarersService,
  withPaginationUtils,
  connect(mapState),
)

export default enhance(ChildContactsListContainer)
