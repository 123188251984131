import React, { Component } from 'react'

import { WrappedComponentType } from 'constants/types'
import { SORT_ORDER, noop } from 'constants/global'

import { withRouteUtilsProps, withRouterUtils } from 'services/utils/router'

interface SortUtilsComponentState {
  sortField?: string
  sortOrder?: string
}

export interface SortUtilsProps extends SortUtilsComponentState {
  onSortChange?: (cb?: any) => (field: any, order?: string, paramsToRemove?: any[]) => any
  order?: string
}

export interface withSortUtilsProps extends withRouteUtilsProps {
  sortingUtils?: SortUtilsProps
}

const withSortingUtils = <P extends withSortUtilsProps> (
  WrappedComponent: WrappedComponentType<P>,
) => {
  class EnhancedComponent extends Component<P & withSortUtilsProps, SortUtilsComponentState> {
    static authParams = {}

    constructor(props) {
      super(props)

      const { sortField = '', sortOrder = SORT_ORDER.DESC } = props.location.query

      this.state = {
        sortField,
        sortOrder,
      }
    }

    getSortOrder = (field) => {
      const { sortField, sortOrder } = this.state

      if (sortField === field && sortOrder === SORT_ORDER.DESC) {
        return SORT_ORDER.ASC
      }

      return SORT_ORDER.DESC
    }

    handleSortChange = (cb = noop) => (field, order = SORT_ORDER.DESC, paramsToRemove = []) => {
      const { setLocationQuery } = this.props

      const sortField = field
      const sortOrder = order !== SORT_ORDER.DESC ? order : this.getSortOrder(field)

      setLocationQuery({ sortField, sortOrder }, paramsToRemove)

      this.setState({ sortField, sortOrder }, cb)
    }

    render() {
      const { sortField, sortOrder } = this.state

      const sort = `${sortField} ${sortOrder}`
      const order = !sortField ? null : (sort.trim() || SORT_ORDER.DESC)

      const sortingUtils: SortUtilsProps = {
        onSortChange: this.handleSortChange,
        order,
        sortField,
        sortOrder,
      }

      return <WrappedComponent sortingUtils={sortingUtils} {...this.props as P} />
    }
  }

  EnhancedComponent.authParams = WrappedComponent.authParams

  return withRouterUtils(EnhancedComponent)
}

export default withSortingUtils
