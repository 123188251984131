import React, { PropsWithChildren } from 'react'
import { ReactSVG } from 'react-svg'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'

import { getIsLoggedIn } from 'utils/auth/selectors'

import { withSubdomainService } from 'services/subdomain'

import { Typography } from 'components'

import i18n from 'translations'

import logo from 'assets/images/blossom-logo.svg'

import { StyledBabyImage, StyledContainer, StyledCustomLogo, StyledLink, StyledLogo } from './VariousPageWrapperStyled'

const mapState = (state, { subdomainSelectors }) => ({
  customBranding: subdomainSelectors.getCustomBranding(state),
  customLogo: subdomainSelectors.getCustomLogo(state),
  isBranded: subdomainSelectors.subdomainIsBranded(state),
  isLoggedIn: getIsLoggedIn(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const VariousPageWrapper: React.FC<PropsWithChildren<PropsFromRedux>> = ({
  children,
  customBranding,
  customLogo,
  isBranded,
}) => (
  <StyledContainer>
    <StyledLink to="/">
      <StyledLogo src={logo} />
    </StyledLink>
    <StyledBabyImage>
      <ReactSVG
        src="/images/404-baby.svg"
      />
      {isBranded && customLogo && (
        <StyledCustomLogo>
          <img
            alt={customBranding.browserTitle || ''}
            src={customLogo}
          />
        </StyledCustomLogo>
      )}
    </StyledBabyImage>
    <Typography
      fontSize={24}
      tertiary
    >
      {i18n.t('module:Various:NotFound:text1')}
    </Typography>
    {children}
  </StyledContainer>
)

const enhance = compose(
  withSubdomainService,
  connector,
)

export default enhance(VariousPageWrapper)
