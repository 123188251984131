import styled from 'styled-components'

export const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 10px;
  position: relative;
  background-color: #F7F7F7;

  @media(min-width: 768px) {
    ${({ size }) => 'small' !== size && 'padding: 20px 10px;'}
    padding: ${({ size }) => ('small' === size ? '20px 10px;' : '20px')};
  }
`

export const StyledLoadingContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`
