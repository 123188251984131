import { generateBasicActions } from 'utils/service'

import {
  CLEAR_ENQUIRY_VIEWINGS_SINGLE,
  CREATE_ENQUIRY_VIEWINGS,
  CREATE_ENQUIRY_VIEWINGS_FAILED,
  CREATE_ENQUIRY_VIEWINGS_SUCCESS,
  GET_ENQUIRY_VIEWINGS,
  GET_ENQUIRY_VIEWINGS_FAILED,
  GET_ENQUIRY_VIEWINGS_SUCCESS,
  UPDATE_ENQUIRY_VIEWINGS,
  UPDATE_ENQUIRY_VIEWINGS_FAILED,
  UPDATE_ENQUIRY_VIEWINGS_SUCCESS,
} from './constants'

export const create = (options = {}) => ({ enquiryViewingsApiClient }) => generateBasicActions.create({
  constants: {
    failed: CREATE_ENQUIRY_VIEWINGS_FAILED,
    init: CREATE_ENQUIRY_VIEWINGS,
    success: CREATE_ENQUIRY_VIEWINGS_SUCCESS,
  },
  options,
  service: enquiryViewingsApiClient,
  serviceMethod: 'create',
})

export const get = (options = {}) => ({ enquiryViewingsApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_ENQUIRY_VIEWINGS_FAILED,
    init: GET_ENQUIRY_VIEWINGS,
    success: GET_ENQUIRY_VIEWINGS_SUCCESS,
  },
  options,
  service: enquiryViewingsApiClient,
  serviceMethod: 'get',
})

export const update = (options = {}) => ({ enquiryViewingsApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_ENQUIRY_VIEWINGS_FAILED,
    init: UPDATE_ENQUIRY_VIEWINGS,
    success: UPDATE_ENQUIRY_VIEWINGS_SUCCESS,
  },
  options,
  service: enquiryViewingsApiClient,
  serviceMethod: 'update',
})

export const clearSingle = () => ({
  type: CLEAR_ENQUIRY_VIEWINGS_SINGLE,
})
