import _ from 'lodash'
import { ofType } from 'redux-observable'
import { EMPTY, Subject, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { bufferCount, bufferTime, delay, filter, groupBy, map, merge, mergeMap, takeUntil } from 'rxjs/operators'

import {
  AUTOSAVE_OBSERVATION_SUCCESS,
  UPDATE_DRAFT_OBSERVATION_SUCCESS,
  UPDATE_REVIEW_OBSERVATION_SUCCESS,
} from 'services/legacy/observations/single/constants'
import { UPDATE_ACTIVITY_SUCCESS } from 'services/legacy/dailyDiaryActivities/single/constants'

import { getBackendErrors } from 'utils/backendErrors'
import eventBus from 'utils/eventBus'

import { selectors as filesSelectors } from 'services/legacy/files'
import snackbarActions from 'services/utils/snackbar/actions'

import i18n from 'translations'

import { properties } from 'app-config'

import * as uploadSelectors from '../selectors'
import uploadActions from '../actions'
import { REFERENCE_ASYNC_PAGE_TYPES, UPDATE_PROGRESS_EVENT } from '../constants'
import {
  ADD_FILE_TO_REFERENCE_PAGE,
  ADD_FILE_TO_REFERENCE_PAGE_SUCCESS,
  FILE_SIZE_TOO_LARGE,
  MARK_FILE_TO_REMOVE,
  START_UPLOAD_VIDEO,
  UPDATE_REFERENCE_PAGES_STORAGE,
  UPDATE_UPLOAD_FILE,
  UPDATE_UPLOAD_FILE_SUCCESS,
  UPLOADED_FILE_VALIDATION_ERROR,
} from './constants'

const awsParams = [
  'key',
  'X-Amz-Credential',
  'X-Amz-Algorithm',
  'X-Amz-Date',
  'Policy',
  'X-Amz-Signature',
  'bucket',
  'Content-Type',
  'acl',
  'success_action_status',
]

const FILES_GROUPS = {
  read: ['file.file'],
}

const CREATE_OBSERVATION_AND_ACTIVITY_GROUPS = {
  read: [
    'children',
    'medium',
    'medium.children',
    'child',
    'childInformation',
  ],
}

export const streamFileEpic = (action$) => action$.pipe(
  ofType(
    START_UPLOAD_VIDEO,
  ),
  mergeMap((action) => {
    const { file: { awsConfig, file, id } } = action
    const { data: { endpoint, payload } } = awsConfig || {}
    const progressSubscriber = new Subject()
    const body = new FormData()

    if (!endpoint) {
      return [{
        payload: id,
        type: MARK_FILE_TO_REMOVE,
      }]
    }

    _.each(awsParams, (key) => {
      body.append(key, payload[key])
    })

    body.append('file', file)

    const request = ajax({
      body,
      method: 'POST',
      progressSubscriber,
      url: endpoint,
    })

    const requestObservable = request
      .pipe(
        map(() => uploadActions.updateUploadFile(id, {
          isUploaded: true,
          uploadingInProgress: false,
          waitingForProcessing: true,
        })),
        takeUntil(
          action$.pipe(
            ofType(MARK_FILE_TO_REMOVE),
            filter((subAction) => {
              const { payload: fileMarkedToRemove } = subAction

              return id === fileMarkedToRemove
            }),
          ),
        ),
      )

    return progressSubscriber
      .pipe(
        bufferCount(2),
        map((arr) => arr[arr.length - 1]),
        mergeMap((data) => {
          eventBus.dispatch(UPDATE_PROGRESS_EVENT, {
            id,
            uploadedData: data.loaded,
          })

          return [uploadActions.updateUploadFile(id, {
            uploadedData: data.loaded,
          })]
        }),
        filter(() => false),
        merge(requestObservable),
      )
  }),
)

export const initFileToUploadOnBeEpic = (action$, state$) => action$.pipe(
  ofType(
    UPDATE_UPLOAD_FILE,
  ),
  groupBy((item) => item.payload.id),
  mergeMap((group) => group.pipe(
    bufferTime(200),
    filter((items) => items.length),
    mergeMap((action) => [_.merge(...action)]),
    mergeMap((action) => {
      const { payload: { id } } = action
      const file = uploadSelectors.getFileById(id)(state$.value)

      if (file?.initialization.onFe && !file?.initialization.onFeSecond) {
        return [
          uploadActions.updateUploadFile(id, {
            initialization: {
              onFeSecond: true,
            },
          }),
          {
            file,
            type: ADD_FILE_TO_REFERENCE_PAGE,
          },
        ]
      }

      return [{
        type: UPDATE_UPLOAD_FILE_SUCCESS,
      }]
    }),
  )),
)

export const uploadedFileValidationErrorEpic = (action$) => action$.pipe(
  ofType(
    UPLOADED_FILE_VALIDATION_ERROR,
  ),
  mergeMap((action) => {
    const { message, payload } = action

    return [
      snackbarActions.show({
        message: message?.[0],
      }), {
        payload,
        type: MARK_FILE_TO_REMOVE,
      },
    ]
  }),
)

const addFileToReferenceFilesListResponse = (action, filesApiClient, {
  createMethod,
  filesType,
  getMethod,
}) => async (awsConfig) => {
  const { file: { id, mimeType, name, referencePage, size } } = action
  const { data: { endpoint, payload: { key } } } = awsConfig
  const pageId = referencePage[1]

  try {
    const body = filesSelectors[getMethod]({
      [filesType]: pageId,
      mimeType,
      name,
      size,
      url: `${endpoint}/${key}`,
    })

    const params = {
      groups: FILES_GROUPS,
    }

    const result = await filesApiClient[createMethod](params, body)
    const { file, id: beId, name: newName } = result?.data || {}

    return [
      uploadActions.updateUploadFile(id, {
        awsConfig,
        file,
        inQueue: false,
        name: newName,
      }),
      {
        file: {
          ...action.file,
          awsConfig,
          beId,
        },
        type: ADD_FILE_TO_REFERENCE_PAGE_SUCCESS,
      },
    ]
  } catch ({ response }) {
    const message = response && _.map(getBackendErrors(response || {}), (i) => _.map(i)).flat(Infinity)
    if (response.extra['']) {
      message.push(response.extra[''])
    }

    return {
      message,
      payload: id,
      type: UPLOADED_FILE_VALIDATION_ERROR,
    }
  }
}

export const addFileToReferencePageEpic = (action$, state$, {
  dailyDiaryActivitiesApiClientV2,
  filesApiClient,
  observationsApiClient,
  uploadApiClient,
}) => action$.pipe(
  ofType(
    ADD_FILE_TO_REFERENCE_PAGE,
  ),
  mergeMap(async (action) => {
    const uploadingFilesInProgress = uploadSelectors.getUploadingFilesInProgress()(state$.value)

    if (uploadingFilesInProgress?.length >= properties.upload.chunk) {
      return {
        action,
        repeat: true,
      }
    }

    const { file } = action
    const { id: fileId, mimeType, name, referencePage, size, taggedChildren } = file
    const [pageType, pageId] = referencePage
    const fullFile = uploadSelectors.getFileById(fileId)(state$.value)

    if (!fullFile) {
      return {
        type: EMPTY,
      }
    }

    const initBody = uploadSelectors.getInitFileBody(fullFile)
    let exception = null

    try {
      const awsConfig = await uploadApiClient.initFile(initBody)
      const { data: { endpoint, payload: { key } } } = awsConfig
      let changes = {}
      const params = {
        groups: CREATE_OBSERVATION_AND_ACTIVITY_GROUPS,
      }

      if (REFERENCE_ASYNC_PAGE_TYPES.OBSERVATION === pageType) {
        const response = await observationsApiClient.addObservationMedia(pageId, params, [{
          children: _.map(taggedChildren, ({ id: childId }) => ({ id: childId })),
          mimeType,
          name,
          size,
          url: `${endpoint}/${key}`,
        }])

        const { data: [{ exception: mediumException, medium }] } = response
        exception = mediumException
        changes = { ...medium }
      }

      if (REFERENCE_ASYNC_PAGE_TYPES.ACTIVITY === pageType) {
        const response = await dailyDiaryActivitiesApiClientV2.addActivityMedia(pageId, params, [{
          children: _.map(taggedChildren, ({ id: childId }) => ({ id: childId })),
          mimeType,
          name,
          size,
          url: `${endpoint}/${key}`,
        }])

        const { data: [{ exception: mediumException, medium }] } = response
        exception = mediumException
        changes = { ...medium }
      }

      if (REFERENCE_ASYNC_PAGE_TYPES.MEMBERSHIP === pageType) {
        return addFileToReferenceFilesListResponse(action, filesApiClient, {
          createMethod: 'createMembershipFile',
          filesType: 'membership',
          getMethod: 'getMembershipFileValuesForm',
        })(awsConfig)
      }

      if (REFERENCE_ASYNC_PAGE_TYPES.CHILD === pageType) {
        return addFileToReferenceFilesListResponse(action, filesApiClient, {
          createMethod: 'createChildFile',
          filesType: 'child',
          getMethod: 'getChildFileValuesForm',
        })(awsConfig)
      }

      if (REFERENCE_ASYNC_PAGE_TYPES.NURSERY === pageType) {
        return addFileToReferenceFilesListResponse(action, filesApiClient, {
          createMethod: 'createNurseryFile',
          filesType: 'nursery',
          getMethod: 'getNurseryFileValuesForm',
        })(awsConfig)
      }

      delete changes.type

      if (exception) {
        const errors = getBackendErrors(exception?.error)

        return {
          ...action,
          errors: {
            media: _.join(_.values(errors), ', '),
          },
          remove: true,
        }
      }

      return [
        uploadActions.updateUploadFile(fileId, {
          awsConfig,
          inQueue: false,
          uploadingInProgress: true,
          ...changes,
        }),
        {
          ...action,
          type: ADD_FILE_TO_REFERENCE_PAGE_SUCCESS,
        },
      ]
    } catch ({ response }) {
      const errors = getBackendErrors(response || {})

      if (errors?.size) {
        return {
          payload: file,
          type: FILE_SIZE_TOO_LARGE,
        }
      }

      return {
        ...action,
        errors,
        remove: true,
      }
    }
  }, 1),
  mergeMap((action) => {
    if (action.repeat) {
      return of(action.action).pipe(
        delay(1000),
        mergeMap(() => [{
          ...action.action,
          type: ADD_FILE_TO_REFERENCE_PAGE,
        }]),
      )
    }

    if (action.remove) {
      const actions = [
        {
          payload: action.file.id,
          type: MARK_FILE_TO_REMOVE,
        },
      ]

      if (action.errors?.media) {
        actions.push(
          snackbarActions.show({
            message: action.errors.media,
          }),
        )
      }

      return actions
    }

    return _.isArray(action) ? action : [action]
  }),
)

export const startUploadFileEpic = (action$) => action$.pipe(
  ofType(
    ADD_FILE_TO_REFERENCE_PAGE_SUCCESS,
  ),
  mergeMap((action) => {
    const { file: { id } } = action

    return [
      uploadActions.updateUploadFile(id, {
        uploadingInProgress: true,
      }), {
        ...action,
        type: START_UPLOAD_VIDEO,
      },
    ]
  }),
)

export const updateObservationReferenceContextEpic = (action$) => action$.pipe(
  ofType(
    AUTOSAVE_OBSERVATION_SUCCESS,
    UPDATE_DRAFT_OBSERVATION_SUCCESS,
    UPDATE_REVIEW_OBSERVATION_SUCCESS,
  ),
  mergeMap(async (action) => {
    const { payload: { data: { childObservations, id } } } = action
    const parsedChildNames = _.map(childObservations, ({ child: { displayName } }) => displayName)
      .join(', ')

    return {
      payload: {
        id,
        name: `${i18n.t('module:Shell:UploadRoot:types:observationFor')} ${parsedChildNames}`,
        type: REFERENCE_ASYNC_PAGE_TYPES.OBSERVATION,
      },
      type: UPDATE_REFERENCE_PAGES_STORAGE,
    }
  }),
)

export const updateActivityReferenceContextEpic = (action$) => action$.pipe(
  ofType(
    UPDATE_ACTIVITY_SUCCESS,
  ),
  mergeMap(async (action) => {
    const { payload: { id, name } } = action

    return {
      payload: {
        id,
        name: `${i18n.t('module:DailyDiary:Activities:activity')}: ${name}`,
        type: REFERENCE_ASYNC_PAGE_TYPES.ACTIVITY,
      },
      type: UPDATE_REFERENCE_PAGES_STORAGE,
    }
  }),
)
