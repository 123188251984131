export const CLEAR_CHILD_CARER_RELATION = 'CLEAR_CHILD_CARER_RELATION'

export const CREATE_CHILD_CARER_RELATION = 'CREATE_CHILD_CARER_RELATION'
export const CREATE_CHILD_CARER_RELATION_FAILED = 'CREATE_CHILD_CARER_RELATION_FAILED'
export const CREATE_CHILD_CARER_RELATION_SUCCESS = 'CREATE_CHILD_CARER_RELATION_SUCCESS'

export const UPDATE_CHILD_CARER_RELATION = 'UPDATE_CHILD_CARER_RELATION'
export const UPDATE_CHILD_CARER_RELATION_FAILED = 'UPDATE_CHILD_CARER_RELATION_FAILED'
export const UPDATE_CHILD_CARER_RELATION_SUCCESS = 'UPDATE_CHILD_CARER_RELATION_SUCCESS'

export const REMOVE_CHILD_CARER_RELATION = 'REMOVE_CHILD_CARER_RELATION'
export const REMOVE_CHILD_CARER_RELATION_FAILED = 'REMOVE_CHILD_CARER_RELATION_FAILED'
export const REMOVE_CHILD_CARER_RELATION_SUCCESS = 'REMOVE_CHILD_CARER_RELATION_SUCCESS'
