import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'
import { formValueSelector } from 'redux-form'

import { toFloat, toInt } from 'utils/data'

import { getFeeCalculationsOptions } from 'services/legacy/nurseryExtraItems/single/selectors'

import { CHILD_EXTRA_ITEMS_TYPES, CHILD_EXTRA_ITEMS_TYPE_OPTIONS } from '../constants'

export const getChildExtraItemsSelectors = (state) => state.childExtraItems

export const getChildExtraItemsSingleDataSelectors = createSelector(
  [getChildExtraItemsSelectors],
  (state) => state.single.data,
)

export const getPayloadSelector = createSelector(
  [(fields) => fields],
  (fields) => {
    if (!fields) {
      return null
    }

    const {
      attendancePeriod,
      childId,
      endDate,
      extraItem,
      feeCalculations,
      ongoing,
      quantity,
      startDate,
      type,
    } = fields
    const { unitPrice, value: extraItemId } = extraItem
    const { value: typeValue } = type
    const { value: feeCalculationValue } = feeCalculations || {}

    const { ONE_OFF } = CHILD_EXTRA_ITEMS_TYPES

    const getEndDate = () => {
      if (ONE_OFF === typeValue || ongoing) {
        return undefined
      }

      return moment(endDate).format('YYYY-MM-DD')
    }

    return {
      amount: toFloat(unitPrice) * parseInt(quantity),
      attendancePeriod: { id: attendancePeriod?.value },
      child: { id: toInt(childId) },
      endDate: getEndDate(),
      extraItem: { id: extraItemId, type: 'nursery' },
      feeCalculations: feeCalculationValue,
      ongoing,
      quantity: toInt(quantity),
      startDate: moment(startDate).format('YYYY-MM-DD'),
      type: typeValue,
    }
  },
)

export const getArchivedPayloadSelector = createSelector(
  [(fields) => fields],
  (fields) => {
    if (!fields) {
      return null
    }

    const { archived, type } = fields

    return {
      archived,
      type,
    }
  },
)

const formSelector = (formName) => formValueSelector(formName)

const typeFieldSelector = (formName) => (state) => formSelector(formName)(state, 'type')
const extraItemFieldSelector = (formName) => (state) => formSelector(formName)(state, 'extraItem')
const quantityFieldSelector = (formName) => (state) => formSelector(formName)(state, 'quantity')
const ongoingFieldSelector = (formName) => (state) => formSelector(formName)(state, 'ongoing')
const startDateFieldSelector = (formName) => (state) => formSelector(formName)(state, 'startDate')

export const getExtraItem = (formName) => createSelector(
  [extraItemFieldSelector(formName)],
  (extraItemOption) => {
    if (!extraItemOption) {
      return null
    }

    return extraItemOption
  },
)

export const getDateRangeFlag = (formName) => createSelector(
  [typeFieldSelector(formName)],
  (typeOption) => {
    if (!typeOption) {
      return false
    }

    const { value } = typeOption

    return CHILD_EXTRA_ITEMS_TYPES.ONE_OFF !== value
  },
)

export const getCost = (formName) => createSelector(
  [
    extraItemFieldSelector(formName),
    quantityFieldSelector(formName),
    typeFieldSelector(formName),
  ],
  (extraItemOption, quantity, typeOption) => {
    if (!extraItemOption || !extraItemOption.unitPrice || !typeOption) {
      return null
    }

    const { value } = typeOption
    const { unitPrice } = extraItemOption

    const newQuantity = CHILD_EXTRA_ITEMS_TYPES.ONE_OFF === value ? quantity : 1

    return toInt(newQuantity) * toFloat(unitPrice)
  },
)

export const getEndDateDisabledFlag = (formName) => createSelector(
  [ongoingFieldSelector(formName)],
  (ongoing) => !!ongoing,
)

export const getDescription = (formName) => createSelector(
  [typeFieldSelector(formName), startDateFieldSelector(formName)],
  (typeOption, startDate) => {
    if (!typeOption) {
      return false
    }

    const { value } = typeOption

    switch (value) {
      case CHILD_EXTRA_ITEMS_TYPES.ONE_OFF:
        return 'One off extra items will be billed as actuals.'

      case CHILD_EXTRA_ITEMS_TYPES.WEEKLY:
        return startDate
          // eslint-disable-next-line max-len
          ? `This will take place on the ${moment(startDate).format('DD/MM/YYYY')} and then be repeated on the ${moment(startDate).format('dddd')} of every subsequent week.`
          : null

      case CHILD_EXTRA_ITEMS_TYPES.MONTHLY:
        return startDate
          // eslint-disable-next-line max-len
          ? `This will take place on the ${moment(startDate).format('DD/MM/YYYY')} and then be repeated on the 1st of every subsequent month.`
          : null

      default:
        return startDate ? 'One off extra items will be billed as actuals.' : null
    }
  },
)

export const getFeeCalculationFlag = (formName) => createSelector(
  [typeFieldSelector(formName)],
  (typeOption) => {
    if (!typeOption) {
      return false
    }

    const { value } = typeOption

    return CHILD_EXTRA_ITEMS_TYPES.WEEKLY === value
  },
)

export const getQuantityFlag = (formName) => createSelector(
  [typeFieldSelector(formName)],
  (typeOption) => {
    if (!typeOption) {
      return false
    }

    const { value } = typeOption

    return CHILD_EXTRA_ITEMS_TYPES.ONE_OFF === value
  },
)

export const getInitialValues = createSelector(
  [getChildExtraItemsSingleDataSelectors, getFeeCalculationsOptions],
  (singleData, nurseryExtraItemsFeeCalculationOption) => {
    if (!singleData || !nurseryExtraItemsFeeCalculationOption?.length) {
      return null
    }

    const { attendancePeriod, endDate, extraItem, feeCalculations, ongoing, quantity, startDate, type } = singleData
    const { id: extraItemId, name: extraItemName, unitPrice } = extraItem
    const { id: attendancePeriodId, name: attendancePeriodName } = attendancePeriod

    const feeCalculationsOption = _.find(nurseryExtraItemsFeeCalculationOption, { value: feeCalculations })
    const typeOption = _.find(CHILD_EXTRA_ITEMS_TYPE_OPTIONS, { value: type })

    return {
      attendancePeriod: {
        label: attendancePeriodName,
        value: attendancePeriodId,
      },
      endDate,
      extraItem: { label: extraItemName, unitPrice, value: extraItemId },
      feeCalculations: feeCalculationsOption,
      ongoing,
      quantity,
      startDate,
      type: typeOption,
    }
  },
)

export const isArchivedSelector = createSelector(
  [getChildExtraItemsSingleDataSelectors],
  (singleData) => {
    if (!singleData) {
      return null
    }

    const { archived } = singleData

    return archived
  },
)
