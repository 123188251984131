import moment from 'moment'

import React from 'react'

import {
  StyledContainer,
  StyledTable,
  StyledTitleContainer,
  StyledTitleHighlight,
} from './FrameworkBulkMarkPreviewStyled'

const FrameworkBulkMarkPreview = ({ currentValues, itemsCount }) => {
  const { markAs = {}, ageBand = {}, area = {}, aspect = {}, date, markAll } = currentValues

  const ageBandLabel = `${ageBand.label}${markAll ? ' (all age bands up to and including this)' : ''}`

  return (
    <StyledContainer>
      <StyledTitleContainer>
        Do you want to bulk mark&nbsp;
        <StyledTitleHighlight>{itemsCount}</StyledTitleHighlight>
        &nbsp;statements as&nbsp;
        <StyledTitleHighlight color={markAs.color}>{markAs.label.toLowerCase()}</StyledTitleHighlight>
        &nbsp;on&nbsp;
        <StyledTitleHighlight>{moment(date).format('DD/MM/YYYY')}</StyledTitleHighlight>
        ?
      </StyledTitleContainer>
      These statements include:
      <StyledTable cellPadding="5px 0">
        <tr>
          <th>Age band</th>
          <td>{ageBandLabel}</td>
        </tr>
        <tr>
          <th>Area of Learning</th>
          <td>{area.label}</td>
        </tr>
        <tr>
          <th>Aspect</th>
          <td>{aspect ? aspect.label : undefined}</td>
        </tr>
      </StyledTable>
      Please note: this will not affect any previously generated formative reports.
    </StyledContainer>
  )
}

export default FrameworkBulkMarkPreview
