import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { RootState } from 'core/reducers'

import { injectActions } from 'utils/service'

import actions from './actions'
import selectors from './selectors'
import { ProductPriceGroupsListState } from './list/reducer'

export interface withProductPriceGroupsServiceProps {
  productPriceGroupsActions: typeof actions
  productPriceGroupsListState: ProductPriceGroupsListState
  productPriceGroupsSelectors: typeof selectors
}

const withProductPriceGroupsService = <P extends withProductPriceGroupsServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ productPriceGroups }: RootState) => ({ productPriceGroups })

  const mapDispatch = injectActions('productPriceGroupsActions', actions)

  const Component = ({ productPriceGroups, productPriceGroupsActions, ...others }) => (
    <WrappedComponent
      productPriceGroupsActions={productPriceGroupsActions}
      productPriceGroupsListState={productPriceGroups.list}
      productPriceGroupsSelectors={selectors}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withProductPriceGroupsService
