import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'

import BirthdaysWidgetView from './BirthdaysWidgetView'

const AFTER_DATE = '01-01'
const DATE_FORMAT = 'MM-DD'
const LIMIT_PAGE = 10
const MIN_RESULTS = 3

class BirthdaysWidgetContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      nextYearPage: 1,
      thisYearPage: 1,
    }
  }

  componentDidMount() {
    this.fetchThisYearBirthdays(true)
  }

  componentWillUnmount() {
    const { childActions } = this.props

    childActions.clearBirthdaysList()
  }

  fetch = () => {
    const { isThisYearFetched } = this.props

    if (!isThisYearFetched) {
      this.fetchThisYearBirthdays()
    } else {
      this.fetchNextYearBirthdays()
    }
  }

  fetchThisYearBirthdays = (initial = false) => {
    const { childActions, childSelectors: { getBirthdaysCriteria } } = this.props
    const { thisYearPage } = this.state

    const filters = {
      after: moment().format(DATE_FORMAT),
      isArchived: false,
      order: 'ASC',
    }

    const params = {
      criteria: getBirthdaysCriteria(filters),
      limit: LIMIT_PAGE,
      page: thisYearPage,
    }

    childActions.getBirthdaysUntilEndOfYear({
      mergeResult: true,
      onSuccess: ({ data }) => this.handleFetchThisYearBirthdaysSuccess(data, initial),
      params,
    })
  }

  handleFetchThisYearBirthdaysSuccess = (data, initial) => {
    const { thisYearPage } = this.state

    this.setState({ thisYearPage: thisYearPage + 1 })

    if (initial && MIN_RESULTS > data.length) {
      this.fetchNextYearBirthdays()
    } else {
      this.setState({ isLoading: false })
    }
  }

  fetchNextYearBirthdays = () => {
    const { childActions, childSelectors: { getBirthdaysCriteria } } = this.props
    const { nextYearPage } = this.state

    const filters = {
      after: AFTER_DATE,
      before: moment().subtract(1, 'day').format(DATE_FORMAT),
      isArchived: false,
      order: 'ASC',
    }

    const params = {
      criteria: getBirthdaysCriteria(filters),
      limit: LIMIT_PAGE,
      page: nextYearPage,
    }

    childActions.getBirthdaysUntilYesterday({
      mergeResult: true,
      onSuccess: () => { this.setState({ isLoading: false, nextYearPage: nextYearPage + 1 }) },
      params,
    })
  }

  handleLoadNextPage = () => {
    this.fetch()
  }

  render() {
    const {
      isNextYearFetched,
      isThisYearFetched,
      todayBirthdays,
      upcomingBirthdays,
    } = this.props
    const {
      isLoading,
    } = this.state

    return (
      <BirthdaysWidgetView
        hasMore={!isThisYearFetched || (!isNextYearFetched && AFTER_DATE !== moment().format(DATE_FORMAT))}
        isLoading={isLoading}
        todayBirthdays={todayBirthdays}
        upcomingBirthdays={upcomingBirthdays}
        onLoadNextPage={this.handleLoadNextPage}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childBirthdaysListState,
  childSelectors,
}) => ({
  errorMessages: appSelectors.getErrorMessages(childBirthdaysListState),
  isNextYearFetched: childSelectors.getIsNextYearFetched(state),
  isThisYearFetched: childSelectors.getIsThisYearFetched(state),
  todayBirthdays: childSelectors.getTodayBirthdays(state),
  upcomingBirthdays: childSelectors.getUpcomingBirthdays(state),
})

const enhance = compose(
  withAppService,
  withChildService,
  connect(mapState),
)

export default enhance(BirthdaysWidgetContainer)
