import moment from 'moment'
import React from 'react'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { getWorklogDayType } from 'services/legacy/membershipRegisters/list/selectors'

import PreviousRecordBanner from 'module/Staff/components/PreviousRecordBanner'

import { Banner } from 'components'

import { StyledCustomBanner, StyledDay, StyledRowExtraWrapper } from './ShiftModalFormStyled'
import { SHIFT_MODAL_MODE } from '../../ShiftModalContainer'

const ShiftModalFormPreviousRecordBanner = ({
  currentRegisters,
  date,
  getPreviousRecord,
  memberDetails,
  mode,
}) => {
  if (mode === SHIFT_MODAL_MODE.PLANNED) {
    return null
  }

  const finalData = moment(date, DEFAULT_DATE_FORMAT)
  const previousRecord = getPreviousRecord(finalData, currentRegisters)
  const { id: membershipId, name } = memberDetails || {}
  const { endDate: previousEndDate, startDate: previousStartDate } = previousRecord || {}
  const worklogDayType = getWorklogDayType(previousRecord, finalData)

  if (moment(previousStartDate).isAfter(finalData) || (
    moment(previousEndDate).isBefore(finalData)
  )) {
    return null
  }

  return (
    <StyledRowExtraWrapper>
      <StyledDay noBorder />
      <StyledDay noPadding white>
        <StyledCustomBanner>
          <Banner.Warning>
            <PreviousRecordBanner
              firstName={name}
              membershipId={membershipId}
              previousEndDate={previousEndDate}
              previousStartDate={previousStartDate}
              selectedDate={finalData}
              worklogDayType={worklogDayType}
            />
          </Banner.Warning>
        </StyledCustomBanner>
      </StyledDay>
    </StyledRowExtraWrapper>
  )
}

export default React.memo(ShiftModalFormPreviousRecordBanner)
