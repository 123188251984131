import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_REQUESTED_EXTRA_SESSIONS_COST_STATISTICS,
  REQUESTED_EXTRA_SESSIONS_COST_STATISTICS,
  REQUESTED_EXTRA_SESSIONS_COST_STATISTICS_FAILED,
  REQUESTED_EXTRA_SESSIONS_COST_STATISTICS_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_REQUESTED_EXTRA_SESSIONS_COST_STATISTICS,
    itemFailed: REQUESTED_EXTRA_SESSIONS_COST_STATISTICS_FAILED,
    itemInit: REQUESTED_EXTRA_SESSIONS_COST_STATISTICS,
    itemSuccess: REQUESTED_EXTRA_SESSIONS_COST_STATISTICS_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
