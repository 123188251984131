import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getFormValues, stopSubmit } from 'redux-form'

import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'
import { CHILD_LEAVE_TYPE_OPTIONS } from 'services/legacy/childAbsences/constants'

import { getBackendErrors } from 'utils/backendErrors'
import { isSameDay } from 'utils/date'

import { withAppService } from 'services/app'
import { withChildAbsencesService } from 'services/legacy/childAbsences'
import { withModalService } from 'services/utils/modal'

import { Typography } from 'components'

import i18n from 'translations'

import ChildLeaveModalView from './ChildLeaveModalView'
import { CHILD_LEAVE_MODAL_FORM } from './components/ChildLeaveModalForm'

const GROUPS = {
  read: [
    'absenceReason',
    'child',
    'childAbsence.childRegister',
    'childAbsence.absenceReason',
    'childAbsence.child',
    'childAbsence.author',
    'childRegister',
    'user',
  ],
}

class ChildLeaveModalContainer extends Component {
  componentDidMount() {
    const { childAbsencesActions, id, isEdit } = this.props

    if (isEdit) {
      childAbsencesActions.get({
        params: [id, { groups: GROUPS }],
      })
    }
  }

  componentWillUnmount() {
    const { childAbsencesActions } = this.props

    childAbsencesActions.clear()
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSubmitSuccess = (response) => {
    const { onSuccess } = this.props

    this.handleCloseClick()
    onSuccess?.(response)
  }

  handleListChildAbsencesSuccess = (dateRange) => ({ data }) => {
    const { modalActions, modalConsts } = this.props

    const leaves = []

    _.forEach(data, ({ absenceType, endDate, startDate }) => {
      leaves.push({
        date: [
          moment(startDate).format(DISPLAY_SHORT_DATE_FORMAT),
          moment(endDate).format(DISPLAY_SHORT_DATE_FORMAT),
        ],
        type: _.find(CHILD_LEAVE_TYPE_OPTIONS, ['value', absenceType])?.label,
      })
    })

    const dateText = isSameDay(dateRange[0], dateRange[1])
      ? i18n.t('module:Modals:ChildLeave:ConfirmOverride:singleDateText', {
        date: moment(dateRange[0]).format(DISPLAY_SHORT_DATE_FORMAT),
      })
      : i18n.t('module:Modals:ChildLeave:ConfirmOverride:dateRangeText', {
        endDate: moment(dateRange[1]).format(DISPLAY_SHORT_DATE_FORMAT),
        startDate: moment(dateRange[0]).format(DISPLAY_SHORT_DATE_FORMAT),
      })

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('module:Modals:ChildLeave:ConfirmOverride:override'),
      onConfirm: this.handleSubmitWithOverlapping,
      text: (
        <React.Fragment>
          <Typography>
            {i18n.t('module:Modals:ChildLeave:ConfirmOverride:title')}
          </Typography>
          <Typography>
            {dateText}
          </Typography>
          <Typography>
            {_.map(leaves, ({ date, type }) => {
              const [startDate, endDate] = date

              return (
                <React.Fragment>
                  {startDate === endDate ? `${type} ${startDate}` : `${type} ${startDate} - ${endDate}`}
                  <br />
                </React.Fragment>
              )
            })}
          </Typography>
        </React.Fragment>
      ),
    })
  }

  handleOverride = (childId) => {
    const { childAbsencesActions, childAbsencesSelectors, formValues } = this.props

    const criteria = childAbsencesSelectors.getListCriteria({ childId, dateRange: formValues.date })

    childAbsencesActions.list({
      onSuccess: this.handleListChildAbsencesSuccess(formValues.date),
      onlyData: true,
      params: [{ criteria }],
    })
  }

  handleSubmitFailed = (values) => (response) => setTimeout(() => {
    const { injectValidation } = this.props
    const { child } = values

    const errors = getBackendErrors(response)

    if (errors.overrideOverlapping) {
      return this.handleOverride(child.value)
    }

    if (!errors) {
      return false
    }

    if (errors.startDate || errors.endDate) {
      errors.date = errors.startDate || errors.endDate

      delete errors.startDate
      delete errors.endDate
    }

    return injectValidation(errors)
  })

  handleSubmitWithOverlapping = () => {
    const { formValues } = this.props

    this.handleSubmit({ ...formValues, overrideOverlapping: true })
  }

  handleSubmit = (values) => {
    const { childAbsencesActions, childAbsencesSelectors, id, isEdit, isOffline, onSubmitOfflineMode } = this.props

    const body = childAbsencesSelectors.getPayload(values)

    if (isOffline && onSubmitOfflineMode) {
      onSubmitOfflineMode({
        body,
        params: isEdit ? [id, { groups: GROUPS }] : [{ groups: GROUPS }],
        toEdit: isEdit || false,
      })

      return this.handleCloseClick()
    }

    if (isEdit) {
      return childAbsencesActions.update({
        body,
        onFailed: this.handleSubmitFailed(values),
        onSuccess: this.handleSubmitSuccess,
        params: [id, { groups: GROUPS }],
      })
    }

    return childAbsencesActions.create({
      body,
      onFailed: this.handleSubmitFailed(values),
      onSuccess: this.handleSubmitSuccess,
      params: [{ groups: GROUPS }],
    })
  }

  render() {
    const {
      formValues,
      initialValues,
      isChildContext,
      isEdit,
      isFetching,
      isOffline,
      isSubmitting,
    } = this.props

    return (
      <ChildLeaveModalView
        formValues={formValues}
        initialValues={initialValues}
        isChildContext={isChildContext}
        isEdit={isEdit}
        isFetching={isFetching}
        isOffline={isOffline}
        isSubmitting={isSubmitting}
        onCloseClick={this.handleCloseClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, { appSelectors, child, childAbsencesSelectors, childAbsencesSingleState, date }) => ({
  data: childAbsencesSelectors.getChildAbsencesSingleSelector(state),
  errorMessages: appSelectors.getErrorMessages(childAbsencesSingleState),
  formValues: getFormValues(CHILD_LEAVE_MODAL_FORM)(state),
  initialValues: childAbsencesSelectors.getInitialValues(child, date)(state),
  isFetching: appSelectors.getIsFetching(childAbsencesSingleState),
  isOffline: appSelectors.getAppIsOfflineAndFFIsEnabled(state),
  isSubmitting: appSelectors.getIsSubmitting(childAbsencesSingleState),
})

const mapDispatch = {
  injectValidation: (errors) => stopSubmit(CHILD_LEAVE_MODAL_FORM, errors),
}

const enhance = compose(
  withAppService,
  withChildAbsencesService,
  withModalService,
  connect(mapState, mapDispatch),
)

export default enhance(ChildLeaveModalContainer)
