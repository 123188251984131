import React from 'react'

import { ChildrenSlider } from 'components'

import SleepsTabForm from './components/SleepsTabForm'
import { StyledContainer } from './SleepsTabStyled'
import { StyledSliderContainer } from '../DailyDiaryRecordModalStyled'

const SleepsTabView = ({
  formValues,
  initialValues,
  isSubmitting,
  onCancel,
  onChangeDidNotSleep,
  onSubmit,
  selectedChildren,
}) => (
  <StyledContainer>
    <StyledSliderContainer>
      <ChildrenSlider
        childrenList={selectedChildren || []}
      />
    </StyledSliderContainer>
    <SleepsTabForm
      formValues={formValues}
      initialValues={initialValues}
      isSubmitting={isSubmitting}
      onCancel={onCancel}
      onChangeDidNotSleep={onChangeDidNotSleep}
      onSubmit={onSubmit}
    />
  </StyledContainer>
)

export default SleepsTabView
