import i18n from 'translations'

export const STATUS_OPTIONS = [
  {
    label: i18n.t('global:All'),
    value: -1,
  },
  {
    label: i18n.t('global:Active'),
    value: 0,
  },
  {
    label: i18n.t('global:Disabled'),
    value: 1,
  },
]

export const COLUMNS = [
  {
    align: 'left',
    field: 'organisation',
    title: i18n.t('module:Organisations:List:TableHeaders:organisation'),
  },
  {
    align: 'center',
    field: 'numberOfNurseries',
    title: i18n.t('module:Organisations:List:TableHeaders:numberOfNurseries'),
  },
  {
    field: 'domain',
    title: i18n.t('module:Organisations:List:TableHeaders:domain'),
  },
  {
    field: 'edit',
  },
]
