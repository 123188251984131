export const GET_MEMBERSHIPS_LIST_SHIFT = 'GET_MEMBERSHIPS_LIST_SHIFT'
export const GET_MEMBERSHIPS_LIST_SHIFT_FAILED = 'GET_MEMBERSHIPS_LIST_SHIFT_FAILED'
export const GET_MEMBERSHIPS_LIST_SHIFT_SUCCESS = 'GET_MEMBERSHIPS_LIST_SHIFT_SUCCESS'

export const CREATE_BATCH_MEMBERSHIPS_SHIFTS = 'CREATE_BATCH_MEMBERSHIPS_SHIFTS'
export const CREATE_BATCH_MEMBERSHIPS_SHIFTS_FAILED = 'CREATE_BATCH_MEMBERSHIPS_SHIFTS_FAILED'
export const CREATE_BATCH_MEMBERSHIPS_SHIFTS_SUCCESS = 'CREATE_BATCH_MEMBERSHIPS_SHIFTS_SUCCESS'

export const EXPORT_MEMBERSHIPS_SHIFTS = 'EXPORT_MEMBERSHIPS_SHIFTS'
export const EXPORT_MEMBERSHIPS_SHIFTS_FAILED = 'EXPORT_MEMBERSHIPS_SHIFTS_FAILED'
export const EXPORT_MEMBERSHIPS_SHIFTS_SUCCESS = 'EXPORT_MEMBERSHIPS_SHIFTS_SUCCESS'
