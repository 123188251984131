import styled from 'styled-components'

import layout from 'constants/layout'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledItems = styled.div`
  display: grid;
  grid-column-gap: 10px;
  
  ${({ $optionsLength }) => $optionsLength && `
    grid-template-columns: repeat(${$optionsLength}, auto);
  `}
  
  @media(max-width: ${layout.mobileWidthResolution}px) {
    grid-template-columns: auto;
    grid-row-gap: 10px;
  }
`
