import _ from 'lodash'

import { isTimeBetweenStartAndEndTime } from 'utils/date'

import { createSelector } from 'reselect'

const getRegister = (state) => state?.register?.common

export const getRegisterDataSelector = createSelector([getRegister], (register) => register.data)

const getRegisterMetaSelector = createSelector([getRegister], (register) => register.meta)

const getRegisterErrorSelector = createSelector([getRegister], (register) => register.error)

const getRegisterIsFetchingSelector = createSelector([getRegister], (register) => register.isFetching)

export const getOfflineDataToday = createSelector([getRegister], (register) => register.offlineTodayData)

export const getRegisterSelector = createSelector(
  [getRegisterDataSelector, getRegisterMetaSelector, getRegisterErrorSelector, getRegisterIsFetchingSelector],
  (data, meta, error, isFetching) => {
    if (!data) {
      return {
        error,
        isFetching,
        meta,
      }
    }

    const isConflictFound = !!(error && error.code && 409 === error.code)
    const newRegisterItem = error && error.extra && error.extra.entry ? error.extra.entry : null
    const conflictChildId = newRegisterItem && newRegisterItem.child ? newRegisterItem.child.id : null

    const newRegisterData = _.map(data, (registerItem) => {
      const { extraSessions, id, registers, sessions } = registerItem

      const newRegisters = isConflictFound && id === conflictChildId ? [newRegisterItem] : registers
      const errorMessage = isConflictFound && id === conflictChildId ? 'This child has already been signed in.' : null

      return {
        ...registerItem,
        errorMessage,
        extraSessions,
        registers: newRegisters,
        sessions,
      }
    })

    return {
      data: _.orderBy(newRegisterData, ['sessionStartTime', 'firstName', 'surname']),
      error: isConflictFound ? null : error,
      isFetching,
      meta,
    }
  },
)

export const getRegisterHasExtraSessions = createSelector([getRegisterDataSelector], (data) => {
  if (!data) {
    return false
  }

  let hasExtraSessions = false

  _.forEach(data, (registerItem) => {
    const { extraSessions } = registerItem

    hasExtraSessions = hasExtraSessions || (extraSessions && 0 < extraSessions.length)
  })

  return hasExtraSessions
})

export const getAttendanceSummary = createSelector(
  [getRegisterDataSelector],
  (registerData) => {
    if (!registerData || !registerData.length) {
      return null
    }

    const attendanceSummary = { active: 0, total: 0 }

    _.forEach(registerData, ({ extraSessions, registers, sessions }) => {
      const isSessionMatchCurrentTime = _.some(sessions, ({ plans }) => (
        _.some(plans, ({ endTime, startTime }) => isTimeBetweenStartAndEndTime(startTime, endTime))
      ))

      const isExtraSessionMatchCurrentTime = _.some(extraSessions, ({ endTime, nurserySession, startTime }) => {
        const { endTime: sessionEndTime, startTime: sessionStartTime } = nurserySession

        const extraSessionStartTime = startTime || sessionStartTime
        const extraSessionEndTime = endTime || sessionEndTime

        return isTimeBetweenStartAndEndTime(extraSessionStartTime, extraSessionEndTime)
      })

      if (isExtraSessionMatchCurrentTime || isSessionMatchCurrentTime) {
        attendanceSummary.total += 1
      }

      const isChildActive = _.some(
        registers,
        ({ signIns }) => _.some(signIns, ({ signedInAt, signedOutAt }) => signedInAt && !signedOutAt),
      )

      if (isChildActive) {
        attendanceSummary.active += 1
      }
    })

    return attendanceSummary
  },
)

export const getRegisterChildrenCriteria = (filters) => {
  const { allergy, entryDate, present } = filters
  const criteria = []

  if (0 <= allergy) {
    criteria.push({
      field: 'information.allergies',
      value: allergy,
    })
  }

  if (entryDate) {
    criteria.push({
      field: 'entryDate',
      value: entryDate,
    })
  }

  if (0 <= present) {
    criteria.push({
      field: 'present',
      value: present,
    })
  }

  return criteria
}

export * from './list/selectors'
export * from './global/selectors'
