export const CLEAR_CONTRACT = 'CLEAR_CONTRACT'

export const CREATE_CONTRACT = 'CREATE_CONTRACT'
export const CREATE_CONTRACT_FAILED = 'CREATE_CONTRACT_FAILED'
export const CREATE_CONTRACT_SUCCESS = 'CREATE_CONTRACT_SUCCESS'

export const DELETE_CONTRACT = 'DELETE_CONTRACT'
export const DELETE_CONTRACT_FAILED = 'DELETE_CONTRACT_FAILED'
export const DELETE_CONTRACT_SUCCESS = 'DELETE_CONTRACT_SUCCESS'

export const GET_CONTRACT = 'GET_CONTRACT'
export const GET_CONTRACT_FAILED = 'GET_CONTRACT_FAILED'
export const GET_CONTRACT_SUCCESS = 'GET_CONTRACT_SUCCESS'

export const UPDATE_CONTRACT = 'UPDATE_CONTRACT'
export const UPDATE_CONTRACT_FAILED = 'UPDATE_CONTRACT_FAILED'
export const UPDATE_CONTRACT_SUCCESS = 'UPDATE_CONTRACT_SUCCESS'
