import React from 'react'
import { connect } from 'react-redux'

import * as actions from './actions'
import * as selectors from './selectors'

const withPaymentReminderTagsService = (WrappedComponent) => {
  const mapState = ({ paymentReminderTags }) => ({ paymentReminderTags })

  const mapDispatch = {
    clear: actions.clear,
    list: actions.list,
  }

  const Component = (props) => {
    const { clear, list, paymentReminderTags, ...others } = props

    const paymentReminderTagsState = paymentReminderTags
    const paymentReminderTagsActions = { clear, list }
    const paymentReminderTagsSelectors = selectors

    return (
      <WrappedComponent
        paymentReminderTagsActions={paymentReminderTagsActions}
        paymentReminderTagsSelectors={paymentReminderTagsSelectors}
        paymentReminderTagsState={paymentReminderTagsState}
        {...others}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withPaymentReminderTagsService
