import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_LIST_PRODUCT_PRICE_GROUPS,
  LIST_PRODUCT_PRICE_GROUPS,
  LIST_PRODUCT_PRICE_GROUPS_FAILED,
  LIST_PRODUCT_PRICE_GROUPS_SUCCESS,
} from './constants'

export const list = (options: BasicActionOptionsProps) => ({ productPriceGroupsApiClient }) => (
  generateBasicActions.list({
    constants: {
      clear: CLEAR_LIST_PRODUCT_PRICE_GROUPS,
      failed: LIST_PRODUCT_PRICE_GROUPS_FAILED,
      init: LIST_PRODUCT_PRICE_GROUPS,
      success: LIST_PRODUCT_PRICE_GROUPS_SUCCESS,
    },
    options,
    service: productPriceGroupsApiClient,
    serviceMethod: 'list',
  })
)

export const clearList = () => ({
  type: CLEAR_LIST_PRODUCT_PRICE_GROUPS,
})
