import React from 'react'

import { Icon } from 'components'

import { StyledContainer, StyledLabelContainer, StyledNavContainer } from './SeeMoreListItemStyled'

const SeeMoreListItem = ({ label = 'See more', to }) => (
  <StyledContainer to={to}>
    <StyledLabelContainer>
      {label}
    </StyledLabelContainer>
    <StyledNavContainer>
      <Icon color="#fff" height={20} icon="chevron-right" />
    </StyledNavContainer>
  </StyledContainer>
)

export default SeeMoreListItem
