/* eslint-disable max-len */
/* eslint-disable import-newlines/enforce */

import i18n from 'translations'

import withFinanceGateway from 'module/Finance/withFinanceGateway'

import Management from 'module/Management/index'
import AttendancePeriods from 'module/Management/AttendancePeriods'
import AttendancePeriodsAdd from 'module/Management/AttendancePeriods/Add'
import AttendancePeriodsSetTermDates from 'module/Management/AttendancePeriods/SetTermDates'
import ManagementNursery from 'module/Management/ManagementNursery'
import ManagementNurseryEdit from 'module/Management/ManagementNursery/ManagementNurseryEdit'
import ManagementGeneralSettings from 'module/Management/ManagementGeneralSettings'
import ManagementGettingStarted from 'module/Management/ManagementGettingStarted'
import ManagementBlossomboard from 'module/Management/ManagementBlossomboard'
import ManagementLeaveTypes from 'module/Management/ManagementLeaveTypes'
import ManagementLeaveTypesChildAbsenceReasons from 'module/Management/ManagementLeaveTypes/ChildAbsenceReasons'
import ManagementLeaveTypesChildAbsenceReasonsAdd
  from 'module/Management/ManagementLeaveTypes/ChildAbsenceReasons/ChildAbsenceReasonsAdd'
import ManagementLeaveTypesStaffLeaveTypes from 'module/Management/ManagementLeaveTypes/StaffLeaveTypes'
import ManagementLeaveTypesStaffLeaveTypesAdd
  from 'module/Management/ManagementLeaveTypes/StaffLeaveTypes/StaffLeaveTypesAdd'
import ManagementSessionsV2 from 'module/Management/ManagementFinanceSettings/Sessions/V2'
import ManagementSessionsV2Add from 'module/Management/ManagementFinanceSettings/Sessions/V2/Add'
import ManagementSessionsV3 from 'module/Management/ManagementFinanceSettings/Sessions/V3'
import ManagementSessionsV3Add from 'module/Management/ManagementFinanceSettings/Sessions/V3/Add'
import ManagementInvoicesV2 from './ManagementFinanceSettings/ManagementInvoices/V2'
import ManagementInvoicesV3Wrapper from './ManagementFinanceSettings/ManagementInvoices/V3/ManagementInvoicesV3Wrapper'
import ManagementInvoicesV3 from './ManagementFinanceSettings/ManagementInvoices/V3'
import ManagementInvoicesV3EditBank
  from './ManagementFinanceSettings/ManagementInvoices/V3/ManagementInvoicesV3EditBank'
import ManagementInvoiceV3NumbersEdit
  from './ManagementFinanceSettings/ManagementInvoices/V3/ManagementInvoiceV3NumbersEdit'
import ManagementInvoicesV3EditAnnualised
  from './ManagementFinanceSettings/ManagementInvoices/V3/ManagementInvoicesV3EditAnnualised'
import ManagementDiscountsWrapper from './ManagementFinanceSettings/ManagementDiscounts/v2/ManagementDiscountsV2Wrapper'
import ManagementDiscountsV2 from './ManagementFinanceSettings/ManagementDiscounts/v2'
import ManagementDiscountsV2Add from './ManagementFinanceSettings/ManagementDiscounts/v2/ManagementDiscountsV2Add'
import ManagementDiscountsV3 from './ManagementFinanceSettings/ManagementDiscounts/v3'
import ManagementDiscountsV3Add from './ManagementFinanceSettings/ManagementDiscounts/v3/ManagementDiscountsV3Add'
import ManagementItemsWrapper from './ManagementFinanceSettings/ManagementItems/ManagementItemsWrapper'
import ManagementItemsListV2 from './ManagementFinanceSettings/ManagementItems/v2/ManagementItemsList'
import ManagementItemsListV3 from './ManagementFinanceSettings/ManagementItems/v3/ManagementItemsList'
import ManagementItemsAdd from './ManagementFinanceSettings/ManagementItems/v3/ManagementItemsAdd'
import ManagementExtraItemsAdd from './ManagementFinanceSettings/ManagementItems/v2/ManagementItemsAdd'
import ManagementConsumablesV2 from './ManagementFinanceSettings/ManagementConsumables/v2'
import ManagementConsumablesAddV2 from './ManagementFinanceSettings/ManagementConsumables/v2/ManagementConsumablesV2Add'
import ManagementConsumablesV3 from './ManagementFinanceSettings/ManagementConsumables/v3'
import ManagementConsumablesAddV3 from './ManagementFinanceSettings/ManagementConsumables/v3/ManagementConsumablesV3Add'
import ManagementConsumablesWrapper
  from './ManagementFinanceSettings/ManagementConsumables/ManagementConsumablesWrapper'
import ManagementFinanceSettings from './ManagementFinanceSettings'
import ManagementFundingWrapper from './ManagementFinanceSettings/ManagementFunding/ManagementFundingWrapper'
import ManagementFundingListV2 from './ManagementFinanceSettings/ManagementFunding/v2/ManagementFundingList'
import ManagementFundingListV3 from './ManagementFinanceSettings/ManagementFunding/v3/ManagementFundingList'
import ManagementFundingItem from './ManagementFinanceSettings/ManagementFunding/v2/ManagementFundingItem'
import ManagementFundingAddV2 from './ManagementFinanceSettings/ManagementFunding/v2/ManagementFundingAdd'
import ManagementFundingAddV3 from './ManagementFinanceSettings/ManagementFunding/v3/ManagementFundingAdd'
import ManagementPaymentTypes from './ManagementFinanceSettings/ManagementPaymentTypes'
import ManagementPaymentTypesWrapper
  from './ManagementFinanceSettings/ManagementPaymentTypes/ManagementPaymentTypesWrapper'
import ManagementPaymentTypesAdd from './ManagementFinanceSettings/ManagementPaymentTypes/ManagementPaymentTypesAdd'
import ManagementNewFinance from './ManagementFinanceSettings/ManagementNewFinance'
import ManagementPaymentGateway from './ManagementFinanceSettings/ManagementPaymentGateway'
import ManagementAccountCodes from './ManagementFinanceSettings/ManagementAccountCodes'
import ManagementReferenceCodes from './ManagementFinanceSettings/ManagementReferenceCodes'
import ManagementReferenceCodeGeneration from './ManagementFinanceSettings/ReferenceCodeGeneration'
import ManagementReferenceCodeGenerationEdit from './ManagementFinanceSettings/ReferenceCodeGeneration/Edit'
import EnquiriesSettings from './EnquiriesSettings'
import EnquiriesSettingsWrapper from './EnquiriesSettings/EnquiriesSettingsWrapper'
import EnquiryFormSettings from './EnquiriesSettings/Form'
import EnquiryCommonSettings from './EnquiriesSettings/Common'
import EnquiryCommonSettingsAdd from './EnquiriesSettings/Common/Add'
import EnquiryPipelineSettings from './EnquiriesSettings/Pipeline'
import EnquiryPipelineSettingsAdd from './EnquiriesSettings/Pipeline/Add'
import ManagementParentApp from './ManagementParentApp'
import ManagementParentAppRequestableExtraSessionsWrapper
  from './ManagementParentApp/RequestableExtraSessions/RequestableExtraSessionsWrapper'
import ManagementParentAppRequestableExtraSessions from './ManagementParentApp/RequestableExtraSessions'
import ManagementParentAppRequestableExtraSessionsCorePreferences
  from './ManagementParentApp/RequestableExtraSessions/CorePreferences'
import ManagementParentAppRequestableExtraSessionsUnavailableTimes
  from './ManagementParentApp/RequestableExtraSessions/UnavailableTimes'
import ManagementBookingAbsence from './ManagementParentApp/BookingAbsence'
import ManagementParentCommunication from './ManagementParentApp/ParentCommunication'
import ManagementMenuItems from './ManagementMenuItems'
import ManagementMenuItemsWrapper from './ManagementMenuItems/ManagementMenuItemsWrapper'
import ManagementMenuItemsList from './ManagementMenuItems/MenuItemsList'
import ManagementMenuItemsAdd from './ManagementMenuItems/MenuItemsAdd'
import ManagementLearning from './ManagementLearning'
import ManagementLearningWrapper from './ManagementLearning/ManagementLearningWrapper'
import ManagementLearningCurriculums from './ManagementLearning/Curriculums'
import ManagementLearningGeneral from './ManagementLearning/General'
import Security from './Security'
import SecurityWrapper from './Security/SecurityWrapper'
import AuthorisedDevicesList from './Security/AuthorisedDevices/AuthorisedDevicesList'
import AddDevice from './Security/AuthorisedDevices/AddDevice'
import EditDevice from './Security/AuthorisedDevices/EditDevice'
import DeviceDetails from './Security/AuthorisedDevices/DeviceDetails'
import AccessLogsList from './Security/AccessLogsList'
import PractitionerChildAccess from './Security/PractitionerChildAccess'
import GeneralSecuritySettings from './Security/GeneralSecuritySettings'
import ClosureDays from './ClosureDays'
import ClosureDates from './ClosureDays/ClosureDates'
import ClosureDatesAdd from './ClosureDays/ClosureDates/Add'
import ClosureDatesPreview from './ClosureDays/ClosureDates/Preview'
import ClosureDayTypes from './ClosureDays/ClosureDayTypes'
import ClosureDayTypesAdd from './ClosureDays/ClosureDayTypes/Add'

export const ROUTE_NAMES = {
  'MANAGEMENT.ATTENDANCE_PERIODS': 'MANAGEMENT.ATTENDANCE_PERIODS',
  'MANAGEMENT.ATTENDANCE_PERIODS.ADD': 'MANAGEMENT.ATTENDANCE_PERIODS.ADD',
  'MANAGEMENT.ATTENDANCE_PERIODS.EDIT': 'MANAGEMENT.ATTENDANCE_PERIODS.EDIT',
  'MANAGEMENT.ATTENDANCE_PERIODS.SET_TERM_DATES': 'MANAGEMENT.ATTENDANCE_PERIODS.SET_TERM_DATES',
  'MANAGEMENT.BLOSSOMBOARD': 'MANAGEMENT.BLOSSOMBOARD',
  'MANAGEMENT.CLOSURE_DAYS': 'MANAGEMENT.CLOSURE_DAYS',
  'MANAGEMENT.CLOSURE_DAYS.CLOSURE_DATES': 'MANAGEMENT.CLOSURE_DAYS.CLOSURE_DATES',
  'MANAGEMENT.CLOSURE_DAYS.CLOSURE_DATES.ADD': 'MANAGEMENT.CLOSURE_DAYS.CLOSURE_DATES.ADD',
  'MANAGEMENT.CLOSURE_DAYS.CLOSURE_DATES.EDIT': 'MANAGEMENT.CLOSURE_DAYS.CLOSURE_DATES.EDIT',
  'MANAGEMENT.CLOSURE_DAYS.CLOSURE_DATES.PREVIEW': 'MANAGEMENT.CLOSURE_DAYS.CLOSURE_DATES.PREVIEW',
  'MANAGEMENT.CLOSURE_DAYS.CLOSURE_DAY_TYPES': 'MANAGEMENT.CLOSURE_DAYS.CLOSURE_DAY_TYPES',
  'MANAGEMENT.CLOSURE_DAYS.CLOSURE_DAY_TYPES.ADD': 'MANAGEMENT.CLOSURE_DAYS.CLOSURE_DAY_TYPES.ADD',
  'MANAGEMENT.CLOSURE_DAYS.CLOSURE_DAY_TYPES.EDIT': 'MANAGEMENT.CLOSURE_DAYS.CLOSURE_DAY_TYPES.EDIT',
  'MANAGEMENT.ENQUIRIES_SETTINGS': 'MANAGEMENT.ENQUIRIES_SETTINGS',
  'MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_COMMON.ADD': 'MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_COMMON.ADD',
  'MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_COMMON.EDIT': 'MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_COMMON.EDIT',
  'MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_COMMON.INDEX': 'MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_COMMON.INDEX',
  'MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_FORM': 'MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_FORM',
  'MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_PIPELINE.ADD': 'MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_PIPELINE.ADD',
  'MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_PIPELINE.EDIT': 'MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_PIPELINE.EDIT',
  'MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_PIPELINE.INDEX': 'MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_PIPELINE.INDEX',
  'MANAGEMENT.FINANCE_SETTINGS': 'MANAGEMENT.FINANCE_SETTINGS',
  'MANAGEMENT.FINANCE_SETTINGS.ACCOUNT_CODES': 'MANAGEMENT.FINANCE_SETTINGS.ACCOUNT_CODES',
  'MANAGEMENT.FINANCE_SETTINGS.CONSUMABLES': 'MANAGEMENT.FINANCE_SETTINGS.CONSUMABLES',
  'MANAGEMENT.FINANCE_SETTINGS.CONSUMABLES.ADD': 'MANAGEMENT.FINANCE_SETTINGS.CONSUMABLES.ADD',
  'MANAGEMENT.FINANCE_SETTINGS.CONSUMABLES.EDIT': 'MANAGEMENT.FINANCE_SETTINGS.CONSUMABLES.EDIT',
  'MANAGEMENT.FINANCE_SETTINGS.DISCOUNTS': 'MANAGEMENT.FINANCE_SETTINGS.DISCOUNTS',
  'MANAGEMENT.FINANCE_SETTINGS.DISCOUNTS.ADD': 'MANAGEMENT.FINANCE_SETTINGS.DISCOUNTS.ADD',
  'MANAGEMENT.FINANCE_SETTINGS.DISCOUNTS.EDIT': 'MANAGEMENT.FINANCE_SETTINGS.DISCOUNTS.EDIT',
  'MANAGEMENT.FINANCE_SETTINGS.FUNDING': 'MANAGEMENT.FINANCE_SETTINGS.FUNDING',
  'MANAGEMENT.FINANCE_SETTINGS.FUNDING.ADD': 'MANAGEMENT.FINANCE_SETTINGS.FUNDING.ADD',
  'MANAGEMENT.FINANCE_SETTINGS.FUNDING.EDIT': 'MANAGEMENT.FINANCE_SETTINGS.FUNDING.EDIT',
  'MANAGEMENT.FINANCE_SETTINGS.FUNDING.VIEW': 'MANAGEMENT.FINANCE_SETTINGS.FUNDING.VIEW',
  'MANAGEMENT.FINANCE_SETTINGS.INVOICES': 'MANAGEMENT.FINANCE_SETTINGS.INVOICES',
  'MANAGEMENT.FINANCE_SETTINGS.INVOICES.ANNUALISED_EDIT': 'MANAGEMENT.FINANCE_SETTINGS.INVOICES.ANNUALISED_EDIT',
  'MANAGEMENT.FINANCE_SETTINGS.INVOICES.BANK_EDIT': 'MANAGEMENT.FINANCE_SETTINGS.INVOICES.BANK_EDIT',
  'MANAGEMENT.FINANCE_SETTINGS.INVOICES.INVOICE_NUMBER_EDIT': 'MANAGEMENT.FINANCE_SETTINGS.INVOICES.INVOICE_NUMBER_EDIT',
  'MANAGEMENT.FINANCE_SETTINGS.INVOICES.VIEW': 'MANAGEMENT.FINANCE_SETTINGS.INVOICES.VIEW',
  'MANAGEMENT.FINANCE_SETTINGS.ITEMS': 'MANAGEMENT.FINANCE_SETTINGS.ITEMS',
  'MANAGEMENT.FINANCE_SETTINGS.ITEMS.ADD': 'MANAGEMENT.FINANCE_SETTINGS.ITEMS.ADD',
  'MANAGEMENT.FINANCE_SETTINGS.ITEMS.EDIT': 'MANAGEMENT.FINANCE_SETTINGS.ITEMS.EDIT',
  'MANAGEMENT.FINANCE_SETTINGS.NEW_FINANCE': 'MANAGEMENT.FINANCE_SETTINGS.NEW_FINANCE',
  'MANAGEMENT.FINANCE_SETTINGS.PAYMENT_GATEWAY': 'MANAGEMENT.FINANCE_SETTINGS.PAYMENT_GATEWAY',
  'MANAGEMENT.FINANCE_SETTINGS.PAYMENT_TYPES': 'MANAGEMENT.FINANCE_SETTINGS.PAYMENT_TYPES',
  'MANAGEMENT.FINANCE_SETTINGS.PAYMENT_TYPES.ADD': 'MANAGEMENT.FINANCE_SETTINGS.PAYMENT_TYPES.ADD',
  'MANAGEMENT.FINANCE_SETTINGS.PAYMENT_TYPES.EDIT': 'MANAGEMENT.FINANCE_SETTINGS.PAYMENT_TYPES.EDIT',
  'MANAGEMENT.FINANCE_SETTINGS.REFERENCE_CODES': 'MANAGEMENT.FINANCE_SETTINGS.REFERENCE_CODES',
  'MANAGEMENT.FINANCE_SETTINGS.REFERENCE_CODE_GENERATION': 'MANAGEMENT.FINANCE_SETTINGS.REFERENCE_CODE_GENERATION',
  'MANAGEMENT.FINANCE_SETTINGS.REFERENCE_CODE_GENERATION.EDIT': 'MANAGEMENT.FINANCE_SETTINGS.REFERENCE_CODE_GENERATION.EDIT',
  'MANAGEMENT.FINANCE_SETTINGS.SESSIONS': 'MANAGEMENT.FINANCE_SETTINGS.SESSIONS',
  'MANAGEMENT.FINANCE_SETTINGS.SESSIONS.ADD': 'MANAGEMENT.FINANCE_SETTINGS.SESSIONS.ADD',
  'MANAGEMENT.FINANCE_SETTINGS.SESSIONS.EDIT': 'MANAGEMENT.FINANCE_SETTINGS.SESSIONS.EDIT',
  'MANAGEMENT.GENERAL_SETTINGS': 'MANAGEMENT.GENERAL_SETTINGS',
  'MANAGEMENT.GETTING_STARTED': 'MANAGEMENT.GETTING_STARTED',
  'MANAGEMENT.INDEX': 'MANAGEMENT.INDEX',
  'MANAGEMENT.LEARNING': 'MANAGEMENT.LEARNING',
  'MANAGEMENT.LEARNING.CURRICULUMS': 'MANAGEMENT.LEARNING.CURRICULUMS',
  'MANAGEMENT.LEARNING.GENERAL': 'MANAGEMENT.LEARNING.GENERAL',
  'MANAGEMENT.LEAVE_TYPES': 'MANAGEMENT.LEAVE_TYPES',
  'MANAGEMENT.LEAVE_TYPES.CHILD_ABSENCE_REASONS': 'MANAGEMENT.LEAVE_TYPES.CHILD_ABSENCE_REASONS',
  'MANAGEMENT.LEAVE_TYPES.CHILD_ABSENCE_REASONS.ADD': 'MANAGEMENT.LEAVE_TYPES.CHILD_ABSENCE_REASONS.ADD',
  'MANAGEMENT.LEAVE_TYPES.CHILD_ABSENCE_REASONS.EDIT': 'MANAGEMENT.LEAVE_TYPES.CHILD_ABSENCE_REASONS.EDIT',
  'MANAGEMENT.LEAVE_TYPES.STAFF_LEAVE_TYPES': 'MANAGEMENT.LEAVE_TYPES.STAFF_LEAVE_TYPES',
  'MANAGEMENT.LEAVE_TYPES.STAFF_LEAVE_TYPES.ADD': 'MANAGEMENT.LEAVE_TYPES.STAFF_LEAVE_TYPES.ADD',
  'MANAGEMENT.LEAVE_TYPES.STAFF_LEAVE_TYPES.EDIT': 'MANAGEMENT.LEAVE_TYPES.STAFF_LEAVE_TYPES.EDIT',
  'MANAGEMENT.MENU_ITEMS': 'MANAGEMENT.MENU_ITEMS',
  'MANAGEMENT.MENU_ITEMS.MEALS': 'MANAGEMENT.MENU_ITEMS.MEALS',
  'MANAGEMENT.MENU_ITEMS.MEALS.ADD': 'MANAGEMENT.MENU_ITEMS.MEALS.ADD',
  'MANAGEMENT.MENU_ITEMS.MEALS.EDIT': 'MANAGEMENT.MENU_ITEMS.MEALS.EDIT',
  'MANAGEMENT.MENU_ITEMS.SNACKS': 'MANAGEMENT.MENU_ITEMS.SNACKS',
  'MANAGEMENT.MENU_ITEMS.SNACKS.ADD': 'MANAGEMENT.MENU_ITEMS.SNACKS.ADD',
  'MANAGEMENT.MENU_ITEMS.SNACKS.EDIT': 'MANAGEMENT.MENU_ITEMS.SNACKS.EDIT',
  'MANAGEMENT.NURSERY': 'MANAGEMENT.NURSERY',
  'MANAGEMENT.NURSERY.EDIT': 'MANAGEMENT.NURSERY.EDIT',
  'MANAGEMENT.PARENT_APP': 'MANAGEMENT.PARENT_APP',
  'MANAGEMENT.PARENT_APP.BOOKING_ABSENCE': 'MANAGEMENT.PARENT_APP.BOOKING_ABSENCE',
  'MANAGEMENT.PARENT_APP.PARENT_COMMUNICATION': 'MANAGEMENT.PARENT_APP.PARENT_COMMUNICATION',
  'MANAGEMENT.PARENT_APP.REQUESTABLE_EXTRA_SESSIONS': 'MANAGEMENT.PARENT_APP.REQUESTABLE_EXTRA_SESSIONS',
  'MANAGEMENT.PARENT_APP.REQUESTABLE_EXTRA_SESSIONS.CORE_PREFERENCES': 'MANAGEMENT.PARENT_APP.REQUESTABLE_EXTRA_SESSIONS.CORE_PREFERENCES',
  'MANAGEMENT.PARENT_APP.REQUESTABLE_EXTRA_SESSIONS.UNAVAILABLE_TIMES': 'MANAGEMENT.PARENT_APP.REQUESTABLE_EXTRA_SESSIONS.UNAVAILABLE_TIMES',
  'MANAGEMENT.SECURITY.AUTHENTICATION_LOGS': 'MANAGEMENT.SECURITY.AUTHENTICATION_LOGS',
  'MANAGEMENT.SECURITY.DEVICES': 'MANAGEMENT.SECURITY.DEVICES',
  'MANAGEMENT.SECURITY.DEVICES.ADD': 'MANAGEMENT.SECURITY.DEVICES.ADD',
  'MANAGEMENT.SECURITY.DEVICES.DETAILS': 'MANAGEMENT.SECURITY.DEVICES.DETAILS',
  'MANAGEMENT.SECURITY.DEVICES.EDIT': 'MANAGEMENT.SECURITY.DEVICES.EDIT',
  'MANAGEMENT.SECURITY.INDEX': 'MANAGEMENT.SECURITY.INDEX',
  'MANAGEMENT.SECURITY.PRACTITIONER_CHILD_ACCESS': 'MANAGEMENT.SECURITY.PRACTITIONER_CHILD_ACCESS',
  'MANAGEMENT.SECURITY.SECURITY_SETTINGS': 'MANAGEMENT.SECURITY.SECURITY_SETTINGS',
  'MANAGEMENT.TERM_DATES': 'MANAGEMENT.TERM_DATES',
  'MANAGEMENT.TERM_DATES.ADD': 'MANAGEMENT.TERM_DATES.ADD',
  'MANAGEMENT.TERM_DATES.EDIT': 'MANAGEMENT.TERM_DATES.EDIT',
  'MANAGEMENT.TERM_DATES.OPEN_PERIOD': 'MANAGEMENT.TERM_DATES.OPEN_PERIOD',
}

const ManagementRouting = {
  childRoutes: [
    {
      indexRoute: {
        component: ManagementGettingStarted,
      },
      name: ROUTE_NAMES['MANAGEMENT.GETTING_STARTED'],
      path: 'getting-started',
      title: i18n.t('module:Management:GettingStarted:title'),
    },
    {
      indexRoute: {
        component: ManagementBlossomboard,
      },
      name: ROUTE_NAMES['MANAGEMENT.BLOSSOMBOARD'],
      path: 'blossomboard',
      title: i18n.t('module:Management:ManagementRouting:Blossomboard'),
    },
    {
      childRoutes: [
        {
          childRoutes: [
            {
              component: ClosureDatesAdd,
              name: ROUTE_NAMES['MANAGEMENT.CLOSURE_DAYS.CLOSURE_DATES.ADD'],
              path: 'add',
              title: i18n.t('module:Management:ClosureDays:ClosureDates:Add:title'),
            },
            {
              component: ClosureDatesAdd,
              name: ROUTE_NAMES['MANAGEMENT.CLOSURE_DAYS.CLOSURE_DATES.EDIT'],
              path: 'edit/:nurseryAcademicYearId',
              title: i18n.t('module:Management:ClosureDays:ClosureDates:Add:editTitle'),
            },
            {
              component: ClosureDatesPreview,
              name: ROUTE_NAMES['MANAGEMENT.CLOSURE_DAYS.CLOSURE_DATES.PREVIEW'],
              path: 'preview/:nurseryAcademicYearId',
            },
          ],
          indexRoute: {
            component: ClosureDates,
          },
          name: ROUTE_NAMES['MANAGEMENT.CLOSURE_DAYS.CLOSURE_DATES'],
          path: 'closure-dates',
          title: i18n.t('module:Management:ClosureDays:ClosureDates:title'),
        },
        {
          childRoutes: [
            {
              component: ClosureDayTypesAdd,
              name: ROUTE_NAMES['MANAGEMENT.CLOSURE_DAYS.CLOSURE_DAY_TYPES.ADD'],
              path: 'add',
              title: i18n.t('module:Management:ClosureDays:ClosureDayTypes:Add:title'),
            },
            {
              component: ClosureDayTypesAdd,
              name: ROUTE_NAMES['MANAGEMENT.CLOSURE_DAYS.CLOSURE_DAY_TYPES.EDIT'],
              path: 'edit/:closureDayTypeId',
              title: i18n.t('module:Management:ClosureDays:ClosureDayTypes:Add:editTitle'),
            },
          ],
          indexRoute: {
            component: ClosureDayTypes,
          },
          name: ROUTE_NAMES['MANAGEMENT.CLOSURE_DAYS.CLOSURE_DAY_TYPES'],
          path: 'closure-day-types',
          title: i18n.t('module:Management:ClosureDays:ClosureDayTypes:title'),

        }],
      indexRoute: {
        component: ClosureDays,
      },
      name: ROUTE_NAMES['MANAGEMENT.CLOSURE_DAYS'],
      path: 'closure-days',
      title: i18n.t('module:Management:ClosureDays:title'),
    },
    {
      childRoutes: [
        {
          childRoutes: [
            {
              component: withFinanceGateway({ V2: ManagementSessionsV2Add, V3: ManagementSessionsV3Add }),
              name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.SESSIONS.ADD'],
              path: 'add',
              title: i18n.t('module:Management:Finance:Sessions:Add:title'),
            },
            {
              component: withFinanceGateway({ V2: ManagementSessionsV2Add, V3: ManagementSessionsV3Add }),
              name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.SESSIONS.EDIT'],
              path: ':sessionId/edit',
              title: i18n.t('module:Management:Finance:Sessions:Edit:title'),
            },
          ],
          indexRoute: {
            component: withFinanceGateway({ V2: ManagementSessionsV2, V3: ManagementSessionsV3 }),
          },
          name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.SESSIONS'],
          path: 'sessions',
          title: i18n.t('module:Management:Finance:Sessions:title'),
        },
        {
          childRoutes: [
            {
              component: withFinanceGateway({ V2: ManagementInvoicesV2, V3: ManagementInvoicesV3 }),
              name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.INVOICES.VIEW'],
              path: 'view',
              title: i18n.t('global:View'),
            },
            {
              component: ManagementInvoicesV3EditBank,
              name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.INVOICES.BANK_EDIT'],
              path: 'bank/edit',
              title: i18n.t('module:Management:Finance:Invoice:BankSettings:title'),
            },
            {
              component: ManagementInvoiceV3NumbersEdit,
              name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.INVOICES.INVOICE_NUMBER_EDIT'],
              path: 'invoice-number/edit',
              title: i18n.t('module:Management:Finance:InvoiceNumber:Edit:title'),
            },
            {
              component: ManagementInvoicesV3EditAnnualised,
              name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.INVOICES.ANNUALISED_EDIT'],
              path: 'annualised/edit',
              title: i18n.t('module:Management:Finance:Annualised:title'),
            },
          ],
          component: ManagementInvoicesV3Wrapper,
          indexRoute: {
            component: withFinanceGateway({ V2: ManagementInvoicesV2, V3: ManagementInvoicesV3 }),
          },
          name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.INVOICES'],
          path: 'invoices',
          title: i18n.t('module:Management:Finance:Invoice:title'),
        },
        {
          childRoutes: [
            {
              component: withFinanceGateway({
                V2: ManagementExtraItemsAdd,
                V3: ManagementItemsAdd,
              }),
              name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.ITEMS.ADD'],
              path: 'add',
              title: i18n.t('module:Management:Finance:ExtraItems:Add:title'),
            },
            {
              component: withFinanceGateway({
                V2: ManagementExtraItemsAdd,
                V3: ManagementItemsAdd,
              }),
              name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.ITEMS.EDIT'],
              path: 'edit/:itemId',
              title: i18n.t('module:Management:Finance:ExtraItems:Edit:title'),
            },
          ],
          component: ManagementItemsWrapper,
          indexRoute: {
            component: withFinanceGateway({
              V2: ManagementItemsListV2,
              V3: ManagementItemsListV3,
            }),
          },
          name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.ITEMS'],
          path: 'items',
        },
        {
          childRoutes: [
            {
              component: withFinanceGateway({
                V2: ManagementFundingAddV2,
                V3: ManagementFundingAddV3,
              }),
              name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.FUNDING.ADD'],
              path: 'add',
              title: i18n.t('module:Management:ManagementRouting:Finance:Funding:add'),
            },
            {
              component: withFinanceGateway({
                V2: ManagementFundingAddV2,
                V3: ManagementFundingAddV3,
              }),
              name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.FUNDING.EDIT'],
              path: ':nurseryFundingId/edit',
              title: i18n.t('module:Management:ManagementRouting:Finance:Funding:edit'),
            },
            {
              component: ManagementFundingItem,
              name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.FUNDING.VIEW'],
              path: ':nurseryFundingId/view',
              title: i18n.t('module:Management:ManagementRouting:Finance:Funding:view'),
            },
          ],
          component: ManagementFundingWrapper,
          indexRoute: {
            component: withFinanceGateway({
              V2: ManagementFundingListV2,
              V3: ManagementFundingListV3,
            }),
          },
          name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.FUNDING'],
          path: 'funding',
          title: i18n.t('module:Management:Finance:Funding:title'),
        },
        {
          childRoutes: [
            {
              component: withFinanceGateway({
                V2: ManagementDiscountsV2Add,
                V3: ManagementDiscountsV3Add,
              }),
              name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.DISCOUNTS.ADD'],
              path: 'add',
              title: i18n.t('module:Management:Finance:Discounts:add:title'),
            },
            {
              component: withFinanceGateway({
                V2: ManagementDiscountsV2Add,
                V3: ManagementDiscountsV3Add,
              }),
              name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.DISCOUNTS.EDIT'],
              path: 'edit/:itemId',
              title: i18n.t('module:Management:Finance:Discounts:edit:title'),
            },
          ],
          component: ManagementDiscountsWrapper,
          indexRoute: {
            component: withFinanceGateway({
              V2: ManagementDiscountsV2,
              V3: ManagementDiscountsV3,
            }),
          },
          name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.DISCOUNTS'],
          path: 'discounts',
          title: i18n.t('module:Management:Finance:Discounts:title'),
        },
        {
          childRoutes: [
            {
              component: withFinanceGateway({
                V2: ManagementConsumablesAddV2,
                V3: ManagementConsumablesAddV3,
              }),
              name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.CONSUMABLES.ADD'],
              path: 'add',
              title: i18n.t('module:Management:Finance:Consumables:Add:title'),
            },
            {
              component: withFinanceGateway({
                V2: ManagementConsumablesAddV2,
                V3: ManagementConsumablesAddV3,
              }),
              name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.CONSUMABLES.EDIT'],
              path: 'edit/:itemId',
              title: i18n.t('module:Management:Finance:Consumables:Edit:title'),
            },
          ],
          component: ManagementConsumablesWrapper,
          indexRoute: {
            component: withFinanceGateway({
              V2: ManagementConsumablesV2,
              V3: ManagementConsumablesV3,
            }),
          },
          name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.CONSUMABLES'],
          path: 'consumables',
          title: i18n.t('module:Management:Finance:Consumables:title'),
        },
        {
          childRoutes: [
            {
              component: ManagementPaymentTypesAdd,
              name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.PAYMENT_TYPES.ADD'],
              path: 'add',
              title: i18n.t('module:Management:ManagementPaymentTypes:routeAddTitle'),
            },
            {
              component: ManagementPaymentTypesAdd,
              name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.PAYMENT_TYPES.EDIT'],
              path: 'edit/:itemId',
              title: i18n.t('module:Management:ManagementPaymentTypes:routeEditTitle'),
            },
          ],
          component: ManagementPaymentTypesWrapper,
          indexRoute: {
            component: ManagementPaymentTypes,
          },
          name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.PAYMENT_TYPES'],
          path: 'payment-methods',
          title: i18n.t('module:Management:ManagementPaymentTypes:title'),
        },
        {
          component: ManagementNewFinance,
          name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.NEW_FINANCE'],
          path: 'new-finance',
          title: i18n.t('module:Management:Finance:NewFinance:title'),
        },
        {
          component: ManagementPaymentGateway,
          name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.PAYMENT_GATEWAY'],
          path: 'payment-gateway',
          title: i18n.t('module:Management:Finance:PaymentGateway:title'),
        },
        {
          component: ManagementAccountCodes,
          name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.ACCOUNT_CODES'],
          path: 'account-codes',
          title: i18n.t('module:Management:Finance:AccountCodes:title'),
        },
        {
          component: ManagementReferenceCodes,
          name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.REFERENCE_CODES'],
          path: 'reference-codes',
          title: i18n.t('global:Child:childReferenceCodes'),
        },
        {
          childRoutes: [
            {
              component: ManagementReferenceCodeGenerationEdit,
              name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.REFERENCE_CODE_GENERATION.EDIT'],
              path: 'edit',
              title: i18n.t('global:Edit'),
            },
          ],
          indexRoute: {
            component: ManagementReferenceCodeGeneration,
          },
          name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS.REFERENCE_CODE_GENERATION'],
          path: 'reference-code-generation',
          title: i18n.t('global:Child:childReferenceCodeGeneration'),
        },
      ],
      indexRoute: {
        component: ManagementFinanceSettings,
      },
      name: ROUTE_NAMES['MANAGEMENT.FINANCE_SETTINGS'],
      path: 'finance-settings',
      title: i18n.t('module:Management:Finance:title'),
    },
    {
      childRoutes: [
        {
          component: ManagementNurseryEdit,
          name: ROUTE_NAMES['MANAGEMENT.NURSERY.EDIT'],
          path: 'edit',
          title: i18n.t('global:Edit'),
        },
      ],
      indexRoute: {
        component: ManagementNursery,
      },
      name: ROUTE_NAMES['MANAGEMENT.NURSERY'],
      path: 'nursery',
      title: i18n.t('module:Management:Nursery:title'),
    },
    {
      childRoutes: [
        {
          component: withFinanceGateway({ V2: ManagementSessionsV2Add, V3: ManagementSessionsV3Add }),
          name: ROUTE_NAMES['MANAGEMENT.SESSIONS.ADD'],
          path: 'add',
          title: i18n.t('module:Management:Sessions:Add:title'),
        },
        {
          component: withFinanceGateway({ V2: ManagementSessionsV2Add, V3: ManagementSessionsV3Add }),
          name: ROUTE_NAMES['MANAGEMENT.SESSIONS.EDIT'],
          path: ':sessionId/edit',
          title: i18n.t('module:Management:Sessions:Edit:title'),
        },
      ],
      indexRoute: {
        component: withFinanceGateway({ V2: ManagementSessionsV2, V3: ManagementSessionsV3 }),
      },
      name: ROUTE_NAMES['MANAGEMENT.SESSIONS'],
      path: 'sessions',
      title: i18n.t('module:Management:Sessions:title'),
    },
    {
      childRoutes: [
        {
          component: AttendancePeriodsAdd,
          name: ROUTE_NAMES['MANAGEMENT.ATTENDANCE_PERIODS.ADD'],
          path: 'add',
          title: i18n.t('module:Management:AttendancePeriods:Add:title'),
        },
        {
          component: AttendancePeriodsAdd,
          name: ROUTE_NAMES['MANAGEMENT.ATTENDANCE_PERIODS.EDIT'],
          path: 'edit/:id',
          title: i18n.t('module:Management:AttendancePeriods:Add:editTitle'),
        },
        {
          component: AttendancePeriodsSetTermDates,
          name: ROUTE_NAMES['MANAGEMENT.ATTENDANCE_PERIODS.SET_TERM_DATES'],
          path: 'set-term-dates/:id',
          title: i18n.t('module:Management:AttendancePeriods:SetTermDates:routeTitle'),
        },
      ],
      indexRoute: {
        component: AttendancePeriods,
      },
      name: ROUTE_NAMES['MANAGEMENT.ATTENDANCE_PERIODS'],
      path: 'attendance-periods',
      title: i18n.t('module:Management:AttendancePeriods:title'),
    },
    {
      childRoutes: [
        {
          childRoutes: [
            {
              component: ManagementLeaveTypesStaffLeaveTypesAdd,
              name: ROUTE_NAMES['MANAGEMENT.LEAVE_TYPES.STAFF_LEAVE_TYPES.ADD'],
              path: 'add',
              title: i18n.t('module:Management:LeaveTypes:Staff:Add:title'),
            },
            {
              component: ManagementLeaveTypesStaffLeaveTypesAdd,
              name: ROUTE_NAMES['MANAGEMENT.LEAVE_TYPES.STAFF_LEAVE_TYPES.EDIT'],
              path: 'edit/:itemId',
              title: i18n.t('module:Management:LeaveTypes:Staff:Edit:title'),
            },
          ],
          indexRoute: {
            component: ManagementLeaveTypesStaffLeaveTypes,
          },
          name: ROUTE_NAMES['MANAGEMENT.LEAVE_TYPES.STAFF_LEAVE_TYPES'],
          path: 'staff-leave-types',
          title: i18n.t('module:Management:LeaveTypes:Staff:title'),
        },
        {
          childRoutes: [
            {
              component: ManagementLeaveTypesChildAbsenceReasonsAdd,
              name: ROUTE_NAMES['MANAGEMENT.LEAVE_TYPES.CHILD_ABSENCE_REASONS.ADD'],
              path: 'add',
              title: i18n.t('module:Management:LeaveTypes:Child:Add:title'),
            },
            {
              component: ManagementLeaveTypesChildAbsenceReasonsAdd,
              name: ROUTE_NAMES['MANAGEMENT.LEAVE_TYPES.CHILD_ABSENCE_REASONS.EDIT'],
              path: 'edit/:itemId',
              title: i18n.t('module:Management:LeaveTypes:Child:Edit:title'),
            },
          ],
          indexRoute: {
            component: ManagementLeaveTypesChildAbsenceReasons,
          },
          name: ROUTE_NAMES['MANAGEMENT.LEAVE_TYPES.CHILD_ABSENCE_REASONS'],
          path: 'child-absence-reasons',
          title: i18n.t('module:Management:LeaveTypes:Child:title'),
        },
      ],
      indexRoute: {
        component: ManagementLeaveTypes,
      },
      name: ROUTE_NAMES['MANAGEMENT.LEAVE_TYPES'],
      path: 'leave-types',
      title: i18n.t('module:Management:LeaveTypes:title'),
    },
    {
      indexRoute: {
        component: ManagementGeneralSettings,
      },
      name: ROUTE_NAMES['MANAGEMENT.GENERAL_SETTINGS'],
      path: 'general-settings',
      title: 'General',
    },
    {
      childRoutes: [
        {
          childRoutes: [
            {
              component: ManagementParentAppRequestableExtraSessionsUnavailableTimes,
              name: ROUTE_NAMES['MANAGEMENT.PARENT_APP.REQUESTABLE_EXTRA_SESSIONS.UNAVAILABLE_TIMES'],
              path: 'unavailable-times',
              title: i18n.t('module:Management:ParentApp:RequestableExtraSessions:UnavailableTimes:title'),
            },
            {
              component: ManagementParentAppRequestableExtraSessionsCorePreferences,
              name: ROUTE_NAMES['MANAGEMENT.PARENT_APP.REQUESTABLE_EXTRA_SESSIONS.CORE_PREFERENCES'],
              path: 'core-preferences',
              title: i18n.t('module:Management:ParentApp:RequestableExtraSessions:CorePreferences:title'),
            },
          ],
          component: ManagementParentAppRequestableExtraSessionsWrapper,
          indexRoute: {
            component: ManagementParentAppRequestableExtraSessions,
          },
          name: ROUTE_NAMES['MANAGEMENT.PARENT_APP.REQUESTABLE_EXTRA_SESSIONS'],
          path: 'requestable-extra-sessions',
          title: i18n.t('module:Management:ParentApp:RequestableExtraSessions:title'),
        },
        {
          component: ManagementBookingAbsence,
          name: ROUTE_NAMES['MANAGEMENT.PARENT_APP.BOOKING_ABSENCE'],
          path: 'children-absences',
          title: i18n.t('module:Management:ParentApp:BookingAbsence:title'),
        },
        {
          component: ManagementParentCommunication,
          name: ROUTE_NAMES['MANAGEMENT.PARENT_APP.PARENT_COMMUNICATION'],
          path: 'parent-communication',
          title: i18n.t('module:Management:ParentApp:ParentCommunication:title'),
        },
      ],
      indexRoute: {
        component: ManagementParentApp,
      },
      name: ROUTE_NAMES['MANAGEMENT.PARENT_APP'],
      path: 'parent-app',
      title: i18n.t('module:Management:ParentApp:Title:short'),
    },
    {
      childRoutes: [
        {
          component: EnquiryFormSettings,
          name: ROUTE_NAMES['MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_FORM'],
          path: 'enquiry-form',
          title: i18n.t('module:Management:Enquiries:EnquiryForm:title'),
        },
        {
          childRoutes: [
            {
              component: EnquiryPipelineSettingsAdd,
              name: ROUTE_NAMES['MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_PIPELINE.ADD'],
              path: 'add',
              title: i18n.t('module:Management:Enquiries:EnquiryPipeline:Add:addTitle'),
            },
            {
              component: EnquiryPipelineSettingsAdd,
              name: ROUTE_NAMES['MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_PIPELINE.EDIT'],
              path: 'edit/:enquiryStatusTypeId',
              title: i18n.t('module:Management:Enquiries:EnquiryPipeline:Add:editTitle'),
            },
          ],
          indexRoute: {
            component: EnquiryPipelineSettings,
          },
          name: ROUTE_NAMES['MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_PIPELINE.INDEX'],
          path: 'pipeline',
          title: i18n.t('module:Management:Enquiries:EnquiryPipeline:title'),
        },
        {
          childRoutes: [
            {
              component: EnquiryCommonSettingsAdd,
              name: ROUTE_NAMES['MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_COMMON.ADD'],
              path: 'add',
            },
            {
              component: EnquiryCommonSettingsAdd,
              name: ROUTE_NAMES['MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_COMMON.EDIT'],
              path: 'edit/:enquirySettingId',
            },
          ],
          indexRoute: {
            component: EnquiryCommonSettings,
          },
          name: ROUTE_NAMES['MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_COMMON.INDEX'],
          path: ':type',
        },
      ],
      component: EnquiriesSettingsWrapper,
      indexRoute: {
        component: EnquiriesSettings,
      },
      name: ROUTE_NAMES['MANAGEMENT.ENQUIRIES_SETTINGS'],
      path: 'enquiries-settings',
      title: i18n.t('module:Management:Enquiries:title'),
    },
    {
      childRoutes: [
        {
          component: ManagementLearningCurriculums,
          name: ROUTE_NAMES['MANAGEMENT.LEARNING.CURRICULUMS'],
          path: 'curriculums',
          title: i18n.t('module:Management:Learning:Curriculums:title'),
        },
        {
          component: ManagementLearningGeneral,
          name: ROUTE_NAMES['MANAGEMENT.LEARNING.GENERAL'],
          path: 'general',
          title: i18n.t('module:Management:Learning:General:title'),
        },
      ],
      component: ManagementLearningWrapper,
      indexRoute: {
        component: ManagementLearning,
      },
      name: ROUTE_NAMES['MANAGEMENT.LEARNING'],
      path: 'learning',
      title: i18n.t('module:Management:Learning:Title:short'),
    },
    {
      childRoutes: [
        {
          childRoutes: [
            {
              component: ManagementMenuItemsAdd,
              name: ROUTE_NAMES['MANAGEMENT.MENU_ITEMS.MEALS.ADD'],
              path: 'add',
              title: i18n.t('module:Management:MenuItems:Meals:Form:title', { action: i18n.t('global:Add') }),
            },
            {
              component: ManagementMenuItemsAdd,
              name: ROUTE_NAMES['MANAGEMENT.MENU_ITEMS.MEALS.EDIT'],
              path: ':id/edit',
              title: i18n.t('module:Management:MenuItems:Meals:Form:title', { action: i18n.t('global:Edit') }),
            },
          ],
          indexRoute: {
            component: ManagementMenuItemsList,
          },
          name: ROUTE_NAMES['MANAGEMENT.MENU_ITEMS.MEALS'],
          path: 'meals',
          title: i18n.t('module:Management:MenuItems:Meals:title'),
        },
        {
          childRoutes: [
            {
              component: ManagementMenuItemsAdd,
              name: ROUTE_NAMES['MANAGEMENT.MENU_ITEMS.SNACKS.ADD'],
              path: 'add',
              title: i18n.t('module:Management:MenuItems:Snacks:Form:title', { action: i18n.t('global:Add') }),
            },
            {
              component: ManagementMenuItemsAdd,
              name: ROUTE_NAMES['MANAGEMENT.MENU_ITEMS.SNACKS.EDIT'],
              path: ':id/edit',
              title: i18n.t('module:Management:MenuItems:Snacks:Form:title', { action: i18n.t('global:Edit') }),
            },
          ],
          indexRoute: {
            component: ManagementMenuItemsList,
          },
          name: ROUTE_NAMES['MANAGEMENT.MENU_ITEMS.SNACKS'],
          path: 'snacks',
          title: i18n.t('module:Management:MenuItems:Snacks:title'),
        },
      ],
      component: ManagementMenuItemsWrapper,
      indexRoute: {
        component: ManagementMenuItems,
      },
      name: ROUTE_NAMES['MANAGEMENT.MENU_ITEMS'],
      path: 'menu-items',
      title: i18n.t('module:Management:MenuItems:title'),
    },
    {
      childRoutes: [
        {
          component: PractitionerChildAccess,
          name: ROUTE_NAMES['MANAGEMENT.SECURITY.PRACTITIONER_CHILD_ACCESS'],
          path: 'practitioner-child-access',
          title: i18n.t('module:Management:Security:PractitionerChildAccess:title'),
        },
        {
          component: AccessLogsList,
          name: ROUTE_NAMES['MANAGEMENT.SECURITY.AUTHENTICATION_LOGS'],
          path: 'access-logs',
          title: i18n.t('module:Management:Security:AccessLogsList:title'),
        },
        {
          childRoutes: [
            {
              component: AddDevice,
              name: ROUTE_NAMES['MANAGEMENT.SECURITY.DEVICES.ADD'],
              path: 'add',
              title: 'Authorise new device',
            },
            {
              childRoutes: [
                {
                  component: EditDevice,
                  name: ROUTE_NAMES['MANAGEMENT.SECURITY.DEVICES.EDIT'],
                  path: 'edit',
                  title: 'Edit device',
                },
                {
                  component: DeviceDetails,
                  name: ROUTE_NAMES['MANAGEMENT.SECURITY.DEVICES.DETAILS'],
                  path: 'details',
                  title: 'Details device',
                },
              ],
              path: ':deviceId',
            },
          ],
          indexRoute: {
            component: AuthorisedDevicesList,
          },
          name: ROUTE_NAMES['MANAGEMENT.SECURITY.DEVICES'],
          path: 'devices',
          title: 'Authorised devices',
        },
        {
          component: GeneralSecuritySettings,
          name: ROUTE_NAMES['MANAGEMENT.SECURITY.SECURITY_SETTINGS'],
          path: 'general-security-settings',
          title: i18n.t('module:Management:Security:GeneralSecuritySettings:title'),
        },
      ],
      component: SecurityWrapper,
      indexRoute: {
        component: Security,
      },
      name: ROUTE_NAMES['MANAGEMENT.SECURITY.INDEX'],
      path: 'security',
      title: 'Security',
    },
  ],
  component: Management,
  name: ROUTE_NAMES['MANAGEMENT.INDEX'],
  path: 'management',
  title: 'Settings',
}

ManagementRouting.ROUTE_NAMES = ROUTE_NAMES

export default ManagementRouting
