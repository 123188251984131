import _ from 'lodash'
import { v4 } from 'uuid'

import React, { Component } from 'react'
import { compose } from 'recompose'

import { STATUS_OPTIONS_VALUES } from 'services/users/constants'

import { hasMoreRecords } from 'utils/pagination'

import { withChildService } from 'services/legacy/child'
import { withUsersService } from 'services/users'

import { Select } from 'components'

import i18n from 'translations'

class IndividualDropdown extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cacheUniq: v4(),
      childPage: 1,
      hasMoreUsers: true,
      oldSearchedPhrase: null,
      userPage: 1,
    }
  }

  componentWillUnmount() {
    const { childActions, usersActions } = this.props

    childActions.clear()
    usersActions.clearList()
  }

  handleLoadMoreElements = async (phrase) => {
    const { childActions, childSelectors, limit, usersActions, usersSelectors } = this.props
    const { childPage, hasMoreUsers, oldSearchedPhrase, userPage } = this.state

    let userCurrentPage = userPage
    let childCurrentPage = childPage
    let newHasMoreUsers = hasMoreUsers

    let userResult = { options: [] }
    let childResult = { options: [] }

    if (oldSearchedPhrase !== phrase) {
      usersActions.clearList()
      childActions.clear()

      userCurrentPage = 1
      childCurrentPage = 1
      newHasMoreUsers = true

      this.setState({ oldSearchedPhrase: phrase })
    }

    if (newHasMoreUsers) {
      await usersActions.list({
        mergeResult: true,
        onSuccess: (response) => {
          const { data, meta } = response

          userResult = {
            hasMore: hasMoreRecords(meta),
            options: [{
              label: i18n.t('global:Staff'),
              options: _.map(data, ({ id, name }) => ({
                label: name,
                type: 'user',
                value: id,
              })),
            }],
          }

          this.setState({ cacheUniq: false, hasMoreUsers: userResult.hasMore, userPage: userCurrentPage + 1 })
        },
        params: {
          criteria: usersSelectors.getUsersListCriteria({
            search: phrase,
            status: STATUS_OPTIONS_VALUES.ACTIVE,
          }),
          limit,
          page: userCurrentPage,
        },
      })
    }

    if (!hasMoreUsers || !userResult.hasMore) {
      await childActions.list({
        mergeResult: true,
        onSuccess: (response) => {
          const { data, meta } = response

          childResult = {
            hasMore: hasMoreRecords(meta),
            options: [{
              label: i18n.t('global:Children'),
              options: _.map(data, ({ id, name }) => ({
                label: name,
                type: 'child',
                value: id,
              })),
            }],
          }

          this.setState({ cacheUniq: false, childPage: childCurrentPage + 1 })
        },
        params: {
          criteria: childSelectors.getCriteria({ displayName: phrase }),
          limit,
          page: childCurrentPage,
        },
      })
    }

    return {
      hasMore: !!userResult.hasMore || !!childResult.hasMore,
      options: [...userResult.options, ...childResult.options],
    }
  }

  handleIndividualOptions = (previousOptions, newOptions) => {
    const individual = _.groupBy([...previousOptions, ...newOptions], 'label')

    return _.map(individual, (value, label) => {
      const individualItemOptions = []

      _.forEach(value, ({ options }) => individualItemOptions.push(...options))

      return {
        label,
        options: individualItemOptions,
      }
    })
  }

  render() {
    const {
      input: { onChange, value },
      isFetching,
      meta: { error, submitFailed, valid },
    } = this.props
    const { cacheUniq } = this.state

    return (
      <Select
        cacheUniq={cacheUniq}
        disabled={isFetching}
        error={submitFailed && error}
        loadOptions={this.handleLoadMoreElements}
        placeholder={i18n.t('module:Newsletters:Common:individualDropdownPlaceholder')}
        reduceOptions={this.handleIndividualOptions}
        valid={!(submitFailed && !valid)}
        value={value}
        multi
        searchable
        onChange={onChange}
      />
    )
  }
}

const enhance = compose(
  withChildService,
  withUsersService,
)

export default enhance(IndividualDropdown)
