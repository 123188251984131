import _ from 'lodash'

import { ReactSVG } from 'react-svg'
import React from 'react'

import {
  DatePicker,
  EmptyState,
  InfiniteDropdowns,
  Page,
  Pagination,
  Section,
  Select,
  Spinner,
  TabFilter,
  Table,
  Toolbar,
} from 'components'

import i18n from 'translations'

const DietaryReportView = ({
  childrenOptions,
  childrenValue,
  columns,
  dietaryReportExportGranted,
  isClicked,
  isInitialDataLoading,
  isLoading,
  onAllergiesClick,
  onChildrenDueInChange,
  onMissingInfoClick,
  onNoAllergiesClick,
  onNoPreferenceClick,
  onOtherClick,
  onPageChange,
  onPescatarianClick,
  onRegisterDateChange,
  onRoomChange,
  onSendReport,
  onVeganClick,
  onVegetarianClick,
  page,
  pageCount,
  perPage,
  registerDate,
  reports,
  room,
  showMissingInfoFilter,
  statistics,
  totalResults,
}) => {
  const {
    data: {
      totalAllergies,
      totalMissingInformation,
      totalNoAllergies,
      totalNoPreferences,
      totalOthers,
      totalPescatarian,
      totalVegans,
      totalVegetarians,
    },
    isFetching,
  } = statistics

  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('global:pagination:children')}
      titleSingular={i18n.t('global:pagination:child')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!reports || !reports.length) {
      return (
        <EmptyState
          iconComponent={<ReactSVG src="/images/dietary-report-empty-icon.svg" />}
          text1={i18n.t('module:Reports:emptyStateText')}
          iconComponentSmallMargin
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table columns={columns} data={reports} />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const renderDietaryFilters = () => (
    <Toolbar>
      <TabFilter>
        {showMissingInfoFilter && (
          <TabFilter.Item
            isClicked={isClicked.missingInfo}
            isLoading={isFetching}
            title={i18n.t('module:Children:Constants:DietaryRequirementsOptions:missingInfoLabel')}
            value={totalMissingInformation}
            onClick={onMissingInfoClick}
          />
        )}
        <TabFilter.Item
          isClicked={isClicked.noPreference}
          isLoading={isFetching}
          title={i18n.t('module:Children:Constants:DietaryRequirementsOptions:noPreferenceLabel')}
          value={totalNoPreferences}
          onClick={onNoPreferenceClick}
        />
        <TabFilter.Item
          isClicked={isClicked.pescatarian}
          isLoading={isFetching}
          title={i18n.t('module:Children:Constants:DietaryRequirementsOptions:pescatarianLabel')}
          value={totalPescatarian}
          onClick={onPescatarianClick}
        />
        <TabFilter.Item
          isClicked={isClicked.vegetarian}
          isLoading={isFetching}
          title={i18n.t('module:Children:Constants:DietaryRequirementsOptions:vegetarianLabel')}
          value={totalVegetarians}
          onClick={onVegetarianClick}
        />
        <TabFilter.Item
          isClicked={isClicked.vegan}
          isLoading={isFetching}
          title={i18n.t('module:Children:Constants:DietaryRequirementsOptions:veganLabel')}
          value={totalVegans}
          onClick={onVeganClick}
        />
        <TabFilter.Item
          isClicked={isClicked.other}
          isLoading={isFetching}
          title={i18n.t('global:Other')}
          value={totalOthers}
          onClick={onOtherClick}
        />
        <TabFilter.Item
          isClicked={isClicked.allergies}
          isLoading={isFetching}
          title={i18n.t('module:Children:Constants:DietaryAllergiesOptions:allergiesLabel')}
          value={totalAllergies}
          onClick={onAllergiesClick}
        />
        <TabFilter.Item
          isClicked={isClicked.noAllergies}
          isLoading={isFetching}
          title={i18n.t('module:Children:Constants:DietaryAllergiesOptions:noAllergiesLabel')}
          value={totalNoAllergies}
          onClick={onNoAllergiesClick}
        />
      </TabFilter>
    </Toolbar>
  )

  const actions = (
    <Section.Actions
      options={[{
        auth: dietaryReportExportGranted,
        onClick: onSendReport,
        type: 'export',
      }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isInitialDataLoading || isLoading}
      title={_.upperFirst(i18n.t('module:Reports:Dietary:title'))}
    >
      <Toolbar bottomLine>
        <Toolbar.Group>
          <Toolbar.Item>
            <InfiniteDropdowns.Rooms
              placeholder={i18n.t('global:AllRooms')}
              value={room}
              onChange={onRoomChange}
            />
          </Toolbar.Item>
          <Toolbar.Item>
            <Select
              clearable={false}
              options={childrenOptions}
              placeholder={_.upperFirst(i18n.t('global:children'))}
              value={childrenValue}
              onChange={onChildrenDueInChange}
            />
          </Toolbar.Item>
          {childrenValue && (
            <Toolbar.Item>
              <DatePicker value={registerDate} onChange={onRegisterDateChange} />
            </Toolbar.Item>
          )}
        </Toolbar.Group>
      </Toolbar>
      {isInitialDataLoading
        ? <Spinner />
        : (
          <React.Fragment>
            {renderDietaryFilters()}
            {renderContent()}
          </React.Fragment>
        )}
    </Page.Section>
  )
}

export default DietaryReportView
