import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class ClosureDayTypesApiClient extends ServiceBase {
  list = (params = {}) => this.request({
    path: `/v2/closure_day_types?${getQueryStringV2(params)}`,
  })

  get = (closureDayTypeId) => this.request({
    path: `/v2/closure_day_types/${closureDayTypeId}`,
  })

  create = (body) => this.request({
    body,
    method: 'POST',
    path: '/v2/closure_day_types',
  })

  update = (closureDayTypeId, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/closure_day_types/${closureDayTypeId}`,
  })

  remove = (closureDayTypeId) => this.request({
    method: 'DELETE',
    path: `/v2/closureDayTypes/${closureDayTypeId}`,
  })
}

export default ClosureDayTypesApiClient
