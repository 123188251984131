import React, { useEffect, useState } from 'react'

import { useWindowSize } from 'utils/hooks'

import { Button, Popover } from 'components'

import CountBadge from './components/CountBadge'
import { StyledButton, StyledLayerWrapper } from './ConversationMenuStyled'

const ConversationMenu = ({
  anchor = 'BOTTOM_CENTER',
  buttonIcon,
  children,
  isOffline,
  margin,
  onVisibleChange,
  unreadCount,
  zIndex,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [badgeOverflow, setBadgeOverflow] = useState(false)
  const { width } = useWindowSize()

  const onOverflow = (value) => {
    if (badgeOverflow !== value) {
      setBadgeOverflow(value)
    }
  }

  useEffect(() => {
    if (isOffline && isOpen) {
      setIsOpen(false)
    }
  }, [isOffline, isOpen])

  const renderDefaultButton = () => (
    <StyledButton $badgeOverflow={badgeOverflow} $disabled={isOffline} $width={width}>
      <CountBadge unreadCount={unreadCount} onOverflow={onOverflow} />
      <Button
        color="senary"
        disabled={isOffline}
        icon={buttonIcon}
        inverted={isOpen}
        size="small"
        disableElevation
        negativeVerticalMargins
      />
    </StyledButton>
  )

  const renderLayerWrapper = (content) => (
    <StyledLayerWrapper>
      {content}
    </StyledLayerWrapper>
  )

  const handleClick = () => {
    setIsOpen(!isOpen)
    onVisibleChange?.(!isOpen)
  }

  return (
    <Popover
      anchor={anchor}
      button={renderDefaultButton()}
      isOpen={isOpen}
      margin={margin}
      parentZindex={zIndex}
      renderLayerWrapper={renderLayerWrapper}
      scrollOffset={20}
      zIndex={zIndex}
      disableArrow
      disableClose
      disableCloseInside
      uncontrolled
      onClick={handleClick}
      onOutsideClick={() => setIsOpen(false)}
      onVisible
    >
      {isOpen && width && children({ setIsOpen, width })}
    </Popover>
  )
}

export default ConversationMenu
