import React from 'react'

import { generateRoute } from 'utils/routing'

import { Callout, DatePicker, EmptyState, Page, Pagination, Section, Select, Spinner, Table, Toolbar } from 'components'

import i18n from 'translations'

const ChildAdhocSessionsView = ({
  authAccessMap,
  child,
  childAdhocSessionsTableData,
  dateRange,
  errorMessages,
  isEmpty,
  isLoading,
  onDateRangeChange,
  onPageChange,
  onSortChange,
  onStatusChange,
  page,
  pageCount,
  perPage,
  sortField,
  sortOrder,
  status,
  statusOptions,
  totalResults,
}) => {
  const { firstName, id: childId } = child

  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('module:Children:Child:BookingPattern:ExtraSessions:extraSessions')}
      titleSingular={i18n.t('module:Children:Child:BookingPattern:ExtraSessions:extraSession')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (isEmpty) {
      return (
        <EmptyState
          icon="extra-sessions"
          text1={`Does ${firstName} have any extra sessions?`}
          text2={`You can add extra sessions for ${firstName} here to show up on invoices.`}
        />
      )
    }

    const columns = [
      {
        align: 'left',
        field: 'takenDate',
        sortable: true,
        title: 'Date',
      },
      {
        field: 'name',
        title: 'Session',
      },
      {
        field: 'cost',
        title: 'Cost',
      },
    ]

    if (!authAccessMap.section.ExtraSessionsIsReadOnly) {
      columns.push({ field: 'rowToButton' })
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={columns}
          data={childAdhocSessionsTableData}
          sortField={sortField}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const actions = !authAccessMap.section.ExtraSessionsIsReadOnly && (
    <Section.Actions
      primary={[{
        to: generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_SESSIONS.ADD', { childId }),
      }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title="Extra Sessions"
    >
      <Callout content={errorMessages} error />
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <DatePicker
              value={dateRange}
              clearable
              range
              onChange={onDateRangeChange}
            />
          </Toolbar.Item>
          <Toolbar.Item>
            <Select
              clearable={false}
              options={statusOptions}
              placeholder="Status"
              value={status}
              onChange={onStatusChange}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      {renderContent()}
    </Page.Section>
  )
}

export default ChildAdhocSessionsView
