import { v4 } from 'uuid'
import { TooltipProps as TooltipMaterialProps } from '@material-ui/core/Tooltip/Tooltip'

import React, { PropsWithChildren } from 'react'

import { CircleIcon, Tooltip } from 'components'

import {
  StyledCheckMarkContainer,
  StyledContainer,
  StyledGroupContainer,
  StyledInputContainer,
  StyledItemContainer,
  StyledLabelContainer,
  StyledRadioContainer,
} from './RadioStyled'

interface RadioProps {
  checked?: boolean
  input?: any
  label?: string
  onChange?: () => void
  rightComponent?: React.FunctionComponent<any>
  tooltip?: string
  tooltipPlacement?: TooltipMaterialProps['placement']
  value?: boolean | string | number | readonly string[]
}

const Radio: React.FC<PropsWithChildren<RadioProps>> = ({
  checked,
  label,
  onChange,
  rightComponent,
  tooltip,
  tooltipPlacement,
  value,
  ...input
}) => {
  const id = v4()

  return (
    <StyledContainer $disabled={(input as any)?.disabled}>
      <StyledGroupContainer>
        <StyledItemContainer>
          <StyledRadioContainer>
            <StyledLabelContainer>
              <StyledInputContainer
                checked={checked}
                id={id}
                type="radio"
                value={value}
                onChange={onChange}
                {...input}
              />
              <StyledCheckMarkContainer />
            </StyledLabelContainer>
          </StyledRadioContainer>
        </StyledItemContainer>
        <StyledItemContainer htmlFor={id} onClick={(e) => e.stopPropagation()}>
          {label}
        </StyledItemContainer>
        {rightComponent && (
          <StyledItemContainer>
            {rightComponent({ ...input, value })}
          </StyledItemContainer>
        )}
      </StyledGroupContainer>
      {tooltip && (
        <Tooltip
          placement={tooltipPlacement}
          title={tooltip}
        >
          <StyledGroupContainer>
            <StyledItemContainer>
              <CircleIcon
                icon="info"
                iconSize={16}
                size={24}
              />
            </StyledItemContainer>
          </StyledGroupContainer>
        </Tooltip>
      )}
    </StyledContainer>
  )
}

export default Radio
