import _ from 'lodash'

import React from 'react'
import { ReactSVG } from 'react-svg'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

import { DatePicker, EmptyState, InfiniteDropdowns, Legend, List, Page, Pagination, Spinner, Toolbar } from 'components'

import InjuryListItem from 'module/Safeguarding/Injury/components/InjuryListItem'
import { StyledInjuriesIcon } from './InjuryApprovalsStyled'

const InjuryApprovalsView = ({
  dateRange,
  isLoading,
  onDateChange,
  onPageChange,
  onSupervisorChange,
  page,
  pageCount,
  perPage,
  records,
  supervisor,
  totalResults,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!records || !records.length) {
      const text = (
        <span>
          There are no records.
          <br />
          Log an accident or incident record.
        </span>
      )

      return (
        <EmptyState
          iconComponent={(
            <StyledInjuriesIcon>
              <ReactSVG src="/images/injuries.svg" />
            </StyledInjuriesIcon>
          )}
          text1={text}
        />
      )
    }

    const renderInjuryRow = (item) => (
      <InjuryListItem {...item} />
    )

    return (
      <React.Fragment>
        {renderPagination()}
        <List.Standard>
          {_.map(records, renderInjuryRow)}
        </List.Standard>
        {renderPagination()}
      </React.Fragment>
    )
  }

  return (
    <Page.Section
      isLoading={isLoading}
      title="Approvals:"
      titleSecond="Accidents & Incidents"
    >
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <DatePicker value={dateRange} range onChange={onDateChange} />
          </Toolbar.Item>
          <Toolbar.Item>
            <InfiniteDropdowns.Users
              placeholder="Supervisor"
              value={supervisor}
              onChange={onSupervisorChange}
            />
          </Toolbar.Item>
          <Toolbar.Item>
            <Legend
              noMarginBottom
            >
              <Legend.Item
                background={NEUTRAL_COLOURS.WHITE}
                color={getBrandingColor('primary-color')}
                icon="accident"
                label="Accident"
                size={14}
              />
              <Legend.Item
                background={NEUTRAL_COLOURS.WHITE}
                color={getBrandingColor('primary-color')}
                icon="incident"
                label="Incident"
                size={14}
              />
            </Legend>
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      {renderContent()}
    </Page.Section>
  )
}

export default InjuryApprovalsView
