import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { ROOM_TYPES } from '../constants'

const getRooms = (state) => state.newRooms

export const isTeachingRoom = (type) => 0 <= _.indexOf([ROOM_TYPES.TEACHING], type)

export const getRoomsSingleSelector = createSelector(
  [getRooms],
  (state) => {
    if (!state) {
      return null
    }

    return state.single
  },
)

export const getRoomsSingleDataSelector = createSelector(
  [getRoomsSingleSelector],
  (state) => {
    if (!state) {
      return null
    }

    return state.data
  },
)

export const getInitialValuesSelector = createSelector(
  [getRoomsSingleDataSelector],
  (room) => {
    if (!room) {
      return null
    }

    const {
      capacity,
      endAgeInMonths,
      name,
      ratio,
      staffRequired,
      staffRequirements,
      startAgeInMonths,
      type,
    } = room

    return {
      capacity,
      endAgeInMonths,
      name,
      ratio,
      staffRequired,
      staffRequirements: _.map(staffRequirements, (item) => ({
        ...item,
        endTime: moment(item.endTime).utc(),
        startTime: moment(item.startTime).utc(),
      })),
      startAgeInMonths,
      type,
    }
  },
)

export const getValuesForm = (values, editMode) => {
  const {
    capacity,
    endAgeInMonths,
    name,
    ratio,
    staffRequired,
    staffRequirements,
    startAgeInMonths,
    type,
  } = values
  const preparedValues = {}

  if (editMode && capacity === undefined) {
    preparedValues.capacity = ''
  } else if (capacity) {
    preparedValues.capacity = +capacity
  }

  if (editMode && endAgeInMonths === undefined) {
    preparedValues.endAgeInMonths = ''
  } else if (endAgeInMonths) {
    preparedValues.endAgeInMonths = +endAgeInMonths
  }

  if (editMode && name === undefined) {
    preparedValues.name = ''
  } else if (name) {
    preparedValues.name = name
  }

  if (editMode && ratio === undefined) {
    preparedValues.ratio = ''
  } else if (ratio) {
    preparedValues.ratio = +ratio
  }

  if (editMode && staffRequired === undefined) {
    preparedValues.staffRequired = ''
  } else if (staffRequired) {
    preparedValues.staffRequired = +staffRequired
  }

  if (editMode && startAgeInMonths === undefined) {
    preparedValues.startAgeInMonths = ''
  } else if (startAgeInMonths) {
    preparedValues.startAgeInMonths = +startAgeInMonths
  }

  preparedValues.type = type?.value || type

  if (staffRequirements) {
    preparedValues.staffRequirements = _.map(staffRequirements, (item) => {
      const dayMoment = item.startTime || item.endTime
        ? moment(item.startTime || item.endTime)
        : moment()

      const startDateTime = dayMoment.startOf('day')

      const startTime = item.startTime ? item.startTime.diff(startDateTime) : undefined
      const endTime = item.endTime ? item.endTime.diff(startDateTime) : undefined

      return {
        ...item,
        dayOfWeek: item.dayOfWeek?.value || item.dayOfWeek,
        endTime,
        staffRequired: Number(item.staffRequired),
        startTime,
      }
    })
  }

  return preparedValues
}
