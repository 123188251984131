export const GET_INVOICE_PREVIEW = 'GET_INVOICE_PREVIEW'
export const GET_INVOICE_PREVIEW_SUCCESS = 'GET_INVOICE_PREVIEW_SUCCESS'
export const GET_INVOICE_PREVIEW_FAILED = 'GET_INVOICE_PREVIEW_FAILED'

export const REPEAT_INVOICE_PREVIEW = 'REPEAT_INVOICE_PREVIEW'
export const REPEAT_INVOICE_PREVIEW_SUCCESS = 'REPEAT_INVOICE_PREVIEW_SUCCESS'
export const REPEAT_INVOICE_PREVIEW_FAILED = 'REPEAT_INVOICE_PREVIEW_FAILED'

export const ADD_INVOICE_ITEM = 'ADD_INVOICE_ITEM'
export const REMOVE_INVOICE_ITEM = 'REMOVE_INVOICE_ITEM'

export const SET_INITIAL_VALUES = 'SET_INITIAL_VALUES'

export const CLEAR_INVOICE_PREVIEW = 'CLEAR_INVOICE_PREVIEW'
