import { generateBasicActions } from 'utils/service'

import {
  CLEAR_FINANCE_REPORTS_AGE_DEBT_STATISTICS,
  FINANCE_REPORTS_AGE_DEBT_STATISTICS,
  FINANCE_REPORTS_AGE_DEBT_STATISTICS_FAILED,
  FINANCE_REPORTS_AGE_DEBT_STATISTICS_SUCCESS,
} from './constants'

export const getStatistics = (options = {}) => ({ financeBadDebtReportApiClient }) => generateBasicActions.list({
  constants: {
    failed: FINANCE_REPORTS_AGE_DEBT_STATISTICS_FAILED,
    init: FINANCE_REPORTS_AGE_DEBT_STATISTICS,
    success: FINANCE_REPORTS_AGE_DEBT_STATISTICS_SUCCESS,
  },
  options,
  service: financeBadDebtReportApiClient,
  serviceMethod: 'getStatistics',
})

export const clearStatistics = () => ({
  type: CLEAR_FINANCE_REPORTS_AGE_DEBT_STATISTICS,
})
