import _ from 'lodash'

import { TOILET_TRAINED } from 'services/legacy/child/constants'

import { addDiaryRecord, removeDiaryRecord, updateDiaryRecord } from 'services/legacy/dailyDiary/helpers'

export const findDiaryNappyRecord = (state, data) => {
  const childRecord = _.find(state.data, ({ childRegister }) => (
    childRegister.child.id === data.child.id
  ))

  const { comments, id, nappyRash, recordedAt, type, wasCreamApplied } = data

  return {
    childRecord,
    newDiaryRecord: {
      id,
      nappyRash,
      record: {
        comments,
        recordedAt,
      },
      type,
      wasCreamApplied,
    },
  }
}

const filterUpdatedDiaryNappyRecord = (data) => _.filter(data, ({ childRegister, diaryRecords }) => {
  if (
    childRegister?.child?.information?.toiletTrained
    && TOILET_TRAINED.NAPPIES === childRegister.child.information.toiletTrained
  ) {
    return true
  }

  return diaryRecords?.length
})

export const addDiaryNappyRecord = (state, payload) => addDiaryRecord(state, payload, findDiaryNappyRecord)

export const updateDiaryNappyRecord = (state, data) => updateDiaryRecord(state, data, findDiaryNappyRecord)

export const removeDiaryNappyRecord = (state, id) => removeDiaryRecord(state, id, filterUpdatedDiaryNappyRecord)
