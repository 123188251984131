import _ from 'lodash'
import i18n from 'translations'

import React from 'react'

import { Banner } from 'components'

import { StyledContainer, StyledInfoBlockContainer } from './InvoicePaymentReminderCaptionStyled'

const InvoicePaymentReminderCaption = ({ isEdit, paymentReminderTags }) => (
  <StyledContainer>
    {i18n.t('components:Settings:InvoiceSettings:PaymentReminder:caption')}
    {isEdit && (
      <StyledInfoBlockContainer>
        <Banner.Info hideIcon>
          {i18n.t('components:Settings:InvoiceSettings:PaymentReminder:infoBlockCopy')}
          <br />
          {_.map(paymentReminderTags, ({ name }) => (
            <React.Fragment>
              <br />
              {name}
            </React.Fragment>
          ))}
        </Banner.Info>
      </StyledInfoBlockContainer>
    )}
  </StyledContainer>
)

export default InvoicePaymentReminderCaption
