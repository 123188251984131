import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`

export const StyledText = styled.p`
  margin: 5px 0;
`

interface StyledCountProps {
  $color?: string
}

export const StyledCount = styled.strong<StyledCountProps>`
  font-size: 16px;
  ${({ $color }) => $color && `color: ${$color};`}
`
