export const CLEAR_MEMBERSHIP = 'CLEAR_MEMBERSHIP'

export const CONFIRM_GDPR = 'CONFIRM_GDPR'
export const CONFIRM_GDPR_FAILED = 'CONFIRM_GDPR_FAILED'
export const CONFIRM_GDPR_SUCCESS = 'CONFIRM_GDPR_SUCCESS'

export const CREATE_MEMBERSHIP = 'CREATE_MEMBERSHIP'
export const CREATE_MEMBERSHIP_FAILED = 'CREATE_MEMBERSHIP_FAILED'
export const CREATE_MEMBERSHIP_SUCCESS = 'CREATE_MEMBERSHIP_SUCCESS'

export const GET_MEMBERSHIP = 'GET_MEMBERSHIP'
export const GET_MEMBERSHIP_FAILED = 'GET_MEMBERSHIP_FAILED'
export const GET_MEMBERSHIP_SUCCESS = 'GET_MEMBERSHIP_SUCCESS'

export const GET_ORGANIZATION_MEMBERSHIP = 'GET_ORGANIZATION_MEMBERSHIP'
export const GET_ORGANIZATION_MEMBERSHIP_FAILED = 'GET_ORGANIZATION_MEMBERSHIP_FAILED'
export const GET_ORGANIZATION_MEMBERSHIP_SUCCESS = 'GET_ORGANIZATION_MEMBERSHIP_SUCCESS'

export const UPDATE_MEMBERSHIP = 'UPDATE_MEMBERSHIP'
export const UPDATE_MEMBERSHIP_FAILED = 'UPDATE_MEMBERSHIP_FAILED'
export const UPDATE_MEMBERSHIP_SUCCESS = 'UPDATE_MEMBERSHIP_SUCCESS'

export const UPDATE_MEMBERSHIP_PASSWORD = 'UPDATE_MEMBERSHIP_PASSWORD'
export const UPDATE_MEMBERSHIP_PASSWORD_FAILED = 'UPDATE_MEMBERSHIP_PASSWORD_FAILED'
export const UPDATE_MEMBERSHIP_PASSWORD_SUCCESS = 'UPDATE_MEMBERSHIP_PASSWORD_SUCCESS'

export const SET_SELECTED_MEMBERSHIP = 'SET_SELECTED_MEMBERSHIP'
