import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { Arc, Button, Icon, XMasIcon } from 'components'
import Widget from 'module/Dashboard/components/Widget'

import i18n from 'translations'

import { StyledActiveCount, StyledContainer, StyledCountContainer, StyledTotalCount } from './AttendanceWidgetStyled'

const AttendanceWidgetView = ({ isFetching, registerStatistics }) => {
  const renderContent = () => {
    const { attending, expected } = registerStatistics

    return (
      <React.Fragment>
        <XMasIcon height="110px" icon="hanging-ball-left" marginLeft="-256px" marginTop="-129px" width="48px" />
        <XMasIcon height="19px" icon="circle-variant-3" marginLeft="-283px" marginTop="74px" width="18px" />
        <StyledContainer>
          <Arc
            content={(
              <Icon
                color={NEUTRAL_COLOURS.WHITE}
                height={39}
                icon="check"
              />
            )}
            size={140}
            total={expected || 0}
            value={attending || 0}
          />
          <StyledCountContainer>
            <StyledActiveCount>
              {attending}
            &nbsp;
            </StyledActiveCount>
            <StyledTotalCount>
              {`${i18n.t('global:of')} ${expected}`}
            </StyledTotalCount>
          </StyledCountContainer>
        </StyledContainer>
        <XMasIcon height="19px" icon="circle-variant-3" marginLeft="214px" marginTop="15px" width="18px" />
        <XMasIcon height="220px" icon="hanging-ball-right" marginLeft="254px" marginTop="-20px" width="40px" />
        <XMasIcon height="14px" icon="snowflake-star" marginLeft="-186px" marginTop="-176px" width="14px" />
        <XMasIcon height="25px" icon="circle-variant-1" marginLeft="-254px" marginTop="173px" width="23px" />
        <Button
          hierarchy="secondary"
          label={i18n.t('module:Dashboard:Widgets:AttendanceWidget:viewRegister')}
          to={generateRoute('REGISTER.INDEX')}
          inverted
        />
        <XMasIcon height="21px" icon="circle-variant-2" marginLeft="254px" marginTop="130px" width="18px" />
      </React.Fragment>
    )
  }

  return (
    <Widget>
      <Widget.Header>
        <XMasIcon height="26px" icon="snowflake-variant-2" marginLeft="-254px" width="23px" />
        {i18n.t('module:Dashboard:Widgets:AttendanceWidget:title')}
        <XMasIcon height="25px" icon="snowflake-variant-3" marginLeft="200px" marginTop="5px" width="25px" />
      </Widget.Header>
      <Widget.Content
        horizontalAlign="center"
        isLoading={isFetching}
        verticalAlign="center"
      >
        {!isFetching && renderContent()}
      </Widget.Content>
    </Widget>
  )
}

export default AttendanceWidgetView
