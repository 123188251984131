import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import auth from 'utils/auth'

import { FUNDING_STATUS_OPTIONS } from 'services/legacy/childBulkFunding/constants'
import { AGE_IN_MONTHS_OPTIONS } from 'services/legacy/child/constants'
import { FUNDING_MODAL_TYPE } from 'module/Modals/FundingModal/constants'
import { FUNDING_FEES_TYPES } from 'services/nurseryFunding/constants'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withChildBulkFundingService } from 'services/legacy/childBulkFunding'
import { withChildFundingService } from 'services/legacy/childFunding'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'

import i18n from 'translations'

import FinanceFundingView from './FinanceFundingView'

const FUNDING_GROUPS = {
  read: [
    'child',
    'childFunding.funding',
    'nurseryFunding',
    'nurseryFunding.fundingType',
    'fundingType',
  ],
}

const ARCHIVE_FUNDING_GROUPS = {
  read: [
    'childFunding.child',
    'childFunding.funding',
    'nurseryFunding',
    'nurseryFunding.fundingType',
    'fundingType',
  ],
}

class FinanceFundingContainer extends Component {
  constructor(props) {
    super(props)

    const { location } = props
    const { query } = location
    const { date, fundingStatus, fundingType, room } = query

    this.state = {
      archiveFundingType: null,
      assignFundingType: null,
      filters: {
        date: date ? moment(date).toDate() : new Date(),
        fromAge: null,
        fundingStatus,
        fundingType,
        room,
        search: null,
        toAge: null,
      },
      selectAll: false,
      selectedChildren: [],
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate() {
    const { isFinanceV3Enabled, router } = this.props

    if (isFinanceV3Enabled) {
      router.replace(generateRoute('FINANCE.INDEX'))
    }
  }

  handleSearchChange = (search) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, search },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  fetch = () => {
    const {
      childBulkFundingActions,
      childBulkFundingSelectors,
      paginationUtils,
      setLocationQuery,
    } = this.props
    const { filters } = this.state
    const { page } = paginationUtils

    this.setState({
      selectAll: false,
      selectedChildren: [],
    })

    setLocationQuery({
      ...filters,
      date: filters.date ? moment(filters.date).format('YYYY-MM-DD') : null,
    })

    const criteria = childBulkFundingSelectors.getListCriteria(filters)

    childBulkFundingActions.list({
      params: {
        criteria,
        groups: FUNDING_GROUPS,
        page,
      },
    })
  }

  handleCreateFundingTypeSuccess = () => {
    const { modalActions } = this.props

    modalActions.hide()

    this.fetch()
  }

  handleCreateFundingTypeClick = () => {
    const { isFinanceV3Enabled, modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.FUNDING, {
      modalComponentType: isFinanceV3Enabled
        ? FUNDING_MODAL_TYPE.ADD_NURSERY_FUNDING_V3
        : FUNDING_MODAL_TYPE.ADD_NURSERY_FUNDING,
      onCreateSuccess: this.handleCreateFundingTypeSuccess,
      title: i18n.t('module:Finance:Funding:createFundingTypeButtonLabel'),
    }, {
      enableMultipleModal: true,
    })
  }

  handleDateChange = (date) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        date,
      },
    }), () => onPageChange(this.fetch)(1))
  }

  handleRoomChange = (room) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        room: room?.value,
      },
    }), () => onPageChange(this.fetch)(1))
  }

  handleFundingStatusChange = (fundingStatus) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        fundingStatus: fundingStatus?.value,
      },
    }), () => onPageChange(this.fetch)(1))
  }

  handleFundingTypeChange = (fundingType) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        fundingType: fundingType?.value,
      },
    }), () => onPageChange(this.fetch)(1))
  }

  handleAgeChange = (key) => (age) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        [key]: age?.value,
      },
    }), () => onPageChange(this.fetch)(1))
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleSelectChild = (childId) => {
    this.setState((prevState) => {
      const { selectedChildren } = prevState

      if (0 <= _.findIndex(selectedChildren, (id) => id === childId)) {
        return ({
          selectAll: false,
          selectedChildren: _.reject(prevState.selectedChildren, (id) => id === childId),
        })
      }

      return ({
        selectAll: false,
        selectedChildren: [...selectedChildren, childId],
      })
    })
  }

  handleSelectAll = () => {
    const { childBulkFundingListState } = this.props

    this.setState((prevState) => ({
      selectAll: !prevState.selectAll,
      selectedChildren: !prevState.selectAll
        ? _.map(childBulkFundingListState?.data, ({ id }) => id)
        : [],
    }))
  }

  handleChildFundingSuccess = (response) => {
    const { modalActions, modalConsts } = this.props

    const { data } = response
    const { child, id: childFundingId, type } = data
    const { id: childId } = child

    modalActions.hide()

    if (FUNDING_FEES_TYPES.MANUAL_HOURS === type) {
      modalActions.show(modalConsts.TYPES.CONFIRM, {
        cancelButtonLabel: i18n.t('global:Skip'),
        confirmButtonLabel: i18n.t('global:Allocate'),
        onConfirm: () => this.handleAllocateChildFundingClick(childId, childFundingId),
        text: i18n.t('module:Finance:Funding:childFundingManualAllocationMessage'),
      })

      return
    }

    modalActions.show(modalConsts.TYPES.ALERT, {
      text: i18n.t('module:Finance:Funding:childFundingAutoAllocationMessage'),
    })
  }

  handleAssignChildFundingClick = (childId) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.FUNDING, {
      modalChildProps: { childId },
      modalComponentType: FUNDING_MODAL_TYPE.ADD_CHILD_FUNDING,
      onCreateSuccess: this.handleChildFundingSuccess,
      title: i18n.t('module:Children:Child:BookingPattern:Funding:Add:addTitle'),
    })
  }

  handleAssignFundingTypeChange = (assignFundingType) => {
    this.setState({ assignFundingType })
  }

  handleAssignFundingTypeSuccess = () => {
    this.setState({
      archiveFundingType: null,
      assignFundingType: null,
      selectAll: false,
      selectedChildren: [],
    }, this.fetch)
  }

  handleAssignFundingTypeClick = () => {
    const { childBulkFundingActions, childBulkFundingSelectors } = this.props
    const { assignFundingType, selectedChildren } = this.state

    const payload = childBulkFundingSelectors.getBulkPayload({ fundingType: assignFundingType })
    const criteria = childBulkFundingSelectors.getBulkCriteria({ children: selectedChildren })

    return childBulkFundingActions.createBulk({
      onSuccess: this.handleAssignFundingTypeSuccess,
      params: { criteria },
      payload,
    })
  }

  handleAssignFundingTypePopperClose = () => {
    this.setState({ assignFundingType: null })
  }

  handleArchiveFundingTypeClick = () => {
    const { childBulkFundingActions, childBulkFundingSelectors } = this.props
    const { archiveFundingType, selectedChildren } = this.state

    const payload = childBulkFundingSelectors.getBulkPayload({
      archived: true,
      fundingType: archiveFundingType,
    })
    const criteria = childBulkFundingSelectors.getBulkCriteria({ children: selectedChildren })

    return childBulkFundingActions.updateBulk({
      onSuccess: this.handleAssignFundingTypeSuccess,
      params: { criteria },
      payload,
    })
  }

  handleArchiveFundingTypeChange = (archiveFundingType) => {
    this.setState({ archiveFundingType })
  }

  handleArchiveFundingTypePopperClose = () => {
    this.setState({ archiveFundingType: null })
  }

  handleShowAllFundingClick = (childId) => {
    const { navigate } = this.props

    navigate(generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.FUNDING', { childId }))
  }

  handleArchiveChildFundingSuccess = () => {
    const { modalActions } = this.props

    modalActions.hide()
    this.fetch()
  }

  archiveChildFundingConfirm = (childFundingId, type) => () => {
    const { childFundingActions } = this.props
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.ALERT, {
      text: i18n.t('module:Finance:Funding:archiveChildFundingWaitMessage'),
    })

    const payload = { archived: true, type }

    return childFundingActions.update(
      childFundingId,
      payload,
      { groups: ARCHIVE_FUNDING_GROUPS },
      this.handleArchiveChildFundingSuccess,
    )
  }

  handleArchiveChildFundingClick = (childFundingId, type) => {
    const { modalActions, modalConsts } = this.props
    const label = i18n.t('global:archive')

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: label,
      icon: label,
      onConfirm: this.archiveChildFundingConfirm(childFundingId, type),
      text: i18n.t('module:Children:Child:BookingPattern:Funding:Add:archivePopupCopy'),
    })
  }

  handleEditChildFundingClick = (childId, childFundingId) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.FUNDING, {
      modalChildProps: { childFundingId, childId },
      modalComponentType: FUNDING_MODAL_TYPE.ADD_CHILD_FUNDING,
      onCreateSuccess: this.handleChildFundingSuccess,
      title: i18n.t('module:Children:Child:BookingPattern:Funding:Add:editTitle'),
    }, {
      enableMultipleModal: true,
    })
  }

  handleAllocateChildFundingClick = (childId, childFundingId) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.FUNDING, {
      modalChildProps: { childFundingId, childId },
      modalComponentType: FUNDING_MODAL_TYPE.ALLOCATE_CHILD_FUNDING,
      title: i18n.t('module:Children:Child:BookingPattern:Funding:Allocation:title'),
    }, {
      enableMultipleModal: true,
    })
  }

  render() {
    const {
      childBulkFundingListState: { meta },
      isFetching,
      isFinanceV3Enabled,
      isSubmitting,
      paginationUtils,
      tableData,
    } = this.props
    const {
      archiveFundingType,
      assignFundingType,
      filters,
      selectAll,
      selectedChildren,
    } = this.state
    const { date, fromAge, fundingStatus, fundingType, room, toAge } = filters
    const hasOtherFilters = !!(room || fundingType || fundingStatus || fromAge || toAge)
    const { getPageCount, page, perPage } = paginationUtils

    const pageCount = getPageCount(meta.total_results)

    return (
      <FinanceFundingView
        ageMonthsOptions={AGE_IN_MONTHS_OPTIONS}
        archiveFundingType={archiveFundingType}
        assignFundingType={assignFundingType}
        date={date}
        fromAge={fromAge}
        fundingStatus={fundingStatus}
        fundingStatusOptions={FUNDING_STATUS_OPTIONS}
        fundingType={fundingType}
        hasOtherFilters={hasOtherFilters}
        isFinanceV3Enabled={isFinanceV3Enabled}
        isLoading={isFetching}
        isSubmitting={isSubmitting}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        room={room}
        selectAll={selectAll}
        selectedChildren={selectedChildren}
        tableData={tableData}
        toAge={toAge}
        totalResults={meta.total_results}
        onAgeChange={this.handleAgeChange}
        onAllocateChildFundingClick={this.handleAllocateChildFundingClick}
        onArchiveChildFundingClick={this.handleArchiveChildFundingClick}
        onArchiveFundingTypeChange={this.handleArchiveFundingTypeChange}
        onArchiveFundingTypeClick={this.handleArchiveFundingTypeClick}
        onArchiveFundingTypePopperClose={this.handleArchiveFundingTypePopperClose}
        onAssignChildFundingClick={this.handleAssignChildFundingClick}
        onAssignFundingTypeChange={this.handleAssignFundingTypeChange}
        onAssignFundingTypeClick={this.handleAssignFundingTypeClick}
        onAssignFundingTypePopperClose={this.handleAssignFundingTypePopperClose}
        onCreateFundingTypeClick={this.handleCreateFundingTypeClick}
        onDateChange={this.handleDateChange}
        onEditChildFundingClick={this.handleEditChildFundingClick}
        onFundingStatusChange={this.handleFundingStatusChange}
        onFundingTypeChange={this.handleFundingTypeChange}
        onPageChange={this.handlePageChange}
        onRoomChange={this.handleRoomChange}
        onSearchChange={this.handleSearchChange}
        onSelectAll={this.handleSelectAll}
        onSelectChild={this.handleSelectChild}
        onShowAllFundingClick={this.handleShowAllFundingClick}
      />
    )
  }
}

const mapState = (state, { appSelectors, childBulkFundingListState, childBulkFundingSelectors }) => ({
  errorMessages: appSelectors.getErrorMessages(childBulkFundingListState),
  isFetching: appSelectors.getIsFetching(childBulkFundingListState),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
  isSubmitting: appSelectors.getIsSubmitting(childBulkFundingListState),
  tableData: childBulkFundingSelectors.getTableData(state),
})

const enhance = compose(
  withAppService,
  withModalService,
  withChildBulkFundingService,
  withChildFundingService,
  withPaginationUtils,
  withRouterUtils,
  connect(mapState),
)

export default enhance(FinanceFundingContainer)
