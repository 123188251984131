import React from 'react'
import { compose } from 'recompose'

import { withModalService } from 'services/utils/modal'

import { getPreviewOptions } from './PreviewInvoiceDeductionModalHelper'

import PreviewInvoiceDeductionModalView from './PreviewInvoiceDeductionModalView'

const PreviewInvoiceDeductionModalContainer = ({
  fundingNamePreview = '3 year old (15 hours)',
  hideModal,
}) => {
  const handleCloseClick = () => {
    hideModal()
  }

  const previewOptions = getPreviewOptions(fundingNamePreview)

  return (
    <PreviewInvoiceDeductionModalView
      previewOptions={previewOptions}
      onCloseClick={handleCloseClick}
    />
  )
}

const enhance = compose(
  withModalService,
)

export default enhance(PreviewInvoiceDeductionModalContainer)
