import { createSelector } from 'reselect'

import { getSubdomainInfo } from 'services/subdomain/single/selectors'

import { CONTEXT_ADMIN, CONTEXT_NURSERY, CONTEXT_ORGANIZATION } from './constants'

export const isOrganizationContext = createSelector(
  [getSubdomainInfo],
  (subdomain) => subdomain && subdomain.type === CONTEXT_ORGANIZATION,
)

export const isNurseryContext = createSelector(
  [getSubdomainInfo],
  (subdomain) => subdomain && subdomain.type === CONTEXT_NURSERY,
)

export const isAdministrationContext = createSelector(
  [getSubdomainInfo],
  (subdomain) => subdomain && subdomain.type === CONTEXT_ADMIN,
)
