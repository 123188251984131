import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_CARER,
  CREATE_CARER,
  CREATE_CARER_FAILED,
  CREATE_CARER_SUCCESS,
  GET_CARER,
  GET_CARER_FAILED,
  GET_CARER_SUCCESS,
  REMOVE_CARER,
  REMOVE_CARER_FAILED,
  REMOVE_CARER_SUCCESS,
  RESEND_PARENT_APP_INVITATION,
  RESEND_PARENT_APP_INVITATION_FAILED,
  RESEND_PARENT_APP_INVITATION_SUCCESS,
  UPDATE_CARER,
  UPDATE_CARER_FAILED,
  UPDATE_CARER_SUCCESS,
} from './constants'

const initialState = {
  ...getBasicSingleInitialState,
  isInvitationResending: false,
}

export default (...props) => {
  const [state = initialState, { type }] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_CARER,
    createFailed: CREATE_CARER_FAILED,
    createInit: CREATE_CARER,
    createSuccess: CREATE_CARER_SUCCESS,
    itemFailed: GET_CARER_FAILED,
    itemInit: GET_CARER,
    itemSuccess: GET_CARER_SUCCESS,
    removeFailed: REMOVE_CARER_FAILED,
    removeInit: REMOVE_CARER,
    removeSuccess: REMOVE_CARER_SUCCESS,
    updateFailed: UPDATE_CARER_FAILED,
    updateInit: UPDATE_CARER,
    updateSuccess: UPDATE_CARER_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  if (type === RESEND_PARENT_APP_INVITATION) {
    return {
      ...state,
      isInvitationResending: true,
    }
  }

  if (type === RESEND_PARENT_APP_INVITATION_SUCCESS
    || type === RESEND_PARENT_APP_INVITATION_FAILED) {
    return {
      ...state,
      isInvitationResending: false,
    }
  }

  return state
}
