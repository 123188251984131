import { createSelector } from 'reselect'

import _ from 'lodash'
import moment from 'moment'

import { RootState } from 'core/reducers'
import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { ProductPriceGroups } from 'services/product/productPriceGroups/models'
import {
  CONSUMABLE_CHARGEABLE_OPTIONS,
  SESSION_TYPES,
  SessionAddFormValues,
} from 'module/Management/ManagementFinanceSettings/Sessions/V3/Add/components/models'

import { convertDecimalToInteger } from 'utils/data'

const getNurserySessions = (state: RootState) => state.nurserySessionsV3

export const getNurserySession = createSelector(
  [getNurserySessions],
  (state) => state.single.data,
)

export const isInheritedFromOrganizationSelector = createSelector(
  [getNurserySession],
  (nurserySession) => !!(nurserySession && nurserySession.organizationSession && nurserySession.organizationSession.id),
)

export const getInitialValues = (session, isEdit, productPriceGroups) => {
  if (!isEdit) {
    const response = {
      nurseryFees: [{
        endDate: null,
        startDate: null,
      }],
      sessionType: SESSION_TYPES.FIXED_SESSION_TIME,
    }

    _.each(productPriceGroups, ({ id }) => {
      response.nurseryFees[0] = {
        ...response.nurseryFees[0],
        [`priceGroups-${id}`]: {
          value: 0,
        },
      }
    })

    return response
  }

  if (!session) {
    return null
  }

  const {
    endTime,
    hourly,
    items,
    name,
    priceChanges,
    startTime,
  } = session

  const body = {
    endTime,
    name,
    nurseryConsumablesExtraItems: [],
    nurseryFees: [],
    sessionType: hourly ? SESSION_TYPES.FLEXIBLE_HOURLY_SESSION : SESSION_TYPES.FIXED_SESSION_TIME,
    startTime,
  }

  _.each(items, ({ link, settings }, i) => {
    body.nurseryConsumablesExtraItems[i] = {
      chargeable: _.find(CONSUMABLE_CHARGEABLE_OPTIONS, { value: settings.chargeable }),
      consumable: {
        label: link.name,
        value: link.id,
      },
    }
  })

  _.each(priceChanges, ({ endDate = null, id, prices, startDate = null }, i) => {
    body.nurseryFees[i] = {
      endDate,
      id,
      startDate,
    }

    _.each(prices, ({ id: priceId, price, priceGroup }) => {
      body.nurseryFees[i][`priceGroups-${priceGroup.id}`] = {
        id: priceId,
        value: price / 100,
      }
    })

    _.each(items, ({ id: itemId, link, priceChanges: itemPriceChanges }) => {
      const itemPriceChangeRecord = _.find(itemPriceChanges, ({
        endDate: priceChangesEndDate = '',
        startDate: priceChangesStartDate = '',
      }) => {
        const priceChangesFormattedStartDate = priceChangesStartDate
          ? moment(priceChangesStartDate).format(DEFAULT_DATE_FORMAT)
          : ''
        const priceChangesFormattedEndDate = priceChangesEndDate
          ? moment(priceChangesEndDate).format(DEFAULT_DATE_FORMAT)
          : ''
        const formattedStartDate = startDate ? moment(startDate).format(DEFAULT_DATE_FORMAT) : ''
        const formattedEndDate = endDate ? moment(endDate).format(DEFAULT_DATE_FORMAT) : ''

        return priceChangesFormattedStartDate === (formattedStartDate || '')
          && priceChangesFormattedEndDate === (formattedEndDate || '')
      })

      body.nurseryFees[i][`consumable-${link.id}`] = {
        id: itemPriceChangeRecord.prices[0].id,
        itemId,
        priceChangeId: itemPriceChangeRecord.id,
        value: itemPriceChangeRecord.prices[0].price / 100,
      }
    })
  })

  return body
}

export const getPayload = (fields: SessionAddFormValues, productPriceGroups: ProductPriceGroups[]) => {
  const {
    endTime,
    name,
    nurseryConsumablesExtraItems,
    nurseryFees,
    sessionType,
    startTime,
  } = fields

  const hourly = sessionType === SESSION_TYPES.FLEXIBLE_HOURLY_SESSION

  const body = {
    endTime: !hourly ? endTime : null,
    hourly,
    items: [],
    name,
    priceChanges: [],
    startTime: !hourly ? startTime : null,
  }

  _.each(nurseryConsumablesExtraItems, ({ chargeable, consumable }) => {
    body.items.push({
      id: nurseryFees[0][`consumable-${consumable.value}`].itemId,
      link: { id: consumable.value },
      priceChanges: _.map(nurseryFees, (nurseryFee) => ({
        endDate: nurseryFee.endDate ? moment(nurseryFee.endDate).format(DEFAULT_DATE_FORMAT) : null,
        id: nurseryFee[`consumable-${consumable.value}`].priceChangeId,
        prices: [{
          id: nurseryFee[`consumable-${consumable.value}`].id
            ? +nurseryFee[`consumable-${consumable.value}`].id
            : undefined,
          price: convertDecimalToInteger(+nurseryFee[`consumable-${consumable.value}`].value),
        }],
        startDate: nurseryFee.startDate ? moment(nurseryFee.startDate).format(DEFAULT_DATE_FORMAT) : null,
      })),
      settings: {
        applicable: 'all',
        chargeable: hourly ? chargeable.value : undefined,
      },
      type: 'nursery_session_consumable',
    })
  })

  _.each(nurseryFees, (nurseryFee) => {
    body.priceChanges.push({
      endDate: nurseryFee.endDate ? moment(nurseryFee.endDate).format(DEFAULT_DATE_FORMAT) : null,
      id: nurseryFee.id,
      prices: _.map(productPriceGroups, ({ id }) => ({
        id: nurseryFee[`priceGroups-${id}`].id ? +nurseryFee[`priceGroups-${id}`].id : undefined,
        price: convertDecimalToInteger(+nurseryFee[`priceGroups-${id}`].value),
        priceGroup: { id },
      })),
      startDate: nurseryFee.startDate ? moment(nurseryFee.startDate).format(DEFAULT_DATE_FORMAT) : null,
    })
  })

  return body
}
