import styled from 'styled-components'

export const StyledItemWrapperContainer = styled.div`
  display: flex;
`

export const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  margin: -5px;
  
  & ${StyledItemWrapperContainer} ~ ${StyledItemWrapperContainer} {
    @media (min-width: 600px) {
      ${({ showSeparator }) => showSeparator
    && (
      `
        border-top: 2px dotted #bbb;
      `
    )}
    }

    @media (min-width: 768px) {
      border-top: none;
      ${({ isEditing, showSeparator, size }) => !isEditing
    && 'small' !== size
    && showSeparator
    && (
      `
          border-left: 2px dotted #bbb;
          margin-left: 5px;
          padding-left: 5px;
        `
    )}
    }

    @media (min-width: 1024px) {
      border-top: none;
      ${({ isEditing, showSeparator }) => !isEditing && showSeparator && `
        border-left: 2px dotted #bbb;
        margin-left: 5px;
        padding-left: 5px;
      `}
    }
  }

  @media (min-width: 600px) {
    ${({ showSeparator }) => showSeparator && `
      flex-direction: column;
    `}
  }

  @media (min-width: 768px) {
    ${({ size }) => 'small' !== size && `
      flex-direction: row;
      margin: 0;
    `}
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    margin: 0;
  }
`

export const StyledItemContainer = styled.div`
  padding: 10px 2px;

  @media (min-width: 600px) {
    padding: ${({ size }) => 'small' !== size && '10px'};
  }

  @media (min-width: 768px) {
    padding: ${({ size }) => 'small' === size && '10px'};
  }

  @media (min-width: 900px) {
    padding: ${({ size }) => 'small' === size && '10px'};
  }

  @media (min-width: 1024px) {
    padding: ${({ size }) => 'small' === size && '10px 2px'};
  }
`
