import _ from 'lodash'

import React from 'react'

import { Pagination, Table } from 'components'

import i18n from 'translations'

import { renderItem } from '../NurseriesListHelpers'

const NURSERIES_COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'nursery',
    title: i18n.t('module:Nurseries:List:TableHeaders:nursery'),
    width: '30%',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'manager',
    title: i18n.t('module:Nurseries:List:TableHeaders:manager'),
    width: '30%',
  },
  {
    field: 'staff',
    title: i18n.t('module:Nurseries:List:TableHeaders:staff'),
    width: '15%',
  },
  {
    field: 'children',
    title: i18n.t('module:Nurseries:List:TableHeaders:children'),
    width: '15%',
  },
  {
    field: 'edit',
    width: '10%',
  },
]

const NurseriesList = ({
  authAccessMap,
  isFetching,
  nurseries,
  onPageChange,
  page,
  pageCount,
  perPage,
  totalResults,
}) => {
  const renderPagination = () => !isFetching && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural="nurseries"
      titleSingular="nursery"
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  return (
    <React.Fragment>
      {renderPagination()}
      <Table
        columns={NURSERIES_COLUMNS}
        data={_.map(nurseries, (item) => renderItem({ authAccessMap, item }))}
        visualType="flat"
      />
      {renderPagination()}
    </React.Fragment>
  )
}

export default NurseriesList
