import moment from 'moment'

import React from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'
import { WrappedComponentType } from 'constants/types'
import { CHILD_AGES_OPTIONS } from 'services/legacy/child/constants'
import { ROLES } from 'constants/security'

import { withAppService, withAppServiceProps } from 'services/app'
import { withRouteUtilsProps } from 'services/utils/router'
import {
  withChildrenSignedUpReportService,
  withChildrenSignedUpReportServiceProps,
} from 'services/childrenSignedUpReport'
import { withPaginationUtils, withPaginationUtilsProps } from 'services/utils/pagination'
import { withSecurityService, withSecurityServiceProps } from 'services/security'
import { withRouter, withRouterProps } from 'services/router'

import { useBasicReport } from 'hooks/reports'

import { DatePicker, Form, InfiniteDropdowns, ReportView, Select, Toolbar } from 'components'

import i18n from 'translations'

import { getColumns, getStatistics, getTableData } from './ChildrenSignedUpHelper'

type ChildrenSignedUpContainerProps = withAppServiceProps
  & withChildrenSignedUpReportServiceProps
  & withSecurityServiceProps
  & withRouteUtilsProps
  & withRouterProps
  & withPaginationUtilsProps

const mapState = (state: RootState, {
  appSelectors,
  childrenSignedUpReportChildrenState,
  childrenSignedUpReportNurseriesState,
  childrenSignedUpReportSelectors,
  childrenSignedUpReportStatisticsState,
  securitySelectors,
}: ChildrenSignedUpContainerProps) => ({
  childrenData: childrenSignedUpReportSelectors.getChildrenSignedUpReportChildrenData(state),
  childrenTotalResults: appSelectors.getTotalResults(childrenSignedUpReportChildrenState),
  errorMessages: appSelectors.getErrorMessages(
    childrenSignedUpReportChildrenState,
    childrenSignedUpReportNurseriesState,
    childrenSignedUpReportStatisticsState,
  ),
  isFetching: appSelectors.getIsFetching(childrenSignedUpReportChildrenState, childrenSignedUpReportNurseriesState),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
  isStatisticsFetching: appSelectors.getIsFetching(childrenSignedUpReportStatisticsState),
  nurseriesData: childrenSignedUpReportSelectors.getChildrenSignedUpReportNurseriesData(state),
  nurseriesTotalResults: appSelectors.getTotalResults(childrenSignedUpReportNurseriesState),
  statisticsData: childrenSignedUpReportSelectors.getChildrenSignedUpReportStatisticsData(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const CHILDREN_SIGNUP_GROUPS = {
  read: [
    'child',
    'child.nurseryClass',
    'nurseryClass',
    'child.extend',
  ],
}

const NURSERIES_SIGNUP_GROUPS = {
  read: [
    'nursery',
  ],
}

const ChildrenSignedUpContainer: WrappedComponentType<ChildrenSignedUpContainerProps & PropsFromRedux> = ({
  childrenData,
  childrenSignedUpReportActions,
  childrenSignedUpReportSelectors,
  childrenTotalResults,
  errorMessages,
  isFetching,
  isOrganizationContext,
  isStatisticsFetching,
  location,
  nurseriesData,
  nurseriesTotalResults,
  paginationUtils,
  setLocationQuery,
  statisticsData,
}) => {
  const {
    age,
    dateRange,
    onAgeChange,
    onDateChange,
    onPageChange,
    onRoomChange,
    page,
    pageCount,
    perPage,
    room,
    statistics,
    tableColumns,
    tableData,
    totalResults,
  } = useBasicReport({
    actions: childrenSignedUpReportActions,
    ageOptions: CHILD_AGES_OPTIONS,
    callStatisticsAction: true,
    childrenApiGroups: CHILDREN_SIGNUP_GROUPS,
    childrenData,
    childrenTotalResults,
    defaultFilters: {
      date: {
        after: moment().startOf('month'),
        before: moment().endOf('month'),
      },
    },
    errorMessages,
    getColumns,
    getStatistics,
    getTableData,
    isOrganizationContext,
    location,
    nurseriesApiGroups: NURSERIES_SIGNUP_GROUPS,
    nurseriesData,
    nurseriesTotalResults,
    paginationUtils,
    selectors: childrenSignedUpReportSelectors,
    setLocationQuery,
    statisticsData,
  })

  return (
    <ReportView.DetailPage
      description={i18n.t('module:Reports:ChildrenSignedUp:description')}
      errorMessages={errorMessages}
      filters={(
        <Toolbar bottomLine>
          <Toolbar.Group>
            <Toolbar.Item>
              <Form.Row
                label={i18n.t('module:Reports:ChildrenSignedUp:Filters:RegistrationDate:label')}
                verticalLabel
              >
                <DatePicker
                  clearable={false}
                  value={dateRange}
                  range
                  onChange={onDateChange}
                />
              </Form.Row>
            </Toolbar.Item>
            {!isOrganizationContext && (
              <Toolbar.Item>
                <Form.Row
                  label={i18n.t('module:Reports:ChildrenSignedUp:Filters:Rooms:label')}
                  verticalLabel
                >
                  <InfiniteDropdowns.Rooms
                    placeholder={i18n.t('global:AllRooms')}
                    value={room}
                    onChange={onRoomChange}
                  />
                </Form.Row>
              </Toolbar.Item>
            )}
            <Toolbar.Item>
              <Form.Row
                label={i18n.t('module:Reports:ChildrenSignedUp:Filters:Age:label')}
                verticalLabel
              >
                <Select<typeof age>
                  options={CHILD_AGES_OPTIONS}
                  placeholder={i18n.t('global:AllAges')}
                  value={age}
                  onChange={onAgeChange}
                />
              </Form.Row>
            </Toolbar.Item>
          </Toolbar.Group>
        </Toolbar>
      )}
      isPageLoading={isFetching && isStatisticsFetching && 1 === page}
      isStatisticsLoading={isStatisticsFetching}
      isTableLoading={isFetching}
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      statistics={statistics}
      tableColumns={tableColumns}
      tableData={tableData}
      title={i18n.t('module:Reports:ChildrenSignedUp:title')}
      totalResults={totalResults}
      hideExport
      onPageChange={onPageChange}
    />
  )
}

const enhance = compose(
  withRouter,
  withAppService,
  withChildrenSignedUpReportService,
  withPaginationUtils,
  withSecurityService,
  connector,
)

ChildrenSignedUpContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

export default enhance(ChildrenSignedUpContainer)
