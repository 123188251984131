import i18n from 'translations'

export const NEXT_STEP_TYPE = {
  EYFS: 'EYFS',
  MONTESSORI: 'MONTESSORI',
}

export const NEXT_STEPS_FILTERS = {
  ACHIEVED: 'ACHIEVED',
  ACHIEVED_NO_OBSERVATION: 'ACHIEVED_NO_OBSERVATION',
  WORKING_ON: 'WORKING_ON',
}

export const NEXT_STEPS_FILTERS_OPTIONS = [
  {
    label: i18n.t('services:NextSteps:WorkingOn'),
    value: NEXT_STEPS_FILTERS.WORKING_ON,
  },
  {
    label: i18n.t('services:NextSteps:Achieved'),
    value: NEXT_STEPS_FILTERS.ACHIEVED,
  },
  {
    label: i18n.t('services:NextSteps:AchievedNoObservation'),
    value: NEXT_STEPS_FILTERS.ACHIEVED_NO_OBSERVATION,
  },
]

export const MONTESSORI_AREA = {
  colour: '#373736',
  shortName: 'Montessori',
}
