import { generateBasicActions } from 'utils/service'

import {
  CLEAR_REQUESTED_EXTRA_SESSIONS_COST_STATISTICS,
  REQUESTED_EXTRA_SESSIONS_COST_STATISTICS,
  REQUESTED_EXTRA_SESSIONS_COST_STATISTICS_FAILED,
  REQUESTED_EXTRA_SESSIONS_COST_STATISTICS_SUCCESS,
} from './constants'

export const getCostStatistics = (options = {}) => ({ requestedExtraSessionsApiClient }) => generateBasicActions.get({
  constants: {
    failed: REQUESTED_EXTRA_SESSIONS_COST_STATISTICS_FAILED,
    init: REQUESTED_EXTRA_SESSIONS_COST_STATISTICS,
    success: REQUESTED_EXTRA_SESSIONS_COST_STATISTICS_SUCCESS,
  },
  options,
  service: requestedExtraSessionsApiClient,
  serviceMethod: 'getCostStatistics',
})

export const clearCostStatistics = () => ({
  type: CLEAR_REQUESTED_EXTRA_SESSIONS_COST_STATISTICS,
})
