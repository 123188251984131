import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, formValueSelector, getFormValues, stopSubmit, submit } from 'redux-form'

import { getBackendErrors } from 'utils/backendErrors'

const withInvoiceSettingsComponent = (formNamePrefix) => (WrappedComponent) => {
  class InvoiceComponent extends Component {
    constructor(props) {
      super(props)

      this.state = {
        isEdit: false,
        isSubmitting: false,
      }
    }

    handleCancelClick = () => {
      this.setState({ isEdit: false })
    }

    handleEditClick = () => () => {
      const { formName, submitForm } = this.props
      const { isEdit } = this.state

      if (isEdit) {
        submitForm(formName)

        return
      }

      this.setState({ isEdit: true })
    }

    handleSuccess = () => {
      this.setState({ isEdit: false, isSubmitting: false })
    }

    handleFailed = (response) => {
      const { formName, injectValidation } = this.props

      const errors = getBackendErrors(response)

      this.setState({ isSubmitting: false })

      setTimeout(() => {
        injectValidation(formName, errors)
      })
    }

    handleSubmit = (payload) => {
      const { onSubmit } = this.props

      this.setState({ isSubmitting: true })

      onSubmit(payload)(this.handleSuccess, this.handleFailed)
    }

    render() {
      const { isEdit, isSubmitting } = this.state

      return (
        <WrappedComponent
          {...this.props}
          isEdit={isEdit}
          isSubmitting={isSubmitting}
          onCancelClick={this.handleCancelClick}
          onEditClick={this.handleEditClick}
          onSubmit={this.handleSubmit}
        />
      )
    }
  }

  const mapState = (state, ownProps) => {
    const { fields: { global, item }, noteId } = ownProps
    const { id } = item
    const { id: globalId } = global
    const formName = `${formNamePrefix}_${noteId || id}`

    return {
      formName,
      formValues: getFormValues(formName)(state),
      isGlobalOptionOn: formValueSelector(formName)(state, globalId),
    }
  }

  const mapDispatch = {
    injectValidation: (formName, data) => stopSubmit(formName, data),
    submitForm: (formName) => submit(formName),
    updateOrgValue: (formName, fieldName, value) => change(formName, fieldName, value),
  }

  return connect(mapState, mapDispatch)(InvoiceComponent)
}

export default withInvoiceSettingsComponent
