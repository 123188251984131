export const ADD_BOTTLE = 'ADD_BOTTLE'
export const ADD_BOTTLE_SUCCESS = 'ADD_BOTTLE_SUCCESS'
export const ADD_BOTTLE_FAILED = 'ADD_BOTTLE_FAILED'

export const REMOVE_BOTTLE = 'REMOVE_BOTTLE'
export const REMOVE_BOTTLE_SUCCESS = 'REMOVE_BOTTLE_SUCCESS'
export const REMOVE_BOTTLE_FAILED = 'REMOVE_BOTTLE_FAILED'

export const UPDATE_BOTTLE = 'UPDATE_BOTTLE'
export const UPDATE_BOTTLE_SUCCESS = 'UPDATE_BOTTLE_SUCCESS'
export const UPDATE_BOTTLE_FAILED = 'UPDATE_BOTTLE_FAILED'
