import { generateBasicActions } from 'utils/service'

import {
  ADD_NAPPIE,
  ADD_NAPPIE_FAILED,
  ADD_NAPPIE_SUCCESS,
  REMOVE_NAPPIE,
  REMOVE_NAPPIE_FAILED,
  REMOVE_NAPPIE_SUCCESS,
  UPDATE_NAPPIE,
  UPDATE_NAPPIE_FAILED,
  UPDATE_NAPPIE_SUCCESS,
} from './constants'

export const addRecord = (options = {}) => ({ dailyDiaryNappiesApiClient }) => generateBasicActions.create({
  constants: {
    failed: ADD_NAPPIE_FAILED,
    init: ADD_NAPPIE,
    success: ADD_NAPPIE_SUCCESS,
  },
  options,
  service: dailyDiaryNappiesApiClient,
  serviceMethod: 'addRecord',
})

export const removeRecord = (id, onSuccess, onFailed) => ({ dailyDiaryNappiesApiClient }) => (dispatch) => {
  dispatch({ id, type: REMOVE_NAPPIE })

  dailyDiaryNappiesApiClient.removeRecord(id).then(
    () => {
      dispatch({ id, type: REMOVE_NAPPIE_SUCCESS })

      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: REMOVE_NAPPIE_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const updateRecord = (id, body, onSuccess, onFailed) => ({ dailyDiaryNappiesApiClient }) => (dispatch) => {
  dispatch({ type: UPDATE_NAPPIE })

  dailyDiaryNappiesApiClient.updateRecord(id, body).then(
    ({ data }) => {
      dispatch({ payload: data, type: UPDATE_NAPPIE_SUCCESS })

      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: UPDATE_NAPPIE_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}
