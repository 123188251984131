import { Property } from 'csstype'

import React, { PropsWithChildren } from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { typeByObject } from 'utils/typescript'

import { CircleIcon, Icon, Tooltip } from 'components'

import TableTh from './TableTh'
import { VISUAL_TYPE } from './Table'
import {
  StyledSortableThContent,
  StyledSortableThDefaultIconContainer,
  StyledSortableThIconContainer,
  StyledSortableThTitleContainer,
  StyledTooltipWrapper,
} from './TableStyled'

interface TableSortableThProps {
  align?: Property.JustifyContent
  background?: string
  icon?: IconType
  isStickyVersion?: boolean
  onClick?: () => void
  sortIconHeight?: number
  title?: string
  tooltip?: string
  visualType?: typeByObject<typeof VISUAL_TYPE>
  width?: string
}

const TableSortableTh: React.FC<PropsWithChildren<TableSortableThProps>> = ({
  align,
  background,
  icon,
  isStickyVersion,
  onClick,
  sortIconHeight,
  title,
  tooltip,
  visualType,
  width,
}) => {
  const iconColor = VISUAL_TYPE.TRANSPARENT === visualType ? NEUTRAL_COLOURS.GRAY : NEUTRAL_COLOURS.WHITE

  const renderIcon = () => {
    if (icon) {
      return (
        <StyledSortableThIconContainer>
          <Icon
            color={iconColor}
            height={sortIconHeight || (VISUAL_TYPE.TRANSPARENT === visualType ? 12 : 6)}
            icon={icon}
          />
        </StyledSortableThIconContainer>
      )
    }

    return (
      <StyledSortableThDefaultIconContainer>
        <Icon color={iconColor} height={7} icon="sort-up" />
        <Icon color={iconColor} height={7} icon="sort-down" />
      </StyledSortableThDefaultIconContainer>
    )
  }

  return (
    <TableTh
      background={background}
      isStickyVersion={isStickyVersion}
      visualType={visualType}
      width={width}
      onClick={onClick}
    >
      <StyledSortableThContent $align={align}>
        <StyledSortableThTitleContainer $visualType={visualType}>
          {tooltip ? (
            <StyledTooltipWrapper>
              <div>
                {title}
              </div>
              <Tooltip title={tooltip}>
                <CircleIcon
                  cursor="pointer"
                  icon="info"
                  iconSize={16}
                  size={24}
                />
              </Tooltip>
            </StyledTooltipWrapper>
          ) : title}
        </StyledSortableThTitleContainer>
        {renderIcon()}
      </StyledSortableThContent>
    </TableTh>
  )
}

export default TableSortableTh
