import { combineReducers } from 'redux'

import conversionRate from './conversionRate/reducer'
import conversionRateStatistics from './conversionRateStatistics/reducer'
import performance from './performance/reducer'
import viewingPerformance from './viewingPerformance/reducer'
import viewingPerformanceStatistics from './viewingPerformanceStatistics/reducer'

export default combineReducers({
  conversionRate,
  conversionRateStatistics,
  performance,
  viewingPerformance,
  viewingPerformanceStatistics,
})
