import _ from 'lodash'

import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { getBrandingColor } from 'utils/branding'

import { DataPreview, EmptyState, Section } from 'components'

import { withFormativeReportsService } from 'services/legacy/formativeReports'

import {
  StyledActivitiesList,
  StyledActivity,
  StyledRowContainer,
  StyledSubCategoryContainer,
  StyledSubCategoryName,
} from './MontessoriStyled'

const Montessori = ({
  montessoriItems,
}) => {
  if (!montessoriItems.length) {
    return (
      <EmptyState
        text1="There have been no Montessori activities selected during this time period."
      />
    )
  }

  const renderSubCategory = ({ subCategoryName, values: { secureItems, workOnItems } }) => (
    <StyledSubCategoryContainer>
      <StyledSubCategoryName>
        {subCategoryName}
      </StyledSubCategoryName>
      <StyledRowContainer key={subCategoryName}>
        <DataPreview
          content={secureItems && secureItems.length ? (
            <StyledActivitiesList>
              {_.map(secureItems, ({ id, name }) => (
                <StyledActivity
                  key={`${name}_${id}`}
                >
                  {name}
                </StyledActivity>
              ))}
            </StyledActivitiesList>
          ) : '---'}
          title="Completed activities"
          withBackground
        />
        <DataPreview
          content={workOnItems && workOnItems.length ? (
            <StyledActivitiesList>
              {_.map(workOnItems, ({ id, name }) => (
                <StyledActivity
                  key={`${name}_${id}`}
                >
                  {name}
                </StyledActivity>
              ))}
            </StyledActivitiesList>
          ) : '---'}
          title="Activities being worked on"
          withBackground
        />
      </StyledRowContainer>
    </StyledSubCategoryContainer>
  )

  const renderCategory = ({ categoryName, subcategories }) => (
    <Section
      key={categoryName}
      title={categoryName}
      titleColor={getBrandingColor('primary-color')}
    >
      {_.map(subcategories, renderSubCategory)}
    </Section>
  )

  return (
    <React.Fragment>
      {_.map(montessoriItems, renderCategory)}
    </React.Fragment>
  )
}

const mapState = (state, {
  formativeReportsSelectors,
}) => ({
  montessoriItems: formativeReportsSelectors.getFormativeReportsListMontessoriItems(state),
})

const enhance = compose(
  withFormativeReportsService,
  connect(mapState),
)

export default enhance(Montessori)
