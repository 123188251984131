import { generateBasicActions } from 'utils/service'

import {
  CLEAR_FORMATIVE_REPORT_ITEMS,
  GET_FORMATIVE_REPORT_ITEMS,
  GET_FORMATIVE_REPORT_ITEMS_FAILED,
  GET_FORMATIVE_REPORT_ITEMS_SUCCESS,
} from './constants'

export const getFormativeReportItems = (options = {}) => ({ formativeReportsApiClient }) => (
  generateBasicActions.list({
    constants: {
      failed: GET_FORMATIVE_REPORT_ITEMS_FAILED,
      init: GET_FORMATIVE_REPORT_ITEMS,
      success: GET_FORMATIVE_REPORT_ITEMS_SUCCESS,
    },
    options,
    service: formativeReportsApiClient,
    serviceMethod: 'getFormativeReportItems',
  })
)

export const clearFormativeReportItems = () => ({
  type: CLEAR_FORMATIVE_REPORT_ITEMS,
})
