import { generateBasicActions } from 'utils/service'

import {
  CLEAR_MONTESSORI_CATEGORIES,
  GET_MONTESSORI_CATEGORIES,
  GET_MONTESSORI_CATEGORIES_FAILED,
  GET_MONTESSORI_CATEGORIES_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ montessoriCategoriesApiClient }) => generateBasicActions.list({
  constants: {
    failed: GET_MONTESSORI_CATEGORIES_FAILED,
    init: GET_MONTESSORI_CATEGORIES,
    success: GET_MONTESSORI_CATEGORIES_SUCCESS,
  },
  options,
  service: montessoriCategoriesApiClient,
  serviceMethod: 'list',
})

export const clearList = () => ({
  type: CLEAR_MONTESSORI_CATEGORIES,
})
