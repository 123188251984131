import _ from 'lodash'
import moment from 'moment'

import { ABSENCE_TYPE } from '../../constants'

export const getFormattedSignIns = (signIns) => {
  if (!signIns || 0 === signIns.length) {
    return null
  }

  return _.map(signIns, ({ id, signedInAt, signedOutAt }) => ({
    id,
    signedInAt: signedInAt ? moment(signedInAt).toDate() : null,
    signedOutAt: signedOutAt ? moment(signedOutAt).toDate() : null,
  }))
}

export const getAbsencePayload = (absence, entryDate) => {
  if (!absence) {
    return null
  }

  const { absenceType, comments, endDate, id, reason, startDate } = absence

  return {
    absenceType,
    comments,
    endDate: absenceType === ABSENCE_TYPE.ABSENCE ? null : moment(endDate).format('YYYY-MM-DD'),
    id,
    reason: absenceType === ABSENCE_TYPE.ABSENCE ? reason : undefined,
    startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : moment(entryDate).format('YYYY-MM-DD'),
  }
}
