import React from 'react'

import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

import { ModalBox } from 'components'

import PreviewOptions from './components/PreviewOptions'

export const getOptions = (getFormattedCurrencyValue) => ({
  AMOUNT: {
    fundingValue: getFormattedCurrencyValue(200),
    mergeFundingCell: false,
  },
  HOURS: {
    fundingValue: '45.98 hours',
    mergeFundingCell: false,
  },
  NO_AMOUNT: {
    mergeFundingCell: true,
  },
})

const PreviewLineItemModalContainer = ({
  fundingLineItemOptions,
  fundingNamePreview,
  isDeductedFromInvoice,
  isSubstractFundingSelected,
  onCloseClick,
}) => {
  const renderPreviewOptions = (getFormattedCurrencyValue) => {
    const finalOptions = getOptions(getFormattedCurrencyValue)

    const getPreviewOptions = () => {
      if (!isDeductedFromInvoice) {
        return [finalOptions.NO_AMOUNT]
      }

      if (isSubstractFundingSelected) {
        return [finalOptions.AMOUNT, finalOptions.NO_AMOUNT]
      }

      return [finalOptions.HOURS, finalOptions.NO_AMOUNT]
    }

    return (
      <PreviewOptions
        fundingLineItemOptions={fundingLineItemOptions}
        fundingNamePreview={fundingNamePreview}
        previewOptions={getPreviewOptions()}
      />
    )
  }

  return (
    <ModalBox
      title="Preview funding line items on invoice"
      autoHeight
      onCloseClick={onCloseClick}
    >
      <SubdomainCurrencyProvider>
        {({ getFormattedCurrencyValue }) => renderPreviewOptions(getFormattedCurrencyValue)}
      </SubdomainCurrencyProvider>
    </ModalBox>
  )
}

export default PreviewLineItemModalContainer
