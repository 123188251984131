import _ from 'lodash'
import moment from 'moment'

export const getInitialValues = (params) => {
  const initialValues = {}
  const { amount, child, date, record } = params || {}
  const { comments, recordedAt } = record || {}

  if (child) {
    const { firstName, id, photo, surname } = child

    initialValues.child = {
      avatar: photo,
      label: `${firstName} ${surname}`,
      value: id,
    }
  }

  if (_.isInteger(amount)) {
    initialValues.amount = amount
  } else {
    initialValues.amount = 0
  }

  if (comments) {
    initialValues.comments = comments
  }

  if (recordedAt) {
    initialValues.recordedAt = moment(recordedAt)
  } else if (date) {
    initialValues.recordedAt = moment(date)
      .set('hour', moment().format('HH'))
      .set('minute', moment().format('mm'))
  }

  return initialValues
}

export const getValuesForm = (values) => {
  const body = {
    comments: null,
  }

  const { amount, child, comments, recordedAt } = values

  if (child) {
    body.child = child.value
  }

  if (_.isInteger(amount)) {
    body.amount = amount
  }

  if (recordedAt) {
    body.recordedAt = moment(recordedAt).toISOString()
  }

  if (comments) {
    body.comments = comments
  }

  return body
}
