import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class NurseryFrameworkRelationsApiClient extends ServiceBase {
  list = (params = {}) => this.request({
    headers: params.headers,
    path: `/v2/nursery_framework_relations?${getQueryStringV2(params)}`,
  })

  create = (params = {}, body) => this.request({
    body,
    headers: params.headers,
    method: 'POST',
    path: `/v2/nursery_framework_relations?${getQueryStringV2(params)}`,
  })

  update = (id, params = {}, body) => this.request({
    body,
    headers: params.headers,
    method: 'PUT',
    path: `/v2/nursery_framework_relations/${id}?${getQueryStringV2(params)}`,
  })

  remove = (id, params = {}) => this.request({
    headers: params.headers,
    method: 'DELETE',
    path: `/v2/nursery_framework_relations/${id}`,
  })
}

export default NurseryFrameworkRelationsApiClient
