import _ from 'lodash'

import { DAYS_OF_WEEK_LIST } from 'constants/date'
import { DEFAULT_CURRENCY, DEFAULT_LOCALE } from 'constants/locale'
import { INDEPENDENT_NURSERY_OPTION } from 'services/nurseries/constants'

import i18n from 'translations'

export const getInitialValues = (nursery, daysOptions) => {
  if (!nursery) {
    return null
  }

  const {
    address,
    email,
    logo,
    name,
    nurserySettings,
    ofstedId,
    organization,
    postCode,
    primaryContact,
    telephone,
  } = nursery
  const { id: primaryContactId, name: primaryContactName } = primaryContact || {}
  const { currency, locale, openingDays, startWeekFrom } = nurserySettings || {}

  return {
    address,
    email,
    logo,
    name,
    nurserySettings: {
      ...nurserySettings,
      currency: currency || DEFAULT_CURRENCY,
      locale: locale || DEFAULT_LOCALE,
      openingDays: _.map(DAYS_OF_WEEK_LIST, (day, index) => {
        const dayApiData = _.find(openingDays, { day: `${day}` })

        if (!dayApiData) {
          return {
            day,
            isChecked: false,
            label: i18n.t(`global:DayNames:${index + 1}`),
          }
        }

        const { endTime, startTime } = dayApiData

        return {
          day,
          endTime,
          isChecked: true,
          label: i18n.t(`global:DayNames:${index + 1}`),
          startTime,
        }
      }),
      startWeekFrom: null !== startWeekFrom ? _.find(daysOptions, { value: startWeekFrom }) : null,
    },
    ofstedId,
    organization: organization ? {
      label: organization.name,
      value: organization.value,
    } : INDEPENDENT_NURSERY_OPTION,
    postCode,
    primaryContact: primaryContact
      ? {
        label: primaryContactName,
        value: primaryContactId,
      } : null,
    telephone,
  }
}

export const getPayload = (fields, logo) => {
  if (!fields) {
    return null
  }

  const { nurserySettings, primaryContact } = fields
  const { openWeeks, openingDays, startWeekFrom } = nurserySettings || {}

  // eslint-disable-next-line no-param-reassign
  delete fields.organization

  return {
    ...fields,
    logo: logo || fields.logo,
    nurserySettings: {
      ...nurserySettings,
      currency: nurserySettings?.currency?.value || nurserySettings?.currency || DEFAULT_CURRENCY,
      locale: nurserySettings?.locale?.value || nurserySettings?.locale || DEFAULT_LOCALE,
      openWeeks: +openWeeks,
      openingDays: _.map(_.filter(openingDays, (openDay) => openDay.isChecked), (openDay) => {
        const { day, endTime, startTime } = openDay

        return {
          day,
          endTime,
          startTime,
        }
      }),
      startWeekFrom: startWeekFrom ? startWeekFrom.value : null,
    },
    primaryContact: primaryContact
      ? {
        id: primaryContact.value,
      } : undefined,
  }
}
