import moment from 'moment'
import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { stopSubmit } from 'redux-form'

import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withDailyDiaryToiletTrainingService } from 'services/legacy/dailyDiaryToiletTraining'
import { withModalService } from 'services/utils/modal'

import ToiletTrainingTabView from './ToiletTrainingTabView'
import { TOILET_TRAINING_TAB_FORM } from './components/ToiletTrainingTabForm'

const GROUPS = {
  read: ['toiletTrainingRecord'],
}

class ToiletTrainingTabContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      addNappyChange: false,
    }
  }

  handleSubmitFailed = (response) => {
    const { injectValidation } = this.props
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    return injectValidation(errors)
  }

  handleSubmitSuccess = (selectedChildren) => ({ data }) => {
    const { dailyDiaryToiletTrainingListWithoutRecordsState, onSuccess } = this.props
    const { addNappyChange } = this.state
    const { data: withoutRecords } = dailyDiaryToiletTrainingListWithoutRecordsState

    const records = _.map(data, ({ child, record }) => ({
      data: {
        ...record,
        child: {
          id: child.id,
        },
      },
      record: _.find(withoutRecords, ({ childRegister }) => childRegister.child.id === child.id),
    }))

    onSuccess({ addNappyChange, records, selectedChildren })
  }

  handleSubmit = (values) => {
    const { dailyDiaryToiletTrainingActions, dailyDiaryToiletTrainingSelectors, selectedChildren } = this.props
    const body = dailyDiaryToiletTrainingSelectors.getValuesForm(values)

    const criteria = _.map(selectedChildren, (child, index) => ({
      field: `id[${index}]`,
      value: child.id,
    }))

    dailyDiaryToiletTrainingActions.addRecord({
      body: {
        recordedAt: moment().toISOString(),
        ...body,
      },
      onFailed: this.handleSubmitFailed,
      onSuccess: this.handleSubmitSuccess(selectedChildren),
      params: [{
        criteria,
        groups: GROUPS,
      }],
    })
  }

  handleAddNappyChange = () => this.setState((state) => ({ addNappyChange: !state.addNappyChange }))

  render() {
    const {
      date,
      getInitialValues,
      isSubmitting,
      onCancel,
      selectedChildren,
    } = this.props
    const { addNappyChange } = this.state

    const initialValues = getInitialValues({ date })

    return (
      <ToiletTrainingTabView
        addNappyChange={addNappyChange}
        initialValues={initialValues}
        isSubmitting={isSubmitting}
        selectedChildren={selectedChildren}
        onAddNappyChange={this.handleAddNappyChange}
        onCancel={onCancel}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  injectValidation: (params) => stopSubmit(TOILET_TRAINING_TAB_FORM, params),
}

const mapState = (state, {
  appSelectors,
  dailyDiaryToiletTrainingSelectors,
  dailyDiaryToiletTrainingSingleState,
}) => ({
  getInitialValues: (params) => dailyDiaryToiletTrainingSelectors.getInitialValues(params),
  isSubmitting: appSelectors.getIsSubmitting(dailyDiaryToiletTrainingSingleState),
})

const enhance = compose(
  withAppService,
  withDailyDiaryToiletTrainingService,
  withModalService,
  connect(mapState, mapDispatch),
)

export default enhance(ToiletTrainingTabContainer)
