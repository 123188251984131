import { createSelector } from 'reselect'

import { CriterionV2 } from 'constants/service'
import { RootState } from 'core/reducers'

import { typeByObject } from 'utils/typescript'

import * as constants from '../constants'

const getNurseryDiscounts = (state: RootState) => state.nurseryDiscountsV3

export const getNurseryDiscountsListSelector = createSelector(
  [getNurseryDiscounts],
  (state) => state.list.data,
)

interface GetCriteriaSelectorProps {
  search?: string
  statusFilter?: typeByObject<typeof constants.NURSERY_DISCOUNTS_FILTER>
  type?: string
}

export const getCriteriaSelector = ({ search, statusFilter, type }: GetCriteriaSelectorProps): CriterionV2[] => {
  const criteria = []

  if (statusFilter && statusFilter !== constants.NURSERY_DISCOUNTS_FILTER.ALL) {
    criteria.push({
      field: 'archived',
      value: statusFilter === constants.NURSERY_DISCOUNTS_FILTER.ARCHIVED ? 1 : 0,
    })
  }

  if (search) {
    criteria.push({
      field: 'name',
      value: search,
    })
  }

  if (type) {
    criteria.push({
      field: 'type',
      value: type,
    })
  }

  return criteria
}

