import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withNurseriesService } from 'services/nurseries'
import { withChildSessionsService } from 'services/legacy/childSessions'
import { withChildAdhocSessionsService } from 'services/legacy/childAdhocSessions'
import { withChildExtraItemsService } from 'services/legacy/childExtraItems'
import { withChildFundingService } from 'services/legacy/childFunding'
import { withChildDiscountsService } from 'services/legacy/childDiscounts'

import PlanDetailsModalView from './PlanDetailsModalView'

const START_DATE = moment().startOf('month')
const END_DATE = moment().endOf('month')
const NURSERY_SETTINGS_GROUPS = { read: ['nursery.settings', 'nurserySettings'] }

class PlanDetailsModalContainer extends Component {
  componentDidMount() {
    const { nurseriesActions, nurseryOptions } = this.props

    nurseriesActions.get(nurseryOptions.id, {
      params: { groups: NURSERY_SETTINGS_GROUPS },
    })
  }

  componentWillUnmount() {
    const { nurseriesActions } = this.props

    nurseriesActions.clear()
  }

  render() {
    const {
      child,
      childAdhocSessions,
      childDiscountsList,
      childExtraItems,
      childFundings,
      childSessions,
      errorMessages,
      hideModal,
      isFetching,
    } = this.props

    const childSessionsCurrentPlan = (
      childSessions
      && childSessions.current
      && childSessions.current.length
      && childSessions.current[0].formattedPlans
    )

    return (
      <PlanDetailsModalView
        child={child}
        childAdhocSessions={childAdhocSessions}
        childDiscounts={childDiscountsList}
        childExtraItems={childExtraItems}
        childFundings={childFundings}
        childSessions={childSessionsCurrentPlan}
        endDate={END_DATE}
        errorMessages={errorMessages}
        isLoading={isFetching}
        startDate={START_DATE}
        onCloseClick={hideModal}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childAdhocSessionsSelectors,
  childDiscountsSelectors,
  childExtraItemsSelectors,
  childFundingSelectors,
  childSessionsSelectors,
  nurseriesSingleState,
  params,
}) => ({
  childAdhocSessions: childAdhocSessionsSelectors.getFormattedAdhocSessions(state),
  childDiscountsList: childDiscountsSelectors.getChildDiscountsListSelector(state),
  childExtraItems: childExtraItemsSelectors.getChildExtraItemsListSummaryByNameAndType(state),
  childFundings: childFundingSelectors.getChildFundingListSummary(state),
  childSessions: childSessionsSelectors.getChildSessionsListSelectors(state),
  errorMessages: appSelectors.getErrorMessages(nurseriesSingleState),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const enhance = compose(
  withAppService,
  withModalService,
  withNurseriesService,
  withChildSessionsService,
  withChildAdhocSessionsService,
  withChildExtraItemsService,
  withChildFundingService,
  withChildDiscountsService,
  connect(mapState),
)

export default enhance(PlanDetailsModalContainer)
