import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getFormValues as getFormValuesForm, stopSubmit } from 'redux-form'

import { getFieldValidationFromError } from 'services/app/helpers'

import { Field } from 'components'

import i18n from 'translations'

import { StyledContainer, StyledSubTitleContainer } from './InlineEditableFieldStyled'

class InlineEditableField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isEdit: props.isEdit || false,
      isSubmitting: false,
    }
  }

  handleClose = () => {
    const { onClose } = this.props

    this.setState({ isEdit: false })

    if (onClose) {
      onClose()
    }
  }

  handleEditClick = () => {
    const { enableHandleFailed, formName, getFormValues } = this.props
    const { isEdit } = this.state

    if (isEdit) {
      const formValues = getFormValues(formName)

      this.handleSubmit(formValues)

      if (enableHandleFailed) {
        return
      }
    }

    this.setState((prevState) => ({ isEdit: !prevState.isEdit }))
  }

  handleSuccess = () => {
    this.setState({ isEdit: false, isSubmitting: false })
  }

  handleFailed = (response) => {
    const { enableHandleFailed, formName, injectValidation, onPraseFailedErrors } = this.props

    if (!enableHandleFailed) {
      return
    }

    this.setState({ isSubmitting: false })

    let finalError = response

    if (response?.extra) {
      finalError = getFieldValidationFromError(response)
    }

    setTimeout(() => {
      injectValidation(formName, onPraseFailedErrors ? onPraseFailedErrors(finalError) : finalError)
    })
  }

  handleSubmit = (payload) => {
    const { onSubmit } = this.props

    this.setState({ isSubmitting: true })

    onSubmit(payload)(this.handleSuccess, this.handleFailed)
  }

  render() {
    const {
      description,
      editableComponent,
      formName,
      getFormValues,
      hideActions,
      inline,
      onDeleteClick,
      subtitle,
      title,
      value,
    } = this.props
    const { isEdit, isSubmitting } = this.state

    const formValues = getFormValues(formName)
    const initialDescription = value ? i18n.t('global:Yes') : i18n.t('global:No')

    return (
      <Field.InlineEdit
        actions={editableComponent && !hideActions ? (
          <Field.InlineEdit.Actions
            isEdit={isEdit}
            onCancelClick={this.handleClose}
            onDeleteClick={onDeleteClick}
            onEditClick={this.handleEditClick}
            onSaveClick={this.handleEditClick}
          />
        ) : null}
        description={description || initialDescription}
        editableComponent={editableComponent?.(this.handleSubmit, isSubmitting, formValues)}
        inline={inline}
        isEditMode={isEdit}
        isLoading={isSubmitting}
        label={(title || subtitle) && (
          <StyledContainer>
            {title}
            {subtitle ? (
              <StyledSubTitleContainer $inline={inline}>
                {subtitle}
              </StyledSubTitleContainer>
            ) : null}
          </StyledContainer>
        )}
      />
    )
  }
}

const mapDispatch = {
  injectValidation: (formName, data) => stopSubmit(formName, data),
}

const mapState = (state) => ({
  getFormValues: (formName) => getFormValuesForm(formName)(state),
})

const enhance = compose(
  connect(mapState, mapDispatch),
)

export default enhance(InlineEditableField)

