import styled from 'styled-components'

import layout from 'constants/layout'
import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 340px;
  background: ${NEUTRAL_COLOURS.WHITE};
  height: 100vh;
  display: flex;
  flex-direction: column;
  transform: translateX(0);
  
  @media not all and (hover:hover) {
    height: calc(var(--app-height, 1vh) * 100);
  }
  
  @media(max-width: ${layout.mobileWidthResolution}px) {
    width: 300px;
  }
`

export const StyledToolbarContainer = styled.div`
  padding: 10px;
  padding-bottom: 0;
  margin-bottom: -20px;
`

export const StyledMainContainer = styled.div`
  padding: 0 10px;
  margin-bottom: 65px;
  margin-top: 10px;
  flex: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

export const StyledFooterContainer = styled.div`
  background: #585756;
  padding: 10px;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 340px;
  
  @media(max-width: ${layout.mobileWidthResolution}px) {
    width: 300px;
  }
`
