import React from 'react'

import { Callout, Section, Spinner } from 'components'
import Settings from 'module/Settings/components'

export const FORM_NAME = 'EditInvoiceNumbersForm'

const SettingsInvoiceNumbersEditView = ({
  errorMessages,
  initialValues,
  isFormLoading,
  isLoading,
  onSubmit,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <Section title="Edit invoice number sequencing across organisation">
        <Settings.InvoiceNumbersSettingForm
          cancelButtonLink="/settings/invoice-numbers"
          form={FORM_NAME}
          initialValues={initialValues}
          isLoading={isFormLoading}
          onSubmit={onSubmit}
        />
      </Section>
    )
  }

  return (
    <div>
      <Callout content={errorMessages} error />
      {renderContent()}
    </div>
  )
}

export default SettingsInvoiceNumbersEditView
