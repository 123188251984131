import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_PAYOUT_CURRENCIES_LIST,
  GET_PAYOUT_CURRENCIES_LIST,
  GET_PAYOUT_CURRENCIES_LIST_FAILED,
  GET_PAYOUT_CURRENCIES_LIST_SUCCESS,
} from './constants'

export const getPayoutCurrencies = (
  options: BasicActionOptionsProps,
) => ({ nurseryIntegrationsApiClient }) => generateBasicActions.list({
  constants: {
    failed: GET_PAYOUT_CURRENCIES_LIST_FAILED,
    init: GET_PAYOUT_CURRENCIES_LIST,
    success: GET_PAYOUT_CURRENCIES_LIST_SUCCESS,
  },
  options,
  service: nurseryIntegrationsApiClient,
  serviceMethod: 'getPayoutCurrencies',
})

export const clearPayoutCurrencies = () => ({
  type: CLEAR_PAYOUT_CURRENCIES_LIST,
})
