import _ from 'lodash'

import React from 'react'

import { Table } from 'components'

import i18n from 'translations'

import FundingTableExpandableRow from './FundingTableExpandableRow'

import { StyledContainer } from './FundingTableStyled'

const COLUMNS = [
  {
    title: '',
  },
  {
    title: i18n.t('module:Finance:Funding:Table:Header:child'),
  },
  {
    title: i18n.t('module:Finance:Funding:Table:Header:age'),
  },
  {
    title: i18n.t('module:Finance:Funding:Table:Header:currentFunding'),
  },
  {
    title: '',
  },
]

const FundingTable = ({
  data,
  onAllocateChildFundingClick,
  onArchiveChildFundingClick,
  onAssignChildFundingClick,
  onEditChildFundingClick,
  onSelectChild,
  onShowAllFundingClick,
  selectedChildren,
}) => {
  const renderRow = (item) => (
    <FundingTableExpandableRow
      item={item}
      selectedChildren={selectedChildren}
      onAllocateChildFundingClick={onAllocateChildFundingClick}
      onArchiveChildFundingClick={onArchiveChildFundingClick}
      onAssignChildFundingClick={onAssignChildFundingClick}
      onEditChildFundingClick={onEditChildFundingClick}
      onSelectChild={onSelectChild}
      onShowAllFundingClick={onShowAllFundingClick}
    />
  )

  return (
    <StyledContainer>
      <Table columns={COLUMNS} sticky>
        {_.map(data, renderRow)}
      </Table>
    </StyledContainer>
  )
}

export default FundingTable
