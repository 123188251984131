import React from 'react'

import { Callout, Page, Section } from 'components'

import i18n from 'translations'

import ChildContactsForm from './components/ChildContactsForm'

const ChildContactsAddView = ({
  child,
  childId,
  errorMessages,
  formValues,
  hasAccessToChildInParentApp,
  initialValues,
  isEditContext,
  isEmailDisabled,
  isInvitationResending,
  isSubmitting,
  isUserGrantedEdit,
  onChangeExistingContact,
  onRemoveCarer,
  onResendParentAppInvitation,
  onSubmit,
  selectedContact,
  userTitles,
}) => (
  <Page>
    <Page.Content>
      <Section
        actions={isEditContext && isUserGrantedEdit && (
          <Section.Actions
            options={[{
              onClick: onRemoveCarer,
              type: 'remove',
            }]}
          />
        )}
        title={isEditContext
          ? i18n.t('module:Children:Child:Contacts:Form:editContact')
          : i18n.t('module:Children:Child:Contacts:Form:addContact')}
      >
        <Callout content={errorMessages} error />
        <ChildContactsForm
          child={child}
          childId={childId}
          formValues={formValues}
          hasAccessToChildInParentApp={hasAccessToChildInParentApp}
          initialValues={initialValues}
          isEditContext={isEditContext}
          isEmailDisabled={isEmailDisabled}
          isInvitationResending={isInvitationResending}
          isSubmitting={isSubmitting}
          readOnly={isEditContext && !isUserGrantedEdit}
          selectedContact={selectedContact}
          userTitles={userTitles}
          onChangeExistingContact={onChangeExistingContact}
          onResendParentAppInvitation={onResendParentAppInvitation}
          onSubmit={onSubmit}
        />
      </Section>
    </Page.Content>
  </Page>
)

export default ChildContactsAddView
