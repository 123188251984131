import styled from 'styled-components'

import { Link } from 'react-router'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

interface StyledHyperlinkProps {
  $visibility?: boolean
}

export const StyledHyperlink = styled(Link)<StyledHyperlinkProps>`
  color: ${NEUTRAL_COLOURS.BASIC};
  cursor: pointer;
  transition: .2s;
  visibility: ${({ $visibility = true }) => ($visibility ? 'visible' : 'hidden')};

  &:hover {
    color: ${getBrandingColor('primary-color')};
  }
`
