import React, { PropsWithChildren } from 'react'

import PageTabNavItem from './PageTabNavItem'
import { StyledContainer, StyledItems } from './PageTabNavStyled'

interface PageTabNavRoot extends React.FC<PropsWithChildren<any>> {
  Item?: typeof PageTabNavItem
}

const PageTabNav: PageTabNavRoot = ({ children }) => (
  <StyledContainer>
    <StyledItems>
      {children}
    </StyledItems>
  </StyledContainer>
)

export default PageTabNav
