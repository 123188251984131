import _ from 'lodash'
import moment from 'moment'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { WORK_SHIFT_TYPES } from 'services/legacy/membershipRegisters/constants'

export const isShiftBreak = (type) => _.includes([WORK_SHIFT_TYPES.BREAK, WORK_SHIFT_TYPES.BREAK_DURATION], type)

export const getShiftByDay = ({ day, membershipShiftTimes }) => {
  if (!membershipShiftTimes?.length || !day) {
    return null
  }

  return _.filter(
    membershipShiftTimes,
    ({ day: shiftDay }) => moment(shiftDay).format(DEFAULT_DATE_FORMAT) === moment(day).format(DEFAULT_DATE_FORMAT),
  )
}
