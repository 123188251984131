import styled from 'styled-components'

import layout from 'constants/layout'

import { Button } from 'components'

export const StyledWrapper = styled.div`
  padding: 0 12%;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    padding: 0;
  }
`

export const StyledButton = styled(Button)`
  margin: 50px 0 0;
  width: 100%;
`
