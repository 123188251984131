import _ from 'lodash'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { Avatar, EmptyState, Icon, InfiniteScroll, SearchBar, Spinner, Typography } from 'components'
import Widget from 'module/Dashboard/components/Widget'

import { StyledChildBox, StyledChildrenContainer, StyledEmptyState } from './ChildrenSearchWidgetStyled'

const ChildrenSearchWidgetView = ({
  childList,
  childListMeta,
  isLoading,
  isLoadingSearchedPages,
  onChangeSearch,
  onLoadNextPage,
}) => {
  const { limit, start, total_results: totalResults } = childListMeta

  return (
    <Widget>
      <Widget.Content
        isLoading={isLoading}
        noPadding
      >
        <SearchBar
          placeholder="Search by child name"
          noBackground
          onChange={onChangeSearch}
        />
        <StyledChildrenContainer
          id="scrollableDiv"
        >
          {isLoadingSearchedPages && (
            <Spinner
              light
            />
          )}
          {!isLoadingSearchedPages && (!childList || !childList.length) && (
            <StyledEmptyState>
              <EmptyState
                iconComponent={(
                  <Icon
                    color={NEUTRAL_COLOURS.WHITE}
                    height={60}
                    icon="children"
                  />
                )}
                text1="No children found for specific criteria"
              />
            </StyledEmptyState>
          )}
          {!isLoadingSearchedPages && (
            <InfiniteScroll
              dataLength={childList ? childList.length : 0}
              hasMore={start * limit < totalResults}
              next={onLoadNextPage}
              scrollableTarget="scrollableDiv"
              lightSpinner
            >
              {_.map(childList, (child) => (
                <StyledChildBox key={child.id}>
                  <Avatar
                    avatarSize="medium"
                    initials={[child.firstName, child.surname]}
                    src={child.photo}
                    title={(
                      <Typography color={NEUTRAL_COLOURS.WHITE} fontSize={14}>
                        {child.displayName}
                      </Typography>
                    )}
                    to={generateRoute('CHILDREN.CHILD', { childId: child.id })}
                  />
                </StyledChildBox>
              ))}
            </InfiniteScroll>
          )}
        </StyledChildrenContainer>
      </Widget.Content>
    </Widget>
  )
}

export default ChildrenSearchWidgetView
