import React from 'react'

import { Callout, Page, Section, Spinner } from 'components'

import i18n from 'translations'

import { ChildDetail } from '../components'
import ChildFundingAddForm from './components/ChildFundingAddForm'

const ChildFundingAddFundingView = ({
  allocationData,
  allowOverrideHoursPerWeek,
  allowOverrideMaxHoursPerDay,
  child,
  errorMessages,
  excludedPeriods,
  fundingTypeOptions,
  initialValues,
  isArchived,
  isEdit,
  isFormLoading,
  isInfoBlockVisible,
  isInsideModal,
  isLoading,
  onArchiveClick,
  onCancelClick,
  onPeriodChange,
  onSubmit,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <ChildFundingAddForm
        allocationData={allocationData}
        allowOverrideHoursPerWeek={allowOverrideHoursPerWeek}
        allowOverrideMaxHoursPerDay={allowOverrideMaxHoursPerDay}
        child={child}
        excludedPeriods={excludedPeriods}
        fundingTypeOptions={fundingTypeOptions}
        initialValues={initialValues}
        isArchived={isArchived}
        isEdit={isEdit}
        isInfoBlockVisible={isInfoBlockVisible}
        isLoading={isFormLoading}
        onCancelClick={onCancelClick}
        onPeriodChange={onPeriodChange}
        onSubmit={onSubmit}
      />
    )
  }

  if (isInsideModal) {
    return (
      <React.Fragment>
        <Callout content={errorMessages} error />
        <ChildDetail child={child} isLoading={isLoading} />
        {renderContent()}
      </React.Fragment>
    )
  }

  return (
    <Page.Section
      actions={isEdit && (
        <Section.Actions
          options={[{
            onClick: onArchiveClick(!isArchived),
            type: isArchived ? 'unarchive' : 'archive',
          }]}
        />
      )}
      isLoading={isLoading}
      title={isEdit
        ? i18n.t('module:Children:Child:BookingPattern:Funding:Add:editTitle')
        : i18n.t('module:Children:Child:BookingPattern:Funding:Add:addTitle')}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default ChildFundingAddFundingView
