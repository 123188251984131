import _ from 'lodash'

import React from 'react'
import { compose } from 'recompose'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { Membership } from 'services/legacy/memberships/models'
import { ModalType } from 'modals'

import { withModalService, withModalServiceProps } from 'services/utils/modal'

import { Button, Space, Typography } from 'components'

import i18n from 'translations'

import { StyledContainer, StyledWrapper } from './AccessToLikesAndCommentsStyled'

interface AccessToLikesAndCommentsProps {
  enabled: boolean
  members: Membership[]
  onReload: () => void
}

type AccessToLikesAndCommentsFullProps = AccessToLikesAndCommentsProps
  & withModalServiceProps

const AccessToLikesAndComments: React.FC<AccessToLikesAndCommentsFullProps> = ({
  enabled,
  members,
  modalActions,
  onReload,
}) => {
  const handleEditClick = () => modalActions.show<ModalType.ACCESS_TO_LIKES_AND_COMMENTS>(
    ModalType.ACCESS_TO_LIKES_AND_COMMENTS,
    {
      onSuccess: onReload,
      selectedMembersInitial: _.map(members, ({ id }: Membership) => id),
    },
  )

  return (
    <StyledContainer>
      <Space space="15px" />
      <StyledWrapper>
        <Typography color={enabled ? NEUTRAL_COLOURS.BASIC : NEUTRAL_COLOURS.GRAY_SECONDARY} bold>
          {i18n.t('module:Management:General:LikesAndComments:AccessToLikesAndComments:title')}
        </Typography>
        <Button
          disabled={!enabled}
          hierarchy="tertiary"
          label={i18n.t('global:Edit')}
          size="small"
          negativeMargins
          onClick={handleEditClick}
        />
      </StyledWrapper>
      <Typography color={enabled ? NEUTRAL_COLOURS.BASIC : NEUTRAL_COLOURS.GRAY_SECONDARY} margin="10px 0 0">
        {!members?.length ? (
          <Typography color={NEUTRAL_COLOURS.GRAY} italic>
            -
          </Typography>
        ) : null}
        {_.map(members, (member) => member.name).join(', ')}
      </Typography>
      <Space space="25px" />
    </StyledContainer>
  )
}

const enhance = compose(
  withModalService,
)

export default enhance(AccessToLikesAndComments)
