import React from 'react'

import { Callout, EmptyState, Page, Pagination, Section, Spinner, Table } from 'components'

import i18n from 'translations'

const COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'contractPeriod',
    title: i18n.t('module:Staff:StaffContracts:Labels:contractPeriod'),
    width: '30%',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'type',
    title: i18n.t('module:Staff:StaffContracts:Labels:type'),
    width: '20%',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'jobTitle',
    title: i18n.t('module:Staff:StaffContracts:Labels:jobTitle'),
    width: '20%',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'contractedHours',
    title: i18n.t('module:Staff:StaffContracts:Labels:ContractedHours:short'),
    width: '10%',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'hourlySalary',
    title: i18n.t('module:Staff:StaffContracts:Labels:hourlySalary'),
    width: '10%',
  },
  {
    field: 'action',
    width: '10%',
  },
]

const StaffContractsListView = ({
  addContractLink,
  data,
  errorMessages,
  isFetching,
  isGrantedEdit,
  onPageChange,
  page,
  pageCount,
  perPage,
  totalResults,
}) => {
  const renderPagination = () => (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('module:Staff:StaffContracts:List:Pagination:plural')}
      titleSingular={i18n.t('module:Staff:StaffContracts:List:Pagination:singular')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    if (!data?.length) {
      return (
        <EmptyState
          icon="staff"
          text1={i18n.t('module:Staff:StaffContracts:List:EmptyState:text1')}
          text2={i18n.t('module:Staff:StaffContracts:List:EmptyState:text2')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={COLUMNS}
          data={data}
          visualType="gray_rounded"
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const actions = isGrantedEdit && (
    <Section.Actions
      primary={[{
        label: i18n.t('module:Staff:StaffContracts:List:addContract'),
        to: addContractLink,
      }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isFetching}
      title={i18n.t('module:Staff:StaffContracts:List:title')}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default StaffContractsListView
