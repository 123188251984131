import React from 'react'

import { BasicViewProps } from 'constants/models'
import { Child } from 'services/child/models'
import { Carer } from 'services/legacy/carers/models'

import { generateRoute } from 'utils/routing'

import { Button, Callout, EmptyState, Spinner } from 'components'

import i18n from 'translations'

import ChildFinanceAddContactForm, {
  ChildFinanceAddContactFormProps,
  ChildFinanceAddContactFormValues,
} from './components/ChildFinanceAddContactForm'

interface ChildFinanceAddContactViewProps extends BasicViewProps {
  carerList: Carer[]
  child: Child
  initialValues: ChildFinanceAddContactFormValues
}

type ChildFinanceAddContactFullProps = ChildFinanceAddContactViewProps
  & ChildFinanceAddContactFormProps

const ChildFinanceAddContactView: React.FC<ChildFinanceAddContactFullProps> = ({
  carerList,
  child,
  errorMessages,
  initialValues,
  isCustomEmail,
  isFinanceV3Enabled,
  isLoading,
  isSubmitting,
  onDeleteItemClick,
  onSubmit,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!carerList || !carerList.length) {
      return (
        <EmptyState
          actions={(
            <Button
              label={i18n.t('module:Children:Child:Contacts:Form:addContact')}
              to={generateRoute('CHILDREN.CHILD.CONTACTS.ADD', { childId: child?.id })}
            />
            )}
          icon="profile"
          text1={i18n.t('module:Children:Child:Finance:ContactDetails:EmptyState:missingContactText', {
            childFirstName: child?.firstName,
          })}
        />
      )
    }

    return (
      <ChildFinanceAddContactForm
        carerList={carerList}
        childId={child?.id}
        errorMessages={errorMessages}
        initialValues={initialValues}
        isCustomEmail={isCustomEmail}
        isFinanceV3Enabled={isFinanceV3Enabled}
        isLoading={isSubmitting}
        onDeleteItemClick={onDeleteItemClick}
        onSubmit={onSubmit}
      />
    )
  }

  return (
    <React.Fragment>
      <Callout content={errorMessages} error />
      {renderContent()}
    </React.Fragment>
  )
}

export default ChildFinanceAddContactView
