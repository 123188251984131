import _ from 'lodash'

import React from 'react'

import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'
import { RECURRING_PAYMENT_METHOD_LABEL, SETUP_TYPE_LABEL } from 'services/nurseryIntegrations/constants'

import { generateRoute } from 'utils/routing'

import { getNurseryPublicUrl } from 'services/nurseries/single/selectors/single'

import { Avatar, CircleIcon, Currency, DateString, Typography } from 'components'

import i18n from 'translations'

import { StyledStatusContainer } from './ActiveRecurringPaymentStyled'

export const getTableData = ({ data, hasError, isOrganizationContext }) => {
  if (!data?.length || hasError) {
    return null
  }

  return _.map(data, ({
    accountHolder,
    activated,
    child,
    estimatedCompletionDate,
    nextRunDate,
    paymentMethod,
    setupType,
    ...rest
  }) => {
    const { balance, codeReference, firstName, id: childId, nursery, photo, surname } = child || {}
    const { name: nurseryName } = nursery || {}
    let avatarLink = generateRoute('CHILDREN.CHILD.FINANCE.INVOICING', { childId })

    if (isOrganizationContext) {
      avatarLink = `${getNurseryPublicUrl(nursery)}${avatarLink}`
    }

    return {
      ...rest,
      accountHolder: accountHolder || '-',
      balance: <Currency value={balance} />,
      child: (
        <Avatar
          avatarSize="small"
          initials={[firstName, surname]}
          src={photo}
          target={isOrganizationContext ? '_blank' : '_self'}
          title={(
            <Typography fontSize={14} primary>
              {`${firstName} ${surname}`}
            </Typography>
            )}
          to={avatarLink}
        />
      ),
      codeReference,
      estimatedCompletionDate: estimatedCompletionDate ? <DateString date={estimatedCompletionDate} /> : '-',
      nextRunDate: nextRunDate ? <DateString date={nextRunDate} /> : '-',
      nursery: nurseryName,
      paymentMethod: RECURRING_PAYMENT_METHOD_LABEL[paymentMethod] || '-',
      setupType: SETUP_TYPE_LABEL[setupType] || '-',
      status: (
        <StyledStatusContainer>
          <CircleIcon
            background={activated ? FLAG_COLOURS.SUCCESS : NEUTRAL_COLOURS.GRAY_SECONDARY}
            size={12}
          />
          {activated
            ? i18n.t('services:NurseryIntegrations:Status:active')
            : i18n.t('services:NurseryIntegrations:Status:notActive')}
        </StyledStatusContainer>
      ),
    }
  })
}
