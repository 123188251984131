import _ from 'lodash'

import React from 'react'

import { Spinner } from 'components'
import InlineEditableField from 'module/Management/components/InlineEditableField'

import i18n from 'translations'

import RoundingRulesForm, { ROUNDING_RULES_FORM } from './RoundingRulesForm'

const ROUNDING_RULES_OPTIONS = _.times(10, (i) => ({ label: `.0${i}`, value: i }))

const RoundingRules = ({ isFetching, onSubmit, value }) => {
  const initialValues = {
    roundingRules: _.find(ROUNDING_RULES_OPTIONS, { value }),
  }

  const renderRoundingRules = () => i18n.t(
    'module:Management:General:RotaAndOccupancy:RoundingRules:text',
    { value: initialValues.roundingRules?.label },
  )

  return (
    <InlineEditableField
      description={isFetching ? (
        <Spinner
          position="flex-start"
        />
      ) : renderRoundingRules()}
      editableComponent={(callback, isSubmitting) => (
        <RoundingRulesForm
          initialValues={initialValues}
          isSubmitting={isSubmitting}
          options={ROUNDING_RULES_OPTIONS}
          onSubmit={callback}
        />
      )}
      formName={ROUNDING_RULES_FORM}
      title={i18n.t('module:Management:General:RotaAndOccupancy:RoundingRules:title')}
      value={value}
      enableHandleFailed
      onSubmit={onSubmit}
    />
  )
}

export default RoundingRules
