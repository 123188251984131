import _ from 'lodash'

import { createSelector } from 'reselect'

import { getErrorMessagesCustomCodes } from 'services/app/common/selectors'

import { ERROR_CODES, STATUS_TYPES } from '../constants'

export const getRequestedExtraSessionSingleSelector = (state) => state.requestedExtraSessions.single

export const getRequestedExtraSessionSingleDataSelector = createSelector(
  [getRequestedExtraSessionSingleSelector],
  (state) => state.data,
)

export const getErrorStatistics = createSelector(
  [getRequestedExtraSessionSingleSelector],
  (state) => {
    const error = _.find(
      state.error?.extra,
      (i) => _.find(
        i,
        (item) => item.payload?.code === ERROR_CODES.AVAILABILITY_STAFF_REQUIREMENTS_CHANGED,
      ),
    )

    if (!error || _.isEmpty(error)) {
      return null
    }

    return error[0]?.payload?.statistics
  },
)

export const getUpdateRequestPayload = ({ fields, isApproveAction, statistics }) => {
  if (!fields) {
    return null
  }

  const { reason } = fields

  return {
    reason,
    statisticsSeenByUser: statistics,
    status: isApproveAction ? STATUS_TYPES.APPROVED : STATUS_TYPES.DECLINED,
  }
}

const compareCurrentDataAndSnapshot = ({
  currentData,
  excludedFields,
  snapshot,
}) => !_.isEmpty(
  _.filter(
    _.reduce(
      currentData,
      (result, value, key) => (_.isEqual(value, snapshot[key]) ? result : result.concat(key)),
      [],
    ),
    (field) => !_.includes(excludedFields, field),
  ),
)

const didErrorCodeOccur = ({ errorCode, errorCodes }) => _.includes(
  _.flatten(errorCodes),
  errorCode,
)

export const isSessionDetailsChanged = createSelector(
  [getRequestedExtraSessionSingleDataSelector],
  (data) => {
    const {
      nurserySession,
      nurserySessionProduct,
      nurserySessionProductSnapshot,
      nurserySessionSnapshot,
    } = data || {}

    const finalNurserySession = nurserySession || nurserySessionProduct || {}
    const finalNurserySessionSnapshot = nurserySessionSnapshot || nurserySessionProductSnapshot || {}

    return compareCurrentDataAndSnapshot({
      currentData: finalNurserySession,
      excludedFields: [
        'archivedAt',
        'createdAt',
        'isHourly',
        'requestable',
        'updatedAt',
      ],
      snapshot: finalNurserySessionSnapshot,
    })
  },
)

export const isAvailabilityStaffRequirementsChanged = createSelector(
  [getRequestedExtraSessionSingleSelector],
  (state) => {
    const errorMessagesCustomCodes = getErrorMessagesCustomCodes(state)

    return didErrorCodeOccur({
      errorCode: ERROR_CODES.AVAILABILITY_STAFF_REQUIREMENTS_CHANGED,
      errorCodes: errorMessagesCustomCodes,
    })
  },
)
