import styled from 'styled-components'

import { Link } from 'react-router'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { CursorTypes } from 'constants/css'

import { getBrandingColor } from 'utils/branding'

import { StyledLayoutContainer } from 'components/LayoutContainer/LayoutContainerStyled'

import blurBaby from 'assets/images/blur-baby.jpg'

export const StyledProfileHeader = styled.header`
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 4px 0 rgba(207, 207, 207, .2);

  #profileHeaderBottom {
    margin-top: -1px;
    height: 1px;
  }
`

export const StyledTop = styled.div`
  flex: 1;
  background: url(${blurBaby}) #EEE no-repeat center center;
  min-height: 110px;
  background-size: cover;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  
  & > div,
  & > div > div,
  & > div > div > div {
    height: 100%;
  }
  
  @media(max-width: 760px) {
    & > div,
    & > div > div,
    & > div > div > div {
      height: auto;
    }
  }
  
  & > div > div > div {
    @media(max-width: 760px) {
      display: flex;
      justify-content: center;
    }
  }
  
  ${StyledLayoutContainer} {
    padding: 20px;
  }
`

export const StyledBottom = styled.div`
  height: 58px;
  min-height: 58px;
  background: ${NEUTRAL_COLOURS.WHITE};
  border-bottom: 1px solid #E3E3E3;
  
  & > div,
  & > div > div,
  & > div > div > div {
    height: 100%;
  }
  
  & > div > div > div {
    padding-top: 0;
    padding-bottom: 0;
  }
`

export const StyledAvatar = styled.div`
  position: relative;
  
  & > div {
    position: absolute;
    
    @media(max-width: 760px) {
      position: relative;
      width: 80px !important;
      height: 80px !important;
      min-width: 80px !important;
      transform: translateY(-5px);
      
      div {
        width: 80px !important;
        height: 80px !important;
        min-width: 80px !important;
        min-height: 80px !important;
        transform: translateX(50%);
      }
    }
    
    @media(max-width: 520px) {
      div {
        transform: translateX(0%);
      }
    }
  }

  @media(max-width: 920px) {
    display: none;
  }
  
  @media(max-width: 760px) {
    display: block;
  }
`

export const StyledChildDetails = styled.div`
  display: flex;
  align-items: center;
  margin-left: 160px;
  height: 100%;
  width: 100%;
  
  @media(max-width: 920px) {
    margin-left: 0;
  }
  
  @media(max-width: 760px) {
    flex-direction: column;
  }
`

export const StyledLeftDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media(max-width: 760px) {
    align-items: center;
  }
`

export const StyledMiddleDetails = styled.div`
  flex: 1;
`

export const StyledRightDetails = styled.div`
  display: flex;
  align-items: center;
  
  @media(max-width: 760px) {
    margin-top: 20px;
    transform: translateX(-40px);
  }
  
  @media(max-width: 480px) {
    flex-direction: column;
  }
  
  @media(max-width: 480px) {
    margin-top: 10px;
  }
`

export const StyledLeftDetailsTitle = styled.div`
  margin: 0;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 500px;
  
  @media(max-width: 920px) {
    max-width: 320px;
  }
  
  @media(max-width: 760px) {
    padding: 0 20px;
    max-width: calc(100vw - 120px);
  }
`

export const StyledLeftDetailsSubtitle = styled.div`
  display: flex;
  align-items: center;
  
  @media(max-width: 480px) {
    flex-direction: column;
  }
`

export const StyledSubtitle = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
`

export const StyledLastEdited = styled.p`
  margin: 0;
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid #8E8E8D;
  color: #8E8E8D;
  height: 16px;
  line-height: 15px;
  
  @media(max-width: 480px) {
    margin-left: 0;
    padding-left: 0;
    border-left: none;
  }
`

export const StyledWhiteBox = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: rgba(255, 255, 255, .6);
  margin-left: 15px;
  
  @media(max-width: 480px) {
    margin-left: 0;
    margin-top: 10px;
  }
`

interface StyledWhiteBoxChildrenProps {
  $cursor?: CursorTypes
}

export const StyledWhiteBoxChildren = styled.div<StyledWhiteBoxChildrenProps>`
  ${({ $cursor }) => $cursor && `
    cursor: ${$cursor};
  `}
`

export const StyledWhiteBoxLeft = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin-right: -15px;
`

export const StyledWhiteBoxRight = styled.div`
  flex: 1;
  padding: 5px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const StyledWhiteBoxTitle = styled.p`
  font-weight: 600;
  margin: 0 0 2px;
`

export const StyledWhiteBoxValue = styled.p`
  margin: 0;
`

export const StyledMenu = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-left: 160px;
  height: 100%;
  align-items: center;
  width: 100%;
  
  @media(max-width: 920px) {
    padding-left: 0;
  }
  
  @media(max-width: 770px) {
    display: none;
  }
`

interface StyledLinkProps {
  $isNew: boolean
  $noLeftBorder: boolean
}

export const StyledLink = styled(Link)<StyledLinkProps>`
  font-size: 18px;
  color: ${NEUTRAL_COLOURS.BASIC};
  width: 100%;
  height: calc(100% - 8px);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #ECECEC;
  margin: 4px 0;
  padding: 0 30px;
  white-space: nowrap;
  transition: .2s;
  cursor: pointer;
  
  ${({ $isNew }) => $isNew && `
    padding: 0 10px 0 20px;
  `}

  @media(max-width: 1020px) {
    padding: 0 15px;

    ${({ $isNew }) => $isNew && `
      padding: 0 5px 0 10px;
    `}
  }
  
  ${({ $noLeftBorder }) => $noLeftBorder && `
    border-left: 0;
  `}
  
  &:hover, &.active {
    color: ${getBrandingColor('primary-color')};
  }
  
  @media(max-width: 760px) {
    font-size: 14px;
  }
`

export const StyledMenuItem = styled.li`
  flex: 1;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 200px;
  
  &:nth-last-of-type(1) {
    ${StyledLink} {
      border-right: 1px solid #ECECEC;
    }
  }

  @media(max-width: 1024px) {
    max-width: 150px;
  }

  @media(max-width: 770px) {
    max-width: 170px;
  }
`

export const StyledDropdownMenu = styled.div`
  height: 100%;
  
  & > div,
  & > div > div {
    height: 100%;
  }
`

export const StyledMobileMenu = styled.div`
  display: none;
  
  @media(max-width: 520px) {
    display: flex;
    width: 100%;
    height: 100%;
    
    & > div,
    & > div > div {
      width: 100%;
    }
  }
`

export const StyledTabletMenu = styled.ul`
  display: none;
  
  @media(max-width: 770px) {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    height: 100%;
    align-items: center;
    width: 100%;
  }

  @media(max-width: 520px) {
    display: none;
  }
`

export const StyledMenuContainer = styled.div`
  height: 100%;
  width: 100%;
  
  @media(max-width: 1024px) {
    padding: 0 10px;
  }
`

export const StyledNewBadge = styled.div`
  margin-right: 15px;
  border-radius: 20px;
  
  @media(max-width: 1020px) {
    margin-right: 10px;
  }
`
