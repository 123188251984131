import { browserHistory } from 'react-router'
import { Dispatch, Middleware } from 'redux'

import * as subdomainSingleActionTypes from 'services/subdomain/single/constants'

import { generateRoute } from 'utils/routing'

const ErrorHandlingMiddleware = () => (next: Dispatch) => (action): Middleware => {
  const result = next(action)

  if (action.type === subdomainSingleActionTypes.GET_SUBDOMAIN_FAILED) {
    browserHistory.push(generateRoute('VARIOUS.NOT_FOUND'))
  }

  return result
}

export default ErrorHandlingMiddleware
