import React from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'
import { InjectedFormProps, formValueSelector, reduxForm } from 'redux-form'

import { generateRoute } from 'utils/routing'

import { Callout, Form } from 'components'

import i18n from 'translations'

import AddDiscountFormFields from './AddDiscountFormFields'

export const ADD_DISCOUNT_FORM = 'AddDiscountForm'

export interface AddDiscountFormValues {
  allowOverride?: boolean
  applicableBeforeFunding?: string
  consumables?: boolean
  extraItems?: boolean
  extraSessions?: boolean
  name?: string
  regularItems?: boolean
  sessions?: boolean
  type?: string
  value?: number
}

interface AddDiscountFormProps {
  formValues?: AddDiscountFormValues
  isEdit: boolean
  isLoading: boolean
  onSubmit: (values: AddDiscountFormValues) => void
  type: string
}

const mapState = (state) => ({
  type: formValueSelector(ADD_DISCOUNT_FORM)(state, 'type'),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type AddDiscountFormFullProps = AddDiscountFormProps & InjectedFormProps<{}, AddDiscountFormProps>

const AddDiscountForm: React.FC<AddDiscountFormFullProps & PropsFromRedux> = ({
  error,
  formValues,
  handleSubmit,
  isEdit,
  isLoading,
  onSubmit,
  type,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Callout content={error} error />
    <AddDiscountFormFields formValues={formValues} isEdit={isEdit} type={type} />
    <Form.FooterActions
      cancelLink={generateRoute('MANAGEMENT.FINANCE_SETTINGS.DISCOUNTS')}
      isSubmitting={isLoading}
      submitLabel={i18n.t('global:Save')}
    />
  </Form>
)

const enhance = compose(
  reduxForm({ form: ADD_DISCOUNT_FORM }),
  connector,
)

export default enhance(AddDiscountForm)
