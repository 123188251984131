import _ from 'lodash'
import moment from 'moment'

import React, { useMemo } from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { GBP_CURRENCY } from 'constants/locale'
import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'
import { CAPABILITIES_TYPES, STRIPE_STATUSES, STRIPE_STATUSES_OPTIONS } from 'services/nurseryIntegrations/constants'

import { getCalendarDayOptions } from 'utils/date'

import {
  Banner,
  Box,
  Button,
  Form,
  Hyperlink,
  Page,
  Section,
  Select,
  Space,
  Spinner,
  Switch,
  Tooltip,
  Typography,
} from 'components'

import i18n from 'translations'

import stripeLogo from 'assets/images/stripe.svg'
import amexLogo from 'assets/images/amex.svg'
import recurringCard from 'assets/images/recurring-card.png'

import {
  StyledButtonContainer,
  StyledCard,
  StyledCardLogo,
  StyledCardLogosWrapper,
  StyledInsideBox,
  StyledPageWrapper,
  StyledReceiveText,
  StyledStatus,
  StyledStripeWrapper,
  StyledSwitchBox,
  StyledSwitchWrapper,
  StyledTitleHeader,
  StyledWhiteBox,
} from './ManagementPaymentGatewayStyled'

const HELP_CENTER_URL = 'https://support.stripe.com/'

const CARD_LOGOS = [
  {
    image: '/images/cards/visa.png',
  },
  {
    image: '/images/cards/mastercard.png',
  },
  {
    image: '/images/cards/union-pay.png',
  },
  {
    image: '/images/cards/jcb.png',
  },
  {
    image: '/images/cards/diners-club.png',
  },
  {
    image: '/images/cards/discover.png',
  },
  {
    image: '/images/cards/visa-electron.png',
  },
  {
    image: '/images/cards/cirrus.png',
  },
  {
    image: '/images/cards/apple-pay.png',
  },
  {
    image: '/images/cards/google-pay.png',
  },
]

export const CHARGE_TYPES_FOR = {
  BACS: 'bacsChargeDay',
  CARD: 'cardChargeDay',
}

const ManagementPaymentGatewayView = ({
  bacsChargeDay,
  capabilitiesEnabled,
  cardChargeDay,
  initialized,
  isBacsCapabilityEnabled,
  isFetching,
  isFetchingCapability,
  isProcessing,
  isProcessingChargeTypeFor,
  isRemovingProcessing,
  onChangeCapability,
  onChangeChargeType,
  onConnectWithStripe,
  onDisconnectStripe,
  stripeNurseryIntegration,
  subdomainCurrency,
}) => {
  const { accountLink, dashboardLink, status } = stripeNurseryIntegration || {}
  const stripeStatus = _.find(STRIPE_STATUSES_OPTIONS, ({ value }) => status === value)
  const calendarDaysOptions = useMemo(getCalendarDayOptions, [])

  const renderRunDates = (capability) => {
    const chargeType = CAPABILITIES_TYPES.BACS === capability
      ? CHARGE_TYPES_FOR.BACS
      : CHARGE_TYPES_FOR.CARD
    const chargeTypeValue = stripeNurseryIntegration[chargeType]

    if (!capabilitiesEnabled[capability]) {
      return null
    }

    const dates = _.times(3, (i) => {
      const date = moment().startOf('month').add(i, 'M')
      const helperDate = moment(date).set({ date: chargeTypeValue })

      while (moment(date).format('MM') !== moment(helperDate).format('MM')) {
        helperDate.add(-1, 'day')
      }

      return helperDate
    })

    return (
      <React.Fragment>
        <Typography fontSize={14} margin="20px 0 8px">
          {CAPABILITIES_TYPES.BACS === capability && (
            i18n.t('module:Management:Finance:PaymentGateway:nextDirectDebit')
          )}
          {CAPABILITIES_TYPES.CARD === capability && (
            i18n.t('module:Management:Finance:PaymentGateway:nextRecurringPayments')
          )}
        </Typography>
        {_.map(dates, (date) => (
          <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} key={date} margin="3px 0">
            {moment(date).format(DISPLAY_SHORT_DATE_FORMAT)}
          </Typography>
        ))}
      </React.Fragment>
    )
  }

  const renderDirectDebit = () => {
    const changeIsBlocked = (
      !capabilitiesEnabled[CAPABILITIES_TYPES.BACS] && !capabilitiesEnabled[CAPABILITIES_TYPES.CARD]
    )

    if (subdomainCurrency !== GBP_CURRENCY) {
      return null
    }

    return (
      <Box withPadding>
        <StyledSwitchWrapper>
          <Typography variant="h6">
            {i18n.t('module:Management:Finance:PaymentGateway:directDebit')}
          </Typography>
          <StyledSwitchBox>
            <Typography align="right">
              {capabilitiesEnabled[CAPABILITIES_TYPES.BACS]
                ? _.upperFirst(i18n.t('global:enabled'))
                : _.upperFirst(i18n.t('global:disabled'))}
            </Typography>
            <Tooltip
              title={changeIsBlocked
                ? i18n.t('module:Management:Finance:PaymentGateway:cardPaymentMustBeEnabledAsFirst')
                : ''}
            >
              <Switch
                disabled={isFetchingCapability || isRemovingProcessing || changeIsBlocked}
                value={capabilitiesEnabled[CAPABILITIES_TYPES.BACS]}
                simple
                onChange={onChangeCapability(CAPABILITIES_TYPES.BACS)}
              />
            </Tooltip>
          </StyledSwitchBox>
        </StyledSwitchWrapper>
        <StyledReceiveText>
          <Typography color={NEUTRAL_COLOURS.GRAY}>
            {i18n.t('module:Management:Finance:PaymentGateway:receiveAutomaticPayments')}
          </Typography>
        </StyledReceiveText>
        {capabilitiesEnabled[CAPABILITIES_TYPES.BACS] && (
          <React.Fragment>
            <Space space="25px" />
            <StyledWhiteBox>
              <Space margin="-10px 0 0" />
              <Form.Row
                label={i18n.t('module:Management:Finance:PaymentGateway:startMonthlyDirectDebitRunOn')}
                verticalLabel
              >
                <Form.Row.FlexItem>
                  <Select
                    clearable={false}
                    disabled={isProcessingChargeTypeFor[CHARGE_TYPES_FOR.BACS] || isRemovingProcessing}
                    options={calendarDaysOptions}
                    placeholder={i18n.t('module:Management:Finance:PaymentGateway:startMonthlyDirectDebitRunOn')}
                    value={bacsChargeDay}
                    onChange={(value) => onChangeChargeType(value, CHARGE_TYPES_FOR.BACS)}
                  />
                </Form.Row.FlexItem>
              </Form.Row>
              <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={12} margin="-10px 0 10px">
                {i18n.t('module:Management:Finance:PaymentGateway:currentlyProcessingBACS')}
              </Typography>
              {renderRunDates(CAPABILITIES_TYPES.BACS)}
            </StyledWhiteBox>
          </React.Fragment>
        )}
      </Box>
    )
  }

  const renderCardLogos = () => (
    <StyledCardLogosWrapper>
      {_.map(CARD_LOGOS, (card) => (
        <StyledCard $src={card.image} key={card.image} />
      ))}
    </StyledCardLogosWrapper>
  )

  const renderCreditCard = () => {
    const changeIsBlocked = (
      capabilitiesEnabled[CAPABILITIES_TYPES.BACS] && capabilitiesEnabled[CAPABILITIES_TYPES.CARD]
    )

    return (
      <Box withPadding>
        <StyledSwitchWrapper>
          <Typography variant="h6">
            {i18n.t('module:Management:Finance:PaymentGateway:creditCard')}
          </Typography>
          <StyledSwitchBox>
            <Typography align="right">
              {capabilitiesEnabled[CAPABILITIES_TYPES.CARD]
                ? _.upperFirst(i18n.t('global:enabled'))
                : _.upperFirst(i18n.t('global:disabled'))}
            </Typography>
            <Tooltip
              title={changeIsBlocked
                ? i18n.t('module:Management:Finance:PaymentGateway:bacsMustBeDisabledAsFirst')
                : ''}
            >
              <Switch
                disabled={isFetchingCapability || isRemovingProcessing || changeIsBlocked}
                value={capabilitiesEnabled[CAPABILITIES_TYPES.CARD]}
                simple
                onChange={onChangeCapability(CAPABILITIES_TYPES.CARD)}
              />
            </Tooltip>
          </StyledSwitchBox>
        </StyledSwitchWrapper>
        <StyledReceiveText>
          <Typography color={NEUTRAL_COLOURS.GRAY}>
            {i18n.t('module:Management:Finance:PaymentGateway:receiveOneOffAndAutomaticPayments')}
          </Typography>
        </StyledReceiveText>
        {capabilitiesEnabled[CAPABILITIES_TYPES.CARD] && (
          <React.Fragment>
            {renderCardLogos()}
            <Space margin="25px" />
            <StyledWhiteBox>
              <StyledInsideBox>
                <StyledCardLogo $src={amexLogo} />
                <Typography fontSize={16} bold>
                  {i18n.t('module:Management:Finance:PaymentGateway:americanExpress')}
                </Typography>
                <Typography align="right">
                  {capabilitiesEnabled[CAPABILITIES_TYPES.AMERICAN_EXPRESS]
                    ? _.upperFirst(i18n.t('global:enabled'))
                    : _.upperFirst(i18n.t('global:disabled'))}
                </Typography>
                <Switch
                  disabled={isFetchingCapability || isRemovingProcessing}
                  value={capabilitiesEnabled[CAPABILITIES_TYPES.AMERICAN_EXPRESS]}
                  simple
                  onChange={onChangeCapability(CAPABILITIES_TYPES.AMERICAN_EXPRESS)}
                />
              </StyledInsideBox>
            </StyledWhiteBox>
            <Space space="15px" />
            <StyledWhiteBox>
              <StyledInsideBox>
                <StyledCardLogo $src={recurringCard} />
                <Typography fontSize={16} bold>
                  {i18n.t('module:Management:Finance:PaymentGateway:recurringPayments')}
                </Typography>
              </StyledInsideBox>
              <Space space="25px" />
              <Form.Row
                label={i18n.t('module:Management:Finance:PaymentGateway:monthlyPaymentDate')}
                verticalLabel
              >
                <Form.Row.FlexItem>
                  <Select
                    clearable={false}
                    disabled={isProcessingChargeTypeFor[CHARGE_TYPES_FOR.CARD] || isRemovingProcessing}
                    options={calendarDaysOptions}
                    placeholder={i18n.t('module:Management:Finance:PaymentGateway:monthlyPaymentDate')}
                    value={cardChargeDay}
                    onChange={(value) => onChangeChargeType(value, CHARGE_TYPES_FOR.CARD)}
                  />
                </Form.Row.FlexItem>
              </Form.Row>
              {renderRunDates(CAPABILITIES_TYPES.CARD)}
            </StyledWhiteBox>
          </React.Fragment>
        )}
      </Box>
    )
  }

  const renderConnectedToStripeBox = () => (
    <Box withPadding>
      <StyledStripeWrapper>
        <div>
          <img alt="Stripe" src={stripeLogo} />
        </div>
        <div>
          <StyledTitleHeader>
            <Typography variant="h5">
              {i18n.t('module:Management:Finance:PaymentGateway:connectedToStripe')}
            </Typography>
            <div>
              {stripeStatus && (
                <StyledStatus background={stripeStatus.background} color={stripeStatus.color}>
                  {stripeStatus.label}
                </StyledStatus>
              )}
            </div>
          </StyledTitleHeader>
        </div>
      </StyledStripeWrapper>
      <Space space="15px" />
      {(
        STRIPE_STATUSES.NOT_ONBOARDED === status
        || STRIPE_STATUSES.RESTRICTED === status
        || STRIPE_STATUSES.RESTRICTED_SOON === status
        || STRIPE_STATUSES.REJECTED === status
      ) && (
        <Banner.Error margin="0 0 15px">
          {i18n.t('module:Management:Finance:PaymentGateway:missingInformationBanner')}
          <br />
          <br />
          {i18n.t('module:Management:Finance:PaymentGateway:missingInformationBannerSecond')}
        </Banner.Error>
      )}
      <StyledButtonContainer>
        <Button
          disabled={isRemovingProcessing || (!dashboardLink && !accountLink)}
          label={
            accountLink
              ? i18n.t('module:Management:Finance:PaymentGateway:continueOnboarding')
              : i18n.t('module:Management:Finance:PaymentGateway:viewStripeAccount')
          }
          target="_blank"
          to={dashboardLink || accountLink}
        />
        <Button
          hierarchy="tertiary"
          isLoading={isRemovingProcessing}
          label={i18n.t('module:Management:Finance:PaymentGateway:disconnectFromStripe')}
          onClick={onDisconnectStripe}
        />
      </StyledButtonContainer>
    </Box>
  )

  const renderConnectedView = () => (
    <React.Fragment>
      <StyledPageWrapper>
        {renderConnectedToStripeBox()}
      </StyledPageWrapper>
      {STRIPE_STATUSES.NOT_ONBOARDED !== status && (
        <Section title={i18n.t('module:Management:Finance:PaymentGateway:paymentSettings')}>
          <StyledPageWrapper>
            {isBacsCapabilityEnabled && renderDirectDebit()}
            {renderCreditCard()}
          </StyledPageWrapper>
        </Section>
      )}
    </React.Fragment>
  )

  const renderDisconnectedView = () => (
    <React.Fragment>
      <Typography margin="0 0 5px" bold>
        {i18n.t('module:Management:Finance:PaymentGateway:getPaidFaster')}
      </Typography>
      <Typography>
        {i18n.t('module:Management:Finance:PaymentGateway:connectWithStripeDesc')}
      </Typography>
      <Button
        isLoading={isProcessing}
        label={i18n.t('module:Management:Finance:PaymentGateway:connectWithStripe')}
        margin="10px 0 5px"
        onClick={onConnectWithStripe}
      />
      <Typography margin="8px 0 0">
        {i18n.t('module:Management:Finance:PaymentGateway:learnMore', {
          components: [(
            <Hyperlink target="_blank" to={HELP_CENTER_URL} variant="span" primary />
          )],
        })}
      </Typography>
    </React.Fragment>
  )

  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    if (initialized) {
      return renderConnectedView()
    }

    return renderDisconnectedView()
  }

  return (
    <Page.Section
      isLoading={isFetching}
      title={i18n.t('module:Management:Finance:PaymentGateway:title')}
    >
      {renderContent()}
    </Page.Section>
  )
}

export default ManagementPaymentGatewayView
