import {
  CLEAR_LIST_WORK_LOG_BY_DAY,
  GET_LIST_WORK_LOG_BY_DAY,
  GET_LIST_WORK_LOG_BY_DAY_FAILED,
  GET_LIST_WORK_LOG_BY_DAY_SUCCESS,
} from './constants'

export const listByDay = (options = {}) => ({ membershipRegistersApiClient }) => (dispatch) => {
  const { mergeResult, onFailed, onSuccess, params = {} } = options

  dispatch({ type: GET_LIST_WORK_LOG_BY_DAY })

  membershipRegistersApiClient.list(params).then(
    (response) => {
      dispatch({
        mergeResult,
        payload: response,
        type: GET_LIST_WORK_LOG_BY_DAY_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: GET_LIST_WORK_LOG_BY_DAY_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const clearListByDay = () => ({
  type: CLEAR_LIST_WORK_LOG_BY_DAY,
})
