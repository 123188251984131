import i18n from 'translations'

import ObservationsWidget from './ObservationsWidgetContainer'

export const WIDGET_NAME = {
  component: ObservationsWidget,
  id: 'OBSERVATIONS_WIDGET',
  label: i18n.t('module:Dashboard:Widgets:ObservationsWidget:titleInSettings'),
}

export default ObservationsWidget
