import _ from 'lodash'

import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'
import { DEFAULT_CURRENCY, DEFAULT_LOCALE } from 'constants/locale'

export const getOrganizationData = (state: RootState) => state.organization.single.data

export const getOrganizationFlags = (state: RootState) => state.organization.single.flags

export const getOrganizationSettings = createSelector(
  [getOrganizationData],
  (org) => {
    if (!org || !org.organizationSettings) {
      return null
    }

    return org.organizationSettings
  },
)

export const getInitialValues = createSelector(
  [getOrganizationData, getOrganizationFlags],
  (data, flags) => {
    const featureFlags = {}

    _.forEach(flags, ({ enabled, flag }) => { featureFlags[flag] = enabled })

    const newData = {
      ...data,
      organizationSettings: {
        ...(data?.organizationSettings || {}),
      },
    }

    if (!newData?.organizationSettings?.locale) {
      newData.organizationSettings.locale = DEFAULT_LOCALE
    }

    if (!newData?.organizationSettings?.currency) {
      newData.organizationSettings.currency = DEFAULT_CURRENCY
    }

    return { ...newData, featureFlags }
  },
)

interface FormDataResponseProps {
  logo?: string
  name?: string
  organizationSettings?: {
    [key: string]: any
  }
  subdomain?: string
  subdomainSuffix?: string
}

export const getFormData = ({
  logo,
  name,
  organizationSettings,
  subdomain,
}) => {
  const body: FormDataResponseProps = {}

  if (logo) {
    body.logo = logo
  }

  if (name) {
    body.name = name
  }

  if (subdomain) {
    body.subdomain = subdomain
    body.subdomainSuffix = `-${subdomain}`
  }

  if (organizationSettings) {
    if (organizationSettings.locale) {
      if (!body.organizationSettings) {
        body.organizationSettings = {}
      }

      body.organizationSettings.locale = organizationSettings.locale?.value
    }

    if (organizationSettings.currency) {
      if (!body.organizationSettings) {
        body.organizationSettings = {}
      }

      body.organizationSettings.currency = organizationSettings.currency?.value
    }
  }

  return body
}

export const getOrganizationIsArchived = createSelector(
  [getOrganizationData],
  (data) => data?.archived,
)
