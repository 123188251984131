import { generateBasicActions } from 'utils/service'

import { CLEAR_SINGLE_FRAMEWORK, GET_FRAMEWORK, GET_FRAMEWORK_FAILED, GET_FRAMEWORK_SUCCESS } from './constants'

export const get = (options = {}) => ({ frameworksApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_FRAMEWORK_FAILED,
    init: GET_FRAMEWORK,
    success: GET_FRAMEWORK_SUCCESS,
  },
  options,
  service: frameworksApiClient,
  serviceMethod: 'get',
})

export const clearSingleFramework = () => ({
  type: CLEAR_SINGLE_FRAMEWORK,
})
