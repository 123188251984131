export const ADD_TOILET_TRAINING = 'ADD_TOILET_TRAINING'
export const ADD_TOILET_TRAINING_SUCCESS = 'ADD_TOILET_TRAINING_SUCCESS'
export const ADD_TOILET_TRAINING_FAILED = 'ADD_TOILET_TRAINING_FAILED'

export const REMOVE_TOILET_TRAINING = 'REMOVE_TOILET_TRAINING'
export const REMOVE_TOILET_TRAINING_SUCCESS = 'REMOVE_TOILET_TRAINING_SUCCESS'
export const REMOVE_TOILET_TRAINING_FAILED = 'REMOVE_TOILET_TRAINING_FAILED'

export const UPDATE_TOILET_TRAINING = 'UPDATE_TOILET_TRAINING'
export const UPDATE_TOILET_TRAINING_SUCCESS = 'UPDATE_TOILET_TRAINING_SUCCESS'
export const UPDATE_TOILET_TRAINING_FAILED = 'UPDATE_TOILET_TRAINING_FAILED'
