import { generateRoute } from 'utils/routing'

export const getObservationEventLogContext = (pathname) => {
  if (
    generateRoute('APPROVALS.OBSERVATIONS') === pathname
    || pathname.startsWith(`${generateRoute('APPROVALS.OBSERVATIONS')}/`)
  ) {
    return 'approvals list'
  }

  if (
    generateRoute('LEARNING.OBSERVATIONS') === pathname
  || pathname.startsWith(`${generateRoute('LEARNING.OBSERVATIONS')}/`)
  ) {
    return 'list'
  }

  return 'child profile'
}
