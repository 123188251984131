import moment from 'moment'
import _ from 'lodash'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { SHIFT_MAIN_TYPES } from 'services/legacy/membershipRegisters/constants'
import { BREAKDOWN } from 'module/Modals/Staff/Leave/ExportModal/constants'

const getMembershipsLeavesListSelector = (state) => state.membershipsLeaves.list

export const getMembershipsLeavesListDataSelector = createSelector(
  [getMembershipsLeavesListSelector],
  (state) => state.data || [],
)

export const getSingleUserLeavesSelector = createSelector(
  [getMembershipsLeavesListDataSelector],
  (state) => state?.[0]?.shifts,
)

export const getConcatenatedLeaveTimesGroupedByDay = (response) => {
  const member = response?.[0]
  const { shifts } = member || {}

  let shiftTimes = []

  _.each(shifts, ({ leaveShiftType, membershipShiftTimes, type }) => {
    if (SHIFT_MAIN_TYPES.LEAVE === type) {
      shiftTimes = [
        ...shiftTimes,
        ..._.map(membershipShiftTimes, (item) => ({
          ...item,
          leaveShiftType,
          type,
        })),
      ]
    }
  })

  shiftTimes = _.map(shiftTimes, (item) => ({
    ...item,
    day: moment(item.day).format(DEFAULT_DATE_FORMAT),
  }))

  shiftTimes = _.groupBy(shiftTimes, 'day')

  return shiftTimes
}

export const getConcatenatedLeaveTimesGroupedByDaySelector = createSelector(
  [getMembershipsLeavesListDataSelector],
  getConcatenatedLeaveTimesGroupedByDay,
)

export const getLeaveExportCriteria = ({ breakdown, reportDatesRange, sendTo, staffMembers }) => {
  const criteria = [
    {
      field: 'archived',
      value: 0,
    },
    {
      field: 'shifts.type',
      value: SHIFT_MAIN_TYPES.LEAVE,
    },
  ]

  if (BREAKDOWN.TOTAL !== breakdown) {
    criteria.push({
      field: 'shifts[exists]',
      value: 1,
    })
  }

  if (sendTo) {
    criteria.push({
      field: 'membership',
      value: sendTo?.value,
    })
  }

  if (reportDatesRange?.length) {
    const [startDate, endDate] = reportDatesRange

    criteria.push(
      {
        field: 'shifts.dateTo[after]',
        value: startDate.format(DEFAULT_DATE_FORMAT),
      },
      {
        field: 'shifts.dateFrom[before]',
        value: endDate.format(DEFAULT_DATE_FORMAT),
      },
      {
        field: 'shifts.membershipShiftTimes.day[after]',
        value: startDate.format(DEFAULT_DATE_FORMAT),
      },
      {
        field: 'shifts.membershipShiftTimes.day[before]',
        value: endDate.format(DEFAULT_DATE_FORMAT),
      },
    )
  }

  if (staffMembers?.value) {
    criteria.push({
      field: 'id',
      value: staffMembers?.value,
    })
  }

  return criteria
}

export const getSicknessExportCriteria = ({ reportDatesRange, sendTo, staffMembers }) => {
  const criteria = []

  if (sendTo) {
    criteria.push({
      field: 'membership',
      value: sendTo?.value,
    })
  }

  if (reportDatesRange?.length) {
    const [startDate, endDate] = reportDatesRange

    criteria.push(
      {
        field: 'shifts.dateTo[after]',
        value: startDate.format(DEFAULT_DATE_FORMAT),
      },
      {
        field: 'shifts.dateFrom[before]',
        value: endDate.format(DEFAULT_DATE_FORMAT),
      },
      {
        field: 'shifts.membershipShiftTimes.day[after]',
        value: startDate.format(DEFAULT_DATE_FORMAT),
      },
      {
        field: 'shifts.membershipShiftTimes.day[before]',
        value: endDate.format(DEFAULT_DATE_FORMAT),
      },
      {
        field: 'currentRegisters.date[after]',
        value: startDate.format(DEFAULT_DATE_FORMAT),
      },
      {
        field: 'currentRegisters.date[before]',
        value: endDate.format(DEFAULT_DATE_FORMAT),
      },
    )
  }

  if (staffMembers?.value) {
    criteria.push({
      field: 'id',
      value: staffMembers?.value,
    })
  }

  return criteria
}
