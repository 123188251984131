import _ from 'lodash'
import moment from 'moment'

import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { ROLES } from 'constants/security'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withInvoicesService } from 'services/legacy/invoices'
import { withFinanceBadDebtReportService } from 'services/legacy/financeBadDebtReport'
import { withPaginationUtils } from 'services/utils/pagination'
import { withSecurityService } from 'services/security'
import { withRouter } from 'services/router'

import { useBasicReport } from 'hooks/reports'

import {
  Currency,
  DatePicker,
  Form,
  InfiniteDropdowns,
  ReportView,
  Select,
  StickyFooter,
  Toolbar,
  Typography,
} from 'components'

import i18n from 'translations'

import { getColumns, getStatistics, getTableData } from './BadDebtHelper'
import { DATE_TYPE_OPTIONS } from './constants'

const CHILDREN_BALANCE_GROUPS = {
  read: [
    'child',
    'invoice',
    'invoice.child',
    'invoice.badDebt',
  ],
}

const NURSERIES_BALANCE_GROUPS = {
  read: [
    'nursery',
  ],
}

const BadDebtContainer = ({
  childrenData,
  childrenTotalResults,
  errorMessages,
  financeBadDebtReportActions,
  financeBadDebtReportSelectors,
  invoicesActions,
  invoicesSelectors,
  isFetching,
  isOrganizationContext,
  isStatisticsFetching,
  location,
  modalActions,
  modalConsts,
  nurseriesData,
  nurseriesTotalResults,
  paginationUtils,
  setLocationQuery,
  statisticsData,
}) => {
  const {
    child,
    dateRange,
    dateType,
    onChildChange,
    onDateChange,
    onDateTypeChange,
    onPageChange,
    onRoomChange,
    page,
    pageCount,
    perPage,
    room,
    statistics,
    tableColumns,
    tableData,
    totalResults,
  } = useBasicReport({
    actions: financeBadDebtReportActions,
    callStatisticsAction: true,
    childrenApiGroups: CHILDREN_BALANCE_GROUPS,
    childrenData,
    childrenTotalResults,
    dateTypeOptions: DATE_TYPE_OPTIONS,
    defaultFilters: {
      date: {
        after: moment().startOf('month'),
        before: moment().endOf('month'),
      },
    },
    errorMessages,
    getColumns,
    getStatistics,
    getTableData,
    isOrganizationContext,
    location,
    nurseriesApiGroups: NURSERIES_BALANCE_GROUPS,
    nurseriesData,
    nurseriesTotalResults,
    paginationUtils,
    selectors: financeBadDebtReportSelectors,
    setLocationQuery,
    statisticsData,
  })

  const handleExportModalClick = (body, onSuccessCb) => {
    const { criteria } = body

    const userCriteria = _.find(criteria, { field: 'membership' })
    const filterCriteria = invoicesSelectors.getBadDebtCriteriaSelector({
      badDebtDate: {
        after: dateRange[0],
        before: dateRange[1],
      },
      child: child?.value,
      dateType: dateType?.value,
      room: room?.value,
    })

    const apiParams = {
      criteria: [
        ...[userCriteria],
        ...filterCriteria,
      ],
    }

    invoicesActions.exportInvoice('bad-debt', {
      onSuccess: onSuccessCb,
      params: apiParams,
    })
  }

  const handleExportClick = () => {
    modalActions.show(modalConsts.TYPES.EXPORT_REPORT, {
      onSendReport: handleExportModalClick,
      title: i18n.t('module:Finance:Reports:BadDebt:Export:title'),
      userLabel: i18n.t('module:Finance:Reports:BadDebt:Export:description'),
    })
  }

  const renderFilters = () => (
    <Toolbar bottomLine>
      <Toolbar.Group>
        <Toolbar.Item>
          <Form.Row
            label={i18n.t('module:Finance:Reports:BadDebt:Filters:DateType:label')}
            verticalLabel
          >
            <Select
              clearable={false}
              options={DATE_TYPE_OPTIONS}
              placeholder={i18n.t('module:Finance:Reports:BadDebt:Filters:DateType:placeholder')}
              value={dateType}
              onChange={onDateTypeChange}
            />
          </Form.Row>
        </Toolbar.Item>
        <Toolbar.Item>
          <Form.Row
            label={i18n.t('module:Finance:Reports:BadDebt:Filters:Date:label')}
            verticalLabel
          >
            <DatePicker
              clearable={false}
              value={dateRange}
              range
              onChange={onDateChange}
            />
          </Form.Row>
        </Toolbar.Item>
        {!isOrganizationContext && (
          <Toolbar.Item>
            <Form.Row
              label={i18n.t('module:Finance:Reports:BadDebt:Filters:Rooms:label')}
              verticalLabel
            >
              <InfiniteDropdowns.Rooms
                placeholder={i18n.t('global:AllRooms')}
                value={room}
                onChange={onRoomChange}
              />
            </Form.Row>
          </Toolbar.Item>
        )}
        {!isOrganizationContext && (
          <Toolbar.Item>
            <Form.Row
              label={i18n.t('module:Finance:Reports:Funding:Filters:Child:label')}
              verticalLabel
            >
              <InfiniteDropdowns.Children
                value={child}
                onChange={onChildChange}
              />
            </Form.Row>
          </Toolbar.Item>
        )}
      </Toolbar.Group>
    </Toolbar>
  )

  const renderFooter = () => (
    <StickyFooter>
      <StickyFooter.Flex />
      <Typography fontSize={20} margin="10px 20px" variant="span" bold>
        {`${i18n.t('module:Finance:Reports:BadDebt:footerLabel')}: `}
        <Currency value={statisticsData?.amount} />
      </Typography>
    </StickyFooter>
  )

  return (
    <ReportView.DetailPage
      description={isOrganizationContext
        ? i18n.t('module:Finance:Reports:BadDebt:Organization:description')
        : i18n.t('module:Finance:Reports:BadDebt:description')}
      errorMessages={errorMessages}
      filters={renderFilters()}
      footer={!isOrganizationContext && renderFooter()}
      hideExport={isOrganizationContext}
      isOrganizationContext={isOrganizationContext}
      isPageLoading={isFetching && isStatisticsFetching && 1 === page}
      isStatisticsLoading={isStatisticsFetching}
      isTableLoading={isFetching}
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      statistics={statistics}
      statisticsGap="60px"
      tableColumns={tableColumns}
      tableData={tableData}
      title={i18n.t('module:Finance:Reports:BadDebt:title')}
      totalResults={totalResults}
      onExportClick={handleExportClick}
      onPageChange={onPageChange}
    />
  )
}

BadDebtContainer.authParams = {
  antiRoles: [
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
  ],
}

const mapState = (state, {
  appSelectors,
  financeBadDebtReportChildrenState,
  financeBadDebtReportNurseriesState,
  financeBadDebtReportSelectors,
  financeBadDebtReportStatisticsState,
  securitySelectors,
}) => ({
  childrenData: financeBadDebtReportSelectors.getFinanceBadDebtReportChildrenData(state),
  childrenTotalResults: appSelectors.getTotalResults(financeBadDebtReportChildrenState),
  errorMessages: appSelectors.getErrorMessages(
    financeBadDebtReportChildrenState,
    financeBadDebtReportNurseriesState,
    financeBadDebtReportStatisticsState,
  ),
  isFetching: appSelectors.getIsFetching(
    financeBadDebtReportChildrenState,
    financeBadDebtReportNurseriesState,
  ),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
  isStatisticsFetching: appSelectors.getIsFetching(financeBadDebtReportStatisticsState),
  nurseriesData: financeBadDebtReportSelectors.getFinanceBadDebtReportNurseriesData(state),
  nurseriesTotalResults: appSelectors.getTotalResults(financeBadDebtReportNurseriesState),
  statisticsData: financeBadDebtReportSelectors.getFinanceBadDebtReportStatisticsData(state),
})

const enhance = compose(
  withRouter,
  withAppService,
  withFinanceBadDebtReportService,
  withInvoicesService,
  withModalService,
  withPaginationUtils,
  withSecurityService,
  connect(mapState),
)

export default enhance(BadDebtContainer)
