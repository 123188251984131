import {
  CLEAR_INVOICES_STATISTICS,
  INVOICES_STATISTICS,
  INVOICES_STATISTICS_FAILED,
  INVOICES_STATISTICS_SUCCESS,
} from './constants'

export const getStatistics = (params, onSuccess) => ({ invoicesApiClient }) => (dispatch) => {
  dispatch({
    params,
    type: INVOICES_STATISTICS,
  })

  invoicesApiClient.statistics(params).then(
    (data) => {
      dispatch({
        payload: data,
        type: INVOICES_STATISTICS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: INVOICES_STATISTICS_FAILED,
      })
    },
  )
}

export const clearStatistics = () => ({
  type: CLEAR_INVOICES_STATISTICS,
})
