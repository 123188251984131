import React from 'react'

import { Callout, ModalBox } from 'components'

import i18n from 'translations'
import StatusTypeMigrationModalForm from './components/StatusTypeMigrationModalForm'

const EnrolChildModalView = ({
  enquiryStatusType,
  errorMessages,
  isPreviewMode,
  isSubmitting,
  newStage,
  onCancelClick,
  onCloseClick,
  onMigrateClick,
}) => (
  <ModalBox
    maxWidth={600}
    title={isPreviewMode
      ? i18n.t('module:Modals:Enquiry:StatusTypeMigration:confirmationTitle')
      : i18n.t('module:Modals:Enquiry:StatusTypeMigration:title')}
    autoHeight
    onCloseClick={onCloseClick}
  >
    <Callout content={errorMessages} error />
    <StatusTypeMigrationModalForm
      enquiryStatusType={enquiryStatusType}
      isPreviewMode={isPreviewMode}
      isSubmitting={isSubmitting}
      newStage={newStage}
      onCancelClick={onCancelClick}
      onSubmit={onMigrateClick}
    />
  </ModalBox>
)

export default EnrolChildModalView
