import i18n from 'translations'

import ChildrenSearchWidget from './ChildrenSearchWidgetContainer'

export const WIDGET_NAME = {
  component: ChildrenSearchWidget,
  id: 'CHILDREN_SEARCH_WIDGET',
  label: i18n.t('module:Dashboard:Widgets:ChildrenSearchWidget:titleInSettings'),
}

export default ChildrenSearchWidget
