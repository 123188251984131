import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { REFERENCE_ASYNC_PAGE_TYPES } from 'services/legacy/upload/constants'
import { SUPPORTED_FILE_TYPES } from 'constants/mimetypes'

import { EmptyState, Form } from 'components'

import i18n from 'translations'

import { properties } from 'app-config'

export const CHILD_FILE_UPLOADS_FORM = 'ChildFilesForm'

const ChildFilesForm = ({
  childId,
  handleSubmit,
  onSortChange,
  onSubmit,
  ...rest
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row.FlexItem mobileFull>
      <Field
        accept={[SUPPORTED_FILE_TYPES.IMAGES, SUPPORTED_FILE_TYPES.PDF, SUPPORTED_FILE_TYPES.DOCUMENTS]}
        component={Form.MediaPicker}
        emptyState={(
          <EmptyState
            icon="document"
            text1={i18n.t('services:Files:notFound')}
          />
        )}
        filesLimit={properties.upload.limits.child}
        name="media"
        referencePage={[REFERENCE_ASYNC_PAGE_TYPES.CHILD, +childId]}
        disableMediaTag
        disableTagging
        sectionMode
        uploadDateColumn
        onSortChange={onSortChange}
        {...rest}
      />
    </Form.Row.FlexItem>
  </Form>
)

export default reduxForm({ form: CHILD_FILE_UPLOADS_FORM })(ChildFilesForm)
