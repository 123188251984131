import styled from 'styled-components'

export const StyledContentContainer = styled.div`
  padding: 10px;
`

export const StyledContent = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  padding: 40px 30px 25px;
  justify-items: center;
  
  @media(max-width: 600px) {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    text-align: center;
    
    & > div:nth-of-type(odd) {
      margin-top: 25px;
    }
  }
`

export const StyledIconContainer = styled.div`
  grid-column: ${({ columnIndex }) => columnIndex};
  grid-row: 1;
`

export const StyledTextContainer = styled.div`
  grid-column: ${({ columnIndex }) => columnIndex};
  grid-row: 2;
`
