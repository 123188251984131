import * as dropdownListActions from './dropdownList/actions'
import * as dropdownListPublicEnquirySource from './dropdownListPublicEnquirySource/actions'
import * as listActions from './list/actions'
import * as singleActions from './single/actions'

export default {
  ...dropdownListActions,
  ...dropdownListPublicEnquirySource,
  ...listActions,
  ...singleActions,
}
