import _ from 'lodash'

import { createSelector } from 'reselect'

import i18n from 'translations'

import { SELECT_ALL_LABEL_OPTION, TERM_STARTING_POINT } from '../constants'

const getFormativeReports = (state) => state.formativeReports

const getFormativeReportsPeriodsLabels = createSelector(
  [getFormativeReports],
  (state) => state.periods,
)

const getPeriodsLabels = createSelector(
  [getFormativeReportsPeriodsLabels],
  (periodsLabels) => periodsLabels.data && _.filter(periodsLabels.data, (item) => item && item.label),
)

const convertLabelToString = (label) => {
  let value = null

  if (TERM_STARTING_POINT === label) {
    value = i18n.t('module:Learning:CohortTracking:global:startingPoint')
  } else {
    const chunks = label.split('_')

    if (1 >= chunks.length) {
      return label
    }

    const season = `${chunks[1].charAt(0).toUpperCase()}${chunks[1].substr(1).toLowerCase()}`

    value = `${season} ${chunks[0]}`

    // NOTE: if half-term enabled than the pattern is {year}_{season}_{2}?
    if (2 < chunks.length) {
      value = `${value} ${chunks[2]}`
    }
  }

  return value
}

export const getPeriodsLabelsOptions = createSelector(
  [getPeriodsLabels],
  (labels) => {
    if (!labels || !labels.length) {
      return null
    }

    return [
      SELECT_ALL_LABEL_OPTION,
      ..._.map(labels, (item) => item.label && {
        label: convertLabelToString(item.label),
        value: item.label,
      }),
    ]
  },
)

export const getPeriodsCriteria = (filters) => {
  const { childId } = filters
  const criteria = []

  if (childId) {
    criteria.push({
      field: 'child',
      value: childId,
    })
  }

  return criteria
}
