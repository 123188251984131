import { mergeData } from 'utils/data'

import {
  CLEAR_DAILY_DIARIES,
  LIST_DAILY_DIARIES,
  LIST_DAILY_DIARIES_FAILED,
  LIST_DAILY_DIARIES_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: null,
}

export default (state = initialState, { error, mergeResult, payload, type }) => {
  switch (type) {
    case LIST_DAILY_DIARIES:
      return {
        ...state,
        error: initialState.error,
        isFetching: true,
      }
    case LIST_DAILY_DIARIES_SUCCESS:
      return {
        ...state,
        data: mergeData(state.data, payload.data, mergeResult),
        isFetching: false,
        meta: payload.meta,
      }
    case LIST_DAILY_DIARIES_FAILED:
      return {
        ...state,
        error,
        isFetching: false,
      }
    case CLEAR_DAILY_DIARIES:
      return initialState
    default:
      return state
  }
}
