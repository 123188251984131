import { combineReducers } from 'redux'

import common, { ModalCommonState } from './common/reducer'

export default combineReducers({
  common,
})

export interface ModalState {
  common: ModalCommonState
}
