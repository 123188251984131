import _ from 'lodash'

import React from 'react'

import { InfiniteScroll, Page, SearchBar, Spinner } from 'components'

import i18n from 'translations'

import NurseryList from './components/NurseryList'

const OrganizationDashboardView = ({
  authAccessMap,
  isLoading,
  nurseries,
  onEditClick,
  onPageChange,
  onSearchChange,
  page,
  pageCount,
}) => {
  const renderContent = () => {
    if (isLoading && 1 === page) {
      return <Spinner />
    }

    return (
      <InfiniteScroll
        dataLength={nurseries ? nurseries.length : 0}
        hasMore={page < pageCount}
        isFetching={isLoading}
        next={() => onPageChange(+page + 1)}
      >
        <NurseryList>
          {_.map(nurseries, (nursery) => (
            <NurseryList.Item
              authAccessMap={authAccessMap}
              nursery={nursery}
              onEditClick={onEditClick}
            />
          ))}
        </NurseryList>
      </InfiniteScroll>
    )
  }

  return (
    <Page isLoading={isLoading && 1 === page}>
      <SearchBar placeholder={i18n.t('module:Dashboard:Organization:searchBarPlaceholder')} onChange={onSearchChange} />
      <Page.Content>{renderContent()}</Page.Content>
    </Page>
  )
}

export default OrganizationDashboardView
