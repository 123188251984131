import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as childrenLeavingReportSelectors from './selectors'

const withChildrenLeavingReportService = (WrappedComponent) => {
  const mapState = ({ childrenLeavingReport }) => ({ childrenLeavingReport })

  const mapDispatch = injectActions('childrenLeavingReportActions', actions)

  const Component = ({ childrenLeavingReport, childrenLeavingReportActions, ...others }) => (
    <WrappedComponent
      childrenLeavingReportActions={childrenLeavingReportActions}
      childrenLeavingReportChildrenState={childrenLeavingReport.children}
      childrenLeavingReportNurseriesState={childrenLeavingReport.nurseries}
      childrenLeavingReportSelectors={childrenLeavingReportSelectors}
      childrenLeavingReportStatisticsState={childrenLeavingReport.statistics}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withChildrenLeavingReportService
