import { generateBasicActions } from 'utils/service'

import {
  CLEAR_FINANCE_REPORTS_BALANCE_STATISTICS,
  FINANCE_REPORTS_BALANCE_STATISTICS,
  FINANCE_REPORTS_BALANCE_STATISTICS_FAILED,
  FINANCE_REPORTS_BALANCE_STATISTICS_SUCCESS,
} from './constants'

export const getStatistics = (options = {}) => ({ financeBalanceReportApiClient }) => generateBasicActions.list({
  constants: {
    failed: FINANCE_REPORTS_BALANCE_STATISTICS_FAILED,
    init: FINANCE_REPORTS_BALANCE_STATISTICS,
    success: FINANCE_REPORTS_BALANCE_STATISTICS_SUCCESS,
  },
  options,
  service: financeBalanceReportApiClient,
  serviceMethod: 'getStatistics',
})

export const clearStatistics = () => ({
  type: CLEAR_FINANCE_REPORTS_BALANCE_STATISTICS,
})
