import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class EnquiryStatusTypesApiClient extends ServiceBase {
  list = (params = {}) => this.request({
    path: `/v2/enquiry_status_types?${getQueryStringV2(params)}`,
  })

  get = (id, params = {}) => this.request({
    path: `/v2/enquiry_status_types/${id}?${getQueryStringV2(params)}`,
  })

  create = (params = {}, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/enquiry_status_types?${getQueryStringV2(params)}`,
  })

  update = (id, params = {}, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/enquiry_status_types/${id}?${getQueryStringV2(params)}`,
  })
}

export default EnquiryStatusTypesApiClient
