import { LIST_CHILD_DISCOUNTS, LIST_CHILD_DISCOUNTS_FAILED, LIST_CHILD_DISCOUNTS_SUCCESS } from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: {},
}

export default (state = initialState, { error, mergeResult, payload, type }) => {
  switch (type) {
    case LIST_CHILD_DISCOUNTS:
      return { ...state, error: initialState.error, isFetching: true }
    case LIST_CHILD_DISCOUNTS_SUCCESS:
      return {
        ...state,
        data: !mergeResult || !state.data ? payload.data : [...state.data, ...payload.data],
        isFetching: false,
        meta: payload.meta,
      }
    case LIST_CHILD_DISCOUNTS_FAILED:
      return { ...state, error, isFetching: false }
    default:
      return state
  }
}
