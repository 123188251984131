import { createSelector } from 'reselect'

import { LIST_STATUS_FILTERS } from 'constants/filters'

export const getAbsenceReasonsSelector = (state) => state.absenceReasons

export const getAbsenceReasonsListDataSelector = createSelector(
  [getAbsenceReasonsSelector],
  (state) => state.list.data,
)

export const getListCriteria = (filters) => {
  const { status } = filters || {}

  const criteria = []

  if (status && status !== LIST_STATUS_FILTERS.ALL) {
    criteria.push({
      field: 'archived',
      value: Number(status === LIST_STATUS_FILTERS.ARCHIVED),
    })
  }

  return criteria
}

