import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withMembershipsService } from 'services/legacy/memberships'
import { withMembershipRegistersService } from 'services/legacy/membershipRegisters'
import { withModalService } from 'services/utils/modal'
import { getWorklogDayType } from 'services/legacy/membershipRegisters/list/selectors'

import ChangeLogLegacyModalView from './ChangeLogModalView'

const GROUPS = {
  read: ['membership'],
}

const MEMBERSHIP_GROUPS = {
  read: ['membership.details'],
}

const MEMBERSHIP_REGISTER_GROUPS = {
  read: [
    'membershipRegisterEntry.nurseryClass',
    'nurseryClass',
  ],
}

class ChangeLogModalContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isFetchingMembership: true,
      membershipDetails: null,
    }
  }

  componentDidMount() {
    const {
      membership,
      membershipRegistersActions,
      membershipRegistersSelectors,
      membershipsActions,
      record,
      selectedDate,
      startDate,
    } = this.props

    const criteria = membershipRegistersSelectors.getMembershipRegistersListCriteria({
      endDate: selectedDate,
      id: membership,
      startDate,
    })

    membershipRegistersActions.listByDay({
      params: {
        criteria,
        groups: MEMBERSHIP_REGISTER_GROUPS,
      },
    })

    membershipsActions.get(membership, {
      onSuccess: ({ data }) => {
        this.setState({
          isFetchingMembership: false,
          membershipDetails: data,
        })
      },
      onlyData: true,
      params: { groups: MEMBERSHIP_GROUPS },
    })

    if (record?.id) {
      membershipRegistersActions.getChangelog(record?.id, {
        params: { groups: GROUPS },
      })
    }
  }

  componentWillUnmount() {
    const { membershipRegistersActions } = this.props

    membershipRegistersActions.clearChangelog()
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  render() {
    const {
      changelogData,
      getPreviousRecord,
      isFetching,
      selectedDate,
    } = this.props
    const { isFetchingMembership, membershipDetails } = this.state
    const previousRecord = getPreviousRecord(selectedDate)

    return (
      <ChangeLogLegacyModalView
        changelogData={changelogData}
        isFetching={isFetching || isFetchingMembership}
        memberDetails={membershipDetails}
        previousRecord={previousRecord}
        selectedDate={selectedDate}
        worklogDayType={getWorklogDayType(previousRecord, selectedDate)}
        onCloseClick={this.handleCloseClick}
      />
    )
  }
}

const mapState = (state, { appSelectors, membershipRegistersChangelogState, membershipRegistersSelectors }) => ({
  changelogData: membershipRegistersSelectors.getChangelogDataSelector(state),
  getPreviousRecord: (endDate) => membershipRegistersSelectors.getListWorklogByPreviousDayOngoing(endDate)(state),
  isFetching: appSelectors.getIsFetching(
    membershipRegistersChangelogState,
  ),
})

const enhance = compose(
  withAppService,
  withMembershipsService,
  withMembershipRegistersService,
  withModalService,
  connect(mapState),
)

export default enhance(ChangeLogModalContainer)
