import React, { useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'

import { RootState } from 'core/reducers'
import { withRouteUtilsProps } from 'services/utils/router'

import { generateRoute } from 'utils/routing'

import { withAppService, withAppServiceProps } from 'services/app'
import { withNurseriesService, withNurseriesServiceProps } from 'services/nurseries'
import { withRouter, withRouterProps } from 'services/router'

import { Nursery } from 'services/nurseries/models'
import { InitialValuesResponseProps, getInitialValues } from './helpers'
import SettingsInvoicesV3EditItemAnnualisedView from './SettingsInvoicesV3EditItemAnnualisedView'

const groups = {
  read: [
    'nursery.settings',
    'nurserySettings.invoice',
    'nurseryInvoiceSettings',
    'nurseryInvoiceSettings.numbers',
    'invoiceNumbers',
    'nurseryInvoiceSettings.paymentReminders',
    'nurseryInvoiceSettings.accountCodes',
    'accountCodes',
    'nurseryInvoiceSettings.annualisedInvoiceSettings',
    'annualisedInvoiceSettings',
    'nursery.organizationSettings',
    'organizationSettings',
    'organizationSettings.invoice',
    'organizationInvoiceSettings',
  ],
}

type SettingsInvoicesV3EditItemAnnualisedContainerProps = withAppServiceProps
  & withRouteUtilsProps
  & withRouterProps
  & withNurseriesServiceProps

const mapState = (
  state: RootState,
  { appSelectors, nurseriesSingleState }: SettingsInvoicesV3EditItemAnnualisedContainerProps,
) => ({
  errorMessages: appSelectors.getErrorMessages(nurseriesSingleState),
  isSubmitting: appSelectors.getIsSubmitting(nurseriesSingleState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const SettingsInvoicesV3EditItemAnnualisedContainer: React.FC<
  SettingsInvoicesV3EditItemAnnualisedContainerProps
  & PropsFromRedux
> = ({
  errorMessages,
  isSubmitting,
  navigate,
  nurseriesActions,
  nurseriesSelectors,
  params,
}) => {
  const [initialValues, setInitialValues] = useState<InitialValuesResponseProps>()
  const [nurseryData, setNurseryData] = useState<Nursery>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { nurseryId } = params

  const handleGetNurseriesSuccess = (response) => {
    setNurseryData(response?.data)
    setInitialValues(getInitialValues(response))
    setIsLoading(false)
  }

  useEffect(() => {
    nurseriesActions.get(nurseryId, {
      onSuccess: handleGetNurseriesSuccess,
      onlyData: true,
      params: { groups },
    })
  }, [])

  const handleSubmitSuccess = () => {
    navigate(generateRoute('SETTINGS.INVOICES.DETAILS', { nurseryId }))
  }

  const handleSubmit = (fields) => {
    const payload = nurseriesSelectors.getAnnualisedPayload(fields, nurseryData?.nurserySettings)

    nurseriesActions.update(nurseryId, {
      onSuccess: handleSubmitSuccess,
      params: { groups },
      payload,
    })
  }

  return (
    <SettingsInvoicesV3EditItemAnnualisedView
      cancelLink={generateRoute('SETTINGS.INVOICES.DETAILS', { nurseryId })}
      errorMessages={errorMessages}
      initialValues={initialValues}
      isFormLoading={isSubmitting}
      isLoading={isLoading}
      nurseryName={nurseryData?.name}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withRouter,
  withAppService,
  withNurseriesService,
  connector,
)

export default enhance(SettingsInvoicesV3EditItemAnnualisedContainer)
