import ServiceBase from 'services/ServiceBase'

import { getQueryString } from 'utils/api'

class OrganizationTermssApiClient extends ServiceBase {
  list = (nurseryId, params = {}) => this.request({
    path: `/nurseries/${nurseryId}/terms?${getQueryString(params)}`,
  })

  get = (nurseryId, id) => this.request({
    path: `/nurseries/${nurseryId}/terms/${id}/bulk`,
  })

  update = (nurseryId, id, payload) => this.request({
    body: payload,
    method: 'POST',
    path: `/nurseries/${nurseryId}/terms/${id}/bulk`,
  })

  delete = (nurseryId, id) => this.request({
    method: 'DELETE',
    path: `/nurseries/${nurseryId}/terms/${id}`,
  })
}

export default OrganizationTermssApiClient
