import _ from 'lodash'

export const validateRooms = (rooms, roomMoves) => {
  _.map(rooms, (room) => {
    const preparedRoom = room
    preparedRoom.invalid = !!_.find(roomMoves, (roomMove) => (
      roomMove.fromNurseryClass
      && roomMove.fromNurseryClass.id === room.id
      && roomMove.invalid
    ))

    return preparedRoom
  })

  return rooms
}
