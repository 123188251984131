import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledStatus = styled.div`
  border-radius: 18px;
  padding: 8px 14px;
  font-size: 13px;
  line-height: 12px;
  font-weight: 600;
  display: table-cell;
  color: ${NEUTRAL_COLOURS.WHITE};
  background: ${NEUTRAL_COLOURS.BASIC};
  text-transform: uppercase;

  ${({ background }) => background && `
    background: ${background};
  `}
`
