import moment from 'moment'

import { compose } from 'recompose'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { CHILD_EXTRA_ITEMS_FILTERS } from 'services/legacy/childExtraItems/constants'
import { CHILD_FUNDING_FILTERS } from 'services/legacy/childFunding/constants'
import { CHILD_DISCOUNTS_FILTER } from 'services/legacy/childDiscounts/constants'

import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withModalService } from 'services/utils/modal'
import { withChildService, withLegacyChildBankDetailsService } from 'services/legacy/child'
import { withChildSessionsService } from 'services/legacy/childSessions'
import { withChildAdhocSessionsService } from 'services/legacy/childAdhocSessions'
import { withChildExtraItemsService } from 'services/legacy/childExtraItems'
import { withChildFundingService } from 'services/legacy/childFunding'
import { withChildDiscountsService } from 'services/legacy/childDiscounts'
import { withChildBacsDetailsService } from 'services/childBacsDetails'
import { withRouter } from 'services/router'

import ChildFinanceDetailsView from './ChildFinanceDetailsView'

const CHILD_EXTRA_SESSIONS_GROUPS = { read: ['childExtraSession.nurserySession', 'nurserySession'] }
const CHILD_EXTRA_ITEMS_GROUPS = { read: ['childExtraItem.extraItem', 'extraItem'] }
const CHILD_FUNDING_GROUPS = {
  read: [
    'childFunding.funding',
    'nurseryFunding',
    'nurseryFunding.fundingType',
    'fundingType',
  ],
}

const CHILD_DISCOUNTS_GROUPS = {
  read: [
    'childDiscount.nurseryDiscount',
    'childDiscount.child',
    'nurseryDiscount',
  ],
}

const CHILD_BANK_DETAILS_GROUPS = {
  read: [
    'child.childBankDetail',
    'childBankDetail',
    'childBankDetail.paymentContact',
    'childBankDetail.invoiceRecipients',
    'childBankDetail.fundingLoopContact',
    'childBankDetail.notes',
    'childBankDetail.usualPaymentMethod',
    'paymentType',
    'carerChildRelation.carer',
    'carer',
    'carer.address',
  ],
}

const NURSERY_INVOICE_SETTINGS_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings',
    'nurserySettings.localeDetails',
  ],
}

const START_DATE = moment().startOf('month').format('YYYY-MM-DD')
const END_DATE = moment().endOf('month').format('YYYY-MM-DD')

class ChildFinanceDetailsContainer extends Component {
  componentDidMount() {
    this.fetchNurseryDetails()
    this.fetchChildBankDetails()
    this.fetchChildSessions()
    this.fetchChildAdhocSessions()
    this.fetchChildExtraItems()
    this.fetchChildFundings()
    this.fetchChildDiscounts()
    this.fetchChildBacsDetails()
  }

  componentWillUnmount() {
    const {
      childActions,
      childAdhocSessionsActions,
      childBacsDetailsActions,
      childDiscountsActions,
      childExtraItemsActions,
      childFundingActions,
      childSessionsActions,
    } = this.props

    childActions.clearBankDetails()
    childSessionsActions.clear()
    childAdhocSessionsActions.clear()
    childExtraItemsActions.clear()
    childFundingActions.clear()
    childDiscountsActions.clear()
    childBacsDetailsActions.clear()
  }

  fetchNurseryDetails = () => {
    const { nurseriesActions, nurseryOptions } = this.props

    nurseriesActions.get(nurseryOptions.id, {
      params: { groups: NURSERY_INVOICE_SETTINGS_GROUPS },
    })
  }

  fetchChildBankDetails = (silent) => {
    const {
      childActions,
      params,
    } = this.props

    childActions.getBankDetails({
      params: [params.childId, {
        groups: CHILD_BANK_DETAILS_GROUPS,
      }],
      silent,
    })
  }

  fetchChildSessions = () => {
    const { childSessionsActions, childSessionsSelectors, params } = this.props
    const { childId } = params

    const childSessionsApiParams = {
      criteria: childSessionsSelectors.getCriteria({ archived: 0, sessionDate: new Date() }),
      limit: 1,
      order: 'startDate DESC',
    }

    childSessionsActions.groupedByList(childId, childSessionsApiParams)
  }

  fetchChildAdhocSessions = () => {
    const {
      childAdhocSessionsHelpers,
      params,
    } = this.props

    const filters = {
      archived: false,
      date: {
        after: START_DATE,
        before: END_DATE,
      },
    }
    const criteria = childAdhocSessionsHelpers.getCriteria(filters, params.childId)

    const childAdhocSessionsApiParams = {
      criteria,
      groups: CHILD_EXTRA_SESSIONS_GROUPS,
    }

    childAdhocSessionsHelpers.getAllChildAdhocSessions(params.childId, childAdhocSessionsApiParams, true)
  }

  fetchChildExtraItems = () => {
    const {
      childExtraItemsHelper,
      childExtraItemsSelectors,
      params,
    } = this.props

    const filters = {
      dateRange: [null, END_DATE],
      status: CHILD_EXTRA_ITEMS_FILTERS.ACTIVE,
    }
    const criteria = childExtraItemsSelectors.getCriteriaSelector({
      ...filters,
      childId: params.childId,
      or: {
        ongoing: 1,
        startDate: START_DATE,
      },
    })

    childExtraItemsHelper.getAllChildExtraItems({ criteria, groups: CHILD_EXTRA_ITEMS_GROUPS })
  }

  fetchChildFundings = () => {
    const {
      childFundingHelpers,
      params,
    } = this.props

    const filters = {
      endDate: END_DATE,
      startDate: START_DATE,
      status: CHILD_FUNDING_FILTERS.ACTIVE,
    }
    const criteria = childFundingHelpers.getCriteria(filters, params.childId)

    const apiParams = { criteria, groups: CHILD_FUNDING_GROUPS }

    childFundingHelpers.getAllChildFundings(apiParams)
  }

  fetchChildDiscounts = () => {
    const {
      childDiscountsHelpers,
      childDiscountsSelectors,
      params,
    } = this.props

    const criteria = childDiscountsSelectors.getCriteriaSelector({
      ...{ status: CHILD_DISCOUNTS_FILTER.ACTIVE },
      childId: params.childId,
    })

    const apiParams = {
      criteria,
      groups: CHILD_DISCOUNTS_GROUPS,
    }

    childDiscountsHelpers.getAllChildDiscounts(apiParams)
  }

  fetchChildBacsDetails = () => {
    const { childBacsDetailsActions, params } = this.props

    childBacsDetailsActions.get({
      params: [params.childId],
    })
  }

  handleViewPlanDetailClick = () => {
    const { child, modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.PLAN_DETAILS, { child })
  }

  handleSubmitNotes = (fields) => (onSuccess) => {
    const { isNotesEditing, legacyChildBankDetailsActions, params } = this.props

    if (isNotesEditing) {
      return legacyChildBankDetailsActions.update(params.childId, fields, this.handleSubmitNotesSuccess(onSuccess))
    }

    return legacyChildBankDetailsActions.create(params.childId, fields, this.handleSubmitNotes(onSuccess))
  }

  handleSubmitNotesSuccess = (onSuccess) => () => {
    this.fetchChildBankDetails(true)
    onSuccess()
  }

  render() {
    const {
      bankData,
      child,
      childAdhocSessionTotalResult,
      childBacsDetails,
      childBankDetailsState,
      childBankNotes,
      childCurrentSessions,
      childDiscountsList,
      childExtraItems,
      childFundings,
      contactData,
      errorMessages,
      invoiceRecipients,
      isBankEditing,
      isCurrentPlanLoading,
      isFinanceV3Enabled,
      isUkCountry,
      router: { location: { pathname } },
    } = this.props

    const isLoading = childBankDetailsState.isFetching
    const isManagementPage = pathname.startsWith('/children/')

    return (
      <ChildFinanceDetailsView
        bankData={bankData}
        child={child}
        childAdhocSessionCount={childAdhocSessionTotalResult}
        childBacsDetails={childBacsDetails}
        childBankNotes={childBankNotes}
        childCurrentSessions={childCurrentSessions}
        childDiscounts={childDiscountsList}
        childExtraItems={childExtraItems}
        childFundings={childFundings}
        contactData={contactData}
        errorMessages={errorMessages}
        invoiceRecipients={invoiceRecipients}
        isBankEditing={isBankEditing}
        isCurrentPlanLoading={isCurrentPlanLoading}
        isFinanceV3Enabled={isFinanceV3Enabled}
        isLoading={isLoading}
        isManagementPage={isManagementPage}
        isUkCountry={isUkCountry}
        onSubmitNotes={this.handleSubmitNotes}
        onViewPlanDetailClick={this.handleViewPlanDetailClick}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childAdhocSessionsListState,
  childAdhocSessionsSelectors,
  childBacsDetailsSelectors,
  childBankDetailsState,
  childDiscountsListState,
  childDiscountsSelectors,
  childExtraItemsListState,
  childExtraItemsSelectors,
  childFundingListState,
  childFundingSelectors,
  childSelectors,
  childSessionsListState,
  childSessionsSelectors,
  nurseriesSelectors,
  params,
}) => ({
  bankData: childSelectors.getBankDetailsSelector(state),
  child: childSelectors.getChildSelector(state),
  childAdhocSessionTotalResult: childAdhocSessionsSelectors.getTotalResult(state),
  childBacsDetails: childBacsDetailsSelectors.getChildBacsDetails(state),
  childBankNotes: childSelectors.getChildBankNotes(state),
  childCurrentSessions: childSessionsSelectors.getChildCurrentSessionsSummaryBySessionNameSelectors(state),
  childDiscountsList: childDiscountsSelectors.getChildDiscountsListSelector(state),
  childExtraItems: childExtraItemsSelectors.getChildExtraItemsListSummaryByNameAndType(state),
  childFundings: childFundingSelectors.getChildFundingListSummary(state),
  contactData: childSelectors.getContactDetailsSelector(state),
  errorMessages: appSelectors.getErrorMessages(childBankDetailsState),
  invoiceRecipients: childSelectors.getInvoiceRecipientsWithPrimaryContact(state),
  isBankEditing: childSelectors.isBankEditingSelector(state),
  isCurrentPlanLoading: appSelectors.getIsFetching(
    childAdhocSessionsListState,
    childDiscountsListState,
    childExtraItemsListState,
    childFundingListState,
    childSessionsListState,
  ),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
  isNotesEditing: childSelectors.isNotesEditingSelector(state),
  isUkCountry: nurseriesSelectors.getIsUkCountry(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withChildService,
  withLegacyChildBankDetailsService,
  withChildSessionsService,
  withChildAdhocSessionsService,
  withChildExtraItemsService,
  withChildFundingService,
  withChildDiscountsService,
  withChildBacsDetailsService,
  withNurseriesService,
  connect(mapState),
)

export default enhance(ChildFinanceDetailsContainer)
