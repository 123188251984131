import _ from 'lodash'

import React from 'react'

import { generateRoute } from 'utils/routing'

import { Button, DateString, Hyperlink, TimeString } from 'components'

import i18n from 'translations'

import { Status } from './components'

export const getViewings = (data, onActionButtonClick) => _.map(data, (item) => {
  const {
    assignedPerson,
    enquiry,
    showAroundDate,
    status,
    ...rest
  } = item

  return {
    ...rest,
    childAgeMonths: i18n.t('global:ageMonths', {
      ageMonths: enquiry?.data?.child?.ageMonths,
    }),
    childName: (
      <Hyperlink to={generateRoute('ENQUIRIES.DETAIL', { enquiryId: enquiry?.id })} primary>
        {enquiry?.childName}
      </Hyperlink>
    ),
    contactName: (
      <Hyperlink to={generateRoute('ENQUIRIES.DETAIL', { enquiryId: enquiry?.id })} primary>
        {enquiry?.contactName}
      </Hyperlink>
    ),
    edit: (
      <Button.TableAction
        onClick={() => onActionButtonClick(item)}
      />
    ),
    staffName: assignedPerson?.name,
    status: (
      <Status showAroundDate={showAroundDate} status={status} />
    ),
    viewingDate: <DateString date={showAroundDate} />,
    viewingTime: <TimeString date={showAroundDate} />,
  }
})
