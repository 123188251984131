import _ from 'lodash'

import React from 'react'

import { ModalBox } from 'components'

import i18n from 'translations'

import { DAILY_DIARY_RECORD_MODAL_VARIANTS } from './DailyDiaryRecordModalContainer'

import ChildPickerTab from './ChildPickerTab'
import BottleTab from './BottleTab'
import SleepsTab from './SleepsTab'
import NappiesTab from './NappiesTab'
import ActivityTab from './ActivityTab'
import ToiletTrainingTab from './ToiletTrainingTab'

const DailyDiaryRecordModalView = ({
  childPickerTabVisible,
  date,
  forSpecificChild,
  onCancel,
  onCloseClick,
  onNext,
  onSuccess,
  onlyChildPicker,
  selectedChildren,
  timeType,
  variant,
}) => {
  const renderTitle = () => {
    if (onlyChildPicker) {
      return i18n.t('module:Modals:DailyDiaryRecordModal:addChildren')
    }

    if (DAILY_DIARY_RECORD_MODAL_VARIANTS.BOTTLE === variant) {
      return i18n.t('module:Modals:DailyDiaryRecordModal:addBottle')
    }

    if (DAILY_DIARY_RECORD_MODAL_VARIANTS.SLEEP === variant) {
      return i18n.t('module:Modals:DailyDiaryRecordModal:addSleep')
    }

    if (DAILY_DIARY_RECORD_MODAL_VARIANTS.NAPPY === variant) {
      return i18n.t('module:Modals:DailyDiaryRecordModal:addNappy')
    }

    if (DAILY_DIARY_RECORD_MODAL_VARIANTS.ACTIVITY === variant) {
      return i18n.t('module:Modals:DailyDiaryRecordModal:addActivity')
    }

    if (DAILY_DIARY_RECORD_MODAL_VARIANTS.TOILET_TRAINING === variant) {
      return i18n.t('module:Modals:DailyDiaryRecordModal:addToiletTraining')
    }

    return null
  }

  const renderContent = () => {
    if (childPickerTabVisible) {
      return (
        <ChildPickerTab
          date={date}
          onlyChildPicker={onlyChildPicker}
          selectedChildren={_.map(selectedChildren, ({ id }) => id)}
          timeType={timeType}
          variant={variant}
          onCloseClick={onCloseClick}
          onNext={onNext}
        />
      )
    }

    if (DAILY_DIARY_RECORD_MODAL_VARIANTS.BOTTLE === variant) {
      return (
        <BottleTab
          date={date}
          selectedChildren={selectedChildren}
          onCancel={forSpecificChild ? onCloseClick : onCancel}
          onSuccess={onSuccess}
        />
      )
    }

    if (DAILY_DIARY_RECORD_MODAL_VARIANTS.SLEEP === variant) {
      return (
        <SleepsTab
          date={date}
          selectedChildren={selectedChildren}
          onCancel={forSpecificChild ? onCloseClick : onCancel}
          onSuccess={onSuccess}
        />
      )
    }

    if (DAILY_DIARY_RECORD_MODAL_VARIANTS.NAPPY === variant) {
      return (
        <NappiesTab
          date={date}
          selectedChildren={selectedChildren}
          onCancel={forSpecificChild ? onCloseClick : onCancel}
          onSuccess={onSuccess}
        />
      )
    }

    if (DAILY_DIARY_RECORD_MODAL_VARIANTS.ACTIVITY === variant) {
      return (
        <ActivityTab
          date={date}
          selectedChildren={selectedChildren}
          onCancel={onCancel}
          onSuccess={onSuccess}
        />
      )
    }

    if (DAILY_DIARY_RECORD_MODAL_VARIANTS.TOILET_TRAINING === variant) {
      return (
        <ToiletTrainingTab
          date={date}
          selectedChildren={selectedChildren}
          onCancel={forSpecificChild ? onCloseClick : onCancel}
          onSuccess={onSuccess}
        />
      )
    }

    return null
  }

  return (
    <ModalBox
      scrollToBody={false}
      title={renderTitle()}
      autoHeight
      onCloseClick={() => onCloseClick()}
    >
      {renderContent()}
    </ModalBox>
  )
}

export default DailyDiaryRecordModalView
