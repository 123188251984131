import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class FrameworkProgressLevelsApiClient extends ServiceBase {
  list = (options = {}) => this.request({
    path: `/v2/framework_progress_levels?${getQueryStringV2(options)}`,
  })
}

export default FrameworkProgressLevelsApiClient
