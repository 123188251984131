import React, { useMemo } from 'react'
import { Field } from 'redux-form'

import { OneOffBookingTypes } from 'services/booking/childProducts/constants'

import { isRequired } from 'utils/fieldValidation'

import { getCriteria } from 'services/booking/childProducts/list/selectors'

import { Form } from 'components'

import i18n from 'translations'

import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

const DISCOUNT_GROUPS = {
  read: [
    'oneOffChildProduct.product',
    'nurseryDiscountProduct',
    'nurseryDiscountProduct.settings',
    'nurseryDiscountProductSettings',
  ],
}

interface DiscountFormFieldsProps {
  childId?: string
  isReadOnly?: boolean
}

export interface DiscountFormProps {
  discount?: string
}

const DiscountFormFields = ({
  childId,
  isReadOnly,
}: DiscountFormFieldsProps): JSX.Element => {
  const criteria = useMemo(() => getCriteria({
    childId,
    invoiced: false,
    type: OneOffBookingTypes.ONE_OFF_DISCOUNT,
  }), [childId])

  return (
    <React.Fragment>
      <Form.Row
        label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Discount:label')}
        width={{ field: '400px' }}
        required
      >
        <Field
          childId={childId}
          component={Form.InfiniteDropdowns.ChildProducts}
          criteria={criteria}
          disabled={isReadOnly}
          extraFields={['type', 'product.settings.value']}
          groups={DISCOUNT_GROUPS}
          maxMenuHeight={150}
          name="customOneOffDiscountChildProduct"
          placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Discount:placeholder')}
          validate={isRequired}
          pendingOnly
        />
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Amount:label')}
        width={{ field: '150px' }}
        required
      >
        <SubdomainCurrencyProvider>
          {({ currencySymbol }) => (
            <Field
              component={Form.TextField}
              name="total"
              placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Amount:placeholder')}
              prefix={currencySymbol}
              prefixWidth="30px"
              type="number"
              validate={isRequired}
              disabled
            />
          )}
        </SubdomainCurrencyProvider>
      </Form.Row>
    </React.Fragment>
  )
}

export default DiscountFormFields
