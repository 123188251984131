import _ from 'lodash'

import React from 'react'

import { getBrandingColor } from 'utils/branding'
import { generateRoute } from 'utils/routing'

import { BALANCE_ADJUSTMENT_TYPE } from 'services/legacy/balanceAdjustments/constants'

import {
  AmountSummary,
  Callout,
  DatePicker,
  EmptyState,
  Pagination,
  Section,
  Spinner,
  Table,
  Toolbar,
} from 'components'

import i18n from 'translations'

const ChildFinanceTransactionsView = ({
  ageDebtStatistics,
  ageingAnalysisColumns,
  childFinanceDetails,
  childId,
  columns,
  dateRange,
  errorMessages,
  isEmpty,
  isLoading,
  onClickBalanceAdjustment,
  onDateChange,
  onDownloadClick,
  onPageChange,
  onSendClick,
  onShowBalanceAdjustmentModal,
  onSortChange,
  page,
  pageCount,
  perPage,
  sortField,
  sortOrder,
  totalResults,
  transactions,
}) => {
  if (!childFinanceDetails) {
    return <Spinner />
  }

  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('module:Children:Child:Finance:Transactions:transactions')}
      titleSingular={i18n.t('module:Children:Child:Finance:Transactions:transaction')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const preparedTransactions = _.map(transactions, (item) => {
    const copyItem = { ...item }
    const { type } = item

    if (type === BALANCE_ADJUSTMENT_TYPE) {
      copyItem.onClick = () => onShowBalanceAdjustmentModal(item)
    }

    return copyItem
  })

  const renderSummary = () => {
    const { balance, totalBadDebt, totalInvoices } = childFinanceDetails || {}

    return (
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <AmountSummary
              amount={Math.abs(balance)}
              color={getBrandingColor('tertiary-color')}
              label="Total outstanding"
              suffix={0 < balance && ' CR'}
            />
          </Toolbar.Item>
          {0 < totalBadDebt && (
            <Toolbar.Item>
              <AmountSummary
                amount={totalBadDebt}
                color={getBrandingColor('quaternary-color')}
                label="Bad debt"
                margin="0 0 0 15px"
              />
            </Toolbar.Item>
          )}
        </Toolbar.Group>
        <Toolbar.Group>
          <Toolbar.Item>
            <AmountSummary
              amount={totalInvoices}
              label="Total billed to date"
              margin="0 0 0 15px"
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
    )
  }

  const renderHeader = () => (
    <Toolbar noMargin>
      <Toolbar.Group>
        <Toolbar.Item>
          <DatePicker
            value={dateRange}
            clearable
            range
            onChange={onDateChange}
          />
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )

  const renderContent = () => {
    if (isLoading) {
      return (
        <Spinner />
      )
    }

    if (isEmpty) {
      return (
        <EmptyState
          icon="transactions"
          text1="No transactions found for this child"
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={columns}
          data={preparedTransactions}
          sortField={sortField}
          sortOrder={sortOrder}
          disableSticky
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const renderAgeingAnalysis = () => (
    <Section
      title="Ageing Analysis"
    >
      <Table
        columns={ageingAnalysisColumns}
        data={ageDebtStatistics}
        disableSticky
      />
    </Section>
  )

  const actions = (
    <Section.Actions
      options={[{
        onClick: onSendClick,
        type: 'send',
      }, {
        onClick: onDownloadClick,
        type: 'download',
      }, {
        icon: 'balance-adjustment',
        label: 'Balance Adjustment',
        onClick: onClickBalanceAdjustment,
      }]}
      primary={[{
        label: 'Invoice',
        to: generateRoute('CHILDREN.CHILD.FINANCE.INVOICE.ADD', { childId }),
      }, {
        label: 'Payment',
        to: generateRoute('CHILDREN.CHILD.FINANCE.PAYMENT', { childId }),
      }]}
    />
  )

  return (
    <div>
      <Callout content={errorMessages} error />
      <Section
        actions={actions}
        title="Transactions"
      >
        {renderSummary()}
        {renderHeader()}
        {renderContent()}
      </Section>
      {!isLoading && renderAgeingAnalysis()}
    </div>
  )
}

export default ChildFinanceTransactionsView
