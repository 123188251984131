import * as OccupancyConstants from 'services/legacy/occupancy/constants'

export const getContentWidth = (isAmPmBreakdown, type, breakdown) => {
  if (
    type === OccupancyConstants.OCCUPANCY_REQUEST_TYPE.WEEKLY
    && breakdown === OccupancyConstants.OCCUPANCY_REQUEST_BREAKDOWN.AM_PM
  ) {
    return 80
  }

  return isAmPmBreakdown ? 150 : 70
}
