import React from 'react'

import { StyledTagContainer, StyledTagWrapper } from './PlanViewStyled'

const PlanViewTag = ({ text }) => (
  <StyledTagWrapper>
    <StyledTagContainer>
      {text}
    </StyledTagContainer>
  </StyledTagWrapper>
)

export default PlanViewTag
