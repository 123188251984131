import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

interface StyledContainerProps {
  $disabled?: boolean
  $selected?: boolean
  onClick?: () => {}
  to?: string
}

export const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  background: ${({ $selected }) => ($selected
    ? getBrandingColor('secondary-color')
    : getBrandingColor('primary-color'))};
  padding: 10px;
  border-radius: 4px;
  cursor: ${({ $disabled, onClick, to }) => ((onClick || to) && !$disabled ? 'pointer' : 'default')};
  opacity: ${({ $disabled }) => ($disabled ? '.5' : '1')};
  position: relative;
`

export const StyledPhotoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`

export const StyledMainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`

interface StyledNameProps {
  $compact?: boolean
}

export const StyledName = styled.div<StyledNameProps>`
  color: ${NEUTRAL_COLOURS.WHITE};
  font-size: ${({ $compact }) => ($compact ? '14px' : '18px')};
  text-overflow: ellipsis;
  word-break: break-all;
  padding-right: 8px;
  overflow: hidden;
`

export const StyledSubtitle = styled.div`
  font-size: 12px;
  margin-top: 3px;
  color: rgba(255, 255, 255, .75);
`

interface StyledIconContainerProps {
  $selected?: boolean
}

export const StyledIconContainer = styled.div<StyledIconContainerProps>`
  display: flex;
  align-items: center;
  
  ${({ $selected }) => $selected && `
    margin-right: 10px;
  `}
`

export const StyledSelectedIconContainer = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
`
