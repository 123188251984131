import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import { ChildBooking } from '../constants'

import {
  CLEAR_NURSERY_FUNDING_SINGLE,
  CREATE_CHILD_BOOKING,
  CREATE_CHILD_BOOKING_FAILED,
  CREATE_CHILD_BOOKING_SUCCESS,
  DELETE_CHILD_BOOKING,
  DELETE_CHILD_BOOKING_FAILED,
  DELETE_CHILD_BOOKING_SUCCESS,
  GET_CHILD_BOOKING,
  GET_CHILD_BOOKING_FAILED,
  GET_CHILD_BOOKING_SUCCESS,
  UPDATE_CHILD_BOOKING,
  UPDATE_CHILD_BOOKING_FAILED,
  UPDATE_CHILD_BOOKING_SUCCESS,
} from './constants'

export const get = (options: BasicActionOptionsProps) => ({ childBookingApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: GET_CHILD_BOOKING_FAILED,
      init: GET_CHILD_BOOKING,
      success: GET_CHILD_BOOKING_SUCCESS,
    },
    options,
    service: childBookingApiClient,
    serviceMethod: 'get',
  })
)

export const create = (
  options: BasicActionOptionsProps<ChildBooking>,
) => ({ childBookingApiClient }) => generateBasicActions.create({
  constants: {
    failed: CREATE_CHILD_BOOKING_FAILED,
    init: CREATE_CHILD_BOOKING,
    success: CREATE_CHILD_BOOKING_SUCCESS,
  },
  options,
  service: childBookingApiClient,
  serviceMethod: 'create',
})

export const update = (
  options: BasicActionOptionsProps<ChildBooking>,
) => ({ childBookingApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_CHILD_BOOKING_FAILED,
    init: UPDATE_CHILD_BOOKING,
    success: UPDATE_CHILD_BOOKING_SUCCESS,
  },
  options,
  service: childBookingApiClient,
  serviceMethod: 'update',
})

export const remove = (
  options: BasicActionOptionsProps<ChildBooking>,
) => ({ childBookingApiClient }) => generateBasicActions.update({
  constants: {
    failed: DELETE_CHILD_BOOKING_FAILED,
    init: DELETE_CHILD_BOOKING,
    success: DELETE_CHILD_BOOKING_SUCCESS,
  },
  options,
  service: childBookingApiClient,
  serviceMethod: 'remove',
})

export const clear = () => ({
  type: CLEAR_NURSERY_FUNDING_SINGLE,
})
