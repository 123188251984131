import * as itemsActions from './items/actions'
import * as montessoriItems from './montessoriItems/actions'
import * as listActions from './list/actions'
import * as singleActions from './single/actions'
import * as periodsActions from './periods/actions'
import * as termsActions from './terms/actions'

export default {
  ...itemsActions,
  ...montessoriItems,
  ...listActions,
  ...singleActions,
  ...periodsActions,
  ...termsActions,
}
