import _ from 'lodash'

export * from './childSingle/selectors'
export * from './membershipSingle/selectors'
export * from './nurserySingle/selectors'

export const getListCriteria = (params) => {
  const { search } = params
  const criteria = []

  if (search) {
    criteria.push({
      field: 'name',
      value: search,
    })
  }

  return criteria
}

export const getFilesInitialValues = (files) => (
  _.map(files, ({
    createdAt,
    description,
    file: { mimeType, size, status, url, videoEncoded, videoThumbnail },
    id,
    name,
  }) => ({
    createdAt,
    description,
    id,
    mimeType,
    name,
    size,
    status,
    url,
    videoEncoded,
    videoThumbnail,
  }))
)

export const normalizeFileBasedOnUploadingFormat = (item) => ({
  createdAt: item.createdAt,
  description: null,
  file: item.file,
  id: item.beId,
  mimeType: item.file.mimeType,
  name: item.name,
  size: item.file.size,
  url: item.file.url,
})
