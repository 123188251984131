import i18n from 'translations'

import ChildrenList from './ChildrenList'

export const ROUTE_NAMES = {
  'ORGANIZATION_CHILDREN.INDEX': 'ORGANIZATION_CHILDREN.INDEX',
}

const OrganizationChildrenRouting = {
  component: ChildrenList,
  name: ROUTE_NAMES['ORGANIZATION_CHILDREN.INDEX'],
  path: 'organization/children',
  title: i18n.t('module:Organization:Children:title'),
}

OrganizationChildrenRouting.ROUTE_NAMES = ROUTE_NAMES

export default OrganizationChildrenRouting
