import _ from 'lodash'

import React from 'react'

import { CircleIcon, Spinner, Tooltip } from 'components'
import InlineEditableField from 'module/Management/components/InlineEditableField'

import i18n from 'translations'

import StaffChildRatiosForm, { STAFF_CHILD_RATIOS_FORM } from './StaffChildRatiosForm'
import { StyledColumn, StyledContainer, StyledTooltip, StyledUsedForExpertLabel } from './StaffChildRatiosStyled'

const StaffChildRatios = ({ isFetching, onSubmit, value }) => {
  const initialValues = {
    staffChildRatios: _.map(value, ({ ageFrom, ageTo, ratio, usedForExpert }) => ({
      ageFrom, ageTo, ratio, usedForExpert,
    })),
  }

  const renderAssessmentPeriod = () => (
    <StyledContainer>
      <StyledColumn marginBottom>
        {!_.isEmpty(value)
        && i18n.t('module:Management:General:RotaAndOccupancy:StaffChildRatios:AgeGroups:label')}
      </StyledColumn>
      <StyledColumn marginBottom>
        {!_.isEmpty(value)
        && i18n.t('module:Management:General:RotaAndOccupancy:StaffChildRatios:title')}
      </StyledColumn>
      <StyledColumn marginBottom>
        {!_.isEmpty(value) && (
          <StyledUsedForExpertLabel>
            {i18n.t('module:Management:General:RotaAndOccupancy:StaffChildRatios:UsedForExpert:label')}
            <StyledTooltip>
              <Tooltip
                placement="bottom-end"
                title={i18n.t('module:Management:General:RotaAndOccupancy:StaffChildRatios:UsedForExpert:tooltip')}
              >
                <CircleIcon
                  cursor="pointer"
                  icon="info"
                  iconSize={16}
                  size={24}
                />
              </Tooltip>
            </StyledTooltip>
          </StyledUsedForExpertLabel>
        )}
      </StyledColumn>
      {_.map(value, ({ ageFrom, ageTo, ratio, usedForExpert }) => (
        <React.Fragment>
          <StyledColumn>
            {`${ageFrom}-${ageTo} ${i18n.t('global:months')}`}
          </StyledColumn>
          <StyledColumn>
            {`1:${ratio}`}
          </StyledColumn>
          <StyledColumn>
            {usedForExpert ? i18n.t('global:Yes') : i18n.t('global:No')}
          </StyledColumn>
        </React.Fragment>
      ))}
    </StyledContainer>
  )

  return (
    <InlineEditableField
      description={isFetching ? (
        <Spinner
          position="flex-start"
        />
      ) : renderAssessmentPeriod()}
      editableComponent={(callback, isSubmitting, formValues) => (
        <StaffChildRatiosForm
          formValues={formValues}
          initialValues={initialValues}
          isSubmitting={isSubmitting}
          onSubmit={callback}
        />
      )}
      formName={STAFF_CHILD_RATIOS_FORM}
      title={i18n.t('module:Management:General:RotaAndOccupancy:StaffChildRatios:title')}
      value={value}
      enableHandleFailed
      onSubmit={onSubmit}
    />
  )
}

export default StaffChildRatios

