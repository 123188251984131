import _ from 'lodash'

import React from 'react'
import { FieldArray, Field as FormField, reduxForm } from 'redux-form'
import { compose } from 'recompose'

import { isRequired, isValidInteger } from 'utils/fieldValidation'

import { Button, Field, Form } from 'components'

import i18n from 'translations'

import {
  StyledAgeGroups,
  StyledPause,
  StyledRatio,
  StyledRatioSuffix,
  StyledRemoveButton,
} from './StaffChildRatiosStyled'

export const STAFF_CHILD_RATIOS_FORM = 'STAFF_CHILD_RATIOS_FORM'

const renderRow = (formValues, isSubmitting) => (item, index, fields) => (
  <Form.Row
    key={index}
    margin="0 0 15px"
    multipleFieldsInARow
  >
    <Form.Row.FlexItem flex="0 0 291px" mobileFull>
      <Field.TopLabel
        hidden={!formValues?.staffChildRatios[index]?.ageFrom && !formValues?.staffChildRatios[index]?.ageTo}
        label={i18n.t('module:Management:General:RotaAndOccupancy:StaffChildRatios:AgeGroups:label')}
      />
      <StyledAgeGroups>
        <div>
          <FormField
            component={Form.TextField}
            disabled={isSubmitting}
            min="0"
            name={`${item}.ageFrom`}
            placeholder={
              i18n.t('module:Management:General:RotaAndOccupancy:StaffChildRatios:AgeGroups:AgeFrom:placeholder')
            }
            type="number"
            validate={[isRequired, isValidInteger]}
          />
        </div>
        <StyledPause>-</StyledPause>
        <div>
          <FormField
            component={Form.TextField}
            disabled={isSubmitting}
            min="0"
            name={`${item}.ageTo`}
            placeholder={
              i18n.t('module:Management:General:RotaAndOccupancy:StaffChildRatios:AgeGroups:AgeTo:placeholder')
            }
            type="number"
            validate={[isRequired, isValidInteger]}
          />
        </div>
      </StyledAgeGroups>
    </Form.Row.FlexItem>
    <Form.Row.FlexItem flex="0 0 200px" mobileFull>
      <Field.TopLabel
        hidden={!formValues?.staffChildRatios[index]?.ratio}
        label={i18n.t('module:Management:General:RotaAndOccupancy:StaffChildRatios:Ratio:label')}
      />
      <StyledRatio>
        <StyledRatioSuffix>1:</StyledRatioSuffix>
        <div>
          <FormField
            component={Form.TextField}
            disabled={isSubmitting}
            min="1"
            name={`${item}.ratio`}
            placeholder={i18n.t('module:Management:General:RotaAndOccupancy:StaffChildRatios:Ratio:placeholder')}
            type="number"
            validate={[isRequired, isValidInteger]}
          />
        </div>
      </StyledRatio>
    </Form.Row.FlexItem>
    <Form.Row.FlexItem flex="0 0 200px" mobileFull>
      <Field.TopLabel label="" hidden />
      <FormField
        component={Form.Checkbox}
        disabled={isSubmitting}
        label={i18n.t('module:Management:General:RotaAndOccupancy:StaffChildRatios:UsedForExpert:label')}
        name={`${item}.usedForExpert`}
      />
    </Form.Row.FlexItem>
    <Form.Row.FlexItem mobileFull>
      <StyledRemoveButton>
        <Form.RemoveRowButton
          disabled={isSubmitting}
          trash
          onClick={() => fields.remove(index)}
        />
      </StyledRemoveButton>
    </Form.Row.FlexItem>
  </Form.Row>
)

const renderRows = ({ fields, formValues, isSubmitting }) => (
  <React.Fragment>
    {fields.map(renderRow(formValues))}
    <Button.ActionButton
      disabled={isSubmitting}
      label={i18n.t('module:Management:General:RotaAndOccupancy:StaffChildRatios:addAgeGroup')}
      onClick={() => fields.push({})}
    />
  </React.Fragment>
)

const StaffChildRatiosForm = ({
  formValues,
  handleSubmit,
  isSubmitting,
  onSubmit,
}) => (
  <Form flex={1} onSubmit={handleSubmit(onSubmit)}>
    <FieldArray
      component={renderRows}
      name="staffChildRatios"
      props={{ formValues, isSubmitting }}
    />
  </Form>
)

const validate = (fields) => {
  const errors = { staffChildRatios: [] }

  _.forEach(fields?.staffChildRatios, ({ ageFrom, ageTo }, index) => {
    if (Number(ageTo) < Number(ageFrom)) {
      errors.staffChildRatios[index] = {
        ageTo: i18n.t('utils:Validation:greaterOrEqual', { value: ageFrom }),
      }
    }
  })

  _.forEach(fields?.staffChildRatios, ({ ageFrom: ageFromA, ageTo: ageToA }, indexA) => {
    _.forEach(fields?.staffChildRatios, ({ ageFrom: ageFromB }, indexB) => {
      if (Number(ageFromA) < Number(ageFromB) && Number(ageToA) > Number(ageFromB)) {
        errors.staffChildRatios[indexB] = {
          ageFrom: i18n.t('utils:Validation:overlappingAgeGroups'),
        }
      }

      if (Number(ageFromA) === Number(ageFromB) && indexA !== indexB) {
        errors.staffChildRatios[indexB] = {
          ageFrom: i18n.t('utils:Validation:overlappingAgeGroups'),
        }
      }
    })
  })

  return errors
}

const enhance = compose(reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: true,
  form: STAFF_CHILD_RATIOS_FORM,
  validate,
}))

export default enhance(StaffChildRatiosForm)
