import {
  CLEAR_FORMATIVE_REPORTS_LISTS,
  LIST_FORMATIVE_REPORTS,
  LIST_FORMATIVE_REPORTS_FAILED,
  LIST_FORMATIVE_REPORTS_SUCCESS,
} from './constants'

export const list = (params, onSuccess, onFailed) => ({ formativeReportsApiClient }) => (dispatch) => {
  dispatch({
    type: LIST_FORMATIVE_REPORTS,
  })

  formativeReportsApiClient.list(params).then(
    (response) => {
      dispatch({
        payload: response,
        type: LIST_FORMATIVE_REPORTS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: LIST_FORMATIVE_REPORTS_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const clearList = () => ({
  type: CLEAR_FORMATIVE_REPORTS_LISTS,
})
