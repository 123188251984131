import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { Form } from 'components'

import i18n from 'translations'

import { CHILD_ADD_FORM } from './constants'
import ChildFormFooter from './ChildFormFooter'

const TRANS_PREFIX = 'module:Children:Child:Add:LikesDislikes'

const LikesDislikesInformationForm = ({
  childId,
  handleSubmit,
  isChildArchived,
  isEditMode,
  isSubmitting,
  onArchiveClick,
  onPreviousStageClick,
  permissionToArchive,
}) => {
  const renderForm = () => (
    <React.Fragment>
      <Form.Row
        label={i18n.t(`${TRANS_PREFIX}:Form:Toys:label`)}
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextAreaField}
            name="profile.toysPreference"
            placeholder={i18n.t(`${TRANS_PREFIX}:Form:Toys:placeholder`)}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t(`${TRANS_PREFIX}:Form:Books:label`)}
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextAreaField}
            name="profile.booksPreference"
            placeholder={i18n.t(`${TRANS_PREFIX}:Form:Books:placeholder`)}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t(`${TRANS_PREFIX}:Form:Food:label`)}
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextAreaField}
            name="profile.foodsPreference"
            placeholder={i18n.t(`${TRANS_PREFIX}:Form:Food:placeholder`)}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t(`${TRANS_PREFIX}:Form:Interests:label`)}
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextAreaField}
            name="profile.otherPreference"
            placeholder={i18n.t(`${TRANS_PREFIX}:Form:Interests:placeholder`)}
          />
        </Form.Row.FlexItem>
      </Form.Row>
    </React.Fragment>
  )

  const renderFooter = () => (
    <ChildFormFooter
      childId={childId}
      isChildArchived={isChildArchived}
      isEditMode={isEditMode}
      isSubmitting={isSubmitting}
      permissionToArchive={permissionToArchive}
      onArchiveClick={onArchiveClick}
      onPreviousStageClick={onPreviousStageClick}
    />
  )

  return (
    <Form onSubmit={handleSubmit}>
      {renderForm()}
      {renderFooter()}
    </Form>
  )
}

export default reduxForm({
  destroyOnUnmount: false,
  form: CHILD_ADD_FORM,
})(LikesDislikesInformationForm)
