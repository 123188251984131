import React, { PropsWithChildren } from 'react'

import { Avatar } from 'components'

import { StyledAvatar } from './ProfileHeaderStyled'

interface ProfileHeaderAvatarProps {
  initials?: string | string[]
  src: string
}

const ProfileHeaderAvatar: React.FC<PropsWithChildren<ProfileHeaderAvatarProps>> = ({ initials, src }) => (
  <StyledAvatar>
    <Avatar
      avatarSize="big"
      initials={initials}
      src={src}
    />
  </StyledAvatar>
)

export default ProfileHeaderAvatar
