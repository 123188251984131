import React from 'react'

import { Button, EmptyState, Typography } from 'components'

import i18n from 'translations'

import BoxWrapper from '../components/BoxWrapper'

const ChooseNewPinSuccessView = ({
  onContinueButtonClick,
}) => (
  <BoxWrapper>
    <EmptyState
      actions={(
        <Button
          label={i18n.t('global:Continue')}
          negativeMargins
          onClick={onContinueButtonClick}
        />
      )}
      icon="confirmed"
      iconColor="#77dd77"
      text1={(
        <Typography
          fontSize={18}
          bold
        >
          {i18n.t('module:StaffRegister:ChooseNewPinSuccess:messageOne')}
        </Typography>
      )}
      text2={i18n.t('module:StaffRegister:ChooseNewPinSuccess:messageSecond')}
    />
  </BoxWrapper>
)

export default ChooseNewPinSuccessView
