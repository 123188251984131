import ServiceBase from 'services/ServiceBase'
import { getQueryStringV2 } from 'utils/api'

import { FILE_TYPES } from 'constants/mimetypes'

class ChildApiClient extends ServiceBase {
  list = (params) => this.request({
    path: `/v2/children?${getQueryStringV2(params)}`,
  })

  get = (childId, params = {}) => this.request({
    path: `/v2/children/${childId}?${getQueryStringV2(params)}`,
  })

  create = (params, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/children?${getQueryStringV2(params)}`,
  })

  update = (childId, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/children/${childId}`,
  })

  sendReport = (params) => this.request({
    headers: params.headers || {
      Accept: FILE_TYPES.CSV.acceptHeader,
    },
    path: `/v2/children/report?${getQueryStringV2(params)}`,
  })

  exportChildrenReport = (params) => this.request({
    path: `/v2/children/report/children?${getQueryStringV2(params)}`,
  })

  exportFundingLoopReport = (params) => this.request({
    path: `/v2/children/report/funding_loop?${getQueryStringV2(params)}`,
  })

  getDietaryStatistics = (params) => this.request({
    path: `/v2/statistics/dietary?${getQueryStringV2(params)}`,
  })

  sendFormativeReport = (childId, params) => this.request({
    path: `/v2/children/${childId}/report/formative?${getQueryStringV2(params)}`,
  })

  sendForecastReport = (payload) => this.request({
    body: payload,
    method: 'POST',
    path: '/v2/export/report/forecasting',
  })

  listChildFiles = (id, params) => this.request({
    path: `/v2/children/${id}/files?${getQueryStringV2(params)}`,
  })

  listChildChangelogs = (id, params) => this.request({
    path: `/v2/children/${id}/changelog?${getQueryStringV2(params)}`,
  })

  getChildFundingCalculator = (id, params, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/children/${id}/funding_calculator?${getQueryStringV2(params)}`,
  })

  getFundingSummaryCalculator = (id, params, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/children/${id}/funding_summary_calculator?${getQueryStringV2(params)}`,
  })

  getProductPriceCalculator = (id, params, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/children/${id}/product_price_calculator?${getQueryStringV2(params)}`,
  })
}

export default ChildApiClient
