import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class ChildFrameworkProgressApiClient extends ServiceBase {
  bulkUpdate = (body) => this.request({
    body,
    method: 'POST',
    path: '/v2/bulk/children/mark-framework',
  })

  listOfProgress = (childId, frameworkId, frameworkAreaId, params) => this.request(({
    path: `/v2/children/${childId}/framework/${frameworkId}/progress/${frameworkAreaId}?${getQueryStringV2(params)}`,
  }))

  listOfMontessoriProgress = (childId, montessori, params) => this.request(({
    path: `/v2/children/${childId}/montessori/progress/${montessori}?${getQueryStringV2(params)}`,
  }))

  batchUpdate = (params = {}, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/batch/child_framework_item_events?${getQueryStringV2(params)}`,
  })

  batchMontessoriUpdate = (params = {}, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/batch/child_montessori_activity_events?${getQueryStringV2(params)}`,
  })

  getProgressHistory = (params = {}) => this.request({
    path: `/v2/child_framework_item_events?${getQueryStringV2(params)}`,
  })

  getProgressHistoryMontessori = (params = {}) => this.request({
    path: `/v2/child_montessori_activity_events?${getQueryStringV2(params)}`,
  })

  removeEvent = (id) => this.request({
    method: 'DELETE',
    path: `/v2/child_framework_item_events/${id}`,
  })

  removeMontessoriEvent = (id) => this.request({
    method: 'DELETE',
    path: `/v2/child_montessori_activity_events/${id}`,
  })
}

export default ChildFrameworkProgressApiClient
