import styled from 'styled-components'

import colors, { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledHeader = styled.div`
  border-bottom: 1px dashed ${NEUTRAL_COLOURS.SILVER};
  padding: 10px 0;
  padding-right: 76px;
`

export const StyledFooterAction = styled.footer`
  margin-top: 15px;
  margin-bottom: 25px;
`

export const StyledMessage = styled.strong`
  ${({ invalid }) => invalid && `
    color: ${colors.remove};  
  `} 
`

export const StyledMoveRoomListContainer = styled.div`
  @media(max-width: 1070px) {
    overflow: visible;
    overflow-y: auto; 
  }
`

export const StyledMoveRoomListContent = styled.div`
  height: 100%;
  min-width: 940px;
  padding-left: 10px;
`
