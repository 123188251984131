import React, { useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'

import { FUNDING_STATUS_FILTERS } from 'services/product/nurseryFundingV3/constants'

import { typeByObject } from 'utils/typescript'

import { withAppService, withAppServiceProps } from 'services/app'
import { withNurseryFundingV3Service, withNurseryFundingV3ServiceProps } from 'services/product/nurseryFundingV3'
import { withPaginationUtils, withPaginationUtilsProps } from 'services/utils/pagination'
import { withRouteUtilsProps, withRouterUtils } from 'services/utils/router'
import { withRouter, withRouterProps } from 'services/router'

import ManagementFundingListV3View from './ManagementFundingListV3View'

type ManagementFundingListV3ContainerProps = withAppServiceProps
  & withNurseryFundingV3ServiceProps
  & withPaginationUtilsProps
  & withRouteUtilsProps
  & withRouterProps

const mapState = (state, {
  appSelectors,
  nurseryFundingV3ListState,
  nurseryFundingV3Selectors,
}) => ({
  errorMessages: appSelectors.getErrorMessages(nurseryFundingV3ListState),
  isFetching: appSelectors.getIsFetching(nurseryFundingV3ListState),
  records: nurseryFundingV3Selectors.getNurseryFundingV3ListDataState(state),
  totalResults: appSelectors.getTotalResults(nurseryFundingV3ListState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const NURSERY_FUNDING_GROUPS = {
  read: [
    'productPrice',
    'productPriceChange',
    'productPriceChange.prices',
    'nurseryFundingProduct.priceChanges',
    'nurseryFundingProduct.settings',
    'nurseryFundingProduct.nursery',
    'nurseryFundingProductSettings',
  ],
}

export interface ManagementFundingListV3Filters {
  status?: typeByObject<typeof FUNDING_STATUS_FILTERS>
}

const ManagementFundingListV3Container: React.FC<ManagementFundingListV3ContainerProps & PropsFromRedux> = ({
  errorMessages,
  isFetching,
  location,
  nurseryFundingV3Actions,
  nurseryFundingV3Selectors,
  paginationUtils,
  records,
  setLocationQuery,
  totalResults,
}) => {
  const { getPaginationDetails, onPageChange, page } = paginationUtils
  const { query } = location

  const [filters, setFilters] = useState<ManagementFundingListV3Filters>({
    status: query?.status || FUNDING_STATUS_FILTERS.ACTIVE,
  })

  const fetch = () => {
    const criteria = nurseryFundingV3Selectors.getCriteria(filters)

    setLocationQuery({ page, ...filters })

    const params = {
      criteria,
      groups: NURSERY_FUNDING_GROUPS,
      page,
    }

    nurseryFundingV3Actions.list({
      params,
    })
  }

  useEffect(() => () => {
    nurseryFundingV3Actions.clearList()
  }, [])

  useEffect(fetch, [page, filters])

  const handleFiltersChange = (filterName: keyof ManagementFundingListV3Filters) => (filterValue) => {
    onPageChange()(1)
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: filterValue,
    }))
  }

  return (
    <ManagementFundingListV3View
      errorMessages={errorMessages}
      filters={filters}
      isFetching={isFetching}
      pagination={getPaginationDetails(totalResults)}
      records={records}
      onFilterChange={handleFiltersChange}
    />
  )
}

const enhance = compose(
  withAppService,
  withNurseryFundingV3Service,
  withPaginationUtils,
  withRouter,
  withRouterUtils,
  connector,
)

export default enhance(ManagementFundingListV3Container)
