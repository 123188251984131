import React from 'react'
import { Field } from 'redux-form'

import { isEmailValid, isRequired, isValidPhotoUploadType } from 'utils/fieldValidation'

import { Banner, Form, Tooltip, Typography } from 'components'

import i18n from 'translations'

const StaffForm = ({
  disabled,
  isCreatingContext,
  isMe,
  isStaffingEnabled,
  onLoadMoreNurseries,
  onNameChange,
  showNurseriesSelect,
  titlesOptions,
}) => {
  const renderNurseriesSelect = () => showNurseriesSelect && (
    <Form.Row
      label={i18n.t('module:Staff:StaffAdd:Fields:Nurseries:label')}
      multipleFieldsInARow
    >
      <Form.Row.FlexItem flex="0 0 240px" mobileFull>
        <Tooltip
          placement="right"
          title={isMe
            ? i18n.t('module:Staff:StaffAdd:Fields:Nurseries:tooltip')
            : ''}
        >
          <Field
            component={Form.Select}
            disabled={isMe}
            loadOptions={onLoadMoreNurseries}
            name="nurseries"
            placeholder={i18n.t('module:Staff:StaffAdd:Fields:Nurseries:label')}
            searchable={false}
            multi
          />
        </Tooltip>
      </Form.Row.FlexItem>
      {!isMe && (
        <Form.Row.FlexItem flex="0 0 420px" mobileFull>
          <Banner.Info hideIcon>
            {i18n.t('module:Staff:StaffAdd:Fields:Nurseries:text')}
          </Banner.Info>
        </Form.Row.FlexItem>
      )}
    </Form.Row>
  )

  return (
    <React.Fragment>
      <Form.Row
        label={i18n.t('module:Staff:StaffAdd:Fields:Title:placeholder')}
      >
        <Form.Row.FlexItem flex="0 0 180px" mobileFull>
          <Field
            component={Form.Select}
            disabled={disabled}
            name="title"
            options={titlesOptions}
            placeholder={i18n.t('module:Staff:StaffAdd:Fields:Title:placeholder')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Staff:StaffAdd:Fields:Name:label')}
        multipleFieldsInARow
        required
      >
        <Form.Row.FlexItem flex="0 0 300px" mobileFull>
          <Field
            component={Form.TextField}
            disabled={disabled}
            label={i18n.t('module:Staff:StaffAdd:Fields:FirstName:placeholder')}
            name="firstName"
            placeholder={i18n.t('module:Staff:StaffAdd:Fields:FirstName:placeholder')}
            validate={!disabled && isRequired}
            onChange={isCreatingContext ? (e) => onNameChange('firstName', e) : () => { }}
          />
        </Form.Row.FlexItem>
        <Form.Row.FlexItem flex="0 0 300px" mobileFull>
          <Field
            component={Form.TextField}
            disabled={disabled}
            label={i18n.t('module:Staff:StaffAdd:Fields:Surname:placeholder')}
            name="surname"
            placeholder={i18n.t('module:Staff:StaffAdd:Fields:Surname:placeholder')}
            validate={!disabled && isRequired}
            onChange={isCreatingContext ? (e) => onNameChange('surname', e) : () => { }}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      {renderNurseriesSelect()}
      <Form.Row
        label={i18n.t('module:Staff:StaffAdd:Fields:Username:label')}
        required
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextField}
            disabled={disabled}
            name="username"
            placeholder={i18n.t('module:Staff:StaffAdd:Fields:Username:label')}
            validate={!disabled && isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Staff:StaffAdd:Fields:Email:label')}
        required
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextField}
            disabled={disabled}
            name="email"
            placeholder={i18n.t('module:Staff:StaffAdd:Fields:Email:placeholder')}
            validate={!disabled && [isRequired, isEmailValid]}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Staff:StaffAdd:Fields:ContactNumber:label')}
        multipleFieldsInARow
      >
        <Form.Row.FlexItem flex="0 0 300px" mobileFull>
          <Field
            component={Form.TextField}
            disabled={disabled}
            label={i18n.t('module:Staff:StaffAdd:Fields:Telephone:placeholder')}
            name="membershipProfile.telephone"
            placeholder={i18n.t('module:Staff:StaffAdd:Fields:Telephone:placeholder')}
          />
        </Form.Row.FlexItem>
        <Form.Row.FlexItem flex="0 0 300px" mobileFull>
          <Field
            component={Form.TextField}
            disabled={disabled}
            label={i18n.t('module:Staff:StaffAdd:Fields:Mobile:placeholder')}
            name="membershipProfile.mobile"
            placeholder={i18n.t('module:Staff:StaffAdd:Fields:Mobile:placeholder')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Staff:StaffAdd:Fields:Photo:label')}
        multipleFieldsInARow
      >
        <Form.Row.FlexItem flex="0 0 120px" mobileFull>
          <Field
            component={Form.PhotoUpload}
            disabled={disabled}
            name="photo"
            validate={isValidPhotoUploadType}
          />
        </Form.Row.FlexItem>
        <Form.Row.FlexItem flex="0 0 460px" mobileFull>
          <Banner.Info hideIcon>
            <Typography>
              {i18n.t('module:Staff:StaffAdd:Fields:Photo:Text:1')}
            </Typography>
            <Typography margin="10px 0 0">
              {i18n.t('module:Staff:StaffAdd:Fields:Photo:Text:2')}
            </Typography>
          </Banner.Info>
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Staff:StaffAdd:Fields:BirthDate:label')}
        width={{ field: '175px' }}
      >
        <Form.Row.Item mobileFull>
          <Field
            component={Form.DatePicker}
            disabled={disabled}
            name="birthDate"
            placeholder={i18n.t('module:Staff:StaffAdd:Fields:BirthDate:placeholder')}
            clearable
          />
        </Form.Row.Item>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Staff:StaffAdd:Fields:PayrollNumber:label')}
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextField}
            disabled={disabled}
            name="membershipProfile.payrollNumber"
            placeholder={i18n.t('module:Staff:StaffAdd:Fields:PayrollNumber:label')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Staff:StaffAdd:Fields:Qualification:label')}
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextField}
            disabled={disabled}
            name="membershipProfile.qualification"
            placeholder={i18n.t('module:Staff:StaffAdd:Fields:Qualification:label')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      {isStaffingEnabled && (
        <Form.Row
          label={i18n.t('module:Staff:StaffAdd:Fields:OneThirteenQualification:label')}
        >
          <Form.Row.FlexItem mobileFull>
            <Field
              component={Form.Switch}
              disabled={disabled}
              name="membershipProfile.expert"
              placeholder={i18n.t('module:Staff:StaffAdd:Fields:OneThirteenQualification:label')}
            />
          </Form.Row.FlexItem>
        </Form.Row>
      )}
      <Form.Row
        label={i18n.t('module:Staff:StaffAdd:Fields:DbsCertificate:label')}
        multipleFieldsInARow
      >
        <Form.Row.FlexItem flex="0 0 160px" mobileFull>
          <Field
            component={Form.TextField}
            disabled={disabled}
            label={i18n.t('module:Staff:StaffAdd:Fields:DbsCertificateId:placeholder')}
            name="membershipProfile.dbsCheck.certificateId"
            placeholder={i18n.t('module:Staff:StaffAdd:Fields:DbsCertificateId:placeholder')}
          />
        </Form.Row.FlexItem>
        <Form.Row.FlexItem flex="0 0 250px" mobileFull>
          <Field
            component={Form.TextField}
            disabled={disabled}
            label={i18n.t('module:Staff:StaffAdd:Fields:DbsReviewBy:placeholder')}
            name="membershipProfile.dbsCheck.reviewBy"
            placeholder={i18n.t('module:Staff:StaffAdd:Fields:DbsReviewBy:placeholder')}
          />
        </Form.Row.FlexItem>
        <Form.Row.FlexItem flex="0 0 175px" mobileFull>
          <Field
            component={Form.DatePicker}
            disabled={disabled}
            label={i18n.t('module:Staff:StaffAdd:Fields:DbsReviewDate:placeholder')}
            name="membershipProfile.dbsCheck.reviewDate"
            placeholder={i18n.t('module:Staff:StaffAdd:Fields:DbsReviewDate:placeholder')}
            clearable
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Staff:StaffAdd:Fields:Address:label')}
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextAreaField}
            disabled={disabled}
            name="membershipProfile.address"
            placeholder={i18n.t('module:Staff:StaffAdd:Fields:Address:label')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Staff:StaffAdd:Fields:Postcode:label')}
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextField}
            disabled={disabled}
            name="membershipProfile.postCode"
            placeholder={i18n.t('module:Staff:StaffAdd:Fields:Postcode:label')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Staff:StaffAdd:Fields:Bio:label')}
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextAreaField}
            disabled={disabled}
            maxCharacters={640}
            name="membershipProfile.bio"
            placeholder={i18n.t('module:Staff:StaffAdd:Fields:Bio:placeholder')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
    </React.Fragment>
  )
}

export default StaffForm
