import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { STAFF_REGISTER_STATUS } from 'services/legacy/membershipRegisters/constants'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'

import withStaffRegisterWrapper from '../withStaffRegisterWrapper'
import ChangeLocationView from './ChangeLocationView'

class ChangeLocationContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentDate: moment(),
    }

    this.timeHandler = null

    this.updateCurrentDate()
  }

  componentWillUnmount() {
    clearTimeout(this.timeHandler)
  }

  updateCurrentDate = () => {
    this.timeHandler = setTimeout(() => {
      this.setState({
        currentDate: moment(),
      })

      this.updateCurrentDate()
    }, 30 * 1000)
  }

  handleSuccessEntry = (response) => {
    const { clearStoreAndRedirectToStaffList, router, sendNotification, staff, staffProfile } = this.props
    const { entries } = staff
    const { name } = staffProfile || {}

    sendNotification({
      hasEntries: !!entries?.length,
      name,
      response,
    })
    clearStoreAndRedirectToStaffList(() => router.replace(generateRoute('STAFF_REGISTER.LIST')))
  }

  handleSubmit = ({ nurseryClass }) => {
    const {
      membershipRegistersActions,
      membershipRegistersSelectors,
      membershipRegistersSingleState,
      selectedStatus,
    } = this.props

    const payload = membershipRegistersSelectors.getCreateEntryPayload({
      membershipRegisterData: membershipRegistersSingleState.data,
      nurseryClass,
      status: selectedStatus,
    })

    membershipRegistersActions.createEntry({
      onSuccess: this.handleSuccessEntry,
      payload,
    })
  }

  handleBackButtonClick = () => {
    const { navigate, params } = this.props
    const { staffId } = params || {}

    navigate(generateRoute('STAFF_REGISTER.DASHBOARD', { staffId }))
  }

  handleSkipButtonClick = () => {
    const {
      membershipRegistersActions,
      membershipRegistersSelectors,
      membershipRegistersSingleState,
      selectedStatus,
    } = this.props

    const payload = membershipRegistersSelectors.getCreateEntryPayload({
      membershipRegisterData: membershipRegistersSingleState.data,
      status: selectedStatus,
    })

    membershipRegistersActions.createEntry({
      onSuccess: this.handleSuccessEntry,
      payload,
    })
  }

  render() {
    const {
      errorMessages,
      membershipRegistersSingleState,
      selectedStatus,
      staff,
      staffProfile,
    } = this.props
    const { currentDate } = this.state

    const isSubmitting = membershipRegistersSingleState.isSubmitting
    const isLocationRequired = selectedStatus !== STAFF_REGISTER_STATUS.ON_BREAK

    return (
      <ChangeLocationView
        currentDate={currentDate}
        errorMessages={errorMessages}
        isLocationRequired={isLocationRequired}
        isSubmitting={isSubmitting}
        selectedStatus={selectedStatus}
        staff={staff}
        staffProfile={staffProfile}
        onBackButtonClick={this.handleBackButtonClick}
        onSkipButtonClick={this.handleSkipButtonClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  authSelectors,
  membershipRegistersSelectors,
  membershipRegistersSingleState,
}) => ({
  errorMessages: appSelectors.getErrorMessages(membershipRegistersSingleState),
  selectedStatus: membershipRegistersSelectors.getMembershipRegisterSingleStatus(state),
  staff: membershipRegistersSelectors.getMembershipRegisterSingleFormattedData(state),
  staffProfile: authSelectors.getAuthPinProfile(state),
})

const enhance = compose(
  withAppService,
  withStaffRegisterWrapper,
  connect(mapState),
)

export default enhance(ChangeLocationContainer)
