import React from 'react'

import { noop } from 'constants/global'

import { Page, SearchBar, Section, Spinner } from 'components'

import i18n from 'translations'

import StaffFileUploadsForm from './components/StaffFileUploadsForm'
import { StyledCustomPageWrapper } from './StaffFileUploadsStyled'

const StaffFileUploadsView = ({
  isFetching,
  onSearchChange,
  pagination,
  ...rest
}) => {
  const renderContent = () => {
    if (isFetching && 1 === pagination.page) {
      return (
        <Spinner />
      )
    }

    return (
      <StaffFileUploadsForm
        isFetching={isFetching}
        pagination={pagination}
        onSubmit={noop}
        {...rest}
      />
    )
  }

  return (
    <Page isLoading={isFetching && 1 === pagination.page}>
      <Page.Content>
        <StyledCustomPageWrapper>
          <Section title={i18n.t('services:Files:title')} noMargin>
            <SearchBar
              placeholder={i18n.t('global:searchByFileName')}
              variant="standard"
              noBackground
              onChange={onSearchChange}
            />
            {renderContent()}
          </Section>
        </StyledCustomPageWrapper>
      </Page.Content>
    </Page>
  )
}

export default StaffFileUploadsView
