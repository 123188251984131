import React from 'react'

import { Button, Pinpad, Space, Typography } from 'components'

import i18n from 'translations'

import { BoxWrapper, PinProfileAvatar } from '../components'

const ChooseNewPinView = ({
  formError,
  isConfirmPage,
  isCreatePinPage,
  isLoading,
  onBackButtonClick,
  onSubmit,
  user,
}) => {
  const { firstName, name, photo, surname } = user || {}

  const renderContent = () => (
    <React.Fragment>
      <Typography align="center" variant="h6" medium>
        {isCreatePinPage
          ? i18n.t('module:StaffRegister:global:createPin')
          : i18n.t('module:StaffRegister:global:forgotPin')}
      </Typography>
      <Space margin="10px 0 0" />
      <Typography align="center" variant="h5">
        {isConfirmPage
          ? i18n.t('module:StaffRegister:ChooseNewPin:confirmNewPin')
          : i18n.t('module:StaffRegister:ChooseNewPin:chooseNewPin')}
      </Typography>
      <Space margin="15px 0 0" />
      <PinProfileAvatar
        firstName={firstName}
        name={name}
        photo={photo}
        surname={surname}
      />
      <Pinpad
        error={formError}
        isLoading={isLoading}
        valid={!formError}
        onSubmit={onSubmit}
      />
    </React.Fragment>
  )

  return (
    <BoxWrapper
      footer={(
        <Button
          hierarchy="tertiary"
          label={i18n.t('global:Back')}
          negativeMargins
          onClick={onBackButtonClick}
        />
      )}
    >
      {renderContent()}
    </BoxWrapper>
  )
}

export default ChooseNewPinView
