import _ from 'lodash'
import browser from 'browser-detect'

import { BROWSERS } from 'constants/browsers'

export const checkBrowserIsOutOfTheList = () => {
  const result = browser()
  const { name } = result

  return !_.find(BROWSERS, (value) => value === name)
}

// @ts-ignore
export const isIOS = () => /iPad|iPhone|iPod/.test(window.navigator.userAgent) && !window.MSStream
