import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as frameworksSelectors from './selectors'

const withFrameworksService = (WrappedComponent) => {
  const mapState = ({ frameworks }) => ({ frameworks })

  const mapDispatch = injectActions('frameworksActions', actions)

  const Component = ({ frameworks, frameworksActions, ...other }) => (
    <WrappedComponent
      frameworksActions={frameworksActions}
      frameworksLegacyListState={frameworks.legacyList}
      frameworksListState={frameworks.list}
      frameworksSelectors={frameworksSelectors}
      frameworksSingleState={frameworks.single}
      {...other}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withFrameworksService
