import styled from 'styled-components'

export const StyledComments = styled.div`
  margin-top: 20px;
  word-break: break-all;
  
  ${({ $noComments }) => $noComments && `
    color: #999;
  `}
`

export const StyledHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const StyledDate = styled.div`
  font-weight: 600;
`
