import { CLEAR_MY_DAILY_DIARY_LIST, GET_MY_DAILY_DIARY_LIST, GET_MY_DAILY_DIARY_LIST_SUCCESS } from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: null,
}

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_MY_DAILY_DIARY_LIST:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_MY_DAILY_DIARY_LIST_SUCCESS:
      return { ...state, data: payload.data, isFetching: false, meta: payload.meta }
    case CLEAR_MY_DAILY_DIARY_LIST:
      return initialState
    default:
      return state
  }
}
