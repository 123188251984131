import { UPDATE_CHILDREN_SESSION, UPDATE_CHILDREN_SESSION_FAILED, UPDATE_CHILDREN_SESSION_SUCCESS } from './constants'

export const update = (id, payload, options = {}) => ({ childrenSessionsApiClient }) => (dispatch) => {
  const { params = {}, onSuccess, onFailed } = options

  dispatch({
    id,
    params,
    payload,
    type: UPDATE_CHILDREN_SESSION,
  })

  childrenSessionsApiClient.update(id, payload, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: UPDATE_CHILDREN_SESSION_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      const errorResponse = response

      dispatch({
        error: !errorResponse.extra ? errorResponse : null,
        type: UPDATE_CHILDREN_SESSION_FAILED,
      })

      if (onFailed) {
        onFailed(errorResponse)
      }
    },
  )
}
