import { generateRoute } from 'utils/routing'

import i18n from 'translations'

export const getMenuItems = (authAccessMap, userId, isMyDetailsContext) => ([
  {
    label: i18n.t('module:Staff:SubMenu:about'),
    to: isMyDetailsContext
      ? generateRoute('ACCOUNT.ABOUT')
      : generateRoute('STAFF.PROFILE.ABOUT', { userId }),
  },
  {
    auth: authAccessMap.module.StaffContracts,
    label: i18n.t('module:Staff:SubMenu:contracts'),
    to: isMyDetailsContext
      ? generateRoute('ACCOUNT.CONTRACTS')
      : generateRoute('STAFF.PROFILE.CONTRACTS', { userId }),
  },
  {
    auth: authAccessMap.module.StaffLeave,
    label: i18n.t('module:Staff:SubMenu:leave'),
    to: isMyDetailsContext
      ? generateRoute('ACCOUNT.LEAVE')
      : generateRoute('STAFF.PROFILE.LEAVE', { userId }),
  },
  {
    auth: authAccessMap.module.StaffFiles,
    label: i18n.t('services:Files:title'),
    to: isMyDetailsContext
      ? generateRoute('ACCOUNT.FILES')
      : generateRoute('STAFF.PROFILE.FILES', { userId }),
  },
])
