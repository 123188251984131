import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  GET_NURSERY_FUNDING_V3_LIST,
  GET_NURSERY_FUNDING_V3_LIST_FAILED,
  GET_NURSERY_FUNDING_V3_LIST_SUCCESS,
} from './constants'

export const dropdownList = (
  options: BasicActionOptionsProps,
) => ({ nurseryFundingV3ApiClient }) => generateBasicActions.list({
  constants: {
    failed: GET_NURSERY_FUNDING_V3_LIST_FAILED,
    init: GET_NURSERY_FUNDING_V3_LIST,
    success: GET_NURSERY_FUNDING_V3_LIST_SUCCESS,
  },
  options,
  service: nurseryFundingV3ApiClient,
  serviceMethod: 'list',
})
