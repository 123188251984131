import _ from 'lodash'

import React, { useMemo, useState } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { FEATURE_FLAGS } from 'constants/security'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import i18n from 'translations'

import FinanceReportAnnualRevenue from './FinanceReportAnnualRevenue'
import FinanceReportForcasting from './FinanceReportForecasting'

import FinanceReportView from './FinanceReportView'

const FinanceReportContainer = ({
  AnnualRevenueGranted,
  ForecastingGranted,
  GroupReportingEnabled,
}) => {
  const [search, setSearch] = useState('')

  const items = useMemo(() => {
    const reportItems = [
      {
        description: i18n.t('module:Finance:Reports:DetailedRevenue:description'),
        title: i18n.t('module:Finance:Reports:DetailedRevenue:title'),
        to: generateRoute('FINANCE.REPORT.DETAILED_REVENUE'),
      },
      {
        description: i18n.t('module:Finance:Reports:AnnualRevenue:description'),
        hidden: !AnnualRevenueGranted,
        title: i18n.t('module:Finance:Reports:AnnualRevenue:title'),
        to: generateRoute('FINANCE.REPORT.ANNUAL_REVENUE'),
      },
      {
        description: i18n.t('module:Finance:Reports:CreditNotes:description'),
        title: i18n.t('module:Finance:Reports:CreditNotes:title'),
        to: generateRoute('FINANCE.REPORT.CREDIT_REPORT'),
      }, {
        description: i18n.t('module:Finance:Reports:Forecasting:description'),
        hidden: !ForecastingGranted,
        title: i18n.t('module:Finance:Reports:Forecasting:title'),
        to: generateRoute('FINANCE.REPORT.FORECASTING'),
      },
      {
        description: i18n.t('module:Finance:Reports:Funding:description'),
        title: i18n.t('module:Finance:Reports:Funding:title'),
        to: generateRoute('FINANCE.REPORT.FUNDING'),
      },
      {
        description: i18n.t('module:Finance:Reports:BadDebt:description'),
        title: i18n.t('module:Finance:Reports:BadDebt:title'),
        to: generateRoute('FINANCE.REPORT.BAD_DEBT'),
      },
      {
        description: i18n.t('module:Finance:Reports:AgedDebt:description'),
        title: i18n.t('module:Finance:Reports:AgedDebt:title'),
        to: generateRoute('FINANCE.REPORT.AGED_DEBT'),
      },
      {
        description: i18n.t('module:Finance:Reports:Balances:description'),
        hidden: !GroupReportingEnabled,
        title: i18n.t('module:Finance:Reports:Balances:title'),
        to: generateRoute('FINANCE.REPORT.BALANCE'),
      },
      {
        description: i18n.t('module:Finance:Reports:RevenuePerAge:description'),
        hidden: !GroupReportingEnabled,
        title: i18n.t('module:Finance:Reports:RevenuePerAge:title'),
        to: generateRoute('FINANCE.REPORT.REVENUE_PER_AGE'),
      },
      {
        description: i18n.t('module:Finance:Reports:RevenuePerRoom:description'),
        hidden: !GroupReportingEnabled,
        title: i18n.t('module:Finance:Reports:RevenuePerRoom:title'),
        to: generateRoute('FINANCE.REPORT.REVENUE_PER_ROOM'),
      },
      {
        description: i18n.t('module:Finance:Reports:FailedRecurringPayment:description'),
        hidden: !GroupReportingEnabled,
        title: i18n.t('module:Finance:Reports:FailedRecurringPayment:title'),
        to: generateRoute('FINANCE.REPORT.FAILED_RECURRING_PAYMENT'),
      },
      {
        description: i18n.t('module:Finance:Reports:ActiveRecurringPayment:description'),
        hidden: !GroupReportingEnabled,
        title: i18n.t('module:Finance:Reports:ActiveRecurringPayment:title'),
        to: generateRoute('FINANCE.REPORT.ACTIVE_RECURRING_PAYMENT'),
      },
    ]

    if (search) {
      return _.filter(reportItems, ({ description, title }) => (
        title.toLowerCase().includes(search.toLowerCase())
        || description.toLowerCase().includes(search.toLowerCase())
      ))
    }

    return reportItems
  }, [AnnualRevenueGranted, ForecastingGranted, GroupReportingEnabled, search])

  return (
    <FinanceReportView items={items} onSearchChange={setSearch} />
  )
}

FinanceReportContainer.authParams = {
  flags: [FEATURE_FLAGS.FINANCE_AUTOMATION],
}

const mapState = (state) => ({
  AnnualRevenueGranted: auth.SELECTORS.getComponentIsAuthorised(state, FinanceReportAnnualRevenue),
  ForecastingGranted: auth.SELECTORS.getComponentIsAuthorised(state, FinanceReportForcasting),
  GroupReportingEnabled: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.GROUP_REPORTING_V2],
  }),
})

const enhance = compose(
  connect(mapState),
)

export default enhance(FinanceReportContainer)
