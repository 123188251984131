export const STRIPE_INTEGRATION_TYPE = 'stripe'

export const BACS_TYPE = 'BACS'

export type IntegrationFeeModel = {
  createdAt?: Date
  default?: boolean
  extraAmount?: boolean
  id?: number | string
  isDraft?: boolean
  maxAmount?: number
  minAmount?: number
  paymentType?: string
  percentage?: number
  type?: string
  updatedAt?: Date
}
