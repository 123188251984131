import styled from 'styled-components'

export const StyledContainer = styled.section`
  display: grid;
  grid-row-gap: 12px;
  grid-column-gap: 40px;
  grid-template-columns: 1fr auto 1.5fr;
  grid-template-areas: 'source-label source-label target-label' 'source-value icon target-value';
  align-items: center;
`

export const StyledSourceLabel = styled.div`
  grid-area: source-label;
`

export const StyledTargetLabel = styled.div`
  grid-area: target-label;
`

export const StyledSourceValue = styled.div`
  grid-area: source-value;
`

export const StyledIcon = styled.div`
  grid-area: icon;
`

export const StyledTargetValue = styled.div`
  grid-area: target-value;
`
