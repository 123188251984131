import { generateBasicActions } from 'utils/service'

import {
  ABSENCE_REASONS_DROPDOWN_LIST,
  ABSENCE_REASONS_DROPDOWN_LIST_FAILED,
  ABSENCE_REASONS_DROPDOWN_LIST_SUCCESS,
  GET_ABSENCE_REASONS_DROPDOWN_ITEM,
  GET_ABSENCE_REASONS_DROPDOWN_ITEM_FAILED,
  GET_ABSENCE_REASONS_DROPDOWN_ITEM_SUCCESS,
} from './constants'

export const dropdownList = (options = {}) => ({
  absenceReasonsApiClient,
}) => generateBasicActions.list({
  constants: {
    failed: ABSENCE_REASONS_DROPDOWN_LIST_FAILED,
    init: ABSENCE_REASONS_DROPDOWN_LIST,
    success: ABSENCE_REASONS_DROPDOWN_LIST_SUCCESS,
  },
  options,
  service: absenceReasonsApiClient,
  serviceMethod: 'list',
})

export const getDropdownItem = (options = {}) => ({ absenceReasonsApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_ABSENCE_REASONS_DROPDOWN_ITEM_FAILED,
    init: GET_ABSENCE_REASONS_DROPDOWN_ITEM,
    success: GET_ABSENCE_REASONS_DROPDOWN_ITEM_SUCCESS,
  },
  options,
  service: absenceReasonsApiClient,
  serviceMethod: 'get',
})
