import React from 'react'

import { getBrandingColor } from 'utils/branding'

import { GroupAvatars, Icon } from 'components'

import {
  StyledChild,
  StyledContainer,
  StyledDetail,
  StyledLinkContainer,
  StyledNavImage,
  StyledRightColumn,
} from './WidgetListStyled'

const WidgetListRow = ({
  childList,
  children,
  childrenCount,
  index,
  leftColumn,
  previewChildren,
  reverse = false,
  rightColumn,
  to,
}) => {
  const renderChildList = () => (
    <GroupAvatars
      backgroundColor={getBrandingColor(0 !== (index + (+reverse)) % 2 ? 'secondary-color' : 'primary-color')}
      borderColor={getBrandingColor(0 !== (index + (+reverse)) % 2 ? 'primary-color' : 'senary-color')}
      countOverride={childrenCount || childList?.length}
      maxLengthOfAvatars={2}
      persons={previewChildren || childList}
      withoutText
    />
  )

  const renderContent = () => {
    if (children) {
      return children
    }

    return (
      <React.Fragment>
        {(childList || previewChildren) && (
          <StyledChild>
            {renderChildList()}
          </StyledChild>
        )}
        <StyledDetail>
          {leftColumn && leftColumn}
        </StyledDetail>
        <StyledRightColumn>
          {rightColumn && rightColumn}
        </StyledRightColumn>
        {to && (
          <StyledNavImage>
            <Icon
              color="#fff"
              height={20}
              icon="chevron-right"
            />
          </StyledNavImage>
        )}
      </React.Fragment>
    )
  }

  if (to) {
    return (
      <StyledLinkContainer to={to}>
        {renderContent()}
      </StyledLinkContainer>
    )
  }

  return (
    <StyledContainer>
      {renderContent()}
    </StyledContainer>
  )
}

export default WidgetListRow
