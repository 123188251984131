import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { FEATURE_FLAGS } from 'constants/security'

import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'

import SettingsInvoicesItemDetailsV2View from './SettingsInvoicesItemDetailsV2View'

const NURSERY_INVOICE_SETTINGS_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings.invoice',
    'nurseryInvoiceSettings',
    'nurseryInvoiceSettings.numbers',
    'invoiceNumbers',
    'nurseryInvoiceSettings.paymentReminders',
    'nurseryInvoiceSettings.accountCodes',
    'accountCodes',
    'nurseryInvoiceSettings.annualisedInvoiceSettings',
    'annualisedInvoiceSettings',
    'nursery.organizationSettings',
    'organizationSettings',
    'organizationSettings.invoice',
    'organizationInvoiceSettings',
    'nursery.bankDetails',
  ],
}

class SettingsInvoicesItemDetailsV2Container extends Component {
  componentDidMount() {
    const {
      nurseriesActions,
      params,
    } = this.props
    const { nurseryId } = params

    const nurseryApiParams = { groups: NURSERY_INVOICE_SETTINGS_GROUPS }

    nurseriesActions.get(nurseryId, {
      params: nurseryApiParams,
    })
  }

  componentWillUnmount() {
    const { nurseriesActions } = this.props

    nurseriesActions.clearSingle()
  }

  handleSubmit = (fields) => (successCallback) => {
    const {
      nurseriesActions,
      nurseriesSelectors,
      nurseriesSingleState,
      params,
    } = this.props
    const { nurseryId } = params

    const apiParams = { groups: NURSERY_INVOICE_SETTINGS_GROUPS }
    const payload = nurseriesSelectors.getPayload({
      fields,
      nurseriesSingleState,
    })

    nurseriesActions.update(nurseryId, {
      onSuccess: successCallback,
      params: apiParams,
      payload,
    })
  }

  render() {
    const {
      authAccessMap,
      bankDetails,
      errorMessages,
      isEditDisabled,
      isFetching,
      isGlobalEnabled,
      nurseryInvoiceNumberSettings,
      nurseryInvoiceSettings,
      nurseryName,
      openPeriodData,
      organizationInvoiceSettings,
      params,
    } = this.props

    const { openWeeks } = openPeriodData || {}
    const hideGlobalOption = !isGlobalEnabled
    const { nurseryId } = params

    return (
      <SettingsInvoicesItemDetailsV2View
        authAccessMap={authAccessMap}
        bankDetails={bankDetails}
        disableEdit={isEditDisabled}
        errorMessages={errorMessages}
        hideGlobalOption={hideGlobalOption}
        invoiceNumberSettings={nurseryInvoiceNumberSettings}
        invoiceSettings={nurseryInvoiceSettings}
        isLoading={isFetching}
        nurseryId={nurseryId}
        nurseryName={nurseryName}
        openWeeks={openWeeks}
        orgInvoicesSettings={organizationInvoiceSettings}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseriesSelectors,
  nurseriesSingleState,
}) => ({
  authAccessMap: {
    section: {
      FinanceAutomation: auth.SELECTORS.getIsAuthorised(state, {
        flags: [FEATURE_FLAGS.FINANCE_AUTOMATION],
      }),
    },
  },
  bankDetails: nurseriesSelectors.getBankDetails(state),
  errorMessages: appSelectors.getErrorMessages(nurseriesSingleState),
  isEditDisabled: nurseriesSelectors.isGlobalSettingEditable(state),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState),
  isGlobalEnabled: nurseriesSelectors.isGlobalSettingCustomizable(state),
  nurseryInvoiceNumberSettings: nurseriesSelectors.getNurseryInvoiceNumberWithDescriptions(state),
  nurseryInvoiceSettings: nurseriesSelectors.getNurseryInvoiceSettings(state),
  nurseryName: nurseriesSelectors.getNurseryName(state),
  openPeriodData: nurseriesSelectors.getNurseryOpenPeriod(state),
  organizationInvoiceSettings: nurseriesSelectors.getOrganizationInvoiceSettings(state),
})

const enhance = compose(
  withAppService,
  withNurseriesService,
  connect(mapState),
)

export default enhance(SettingsInvoicesItemDetailsV2Container)
