import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'

import { FUNDING_STATUS_FILTERS } from '../constants'

export const getNurseryFundingV3ListState = (state: RootState) => state.nurseryFundingV3.list

export const getNurseryFundingV3ListDataState = createSelector(
  [getNurseryFundingV3ListState], (list) => list?.data,
)

export const getCriteria = (filters) => {
  const { status } = filters
  const criteria = []

  if (status && status !== FUNDING_STATUS_FILTERS.ALL) {
    criteria.push({
      field: 'archived',
      value: +(status === FUNDING_STATUS_FILTERS.ARCHIVED),
    })
  }

  return criteria
}
