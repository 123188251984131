import { LIKE_TYPE } from 'services/likes/constants'

import { typeByObject } from 'utils/typescript'

export enum AuthorType {
  DEACTIVATED = 'notFound',
  NURSERY = 'nursery',
  SUPER_ADMIN = 'superAdmin'
}

export interface Like {
  author?: {
    fullName: string
    id: number
    photo: string
    type: AuthorType
  }
  createdAt: Date
  id: number
  objectId: number
  type: typeByObject<typeof LIKE_TYPE>
}
