import ServiceBase from 'services/ServiceBase'

import { getQueryString } from 'utils/api'

class registerApiClient extends ServiceBase {
  list = (params) => this.request({
    path: `/children/registers?${getQueryString(params)}`,
  })

  listByChild = (childId, params) => this.request({
    path: `/children/${childId}/registers?${getQueryString(params)}`,
  })

  create = (childId, payload) => this.request({
    body: payload,
    method: 'POST',
    path: `/children/${childId}/registers`,
  })

  update = (childId, id, payload) => this.request({
    body: payload,
    method: 'PATCH',
    path: `/children/${childId}/registers/${id}`,
  })

  delete = (childId, id) => this.request({
    method: 'DELETE',
    path: `/children/${childId}/registers/${id}`,
  })

  download = (params) => this.request({
    download: true,
    path: `/children/registers/download?${getQueryString(params)}`,
  })
}

export default registerApiClient
