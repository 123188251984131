import _ from 'lodash'
import { flatten, nest } from 'utils/flatnest'

import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'
import { LIST_STATUS_FILTERS, LIST_STATUS_FILTERS_DROPDOWN } from 'constants/filters'

import { withAppService, withAppServiceProps } from 'services/app'
import {
  withNurseryConsumablesV3Service,
  withNurseryConsumablesV3ServiceProps,
} from 'services/product/nurseryConsumablesV3'
import { withPaginationUtils, withPaginationUtilsProps } from 'services/utils/pagination'
import { withRouterProps } from 'services/router'

import ManagementConsumablesV3View from './ManagementConsumablesV3View'
import { getNurseryConsumablesTableData } from './ManagementConsumablesV3Helpers'

const CONSUMABLES_GROUP = {
  read: [
    'nurseryConsumable',
    'nurseryConsumable.settings',
  ],
}

type ManagementConsumablesV3ContainerProps = withAppServiceProps
  & withNurseryConsumablesV3ServiceProps
  & withPaginationUtilsProps
  & withRouterProps

const mapState = (state: RootState, {
  appSelectors,
  nurseryConsumablesV3ListState,
  nurseryConsumablesV3Selectors,
}: ManagementConsumablesV3ContainerProps) => ({
  errorMessages: appSelectors.getErrorMessages(nurseryConsumablesV3ListState),
  isFetching: appSelectors.getIsFetching(nurseryConsumablesV3ListState),
  records: nurseryConsumablesV3Selectors.getNurseryConsumablesListSelector(state),
  totalResults: appSelectors.getTotalResults(nurseryConsumablesV3ListState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const ManagementConsumablesV3Container: React.FC<ManagementConsumablesV3ContainerProps & PropsFromRedux> = ({
  errorMessages,
  isFetching,
  location,
  nurseryConsumablesV3Actions,
  nurseryConsumablesV3Selectors,
  paginationUtils,
  records,
  setLocationQuery,
  totalResults,
}) => {
  const [active, setActive] = useState(() => {
    const { query } = location
    const { active: activeQuery } = nest(query)

    return _.find(LIST_STATUS_FILTERS_DROPDOWN, { value: activeQuery || LIST_STATUS_FILTERS.ACTIVE })
  })

  const { getPaginationDetails, onPageChange, page } = paginationUtils
  const tableData = getNurseryConsumablesTableData(records)

  useEffect(() => {
    const criteria = nurseryConsumablesV3Selectors.getCriteria({ active })

    setLocationQuery(flatten({ active: active.value }))

    nurseryConsumablesV3Actions.list({
      params: {
        criteria,
        groups: CONSUMABLES_GROUP,
        page,
      },
    })

    return () => {
      nurseryConsumablesV3Actions.clearList()
    }
  }, [active, page])

  const handleActiveChange = (newActive) => {
    setActive(newActive)
    onPageChange()(1)
  }

  return (
    <ManagementConsumablesV3View
      active={active}
      activeOptions={LIST_STATUS_FILTERS_DROPDOWN}
      errorMessages={errorMessages}
      isLoading={isFetching}
      pagination={getPaginationDetails(totalResults)}
      tableData={tableData}
      onActiveFilterChange={handleActiveChange}
    />
  )
}

const enhance = compose(
  withAppService,
  withNurseryConsumablesV3Service,
  withPaginationUtils,
  connector,
)

export default enhance(ManagementConsumablesV3Container)
