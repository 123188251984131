import React, { useEffect, useMemo, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'
import { flatten, nest } from 'utils/flatnest'

import { ROLES } from 'constants/security'

import { WrappedComponentType } from 'constants/types'
import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { RootState } from 'core/reducers'
import { ModalType } from 'modals'

import { withAppService, withAppServiceProps } from 'services/app'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withNurseryIntegrationsService, withNurseryIntegrationsServiceProps } from 'services/nurseryIntegrations'
import { withSecurityService, withSecurityServiceProps } from 'services/security'
import { withRouteUtilsProps, withRouterUtils } from 'services/utils/router'
import { withRouter, withRouterProps } from 'services/router'

import i18n from 'translations'

import { getTableData } from './FailedRecurringPaymentHelpers'
import FailedRecurringPaymentView from './FailedRecurringPaymentView'

const FAILED_RECURRING_PAYMENT_NURSERY_LEVEL_GROUPS = {
  read: [
    'failedRecurringPaymentRun.child',
    'child',
  ],
}

const FAILED_RECURRING_PAYMENT_GROUP_LEVEL_GROUPS = {
  read: [
    ...FAILED_RECURRING_PAYMENT_NURSERY_LEVEL_GROUPS.read,
    'child.nursery',
    'nursery',
  ],
}

type FailedRecurringPaymentProps = withAppServiceProps
  & withModalServiceProps
  & withNurseryIntegrationsServiceProps
  & withSecurityServiceProps
  & withRouteUtilsProps
  & withRouterProps

const mapState = (state: RootState, {
  appSelectors,
  nurseryIntegrationsFailedRecurringPaymentState,
  nurseryIntegrationsSelectors,
  securitySelectors,
}: FailedRecurringPaymentProps) => ({
  errorMessages: appSelectors.getErrorMessages(nurseryIntegrationsFailedRecurringPaymentState),
  failedRecurringPaymentData: nurseryIntegrationsSelectors.getFailedRecurringPayment(state),
  failedRecurringPaymentHasMore:
    nurseryIntegrationsSelectors.getFailedRecurringPaymentHasMore(state),
  failedRecurringPaymentNextPageId:
    nurseryIntegrationsSelectors.getFailedRecurringPaymentNextPageId(state),
  failedRecurringPaymentTotalResults: appSelectors.getTotalResults(nurseryIntegrationsFailedRecurringPaymentState),
  isFetching: appSelectors.getIsFetching(nurseryIntegrationsFailedRecurringPaymentState),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

export type OptionProps = {
  label: string
  value: string | number
} | string | any

const FailedRecurringPaymentContainer: WrappedComponentType<FailedRecurringPaymentProps & PropsFromRedux> = ({
  errorMessages,
  failedRecurringPaymentData,
  failedRecurringPaymentHasMore,
  failedRecurringPaymentNextPageId,
  failedRecurringPaymentTotalResults,
  isFetching,
  isOrganizationContext,
  location,
  modalActions,
  nurseryIntegrationsActions,
  nurseryIntegrationsSelectors,
  setLocationQuery,
}) => {
  const [date, setDate] = useState<string[] | null>(() => {
    const { query = {} } = location
    const { date: queryDate } = nest(query)

    if (queryDate?.after || queryDate?.before) {
      return [queryDate?.after, queryDate?.before]
    }

    return undefined
  })
  const [room, setRoom] = useState<OptionProps>(() => {
    const { query = {} } = location
    const { room: queryRoom } = nest(query)

    return queryRoom
  })
  const [paymentMethod, setPaymentMethod] = useState<OptionProps>(() => {
    const { query = {} } = location
    const { paymentMethod: queryPaymentMethod } = nest(query)

    return queryPaymentMethod
  })
  const [nursery, setNursery] = useState<OptionProps>(() => {
    const { query = {} } = location
    const { nursery: queryNursery } = nest(query)

    return queryNursery
  })

  const tableData = useMemo(
    () => getTableData({
      data: failedRecurringPaymentData,
      hasError: !!errorMessages,
      isOrganizationContext,
    }),
    [failedRecurringPaymentData, errorMessages],
  )

  const fetchData = (isInitialized) => {
    if (!isInitialized) {
      nurseryIntegrationsActions.clearFailedRecurringPayment()
    }

    setLocationQuery(flatten({
      date: {
        after: date?.[0],
        before: date?.[1],
      },
      nursery: nursery?.value || nursery,
      paymentMethod: paymentMethod?.value || paymentMethod,
      room: room?.value || room,
    }))

    const criteria = nurseryIntegrationsSelectors.getFailedRecurringPaymentCriteria({
      dateRange: date,
      nursery,
      paymentMethod,
      room,
    })

    nurseryIntegrationsActions.getFailedRecurringPayment({
      mergeResult: isInitialized,
      params: {
        criteria,
        groups: isOrganizationContext
          ? FAILED_RECURRING_PAYMENT_GROUP_LEVEL_GROUPS
          : FAILED_RECURRING_PAYMENT_NURSERY_LEVEL_GROUPS,
        nextPage: isInitialized ? failedRecurringPaymentNextPageId : null,
      },
    })
  }

  const handlePageChange = () => fetchData(true)

  useEffect(() => {
    fetchData(false)

    return () => {
      nurseryIntegrationsActions.clearFailedRecurringPayment()
    }
  }, [date, room, paymentMethod, nursery])

  const handleExportModalClick = (body, onSuccess, onFailed) => {
    const criteria = nurseryIntegrationsSelectors.getFailedRecurringPaymentCriteria({
      dateRange: date,
      nursery,
      paymentMethod,
      room,
    })

    nurseryIntegrationsActions.exportFailedRecurringPayment({
      onFailed,
      onSuccess,
      params: { ...body, criteria: [...body.criteria, ...criteria] },
    })
  }

  const handleExportClick = () => {
    modalActions.show<ModalType.EXPORT_REPORT>(ModalType.EXPORT_REPORT, {
      onSendReport: handleExportModalClick,
      title: i18n.t('module:Finance:Reports:FailedRecurringPayment:Export:title'),
      userLabel: i18n.t('module:Modals:ExportReportModal:User:Label:export'),
    })
  }

  const handleDateChange = ([after, before]) => {
    setDate([after?.format(DEFAULT_DATE_FORMAT), before?.format(DEFAULT_DATE_FORMAT)])
  }

  return (
    <FailedRecurringPaymentView
      date={date}
      errorMessages={errorMessages}
      hasMoreRecords={failedRecurringPaymentHasMore}
      isLoading={isFetching}
      isOrganizationContext={isOrganizationContext}
      nursery={nursery}
      paymentMethod={paymentMethod}
      room={room}
      tableData={tableData}
      title={i18n.t('module:Finance:Reports:FailedRecurringPayment:title')}
      totalResults={failedRecurringPaymentTotalResults}
      onDateChange={handleDateChange}
      onExportClick={handleExportClick}
      onNurseryChange={setNursery}
      onPageChange={handlePageChange}
      onPaymentMethodChange={setPaymentMethod}
      onRoomChange={setRoom}
    />
  )
}

FailedRecurringPaymentContainer.authParams = {
  antiRoles: [
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
  ],
}

const enhance = compose(
  withRouter,
  withRouterUtils,
  withAppService,
  withNurseryIntegrationsService,
  withModalService,
  withSecurityService,
  connector,
)

export default enhance(FailedRecurringPaymentContainer)

