import moment from 'moment'

import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'
import { ChildNote, EditChildNoteFormValues } from 'services/childNotes/models'

const getChildNotesState = (state: RootState) => state?.childNotes

export const getChildNote = createSelector([getChildNotesState], (state) => state?.single?.data)

export const getChildNoteInitialValues = createSelector([getChildNote], (note: ChildNote) => {
  const values = {} as EditChildNoteFormValues
  const { content, date, title } = note || {}

  if (content) {
    values.content = content
  }

  if (date) {
    values.date = date
  } else {
    values.date = moment()
  }

  if (title) {
    values.title = title
  }

  return values
})

export const getChildNoteValuesForm = (values: EditChildNoteFormValues) => {
  const { content, date, title } = values || {}
  const body = {
    title: title || '',
  } as any

  if (content) {
    body.content = content
  }

  if (date) {
    body.date = moment(date).toDate()
  }

  return body
}
