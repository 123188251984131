import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { NEWS_STATUSES, TAB_NAMES } from 'services/legacy/newsletters/constants'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withNewslettersService } from 'services/legacy/newsletters'
import { withNewsletterRecipientsService } from 'services/legacy/newsletterRecipients'
import { withModalService } from 'services/utils/modal'
import { withRouterUtils } from 'services/utils/router'
import { withPaginationUtils } from 'services/utils/pagination'
import { withSortingUtils } from 'services/utils/sorting'
import { withSnackbarService } from 'services/utils/snackbar'
import { getAuthNursery } from 'services/security/selectors'
import { withRouter } from 'services/router'

import i18n from 'translations'

import PreviewNewsletterView from './PreviewNewsletterView'

const NEWSLETTERS_SINGLE_GROUPS = {
  read: [
    'medium',
    'newsletter.filters',
    'newsletter.media',
    'newsletter.author',
    'user',
  ],
}

const NEWSLETTER_RECIPIENTS_GROUPS = {
  read: ['user', 'user.details'],
}

class PreviewNewsletterContainer extends Component {
  constructor(props) {
    super(props)

    const { location, paginationUtils } = props
    const { query } = location
    const { tab } = query
    const { setPageLocationQuery } = paginationUtils

    this.state = {
      search: null,
      tab: tab || TAB_NAMES.OVERVIEW,
    }

    setPageLocationQuery(false)
  }

  componentDidMount() {
    const {
      newsActions,
      params,
      router,
    } = this.props
    const { tab } = this.state
    const { newsId } = params

    newsActions.get(newsId, { groups: NEWSLETTERS_SINGLE_GROUPS }, this.handleGetNewsSuccess)

    if (TAB_NAMES.RECIPIENTS === tab) {
      this.fetchRecipients()
    }

    router.replace(generateRoute('NEWSLETTERS.PREVIEW', { newsId }))
  }

  componentWillUnmount() {
    const { newsActions, newsletterRecipientsActions } = this.props

    newsActions.clearSingle()
    newsletterRecipientsActions.clear()
  }

  handleGetNewsSuccess = ({ data: news }) => {
    const { navigate, params } = this.props
    const { status } = news
    const { newsId } = params

    if (NEWS_STATUSES.DRAFT === status) {
      navigate(generateRoute('NEWSLETTERS.EDIT', { newsId }))
    }
  }

  fetchRecipients = () => {
    const {
      newsletterRecipientsActions,
      newsletterRecipientsSelectors,
      paginationUtils,
      params,
      sortingUtils,
    } = this.props
    const { search } = this.state
    const { newsId } = params
    const { page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const newsletterRecipientsCriteria = newsletterRecipientsSelectors.getCriteriaSelector({ newsId, search })

    const apiParams = {
      criteria: newsletterRecipientsCriteria,
      groups: NEWSLETTER_RECIPIENTS_GROUPS,
      order: { sortField, sortOrder },
      page,
    }

    newsletterRecipientsActions.list(apiParams, 1 !== page)
  }

  handleDeleteSuccess = () => {
    const { navigate } = this.props

    navigate(generateRoute('NEWSLETTERS.INDEX'))
  }

  delete = () => {
    const { newsActions, params } = this.props
    const { newsId } = params

    const body = { archived: true }

    return newsActions.update(newsId, body, { groups: NEWSLETTERS_SINGLE_GROUPS }, this.handleDeleteSuccess)
  }

  handleRemoveClick = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'trash',
      onConfirm: this.delete,
      text: i18n.t('module:Newsletters:Preview:deleteText'),
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetchRecipients)(page)
  }

  handleSortChange = (key) => {
    const { sortingUtils } = this.props
    const { onSortChange } = sortingUtils

    onSortChange(this.handlePageChange(1))(key)
  }

  handleSearchChange = (search) => {
    this.setState({ search }, () => this.handlePageChange(1))
  }

  handleResendClick = (recipientId) => {
    const { newsletterRecipientsActions } = this.props

    newsletterRecipientsActions.resend(recipientId)
  }

  removeItem = (recipientId) => {
    const { newsletterRecipientsActions } = this.props

    newsletterRecipientsActions.remove(recipientId)
  }

  handleRemoveItemClick = (recipientId, name) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('global:Remove'),
      icon: 'trash',
      onConfirm: () => this.removeItem(recipientId),
      text: i18n.t('module:Newsletters:Preview:deleteRecipientText', { name }),
    })
  }

  handleAddNewRecipientsSuccess = () => {
    const { snackbarActions } = this.props

    this.handlePageChange(1)

    snackbarActions.show({
      message: i18n.t('module:Newsletters:Preview:recipientAddSuccessText'),
    })
  }

  handleAddNewRecipients = () => {
    const { modalActions, modalConsts, params } = this.props
    const { newsId } = params

    modalActions.show(modalConsts.TYPES.ADD_RECIPIENTS, { newsId, onSuccess: this.handleAddNewRecipientsSuccess })
  }

  handleChangeTab = (newValue) => {
    const { recipientsTableData } = this.props

    if (TAB_NAMES.RECIPIENTS === newValue && !recipientsTableData?.length) {
      this.fetchRecipients()
    }

    this.setState({ tab: newValue })
  }

  handleGoToRecipients = () => {
    const { recipientsTableData } = this.props

    if (!recipientsTableData?.length) {
      this.fetchRecipients()
    }

    this.setState({ tab: TAB_NAMES.RECIPIENTS })
  }

  render() {
    const {
      isFetching,
      isTableDataFetching,
      nursery,
      paginationUtils,
      previewData,
      recipientsTableData,
      recipientsTableTotalResults,
      sortingUtils,
    } = this.props
    const { getPageCount, page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils
    const { tab } = this.state

    const pageCount = getPageCount(recipientsTableTotalResults)

    return (
      <PreviewNewsletterView
        isFetching={isFetching}
        isTableDataFetching={isTableDataFetching}
        nursery={nursery}
        page={page}
        pageCount={pageCount}
        previewData={previewData}
        recipientsTableData={recipientsTableData}
        recipientsTableTotalResults={recipientsTableTotalResults}
        sortField={sortField}
        sortOrder={sortOrder}
        tab={tab}
        onAddNewRecipients={this.handleAddNewRecipients}
        onChangeTab={this.handleChangeTab}
        onGoToRecipients={this.handleGoToRecipients}
        onPageChange={this.handlePageChange}
        onRemoveClick={this.handleRemoveClick}
        onRemoveItemClick={this.handleRemoveItemClick}
        onResendClick={this.handleResendClick}
        onSearchChange={this.handleSearchChange}
        onSortChange={this.handleSortChange}
      />
    )
  }
}

const mapState = (
  state,
  { appSelectors, newsSelectors, newsSingleState, newsletterRecipientsListState, newsletterRecipientsSelectors },
) => ({
  isFetching: appSelectors.getIsFetching(newsSingleState),
  isTableDataFetching: appSelectors.getIsFetching(newsletterRecipientsListState),
  nursery: getAuthNursery(state),
  previewData: newsSelectors.getSingleNewsPreviewDataSelectors(state),
  recipientsTableData: newsletterRecipientsSelectors.getNewsRecipientsTableListSelector(state),
  recipientsTableTotalResults: newsletterRecipientsSelectors.getNewsRecipientsTableTotalResultsSelector(state),
})

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withSnackbarService,
  withPaginationUtils,
  withSortingUtils,
  withRouterUtils,
  withNewslettersService,
  withNewsletterRecipientsService,
  connect(mapState),
)

export default enhance(PreviewNewsletterContainer)
