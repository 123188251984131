import React, { PropsWithChildren } from 'react'

import MinimalModalBoxTopIcon from './MinimalModalBoxTopIcon'
import { StyledWrapper } from './MinimalModalBoxStyled'

interface MinimalModalBoxRoot extends React.FC<PropsWithChildren<any>> {
  TopIcon?: typeof MinimalModalBoxTopIcon
}

const MinimalModalBox: MinimalModalBoxRoot = ({ children }) => (
  <StyledWrapper>
    {children}
  </StyledWrapper>
)

export default MinimalModalBox
