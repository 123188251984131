import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

const CIRCLE_WIDTH = '33px'
const margin = '15px'

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  &:before {
    content: counter(numbered-content);
    margin-right: ${margin};
    width: ${CIRCLE_WIDTH};
    min-width: ${CIRCLE_WIDTH};
    height: ${CIRCLE_WIDTH};
    min-height: ${CIRCLE_WIDTH};
    border-radius: 50%;
    background: ${NEUTRAL_COLOURS.GRAY_SECONDARY};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }
`

export const StyledContent = styled.div`
  position: relative;
  padding: 5px 0 30px calc(${CIRCLE_WIDTH} + ${margin});

  &:before {
    position: absolute;
    left: 15px;
    top: 0;
    bottom: 0;
    width: 3px;
    z-index: 5;
    content: '';
    background: ${NEUTRAL_COLOURS.GRAY_SECONDARY};
  }
`

export const StyledContainer = styled.div`
  margin-bottom: 5px;
  counter-increment: numbered-content;
`

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  counter-reset: numbered-content;

  & ${StyledContainer}:last-child > ${StyledContent}:before {
    display: none;
  }
`
