import { combineReducers } from 'redux'

import list, { UsersListState } from './list/reducer'
import single, { UsersSingleState } from './single/reducer'
import settings, { UsersSettingsState } from './settings/reducer'

export default combineReducers({
  list,
  settings,
  single,
})

export interface UsersState {
  list: UsersListState
  settings: UsersSettingsState
  single: UsersSingleState
}
