import React, { useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'
import { getFormValues } from 'redux-form'

import { BasicModalProps } from 'modals'
import { Child } from 'services/child/models'
import { ITEM_STATUS_FILTERS } from 'services/product/nurseryItem/constants'
// eslint-disable-next-line max-len, import-newlines/enforce
import { RegularBookingsAddFormValues } from 'module/Children/Child/ChildBookingPattern/RegularBookings/RegularBookingsAdd/components/RegularBookingsAddForm/RegularBookingsAddForm'
import { ROLES } from 'constants/security'

import auth from 'utils/auth'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withNurseryItemService, withNurseryItemServiceProps } from 'services/product/nurseryItem'
import { withChildService, withChildServiceProps } from 'services/legacy/child'
import { withChildBookingService, withChildBookingServiceProps } from 'services/booking/childBooking'
import { withNurseryContextService, withNurseryContextServiceProps } from 'services/nurseryContext'

import AddRegularItemsToRegularBookingsModalView from './AddRegularItemsToRegularBookingsModalView'
import {
  ADD_REGULAR_ITEMS_TO_REGULAR_BOOKINGS_MODAL_FORM,
  AddRegularItemsToRegularBookingsModalFormValues,
} from './components/AddRegularItemsToRegularBookingsModalForm'

const NURSERY_FUNDING_GROUPS = {
  read: [
    'productPrice',
    'productPriceChange',
    'productPriceChange.prices',
    'nurseryItemProduct',
    'nurseryItemProduct.priceChanges',
  ],
}

export interface AddRegularItemsToRegularBookingsModalProps {
  alternate: number
  child: Child
  onSuccess: (values: AddRegularItemsToRegularBookingsModalFormValues, price: number) => void
  regularBookingsFormValues: RegularBookingsAddFormValues
}

const mapState = (state, { nurseryContextSelectors }) => ({
  formValues: getFormValues(
    ADD_REGULAR_ITEMS_TO_REGULAR_BOOKINGS_MODAL_FORM,
  )(state) as AddRegularItemsToRegularBookingsModalFormValues,
  hasAccessToViewPrice: auth.SELECTORS.getIsAuthorised(state, {
    roles: [
      ROLES.ORGANIZATION_DIRECTOR,
      ROLES.ORGANIZATION_NATIONAL_ADMIN,
      ROLES.ORGANIZATION_FINANCE_ADMIN,
      ROLES.ORGANIZATION_LINE_MANAGER,
      ROLES.NURSERY_MANAGER,
      ROLES.NURSERY_ADMIN,
      ROLES.SUPER_ADMIN,
    ],
  }),
  nurseryOpeningDays: nurseryContextSelectors.getNurseryOpeningDays(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type AddRegularItemsToRegularBookingsModalContainerFullProps = PropsFromRedux
  & AddRegularItemsToRegularBookingsModalProps
  & BasicModalProps
  & withChildServiceProps
  & withChildBookingServiceProps
  & withModalServiceProps
  & withNurseryItemServiceProps
  & withNurseryContextServiceProps

const AddRegularItemsToRegularBookingsModalContainer: React.FC<
  AddRegularItemsToRegularBookingsModalContainerFullProps
> = ({
  alternate,
  child,
  childActions,
  childBookingSelectors,
  formValues,
  hasAccessToViewPrice,
  hideModal,
  nurseryItemActions,
  nurseryItemSelectors,
  nurseryOpeningDays,
  onSuccess,
  regularBookingsFormValues,
}) => {
  const [initialValues, setInitialValues] = useState<AddRegularItemsToRegularBookingsModalFormValues>(null)
  const [price, setPrice] = useState<number>(null)
  const [isFetchingPrice, setIsFetchingPrice] = useState<boolean>(false)

  useEffect(() => {
    nurseryItemActions.list({
      onSuccess: ({ data }) => {
        if (data?.length) {
          setInitialValues({
            item: {
              label: data[0].name,
              priceChanges: data[0].priceChanges,
              value: data[0].id,
            },
            quantity: 1,
          })
        } else {
          setInitialValues({
            quantity: 1,
          })
        }
      },
      onlyData: true,
      params: {
        criteria: nurseryItemSelectors.getCriteria({ status: ITEM_STATUS_FILTERS.ACTIVE }),
        groups: NURSERY_FUNDING_GROUPS,
        limit: 1,
      },
    })
  }, [])

  useEffect(() => {
    if (
      !regularBookingsFormValues.startDate
      || !formValues
      || !formValues?.item
      || !hasAccessToViewPrice
    ) {
      setPrice(null)

      return
    }

    setIsFetchingPrice(true)

    const body = childBookingSelectors.getRegularItemBodyForPriceCalculator({
      alternate,
      date: regularBookingsFormValues.startDate,
      formValues,
    })

    childActions.getProductPriceCalculator({
      body,
      onFailed: () => {
        setPrice(null)
        setIsFetchingPrice(false)
      },
      onSuccess: (response) => {
        const { data } = response
        const { value } = data

        setPrice(value)
        setIsFetchingPrice(false)
      },
      params: [child.id, {}],
    })
  }, [
    formValues?.quantity,
    formValues?.item,
  ])

  const handleCloseClick = () => {
    hideModal()
  }

  const handleSubmit = (values) => {
    onSuccess(values, price)
    hideModal()
  }

  return (
    <AddRegularItemsToRegularBookingsModalView
      hasAccessToViewPrice={hasAccessToViewPrice}
      initialValues={initialValues}
      isFetchingPrice={isFetchingPrice}
      nurseryOpeningDays={nurseryOpeningDays}
      price={price}
      onCloseClick={handleCloseClick}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withChildService,
  withChildBookingService,
  withModalService,
  withNurseryItemService,
  withNurseryContextService,
  connector,
)

export default enhance(AddRegularItemsToRegularBookingsModalContainer)
