import styled from 'styled-components'

import layout, { ZINDEX_ORDER } from 'constants/layout'

import { StyledButtonWrapper } from 'components/Button/ButtonStyled'

interface StyledFooterContainerProps {
  $elementHeight?: boolean
}

export const StyledFooterContainer = styled.div<StyledFooterContainerProps>`
  ${({ $elementHeight }) => ($elementHeight ? `
    height: ${$elementHeight}px;
  ` : `
    padding: 20px;
  `)}

  @media(max-width: ${layout.mobileWidthResolution}px) {
    padding: 50px;
  }
`

export const StyledStickyFooter = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #E6E6E6;
  padding: 10px;
  z-index: ${ZINDEX_ORDER.STICKY_FOOTER};
  
  & > div > a, button, ${StyledButtonWrapper} {
    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  @media(max-width: ${layout.mobileWidthResolution}px) {
    & > div,
    & > div > div {
      flex-direction: column;
      display: grid;
      grid-row-gap: 8px;
    }

    & > div > a, button, ${StyledButtonWrapper} {
      margin-right: 0;
    }
  }
`

export const StyledFlex = styled.div`
  flex: 1;
`
