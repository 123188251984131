import styled from 'styled-components'

import colors from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledAuthor = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin: 11px 0;
`

export const StyledStar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  
  & > div {
    transform: translateY(-2px);
  }
`

export const StyledGoldenMoment = styled.span`
  margin-left: 7px;
  display: inline-block;
  font-weight: 600;
  color: ${colors.gold};
`

export const StyledLearningJourneyButton = styled.div`
  a {
    display: flex;
    align-items: center;
  }
`

export const StyledLearningJourneyLabel = styled.span`
  margin-left: 10px;
  color: ${getBrandingColor('primary-color')};
`
