import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

import { StyledLoader } from 'components/InfiniteScroll/InfiniteScrollStyled'

export const StyledEmptyState = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  & > div {
    padding: 0;
  }
`

export const StyledIcon = styled.div`
  margin-right: 10px;
`

export const StyledContent = styled.div`
  & > div {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`

export const StyledHeader = styled.div`
  margin: -10px -10px 10px;
  padding: 10px 0;

  ${({ reverse }) => reverse && `
    background-color: ${getBrandingColor('senary-color')};
  `}
`

export const StyledWrapper = styled.div`
  ${StyledLoader} {
    margin-top: 15px;
  }
`
