import React from 'react'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import invoicesConstants from 'services/legacy/invoices/constants'

import { isRequired } from 'utils/fieldValidation'

import { Currency, Form, Typography } from 'components'

import i18n from 'translations'

export const SEND_INVOICE_MODAL_FORM_NAME = 'SendDraftInvoiceModalForm'

const SendDraftInvoiceModalForm = ({
  balance,
  childFirstName,
  handleSubmit,
  invoiceNumberOption,
  isSubmitting,
  onCancelClick,
  onSubmit,
}) => {
  const isCustomSelected = invoicesConstants.SEND_INVOICE_NUMBER_TYPES.CUSTOM === invoiceNumberOption

  const renderSubHeader = (title) => (
    <Typography margin="0 0 15px" bold>
      {title}
    </Typography>
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {renderSubHeader(i18n.t('module:Modals:SendDraftInvoice:InvoiceNumber:title'))}
      <Form.Row>
        <Form.Row.Item>
          <Field
            component={Form.RadioGroup}
            name="invoiceNumberOption"
            options={invoicesConstants.SEND_INVOICE_NUMBER_OPTIONS}
            validate={isRequired}
          />
        </Form.Row.Item>
      </Form.Row>
      <Form.Row>
        <Form.Row.Item>
          <Field
            component={Form.TextField}
            disabled={!isCustomSelected}
            name="number"
            validate={isCustomSelected && isRequired}
          />
        </Form.Row.Item>
      </Form.Row>
      {renderSubHeader(i18n.t('module:Modals:SendDraftInvoice:DebitOrCredit:title'))}
      <Form.Row>
        <Typography>
          {i18n.t(
            `module:Modals:SendDraftInvoice:DebitOrCredit:${0 < balance
              ? 'creditTextPart1'
              : 'debitTextPart1'}`,
            { childFirstName },
          )}
          <Currency value={Math.abs(balance)} />
          {i18n.t('module:Modals:SendDraftInvoice:DebitOrCredit:textPart2')}
        </Typography>
      </Form.Row>
      {renderSubHeader(i18n.t('module:Modals:SendDraftInvoice:Recipients:title'))}
      <Form.Row>
        <Typography>
          {i18n.t('module:Modals:SendDraftInvoice:Recipients:subtitle', { childFirstName })}
        </Typography>
      </Form.Row>
      <Form.FooterActions
        isSubmitting={isSubmitting}
        submitLabel={i18n.t('module:Modals:SendDraftInvoice:confirmAndSendButtonLabel')}
        onCancelClick={onCancelClick}
      />
    </Form>
  )
}

const mapState = (state) => ({
  invoiceNumberOption: formValueSelector(SEND_INVOICE_MODAL_FORM_NAME)(state, 'invoiceNumberOption'),
})

const enhance = compose(
  connect(mapState),
)

export default reduxForm({ form: SEND_INVOICE_MODAL_FORM_NAME })(enhance(SendDraftInvoiceModalForm))
