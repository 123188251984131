import { createSelector } from 'reselect'

import { ChildrenSignedUpReportRootState } from '../models'

const getChildrenSignedUpReport = (state: ChildrenSignedUpReportRootState) => state.childrenSignedUpReport

export const getChildrenSignedUpReportNurseries = createSelector(
  [getChildrenSignedUpReport],
  (state) => state.nurseries,
)

export const getChildrenSignedUpReportNurseriesData = createSelector(
  [getChildrenSignedUpReportNurseries],
  (state) => state.data,
)
