import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withNurseriesService } from 'services/nurseries'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRequestedExtraSessionsService } from 'services/legacy/requestedExtraSessions'
import { withSortingUtils } from 'services/utils/sorting'

import i18n from 'translations'

import UnavailableTimesView from './UnavailableTimesView'
import { getExceptionalUnavailableTimesTableData, getRegularUnavailableTimesTableData } from './UnavailableTimesHelpers'

const GROUPS = {
  read: [
    'unavailableTime.nurseryClass',
    'nurseryClass',
    'unavailableTime.nursery',
    'nursery',
  ],
}

const NURSERY_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings',
    'nurserySettings.requestExtraSession',
    'requestExtraSessionSettings',
    'requestableSessionsSettings',
  ],
}

const DEFAULT_SORT = {
  sortField: 'date',
  sortOrder: 'ASC',
}

class UnavailableTimesContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openingDays: [],
    }

    const { paginationUtils: { setPageLocationQuery } } = props

    setPageLocationQuery(false)
  }

  componentDidMount() {
    const {
      nurseriesActions,
      nurseryOptions,
    } = this.props

    this.fetchExceptionalUnavailableTimes()
    this.fetchRegularUnavailableTimes()
    nurseriesActions.get(nurseryOptions.id, {
      onSuccess: ({ data }) => (
        this.setState({ openingDays: _.map(data.nurserySettings.openingDays, (item) => item.day) })
      ),
      params: { groups: NURSERY_GROUPS },
    })
  }

  componentWillUnmount() {
    const {
      nurseriesActions,
      requestedExtraSessionsActions,
    } = this.props

    nurseriesActions.clearSingle()
    requestedExtraSessionsActions.clearExceptionalUnavailableTimesList()
    requestedExtraSessionsActions.clearRegularUnavailableTimesList()
  }

  fetchRegularUnavailableTimes = () => {
    const { requestedExtraSessionsActions, requestedExtraSessionsSelectors } = this.props

    const criteria = requestedExtraSessionsSelectors.getRegularUnavailableTimesListCriteria()

    return requestedExtraSessionsActions.listRegularUnavailableTimesRecurrency({
      params: { criteria, groups: GROUPS },
    })
  }

  fetchExceptionalUnavailableTimes = () => {
    const {
      paginationUtils: { page },
      requestedExtraSessionsActions,
      requestedExtraSessionsSelectors,
      sortingUtils: { sortField, sortOrder },
    } = this.props

    const criteria = requestedExtraSessionsSelectors.getExceptionalUnavailableTimesListCriteria()

    const params = [{
      criteria,
      groups: GROUPS,
      order: {
        sortField: sortField || DEFAULT_SORT.sortField,
        sortOrder: sortField ? sortOrder : DEFAULT_SORT.sortOrder,
      },
      page,
    }]

    return requestedExtraSessionsActions.listExceptionalUnavailableTimes({
      mergeResult: 1 !== page,
      params,
    })
  }

  handleEditRegularUnavailableTimes = () => {
    const { modalActions, modalConsts } = this.props
    const { openingDays } = this.state

    modalActions.show(modalConsts.TYPES.REGULAR_UNAVAILABLE_TIMES, {
      onSuccess: this.fetchRegularUnavailableTimes,
      openingDays,
    })
  }

  handleAddExceptionalUnavailableTime = () => {
    const {
      modalActions,
      modalConsts,
      paginationUtils: { onPageChange },
      sortingUtils: { onSortChange },
    } = this.props

    modalActions.show(modalConsts.TYPES.EXCEPTIONAL_UNAVAILABLE_TIMES, {
      onSuccess: () => onSortChange(onPageChange(this.fetchExceptionalUnavailableTimes)(1)),
    })
  }

  handleEditExceptionalUnavailableTime = (id) => {
    const {
      exceptionalUnavailableTimes,
      modalActions,
      modalConsts,
      paginationUtils: { onPageChange },
      sortingUtils: { onSortChange },
    } = this.props

    modalActions.show(modalConsts.TYPES.EXCEPTIONAL_UNAVAILABLE_TIMES, {
      data: _.find(exceptionalUnavailableTimes, { id }),
      id,
      isEditMode: true,
      onSuccess: () => onSortChange(onPageChange(this.fetchExceptionalUnavailableTimes)(1)),
    })
  }

  handleDeleteExceptionalUnavailableTimeConfirm = (id) => {
    const {
      paginationUtils: { onPageChange },
      requestedExtraSessionsActions,
      sortingUtils: { onSortChange },
    } = this.props

    return requestedExtraSessionsActions.removeExceptionalUnavailableTime({
      onSuccess: () => onSortChange(onPageChange(this.fetchExceptionalUnavailableTimes)(1)),
      params: [id],
    })
  }

  handleDeleteExceptionalUnavailableTime = (id) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('global:Delete'),
      icon: 'trash',
      onConfirm: () => this.handleDeleteExceptionalUnavailableTimeConfirm(id),
      text: i18n.t('module:Management:ParentApp:RequestableExtraSessions:UnavailableTimes:Exceptional:deleteModal'),
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetchExceptionalUnavailableTimes)(page)
  }

  handleSortChange = (key) => {
    const { paginationUtils, sortingUtils } = this.props
    const { onSortChange } = sortingUtils
    const { onPageChange } = paginationUtils

    onSortChange(onPageChange(this.fetchExceptionalUnavailableTimes)(1))(key)
  }

  render() {
    const {
      errorMessages,
      exceptionalUnavailableTimes,
      isExceptionalUnavailableTimesFetching,
      isNurseryFetching,
      isRegularUnavailableTimesFetching,
      paginationUtils,
      regularUnavailableTimes,
      sortingUtils,
      totalResults,
    } = this.props
    const { openingDays } = this.state
    const { getPageCount, page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const pageCount = getPageCount(totalResults)
    const exceptionalUnavailableTimesTableData = getExceptionalUnavailableTimesTableData({
      data: exceptionalUnavailableTimes,
      onDeleteClick: this.handleDeleteExceptionalUnavailableTime,
      onEditClick: this.handleEditExceptionalUnavailableTime,
    })
    const regularUnavailableTimesTableData = getRegularUnavailableTimesTableData({
      data: regularUnavailableTimes,
      openingDays,
    })

    return (
      <UnavailableTimesView
        errorMessages={errorMessages}
        exceptionalUnavailableTimes={exceptionalUnavailableTimesTableData}
        isExceptionalUnavailableTimesFetching={isExceptionalUnavailableTimesFetching}
        isNurseryFetching={isNurseryFetching}
        isRegularUnavailableTimesFetching={isRegularUnavailableTimesFetching}
        openingDays={openingDays}
        page={page}
        pageCount={pageCount}
        regularUnavailableTimes={regularUnavailableTimesTableData}
        sortField={sortField}
        sortOrder={sortOrder}
        onAddExceptionalUnavailableTime={this.handleAddExceptionalUnavailableTime}
        onEditRegularUnavailableTimes={this.handleEditRegularUnavailableTimes}
        onPageChange={this.handlePageChange}
        onSortChange={this.handleSortChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  exceptionalUnavailableTimesListState,
  nurseriesSingleState,
  params,
  regularUnavailableTimesListState,
  requestedExtraSessionsSelectors,
}) => ({
  errorMessages: appSelectors.getErrorMessages(
    exceptionalUnavailableTimesListState,
    nurseriesSingleState,
    regularUnavailableTimesListState,
  ),
  exceptionalUnavailableTimes: requestedExtraSessionsSelectors.getExceptionalUnavailableTimesListData(state),
  isExceptionalUnavailableTimesFetching: appSelectors.getIsFetching(exceptionalUnavailableTimesListState),
  isNurseryFetching: appSelectors.getIsFetching(nurseriesSingleState),
  isRegularUnavailableTimesFetching: appSelectors.getIsFetching(regularUnavailableTimesListState),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  regularUnavailableTimes: requestedExtraSessionsSelectors.getRegularUnavailableTimesListData(state),
  totalResults: appSelectors.getTotalResults(exceptionalUnavailableTimesListState),
})

const enhance = compose(
  withAppService,
  withModalService,
  withNurseriesService,
  withPaginationUtils,
  withRequestedExtraSessionsService,
  withSortingUtils,
  connect(mapState),
)

export default enhance(UnavailableTimesContainer)
