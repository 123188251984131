import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_CHILD_CARER_RELATION,
  CREATE_CHILD_CARER_RELATION,
  CREATE_CHILD_CARER_RELATION_FAILED,
  CREATE_CHILD_CARER_RELATION_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_CHILD_CARER_RELATION,
    createFailed: CREATE_CHILD_CARER_RELATION_FAILED,
    createInit: CREATE_CHILD_CARER_RELATION,
    createSuccess: CREATE_CHILD_CARER_RELATION_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
