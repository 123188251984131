import _ from 'lodash'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { ROLES } from 'constants/security'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withAgedDebtService } from 'services/legacy/agedDebt'
import { withNurseriesService } from 'services/nurseries'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withSortingUtils } from 'services/utils/sorting'
import { withSecurityService } from 'services/security'
import { withRouter } from 'services/router'

import AgedDebtView from './AgedDebtView'
import { getChart, getGroupTableData } from './AgedDebtHelpers'

const CHILD_AGED_DEBT_REPORT_GROUPS = {
  read: [
    'childAgedDebtStatistics',
    'child.finance',
  ],
}

const NURSERIES_GROUPS = {
  read: [
    'nurseryAgedDebtStatistics',
    'childAgedDebtStatistics',
  ],
}

class AgedDebtContainer extends Component {
  constructor(props) {
    super(props)

    const { location, paginationUtils } = props
    const { query } = location
    const { child, room } = query
    const { setPageLocationQuery } = paginationUtils

    this.state = {
      child,
      room,
    }

    setPageLocationQuery(false)
  }

  componentDidMount() {
    this.fetchAll()
  }

  componentWillUnmount() {
    const { agedDebtActions } = this.props

    agedDebtActions.clear()
    agedDebtActions.clearStatistics()
  }

  fetchAll = () => {
    const { setLocationQuery } = this.props
    const { child, room } = this.state

    setLocationQuery({ child, room })

    this.fetchChart()
    this.fetchTable()
  }

  fetchChart = () => {
    const { agedDebtActions, agedDebtSelectors } = this.props
    const { child, room } = this.state

    const statisticsApiParams = {
      criteria: agedDebtSelectors.getAgedDebtStatisticsCriteria({ child: child?.value, room }),
    }

    agedDebtActions.getStatistics(statisticsApiParams)
  }

  fetchChildrenTable = () => {
    const {
      agedDebtActions,
      agedDebtSelectors,
      paginationUtils,
      setLocationQuery,
      sortingUtils,
    } = this.props
    const { child, room } = this.state
    const { page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const childAgedDebtReportApiParams = {
      criteria: agedDebtSelectors.getAgedDebtReportCriteria({ child, room }),
      groups: CHILD_AGED_DEBT_REPORT_GROUPS,
      order: { sortField, sortOrder },
      page,
    }

    setLocationQuery({ child, room })

    agedDebtActions.list(
      childAgedDebtReportApiParams,
      1 !== page,
    )
  }

  fetchNurseryTable = () => {
    const { nurseriesActions, paginationUtils, sortingUtils } = this.props
    const { page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    nurseriesActions.list({
      mergeResult: 1 !== page,
      onSuccess: this.fetchStatistics,
      params: {
        groups: NURSERIES_GROUPS,
        order: { sortField, sortOrder },
        page,
      },
      recurrency: true,
    })
  }

  fetchTable = () => {
    const { isOrganizationContext } = this.props

    if (isOrganizationContext) {
      return this.fetchNurseryTable()
    }

    return this.fetchChildrenTable()
  }

  handleChildChange = (child) => {
    const { paginationUtils, sortingUtils } = this.props

    const { onPageChange } = paginationUtils
    const { onSortChange } = sortingUtils

    this.setState(
      { child: child?.value },
      () => onSortChange(onPageChange(this.fetchAll)(1)),
    )
  }

  handleRoomChange = (room) => {
    const { paginationUtils, sortingUtils } = this.props

    const { onPageChange } = paginationUtils
    const { onSortChange } = sortingUtils

    this.setState(
      { room: room ? room.value : null },
      () => onSortChange(onPageChange(this.fetchAll)(1)),
    )
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetchTable)(page)
  }

  handleExportModalClick = (body, onSuccessCb) => {
    const { agedDebtActions, agedDebtSelectors } = this.props
    const { child, room } = this.state
    const { criteria } = body

    const userCriteria = _.find(criteria, { field: 'membership' })
    const filterCriteria = agedDebtSelectors.getAgedDebtReportCriteria({ child, room })

    const apiParams = {
      criteria: [
        ...[userCriteria],
        ...filterCriteria,
      ],
      group: CHILD_AGED_DEBT_REPORT_GROUPS,
    }

    agedDebtActions.sendReport(apiParams, onSuccessCb)
  }

  handleSendReport = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.EXPORT_REPORT, {
      onSendReport: this.handleExportModalClick,
      title: 'Export your aged debt report',
      userLabel: 'Select a manager or administrator to send the export to',
    })
  }

  handleSortChange = (key) => {
    const { paginationUtils, sortingUtils } = this.props
    const { onSortChange } = sortingUtils
    const { onPageChange } = paginationUtils

    onSortChange(onPageChange(this.fetchTable)(1))(key)
  }

  render() {
    const {
      agedDebtChartSummary,
      agedDebtListChildrenState,
      agedDebtStatisticsState,
      agedDebtTable,
      errorMessages,
      isOrganizationContext,
      nurseries,
      nurseriesListState,
      paginationUtils,
      sortingUtils,
    } = this.props
    const { child, room } = this.state
    const { getPageCount, page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils
    const agedDebtState = isOrganizationContext ? nurseriesListState : agedDebtListChildrenState

    const ageDebtChartData = getChart(agedDebtChartSummary)
    const pageCount = getPageCount(agedDebtState.meta.total_results)
    const isChartLoading = agedDebtStatisticsState.isFetching
    const isTableLoading = agedDebtState.isFetching
    const isLoading = isChartLoading && isTableLoading
    const isEmpty = !agedDebtChartSummary

    const groupTableData = isOrganizationContext && getGroupTableData({
      data: nurseries,
    })

    return (
      <AgedDebtView
        ageDebtChartData={ageDebtChartData}
        child={child}
        errorMessages={errorMessages}
        isEmpty={isEmpty}
        isLoading={isLoading}
        isOrganizationContext={isOrganizationContext}
        isTableLoading={isTableLoading}
        page={page}
        pageCount={pageCount}
        room={room}
        sortField={sortField}
        sortOrder={sortOrder}
        tableData={isOrganizationContext ? groupTableData : agedDebtTable}
        totalResults={agedDebtState.meta.total_results}
        onChartPointClick={this.handleChartPointClick}
        onChildChange={this.handleChildChange}
        onPageChange={this.handlePageChange}
        onRoomChange={this.handleRoomChange}
        onSendReport={this.handleSendReport}
        onSortChange={this.handleSortChange}
      />
    )
  }
}

AgedDebtContainer.authParams = {
  antiRoles: [
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
  ],
}

const mapState = (state, {
  agedDebtListChildrenState,
  agedDebtSelectors,
  agedDebtStatisticsState,
  appSelectors,
  nurseriesSelectors,
  securitySelectors,
}) => ({
  agedDebtChartSummary: agedDebtSelectors.getAgedDebtChartSummarySelector(state),
  agedDebtTable: agedDebtSelectors.getAgedDebtTableData(state),
  errorMessages: appSelectors.getErrorMessages(agedDebtListChildrenState, agedDebtStatisticsState),
  isFetching: appSelectors.getIsFetching(agedDebtListChildrenState, agedDebtStatisticsState),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
  nurseries: nurseriesSelectors.getNurseriesListDataSelector(state),
})

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withAgedDebtService,
  withNurseriesService,
  withPaginationUtils,
  withRouterUtils,
  withSortingUtils,
  withSecurityService,
  connect(mapState),
)

export default enhance(AgedDebtContainer)
