import _ from 'lodash'

import React from 'react'
import { Field, FormSection, InjectedFormProps, reduxForm } from 'redux-form'

import { ChargeForEnum, ChargeForLabel } from 'services/closureDayTypes/models'

import { isMinLengthGreaterThan, isRequired } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'

import { Callout, Form } from 'components'

import i18n from 'translations'

import { AddViewProps } from '../AddView'

const isMinLength = isMinLengthGreaterThan(3)

const CHARGE_FOR_FIELDS = [
  ChargeForEnum.REGULAR_SESSION,
  ChargeForEnum.REGULAR_SESSION_CONSUMABLE,
  ChargeForEnum.REGULAR_ITEMS,
  ChargeForEnum.REGULAR_DISCOUNT,
  ChargeForEnum.EXTRA_SESSION,
  ChargeForEnum.EXTRA_SESSION_CONSUMABLE,
  ChargeForEnum.EXTRA_ITEM,
  ChargeForEnum.ONE_OFF_DISCOUNT,
  ChargeForEnum.FUNDING_DEFICIT,
]

export const CLOSURE_DATE_SETTING_FORM = 'ClosureDateSettingForm'

interface AddFormProps extends AddViewProps {}

type AddFormFullProps = InjectedFormProps<{}, AddFormProps> & AddFormProps

const AddForm: React.FC<AddFormFullProps> = ({
  error,
  handleSubmit,
  isSubmitting,
  onSubmit,
  submitFailed,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    {submitFailed && <Callout content={error} error />}
    <Form.Row
      label={i18n.t('module:Management:ClosureDays:ClosureDayTypes:Add:Form:Name:label')}
      width={{ field: '300px' }}
      verticalLabel
    >
      <Field
        component={Form.TextField}
        name="name"
        placeholder={i18n.t('module:Management:ClosureDays:ClosureDayTypes:Add:Form:Name:placeholder')}
        validate={[isRequired, isMinLength]}
      />
    </Form.Row>
    <FormSection name="chargeFor">
      <Form.Row
        label={i18n.t('module:Management:ClosureDays:ClosureDayTypes:Add:Form:ChargeFor:label')}
        margin="0"
      />
      {_.map(CHARGE_FOR_FIELDS, (field) => (
        <Form.Row margin="0 0 5px">
          <Field
            component={Form.Checkbox}
            key={field}
            label={ChargeForLabel[field]}
            name={field}
          />
        </Form.Row>
      ))}
    </FormSection>
    <Form.FooterActions
      cancelLink={generateRoute('MANAGEMENT.CLOSURE_DAYS.CLOSURE_DAY_TYPES')}
      isSubmitting={isSubmitting}
      submitLabel={i18n.t('global:Save')}
    />
  </Form>
)

export default reduxForm<{}, AddFormProps>({ form: CLOSURE_DATE_SETTING_FORM })(AddForm)
