import { generateBasicActions } from 'utils/service'

import {
  CLEAR_CHILD_FINANCE,
  GET_CHILD_FINANCE,
  GET_CHILD_FINANCE_FAILED,
  GET_CHILD_FINANCE_SUCCESS,
} from './constants'

export const getFinanceDetails = (options = {}) => ({ childApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_CHILD_FINANCE_FAILED,
    init: GET_CHILD_FINANCE,
    success: GET_CHILD_FINANCE_SUCCESS,
  },
  options,
  service: childApiClient,
  serviceMethod: 'get',
})

export const clearFinanceDetails = () => ({
  type: CLEAR_CHILD_FINANCE,
})
