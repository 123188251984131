import _ from 'lodash'
import moment from 'moment'
import React from 'react'

import { generateRoute } from 'utils/routing'
import { useI18nKey } from 'utils/hooks'

import { Button, Callout, EmptyState, Section, Spinner } from 'components'

import i18n from 'translations'

import ChildSessionsList from '../components/ChildSessionsList'

import { StyledFutureSessionButtonContainer } from './ChildSessionsCurrentStyled'

const ChildSessionsCurrentView = ({
  authAccessMap,
  child,
  childCurrentSessions,
  childFutureSessions,
  errorMessages,
  isCurrentEmpty,
  isFutureEmpty,
  isLoading,
  isOpeningDaysEmpty,
  onAddSessionClick,
}) => {
  const { generateI18nKey: generateCurrentI18nKey } = useI18nKey(
    'module:Children:Child:BookingPattern:Sessions:List:Current',
  )
  const { generateI18nKey: generateFutureI18nKey } = useI18nKey(
    'module:Children:Child:BookingPattern:Sessions:List:Future',
  )

  const renderChildSession = (childSession) => {
    const { attendancePeriod, endDate, id, isOngoing, startDate } = childSession

    const startDateText = moment(startDate).format('DD/MM/YYYY')
    const endDateText = isOngoing
      ? i18n.t(generateCurrentI18nKey('ongoing'))
      : moment(endDate).format('DD/MM/YYYY')
    const title = `${startDateText} - ${endDateText}`

    return (
      <ChildSessionsList
        attendancePeriodText={`${attendancePeriod?.name}${attendancePeriod?.isArchived ? ' (archived)' : ''}`}
        child={child}
        childSession={childSession}
        displayAddAction={authAccessMap.module.ChildSessionsAdd}
        key={id}
        title={title}
      />
    )
  }

  const renderCurrentSessions = () => {
    if (isOpeningDaysEmpty) {
      return (
        <Section title={i18n.t(generateCurrentI18nKey('title'))}>
          <EmptyState
            actions={(
              <Button
                label={i18n.t(generateCurrentI18nKey('OpeningDaysEmptyState:actionButtonLabel'))}
                to={generateRoute('MANAGEMENT.NURSERY')}
              />
            )}
            icon="sessions"
            text1={i18n.t(generateCurrentI18nKey('OpeningDaysEmptyState:text1'))}
          />
        </Section>
      )
    }

    if (isCurrentEmpty) {
      const { firstName } = child

      return (
        <Section title={i18n.t(generateCurrentI18nKey('title'))}>
          <EmptyState
            actions={authAccessMap.module.ChildSessionsAdd && (
              <Button
                label={i18n.t(generateCurrentI18nKey('EmptyState:actionButtonLabel'))}
                onClick={onAddSessionClick}
              />
            )}
            icon="sessions"
            text1={i18n.t(generateCurrentI18nKey('EmptyState:text1'), { firstName })}
            text2={i18n.t(generateCurrentI18nKey('EmptyState:text2'))}
          />
        </Section>
      )
    }

    return (
      <Section title={i18n.t(generateCurrentI18nKey('title'))}>
        {_.map(childCurrentSessions, renderChildSession)}
      </Section>
    )
  }

  const renderAddFutureSessionButton = () => (
    <Button
      label={i18n.t(generateFutureI18nKey('addButtonLabel'))}
      onClick={onAddSessionClick}
    />
  )

  const renderFutureSessions = () => {
    if (isCurrentEmpty && isFutureEmpty) {
      return null
    }

    if (isFutureEmpty) {
      const { firstName } = child || {}

      return (
        <Section title={i18n.t(generateFutureI18nKey('title'))}>
          <EmptyState
            actions={authAccessMap.module.ChildSessionsAdd && renderAddFutureSessionButton()}
            icon="sessions"
            text1={i18n.t(generateFutureI18nKey('EmptyState:text1'), { firstName })}
            text2={i18n.t(generateFutureI18nKey('EmptyState:text2'))}
          />
        </Section>
      )
    }

    return (
      <Section title={i18n.t(generateFutureI18nKey('title'))}>
        {_.map(childFutureSessions, renderChildSession)}
      </Section>
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <div>
        {renderCurrentSessions()}
        {renderFutureSessions()}
        {!isFutureEmpty && (
          <StyledFutureSessionButtonContainer>
            {renderAddFutureSessionButton()}
          </StyledFutureSessionButtonContainer>
        )}
      </div>
    )
  }

  return (
    <div>
      <Callout content={errorMessages} error />
      {renderContent()}
    </div>
  )
}

export default ChildSessionsCurrentView
