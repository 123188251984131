import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired, isRequiredDateRange, isValidDateRange } from 'utils/fieldValidation'

import { Form } from 'components'

import i18n from 'translations'

import { STATUS_OPTIONS } from '../constants'

export const DEPOSITS_EXPORT_FORM = 'depositsExportForm'

const DepositsExportModalForm = ({
  handleSubmit,
  isSubmitting,
  onCancelClick,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row
      label={i18n.t('module:Modals:DepositsExport:Form:Dates:label')}
      width="250px"
      verticalLabel
    >
      <Form.Row.Item>
        <Field
          component={Form.DatePicker}
          name="dateRange"
          validate={[isRequiredDateRange, isValidDateRange]}
          clearable
          range
        />
      </Form.Row.Item>
    </Form.Row>
    <Form.Row
      label={i18n.t('module:Modals:DepositsExport:Form:Status:label')}
      width="250px"
      verticalLabel
    >
      <Form.Row.Item>
        <Field
          component={Form.Select}
          name="status"
          options={STATUS_OPTIONS}
          placeholder={i18n.t('module:Modals:DepositsExport:Form:Status:placeholder')}
          validate={isRequired}
        />
      </Form.Row.Item>
    </Form.Row>
    <Form.Row
      label={i18n.t('module:Modals:DepositsExport:Form:Membership:label')}
      width="250px"
      verticalLabel
    >
      <Form.Row.Item>
        <Field
          component={Form.InfiniteDropdowns.OrganizationMemberships}
          name="membership"
          placeholder={i18n.t('module:Modals:DepositsExport:Form:Membership:placeholder')}
          validate={isRequired}
        />
      </Form.Row.Item>
    </Form.Row>
    <Form.FooterActions
      isSubmitting={isSubmitting}
      submitLabel={i18n.t('global:Export')}
      onCancelClick={onCancelClick}
    />
  </Form>
)

export default reduxForm({ form: DEPOSITS_EXPORT_FORM })(DepositsExportModalForm)
