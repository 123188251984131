import React from 'react'

import { Callout, ModalBox } from 'components'

import i18n from 'translations'

import StatusChangeCommentForm from './components/StatusChangeCommentForm'

const StatusChangeCommentModalView = ({
  errorMessages,
  isLostEnquiryStatus,
  isSubmitting,
  onCancelClick,
  onNurserySettingsClick,
  onSubmit,
}) => (
  <ModalBox
    maxWidth={650}
    title={isLostEnquiryStatus
      ? i18n.t('module:Modals:Enquiry:StatusChange:lostEnquiryTitle')
      : i18n.t('module:Modals:Enquiry:StatusChange:noSpaceTitle')}
    autoHeight
    onCloseClick={onCancelClick}
  >
    <Callout content={errorMessages} error />
    <StatusChangeCommentForm
      isLostEnquiryStatus={isLostEnquiryStatus}
      isSubmitting={isSubmitting}
      onCancelClick={onCancelClick}
      onNurserySettingsClick={onNurserySettingsClick}
      onSubmit={onSubmit}
    />
  </ModalBox>
)

export default StatusChangeCommentModalView
