import React from 'react'

import { XMasIcon } from 'components'
import Widget from 'module/Dashboard/components/Widget'

import IncomeDetail from './components/IncomeDetail'

const MonthlyIncomeWidgetView = ({ currentStatistics, previousStatistics }) => (
  <Widget part>
    <Widget.Part>
      <XMasIcon height="38px" icon="circle-variant-3" marginLeft="15px" marginTop="15px" width="36px" />
      <IncomeDetail statistics={currentStatistics} />
    </Widget.Part>
    <Widget.Part>
      <IncomeDetail statistics={previousStatistics} />
      <XMasIcon height="25px" icon="snowflake-variant-3" marginLeft="290px" marginTop="-105px" width="25px" />
    </Widget.Part>
  </Widget>
)

export default MonthlyIncomeWidgetView
