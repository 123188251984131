import ServiceBase from 'services/ServiceBase'

import { FILE_TYPES } from 'constants/mimetypes'

import { getQueryStringV2 } from 'utils/api'

class InjuriesApiClient extends ServiceBase {
  list = (params) => this.request({
    path: `/v2/injuries?${getQueryStringV2(params)}`,
  })

  get = (injuryId, params = {}) => this.request({
    path: `/v2/injuries/${injuryId}?${getQueryStringV2(params)}`,
  })

  update = (injuryId, body, params = {}) => this.request({
    body,
    method: 'PUT',
    path: `/v2/injuries/${injuryId}?${getQueryStringV2(params)}`,
  })

  create = (body, params) => this.request({
    body,
    method: 'POST',
    path: `/v2/injuries?${getQueryStringV2(params)}`,
  })

  remove = (injuryId) => this.request({
    method: 'DELETE',
    path: `/v2/injuries/${injuryId}`,
  })

  sendReport = (injuryId, params) => this.request({
    headers: { Accept: FILE_TYPES.PDF.acceptHeader },
    path: `/v2/injury/${injuryId}/report?${getQueryStringV2(params)}`,
  })

  updateSignature = (signatureId, body, params = {}) => this.request({
    body,
    method: 'PUT',
    path: `/v2/injury_signatures/${signatureId}?${getQueryStringV2(params)}`,
  })

  createSignature = (body, params = {}) => this.request({
    body,
    method: 'POST',
    path: `/v2/injury_signatures?${getQueryStringV2(params)}`,
  })

  removeSignature = (signatureId) => this.request({
    method: 'DELETE',
    path: `/v2/injury_signatures/${signatureId}`,
  })
}

export default InjuriesApiClient
