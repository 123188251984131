import { generateBasicActions } from 'utils/service'

import {
  CLEAR_ENQUIRY_VIEWING_PERFORMANCE_REPORT_STATISTICS,
  GET_ENQUIRY_VIEWING_PERFORMANCE_REPORT_STATISTICS,
  GET_ENQUIRY_VIEWING_PERFORMANCE_REPORT_STATISTICS_FAILED,
  GET_ENQUIRY_VIEWING_PERFORMANCE_REPORT_STATISTICS_SUCCESS,
} from './constants'

export const getViewingPerformanceStatistics = (options = {}) => ({
  enquiryReportsApiClient,
}) => generateBasicActions.get({
  constants: {
    failed: GET_ENQUIRY_VIEWING_PERFORMANCE_REPORT_STATISTICS_FAILED,
    init: GET_ENQUIRY_VIEWING_PERFORMANCE_REPORT_STATISTICS,
    success: GET_ENQUIRY_VIEWING_PERFORMANCE_REPORT_STATISTICS_SUCCESS,
  },
  options,
  service: enquiryReportsApiClient,
  serviceMethod: 'getViewingPerformanceStatistics',
})

export const clearViewingPerformanceStatistics = () => ({
  type: CLEAR_ENQUIRY_VIEWING_PERFORMANCE_REPORT_STATISTICS,
})
