import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_COMPARISON_SINGLE,
  GET_COMPARISON_PREVIEW,
  GET_COMPARISON_PREVIEW_FAILED,
  GET_COMPARISON_PREVIEW_SUCCESS,
  GET_COMPARISON_REPORT,
  GET_COMPARISON_REPORT_FAILED,
  GET_COMPARISON_REPORT_SUCCESS,
  UPDATE_COMPARISON_ACTION_PLAN_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export default (...props) => {
  const [state = initialState, { payload, type }] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_COMPARISON_SINGLE,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  switch (type) {
    case GET_COMPARISON_REPORT:
      return {
        ...state,
        isFetching: true,
      }
    case GET_COMPARISON_PREVIEW: {
      const params = payload[1]?.extra
      const { ethnicity, framework, frameworkGroup, term, ...filters } = params || {}

      const data = {}

      if (framework) {
        data.framework = {
          id: +framework,
        }
      }

      if (term) {
        data.term = term
      }

      if (frameworkGroup) {
        data.frameworkGroup = {
          id: +frameworkGroup,
        }
      }

      if (ethnicity) {
        data.ethnicity = {
          id: +ethnicity,
        }
      }

      if (filters) {
        data.filters = filters
      }

      return {
        ...state,
        data,
        isFetching: true,
      }
    }
    case GET_COMPARISON_REPORT_FAILED:
    case GET_COMPARISON_PREVIEW_FAILED:
      return {
        ...state,
        isFetching: false,
      }
    case GET_COMPARISON_PREVIEW_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          data: payload.data,
        },
        isFetching: false,
      }
    case GET_COMPARISON_REPORT_SUCCESS:
      return {
        ...state,
        data: payload.data,
        isFetching: false,
      }
    case UPDATE_COMPARISON_ACTION_PLAN_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          actionPlan: payload.data.actionPlan,
        },
      }
    default:
      return state
  }
}
