import _ from 'lodash'
import moment from 'moment'

import { Option } from 'constants/models'
import {
  ChildProductTypes,
  ChildProductsSingleState,
  ONE_OFF_BOOKING_TYPE_OPTIONS,
  OneOffBookingTypes,
} from 'services/booking/childProducts/constants'
import { ChildProduct } from 'services/booking/childBooking/constants'
import { NurseryFundingProductType } from 'services/product/nurseryFundingV3/constants'
import { ApplicableValues, NurseryDiscountTypesV3, OTHER } from 'services/product/nurseryDiscountsV3/constants'
import { Product } from 'services/product/models'
// eslint-disable-next-line max-len, import-newlines/enforce
import { AddDiscountFormValues } from 'module/Management/ManagementFinanceSettings/ManagementDiscounts/v3/ManagementDiscountsV3Add/components/AddDiscountForm'
import { createTypeFromEnumValues } from 'utils/typescript'

import i18n from 'translations'

import { AddFormFieldProps } from './components/AddForm'

const getTypeForInitialValue = (type: ChildProductTypes | NurseryFundingProductType) => {
  if (type === ChildProductTypes.ONE_OFF_NO_DEDUCT_FUNDING) {
    return _.find(ONE_OFF_BOOKING_TYPE_OPTIONS, { value: ChildProductTypes.ONE_OFF_FUNDING })
  }

  if (
    type === ChildProductTypes.ONE_OFF_AMOUNT_DISCOUNT
    || type === ChildProductTypes.ONE_OFF_LINK_DISCOUNT
    || type === ChildProductTypes.ONE_OFF_PERCENTAGE_DISCOUNT
  ) {
    return _.find(ONE_OFF_BOOKING_TYPE_OPTIONS, { value: ChildProductTypes.ONE_OFF_DISCOUNT })
  }

  return _.find(ONE_OFF_BOOKING_TYPE_OPTIONS, { value: type })
}

const getDiscountForm = (type: ChildProductTypes, data: ChildProduct): AddDiscountFormValues => {
  if (
    type === ChildProductTypes.ONE_OFF_AMOUNT_DISCOUNT
    || type === ChildProductTypes.ONE_OFF_PERCENTAGE_DISCOUNT
  ) {
    // @ts-ignore
    const applicable = data?.settings?.applicable || []

    return {
      consumables: applicable.includes(ApplicableValues.CONSUMABLES),
      extraItems: applicable.includes(ApplicableValues.EXTRA_ITEMS),
      extraSessions: applicable.includes(ApplicableValues.EXTRA_SESSIONS),
      // @ts-ignore
      name: data?.settings?.name,
      sessions: applicable.includes(ApplicableValues.SESSIONS),
      type: type === ChildProductTypes.ONE_OFF_AMOUNT_DISCOUNT
        ? NurseryDiscountTypesV3.AMOUNT
        : NurseryDiscountTypesV3.PERCENTAGE,
      // @ts-ignore
      value: data?.settings?.value ? data.settings.value / 100 : undefined,
    }
  }

  return undefined
}

const getItem = (type: ChildProductTypes, product: Product, settings: any) => {
  if (
    type === ChildProductTypes.ONE_OFF_AMOUNT_DISCOUNT
    || type === ChildProductTypes.ONE_OFF_PERCENTAGE_DISCOUNT
  ) {
    return {
      label: _.upperFirst(i18n.t('global:other')),
      value: OTHER,
    }
  }

  return {
    hourly: product?.hourly,
    label: product?.name,
    settings: product?.settings || settings,
    type: product.type,
    value: product?.id,
  }
}

export const getInitialValues = (
  isEdit: boolean,
  response?: ChildProductsSingleState,
  childContextData?: any,
): AddFormFieldProps => {
  if (!isEdit) {
    return {
      child: childContextData ? {
        avatar: childContextData?.photo,
        label: childContextData?.name,
        value: childContextData?.id,
      } : undefined,
      date: moment(),
      quantity: 1,
      type: _.find(ONE_OFF_BOOKING_TYPE_OPTIONS, { value: ChildProductTypes.EXTRA_SESSION }),
    }
  }

  const { data } = response
  // @ts-ignore
  const { child, date, endTime, product, settings, startTime, type } = data
  const typeOption = getTypeForInitialValue(type)
  // @ts-ignore
  const discountForm = getDiscountForm(type, data)

  const body = {
    child: {
      avatar: child?.photo,
      label: child?.name,
      value: child?.id,
    },
    date: moment(date),
    // deductFromParentInvoice: type === NurseryFundingProductType.ONE_OFF_NO_DEDUCT_FUNDING as any,
    discountForm,
    endTime,
    // @ts-ignore
    includeConsumables: settings?.consumablesIncluded,
    item: getItem(type, product, settings),
    // @ts-ignore
    quantity: settings?.quantity || 1,
    startTime,
    type: typeOption as Option<createTypeFromEnumValues<OneOffBookingTypes>>,
    // @ts-ignore
    value: settings?.total ? settings.total / 100 : undefined,
  }

  if ((settings as any)?.overwritten) {
    // eslint-disable-next-line no-unsafe-optional-chaining
    body.value = (settings as any)?.value / 100
  }

  return body
}
