import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import {
  CLEAR_FINANCE_REPORTS_NURSERIES_BALANCE,
  FINANCE_REPORTS_NURSERIES_BALANCE,
  FINANCE_REPORTS_NURSERIES_BALANCE_FAILED,
  FINANCE_REPORTS_NURSERIES_BALANCE_SUCCESS,
} from './constants'

export default (...props) => {
  const [state = getBasicListInitialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_FINANCE_REPORTS_NURSERIES_BALANCE,
    failed: FINANCE_REPORTS_NURSERIES_BALANCE_FAILED,
    init: FINANCE_REPORTS_NURSERIES_BALANCE,
    success: FINANCE_REPORTS_NURSERIES_BALANCE_SUCCESS,
  }, getBasicListInitialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
