import { createSelector } from 'reselect'

import { hasOnlySeniorTeacherOrTeacherAccess } from 'services/security/selectors'

const getCarersState = (state) => state.carers.single

export const getIsInvitationResending = createSelector(
  [getCarersState],
  (state) => state?.isInvitationResending,
)

export const getCarersValuesForm = ({
  address,
  email,
  firstName,
  mobile,
  occupation,
  photo,
  postCode,
  surname,
  telephone,
  title,
  workTelephone,
}) => ({
  address,
  email,
  firstName,
  mobile,
  occupation,
  photo,
  postCode,
  surname,
  telephone,
  title: title ? title.value : null,
  workTelephone,
})

export const isGrantedEditSelector = createSelector(
  [hasOnlySeniorTeacherOrTeacherAccess],
  (isSeniorTeacherOrTeacher) => !isSeniorTeacherOrTeacher,
)
