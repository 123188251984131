import _ from 'lodash'

import React from 'react'

import { generateRoute } from 'utils/routing'
import { useI18nKey } from 'utils/hooks'

import { Button, EmptyState, Section, Space, Typography } from 'components'

import i18n from 'translations'

import { StyledContainer, StyledItemContainer, StyledPrimaryValue } from './FinanceContactDetailsStyled'

interface FinanceContactDetailsProps {
  childId: number
  contactData: any
  invoiceRecipients: any
  isFinanceV3Enabled: boolean
}

const FinanceContactDetails = ({
  childId,
  contactData,
  invoiceRecipients,
  isFinanceV3Enabled,
}: FinanceContactDetailsProps) => {
  const { generateI18nKey } = useI18nKey('module:Children:Child:Finance:ContactDetails')

  if (!contactData || (contactData && !contactData.paymentContact && !contactData.paymentEmail)) {
    return (
      <EmptyState
        actions={(
          <Button
            label={i18n.t(generateI18nKey('EmptyState:actionButtonLabel'))}
            to={generateRoute('CHILDREN.CHILD.FINANCE.CONTACT', { childId })}
          />
        )}
        icon="contact"
        text1={i18n.t(generateI18nKey('EmptyState:text1'))}
      />
    )
  }

  const { fundingLoopContact, paymentContact, paymentEmail } = contactData

  if (!paymentContact && !paymentEmail) {
    return null
  }

  let fullName = null
  let address = null
  let postCode = null

  if (paymentContact) {
    const { carer } = paymentContact || {}

    if (carer) {
      fullName = carer.fullName
      address = carer.address
      postCode = carer.postCode
    }
  }

  const getAdditionalParticipantsString = (moreRecipientsCount) => {
    if (0 >= moreRecipientsCount) {
      return null
    }

    if (1 < moreRecipientsCount) {
      return (
        <StyledPrimaryValue>
          {i18n.t(generateI18nKey('additionalParticipantsText'), { count: moreRecipientsCount })}
        </StyledPrimaryValue>
      )
    }

    return (
      <StyledPrimaryValue>
        {i18n.t(generateI18nKey('additionalParticipantText'), { count: moreRecipientsCount })}
      </StyledPrimaryValue>
    )
  }

  const renderAdditionalRecipients = () => {
    if (!invoiceRecipients?.length) {
      return '-'
    }

    const additionalRecipients = _.slice(invoiceRecipients, 1, invoiceRecipients.length)

    if (!additionalRecipients?.length) {
      return '-'
    }

    const moreRecipientsCount = additionalRecipients.length - 3
    const visibleRecipients = additionalRecipients.slice(0, 3)

    return (
      <div>
        {_.map<any>(visibleRecipients, (item) => {
          const { firstName, surname } = item?.carer || {}

          return (
            <StyledPrimaryValue>
              {`${firstName} ${surname}`}
            </StyledPrimaryValue>
          )
        })}
        {getAdditionalParticipantsString(moreRecipientsCount)}
      </div>
    )
  }

  return (
    <Section
      actions={(
        <Button
          hierarchy="tertiary"
          label={i18n.t('global:Edit')}
          margin="0 0 -2px 0"
          size="small"
          to={generateRoute('CHILDREN.CHILD.FINANCE.CONTACT', { childId })}
        />
      )}
      title={(
        <Typography fontSize="20">
          {i18n.t('module:Children:Child:Finance:ContactDetails:contactAndBankDetails')}
        </Typography>
      )}
      noMargin
    >
      <StyledContainer>
        <StyledItemContainer>
          <Typography fontSize="16" margin="0 0 10px 0" bold>
            {i18n.t('module:Children:Child:Finance:ContactDetails:primaryInvoicingContact')}
          </Typography>
          <StyledPrimaryValue>
            {fullName || '-'}
          </StyledPrimaryValue>
          <StyledPrimaryValue>
            {address || '-'}
          </StyledPrimaryValue>
          <StyledPrimaryValue>
            {postCode || '-'}
          </StyledPrimaryValue>
          <StyledPrimaryValue>
            {paymentEmail || '-'}
          </StyledPrimaryValue>
        </StyledItemContainer>
        <StyledItemContainer>
          <Typography fontSize="16" margin="0 0 10px 0" bold>
            {i18n.t('module:Children:Child:Finance:ContactDetails:AdditionalInvoiceRecipients')}
          </Typography>
          {renderAdditionalRecipients()}
        </StyledItemContainer>
      </StyledContainer>
      <Space space="20px" />
      {isFinanceV3Enabled && (
        <StyledContainer>
          <StyledItemContainer>
            <Typography fontSize="16" margin="0 0 10px 0" bold>
              {i18n.t('module:Children:Child:Finance:ContactDetails:fundingLoopContact')}
            </Typography>
            <StyledPrimaryValue>
              {fundingLoopContact?.carer?.fullName || '-'}
            </StyledPrimaryValue>
          </StyledItemContainer>
        </StyledContainer>
      )}
    </Section>
  )
}

export default FinanceContactDetails
