import { StylesProvider } from '@material-ui/core/styles'
import * as Sentry from '@sentry/react'

import React, { useEffect } from 'react'
import { Router } from 'react-router'
import { Provider } from 'react-redux'
import { useIdleTimer } from 'react-idle-timer'

import { properties } from 'app-config'
import { LOGIN_SUCCESSFUL } from 'services/legacy/auth/constants'
import { Subdomain } from 'services/subdomain/models'
import { Profile } from 'services/authentication/models'

import eventBus from 'utils/eventBus'

import { Routing } from 'core'
import SyncProviderWrapper from 'core/provider/SyncProviderWrapper'

import { EVENTS, installAnalytics, logEvent } from 'analytics'

import Styled from 'styled'
import { installUserFlow } from 'core/helpers/userflow'
import { setUserContextForSentry } from 'core/helpers/sentry'

interface AppProps {
  authProfile: Profile
  authToken: string
  history: any
  store: any
  subdomain: Subdomain
}

const App: React.FC<AppProps> = ({ authProfile, authToken, history, store, subdomain }) => {
  const onActive = () => logEvent(EVENTS.SESSION_STARTED, { context: 'on active' })

  const init = (profile) => {
    if (!subdomain) {
      return
    }

    installAnalytics(subdomain, profile)
    installUserFlow(subdomain, profile)

    if (profile) {
      setUserContextForSentry(profile)
    }
  }

  const handleLoginSuccess = (e) => {
    const { profile } = e.detail

    init(profile)
  }

  useEffect(() => {
    eventBus.on(LOGIN_SUCCESSFUL, handleLoginSuccess)

    init(authProfile)

    return () => {
      eventBus.remove(LOGIN_SUCCESSFUL, handleLoginSuccess)
    }
  }, [])

  useIdleTimer({
    name: 'analytics-session-timer',
    onActive,
    timeout: properties.analyticsSessionTime,
  })

  return (
    <Provider store={store}>
      <StylesProvider injectFirst>
        <SyncProviderWrapper
          authProfile={authProfile}
          authToken={authToken}
          subdomain={subdomain?.id}
        >
          <Styled />
          <Router
            history={history}
            routes={Routing}
          />
        </SyncProviderWrapper>
      </StylesProvider>
    </Provider>
  )
}

export default Sentry.withProfiler(App)
