import React from 'react'

import { ModalBox } from 'components'

import RegisterExtraSessionForm from './components/RegisterExtraSessionForm'

const RegisterExtraSessionModalView = ({
  errorMessages,
  initialValues,
  isLoading,
  isSubmitting,
  onAddChild,
  onAddSession,
  onCloseClick,
  onRemoveChild,
  onRemoveSession,
  onSubmit,
  sessionOptions,
}) => (
  <ModalBox title="Add an extra session" autoHeight onCloseClick={onCloseClick}>
    <RegisterExtraSessionForm
      errorMessages={errorMessages}
      initialValues={initialValues}
      isLoading={isLoading}
      isSubmitting={isSubmitting}
      sessionOptions={sessionOptions}
      onAddChild={onAddChild}
      onAddSession={onAddSession}
      onCancelClick={onCloseClick}
      onRemoveChild={onRemoveChild}
      onRemoveSession={onRemoveSession}
      onSubmit={onSubmit}
    />
  </ModalBox>
)

export default RegisterExtraSessionModalView
