import React, { PropsWithChildren } from 'react'

import { LayoutContainer } from 'components'

import { StyledTop } from './ProfileHeaderStyled'

const ProfileHeaderTop: React.FC<PropsWithChildren<any>> = ({ children }) => (
  <StyledTop>
    <LayoutContainer>
      {children}
    </LayoutContainer>
  </StyledTop>
)

export default ProfileHeaderTop
