/* eslint-disable max-len */
/* eslint-disable import-newlines/enforce */
import i18n from 'translations'

import withFinanceGateway from 'module/Finance/withFinanceGateway'

import Children from 'module/Children/index'
import ChildAutoCreditNoting from 'module/Children/Child/ChildBookingPattern/AutoCreditNoting'
import ChildContactsList from 'module/Children/Child/ChildContacts/ChildContactsList'
import ChildContactsAdd from 'module/Children/Child/ChildContacts/ChildContactsAdd'
import ChildFundingWrapper from 'module/Children/Child/ChildBookingPattern/ChildFunding/ChildFundingWrapper'
import ChildFunding from 'module/Children/Child/ChildBookingPattern/ChildFunding'
import ChildFundingItem from 'module/Children/Child/ChildBookingPattern/ChildFunding/ChildFundingItem'
import ChildFundingAdd from 'module/Children/Child/ChildBookingPattern/ChildFunding/ChildFundingAdd'
import ChildFundingAllocation from 'module/Children/Child/ChildBookingPattern/ChildFunding/ChildFundingAllocation'
import ChildSessionsWrapper from 'module/Children/Child/ChildBookingPattern/ChildSessions/ChildSessionsWrapperContainer'
import ChildSessions from 'module/Children/Child/ChildBookingPattern/ChildSessions'
import RegularBookingsList from 'module/Finance/RegularBookings/RegularBookingsList'
import ChildSessionsCurrent from 'module/Children/Child/ChildBookingPattern/ChildSessions/ChildSessionsCurrent'
import ChildSessionsHistory from 'module/Children/Child/ChildBookingPattern/ChildSessions/ChildSessionsHistory'
import ChildSessionsAdd from 'module/Children/Child/ChildBookingPattern/ChildSessions/ChildSessionsAdd'
import ChildExtraItems from 'module/Children/Child/ChildBookingPattern/ChildExtraItems'
import ChildExtraItemsAdd from 'module/Children/Child/ChildBookingPattern/ChildExtraItems/ChildExtraItemsAdd'
import Child from 'module/Children/Child'
import ChildAdd from 'module/Children/Child/ChildAdd'
import ChildEdit from 'module/Children/Child/ChildEdit'
import ChildAbout from 'module/Children/Child/ChildAbout'
import ChildAttendance from 'module/Children/Child/ChildAbout/ChildAttendance'
import ChildFiles from 'module/Children/Child/ChildAbout/ChildFiles'
import ChildAdhocSessionsWrapper
  from 'module/Children/Child/ChildBookingPattern/ChildAdhocSessions/ChildAdhocSessionsWrapper'
import ChildAdhocSessions from 'module/Children/Child/ChildBookingPattern/ChildAdhocSessions'
import ChildAdhocSessionsAdd from 'module/Children/Child/ChildBookingPattern/ChildAdhocSessions/ChildAdhocSessionsAdd'
import ChildObservations from 'module/Learning/Observations/ObservationsList'
import AddFromHomeObservationWrapper from 'module/HomeObservations/AddFromHomeObservationWrapper'
import ChildLearningJourney from 'module/Children/Child/ChildLearningJourney'
import ChildFinance from 'module/Children/Child/ChildFinance'
import ChildExtraItemsWrapper from 'module/Children/Child/ChildBookingPattern/ChildExtraItems/ChildExtraItemsWrapper'
import ChildFinanceTransactions from 'module/Children/Child/ChildFinance/ChildFinanceTransactions'
import ChildFinanceAddBank from 'module/Children/Child/ChildFinance/ChildFinanceAddBank'
import ChildAddBacsDetails from 'module/Children/Child/ChildFinance/AddBacsDetails'
import ChildFinanceAddContact from 'module/Children/Child/ChildFinance/ChildFinanceAddContact'
import ChildFinanceInvoice from 'module/Children/Child/ChildFinance/ChildFinanceInvoice'
import ChildFinanceDetails from 'module/Children/Child/ChildFinance/ChildFinanceDetails'
import ChildFinanceCreditNotesPreview from 'module/Children/Child/ChildFinance/ChildFinanceCreditNotesPreview'
import ChildFinanceInvoicesPayment from 'module/Children/Child/ChildFinance/ChildFinanceInvoicesPayment'
import ChildFinanceInvoicesPreview from 'module/Children/Child/ChildFinance/ChildFinanceInvoicesPreview'
import DiaryTimeline from 'module/DailyDiary/DiaryTimeline'
import ChildNotesList from 'module/Children/Child/ChildNotes/ChildNotesList'
import EditChildNote from 'module/Children/Child/ChildNotes/EditChildNote'
import ChildSafeguardingWrapper from 'module/Children/Child/ChildSafeguarding'
import ChildDiscountsWrapper from 'module/Children/Child/ChildBookingPattern/ChildDiscounts/ChildDiscountsWrapper'
import ChildDiscounts from 'module/Children/Child/ChildBookingPattern/ChildDiscounts'
import ChildDiscountsAdd from 'module/Children/Child/ChildBookingPattern/ChildDiscounts/ChildDiscountsAdd'
import RoomsMoves from 'module/Rooms/RoomsMoves'
import InjuryRecords from 'module/Safeguarding/Injury/InjuryRecords'
import InjuryWrapper from 'module/Safeguarding/Injury/InjuryWrapper'
import LogInjury from 'module/Safeguarding/Injury/LogInjury'
import InjurySingleRecord from 'module/Safeguarding/Injury/InjurySingleRecord'
import FormativeReportsList from 'module/Learning/FormativeReports/FormativeReportsList'
import FormativeReportsCreate from 'module/Learning/FormativeReports/FormativeReportsCreate'
import FormativeReportsPreview from 'module/Learning/FormativeReports/FormativeReportsPreview'
import ChildBookingPattern from 'module/Children/Child/ChildBookingPattern'
import HomeObservationsList from 'module/HomeObservations/HomeObservationsList'
import NextStepsList from 'module/Learning/NextSteps/NextStepsList'
import ChildFramework from 'module/Children/Child/ChildLearningJourney/ChildFramework'
import FinancePayments from 'module/Finance/FinancePayments'
import ChildAbsences from 'module/Children/Child/ChildAbout/ChildAbsences'
import DepositsAdd from 'module/Finance/Deposits/Add'
import DepositsWrapper from 'module/Finance/Deposits/DepositsWrapper'
import DepositsList from 'module/Finance/Deposits/List'
import RegularBookingsAdd from 'module/Children/Child/ChildBookingPattern/RegularBookings/RegularBookingsAdd'
import RegularBookingsPreview from 'module/Children/Child/ChildBookingPattern/RegularBookings/RegularBookingsPreview'
import OneOffBookingsWrapper from 'module/Finance/OneOffBookings/OneOffBookingsWrapper'
import OneOffBookingsList from 'module/Finance/OneOffBookings/List'
import OneOffBookingsAdd from 'module/Finance/OneOffBookings/Add'
import FinanceReportFundingV3 from 'module/Finance/FinanceReport/Funding/V3'

import ChildProfile from './Child/ChildAbout/ChildProfile'
import ChildProfileHistory from './Child/ChildAbout/ChildProfileHistory'
import ChildrenList from './ChildrenList'

/* eslint-disable max-len */
export const ROUTE_NAMES = {
  'CHILDREN.ADD': 'CHILDREN.ADD',
  'CHILDREN.CHILD': 'CHILDREN.CHILD',
  'CHILDREN.CHILD.ABOUT': 'CHILDREN.CHILD.ABOUT',
  'CHILDREN.CHILD.ABOUT.ABSENCES': 'CHILDREN.CHILD.ABOUT.ABSENCES',
  'CHILDREN.CHILD.ABOUT.ATTENDANCE': 'CHILDREN.CHILD.ABOUT.ATTENDANCE',
  'CHILDREN.CHILD.ABOUT.FILES': 'CHILDREN.CHILD.ABOUT.FILES',
  'CHILDREN.CHILD.ABOUT.PROFILE': 'CHILDREN.CHILD.ABOUT.PROFILE',
  'CHILDREN.CHILD.ABOUT.PROFILE_HISTORY': 'CHILDREN.CHILD.ABOUT.PROFILE_HISTORY',
  'CHILDREN.CHILD.ABOUT.ROOMS_MOVES': 'CHILDREN.CHILD.ABOUT.ROOMS_MOVES',
  'CHILDREN.CHILD.BOOKING_PATTERN': 'CHILDREN.CHILD.BOOKING_PATTERN',
  'CHILDREN.CHILD.BOOKING_PATTERN.AUTO_CREDIT_NOTING': 'CHILDREN.CHILD.BOOKING_PATTERN.AUTO_CREDIT_NOTING',
  'CHILDREN.CHILD.BOOKING_PATTERN.DEPOSITS': 'CHILDREN.CHILD.BOOKING_PATTERN.DEPOSITS',
  'CHILDREN.CHILD.BOOKING_PATTERN.DEPOSITS.ADD': 'CHILDREN.CHILD.BOOKING_PATTERN.DEPOSITS.ADD',
  'CHILDREN.CHILD.BOOKING_PATTERN.DEPOSITS.EDIT': 'CHILDREN.CHILD.BOOKING_PATTERN.DEPOSITS.EDIT',
  'CHILDREN.CHILD.BOOKING_PATTERN.DEPOSITS.REFUND': 'CHILDREN.CHILD.BOOKING_PATTERN.DEPOSITS.REFUND',
  'CHILDREN.CHILD.BOOKING_PATTERN.DISCOUNTS': 'CHILDREN.CHILD.BOOKING_PATTERN.DISCOUNTS',
  'CHILDREN.CHILD.BOOKING_PATTERN.DISCOUNTS.ADD': 'CHILDREN.CHILD.BOOKING_PATTERN.DISCOUNTS.ADD',
  'CHILDREN.CHILD.BOOKING_PATTERN.DISCOUNTS.EDIT': 'CHILDREN.CHILD.BOOKING_PATTERN.DISCOUNTS.EDIT',
  'CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_ITEMS': 'CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_ITEMS',
  'CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_ITEMS.ADD': 'CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_ITEMS.ADD',
  'CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_ITEMS.EDIT': 'CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_ITEMS.EDIT',
  'CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_SESSIONS': 'CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_SESSIONS',
  'CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_SESSIONS.ADD': 'CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_SESSIONS.ADD',
  'CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_SESSIONS.EDIT': 'CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_SESSIONS.EDIT',
  'CHILDREN.CHILD.BOOKING_PATTERN.FUNDING': 'CHILDREN.CHILD.BOOKING_PATTERN.FUNDING',
  'CHILDREN.CHILD.BOOKING_PATTERN.FUNDING.ADD': 'CHILDREN.CHILD.BOOKING_PATTERN.FUNDING.ADD',
  'CHILDREN.CHILD.BOOKING_PATTERN.FUNDING.ALLOCATION': 'CHILDREN.CHILD.BOOKING_PATTERN.FUNDING.ALLOCATION',
  'CHILDREN.CHILD.BOOKING_PATTERN.FUNDING.EDIT': 'CHILDREN.CHILD.BOOKING_PATTERN.FUNDING.EDIT',
  'CHILDREN.CHILD.BOOKING_PATTERN.FUNDING.VIEW': 'CHILDREN.CHILD.BOOKING_PATTERN.FUNDING.VIEW',
  'CHILDREN.CHILD.BOOKING_PATTERN.ONE_OFF_BOOKINGS': 'CHILDREN.CHILD.BOOKING_PATTERN.ONE_OFF_BOOKINGS',
  'CHILDREN.CHILD.BOOKING_PATTERN.ONE_OFF_BOOKINGS.ADD': 'CHILDREN.CHILD.BOOKING_PATTERN.ONE_OFF_BOOKINGS.ADD',
  'CHILDREN.CHILD.BOOKING_PATTERN.ONE_OFF_BOOKINGS.EDIT': 'CHILDREN.CHILD.BOOKING_PATTERN.ONE_OFF_BOOKINGS.EDIT',
  'CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS': 'CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS',
  'CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS.ADD': 'CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS.ADD',
  'CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS.EDIT': 'CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS.EDIT',
  'CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS.PREVIEW': 'CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS.PREVIEW',
  'CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS': 'CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS',
  'CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS.ADD': 'CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS.ADD',
  'CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS.EDIT': 'CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS.EDIT',
  'CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS.VIEW': 'CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS.VIEW',
  'CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS.VIEW.HISTORY': 'CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS.VIEW.HISTORY',
  'CHILDREN.CHILD.CONTACTS': 'CHILDREN.CHILD.CONTACTS',
  'CHILDREN.CHILD.CONTACTS.ADD': 'CHILDREN.CHILD.CONTACTS.ADD',
  'CHILDREN.CHILD.CONTACTS.EDIT': 'CHILDREN.CHILD.CONTACTS.EDIT',
  'CHILDREN.CHILD.DAILY_DIARY': 'CHILDREN.CHILD.DAILY_DIARY',
  'CHILDREN.CHILD.FINANCE': 'CHILDREN.CHILD.FINANCE',
  'CHILDREN.CHILD.FINANCE.BACS_DETAILS': 'CHILDREN.CHILD.FINANCE.BACS_DETAILS',
  'CHILDREN.CHILD.FINANCE.BANK': 'CHILDREN.CHILD.FINANCE.BANK',
  'CHILDREN.CHILD.FINANCE.BANK.ADD': 'CHILDREN.CHILD.FINANCE.BANK.ADD',
  'CHILDREN.CHILD.FINANCE.CONTACT': 'CHILDREN.CHILD.FINANCE.CONTACT',
  'CHILDREN.CHILD.FINANCE.CONTACT.ADD': 'CHILDREN.CHILD.FINANCE.CONTACT.ADD',
  'CHILDREN.CHILD.FINANCE.CREDIT_NOTES': 'CHILDREN.CHILD.FINANCE.CREDIT_NOTES',
  'CHILDREN.CHILD.FINANCE.INVOICE': 'CHILDREN.CHILD.FINANCE.INVOICE',
  'CHILDREN.CHILD.FINANCE.INVOICE.ADD': 'CHILDREN.CHILD.FINANCE.INVOICE.ADD',
  'CHILDREN.CHILD.FINANCE.INVOICE.EDIT': 'CHILDREN.CHILD.FINANCE.INVOICE.EDIT',
  'CHILDREN.CHILD.FINANCE.INVOICING': 'CHILDREN.CHILD.FINANCE.INVOICING',
  'CHILDREN.CHILD.FINANCE.PAYMENT': 'CHILDREN.CHILD.FINANCE.PAYMENT',
  'CHILDREN.CHILD.FINANCE.PAYMENT.DETAILS': 'CHILDREN.CHILD.FINANCE.PAYMENT.DETAILS',
  'CHILDREN.CHILD.FINANCE.PAYMENT_HISTORY': 'CHILDREN.CHILD.FINANCE.PAYMENT_HISTORY',
  'CHILDREN.CHILD.FINANCE.PAYMENT_HISTORY.ADD_PAYMENT': 'CHILDREN.CHILD.FINANCE.PAYMENT_HISTORY.ADD_PAYMENT',
  'CHILDREN.CHILD.FINANCE.SINGLE_INVOICE': 'CHILDREN.CHILD.FINANCE.SINGLE_INVOICE',
  'CHILDREN.CHILD.FINANCE.STATEMENT': 'CHILDREN.CHILD.FINANCE.STATEMENT',
  'CHILDREN.CHILD.FINANCE.TRANSACTIONS': 'CHILDREN.CHILD.FINANCE.TRANSACTIONS',
  'CHILDREN.CHILD.LEARNING_JOURNEY': 'CHILDREN.CHILD.LEARNING_JOURNEY',
  'CHILDREN.CHILD.LEARNING_JOURNEY.FORMATIVE_REPORTS': 'CHILDREN.CHILD.LEARNING_JOURNEY.FORMATIVE_REPORTS',
  'CHILDREN.CHILD.LEARNING_JOURNEY.FORMATIVE_REPORTS.CREATE': 'CHILDREN.CHILD.LEARNING_JOURNEY.FORMATIVE_REPORTS.CREATE',
  'CHILDREN.CHILD.LEARNING_JOURNEY.FORMATIVE_REPORTS.PREVIEW': 'CHILDREN.CHILD.LEARNING_JOURNEY.FORMATIVE_REPORTS.PREVIEW',
  'CHILDREN.CHILD.LEARNING_JOURNEY.FRAMEWORK': 'CHILDREN.CHILD.LEARNING_JOURNEY.FRAMEWORK',
  'CHILDREN.CHILD.LEARNING_JOURNEY.NEXT_STEPS': 'CHILDREN.CHILD.LEARNING_JOURNEY.NEXT_STEPS',
  'CHILDREN.CHILD.LEARNING_JOURNEY.OBSERVATIONS': 'CHILDREN.CHILD.LEARNING_JOURNEY.OBSERVATIONS',
  'CHILDREN.CHILD.LEARNING_JOURNEY.OBSERVATIONS.HOME_OBSERVATIONS': 'CHILDREN.CHILD.LEARNING_JOURNEY.OBSERVATIONS.HOME_OBSERVATIONS',
  'CHILDREN.CHILD.NOTES': 'CHILDREN.CHILD.NOTES',
  'CHILDREN.CHILD.NOTES.ADD': 'CHILDREN.CHILD.NOTES.ADD',
  'CHILDREN.CHILD.NOTES.EDIT': 'CHILDREN.CHILD.NOTES.EDIT',
  'CHILDREN.CHILD.SAFEGUARDING': 'CHILDREN.CHILD.SAFEGUARDING',
  'CHILDREN.CHILD.SAFEGUARDING.HOME_ACCIDENTS': 'CHILDREN.CHILD.SAFEGUARDING.HOME_ACCIDENTS',
  'CHILDREN.CHILD.SAFEGUARDING.HOME_ACCIDENTS.SINGLE_ADD': 'CHILDREN.CHILD.SAFEGUARDING.HOME_ACCIDENTS.SINGLE_ADD',
  'CHILDREN.CHILD.SAFEGUARDING.HOME_ACCIDENTS.SINGLE_EDIT': 'CHILDREN.CHILD.SAFEGUARDING.HOME_ACCIDENTS.SINGLE_EDIT',
  'CHILDREN.CHILD.SAFEGUARDING.HOME_ACCIDENTS.SINGLE_PREVIEW': 'CHILDREN.CHILD.SAFEGUARDING.HOME_ACCIDENTS.SINGLE_PREVIEW',
  'CHILDREN.CHILD.SAFEGUARDING.INJURY': 'CHILDREN.CHILD.SAFEGUARDING.INJURY',
  'CHILDREN.CHILD.SAFEGUARDING.INJURY.SINGLE_ADD': 'CHILDREN.CHILD.SAFEGUARDING.INJURY.SINGLE_ADD',
  'CHILDREN.CHILD.SAFEGUARDING.INJURY.SINGLE_EDIT': 'CHILDREN.CHILD.SAFEGUARDING.INJURY.SINGLE_EDIT',
  'CHILDREN.CHILD.SAFEGUARDING.INJURY.SINGLE_PREVIEW': 'CHILDREN.CHILD.SAFEGUARDING.INJURY.SINGLE_PREVIEW',
  'CHILDREN.EDIT': 'CHILDREN.EDIT',
  'CHILDREN.INDEX': 'CHILDREN.INDEX',
}
/* eslint-enable */

const ChildrenRouting = {
  childRoutes: [
    {
      component: ChildAdd,
      name: ROUTE_NAMES['CHILDREN.ADD'],
      path: 'add',
      title: 'Add children',
    },
    {
      component: ChildEdit,
      name: ROUTE_NAMES['CHILDREN.EDIT'],
      path: ':childId/edit/:type',
      title: 'Edit children',
    },
    {
      childRoutes: [
        {
          childRoutes: [
            {
              component: ChildProfile,
              name: ROUTE_NAMES['CHILDREN.CHILD.ABOUT.PROFILE'],
              path: 'profile',
              title: i18n.t('module:Children:Routing:Child:Profile:Profile'),
            },
            {
              component: ChildProfileHistory,
              name: ROUTE_NAMES['CHILDREN.CHILD.ABOUT.PROFILE_HISTORY'],
              path: 'profile-history',
              title: i18n.t('module:Children:Routing:Child:Profile:ProfileHistory'),
            },
            {
              component: RoomsMoves,
              name: ROUTE_NAMES['CHILDREN.CHILD.ABOUT.ROOMS_MOVES'],
              path: 'rooms-moves',
              title: 'Room moves',
            },
            {
              component: ChildAttendance,
              name: ROUTE_NAMES['CHILDREN.CHILD.ABOUT.ATTENDANCE'],
              path: 'attendance',
              title: 'Attendance',
            },
            {
              component: ChildFiles,
              name: ROUTE_NAMES['CHILDREN.CHILD.ABOUT.FILES'],
              path: 'files',
              title: i18n.t('services:Files:title'),
            },
            {
              component: ChildAbsences,
              name: ROUTE_NAMES['CHILDREN.CHILD.ABOUT.ABSENCES'],
              path: 'absences',
              title: i18n.t('module:Children:Child:About:Absences:Title:long'),
            },
          ],
          component: ChildAbout,
          name: ROUTE_NAMES['CHILDREN.CHILD.ABOUT'],
          path: 'about',
          title: 'About child',
        },
        {
          childRoutes: [
            {
              component: ChildContactsAdd,
              name: ROUTE_NAMES['CHILDREN.CHILD.CONTACTS.ADD'],
              path: 'add',
              title: 'Add contact',
            },
            {
              component: ChildContactsAdd,
              name: ROUTE_NAMES['CHILDREN.CHILD.CONTACTS.EDIT'],
              path: 'edit/:carerId',
              title: 'Edit contact',
            },
          ],
          indexRoute: {
            component: ChildContactsList,
          },
          name: ROUTE_NAMES['CHILDREN.CHILD.CONTACTS'],
          path: 'contacts',
          title: 'Contacts',
        },
        {
          childRoutes: [
            {
              childRoutes: [
                {
                  childRoutes: [
                    {
                      component: ChildSessionsHistory,
                      name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS.VIEW.HISTORY'],
                      path: 'history',
                      title: 'History',
                    },
                  ],
                  component: ChildSessions,
                  indexRoute: {
                    component: ChildSessionsCurrent,
                  },
                  name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS.VIEW'],
                  path: 'view',
                  title: 'View',
                },
                {
                  component: ChildSessionsAdd,
                  name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS.EDIT'],
                  path: ':sessionId/edit',
                  title: 'Edit session',
                },
                {
                  component: ChildSessionsAdd,
                  name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS.ADD'],
                  path: 'add',
                  title: 'Add session',
                },
                {
                  component: ChildAutoCreditNoting,
                  name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.AUTO_CREDIT_NOTING'],
                  path: 'auto-credit-noting',
                  title: i18n.t('module:Children:Routing:Child:BookingPattern.autoCreditNoting'),
                },
              ],
              component: ChildSessionsWrapper,
              name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS'],
              path: 'sessions',
              title: 'Sessions',
            },
            {
              childRoutes: [
                {
                  component: RegularBookingsAdd,
                  name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS.ADD'],
                  path: 'add',
                  title: i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:title'),
                },
                {
                  component: RegularBookingsAdd,
                  name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS.EDIT'],
                  path: 'edit/:bookingId',
                  title: i18n.t('module:Children:Child:BookingPattern:RegularBookings:Preview:title'),
                },
                {
                  component: RegularBookingsPreview,
                  name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS.PREVIEW'],
                  path: 'preview/:bookingId',
                  title: i18n.t('module:Children:Child:BookingPattern:RegularBookings:Preview:title'),
                },
              ],
              indexRoute: {
                component: RegularBookingsList,
              },
              name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS'],
              path: 'regular-bookings',
              title: i18n.t('module:Finance:RegularBookings:title'),
            },
            {
              childRoutes: [
                {
                  component: ChildAdhocSessionsAdd,
                  name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_SESSIONS.ADD'],
                  path: 'add',
                  title: 'Add extra sessions',
                },
                {
                  component: ChildAdhocSessionsAdd,
                  name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_SESSIONS.EDIT'],
                  path: 'edit/:id',
                  title: 'Edit extra sessions',
                },
              ],
              component: ChildAdhocSessionsWrapper,
              indexRoute: {
                component: ChildAdhocSessions,
              },
              name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_SESSIONS'],
              path: 'extra-sessions',
              title: 'Extra sessions',
            },
            {
              childRoutes: [
                {
                  component: ChildExtraItemsAdd,
                  name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_ITEMS.ADD'],
                  path: 'add',
                  title: 'Add extra item',
                },
                {
                  component: ChildExtraItemsAdd,
                  name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_ITEMS.EDIT'],
                  path: ':id/edit',
                  title: 'Edit extra item',
                },
              ],
              component: ChildExtraItemsWrapper,
              indexRoute: {
                component: ChildExtraItems,
              },
              name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_ITEMS'],
              path: 'extra-items',
              title: 'Extra items',
            },
            {
              childRoutes: [
                {
                  component: ChildFundingAdd,
                  name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.FUNDING.ADD'],
                  path: 'add',
                  title: i18n.t('module:Children:Child:BookingPattern:Funding:Add:addTitle'),
                },
                {
                  component: ChildFundingItem,
                  name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.FUNDING.VIEW'],
                  path: ':childFundingId/view',
                  title: 'View funding',
                },
                {
                  component: ChildFundingAdd,
                  name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.FUNDING.EDIT'],
                  path: ':childFundingId/edit',
                  title: 'Edit funding',
                },
                {
                  component: ChildFundingAllocation,
                  name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.FUNDING.ALLOCATION'],
                  path: ':childFundingId/allocation',
                  title: 'Edit funding allocation',
                },
              ],
              component: ChildFundingWrapper,
              indexRoute: {
                component: withFinanceGateway({ V2: ChildFunding, V3: FinanceReportFundingV3 }),
              },
              name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.FUNDING'],
              path: 'funding',
              title: 'Funding',
            },
            {
              childRoutes: [
                {
                  component: ChildDiscountsAdd,
                  name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.DISCOUNTS.ADD'],
                  path: 'add',
                  title: 'Add discount',
                },
                {
                  component: ChildDiscountsAdd,
                  name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.DISCOUNTS.EDIT'],
                  path: ':id/edit',
                  title: 'Edit discount',
                },
              ],
              component: ChildDiscountsWrapper,
              indexRoute: {
                component: ChildDiscounts,
              },
              name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.DISCOUNTS'],
              path: 'discounts',
              title: 'Discounts',
            },
            {
              childRoutes: [
                {
                  component: DepositsAdd,
                  name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.DEPOSITS.ADD'],
                  path: 'add',
                  title: i18n.t('module:Finance:Deposits:Form:Title:add'),
                },
                {
                  component: DepositsAdd,
                  name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.DEPOSITS.EDIT'],
                  path: ':depositId/edit',
                  title: i18n.t('module:Finance:Deposits:Form:Title:edit'),
                },
                {
                  component: DepositsAdd,
                  name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.DEPOSITS.REFUND'],
                  path: ':depositId/refund',
                  title: i18n.t('module:Finance:Deposits:Form:Title:refund'),
                },
              ],
              component: DepositsWrapper,
              indexRoute: {
                component: DepositsList,
              },
              name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.DEPOSITS'],
              path: 'deposits',
              title: i18n.t('module:Finance:Deposits:title'),
            },
            {
              childRoutes: [
                {
                  component: OneOffBookingsAdd,
                  name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.ONE_OFF_BOOKINGS.ADD'],
                  path: 'add',
                  title: i18n.t('module:Finance:OneOffBookings:Form:Title:add'),
                },
                {
                  component: OneOffBookingsAdd,
                  name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.ONE_OFF_BOOKINGS.EDIT'],
                  path: ':oneOffBookingId/edit',
                  title: i18n.t('module:Finance:OneOffBookings:Form:Title:edit'),
                },
              ],
              component: OneOffBookingsWrapper,
              indexRoute: {
                component: OneOffBookingsList,
              },
              name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN.ONE_OFF_BOOKINGS'],
              path: 'one-off-bookings',
              title: i18n.t('module:Finance:OneOffBookings:title'),
            },
          ],
          component: ChildBookingPattern,
          name: ROUTE_NAMES['CHILDREN.CHILD.BOOKING_PATTERN'],
          path: 'booking-pattern',
          title: 'Booking pattern',
        },
        {
          childRoutes: [
            {
              component: NextStepsList,
              name: ROUTE_NAMES['CHILDREN.CHILD.LEARNING_JOURNEY.NEXT_STEPS'],
              path: 'next-steps',
              title: i18n.t('module:Learning:NextSteps:title'),
            },
            {
              component: ChildFramework,
              name: ROUTE_NAMES['CHILDREN.CHILD.LEARNING_JOURNEY.FRAMEWORK'],
              path: 'framework',
              title: i18n.t('module:Learning:Observations:ObservationFrameworks:frameworks'),
            },
            {
              childRoutes: [
                {
                  component: AddFromHomeObservationWrapper,
                  indexRoute: {
                    component: HomeObservationsList,
                  },
                  name: ROUTE_NAMES['CHILDREN.CHILD.LEARNING_JOURNEY.OBSERVATIONS.HOME_OBSERVATIONS'],
                  path: 'home-observations',
                  title: 'Home observations',
                },
              ],
              indexRoute: {
                component: ChildObservations,
              },
              name: ROUTE_NAMES['CHILDREN.CHILD.LEARNING_JOURNEY.OBSERVATIONS'],
              path: 'observations',
              title: i18n.t('module:Learning:Observations:title'),
            },
            {
              childRoutes: [
                {
                  component: FormativeReportsCreate,
                  name: ROUTE_NAMES['CHILDREN.CHILD.LEARNING_JOURNEY.FORMATIVE_REPORTS.CREATE'],
                  path: 'create',
                  title: i18n.t('module:Learning:FormativeReports:FormativeReportsCreate:title'),
                },
                {
                  component: FormativeReportsPreview,
                  name: ROUTE_NAMES['CHILDREN.CHILD.LEARNING_JOURNEY.FORMATIVE_REPORTS.PREVIEW'],
                  path: ':formativeReportId/preview',
                  title: i18n.t('module:Learning:FormativeReports:FormativeReportsPreview:title'),
                },
              ],
              indexRoute: {
                component: FormativeReportsList,
              },
              name: ROUTE_NAMES['CHILDREN.CHILD.LEARNING_JOURNEY.FORMATIVE_REPORTS'],
              path: 'formative-reports',
              title: i18n.t('module:Learning:FormativeReports:title'),
            },
          ],
          component: ChildLearningJourney,
          name: ROUTE_NAMES['CHILDREN.CHILD.LEARNING_JOURNEY'],
          path: 'learning-journey',
          title: 'Learning journey',
        },
        {
          component: DiaryTimeline,
          name: ROUTE_NAMES['CHILDREN.CHILD.DAILY_DIARY'],
          path: 'daily-diary',
          title: 'Diary',
        },
        {
          childRoutes: [
            {
              component: ChildFinanceDetails,
              name: ROUTE_NAMES['CHILDREN.CHILD.FINANCE.INVOICING'],
              path: 'invoicing',
              title: i18n.t('module:Children:Child:Finance:Invoicing:title'),
            },
            {
              childRoutes: [
                {
                  component: ChildFinanceInvoicesPayment,
                  name: ROUTE_NAMES['CHILDREN.CHILD.FINANCE.PAYMENT_HISTORY.ADD_PAYMENT'],
                  path: 'add',
                  title: i18n.t('module:Children:Child:Finance:Payment:Add:breadcrumbsTitle'),
                },
              ],
              indexRoute: {
                component: FinancePayments,
              },
              name: ROUTE_NAMES['CHILDREN.CHILD.FINANCE.PAYMENT_HISTORY'],
              path: 'payment-history',
              title: i18n.t('module:Children:Child:Finance:PaymentHistory:title'),
            },
            {
              indexRoute: {
                component: ChildFinanceInvoicesPreview,
              },
              name: ROUTE_NAMES['CHILDREN.CHILD.FINANCE.SINGLE_INVOICE'],
              path: 'invoices/:invoiceId',
              title: 'Invoice preview',
            },
            {
              indexRoute: {
                component: ChildFinanceCreditNotesPreview,
              },
              name: ROUTE_NAMES['CHILDREN.CHILD.FINANCE.CREDIT_NOTES'],
              path: 'credit-note/:creditNoteId',
              title: 'Credit note preview',
            },
            {
              indexRoute: {
                component: ChildAddBacsDetails,
              },
              name: ROUTE_NAMES['CHILDREN.CHILD.FINANCE.BACS_DETAILS'],
              path: 'bacs-details',
              title: i18n.t('module:Children:Child:Finance:BacsDetails:title'),
            },
            {
              childRoutes: [
                {
                  component: ChildFinanceAddBank,
                  name: ROUTE_NAMES['CHILDREN.CHILD.FINANCE.BANK.ADD'],
                  path: ':id',
                  title: 'Add',
                },
              ],
              indexRoute: {
                component: ChildFinanceAddBank,
              },
              name: ROUTE_NAMES['CHILDREN.CHILD.FINANCE.BANK'],
              path: 'bank',
              title: 'Bank details',
            },
            {
              childRoutes: [
                {
                  component: ChildFinanceAddContact,
                  name: ROUTE_NAMES['CHILDREN.CHILD.FINANCE.CONTACT.ADD'],
                  path: ':id',
                  title: 'Add',
                },
              ],
              indexRoute: {
                component: ChildFinanceAddContact,
              },
              name: ROUTE_NAMES['CHILDREN.CHILD.FINANCE.CONTACT'],
              path: 'contact',
              title: 'Contact details',
            },
            {
              component: ChildFinanceTransactions,
              name: ROUTE_NAMES['CHILDREN.CHILD.FINANCE.TRANSACTIONS'],
              path: 'transactions',
              title: i18n.t('module:Children:Child:Finance:Transactions:title'),
            },
            {
              component: ChildFinanceInvoicesPayment,
              name: ROUTE_NAMES['CHILDREN.CHILD.FINANCE.PAYMENT'],
              path: 'payment/add',
              title: i18n.t('module:Children:Child:Finance:Payment:Add:breadcrumbsTitle'),
            },
            {
              childRoutes: [
                {
                  component: ChildFinanceInvoice,
                  name: ROUTE_NAMES['CHILDREN.CHILD.FINANCE.INVOICE.ADD'],
                  path: 'add',
                  title: 'Add invoice',
                },
                {
                  component: ChildFinanceInvoice,
                  name: ROUTE_NAMES['CHILDREN.CHILD.FINANCE.INVOICE.EDIT'],
                  path: ':invoiceId/edit',
                  title: i18n.t('module:Children:Child:Finance:Invoice:Add:editTitle'),
                },
              ],
              path: 'invoice',
            },

          ],
          component: ChildFinance,
          name: ROUTE_NAMES['CHILDREN.CHILD.FINANCE'],
          path: 'finance',
          title: 'Finance',
        },
        {
          childRoutes: [
            {
              component: EditChildNote,
              name: ROUTE_NAMES['CHILDREN.CHILD.NOTES.ADD'],
              path: 'add',
              title: 'Add',
            },
            {
              component: EditChildNote,
              name: ROUTE_NAMES['CHILDREN.CHILD.NOTES.EDIT'],
              path: ':noteId/edit',
              title: 'Edit',
            },
          ],
          indexRoute: {
            component: ChildNotesList,
          },
          name: ROUTE_NAMES['CHILDREN.CHILD.NOTES'],
          path: 'notes',
          title: i18n.t('module:Children:Child:Notes:List:title'),
        },
        {
          childRoutes: [
            {
              childRoutes: [
                {
                  childRoutes: [
                    {
                      component: LogInjury,
                      name: ROUTE_NAMES['CHILDREN.CHILD.SAFEGUARDING.INJURY.SINGLE_EDIT'],
                      path: 'edit',
                      title: i18n.t('module:Injury:Routing:Edit:title'),
                    },
                  ],
                  indexRoute: {
                    component: InjurySingleRecord,
                  },
                  name: ROUTE_NAMES['CHILDREN.CHILD.SAFEGUARDING.INJURY.SINGLE_PREVIEW'],
                  path: ':injuryId/preview',
                  title: i18n.t('module:Injury:Preview:accidentRecordTitle'),
                },
                {
                  component: LogInjury,
                  name: ROUTE_NAMES['CHILDREN.CHILD.SAFEGUARDING.INJURY.SINGLE_ADD'],
                  path: 'add',
                  title: i18n.t('module:Injury:Routing:Add:title'),
                },
              ],
              component: InjuryWrapper,
              indexRoute: {
                component: InjuryRecords,
              },
              name: ROUTE_NAMES['CHILDREN.CHILD.SAFEGUARDING.INJURY'],
              path: 'injury',
              title: i18n.t('module:Safeguarding:Routing:accidentsIncidents'),
            },
            {
              childRoutes: [
                {
                  childRoutes: [
                    {
                      component: LogInjury,
                      name: ROUTE_NAMES['CHILDREN.CHILD.SAFEGUARDING.HOME_ACCIDENTS.SINGLE_EDIT'],
                      path: 'edit',
                      title: i18n.t('module:Safeguarding:Add:editTitle'),
                    },
                  ],
                  indexRoute: {
                    component: InjurySingleRecord,
                  },
                  name: ROUTE_NAMES['CHILDREN.CHILD.SAFEGUARDING.HOME_ACCIDENTS.SINGLE_PREVIEW'],
                  path: ':injuryId/preview',
                  title: i18n.t('module:Safeguarding:List:previewTitle'),
                },
                {
                  component: LogInjury,
                  name: ROUTE_NAMES['CHILDREN.CHILD.SAFEGUARDING.HOME_ACCIDENTS.SINGLE_ADD'],
                  path: 'add',
                  title: i18n.t('module:Safeguarding:Add:addTitle'),
                },
              ],
              component: InjuryWrapper,
              indexRoute: {
                component: InjuryRecords,
              },
              name: ROUTE_NAMES['CHILDREN.CHILD.SAFEGUARDING.HOME_ACCIDENTS'],
              path: 'home-accidents',
              title: i18n.t('module:Safeguarding:Routing:homeAccidents'),
            },
          ],
          component: ChildSafeguardingWrapper,
          indexRoute: {
            component: InjuryRecords,
          },
          name: ROUTE_NAMES['CHILDREN.CHILD.SAFEGUARDING'],
          path: 'safeguarding',
          title: i18n.t('module:Safeguarding:Routing:title'),
        },
      ],
      component: Child,
      name: ROUTE_NAMES['CHILDREN.CHILD'],
      path: ':childId',
    },
  ],
  component: Children,
  indexRoute: {
    component: ChildrenList,
  },
  name: ROUTE_NAMES['CHILDREN.INDEX'],
  path: 'children',
  title: 'Children',
}

ChildrenRouting.ROUTE_NAMES = ROUTE_NAMES

export default ChildrenRouting
