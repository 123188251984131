import { createSelector } from 'reselect'

const getFinanceReports = (state) => state.financeDepositsReport

export const getFinanceDepositsReportStatistics = createSelector(
  [getFinanceReports],
  (state) => state.statistics,
)

export const getFinanceDepositsReportStatisticsData = createSelector(
  [getFinanceDepositsReportStatistics],
  (state) => state.data,
)

