import FooterActions from './FooterActions'
import FooterActionsGroup from './FooterActionsGroup'
import FooterActionsFlex from './FooterActionsFlex'
import FooterActionsItem from './FooterActionsItem'

FooterActions.Flex = FooterActionsFlex
FooterActions.Item = FooterActionsItem
FooterActions.Group = FooterActionsGroup

export default FooterActions
