import i18n from 'translations'

export const ORG_SESSIONS_FILTER = {
  ACTIVE: 'ACTIVE',
  ALL: 'ALL',
  ARCHIVED: 'ARCHIVED',
}

export const ORG_SESSIONS_FILTER_OPTIONS = [
  { label: i18n.t('global:Active'), value: ORG_SESSIONS_FILTER.ACTIVE },
  { label: i18n.t('global:All'), value: ORG_SESSIONS_FILTER.ALL },
  { label: i18n.t('global:Archived'), value: ORG_SESSIONS_FILTER.ARCHIVED },
]
