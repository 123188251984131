import React from 'react'

import { Page, Spinner } from 'components'

import i18n from 'translations'

import { ShowChildOverViewOnFormativeReports, UseOnlySecureToMarkProgress } from './components'

const GeneralView = ({
  isFetching,
  nurseryLearningSettings,
  onSubmit,
}) => {
  const {
    childOverviewSectionOnFormativeReportsVisible,
    useOnlySecureToMarkProgress,
  } = nurseryLearningSettings || {}

  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    return (
      <React.Fragment>
        <ShowChildOverViewOnFormativeReports
          value={childOverviewSectionOnFormativeReportsVisible}
          onSubmit={onSubmit}
        />
        <UseOnlySecureToMarkProgress
          value={useOnlySecureToMarkProgress}
          onSubmit={onSubmit}
        />
      </React.Fragment>
    )
  }

  return (
    <Page.Section
      isLoading={isFetching}
      title={i18n.t('module:Management:Learning:General:title')}
    >
      {renderContent()}
    </Page.Section>
  )
}

export default GeneralView
