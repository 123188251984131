import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'
import { WrappedComponentType } from 'constants/types'
import { ROLES } from 'constants/security'

import { withAppService, withAppServiceProps } from 'services/app'
import { withPaginationUtils, withPaginationUtilsProps } from 'services/utils/pagination'
import { withSortUtilsProps, withSortingUtils } from 'services/utils/sorting'
import { withNurseryAcademicYearsService, withNurseryAcademicYearsServiceProps } from 'services/nurseryAcademicYears'
import { withRouter } from 'services/router'

import ClosureDatesView from './ClosureDatesView'
import { getTableData } from './ClosureDatesHelpers'

type ClosureDatesContainerProps = withAppServiceProps
  & withPaginationUtilsProps
  & withSortUtilsProps
  & withNurseryAcademicYearsServiceProps

const mapState = (state: RootState, {
  appSelectors,
  nurseryAcademicYearsListState,
  nurseryAcademicYearsSelectors,
}: ClosureDatesContainerProps) => ({
  errorMessages: appSelectors.getErrorMessages(nurseryAcademicYearsListState),
  isFetching: appSelectors.getIsFetching(nurseryAcademicYearsListState),
  nurseryAcademicYearsList: nurseryAcademicYearsSelectors.getNurseryAcademicYearsList(state),
  totalResults: appSelectors.getTotalResults(nurseryAcademicYearsListState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const ClosureDatesContainer: WrappedComponentType<ClosureDatesContainerProps & PropsFromRedux> = ({
  errorMessages,
  isFetching,
  nurseryAcademicYearsActions,
  nurseryAcademicYearsList,
  nurseryAcademicYearsSelectors,
  paginationUtils,
  sortingUtils,
  totalResults,
}) => {
  const [isInitialized, setIsInitialized] = useState(false)

  const { getPageCount, page, perPage } = paginationUtils
  const { onSortChange, sortField, sortOrder } = sortingUtils

  const fetchData = () => {
    const criteria = nurseryAcademicYearsSelectors.getCriteria({ periodExists: 1 })

    nurseryAcademicYearsActions.list({
      params: [{
        criteria,
        order: {
          sortField,
          sortOrder,
        },
      }],
    })
  }

  useEffect(() => {
    if (!isInitialized) {
      fetchData()
      setIsInitialized(true)
    }

    return () => {
      nurseryAcademicYearsActions.clearList()
    }
  }, [])

  useEffect(() => {
    if (isInitialized) {
      fetchData()
    }
  }, [page])

  useEffect(() => {
    if (isInitialized) {
      const { onPageChange } = paginationUtils

      if (1 === page) {
        fetchData()

        return
      }

      onPageChange()(1)
    }
  }, [sortField, sortOrder])

  const handlePageChange = (newPage) => {
    const { onPageChange } = paginationUtils

    onPageChange()(newPage)
  }

  const handleSortChange = (key) => {
    onSortChange()(key)
  }

  const tableData = getTableData(nurseryAcademicYearsList)
  const pageCount = getPageCount(totalResults)

  return (
    <ClosureDatesView
      errorMessages={errorMessages}
      isLoading={isFetching}
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      sortField={sortField}
      sortOrder={sortOrder}
      tableData={tableData}
      totalResults={totalResults}
      onPageChange={handlePageChange}
      onSortChange={handleSortChange}
    />
  )
}

ClosureDatesContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

const enhance = compose(
  withAppService,
  withRouter,
  withPaginationUtils,
  withSortingUtils,
  withNurseryAcademicYearsService,
  connector,
)

export default enhance(ClosureDatesContainer)
