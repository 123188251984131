export const GET_NURSERY_SESSION_V3 = 'NURSERY_SESSIONS/GET_NURSERY_SESSION_V3'
export const GET_NURSERY_SESSION_V3_SUCCESS = 'NURSERY_SESSIONS/GET_NURSERY_SESSION_V3_SUCCESS'
export const GET_NURSERY_SESSION_V3_FAILED = 'NURSERY_SESSIONS/GET_NURSERY_SESSION_V3_FAILED'

export const CREATE_NURSERY_SESSION_V3 = 'NURSERY_SESSIONS/CREATE_NURSERY_SESSION_V3'
export const CREATE_NURSERY_SESSION_V3_SUCCESS = 'NURSERY_SESSIONS/CREATE_NURSERY_SESSION_V3_SUCCESS'
export const CREATE_NURSERY_SESSION_V3_FAILED = 'NURSERY_SESSIONS/CREATE_NURSERY_SESSION_V3_FAILED'

export const UPDATE_NURSERY_SESSION_V3 = 'NURSERY_SESSIONS/UPDATE_NURSERY_SESSION_V3'
export const UPDATE_NURSERY_SESSION_V3_SUCCESS = 'NURSERY_SESSIONS/UPDATE_NURSERY_SESSION_V3_SUCCESS'
export const UPDATE_NURSERY_SESSION_V3_FAILED = 'NURSERY_SESSIONS/UPDATE_NURSERY_SESSION_V3_FAILED'

export const UPDATE_BATCH_NURSERY_SESSION_V3 = 'NURSERY_SESSIONS/UPDATE_BATCH_NURSERY_SESSION_V3'
export const UPDATE_BATCH_NURSERY_SESSION_V3_SUCCESS = 'NURSERY_SESSIONS/UPDATE_BATCH_NURSERY_SESSION_V3_SUCCESS'
export const UPDATE_BATCH_NURSERY_SESSION_V3_FAILED = 'NURSERY_SESSIONS/UPDATE_BATCH_NURSERY_SESSION_V3_FAILED'

export const CLEAR_NURSERY_SESSION_V3 = 'NURSERY_SESSIONS/CLEAR_NURSERY_SESSION_V3'
