import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { NURSERY_SESSIONS_FILTER } from 'services/nurserySessions/constants'

import { withAppService } from 'services/app'
import { withNurserySessionsService } from 'services/nurserySessions'

import { Select } from 'components'

import i18n from 'translations'

import withInfiniteDropdownHelpers from './withInfiniteDropdown'

class NurserySessionsDropdown extends Component {
  componentDidMount() {
    const { infiniteDropdownHelpers, nurserySessionsActions, value } = this.props

    if (!_.isArray(value) && value) {
      return infiniteDropdownHelpers.handleComponentDidMount({
        action: () => nurserySessionsActions.get((value?.value || value), {
          onFailed: this.notFoundValue,
          onSuccess: infiniteDropdownHelpers.handleComponentDidMountOnSuccess,
        }),
        value,
      })
    }

    return null
  }

  componentWillUnmount() {
    const { nurserySessionsActions } = this.props

    nurserySessionsActions.clearDropdownList()
  }

  handleLoadMoreElements = (phrase) => {
    const {
      extraFields,
      infiniteDropdownHelpers,
      limit,
      nurserySessionsActions,
      nurserySessionsSelectors,
    } = this.props

    return infiniteDropdownHelpers.handleLoadMoreElements({
      clearAction: nurserySessionsActions.clearDropdownList,
      listAction: ({ newPage, newSearchPhrase, result }) => nurserySessionsActions.dropdownList({
        onSuccess: (response) => infiniteDropdownHelpers.handleLoadMoreElementsOnSuccess({
          extraFields,
          newPage,
          newSearchPhrase,
          response,
          result,
        }),
        params: {
          criteria: nurserySessionsSelectors.getCriteria({
            search: newSearchPhrase,
            statusFilter: NURSERY_SESSIONS_FILTER.ACTIVE,
          }),
          limit,
          mergeResult: true,
          page: newPage,
        },
      }),
      phrase,
    })
  }

  notFoundValue = () => {
    const { onChange } = this.props

    onChange(null)
  }

  handleChange = (e) => {
    const { infiniteDropdownHelpers, onChange } = this.props

    infiniteDropdownHelpers.handleOnChange(e, () => onChange(e))
  }

  render() {
    const {
      disabled,
      infiniteDropdownState,
      placeholder,
      rawValue,
      value,
      ...rest
    } = this.props
    const { cacheUniq, options, selectedValue } = infiniteDropdownState

    return (
      <Select
        {...rest}
        cacheUniq={cacheUniq}
        disabled={disabled}
        loadOptions={this.handleLoadMoreElements}
        options={options}
        placeholder={placeholder || i18n.t('global:NurserySession')}
        rawValue={rawValue || selectedValue || value}
        value={value}
        searchable
        onChange={this.handleChange}
      />
    )
  }
}

const mapState = (state, { appSelectors, params }) => ({
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const enhance = compose(
  withAppService,
  withNurserySessionsService,
  withInfiniteDropdownHelpers,
  connect(mapState),
)

export default enhance(NurserySessionsDropdown)
