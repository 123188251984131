import { BasicModalProps, ModalProps } from 'modals'

import { HIDE_ALL_MODAL, HIDE_MODAL, SHOW_MODAL } from './constants'
import { ModalOptions } from '../constants'

export const show = <T = void>(
  modalType: T extends void ? 'error' : T,
  // @ts-ignore
  modalProps?: BasicModalProps & ModalProps[T],
  modalOptions?: ModalOptions,
) => ({
    modalOptions,
    modalProps,
    modalType,
    type: SHOW_MODAL,
  })

export const hide = () => ({
  type: HIDE_MODAL,
})

export const hideAll = () => ({
  type: HIDE_ALL_MODAL,
})
