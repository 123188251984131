import React from 'react'

import { Page, Spinner } from 'components'

import RoomsAddForm from './components/RoomsAddForm'
import RoomsAddFormNoStaffing from './components/RoomsAddFormNoStaffing'

const RoomsAddView = ({
  errorMessages,
  formValues,
  initialValues,
  isArchived,
  isEdit,
  isFormLoading,
  isLoading,
  isStaffingEnabled,
  onArchiveClick,
  onSubmit,
  permissionToPremiumSection,
}) => {
  if (isLoading) {
    return (
      <Page>
        <Page.Content>
          <Spinner />
        </Page.Content>
      </Page>
    )
  }

  if (isStaffingEnabled) {
    return (
      <RoomsAddForm
        errorMessages={errorMessages}
        formValues={formValues}
        initialValues={initialValues}
        isArchived={isArchived}
        isEdit={isEdit}
        isLoading={isFormLoading}
        permissionToPremiumSection={permissionToPremiumSection}
        onArchiveClick={onArchiveClick}
        onSubmit={onSubmit}
      />
    )
  }

  return (
    <RoomsAddFormNoStaffing
      errorMessages={errorMessages}
      formValues={formValues}
      initialValues={initialValues}
      isArchived={isArchived}
      isEdit={isEdit}
      isLoading={isFormLoading}
      permissionToPremiumSection={permissionToPremiumSection}
      onArchiveClick={onArchiveClick}
      onSubmit={onSubmit}
    />
  )
}

export default RoomsAddView
