import color from 'color'
import styled from 'styled-components'

import layout, { ZINDEX_ORDER } from 'constants/layout'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledMenuSidebar = styled.div`
  & > div {
    height: auto !important;
    top: 152px !important;
    bottom: 0 !important;
    padding-left: 0 !important;
    z-index: ${ZINDEX_ORDER.MENU} !important;
  }
  
  @media(max-width: 580px) {
    & > div {
      top: ${layout.topBarHeight}px !important;
    }
  }
  
  ${({ open }) => open && `
    & > div {
      box-shadow: -4px 0 20px 0 rgba(89, 89, 89, 0.5);
    }  
  `}
`

export const StyledSidebarContent = styled.section`
  background: ${color(getBrandingColor('primary-color')).string()};
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const StyledFooter = styled.footer`
  background: ${color(getBrandingColor('primary-color')).mix(color(NEUTRAL_COLOURS.WHITE), 0.1).string()};
  padding: 10px 20px;

  @media(min-width: 581px) {
    display: none;
  }
`

export const StyledWrapper = styled.div`
background: ${color(getBrandingColor('primary-color')).string()};
  display: flex;
  flex-direction: column;
  min-height: 100%;
`
