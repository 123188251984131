import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import auth from 'utils/auth'
import { getBackendErrors } from 'utils/backendErrors'

import { withRouterUtils } from 'services/utils/router'
import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withSnackbarService } from 'services/utils/snackbar'

import i18n from 'translations'

import ManagementAccountCodesView from './ManagementAccountCodesView'
import { getTableData } from './helpers'

const GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings.invoice',
    'nurseryInvoiceSettings',
    'nurseryInvoiceSettings.numbers',
    'nurseryInvoiceSettings.paymentReminders',
    'nurseryInvoiceSettings.accountCodes',
    'accountCodes',
    'nurseryInvoiceSettings.annualisedInvoiceSettings',
    'annualisedInvoiceSettings',
    'invoiceNumbers',
  ],
}

class ManagementAccountCodesContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      accountCodes: {},
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { nurseriesActions } = this.props

    nurseriesActions.clearSingle()
  }

  fetch = () => {
    const {
      nurseriesActions,
      nurseryOptions,
    } = this.props

    return nurseriesActions.get(nurseryOptions.id, {
      params: { groups: GROUPS },
    })
  }

  handleAccountCodeBlurOnSuccess = (name) => {
    const { snackbarActions } = this.props

    snackbarActions.show({
      message: i18n.t('module:Management:Finance:AccountCodes:snackbarMessage', { name }),
    })
  }

  handleAccountCodeBlurOnFail = (response, key) => {
    const errors = getBackendErrors(response || {})

    if (!errors) {
      return false
    }

    if (errors.nurserySettings?.invoice?.accountCodes) {
      return this.setState((prevState) => ({
        ...prevState,
        accountCodes: {
          ...prevState.accountCodes,
          [key]: {
            ...prevState.accountCodes[key],
            error: errors.nurserySettings.invoice.accountCodes?.[key],
          },
        },
      }))
    }

    return null
  }

  handleAccountCodeBlur = (e, key, name) => {
    const {
      isFinanceV3Enabled,
      nurseriesActions,
      nurseriesSelectors,
      nurseryOptions,
      nurserySettings,
    } = this.props

    const value = e.target.value

    const payload = nurseriesSelectors.getAccountCodesPayload({
      accountCode: { [key]: value },
      isFinanceV3Enabled,
      nurserySettings,
    })

    return nurseriesActions.update(nurseryOptions.id, {
      onFailed: ({ response }) => this.handleAccountCodeBlurOnFail(response, key),
      onSuccess: () => this.handleAccountCodeBlurOnSuccess(name),
      params: { groups: GROUPS },
      payload,
    })
  }

  handleAccountCodeChange = (e, key) => {
    const value = e.target.value

    this.setState((prevState) => ({
      ...prevState,
      accountCodes: {
        ...prevState.accountCodes,
        [key]: {
          edited: true,
          error: null,
          value,
        },
      },
    }))
  }

  render() {
    const {
      data,
      errorMessages,
      isFetching,
      isFinanceV3Enabled,
    } = this.props
    const { accountCodes } = this.state

    const tableData = getTableData({
      accountCodes,
      data,
      isFinanceV3Enabled,
      onBlur: this.handleAccountCodeBlur,
      onChange: this.handleAccountCodeChange,
    })

    return (
      <ManagementAccountCodesView
        data={tableData}
        errorMessages={errorMessages}
        isLoading={isFetching}
      />
    )
  }
}

const mapState = (state, { appSelectors, nurseriesSelectors, nurseriesSingleState, params }) => ({
  data: nurseriesSelectors.getAccountCodes(state),
  errorMessages: appSelectors.getErrorMessages(nurseriesSingleState),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  nurserySettings: nurseriesSelectors.getNurserySettings(state),
})

const enhance = compose(
  withAppService,
  withNurseriesService,
  withRouterUtils,
  withSnackbarService,
  connect(mapState),
)

export default enhance(ManagementAccountCodesContainer)
