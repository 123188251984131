export const CREATE_ENQUIRY_STATUS_TYPES = 'CREATE_ENQUIRY_STATUS_TYPES'
export const CREATE_ENQUIRY_STATUS_TYPES_SUCCESS = 'CREATE_ENQUIRY_STATUS_TYPES_SUCCESS'
export const CREATE_ENQUIRY_STATUS_TYPES_FAILED = 'CREATE_ENQUIRY_STATUS_TYPES_FAILED'

export const GET_ENQUIRY_STATUS_TYPES = 'GET_ENQUIRY_STATUS_TYPES'
export const GET_ENQUIRY_STATUS_TYPES_SUCCESS = 'GET_ENQUIRY_STATUS_TYPES_SUCCESS'
export const GET_ENQUIRY_STATUS_TYPES_FAILED = 'GET_ENQUIRY_STATUS_TYPES_FAILED'

export const UPDATE_ENQUIRY_STATUS_TYPES = 'UPDATE_ENQUIRY_STATUS_TYPES'
export const UPDATE_ENQUIRY_STATUS_TYPES_SUCCESS = 'UPDATE_ENQUIRY_STATUS_TYPES_SUCCESS'
export const UPDATE_ENQUIRY_STATUS_TYPES_FAILED = 'UPDATE_ENQUIRY_STATUS_TYPES_FAILED'

export const CLEAR_ENQUIRY_STATUS_TYPES_SINGLE = 'CLEAR_ENQUIRY_STATUS_TYPES_SINGLE'
