import _ from 'lodash'

import React, { PropsWithChildren } from 'react'

import { Tooltip } from 'components'

import i18n from 'translations'

import { StyledContainer, StyledDivider, StyledItem, StyledListItem } from './StagesIndicatorStyled'

interface StagesIndicatorProps {
  currentStage?: number
  disabled?: boolean
  highestAvailableStage?: number
  onStageChange: (i?: number) => {}
  stages: Array<{
    disabled?: boolean
    label: string
  }>
}

const StagesIndicator: React.FC<PropsWithChildren<StagesIndicatorProps>> = ({
  currentStage,
  disabled,
  highestAvailableStage,
  onStageChange,
  stages,
}) => {
  const renderItems = () => _.map(stages, (stage, i) => (
    <React.Fragment>
      {/* @ts-ignore */}
      {0 !== i && <StyledDivider $active={i + 1 === currentStage} />}
      <StyledListItem key={i}>
        <Tooltip
          placement="top"
          title={i + 1 > highestAvailableStage
            ? i18n.t('components:StagesIndicator:disabledTooltip')
            : ''}
          fullHeight
        >
          <StyledItem
            $active={i + 1 === currentStage}
            $disabled={disabled || stage.disabled || i + 1 > highestAvailableStage}
            $interactive={i + 1 <= highestAvailableStage}
            $past={i + 1 < currentStage}
            onClick={() => onStageChange(i + 1)}
          >
            {stage.label}
          </StyledItem>
        </Tooltip>
      </StyledListItem>
    </React.Fragment>
  ))

  return (
    <StyledContainer>
      {renderItems()}
    </StyledContainer>
  )
}

export default StagesIndicator
