import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withSecurityService } from 'services/security'

import ChildAdd from '../../ChildAdd'
import ChildProfileHistory from '../ChildProfileHistory'
import ChildProfileView from './ChildProfileView'

const CHILD_GROUPS = {
  read: [
    'child.keyWorkers',
    'user',
    'user.details',
    'child.carerChildRelations',
    'carerChildRelation.carer',
    'carer',
    'child.extend',
    'child.nurseryClass',
    'child.ethnicity',
    'nurseryClass',
    'childProfile',
    'childInformation',
    'ethnicity',
    'user.details',
  ],
}

class ChildProfileContainer extends Component {
  componentDidMount() {
    const { childActions, params } = this.props
    const { childId } = params

    childActions.get({
      params: [childId, {
        groups: CHILD_GROUPS,
      }],
    })
  }

  render() {
    const {
      child,
      drinkingMethods,
      isFetching,
      maritalStatuses,
      permissionToEdit,
      permissionToProfileHistory,
      toiletTrainingOptions,
    } = this.props

    return (
      <ChildProfileView
        child={child}
        drinkingMethods={drinkingMethods}
        isLoading={isFetching}
        maritalStatuses={maritalStatuses}
        permissionToEdit={permissionToEdit}
        permissionToProfileHistory={permissionToProfileHistory}
        toiletTrainingOptions={toiletTrainingOptions}
      />
    )
  }
}

const mapState = (state, { appSelectors, childSelectors, childSingleState, securitySelectors }) => ({
  child: childSelectors.getChildSelector(state),
  drinkingMethods: appSelectors.getDrinkingMethods(state),
  isFetching: appSelectors.getIsFetching(childSingleState),
  maritalStatuses: appSelectors.getMaritalStatuses(state),
  permissionToEdit:
    securitySelectors.isAdministrationContext(state)
    || auth.SELECTORS.getComponentIsAuthorised(state, ChildAdd),
  permissionToProfileHistory: auth.SELECTORS.getComponentIsAuthorised(state, ChildProfileHistory),
  toiletTrainingOptions: appSelectors.getToiletTrainingOptions(state),
})

const enhance = compose(
  withAppService,
  withChildService,
  withSecurityService,
  connect(mapState),
)

export default enhance(ChildProfileContainer)
