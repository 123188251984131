import styled from 'styled-components'

export const StyledHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 15px;
  margin-bottom: 50px;
  
  @media(max-width: 680px) {
    flex-direction: column-reverse;
    display: flex;
    align-items: center;
  }
`

export const StyledStatistics = styled.div`
  display: grid;
  grid-template-columns: 100px 100px 100px auto;
  grid-column-gap: 15px;
  margin: 20px 0 35px;
  
  @media(max-width: 660px) {
    grid-template-columns: auto;
    grid-row-gap: 15px;
    justify-content: center;
    text-align: center;
  }
`
