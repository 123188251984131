import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired, isValidInteger } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'

import { Form, GridLayout, Page, Section, Typography } from 'components'

import i18n from 'translations'

export const ROOM_FORM = 'RoomForm'

const RoomsAddForm = ({
  handleSubmit,
  isArchived,
  isEdit,
  isLoading,
  onArchiveClick,
  onSubmit,
  permissionToPremiumSection,
}) => {
  const renderPremiumSection = () => (
    <div>
      <GridLayout.Group noVerticalMargin>
        <GridLayout.Item>
          <Form.Row label="Start age" required>
            <Form.Row.FlexItem flex="0 0 110px" mobileFull>
              <Field
                component={Form.TextField}
                name="startAgeInMonths"
                placeholder="Start age"
                type="number"
                validate={[isRequired, isValidInteger]}
              />
            </Form.Row.FlexItem>
            <Form.Row.FlexItem flex="0 0 70px">
              months
            </Form.Row.FlexItem>
          </Form.Row>
        </GridLayout.Item>
        <GridLayout.Item>
          <Form.Row label="Capacity" required>
            <Form.Row.FlexItem>
              <Field
                component={Form.TextField}
                name="capacity"
                placeholder="Capacity"
                type="number"
                validate={[isRequired, isValidInteger]}
              />
            </Form.Row.FlexItem>
          </Form.Row>
        </GridLayout.Item>
      </GridLayout.Group>
      <GridLayout.Group noVerticalMargin>
        <GridLayout.Item>
          <Form.Row label="Finish age" required>
            <Form.Row.FlexItem flex="0 0 110px" mobileFull>
              <Field
                component={Form.TextField}
                max="216"
                min="0"
                name="endAgeInMonths"
                placeholder="Finish age"
                type="number"
                validate={[isRequired, isValidInteger]}
              />
            </Form.Row.FlexItem>
            <Form.Row.FlexItem flex="0 0 70px">
              months
            </Form.Row.FlexItem>
          </Form.Row>
        </GridLayout.Item>
        <GridLayout.Item>
          <Form.Row label="Ratio" required>
            <Form.Row.FlexItem flex="0 0 15px">
              1:
            </Form.Row.FlexItem>
            <Form.Row.FlexItem>
              <Field
                component={Form.TextField}
                name="ratio"
                placeholder="Ratio"
                type="number"
                validate={[isRequired, isValidInteger]}
              />
            </Form.Row.FlexItem>
          </Form.Row>
        </GridLayout.Item>
      </GridLayout.Group>
      <GridLayout.Group noVerticalMargin>
        <GridLayout.Item />
        <GridLayout.Item>
          <Form.Row label="Minimum staff required" required>
            <Form.Row.FlexItem>
              <Field
                component={Form.TextField}
                name="staffRequired"
                placeholder="Minimum staff required"
                type="number"
                validate={[isRequired, isValidInteger]}
              />
            </Form.Row.FlexItem>
          </Form.Row>
        </GridLayout.Item>
      </GridLayout.Group>
    </div>
  )

  return (
    <Page.Section
      actions={isEdit && (
        <Section.Actions
          options={[{
            onClick: onArchiveClick(!isArchived),
            type: isArchived ? 'unarchive' : 'archive',
          }]}
        />
      )}
      title={`${isEdit ? 'Edit' : 'Add'} room`}
    >
      <Typography margin="20px 0">
        {isEdit
          ? 'Edit your room.'
          : 'Set up your room.'}
      </Typography>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <GridLayout>
          <GridLayout.Group noVerticalMargin>
            <GridLayout.Item>
              <Form.Row label="Room name" required>
                <Form.Row.FlexItem flex="0 0 300px" mobileFull>
                  <Field
                    component={Form.TextField}
                    name="name"
                    placeholder="Room name"
                    validate={isRequired}
                  />
                </Form.Row.FlexItem>
              </Form.Row>
            </GridLayout.Item>
          </GridLayout.Group>
          {permissionToPremiumSection && renderPremiumSection()}
        </GridLayout>
        <Form.FooterActions
          cancelLink={generateRoute('ROOMS.INDEX')}
          isSubmitting={isLoading}
          submitLabel={isEdit ? i18n.t('global:Save') : 'Add room'}
        />
      </Form>
    </Page.Section>
  )
}

export default reduxForm({ form: ROOM_FORM })(RoomsAddForm)
