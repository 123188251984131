import _ from 'lodash'
import { getThumbnails } from 'video-metadata-thumbnails'

import React, { useEffect, useState } from 'react'

import ImagesSize from 'constants/imagesSize'
import { SUPPORTED_FILE_TYPES } from 'constants/mimetypes'
import { NEUTRAL_COLOURS } from 'constants/colors'
import { noop } from 'constants/global'
import { UPLOADED_FILE_STATUS } from 'services/legacy/upload/constants'

import { isDocumentMimeType, isImageMimeType, isPdfMimeType, isVideoMimeType } from 'utils/attachment'
import { convertBlobToBase64, getOptimizedImage } from 'utils/image'

import {
  BlockedIcon,
  Button,
  Checkbox,
  EmptyState,
  FooterActions,
  Icon,
  Image,
  ModalBox,
  Toolbar,
  Typography,
  UserTile,
  VideoPlayer,
} from 'components'

import i18n from 'translations'

import MediaTagModalForm from './components/MediaTagModalForm'
import {
  StyledChildrenList,
  StyledImageContainer,
  StyledImageWrapper,
  StyledVideoIconWrapper,
} from './MediaTagModalStyled'

const MediaTagModalView = ({
  childrenList,
  disableTagging,
  fileIndex,
  files,
  onCloseClick,
  onSelectAll,
  onSelectChild,
  onSubmit,
  selectAll,
  selectedChildren,
}) => {
  const [thumbnail, setThumbnail] = useState(null)
  const [imageLoaded, setImageLoaded] = useState(false)
  const [thumbnailError, setThumbnailError] = useState(false)

  const file = files[fileIndex]
  const isLastFile = files.length - 1 === fileIndex

  useEffect(() => {
    const processFile = async () => {
      setThumbnail(null)
      setThumbnailError(false)

      const isImage = isImageMimeType(file.mimeType)
      const isVideo = isVideoMimeType(file.mimeType)

      if (isImage || isVideo) {
        setThumbnail(getOptimizedImage(file.thumbnail, ImagesSize.FULL_VIEW))
      }

      if (!file.itemFromBe) {
        if (isImage && file.file && !_.isString(file.file)) {
          const thumb = await convertBlobToBase64(file.file)

          setThumbnail(thumb)
        }

        if (isVideo) {
          try {
            const thumbnails = await getThumbnails(file.file, {
              end: 0,
              quality: 0.6,
            })

            if (thumbnails?.length && thumbnails?.[0]?.blob) {
              const thumb = await convertBlobToBase64(thumbnails[0].blob)
              setThumbnail(thumb)
            } else {
              setThumbnailError(true)
            }
          } catch (error) {
            setThumbnailError(true)
          }
        }
      }
    }

    processFile()
  }, [file])

  const renderDocumentEmptyState = () => {
    const document = _.find([
      ...SUPPORTED_FILE_TYPES.DOCUMENTS,
      ...SUPPORTED_FILE_TYPES.PDF,
    ], ({ mimeTypes }) => _.includes(mimeTypes, file.mimeType))

    return (
      <EmptyState
        icon={document.icon}
        text1={i18n.t('module:Modals:MediaTagModal:PreviewIsNotAvailable')}
      />
    )
  }

  const renderHeaderContent = () => {
    if (isDocumentMimeType(file.mimeType) || isPdfMimeType(file.mimeType)) {
      return renderDocumentEmptyState(file)
    }

    const isVideo = isVideoMimeType(file.mimeType)
    if (isVideo && file.itemFromBe && UPLOADED_FILE_STATUS.READY === file.lambdaStatus) {
      return <VideoPlayer id={file.id} mimeType={file.playerMimeType} src={file.file} />
    }

    if (isImageMimeType(file.mimeType) || isVideo) {
      const icon = isVideo ? 'video-file' : 'image-file'
      let EmptyStateComponent = (
        <EmptyState
          icon={icon}
          text1={`${i18n.t('global:Processing')}...`}
        />
      )

      if (thumbnailError) {
        EmptyStateComponent = (
          <EmptyState
            icon={icon}
            text1={(
              <Typography bold>
                {`${i18n.t('module:Modals:MediaTagModal:PreviewUnavailable:textPart1')}`}
              </Typography>
            )}
            text2={`${i18n.t('module:Modals:MediaTagModal:PreviewUnavailable:textPart2')}`}
            text3={`${i18n.t('module:Modals:MediaTagModal:PreviewUnavailable:textPart3')}`}
          />
        )
      }

      return (
        <StyledImageWrapper>
          {isVideo && null !== thumbnail && imageLoaded && (
            <StyledVideoIconWrapper>
              <Icon
                color="#FFF"
                height={18}
                icon="videocam"
              />
            </StyledVideoIconWrapper>
          )}
          <Image
            effect="opacity"
            errorComponent={EmptyStateComponent}
            height={ImagesSize.MEDIA_PICKER_PREVIEW.height}
            src={thumbnail}
            width={ImagesSize.MEDIA_PICKER_PREVIEW.width}
            wrapperClassName="mediaPickerImage"
            onLoaded={setImageLoaded}
          />
        </StyledImageWrapper>
      )
    }

    return null
  }

  const renderHeader = () => (
    <React.Fragment>
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <Typography bold>
              {i18n.t('module:Modals:MediaTagModal:Media')}
            </Typography>
          </Toolbar.Item>
        </Toolbar.Group>
        <Toolbar.Group>
          <Toolbar.Item>
            <Typography bold>
              {`${fileIndex + 1} / ${files.length}`}
            </Typography>
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      <StyledImageContainer>
        {renderHeaderContent()}
      </StyledImageContainer>
    </React.Fragment>
  )

  const getIcon = (canBePhotographed, canBeTaggedInGroupPhotos) => {
    if (!canBePhotographed) {
      return (
        <BlockedIcon
          color={NEUTRAL_COLOURS.WHITE}
          icon="camera"
        />
      )
    }

    if (!canBeTaggedInGroupPhotos) {
      return (
        <BlockedIcon
          color={NEUTRAL_COLOURS.WHITE}
          icon="group"
        />
      )
    }

    return null
  }

  const renderChildPicker = (
    <React.Fragment>
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <Typography bold>
              {i18n.t('module:Modals:MediaTagModal:tagChildren')}
            </Typography>
          </Toolbar.Item>
        </Toolbar.Group>
        <Toolbar.Group>
          <Toolbar.Item>
            <Checkbox
              label={i18n.t('global:selectAll')}
              value={selectAll}
              onChange={onSelectAll}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      <StyledChildrenList>
        {_.map(childrenList, (child) => {
          const {
            displayName,
            firstName,
            id,
            information,
            photo,
            surname,
          } = child
          const {
            canBePhotographed,
            canBeTaggedInGroupPhotos,
          } = information

          return (
            <UserTile
              disabled={!canBePhotographed}
              firstName={firstName}
              icon={getIcon(canBePhotographed, canBeTaggedInGroupPhotos)}
              key={id}
              photo={photo}
              selected={_.find(selectedChildren, ({ id: childId }) => id === childId)}
              surname={surname}
              title={displayName}
              compact
              onClick={() => onSelectChild(child)}
            />
          )
        })}
      </StyledChildrenList>
    </React.Fragment>
  )

  return (
    <ModalBox
      title={!disableTagging
        ? i18n.t('module:Modals:MediaTagModal:title')
        : i18n.t('module:Modals:MediaTagModal:secondTitle')}
      iosPolyfill
      onCloseClick={() => onCloseClick(true)}
    >
      {renderHeader()}
      <MediaTagModalForm
        file={file}
        onSubmit={noop}
      />
      {!disableTagging && childrenList?.length ? renderChildPicker : null}
      <FooterActions>
        <FooterActions.Item>
          <Button
            label={isLastFile ? i18n.t('global:Finish') : i18n.t('global:Next')}
            negativeMargins
            onClick={onSubmit}
          />
        </FooterActions.Item>
      </FooterActions>
    </ModalBox>
  )
}

export default MediaTagModalView
