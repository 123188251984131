import { RESPONSE } from 'constants/http'
import {
  CLEAR_CREDIT_NOTE,
  CREATE_BULK_CREDIT_NOTE,
  CREATE_BULK_CREDIT_NOTE_FAILED,
  CREATE_BULK_CREDIT_NOTE_SUCCESS,
  CREATE_CREDIT_NOTE,
  CREATE_CREDIT_NOTE_FAILED,
  CREATE_CREDIT_NOTE_SUCCESS,
  DOWNLOAD_CREDIT_NOTE,
  DOWNLOAD_CREDIT_NOTE_FAILED,
  DOWNLOAD_CREDIT_NOTE_SUCCESS,
  GET_CREDIT_NOTE,
  GET_CREDIT_NOTE_FAILED,
  GET_CREDIT_NOTE_SUCCESS,
  SEND_CREDIT_NOTE,
  SEND_CREDIT_NOTE_FAILED,
  SEND_CREDIT_NOTE_SUCCESS,
} from './constants'

export const get = (id, params) => ({ creditNotesApiClient }) => (dispatch) => {
  dispatch({
    id,
    type: GET_CREDIT_NOTE,
  })

  creditNotesApiClient.get(id, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: GET_CREDIT_NOTE_SUCCESS,
      })
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: GET_CREDIT_NOTE_FAILED,
      })
    },
  )
}

export const create = (payload, onSuccess, onFailed) => ({ creditNotesApiClient }) => (dispatch) => {
  dispatch({
    payload,
    type: CREATE_CREDIT_NOTE,
  })

  creditNotesApiClient.create(payload).then(
    (response) => {
      dispatch({
        payload: response,
        type: CREATE_CREDIT_NOTE_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: CREATE_CREDIT_NOTE_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const createBulk = (payload, options = {}) => ({ creditNotesApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params } = options

  dispatch({
    payload,
    type: CREATE_BULK_CREDIT_NOTE,
  })

  creditNotesApiClient.createBulk(payload, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: CREATE_BULK_CREDIT_NOTE_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: CREATE_BULK_CREDIT_NOTE_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const send = (id, onSuccess, onFailed) => ({ creditNotesApiClient }) => (dispatch) => {
  dispatch({
    id,
    type: SEND_CREDIT_NOTE,
  })

  creditNotesApiClient.send(id).then(
    (response) => {
      dispatch({
        payload: response,
        type: SEND_CREDIT_NOTE_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      const { response, status } = error

      if (RESPONSE.HTTP_200_OK === status && onSuccess) {
        onSuccess()
      }

      if (RESPONSE.HTTP_200_OK !== status && onFailed) {
        onFailed(error)
      }

      dispatch({
        error: response,
        type: SEND_CREDIT_NOTE_FAILED,
      })
    },
  )
}

export const download = (id, onSuccess) => ({ creditNotesApiClient }) => (dispatch) => {
  dispatch({
    id,
    type: DOWNLOAD_CREDIT_NOTE,
  })

  creditNotesApiClient.download(id).then(
    (response) => {
      dispatch({
        payload: response,
        type: DOWNLOAD_CREDIT_NOTE_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: DOWNLOAD_CREDIT_NOTE_FAILED,
      })
    },
  )
}

export const clear = () => ({
  type: CLEAR_CREDIT_NOTE,
})
