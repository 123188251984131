export const CREATE_ENQUIRY_VIEWINGS = 'CREATE_ENQUIRY_VIEWINGS'
export const CREATE_ENQUIRY_VIEWINGS_SUCCESS = 'CREATE_ENQUIRY_VIEWINGS_SUCCESS'
export const CREATE_ENQUIRY_VIEWINGS_FAILED = 'CREATE_ENQUIRY_VIEWINGS_FAILED'

export const GET_ENQUIRY_VIEWINGS = 'GET_ENQUIRY_VIEWINGS'
export const GET_ENQUIRY_VIEWINGS_SUCCESS = 'GET_ENQUIRY_VIEWINGS_SUCCESS'
export const GET_ENQUIRY_VIEWINGS_FAILED = 'GET_ENQUIRY_VIEWINGS_FAILED'

export const UPDATE_ENQUIRY_VIEWINGS = 'UPDATE_ENQUIRY_VIEWINGS'
export const UPDATE_ENQUIRY_VIEWINGS_SUCCESS = 'UPDATE_ENQUIRY_VIEWINGS_SUCCESS'
export const UPDATE_ENQUIRY_VIEWINGS_FAILED = 'UPDATE_ENQUIRY_VIEWINGS_FAILED'

export const CLEAR_ENQUIRY_VIEWINGS_SINGLE = 'CLEAR_ENQUIRY_VIEWINGS_SINGLE'
