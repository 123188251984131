import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  GET_NURSERY_STRIPE_INTEGRATION,
  GET_NURSERY_STRIPE_INTEGRATION_FAILED,
  GET_NURSERY_STRIPE_INTEGRATION_SUCCESS,
} from './constants'

export const getNurseryStripeIntegration = (
  options: BasicActionOptionsProps,
) => ({ nurseriesApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_NURSERY_STRIPE_INTEGRATION_FAILED,
    init: GET_NURSERY_STRIPE_INTEGRATION,
    success: GET_NURSERY_STRIPE_INTEGRATION_SUCCESS,
  },
  options,
  service: nurseriesApiClient,
  serviceMethod: 'getStripeIntegration',
})
