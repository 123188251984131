import _ from 'lodash'

import React from 'react'

import { StyledContent, StyledHeader, StyledTimeCallout } from './TimeCalloutStyled'

const TimeCallout = ({
  content,
  header,
  isOngoing,
  isOverlapping,
  noBottomMargin,
  type,
  worklogType,
}) => {
  if (!content || (_.isArray(content) && !_.find(content, (i) => !!i))) {
    return null
  }

  return (
    <StyledTimeCallout
      isOngoing={isOngoing}
      isOverlapping={isOverlapping}
      noBottomMargin={noBottomMargin}
      type={type}
      worklogType={worklogType}
    >
      {header && !isOverlapping && (
        <StyledHeader type={type} worklogType={worklogType}>
          {header}
        </StyledHeader>
      )}
      <StyledContent isOverlapping={isOverlapping}>
        {content}
      </StyledContent>
    </StyledTimeCallout>
  )
}

export default TimeCallout
