import React from 'react'

import { Masonry } from 'components'

import {
  DevelopmentBreakdown,
  EnrolmentBreakdown,
  FamilyBreakdown,
  GeneralBreakdown,
  HealthAndDietBreakdown,
  LikesDislikesBreakdown,
  PermissionsBreakdown,
} from './components'
import { StyledContainer } from './ChildPreviewStyled'

const ChildPreview = ({
  child = {},
  isFormContext,
  drinkingMethods,
  maritalStatuses,
  permissionToEdit,
  toiletTrainingOptions,
}) => (
  <StyledContainer>
    <Masonry>
      <GeneralBreakdown
        child={child}
        isFormContext={isFormContext}
        permissionToEdit={permissionToEdit}
      />
      <HealthAndDietBreakdown
        child={child}
        isFormContext={isFormContext}
        permissionToEdit={permissionToEdit}
      />
      <FamilyBreakdown
        child={child}
        isFormContext={isFormContext}
        maritalStatuses={maritalStatuses}
        permissionToEdit={permissionToEdit}
      />
      <EnrolmentBreakdown
        child={child}
        isFormContext={isFormContext}
        permissionToEdit={permissionToEdit}
      />
      <DevelopmentBreakdown
        child={child}
        drinkingMethods={drinkingMethods}
        isFormContext={isFormContext}
        permissionToEdit={permissionToEdit}
        toiletTrainingOptions={toiletTrainingOptions}
      />
      <PermissionsBreakdown
        child={child}
        drinkingMethods={drinkingMethods}
        isFormContext={isFormContext}
        permissionToEdit={permissionToEdit}
        toiletTrainingOptions={toiletTrainingOptions}
      />
      <LikesDislikesBreakdown
        child={child}
        isFormContext={isFormContext}
        permissionToEdit={permissionToEdit}
      />
    </Masonry>
  </StyledContainer>
)

export default ChildPreview
