import React from 'react'

import { Typography } from 'components'

import { StyledRowContainer, StyledRowContentContainer, StyledRowInfoContainer } from './SignatureListStyled'

const SignatureListRow = ({ content, label, value }) => (
  <StyledRowContainer>
    <StyledRowInfoContainer>
      {label && (
        <Typography padding="0 0 5px">
          {label}
        </Typography>
      )}
      <Typography fontSize={18} bold>
        {value}
      </Typography>
    </StyledRowInfoContainer>
    <StyledRowContentContainer>
      {content}
    </StyledRowContentContainer>
  </StyledRowContainer>
)

export default SignatureListRow
