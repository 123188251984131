import _ from 'lodash'

import React, { useEffect } from 'react'
import { compose } from 'recompose'

import { SelectProps } from 'components/Select/Select'
import { InfinityDropdownProps } from 'components/InfiniteDropdowns/InfiniteDropdownsModel'
import { CLOSURE_DAY_TYPE_FILTER, CLOSURE_DAY_TYPE_FILTER_OPTIONS } from 'services/closureDayTypes/models'

import { withClosureDayTypesService, withClosureDayTypesServiceProps } from 'services/closureDayTypes'

import { Select } from 'components'

import i18n from 'translations'

// eslint-disable-next-line import-newlines/enforce, max-len
import withInfiniteDropdownHelpers, { withInfiniteDropdownHelpersProps } from '../../legacy/InfiniteDropdowns/withInfiniteDropdown'

export type ClosureDayTypesDropdownProps = withInfiniteDropdownHelpersProps
  & withClosureDayTypesServiceProps
  & SelectProps
  & InfinityDropdownProps

const ClosureDayTypesDropdown: React.FC<ClosureDayTypesDropdownProps> = ({
  extraFields = [],
  infiniteDropdownHelpers,
  closureDayTypesActions,
  closureDayTypesSelectors,
  onChange,
  value,
  ...rest
}) => {
  useEffect(() => {
    infiniteDropdownHelpers.handleComponentDidMount({
      action: () => closureDayTypesActions.get({
        onFailed: () => onChange(null),
        onSuccess: infiniteDropdownHelpers.handleComponentDidMountOnSuccess,
        onlyData: true,
        params: [(value as any)?.value || value],
      }),
      value,
    })
  }, [])

  const listAction = ({ newPage, newSearchPhrase, ...others }) => {
    const criteria = closureDayTypesSelectors.getCriteria({
      name: newSearchPhrase,
      statusFilter: _.find(CLOSURE_DAY_TYPE_FILTER_OPTIONS, {
        value: CLOSURE_DAY_TYPE_FILTER.ACTIVE,
      }),
    })

    return closureDayTypesActions.dropdownList({
      onSuccess: infiniteDropdownHelpers.handleLoadMoreElementsOnSuccessV2({
        extraFields,
        newPage,
        newSearchPhrase,
        ...others,
      }),
      params: {
        criteria,
        page: newPage,
      },
    })
  }

  return (
    <Select
      {...infiniteDropdownHelpers.getSelectRestParams({ onChange, value, ...rest })}
      loadOptions={infiniteDropdownHelpers.handleLoadMoreElementsV2({ listAction })}
      placeholder={i18n.t('components:InfiniteDropdowns:ClosureDayTypes:placeholder')}
    />
  )
}

const enhance = compose(
  withClosureDayTypesService,
  withInfiniteDropdownHelpers,
)

export default enhance(ClosureDayTypesDropdown)
