import ServiceBase from 'services/ServiceBase'

class UploadApiClient extends ServiceBase {
  getUploadConfig = async (body) => this.request({
    body,
    method: 'POST',
    path: '/upload/s3',
  })

  initFile = async (body) => this.request({
    body,
    method: 'POST',
    path: '/v2/upload/s3',
  })
}

export default UploadApiClient
