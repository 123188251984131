export const SITE_NAME = 'Blossom Educational'

export const STORE_KEY_NAME_IN_INDEX_DB = 'blsm'

export const APP_ENV = {
  LOCAL: 'LOCAL',
}

export const noop = () => {}

export type ORDER = 'ASC' | 'DESC'

export const SORT_ORDER = {
  ASC: 'ASC',
  DESC: 'DESC',
}
