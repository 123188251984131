import _ from 'lodash'

import Cookie, { CookieAttributes } from 'js-cookie'

import { APP_ENV } from 'constants/global'

import { properties } from 'app-config'

export default class CookieIdentityService {
  static getOptions = ({ domain, expires, path }: CookieAttributes) => {
    const options: CookieAttributes = {}

    if (domain) {
      options.domain = domain
    }

    if (expires) {
      options.expires = expires
    }

    if (path) {
      options.path = path
    }

    return options
  }

  static getIdentity() {
    try {
      return JSON.parse(Cookie.get(properties.identityKey))
    } catch (err) {}

    return null
  }

  static getPinIdentity() {
    try {
      return JSON.parse(Cookie.get(properties.pinIdentityKey))
    } catch (err) {}

    return null
  }

  static isIdentitySet = (identityKey) => {
    const cookies = Cookie.get()

    return !!cookies[identityKey]
  }

  static setIdentity = (identity, {
    domain,
    expires = properties.identityExpireTime,
    path = '/',
    key = properties.identityKey,
  }) => {
    let finalDomain = domain

    if (APP_ENV.LOCAL === properties.env && properties.debugSubdomain) {
      finalDomain = null
    }

    Cookie.set(
      key,
      _.isString(identity) ? identity : JSON.stringify(identity),
      CookieIdentityService.getOptions({ domain: finalDomain, expires, path }),
    )
  }

  static removeIdentity = ({
    domain,
    path = '/',
    key = properties.identityKey,
    removeIdentityOnly = false,
  }) => {
    let finalDomain = domain

    if (APP_ENV.LOCAL === properties.env && properties.debugSubdomain) {
      finalDomain = null
    }

    if (!removeIdentityOnly) {
      Cookie.remove(properties.assetsToken, CookieIdentityService.getOptions({ domain: finalDomain, path }))
    }

    Cookie.remove(key, CookieIdentityService.getOptions({ domain, path }))
  }
}
