import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { generateRoute } from 'utils/routing'
import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withMembershipsService } from 'services/legacy/memberships'
import { withNurseriesService } from 'services/nurseries'
import { withSecurityService } from 'services/security'
import { withShellService } from 'services/shell'
import { withRouter } from 'services/router'

import StaffLeaveDetails from 'module/Staff/StaffLeaveDetails'
import StaffFileUploads from 'module/Staff/StaffFileUploads'
import StaffContracts from 'module/Staff/StaffContracts'
import withStaffHoc from 'module/Staff/withStaffHoc'

import StaffView from './StaffView'

const GROUPS = {
  read: [
    'membership.primaryManager',
    'membership.user',
    'membership.details',
  ],
}

class StaffContainer extends Component {
  constructor(props) {
    super(props)

    this.indexRedirect(props)
  }

  componentDidMount() {
    const {
      membershipsActions,
      params: { userId },
    } = this.props

    if (userId) {
      membershipsActions.get(userId, {
        params: { groups: GROUPS },
      })
    } else {
      membershipsActions.getMe({
        params: { groups: GROUPS },
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { membership, shellActions } = this.props

    if (
      membership?.name
      && prevProps?.membership?.name !== membership?.name
    ) {
      shellActions.setRouteTitle({
        name: 'STAFF.PROFILE',
        title: membership?.name,
      })
    }
  }

  shouldComponentUpdate(nextProps) {
    this.indexRedirect(nextProps)

    return true
  }

  componentWillUnmount() {
    const { membershipsActions, nurseriesActions } = this.props

    membershipsActions.clear()
    nurseriesActions.clear()
  }

  indexRedirect = (props) => {
    const { location: { pathname }, navigate, params: { userId } } = props

    if (generateRoute('STAFF.PROFILE', { userId }) === pathname) {
      navigate(generateRoute('STAFF.PROFILE.ABOUT', { userId }))
    }

    if (pathname === generateRoute('ACCOUNT.INDEX')) {
      navigate(generateRoute('ACCOUNT.ABOUT'))
    }
  }

  render() {
    const {
      authAccessMap,
      children,
      isMyDetailsContext,
      isNurseryContext,
      membership,
    } = this.props

    if (!membership) {
      return null
    }

    return (
      <StaffView
        authAccessMap={authAccessMap}
        isMyDetailsContext={isMyDetailsContext}
        isNurseryContext={isNurseryContext}
        membership={membership}
      >
        {children}
      </StaffView>
    )
  }
}

const mapState = (state, { membershipsSelectors, securitySelectors }) => ({
  authAccessMap: {
    module: {
      StaffContracts: auth.SELECTORS.getComponentIsAuthorised(state, StaffContracts),
      StaffFiles: auth.SELECTORS.getComponentIsAuthorised(state, StaffFileUploads),
      StaffLeave: auth.SELECTORS.getComponentIsAuthorised(state, StaffLeaveDetails),
    },
  },
  isNurseryContext: securitySelectors.isNurseryContext(state),
  membership: membershipsSelectors.getMembershipDataSelector(state),
})

const enhance = compose(
  withAppService,
  withMembershipsService,
  withNurseriesService,
  withRouter,
  withSecurityService,
  withShellService,
  withStaffHoc,
  connect(mapState),
)

export default enhance(StaffContainer)
