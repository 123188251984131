import _ from 'lodash'

import React from 'react'
import { Field, FieldArray, InjectedFormProps, getFormValues, reduxForm } from 'redux-form'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { Option } from 'constants/models'
import { isEmailValid } from 'utils/fieldValidation'
import { RootState } from 'core/reducers'
import { Carer } from 'services/legacy/carers/models'

import { generateRoute } from 'utils/routing'

import { Button, Callout, Form, Section, TextField, Typography } from 'components'

import i18n from 'translations'

import {
  StyledCarerDropdown,
  StyledCarerInfoContainer,
  StyledColumnContainer,
} from './ChildFinanceAddContactFormStyled'
import { carerInfo, getCarerInfo, isCustomEmailRequired } from '../ChildFinanceAddContactHelpers'

export const CHILD_FINANCE_ADD_CONTACT_FORM = 'ChildFinanceAddContactForm'

const renderInvoiceRecipientsItem = (
  fields,
  onDeleteItemClick,
  childId,
  carerList,
  invoiceRecipients,
) => (item, index) => {
  const invoiceRecipient = invoiceRecipients[index]

  return (
    <StyledCarerInfoContainer key={_.kebabCase(index)}>
      <StyledCarerDropdown>
        <Field
          childId={childId}
          component={Form.InfiniteDropdowns.Carers}
          name={`${item}.carer`}
          placeholder={i18n.t('module:Children:Child:Finance:ContactDetails:ContactForm:SelectContact:placeholder')}
          bodyTarget
        />
        <Form.RemoveRowButton onClick={() => onDeleteItemClick(fields, index)} />
      </StyledCarerDropdown>
      {carerInfo(carerList, invoiceRecipient?.carer?.value)}
    </StyledCarerInfoContainer>
  )
}

const renderInvoiceRecipients = ({
  carerList,
  childId,
  fields,
  invoiceRecipients,
  onDeleteItemClick,
}) => (
  <React.Fragment>
    {fields.map(
      renderInvoiceRecipientsItem(
        fields,
        onDeleteItemClick,
        childId,
        carerList,
        invoiceRecipients,
      ),
    )}
    <Form.Row>
      <Form.Row.Item>
        <Button.ActionButton
          label={i18n.t('global:Add')}
          onClick={() => fields.push({ carer: null })}
        />
      </Form.Row.Item>
    </Form.Row>
  </React.Fragment>
)

export interface ChildFinanceAddContactFormValues {
  carerId?: Option
  fundingLoopContact?: Option
  invoiceRecipients?: Option[]
  isCustomEmail?: boolean
  paymentEmail?: string
}

export interface ChildFinanceAddContactFormProps {
  carerList: Carer[]
  childId?: number
  errorMessages: string
  isCustomEmail: boolean
  isFinanceV3Enabled: boolean
  isLoading: boolean
  onDeleteItemClick: (fields: any, index: number) => void
  onSubmit: (values: ChildFinanceAddContactFormValues) => void
}

const mapState = (state: RootState) => ({
  formValues: getFormValues(CHILD_FINANCE_ADD_CONTACT_FORM)(state) as ChildFinanceAddContactFormValues,
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type AddFormFullProps = InjectedFormProps<{}, ChildFinanceAddContactFormProps>
  & ChildFinanceAddContactFormProps
  & PropsFromRedux

const ChildFinanceAddContactForm: React.FC<AddFormFullProps> = ({
  carerList,
  childId,
  errorMessages,
  formValues,
  handleSubmit,
  isCustomEmail,
  isFinanceV3Enabled,
  isLoading,
  onDeleteItemClick,
  onSubmit,
}) => {
  const carerId = formValues?.carerId?.value
  const fundingLoopCarerId = formValues?.fundingLoopContact?.value
  const carerDetail = getCarerInfo(carerList, carerId)
  const invoiceRecipients = formValues?.invoiceRecipients

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Callout content={errorMessages} error />
      <Section title={i18n.t('module:Children:Child:Finance:ContactDetails:contactAndBankDetails')}>
        <StyledColumnContainer>
          <Typography overflow="hidden" variant="div">
            <Typography margin="0 0 10px" bold>
              {i18n.t('module:Children:Child:Finance:ContactDetails:primaryInvoicingContact')}
            </Typography>
            <Form.Row
              width={{ field: '100%' }}
              multipleFieldsInARow
              verticalLabel
            >
              <Field
                childId={childId}
                clearable={false}
                component={Form.InfiniteDropdowns.Carers}
                name="carerId"
                placeholder={
                  i18n.t('module:Children:Child:Finance:ContactDetails:ContactForm:SelectContact:placeholder')
                }
                bodyTarget
              />
            </Form.Row>
            {carerInfo(carerList, carerId)}
            {carerId && (
              <Typography margin="30px 0 0 0">
                <Field
                  component={Form.Checkbox}
                  label={i18n.t('module:Children:Child:Finance:ContactDetails:ContactForm:UseCustomEmail:label')}
                  name="isCustomEmail"
                />
                <Typography margin="10px 0 0">
                  {isCustomEmail ? (
                    <Field
                      component={Form.TextField}
                      disabled={!isCustomEmail}
                      name="paymentEmail"
                      placeholder={i18n.t('module:Children:Child:Finance:ContactDetails:emailLabel')}
                      validate={[isCustomEmailRequired, isEmailValid]}
                    />
                  ) : (
                    <TextField
                      value={carerDetail?.email}
                      disabled
                    />
                  )}
                </Typography>
              </Typography>
            )}
          </Typography>
          {isFinanceV3Enabled && (
            <Typography overflow="hidden" variant="div">
              <Typography margin="0 0 10px" bold>
                {i18n.t('module:Children:Child:Finance:ContactDetails:fundingLoopContact')}
              </Typography>
              <Form.Row
                width={{ field: '100%' }}
                multipleFieldsInARow
                verticalLabel
              >
                <Form.Row.Item>
                  <Field
                    childId={childId}
                    component={Form.InfiniteDropdowns.Carers}
                    name="fundingLoopContact"
                    placeholder={
                      i18n.t('module:Children:Child:Finance:ContactDetails:ContactForm:SelectContact:placeholder')
                    }
                    bodyTarget
                  />
                </Form.Row.Item>
              </Form.Row>
              {carerInfo(carerList, fundingLoopCarerId)}
            </Typography>
          )}
          <Typography overflow="hidden" variant="div">
            <Typography margin="0 0 10px" bold>
              {i18n.t('module:Children:Child:Finance:ContactDetails:AdditionalInvoiceRecipients')}
            </Typography>
            <FieldArray
              carerList={carerList}
              childId={childId}
              component={renderInvoiceRecipients}
              invoiceRecipients={invoiceRecipients}
              name="invoiceRecipients"
              onDeleteItemClick={onDeleteItemClick}
            />
          </Typography>
        </StyledColumnContainer>
      </Section>
      <Form.FooterActions
        cancelLink={generateRoute('CHILDREN.CHILD.FINANCE.INVOICING', { childId })}
        isSubmitting={isLoading}
        submitLabel={i18n.t('global:Save')}
      />
    </Form>
  )
}

const validate = (fields, props) => {
  const errors = {} as any

  const { carerId, fundingLoopContact, invoiceRecipients } = fields
  const { carerList } = props

  if (carerId) {
    const carerRelation = _.find(carerList, (item) => item?.carer?.id === carerId?.value)

    if (carerRelation?.carer && !carerRelation?.carer.email) {
      errors.carerId = i18n.t('module:Children:Child:Finance:ContactDetails:Validation:carerEmailNotFound')
    }
  }

  if (fundingLoopContact) {
    const carerRelation = _.find(carerList, (item) => item?.carer?.id === fundingLoopContact?.value)

    if (carerRelation?.carer && (
      !carerRelation?.carer.email
      || !carerRelation?.carer.address
      || !carerRelation?.carer.postCode
    )) {
      errors.fundingLoopContact = i18n.t(
        'module:Children:Child:Finance:ContactDetails:Validation:fundingLoopCarerDetailsNotFound',
      )
    }
  }

  if (invoiceRecipients?.length) {
    errors.invoiceRecipients = _.map(invoiceRecipients, ({ carer }) => {
      if (!carer?.value || !carerId?.value) {
        return null
      }

      if (carer.value === carerId?.value) {
        return {
          carer: i18n.t('module:Children:Child:Finance:ContactDetails:Validation:invoiceRecipientSameAsPrimary'),
        }
      }

      const carerRelation = _.find(carerList, (item) => item?.carer?.id === carer?.value)

      if (carerRelation?.carer && !carerRelation?.carer.email) {
        return {
          carer: i18n.t('module:Children:Child:Finance:ContactDetails:Validation:carerEmailNotFound'),
        }
      }

      return {
        carer: null,
      }
    })
  }

  return errors
}

const enhance = compose(
  connector,
)

export default reduxForm<{}, ChildFinanceAddContactFormProps>({
  form: CHILD_FINANCE_ADD_CONTACT_FORM,
  validate,
})(enhance(ChildFinanceAddContactForm))
