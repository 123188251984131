import styled from 'styled-components'

export const StyledItemContainer = styled.div`
  background-color: #F7F7F7;
  border-radius: 10px;
  display: flex;
  padding: 10px;
`

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: -10px;
  
  & ${StyledItemContainer} ~ ${StyledItemContainer} {
    margin-top: 10px;
  }
`

export const StyledButtonItemContainer = styled.div`
  display: flex;
  padding: 10px;
`
