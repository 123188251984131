import React from 'react'
import { Link } from 'react-router'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { Icon, Typography, XMasIcon } from 'components'
import Widget from 'module/Dashboard/components/Widget'

import { StyledCustomButton } from './ObservationDiaryButtonsWidgetStyled'

const ObservationDiaryButtonsWidgetView = ({ onSendDailyDiaryButtonClick }) => (
  <Widget part>
    <Widget.Part>
      <Link
        to={generateRoute('LEARNING.OBSERVATIONS.ADD')}
        onlyActiveOnIndex
      >
        <StyledCustomButton>
          <XMasIcon height="68px" icon="holly-left" marginLeft="-268px" marginTop="-77px" width="85px" />
          <XMasIcon height="68px" icon="holly-right" marginLeft="289px" marginTop="-77px" width="85px" />
          <Icon
            color={NEUTRAL_COLOURS.WHITE}
            icon="observations"
          />
          <Typography
            color={NEUTRAL_COLOURS.WHITE}
            transform="uppercase"
          >
            Make observation
          </Typography>
          <XMasIcon height="90px" icon="santa-hat" marginLeft="279px" marginTop="90px" width="100px" />
        </StyledCustomButton>
      </Link>
    </Widget.Part>
    <Widget.Part>
      <StyledCustomButton
        onClick={onSendDailyDiaryButtonClick}
      >
        <XMasIcon height="40px" icon="snowflake" marginLeft="-274px" marginTop="-71px" width="46px" />
        <Icon
          color={NEUTRAL_COLOURS.WHITE}
          icon="send"
        />
        <Typography
          color={NEUTRAL_COLOURS.WHITE}
          transform="uppercase"
        >
          Send diary
        </Typography>
        <XMasIcon height="75px" icon="present-box" marginLeft="272px" marginTop="85px" width="70px" />
      </StyledCustomButton>
    </Widget.Part>
  </Widget>
)

export default ObservationDiaryButtonsWidgetView
