import styled from 'styled-components'

export const StyledHeaderOfConfirm = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`

export const StyledConfirmed = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`

export const StyledActions = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
`

export const StyledAction = styled.div`
  padding: 0 5px;
`
