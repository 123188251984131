import React, { useEffect, useRef, useState } from 'react'

const CalloutContent = ({ children }) => {
  const contentRef = useRef()
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    if (contentRef.current.scrollWidth > contentRef.current.clientWidth) {
      setVisible(false)
    }
  }, [setVisible])

  return (
    <div style={{ visibility: visible ? 'visible' : 'hidden' }}>
      <div ref={contentRef}>
        {children}
      </div>
    </div>
  )
}

export default CalloutContent
