import { generateBasicActions } from 'utils/service'

import { CLEAR_TERMS, GET_TERMS, GET_TERMS_FAILED, GET_TERMS_SUCCESS } from './constants'

export const getTerms = (options = {}) => ({ formativeReportsApiClient }) => (
  generateBasicActions.list({
    constants: {
      failed: GET_TERMS_FAILED,
      init: GET_TERMS,
      success: GET_TERMS_SUCCESS,
    },
    options,
    service: formativeReportsApiClient,
    serviceMethod: 'getTerms',
  })
)

export const clearTerms = () => ({
  type: CLEAR_TERMS,
})
