import { compose } from 'recompose'
import React, { Component } from 'react'
import i18n from 'translations'

import { withModalService } from 'services/utils/modal'

import { Hyperlink } from 'components'

class InvoiceDetailRightComponent extends Component {
  handleClick = () => {
    const { modalActions, modalConsts, value } = this.props

    modalActions.show(modalConsts.TYPES.INVOICE_DETAIL_PREVIEW, { value })
  }

  render() {
    return (
      <Hyperlink margin="0 0 0 6px" primary onClick={this.handleClick}>
        {i18n.t('components:Settings:InvoiceSettings:InvoiceDetail:previewButtonLabel')}
      </Hyperlink>
    )
  }
}

const enhance = compose(
  withModalService,
)

export default enhance(InvoiceDetailRightComponent)
