import _ from 'lodash'

import React from 'react'

import {
  BREAK_ALLOWANCE_OPTIONS,
  CONTRACT_TYPE,
  CONTRACT_TYPE_OPTIONS,
  OVERTIME_TREATMENT_OPTIONS,
} from 'services/legacy/contracts/constants'

import { convertTimeDuration, millisecondsToHoursAndMinutesString } from 'utils/date'

import { Currency, DetailsBreakdown, Typography } from 'components'

import i18n from 'translations'

import ContractPeriod from 'module/Staff/StaffContracts/components/ContractPeriod'

const StaffContractsPreviewDetailsBreakdown = ({
  contract,
  editLink,
  isGrantedEdit,
  salaryPerMonth,
}) => {
  const {
    breakAllowances,
    contractedHours,
    endDate,
    hourlySalary,
    jobTitle,
    ongoing,
    overtimeTreatment,
    salary,
    startDate,
    status,
    type,
  } = contract || {}

  const renderHeader = () => (
    <DetailsBreakdown.Header edit={{ auth: isGrantedEdit, to: editLink }}>
      <Typography fontSize={28} fontWeight={300}>
        {i18n.t('module:Staff:StaffContracts:Preview:general')}
      </Typography>
    </DetailsBreakdown.Header>
  )

  const renderContractPeriod = () => (
    <ContractPeriod
      endDate={endDate}
      ongoing={ongoing}
      startDate={startDate}
      status={status}
      wrap
    />
  )

  const renderSalary = () => (
    <React.Fragment>
      <Typography>
        <Currency value={salary} />
        {` / ${i18n.t('global:year')}`}
      </Typography>
      <Typography>
        <Currency value={salaryPerMonth} />
        {` / ${i18n.t('global:month')}`}
      </Typography>
    </React.Fragment>
  )

  const renderHourlySalary = () => (
    <React.Fragment>
      <Currency value={hourlySalary} />
      {` / ${i18n.t('global:hour')}`}
    </React.Fragment>
  )

  const renderBreakAllowance = () => {
    if (!breakAllowances?.length) {
      return null
    }

    return _.map(breakAllowances, ({ endTime, startTime, time, type: allowanceType }) => {
      const allowanceTypeLabel = _.toLower(_.find(BREAK_ALLOWANCE_OPTIONS, { value: allowanceType })?.label)

      return (
        <div>
          {convertTimeDuration(time, 'milliseconds', 'minutes')}
          {' '}
          {i18n.t('global:minutes')}
          {' '}
          {allowanceTypeLabel}
          {' '}
          {i18n.t('module:Staff:StaffContracts:DailyBreakAllowance:conditionText')}
          {' '}
          {startTime ? millisecondsToHoursAndMinutesString(startTime) : 0 }
          {' - '}
          {endTime ? millisecondsToHoursAndMinutesString(endTime) : 0 }
          {' '}
          {i18n.t('module:Staff:StaffContracts:DailyBreakAllowance:workingTime')}
        </div>
      )
    })
  }

  return (
    <DetailsBreakdown>
      <DetailsBreakdown.Section>
        {renderHeader()}
        <DetailsBreakdown.Item
          label={i18n.t('module:Staff:StaffContracts:Labels:type')}
          value={_.find(CONTRACT_TYPE_OPTIONS, { value: type })?.label}
        />
        <DetailsBreakdown.Item
          label={i18n.t('module:Staff:StaffContracts:Labels:jobTitle')}
          value={jobTitle}
        />
        <DetailsBreakdown.Item
          label={i18n.t('module:Staff:StaffContracts:Labels:contractDate')}
          value={startDate && renderContractPeriod()}
        />
        {CONTRACT_TYPE.PERMANENT === type && (
          <React.Fragment>
            <DetailsBreakdown.Item
              label={i18n.t('module:Staff:StaffContracts:Labels:ContractedHours:long')}
              value={millisecondsToHoursAndMinutesString(contractedHours, true)}
            />
            <DetailsBreakdown.Item
              label={i18n.t('module:Staff:StaffContracts:Labels:salary')}
              value={salary && renderSalary()}
            />
          </React.Fragment>
        )}
        <DetailsBreakdown.Item
          label={i18n.t('module:Staff:StaffContracts:Labels:hourlySalary')}
          value={hourlySalary && renderHourlySalary()}
        />
        {CONTRACT_TYPE.PERMANENT === type && (
          <DetailsBreakdown.Item
            label={i18n.t('module:Staff:StaffContracts:Labels:overtimeTreatment')}
            value={_.find(OVERTIME_TREATMENT_OPTIONS, { value: overtimeTreatment })?.label}
          />
        )}
        <DetailsBreakdown.Item
          label={i18n.t('module:Staff:StaffContracts:DailyBreakAllowance:label')}
          value={renderBreakAllowance()}
        />
      </DetailsBreakdown.Section>
    </DetailsBreakdown>
  )
}

export default StaffContractsPreviewDetailsBreakdown
