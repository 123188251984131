import _ from 'lodash'

import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { FILE_TYPES } from 'constants/mimetypes'

import { isRequired } from 'utils/fieldValidation'

import { Form } from 'components'

import i18n from 'translations'

import { StyledExtension } from '../MediaTagModalStyled'

export const MEDIA_TAG_MODAL_FORM = 'MediaTagModalForm'

const MediaTagModalForm = ({
  file,
}) => (
  <Form>
    <Form.Row label={i18n.t('module:Modals:MediaTagModal:fileName')}>
      <Form.Row.FlexItem mobileFull>
        <Field
          component={Form.TextField}
          maxLength={100}
          name="name"
          placeholder={i18n.t('module:Modals:MediaTagModal:fileName')}
          validate={isRequired}
        />
      </Form.Row.FlexItem>
      <StyledExtension>
        {_.get(_.find(FILE_TYPES, ({ value }) => file?.mimeType === value), 'label')}
      </StyledExtension>
    </Form.Row>
  </Form>
)

export default reduxForm({ form: MEDIA_TAG_MODAL_FORM })(MediaTagModalForm)
