import React, { PropsWithChildren } from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

import { Avatar, Typography } from 'components'

import { StyledContainer } from './RadioUserStyled'

export interface RadioUserProps {
  checked?: boolean
  firstName: string
  imageUrl?: string
  meta?: {
    error?: string
  }
  onChange: any
  skipValidation?: boolean
  subtitle?: string
  surname: string
  type?: 'checkbox' | 'radio'
  value: string
}

const RadioUser: React.FC<PropsWithChildren<RadioUserProps>> = ({
  checked,
  firstName,
  imageUrl,
  meta,
  onChange,
  skipValidation,
  subtitle,
  surname,
  type = 'radio',
  value,
}) => {
  const { error } = meta || {}

  const getBorderColor = () => {
    if (error && !skipValidation) {
      return getBrandingColor('tertiary-color')
    }

    return checked ? getBrandingColor('primary-color') : NEUTRAL_COLOURS.SILVER
  }

  const titleColor = error && !skipValidation ? getBrandingColor('tertiary-color') : getBrandingColor('primary-color')
  const borderColor = getBorderColor()
  const borderWidth = checked ? 2 : 1

  return (
    <StyledContainer $checked={checked}>
      <input checked={checked} type={type} value={value} onChange={onChange} />
      <Avatar
        borderColor={borderColor}
        borderWidth={borderWidth}
        direction="vertical"
        initials={[firstName, surname]}
        src={imageUrl}
        subTitle={subtitle}
        title={(
          <Typography color={titleColor} fontSize={14}>
            {`${firstName} ${surname}`}
          </Typography>
        )}
      />
    </StyledContainer>
  )
}

export default RadioUser
