import { CLEAR_TERMS, GET_TERMS, GET_TERMS_FAILED, GET_TERMS_SUCCESS } from './constants'

const initialState = {
  data: null,
  isFetching: false,
}

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_TERMS:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_TERMS_SUCCESS: {
      const { data } = payload

      return {
        ...state,
        data,
        isFetching: false,
      }
    }
    case GET_TERMS_FAILED:
      return { ...state, isFetching: false }
    case CLEAR_TERMS:
      return initialState
    default:
      return state
  }
}
