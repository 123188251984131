import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { CursorTypes } from 'constants/css'

import { getBrandingColor } from 'utils/branding'
import { typeByObject } from 'utils/typescript'

import { AVATAR_DIRECTION } from './Avatar'

export const FONT_SIZE_PROPORTION = 2.22

interface StyledAvatarContainerProps {
  $avatarSize: number
  $borderAvatar?: boolean
  $borderColor?: string
  $borderWidth?: number
  $isArchived?: boolean
  $isEmpty?: boolean
}

export const StyledAvatarContainer = styled.div<StyledAvatarContainerProps>`
  border-radius: 50%;
  width: ${({ $avatarSize }) => $avatarSize}px;
  height: ${({ $avatarSize }) => $avatarSize}px;
  min-width: ${({ $avatarSize }) => $avatarSize}px;
  min-height: ${({ $avatarSize }) => $avatarSize}px;
  background: ${NEUTRAL_COLOURS.WHITE};
  font-weight: 600;
  font-size: ${({ $avatarSize }) => $avatarSize / FONT_SIZE_PROPORTION}px;
  color: ${getBrandingColor('tertiary-color')};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
  overflow: hidden;
  
  img {
    object-fit: cover;
  }

  ${({ $isEmpty }) => $isEmpty && `
    background: #EBEBEB;
  `}
  
  ${({ $borderAvatar, $borderColor, $borderWidth }) => $borderAvatar && `
    border: ${$borderWidth || 1}px solid ${$borderColor};
  `}

  ${({ $isArchived }) => $isArchived && `
    opacity: .5;
  `}
`

export const StyledTitle = styled.div`
  order: 1;
`

export const StyledDetails = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  word-break: break-word;
`

interface StyledContainerProps {
  $avatarSize: number
  $cursor?: CursorTypes
  $direction: typeByObject<typeof AVATAR_DIRECTION>
  $gap?: number
  $hasRightColumn: boolean
  $minWidth: number
}

export const StyledContainer = styled.div<StyledContainerProps>`
  ${({ $minWidth }) => $minWidth && `
    min-width: ${$minWidth}px;
  `}

  ${({ $avatarSize, $direction, $gap, $hasRightColumn }) => $direction === AVATAR_DIRECTION.HORIZONTAL && `
    display: grid;
    grid-column-gap: ${$gap || 8}px;
    align-items: center;
    
    ${StyledAvatarContainer} {
      align-self: flex-start;
    }
    
    ${$avatarSize && `
      width: ${$avatarSize}px;
    `}
    
    ${$hasRightColumn && $avatarSize && `
      width: auto;
      grid-template-columns: ${$avatarSize}px auto;
    `}
  `}

  ${({ $avatarSize, $direction, $gap }) => $direction === AVATAR_DIRECTION.VERTICAL && `
    display: grid;
    grid-row-gap: ${$gap || 8}px;
    align-items: center;
    justify-content: center;
    grid-row-columns: ${$avatarSize}px auto;
    
    ${StyledAvatarContainer} {
      justify-self: center;
    }
    
    ${StyledDetails} {
      text-align: center;
    }
  `}

  ${({ $cursor }) => $cursor && `
    cursor: ${$cursor};
  `}
`

interface StyledSubTitleProps {
  $subTitleAbove?: boolean
}

export const StyledSubTitle = styled.div<StyledSubTitleProps>`
  order: 2;
  color: ${NEUTRAL_COLOURS.BASIC};
  
  ${({ $subTitleAbove }) => $subTitleAbove && `
    order: 0;
  `}
`
