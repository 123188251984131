import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'
import { WrappedComponentType } from 'constants/types'
import { FEATURE_FLAGS, ROLES } from 'constants/security'

import { ModalType } from 'modals'

import { withAppService, withAppServiceProps } from 'services/app'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withSecurityService, withSecurityServiceProps } from 'services/security'
import { withOrganizationService, withOrganizationServiceProps } from 'services/organization'
import { withSnackbarService, withSnackbarServiceProps } from 'services/utils/snackbar'
import { getAuthOrganization } from 'services/security/selectors'
import { withRouter, withRouterProps } from 'services/router'

import { Space, Typography } from 'components'

import i18n from 'translations'

import ManagementNewFinanceView
  from 'module/Management/ManagementFinanceSettings/ManagementNewFinance/ManagementNewFinanceView'

const ORGANIZATION_SECURITY_SETTINGS_GROUPS = {
  read: [
    'organization.newFinance',
  ],
}

type ManagementNewFinanceContainerProps = withAppServiceProps
  & withOrganizationServiceProps
  & withModalServiceProps
  & withSecurityServiceProps
  & withSnackbarServiceProps
  & withRouterProps

const mapState = (state: RootState, { securitySelectors }) => ({
  authOrganization: getAuthOrganization(state),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const NewFinanceContainer: WrappedComponentType<ManagementNewFinanceContainerProps & PropsFromRedux> = ({
  authOrganization,
  isOrganizationContext,
  modalActions,
  organizationActions,
  snackbarActions,
}) => {
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const [newFinanceEnable, setNewFinanceEnable] = useState<boolean>(false)

  const handleSuccess = (data) => {
    const { newFinanceEnabled } = data || {}

    setIsFetching(false)
    setNewFinanceEnable(newFinanceEnabled)
  }

  useEffect(() => {
    organizationActions.get(authOrganization.id, {
      onSuccess: handleSuccess,
      params: {
        groups: ORGANIZATION_SECURITY_SETTINGS_GROUPS,
      },
    })

    return () => {
      organizationActions.clearSingle()
    }
  }, [])

  const handleChangeNewFinanceFailed = (response) => {
    if (response?.message) {
      modalActions.show<ModalType.ALERT>(ModalType.ALERT, {
        text: (
          <Typography>
            {response.message}
            {response?.extra?.organization && (
              <React.Fragment>
                <Space space="20px" />
                <Typography>
                  {response.extra.organization}
                </Typography>
              </React.Fragment>
            )}
          </Typography>
        ),
      })
    }
  }

  const handleChangeNewFinanceSuccess = (value) => {
    snackbarActions.show({
      message: i18n.t('module:Management:Finance:NewFinance:activateOrInactivateSnackbar:text', {
        status: value ? i18n.t('global:activated') : i18n.t('global:deactivated'),
      }),
    })
  }

  const handleChangeFinance = () => {
    const value = !newFinanceEnable

    setNewFinanceEnable(value)

    organizationActions.updateFinanceMigration({
      body: { newFinanceEnabled: value },
      onFailed: handleChangeNewFinanceFailed,
      onSuccess: () => handleChangeNewFinanceSuccess(value),
      params: [authOrganization.id, {
        groups: ORGANIZATION_SECURITY_SETTINGS_GROUPS,
      }],
    })
  }

  return (
    <ManagementNewFinanceView
      isFetching={isFetching}
      isOrganizationContext={isOrganizationContext}
      newFinanceEnabled={newFinanceEnable}
      onChangeNewFinance={handleChangeFinance}
    />
  )
}

NewFinanceContainer.authParams = {
  flags: [FEATURE_FLAGS.FINANCE_V3],
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
  ],
}

const enhance = compose(
  withAppService,
  withModalService,
  withSecurityService,
  withRouter,
  withSnackbarService,
  withOrganizationService,
  connector,
)

export default enhance(NewFinanceContainer)
