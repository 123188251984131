import _ from 'lodash'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { Button, Tooltip, Typography } from 'components'

import i18n from 'translations'

import { PAYMENT_TYPE_CODE } from './constants'

export const getTableData = (paymentTypesList, isUkCountry) => {
  if (!paymentTypesList?.length) {
    return null
  }

  let newPaymentTypeList = paymentTypesList

  if (!isUkCountry) {
    newPaymentTypeList = _.filter(paymentTypesList, (item) => PAYMENT_TYPE_CODE.BACS_DIRECT_DEBIT !== item.code)
  }

  return _.map(newPaymentTypeList, (paymentType) => {
    const { archived, id, integrationId, name } = paymentType

    const isEditable = !integrationId

    return {
      name: archived ? (
        <Typography>
          {name}
          {' '}
          <Typography
            color={NEUTRAL_COLOURS.GRAY}
            variant="span"
          >
            {`(${i18n.t('global:Archived')})`}
          </Typography>
        </Typography>
      ) : name,
      rowToButton: (
        <Tooltip
          title={isEditable ? null : i18n.t('module:Management:ManagementPaymentTypes:paymentMethodPreBuilt')}
        >
          <Button.TableAction
            color={!isEditable && 'gray'}
            disabled={!isEditable}
            icon={isEditable ? 'edit' : 'padlock'}
            to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.PAYMENT_TYPES.EDIT', {
              itemId: id,
            })}
          />
        </Tooltip>
      ),
    }
  })
}
