import { generateBasicActions } from 'utils/service'

import {
  CLEAR_LIST_ENQUIRY_ACTIVITIES,
  LIST_ENQUIRY_ACTIVITIES,
  LIST_ENQUIRY_ACTIVITIES_FAILED,
  LIST_ENQUIRY_ACTIVITIES_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ enquiryActivitiesApiClient }) => generateBasicActions.list({
  constants: {
    failed: LIST_ENQUIRY_ACTIVITIES_FAILED,
    init: LIST_ENQUIRY_ACTIVITIES,
    success: LIST_ENQUIRY_ACTIVITIES_SUCCESS,
  },
  options,
  service: enquiryActivitiesApiClient,
  serviceMethod: 'list',
})

export const clear = () => ({
  type: CLEAR_LIST_ENQUIRY_ACTIVITIES,
})
