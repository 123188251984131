import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { FAMILY_POSITIONS_OPTIONS } from 'services/legacy/child/constants'

import { Form } from 'components'

import i18n from 'translations'

import { CHILD_ADD_FORM } from './constants'
import ChildFormFooter from './ChildFormFooter'

const FamilyInformationForm = ({
  childId,
  handleSubmit,
  isChildArchived,
  isEditMode,
  isSubmitting,
  languagesOptions,
  maritalStatusDropdownOptions,
  onArchiveClick,
  onPreviousStageClick,
  permissionToArchive,
}) => {
  const renderForm = () => (
    <React.Fragment>
      <Form.Row
        label={i18n.t('module:Children:Child:Add:Family:Form:Siblings:label')}
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextAreaField}
            name="profile.siblings"
            placeholder={i18n.t('module:Children:Child:Add:Family:Form:Siblings:placeholder')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Children:Child:Add:Family:Form:FamilyPosition:label')}
      >
        <Form.Row.Item>
          <Field
            component={Form.Select}
            name="profile.familyPosition"
            options={FAMILY_POSITIONS_OPTIONS}
            placeholder={i18n.t('module:Children:Child:Add:Family:Form:FamilyPosition:placeholder')}
          />
        </Form.Row.Item>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Children:Child:Add:Family:Form:FamilyLanguage:label')}
      >
        <Form.Row.Item>
          <Field
            component={Form.Select}
            name="profile.familySpokenLanguage"
            options={languagesOptions}
            placeholder={i18n.t('module:Children:Child:Add:Family:Form:FamilyLanguage:placeholder')}
            multi
          />
        </Form.Row.Item>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Children:Child:Add:Family:Form:FamilyMaritalStatus:label')}
      >
        <Form.Row.Item>
          <Field
            component={Form.Select}
            name="profile.familyMaritalStatus"
            options={maritalStatusDropdownOptions}
            placeholder={i18n.t('module:Children:Child:Add:Family:Form:FamilyMaritalStatus:placeholder')}
          />
        </Form.Row.Item>
      </Form.Row>
      <Form.Row label>
        <Form.Row.Item>
          <Field
            component={Form.Checkbox}
            label={i18n.t('module:Children:Child:Add:Family:Form:ParentArmedForcesPersonnel:label')}
            name="profile.parentsAreServingArmedForcesPersonnel"
          />
        </Form.Row.Item>
      </Form.Row>
    </React.Fragment>
  )

  const renderFooter = () => (
    <ChildFormFooter
      childId={childId}
      isChildArchived={isChildArchived}
      isEditMode={isEditMode}
      isSubmitting={isSubmitting}
      permissionToArchive={permissionToArchive}
      onArchiveClick={onArchiveClick}
      onPreviousStageClick={onPreviousStageClick}
    />
  )

  return (
    <Form onSubmit={handleSubmit}>
      {renderForm()}
      {renderFooter()}
    </Form>
  )
}

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: CHILD_ADD_FORM,
})(FamilyInformationForm)
