import _ from 'lodash'
import moment from 'moment'

import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { ABSENCE_TYPE, ABSENCE_TYPE_OPTIONS } from 'module/Register/constants'

import { isRequired } from 'utils/fieldValidation'

import { Form } from 'components'

import i18n from 'translations'

export const CHILD_LEAVE_MODAL_FORM = 'ChildLeaveModalForm'

const ChildLeaveModalForm = ({
  formValues,
  handleSubmit,
  isChildContext,
  isEdit,
  isOffline,
  isSubmitting,
  onCloseClick,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row
      horizontalLabel={false}
      label={_.upperFirst(i18n.t('global:child'))}
    >
      <Form.Row.FlexItem flex="0 0 300px" mobileFull>
        <Field
          component={Form.InfiniteDropdowns.Children}
          disabled={isOffline || isChildContext || isEdit}
          name="child"
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.Row
      horizontalLabel={false}
      label={i18n.t('module:Children:Child:About:Absences:leaveType')}
      required
    >
      <Form.Row.FlexItem flex="0 0 300px" mobileFull>
        <Field
          component={Form.Select}
          name="absenceType"
          options={ABSENCE_TYPE_OPTIONS}
          placeholder={i18n.t('module:Children:Child:About:Absences:leaveType')}
          validate={isRequired}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.Row
      horizontalLabel={false}
      label={i18n.t('global:Date')}
      required
    >
      <Form.Row.FlexItem flex="0 0 300px" mobileFull>
        <Field
          component={Form.DatePicker}
          disabled={isOffline}
          name="date"
          validate={isRequired}
          range
        />
      </Form.Row.FlexItem>
    </Form.Row>
    {ABSENCE_TYPE.ABSENCE === formValues?.absenceType?.value && (
      <Form.Row
        horizontalLabel={false}
        label={i18n.t('module:Children:Child:About:Absences:absenceReason')}
      >
        <Form.Row.FlexItem flex="0 0 300px" mobileFull>
          <Field
            component={Form.InfiniteDropdowns.AbsenceReasons}
            name="absenceReason"
          />
        </Form.Row.FlexItem>
      </Form.Row>
    )}
    <Form.Row
      horizontalLabel={false}
      label={i18n.t('global:Comment')}
    >
      <Form.Row.FlexItem mobileFull>
        <Field
          component={Form.TextAreaField}
          maxCharacters={800}
          name="comments"
          placeholder={i18n.t('global:Comment')}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.FooterActions
      isSubmitting={isSubmitting}
      submitLabel={i18n.t('global:Save')}
      onCancelClick={onCloseClick}
    />
  </Form>
)

export const validate = (values) => {
  const { date } = values
  const [startDate, endDate] = date || []

  const errors = {}

  if (
    startDate
    && endDate
    && 60 < moment(endDate).diff(moment(startDate), 'days')
  ) {
    errors.date = i18n.t('module:Modals:ChildLeave:dateRangeValidation')
  }

  return errors
}

export default reduxForm({ form: CHILD_LEAVE_MODAL_FORM, validate })(ChildLeaveModalForm)
