import React from 'react'

import { ChildrenSlider } from 'components'

import ToiletTrainingTabForm from './components/ToiletTrainingTabForm'
import { StyledContainer } from './ToiletTrainingTabStyled'
import { StyledSliderContainer } from '../DailyDiaryRecordModalStyled'

const ToiletTrainingTabView = ({
  selectedChildren,
  ...rest
}) => (
  <StyledContainer>
    <StyledSliderContainer>
      <ChildrenSlider
        childrenList={selectedChildren || []}
      />
    </StyledSliderContainer>
    <ToiletTrainingTabForm {...rest} />
  </StyledContainer>
)

export default ToiletTrainingTabView
