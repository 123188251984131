import React from 'react'

import { generateRoute } from 'utils/routing'

import { Button, Callout, Field, Section, Spinner } from 'components'
import NurseriesListTable from 'module/Settings/components/NurseriesListTable'
import Settings from 'module/Settings/components'

import i18n from 'translations'

const SettingsInvoiceNumbersView = ({
  errorMessages,
  globalNumberTypeDescription,
  isAcrossOrganizationSelected,
  isEmpty,
  isLoading,
  nurseries,
  onPageChange,
  organizationInvoiceNumberSettings,
  page,
  pageCount,
  perPage,
  totalResults,
}) => {
  if ((isLoading || !nurseries) && 1 === page) {
    return (
      <Section>
        <Spinner />
      </Section>
    )
  }

  if (isEmpty) {
    return null
  }

  const renderContent = () => {
    if (isAcrossOrganizationSelected) {
      const {
        creditNoteNumberDescription,
        invoiceNumberDescription,
        receiptNumberDescription,
      } = organizationInvoiceNumberSettings

      return (
        <Settings.InvoiceNumbersSettingView
          creditNoteNumberDescription={creditNoteNumberDescription}
          editButtonLink={generateRoute('SETTINGS.INVOICE_NUMBERS.EDIT')}
          invoiceNumberDescription={invoiceNumberDescription}
          receiptNumberDescription={receiptNumberDescription}
          title="Invoice number sequencing across organisation"
        />
      )
    }

    return (
      <Section title="Individual nursery invoice numbers settings">
        <NurseriesListTable
          getActionRouting={({ id }) => generateRoute('SETTINGS.INVOICE_NUMBERS.ITEM', { nurseryId: id })}
          isFetching={isLoading}
          noDataIcon="invoices"
          nurseries={nurseries}
          page={page}
          pageCount={pageCount}
          perPage={perPage}
          totalResults={totalResults}
          onPageChange={onPageChange}
        />
      </Section>
    )
  }

  return (
    <div>
      <Callout content={errorMessages} error />
      <Section title="Invoice numbers sequencing">
        <Field.InlineEdit
          actions={(
            <Button
              hierarchy="tertiary"
              label={i18n.t('global:Edit')}
              size="small"
              to={generateRoute('SETTINGS.INVOICE_NUMBERS.GLOBAL_EDIT')}
              negativeMargins
            />
          )}
          description={globalNumberTypeDescription}
          label="How would you like to apply sequencing of invoice, receipt and credit note numbers?"
        />
      </Section>
      {renderContent()}
    </div>
  )
}

export default SettingsInvoiceNumbersView
