import _ from 'lodash'

import React from 'react'

import { Tooltip } from 'components'

import i18n from 'translations'

export const getAvailableRolesWithTooltip = (roles) => _.map(roles, (role) => ({
  ...role,
  label: role?.isDisabled
    ? (
      <Tooltip
        placement="bottom"
        title={i18n.t('module:Staff:StaffAdd:disabledRoleOptionTooltip', { role: role.label })}
      >
        {role.label}
      </Tooltip>
    )
    : role.label,
}))
