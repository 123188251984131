import { combineReducers } from 'redux'

import common, { VersionControlCommonState } from './common/reducer'

export default combineReducers({
  common,
})

export interface VersionControlState {
  common: VersionControlCommonState
}
