import React from 'react'

import { Callout, ModalBox, Typography } from 'components'

import i18n from 'translations'

import AddNewRecipientsModalForm from './components/AddNewRecipientsModalForm'

const AddNewRecipientsModalView = ({
  errorMessages,
  groupDropdownOptions,
  individualOptions,
  isSubmitting,
  news,
  onCloseClick,
  onSubmit,
}) => {
  const { title } = news || {}

  return (
    <ModalBox title={i18n.t('module:Modals:AddNewRecipientsModal:title')} autoHeight onCloseClick={onCloseClick}>
      <Callout content={errorMessages} error />
      <Typography margin="0 0 20px 0" variant="h5">
        {title}
      </Typography>
      <Typography margin="0 0 30px 0">
        {i18n.t('module:Modals:AddNewRecipientsModal:subTitle')}
      </Typography>
      <AddNewRecipientsModalForm
        groupDropdownOptions={groupDropdownOptions}
        individualOptions={individualOptions}
        isSubmitting={isSubmitting}
        news={news}
        onCloseClick={onCloseClick}
        onSubmit={onSubmit}
      />
    </ModalBox>
  )
}

export default AddNewRecipientsModalView
