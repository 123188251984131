import React from 'react'

import { StyledContainer } from './BreakDownSubHeaderStyled'

const BreakdownSubheader = ({ subtitle }) => (
  <StyledContainer>
    {subtitle}
  </StyledContainer>
)

export default BreakdownSubheader
