import _ from 'lodash'

import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { NEUTRAL_COLOURS } from 'constants/colors'
import ImagesSize from 'constants/imagesSize'
import { UPLOADED_FILE_STATUS } from 'services/legacy/upload/constants'
import { SUPPORTED_FILE_TYPES } from 'constants/mimetypes'

import { getImageExist, getOptimizedImage } from 'utils/image'
import { isDocumentMimeType, isImageMimeType, isPdfMimeType, isVideoMimeType } from 'utils/attachment'

import { getFileFullName } from 'services/legacy/upload/common/selectors'

import { Icon, Tooltip, Typography } from 'components'

import i18n from 'translations'

import { StyledDocumentWrapper, StyledFilePreview, StyledIcon, StyledName } from './NameColumnStyled'

const NameColumn = ({
  file,
  noCloudUpload,
  onDownloadFile,
  onLightBoxOpen,
}) => {
  const { id, isSync, isUploaded, itemFromBe, lambdaStatus, mimeType, thumbnail } = file
  const isVideo = isVideoMimeType(mimeType)
  const isPdf = isPdfMimeType(mimeType)
  const isDocument = isDocumentMimeType(mimeType)
  const isImage = isImageMimeType(mimeType)

  const isFailed = UPLOADED_FILE_STATUS.FAILED === lambdaStatus
  const notReadyToPreview = (!isUploaded || ((
    UPLOADED_FILE_STATUS.PROCESSING === lambdaStatus
    || UPLOADED_FILE_STATUS.UPLOADING === lambdaStatus
  ) && itemFromBe))
  const [thumbnailDoestExist, setThumbnailDoestExist] = useState(true)

  useEffect(() => {
    if (
      thumbnail
      && ((
        isVideo
        && file.lambdaStatus !== UPLOADED_FILE_STATUS.PROCESSING
        && file.lambdaStatus !== UPLOADED_FILE_STATUS.UPLOADING
      ) || !isVideo)
      && file.lambdaStatus !== UPLOADED_FILE_STATUS.FAILED
    ) {
      getImageExist(getOptimizedImage(thumbnail, ImagesSize.MEDIA_PICKER_THUMBNAIL))
        .then(() => {
          setThumbnailDoestExist(false)
        }, () => {})
    }
  }, [file.file, file.lambdaStatus, isVideo, thumbnail])

  const renderImageOrIcon = () => {
    if (isDocument || isPdf) {
      const document = _.find([
        ...SUPPORTED_FILE_TYPES.DOCUMENTS,
        ...SUPPORTED_FILE_TYPES.PDF,
      ], ({ mimeTypes }) => _.includes(mimeTypes, file.mimeType))

      return (
        <StyledDocumentWrapper background={NEUTRAL_COLOURS.WHITE}>
          <Icon
            color={document.color}
            height={40}
            icon={document.icon}
          />
        </StyledDocumentWrapper>
      )
    }

    if (notReadyToPreview && isVideo) {
      return (
        <Icon
          color="#29ABE2"
          height={40}
          icon="video-file"
        />
      )
    }

    if (!isUploaded && isImage) {
      return (
        <Icon
          color="#4FB150"
          height={40}
          icon="image-file"
        />
      )
    }

    if (
      UPLOADED_FILE_STATUS.FAILED !== lambdaStatus
      && thumbnail
      && !thumbnailDoestExist
      && ((isVideo && !notReadyToPreview) || isImage)
    ) {
      return (
        <LazyLoadImage
          effect="opacity"
          height={ImagesSize.MEDIA_PICKER_THUMBNAIL.height}
          src={isSync || (itemFromBe && UPLOADED_FILE_STATUS.UPLOADING !== lambdaStatus)
            ? getOptimizedImage(thumbnail, ImagesSize.MEDIA_PICKER_THUMBNAIL)
            : thumbnail}
          width={ImagesSize.MEDIA_PICKER_THUMBNAIL.width}
        />
      )
    }

    return null
  }

  const handleClick = () => {
    if ((isPdf || isDocument) && !notReadyToPreview && !isFailed) {
      return onDownloadFile(file)
    }

    if (!notReadyToPreview && !isFailed) {
      return onLightBoxOpen(id)
    }

    return null
  }

  return (
    <StyledName onClick={!noCloudUpload ? handleClick : () => {}}>
      <Tooltip
        title={(
          isFailed
            ? i18n.t('components:MediaPicker:previewIsNotAvailable')
            : (notReadyToPreview && i18n.t('components:MediaPicker:previewWhenNotUploaded'))
        )}
      >
        <StyledFilePreview
          isVideo={isVideo}
          noCloudUpload={noCloudUpload}
          notReadyToPreview={notReadyToPreview || isFailed}
        >
          {renderImageOrIcon()}
          {isVideo && !notReadyToPreview && (
            <StyledIcon>
              <Icon
                color={NEUTRAL_COLOURS.WHITE}
                height={14}
                icon="play"
              />
            </StyledIcon>
          )}
        </StyledFilePreview>
      </Tooltip>
      <Typography cursor={!noCloudUpload ? 'pointer' : 'default'}>
        {getFileFullName(file)}
      </Typography>
    </StyledName>
  )
}

export default NameColumn
