import _ from 'lodash'
import { nest } from 'utils/flatnest'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'

import { PROGRESS_TRACKER_TYPES } from 'services/legacy/cohortTracking/constants'
import { FRAMEWORK_PROGRESS_LEVELS_TYPES } from 'services/legacy/frameworkProgressLevels/constants'
import { FRAMEWORK_STRUCTURE_APPLICABLE } from 'services/legacy/frameworks/constants'
import { ACTION_PLAN_FORM } from 'module/Learning/CohortTracking/components/ActionPlan/ActionPlan'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withCohortTrackingService } from 'services/legacy/cohortTracking'
import { withEthnicitiesService } from 'services/legacy/ethnicities'
import { withFrameworksService } from 'services/legacy/frameworks'
import { withFrameworkProgressLevelsService } from 'services/legacy/frameworkProgressLevels'
import { withFormativeReportsService } from 'services/legacy/formativeReports'
import { withSnackbarService } from 'services/utils/snackbar'
import { withModalService } from 'services/utils/modal'
import { withRoomsService } from 'services/legacy/rooms'
import { withUsersService } from 'services/users'
import { withRouter } from 'services/router'

import i18n from 'translations'

import ProgressTrackerReportView from './ProgressTrackerReportView'

const FRAMEWORK_GROUPS = {
  read: [
    'framework.areas',
    'frameworkArea',
    'structure',
    'structure.cohort',
    'frameworkArea.groups',
    'frameworkGroup',
    'framework.default',
  ],
}

class ProgressTrackerReportContainer extends Component {
  constructor(props) {
    super(props)
    const { location: { pathname } } = this.props

    this.state = {
      itsUnsavedPreview: generateRoute('LEARNING.COHORT_TRACKING.PROGRESS_TRACKER.PREVIEW') === pathname,
      search: '',
    }
  }

  async componentDidMount() {
    const {
      ethnicitiesActions,
      formativeReportsActions,
      frameworksActions,
      location,
      roomsActions,
      usersActions,
    } = this.props
    const { query } = location
    const nestedQuery = nest(query)
    const { filters } = nestedQuery || {}

    const frameworkParams = {
      params: [{
        groups: FRAMEWORK_GROUPS,
      }],
    }

    await Promise.all([
      ethnicitiesActions.list(),
      formativeReportsActions.getTerms(),
      frameworksActions.list(frameworkParams),
      // eslint-disable-next-line no-unsafe-optional-chaining
      filters?.keyWorker ? usersActions.get({ params: [+filters?.keyWorker] }) : null,
      // eslint-disable-next-line no-unsafe-optional-chaining
      filters?.nurseryClass ? roomsActions.get({ params: [+filters?.nurseryClass] }) : null,
    ])

    setTimeout(() => {
      this.validationQuery()
      this.getReport()
    })
  }

  componentWillUnmount() {
    const {
      cohortTrackingActions,
      ethnicitiesActions,
      formativeReportsActions,
      frameworkProgressLevelsActions,
      frameworksActions,
      roomsActions,
      usersActions,
    } = this.props

    formativeReportsActions.clearTerms()
    ethnicitiesActions.clear()
    frameworksActions.clear()
    frameworkProgressLevelsActions.clear()
    cohortTrackingActions.clearProgressTracker()
    roomsActions.clearSingle()
    usersActions.clear()
  }

  getReport = () => {
    const { cohortTrackingActions, cohortTrackingSelectors, location, navigate, params } = this.props
    const { itsUnsavedPreview } = this.state
    const { query } = location

    const payload = cohortTrackingSelectors.getCohortTrackingReportsPayload(query)

    if (itsUnsavedPreview) {
      return cohortTrackingActions.getProgressTrackerPreview({
        onSuccess: this.handleGetProgressLevels,
        params: [query.type, { extra: payload }],
      })
    }

    return cohortTrackingActions.getProgressTrackerReport({
      onFailed: () => navigate(generateRoute('LEARNING.COHORT_TRACKING')),
      onSuccess: this.handleGetProgressLevels,
      params: [query.type, +params.id],
    })
  }

  invalidQueryParams = () => {
    const { navigate, snackbarActions } = this.props

    snackbarActions.show({
      message: i18n.t('module:Learning:CohortTracking:global:invalidQueryParams'),
    })

    return navigate(generateRoute('LEARNING.COHORT_TRACKING.PROGRESS_TRACKER'))
  }

  validationQuery = () => {
    const { frameworksList, location, terms } = this.props
    const { itsUnsavedPreview } = this.state
    const { query } = location

    if (
      query.type !== PROGRESS_TRACKER_TYPES.TERMLY_PROGRESS
      && query.type !== PROGRESS_TRACKER_TYPES.AREA_OF_LEARNING) {
      return this.invalidQueryParams()
    }

    if (!itsUnsavedPreview) {
      return null
    }

    if (!_.find(frameworksList, ({ id }) => id === +query.framework)) {
      return this.invalidQueryParams()
    }

    if (
      query.type === PROGRESS_TRACKER_TYPES.AREA_OF_LEARNING
      && !_.find(terms, ({ value }) => value === query.term)
    ) {
      return this.invalidQueryParams()
    }

    if (query.type === PROGRESS_TRACKER_TYPES.TERMLY_PROGRESS) {
      const framework = _.find(frameworksList, ({ id }) => id === +query.framework)

      if (!_.find(framework.areas, ({ id }) => id === +query.frameworkArea)) {
        return this.invalidQueryParams()
      }
    }

    return null
  }

  handleChangeFilters = () => {
    const { location, navigate } = this.props
    const { query } = location

    return navigate(
      `${generateRoute('LEARNING.COHORT_TRACKING.PROGRESS_TRACKER')}?params=${JSON.stringify(query)}`,
    )
  }

  handleChangeSearch = (search) => {
    this.setState({ search })
  }

  handleRemoveReportConfirmed = () => {
    const { cohortTrackingActions, navigate, report } = this.props
    const type = report?.frameworkArea?.id
      ? PROGRESS_TRACKER_TYPES.TERMLY_PROGRESS
      : PROGRESS_TRACKER_TYPES.AREA_OF_LEARNING

    cohortTrackingActions.removeProgressTracker({
      onSuccess: () => navigate(generateRoute('LEARNING.COHORT_TRACKING')),
      params: [type, report.id],
    })
  }

  handleRemoveReport = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      onConfirm: this.handleRemoveReportConfirmed,
      text: i18n.t('module:Learning:CohortTracking:ProgressTrackerReport:confirmRemove'),
    })
  }

  getReportType = () => {
    const { report } = this.props

    return report?.frameworkArea?.id
      ? PROGRESS_TRACKER_TYPES.TERMLY_PROGRESS
      : PROGRESS_TRACKER_TYPES.AREA_OF_LEARNING
  }

  handleSaveReport = () => {
    const {
      actionPlanFormValues,
      cohortTrackingActions,
      cohortTrackingSelectors,
      ethnicities,
      keyWorker,
      location,
      navigate,
      nurseryClass,
      report,
    } = this.props
    const { query } = location

    const filterLegacyLabel = cohortTrackingSelectors.generateFiltersLabel(report, {
      ethnicities,
      keyWorker,
      nurseryClass,
    })
    const body = cohortTrackingSelectors.getCohortTrackingReportsPayload(
      query,
      filterLegacyLabel,
      actionPlanFormValues?.actionPlan,
    )

    return cohortTrackingActions.createProgressTracker({
      body,
      onSuccess: () => navigate(generateRoute('LEARNING.COHORT_TRACKING')),
      params: [query.type],
    })
  }

  handleChangeActionPlan = (onSuccess) => setTimeout(() => {
    const { actionPlanFormValues, cohortTrackingActions, location, params } = this.props
    const { query } = location

    const body = {
      actionPlan: actionPlanFormValues?.actionPlan || '',
    }

    cohortTrackingActions.updateProgressTrackerActionPlan({
      body,
      onSuccess,
      params: [query.type, +params.id],
    })
  })

  handleGetProgressLevels = ({ data }) => {
    const { frameworkProgressLevelsActions, report } = this.props

    const progressLevelsCriteria = [
      {
        field: 'framework',
        value: report?.framework?.id || data?.framework?.id,
      },
      {
        field: 'type',
        value: FRAMEWORK_PROGRESS_LEVELS_TYPES.FORMATIVE_REPORT_AGE_BAND,
      },
    ]

    frameworkProgressLevelsActions.list({
      params: {
        criteria: progressLevelsCriteria,
      },
      recursively: true,
    })
  }

  render() {
    const {
      cohortTrackingSelectors,
      ethnicities,
      frameworksList,
      isFetching,
      keyWorker,
      nurseryClass,
      progressLevels,
      report,
      terms,
    } = this.props
    const { itsUnsavedPreview, search } = this.state

    const reportType = this.getReportType()
    const records = cohortTrackingSelectors.getFilteredRecords(report.data, search, reportType)

    return (
      <ProgressTrackerReportView
        ethnicities={ethnicities}
        frameworksList={frameworksList}
        isFetching={isFetching}
        itsUnsavedPreview={itsUnsavedPreview}
        keyWorker={keyWorker}
        nurseryClass={nurseryClass}
        progressLevels={progressLevels}
        records={records}
        report={report}
        reportType={reportType}
        search={search}
        terms={terms}
        onChangeActionPlan={this.handleChangeActionPlan}
        onChangeFilters={this.handleChangeFilters}
        onChangeSearch={this.handleChangeSearch}
        onRemoveReport={this.handleRemoveReport}
        onSaveReport={this.handleSaveReport}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  cohortTrackingProgressTrackerSingleState,
  cohortTrackingSelectors,
  ethnicitiesListState,
  ethnicitiesSelectors,
  formativeReportsSelectors,
  formativeReportsTermsState,
  frameworkProgressLevelsListState,
  frameworkProgressLevelsSelectors,
  frameworksListState,
  frameworksSelectors,
  roomsSelectors,
  roomsSingleState,
  usersSelectors,
  usersSingleState,
}) => ({
  actionPlanFormValues: getFormValues(ACTION_PLAN_FORM)(state),
  ethnicities: ethnicitiesSelectors.getEthnicitiesListDataDropdownSelector(state),
  frameworksList: frameworksSelectors.getFilteredFrameworksRawList(
    FRAMEWORK_STRUCTURE_APPLICABLE.COHORT_TRACKING,
  )(state),
  isFetching: appSelectors.getIsFetching(
    cohortTrackingProgressTrackerSingleState,
    ethnicitiesListState,
    frameworksListState,
    frameworkProgressLevelsListState,
    formativeReportsTermsState,
    roomsSingleState,
    usersSingleState,
  ),
  keyWorker: usersSelectors.getUsersSingleDataSelector(state),
  nurseryClass: roomsSelectors.getRoomsSingleDataSelector(state),
  progressLevels: frameworkProgressLevelsSelectors.getFrameworkProgressLevels(state),
  report: cohortTrackingSelectors.getProgressTrackerReport(state),
  terms: formativeReportsSelectors.getUsedTermsDropdownInFormativeReports(state),
})

const enhance = compose(
  withAppService,
  withCohortTrackingService,
  withEthnicitiesService,
  withFrameworksService,
  withFrameworkProgressLevelsService,
  withFormativeReportsService,
  withModalService,
  withSnackbarService,
  withRoomsService,
  withUsersService,
  withRouter,
  connect(mapState),
)

export default enhance(ProgressTrackerReportContainer)
