import React, { Component } from 'react'

import TimeWidgetView from './TimeWidgetView'

export default class TimeWidgetContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      date: new Date(),
    }
  }

  componentDidMount() {
    this.interval = setInterval(this.handleTick, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  handleTick = () => {
    const date = new Date()

    this.setState({ date })
  }

  render() {
    const { date } = this.state

    return (
      <TimeWidgetView
        date={date}
      />
    )
  }
}
