import React from 'react'

import { Form } from 'components'

import {
  StyledDrCrContainer,
  StyledDrCrLabel,
  StyledSwitchContainer,
  StyledToggleContainer,
} from './FormSwitchDebitCreditStyled'

const FormSwitchDebitCredit = (props) => (
  <Form.Switch {...props}>
    {(value, onChange) => (
      <StyledSwitchContainer
        onClick={() => onChange(!value)}
      >
        <StyledDrCrContainer onClick={() => onChange(!value)}>
          <StyledDrCrLabel isActive={!value} onClick={() => onChange(false)}>Debit</StyledDrCrLabel>
          <StyledDrCrLabel isActive={value} onClick={() => onChange(true)}>Credit</StyledDrCrLabel>
        </StyledDrCrContainer>
        <StyledToggleContainer value={value} onClick={() => onChange(!value)} />
      </StyledSwitchContainer>
    )}
  </Form.Switch>
)

export default FormSwitchDebitCredit
