import moment from 'moment'
import _ from 'lodash'

import React, { useEffect, useRef, useState } from 'react'
import Sticky from 'react-sticky-el'
import { useInView } from 'react-intersection-observer'

import { HEADER_PINNED_EVENT, IS_HEADER_PINNED_LS } from 'services/shell/constants'
import { NEUTRAL_COLOURS } from 'constants/colors'

import eventBus from 'utils/eventBus'
import { useWindowSize } from 'utils/hooks'

import { Space, Spinner, Typography } from 'components'
import ProgressLabel from 'module/Learning/Observations/components/ProgressLabel'

import i18n from 'translations'

import {
  StyledColumn,
  StyledContentColumn,
  StyledCustomScrollbarWrapper,
  StyledEndOfContent,
  StyledImitationContentWidth,
  StyledItem,
  StyledItemDetailsWrapper,
  StyledLeftColumn,
  StyledRow,
  StyledTableWrapper,
} from './ChildFrameworkTableStyled'

const ChildFrameworkTable = ({
  areaOfLearning,
  childFrameworkProgress,
  frameworkDetails,
  isFetching,
  onShowSelectProgressModal,
  recalculateFrameworkTable,
}) => {
  const { areas, structure } = frameworkDetails || {}
  const areaData = _.find(areas, ({ id }) => areaOfLearning === id)
  const tableWrapperRef = useRef(null)
  const [isHeaderPinned, setHeaderIsPinned] = useState(+window.localStorage.getItem(IS_HEADER_PINNED_LS))
  const contentArtificialScrollableRef = useRef(null)
  const windowSize = useWindowSize()
  const contentScrollableRef = useRef(null)
  const headerScrollableRef = useRef(null)
  const [contentScrollWidth, setContentScrollWidth] = useState(0)
  const [contentWidth, setContentWidth] = useState(0)
  const [originalScrollbarRef, originalScrollbarInView] = useInView({
    threshold: 0,
  })

  const [profileHeaderTopPosition, changeProfileHeaderTopPosition] = useState(
    document.querySelector('#profileHeaderBottom')?.getBoundingClientRect()?.top || 0,
  )

  const updateHeaderIsPinned = () => {
    const newValue = +window.localStorage.getItem(IS_HEADER_PINNED_LS)

    if (newValue !== isHeaderPinned) {
      setHeaderIsPinned(newValue)
    }
  }

  const scrollListener = () => {
    changeProfileHeaderTopPosition(
      document.querySelector('#profileHeaderBottom')?.getBoundingClientRect()?.top,
    )
  }

  const { groups } = areaData || {}
  let categories = []
  _.each(groups, ({ categories: groupCategories }) => {
    _.each(groupCategories, (category) => {
      categories.push(category)
    })
  })

  categories = _.uniqBy(categories, ({ name }) => name)

  const calculateHeightOfRows = () => {
    setTimeout(() => {
      _.each(categories, ({ name }) => {
        // There must be without template style, because is some bug in babel-eslint
        // eslint-disable-next-line prefer-template
        const categoryRows = tableWrapperRef.current?.querySelectorAll('[data-category-name="' + name + '"]')

        _.each(categoryRows, (row) => {
          // eslint-disable-next-line no-param-reassign
          row.style.height = 'auto'
        })
      })

      _.each(categories, ({ name }) => {
        // There must be without template style, because is some bug in babel-eslint
        // eslint-disable-next-line prefer-template
        const categoryRows = tableWrapperRef.current?.querySelectorAll('[data-category-name="' + name + '"]')
        let maxHeightOfRow = 0

        _.each(categoryRows, (row) => {
          if (row.offsetHeight > maxHeightOfRow) {
            maxHeightOfRow = row.offsetHeight
          }
        })

        _.each(categoryRows, (row) => {
          // eslint-disable-next-line no-param-reassign
          row.style.height = `${maxHeightOfRow}px`
        })
      })
    })
  }

  useEffect(() => {
    eventBus.on(HEADER_PINNED_EVENT, updateHeaderIsPinned)
    window.addEventListener('scroll', scrollListener)
    window.addEventListener('resize', calculateHeightOfRows)

    return () => {
      eventBus.remove(HEADER_PINNED_EVENT, updateHeaderIsPinned)
      window.removeEventListener('scroll', scrollListener)
      window.removeEventListener('resize', calculateHeightOfRows)
    }
  })

  useEffect(calculateHeightOfRows, [tableWrapperRef, frameworkDetails, areaOfLearning, recalculateFrameworkTable])

  useEffect(() => {
    const { offsetWidth, scrollWidth } = contentScrollableRef?.current || {}

    setContentWidth(offsetWidth)
    setContentScrollWidth(scrollWidth)
  }, [contentScrollableRef, windowSize])

  const handleOriginalScroll = (e) => {
    contentArtificialScrollableRef.current.scrollLeft = e.target.scrollLeft
    headerScrollableRef.current.scrollLeft = e.target.scrollLeft

    return true
  }

  const handleArtificialScroll = (e) => {
    contentScrollableRef.current.scrollLeft = e.target.scrollLeft
    headerScrollableRef.current.scrollLeft = e.target.scrollLeft

    return true
  }

  if (isFetching) {
    return (
      <Spinner />
    )
  }

  if (!areaData) {
    return null
  }

  const renderHeader = () => (
    <Sticky
      stickyStyle={{
        marginTop: profileHeaderTopPosition || (isHeaderPinned ? '320px' : '228px'),
        zIndex: 190,
      }}
      topOffset={(profileHeaderTopPosition * -1) || (isHeaderPinned ? -320 : -228)}
    >
      <StyledTableWrapper hideCategories={!structure.framework.category.visible}>
        {structure.framework.category.visible && (
          <StyledLeftColumn>
            <StyledRow>
              <Typography fontSize={15} margin="5px 0" bold>
                {i18n.t('module:Children:Child:LearningJourney:Framework:ageGroup')}
              </Typography>
            </StyledRow>
          </StyledLeftColumn>
        )}
        <StyledContentColumn
          length={groups.length}
          ref={headerScrollableRef}
          hideScroll
        >
          {structure.framework.group.visible
            ? _.map(groups, ({ id, name }, index) => (
              <StyledColumn key={`groupHead_${id}`} last={groups.length - 1 === index} center>
                <StyledRow header>
                  <Typography fontSize={15} margin="5px 0" bold>
                    {name}
                  </Typography>
                </StyledRow>
              </StyledColumn>
            ))
            : (
              <StyledColumn center>
                <StyledRow header>
                  {structure.framework.area.groupHeader ? (
                    areaData.name
                  ) : (
                    <React.Fragment>
                      &nbsp;
                    </React.Fragment>
                  )}
                </StyledRow>
              </StyledColumn>
            )}
        </StyledContentColumn>
      </StyledTableWrapper>
    </Sticky>
  )

  const renderItem = (items) => (item, index) => {
    const { description, id } = item
    const value = childFrameworkProgress?.[id]

    return (
      <StyledItem
        isEditable={structure.professionalOpinionEditable}
        isLast={items.length === index + 1}
        key={`item_${id}`}
        onClick={() => structure.professionalOpinionEditable && (
          onShowSelectProgressModal(item)
        )}
      >
        {description}
        {value && structure.professionalOpinionEditable && (
          <StyledItemDetailsWrapper>
            <ProgressLabel value={value.progressLevel} />
            <Typography color={NEUTRAL_COLOURS.GRAY}>
              {moment(value.date).format('DD-MM-YYYY')}
            </Typography>
          </StyledItemDetailsWrapper>
        )}
      </StyledItem>
    )
  }

  const renderGroups = () => _.map(groups, ({ categories: groupCategories, id: groupId }) => (
    <StyledColumn key={`groupHead_${groupId}`}>
      {_.map(categories, (category) => {
        const items = _.find(groupCategories, ({ name }) => (
          category.name === name
        ))?.items

        return (
          <StyledRow data-category-name={category.name} key={`category_${groupId}_${category.id}`}>
            {_.map(items, renderItem(items))}
          </StyledRow>
        )
      })}
    </StyledColumn>
  ))

  return (
    <React.Fragment>
      <Space space="40px" />
      {renderHeader()}
      <StyledTableWrapper hideCategories={!structure.framework.category.visible} ref={tableWrapperRef}>
        {structure.framework.category.visible && (
          <StyledLeftColumn>
            {_.map(categories, (category) => (
              <StyledRow data-category-name={category.name} key={`category_${category.id}`}>
                <Typography
                  transform="uppercase"
                  bold
                >
                  {category.name}
                </Typography>
              </StyledRow>
            ))}
          </StyledLeftColumn>
        )}
        <StyledContentColumn
          length={groups.length}
          ref={contentScrollableRef}
          onScroll={handleOriginalScroll}
        >
          {renderGroups()}
          <StyledEndOfContent
            ref={originalScrollbarRef}
          />
          <StyledCustomScrollbarWrapper
            isVisible={originalScrollbarInView}
            ref={contentArtificialScrollableRef}
            width={contentWidth}
            onScroll={handleArtificialScroll}
          >
            <StyledImitationContentWidth width={contentScrollWidth} />
          </StyledCustomScrollbarWrapper>
        </StyledContentColumn>
      </StyledTableWrapper>
    </React.Fragment>
  )
}

export default ChildFrameworkTable
