import { BasicActionOptionsProps } from 'constants/service'

import {
  CLEAR_NURSERY_ROTA_STATISTICS,
  GET_NURSERY_ROTA_STATISTICS,
  GET_NURSERY_ROTA_STATISTICS_FAILED,
  GET_NURSERY_ROTA_STATISTICS_SUCCESS,
} from './constants'

export const getRotaStatistics = (
  id,
  options: BasicActionOptionsProps,
) => ({ nurseriesApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, onlyData, params = {} } = options

  dispatch({ type: GET_NURSERY_ROTA_STATISTICS })

  return nurseriesApiClient.rotaStatistics(id, params).then(
    (response) => {
      if (onlyData) {
        dispatch({
          payload: response,
          type: GET_NURSERY_ROTA_STATISTICS_SUCCESS,
        })
      }

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      if (onlyData) {
        dispatch({
          payload: response,
          type: GET_NURSERY_ROTA_STATISTICS_FAILED,
        })
      }

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const clearRotaStatistics = () => ({
  type: CLEAR_NURSERY_ROTA_STATISTICS,
})

