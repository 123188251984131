import moment from 'moment'

import { compose } from 'recompose'
import React, { Component } from 'react'

import { EVENTS, logEvent } from 'analytics'

import { withModalService } from 'services/utils/modal'
import { withInvoicesService } from 'services/legacy/invoices'

import BadDebtModalView from './BadDebtModalView'

class BadDebtModalContainer extends Component {
  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleOnSuccess = () => {
    logEvent(EVENTS.INVOICE_MARK_BAD_DEBT_SUBMITTED)

    this.handleCloseClick()
  }

  handleSubmit = (fields) => {
    const { invoice, invoicesActions, invoicesSelectors } = this.props
    const { id } = invoice

    const apiParams = {
      groups: { read: ['invoice.badDebt'], write: ['invoice.badDebt'] },
    }
    const payload = invoicesSelectors.getBadDebtPayload({ fields })

    invoicesActions.update(id, payload, apiParams, this.handleOnSuccess)
  }

  render() {
    const { invoice, invoicesSingleState: { isSubmitting } } = this.props
    const { badDebtDate, badDebtReason } = invoice
    const initialValues = badDebtDate ? { badDebtDate, badDebtReason } : { badDebtDate: moment() }

    return (
      <BadDebtModalView
        initialValues={initialValues}
        invoice={invoice}
        isSubmitting={isSubmitting}
        onCloseClick={this.handleCloseClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const enhance = compose(
  withModalService,
  withInvoicesService,
)

export default enhance(BadDebtModalContainer)
