import styled from 'styled-components'
import color from 'color'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

import { StyledContainer } from 'components/Avatar/AvatarStyled'

export const StyledChannelHeader = styled.header`
  background: ${NEUTRAL_COLOURS.WHITE};
  border-bottom: 2px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  padding: 0 23px;
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: 1fr 100px 32px;
  align-items: center; 
    
  ${({ hiddenDropdown }) => hiddenDropdown && `
    grid-template-columns: 1fr 100px;
  `}
  
  ${StyledContainer} {
    grid-column-gap: 16px;
  }
  
  @media(max-width: 400px) {
    grid-template-columns: 1fr 32px;
  }
`

// stylelint-disable declaration-block-no-duplicate-properties
export const StyledGroupAvatarsContainer = styled.div`
  display: grid;
  grid-template-columns: auto 22px;
  grid-template-columns: max-content 22px;
  grid-column-gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 4px;
  cursor: pointer;
  max-width: max-content;
  justify-self: flex-end;
  transition: .2s;
  border-radius: 24px;
  
  &:hover {
    background: ${color(getBrandingColor('primary-color')).alpha(0.08).string()};
  }
  
  ${({ amount }) => !amount && `
    grid-template-columns: 30px 22px;
  `}

  @media(max-width: 400px) {
    display: none;
  }
`

export const StyledConversationMembers = styled.div`
  display: none;
  
  @media(max-width: 400px) {
    display: block;
  }
`

export const StyledDropdownWrapper = styled.div`
  ${({ hidden }) => hidden && `
    display: none;
    
    @media(max-width: 400px) {
      display: block !important;
    }
  `}  
`
