import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as selectors from './selectors'

export interface withEnquirySettingsServiceProps {
  enquirySettingsActions: typeof actions
  enquirySettingsListState: any
  enquirySettingsSelectors: typeof selectors
  enquirySettingsSingleState: any
}

const withEnquirySettingsService = <P extends withEnquirySettingsServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ enquirySettings }) => ({ enquirySettings })

  const mapDispatch = injectActions('enquirySettingsActions', actions)

  const Component = ({ enquirySettings, enquirySettingsActions, ...other }) => (
    <WrappedComponent
      enquirySettingsActions={enquirySettingsActions}
      enquirySettingsListState={enquirySettings.list}
      enquirySettingsSelectors={selectors}
      enquirySettingsSingleState={enquirySettings.single}
      {...other as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withEnquirySettingsService
