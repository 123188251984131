import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'

import { DAYS_OPTIONS } from 'constants/sessionDays'
import { DAYS_OF_WEEK_LIST, DEFAULT_DATE_FORMAT } from 'constants/date'
import { DEFAULT_CURRENCY, DEFAULT_LOCALE } from 'constants/locale'
import { INDEPENDENT_NURSERY_OPTION } from 'services/nurseries/constants'
import { NurserySettings, ReferenceCodeGenerationProps } from 'services/nurseries/models'
import { Membership } from 'services/legacy/memberships/models'
import {
  CODE_AUTOMATIC_GENERATION,
} from 'module/Management/ManagementFinanceSettings/ReferenceCodeGeneration/constants'

import { getBackendErrors } from 'utils/backendErrors'
import { toInt } from 'utils/data'

import { getAuthNursery } from 'services/security/selectors'
import { findDomainSuffix, getPortSuffix } from 'services/subdomain/selectors'

import { properties } from 'app-config'
import i18n from 'translations'

import constants, { EDITING_STAFF_ROTA } from '../../constants'

interface NurserySettingsPayloadProps {
  id?: any
  learning?: any
  likeAndComment?: {
    enabled?: boolean
  }
  likeAndCommentMembers?: { id: number }[]
  messaging?: any
  occupancy?: any
  register?: any
  security?: any
  staffRota?: any
}

interface NurseryPayloadProps {
  nurserySettings?: NurserySettingsPayloadProps
}

export const getNurseriesSelector = (state: RootState) => state.nurseries

export const getNurseriesSingleDataSelector = createSelector(
  [getNurseriesSelector],
  (state) => state.single.data,
)

export const getNurseryFlagsSelector = createSelector(
  [getNurseriesSelector],
  (state) => state.single.flags,
)

export const getNurseryIsArchived = createSelector(
  [getNurseriesSingleDataSelector],
  (state) => state?.archived,
)

export const getIsUkCountry = createSelector(
  [getNurseriesSingleDataSelector], (state) => (state?.nurserySettings?.locale === DEFAULT_LOCALE),
)

const getInitialValuesNurserySettings = (nurserySettings: NurserySettings) => {
  if (!nurserySettings) {
    return undefined
  }

  const { currency, locale, openingDays, startWeekFrom } = nurserySettings || {}

  return {
    ...nurserySettings,
    currency: currency || DEFAULT_CURRENCY,
    locale: locale || DEFAULT_LOCALE,
    openingDays: _.map(DAYS_OF_WEEK_LIST, (day, index) => {
      const dayApiData = _.find(openingDays, { day: `${day.toLocaleLowerCase()}` })

      if (!dayApiData) {
        return {
          day: day.toLocaleLowerCase(),
          isChecked: false,
          label: i18n.t(`global:DayNames:${index + 1}`),
        }
      }

      const { endTime, startTime } = dayApiData

      return {
        day: day.toLocaleLowerCase(),
        endTime,
        isChecked: true,
        label: i18n.t(`global:DayNames:${index + 1}`),
        startTime,
      }
    }),
    startWeekFrom: null !== startWeekFrom ? _.find(DAYS_OPTIONS, { value: startWeekFrom }) : null,
  }
}

export const getInitialValues = createSelector(
  [getNurseriesSingleDataSelector, getNurseryFlagsSelector],
  (data, flags) => {
    const { nurserySettings, organization, ...rest } = data || {}

    const featureFlags = {}

    _.forEach(flags, ({ enabled, flag }) => { featureFlags[flag] = enabled })

    const response = {
      ...rest,
      featureFlags,
      nurserySettings: getInitialValuesNurserySettings(nurserySettings),
      organization: {},
    }

    if (organization) {
      response.organization = {
        label: organization.name,
        value: organization.id,
      }
    } else {
      response.organization = INDEPENDENT_NURSERY_OPTION
    }

    return response
  },
)

export const getEditNurseryPayload = (fields) => {
  if (!fields) {
    return null
  }

  const organization = _.clone(fields.organization)
  const { nurserySettings } = fields || {}
  const { openingDays, startWeekFrom } = nurserySettings || {}

  // eslint-disable-next-line no-param-reassign
  delete fields.organization
  // eslint-disable-next-line no-param-reassign
  delete fields.isMontessori

  const response = {
    ...fields,
    nurserySettings: nurserySettings ? {
      ...nurserySettings,
      currency: nurserySettings?.currency?.value || nurserySettings?.currency || DEFAULT_CURRENCY,
      locale: nurserySettings?.locale?.value || nurserySettings?.locale || DEFAULT_LOCALE,
      openingDays: _.map(_.filter(openingDays, (openDay) => openDay.isChecked), (openDay) => {
        const { day, endTime, startTime } = openDay

        return {
          day,
          endTime,
          startTime,
        }
      }),
      startWeekFrom: startWeekFrom ? startWeekFrom.value : null,
    } : undefined,
  }

  if (organization?.value && false === nurserySettings.financeLocked) {
    response.subdomain = fields.name + organization.subdomainSuffix

    response.organization = {
      id: organization.value,
    }
  }

  return response
}

const getMembershipProfile = (membershipProfile) => {
  if (!membershipProfile) {
    return {}
  }

  if (!membershipProfile.dbsCheck) {
    return membershipProfile
  }

  return {
    ...membershipProfile,
    dbsCheck: {
      ...membershipProfile.dbsCheck,
      reviewDate: moment(membershipProfile.dbsCheck.reviewDate).format(DEFAULT_DATE_FORMAT),
    },
  }
}

export const getCreateNurseryPayload = ({
  formValues,
  isAdministrationContext,
  isOrganizationContext,
  subdomainSuffix,
}) => {
  if (!formValues) {
    return {}
  }

  const { existingPrimaryContact, newPrimaryContact, nursery } = formValues
  const { organization, ...restNursery } = nursery

  const response = {
    ...restNursery,
    nurserySettings: {
      ...nursery.nurserySettings,
      currency: nursery?.nurserySettings?.currency?.value || nursery?.nurserySettings?.currency || DEFAULT_CURRENCY,
      locale: nursery?.nurserySettings?.locale?.value || nursery?.nurserySettings?.locale || DEFAULT_LOCALE,
    },
    primaryContact: isOrganizationContext && existingPrimaryContact
      ? {
        id: existingPrimaryContact.value,
        type: 'organization',
      } : {
        ...newPrimaryContact,
        birthDate: (
          newPrimaryContact?.birthDate
            ? moment(newPrimaryContact?.birthDate).format(DEFAULT_DATE_FORMAT)
            : null
        ),
        membershipProfile: getMembershipProfile(newPrimaryContact?.membershipProfile),
        title: newPrimaryContact?.title?.value,
        type: 'nursery',
      },
    subdomain: nursery.subdomain + subdomainSuffix,
  }

  if (isAdministrationContext && organization?.value) {
    response.organization = {
      id: organization.value,
    }

    response.subdomain = nursery.subdomain + organization.subdomainSuffix
  }

  return response
}

export const getNurseryName = createSelector(
  [getNurseriesSingleDataSelector],
  (nurseryData): string => nurseryData?.name,
)

export const getNurseryStripeNurseryIntegration = createSelector(
  [getNurseriesSingleDataSelector],
  (nurseryData) => nurseryData?.stripeNurseryIntegration,
)

export const getNurseryOpenPeriodDataEditSelector = createSelector(
  [getNurseriesSingleDataSelector],
  (state) => {
    if (!state) {
      return null
    }

    const { nurserySettings } = state
    const { openPeriod, openWeeks } = nurserySettings || {}

    const getOpenPeriodOption = () => {
      const openPeriodOption = _.find(constants.OPEN_PERIOD_OPTIONS, { value: openPeriod })

      if (!openPeriodOption) {
        return openPeriod
      }

      return openPeriodOption
    }

    return {
      ...state,
      nurserySettings: {
        ...nurserySettings,
        openPeriod: getOpenPeriodOption(),
        openWeeks,
      },
    }
  },
)

export const getNurseryOpenPeriod = createSelector(
  [getNurseriesSingleDataSelector],
  (state) => {
    if (!state) {
      return null
    }

    const { nurserySettings } = state
    const { openPeriod, openWeeks } = nurserySettings || {}

    return { openPeriod, openWeeks }
  },
)

export const getNurseryOpenPeriodDataViewSelector = createSelector(
  [getNurseryOpenPeriod],
  (openPeriodData) => {
    if (!openPeriodData) {
      return null
    }

    const { openPeriod, openWeeks } = openPeriodData

    const getOpenPeriodDescription = () => {
      if (!openPeriod) {
        return null
      }

      const openPeriodOption = _.find(constants.OPEN_PERIOD_OPTIONS, { value: openPeriod })

      if (!openPeriodOption) {
        return openPeriod
      }

      return openPeriodOption.label
    }

    const getOpenWeeksDescription = () => {
      if (!openWeeks) {
        return null
      }

      return `${openWeeks} weeks`
    }

    return { openPeriod: getOpenPeriodDescription(), openWeeks: getOpenWeeksDescription() }
  },
)

export const getNurseryOpenPeriodPayloadSelector = createSelector([(fields) => fields], (fields) => {
  if (!fields) {
    return null
  }

  const { nurserySettings } = fields
  const { openPeriod, openWeeks } = nurserySettings || {}

  return {
    ...fields,
    nurserySettings: {
      ...nurserySettings,
      openPeriod: openPeriod ? openPeriod.value : null,
      openWeeks: openWeeks ? toInt(openWeeks) : null,
    },
  }
})

export const getNurseryData = createSelector([getNurseriesSingleDataSelector], (state) => {
  if (!state) {
    return null
  }

  const getFormattedOpeningDays = (nurserySettings) => {
    if (!nurserySettings) {
      return null
    }

    const { openingDays, startWeekFrom } = nurserySettings

    if (!openingDays || !startWeekFrom) {
      return null
    }

    const startDateOfWeek = moment().startOf('week').isoWeekday(startWeekFrom)

    const formatedDays = []
    for (let i = 0; 7 > i; i += 1) {
      const day = startDateOfWeek.format('dddd').toLocaleLowerCase()

      if (-1 !== _.findIndex(openingDays, { day })) {
        formatedDays.push(day)
      }

      startDateOfWeek.add('d', 1)
    }

    return formatedDays
  }

  const getOpenPeriodText = (nurseryData) => {
    const { nurserySettings } = nurseryData
    const { openPeriod, openWeeks } = nurserySettings || {}

    if (!openPeriod) {
      return null
    }

    const openPeriodDescription = (
      openPeriod === constants.OPEN_PERIOD.OPEN_FOR_TERM_TIME ? 'Term time' : 'All year round'
    )

    if (!openWeeks) {
      return openPeriodDescription
    }

    return `${openPeriodDescription} - ${openWeeks} weeks`
  }

  const getOpeningDays = (nurseryData) => {
    const { nurserySettings } = nurseryData
    const { openingDays } = nurserySettings || {}

    return _.map(DAYS_OF_WEEK_LIST, (day, index) => {
      const dayApiData = _.find(openingDays, { day: `${day.toLocaleLowerCase()}` })

      if (!dayApiData) {
        return {
          isClosed: true,
          label: String(i18n.t(`global:DayNames:${index + 1}`)).substring(0, 1),
        }
      }

      const { endTime, startTime } = dayApiData

      return {
        endTime,
        label: String(
          i18n.t(`global:DayNames:${_.findIndex(DAYS_OF_WEEK_LIST, (i) => i === day) + 1}`),
        ).substring(0, 1),
        startTime,
      }
    })
  }

  const getStartWeekFrom = (nurseryData) => {
    const { nurserySettings } = nurseryData
    const { startWeekFrom } = nurserySettings || {}

    const startDay = _.find(DAYS_OPTIONS, { value: startWeekFrom })

    return startDay ? startDay.label : null
  }

  return {
    ...state,
    nurserySettings: {
      ...state.nurserySettings,
      formattedOpeningDays: getFormattedOpeningDays(state.nurserySettings),
      openPeriod: getOpenPeriodText(state),
      openingDays: getOpeningDays(state),
      startWeekFrom: getStartWeekFrom(state),
    },
  }
})

export const isOpeningDaysEmpty = createSelector([getNurseriesSingleDataSelector], (state) => (
  (!state || !state.nurserySettings || !state.nurserySettings.openingDays || !state.nurserySettings.openingDays.length)
))

export const isAllYearRoundSelected = createSelector(
  [getNurseriesSingleDataSelector],
  (state) => {
    if (!state || !state.nurserySettings || !state.nurserySettings.openPeriod) {
      return false
    }

    return state.nurserySettings.openPeriod === constants.OPEN_PERIOD.OPEN_FOR_YEAR_ROUND
  },
)

export const getNurserySettings = createSelector(
  [getNurseriesSingleDataSelector],
  (nurseryData) => {
    if (!nurseryData || !nurseryData.nurserySettings) {
      return null
    }

    return nurseryData.nurserySettings as NurserySettings
  },
)

export const getPayload = createSelector(
  [(values) => values],
  (values) => {
    const { fields, nurseriesSingleState } = values || {}
    const { nurserySettings } = nurseriesSingleState.data || {}

    if (_.isString(fields.sessionCalculationWeeks)) {
      fields.sessionCalculationWeeks = +fields.sessionCalculationWeeks
    }

    if (_.isString(fields.sessionCalculationDays)) {
      fields.sessionCalculationDays = +fields.sessionCalculationDays
    }

    if (_.isString(fields.dueDate)) {
      fields.dueDate = +fields.dueDate
    }

    return {
      nurserySettings: {
        ...nurserySettings,
        invoice: {
          ...nurserySettings.invoice,
          ...fields,
        },
      },
    }
  },
)

export const getInvoiceNumberSettingsPayload = createSelector(
  [(values) => values],
  (values) => {
    const { fields, nurseriesSingleState } = values || {}
    const { nurserySettings } = nurseriesSingleState.data || {}

    const { invoice } = nurserySettings
    const { invoiceNumbers } = invoice

    const {
      creditNoteNumber,
      invoiceNumber,
      receiptNumber,
    } = invoiceNumbers

    const {
      creditNoteNumber: fieldCreditNoteNumber,
      invoiceNumber: fieldInvoiceNumber,
      receiptNumber: fieldReceiptNumber,
    } = fields || {}

    return {
      nurserySettings: {
        ...nurserySettings,
        invoice: {
          ...invoice,
          invoiceNumbers: {
            ...invoiceNumbers,
            ...fields,
            creditNoteNumber: BigInt(fieldCreditNoteNumber) || creditNoteNumber,
            invoiceNumber: BigInt(fieldInvoiceNumber) || invoiceNumber,
            receiptNumber: BigInt(fieldReceiptNumber) || receiptNumber,
          },
        },
      },
    }
  },
)

export const getOrganizationSettings = createSelector(
  [getNurseryData],
  (nurseryData) => {
    if (!nurseryData || !nurseryData.organizationSettings) {
      return null
    }

    return nurseryData.organizationSettings
  },
)

export const getStripeNurseryIntegrationSettings = createSelector(
  [getNurseryData],
  (nurseryData) => nurseryData?.stripeNurseryIntegration,
)

export const getBankDetails = createSelector(
  [getNurseriesSingleDataSelector],
  (nurseryDetails) => {
    if (!nurseryDetails) {
      return null
    }

    const { bankAccountName, bankAccountNotes, bankAccountNumber, bankName, sortCode } = nurseryDetails

    return {
      bankAccountName,
      bankAccountNotes,
      bankAccountNumber,
      bankName,
      sortCode,
    }
  },
)

export const getNurserySettingsPayload = (values) => {
  const { fields, nurseriesSingleState } = values || {}
  const { nurserySettings } = nurseriesSingleState.data || {}

  const {
    assessmentPeriods,
    childOverviewSectionOnFormativeReportsVisible,
    editingStaffRota,
    enabled,
    enabledForParents,
    formativeReportsTrackingMode,
    likeAndCommentMembers,
    security,
    shareAllObservationsWithParents,
    staffChildRatios,
    staffRequiredCalculation,
    useOnlySecureToMarkProgress,
  } = fields || {}

  const payload: NurseryPayloadProps = {
    nurserySettings: {
      id: nurserySettings.id,
    },
  }

  if (
    assessmentPeriods
    || !_.isUndefined(childOverviewSectionOnFormativeReportsVisible)
    || formativeReportsTrackingMode
    || !_.isUndefined(shareAllObservationsWithParents)
    || !_.isUndefined(useOnlySecureToMarkProgress)
  ) {
    payload.nurserySettings.learning = { ...nurserySettings.learning }

    if (assessmentPeriods || formativeReportsTrackingMode) {
      payload.nurserySettings.learning.assessmentPeriods = nurserySettings?.learning?.assessmentPeriods

      if (assessmentPeriods) {
        payload.nurserySettings.learning.assessmentPeriods = _.map(assessmentPeriods, (item) => ({
          age: item.age?.value ? item.age.value : item.age,
          label: item.label,
        }))
      }
    }

    if (!_.isUndefined(childOverviewSectionOnFormativeReportsVisible)) {
      // eslint-disable-next-line max-len
      payload.nurserySettings.learning.childOverviewSectionOnFormativeReportsVisible = childOverviewSectionOnFormativeReportsVisible
    }

    if (formativeReportsTrackingMode) {
      payload.nurserySettings.learning.formativeReportsTrackingMode = formativeReportsTrackingMode
    }

    if (!_.isUndefined(shareAllObservationsWithParents)) {
      payload.nurserySettings.learning.shareAllObservationsWithParents = shareAllObservationsWithParents
    }

    if (!_.isUndefined(useOnlySecureToMarkProgress)) {
      payload.nurserySettings.learning.useOnlySecureToMarkProgress = useOnlySecureToMarkProgress
    }
  }

  if (
    staffChildRatios
    || staffRequiredCalculation
  ) {
    payload.nurserySettings.occupancy = {}

    if (staffChildRatios) {
      payload.nurserySettings.occupancy.staffChildRatios = _.map(staffChildRatios, (staffChildRatio) => ({
        ageFrom: Number(staffChildRatio.ageFrom),
        ageTo: Number(staffChildRatio.ageTo),
        ratio: Number(staffChildRatio.ratio),
        usedForExpert: staffChildRatio.usedForExpert,
      }))
      payload.nurserySettings.occupancy.staffRequiredCalculation = nurserySettings.occupancy.staffRequiredCalculation
    }

    if (staffRequiredCalculation) {
      payload.nurserySettings.occupancy.staffChildRatios = nurserySettings.occupancy.staffChildRatios
      payload.nurserySettings.occupancy.staffRequiredCalculation = staffRequiredCalculation
    }
  }

  if (!_.isUndefined(editingStaffRota)) {
    payload.nurserySettings.staffRota = {
      pastRotaCanBeEdited: editingStaffRota === EDITING_STAFF_ROTA.PAST_WEEK_CAN_EDITED,
    }
  }

  if (!_.isUndefined(enabled)) {
    payload.nurserySettings.likeAndComment = {
      enabled,
    }
  }

  if (!_.isUndefined(likeAndCommentMembers)) {
    payload.nurserySettings.likeAndCommentMembers = likeAndCommentMembers
  }

  if (!_.isUndefined(enabledForParents)) {
    payload.nurserySettings.messaging = {
      enabledForParents,
    }
  }

  if (security) {
    payload.nurserySettings.security = {
      ...nurserySettings.security,
      sessionExpireTime: !_.isNil(security.sessionExpireTime)
        ? +security.sessionExpireTime
        : nurserySettings?.security?.sessionExpireTime,
      sessionExpireTimeEnabled: !_.isNil(security.sessionExpireTimeEnabled)
        ? security.sessionExpireTimeEnabled
        : nurserySettings?.security?.sessionExpireTimeEnabled,
      showAllChildren: !_.isNil(security.showAllChildren)
        ? security.showAllChildren
        : nurserySettings?.security?.showAllChildren,
    }
  }

  return payload
}

export const getNurserySettingsFormErrors = createSelector([getNurseriesSelector], (state) => {
  const errors = state.single.error && getBackendErrors(state.single.error)

  return errors && errors.nurserySettings && errors.nurserySettings.learning
})

export const getAuthNurseryLogo = createSelector([getAuthNursery], (authNursery) => {
  if (authNursery && authNursery.logo) {
    return authNursery.logo
  }

  if (authNursery && authNursery.organization && authNursery.organization.logo) {
    return authNursery.organization.logo
  }

  return null
})

export const getNurseryPublicUrl = (nursery) => {
  if (!nursery) {
    return null
  }

  const domainSuffix = findDomainSuffix()
  const portSuffix = getPortSuffix()

  return properties.nurseryDomainPrefix + nursery.subdomain + domainSuffix + portSuffix
}

export const getNurseryPublicUrlSelector = createSelector(
  [getNurseriesSingleDataSelector],
  (nursery) => {
    if (!nursery) {
      return null
    }

    const domainSuffix = findDomainSuffix()
    const portSuffix = getPortSuffix()

    return properties.nurseryDomainPrefix + nursery.subdomain + domainSuffix + portSuffix
  },
)

export const isAllYearRoundToTermTimeChange = createSelector(
  [((values) => values)],
  ({ newOpenPeriod, oldOpenPeriod }) => {
    const { OPEN_PERIOD } = constants

    return oldOpenPeriod
      && newOpenPeriod
      && oldOpenPeriod.value
      && newOpenPeriod.value
      && oldOpenPeriod.value === OPEN_PERIOD.OPEN_FOR_YEAR_ROUND
      && newOpenPeriod.value === OPEN_PERIOD.OPEN_FOR_TERM_TIME
  },
)

export const getNurseryEnquiryTokenSelector = createSelector(
  [getNurseriesSingleDataSelector],
  (nursery) => nursery?.enquiryToken,
)

export const getNurserySettingsRegisterPayload = (values) => {
  const { fields, nurseriesSingleState } = values || {}
  const { nurserySettings } = nurseriesSingleState.data || {}

  const { allowParentBookingAbsence } = fields || {}

  const payload: NurseryPayloadProps = {
    nurserySettings: {
      id: nurserySettings.id,
      register: {},
    },
  }

  if (null !== allowParentBookingAbsence || allowParentBookingAbsence !== undefined) {
    payload.nurserySettings.register.allowParentBookingAbsence = allowParentBookingAbsence
  }

  return payload
}

export const getMembersWithAccessToLikesAndComments = createSelector(
  [getNurseriesSingleDataSelector],
  (state): Membership[] => (
    state?.likeAndCommentMembers || []
  ),
)

export const getReferenceCodeGenerationPayload = ({ nurserySettings, payload }) => ({
  nurserySettings: {
    id: nurserySettings.id,
    referenceCodeGeneration: {
      ...payload,
    },
  },
})

export const getReferenceCodeGenerationInitialValues = createSelector(
  [getNurserySettings],
  (nursery): ReferenceCodeGenerationProps => {
    if (!nursery?.referenceCodeGeneration) {
      return null
    }

    const {
      codePrefix,
      codePrefixEnabled,
      counter,
      randomisedBySystem,
      sequential,
      sequentialNumber,
    } = nursery.referenceCodeGeneration

    return {
      codePrefix,
      codePrefixEnabled,
      counter,
      randomisedBySystem: randomisedBySystem
        ? CODE_AUTOMATIC_GENERATION.RENDOMISED_BY_SYSTEM
        : CODE_AUTOMATIC_GENERATION.SEQUENTIAL_NUMBERS,
      sequential,
      sequentialNumber,
    }
  },
)

export const getReferenceCodeGeneration = createSelector(
  [getNurserySettings],
  (nurserySettings): ReferenceCodeGenerationProps => {
    if (!nurserySettings?.referenceCodeGeneration) {
      return null
    }

    return nurserySettings.referenceCodeGeneration
  },
)

