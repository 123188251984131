import { CLEAR_LEAVE_NOTE, CREATE_LEAVE_NOTE, CREATE_LEAVE_NOTE_FAILED, CREATE_LEAVE_NOTE_SUCCESS } from './constants'

const initialState = {
  data: null,
  error: null,
  isSubmitting: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case CREATE_LEAVE_NOTE:
      return { ...state, error: initialState.error, isSubmitting: true }
    case CREATE_LEAVE_NOTE_SUCCESS:
      return { ...state, data: payload.data, isSubmitting: false }
    case CREATE_LEAVE_NOTE_FAILED:
      return { ...state, error, isSubmitting: false }
    case CLEAR_LEAVE_NOTE:
      return initialState
    default:
      return state
  }
}
