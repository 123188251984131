import _ from 'lodash'

import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'

import { properties } from 'app-config'

export const findDomainSuffix = (hostname = window.location.hostname) => _.find(
  properties.nurseryDomainSuffixes,
  (suffix) => hostname.includes(suffix),
)

export const getPortSuffix = () => (window.location.port ? `:${window.location.port}` : '')

export const findDomain = () => {
  const suffix = findDomainSuffix()

  return suffix ? suffix.replace(/^./, '') : null
}

export const getEffectiveSubdomain = (domain, possibleSuffix, defaultSubdomain = properties.defaultSubdomain) => {
  if ('LOCAL' === properties.env && properties.debugSubdomain) {
    return properties.debugSubdomain
  }

  if (!defaultSubdomain) {
    return null
  }

  // if the domain does not end with the possible suffix then return
  if (domain.slice(-(possibleSuffix?.length || 0)) !== possibleSuffix) {
    return null
  }

  return domain.slice(0, (domain?.length || 0) - (possibleSuffix?.length || 0))
}

export const getEffectiveAuthenticationSubdomain = (
  domain,
  possibleSuffix,
  defaultSubdomain = properties.defaultSubdomain,
) => {
  const subdomain = getEffectiveSubdomain(domain, possibleSuffix, defaultSubdomain)

  if (subdomain?.toString().toLowerCase() === defaultSubdomain?.toString().toLowerCase()) {
    return null
  }

  return subdomain
}

export const getSubdomain = (state: RootState) => state.subdomain?.single

export const getSubdomainInfo = createSelector([getSubdomain], (subdomain) => {
  if (!subdomain) {
    return null
  }

  return subdomain.data
})

export const subdomainIsBranded = createSelector([getSubdomainInfo], (data) => Boolean(data?.customBranding))

export const getCustomBranding = createSelector([getSubdomainInfo], (data) => data?.customBranding)

export const getSubdomainId = createSelector([getSubdomainInfo], (data) => data?.id)

export const getSubdomainLogo = createSelector([getSubdomainInfo], (data) => data?.logo)

export const getSubdomainName = createSelector([getSubdomainInfo], (data) => data?.name)

export const getSubdomainUserflowToken = createSelector([getSubdomainInfo], (data) => data?.userflowToken)

export const getDashboardIcon = createSelector([getCustomBranding], (customBranding) => {
  if (!customBranding || !customBranding.dashboardIcon) {
    return '/images/blossom-logo.png'
  }

  return customBranding.dashboardIcon
})

export const getCustomLogo = createSelector(
  [getCustomBranding],
  (customBranding) => customBranding && customBranding.logo,
)

export const getDashboardTitle = createSelector(
  [getCustomBranding],
  (customBranding) => (customBranding && customBranding.dashboardTitle) || 'Blossomboard',
)

export const hostname = window.location.hostname
