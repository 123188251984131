import * as conversionRateActions from './conversionRate/actions'
import * as conversionRateStatisticsActions from './conversionRateStatistics/actions'
import * as performanceActions from './performance/actions'
import * as viewingPerformanceActions from './viewingPerformance/actions'
import * as viewingPerformanceStatisticsActions from './viewingPerformanceStatistics/actions'

export default {
  ...conversionRateActions,
  ...conversionRateStatisticsActions,
  ...performanceActions,
  ...viewingPerformanceActions,
  ...viewingPerformanceStatisticsActions,
}
