import { BasicListInitialState, getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import {
  UPDATE_BATCH_NURSERY_SESSIONS,
  UPDATE_BATCH_NURSERY_SESSIONS_FAILED,
  UPDATE_BATCH_NURSERY_SESSIONS_SUCCESS,
} from './constants'

const initialState = getBasicListInitialState

export interface NurserySessionsBatchState extends BasicListInitialState {}

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicListReducers({
    failed: UPDATE_BATCH_NURSERY_SESSIONS_FAILED,
    init: UPDATE_BATCH_NURSERY_SESSIONS,
    success: UPDATE_BATCH_NURSERY_SESSIONS_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
