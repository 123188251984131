import _ from 'lodash'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { DISCONNECT_CONDITIONS } from 'services/nurseryIntegrations/constants'

import { Button, Icon, MinimalModalBox, Space, Typography } from 'components'

import i18n from 'translations'

import {
  StyledConditionItem,
  StyledContent,
  StyledEmptyCircle,
  StyledFooter,
} from './StripeDisconnectConditionsModalStyled'

const StripeDisconnectConditionsModalView = ({
  conditions,
  onHideModal,
}) => {
  const renderConditionItem = ({ label, value }) => (
    <StyledConditionItem key={value}>
      {conditions[value]
        ? (
          <Icon
            color={NEUTRAL_COLOURS.WHITE}
            height={32}
            icon="circle-tick-secondary"
          />
        ) : (
          <StyledEmptyCircle />
        )}
      <Typography fontSize={18}>
        {label}
      </Typography>
    </StyledConditionItem>
  )

  return (
    <MinimalModalBox>
      <StyledContent>
        <MinimalModalBox.TopIcon height={78} icon="warning" />
        <Typography align="center" fontSize={24}>
          {i18n.t('module:Modals:StripeDisconnectConditions:cannotDisconnectStripe')}
        </Typography>
        <Space space="25px" />
        <Typography fontSize={18}>
          {i18n.t('module:Modals:StripeDisconnectConditions:toDisconnectYourStripeAccount')}
        </Typography>
        <Space space="28px" />
        {_.map(DISCONNECT_CONDITIONS, renderConditionItem)}
        <StyledFooter>
          <Button
            color="tertiary"
            label={i18n.t('global:Ok')}
            inverted
            negativeMargins
            onClick={onHideModal}
          />
        </StyledFooter>
      </StyledContent>
    </MinimalModalBox>
  )
}

export default StripeDisconnectConditionsModalView
