import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import { CLEAR_USER, GET_USER, GET_USER_FAILED, GET_USER_SUCCESS } from './constants'

export const get = (options: BasicActionOptionsProps) => ({ usersApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: GET_USER_FAILED,
      init: GET_USER,
      success: GET_USER_SUCCESS,
    },
    options,
    service: usersApiClient,
    serviceMethod: 'get',
  })
)

export const clear = () => ({
  type: CLEAR_USER,
})
