import { createSelector } from 'reselect'

const getChildrenLeavingReport = (state) => state.childrenLeavingReport

export const getChildrenLeavingReportStatistics = createSelector(
  [getChildrenLeavingReport],
  (state) => state.statistics,
)

export const getChildrenLeavingReportStatisticsData = createSelector(
  [getChildrenLeavingReportStatistics],
  (state) => state.data,
)
