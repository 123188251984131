import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { STATUS_TYPES } from 'services/legacy/invoices/constants'

import { DEPOSIT_TYPES, STATISTIC_TYPES } from '../constants'

const getDepositsState = (state) => state.deposits

export const getListSelector = createSelector(
  [getDepositsState],
  (state) => state?.list,
)

export const getListDataSelector = createSelector(
  [getListSelector],
  (state) => state?.data,
)

const getStatisticsFilterCriteria = (statisticsType) => {
  switch (statisticsType) {
    case STATISTIC_TYPES.RAISED:
      return [
        {
          field: 'type',
          value: DEPOSIT_TYPES.DEPOSIT,
        },
      ]
    case STATISTIC_TYPES.HELD:
      return [
        {
          field: 'type',
          value: DEPOSIT_TYPES.DEPOSIT,
        },
        {
          field: 'or[0][invoiceItem.invoice.status][eq]',
          value: STATUS_TYPES.PAID,
        },
        {
          field: 'or[0][used][eq]',
          value: 0,
        },
        {
          field: 'refundDeposit[exists]',
          value: 0,
        },
      ]
    case STATISTIC_TYPES.REFUNDED:
      return [
        {
          field: 'type',
          value: DEPOSIT_TYPES.REFUND,
        },
        {
          field: 'or[0][invoiceItem.invoice.status][neq] ',
          value: STATUS_TYPES.DRAFT,
        },
        {
          field: 'or[0][used][eq]',
          value: 0,
        },
      ]
    case STATISTIC_TYPES.PENDING_DEPOSITS:
      return [
        {
          field: 'type',
          value: DEPOSIT_TYPES.DEPOSIT,
        },
        {
          field: 'or[0][invoiceItem.invoice.status][eq]',
          value: STATUS_TYPES.DRAFT,
        },
        {
          field: 'or[0][used][eq]',
          value: 1,
        },
        {
          field: 'or[1][invoiceItem.invoice.status][eq]',
          value: STATUS_TYPES.DRAFT,
        },
        {
          field: 'or[1][invoiceItem.id][eq]',
          value: null,
        },
      ]
    case STATISTIC_TYPES.PENDING_REFUNDS:
      return [
        {
          field: 'type',
          value: DEPOSIT_TYPES.REFUND,
        },
        {
          field: 'or[0][invoiceItem.invoice.status][eq]',
          value: STATUS_TYPES.DRAFT,
        },
        {
          field: 'or[0][used][eq]',
          value: 1,
        },
        {
          field: 'or[1][invoiceItem.invoice.status][eq]',
          value: STATUS_TYPES.DRAFT,
        },
        {
          field: 'or[1][invoiceItem.id][eq]',
          value: null,
        },
      ]
    default:
      return []
  }
}

export const getCriteria = (filters) => {
  const {
    childId,
    search,
    statisticsType,
    type,
  } = filters

  const criteria = []

  if (statisticsType) {
    criteria.push(...getStatisticsFilterCriteria(statisticsType))
  }

  if (type) {
    criteria.push({
      field: 'type',
      value: type,
    })
  }

  if (search) {
    criteria.push({
      field: 'concatenable',
      value: search,
    })
  }

  if (childId) {
    criteria.push({
      field: 'child',
      value: childId,
    })
  }

  return criteria
}

export const getExportCriteria = (filters) => {
  const {
    date,
    membership,
    statisticsType,
  } = filters

  const criteria = []

  if (statisticsType) {
    criteria.push(...getStatisticsFilterCriteria(statisticsType))
  }

  if (date) {
    criteria.push({
      field: 'date[after]',
      value: moment(date.after).format(DEFAULT_DATE_FORMAT),
    }, {
      field: 'date[before]',
      value: moment(date.before).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (membership) {
    criteria.push({
      field: 'membership',
      value: membership,
    })
  }

  return criteria
}
