import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { ROTA_STATISTICS_TYPE } from '../constants'

const getMembershipsShiftsStatisticsSelector = (state) => state.membershipsShifts.statistics

export const getRotaStatisticsSelector = createSelector(
  [getMembershipsShiftsStatisticsSelector],
  (state) => {
    const { data } = state
    const { periods } = data?.[0] || {}

    return _.groupBy(periods, ({ date }) => moment(date)
      .format(DEFAULT_DATE_FORMAT))
  },
)

export const getStatisticsCriteria = (params) => {
  const { dateFrom, room, type = ROTA_STATISTICS_TYPE.WEEKLY } = params
  const criteria = []

  if (dateFrom) {
    criteria.push({
      field: 'rotaStatistics.date',
      value: moment(dateFrom).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (room && 0 !== room.value) {
    criteria.push({
      field: 'id',
      value: +room,
    })
  }

  criteria.push({
    field: 'rotaStatistics.type',
    value: type,
  })

  return criteria
}
