import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as balanceAdjustmentsSelectors from './selectors'

const withBalanceAdjustmentsService = (WrappedComponent) => {
  const mapState = ({ balanceAdjustments }) => ({ balanceAdjustments })

  const mapDispatch = injectActions('balanceAdjustmentsActions', actions)

  const Component = ({ balanceAdjustments, balanceAdjustmentsActions, ...others }) => (
    <WrappedComponent
      balanceAdjustmentsActions={balanceAdjustmentsActions}
      balanceAdjustmentsSelectors={balanceAdjustmentsSelectors}
      balanceAdjustmentsSingleState={balanceAdjustments.single}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withBalanceAdjustmentsService
