import ServiceBase from 'services/ServiceBase'
import { getQueryStringV2 } from 'utils/api'

class AgedDebtApiClient extends ServiceBase {
  sendReport = (params) => this.request({
    path: `/v2/children/report/aged-debt?${getQueryStringV2(params)}`,
  })

  getStatistics = (params = {}) => this.request({
    path: `/v2/statistics/aged-debt?${getQueryStringV2(params)}`,
  })
}

export default AgedDebtApiClient
