import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class MembershipsShiftsApiClient extends ServiceBase {
  create = (payload = {}, params) => this.request({
    body: payload,
    method: 'POST',
    path: `/v2/membership_shifts?${getQueryStringV2(params)}`,
  })

  createBatch = (payload = {}, params) => this.request({
    body: payload,
    method: 'POST',
    path: `/v2/batch/membership_shifts?${getQueryStringV2(params)}`,
  })

  exportReport = (type, params = {}, payload) => this.request({
    body: payload,
    method: 'POST',
    path: `/v2/export/rota/report/${type}?${getQueryStringV2(params)}`,
  })

  list = (params = {}) => this.request({
    method: 'GET',
    path: `/v2/memberships/shifts?${getQueryStringV2(params)}`,
  })

  listGroupedByShifts = (params = {}) => this.request({
    method: 'GET',
    path: `/v2/membership_shifts?${getQueryStringV2(params)}`,
  })

  getRotaStatistics = (params = {}) => this.request({
    method: 'GET',
    path: `/v2/statistics/rota/planned?${getQueryStringV2(params)}`,
  })

  update = (id, payload, params = {}) => this.request({
    body: payload,
    method: 'PUT',
    path: `/v2/membership_shifts/${id}?${getQueryStringV2(params)}`,
  })

  remove = (id) => this.request({
    method: 'DELETE',
    path: `/v2/membership_shifts/${id}`,
  })
}

export default MembershipsShiftsApiClient
