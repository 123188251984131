import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import { properties } from 'app-config'
import { Profile } from 'services/authentication/models'

const canInstall = () => {
  if (!properties.ravenDNS) {
    // eslint-disable-next-line no-console
    console.warn('[SENTRY_DNS] not defined.')

    return false
  }

  if ('LOCAL' === properties.env) {
    // eslint-disable-next-line no-console
    console.warn('[SENTRY] not installed on LOCAL env.')

    return false
  }

  return true
}

const muteCustomErrors = (event, hint) => {
  const userAgent = event?.request?.headers['User-Agent']
  const error = hint.originalException

  if (
    (
      -1 < userAgent.indexOf('OS 10_3_')
      || (
        -1 < userAgent.indexOf('Version/10.0')
        && -1 < userAgent.indexOf('Safari')
      )
    ) && (
      error.message.match(/Invalid attempt to destructure non-iterable instance/)
      || error.message.match(/Invalid attempt to spread non-iterable instance/)
    )
  ) {
    return null
  }

  return event
}

export const installSentry = () => {
  if (!canInstall()) {
    return
  }

  Sentry.init({
    allowUrls: [
      /\.blossomeducational\.com/,
      /\.staging\.blsm\.uk/,
      /\.demo\.blsm\.uk/,
      /\.hotfix\.blsm\.uk'/,
      /\.dev\.blsm\.uk'/,
    ],
    beforeSend: muteCustomErrors,
    denyUrls: [
      /extensions\//i,
      /^chrome:\/\//i,
    ],
    dsn: properties.ravenDNS,
    ignoreErrors: properties.sentry.ignoreErrors,
    integrations: [new BrowserTracing()],
    maxValueLength: 1000,
    release: properties.release,
    tracesSampleRate: 0.2,
  })
}

export const setUserContextForSentry = (profile: Profile) => {
  if (!canInstall()) {
    return
  }

  const { featureFlags, nursery, user } = profile

  const context = {
    email: user.email,
    id: user.id,
    username: user.username,
  } as any

  if (nursery) {
    context.nursery = {
      id: nursery.id,
      subdomain: nursery.subdomain,
    }
  }

  if (featureFlags) {
    context.featureFlags = featureFlags
  }

  Sentry.configureScope((scope) => {
    scope.setTag('codeVersion', properties.codeVersion)
    scope.setUser(context)
  })
}
