import React from 'react'

import { Button, Icon, Typography } from 'components'

import i18n from 'translations'

import { StyledConfirmationMessage, StyledContainer, StyledContent } from './ResendInvitationBannerStyled'

const ResendInvitationBanner = ({
  invitation: { error, isProcessing = false, sent = false },
  membershipIsAccepted = true,
  onClick,
}) => {
  if (membershipIsAccepted) {
    return null
  }

  const renderErrorMessage = () => error && (
    <Typography align="center" color="#E31723" fontSize={14} margin="0 0 10px">
      {error}
    </Typography>
  )

  const renderConfirmationMessage = () => sent && !error && (
    <StyledConfirmationMessage>
      <Icon icon="check" />
      <Typography margin="0 0 0 3px" tertiary>
        {i18n.t('module:Staff:StaffAdd:ResendInvitation:confirmationMessage')}
      </Typography>
    </StyledConfirmationMessage>
  )

  return (
    <StyledContainer>
      {renderErrorMessage()}
      <StyledContent>
        <div>
          <Typography>
            {i18n.t('module:Staff:StaffAdd:ResendInvitation:text')}
          </Typography>
        </div>
        <Button
          disabled={isProcessing}
          isLoading={isProcessing}
          label={i18n.t('module:Staff:StaffAdd:ResendInvitation:label')}
          onClick={onClick}
        />
        {renderConfirmationMessage()}
      </StyledContent>
    </StyledContainer>
  )
}

export default ResendInvitationBanner
