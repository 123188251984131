import moment from 'moment'
import _ from 'lodash'

import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import {
  CLEAR_LIST_OF_FRAMEWORK_MONTESSORI_PROGRESS,
  LIST_OF_FRAMEWORK_MONTESSORI_PROGRESS,
  LIST_OF_FRAMEWORK_MONTESSORI_PROGRESS_FAILED,
  LIST_OF_FRAMEWORK_MONTESSORI_PROGRESS_SUCCESS,
  UPDATE_BATCH_FRAMEWORK_MONTESSORI_PROGRESS,
  UPDATE_BATCH_FRAMEWORK_MONTESSORI_PROGRESS_FAILED,
  UPDATE_BATCH_FRAMEWORK_MONTESSORI_PROGRESS_SUCCESS,
  UPDATE_SINGLE_MONTESSORI_PROGRESS,
} from './constants'

export default (...props) => {
  const [state = {
    ...getBasicListInitialState,
    isSubmitting: false,
  }, { payload, type }] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_LIST_OF_FRAMEWORK_MONTESSORI_PROGRESS,
    failed: LIST_OF_FRAMEWORK_MONTESSORI_PROGRESS_FAILED,
    init: LIST_OF_FRAMEWORK_MONTESSORI_PROGRESS,
  }, getBasicListInitialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  if (type === UPDATE_BATCH_FRAMEWORK_MONTESSORI_PROGRESS) {
    return {
      ...state,
      isSubmitting: true,
    }
  }

  if (type === UPDATE_BATCH_FRAMEWORK_MONTESSORI_PROGRESS_FAILED) {
    return {
      ...state,
      isSubmitting: false,
    }
  }

  if (type === UPDATE_BATCH_FRAMEWORK_MONTESSORI_PROGRESS_SUCCESS) {
    if (_.find(payload.data, (i) => i.exception?.error)) {
      return {
        ...state,
        isSubmitting: false,
      }
    }

    const { data: [{ childMontessoriActivityEvent }] } = payload

    return {
      ...state,
      data: {
        ...state.data,
        [childMontessoriActivityEvent.montessoriActivity.id]: childMontessoriActivityEvent,
      },
      isSubmitting: false,
    }
  }

  if (type === UPDATE_SINGLE_MONTESSORI_PROGRESS) {
    return {
      ...state,
      data: {
        ...state.data,
        [payload.frameworkItem.id]: {
          [payload.state]: {
            state: payload.state,
          },
        },
      },
    }
  }

  if (type === LIST_OF_FRAMEWORK_MONTESSORI_PROGRESS_SUCCESS) {
    const data = {}

    _.each(payload.data?.subcategories, ({ activities }) => {
      _.each(activities, (item) => {
        if (_.keys(item?.progress?.progress).length) {
          data[item.id] = _.maxBy(_.values(item?.progress?.progress), ({ createdAt }) => (
            moment(createdAt).format('x')
          ))
        }
      })
    })

    return {
      data,
      error: null,
      isFetching: false,
      meta: {
        ...state.meta,
        ...payload.meta,
      },
    }
  }

  return state
}
