import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { reset, stopSubmit } from 'redux-form'

import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withNurseriesService } from 'services/nurseries'
import { withRequestedExtraSessionsService } from 'services/legacy/requestedExtraSessions'

import ExceptionalUnavailableTimesModalView from './ExceptionalUnavailableTimesModalView'
import { EXCEPTIONAL_UNAVAILABLE_TIMES_MODAL_FORM } from './components/ExceptionalUnavailableTimesModalForm'
import { getInitialValues } from './ExceptionalUnavailableTimesModalHelpers'

class ExceptionalUnavailableTimesModalContainer extends Component {
  componentWillUnmount() {
    const {
      resetForm,
    } = this.props

    resetForm()
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSubmitFailed = (response) => {
    const { injectValidation } = this.props
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    return injectValidation(errors)
  }

  handleSubmitSuccess = () => {
    const { onSuccess } = this.props

    onSuccess()

    return this.handleCloseClick()
  }

  handleSubmit = (values) => {
    const {
      id,
      isEditMode,
      requestedExtraSessionsActions,
      requestedExtraSessionsSelectors,
    } = this.props

    const payload = requestedExtraSessionsSelectors.getExceptionalUnavailableTimesPayload(values)

    if (isEditMode) {
      return requestedExtraSessionsActions.updateExceptionalUnavailableTime({
        body: payload,
        onFailed: this.handleSubmitFailed,
        onSuccess: this.handleSubmitSuccess,
        params: [id, {}],
      })
    }

    return requestedExtraSessionsActions.createExceptionalUnavailableTime({
      body: payload,
      onFailed: this.handleSubmitFailed,
      onSuccess: this.handleSubmitSuccess,
      params: [{}],
    })
  }

  render() {
    const {
      data,
      errorMessages,
      isEditMode,
      isFetching,
      isSubmitting,
      nurserySettings,
    } = this.props

    const initialValues = getInitialValues({ data, nurserySettings })

    return (
      <ExceptionalUnavailableTimesModalView
        errorMessages={errorMessages}
        initialValues={initialValues}
        isEditMode={isEditMode}
        isFetching={isFetching}
        isSubmitting={isSubmitting}
        onCloseClick={this.handleCloseClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  injectValidation: (data) => stopSubmit(EXCEPTIONAL_UNAVAILABLE_TIMES_MODAL_FORM, data),
  resetForm: () => reset(EXCEPTIONAL_UNAVAILABLE_TIMES_MODAL_FORM),
}

const mapState = (state, {
  appSelectors,
  exceptionalUnavailableTimesSingleState,
  nurseriesSelectors,
}) => ({
  errorMessages: appSelectors.getErrorMessages(exceptionalUnavailableTimesSingleState),
  isFetching: appSelectors.getIsFetching(exceptionalUnavailableTimesSingleState),
  isSubmitting: appSelectors.getIsSubmitting(exceptionalUnavailableTimesSingleState),
  nurserySettings: nurseriesSelectors.getNurserySettings(state),
})

const enhance = compose(
  withAppService,
  withModalService,
  withNurseriesService,
  withRequestedExtraSessionsService,
  connect(mapState, mapDispatch),
)

export default enhance(ExceptionalUnavailableTimesModalContainer)
