import styled from 'styled-components'

import layout from 'constants/layout'

export const StyledDoubleTimePicker = styled.div`
  display: flex;
  flex-direction: row;

  @media(max-width: 400px) {
    flex-direction: column;
  }
`

export const StyledPause = styled.div`
  font-size: 14px;
  margin: 14px 10px 0 10px;

  @media(max-width: 400px) {
    margin: 5px 10px;
  }
`

/* eslint-disable max-len */
export const StyledTrashIcon = styled.div`
  margin-top: 0;

  @media(max-width: calc(${layout.tabletWidthResolution}px - 1px)) and (min-width: calc(${layout.mobileWidthResolution}px + 1px)) {
    margin-top: 25px;
  }
`
/* eslint-enable max-len */

export const StyledMinStaffLabels = styled.div`
  @media(max-width: calc(${layout.tabletWidthResolution}px - 1px)) {
    display: none;
  }
`

export const StyledTopLabel = styled.div`
  @media(min-width: ${layout.tabletWidthResolution}px) {
    display: none;
  }
`
