import React from 'react'

import { AUTH_TYPE_PIN } from 'services/device/constants'

import { Callout, Page, Typography } from 'components'

import i18n from 'translations'

import AddDeviceForm from './components/AddDeviceForm'

const AddDeviceView = ({
  errorMessages,
  isSubmitting,
  method,
  onSubmit,
}) => (
  <Page.Section title={i18n.t('module:Management:Security:AuthorisedDevices:AddDevice:title')}>
    <Callout content={errorMessages} error />
    {method === AUTH_TYPE_PIN && (
      <Typography margin="0 0 25px" bold>
        {i18n.t('module:Management:Security:AuthorisedDevices:global:description')}
      </Typography>
    )}
    <AddDeviceForm
      isSubmitting={isSubmitting}
      pinVersion={method === AUTH_TYPE_PIN}
      onSubmit={onSubmit}
    />
  </Page.Section>
)

export default AddDeviceView
