import styled from 'styled-components'

export const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledContent = styled.div`
  flex: 1;  
`
