import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as registerStatisticsSelectors from './selectors'

const withTransactionsService = (WrappedComponent) => {
  const mapState = ({ registerStatistics }) => ({ registerStatistics })

  const mapDispatch = injectActions('registerStatisticsActions', actions)

  const Component = ({ registerStatistics, registerStatisticsActions, ...others }) => (
    <WrappedComponent
      registerStatisticsActions={registerStatisticsActions}
      registerStatisticsSelectors={registerStatisticsSelectors}
      registerStatisticsState={registerStatistics.statistics}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withTransactionsService
