import { STATUS_TYPES } from 'services/legacy/enquiries/settings/constants'

export const ROUTE_URL_TYPE = {
  LOST_REASON: 'lost-reason',
  REASON: 'reason',
  SOURCE: 'source',
}

export const ROUTE_URL_TYPE_MAPPINGS = {
  [ROUTE_URL_TYPE.LOST_REASON]: STATUS_TYPES.LOST_REASON,
  [ROUTE_URL_TYPE.REASON]: STATUS_TYPES.REASON,
  [ROUTE_URL_TYPE.SOURCE]: STATUS_TYPES.SOURCE,
}

export const TYPE_TO_ROUTE_URL_MAPPINGS = {
  [STATUS_TYPES.LOST_REASON]: ROUTE_URL_TYPE.LOST_REASON,
  [STATUS_TYPES.REASON]: ROUTE_URL_TYPE.REASON,
  [STATUS_TYPES.SOURCE]: ROUTE_URL_TYPE.SOURCE,
}

export const TRANS_TYPE_MAPPINGS = {
  [STATUS_TYPES.LOST_REASON]: 'LostReason',
  [STATUS_TYPES.REASON]: 'EnquiryReason',
  [STATUS_TYPES.SOURCE]: 'EnquirySource',
}
