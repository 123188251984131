import styled from 'styled-components'

import layout from 'constants/layout'

export const StyledFooterContainer = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: auto auto;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    grid-template-columns: auto;
    grid-row-gap: 20px;
  }
`
