export const CLEAR_CHILD_EXTRA_ITEMS = 'CLEAR_CHILD_EXTRA_ITEMS'

export const LIST_GET_CHILD_EXTRA_ITEMS = 'LIST_GET_CHILD_EXTRA_ITEMS'
export const LIST_GET_CHILD_EXTRA_ITEMS_SUCCESS = 'LIST_GET_CHILD_EXTRA_ITEMS_SUCCESS'
export const LIST_GET_CHILD_EXTRA_ITEMS_FAILED = 'LIST_GET_CHILD_EXTRA_ITEMS_FAILED'

export const listExtraItems = (params, mergeData, onSuccess) => ({ childExtraItemsApiClient }) => (dispatch) => {
  dispatch({
    params,
    type: LIST_GET_CHILD_EXTRA_ITEMS,
  })

  childExtraItemsApiClient.list(params).then(
    (data) => {
      dispatch({
        mergeData,
        payload: data,
        type: LIST_GET_CHILD_EXTRA_ITEMS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: LIST_GET_CHILD_EXTRA_ITEMS_FAILED,
      })
    },
  )
}

export const clear = () => ({
  type: CLEAR_CHILD_EXTRA_ITEMS,
})
