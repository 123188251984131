import React from 'react'

import { StyledWidgetListContainer } from './WidgetListStyled'

const WidgetList = ({ children, reverse }) => (
  <StyledWidgetListContainer reverse={reverse}>
    {children}
  </StyledWidgetListContainer>
)

export default WidgetList
