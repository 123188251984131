import { generateBasicActions } from 'utils/service'

import {
  CLEAR_DEPOSITS,
  EXPORT_DEPOSITS,
  EXPORT_DEPOSITS_FAILED,
  EXPORT_DEPOSITS_SUCCESS,
  LIST_DEPOSITS,
  LIST_DEPOSITS_FAILED,
  LIST_DEPOSITS_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ depositsApiClient }) => generateBasicActions.list({
  constants: {
    failed: LIST_DEPOSITS_FAILED,
    init: LIST_DEPOSITS,
    success: LIST_DEPOSITS_SUCCESS,
  },
  options,
  service: depositsApiClient,
  serviceMethod: 'list',
})

export const exportDeposits = (options = {}) => ({ depositsApiClient }) => generateBasicActions.list({
  constants: {
    failed: EXPORT_DEPOSITS_FAILED,
    init: EXPORT_DEPOSITS,
    success: EXPORT_DEPOSITS_SUCCESS,
  },
  options,
  service: depositsApiClient,
  serviceMethod: 'export',
})

export const clear = () => ({
  type: CLEAR_DEPOSITS,
})
