import React from 'react'

import { INJURIES, SIGNATURE_TYPES } from 'services/legacy/injuries/constants'

import { Callout, ModalBox } from 'components'

import i18n from 'translations'

import { INJURY_SIGNATURE_STEP_TYPES } from './injurySignatureModalConstants'
import { ChildReport, Info, Signature } from './components'

const InjurySignatureModalView = ({
  currentStep,
  errorMessage,
  hideWaterMark,
  injuryPreview,
  isSubmitting,
  onAddSignatureClick,
  onBackClick,
  onCloseClick,
  onContinueClick,
  onHideWaterMark,
  signatureItem,
  signatureUploaded,
}) => {
  const renderComponent = () => {
    if (INJURY_SIGNATURE_STEP_TYPES.INFO === currentStep) {
      return (
        <Info onContinueClick={onContinueClick} />
      )
    }

    if (INJURY_SIGNATURE_STEP_TYPES.REPORT === currentStep) {
      const { owner, type } = signatureItem || {}

      let childId = ''

      if (type === SIGNATURE_TYPES.FIRSTAID || type === SIGNATURE_TYPES.PARENT) {
        const { child = {} } = owner || {}

        childId = child.id
      }

      return (
        <ChildReport
          childId={childId}
          injuryPreview={injuryPreview}
          onContinueClick={onContinueClick}
        />
      )
    }

    return (
      <Signature
        hideWaterMark={hideWaterMark}
        injuryPreview={injuryPreview}
        isSubmitting={isSubmitting}
        signatureItem={signatureItem}
        signatureUploaded={signatureUploaded}
        onBackClick={onBackClick}
        onCloseClick={onCloseClick}
        onContinueClick={onAddSignatureClick}
        onHideWaterMark={onHideWaterMark}
      />
    )
  }

  const getTitle = () => {
    if (INJURY_SIGNATURE_STEP_TYPES.REPORT === currentStep) {
      const { type } = injuryPreview

      const accidentIncidentLabel = type === INJURIES.INCIDENT
        ? i18n.t('module:Injury:Common:incident')
        : i18n.t('module:Injury:Common:accident')

      return i18n.t('module:Modals:InjurySignature:ChildPreview:title', { type: accidentIncidentLabel })
    }

    return i18n.t('module:Modals:InjurySignature:Info:title')
  }

  return (
    <ModalBox
      maxWidth={900}
      scrollToBody={false}
      title={getTitle()}
      autoHeight
      onCloseClick={onCloseClick}
    >
      <Callout content={errorMessage} error />
      {renderComponent()}
    </ModalBox>
  )
}

export default InjurySignatureModalView
