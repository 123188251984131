import React, { PropsWithChildren } from 'react'

import { StyledItemContainer } from './FooterActionsStyled'

const FooterActionsItem: React.FC<PropsWithChildren<any>> = ({ children }) => (
  <StyledItemContainer>
    {children}
  </StyledItemContainer>
)

export default FooterActionsItem
