import React, { Component } from 'react'
import { compose } from 'recompose'

import { withModalService } from 'services/utils/modal'

import ConversationMembersModalView from './ConversationMembersModalView'

class ConversationMembersModalContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      search: '',
    }
  }

  handleChangeSearch = (search) => {
    this.setState({ search })
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  render() {
    const { channel, channelMembers } = this.props
    const { search } = this.state

    return (
      <ConversationMembersModalView
        channel={channel}
        channelMembers={channelMembers}
        search={search}
        onChangeSearch={this.handleChangeSearch}
        onCloseClick={this.handleCloseClick}
      />
    )
  }
}

const enhance = compose(
  withModalService,
)

export default enhance(ConversationMembersModalContainer)
