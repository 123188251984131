import React, { PropsWithChildren } from 'react'
import { Link } from 'react-router'

import { Avatar, Icon } from 'components'

import i18n from 'translations'

import {
  StyledContainer,
  StyledIconContainer,
  StyledMainContainer,
  StyledName,
  StyledPhotoContainer,
  StyledSelectedIconContainer,
  StyledSubtitle,
} from './UserTileStyled'

interface UserTileProps {
  archived?: boolean
  compact?: boolean
  disabled?: boolean
  firstName?: string
  icon?: React.ReactNode
  onClick?: () => {}
  photo?: string
  selected?: boolean
  subtitle?: string
  surname?: string
  title?: string
  to?: string
}

const UserTile: React.FC<PropsWithChildren<UserTileProps>> = ({
  archived,
  compact,
  disabled,
  firstName,
  icon,
  onClick,
  photo,
  selected,
  subtitle,
  surname,
  title,
  to,
}) => {
  const Container = to ? StyledContainer.withComponent(Link) : StyledContainer

  return (
    <Container
      $disabled={disabled}
      $selected={selected}
      to={to}
      onClick={!disabled ? onClick : undefined}
    >
      <StyledPhotoContainer>
        <Avatar
          avatarSize="medium"
          initials={[firstName, surname]}
          src={photo}
        />
      </StyledPhotoContainer>
      <StyledMainContainer>
        <StyledName $compact={compact}>
          {title}
          {archived ? ` (${i18n.t('global:archived')})` : null}
        </StyledName>
        {subtitle && (
          <StyledSubtitle>
            {subtitle}
          </StyledSubtitle>
        )}
      </StyledMainContainer>
      {icon && (
        <StyledIconContainer $selected={selected}>
          {icon}
        </StyledIconContainer>
      )}
      {selected && (
        <StyledSelectedIconContainer>
          <Icon color="#fff" height={18} icon="circle-tick" />
        </StyledSelectedIconContainer>
      )}
    </Container>
  )
}

export default UserTile
