import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'

import { getBrandingColor } from 'utils/branding'

import { Avatar, Checkbox, Icon, Typography } from 'components'

export const getTableData = ({
  all,
  dailyDiaries,
  data,
  onSelectItemClick,
  skipIds,
}) => {
  if (!data?.length) {
    return []
  }

  return _.map(data, ({ approved, child, id, updatedAt }) => ({
    child: (
      <Avatar
        avatarSize="small"
        initials={[child.firstName, child.surname]}
        src={child.photo}
        title={(
          <Typography fontSize={14} primary>
            {`${child.firstName} ${child.surname}`}
          </Typography>
        )}
      />
    ),
    lastSentAt: approved && moment(updatedAt).format(DISPLAY_SHORT_DATE_FORMAT),
    send: (
      <Checkbox
        value={all || (skipIds?.length && !_.includes(skipIds, id)) || _.includes(dailyDiaries, id)}
        centered
        onChange={(e) => onSelectItemClick(e, id)}
      />
    ),
    sent: approved && (
      <Icon
        color={getBrandingColor('tertiary-color')}
        icon="check"
      />
    ),
  }))
}
