import _ from 'lodash'
import moment from 'moment'

export const getInitialValues = (params) => {
  const initialValues = {}
  const { child, didNotSleep, endTime, forExistingChild, record, startTime } = params || {}
  const { comments } = record || {}

  if (child) {
    const { firstName, id, photo, surname } = child

    initialValues.child = {
      avatar: photo,
      label: `${firstName} ${surname}`,
      value: id,
    }
  } else if (forExistingChild) {
    const { firstName, id, photo, surname } = forExistingChild

    initialValues.child = {
      avatar: photo,
      label: `${firstName} ${surname}`,
      value: id,
    }
  }

  if (comments) {
    initialValues.comments = comments
  }

  if (didNotSleep) {
    initialValues.didNotSleep = didNotSleep

    if (_.isInteger(startTime)) {
      initialValues.time = moment(startTime, 'x').utc()
    } else if (startTime) {
      initialValues.time = moment(startTime)
    }
  } else {
    if (_.isInteger(startTime)) {
      initialValues.startTime = moment(startTime, 'x').utc()
    } else if (startTime) {
      initialValues.startTime = moment(startTime)
    }

    if (_.isInteger(endTime)) {
      initialValues.endTime = moment(endTime, 'x').utc()
    }
  }

  return initialValues
}

export const getValuesForm = (values) => {
  const body = {
    comments: null,
    didNotSleep: null,
    endTime: null,
    startTime: null,
  }

  const { child, comments, didNotSleep, endTime, startTime, time } = values

  if (child) {
    body.child = child.value
  }

  body.comments = comments || ''

  if (didNotSleep) {
    body.didNotSleep = true

    if (time) {
      const preparedTime = +moment(0)
        .utc()
        .set('hour', moment(time).get('hour'))
        .set('minute', moment(time).get('minute'))
        .format('x')

      body.startTime = preparedTime
      body.endTime = preparedTime
    }
  } else {
    body.didNotSleep = false

    if (startTime) {
      body.startTime = +moment(0)
        .utc()
        .set('hour', moment(startTime).get('hour'))
        .set('minute', moment(startTime).get('minute'))
        .format('x')
    }

    if (endTime) {
      body.endTime = +moment(0)
        .utc()
        .set('hour', moment(endTime).get('hour'))
        .set('minute', moment(endTime).get('minute'))
        .format('x')
    }
  }

  return body
}
