import React from 'react'

import { DEPOSIT_TYPES } from 'services/legacy/deposits/constants'
import { STATUS_TYPES } from 'services/legacy/invoices/constants'

import { generateRoute } from 'utils/routing'

import { DateString, Hyperlink, Typography } from 'components'

import i18n from 'translations'

const getStatusLabel = (status, type) => {
  if (type === DEPOSIT_TYPES.REFUND) {
    switch (status) {
      case STATUS_TYPES.CANCELLED:
        return i18n.t('module:Finance:Deposits:List:Table:Column:InvoiceStatus:creditNoted')

      default:
        return i18n.t('module:Finance:Deposits:List:Table:Column:InvoiceStatus:invoiced')
    }
  }

  switch (status) {
    case STATUS_TYPES.PAID:
      return i18n.t('module:Finance:Deposits:List:Table:Column:InvoiceStatus:paid')

    case STATUS_TYPES.CANCELLED:
      return i18n.t('module:Finance:Deposits:List:Table:Column:InvoiceStatus:creditNoted')

    default:
      return i18n.t('module:Finance:Deposits:List:Table:Column:InvoiceStatus:unpaid')
  }
}

const InvoiceStatus = ({
  childId,
  isDetailMode,
  lastInvoice,
  originalDeposit,
  type,
  used,
}) => {
  if (!used && !lastInvoice) {
    return type === DEPOSIT_TYPES.DEPOSIT
      ? i18n.t('module:Finance:Deposits:List:Table:Column:InvoiceStatus:markedAsPaid')
      : i18n.t('module:Finance:Deposits:List:Table:Column:InvoiceStatus:markedAsRefunded')
  }

  if (used && !lastInvoice) {
    return i18n.t('module:Finance:Deposits:List:Table:Column:InvoiceStatus:pendingInvoice')
  }

  if (lastInvoice) {
    const { id, issueDate, number, status } = lastInvoice
    const invoiceLink = generateRoute('CHILDREN.CHILD.FINANCE.SINGLE_INVOICE', { childId, invoiceId: id })

    if (status === STATUS_TYPES.DRAFT) {
      return (
        <Hyperlink to={invoiceLink} primary>
          {i18n.t('module:Finance:Deposits:List:Table:Column:InvoiceStatus:draftInvoice')}
        </Hyperlink>
      )
    }

    const renderDate = () => (
      <React.Fragment>
        {' - '}
        <DateString date={issueDate} />
        {' '}
      </React.Fragment>
    )

    return (
      <React.Fragment>
        {`${getStatusLabel(status, type)} `}
        {isDetailMode && renderDate()}
        <Hyperlink to={invoiceLink} inline primary>
          {`(${number})`}
        </Hyperlink>
        {type === DEPOSIT_TYPES.REFUND
          && originalDeposit?.lastInvoice?.status === STATUS_TYPES.CANCELLED
          && (
            <Typography fontSize={12} tertiary>
              {i18n.t('module:Finance:Deposits:List:Table:Column:InvoiceStatus:originalDepositCreditNoted')}
            </Typography>
          )}
      </React.Fragment>
    )
  }

  return (
    <div />
  )
}

export default InvoiceStatus
