import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

const getEnquiryReports = (state) => state.enquiryReports

export const getEnquiryReportsPerformanceSelector = createSelector(
  [getEnquiryReports],
  (state) => state?.performance,
)

export const getPerformanceCriteria = ({ breakdownMode, dateRange, nursery }) => {
  const criteria = []

  if (dateRange?.after && dateRange?.before) {
    criteria.push({
      field: 'dateFrom',
      value: moment(dateRange.after).startOf('month').format(DEFAULT_DATE_FORMAT),
    })

    criteria.push({
      field: 'dateTo',
      value: moment(dateRange.before).endOf('month').format(DEFAULT_DATE_FORMAT),
    })
  }

  if (nursery) {
    criteria.push({
      field: 'nursery',
      value: nursery.value,
    })
  }

  if (breakdownMode) {
    criteria.push({
      field: 'breakdownMode',
      value: breakdownMode,
    })
  }

  return criteria
}

export const getEnquiryReportsPerformanceDataSelector = createSelector(
  [getEnquiryReportsPerformanceSelector],
  (state) => state.data,
)
