import { noop } from 'constants/global'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_LIST_CHILDREN,
  EXPORT_CHILDREN_REPORT,
  EXPORT_CHILDREN_REPORT_FAILED,
  EXPORT_CHILDREN_REPORT_SUCCESS,
  EXPORT_FUNDING_REPORT,
  EXPORT_FUNDING_REPORT_FAILED,
  EXPORT_FUNDING_REPORT_SUCCESS,
  LIST_CHILDREN,
  LIST_CHILDREN_FAILED,
  LIST_CHILDREN_SUCCESS,
  SEND_FORECAST_REPORT,
  SEND_FORECAST_REPORT_FAILED,
  SEND_FORECAST_REPORT_SUCCESS,
  SEND_FORMATIVE_REPORT,
  SEND_FORMATIVE_REPORT_FAILED,
  SEND_FORMATIVE_REPORT_SUCCESS,
  SEND_REPORT,
  SEND_REPORT_FAILED,
  SEND_REPORT_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ childApiClient }) => generateBasicActions.list({
  constants: {
    failed: LIST_CHILDREN_FAILED,
    init: LIST_CHILDREN,
    success: LIST_CHILDREN_SUCCESS,
  },
  options,
  service: childApiClient,
  serviceMethod: 'list',
})

export const clear = () => ({
  type: CLEAR_LIST_CHILDREN,
})

export const sendReport = (params, onSuccess, onFailed) => ({ childApiClient }) => (dispatch) => {
  dispatch({ type: SEND_REPORT })

  childApiClient.sendReport(params).then(
    () => {
      dispatch({ type: SEND_REPORT_SUCCESS })

      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => {
      dispatch({ type: SEND_REPORT_FAILED })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const exportChildrenReport = (options = {}) => ({ childApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params } = options

  dispatch({ type: EXPORT_CHILDREN_REPORT })

  childApiClient.exportChildrenReport(params).then(
    () => {
      dispatch({ type: EXPORT_CHILDREN_REPORT_SUCCESS })

      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => {
      dispatch({ type: EXPORT_CHILDREN_REPORT_FAILED })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const exportFundingReport = (options = {}) => ({ childApiClient }) => generateBasicActions.get({
  constants: {
    failed: EXPORT_FUNDING_REPORT_FAILED,
    init: EXPORT_FUNDING_REPORT,
    success: EXPORT_FUNDING_REPORT_SUCCESS,
  },
  options,
  service: childApiClient,
  serviceMethod: 'exportFundingLoopReport',
})

export const sendFormativeReport = (childId, params, onSuccess, onFailed) => ({ childApiClient }) => (dispatch) => {
  dispatch({ type: SEND_FORMATIVE_REPORT })

  return childApiClient.sendFormativeReport(childId, params).then(
    () => {
      dispatch({ type: SEND_FORMATIVE_REPORT_SUCCESS })

      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => {
      dispatch({ type: SEND_FORMATIVE_REPORT_FAILED })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const sendForecastReport = (params, onSuccess = noop, onFailed = noop) => ({
  childApiClient,
}) => (dispatch) => {
  dispatch({ type: SEND_FORECAST_REPORT })

  return childApiClient.sendForecastReport(params).then(
    () => {
      dispatch({ type: SEND_FORECAST_REPORT_SUCCESS })

      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => {
      dispatch({ type: SEND_FORECAST_REPORT_FAILED })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}
