import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledInfoBlockContainer = styled.div`
  margin-top: 10px;
  font-weight: 400;

  > div > div {
    font-size: 14px;
  }
`
