import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as nextMontessoriActivitiesSelectors from './selectors'

const withNextMontessoriActivitiesService = (WrappedComponent) => {
  const mapState = ({ nextMontessoriActivities }) => ({ nextMontessoriActivities })

  const mapDispatch = injectActions('nextMontessoriActivitiesActions', actions)

  const Component = ({ nextMontessoriActivities, nextMontessoriActivitiesActions, ...others }) => (
    <WrappedComponent
      nextMontessoriActivitiesActions={nextMontessoriActivitiesActions}
      nextMontessoriActivitiesSelectors={nextMontessoriActivitiesSelectors}
      nextMontessoriActivitiesSingleState={nextMontessoriActivities.single}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withNextMontessoriActivitiesService
