import { createSelector } from 'reselect'

const getFinanceReports = (state) => state.financeBadDebtReport

export const getFinanceBadDebtReportNurseries = createSelector(
  [getFinanceReports],
  (state) => state.nurseries,
)

export const getFinanceBadDebtReportNurseriesData = createSelector(
  [getFinanceBadDebtReportNurseries],
  (state) => state.data,
)
