import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledContainer = styled.div`
  margin-bottom: 20px;
`

export const StyledCollectingContainer = styled.div`
  display: flex;
  align-items: baseline;
`

export const StyledLink = styled.a`
  color: ${getBrandingColor('primary-color')};
  padding-left: 10px;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: ${getBrandingColor('primary-color')};
  }
`

export const StyledSignatureTotalContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
`
