import React from 'react'

import { Avatar, DateString, Typography } from 'components'

import i18n from 'translations'

const ChildDetail = ({ child, isLoading }) => {
  if (isLoading || !child) {
    return null
  }

  const { ageMonths, birthDate, firstName, photo, surname } = child

  return (
    <div>
      <Avatar
        initials={[firstName, surname]}
        src={photo}
        subTitle={(
          <React.Fragment>
            <DateString date={birthDate} />
            {` (${i18n.t('global:ageMonths', { ageMonths })})`}
          </React.Fragment>
        )}
        title={(
          <Typography fontSize={22} bold>
            {`${firstName} ${surname}`}
          </Typography>
        )}
      />
    </div>
  )
}

export default ChildDetail
