import moment from 'moment'

import React, { useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'
import { getFormValues, stopSubmit } from 'redux-form'

import { withAppService, withAppServiceProps } from 'services/app'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withChildService, withChildServiceProps } from 'services/legacy/child'

import i18n from 'translations'

import { ModalType } from 'modals'
import {
  FUNDING_LOOP_EXPORT_MODAL_FORM,
  FundingLoopExportModalFormValues,
} from './components/FundingLoopExportModalForm'

import FundingLoopExportModalView from './FundingLoopExportModalView'

export interface FundingLoopExportModalProps {
  onSendReport: () => void
}

export type FundingLoopExportModalContainerProps = withAppServiceProps
  & withModalServiceProps
  & withChildServiceProps

const mapState = (state) => ({
  formValues: getFormValues(FUNDING_LOOP_EXPORT_MODAL_FORM)(state),
})

const mapDispatch = {
  stopSubmitForm: (params) => stopSubmit(FUNDING_LOOP_EXPORT_MODAL_FORM, params),
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

const FundingLoopExportModalContainer: React.FC<FundingLoopExportModalContainerProps & PropsFromRedux> = ({
  childActions,
  childSelectors,
  modalActions,
  stopSubmitForm,
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const initialValues: FundingLoopExportModalFormValues = {
    dateRangeWeek: [
      moment().startOf('week'),
      moment().endOf('week'),
    ],
  }

  const handleSendReportFailed = ({ message }) => {
    setIsSubmitting(false)

    stopSubmitForm({ user: message })
  }

  const handleSendReportSuccess = (email) => {
    setIsSubmitting(false)
    modalActions.hide()

    modalActions.show<ModalType.ALERT>(ModalType.ALERT, {
      text: i18n.t('module:Modals:ExportReportModal:alert', { email }),
    })
  }

  const handleSubmit = (values: FundingLoopExportModalFormValues) => {
    setIsSubmitting(true)

    const criteria = childSelectors.getExportFundingLoopReportCriteria(values)

    const params = { criteria }

    childActions.exportFundingReport({
      onFailed: handleSendReportFailed,
      onSuccess: () => handleSendReportSuccess(values?.sendTo?.email),
      params,
    })
  }

  const handleCancelClick = () => {
    modalActions.hide()
  }

  return (
    <FundingLoopExportModalView
      initialValues={initialValues}
      isSubmitting={isSubmitting}
      onCancelClick={handleCancelClick}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withAppService,
  withModalService,
  withChildService,
  connector,
)

export default enhance(FundingLoopExportModalContainer)
