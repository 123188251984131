import _ from 'lodash'

import { TableColumn } from 'components/Table/Table'

import i18n from 'translations'

export const TABLE_COLUMNS: TableColumn[] = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'child',
    title: _.upperFirst(i18n.t('global:child')),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'type',
    title: _.upperFirst(i18n.t('global:Type')),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'reference',
    title: _.upperFirst(i18n.t('global:Reference')),
  },
  {
    align: 'right',
    alignTh: 'right',
    field: 'gross',
    title: _.upperFirst(i18n.t('global:Finance:Gross')),
  },
  {
    align: 'right',
    alignTh: 'right',
    field: 'fee',
    title: _.upperFirst(i18n.t('global:Finance:Fee')),
  },
  {
    align: 'right',
    alignTh: 'right',
    field: 'total',
    title: _.upperFirst(i18n.t('global:Finance:Total')),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'paymentDate',
    title: _.upperFirst(i18n.t('global:Date')),
  },
]
