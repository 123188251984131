import React from 'react'

import colors from 'constants/colors'

import { Typography } from 'components'

const PlanViewSubTitle = ({ subtitle }) => (
  <Typography color={colors.gray}>
    {subtitle}
  </Typography>
)

export default PlanViewSubTitle
