import styled from 'styled-components'

import layout from 'constants/layout'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledGroupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  @media(max-width: ${layout.mobileWidthResolution}px) {
    flex-direction: row;
  }
`

interface StyledAvatarContainerProps {
  $avatarSize?: number
  $length?: number
  $maxLengthOfAvatars?: number
}

export const StyledAvatarContainer = styled.div<StyledAvatarContainerProps>`
  display: flex;
  justify-content: center;
  position: relative;

  ${({ $avatarSize, $length = 1, $maxLengthOfAvatars }) => `
    width: ${((($maxLengthOfAvatars < $length ? $maxLengthOfAvatars : $length) + 1) * $avatarSize) / 2}px;
    height: ${$avatarSize}px;
    min-height: ${$avatarSize}px;
  `}
`

interface StyledAvatarProps {
  $avatarSize?: number
  $index?: number
}

export const StyledAvatar = styled.div<StyledAvatarProps>`
  position: absolute;
  top: 0;

  ${({ $avatarSize, $index }) => Number.isInteger($index) && `
    left: ${(($avatarSize / 2) * $index)}px;
  `};
`

interface StyledAvatarWithTextProps {
  $avatarSize?: number
  $backgroundColor?: string
  $borderColor?: string
  $index?: number
}

export const StyledAvatarWithText = styled.div<StyledAvatarWithTextProps>`
  ${({ $avatarSize }) => `
    width: ${$avatarSize}px;
    height: ${$avatarSize}px;
    min-width: ${$avatarSize}px;
    min-height: ${$avatarSize}px;
  `}
  
  position: absolute;
  top: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: ${NEUTRAL_COLOURS.WHITE};
  font-weight: 600;
  border: 4px solid ${({ $borderColor }) => $borderColor || NEUTRAL_COLOURS.SILVER};

  ${({ $avatarSize, $index }) => Number.isInteger($index) && `
    left: ${(($avatarSize / 2) * $index)}px;
  `};

  ${({ $backgroundColor }) => `
    color: ${NEUTRAL_COLOURS.WHITE};
    background-color: ${$backgroundColor || getBrandingColor('tertiary-color')};
  `};
`

export const StyledContent = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  
  @media(max-width: ${layout.mobileWidthResolution}px) {
    margin: 0 0 0 10px;
  }
`

export const StyledContentTitle = styled.div`
  font-weight: 600;
  text-align: center;
`

export const StyledAgeMonths = styled.div`
  font-weight: 400;
  color: #929292;
`
