import { CLOSE_SNACKBAR, SHOW_SNACKBAR, UPDATE_SNACKBAR } from './constants'
import { DEFAULT_AUTO_HIDE_DURATION } from '../constants'

export const show = (params) => ({
  payload: {
    time: DEFAULT_AUTO_HIDE_DURATION,
    ...params,
  },
  type: SHOW_SNACKBAR,
})

export const close = () => ({
  type: CLOSE_SNACKBAR,
})

export const update = () => ({
  type: UPDATE_SNACKBAR,
})
