import {
  CLEAR_LIST_CHILD_MOVES,
  LIST_CHILD_MOVES,
  LIST_CHILD_MOVES_FAILED,
  LIST_CHILD_MOVES_SETUP,
  LIST_CHILD_MOVES_SETUP_FAILED,
  LIST_CHILD_MOVES_SETUP_SUCCESS,
  LIST_CHILD_MOVES_SUCCESS,
} from './constants'

export const list = (params, onSuccess = () => {}) => ({ childMovesApiClient }) => (dispatch) => {
  dispatch({
    type: LIST_CHILD_MOVES,
  })

  childMovesApiClient.list(params).then(
    (response) => {
      dispatch({
        payload: response,
        type: LIST_CHILD_MOVES_SUCCESS,
      })
      onSuccess(response)
    },
    ({ response }) => dispatch({
      error: response,
      type: LIST_CHILD_MOVES_FAILED,
    }),
  )
}

export const listSetup = (childId, onSuccess = () => {}) => ({ childMovesApiClient }) => (dispatch) => {
  dispatch({
    type: LIST_CHILD_MOVES_SETUP,
  })

  const criteria = [
    {
      field: 'child',
      value: childId,
    },
    {
      comparator: 'exists',
      field: 'confirmedAt',
      value: true,
    },
  ]

  childMovesApiClient.list({ criteria, limit: 0 }).then(
    (response) => {
      dispatch({
        payload: response,
        type: LIST_CHILD_MOVES_SETUP_SUCCESS,
      })
      onSuccess(response)
    },
    ({ response }) => dispatch({
      error: response,
      type: LIST_CHILD_MOVES_SETUP_FAILED,
    }),
  )
}

export const clear = () => ({
  type: CLEAR_LIST_CHILD_MOVES,
})
