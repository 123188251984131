import React, { Component } from 'react'
import { compose } from 'recompose'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withAgedDebtService } from 'services/legacy/agedDebt'

import TopOutstandingAccountsWidgetView from './TopOutstandingAccountsWidgetView'

const GROUPS = {
  read: [
    'child',
    'child.finance',
  ],
}

class TopOutstandingAccountsWidgetContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      childrenList: null,
      isLoading: true,
      totalOutstanding: 0,
    }
  }

  componentDidMount() {
    const { agedDebtActions, childActions, childSelectors } = this.props

    const params = {
      criteria: childSelectors.getCriteria({
        onlyOutstanding: true,
      }),
      groups: GROUPS,
      limit: 10,
      order: {
        sortField: 'balance',
        sortOrder: 'ASC',
      },
    }

    agedDebtActions.getStatistics({}, ({ data: { total } }) => {
      this.setState({
        totalOutstanding: total,
      })
    })

    childActions.list({
      mergeResult: false,
      onSuccess: ({ data }) => {
        this.setState({
          childrenList: data,
          isLoading: false,
        })
      },
      onlyData: true,
      params,
    })
  }

  componentWillUnmount() {
    const { childActions } = this.props

    childActions.clear()
  }

  render() {
    const { childrenList, isLoading, totalOutstanding } = this.state

    return (
      <TopOutstandingAccountsWidgetView
        childrenList={childrenList}
        isLoading={isLoading}
        totalOutstanding={totalOutstanding}
      />
    )
  }
}

const enhance = compose(
  withAppService,
  withChildService,
  withAgedDebtService,
)

export default enhance(TopOutstandingAccountsWidgetContainer)
