/**
 * Localstorage key for keeping device identity jwt token
 */
const KEY = 'DEVICE_IDENTITY'

/**
 * Localstorage key for keeping device identity number
 */
const KEY_REAL_ID = 'DEVICE_IDENTITY_REAL'

/**
 * Set current device (browser) identity by putting value in localstorage.
 */
export default class DeviceIdentityService {
  static getIdentity() {
    try {
      return JSON.parse(window.localStorage.getItem(KEY))
    } catch (err) {}

    return null
  }

  static setIdentity(identity) {
    if (identity) {
      window.localStorage.setItem(KEY, JSON.stringify(identity))
    } else {
      window.localStorage.removeItem(KEY)
    }
  }

  static getRealIdentity() {
    try {
      return JSON.parse(window.localStorage.getItem(KEY_REAL_ID))
    } catch (err) {}

    return null
  }

  static setRealIdentity(identity) {
    if (identity) {
      window.localStorage.setItem(KEY_REAL_ID, JSON.stringify(identity))
    } else {
      window.localStorage.removeItem(KEY_REAL_ID)
    }
  }
}
