import React from 'react'

import ChildDetail from './ChildDetail'
import ActivityDetail from './ActivityDetail'

import { StyledActivityDetail, StyledChildDetail, StyledPageWrapper } from './DetailStyled'

const DetailView = () => (
  <StyledPageWrapper>
    <StyledChildDetail>
      <ChildDetail />
    </StyledChildDetail>
    <StyledActivityDetail>
      <ActivityDetail />
    </StyledActivityDetail>
  </StyledPageWrapper>
)

export default DetailView
