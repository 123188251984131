import _ from 'lodash'
import moment from 'moment'
import { createSelector } from 'reselect'

import { toFloat, toInt } from 'utils/data'

import { getCriteriaSelector } from '../list/selectors'
import * as constants from '../constants'

export const getChildExtraItemsSelectors = (state) => state.childExtraItems

export const getChildExtraItemsListProjectionsDataSelectors = createSelector(
  [getChildExtraItemsSelectors],
  (state) => state.listProjections.data,
)

export const getProjectionCriteriaSelector = (filters) => {
  const { projectionEndDate, projectionStartDate, search } = filters

  const criteria = getCriteriaSelector(filters)

  if (projectionStartDate) {
    criteria.push({
      comparator: 'after',
      field: 'date',
      value: moment(projectionStartDate).format('YYYY-MM-DD'),
    })
  }

  if (projectionEndDate) {
    criteria.push({
      comparator: 'before',
      field: 'date',
      value: moment(projectionEndDate).format('YYYY-MM-DD'),
    })
  }

  if (search) {
    criteria.push({
      field: 'concatenable',
      value: search,
    })
  }

  return criteria
}

export const getProjectionsDropdownOption = (item) => {
  const { childExtraItem, date, id } = item
  const { extraItem, quantity, type: frequency } = childExtraItem || {}
  const { archived, name, unitPrice } = extraItem || {}
  const total = toInt(quantity || 1) * toFloat(unitPrice)
  const frequencyOption = _.find(constants.CHILD_EXTRA_ITEMS_TYPE_OPTIONS, { value: frequency })

  return {
    frequency: frequencyOption,
    label: `${name}${archived ? ' (archived)' : ''} - ${moment(date).format('DD/MM/YYYY')}`,
    quantity: quantity || 1,
    total,
    unitPrice,
    value: id,
  }
}

export const getProjectionsDropdownOptions = createSelector(
  [getChildExtraItemsListProjectionsDataSelectors],
  (listData) => {
    if (!listData) {
      return []
    }

    return _.map(listData, getProjectionsDropdownOption)
  },
)
