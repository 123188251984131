import _ from 'lodash'

import React from 'react'

import { Button, Currency, TimeString } from 'components'
import { NurserySession } from 'services/nurserySessions/models'

import i18n from 'translations'

interface TableDataProps {
  costTwoYears: JSX.Element
  costTwoYearsAbove: JSX.Element
  costUnderTwo: JSX.Element
  edit: JSX.Element
  endTime: JSX.Element
  name: string
  startTime: JSX.Element
}

export const getSessionsTableData = (sessions: NurserySession[], routePrefix: string): TableDataProps[] => {
  if (!sessions || !sessions.length) {
    return []
  }

  return _.map(sessions, (session) => {
    const {
      archived,
      costTwoYears,
      costTwoYearsAbove,
      costUnderTwo,
      endTime,
      id,
      isHourly,
      name,
      startTime,
    } = session

    return {
      costTwoYears: <Currency value={costTwoYears} />,
      costTwoYearsAbove: <Currency value={costTwoYearsAbove} />,
      costUnderTwo: <Currency value={costUnderTwo} />,
      edit: <Button.TableAction to={`${routePrefix}/sessions/${id}/edit`} edit />,
      endTime: isHourly ? i18n.t('global:Flexible') : <TimeString date={endTime} local={false} />,
      name: archived ? `${name} (Archived)` : name,
      startTime: isHourly ? i18n.t('global:Flexible') : <TimeString date={startTime} local={false} />,
    }
  })
}
