import i18n from 'translations'

export const ITEM_CATEGORIES = (isFinanceV3Enabled) => {
  const items = [
    {
      label: i18n.t(isFinanceV3Enabled
        ? 'module:Management:Finance:AccountCodes:ItemCategories:regularSessions'
        : 'module:Management:Finance:AccountCodes:ItemCategories:sessions',
      ),
      value: 'sessionCode',
    },
    {
      label: i18n.t('module:Management:Finance:AccountCodes:ItemCategories:extraSessions'),
      value: 'extraSessionCode',
    },
    {
      label: i18n.t('module:Management:Finance:AccountCodes:ItemCategories:consumables'),
      value: 'consumableCode',
    },
    {
      label: i18n.t(isFinanceV3Enabled
        ? 'module:Management:Finance:AccountCodes:ItemCategories:items'
        : 'module:Management:Finance:AccountCodes:ItemCategories:extraItems',
      ),
      value: 'itemCode',
    },
    {
      label: i18n.t('module:Management:Finance:AccountCodes:ItemCategories:discounts'),
      value: 'discountCode',
    },
    {
      label: i18n.t('module:Management:Finance:AccountCodes:ItemCategories:other'),
      value: 'otherCode',
    },
    {
      label: i18n.t('module:Management:Finance:AccountCodes:ItemCategories:fundingDeducted'),
      value: 'fundingCode',
    },
    {
      label: i18n.t('module:Management:Finance:AccountCodes:ItemCategories:deposits'),
      value: 'depositCode',
    },
  ]

  if (isFinanceV3Enabled) {
    items.splice(4, 0, {
      label: i18n.t('module:Management:Finance:AccountCodes:ItemCategories:packages'),
      value: 'packageCode',
    })
  }

  return items
}
