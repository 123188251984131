export const CREATE_FOOD_TYPE = 'CREATE_FOOD_TYPE'
export const CREATE_FOOD_TYPE_SUCCESS = 'CREATE_FOOD_TYPE_SUCCESS'
export const CREATE_FOOD_TYPE_FAILED = 'CREATE_FOOD_TYPE_FAILED'

export const GET_FOOD_TYPE = 'GET_FOOD_TYPE'
export const GET_FOOD_TYPE_FAILED = 'GET_FOOD_TYPE_FAILED'
export const GET_FOOD_TYPE_SUCCESS = 'GET_FOOD_TYPE_SUCCESS'

export const UPDATE_FOOD_TYPE = 'UPDATE_FOOD_TYPE'
export const UPDATE_FOOD_TYPE_FAILED = 'UPDATE_FOOD_TYPE_FAILED'
export const UPDATE_FOOD_TYPE_SUCCESS = 'UPDATE_FOOD_TYPE_SUCCESS'

export const CLEAR_FOOD_TYPE = 'CLEAR_FOOD_TYPE'
