/* eslint-disable max-len */
import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledDayContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  padding: 2px;
  box-sizing: border-box;
  position: relative;
  z-index: 3;
`

export const StyledCalendarContainer = styled.div`
  &&& {
    .DayPicker-Caption {
      text-align: center;
    }

    .DayPicker-Months {
      justify-content: flex-start;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      color: inherit;
    }

    .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day {
      padding: 0;
      border: none;
      position: relative;
      border-radius: 0;
    }

    .DayPicker:not(.DayPicker--interactionDisabled)
      .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--weekend),
    .DayPicker:not(.DayPicker--interactionDisabled)
      .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--weekend):hover {
      background: transparent;
    }

    .DayPicker-Day--disabled:not(.DayPicker-Day--outside) ${StyledDayContainer} {
      background: ${NEUTRAL_COLOURS.GRAY_TERTIARY};
      color: ${NEUTRAL_COLOURS.GRAY};
      border-radius: 0;
      cursor: pointer;
    }

    .DayPicker-Day--disabled:not(.DayPicker-Day--outside) {
      background: ${NEUTRAL_COLOURS.GRAY_TERTIARY};
    }
    
    .DayPicker:not(.DayPicker--interactionDisabled)
      .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):not(.DayPicker-Day--from):not(.DayPicker-Day--to):hover:after,
    .DayPicker-Day.DayPicker-Day--unSelected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--alreadyInRange):hover:after {
      content: '';
      inset: 0;
      position: absolute;
      background-color: ${getBrandingColor('primary-color-rgba-2')} !important;
      z-index: 1;
      border-radius: 50%;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--outside):not(.DayPicker-Day--unSelected)
      ${StyledDayContainer} {
      position: relative;
      background-color: ${getBrandingColor('primary-color-lighten-20')};
      color: ${NEUTRAL_COLOURS.WHITE};

      .webfont {
        color: ${NEUTRAL_COLOURS.WHITE} !important; 
      }

      svg {
        fill: ${NEUTRAL_COLOURS.WHITE};
      }
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--outside).DayPicker-Day--firstDayFromRange
      ${StyledDayContainer} {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
      color: ${NEUTRAL_COLOURS.WHITE};

      svg {
        fill: ${NEUTRAL_COLOURS.WHITE};
      }
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--outside).DayPicker-Day--lastDayFromRange
      ${StyledDayContainer} {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
      color: ${NEUTRAL_COLOURS.WHITE};

      .webfont {
        color: ${NEUTRAL_COLOURS.WHITE} !important; 
      }

      svg {
        fill: ${NEUTRAL_COLOURS.WHITE};
      }
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--outside).DayPicker-Day--alreadyInRange
      ${StyledDayContainer} {
      background-color: ${getBrandingColor('primary-color-lighten-10')};
      color: ${NEUTRAL_COLOURS.WHITE};

      .webfont {
        color: ${NEUTRAL_COLOURS.WHITE} !important; 
      }

      svg {
        fill: ${NEUTRAL_COLOURS.WHITE};
      }
    }

    .DayPicker:not(.DayPicker--interactionDisabled)
      .DayPicker-Day--unSelected.DayPicker-Day--alreadyInRange
      ${StyledDayContainer} {
      background-color: ${getBrandingColor('primary-color')} !important;
      color: ${NEUTRAL_COLOURS.WHITE};

      .webfont {
        color: ${NEUTRAL_COLOURS.WHITE} !important; 
      }

      svg {
        fill: ${NEUTRAL_COLOURS.WHITE};
      }
    }

    .DayPicker-Day--from:not(.DayPicker-Day--outside) ${StyledDayContainer} {
      border-radius: 50%;
      background-color: ${getBrandingColor('primary-color-lighten-10')} !important;
      position: relative;
      color: ${NEUTRAL_COLOURS.WHITE};

      .webfont {
        color: ${NEUTRAL_COLOURS.WHITE} !important; 
      }

      svg {
        fill: ${NEUTRAL_COLOURS.WHITE};
      }
    }

    .DayPicker-Day--from:not(.DayPicker-Day--outside):before {
      position: absolute;
      background-color: ${getBrandingColor('primary-color-lighten-20')};
      left: 50%;
      top: 0;
      width: 50%;
      content: '';
      height: 100%;
      z-index: 0;
    }

    .DayPicker-Day--to:not(.DayPicker-Day--outside) ${StyledDayContainer} {
      border-radius: 50%;
      background-color: ${getBrandingColor('primary-color-lighten-10')} !important;
      position: relative;
      color: ${NEUTRAL_COLOURS.WHITE};

      .webfont {
        color: ${NEUTRAL_COLOURS.WHITE} !important; 
      }

      svg {
        fill: ${NEUTRAL_COLOURS.WHITE};
      }
    }

    .DayPicker-Day--to:not(.DayPicker-Day--outside):before {
      position: absolute;
      background-color: ${getBrandingColor('primary-color-lighten-20')};
      left: 0;
      top: 0;
      width: 50%;
      content: '';
      height: 100%;
      z-index: 0;
    }

    .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day--alreadyInRange:hover
      ${StyledDayContainer} {
      background-color: ${getBrandingColor('primary-color')};
    }
  }
`
