import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change, stopSubmit } from 'redux-form'

import { DISCOUNT_OPTIONS, FUNDING_FEES_OPTIONS, FUNDING_FEES_TYPES } from 'services/nurseryFunding/constants'

import { toFloat } from 'utils/data'
import { hoursAndMinutesToFloat } from 'utils/date'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withFundingTypesService } from 'services/legacy/fundingTypes'
import { withNurseryFundingService } from 'services/nurseryFunding'
import { withRouter } from 'services/router'

import ManagementFundingAddV2View from './ManagementFundingAddV2View'
import { FORM_NAME } from './components/AddFundingForm'

const NURSERY_FUNDING_GROUPS = {
  read: [
    'nurseryFunding.settings',
    'nurseryFundingSettings',
    'nurseryFunding.nursery',
    'nurseryFunding.fundingType',
  ],
}

class ManagementFundingAddV2Container extends Component {
  componentDidMount() {
    const { fundingTypesActions, isEdit, nurseryFundingActions, params } = this.props
    const { nurseryFundingId } = params

    fundingTypesActions.list()

    if (isEdit) {
      nurseryFundingActions.get({
        params: [nurseryFundingId, { groups: NURSERY_FUNDING_GROUPS }],
      })
    }
  }

  componentWillUnmount() {
    const { fundingTypesActions, nurseryFundingActions } = this.props

    fundingTypesActions.clear()
    nurseryFundingActions.clear()
  }

  componentDidUpdate(prevProps) {
    const { calculatedTotalHours, hourlyRate, isDirty, totalHours, updateField } = this.props
    const { calculatedTotalHours: prevCalculatedTotal, totalHours: prevHours } = prevProps
    const { hours: prevTotalHours, minutes: prevTotalMinutes } = prevHours || {}
    const { hours: prevCalcHours, minutes: prevCalcMinutes } = prevCalculatedTotal || {}

    // NOTE: isDirty is to update total only when user change cost or quantity
    // should not recalculate total on edit mode initialization
    if (
      isDirty
      && (hourlyRate && totalHours)
      && (
        hourlyRate !== prevProps.hourlyRate
        || totalHours.hours !== prevTotalHours
        || totalHours.minutes !== prevTotalMinutes
      )
    ) {
      this.updateTotal(hourlyRate, totalHours)
    }

    if (
      isDirty
      && calculatedTotalHours
      && (
        calculatedTotalHours.hours !== prevCalcHours
        || calculatedTotalHours.minutes !== prevCalcMinutes
      )
    ) {
      updateField('totalHours.hours', calculatedTotalHours.hours)
      updateField('totalHours.minutes', calculatedTotalHours.minutes)

      this.updateTotal(hourlyRate, calculatedTotalHours)
    }
  }

  updateTotal = (hourlyRate, totalHours) => {
    const { updateField } = this.props
    const { hours, minutes = 0 } = totalHours

    const parsedHourlyRate = toFloat(hourlyRate) || 0
    const floatHours = hoursAndMinutesToFloat(hours, minutes)
    const total = toFloat(parsedHourlyRate * floatHours)

    updateField('total', total)
  }

  handleFail = (error) => {
    const { appSelectors, nurseryFundingHelpers, stopSubmitForm } = this.props

    const fieldValidations = nurseryFundingHelpers.updateNurseryFundingDateFieldError(
      appSelectors.getFieldValidationFromError(error, { doNotSplitKeys: true }),
    )

    stopSubmitForm(fieldValidations)
  }

  redirect = () => {
    const { isEdit, navigate, params } = this.props
    const { nurseryFundingId } = params

    return navigate(
      isEdit
        ? `/management/finance-settings/funding/${nurseryFundingId}/view`
        : '/management/finance-settings/funding',
    )
  }

  handleSuccess = () => {
    const { onModalCreateSuccess } = this.props

    if (onModalCreateSuccess) {
      return onModalCreateSuccess()
    }

    return this.redirect()
  }

  handleSuccessArchived = () => {
    const { navigate } = this.props

    navigate('/management/finance-settings/funding')
  }

  handleSubmit = (fields) => {
    const {
      isEdit,
      modalActions,
      modalConsts,
      nurseryFundingActions,
      nurseryFundingHelpers,
      nurseryOptions,
      params,
    } = this.props
    const { nurseryFundingId } = params
    const payload = nurseryFundingHelpers.getPayload(fields, nurseryOptions.id)

    if (isEdit) {
      const { settings } = fields
      const { feesAllocation } = settings

      if (feesAllocation === FUNDING_FEES_TYPES.MANUAL_HOURS) {
        modalActions.show(modalConsts.TYPES.CONFIRM, {
          icon: 'warning',
          onConfirm: () => {
            nurseryFundingActions.update({
              body: payload,
              onFailed: this.handleFail,
              onSuccess: this.handleSuccess,
              params: [nurseryFundingId, {
                groups: NURSERY_FUNDING_GROUPS,
              }],
            })
          },
          text: `
          Are you sure you want to change the funding allocation parameters?
          Please note: your uninvoiced funding may be unallocated. You may want to reallocate your funding.
        `,
        })

        return
      }

      nurseryFundingActions.update({
        body: payload,
        onFailed: this.handleFail,
        onSuccess: this.handleSuccess,
        params: [nurseryFundingId, {
          groups: NURSERY_FUNDING_GROUPS,
        }],
      })

      return
    }

    nurseryFundingActions.create({
      body: payload,
      onFailed: this.handleFail,
      onSuccess: this.handleSuccess,
      params: [{
        groups: NURSERY_FUNDING_GROUPS,
      }],
    })
  }

  archiveFunding = (archived) => () => {
    const { nurseryFundingActions, nurseryFundingSingleState, params } = this.props
    const { nurseryFundingId } = params
    const { data: { type } } = nurseryFundingSingleState

    return nurseryFundingActions.update({
      body: { archived, type },
      onSuccess: this.handleSuccessArchived,
      params: [nurseryFundingId, {
        groups: NURSERY_FUNDING_GROUPS,
      }],
    })
  }

  handleArchiveClick = (archived) => () => {
    const { modalActions, modalConsts } = this.props

    const label = archived ? 'archive' : 'unarchive'

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: label,
      icon: label,
      onConfirm: this.archiveFunding(archived),
      text: (
        <div>
          {`Are you sure you want to ${label} this funding type?`}
          <br />
          You can unarchive it at any time.
        </div>
      ),
    })
  }

  handleCancelClick = () => {
    const { onModalCancelClick } = this.props

    if (onModalCancelClick) {
      return onModalCancelClick()
    }

    return this.redirect()
  }

  handlePreviewClick = (type) => {
    const {
      fundingLineItemOptions,
      fundingNamePreview,
      isDeductedFromInvoice,
      isSubstractFundingSelected,
      modalActions,
    } = this.props

    modalActions.show(type, {
      fundingLineItemOptions,
      fundingNamePreview,
      isDeductedFromInvoice,
      isSubstractFundingSelected,
    })
  }

  render() {
    const {
      errorMessages,
      fundingCalculationOptions,
      fundingDeductionOptions,
      fundingLineItemOptions,
      fundingTypesOptions,
      initialValues,
      isActualSelected,
      isArchived,
      isCheapestSessionSelected,
      isDeductedFromInvoice,
      isDeficitChanged,
      isEdit,
      isFetching,
      isInsideModal,
      isOtherFundingTypeSelected,
      isSubstractFundingSelected,
      nurseryFundingSingleState,
    } = this.props
    const isLoading = isFetching
    const isFormLoading = nurseryFundingSingleState.isSubmitting

    return (
      <ManagementFundingAddV2View
        discountOptions={DISCOUNT_OPTIONS}
        errorMessages={errorMessages}
        fundingCalculationOptions={fundingCalculationOptions}
        fundingDeductionOptions={fundingDeductionOptions}
        fundingFeesOptions={FUNDING_FEES_OPTIONS}
        fundingLineItemOptions={fundingLineItemOptions}
        fundingTypeOptions={fundingTypesOptions}
        initialValues={initialValues}
        isActualSelected={isActualSelected}
        isArchived={isArchived}
        isCheapestSessionSelected={isCheapestSessionSelected}
        isDeductedFromInvoice={isDeductedFromInvoice}
        isDeficitChanged={isDeficitChanged}
        isEdit={isEdit}
        isFormLoading={isFormLoading}
        isInsideModal={isInsideModal}
        isLoading={isLoading}
        isOtherFundingTypeSelected={isOtherFundingTypeSelected}
        isSubstractFundingSelected={isSubstractFundingSelected}
        onArchiveClick={this.handleArchiveClick}
        onCancelClick={this.handleCancelClick}
        onPreviewClick={this.handlePreviewClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  fundingTypesSelectors,
  fundingTypesState,
  nurseryFundingSelectors,
  nurseryFundingSingleState,
  params,
}) => ({
  calculatedTotalHours: nurseryFundingSelectors.getCalculatedTotalHours(FORM_NAME)(state),
  errorMessages: appSelectors.getErrorMessages(fundingTypesState, nurseryFundingSingleState),
  fundingCalculationOptions: nurseryFundingSelectors.getFundingCalculationOptions(FORM_NAME)(state),
  fundingDeductionOptions: nurseryFundingSelectors.getFundingDeductionOptions(FORM_NAME)(state),
  fundingLineItemOptions: nurseryFundingSelectors.getFundingLineItemOptions(FORM_NAME)(state),
  fundingNamePreview: nurseryFundingSelectors.getFundingNamePreview(FORM_NAME)(state),
  fundingTypesOptions: fundingTypesSelectors.getFundingTypesOptionsSelectors(state),
  hourlyRate: nurseryFundingSelectors.getHourlyRate(FORM_NAME)(state),
  initialValues: nurseryFundingSelectors.getInitialValuesSelector(!!params.nurseryFundingId)(state),
  isActualSelected: nurseryFundingSelectors.isActualSelectedSelector(FORM_NAME)(state),
  isArchived: nurseryFundingSelectors.isArchivedSelector(state),
  isCheapestSessionSelected: nurseryFundingSelectors.isCheapestSessionSelectedSelector(FORM_NAME)(state),
  isDeductedFromInvoice: nurseryFundingSelectors.isDeductedFromInvoiceSelector(FORM_NAME)(state),
  isDeficitChanged: nurseryFundingSelectors.isDeficitChangedSelector(FORM_NAME)(state),
  isDirty: nurseryFundingSelectors.isDirtySelector(FORM_NAME)(state),
  isEdit: !!params.nurseryFundingId,
  isFetching: appSelectors.getIsFetching(fundingTypesState, nurseryFundingSingleState),
  isOtherFundingTypeSelected: nurseryFundingSelectors.isOtherFundingTypeSelectedSelector(FORM_NAME)(state),
  isSubstractFundingSelected: nurseryFundingSelectors.isSubstractFundingSelectedSelector(FORM_NAME)(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  totalHours: nurseryFundingSelectors.getTotalHours(FORM_NAME)(state),
})

const mapDispatch = {
  stopSubmitForm: (params) => stopSubmit(FORM_NAME, params),
  updateField: (fieldName, fieldValue) => change(FORM_NAME, fieldName, fieldValue),
}

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withFundingTypesService,
  withNurseryFundingService,
  connect(mapState, mapDispatch),
)

export default enhance(ManagementFundingAddV2Container)

