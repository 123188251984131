import {
  ADD_SLEEP_TO_LIST,
  CLEAR_DAILY_DIARY_SLEEPS,
  GET_DAILY_DIARY_SLEEPS,
  GET_DAILY_DIARY_SLEEPS_FAILED,
  GET_DAILY_DIARY_SLEEPS_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ dailyDiarySleepsApiClient }) => (dispatch) => {
  const { mergeResult, params, silent } = options

  dispatch({
    silent,
    type: GET_DAILY_DIARY_SLEEPS,
  })

  dailyDiarySleepsApiClient.list(params).then(
    (response) => {
      dispatch({
        mergeResult,
        payload: response,
        type: GET_DAILY_DIARY_SLEEPS_SUCCESS,
      })
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: GET_DAILY_DIARY_SLEEPS_FAILED,
      })
    },
  )
}

export const addToList = (payload) => ({
  payload,
  type: ADD_SLEEP_TO_LIST,
})

export const clearListWithoutRecords = () => ({
  type: CLEAR_DAILY_DIARY_SLEEPS,
})
