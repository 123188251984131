import _ from 'lodash'

import React from 'react'
import { FieldArray, Field as FormField } from 'redux-form'

import { FOOD_AMOUNT, FOOD_AMOUNT_DROPDOWN } from 'services/legacy/foodMenu/constants'

import { getBrandingColor } from 'utils/branding'
import { isRequired } from 'utils/fieldValidation'

import {
  Button,
  Callout,
  DefinitionList,
  DropdownMenu,
  Field,
  Form,
  GridLayout,
  TimeString,
  Toolbar,
  Typography,
} from 'components'

import i18n from 'translations'

import { StyledEditableComponent, StyledInlineEdit, StyledRemoveButton, StyledVisibleBody } from './FoodRecordStyled'

const renderRow = (
  field,
  i,
  fields,
  form,
  formValues,
  isProcessing,
  onGetFoodCategory,
) => {
  const name = `${field}.food`
  const rawValue = formValues(form).items[i]?.food

  return (
    <StyledEditableComponent>
      <GridLayout.Group key={i}>
        <GridLayout.Item
          alignItems="flex-start"
          flex={5}
          padding="0"
        >
          <Form.Row
            label={!i && (
              <Typography color={getBrandingColor('primary-color')} bold>
                {i18n.t('module:DailyDiary:global:food')}
              </Typography>
            )}
            margin="0 0 10px 0"
            verticalLabel
          >
            <FormField
              component={Form.InfiniteDropdowns.MenuItems}
              disabled={isProcessing}
              fieldName={name}
              formName={form}
              menuItemCategory={onGetFoodCategory().value}
              name={name}
              rawValue={rawValue}
              validate={isRequired}
              creatable
              fullWidth
            />
          </Form.Row>
        </GridLayout.Item>
        <GridLayout.Item
          alignItems="flex-start"
          flex={3}
          padding="0 10px"
        >
          <Form.Row
            label={!i && (
              <Typography color={getBrandingColor('primary-color')} bold>
                {_.upperFirst(i18n.t('global:amount'))}
              </Typography>
            )}
            margin="0 0 10px 0"
            verticalLabel
          >
            <FormField
              component={Form.Select}
              disabled={isProcessing}
              name={`${field}.amount`}
              options={FOOD_AMOUNT_DROPDOWN}
              validate={isRequired}
              fullWidth
            />
          </Form.Row>
        </GridLayout.Item>
        <GridLayout.Item
          alignItems="flex-start"
          flex={1}
          padding="0"
        >
          <StyledRemoveButton
            withoutLabel={!!i}
          >
            <Form.RemoveRowButton
              disabled={isProcessing}
              trash
              onClick={() => fields.remove(i)}
            />
          </StyledRemoveButton>
        </GridLayout.Item>
      </GridLayout.Group>
    </StyledEditableComponent>
  )
}

const renderFoodRows = ({
  fields,
  form,
  formErrors,
  formValues,
  isProcessing,
  onGetFoodCategory,
}) => (
  <React.Fragment>
    <Callout content={!fields?.length && [formErrors?.items]} error />
    <GridLayout>
      {fields.map((field, i) => renderRow(
        field,
        i,
        fields,
        form,
        formValues,
        isProcessing,
        onGetFoodCategory,
      ))}
    </GridLayout>
    <Button.ActionButton
      disabled={isProcessing}
      label={i18n.t('global:Add')}
      onClick={() => fields.push({
        amount: _.find(FOOD_AMOUNT_DROPDOWN, ({ value }) => FOOD_AMOUNT.ALL === value),
      })}
    />
  </React.Fragment>
)

const FoodRecordView = ({
  errorMessage,
  form,
  formErrors,
  formValues,
  isEditMode,
  isProcessing,
  item,
  onEditMode,
  onGetFoodCategory,
  onResetRecord,
  onSubmit,
}) => {
  const { diaryRecords: [diaryRecord] } = item
  const { items, record } = diaryRecord || {}
  const { comments, recordedAt } = record || {}

  const actions = (
    <Toolbar noMargin>
      <Toolbar.Group>
        {isEditMode && (
          <React.Fragment>
            <Toolbar.Item>
              <Button
                isLoading={isProcessing}
                label={i18n.t('global:Save')}
                size="small"
                negativeMargins
                onClick={onSubmit}
              />
            </Toolbar.Item>
            {item.diaryRecords?.length ? (
              <Toolbar.Item>
                <Button
                  disabled={isProcessing}
                  hierarchy="tertiary"
                  label={i18n.t('global:Cancel')}
                  size="small"
                  negativeMargins
                  onClick={() => onEditMode(false)}
                />
              </Toolbar.Item>
            ) : null}
            <Toolbar.Item>
              <DropdownMenu
                disabled={isProcessing}
                small
              >
                <DropdownMenu.Item
                  type="reset"
                  onClick={onResetRecord}
                />
              </DropdownMenu>
            </Toolbar.Item>
          </React.Fragment>
        )}
        {!isEditMode && (
          <Toolbar.Item>
            <Button
              hierarchy="tertiary"
              label={i18n.t('global:Edit')}
              size="small"
              negativeMargins
              onClick={() => onEditMode(true)}
            />
          </Toolbar.Item>
        )}
      </Toolbar.Group>
    </Toolbar>
  )

  const renderEditableComponent = (
    <GridLayout>
      <GridLayout.Group noVerticalMargin>
        <GridLayout.Item padding="0">
          <DefinitionList vertical>
            <DefinitionList.Item
              label={(
                <Typography bold primary>
                  {i18n.t('global:Time')}
                </Typography>
              )}
              margin="0 0 10px 0"
              value={(
                <FormField
                  component={Form.TimePicker}
                  disabled={isProcessing}
                  name="recordedAt"
                  validate={isRequired}
                />
              )}
            />
          </DefinitionList>
        </GridLayout.Item>
      </GridLayout.Group>
      <GridLayout.Group noVerticalMargin>
        <GridLayout.Item padding="20px 0" column>
          <FieldArray
            component={renderFoodRows}
            name="items"
            props={{
              form,
              formErrors,
              formValues,
              isProcessing,
              onGetFoodCategory,
            }}
          />
        </GridLayout.Item>
      </GridLayout.Group>
      <GridLayout.Group noVerticalMargin>
        <GridLayout.Item padding="10px 0 0">
          <DefinitionList fullWidth vertical>
            <DefinitionList.Item
              label={(
                <Typography bold primary>
                  {i18n.t('module:DailyDiary:global:additionalNotes')}
                </Typography>
              )}
              value={(
                <FormField
                  component={Form.TextAreaField}
                  disabled={isProcessing}
                  height={90}
                  maxCharacters={2000}
                  name="comments"
                  placeholder={i18n.t('module:DailyDiary:global:enterAdditionalNotesHere')}
                  autosize
                />
              )}
              whiteSpace="pre-line"
            />
          </DefinitionList>
        </GridLayout.Item>
      </GridLayout.Group>
    </GridLayout>
  )

  const renderVisibleComponent = (
    <StyledVisibleBody>
      <DefinitionList vertical>
        <DefinitionList.Item
          label={(
            <Typography bold primary>
              {i18n.t('global:Time')}
            </Typography>
          )}
          margin="0 0 20px 0"
          value={(
            <TimeString date={recordedAt} />
          )}
        />
        {_.map(items, ({ amount, food: { name } }) => (
          <DefinitionList.Item
            label={name}
            margin="0 0 5px 0"
            value={(
              <Typography bold primary>
                {_.get(_.find(FOOD_AMOUNT_DROPDOWN, ({ value }) => value === amount), 'label')}
              </Typography>
            )}
            whiteSpace="pre-wrap"
            wordBreak="break-word"
          />
        ))}
      </DefinitionList>
      <DefinitionList vertical>
        <DefinitionList.Item
          label={(
            <Typography bold primary>
              {i18n.t('module:DailyDiary:global:additionalNotes')}
            </Typography>
          )}
          value={comments || '-'}
          whiteSpace="pre-line"
          wordBreak="break-word"
        />
      </DefinitionList>
    </StyledVisibleBody>
  )

  return (
    <Form onSubmit={onSubmit}>
      <Callout content={errorMessage?.message} error />
      <StyledInlineEdit isEditMode={isEditMode}>
        <Field.InlineEdit
          actions={actions}
          description={renderVisibleComponent}
          editableComponent={renderEditableComponent}
          isEditMode={isEditMode}
          noPadding
        />
      </StyledInlineEdit>
    </Form>
  )
}

export default FoodRecordView
