import React from 'react'
import { Link } from 'react-router'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { Conversations, Icon, Typography } from 'components'

import i18n from 'translations'

import { StyledContentRow, StyledDetailsRow, StyledNewChannelContainer } from './ChannelStyled'
import ChannelLastMessage from './components/ChannelLastMessage'
import Archived from '../Archived'

const Channel = ({ authUserUuid, channel, isActive, isNewChannel, lastMessage, onRedirect }) => {
  if (isNewChannel) {
    return (
      <Conversations.ListItem
        avatarProps={{ placeholder: true }}
        content={(
          <StyledContentRow>
            <StyledNewChannelContainer>
              <Typography nowrap>
                {i18n.t('module:Messaging:Channel:newMessage')}
              </Typography>
              <Link
                to={channel
                  ? generateRoute('MESSAGING.CHANNEL', { channelId: channel.id })
                  : generateRoute('MESSAGING.INDEX')}
              >
                <Icon
                  color={NEUTRAL_COLOURS.GRAY}
                  height={14}
                  icon="close"
                />
              </Link>
            </StyledNewChannelContainer>
          </StyledContentRow>
        )}
      />
    )
  }

  return (
    <Conversations.ListItem
      avatarProps={{
        disableEffect: true,
        initials: channel?.name?.split(' '),
        src: channel?.photo,
      }}
      content={(
        <StyledContentRow>
          <Typography ellipsis nowrap>
            {channel.name}
          </Typography>
          <StyledDetailsRow isArchived={channel.archivedAt}>
            {channel.archivedAt && (<Archived />)}
            <ChannelLastMessage
              authUserUuid={authUserUuid}
              channel={channel}
              isArchived={!!channel.archivedAt}
              key={`lastMessage_${channel.id}`}
            />
          </StyledDetailsRow>
        </StyledContentRow>
)}
      isActive={isActive}
      isUnread={!!channel.unreadMessagesCount}
      lastConversationDate={lastMessage}
      to={generateRoute('MESSAGING.CHANNEL', { channelId: channel.id })}
      onClick={onRedirect}
    />
  )
}

export default React.memo(Channel)
