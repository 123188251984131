import ServiceBase from 'services/ServiceBase'

import { RESPONSE } from 'constants/http'

class DailyDiaryActivitiesApiClient extends ServiceBase {
  getRecord = (id) => this.request({
    path: `/activity-records/${id}`,
  })

  addRecord = (body) => this.request({
    body,
    method: 'POST',
    path: '/activity-records',
  })

  updateRecord = (id, body) => this.request({
    body,
    disableErrors: [RESPONSE.HTTP_404_NOT_FOUND],
    method: 'PATCH',
    path: `/activity-records/${id}`,
  })

  removeRecord = (id) => this.request({
    method: 'DELETE',
    path: `/activity-records/${id}`,
  })
}

export default DailyDiaryActivitiesApiClient
