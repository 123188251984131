import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { FEATURE_FLAGS } from 'constants/security'

import auth from 'utils/auth'

import SecurityView from './SecurityView'

const SecurityContainer = ({ PractitionerChildAccess }) => (
  <SecurityView
    PractitionerChildAccess={PractitionerChildAccess}
  />
)

const mapState = (state) => ({
  PractitionerChildAccess: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.PRACTITIONER_CHILD_ACCESS],
  }),
})

const enhance = compose(
  connect(mapState),
)

export default enhance(SecurityContainer)
