import React from 'react'

import { StyledScrollContent } from './ModalBoxStyled'

const ModalBoxScrollContent = ({ children }) => (
  <StyledScrollContent>
    {children}
  </StyledScrollContent>
)

export default ModalBoxScrollContent
