import _ from 'lodash'
import moment, { Moment } from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { Period, Type } from 'services/booking/periods/models'
import { Ranges } from 'components/MultiRangeCalendar/MultiRangeCalendar'

import { getDateString } from 'utils/date'

import { ActionType, PeriodTime, PeriodTimesRootState } from '../models'

const getPeriodTimesState = (state: PeriodTimesRootState) => state?.periodTimes

export const getPeriodTimesList = createSelector([getPeriodTimesState], (state) => state?.list)

export const getPeriodTimesListData = createSelector([getPeriodTimesList], (state) => state?.data)

interface GetCriteriaProp {
  endDate?: Moment
  periodId?: string
  startDate?: Moment
  type?: Type
}

export const getCriteria = ({ endDate, periodId, startDate, type }: GetCriteriaProp) => {
  const criteria = []

  if (periodId) {
    criteria.push({
      field: 'period',
      value: periodId,
    })
  }

  if (startDate) {
    criteria.push({
      comparator: 'after',
      field: 'startDate',
      value: startDate.format(DEFAULT_DATE_FORMAT),
    })
  }

  if (endDate) {
    criteria.push({
      comparator: 'before',
      field: 'endDate',
      value: endDate.format(DEFAULT_DATE_FORMAT),
    })
  }

  if (type) {
    criteria.push({
      field: 'period.type',
      value: type,
    })
  }

  return criteria
}

interface BatchBodyParamProps {
  period: Period
  periodTimes: PeriodTime[]
  selectedRanges: Ranges[]
}

export const getBatchBody = ({
  period,
  periodTimes,
  selectedRanges,
}: BatchBodyParamProps) => {
  const updateItemIds = []

  const createItems: PeriodTime[] = _.reduce(selectedRanges, (newItems, { from, to }) => {
    const existingPeriodTime = _.find(periodTimes, ({
      endDate,
      startDate,
    }) => getDateString(startDate) === getDateString(from) && getDateString(endDate) === getDateString(to))

    if (existingPeriodTime) {
      updateItemIds.push(existingPeriodTime.id)

      return newItems
    }

    return [
      ...newItems,
      {
        endDate: to,
        startDate: from,
      },
    ]
  }, [])

  const deleteItems = _.filter(periodTimes, ({ id: periodTimesId }) => !_.includes(updateItemIds, periodTimesId))

  return [
    ..._.map(createItems, ({ endDate, startDate }) => ({
      action: ActionType.create,
      periodTime: {
        endDate: moment(endDate).format(DEFAULT_DATE_FORMAT),
        period: { id: period.id },
        startDate: moment(startDate).format(DEFAULT_DATE_FORMAT),
      },
    })),
    ..._.map(deleteItems, ({ id }) => ({
      action: ActionType.delete,
      periodTime: { id },
    })),
  ]
}
