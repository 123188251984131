import _ from 'lodash'

import React, { useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'

import { Nullable, Option } from 'constants/models'
import { WrappedComponentType } from 'constants/types'
import { withRouterProps } from 'services/router/constants'
import { NURSERY_SESSIONS_FILTER, NURSERY_SESSIONS_FILTER_OPTIONS } from 'services/product/nurserySessionsV3/constants'
import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'

import { withAppService, withAppServiceProps } from 'services/app'
import { withNurserySessionsV3Service, withNurserySessionsV3ServiceProps } from 'services/product/nurserySessionsV3'
import { withPaginationUtils, withPaginationUtilsProps } from 'services/utils/pagination'
import { withRouteUtilsProps, withRouterUtils } from 'services/utils/router'
import { withSortUtilsProps, withSortingUtils } from 'services/utils/sorting'
import { withSecurityService, withSecurityServiceProps } from 'services/security'
import { withNurseriesService, withNurseriesServiceProps } from 'services/nurseries'
import { withShellService, withShellServiceProps } from 'services/shell'

import i18n from 'translations'

import { RootState } from 'core/reducers'
import { getSessionsTableData } from './SessionsV3Helpers'
import SessionsV3View from './SessionsV3View'

const SESSIONS_GROUPS = {
  read: [
    'nurserySession.nurseryConsumablesExtraItems',
    'nurserySession.organizationSession',
  ],
}

type SessionsV3ContainerProps = withNurserySessionsV3ServiceProps
  & withAppServiceProps
  & withRouteUtilsProps
  & withSortUtilsProps
  & withPaginationUtilsProps
  & withRouterProps
  & withSecurityServiceProps
  & withNurseriesServiceProps
  & withShellServiceProps

const mapState = (state: RootState, {
  appSelectors,
  nurserySessionsV3ListState,
  securitySelectors,
}: SessionsV3ContainerProps) => ({
  FinanceAutomationGranted: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.FINANCE_AUTOMATION],
  }),
  errorMessages: appSelectors.getErrorMessages(nurserySessionsV3ListState),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
  totalResults: appSelectors.getTotalResults(nurserySessionsV3ListState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const SessionsV3Container: WrappedComponentType<SessionsV3ContainerProps & PropsFromRedux> = ({
  FinanceAutomationGranted,
  errorMessages,
  isOrganizationContext,
  location,
  nurseriesActions,
  nurserySessionsV3Actions,
  nurserySessionsV3ListState,
  nurserySessionsV3Selectors,
  paginationUtils,
  params,
  setLocationQuery,
  shellActions,
  sortingUtils,
  totalResults,
}) => {
  const { getPaginationDetails, onPageChange, page } = paginationUtils
  const { onSortChange, sortField, sortOrder } = sortingUtils
  const { query } = location
  const { nurseryId } = params || {}

  const [statusFilter, setStatusFilter] = useState<Nullable<Option>>(() => _.find(NURSERY_SESSIONS_FILTER_OPTIONS, {
    value: query.statusFilter || NURSERY_SESSIONS_FILTER.ACTIVE,
  }))
  const [nursery, setNursery] = useState(null)
  const [initialized, setInitialized] = useState(false)

  const fetch = () => {
    const criteria = nurserySessionsV3Selectors.getCriteria({ statusFilter: statusFilter.value }, nurseryId)

    setLocationQuery({ statusFilter: statusFilter.value })
    nurserySessionsV3Actions.list({
      params: [{
        criteria,
        groups: SESSIONS_GROUPS,
        order: sortField ? {
          sortField,
          sortOrder,
        } : undefined,
        page,
      }, (isOrganizationContext && nursery) ? nursery?.subdomain : null],
    })
  }

  const handleGetNurserySuccess = ({ data }) => {
    setNursery(data)
    setInitialized(true)
    shellActions.setRouteTitle({
      name: 'SETTINGS.SESSIONS.NURSERY',
      title: i18n.t('module:Management:Finance:SessionsV3:sessionsFor', { name: data.name }),
    })
  }

  useEffect(() => {
    if (isOrganizationContext && !initialized) {
      nurseriesActions.get(nurseryId, {
        onSuccess: handleGetNurserySuccess,
        onlyData: true,
      })
    } else if (!initialized) {
      setInitialized(true)
    }
  }, [isOrganizationContext])

  useEffect(() => {
    if (initialized) {
      fetch()
    }
  }, [statusFilter, initialized, page])

  const handleStatusFilterChange = (newStatusFilter) => {
    setStatusFilter(newStatusFilter)
  }

  const handleSortChange = (key) => {
    onSortChange(onPageChange()(1))(key)
  }

  const sessionsTableData = getSessionsTableData(nurserySessionsV3ListState.data, isOrganizationContext, nursery)
  const isLoading = nurserySessionsV3ListState.isFetching || !nurserySessionsV3ListState.data
  const isEmpty = !nurserySessionsV3ListState?.data?.length && !statusFilter

  return (
    <SessionsV3View
      FinanceAutomationGranted={FinanceAutomationGranted}
      errorMessages={errorMessages}
      isEmpty={isEmpty}
      isLoading={isLoading}
      isOrganizationContext={isOrganizationContext}
      nursery={nursery}
      pagination={getPaginationDetails(totalResults)}
      sessions={sessionsTableData}
      sortField={sortField}
      sortOrder={sortOrder}
      statusFilter={statusFilter}
      onSortChange={handleSortChange}
      onStatusFilterChange={handleStatusFilterChange}
    />
  )
}

SessionsV3Container.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

const enhance = compose(
  withAppService,
  withNurserySessionsV3Service,
  withRouterUtils,
  withPaginationUtils,
  withSortingUtils,
  withSecurityService,
  withNurseriesService,
  withShellService,
  connector,
)

export default enhance(SessionsV3Container)
