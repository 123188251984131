import i18n from 'translations'

export const COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'name',
    title: i18n.t('module:Children:Child:Contacts:List:Name'),
    width: '30%',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'relation',
    title: i18n.t('module:Children:Child:Contacts:List:Relation'),
    width: '20%',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'parentalResponsibility',
    title: i18n.t('module:Children:Child:Contacts:List:ParentalResponsibility'),
    width: '20%',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'parentAppAccess',
    title: i18n.t('module:Children:Child:Contacts:List:ParentApp'),
    width: '20%',
  },
  {
    field: 'rowToButton',
  },
]
