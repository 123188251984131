import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
`

export const StyledSubContentWrapper = styled.div`
  ${({ $hasAccessToLikesAndComments }) => $hasAccessToLikesAndComments && `
    display: grid;
    grid-template-columns: auto 120px;
    grid-gap: 10px;
  `}
`

export const StyledCarerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`

export const StyledCarerText = styled.div`
  font-weight: 600;
  margin-left: 5px;
`

export const StyledTextContainer = styled.div`
  display: flex;
  align-items: center;
`

export const StyledLetter = styled.div`
  margin-right: 8px;
`

export const StyledText = styled.div`
  color: ${getBrandingColor('tertiary-color')};
  font-weight: 600;
`

export const StyledObservationBadge = styled.div`
  font-size: 10px;
  background: ${getBrandingColor('tertiary-color')};
  color: ${NEUTRAL_COLOURS.WHITE};
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  line-height: 1;
`

export const StyledLikesWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 20px;
  padding-top: 2px;
`
