import React, { Component } from 'react'
import { compose } from 'recompose'

import { generateRoute } from 'utils/routing'

import { withModalService } from 'services/utils/modal'
import { withRouter } from 'services/router'

import i18n from 'translations'

import AutoCreditNotingView from './AutoCreditNotingView'

const AUTO_CREDIT_NOTE_TRANS_PREFIX = 'module:Children:Child:BookingPattern:AutoCreditNoting'

class AutoCreditNotingContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentStage: 1,
      disableUnsavedPopup: false,
      fundingEndDate: null,
      fundingStartDate: null,
      highestAvailableStage: 1,
    }
  }

  componentDidMount() {
    const { router } = this.props

    router.setRouteLeaveHook(router.routes[router.routes.length - 1], this.handleUnsavedPopup)
  }

  handleUnsavedPopup = (nextLocation) => {
    const { modalActions, modalConsts } = this.props
    const { currentStage, disableUnsavedPopup } = this.state

    if (disableUnsavedPopup) {
      return null
    }

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      cancelButtonLabel: i18n.t(`${AUTO_CREDIT_NOTE_TRANS_PREFIX}:LeavePagePopup:cancelButtonLabel`),
      confirmButtonLabel: i18n.t(`${AUTO_CREDIT_NOTE_TRANS_PREFIX}:LeavePagePopup:confirmButtonLabel`),
      icon: 'warning',
      onCancel: () => this.handleUnsavedPopupSuccess(nextLocation),
      text: 3 !== currentStage
        ? i18n.t(`${AUTO_CREDIT_NOTE_TRANS_PREFIX}:LeavePagePopup:leaveOtherPage`)
        : i18n.t(`${AUTO_CREDIT_NOTE_TRANS_PREFIX}:LeavePagePopup:leaveReIssueInvoicePageText`),
    })

    return false
  }

  handleUnsavedPopupSuccess = (nextLocation) => {
    const { navigate } = this.props

    this.setState({ disableUnsavedPopup: true }, () => {
      navigate(nextLocation)
    })

    return true
  }

  handleNextStage = () => {
    const { currentStage, highestAvailableStage } = this.state

    this.setState({ highestAvailableStage: highestAvailableStage === currentStage
      ? currentStage + 1
      : highestAvailableStage,
    })

    this.setState({ currentStage: currentStage + 1 })
  }

  handleStageChange = (stage) => {
    const { currentStage, highestAvailableStage } = this.state

    if (stage < currentStage || stage <= highestAvailableStage) {
      return this.setState({ currentStage: stage })
    }

    return null
  }

  handleAllocateFundingContinueClick = (fundingStartDate, fundingEndDate) => {
    this.setState({ fundingEndDate, fundingStartDate })

    this.handleNextStage()
  }

  handleIssueCreditNoteContinueClick = () => {
    this.handleNextStage()
  }

  handleReIssueInvoiceContinueClick = () => {
    const { navigate, params } = this.props
    const { childId } = params

    this.setState({ disableUnsavedPopup: true }, () => {
      navigate(generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS', { childId }))
    })
  }

  render() {
    const { location: { query }, params } = this.props
    const { currentStage, fundingEndDate, fundingStartDate, highestAvailableStage } = this.state
    const { childId } = params
    const { endDate, startDate } = query

    return (
      <AutoCreditNotingView
        childId={childId}
        currentStage={currentStage}
        endDate={endDate}
        fundingEndDate={fundingEndDate}
        fundingStartDate={fundingStartDate}
        highestAvailableStage={highestAvailableStage}
        startDate={startDate}
        onAllocateFundingContinueClick={this.handleAllocateFundingContinueClick}
        onIssueCreditNoteContinueClick={this.handleIssueCreditNoteContinueClick}
        onReIssueInvoiceContinueClick={this.handleReIssueInvoiceContinueClick}
        onStageChange={this.handleStageChange}
      />
    )
  }
}

const enhance = compose(
  withModalService,
  withRouter,
)

export default enhance(AutoCreditNotingContainer)
