import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_BATCH_FOOD_TYPES,
  UPDATE_BATCH_FOOD_TYPES,
  UPDATE_BATCH_FOOD_TYPES_FAILED,
  UPDATE_BATCH_FOOD_TYPES_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_BATCH_FOOD_TYPES,
    updateFailed: UPDATE_BATCH_FOOD_TYPES_FAILED,
    updateInit: UPDATE_BATCH_FOOD_TYPES,
    updateSuccess: UPDATE_BATCH_FOOD_TYPES_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
