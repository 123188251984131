import _ from 'lodash'
import { v4 } from 'uuid'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'

import { FRAMEWORK_STRUCTURE_APPLICABLE, FRAMEWORK_TYPE_MONTESSORI } from 'services/legacy/frameworks/constants'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withFrameworksService } from 'services/legacy/frameworks'
import { withChildFrameworkProgressService } from 'services/legacy/childFrameworkProgress'
import { withMontessoriCategoriesService } from 'services/legacy/montessoriCategories'
import { withNurseriesService } from 'services/nurseries'
import { withRouter } from 'services/router'

import ChildFrameworkView from './ChildFrameworkView'

const FRAMEWORK_ITEM_GROUPS = {
  read: [
    'framework.areas',
    'frameworkArea',
    'frameworkArea.groups',
    'frameworkGroup',
    'frameworkGroup.categories',
    'frameworkCategory',
    'frameworkCategory.items',
    'frameworkItem',
    'structure',
    'structure.framework',
  ],
}

const CHILD_FRAMEWORK_PROGRESS_GROUPS = {
  read: [
    'frameworkArea',
    'frameworkArea.groups',
    'frameworkGroup',
    'frameworkGroup.categories',
    'frameworkCategory',
    'frameworkCategory.items',
    'frameworkItem',
    'frameworkItem.progress',
    'frameworkProgressLevel',
    'progress',
    'childFrameworkItemEvent',
    'childFrameworkItemEvent.observation',
    'childFrameworkItemEvent.progressLevel',
  ],
}

const CHILD_FRAMEWORK_MONTESSORI_PROGRESS_GROUPS = {
  read: [
    'montessoriCategory',
    'montessoriCategory.subcategories',
    'montessoriSubcategory',
    'montessoriSubcategory.activities',
    'montessoriActivity',
    'montessoriActivity.progress',
    'progress',
    'childMontessoriActivityEvent',
  ],
}

const MONTESSORI_CATEGORIES_GROUPS = {
  read: [
    'montessoriCategory',
    'montessoriCategory.subcategories',
    'montessoriSubcategory.activities',
    'montessoriSubcategory',
    'montessoriActivity',
  ],
}

export const FRAMEWORK_LIST_GROUPS = {
  read: [
    'framework.default',
    'structure',
  ],
}

export const NURSERY_SETTINGS_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings.learning',
    'nurseryLearningSettings',
  ],
}

class ChildFrameworkContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      _recalculateFrameworkTable: v4(),
      areaOfLearning: null,
      framework: null,
    }
  }

  componentDidMount() {
    const { frameworksActions, nurseriesActions, nurseryOptions } = this.props

    nurseriesActions.get(nurseryOptions.id, {
      params: {
        groups: NURSERY_SETTINGS_GROUPS,
      },
    })

    frameworksActions.list({
      onSuccess: this.handleGetFrameworksListSuccess,
      params: [{
        groups: FRAMEWORK_LIST_GROUPS,
      }],
    })
  }

  componentWillUnmount() {
    const { childFrameworkProgressActions, frameworksActions } = this.props

    childFrameworkProgressActions.clear()
    frameworksActions.clear()
  }

  addQueryToUrl = () => {
    const { areaOfLearning, framework } = this.state
    const location = _.assign({}, browserHistory.getCurrentLocation())

    location.query = {
      ...location.query,
    }

    if (framework) {
      location.query.framework = framework
    }

    if (areaOfLearning) {
      location.query.areaOfLearning = areaOfLearning
    }

    browserHistory.push(location)
  }

  handleGetFrameworksListSuccess = () => {
    const { frameworksRawList, location } = this.props
    const { query: { framework } } = location

    if (framework === FRAMEWORK_TYPE_MONTESSORI) {
      return this.setState({ framework: FRAMEWORK_TYPE_MONTESSORI }, this.getFrameworkDetails)
    }

    let firstFramework = frameworksRawList?.[0]?.id
    const defaultFramework = _.find(frameworksRawList, 'default')?.id

    if (_.find(frameworksRawList, ({ id }) => +framework === id)) {
      firstFramework = +framework
    }

    if (firstFramework) {
      this.setState({ framework: defaultFramework || firstFramework }, this.getFrameworkDetails)
    }

    return null
  }

  getProgressDetails = () => {
    const { childFrameworkProgressActions, params } = this.props
    const { areaOfLearning, framework } = this.state
    const { childId } = params

    this.addQueryToUrl()

    if (FRAMEWORK_TYPE_MONTESSORI === framework) {
      return childFrameworkProgressActions.listOfMontessoriProgress({
        params: [
          +childId,
          areaOfLearning,
          {
            groups: CHILD_FRAMEWORK_MONTESSORI_PROGRESS_GROUPS,
          },
        ],
      })
    }

    return childFrameworkProgressActions.listOfProgress({
      params: [
        +childId,
        framework,
        areaOfLearning,
        {
          groups: CHILD_FRAMEWORK_PROGRESS_GROUPS,
        },
      ],
    })
  }

  getMontessoriCategoriesSuccess = (response) => {
    const { location } = this.props
    const { data } = response
    const { query: { areaOfLearning } } = location

    let firstAreaOfLearning = data?.[0]?.id

    if (_.find(data, ({ id }) => +areaOfLearning === id)) {
      firstAreaOfLearning = +areaOfLearning
    }

    if (firstAreaOfLearning) {
      this.setState({ areaOfLearning: firstAreaOfLearning }, this.getProgressDetails)
    }
  }

  getFrameworkDetailsSuccess = (response) => {
    const { location } = this.props
    const { data } = response
    const { query: { areaOfLearning } } = location
    const { areas } = data

    let firstAreaOfLearning = areas?.[0]?.id

    if (_.find(areas, ({ id }) => +areaOfLearning === id)) {
      firstAreaOfLearning = +areaOfLearning
    }

    if (firstAreaOfLearning) {
      this.setState({ areaOfLearning: firstAreaOfLearning }, this.getProgressDetails)
    }
  }

  getFrameworkDetails = () => {
    const { frameworksActions, montessoriCategoriesActions } = this.props
    const { framework } = this.state

    if (FRAMEWORK_TYPE_MONTESSORI === framework) {
      return montessoriCategoriesActions.list({
        onSuccess: this.getMontessoriCategoriesSuccess,
        params: [{
          groups: MONTESSORI_CATEGORIES_GROUPS,
        }],
      })
    }

    return frameworksActions.get({
      onSuccess: this.getFrameworkDetailsSuccess,
      params: [framework, {
        groups: FRAMEWORK_ITEM_GROUPS,
      }],
    })
  }

  handleAreaOfLearningChange = (areaOfLearning) => {
    this.setState({ areaOfLearning: areaOfLearning?.value }, this.getProgressDetails)
  }

  handleFrameworkChange = (framework) => {
    this.setState({ areaOfLearning: null, framework: framework?.value }, this.getFrameworkDetails)
  }

  handleShowSelectProgressModal = (item) => {
    const { frameworkDetails, modalActions, modalConsts, nurseryLearningSettings, params } = this.props
    const { structure = {} } = frameworkDetails || {}
    const { markOnlyCompleted } = structure
    const { useOnlySecureToMarkProgress } = nurseryLearningSettings
    const { framework } = this.state
    const { childId } = params

    const onlyCompletedLevels = markOnlyCompleted && useOnlySecureToMarkProgress

    modalActions.show(modalConsts.TYPES.FRAMEWORK_SELECT_PROGRESS, {
      childId,
      description: item.description,
      framework,
      isFrameworkMontessori: FRAMEWORK_TYPE_MONTESSORI === framework,
      itemId: item.id,
      onRemoveSuccess: this.getFrameworkDetails,
      onSuccess: () => {
        if (FRAMEWORK_TYPE_MONTESSORI !== framework) {
          this.setState({ _recalculateFrameworkTable: v4() })
        }
      },
      onlyCompletedLevels,
    }, {
      enableMultipleModal: true,
    })
  }

  render() {
    const {
      childFrameworkMontessoriProgress,
      childFrameworkProgress,
      frameworkDetails,
      frameworksList,
      isFetching,
      isFetchingFramework,
      montessoriCategories,
      params,
    } = this.props
    const { _recalculateFrameworkTable, areaOfLearning, framework } = this.state
    const { childId } = params

    return (
      <ChildFrameworkView
        areaOfLearning={areaOfLearning}
        childFrameworkMontessoriProgress={childFrameworkMontessoriProgress}
        childFrameworkProgress={childFrameworkProgress}
        childId={childId}
        framework={framework}
        frameworkDetails={frameworkDetails}
        frameworksList={frameworksList}
        isFetching={isFetching}
        isFetchingFramework={isFetchingFramework}
        montessoriCategories={montessoriCategories}
        recalculateFrameworkTable={_recalculateFrameworkTable}
        onAreaOfLearningChange={this.handleAreaOfLearningChange}
        onFrameworkChange={this.handleFrameworkChange}
        onShowSelectProgressModal={this.handleShowSelectProgressModal}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childFrameworkProgressListMontessoriState,
  childFrameworkProgressListState,
  childFrameworkProgressSelectors,
  frameworksListState,
  frameworksSelectors,
  frameworksSingleState,
  montessoriCategoriesListState,
  montessoriCategoriesSelectors,
  nurseriesSelectors,
  nurseriesSingleState,
  params,
}) => ({
  childFrameworkMontessoriProgress: (
    childFrameworkProgressSelectors.getChildFrameworkProgressListMontessoriDataState(state)
  ),
  childFrameworkProgress: childFrameworkProgressSelectors.getChildFrameworkProgressListDataState(state),
  frameworkDetails: frameworksSelectors.getFrameworksSingleDataState(state),
  frameworksList: (
    frameworksSelectors.getFilteredFrameworksWithMontessoriList(FRAMEWORK_STRUCTURE_APPLICABLE.FRAMEWORK)(state)
  ),
  frameworksRawList: frameworksSelectors.getFilteredFrameworksRawList(FRAMEWORK_STRUCTURE_APPLICABLE.FRAMEWORK)(state),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState, frameworksListState),
  isFetchingFramework: appSelectors.getIsFetching(
    childFrameworkProgressListMontessoriState,
    childFrameworkProgressListState,
    frameworksSingleState,
    montessoriCategoriesListState,
  ),
  montessoriCategories: montessoriCategoriesSelectors.getMontessoriCategoriesListData(state),
  nurseryLearningSettings: nurseriesSelectors.getNurseryLearningSettings(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const enhance = compose(
  withAppService,
  withChildFrameworkProgressService,
  withFrameworksService,
  withMontessoriCategoriesService,
  withModalService,
  withNurseriesService,
  withRouter,
  connect(mapState),
)

export default enhance(ChildFrameworkContainer)
