import _ from 'lodash'

import React from 'react'

import PlanView from 'module/Finance/components/PlanView'

import i18n from 'translations'

const PlanDetailFundings = ({ childFundings }) => {
  if (!childFundings || !childFundings.length) {
    return (
      <PlanView>
        <PlanView.Icon icon="funding" />
        <PlanView.Content>
          <PlanView.SubTitle
            subtitle={i18n.t('module:Children:Child:Finance:PlanView:Summary:fundingEmptyState')}
          />
        </PlanView.Content>
      </PlanView>
    )
  }

  const renderFunding = ({ id, label, period }) => (
    <React.Fragment key={id}>
      <PlanView.SubGroup>
        <PlanView.SubGroupContent>
          <PlanView.Item>
            {label}
          </PlanView.Item>
          <PlanView.Item>
            <PlanView.SubTitle subtitle={`Period: ${period}`} />
          </PlanView.Item>
        </PlanView.SubGroupContent>
      </PlanView.SubGroup>
    </React.Fragment>
  )

  return (
    <PlanView>
      <PlanView.Icon icon="funding" />
      <PlanView.Content>
        <PlanView.Title title={i18n.t('module:Children:Child:Finance:PlanView:Summary:funding')} />
        {_.map(childFundings, renderFunding)}
      </PlanView.Content>
    </PlanView>
  )
}

export default PlanDetailFundings
