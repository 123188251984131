import React, { useEffect, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'

import { StyledContainer } from './SignaturePadStyled'

interface SignaturePadProps extends React.ComponentPropsWithoutRef<any> {
  onBegin?: () => void
}

const SignaturePad = React.forwardRef<any, SignaturePadProps>((props, ref) => {
  const parentRef = useRef(null)
  const [height, setHeight] = useState<number>(100)
  const [width, setWidth] = useState<number>(100)

  useEffect(() => {
    setHeight(parentRef.current.clientHeight)
    setWidth(parentRef.current.clientWidth)
  }, [])

  const newCanvasProps = {
    height,
    width,
    ...props.canvasProps,
  }

  return (
    <StyledContainer ref={parentRef}>
      <SignatureCanvas
        {...props}
        canvasProps={newCanvasProps}
        ref={ref}
      />
    </StyledContainer>
  )
})

export default SignaturePad
