import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change as changeForm, formValueSelector, getFormValues } from 'redux-form'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withFrameworksService } from 'services/legacy/frameworks'
import { withChildFrameworkProgressService } from 'services/legacy/childFrameworkProgress'

import { ALL_OPTION } from 'services/legacy/frameworks/constants'

import FrameworkBulkMarkModalView from './FrameworkBulkMarkModalView'
import { FRAMEWORK_BULK_MARK_FORM } from './components/FrameworkBulkMarkForm/FrameworkBulkMarkForm'

const FrameworkBulkMarkModalContainer = ({
  ageBandOptions,
  appSelectors,
  area,
  areaOptions,
  change,
  child,
  childFrameworkProgressActions,
  childFrameworkProgressSelectors,
  formValues,
  frameworksActions,
  frameworksListState,
  getChildEyfsObservations,
  hideModal,
  onBulkMarkSuccess,
}) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [error, setError] = useState(null)
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [itemsCount, setItemsCount] = useState(0)

  useEffect(() => {
    frameworksActions.list()
  }, [])

  useEffect(() => {
    if (area) {
      change('aspect', ALL_OPTION)
    }
  }, [area])

  const resetError = () => setError(null)

  const handleCloseClick = () => {
    if (currentStep) {
      setCurrentStep(0)
      resetError()
      return
    }

    hideModal()
  }

  const handleSuccessBulkUpdate = () => {
    if (onBulkMarkSuccess) {
      onBulkMarkSuccess()
    }

    setIsFormLoading(false)
    hideModal()
  }

  const handleFailBulkUpdate = (err) => {
    const apiError = appSelectors.getErrorMessage({ error: err })

    setIsFormLoading(false)
    setError(apiError)
  }

  const handleSuccessObservations = (fields) => (data) => {
    const count = childFrameworkProgressSelectors.getExpectBulkUpdateItemCount({
      childFrameworkItemEvents: data,
      fields,
    })

    setItemsCount(count)
    setIsFormLoading(false)
    setCurrentStep(1)
    resetError()
  }

  const handleSubmit = (fields) => {
    setIsFormLoading(true)

    if (!currentStep) {
      getChildEyfsObservations(child.id, area.value, handleSuccessObservations(fields))
      return
    }

    const payload = childFrameworkProgressSelectors.getPayload({
      ...fields,
      child,
      frameworksListState,
    })

    childFrameworkProgressActions.bulkUpdate(payload, handleSuccessBulkUpdate, handleFailBulkUpdate)
  }

  const groupOptions = area ? area.groupOptions : null
  const initialValues = { date: new Date() }

  return (
    <FrameworkBulkMarkModalView
      ageBandOptions={ageBandOptions}
      areaOptions={areaOptions}
      child={child}
      currentStep={currentStep}
      error={error}
      formValues={formValues}
      groupOptions={groupOptions}
      initialValues={initialValues}
      isFormLoading={isFormLoading}
      itemsCount={itemsCount}
      onCloseClick={handleCloseClick}
      onSubmit={handleSubmit}
    />
  )
}

const mapState = (state, { frameworksSelectors }) => ({
  ageBandOptions: frameworksSelectors.getAgeBandOptions(state),
  area: formValueSelector(FRAMEWORK_BULK_MARK_FORM)(state, 'area'),
  areaOptions: frameworksSelectors.getAreaDropdownOptions(state),
  formValues: getFormValues(FRAMEWORK_BULK_MARK_FORM)(state),
})

const mapDispatch = {
  change: (field, value) => changeForm(FRAMEWORK_BULK_MARK_FORM, field, value),
}

const enhance = compose(
  withAppService,
  withModalService,
  withFrameworksService,
  withChildFrameworkProgressService,
  connect(mapState, mapDispatch),
)

export default enhance(FrameworkBulkMarkModalContainer)

