import React from 'react'

import { Callout, Page, Spinner } from 'components'

import i18n from 'translations'

import AddForm from './components/AddForm'

const AddView = ({ errorMessages, isEditMode, isLoading, ...rest }) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <AddForm
        isEditMode={isEditMode}
        {...rest}
      />
    )
  }

  return (
    <Page.Section
      isLoading={isLoading}
      title={i18n.t(`module:Enquiries:List:Add:${isEditMode ? 'editTitle' : 'addTitle'}`)}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default AddView
