import styled from 'styled-components'
import color from 'color'

import { getBrandingColor } from 'utils/branding'

export const StyledItem = styled.div`
  display: block;
  padding: 10px 20px;
  margin: 0 -20px;
  border-left: 2px solid transparent;
  cursor: pointer;
  
  ${({ $active }) => $active && `
    color: ${getBrandingColor('primary-color')};
    background: ${color(getBrandingColor('primary-color')).alpha(0.1).rgb().string()};
    border-color: ${getBrandingColor('primary-color')};
  `}
  
  @media(max-width: 480px) {
    margin: 0 -10px;
  }
`

export const StyledWrapperAccordionHeader = styled.div`
  padding-right: 35px;
`

export const StyledButtonWrapper = styled.div`
  @media(max-width: 480px) {
    line-height: 16px;
    font-size: 12px;

    a {
      height: auto;
    }

    .MuiButton-label {
      white-space: normal;
      line-height: 16px;
      padding: 5px 0;
      text-align: center;
    }
  }
`

export const StyledTabWrapper = styled.div`
  overflow-x: auto;
`

export const StyledTabInsideWrapper = styled.div`
  min-width: 500px
`
