import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Field, Typography } from 'components'

import { StyledDetailWrapper } from './DetailStyled'

const Detail = ({ label, main, value }) => (
  <StyledDetailWrapper main={main}>
    <Field.InlineEdit
      description={value}
      label={(
        <Typography color={!main ? NEUTRAL_COLOURS.GRAY : NEUTRAL_COLOURS.BASIC} bold>
          {label}
        </Typography>
      )}
      padding="5px 0"
      noBorder
    />
  </StyledDetailWrapper>
)

export default Detail
