import {
  CLEAR_NEWSLETTER_RECIPIENT_LIST,
  DELETE_NEWSLETTER_RECIPIENT,
  DELETE_NEWSLETTER_RECIPIENT_FAILED,
  DELETE_NEWSLETTER_RECIPIENT_SUCCESS,
  GET_NEWSLETTER_RECIPIENT_LIST,
  GET_NEWSLETTER_RECIPIENT_LIST_FAILED,
  GET_NEWSLETTER_RECIPIENT_LIST_SUCCESS,
  RESEND_NEWSLETTER_RECIPIENT,
  RESEND_NEWSLETTER_RECIPIENT_FAILED,
  RESEND_NEWSLETTER_RECIPIENT_SUCCESS,
} from './constants'

export const list = (
  params,
  mergeResult,
  onSuccess = () => { },
) => ({ newsletterRecipientsApiClient }) => (dispatch) => {
  dispatch({ type: GET_NEWSLETTER_RECIPIENT_LIST })

  newsletterRecipientsApiClient.list(params).then(
    (response) => {
      dispatch({
        mergeResult,
        payload: response,
        type: GET_NEWSLETTER_RECIPIENT_LIST_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => dispatch({
      error: response,
      type: GET_NEWSLETTER_RECIPIENT_LIST_FAILED,
    }),
  )
}

export const resend = (recipientId) => ({ newsletterRecipientsApiClient }) => (dispatch) => {
  dispatch({ recipientId, type: RESEND_NEWSLETTER_RECIPIENT })

  newsletterRecipientsApiClient.resend(recipientId).then(
    (response) => {
      dispatch({
        payload: response,
        recipientId,
        type: RESEND_NEWSLETTER_RECIPIENT_SUCCESS,
      })
    },
    ({ response }) => dispatch({
      error: response,
      recipientId,
      type: RESEND_NEWSLETTER_RECIPIENT_FAILED,
    }),
  )
}

export const remove = (recipientId) => ({ newsletterRecipientsApiClient }) => (dispatch) => {
  dispatch({ recipientId, type: DELETE_NEWSLETTER_RECIPIENT })

  newsletterRecipientsApiClient.delete(recipientId).then(
    (response) => {
      dispatch({
        payload: response,
        recipientId,
        type: DELETE_NEWSLETTER_RECIPIENT_SUCCESS,
      })
    },
    ({ response }) => dispatch({
      error: response,
      recipientId,
      type: DELETE_NEWSLETTER_RECIPIENT_FAILED,
    }),
  )
}

export const clear = () => ({
  type: CLEAR_NEWSLETTER_RECIPIENT_LIST,
})
