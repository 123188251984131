import { BasicListInitialState, getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import {
  CLEAR_ACTIVE_RECURRING_PAYMENT,
  GET_ACTIVE_RECURRING_PAYMENT,
  GET_ACTIVE_RECURRING_PAYMENT_FAILED,
  GET_ACTIVE_RECURRING_PAYMENT_SUCCESS,
} from './constants'

const initialState = getBasicListInitialState

export interface NurseryIntegrationsActiveRecurringPaymentState extends BasicListInitialState {}

export default (...props) => {
  const [state = initialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_ACTIVE_RECURRING_PAYMENT,
    failed: GET_ACTIVE_RECURRING_PAYMENT_FAILED,
    init: GET_ACTIVE_RECURRING_PAYMENT,
    success: GET_ACTIVE_RECURRING_PAYMENT_SUCCESS,
  }, initialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
