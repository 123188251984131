import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { NEUTRAL_COLOURS } from 'constants/colors'
import { VIEW_MODE, VIEW_MODE_OPTIONS } from 'services/legacy/membershipsShifts/constants'
import { AVAILABLE_DAYS } from 'services/legacy/membershipRegisters/constants'

import { getBrandingColor } from 'utils/branding'

import { DatePicker, InfiniteScroll, List, Page, Pagination, Select, Toolbar, Typography } from 'components'

import i18n from 'translations'

import { NurseryRotaItem } from './components'
import { StyledDayBox, StyledDayListContainer } from './NurseryRotaStyled'

const NurseryRotaView = ({
  date,
  dateRange,
  day,
  isFetching,
  nurseries,
  onChangeDate,
  onChangeDay,
  onChangeViewMode,
  onPageChange,
  page,
  pageCount,
  totalResults,
  viewMode,
}) => {
  const renderHeader = () => (
    <Toolbar noMargin>
      <Toolbar.Group>
        <Toolbar.Item>
          <Select
            clearable={false}
            options={VIEW_MODE_OPTIONS}
            placeholder={i18n.t('global:View')}
            searchable={false}
            value={viewMode}
            onChange={onChangeViewMode}
          />
        </Toolbar.Item>
      </Toolbar.Group>
      <Toolbar.Group>
        <Toolbar.Item>
          <DatePicker
            type="week"
            value={dateRange}
            range
            onChange={onChangeDate}
          />
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )

  const renderPagination = () => !isFetching && (
    <Pagination
      totalResults={totalResults}
    />
  )

  const renderItem = (nursery) => (
    <NurseryRotaItem
      date={date}
      day={day}
      key={nursery.id}
      nursery={nursery}
      viewMode={viewMode}
    />
  )

  const renderDayList = () => (
    <StyledDayListContainer>
      {_.times(AVAILABLE_DAYS, (i) => {
        const isActive = moment(date).add(i, 'days')
          .format(DEFAULT_DATE_FORMAT) === moment(day).format(DEFAULT_DATE_FORMAT)

        return (
          <StyledDayBox
            isActive={isActive}
            key={`DAY_${i}`}
            onClick={() => onChangeDay(i)}
          >
            <Typography
              color={isActive ? getBrandingColor('primary-color') : NEUTRAL_COLOURS.BASIC}
              transform="uppercase"
            >
              <Typography variant="span" bold>
                {moment(date).add(i, 'days').format('ddd')}
              </Typography>
              {' '}
              <Typography
                color={isActive ? getBrandingColor('primary-color') : NEUTRAL_COLOURS.GRAY}
                fontSize={17}
                variant="span"
              >
                {moment(date).add(i, 'days').format('DD')}
              </Typography>
            </Typography>
          </StyledDayBox>
        )
      })}
    </StyledDayListContainer>
  )

  const renderContent = () => (
    <React.Fragment>
      {VIEW_MODE.DAY === viewMode && renderDayList()}
      {renderPagination()}
      <InfiniteScroll
        dataLength={nurseries?.length || 0}
        hasMore={page < pageCount}
        isFetching={isFetching}
        next={() => onPageChange((+page) + 1)}
      >
        <List.Standard>
          {_.map(nurseries, renderItem)}
        </List.Standard>
      </InfiniteScroll>
      {renderPagination()}
    </React.Fragment>
  )

  return (
    <Page>
      <Page.Content>
        {renderHeader()}
        {renderContent()}
      </Page.Content>
    </Page>
  )
}

export default NurseryRotaView
