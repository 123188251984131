import _ from 'lodash'
import { createSelector } from 'reselect'

import { DIETARY_REQUIREMENTS_OPTIONS } from 'services/legacy/child/constants'

const getChild = (state) => state.child

export const getDietaryOptionLabel = (dietaryReq) => _.get(
  _.find(DIETARY_REQUIREMENTS_OPTIONS, (option) => option.value === dietaryReq),
  'label',
)

export const getDietaryStatistics = createSelector([getChild], (state) => state.dietary)
