import React, { Component } from 'react'
import { Field as FormField, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { NAPPY_WAS_OPTIONS } from 'services/legacy/dailyDiaryNappies/constants'

import { isRequired } from 'utils/fieldValidation'

import { withDailyDiaryNappiesService } from 'services/legacy/dailyDiaryNappies'

import { DefinitionList, Field, Form, TimeString, Typography } from 'components'

import i18n from 'translations'

import withDailyDiaryRecordHandlers from '../../../withDailyDiaryRecordHandlers'
import { StyledFieldWrapper, StyledVisibleBody } from './NappyRecordStyled'

export const NAPPY_RECORD_EDIT_FORM = 'NappyRecordEditForm'

class NappyRecord extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isEditMode: false,
    }
  }

  handleEditMode = (isEditMode) => {
    const { item, onCancelEdit } = this.props

    if (!isEditMode && onCancelEdit) {
      onCancelEdit(item)
    }

    this.setState({ isEditMode })
  }

  handleSubmitClick = (e) => {
    const { dailyDiaryRecordHelpers: { handleSubmitClick } } = this.props

    handleSubmitClick(e, {
      onFailed: this.handleEditMode,
    })
  }

  render() {
    const { dailyDiaryRecordState, item, onRemoveRecord, typeValue } = this.props
    const { isProcessing } = dailyDiaryRecordState
    const { isEditMode } = this.state

    const { id, nappyRash, record, wasCreamApplied } = item
    const { comments, recordedAt } = record || {}

    const actions = (
      <Field.InlineEdit.Actions
        isEdit={isEditMode}
        isProcessing={isProcessing}
        onCancelClick={() => this.handleEditMode(false)}
        onDeleteClick={() => onRemoveRecord(id)}
        onEditClick={() => this.handleEditMode(true)}
        onSaveClick={this.handleSubmitClick}
      />
    )

    const renderEditableComponent = (
      <StyledVisibleBody>
        <DefinitionList.Item
          label={(
            <Typography bold primary>
              {i18n.t('module:DailyDiary:Nappies:nappyChange')}
            </Typography>
          )}
          value={(
            <FormField
              component={Form.TimePicker}
              disabled={isProcessing}
              name="recordedAt"
              validate={isRequired}
            />
          )}
        />
        <DefinitionList.Item
          label={(
            <Typography bold primary>
              {i18n.t('module:DailyDiary:Nappies:nappyWas')}
            </Typography>
          )}
          value={(
            <FormField
              component={Form.RadioGroup}
              disabled={isProcessing}
              name="type"
              options={NAPPY_WAS_OPTIONS}
              validate={isRequired}
            />
          )}
        />
        <DefinitionList.Item
          label={i18n.t('module:DailyDiary:Nappies:creamApplied')}
          value={(
            <FormField
              component={Form.Switch}
              disabled={isProcessing}
              name="wasCreamApplied"
            />
          )}
        />
        <DefinitionList.Item
          label={i18n.t('module:DailyDiary:Nappies:nappyRash')}
          value={(
            <FormField
              component={Form.Switch}
              disabled={isProcessing}
              name="nappyRash"
            />
          )}
        />
        <DefinitionList.Item
          label={i18n.t('module:DailyDiary:global:additionalNotes')}
          value={(
            <FormField
              component={Form.TextAreaField}
              disabled={isProcessing}
              height={90}
              maxCharacters={2000}
              name="comments"
              placeholder={i18n.t('module:DailyDiary:global:enterAdditionalNotesHere')}
              autosize
            />
          )}
          whiteSpace="pre-line"
        />
      </StyledVisibleBody>
    )

    const renderVisibleComponent = (
      <StyledVisibleBody>
        <DefinitionList.Item
          label={(
            <Typography bold primary>
              {i18n.t('module:DailyDiary:Nappies:nappyChange')}
            </Typography>
          )}
          value={(
            <TimeString date={recordedAt} />
          )}
        />
        <DefinitionList.Item
          label={i18n.t('module:DailyDiary:Nappies:nappyWas')}
          value={typeValue}
        />
        <DefinitionList.Item
          label={i18n.t('module:DailyDiary:Nappies:creamApplied')}
          value={wasCreamApplied ? i18n.t('global:Yes') : i18n.t('global:No')}
        />
        <DefinitionList.Item
          label={i18n.t('module:DailyDiary:Nappies:nappyRash')}
          margin="0 0 10px"
          value={nappyRash ? i18n.t('global:Yes') : i18n.t('global:No')}
        />
        <DefinitionList.Item
          label={i18n.t('module:DailyDiary:global:additionalNotes')}
          value={comments || '-'}
          whiteSpace="pre-line"
          wordBreak="break-word"
        />
      </StyledVisibleBody>
    )

    return (
      <StyledFieldWrapper>
        <Form onSubmit={this.handleSubmitClick}>
          <Field.InlineEdit
            actions={actions}
            description={renderVisibleComponent}
            editableComponent={renderEditableComponent}
            isEditMode={isEditMode}
            noPadding
          />
        </Form>
      </StyledFieldWrapper>
    )
  }
}

const mapState = (state, {
  dailyDiaryNappiesSelectors,
  item,
}) => {
  const getTypeValue = dailyDiaryNappiesSelectors.makeGetTypeValue()

  return {
    typeValue: getTypeValue(item),
  }
}

const enhance = compose(
  withDailyDiaryRecordHandlers,
  withDailyDiaryNappiesService,
  connect(mapState),
)

export default reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: true,
})(enhance(NappyRecord))
