import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withRegisterStatisticsService } from 'services/legacy/registerStatistics'

import AttendanceWidgetView from './AttendanceWidgetView'

class AttendanceWidgetContainer extends Component {
  componentDidMount() {
    const { registerStatisticsActions } = this.props

    registerStatisticsActions.getRegisterStatistics(moment().utc().format('YYYY-MM-DD HH:mm:ss'))
  }

  componentWillUnmount() {
    const { registerStatisticsActions } = this.props

    registerStatisticsActions.clearRegisterStatistics()
  }

  render() {
    const { isFetching, registerStatistics } = this.props

    return (
      <AttendanceWidgetView
        isFetching={isFetching}
        registerStatistics={registerStatistics}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  registerStatisticsSelectors,
  registerStatisticsState,
}) => ({
  isFetching: appSelectors.getIsFetching(registerStatisticsState),
  registerStatistics: registerStatisticsSelectors.getRegisterStatisticsData(state),
})

const enhance = compose(
  withAppService,
  withRegisterStatisticsService,
  connect(mapState),
)

export default enhance(AttendanceWidgetContainer)
