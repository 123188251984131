import _ from 'lodash'
import moment from 'moment'
import { flatten } from 'utils/flatnest'

import React from 'react'

import colors, { NEUTRAL_COLOURS } from 'constants/colors'

import { convertToPercent, toFloat } from 'utils/data'
import { generateRoute } from 'utils/routing'

import { Avatar, Button, Currency, ReportView, Typography } from 'components'

import i18n from 'translations'

import { NURSERY_TABLE_COLUMNS, ORGANIZATION_TABLE_COLUMNS } from './constants'

const createFundingColumns = (statisticsData) => {
  if (!statisticsData?.fundingTypes?.length) {
    return []
  }

  const columns = _.map(statisticsData?.fundingTypes, ({ fundingType }) => ({
    field: fundingType.id,
    title: fundingType.name,
  }))

  return _.orderBy(columns, 'title')
}

export const getColumns = (isOrganizationContext, data, statisticsData) => {
  if (isOrganizationContext) {
    return [
      ...ORGANIZATION_TABLE_COLUMNS,
      ...createFundingColumns(statisticsData),
    ]
  }

  return NURSERY_TABLE_COLUMNS
}

export const getTableData = ({ data, filters, hasError, isOrganizationContext }) => {
  if (!data?.length || hasError) {
    return null
  }

  if (isOrganizationContext) {
    const queryString = new URLSearchParams(flatten({
      age: filters?.age,
      status: filters?.status,
    })).toString()

    return _.map(data, ({
      fundingTypes,
      resource,
      total,
    }) => ({
      name: (
        <ReportView.NurseryLink
          nursery={resource}
          queryString={queryString}
          routeKey="FINANCE.REPORT.FUNDING"
        />
      ),
      total: <Currency value={total} />,
      ..._.reduce(fundingTypes, (result, { fundingType, total: itemTotal }) => ({
        ...result,
        [fundingType.id]: <Currency value={itemTotal} />,
      }), {}),
    }))
  }

  return _.map(data, ({ child, funding, total, totalHours }) => {
    const { firstName, id: childId, photo, surname } = child || {}
    const {
      archived,
      endDate,
      fundingType,
      hourlyRate,
      id,
      label: fundingLabel,
      startDate,
    } = funding || {}
    const { name: fundingTypeLabel } = fundingType || {}

    const fundingName = `${fundingLabel} - ${fundingTypeLabel}${archived ? ` (${i18n.t('global:Archived')})` : ''}`

    return {
      child: (
        <Avatar
          avatarSize="small"
          initials={[firstName, surname]}
          src={photo}
          title={`${firstName} ${surname}`}
          primary
        />
      ),
      fundingType: fundingName,
      hourlyRate: hourlyRate ? <Currency value={hourlyRate} /> : null,
      id,
      period: `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`,
      rowToButton: (
        <Button.TableAction
          to={generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.FUNDING.VIEW', { childFundingId: id, childId })}
        />
      ),
      total: <Currency value={total} />,
      totalHours: toFloat(totalHours) || 0,
    }
  })
}

export const getStatistics = ({ data, hasError }) => {
  if (!data || hasError) {
    return null
  }

  if (!data) {
    return {
      chart: [],
      series: [],
    }
  }

  const { fundingTypes, total } = data
  const categories = _.map(fundingTypes, ({ name }) => name)

  return {
    categories,
    data: _.map(fundingTypes, (fundingType, i) => {
      const { fundingType: { name }, total: fundingTypeTotal } = fundingType

      const percentage = convertToPercent(total, fundingTypeTotal)

      return {
        amount: (
          <React.Fragment>
            <Currency value={fundingTypeTotal} oneSize />
            {' '}
            <Typography color={NEUTRAL_COLOURS.GRAY} fontSize="inherit" variant="span">
              {`(${percentage}%)`}
            </Typography>
          </React.Fragment>
        ),
        color: colors.standardPieChartColors[i],
        id: name,
        isCurrencyValue: true,
        percentage,
        value: toFloat(fundingTypeTotal),
      }
    }),
    id: 1,
    subtitle: (
      <Currency value={total} oneSize />
    ),
    title: 'Total',
  }
}
