import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Callout, Spinner, Typography } from 'components'
import Settings from 'module/Settings/components'

const SettingsInvoicesEditBankView = ({
  cancelLink,
  errorMessages,
  initialValues,
  isFormLoading,
  isLoading,
  nurseryId,
  nurseryName,
  onSubmit,
}) => {
  if (isLoading) {
    return <Spinner />
  }

  const title = (
    <span>
      Your invoice settings for
      {' '}
      <Typography color={NEUTRAL_COLOURS.BLUE} fontSize="inherit" variant="span" bold>
        {nurseryName}
      </Typography>
    </span>
  )

  return (
    <div>
      <Callout content={errorMessages} error />
      <Settings.InvoiceBankSettingForm
        cancelLink={cancelLink}
        initialValues={initialValues}
        isLoading={isFormLoading}
        nurseryId={nurseryId}
        title={title}
        onSubmit={onSubmit}
      />
    </div>
  )
}

export default SettingsInvoicesEditBankView
