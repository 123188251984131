import styled from 'styled-components'

import colors from 'constants/colors'

export const StyledRequiredChar = styled.span`
  color: ${colors.pink};
`

export const StyledShiftLabel = styled.span`
  display: flex;
  align-items: center;
`
