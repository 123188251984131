import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class NurseryAcademicYearsApiClient extends ServiceBase {
  list = (params = {}) => this.request({
    path: `/v2/nursery_academic_years?${getQueryStringV2(params)}`,
  })

  get = (nurseryAcademicYearId) => this.request({
    path: `/v2/nursery_academic_years/${nurseryAcademicYearId}`,
  })

  create = (body) => this.request({
    body,
    method: 'POST',
    path: '/v2/nursery_academic_years',
  })

  update = (nurseryAcademicYearId, body) => this.request({
    body,
    method: 'PATCH',
    path: `/v2/nursery_academic_years/${nurseryAcademicYearId}`,
  })

  remove = (nurseryAcademicYearId) => this.request({
    method: 'DELETE',
    path: `/v2/nursery_academic_years/${nurseryAcademicYearId}`,
  })
}

export default NurseryAcademicYearsApiClient
