import React from 'react'

import { ChildrenSlider } from 'components'

import NappiesTabForm from './components/NappiesTabForm'
import { StyledContainer } from './NappiesTabStyled'
import { StyledSliderContainer } from '../DailyDiaryRecordModalStyled'

const NappiesTabView = ({
  formValues,
  initialValues,
  isSubmitting,
  onCancel,
  onChangeDidNotSleep,
  onSubmit,
  onToiletTrainingChange,
  selectedChildren,
  trackToiletTraining,
}) => (
  <StyledContainer>
    <StyledSliderContainer>
      <ChildrenSlider
        childrenList={selectedChildren || []}
      />
    </StyledSliderContainer>
    <NappiesTabForm
      formValues={formValues}
      initialValues={initialValues}
      isSubmitting={isSubmitting}
      trackToiletTraining={trackToiletTraining}
      onCancel={onCancel}
      onChangeDidNotSleep={onChangeDidNotSleep}
      onSubmit={onSubmit}
      onToiletTrainingChange={onToiletTrainingChange}
    />
  </StyledContainer>
)

export default NappiesTabView
