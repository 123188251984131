export const GET_NURSERY_ACADEMIC_YEAR = 'NURSERY_ACADEMIC_YEARS/GET_NURSERY_ACADEMIC_YEAR'
export const GET_NURSERY_ACADEMIC_YEAR_FAILED = 'NURSERY_ACADEMIC_YEARS/GET_NURSERY_ACADEMIC_YEAR_FAILED'
export const GET_NURSERY_ACADEMIC_YEAR_SUCCESS = 'NURSERY_ACADEMIC_YEARS/GET_NURSERY_ACADEMIC_YEAR_SUCCESS'

export const CREATE_NURSERY_ACADEMIC_YEAR = 'NURSERY_ACADEMIC_YEARS/CREATE_NURSERY_ACADEMIC_YEAR'
export const CREATE_NURSERY_ACADEMIC_YEAR_FAILED = 'NURSERY_ACADEMIC_YEARS/CREATE_NURSERY_ACADEMIC_YEAR_FAILED'
export const CREATE_NURSERY_ACADEMIC_YEAR_SUCCESS = 'NURSERY_ACADEMIC_YEARS/CREATE_NURSERY_ACADEMIC_YEAR_SUCCESS'

export const UPDATE_NURSERY_ACADEMIC_YEAR = 'NURSERY_ACADEMIC_YEARS/UPDATE_NURSERY_ACADEMIC_YEAR'
export const UPDATE_NURSERY_ACADEMIC_YEAR_FAILED = 'NURSERY_ACADEMIC_YEARS/UPDATE_NURSERY_ACADEMIC_YEAR_FAILED'
export const UPDATE_NURSERY_ACADEMIC_YEAR_SUCCESS = 'NURSERY_ACADEMIC_YEARS/UPDATE_NURSERY_ACADEMIC_YEAR_SUCCESS'

export const REMOVE_NURSERY_ACADEMIC_YEAR = 'NURSERY_ACADEMIC_YEARS/REMOVE_NURSERY_ACADEMIC_YEAR'
export const REMOVE_NURSERY_ACADEMIC_YEAR_FAILED = 'NURSERY_ACADEMIC_YEARS/REMOVE_NURSERY_ACADEMIC_YEAR_FAILED'
export const REMOVE_NURSERY_ACADEMIC_YEAR_SUCCESS = 'NURSERY_ACADEMIC_YEARS/REMOVE_NURSERY_ACADEMIC_YEAR_SUCCESS'

export const CLEAR_NURSERY_ACADEMIC_YEAR = 'NURSERY_ACADEMIC_YEARS/CLEAR_NURSERY_ACADEMIC_YEAR'
