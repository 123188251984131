import { generateBasicActions } from 'utils/service'

import {
  CLEAR_CHILD_NOTES_LIST,
  GET_CHILD_NOTES_LIST,
  GET_CHILD_NOTES_LIST_FAILED,
  GET_CHILD_NOTES_LIST_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ childNotesApiClient }) => (
  generateBasicActions.list({
    constants: {
      failed: GET_CHILD_NOTES_LIST_FAILED,
      init: GET_CHILD_NOTES_LIST,
      success: GET_CHILD_NOTES_LIST_SUCCESS,
    },
    options,
    service: childNotesApiClient,
    serviceMethod: 'list',
  })
)

export const clearList = () => ({
  type: CLEAR_CHILD_NOTES_LIST,
})
