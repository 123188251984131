import React from 'react'

import StripeDisconnectConditionsModalView from './StripeDisconnectConditionsModalView'

const StripeDisconnectConditionsModalContainer = ({
  conditions,
  hideModal,
}) => (
  <StripeDisconnectConditionsModalView
    conditions={conditions}
    onHideModal={hideModal}
  />
)

export default StripeDisconnectConditionsModalContainer
