import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { BROWSERS } from 'constants/browsers'

import { StyledHeader, StyledSearchWrapper } from 'components/Conversations/ConversationsStyled'

const CHANNEL_LIST_COLUMN_WIDTH = 320

export const StyledPageWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 152px;
  background: #F9F9F9;
  
  @media(max-width: 580px) {
    top: 60px;
  }
`

export const StyledChannelList = styled.div`
  background: ${NEUTRAL_COLOURS.WHITE};
  border-right: 2px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  padding: 16px;
  display: grid;
  grid-template-rows: 114px auto;
  position: absolute;
  top: 0;
  bottom: 0;
  width: ${CHANNEL_LIST_COLUMN_WIDTH}px;
  
  ${({ hide }) => hide && `
    opacity: 0;
  `}
  
  ${StyledHeader} {
    display: grid;
    grid-row-gap: 0;
    grid-template-rows: 80px auto;
    margin-top: -16px;
    align-items: center;
  }
  
  ${StyledSearchWrapper} {
    margin-top: -10px;
  }
  
  @media(max-width: 700px) {
    display: none;
  }
`

export const StyledMessagesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: ${CHANNEL_LIST_COLUMN_WIDTH}px;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-rows: 80px 1fr auto;
  
  ${({ browserName }) => browserName === BROWSERS.SAFARI && `
    grid-template-rows: 80px calc(100vh - 80px - 152px - 69px) 44px;
    
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      grid-template-rows: 80px calc(100vh - 80px - 152px - 100px) 44px;
    }
  `}
  
  @media(max-width: 700px) {
    left: 0;
  }
`
