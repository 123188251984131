import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'
import { FEATURE_FLAGS } from 'constants/security'

import auth from 'utils/auth'

const getAppState = (state: RootState) => state.app

export const getAppIsOffline = createSelector([getAppState], (state) => state.offline.isOffline)

const getOfflineModeFFIsEnabled = (state) => auth.SELECTORS.getIsAuthorised(state, {
  flags: [FEATURE_FLAGS.OFFLINE_MODE],
})

export const getAppIsOfflineAndFFIsEnabled = createSelector([
  getAppIsOffline,
  getOfflineModeFFIsEnabled,
], (isOffline, offlineModeFFIsEnabled) => isOffline && offlineModeFFIsEnabled)
