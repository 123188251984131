import _ from 'lodash'

import React from 'react'
import { Field } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { Button, Form } from 'components'
import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

import i18n from 'translations'

import { StyledPriceChangesItem, StyledRemoveButtonWrapper } from './FundingAddFormStyled'

const renderPriceChangeRow = ({
  fields,
  i,
  isSubmitting,
  item,
  onChangeStartDate,
  onRemovePriceChangeRow,
}) => (
  <StyledPriceChangesItem $fieldsLength={fields.length} key={i}>
    <Form.Row
      label={0 === i && i18n.t('module:Management:Finance:Funding:Add:hourlyRate')}
      margin="0 0 5px 0"
      width={{ field: '100%' }}
      verticalLabel
    >
      <SubdomainCurrencyProvider>
        {({ currencySymbol }) => (
          <Field
            component={Form.TextField}
            disabled={isSubmitting}
            name={`${item}.prices[0].price`}
            placeholder={i18n.t('module:Management:Finance:Funding:Add:hourlyRate')}
            prefix={currencySymbol}
            prefixWidth="30px"
            type="number"
            validate={isRequired}
          />
        )}
      </SubdomainCurrencyProvider>
    </Form.Row>
    {1 < fields.length && (
      <React.Fragment>
        <Form.Row
          label={0 === i && _.upperFirst(i18n.t('global:startDate'))}
          margin="0 0 5px 0"
          width={{ field: '100%' }}
          verticalLabel
        >
          <Field
            component={Form.DatePicker}
            disabled={isSubmitting || 0 === i}
            name={`${item}.startDate`}
            placeholder={0 === i ? '-' : _.upperFirst(i18n.t('global:startDate'))}
            validate={1 < fields.length && 0 !== i && isRequired}
            onChange={(value) => onChangeStartDate(value, i)}
          />
        </Form.Row>
        <Form.Row
          label={0 === i && _.upperFirst(i18n.t('global:endDate'))}
          margin="0 0 5px 0"
          width={{ field: '100%' }}
          verticalLabel
        >
          <Field
            component={Form.DatePicker}
            name={`${item}.endDate`}
            placeholder={
              fields.length - 1 === i
                ? i18n.t('module:Management:Finance:Funding:Add:ongoing')
                : _.upperFirst(i18n.t('global:endDate'))
            }
            disabled
          />
        </Form.Row>
      </React.Fragment>
    )}
    {1 < fields.length && (
      <StyledRemoveButtonWrapper $first={0 === i}>
        <Form.RemoveRowButton
          disabled={isSubmitting}
          trash
          onClick={() => {
            fields.remove(i)
            onRemovePriceChangeRow(i)
          }}
        />
      </StyledRemoveButtonWrapper>
    )}
  </StyledPriceChangesItem>
)

export const renderPriceChanges = ({ fields, isSubmitting, onChangeStartDate, onRemovePriceChangeRow }) => (
  <React.Fragment>
    {fields.map((item, i) => renderPriceChangeRow({
      fields,
      i,
      isSubmitting,
      item,
      onChangeStartDate,
      onRemovePriceChangeRow,
    }))}
    <Button.ActionButton
      label={i18n.t('module:Management:Finance:Funding:Add:scheduleHourlyRateChange')}
      onClick={() => fields.push({ prices: [{ price: 0 }] })}
    />
  </React.Fragment>
)

const renderExclusionPeriodRow = ({
  fields,
  i,
  isSubmitting,
  item,
  onConfirmChangeExclusionPeriod,
}) => (
  <Form.Row
    label={0 === i && i18n.t('module:Management:Finance:Funding:Add:exclusionPeriod')}
    margin="0 0 5px 0"
    verticalLabel
  >
    <Form.Row.Item>
      <Field
        component={Form.DatePicker}
        disabled={isSubmitting}
        name={`${item}.dateRange`}
        placeholder={i18n.t('global:DateRangePlaceholder')}
        validate={isRequired}
        range
        onValueChange={onConfirmChangeExclusionPeriod(i, fields)}
      />
    </Form.Row.Item>
    <Form.Row.Item>
      <Form.RemoveRowButton
        disabled={isSubmitting}
        trash
        onClick={() => fields.remove(i)}
      />
    </Form.Row.Item>
  </Form.Row>
)

export const renderExclusionPeriods = ({ fields, isSubmitting, onConfirmChangeExclusionPeriod }) => (
  <React.Fragment>
    {fields.map((item, i) => renderExclusionPeriodRow({
      fields,
      i,
      isSubmitting,
      item,
      onConfirmChangeExclusionPeriod,
    }))}
    <Button.ActionButton
      label={i18n.t('module:Management:Finance:Funding:Add:addExclusionPeriod')}
      onClick={() => fields.push({})}
    />
  </React.Fragment>
)
