export const getChildren = (selectedAll, selected, queryChildren) => {
  if (selectedAll && !queryChildren) {
    return null
  }

  if (selectedAll && queryChildren) {
    return queryChildren.split(',')
  }

  return selected
}
