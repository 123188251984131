import i18n from 'translations'

export const CONTRACT_TYPE = {
  BANK_STAFF: 'bankStaff',
  PERMANENT: 'permanent',
}

export const CONTRACT_TYPE_OPTIONS = [
  {
    label: i18n.t('module:Staff:StaffContracts:ContractType:Permanent:long'),
    value: CONTRACT_TYPE.PERMANENT,
  },
  {
    label: i18n.t('module:Staff:StaffContracts:ContractType:bankStaff'),
    value: CONTRACT_TYPE.BANK_STAFF,
  },
]

export const CONTRACT_TYPE_OPTIONS_SHORT = [
  {
    label: i18n.t('module:Staff:StaffContracts:ContractType:Permanent:short'),
    value: CONTRACT_TYPE.PERMANENT,
  },
  {
    label: i18n.t('module:Staff:StaffContracts:ContractType:bankStaff'),
    value: CONTRACT_TYPE.BANK_STAFF,
  },
]

export const OVERTIME_TREATMENT = {
  NOT_PAID: 'NOT_PAID',
  PAID: 'PAID',
  TOIL: 'TOIL',
}

export const OVERTIME_TREATMENT_OPTIONS = [
  {
    label: i18n.t('module:Staff:StaffContracts:OvertimeTreatment:paid'),
    value: OVERTIME_TREATMENT.PAID,
  },
  {
    label: i18n.t('module:Staff:StaffContracts:OvertimeTreatment:notPaid'),
    value: OVERTIME_TREATMENT.NOT_PAID,
  },
  {
    label: i18n.t('module:Staff:StaffContracts:OvertimeTreatment:toil'),
    value: OVERTIME_TREATMENT.TOIL,
  },
]

export const CONTRACT_STATUS = {
  CURRENT: 'CURRENT',
  FUTURE: 'FUTURE',
  PAST: 'PAST',
}

export const CONTRACT_STATUS_OPTIONS = [
  {
    label: i18n.t('module:Staff:StaffContracts:Status:current'),
    value: CONTRACT_STATUS.CURRENT,
  },
  {
    label: i18n.t('module:Staff:StaffContracts:Status:future'),
    value: CONTRACT_STATUS.FUTURE,
  },
  {
    label: i18n.t('module:Staff:StaffContracts:Status:past'),
    value: CONTRACT_STATUS.PAST,
  },
]

export const BREAK_ALLOWANCE_TYPE = {
  PAID: 'PAID',
  UNPAID: 'UNPAID',
}

export const BREAK_ALLOWANCE_OPTIONS = [
  {
    label: i18n.t('services:Contacts:BreakAllowanceLabels:paidBreak'),
    value: BREAK_ALLOWANCE_TYPE.PAID,
  },
  {
    label: i18n.t('services:Contacts:BreakAllowanceLabels:unpaidBreak'),
    value: BREAK_ALLOWANCE_TYPE.UNPAID,
  },
]
