import _ from 'lodash'

import React from 'react'

import PlanView from 'module/Finance/components/PlanView'

import i18n from 'translations'

const MAX_VISIBLE_EXTRA_ITEMS = 2

const FinanceCurrentPlanExtraItems = ({ childExtraItems }) => {
  if (!childExtraItems || !childExtraItems.length) {
    return (
      <PlanView>
        <PlanView.Icon icon="plus" />
        <PlanView.Content>
          <PlanView.SubTitle
            subtitle={i18n.t('module:Children:Child:Finance:PlanView:Summary:extraItemsEmptyState')}
          />
        </PlanView.Content>
      </PlanView>
    )
  }

  const moreExtraItemCount = childExtraItems.length - MAX_VISIBLE_EXTRA_ITEMS
  const visibleExtraItems = childExtraItems.slice(0, MAX_VISIBLE_EXTRA_ITEMS)

  const renderExtraItem = ({ count, name, type }, index) => (
    <PlanView.Item key={_.kebabCase(index)}>
      <PlanView.Highlight>
        {`${count} x ${type}`}
          &nbsp;
      </PlanView.Highlight>
      {name}
    </PlanView.Item>
  )

  const renderMoreExtraItems = () => {
    if (0 >= moreExtraItemCount) {
      return null
    }

    return (
      <PlanView.Item>
        <PlanView.Highlight>
          {i18n.t('module:Children:Child:Finance:PlanView:Summary:more', { count: moreExtraItemCount })}
          &nbsp;
        </PlanView.Highlight>
        {i18n.t('module:Children:Child:Finance:PlanView:Summary:extraItems')}
      </PlanView.Item>
    )
  }

  return (
    <PlanView>
      <PlanView.Icon icon="plus" />
      <PlanView.Content>
        {_.map(visibleExtraItems, renderExtraItem)}
        {renderMoreExtraItems()}
      </PlanView.Content>
    </PlanView>
  )
}

export default FinanceCurrentPlanExtraItems
