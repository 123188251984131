import {
  CLEAR_LIST_DEVICE_TOKENS,
  LIST_DEVICE_TOKENS,
  LIST_DEVICE_TOKENS_FAILED,
  LIST_DEVICE_TOKENS_SUCCESS,
} from './constants'

export const list = (params, mergeResult, onSuccess, onFailed) => ({ deviceTokensApiClient }) => async (dispatch) => {
  dispatch({
    type: LIST_DEVICE_TOKENS,
  })

  await deviceTokensApiClient.list(params).then(
    (response) => {
      dispatch({
        mergeResult,
        payload: response,
        type: LIST_DEVICE_TOKENS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: LIST_DEVICE_TOKENS_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const clearList = () => ({
  type: CLEAR_LIST_DEVICE_TOKENS,
})
