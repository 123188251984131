import _ from 'lodash'

import React, { PropsWithChildren } from 'react'

import TimelineItem from './TimelineItem/TimelineItem'
import { StyledContainer } from './TimelineStyled'

interface TimelineRoot<T> extends React.FC<PropsWithChildren<T>> {
  Item?: typeof TimelineItem
}

interface TimelineProps {
  children: any
}

const Timeline: TimelineRoot<TimelineProps> = ({ children }) => {
  if (children?.length) {
    return (
      <StyledContainer>
        {_.map(children, (item, index) => {
          // @ts-ignore
          if (children.length - 1 === index) {
            return {
              ...item,
              props: {
                ...item.props,
                isLast: true,
              },
            }
          }

          return item
        })}
      </StyledContainer>
    )
  }

  return (
    <StyledContainer>
      {{
        ...children,
        props: {
          ...children.props,
          isLast: true,
        },
      }}
    </StyledContainer>
  )
}

export default Timeline

