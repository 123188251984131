import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'

import { EVENTS, logEvent } from 'analytics'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withObservationsService } from 'services/legacy/observations'

import ObservationsExportModalView from './ObservationsExportModalView'
import { FORM_NAME } from './components/ObservationsExportForm'

export const ALL_KEY_PERSONS = {
  label: 'All key persons',
  value: null,
}

export const ALL_CHILDREN = {
  label: 'All children',
  value: null,
}

class ObservationsExportModalContainer extends Component {
  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSubmitSuccess = () => {
    const { modalActions, modalConsts } = this.props

    logEvent(EVENTS.OBSERVATION_LIST_EXPORTED)

    modalActions.show(modalConsts.TYPES.ALERT, {
      text: 'Your observations have been sent to your manager’s email address. This may take a few minutes.',
    })
  }

  handleSubmit = (fields) => {
    const { child, observationsActions, observationsSelectors } = this.props

    const criteria = observationsSelectors.getExportCriteria({ child, fields })

    observationsActions.exportReport({ criteria }, this.handleSubmitSuccess)
  }

  render() {
    const {
      child,
      errorMessages,
      isExporting,
      keyPerson,
      room,
    } = this.props

    const isRoomsDisabled = !!keyPerson
    const isKeyPersonsDisabled = !!room

    return (
      <ObservationsExportModalView
        child={child}
        errorMessages={errorMessages}
        isKeyPersonsDisabled={isKeyPersonsDisabled}
        isRoomsDisabled={isRoomsDisabled}
        isSubmitting={isExporting}
        onCloseClick={this.handleCloseClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, { appSelectors, observationsListState, observationsSelectors }) => ({
  errorMessages: appSelectors.getErrorMessages(observationsListState),
  isExporting: observationsSelectors.getObservationsIsExporting(state),
  keyPerson: formValueSelector(FORM_NAME)(state, 'keyPerson'),
  room: formValueSelector(FORM_NAME)(state, 'room'),
})

const enhance = compose(
  withAppService,
  withModalService,
  withObservationsService,
  connect(mapState),
)

export default enhance(ObservationsExportModalContainer)
