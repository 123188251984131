import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getFormValues, stopSubmit } from 'redux-form'

import { FEATURE_FLAGS, ROLES } from 'constants/security'

import { getBackendErrors } from 'utils/backendErrors'

import { EVENTS, logEvent } from 'analytics'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withLeaveNotesService } from 'services/legacy/leaveNotes'
import { withPaginationUtils } from 'services/utils/pagination'
import { withSecurityService } from 'services/security'
import { withRouter } from 'services/router'

import withStaffHoc from 'module/Staff/withStaffHoc'

import EntitlementNotesView from './EntitlementNotesView'
import { ENTITLEMENT_NOTES_FORM } from './components/EntitlementNotesForm'

const GROUPS = {
  read: [
    'leaveNote.author',
    'leaveNote.membership',
    'membership',
  ],
}

class EntitlementNotes extends Component {
  constructor(props) {
    super(props)

    const { paginationUtils: { setPageLocationQuery } } = props

    this.state = {
      formEnabled: false,
    }

    setPageLocationQuery(false)
  }

  componentDidMount() {
    this.fetch()
  }

  fetch = () => {
    const {
      currentUser,
      isMyDetailsContext,
      leaveNotesActions,
      paginationUtils: { page },
      params: { userId },
    } = this.props

    const criteria = [{
      field: 'membership',
      value: userId || (isMyDetailsContext && currentUser.membership.id),
    }]

    leaveNotesActions.list({
      mergeResult: 1 !== page,
      params: {
        criteria,
        groups: GROUPS,
        page,
      },
    })
  }

  enableForm = () => {
    logEvent(EVENTS.STAFF_LEAVE_ENTITLEMENT_ADD_BTN_CLICKED)

    this.setState({ formEnabled: true })
  }

  disableForm = () => {
    this.setState({ formEnabled: false })
  }

  handleSubmitFailed = (response) => {
    const { injectValidation } = this.props
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    return injectValidation(errors)
  }

  handleSubmitSuccess = () => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    logEvent(EVENTS.STAFF_LEAVE_ENTITLEMENT_ADDED)

    onPageChange(this.fetch)(1)
    this.disableForm()
  }

  handleSubmit = () => {
    const {
      currentUser,
      formValues,
      isMyDetailsContext,
      leaveNotesActions,
      leaveNotesSelectors,
      params: { userId },
    } = this.props

    const payload = leaveNotesSelectors.getPayload(
      formValues,
      userId || (isMyDetailsContext && currentUser.membership.id),
    )

    leaveNotesActions.create({
      onFailed: this.handleSubmitFailed,
      onSuccess: this.handleSubmitSuccess,
      params: { groups: GROUPS },
      payload,
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  render() {
    const {
      isFetching,
      isSubmitting,
      leaveNotes,
      paginationUtils,
      totalResults,
    } = this.props
    const { formEnabled } = this.state
    const { getPageCount, page } = paginationUtils

    const pageCount = getPageCount(totalResults)

    return (
      <EntitlementNotesView
        formEnabled={formEnabled}
        isFetching={isFetching}
        isSubmitting={isSubmitting}
        leaveNotes={leaveNotes}
        page={page}
        pageCount={pageCount}
        onAddNoteClick={this.enableForm}
        onCancelClick={this.disableForm}
        onPageChange={this.handlePageChange}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

EntitlementNotes.authParams = {
  flags: [FEATURE_FLAGS.STAFF_REGISTER],
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

const mapState = (state, {
  appSelectors,
  leaveNotesListState,
  leaveNotesSelectors,
  leaveNotesSingleState,
  securitySelectors,
}) => ({
  currentUser: securitySelectors.getAuthUser(state),
  formValues: getFormValues(ENTITLEMENT_NOTES_FORM)(state),
  isFetching: appSelectors.getIsFetching(leaveNotesListState),
  isSubmitting: appSelectors.getIsSubmitting(leaveNotesSingleState),
  leaveNotes: leaveNotesSelectors.getLeaveNotesDataSelector(state),
  totalResults: appSelectors.getTotalResults(leaveNotesListState),
})

const mapDispatch = {
  injectValidation: (errors = {}) => stopSubmit(ENTITLEMENT_NOTES_FORM, errors),
}

const enhance = compose(
  withAppService,
  withModalService,
  withLeaveNotesService,
  withPaginationUtils,
  withRouter,
  withSecurityService,
  withStaffHoc,
  connect(mapState, mapDispatch),
)

export default enhance(EntitlementNotes)
