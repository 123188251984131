import { QueryPropsV2 } from 'constants/service'

import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

import { BatchBody, Period } from './models'

class PeriodsApiClient extends ServiceBase {
  list = (params: QueryPropsV2) => this.request({
    path: `/v2/periods?${getQueryStringV2(params)}`,
  })

  batch = (body: BatchBody) => this.request({
    body,
    method: 'POST',
    path: '/v2/batch/period',
  })

  get = (periodId: number) => this.request({
    path: `/v2/periods/${periodId}`,
  })

  create = (body: Period) => this.request({
    body,
    method: 'POST',
    path: '/v2/periods',
  })

  update = (periodId: number, body: Period) => this.request({
    body,
    method: 'PUT',
    path: `/v2/periods/${periodId}`,
  })

  remove = (periodId: number) => this.request({
    method: 'DELETE',
    path: `/v2/periods/${periodId}`,
  })
}

export default PeriodsApiClient
