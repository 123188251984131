import _ from 'lodash'

import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  ADD_CHILDREN_TO_OBSERVATION,
  AUTOSAVE_OBSERVATION,
  AUTOSAVE_OBSERVATION_FAILED,
  AUTOSAVE_OBSERVATION_SUCCESS,
  CLEAR_OBSERVATION,
  CREATE_OBSERVATION,
  CREATE_OBSERVATION_FAILED,
  CREATE_OBSERVATION_SUCCESS,
  GET_OBSERVATION,
  GET_OBSERVATION_FAILED,
  GET_OBSERVATION_SUCCESS,
  REMOVE_CHILDREN_FROM_OBSERVATION,
  REMOVE_OBSERVATION,
  REMOVE_OBSERVATION_FAILED,
  REMOVE_OBSERVATION_SUCCESS,
  SET_NEW_OBSERVATION,
  UPDATE_DRAFT_OBSERVATION,
  UPDATE_DRAFT_OBSERVATION_FAILED,
  UPDATE_DRAFT_OBSERVATION_SUCCESS,
  UPDATE_OBSERVATION,
  UPDATE_OBSERVATION_FAILED,
  UPDATE_OBSERVATION_SUCCESS,
  UPDATE_REVIEW_OBSERVATION,
  UPDATE_REVIEW_OBSERVATION_FAILED,
  UPDATE_REVIEW_OBSERVATION_SUCCESS,
} from './constants'
import { REMOVE_MONTESSORI_ACTIVITIES_IN_CHILD_OBSERVATION_SUCCESS } from '../../childObservations/single/constants'

const initialState = {
  ...getBasicSingleInitialState,
  autosave: false,
  data: {
    childObservations: [],
  },
  isSubmittingAsDraft: false,
  isSubmittingAsReview: false,
}

export default (...props) => {
  const [state = initialState, { error, payload, type }] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_OBSERVATION,
    createFailed: CREATE_OBSERVATION_FAILED,
    createInit: CREATE_OBSERVATION,
    createSuccess: CREATE_OBSERVATION_SUCCESS,
    itemFailed: GET_OBSERVATION_FAILED,
    itemInit: GET_OBSERVATION,
    itemSuccess: GET_OBSERVATION_SUCCESS,
    removeFailed: REMOVE_OBSERVATION_FAILED,
    removeInit: REMOVE_OBSERVATION,
    removeSuccess: REMOVE_OBSERVATION_SUCCESS,
    updateFailed: UPDATE_OBSERVATION_FAILED,
    updateInit: UPDATE_OBSERVATION,
    updateSuccess: UPDATE_OBSERVATION_SUCCESS,
  }, initialState, props)

  if (UPDATE_DRAFT_OBSERVATION === type) {
    return {
      ...state,
      error: null,
      isSubmittingAsDraft: true,
    }
  }

  if (UPDATE_DRAFT_OBSERVATION_FAILED === type) {
    return {
      ...state,
      error,
      isSubmittingAsDraft: false,
    }
  }

  if (UPDATE_DRAFT_OBSERVATION_SUCCESS === type) {
    return {
      ...state,
      data: payload.data,
      isSubmittingAsDraft: false,
    }
  }

  if (UPDATE_REVIEW_OBSERVATION === type) {
    return {
      ...state,
      error: null,
      isSubmittingAsReview: true,
    }
  }

  if (UPDATE_REVIEW_OBSERVATION_FAILED === type) {
    return {
      ...state,
      error,
      isSubmittingAsReview: false,
    }
  }

  if (UPDATE_REVIEW_OBSERVATION_SUCCESS === type) {
    return {
      ...state,
      data: payload.data,
      isSubmittingAsReview: false,
    }
  }

  if (AUTOSAVE_OBSERVATION === type) {
    return {
      ...state,
      autosave: true,
      error: null,
    }
  }

  if (AUTOSAVE_OBSERVATION_FAILED === type) {
    return {
      ...state,
      autosave: false,
      error,
    }
  }

  if (AUTOSAVE_OBSERVATION_SUCCESS === type) {
    return {
      ...state,
      autosave: false,
      data: payload.data,
    }
  }

  if (ADD_CHILDREN_TO_OBSERVATION === type) {
    return {
      ...state,
      data: {
        ...state.data,
        childObservations: payload,
      },
    }
  }

  if (SET_NEW_OBSERVATION === type) {
    return {
      ...state,
      data: payload,
    }
  }

  if (REMOVE_CHILDREN_FROM_OBSERVATION === type) {
    return {
      ...state,
      data: {
        ...state.data,
        childObservations: _.filter(state.data.childObservations, ({ child }) => child.id !== payload),
      },
    }
  }

  if (REMOVE_MONTESSORI_ACTIVITIES_IN_CHILD_OBSERVATION_SUCCESS === type) {
    return {
      ...state,
      data: {
        ...state.data,
      },
    }
  }

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
