import i18n from 'translations'

export const DISCOUNT_TYPES = {
  DISCOUNT_APPLIED: 'DISCOUNT_APPLIED',
  DISCOUNT_NOT_APPLIED: 'DISCOUNT_NOT_APPLIED',
}

export const DISCOUNT_OPTIONS = [
  {
    label: 'Yes',
    tooltip: 'Selecting ‘yes’ will reduce either the hours parents are charged for, '
      .concat('or the total amount they are charged (depending on the options you select below). ')
      .concat('Please note: You cannot allocate two deductible funding types for one child in the same period.\n\n')
      .concat('Selecting ‘no’ means that this funding type will not deduct hours or the total amount from ')
      .concat('(but you will still have the option to show it as a line item on invoices).'),
    value: DISCOUNT_TYPES.DISCOUNT_APPLIED,
  },
  {
    label: 'No',
    value: DISCOUNT_TYPES.DISCOUNT_NOT_APPLIED,
  },
]

export const FUNDING_FEES_TYPES = {
  CHEAPEST_SESSIONS: 'cheapestSessions',
  MANUAL_HOURS: 'manuallyAllocate',
  SUBTRACT_AMOUNT_FROM_INVOICE: 'subtract',
}

export const FUNDING_FEES_OPTIONS = [
  {
    label: 'Manually allocate funded hours to sessions',
    tooltip: 'This option will allow you to manually allocate the funded hours to specific sessions in the '
      .concat('funding section within the child profile.Once these hours have been manually allocated, ')
      .concat('the hours can be invoiced.'),
    value: FUNDING_FEES_TYPES.MANUAL_HOURS,
  },
  {
    label: 'Subtract funding amount from invoice',
    tooltip: 'This option will subtract the amount allocated for funding for the '.concat(
      'time period and charge the parent for the remainder.',
    ),
    value: FUNDING_FEES_TYPES.SUBTRACT_AMOUNT_FROM_INVOICE,
  },
  {
    label: 'Allocate funded hours to monthly cheapest sessions',
    tooltip: 'This option will calculate the number of funded hours to be allocated per invoice period, '.concat(
      'subtract the cheapest hours and charge the parent for the remainder.',
    ),
    value: FUNDING_FEES_TYPES.CHEAPEST_SESSIONS,
  },
]

export const FUNDING_CALCULATION_TYPES = {
  ACTUAL_SESSIONS: 'ACTUAL_SESSIONS',
  AVERAGE_COST: 'AVERAGE_COST',
}

export const FUNDING_CALCULATION_OPTIONS = [
  {
    label: 'Actuals based on number of open days in month',
    tooltip: 'This option will proportionally allocate funding for your invoice period,'
      .concat(' based upon the number of days the nursery is open for the time period. ')
      .concat('Nursery closure dates are not included for this calculation.'),
    value: FUNDING_CALCULATION_TYPES.ACTUAL_SESSIONS,
  },
  {
    label: 'Average',
    tooltip: 'This option will equally divide out the total funding hours/amount across the '
      .concat('different months covered by the funding period. You must select full months for this option ')
      .concat('to be applicable – partial months being select will mean that funding revert to being calculated ')
      .concat('on ‘actuals’ (the option above) rather than this option.'),
    value: FUNDING_CALCULATION_TYPES.AVERAGE_COST,
  },
]

export const FUNDING_INVOICE_DEDUCTION_TYPES = {
  INVOICE_TOTAL: 'INVOICE_TOTAL',
  LINE_ITEM: 'LINE_ITEM',
}

export const FUNDING_INVOICE_DEDUCTION_OPTIONS = [
  {
    fundingFeesTypes: [FUNDING_FEES_TYPES.CHEAPEST_SESSIONS, FUNDING_FEES_TYPES.MANUAL_HOURS],
    label: 'Funding deducted from invoice total',
    tooltip: 'This will show the a break down of the original session cost prior to Funding being deducted.',
    value: FUNDING_INVOICE_DEDUCTION_TYPES.INVOICE_TOTAL,
  },
  {
    fundingFeesTypes: [FUNDING_FEES_TYPES.CHEAPEST_SESSIONS, FUNDING_FEES_TYPES.MANUAL_HOURS],
    label: 'Funding deducted from session line items',
    tooltip: 'This will show the cost of the Session after Funding has already been deducted.',
    value: FUNDING_INVOICE_DEDUCTION_TYPES.LINE_ITEM,
  },
]

export const FUNDING_LINE_ITEM_TYPES = {
  DO_NOT_SHOW: 'DO_NOT_SHOW',
  NAME_WITHOUT_AMOUNT: 'NAME_WITHOUT_AMOUNT',
  NAME_WITH_AMOUNT: 'NAME_WITH_AMOUNT',
  NUMBER_OF_HOURS: 'NUMBER_OF_HOURS',
}

export const FUNDING_LINE_ITEM_OPTIONS = [
  {
    fundingFeesTypes: [FUNDING_FEES_TYPES.CHEAPEST_SESSIONS, FUNDING_FEES_TYPES.MANUAL_HOURS],
    label: i18n.t('services:NurseryFundingV3:FundingLineItem:ShowHours.label'),
    tooltip: i18n.t('services:NurseryFundingV3:FundingLineItem:ShowHours.tooltip'),
    value: FUNDING_LINE_ITEM_TYPES.NUMBER_OF_HOURS,
  },
  {
    fundingFeesTypes: [FUNDING_FEES_TYPES.CHEAPEST_SESSIONS, FUNDING_FEES_TYPES.MANUAL_HOURS],
    label: i18n.t('services:NurseryFundingV3:FundingLineItem:HideHours.label'),
    tooltip: i18n.t('services:NurseryFundingV3:FundingLineItem:HideHours.tooltip'),
    value: FUNDING_LINE_ITEM_TYPES.NAME_WITHOUT_AMOUNT,
  },
  {
    fundingFeesTypes: [FUNDING_FEES_TYPES.SUBTRACT_AMOUNT_FROM_INVOICE],
    label: i18n.t('services:NurseryFundingV3:FundingLineItem:ShowValue.label'),
    tooltip: i18n.t('services:NurseryFundingV3:FundingLineItem:ShowValue.tooltip'),
    value: FUNDING_LINE_ITEM_TYPES.NAME_WITH_AMOUNT,
  },
  {
    fundingFeesTypes: [FUNDING_FEES_TYPES.SUBTRACT_AMOUNT_FROM_INVOICE],
    label: i18n.t('services:NurseryFundingV3:FundingLineItem:HideValue.label'),
    tooltip: i18n.t('services:NurseryFundingV3:FundingLineItem:HideValue.tooltip'),
    value: FUNDING_LINE_ITEM_TYPES.NAME_WITHOUT_AMOUNT,
  },
  {
    label: i18n.t('services:NurseryFundingV3:FundingLineItem:DoNotShow.label'),
    tooltip: i18n.t('services:NurseryFundingV3:FundingLineItem:DoNotShow.tooltip'),
    value: FUNDING_LINE_ITEM_TYPES.DO_NOT_SHOW,
  },
]

export const FUNDING_FILTERS = {
  ACTIVE: 'ACTIVE',
  ALL: 'ALL',
  ARCHIVED: 'ARCHIVED',
}

export const FUNDING_FILTER_OPTIONS = [
  { label: i18n.t('global:Active'), value: FUNDING_FILTERS.ACTIVE },
  { label: 'All funding', value: FUNDING_FILTERS.ALL },
  { label: i18n.t('global:Archived'), value: FUNDING_FILTERS.ARCHIVED },
]

export const TYPES = {
  ...FUNDING_FEES_TYPES,
  NO_DEDUCT: 'noDeduct',
}
