import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getFormInitialValues, getFormValues } from 'redux-form'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { FEATURE_FLAGS, ROLES } from 'constants/security'
import { NURSERY_SESSIONS_FILTER } from 'services/nurserySessions/constants'
import { Type } from 'services/booking/periods/models'

import { generateRoute } from 'utils/routing'
import auth from 'utils/auth'
import eventBus from 'utils/eventBus'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withModalService } from 'services/utils/modal'
import { withNurseriesService } from 'services/nurseries'
import { withChildSessionsService } from 'services/legacy/childSessions'
import { withChildrenSessionsService } from 'services/legacy/childrenSessions'
import { withNurserySessionsService } from 'services/nurserySessions'
import { withPeriodsService } from 'services/booking/periods'
import { withRouter } from 'services/router'

import i18n from 'translations'

import ChildSessionsAddView from './ChildSessionsAddView'
import { FORM_NAME } from './components/AddChildSessionsForm'
import { UPDATE_CHILD_SESSION_SETUP } from '../../../ChildContainer'

const CHILD_SESSIONS_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings',
    'nurserySettings.invoice',
    'nurseryInvoiceSettings',
  ],
}

const CHILDREN_SESSIONS_GROUPS = {
  read: [
    'childSession.invoiced',
    'childSession.fundingAssigned',
  ],
}

const CURRENT_MODE = {
  CREATING: 'Create',
  DELETING: 'Delete',
  EDITING: 'Edit',
}

class ChildSessionsAddContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      periodOption: null,
    }
  }

  componentDidMount() {
    const {
      childSessionsActions,
      isEdit,
      nurseriesActions,
      nurseryOptions,
      nurserySessionsActions,
      nurserySessionsSelectors,
      params,
      periodsActions,
      periodsSelectors,
    } = this.props
    const { childId, sessionId } = params

    if (isEdit) {
      childSessionsActions.get(childId, sessionId, { includes: ['attendancePeriod', 'invoiced', 'fundingAssigned'] })
    }

    const apiParams = { groups: CHILD_SESSIONS_GROUPS }
    nurseriesActions.get(nurseryOptions.id, { params: apiParams })

    nurserySessionsActions.list({
      params: {
        criteria: nurserySessionsSelectors.getCriteria({ statusFilter: NURSERY_SESSIONS_FILTER.ACTIVE }),
      },
      recursively: true,
    })

    const criteria = periodsSelectors.getCriteria({
      archived: 0,
      type: Type.attendance,
    })

    periodsActions.list({
      onSuccess: (response) => {
        this.setState({
          periodOption: response?.data?.length ? {
            label: response?.data?.[0]?.name,
            value: response?.data?.[0]?.id,
          } : [],
        })
      },
      onlyData: true,
      params: {
        criteria,
        limit: 1,
        order: {
          sortField: 'name',
          sortOrder: 'ASC',
        },
      },
    })
  }

  componentWillUnmount() {
    const {
      childSessionsActions,
      nurserySessionsActions,
    } = this.props

    childSessionsActions.clearSingle()
    nurserySessionsActions.clear()
  }

  handleAddItemClick = (fields) => () => {
    fields.push({})
  }

  handleDeleteItemClick = (fields, index) => () => {
    fields.remove(index)
  }

  handleSubmitSuccess = (redirectToAutoCreditNote, fields = {}, callback) => () => {
    const {
      child,
      formInitialValues,
      navigate,
    } = this.props
    const { id: childId } = child

    // eslint-disable-next-line no-unused-expressions
    callback?.()

    if (redirectToAutoCreditNote) {
      const { endDate: initialEndDate, isOngoing: initialIsOngoing, startDate: initialStartDate } = formInitialValues
      const { endDate, isOngoing, startDate } = fields

      const paramStartDate = moment(_.min([startDate, initialStartDate])).format(DEFAULT_DATE_FORMAT)
      const paramEndDate = isOngoing || initialIsOngoing
        ? ''
        : moment(_.max([endDate, initialEndDate])).format(DEFAULT_DATE_FORMAT)
      const queryParam = `startDate=${paramStartDate}&endDate=${paramEndDate}`

      return navigate(
        `${generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.AUTO_CREDIT_NOTING', { childId })}?${queryParam}`,
      )
    }

    return navigate(generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS', { childId }))
  }

  handleConflict = (cb) => {
    const { modalActions, modalConsts } = this.props

    // eslint-disable-next-line no-unused-expressions
    cb?.()

    modalActions.show(modalConsts.TYPES.ALERT, {
      icon: 'warning',
      text: i18n.t('module:Children:Child:BookingPattern:Sessions:Add:dateRangeConflictCopy'),
    })
  }

  displayImpactModal = ({ cb, currentMode, data }) => {
    const { AutoCreditNotingAccess, child, formValues, modalActions, modalConsts } = this.props
    const { firstName: childFirstName } = child

    return modalActions.show(modalConsts.TYPES.SESSION_SET_IMPACT, {
      autoCreditNotingEnabled: AutoCreditNotingAccess,
      childFirstName,
      currentMode,
      currentSession: formValues,
      onSave: (callback) => cb(AutoCreditNotingAccess, callback),
      otherImpactedSession: data,
    })
  }

  handleCheckOverlappingSessionsSuccess = (
    { data },
    currentMode,
    cb,
    isCurrentSessionInvoicedOrFundingAssigned,
  ) => {
    if (!data?.length && !isCurrentSessionInvoicedOrFundingAssigned) {
      return cb()
    }

    return this.displayImpactModal({ cb, currentMode, data })
  }

  checkOverlappingSessions = ({
    cb,
    currentMode,
    fields,
    isCurrentSessionInvoicedOrFundingAssigned,
    isDateFieldsChanged = true,
  }) => {
    if (!isDateFieldsChanged) {
      cb()

      return
    }

    const {
      child,
      childrenSessionsActions,
      childrenSessionsSelectors,
      params,
    } = this.props

    const { endDate, isOngoing, startDate } = fields

    childrenSessionsActions.list({
      onSuccess: (response) => this.handleCheckOverlappingSessionsSuccess(
        response,
        currentMode,
        cb,
        isCurrentSessionInvoicedOrFundingAssigned,
      ),
      params: {
        criteria: childrenSessionsSelectors.getOverlappingSessionsCriteria({
          childId: child.id,
          endDate,
          isOngoing,
          sessionId: currentMode === CURRENT_MODE.CREATING ? undefined : params.sessionId,
          startDate,
        }),
        groups: CHILDREN_SESSIONS_GROUPS,
      },
    })
  }

  handleSubmit = (fields) => {
    const {
      child,
      childSession,
      childSessionsActions,
      childSessionsSelectors,
      formInitialValues,
      isEdit,
      isInvoiceAssigned,
      modalActions,
      modalConsts,
      params,
    } = this.props
    const { fundingAssigned, invoiced } = childSession || {}

    const isSessionEmpty = childSessionsSelectors.isSessionsEmpty(fields)

    if (isSessionEmpty) {
      return modalActions.show(modalConsts.TYPES.ALERT, {
        icon: 'warning',
        text: i18n.t('module:Children:Child:BookingPattern:Sessions:Add:emptySessionCopy'),
      })
    }

    const payload = childSessionsSelectors.getPayload({
      ...fields,
      isInvoiceAssigned,
    })

    if (isEdit) {
      const isDateFieldsChanged = childSessionsSelectors.isDateFieldChangedSelector({ fields, formInitialValues })

      const updateSession = (redirectToAutoCreditNote, callback) => {
        childSessionsActions.update(
          child.id,
          params.sessionId,
          payload,
          this.handleSubmitSuccess(redirectToAutoCreditNote, fields, callback),
          () => this.handleConflict(callback),
        )
      }

      return this.checkOverlappingSessions({
        cb: updateSession,
        currentMode: CURRENT_MODE.EDITING,
        fields,
        isCurrentSessionInvoicedOrFundingAssigned: (invoiced || fundingAssigned),
        isDateFieldsChanged,
      })
    }

    const createSession = (redirectToAutoCreditNote) => {
      childSessionsActions.create(
        child.id,
        payload,
        this.handleSubmitSuccess(redirectToAutoCreditNote, fields),
        this.handleConflict,
      )
    }

    return this.checkOverlappingSessions({
      cb: createSession,
      currentMode: CURRENT_MODE.CREATING,
      fields,
    })
  }

  handleDeleteButtonClick = () => {
    const {
      childSession,
      childrenSessionsActions,
      isFundingAssigned,
      isInvoiceAssigned,
      modalActions,
      modalConsts,
      params,
    } = this.props

    const remove = (redirectToAutoCreditNote) => childrenSessionsActions.update(
      params.sessionId,
      { archived: true },
      {
        onSuccess: () => {
          eventBus.dispatch(UPDATE_CHILD_SESSION_SETUP)
          this.handleSubmitSuccess(redirectToAutoCreditNote, childSession)()
        },
      },
    )

    if (isFundingAssigned || isInvoiceAssigned) {
      return this.checkOverlappingSessions({
        cb: remove,
        currentMode: CURRENT_MODE.DELETING,
        fields: childSession,
        isCurrentSessionInvoicedOrFundingAssigned: (isFundingAssigned || isInvoiceAssigned),
      })
    }

    return modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'trash',
      onConfirm: remove,
      text: i18n.t('module:Children:Child:BookingPattern:Sessions:Add:deletePopupCopy'),
    })
  }

  render() {
    const {
      SessionCalculationAccess,
      child,
      childSessionsConstants,
      childSessionsListState,
      childSessionsSelectors,
      childSessionsSingleState,
      errorMessages,
      isEdit,
      isInvoiceAssigned,
      isSessionAverageCostSelected,
      nurserySessionsOptions,
      nurserySettingsBase,
      sessionCalculationsOptions,
    } = this.props
    const { periodOption } = this.state

    const { data: childSession } = childSessionsSingleState
    const { nurserySettings } = nurserySettingsBase || {}
    const { formattedOpeningDays } = nurserySettings || {}

    const initialValues = childSessionsSelectors.getInitialValues({
      childSession,
      formattedOpeningDays,
      isEdit,
      isSessionAverageCostSelected,
      periodOption,
      sessionCalculationEnabled: SessionCalculationAccess,
    })
    const isLoading = !initialValues || !child
    const isFormLoading = childSessionsSingleState.isSubmitting || childSessionsListState.isFetching

    return (
      <ChildSessionsAddView
        SessionCalculationAccess={SessionCalculationAccess}
        child={child}
        childSessionsConstants={childSessionsConstants}
        childSessionsSelectors={childSessionsSelectors}
        errorMessages={errorMessages}
        initialValues={initialValues}
        isEdit={isEdit}
        isFormLoading={isFormLoading}
        isInvoiceAssigned={isInvoiceAssigned}
        isLoading={isLoading}
        nurserySessionsOptions={nurserySessionsOptions}
        openingDays={formattedOpeningDays}
        sessionCalculationOptions={sessionCalculationsOptions}
        onAddItemClick={this.handleAddItemClick}
        onDeleteButtonClick={this.handleDeleteButtonClick}
        onDeleteItemClick={this.handleDeleteItemClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

ChildSessionsAddContainer.authParams = {
  roles: [
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.SUPER_ADMIN,
  ],
}

const mapState = (state, {
  appSelectors,
  childSelectors,
  childSessionsSelectors,
  childSessionsSingleState,
  nurseriesSelectors,
  nurserySessionsListState,
  params,
}) => ({
  AutoCreditNotingAccess: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.AUTO_CREDIT_NOTING],
  }),
  SessionCalculationAccess: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.FINANCE_AUTOMATION],
  }),
  child: childSelectors.getChildSelector(state),
  childSession: childSessionsSelectors.getChildSessionsSingleData(state),
  errorMessages: appSelectors.getErrorMessages(childSessionsSingleState, nurserySessionsListState),
  formInitialValues: getFormInitialValues(FORM_NAME)(state),
  formValues: getFormValues(FORM_NAME)(state),
  isEdit: !!params.sessionId,
  isFundingAssigned: childSessionsSelectors.isFundingAssigned(state),
  isInvoiceAssigned: childSessionsSelectors.isInvoiceAssigned(state),
  isSessionAverageCostSelected: nurseriesSelectors.isSessionAverageCostSelected(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  nurserySessionsOptions: childSessionsSelectors.getNurserySessionsOptions(state),
  nurserySettingsBase: nurseriesSelectors.getNurseryData(state),
  sessionCalculationsOptions: childSessionsSelectors.getSessionCalculationsOptions(state),
})

const enhance = compose(
  withRouter,
  withAppService,
  withChildService,
  withModalService,
  withNurseriesService,
  withChildSessionsService,
  withChildrenSessionsService,
  withNurserySessionsService,
  withPeriodsService,
  connect(mapState),
)

export default enhance(ChildSessionsAddContainer)
