import React from 'react'

import { Callout, ModalBox } from 'components'

import i18n from 'translations'

import ExportModalForm from './components/ExportModalForm'
import { getTransPrefix } from './ExportModalHelpers'

const ExportModalView = ({
  errorMessages,
  initialValues,
  isSubmitting,
  moduleType,
  onCancelClick,
  onSubmit,
}) => (
  <ModalBox
    maxWidth={650}
    title={i18n.t(`${getTransPrefix(moduleType)}title`)}
    autoHeight
    onCloseClick={onCancelClick}
  >
    <Callout content={errorMessages} error />
    <ExportModalForm
      initialValues={initialValues}
      isSubmitting={isSubmitting}
      moduleType={moduleType}
      onCancelClick={onCancelClick}
      onSubmit={onSubmit}
    />
  </ModalBox>
)

export default ExportModalView
