import _ from 'lodash'

import React, { Component } from 'react'

import { FLAG_COLOURS } from 'constants/colors'
import { ERROR_CODE_MAPPINGS } from 'constants/errorMappings'

import { Icon } from 'components'

import Info from '../Info'

import {
  StyledContainer,
  StyledErrorContainer,
  StyledErrorItemContainer,
  StyledErrorTextContainer,
  StyledTitleContainer,
} from './ErrorSummaryStyled'

class ErrorSummary extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  handleTitleClick = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }))
  }

  getError = (error) => {
    const { code, extra, message } = error || {}

    if (extra) {
      return _.values(extra)
    }

    const customMessage = ERROR_CODE_MAPPINGS[code]

    return [customMessage || message]
  }

  renderErrors = () => {
    const { errors } = this.props
    const { isOpen } = this.state

    if (!isOpen) {
      return null
    }

    return (
      <StyledErrorContainer>
        {_.map(errors, ({ exception }, index) => {
          const { error } = exception || {}
          const displayErrors = this.getError(error)

          return (
            <StyledErrorItemContainer key={index}>
              <StyledErrorTextContainer>
                {_.map(displayErrors, (errorItem, itemIndex) => (
                  <div key={`item-${itemIndex}`}>
                    {errorItem}
                  </div>
                ))}
              </StyledErrorTextContainer>
            </StyledErrorItemContainer>
          )
        })}
      </StyledErrorContainer>
    )
  }

  render() {
    const { errors } = this.props
    const { isOpen } = this.state

    if (!errors || !errors.length) {
      return null
    }

    return (
      <StyledContainer>
        <StyledTitleContainer onClick={this.handleTitleClick}>
          <div>
            <Info color={FLAG_COLOURS.ERROR} text={`Unresolved issues (${errors.length})`} />
          </div>
          <div>
            <Icon height={10} icon={isOpen ? 'chevron-up' : 'chevron-down'} />
          </div>
        </StyledTitleContainer>
        {this.renderErrors()}
      </StyledContainer>
    )
  }
}

export default ErrorSummary
