import React from 'react'

import { Callout, Page, Section, Spinner } from 'components'

import i18n from 'translations'

import StaffContractsAddForm from './components/StaffContractsAddForm'

const StaffContractsAddView = ({
  errorMessages,
  isCreatingContext,
  isFetching,
  onRemoveClick,
  ...rest
}) => {
  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    return (
      <StaffContractsAddForm
        isCreatingContext={isCreatingContext}
        {...rest}
      />
    )
  }
  const actions = !isCreatingContext && (
    <Section.Actions
      options={[{
        onClick: onRemoveClick,
        type: 'delete',
      }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isFetching}
      title={isCreatingContext
        ? i18n.t('module:Staff:StaffContracts:Add:title')
        : i18n.t('module:Staff:StaffContracts:Edit:title')}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default StaffContractsAddView
