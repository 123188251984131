import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired, isRequiredDateRange, isValidDateRange } from 'utils/fieldValidation'

import { Form } from 'components'

import i18n from 'translations'

import { getTransPrefix } from '../ExportModalHelpers'

export const ENQUIRY_EXPORT_FORM = 'enquiryExportForm'

const ExportModalForm = ({
  handleSubmit,
  isSubmitting,
  moduleType,
  onCancelClick,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row
      label={i18n.t(`${getTransPrefix(moduleType)}Form:Membership:label`)}
      verticalLabel
    >
      <Form.Row.Item>
        <Field
          component={Form.InfiniteDropdowns.OrganizationMemberships}
          name="membership"
          placeholder={i18n.t(`${getTransPrefix(moduleType)}Form:Membership:placeholder`)}
          validate={isRequired}
        />
      </Form.Row.Item>
    </Form.Row>
    <Form.Row
      label={i18n.t(`${getTransPrefix(moduleType)}Form:Dates:label`)}
      verticalLabel
    >
      <Form.Row.Item>
        <Field
          component={Form.DatePicker}
          name="dateRange"
          validate={[isRequiredDateRange, isValidDateRange]}
          clearable
          range
        />
      </Form.Row.Item>
    </Form.Row>
    <Form.FooterActions
      isSubmitting={isSubmitting}
      submitLabel={i18n.t('global:Export')}
      onCancelClick={onCancelClick}
    />
  </Form>
)

export default reduxForm({ form: ENQUIRY_EXPORT_FORM })(ExportModalForm)
