import styled from 'styled-components'

export const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 5px;
  
  @media(max-width: 740px) {
    grid-template-columns: repeat(5, 1fr);
  }
    
  @media(max-width: 640px) {
    grid-template-columns: repeat(4, 1fr);
  }
    
  @media(max-width: 540px) {
    grid-template-columns: repeat(3, 1fr);
  }
    
  @media(max-width: 440px) {
    grid-template-columns: repeat(2, 1fr);
  }  
`

export const StyledChild = styled.div`
  padding: 25px 10px;
`

export const StyledSubTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;  
`

export const StyledIcons = styled.div`
  display: flex;
`

export const StyledContent = styled.div`
  margin: -20px;
  padding: 20px;
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: -5px;
`
