import { createSelector } from 'reselect'
import moment from 'moment'
import _ from 'lodash'

import { NAPPY_WAS, NAPPY_WAS_OPTIONS } from '../constants'

export const getInitialValues = (params) => {
  const initialValues = {}
  const { child, date, nappyRash, record, type, wasCreamApplied } = params
  const { comments, recordedAt } = record || {}

  if (child) {
    const { firstName, id, photo, surname } = child

    initialValues.child = {
      avatar: photo,
      label: `${firstName} ${surname}`,
      value: id,
    }
  }

  if (comments) {
    initialValues.comments = comments
  }

  if (wasCreamApplied) {
    initialValues.wasCreamApplied = wasCreamApplied
  }

  if (nappyRash) {
    initialValues.nappyRash = nappyRash
  }

  if (type) {
    initialValues.type = type
  } else {
    initialValues.type = NAPPY_WAS.BOTH
  }

  if (recordedAt) {
    initialValues.recordedAt = moment(recordedAt)
  } else if (date) {
    initialValues.recordedAt = moment(date)
      .set('hour', moment().format('HH'))
      .set('minute', moment().format('mm'))
  }

  return initialValues
}

export const getValuesForm = (values) => {
  const body = {}
  const {
    child,
    comments,
    nappyRash = false,
    recordedAt,
    type,
    wasCreamApplied = false,
  } = values

  body.wasCreamApplied = wasCreamApplied
  body.comments = comments || ''
  body.nappyRash = nappyRash

  if (child) {
    body.child = child.value
  }

  if (type) {
    body.type = type
  }

  if (recordedAt) {
    body.recordedAt = moment(recordedAt).toISOString()
  }

  return body
}

const getTypeValue = (
  item,
) => _.get(_.find(NAPPY_WAS_OPTIONS, ({ value }) => item.type === value), 'label')

export const makeGetTypeValue = () => createSelector(
  [getTypeValue],
  (value) => value,
)
