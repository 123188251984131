import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'

const getNurseryIntegrationsStatisticsState = (state: RootState) => state.nurseryIntegrations.statistics

export const getPayoutsStatistics = createSelector(
  [getNurseryIntegrationsStatisticsState],
  ({ data }) => data,
)
