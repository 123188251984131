import styled from 'styled-components'

import layout from 'constants/layout'

interface StyledRemoveButtonWrapperProps {
  $first: boolean
}

export const StyledRemoveButtonWrapper = styled.div<StyledRemoveButtonWrapperProps>`
  display: flex;
  align-items: flex-start;
  padding-top: 5px;
  
  ${({ $first }) => $first && `
    padding-top: 46px;
    
    @media(max-width: ${layout.mobileWidthResolution}px) {
      padding-top: 35px;
    }
  `}           
`

interface StyledPriceChangesItemProps {
  $fieldsLength: number
}

export const StyledPriceChangesItem = styled.div<StyledPriceChangesItemProps>`
  display: grid;
  grid-template-columns: 140px 180px 180px auto;
  grid-column-gap: 5px;
  align-items: flex-start;
  
  & > div {
    width: 100%;
  }

  ${({ $fieldsLength }) => 1 === $fieldsLength && `
    grid-template-columns: 140px auto;
  `}
`

export const StyledSelectWrapper = styled.div`
  @media(max-width: ${layout.mobileWidthResolution}px) {
    & > div {
      margin-bottom: 25px;
    }
  }
`

export const StyledPriceChangesWrapper = styled.div`
  overflow: auto;
`
