import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

const getCursor = (isDisabled, hasTime) => {
  if (hasTime) {
    return 'default'
  }

  if (isDisabled) {
    return 'not-allowed'
  }

  return 'pointer'
}

export const StyledContainer = styled.div`
  cursor: ${({ hasTime, isDisabled }) => getCursor(isDisabled, hasTime)};
  min-width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ isDisabled }) => (isDisabled ? NEUTRAL_COLOURS.GRAY : 'inherit')};

  :hover {
    color: ${({ isDisabled }) => (isDisabled ? NEUTRAL_COLOURS.GRAY : 'inherit')};
  }

  @media(min-width: 768px) {
    min-width: 65px;
  }
`

export const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
`

export const StyledTime = styled.div`
  color: ${({ color }) => color};
  font-size: 22px;
  display: flex;
  align-items: center;
  font-weight: 600;
  
  @media(min-width: 768px) {
    font-size: 24px;
  }
`

export const StyledTitle = styled.div`
  font-size: 12px;
  line-height: 1;
  margin-top: 10px;
  height: 30px;
  width: 60px;
  text-align: center;
  font-weight: ${({ hasData }) => (hasData ? '600' : '400')};
  color: ${({ color, hasData }) => (hasData ? color : 'inherit')};

  @media(min-width: 768px) {
    font-size: 14px;
    width: 65px;
  }

  @media (min-width: 1024px) {
    width: 80px;
  }
`

export const StyledButtonContainer = styled.div`
  position: relative;
`

export const StyledBadgeContainer = styled.div`
  position: absolute;
  top: -1px;
  right: 3px;
  font-size: .11px;
  background: ${({ color }) => color};
  color: ${NEUTRAL_COLOURS.WHITE};
  width: 15px;
  height: 15px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  box-shadow: 0 0 1px #333;
`
