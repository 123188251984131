import React from 'react'

import { CHILD_LEAVE_TYPE_OPTIONS } from 'services/legacy/childAbsences/constants'

import { Callout, DatePicker, EmptyState, Page, Pagination, Section, Select, Spinner, Table, Toolbar } from 'components'

import i18n from 'translations'

const COLUMNS = [
  {
    align: 'left',
    field: 'absenceType',
    title: i18n.t('global:Type'),
  },
  {
    align: 'left',
    field: 'date',
    title: i18n.t('global:Date'),
  },
  {
    align: 'left',
    field: 'comments',
    title: i18n.t('global:Comment'),
  },
  {
    field: 'options',
    width: '10%',
  },
]

const ChildAbsencesView = ({
  data,
  dateRange,
  errorMessages,
  isFetching,
  onAddLeaveClick,
  onDateRangeChange,
  onPageChange,
  onTypeChange,
  page,
  pageCount,
  perPage,
  totalResults,
  type,
}) => {
  const renderPagination = () => (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('global:pagination:entries')}
      titleSingular={i18n.t('global:pagination:entry')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderHeader = () => (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <DatePicker
            value={dateRange}
            clearable
            range
            onChange={onDateRangeChange}
          />
        </Toolbar.Item>
        <Toolbar.Item>
          <Select
            options={CHILD_LEAVE_TYPE_OPTIONS}
            placeholder={i18n.t('global:AllEntries')}
            searchable={false}
            value={type}
            onChange={onTypeChange}
          />
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )

  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    if (!data?.length) {
      return (
        <EmptyState
          icon="absence"
          text1={i18n.t('module:Children:Child:About:Absences:emptyState')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={COLUMNS}
          data={data}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const actions = (
    <Section.Actions
      primary={[{
        label: i18n.t('module:Children:Child:About:Absences:addLeave'),
        onClick: onAddLeaveClick,
      }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isFetching}
      title={i18n.t('module:Children:Child:About:Absences:Title:long')}
    >
      <Callout content={errorMessages} error />
      {renderHeader()}
      {renderContent()}
    </Page.Section>
  )
}

export default ChildAbsencesView
