import _ from 'lodash'
import { Switch as SwitchMaterial } from '@material-ui/core'

import React, { PropsWithChildren, useEffect, useState } from 'react'

import { FieldError } from 'components'

import { StyledContainer } from './SwitchStyled'

export interface SwitchProps {
  children?: React.FC<PropsWithChildren<boolean>>
  disabled?: boolean
  error?: string | React.ReactNode
  onChange: (value: boolean) => void
  simple?: boolean
  value: boolean
}

const Switch: React.FC<PropsWithChildren<SwitchProps>> = ({ children, disabled, error, onChange, simple, value }) => {
  const finalValue = value || false
  const [checked, setChecked] = useState<boolean>(finalValue)

  useEffect(() => setChecked(finalValue), [finalValue])

  if (children && _.isFunction(children)) {
    return children(
      finalValue,
      onChange,
    )
  }

  const handleChange = () => {
    if (simple) {
      return onChange(!checked)
    }

    return setChecked((prev) => {
      if (onChange) {
        onChange(!prev)
      }

      return !prev
    })
  }

  return (
    <StyledContainer $disabled={disabled}>
      <SwitchMaterial
        checked={simple ? finalValue : checked}
        disabled={disabled}
        onChange={handleChange}
      />
      <FieldError error={error} />
    </StyledContainer>
  )
}

export default Switch
