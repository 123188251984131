import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change, getFormValues, reset } from 'redux-form'

import { DEPOSIT_TYPES } from 'services/legacy/deposits/constants'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withDepositsService } from 'services/legacy/deposits'
import { withModalService } from 'services/utils/modal'
import { withSnackbarService } from 'services/utils/snackbar'
import { withRouter } from 'services/router'

import i18n from 'translations'

import AddView from './AddView'
import { DEPOSITS_ADD_FORM } from './components/AddForm'

const DEPOSIT_GROUPS = {
  read: [
    'child',
    'deposit.child',
    'deposit.invoices',
    'deposit.lastInvoice',
    'deposit.refundDeposit',
    'invoice',
  ],
}

const REFUND_GROUPS = {
  read: [
    'child',
    'deposit.child',
    'deposit.invoices',
    'deposit.lastInvoice',
    'deposit.originalDeposit',
    'invoice',
  ],
}

class AddContainer extends Component {
  constructor(props) {
    super(props)

    const { location, params } = this.props
    const { childId, depositId } = params
    const { pathname } = location

    const isEdit = !!depositId
    const isRefund = pathname === generateRoute('FINANCE.DEPOSITS.REFUND', { depositId })
    || pathname === generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.DEPOSITS.REFUND', { childId, depositId })

    this.state = {
      groups: isRefund ? REFUND_GROUPS : DEPOSIT_GROUPS,
      isChildContext: !!childId,
      isEdit,
      isGeneratingDescription: false,
      isRefund,
      title: this.getTitle({ isEdit, isRefund }),
    }
  }

  componentDidMount() {
    const { params } = this.props
    const { isChildContext, isEdit } = this.state

    const { childId } = params

    if (isEdit) {
      return this.fetch()
    }

    if (isChildContext) {
      return this.handleGenerateDescription(childId)
    }

    return null
  }

  componentWillUnmount() {
    const { depositsActions } = this.props

    depositsActions.clearSingle()
  }

  getTitle = ({ isEdit, isRefund }) => {
    if (isRefund) {
      return i18n.t('module:Finance:Deposits:Form:Title:refund')
    }

    return isEdit
      ? i18n.t('module:Finance:Deposits:Form:Title:edit')
      : i18n.t('module:Finance:Deposits:Form:Title:add')
  }

  fetch = () => {
    const { depositsActions, params } = this.props
    const { groups } = this.state
    const { depositId } = params

    depositsActions.get({
      params: [depositId, { groups }],
    })
  }

  goToList = () => {
    const { navigate, params } = this.props
    const { isChildContext } = this.state
    const { childId } = params

    const route = isChildContext
      ? generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.DEPOSITS', { childId })
      : generateRoute('FINANCE.DEPOSITS')

    return navigate(route)
  }

  getTypeLabel = () => {
    const { isRefund } = this.state

    return isRefund
      ? i18n.t('module:Finance:Deposits:Form:Type:depositRefund')
      : i18n.t('module:Finance:Deposits:Form:Type:deposit')
  }

  handleUpdateSuccess = () => {
    const { snackbarActions } = this.props

    const action = i18n.t('module:Finance:Deposits:Form:Snackbar:Action:updated')
    const type = _.upperFirst(this.getTypeLabel())

    snackbarActions.show({
      message: i18n.t('module:Finance:Deposits:Form:Snackbar:message', { action, type }),
    })

    this.goToList()
  }

  handleCreateSuccess = () => {
    const { snackbarActions } = this.props

    const action = i18n.t('module:Finance:Deposits:Form:Snackbar:Action:created')
    const type = _.upperFirst(this.getTypeLabel())

    snackbarActions.show({
      message: i18n.t('module:Finance:Deposits:Form:Snackbar:message', { action, type }),
    })

    this.goToList()
  }

  handleSubmit = (values) => {
    const { depositsActions, depositsSelectors } = this.props
    const { isEdit } = this.state

    const body = depositsSelectors.getPayload({ isEdit, values })

    if (isEdit) {
      return depositsActions.update({
        body,
        onSuccess: this.handleUpdateSuccess,
        params: [values.id],
      })
    }

    return depositsActions.create({
      body,
      onSuccess: this.handleCreateSuccess,
      params: [{}],
    })
  }

  handleListChildDepositsSuccess = ({ meta }) => {
    const { changeField } = this.props

    changeField(
      'description',
      i18n.t('module:Finance:Deposits:Form:descriptionTemplate', { number: meta.total_results + 1 }),
    )

    this.setState({ isGeneratingDescription: false })
  }

  handleGenerateDescription = (childId) => {
    const { depositsActions, depositsSelectors } = this.props

    this.setState({ isGeneratingDescription: true })

    const criteria = depositsSelectors.getCriteria({
      childId,
      type: DEPOSIT_TYPES.DEPOSIT,
    })

    return depositsActions.list({
      onSuccess: this.handleListChildDepositsSuccess,
      onlyData: true,
      params: [{ criteria, limit: 0 }],
    })
  }

  handleSelectChild = (child) => {
    const { resetForm } = this.props

    const { value } = child || {}

    if (!value) {
      return resetForm()
    }

    return this.handleGenerateDescription(value)
  }

  handleRefundClick = () => {
    const { data, modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.DEPOSIT_REFUND, {
      originalDepositId: data?.id,
    })
  }

  handleDeleteSuccess = () => {
    const { snackbarActions } = this.props

    const action = i18n.t('module:Finance:Deposits:Form:Snackbar:Action:deleted')
    const type = _.upperFirst(this.getTypeLabel())

    snackbarActions.show({
      message: i18n.t('module:Finance:Deposits:Form:Snackbar:message', { action, type }),
    })

    this.goToList()
  }

  handleDelete = () => {
    const { data, depositsActions } = this.props
    const { id } = data

    depositsActions.remove({
      onSuccess: this.handleDeleteSuccess,
      params: [id],
    })
  }

  handleDeleteClick = () => {
    const { modalActions, modalConsts } = this.props

    const type = this.getTypeLabel()

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'trash',
      onConfirm: this.handleDelete,
      text: i18n.t('module:Finance:Deposits:Form:deleteConfirm', { type }),
    })
  }

  render() {
    const {
      data,
      errorMessages,
      formValues,
      initialValues,
      isDeletingDisabled,
      isDepositEditingDisabled,
      isDepositRefundInvoiced,
      isFetching,
      isRefundingDisabled,
      isSubmitting,
      params,
    } = this.props
    const {
      isChildContext,
      isEdit,
      isGeneratingDescription,
      isRefund,
      title,
    } = this.state

    const { childId } = params

    return (
      <AddView
        childId={childId}
        data={data}
        deleteDisabled={isDeletingDisabled}
        depositEditingDisabled={isDepositEditingDisabled}
        depositRefundInvoiced={isDepositRefundInvoiced}
        errorMessages={errorMessages}
        formValues={formValues}
        initialValues={initialValues}
        isChildContext={isChildContext}
        isEdit={isEdit}
        isFetching={isFetching}
        isGeneratingDescription={isGeneratingDescription}
        isRefund={isRefund}
        isSubmitting={isSubmitting}
        refundDisabled={isRefundingDisabled}
        title={title}
        onDeleteClick={this.handleDeleteClick}
        onRefundClick={this.handleRefundClick}
        onSelectChild={this.handleSelectChild}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  depositsSelectors,
  depositsSingleState,
  params,
}) => ({
  data: depositsSelectors.getDepositsSingleStateFormattedDataSelector(state),
  errorMessages: appSelectors.getErrorMessages(depositsSingleState),
  formValues: getFormValues(DEPOSITS_ADD_FORM)(state),
  initialValues: depositsSelectors.getInitialValues(params?.childId)(state),
  isDeletingDisabled: depositsSelectors.getIsDepositDeletingDisabled(state),
  isDepositEditingDisabled: depositsSelectors.getIsDepositEditingDisabled(state),
  isDepositRefundInvoiced: depositsSelectors.getIsDepositRefundInvoiced(state),
  isFetching: appSelectors.getIsFetching(depositsSingleState),
  isRefundingDisabled: depositsSelectors.getIsDepositRefundingDisabled(state),
  isSubmitting: appSelectors.getIsSubmitting(depositsSingleState),
})

const mapDispatch = {
  changeField: (field, value) => change(DEPOSITS_ADD_FORM, field, value),
  resetForm: () => reset(DEPOSITS_ADD_FORM),
}

const enhance = compose(
  withAppService,
  withDepositsService,
  withModalService,
  withRouter,
  withSnackbarService,
  connect(mapState, mapDispatch),
)

export default enhance(AddContainer)
