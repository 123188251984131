import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { Form } from 'components'

import i18n from 'translations'

const SelectFormatModalForm = ({
  formatOptions,
  handleSubmit,
  onCloseClick,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row label="Select format" required verticalLabel>
      <Field
        component={Form.Select}
        name="format"
        options={formatOptions}
        placeholder="Select format"
        validate={isRequired}
      />
    </Form.Row>
    <Form.FooterActions
      submitLabel={i18n.t('global:Download')}
      onCancelClick={onCloseClick}
    />
  </Form>
)

export default reduxForm({ form: 'SelectFormatModalForm' })(SelectFormatModalForm)
