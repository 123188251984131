import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const BUTTON_EDGE = {
  END: 'end',
  START: 'start',
} as const

export const BUTTON_HIERARCHY = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
} as const

export const BUTTON_SIZE = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
} as const

export const COLOR_THEME = {
  INVERTED: 'secondary',
  MAIN: 'primary',
}

const CUSTOM_COLORS = {
  ERROR: 'error',
  GRAY: 'gray',
  SENARY: 'senary',
  SUCCESS: 'success',
  TERTIARY: 'tertiary',
}

export const CUSTOM_COLORS_OPTIONS = [
  {
    color: FLAG_COLOURS.ERROR,
    value: CUSTOM_COLORS.ERROR,
  },
  {
    color: NEUTRAL_COLOURS.GRAY,
    value: CUSTOM_COLORS.GRAY,
  },
  {
    color: getBrandingColor('senary-color'),
    value: CUSTOM_COLORS.SENARY,
  },
  {
    color: FLAG_COLOURS.SUCCESS,
    value: CUSTOM_COLORS.SUCCESS,
  },
  {
    color: getBrandingColor('tertiary-color'),
    value: CUSTOM_COLORS.TERTIARY,
  },
]
