import { generateRoute } from 'utils/routing'

import i18n from 'translations'

export const getMenuItems = (authAccessMap, childId) => ([
  {
    label: i18n.t('module:Children:Child:Menu:about'),
    to: generateRoute('CHILDREN.CHILD.ABOUT', { childId }),
  },
  {
    label: i18n.t('module:Children:Child:Menu:contacts'),
    to: generateRoute('CHILDREN.CHILD.CONTACTS', { childId }),
  },
  {
    label: i18n.t('module:Children:Child:Menu:bookingPattern'),
    to: generateRoute('CHILDREN.CHILD.BOOKING_PATTERN', { childId }),
  },
  {
    auth: authAccessMap.ChildLearningJourney,
    label: i18n.t('module:Children:Child:Menu:learningJourney'),
    to: generateRoute('CHILDREN.CHILD.LEARNING_JOURNEY', { childId }),
  },
  {
    auth: authAccessMap.Finance,
    label: i18n.t('module:Children:Child:Menu:finance'),
    to: generateRoute('CHILDREN.CHILD.FINANCE', { childId }),
  },
  {
    label: i18n.t('module:Children:Child:Menu:diary'),
    to: generateRoute('CHILDREN.CHILD.DAILY_DIARY', { childId }),
  },
  {
    label: i18n.t('module:Children:Child:Menu:notes'),
    to: generateRoute('CHILDREN.CHILD.NOTES', { childId }),
  },
  {
    auth: authAccessMap.Injury,
    label: i18n.t('module:Children:Child:Menu:accidentsIncidents'),
    to: generateRoute('CHILDREN.CHILD.SAFEGUARDING', { childId }),
  },
])
