import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withBalanceAdjustmentsService } from 'services/legacy/balanceAdjustments'
import { withModalService } from 'services/utils/modal'
import { getAuthUser } from 'services/security/selectors'

import BalanceAdjustmentModalView from './BalanceAdjustmentModalView'
import { BALANCE_ADJUSTMENT_FORM } from './components/BalanceAdjustmentModalForm'

class BalanceAdjustmentModalContainer extends Component {
  componentWillUnmount() {
    const { balanceAdjustmentsActions } = this.props

    return balanceAdjustmentsActions.clearBalanceAdjustment()
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSubmit = (values) => {
    const {
      balanceAdjustmentsActions,
      balanceAdjustmentsSelectors,
      childFinanceDetails,
      onCreateSuccess,
    } = this.props

    const body = balanceAdjustmentsSelectors.getBody(values, childFinanceDetails)
    balanceAdjustmentsActions.createBalanceAdjustment(body, () => {
      this.handleCloseClick()

      if (onCreateSuccess) {
        onCreateSuccess()
      }
    })
  }

  render() {
    const {
      authUser,
      balanceAdjustment,
      childFinanceDetails,
      errorMessages,
      formValues,
      isFetching,
      isSubmitting,
      item,
    } = this.props
    const { balance } = childFinanceDetails

    let newBalance = +balance

    if (formValues) {
      const { isCredit, total = 0 } = formValues

      newBalance += isCredit ? +total : -total
    }

    return (
      <BalanceAdjustmentModalView
        authUser={authUser}
        balanceAdjustment={balanceAdjustment}
        child={childFinanceDetails}
        errorMessages={errorMessages}
        isFetching={isFetching}
        isSubmitting={isSubmitting}
        item={item}
        newBalance={newBalance}
        onCloseClick={this.handleCloseClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  balanceAdjustmentsSingleState,
  childSelectors,
}) => ({
  authUser: getAuthUser(state),
  childFinanceDetails: childSelectors.getFinanceDetailsSelector(state),
  errorMessages: appSelectors.getErrorMessages(balanceAdjustmentsSingleState),
  formValues: getFormValues(BALANCE_ADJUSTMENT_FORM)(state),
  isFetching: appSelectors.getIsFetching(balanceAdjustmentsSingleState),
  isSubmitting: appSelectors.getIsSubmitting(balanceAdjustmentsSingleState),
})

const enhance = compose(
  withAppService,
  withChildService,
  withBalanceAdjustmentsService,
  withModalService,
  connect(mapState),
)

export default enhance(BalanceAdjustmentModalContainer)

