import { createSelector } from 'reselect'

import { CriterionV2 } from 'constants/service'
import { Option } from 'constants/models'

import { CLOSURE_DAY_TYPE_FILTER, ClosureDayTypesRootState } from '../models'

const getClosureDayTypesState = (state: ClosureDayTypesRootState) => state?.closureDayTypes

export const getClosureDayTypesList = createSelector([getClosureDayTypesState], (state) => state?.list?.data)

interface CriteriaParamsProps {
  name?: string
  statusFilter?: Option
}

export const getCriteria = ({ name, statusFilter }: CriteriaParamsProps): CriterionV2[] => {
  const criteria = []

  if (name) {
    criteria.push({
      field: 'name',
      value: name,
    })
  }

  if (statusFilter?.value !== CLOSURE_DAY_TYPE_FILTER.ALL) {
    criteria.push({
      field: 'archived',
      value: statusFilter.value === CLOSURE_DAY_TYPE_FILTER.ARCHIVED ? 1 : 0,
    })
  }

  return criteria
}
