import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class DepositsApiClient extends ServiceBase {
  list = (params) => this.request({
    path: `/v2/deposits/statistics/deposit?${getQueryStringV2(params)}`,
  })

  get = (id, params) => this.request({
    path: `/v2/deposits/${id}?${getQueryStringV2(params)}`,
  })

  create = (params, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/deposits?${getQueryStringV2(params)}`,
  })

  remove = (id) => this.request({
    method: 'DELETE',
    path: `/v2/deposits/${id}`,
  })

  update = (id, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/deposits/${id}`,
  })

  statistics = (params) => this.request({
    path: `/v2/statistics/deposit?${getQueryStringV2(params)}`,
  })

  export = (params = {}) => this.request({
    path: `/v2/deposits/report/deposit?${getQueryStringV2(params)}`,
  })
}

export default DepositsApiClient
