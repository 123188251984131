import _ from 'lodash'

import { createSelector } from 'reselect'

const getSupportedCurrencyState = (state) => state.supportedCurrency

export const getSupportedCurrencyListData = createSelector(
  [getSupportedCurrencyState],
  (state) => state.list.data,
)

export const getSupportedCurrencyListOptions = createSelector(
  [getSupportedCurrencyListData],
  (state) => _.map(state, ({ code, name }) => ({ label: name, value: code })),
)
