import React from 'react'

import { generateRoute } from 'utils/routing'

import { Callout, EmptyState, Page, Pagination, Section, Select, Spinner, Table, Toolbar } from 'components'

import i18n from 'translations'

const ChildDiscountsView = ({
  child,
  childDiscountsTableData,
  errorMessages,
  isEmpty,
  isLoading,
  onPageChange,
  onSortChange,
  onStatusChange,
  page,
  pageCount,
  perPage,
  sortField,
  sortOrder,
  status,
  statusOptions,
  totalResults,
}) => {
  const { firstName, id: childId } = child

  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('module:Children:Child:BookingPattern:Discounts:discounts')}
      titleSingular={i18n.t('module:Children:Child:BookingPattern:Discounts:discount')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderHeader = () => (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <Select
            clearable={false}
            options={statusOptions}
            placeholder="Status"
            value={status}
            onChange={onStatusChange}
          />
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (isEmpty) {
      return (
        <EmptyState
          icon="discount"
          text1={`Does ${firstName} have any discounts?`}
          text2={`You can allocate discounts for ${firstName} here to show up on invoices.`}
        />
      )
    }

    const columns = [
      {
        field: 'createdAt',
        title: 'Start date',
      },
      {
        align: 'left',
        field: 'description',
        title: 'Description',
      },
      {
        field: 'discount',
        title: 'Discount amount',
      },
      {
        field: 'rowToButton',
      },
    ]

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={columns}
          data={childDiscountsTableData}
          sortField={sortField}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const actions = (
    <Section.Actions
      primary={[{ to: generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.DISCOUNTS.ADD', { childId }) }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title="Discounts"
    >
      <Callout content={errorMessages} error />
      {renderHeader()}
      {renderContent()}
    </Page.Section>
  )
}

export default ChildDiscountsView
