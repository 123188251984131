import _ from 'lodash'

import { STATUS_TYPES } from 'services/legacy/enquiries/settings/constants'

import { generateRoute } from 'utils/routing'

import { Button, Tooltip } from 'components'

import i18n from 'translations'

import { TRANS_TYPE_MAPPINGS, TYPE_TO_ROUTE_URL_MAPPINGS } from './constants'

export const getTransPrefix = (type) => `module:Management:Enquiries:${TRANS_TYPE_MAPPINGS[type]}`

const getTableHeaderTransKey = (type) => {
  switch (type) {
    case STATUS_TYPES.SOURCE:
      return i18n.t(`${getTransPrefix(type)}:Table:Header:source`)
    case STATUS_TYPES.REASON:
      return i18n.t(`${getTransPrefix(type)}:Table:Header:reason`)
    default:
      return i18n.t(`${getTransPrefix(type)}:Table:Header:lostReason`)
  }
}

export const getColumns = (type) => [
  {
    align: 'left',
    field: 'name',
    sortable: true,
    title: getTableHeaderTransKey(type),
  },
  {
    field: 'edit',
    width: '80px',
  },
]

export const getEnquirySettings = (data, type) => _.map(data, (item) => {
  const { editable } = item

  return {
    ...item,
    edit: (
      <Tooltip
        placement="left"
        title={!editable ? i18n.t(`${getTransPrefix(type)}:tooltip`) : ''}
        inline
      >
        <Button.TableAction
          color={!editable && 'gray'}
          disabled={!editable}
          icon={editable ? 'chevron-right' : 'padlock'}
          to={generateRoute('MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_COMMON.EDIT', {
            enquirySettingId: item.id,
            type: TYPE_TO_ROUTE_URL_MAPPINGS[type],
          })}
        />
      </Tooltip>
    ),
  }
})
