import ServiceBase from 'services/ServiceBase'

import { QueryPropsV2 } from 'constants/service'

import { getQueryStringV2 } from 'utils/api'

class InvoicesApiClient extends ServiceBase {
  list = (params: QueryPropsV2) => this.request({
    path: `/v2/invoices?${getQueryStringV2(params)}`,
  })

  export = (reportType, params: QueryPropsV2) => this.request({
    path: `/v2/invoices/report/${reportType}?${getQueryStringV2(params)}`,
  })

  get = (id: string, params: QueryPropsV2) => this.request({
    path: `/v2/invoices/${id}?${getQueryStringV2(params)}`,
  })

  getPreview = (id: string, params: QueryPropsV2) => this.request({
    path: `/v2/invoices/${id}/preview?${getQueryStringV2(params)}`,
  })

  create = (body, params: QueryPropsV2) => this.request({
    body,
    method: 'POST',
    path: `/v2/invoices?${getQueryStringV2(params)}`,
  })

  update = (id: string, body, params: QueryPropsV2) => this.request({
    body,
    method: 'PUT',
    path: `/v2/invoices/${id}?${getQueryStringV2(params)}`,
  })

  delete = (id: string) => this.request({
    method: 'DELETE',
    path: `/v2/invoices/${id}`,
  })

  download = (id: string) => this.request({
    download: true,
    method: 'GET',
    path: `/v2/invoices/${id}/download`,
  })

  autoCalculate = (childId, params: QueryPropsV2, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/children/${childId}/calculator?${getQueryStringV2(params)}`,
  })

  statistics = (params: QueryPropsV2) => this.request({
    path: `/v2/statistics/invoices?${getQueryStringV2(params)}`,
  })

  forecast = (params: QueryPropsV2) => this.request({
    path: `/v2/statistics/monthly/forecast?${getQueryStringV2(params)}`,
  })

  createBulk = (body, params: QueryPropsV2) => this.request({
    body,
    method: 'POST',
    path: `/v2/bulk/invoices?${getQueryStringV2(params)}`,
  })

  createBatch = (body, params: QueryPropsV2) => this.request({
    body,
    method: 'POST',
    path: `/v2/batch/invoices?${getQueryStringV2(params)}`,
  })

  updateBatch = (body, params: QueryPropsV2) => this.request({
    body,
    method: 'PUT',
    path: `/v2/batch/invoices?${getQueryStringV2(params)}`,
  })

  sendAnnualReport = (body) => this.request({
    body,
    method: 'POST',
    path: '/v2/export/report/annualRevenue',
  })

  getBadDebtStatistics = (params: QueryPropsV2) => this.request({
    path: `/v2/invoices/statistics/bad_debt?${getQueryStringV2(params)}`,
  })

  getBadDebtTotalStatistics = (params: QueryPropsV2) => this.request({
    path: `/v2/statistics/bad_debt?${getQueryStringV2(params)}`,
  })
}

export default InvoicesApiClient
