import { flatten, nest } from 'utils/flatnest'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { MODULE_TYPE } from 'module/Modals/Enquiry/ExportModal/constants'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withEnquiryViewingsService } from 'services/legacy/enquiries'
import { withPaginationUtils } from 'services/utils/pagination'
import { withSortingUtils } from 'services/utils/sorting'
import { withRouter } from 'services/router'

import ViewingsView from './ViewingsView'
import { getViewings } from './ViewingsHelper'

const ENQUIRY_VIEWING_GROUPS = {
  read: [
    'enquiry',
    'enquiry.data',
    'membership',
    'showAround.status',
    'showAround.enquiry',
    'showAround.assignedPerson',
  ],
}

const DEFAULT_SORT = {
  sortField: 'showAroundDate',
  sortOrder: 'desc',
}

class ViewingsContainer extends Component {
  constructor(props) {
    super(props)

    const { query } = props.location
    const { showAroundDate } = nest(query)

    this.state = {
      search: null,
      showAroundDate: {
        after: showAroundDate?.after
          ? moment(showAroundDate.after).format(DEFAULT_DATE_FORMAT)
          : moment().startOf('week').format(DEFAULT_DATE_FORMAT),
        before: showAroundDate?.before
          ? moment(showAroundDate.before).format(DEFAULT_DATE_FORMAT)
          : moment().endOf('week').format(DEFAULT_DATE_FORMAT),
      },
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { enquiryViewingsActions } = this.props

    enquiryViewingsActions.clear()
  }

  fetch = () => {
    const {
      enquiryViewingsActions,
      enquiryViewingsSelectors,
      paginationUtils,
      setLocationQuery,
      sortingUtils,
    } = this.props
    const { search, showAroundDate } = this.state
    const { page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const filters = { search, showAroundDate }

    setLocationQuery(flatten(filters))

    const criteria = enquiryViewingsSelectors.getCriteria(filters)

    const apiParams = {
      criteria,
      groups: ENQUIRY_VIEWING_GROUPS,
      order: {
        sortField: sortField || DEFAULT_SORT.sortField,
        sortOrder: sortField ? sortOrder : DEFAULT_SORT.sortOrder,
      },
      page,
    }

    enquiryViewingsActions.list({
      params: [apiParams],
    })
  }

  handleSearchChange = (search) => {
    this.setState({ search }, () => this.handlePageChange(1))
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleShowAroundDateChange = (showAroundDate) => {
    this.setState({
      showAroundDate: {
        after: showAroundDate[0] ? moment(showAroundDate[0]).format(DEFAULT_DATE_FORMAT) : undefined,
        before: showAroundDate[1] ? moment(showAroundDate[1]).format(DEFAULT_DATE_FORMAT) : undefined,
      },
    }, () => this.handlePageChange(1))
  }

  handleSortChange = (key) => {
    const { sortingUtils } = this.props
    const { onSortChange } = sortingUtils

    onSortChange(() => this.handlePageChange(1))(key)
  }

  handleSubmitSuccess = () => {
    this.handlePageChange(1)
  }

  handleActionButtonClick = (showAroundData) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.ENQUIRY_VIEWING_EDIT, {
      onSubmitSuccess: this.handleSubmitSuccess,
      showAroundData,
    }, {
      enableMultipleModal: true,
    })
  }

  handleExportClick = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.ENQUIRY_EXPORT, {
      moduleType: MODULE_TYPE.VIEWINGS,
    }, {
      enableMultipleModal: true,
    })
  }

  render() {
    const {
      isFetching,
      paginationUtils,
      sortingUtils,
      totalResults,
      viewings,
    } = this.props
    const { search, showAroundDate } = this.state

    const { getPageCount, page, perPage } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const pageCount = getPageCount(totalResults)

    return (
      <ViewingsView
        isLoading={isFetching}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        search={search}
        showAroundDate={[showAroundDate?.after, showAroundDate?.before]}
        sortField={sortField || DEFAULT_SORT.sortField}
        sortOrder={sortField ? sortOrder : DEFAULT_SORT.sortOrder}
        totalResults={totalResults}
        viewings={getViewings(viewings, this.handleActionButtonClick)}
        onExportClick={this.handleExportClick}
        onPageChange={this.handlePageChange}
        onSearchChange={this.handleSearchChange}
        onShowAroundDateChange={this.handleShowAroundDateChange}
        onSortChange={this.handleSortChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  enquiryViewingsListState,
  enquiryViewingsSelectors,
}) => ({
  errorMessages: appSelectors.getErrorMessages(enquiryViewingsListState),
  isFetching: appSelectors.getIsFetching(enquiryViewingsListState),
  totalResults: appSelectors.getTotalResults(enquiryViewingsListState),
  viewings: enquiryViewingsSelectors.getEnquiryViewingsListDataSelector(state),
})

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withEnquiryViewingsService,
  withPaginationUtils,
  withSortingUtils,
  connect(mapState),
)

export default enhance(ViewingsContainer)
