import _ from 'lodash'

import React, { PropsWithChildren, useEffect } from 'react'
import { compose } from 'recompose'

import { generateRoute } from 'utils/routing'

import { withShellService, withShellServiceProps } from 'services/shell'
import { withRouter, withRouterProps } from 'services/router'

import i18n from 'translations'

import SafeguardingWrapperView from './SafeguardingWrapperView'

type SafeguardingWrapperContainerProps = withShellServiceProps
  & withRouterProps

const SafeguardingWrapperContainer: React.FC<PropsWithChildren<SafeguardingWrapperContainerProps>> = ({
  children,
  location,
  navigate,
  shellActions,
}) => {
  const { pathname } = location

  const setMenu = () => {
    shellActions.setSubMenu([
      {
        icon: 'injury-records',
        label: _.startCase(i18n.t('module:Safeguarding:Routing:accidentsIncidents')),
        to: generateRoute('SAFEGUARDING.INJURY.LIST'),
      },
      {
        icon: 'home-accidents',
        label: _.startCase(i18n.t('module:Safeguarding:Routing:homeAccidents')),
        to: generateRoute('SAFEGUARDING.HOME_ACCIDENTS.LIST'),
      },
    ])
  }

  useEffect(() => {
    setMenu()
  }, [])

  useEffect(() => {
    if (generateRoute('SAFEGUARDING.INDEX') === pathname) {
      navigate(generateRoute('SAFEGUARDING.INJURY.LIST'))
    }
  }, [pathname])

  return (
    <SafeguardingWrapperView>
      {children}
    </SafeguardingWrapperView>
  )
}

const enhance = compose(
  withRouter,
  withShellService,
)

export default enhance(SafeguardingWrapperContainer)
