import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import constants from 'services/legacy/invoices/constants'

import { Currency, Form, Typography } from 'components'

import { StyledContainer } from './IssueCreditNoteModalFormStyled'

export const ISSUE_CREDIT_NOTE_FORM = 'IssueCreditNoteForm'

const IssueCreditNoteModalForm = ({
  creditNoteIsFetching,
  handleSubmit,
  invoice,
  onCloseClick,
  onSubmit,
}) => {
  const { paymentsTotal, status } = invoice

  return (
    <StyledContainer>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row label="Credit note date" verticalLabel>
          <Form.Row.Item>
            <Field
              component={Form.DatePicker}
              name="issueDate"
              validate={isRequired}
              clearable
              local
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row
          label="Reason or comment"
          verticalLabel
        >
          <Form.Row.FlexItem flex="0 0 420px">
            <Field
              component={Form.TextAreaField}
              name="description"
              placeholder="Enter reason or comment to display on invoice."
              validate={isRequired}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row>
          <Form.Row.FlexItem>
            `
          </Form.Row.FlexItem>
        </Form.Row>
        {0 < paymentsTotal
        && (status === constants.STATUS_TYPES.PAID || status === constants.STATUS_TYPES.PARTIALLY_PAID) && (
          <Typography margin="20px 0">
            There is a&nbsp;
            <strong>
              <Currency value={paymentsTotal} />
            </strong>
            &nbsp;payment on this invoice which will be allocated to pay the oldest
            outstanding invoices first and any remainder will go into account overpayment.
          </Typography>
        )}
        <Typography margin="20px 0">
          Please note: Issuing this credit note will be for the full amount of the invoice
          and the invoice will be cancelled.&nbsp;
          <strong>
            This action cannot be undone.
          </strong>
        </Typography>
        <Form.FooterActions
          isSubmitting={creditNoteIsFetching}
          submitLabel="Issue credit note"
          onCancelClick={onCloseClick}
        />
      </Form>
    </StyledContainer>
  )
}

export default reduxForm({ form: ISSUE_CREDIT_NOTE_FORM })(IssueCreditNoteModalForm)
