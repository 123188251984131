import { mergeData } from 'utils/data'

import {
  CLEAR_DAILY_DIARY_ACTIVITIES,
  GET_DAILY_DIARY_ACTIVITIES,
  GET_DAILY_DIARY_ACTIVITIES_FAILED,
  GET_DAILY_DIARY_ACTIVITIES_SUCCESS,
} from './constants'

import { ADD_ACTIVITY_SUCCESS, REMOVE_ACTIVITY_SUCCESS, UPDATE_ACTIVITY_SUCCESS } from '../single/constants'

import { addDiaryActivitiesRecord, removeDiaryActivitiesRecord, updateDiaryActivitiesRecord } from './helpers'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: {},
}

export default (state = initialState, { error, id, mergeResult, payload, silent, type }) => {
  switch (type) {
    case GET_DAILY_DIARY_ACTIVITIES:
      return { ...state, error: initialState.error, isFetching: !silent }
    case GET_DAILY_DIARY_ACTIVITIES_SUCCESS:
      return {
        ...state,
        data: mergeData(state.data, payload.data, mergeResult),
        isFetching: false,
        meta: payload.meta,
      }
    case GET_DAILY_DIARY_ACTIVITIES_FAILED:
      return { ...state, error, isFetching: false }
    case ADD_ACTIVITY_SUCCESS:
      return addDiaryActivitiesRecord(state, payload)
    case UPDATE_ACTIVITY_SUCCESS:
      return updateDiaryActivitiesRecord(state, payload)
    case REMOVE_ACTIVITY_SUCCESS:
      return removeDiaryActivitiesRecord(state, id)
    case CLEAR_DAILY_DIARY_ACTIVITIES:
      return initialState
    default:
      return state
  }
}
