import { Normalize } from 'styled-normalize'
import 'react-virtualized/styles.css'

import React from 'react'

import { GlobalStyled } from './global'
import { FlagsStyled } from './flags'
import './fonts.css'

const Styled = () => (
  <React.Fragment>
    <Normalize />
    <GlobalStyled />
    <FlagsStyled />
  </React.Fragment>
)

export default Styled
