import { createSelector } from 'reselect'

const getEnquiryStatusTypes = (state) => state.enquiryStatusTypes

export const getEnquiryStatusTypesSingleSelector = createSelector(
  [getEnquiryStatusTypes],
  (state) => state?.single,
)

export const getEnquiryStatusTypesSingleDataSelector = createSelector(
  [getEnquiryStatusTypesSingleSelector],
  (state) => state.data,
)

export const getInitialValues = (isEditMode) => createSelector(
  [getEnquiryStatusTypesSingleDataSelector],
  (enquiryStatusType) => {
    if (!isEditMode || !enquiryStatusType) {
      return null
    }

    const { name } = enquiryStatusType

    return { name }
  },
)

