import _ from 'lodash'

import React from 'react'

import { CHILD_EDIT_FORM_ROUTE_TYPE } from 'module/Children/Child/constants'

import { generateRoute } from 'utils/routing'

import { getAllergyType, getAllergyTypeLabelColor } from 'services/legacy/child/helpers'
import { getInitialValues } from 'services/legacy/dailyDiaryFoods/single/selectors'
import { getDietaryOptionLabel } from 'services/legacy/child/selectors'

import { Avatar, EmptyState, InfiniteScroll, List, Pagination, Spinner, Typography } from 'components'
import MissingInformationDietary from 'module/Reports/DietaryReport/components/MissingInformationDietary'

import i18n from 'translations'

import FoodMenu from './components/FoodMenu'
import FoodRecord from './components/FoodRecord'
import { StyledDietaryAvatar, StyledListRow, StyledSpace, StyledSpinner } from './FoodsStyled'

const FoodsView = ({
  category,
  date,
  foodMenuIsProgress,
  getFormName,
  isFetching,
  isFetchingContent,
  isOffline,
  onChangeSession,
  onPageChange,
  onReinitializeFormValues,
  onResetRecord,
  onUpdateChildRecords,
  onUpdateRecord,
  page,
  pageCount,
  records,
  registers,
  totalResults,
  type,
}) => {
  if (isFetching && 1 === page) {
    if (isOffline) {
      return (
        <EmptyState
          icon="offline"
          text1={i18n.t('global:youAreOffline')}
        />
      )
    }

    return (
      <Spinner />
    )
  }

  if (!isFetching && (registers && !registers.length)) {
    return (
      <EmptyState
        icon="daily-diary"
        text1={i18n.t('module:DailyDiary:global:noChildrenAttending')}
      />
    )
  }

  const renderPagination = () => !isFetching && (
    <Pagination
      totalResults={totalResults}
    />
  )

  const renderItem = (item) => {
    const { childRegister, diaryRecords } = item || {}
    const { child } = childRegister || {}
    const { firstName, id: childId, information, photo, surname } = child
    const { allergies, allergiesDetails, dietaryReq, dietaryReqDesc } = information

    return (
      <StyledListRow>
        <List.Standard.Row
          content={
            isFetchingContent ? (
              <StyledSpinner>
                <Spinner />
              </StyledSpinner>
            ) : (
              <FoodRecord
                form={getFormName({ childId })}
                initialValues={getInitialValues(diaryRecords[0], date)}
                item={item}
                type={type}
                onReinitializeFormValues={() => onReinitializeFormValues({
                  formName: getFormName({ childId }),
                  initialValues: getInitialValues(diaryRecords[0], date),
                })}
                onResetRecord={onResetRecord}
                onSubmit={onUpdateRecord}
              />
            )
          }
          key={`${date}_${type}_${childId}`}
          leftColumn={(
            <StyledDietaryAvatar>
              <Avatar
                avatarSize="medium"
                initials={[firstName, surname]}
                src={photo}
                title={(
                  <Typography fontSize={14} bold>
                    {`${firstName} ${surname}`}
                  </Typography>
                )}
              />
              <StyledSpace>
                {dietaryReq
                  ? (
                    <React.Fragment>
                      <Typography bold>
                        {getDietaryOptionLabel(dietaryReq)}
                      </Typography>
                      {dietaryReqDesc}
                    </React.Fragment>
                  )
                  : (
                    <MissingInformationDietary
                      linkLabel={i18n.t('global:EditPreferences')}
                      titleLabel={
                        i18n.t('module:Children:Constants:DietaryRequirementsOptions:unknownDietaryPreference')
                      }
                      to={`${generateRoute('CHILDREN.EDIT', {
                        childId,
                        type: CHILD_EDIT_FORM_ROUTE_TYPE.HEALTH_AND_DIET_INFO,
                      })}`}
                    />
                  )}
              </StyledSpace>
              <StyledSpace>
                <Typography color={getAllergyTypeLabelColor(allergies)}>
                  {getAllergyType(allergies, allergiesDetails)}
                </Typography>
              </StyledSpace>
            </StyledDietaryAvatar>
          )}
        />
      </StyledListRow>
    )
  }

  const renderContent = () => {
    if (isFetchingContent) {
      return (
        <Spinner />
      )
    }

    if (!isFetchingContent && (records && !records.length)) {
      return (
        <EmptyState
          icon="daily-diary"
          text1={i18n.t('module:DailyDiary:global:noChildrenAttending')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <InfiniteScroll
          dataLength={records?.length || 0}
          hasMore={page < pageCount}
          isFetching={isFetchingContent}
          next={() => onPageChange((+page) + 1)}
        >
          <List.Standard>
            {_.map(records, renderItem)}
          </List.Standard>
        </InfiniteScroll>
        {renderPagination()}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {!foodMenuIsProgress && (
        <FoodMenu
          category={category}
          onChangeSession={onChangeSession}
          onUpdateChildRecords={onUpdateChildRecords}
        />
      )}
      {renderContent()}
    </React.Fragment>
  )
}

export default FoodsView
