import React from 'react'

import { Callout, Spinner } from 'components'
import Settings from 'module/Settings/components'

import i18n from 'translations'

const SettingsInvoicesDetailsV2View = ({
  authAccessMap,
  errorMessages,
  invoicesSettings,
  isLoading,
  onSubmit,
}) => {
  if (isLoading) {
    return <Spinner />
  }

  const hideAutomationOptions = !authAccessMap.section.FinanceAutomation

  return (
    <div>
      <Callout content={errorMessages} error />
      <Settings.InvoiceSettingV2
        description={i18n.t('module:Settings:Invoice:InvoiceDetails:subtile')}
        hideAutomationOptions={hideAutomationOptions}
        invoicesSettings={invoicesSettings}
        title="Your default invoice settings"
        hideAutoCalculateStartDate
        hideGlobalOption
        hideInvoiceNumberOptions
        hidePaymentReminders
        onSubmit={onSubmit}
      />
    </div>
  )
}

export default SettingsInvoicesDetailsV2View
