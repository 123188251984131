import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import colors, { NEUTRAL_COLOURS } from 'constants/colors'
import { TableColumn } from 'components/Table'

import { convertToPercent, toFloat } from 'utils/data'

import { Avatar, Currency, DateString, Typography } from 'components'

import i18n from 'translations'

import { FundingTypeValues } from 'services/product/nurseryFundingV3/constants'
import { CHILD_REPORT_TABLE_COLUMNS, REPORT_TABLE_COLUMNS } from './constants'

export const getColumns = (isChildContext: boolean) => (): TableColumn[] => (
  isChildContext
    ? CHILD_REPORT_TABLE_COLUMNS
    : REPORT_TABLE_COLUMNS
)

export interface GetTableDataReturnProps {
  ageMonths: string
  child: JSX.Element
  codeReference: string
  dateOfBirth: JSX.Element
  deductedFromInvoice: string
  fundingHours: string
  fundingInvoicedHours: string
  fundingInvoicedValue: string | JSX.Element
  fundingValue: JSX.Element
  name: string
  type: string
}

const getFundingHours = (hours?: number, minutes?: number): string | null => {
  if (!hours && !minutes) {
    return i18n.t('global:Hours', { value: 0 })
  }

  if (!hours && minutes) {
    return i18n.t('global:Minutes', { value: minutes })
  }

  if (hours && !minutes) {
    return i18n.t('global:Hours', { value: hours })
  }

  const formattedHours = i18n.t('global:Hours', { value: hours })
  const formattedMinutes = i18n.t('global:Minutes', { value: minutes })

  return `${formattedHours} ${formattedMinutes}`
}

const renderFundingType = (type) => {
  if (!type) {
    return '-'
  }

  if (FundingTypeValues.REGULAR === type) {
    return i18n.t('services:NurseryFundingV3:fundingType:regularFunding')
  }

  return i18n.t('services:NurseryFundingV3:fundingType:oneOffFunding')
}

const getReportItem = ({ defaultValue = '-', displayDefaultValue, displayValue }) => {
  if (displayDefaultValue && defaultValue) {
    return defaultValue
  }

  return displayValue
}

export const getTableData = ({ data, hasError }): GetTableDataReturnProps[] => {
  if (!data?.length || hasError) {
    return null
  }

  return _.map(data, ({
    child,
    deductsFromInvoice,
    fundingHours,
    fundingInvoicedHours,
    fundingInvoicedValue,
    fundingMaxHours,
    fundingMaxMinutes,
    fundingMinutes,
    fundingValue,
    name,
    times,
    type,
  }) => {
    const { ageMonths, birthDate, codeReference, firstName, photo, surname } = child || {}
    const formattedMaxFundingHours = getFundingHours(fundingMaxHours, fundingMaxMinutes)
    const formattedFundingHours = getFundingHours(fundingHours, fundingMinutes)
    const fundedHours = formattedMaxFundingHours
      ? `${formattedFundingHours} / ${formattedMaxFundingHours}`
      : formattedFundingHours

    return {
      ageMonths: i18n.t('global:ageMonths', { ageMonths }),
      child: (
        <Avatar
          avatarSize="small"
          initials={[firstName, surname]}
          src={photo}
          title={`${firstName} ${surname}`}
        />
      ),
      codeReference,
      dateOfBirth: <DateString date={birthDate} />,
      deductedFromInvoice: getReportItem({
        displayDefaultValue: !type,
        displayValue: deductsFromInvoice ? i18n.t('global:Yes') : i18n.t('global:No'),
      }),
      fundingHours: getReportItem({
        displayDefaultValue: !type || FundingTypeValues.ONE_OFF_LEGACY === type,
        displayValue: fundedHours,
      }),
      fundingInvoicedHours: getReportItem({
        displayDefaultValue: !type || FundingTypeValues.ONE_OFF_LEGACY === type,
        displayValue: i18n.t('global:Hours', { value: fundingInvoicedHours }),
      }),
      fundingInvoicedValue: getReportItem({
        displayDefaultValue: !type || !deductsFromInvoice,
        displayValue: <Currency value={fundingInvoicedValue} />,
      }),
      fundingValue: getReportItem({
        displayDefaultValue: !type,
        displayValue: <Currency value={fundingValue} />,
      }),
      name: getReportItem({
        displayDefaultValue: !name,
        displayValue: name,
      }),
      period: getReportItem({
        displayDefaultValue: !times?.length,
        displayValue: _.map(times, ({ endDate, startDate }) => (
          <div>
            {FundingTypeValues.ONE_OFF_LEGACY === type
              ? moment(startDate).format('DD/MM/YYYY')
              : `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`}
          </div>
        )),
      }),
      type: renderFundingType(type),
    }
  })
}

export const getStatistics = ({ data, hasError }) => {
  if (!data || hasError) {
    return null
  }

  if (!data) {
    return {
      chart: [],
      series: [],
    }
  }

  const { fundingTypes, total } = data
  const categories = _.map(fundingTypes, ({ name }) => name)

  return {
    categories,
    data: _.map(fundingTypes, (fundingType, i) => {
      const { fundingType: { name }, total: fundingTypeTotal } = fundingType

      const percentage = convertToPercent(total, fundingTypeTotal)

      return {
        amount: (
          <React.Fragment>
            <Currency value={fundingTypeTotal} oneSize />
            {' '}
            <Typography color={NEUTRAL_COLOURS.GRAY} fontSize="inherit" variant="span">
              {`(${percentage}%)`}
            </Typography>
          </React.Fragment>
        ),
        color: colors.standardPieChartColors[i],
        id: name,
        isCurrencyValue: true,
        percentage,
        value: toFloat(fundingTypeTotal),
      }
    }),
    id: 1,
    subtitle: (
      <Currency value={total} oneSize />
    ),
    title: 'Total',
  }
}
