import React from 'react'

import { generateRoute } from 'utils/routing'

import { Button, Hyperlink, Pinpad, Space, Spinner, Typography } from 'components'

import i18n from 'translations'

import { BoxWrapper, PinProfileAvatar } from '../components'
import { StyledHyperlink } from './AuthenticationStyled'

const AuthenticationView = ({
  formError,
  hasPin,
  isAuthLoading,
  isFetching,
  memberDetails,
  onSubmit,
}) => {
  if (isFetching) {
    return <Spinner />
  }

  const { firstName, id, name, photo, surname } = memberDetails || {}

  const renderContent = () => (
    <React.Fragment>
      <Typography align="center" variant="h5">
        {i18n.t('module:StaffRegister:Authentication:title')}
      </Typography>
      <Space margin="15px 0 0" />
      <PinProfileAvatar
        firstName={firstName}
        name={name}
        photo={photo}
        surname={surname}
      />
      <Pinpad
        error={formError}
        isLoading={isAuthLoading}
        valid={!formError}
        onSubmit={onSubmit}
      />
    </React.Fragment>
  )

  return (
    <BoxWrapper
      footer={(
        <React.Fragment>
          <Button
            hierarchy="tertiary"
            label={i18n.t('global:Back')}
            to={generateRoute('STAFF_REGISTER.LIST')}
            negativeMargins
          />
          {hasPin ? (
            <StyledHyperlink>
              <Hyperlink fontSize={18} to={`${generateRoute('STAFF_REGISTER.FORGOT_PIN')}?staff=${id}`}>
                {i18n.t('module:StaffRegister:global:forgotPin')}
              </Hyperlink>
            </StyledHyperlink>
          ) : (
            <StyledHyperlink>
              <Hyperlink fontSize={18} to={`${generateRoute('STAFF_REGISTER.CREATE_PIN')}?staff=${id}`}>
                {i18n.t('module:StaffRegister:global:createPin')}
              </Hyperlink>
            </StyledHyperlink>
          )}
        </React.Fragment>
      )}
    >
      {renderContent()}
    </BoxWrapper>
  )
}

export default AuthenticationView
