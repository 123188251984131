import _ from 'lodash'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { Button, Tooltip, Typography } from 'components'

import i18n from 'translations'

export const getTableData = (leaveTypesList) => {
  if (!leaveTypesList?.length) {
    return null
  }

  return _.map(leaveTypesList, (leaveType) => {
    const { archived, editable, id, name } = leaveType

    return {
      name: archived ? (
        <Typography>
          {name}
          {' '}
          <Typography
            color={NEUTRAL_COLOURS.GRAY}
            variant="span"
          >
            {`(${i18n.t('global:Archived')})`}
          </Typography>
        </Typography>
      ) : name,
      rowToButton: (
        <Tooltip
          placement="left"
          title={!editable ? i18n.t('module:Management:LeaveTypes:Staff:tooltip') : ''}
          inline
        >
          <Button.TableAction
            color={!editable && 'gray'}
            disabled={!editable}
            icon={editable ? 'edit' : 'padlock'}
            to={generateRoute('MANAGEMENT.LEAVE_TYPES.STAFF_LEAVE_TYPES.EDIT', {
              itemId: id,
            })}
          />
        </Tooltip>
      ),
    }
  })
}
