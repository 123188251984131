import styled from 'styled-components'

import layout from 'constants/layout'
import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledTimeLabel = styled.div`
  max-width: 50px;  
`

export const StyledValue = styled.div`
  border-radius: ${layout.borderRadius};
  padding: 4px 8px;
  min-width: 35px;
  
  ${({ red }) => red && `
    color: #D30923;
  `}  
  
  ${({ blue }) => blue && `
    color: #5094E0;
  `}
  
  ${({ gray }) => gray && `
    color: #9A9A9A;
  `}
  
  ${({ redBackground }) => redBackground && `
    background: #D30923;
    color: ${NEUTRAL_COLOURS.WHITE};
  `}  

  ${({ grayBackground }) => grayBackground && `
    background: #C5C5C5;
  `}  
`

export const StyledChildItemContainer = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: auto 1fr;
  align-items: center;
  margin: 0 -5px;
  max-width: 64px;
  overflow: hidden;
  text-overflow: ellipsis;
  
  & > p {
    max-width: 44px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const StyledChildContentContainer = styled.div`
  padding: 10px 0;

  &> div ~ div {
    padding-top: 17.5px;
  }
`

export const StyledLabelContainer = styled.div`
  text-align: center;
  line-height: 12px;
`

export const StyledSeparator = styled.p`
  transform: translateY(-4px);
  font-size: 14px;
  height: 7px;
`
