import { generateBasicActions } from 'utils/service'

import {
  CLEAR_FINANCE_REPORTS_CHILDREN_FUNDING,
  FINANCE_REPORTS_CHILDREN_FUNDING,
  FINANCE_REPORTS_CHILDREN_FUNDING_FAILED,
  FINANCE_REPORTS_CHILDREN_FUNDING_SUCCESS,
  FINANCE_REPORTS_FUNDING_EXPORT,
  FINANCE_REPORTS_FUNDING_EXPORT_FAILED,
  FINANCE_REPORTS_FUNDING_EXPORT_SUCCESS,
} from './constants'

export const getChildren = (options = {}) => ({ financeFundingReportApiClient }) => generateBasicActions.list({
  constants: {
    failed: FINANCE_REPORTS_CHILDREN_FUNDING_FAILED,
    init: FINANCE_REPORTS_CHILDREN_FUNDING,
    success: FINANCE_REPORTS_CHILDREN_FUNDING_SUCCESS,
  },
  options,
  service: financeFundingReportApiClient,
  serviceMethod: 'getChildren',
})

export const exportReport = (options = {}) => ({ financeFundingReportApiClient }) => generateBasicActions.list({
  constants: {
    failed: FINANCE_REPORTS_FUNDING_EXPORT_FAILED,
    init: FINANCE_REPORTS_FUNDING_EXPORT,
    success: FINANCE_REPORTS_FUNDING_EXPORT_SUCCESS,
  },
  options,
  service: financeFundingReportApiClient,
  serviceMethod: 'export',
})

export const clearChildren = () => ({
  type: CLEAR_FINANCE_REPORTS_CHILDREN_FUNDING,
})
