import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'

import { COMPARISON_REPORT_TYPES, COMPARISON_REPORT_TYPES_DROPDOWN } from 'services/legacy/cohortTracking/constants'

import { Form, Section, Typography } from 'components'
import ReportGeneratorFilters from 'module/Learning/CohortTracking/components/ReportGeneratorFilters'

import i18n from 'translations'

export const COMPARISON_REPORT_GENERATOR_FORM = 'ComparisonReportGeneratorForm'

const ComparisonReportGeneratorForm = ({
  ethnicities,
  formValues,
  frameworkGroupsOptions,
  frameworksOptions,
  handleSubmit,
  onChangeComparisonType,
  onChangeFramework,
  onResetFilters,
  onSubmit,
  terms,
}) => {
  const renderFields = (
    <Section title={i18n.t('module:Learning:CohortTracking:ComparisonReportGenerator:title')}>
      <Typography fontSize={15} margin="0 0 5px 0" bold>
        {i18n.t('module:Learning:CohortTracking:ComparisonReportGenerator:selectComparisonReport')}
      </Typography>
      <Typography margin="0 0 20px 0">
        {i18n.t('module:Learning:CohortTracking:global:description')}
      </Typography>
      <Form.Row
        label={i18n.t('module:Learning:CohortTracking:ComparisonReportGenerator:comparisonReportType')}
        labelMargin="0 20px 0 0"
        required
      >
        <Form.Row.FlexItem flex="0 0 300px" mobileFull>
          <Field
            component={Form.Select}
            name="type"
            options={COMPARISON_REPORT_TYPES_DROPDOWN}
            placeholder={i18n.t('module:Learning:CohortTracking:ComparisonReportGenerator:comparisonReportType')}
            validate={isRequired}
            onChange={onChangeComparisonType}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Learning:CohortTracking:global:framework')}
        labelMargin="0 20px 0 0"
        required
      >
        <Form.Row.FlexItem flex="0 0 340px" mobileFull>
          <Field
            component={Form.Select}
            name="framework"
            options={frameworksOptions}
            placeholder={i18n.t('module:Learning:CohortTracking:global:framework')}
            validate={isRequired}
            onChange={onChangeFramework}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      {COMPARISON_REPORT_TYPES.ETHNICITY === formValues?.type?.value && (
        <Form.Row
          label={i18n.t('module:Learning:CohortTracking:components:ReportGeneratorFilters:ethnicity')}
          labelMargin="0 20px 0 0"
          required
        >
          <Form.Row.FlexItem flex="0 0 240px" mobileFull>
            <Field
              component={Form.Select}
              name="ethnicity"
              options={ethnicities}
              placeholder={i18n.t('module:Learning:CohortTracking:components:ReportGeneratorFilters:ethnicity')}
              validate={isRequired}
            />
          </Form.Row.FlexItem>
        </Form.Row>
      )}
      <Form.Row
        label={i18n.t('module:Learning:CohortTracking:global:selectTerm')}
        labelMargin="0 20px 0 0"
        required
      >
        <Form.Row.FlexItem flex="0 0 240px" mobileFull>
          <Field
            component={Form.Select}
            name="term"
            options={terms || []}
            placeholder={i18n.t('module:Learning:CohortTracking:global:selectTerm')}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Learning:CohortTracking:global:areaOfLearning')}
        labelMargin="0 20px 0 0"
        required
      >
        <Form.Row.FlexItem flex="0 0 300px" mobileFull>
          <Field
            component={Form.Select}
            disabled={!formValues?.framework}
            name="frameworkGroup"
            options={frameworkGroupsOptions}
            placeholder={i18n.t('module:Learning:CohortTracking:global:areaOfLearning')}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
    </Section>
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {renderFields}
      <ReportGeneratorFilters
        disableEthnicity={COMPARISON_REPORT_TYPES.ETHNICITY === formValues?.type?.value}
        ethnicities={ethnicities}
        onResetFilters={onResetFilters}
      />
      <Form.StickyFooter
        cancelLink={generateRoute('LEARNING.COHORT_TRACKING')}
        submitLabel={i18n.t('global:Generate')}
      />
    </Form>
  )
}

export default reduxForm({
  form: COMPARISON_REPORT_GENERATOR_FORM,
})(ComparisonReportGeneratorForm)
