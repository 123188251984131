import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledWrapper = styled.div`
  display: flex;
  padding-right: 60px;
`

export const StyledContainer = styled.div`
  flex: 1;
  background: #F7F7F7;
  border-radius: 20px;
`

export const StyledTopContainer = styled.div`
  display: flex;
  ${({ hasError }) => hasError && 'margin-top: -16px;'};
  position: relative;
`

export const StyledDetailContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`

export const StyledActionsContainer = styled.div`
  width: 38px;
  background: ${NEUTRAL_COLOURS.SILVER};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 20px 20px 0;

  @media (min-width: 768px) {
    width: 60px;
  }
`

export const StyledEditIconContainer = styled.nav`
  position: absolute;
  top: 0;
  left: 100%;
  height: 140px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledErrorContainer = styled.div`
  padding: 5px 10px 20px 20px;
  background-color: #EE4D9B;
  border-radius: 20px 20px 0 0;
`

export const StyledErrorMsgContainer = styled.div`
  color: ${NEUTRAL_COLOURS.WHITE};
`
