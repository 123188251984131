import styled from 'styled-components'

import layout from 'constants/layout'

import { getBrandingColor } from 'utils/branding'

import { StyledContainer, StyledIconContainer } from 'components/EmptyState/EmptyStateStyled'

export const StyledHeader = styled.header`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
  padding-bottom: 10px;
`

export const StyledApprovals = styled.div`
  padding: 10px;
  background-color: ${getBrandingColor('senary-color')};
  border-radius: ${layout.borderRadius};
  text-align: center;
`

export const StyledEmptyState = styled.div`
  ${StyledContainer} {
    padding: 20px 0;
  }
  
  ${StyledIconContainer} {
    margin-bottom: 10px;
  }
`
