import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_PAYOUT_TRANSACTIONS,
  GET_PAYOUT_TRANSACTIONS,
  GET_PAYOUT_TRANSACTIONS_EXPORT,
  GET_PAYOUT_TRANSACTIONS_EXPORT_FAILED,
  GET_PAYOUT_TRANSACTIONS_EXPORT_SUCCESS,
  GET_PAYOUT_TRANSACTIONS_FAILED,
  GET_PAYOUT_TRANSACTIONS_SUCCESS,
} from './constants'

export const getPayoutTransactions = (
  options: BasicActionOptionsProps,
) => ({ nurseryIntegrationsApiClient }) => generateBasicActions.list({
  constants: {
    failed: GET_PAYOUT_TRANSACTIONS_FAILED,
    init: GET_PAYOUT_TRANSACTIONS,
    success: GET_PAYOUT_TRANSACTIONS_SUCCESS,
  },
  options,
  service: nurseryIntegrationsApiClient,
  serviceMethod: 'payoutTransactions',
})

export const exportPayoutTransactions = (options = {}) => ({
  nurseryIntegrationsApiClient,
}) => generateBasicActions.list({
  constants: {
    failed: GET_PAYOUT_TRANSACTIONS_EXPORT_FAILED,
    init: GET_PAYOUT_TRANSACTIONS_EXPORT,
    success: GET_PAYOUT_TRANSACTIONS_EXPORT_SUCCESS,
  },
  options,
  service: nurseryIntegrationsApiClient,
  serviceMethod: 'exportPayoutTransactions',
})

export const clearPayoutTransactions = () => ({
  type: CLEAR_PAYOUT_TRANSACTIONS,
})
