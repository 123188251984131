import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

const getCreditNotes = (state) => state.creditNotes

const getCreditNotesSingle = createSelector([getCreditNotes], (state) => state.single)

export const getCreditNoteValuesForm = (values) => {
  const body = {}
  const {
    description,
    invoice,
    issueDate,
    sendInvoice,
  } = values

  body.description = description || ''

  if (issueDate) {
    body.issueDate = moment(issueDate).format('YYYY-MM-DD')
  }

  if (invoice) {
    body.invoice = {
      id: invoice,
    }
  }

  if (sendInvoice) {
    body.sendInvoice = sendInvoice
  }

  return body
}

export const getCreditNotesSingleIsFetching = createSelector([getCreditNotesSingle], (data) => {
  if (!data) {
    return null
  }

  return data.isFetching
})

export const getCreditNote = createSelector([getCreditNotesSingle], (data) => {
  if (!data) {
    return null
  }

  return data.data
})

export const getCreditNoteBulkCriteria = (filters) => {
  if (!filters) {
    return null
  }

  const criteria = []
  const { invoiceIds } = filters

  _.each(invoiceIds, (invoiceId) => {
    criteria.push({
      field: 'id[]',
      value: invoiceId,
    })
  })

  return criteria
}
