import { createSelector } from 'reselect'

import { getFilesInitialValues } from 'services/legacy/files/selectors'

const getChildState = (state) => state.child

export const getChildFilesList = createSelector([getChildState], (state) => state.files)

export const getChildFilesListData = createSelector([getChildFilesList], (state) => state?.data)

export const getChildFilesInitialValuesSelector = createSelector(
  [getChildFilesListData],
  getFilesInitialValues,
)
