import _ from 'lodash'

import React from 'react'

import { Callout, Form, InfiniteDropdowns, Section, Spinner } from 'components'

import i18n from 'translations'

import ChildFinanceInvoicesPaymentForm from './components/ChildFinanceInvoicesPaymentForm'

const ChildFinanceInvoicesPaymentView = ({
  balance,
  cancelButtonLink,
  child,
  errorMessages,
  formValues,
  initialValues,
  isChildFetching,
  isSubmitting,
  onChildChange,
  onSubmit,
  receiptNumber,
  showChildrenDropdown,
}) => (
  <Section
    title={i18n.t('module:Children:Child:Finance:Payment:Add:title')}
  >
    <Callout content={errorMessages} error />
    {showChildrenDropdown && (
      <Form>
        <Form.Row label={_.upperFirst(i18n.t('global:child'))} required>
          <Form.Row.FlexItem flex="0 0 150px">
            <InfiniteDropdowns.Children
              value={child}
              onChange={onChildChange}
            />
          </Form.Row.FlexItem>
        </Form.Row>
      </Form>
    )}
    {isChildFetching && <Spinner />}
    <ChildFinanceInvoicesPaymentForm
      balance={balance}
      cancelButtonLink={cancelButtonLink}
      formValues={formValues}
      initialValues={initialValues}
      isSubmitButtonDisabled={showChildrenDropdown && (!child || isChildFetching)}
      isSubmitting={isSubmitting}
      receiptNumber={receiptNumber}
      showForm={!showChildrenDropdown || (child && !isChildFetching)}
      onSubmit={onSubmit}
    />
  </Section>
)

export default ChildFinanceInvoicesPaymentView
