import { generateBasicActions } from 'utils/service'

import { DROPDOWN_LIST_CARERS, DROPDOWN_LIST_CARERS_FAILED, DROPDOWN_LIST_CARERS_SUCCESS } from './constants'

export const dropdownList = (options = {}) => ({ carersApiClient }) => generateBasicActions.list({
  constants: {
    failed: DROPDOWN_LIST_CARERS_FAILED,
    init: DROPDOWN_LIST_CARERS,
    success: DROPDOWN_LIST_CARERS_SUCCESS,
  },
  options,
  service: carersApiClient,
  serviceMethod: 'list',
})

