import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { Form } from 'components'

import i18n from 'translations'

export const SLEEPS_TAB_FORM = 'SleepsTabForm'

const SleepsTabForm = ({
  formValues,
  handleSubmit,
  isSubmitting,
  onCancel,
  onChangeDidNotSleep,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    {(formValues && formValues.didNotSleep) ? (
      <Form.Row label={i18n.t('global:Time')} verticalLabel>
        <Form.Row.Item>
          <Field
            component={Form.TimePicker}
            disabled={isSubmitting}
            name="time"
            validate={isRequired}
          />
        </Form.Row.Item>
      </Form.Row>
    ) : (
      <React.Fragment>
        <Form.Row label={i18n.t('module:DailyDiary:Sleeps:fellAsleep')} verticalLabel>
          <Form.Row.Item>
            <Field
              component={Form.TimePicker}
              disabled={isSubmitting}
              name="startTime"
              validate={isRequired}
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row label={i18n.t('module:DailyDiary:Sleeps:wokeUp')} verticalLabel>
          <Form.Row.Item>
            <Field
              component={Form.TimePicker}
              disabled={isSubmitting}
              name="endTime"
            />
          </Form.Row.Item>
        </Form.Row>
      </React.Fragment>
    )}
    <Form.Row label={i18n.t('module:DailyDiary:Sleeps:childDidNotSleep')} verticalLabel>
      <Form.Row.Item>
        <Field
          component={Form.Switch}
          disabled={isSubmitting}
          name="didNotSleep"
          onChange={onChangeDidNotSleep}
        />
      </Form.Row.Item>
    </Form.Row>
    <Form.Row label={i18n.t('module:DailyDiary:global:additionalNotes')} verticalLabel>
      <Form.Row.FlexItem flex="0 0 420px" mobileFull>
        <Field
          component={Form.TextAreaField}
          disabled={isSubmitting}
          height={120}
          maxCharacters={2000}
          name="comments"
          placeholder={i18n.t('module:DailyDiary:global:enterAdditionalNotesHere')}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.FooterActions
      cancelLabel={i18n.t('global:Back')}
      isSubmitting={isSubmitting}
      onCancelClick={onCancel}
    />
  </Form>
)

const validate = (fields) => {
  const { endTime, startTime } = fields

  const errors = {
    startTime: null,
  }

  if (startTime && endTime && startTime.isSameOrAfter(endTime)) {
    errors.startTime = i18n.t('module:DailyDiary:Sleeps:sleepTimeError')
  }

  return errors
}

export default reduxForm({ form: SLEEPS_TAB_FORM, validate })(SleepsTabForm)
