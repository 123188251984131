import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { RootState } from 'core/reducers'

import { injectActions } from 'utils/service'

import actions from './actions'
import selectors from './selectors'
import { NurseryDiscountsListState } from './list/reducer'
import { NurseryDiscountsSingleState } from './single/reducer'

export interface withNurseryDiscountsV3ServiceProps {
  nurseryDiscountsV3Actions: typeof actions
  nurseryDiscountsV3ListState: NurseryDiscountsListState
  nurseryDiscountsV3Selectors: typeof selectors
  nurseryDiscountsV3SingleState: NurseryDiscountsSingleState
}

const withNurseryDiscountsV3Service = <P extends withNurseryDiscountsV3ServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ nurseryDiscountsV3 }: RootState) => ({ nurseryDiscountsV3 })

  const mapDispatch = injectActions('nurseryDiscountsV3Actions', actions)

  const Component = ({ nurseryDiscountsV3, nurseryDiscountsV3Actions, ...others }) => (
    <WrappedComponent
      nurseryDiscountsV3Actions={nurseryDiscountsV3Actions}
      nurseryDiscountsV3ListState={nurseryDiscountsV3.list}
      nurseryDiscountsV3Selectors={selectors}
      nurseryDiscountsV3SingleState={nurseryDiscountsV3.single}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withNurseryDiscountsV3Service
