import moment from 'moment'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { VIEW_MODE } from 'services/legacy/membershipsShifts/constants'

import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'

export const getNurseryRotaStatistics = (state: RootState) => state.nurseries.rotaStatistics

export const getNurseryRotaStatisticsDataSelector = createSelector(
  [getNurseryRotaStatistics],
  (state) => state.data,
)

export const getNurseryRotaStatisticsMetaSelector = createSelector(
  [getNurseryRotaStatistics],
  (state) => state.meta,
)

export const getRotaStatisticsCriteria = (filters) => {
  const { date, viewMode } = filters || {}

  const criteria = []

  if (date) {
    criteria.push({
      field: 'date',
      value: moment(date).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (viewMode) {
    criteria.push({
      field: 'type',
      value: VIEW_MODE.DAY === viewMode ? 'DAILY' : 'WEEKLY',
    })
  }

  return criteria
}
