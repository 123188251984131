import _ from 'lodash'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { noop } from 'constants/global'

import { generateRoute } from 'utils/routing'

import * as cohortTrackingSelectors from 'services/legacy/cohortTracking/selectors'

import {
  Button,
  DropdownMenu,
  EmptyState,
  Hyperlink,
  Page,
  SearchBar,
  Section,
  Space,
  Spinner,
  Toolbar,
  Typography,
} from 'components'
import ActionPlan from 'module/Learning/CohortTracking/components/ActionPlan'

import i18n from 'translations'

import ProgressTrackerTable from './components/ProgressTrackerTable'
import { StyledFilterWrapper, StyledLegendItem, StyledRectangle, StyledToolbar } from './ProgressTrackerReportStyled'

const ProgressTrackerReportView = ({
  ethnicities,
  frameworksList,
  isFetching,
  itsUnsavedPreview,
  keyWorker,
  nurseryClass,
  onChangeActionPlan,
  onChangeFilters,
  onChangeSearch,
  onRemoveReport,
  onSaveReport,
  progressLevels,
  records,
  report,
  reportType,
  terms,
}) => {
  const frameworkDetails = _.find(frameworksList, ({ id }) => report.framework?.id === id)

  const generateFilters = () => {
    if (itsUnsavedPreview) {
      return cohortTrackingSelectors.generateFiltersLabel(report, {
        ethnicities,
        keyWorker,
        nurseryClass,
      })
    }

    return report.filters || '-'
  }

  const renderFilters = () => !isFetching && (
    <StyledFilterWrapper itsUnsavedPreview={itsUnsavedPreview}>
      {itsUnsavedPreview && (
        <Button
          label={i18n.t('global:Filters')}
          negativeMargins
          onClick={onChangeFilters}
        />
      )}
      <StyledToolbar>
        <div>
          <Typography fontSize={15} bold>
            {i18n.t('global:Framework')}
          </Typography>
          <Typography>
            {_.get(frameworkDetails, 'type', '-')}
          </Typography>
        </div>
        <div>
          <Typography fontSize={15} bold>
            {report?.term ? i18n.t('module:Learning:CohortTracking:ProgressTrackerReport:term') : ''}
            {report?.frameworkArea?.id
              ? i18n.t('module:Learning:CohortTracking:ProgressTrackerReport:areaOfLearning')
              : ''}
          </Typography>
          <Typography>
            {report?.term ? _.get(_.find(terms, ({ value }) => report.term === value), 'label') : ''}
            {report?.frameworkArea
              ? _.find(frameworkDetails?.areas, ({ id }) => id === report.frameworkArea.id)?.name
              : ''}
          </Typography>
        </div>
        <div>
          <Typography fontSize={15} bold>
            {i18n.t('global:Filters')}
          </Typography>
          <Typography maxWidth={500}>
            {generateFilters()}
          </Typography>
        </div>
      </StyledToolbar>
      {itsUnsavedPreview && (
        <Button
          label={i18n.t('global:Save')}
          negativeMargins
          onClick={onSaveReport}
        />
      )}
    </StyledFilterWrapper>
  )

  const renderToolbar = (expandPageButton) => (
    <Toolbar noMargin>
      <Toolbar.Group>
        <Toolbar.Item>
          {expandPageButton}
        </Toolbar.Item>
        {!itsUnsavedPreview && (
          <Toolbar.Item>
            <DropdownMenu>
              <DropdownMenu.Item
                type="delete"
                onClick={onRemoveReport}
              />
            </DropdownMenu>
          </Toolbar.Item>
        )}
      </Toolbar.Group>
    </Toolbar>
  )

  const renderLegend = () => (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <Typography color={NEUTRAL_COLOURS.GRAY} margin="20px 0">
            {i18n.t('module:Learning:CohortTracking:ProgressTrackerReport:description')}
          </Typography>
        </Toolbar.Item>
      </Toolbar.Group>
      <Toolbar.Group>
        {_.map(progressLevels, (progressLevel) => (
          <Toolbar.Item key={progressLevel.id}>
            <StyledLegendItem>
              <StyledRectangle color={progressLevel.colour} />
              <Typography bold>
                {progressLevel.name}
              </Typography>
            </StyledLegendItem>
          </Toolbar.Item>
        ))}
      </Toolbar.Group>
    </Toolbar>
  )

  const renderContent = () => {
    if (isFetching || !frameworkDetails) {
      return (
        <React.Fragment>
          <Space space="20px" />
          <Spinner />
          <Space space="20px" />
        </React.Fragment>
      )
    }

    if (!frameworksList?.length) {
      return (
        <EmptyState
          icon="cohort-tracking"
          text1={i18n.t('module:Learning:CohortTracking:ProgressTrackerGenerator:noFrameworksList')}
          text2={(
            <Hyperlink to={generateRoute('MANAGEMENT.LEARNING.CURRICULUMS')} primary>
              {i18n.t('module:Learning:CohortTracking:global:goToSettings')}
            </Hyperlink>
          )}
        />
      )
    }

    if (!records?.length) {
      return (
        <EmptyState
          icon="cohort-tracking"
          text1={i18n.t('module:Learning:CohortTracking:global:nothingFound')}
        />
      )
    }

    const latestTerms = _.takeRight(terms, 4)

    return (
      <React.Fragment>
        {renderLegend()}
        <ActionPlan
          defaultValue={report.actionPlan}
          initialValues={{ actionPlan: report.actionPlan }}
          itsUnsavedPreview={itsUnsavedPreview}
          onChangeActionPlan={onChangeActionPlan}
          onSubmit={noop}
        />
        <ProgressTrackerTable
          frameworkDetails={frameworkDetails}
          latestTerms={latestTerms}
          records={records}
          report={report}
          reportType={reportType}
          terms={terms}
        />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <SearchBar
        placeholder={i18n.t('module:Learning:CohortTracking:ProgressTrackerReport:search')}
        onChange={onChangeSearch}
      />
      <Page isLoading={isFetching}>
        {(expandPageButton) => (
          <Page.Content>
            <Section
              actions={renderToolbar(expandPageButton)}
              title={i18n.t('module:Learning:CohortTracking:ProgressTrackerReport:title')}
            >
              {renderFilters()}
              {renderContent()}
            </Section>
          </Page.Content>
        )}
      </Page>
    </React.Fragment>
  )
}

export default ProgressTrackerReportView
