import React from 'react'

import { Currency, DateString, DefinitionList, GridLayout, ModalBox } from 'components'

import BadDebtForm from './components/BadDebtForm'

const BadDebtModalView = ({
  initialValues,
  invoice,
  isSubmitting,
  onCloseClick,
  onSubmit,
}) => {
  const { createdAt, name, total, totalOutstanding } = invoice

  return (
    <ModalBox
      title="Mark invoice as bad debt"
      autoHeight
      onCloseClick={onCloseClick}
    >
      <GridLayout>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item>
            <DefinitionList margin="0 0 10px 0" vertical>
              <DefinitionList.Item label="Invoice" value={name} />
              <DefinitionList.Item label="Invoice total" value={<Currency value={total} />} />
              <DefinitionList.Item label="Bad debt amount" value={<Currency value={totalOutstanding} />} />
            </DefinitionList>
          </GridLayout.Item>
          <GridLayout.Item>
            <DefinitionList margin="0 0 10px 0" vertical>
              <DefinitionList.Item label="Created on" value={<DateString date={createdAt} />} />
              <DefinitionList.Item
                label="Outstanding invoice balance"
                value={<Currency value={totalOutstanding} />}
              />
            </DefinitionList>
          </GridLayout.Item>
        </GridLayout.Group>
      </GridLayout>
      <BadDebtForm
        initialValues={initialValues}
        isSubmitting={isSubmitting}
        onCloseClick={onCloseClick}
        onSubmit={onSubmit}
      />
    </ModalBox>
  )
}

export default BadDebtModalView
