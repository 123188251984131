import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { DAILY_DIARY_DATE_FORMAT } from 'services/legacy/dailyDiary/constants'
import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { generateRoute } from 'utils/routing'
import { changeHours } from 'utils/date'

import { withAppService } from 'services/app'
import { withChildDailyDiaryService } from 'services/legacy/childDailyDiary'
import { withDailyDiaryService } from 'services/legacy/dailyDiary'
import { withLikesAndCommentsService } from 'services/likesAndComments'
import { withRouterUtils } from 'services/utils/router'
import { withRouter } from 'services/router'

import DiaryTimelineView from './DiaryTimelineView'

class DiaryTimelineContainer extends Component {
  constructor(props) {
    super(props)

    const { location, setLocationQuery } = props

    const date = location.query.date
      ? changeHours(moment(location.query.date, DAILY_DIARY_DATE_FORMAT), new Date())
      : moment()

    this.state = {
      filters: {
        date,
      },
    }

    setLocationQuery({
      date: moment(date).format(DAILY_DIARY_DATE_FORMAT),
    })
  }

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate() {
    const { location, params, router } = this.props
    const { filters: { date } } = this.state
    const filterDate = date ? date.format(DEFAULT_DATE_FORMAT) : undefined

    const currentDate = location?.query?.date
    const from = location?.query?.from

    if (currentDate && filterDate && currentDate !== filterDate && from) {
      router.replace(`${generateRoute('CHILDREN.CHILD.DAILY_DIARY',
        { childId: params.childId },
      )}?date=${currentDate}`)

      const newDate = changeHours(moment(currentDate, DAILY_DIARY_DATE_FORMAT), new Date())

      this.setState(
        (prevState) => ({
          filters: { ...prevState.filters, date: newDate },
        }),
        this.fetch,
      )
    }
  }

  isParentContext = () => {
    const { location: { pathname } } = this.props

    return `/${generateRoute('MY_DAILY_DIARY')}` === pathname
  }

  fetch = () => {
    const {
      childDailyDiaryActions,
      dailyDiaryActions,
      hasAccessToLikesAndComments,
      location,
      navigate,
      params,
    } = this.props
    const { filters: { date } } = this.state
    const { childId } = params

    const queryParams = {
      from: moment(date).utc().startOf('day').toISOString(),
      includes: hasAccessToLikesAndComments ? ['record.userLike'] : undefined,
      to: moment(date).utc().endOf('day').toISOString(),
    }

    const { query: { t } } = location

    if (this.isParentContext() && !t) {
      return navigate(generateRoute('VARIOUS.NOT_FOUND'))
    }

    if (this.isParentContext()) {
      const token = window.atob(t)

      return dailyDiaryActions.getMyDailyDiary(
        queryParams,
        token,
        null,
      )
    }

    return childDailyDiaryActions.list(childId, queryParams)
  }

  handleDateChange = (date) => {
    const { setLocationQuery } = this.props

    setLocationQuery({
      date: moment(date).format(DAILY_DIARY_DATE_FORMAT),
    })

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, date },
      }),
      this.fetch,
    )
  }

  render() {
    const {
      hasAccessToLikesAndComments,
      hasAccessToLikesAndCommentsSettings,
      isFetching,
      myDailyDiaryChildDetails,
      myDailyDiaryRecords,
      records,
    } = this.props
    const { filters: { date } } = this.state

    return (
      <DiaryTimelineView
        childDetails={myDailyDiaryChildDetails}
        date={date}
        hasAccessToLikesAndComments={hasAccessToLikesAndComments}
        hasAccessToLikesAndCommentsSettings={hasAccessToLikesAndCommentsSettings}
        isFetching={isFetching}
        parentContext={this.isParentContext()}
        records={this.isParentContext() ? myDailyDiaryRecords : records}
        onDateChange={this.handleDateChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childDailyDiaryListState,
  childDailyDiarySelectors,
  dailyDiaryMyDailyDiaryState,
  dailyDiarySelectors,
  likesAndCommentsSelectors,
}) => ({
  hasAccessToLikesAndComments: likesAndCommentsSelectors.hasAccessToLikesAndComments(state),
  hasAccessToLikesAndCommentsSettings: likesAndCommentsSelectors.hasAccessToLikesAndCommentsSettings(state),
  isFetching: appSelectors.getIsFetching(childDailyDiaryListState, dailyDiaryMyDailyDiaryState),
  myDailyDiaryChildDetails: dailyDiarySelectors.getMyDailyDiaryChildDataSelector(state),
  myDailyDiaryRecords: dailyDiarySelectors.getMyDailyDiaryDataSelector(state),
  records: childDailyDiarySelectors.getSortedChildDailyDiaryListSelector(state),
})

const enhance = compose(
  withAppService,
  withChildDailyDiaryService,
  withDailyDiaryService,
  withLikesAndCommentsService,
  withRouter,
  withRouterUtils,
  connect(mapState),
)

export default enhance(DiaryTimelineContainer)
