import _ from 'lodash'

import React from 'react'

import {
  HA_SIGNATURE_LABEL_MAPPINGS,
  OTHER_SIGNATURE_TYPES,
  SIGNATURE_LABEL_MAPPINGS,
  SIGNATURE_TYPES,
} from 'services/legacy/injuries/constants'

import { Button, InfiniteDropdowns, Space } from 'components'

import i18n from 'translations'

import SignatureList from '../SignatureList'
import SignatureDigitalFormContent from './SignatureDigitalFormContent'
import { StyledActionButtonContainer } from './SignatureDigitalFormStyled'

const SignatureDigitalFormStaffSignatures = ({
  disableSignature,
  injurySignatureStatistics,
  isHomeAccidents,
  onAddSignatureClick,
  onAddStaffMemberClick,
  onChangeStaffMember,
  staffSignatures,
}) => _.map(isHomeAccidents ? HA_SIGNATURE_LABEL_MAPPINGS : SIGNATURE_LABEL_MAPPINGS, (value, key) => {
  if (SIGNATURE_TYPES.PARENT === key) {
    return null
  }

  const filterTypes = [key]

  if (key === SIGNATURE_TYPES.STAFF_MEMBER) {
    filterTypes.push(OTHER_SIGNATURE_TYPES.OTHER_STAFF_MEMBER)
  }

  if (key === SIGNATURE_TYPES.WITNESS) {
    filterTypes.push(OTHER_SIGNATURE_TYPES.OTHER_WITNESS)
  }

  const filteredSignatures = _.filter(staffSignatures, ({ type: itemType }) => filterTypes.includes(itemType))

  const ownerIds = []
  _.filter(filteredSignatures, ({ owner }) => {
    if (owner.id) {
      ownerIds.push(owner.id)
    }
  })

  if (!isHomeAccidents && !filteredSignatures?.length) {
    return null
  }

  if (filteredSignatures?.length) {
    return (
      <SignatureList.Group key={key} title={value}>
        {_.map(filteredSignatures, (signatureItem) => {
          const {
            additionalData,
            disabled,
            error,
            id,
            owner,
            signature,
            signedAt,
            type,
            uid,
          } = signatureItem
          const { label, name } = additionalData || {}
          const { url } = signature || {}
          const isParentSinged = 0 === injurySignatureStatistics.parent.signed
          const isOtherSignatureType = [
            OTHER_SIGNATURE_TYPES.OTHER_STAFF_MEMBER,
            OTHER_SIGNATURE_TYPES.OTHER_WITNESS,
          ].includes(type)

          const disableAddButton = (!owner?.id && SIGNATURE_TYPES.PARENT !== key && !isOtherSignatureType)
          || error
          || (isHomeAccidents && isParentSinged)

          return (
            <SignatureList.Row
              content={(
                <SignatureDigitalFormContent
                  disableAddButton={disableAddButton}
                  disabledItem={disabled}
                  signatureId={id}
                  signatureUid={uid}
                  signedAt={signedAt}
                  url={url}
                  onAddSignatureClick={onAddSignatureClick}
                />
                )}
              key={id || uid}
              label={isHomeAccidents || label}
              value={isHomeAccidents
                ? (
                  <InfiniteDropdowns.Users
                    disabled={!!url || isParentSinged}
                    emptySearchNoOptionsMessage={i18n.t(
                      'module:Injury:Preview:Signatures:Digital:contactEmptySearchNoOptionsMessage',
                    )}
                    maxMenuHeight={150}
                    placeholder={i18n.t('module:Safeguarding:Preview:Signatures:Digital:staffMemberPlaceholder')}
                    skipIds={ownerIds}
                    value={owner?.id ? owner : null}
                    onChange={(option) => onChangeStaffMember(filteredSignatures, signatureItem, option)}
                  />
                )
                : name}
            />
          )
        })}
        {isHomeAccidents && (
          <React.Fragment>
            <StyledActionButtonContainer>
              <Button.ActionButton
                disabled={disableSignature}
                label={i18n.t('module:Safeguarding:Add:addStaffMember')}
                onClick={() => onAddStaffMemberClick(filteredSignatures[0])}
              />
            </StyledActionButtonContainer>
            <Space space="65px" />
          </React.Fragment>
        )}
      </SignatureList.Group>
    )
  }

  return (
    isHomeAccidents && (
      <React.Fragment>
        <SignatureList.RowDivider />
        <StyledActionButtonContainer>
          <Button.ActionButton
            disabled={disableSignature}
            label={i18n.t('module:Safeguarding:Add:addStaffMember')}
            onClick={() => onAddStaffMemberClick({ owner: { } })}
          />
        </StyledActionButtonContainer>
      </React.Fragment>
    )
  )
})

export default SignatureDigitalFormStaffSignatures
