import React from 'react'
import { Field } from 'redux-form'

import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

import { Form, Section } from 'components'

import i18n from 'translations'

const SessionFees = ({ isInheritedFromOrganization }) => (
  <Section
    title={i18n.t('module:Management:Finance:Sessions:Add:Fees:title')}
    tooltip={i18n.t('module:Management:Finance:Sessions:Add:Fees:tooltip')}
  >
    <SubdomainCurrencyProvider>
      {({ currencySymbol }) => (
        <React.Fragment>
          <Form.Row label={i18n.t('module:Management:Finance:Sessions:Add:Fees:Form:Under2s:label')}>
            <Form.Row.Item>
              <Field
                component={Form.TextField}
                disabled={isInheritedFromOrganization}
                name="costUnderTwo"
                placeholder={currencySymbol}
                step="any"
                type="number"
              />
            </Form.Row.Item>
          </Form.Row>
          <Form.Row label={i18n.t('module:Management:Finance:Sessions:Add:Fees:Form:TwoYearOld:label')}>
            <Form.Row.Item>
              <Field
                component={Form.TextField}
                disabled={isInheritedFromOrganization}
                name="costTwoYears"
                placeholder={currencySymbol}
                step="any"
                type="number"
              />
            </Form.Row.Item>
          </Form.Row>
          <Form.Row label={i18n.t('module:Management:Finance:Sessions:Add:Fees:Form:ThreeToFiveYearOld:label')}>
            <Form.Row.Item>
              <Field
                component={Form.TextField}
                disabled={isInheritedFromOrganization}
                name="costTwoYearsAbove"
                placeholder={currencySymbol}
                step="any"
                type="number"
              />
            </Form.Row.Item>
          </Form.Row>
        </React.Fragment>
      )}
    </SubdomainCurrencyProvider>
  </Section>
)

export default SessionFees
