import moment from 'moment'

import React, { useEffect } from 'react'

import { FLAG_COLOURS } from 'constants/colors'
import { REGISTER_STATUS, REGISTER_STATUS_OPTIONS } from 'module/Register/constants'

import eventBus from 'utils/eventBus'
import { isSameDay } from 'utils/date'

import { Arc, Button, CircleIcon, DatePicker, Icon, InfiniteDropdowns, Select, Toolbar, Tooltip } from 'components'

import i18n from 'translations'

import {
  StyledActiveCount,
  StyledCountContainer,
  StyledTooltipContainer,
  StyledTooltipWrapper,
  StyledTotalCount,
} from './RegisterHeaderStyled'

const OVERLOAD_REGISTER_FILTERS = 'EVENT/OVERLOAD_REGISTER_FILTERS'

const RegisterHeader = ({
  ExtraSessionsIsReadOnly,
  attendanceSummary,
  date,
  isFinanceV3Enabled,
  isOffline,
  offlineTodayData,
  onAddExtraSessionClick,
  onDateChange,
  onRoomChange,
  onSessionChange,
  onStatusChange,
  room,
  session,
  showWidget,
  status,
  totalCount,
}) => {
  const { active } = attendanceSummary || {}
  const isTimeOutOrAbsenceSelected = status === REGISTER_STATUS.TIMED_OUT || status === REGISTER_STATUS.ABSENT

  useEffect(() => {
    if (isOffline && !offlineTodayData) {
      eventBus.dispatch(OVERLOAD_REGISTER_FILTERS)
    }
  }, [isOffline, offlineTodayData])

  return (
    <div>
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <DatePicker
              disabled={isOffline && !offlineTodayData}
              disabledDays={isOffline && offlineTodayData && ((day) => !isSameDay(moment(), day))}
              value={isOffline && !offlineTodayData ? moment() : date}
              onChange={onDateChange}
            />
          </Toolbar.Item>
          <Toolbar.Item>
            <InfiniteDropdowns.Rooms
              disabled={isOffline}
              overloadValueEventName={OVERLOAD_REGISTER_FILTERS}
              value={room}
              onChange={onRoomChange}
            />
          </Toolbar.Item>
          <Toolbar.Item>
            {isFinanceV3Enabled ? (
              <InfiniteDropdowns.NurserySessionsV3
                disabled={isOffline}
                overloadValueEventName={OVERLOAD_REGISTER_FILTERS}
                placeholder={i18n.t('global:Session')}
                value={session}
                searchable
                onChange={onSessionChange}
              />
            ) : (
              <InfiniteDropdowns.NurserySessions
                disabled={isOffline}
                overloadValueEventName={OVERLOAD_REGISTER_FILTERS}
                placeholder={i18n.t('global:Session')}
                value={session}
                searchable
                onChange={onSessionChange}
              />
            )}
          </Toolbar.Item>
          <Toolbar.Item>
            <Select
              options={REGISTER_STATUS_OPTIONS}
              placeholder={i18n.t('global:Status')}
              value={status}
              clearable
              onChange={onStatusChange}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      <Toolbar bottomLine>
        <Toolbar.Group>
          <Toolbar.Item>
            <StyledTooltipContainer>
              Simply tap in and tap out the children to make your mornings easier.
              <Tooltip
                placement="bottom-end"
                title={(
                  <React.Fragment>
                    Mark children in and out or as absent.
                    <br />
                    <br />
                    Add comments, extra sessions, multiple time in and view a log of all staff member actions
                    on the register.
                    <br />
                    <br />
                    This list has been sorted by children’s session times and then alphabetically.
                  </React.Fragment>
                )}
              >
                <CircleIcon
                  cursor="pointer"
                  icon="info"
                  iconSize={16}
                  size={24}
                />
              </Tooltip>
            </StyledTooltipContainer>
          </Toolbar.Item>
        </Toolbar.Group>
        {showWidget && (
          <Toolbar.Group>
            <Toolbar.Item>
              <StyledCountContainer>
                <StyledActiveCount>
                  {isTimeOutOrAbsenceSelected ? 0 : active}
                  &nbsp;
                </StyledActiveCount>
                <StyledTotalCount>
                  {`of ${totalCount}`}
                </StyledTotalCount>
              </StyledCountContainer>
            </Toolbar.Item>
            <Toolbar.Item>
              <Arc
                content={(
                  <Icon
                    color={FLAG_COLOURS.SUCCESS}
                    height={16}
                    icon="confirmed"
                  />
                )}
                size={40}
                total={totalCount}
                value={isTimeOutOrAbsenceSelected ? 0 : active}
              />
            </Toolbar.Item>
          </Toolbar.Group>
        )}
      </Toolbar>
      {!ExtraSessionsIsReadOnly && (
        <Toolbar>
          <Toolbar.Group>
            <Toolbar.Item>
              <Tooltip title={isOffline && i18n.t('module:Register:youCanNotAddSessionWhenOffline')}>
                <StyledTooltipWrapper $isOffline={isOffline}>
                  <Button.ActionButton
                    disabled={isOffline}
                    label="Add extra sessions"
                    onClick={onAddExtraSessionClick}
                  />
                </StyledTooltipWrapper>
              </Tooltip>
            </Toolbar.Item>
          </Toolbar.Group>
        </Toolbar>
      )}
    </div>
  )
}

export default RegisterHeader
