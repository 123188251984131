import React from 'react'

import { generateRoute } from 'utils/routing'

import { Callout, EmptyState, Page, Pagination, Section, Spinner, Table } from 'components'

import i18n from 'translations'

import { COLUMNS } from './constants'

const ChildContactsListView = ({
  authAccessMap,
  childId,
  errorMessages,
  isEmpty,
  isFetching,
  onPageChange,
  page,
  pageCount,
  perPage,
  tableData,
  totalResults,
}) => {
  const renderPagination = () => !isFetching && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('module:Children:Child:Contacts:List:Pagination:plural')}
      titleSingular={i18n.t('module:Children:Child:Contacts:List:Pagination:singular')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    if (isEmpty) {
      return (
        <EmptyState
          icon="group"
          text1={i18n.t('module:Children:Child:Contacts:List:notFound')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={COLUMNS}
          data={tableData}
          visualType="transparent"
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const actions = authAccessMap.module.ChildContactsAdd && (
    <Section.Actions
      primary={[{
        label: i18n.t('module:Children:Child:Contacts:List:AddNewContact'),
        to: generateRoute('CHILDREN.CHILD.CONTACTS.ADD', { childId }),
      }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isFetching}
      title={i18n.t('module:Children:Child:Contacts:List:Contacts')}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default ChildContactsListView
