import { bindActionCreators } from 'redux'

import { hasMoreRecords } from 'utils/pagination'

import { CLEAR_ROOMS, LIST_ROOMS, LIST_ROOMS_FAILED, LIST_ROOMS_SUCCESS, VALIDATE_ROOMS } from './constants'

export const list = (
  params,
  mergeResult,
  onSuccess = () => {},
  onlyData,
) => ({ roomsApiClient }) => async (dispatch) => {
  if (!onlyData) {
    dispatch({
      type: LIST_ROOMS,
    })
  }

  try {
    const response = await roomsApiClient.list(params)

    onSuccess(response)

    if (!onlyData) {
      dispatch({
        mergeResult,
        payload: { done: true, response },
        type: LIST_ROOMS_SUCCESS,
      })
    }
  } catch (error) {
    if (!onlyData) {
      dispatch({
        error: error.response,
        type: LIST_ROOMS_FAILED,
      })
    }

    throw new Error(error)
  }
}

export const clear = () => ({
  type: CLEAR_ROOMS,
})

export const validateRooms = (payload) => ({
  payload,
  type: VALIDATE_ROOMS,
})

export const listRecurrency = (params = {}, onSuccess = () => {}) => ({ roomsApiClient }) => (dispatch) => {
  dispatch({ type: LIST_ROOMS })

  return roomsApiClient.list(params).then(
    (response) => {
      const { meta } = response

      if (hasMoreRecords(meta)) {
        dispatch({
          mergeResult: 1 !== meta.start,
          payload: { response },
          type: LIST_ROOMS_SUCCESS,
        })

        return bindActionCreators(listRecurrency, dispatch)(
          { ...params, page: (meta.start + 1) },
          onSuccess,
        )
      }

      dispatch({
        mergeResult: 1 !== meta.start,
        payload: { done: true, response },
        type: LIST_ROOMS_SUCCESS,
      })

      return onSuccess(response)
    },
    ({ response }) => dispatch({ payload: response, type: LIST_ROOMS_FAILED }),
  )
}
