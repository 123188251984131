import moment from 'moment'

import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { ROLES } from 'constants/security'

import { withAppService } from 'services/app'
import { withFinanceRevenuePerRoomReportService } from 'services/legacy/financeRevenuePerRoomReport'
import { withPaginationUtils } from 'services/utils/pagination'
import { withSecurityService } from 'services/security'
import { withRouter } from 'services/router'

import { useBasicReport } from 'hooks/reports'

import { DatePicker, Form, ReportView, Toolbar } from 'components'

import i18n from 'translations'

import { getColumns, getStatistics, getTableData } from './RevenuePerRoomHelper'

const CHILDREN_BALANCE_GROUPS = {
  read: [
    'nurseryClass',
  ],
}

const NURSERIES_BALANCE_GROUPS = {
  read: [
    'nursery',
  ],
}

const RevenuePerRoomContainer = ({
  appProperties,
  childrenData,
  childrenTotalResults,
  errorMessages,
  financeRevenuePerRoomReportActions,
  financeRevenuePerRoomReportSelectors,
  isFetching,
  isOrganizationContext,
  isStatisticsFetching,
  location,
  nurseriesData,
  nurseriesTotalResults,
  paginationUtils,
  setLocationQuery,
  statisticsData,
}) => {
  const {
    dateRange,
    onDateChange,
    onPageChange,
    page,
    pageCount,
    perPage,
    statistics,
    tableColumns,
    tableData,
    totalResults,
  } = useBasicReport({
    actions: financeRevenuePerRoomReportActions,
    childrenApiGroups: CHILDREN_BALANCE_GROUPS,
    childrenData,
    childrenTotalResults,
    defaultFilters: {
      date: {
        after: moment().startOf('month'),
        before: moment(),
      },
    },
    errorMessages,
    getColumns,
    getStatistics,
    getTableData: (props) => getTableData({ ...props, appProperties }),
    isOrganizationContext,
    location,
    nurseriesApiGroups: NURSERIES_BALANCE_GROUPS,
    nurseriesData,
    nurseriesTotalResults,
    paginationUtils,
    selectors: financeRevenuePerRoomReportSelectors,
    setLocationQuery,
    statisticsData,
  })

  return (
    <ReportView.DetailPage
      description={isOrganizationContext
        ? i18n.t('module:Finance:Reports:RevenuePerRoom:Organization:description')
        : i18n.t('module:Finance:Reports:RevenuePerRoom:description')}
      errorMessages={errorMessages}
      filters={(
        <Toolbar bottomLine>
          <Toolbar.Group>
            <Toolbar.Item>
              <Form.Row
                label={i18n.t('module:Finance:Reports:RevenuePerAge:Filters:Date:label')}
                verticalLabel
              >
                <DatePicker
                  clearable={false}
                  disabledDays={{
                    after: moment().toDate(),
                  }}
                  value={dateRange}
                  range
                  onChange={onDateChange}
                />
              </Form.Row>
            </Toolbar.Item>
          </Toolbar.Group>
        </Toolbar>
      )}
      isOrganizationContext={isOrganizationContext}
      isPageLoading={isFetching && isStatisticsFetching && 1 === page}
      isStatisticsLoading={isStatisticsFetching}
      isTableLoading={isFetching}
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      statistics={statistics}
      tableColumns={tableColumns}
      tableData={tableData}
      title={i18n.t('module:Finance:Reports:RevenuePerRoom:title')}
      totalResults={totalResults}
      hideExport
      onPageChange={onPageChange}
    />
  )
}

RevenuePerRoomContainer.authParams = {
  antiRoles: [
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
  ],
}

const mapState = (state, {
  appSelectors,
  financeRevenuePerRoomReportChildrenState,
  financeRevenuePerRoomReportNurseriesState,
  financeRevenuePerRoomReportSelectors,
  financeRevenuePerRoomReportStatisticsState,
  securitySelectors,
}) => ({
  appProperties: appSelectors.getApplicationProperties(state),
  childrenData: financeRevenuePerRoomReportSelectors.getFinanceRevenuePerRoomReportChildrenData(state),
  childrenTotalResults: appSelectors.getTotalResults(financeRevenuePerRoomReportChildrenState),
  errorMessages: appSelectors.getErrorMessages(
    financeRevenuePerRoomReportChildrenState,
    financeRevenuePerRoomReportNurseriesState,
    financeRevenuePerRoomReportStatisticsState,
  ),
  isFetching: appSelectors.getIsFetching(
    financeRevenuePerRoomReportChildrenState,
    financeRevenuePerRoomReportNurseriesState,
  ),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
  isStatisticsFetching: appSelectors.getIsFetching(financeRevenuePerRoomReportStatisticsState),
  nurseriesData: financeRevenuePerRoomReportSelectors.getFinanceRevenuePerRoomReportNurseriesData(state),
  nurseriesTotalResults: appSelectors.getTotalResults(financeRevenuePerRoomReportNurseriesState),
  statisticsData: financeRevenuePerRoomReportSelectors.getFinanceRevenuePerRoomReportStatisticsData(state),
})

const enhance = compose(
  withRouter,
  withAppService,
  withFinanceRevenuePerRoomReportService,
  withPaginationUtils,
  withSecurityService,
  connect(mapState),
)

export default enhance(RevenuePerRoomContainer)
