import React, { PropsWithChildren } from 'react'

import { getBrandingColor } from 'utils/branding'

import { CircleIcon, Typography } from 'components'

import { StyledContent, StyledTitle, StyledWrapper } from './AlertBoxStyled'

interface AlertBoxProps {
  color?: string
  content: React.ReactNode
  fitContent?: boolean
  icon?: IconType
  title?: string
}

const AlertBox: React.FC<PropsWithChildren<AlertBoxProps>> = ({
  color = getBrandingColor('primary-color'),
  content,
  fitContent,
  icon = 'exclamation-mark',
  title,
}) => (
  <StyledWrapper $color={color} $fitContent={fitContent}>
    <StyledTitle>
      <CircleIcon
        background={color}
        icon={icon}
        size={20}
      />
      <Typography color={color} margin="0 0 0 10px" bold>
        {title}
      </Typography>
    </StyledTitle>
    <StyledContent>
      {content}
    </StyledContent>
  </StyledWrapper>
)

export default AlertBox
