import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { VIEW_MODE } from 'services/legacy/membershipsShifts/constants'
import { FEATURE_FLAGS, ROLES } from 'constants/security'

import { withAppService } from 'services/app'
import { withPaginationUtils } from 'services/utils/pagination'
import { withNurseriesService } from 'services/nurseries'

import NurseryRotaView from './NurseryRotaView'

const NURSERIES_GROUPS = {
  read: ['nursery.extraInfo'],
}

const LIST_LIMIT = 5

class NurseryRotaContainer extends Component {
  constructor(props) {
    super(props)

    const {
      location: { query: { date, viewMode } },
      paginationUtils: { setPageLocationQuery, setPerPage },
    } = props

    this.state = {
      filters: {
        date: date
          ? moment(date, DEFAULT_DATE_FORMAT)
          : moment().startOf('week'),
        day: VIEW_MODE.DAY === viewMode ? moment(date, DEFAULT_DATE_FORMAT) : null,
        viewMode: (VIEW_MODE.DAY === viewMode || VIEW_MODE.WEEK === viewMode) ? viewMode : VIEW_MODE.WEEK,
      },
    }

    setPerPage(LIST_LIMIT)
    setPageLocationQuery(false)
  }

  componentDidMount() {
    this.fetch()
  }

  setQuery = () => {
    const { setLocationQuery } = this.props
    const { filters } = this.state
    const { date, viewMode } = filters

    setLocationQuery({
      date: moment(date).format(DEFAULT_DATE_FORMAT),
      viewMode,
    })
  }

  fetch = () => {
    const { nurseriesActions, paginationUtils } = this.props
    const { page } = paginationUtils

    this.setQuery()

    nurseriesActions.list({
      mergeResult: 1 !== page,
      onSuccess: this.fetchStatistics,
      params: {
        groups: NURSERIES_GROUPS,
        limit: LIST_LIMIT,
        page,
      },
      recurrency: true,
    })
  }

  handleChangeDate = (date) => {
    this.setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        date: date[0],
        day: VIEW_MODE.DAY === prev.filters.viewMode ? date[0] : null,
      },
    }), this.setQuery)
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleChangeViewMode = (viewMode) => {
    this.setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        day: VIEW_MODE.DAY === viewMode?.value
          ? prev.filters.date
          : null,
        viewMode: viewMode?.value,
      },
    }), this.setQuery)
  }

  handleChangeDay = (index) => {
    this.setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        day: moment(prev.filters.date).add(index, 'days'),
      },
    }), this.setQuery)
  }

  render() {
    const {
      isFetching,
      nurseries,
      paginationUtils,
      totalResults,
    } = this.props
    const { filters } = this.state
    const { date, day, viewMode } = filters
    const { getPageCount, page } = paginationUtils
    const dateRange = [date, moment(date).endOf('week')]

    const pageCount = getPageCount(totalResults)

    return (
      <NurseryRotaView
        date={date}
        dateRange={dateRange}
        day={day}
        isFetching={isFetching}
        nurseries={nurseries}
        page={page}
        pageCount={pageCount}
        totalResults={totalResults}
        viewMode={viewMode}
        onChangeDate={this.handleChangeDate}
        onChangeDay={this.handleChangeDay}
        onChangeViewMode={this.handleChangeViewMode}
        onPageChange={this.handlePageChange}
      />
    )
  }
}

NurseryRotaContainer.authParams = {
  flags: [FEATURE_FLAGS.STAFF_REGISTER],
  organizationContext: true,
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.DEPUTY_MANAGER,
    ROLES.ROOM_LEADER,
    ROLES.SENIOR_TEACHER,
    ROLES.TEACHER,
  ],
}

const mapState = (state, {
  appSelectors,
  nurseriesListState,
  nurseriesSelectors,
}) => ({
  isFetching: appSelectors.getIsFetching(nurseriesListState),
  nurseries: nurseriesSelectors.getOrganizationNurseriesDataWithPublicUrl(state),
  totalResults: appSelectors.getTotalResults(nurseriesListState),
})

const enhance = compose(
  withAppService,
  withPaginationUtils,
  withNurseriesService,
  connect(mapState),
)

export default enhance(NurseryRotaContainer)
