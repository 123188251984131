import { createSelector } from 'reselect'

const getEnquiryReports = (state) => state.enquiryReports

export const getEnquiryReportsViewingPerformanceStatisticsSelector = createSelector(
  [getEnquiryReports],
  (state) => state?.viewingPerformanceStatistics,
)

export const getEnquiryReportsViewingPerformanceStatisticsDataSelector = createSelector(
  [getEnquiryReportsViewingPerformanceStatisticsSelector],
  (state) => state.data,
)
