import React from 'react'

import { Button, FooterActions, ModalBox, Section, Typography } from 'components'

import { StyledLogoContainer } from './TermsUpdateModalStyled'

const TermsUpdateModal = ({ hideModal, onConfirm }) => {
  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm()
    }

    hideModal()
  }

  return (
    <ModalBox maxWidth={450} autoHeight noHeader>
      <StyledLogoContainer>
        <img
          alt="Blossom Educational"
          src="/images/logo.png"
        />
      </StyledLogoContainer>
      <Section title="We’re updating our terms">
        <Typography margin="20px 0">
          <p>
            As part of our effort to improve your experience of the Blossom Platform and outline your rights as a
            customer more specifically, we’re updating the terms.
          </p>
          <p>By continuing below, you agree to our terms and conditions.</p>
          <p>
            To learn more click here to view our
            {' '}
            <a
                href="https://blossomeducational.com/wp-content/uploads/2018/05/Blossom-Educational-B-Conditions-v-18-May-2018.pdf" // eslint-disable-line
              rel="noreferrer noopener"
              target="_blank"
            >
              terms and conditions
            </a>
            .
          </p>
        </Typography>
      </Section>
      <FooterActions>
        <FooterActions.Item>
          <Button
            label="Continue"
            negativeMargins
            onClick={handleConfirm}
          />
        </FooterActions.Item>
      </FooterActions>
    </ModalBox>
  )
}

export default TermsUpdateModal
