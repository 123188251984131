import styled from 'styled-components'

import layout from 'constants/layout'
import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  margin: 20px 0;

  :last-child {
    border-bottom: 1px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  }

  @media(max-width: ${layout.mobileWidthResolution}px) {
    grid-column-gap: 0;
  }
`

export const StyledItemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr auto;
  padding: 10px 20px;
  grid-gap: 20px;
  align-items: center;
  border-top: 1px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};  
  font-size: 16px;
`
