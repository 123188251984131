import _ from 'lodash'

import React, { useMemo } from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import * as OccupancyConstants from 'services/legacy/occupancy/constants'

import { Button, Callout, Chart, DataTable, EmptyState, InfiniteScroll, Page, Section, Spinner } from 'components'

import OverviewReport from './components/OverviewReport'
import ReportContent from './components/ReportContent'
import ReportFilter from './components/ReportFilter'
import ReportHeader from './components/ReportHeader'
import { WEEKS_PER_PAGE } from './OccupancyReportContainer'
import { customChartConfig } from './OccupancyReportHelper'
import { getContentWidth } from './components/ReportContent/ReportContentHelper'
import { StyledTimeLabel } from './components/ReportContent/ReportContentStyled'
import { StyledChart, StyledChartContainer } from './OccupancyReportStyled'

const OccupancyReportView = ({
  errorMessages,
  expandedRooms,
  filters,
  isFetching,
  isOverviewReport,
  isStaffingEnabled,
  itemsToDownload,
  onChangeDate,
  onChildrenRowClick,
  onFilterClick,
  onLoadMoreElements,
  onPageChange,
  overviewNextPageIsLoading,
  page,
  pageCount,
  reportChart,
  reportData,
  reportHeader,
  rooms,
}) => {
  const isAmPmBreakdown = filters?.breakdown === OccupancyConstants.OCCUPANCY_REQUEST_BREAKDOWN.AM_PM
  const contentWidth = useMemo(
    () => getContentWidth(isAmPmBreakdown, filters?.type, filters?.breakdown),
    [filters?.breakdown, filters?.type],
  )

  const renderSpinner = (renderText) => { // eslint-disable-line
    let percentage = 0

    if (isOverviewReport) {
      percentage = parseInt(((WEEKS_PER_PAGE - itemsToDownload) / WEEKS_PER_PAGE) * 100)
    }

    const text = overviewNextPageIsLoading
      ? `The next elements are loading... ${percentage}%`
      : `Your report is being generated... ${percentage}%`

    return (
      <EmptyState
        iconComponent={(
          <Spinner />
        )}
        text1={renderText && text}
      />
    )
  }

  const renderColumnChart = () => {
    if (!reportChart.data || isOverviewReport) {
      return null
    }

    return (
      <StyledChartContainer>
        <Button
          hierarchy="secondary"
          icon="chevron-left"
          size="small"
          negativeMargins
          onClick={() => onChangeDate(-1)}
        />
        <StyledChart>
          <Chart.Column
            categories={reportChart.header}
            config={customChartConfig}
            data={[{
              data: reportChart.data,
              name: 'FTE',
            }]}
          />
        </StyledChart>
        <Button
          hierarchy="secondary"
          icon="chevron-right"
          size="small"
          negativeMargins
          onClick={() => onChangeDate(1)}
        />
      </StyledChartContainer>
    )
  }

  const renderStickyHeader = () => _.map(reportHeader, ({ breakdowns, label }, dayNumber) => (
    <DataTable.Column key={`${label}_${dayNumber}`}>
      <DataTable.Row background={NEUTRAL_COLOURS.WHITE} noBorder>
        <strong>
          {label}
        </strong>
      </DataTable.Row>
      {breakdowns.length ? (
        <DataTable.Row background={NEUTRAL_COLOURS.WHITE} noBorder noPadding>
          {_.map(breakdowns, (breakdownData, i) => (
            <DataTable.Column key={breakdownData.label} noBorder>
              <DataTable.Row
                gray={0 === i % 2}
                width={contentWidth}
                noBorder
              >
                <StyledTimeLabel>
                  {breakdownData.label}
                </StyledTimeLabel>
              </DataTable.Row>
            </DataTable.Column>
          ))}
        </DataTable.Row>
      ) : null}
    </DataTable.Column>
  ))

  const renderContent = () => {
    if (
      isOverviewReport
      && !overviewNextPageIsLoading
      && (null === itemsToDownload || 0 < itemsToDownload || isFetching)
      && 1 === page
    ) {
      if (null === itemsToDownload) {
        return renderSpinner()
      }

      return renderSpinner(true)
    }

    if (
      !isOverviewReport
      && isFetching
      && !overviewNextPageIsLoading
      && 1 === page
    ) {
      return renderSpinner()
    }

    if (!reportData) {
      return (
        <EmptyState
          icon="occupancy"
          text1="There are no data to display in the report"
        />
      )
    }

    if (isOverviewReport) {
      return (
        <React.Fragment>
          <OverviewReport
            overviewNextPageIsLoading={overviewNextPageIsLoading}
            reportData={reportData}
            reportHeader={reportHeader}
            rooms={rooms}
            onLoadMoreElements={onLoadMoreElements}
          />
          {overviewNextPageIsLoading && renderSpinner(true)}
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        {renderColumnChart()}
        <InfiniteScroll
          dataLength={reportData ? Object.keys(reportData).length : 0}
          hasMore={page < pageCount}
          next={() => onPageChange(+page + 1)}
        >
          <DataTable>
            <DataTable.Head>
              <ReportHeader
                expandedRooms={expandedRooms}
                filters={filters}
                isOverviewReport={isOverviewReport}
                isStaffingEnabled={isStaffingEnabled}
                reportChart={reportChart}
                reportData={reportData}
                rooms={rooms}
                onChildrenRowClick={onChildrenRowClick}
              />
            </DataTable.Head>
            <DataTable.ContentScrollable
              data={[{
                data: (
                  <ReportContent
                    expandedRooms={expandedRooms}
                    filters={filters}
                    isStaffingEnabled={isStaffingEnabled}
                    reportChart={reportChart}
                    reportData={reportData}
                    reportHeader={reportHeader}
                    rooms={rooms}
                  />
                ),
                stickyHeader: () => [renderStickyHeader()],
              }]}
              enableCustomScrollbar
            />
          </DataTable>
        </InfiniteScroll>
      </React.Fragment>
    )
  }

  return (
    <Page.Section
      actions={(
        <Section.Actions
          tertiary={{
            label: 'Filters',
            onClick: onFilterClick,
          }}
        />
      )}
      alwaysExpanded={isOverviewReport}
      isLoading={(
        isOverviewReport
        && !overviewNextPageIsLoading
        && (null === itemsToDownload || 0 < itemsToDownload || isFetching)
        && 1 === page
      ) || (
        !isOverviewReport
        && isFetching
        && !overviewNextPageIsLoading
        && 1 === page
      )}
      title="Occupancy report"
    >
      <Callout content={errorMessages} error />
      <ReportFilter
        filters={filters}
        isOverviewReport={isOverviewReport}
        rooms={rooms}
      />
      {renderContent()}
    </Page.Section>
  )
}

export default OccupancyReportView
