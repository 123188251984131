import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class NurseryPaymentTypesApiClient extends ServiceBase {
  list = (params = {}) => this.request({
    path: `/v2/payment_types?${getQueryStringV2(params)}`,
  })

  get = (id) => this.request({
    path: `/v2/payment_types/${id}`,
  })

  create = (body) => this.request({
    body,
    method: 'POST',
    path: '/v2/payment_types',
  })

  update = (id, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/payment_types/${id}`,
  })
}

export default NurseryPaymentTypesApiClient
