import React, { PropsWithChildren } from 'react'

import { StyledGroupHeader } from './DataTableStyled'

const DataTableGroupHeader: React.FC<PropsWithChildren<any>> = ({ children }) => (
  <StyledGroupHeader>
    {children}
  </StyledGroupHeader>
)

export default DataTableGroupHeader
