import styled from 'styled-components'

export const StyledAvatarContainer = styled.div`
  display: flex;
  align-items: center;
`

export const StyledAvatarContentContainer = styled.div`
  display: block;
  padding-left: 10px;
  text-align: left;
`

export const StyledConfirmed = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
