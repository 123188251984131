import moment from 'moment'
import _ from 'lodash'

import { createSelector } from 'reselect'

import { COMPLETED_STATE, WORKING_ON_STATE } from 'services/legacy/frameworkProgressLevels/constants'

import { toInt } from 'utils/data'

import { hasHalfTermEnabled } from 'services/security/featureFlags/selectors'
import { getAuthUser, hasOnlySeniorTeacherOrTeacherAccess } from 'services/security/selectors'

import i18n from 'translations'

import {
  ASSESSMENT_PERIOD_CUSTOM,
  FORMATIVE_REPORTS_STATUSES,
  SELECT_ALL_LABEL_OPTION,
  TERM_CUSTOM,
  TERM_CUSTOM_VALUE,
  TERM_STARTING_POINT,
  TYPE_OF_REPORT_DROPDOWN,
  TYPE_OF_TRACKING,
} from '../constants'

const getFormativeReports = (state) => state.formativeReports

export const getFormativeReportsSingle = createSelector(
  [getFormativeReports],
  (state) => state.single,
)

export const isExporting = createSelector(
  [getFormativeReportsSingle],
  (state) => state.isExporting,
)

export const getFormativeReport = createSelector(
  [getFormativeReportsSingle],
  (state) => state.data,
)

export const getExportCriteria = createSelector((state) => state, ({ child, fields }) => {
  const criteria = []

  if (child) {
    const { id } = child

    criteria.push({
      field: 'id',
      value: id,
    })
  }

  _.forEach(fields, (item, key) => {
    switch (key) {
      case 'label':
        if (item?.value !== SELECT_ALL_LABEL_OPTION.value) {
          criteria.push({
            field: 'label',
            value: item.value,
          })
        }
        break

      case 'manager':
        if (item) {
          criteria.push({
            field: 'email',
            value: item.email,
          })
        }
        break

      case 'memberships':
        _.forEach(item, (membership) => {
          criteria.push({
            field: 'memberships[]',
            value: membership.value,
          })
        })
        break

      case 'carers':
        _.forEach(item, (carer) => {
          criteria.push({
            field: 'carers[]',
            value: carer.value,
          })
        })
        break

      default:
        break
    }
  })

  return criteria
})

const SEASONS = ['spring', 'summer', 'autumn']

const YEAR_FROM = 2016

export const getTerms = createSelector(
  [hasHalfTermEnabled],
  (isHalfTermEnabled) => {
    const yearFrom = YEAR_FROM
    const yearTo = new Date().getFullYear() + 1
    const terms = []

    for (let i = yearFrom; i <= yearTo; i += 1) {
      _.each(SEASONS, (season) => {
        terms.push({
          label: `${season.charAt(0).toUpperCase() + season.slice(1)} ${i}`,
          value: `${i}_${season.toUpperCase()}`,
        })

        if (isHalfTermEnabled) {
          terms.push({
            label: `${season.charAt(0).toUpperCase() + season.slice(1)} ${i} 2`,
            value: `${i}_${season.toUpperCase()}_2`,
          })
        }
      })
    }

    terms.unshift({
      label: 'Starting Point',
      value: TERM_STARTING_POINT,
    })

    return terms
  },
)

export const getTermsDropdown = createSelector([getTerms], (terms) => ([
  ...terms || {}, {
    label: _.upperFirst(i18n.t('global:custom')),
    value: TERM_CUSTOM,
  },
]))

export const getFormData = (fields, child) => {
  const DATE_FORMAT = 'YYYY-MM-DD'
  const body = {}
  const {
    childVoice,
    date,
    nurseryComments,
    period,
    periodCustom,
    sharedWithParents = false,
    term,
    termCustom,
    type,
  } = fields

  if (child) {
    body.child = {
      id: +child,
    }
  }

  if (date) {
    const [dateFrom, dateTo] = date

    if (dateFrom) {
      body.dateFrom = moment(dateFrom).format(DATE_FORMAT)
    }

    if (dateTo) {
      body.dateTo = moment(dateTo).format(DATE_FORMAT)
    }
  }

  if (type && type.value) {
    body.type = type.value
  }

  body.sharedWithParents = sharedWithParents

  if (term?.value) {
    if (term.value === TERM_CUSTOM) {
      body.period = {
        custom: true,
        key: termCustom,
        type: 'term',
      }
    } else {
      body.period = {
        custom: false,
        key: term.value,
        type: 'term',
      }
    }
  }

  if (period?.value) {
    if (period.value === ASSESSMENT_PERIOD_CUSTOM) {
      body.period = {
        custom: true,
        key: periodCustom,
        label: periodCustom,
        type: 'assessmentPeriod',
      }
    } else {
      body.period = {
        custom: false,
        key: period.value,
        label: period.label,
        type: 'assessmentPeriod',
        value: period.age,
      }
    }
  }

  if (nurseryComments) {
    body.nurseryComments = nurseryComments
  }

  if (childVoice) {
    body.childVoice = childVoice
  }

  return body
}

export const getPreviewInitialValues = (report, assessmentPeriods, terms, { formativeReportId } = {}) => {
  // NOTE: due to the autosave sometimes the UPDATE_FORMATIVE_REPORTS_COMMENTS action
  // finish after FormativeReportsPreviewContainer::willUnmount lifecycle. Because on that,
  // the previous FR data are set as redux-form initial values. To prevent this, we compare
  // the redux state data with the URL query param to be sure the right data are set in the FR form
  if (!report || (formativeReportId && toInt(formativeReportId) !== report.id)) {
    return null
  }

  const values = {}

  const { attainmentLevels, childVoice, comments, dateFrom, dateTo, nurseryComments, period, type } = report

  if (attainmentLevels) {
    values.attainmentLevels = {}

    _.each(attainmentLevels, ({ frameworkGroup, progressLevel, range }) => {
      values.attainmentLevels[frameworkGroup.id] = {
        progressLevel: progressLevel?.id,
        range,
      }
    })
  }

  if (dateFrom && dateTo) {
    values.date = [
      moment(dateFrom)
        .set('hour', 0)
        .set('minutes', 0)
        .format(),
      moment(dateTo)
        .set('hour', 0)
        .set('minutes', 0)
        .format(),
    ]
  }

  if (type) {
    values.type = (
      _.find(TYPE_OF_REPORT_DROPDOWN, ({ value }) => value === type)
    )
  }

  if (period) {
    if (period.custom && period.type === TYPE_OF_TRACKING.ASSESSMENT) {
      values.periodCustom = period.label
      values.period = { ...period }
    } else if (period.custom && period.type === TYPE_OF_TRACKING.TERM) {
      values.termCustom = period.label
      values.term = TERM_CUSTOM_VALUE
    } else if (period.type === TYPE_OF_TRACKING.ASSESSMENT) {
      values.period = _.find(assessmentPeriods, ({ age }) => period.value === age)
    } else if (period.type === TYPE_OF_TRACKING.TERM) {
      values.term = _.find(terms, ({ value }) => value === period.label)
    }
  }

  if (childVoice) {
    values.childVoice = childVoice
  }

  if (nurseryComments) {
    values.nurseryComments = nurseryComments
  }

  if (comments) {
    values.comments = {}
    _.each(comments, ({ comment, frameworkArea, type: commentType }) => {
      if (!values.comments[frameworkArea.id]) {
        values.comments[frameworkArea.id] = {}
      }

      values.comments[frameworkArea.id][commentType] = comment
    })
  }

  return values
}

export const isFormativeReportOwner = createSelector(
  [getAuthUser, getFormativeReport],
  (authUser, formativeReport) => (
    authUser && formativeReport && formativeReport.author && (formativeReport.author.id === authUser.id)
  ),
)

export const isGrantedToApprove = createSelector(
  [hasOnlySeniorTeacherOrTeacherAccess, getFormativeReport],
  (isOnlyTeacherOrSeniorTeacher, formativeReport) => formativeReport
    && formativeReport.status !== FORMATIVE_REPORTS_STATUSES.APPROVED.value
    && !isOnlyTeacherOrSeniorTeacher,
)

export const isGrantedForSendingToPendingApproval = createSelector(
  [hasOnlySeniorTeacherOrTeacherAccess, getFormativeReport, isFormativeReportOwner],
  (isOnlyTeacherOrSeniorTeacher, formativeReport, isOwner) => formativeReport
      && formativeReport.status !== FORMATIVE_REPORTS_STATUSES.APPROVED.value
      && formativeReport.status !== FORMATIVE_REPORTS_STATUSES.PENDING_APPROVAL.value
      && isOwner
      && isOnlyTeacherOrSeniorTeacher,
)

export const isGrantedToEditFormativeReport = createSelector(
  [hasOnlySeniorTeacherOrTeacherAccess, getFormativeReport, isFormativeReportOwner],
  (isOnlyTeacherOrSeniorTeacher, formativeReport, isOwner) => {
    if (formativeReport && !(isOnlyTeacherOrSeniorTeacher)) {
      return true
    }

    return formativeReport && formativeReport.status !== FORMATIVE_REPORTS_STATUSES.APPROVED.value
      && isOwner
  },
)

export const getFormattedItems = (items, activeAreaId) => {
  const itemsFilteredByArea = _.filter(
    items,
    ({ frameworkItem: { category: { group: { area: { id } } } } }) => (
      activeAreaId === id
    ),
  )

  const completedStatements = _.filter(
    itemsFilteredByArea,
    ({ progressLevel }) => progressLevel?.state === COMPLETED_STATE,
  )

  const statementsBeingWorkedOn = _.filter(
    itemsFilteredByArea,
    ({ progressLevel }) => progressLevel?.state === WORKING_ON_STATE,
  )

  const completedStatementsGroupedByGroup = _.flow(
    (elements) => _.groupBy(elements, (e) => e.frameworkItem.category.group.name),
    (elements) => _.map(elements, (values, name) => ({ name, values })),
  )(completedStatements)

  const statementsBeingWorkedOnByGroup = _.flow(
    (elements) => _.groupBy(elements, (e) => e.frameworkItem.category.group.name),
    (elements) => _.map(elements, (values, name) => ({ name, values })),
  )(statementsBeingWorkedOn)

  return {
    completedStatementsGroupedByGroup,
    statementsBeingWorkedOnByGroup,
  }
}

export const getFormattedPreviewData = (areas, statusItems) => {
  let formattedAreas = _.map(areas, ({ groups, ...restArea }) => ({
    ...restArea,
    groups: _.map(groups, ({ categories, ...restGroup }) => ({
      ...restGroup,
      categories: _.map(categories, ({ items, ...restCategory }) => ({
        ...restCategory,
        items: _.filter(items, (item) => (
          !!_.find(statusItems, ({ frameworkItem }) => (
            frameworkItem?.id === item?.id
          ))
        )),
      })),
    })),
  }))

  formattedAreas = _.map(formattedAreas, ({ groups, ...restArea }) => ({
    ...restArea,
    groups: _.map(groups, ({ categories, ...restGroup }) => ({
      ...restGroup,
      categories: _.filter(categories, ({ items }) => items.length),
    })),
  }))

  formattedAreas = _.map(formattedAreas, ({ groups, ...restArea }) => ({
    ...restArea,
    groups: _.filter(groups, ({ categories }) => categories.length),
  }))

  formattedAreas = _.filter(formattedAreas, ({ groups }) => groups.length)

  return formattedAreas
}
