import moment from 'moment'

import React from 'react'

import { TYPES } from 'components/DropdownMenu/constants'

import { Page, Section, Spinner } from 'components'

import i18n from 'translations'

import ManagementItemAddForm, { ItemAddFormValues } from './components/ItemAddForm/ItemAddForm'

interface ManagementItemAddViewProps {
  errorMessages?: string[]
  initialValues?: ItemAddFormValues
  isArchived: boolean
  isEdit: boolean
  isFetching: boolean
  isSubmitting: boolean
  onArchiveClick: (isArchived: boolean) => any
  onChangeStartDate: (value: moment.Moment, index: number) => void
  onRemovePriceChangeRow: (index: number) => void
  onSubmit: (values: ItemAddFormValues) => void
}

const ManagementItemAddView: React.FC<ManagementItemAddViewProps> = ({
  errorMessages,
  isArchived,
  isEdit,
  isFetching,
  onArchiveClick,
  ...rest
}) => {
  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    return (
      <ManagementItemAddForm {...rest} />
    )
  }

  return (
    <Page.Section
      actions={isEdit && (
        <Section.Actions
          options={[{
            onClick: () => onArchiveClick(isArchived),
            type: isArchived ? TYPES.UNARCHIVE : TYPES.ARCHIVE,
          }]}
        />
      )}
      errorMessages={errorMessages}
      title={
        isEdit
          ? i18n.t('module:Management:Finance:Item:Edit:title')
          : i18n.t('module:Management:Finance:Item:Add:title')
      }
    >
      {renderContent()}
    </Page.Section>
  )
}

export default ManagementItemAddView
