import { generateBasicActions } from 'utils/service'

import {
  CLEAR_DEPOSITS_STATISTICS,
  DEPOSITS_STATISTICS,
  DEPOSITS_STATISTICS_FAILED,
  DEPOSITS_STATISTICS_SUCCESS,
} from './constants'

export const getStatistics = (options = {}) => ({ depositsApiClient }) => generateBasicActions.get({
  constants: {
    failed: DEPOSITS_STATISTICS_FAILED,
    init: DEPOSITS_STATISTICS,
    success: DEPOSITS_STATISTICS_SUCCESS,
  },
  options,
  service: depositsApiClient,
  serviceMethod: 'statistics',
})

export const clearStatistics = () => ({
  type: CLEAR_DEPOSITS_STATISTICS,
})
