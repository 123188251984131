import { combineReducers } from 'redux'

import badDebtStatistics from './badDebtStatistics/reducer'
import badDebt from './badDebt/reducer'
import batch from './batch/reducer'
import list from './list/reducer'
import preview from './preview/reducer'
import single from './single/reducer'
import statistics from './statistics/reducer'
import forecast from './forecast/reducer'

export default combineReducers({
  badDebt,
  badDebtStatistics,
  batch,
  forecast,
  list,
  preview,
  single,
  statistics,
})
