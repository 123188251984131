import _ from 'lodash'
import Button from '@material-ui/core/Button'
import { createTheme } from '@material-ui/core/styles'
import styled from 'styled-components'
import colorMethod from 'color'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

import { BUTTON_EDGE, BUTTON_SIZE } from './constants'

const BORDER_WIDTH = 2
const PADDING_LARGE = 24
const PADDING_SMALL = 16
export const PADDING_TEXT = 8
const HEIGHT_LARGE = 48
const HEIGHT_MEDIUM = 40
const HEIGHT_SMALL = 32
const TOUCH_TARGET_HEIGHT = 48
export const ICON_SIZE_LARGE = 18
export const ICON_SIZE_SMALL = 18
const FONT_SIZE_LARGE = 16
const FONT_SIZE_SMALL = 14
const HOVER_OPACITY = 0.08
const MARGIN_MEDIUM = (TOUCH_TARGET_HEIGHT - HEIGHT_MEDIUM) / 2
const MARGIN_SMALL = (TOUCH_TARGET_HEIGHT - HEIGHT_SMALL) / 2

export const theme = createTheme({
  overrides: {
    MuiButton: {
      contained: {
        '&$disabled': {
          boxShadow: 'none',
        },
        '&:hover': {
          boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.3)',
        },
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
        paddingLeft: PADDING_LARGE,
        paddingRight: PADDING_LARGE,
      },
      containedPrimary: {
        '&$disabled': {
          backgroundColor: getBrandingColor('primary-color') || NEUTRAL_COLOURS.WHITE,
          color: NEUTRAL_COLOURS.WHITE,
        },
        '&:hover': {
          '&$disabled': {
            backgroundColor: getBrandingColor('primary-color') || NEUTRAL_COLOURS.WHITE,
          },
          backgroundColor: getBrandingColor('primary-color') || NEUTRAL_COLOURS.WHITE,
          color: NEUTRAL_COLOURS.WHITE,
        },
      },
      containedSecondary: {
        '&$disabled': {
          backgroundColor: NEUTRAL_COLOURS.WHITE,
          color: getBrandingColor('primary-color') || NEUTRAL_COLOURS.WHITE,
        },
        '&:hover': {
          '&$disabled': {
            backgroundColor: NEUTRAL_COLOURS.WHITE,
          },
          backgroundColor: NEUTRAL_COLOURS.WHITE,
          color: getBrandingColor('primary-color') || NEUTRAL_COLOURS.WHITE,
        },
      },
      containedSizeLarge: {
        paddingLeft: PADDING_LARGE,
        paddingRight: PADDING_LARGE,
      },
      containedSizeSmall: {
        paddingLeft: PADDING_SMALL,
        paddingRight: PADDING_SMALL,
      },
      endIcon: {
        '&$iconSizeSmall': {
          marginRight: -4,
        },
        marginRight: -6,
      },
      iconSizeLarge: {
        '& > *:first-child': {
          fontSize: ICON_SIZE_LARGE,
        },
      },
      iconSizeMedium: {
        '& > *:first-child': {
          fontSize: ICON_SIZE_LARGE,
        },
      },
      iconSizeSmall: {
        '& > *:first-child': {
          fontSize: ICON_SIZE_SMALL,
        },
      },
      outlined: {
        '&$disabled': {
          borderWidth: BORDER_WIDTH,
        },
        '&:hover': {
          borderWidth: BORDER_WIDTH,
        },
        borderWidth: BORDER_WIDTH,
        paddingLeft: PADDING_LARGE - BORDER_WIDTH,
        paddingRight: PADDING_LARGE - BORDER_WIDTH,
      },
      outlinedPrimary: {
        '&$disabled': {
          borderColor: getBrandingColor('primary-color') || NEUTRAL_COLOURS.WHITE,
          borderWidth: BORDER_WIDTH,
          color: getBrandingColor('primary-color') || NEUTRAL_COLOURS.WHITE,
        },
        '&:hover': {
          borderWidth: BORDER_WIDTH,
          color: getBrandingColor('primary-color') || NEUTRAL_COLOURS.WHITE,
        },
        borderColor: getBrandingColor('primary-color') || NEUTRAL_COLOURS.WHITE,
        borderWidth: BORDER_WIDTH,
      },
      outlinedSecondary: {
        '&$disabled': {
          borderColor: NEUTRAL_COLOURS.WHITE,
          borderWidth: BORDER_WIDTH,
          color: NEUTRAL_COLOURS.WHITE,
        },
        '&:hover': {
          borderWidth: BORDER_WIDTH,
          color: NEUTRAL_COLOURS.WHITE,
        },
        borderColor: NEUTRAL_COLOURS.WHITE,
        borderWidth: BORDER_WIDTH,
      },
      outlinedSizeLarge: {
        paddingLeft: PADDING_LARGE - BORDER_WIDTH,
        paddingRight: PADDING_LARGE - BORDER_WIDTH,
      },
      outlinedSizeSmall: {
        paddingLeft: PADDING_SMALL - BORDER_WIDTH,
        paddingRight: PADDING_SMALL - BORDER_WIDTH,
      },
      root: {
        '&$disabled': {
          opacity: 0.4,
        },
        height: HEIGHT_MEDIUM,
        marginBottom: MARGIN_MEDIUM,
        marginTop: MARGIN_MEDIUM,
        minWidth: 80,
      },
      sizeLarge: {
        fontSize: FONT_SIZE_LARGE,
        height: HEIGHT_LARGE,
        marginBottom: 0,
        marginTop: 0,
        minWidth: 80,
      },
      sizeSmall: {
        fontSize: FONT_SIZE_SMALL,
        height: HEIGHT_SMALL,
        marginBottom: MARGIN_SMALL,
        marginTop: MARGIN_SMALL,
        minWidth: 60,
      },
      startIcon: {
        '&$iconSizeSmall': {
          marginLeft: -4,
        },
        marginLeft: -6,
      },
      text: {
        paddingLeft: PADDING_TEXT,
        paddingRight: PADDING_TEXT,
      },
      textPrimary: {
        '&$disabled': {
          color: getBrandingColor('primary-color') || NEUTRAL_COLOURS.WHITE,
        },
        '&:hover': {
          color: getBrandingColor('primary-color') || NEUTRAL_COLOURS.WHITE,
        },
      },
      textSecondary: {
        '&$disabled': {
          color: NEUTRAL_COLOURS.WHITE,
        },
        '&:hover': {
          color: NEUTRAL_COLOURS.WHITE,
        },
      },
      textSizeLarge: {
        paddingLeft: PADDING_TEXT,
        paddingRight: PADDING_TEXT,
      },
      textSizeSmall: {
        paddingLeft: PADDING_TEXT,
        paddingRight: PADDING_TEXT,
      },
    },
  },
  palette: {
    action: {
      hoverOpacity: HOVER_OPACITY,
    },
    primary: {
      contrastText: NEUTRAL_COLOURS.WHITE,
      main: getBrandingColor('primary-color') || NEUTRAL_COLOURS.WHITE,
    },
    secondary: {
      contrastText: getBrandingColor('primary-color') || NEUTRAL_COLOURS.WHITE,
      main: NEUTRAL_COLOURS.WHITE,
    },
  },
  shape: {
    borderRadius: 24,
  },
  typography: {
    button: {
      fontFamily: 'inherit',
      fontSize: 16,
      fontWeight: 600,
    },
  },
})

export const StyledButton = styled(Button)`
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    min-height: ${TOUCH_TARGET_HEIGHT}px;
    min-width: ${TOUCH_TARGET_HEIGHT}px;

    ${({ variant }) => 'outlined' === variant && `
      height: calc(100% + 2 * ${BORDER_WIDTH}px);
      width: calc(100% + 2 * ${BORDER_WIDTH}px);
      left: -${BORDER_WIDTH}px;
      top: -${BORDER_WIDTH}px;
    `}

    ${({ size }) => BUTTON_SIZE.MEDIUM === size && `
      top: -${MARGIN_MEDIUM}px;
    `}

    ${({ size, variant }) => BUTTON_SIZE.MEDIUM === size && 'outlined' === variant && `
      top: calc(-${MARGIN_MEDIUM}px - ${BORDER_WIDTH}px);
    `}

    ${({ size }) => BUTTON_SIZE.SMALL === size && `
      top: -${MARGIN_SMALL}px;
    `}

    ${({ size, variant }) => BUTTON_SIZE.SMALL === size && 'outlined' === variant && `
      top: calc(-${MARGIN_SMALL}px - ${BORDER_WIDTH}px);
    `}

    ${({ $iconOnly, size }) => $iconOnly && BUTTON_SIZE.MEDIUM === size && `
      left: -${MARGIN_MEDIUM}px;
    `}

    ${({ $iconOnly, size, variant }) => $iconOnly && BUTTON_SIZE.MEDIUM === size && 'outlined' === variant && `
      left: calc(-${MARGIN_MEDIUM}px - ${BORDER_WIDTH}px);
    `}

    ${({ $iconOnly, size }) => $iconOnly && BUTTON_SIZE.SMALL === size && `
      left: -${MARGIN_SMALL}px;
    `}

    ${({ $iconOnly, size, variant }) => $iconOnly && BUTTON_SIZE.SMALL === size && 'outlined' === variant && `
      left: calc(-${MARGIN_SMALL}px - ${BORDER_WIDTH}px);
    `}
  }

  ${({ $iconOnly }) => $iconOnly && `
    min-width: 0;
    padding: 0;
  `}

  ${({ $iconOnly, size }) => $iconOnly && BUTTON_SIZE.LARGE === size && `
    width: ${HEIGHT_LARGE}px;
  `}

  ${({ $iconOnly, size }) => $iconOnly && BUTTON_SIZE.MEDIUM === size && `
    width: ${HEIGHT_MEDIUM}px;
    margin-left: ${MARGIN_MEDIUM}px;
    margin-right: ${MARGIN_MEDIUM}px;
  `}

  ${({ $iconOnly, size }) => $iconOnly && BUTTON_SIZE.SMALL === size && `
    width: ${HEIGHT_SMALL}px;
    margin-left: ${MARGIN_SMALL}px;
    margin-right: ${MARGIN_SMALL}px;
  `}

  ${({ $iconOnly, edge, variant }) => BUTTON_EDGE.START === edge && !$iconOnly && 'text' === variant && `
    margin-left: -${PADDING_TEXT}px;
  `}

  ${({ $iconOnly, edge, variant }) => BUTTON_EDGE.END === edge && !$iconOnly && 'text' === variant && `
    margin-right: -${PADDING_TEXT}px;
  `}

  ${({ $customColor, $inverted, variant }) => $customColor && $inverted && 'contained' === variant && `
    color: ${$customColor};

    &.Mui-disabled {
      color: ${$customColor};
    }

    &:hover {
      color: ${$customColor};
    }
  `}

  ${({ $customColor, $inverted, variant }) => $customColor && !$inverted && 'contained' === variant && `
    background-color: ${$customColor} !important;

    &.Mui-disabled {
      background-color: ${$customColor};
    }

    &:hover {
      background-color: ${$customColor};

      &.Mui-disabled {
        background-color: ${$customColor};
      }
    }
  `}

  ${({ $customColor, $inverted, variant }) => $customColor && !$inverted && 'contained' !== variant && `
    border-color: ${$customColor};
    color: ${$customColor};

    &.Mui-disabled {
      border-color: ${$customColor};
      color: ${$customColor};
    }

    &:hover {
      border-color: ${$customColor};
      color: ${$customColor};
      background-color: ${colorMethod($customColor).alpha(HOVER_OPACITY).rgb().string()};
    }
  `}

  ${({ $isLoading }) => $isLoading && `
    &.Mui-disabled {
      opacity: 0.4;
      cursor: default;
    }
  `}

  ${({ $margin }) => !_.isUndefined($margin) && `
    margin: ${$margin};
  `}

  &.MuiButton-text .MuiButton-startIcon {
    margin-left: 0;
  }

  &.MuiButton-text .MuiButton-endIcon {
    margin-right: 0;
  }

  &.MuiButtonBase-root:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }

  & .MuiButton-label {
    white-space: nowrap;
  }
`

export const StyledButtonWrapper = styled.div`
  display: inline-block;

  ${({ $disabled }) => $disabled && `
    cursor: not-allowed;
  `}

  ${({ $isLoading }) => $isLoading && `
    cursor: default;
  `}

  ${({ $fullWidth }) => $fullWidth && `
    width: 100%;
  `}

  ${({ $negativeMargins, $size }) => $negativeMargins && BUTTON_SIZE.MEDIUM === $size && `
    margin-top: -${MARGIN_MEDIUM}px;
    margin-bottom: -${MARGIN_MEDIUM}px;
  `}

  ${({ $negativeMargins, $size }) => $negativeMargins && BUTTON_SIZE.SMALL === $size && `
    margin-top: -${MARGIN_SMALL}px;
    margin-bottom: -${MARGIN_SMALL}px;
  `}

  ${({ $iconOnly, $negativeMargins, $size }) => $negativeMargins && $iconOnly && BUTTON_SIZE.MEDIUM === $size && `
    margin-left: -${MARGIN_MEDIUM}px;
    margin-right: -${MARGIN_MEDIUM}px;
  `}

  ${({ $iconOnly, $negativeMargins, $size }) => $negativeMargins && $iconOnly && BUTTON_SIZE.SMALL === $size && `
    margin-left: -${MARGIN_SMALL}px;
    margin-right: -${MARGIN_SMALL}px;
  `}

  ${({
    $iconOnly,
    $negativeVerticalMargins,
    $size,
  }) => $negativeVerticalMargins && $iconOnly && BUTTON_SIZE.MEDIUM === $size && `
    margin-top: -${MARGIN_MEDIUM}px;
    margin-bottom: -${MARGIN_MEDIUM}px;
  `}

  ${({
    $iconOnly,
    $negativeVerticalMargins,
    $size,
  }) => $negativeVerticalMargins && $iconOnly && BUTTON_SIZE.SMALL === $size && `
    margin-top: -${MARGIN_SMALL}px;
    margin-bottom: -${MARGIN_SMALL}px;
  `}

  ${({ $margin }) => !_.isUndefined($margin) && `
    margin: ${$margin};
  `}
`
