export const GET_CHILD_FUNDING = 'GET_CHILD_FUNDING'
export const GET_CHILD_FUNDING_SUCCESS = 'GET_CHILD_FUNDING_SUCCESS'
export const GET_CHILD_FUNDING_FAILED = 'GET_CHILD_FUNDING_FAILED'

export const CREATE_CHILD_FUNDING = 'CREATE_CHILD_FUNDING'
export const CREATE_CHILD_FUNDING_SUCCESS = 'CREATE_CHILD_FUNDING_SUCCESS'
export const CREATE_CHILD_FUNDING_FAILED = 'CREATE_CHILD_FUNDING_FAILED'

export const UPDATE_CHILD_FUNDING = 'UPDATE_CHILD_FUNDING'
export const UPDATE_CHILD_FUNDING_SUCCESS = 'UPDATE_CHILD_FUNDING_SUCCESS'
export const UPDATE_CHILD_FUNDING_FAILED = 'UPDATE_CHILD_FUNDING_FAILED'

export const DELETE_CHILD_FUNDING = 'DELETE_CHILD_FUNDING'
export const DELETE_CHILD_FUNDING_SUCCESS = 'DELETE_CHILD_FUNDING_SUCCESS'
export const DELETE_CHILD_FUNDING_FAILED = 'DELETE_CHILD_FUNDING_FAILED'

export const CLEAR_SINGLE_CHILD_FUNDING = 'CLEAR_SINGLE_CHILD_FUNDING'
