import styled from 'styled-components'

import { StyledBox } from 'components/Popover/PopoverStyled'
import { StyledContainer } from 'components/Conversations/ConversationsStyled'

export const StyledButton = styled.div`
  position: relative;
  
  ${({ $badgeOverflow }) => $badgeOverflow && `
    margin-right: 15px;
  `}  

  ${({ $disabled }) => $disabled && `
    opacity: .4;
  `}

  ${({ $width }) => 380 > $width && `
    button {
      margin: 8px 4px; 
    }
  `}
`

export const StyledLayerWrapper = styled.div`
  @media(max-width: 580px) {
    ${StyledBox} {
      transform: translateX(-10px) !important;
    }
  }
  
  @media(max-width: 360px) {
    ${StyledBox} {
      left: 0 !important;
      right: 0 !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      transform: translateX(0) !important;
    }
    
    ${StyledContainer} {
      width: 310px;
    }
  }
`
