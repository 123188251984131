import _ from 'lodash'
import moment from 'moment'
import { createSelector } from 'reselect'

import React from 'react'

import { toFloat } from 'utils/data'
import { generateRoute } from 'utils/routing'

import { getFundingLineItemDisplaySettings } from 'services/legacy/invoices/preview/helpers'
import { getFormattedFunding } from 'services/legacy/childBulkFunding/list/selectors'

import { Avatar, Button, Currency } from 'components'

export const getChildFundingSelectors = (state) => state.childFunding

export const getListDataSelectors = createSelector(
  [getChildFundingSelectors],
  (state) => state.list.data,
)

export const getListMetaSelectors = createSelector(
  [getChildFundingSelectors],
  (state) => state.list.meta,
)

export const getFormattedListData = createSelector(
  [getListDataSelectors],
  (listData) => {
    if (!listData?.length) {
      return null
    }

    return _.map(listData, getFormattedFunding)
  },
)

export const getListTableDataSelectors = createSelector(
  [getListDataSelectors],
  (listData) => {
    if (!listData?.length) {
      return null
    }

    return _.map(listData, (item) => {
      const {
        archived,
        child,
        endDate,
        funding,
        hourlyRate,
        id,
        startDate,
        total,
        totalHours,
      } = item

      const { firstName, id: childId, photo, surname } = child || {}
      const { fundingType, label: fundingLabel } = funding || {}
      const { name: fundingTypeLabel } = fundingType || {}

      const fundingName = `${fundingLabel} - ${fundingTypeLabel}${archived ? ' (Archived)' : ''}`

      return {
        child: (
          <Avatar
            avatarSize="small"
            initials={[firstName, surname]}
            src={photo}
            title={`${firstName} ${surname}`}
            primary
          />
        ),
        fundingType: fundingName,
        hourlyRate: hourlyRate ? <Currency value={hourlyRate} /> : null,
        id,
        period: `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`,
        rowToButton: (
          <Button.TableAction
            to={generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.FUNDING.VIEW', { childFundingId: id, childId })}
          />
        ),
        total: <Currency value={total} />,
        totalHours: toFloat(totalHours) || 0,
      }
    })
  },
)

export const getDropdownOptions = createSelector(
  [getListDataSelectors],
  (childFundingList) => {
    if (!childFundingList) {
      return null
    }

    return _.map(childFundingList, (item) => {
      const { amountUsed, funding, hourlyRate, hoursUsed, id, total, totalHours, type } = item
      const { fundingType, label, settings } = funding || {}
      const { name } = fundingType || {}
      const { hoursPerWeek, maxHoursPerDay } = settings || {}
      const fundingLineItemDisplay = getFundingLineItemDisplaySettings(item)

      return {
        amountUsed,
        fundingLineItemDisplay,
        hourlyRate,
        hoursPerWeek,
        hoursUsed,
        label: `${label} - ${name}`,
        maxHoursPerDay,
        total,
        totalHours,
        type,
        value: id,
      }
    })
  },
)

export const getChildFundingListSummary = createSelector(
  [getListDataSelectors],
  (childFundingList) => {
    if (!childFundingList) {
      return null
    }

    const uniqueChildFundingList = _.uniqBy(childFundingList, ({ funding }) => `${funding.id}_${funding.type.id}`)

    return _.map(uniqueChildFundingList, (childFunding) => {
      const { endDate, funding, startDate } = childFunding
      const { fundingType, id, label: fundingLabel } = funding
      const { name: fundingTypeName } = fundingType

      return {
        id,
        label: `${fundingLabel} - ${fundingTypeName}`,
        period: `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`,
      }
    })
  },
)

export const hasChildLevelManualFunding = createSelector(
  [getListMetaSelectors],
  (metaData) => {
    if (!metaData) {
      return false
    }

    return !!metaData.total_results
  },
)
