import _ from 'lodash'
import moment from 'moment'

import React from 'react'
import { ReactSVG } from 'react-svg'

import { generateRoute } from 'utils/routing'

import {
  DatePicker,
  EmptyState,
  Form,
  InfiniteDropdowns,
  List,
  Page,
  Pagination,
  SearchBar,
  Section,
  Select,
  Spinner,
  Toolbar,
} from 'components'

import i18n from 'translations'

import InjuryListItem from '../components/InjuryListItem'
import { StyledInjuriesIcon } from './InjuryRecordsStyled'

const InjuryRecordsView = ({
  childId,
  dateRange,
  homeAccidentTypeOptions,
  injuryType,
  isChildContext,
  isHomeAccidents,
  isLoading,
  onChildSearch,
  onDateChange,
  onInjuryTypeChange,
  onPageChange,
  onRoomChange,
  onStatusChange,
  onSupervisorChange,
  onTypeChange,
  page,
  pageCount,
  perPage,
  records,
  room,
  status,
  statusOptions,
  supervisor,
  totalResults,
  type,
  typeOptions,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!records || !records.length) {
      const text = (
        <span>
          {isHomeAccidents
            ? i18n.t('module:Safeguarding:List:emptyStateText1')
            : i18n.t('module:Injury:List:emptyStateText1')}
          <br />
          {isHomeAccidents
            ? i18n.t('module:Safeguarding:List:emptyStateText2')
            : i18n.t('module:Injury:List:emptyStateText2')}
        </span>
      )
      const imgName = isHomeAccidents ? 'home-accident.svg' : 'injuries.svg'

      return (
        <EmptyState
          iconComponent={(
            <StyledInjuriesIcon>
              <ReactSVG src={`/images/${imgName}`} />
            </StyledInjuriesIcon>
          )}
          text1={text}
          iconComponentSmallMargin
        />
      )
    }

    const renderInjuryRow = (item) => (
      <InjuryListItem
        key={item.id}
        {...item}
        childId={childId}
        isChildContext={isChildContext}
        isHomeAccidents={isHomeAccidents}
      />
    )

    return (
      <React.Fragment>
        {renderPagination()}
        <List.Standard>
          {_.map(records, renderInjuryRow)}
        </List.Standard>
        {renderPagination()}
      </React.Fragment>
    )
  }

  const addHomeAccidentsButton = isChildContext
    ? generateRoute('CHILDREN.CHILD.SAFEGUARDING.HOME_ACCIDENTS.SINGLE_ADD', { childId })
    : generateRoute('SAFEGUARDING.HOME_ACCIDENTS.SINGLE_ADD', { childId })

  const addAccidentIncidentButton = isChildContext
    ? generateRoute('CHILDREN.CHILD.SAFEGUARDING.INJURY.SINGLE_ADD', { childId })
    : generateRoute('SAFEGUARDING.INJURY.SINGLE_ADD', { childId })

  const actions = (
    <Section.Actions
      primary={[{ to: isHomeAccidents ? addHomeAccidentsButton : addAccidentIncidentButton }]}
    />
  )

  return (
    <React.Fragment>
      {!childId && (
        <SearchBar
          placeholder={i18n.t('global:searchChild')}
          onChange={onChildSearch}
        />
      )}
      <Page.Section
        actions={actions}
        isLoading={isLoading}
        title={isHomeAccidents ? i18n.t('module:Safeguarding:List:title') : i18n.t('module:Injury:List:title')}
      >
        <Toolbar>
          <Toolbar.Group>
            <Toolbar.Item>
              <Form.Row label={i18n.t('module:Injury:Filter:dateRange')} verticalLabel>
                <DatePicker
                  disabledDays={isHomeAccidents && {
                    after: moment().toDate(),
                  }}
                  value={dateRange}
                  clearable
                  range
                  onChange={onDateChange}
                />
              </Form.Row>
            </Toolbar.Item>
            {!isHomeAccidents && (
              <React.Fragment>
                <Toolbar.Item>
                  <Form.Row label={i18n.t('module:Injury:Filter:type')} verticalLabel>
                    <Select
                      options={typeOptions}
                      placeholder={_.upperFirst(i18n.t('global:All'))}
                      value={type}
                      onChange={onTypeChange}
                    />
                  </Form.Row>
                </Toolbar.Item>
                <Toolbar.Item>
                  <Form.Row label={i18n.t('module:Injury:Filter:supervisor')} verticalLabel>
                    <InfiniteDropdowns.Users
                      placeholder={_.upperFirst(i18n.t('global:All'))}
                      value={supervisor}
                      searchable
                      onChange={onSupervisorChange}
                    />
                  </Form.Row>
                </Toolbar.Item>
                <Toolbar.Item>
                  <Form.Row label={i18n.t('module:Injury:Filter:room')} verticalLabel>
                    <InfiniteDropdowns.Rooms
                      placeholder={_.upperFirst(i18n.t('global:All'))}
                      value={room}
                      onChange={onRoomChange}
                    />
                  </Form.Row>
                </Toolbar.Item>
              </React.Fragment>
            )}
            <Toolbar.Item>
              <Form.Row label={i18n.t('module:Injury:Filter:injuries')} verticalLabel>
                <Select
                  options={homeAccidentTypeOptions}
                  placeholder={_.upperFirst(i18n.t('global:All'))}
                  value={injuryType}
                  onChange={onInjuryTypeChange}
                />
              </Form.Row>
            </Toolbar.Item>
            <Toolbar.Item>
              <Form.Row
                label={_.upperFirst(i18n.t('global:status'))}
                margin={!isHomeAccidents ? '-30px 0 0' : ''}
                verticalLabel
              >
                <Select
                  options={statusOptions}
                  placeholder={_.upperFirst(i18n.t('global:All'))}
                  value={status}
                  onChange={onStatusChange}
                />
              </Form.Row>
            </Toolbar.Item>
          </Toolbar.Group>
        </Toolbar>
        {renderContent()}
      </Page.Section>
    </React.Fragment>
  )
}

export default InjuryRecordsView
