import { generateBasicActions } from 'utils/service'

import {
  CLEAR_REQUESTED_EXTRA_SESSIONS_AVAILABILITY_STATISTICS,
  REQUESTED_EXTRA_SESSIONS_AVAILABILITY_STATISTICS,
  REQUESTED_EXTRA_SESSIONS_AVAILABILITY_STATISTICS_FAILED,
  REQUESTED_EXTRA_SESSIONS_AVAILABILITY_STATISTICS_SUCCESS,
} from './constants'

export const getAvailabilityStatistics = (options = {}) => ({ requestedExtraSessionsApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: REQUESTED_EXTRA_SESSIONS_AVAILABILITY_STATISTICS_FAILED,
      init: REQUESTED_EXTRA_SESSIONS_AVAILABILITY_STATISTICS,
      success: REQUESTED_EXTRA_SESSIONS_AVAILABILITY_STATISTICS_SUCCESS,
    },
    options,
    service: requestedExtraSessionsApiClient,
    serviceMethod: 'getAvailabilityStatistics',
  })
)

export const clearAvailabilityStatistics = () => ({
  type: CLEAR_REQUESTED_EXTRA_SESSIONS_AVAILABILITY_STATISTICS,
})
