import moment from 'moment'
import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { DEFAULT_DATE_FORMAT, DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'
import { SHIFT_MAIN_TYPES } from 'services/legacy/membershipRegisters/constants'
import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'
import { addMillisecondsFromMidnight } from 'utils/date'

import { EVENTS, logEvent } from 'analytics'

import { getAuthUser, isNurseryContext } from 'services/security/selectors'
import { isOrganizationMember } from 'services/legacy/memberships/single/selectors'
import { getMembershipDataSelector } from 'services/legacy/memberships/selectors'

import { withAppService } from 'services/app'
import { withMembershipsLeavesService } from 'services/legacy/membershipsLeaves'
import { withMembershipsService } from 'services/legacy/memberships'
import { withMembershipsShiftsService } from 'services/legacy/membershipsShifts'
import { withModalService } from 'services/utils/modal'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withSecurityService } from 'services/security'
import { withShellService } from 'services/shell'

import i18n from 'translations'

import withStaffHoc from 'module/Staff/withStaffHoc'

import StaffLeaveDetailsView from './StaffLeaveDetailsView'
import EntitlementNotes from './components/EntitlementNotes'

const MEMBERSHIP_GROUPS = {
  read: [
    'membership.details',
  ],
}

const GROUPS = {
  read: [
    'membershipShift.membershipShiftTimes',
    'membershipShift.membershipShiftTimeStatistics',
    'membershipShiftSettings',
    'membershipShiftTime',
    'membershipShiftTime.class',
    'membershipShift.leaveShiftType',
    'leaveShiftType',
  ],
}

const GROUPS_STATISTICS = {
  read: [
    'membershipShift.membershipShiftTimes',
    'membershipShiftSettings',
    'membershipShiftTime',
    'membershipShiftTime.class',
  ],
}

class StaffLeaveDetailsContainer extends Component {
  constructor(props) {
    super(props)

    const { location: { query } } = props
    const { dateFrom, dateTo } = query

    this.state = {
      filters: {
        date: [
          dateFrom && moment(dateFrom, DEFAULT_DATE_FORMAT).isValid()
            ? moment(dateFrom, DEFAULT_DATE_FORMAT)
            : moment().startOf('year'),
          dateTo && moment(dateTo, DEFAULT_DATE_FORMAT).isValid()
            ? moment(dateTo, DEFAULT_DATE_FORMAT)
            : moment().endOf('year'),
        ],
      },
    }
  }

  componentDidMount() {
    logEvent(EVENTS.STAFF_LEAVE_PAGE_VIEWED)

    this.fetchMembership()
    this.fetch()
  }

  componentWillUnmount() {
    const { membershipsLeavesActions } = this.props

    membershipsLeavesActions.clearList()
    membershipsLeavesActions.clearStatistics()
  }

  fetchMembership = () => {
    const {
      membershipsActions,
      params: { userId },
    } = this.props

    if (userId) {
      return membershipsActions.get(userId, {
        params: {
          groups: MEMBERSHIP_GROUPS,
        },
      })
    }

    return membershipsActions.getMe({
      params: { groups: MEMBERSHIP_GROUPS },
    })
  }

  fetch = () => {
    const {
      currentUser,
      isMyDetailsContext,
      membershipsLeavesActions,
      membershipsShiftsSelectors,
      paginationUtils,
      params: { userId },
      setLocationQuery,
    } = this.props
    const { page } = paginationUtils
    const { filters } = this.state
    const { date } = filters

    const [dateFrom, dateTo] = date

    setLocationQuery({
      dateFrom: moment(dateFrom).format(DEFAULT_DATE_FORMAT),
      dateTo: moment(dateTo).format(DEFAULT_DATE_FORMAT),
    }, ['page'])

    const criteria = membershipsShiftsSelectors.getListCriteria({
      dateFrom: moment(dateFrom).format(DEFAULT_DATE_FORMAT),
      dateTo: moment(dateTo).format(DEFAULT_DATE_FORMAT),
      membership: userId || (isMyDetailsContext && currentUser.membership.id),
      type: SHIFT_MAIN_TYPES.LEAVE,
    })

    const statisticsCriteria = membershipsShiftsSelectors.getListCriteria({
      dateFrom: moment(dateFrom).format(DEFAULT_DATE_FORMAT),
      dateTo: moment(dateTo).format(DEFAULT_DATE_FORMAT),
      membership: userId || (isMyDetailsContext && currentUser.membership.id),
      now: moment().format('YYYY-MM-DD HH:mm'),
    })

    membershipsLeavesActions.list({
      params: {
        criteria,
        groups: GROUPS,
        page,
      },
    })

    membershipsLeavesActions.getStatistics({
      params: {
        criteria: statisticsCriteria,
        groups: GROUPS_STATISTICS,
      },
    })
  }

  handleAddLeave = () => {
    const { membership, modalActions, modalConsts } = this.props

    logEvent(EVENTS.STAFF_LEAVE_ADD_BTN_CLICKED, { context: 'staff profile' })

    const initialValues = {}

    if (membership) {
      initialValues.membership = {
        avatar: membership?.photo,
        label: membership?.name,
        value: membership?.id,
      }
    }

    modalActions.show(modalConsts.TYPES.STAFF_LEAVE_ADD, {
      initialValues,
      onSuccess: () => {
        logEvent(EVENTS.STAFF_LEAVE_ADDED, { context: 'staff profile' })

        this.fetch()
      },
    })
  }

  handleChangeDate = (date) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    logEvent(EVENTS.STAFF_LEAVE_LIST_FILTER_USED, { type: 'date' })

    this.setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        date,
      },
    }), onPageChange(this.fetch)(1))
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleDeleteLeaveConfirmed = (leave) => {
    const { membershipsShiftsActions } = this.props
    const { id } = leave

    return membershipsShiftsActions.remove(id, {
      onSuccess: this.fetch,
    })
  }

  handleDeleteLeave = (leave) => {
    const { modalActions, modalConsts } = this.props
    const { dateFrom, dateTo } = leave

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'trash',
      onConfirm: () => this.handleDeleteLeaveConfirmed(leave),
      text: `
        ${i18n.t('module:Staff:StaffLeave:deleteLeave')} 
        ${moment(dateFrom).format(DISPLAY_SHORT_DATE_FORMAT)} - ${moment(dateTo).format(DISPLAY_SHORT_DATE_FORMAT)}
      `,
    })
  }

  handleEditLeave = (leave) => {
    const { membership, modalActions, modalConsts } = this.props
    const { dateFrom, dateTo, leaveShiftType, membershipShiftTimes: membershipShiftTimesRaw, type } = leave
    const membershipShiftTimes = {}

    _.each(membershipShiftTimesRaw, ({ day, endTime, id, startTime }) => {
      const date = moment(day).format(DEFAULT_DATE_FORMAT)

      if (!membershipShiftTimes[date]?.length) {
        membershipShiftTimes[date] = []
      }

      membershipShiftTimes[date].push({
        endTime: addMillisecondsFromMidnight(endTime, day),
        id,
        startTime: addMillisecondsFromMidnight(startTime, day),
      })
    })

    const initialValues = {
      date: [moment(dateFrom), moment(dateTo)],
      leaveShiftType: {
        label: leaveShiftType.name,
        value: leaveShiftType.id,
      },
      membership: {
        avatar: membership.photo,
        label: membership.name,
        value: membership.id,
      },
      membershipShiftTimes,
      type,
    }

    modalActions.show(modalConsts.TYPES.STAFF_LEAVE_ADD, {
      initialValues,
      leave,
      onSuccess: this.fetch,
    })
  }

  handleExportClick = () => {
    const { membership, modalActions, modalConsts } = this.props

    logEvent(EVENTS.STAFF_LEAVE_EXPORT_BTN_CLICKED, { context: 'staff profile' })

    modalActions.show(modalConsts.TYPES.STAFF_LEAVE_EXPORT, {
      membership,
    })
  }

  render() {
    const {
      EntitlementNotesGranted,
      StaffLeaveAddGranted,
      isFetching,
      paginationUtils,
      records,
      statistics,
      totalResults,
    } = this.props
    const { filters } = this.state
    const { date } = filters
    const { getPageCount, page, perPage } = paginationUtils

    const pageCount = getPageCount(totalResults)

    return (
      <StaffLeaveDetailsView
        authEntitlementNotes={EntitlementNotesGranted}
        date={date}
        hasPermissionToEdit={StaffLeaveAddGranted}
        isFetching={isFetching}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        records={records}
        statistics={statistics}
        totalResults={totalResults}
        onAddLeave={this.handleAddLeave}
        onChangeDate={this.handleChangeDate}
        onDeleteLeave={this.handleDeleteLeave}
        onEditLeave={this.handleEditLeave}
        onExportClick={this.handleExportClick}
        onPageChange={this.handlePageChange}
      />
    )
  }
}

StaffLeaveDetailsContainer.authParams = (state) => {
  const { router } = state
  const { locationBeforeTransitions } = router?.common || {}
  const { pathname } = locationBeforeTransitions || {}

  const membership = getMembershipDataSelector(state)
  const authUser = getAuthUser(state)

  if (membership?.id === authUser?.membership?.id && isOrganizationMember(state)) {
    return {}
  }

  if (pathname?.startsWith(generateRoute('ACCOUNT.INDEX'))) {
    return null
  }

  if (isNurseryContext(state) && isOrganizationMember(state)) {
    return {}
  }

  return {
    flags: [FEATURE_FLAGS.STAFF_REGISTER],
    nurseryContext: true,
    roles: [
      ROLES.SUPER_ADMIN,
      ROLES.ORGANIZATION_DIRECTOR,
      ROLES.ORGANIZATION_NATIONAL_ADMIN,
      ROLES.ORGANIZATION_FINANCE_ADMIN,
      ROLES.ORGANIZATION_LINE_MANAGER,
      ROLES.NURSERY_MANAGER,
      ROLES.NURSERY_ADMIN,
      ROLES.DEPUTY_MANAGER,
    ],
  }
}

const mapState = (state, {
  appSelectors,
  membershipsLeavesSelectors,
  membershipsLeavesStatisticsState,
  membershipsSelectors,
  membershipsShiftsListState,
  securitySelectors,
}) => ({
  EntitlementNotesGranted: auth.SELECTORS.getComponentIsAuthorised(state, EntitlementNotes),
  StaffLeaveAddGranted: auth.SELECTORS.getIsAuthorised(state, {
    roles: [
      ROLES.SUPER_ADMIN,
      ROLES.ORGANIZATION_DIRECTOR,
      ROLES.ORGANIZATION_NATIONAL_ADMIN,
      ROLES.ORGANIZATION_FINANCE_ADMIN,
      ROLES.ORGANIZATION_LINE_MANAGER,
      ROLES.NURSERY_MANAGER,
      ROLES.NURSERY_ADMIN,
    ],
  }),
  currentUser: securitySelectors.getAuthUser(state),
  isFetching: appSelectors.getIsFetching(
    membershipsLeavesStatisticsState,
    membershipsShiftsListState,
  ),
  membership: membershipsSelectors.getMembershipDataSelector(state),
  records: membershipsLeavesSelectors.getSingleUserLeavesSelector(state),
  statistics: membershipsLeavesSelectors.getLeaveStatistics(state),
  totalResults: appSelectors.getTotalResults(membershipsShiftsListState),
})

const enhance = compose(
  withAppService,
  withMembershipsService,
  withMembershipsLeavesService,
  withMembershipsShiftsService,
  withModalService,
  withPaginationUtils,
  withRouterUtils,
  withSecurityService,
  withShellService,
  withStaffHoc,
  connect(mapState),
)

export default enhance(StaffLeaveDetailsContainer)
