import { createSelector } from 'reselect'

const getRegularUnavailableTimesListSelector = (state) => state.requestedExtraSessions.regularUnavailableTimesList

export const getRegularUnavailableTimesListData = createSelector(
  [getRegularUnavailableTimesListSelector],
  (state) => state.data,
)

export const getRegularUnavailableTimesListCriteria = () => [
  {
    field: 'or[0][nurseryClass.archived][eq]',
    value: 0,
  },
  {
    field: 'or[0][nurseryClass][eq]',
    value: null,
  },
]
