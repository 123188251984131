import _ from 'lodash'

import React from 'react'

import { Page, Section, Spinner, Typography } from 'components'

import i18n from 'translations'

import ManagementBlossomboardForm from './components/ManagementBlossomboardForm'

const ManagementBlossomboardView = ({
  editMode,
  isFetching,
  isSubmitting,
  onCancel,
  onEditMode,
  onSubmit,
  widgets,
}) => {
  const actions = <Section.Actions tertiary={{ onClick: () => onEditMode(true) }} />

  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    return (
      <React.Fragment>
        <Typography>
          {!editMode && i18n.t('module:Management:ManagementBlossomboard:pageDescription')}
          {editMode && i18n.t('module:Management:ManagementBlossomboard:editionDescription')}
        </Typography>
        <ManagementBlossomboardForm
          editMode={editMode}
          initialValues={{
            settings: _.map(widgets, ({ draggableId, enabled, widget }) => ({
              ...widget,
              draggableId,
              enabled,
            })),
          }}
          isSubmitting={isSubmitting}
          widgets={widgets}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </React.Fragment>
    )
  }

  return (
    <Page.Section
      actions={!editMode && actions}
      isLoading={isFetching}
      title={i18n.t('module:Management:ManagementBlossomboard:title')}
    >
      {renderContent()}
    </Page.Section>
  )
}

export default ManagementBlossomboardView
