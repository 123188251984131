import { compose } from 'recompose'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withChildRegistersService } from 'services/legacy/childRegisters'
import { withPaginationUtils } from 'services/utils/pagination'

import ActivitiesStatisticsModalView from './ActivitiesStatisticsModalView'

const CHILD_REGISTERS_GROUPS = {
  read: [
    'child',
    'child.profile',
    'childRegister.child',
    'childInformation',
    'childRegister.diaryRecordStatistics',
    'childRegisterDiaryRecordStatistics',
  ],
}

class ActivitiesStatisticsModalContainer extends Component {
  constructor(props) {
    super(props)

    const {
      paginationUtils: { setPageLocationQuery },
    } = this.props

    setPageLocationQuery(false)
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  fetch = () => {
    const {
      childRegistersActions,
      childRegistersSelectors,
      date,
      paginationUtils,
      room,
    } = this.props
    const { page } = paginationUtils

    const criteria = childRegistersSelectors.getListCriteria({ entryDate: date, room })

    childRegistersActions.listStatistics({
      mergeResult: 1 !== page,
      params: {
        criteria,
        groups: CHILD_REGISTERS_GROUPS,
        page,
      },
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  render() {
    const { isFetching, paginationUtils, records, recordsToSkip, totalResults } = this.props
    const { getPageCount, page } = paginationUtils
    const pageCount = getPageCount(totalResults)

    const cuttedRecords = records ? records.slice(recordsToSkip, records.length) : []

    return (
      <ActivitiesStatisticsModalView
        isFetching={isFetching}
        page={page}
        pageCount={pageCount}
        records={cuttedRecords}
        onCloseClick={this.handleCloseClick}
        onPageChange={this.handlePageChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childRegistersSelectors,
  childRegistersStatisticsState,
}) => ({
  isFetching: appSelectors.getIsFetching(
    childRegistersStatisticsState,
  ),
  records: childRegistersSelectors.getChildRegistersStatisticsData(state),
  totalResults: appSelectors.getTotalResults(childRegistersStatisticsState),
})

const enhance = compose(
  withAppService,
  withPaginationUtils,
  withChildRegistersService,
  connect(mapState),
)

export default enhance(ActivitiesStatisticsModalContainer)
