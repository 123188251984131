import { Moment } from 'moment'

import React, { PropsWithChildren } from 'react'

import { RECURRING_PAYMENT_METHOD_OPTIONS } from 'services/nurseryIntegrations/constants'

import {
  Callout,
  DatePicker,
  EmptyState,
  Form,
  InfiniteDropdowns,
  InfiniteScroll,
  Page,
  Pagination,
  Section,
  Select,
  Spinner,
  Table,
  Toolbar,
} from 'components'

import i18n from 'translations'

import { NURSERY_TABLE_COLUMNS, ORGANIZATION_TABLE_COLUMNS } from './constants'
import { OptionProps } from './FailedRecurringPaymentContainer'

interface FailedRecurringPaymentViewProps {
  date?: string[]
  errorMessages?: string[]
  hasMoreRecords?: boolean
  isLoading?: boolean
  isOrganizationContext?: boolean
  nursery?: OptionProps
  onDateChange?: (date: Moment[]) => void
  onExportClick?: () => void
  onNurseryChange?: (nursery: OptionProps) => void
  onPageChange: () => void
  onPaymentMethodChange?: (room: OptionProps) => void
  onRoomChange?: (room: OptionProps) => void
  paymentMethod?: OptionProps
  room?: OptionProps
  tableData?: any
  title?: string
  totalResults?: number
}

const FailedRecurringPaymentView: React.FC<PropsWithChildren<FailedRecurringPaymentViewProps>> = ({
  date,
  errorMessages,
  hasMoreRecords,
  isLoading,
  isOrganizationContext,
  nursery,
  onDateChange,
  onExportClick,
  onNurseryChange,
  onPageChange,
  onPaymentMethodChange,
  onRoomChange,
  paymentMethod,
  room,
  tableData,
  title,
  totalResults,
}) => {
  const renderContent = () => {
    if (isLoading && !hasMoreRecords) {
      return <Spinner />
    }

    if (errorMessages) {
      return null
    }

    if (!isLoading && !tableData?.length) {
      return (
        <EmptyState
          icon="reports"
          text1={i18n.t('global:EmptyState:long')}
        />
      )
    }

    return (
      <React.Fragment>
        <Pagination totalResults={totalResults} />
        <InfiniteScroll
          dataLength={tableData?.length || 0}
          hasMore={hasMoreRecords}
          next={() => onPageChange()}
        >
          <Table
            columns={isOrganizationContext ? ORGANIZATION_TABLE_COLUMNS : NURSERY_TABLE_COLUMNS}
            data={tableData}
            minWidth={1300}
            visualType="transparent"
          />
        </InfiniteScroll>
      </React.Fragment>
    )
  }

  const actions = <Section.Actions options={[{ onClick: onExportClick, type: 'export' }]} />

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading && !hasMoreRecords}
      title={title}
    >
      <Callout content={errorMessages} error />
      <Toolbar bottomLine>
        <Toolbar.Group>
          <Toolbar.Item>
            <Form.Row
              label={i18n.t('global:Date')}
              verticalLabel
            >
              <DatePicker
                value={date}
                clearable
                range
                onChange={onDateChange}
              />
            </Form.Row>
          </Toolbar.Item>
          <Toolbar.Item>
            <Form.Row
              label={i18n.t('global:Room')}
              verticalLabel
            >
              <InfiniteDropdowns.Rooms
                placeholder={i18n.t('global:AllRooms')}
                value={room}
                onChange={onRoomChange}
              />
            </Form.Row>
          </Toolbar.Item>
          <Toolbar.Item>
            <Form.Row
              label={i18n.t('module:Finance:Reports:FailedRecurringPayment:Filters:PaymentMethod:label')}
              verticalLabel
            >
              <Select
                options={RECURRING_PAYMENT_METHOD_OPTIONS}
                value={paymentMethod}
                onChange={onPaymentMethodChange}
              />
            </Form.Row>
          </Toolbar.Item>
          {isOrganizationContext && (
            <Toolbar.Item>
              <Form.Row
                label={i18n.t('module:Finance:Reports:FailedRecurringPayment:Filters:Site:label')}
                verticalLabel
              >
                <InfiniteDropdowns.Nurseries
                  value={nursery}
                  onChange={onNurseryChange}
                />
              </Form.Row>
            </Toolbar.Item>
          )}
        </Toolbar.Group>
      </Toolbar>
      {renderContent()}
    </Page.Section>
  )
}

export default FailedRecurringPaymentView
