import React, { Component } from 'react'
import { compose } from 'recompose'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withHomeObservationsService } from 'services/legacy/homeObservations'
import { withObservationsService } from 'services/legacy/observations'
import { withRouter } from 'services/router'

import ObservationsAddView from './ObservationsAddView'

const HOME_OBSERVATION_GROUPS = {
  read: [
    'child',
    'carer',
    'medium',
    'homeObservation.media',
    'homeObservation.carer',
    'homeObservation.child',
    'homeObservation.observations',
  ],
}

class ObservationsAddContainer extends Component {
  componentDidMount() {
    const { homeObservationsActions, params } = this.props
    const { homeObservationId } = params

    return homeObservationsActions.getHomeObservation({
      onSuccess: this.handleSuccessHomeObservation,
      params: [homeObservationId, {
        groups: HOME_OBSERVATION_GROUPS,
      }],
    })
  }

  handleSuccessHomeObservation = ({ data }) => {
    const { homeObservationsSelectors, navigate, observationsActions, observationsSelectors } = this.props

    const formData = homeObservationsSelectors.getInitialValuesFromHomeObservation(data)
    const body = observationsSelectors.getUpdateObservationPayload(formData)

    return observationsActions.create({
      body,
      onSuccess: ({ data: { id } }) => {
        navigate(`${generateRoute('LEARNING.OBSERVATIONS.EDIT', {
          observationId: id,
        })}?fromAdd`)
      },
      params: [{}],
    })
  }

  render() {
    return (
      <ObservationsAddView />
    )
  }
}

const enhance = compose(
  withAppService,
  withHomeObservationsService,
  withObservationsService,
  withRouter,
)

export default enhance(ObservationsAddContainer)
