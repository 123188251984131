import moment from 'moment'

import React from 'react'

import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { Avatar, ModalBox, Space, Typography } from 'components'

import i18n from 'translations'

import AddTimeModalForm from './components/AddTimeModalForm'
import { StyledHeader } from './AddTimeModalStyled'

const AddTimeModalView = ({
  formValues,
  initialValues,
  memberDetails,
  onCalculateTimeRange,
  onCloseClick,
  onSubmit,
  selectedDate,
  timeRange,
}) => {
  const { firstName, photo, roleLabels, surname = '' } = memberDetails || {}

  const renderHeader = () => (
    <StyledHeader>
      <Avatar
        gap={15}
        initials={[firstName, surname]}
        src={photo}
        subTitle={(
          <Typography color={NEUTRAL_COLOURS.GRAY}>
            {roleLabels}
          </Typography>
        )}
        title={(
          <Typography fontSize={24} bold>
            {`${firstName} ${surname}`}
          </Typography>
        )}
      />

      <Typography fontSize={22} variant="span" bold>
        {moment(selectedDate).format('dddd')}
        {', '}
        <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={22} variant="span" medium>
          {moment(selectedDate).format(DISPLAY_SHORT_DATE_FORMAT)}
        </Typography>
        <Space space="10px" />
      </Typography>
    </StyledHeader>
  )

  return (
    <ModalBox
      title={i18n.t('module:Modals:Staff:Worklog:AddTime:title')}
      autoHeight
      onCloseClick={onCloseClick}
    >
      {renderHeader()}
      <AddTimeModalForm
        formValues={formValues}
        initialValues={initialValues}
        timeRange={timeRange}
        onCalculateTimeRange={onCalculateTimeRange}
        onCloseClick={onCloseClick}
        onSubmit={onSubmit}
      />
    </ModalBox>
  )
}

export default AddTimeModalView
