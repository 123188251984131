import _ from 'lodash'

import React from 'react'

import { generateRoute } from 'utils/routing'
import { BasicViewProps } from 'constants/models'
import { ReferenceCodeGenerationProps } from 'services/nurseries/models'

import { Field, Page, Section, Space, Spinner, Typography } from 'components'

import i18n from 'translations'

export interface ReferenceCodeGenerationViewProps extends BasicViewProps {
  isFetching: boolean
  referenceCodeGeneration: ReferenceCodeGenerationProps
}

const ReferenceCodeGenerationView: React.FC<ReferenceCodeGenerationViewProps> = ({
  isFetching,
  referenceCodeGeneration,
}) => {
  const {
    codePrefix,
    codePrefixEnabled,
    randomisedBySystem,
    sequentialNumber,
  } = referenceCodeGeneration || {}

  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    return (
      <React.Fragment>
        <Field.InlineEdit
          description={randomisedBySystem
            ? i18n.t('module:Management:Finance:ReferenceCodeGeneration:randomisedBySystem')
            : i18n.t('module:Management:Finance:ReferenceCodeGeneration:sequentialNumbers')}
          label={i18n.t('module:Management:Finance:ReferenceCodeGeneration:codeAutomaticGeneration')}
        />
        <Field.InlineEdit
          description={codePrefixEnabled
            ? _.trim(codePrefix)
            : i18n.t('module:Management:Finance:ReferenceCodeGeneration:noPrefix')}
          label={i18n.t('module:Management:Finance:ReferenceCodeGeneration:codePrefix')}
        />
        {!randomisedBySystem && (
          <Field.InlineEdit
            description={sequentialNumber?.toString()}
            label={i18n.t('module:Management:Finance:ReferenceCodeGeneration:nextNumbersInSequenceFrom')}
          />
        )}
      </React.Fragment>
    )
  }

  const actions = (
    <Section.Actions
      tertiary={{ to: generateRoute('MANAGEMENT.FINANCE_SETTINGS.REFERENCE_CODE_GENERATION.EDIT') }}
    />
  )

  return (
    <Page.Section
      actions={actions}
      title={i18n.t('module:Management:Finance:ReferenceCodeGeneration:title')}
    >
      <Typography fontSize={18}>
        {i18n.t('module:Management:Finance:ReferenceCodeGeneration:subtitle')}
      </Typography>
      <Space margin="40px 0 0" />
      {renderContent()}
    </Page.Section>
  )
}

export default ReferenceCodeGenerationView
