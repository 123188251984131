import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { change, getFormValues, stopSubmit } from 'redux-form'

import { STAFF_MODULE_TYPE } from 'services/legacy/memberships/constants'

import { EVENTS, logEvent } from 'analytics'

import { withMembershipsService } from 'services/legacy/memberships'
import { withModalService } from 'services/utils/modal'

import withStaffExportModal from 'module/Modals/Staff/withStaffExportModal'

import { BREAKDOWN } from './constants'
import { STAFF_WORKLOG_EXPORT_MODAL_FORM } from './components/ExportModalForm'
import ExportModalView from './ExportModalView'

class ExportModalContainer extends Component {
  componentWillUnmount() {
    const { membershipsActions } = this.props

    membershipsActions.clear()
  }

  handleBreakdownChange = (e) => {
    const { changeFieldValue, staffExportHelpers } = this.props

    return staffExportHelpers.handleBreakdownChange({
      BREAKDOWN,
      changeFieldValue,
      value: e.target.value,
    })
  }

  handleReportDateChange = (e) => {
    const { changeFieldValue, staffExportHelpers } = this.props

    return staffExportHelpers.handleReportDateChange({
      changeFieldValue,
      value: e.target.value,
    })
  }

  handleSendReport = (values) => {
    const {
      membershipsSelectors,
      staffExportHelpers,
      stopSubmitForm,
    } = this.props

    const { breakdown, sendTo } = values

    const reportDatesRange = staffExportHelpers.getDateRange(values)

    const criteria = BREAKDOWN.PAYROLL === breakdown
      ? membershipsSelectors.getStaffPayrollCriteria({
        ...values,
        reportDatesRange,
      })
      : membershipsSelectors.getStaffWorklogCriteria({
        ...values,
        reportDatesRange,
      })

    const body = {
      criteria,
    }

    return staffExportHelpers.handleSendReport({
      moduleType: BREAKDOWN.PAYROLL === breakdown ? STAFF_MODULE_TYPE.PAYROLL : STAFF_MODULE_TYPE.WORKLOG,
      onSuccess: () => logEvent(EVENTS.STAFF_WORKLOG_EXPORTED),
      params: body,
      sendTo,
      stopSubmitForm,
      type: breakdown,
    })
  }

  render() {
    const { formValues, staffExportHelpers, staffExportState } = this.props
    const { isExporting } = staffExportState

    const initialValues = staffExportHelpers.getInitialValues({ breakdown: BREAKDOWN.DAILY })

    return (
      <ExportModalView
        formValues={formValues}
        initialValues={initialValues}
        isProcessing={isExporting}
        onBreakdownChange={this.handleBreakdownChange}
        onCloseClick={staffExportHelpers.handleCloseClick}
        onReportDateChange={this.handleReportDateChange}
        onSubmit={this.handleSendReport}
      />
    )
  }
}

const mapState = (state) => ({
  formValues: getFormValues(STAFF_WORKLOG_EXPORT_MODAL_FORM)(state),
})

const mapDispatch = {
  changeFieldValue: (field, value) => change(STAFF_WORKLOG_EXPORT_MODAL_FORM, field, value),
  stopSubmitForm: (errors) => stopSubmit(STAFF_WORKLOG_EXPORT_MODAL_FORM, errors),
}

const enhance = compose(
  withModalService,
  withMembershipsService,
  withStaffExportModal,
  connect(mapState, mapDispatch),
)

export default enhance(ExportModalContainer)
