import styled from 'styled-components'

import colors from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledTitle = styled.strong`
  min-width: 100px;
  display: inline-block;
  
  ${({ senary }) => senary && `
    color: ${getBrandingColor('senary-color')};
  `}
`

export const StyledMainTitle = styled.div`
  text-align: center;
  font-weight: 800;
  padding: 10px;
`

export const StyledValue = styled.span`
  ${({ senary }) => senary && `
    color: ${getBrandingColor('senary-color')};
  `}  
  
  ${({ red }) => red && `
    color: ${colors.remove};
  `}
`
