import _ from 'lodash'
import { flatten } from 'utils/flatnest'

import { toFloat } from 'utils/data'

import { Currency, DateString, ReportView } from 'components'

import i18n from 'translations'

import { NURSERY_TABLE_COLUMNS, ORGANIZATION_TABLE_COLUMNS } from './constants'

export const getColumns = (isOrganizationContext) => {
  if (isOrganizationContext) {
    return ORGANIZATION_TABLE_COLUMNS
  }

  return NURSERY_TABLE_COLUMNS
}

export const getTableData = ({ data, filters, hasError, isOrganizationContext }) => {
  if (!data?.length || hasError) {
    return null
  }

  const queryString = new URLSearchParams(flatten({
    year: filters?.date ? filters?.date?.format('YYYY') : undefined,
  })).toString()

  return _.map(data, ({
    date,
    existingDecrease,
    existingIncrease,
    leavers,
    monthlyTotal,
    netChange,
    resource,
    starters,
  }) => ({
    date: <DateString date={date} format="MMMM YYYY" />,
    existingDecrease: toFloat(existingDecrease),
    existingIncrease: toFloat(existingIncrease),
    leavers: toFloat(leavers),
    monthlyTotal: toFloat(monthlyTotal),
    name: isOrganizationContext
      && (
        <ReportView.NurseryLink
          nursery={resource}
          queryString={queryString}
          routeKey="REPORTS.MONTHLY_FTE_CHANGE"
        />
      ),
    netChange: toFloat(netChange),
    starters: toFloat(starters),
  }))
}

export const getStatistics = ({ data, hasError, isOrganizationContext }) => {
  if (!data || !isOrganizationContext || hasError) {
    return null
  }

  return [
    {
      label: i18n.t('module:Reports:MonthlyFteChange:Organization:Statistics:totalSignups'),
      value: data.signups,
    },
    {
      label: i18n.t('module:Reports:Common:totalFte'),
      value: toFloat(data.fte),
    },
    {
      label: i18n.t('module:Reports:Common:totalWeeklyFee'),
      value: <Currency value={data.weeklyFee} />,
    },
  ]
}
