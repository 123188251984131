import React from 'react'

import { Callout } from 'components'

import ActivityDetailAddForm from './components/ActivityDetailAddForm'

const ActivityDetailAddView = ({
  errorMessage,
  formName,
  ...rest
}) => (
  <React.Fragment>
    <Callout content={errorMessage} error />
    <ActivityDetailAddForm
      form={formName}
      {...rest}
    />
  </React.Fragment>
)

export default ActivityDetailAddView
