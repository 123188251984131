import styled from 'styled-components'

export const StyledButtonWrapper = styled.div`
  @media(max-width: 480px) {
    line-height: 16px;
    font-size: 12px;
    
    a {
      height: auto;
    }

    .MuiButton-label {
      white-space: normal;
      line-height: 14px;
      padding: 5px 0;
      text-align: center;
    }
  }
`
