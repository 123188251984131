import { generateBasicActions } from 'utils/service'

import {
  CLEAR_LIST_ENQUIRY_VIEWINGS,
  EXPORT_ENQUIRY_VIEWINGS,
  EXPORT_ENQUIRY_VIEWINGS_FAILED,
  EXPORT_ENQUIRY_VIEWINGS_SUCCESS,
  LIST_ENQUIRY_VIEWINGS,
  LIST_ENQUIRY_VIEWINGS_FAILED,
  LIST_ENQUIRY_VIEWINGS_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ enquiryViewingsApiClient }) => generateBasicActions.list({
  constants: {
    failed: LIST_ENQUIRY_VIEWINGS_FAILED,
    init: LIST_ENQUIRY_VIEWINGS,
    success: LIST_ENQUIRY_VIEWINGS_SUCCESS,
  },
  options,
  service: enquiryViewingsApiClient,
  serviceMethod: 'list',
})

export const exportViewings = (options = {}) => ({ enquiryViewingsApiClient }) => generateBasicActions.list({
  constants: {
    failed: EXPORT_ENQUIRY_VIEWINGS_FAILED,
    init: EXPORT_ENQUIRY_VIEWINGS,
    success: EXPORT_ENQUIRY_VIEWINGS_SUCCESS,
  },
  options: {
    ...options,
    onlyData: true,
  },
  service: enquiryViewingsApiClient,
  serviceMethod: 'export',
})

export const clear = () => ({
  type: CLEAR_LIST_ENQUIRY_VIEWINGS,
})
