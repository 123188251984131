import { createSelector } from 'reselect'

const getDepositsStatisticsState = (state) => state.deposits.statistics

export const getStatisticsSelector = createSelector([getDepositsStatisticsState], (state) => state.data)

export const getStatisticsCriteria = (filters) => {
  const {
    childId,
    search,
  } = filters

  const criteria = []

  if (search) {
    criteria.push({
      field: 'or[0][childName][like][]',
      value: `%${search}%`,
    })
  }

  if (childId) {
    criteria.push({
      field: 'child',
      value: childId,
    })
  }

  return criteria
}
