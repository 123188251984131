import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { EVENTS, logEvent } from 'analytics'

import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withRegisterService } from 'services/legacy/register'

import DownloadRegisterModalView from './DownloadRegisterModalView'

class DownloadRegisterModalContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      initialValues: {
        entryDate: [moment().startOf('week'), moment().endOf('week')],
        includeComments: true,
      },
      isProcessing: false,
    }
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSendReportSuccess = () => {
    this.setState({ isProcessing: false })

    logEvent(EVENTS.REGISTER_DOWNLOADED)

    this.handleCloseClick()
  }

  handleSendReportFailed = () => {
    this.setState({ isProcessing: false })
  }

  handleSendReport = (params) => {
    const { registerActions, registerSelectors } = this.props

    this.setState({ isProcessing: true })

    const { criteria, extra } = registerSelectors.getReportCriteria(params)

    registerActions.download({
      onFailed: this.handleSendReportFailed,
      onSuccess: this.handleSendReportSuccess,
      params: [{
        criteria,
        extra,
      }],
    })
  }

  render() {
    const { isFinanceV3Enabled } = this.props
    const { initialValues, isProcessing } = this.state

    return (
      <DownloadRegisterModalView
        initialValues={initialValues}
        isFinanceV3Enabled={isFinanceV3Enabled}
        isProcessing={isProcessing}
        onCloseClick={this.handleCloseClick}
        onSubmit={this.handleSendReport}
      />
    )
  }
}

const mapState = (state) => ({
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
})

const enhance = compose(
  withAppService,
  withRegisterService,
  withModalService,
  connect(mapState),
)

export default enhance(DownloadRegisterModalContainer)
