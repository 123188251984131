import moment from 'moment'

import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { CHILD_AGES_OPTIONS } from 'services/legacy/child/constants'
import { ROLES } from 'constants/security'

import { withAppService } from 'services/app'
import { withChildrenLeavingReportService } from 'services/legacy/childrenLeavingReport'
import { withPaginationUtils } from 'services/utils/pagination'
import { withSecurityService } from 'services/security'
import { withRouter } from 'services/router'

import { useBasicReport } from 'hooks/reports'

import { DatePicker, Form, InfiniteDropdowns, ReportView, Select, Toolbar } from 'components'

import i18n from 'translations'

import { getColumns, getStatistics, getTableData } from './ChildrenLeavingHelper'

const CHILDREN_SIGNUP_GROUPS = {
  read: [
    'child',
    'child.nurseryClass',
    'nurseryClass',
    'child.extend',
  ],
}

const NURSERIES_SIGNUP_GROUPS = {
  read: [
    'nursery',
  ],
}

const ChildrenLeavingContainer = ({
  childrenData,
  childrenLeavingReportActions,
  childrenLeavingReportSelectors,
  childrenTotalResults,
  errorMessages,
  isFetching,
  isOrganizationContext,
  isStatisticsFetching,
  location,
  nurseriesData,
  nurseriesTotalResults,
  paginationUtils,
  setLocationQuery,
  statisticsData,
}) => {
  const {
    age,
    dateRange,
    onAgeChange,
    onDateChange,
    onPageChange,
    onRoomChange,
    page,
    pageCount,
    perPage,
    room,
    statistics,
    tableColumns,
    tableData,
    totalResults,
  } = useBasicReport({
    actions: childrenLeavingReportActions,
    ageOptions: CHILD_AGES_OPTIONS,
    callStatisticsAction: true,
    childrenApiGroups: CHILDREN_SIGNUP_GROUPS,
    childrenData,
    childrenTotalResults,
    defaultFilters: {
      date: {
        after: moment().startOf('month'),
        before: moment().endOf('month'),
      },
    },
    errorMessages,
    getColumns,
    getStatistics,
    getTableData,
    isOrganizationContext,
    location,
    nurseriesApiGroups: NURSERIES_SIGNUP_GROUPS,
    nurseriesData,
    nurseriesTotalResults,
    paginationUtils,
    selectors: childrenLeavingReportSelectors,
    setLocationQuery,
    statisticsData,
  })

  return (
    <ReportView.DetailPage
      description={i18n.t('module:Reports:ChildrenLeaving:description')}
      errorMessages={errorMessages}
      filters={(
        <Toolbar bottomLine>
          <Toolbar.Group>
            <Toolbar.Item>
              <Form.Row
                label={i18n.t('module:Reports:ChildrenLeaving:Filters:LeaveDate:label')}
                verticalLabel
              >
                <DatePicker
                  clearable={false}
                  value={dateRange}
                  range
                  onChange={onDateChange}
                />
              </Form.Row>
            </Toolbar.Item>
            {!isOrganizationContext && (
              <Toolbar.Item>
                <Form.Row
                  label={i18n.t('module:Reports:ChildrenLeaving:Filters:Rooms:label')}
                  verticalLabel
                >
                  <InfiniteDropdowns.Rooms
                    placeholder={i18n.t('global:AllRooms')}
                    value={room}
                    onChange={onRoomChange}
                  />
                </Form.Row>
              </Toolbar.Item>
            )}
            <Toolbar.Item>
              <Form.Row
                label={i18n.t('module:Reports:ChildrenLeaving:Filters:Age:label')}
                verticalLabel
              >
                <Select
                  options={CHILD_AGES_OPTIONS}
                  placeholder={i18n.t('global:AllAges')}
                  value={age}
                  onChange={onAgeChange}
                />
              </Form.Row>
            </Toolbar.Item>
          </Toolbar.Group>
        </Toolbar>
      )}
      isOrganizationContext={isOrganizationContext}
      isPageLoading={isFetching && isStatisticsFetching && 1 === page}
      isStatisticsLoading={isStatisticsFetching}
      isTableLoading={isFetching}
      minWidth={1200}
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      statistics={statistics}
      tableColumns={tableColumns}
      tableData={tableData}
      title={i18n.t('module:Reports:ChildrenLeaving:title')}
      totalResults={totalResults}
      hideExport
      onPageChange={onPageChange}
    />
  )
}

const mapState = (state, {
  appSelectors,
  childrenLeavingReportChildrenState,
  childrenLeavingReportNurseriesState,
  childrenLeavingReportSelectors,
  childrenLeavingReportStatisticsState,
  securitySelectors,
}) => ({
  childrenData: childrenLeavingReportSelectors.getChildrenLeavingReportChildrenData(state),
  childrenTotalResults: appSelectors.getTotalResults(childrenLeavingReportChildrenState),
  errorMessages: appSelectors.getErrorMessages(
    childrenLeavingReportChildrenState,
    childrenLeavingReportNurseriesState,
    childrenLeavingReportStatisticsState,
  ),
  isFetching: appSelectors.getIsFetching(childrenLeavingReportChildrenState, childrenLeavingReportNurseriesState),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
  isStatisticsFetching: appSelectors.getIsFetching(childrenLeavingReportStatisticsState),
  nurseriesData: childrenLeavingReportSelectors.getChildrenLeavingReportNurseriesData(state),
  nurseriesTotalResults: appSelectors.getTotalResults(childrenLeavingReportNurseriesState),
  statisticsData: childrenLeavingReportSelectors.getChildrenLeavingReportStatisticsData(state),
})

const enhance = compose(
  withRouter,
  withAppService,
  withChildrenLeavingReportService,
  withPaginationUtils,
  withSecurityService,
  connect(mapState),
)

ChildrenLeavingContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

export default enhance(ChildrenLeavingContainer)
