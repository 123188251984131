import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { NONE_VALUE, OBSERVATION_STATES } from 'services/legacy/frameworks/constants'
import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'

import { Accordion, Button, DropdownMenu, Icon, ModalBox, Spinner, Toolbar, Typography } from 'components'
import ProgressLabel from 'module/Learning/Observations/components/ProgressLabel'

import i18n from 'translations'

import FrameworkSelectProgressModalForm from './components/FrameworkSelectProgressModalForm'
import {
  StyledHistoryItemWrapper,
  StyledHistoryProgressDetailRow,
  StyledStep,
  StyledStepsContainer,
} from './FrameworkSelectProgressModalStyled'

const FrameworkSelectProgressModalView = ({
  childId,
  description,
  isFetching,
  isFetchingProgressHistory,
  isFrameworkMontessori,
  onCloseClick,
  onRemove,
  onView,
  progressHistory,
  ...rest
}) => {
  const renderProgressHistoryItem = (item, index) => {
    const childObservation = _.find(item?.observation?.childObservations, ({ child: childItem }) => (
      childItem?.id === childId
    ))

    const renderNextStep = (nextStep, id) => {
      const { comments: nextStepComments, frameworkAreas, id: nextStepId } = nextStep

      return (
        <React.Fragment key={`nextStep_${nextStepId}`}>
          <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} margin="10px 0 0" wordBreak="break-word">
            {`${id + 1}. `}
            {nextStepComments}
          </Typography>
          <StyledStepsContainer>
            {_.map(frameworkAreas, (area) => (
              <StyledStep key={`frameworkArea_${area.id}`}>
                {area.name}
              </StyledStep>
            ))}
          </StyledStepsContainer>
        </React.Fragment>
      )
    }

    const stateObject = _.find(OBSERVATION_STATES, (element) => item?.state === element.value)

    return (
      <StyledHistoryItemWrapper
        last={progressHistory.length - 1 === index}
      >
        <Toolbar>
          <Toolbar.Group>
            <Toolbar.Item noMargin={!item.progressLevel?.default}>
              {!item.progressLevel?.default && (item?.state !== NONE_VALUE)
                ? (
                  <ProgressLabel
                    subTitle={moment(item.date).format(DISPLAY_SHORT_DATE_FORMAT)}
                    value={isFrameworkMontessori ? stateObject : item.progressLevel}
                  />
                )
                : (
                  <Typography fontSize={12} maxWidth={100} bold>
                    {i18n.t('module:Modals:FrameworkSelectProgressModal:markAsNone')}
                  </Typography>
                )}
            </Toolbar.Item>
            <Toolbar.Item noMargin>
              <Typography>
                {(isFrameworkMontessori || item.professionalOpinion) && (
                  <Typography fontSize={15} margin="0 0 0 10px" variant="span" bold>
                    {i18n.t('module:Modals:FrameworkSelectProgressModal:professionalOpinion')}
                  </Typography>
                )}
                {!isFrameworkMontessori && !item.professionalOpinion && (
                  <Typography fontSize={15} margin="0 0 0 10px" variant="span" bold>
                    {i18n.t('module:Modals:FrameworkSelectProgressModal:observation')}
                  </Typography>
                )}
                {' '}
                {i18n.t('module:Modals:FrameworkSelectProgressModal:by')}
                {' '}
                {item.observation ? item.observation.author.name : item.author?.name}
              </Typography>
            </Toolbar.Item>
          </Toolbar.Group>
          <Toolbar.Group>
            {!isFrameworkMontessori && !item.professionalOpinion && (
              <Toolbar.Item noMargin>
                <Button
                  hierarchy="tertiary"
                  label={i18n.t('global:View')}
                  size="small"
                  onClick={() => onView(item.observation.id)}
                />
              </Toolbar.Item>
            )}
            {(isFrameworkMontessori || item.professionalOpinion) && (
              <Toolbar.Item noMargin>
                <DropdownMenu
                  button={(
                    <Button
                      hierarchy="tertiary"
                      icon="dots"
                      size="small"
                      negativeMargins
                    />
                  )}
                >
                  <DropdownMenu.Item type="delete" onClick={() => onRemove(item)} />
                </DropdownMenu>
              </Toolbar.Item>
            )}
          </Toolbar.Group>
        </Toolbar>
        {item.observation && (
          <React.Fragment>
            {childObservation?.comments && (
              <StyledHistoryProgressDetailRow>
                <Icon
                  height={22}
                  icon="minus"
                />
                <div>
                  <Typography fontSize={14}>
                    {i18n.t('module:Modals:FrameworkSelectProgressModal:observationSummary')}
                  </Typography>
                  <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} margin="10px 0 0">
                    {childObservation.comments}
                  </Typography>
                </div>
              </StyledHistoryProgressDetailRow>
            )}
            {(childObservation?.nextSteps?.length || childObservation?.nextMontessoriActivities?.length) ? (
              <StyledHistoryProgressDetailRow last>
                <Icon
                  height={22}
                  icon="minus"
                />
                <div>
                  <Typography fontSize={14}>
                    {i18n.t('module:Modals:FrameworkSelectProgressModal:nextSteps')}
                  </Typography>
                  {_.map([
                    // eslint-disable-next-line no-unsafe-optional-chaining
                    ...childObservation?.nextSteps,
                    ...(childObservation?.nextMontessoriActivities?.length ? [{
                      comments: _.join(
                        _.map(
                          childObservation?.nextMontessoriActivities,
                          (nextMontessoriActivity) => nextMontessoriActivity.montessoriActivity?.name,
                        ),
                        ', ',
                      ),
                      frameworkAreas: [{
                        id: 0,
                        name: childObservation?.nextMontessoriActivities[0].frameworkName,
                      }],
                    }] : []),
                  ], renderNextStep)}
                </div>
              </StyledHistoryProgressDetailRow>
            ) : null}
          </React.Fragment>
        )}
      </StyledHistoryItemWrapper>
    )
  }

  const renderProgressHistory = () => {
    if (isFetchingProgressHistory) {
      return (
        <Spinner />
      )
    }

    if (!progressHistory?.length) {
      return <div />
    }

    return (
      <Accordion>
        <Accordion.Item
          header={(
            <Typography variant="h6">
              {i18n.t('module:Modals:FrameworkSelectProgressModal:progressHistory')}
            </Typography>
          )}
        >
          {_.map(progressHistory, renderProgressHistoryItem)}
        </Accordion.Item>
      </Accordion>
    )
  }

  const renderStatementInfo = () => description && (
    <Typography fontSize={18} margin="0 0 20px">
      {description}
    </Typography>
  )

  const renderContent = () => {
    if (isFetching && !isFrameworkMontessori) {
      return (
        <Spinner />
      )
    }

    return (
      <FrameworkSelectProgressModalForm
        isFrameworkMontessori={isFrameworkMontessori}
        noDescription={!description}
        renderProgressHistory={renderProgressHistory}
        renderStatementInfo={renderStatementInfo}
        onCloseClick={onCloseClick}
        {...rest}
      />
    )
  }

  return (
    <ModalBox
      maxWidth={640}
      title={i18n.t('module:Modals:FrameworkSelectProgressModal:title')}
      autoHeight
      onCloseClick={onCloseClick}
    >
      {renderContent()}
    </ModalBox>
  )
}

export default FrameworkSelectProgressModalView
