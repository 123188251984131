import { GET_MY_DAILY_DIARY_LIST, GET_MY_DAILY_DIARY_LIST_FAILED, GET_MY_DAILY_DIARY_LIST_SUCCESS } from './constants'

export const getMyDailyDiary = (params, token, onSuccess, onFailed) => ({ dailyDiaryApiClient }) => (dispatch) => {
  dispatch({ type: GET_MY_DAILY_DIARY_LIST })

  dailyDiaryApiClient.getMyDailyDiary(params, token).then(
    (response) => {
      dispatch({
        payload: response,
        type: GET_MY_DAILY_DIARY_LIST_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    (error) => {
      dispatch({
        error,
        type: GET_MY_DAILY_DIARY_LIST_FAILED,
      })

      if (onFailed) {
        onFailed()
      }
    },
  )
}

