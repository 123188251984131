import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'
import { formValueSelector } from 'redux-form'

import { getChildSelector } from 'services/legacy/child/single/selectors'

import i18n from 'translations'

import { getCost } from '../list/selectors'

export const getChildAdhocSession = (state) => state.childAdhocSessions

export const getSingleData = createSelector(
  [getChildAdhocSession],
  (childAdhocSession) => childAdhocSession.single.data,
)

export const isArchivedSelector = createSelector(
  [getSingleData],
  (singleData) => {
    if (!singleData) {
      return null
    }

    const { archived } = singleData

    return archived
  },
)

const formSelector = (formName) => formValueSelector(formName)

const extraSessionSelector = (formName) => (state) => formSelector(formName)(state, 'nurserySession')
const takenDateSelector = (formName) => (state) => formSelector(formName)(state, 'takenDate')
const childDobSelector = (formName) => (state) => formSelector(formName)(state, 'childDob')

export const getRuntimeCost = (formName) => createSelector(
  [extraSessionSelector(formName), takenDateSelector(formName), childDobSelector(formName)],
  (extraSession, takenDate, childDob) => {
    const sessionId = extraSession ? extraSession.sessionId : null
    const startTime = extraSession ? extraSession.startTime : null
    const endTime = extraSession ? extraSession.endTime : null

    const rate = getCost(childDob, takenDate, sessionId, startTime, endTime)

    if (_.isNaN(rate)) {
      return 0
    }

    return rate
  },
)

export const getPayload = createSelector(
  [(fields) => fields],
  (fields) => {
    if (!fields) {
      return null
    }

    const { nurserySession, takenDate } = fields

    return {
      endTime: nurserySession && nurserySession.endTime,
      nurserySession: nurserySession && nurserySession.sessionId.value,
      startTime: nurserySession && nurserySession.startTime,
      takenDate: moment(takenDate).format('YYYY-MM-DD'),
    }
  },
)

export const getInitialValues = (isEdit) => createSelector(
  [getSingleData, getChildSelector],
  (singleData, child) => {
    if (!isEdit && child) {
      return { childDob: child.birthDate }
    }
    if (!singleData || !child) {
      return null
    }

    const { endTime, nurserySession, startTime, takenDate } = singleData
    const { archived, id: value, name: label, ...rest } = nurserySession

    return {
      childDob: child.birthDate,
      nurserySession: {
        endTime,
        sessionId: {
          label: `${label}${archived ? ` (${i18n.t('global:archived')})` : ''}`,
          value,
          ...rest,
        },
        startTime,
      },
      takenDate,
    }
  },
)

