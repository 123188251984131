import React from 'react'
import { connect } from 'react-redux'

import actions from './actions'
import selectors from './selectors'

const withChildFundingSessionSetService = (WrappedComponent) => {
  const mapState = ({ childFundingSessionSet }) => ({ childFundingSessionSet })

  const mapDispatch = {
    clear: actions.clearChildFundingSessionSet,
    clearSingle: actions.clearSingleChildFundingSessionSet,
    create: actions.createChildFundingSessionSet,
    get: actions.getChildFundingSessionSet,
    list: actions.listChildFundingSessionSet,
    remove: actions.deleteChildFundingSessionSet,
    update: actions.updateChildFundingSessionSet,
    updateList: actions.updateChildFundingSessionSetList,
  }

  const Component = (props) => {
    const {
      childFundingSessionSet,
      clear,
      clearSingle,
      create,
      get,
      list,
      remove,
      update,
      updateList,
      ...other
    } = props

    const childFundingSessionSetListState = {
      ...childFundingSessionSet.list,
      isEmpty: childFundingSessionSet.list.data && 0 === childFundingSessionSet.list.data.length,
    }
    const childFundingSessionSetSingleState = childFundingSessionSet.single
    const childFundingSessionSetActions = {
      clear,
      clearSingle,
      create,
      get,
      list,
      remove,
      update,
      updateList,
    }
    const childFundingSessionSetSelectors = selectors

    const getAllChildFundingSessionSet = async (params, cb, page = 1, oldData = []) => {
      await list({ ...params, page }, ({ data, meta }) => {
        const { limit, start } = meta
        const totalResults = meta.total_results
        const newData = [...oldData, ...data]

        if (start * limit < totalResults) {
          getAllChildFundingSessionSet(params, cb, page + 1, newData)
        } else if (cb) {
          cb({ data: newData })
        }
      }, 1 < page)
    }

    return (
      <WrappedComponent
        childFundingSessionSetActions={childFundingSessionSetActions}
        childFundingSessionSetHelpers={{ getAllChildFundingSessionSet }}
        childFundingSessionSetListState={childFundingSessionSetListState}
        childFundingSessionSetSelectors={childFundingSessionSetSelectors}
        childFundingSessionSetSingleState={childFundingSessionSetSingleState}
        {...other}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withChildFundingSessionSetService
