import { Property } from 'csstype'

export type ModalOptions = {
  backgroundFilter?: {
    type: Property.Filter
      | 'url'
      | 'blur'
      | 'brightness'
      | 'contrast'
      | 'drop-shadow'
      | 'grayscale'
      | 'hue-rotate'
      | 'invert'
      | 'opacity'
      | 'saturate'
      | 'sepia'
    value: string
  }
  /**
   * This option allows to display several modals at the same time
   */
  enableMultipleModal?: boolean
}

/* @deprecated */
export default {
  TYPES: {
    ACTIVITIES_STATISTICS: 'ACTIVITIES_STATISTICS',
    ADD_RECIPIENTS: 'ADD_RECIPIENTS',
    ALERT: 'ALERT',
    ASSIGN_STAFF_MEMBERS: 'ASSIGN_STAFF_MEMBERS',
    BAD_DEBT: 'BAD_DEBT',
    BALANCE_ADJUSTMENT: 'BALANCE_ADJUSTMENT',
    CHILDREN_EXPORT: 'CHILDREN_EXPORT',
    CHILD_LEAVE: 'CHILD_LEAVE',
    CHILD_PICKER: 'CHILD_PICKER',
    CONFIRM: 'CONFIRM',
    CONVERSATION_MEMBERS: 'CONVERSATION_MEMBERS',
    DAILY_DIARY: 'DAILY_DIARY',
    DAILY_DIARY_RECORD: 'DAILY_DIARY_RECORD',
    DEPOSITS_EXPORT: 'DEPOSIT_EXPORT',
    DEPOSIT_REFUND: 'DEPOSIT_REFUND',
    DIETARY_REPORT_EXPORT: 'DIETARY_REPORT_EXPORT',
    DOWNLOAD_REGISTER: 'DOWNLOAD_REGISTER',
    EDIT_ROOM_MOVE: 'EDIT_ROOM_MOVE',
    EMERGENCY_CONTACT_LISTS: 'EMERGENCY_CONTACT_LISTS',
    ENQUIRY_ENROL_CHILD: 'ENQUIRY_ENROL_CHILD',
    ENQUIRY_EXPORT: 'ENQUIRY_EXPORT',
    ENQUIRY_STATUS_CHANGE: 'ENQUIRY_STATUS_CHANGE',
    ENQUIRY_STATUS_TYPE_MIGRATION: 'ENQUIRY_STATUS_TYPE_MIGRATION',
    ENQUIRY_VIEWING_EDIT: 'ENQUIRY_VIEWING_EDIT',
    ENTER_STAFF_REGISTER: 'ENTER_STAFF_REGISTER',
    EXCEPTIONAL_UNAVAILABLE_TIMES: 'EXCEPTIONAL_UNAVAILABLE_TIMES',
    EXPORT_REPORT: 'EXPORT_REPORT',
    FILE_DETAILS: 'FILE_DETAILS',
    FORMATIVE_REPORTS_EXPORT: 'FORMATIVE_REPORTS_EXPORT',
    FORMATIVE_REPORTS_SEND: 'FORMATIVE_REPORTS_SEND',
    FRAMEWORK_BULK_MARK: 'FRAMEWORK_BULK_MARK',
    FRAMEWORK_SELECT_PROGRESS: 'FRAMEWORK_SELECT_PROGRESS',
    FUNDING: 'FUNDING',
    FUNDING_LOOP_EXPORT: 'FUNDING_LOOP_EXPORT',
    HELP: 'HELP',
    INJURY_SIGNATURE: 'INJURY_SIGNATURE',
    INVOICE_ADD: 'INVOICE_ADD',
    INVOICE_ADD_ITEM: 'INVOICE_ADD_ITEM',
    INVOICE_ADD_ITEM_V3: 'INVOICE_ADD_ITEM_V3',
    INVOICE_DETAIL_PREVIEW: 'INVOICE__DETAIL_PREVIEW',
    INVOICE_EXPORT: 'INVOICE_EXPORT',
    INVOICE_PREVIEW: 'INVOICE_PREVIEW',
    ISSUE_CREDIT_NOTE: 'ISSUE_CREDIT_NOTE',
    MEDIA_TAG: 'MEDIA_TAG',
    OBSERVATIONS_EXPORT: 'OBSERVATIONS_EXPORT',
    PLAN_DETAILS: 'PLAN_DETAILS',
    PREMIUM_PLAN: 'PREMIUM_PLAN',
    PREVIEW_INVOICE_DEDUCTION: 'PREVIEW_INVOICE_DEDUCTION',
    PREVIEW_LINE_ITEM: 'PREVIEW_LINE_ITEM',
    REGISTER_EXTRA_SESSION: 'REGISTER_EXTRA_SESSION',
    REGISTER_RESET: 'REGISTER_RESET',
    REGISTER_STAFF_LOG: 'REGISTER_STAFF_LOG',
    REGULAR_UNAVAILABLE_TIMES: 'REGULAR_UNAVAILABLE_TIMES',
    REQUESTED_EXTRA_SESSION: 'REQUESTED_EXTRA_SESSION',
    SELECT_FORMAT: 'SELECT_FORMAT',
    SEND_DRAFT_INVOICE: 'SEND_DRAFT_INVOICE',
    SESSION_SET_IMPACT: 'SESSION_SET_IMPACT',
    SESSION_TIMEOUT: 'SESSION_TIMEOUT',
    STAFF_CHANGE_LOG: 'STAFF_CHANGE_LOG',
    STAFF_LEAVE_ADD: 'STAFF_LEAVE_ADD',
    STAFF_LEAVE_EXPORT: 'STAFF_LEAVE_EXPORT',
    STAFF_LEGACY_CHANGE_LOG: 'STAFF_LEGACY_CHANGE_LOG',
    STAFF_ROTA_CHILDREN_EXPECTED: 'STAFF_ROTA_CHILDREN_EXPECTED',
    STAFF_ROTA_EXPORT: 'STAFF_ROTA_EXPORT',
    STAFF_ROTA_SHIFT: 'STAFF_ROTA_SHIFT',
    STAFF_ROTA_SHIFT_COPY: 'STAFF_ROTA_SHIFT_COPY',
    STAFF_WORKLOG_ADD_TIME: 'STAFF_WORKLOG_ADD_TIME',
    STAFF_WORKLOG_END_TIME: 'STAFF_WORKLOG_END_TIME',
    STAFF_WORKLOG_EXPORT: 'STAFF_WORKLOG_EXPORT',
    STRIPE_DISCONNECT_CONDITIONS: 'STRIPE_DISCONNECT_CONDITIONS',
    TERMS_UPDATE: 'TERMS_UPDATE',
  },
}

