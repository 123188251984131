import { createSelector } from 'reselect'

const getCarerChildRelationSelector = (state) => state.carerChildRelations.single

export const getCarerChildRelationDataSelector = createSelector([getCarerChildRelationSelector], (state) => state.data)

export const getPayload = ({
  carerId,
  childId,
  isEditMode,
  values,
}) => {
  const body = {
    hasAccessToChildInParentApp: values.hasAccessToChildInParentApp || false,
    hasParentalResponsibility: values.hasParentalResponsibility || false,
    shouldReceiveDailyDiaryEmail: values.shouldReceiveDailyDiaryEmail || false,
    shouldReceiveNewsletterEmail: values.shouldReceiveNewsletterEmail || false,
  }

  if (values?.collectionPassword) {
    body.collectionPassword = values?.collectionPassword
  }

  if (values?.relation) {
    body.relation = values?.relation?.label
  }

  if (!isEditMode) {
    body.carer = { id: carerId }
    body.child = { id: childId }
  }

  return body
}
