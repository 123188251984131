import React, { PropsWithChildren } from 'react'

import { StyledFooter } from './DetailsBreakdownStyled'

const DetailsBreakdownFooter: React.FC<PropsWithChildren<any>> = ({ children }) => (
  <StyledFooter>
    {children}
  </StyledFooter>
)

export default DetailsBreakdownFooter
