import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { stopSubmit } from 'redux-form'

import { EVENTS, logEvent } from 'analytics'
import { FEATURE_FLAGS } from 'constants/security'

import { getBackendErrors } from 'utils/backendErrors'
import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withInvoicesService } from 'services/legacy/invoices'
import { withModalService } from 'services/utils/modal'

import i18n from 'translations'

import { ALL_CHILDREN, EXPORT_FOR } from './constants'
import { INVOICES_EXPORT_MODAL_FORM } from './components/ExportModalForm'
import ExportModalView from './ExportModalView'

class ExportModalContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isExporting: false,
    }
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSubmitSuccess = (email) => {
    const { modalActions, modalConsts } = this.props

    this.setState({ isExporting: false })

    logEvent(EVENTS.INVOICE_LIST_EXPORTED)

    return modalActions.show(modalConsts.TYPES.ALERT, {
      text: i18n.t('module:Modals:ExportReportModal:alert', { email }),
    })
  }

  handleSubmitFailed = (response) => {
    const { injectValidation } = this.props

    this.setState({ isExporting: false })

    const errors = getBackendErrors(response || {})

    return setTimeout(() => injectValidation(errors))
  }

  handleSubmit = (values) => {
    const { invoicesActions, invoicesSelectors } = this.props

    const { exportFor, sendTo } = values

    this.setState({ isExporting: true })

    const criteria = invoicesSelectors.getAccountingCriteriaSelector(values)

    const params = {
      criteria,
    }

    return invoicesActions.exportInvoice(`accounting/${exportFor}`, {
      onFailed: this.handleSubmitFailed,
      onSuccess: () => this.handleSubmitSuccess(sendTo.email),
      params,
    })
  }

  render() {
    const { enableExtendedExport } = this.props
    const { isExporting } = this.state

    const initialValues = {
      child: ALL_CHILDREN,
      creditNotes: 1,
      dateRange: [moment().startOf('week'), moment().endOf('week')],
      exportFor: EXPORT_FOR.GENERAL,
      invoices: 1,
    }

    return (
      <ExportModalView
        enableExtendedExport={enableExtendedExport}
        initialValues={initialValues}
        isProcessing={isExporting}
        onCloseClick={this.handleCloseClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state) => ({
  enableExtendedExport: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.ACCOUNTING_EXTENDED_EXPORT],
  }),
})

const mapDispatch = {
  injectValidation: (errors) => stopSubmit(INVOICES_EXPORT_MODAL_FORM, errors),
}

const enhance = compose(
  withAppService,
  withInvoicesService,
  withModalService,
  connect(mapState, mapDispatch),
)

export default enhance(ExportModalContainer)
