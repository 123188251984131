import styled from 'styled-components'

import colors from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

import constants from 'services/legacy/invoices/constants'

export const StyledContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`

export const StyledStatusContainer = styled.div`
  flex: 0 0 200px;
`

export const StyledStatusTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
`

export const StyledStatus = styled.div`
  margin-top: ${({ marginTop = 10 }) => marginTop}px;
  font-size: ${({ fontSize = 20 }) => fontSize}px;
  font-weight: 600;
  line-height: 1;
  color: ${getBrandingColor('primary-color')};

  ${({ status }) => status && status === constants.STATUS_TYPES.CANCELED && `
    color: ${colors.gray};
  `}
`

export const StyledTablesContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`

export const StyledTable = styled.table`
  font-size: 14px;

  th,
  td {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  th {
    padding-right: 10px;
    text-align: ${({ right }) => (right ? 'right' : 'left')};
  }
`

export const StyledRowContainer = styled.div`
  display: table-row;
`

export const StyledCellHeaderContainer = styled.div`
  display: table-cell;
  height: 30px;
  padding-right: 20px;
`

export const StyledCellValueContainer = styled.div`
  display: table-cell;
  text-align: right;
`
