import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT } from 'constants/date'
import { ACTIVITY_TYPE, ENTRY_TAB_NAMES, LIST_TAB_NAMES } from 'services/legacy/enquiries/activities/constants'
import { ENQUIRY_STATUS_CODES } from 'services/legacy/enquiries/constants'

import { generateRoute } from 'utils/routing'

import {
  Button,
  DropdownMenu,
  EmptyState,
  Hyperlink,
  InfiniteDropdowns,
  InfiniteScroll,
  Space,
  Spinner,
  Tabs,
  Toolbar,
  Typography,
} from 'components'

import i18n from 'translations'

import { ActivityList } from './components'
import ActivityDetailAdd from './ActivityDetailAdd'
import { ACTIVITY_MAPPING, LIST_TAB_MAPPINGS } from './constants'
import { StyledBodyContainer, StyledHeader } from '../DetailStyled'
import { StyledActivityContent, StyledContentLayoutWrapper } from './ActivityDetailStyled'

const ActivityDetailView = ({
  enquiry,
  enquiryActivitiesList,
  enquiryId,
  entryTab,
  isFetching,
  listTab,
  onActivitySubmitSuccess,
  onChangeEntryTab,
  onChangeListTab,
  onDeleteActivityClick,
  onDeleteClick,
  onEnrolClick,
  onPageChange,
  onStatusChange,
  page,
  pageCount,
  status,
}) => {
  const { child, status: enquiryStatus } = enquiry || {}
  const { code: statusCode } = enquiryStatus || {}
  const { createdAt, id: childId } = child || {}

  const renderActivityEntry = () => (
    <Tabs
      fullWidth={false}
      value={entryTab}
      variant="standard"
      noContentPadding
      onChange={onChangeEntryTab}
    >
      <Tabs.Item
        name={ENTRY_TAB_NAMES.NOTE}
        title={i18n.t('module:Enquiries:List:Detail:Activity:Entry:Tabs:Note:title')}
      >
        <ActivityDetailAdd
          enquiryId={enquiryId}
          formName={ACTIVITY_MAPPING[ACTIVITY_TYPE.NOTE.value].formName}
          notePlaceholder={i18n.t('module:Enquiries:List:Detail:Activity:Entry:Tabs:Note:Note:placeholder')}
          submitButtonLabel={i18n.t('module:Enquiries:List:Detail:Activity:Entry:Tabs:Note:submitButtonLabel')}
          type={ACTIVITY_TYPE.NOTE.value}
          onSuccess={() => onActivitySubmitSuccess({ type: ACTIVITY_TYPE.NOTE.value })}
        />
      </Tabs.Item>
      <Tabs.Item
        name={ENTRY_TAB_NAMES.EMAIL}
        title={i18n.t('module:Enquiries:List:Detail:Activity:Entry:Tabs:Email:title')}
      >
        <ActivityDetailAdd
          enquiryId={enquiryId}
          formName={ACTIVITY_MAPPING[ACTIVITY_TYPE.EMAIL.value].formName}
          notePlaceholder={i18n.t('module:Enquiries:List:Detail:Activity:Entry:Tabs:Email:Note:placeholder')}
          submitButtonLabel={i18n.t('module:Enquiries:List:Detail:Activity:Entry:Tabs:Email:submitButtonLabel')}
          type={ACTIVITY_TYPE.EMAIL.value}
          onSuccess={() => onActivitySubmitSuccess({ type: ACTIVITY_TYPE.EMAIL.value })}
        />
      </Tabs.Item>
      <Tabs.Item
        name={ENTRY_TAB_NAMES.CALL}
        title={i18n.t('module:Enquiries:List:Detail:Activity:Entry:Tabs:Call:title')}
      >
        <ActivityDetailAdd
          enquiryId={enquiryId}
          formName={ACTIVITY_MAPPING[ACTIVITY_TYPE.CALL.value].formName}
          notePlaceholder={i18n.t('module:Enquiries:List:Detail:Activity:Entry:Tabs:Call:Note:placeholder')}
          submitButtonLabel={i18n.t('module:Enquiries:List:Detail:Activity:Entry:Tabs:Call:submitButtonLabel')}
          type={ACTIVITY_TYPE.CALL.value}
          onSuccess={() => onActivitySubmitSuccess({ type: ACTIVITY_TYPE.CALL.value })}
        />
      </Tabs.Item>
      <Tabs.Item
        name={ENTRY_TAB_NAMES.VIEWING}
        title={i18n.t('module:Enquiries:List:Detail:Activity:Entry:Tabs:Viewing:title')}
      >
        <ActivityDetailAdd
          enquiryId={enquiryId}
          formName={ACTIVITY_MAPPING[ACTIVITY_TYPE.VIEWING.value].formName}
          notePlaceholder={i18n.t('module:Enquiries:List:Detail:Activity:Entry:Tabs:Viewing:Note:placeholder')}
          submitButtonLabel={i18n.t('module:Enquiries:List:Detail:Activity:Entry:Tabs:Viewing:submitButtonLabel')}
          type={ACTIVITY_TYPE.VIEWING.value}
          onSuccess={() => onActivitySubmitSuccess({ type: ACTIVITY_TYPE.VIEWING.value })}
        />
      </Tabs.Item>
    </Tabs>
  )

  const renderActivityListContent = () => {
    if ((isFetching || !enquiryActivitiesList) && 1 === page) {
      return <Spinner />
    }

    if (!enquiryActivitiesList?.length) {
      return (
        <EmptyState
          icon={LIST_TAB_MAPPINGS[listTab]?.icon}
          text1={LIST_TAB_MAPPINGS[listTab]?.emptyState}
        />
      )
    }

    return (
      <InfiniteScroll
        dataLength={enquiryActivitiesList.length}
        hasMore={page < pageCount}
        isFetching={isFetching}
        next={() => onPageChange((+page) + 1)}
      >
        <ActivityList>
          {_.map(enquiryActivitiesList, (enquiryActivity) => (
            <ActivityList.Item
              enquiryActivity={enquiryActivity}
              enquiryId={enquiryId}
              key={enquiryActivity.id}
              onDeleteClick={() => onDeleteActivityClick(enquiryActivity)}
              onSuccess={() => onActivitySubmitSuccess({ isEditMode: true, type: enquiryActivity.type })}
            />
          ))}
        </ActivityList>
      </InfiniteScroll>
    )
  }

  const renderActivityList = () => (
    <React.Fragment>
      <Typography margin=" 50px 0 30px" variant="h3">
        {i18n.t('module:Enquiries:List:Detail:Activity:List:title')}
      </Typography>
      <Tabs
        fullWidth={false}
        value={listTab}
        variant="standard"
        onChange={onChangeListTab}
      >
        <Tabs.Item
          name={LIST_TAB_NAMES.ALL}
          title={i18n.t('module:Enquiries:List:Detail:Activity:List:Tabs:All:title')}
        >
          {renderActivityListContent()}
        </Tabs.Item>
        <Tabs.Item
          name={LIST_TAB_NAMES.NOTES}
          title={i18n.t('module:Enquiries:List:Detail:Activity:List:Tabs:Notes:title')}
        >
          {renderActivityListContent()}
        </Tabs.Item>
        <Tabs.Item
          name={LIST_TAB_NAMES.EMAILS}
          title={i18n.t('module:Enquiries:List:Detail:Activity:List:Tabs:Emails:title')}
        >
          {renderActivityListContent()}
        </Tabs.Item>
        <Tabs.Item
          name={LIST_TAB_NAMES.CALLS}
          title={i18n.t('module:Enquiries:List:Detail:Activity:List:Tabs:Calls:title')}
        >
          {renderActivityListContent()}
        </Tabs.Item>
        <Tabs.Item
          name={LIST_TAB_NAMES.VIEWINGS}
          title={i18n.t('module:Enquiries:List:Detail:Activity:List:Tabs:Viewings:title')}
        >
          {renderActivityListContent()}
        </Tabs.Item>
      </Tabs>
    </React.Fragment>
  )

  const renderEnrolContent = () => {
    if (ENQUIRY_STATUS_CODES.ENROLLED === statusCode) {
      return (
        <React.Fragment>
          <Typography>
            <Typography variant="span">
              {i18n.t('global:Enrolled')}
            </Typography>
            <Typography margin="0 0 0 5px" variant="span" bold>
              {moment(createdAt).format(DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT)}
            </Typography>
          </Typography>
          {childId && (
            <Hyperlink
              align="right"
              margin="5px 0 0"
              to={generateRoute('CHILDREN.CHILD.ABOUT.PROFILE', { childId })}
              primary
            >
              {i18n.t('module:Enquiries:List:Detail:Activity:Header:childRouteLinkLabel')}
            </Hyperlink>
          )}
        </React.Fragment>
      )
    }

    return (
      <Button
        hierarchy="tertiary"
        label={i18n.t('module:Enquiries:List:Detail:Activity:Header:enrolChildLabel')}
        onClick={onEnrolClick}
      />
    )
  }

  return (
    <StyledBodyContainer>
      <StyledHeader>
        <StyledContentLayoutWrapper>
          <Toolbar noMargin>
            <Toolbar.Group>
              <Toolbar.Item>
                {enquiry && (
                  <InfiniteDropdowns.EnquiryStatusTypes
                    clearable={false}
                    placeholder={i18n.t('module:Enquiries:List:Filters:Status:placeholder')}
                    rawValue={status}
                    value={status}
                    visualType="transparent"
                    onChange={onStatusChange}
                  />
                )}
              </Toolbar.Item>
            </Toolbar.Group>
            <Toolbar.Group>
              <Toolbar.Item>
                {enquiry && renderEnrolContent()}
              </Toolbar.Item>
              <Toolbar.Item>
                <DropdownMenu
                  minWidth={150}
                >
                  <DropdownMenu.Item
                    type="delete"
                    onClick={() => onDeleteClick(enquiryId)}
                  />
                </DropdownMenu>
              </Toolbar.Item>
            </Toolbar.Group>
          </Toolbar>
        </StyledContentLayoutWrapper>
      </StyledHeader>
      <StyledActivityContent>
        <StyledContentLayoutWrapper>
          {renderActivityEntry()}
          <Space space="30px" />
          {renderActivityList()}
        </StyledContentLayoutWrapper>
      </StyledActivityContent>
    </StyledBodyContainer>
  )
}

export default ActivityDetailView
