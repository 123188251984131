import _ from 'lodash'
import styled from 'styled-components'
import { Property } from 'csstype'

import layout from 'constants/layout'
import colors, { NEUTRAL_COLOURS } from 'constants/colors'

import { StyledInputWrapper } from 'components/DatePicker/DatePickerStyled'
import { StyledInputContainer } from 'components/TextField/TextFieldStyled'

interface StyledRowItemProps {
  $margin?: string
}

export const StyledRowItem = styled.div<StyledRowItemProps>`
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ $margin }) => $margin && `
    margin: ${$margin};
  `}

  @media(max-width: ${layout.mobileWidthResolution}px) {
    margin: 0 0 0 5px;    
  }
`

interface StyledRowTextItemProps {
  $flex?: string
  $margin?: string
}

export const StyledRowTextItem = styled.div<StyledRowTextItemProps>`
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;

  ${({ $flex }) => $flex && `
    flex: ${$flex};
  `}

  ${({ $margin }) => $margin && `
    margin: ${$margin};
  `}

  @media(max-width: ${layout.mobileWidthResolution}px) {
    ${({ $flex }) => $flex && `
      flex: ${$flex || 1};
    `}
  }
`

interface StyledRowLabelContainerProps {
  $autoHeight?: boolean
  $fontWeight?: string
  $labelMargin?: string
}

export const StyledRowLabelContainer = styled.div<StyledRowLabelContainerProps>`
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 14px;

  ${({ $fontWeight }) => $fontWeight && `
    font-weight: ${$fontWeight};
  `};

  ${({ $labelMargin }) => $labelMargin && `
    margin: ${$labelMargin};
  `};
  
  ${({ $autoHeight }) => $autoHeight && `
    height: auto;
  `};
  
  @media(max-width: ${layout.mobileWidthResolution}px) {
    flex: 0;
    height: auto;
    margin: 0 0 10px 0 !important;
  }
`

interface StyledRowFlexItemProps {
  $align?: Property.TextAlign
  $flex?: string
  $margin?: string
  $mobileFull?: boolean
  $noPadding?: boolean
}

export const StyledRowFlexItem = styled.div<StyledRowFlexItemProps>`
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  ${({ $flex }) => $flex && `
    flex: ${$flex};
  `}

  ${({ $align }) => $align && `
    text-align: ${$align};
  `} 
  
  ${({ $margin }) => $margin && `
    margin: ${$margin};
  `}
  
  @media(max-width: ${layout.mobileWidthResolution}px) {
    padding-left: 5px;
    padding-right: 5px;
    
    ${({ $flex }) => $flex && `
      flex: ${$flex || 1};
    `}

    ${({ $noPadding }) => $noPadding && `
      padding: 0;
    `}

    ${({ $mobileFull }) => $mobileFull && `
      flex: 1;
      max-width: 100%;
    `};
  }
`

interface StyledRowChildrenContainerProps {
  $children?: any
  $multipleFieldsInARow?: boolean
  $width?: string
}

export const StyledRowChildrenContainer = styled.div<StyledRowChildrenContainerProps>`
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  ${({ $width, children }) => !_.isArray(children) && $width && `
    & > * {
      width: 100%;
    }
  `}

  ${({ $multipleFieldsInARow }) => $multipleFieldsInARow && `
    & > div {
      margin-bottom: 5px;
    }

    & > div:not(:last-child) {
      margin-right: 10px;
    }

    @media(max-width: ${layout.mobileWidthResolution}px) {
      flex-direction: column;

      & > div {
        width: 100%;
      }

      & > div:not(:last-child) {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  `};

  ${({ $autoHeight }) => $autoHeight && `
    align-items: center;

    & > ${StyledRowItem}, ${StyledRowFlexItem} {
      min-height: auto;
    }
  `}
`

interface StyledRowContainerProps {
  $childrenLength?: number
  $label?: string
  $margin?: string
  $multipleFieldsInARow?: boolean
  $padding?: string
  $verticalLabel?: boolean
  $width?: {
    field?: string
    label?: string
  }
}

export const StyledRowContainer = styled.div<StyledRowContainerProps>`
  margin-bottom: 20px;
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 110px auto;
  width: 100%;
  
  ${({ $childrenLength }) => 1 < $childrenLength && `
    grid-template-columns: 110px auto;
  `}

  ${({ $label }) => !$label && `
    grid-template-columns: 300px;
  `}
  
  ${({ $verticalLabel }) => $verticalLabel && `
    grid-template-columns: auto;
  `}
  
  ${({ $margin }) => $margin && `
    margin: ${$margin};
  `}
  
  ${({ $padding }) => $padding && `
    padding: ${$padding};
  `}

  ${({ $multipleFieldsInARow }) => $multipleFieldsInARow && `
    margin-bottom: 15px;

    ${StyledRowTextItem} {
      @media(max-width: ${layout.mobileWidthResolution}px) {
        min-height: 0;
      }
    }
  `}

  @media(max-width: ${layout.mobileWidthResolution}px) {
    grid-template-columns: auto;
  }
  
  ${({ $width }) => ($width?.field || $width?.label) && `
    display: grid;
    flex: 1;
    grid-column-gap: 10px;
    grid-template-columns: ${$width?.label || '110px'} ${$width?.field || '300px'};
  `}
  
  ${({ $width }) => $width?.field && `
    ${StyledInputWrapper} {
      min-width: 0;
    }
  `}
  
  ${({ $label, $width }) => !$label && `
    grid-template-columns: ${$width?.field || 'auto'};
  `}
  
  ${({ $verticalLabel, $width }) => ($width?.field || $width?.label) && $verticalLabel && `
    grid-template-columns: auto;
    grid-column-gap: 0;
  `}
  
  ${({ $verticalLabel, $width }) => $width?.label && $verticalLabel && `
    ${StyledRowLabelContainer} {
      width: ${$width?.label};
    }
  `}
  
  ${({ $verticalLabel, $width }) => $width?.field && $verticalLabel && `
    ${StyledRowChildrenContainer},
    ${StyledInputContainer} {
      width: ${$width?.field};
    }
  `}
`

export const StyledRequiredChar = styled.span`
  color: ${colors.pink};
`

export const StyledTooltip = styled.div`
  margin-left: 10px;
`

export const StyledDivider = styled.hr`
  border: 0 none;
  border-top: 1px dashed ${NEUTRAL_COLOURS.SILVER};
  background: none;
  height: 0;
  margin: 20px 0;
`
