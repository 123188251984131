import styled from 'styled-components'

import layout from 'constants/layout'

import { StyledStickyFooter } from 'components/StickyFooter/StickyFooterStyled'

export const StyledTooltipContainer = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-column-gap: 5px;
  grid-template-columns: 100px auto 15px;
`

export const StyledTooltipContentContainer = styled.div`
  max-width: 150px;
`

export const StyledCustomStickyFooterDesign = styled.div`
  ${StyledStickyFooter} {
    @media(max-width: ${layout.mobileWidthResolution}px) {
      & > div,
      & > div > div {
        display: flex;
        flex-direction: row;
      }
    }
    
    @media(max-width: 410px) {
      & > div,
      & > div > div {
        flex-direction: column;
        display: grid;
        grid-row-gap: 2.5px;
      }
    }  
  }
`
