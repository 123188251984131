import { createSelector } from 'reselect'

const getRoomMove = (state) => state.roomMove

export const getRoomsMoveSingleSelector = createSelector(
  [getRoomMove],
  (state) => {
    if (!state) {
      return null
    }

    return state.single
  },
)

export const getRoomMoveValuesForm = (values) => {
  const { fromNurseryClass, moveOn, toNurseryClass } = values
  const data = {}

  if (fromNurseryClass && fromNurseryClass.value) {
    data.fromNurseryClass = {
      id: fromNurseryClass.value,
    }
  }

  if (toNurseryClass && toNurseryClass.value) {
    data.toNurseryClass = {
      id: toNurseryClass.value,
    }
  }

  if (toNurseryClass && 0 === toNurseryClass.value) {
    data.toLeaving = true
  }

  if (moveOn) {
    data.moveOn = moveOn.value
  }

  return data
}
