import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class ObservationsApiClient extends ServiceBase {
  create = (params, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/observations?${getQueryStringV2(params)}`,
  })

  get = (id, params) => this.request({
    path: `/v2/observations/${id}?${getQueryStringV2(params)}`,
  })

  remove = (id) => this.request({
    method: 'DELETE',
    path: `/v2/observations/${id}`,
  })

  update = (id, params, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/observations/${id}?${getQueryStringV2(params)}`,
  })

  list = (params) => this.request({
    path: `/v2/observations?${getQueryStringV2(params)}`,
  })

  export = (params) => this.request({
    path: `/v2/reports/children/observation?${getQueryStringV2(params)}`,
  })

  addObservationMedia = (observationId, params, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/batch/observations/${observationId}/media?${getQueryStringV2(params)}`,
  })

  deleteObservationMedia = (observationId, params, body) => this.request({
    body,
    method: 'DELETE',
    path: `/v2/batch/observations/${observationId}/media?${getQueryStringV2(params)}`,
  })

  updateObservationMedia = (observationId, params, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/batch/observations/${observationId}/media?${getQueryStringV2(params)}`,
  })
}

export default ObservationsApiClient
