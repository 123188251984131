import React, { PropsWithChildren } from 'react'

import { LocaleUtils } from 'react-day-picker'

import { populateYears } from 'utils/date'

import { Space } from 'components'

const CURRENT_YEAR = new Date().getFullYear()
const FROM_MONTH = new Date(1901, 0)
const TO_MONTH = new Date(CURRENT_YEAR + 10, 11)

interface YearMonthSelectProps {
  date: Date
  localeUtils: LocaleUtils
  onChangeDate: (date: Date) => void
}

const YearMonthSelect: React.FC<PropsWithChildren<YearMonthSelectProps>> = ({ date, localeUtils, onChangeDate }) => {
  const months = localeUtils.getMonths()
  const years = populateYears(FROM_MONTH, TO_MONTH)

  const handleChange = (e) => {
    const { month, year } = e.target.form
    onChangeDate(new Date(year.value, month.value))
  }

  return (
    <form className="DayPicker-Caption">
      <select name="month" value={date.getMonth()} onChange={handleChange}>
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <Space margin="0 0 0 10px" inline />
      <select name="year" value={date.getFullYear()} onChange={handleChange}>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </form>
  )
}

export default YearMonthSelect
