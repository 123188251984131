import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as ethnicitiesSelectors from './selectors'

const withEthnicitiesService = (WrappedComponent) => {
  const mapState = ({ ethnicities }) => ({ ethnicities })

  const mapDispatch = injectActions('ethnicitiesActions', actions)

  const Component = ({ ethnicities, ethnicitiesActions, ...others }) => (
    <WrappedComponent
      ethnicitiesActions={ethnicitiesActions}
      ethnicitiesListState={ethnicities.list}
      ethnicitiesSelectors={ethnicitiesSelectors}
      ethnicitiesSingleState={ethnicities.single}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withEthnicitiesService
