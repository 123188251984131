import _ from 'lodash'

export const getData = (data) => {
  const { nextMontessoriActivities, nextSteps } = data || {}

  return ([
    ...(nextSteps || []),
    ...(nextMontessoriActivities?.length ? [{
      comments: _.join(_.map(nextMontessoriActivities, (item) => item.montessoriActivity?.name), ', '),
      frameworkAreas: [{ name: nextMontessoriActivities[0].frameworkName }],
    }] : []),
  ])
}
