import SignatureList from './SignatureList'
import SignatureListGroup from './SignatureListGroup'
import SignatureListRow from './SignatureListRow'
import SignatureListRowDivider from './SignatureListRowDivider'

SignatureList.Group = SignatureListGroup
SignatureList.Row = SignatureListRow
SignatureList.RowDivider = SignatureListRowDivider

export default SignatureList
