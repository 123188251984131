import _ from 'lodash'

import { getBrandingColor } from 'utils/branding'

import i18n from 'translations'

export const TERM_CUSTOM = 'CUSTOM'

export const ASSESSMENT_PERIOD_CUSTOM = 'CUSTOM'

export const TERM_STARTING_POINT = 'STARTING_POINT'

export const ASSESSMENT_PERIOD_CUSTOM_VALUE = {
  label: _.upperFirst(i18n.t('global:custom')),
  value: ASSESSMENT_PERIOD_CUSTOM,
}

export const TERM_CUSTOM_VALUE = {
  label: _.upperFirst(i18n.t('global:custom')),
  value: TERM_CUSTOM,
}

export const FORMATIVE_REPORTS_STATUSES = {
  APPROVED: {
    color: () => getBrandingColor('tertiary-color'),
    label: i18n.t('module:Approvals:Status:approved'),
    value: 'APPROVED',
  },
  DRAFT: {
    color: () => '#808080',
    label: _.upperFirst(i18n.t('global:draft')),
    value: 'DRAFT',
  },
  PENDING_APPROVAL: {
    color: () => getBrandingColor('primary-color'),
    label: i18n.t('module:Approvals:Status:pendingApproval'),
    value: 'PENDING_APPROVAL',
  },
}

export const COMMENTS_TYPES = {
  COMPLETED: 'COMPLETED',
  WORKING: 'WORKING',
}

export const STATUS_DROPDOWN = _.map(FORMATIVE_REPORTS_STATUSES, ({ label, value }) => ({
  label,
  value,
}))

export const TYPE_OF_REPORT = {
  FORMATIVE_REPORT: 'FORMATIVE_REPORT',
  TRANSITION_REPORT: 'TRANSITION_REPORT',
  TWO_YEAR_PROGRESS_CHECK: '2_YEAR_PROGRESS_CHECK',
}

export const TYPE_LABELS = {
  [TYPE_OF_REPORT.FORMATIVE_REPORT]: i18n.t('module:Learning:FormativeReports:ReportTypes:formativeReport'),
  [TYPE_OF_REPORT.TWO_YEAR_PROGRESS_CHECK]: i18n.t('module:Learning:FormativeReports:ReportTypes:2YearProgressCheck'),
  [TYPE_OF_REPORT.TRANSITION_REPORT]: i18n.t('module:Learning:FormativeReports:ReportTypes:transitionReport'),
}

export const TYPE_OF_REPORT_DROPDOWN = [
  {
    label: i18n.t('module:Learning:FormativeReports:ReportTypes:formativeReport'),
    value: TYPE_OF_REPORT.FORMATIVE_REPORT,
  },
  {
    label: i18n.t('module:Learning:FormativeReports:ReportTypes:2YearCheck'),
    value: TYPE_OF_REPORT.TWO_YEAR_PROGRESS_CHECK,
  },
  {
    label: i18n.t('module:Learning:FormativeReports:ReportTypes:transitionReport'),
    value: TYPE_OF_REPORT.TRANSITION_REPORT,
  },
]

export const TYPE_OF_TRACKING = {
  ASSESSMENT: 'assessmentPeriod',
  TERM: 'term',
}

export const FORMATIVE_REPORT_TRACKING_MODE = {
  AUTOMATED: 'AUTOMATED',
  BOTH: 'BOTH',
  MANUAL: 'MANUAL',
}

export const SELECT_ALL_LABEL_OPTION = {
  label: i18n.t('global:selectAll'),
  value: 'selectAll',
}
