import _ from 'lodash'
import { createSelector } from 'reselect'

import { GLOBAL_SETTINGS_OPTIONS } from 'services/organization/constants'

import { getOrganizationSettings } from './single'

export const getOrganizationInvoiceSettings = createSelector(
  [getOrganizationSettings],
  (orgSettings) => {
    if (!orgSettings || !orgSettings.invoice) {
      return null
    }

    return orgSettings.invoice
  },
)

export const getGlobalType = createSelector(
  [getOrganizationInvoiceSettings],
  (orgInvoiceSettings) => {
    if (!orgInvoiceSettings || !orgInvoiceSettings.globalInheritanceType) {
      return null
    }

    return orgInvoiceSettings.globalInheritanceType
  },
)

export const getGlobalTypeDescription = createSelector(
  [getGlobalType],
  (globalType) => {
    if (!globalType) {
      return null
    }

    const settingOption = _.find(GLOBAL_SETTINGS_OPTIONS, { value: globalType })

    if (!settingOption) {
      return null
    }

    return settingOption.label
  },
)

export const getPayload = createSelector(
  [(values) => values],
  (values) => {
    const { fields, organizationSingleState } = values || {}
    const { organizationSettings } = organizationSingleState.data || {}

    if (!fields) {
      return null
    }

    return {
      organizationSettings: {
        ...organizationSettings,
        invoice: {
          ...organizationSettings.invoice,
          ...fields,
        },
      },
    }
  },
)

export const getAnnualisedPayload = (fields, organizationSettings) => {
  const {
    allowOverwrite,
    annualisedInvoiceSettingsAppliedFromGlobal,
    excludedMonths,
    months,
    weeks,
  } = fields

  return {
    organizationSettings: {
      id: organizationSettings.id,
      invoice: {
        ...organizationSettings.invoice,
        annualisedInvoiceSettings: {
          allowOverwriteOnChildLevel: allowOverwrite,
          excludedMonths: _.times(12 - (+months), (index) => excludedMonths[index].value),
          months: +months,
          weeks: +weeks,
        },
        annualisedInvoiceSettingsAppliedFromGlobal,
      },
    },
  }
}
