import _ from 'lodash'
import moment from 'moment'

import React from 'react'
import { createSelector } from 'reselect'
import { isDirty as isDirtyOriginal } from 'redux-form'

import {
  hasOnlySeniorTeacherOrTeacherAccess as hasOnlySeniorTeacherOrTeacherAccessSelector,
} from 'services/security/user/selectors'

import i18n from 'translations'

import { OTHER_OPTION } from 'services/users/constants'
import { INJURIES, INJURIES_STATUS } from '../constants'
import { getSignatureTotal } from '../signatures/selectors'

const getInjuries = (state) => state.injuries

export const injuryIsFetching = createSelector([getInjuries], (state) => state.single.isFetching)

export const injuryIsSubmittingAsDraft = createSelector([getInjuries], (state) => state.single.isSubmittingAsDraft)

export const injuryIsSubmittingAsApproved = createSelector(
  [getInjuries],
  (state) => state.single.isSubmittingAsApproved,
)

export const injuryIsSubmittingUpdateStatus = createSelector(
  [getInjuries],
  (state) => state.single.isSubmittingUpdateStatus,
)

export const injuryIsRemovedInProgress = createSelector([getInjuries], (state) => state.single.isRemovedInProgress)

export const getInjury = createSelector([getInjuries], (state) => state.single.data)

// NOTE: custom validation to be displayed in the popup not in the form
export const generateValidationErrorMsg = ({ data, error, isHomeAccidents }) => {
  if (!data?.type || !error) {
    return null
  }

  if (!error.extra && error.message) {
    return (
      <div>
        {error.message}
      </div>
    )
  }

  const fieldErrors = error.extra
  const isLeastOneErrorFromBasicForm = !!_.find(fieldErrors, (e, key) => !_.includes(key, 'injured'))
  const isLeastOneErrorFromUserForm = (
    _.find(fieldErrors, (e, key) => _.includes(key, 'injured')) && !fieldErrors.injured
  )

  const renderErrors = () => (
    <div>
      {fieldErrors.date && (
        <div>
          Date
        </div>
      )}
      {fieldErrors.location && (
        <div>
          Location
        </div>
      )}
      {fieldErrors.senior && (
        <div>
          Senior in charge
        </div>
      )}
      {fieldErrors.supervisor && (
        <div>
          Staff supervising
        </div>
      )}
      {fieldErrors.witnesses && (
        <div>
          Witnessed by
        </div>
      )}
      {_.find(fieldErrors, (e, key) => _.includes(key, 'injured') && _.includes(key, 'details')) && (
        <div>
          {`${_.upperFirst(data.type)} details`}
        </div>
      )}
      {_.find(fieldErrors, (e, key) => _.includes(key, 'injured') && _.includes(key, 'description')) && (
        <div>
          Injury description
        </div>
      )}
      {_.find(fieldErrors, (e, key) => _.includes(key, 'injured') && _.includes(key, 'firstAider')) && (
        <div>
          First aid by
        </div>
      )}
    </div>
  )

  return (
    <div>
      {fieldErrors.injured && _.isString(fieldErrors?.injured) && (
        <div>
          {`${_.upperFirst(isHomeAccidents
            ? i18n.t('module:Safeguarding:HomeAccidents:title')
            : data.type)} record should contain at least one child.`}
          {(isLeastOneErrorFromBasicForm || isLeastOneErrorFromUserForm) && (
            <br />
          )}
          <br />
        </div>
      )}
      {(isLeastOneErrorFromBasicForm || isLeastOneErrorFromUserForm) && (
        <div>
          {isHomeAccidents
            ? i18n.t('module:Safeguarding:Preview:requireFollowingField')
            : i18n.t('module:Injury:Preview:requireFollowingField', { type: data.type.toLowerCase() })}
          <br />
          <br />
        </div>
      )}
      {renderErrors()}
    </div>
  )
}
export const getValidationErrorMsg = createSelector([getInjuries], (state) => generateValidationErrorMsg(state.single))

export const getSelectedChildren = createSelector([getInjury], (state) => (
  _.map(state.injured, (injury) => injury.child)
))

const getOption = (value, otherValue) => {
  if (otherValue) {
    return { label: OTHER_OPTION.label, value: OTHER_OPTION.value }
  }

  return value?.id ? { label: value.name, value: value.id } : value
}

export const getInitialValuesForBasicForm = createSelector([getInjury], (injury) => {
  if (!injury) {
    return {
      date: moment(),
    }
  }

  const {
    date,
    injured,
    location,
    otherSeniorInCharge,
    otherStaffSupervising,
    otherWitness,
    senior,
    supervisor,
    witnesses,
  } = injury
  const accidentDetails = injured[0]?.accidentDetails

  const seniorOption = getOption(senior, otherSeniorInCharge)
  const superviserOption = getOption(supervisor, otherStaffSupervising)
  const witnessOption = otherWitness ? getOption(witnesses, otherWitness) : null
  const witnessesOtherOption = _.map(witnesses, (e) => ({ label: e.name, value: e.id }))
  const witnessesSelectWithOtherOption = otherWitness ? [...witnessesOtherOption, witnessOption] : witnessesOtherOption

  return {
    accidentDetails: accidentDetails?.accidentDetails,
    accidentDetailsId: accidentDetails?.id,
    additionalNotes: accidentDetails?.additionalNotes,
    bodyMapCoordinates: accidentDetails?.bodyMapCoordinates,
    childName: injured[0]?.child?.id,
    date: moment(date),
    firstAidProvided: accidentDetails?.firstAidProvided,
    id: injured[0]?.id,
    injuryDescription: accidentDetails?.injuryDescription,
    injuryType: accidentDetails?.injuryType,
    location,
    media: injured[0]?.media,
    otherSeniorInCharge,
    otherStaffSupervising,
    otherWitness,
    senior: seniorOption,
    supervisor: superviserOption,
    witnesses: witnessesSelectWithOtherOption,
  }
})

export const getInitialValuesForChildrenForm = createSelector([getInjury], (injury) => {
  const data = {}

  _.each(injury.injured, ({
    accidentDetails,
    child,
    contactPerson,
    description,
    firstAider,
    id,
    media,
    otherFirstAider,
    riskAssessment,
  }) => {
    const firstAiderOption = getOption(firstAider, otherFirstAider)

    data[child.id] = {
      accidentDetailsId: accidentDetails?.id || null,
      bodyMapCoordinates: accidentDetails?.bodyMapCoordinates || [],
      child: child.id,
      contactPerson: contactPerson && contactPerson.id
        ? { label: contactPerson.fullName, value: contactPerson.id }
        : contactPerson,
      description: description || '',
      firstAider: firstAiderOption,
      id,
      injuryDescription: accidentDetails?.injuryDescription || '',
      injuryType: accidentDetails?.injuryType || null,
      media,
      otherFirstAider,
      riskAssessment,
      treatment: accidentDetails?.treatment || '',
    }
  })

  return data
})

export const getValuesHomeAccidentForm = (params) => {
  const { basicForm, childId, coordinateList, injury, isChangedCoordinate } = params
  const { status } = injury

  const {
    accidentDetails,
    accidentDetailsId,
    additionalNotes,
    bodyMapCoordinates,
    childName,
    date,
    firstAidProvided,
    id,
    injuryDescription,
    injuryType,
    location,
    media,
    otherSeniorInCharge,
    otherStaffSupervising,
    otherWitness,
    senior,
    supervisor,
    witnesses,
  } = basicForm

  const type = INJURIES.HOME_ACCIDENT
  const childid = (parseInt(childId) || parseInt(childName?.value)) || childName

  const values = {
    status,
    type,
  }

  const isAvailableOtherOption = _.filter(witnesses, (item) => OTHER_OPTION.value === item.value)
  const getValue = (value) => ({ id: Number.isInteger(value) ? value : value?.value })
  const witnessesExceptOtherOption = _.filter(witnesses,
    (witness) => OTHER_OPTION.value !== witness.value && getValue(witness),
  )

  values.date = date ? moment(date).toDate() : ''
  values.location = location || ''

  if (senior) {
    values.senior = senior?.value !== OTHER_OPTION.value ? getValue(senior) : null
    values.otherSeniorInCharge = senior?.value === OTHER_OPTION.value ? otherSeniorInCharge : null
  }

  if (witnesses && 0 < witnessesExceptOtherOption.length) {
    values.witnesses = witnesses && 0 < witnessesExceptOtherOption.length
      ? _.map(witnessesExceptOtherOption, (witness) => getValue(witness))
      : []
  }

  if (supervisor) {
    values.otherStaffSupervising = supervisor?.value === OTHER_OPTION.value ? otherStaffSupervising : null
  }

  if (0 < isAvailableOtherOption.length) {
    values.otherWitness = 0 < isAvailableOtherOption.length ? otherWitness : null
  }

  values.injured = childid ? [
    {
      accidentDetails: {
        accidentDetails,
        additionalNotes,
        bodyMapCoordinates: !isChangedCoordinate ? bodyMapCoordinates : coordinateList,
        firstAidProvided: firstAidProvided || '',
        id: accidentDetailsId || null,
        injuryDescription,
        injuryType: injuryType?.value ? injuryType?.value : injuryType,
      },
      child: { id: childid },
      id,
      media: media?.length ? _.map(media, ({
        children,
        id: mediaId,
        itemFromBe,
        mimeType,
        name,
        path,
        size,
        url,
      }) => {
        const item = {
          children,
          mimeType,
          name,
          path,
          size,
          url,
        }

        if (false !== itemFromBe) {
          item.id = mediaId
        }

        if (false === itemFromBe) {
          item.isUploaded = true
        }

        return item
      }) : [],
    },
  ] : []

  return values
}

export const getValuesForm = (params) => {
  const { basicForm, childrenForms, injury } = params
  const { status } = injury
  const {
    date,
    location,
    otherSeniorInCharge,
    otherStaffSupervising,
    otherWitness,
    senior,
    supervisor,
    witnesses,
  } = basicForm
  const type = 1 >= childrenForms?.length ? INJURIES.ACCIDENT : INJURIES.INCIDENT

  const values = {
    status,
    type,
  }

  const isAvailableOtherOption = _.filter(witnesses, (item) => OTHER_OPTION.value === item.value)
  const getValue = (value) => ({ id: Number.isInteger(value) ? value : value?.value })
  const witnessesExceptOtherOption = _.filter(witnesses,
    (witness) => OTHER_OPTION.value !== witness.value && getValue(witness),
  )

  values.date = date ? moment(date).toDate() : ''

  if (supervisor) {
    values.supervisor = supervisor?.value !== OTHER_OPTION.value ? getValue(supervisor) : null
    values.otherStaffSupervising = supervisor?.value === OTHER_OPTION.value ? otherStaffSupervising : null
  }

  values.location = location || ''

  if (senior) {
    values.senior = senior?.value !== OTHER_OPTION.value ? getValue(senior) : null
    values.otherSeniorInCharge = senior?.value === OTHER_OPTION.value ? otherSeniorInCharge : null
  }

  if (witnesses && 0 < witnessesExceptOtherOption.length) {
    values.witnesses = witnesses && 0 < witnessesExceptOtherOption.length
      ? _.map(witnessesExceptOtherOption, (witness) => getValue(witness))
      : []
  }

  values.otherWitness = 0 < isAvailableOtherOption.length ? otherWitness : null

  values.injured = _.map(childrenForms, ({
    accidentDetailsId,
    bodyMapCoordinates,
    child,
    contactPerson,
    description,
    firstAider,
    id,
    injuryDescription,
    injuryType,
    media,
    otherFirstAider,
    riskAssessment,
    treatment,
  }) => {
    const coordinationSelection = bodyMapCoordinates?.length ? bodyMapCoordinates : []

    const data = {}
    data.child = !id ? getValue(child) : undefined

    if (id) {
      data.id = id
    }

    data.description = description || ''
    data.media = media?.length ? _.map(media, ({
      children,
      id: mediaId,
      itemFromBe,
      mimeType,
      name,
      path,
      size,
      url,
    }) => {
      const item = {
        children,
        mimeType,
        name,
        path,
        size,
        url,
      }

      if (false !== itemFromBe) {
        item.id = mediaId
      }

      if (false === itemFromBe) {
        item.isUploaded = true
      }

      return item
    }) : []
    const firstAiderValue = firstAider?.value ? getValue(firstAider) : null
    data.firstAider = firstAider?.value !== OTHER_OPTION.value ? firstAiderValue : null
    data.riskAssessment = riskAssessment || ''
    data.otherFirstAider = firstAider?.value === OTHER_OPTION.value ? otherFirstAider : undefined
    data.contactPerson = contactPerson ? getValue(contactPerson) : ''

    data.accidentDetails = {
      bodyMapCoordinates: bodyMapCoordinates?.coordinateList?.length
        ? bodyMapCoordinates?.coordinateList
        : coordinationSelection || [],
      id: accidentDetailsId || null,
      injuryDescription: injuryDescription || '',
      injuryType: injuryType?.value ? injuryType?.value : injuryType || null,
      treatment: treatment || '',
    }

    return data
  })

  return values
}

export const getPreview = createSelector(
  [getInjury],
  (injury) => {
    if (!injury) {
      return null
    }

    const { injurySignatureStatistics, type } = injury

    const signatureTotal = getSignatureTotal(injurySignatureStatistics, type)

    return {
      ...injury,
      hasSignatures: !!signatureTotal.signed,
      signatureTotal,
    }
  },
)

export const isGrantedSaveAsDraft = createSelector(
  [getInjury],
  (injury) => {
    if (!injury) {
      return true
    }

    const { status } = injury

    return status !== INJURIES_STATUS.PENDING_APPROVAL.value
      && status !== INJURIES_STATUS.APPROVED_AND_SIGNED.value
      && status !== INJURIES_STATUS.SIGNATURES_NEEDED.value
  },
)

export const isGrantedSaveAndRequestForApproval = createSelector(
  [getInjury, hasOnlySeniorTeacherOrTeacherAccessSelector],
  (injury, hasOnlySeniorTeacherOrTeacherAccess) => {
    if (!injury) {
      return false
    }

    const { status } = injury

    return hasOnlySeniorTeacherOrTeacherAccess
      && (status !== INJURIES_STATUS.APPROVED_AND_SIGNED.value
        || status !== INJURIES_STATUS.SIGNATURES_NEEDED.value
      )
  },
)

export const isGrantedRequestForApproval = createSelector(
  [getInjury, hasOnlySeniorTeacherOrTeacherAccessSelector],
  (injury, hasOnlySeniorTeacherOrTeacherAccess) => {
    if (!injury) {
      return false
    }

    const { status } = injury

    return hasOnlySeniorTeacherOrTeacherAccess && status !== INJURIES_STATUS.DRAFT.value
  },
)

export const isGrantedEdit = createSelector(
  [getInjury, isGrantedSaveAsDraft, hasOnlySeniorTeacherOrTeacherAccessSelector],
  (injury, isGrantedSaveAsDraftSelector, hasOnlySeniorTeacherOrTeacherAccess) => {
    if (!injury) {
      return true
    }

    const { status } = injury

    return isGrantedSaveAsDraftSelector
      || !hasOnlySeniorTeacherOrTeacherAccess
      || (
        hasOnlySeniorTeacherOrTeacherAccess && (
          status === INJURIES_STATUS.DRAFT.value
          || status === INJURIES_STATUS.PENDING_APPROVAL.value
        )
      )
  },
)

export const isDirty = (injuryBasicFormName, injuryChildFormName) => createSelector(
  [getInjury, (state) => state],
  (injury, state) => {
    if (!injury) {
      return false
    }

    const { injured } = injury

    let isAnyFormDirty = isDirtyOriginal(injuryBasicFormName)(state)

    _.forEach(injured, ({ child }) => {
      isAnyFormDirty = isAnyFormDirty || isDirtyOriginal(`${injuryChildFormName}_${child.id}`)(state)
    })

    return isAnyFormDirty
  },
)
