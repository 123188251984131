import { generateBasicActions } from 'utils/service'

import {
  CLEAR_NURSERIES_MONTHLY_FTE_CHANGE_REPORT,
  NURSERIES_MONTHLY_FTE_CHANGE_REPORT,
  NURSERIES_MONTHLY_FTE_CHANGE_REPORT_FAILED,
  NURSERIES_MONTHLY_FTE_CHANGE_REPORT_SUCCESS,
} from './constants'

export const getNurseries = (options = {}) => ({ monthlyFteChangeReportApiClient }) => generateBasicActions.list({
  constants: {
    failed: NURSERIES_MONTHLY_FTE_CHANGE_REPORT_FAILED,
    init: NURSERIES_MONTHLY_FTE_CHANGE_REPORT,
    success: NURSERIES_MONTHLY_FTE_CHANGE_REPORT_SUCCESS,
  },
  options,
  service: monthlyFteChangeReportApiClient,
  serviceMethod: 'getNurseries',
})

export const clearNurseries = () => ({
  type: CLEAR_NURSERIES_MONTHLY_FTE_CHANGE_REPORT,
})
