import React from 'react'

import { ModalBox } from 'components'

import i18n from 'translations'

import AddSessionToRegularBookingsModalForm, {
  AddSessionToRegularBookingsModalFormProps,
  AddSessionToRegularBookingsModalFormValues,
} from './components/AddSessionToRegularBookingsModalForm'

interface AddSessionToRegularBookingsModalViewProps extends AddSessionToRegularBookingsModalFormProps {
  initialValues?: AddSessionToRegularBookingsModalFormValues
}

const AddSessionToRegularBookingsModalView: React.FC<AddSessionToRegularBookingsModalViewProps> = ({
  onCloseClick,
  ...rest
}) => (
  <ModalBox
    maxWidth={600}
    title={i18n.t('modals:AddSessionToRegularBookings:title')}
    onCloseClick={onCloseClick}
  >
    <AddSessionToRegularBookingsModalForm
      onCloseClick={onCloseClick}
      {...rest}
    />
  </ModalBox>
)

export default AddSessionToRegularBookingsModalView
