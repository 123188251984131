import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { SUPPORTED_FILE_TYPES } from 'constants/mimetypes'

import { isRequired } from 'utils/fieldValidation'

import i18n from 'translations'

import { Button, CircleIcon, Form, MediaPicker, StickyFooter, Tooltip } from 'components'

import AvatarWithLabel from '../../components/AvatarWithLabel'
import GroupDropdown from '../../components/GroupDropdown'
import IndividualDropdown from '../../components/IndividualDropdown'

import {
  StyledCustomStickyFooterDesign,
  StyledTooltipContainer,
  StyledTooltipContentContainer,
} from './NewslettersAddFormStyled'

const UPLOAD_NEWSLETTER_DIRECTORY = '/images/newsletter/'
export const NEWSLETTERS_FORM = 'NewsForm'
const TINY_MCE_PLUGINS = [
  'table',
  'image',
  'lists',
  'autoresize',
  'link',
]
const TINY_MCE_TOOLBAR = [
  'undo redo',
  'styles',
  'bold italic backcolor',
  'fontfamily fontsize',
  'alignleft aligncenter alignright alignjustify',
  'bullist numlist outdent indent',
  'removeformat',
  'table',
  'image image_editing',
  'link',
].join(' | ')

const NewslettersAddForm = ({
  currentUser,
  handleSubmit,
  isSubmitting,
  news,
  nursery,
  onCancelClick,
  onChangeMedia,
  onDraftClick,
  onPublishClick,
  onSubmit,
}) => {
  const { author = currentUser } = news || {}
  const { logo, name: nurseryName } = nursery || {}
  const { name, photo } = author || {}

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Row label={i18n.t('module:Newsletters:Add:fromLabel')}>
        <Form.Row.FlexItem>
          <AvatarWithLabel initials={nurseryName && nurseryName.split(' ')} name={nurseryName} src={logo} />
        </Form.Row.FlexItem>
        <Form.Row.Item>
          <AvatarWithLabel
            initials={name && name.split(' ')}
            label={`${i18n.t('module:Newsletters:Add:authorLabel')}: `}
            name={name}
            src={photo}
          />
        </Form.Row.Item>
      </Form.Row>
      <Form.Row label={i18n.t('module:Newsletters:Add:toGroupLabel')}>
        <Form.Row.FlexItem>
          <Field
            component={GroupDropdown}
            name="groups"
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:Newsletters:Add:toIndividualsLabel')}>
        <Form.Row.FlexItem>
          <Field
            component={IndividualDropdown}
            name="individuals"
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:Newsletters:Add:postTitleLabel')} required>
        <Form.Row.FlexItem>
          <Field
            component={Form.TextField}
            name="title"
            placeholder={i18n.t('module:Newsletters:Add:postTitlePlaceholder')}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:Newsletters:Add:contentLabel')}>
        <Form.Row.FlexItem>
          <Field
            component={Form.TextEditorField}
            name="content"
            placeholder={i18n.t('module:Newsletters:Add:contentPlaceholder')}
            plugins={TINY_MCE_PLUGINS}
            toolbar={TINY_MCE_TOOLBAR}
            uploadDirectory={UPLOAD_NEWSLETTER_DIRECTORY}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:Newsletters:Add:attachmentsLabel')}>
        <Form.Row.FlexItem mobileFull>
          <Field
            accept={[SUPPORTED_FILE_TYPES.IMAGES, SUPPORTED_FILE_TYPES.PDF, SUPPORTED_FILE_TYPES.DOCUMENTS]}
            component={Form.MediaPicker}
            fieldErrorEnabled={false}
            label={(
              <StyledTooltipContainer>
                <MediaPicker.Label
                  label={i18n.t('module:Newsletters:Add:uploadImageButtonLabel')}
                />
                <Tooltip
                  placement="bottom"
                  title={(
                    <StyledTooltipContentContainer>
                      {i18n.t('module:Newsletters:Add:tooltipFirstLine')}
                      {' '}
                      {i18n.t('module:Newsletters:Add:tooltipSecondLine')}
                    </StyledTooltipContentContainer>
                  )}
                >
                  <CircleIcon
                    cursor="pointer"
                    icon="info"
                    iconSize={16}
                    size={24}
                  />
                </Tooltip>
              </StyledTooltipContainer>
            )}
            name="newsletterMedia"
            disableMediaTag
            disableTagging
            sync
            onChange={onChangeMedia}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <StyledCustomStickyFooterDesign>
        <StickyFooter>
          <Button
            hierarchy="tertiary"
            label={i18n.t('global:Cancel')}
            negativeMargins
            onClick={onCancelClick}
          />
          <StickyFooter.Flex />
          <Button
            disabled={isSubmitting}
            hierarchy="secondary"
            isLoading={isSubmitting}
            label={i18n.t('module:Newsletters:Add:saveAsDraftButtonLabel')}
            negativeMargins
            onClick={onDraftClick}
          />
          <Button
            disabled={isSubmitting}
            isLoading={isSubmitting}
            label={i18n.t('module:Newsletters:Add:publishButtonLabel')}
            negativeMargins
            onClick={onPublishClick}
          />
        </StickyFooter>
      </StyledCustomStickyFooterDesign>
    </Form>
  )
}

export default reduxForm({ form: NEWSLETTERS_FORM })(NewslettersAddForm)
