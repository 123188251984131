import { generateBasicActions } from 'utils/service'

import {
  CLEAR_COHORT_TRACKING_LISTS,
  LIST_COHORT_TRACKING,
  LIST_COHORT_TRACKING_FAILED,
  LIST_COHORT_TRACKING_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ cohortTrackingApiClient }) => generateBasicActions.list({
  constants: {
    failed: LIST_COHORT_TRACKING_FAILED,
    init: LIST_COHORT_TRACKING,
    success: LIST_COHORT_TRACKING_SUCCESS,
  },
  options,
  service: cohortTrackingApiClient,
  serviceMethod: 'list',
})

export const clear = () => ({
  type: CLEAR_COHORT_TRACKING_LISTS,
})
