import _ from 'lodash'

import React, { PropsWithChildren } from 'react'

import { AVATAR_SIZE_VARIANTS } from 'components/Avatar/Avatar'
import ImagesSize from 'constants/imagesSize'

import { typeByObject } from 'utils/typescript'

import { Avatar } from 'components'

import i18n from 'translations'

import {
  StyledAgeMonths,
  StyledAvatar,
  StyledAvatarContainer,
  StyledAvatarWithText,
  StyledContent,
  StyledContentTitle,
  StyledGroupContainer,
} from './GroupAvatarsStyled'

interface GroupAvatarsProps {
  avatarSize?: typeByObject<typeof AVATAR_SIZE_VARIANTS>
  backgroundColor?: string
  borderColor?: string
  borderWidth?: number
  /**
   * This param is optional and if we have in `persons` param all
   * data then param countOverride in unnecessary. Is used only in scenario if persons are paginated
   * and we don't have all result.
   */
  countOverride?: number
  disableRestAmount?: boolean
  maxLengthOfAvatars?: number
  onRecalculateAgeMonth?: (person: any) => any
  persons: any[]
  renderUrl?: (person: any) => string
  withoutText?: boolean
}

const GroupAvatars: React.FC<PropsWithChildren<GroupAvatarsProps>> = ({
  avatarSize = AVATAR_SIZE_VARIANTS.STANDARD,
  backgroundColor,
  borderColor,
  borderWidth = 4,
  countOverride,
  disableRestAmount,
  maxLengthOfAvatars = 3,
  persons,
  renderUrl,
  withoutText,
  onRecalculateAgeMonth,
}) => {
  const avatarSizeDetails = _.get(_.find(ImagesSize.AVATARS, (value, key) => (
    key === _.upperCase(avatarSize)
  )), 'width')

  const finalCount = countOverride || persons?.length
  const moreThanMaxLength = finalCount > maxLengthOfAvatars
  const numberOfShownAvatars = moreThanMaxLength ? maxLengthOfAvatars - 1 : maxLengthOfAvatars
  const numberOfNotShownAvatars = finalCount - maxLengthOfAvatars + 1
  const previewArray = persons || []

  const firstPerson = previewArray[0]

  const renderDescription = () => {
    if (!firstPerson) {
      return '-'
    }

    if (1 < finalCount) {
      return (
        <StyledContentTitle>
          {`${i18n.t('components:GroupAvatars:Group')} (${finalCount})`}
        </StyledContentTitle>
      )
    }

    return (
      <StyledContentTitle>
        {`${firstPerson.firstName} ${firstPerson.surname}`}
        {firstPerson.ageMonths && (
          <StyledAgeMonths>
            {onRecalculateAgeMonth ? onRecalculateAgeMonth(firstPerson) : firstPerson.ageMonths}
            {' '}
            {i18n.t('components:GroupAvatars:Months')}
          </StyledAgeMonths>
        )}
      </StyledContentTitle>
    )
  }

  const renderAvatars = () => {
    if (!finalCount) {
      return (
        <StyledAvatarContainer $avatarSize={avatarSizeDetails}>
          <Avatar
            avatarSize={avatarSize}
            borderColor={borderColor}
            borderWidth={borderWidth}
            src="assets/images/placeholders/avatar.jpg"
          />
        </StyledAvatarContainer>
      )
    }

    const renderAvatar = (person, index) => {
      const { firstName, id, name, photo, surname } = person || {}

      return (
        <StyledAvatar
          $avatarSize={avatarSizeDetails}
          $index={index}
          key={id}
        >
          <Avatar
            avatarSize={avatarSize}
            borderColor={borderColor}
            borderWidth={borderWidth}
            initials={firstName && surname ? [firstName, surname] : name?.split(' ')}
            src={photo}
            to={renderUrl?.(person)}
          />
        </StyledAvatar>
      )
    }

    return (
      <StyledAvatarContainer
        $avatarSize={avatarSizeDetails}
        $length={finalCount}
        $maxLengthOfAvatars={maxLengthOfAvatars}
      >
        {previewArray.slice(0, disableRestAmount ? maxLengthOfAvatars : numberOfShownAvatars).map(renderAvatar)}
        {
          !disableRestAmount && moreThanMaxLength && (
            <StyledAvatarWithText
              $avatarSize={avatarSizeDetails}
              $backgroundColor={backgroundColor}
              $borderColor={borderColor}
              $index={maxLengthOfAvatars - 1}
            >
              {`+${numberOfNotShownAvatars}`}
            </StyledAvatarWithText>
          )
        }
        {
          !finalCount && (
            <Avatar
              avatarSize={avatarSize}
              borderColor={borderColor}
              borderWidth={borderWidth}
              src="assets/images/placeholders/avatar.jpg"
            />
          )
        }
      </StyledAvatarContainer>
    )
  }

  return (
    <StyledGroupContainer>
      {renderAvatars()}
      {!withoutText && (
        <StyledContent>
          {renderDescription()}
        </StyledContent>
      )}
    </StyledGroupContainer>
  )
}

export default GroupAvatars
