import { createSelector } from 'reselect'

const getFinanceReports = (state) => state.financeBadDebtReport

export const getFinanceBadDebtReportStatistics = createSelector(
  [getFinanceReports],
  (state) => state.statistics,
)

export const getFinanceBadDebtReportStatisticsData = createSelector(
  [getFinanceBadDebtReportStatistics],
  (state) => state.data,
)

