import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { EVENTS, logEvent } from 'analytics'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withInvoicesService } from 'services/legacy/invoices'
import { withModalService } from 'services/utils/modal'
import { withNurseryPaymentTypesService } from 'services/legacy/nurseryPaymentTypes'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withSortingUtils } from 'services/utils/sorting'
import { withRouter } from 'services/router'

import { getCriteria, getInvoices, getStatusOptions } from './ChildFinanceDetailsInvoicesHelpers'
import ChildFinanceDetailsInvoicesView from './ChildFinanceDetailsInvoicesView'

const groups = {
  read: [
    'invoice.payments',
    'invoice.child',
    'child',
    'child.childBankDetail',
    'childBankDetail',
  ],
}
const CHILD_FINANCE_DETAILS_GROUPS = {
  read: [
    'child.finance',
  ],
}

class ChildFinanceDetailsInvoicesContainer extends Component {
  constructor(props) {
    super(props)

    const { endDate, startDate, status, type } = props.location.query

    this.state = {
      defaultSort: {
        sortField: 'issueDate',
        sortOrder: 'DESC',
      },
      filters: {
        endDate,
        startDate,
        status,
        type,
      },
      statusOptions: getStatusOptions(),
    }
  }

  componentDidMount() {
    const { nurseryPaymentTypesActions, sortingUtils } = this.props
    const { onSortChange } = sortingUtils

    onSortChange(this.fetch())
    nurseryPaymentTypesActions.list()

    logEvent(EVENTS.INVOICE_LIST_PAGE_VIEWED, { context: 'child profile' })
  }

  componentWillUnmount() {
    const { childActions, invoicesActions, nurseryPaymentTypesActions } = this.props

    nurseryPaymentTypesActions.clearList()
    invoicesActions.clear()
    childActions.clearFinanceDetails()
  }

  getInvoices = () => {
    const { invoiceList, params } = this.props
    const { childId } = params

    return getInvoices(invoiceList, childId)
  }

  fetch = () => {
    const {
      childActions,
      invoicesActions,
      paginationUtils,
      params,
      setLocationQuery,
      sortingUtils,
    } = this.props
    const { defaultSort, filters } = this.state

    const { childId } = params
    const { page } = paginationUtils
    const { sortField: defaultSortField } = defaultSort
    const { sortField, sortOrder } = sortingUtils

    const criteria = getCriteria(filters, childId)

    setLocationQuery(filters)

    const listParams = {
      criteria,
      groups,
      order: { sortField: sortField || defaultSortField, sortOrder },
      page,
    }

    invoicesActions.list(listParams)

    childActions.getFinanceDetails({
      params: [childId, {
        groups: CHILD_FINANCE_DETAILS_GROUPS,
      }],
    })
  }

  handleSortChange = () => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(1)
  }

  handleDateChange = (dateRange) => {
    const { paginationUtils, sortingUtils } = this.props

    const { onPageChange } = paginationUtils
    const { onSortChange } = sortingUtils

    const [startDate, endDate] = dateRange

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
          startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
        },
      }),
      () => {
        if ((startDate && endDate) || (!startDate && !endDate)) {
          onSortChange(onPageChange(this.fetch)(1))
        }
      },
    )

    logEvent(EVENTS.INVOICE_LIST_FILTER_USED, { context: 'child profile', type: 'date' })
  }

  handleStatusChange = (status) => {
    const { paginationUtils, sortingUtils } = this.props

    const { onPageChange } = paginationUtils
    const { onSortChange } = sortingUtils

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          status: status ? status.value : null,
        },
      }),
      () => onSortChange(onPageChange(this.fetch)(1)),
    )

    logEvent(EVENTS.INVOICE_LIST_FILTER_USED, { context: 'child profile', type: 'status' })
  }

  handleTypeChange = (type) => {
    const { paginationUtils, sortingUtils } = this.props
    const { onPageChange } = paginationUtils
    const { onSortChange } = sortingUtils

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          type: type ? type.value : null,
        },
      }),
      () => onSortChange(onPageChange(this.fetch)(1)),
    )

    logEvent(EVENTS.INVOICE_LIST_FILTER_USED, { context: 'child', type: 'invoice type' })
  }

  render() {
    const {
      cannotBePaidOverTheParentApp,
      childFinanceDetails,
      errorMessages,
      invoiceListMeta,
      invoicesConstants,
      invoicesListState,
      isFetching,
      paginationUtils,
      params,
      sortingUtils,
    } = this.props
    const { filters, statusOptions } = this.state
    const { childId } = params
    const { INVOICE_TYPES_DROPDOWN } = invoicesConstants
    const { endDate, startDate, status, type } = filters

    const invoices = this.getInvoices()
    const isLoading = isFetching
    const isEmpty = invoicesListState.isEmpty && !startDate && !endDate && !status

    const { getPageCount, onPageChange, page, perPage } = paginationUtils
    const { onSortChange, sortField, sortOrder } = sortingUtils
    const pageCount = getPageCount(invoiceListMeta ? invoiceListMeta.totalResults : null)
    const dateRange = [startDate, endDate]

    return (
      <ChildFinanceDetailsInvoicesView
        cannotBePaidOverTheParentApp={cannotBePaidOverTheParentApp}
        childFinanceDetails={childFinanceDetails}
        childId={childId}
        dateRange={dateRange}
        errorMessages={errorMessages}
        invoices={invoices}
        isEmpty={isEmpty}
        isLoading={isLoading}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        sortField={sortField}
        sortOrder={sortOrder}
        status={status}
        statusOptions={statusOptions}
        totalResults={invoiceListMeta && invoiceListMeta.totalResults}
        type={type}
        typeOptions={INVOICE_TYPES_DROPDOWN}
        onDateChange={this.handleDateChange}
        onPageChange={onPageChange(this.fetch)}
        onSortChange={onSortChange(this.handleSortChange)}
        onStatusChange={this.handleStatusChange}
        onTypeChange={this.handleTypeChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childSelectors,
  invoicesListState,
  invoicesSelectors,
  nurseryPaymentTypesListState,
}) => ({
  cannotBePaidOverTheParentApp: childSelectors.getCannotBePaidOverTheParentApp(state),
  childFinanceDetails: childSelectors.getFinanceDetailsSelector(state),
  errorMessages: appSelectors.getErrorMessages(invoicesListState),
  invoiceList: invoicesSelectors.getInvoiceListSelector(state),
  invoiceListMeta: invoicesSelectors.getInvoiceListMetaSelector(state),
  isFetching: appSelectors.getIsFetching(invoicesListState, nurseryPaymentTypesListState),
})

const enhance = compose(
  withRouter,
  withChildService,
  withAppService,
  withInvoicesService,
  withModalService,
  withNurseryPaymentTypesService,
  withPaginationUtils,
  withRouterUtils,
  withSortingUtils,
  connect(mapState),
)

export default enhance(ChildFinanceDetailsInvoicesContainer)
