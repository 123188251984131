import React, { useMemo } from 'react'
import { Field } from 'redux-form'

import { STRIPE_STATUSES } from 'services/nurseryIntegrations/constants'
import { FEATURE_FLAGS } from 'constants/security'

import { Form, Tooltip } from 'components'

import i18n from 'translations'

const FeatureFlagItemField = ({
  formValues,
  label,
  nursery,
  nurseryIntegrationsSelectors,
  onFeatureFlagChange,
  value,
}) => {
  // eslint-disable-next-line prefer-const
  let { isOrganizationNursery, mobilePaymentBACSFlagIsDisabled, mobilePaymentFlagIsDisabled, tooltip } = useMemo(() => {
    let mobilePaymentFlagIsDisabledTmp = false
    let mobilePaymentBACSFlagIsDisabledTmp = false
    let isOrganizationNurseryTmp = false
    let tooltipTmp = ''

    const bacs = nurseryIntegrationsSelectors.getBacsCapability(nursery)

    if (bacs?.enabled && value === FEATURE_FLAGS.MOBILE_PAYMENTS_BACS_CAPABILITY) {
      mobilePaymentBACSFlagIsDisabledTmp = true
      tooltipTmp = i18n.t('module:Nurseries:Edit:mobilePaymentBACSisTurnedOn')
    }

    if (
      value === FEATURE_FLAGS.MOBILE_PAYMENTS
      && nursery?.stripeNurseryIntegration
      && nursery.stripeNurseryIntegration?.status !== STRIPE_STATUSES.NOT_ONBOARDED
    ) {
      mobilePaymentFlagIsDisabledTmp = true
      tooltipTmp = i18n.t('module:Nurseries:Edit:stripeIsConnected')
    }

    if (
      value === FEATURE_FLAGS.MOBILE_PAYMENTS
      && formValues?.featureFlags[FEATURE_FLAGS.MOBILE_PAYMENTS]
      && formValues?.featureFlags[FEATURE_FLAGS.MOBILE_PAYMENTS_BACS_CAPABILITY]
    ) {
      mobilePaymentFlagIsDisabledTmp = true
      tooltipTmp = i18n.t('module:Nurseries:Edit:mobilePaymentBACSFlagIsActivated')
    }

    if (
      value === FEATURE_FLAGS.MOBILE_PAYMENTS_BACS_CAPABILITY
      && !formValues?.featureFlags[FEATURE_FLAGS.MOBILE_PAYMENTS]
    ) {
      mobilePaymentBACSFlagIsDisabledTmp = true
      tooltipTmp = i18n.t('module:Nurseries:Edit:mobilePaymentFlagIsDeactivated')
    }

    if (value === FEATURE_FLAGS.FINANCE_V3 && nursery?.organization) {
      isOrganizationNurseryTmp = true
      tooltipTmp = i18n.t('module:Nurseries:Edit:financeV3FlagIsDeactivated')
    }

    return {
      isOrganizationNursery: isOrganizationNurseryTmp,
      mobilePaymentBACSFlagIsDisabled: mobilePaymentBACSFlagIsDisabledTmp,
      mobilePaymentFlagIsDisabled: mobilePaymentFlagIsDisabledTmp,
      tooltip: tooltipTmp,
    }
  }, [formValues, value, nursery])

  const financeV3Disabled = value === FEATURE_FLAGS.FINANCE_V3 && !formValues?.featureFlags[FEATURE_FLAGS.FINANCE_MVP]
  const financeDisabled = value === FEATURE_FLAGS.FINANCE_MVP && formValues?.featureFlags[FEATURE_FLAGS.FINANCE_V3]

  if (financeDisabled) {
    tooltip = i18n.t('module:Nurseries:Edit:financeV3IsEnabled')
  }

  return (
    <Form.Row label={label}>
      <Form.Row.FlexItem flex="0 0 150px 50px" mobileFull>
        <Tooltip title={tooltip}>
          <Field
            component={Form.Switch}
            disabled={
              mobilePaymentBACSFlagIsDisabled
              || mobilePaymentFlagIsDisabled
              || financeDisabled
              || financeV3Disabled
              || isOrganizationNursery
            }
            name={value}
            onChange={() => onFeatureFlagChange(label, value)}
          />
        </Tooltip>
      </Form.Row.FlexItem>
    </Form.Row>
  )
}

export default FeatureFlagItemField
