import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { AUTH_TYPE_DIRECT, AUTH_TYPE_PIN } from 'services/device/constants'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withDeviceService } from 'services/device'
import { withDeviceTokensService } from 'services/legacy/deviceTokens'
import { withShellService } from 'services/shell'
import { withRouter } from 'services/router'

import AddDeviceView from './AddDeviceView'

const METHOD_PARAM = 'm'

class AddDeviceContainer extends Component {
  componentDidMount() {
    const { shellActions } = this.props

    shellActions.setSettings({
      suppressDeviceWarning: true,
    })
  }

  componentWillUnmount() {
    const { deviceTokensActions } = this.props

    deviceTokensActions.clearSingle()
  }

  handleUpdateDeviceSuccess = (response) => {
    const { deviceActions, navigate } = this.props
    const { data } = response

    deviceActions.changeDeviceIdentity(data.token)
    navigate(generateRoute('MANAGEMENT.SECURITY.DEVICES'))
  }

  handleDeviceDirectCreateSuccess = (response) => {
    const { deviceActions, deviceTokensActions } = this.props
    const { data } = response

    deviceActions.changeDeviceRealIdentity(data.token)
    deviceTokensActions.installDeviceToken({
      body: data,
      onSuccess: this.handleUpdateDeviceSuccess,
      params: [data.id],
    })
  }

  handleDevicePinCreateSuccess = (response) => {
    const { navigate } = this.props
    const { data } = response

    navigate(generateRoute('MANAGEMENT.SECURITY.DEVICES.DETAILS', { deviceId: data.id }))
  }

  handleSubmit = (values) => {
    const { deviceTokensActions, deviceTokensSelectors, location } = this.props

    const body = deviceTokensSelectors.getDeviceTokensValuesForm({
      ...values,
      method: location.query[METHOD_PARAM] ? AUTH_TYPE_DIRECT : AUTH_TYPE_PIN,
    })

    deviceTokensActions.createDeviceTokens({
      body,
      onSuccess: !location.query[METHOD_PARAM]
        ? this.handleDevicePinCreateSuccess
        : this.handleDeviceDirectCreateSuccess,
    })
  }

  render() {
    const { isSubmitting, location } = this.props

    return (
      <AddDeviceView
        isSubmitting={isSubmitting}
        method={location.query[METHOD_PARAM] || AUTH_TYPE_PIN}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  deviceTokensSingleState,
}) => ({
  errorMessages: appSelectors.getErrorMessages(deviceTokensSingleState),
  isSubmitting: appSelectors.getIsSubmitting(deviceTokensSingleState),
})

const enhance = compose(
  withAppService,
  withDeviceService,
  withDeviceTokensService,
  withShellService,
  withRouter,
  connect(mapState),
)

export default enhance(AddDeviceContainer)
