import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { AMOUNT_DRUNK_MARKS } from 'services/legacy/dailyDiaryBottles/constants'

import { isRequired } from 'utils/fieldValidation'

import { Form } from 'components'

import i18n from 'translations'

export const BOTTLE_TAB_FORM = 'BottleTabForm'

const BottleTabForm = ({
  handleSubmit,
  isSubmitting,
  onCancel,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row label={i18n.t('module:DailyDiary:Bottles:amountDrunk')} verticalLabel>
      <Form.Row.Item>
        <Field
          component={Form.Slider}
          disabled={isSubmitting}
          marks={AMOUNT_DRUNK_MARKS}
          max={10}
          min={0}
          name="amount"
          validate={isRequired}
          valueFormat={(value) => `${value}${i18n.t('global:oz')}`}
        />
      </Form.Row.Item>
    </Form.Row>
    <Form.Row label={i18n.t('global:Time')} verticalLabel>
      <Form.Row.Item>
        <Field
          component={Form.TimePicker}
          disabled={isSubmitting}
          name="recordedAt"
          validate={isRequired}
        />
      </Form.Row.Item>
    </Form.Row>
    <Form.Row
      label={i18n.t('module:DailyDiary:global:additionalNotes')}
      verticalLabel
    >
      <Form.Row.FlexItem flex="0 0 420px" mobileFull>
        <Field
          component={Form.TextAreaField}
          disabled={isSubmitting}
          name="comments"
          placeholder={i18n.t('module:DailyDiary:global:enterAdditionalNotesHere')}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.FooterActions
      cancelLabel={i18n.t('global:Back')}
      isSubmitting={isSubmitting}
      onCancelClick={onCancel}
    />
  </Form>
)

export default reduxForm({ form: BOTTLE_TAB_FORM })(BottleTabForm)
