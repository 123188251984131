import _ from 'lodash'

import React from 'react'

import { Table } from 'components'

import {
  StyledContainer,
  StyledItemContainer,
  StyledTableContainer,
  StyledTitleContainer,
} from './PreviewOptionsStyled'

const PreviewOptions = ({ previewOptions }) => {
  const renderRow = ({ description, isTotal, type, value }) => {
    if (isTotal) {
      return (
        <Table.Tr style={{ height: 70 }}>
          <Table.SummaryTd colSpan={4}>
            {description}
            {' '}
            {value}
          </Table.SummaryTd>
        </Table.Tr>
      )
    }

    return (
      <Table.Tr style={{ height: 70 }}>
        <Table.Td style={{ width: 75 }}>
          {type}
        </Table.Td>
        <Table.Td align="left" style={{ width: 250 }}>
          {description}
        </Table.Td>
        <Table.Td style={{ width: 75 }}>
          {value}
        </Table.Td>
      </Table.Tr>
    )
  }

  return (
    <StyledContainer>
      {_.map(previewOptions, ({ items, title }, index) => (
        <StyledItemContainer>
          <StyledTitleContainer>
            {`Option ${index + 1}: ${title}`}
          </StyledTitleContainer>
          <StyledTableContainer>
            <Table>
              {_.map(items, renderRow)}
            </Table>
          </StyledTableContainer>
        </StyledItemContainer>
      ))}
    </StyledContainer>
  )
}

export default PreviewOptions
