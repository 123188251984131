import ProfileHeader from './ProfileHeader'
import ProfileHeaderAvatar from './ProfileHeaderAvatar'
import ProfileHeaderBottom from './ProfileHeaderBottom'
import ProfileHeaderDetails from './ProfileHeaderDetails'
import ProfileHeaderDetailsLeft from './ProfileHeaderDetailsLeft'
import ProfileHeaderDetailsMiddle from './ProfileHeaderDetailsMiddle'
import ProfileHeaderDetailsRight from './ProfileHeaderDetailsRight'
import ProfileHeaderMenu from './ProfileHeaderMenu'
import ProfileHeaderTop from './ProfileHeaderTop'
import ProfileHeaderWhiteBox from './ProfileHeaderWhiteBox'

ProfileHeader.Avatar = ProfileHeaderAvatar
ProfileHeader.Bottom = ProfileHeaderBottom
ProfileHeader.Details = ProfileHeaderDetails
ProfileHeader.Details.Left = ProfileHeaderDetailsLeft
ProfileHeader.Details.Middle = ProfileHeaderDetailsMiddle
ProfileHeader.Details.Right = ProfileHeaderDetailsRight
ProfileHeader.Menu = ProfileHeaderMenu
ProfileHeader.Top = ProfileHeaderTop
ProfileHeader.WhiteBox = ProfileHeaderWhiteBox

export default ProfileHeader
