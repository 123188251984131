import styled from 'styled-components'

import { DESCRIPTION_LINE_HEIGHT } from './Description'

export const StyledTextWrapper = styled.div`
  display: block;
  overflow: visible;
  word-break: break-word;
  line-height: ${DESCRIPTION_LINE_HEIGHT}px;

  ${({ collapsed }) => collapsed && `
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `} 
`
