import _ from 'lodash'

import { Nursery } from 'services/nurseries/models'
import { GET_NURSERY_STRIPE_INTEGRATION_SUCCESS } from 'services/nurseries/stripeIntegration/constants'
import {
  UPDATE_CHILD_CODE_REFERENCE_SETTINGS_SUCCESS,
  UPDATE_FINANCE_MIGRATION_SUCCESS,
} from 'services/nurseries/single/constants'

import { BasicSingleInitialState, getBasicSingleInitialState } from 'utils/reducer'

import {
  GET_NURSERY_SETTINGS,
  GET_NURSERY_SETTINGS_FAILED,
  GET_NURSERY_SETTINGS_SUCCESS,
  REMOVE_STRIPE_INTEGRATION,
} from './constants'

export interface NurseryContextCommonState extends BasicSingleInitialState {
  data: Nursery | null
}

const initialState: NurseryContextCommonState = getBasicSingleInitialState

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_NURSERY_SETTINGS: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case GET_NURSERY_SETTINGS_FAILED: {
      return {
        ...state,
        isFetching: false,
      }
    }
    case GET_NURSERY_STRIPE_INTEGRATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          stripeNurseryIntegration: {
            ...state.data.stripeNurseryIntegration,
            status: payload.data.status,
          },
        },
      }
    case GET_NURSERY_SETTINGS_SUCCESS: {
      const updatedData = _.merge(state.data || {}, payload.data || {})

      let resultData = {
        ...updatedData,
      }

      if (updatedData.nurserySettings) {
        resultData = {
          ...resultData,
          nurserySettings: {
            ...resultData.nurserySettings,
            openingDays: payload?.data?.nurserySettings?.openingDays || state?.data?.nurserySettings?.openingDays || [],
          },
        }
      }

      return {
        ...state,
        data: resultData,
        isFetching: false,
      }
    }
    case REMOVE_STRIPE_INTEGRATION: {
      const newData = { ...state.data }
      delete newData.stripeNurseryIntegration

      return {
        ...state,
        data: newData,
      }
    }

    case UPDATE_FINANCE_MIGRATION_SUCCESS:
    case UPDATE_CHILD_CODE_REFERENCE_SETTINGS_SUCCESS: {
      return {
        ...state,
        data: _.merge(state.data || {}, payload.data || {}),
      }
    }

    default:
      return state
  }
}
