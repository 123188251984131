import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_CHILD_PRODUCTS,
  LIST_CHILD_PRODUCTS,
  LIST_CHILD_PRODUCTS_FAILED,
  LIST_CHILD_PRODUCTS_SUCCESS,
} from './constants'

export const list = (options: BasicActionOptionsProps) => ({ childProductsApiClient }) => (
  generateBasicActions.list({
    constants: {
      clear: CLEAR_CHILD_PRODUCTS,
      failed: LIST_CHILD_PRODUCTS_FAILED,
      init: LIST_CHILD_PRODUCTS,
      success: LIST_CHILD_PRODUCTS_SUCCESS,
    },
    options,
    service: childProductsApiClient,
    serviceMethod: 'list',
  })
)

export const clearList = () => ({
  type: CLEAR_CHILD_PRODUCTS,
})
