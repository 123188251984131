import i18n from 'translations'
import { BasicListInitialState, BasicSingleInitialState } from 'utils/reducer'
import { createTypeFromEnumValues } from 'utils/typescript'

export enum ApplicableValues {
  CONSUMABLES = 'consumables',
  EXTRA_ITEMS = 'extra_items',
  EXTRA_SESSIONS = 'extra_sessions',
  REGULAR_ITEMS = 'regular_items',
  SESSIONS = 'sessions',
}

export const NURSERY_DISCOUNT_TYPES = {
  AMOUNT: 'amount',
  PERCENTAGE: 'percentage',
}

export const NURSERY_DISCOUNTS_FILTER = {
  ACTIVE: 'ACTIVE',
  ALL: 'ALL',
  ARCHIVED: 'ARCHIVED',
}

export const NURSERY_DISCOUNTS_FILTER_OPTIONS = [
  { label: i18n.t('global:Active'), value: NURSERY_DISCOUNTS_FILTER.ACTIVE },
  { label: i18n.t('global:All'), value: NURSERY_DISCOUNTS_FILTER.ALL },
  { label: i18n.t('global:Archived'), value: NURSERY_DISCOUNTS_FILTER.ARCHIVED },
]

export const NURSERY_DISCOUNT_TYPE_OPTIONS = [
  {
    label: i18n.t('module:Management:Finance:Discounts:discountPercentage'),
    value: NURSERY_DISCOUNT_TYPES.PERCENTAGE,
  },
  {
    label: i18n.t('module:Management:Finance:Discounts:discountAmount'),
    value: NURSERY_DISCOUNT_TYPES.AMOUNT,
  },
]

export enum NurseryDiscountTypesV3 {
  AMOUNT = 'nursery_amount_discount',
  PERCENTAGE = 'nursery_percentage_discount',
}

export const NURSERY_DISCOUNT_TYPE_OPTIONS_V3 = [
  {
    label: i18n.t('module:Management:Finance:Discounts:percentageDiscount'),
    value: NurseryDiscountTypesV3.PERCENTAGE,
  },
  {
    label: i18n.t('module:Management:Finance:Discounts:fixedAmountDiscount'),
    value: NurseryDiscountTypesV3.AMOUNT,
  },
]

export const NURSERY_DISCOUNT_BEFORE_AFTER = {
  AFTER: 'AFTER',
  BEFORE: 'BEFORE',
}

export const OTHER = 'Other'

export interface NurseryDiscountSettings {
  allowOverride?: boolean
  applicable?: ApplicableValues[]
  value: number
}

export interface NurseryDiscount {
  archived?: boolean
  archivedAt?: Date
  createdAt?: Date
  endTime?: number
  id?: number
  name?: string
  settings: NurseryDiscountSettings
  startTime?: number
  type: createTypeFromEnumValues<NurseryDiscountTypesV3>
  updatedAt?: Date
}

export interface NurseryDiscountsV3SingleState extends BasicSingleInitialState {
  data: NurseryDiscount
}

export interface NurseryDiscountsV3ListState extends BasicListInitialState {
  data: NurseryDiscount[]
}

export interface NurseryDiscountsV3State {
  list: NurseryDiscountsV3ListState
  single: NurseryDiscountsV3SingleState
}

export interface NurseryDiscountsV3RootState {
  nurseryDiscountsV3: NurseryDiscountsV3State
}
