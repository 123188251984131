import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { SUPPORTED_FILE_TYPES } from 'constants/mimetypes'
import { OTHER_OPTION, STATUS_OPTIONS_VALUES } from 'services/users/constants'

import { isRequired } from 'utils/fieldValidation'

import { Button, Form, Space, SvgInteractive, Typography } from 'components'

import i18n from 'translations'

import image from 'assets/images/body-map.svg'

import { StyledInjuryChildContainer } from './InjuryChildFormStyled'

export const INJURY_CHILD_FORM = 'InjuryChildForm'

const InjuryChildForm = ({
  defaultBodyMapCoordinates,
  formValues,
  handleSubmit,
  initialValues,
  injuryTypeOptions,
  isChangedCoordinate,
  isEditMode,
  isHideBodyMap,
  onChangeMedia,
  onClickCoordinates,
  onClickHideBodyMap,
  onSubmit,
  selectedChild,
  visible,
}) => {
  const accidentIncidentLabel = 1 === selectedChild
    ? i18n.t('module:Injury:Common:accident')
    : i18n.t('module:Injury:Common:incident')

  return (
    <div style={{ userSelect: 'none' }}>
      <StyledInjuryChildContainer visible={visible}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            component={Form.TextField}
            name="id"
            type="hidden"
          />
          <Field
            component={Form.TextField}
            name="child"
            type="hidden"
          />
          <Field
            component={Form.TextField}
            name="accidentDetailsId"
            type="hidden"
          />
          <Field
            component={Form.TextField}
            name="bodyMapCoordinates"
            type="hidden"
          />
          <Form.Row>
            <Typography fontSize="20" bold>{i18n.t('module:Injury:Preview:Details:title')}</Typography>
          </Form.Row>
          <Form.Row
            label={i18n.t('global:Description')}
            labelMargin="0 20px 0 0"
            width={{ field: '600px' }}
            required
            verticalLabel
          >
            <Field
              component={Form.TextAreaField}
              name="description"
              placeholder={
              i18n.t('module:Injury:Placeholder:AccidentIncidentDescription', {
                type: accidentIncidentLabel.toLowerCase() })
            }
              validate={isRequired}
            />
          </Form.Row>
          <Form.Row
            label={i18n.t('module:Injury:Preview:Details:riskAssessmentLabel')}
            labelMargin="0 20px 0 0"
            width={{ field: '600px' }}
            verticalLabel
          >
            <Field
              component={Form.TextAreaField}
              name="riskAssessment"
              placeholder={i18n.t('module:Injury:Placeholder:ristOutcomes')}
            />
          </Form.Row>
          <Form.Row>
            <Typography fontSize="20" bold>{i18n.t('module:Safeguarding:Fields:InjuryDetails:label')}</Typography>
          </Form.Row>
          <Form.Row
            label={i18n.t('module:Safeguarding:Fields:Injury:label')}
            width={{ field: '300px' }}
            verticalLabel
          >
            <Field
              component={Form.Select}
              name="injuryType"
              options={injuryTypeOptions}
              placeholder={i18n.t('module:Safeguarding:Fields:Injury:placeholder')}
            />
          </Form.Row>
          <Form.Row
            label={i18n.t('module:Safeguarding:Fields:InjuryDescription:label')}
            width={{ field: '800px' }}
            required
            verticalLabel
          >
            <Field
              component={Form.TextAreaField}
              name="injuryDescription"
              placeholder={i18n.t('module:Safeguarding:Fields:InjuryDescription:placeholder')}
              validate={isRequired}
            />
          </Form.Row>
          <Form.Row>
            <Typography fontSize="20" bold>{i18n.t('module:Safeguarding:Fields:BodyMap:label')}</Typography>
          </Form.Row>
          <Form.Row width={{ field: '800px' }} verticalLabel>
            <Typography align="right">
              <Button
                hierarchy="tertiary"
                label={(
                  <Typography transform="uppercase" bold>
                    {isHideBodyMap
                      ? i18n.t('module:Safeguarding:Fields:BodyMap:showBodyMap')
                      : i18n.t('module:Safeguarding:Fields:BodyMap:hideBodyMap')}
                  </Typography>
            )}
                negativeMargins
                onClick={onClickHideBodyMap}
              />
            </Typography>
          </Form.Row>
          <Form.Row width={{ field: '400px' }} verticalLabel>
            {!isHideBodyMap && (
              <Typography fontSize={14} margin="-50px 0 0">
                {i18n.t('module:Safeguarding:Fields:BodyMap:note')}
              </Typography>
            )}
          </Form.Row>
          {!isHideBodyMap && (
            <Form.Row
              margin="-35px 0 0 0"
              width={{ field: '800px' }}
              verticalLabel
            >
              <SvgInteractive
                defaultValues={isEditMode ? initialValues.bodyMapCoordinates : defaultBodyMapCoordinates}
                displayClearAll={(!isChangedCoordinate
              && isEditMode
              && initialValues.bodyMapCoordinates.length)
                  ? true
                  : !!formValues?.bodyMapCoordinates?.coordinateList?.length}
                image={image}
                svgViewBox="0 0 924 535"
                onClick={onClickCoordinates}
              />
            </Form.Row>
          )}
          <Form.Row
            label={i18n.t('module:Injury:Preview:Details:attachedMediaLabel')}
            labelMargin="0 20px 0 0"
            verticalLabel
          >
            <Form.Row.FlexItem flex="1" mobileFull>
              <Field
                accept={[SUPPORTED_FILE_TYPES.IMAGES]}
                component={Form.MediaPicker}
                name="media"
                disableMediaTag
                disableTagging
                sync
                onChange={onChangeMedia}
              />
            </Form.Row.FlexItem>
          </Form.Row>
          <Form.Row>
            <Typography fontSize="20" bold>{i18n.t('module:Injury:Preview:Details:treatment')}</Typography>
          </Form.Row>
          <Form.Row
            label={i18n.t('module:Injury:Preview:Details:firstAidByLabel')}
            width={{ field: '300px' }}
            verticalLabel
          >
            <Field
              component={Form.InfiniteDropdowns.Users}
              extraOptions={[OTHER_OPTION]}
              name="firstAider"
              placeholder={i18n.t('module:Injury:Preview:Details:firstAidByLabel')}
              status={STATUS_OPTIONS_VALUES.ACTIVE}
              searchable
              value
            />
          </Form.Row>
          {OTHER_OPTION.label === formValues?.firstAider?.label && (
            <Form.Row
              label={i18n.t('module:Injury:Fields:otherFirstAidBy:label')}
              labelMargin="0 20px 0 0"
              width={{ field: '300px' }}
              required
            >
              <Field
                component={Form.TextField}
                name="otherFirstAider"
                placeholder={i18n.t('module:Injury:Fields:otherFirstAidBy:placeholder')}
                validate={isRequired}
              />
            </Form.Row>
          )}
          <Form.Row
            label={i18n.t('module:Injury:Preview:Details:treatmentDetailsLabel')}
            width={{ field: '600px' }}
            verticalLabel
          >
            <Field
              component={Form.TextAreaField}
              name="treatment"
              placeholder={i18n.t('module:Injury:Placeholder:treatmentProvided')}
            />
          </Form.Row>
          <Form.Row>
            <Typography fontSize="20" bold>{i18n.t('module:Injury:Preview:Details:noticeLabel')}</Typography>
          </Form.Row>
          <Form.Row
            label={i18n.t('module:Injury:Preview:guardianContactedLabel')}
            width={{ field: '300px' }}
            verticalLabel
          >
            <Field
              childId={initialValues.child}
              component={Form.InfiniteDropdowns.Carers}
              name="contactPerson"
              placeholder={i18n.t('module:Injury:Placeholder:parent')}
              status={STATUS_OPTIONS_VALUES.ACTIVE}
              parentalResponsibilityOnly
              searchable
              value
            />
          </Form.Row>
          <Space margin="0 0 20px 0" padding="0 0 10px 0" />
        </Form>
      </StyledInjuryChildContainer>
    </div>
  )
}
export default reduxForm()(InjuryChildForm)
