import _ from 'lodash'

import React from 'react'
import Drawer from 'react-motion-drawer'

import { MenuItem } from 'components'

import { StyledFooter, StyledMenuSidebar, StyledSidebarContent, StyledWrapper } from './MenuSidebarStyled'
import LogoutWithHelp from '../LogoutWithHelp'
import ReferralButton from '../ReferralButton'

const MenuSidebar = ({
  authUser,
  dashboardTitle,
  isOffline,
  items,
  onChangeSidebar,
  onHelpClick,
  onLogout,
  onShowPremiumModal,
  sidebarIsOpen,
}) => {
  const renderItem = ({
    availableInOfflineMode,
    badgeValue,
    dashboardType,
    hidden,
    icon,
    isNew,
    label,
    premium,
    to,
  }, index) => {
    if (dashboardType) {
      return (
        <MenuItem
          disabled={isOffline}
          icon="blossomboard"
          key={index}
          label={dashboardTitle}
          to="/"
          inline
          onChangeSidebar={onChangeSidebar}
        />
      )
    }

    return (
      <MenuItem
        badgeValue={badgeValue}
        disabled={isOffline && !availableInOfflineMode}
        hidden={hidden}
        icon={icon}
        isNew={isNew}
        key={index}
        label={label}
        premium={premium}
        to={to}
        inline
        onChangeSidebar={onChangeSidebar}
        onShowPremiumModal={onShowPremiumModal}
      />
    )
  }

  return (
    <StyledMenuSidebar
      open={sidebarIsOpen}
    >
      <Drawer
        open={sidebarIsOpen}
        overlayColor="rgba(0,0,0,0.5)"
        width={260}
        right
        onChange={onChangeSidebar}
      >
        <StyledWrapper>
          <StyledSidebarContent>
            {_.map(items, renderItem)}
          </StyledSidebarContent>
          <ReferralButton />
          <StyledFooter>
            <LogoutWithHelp
              authUser={authUser}
              full
              onHelpClick={onHelpClick}
              onLogout={onLogout}
            />
          </StyledFooter>
        </StyledWrapper>
      </Drawer>
    </StyledMenuSidebar>
  )
}

export default MenuSidebar
