import React from 'react'

import { Page } from 'components'

import InlineEditableField from 'module/Management/components/InlineEditableField'

import i18n from 'translations'
import BookingAbsenceForm, { BOOKING_ABSENCE_FORM } from './components/BookingAbsenceForm'

const BookingAbsenceView = ({ initialValues, onSubmit, value }) => (
  <Page.Section title={i18n.t('module:Management:ParentApp:BookingAbsence:title')} noMargin>
    <InlineEditableField
      editableComponent={(callback) => (
        <BookingAbsenceForm
          initialValues={initialValues}
          onSubmit={callback}
        />
      )}
      formName={BOOKING_ABSENCE_FORM}
      title={i18n.t('module:Management:ParentApp:BookingAbsence:AllowParentBookingAbsence:title')}
      value={value}
      onSubmit={onSubmit}
    />
  </Page.Section>
)

export default BookingAbsenceView
