import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'recompose'

import { Form } from 'components'

export const INSTANT_MESSENGER_FORM = 'INSTANT_MESSENGER_FORM'

const InstantMessengerForm = ({
  handleSubmit,
  isSubmitting,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row>
      <Form.Row.FlexItem flex="0 0 180px" mobileFull>
        <Field
          component={Form.Switch}
          disabled={isSubmitting}
          name="enabledForParents"
        />
      </Form.Row.FlexItem>
    </Form.Row>
  </Form>
)

const enhance = compose(reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: true,
  form: INSTANT_MESSENGER_FORM,
}))

export default enhance(InstantMessengerForm)
