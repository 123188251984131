import { Property } from 'csstype'

import React, { PropsWithChildren } from 'react'

import { StyledGridItem } from './GridStyled'

export interface GridItemProps {
  alignItems?: {
    desktop?: Property.AlignItems
    mobile?: Property.AlignItems
    tablet?: Property.AlignItems
  }
  flex?: {
    desktop?: number | string
    mobile?: number | string
    tablet?: number | string
  }
  justifyContent?: {
    desktop?: Property.JustifyContent
    mobile?: Property.JustifyContent
    tablet?: Property.JustifyContent
  }
  maxWidth?: {
    desktop?: string
    mobile?: string
    tablet?: string
  }
  textAlign?: {
    desktop?: Property.TextAlign
    mobile?: Property.TextAlign
    tablet?: Property.TextAlign
  }
  width?: {
    desktop?: string
    mobile?: string
    tablet?: string
  }
}

const GridItem: React.FC<PropsWithChildren<GridItemProps>> = ({
  alignItems,
  children,
  flex,
  justifyContent,
  maxWidth,
  textAlign,
  width,
}) => (
  <StyledGridItem
    $alignItems={alignItems}
    $flex={flex}
    $justifyContent={justifyContent}
    $maxWidth={maxWidth}
    $textAlign={textAlign}
    $width={width}
  >
    {children}
  </StyledGridItem>
)

export default GridItem
