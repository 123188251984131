import styled from 'styled-components'

export const StyledAmountContainer = styled.div`
  & > div {
    display: flex;
    align-items: center;

    & > * ~ * {
      padding-left: 10px;
    }
  }
`
