import styled from 'styled-components'
import color from 'color'

import { getBrandingColor } from 'utils/branding'

export const StyledCustomButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer;
  transition: .2s;
  
  &:hover {
    background: ${color(getBrandingColor('primary-color')).lighten(0.1).hex()};
  }
  
  p {
    width: auto;
    margin-left: 15px;
  }
`
