import moment from 'moment'
import _ from 'lodash'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { OBSERVATION_STATES } from 'services/legacy/frameworks/constants'

import { Typography } from 'components'
import ProgressLabel from 'module/Learning/Observations/components/ProgressLabel'

import {
  StyledActivitiesWrapper,
  StyledActivity,
  StyledActivityButton,
  StyledItemDetailsWrapper,
  StyledMontessoriTableWrapper,
  StyledSubCategory,
  StyledSubCategoryHeader,
} from './MontessoriTableStyled'

const MontessoriTable = ({
  category,
  childFrameworkMontessoriProgress,
  montessoriCategories,
  onShowSelectProgressModal,
}) => {
  if (!category) {
    return null
  }

  const { subcategories } = _.find(montessoriCategories, ({ id }) => category === id) || {}

  const renderActivity = (activity) => {
    const value = childFrameworkMontessoriProgress?.[activity.id]
    const stateObject = _.find(OBSERVATION_STATES, (item) => item.value === value?.state)

    return (
      <StyledActivity key={`activity_${activity.id}`}>
        <StyledActivityButton
          onClick={() => onShowSelectProgressModal(activity)}
        >
          <Typography fontSize={15} bold>
            {activity.name}
          </Typography>
          {stateObject && (
            <StyledItemDetailsWrapper>
              <ProgressLabel value={stateObject} />
              <Typography color={NEUTRAL_COLOURS.GRAY}>
                {moment(value?.date).format('DD-MM-YYYY')}
              </Typography>
            </StyledItemDetailsWrapper>
          )}
        </StyledActivityButton>
      </StyledActivity>
    )
  }

  const renderSubCategory = ({ activities, id, name: subCategoryName }) => (
    <StyledSubCategory key={`subcategory_${id}`}>
      <StyledSubCategoryHeader>
        <Typography fontSize={17} transform="uppercase" bold>
          {subCategoryName}
        </Typography>
      </StyledSubCategoryHeader>
      <StyledActivitiesWrapper>
        {_.map(activities, renderActivity)}
      </StyledActivitiesWrapper>
    </StyledSubCategory>
  )

  return (
    <StyledMontessoriTableWrapper>
      {_.map(subcategories, renderSubCategory)}
    </StyledMontessoriTableWrapper>
  )
}

export default MontessoriTable
