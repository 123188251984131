import _ from 'lodash'
import parse from 'html-react-parser'
import { NotificationTagEnum, useNotifications, useSync } from '@blossomdev/sync'

import React from 'react'
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized'

import { ZINDEX_ORDER } from 'constants/layout'
import { NEUTRAL_COLOURS } from 'constants/colors'
import { NOTIFICATION_TYPES } from 'module/Notification/components/NotificationList/Constants'

import { getBrandingColor } from 'utils/branding'

import { Conversations, DropdownMenu, EmptyState, Space, Spinner } from 'components'

import i18n from 'translations'

import { generateRedirectUrl, replaceBodyWithValues } from './NotificationListHelper'
import { StyledItemContent, StyledItemContentContainer } from './NotificationListStyled'

export const PHOTO_ALT_KEY = 'photoAlt'
interface NotificationListProps {
  notificationTagType?: NotificationTagEnum
  onRedirect?: () => void
}

const cache = new CellMeasurerCache({
  defaultHeight: 78,
  fixedWidth: true,
})

const NotificationList: React.FC<NotificationListProps> = ({ notificationTagType, onRedirect }) => {
  const { changeNotificationReadStatus, deleteNotification } = useSync()
  const { initialized, loadMore, notifications } = useNotifications(notificationTagType)

  const renderItemContent = (item) => (
    <StyledItemContentContainer>
      <StyledItemContent>
        {parse(replaceBodyWithValues(item.body.value, item.body.content, item.body.placeholders))}
      </StyledItemContent>
    </StyledItemContentContainer>
  )

  const handleItemClick = (id, isRead) => {
    if (!isRead) {
      changeNotificationReadStatus(id, !isRead)
    }

    onRedirect()
  }

  const handleItemChangeStatus = (item, isRead) => {
    changeNotificationReadStatus(item.id, !isRead ? isRead : isRead)
  }

  const handleItemDelete = (item) => {
    if (item.id) {
      deleteNotification(item.id)
    }
  }

  const renderRow = ({ index, key, parent, style }) => {
    const item = notifications[index]
    const { data, type } = item
    const photoAltObj = _.find(data, { key: PHOTO_ALT_KEY })
    const redirectUrl = generateRedirectUrl(item)

    const actions = (
      <DropdownMenu
        color={NEUTRAL_COLOURS.GRAY_SECONDARY}
        parentZindex={ZINDEX_ORDER.NOTIFICATION}
        zIndex={ZINDEX_ORDER.NOTIFICATION_MENU}
      >
        <DropdownMenu.Item
          key="mark_as_read"
          label={i18n.t('module:Notifications:Action:MarkAsRead:label')}
          onClick={() => handleItemChangeStatus(item, true)}
        />
        <DropdownMenu.Item
          key="mark_as_unread"
          label={i18n.t('module:Notifications:Action:MarkAsUnRead:label')}
          onClick={() => handleItemChangeStatus(item, false)}
        />
        <DropdownMenu.Item
          key="clear"
          label={i18n.t('module:Notifications:Action:Clear:label')}
          onClick={() => handleItemDelete(item)}
        />
      </DropdownMenu>
    )

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        <div key={item.id} style={style}>
          <Conversations.ListItem
            actions={actions}
            avatarProps={type !== NOTIFICATION_TYPES.NEW_ENQUIRY && {
              disableEffect: true,
              initials: photoAltObj?.value,
              src: item?.photo,
            }}
            content={renderItemContent(item)}
            iconProps={type === NOTIFICATION_TYPES.NEW_ENQUIRY && {
              color: getBrandingColor('primary-color'),
              height: 24,
              icon: 'enquiries',
            }}
            isUnread={!item.isRead}
            lastConversationDate={item.createdAt}
            to={redirectUrl}
            onClick={() => handleItemClick(item.id, item.isRead)}
          />
        </div>
      </CellMeasurer>
    )
  }

  if (!initialized) {
    return (
      <React.Fragment>
        <Space space="15px" />
        <Spinner />
      </React.Fragment>
    )
  }

  if (!notifications?.length && initialized) {
    return (
      <EmptyState
        icon="bell"
        text1={i18n.t('module:Notifications:List:emptyState')}
      />
    )
  }

  return (
    <Conversations.List
      cacheCurrent={cache}
      infiniteLoaderProps={{
        items: notifications,
        loadMore: () => loadMore(),
        threshold: 5,
        totalResult: 1000000,
      }}
      itemsLength={notifications?.length}
      renderRow={renderRow}
      enableInfiniteLoader
    />
  )
}

export default NotificationList
