import _ from 'lodash'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { EVENTS, logEvent } from 'analytics'

import { generateRoute } from 'utils/routing'

import {
  Avatar,
  DateString,
  EmptyState,
  InfiniteDropdowns,
  InfiniteScroll,
  Page,
  Pagination,
  SearchBar,
  Section,
  Select,
  Spinner,
  Toolbar,
  Typography,
} from 'components'

import i18n from 'translations'

import { StyledChildContainer, StyledChildRow } from './ChildrenListStyled'

const ChildrenListView = ({
  ageMonthsOptions,
  authAccessMap,
  childExportGranted,
  childType,
  childTypeOptions,
  childrenList,
  fromAge,
  isFetching,
  onAgeChange,
  onChildTypeFilterChange,
  onExportClick,
  onPageChange,
  onRoomFilterChange,
  onSearchChange,
  page,
  pageCount,
  room,
  toAge,
  totalResults,
}) => {
  const renderItem = (item) => {
    const { ageMonths, birthDate, displayName, firstName, id, isArchived, photo, surname } = item

    const subtitle = (
      <Typography color={NEUTRAL_COLOURS.BASIC} fontSize={14} margin="3px 0 8px 0">
        {i18n.t('global:ageMonths', { ageMonths })}
        {' ('}
        <DateString date={birthDate} />
        {')'}
      </Typography>
    )

    return (
      <StyledChildRow key={id}>
        <Avatar
          direction="vertical"
          initials={[firstName, surname]}
          isArchived={isArchived}
          src={photo}
          subTitle={subtitle}
          title={(
            <Typography fontSize={14} primary>
              {displayName}
            </Typography>
          )}
          to={`/children/${id}`}
        />
      </StyledChildRow>
    )
  }

  const renderContent = () => {
    if (isFetching && 1 === page) {
      return (
        <Spinner />
      )
    }

    if (!isFetching && (childrenList && !childrenList.length)) {
      return (
        <EmptyState
          icon="children"
          text1={i18n.t('module:Children:List:emptyStateText')}
        />
      )
    }

    return (
      <React.Fragment>
        <Pagination
          titlePlural={i18n.t('global:pagination:children')}
          titleSingular={i18n.t('global:pagination:child')}
          totalResults={totalResults}
        />
        <br />
        <InfiniteScroll
          dataLength={childrenList ? childrenList.length : 0}
          hasMore={page < pageCount}
          isFetching={isFetching}
          next={() => onPageChange((+page) + 1)}
        >
          <StyledChildContainer>
            {_.map(childrenList, renderItem)}
          </StyledChildContainer>
        </InfiniteScroll>
      </React.Fragment>
    )
  }

  const actions = authAccessMap.ChildAddAccess && (
    <Section.Actions
      options={childExportGranted ? [{ onClick: onExportClick, type: 'export' }] : []}
      primary={[{
        label: i18n.t('module:Children:List:createButtonLabel'),
        onClick: () => logEvent(EVENTS.CHILD_ADD_BUTTON_CLICKED, { context: 'list' }),
        to: generateRoute('CHILDREN.ADD'),
      }]}
    />
  )

  return (
    <React.Fragment>
      <SearchBar placeholder={i18n.t('module:Children:List:searchPlaceholder')} onChange={onSearchChange} />
      <Page.Section
        actions={actions}
        isLoading={isFetching && 1 === page}
        title={i18n.t('module:Children:List:title')}
      >
        <Toolbar>
          <Toolbar.Group>
            <Toolbar.Item>
              <InfiniteDropdowns.Rooms
                placeholder={i18n.t('module:Children:List:Filters:allRoomsPlaceHolder')}
                value={room}
                onChange={onRoomFilterChange}
              />
            </Toolbar.Item>
            <Toolbar.Item>
              <Select
                clearable={false}
                options={childTypeOptions}
                placeholder={i18n.t('module:Children:List:Filters:childTypePlaceHolder')}
                value={childType}
                onChange={onChildTypeFilterChange}
              />
            </Toolbar.Item>
            <Toolbar.Item>{i18n.t('module:Children:List:Filters:ageInMonthsLabel')}</Toolbar.Item>
            <Toolbar.Item>
              <Select
                minWidth={100}
                options={ageMonthsOptions}
                placeholder={i18n.t('module:Children:List:Filters:ageInMonthsFromPlaceholder')}
                value={fromAge}
                onChange={onAgeChange('fromAge')}
              />
            </Toolbar.Item>
            <Toolbar.Item>{i18n.t('module:Children:List:Filters:ageInMonthsToLabel')}</Toolbar.Item>
            <Toolbar.Item>
              <Select
                minWidth={100}
                options={ageMonthsOptions}
                placeholder={i18n.t('module:Children:List:Filters:ageInMonthsToPlaceholder')}
                value={toAge}
                onChange={onAgeChange('toAge')}
              />
            </Toolbar.Item>
          </Toolbar.Group>
        </Toolbar>
        {renderContent()}
      </Page.Section>
    </React.Fragment>
  )
}

export default ChildrenListView
