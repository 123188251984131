import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { FEATURE_FLAGS } from 'constants/security'

import auth from 'utils/auth'
import SettingsSessionsAdd from 'module/Settings/SettingsSessions/v2/SettingsSessionsAdd'

import { withAppService } from 'services/app'
import { withOrganizationSessionsService } from 'services/legacy/organizationSessions'

import SettingsSessionsItemView from './SettingsSessionsItemView'

class SettingsSessionsItemContainer extends Component {
  componentDidMount() {
    const { organizationSessionsActions, params } = this.props
    const { sessionId } = params

    organizationSessionsActions.getSession(sessionId)
  }

  componentWillUnmount() {
    const { organizationSessionsActions } = this.props

    organizationSessionsActions.clearSession()
  }

  render() {
    const { authAccessMap, errorMessages, organizationSessionsSingleState } = this.props

    const session = organizationSessionsSingleState.data
    const isLoading = organizationSessionsSingleState.isFetching || !session

    return (
      <SettingsSessionsItemView
        authAccessMap={authAccessMap}
        errorMessages={errorMessages}
        isLoading={isLoading}
        session={session}
      />
    )
  }
}

const mapState = (state, { appSelectors, organizationSessionsSingleState }) => ({
  authAccessMap: {
    module: {
      SettingsSessionsAdd: auth.SELECTORS.getComponentIsAuthorised(state, SettingsSessionsAdd),
    },
    section: {
      FinanceAutomation: auth.SELECTORS.getIsAuthorised(state, {
        flags: [FEATURE_FLAGS.FINANCE_AUTOMATION],
      }),
    },
  },
  errorMessages: appSelectors.getErrorMessages(organizationSessionsSingleState),
})

const enhance = compose(withAppService, withOrganizationSessionsService, connect(mapState))

export default enhance(SettingsSessionsItemContainer)
