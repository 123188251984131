import React from 'react'

import { ModalBox } from 'components'

import i18n from 'translations'

import AddDiscountToRegularBookingsModalForm, {
  AddDiscountToRegularBookingsModalFormProps,
  AddDiscountToRegularBookingsModalFormValues,
} from './components/AddDiscountToRegularBookingsModalForm'

interface AddDiscountToRegularBookingsModalViewProps extends AddDiscountToRegularBookingsModalFormProps{
  initialValues: AddDiscountToRegularBookingsModalFormValues
}

const AddDiscountToRegularBookingsModalView: React.FC<AddDiscountToRegularBookingsModalViewProps> = ({
  onCloseClick,
  ...rest
}) => (
  <ModalBox
    maxWidth={600}
    title={i18n.t('modals:AddDiscountToRegularBookings:title')}
    onCloseClick={onCloseClick}
  >
    <AddDiscountToRegularBookingsModalForm
      onCloseClick={onCloseClick}
      {...rest}
    />
  </ModalBox>
)

export default AddDiscountToRegularBookingsModalView
