import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class CarerChildRelationsApiClient extends ServiceBase {
  create = (params = {}, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/carer_child_relations?${getQueryStringV2(params)}`,
  })

  update = (id, params = {}, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/carer_child_relations/${id}?${getQueryStringV2(params)}`,
  })

  remove = (id) => this.request({
    method: 'DELETE',
    path: `/v2/carer_child_relations/${id}`,
  })
}

export default CarerChildRelationsApiClient
