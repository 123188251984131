import styled from 'styled-components'

export const StyledChartWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
  padding-bottom: 50px;
  justify-content: center;
  
  @media(max-width: 790px) {
    grid-template-columns: 1fr;
    grid-row-gap: 25px;
  }
`

export const StyledPartWrapper = styled.div`
  position: relative;
`

export const StyledEmptyState = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  left: 50%;
  right: 0;
  transform: translateX(-50%);
  height: 300px;
  max-width: 300px;
  text-align: center;
`

export const StyledFooterDescription = styled.div`
  padding: 40px 0;
  text-align: center;  
`
