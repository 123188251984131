import React from 'react'

import { BasicViewProps, Option } from 'constants/models'
import { NEUTRAL_COLOURS } from 'constants/colors'
import { Ranges } from 'components/MultiRangeCalendar/MultiRangeCalendar'
import { Period } from 'services/booking/periods/models'
import { PeriodTime } from 'services/periodTimes/models'

import { generateRoute } from 'utils/routing'

import {
  Banner,
  Button,
  Callout,
  Icon,
  MultiRangeCalendar,
  Page,
  Section,
  Select,
  Spinner,
  StickyFooter,
  Toolbar,
  Typography,
} from 'components'

import i18n from 'translations'

import { StyledBannerContainer, StyledLegendContainer, StyledTermTimeLegend } from './SetTermDatesStyled'

export interface SetTermDatesViewProps extends BasicViewProps {
  closureDates?: PeriodTime[]
  defaultRanges?: Ranges[]
  monthOptions?: Option[]
  onMonthChange?: (month: Option) => void
  onMultiRangeCalendarChange?: (ranges: Ranges[]) => void
  onYearChange?: (year: Option) => void
  period?: Period
  selectedMonth?: Option
  selectedYear?: Option
  yearOptions?: Option[]
}

const SetTermDatesView: React.FC<SetTermDatesViewProps> = ({
  closureDates,
  defaultRanges,
  errorMessages,
  isLoading,
  isSubmitting,
  monthOptions,
  onMonthChange,
  onMultiRangeCalendarChange,
  onSubmit,
  onYearChange,
  period,
  selectedMonth,
  selectedYear,
  yearOptions,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <React.Fragment>
        <Toolbar>
          <Toolbar.Group>
            <Toolbar.Item>
              <Select
                clearable={false}
                options={yearOptions}
                value={selectedYear}
                onChange={onYearChange}
              />
            </Toolbar.Item>
            <Toolbar.Item>
              <Select
                clearable={false}
                options={monthOptions}
                value={selectedMonth}
                onChange={onMonthChange}
              />
            </Toolbar.Item>
            <Toolbar.Item>
              <StyledLegendContainer>
                <StyledTermTimeLegend />
                <Typography margin="0 0 0 8px">
                  {i18n.t('module:Management:AttendancePeriods:SetTermDates:termTimeLegendLabel')}
                </Typography>
              </StyledLegendContainer>
            </Toolbar.Item>
            <Toolbar.Item>
              <StyledLegendContainer>
                <Icon color={NEUTRAL_COLOURS.BASIC} height={20} icon="blocked" />
                <Typography margin="0 0 0 8px">
                  {i18n.t('module:Management:AttendancePeriods:SetTermDates:closureDateLegendLabel')}
                </Typography>
              </StyledLegendContainer>
            </Toolbar.Item>
          </Toolbar.Group>
        </Toolbar>
        <MultiRangeCalendar
          closureDates={closureDates}
          defaultDateRanges={defaultRanges}
          month={+selectedMonth.value}
          year={+selectedYear.value}
          onChange={onMultiRangeCalendarChange}
        />
        <StickyFooter>
          <Button
            hierarchy="tertiary"
            label={i18n.t('global:Cancel')}
            to={generateRoute('MANAGEMENT.ATTENDANCE_PERIODS')}
            negativeMargins
          />
          <StickyFooter.Flex />
          <StyledBannerContainer>
            <Banner.Info noBottomMargin>
              {i18n.t('module:Management:AttendancePeriods:SetTermDates:editInfoBanner')}
            </Banner.Info>
          </StyledBannerContainer>
          <Button
            isLoading={isSubmitting}
            label={i18n.t('global:Save')}
            onClick={onSubmit}
          />
        </StickyFooter>
      </React.Fragment>
    )
  }

  return (
    <Page>
      <Page.Content>
        <Callout content={errorMessages} error />
        <Section
          title={i18n.t('module:Management:AttendancePeriods:SetTermDates:title', { periodName: period?.name })}
        >
          {renderContent()}
        </Section>
      </Page.Content>
    </Page>
  )
}

export default SetTermDatesView
