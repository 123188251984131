import styled from 'styled-components'

import { StyledContainerWrapper } from 'components/SearchBar/SearchBarStyled'
import { StyledSectionActionButton } from 'components/legacy/MediaPicker/MediaPickerStyled'

export const StyledCustomPageWrapper = styled.div`
  position: relative;

  ${StyledContainerWrapper} {
    margin-top: -10px;
  }
  
  ${StyledSectionActionButton} {
    position: absolute;
    right: 0;
    top: 0;
    
    @media(max-width: 730px) {
      position: relative;
    }
  }
`
