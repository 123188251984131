import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as nextStepsSelectors from './selectors'

const withNextStepsService = (WrappedComponent) => {
  const mapState = ({ nextSteps }) => ({ nextSteps })

  const mapDispatch = injectActions('nextStepsActions', actions)

  const Component = ({ nextSteps, nextStepsActions, ...others }) => (
    <WrappedComponent
      nextStepsActions={nextStepsActions}
      nextStepsListState={nextSteps.list}
      nextStepsSelectors={nextStepsSelectors}
      nextStepsSingleState={nextSteps.single}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withNextStepsService
