import {
  ADD_SLEEP,
  ADD_SLEEP_FAILED,
  ADD_SLEEP_SUCCESS,
  REMOVE_SLEEP,
  REMOVE_SLEEP_FAILED,
  REMOVE_SLEEP_SUCCESS,
  UPDATE_SLEEP,
  UPDATE_SLEEP_FAILED,
  UPDATE_SLEEP_SUCCESS,
} from './constants'

const initialState = {
  isSubmitting: false,
}

export default (state = initialState, { type }) => {
  switch (type) {
    case ADD_SLEEP:
    case REMOVE_SLEEP:
    case UPDATE_SLEEP:
      return { ...state, isSubmitting: true }
    case REMOVE_SLEEP_FAILED:
    case REMOVE_SLEEP_SUCCESS:
    case ADD_SLEEP_FAILED:
    case ADD_SLEEP_SUCCESS:
    case UPDATE_SLEEP_FAILED:
    case UPDATE_SLEEP_SUCCESS:
      return { ...state, isSubmitting: false }
    default:
      return state
  }
}
