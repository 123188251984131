import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withNurseryFrameworkRelationsService } from 'services/legacy/nurseryFrameworkRelations'
import { withSnackbarService } from 'services/utils/snackbar'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouter } from 'services/router'

import { Typography } from 'components'

import i18n from 'translations'

import CurriculumsView from './CurriculumsView'
import { getTableData } from './CurriculumsHelpers'

class CurriculumsContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      defaultFramework: null,
      enabledFrameworks: [],
      isEnableFrameworkSubmitting: false,
      isSetAsDefaultSubmitting: false,
      willBeActivated: null,
      willBeDefault: null,
    }
  }

  componentDidMount() {
    this.fetch(false)
  }

  componentWillUnmount() {
    const { nurseryFrameworkRelationsActions } = this.props

    nurseryFrameworkRelationsActions.clearList()
  }

  handleListSuccess = ({ data }) => {
    this.setState({
      defaultFramework: _.find(data, 'default')?.id,
      enabledFrameworks: _.map(_.filter(data, ({ enabled }) => enabled), ({ id }) => id),
    })
  }

  fetch = (silent = true) => {
    const { nurseryFrameworkRelationsActions } = this.props

    nurseryFrameworkRelationsActions.list({
      onSuccess: this.handleListSuccess,
      silent,
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleSetAsDefaultFailed = () => {
    this.setState({
      isSetAsDefaultSubmitting: false,
      willBeDefault: null,
    })
  }

  handleSetAsDefaultSuccess = (id) => () => {
    this.setState({
      defaultFramework: id,
      isSetAsDefaultSubmitting: false,
      willBeDefault: null,
    })
  }

  handleSetAsDefaultClick = (id) => {
    const { nurseryFrameworkRelationsActions } = this.props

    this.setState({
      isSetAsDefaultSubmitting: true,
      willBeDefault: id,
    }, () => {
      nurseryFrameworkRelationsActions.update({
        body: ({
          default: true,
        }),
        onFailed: this.handleSetAsDefaultFailed,
        onSuccess: this.handleSetAsDefaultSuccess(id),
        params: [id, {}],
      })
    })
  }

  handleActivateCurriculumFailed = () => {
    this.setState({
      isEnableFrameworkSubmitting: false,
      willBeActivated: null,
    })
  }

  handleActivateCurriculumSuccess = (id) => ({ data }) => {
    this.setState((prevState) => ({
      enabledFrameworks: _.xor(prevState.enabledFrameworks, [id]),
      isEnableFrameworkSubmitting: false,
      willBeActivated: null,
    }), () => {
      const { defaultFramework, enabledFrameworks } = this.state

      if (defaultFramework === id && enabledFrameworks?.length) {
        return this.handlePageChange(1)
      }

      if (defaultFramework === id) {
        return this.setState({ defaultFramework: null })
      }

      return this.setState((prevState) => ({
        defaultFramework: data.default ? id : prevState.defaultFramework,
      }))
    })
  }

  handleActivateCurriculum = ({ enable, id }) => {
    const { nurseryFrameworkRelationsActions } = this.props

    this.setState({
      isEnableFrameworkSubmitting: true,
      willBeActivated: id,
    }, () => {
      nurseryFrameworkRelationsActions.update({
        body: ({
          enabled: enable,
        }),
        onFailed: this.handleActivateCurriculumFailed,
        onSuccess: this.handleActivateCurriculumSuccess(id),
        params: [id, {}],
      })
    })
  }

  handleToggleChange = (id) => {
    const { modalActions, modalConsts } = this.props
    const { enabledFrameworks } = this.state

    const isEnabled = -1 !== _.indexOf(enabledFrameworks, id)
    const prefix = 'module:Management:Learning:Curriculums:ConfirmModal:'

    if (!isEnabled) {
      return this.handleActivateCurriculum({ enable: true, id })
    }

    return modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('global:deactivate'),
      icon: 'warning',
      onConfirm: () => this.handleActivateCurriculum({ enable: false, id }),
      text: (
        <React.Fragment>
          <Typography>
            {i18n.t(prefix.concat('1'))}
          </Typography>
          <Typography>
            {i18n.t(prefix.concat('2'))}
          </Typography>
        </React.Fragment>
      ),
    })
  }

  render() {
    const {
      data,
      errorMessages,
      isFetching,
      paginationUtils,
      totalResults,
    } = this.props
    const {
      defaultFramework,
      enabledFrameworks,
      isEnableFrameworkSubmitting,
      isSetAsDefaultSubmitting,
      willBeActivated,
      willBeDefault,
    } = this.state
    const { getPageCount, page } = paginationUtils

    const pageCount = getPageCount(totalResults)
    const tableData = getTableData({
      data,
      defaultFramework,
      enabledFrameworks,
      isEnableFrameworkSubmitting,
      isSetAsDefaultSubmitting,
      onSetAsDefaultClick: this.handleSetAsDefaultClick,
      onToggleChange: this.handleToggleChange,
      willBeActivated,
      willBeDefault,
    })

    return (
      <CurriculumsView
        data={tableData}
        errorMessages={errorMessages}
        isFetching={isFetching}
        page={page}
        pageCount={pageCount}
        onPageChange={this.handlePageChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseryFrameworkRelationsListState,
  nurseryFrameworkRelationsSelectors,
  nurseryFrameworkRelationsSingleState,
}) => ({
  data: nurseryFrameworkRelationsSelectors.getNurseryFrameworkRelationsListDataState(state),
  errorMessages: appSelectors.getErrorMessages(
    nurseryFrameworkRelationsSingleState,
    nurseryFrameworkRelationsListState,
  ),
  isFetching: appSelectors.getIsFetching(nurseryFrameworkRelationsListState),
  totalResults: appSelectors.getTotalResults(nurseryFrameworkRelationsListState),
})

const enhance = compose(
  withAppService,
  withModalService,
  withNurseryFrameworkRelationsService,
  withPaginationUtils,
  withRouter,
  withSnackbarService,
  connect(mapState),
)

export default enhance(CurriculumsContainer)
