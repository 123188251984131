import * as changelogActions from './changelog/actions'
import * as listActions from './list/actions'
import * as listWorklogByDayActions from './listWorklogByDay/actions'
import * as singleActions from './single/actions'
import * as statistics from './statistics/actions'

export default {
  ...changelogActions,
  ...listActions,
  ...listWorklogByDayActions,
  ...singleActions,
  ...statistics,
}
