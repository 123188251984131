import React from 'react'

import { Callout, ModalBox } from 'components'

import i18n from 'translations'

import RegularUnavailableTimesModalForm from './components/RegularUnavailableTimesModalForm'

const RegularUnavailableTimesModalView = ({
  errorMessages,
  onCloseClick,
  ...rest
}) => (
  <ModalBox
    scrollToBody={false}
    title={i18n.t('module:Modals:RequestableExtraSessions:RegularUnavailableTimes:title')}
    autoHeight
    onCloseClick={onCloseClick}
  >
    <Callout content={errorMessages} error />
    <RegularUnavailableTimesModalForm
      onCloseClick={onCloseClick}
      {...rest}
    />
  </ModalBox>
)

export default RegularUnavailableTimesModalView
