import React from 'react'

import colors, { NEUTRAL_COLOURS } from 'constants/colors'

import { Button, CircleIcon, Typography } from 'components'

import i18n from 'translations'

import { StyledAllocationLabelContainer, StyledDetailAllocationContainer } from './FundingTableStyled'

const FundingTableDetailAllocationCell = ({
  childFundingId,
  childId,
  fundingAllocationLabel,
  hoursAllocated,
  isManualType,
  onAllocateChildFundingClick,
  totalHours,
}) => (
  <StyledDetailAllocationContainer>
    <StyledAllocationLabelContainer>
      <Typography
        align="center"
        background={NEUTRAL_COLOURS.SILVER}
        padding="3px 5px"
        variant="span"
        width={80}
      >
        {fundingAllocationLabel}
      </Typography>
    </StyledAllocationLabelContainer>
    <CircleIcon
      background={isManualType && hoursAllocated !== totalHours ? colors.gold : 'none'}
      icon="exclamation-mark"
      size={18}
    />
    <Typography minWidth={90} variant="span">
      {`${hoursAllocated}h / ${totalHours}h`}
    </Typography>
    {isManualType ? (
      <Button
        hierarchy="tertiary"
        label={i18n.t('module:Finance:Funding:Table:allocateButtonLabel')}
        size="small"
        negativeMargins
        onClick={() => onAllocateChildFundingClick(childId, childFundingId)}
      />
    ) : null}
  </StyledDetailAllocationContainer>
)

export default FundingTableDetailAllocationCell
