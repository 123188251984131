import React, { memo } from 'react'
import isEqual from 'react-fast-compare'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { TimeString, Typography } from 'components'

import { StyledWrapper } from './SessionStyled'

const Session = ({ extraSession, isFinanceV3Enabled }) => {
  const { nurserySession, nurserySessionProduct } = extraSession

  const session = isFinanceV3Enabled ? nurserySessionProduct : nurserySession
  const { endTime, name, startTime } = session || {}

  return (
    <StyledWrapper>
      <Typography ellipsis>
        {name}
      </Typography>
      <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
        <TimeString.Range end={endTime} local={false} start={startTime} />
      </Typography>
    </StyledWrapper>
  )
}

export default memo(Session, isEqual)
