import { combineReducers } from 'redux'

import common, { RouterCommonState } from './common/reducer'

export default combineReducers({
  common,
})

export interface RouterState {
  common: RouterCommonState
}
