import _ from 'lodash'

import React, { useEffect } from 'react'
import { Field, FieldArray, FormSection, formValueSelector, reduxForm } from 'redux-form'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import childSessionContants from 'services/legacy/childSessions/constants'
import { ROLES } from 'constants/security'

import {
  isEndTimeGreaterThanStartTime,
  isRequired,
  isTimeAvailableInHourlySession,
  sessionFieldValidations,
} from 'utils/fieldValidation'
import auth from 'utils/auth'

import ChildSessionsGrid from 'module/Children/Child/components/ChildSessionsGrid'

import { Button, Callout, CircleIcon, EmptyState, Form, Grid, Section, Tooltip } from 'components'

import i18n from 'translations'

import { CHILD_ADD_FORM } from '../../components/ChildForms/constants'

const renderChildSession = (nurserySessionsOptions, onDeleteItemClick) => (
  childSession,
  index,
  fields,
) => (
  <ChildSessionsGrid.Item key={index}>
    <Field
      component={Form.ChildSession}
      dropdownWidth="300px"
      name={childSession}
      options={nurserySessionsOptions}
      validate={[isTimeAvailableInHourlySession, isEndTimeGreaterThanStartTime]}
      showHoursByDefault
      onDeleteClick={onDeleteItemClick(fields, index)}
    />
  </ChildSessionsGrid.Item>
)

const renderChildSessions = ({
  fields,
  nurserySessionsOptions,
  onAddItemClick,
  onDeleteItemClick,
}) => (
  <ChildSessionsGrid>
    {fields.map(renderChildSession(nurserySessionsOptions, onDeleteItemClick))}
    <ChildSessionsGrid.ButtonItem>
      <Button.ActionButton
        label={i18n.t('module:Children:Child:BookingPattern:Sessions:Add:listActionButtonText')}
        onClick={onAddItemClick(fields)}
      />
    </ChildSessionsGrid.ButtonItem>
  </ChildSessionsGrid>
)

const EnrolmentDetails = ({
  SessionCalculationAccess,
  cancelLink,
  enableSessions,
  error,
  handleSubmit,
  isAccessToSessionSection,
  isAllYearRoundSelected,
  isOngoing,
  isSubmitting,
  nurserySessionsOptions,
  onAddSessionItemClick,
  onDeleteSessionItemClick,
  onPreviousStageClick,
  onToggleSessionClick,
  openingDays,
  sessionCalculationOptions,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const renderDay = (day) => (
    <Section key={_.kebabCase(day)} title={_.startCase(day)}>
      <FieldArray
        component={renderChildSessions}
        name={`plans.${day}`}
        props={{
          nurserySessionsOptions,
          onAddItemClick: onAddSessionItemClick,
          onDeleteItemClick: onDeleteSessionItemClick,
        }}
      />
    </Section>
  )

  const renderSessions = () => {
    if (!enableSessions) {
      return (
        <Section title={i18n.t('global:Sessions')}>
          <EmptyState
            actions={(
              <Button
                label={i18n.t('module:Children:Child:BookingPattern:Sessions:Add:listActionButtonText')}
                onClick={onToggleSessionClick}
              />
            )}
            icon="sessions"
            text1={i18n.t('module:Children:Child:Add:EnrolmentDetails:Subsections:Sessions:emptySessionText')}
          />
        </Section>
      )
    }

    return (
      <Section
        actions={(
          <Section.Actions options={[{
            onClick: onToggleSessionClick,
            type: 'delete',
          }]}
          />
        )}
        title={i18n.t('global:Sessions')}
      >
        <FormSection name="session">
          <Section title={i18n.t('module:Children:Child:BookingPattern:Sessions:Add:dateSectionTitle')}>
            <Form.Row
              label={i18n.t('module:Children:Child:Add:EnrolmentDetails:Subsections:Sessions:Form:SessionStart:label')}
              required
            >
              <Form.Row.Item>
                <Field
                  component={Form.DatePicker}
                  name="startDate"
                  placeholder={i18n.t(
                    'module:Children:Child:Add:EnrolmentDetails:Subsections:Sessions:Form:SessionStart:placeholder',
                  )}
                  validate={isRequired}
                  clearable
                />
              </Form.Row.Item>
            </Form.Row>
            <Form.Row
              label={i18n.t('module:Children:Child:Add:EnrolmentDetails:Subsections:Sessions:Form:SessionEnd:label')}
              required
            >
              <Form.Row.Item>
                <Field
                  component={Form.DatePicker}
                  disabled={isOngoing}
                  name="endDate"
                  placeholder={i18n.t(
                    'module:Children:Child:Add:EnrolmentDetails:Subsections:Sessions:Form:SessionEnd:placeholder',
                  )}
                  clearable
                />
              </Form.Row.Item>
              <Form.Row.TextItem text={i18n.t('global:or')} />
              <Form.Row.Item>
                <Field
                  component={Form.Checkbox}
                  label={i18n.t('module:Children:Child:BookingPattern:Sessions:Add:ongoingSession')}
                  name="isOngoing"
                  tooltip={i18n.t('module:Children:Child:BookingPattern:Sessions:Add:ongoingSessionTooltip')}
                />
              </Form.Row.Item>
            </Form.Row>
            <Form.Row
              label={i18n.t('module:Children:Child:BookingPattern:Sessions:Add:attendancePeriod')}
              width={{ field: '100%' }}
            >
              <Grid>
                <Grid.Item width={{ desktop: '300px', mobile: 'calc(100% - 50px)' }}>
                  <Field
                    component={Form.InfiniteDropdowns.Periods}
                    name="attendancePeriod"
                    placeholder={i18n.t('module:Children:Child:BookingPattern:Sessions:Add:attendancePeriod')}
                    validate={[isRequired]}
                  />
                </Grid.Item>
                <Grid.Item alignItems={{ desktop: 'center' }}>
                  <Tooltip
                    title={i18n.t('module:Children:Child:BookingPattern:Sessions:Add:attendancePeriodTooltip')}
                  >
                    <CircleIcon
                      cursor="pointer"
                      icon="info"
                      iconSize={16}
                      size={24}
                    />
                  </Tooltip>
                </Grid.Item>
              </Grid>
            </Form.Row>
          </Section>
          {isAllYearRoundSelected && (
            <Section title={i18n.t('module:Children:Child:BookingPattern:Sessions:Add:allocationPeriodTitle')}>
              <Form.Row>
                <Form.Row.Item>
                  <Field
                    component={Form.RadioGroup}
                    name="allocationPeriod"
                    options={childSessionContants.ALLOCATION_PERIOD_OPTIONS}
                    validate={isRequired}
                  />
                </Form.Row.Item>
              </Form.Row>
            </Section>
          )}
          {SessionCalculationAccess && (
            <Section title={i18n.t('module:Children:Child:BookingPattern:Sessions:Add:sessionCalculationTitle')}>
              <Form.Row>
                <Form.Row.FlexItem>
                  <Field
                    component={Form.RadioGroup}
                    name="sessionCalculation"
                    options={sessionCalculationOptions}
                    validate={isRequired}
                  />
                </Form.Row.FlexItem>
              </Form.Row>
            </Section>
          )}
          {_.map(openingDays, renderDay)}
        </FormSection>
      </Section>
    )
  }

  const renderForm = () => (
    <React.Fragment>
      <Callout content={error} error />
      <Section title={i18n.t('module:Children:Child:Add:EnrolmentDetails:Sections:attendance')}>
        <Form.Row
          label={i18n.t('module:Children:Child:Add:EnrolmentDetails:Subsections:Attendance:Form:StartDate:label')}
          required
        >
          <Form.Row.Item>
            <Field
              component={Form.DatePicker}
              name="startDate"
              placeholder={
                i18n.t('module:Children:Child:Add:EnrolmentDetails:Subsections:Attendance:Form:StartDate:placeholder')
              }
              validate={isRequired}
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row
          label={
            i18n.t('module:Children:Child:Add:EnrolmentDetails:Subsections:Attendance:Form:RegistrationDate:label')
          }
        >
          <Form.Row.Item>
            <Field
              component={Form.DatePicker}
              name="registeredAt"
              placeholder={i18n.t(
                'module:Children:Child:Add:EnrolmentDetails:Subsections:Attendance:Form:RegistrationDate:placeholder',
              )}
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row
          label={i18n.t('module:Children:Child:Add:EnrolmentDetails:Subsections:Attendance:Form:Room:label')}
          required
        >
          <Form.Row.Item>
            <Field
              component={Form.InfiniteDropdowns.Rooms}
              name="nurseryClass"
              placeholder={
                i18n.t('module:Children:Child:Add:EnrolmentDetails:Subsections:Attendance:Form:Room:placeholder')
              }
              validate={isRequired}
            />
          </Form.Row.Item>
        </Form.Row>
      </Section>
      {isAccessToSessionSection && renderSessions()}
    </React.Fragment>
  )

  const renderFooter = () => (
    <Form.FooterActions
      cancelLink={cancelLink}
      isSubmitting={isSubmitting}
      secondary={{
        label: i18n.t('global:Previous'),
        onClick: onPreviousStageClick,
      }}
      submitLabel={i18n.t('global:Next')}
    />
  )

  return (
    <Form onSubmit={handleSubmit}>
      {renderForm()}
      {renderFooter()}
    </Form>
  )
}

const validate = (fields, { enableSessions }) => {
  const errors = {
    session: {},
  }

  if (!fields) {
    return errors
  }

  if (enableSessions) {
    errors.session = { ...sessionFieldValidations(fields?.session) }
  }

  return errors
}

const mapState = (state) => ({
  isAccessToSessionSection: auth.SELECTORS.getIsAuthorised(state, {
    roles: [
      ROLES.ORGANIZATION_DIRECTOR,
      ROLES.ORGANIZATION_NATIONAL_ADMIN,
      ROLES.ORGANIZATION_FINANCE_ADMIN,
      ROLES.ORGANIZATION_LINE_MANAGER,
      ROLES.DEPUTY_MANAGER,
      ROLES.NURSERY_MANAGER,
      ROLES.NURSERY_ADMIN,
      ROLES.SUPER_ADMIN,
    ],
  }),
  isOngoing: formValueSelector(CHILD_ADD_FORM)(state, 'session.isOngoing'),
})

const enhance = compose(
  reduxForm({ destroyOnUnmount: false, form: CHILD_ADD_FORM, validate }),
  connect(mapState),
)

export default enhance(EnrolmentDetails)
