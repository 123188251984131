import { combineReducers } from 'redux'

import bankDetails from './bankDetails/reducer'
import fundingSummaryCalculator from '../../child/fundingSummaryCalculator/reducer'
import changelogs from './changelogs/reducer'
import legacyBankDetails from './legacyBankDetails/reducer'
import birthdaysList from './birthdaysList/reducer'
import dietary from './dietary/reducer'
import files from './files/reducer'
import financeDetails from './financeDetails/reducer'
import list from './list/reducer'
import setup from './setup/reducer'
import single from './single/reducer'

export default combineReducers({
  bankDetails,
  birthdaysList,
  changelogs,
  dietary,
  files,
  financeDetails,
  fundingSummaryCalculator,
  legacyBankDetails,
  list,
  setup,
  single,
})
