import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class FinanceCreditNotesReportApiClient extends ServiceBase {
  getChildren = (params) => this.request({
    path: `/v2/credit_notes/statistics/credit_note?${getQueryStringV2(params)}`,
  })

  getNurseries = (params) => this.request({
    path: `/v2/nurseries/statistics/credit_note?${getQueryStringV2(params)}`,
  })

  getStatistics = (params) => this.request({
    path: `/v2/statistics/credit_note?${getQueryStringV2(params)}`,
  })

  export = (reportType, params = {}) => this.request({
    path: `/v2/invoices/report/${reportType}?${getQueryStringV2(params)}`,
  })
}

export default FinanceCreditNotesReportApiClient
