import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import { PERIODS_DROPDOWN_LIST, PERIODS_DROPDOWN_LIST_FAILED, PERIODS_DROPDOWN_LIST_SUCCESS } from './constants'

export const dropdownList = (
  options: BasicActionOptionsProps,
) => ({ periodsApiClient }) => generateBasicActions.list({
  constants: {
    failed: PERIODS_DROPDOWN_LIST_FAILED,
    init: PERIODS_DROPDOWN_LIST,
    success: PERIODS_DROPDOWN_LIST_SUCCESS,
  },
  options,
  service: periodsApiClient,
  serviceMethod: 'list',
})
