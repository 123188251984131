import React from 'react'
import { withRouter as withRouterOriginal } from 'react-router'
import { compose } from 'recompose'

import { WrappedComponentType } from 'constants/types'

export interface withRouterProps {
  location: any
  navigate: any
  params: any
  router: any
  routes: any
}

/**
 * This is custom router service to replace original `withRouter` from redux-router-v3.
 * In newest v6 version of react-router original hook does not exist
 * and to support old components we need this hook.
 *
 * This is added as first part of refactoring react-router.
 */
const withRouter = <P extends withRouterProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const Component = ({ router, ...others }) => (
    <WrappedComponent
      location={router.location || {}}
      navigate={router.push}
      params={router.params || {}}
      router={router}
      routes={router.routes}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  const enhance = compose(withRouterOriginal)

  return enhance(Component)
}

export default withRouter
