export const hasMoreRecords = (meta) => {
  if (!meta) {
    return false
  }

  const {
    limit,
    page_results: pageResults,
    start,
    total_results: totalResults,
  } = meta

  return ((start - 1) * limit) + pageResults < totalResults
}
