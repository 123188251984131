import React from 'react'

import { ChildrenSlider } from 'components'

import BottleTabForm from './components/BottleTabForm'
import { StyledContainer } from './BottleTabStyled'
import { StyledSliderContainer } from '../DailyDiaryRecordModalStyled'

const BottleTabView = ({
  initialValues,
  isSubmitting,
  onCancel,
  onSubmit,
  selectedChildren,
}) => (
  <StyledContainer>
    <StyledSliderContainer>
      <ChildrenSlider
        childrenList={selectedChildren || []}
      />
    </StyledSliderContainer>
    <BottleTabForm
      initialValues={initialValues}
      isSubmitting={isSubmitting}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  </StyledContainer>
)

export default BottleTabView
