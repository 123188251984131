import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class FinanceFundingReportApiClient extends ServiceBase {
  getChildren = (params) => this.request({
    path: `/v2/child_fundings?${getQueryStringV2(params)}`,
  })

  getNurseries = (params) => this.request({
    path: `/v2/fundings/statistics/funding?${getQueryStringV2(params)}`,
  })

  getStatistics = (params) => this.request({
    path: `/v2/statistics/funding_endpoint?${getQueryStringV2(params)}`,
  })

  export = (params) => this.request({
    path: `/v2/child_fundings/report/funding?${getQueryStringV2(params)}`,
  })
}

export default FinanceFundingReportApiClient
