import _ from 'lodash'

import { createSelector } from 'reselect'

import { FRAMEWORK_GROUPED_BY } from 'services/legacy/frameworks/constants'

const getFrameworks = (state) => state.frameworks

export const getFrameworksSingleState = createSelector([getFrameworks], (state) => state.single)

export const getFrameworksSingleDataState = createSelector([getFrameworksSingleState], (state) => state.data)

export const getFormattedFrameworkEvents = (frameworkEvents, oldResponse) => {
  const filteredEvents = _.filter(frameworkEvents, ({ events }) => events.length)
  let response = _.cloneDeep(oldResponse)

  _.each(filteredEvents, (item) => {
    response = _.map(response, (framework) => {
      if (framework.id === item.framework.id) {
        _.each(item.events, (event) => {
          if (FRAMEWORK_GROUPED_BY.AREA === event.type) {
            // eslint-disable-next-line no-param-reassign
            framework.areas = _.map(framework.areas, (area) => {
              if (area.id === event.frameworkElement.id) {
                const newArea = _.cloneDeep(area)
                newArea.value = event.progressLevel?.id

                return newArea
              }

              return area
            })
          }

          if (FRAMEWORK_GROUPED_BY.GROUP === event.type) {
            // eslint-disable-next-line no-param-reassign
            framework.areas = _.map(framework.areas, (area) => {
              const newArea = _.cloneDeep(area)

              newArea.groups = _.map(newArea.groups, (group) => {
                if (group.id === event.frameworkElement.id) {
                  const newGroup = _.cloneDeep(group)
                  newGroup.value = event.progressLevel?.id

                  return newGroup
                }

                return group
              })

              return newArea
            })
          }

          if (FRAMEWORK_GROUPED_BY.ITEM === event.type) {
            // eslint-disable-next-line no-param-reassign
            framework.areas = _.map(framework.areas, (area) => {
              const newArea = _.cloneDeep(area)

              newArea.groups = _.map(newArea.groups, (group) => {
                const newGroup = _.cloneDeep(group)

                newGroup.categories = _.map(newGroup.categories, (category) => {
                  const newCategory = _.cloneDeep(category)

                  newCategory.items = _.map(newCategory.items, (frameworkItem) => {
                    const newItem = _.cloneDeep(frameworkItem)

                    if (newItem.id === event.frameworkElement.id) {
                      newItem.value = event.progressLevel?.id
                    }

                    return newItem
                  })

                  return newCategory
                })

                return newGroup
              })

              return newArea
            })
          }
        })
      }

      return framework
    })
  })

  response = _.map(response, (element) => {
    const newElement = _.cloneDeep(element)
    const groupBy = element.structure.observationLinks?.default

    if (FRAMEWORK_GROUPED_BY.AREA === groupBy) {
      newElement.areas = _.map(newElement.areas, (area) => {
        const newArea = _.cloneDeep(area)
        delete newArea.groups

        return newArea
      })

      newElement.areas = _.filter(newElement.areas, ({ value }) => value)
    }

    if (FRAMEWORK_GROUPED_BY.GROUP === groupBy) {
      newElement.areas = _.map(newElement.areas, (area) => {
        const newArea = _.cloneDeep(area)

        newArea.groups = _.map(newArea.groups, (group) => {
          const newGroup = _.cloneDeep(group)
          delete newGroup.categories

          return newGroup
        })

        newArea.groups = _.filter(newArea.groups, ({ value }) => value)

        return newArea
      })

      newElement.areas = _.filter(newElement.areas, ({ groups }) => groups.length)
    }

    if (FRAMEWORK_GROUPED_BY.ITEM === groupBy) {
      newElement.areas = _.map(newElement.areas, (area) => {
        const newArea = _.cloneDeep(area)

        newArea.groups = _.map(newArea.groups, (group) => {
          const newGroup = _.cloneDeep(group)

          newGroup.categories = _.map(newGroup.categories, (category) => {
            const newCategory = _.cloneDeep(category)

            newCategory.items = _.filter(newCategory.items, ({ value }) => value)

            return newCategory
          })

          newGroup.categories = _.filter(newGroup.categories, ({ items }) => items.length)

          return newGroup
        })

        newArea.groups = _.filter(newArea.groups, ({ categories }) => categories.length)

        return newArea
      })

      newElement.areas = _.filter(newElement.areas, ({ groups }) => groups.length)
    }

    return newElement
  })

  response = _.filter(response, ({ areas }) => areas.length)

  return response
}
