import styled from 'styled-components'

export const StyledStaffingFlag = styled.div`
  margin: -10px;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  
  ${({ arrowIsHidden }) => !arrowIsHidden && `
    cursor: pointer;
  `}
`

export const StyledSectionContainer = styled.div`
  display: grid;
  grid-gap: 15px;
  
  ${({ dayMode }) => dayMode && `
    grid-template-columns: 160px 160px;
  `}
`
