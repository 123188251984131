import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledTitleContainer = styled.div`
  display: flex;
  font-size: 18px;
  padding: 10px 0 30px 0;
`

export const StyledTitleHighlight = styled.span`
  font-weight: 600;
  ${({ color }) => color && `color: ${color};`}
`

export const StyledTable = styled.table`
  padding: 10px 0;
  
  & > tr > th {
    text-align: left;
  }
`
