import {
  CLEAR_NEWSLETTER_RECIPIENT_SINGLE,
  CREATE_NEWSLETTER_RECIPIENT,
  CREATE_NEWSLETTER_RECIPIENT_FAILED,
  CREATE_NEWSLETTER_RECIPIENT_SUCCESS,
  GET_NEWSLETTER_RECIPIENT,
  GET_NEWSLETTER_RECIPIENT_FAILED,
  GET_NEWSLETTER_RECIPIENT_SUCCESS,
  UPDATE_NEWSLETTER_RECIPIENT,
  UPDATE_NEWSLETTER_RECIPIENT_FAILED,
  UPDATE_NEWSLETTER_RECIPIENT_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case GET_NEWSLETTER_RECIPIENT:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_NEWSLETTER_RECIPIENT_SUCCESS:
      return { ...state, data: payload.data, isFetching: false }
    case GET_NEWSLETTER_RECIPIENT_FAILED:
      return { ...state, error, isFetching: false }
    case CREATE_NEWSLETTER_RECIPIENT:
      return { ...state, error: initialState.error, isSubmitting: true }
    case CREATE_NEWSLETTER_RECIPIENT_SUCCESS:
      return { ...state, data: payload.data, isSubmitting: false }
    case CREATE_NEWSLETTER_RECIPIENT_FAILED:
      return { ...state, error, isSubmitting: false }
    case UPDATE_NEWSLETTER_RECIPIENT:
      return { ...state, error: initialState.error, isSubmitting: true }
    case UPDATE_NEWSLETTER_RECIPIENT_SUCCESS:
      return { ...state, data: payload.data, isSubmitting: false }
    case UPDATE_NEWSLETTER_RECIPIENT_FAILED:
      return { ...state, error, isSubmitting: false }
    case CLEAR_NEWSLETTER_RECIPIENT_SINGLE:
      return initialState
    default:
      return state
  }
}
