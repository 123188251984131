import React from 'react'

import { Page } from 'components'

// eslint-disable-next-line import/no-unresolved
import ParentInboxNoAccessPlaceholderImage from 'assets/images/home-observations-placeholder.png'

import { StyledWrapper } from './ParentInboxNoAccessPlaceholderStyled'

const ParentInboxNoAccessPlaceholderView = () => (
  <Page>
    <Page.Content>
      <StyledWrapper>
        <img alt="" src={ParentInboxNoAccessPlaceholderImage} />
      </StyledWrapper>
    </Page.Content>
  </Page>
)

export default ParentInboxNoAccessPlaceholderView
