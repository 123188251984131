import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'recompose'

import { isRequired } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'

import { Banner, CircleIcon, Currency, Form, Space, Tooltip, Typography } from 'components'

import i18n from 'translations'

export const FORM_NAME = 'ChildExtraItemsAddForm'

const ChildExtraItemsAddForm = ({
  childId,
  cost,
  description,
  disableEndDate,
  feeCalculationOptions,
  formValues,
  frequencyOptions,
  handleSubmit,
  isEdit,
  isLoading,
  onLoadMoreExtraItems,
  onSubmit,
  showDateRange,
  showFeeCalculation,
  showQuantity,
}) => {
  const startDateLabel = showDateRange ? 'Start Date' : 'Date'

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Row label="Extra item">
        <Form.Row.FlexItem flex="0 0 300px">
          <Field
            component={Form.Select}
            disabled={isEdit}
            loadOptions={onLoadMoreExtraItems}
            name="extraItem"
            placeholder="Extra item"
            rawValue={formValues?.extraItem}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label="Frequency">
        <Form.Row.FlexItem flex="0 0 200px">
          <Field
            component={Form.Select}
            disabled={isEdit}
            name="type"
            options={frequencyOptions}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      {showQuantity && (
        <Form.Row label="Quantity">
          <Form.Row.FlexItem flex="0 0 100px">
            <Field
              component={Form.TextField}
              disabled={isEdit}
              min="1"
              name="quantity"
              type="number"
              validate={isRequired}
            />
          </Form.Row.FlexItem>
        </Form.Row>
      )}
      <Form.Row label>
        <Form.Row.FlexItem flex="0 0 150px">
          <Typography bold>
            {i18n.t('global:Cost')}
            <Space margin="5px" inline />
            <Typography variant="span" bold tertiary>
              <Currency value={cost} />
            </Typography>
          </Typography>
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={startDateLabel}>
        <Form.Row.FlexItem flex="0 0 100px">
          <Field
            component={Form.DatePicker}
            disabled={isEdit}
            name="startDate"
            placeholder={startDateLabel}
            validate={isRequired}
            clearable
          />
        </Form.Row.FlexItem>
      </Form.Row>
      {showDateRange && (
        <Form.Row label="End Date">
          <Form.Row.Item>
            <Field
              component={Form.DatePicker}
              disabled={isEdit || disableEndDate}
              name="endDate"
              placeholder="End Date"
              clearable
            />
          </Form.Row.Item>
          <Form.Row.TextItem text="or" />
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              disabled={isEdit}
              label="Ongoing"
              name="ongoing"
            />
          </Form.Row.Item>
        </Form.Row>
      )}
      {description && (
        <Form.Row label>
          <Form.Row.Item>
            <Banner.Info>
              {description}
            </Banner.Info>
          </Form.Row.Item>
        </Form.Row>
      )}
      <Form.Row label={i18n.t('module:Children:Child:BookingPattern:ExtraItems:Add:Form:AttendancePeriod:label')}>
        <Form.Row.FlexItem flex="0 0 300px">
          <Field
            component={Form.InfiniteDropdowns.Periods}
            disabled={isEdit}
            name="attendancePeriod"
            placeholder={
              i18n.t('module:Children:Child:BookingPattern:ExtraItems:Add:Form:AttendancePeriod:placeholder')
            }
            validate={isRequired}
          />
        </Form.Row.FlexItem>
        <Space margin="0 10px 0 0" />
        <Form.Row.Item>
          <Tooltip
            placement="bottom-center"
            title={i18n.t('module:Children:Child:BookingPattern:ExtraItems:Add:Form:AttendancePeriod:tooltip')}
            inline
          >
            <CircleIcon
              cursor="pointer"
              icon="info"
              iconSize={12}
              size={24}
            />
          </Tooltip>
        </Form.Row.Item>
      </Form.Row>
      {showFeeCalculation && (
        <Form.Row label="Fee calculation">
          <Form.Row.FlexItem flex="0 0 300px">
            <Field
              component={Form.Select}
              disabled={isEdit}
              name="feeCalculations"
              options={feeCalculationOptions}
              placeholder="Fee calculation"
              validate={isRequired}
            />
          </Form.Row.FlexItem>
          <Form.Row.Item>
            <Tooltip title="The average weeks and days are set from the invoice settings">
              <CircleIcon
                cursor="pointer"
                icon="info"
                iconSize={16}
                size={24}
              />
            </Tooltip>
          </Form.Row.Item>
        </Form.Row>
      )}
      <Form.FooterActions
        cancelLink={generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_ITEMS', { childId })}
        isSubmitting={isLoading}
        submitDisabled={isEdit}
        submitLabel={i18n.t('global:Save')}
      />
    </Form>
  )
}

const enhance = compose(reduxForm({ form: 'ChildExtraItemsAddForm' }))

export default enhance(ChildExtraItemsAddForm)
