import { ROLES } from 'constants/security'

const SettingsSessionsWrapper = ({ children }) => children

SettingsSessionsWrapper.authParams = {
  roles: [
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
  ],
}

export default SettingsSessionsWrapper
