export const GET_MEMBERSHIP_FILES_LIST = 'GET_MEMBERSHIP_FILES_LIST'
export const GET_MEMBERSHIP_FILES_LIST_SUCCESS = 'GET_MEMBERSHIP_FILES_LIST_SUCCESS'
export const GET_MEMBERSHIP_FILES_LIST_FAILED = 'GET_MEMBERSHIP_FILES_LIST_FAILED'

export const GET_MEMBERSHIP_FILES_TOTAL_RESULTS_LIST = 'GET_MEMBERSHIP_FILES_TOTAL_RESULTS_LIST'
export const GET_MEMBERSHIP_FILES_TOTAL_RESULTS_LIST_SUCCESS = 'GET_MEMBERSHIP_FILES_TOTAL_RESULTS_LIST_SUCCESS'
export const GET_MEMBERSHIP_FILES_TOTAL_RESULTS_LIST_FAILED = 'GET_MEMBERSHIP_FILES_TOTAL_RESULTS_LIST_FAILED'

export const CLEAR_MEMBERSHIP_FILES_LIST = 'CLEAR_MEMBERSHIP_FILES_LIST'

export const ADD_MANUALLY_MEMBERSHIP_FILES = 'ADD_MANUALLY_MEMBERSHIP_FILES'

export const REMOVE_FILE_FROM_LIST = 'MEMBERSHIPS/REMOVE_FILE_FROM_LIST'
