import _ from 'lodash'

import React, { useEffect, useMemo } from 'react'
import { Field, FieldArray, FormSection, formValueSelector, reduxForm } from 'redux-form'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { GENDER_DROPDOWN, GENDER_DROPDOWN_WITH_OTHER } from 'services/legacy/child/constants'
import { getCost, getFinanceV3Cost } from 'services/legacy/childAdhocSessions/list/selectors'
import { ENQUIRY_REASON_LABELS, SOURCE_LABELS } from 'services/legacy/enquiries/constants'

import { isEndTimeGreaterThanStartTime, isRequired } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'
import { scrollToElement } from 'utils/dom'

import { withNurserySessionsService } from 'services/nurserySessions'

import { Button, Currency, Form, GridLayout, Typography } from 'components'

import i18n from 'translations'

import ChildSessionsGrid from './ChildSessionsGrid'

export const ENQUIRIES_ADD_FORM = 'ENQUIRIES_ADD_FORM'

const renderChildSession = (nurserySessionsOptions, daySessionValues) => (
  childSession,
  index,
  fields,
) => (
  <ChildSessionsGrid.Item key={index}>
    <Field
      component={Form.ChildSession}
      dropdownWidth="300px"
      name={childSession}
      options={nurserySessionsOptions}
      validate={isEndTimeGreaterThanStartTime}
      showHoursByDefault
      onDeleteClick={() => fields.remove(index)}
    />
    <Typography>
      {i18n.t('global:Value')}
      {' '}
      <Currency value={daySessionValues[index]} />
    </Typography>
  </ChildSessionsGrid.Item>
)

const renderChildSessions = ({
  daySessionValues,
  fields,
  nurserySessionsOptions,
}) => (
  <ChildSessionsGrid>
    {fields.map(renderChildSession(nurserySessionsOptions, daySessionValues))}
    <ChildSessionsGrid.ButtonItem>
      <Button.ActionButton
        label={i18n.t('module:Children:Child:BookingPattern:Sessions:Add:listActionButtonText')}
        onClick={() => fields.push({})}
      />
    </ChildSessionsGrid.ButtonItem>
  </ChildSessionsGrid>
)

const AddForm = ({
  OccupancyEnabled,
  birthDate,
  enquiryId,
  enquiryReason,
  handleSubmit,
  isEditMode,
  isFinanceV3Enabled,
  isSubmitting,
  nurserySessions,
  nurserySessionsOptions,
  openingDays,
  section,
  sessionPlans,
  source,
  startDate,
}) => {
  const { sessionValues, totalValue } = useMemo(() => {
    const values = {}

    _.each(sessionPlans, (plans, key) => {
      values[key] = []

      if (birthDate && startDate) {
        _.each(plans, ({ endTime, sessionId, startTime }) => {
          if (sessionId) {
            const { priceChanges, value } = sessionId

            const nurserySession = _.find(nurserySessions, { id: value })

            const cost = isFinanceV3Enabled
              ? getFinanceV3Cost({
                birthDate,
                endTime,
                isHourly: sessionId.isHourly,
                priceChanges,
                startDate,
                startTime,
              })
              : getCost(birthDate, startDate, nurserySession, startTime, endTime)

            values[key].push(cost)
          }
        })
      }
    })

    const total = _.reduce(values, (sessionTotal, dayValue) => sessionTotal + _.sum(dayValue), 0)

    return { sessionValues: values, totalValue: total }
  }, [sessionPlans, startDate, birthDate, nurserySessions])

  useEffect(() => {
    scrollToElement(document.getElementById(section))
  }, [section])

  const renderTitle = (id, title) => (
    <div id={id}>
      <Typography margin="20px 0" bold>
        {title}
      </Typography>
    </div>
  )

  const renderContactFields = () => (
    <FormSection name="data.contact">
      {renderTitle('contact', i18n.t('module:Enquiries:List:Add:ContactInformation:title'))}
      <GridLayout>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item>
            <Form.Row
              label={i18n.t('module:Enquiries:List:Add:ContactInformation:FirstName:label')}
              required
              verticalLabel
            >
              <Form.Row.FlexItem mobileFull>
                <Field
                  component={Form.TextField}
                  name="firstName"
                  placeholder={i18n.t('module:Enquiries:List:Add:ContactInformation:FirstName:placeholder')}
                  validate={isRequired}
                />
              </Form.Row.FlexItem>
            </Form.Row>
          </GridLayout.Item>
          <GridLayout.Item>
            <Form.Row
              label={i18n.t('module:Enquiries:List:Add:ContactInformation:Surname:label')}
              required
              verticalLabel
            >
              <Form.Row.FlexItem mobileFull>
                <Field
                  component={Form.TextField}
                  name="surname"
                  placeholder={i18n.t('module:Enquiries:List:Add:ContactInformation:Surname:placeholder')}
                  validate={isRequired}
                />
              </Form.Row.FlexItem>
            </Form.Row>
          </GridLayout.Item>
        </GridLayout.Group>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item>
            <Form.Row
              label={i18n.t('module:Enquiries:List:Add:ContactInformation:Gender:label')}
              verticalLabel
            >
              <Form.Row.FlexItem mobileFull>
                <Field
                  component={Form.Select}
                  name="gender"
                  options={GENDER_DROPDOWN_WITH_OTHER}
                  placeholder={i18n.t('module:Enquiries:List:Add:ContactInformation:Gender:placeholder')}
                />
              </Form.Row.FlexItem>
            </Form.Row>
          </GridLayout.Item>
          <GridLayout.Item>
            <Form.Row
              label={i18n.t('module:Enquiries:List:Add:ContactInformation:RelationToChild:label')}
              verticalLabel
            >
              <Form.Row.FlexItem mobileFull>
                <Field
                  component={Form.InfiniteDropdowns.Relations}
                  name="relation"
                  placeholder={i18n.t('module:Enquiries:List:Add:ContactInformation:RelationToChild:placeholder')}
                  creatable
                />
              </Form.Row.FlexItem>
            </Form.Row>
          </GridLayout.Item>
        </GridLayout.Group>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item>
            <Form.Row
              label={i18n.t('module:Enquiries:List:Add:ContactInformation:Phone:label')}
              verticalLabel
            >
              <Form.Row.FlexItem mobileFull>
                <Field
                  component={Form.TextField}
                  name="telephone"
                  placeholder={i18n.t('module:Enquiries:List:Add:ContactInformation:Phone:placeholder')}
                />
              </Form.Row.FlexItem>
            </Form.Row>
          </GridLayout.Item>
          <GridLayout.Item>
            <Form.Row
              label={i18n.t('module:Enquiries:List:Add:ContactInformation:Email:label')}
              verticalLabel
            >
              <Form.Row.FlexItem mobileFull>
                <Field
                  component={Form.TextField}
                  name="email"
                  placeholder={i18n.t('module:Enquiries:List:Add:ContactInformation:Email:placeholder')}
                />
              </Form.Row.FlexItem>
            </Form.Row>
          </GridLayout.Item>
        </GridLayout.Group>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item>
            <Form.Row
              label={i18n.t('module:Enquiries:List:Add:ContactInformation:Address:label')}
              verticalLabel
            >
              <Form.Row.FlexItem mobileFull>
                <Field
                  component={Form.TextField}
                  name="address"
                  placeholder={i18n.t('module:Enquiries:List:Add:ContactInformation:Address:placeholder')}
                />
              </Form.Row.FlexItem>
            </Form.Row>
          </GridLayout.Item>
          <GridLayout.Item>
            <Form.Row
              label={i18n.t('module:Enquiries:List:Add:ContactInformation:Postcode:label')}
              verticalLabel
            >
              <Form.Row.FlexItem mobileFull>
                <Field
                  component={Form.TextField}
                  name="postCode"
                  placeholder={i18n.t('module:Enquiries:List:Add:ContactInformation:Postcode:placeholder')}
                />
              </Form.Row.FlexItem>
            </Form.Row>
          </GridLayout.Item>
        </GridLayout.Group>
      </GridLayout>
    </FormSection>
  )

  const renderChildFields = () => (
    <FormSection name="data.child">
      {renderTitle('child', i18n.t('module:Enquiries:List:Add:ChildInformation:title'))}
      <GridLayout>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item>
            <Form.Row
              label={i18n.t('module:Enquiries:List:Add:ChildInformation:FirstName:label')}
              required
              verticalLabel
            >
              <Form.Row.FlexItem mobileFull>
                <Field
                  component={Form.TextField}
                  name="firstName"
                  placeholder={i18n.t('module:Enquiries:List:Add:ChildInformation:FirstName:placeholder')}
                  validate={isRequired}
                />
              </Form.Row.FlexItem>
            </Form.Row>
          </GridLayout.Item>
          <GridLayout.Item>
            <Form.Row
              label={i18n.t('module:Enquiries:List:Add:ChildInformation:Surname:label')}
              required
              verticalLabel
            >
              <Form.Row.FlexItem mobileFull>
                <Field
                  component={Form.TextField}
                  name="surname"
                  placeholder={i18n.t('module:Enquiries:List:Add:ChildInformation:Surname:placeholder')}
                  validate={isRequired}
                />
              </Form.Row.FlexItem>
            </Form.Row>
          </GridLayout.Item>
        </GridLayout.Group>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item>
            <Form.Row
              label={i18n.t('module:Enquiries:List:Add:ChildInformation:Gender:label')}
              verticalLabel
            >
              <Form.Row.FlexItem mobileFull>
                <Field
                  component={Form.Select}
                  name="gender"
                  options={GENDER_DROPDOWN}
                  placeholder={i18n.t('module:Enquiries:List:Add:ChildInformation:Gender:placeholder')}
                />
              </Form.Row.FlexItem>
            </Form.Row>
          </GridLayout.Item>
          <GridLayout.Item>
            <Form.Row
              label={i18n.t('module:Enquiries:List:Add:ChildInformation:DateOfBirth:label')}
              required
              verticalLabel
            >
              <Form.Row.Item mobileFull>
                <Field
                  component={Form.DatePicker}
                  name="birthDate"
                  placeholder={i18n.t('module:Enquiries:List:Add:BookingInformation:StartDate:placeholder')}
                  validate={isRequired}
                />
              </Form.Row.Item>
            </Form.Row>
          </GridLayout.Item>
        </GridLayout.Group>
      </GridLayout>
    </FormSection>
  )

  const renderEnquiryViewingFields = () => (
    <React.Fragment>
      {renderTitle('viewing', i18n.t('module:Enquiries:List:Add:ViewingInformation:title'))}
      <GridLayout>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item>
            <Form.Row
              label={i18n.t('module:Enquiries:List:Add:ViewingInformation:ViewingDate:label')}
              verticalLabel
            >
              <Form.Row.Item mobileFull>
                <Field
                  component={Form.DatePicker}
                  name="showAroundDate"
                  placeholder={i18n.t('module:Enquiries:List:Add:ViewingInformation:ViewingDate:placeholder')}
                  clearable
                />
              </Form.Row.Item>
            </Form.Row>
            <Form.Row
              label={i18n.t('module:Enquiries:List:Add:ViewingInformation:ViewingTime:label')}
              verticalLabel
            >
              <Form.Row.Item mobileFull>
                <Field
                  component={Form.TimePicker}
                  name="showAroundTime"
                />
              </Form.Row.Item>
            </Form.Row>
          </GridLayout.Item>
          <GridLayout.Item>
            <Form.Row
              label={i18n.t('module:Enquiries:List:Add:ViewingInformation:Staff:label')}
              verticalLabel
            >
              <Form.Row.FlexItem mobileFull>
                <Field
                  component={Form.InfiniteDropdowns.Memberships}
                  name="assignedPerson"
                  placeholder={i18n.t('module:Enquiries:List:Add:ViewingInformation:Staff:placeholder')}
                  searchable
                />
              </Form.Row.FlexItem>
            </Form.Row>
          </GridLayout.Item>
        </GridLayout.Group>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item>
            <Form.Row
              label={i18n.t('module:Enquiries:List:Add:ViewingInformation:Notes:label')}
              verticalLabel
            >
              <Form.Row.FlexItem mobileFull>
                <Field
                  component={Form.TextAreaField}
                  name="note"
                  placeholder={i18n.t('module:Enquiries:List:Add:ViewingInformation:Notes:placeholder')}
                />
              </Form.Row.FlexItem>
            </Form.Row>
          </GridLayout.Item>
          <GridLayout.Item />
        </GridLayout.Group>
      </GridLayout>
    </React.Fragment>
  )

  const renderBookingFields = () => (
    <React.Fragment>
      {renderTitle('booking', i18n.t('module:Enquiries:List:Add:BookingInformation:title'))}
      {OccupancyEnabled && (
        <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} margin="0 0 15px 0">
          View the Occupancy Planner to check available spaces.
        </Typography>
      )}
      <GridLayout>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item>
            <Form.Row
              label={i18n.t('module:Enquiries:List:Add:BookingInformation:Room:label')}
              verticalLabel
            >
              <Form.Row.FlexItem mobileFull>
                <Field
                  component={Form.InfiniteDropdowns.Rooms}
                  name="nurseryClass"
                  placeholder={i18n.t('module:Enquiries:List:Add:BookingInformation:Room:placeholder')}
                />
              </Form.Row.FlexItem>
            </Form.Row>
          </GridLayout.Item>
          <GridLayout.Item>
            <Form.Row
              label={i18n.t('module:Enquiries:List:Add:BookingInformation:StartDate:label')}
              verticalLabel
            >
              <Form.Row.Item mobileFull>
                <Field
                  component={Form.DatePicker}
                  name="startDate"
                  placeholder={i18n.t('module:Enquiries:List:Add:BookingInformation:StartDate:placeholder')}
                />
              </Form.Row.Item>
            </Form.Row>
          </GridLayout.Item>
        </GridLayout.Group>
      </GridLayout>
    </React.Fragment>
  )

  const renderDay = (day) => (
    <React.Fragment key={_.kebabCase(day)}>
      {renderTitle(null, _.startCase(day))}
      <FieldArray
        component={renderChildSessions}
        name={`sessionPlans.${day}`}
        props={{
          daySessionValues: sessionValues[day],
          nurserySessionsOptions,
        }}
      />
    </React.Fragment>
  )

  const renderSessionFields = () => (
    <React.Fragment>
      <Typography id="session" margin="20px 0" bold>
        {i18n.t('module:Enquiries:List:Add:SessionDetails:title')}
      </Typography>
      {_.map(openingDays, renderDay)}
      <Typography align="right" fontSize={24} margin="20px 0">
        {i18n.t('module:Enquiries:List:Add:SessionDetails:weeklyValueLabel')}
        {' '}
        <Typography fontSize={24} variant="span" bold>
          <Currency value={totalValue || 0} />
        </Typography>
      </Typography>
    </React.Fragment>
  )

  const renderEnquiryDetailFields = () => (
    <React.Fragment>
      {renderTitle('enquiry', i18n.t('module:Enquiries:List:Add:EnquiryDetails:title'))}
      <GridLayout>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item>
            <Form.Row
              label={i18n.t('module:Enquiries:List:Add:EnquiryDetails:EnquiryDate:label')}
              required
              verticalLabel
            >
              <Form.Row.Item mobileFull>
                <Field
                  component={Form.DatePicker}
                  name="enquiryDate"
                  placeholder={i18n.t('module:Enquiries:List:Add:EnquiryDetails:EnquiryDate:placeholder')}
                  validate={isRequired}
                />
              </Form.Row.Item>
            </Form.Row>
          </GridLayout.Item>
          <GridLayout.Item>
            <Form.Row
              label={i18n.t('module:Enquiries:List:Add:EnquiryDetails:OtherConsideredNurseries:label')}
              verticalLabel
            >
              <Form.Row.FlexItem mobileFull>
                <Field
                  component={Form.TextField}
                  name="data.otherConsideredNurseries"
                  placeholder={i18n.t('module:Enquiries:List:Add:EnquiryDetails:OtherConsideredNurseries:placeholder')}
                />
              </Form.Row.FlexItem>
            </Form.Row>
          </GridLayout.Item>
        </GridLayout.Group>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item>
            <Form.Row
              label={i18n.t('module:Enquiries:List:Add:EnquiryDetails:EnquiryReason:label')}
              verticalLabel
            >
              <Form.Row.FlexItem mobileFull>
                <Field
                  component={Form.InfiniteDropdowns.EnquirySettings}
                  name="data.enquiryReason"
                  placeholder={i18n.t('module:Enquiries:List:Add:EnquiryDetails:EnquiryReason:placeholder')}
                  type="reason"
                  bodyTarget
                />
              </Form.Row.FlexItem>
            </Form.Row>
          </GridLayout.Item>
          <GridLayout.Item>
            <Form.Row
              label={i18n.t('module:Enquiries:List:Add:EnquiryDetails:Source:label')}
              verticalLabel
            >
              <Form.Row.FlexItem mobileFull>
                <Field
                  component={Form.InfiniteDropdowns.EnquirySettings}
                  name="data.source"
                  placeholder={i18n.t('module:Enquiries:List:Add:EnquiryDetails:Source:placeholder')}
                  type="source"
                  bodyTarget
                />
              </Form.Row.FlexItem>
            </Form.Row>
          </GridLayout.Item>
        </GridLayout.Group>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item>
            {ENQUIRY_REASON_LABELS.Other === enquiryReason?.label && (
              <Form.Row
                label={i18n.t('module:Enquiries:List:Add:EnquiryDetails:OtherEnquiryReason:label')}
                verticalLabel
              >
                <Form.Row.FlexItem mobileFull>
                  <Field
                    component={Form.TextField}
                    name="data.enquiryReasonOther"
                    placeholder={i18n.t('module:Enquiries:List:Add:EnquiryDetails:OtherEnquiryReason:placeholder')}
                  />
                </Form.Row.FlexItem>
              </Form.Row>
            )}
          </GridLayout.Item>
          <GridLayout.Item>
            {SOURCE_LABELS.Other === source?.label && (
              <Form.Row
                label={i18n.t('module:Enquiries:List:Add:EnquiryDetails:OtherSource:label')}
                verticalLabel
              >
                <Form.Row.FlexItem mobileFull>
                  <Field
                    component={Form.TextField}
                    name="data.sourceOther"
                    placeholder={i18n.t('module:Enquiries:List:Add:EnquiryDetails:OtherSource:placeholder')}
                  />
                </Form.Row.FlexItem>
              </Form.Row>
            )}
          </GridLayout.Item>
        </GridLayout.Group>
      </GridLayout>
    </React.Fragment>
  )

  return (
    <Form onSubmit={handleSubmit}>
      {renderContactFields()}
      {renderChildFields()}
      {!isEditMode && renderEnquiryViewingFields()}
      {renderBookingFields()}
      {renderSessionFields()}
      {renderEnquiryDetailFields()}
      <Form.StickyFooter
        cancelLink={isEditMode ? generateRoute('ENQUIRIES.DETAIL', { enquiryId }) : generateRoute('ENQUIRIES.LIST')}
        isSubmitting={isSubmitting}
        submitLabel={i18n.t(`module:Enquiries:List:Add:${isEditMode ? 'updateLabel' : 'saveLabel'}`)}
      />
    </Form>
  )
}

const mapState = (state, { nurserySessionsSelectors }) => ({
  birthDate: formValueSelector(ENQUIRIES_ADD_FORM)(state, 'data.child.birthDate'),
  enquiryReason: formValueSelector(ENQUIRIES_ADD_FORM)(state, 'data.enquiryReason'),
  nurserySessions: nurserySessionsSelectors.getNurserySessionsListData(state),
  sessionPlans: formValueSelector(ENQUIRIES_ADD_FORM)(state, 'sessionPlans'),
  source: formValueSelector(ENQUIRIES_ADD_FORM)(state, 'data.source'),
  startDate: formValueSelector(ENQUIRIES_ADD_FORM)(state, 'startDate'),
})

const validate = (fields) => {
  const errors = {}

  const { assignedPerson, note, showAroundDate, showAroundTime } = fields

  if (showAroundDate || showAroundDate || assignedPerson || note) {
    if (!showAroundDate) {
      errors.showAroundDate = i18n.t('utils:Validation:isRequired')
    }

    if (!showAroundTime) {
      errors.showAroundTime = i18n.t('utils:Validation:isRequired')
    }
  }

  return errors
}

const enhance = compose(
  withNurserySessionsService,
  reduxForm({ form: ENQUIRIES_ADD_FORM, validate }),
  connect(mapState),
)

export default enhance(AddForm)
