/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'

import { withNurseriesService, withNurseriesServiceProps } from 'services/nurseries'
import { withNurseryContextService, withNurseryContextServiceProps } from 'services/nurseryContext'
import { withRoomsService } from 'services/legacy/rooms'
import { withAppService, withAppServiceProps } from 'services/app'
import { withRouter, withRouterProps } from 'services/router'

import { change } from 'redux-form'
import { ROOM_TYPES } from 'services/legacy/rooms/constants'
import PractitionerChildAccessView from './PractitionerChildAccessView'
import { ACCESS_VIEW_TYPES } from './components/constants'
import {
  PRACTITIONER_CHILD_ACCESS_FORM,
  PractitionerChildAccessFormVales,
} from './components/PractitionerChildAccessForm'

export const NURSERY_SECURITY_SETTINGS_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings',
    'nurserySettings.security',
    'nurserySecuritySettings',
  ],
}

const ROOM_GROUPS = {
  read: [
    'membership',
    'nurseryClass.classMemberships',
  ],
}

type PractitionerChildAccessContainerProps = withAppServiceProps
  & withRouterProps
  & withNurseriesServiceProps
  & withNurseryContextServiceProps
  & {
    roomsActions?: any
    roomsListState?: any
    roomsSelectors?: any
  }

const mapState = (state: RootState, {
  appSelectors,
  nurseriesSingleState,
  params,
  roomsListState,
  roomsSelectors,
}: PractitionerChildAccessContainerProps) => ({
  errorMessages: appSelectors.getErrorMessages(roomsListState, nurseriesSingleState),
  isRoomFetching: appSelectors.getIsFetching(roomsListState),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  rooms: roomsSelectors.getRoomsListDataSelector(state),
})

const mapDispatch = {
  updateField: (fieldName, fieldValue) => change(PRACTITIONER_CHILD_ACCESS_FORM, fieldName, fieldValue),
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

const PractitionerChildAccessContainer: React.FC<
  PractitionerChildAccessContainerProps & PropsFromRedux
> = ({
  isRoomFetching,
  nurseriesActions,
  nurseriesSelectors,
  nurseriesSingleState,
  nurseryOptions,
  rooms,
  roomsActions,
  updateField,
}) => {
  const [initialValues, setInitialValues] = useState<PractitionerChildAccessFormVales>()
  const [isFetching, setIsFetching] = useState<boolean>(true)

  const fetchRoomList = () => {
    roomsActions.listRecurrency({
      criteria: [
        {
          field: 'archived',
          value: false,
        },
        {
          field: 'type',
          value: ROOM_TYPES.TEACHING,
        },
      ],
      groups: ROOM_GROUPS,
    })
  }

  useEffect(() => {
    setIsFetching(true)

    nurseriesActions.get(nurseryOptions.id, {
      onFailed: () => setIsFetching(false),
      onSuccess: (response) => {
        const { data } = response

        setInitialValues({
          accessViewType: data?.nurserySettings?.security?.showAllChildren
            ? ACCESS_VIEW_TYPES.ALL_CHILDREN
            : ACCESS_VIEW_TYPES.ONLY_OWN_ROOMS,
        })

        if (!data?.nurserySettings?.security?.showAllChildren) {
          fetchRoomList()
        }

        setIsFetching(false)
      },
      onlyData: true,
      params: { groups: NURSERY_SECURITY_SETTINGS_GROUPS },
    })

    return () => {
      roomsActions.clear()
    }
  }, [])

  const failedCallback = (value) => {
    updateField('accessViewType', value === ACCESS_VIEW_TYPES.ALL_CHILDREN
      ? ACCESS_VIEW_TYPES.ONLY_OWN_ROOMS
      : ACCESS_VIEW_TYPES.ALL_CHILDREN)
  }

  const handleAccessViewTypeChange = (e) => {
    const payload = nurseriesSelectors.getNurserySettingsPayload({
      fields: {
        security: {
          showAllChildren: e.target.value === ACCESS_VIEW_TYPES.ALL_CHILDREN,
        },
      },
      nurseriesSingleState,
    })

    nurseriesActions.update(nurseryOptions.id, {
      onFailed: () => failedCallback(e.target.value),
      onSuccess: () => e.target.value === ACCESS_VIEW_TYPES.ONLY_OWN_ROOMS
        && !rooms
        && fetchRoomList(),
      params: { groups: NURSERY_SECURITY_SETTINGS_GROUPS },
      payload,
    })
  }

  const handleRoomListReload = () => {
    fetchRoomList()
  }

  return (
    <PractitionerChildAccessView
      initialValues={initialValues}
      isFetching={isFetching}
      isRoomFetching={isRoomFetching}
      rooms={rooms}
      onAccessViewTypeChange={handleAccessViewTypeChange}
      onRoomListReload={handleRoomListReload}
    />
  )
}

const enhance = compose(
  withAppService,
  withRoomsService,
  withNurseryContextService,
  withNurseriesService,
  withRouter,
  connector,
)

export default enhance(PractitionerChildAccessContainer)
