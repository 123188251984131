import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import {
  CLEAR_CHILD_BOOKING_LIST,
  GET_CHILD_BOOKING_LIST,
  GET_CHILD_BOOKING_LIST_FAILED,
  GET_CHILD_BOOKING_LIST_SUCCESS,
} from './constants'

const initialState = getBasicListInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_CHILD_BOOKING_LIST,
    failed: GET_CHILD_BOOKING_LIST_FAILED,
    init: GET_CHILD_BOOKING_LIST,
    success: GET_CHILD_BOOKING_LIST_SUCCESS,
  }, initialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
