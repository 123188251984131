import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'

import { Form } from 'components'

import i18n from 'translations'

import { ENQUIRY_SETTINGS_ADD_FORM } from '../AddConstants'
import { getTransPrefix } from '../../helpers'
import { TYPE_TO_ROUTE_URL_MAPPINGS } from '../../constants'

const AddForm = ({
  handleSubmit,
  isEditMode,
  isSubmitting,
  type,
}) => (
  <Form onSubmit={handleSubmit}>
    <Form.Row
      label={i18n.t(`${getTransPrefix(type)}:Add:Form:Name:label`)}
      required
    >
      <Form.Row.FlexItem mobileFull>
        <Field
          component={Form.TextField}
          name="name"
          placeholder={i18n.t(`${getTransPrefix(type)}:Add:Form:Name:placeholder`)}
          validate={isRequired}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.FooterActions
      cancelLink={generateRoute('MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_COMMON.INDEX', {
        type: TYPE_TO_ROUTE_URL_MAPPINGS[type],
      })}
      isSubmitting={isSubmitting}
      submitLabel={i18n.t(`global:${isEditMode ? 'Update' : 'Save'}`)}
    />
  </Form>
)

export default reduxForm({ form: ENQUIRY_SETTINGS_ADD_FORM })(AddForm)
