import { RESPONSE, Request } from 'constants/http'

import { generateRoute } from 'utils/routing'

import authenticationActions from 'services/authentication/actions'

const isAccessForbidden = (request: Request, response, dispatch) => {
  if (
    RESPONSE.HTTP_403_FORBIDDEN === response.status
    && !request.public
    && window.location.pathname !== generateRoute('VARIOUS.ACCESS_FORBIDDEN')
  ) {
    if ('/auth/me' === request.path) {
      dispatch(authenticationActions.logout())

      window.location.href = `${generateRoute('AUTHENTICATION.LOGIN')}?fromLogout`

      return response
    }

    window.location.href = generateRoute('VARIOUS.ACCESS_FORBIDDEN')
  }

  return response
}

const AccessForbiddenMiddleware = (dispatch) => async (request: Request, next) => {
  const response = await next(request)

  isAccessForbidden(request, response, dispatch)

  return response
}

export default AccessForbiddenMiddleware
