import { createSelector } from 'reselect'

const getFinanceReports = (state) => state.financeBalanceReport

export const getFinanceBalanceReportStatistics = createSelector(
  [getFinanceReports],
  (state) => state.statistics,
)

export const getFinanceBalanceReportStatisticsData = createSelector(
  [getFinanceBalanceReportStatistics],
  (state) => state.data,
)

