import _ from 'lodash'

import { REMOVE_NURSERY_FILE_SUCCESS, UPDATE_NURSERY_FILE_SUCCESS } from 'services/legacy/files/nurserySingle/constants'

import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import { properties } from 'app-config'

import {
  ADD_MANUALLY_NURSERY_FILES,
  CLEAR_NURSERY_FILES_LIST,
  GET_NURSERY_FILES_LIST,
  GET_NURSERY_FILES_LIST_FAILED,
  GET_NURSERY_FILES_LIST_SUCCESS,
  GET_NURSERY_FILES_TOTAL_RESULTS_LIST_SUCCESS,
  REMOVE_FILE_FROM_LIST,
} from './constants'

const initialState = {
  ...getBasicListInitialState,
  isFetching: true,
}

export default (...props) => {
  const [state = initialState, { payload, type }] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_NURSERY_FILES_LIST,
    failed: GET_NURSERY_FILES_LIST_FAILED,
    init: GET_NURSERY_FILES_LIST,
    success: GET_NURSERY_FILES_LIST_SUCCESS,
  }, initialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  if (GET_NURSERY_FILES_TOTAL_RESULTS_LIST_SUCCESS === type) {
    return {
      ...state,
      meta: {
        ...state.meta,
        totalUnfilteredResults: payload?.meta?.total_results,
      },
    }
  }

  if (UPDATE_NURSERY_FILE_SUCCESS === type) {
    const { data: { description, id, name } } = payload

    return {
      ...state,
      data: _.map(state.data, (item) => {
        if (id === item.id) {
          return {
            ...item,
            description,
            name,
          }
        }

        return item
      }),
      error: null,
      isFetching: false,
    }
  }

  if (REMOVE_NURSERY_FILE_SUCCESS === type) {
    const [id] = payload

    return {
      ...state,
      data: _.filter(state.data, (item) => id !== item.id),
      isFetching: false,
    }
  }

  if (ADD_MANUALLY_NURSERY_FILES === type) {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const newTotalResults = state.meta.totalUnfilteredResults + payload?.length

    return {
      ...state,
      data: [
        ...state.data,
        ...payload,
      ],
      meta: {
        ...state.meta,
        // eslint-disable-next-line camelcase
        totalUnfilteredResults: newTotalResults > properties.upload.limits.nursery
          ? properties.upload.limits.nursery
          : newTotalResults,
      },
    }
  }

  if (REMOVE_FILE_FROM_LIST === type) {
    const itemToRemove = _.find(state.data, ({ id }) => (
      (_.isString(payload.id) ? payload.beId : payload.id) === id
    ))

    return {
      ...state,
      data: _.filter(state.data, ({ id }) => (
        (_.isString(payload.id) ? payload.beId : payload.id) !== id
      )),
      meta: {
        ...state.meta,
        // eslint-disable-next-line camelcase
        totalUnfilteredResults: state.meta.totalUnfilteredResults - (itemToRemove ? 1 : 0),
      },
    }
  }

  return state
}
