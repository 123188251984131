import React, { useEffect } from 'react'

import { Form, Space } from 'components'

import i18n from 'translations'

import ReviewGeneralInformation from './components/ReviewGeneralInformation'
import ReviewEnrolmentDetails from './components/ReviewEnrolmentDetails'
import ReviewContacts from './components/ReviewContacts'

const ReviewPage = ({
  cancelLink,
  enableSessions,
  formValues,
  isAllYearRoundSelected,
  isFinanceV3Enabled,
  isSubmitting,
  onPreviousStageClick,
  onReviewSubmit,
  onSetStage,
  openingDays,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
      <ReviewGeneralInformation
        formValues={formValues}
        isFinanceV3Enabled={isFinanceV3Enabled}
        onSetStage={onSetStage}
      />
      <Space margin="0 0 25px" />
      {!isFinanceV3Enabled && (
        <React.Fragment>
          <ReviewEnrolmentDetails
            enableSessions={enableSessions}
            formValues={formValues}
            isAllYearRoundSelected={isAllYearRoundSelected}
            openingDays={openingDays}
            onSetStage={onSetStage}
          />
          <Space margin="0 0 25px" />
        </React.Fragment>
      )}
      <ReviewContacts
        formValues={formValues}
        isFinanceV3Enabled={isFinanceV3Enabled}
        onSetStage={onSetStage}
      />
      <Form.FooterActions
        cancelLink={cancelLink}
        isSubmitting={isSubmitting}
        secondary={{
          label: i18n.t('global:Previous'),
          onClick: onPreviousStageClick,
        }}
        submitLabel={i18n.t('module:Children:Child:Add:Review:submitLabel')}
        onSubmitClick={() => onReviewSubmit(formValues)}
      />
    </React.Fragment>
  )
}

export default ReviewPage
