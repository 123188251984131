import React from 'react'

import { Link } from 'react-router'

import { Avatar, Button, Typography } from 'components'

import i18n from 'translations'

import {
  StyleHeader,
  StyledAddressContainer,
  StyledChildTotalContainer,
  StyledContactNumber,
  StyledContactNumberContainer,
  StyledContentContainer,
  StyledContentText,
  StyledEditButton,
  StyledItemContainer,
  StyledStaffTotalContainer,
  StyledTotalContainer,
  StyledUserContainer,
  StyledUserProfile,
} from './NurseryListStyled'

const NurseryListItem = ({ authAccessMap, nursery, onEditClick }) => {
  const {
    activeChildrenCount,
    activeStaffCount,
    address,
    archived,
    id,
    name,
    postCode,
    primaryContact,
    publicUrl,
    telephone,
  } = nursery
  const { fullName, name: contactName, photo } = primaryContact || {}

  const handleEditClick = (e) => {
    e.preventDefault()

    onEditClick(id)
  }

  return (
    <div>
      <Link target="_blank" to={publicUrl}>
        <StyledItemContainer isDisabled={archived}>
          <StyleHeader>
            {name}
            {archived && ` (${i18n.t('global:disabled')})`}
          </StyleHeader>
          <StyledContactNumberContainer>
            <StyledContactNumber>{telephone}</StyledContactNumber>
            {authAccessMap.module.NurseryAdd && (
              <StyledEditButton>
                <Button
                  hierarchy="tertiary"
                  icon="edit"
                  size="small"
                  inverted
                  negativeMargins
                  onClick={handleEditClick}
                />
              </StyledEditButton>
            )}
          </StyledContactNumberContainer>
          <StyledUserContainer>
            <Typography color="inherit" bold>Manager</Typography>
            <StyledUserProfile>
              {primaryContact
                ? (
                  <Avatar
                    avatarSize="medium"
                    initials={contactName.split(' ')}
                    src={photo}
                    title={(
                      <Typography color="inherit" fontSize={14}>
                        {fullName || contactName}
                      </Typography>
                    )}
                  />
                )
                : '-'}
            </StyledUserProfile>
          </StyledUserContainer>
          <StyledContentContainer>
            <StyledAddressContainer>
              <Typography color="inherit" margin="0 0 10px 0" bold>
                Address
              </Typography>
              <StyledContentText>{address}</StyledContentText>
              <StyledContentText>{postCode}</StyledContentText>
            </StyledAddressContainer>
            <StyledTotalContainer>
              <StyledChildTotalContainer>
                <Typography color="inherit" variant="h6">Total Children</Typography>
                <Typography color="inherit" variant="h3">{activeChildrenCount}</Typography>
              </StyledChildTotalContainer>
              <StyledStaffTotalContainer>
                <Typography color="inherit" variant="h6">Total Staff</Typography>
                <Typography color="inherit" variant="h3">{activeStaffCount}</Typography>
              </StyledStaffTotalContainer>
            </StyledTotalContainer>
          </StyledContentContainer>
        </StyledItemContainer>
      </Link>
    </div>
  )
}

export default NurseryListItem
