import React from 'react'

import { Button, Callout, EmptyState, FooterActions, Form, Page, Section, Spinner, Toolbar } from 'components'

import i18n from 'translations'

import { ChildDetail, ChildFundingSessionPanel } from '../components'
import ChildFundingDetail from './components/ChildFundingDetail'
import ChildFundingAllocationForm from './components/ChildFundingAllocationForm'
import ChildFundingWeekDaysButtons from './components/ChildFundingWeekDaysButtons'
import ChildFundingDescription from './components/ChildFundingDescription'
import WeeklyScheduler from './components/WeeklyScheduler'

const ChildFundingAllocationView = ({
  child,
  childFunding,
  childFundingSessionSets,
  customErrorMessage,
  enableApplyButton,
  errorMessages,
  initialValues,
  isInsideModal,
  isLoading,
  isSubmitting,
  isTimeLineLoading,
  onAddSessionClick,
  onApplyFundingClick,
  onCancelAllocation,
  onFinishAllocationClick,
  onSaveAllocation,
  onSessionSetClick,
  onTimeLineItemClick,
  onWeekDaysClick,
  selectedTimeLine,
  selectedWeekDays,
  weekDays,
  weeklySchedulerData,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!childFundingSessionSets?.length) {
      const { firstName: childFirstName, id: childId } = child || {}

      return (
        <EmptyState
          actions={(
            <Button
              label={i18n.t('module:Children:Child:BookingPattern:Funding:Allocation:addSessionSetLabel')}
              onClick={() => onAddSessionClick(childId)}
            />
          )}
          icon="funding"
          text1={i18n.t('module:Children:Child:BookingPattern:Funding:Allocation:emptyStateText1', { childFirstName })}
        />
      )
    }

    return (
      <Section title="Edit funding allocation to sessions">
        <p>
          {i18n.t('module:Children:Child:BookingPattern:Funding:Allocation:subtitle')}
        </p>
        <ChildFundingDetail childFunding={childFunding} />
        <Form.Divider />
        <ChildFundingSessionPanel
          sessionSets={childFundingSessionSets}
          showWeeklyAllocation
          onItemClick={onSessionSetClick}
        />
        <Form.Divider />
        <Callout content={customErrorMessage} error />
        <ChildFundingWeekDaysButtons
          selectedWeekDays={selectedWeekDays}
          weekDays={weekDays}
          onItemClick={onWeekDaysClick}
        />
        <ChildFundingDescription
          childFunding={childFunding}
        />
        <WeeklyScheduler
          dateRange={weeklySchedulerData?.dateRange}
          endTime={weeklySchedulerData?.endTime}
          isLoading={isTimeLineLoading}
          selectedWeekDays={selectedWeekDays}
          startTime={weeklySchedulerData?.startTime}
          onItemClick={onTimeLineItemClick}
        />
        {selectedTimeLine && (
          <ChildFundingAllocationForm
            initialValues={initialValues}
            isSubmitting={isSubmitting}
            timeLineData={selectedTimeLine}
            onCancel={onCancelAllocation}
            onSubmit={onSaveAllocation}
          />
        )}
        {!selectedTimeLine && (
          <Toolbar>
            <Toolbar.Group>
              <Toolbar.Item>
                <Button
                  disabled={!enableApplyButton}
                  hierarchy="secondary"
                  isLoading={isLoading}
                  label={i18n.t('module:Children:Child.BookingPattern:Funding:Allocation:applyButtonLabel')}
                  onClick={onApplyFundingClick}
                />
              </Toolbar.Item>
            </Toolbar.Group>
          </Toolbar>
        )}
      </Section>
    )
  }

  if (isInsideModal) {
    return (
      <React.Fragment>
        <Callout content={errorMessages} error />
        <ChildDetail child={child} isLoading={isLoading} />
        {renderContent()}
        {!isLoading && childFundingSessionSets?.length && (
          <FooterActions>
            <FooterActions.Item>
              <Button
                label={i18n.t('module:Children:Child.BookingPattern:Funding:Allocation:finishAllocationLabel')}
                negativeMargins
                onClick={onFinishAllocationClick}
              />
            </FooterActions.Item>
          </FooterActions>
        )}
      </React.Fragment>
    )
  }

  return (
    <Page isLoading={isLoading}>
      <Page.Content>
        <Callout content={errorMessages} error />
        {renderContent()}
      </Page.Content>
    </Page>
  )
}

export default ChildFundingAllocationView
