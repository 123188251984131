import { FEATURE_FLAGS, ROLES } from 'constants/security'

const EnquiriesSettingsWrapper = ({ children }) => children

EnquiriesSettingsWrapper.authParams = {
  flags: [FEATURE_FLAGS.ENQUIRIES],
  roles: [
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.SUPER_ADMIN,
  ],
}

export default EnquiriesSettingsWrapper
