import _ from 'lodash'

import React from 'react'

import { DropdownMenu, Spinner, Switch } from 'components'

import i18n from 'translations'

import { StyledDefault, StyledName, StyledSpinnerWrapper } from './CurriculumsStyled'

export const getTableData = ({
  data,
  defaultFramework,
  enabledFrameworks,
  isEnableFrameworkSubmitting,
  isSetAsDefaultSubmitting,
  onSetAsDefaultClick,
  onToggleChange,
  willBeActivated,
  willBeDefault,
}) => _.map(data, ({ frameworkName, id }) => {
  const isDefault = id === defaultFramework
  const isEnabled = -1 !== _.indexOf(enabledFrameworks, id)

  return ({
    id,
    name: (
      <StyledName>
        {frameworkName}
        {isDefault && !isSetAsDefaultSubmitting && (
          <StyledDefault>
            {i18n.t('global:Default')}
          </StyledDefault>
        )}
        {(isDefault || id === willBeDefault) && isSetAsDefaultSubmitting && (
          <StyledSpinnerWrapper>
            <Spinner size="medium" />
          </StyledSpinnerWrapper>
        )}
      </StyledName>
    ),
    options: (
      <DropdownMenu
        disabled={!isEnabled || (id === willBeActivated && isEnableFrameworkSubmitting) || isDefault}
      >
        <DropdownMenu.Item
          label={i18n.t('module:Management:Learning:Curriculums:setAsDefault')}
          onClick={() => onSetAsDefaultClick(id)}
        />
      </DropdownMenu>
    ),
    switch: (
      <Switch
        disabled={(id === willBeActivated && isEnableFrameworkSubmitting)}
        value={isEnabled}
        simple
        onChange={() => onToggleChange(id)}
      />
    ),
  })
})
