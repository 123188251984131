import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'

import { Field } from 'components'

import withInvoiceSettingsComponent from '../withInvoiceSettingsComponent'
import InvoiceNoteSettingsForm from './InvoiceNoteSettingsForm'
import InvoiceActionButton from '../InvoiceActionButton'

const FORM_NAME_PREFIX = 'InvoiceNoteSettingsForm'

class InvoiceNoteSettings extends Component {
  constructor(props) {
    super(props)

    const { noteId } = props

    this.state = {
      formName: `${FORM_NAME_PREFIX}_${noteId}`,
    }
  }

  handleSubmit = (fields) => {
    const { fields: { global, item }, noteId, onSubmit } = this.props
    const { id, value } = item
    const { id: globalId } = global

    const noteValues = {
      [globalId]: fields[globalId],
      [id]: [
        ..._.reject(value, { noteType: noteId }),
        ..._.map(fields[id], (noteItem) => ({ ...noteItem, noteType: noteId })),
      ],
    }

    onSubmit(noteValues)
  }

  handleGlobalOptionClick = (globalValue) => {
    const { fields: { item: { id, orgValue } }, noteId, updateOrgValue } = this.props
    const { formName } = this.state

    if (globalValue) {
      const orgNoteValues = _.filter(orgValue, { noteType: noteId })

      updateOrgValue(formName, id, orgNoteValues)
    }
  }

  renderNoteLabel = ({ note }, index) => (
    <div key={_.kebabCase(index)}>
      {`Note ${index + 1}`}
      <br />
      {note}
    </div>
  )

  renderLabel = (noteValues) => {
    if (!noteValues || !noteValues.length) {
      return null
    }

    return _.map(noteValues, this.renderNoteLabel)
  }

  render() {
    const {
      caption,
      disableEdit,
      fields: { global, item },
      hideGlobalOption,
      isEdit,
      isGlobalOptionOn,
      isSubmitting,
      noteId,
      onCancelClick,
      onEditClick,
    } = this.props
    const { formName } = this.state
    const { id, value } = item
    const { id: globalId, value: globalValue } = global

    const noteValues = _.filter(value, { noteType: noteId })

    const initialValues = { [globalId]: globalValue, [id]: noteValues && noteValues.length ? noteValues : [{}] }

    return (
      <Field.InlineEdit
        actions={(
          <InvoiceActionButton
            disableEdit={disableEdit}
            formRef={this.myForm}
            isEditMode={isEdit}
            onCancelClick={onCancelClick}
            onClick={onEditClick}
          />
        )}
        description={this.renderLabel(noteValues)}
        editableComponent={(
          <InvoiceNoteSettingsForm
            form={formName}
            globalId={globalId}
            hideGlobalOption={hideGlobalOption}
            id={id}
            initialValues={initialValues}
            isGlobalOptionOn={isGlobalOptionOn}
            ref={(ref) => { this.myForm = ref }}
            onGlobalOptionClick={this.handleGlobalOptionClick}
            onSubmit={this.handleSubmit}
          />
        )}
        isEditMode={isEdit}
        isLoading={isSubmitting}
        label={caption}
      />
    )
  }
}

const enhance = compose(withInvoiceSettingsComponent(FORM_NAME_PREFIX))

export default enhance(InvoiceNoteSettings)
