import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class ChildBacsDetailsApiClient extends ServiceBase {
  create = (childId, payload) => this.request({
    body: payload,
    method: 'POST',
    path: `/v2/integrations/children/${childId}/bacs_debit_details/custom`,
  })

  get = (childId, params = {}) => this.request({
    path: `/v2/integrations/children/${childId}/bacs_debit_details?${getQueryStringV2(params)}`,
  })
}

export default ChildBacsDetailsApiClient
