import { mergeData } from 'utils/data'

import { CLEAR_CONTRACTS, LIST_CONTRACTS, LIST_CONTRACTS_FAILED, LIST_CONTRACTS_SUCCESS } from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: null,
}

export default (state = initialState, { error, mergeResult, payload, type }) => {
  switch (type) {
    case LIST_CONTRACTS:
      return { ...state, error: initialState.error, isFetching: true }
    case LIST_CONTRACTS_SUCCESS:
      return {
        data: mergeData(state.data, payload.data, mergeResult),
        error: initialState.error,
        isFetching: false,
        meta: payload.meta,
      }
    case LIST_CONTRACTS_FAILED:
      return { ...state, error, isFetching: false }
    case CLEAR_CONTRACTS:
      return initialState
    default:
      return state
  }
}
