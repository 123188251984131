import _ from 'lodash'

import React from 'react'

import { FLAG_COLOURS } from 'constants/colors'

import {
  Button,
  Callout,
  DatePicker,
  DropdownMenu,
  EmptyState,
  Icon,
  InfiniteDropdowns,
  Page,
  Pagination,
  SearchBar,
  Spinner,
  Table,
  Toolbar,
  Typography,
} from 'components'

import i18n from 'translations'

import { GrayBox } from './components'
import { StyledAction, StyledActions, StyledConfirmed } from './RoomsMovesStyled'

const RoomMovesView = ({
  childMoves,
  childVersion,
  columns,
  errorMessages,
  isLoading,
  moveOn,
  onConfirmRoomMoveModal,
  onMoveChange,
  onPageChange,
  onRoomChange,
  onSearchChange,
  onShowEditRoomMoveModal,
  onSortChange,
  page,
  pageCount,
  perPage,
  room,
  roomDetails,
  search,
  sortField,
  sortOrder,
  totalResults,
}) => {
  _.map(childMoves, (item) => {
    const childMove = item
    childMove.action = (
      <StyledActions>
        <StyledAction>
          {!childMove.confirmedAt ? (
            <Button
              hierarchy="secondary"
              label={i18n.t('global:Confirm')}
              size="small"
              negativeMargins
              onClick={() => onConfirmRoomMoveModal(childMove)}
            />
          ) : (
            <StyledConfirmed>
              <Icon
                color={FLAG_COLOURS.SUCCESS}
                height={18}
                icon="confirmed"
              />
              <Typography margin="0 0 0 8px">
                Confirmed
              </Typography>
            </StyledConfirmed>
          )}
        </StyledAction>
        <StyledAction>
          <DropdownMenu small>
            <DropdownMenu.Item
              type="edit"
              onClick={() => onShowEditRoomMoveModal(childMove)}
            />
            {childMove.confirmedAt && (
              <DropdownMenu.Item
                icon="unconfirm"
                label="Unconfirm"
                onClick={() => onConfirmRoomMoveModal(childMove, true)}
              />
            )}
          </DropdownMenu>
        </StyledAction>
      </StyledActions>
    )

    return childMove
  })

  const renderHeader = () => (
    <Toolbar noMargin>
      <Toolbar.Group>
        {!childVersion && (
          <Toolbar.Item>
            <InfiniteDropdowns.Rooms
              value={room}
              onChange={onRoomChange}
            />
          </Toolbar.Item>
        )}
        <Toolbar.Item>
          <DatePicker
            value={moveOn}
            clearable
            range
            onChange={onMoveChange}
          />
        </Toolbar.Item>
        {roomDetails && roomDetails.ratio && (
          <Toolbar.Item>
            <GrayBox
              title="Ratio"
              value={roomDetails.ratio}
            />
          </Toolbar.Item>
        )}
        {roomDetails && roomDetails.startAgeInMonths && roomDetails.endAgeInMonths && (
          <Toolbar.Item>
            <GrayBox
              title="Age range"
              value={`${roomDetails.startAgeInMonths}-${roomDetails.endAgeInMonths} months`}
            />
          </Toolbar.Item>
        )}
      </Toolbar.Group>
    </Toolbar>
  )

  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('module:Rooms:RoomMoves:roomMoves')}
      titleSingular={i18n.t('module:Rooms:RoomMoves:roomMove')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return (
        <Spinner />
      )
    }

    if (!childMoves.length) {
      return (
        <EmptyState
          icon="occupancy"
          text1={(
            <div>
              There are no children with
              <br />
              upcoming room moves
            </div>
          )}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={columns}
          data={childMoves}
          minWidth={920}
          sortField={sortField}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {!childVersion && (
        <SearchBar
          placeholder="Search child"
          value={search}
          onChange={onSearchChange}
        />
      )}
      <Page.Section
        isLoading={isLoading}
        title={i18n.t('global:RoomMoves')}
      >
        <Callout content={errorMessages} error />
        {renderHeader()}
        {renderContent()}
      </Page.Section>
    </React.Fragment>
  )
}

export default RoomMovesView
