import React from 'react'

import { StyledContainer } from './SignatureListStyled'

const SignatureList = ({ children }) => (
  <StyledContainer>
    {children}
  </StyledContainer>
)

export default SignatureList
