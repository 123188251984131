import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { isSameDay } from 'utils/date'

import {
  Button,
  DatePicker,
  DateString,
  DefinitionList,
  InfiniteDropdowns,
  Select,
  TextAreaField,
  Toolbar,
} from 'components'

import i18n from 'translations'

import { ABSENCE_TYPE, REGISTER_ACTION_TYPES } from '../../../constants'
import {
  StyledContainer,
  StyledFieldsContainer,
  StyledFooter,
  StyledSelectsContainer,
  StyledTextAreaContainer,
} from './RegisterItemPanelStyled'

const RegisterItemPanel = ({
  absence,
  absenceTypeOptions,
  comments,
  errors,
  expandType,
  hasComment,
  isEditing,
  isLoading,
  oldAbsence,
  onAbsenceEndDateChange,
  onAbsenceReasonChange,
  onAbsenceStartDateChange,
  onAbsenceTypeChange,
  onCancelClick,
  onCommentsChange,
  onSaveClick,
}) => {
  const { ABSENCE, COMMENT } = REGISTER_ACTION_TYPES

  if (!(expandType === COMMENT || expandType === ABSENCE)) {
    return null
  }

  const renderContent = () => {
    if (expandType === COMMENT) {
      if (!isEditing && hasComment) {
        return (
          <DefinitionList>
            <DefinitionList.Item
              label={i18n.t('module:Register:Form:Comments:label')}
              value={comments}
              whiteSpace="pre-wrap"
              wordBreak="break-word"
            />
          </DefinitionList>
        )
      }

      return (
        <div>
          <StyledFieldsContainer>
            <StyledTextAreaContainer>
              <TextAreaField
                error={errors.comments}
                placeholder={i18n.t('module:Register:Form:Comments:placeholder')}
                value={comments}
                onChange={onCommentsChange}
              />
            </StyledTextAreaContainer>
          </StyledFieldsContainer>
          {!isEditing && (
            <StyledFooter>
              <Toolbar noMargin>
                <Toolbar.Group />
                <Toolbar.Group>
                  <Toolbar.Item>
                    <Button
                      disabled={isLoading}
                      hierarchy="tertiary"
                      label={i18n.t('global:Cancel')}
                      size="small"
                      negativeMargins
                      onClick={onCancelClick}
                    />
                  </Toolbar.Item>
                  <Toolbar.Item>
                    <Button
                      isLoading={isLoading}
                      label={i18n.t('global:Save')}
                      size="small"
                      negativeMargins
                      onClick={onSaveClick}
                    />
                  </Toolbar.Item>
                </Toolbar.Group>
              </Toolbar>
            </StyledFooter>
          )}
        </div>
      )
    }

    if (expandType === ABSENCE) {
      const absenceType = absence?.absenceType
      const absenceReason = absence?.absenceReason?.name
      const absenceComments = absence?.comments
      const absenceStartDate = absence?.endDate ? moment(absence.startDate).toDate() : null
      const absenceEndDate = absence?.endDate ? moment(absence.endDate).toDate() : null
      const disabledSave = absenceType === ABSENCE_TYPE.HOLIDAY && errors.absenceEndDate

      if (!isEditing && oldAbsence) {
        return (
          <Toolbar noMargin>
            <Toolbar.Group>
              <Toolbar.Item>
                <DefinitionList>
                  <DefinitionList.Item
                    label={absenceType === ABSENCE_TYPE.HOLIDAY
                      ? i18n.t('module:Register:Form:HolidayDate:label')
                      : i18n.t('module:Register:Form:AbsenceDate:label')}
                    value={isSameDay(absenceStartDate, absenceEndDate)
                      ? <DateString date={absenceStartDate} />
                      : <DateString.Range endDate={absenceEndDate} startDate={absenceStartDate} />}
                  />
                  {absenceType === ABSENCE_TYPE.ABSENCE && (
                    <DefinitionList.Item
                      label={i18n.t('module:Register:Form:AbsencesReason:label')}
                      value={absenceReason}
                    />
                  )}
                  <DefinitionList.Item
                    label={i18n.t('module:Register:Form:Comments:label')}
                    value={absenceComments}
                    whiteSpace="pre-wrap"
                    wordBreak="break-word"
                  />
                </DefinitionList>
              </Toolbar.Item>
            </Toolbar.Group>
          </Toolbar>
        )
      }

      return (
        <div>
          <StyledFieldsContainer>
            <StyledSelectsContainer>
              <Select
                clearable={false}
                options={absenceTypeOptions}
                placeholder={i18n.t('module:Register:Form:AbsenceType:label')}
                searchable={false}
                value={absenceType}
                onChange={onAbsenceTypeChange}
              />
              {absenceType === ABSENCE_TYPE.ABSENCE && (
                <InfiniteDropdowns.AbsenceReasons
                  value={absenceReason}
                  clearable
                  onChange={onAbsenceReasonChange}
                />
              )}
              <DatePicker
                error={errors.absenceStartDate}
                placeholder={_.upperFirst(i18n.t('global:startDate'))}
                value={absenceEndDate}
                clearable
                onChange={onAbsenceStartDateChange}
              />
              <DatePicker
                error={errors.absenceEndDate}
                placeholder={_.upperFirst(i18n.t('global:endDate'))}
                value={absenceEndDate}
                clearable
                onChange={onAbsenceEndDateChange}
              />
            </StyledSelectsContainer>
            <StyledTextAreaContainer>
              <TextAreaField
                error={errors.comments}
                placeholder={i18n.t('module:Register:Form:Comments:placeholder')}
                value={absenceComments}
                onChange={onCommentsChange}
              />
            </StyledTextAreaContainer>
          </StyledFieldsContainer>
          {!isEditing && (
            <StyledFooter>
              <Toolbar noMargin>
                <Toolbar.Group />
                <Toolbar.Group>
                  <Toolbar.Item>
                    <Button
                      disabled={isLoading}
                      hierarchy="tertiary"
                      label={i18n.t('global:Cancel')}
                      size="small"
                      negativeMargins
                      onClick={onCancelClick}
                    />
                  </Toolbar.Item>
                  <Toolbar.Item>
                    <Button
                      disabled={disabledSave || isLoading}
                      isLoading={isLoading}
                      label={i18n.t('global:Save')}
                      size="small"
                      negativeMargins
                      onClick={onSaveClick}
                    />
                  </Toolbar.Item>
                </Toolbar.Group>
              </Toolbar>
            </StyledFooter>
          )}
        </div>
      )
    }

    return null
  }

  return (
    <StyledContainer>
      {renderContent()}
    </StyledContainer>
  )
}

export default RegisterItemPanel
