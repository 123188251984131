export const GET_COMPARISON_PREVIEW = 'GET_COMPARISON_PREVIEW'
export const GET_COMPARISON_PREVIEW_SUCCESS = 'GET_COMPARISON_PREVIEW_SUCCESS'
export const GET_COMPARISON_PREVIEW_FAILED = 'GET_COMPARISON_PREVIEW_FAILED'

export const GET_COMPARISON_REPORT = 'GET_COMPARISON_REPORT'
export const GET_COMPARISON_REPORT_SUCCESS = 'GET_COMPARISON_REPORT_SUCCESS'
export const GET_COMPARISON_REPORT_FAILED = 'GET_COMPARISON_REPORT_FAILED'

export const CREATE_COMPARISON_REPORT = 'CREATE_COMPARISON_REPORT'
export const CREATE_COMPARISON_REPORT_SUCCESS = 'CREATE_COMPARISON_REPORT_SUCCESS'
export const CREATE_COMPARISON_REPORT_FAILED = 'CREATE_COMPARISON_REPORT_FAILED'

export const UPDATE_COMPARISON_ACTION_PLAN = 'UPDATE_COMPARISON_ACTION_PLAN'
export const UPDATE_COMPARISON_ACTION_PLAN_SUCCESS = 'UPDATE_COMPARISON_ACTION_PLAN_SUCCESS'
export const UPDATE_COMPARISON_ACTION_PLAN_FAILED = 'UPDATE_COMPARISON_ACTION_PLAN_FAILED'

export const REMOVE_COMPARISON_REPORT = 'REMOVE_COMPARISON_REPORT'
export const REMOVE_COMPARISON_REPORT_SUCCESS = 'REMOVE_COMPARISON_REPORT_SUCCESS'
export const REMOVE_COMPARISON_REPORT_FAILED = 'REMOVE_COMPARISON_REPORT_FAILED'

export const CLEAR_COMPARISON_SINGLE = 'CLEAR_COMPARISON_SINGLE'
