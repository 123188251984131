import { Property } from 'csstype'

import React, { PropsWithChildren } from 'react'

import { StyledItemContainer } from './ToolbarStyled'

interface ToolbarItemProps {
  justifyContentOnMobile?: Property.JustifyContent
  noMargin?: boolean
}

const ToolbarItem: React.FC<PropsWithChildren<ToolbarItemProps>> = ({
  children,
  justifyContentOnMobile,
  noMargin,
}) => (
  <StyledItemContainer
    $justifyContentOnMobile={justifyContentOnMobile}
    $noMargin={noMargin}
  >
    {children}
  </StyledItemContainer>
)

export default ToolbarItem
