import React from 'react'

import { ALLERGY_TYPES, APPLY_PERMISSIONS } from 'services/legacy/child/constants'

import { getAllergyTypeLabel } from 'services/legacy/child/helpers'
import { getDietaryOptionLabel } from 'services/legacy/child/selectors'

import { DetailsBreakdown } from 'components'

import i18n from 'translations'

import { CHILD_EDIT_FORM_ROUTE_TYPE } from '../../../constants'
import BreakdownHeader from './BreakdownHeader'
import BreakdownSubHeader from './BreakdownSubHeader'
import BreakdownItem from './BreakdownItem'

const TRANS_PREFIX = 'module:Children:Child:Add:HealthAndDiet'

const HealthAndDietBreakdown = ({
  child,
  isFormContext,
  permissionToEdit,
}) => {
  const { id, information, profile } = child
  const {
    allergies,
    allergiesDetails,
    applyPermissions = [],
    canConsumeFoodWithNutTraces,
    canReceiveMedicine,
    dietaryReq,
    dietaryReqDesc,
  } = information || {}
  const { isFussyEater, medicalInformation } = profile || {}

  const getApplyPermissionsValue = (fieldName) => {
    if (isFormContext) {
      return information[fieldName]
    }

    return applyPermissions.includes(fieldName)
  }

  const renderHealth = () => (
    <React.Fragment>
      <BreakdownSubHeader
        subtitle={i18n.t(`${TRANS_PREFIX}:Subsections:Health:title`)}
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:CanReceiveMedicine:label`)}
        padding="15px 0"
        value={canReceiveMedicine ? i18n.t('global:Yes') : i18n.t('global:No')}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:MedicalInformation:label`)}
        padding="15px 0"
        value={medicalInformation}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:Allergies:label`)}
        padding="15px 0"
        value={allergies?.label || getAllergyTypeLabel(allergies)}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:AllergiesDetails:label`)}
        padding="15px 0"
        value={ALLERGY_TYPES.HAS_ALLERGY === (allergies?.value || allergies) && allergiesDetails}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:CanConsumeFoodWithNutTraces:label`)}
        padding="15px 0"
        value={canConsumeFoodWithNutTraces ? i18n.t('global:Yes') : i18n.t('global:No')}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:Patches:label`)}
        padding="15px 0"
        value={getApplyPermissionsValue(APPLY_PERMISSIONS.PATCHES) ? i18n.t('global:Yes') : i18n.t('global:No')}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:SunCream:label`)}
        padding="15px 0"
        value={getApplyPermissionsValue(APPLY_PERMISSIONS.SUN_CREAM) ? i18n.t('global:Yes') : i18n.t('global:No')}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:NappyCream:label`)}
        padding="15px 0"
        value={getApplyPermissionsValue(APPLY_PERMISSIONS.NAPPY_CREAM) ? i18n.t('global:Yes') : i18n.t('global:No')}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Health:Form:FacePaint:label`)}
        padding="15px 0"
        value={getApplyPermissionsValue(APPLY_PERMISSIONS.FACE_PAINT) ? i18n.t('global:Yes') : i18n.t('global:No')}
        withoutBorder
      />
    </React.Fragment>
  )

  const renderDiet = () => (
    <React.Fragment>
      <BreakdownSubHeader
        subtitle={i18n.t(`${TRANS_PREFIX}:Subsections:Diet:title`)}
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Diet:Form:DietaryPreference:label`)}
        padding="15px 0"
        value={dietaryReq?.label || getDietaryOptionLabel(dietaryReq)}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Diet:Form:AdditionalNotes:label`)}
        padding="15px 0"
        value={dietaryReqDesc}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Diet:Form:FussyEater:label`)}
        padding="15px 0"
        value={isFussyEater ? i18n.t('global:Yes') : i18n.t('global:No')}
        withoutBorder
      />
    </React.Fragment>
  )

  return (
    <DetailsBreakdown marginBottom>
      <DetailsBreakdown.Section>
        <BreakdownHeader
          childFormRouteType={CHILD_EDIT_FORM_ROUTE_TYPE.HEALTH_AND_DIET_INFO}
          childId={id}
          isFormContext={isFormContext}
          permissionToEdit={permissionToEdit}
          title={i18n.t(`${TRANS_PREFIX}:title`)}
        />
        {renderHealth()}
        {renderDiet()}
      </DetailsBreakdown.Section>
    </DetailsBreakdown>
  )
}

export default HealthAndDietBreakdown
