import { generateBasicActions } from 'utils/service'

import {
  CLEAR_DEPOSIT,
  CREATE_DEPOSIT,
  CREATE_DEPOSIT_FAILED,
  CREATE_DEPOSIT_SUCCESS,
  GET_DEPOSIT,
  GET_DEPOSIT_FAILED,
  GET_DEPOSIT_SUCCESS,
  REMOVE_DEPOSIT,
  REMOVE_DEPOSIT_FAILED,
  REMOVE_DEPOSIT_SUCCESS,
  UPDATE_DEPOSIT,
  UPDATE_DEPOSIT_FAILED,
  UPDATE_DEPOSIT_SUCCESS,
} from './constants'

export const create = (options = {}) => ({ depositsApiClient }) => generateBasicActions.create({
  constants: {
    failed: CREATE_DEPOSIT_FAILED,
    init: CREATE_DEPOSIT,
    success: CREATE_DEPOSIT_SUCCESS,
  },
  options,
  service: depositsApiClient,
  serviceMethod: 'create',
})

export const get = (options = {}) => ({ depositsApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_DEPOSIT_FAILED,
    init: GET_DEPOSIT,
    success: GET_DEPOSIT_SUCCESS,
  },
  options,
  service: depositsApiClient,
  serviceMethod: 'get',
})

export const update = (options = {}) => ({ depositsApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_DEPOSIT_FAILED,
    init: UPDATE_DEPOSIT,
    success: UPDATE_DEPOSIT_SUCCESS,
  },
  options,
  service: depositsApiClient,
  serviceMethod: 'update',
})

export const remove = (options = {}) => ({ depositsApiClient }) => generateBasicActions.remove({
  constants: {
    failed: REMOVE_DEPOSIT_FAILED,
    init: REMOVE_DEPOSIT,
    success: REMOVE_DEPOSIT_SUCCESS,
  },
  options,
  service: depositsApiClient,
  serviceMethod: 'remove',
})

export const clearSingle = () => ({
  type: CLEAR_DEPOSIT,
})
