import NurseriesWrapper from 'module/Nurseries/index'
import NurseriesEdit from './NurseriesEdit'
import NurseriesList from './NurseriesList'
import NurseriesPreview from './NurseriesPreview'
import NurseriesAdd from './NurseriesAdd'

export const ROUTE_NAMES = {
  'NURSERIES.ADD': 'NURSERIES.ADD',
  'NURSERIES.DETAILS': 'NURSERIES.DETAILS',
  'NURSERIES.EDIT': 'NURSERIES.EDIT',
  'NURSERIES.INDEX': 'NURSERIES.INDEX',
}

const NurseriesRouting = {
  childRoutes: [
    {
      components: NurseriesAdd,
      name: ROUTE_NAMES['NURSERIES.ADD'],
      path: 'add',
      title: 'Add a new nursery',
    },
    {
      childRoutes: [
        {
          component: NurseriesEdit,
          name: ROUTE_NAMES['NURSERIES.EDIT'],
          path: 'edit',
          title: 'Edit nursery',
        },
      ],
      indexRoute: {
        component: NurseriesPreview,
      },
      name: ROUTE_NAMES['NURSERIES.DETAILS'],
      path: ':nurseryId',
      title: 'Nursery details',
    },
  ],
  component: NurseriesWrapper,
  indexRoute: {
    component: NurseriesList,
  },
  name: ROUTE_NAMES['NURSERIES.INDEX'],
  path: 'nurseries',
  title: 'Nurseries',
}

NurseriesRouting.ROUTE_NAMES = ROUTE_NAMES

export default NurseriesRouting
