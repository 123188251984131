import { flatten, nest } from 'utils/flatnest'
import moment from 'moment'
import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withChildMovesService } from 'services/legacy/childMoves'
import { withRoomsService } from 'services/legacy/rooms'
import { withModalService } from 'services/utils/modal'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withSortingUtils } from 'services/utils/sorting'

import { Avatar, Typography } from 'components'

import i18n from 'translations'

import RoomsMovesView from './RoomsMovesView'
import { StyledHeaderOfConfirm } from './RoomsMovesStyled'

const DATE_FORMAT = 'YYYY-MM-DD'
const ROOMS_MOVES_COLUMNS = [
  {
    align: 'left',
    field: 'child',
    sortKey: 'child.firstName',
    sortable: true,
    title: _.upperFirst(i18n.t('global:child')),
  },
  {
    align: 'left',
    field: 'fromNurseryClass',
    sortKey: 'fromNurseryClass.name',
    sortable: true,
    title: 'Move from',
  },
  {
    align: 'left',
    field: 'toNurseryClass',
    sortKey: 'toNurseryClass.name',
    sortable: true,
    title: 'Move to',
  },
  {
    align: 'center',
    field: 'moveOn',
    sortKey: 'moveOn',
    sortable: true,
    title: 'Move date',
  },
  {
    align: 'center',
    field: 'moveOnChildMonths',
    sortKey: 'moveOnChildMonths',
    sortable: true,
    title: 'Move date age',
  },
  {
    field: 'action',
    title: '',
  },
]

class RoomsMovesContainer extends Component {
  constructor(props) {
    super(props)

    const { query } = props.location
    const { moveOn, room, search } = nest(query)

    const after = (moveOn && moveOn.after) ? moment(moveOn.after) : moment()
    const before = (moveOn && moveOn.before) ? moment(moveOn.before) : null

    this.state = {
      filters: {
        moveOn: {
          after: after && after.format(DATE_FORMAT),
          before: before && before.format(DATE_FORMAT),
        },
        room: room || '',
        search,
      },
    }
  }

  componentDidMount() {
    const { roomsActions, roomsSelectors } = this.props

    const criteria = roomsSelectors.getCriteriaSelector()

    roomsActions.list({ criteria })
    this.fetch()
  }

  fetch = () => {
    const {
      childMovesActions,
      childMovesSelectors,
      paginationUtils,
      params,
      setLocationQuery,
      sortingUtils,
    } = this.props
    const { filters } = this.state
    const { childId } = params

    if (childId) {
      filters.child = childId
    } else {
      filters.isArchived = false
    }

    const { page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const criteria = childMovesSelectors.getCriteriaSelector(filters)

    setLocationQuery(flatten(filters))

    const apiParams = {
      criteria,
      order: { sortField, sortOrder },
      page,
    }

    childMovesActions.list(apiParams)
  }

  handleRoomChange = (room) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        room: room ? room.value : null,
      },
    }), () => this.fetch())
  }

  handleSearchChange = (search) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        search,
      },
    }), () => this.fetch())
  }

  handleMoveChange = (dateRange) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    const [after, before] = dateRange
    const moveOn = {
      after: after ? moment(after).format(DATE_FORMAT) : undefined,
      before: before ? moment(before).format(DATE_FORMAT) : undefined,
    }

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, moveOn },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleSortChange = (key) => {
    const { paginationUtils, sortingUtils } = this.props
    const { onSortChange } = sortingUtils
    const { onPageChange } = paginationUtils

    onSortChange(onPageChange(this.fetch)(1))(key)
  }

  handleShowEditRoomMoveModal = ({ id }) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.EDIT_ROOM_MOVE, {
      childMoveId: id,
      onConfirm: () => this.fetch(),
    })
  }

  handleConfirmRoomMoveModal = ({ id }, unconfirm) => {
    const { childMovesActions, childMovesList, modalActions, modalConsts } = this.props
    const childMove = _.find(childMovesList, (item) => item.id === id)

    const {
      child: { firstName, photo, surname },
      fromNurseryClass,
      moveOnDate,
      toLeaving,
      toNurseryClass,
    } = childMove

    let text = null

    if (toLeaving) {
      text = `${firstName} leaves the ${fromNurseryClass.name} on ${moment(moveOnDate).format('DD/MM/YYYY')}.`
    } else {
      text = (
        <React.Fragment>
          {`${firstName} is moving to ${toNurseryClass.name} on ${moment(moveOnDate).format('DD/MM/YYYY')}.`}
          <br />
          {unconfirm
            ? 'All following confirmed room moves will be unconfirmed.'
            : 'All previous unconfirmed room moves will be confirmed.'}
        </React.Fragment>
      )
    }

    const fullName = `${firstName} ${surname}`

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: unconfirm ? 'Unconfirm' : 'Confirm',
      header: (
        <StyledHeaderOfConfirm>
          <Avatar
            direction="vertical"
            initials={[firstName, surname]}
            src={photo}
            title={(
              <Typography color={NEUTRAL_COLOURS.WHITE}>
                {fullName}
              </Typography>
            )}
          />
        </StyledHeaderOfConfirm>
      ),
      onConfirm: () => {
        const body = {
          confirmedAt: unconfirm ? null : new Date(),
        }

        return childMovesActions.updateChildMove(
          id,
          body,
          this.fetch,
        )
      },
      text,
    })
  }

  render() {
    const {
      childMovesIsFetching,
      childMovesMeta,
      childMovesTableList,
      errorMessages,
      paginationUtils,
      params,
      roomList,
      sortingUtils,
    } = this.props
    const { filters } = this.state
    const { childId } = params

    const { getPageCount, page, perPage } = paginationUtils
    const { sortField, sortOrder } = sortingUtils
    const { moveOn: { after, before }, room, search } = filters

    const moveOn = [after, before]
    const pageCount = getPageCount(childMovesMeta.totalResults)
    const roomDetails = _.find(roomList, ({ id }) => id === room)

    return (
      <RoomsMovesView
        childMoves={childMovesTableList}
        childVersion={!!childId}
        columns={ROOMS_MOVES_COLUMNS}
        errorMessages={errorMessages}
        isLoading={childMovesIsFetching}
        moveOn={moveOn}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        room={room}
        roomDetails={roomDetails}
        search={search}
        sortField={sortField}
        sortOrder={sortOrder}
        totalResults={childMovesMeta.totalResults}
        onConfirmRoomMoveModal={this.handleConfirmRoomMoveModal}
        onMoveChange={this.handleMoveChange}
        onPageChange={this.handlePageChange}
        onRoomChange={this.handleRoomChange}
        onSearchChange={this.handleSearchChange}
        onShowEditRoomMoveModal={this.handleShowEditRoomMoveModal}
        onSortChange={this.handleSortChange}
      />
    )
  }
}

RoomsMovesContainer.authParams = {
  flags: [FEATURE_FLAGS.OCCUPANCY_ENABLED],
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

const mapState = (state, {
  appSelectors,
  childMoves,
  childMovesSelectors,
  roomsSelectors,
}) => ({
  authAccessMap: {
    section: {
      PremiumSection: auth.SELECTORS.getIsAuthorised(state, {
        flags: [FEATURE_FLAGS.OCCUPANCY_ENABLED],
      }),
    },
  },
  childMovesIsFetching: childMovesSelectors.getChildMovesIsFetchingSelector(state),
  childMovesList: childMovesSelectors.getChildMovesListDataSelector(state),
  childMovesMeta: childMovesSelectors.getChildMovesListMetaSelector(state),
  childMovesTableList: childMovesSelectors.getChildMovesTableListDataSelector(state),
  errorMessages: appSelectors.getErrorMessages(childMoves.single, childMoves.list),
  roomList: roomsSelectors.getRoomsListDataSelector(state),
})

const enhance = compose(
  withAppService,
  withChildMovesService,
  withModalService,
  withRoomsService,
  withPaginationUtils,
  withRouterUtils,
  withSortingUtils,
  connect(mapState),
)

export default enhance(RoomsMovesContainer)
