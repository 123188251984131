/**
 * this normally is moment-precise-range npm plugin, but it needs some changes to be able to include by webpack loader.
 * (added import moment) and removed some "require" code.
 */

import moment from 'moment'

// eslint-disable-next-line no-shadow, func-names
(function (moment) {
  const STRINGS = {
    day: 'day',
    days: 'days',
    delimiter: ' ',
    hour: 'hour',
    hours: 'hours',
    minute: 'minute',
    minutes: 'minutes',
    month: 'month',
    months: 'months',
    nodiff: '',
    second: 'second',
    seconds: 'seconds',
    year: 'year',
    years: 'years',
  }

  function pluralize(num, word) {
    return `${num} ${STRINGS[word + (1 === num ? '' : 's')]}`
  }

  function buildStringFromValues(yDiff, mDiff, dDiff, hourDiff, minDiff, secDiff) {
    const result = []

    if (yDiff) {
      result.push(pluralize(yDiff, 'year'))
    }
    if (mDiff) {
      result.push(pluralize(mDiff, 'month'))
    }
    if (dDiff) {
      result.push(pluralize(dDiff, 'day'))
    }
    if (hourDiff) {
      result.push(pluralize(hourDiff, 'hour'))
    }
    if (minDiff) {
      result.push(pluralize(minDiff, 'minute'))
    }
    if (secDiff) {
      result.push(pluralize(secDiff, 'second'))
    }

    return result.join(STRINGS.delimiter)
  }
  
  // eslint-disable-next-line no-param-reassign,func-names
  moment.fn.preciseDiff = function (d2, returnValueObject) {
    return moment.preciseDiff(this, d2, returnValueObject)
  }
  
  // eslint-disable-next-line no-param-reassign,func-names
  moment.preciseDiff = function (d1, d2, returnValueObject) {
    let m1 = moment(d1)
    let m2 = moment(d2)
    let firstDateWasLater

    m1.add(m2.utcOffset() - m1.utcOffset(), 'minutes') // shift timezone of m1 to m2

    if (m1.isSame(m2)) {
      return STRINGS.nodiff
    }
    if (m1.isAfter(m2)) {
      const tmp = m1

      m1 = m2
      m2 = tmp
      firstDateWasLater = true
    } else {
      firstDateWasLater = false
    }

    let yDiff = m2.year() - m1.year()
    let mDiff = m2.month() - m1.month()
    let dDiff = m2.date() - m1.date()
    let hourDiff = m2.hour() - m1.hour()
    let minDiff = m2.minute() - m1.minute()
    let secDiff = m2.second() - m1.second()

    if (0 > secDiff) {
      secDiff = 60 + secDiff
      minDiff -= 1
    }
    if (0 > minDiff) {
      minDiff = 60 + minDiff
      hourDiff -= 1
    }
    if (0 > hourDiff) {
      hourDiff = 24 + hourDiff
      dDiff -= 1
    }
    if (0 > dDiff) {
      const daysInLastFullMonth = moment(`${m2.year()}-${m2.month() + 1}`, 'YYYY-MM')
        .subtract(1, 'M')
        .daysInMonth()

      if (daysInLastFullMonth < m1.date()) {
        // 31/01 -> 2/03
        dDiff = daysInLastFullMonth + dDiff + (m1.date() - daysInLastFullMonth)
      } else {
        dDiff = daysInLastFullMonth + dDiff
      }
      mDiff -= 1
    }
    if (0 > mDiff) {
      mDiff = 12 + mDiff
      yDiff -= 1
    }

    if (returnValueObject) {
      return {
        days: dDiff,
        firstDateWasLater,
        hours: hourDiff,
        minutes: minDiff,
        months: mDiff,
        seconds: secDiff,
        years: yDiff,
      }
    }

    return buildStringFromValues(yDiff, mDiff, dDiff, hourDiff, minDiff, secDiff)
  }
}(moment))
