export const ADD_CHILDREN_TO_OBSERVATION = 'ADD_CHILDREN_TO_OBSERVATION'

export const AUTOSAVE_OBSERVATION = 'AUTOSAVE_OBSERVATION'
export const AUTOSAVE_OBSERVATION_FAILED = 'AUTOSAVE_OBSERVATION_FAILED'
export const AUTOSAVE_OBSERVATION_SUCCESS = 'AUTOSAVE_OBSERVATION_SUCCESS'

export const CREATE_OBSERVATION = 'CREATE_OBSERVATION'
export const CREATE_OBSERVATION_FAILED = 'CREATE_OBSERVATION_FAILED'
export const CREATE_OBSERVATION_SUCCESS = 'CREATE_OBSERVATION_SUCCESS'

export const GET_OBSERVATION = 'GET_OBSERVATION'
export const GET_OBSERVATION_FAILED = 'GET_OBSERVATION_FAILED'
export const GET_OBSERVATION_SUCCESS = 'GET_OBSERVATION_SUCCESS'

export const REMOVE_CHILDREN_FROM_OBSERVATION = 'REMOVE_CHILDREN_FROM_OBSERVATION'

export const REMOVE_OBSERVATION = 'REMOVE_OBSERVATION'
export const REMOVE_OBSERVATION_FAILED = 'REMOVE_OBSERVATION_FAILED'
export const REMOVE_OBSERVATION_SUCCESS = 'REMOVE_OBSERVATION_SUCCESS'

export const SET_NEW_OBSERVATION = 'SET_NEW_OBSERVATION'

export const UPDATE_DRAFT_OBSERVATION = 'UPDATE_DRAFT_OBSERVATION'
export const UPDATE_DRAFT_OBSERVATION_FAILED = 'UPDATE_DRAFT_OBSERVATION_FAILED'
export const UPDATE_DRAFT_OBSERVATION_SUCCESS = 'UPDATE_DRAFT_OBSERVATION_SUCCESS'

export const UPDATE_OBSERVATION = 'UPDATE_OBSERVATION'
export const UPDATE_OBSERVATION_FAILED = 'UPDATE_OBSERVATION_FAILED'
export const UPDATE_OBSERVATION_SUCCESS = 'UPDATE_OBSERVATION_SUCCESS'

export const UPDATE_REVIEW_OBSERVATION = 'UPDATE_REVIEW_OBSERVATION'
export const UPDATE_REVIEW_OBSERVATION_FAILED = 'UPDATE_REVIEW_OBSERVATION_FAILED'
export const UPDATE_REVIEW_OBSERVATION_SUCCESS = 'UPDATE_REVIEW_OBSERVATION_SUCCESS'

export const CLEAR_OBSERVATION = 'CLEAR_OBSERVATION'
