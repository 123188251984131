import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Form, Typography } from 'components'

import i18n from 'translations'

const REQUESTED_EXTRA_SESSION_MODAL_FORM = 'RequestedExtraSessionModalForm'

const RequestedExtraSessionModalForm = ({
  handleSubmit,
  isApproveAction,
  isSubmitting,
  onCloseClick,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row
      label={(
        <Typography>
          <Typography variant="span">
            {isApproveAction
              ? i18n.t('module:Modals:RequestedExtraSession:Label:approve')
              : i18n.t('module:Modals:RequestedExtraSession:Label:decline')}
          </Typography>
          <Typography color={NEUTRAL_COLOURS.GRAY} margin="0 0 0 5px" variant="span">
            {`(${i18n.t('module:Modals:RequestedExtraSession:Label:willBeSentToParent')})`}
          </Typography>
        </Typography>
      )}
      verticalLabel
    >
      <Form.Row.FlexItem mobileFull>
        <Field
          component={Form.TextAreaField}
          name="reason"
          placeholder={isApproveAction
            ? i18n.t('module:Modals:RequestedExtraSession:Label:approve')
            : i18n.t('module:Modals:RequestedExtraSession:Label:decline')}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.FooterActions
      isSubmitting={isSubmitting}
      submitLabel={isApproveAction
        ? i18n.t('module:Modals:RequestedExtraSession:SubmitButtonLabel:approve')
        : i18n.t('module:Modals:RequestedExtraSession:SubmitButtonLabel:decline')}
      onCancelClick={onCloseClick}
    />
  </Form>
)

export default reduxForm({ form: REQUESTED_EXTRA_SESSION_MODAL_FORM })(RequestedExtraSessionModalForm)
