import React from 'react'
import { WrappedFieldProps } from 'redux-form'

import { MediaPicker } from 'components'

type FormMediaPickerFullProps = any & WrappedFieldProps

const FormMediaPicker: React.FC<FormMediaPickerFullProps> = ({
  fieldErrorEnabled = true,
  input: { onChange, value },
  meta: { error, submitFailed },
  ...rest
}) => (
  <MediaPicker
    {...rest}
    error={fieldErrorEnabled && submitFailed && error}
    value={value}
    editMode
    onChange={onChange}
  />
)

export default FormMediaPicker
