import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Typography } from 'components'

const ColumnTitle = ({ title }) => (
  <Typography
    color={NEUTRAL_COLOURS.BASIC}
    bold
  >
    {title}
  </Typography>
)

export default ColumnTitle
