import React from 'react'
import { Link } from 'react-router'

import {
  Avatar,
  Callout,
  Currency,
  DateString,
  DefinitionList,
  Section,
  Spinner,
  Table,
  Toolbar,
  Typography,
} from 'components'

import i18n from 'translations'

import { StyledLink } from './ChildFinanceCreditNotesPreviewStyled'

const FinanceCreditNotesPreviewView = ({
  creditNote,
  errorMessages,
  isLoading,
  onDownloadButtonClick,
  onSendButtonClick,
}) => {
  if (isLoading || !creditNote) {
    return (
      <Spinner />
    )
  }

  const renderCreditNote = () => {
    const {
      author,
      child,
      createdAt,
      description,
      invoice,
      issueDate,
      number,
      sentAt,
      total,
    } = creditNote
    const { id } = child

    const actions = (
      <Section.Actions
        options={[{
          onClick: onDownloadButtonClick,
          type: 'download',
        }, {
          onClick: onSendButtonClick,
          type: sentAt ? 'resend' : 'send',
        }]}
      />
    )

    return (
      <Section
        actions={actions}
        title={i18n.t('module:Children:Child:Finance:CreditNotes:title')}
        noMargin
      >
        <Toolbar>
          <Toolbar.Group>
            <Toolbar.Item>
              <DefinitionList vertical>
                <DefinitionList.Item
                  align="center"
                  label={i18n.t('module:Children:Child:Finance:CreditNotes:createdBy')}
                  value={author && (
                    <Avatar
                      avatarSize="small"
                      initials={author.name}
                      src={author.photo}
                      title={(
                        <Typography fontSize={14} primary>
                          {author.name}
                        </Typography>
                      )}
                    />
                  )}
                  horizontal
                />
                <DefinitionList.Item
                  label={i18n.t('module:Children:Child:Finance:CreditNotes:member')}
                  value={number}
                  horizontal
                />
                <DefinitionList.Item
                  label={i18n.t('module:Children:Child:Finance:CreditNotes:invoiceReference')}
                  value={(
                    <Toolbar>
                      <Toolbar.Group>
                        <Toolbar.Item>
                          <StyledLink>
                            <Link to={`/children/${id}/finance/invoices/${invoice.id}`}>
                              {invoice.number}
                            </Link>
                          </StyledLink>
                        </Toolbar.Item>
                        <Toolbar.Item>
                          {invoice.name}
                        </Toolbar.Item>
                        <Toolbar.Item>
                          <Currency value={invoice.total} />
                        </Toolbar.Item>
                      </Toolbar.Group>
                    </Toolbar>
                  )}
                  horizontal
                />
              </DefinitionList>
            </Toolbar.Item>
          </Toolbar.Group>
          <Toolbar.Group>
            <Toolbar.Item>
              <DefinitionList vertical>
                <DefinitionList.Item
                  label={i18n.t('module:Children:Child:Finance:CreditNotes:date')}
                  value={(
                    <DateString date={issueDate} />
                  )}
                  horizontal
                />
                <DefinitionList.Item
                  label={i18n.t('module:Children:Child:Finance:CreditNotes:createdOn')}
                  value={(
                    <DateString date={createdAt} />
                  )}
                  horizontal
                />
              </DefinitionList>
            </Toolbar.Item>
          </Toolbar.Group>
        </Toolbar>
        <Table>
          <Table.Tr>
            <Table.Th>
              {i18n.t('module:Children:Child:Finance:CreditNotes:Table:Header:itemType')}
            </Table.Th>
            <Table.Th>
              {i18n.t('global:Description')}
            </Table.Th>
            <Table.Th>
              {i18n.t('module:Children:Child:Finance:CreditNotes:Table:Header:amount')}
            </Table.Th>
          </Table.Tr>
          <Table.Tr>
            <Table.Td align="left">
              {i18n.t('module:Children:Child:Finance:CreditNotes:Table:Summary:creditNote')}
            </Table.Td>
            <Table.Td align="left">
              {description}
            </Table.Td>
            <Table.Td>
              <Currency value={total} />
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.SummaryTd colSpan={5}>
              <span>
                {i18n.t('global:Finance:Total')}
                {': '}
                <Currency value={total} />
              </span>
            </Table.SummaryTd>
          </Table.Tr>
        </Table>
      </Section>
    )
  }

  return (
    <div>
      <Callout content={errorMessages} error />
      {renderCreditNote()}
    </div>
  )
}

export default FinanceCreditNotesPreviewView
