import { generateBasicActions } from 'utils/service'

import {
  CLEAR_NURSERIES_LEAVING_REPORT,
  NURSERIES_LEAVING_REPORT,
  NURSERIES_LEAVING_REPORT_FAILED,
  NURSERIES_LEAVING_REPORT_SUCCESS,
} from './constants'

export const getNurseries = (options = {}) => ({ childrenLeavingReportApiClient }) => generateBasicActions.list({
  constants: {
    failed: NURSERIES_LEAVING_REPORT_FAILED,
    init: NURSERIES_LEAVING_REPORT,
    success: NURSERIES_LEAVING_REPORT_SUCCESS,
  },
  options,
  service: childrenLeavingReportApiClient,
  serviceMethod: 'getNurseriesLeaving',
})

export const clearNurseries = () => ({
  type: CLEAR_NURSERIES_LEAVING_REPORT,
})
