import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired, isValidInteger, isValidMinutes } from 'utils/fieldValidation'

import { Banner, CircleIcon, Form, Tooltip } from 'components'

import i18n from 'translations'

import { ChildFundingAllocatedWidget } from '../../components'

export const FORM_NAME = 'ChildFundingAddForm'

const ChildFundingAddForm = ({
  allocationData,
  allowOverrideHoursPerWeek,
  allowOverrideMaxHoursPerDay,
  child,
  excludedPeriods,
  fundingTypeOptions,
  handleSubmit,
  isEdit,
  isInfoBlockVisible,
  isLoading,
  onCancelClick,
  onPeriodChange,
  onSubmit,
}) => {
  const { amountUsed, hoursUsed, total, totalHours } = allocationData || {}
  const { firstName: childName } = child || {}
  const startDateDisabled = isEdit && !!amountUsed

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {isEdit && (
        <Form.Row>
          <Form.Row.Item>
            <ChildFundingAllocatedWidget
              amountUsed={amountUsed}
              hoursUsed={hoursUsed}
              totalAmount={total}
              totalHours={totalHours}
            />
          </Form.Row.Item>
        </Form.Row>
      )}
      <Form.Row>
        <Form.Row.Item>
          {i18n.t('module:Children:Child:BookingPattern:Funding:Add:subtitle', { childName })}
        </Form.Row.Item>
      </Form.Row>
      <Form.Row label={i18n.t('module:Children:Child:BookingPattern:Funding:Add:fundingTypeLabel')}>
        <Form.Row.FlexItem>
          <Field
            component={Form.Select}
            disabled={isEdit}
            name="fundingType"
            options={fundingTypeOptions}
            placeholder={i18n.t('module:Children:Child:BookingPattern:Funding:Add:fundingTypePlaceholder')}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:Children:Child:BookingPattern:Funding:Add:periodLabel')}>
        <Form.Row.Item>
          <Field
            component={Form.DatePicker}
            name="period"
            startDateDisabled={startDateDisabled}
            validate={isRequired}
            clearable
            range
            onChange={onPeriodChange}
          />
        </Form.Row.Item>
        {startDateDisabled && (
          <Form.Row.Item margin="0 0 0 5px">
            <Tooltip
              placement="right"
              title={i18n.t('module:Children:Child:BookingPattern:Funding:Add:periodTooltip')}
            >
              <CircleIcon
                cursor="pointer"
                icon="info"
                iconSize={16}
                size={24}
              />
            </Tooltip>
          </Form.Row.Item>
        )}
      </Form.Row>
      <Form.Row label={i18n.t('module:Children:Child:BookingPattern:Funding:Add:exclusionPeriodLabel')}>
        <Form.Row.Item>
          {excludedPeriods}
        </Form.Row.Item>
      </Form.Row>
      <Form.Row label={i18n.t('module:Children:Child:BookingPattern:Funding:Add:hourlyRateLabel')}>
        <Form.Row.FlexItem flex="0 0 150px">
          <Field
            component={Form.TextField}
            disabled={isEdit}
            name="hourlyRate"
            placeholder={i18n.t('module:Children:Child:BookingPattern:Funding:Add:hourlyRatePlaceholder')}
            step="any"
            type="number"
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Divider />
      <Form.Row label={i18n.t('module:Children:Child:BookingPattern:Funding:Add:hoursPerWeekLabel')}>
        <Form.Row.FlexItem flex="0 0 80px">
          <Field
            component={Form.TextField}
            disabled={!allowOverrideHoursPerWeek}
            name="settings.hoursPerWeek.hours"
            step={1}
            type="number"
            validate={isValidInteger}
          />
        </Form.Row.FlexItem>
        <Form.Row.TextItem text={i18n.t('global:hours')} />
        <Form.Row.FlexItem flex="0 0 80px">
          <Field
            component={Form.TextField}
            disabled={!allowOverrideHoursPerWeek}
            name="settings.hoursPerWeek.minutes"
            step={1}
            type="number"
            validate={isValidMinutes}
          />
        </Form.Row.FlexItem>
        <Form.Row.TextItem text={i18n.t('global:minutes')} />
      </Form.Row>
      <Form.Row label={i18n.t('module:Children:Child:BookingPattern:Funding:Add:maxHoursPerDay')}>
        <Form.Row.FlexItem flex="0 0 80px">
          <Field
            component={Form.TextField}
            disabled={!allowOverrideMaxHoursPerDay}
            name="settings.maxHoursPerDay.hours"
            step={1}
            type="number"
            validate={isValidInteger}
          />
        </Form.Row.FlexItem>
        <Form.Row.TextItem text={i18n.t('global:hours')} />
        <Form.Row.FlexItem flex="0 0 80px">
          <Field
            component={Form.TextField}
            disabled={!allowOverrideMaxHoursPerDay}
            name="settings.maxHoursPerDay.minutes"
            step={1}
            type="number"
            validate={isValidMinutes}
          />
        </Form.Row.FlexItem>
        <Form.Row.TextItem text={i18n.t('global:minutes')} />
      </Form.Row>
      <Form.Divider />
      <Form.Row label={i18n.t('module:Children:Child:BookingPattern:Funding:Add:totalHoursLabel')}>
        <Form.Row.FlexItem flex="0 0 80px">
          <Field
            component={Form.TextField}
            name="totalHours.hours"
            placeholder="0"
            step="any"
            type="number"
            validate={isValidInteger}
          />
        </Form.Row.FlexItem>
        <Form.Row.TextItem text={i18n.t('global:hours')} />
        <Form.Row.FlexItem flex="0 0 80px">
          <Field
            component={Form.TextField}
            name="totalHours.minutes"
            type="number"
            validate={isValidMinutes}
          />
        </Form.Row.FlexItem>
        <Form.Row.TextItem text={i18n.t('global:minutes')} />
        {isInfoBlockVisible && (
          <Form.Row.FlexItem>
            <Banner.Info>
              {i18n.t('module:Children:Child:BookingPattern:Funding:Add:totalHoursInfoBlock1')}
              <br />
              {i18n.t('module:Children:Child:BookingPattern:Funding:Add:totalHoursInfoBlock2')}
              <br />
              {i18n.t('module:Children:Child:BookingPattern:Funding:Add:totalHoursInfoBlock2')}
            </Banner.Info>
          </Form.Row.FlexItem>
        )}
      </Form.Row>
      <Form.Row label={i18n.t('module:Children:Child:BookingPattern:Funding:Add:totalFundingValueLabel')}>
        <Form.Row.FlexItem flex="0 0 150px">
          <Field
            component={Form.TextField}
            name="total"
            placeholder={i18n.t('module:Children:Child:BookingPattern:Funding:Add:totalFundingValuePlaceholder')}
            type="total"
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.FooterActions
        isSubmitting={isLoading}
        submitLabel={i18n.t('global:Save')}
        onCancelClick={onCancelClick}
      />
    </Form>
  )
}

export default reduxForm({ form: FORM_NAME })(ChildFundingAddForm)
