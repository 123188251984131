export const GET_CREDIT_NOTE = 'GET_CREDIT_NOTE'
export const GET_CREDIT_NOTE_SUCCESS = 'GET_CREDIT_NOTE_SUCCESS'
export const GET_CREDIT_NOTE_FAILED = 'GET_CREDIT_NOTE_FAILED'

export const CREATE_BULK_CREDIT_NOTE = 'CREATE_BULK_CREDIT_NOTE'
export const CREATE_BULK_CREDIT_NOTE_SUCCESS = 'CREATE_BULK_CREDIT_NOTE_SUCCESS'
export const CREATE_BULK_CREDIT_NOTE_FAILED = 'CREATE_BULK_CREDIT_NOTE_FAILED'

export const CREATE_CREDIT_NOTE = 'CREATE_CREDIT_NOTE'
export const CREATE_CREDIT_NOTE_SUCCESS = 'CREATE_CREDIT_NOTE_SUCCESS'
export const CREATE_CREDIT_NOTE_FAILED = 'CREATE_CREDIT_NOTE_FAILED'

export const SEND_CREDIT_NOTE = 'SEND_CREDIT_NOTE'
export const SEND_CREDIT_NOTE_SUCCESS = 'SEND_CREDIT_NOTE_SUCCESS'
export const SEND_CREDIT_NOTE_FAILED = 'SEND_CREDIT_NOTE_FAILED'

export const DOWNLOAD_CREDIT_NOTE = 'DOWNLOAD_CREDIT_NOTE'
export const DOWNLOAD_CREDIT_NOTE_SUCCESS = 'DOWNLOAD_CREDIT_NOTE_SUCCESS'
export const DOWNLOAD_CREDIT_NOTE_FAILED = 'DOWNLOAD_CREDIT_NOTE_FAILED'

export const CLEAR_CREDIT_NOTE = 'CLEAR_CREDIT_NOTE'
