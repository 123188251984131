import { generateBasicActions } from 'utils/service'

import {
  CREATE_NEW_CHANNEL,
  CREATE_NEW_CHANNEL_FAILED,
  CREATE_NEW_CHANNEL_SUCCESS,
  LEAVE_CHANNEL,
  LEAVE_CHANNEL_FAILED,
  LEAVE_CHANNEL_SUCCESS,
} from './constants'

export const createNewChannel = (options = {}) => ({ messagingApiClient }) => generateBasicActions.create({
  constants: {
    failed: CREATE_NEW_CHANNEL_FAILED,
    init: CREATE_NEW_CHANNEL,
    success: CREATE_NEW_CHANNEL_SUCCESS,
  },
  options,
  service: messagingApiClient,
  serviceMethod: 'createNewChannel',
})

export const leaveChannel = (options = {}) => ({ messagingApiClient }) => generateBasicActions.create({
  constants: {
    failed: LEAVE_CHANNEL_FAILED,
    init: LEAVE_CHANNEL,
    success: LEAVE_CHANNEL_SUCCESS,
  },
  options,
  service: messagingApiClient,
  serviceMethod: 'leaveChannel',
})
