import AccessForbidden from './AccessForbidden'
import DiaryLinkExpired from './DiaryLinkExpired'
import NotFound from './NotFound'

export const ROUTE_NAMES = {
  'VARIOUS.ACCESS_FORBIDDEN': 'VARIOUS.ACCESS_FORBIDDEN',
  'VARIOUS.DIARY_LINK_EXPIRED': 'VARIOUS.DIARY_LINK_EXPIRED',
  'VARIOUS.NOT_FOUND': 'VARIOUS.NOT_FOUND',
  'VARIOUS.STAFF_REGISTER': 'VARIOUS.STAFF_REGISTER',
}

const VariousRouting = [
  {
    component: NotFound,
    disableAuth: true,
    name: ROUTE_NAMES['VARIOUS.NOT_FOUND'],
    noBreadcrumb: true,
    path: '/not-found',
    title: 'Not found',
  },
  {
    component: AccessForbidden,
    disableAuth: true,
    name: ROUTE_NAMES['VARIOUS.ACCESS_FORBIDDEN'],
    noBreadcrumb: true,
    path: '/access-forbidden',
    title: 'Access forbidden',
  },
  {
    component: DiaryLinkExpired,
    disableAuth: true,
    name: ROUTE_NAMES['VARIOUS.DIARY_LINK_EXPIRED'],
    noBreadcrumb: true,
    path: '/your-link-has-expired',
  },
]

export default VariousRouting
