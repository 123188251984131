import React, { useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'
import { startSubmit, stopSubmit } from 'redux-form'

import { ROOM_TYPES } from 'services/legacy/rooms/constants'
import { UPDATE_NURSERY_SETTINGS_EVENT } from 'services/nurseries/constants'
import { RELOAD_GLOBAL_NURSERY_SETTINGS_EVENT } from 'services/nurseryContext/constants'
import { FEATURE_FLAGS, ROLES } from 'constants/security'
import { WrappedComponentType } from 'constants/types'

import auth from 'utils/auth'
import eventBus from 'utils/eventBus'

import { withAppService, withAppServiceProps } from 'services/app'
import { withNurseriesService, withNurseriesServiceProps } from 'services/nurseries'
import { withNurseryContextService, withNurseryContextServiceProps } from 'services/nurseryContext'
import { withSecurityService, withSecurityServiceProps } from 'services/security'
import { withRoomsService } from 'services/legacy/rooms'

import ParentCommunicationView from './ParentCommunicationView'

const NURSERY_SETTINGS_GROUP = {
  read: [
    'assessmentPeriodSettings',
    'nursery.details',
    'nursery.likeAndCommentMembers',
    'nursery.settings',
    'nurseryLikeAndCommentSettings',
    'nurseryLearningSettings',
    'nurserySettings',
    'nurserySettings.learning',
    'nurserySettings.likeAndComment',
    'nurserySettings.occupancy',
    'nurserySettings.messaging',
    'membership',
  ],
}

const ROOM_GROUPS = {
  read: [
    'membership',
    'nurseryClass.messagingRecipients',
  ],
}

const mapState = (state, {
  appSelectors,
  nurseriesSelectors,
  nurseriesSingleState,
  nurseryContextSelectors,
  params,
  roomsSelectors,
  securitySelectors,
}) => ({
  hasParentAppAccess: securitySelectors.hasParentAppAccessSelector(state),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState),
  isLikesAndCommentsEnabled: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.LIKES_AND_COMMENTS],
  }),
  isMessagingEnabled: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.MESSAGING],
  }),
  likeAndCommentMembers: nurseriesSelectors.getMembersWithAccessToLikesAndComments(state),
  nurseryLearningSettings: nurseriesSelectors.getNurseryLearningSettings(state),
  nurseryLikeAndCommentSettings: nurseryContextSelectors.getNurseryLikeAndCommentSettings(state),
  nurseryMessagingSettings: nurseriesSelectors.getNurseryMessagingSettings(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  rooms: roomsSelectors.getRoomsListDataSelector(state),
})

const mapDispatch = {
  injectValidation: (formName, data) => stopSubmit(formName, data),
  startSubmitForm: (formName) => startSubmit(formName),
}

const connector = connect(mapState, mapDispatch)

export type PropsFromRedux = ConnectedProps<typeof connector>

type ParentCommunicationContainerFullProps = PropsFromRedux
  & withAppServiceProps
  & withNurseryContextServiceProps
  & withNurseriesServiceProps
  & withSecurityServiceProps
  & any // withRoomsServiceProps

const ParentCommunicationContainer: WrappedComponentType<ParentCommunicationContainerFullProps> = ({
  hasParentAppAccess,
  injectValidation,
  isFetching,
  isLikesAndCommentsEnabled,
  isMessagingEnabled,
  likeAndCommentMembers,
  nurseriesActions,
  nurseriesSelectors,
  nurseriesSingleState,
  nurseryLearningSettings,
  nurseryLikeAndCommentSettings,
  nurseryMessagingSettings,
  nurseryOptions,
  rooms,
  roomsActions,
  startSubmitForm,
}) => {
  const [roomsIsFetching, setRoomsIsFetching] = useState<boolean>(true)

  const getRoomLists = (page = 1) => {
    const apiParams = {
      criteria: [
        {
          field: 'archived',
          value: false,
        },
        {
          field: 'type',
          value: ROOM_TYPES.TEACHING,
        },
      ],
      groups: ROOM_GROUPS,
      page,
    }

    roomsActions.list(apiParams, 1 !== page, ({ meta: { limit, start, total_results: totalResults } }) => {
      if (start * limit < totalResults) {
        getRoomLists(start + 1)
      } else {
        setRoomsIsFetching(false)
      }
    })
  }

  const getNurseryData = () => {
    const nurseryApiParams = { groups: NURSERY_SETTINGS_GROUP }

    nurseriesActions.get(nurseryOptions.id, {
      onSuccess: (response) => {
        eventBus.dispatch(UPDATE_NURSERY_SETTINGS_EVENT, response)
      },
      params: nurseryApiParams,
    })
  }

  useEffect(() => {
    roomsActions.clear()
    getNurseryData()
    getRoomLists()
  }, [])

  const handleReloadRooms = () => {
    setRoomsIsFetching(true)
    roomsActions.clear()
    getRoomLists()
  }

  const handleSubmitSuccess = (callback) => (response) => {
    getNurseryData()
    eventBus.dispatch(RELOAD_GLOBAL_NURSERY_SETTINGS_EVENT)

    callback(response)
  }

  const handleSubmit = (fields) => (successCallback, failedCallback = () => {}) => {
    const payload = nurseriesSelectors.getNurserySettingsPayload({
      fields,
      nurseriesSingleState,
    })

    nurseriesActions.update(nurseryOptions.id, {
      onFailed: failedCallback,
      onSuccess: handleSubmitSuccess(successCallback),
      payload,
      silent: true,
    })
  }

  return (
    <ParentCommunicationView
      hasParentAppAccess={hasParentAppAccess}
      injectValidation={injectValidation}
      isFetching={isFetching}
      isLikesAndCommentsEnabled={isLikesAndCommentsEnabled}
      isMessagingEnabled={isMessagingEnabled}
      likeAndCommentMembers={likeAndCommentMembers}
      nurseryLearningSettings={nurseryLearningSettings}
      nurseryLikeAndCommentSettings={nurseryLikeAndCommentSettings}
      nurseryMessagingSettings={nurseryMessagingSettings}
      rooms={rooms}
      roomsIsFetching={roomsIsFetching}
      startSubmitForm={startSubmitForm}
      onReload={getNurseryData}
      onRoomListsReload={handleReloadRooms}
      onSubmit={handleSubmit}
    />
  )
}

ParentCommunicationContainer.authParams = {
  flags: [FEATURE_FLAGS.HAS_PARENT_APP_ACCESS],
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

const enhance = compose(
  withAppService,
  withNurseriesService,
  withNurseryContextService,
  withRoomsService,
  withSecurityService,
  connector,
)

export default enhance(ParentCommunicationContainer)
