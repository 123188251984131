import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledTableWrapper = styled.div`
  tbody {
    td {
      padding: 4px;
      
      &:hover {
        background: #F3F3F3;
        cursor: pointer;
      }
    }
  }
`

export const StyledHeadTr = styled.tr`
  ${({ isSticky }) => isSticky && `
    th {
      border-bottom: 2px solid ${NEUTRAL_COLOURS.GRAY_SECONDARY};
      border-left: 2px solid ${NEUTRAL_COLOURS.GRAY_SECONDARY};
      border-right: 2px solid ${NEUTRAL_COLOURS.GRAY_SECONDARY};
      background: ${NEUTRAL_COLOURS.WHITE};
      padding-top: 8px;
      
      &:nth-of-type(1) {
        border-left: 1px solid ${NEUTRAL_COLOURS.GRAY_SECONDARY};
      }
      
      &:nth-last-of-type(1) {
        border-right: 1px solid ${NEUTRAL_COLOURS.GRAY_SECONDARY};
      }
    }
  `}
`
