import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_DAILY_DIARY_TOILET_TRAINING_WITHOUT_RECORDS,
  GET_DAILY_DIARY_TOILET_TRAINING_WITHOUT_RECORDS,
  GET_DAILY_DIARY_TOILET_TRAINING_WITHOUT_RECORDS_FAILED,
  GET_DAILY_DIARY_TOILET_TRAINING_WITHOUT_RECORDS_SUCCESS,
} from './constants'

export const childrenListWithoutRecords = (
  options: BasicActionOptionsProps,
) => ({ dailyDiaryToiletTrainingApiClient }) => generateBasicActions.list({
  constants: {
    failed: GET_DAILY_DIARY_TOILET_TRAINING_WITHOUT_RECORDS_SUCCESS,
    init: GET_DAILY_DIARY_TOILET_TRAINING_WITHOUT_RECORDS,
    success: GET_DAILY_DIARY_TOILET_TRAINING_WITHOUT_RECORDS_FAILED,
  },
  options,
  service: dailyDiaryToiletTrainingApiClient,
  serviceMethod: 'list',
})

export const clearListWithoutRecords = () => ({
  type: CLEAR_DAILY_DIARY_TOILET_TRAINING_WITHOUT_RECORDS,
})
