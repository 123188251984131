import { combineReducers } from 'redux'

import list from './list/reducer'
import signatures from './signatures/reducer'
import single from './single/reducer'

export default combineReducers({
  list,
  signatures,
  single,
})
