import React from 'react'

import { ModalBox } from 'components'

import i18n from 'translations'

import AddFundingToRegularBookingsModalForm, {
  AddFundingToRegularBookingsModalFormProps,
  AddFundingToRegularBookingsModalFormValues,
} from './components/AddFundingToRegularBookingsModalForm'

interface AddFundingToRegularBookingsModalViewProps {
  initialValues: AddFundingToRegularBookingsModalFormValues
  isEditMode: boolean
}

const AddFundingToRegularBookingsModalView: React.FC<
  AddFundingToRegularBookingsModalFormProps
  & AddFundingToRegularBookingsModalViewProps
> = ({
  alternate,
  isEditMode,
  onCloseClick,
  ...rest
}) => (
  <ModalBox
    maxWidth={680}
    title={i18n.t(
      isEditMode ? 'modals:AddFundingToRegularBookings:editTitle' : 'modals:AddFundingToRegularBookings:title',
      { week: alternate },
    )}
    onCloseClick={onCloseClick}
  >
    <AddFundingToRegularBookingsModalForm
      alternate={alternate}
      onCloseClick={onCloseClick}
      {...rest}
    />
  </ModalBox>
)

export default AddFundingToRegularBookingsModalView
