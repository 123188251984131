export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'
export const DISPLAY_SHORT_DATE_FORMAT = 'DD/MM/YYYY'
export const DISPLAY_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm'
export const DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT = 'DD MMM YYYY'
export const FULL_MONTH_NAME_FORMAT = 'MMMM'

/* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */
export const WEEK_DAYS = {
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday',
  SUNDAY: 'sunday',
}
/* eslint-enable sort-keys, sort-keys-fix/sort-keys-fix */

export const NUMBER_OF_MONTHS = 12

export const hour24InMilliseconds = 24 * 60 * 60 * 1000

export type DaysOfWeek = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'

export const DAYS_OF_WEEK_LIST = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]
