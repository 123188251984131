import _ from 'lodash'

export const getInitialValues = () => ({
  children: [
    {
      child: null,
      sessions: [{}],
    },
  ],
})

export const getPayload = (fields, entryDate) => {
  if (!fields) {
    return null
  }

  const { children } = fields
  const takenDate = entryDate
  const payload = []

  _.forEach(children, (childItem) => {
    const { child, sessions } = childItem
    const { value } = child

    _.forEach(sessions, (sessionItem) => {
      const { endTime, startTime } = sessionItem
      const session = sessionItem.sessionId

      payload.push({
        childId: value,
        session: {
          endTime,
          nurserySession: session && session.value,
          startTime,
          takenDate,
        },
      })
    })
  })

  return payload
}
