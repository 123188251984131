import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as financeBadDebtReportSelectors from './selectors'

const withFinanceBadDebtReportService = (WrappedComponent) => {
  const mapState = ({ financeBadDebtReport }) => ({ financeBadDebtReport })

  const mapDispatch = injectActions('financeBadDebtReportActions', actions)

  const Component = ({ financeBadDebtReport, financeBadDebtReportActions, ...others }) => (
    <WrappedComponent
      financeBadDebtReportActions={financeBadDebtReportActions}
      financeBadDebtReportChildrenState={financeBadDebtReport.children}
      financeBadDebtReportNurseriesState={financeBadDebtReport.nurseries}
      financeBadDebtReportSelectors={financeBadDebtReportSelectors}
      financeBadDebtReportStatisticsState={financeBadDebtReport.statistics}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withFinanceBadDebtReportService
