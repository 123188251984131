import React from 'react'
import { Field } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { Form } from 'components'

import { BULK_MARK_PROGRESS_OPTIONS } from 'services/legacy/childFrameworkProgress/constants'

const FrameworkBulkMarkFields = ({
  ageBandOptions,
  areaOptions,
  groupOptions,
}) => (
  <React.Fragment>
    <Form.Row>
      <Form.Row.Item>
        Select what you would like to mark.
      </Form.Row.Item>
    </Form.Row>

    <Form.Row label="Mark as" required>
      <Form.Row.Item>
        <Field
          component={Form.Select}
          name="markAs"
          options={BULK_MARK_PROGRESS_OPTIONS}
          placeholder="Select Progress"
          validate={isRequired}
        />
      </Form.Row.Item>
    </Form.Row>
    <Form.Row label="Date" required>
      <Form.Row.FlexItem flex="0 0 100px">
        <Field
          component={Form.DatePicker}
          name="date"
          placeholder="Date"
          validate={isRequired}
          clearable
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.Row label="Age band" required>
      <Form.Row.Item>
        <Field
          component={Form.Select}
          name="ageBand"
          options={ageBandOptions}
          placeholder="Select age band"
          validate={isRequired}
        />
      </Form.Row.Item>
    </Form.Row>
    <Form.Row label>
      <Form.Row.Item>
        <Field
          component={Form.Checkbox}
          label="Mark all age bands up to and including the selected as secure"
          name="markAll"
        />
      </Form.Row.Item>
    </Form.Row>
    <Form.Row label="Area of learning" required>
      <Form.Row.FlexItem>
        <Field
          component={Form.Select}
          name="area"
          options={areaOptions}
          placeholder="Select area of learning"
          validate={isRequired}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.Row label="Aspect" required>
      <Form.Row.FlexItem>
        <Field
          clearable={false}
          component={Form.Select}
          name="aspect"
          options={groupOptions}
          placeholder="Select aspect"
          validate={isRequired}
        />
      </Form.Row.FlexItem>
    </Form.Row>
  </React.Fragment>
)

export default FrameworkBulkMarkFields
