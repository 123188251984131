import { BasicSingleInitialState } from 'utils/reducer'

import {
  CLEAR_ORGANIZATION,
  CREATE_ORGANIZATION,
  CREATE_ORGANIZATION_FAILED,
  CREATE_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION,
  GET_ORGANIZATION_FAILED,
  GET_ORGANIZATION_FLAGS,
  GET_ORGANIZATION_FLAGS_FAILED,
  GET_ORGANIZATION_FLAGS_SUCCESS,
  GET_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_FAILED,
  UPDATE_ORGANIZATION_FLAG,
  UPDATE_ORGANIZATION_FLAG_FAILED,
  UPDATE_ORGANIZATION_FLAG_SUCCESS,
  UPDATE_ORGANIZATION_SUCCESS,
} from './constants'

export interface OrganizationSingleState extends BasicSingleInitialState {
  flags?: any
  isFlagSubmitting?: boolean
}

const initialState: OrganizationSingleState = {
  data: null,
  error: null,
  flags: null,
  isFetching: false,
  isFlagSubmitting: false,
  isSubmitting: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case GET_ORGANIZATION:
    case GET_ORGANIZATION_FLAGS:
      return {
        ...state,
        error: initialState.error,
        isFetching: true,
      }
    case GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        data: payload,
        error: initialState.error,
        isFetching: false,
      }
    case GET_ORGANIZATION_FLAGS_SUCCESS:
      return {
        ...state,
        error: initialState.error,
        flags: payload,
        isFetching: false,
      }
    case GET_ORGANIZATION_FAILED:
    case GET_ORGANIZATION_FLAGS_FAILED:
      return {
        ...state,
        error,
        isFetching: false,
      }
    case CREATE_ORGANIZATION:
    case UPDATE_ORGANIZATION:
      return {
        ...state,
        error: initialState.error,
        isSubmitting: true,
      }
    case CREATE_ORGANIZATION_SUCCESS:
    case UPDATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        data: payload,
        error: initialState.error,
        isSubmitting: false,
      }
    case CREATE_ORGANIZATION_FAILED:
    case UPDATE_ORGANIZATION_FAILED:
      return {
        ...state,
        error,
        isSubmitting: false,
      }
    case UPDATE_ORGANIZATION_FLAG:
      return {
        ...state,
        error: initialState.error,
        isFlagSubmitting: true,
      }
    case UPDATE_ORGANIZATION_FLAG_SUCCESS:
      return {
        ...state,
        error: initialState.error,
        isFlagSubmitting: false,
      }
    case UPDATE_ORGANIZATION_FLAG_FAILED:
      return {
        ...state,
        error,
        isFlagSubmitting: false,
      }
    case CLEAR_ORGANIZATION:
      return initialState
    default:
      return state
  }
}
