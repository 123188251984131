import styled from 'styled-components'

import colors from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledTitle = styled.strong`
  min-width: 100px;
  display: inline-block;
  
  ${({ senary }) => senary && `
    color: ${getBrandingColor('senary-color')};
  `}
`

export const StyledValue = styled.span`
  ${({ senary }) => senary && `
    color: ${getBrandingColor('senary-color')};
  `}  
  
  ${({ red }) => red && `
    color: ${colors.remove};
  `}  
  
  ${({ cancelled }) => cancelled && `
    opacity: 0.4;
  `}
`
