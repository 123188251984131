import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class RelationsApiClient extends ServiceBase {
  list = (params) => this.request({
    path: `/v2/relations?${getQueryStringV2(params)}`,
  })

  get = (id, params) => this.request({
    path: `/v2/relations/${id}?${getQueryStringV2(params)}`,
  })

  create = (params, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/relations?${getQueryStringV2(params)}`,
  })

  remove = (id) => this.request({
    method: 'DELETE',
    path: `/v2/relations/${id}`,
  })

  update = (id, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/relations/${id}`,
  })
}

export default RelationsApiClient
