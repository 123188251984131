import styled from 'styled-components'

interface StyledWrapperProps {
  $fullHeight?: boolean
  $inline?: boolean
}

export const StyledWrapper = styled.div<StyledWrapperProps>`
  display: block;

  ${({ $fullHeight }) => $fullHeight && `
    height: 100%;
  `}  
  
  ${({ $inline }) => $inline && `
    display: inline-block;
  `}
`

export const StyledStorybookWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;  
`
