export const CREATE_EXCEPTIONAL_UNAVAILABLE_TIME = 'CREATE_EXCEPTIONAL_UNAVAILABLE_TIME'
export const CREATE_EXCEPTIONAL_UNAVAILABLE_TIME_FAILED = 'CREATE_EXCEPTIONAL_UNAVAILABLE_TIME_FAILED'
export const CREATE_EXCEPTIONAL_UNAVAILABLE_TIME_SUCCESS = 'CREATE_EXCEPTIONAL_UNAVAILABLE_TIME_SUCCESS'

export const DELETE_EXCEPTIONAL_UNAVAILABLE_TIME = 'DELETE_EXCEPTIONAL_UNAVAILABLE_TIME'
export const DELETE_EXCEPTIONAL_UNAVAILABLE_TIME_FAILED = 'DELETE_EXCEPTIONAL_UNAVAILABLE_TIME_FAILED'
export const DELETE_EXCEPTIONAL_UNAVAILABLE_TIME_SUCCESS = 'DELETE_EXCEPTIONAL_UNAVAILABLE_TIME_SUCCESS'

export const GET_EXCEPTIONAL_UNAVAILABLE_TIME = 'GET_EXCEPTIONAL_UNAVAILABLE_TIME'
export const GET_EXCEPTIONAL_UNAVAILABLE_TIME_FAILED = 'GET_EXCEPTIONAL_UNAVAILABLE_TIME_FAILED'
export const GET_EXCEPTIONAL_UNAVAILABLE_TIME_SUCCESS = 'GET_EXCEPTIONAL_UNAVAILABLE_TIME_SUCCESS'

export const UPDATE_EXCEPTIONAL_UNAVAILABLE_TIME = 'UPDATE_EXCEPTIONAL_UNAVAILABLE_TIME'
export const UPDATE_EXCEPTIONAL_UNAVAILABLE_TIME_FAILED = 'UPDATE_EXCEPTIONAL_UNAVAILABLE_TIME_FAILED'
export const UPDATE_EXCEPTIONAL_UNAVAILABLE_TIME_SUCCESS = 'UPDATE_EXCEPTIONAL_UNAVAILABLE_TIME_SUCCESS'

export const CLEAR_EXCEPTIONAL_UNAVAILABLE_TIME = 'CLEAR_EXCEPTIONAL_UNAVAILABLE_TIME'
