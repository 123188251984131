import _ from 'lodash'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { LEAVE_SHIFT_TYPES } from 'services/legacy/membershipsLeaves/constants'

import i18n from 'translations'

export const AVAILABLE_DAYS = 7

export const SHIFT_MAIN_TYPES = {
  LEAVE: 'leave',
  WORK: 'shift',
}

export const SHIFT_MAIN_TYPES_COLOURS = {
  [SHIFT_MAIN_TYPES.LEAVE]: '#FDECBB',
  [SHIFT_MAIN_TYPES.WORK]: '#C5E8F6',
}

export const SHIFT_MAIN_TYPES_LABELS = {
  [SHIFT_MAIN_TYPES.LEAVE]: i18n.t('services:MembershipRegisters:mainTypes:leave'),
  [SHIFT_MAIN_TYPES.WORK]: i18n.t('services:MembershipRegisters:mainTypes:work'),
}

export const WORK_SHIFT_BREAK_PARENT_TYPES = 'break'

export const WORK_SHIFT_TYPES = {
  BREAK: 'breakExact',
  BREAK_DURATION: 'breakDuration',
  CONTRACTED: 'contracted',
  OVERTIME: 'overtime',
}

export const STAFF_REGISTER_TYPES = {
  BREAK: 'break',
  WORK: 'signin',
}

export const WORK_SHIFTS = {
  [WORK_SHIFT_TYPES.BREAK]: {
    label: i18n.t('services:MembershipRegisters:workTypes:break'),
  },
  [WORK_SHIFT_TYPES.BREAK_DURATION]: {
    label: i18n.t('services:MembershipRegisters:workTypes:breakDuration'),
  },
  [WORK_SHIFT_TYPES.CONTRACTED]: {
    label: i18n.t('services:MembershipRegisters:workTypes:contracted'),
  },
  [WORK_SHIFT_TYPES.OVERTIME]: {
    label: i18n.t('services:MembershipRegisters:workTypes:overtime'),
  },
  [WORK_SHIFT_BREAK_PARENT_TYPES]: {
    label: i18n.t('module:Modals:Staff:Shift:break'),
  },
}

export const STAFF_ENTRY_TYPES = {
  BREAK: 'break',
  HOLIDAY: 'holiday',
  SICKNESS: 'sickness',
  SIGN_IN: 'signin',
}

export const STAFF_REGISTER_STATUS = {
  CLOCKED_IN: 'CLOCKED_IN',
  CLOCKED_OUT: 'CLOCKED_OUT',
  ON_BREAK: 'ON_BREAK',
}

export const STAFF_REGISTER_STATUS_MAPPING = {
  [STAFF_REGISTER_STATUS.CLOCKED_IN]: STAFF_ENTRY_TYPES.SIGN_IN,
  [STAFF_REGISTER_STATUS.CLOCKED_OUT]: STAFF_ENTRY_TYPES.SIGN_IN,
  [STAFF_REGISTER_STATUS.ON_BREAK]: STAFF_ENTRY_TYPES.BREAK,
}

export const STAFF_REGISTER_STATUS_OPTIONS = [
  {
    label: i18n.t('services:MembershipRegisters:clockedIn'),
    value: STAFF_REGISTER_STATUS.CLOCKED_IN,
  },
  {
    label: i18n.t('services:MembershipRegisters:onBreak'),
    value: STAFF_REGISTER_STATUS.ON_BREAK,
  },
  {
    label: i18n.t('services:MembershipRegisters:clockedOut'),
    value: STAFF_REGISTER_STATUS.CLOCKED_OUT,
  },
]

export const STAFF_REGISTER_FILTERS = {
  CLOCKED_IN: 'clocked_in',
  CLOCKED_OUT: 'clocked_out',
  INSIDE_BUILDING: 'inside_building',
  ON_BREAK: 'on_break',
  OUTSIDE_BUILDING: 'outside_building',
}

/* @deprecated */
export const STAFF_SHIFT_COLOURS = {
  [STAFF_ENTRY_TYPES.SIGN_IN]: {
    background: '#EDF8FC',
    basic: NEUTRAL_COLOURS.BLUE,
    header: '#C5E8F6',
  },
  [SHIFT_MAIN_TYPES.WORK]: {
    background: '#EDF8FC',
    basic: NEUTRAL_COLOURS.BLUE,
    header: '#C5E8F6',
  },
  [WORK_SHIFT_TYPES.BREAK]: {
    background: '#F3F0FF',
    basic: '#B19CD9',
    header: '#E2DBF5',
  },
  [LEAVE_SHIFT_TYPES.HOLIDAY]: {
    background: '#FEF9EA',
    basic: '#F6BA36',
    header: '#FDECBB',
  },
  [LEAVE_SHIFT_TYPES.SICK]: {
    background: '#FEF3F6',
    basic: '#fa6e90',
    header: '#FDD8E1',
  },
  [SHIFT_MAIN_TYPES.LEAVE]: {
    background: '#FFF4F1',
    basic: '#ffb2a3',
    header: '#FFE0D9',
  },
}

export const STAFF_REGISTER_FILTER_OPTIONS = [
  {
    label: i18n.t('services:MembershipRegisters:clockedIn'),
    value: STAFF_REGISTER_FILTERS.CLOCKED_IN,
  },
  {
    label: i18n.t('services:MembershipRegisters:onBreak'),
    value: STAFF_REGISTER_FILTERS.ON_BREAK,
  },
  {
    label: i18n.t('services:MembershipRegisters:clockedOut'),
    value: STAFF_REGISTER_FILTERS.CLOCKED_OUT,
  },
  {
    label: i18n.t('services:MembershipRegisters:insideBuilding'),
    value: STAFF_REGISTER_FILTERS.INSIDE_BUILDING,
  },
  {
    label: i18n.t('services:MembershipRegisters:outsideBuilding'),
    value: STAFF_REGISTER_FILTERS.OUTSIDE_BUILDING,
  },
]

export const STAFF_ENTRY_WORKLOG_MAPPINGS = {
  [STAFF_ENTRY_TYPES.SIGN_IN]: {
    label: _.upperFirst(i18n.t('services:MembershipRegisters:logTypes:shift')),
    totalKey: 'workedMilliseconds',
  },
  [STAFF_ENTRY_TYPES.BREAK]: {
    label: _.upperFirst(i18n.t('services:MembershipRegisters:logTypes:break')),
    totalKey: 'breakMilliseconds',
  },
}

export const STAFF_ENTRY_SHIFTS_MAPPINGS = {
  [STAFF_ENTRY_TYPES.HOLIDAY]: {
    label: _.upperFirst(i18n.t('services:MembershipRegisters:logTypes:holiday')),
  },
  [STAFF_ENTRY_TYPES.SICKNESS]: {
    label: _.upperFirst(i18n.t('services:MembershipRegisters:logTypes:sick')),
  },
}

export const STAFF_ENTRY_MAPPINGS = {
  ...STAFF_ENTRY_WORKLOG_MAPPINGS,
  ...STAFF_ENTRY_SHIFTS_MAPPINGS,
}

export const PLANNED_TYPE_OPTIONS = [
  {
    label: WORK_SHIFTS[WORK_SHIFT_TYPES.CONTRACTED].label,
    value: WORK_SHIFT_TYPES.CONTRACTED,
  },
  {
    label: WORK_SHIFTS[WORK_SHIFT_TYPES.OVERTIME].label,
    value: WORK_SHIFT_TYPES.OVERTIME,
  },
  {
    label: WORK_SHIFTS[WORK_SHIFT_TYPES.BREAK].label,
    value: WORK_SHIFT_TYPES.BREAK,
  },
  {
    label: WORK_SHIFTS[WORK_SHIFT_TYPES.BREAK_DURATION].label,
    value: WORK_SHIFT_TYPES.BREAK_DURATION,
  },
]

export const ACTUAL_TYPE_OPTIONS = [
  {
    label: i18n.t('services:MembershipRegisters:work'),
    value: STAFF_REGISTER_TYPES.WORK,
  },
  {
    label: i18n.t('module:Modals:Staff:Shift:break'),
    value: STAFF_REGISTER_TYPES.BREAK,
  },
]

export const STAFF_ENTRY_OPTIONS = [
  {
    label: STAFF_ENTRY_WORKLOG_MAPPINGS[STAFF_ENTRY_TYPES.SIGN_IN].label,
    value: STAFF_ENTRY_TYPES.SIGN_IN,
  },
  {
    label: STAFF_ENTRY_WORKLOG_MAPPINGS[STAFF_ENTRY_TYPES.BREAK].label,
    value: STAFF_ENTRY_TYPES.BREAK,
  },
]

export const STAFF_STATISTICS_KEY_MAPPING = {
  [STAFF_REGISTER_FILTERS.CLOCKED_IN]: 'totalClockedIn',
  [STAFF_REGISTER_FILTERS.CLOCKED_OUT]: 'totalClockedOut',
  [STAFF_REGISTER_FILTERS.INSIDE_BUILDING]: 'totalInsideBuilding',
  [STAFF_REGISTER_FILTERS.ON_BREAK]: 'totalOnBreak',
  [STAFF_REGISTER_FILTERS.OUTSIDE_BUILDING]: 'totalOutsideBuilding',
}

export const WORKLOG_RECORD_DAY_TYPE = {
  IN_BETWEEN_DAY: 'IN_BETWEEN_DAY',
  LAST_DAY: 'LAST_DAY',
  SAME_DAY: 'SAME_DAY',
}
