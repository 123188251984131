import i18n from 'translations'

export const EXPORT_FOR = {
  GENERAL: 'other',
  QUICKBOOKS: 'quick-books',
  SAGE: 'sage50',
  XERO: 'xero',
}

export const ALL_CHILDREN = {
  label: i18n.t('module:Modals:ChildrenExport:allChildren'),
  noAvatar: true,
  value: 0,
}
