import * as currenciesActions from './currencies/actions'
import * as disconnectConditionsActions from './disconnectConditions/actions'
import * as listActions from './list/actions'
import * as singleActions from './single/actions'
import * as stripeActions from './stripe/actions'
import * as statisticsActions from './statistics/actions'
import * as failedRecurringPayment from './failedRecurringPayment/actions'
import * as activeRecurringPayment from './activeRecurringPayment/actions'
import * as payoutTransactions from './payoutTransactions/actions'

export default {
  ...activeRecurringPayment,
  ...currenciesActions,
  ...disconnectConditionsActions,
  ...singleActions,
  ...statisticsActions,
  ...stripeActions,
  ...listActions,
  ...failedRecurringPayment,
  ...payoutTransactions,
}
