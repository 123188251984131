import { TooltipProps as TooltipMaterialProps } from '@material-ui/core/Tooltip'

import React, { PropsWithChildren } from 'react'

import { CircleIcon, Tooltip } from 'components'

import FormRowFlexItem from './FormRowFlexItem'
import FormRowItem from './FormRowItem'
import FormRowTextItem from './FormRowTextItem'
import {
  StyledRequiredChar,
  StyledRowChildrenContainer,
  StyledRowContainer,
  StyledRowLabelContainer,
  StyledTooltip,
} from './FormRowStyled'

interface FormRowRoot<T> extends React.FC<T> {
  FlexItem?: typeof FormRowFlexItem
  Item?: typeof FormRowItem
  TextItem?: typeof FormRowTextItem
}

interface FormRowProps {
  autoHeight?: boolean
  fontWeight?: string
  label?: string | boolean | JSX.Element
  labelAutoHeight?: boolean
  labelMargin?: string
  margin?: string
  multipleFieldsInARow?: boolean
  onClick?: () => void
  padding?: string
  required?: boolean
  tooltip?: {
    placement: TooltipMaterialProps['placement']
    title: string
  }
  verticalLabel?: boolean
  width?: {
    field?: string
    label?: string
  }
}

const FormRow: FormRowRoot<PropsWithChildren<FormRowProps>> = ({
  autoHeight,
  children,
  fontWeight,
  label,
  labelMargin,
  margin,
  multipleFieldsInARow = false,
  onClick,
  padding,
  required,
  tooltip,
  verticalLabel,
  width,
}) => {
  const renderTooltip = () => (
    <StyledTooltip>
      <Tooltip
        placement={tooltip?.placement || 'right'}
        title={tooltip?.title}
      >
        <CircleIcon
          cursor="pointer"
          icon="info"
          iconSize={14}
          size={20}
        />
      </Tooltip>
    </StyledTooltip>
  )

  const renderLabel = () => (
    <StyledRowLabelContainer
      $autoHeight={autoHeight}
      $fontWeight={fontWeight}
      $labelMargin={labelMargin}
    >
      <div onClick={onClick}>
        {label}
        {required && (
          <StyledRequiredChar>
            *
          </StyledRequiredChar>
        )}
      </div>
      {tooltip && renderTooltip()}
    </StyledRowLabelContainer>
  )

  return (
    <StyledRowContainer
      // @ts-ignore
      $childrenLength={children?.length}
      $label={label}
      $margin={margin}
      $multipleFieldsInARow={multipleFieldsInARow}
      $padding={padding}
      $verticalLabel={verticalLabel}
      $width={width}
    >
      {label && renderLabel()}
      <StyledRowChildrenContainer
        $autoHeight={autoHeight}
        $multipleFieldsInARow={multipleFieldsInARow}
        $width={width}
      >
        {children}
      </StyledRowChildrenContainer>
    </StyledRowContainer>
  )
}

export default FormRow
