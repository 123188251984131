import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'
import layout from 'constants/layout'

import { getBrandingColor } from 'utils/branding'

interface StyledDropdownMenuProps {
  $minWidth?: number
}

export const StyledDropdownMenu = styled.div<StyledDropdownMenuProps>`
  width: 220px;
  padding: 10px 0;

  ${({ $minWidth }) => $minWidth && `
    width: ${$minWidth}px;
  `}
`

export const StyledItem = styled.div`
  padding: 12px 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  
  ${({ $disabled }) => $disabled && `
    opacity: 0.4;
    
    * {
      cursor: not-allowed;
    }
  `}
`

interface StyledLabelProps {
  $color?: string
  $primary?: boolean
}

export const StyledLabel = styled.p<StyledLabelProps>`
  margin: 0;
  color: ${NEUTRAL_COLOURS.BASIC};
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  
  ${({ $primary }) => $primary && `
    color: ${getBrandingColor('primary-color')};
  `}
  
  ${({ $color }) => $color && `
    color: ${$color};
  `}

  @media(max-width: ${layout.mobileWidthResolution}px) {
    font-size: 14px;
  }
`

export const StyledIconContainer = styled.div`
  margin-right: 10px;
  min-width: 24px;
  
  a {
    min-width: auto;
    padding-bottom: 0;
    min-height: auto;
    height: 42px;
    margin-right: 8px;
    transform: translateY(2px);
  }
`

interface StyledButtonWrapperProps {
  $disabled?: boolean
}

export const StyledButtonWrapper = styled.div<StyledButtonWrapperProps>`
  cursor: pointer;
  
  ${({ $disabled }) => $disabled && `
    opacity: 0.4;
    
    * {
      cursor: not-allowed;
    }
  `}
`

export const StyledDivider = styled.div`
  height: 1px;
  background: ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  width: 100%;
  margin: 10px 0;
`

export const StyledStorybookDropdownMenuPlaceholder = styled.div`
  width: 220px;
  padding: 10px 0;
  border-radius: 14px;
  box-shadow: 0 0 20px 0 rgb(89 89 89 / 50%);
  background: #FFF;
  min-height: 35px;
`
