import _ from 'lodash'

import { ERROR_VERSION } from 'constants/http'

import { ERROR_CODES, FIELD_NAMES_OPTIONS } from './constants'

export const getImportCriteriaSelector = (filters) => {
  if (!filters) {
    return null
  }

  const { ignoreWarnings } = filters
  const criteria = []

  if (ignoreWarnings) {
    criteria.push({
      field: 'ignoreWarnings',
      value: 1,
    })
  }

  return criteria
}

export const getErrorsAndWarnings = (data) => {
  const duplicates = {}
  const errors = {}

  _.forEach(data, ({ exception, key }) => {
    const { error } = exception || {}
    const { code, extra, message, version } = error || {}

    if (ERROR_CODES.TABLE_HEADERS === code) {
      errors.TABLE_HEADERS = true
    }

    if (ERROR_CODES.MANDATORY_FIELDS === code) {
      errors.MANDATORY_FIELD = [
        ...(errors?.MANDATORY_FIELD || []),
        {
          message,
          row: key,
        },
      ]
    }

    const missingFields = []

    if (ERROR_VERSION.V2 === version) {
      _.forEach(extra, (item, name) => {
        _.forEach(item, ({ payload }) => {
          const { code: errorCode } = payload || {}

          if (ERROR_CODES.MANDATORY_FIELD === errorCode) {
            const missingField = _.find(FIELD_NAMES_OPTIONS, ({ value }) => name === value).label
            missingFields.push(missingField)
          }

          if (ERROR_CODES.CHILD_REFERENCE === errorCode) {
            errors.CHILD_REFERENCE = [
              ...(errors?.CHILD_REFERENCE || []),
              { row: key },
            ]
          }

          if (ERROR_CODES.PAYMENT_METHOD === errorCode) {
            errors.PAYMENT_METHOD = [
              ...(errors?.PAYMENT_METHOD || []),
              { row: key },
            ]
          }

          if (ERROR_CODES.PAYMENT_DATE === errorCode) {
            errors.PAYMENT_DATE = [
              ...(errors?.PAYMENT_DATE || []),
              { row: key },
            ]
          }

          if (ERROR_CODES.AMOUNT_NEGATIVE === errorCode) {
            errors.AMOUNT_NEGATIVE = [
              ...(errors?.AMOUNT_NEGATIVE || []),
              { row: key },
            ]
          }

          if (ERROR_CODES.AMOUNT_WRONG_FORMAT === errorCode) {
            errors.AMOUNT_WRONG_FORMAT = [
              ...(errors?.AMOUNT_WRONG_FORMAT || []),
              { row: key },
            ]
          }

          if (ERROR_CODES.BILL_PAYER === errorCode) {
            errors.BILL_PAYER = [
              ...(errors?.BILL_PAYER || []),
              { row: key },
            ]
          }

          if (ERROR_CODES.EXISTING_PAYMENTS === errorCode) {
            duplicates.EXISTING_PAYMENTS = [
              ...(duplicates?.EXISTING_PAYMENTS || []),
              { row: key },
            ]
          }

          if (ERROR_CODES.DUPLICATE_WARNING === errorCode) {
            duplicates.DUPLICATE_WARNING = [
              ...(duplicates?.DUPLICATE_WARNING || []),
              { row: key },
            ]
          }
        })
      })
    }

    if (!_.isEmpty(missingFields)) {
      errors.MANDATORY_FIELD = [
        ...(errors?.MANDATORY_FIELD || []),
        {
          message: _.join(missingFields, ', '),
          row: key,
        },
      ]
    }
  })

  return {
    duplicates,
    errors,
  }
}
