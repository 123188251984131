import { SIGNATURE_COLLECTING_TYPES } from '../constants'

export const updateSignatureCount = (state, signatureData, newSignatureStatistics) => {
  if (newSignatureStatistics) {
    return {
      ...state,
      data: {
        ...state.data,
        injurySignatureStatistics: newSignatureStatistics,
      },
    }
  }

  const { data } = state
  const { injurySignatureStatistics, signatureCollectingType } = data

  const { signedDigitally, signedOnPaper, type } = signatureData

  const newStatistics = { ...injurySignatureStatistics }

  if (signatureCollectingType === SIGNATURE_COLLECTING_TYPES.DIGITALLY) {
    newStatistics[type].signed += signedDigitally ? 1 : -1
  } else {
    newStatistics[type].signed += signedOnPaper ? 1 : -1
  }

  return {
    ...state,
    data: {
      ...state.data,
      injurySignatureStatistics: newStatistics,
    },
  }
}
