import moment from 'moment'
import { v4 } from 'uuid'
import _ from 'lodash'

import React, { useRef } from 'react'

import colors from 'constants/colors'
import { INJURIES } from 'services/legacy/injuries/constants'

import { Button, FooterActions, Icon, SignaturePad, Spinner, Toolbar, Typography } from 'components'

import i18n from 'translations'

import {
  StyledBoxContainer,
  StyledClearContainer,
  StyledCompleteContainer,
  StyledContainer,
  StyledSignaturePadContainer,
  StyledTextContainer,
  StyledTextWrapper,
  StyledToolbar,
  StyledUnderline,
  StyledUnderlineWrapper,
} from './SignatureStyled'

const Signature = ({
  hideWaterMark,
  injuryPreview,
  isSubmitting,
  onBackClick,
  onCloseClick,
  onContinueClick,
  onHideWaterMark,
  signatureItem,
  signatureUploaded,
}) => {
  const signaturePadRef = useRef(null)

  const { type } = injuryPreview
  const { additionalData } = signatureItem || {}
  const { label, name } = additionalData || {}
  const accidentIncidentLabel = type === INJURIES.INCIDENT
    ? i18n.t('module:Injury:Common:incident')
    : i18n.t('module:Injury:Common:accident')

  const handleClearSignature = () => {
    signaturePadRef.current.clear()

    onHideWaterMark(false)
  }

  const handleContinueClick = () => {
    const canvas = signaturePadRef.current.getTrimmedCanvas()

    canvas.toBlob((blob) => {
      const file = blob

      file.name = `${v4()}.png`

      onContinueClick(file, signatureItem)
    }, 'image/png')
  }

  const renderSignatureHeader = () => (
    <React.Fragment>
      <StyledToolbar>
        <Toolbar>
          <Toolbar.Group>
            <Toolbar.Item>
              <Typography padding="0 0 5px">{label}</Typography>
              <Typography fontSize={22} bold>{name}</Typography>
            </Toolbar.Item>
          </Toolbar.Group>
          <Toolbar.Group>
            <Toolbar.Item>
              <Typography align="right" padding="0 0 5px">
                {_.upperFirst(i18n.t('global:date'))}
              </Typography>
              <Typography fontSize={22} bold>
                {moment().format('DD/MM/YYYY')}
              </Typography>
            </Toolbar.Item>
          </Toolbar.Group>
        </Toolbar>
      </StyledToolbar>
      <Typography fontSize={18} padding="10px 0 0">
        {i18n.t('module:Modals:InjurySignature:Signature:confirmationText')}
        <Typography cursor="pointer" variant="span" primary underline onClick={onBackClick}>
          {i18n.t('module:Modals:InjurySignature:ChildPreview:title', { type: accidentIncidentLabel })}
        </Typography>
      </Typography>
    </React.Fragment>
  )

  const renderDrawSignatureContent = () => (
    <React.Fragment>
      {!hideWaterMark && (
        <StyledTextContainer>
          <StyledTextWrapper>
            <Icon color={colors.lightGray} height={30} icon="edit" />
            <Typography
              color={colors.lightGray}
              fontSize={32}
              padding="0 0 0 20px"
              bold
            >
              {i18n.t('module:Modals:InjurySignature:Signature:signHere')}
            </Typography>
          </StyledTextWrapper>
        </StyledTextContainer>
      )}
      <StyledUnderlineWrapper>
        <StyledUnderline />
      </StyledUnderlineWrapper>
      <StyledSignaturePadContainer>
        <SignaturePad
          ref={signaturePadRef}
          onBegin={() => onHideWaterMark(true)}
        />
      </StyledSignaturePadContainer>
      <StyledClearContainer>
        <Typography variant="div" primary onClick={handleClearSignature}>
          {_.upperFirst(i18n.t('global:clear'))}
        </Typography>
      </StyledClearContainer>
    </React.Fragment>
  )

  const renderCompleteContent = () => (
    <StyledCompleteContainer>
      {isSubmitting
        ? (
          <React.Fragment>
            <Spinner size="large" />
            <Typography
              align="center"
              fontSize={20}
              padding="30px 0 0"
            >
              {i18n.t('module:Modals:InjurySignature:Signature:addingSignature')}
            </Typography>
          </React.Fragment>
        )
        : (
          <React.Fragment>
            <Icon height={50} icon="check" />
            <Typography
              align="center"
              padding="20px 0 0"
              variant="h4"
            >
              {i18n.t('module:Modals:InjurySignature:Signature:successTitle')}
            </Typography>
            <Typography
              align="center"
              padding="20px 0"
            >
              {i18n.t('module:Modals:InjurySignature:Signature:successSubtitle')}
            </Typography>
            <Button
              label={i18n.t('module:Modals:InjurySignature:Signature:returnToSignatures')}
              onClick={onCloseClick}
            />
          </React.Fragment>
        )}
    </StyledCompleteContainer>
  )

  const renderFooter = () => (
    <FooterActions spaceBetween>
      <FooterActions.Item>
        <Button
          disabled={isSubmitting}
          hierarchy="tertiary"
          label={i18n.t('global:Back')}
          negativeMargins
          onClick={onBackClick}
        />
      </FooterActions.Item>
      <FooterActions.Item>
        <Button
          disabled={!hideWaterMark || isSubmitting}
          label={i18n.t('global:Continue')}
          negativeMargins
          onClick={handleContinueClick}
        />
      </FooterActions.Item>
    </FooterActions>
  )

  return (
    <StyledContainer>
      {renderSignatureHeader()}
      <StyledBoxContainer>
        {signatureUploaded || isSubmitting
          ? renderCompleteContent()
          : renderDrawSignatureContent()}
      </StyledBoxContainer>
      {!signatureUploaded && renderFooter()}
    </StyledContainer>
  )
}

export default Signature
