import React from 'react'

import { Spinner } from 'components'

import AddSessionForm from './components/AddSessionForm'

const SettingsSessionsAddView = ({
  authAccessMap,
  errorMessages,
  initialValues,
  isArchived,
  isEdit,
  isFormLoading,
  isLoading,
  nurseries,
  onArchiveClick,
  onClearItemClick,
  onPageChange,
  onSameFeesForAllChange,
  onSubmit,
  page,
  pageCount,
  sameFeesForAll,
}) => {
  if (isLoading) {
    return <Spinner />
  }

  return (
    <AddSessionForm
      authAccessMap={authAccessMap}
      errorMessages={errorMessages}
      initialValues={initialValues}
      isArchived={isArchived}
      isEdit={isEdit}
      isLoading={isFormLoading}
      nurseries={nurseries}
      page={page}
      pageCount={pageCount}
      sameFeesForAll={sameFeesForAll}
      onArchiveClick={onArchiveClick}
      onClearItemClick={onClearItemClick}
      onPageChange={onPageChange}
      onSameFeesForAllChange={onSameFeesForAllChange}
      onSubmit={onSubmit}
    />
  )
}

export default SettingsSessionsAddView
