import _ from 'lodash'

import { MOVE_ON_DROPDOWN } from 'services/legacy/roomMove/constants'

export const getRoomMoveFormValues = ({
  fromNurseryClass,
  id,
  moveOn,
  toLeaving,
  toNurseryClass,
}) => ({
  fromNurseryClass: fromNurseryClass && {
    label: fromNurseryClass.name,
    value: fromNurseryClass.id,
  },
  id,
  moveOn: _.find(MOVE_ON_DROPDOWN, ({ value }) => value === moveOn),
  toLeaving,
  toNurseryClass: (() => {
    if (toNurseryClass) {
      return ({
        label: toNurseryClass.name,
        value: toNurseryClass.id,
      })
    }

    if (toLeaving) {
      return ({
        label: 'Leaving',
        value: 0,
      })
    }

    return null
  })(),
})
