import React from 'react'

import { ACTION_TYPE, MENU_ITEMS_STATUS, MENU_ITEMS_STATUS_OPTIONS } from 'services/legacy/foodTypes/constants'

import {
  Button,
  Callout,
  Checkbox,
  EmptyState,
  Page,
  Pagination,
  SearchBar,
  Section,
  Select,
  Space,
  Spinner,
  Table,
  Toolbar,
  Typography,
} from 'components'

import i18n from 'translations'

import { getRoute } from '../ManagementMenuItemsHelpers'
import { getColumns, getIcon, getTableData } from './MenuItemsListHelpers'

const MenuItemsListView = ({
  data,
  errorMessages,
  isLoading,
  isMealsContext,
  onArchiveClick,
  onCheckboxClick,
  onPageChange,
  onSearchChange,
  onSelectAll,
  onSortChange,
  onStatusChange,
  page,
  pageCount,
  perPage,
  prefix,
  search,
  selected,
  selectedAll,
  sortField,
  sortOrder,
  status,
  totalResults,
}) => {
  const icon = getIcon(isMealsContext)
  const columns = getColumns({ menuItemTitle: i18n.t(prefix.concat('menuItemTitle')) })
  const tableData = getTableData({
    data,
    isMealsContext,
    onCheckboxClick,
    selected,
  })
  const addItemRoute = getRoute({ action: ACTION_TYPE.ADD, isMealsContext })
  const action = MENU_ITEMS_STATUS.ARCHIVED === status
    ? i18n.t('global:Unarchive')
    : i18n.t('global:Archive')

  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t(prefix.concat('Pagination:plural'))}
      titleSingular={i18n.t(prefix.concat('Pagination:singular'))}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!data?.length) {
      return (
        <EmptyState
          icon={icon}
          text1={i18n.t(prefix.concat('emptyState'))}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Space space="15px" />
        <Toolbar>
          <Toolbar.Group>
            <Toolbar.Item>
              <Checkbox
                label={i18n.t('global:selectAll')}
                value={selectedAll}
                onChange={onSelectAll}
              />
            </Toolbar.Item>
            {selected?.length ? (
              <Toolbar.Item>
                <Button
                  hierarchy="tertiary"
                  icon="archive"
                  label={i18n.t(prefix.concat('archiveItem'), { action })}
                  negativeMargins
                  onClick={onArchiveClick}
                />
              </Toolbar.Item>
            ) : null}
          </Toolbar.Group>
        </Toolbar>
        <Table
          columns={columns}
          data={tableData}
          margin="10px -2px 20px"
          sortField={sortField}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const renderToolbar = () => (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <Select
            clearable={false}
            options={MENU_ITEMS_STATUS_OPTIONS}
            searchable={false}
            value={status}
            onChange={onStatusChange}
          />
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )

  const actions = (
    <Section.Actions
      primary={[{
        label: i18n.t(prefix.concat('addItemLabel')),
        to: addItemRoute,
      }]}
    />
  )

  return (
    <React.Fragment>
      <SearchBar
        placeholder={i18n.t(prefix.concat('searchBarPlaceholder'))}
        value={search}
        onChange={onSearchChange}
      />
      <Page.Section
        actions={actions}
        isLoading={isLoading}
        title={i18n.t(prefix.concat('title'))}
      >
        <Callout content={errorMessages} error />
        <Typography margin="20px 0">
          {i18n.t(prefix.concat('subtitle'))}
        </Typography>
        {renderToolbar()}
        {renderContent()}
      </Page.Section>
    </React.Fragment>
  )
}

export default MenuItemsListView
