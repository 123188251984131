import React from 'react'

import ChildPickerModal from './components/ChildPickerModal'

const ChildPickerModalView = ({
  disableSelectAll,
  emptyStateText,
  filteredChildrenList,
  getItemSelected,
  isEmpty,
  isLoading,
  onConfirmClick,
  onItemClick,
  onRoomFilterChange,
  onSearchChange,
  onSelectAllChange,
  roomFilter,
  roomFilterOptions,
  search,
  selectAll,
}) => (
  <ChildPickerModal
    disableSelectAll={disableSelectAll}
    emptyStateText={emptyStateText}
    filteredChildrenList={filteredChildrenList}
    getItemSelected={getItemSelected}
    isEmpty={isEmpty}
    isLoading={isLoading}
    roomFilter={roomFilter}
    roomFilterOptions={roomFilterOptions}
    search={search}
    selectAll={selectAll}
    onConfirmClick={onConfirmClick}
    onItemClick={onItemClick}
    onRoomFilterChange={onRoomFilterChange}
    onSearchChange={onSearchChange}
    onSelectAllChange={onSelectAllChange}
  />
)

export default ChildPickerModalView
