import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'
import {
  SHIFT_MAIN_TYPES,
  SHIFT_MAIN_TYPES_LABELS,
  STAFF_REGISTER_TYPES,
  WORK_SHIFTS,
  WORK_SHIFT_BREAK_PARENT_TYPES,
  WORK_SHIFT_TYPES,
} from 'services/legacy/membershipRegisters/constants'

import { isFutureDay, isPastDay, millisecondsToHoursAndMinutesString, removeDate, roundMilliseconds } from 'utils/date'
import { sortByKey } from 'utils/data'

import { isShiftBreak } from 'module/Staff/StaffHelpers'

import { Space, Typography } from 'components'
import { TimeCallout } from 'module/Staff/components'

import i18n from 'translations'

import { StyledItemBox, StyledRecord, StyledShiftTimesWrapper } from './RecordStyled'

const ONGOING = 'ongoing'

const ACTUAL_WORK = {
  BREAK: 'break',
  WORKED: 'worked',
}

const VARIATION = {
  BREAK: 'break',
  WORKED: 'worked',
}

const ACTUAL_WORK_ORDER = {
  [ACTUAL_WORK.WORKED]: 1,
  [ACTUAL_WORK.BREAK]: 2,
}

const VARIATION_ORDER = {
  [VARIATION.WORKED]: 1,
  [VARIATION.BREAK]: 2,
}

const Record = ({ date, record }) => {
  const { currentRegisters, id: recordId, shifts } = record
  const isPast = isPastDay(date)
  const isFuture = isFutureDay(date)

  const getLeaveDetails = (items) => {
    let result

    result = _.concat(
      ..._.map(items, ({ leaveShiftType, membershipShiftTimes }) => (
        _.map(membershipShiftTimes, (item) => ({
          ...item,
          leaveShiftType,
          type: leaveShiftType?.code,
        }))
      )),
    )

    result = _.filter(result, (({ day: shiftTimeDay }) => (
      date
      === moment(shiftTimeDay).format(DEFAULT_DATE_FORMAT)
    )))

    result = _.groupBy(result, 'type')
    result = _.mapValues(result, (groupRecords) => {
      const leaveShiftType = groupRecords?.[0]?.leaveShiftType
      let summary = 0

      _.each(groupRecords, ({ endTime, startTime }) => {
        summary += (endTime - startTime)
      })

      summary = roundMilliseconds(summary)

      return ({
        leaveShiftType,
        summary,
      })
    })

    return result
  }

  const calculateWorkTimes = (items) => {
    let result

    result = _.concat(
      ..._.map(items, ({ membershipShiftTimes }) => membershipShiftTimes),
    )

    result = _.filter(result, (({ day: shiftTimeDay }) => (date === moment(shiftTimeDay).format(DEFAULT_DATE_FORMAT))))
    result = _.groupBy(result, ({ type }) => (
      isShiftBreak(type)
        ? WORK_SHIFT_BREAK_PARENT_TYPES
        : type))
    result = _.mapValues(result, (subResults) => _.sumBy(subResults, ({ endTime, startTime }) => endTime - startTime))

    return result
  }

  const calculateWorkVariation = ({ actual = {}, planned = {} }) => {
    const result = {}

    if ((!_.keys(actual).length && !isPast) || (!_.keys(actual).length && !_.keys(planned).length)) {
      return {}
    }

    if (actual.break || planned[WORK_SHIFT_BREAK_PARENT_TYPES] || isPast) {
      result[VARIATION.BREAK] = (actual.break || 0) - (planned[WORK_SHIFT_BREAK_PARENT_TYPES] || 0)
    }

    if (actual?.worked || planned[WORK_SHIFT_TYPES.CONTRACTED] || planned[WORK_SHIFT_TYPES.OVERTIME] || isPast) {
      result[VARIATION.WORKED] = (
        ((actual?.worked || 0) - (actual?.break || 0)) - (
          (planned[WORK_SHIFT_TYPES.CONTRACTED] || 0)
          + (planned[WORK_SHIFT_TYPES.OVERTIME] || 0)
          - (planned[WORK_SHIFT_BREAK_PARENT_TYPES] || 0)
        )
      )
    }

    return result
  }

  const calculateActualWorkTimes = (items) => {
    const filteredItems = _.filter(items, ({ date: recordDate }) => (
      date === moment(recordDate).format(DEFAULT_DATE_FORMAT)
    ))

    if (!filteredItems.length) {
      return null
    }

    if (_.find(filteredItems, ({ ongoing }) => ongoing)) {
      return ONGOING
    }

    const result = {
      break: 0,
      worked: 0,
    }

    _.each(filteredItems, ({ entries }) => {
      _.each(entries, ({ signInAt, signOutAt, type }) => {
        const summaryResult = moment(signOutAt)
          .diff(moment(removeDate(signInAt)))

        result.worked += summaryResult.valueOf()

        if (STAFF_REGISTER_TYPES.BREAK === type) {
          result.break += summaryResult.valueOf()
        }
      })
    })

    return sortByKey(result, ACTUAL_WORK_ORDER)
  }

  const renderLeaveDetails = (mainGroup) => (
    <StyledShiftTimesWrapper>
      {_.map(mainGroup, ({ leaveShiftType, summary }) => (
        <StyledRecord key={`leaveRecord_${recordId}_${date}_${leaveShiftType?.code}`}>
          <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={12} ellipsis>
            {leaveShiftType?.name}
          </Typography>
          <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={12}>
            {millisecondsToHoursAndMinutesString(summary)}
          </Typography>
        </StyledRecord>
      ))}
    </StyledShiftTimesWrapper>
  )

  const renderWorkDetails = (workRecords) => {
    const summaryPlanned = (
      (workRecords.planned?.[WORK_SHIFT_TYPES.CONTRACTED] || 0)
      + (workRecords.planned?.[WORK_SHIFT_TYPES.OVERTIME] || 0)
      - (workRecords.planned?.[WORK_SHIFT_BREAK_PARENT_TYPES] || 0)
    )

    const summaryActual = (
      (workRecords.actual?.[ACTUAL_WORK.WORKED] || 0) - (workRecords.actual?.[ACTUAL_WORK.BREAK] || 0)
    )

    return (
      <React.Fragment>
        {_.keys(workRecords.planned).length || isPast ? (
          <React.Fragment>
            <Typography margin="5px 0 0" variant="div">
              <StyledRecord>
                <Typography fontSize={12} bold>
                  {i18n.t('module:Staff:StaffAttendance:planned')}
                </Typography>
                <Typography fontSize={12} bold>
                  {summaryPlanned
                    ? millisecondsToHoursAndMinutesString(summaryPlanned)
                    : `0${i18n.t('global:hourShortcut')}`}
                </Typography>
              </StyledRecord>
            </Typography>
            <StyledShiftTimesWrapper>
              {_.map(workRecords.planned, (time, type) => (
                <StyledRecord key={`workPlanned_${recordId}_${date}_${type}`}>
                  <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={12} nowrap>
                    {WORK_SHIFTS[type].label}
                  </Typography>
                  <Typography align="right" color={NEUTRAL_COLOURS.GRAY} fontSize={12}>
                    {time ? millisecondsToHoursAndMinutesString(time) : `0${i18n.t('global:hourShortcut')}`}
                  </Typography>
                </StyledRecord>
              ))}
            </StyledShiftTimesWrapper>
          </React.Fragment>
        ) : null}
        {!isFuture && (
          workRecords?.actual === ONGOING
          || workRecords.actual?.[ACTUAL_WORK.WORKED]
          || workRecords.actual?.[ACTUAL_WORK.BREAK]
          || isPast
        ) ? (
          <React.Fragment>
            {_.keys(workRecords.planned).length || isPast ? (
              <Space space="20px" />
            ) : null}
            <Typography margin="5px 0 0" variant="div">
              <StyledRecord>
                <Typography fontSize={12} bold>
                  {i18n.t('module:Staff:StaffAttendance:actual')}
                </Typography>
                {workRecords.actual !== ONGOING ? (
                  <Typography fontSize={12} bold>
                    {summaryActual
                      ? millisecondsToHoursAndMinutesString(summaryActual)
                      : `0${i18n.t('global:hourShortcut')}`}
                  </Typography>
                ) : (
                  <Typography fontSize={12} bold>
                    {i18n.t('module:Staff:StaffAttendance:ongoing')}
                  </Typography>
                )}
              </StyledRecord>
            </Typography>
            {workRecords.actual !== ONGOING && (
              <StyledShiftTimesWrapper>
                {_.map(workRecords.actual, (time, type) => (
                  <StyledRecord key={`workActual_${recordId}_${date}_${type}`}>
                    <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={12} nowrap>
                      {i18n.t(`module:Staff:StaffAttendance:actualTypes:${type}`)}
                    </Typography>
                    <Typography align="right" color={NEUTRAL_COLOURS.GRAY} fontSize={12}>
                      {time ? millisecondsToHoursAndMinutesString(time) : `0${i18n.t('global:hourShortcut')}`}
                    </Typography>
                  </StyledRecord>
                ))}
              </StyledShiftTimesWrapper>
            )}
          </React.Fragment>
          ) : null}
        {workRecords.actual !== ONGOING
        && (workRecords.variation?.[VARIATION.WORKED] || workRecords.actual?.[VARIATION.BREAK]) ? (
          <React.Fragment>
            {(_.keys(workRecords.planned).length
              || (workRecords.actual?.[ACTUAL_WORK.WORKED] || workRecords.actual?.[ACTUAL_WORK.BREAK])
            )
              ? (
                <Space space="20px" />
              )
              : null}
            <Typography margin="5px 0 0" variant="div">
              <StyledRecord>
                <Typography fontSize={12} bold>
                  {i18n.t('module:Staff:StaffAttendance:variation')}
                </Typography>
              </StyledRecord>
            </Typography>
            <StyledShiftTimesWrapper>
              {_.map(workRecords.variation, (time, type) => {
                const isNegative = 0 > time

                return (
                  <StyledRecord key={`variation_${type}`}>
                    <Typography fontSize={12} nowrap>
                      {i18n.t(`module:Staff:StaffAttendance:variationTypes:${type}`)}
                    </Typography>
                    <Typography
                      align="right"
                      color={
                        // eslint-disable-next-line no-nested-ternary
                        0 === time
                          ? NEUTRAL_COLOURS.BASIC
                          : (isNegative ? FLAG_COLOURS.ERROR : '#009540')
                      }
                      fontSize={12}
                    >
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {0 === time ? null : (isNegative ? '-' : '+')}
                      {time
                        ? millisecondsToHoursAndMinutesString(isNegative ? time * -1 : time)
                        : `0${i18n.t('global:hourShortcut')}`}
                    </Typography>
                  </StyledRecord>
                )
              })}
            </StyledShiftTimesWrapper>
          </React.Fragment>
          ) : null}
      </React.Fragment>
    )
  }

  const groupedPlannedShifts = _.groupBy(shifts, 'type') || {}

  const mainGroups = {
    [SHIFT_MAIN_TYPES.WORK]: {},
    [SHIFT_MAIN_TYPES.LEAVE]: getLeaveDetails(groupedPlannedShifts[SHIFT_MAIN_TYPES.LEAVE]),
  }

  const workTimes = calculateWorkTimes(groupedPlannedShifts[SHIFT_MAIN_TYPES.WORK])

  if (_.keys(workTimes).length) {
    mainGroups[SHIFT_MAIN_TYPES.WORK].planned = workTimes
  }

  const actualTimes = calculateActualWorkTimes(currentRegisters)
  if (_.keys(actualTimes).length) {
    mainGroups[SHIFT_MAIN_TYPES.WORK].actual = actualTimes
  }

  const variationTimes = calculateWorkVariation(mainGroups[SHIFT_MAIN_TYPES.WORK])
  if (_.keys(variationTimes).length) {
    mainGroups[SHIFT_MAIN_TYPES.WORK].variation = sortByKey(
      variationTimes,
      VARIATION_ORDER,
    )
  }

  let emptyRecord = true

  if (
    _.keys(mainGroups[SHIFT_MAIN_TYPES.LEAVE]).length
    || _.find(mainGroups[SHIFT_MAIN_TYPES.WORK].planned, (value) => value)
    || (_.find(mainGroups[SHIFT_MAIN_TYPES.WORK].actual, (value) => value) && !isFuture)
  ) {
    emptyRecord = false
  }

  return (
    <StyledItemBox>
      {emptyRecord && (
        <Typography margin="2px 4px 6px">
          {`0${i18n.t('global:hourShortcut')}`}
        </Typography>
      )}
      {!emptyRecord && _.map(mainGroups, (mainGroup, mainType) => {
        if (!_.keys(mainGroup).length) {
          return null
        }

        return (
          <TimeCallout
            content={
              mainType === SHIFT_MAIN_TYPES.WORK
                ? renderWorkDetails(mainGroup)
                : renderLeaveDetails(mainGroup)
            }
            header={(
              <Typography fontSize={12} bold>
                {SHIFT_MAIN_TYPES_LABELS[mainType]}
              </Typography>
            )}
            key={`timeCallout_${mainType}`}
            type={mainType}
          />
        )
      })}
    </StyledItemBox>
  )
}

export default Record
