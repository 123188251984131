import _ from 'lodash'

import { ACTION_TYPE } from 'services/legacy/foodTypes/constants'

import { Button, Checkbox } from 'components'

import i18n from 'translations'

import { getRoute } from '../ManagementMenuItemsHelpers'

export const getIcon = (isMealsContext) => (isMealsContext
  ? 'meals'
  : 'snacks')

export const getColumns = ({ menuItemTitle }) => ([
  {
    field: 'checkbox',
    width: '10px',
  },
  {
    align: 'left',
    field: 'name',
    sortKey: 'name',
    sortable: true,
    title: menuItemTitle,
  },
  {
    field: 'rowToButton',
    width: '100px',
  },
])

export const getTableData = ({ data, isMealsContext, onCheckboxClick, selected }) => {
  if (!data?.length) {
    return null
  }

  return _.map(data, ({ archived, id, name }) => {
    const checked = 0 <= _.find(selected, (itemId) => itemId === id)

    return ({
      checkbox: (
        <Checkbox
          value={checked}
          centered
          onChange={() => onCheckboxClick(id)}
        />
      ),
      name: archived ? `${name} (${i18n.t('global:Archived')})` : name,
      rowToButton: (
        <Button.TableAction
          to={getRoute({ action: ACTION_TYPE.EDIT, id, isMealsContext })}
          edit
        />
      ),
    })
  })
}
