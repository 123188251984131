export const GET_CLOSURE_DAY_TYPE = 'CLOSURE_DAY_TYPES/GET_CLOSURE_DAY_TYPE'
export const GET_CLOSURE_DAY_TYPE_FAILED = 'CLOSURE_DAY_TYPES/GET_CLOSURE_DAY_TYPE_FAILED'
export const GET_CLOSURE_DAY_TYPE_SUCCESS = 'CLOSURE_DAY_TYPES/GET_CLOSURE_DAY_TYPE_SUCCESS'

export const CREATE_CLOSURE_DAY_TYPE = 'CLOSURE_DAY_TYPES/CREATE_CLOSURE_DAY_TYPE'
export const CREATE_CLOSURE_DAY_TYPE_FAILED = 'CLOSURE_DAY_TYPES/CREATE_CLOSURE_DAY_TYPE_FAILED'
export const CREATE_CLOSURE_DAY_TYPE_SUCCESS = 'CLOSURE_DAY_TYPES/CREATE_CLOSURE_DAY_TYPE_SUCCESS'

export const UPDATE_CLOSURE_DAY_TYPE = 'CLOSURE_DAY_TYPES/UPDATE_CLOSURE_DAY_TYPE'
export const UPDATE_CLOSURE_DAY_TYPE_FAILED = 'CLOSURE_DAY_TYPES/UPDATE_CLOSURE_DAY_TYPE_FAILED'
export const UPDATE_CLOSURE_DAY_TYPE_SUCCESS = 'CLOSURE_DAY_TYPES/UPDATE_CLOSURE_DAY_TYPE_SUCCESS'

export const REMOVE_CLOSURE_DAY_TYPE = 'CLOSURE_DAY_TYPES/REMOVE_CLOSURE_DAY_TYPE'
export const REMOVE_CLOSURE_DAY_TYPE_FAILED = 'CLOSURE_DAY_TYPES/REMOVE_CLOSURE_DAY_TYPE_FAILED'
export const REMOVE_CLOSURE_DAY_TYPE_SUCCESS = 'CLOSURE_DAY_TYPES/REMOVE_CLOSURE_DAY_TYPE_SUCCESS'

export const CLEAR_CLOSURE_DAY_TYPE = 'CLOSURE_DAY_TYPES/CLEAR_CLOSURE_DAY_TYPE'
