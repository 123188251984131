import { generateBasicActions } from 'utils/service'

import {
  CLEAR_NEXT_MONTESSORI_ACTIVITIES_SINGLE,
  CREATE_NEXT_MONTESSORI_ACTIVITIES,
  CREATE_NEXT_MONTESSORI_ACTIVITIES_FAILED,
  CREATE_NEXT_MONTESSORI_ACTIVITIES_SUCCESS,
  GET_NEXT_MONTESSORI_ACTIVITIES,
  GET_NEXT_MONTESSORI_ACTIVITIES_FAILED,
  GET_NEXT_MONTESSORI_ACTIVITIES_SUCCESS,
  REMOVE_NEXT_MONTESSORI_ACTIVITIES,
  REMOVE_NEXT_MONTESSORI_ACTIVITIES_FAILED,
  REMOVE_NEXT_MONTESSORI_ACTIVITIES_SUCCESS,
  UPDATE_NEXT_MONTESSORI_ACTIVITIES,
  UPDATE_NEXT_MONTESSORI_ACTIVITIES_FAILED,
  UPDATE_NEXT_MONTESSORI_ACTIVITIES_SUCCESS,
} from './constants'

export const create = (options = {}) => ({ nextMontessoriActivitiesApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: CREATE_NEXT_MONTESSORI_ACTIVITIES_FAILED,
      init: CREATE_NEXT_MONTESSORI_ACTIVITIES,
      success: CREATE_NEXT_MONTESSORI_ACTIVITIES_SUCCESS,
    },
    options,
    service: nextMontessoriActivitiesApiClient,
    serviceMethod: 'create',
  })
)

export const remove = (options = {}) => ({ nextMontessoriActivitiesApiClient }) => (
  generateBasicActions.remove({
    constants: {
      failed: REMOVE_NEXT_MONTESSORI_ACTIVITIES_FAILED,
      init: REMOVE_NEXT_MONTESSORI_ACTIVITIES,
      success: REMOVE_NEXT_MONTESSORI_ACTIVITIES_SUCCESS,
    },
    options,
    service: nextMontessoriActivitiesApiClient,
    serviceMethod: 'remove',
  })
)

export const get = (options = {}) => ({ nextMontessoriActivitiesApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_NEXT_MONTESSORI_ACTIVITIES_FAILED,
    init: GET_NEXT_MONTESSORI_ACTIVITIES,
    success: GET_NEXT_MONTESSORI_ACTIVITIES_SUCCESS,
  },
  options,
  service: nextMontessoriActivitiesApiClient,
  serviceMethod: 'get',
})

export const update = (options = {}) => ({ nextMontessoriActivitiesApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_NEXT_MONTESSORI_ACTIVITIES_FAILED,
      init: UPDATE_NEXT_MONTESSORI_ACTIVITIES,
      success: UPDATE_NEXT_MONTESSORI_ACTIVITIES_SUCCESS,
    },
    options,
    service: nextMontessoriActivitiesApiClient,
    serviceMethod: 'update',
  })
)

export const clear = () => ({
  type: CLEAR_NEXT_MONTESSORI_ACTIVITIES_SINGLE,
})
