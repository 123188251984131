import { flatten, nest } from 'utils/flatnest'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { LIST_STATUS_FILTERS, LIST_STATUS_FILTERS_DROPDOWN } from 'constants/filters'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withNurseryPaymentTypesService } from 'services/legacy/nurseryPaymentTypes'
import { withPaginationUtils } from 'services/utils/pagination'

import { getTableData } from './helpers'
import ManagementPaymentTypesView from './ManagementPaymentTypesView'

class ManagementPaymentTypesContainer extends Component {
  constructor(props) {
    super(props)

    const { query } = props.location
    const { statusFilter } = nest(query)

    this.state = {
      filters: {
        statusFilter: statusFilter || LIST_STATUS_FILTERS.ACTIVE,
      },
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { nurseryPaymentTypesActions } = this.props

    nurseryPaymentTypesActions.clearList()
  }

  fetch = () => {
    const {
      nurseryPaymentTypesActions,
      nurseryPaymentTypesSelectors,
      paginationUtils,
      setLocationQuery,
    } = this.props
    const { filters } = this.state

    const { page } = paginationUtils

    const criteria = nurseryPaymentTypesSelectors.getListCriteria(filters)

    setLocationQuery(flatten(filters))

    const apiParams = {
      criteria,
      page,
    }

    nurseryPaymentTypesActions.list({ mergeResult: false, params: apiParams })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleStatusFilterChange = (statusFilter) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, statusFilter: statusFilter && statusFilter.value },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  render() {
    const {
      errorMessages,
      isFetching,
      isUkCountry,
      nurseryPaymentTypesListState,
      nurseryPaymentTypesTableData,
      paginationUtils,
    } = this.props
    const { filters } = this.state
    const { statusFilter } = filters
    const { getPageCount, page, perPage } = paginationUtils

    const totalResults = nurseryPaymentTypesListState.meta.total_results
    const isEmpty = !nurseryPaymentTypesTableData || !nurseryPaymentTypesTableData.length
    const pageCount = getPageCount(totalResults)
    const tableData = getTableData(nurseryPaymentTypesTableData, isUkCountry)

    return (
      <ManagementPaymentTypesView
        errorMessages={errorMessages}
        isEmpty={isEmpty}
        isLoading={isFetching}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        statusFilter={statusFilter}
        statusFilterOptions={LIST_STATUS_FILTERS_DROPDOWN}
        tableData={tableData}
        totalResults={totalResults}
        onPageChange={this.handlePageChange}
        onStatusFilterChange={this.handleStatusFilterChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseriesSelectors,
  nurseryPaymentTypesListState,
  nurseryPaymentTypesSelectors,
}) => ({
  errorMessages: appSelectors.getErrorMessages(nurseryPaymentTypesListState),
  isFetching: appSelectors.getIsFetching(nurseryPaymentTypesListState),
  isUkCountry: nurseriesSelectors.getIsUkCountry(state),
  nurseryPaymentTypesTableData: nurseryPaymentTypesSelectors.getNurseryPaymentTypesListDataSelector(state),
})

const enhance = compose(
  withAppService,
  withNurseriesService,
  withNurseryPaymentTypesService,
  withPaginationUtils,
  connect(mapState),
)

export default enhance(ManagementPaymentTypesContainer)
