import _ from 'lodash'
import moment from 'moment'
import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { getBrandingColor } from 'utils/branding'

import { getToBeInvoicedFromForecastItem } from 'services/legacy/child/helpers'

import i18n from 'translations'

const getInvoices = (state: RootState) => state.invoices

export const getInvoicesForecast = createSelector([getInvoices], (state) => state.forecast && state.forecast.data)

export const getChartData = createSelector(
  [getInvoicesForecast],
  (invoiceForecast) => {
    if (!invoiceForecast || !invoiceForecast.length) {
      return undefined
    }

    const data = {
      categories: [],
      data: [
        {
          color: getBrandingColor('primary-color'),
          data: [],
          name: i18n.t('module:Finance:Reports:Forecasting:Chart:totalInvoicedLabel'),
        },
        {
          color: getBrandingColor('primary-color-lighten-20'),
          data: [],
          name: i18n.t('module:Finance:Reports:Forecasting:Chart:fundingIncomeLabel'),
        },
        {
          color: '#989898',
          data: [],
          name: i18n.t('module:Finance:Reports:Forecasting:Chart:forecastLabel'),
          type: 'line',
        },
      ],
    }

    _.forEach(invoiceForecast, ({ date, forecast, invoices }) => {
      const { totalIncome: forecastTotalIncome } = forecast
      const { totalFundingIncome, totalInvoices } = invoices

      data.categories.push(moment(date).format('MMM YYYY'))
      data.data[0].data.push(totalInvoices)
      data.data[1].data.push(totalFundingIncome)
      data.data[2].data.push(forecastTotalIncome)
    })

    return data
  },
)

export const getCriteria = createSelector(
  [(filters) => filters],
  (filters) => {
    if (!filters) {
      return null
    }

    const { date = {} } = filters
    const { after, before } = date

    const criteria = []

    if (after) {
      criteria.push({
        comparator: 'after',
        field: 'date',
        value: moment(after).format(DEFAULT_DATE_FORMAT),
      })
    }

    if (before) {
      criteria.push({
        comparator: 'before',
        field: 'date',
        value: moment(before).format(DEFAULT_DATE_FORMAT),
      })
    }

    return criteria
  },
)

export const getForecastingReportTableDataSelector = createSelector(
  [getInvoicesForecast],
  (forecast) => {
    if (!forecast || !forecast.length) {
      return null
    }

    return getToBeInvoicedFromForecastItem(forecast)
  },
)
