import _ from 'lodash'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import {
  Avatar,
  Button,
  EmptyState,
  InfiniteScroll,
  List,
  Pagination,
  Spinner,
  Toolbar,
  Tooltip,
  Typography,
} from 'components'

import { getInitialValues } from 'services/legacy/dailyDiaryNappies/single/selectors'
import {
  getInitialValues as getInitialToiletTrainingValues,
} from 'services/legacy/dailyDiaryToiletTraining/single/selectors'

import i18n from 'translations'

import { StyledItem } from 'components/List/Advanced/ListAdvancedStyled'
import NappyRecord from './components/NappyRecord'
import ToiletTrainingRecord from './components/ToiltetTrainingRecord'
import { NAPPY_RECORD_EDIT_FORM } from './components/NappyRecord/NappyRecord'
import { TOILET_TRAINING_RECORD_EDIT_FORM } from './components/ToiltetTrainingRecord/ToiletTrainingRecord'
import { StyledFooterContainer } from './NappiesStyled'

const NappiesView = ({
  hasNoRecordsData,
  isFetching,
  isFetchingChildrenWithNoRecords,
  isOffline,
  isSubmitting,
  onAddRecord,
  onAddToiletTraining,
  onPageChange,
  onRemoveRecord,
  onRemoveToiletTrainingRecord,
  onUpdateRecord,
  onUpdateToiletTrainingRecord,
  page,
  pageCount,
  records,
  registers,
  totalResults,
}) => {
  if (isFetching && 1 === page) {
    if (isOffline) {
      return (
        <EmptyState
          icon="offline"
          text1={i18n.t('global:youAreOffline')}
        />
      )
    }

    return (
      <Spinner />
    )
  }

  const renderPagination = () => !isFetching && (
    <Pagination
      totalResults={totalResults}
    />
  )

  const renderHeader = () => {
    if (registers && !registers.length) {
      return null
    }

    return (
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <Typography>
              {i18n.t('module:DailyDiary:Nappies:listInfo')}
            </Typography>
          </Toolbar.Item>
        </Toolbar.Group>
        <Toolbar.Group>
          <Toolbar.Item>
            <Tooltip
              placement="top"
              title={
                !isFetchingChildrenWithNoRecords
                && !hasNoRecordsData
                && i18n.t('module:DailyDiary:global:notFoundChildren')
              }
            >
              <Button
                disabled={isSubmitting || !hasNoRecordsData || isFetchingChildrenWithNoRecords}
                icon="plus"
                label={i18n.t('module:DailyDiary:global:addChild')}
                negativeMargins
                onClick={() => onAddRecord(null)}
              />
            </Tooltip>
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
    )
  }

  const renderItem = (item) => {
    const { childRegister, diaryRecords } = item || {}
    const { child } = childRegister || {}
    const { firstName, id, photo, surname } = child

    return (
      <List.Advanced.Row
        footer={(
          <StyledFooterContainer>
            <Button.ActionButton
              label={i18n.t('module:DailyDiary:Nappies:addNappyFeed')}
              onClick={() => onAddRecord(child)}
            />
            <Button.ActionButton
              label={i18n.t('module:DailyDiary:Nappies:addToiletTraining')}
              onClick={() => onAddToiletTraining(child)}
            />
          </StyledFooterContainer>
        )}
        itemComponent={(record) => (
          <StyledItem key={record.id}>
            {record.nappyRash !== undefined ? (
              <NappyRecord
                form={`${NAPPY_RECORD_EDIT_FORM}_${record.id}`}
                initialValues={getInitialValues(record)}
                item={record}
                onRemoveRecord={onRemoveRecord}
                onSubmit={onUpdateRecord}
              />
            ) : (
              <ToiletTrainingRecord
                form={`${TOILET_TRAINING_RECORD_EDIT_FORM}_${record.id}`}
                initialValues={getInitialToiletTrainingValues(record)}
                item={record}
                onRemoveRecord={onRemoveToiletTrainingRecord}
                onSubmit={onUpdateToiletTrainingRecord}
              />
            )}
          </StyledItem>
        )}
        items={diaryRecords}
        key={id}
        leftColumn={(
          <Avatar
            borderColor={NEUTRAL_COLOURS.SILVER}
            borderWidth={4}
            direction="vertical"
            initials={[firstName, surname]}
            src={photo}
            title={`${firstName} ${surname}`}
            to={generateRoute('CHILDREN.CHILD', { childId: id })}
          />
        )}
        noItems={i18n.t('module:DailyDiary:global:noAddedItems')}
        withoutItemWrapper
      />
    )
  }

  const renderContent = () => {
    if (!isFetching && (records && !records.length)) {
      let text = null
      let icon = 'daily-diary'

      if (registers && !registers.length) {
        text = i18n.t('module:DailyDiary:global:noChildrenAttending')
      } else if (records && !records.length) {
        text = i18n.t('module:DailyDiary:Nappies:noRecords')
        icon = 'nappy'
      }

      return (
        <EmptyState
          icon={icon}
          text1={text}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <InfiniteScroll
          dataLength={records?.length || 0}
          hasMore={page < pageCount}
          isFetching={isFetching}
          next={() => onPageChange((+page) + 1)}
        >
          <List.Advanced>
            {_.map(records, renderItem)}
          </List.Advanced>
        </InfiniteScroll>
        {renderPagination()}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {renderHeader()}
      {renderContent()}
    </React.Fragment>
  )
}

export default NappiesView
