import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { PAYOUTS_STATUSES, TYPES_OF_PAYOUT_FAILURES } from 'services/nurseryIntegrations/constants'

import { generateRoute } from 'utils/routing'

import {
  Button,
  Callout,
  Currency,
  DatePicker,
  EmptyState,
  InfiniteScroll,
  Page,
  Spinner,
  TabFilter,
  Table,
  Tabs,
  Toolbar,
  Tooltip,
  Typography,
} from 'components'

import i18n from 'translations'

import { StyledStatus } from './FinancePayoutsStyled'

const COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'arrival',
    title: i18n.t('module:Finance:Payouts:arrival'),
    width: '20%',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'status',
    title: _.upperFirst(i18n.t('global:status')),
    width: '50%',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'amount',
    title: _.upperFirst(i18n.t('global:amount')),
    width: '15%',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'fees',
    title: i18n.t('module:Finance:Payouts:fees'),
    width: '15%',
  },
  {
    field: 'edit',
  },
]

const FinancePayoutsView = ({
  currenciesList,
  dateRange,
  errorMessages,
  isFetching,
  isFetchingCurrency,
  isFetchingStatistics,
  onChangeFilterCurrency,
  onChangeFilterDateChange,
  onChangeFilterStatus,
  pagination,
  records,
  selectedCurrency,
  statistics,
  status,
}) => {
  const renderRecord = (record) => {
    const recordStatus = _.find(PAYOUTS_STATUSES, ({ value }) => record.status === value) || {}
    const failureReason = _.find(TYPES_OF_PAYOUT_FAILURES, ({ value }) => record.failureCode === value)

    return ({
      amount: (
        <Currency customCurrency={record.currency} value={record.amount} />
      ),
      arrival: moment(record.arrival).format('MMM DD, YYYY'),
      edit: (
        <Button.TableAction
          to={generateRoute('FINANCE.PAYOUTS.DETAIL', { payoutId: record.id })}
        />
      ),
      fees: (
        <Currency customCurrency={record.currency} value={record.fee} />
      ),
      status: (
        <StyledStatus background={recordStatus.color}>
          <Tooltip title={failureReason?.label}>
            {recordStatus.label}
          </Tooltip>
        </StyledStatus>
      ),
    })
  }

  const renderStatistics = () => {
    if (isFetchingStatistics) {
      return (
        <Spinner />
      )
    }

    return (
      <TabFilter>
        {_.map(_.values(_.orderBy(PAYOUTS_STATUSES, ({ order }) => order)), ({ label, value }) => {
          const result = _.find(statistics, ({ status: statsStatus }) => value === statsStatus)

          return (
            <TabFilter.Item
              isClicked={status[value]}
              subTitle={(
                <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
                  {i18n.t('module:Finance:Payouts:numberOfPayouts', { payouts: result?.count || 0 })}
                </Typography>
              )}
              title={label}
              value={(
                <Currency customCurrency={selectedCurrency} value={result?.total || 0} />
              )}
              onClick={() => onChangeFilterStatus(value, !status[value])}
            />
          )
        })}
      </TabFilter>
    )
  }

  const renderContent = () => {
    if (isFetchingCurrency) {
      return (
        <Spinner />
      )
    }

    if (!records?.length) {
      return (
        <EmptyState
          icon="payment-types"
          text1={i18n.t('module:Finance:Payouts:emptyState')}
        />
      )
    }

    return (
      <InfiniteScroll
        dataLength={records?.length || 0}
        hasMore={pagination.page < pagination.pageCount}
        next={() => pagination.onPageChange((+pagination.page) + 1)}
      >
        <Table
          columns={COLUMNS}
          data={_.map(records, renderRecord)}
          visualType="transparent"
        />
      </InfiniteScroll>
    )
  }

  const renderBody = () => {
    if (isFetching) {
      return <Spinner />
    }

    return (
      <React.Fragment>
        <Toolbar>
          <Toolbar.Group>
            <Toolbar.Item>
              <DatePicker
                value={dateRange}
                clearable
                range
                onChange={onChangeFilterDateChange}
              />
            </Toolbar.Item>
          </Toolbar.Group>
        </Toolbar>
        {1 < currenciesList?.length && selectedCurrency && (
          <Tabs
            value={selectedCurrency}
            onChange={onChangeFilterCurrency}
          >
            {_.map(currenciesList, (currency) => (
              <Tabs.Item
                name={currency}
                title={i18n.t('module:Finance:Payouts:payouts', { currency: currency.toUpperCase() })}
              />
            ))}
          </Tabs>
        )}
        {!isFetchingCurrency && renderStatistics()}
        {renderContent()}
      </React.Fragment>
    )
  }

  return (
    <Page.Section
      isLoading={isFetching}
      title={i18n.t('module:Finance:Payouts:title')}
    >
      <Callout content={errorMessages} error />
      {renderBody()}
    </Page.Section>
  )
}

export default FinancePayoutsView
