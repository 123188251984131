import React from 'react'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { isChildSession, isEndTimeGreaterThanStartTime, isRequired } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'

import { Currency, Form, Space, Typography } from 'components'

import i18n from 'translations'

export const FORM_NAME = 'ChildAdhocSessionsAddForm'

const ChildAdhocSessionsAddForm = ({
  childDob,
  childId,
  cost,
  handleSubmit,
  isLinkedToRequestedExtraSession,
  isLoading,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Field component={Form.TextField} name="childDob" type="hidden" value={childDob} />
    <Form.Row label={i18n.t('global:Session')}>
      <Form.Row.FlexItem>
        <Field
          component={Form.NurserySession}
          deletable={false}
          disabled={isLinkedToRequestedExtraSession}
          dropdownWidth="100%"
          name="nurserySession"
          placeholder={i18n.t('global:Session')}
          validate={[isChildSession, isEndTimeGreaterThanStartTime]}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.Row label={i18n.t('global:Date')}>
      <Form.Row.FlexItem flex="0 0 100px">
        <Field
          component={Form.DatePicker}
          disabled={isLinkedToRequestedExtraSession}
          name="takenDate"
          placeholder={i18n.t('global:Date')}
          validate={isRequired}
          clearable
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.Row label>
      <Form.Row.FlexItem flex="0 0 150px">
        <Typography bold>
          {i18n.t('global:Cost')}
          <Space margin="5px" inline />
          <Typography variant="span" bold tertiary>
            <Currency value={cost} />
          </Typography>
        </Typography>
      </Form.Row.FlexItem>
    </Form.Row>
    {!isLinkedToRequestedExtraSession && (
      <Form.FooterActions
        cancelLink={generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_SESSIONS', { childId })}
        isSubmitting={isLoading}
        submitLabel={i18n.t('global:Save')}
      />
    )}
  </Form>
)

const mapState = (state) => ({
  nurserySessionOption: formValueSelector(FORM_NAME)(state, 'nurserySession'),
})

const enhance = compose(
  reduxForm({ form: FORM_NAME }),
  connect(mapState),
)

export default enhance(ChildAdhocSessionsAddForm)
