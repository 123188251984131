export const GET_PROGRESS_TRACKER_PREVIEW = 'GET_PROGRESS_TRACKER_PREVIEW'
export const GET_PROGRESS_TRACKER_PREVIEW_SUCCESS = 'GET_PROGRESS_TRACKER_PREVIEW_SUCCESS'
export const GET_PROGRESS_TRACKER_PREVIEW_FAILED = 'GET_PROGRESS_TRACKER_PREVIEW_FAILED'

export const GET_PROGRESS_TRACKER_REPORT = 'GET_PROGRESS_TRACKER_REPORT'
export const GET_PROGRESS_TRACKER_REPORT_SUCCESS = 'GET_PROGRESS_TRACKER_REPORT_SUCCESS'
export const GET_PROGRESS_TRACKER_REPORT_FAILED = 'GET_PROGRESS_TRACKER_REPORT_FAILED'

export const REMOVE_PROGRESS_TRACKER_REPORT = 'REMOVE_PROGRESS_TRACKER_REPORT'
export const REMOVE_PROGRESS_TRACKER_REPORT_SUCCESS = 'REMOVE_PROGRESS_TRACKER_REPORT_SUCCESS'
export const REMOVE_PROGRESS_TRACKER_REPORT_FAILED = 'REMOVE_PROGRESS_TRACKER_REPORT_FAILED'

export const CREATE_PROGRESS_TRACKER_REPORT = 'CREATE_PROGRESS_TRACKER_REPORT'
export const CREATE_PROGRESS_TRACKER_REPORT_SUCCESS = 'CREATE_PROGRESS_TRACKER_REPORT_SUCCESS'
export const CREATE_PROGRESS_TRACKER_REPORT_FAILED = 'CREATE_PROGRESS_TRACKER_REPORT_FAILED'

export const UPDATE_PROGRESS_TRACKER_ACTION_PLAN = 'UPDATE_PROGRESS_TRACKER_ACTION_PLAN'
export const UPDATE_PROGRESS_TRACKER_ACTION_PLAN_SUCCESS = 'UPDATE_PROGRESS_TRACKER_ACTION_PLAN_SUCCESS'
export const UPDATE_PROGRESS_TRACKER_ACTION_PLAN_FAILED = 'UPDATE_PROGRESS_TRACKER_ACTION_PLAN_FAILED'

export const CLEAR_PROGRESS_TRACKER_SINGLE = 'CLEAR_PROGRESS_TRACKER_SINGLE'
