import React, { useMemo } from 'react'

import { Callout, Page, StagesIndicator } from 'components'

import i18n from 'translations'

import GeneralInformationForm from './components/GeneralInformationForm'
import EnrolmentDetailsForm from './components/EnrolmentDetailsForm'
import ContactsForm from './components/ContactsForm'
import ReviewPage from './components/ReviewPage'
import { STAGE_INDEXES, STAGE_INDEXES_V3 } from './constants'

const ChildAddView = ({
  SessionCalculationAccess,
  cancelLink,
  currentStage,
  enableSessions,
  errorMessages,
  formValues,
  highestAvailableStage,
  initialValues,
  isAllYearRoundSelected,
  isFinanceV3Enabled,
  isSubmitting,
  nurserySessionsOptions,
  onAddContactClick,
  onAddSessionItemClick,
  onChangeExistingContact,
  onContactTypeChange,
  onDeleteContactClick,
  onDeleteSessionItemClick,
  onMoveToNextStage,
  onPreviousStageClick,
  onReviewSubmit,
  onSetStage,
  onStageChange,
  onToggleSessionClick,
  openingDays,
  selectedContacts,
  sessionCalculationsOptions,
  userTitles,
}) => {
  const stages = useMemo(() => {
    if (isFinanceV3Enabled) {
      return [
        { label: i18n.t('module:Children:Child:Add:GeneralInformation:title') },
        { label: i18n.t('module:Children:Child:Add:Contacts:title') },
      ]
    }

    return [
      { label: i18n.t('module:Children:Child:Add:GeneralInformation:title') },
      { label: i18n.t('module:Children:Child:Add:EnrolmentDetails:title') },
      { label: i18n.t('module:Children:Child:Add:Contacts:title') },
    ]
  }, [isFinanceV3Enabled])

  const renderStagesIndicator = () => (
    <StagesIndicator
      currentStage={currentStage}
      highestAvailableStage={highestAvailableStage}
      stages={stages}
      onStageChange={onStageChange}
    />
  )

  const renderGeneralInfo = () => (
    <GeneralInformationForm
      cancelLink={cancelLink}
      initialValues={initialValues}
      isFinanceV3Enabled={isFinanceV3Enabled}
      onSubmit={onMoveToNextStage}
    />
  )

  const renderEnrolmentInfo = () => (
    <EnrolmentDetailsForm
      SessionCalculationAccess={SessionCalculationAccess}
      cancelLink={cancelLink}
      enableSessions={enableSessions}
      initialValues={initialValues}
      isAllYearRoundSelected={isAllYearRoundSelected}
      nurserySessionsOptions={nurserySessionsOptions}
      openingDays={openingDays}
      sessionCalculationOptions={sessionCalculationsOptions}
      onAddSessionItemClick={onAddSessionItemClick}
      onDeleteSessionItemClick={onDeleteSessionItemClick}
      onPreviousStageClick={onPreviousStageClick}
      onSubmit={onMoveToNextStage}
      onToggleSessionClick={onToggleSessionClick}
    />
  )

  const renderContactInfo = () => (
    <ContactsForm
      cancelLink={cancelLink}
      initialValues={initialValues}
      selectedContacts={selectedContacts}
      userTitles={userTitles}
      onAddContactClick={onAddContactClick}
      onChangeExistingContact={onChangeExistingContact}
      onDeleteContactClick={onDeleteContactClick}
      onPreviousStageClick={onPreviousStageClick}
      onSubmit={onMoveToNextStage}
      onTypeChange={onContactTypeChange}
    />
  )

  const renderPreviewPage = () => (
    <ReviewPage
      cancelLink={cancelLink}
      enableSessions={enableSessions}
      formValues={formValues}
      isAllYearRoundSelected={isAllYearRoundSelected}
      isFinanceV3Enabled={isFinanceV3Enabled}
      isSubmitting={isSubmitting}
      openingDays={openingDays}
      onPreviousStageClick={onPreviousStageClick}
      onReviewSubmit={onReviewSubmit}
      onSetStage={onSetStage}
    />
  )

  const renderContent = () => {
    switch (currentStage) {
      case STAGE_INDEXES.GENERAL_INFO:
        return renderGeneralInfo()
      case STAGE_INDEXES.ENROLMENT_INFO:
        return renderEnrolmentInfo()
      case STAGE_INDEXES.CONTACT_INFO:
        return renderContactInfo()
      default:
        return renderPreviewPage()
    }
  }

  const renderContentV3 = () => {
    switch (currentStage) {
      case STAGE_INDEXES_V3.GENERAL_INFO:
        return renderGeneralInfo()
      case STAGE_INDEXES_V3.CONTACT_INFO:
        return renderContactInfo()
      default:
        return renderPreviewPage()
    }
  }

  const isSummaryPage = isFinanceV3Enabled ? 2 < currentStage : 3 < currentStage

  return (
    <Page.Section
      title={isSummaryPage
        ? i18n.t('module:Children:Child:Add:Summary:title')
        : i18n.t('module:Children:Child:Add:title')}
    >
      <Callout content={errorMessages} error />
      {!isSummaryPage && renderStagesIndicator()}
      {isFinanceV3Enabled ? renderContentV3() : renderContent()}
    </Page.Section>
  )
}

export default ChildAddView
