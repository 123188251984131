import _ from 'lodash'
import moment from 'moment'

import { FOOD_AMOUNT, FOOD_AMOUNT_DROPDOWN } from 'services/legacy/foodMenu/constants'
import { DAILY_DIARY_DATE_FORMAT } from 'services/legacy/dailyDiary/constants'

export const getInitialValues = (params, currentDate) => {
  const initialValues = {}
  const { items, record } = params || {}
  const { comments, recordedAt } = record || {}

  if (comments) {
    initialValues.comments = comments
  }

  if (items?.length) {
    initialValues.items = _.map(items, ({ amount, food }) => ({
      amount: (
        _.find(FOOD_AMOUNT_DROPDOWN, ({ value }) => amount === value)
        || _.find(FOOD_AMOUNT_DROPDOWN, ({ value }) => FOOD_AMOUNT.ALL === value)
      ),
      food: {
        label: food.name,
        value: food.id,
      },
    }))
  }

  if (!initialValues.items?.length) {
    initialValues.items = [{
      amount: _.find(FOOD_AMOUNT_DROPDOWN, ({ value }) => FOOD_AMOUNT.ALL === value),
    }]
  }

  if (recordedAt) {
    initialValues.recordedAt = moment(recordedAt)
  } else if (currentDate) {
    const currentHour = moment().format('HH')
    const currentMinute = moment().format('mm')

    const date = moment(currentDate, DAILY_DIARY_DATE_FORMAT).add(currentHour, 'hour').add(currentMinute, 'minute')
    initialValues.recordedAt = moment(date)
  } else {
    initialValues.recordedAt = moment()
  }

  return initialValues
}

export const getValuesForm = (values) => {
  const body = {}

  const { child, comments, date, items, recordedAt, type } = values

  body.comments = comments || ''

  if (date) {
    body.recordedAt = moment(date)
      .set('hour', moment(recordedAt).format('HH'))
      .set('minute', moment(recordedAt).format('mm'))
      .toISOString()
  } else {
    body.recordedAt = moment(recordedAt).toISOString()
  }

  if (child) {
    body.child = +child
  }

  if (type) {
    body.type = type
  }

  if (items?.length) {
    body.items = _.map(items, ({ amount, food }) => ({
      amount: amount?.value,
      food: food?.value,
    }))
  } else {
    body.items = []
  }

  return body
}
