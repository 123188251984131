import i18n from 'translations'

export const BREAKDOWN = {
  DAILY: 'leave-daily',
  GROUPED: 'leave-grouped',
  TOTAL: 'leave-total',
}

export const BREAKDOWN_OPTIONS = [
  {
    label: i18n.t('module:Modals:Staff:Export:Breakdown:daily'),
    value: BREAKDOWN.DAILY,
  },
  {
    label: i18n.t('module:Modals:Staff:Export:Breakdown:grouped'),
    value: BREAKDOWN.GROUPED,
  },
  {
    label: i18n.t('module:Modals:Staff:Export:Breakdown:total'),
    value: BREAKDOWN.TOTAL,
  },
]

export const REPORT_TYPE = {
  GENERAL: 'general',
  SICKNESS: 'sickness',
}

export const REPORT_TYPE_OPTIONS = [
  {
    label: i18n.t('module:Modals:Staff:Export:ReportType:general'),
    value: REPORT_TYPE.GENERAL,
  },
  {
    label: i18n.t('module:Modals:Staff:Export:ReportType:sickness'),
    value: REPORT_TYPE.SICKNESS,
  },
]
