import { generateBasicActions } from 'utils/service'

import {
  CLEAR_ENQUIRY_STATUS_TYPES_SINGLE,
  CREATE_ENQUIRY_STATUS_TYPES,
  CREATE_ENQUIRY_STATUS_TYPES_FAILED,
  CREATE_ENQUIRY_STATUS_TYPES_SUCCESS,
  GET_ENQUIRY_STATUS_TYPES,
  GET_ENQUIRY_STATUS_TYPES_FAILED,
  GET_ENQUIRY_STATUS_TYPES_SUCCESS,
  UPDATE_ENQUIRY_STATUS_TYPES,
  UPDATE_ENQUIRY_STATUS_TYPES_FAILED,
  UPDATE_ENQUIRY_STATUS_TYPES_SUCCESS,
} from './constants'

export const create = (options = {}) => ({ enquiryStatusTypesApiClient }) => generateBasicActions.create({
  constants: {
    failed: CREATE_ENQUIRY_STATUS_TYPES_FAILED,
    init: CREATE_ENQUIRY_STATUS_TYPES,
    success: CREATE_ENQUIRY_STATUS_TYPES_SUCCESS,
  },
  options,
  service: enquiryStatusTypesApiClient,
  serviceMethod: 'create',
})

export const get = (options = {}) => ({ enquiryStatusTypesApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_ENQUIRY_STATUS_TYPES_FAILED,
    init: GET_ENQUIRY_STATUS_TYPES,
    success: GET_ENQUIRY_STATUS_TYPES_SUCCESS,
  },
  options,
  service: enquiryStatusTypesApiClient,
  serviceMethod: 'get',
})

export const update = (options = {}) => ({ enquiryStatusTypesApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_ENQUIRY_STATUS_TYPES_FAILED,
    init: UPDATE_ENQUIRY_STATUS_TYPES,
    success: UPDATE_ENQUIRY_STATUS_TYPES_SUCCESS,
  },
  options,
  service: enquiryStatusTypesApiClient,
  serviceMethod: 'update',
})

export const clearSingle = () => ({
  type: CLEAR_ENQUIRY_STATUS_TYPES_SINGLE,
})
