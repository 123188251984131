import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'recompose'

import { isRequired } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'

import { Form, Section } from 'components'

import i18n from 'translations'

export const FORM_NAME = 'GlobalInvoiceSettingsAddForm'

const GlobalInvoiceSettingsAddForm = ({ globalSettingsOptions, handleSubmit, isLoading, onSubmit }) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Section title="Use global settings">
      <Form.Row>
        <Form.Row.Item>
          <Field
            component={Form.RadioGroup}
            name="globalInheritanceType"
            options={globalSettingsOptions}
            validate={isRequired}
          />
        </Form.Row.Item>
      </Form.Row>
    </Section>
    <Form.FooterActions
      cancelLink={generateRoute('SETTINGS.INVOICES')}
      isSubmitting={isLoading}
      submitLabel={i18n.t('global:Save')}
    />
  </Form>
)

const enhance = compose(reduxForm({ form: FORM_NAME }))

export default enhance(GlobalInvoiceSettingsAddForm)
