import { compose } from 'recompose'
import { Component } from 'react'

import { FEATURE_FLAGS, ROLES } from 'constants/security'

import { generateRoute } from 'utils/routing'

import { withRouter } from 'services/router'

import { getAuthUser, isNurseryContext } from 'services/security/selectors'
import { isOrganizationMember } from 'services/legacy/memberships/single/selectors'
import { getMembershipDataSelector } from 'services/legacy/memberships/selectors'

class StaffContractsWrapper extends Component {
  constructor(props) {
    super(props)

    this.indexRedirect(props)
  }

  shouldComponentUpdate(nextProps) {
    this.indexRedirect(nextProps)

    return true
  }

  indexRedirect = (props) => {
    const {
      location: { pathname },
      navigate,
      params: { userId },
    } = props

    if (generateRoute('ACCOUNT.CONTRACTS') === pathname) {
      navigate(generateRoute('ACCOUNT.CONTRACTS.LIST'))
    }

    if (generateRoute('STAFF.PROFILE.CONTRACTS', { userId }) === pathname) {
      navigate(generateRoute('STAFF.PROFILE.CONTRACTS.LIST', { userId }))
    }
  }

  render() {
    const { children } = this.props

    return children
  }
}

StaffContractsWrapper.authParams = (state) => {
  const { router } = state
  const { locationBeforeTransitions } = router?.common || {}
  const { pathname } = locationBeforeTransitions || {}

  if (pathname?.startsWith(generateRoute('ACCOUNT.INDEX'))) {
    const membership = getMembershipDataSelector(state)
    const authUser = getAuthUser(state)

    if (membership?.id === authUser?.membership?.id) {
      return {
        flags: [FEATURE_FLAGS.STAFF_REGISTER],
        nurseryContext: true,
        roles: [
          ROLES.SUPER_ADMIN,
          ROLES.ORGANIZATION_DIRECTOR,
          ROLES.ORGANIZATION_NATIONAL_ADMIN,
          ROLES.ORGANIZATION_FINANCE_ADMIN,
          ROLES.ORGANIZATION_LINE_MANAGER,
          ROLES.NURSERY_MANAGER,
          ROLES.NURSERY_ADMIN,
          ROLES.DEPUTY_MANAGER,
        ],
      }
    }

    return {}
  }

  if (isNurseryContext(state) && isOrganizationMember(state)) {
    return {}
  }

  return {
    flags: [FEATURE_FLAGS.STAFF_REGISTER],
    nurseryContext: true,
    roles: [
      ROLES.SUPER_ADMIN,
      ROLES.ORGANIZATION_DIRECTOR,
      ROLES.ORGANIZATION_NATIONAL_ADMIN,
      ROLES.ORGANIZATION_FINANCE_ADMIN,
      ROLES.ORGANIZATION_LINE_MANAGER,
      ROLES.NURSERY_MANAGER,
      ROLES.NURSERY_ADMIN,
    ],
  }
}

const enhance = compose(
  withRouter,
)

export default enhance(StaffContractsWrapper)
