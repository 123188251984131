import _ from 'lodash'
import { createSelector } from 'reselect'

import * as constants from '../constants'

export const getNurseryExtraItemsSelector = (state) => state.nurseryExtraItems

export const getNurseryExtraItemsListSelector = createSelector(
  [getNurseryExtraItemsSelector],
  (state) => state.list.data,
)

export const formattedNurseryExtraItemOptions = (extraItems) => {
  if (!extraItems?.length) {
    return null
  }

  return _.map(extraItems, ({ feeCalculations, id, name, unitPrice }) => ({
    feeCalculations,
    label: name,
    unitPrice,
    value: id,
  }))
}

export const getNurseryExtraItemsOptionsSelector = createSelector(
  [getNurseryExtraItemsListSelector],
  formattedNurseryExtraItemOptions,
)

export const getCriteriaSelector = createSelector(
  [((filters) => filters)],
  (filters) => {
    const {
      phrase,
      statusFilter = constants.NURSERY_EXTRA_ITEMS_FILTER.ACTIVE,
    } = filters || {}

    const criteria = [{
      field: 'type',
      value: 'nursery',
    }]

    if (statusFilter && statusFilter !== constants.NURSERY_EXTRA_ITEMS_FILTER.ALL) {
      criteria.push({
        field: 'archived',
        value: statusFilter === constants.NURSERY_EXTRA_ITEMS_FILTER.ARCHIVED,
      })
    }

    if (phrase) {
      criteria.push({
        field: 'name',
        value: phrase,
      })
    }

    return criteria
  },
)

export const isNurseryExtraItemsEmpty = createSelector(
  [getNurseryExtraItemsListSelector],
  (extraItems) => !(!extraItems || !extraItems.length),
)
