import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withModalService } from 'services/utils/modal'
import { withFormativeReportsService } from 'services/legacy/formativeReports'

import i18n from 'translations'

import FormativeReportsExportModalView from './FormativeReportsExportModalView'

class FormativeReportsExportModalContainer extends Component {
  componentDidMount() {
    const { child, formativeReportsActions, formativeReportsSelectors } = this.props

    const apiParams = { criteria: formativeReportsSelectors.getPeriodsCriteria({ childId: child?.id }) }

    formativeReportsActions.getFormativeReportsPeriods(apiParams)
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSubmitSuccess = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.ALERT, {
      text: i18n.t('module:Modals:FormativeReportsExportModal:alert'),
    })
  }

  handleSubmit = (fields) => {
    const { child, formativeReportsActions, formativeReportsSelectors } = this.props

    const criteria = formativeReportsSelectors.getExportCriteria(child ? { child, fields } : { fields })

    formativeReportsActions.exportReport({ criteria }, this.handleSubmitSuccess)
  }

  render() {
    const { child, isExporting, periodsLabelsOptions } = this.props

    return (
      <FormativeReportsExportModalView
        child={child}
        isSubmitting={isExporting}
        labelsOptions={periodsLabelsOptions}
        onCloseClick={this.handleCloseClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, { formativeReportsSelectors }) => ({
  isExporting: formativeReportsSelectors.isExporting(state),
  periodsLabelsOptions: formativeReportsSelectors.getPeriodsLabelsOptions(state),
})

const mapDispatch = {}

const enhance = compose(
  withModalService,
  withFormativeReportsService,
  connect(mapState, mapDispatch),
)

export default enhance(FormativeReportsExportModalContainer)
