import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_OFFLINE_ACTIONS,
  LIST_REGISTERS_GLOBAL,
  LIST_REGISTERS_GLOBAL_FAILED,
  LIST_REGISTERS_GLOBAL_SUCCESS,
  UPDATE_OFFLINE_ACTION,
} from './constants'

export const listGlobal = (options: BasicActionOptionsProps) => ({ registerApiClient }) => (
  generateBasicActions.list({
    constants: {
      failed: LIST_REGISTERS_GLOBAL_FAILED,
      init: LIST_REGISTERS_GLOBAL,
      success: LIST_REGISTERS_GLOBAL_SUCCESS,
    },
    options,
    service: registerApiClient,
    serviceMethod: 'list',
  })
)

export const updateOfflineAction = (payload) => ({
  payload,
  type: UPDATE_OFFLINE_ACTION,
})

export const clearOfflineActions = () => ({
  type: CLEAR_OFFLINE_ACTIONS,
})
