import React from 'react'

import { Page, SubMenuWrapper } from 'components'

const SettingsWrapperView = ({ children }) => (
  <SubMenuWrapper>
    <Page>
      <Page.Content>
        {children}
      </Page.Content>
    </Page>
  </SubMenuWrapper>
)

export default SettingsWrapperView
