import React from 'react'

import { ChildrenSlider } from 'components'

import ActivityTabForm from './components/ActivityTabForm'
import { StyledContainer } from './ActivityTabStyled'
import { StyledSliderContainer } from '../DailyDiaryRecordModalStyled'

const ActivityTabView = ({
  selectedChildren,
  ...rest
}) => (
  <StyledContainer>
    <StyledSliderContainer>
      <ChildrenSlider
        childrenList={selectedChildren || []}
      />
    </StyledSliderContainer>
    <ActivityTabForm
      selectedChildren={selectedChildren}
      {...rest}
    />
  </StyledContainer>
)

export default ActivityTabView
