import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as assessmentTrackerSelectors from './selectors'

const withAssessmentTrackerService = (WrappedComponent) => {
  const mapState = ({ assessmentTracker }) => ({ assessmentTracker })

  const mapDispatch = injectActions('assessmentTrackerActions', actions)

  const Component = ({ assessmentTracker, assessmentTrackerActions, ...other }) => (
    <WrappedComponent
      assessmentTrackerActions={assessmentTrackerActions}
      assessmentTrackerListState={assessmentTracker.list}
      assessmentTrackerSelectors={assessmentTrackerSelectors}
      {...other}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withAssessmentTrackerService
