import styled from 'styled-components'

export const StyledTimePickerContainer = styled.div`
  display: grid;
  grid-template-columns: 130px 130px;
  grid-gap: 10px;
`

export const StyledFeesWrapperContainer = styled.div`
  overflow-x: auto;
`

export const StyledFeesContainer = styled.div`
  display: table;
`

export const StyledFeesContentContainer = styled.div`
  display: table-row;
`

export const StyledFeesItemContainer = styled.div`
  display: table-cell;
  padding-bottom: 5px;
  
  &:not(:first-child) {
    padding-left: 10px;
  }
`
