import _ from 'lodash'

import React, { useMemo } from 'react'

import { convertTimeDuration, millisecondsToHoursAndMinutesString } from 'utils/date'

import LeaveBanner from 'module/Modals/Staff/components/LeaveBanner'

import { Banner } from 'components'

import i18n from 'translations'

import ShiftModalFormStatistics from './ShiftModalFormStatistics'
import ShiftModalFormPreviousRecordBanner from './ShiftModalFormPreviousRecordBanner'
import { getBreakBanners } from './ShiftModalFormHelpers'
import { StyledCustomBanner, StyledDay, StyledRowExtraWrapper } from './ShiftModalFormStyled'

const ShiftModalFormExtraFields = ({
  attendanceView,
  contracts,
  currentRegisters,
  date,
  formValues,
  getPreviousRecord,
  leaves,
  memberDetails,
  mode,
  shiftTimesErrors,
  statistics,
}) => {
  const leaveBanners = useMemo(() => _.filter(leaves[date], ({ id }) => (
    !_.find(formValues, ({ id: formId }) => formId === id)
  )), [date, formValues, leaves])
  const errors = shiftTimesErrors?.[date]
  const dayStatistics = statistics?.[date]
  const breakBanners = useMemo(() => getBreakBanners({ contracts, date, formValues }), [contracts, date, formValues])

  return (
    <React.Fragment>
      {attendanceView ? <ShiftModalFormStatistics dayStatistics={dayStatistics} mode={mode} /> : null}
      {errors?.length ? (
        <StyledRowExtraWrapper>
          <StyledDay noBorder />
          <StyledDay noPadding white>
            <StyledCustomBanner>
              <Banner.Error>
                {_.map(errors, (content) => (
                  <React.Fragment key={content}>
                    {content}
                    <br />
                  </React.Fragment>
                ))}
              </Banner.Error>
            </StyledCustomBanner>
          </StyledDay>
        </StyledRowExtraWrapper>
      ) : null}
      {leaveBanners.length ? (
        <StyledRowExtraWrapper>
          <StyledDay noBorder />
          <StyledDay noPadding white>
            <LeaveBanner
              date={date}
              leaves={leaves}
              borderVersion
            />
          </StyledDay>
        </StyledRowExtraWrapper>
      ) : null}
      {breakBanners?.length ? (
        <StyledRowExtraWrapper>
          <StyledDay noBorder />
          <StyledDay noPadding white>
            <StyledCustomBanner>
              <Banner.Warning>
                {_.map(breakBanners, ({ endTime, startTime, time }, index) => (
                  <React.Fragment key={`BREAK_${startTime}_${index}`}>
                    {i18n.t('module:Modals:Staff:Shift:breakBanner', {
                      from: startTime ? millisecondsToHoursAndMinutesString(startTime) : 0,
                      time: convertTimeDuration(time, 'milliseconds', 'minutes'),
                      to: endTime ? millisecondsToHoursAndMinutesString(endTime) : 0,
                    })}
                    <br />
                  </React.Fragment>
                ))}
              </Banner.Warning>
            </StyledCustomBanner>
          </StyledDay>
        </StyledRowExtraWrapper>
      ) : null}
      <ShiftModalFormPreviousRecordBanner
        currentRegisters={currentRegisters}
        date={date}
        getPreviousRecord={getPreviousRecord}
        memberDetails={memberDetails}
        mode={mode}
      />
    </React.Fragment>
  )
}

export default ShiftModalFormExtraFields
