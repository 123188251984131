import React from 'react'

import { Callout, Spinner } from 'components'

import GlobalEditForm from './components/GlobalEditForm'

interface GlobalEditViewProps {
  errorMessages?: string
  initialValues?: any
  isFormLoading?: boolean
  isLoading?: boolean
  onSubmit: (fields: any) => void
}

const GlobalEditView: React.FC<GlobalEditViewProps> = ({
  errorMessages,
  initialValues,
  isFormLoading,
  isLoading,
  onSubmit,
}) => {
  if (isLoading) {
    return <Spinner />
  }

  return (
    <React.Fragment>
      <Callout content={errorMessages} error />
      <GlobalEditForm
        initialValues={initialValues}
        isLoading={isFormLoading}
        onSubmit={onSubmit}
      />
    </React.Fragment>
  )
}

export default GlobalEditView
