export const CREATE_BATCH_INVOICES = 'CREATE_BATCH_INVOICES'
export const CREATE_BATCH_INVOICES_SUCCESS = 'CREATE_BATCH_INVOICES_SUCCESS'
export const CREATE_BATCH_INVOICES_FAILED = 'CREATE_BATCH_INVOICES_FAILED'

export const UPDATE_BATCH_INVOICES = 'UPDATE_BATCH_INVOICES'
export const UPDATE_BATCH_INVOICES_SUCCESS = 'UPDATE_BATCH_INVOICES_SUCCESS'
export const UPDATE_BATCH_INVOICES_FAILED = 'UPDATE_BATCH_INVOICES_FAILED'

export const ADD_BATCH_INVOICE = 'ADD_BATCH_INVOICE'
export const UPDATE_BATCH_INVOICE = 'UPDATE_BATCH_INVOICE'
export const REMOVE_BATCH_INVOICE = 'REMOVE_BATCH_INVOICE'
export const CLEAR_BATCH_INVOICES = 'CLEAR_BATCH_INVOICES'

