import React from 'react'

import { Callout, ModalBox } from 'components'

import ChildHeader from './components/ChildHeader'
import FrameworkBulkMarkForm from './components/FrameworkBulkMarkForm'

const FrameworkBulkMarkModalView = ({
  ageBandOptions,
  areaOptions,
  child,
  currentStep,
  error,
  formValues,
  groupOptions,
  initialValues,
  isFormLoading,
  itemsCount,
  onCloseClick,
  onSubmit,
}) => (
  <ModalBox
    title="Bulk mark"
    autoHeight
    onCloseClick={onCloseClick}
  >
    <Callout content={error} error />
    <ChildHeader child={child} />
    <FrameworkBulkMarkForm
      ageBandOptions={ageBandOptions}
      areaOptions={areaOptions}
      currentStep={currentStep}
      currentValues={formValues}
      groupOptions={groupOptions}
      initialValues={initialValues}
      isLoading={isFormLoading}
      itemsCount={itemsCount}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    />
  </ModalBox>
)

export default FrameworkBulkMarkModalView
