import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { Form } from 'components'
import GroupDropdown from 'module/Newsletters/components/GroupDropdown'
import IndividualDropdown from 'module/Newsletters/components/IndividualDropdown'

import i18n from 'translations'

export const ADD_NEW_RECIPIENTS_FORM = 'AddNewRecipients'

const AddNewRecipientsModalForm = ({
  handleSubmit,
  isSubmitting,
  onCloseClick,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row label={i18n.t('module:Newsletters:Add:toGroupLabel')}>
      <Form.Row.FlexItem>
        <Field
          component={GroupDropdown}
          name="groups"
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.Row label={i18n.t('module:Newsletters:Add:toIndividualsLabel')}>
      <Form.Row.FlexItem>
        <Field
          component={IndividualDropdown}
          name="individuals"
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.FooterActions
      isSubmitting={isSubmitting}
      submitLabel={i18n.t('global:Add')}
      onCancelClick={onCloseClick}
    />
  </Form>
)

export default reduxForm({ form: ADD_NEW_RECIPIENTS_FORM })(AddNewRecipientsModalForm)
