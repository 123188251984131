import { ROLES } from 'constants/security'

const NewslettersWrapper = ({ children }) => children

NewslettersWrapper.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

export default NewslettersWrapper
