import _ from 'lodash'
import moment from 'moment'

export const getToBeInvoicedFromForecastItem = (forecastStatistics) => {
  const negativeValueKeys = ['totalFundingDeducted', 'totalDiscount']

  return _.map(forecastStatistics, (monthlyForecast) => {
    const { forecast, invoices } = monthlyForecast

    const toBeInvoiced = []

    _.forEach(forecast, (value, key) => {
      if (!value) {
        toBeInvoiced[key] = 0

        return
      }

      if (!invoices[key]) {
        toBeInvoiced[key] = value

        return
      }

      const toBeInvoicedValue = _.round(value - invoices[key], 2)

      const isNegativeKeyFound = _.indexOf(negativeValueKeys, key)

      if (0 <= isNegativeKeyFound) {
        toBeInvoiced[key] = 0 > toBeInvoicedValue ? toBeInvoicedValue : 0

        return
      }

      toBeInvoiced[key] = 0 < toBeInvoicedValue ? toBeInvoicedValue : 0
    })

    return {
      ...monthlyForecast,
      toBeInvoiced,
    }
  })
}

export const getToBeInvoicedFromForecastList = (listData) => _.map(
  listData,
  ({ monthlyForecastStatistics, ...other }) => ({
    ...other,
    monthlyForecastStatistics: getToBeInvoicedFromForecastItem(monthlyForecastStatistics),
  }),
)

export const getForecastingExportPayload = (filters) => {
  if (!filters) {
    return null
  }

  const { endDate, recipients, startDate } = filters

  return {
    dateType: 'issueDate',
    endDate: moment(endDate).format('YYYY-MM-DD'),
    recipients: recipients ? [{ id: recipients.value }] : undefined,
    startDate: moment(startDate).format('YYYY-MM-DD'),
  }
}
