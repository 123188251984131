import { combineReducers } from 'redux'

import common, { NurseryContextCommonState } from './common/reducer'

export default combineReducers({
  common,
})

export interface NurseryContextState {
  common: NurseryContextCommonState
}
