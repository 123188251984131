import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  GET_DROPDOWN_NURSERY_ITEM_LIST,
  GET_DROPDOWN_NURSERY_ITEM_LIST_FAILED,
  GET_DROPDOWN_NURSERY_ITEM_LIST_SUCCESS,
} from './constants'

export const dropdownList = (
  options: BasicActionOptionsProps,
) => ({ nurseryItemApiClient }) => generateBasicActions.list({
  constants: {
    failed: GET_DROPDOWN_NURSERY_ITEM_LIST_FAILED,
    init: GET_DROPDOWN_NURSERY_ITEM_LIST,
    success: GET_DROPDOWN_NURSERY_ITEM_LIST_SUCCESS,
  },
  options,
  service: nurseryItemApiClient,
  serviceMethod: 'list',
})
