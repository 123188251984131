import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class EnquiryViewingsApiClient extends ServiceBase {
  get = (id, params = {}) => this.request({
    path: `/v2/enquiry_show_arounds/${id}?${getQueryStringV2(params)}`,
  })

  list = (params = {}) => this.request({
    path: `/v2/enquiry_show_arounds?${getQueryStringV2(params)}`,
  })

  create = (params = {}, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/enquiry_show_arounds?${getQueryStringV2(params)}`,
  })

  update = (id, params = {}, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/enquiry_show_arounds/${id}?${getQueryStringV2(params)}`,
  })

  export = (params = {}) => this.request({
    path: `/v2/enquiry_show_arounds/report/show-around?${getQueryStringV2(params)}`,
  })
}

export default EnquiryViewingsApiClient
