import _ from 'lodash'

import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import { UPDATE_MANUALLY_FRAMEWORK_CHILD_OBSERVATION_PROGRESS } from '../frameworkProgress/constants'
import {
  CLEAR_CHILD_OBSERVATION,
  GET_CHILD_OBSERVATION,
  GET_CHILD_OBSERVATION_FAILED,
  GET_CHILD_OBSERVATION_SUCCESS,
  REMOVE_CHILD_OBSERVATION,
  REMOVE_CHILD_OBSERVATION_FAILED,
  REMOVE_CHILD_OBSERVATION_SUCCESS,
  UPDATE_CHILD_OBSERVATION,
  UPDATE_CHILD_OBSERVATION_FAILED,
  UPDATE_CHILD_OBSERVATION_SUCCESS,
  UPDATE_MONTESSORI_ACTIVITIES_IN_CHILD_OBSERVATION,
} from './constants'

const initialState = getBasicSingleInitialState

export default (...props) => {
  const [state = initialState, { payload, type }] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_CHILD_OBSERVATION,
    itemFailed: GET_CHILD_OBSERVATION_FAILED,
    itemInit: GET_CHILD_OBSERVATION,
    itemSuccess: GET_CHILD_OBSERVATION_SUCCESS,
    removeFailed: REMOVE_CHILD_OBSERVATION_FAILED,
    removeInit: REMOVE_CHILD_OBSERVATION,
    removeSuccess: REMOVE_CHILD_OBSERVATION_SUCCESS,
    updateFailed: UPDATE_CHILD_OBSERVATION_FAILED,
    updateInit: UPDATE_CHILD_OBSERVATION,
    updateSuccess: UPDATE_CHILD_OBSERVATION_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  if (type === UPDATE_MANUALLY_FRAMEWORK_CHILD_OBSERVATION_PROGRESS) {
    const { framework, frameworkItem, linkedItems, progressLevel } = payload || {}
    const { data: { frameworkEvents } } = state

    return {
      ...state,
      data: {
        ...state.data,
        frameworkEvents: _.map(frameworkEvents, (item) => {
          const newItem = { ...item }

          if (newItem.framework.id === framework) {
            let foundItem = false

            newItem.events = _.map(newItem.events, (event) => {
              if (event.frameworkElement?.id === frameworkItem) {
                foundItem = true

                return {
                  ...event,
                  progressLevel,
                }
              }

              return event
            })

            _.each(linkedItems, ({ id: linkedItemId }) => {
              let foundLinkedItem = false

              newItem.events = _.map(newItem.events, (event) => {
                if (event.frameworkElement.id === linkedItemId) {
                  foundLinkedItem = true

                  return {
                    ...event,
                    progressLevel,
                  }
                }

                return event
              })

              if (!foundLinkedItem) {
                newItem.events = [
                  ...newItem.events,
                  {
                    frameworkElement: {
                      id: linkedItemId,
                    },
                    progressLevel,
                  },
                ]
              }
            })

            if (!foundItem) {
              newItem.events = [
                ...newItem.events,
                {
                  frameworkElement: {
                    id: frameworkItem,
                  },
                  progressLevel,
                },
              ]
            }
          }

          return newItem
        }),
      },
    }
  }

  if (UPDATE_MONTESSORI_ACTIVITIES_IN_CHILD_OBSERVATION === type) {
    const [response, toAdd, clearAll] = payload

    let newNextMontessoriActivities = _.cloneDeep(state.data?.nextMontessoriActivities)

    if (toAdd) {
      newNextMontessoriActivities.push(response.data)
    } else if (clearAll) {
      newNextMontessoriActivities = []
    } else {
      newNextMontessoriActivities = _.filter(newNextMontessoriActivities, (activity) => activity.id !== response.id)
    }

    return {
      ...state,
      data: {
        ...state.data,
        nextMontessoriActivities: newNextMontessoriActivities,
      },
    }
  }

  return state
}
