import _ from 'lodash'

import React, { useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'
import { stopSubmit } from 'redux-form'

import { EVENTS, identifyAuthenticated, logEvent } from 'analytics'

import { RootState } from 'core/reducers'
import { LOGIN_SUCCESSFUL } from 'services/legacy/auth/constants'
import { DEVICE_NOT_AUTHORISED_ERROR_CODE } from 'services/authentication/constants'
import { Profile } from 'services/authentication/models'

import eventBus from 'utils/eventBus'
import { getBackendErrors } from 'utils/backendErrors'

import { withAppService, withAppServiceProps } from 'services/app'
import { withAuthenticationService, withAuthenticationServiceProps } from 'services/authentication'
import { withDeviceService, withDeviceServiceProps } from 'services/device'
import { withShellService, withShellServiceProps } from 'services/shell'
import { withRouter, withRouterProps } from 'services/router'

import { INSTALL_DEVICE_FORM, InstallDeviceFormValues } from './components/InstallDeviceForm'
import { LoginFormValues } from './components/LoginForm'
import LoginView from './LoginView'

type LoginContainerFullProps = withAuthenticationServiceProps
  & withAppServiceProps
  & withDeviceServiceProps
  & withShellServiceProps
  & withRouterProps

const mapDispatch = {
  injectValidation: (formName, data) => stopSubmit(formName, data),
}

const mapState = (state: RootState, {
  appSelectors,
  authenticationCommonState,
  authenticationSelectors,
}: LoginContainerFullProps) => ({
  errorMessages: appSelectors.getErrorMessages(authenticationCommonState),
  isUserLoggedIn: authenticationSelectors.getIsUserLoggedIn(state),
})

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

const LoginContainer: React.FC<LoginContainerFullProps & PropsFromRedux> = ({
  authenticationActions,
  deviceActions,
  errorMessages,
  injectValidation,
  isUserLoggedIn,
  location,
  router,
  shellActions,
}) => {
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [fromLogout, setFromLogout] = useState<boolean>(false)
  const [showDeviceFrom, setShowDeviceForm] = useState<boolean>(false)
  const [loginData, setLoginData] = useState<LoginFormValues>({
    identifier: null,
    password: null,
  })

  useEffect(() => {
    const { query } = location

    setFromLogout(_.has(query, 'fromLogout'))
    shellActions.setSettings({
      minimal: true,
    })
  }, [])

  useEffect(() => {
    if (isUserLoggedIn) {
      router.replace(location.query.redirect || '/')
    }
  }, [isUserLoggedIn])

  const handleLoginSuccess = (token, profile: Profile) => {
    const identity = { profile, token }

    identifyAuthenticated(profile)

    logEvent(EVENTS.AUTHENTICATION_SIGNED_IN, { context: 'login page' })
    logEvent(EVENTS.SESSION_STARTED, { context: 'login page' })
    eventBus.dispatch(LOGIN_SUCCESSFUL, identity)
    authenticationActions.setIdentity({ profile, token })

    router.replace('/')
  }

  const handleGetMe = (response) => {
    const { data: { token } } = response

    return authenticationActions.authMe({
      onFailed: () => setIsFetching(false),
      onSuccess: ({ data }) => handleLoginSuccess(token, data),
      params: [token],
    })
  }

  const handleLoginFailed = (values) => (error) => {
    setIsFetching(false)

    if (error?.code === DEVICE_NOT_AUTHORISED_ERROR_CODE) {
      setLoginData(values)
      setShowDeviceForm(true)
    }
  }

  const handleLogin = (values: LoginFormValues) => {
    setIsFetching(true)

    authenticationActions.login({
      body: values,
      onFailed: handleLoginFailed(values),
      onSuccess: handleGetMe,
    })
  }

  const handleLoginWithPinFailed = (response) => {
    setIsFetching(false)
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    return injectValidation(INSTALL_DEVICE_FORM, errors)
  }

  const handleSetDeviceIdentity = (response) => {
    const { data: { token } } = response

    deviceActions.changeDeviceIdentity(token)
    handleLogin(loginData)
  }

  const handleLoginWithPin = (values: InstallDeviceFormValues) => {
    setIsFetching(true)

    authenticationActions.installDeviceToken({
      body: {
        ...loginData,
        ...values,
      },
      onFailed: handleLoginWithPinFailed,
      onSuccess: handleSetDeviceIdentity,
    })
  }

  const handleGoToLogin = () => {
    setShowDeviceForm(false)
    setLoginData({
      identifier: null,
      password: null,
    })
  }

  return (
    <LoginView
      errorMessages={errorMessages}
      fromLogout={fromLogout}
      isFetching={isFetching}
      showDeviceFrom={showDeviceFrom}
      onGoToLogin={handleGoToLogin}
      onLogin={handleLogin}
      onLoginWithPin={handleLoginWithPin}
    />
  )
}

const enhance = compose(
  withAppService,
  withAuthenticationService,
  withDeviceService,
  withRouter,
  withShellService,
  connect(mapState, mapDispatch),
)

export default enhance(LoginContainer)
