import React from 'react'
import moment from 'moment'
import { compose } from 'recompose'
import { Field, reduxForm } from 'redux-form'

import { STATUS_OPTIONS_VALUES } from 'services/users/constants'
import { SUPPORTED_FILE_TYPES } from 'constants/mimetypes'

import { isRequired } from 'utils/fieldValidation'

import { Button, Form, SvgInteractive, Typography } from 'components'

import i18n from 'translations'

import image from 'assets/images/body-map.svg'

import { StyledContainerForm } from '../InjuryBasicForm/InjuryBasicFormStyled'

export const INJURY_BASIC_FORM = 'InjuryBasicForm'

const HomeAccidentForm = ({
  childId,
  defaultBodyMapCoordinates,
  handleSubmit,
  initialValues,
  injuryTypeOptions,
  isChangedCoordinate,
  isEditMode,
  isHideBodyMap,
  onChangeMedia,
  onClickCoordinates,
  onClickHideBodyMap,
  onSubmit,
}) => (
  <div style={{ userSelect: 'none' }}>
    <StyledContainerForm>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Field
          component={Form.TextField}
          name="accidentDetailsId"
          type="hidden"
        />
        {!childId && (
          <Form.Row label={i18n.t('module:Safeguarding:Fields:ChildName:label')} required verticalLabel>
            <Form.Row.FlexItem flex="0 0 400px" mobileFull>
              <Field
                component={Form.InfiniteDropdowns.Children}
                name="childName"
                placeholder={i18n.t('module:Safeguarding:Fields:ChildName:placeholder')}
                status={STATUS_OPTIONS_VALUES.ACTIVE}
                validate={isRequired}
                searchable
              />
            </Form.Row.FlexItem>
          </Form.Row>
        )}
        <Form.Row label={i18n.t('module:Safeguarding:Fields:AccidentDate:label')} required verticalLabel>
          <Form.Row.FlexItem flex="0 0 400px" mobileFull>
            <Field
              component={Form.DateTimePicker}
              disabledDays={{
                after: moment().toDate(),
              }}
              name="date"
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row label={i18n.t('module:Safeguarding:Fields:AccidentLocation:label')} required verticalLabel>
          <Form.Row.FlexItem flex="0 0 400px" mobileFull>
            <Field
              component={Form.TextField}
              name="location"
              placeholder={i18n.t('module:Safeguarding:Fields:AccidentLocation:placeholder')}
              validate={isRequired}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row label={i18n.t('module:Safeguarding:Fields:AccidentDetails:label')} required verticalLabel>
          <Form.Row.FlexItem flex="0 0 800px" mobileFull>
            <Field
              component={Form.TextField}
              name="accidentDetails"
              placeholder={i18n.t('module:Safeguarding:Fields:AccidentDetails:placeholder')}
              validate={isRequired}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row label={i18n.t('module:Safeguarding:Fields:FirstAidProvided:label')} verticalLabel>
          <Form.Row.FlexItem flex="0 0 800px" mobileFull>
            <Field
              component={Form.TextAreaField}
              name="firstAidProvided"
              placeholder={i18n.t('module:Safeguarding:Fields:FirstAidProvided:placeholder')}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Typography bold>{i18n.t('module:Safeguarding:Fields:InjuryDetails:label')}</Typography>
        <Form.Row label={i18n.t('module:Safeguarding:Fields:Injury:label')} verticalLabel>
          <Form.Row.FlexItem flex="0 0 400px" mobileFull>
            <Field
              component={Form.Select}
              name="injuryType"
              options={injuryTypeOptions}
              placeholder={i18n.t('module:Safeguarding:Fields:Injury:placeholder')}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row label={i18n.t('module:Safeguarding:Fields:InjuryDescription:label')} required verticalLabel>
          <Form.Row.FlexItem flex="0 0 800px" mobileFull>
            <Field
              component={Form.TextAreaField}
              name="injuryDescription"
              placeholder={i18n.t('module:Safeguarding:Fields:InjuryDescription:placeholder')}
              validate={isRequired}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row
          label={(
            <Typography bold>
              {i18n.t('module:Safeguarding:Fields:BodyMap:label')}
            </Typography>
          )}
          margin="20px 0 0"
        >
          <Form.Row.FlexItem align="end" mobileFull>
            <Button
              hierarchy="tertiary"
              label={(
                <Typography transform="uppercase" bold>
                  {isHideBodyMap
                    ? i18n.t('module:Safeguarding:Fields:BodyMap:showBodyMap')
                    : i18n.t('module:Safeguarding:Fields:BodyMap:hideBodyMap')}
                </Typography>
            )}
              negativeMargins
              onClick={onClickHideBodyMap}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        {!isHideBodyMap && (
          <Form.Row width={{ field: '800px' }} verticalLabel>
            <Typography fontSize={14}>{i18n.t('module:Safeguarding:Fields:BodyMap:note')}</Typography>
          </Form.Row>
        )}
        {!isHideBodyMap && (
          <Form.Row verticalLabel>
            <Form.Row.FlexItem mobileFull>
              <SvgInteractive
                defaultValues={isEditMode ? initialValues.bodyMapCoordinates : defaultBodyMapCoordinates}
                displayClearAll={(!isChangedCoordinate
                  && !defaultBodyMapCoordinates.length
                  && isEditMode
                  && initialValues.bodyMapCoordinates?.length) ? true : defaultBodyMapCoordinates.length}
                image={image}
                svgViewBox="0 0 924 535"
                onClick={onClickCoordinates}
              />
            </Form.Row.FlexItem>
          </Form.Row>
        )}
        <Form.Row label={i18n.t('module:Safeguarding:Fields:AttachedMedia:label')} labelMargin="0 20px 0 0">
          <Form.Row.FlexItem flex="1" mobileFull>
            <Field
              accept={[SUPPORTED_FILE_TYPES.IMAGES]}
              component={Form.MediaPicker}
              name="media"
              disableMediaTag
              disableTagging
              sync
              onChange={onChangeMedia}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row label={i18n.t('module:Safeguarding:Fields:AdditionalNotes:label')} verticalLabel>
          <Form.Row.FlexItem flex="0 0 800px" mobileFull>
            <Field
              component={Form.TextAreaField}
              name="additionalNotes"
              placeholder={i18n.t('module:Safeguarding:Fields:AdditionalNotes:placeholder')}
            />
          </Form.Row.FlexItem>
        </Form.Row>
      </Form>
    </StyledContainerForm>
  </div>
)
const enhance = compose(
  reduxForm({ form: INJURY_BASIC_FORM }),
)

export default enhance(HomeAccidentForm)
