import React from 'react'

import { Avatar, DateString } from 'components'

import {
  StyledAvatarContainer,
  StyledContainer,
  StyledDate,
  StyledProfileInner,
  StyledProfileName,
  StyledSubtitle,
} from './RegisterItemProfileStyled'

import RegisterItemProfileBadge from './RegisterItemProfileBadge'

const RegisterItemProfile = ({ absence, child, date, hasExtraSessions, isOffline, size, subtitle }) => {
  const renderProfile = () => {
    if (date) {
      return (
        <StyledProfileInner>
          <StyledDate>
            <DateString date={date} />
          </StyledDate>
          {subtitle && (
            <StyledSubtitle>
              {subtitle}
            </StyledSubtitle>
          )}
        </StyledProfileInner>
      )
    }

    if (!child) {
      return null
    }

    const { firstName, id, photo, surname } = child

    const to = `/children/${id}/about/attendance`

    return (
      <StyledProfileInner to={!isOffline && to}>
        <StyledAvatarContainer>
          <Avatar
            initials={[firstName, surname]}
            src={photo}
          />
          <RegisterItemProfileBadge absence={absence} hasExtraSessions={hasExtraSessions} />
        </StyledAvatarContainer>
        <StyledProfileName>
          {`${firstName || ''} ${surname || ''}`}
        </StyledProfileName>
        {subtitle && (
          <StyledSubtitle>
            {subtitle}
          </StyledSubtitle>
        )}
      </StyledProfileInner>
    )
  }

  return (
    <StyledContainer size={size}>
      {renderProfile()}
    </StyledContainer>
  )
}

export default RegisterItemProfile
