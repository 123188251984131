import { createSelector } from 'reselect'

const getFinanceReports = (state) => state.financeRevenuePerRoomReport

export const getFinanceRevenuePerRoomReportNurseries = createSelector(
  [getFinanceReports],
  (state) => state.nurseries,
)

export const getFinanceRevenuePerRoomReportNurseriesData = createSelector(
  [getFinanceRevenuePerRoomReportNurseries],
  (state) => state.data,
)
