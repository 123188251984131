import moment from 'moment'

import React from 'react'

import { ModalBox, Section, Spinner } from 'components'
import InvoiceTable from 'module/Finance/components/InvoiceTable'
import InvoiceDeliveryRefNo from 'module/Finance/components/InvoiceDeliveryRefNo'

import InvoiceHeader from './components/InvoiceHeader'

const PreviewModalView = ({
  invoicePreview,
  isLoading,
  onCloseClick,
}) => {
  const renderContent = () => {
    if (isLoading || !invoicePreview) {
      return <Spinner />
    }

    const { invoice } = invoicePreview
    const { endDate, startDate } = invoice

    const title = moment(startDate).month() === moment(endDate).month()
      ? moment(startDate).format('MMMM YYYY')
      : `${moment(startDate).format('MMMM YYYY')} - ${moment(endDate).format('MMMM YYYY')}`

    return (
      <Section title={title}>
        <InvoiceHeader invoicePreview={invoicePreview} />
        <InvoiceTable invoicePreview={invoicePreview} preview />
        <InvoiceDeliveryRefNo invoicePreview={invoicePreview} />
      </Section>
    )
  }

  return (
    <ModalBox
      title="Invoice preview"
      autoHeight
      onCloseClick={onCloseClick}
    >
      {renderContent()}
    </ModalBox>
  )
}

export default PreviewModalView
