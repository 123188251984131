import _ from 'lodash'

import { getBackendErrors } from 'utils/backendErrors'

export const prepareBackendErrors = (response, membershipShiftTimes) => {
  const errors = getBackendErrors(response)

  if (!errors) {
    return false
  }

  if (errors.dateFrom) {
    errors.date = errors.dateFrom
    delete errors.dateFrom
  }

  if (errors.dateTo) {
    errors.date = errors.dateTo
    delete errors.dateTo
  }

  if (errors.membershipShiftTimes) {
    const newErrors = {}

    if (!_.isString(errors.membershipShiftTimes)) {
      const shiftTimesNotEmpty = _.omitBy(membershipShiftTimes, (dayItems) => (
        !_.filter(dayItems, (subItems) => _.keys(subItems).length).length
      ))

      _.each(errors.membershipShiftTimes, (subError, index) => {
        let previousIndex = 0

        if (_.isUndefined(subError)) {
          return
        }

        _.each(shiftTimesNotEmpty, (items, date) => {
          _.each(items, (shift, shiftIndex) => {
            if (shiftIndex === index - previousIndex) {
              if (!newErrors[date]) {
                newErrors[date] = []
              }

              if (_.isString(subError)) {
                newErrors[date][index - previousIndex] = {
                  startTime: subError,
                }
              } else {
                newErrors[date][index - previousIndex] = subError
              }
            }
          })

          previousIndex += items.length
        })

        errors.membershipShiftTimes = newErrors
      })
    }
  }

  return errors
}

export const prepareExportErrors = (response) => {
  const errors = getBackendErrors(response)

  if (!errors) {
    return false
  }

  if (errors.dateFrom) {
    errors.reportDatesRange = errors.dateFrom
    delete errors.dateFrom
  }

  if (errors.dateTo) {
    errors.reportDatesRange = errors.dateTo
    delete errors.dateTo
  }

  return errors
}
