import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { OBSERVATION_STATUSES } from 'services/legacy/observations/constants'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { Button, EmptyState, Icon, Typography, XMasIcon } from 'components'
import Widget from 'module/Dashboard/components/Widget'

import SeeMoreListItem from '../../components/SeeMoreListItem'

import { StyledApprovals, StyledEmptyState, StyledHeader } from './ObservationsWidgetStyled'

const ObservationsWidgetView = ({
  isFetching,
  observations,
  pendingCount,
}) => {
  const status = OBSERVATION_STATUSES.DRAFT.value

  const renderSubtitle = ({
    childObservationsCount,
    previewChildObservations,
  }) => {
    if (!childObservationsCount) {
      return null
    }

    if (1 < childObservationsCount) {
      return `Group (${childObservationsCount})`
    }

    return previewChildObservations.length ? previewChildObservations[0].child.displayName : null
  }

  const renderListRow = (observation, index) => {
    const {
      childObservationsCount,
      id,
      observationDate,
      previewChildObservations,
    } = observation
    const previewChildren = _.map(previewChildObservations, ({ child }) => child)

    return (
      <Widget.List.Row
        childrenCount={childObservationsCount}
        index={index}
        key={id}
        leftColumn={(
          <React.Fragment>
            <Typography
              color={NEUTRAL_COLOURS.WHITE}
              ellipsis
            >
              {moment(observationDate).format('L [at] LT')}
            </Typography>
            <Typography
              color={NEUTRAL_COLOURS.WHITE}
              ellipsis
            >
              {renderSubtitle(observation)}
            </Typography>
          </React.Fragment>
        )}
        previewChildren={previewChildren}
        to={generateRoute('LEARNING.OBSERVATIONS.REVIEW', {
          observationId: id,
        })}
      />
    )
  }

  const renderContent = () => {
    if (observations && !observations.length) {
      return (
        <StyledEmptyState>
          <EmptyState
            iconComponent={(
              <Icon
                color={NEUTRAL_COLOURS.WHITE}
                height={60}
                icon="observations"
              />
            )}
            text1="There are no draft observations"
          />
        </StyledEmptyState>
      )
    }

    return (
      <React.Fragment>
        <Typography
          margin="10px 0 15px 0"
        >
          Draft observations
        </Typography>
        <Widget.List>
          {_.map(observations, renderListRow)}
          <Widget.List.Row>
            <SeeMoreListItem to={`/learning/observations${status ? `?status=${status}` : ''}`} />
          </Widget.List.Row>
        </Widget.List>
      </React.Fragment>
    )
  }

  return (
    <Widget>
      <Widget.Header>
        Observations
        <XMasIcon height="26px" icon="snowflake-variant-2" marginLeft="254px" width="23px" />
      </Widget.Header>
      <Widget.Content
        isLoading={isFetching}
        primary
      >
        <StyledHeader>
          <StyledApprovals>
            <Typography variant="small">
              Pending approval
            </Typography>
            <br />
            <Typography variant="h3">
              {pendingCount || 0}
            </Typography>
          </StyledApprovals>
          <Button
            hierarchy="secondary"
            icon="plus"
            label="Observation"
            to={generateRoute('LEARNING.OBSERVATIONS.ADD')}
            fullWidth
            inverted
            negativeMargins
          />
          <XMasIcon height="38px" icon="circle-variant-3" marginLeft="200px" marginTop="50px" width="36px" />
        </StyledHeader>
        {renderContent()}
      </Widget.Content>
    </Widget>
  )
}

export default ObservationsWidgetView
