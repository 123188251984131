import React from 'react'

import invoiceConstants from 'services/legacy/invoices/constants'

import { Button, Currency, DateString, Icon, Typography } from 'components'

import {
  StyledContainer,
  StyledContentContainer,
  StyledEditIconContainer,
  StyledItemContainer,
  StyledReasonContentContainer,
  StyledReasonHeaderContainer,
} from './InvoiceBadDebtHighlightStyled'

const InvoiceBadDebtHighlight = ({
  invoicePreview,
  onEditClick,
}) => {
  const { invoice = {} } = invoicePreview || {}
  const { badDebtDate, badDebtReason, status, totalBadDebt } = invoice

  if (status !== invoiceConstants.STATUS_TYPES.BAD_DEBT) {
    return null
  }

  return (
    <StyledContainer>
      <StyledItemContainer>
        <Icon icon="bad-debt" />
      </StyledItemContainer>
      <StyledItemContainer>
        <StyledContentContainer>
          <Currency value={totalBadDebt} />
          &nbsp;
          written off on
          &nbsp;
          <DateString date={badDebtDate} />
          <StyledEditIconContainer>
            <Button
              hierarchy="tertiary"
              label="Edit"
              size="small"
              negativeMargins
              onClick={onEditClick}
            />
          </StyledEditIconContainer>
        </StyledContentContainer>
        {badDebtReason && (
          <StyledReasonContentContainer>
            <StyledReasonHeaderContainer>
              Reason
            </StyledReasonHeaderContainer>
            <Typography wordBreak="break-word">
              {badDebtReason}
            </Typography>
          </StyledReasonContentContainer>
        )}
      </StyledItemContainer>
    </StyledContainer>
  )
}

export default InvoiceBadDebtHighlight
