import i18n from 'translations'

import EnquiriesWrapperContainer from './EnquiriesWrapperContainer'
import List from './List'
import Add from './Add'
import Detail from './Detail'
import Viewings from './Viewings'
import Report from './Report'
import ConversionRate from './Report/ConversionRate'
import Performance from './Report/Performance'
import ViewingPerformance from './Report/ViewingPerformance'

export const ROUTE_NAMES = {
  'ENQUIRIES.ADD': 'ENQUIRIES.ADD',
  'ENQUIRIES.DETAIL': 'ENQUIRIES.DETAIL',
  'ENQUIRIES.EDIT': 'ENQUIRIES.EDIT',
  'ENQUIRIES.INDEX': 'ENQUIRIES.INDEX',
  'ENQUIRIES.LIST': 'ENQUIRIES.LIST',
  'ENQUIRIES.REPORT': 'ENQUIRIES.REPORT',
  'ENQUIRIES.REPORT.CONVERSION_RATE': 'ENQUIRIES.REPORT.CONVERSION_RATE',
  'ENQUIRIES.REPORT.PERFORMANCE': 'ENQUIRIES.REPORT.PERFORMANCE',
  'ENQUIRIES.REPORT.VIEWING_PERFORMANCE': 'ENQUIRIES.REPORT.VIEWING_PERFORMANCE',
  'ENQUIRIES.VIEWINGS': 'ENQUIRIES.VIEWINGS',
}

const EnquiriesRouting = {
  childRoutes: [
    {
      component: List,
      name: ROUTE_NAMES['ENQUIRIES.LIST'],
      path: 'list',
      title: i18n.t('module:Enquiries:List:routeTitle'),
    },
    {
      childRoutes: [
        {
          component: ConversionRate,
          name: ROUTE_NAMES['ENQUIRIES.REPORT.CONVERSION_RATE'],
          path: 'conversion-rate',
          title: i18n.t('module:Enquiries:Report:ConversionRate:title'),
        },
        {
          component: Performance,
          name: ROUTE_NAMES['ENQUIRIES.REPORT.PERFORMANCE'],
          path: 'performance',
          title: i18n.t('module:Enquiries:Report:Performance:title'),
        },
        {
          component: ViewingPerformance,
          name: ROUTE_NAMES['ENQUIRIES.REPORT.VIEWING_PERFORMANCE'],
          path: 'viewing-performance',
          title: i18n.t('module:Enquiries:Report:ViewingPerformance:title'),
        },
      ],
      indexRoute: {
        component: Report,
      },
      name: ROUTE_NAMES['ENQUIRIES.REPORT'],
      path: 'reports',
      title: i18n.t('module:Enquiries:Report:title'),
    },
    {
      component: Add,
      name: ROUTE_NAMES['ENQUIRIES.ADD'],
      path: 'add',
      title: i18n.t('module:Enquiries:List:Add:addTitle'),
    },
    {
      component: Add,
      name: ROUTE_NAMES['ENQUIRIES.EDIT'],
      path: 'edit/:enquiryId',
      title: i18n.t('module:Enquiries:List:Add:editTitle'),
    },
    {
      component: Detail,
      name: ROUTE_NAMES['ENQUIRIES.DETAIL'],
      path: 'detail/:enquiryId',
      title: i18n.t('module:Enquiries:List:Detail:title'),
    },
    {
      component: Viewings,
      name: ROUTE_NAMES['ENQUIRIES.VIEWINGS'],
      path: 'viewings',
      title: i18n.t('module:Enquiries:Viewings:title'),
    },
  ],
  component: EnquiriesWrapperContainer,
  name: ROUTE_NAMES['ENQUIRIES.INDEX'],
  path: 'enquiries',
  title: i18n.t('module:Enquiries:title'),
}

EnquiriesRouting.ROUTE_NAMES = ROUTE_NAMES

export default EnquiriesRouting
