import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { ROLES } from 'constants/security'

import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withNurseriesStatisticsService } from 'services/nurseries'
import { withSubdomainService } from 'services/subdomain'

import Occupancy from 'module/Occupancy'

import ManagementConsumablesWrapper from
  '../ManagementFinanceSettings/ManagementConsumables/ManagementConsumablesWrapper'
import ManagementFundingWrapper from '../ManagementFinanceSettings/ManagementFunding/ManagementFundingWrapper'

import ManagementGettingStartedView from './ManagementGettingStartedView'

const NURSERY_STATISTICS_GROUP = {
  read: [
    'nurseryStatistics.finance',
    'nurseryStatistics.operation',
    'nurseryStatistics.basic',
  ],
}

class ManagementGettingStartedContainer extends Component {
  componentDidMount() {
    const { nurseriesStatisticsActions } = this.props

    nurseriesStatisticsActions.getStatistics({ groups: NURSERY_STATISTICS_GROUP })
  }

  componentWillUnmount() {
    const { nurseriesStatisticsActions } = this.props

    nurseriesStatisticsActions.clearNurseriesStatistics()
  }

  render() {
    const {
      ManagementConsumablesGranted,
      ManagementFundingGranted,
      OccupancyGranted,
      dashboardIcon,
      dashboardTitle,
      errorMessages,
      isFetching,
      statistics,
      statisticsSummary,
    } = this.props

    return (
      <ManagementGettingStartedView
        ManagementConsumablesGranted={ManagementConsumablesGranted}
        ManagementFundingGranted={ManagementFundingGranted}
        OccupancyGranted={OccupancyGranted}
        dashboardIcon={dashboardIcon}
        dashboardTitle={dashboardTitle}
        errorMessages={errorMessages}
        isLoading={isFetching}
        statistics={statistics}
        statisticsSummary={statisticsSummary}
      />
    )
  }
}

ManagementGettingStartedContainer.authParams = {
  roles: [
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.SUPER_ADMIN,
  ],
}

const mapState = (state, {
  appSelectors,
  nurseriesStatisticsSelectors,
  nurseriesStatisticsState,
  subdomainSelectors,
}) => ({
  ManagementConsumablesGranted: auth.SELECTORS.getComponentIsAuthorised(state, ManagementConsumablesWrapper),
  ManagementFundingGranted: auth.SELECTORS.getComponentIsAuthorised(state, ManagementFundingWrapper),
  OccupancyGranted: auth.SELECTORS.getComponentIsAuthorised(state, Occupancy),
  dashboardIcon: subdomainSelectors.getDashboardIcon(state),
  dashboardTitle: subdomainSelectors.getDashboardTitle(state),
  errorMessages: appSelectors.getErrorMessages(nurseriesStatisticsState),
  isFetching: appSelectors.getIsFetching(nurseriesStatisticsState),
  statistics: nurseriesStatisticsSelectors.getStatistics(state),
  statisticsSummary: nurseriesStatisticsSelectors.getStatisticsSummary(state),
})

const enhance = compose(
  withAppService,
  withNurseriesStatisticsService,
  withSubdomainService,
  connect(mapState),
)

export default enhance(ManagementGettingStartedContainer)

