import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change, getFormValues, isDirty, stopSubmit } from 'redux-form'

import { FEATURE_FLAGS } from 'constants/security'

import { getSubdomainFromName } from 'utils/form'
import { getBackendErrors } from 'utils/backendErrors'
import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withOrganizationService } from 'services/organization'
import { withSnackbarService } from 'services/utils/snackbar'
import { withUploadService } from 'services/legacy/upload'
import { withRouter } from 'services/router'

import i18n from 'translations'

import OrganisationsAddView from './OrganisationsAddView'
import { ORGANISATIONS_ADD_FORM } from './components/OrganisationsAddForm'

const GROUPS = {
  read: [
    'organization.details',
    'organization.organizationSettings',
    'organizationSettings',
    'organizationSettings.localeDetails',
  ],
}

class OrganisationsAddContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      imageUploading: false,
    }
  }

  componentDidMount() {
    const { organizationActions, params: { organisationId } } = this.props

    if (organisationId) {
      return organizationActions.get(organisationId, {
        onSuccess: () => { organizationActions.listFlags(organisationId) },
        params: { groups: GROUPS },
      })
    }

    return null
  }

  componentWillUnmount() {
    const { organizationActions } = this.props

    organizationActions.clearSingle()
  }

  uploadFile = async (body) => {
    const { uploadActions } = this.props

    this.setState({ imageUploading: true })

    const url = await uploadActions.uploadFile(body.logo.file)

    this.setState({ imageUploading: false })

    return url
  }

  handleSubmitSuccess = (data) => {
    const { navigate } = this.props

    navigate(generateRoute('ORGANISATIONS.DETAILS', { organisationId: data.id }))
  }

  handleSubmitFailed = (response) => {
    const { injectValidation } = this.props

    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    return injectValidation(ORGANISATIONS_ADD_FORM, errors)
  }

  handleSubmit = async (fields) => {
    const {
      changeField,
      organizationActions,
      organizationSelectors,
      organizationSettings,
      params: { organisationId },
    } = this.props

    const payload = organizationSelectors.getFormData(fields)

    if (payload.organizationSettings && organizationSettings?.id) {
      payload.organizationSettings.id = organizationSettings.id
    }

    if (false === payload?.logo?.isUploaded) {
      payload.logo = await this.uploadFile(payload)

      changeField('logo', {
        isUploaded: true,
        value: payload.logo,
      })
    } else {
      payload.logo = payload?.logo?.value
    }

    if (organisationId) {
      return organizationActions.update(organisationId, {
        onFailed: this.handleSubmitFailed,
        onSuccess: this.handleSubmitSuccess,
        payload,
      })
    }

    return organizationActions.create({
      onFailed: this.handleSubmitFailed,
      onSuccess: this.handleSubmitSuccess,
      payload,
    })
  }

  handleMigrationFailed = (enabled) => {
    const { changeField, modalActions, modalConsts } = this.props

    changeField(`featureFlags.${FEATURE_FLAGS.FINANCE_V3}`, !enabled)

    modalActions.show(modalConsts.TYPES.ALERT, {
      text: i18n.t('module:Organisations:Add:FinanceV3FF:errorMessage'),
    })
  }

  handleUpdateFinanceV3FF = ({ enabled, label }) => {
    const {
      modalActions,
      organizationActions,
      params: { organisationId },
    } = this.props

    organizationActions.updateFlag(organisationId, FEATURE_FLAGS.FINANCE_V3, {
      onSuccess: () => {
        modalActions.hide()
        this.showSnackBarMessage({ enabled, label })
      },
      payload: { enabled },
    })
  }

  migrateFinanceV3 = ({ enabled, label }) => {
    const {
      modalActions,
      modalConsts,
      organizationActions,
      params: { organisationId },
    } = this.props

    modalActions.show(modalConsts.TYPES.ALERT, {
      hideButton: true,
      text: i18n.t(enabled
        ? 'module:Organisations:Add:FinanceV3FF:activateAlertMessage'
        : 'module:Organisations:Add:FinanceV3FF:deactivateAlertMessage',
      ),
    })

    organizationActions.updateFinanceMigration({
      body: { newFinanceEnabled: enabled },
      onFailed: () => this.handleMigrationFailed(enabled),
      onSuccess: () => this.handleUpdateFinanceV3FF({ enabled, label }),
      params: [organisationId, {}],
    })
  }

  handleMigrateFinanceV3 = ({ enabled, label }) => {
    const { changeField, modalActions, modalConsts } = this.props

    if (enabled) {
      this.migrateFinanceV3({ enabled, label })

      return
    }

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('module:Organisations:Add:FinanceV3FF:deactivateConfirmButton'),
      onCancel: () => changeField(`featureFlags.${FEATURE_FLAGS.FINANCE_V3}`, true),
      onConfirm: () => this.migrateFinanceV3({ enabled, label }),
      text: i18n.t('module:Organisations:Add:FinanceV3FF:deactivateMessage'),
    })
  }

  showSnackBarMessage = ({ enabled, label }) => {
    const { snackbarActions } = this.props

    snackbarActions.show({
      message: i18n.t('module:Organisations:Edit:OnFeatureFlagChange:successMessage', {
        action: enabled ? i18n.t('global:enabled') : i18n.t('global:disabled'),
        label,
      }),
    })
  }

  handleFeatureFlagChange = (label, value) => {
    const {
      formValues,
      organizationActions,
      params: { organisationId },
    } = this.props

    const enabled = !formValues.featureFlags[value]

    if (value === FEATURE_FLAGS.FINANCE_V3) {
      this.handleMigrateFinanceV3({ enabled, label })

      return
    }

    organizationActions.updateFlag(organisationId, value, {
      onSuccess: () => this.showSnackBarMessage({ enabled, label }),
      payload: { enabled },
    })
  }

  handleDisableOrganisation = () => {
    const {
      isOrganisationArchived,
      navigate,
      organizationActions,
      params: { organisationId },
    } = this.props

    organizationActions.update(organisationId, {
      onSuccess: () => navigate(generateRoute('ORGANISATIONS.INDEX')),
      payload: { archived: !isOrganisationArchived },
    })
  }

  handleDisableClick = () => {
    const { isOrganisationArchived, modalActions, modalConsts } = this.props

    const text = isOrganisationArchived
      ? i18n.t('module:Organisations:Edit:Enable:confirm')
      : i18n.t('module:Organisations:Edit:Disable:confirm')

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('global:Yes'),
      onConfirm: this.handleDisableOrganisation,
      text,
    })
  }

  handleNameChange = (e) => {
    const { changeField, formValues, isSubdomainDirty } = this.props

    const { name = '', subdomain } = formValues || {}

    if (!isSubdomainDirty || getSubdomainFromName(name) === subdomain) {
      changeField('subdomain', getSubdomainFromName(e.target.value))
    }
  }

  render() {
    const {
      errorMessages,
      formValues,
      initialValues,
      isFetching,
      isOrganisationArchived,
      isSubmitting,
      organizationSettings,
      params: { organisationId },
    } = this.props
    const { imageUploading } = this.state

    return (
      <OrganisationsAddView
        errorMessages={errorMessages}
        financeLocked={organizationSettings?.financeLocked}
        formValues={formValues}
        initialValues={initialValues}
        isCreating={!organisationId}
        isFetching={isFetching}
        isOrganisationArchived={isOrganisationArchived}
        isSubmitting={isSubmitting || imageUploading}
        onDisableClick={this.handleDisableClick}
        onFeatureFlagChange={this.handleFeatureFlagChange}
        onNameChange={this.handleNameChange}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  changeField: (field, value) => change(ORGANISATIONS_ADD_FORM, field, value),
  injectValidation: (formName, errors) => stopSubmit(formName, errors),
}

const mapState = (state, {
  appSelectors,
  organizationSelectors,
  organizationSingleState,
}) => ({
  errorMessages: appSelectors.getErrorMessages(organizationSingleState),
  formValues: getFormValues(ORGANISATIONS_ADD_FORM)(state),
  initialValues: organizationSelectors.getInitialValues(state),
  isFetching: appSelectors.getIsFetching(organizationSingleState),
  isOrganisationArchived: organizationSelectors.getOrganizationIsArchived(state),
  isSubdomainDirty: isDirty(ORGANISATIONS_ADD_FORM)(state, ['subdomain']),
  isSubmitting: appSelectors.getIsSubmitting(organizationSingleState),
  organizationSettings: organizationSelectors.getOrganizationSettings(state),
})

const enhance = compose(
  withAppService,
  withModalService,
  withOrganizationService,
  withRouter,
  withSnackbarService,
  withUploadService,
  connect(mapState, mapDispatch),
)

export default enhance(OrganisationsAddContainer)
