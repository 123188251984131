import _ from 'lodash'

import React, { Component } from 'react'
import CarouselLibrary, { Modal, ModalGateway } from 'react-images'
import { spring } from 'react-motion'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getFileFullName } from 'services/legacy/upload/common/selectors'

import { Button, Space, Typography } from 'components'

import i18n from 'translations'

import PreviewFile from '../PreviewFile'
import {
  StyledCarouselContainer,
  StyledCarouselFooter,
  StyledCarouselHeader,
  StyledFooterLeft,
  StyledFooterRight,
  StyledNavigation,
} from './CarouselStyled'

class Carousel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      updatedTaggedChildren: null,
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      disableTagging,
      lightBoxCurrentImage,
      lightBoxIsOpen,
      preview,
      tiledMode,
    } = this.props
    const { updatedTaggedChildren } = this.state

    if (
      nextProps.lightBoxCurrentImage !== lightBoxCurrentImage
      || nextProps.lightBoxIsOpen !== lightBoxIsOpen
    ) {
      this.setState({ updatedTaggedChildren: null })
    }

    return nextProps.disableTagging !== disableTagging
      || nextProps.lightBoxCurrentImage !== lightBoxCurrentImage
      || nextProps.lightBoxIsOpen !== lightBoxIsOpen
      || nextProps.tiledMode !== tiledMode
      || nextProps.preview !== preview
      || nextState.updatedTaggedChildren !== updatedTaggedChildren
  }

  handleEditSuccess = (updatedFile) => {
    const { children } = updatedFile

    this.setState({ updatedTaggedChildren: children })
  }

  render() {
    const {
      carouselFiles,
      childrenList,
      disableTagging,
      lightBoxCurrentImage,
      lightBoxIsOpen,
      onEditFile,
      onLightBoxClickNext,
      onLightBoxClickPrev,
      onLightBoxClose,
      preview,
      tiledMode,
    } = this.props
    const { updatedTaggedChildren } = this.state

    const CarouselNavigation = ({ innerProps }) => (
      <div {...innerProps} />
    )

    const CarouselHeader = ({ innerProps }) => (
      <StyledCarouselHeader
        {...innerProps}
      >
        <Button
          hierarchy="tertiary"
          icon="close"
          inverted
          negativeMargins
          onClick={onLightBoxClose}
        />
      </StyledCarouselHeader>
    )

    const CarouselFooter = ({ carouselProps, currentIndex, views }) => {
      const file = carouselFiles[currentIndex]
      const { updatedTaggedChildren: updatedChildren } = carouselProps
      const taggedChildren = updatedChildren || views[currentIndex]?.taggedChildren

      return (
        <StyledCarouselFooter>
          <StyledFooterLeft
            center={disableTagging}
          >
            {!tiledMode && (
              <Typography
                color={NEUTRAL_COLOURS.WHITE}
                fontSize={18}
                wordBreak="break-all"
              >
                {getFileFullName(file)}
              </Typography>
            )}
            {!tiledMode && !disableTagging && (
              <React.Fragment>
                <Space margin="0 0 25px" />
                <Typography
                  color={NEUTRAL_COLOURS.WHITE}
                  fontSize={18}
                  bold
                >
                  {_.map(taggedChildren, ({ firstName, id: childId, surname }, i) => {
                    let finalFirstName = firstName
                    let finalSurname = surname

                    if (!firstName && !surname) {
                      const child = _.find(childrenList, (item) => (
                        item.id === childId
                      ))

                      finalFirstName = child?.firstName
                      finalSurname = child?.surname
                    }

                    return (
                      `${finalFirstName} ${finalSurname}${i !== taggedChildren.length - 1 ? ', ' : ' '}`
                    )
                  })}
                </Typography>
              </React.Fragment>
            )}
          </StyledFooterLeft>
          <StyledFooterRight>
            {1 < views?.length && (
              <StyledNavigation>
                <Typography
                  color={NEUTRAL_COLOURS.WHITE}
                  fontSize={18}
                >
                  {currentIndex + 1}
                  &nbsp;
                  {i18n.t('global:of')}
                  &nbsp;
                  {views?.length || 0}
                </Typography>
                <Space margin="0 15px 0 0" />
                <Button
                  disabled={!carouselFiles[currentIndex - 1]}
                  hierarchy="secondary"
                  icon="chevron-left"
                  size="small"
                  inverted
                  negativeVerticalMargins
                  onClick={onLightBoxClickPrev}
                />
                <Button
                  disabled={!carouselFiles[currentIndex + 1]}
                  hierarchy="secondary"
                  icon="chevron-right"
                  size="small"
                  inverted
                  negativeVerticalMargins
                  onClick={onLightBoxClickNext}
                />
              </StyledNavigation>
            )}
            {!tiledMode && !disableTagging && !preview && (
              <React.Fragment>
                {1 < views?.length && (
                  <Space margin="0 0 20px" />
                )}
                <Typography
                  color={NEUTRAL_COLOURS.WHITE}
                  fontSize={18}
                  transform="uppercase"
                  // eslint-disable-next-line react/no-this-in-sfc
                  onClick={() => onEditFile({ ...file, taggedChildren }, true, this.handleEditSuccess)}
                >
                  {i18n.t('components:MediaPicker:tagChildren')}
                </Typography>
              </React.Fragment>
            )}
          </StyledFooterRight>
        </StyledCarouselFooter>
      )
    }

    const CarouselContainer = ({ children }) => (
      <StyledCarouselContainer>
        {children}
      </StyledCarouselContainer>
    )

    return (
      <ModalGateway>
        {lightBoxIsOpen && (
          <Modal
            onClose={onLightBoxClose}
          >
            <CarouselLibrary
              components={{
                Container: CarouselContainer,
                Footer: CarouselFooter,
                Header: CarouselHeader,
                NavigationNext: CarouselNavigation,
                NavigationPrev: CarouselNavigation,
                View: PreviewFile,
              }}
              currentIndex={lightBoxCurrentImage}
              frameProps={{
                autoSize: 'height',
                springConfig: spring(0, { damping: 17, stiffness: 180 }),
              }}
              trackProps={{
                animations: [],
                instant: true,
                swipe: false,
              }}
              updatedTaggedChildren={updatedTaggedChildren}
              views={_.map(carouselFiles, (
                { file, id, mimeType, name, playerMimeType, taggedChildren, thumbnail },
              ) => ({
                file,
                id,
                mimeType,
                name,
                playerMimeType,
                src: thumbnail,
                taggedChildren,
              }))}
            />
          </Modal>
        )}
      </ModalGateway>
    )
  }
}

export default Carousel
