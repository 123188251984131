import _ from 'lodash'
import { createSelector } from 'reselect'
import moment, { Moment } from 'moment'

import { RootState } from 'core/reducers'
import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { LIST_STATUS_FILTERS } from 'constants/filters'
import { Option } from 'constants/models'

export const getChildFundingsListState = (state: RootState) => state.childFundings.list

export const getChildFundingsListDataState = createSelector(
  [getChildFundingsListState],
  (state) => state.data,
)

interface GetCriteriaProps {
  ageRange: {
    from: number
    to: number
  }
  allocation?: string | number
  child?: string | number
  dateRange?: {
    after?: Moment
    before?: Moment
  }
  fundingTypes?: string | number | (string | number)[]
  nursery?: Option
  room?: string | number
  status?: string | number
}

export const getCriteria = ({
  ageRange,
  allocation,
  child,
  dateRange,
  fundingTypes,
  nursery,
  room,
  status,
}: GetCriteriaProps) => {
  const criteria = []

  if (status && status !== LIST_STATUS_FILTERS.ALL) {
    criteria.push({
      field: 'isArchived',
      value: status === LIST_STATUS_FILTERS.ARCHIVED ? 1 : 0,
    })
  }

  if (ageRange) {
    const { from, to } = ageRange

    if (from) {
      criteria.push({
        field: 'birthDate[before]',
        value: moment()
          .add(-from, 'months')
          .format(DEFAULT_DATE_FORMAT),
      })
    }

    if (to) {
      criteria.push({
        field: 'birthDate[after]',
        value: moment()
          .add(-to, 'months')
          .format(DEFAULT_DATE_FORMAT),
      })
    }
  }

  if (nursery) {
    criteria.push({
      field: 'nursery',
      value: nursery.value,
    })
  }

  if (room) {
    criteria.push({
      field: 'nurseryClass',
      value: room,
    })
  }

  if (child) {
    criteria.push({
      field: 'id',
      value: child,
    })
  }

  if (fundingTypes) {
    if (_.isArray(fundingTypes)) {
      _.each(fundingTypes, (id) => {
        if (id) {
          criteria.push({
            field: 'childProducts.product[]',
            value: id,
          })
        }
      })
    } else {
      criteria.push({
        field: 'childProducts.product[]',
        value: fundingTypes,
      })
    }
  }

  if (dateRange) {
    if (dateRange.after) {
      criteria.push({
        field: 'startDate',
        value: moment(dateRange.after).format(DEFAULT_DATE_FORMAT),
      })
    }

    if (dateRange.before) {
      criteria.push({
        field: 'endDate',
        value: moment(dateRange.before).format(DEFAULT_DATE_FORMAT),
      })
    }
  }

  if (allocation) {
    criteria.push({
      field: 'allocation',
      value: allocation,
    })
  }

  return criteria
}
