import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import {
  CLEAR_LIST_ENQUIRY_ACTIVITIES,
  LIST_ENQUIRY_ACTIVITIES,
  LIST_ENQUIRY_ACTIVITIES_FAILED,
  LIST_ENQUIRY_ACTIVITIES_SUCCESS,
} from './constants'

export default (...props) => {
  const [state = getBasicListInitialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_LIST_ENQUIRY_ACTIVITIES,
    failed: LIST_ENQUIRY_ACTIVITIES_FAILED,
    init: LIST_ENQUIRY_ACTIVITIES,
    success: LIST_ENQUIRY_ACTIVITIES_SUCCESS,
  }, getBasicListInitialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
