import { BasicListInitialState } from 'utils/reducer'

import {
  CLEAR_INVOICES_STATISTICS,
  INVOICES_STATISTICS,
  INVOICES_STATISTICS_FAILED,
  INVOICES_STATISTICS_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
}

export interface InvoicesStatisticsState extends BasicListInitialState {}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case INVOICES_STATISTICS:
      return { ...state, error: initialState.error, isFetching: true }
    case INVOICES_STATISTICS_SUCCESS:
      return { ...state, data: payload.data, isFetching: false }
    case INVOICES_STATISTICS_FAILED:
      return { ...state, error, isFetching: false }
    case CLEAR_INVOICES_STATISTICS:
      return initialState
    default:
      return state
  }
}
