import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withNurseryFundingService } from 'services/nurseryFunding'
import { withRouter } from 'services/router'

import ManagementFundingItemView from './ManagementFundingItemView'

const NURSERY_FUNDING_GROUPS = {
  read: [
    'nurseryFunding.settings',
    'nurseryFundingSettings',
    'nurseryFunding.nursery',
    'nurseryFunding.fundingType',
    'fundingType',
  ],
}

class ManagementFundingItemContainer extends Component {
  componentDidMount() {
    const { nurseryFundingActions, params } = this.props
    const { nurseryFundingId } = params

    nurseryFundingActions.get({
      params: [nurseryFundingId, { groups: NURSERY_FUNDING_GROUPS }],
    })
  }

  componentWillUnmount() {
    const { nurseryFundingActions } = this.props

    nurseryFundingActions.clear()
  }

  render() {
    const { errorMessages, isFetching, nurseryFundingPreviewData } = this.props

    return (
      <ManagementFundingItemView
        errorMessages={errorMessages}
        isLoading={isFetching}
        previewData={nurseryFundingPreviewData}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseryFundingSelectors,
  nurseryFundingSingleState,
}) => ({
  errorMessages: appSelectors.getErrorMessages(nurseryFundingSingleState),
  isFetching: appSelectors.getIsFetching(nurseryFundingSingleState),
  nurseryFundingPreviewData: nurseryFundingSelectors.getNurseryFundingSinglePreviewDataSelectors(
    state,
  ),
})

const enhance = compose(
  withRouter,
  withAppService,
  withNurseryFundingService,
  connect(mapState),
)

export default enhance(ManagementFundingItemContainer)
