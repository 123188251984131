import React, { PropsWithChildren, useEffect, useState } from 'react'
import { Link } from 'react-router'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { noop } from 'constants/global'

import { getBrandingColor } from 'utils/branding'
import { typeByObject } from 'utils/typescript'

import { Button, Icon } from 'components'

import { TYPES } from './constants'
import { getProps } from './DropdownMenuHelpers'
import { StyledIconContainer, StyledItem, StyledLabel } from './DropdownMenuStyled'

interface DropdownMenuItemBasicProps {
  color?: string
  disabled?: boolean
  inCircle?: boolean
  onClick?: () => any
  primary?: boolean
  to?: string
}

interface DropdownMenuItemTypeProps extends DropdownMenuItemBasicProps {
  icon?: never
  label?: never
  type?: typeByObject<typeof TYPES>
}

interface DropdownMenuItemIconProps extends DropdownMenuItemBasicProps {
  icon?: IconType
  label?: string
  type?: never
}

export type DropdownMenuItemProps = DropdownMenuItemTypeProps | DropdownMenuItemIconProps

export const DropdownMenuItem: React.FC<PropsWithChildren<DropdownMenuItemProps>> = ({
  color,
  disabled,
  icon,
  inCircle,
  label,
  onClick,
  primary,
  to,
  type,
}) => {
  const [onClickIsDisabled, setOnClickIsDisabled] = useState<boolean>(false)
  const props = getProps(type)
  const { icon: iconByType, label: labelByType } = props || {}

  useEffect(() => {
    setTimeout(() => {
      setOnClickIsDisabled(false)
    }, 2000)
  }, [onClickIsDisabled])

  const handleOnClick = () => {
    if (disabled || onClickIsDisabled) {
      return noop
    }

    setOnClickIsDisabled(true)

    return onClick?.()
  }

  const renderContent = () => (
    <StyledItem $disabled={disabled} onClick={handleOnClick}>
      {(icon || iconByType) && !inCircle && (
        <StyledIconContainer>
          <Icon
            color={primary ? getBrandingColor('primary-color') : (color || NEUTRAL_COLOURS.BASIC)}
            height={20}
            icon={iconByType || icon}
          />
        </StyledIconContainer>
      )}
      {(icon || iconByType) && inCircle && (
        <StyledIconContainer>
          <Button
            color="senary"
            icon={iconByType || icon}
            negativeMargins
          />
        </StyledIconContainer>
      )}
      <StyledLabel $color={color} $primary={primary}>
        {labelByType || label}
      </StyledLabel>
    </StyledItem>
  )

  return to
    ? (
      <Link to={!disabled && to} onlyActiveOnIndex>
        {renderContent()}
      </Link>
    )
    : renderContent()
}

export default DropdownMenuItem
