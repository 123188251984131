import {
  CHILDREN_STARTING_REPORT,
  CHILDREN_STARTING_REPORT_FAILED,
  CHILDREN_STARTING_REPORT_SUCCESS,
  CLEAR_CHILDREN_STARTING_REPORT,
} from 'services/childrenStartingReport/children/constants'

import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

export default (...props) => {
  const [state = getBasicListInitialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_CHILDREN_STARTING_REPORT,
    failed: CHILDREN_STARTING_REPORT_FAILED,
    init: CHILDREN_STARTING_REPORT,
    success: CHILDREN_STARTING_REPORT_SUCCESS,
  }, getBasicListInitialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
