import { generateBasicActions } from 'utils/service'

import {
  CLEAR_FINANCE_REPORTS_CHILDREN_BALANCE,
  FINANCE_REPORTS_CHILDREN_BALANCE,
  FINANCE_REPORTS_CHILDREN_BALANCE_FAILED,
  FINANCE_REPORTS_CHILDREN_BALANCE_SUCCESS,
} from './constants'

export const getChildren = (options = {}) => ({ financeBalanceReportApiClient }) => generateBasicActions.list({
  constants: {
    failed: FINANCE_REPORTS_CHILDREN_BALANCE_FAILED,
    init: FINANCE_REPORTS_CHILDREN_BALANCE,
    success: FINANCE_REPORTS_CHILDREN_BALANCE_SUCCESS,
  },
  options,
  service: financeBalanceReportApiClient,
  serviceMethod: 'getChildren',
})

export const clearChildren = () => ({
  type: CLEAR_FINANCE_REPORTS_CHILDREN_BALANCE,
})
