import { generateBasicActions } from 'utils/service'

import { CLEAR_FRAMEWORKS, LIST_FRAMEWORKS, LIST_FRAMEWORKS_FAILED, LIST_FRAMEWORKS_SUCCESS } from './constants'

export const list = (options = {}) => ({ frameworksApiClient }) => generateBasicActions.list({
  constants: {
    failed: LIST_FRAMEWORKS_FAILED,
    init: LIST_FRAMEWORKS,
    success: LIST_FRAMEWORKS_SUCCESS,
  },
  options,
  service: frameworksApiClient,
  serviceMethod: 'list',
})

export const clear = () => ({
  type: CLEAR_FRAMEWORKS,
})
