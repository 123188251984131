export const copyToClipboard = (link) => {
  const textElement = document.createElement('textarea')

  textElement.innerText = link
  document.body.appendChild(textElement)
  textElement.select()
  document.execCommand('copy')
  textElement.remove()
}

const isInViewport = (elem) => {
  const bounding = elem.getBoundingClientRect()

  return (
    0 <= bounding.top
    && 0 <= bounding.left
    && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    && bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export const scrollToElement = (element) => {
  if (element && !isInViewport(element)) {
    const headerOffset = 100
    const elementPosition = element.getBoundingClientRect().top
    const offsetPosition = elementPosition - headerOffset

    window.scrollTo({
      behavior: 'smooth',
      top: offsetPosition,
    })
  }
}

export const scrollToErrorFields = () => {
  setTimeout(() => {
    const errorFields = (
      document.querySelector('[data-field-error]')
      || document.querySelector('[data-callout-error]')
    )

    errorFields?.scrollIntoView({
      block: 'center',
      inline: 'center',
    })
  })
}
