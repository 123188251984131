import moment from 'moment'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

export const getCriteria = (filters) => {
  const { dateRange, type } = filters

  const criteria = []

  if (dateRange?.[0]) {
    criteria.push({
      comparator: 'gte',
      field: 'entryDate',
      value: moment(dateRange[0]).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (dateRange?.[1]) {
    criteria.push({
      comparator: 'lte',
      field: 'entryDate',
      value: moment(dateRange[1]).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (type) {
    criteria.push({
      field: 'present',
      value: type ? type.value : null,
    })
  }

  return criteria
}
