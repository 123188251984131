import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledChildProfileContainer = styled.div`
  margin: 0 0 25px;
  
  ${({ topLine }) => topLine && `
    border-top: 1px dashed ${NEUTRAL_COLOURS.SILVER};
    padding-top: 25px;
  `}
`
