import React from 'react'

import { Callout, ModalBox } from 'components'

import i18n from 'translations'

import ExportModalForm from './components/DepositsExportModalForm'

const DepositsExportModalView = ({
  errorMessages,
  onCancelClick,
  ...rest
}) => (
  <ModalBox
    maxWidth={500}
    title={i18n.t('module:Modals:DepositsExport:title')}
    autoHeight
    onCloseClick={onCancelClick}
  >
    <Callout content={errorMessages} error />
    <ExportModalForm
      onCancelClick={onCancelClick}
      {...rest}
    />
  </ModalBox>
)

export default DepositsExportModalView
