import React from 'react'

import { Callout, InfiniteScroll, Page, Spinner, Table, Typography } from 'components'

import i18n from 'translations'

const COLUMNS = [
  {
    align: 'left',
    field: 'name',
    title: i18n.t('global:Name'),
  },
  {
    field: 'switch',
    width: '10%',
  },
  {
    field: 'options',
    width: '10%',
  },
]

const CurriculumsView = ({
  data,
  errorMessages,
  isFetching,
  onPageChange,
  page,
  pageCount,
}) => {
  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    return (
      <React.Fragment>
        <Typography margin="20px 0">
          {i18n.t('module:Management:Learning:Curriculums:subtitle')}
        </Typography>
        <InfiniteScroll
          dataLength={data?.length || 0}
          hasMore={page < pageCount}
          next={() => onPageChange((+page) + 1)}
        >
          <Table
            columns={COLUMNS}
            data={data}
            visualType="flat"
          />
        </InfiniteScroll>
      </React.Fragment>
    )
  }

  return (
    <Page.Section
      isLoading={isFetching}
      title={i18n.t('module:Management:Learning:Curriculums:title')}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default CurriculumsView
