import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { RootState } from 'core/reducers'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as usersSelectors from './selectors'
import { UsersListState } from './list/reducer'
import { UsersSettingsState } from './settings/reducer'
import { UsersSingleState } from './single/reducer'

export interface withUsersServiceProps {
  usersActions: typeof actions
  usersListState: UsersListState
  usersSelectors: typeof usersSelectors
  usersSettingsState: UsersSettingsState
  usersSingleState: UsersSingleState
}

const withUsersService = <P extends withUsersServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ users }: RootState) => ({ users })

  const mapDispatch = injectActions('usersActions', actions)

  const Component = ({ users, usersActions, ...others }) => (
    <WrappedComponent
      usersActions={usersActions}
      usersListState={users.list}
      usersSelectors={usersSelectors}
      usersSettingsState={users.settings}
      usersSingleState={users.single}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withUsersService
