import { REGISTER_ACTIVITIES, REGISTER_COLORS } from '../../constants'

export const ACTIVITIES_DESCRIPTION = {
  [REGISTER_ACTIVITIES.ADDED_SIGN_IN]: {
    color: REGISTER_COLORS.timeIn,
    label: 'added',
    name: 'time in',
  },
  [REGISTER_ACTIVITIES.EDITED_SIGN_IN]: {
    color: REGISTER_COLORS.timeIn,
    label: 'edited',
    name: 'time in',
  },
  [REGISTER_ACTIVITIES.ADDED_SIGN_OUT]: {
    color: REGISTER_COLORS.timeOut,
    label: 'added',
    name: 'time out',
  },
  [REGISTER_ACTIVITIES.EDITED_SIGN_OUT]: {
    color: REGISTER_COLORS.timeOut,
    label: 'edited a',
    name: 'time out',
  },
  [REGISTER_ACTIVITIES.ADDED_COMMENTS]: {
    color: REGISTER_COLORS.comment,
    label: 'added a',
    name: 'comment',
  },
  [REGISTER_ACTIVITIES.EDITED_COMMENTS]: {
    color: REGISTER_COLORS.comment,
    label: 'edited',
    name: 'comment',
  },
  [REGISTER_ACTIVITIES.ABSENCE]: {
    color: REGISTER_COLORS.absence,
    label: 'added',
    name: 'an absence',
  },
  [REGISTER_ACTIVITIES.HOLIDAY]: {
    color: REGISTER_COLORS.absence,
    label: 'added',
    name: 'a holiday',
  },
  [REGISTER_ACTIVITIES.REMOVED_SIGN_IN]: {
    label: 'reset the entries for the day.',
  },
}
