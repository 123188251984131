import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change, getFormValues, stopSubmit } from 'redux-form'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { SHIFT_MAIN_TYPES } from 'services/legacy/membershipRegisters/constants'

import { withAppService } from 'services/app'
import { withMembershipsShiftsService } from 'services/legacy/membershipsShifts'
import { withModalService } from 'services/utils/modal'

import ShiftCopyModalView from './ShiftCopyModalView'
import { NUMBER_OF_WEEKS, STAFF_ROTA_SHIFT_COPY_MODAL_FORM } from './components/ShiftCopyModalForm/ShiftCopyModalForm'

const GROUPS = {
  read: [
    'membershipShift.membershipShiftSettings',
    'membershipShift.parentShift',
    'membershipShift.membershipShiftTimes',
    'membershipShiftSettings',
    'membershipShiftTime',
  ],
}

class ShiftCopyModalContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedAll: false,
    }
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSubmit = async (values) => {
    const { membershipsShiftsActions, membershipsShiftsSelectors, payload } = this.props
    const { weeks } = values
    const { dateFrom, membership: { id: membership }, membershipShiftTimes } = payload
    const finalPayload = []

    await Promise.all(_.map(weeks, async (value, i) => {
      if (value) {
        const finalDateFrom = (
          moment(dateFrom).add(i + 1, 'week').startOf('week').format(DEFAULT_DATE_FORMAT)
        )
        const finalDateTo = (
          moment(dateFrom).add(i + 1, 'week').endOf('week').format(DEFAULT_DATE_FORMAT)
        )

        const criteria = membershipsShiftsSelectors.getListCriteria({
          dateFrom: finalDateFrom,
          dateTo: finalDateTo,
          membership,
          type: SHIFT_MAIN_TYPES.WORK,
        })

        await membershipsShiftsActions.list({
          onSuccess: (response) => {
            const { data } = response
            const shiftItem = data?.[0]?.shifts?.[0]
            const { id } = shiftItem || {}

            finalPayload.push({
              ...payload,
              dateFrom: finalDateFrom,
              dateTo: finalDateTo,
              id,
              membershipShiftTimes: _.map(membershipShiftTimes, (shiftTime) => {
                const newShiftTime = { ...shiftTime }

                delete newShiftTime.id

                return {
                  ...newShiftTime,
                  day: moment(newShiftTime.day, DEFAULT_DATE_FORMAT).add(i + 1, 'week').format(DEFAULT_DATE_FORMAT),
                }
              }),
            })
          },
          onlyData: true,
          params: {
            criteria,
            groups: GROUPS,
          },
        })
      }
    }))

    membershipsShiftsActions.createBatch({
      onSuccess: this.handleCloseClick,
      params: {
        groups: GROUPS,
      },
      payload: finalPayload,
    })
  }

  handleSelectAll = () => {
    const { changeFieldValue } = this.props
    const { selectedAll } = this.state

    changeFieldValue('weeks', _.times(NUMBER_OF_WEEKS, () => !selectedAll))

    this.setState({ selectedAll: !selectedAll })
  }

  render() {
    const { formValues, isSubmitting, payload } = this.props
    const { dateFrom, membership: { id: membership } } = payload
    const { selectedAll } = this.state
    const initialValues = {
      weeks: _.times(NUMBER_OF_WEEKS, () => false),
    }

    return (
      <ShiftCopyModalView
        formValues={formValues}
        initialValues={initialValues}
        isSubmitting={isSubmitting}
        membership={membership}
        selectedAll={selectedAll}
        startedWeek={moment(dateFrom).startOf('week')}
        onCloseClick={this.handleCloseClick}
        onSelectAll={this.handleSelectAll}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  changeFieldValue: (field, value) => change(STAFF_ROTA_SHIFT_COPY_MODAL_FORM, field, value),
  injectValidation: (data) => stopSubmit(STAFF_ROTA_SHIFT_COPY_MODAL_FORM, data),
}

const mapState = (state, { appSelectors, membershipsShiftsListState }) => ({
  formValues: getFormValues(STAFF_ROTA_SHIFT_COPY_MODAL_FORM)(state),
  isSubmitting: appSelectors.getIsSubmitting(
    membershipsShiftsListState,
  ),
})

const enhance = compose(
  withAppService,
  withMembershipsShiftsService,
  withModalService,
  connect(mapState, mapDispatch),
)

export default enhance(ShiftCopyModalContainer)
