import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'

const getModalState = (state: RootState) => state?.modal?.common

export const getModalStack = createSelector([getModalState], (state) => state.modalStack)

export const getBackgroundFilter = createSelector(
  [getModalStack],
  (modalStack) => {
    if (!modalStack?.length) {
      return false
    }

    const { modalOptions = {} } = modalStack[modalStack.length - 1]
    const { backgroundFilter } = modalOptions

    return backgroundFilter
  },
)
