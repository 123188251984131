import _ from 'lodash'

import React from 'react'

import { generateRoute } from 'utils/routing'

import {
  Callout,
  Currency,
  EmptyState,
  Page,
  Pagination,
  SearchBar,
  Section,
  Spinner,
  TabFilter,
  Table,
  Toolbar,
} from 'components'

import i18n from 'translations'

import { STATISTIC_OPTIONS } from './constants'

const ListView = ({
  childId,
  columns,
  errorMessages,
  isChildContext,
  isListLoading,
  isStatisticsLoading,
  onExportClick,
  onPageChange,
  onSearchChange,
  onSortChange,
  onStatisticsFilterClick,
  page,
  pageCount,
  perPage,
  selectedFilter,
  sortField,
  sortOrder,
  statistics,
  tableData,
  totalResults,
}) => {
  const renderPagination = () => (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('module:Finance:Deposits:List:deposits')}
      titleSingular={i18n.t('module:Finance:Deposits:List:deposit')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderDepositStatistics = () => (
    <Toolbar>
      <TabFilter>
        {_.map(STATISTIC_OPTIONS, ({ key, label }) => (
          <TabFilter.Item
            isClicked={selectedFilter === key}
            key={key}
            title={label}
            value={statistics[key] ? <Currency value={statistics[key]} /> : '-'}
            onClick={() => onStatisticsFilterClick(key)}
          />
        ))}
      </TabFilter>
    </Toolbar>
  )

  const renderTable = () => {
    if (isListLoading) {
      return <Spinner />
    }

    if (!tableData?.length) {
      return (
        <EmptyState
          icon="deposit"
          text2={i18n.t('module:Finance:Deposits:List:emptyStateText')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={columns}
          data={tableData}
          sortField={sortField}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const renderContent = () => {
    if ((isStatisticsLoading && isListLoading) || !statistics) {
      return <Spinner />
    }

    return (
      <React.Fragment>
        <Callout content={errorMessages} error />
        <Section
          actions={(
            <Section.Actions
              options={[{ onClick: onExportClick, type: 'export' }]}
              primary={[{
                label: i18n.t('module:Finance:Deposits:List:addDeposit'),
                to: isChildContext
                  ? generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.DEPOSITS.ADD', { childId })
                  : generateRoute('FINANCE.DEPOSITS.ADD'),
              }]}
            />
          )}
          title={i18n.t('module:Finance:Deposits:title')}
        >
          {renderDepositStatistics()}
          {renderTable()}
        </Section>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {!isChildContext && (
        <SearchBar placeholder={i18n.t('module:Finance:Deposits:List:searchPlaceholder')} onChange={onSearchChange} />
      )}
      <Page isLoading={(isStatisticsLoading && isListLoading) || !statistics}>
        <Page.Content>
          {renderContent()}
        </Page.Content>
      </Page>
    </React.Fragment>
  )
}

export default ListView
