import {
  CREATE_CHILD_BANK_DETAILS,
  CREATE_CHILD_BANK_DETAILS_FAILED,
  CREATE_CHILD_BANK_DETAILS_SUCCESS,
  UPDATE_CHILD_BANK_DETAILS,
  UPDATE_CHILD_BANK_DETAILS_FAILED,
  UPDATE_CHILD_BANK_DETAILS_SUCCESS,
} from './actions'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case CREATE_CHILD_BANK_DETAILS:
      return { ...state, error: initialState.error, isSubmitting: true }
    case CREATE_CHILD_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          childBankDetail: payload.data,
        },
        isFetching: false,
        isSubmitting: false,
      }
    case CREATE_CHILD_BANK_DETAILS_FAILED:
      return { ...state, error, isSubmitting: false }
    case UPDATE_CHILD_BANK_DETAILS:
      return { ...state, error: initialState.error, isSubmitting: true }
    case UPDATE_CHILD_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          childBankDetail: payload.data,
        },
        isFetching: false,
        isSubmitting: false,
      }
    case UPDATE_CHILD_BANK_DETAILS_FAILED:
      return { ...state, error, isSubmitting: false }
    default:
      return state
  }
}
