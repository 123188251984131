import _ from 'lodash'
import moment from 'moment'

import React, { useMemo } from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { RolesDetails } from 'constants/security'
import { SHIFT_MAIN_TYPES, WORK_SHIFTS, WORK_SHIFT_TYPES } from 'services/legacy/membershipRegisters/constants'
import { VIEW_MODE, VIEW_MODE_OPTIONS } from 'services/legacy/membershipsShifts/constants'
import { ALL_ROOMS } from 'services/legacy/rooms/constants'

import { isSameDay, millisecondsToHoursAndMinutesString } from 'utils/date'

import { calculateEdgeTimesBasedOnStatistics } from 'services/legacy/membershipsShifts/single/selectors'

import {
  Avatar,
  DatePicker,
  InfiniteDropdowns,
  Page,
  Section,
  Select,
  Space,
  Spinner,
  Table,
  Toolbar,
  Tooltip,
  Typography,
} from 'components'
import UserTableRowSummary from 'module/Staff/components/UserTableRowSummary'

import i18n from 'translations'

import DayMode from './components/DayMode'
import WeekMode from './components/WeekMode'
import { getRecordStatistics } from './StaffRotaHelpers'
import { StyledLegend, StyledTooltipDetails, StyledTooltipDetailsRow, StyledWeek } from './StaffRotaStyled'

const DEFAULT_EARLIEST_HOUR = 7
const DEFAULT_LATEST_HOUR = 19

export const COLUMNS_WIDTHS = {
  LEFT: 180,
  RIGHT: 83,
}

export const TIMELINE_MIDNIGHT_START = 24
const TIMELINE_ONE_OCLOCK = 25
const MIDNIGHT_IN_MILLISECONDS = 82800000

export const HOUR_WIDTH_CONSTANT = 72.7

const StaffRotaView = ({
  authAccessMap,
  date,
  dateRange,
  day,
  displayPastWeekBanner,
  firstRoomIsFetching,
  isDayDataLoading,
  isFetching,
  onAddLeave,
  onAddShift,
  onChangeDate,
  onChangeDay,
  onChangePage,
  onChangeRoom,
  onChangeViewMode,
  onEditShift,
  onExportClick,
  page,
  pageCount,
  records,
  room,
  statistics,
  viewMode,
}) => {
  const renderHeader = () => (
    <Toolbar noMargin>
      <Toolbar.Group>
        <Toolbar.Item>
          <InfiniteDropdowns.Rooms
            clearable={false}
            extraOptions={[ALL_ROOMS]}
            value={room}
            allRoomTypes
            onChange={onChangeRoom}
          />
        </Toolbar.Item>
        <Toolbar.Item>
          <Select
            clearable={false}
            options={VIEW_MODE_OPTIONS}
            placeholder={i18n.t('global:View')}
            searchable={false}
            value={viewMode}
            onChange={onChangeViewMode}
          />
        </Toolbar.Item>
      </Toolbar.Group>
      <Toolbar.Group>
        <Toolbar.Item>
          <DatePicker
            type="week"
            value={dateRange}
            range
            onChange={onChangeDate}
          />
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )

  const getEarliestTime = useMemo(() => {
    const edgeTimesFromStatistics = calculateEdgeTimesBasedOnStatistics(statistics, day)
    const shifts = _.flatten(_.map(
      _.flatten(_.map(records, 'shifts')),
      'membershipShiftTimes',
    ))

    const filteredShiftsByDay = _.filter(shifts, ({ day: itemDay }) => isSameDay(itemDay, day))
    const minValue = _.get(_.minBy(filteredShiftsByDay, 'startTime'), 'startTime')
    const hour = +moment(minValue).utc().format('H')
    const result = hour < DEFAULT_EARLIEST_HOUR ? hour : DEFAULT_EARLIEST_HOUR

    return _.min([result, edgeTimesFromStatistics.from])
  }, [day, records, statistics])

  const getLatestTime = useMemo(() => {
    const edgeTimesFromStatistics = calculateEdgeTimesBasedOnStatistics(statistics, day)
    const shifts = _.flatten(_.map(
      _.flatten(_.map(records, 'shifts')),
      'membershipShiftTimes',
    ))
    const filteredShiftsByDay = _.filter(shifts, ({ day: itemDay }) => isSameDay(itemDay, day))
    const maxValue = _.get(_.maxBy(filteredShiftsByDay, 'endTime'), 'endTime')
    const hour = +moment(maxValue).utc().format('H')
    const minutes = +moment(maxValue).format('m')

    if (0 === hour && maxValue >= MIDNIGHT_IN_MILLISECONDS) {
      if (0 === minutes) {
        return TIMELINE_MIDNIGHT_START
      }

      return TIMELINE_ONE_OCLOCK
    }

    if (hour < DEFAULT_LATEST_HOUR) {
      return DEFAULT_LATEST_HOUR
    }

    const result = (0 === minutes ? hour : hour + 1)

    return _.max([result, edgeTimesFromStatistics.to])
  }, [day, records, statistics])

  const RecordStatistic = ({
    hasDetailRecords,
    label,
    stats,
    tooltip,
    type,
  }) => {
    if (!stats && !hasDetailRecords) {
      return null
    }

    return (
      <Tooltip
        placement="right"
        title={tooltip}
        type="white"
        inline
      >
        <UserTableRowSummary
          label={label}
          time={stats}
          type={type}
        />
      </Tooltip>
    )
  }

  const renderRecord = (renderRecordContentWrapper) => (record) => {
    const { id, name, photo, roles, shifts } = record
    const rolesLabels = _.map(roles, (role) => RolesDetails[role]?.label)

    const {
      leaveStatistics,
      recordStatistics,
      workStatistics,
    } = getRecordStatistics(shifts, viewMode, day, name)

    return (
      <StyledWeek disabled={!authAccessMap.section.isStaffRotaWritePermissionGrated} key={id}>
        <Table.Td
          align="left"
          background={NEUTRAL_COLOURS.GRAY_QUATERNARY}
          verticalAlign="top"
          width={`${COLUMNS_WIDTHS.LEFT}px`}
        >
          <Avatar
            avatarSize="medium"
            gap={15}
            initials={name}
            src={photo}
            subTitle={(
              <React.Fragment>
                <Space margin="2px" />
                <Typography
                  color={NEUTRAL_COLOURS.GRAY}
                  fontSize={14}
                >
                  {rolesLabels}
                </Typography>
                <Space margin="2px" />
              </React.Fragment>
            )}
            title={(
              <Typography>
                {name}
              </Typography>
            )}
          />
          {_.sum(_.values(recordStatistics)) ? (
            <Space space="15px" />
          ) : null}
          <RecordStatistic
            hasDetailRecords={!!(workStatistics[WORK_SHIFT_TYPES.CONTRACTED]
              || workStatistics[WORK_SHIFT_TYPES.OVERTIME])}
            label={i18n.t('services:MembershipRegisters:mainTypes:work')}
            stats={recordStatistics.work}
            tooltip={(
              <StyledTooltipDetails>
                {_.map(workStatistics, (value, type) => (
                  <StyledTooltipDetailsRow dayView={viewMode === VIEW_MODE.DAY} key={type}>
                    {viewMode === VIEW_MODE.DAY && (
                      <StyledLegend type={type} />
                    )}
                    <Typography>
                      {`${WORK_SHIFTS[type].label}: `}
                    </Typography>
                    <Typography>
                      {millisecondsToHoursAndMinutesString(value) || `0${i18n.t('global:hourShortcut')}`}
                    </Typography>
                  </StyledTooltipDetailsRow>
                ))}
              </StyledTooltipDetails>
            )}
            type={SHIFT_MAIN_TYPES.WORK}
          />
          <RecordStatistic
            label={i18n.t('services:MembershipRegisters:mainTypes:leave')}
            stats={recordStatistics.leave}
            tooltip={(
              <StyledTooltipDetails>
                {_.map(leaveStatistics, (details, leaveType) => (
                  <StyledTooltipDetailsRow key={leaveType}>
                    <Typography>
                      {`${details.name}: `}
                    </Typography>
                    <Typography>
                      {millisecondsToHoursAndMinutesString(details.value) || `0${i18n.t('global:hourShortcut')}`}
                    </Typography>
                  </StyledTooltipDetailsRow>
                ))}
              </StyledTooltipDetails>
            )}
            type={SHIFT_MAIN_TYPES.LEAVE}
          />
        </Table.Td>
        {renderRecordContentWrapper(record)}
      </StyledWeek>
    )
  }

  const renderContent = () => {
    if (firstRoomIsFetching || (isFetching && 1 === page)) {
      return (
        <React.Fragment>
          <Space space="40px" />
          <Spinner />
        </React.Fragment>
      )
    }

    if (VIEW_MODE.DAY === viewMode) {
      return (
        <DayMode
          authAccessMap={authAccessMap}
          date={date}
          day={day}
          displayPastWeekBanner={displayPastWeekBanner}
          earliestTime={getEarliestTime}
          isDayDataLoading={isDayDataLoading}
          isFetching={isFetching}
          latestTime={getLatestTime}
          page={page}
          pageCount={pageCount}
          recordContent={renderRecord}
          records={records}
          room={room}
          statistics={statistics}
          onChangeDay={onChangeDay}
          onChangePage={onChangePage}
          onEditShift={onEditShift}
        />
      )
    }

    return (
      <WeekMode
        authAccessMap={authAccessMap}
        date={date}
        day={day}
        displayPastWeekBanner={displayPastWeekBanner}
        earliestTime={getEarliestTime}
        isFetching={isFetching}
        latestTime={getLatestTime}
        page={page}
        pageCount={pageCount}
        recordContent={renderRecord}
        records={records}
        room={room}
        statistics={statistics}
        onChangeDay={onChangeDay}
        onChangePage={onChangePage}
        onEditShift={onEditShift}
      />
    )
  }

  const actions = (
    <Section.Actions
      options={[{ onClick: onExportClick, type: 'export' }]}
      primary={[{
        label: i18n.t('module:Staff:StaffRota:addLeave'),
        onClick: onAddLeave,
      }, {
        label: i18n.t('module:Staff:StaffRota:addShift'),
        onClick: onAddShift,
      }]}
    />
  )

  return (
    <Page.Section
      actions={authAccessMap.section.isStaffRotaWritePermissionGrated && actions}
      isLoading={firstRoomIsFetching || (isFetching && 1 === page)}
      title={i18n.t('module:Staff:StaffRota:title')}
    >
      {renderHeader()}
      {renderContent()}
    </Page.Section>
  )
}

export default StaffRotaView
