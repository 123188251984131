import styled from 'styled-components'
import { Property } from 'csstype'

import { typeByObject } from 'utils/typescript'

export const SIZE_VARIANTS = {
  BIG: 'big',
}

interface StyledItemContainerProps {
  $align?: Property.AlignItems
  $horizontal?: boolean
  $margin?: string
  $size?: typeByObject<typeof SIZE_VARIANTS>
}

export const StyledItemContainer = styled.div<StyledItemContainerProps>`
  display: ${({ $horizontal }) => ($horizontal ? 'flex' : 'block')};
  padding: 5px 10px;
  max-width: 100%;
  
  ${({ $size }) => $size === SIZE_VARIANTS.BIG && `
    * {
      font-size: 22px !important;
      font-weight: 600;
    }
  `}
  
  ${({ $align, $horizontal }) => $align && $horizontal && `
    align-items: ${$align};
  `}
  
  ${({ $margin }) => $margin && `
    margin: ${$margin};
  `}
`

export const StyledItemLabelContainer = styled.div`
  font-size: 16px;
  white-space: nowrap;
  font-weight: 600;
  padding-right: 10px;
`

interface StyledContainerProps {
  $fullWidth?: boolean
  $labelWidth?: string
  $margin?: string
  $minLabelWidth?: string
  $vertical?: boolean
}

export const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  flex-wrap: wrap;
  margin: -5px -10px;
  flex-direction: ${({ $vertical }) => ($vertical ? 'column' : 'row')};
  
  ${({ $margin }) => $margin && `
    & > div {
      margin: ${$margin};
    }
  `}
  
  ${({ $fullWidth }) => $fullWidth && `
    width: 100%;
  `}

  ${({ $labelWidth }) => $labelWidth && `
    ${StyledItemLabelContainer} {
      width: ${$labelWidth};
      white-space: normal;
    }
  `}

  ${({ $minLabelWidth }) => $minLabelWidth && `
    ${StyledItemLabelContainer} {
      min-width: ${$minLabelWidth};      
    }
  `}

  ${({ $overflow }) => $overflow && `
    overflow: ${$overflow};
  `}
`

interface StyledItemValueContainerProps {
  $horizontal?: boolean
  $textOverflow?: Property.TextOverflow
  $whiteSpace?: Property.WhiteSpace
  $wordBreak?: Property.WordBreak
}

export const StyledItemValueContainer = styled.div<StyledItemValueContainerProps>`
  font-size: 16px;
  white-space: nowrap;
  margin-top: ${({ $horizontal }) => (!$horizontal ? '5px' : '0')};
  overflow: hidden;
  
  ${({ $whiteSpace }) => $whiteSpace && `
    white-space: ${$whiteSpace};
  `}

  ${({ $wordBreak }) => $wordBreak && `
    word-break: ${$wordBreak};
  `}

  ${({ $textOverflow }) => $textOverflow && `
    text-overflow: ${$textOverflow};
  `}
`
