import React from 'react'
import { Field, FieldArray, reduxForm } from 'redux-form'

import { isFullMonth, isRequired, isRequiredDateRange, isValidInteger, isValidMinutes } from 'utils/fieldValidation'

import { FUNDING_CALCULATION_TYPES } from 'services/nurseryFunding/constants'
import modalConstants from 'services/utils/modal/constants'

import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

import { CircleIcon, Field as FieldComponent, Form, Section, Tooltip } from 'components'

import i18n from 'translations'

import FundingFeesField from './FundingFeesField'
import FundingExcludePeriodField from './FundingExcludePeriodField'
import FundingLineItemLabel from './FundingLineItemLabel'

export const FORM_NAME = 'AddFundingForm'

const AddFundingForm = ({
  discountOptions,
  fundingCalculationOptions,
  fundingDeductionOptions,
  fundingFeesOptions,
  fundingLineItemOptions,
  fundingTypeOptions,
  handleSubmit,
  isActualSelected,
  isArchived,
  isCheapestSessionSelected,
  isDeductedFromInvoice,
  isDeficitChanged,
  isEdit,
  isInsideModal,
  isLoading,
  isOtherFundingTypeSelected,
  isSubstractFundingSelected,
  onArchiveClick,
  onCancelClick,
  onPreviewClick,
  onSubmit,
}) => (
  <SubdomainCurrencyProvider>
    {({ getFormattedCurrencyValue }) => (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Section
          actions={isEdit && (
            <Section.Actions
              options={[{
                onClick: onArchiveClick(!isArchived),
                type: isArchived ? 'unarchive' : 'archive',
              }]}
            />
          )}
          title={isInsideModal ? null : `${isEdit ? 'Edit' : 'Create'} funding type`}
        >
          {!isInsideModal && (
            <Form.Row>
              <Form.Row.Item>
                Add your funding type below.
              </Form.Row.Item>
            </Form.Row>
          )}
          <Form.Row label="Funding type">
            <Form.Row.FlexItem>
              <Field
                component={Form.Select}
                disabled={isEdit}
                name="fundingType"
                options={fundingTypeOptions}
                placeholder="Select funding type"
                validate={isRequired}
              />
            </Form.Row.FlexItem>
            {isOtherFundingTypeSelected && (
              <Form.Row.Item>
                <Field
                  component={Form.TextField}
                  name="customName"
                  placeholder="Extra funding type"
                  validate={isRequired}
                />
              </Form.Row.Item>
            )}
          </Form.Row>
          <Form.Row label="Unique label">
            <Form.Row.Item>
              <Field
                component={Form.TextField}
                name="label"
                placeholder="e.g. Autumn 2018"
                validate={isRequired}
              />
            </Form.Row.Item>
            <Form.Row.Item margin="0 0 0 5px">
              <Tooltip
                title={
                  'Enter the Term, Year and any other specific information for '
                    .concat("this Funding Type (e.g. if it is 'stretched')")
                }
              >
                <CircleIcon
                  cursor="pointer"
                  icon="info"
                  iconSize={16}
                  size={24}
                />
              </Tooltip>
            </Form.Row.Item>
          </Form.Row>
          <Form.Divider />
          <Form.Row>
            <Form.Row.FlexItem>
              <FieldComponent.InlineEdit
                editableComponent={(
                  <Form.Row>
                    <Form.Row.FlexItem>
                      <Field
                        component={Form.RadioGroup}
                        disabled={isEdit}
                        name="settings.deductedFromInvoice"
                        options={discountOptions}
                        validate={isRequired}
                      />
                    </Form.Row.FlexItem>
                  </Form.Row>
                )}
                label="Does this funding type deduct from invoices?"
                isEditMode
              />
              {isDeductedFromInvoice && (
                <FieldComponent.InlineEdit
                  editableComponent={(
                    <Form.Row>
                      <Form.Row.FlexItem>
                        <Field
                          component={FundingFeesField}
                          disabled={isEdit}
                          isDeficitChanged={isDeficitChanged}
                          name="settings.feesAllocation"
                          options={fundingFeesOptions}
                          validate={isRequired}
                        />
                      </Form.Row.FlexItem>
                    </Form.Row>
                  )}
                  label="Funding allocation for fees"
                  isEditMode
                />
              )}
              {isDeductedFromInvoice && (
                <FieldComponent.InlineEdit
                  editableComponent={(
                    <Form.Row>
                      <Form.Row.FlexItem>
                        <Field
                          component={Form.RadioGroup}
                          name="settings.fundingCalculation"
                          options={fundingCalculationOptions}
                          validate={isRequired}
                        />
                      </Form.Row.FlexItem>
                    </Form.Row>
                  )}
                  label="Funding calculation"
                  isEditMode
                />
              )}
            </Form.Row.FlexItem>
          </Form.Row>
          <Form.Divider />
          <Form.Row label="Hourly rate">
            <Form.Row.FlexItem flex="0 0 150px">
              <Field
                component={Form.TextField}
                name="hourlyRate"
                placeholder={getFormattedCurrencyValue(0.00)}
                step="any"
                type="number"
              />
            </Form.Row.FlexItem>
          </Form.Row>
          <Form.Row label="Period">
            <Form.Row.Item>
              <Field
                component={Form.DatePicker}
                name="period"
                validate={isRequiredDateRange}
                clearable
                range
              />
            </Form.Row.Item>
          </Form.Row>
          {
            isDeductedFromInvoice
            && !isSubstractFundingSelected
            && isActualSelected
            && (
              <Form.Row
                label={(
                  <div>
                    Exclude period
                    {' '}
                    <br />
                    e.g. half terms and holidays
                  </div>
                )}
              >
                <Form.Row.Item>
                  <FieldArray
                    component={FundingExcludePeriodField}
                    name="settings.excludedPeriods"
                  />
                </Form.Row.Item>
              </Form.Row>
            )
          }
          {
            isDeductedFromInvoice
            && !isSubstractFundingSelected
            && !isCheapestSessionSelected
            && <Form.Divider />
          }
          {
            isDeductedFromInvoice
            && !isSubstractFundingSelected
            && !isCheapestSessionSelected
            && (
              <Form.Row label="Hours per week">
                <Form.Row.FlexItem flex="0 0 80px">
                  <Field
                    component={Form.TextField}
                    name="settings.hoursPerWeek.hours"
                    step={1}
                    type="number"
                    validate={[isRequired, isValidInteger]}
                  />
                </Form.Row.FlexItem>
                <Form.Row.TextItem text="hours" />
                <Form.Row.FlexItem flex="0 0 80px">
                  <Field
                    component={Form.TextField}
                    name="settings.hoursPerWeek.minutes"
                    step={1}
                    type="number"
                    validate={isValidMinutes}
                  />
                </Form.Row.FlexItem>
                <Form.Row.TextItem text="minutes" />
                <Form.Row.Item>
                  <Field
                    component={Form.Switch}
                    name="settings.hoursPerWeek.allowOverride"
                  />
                </Form.Row.Item>
                <Form.Row.TextItem text="Allow override" />
              </Form.Row>
            )
          }
          {isDeductedFromInvoice
            && !isSubstractFundingSelected
            && !isCheapestSessionSelected
            && (
              <Form.Row label="Max hours per day">
                <Form.Row.FlexItem flex="0 0 80px">
                  <Field
                    component={Form.TextField}
                    name="settings.maxHoursPerDay.hours"
                    step={1}
                    type="number"
                    validate={isValidInteger}
                  />
                </Form.Row.FlexItem>
                <Form.Row.TextItem text="hours" />
                <Form.Row.FlexItem flex="0 0 80px">
                  <Field
                    component={Form.TextField}
                    name="settings.maxHoursPerDay.minutes"
                    step={1}
                    type="number"
                    validate={isValidMinutes}
                  />
                </Form.Row.FlexItem>
                <Form.Row.TextItem text="minutes" />
                <Form.Row.Item>
                  <Field
                    component={Form.Switch}
                    name="settings.maxHoursPerDay.allowOverride"
                  />
                </Form.Row.Item>
                <Form.Row.TextItem text="Allow override" />
              </Form.Row>
            )}
          {isDeductedFromInvoice && !isSubstractFundingSelected && <Form.Divider />}
          <Form.Row label="Total hours">
            <Form.Row.FlexItem flex="0 0 80px">
              <Field
                component={Form.TextField}
                name="totalHours.hours"
                placeholder="0"
                type="number"
                validate={[isRequired, isValidInteger]}
              />
            </Form.Row.FlexItem>
            <Form.Row.TextItem text="hours" />
            <Form.Row.FlexItem flex="0 0 80px">
              <Field
                component={Form.TextField}
                name="totalHours.minutes"
                type="number"
                validate={isValidMinutes}
              />
            </Form.Row.FlexItem>
            <Form.Row.TextItem text="minutes" />
          </Form.Row>
          <Form.Row label="Total funding value">
            <Form.Row.FlexItem flex="0 0 150px">
              <Field
                component={Form.TextField}
                name="total"
                placeholder={getFormattedCurrencyValue(0.00)}
                step="any"
                type="number"
                validate={isRequired}
              />
            </Form.Row.FlexItem>
          </Form.Row>
        </Section>
        <Section title="Invoice display options">
          {!isSubstractFundingSelected && (
            <React.Fragment>
              <Form.Row>
                <FieldComponent.InlineEdit
                  editableComponent={(
                    <Field
                      component={Form.RadioGroup}
                      name="settings.invoiceLineItemDeduction"
                      options={fundingDeductionOptions}
                      validate={isRequired}
                    />
                  )}
                  label={(
                    <FundingLineItemLabel
                      label="Funding deduction on invoices"
                      onPreviewClick={() => onPreviewClick(modalConstants.TYPES.PREVIEW_INVOICE_DEDUCTION)}
                    />
                  )}
                  isEditMode
                />
              </Form.Row>
              <Form.Divider />
            </React.Fragment>
          )}
          <Form.Row>
            <FieldComponent.InlineEdit
              editableComponent={(
                <Field
                  component={Form.RadioGroup}
                  name="settings.invoiceLineItemDisplay"
                  options={fundingLineItemOptions}
                  validate={isRequired}
                />
              )}
              label={(
                <FundingLineItemLabel
                  label="Funding line item on invoices"
                  onPreviewClick={() => onPreviewClick(modalConstants.TYPES.PREVIEW_LINE_ITEM)}
                />
              )}
              isEditMode
            />
          </Form.Row>
        </Section>
        <Form.FooterActions
          isSubmitting={isLoading}
          submitLabel={isEdit ? i18n.t('global:Update') : i18n.t('global:Save')}
          onCancelClick={onCancelClick}
        />
      </Form>
    )}
  </SubdomainCurrencyProvider>
)

const validate = (values) => {
  const errors = {
    settings: {},
  }

  if (
    values.period
    && values.period.length
    && values.period[0]
    && values.period[1]
    && values.settings
    && values.settings.fundingCalculation
    && FUNDING_CALCULATION_TYPES.AVERAGE_COST === values.settings.fundingCalculation
    && !isFullMonth(values.period[0], values.period[1])
  ) {
    errors.settings.fundingCalculation = (
      'The funding calculation can only be averaged if full '
        .concat('months are selected as the period.')
    )
  }

  return errors
}

export default reduxForm({ form: FORM_NAME, validate })(AddFundingForm)
