import { BasicListInitialState } from 'utils/reducer'

import {
  CLEAR_NURSERY_ROTA_STATISTICS,
  GET_NURSERY_ROTA_STATISTICS,
  GET_NURSERY_ROTA_STATISTICS_FAILED,
  GET_NURSERY_ROTA_STATISTICS_SUCCESS,
} from './constants'

export interface NurseriesRotaStatisticsState extends BasicListInitialState {}

const initialState: NurseriesRotaStatisticsState = {
  data: null,
  error: null,
  isFetching: false,
  meta: {},
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case GET_NURSERY_ROTA_STATISTICS:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_NURSERY_ROTA_STATISTICS_SUCCESS:
      return { ...state, data: payload, isFetching: false }
    case GET_NURSERY_ROTA_STATISTICS_FAILED:
      return { ...state, error, isFetching: false }
    case CLEAR_NURSERY_ROTA_STATISTICS:
      return initialState
    default:
      return state
  }
}
