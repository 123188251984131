import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getFormValues, reset, stopSubmit } from 'redux-form'

import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withEnquiryActivitiesService, withEnquiryViewingsService } from 'services/legacy/enquiries'
import { withRouter } from 'services/router'

import { isActivityViewingType } from 'services/legacy/enquiries/activities/selectors'

import i18n from 'translations'

import ActivityDetailAddView from './ActivityDetailAddView'

class ActivityDetailAddContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isSubmitting: false,
    }
  }

  handleSubmitFailed = (response) => {
    const { formName, injectValidation } = this.props
    const errors = getBackendErrors(response)

    this.setState({ isSubmitting: false })

    if (!errors) {
      return false
    }

    return injectValidation(formName, errors)
  }

  handleSubmitSuccess = () => {
    const { formName, onSuccess, resetForm } = this.props

    resetForm(formName)

    this.setState({ isSubmitting: false })
    onSuccess()
  }

  handleSubmit = (formValues) => {
    const {
      enquiryActivitiesActions,
      enquiryActivitiesSelectors,
      enquiryId,
      enquiryViewingsActions,
      enquiryViewingsSelectors,
      id,
      type,
    } = this.props

    this.setState({ isSubmitting: true })

    const isViewingType = isActivityViewingType(type)

    const selectors = isViewingType
      ? enquiryViewingsSelectors
      : enquiryActivitiesSelectors

    const actions = isViewingType
      ? enquiryViewingsActions
      : enquiryActivitiesActions

    const body = selectors.getPayload({ ...formValues, enquiryId, type })

    if (id) {
      actions.update({
        body,
        onFailed: this.handleSubmitFailed,
        onSuccess: this.handleSubmitSuccess,
        params: [id, {}],
      })

      return
    }

    actions.create({
      body,
      onFailed: this.handleSubmitFailed,
      onSuccess: this.handleSubmitSuccess,
      params: [{}],
    })
  }

  handleDeleteActivity = () => {
    const {
      enquiryActivitiesActions,
      enquiryViewingsActions,
      id,
      type,
    } = this.props

    this.setState({ isSubmitting: true })

    const isViewingType = isActivityViewingType(type)

    const actions = isViewingType
      ? enquiryViewingsActions
      : enquiryActivitiesActions

    actions.update({
      body: { archived: true },
      onFailed: this.handleSubmitFailed,
      onSuccess: this.handleSubmitSuccess,
      params: [id, {}],
    })
  }

  handleDeleteClick = () => {
    const { modalActions, modalConsts, type } = this.props

    const isViewingType = isActivityViewingType(type)

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('global:Delete'),
      icon: 'trash',
      onConfirm: this.handleDeleteActivity,
      text: isViewingType
        ? i18n.t('module:Enquiries:List:Detail:Activity:deleteViewingMessage')
        : i18n.t('module:Enquiries:List:Detail:Activity:deleteActivityMessage')
      ,
    })
  }

  render() {
    const {
      errorMessage,
      formName,
      formValues,
      initialValues,
      isInlineEditMode,
      notePlaceholder,
      onCancelClick,
      showDelete,
      submitButtonLabel,
      type,
    } = this.props
    const { isSubmitting } = this.state

    return (
      <ActivityDetailAddView
        errorMessage={errorMessage}
        formName={formName}
        formValues={formValues(formName)}
        initialValues={initialValues}
        isInlineEditMode={isInlineEditMode}
        isSubmitting={isSubmitting}
        notePlaceholder={notePlaceholder}
        showDelete={showDelete}
        submitButtonLabel={submitButtonLabel}
        type={type}
        onCancelClick={onCancelClick}
        onDeleteClick={this.handleDeleteClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  injectValidation: (formName, errors) => stopSubmit(formName, errors),
  resetForm: (formName) => reset(formName),
}

const mapState = (state, {
  appSelectors,
  enquiryActivitiesSingleState,
}) => ({
  errorMessages: appSelectors.getErrorMessages(enquiryActivitiesSingleState),
  formValues: (formName) => getFormValues(formName)(state),
})

const enhance = compose(
  withRouter,
  withModalService,
  withAppService,
  withEnquiryActivitiesService,
  withEnquiryViewingsService,
  connect(mapState, mapDispatch),
)

export default enhance(ActivityDetailAddContainer)
