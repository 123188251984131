import {
  CLEAR_SINGLE_CHILD_FUNDING,
  CREATE_CHILD_FUNDING,
  CREATE_CHILD_FUNDING_FAILED,
  CREATE_CHILD_FUNDING_SUCCESS,
  DELETE_CHILD_FUNDING,
  DELETE_CHILD_FUNDING_FAILED,
  DELETE_CHILD_FUNDING_SUCCESS,
  GET_CHILD_FUNDING,
  GET_CHILD_FUNDING_FAILED,
  GET_CHILD_FUNDING_SUCCESS,
  UPDATE_CHILD_FUNDING,
  UPDATE_CHILD_FUNDING_FAILED,
  UPDATE_CHILD_FUNDING_SUCCESS,
} from './constants'

export const getChildFunding = (id, params, onSuccess) => ({ childFundingApiClient }) => (dispatch) => {
  dispatch({
    params,
    type: GET_CHILD_FUNDING,
  })

  childFundingApiClient.get(id, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: GET_CHILD_FUNDING_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: GET_CHILD_FUNDING_FAILED,
      })
    },
  )
}

export const createChildFunding = (
  payload,
  params,
  onSuccess,
  onFailed,
) => ({ childFundingApiClient }) => (dispatch) => {
  dispatch({
    params,
    payload,
    type: CREATE_CHILD_FUNDING,
  })

  return childFundingApiClient.create(payload, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: CREATE_CHILD_FUNDING_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    (error) => {
      const errorResponse = error.response

      dispatch({
        error: !errorResponse.extra ? errorResponse : null,
        type: CREATE_CHILD_FUNDING_FAILED,
      })

      if (onFailed) {
        onFailed(errorResponse)
      }
    },
  )
}

export const updateChildFunding = (
  id,
  payload,
  params,
  onSuccess,
  onFailed,
) => ({ childFundingApiClient }) => (dispatch) => {
  dispatch({
    id,
    params,
    payload,
    type: UPDATE_CHILD_FUNDING,
  })

  childFundingApiClient.update(id, payload, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: UPDATE_CHILD_FUNDING_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    (error) => {
      const errorResponse = error.response

      dispatch({
        error: !errorResponse.extra ? errorResponse : null,
        type: UPDATE_CHILD_FUNDING_FAILED,
      })

      if (onFailed) {
        onFailed(errorResponse)
      }
    },
  )
}

export const deleteChildFunding = (id, params, onSuccess) => ({ childFundingApiClient }) => (dispatch) => {
  dispatch({
    id,
    params,
    type: DELETE_CHILD_FUNDING,
  })

  childFundingApiClient.delete(id, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: DELETE_CHILD_FUNDING_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: DELETE_CHILD_FUNDING_FAILED,
      })
    },
  )
}

export const clearSingleChildFunding = () => ({
  type: CLEAR_SINGLE_CHILD_FUNDING,
})
