import React from 'react'

import { ModalBox, Spinner } from 'components'

import i18n from 'translations'

import SendDraftInvoiceModalForm from './components/SendDraftInvoiceModalForm'

const SendDraftInvoiceModalView = ({
  balance,
  childFirstName,
  initialValues,
  isLoading,
  isSubmitting,
  onCloseClick,
  onSubmit,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <SendDraftInvoiceModalForm
        balance={balance}
        childFirstName={childFirstName}
        initialValues={initialValues}
        isSubmitting={isSubmitting}
        onCancelClick={onCloseClick}
        onSubmit={onSubmit}
      />
    )
  }

  return (
    <ModalBox
      maxWidth={600}
      title={i18n.t('module:Modals:SendDraftInvoice:title')}
      onCloseClick={onCloseClick}
    >
      {renderContent()}
    </ModalBox>
  )
}

export default SendDraftInvoiceModalView
