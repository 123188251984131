import React from 'react'
import { connect } from 'react-redux'

import actions from './actions'
import selectors from './selectors'

const withNurseryExtraItemsService = (WrappedComponent) => {
  const mapState = ({ nurseryExtraItems }) => ({ nurseryExtraItems })

  const mapDispatch = {
    clear: actions.clearExtraItem,
    clearList: actions.clearList,
    create: actions.createExtraItem,
    get: actions.getExtraItem,
    list: actions.listExtraItems,
    update: actions.updateExtraItem,
  }

  const Component = (props) => {
    const { clear, clearList, create, get, list, nurseryExtraItems, update, ...other } = props

    const nurseryExtraItemsListState = nurseryExtraItems.list
    const nurseryExtraItemsSingleState = nurseryExtraItems.single
    const nurseryExtraItemsActions = { clear, clearList, create, get, list, update }
    const nurseryExtraItemsSelectors = selectors

    return (
      <WrappedComponent
        nurseryExtraItemsActions={nurseryExtraItemsActions}
        nurseryExtraItemsListState={nurseryExtraItemsListState}
        nurseryExtraItemsSelectors={nurseryExtraItemsSelectors}
        nurseryExtraItemsSingleState={nurseryExtraItemsSingleState}
        {...other}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withNurseryExtraItemsService
