import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class FormativeReportsApiClient extends ServiceBase {
  createReport = (payload) => this.request({
    body: payload,
    method: 'POST',
    path: '/v2/formative_reports',
  })

  updateReport = (id, params, payload) => this.request({
    body: payload,
    method: 'PUT',
    path: `/v2/formative_reports/${id}?${getQueryStringV2(params)}`,
  })

  getFormativeReportItems = (params) => this.request({
    path: `/v2/formative_report_items?${getQueryStringV2(params)}`,
  })

  getFormativeReportMontessoriItems = (params) => this.request({
    path: `/v2/formative_report_montessori_items?${getQueryStringV2(params)}`,
  })

  createAgeBands = (params, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/formative_report_age_bands?${getQueryStringV2(params)}`,
  })

  updateAgeBands = (id, payload) => this.request({
    body: payload,
    method: 'PUT',
    path: `/v2/formative_report_age_bands/${id}`,
  })

  createComment = (params, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/formative_report_comments?${getQueryStringV2(params)}`,
  })

  updateComments = (id, payload) => this.request({
    body: payload,
    method: 'PUT',
    path: `/v2/formative_report_comments/${id}`,
  })

  removeReport = (id) => this.request({
    method: 'DELETE',
    path: `/v2/formative_reports/${id}`,
  })

  get = (id, params = {}) => this.request({
    path: `/v2/formative_reports/${id}?${getQueryStringV2(params)}`,
  })

  list = (params) => this.request({
    path: `/v2/formative_reports?${getQueryStringV2(params)}`,
  })

  exportReport = (params) => this.request({
    path: `/v2/reports/children/formative?${getQueryStringV2(params)}`,
  })

  getPeriodLabels = (params) => this.request({
    path: `/v2/formative_reports/period_labels?${getQueryStringV2(params)}`,
  })

  getTerms = () => this.request({
    headers: { Accept: 'application/json' },
    path: '/formative-reports/terms',
  })
}

export default FormativeReportsApiClient
