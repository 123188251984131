import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'

import { Form } from 'components'

import i18n from 'translations'

import { ENQUIRY_PIPELINE_ADD_FORM } from '../AddConstants'

const AddForm = ({
  handleSubmit,
  isEditMode,
  isSubmitting,
}) => (
  <Form onSubmit={handleSubmit}>
    <Form.Row
      label={i18n.t('module:Management:Enquiries:EnquiryPipeline:Add:Form:Name:label')}
      required
    >
      <Form.Row.FlexItem mobileFull>
        <Field
          component={Form.TextField}
          name="name"
          placeholder={i18n.t('module:Management:Enquiries:EnquiryPipeline:Add:Form:Name:placeholder')}
          validate={isRequired}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.FooterActions
      cancelLink={generateRoute('MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_PIPELINE.INDEX')}
      isSubmitting={isSubmitting}
      submitLabel={i18n.t(`global:${isEditMode ? 'Update' : 'Save'}`)}
    />
  </Form>
)

export default reduxForm({ form: ENQUIRY_PIPELINE_ADD_FORM })(AddForm)
