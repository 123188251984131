import styled from 'styled-components'

export const StyledContainer = styled.div`
  .masonryGrid {
    & > * {
      width: calc(50% - 10px);
    }
    
    @media(max-width: 770px) {
      & > * {
        width: 100%;
      }
    }
  }
`
