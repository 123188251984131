import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledFormContainer = styled.section`
  background-color: ${NEUTRAL_COLOURS.WHITE};
`

export const StyledFormFieldContainer = styled.section`
  display: inline-grid;
  padding: ${({ isInlineEditMode }) => (isInlineEditMode ? '20px 0' : '20px')};
  grid-gap: 30px;
  grid-template-columns: ${({ showDelete }) => `auto auto auto auto${showDelete ? ' auto' : ''}`};
  align-items: flex-start;
`

export const StyledFormFooter = styled.footer`
  display: flex;
  padding: ${({ isInlineEditMode }) => (isInlineEditMode ? '10px 0' : '10px 20px')};
  justify-content: ${({ isInlineEditMode }) => (isInlineEditMode ? 'space-between' : 'flex-end')};
  align-items: center;
`

export const StyledRemoveButtonContainer = styled.div`
  align-self: end;
`
