import moment from 'moment'
import _ from 'lodash'

import React, { useEffect, useState } from 'react'

import {
  DAILY_DIARY_TYPE,
  DAILY_DIARY_TYPE_MAPPING_TO_COMMENT_TYPE,
  DAILY_DIARY_TYPE_MAPPING_TO_LIKE_TYPE,
} from 'services/legacy/childDailyDiary/constants'
import { ChildDailyDiary } from 'services/legacy/childDailyDiary/models'
import { EATING_TYPES, FOOD_AMOUNT } from 'services/legacy/foodMenu/constants'
import { GENDER } from 'services/legacy/child/constants'

import { typeByObject } from 'utils/typescript'

import { Button, DefinitionList, Icon, MediaPicker, Space, Typography } from 'components'
import Like from 'module/LikesAndComments/components/Like'
import Comments from 'module/LikesAndComments/components/Comments'

import i18n from 'translations'

import {
  StyledHr,
  StyledIconWrapper,
  StyledMediaPickerWrapper,
  StyledWrapper,
  StyledWrapperContent,
} from './DiaryItemStyled'

export type DiaryType = {
  color: string
  icon: IconType
  type: typeByObject<typeof DAILY_DIARY_TYPE>
}

const DIARY_TYPES: DiaryType[] = [
  {
    color: '#67dbc5',
    icon: 'logout',
    type: DAILY_DIARY_TYPE.ATTENDANCE_START,
  },
  {
    color: '#FDA172',
    icon: 'exit',
    type: DAILY_DIARY_TYPE.ATTENDANCE_STOP,
  },
  {
    color: '#FFCE35',
    icon: 'activities',
    type: DAILY_DIARY_TYPE.ACTIVITY,
  },
  {
    color: '#A8D7F7',
    icon: 'bottle',
    type: DAILY_DIARY_TYPE.BOTTLE,
  },
  {
    color: '#C3A0DE',
    icon: 'nappy',
    type: DAILY_DIARY_TYPE.NAPPY,
  },
  {
    color: '#A4DB94',
    icon: 'meals',
    type: DAILY_DIARY_TYPE.EATING,
  },
  {
    color: '#8C99BF',
    icon: 'sleeps',
    type: DAILY_DIARY_TYPE.SLEEP,
  },
  {
    color: '#C3A0DE',
    icon: 'nappy',
    type: DAILY_DIARY_TYPE.TOILET_TRAINING,
  },
]

interface DiaryItemProps {
  hasAccessToLikesAndComments: boolean
  hasAccessToLikesAndCommentsSettings: boolean
  item: ChildDailyDiary
}

const DiaryItem: React.FC<DiaryItemProps> = ({
  hasAccessToLikesAndComments,
  hasAccessToLikesAndCommentsSettings,
  item,
}) => {
  const { record, time, type } = item
  const {
    accident,
    activityRecordChildren,
    amount,
    child,
    commentCount,
    comments,
    didNotSleep,
    id,
    items,
    likeCount,
    media,
    name,
    nappyRash,
    signIns,
    type: subType,
    userLike,
    wasCreamApplied,
  } = record

  type CountType = 'likeCount' | 'userLike' | 'commentCount'

  const getCount = (countType: CountType) => {
    if (DAILY_DIARY_TYPE.ACTIVITY === type) {
      const object = activityRecordChildren?.[0]

      switch (countType) {
        case 'likeCount': return object?.likeCount
        case 'userLike': return object?.userLike
        case 'commentCount': return object?.commentCount
        default: return 0
      }
    }

    if (DAILY_DIARY_TYPE.ATTENDANCE_START === type) {
      const object = signIns?.[0]

      switch (countType) {
        case 'likeCount': return object?.signInLikeCount
        case 'userLike': return object?.userSignInLike
        case 'commentCount': return object?.signInCommentCount
        default: return 0
      }
    }

    if (DAILY_DIARY_TYPE.ATTENDANCE_START === type) {
      const object = signIns?.[0]

      switch (countType) {
        case 'likeCount': return object?.signOutLikeCount
        case 'userLike': return object?.userSignOutLike
        case 'commentCount': return object?.signOutCommentCount
        default: return 0
      }
    }

    if ('userLike' === countType) {
      return userLike
    }

    if ('commentCount' === countType) {
      return commentCount
    }

    return likeCount
  }

  const [commentsIsOpen, setCommentsIsOpen] = useState(false)
  const [commentCountFinal, setCommentCountFinal] = useState(getCount('commentCount'))
  const [likeCountFinal, setLikeCountFinal] = useState<number>(getCount('likeCount'))
  const [userLikeFinal, setUserLikeFinal] = useState<boolean>(getCount('userLike'))

  let finalObjectId = id

  if (type === DAILY_DIARY_TYPE.ATTENDANCE_START || type === DAILY_DIARY_TYPE.ATTENDANCE_STOP) {
    finalObjectId = signIns?.[0]?.id
  }

  if (type === DAILY_DIARY_TYPE.ACTIVITY) {
    finalObjectId = activityRecordChildren?.[0]?.activityRecordChildId
  }

  useEffect(() => {
    if (type === DAILY_DIARY_TYPE.ATTENDANCE_START) {
      setCommentCountFinal(signIns?.[0]?.signInCommentCount)
      setLikeCountFinal(signIns?.[0]?.signInLikeCount)
      setUserLikeFinal(signIns?.[0]?.userSignInLike)
    } else if (type === DAILY_DIARY_TYPE.ATTENDANCE_STOP) {
      setCommentCountFinal(signIns?.[0]?.signOutCommentCount || 0)
      setLikeCountFinal(signIns?.[0]?.signOutLikeCount || 0)
      setUserLikeFinal(signIns?.[0]?.userSignOutLike)
    } else if (type === DAILY_DIARY_TYPE.ACTIVITY) {
      setCommentCountFinal(activityRecordChildren?.[0]?.commentCount || 0)
      setLikeCountFinal(activityRecordChildren?.[0]?.likeCount || 0)
      setUserLikeFinal(activityRecordChildren?.[0]?.userLike)
    } else {
      setCommentCountFinal(commentCount || 0)
      setLikeCountFinal(likeCount || 0)
    }
  }, [record])

  const handleAddedNewComment = () => {
    setCommentCountFinal(commentCountFinal + 1)
  }

  const handleLikeChange = (direction, response?) => {
    setLikeCountFinal(likeCountFinal + (direction ? 1 : -1))
    setUserLikeFinal(direction ? response : null)
  }

  const diaryType = _.find(DIARY_TYPES, (e) => e.type === type)

  const renderDeterminer = () => (
    GENDER.FEMALE === child.gender
      ? i18n.t('module:Children:Child:DailyDiary:her')
      : i18n.t('module:Children:Child:DailyDiary:his')
  ) || i18n.t('module:Children:Child:DailyDiary:their')

  const renderTitle = () => {
    if (DAILY_DIARY_TYPE.EATING === type) {
      const parsedEatingType = subType?.toLowerCase()
        .replace('_am', '')
        .replace('_pm', '')

      return i18n.t('module:Children:Child:DailyDiary:eatingRecord', {
        child: child.name,
        hadA: ((subType === EATING_TYPES.SNACK_AM || subType === EATING_TYPES.SNACK_PM)
          ? i18n.t('module:Children:Child:DailyDiary:hadA')
          : i18n.t('module:Children:Child:DailyDiary:had')),
        postProcess: 'markdown-jsx',
        type: i18n.t(`module:DailyDiary:global:eatingTypes:${parsedEatingType}`).toLowerCase(),
      })
    }

    if (DAILY_DIARY_TYPE.NAPPY === type) {
      return i18n.t('module:Children:Child:DailyDiary:nappyChanged', {
        child: child.name,
        postProcess: 'markdown-jsx',
      })
    }

    if (DAILY_DIARY_TYPE.TOILET_TRAINING === type) {
      return i18n.t('module:Children:Child:DailyDiary:toiletTrainingRecordTitle', {
        child: child.name,
        postProcess: 'markdown-jsx',
      })
    }

    if (DAILY_DIARY_TYPE.SLEEP === type) {
      if (didNotSleep) {
        return i18n.t('module:Children:Child:DailyDiary:childDidNotSleep', {
          child: child.name,
          postProcess: 'markdown-jsx',
        })
      }

      return i18n.t('module:Children:Child:DailyDiary:childSlept', {
        child: child.name,
        postProcess: 'markdown-jsx',
        // @ts-ignore
        time: moment.preciseDiff(record.startTime, record.endTime),
      })
    }

    if (DAILY_DIARY_TYPE.ACTIVITY === type) {
      return (
        <DefinitionList>
          <DefinitionList.Item
            label={(
              <Typography bold primary>
                {i18n.t('module:DailyDiary:Activities:activityName')}
              </Typography>
            )}
            value={name}
            whiteSpace="pre-wrap"
            wordBreak="break-word"
          />
        </DefinitionList>
      )
    }

    if (DAILY_DIARY_TYPE.BOTTLE === type) {
      return i18n.t('module:Children:Child:DailyDiary:bottleFeed', {
        amount,
        child: child.name,
        postProcess: 'markdown-jsx',
      })
    }

    if (DAILY_DIARY_TYPE.ATTENDANCE_START === type) {
      return i18n.t('module:Children:Child:DailyDiary:attendanceStart', {
        determiner: renderDeterminer(),
        postProcess: 'markdown-jsx',
      })
    }

    if (DAILY_DIARY_TYPE.ATTENDANCE_STOP === type) {
      return i18n.t('module:Children:Child:DailyDiary:attendanceStop', {
        determiner: renderDeterminer(),
        postProcess: 'markdown-jsx',
      })
    }

    return null
  }

  const commentDetails = () => comments && (
    <div>
      <Space space="15px" />
      <DefinitionList>
        <DefinitionList.Item
          label={(
            <Typography bold primary>
              {i18n.t('module:DailyDiary:global:additionalNotes')}
            </Typography>
          )}
          value={comments}
          whiteSpace="pre-wrap"
          wordBreak="break-word"
        />
      </DefinitionList>
    </div>
  )

  const renderDetails = () => {
    if ([
      DAILY_DIARY_TYPE.ATTENDANCE_START,
      DAILY_DIARY_TYPE.ATTENDANCE_STOP,
      DAILY_DIARY_TYPE.BOTTLE,
      DAILY_DIARY_TYPE.SLEEP,
      DAILY_DIARY_TYPE.ACTIVITY,
    ].includes(type)) {
      return commentDetails()
    }

    if (DAILY_DIARY_TYPE.EATING === type) {
      return (
        <div>
          {_.map(items, (recordItem) => (
            <div>
              {i18n.t('module:Children:Child:DailyDiary:foodItemContent', {
                amount: (FOOD_AMOUNT.OVER === recordItem.amount
                  ? i18n.t('module:DailyDiary:Foods:foodAmount:overLong')
                  : i18n.t(`module:DailyDiary:Foods:foodAmount:${recordItem.amount.toLowerCase()}`).toLowerCase()
                ),
                determiner: renderDeterminer(),
                food: recordItem.food.name.toLowerCase(),
                interpolation: { escapeValue: false },
              })}
            </div>
          ))}
          {commentDetails()}
        </div>
      )
    }

    if (DAILY_DIARY_TYPE.NAPPY === type) {
      return (
        <div>
          <div>
            {i18n.t('module:Children:Child:DailyDiary:nappyWas', {
              action: i18n.t(`module:DailyDiary:global:nappyTypes:${subType.toLowerCase()}`).toLowerCase(),
            })}
          </div>
          {nappyRash && (
            <div>
              {i18n.t('module:Children:Child:DailyDiary:hadANappyRash')}
            </div>
          )}
          {wasCreamApplied && (
            <div>
              {i18n.t('module:Children:Child:DailyDiary:wasCreamApplied')}
            </div>
          )}
          {commentDetails()}
        </div>
      )
    }

    if (DAILY_DIARY_TYPE.TOILET_TRAINING === type) {
      return (
        <div>
          <div>
            {i18n.t('module:Children:Child:DailyDiary:toiletTrainingUsed', {
              action: i18n.t(`module:DailyDiary:global:toiletTrainingTypes:${subType.toLowerCase()}`),
            })}
          </div>
          {accident && (
            <div>
              {i18n.t('module:Children:Child:DailyDiary:hadAccident')}
            </div>
          )}
          {commentDetails()}
        </div>
      )
    }

    return null
  }

  return (
    <StyledWrapper>
      <StyledWrapperContent $hasAccessToLikesAndComments={hasAccessToLikesAndComments}>
        <StyledIconWrapper $type={diaryType}>
          <Icon color={diaryType.color} height={20} icon={diaryType.icon} />
        </StyledIconWrapper>
        <Typography bold secondary>
          {moment(time).format('HH:mm')}
        </Typography>
        <Typography>
          {renderTitle()}
          <Space space="5px" />
          {renderDetails()}
        </Typography>
        {hasAccessToLikesAndComments && (
          <React.Fragment>
            <div>
              {(hasAccessToLikesAndCommentsSettings || (!hasAccessToLikesAndCommentsSettings && commentCountFinal)) ? (
                <Button
                  hierarchy="tertiary"
                  label={i18n.t('module:Children:Child:DailyDiary:comments', { amount: commentCountFinal || 0 })}
                  onClick={() => setCommentsIsOpen(!commentsIsOpen)}
                />
              ) : null}
            </div>
            <div>
              <Like
                likeCount={likeCountFinal}
                likeType={DAILY_DIARY_TYPE_MAPPING_TO_LIKE_TYPE[type]}
                objectId={finalObjectId}
                preview={!hasAccessToLikesAndCommentsSettings}
                userLike={userLikeFinal}
                onChange={handleLikeChange}
              />
            </div>
          </React.Fragment>
        )}
      </StyledWrapperContent>
      {(DAILY_DIARY_TYPE.ACTIVITY === type && media?.length) ? (
        <StyledMediaPickerWrapper>
          <MediaPicker
            value={media}
            disableSticky
            disableTagging
            preview
          />
        </StyledMediaPickerWrapper>
      ) : null}
      {commentsIsOpen && (
        <Comments
          commentType={DAILY_DIARY_TYPE_MAPPING_TO_COMMENT_TYPE[type]}
          objectId={finalObjectId}
          wrapper={(children) => (
            <React.Fragment>
              <StyledHr />
              {children}
            </React.Fragment>
          )}
          onAddedNewComment={handleAddedNewComment}
        />
      )}
    </StyledWrapper>
  )
}

export default DiaryItem
