import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withLeaveTypesService } from 'services/legacy/leaveTypes'
import { withRouter } from 'services/router'

import i18n from 'translations'

import StaffLeaveTypesAddView from './StaffLeaveTypesAddView'

class StaffLeaveTypesAddContainer extends Component {
  componentDidMount() {
    const { isEdit, leaveTypesActions, params } = this.props
    const { itemId } = params

    if (isEdit) {
      leaveTypesActions.get({ id: itemId })
    }
  }

  componentWillUnmount() {
    const { leaveTypesActions } = this.props

    leaveTypesActions.clear()
  }

  handleSubmitSuccess = () => {
    const { navigate } = this.props

    navigate(generateRoute('MANAGEMENT.LEAVE_TYPES.STAFF_LEAVE_TYPES'))
  }

  handleSubmit = (fields) => {
    const { isEdit, leaveTypesActions, params } = this.props
    const { itemId } = params

    if (isEdit) {
      return leaveTypesActions.update({ id: itemId, onSuccess: this.handleSubmitSuccess, payload: fields })
    }

    return leaveTypesActions.create({ onSuccess: this.handleSubmitSuccess, payload: fields })
  }

  archiveLeaveType = (archived) => {
    const { leaveTypesActions, params } = this.props
    const { itemId } = params

    return leaveTypesActions.update({ id: itemId, onSuccess: this.handleSubmitSuccess, payload: { archived } })
  }

  handleArchiveClick = (archived) => () => {
    const { modalActions, modalConsts } = this.props

    if (!archived) {
      return this.archiveLeaveType(archived)
    }

    return modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('global:archive'),
      icon: 'archive',
      onConfirm: () => this.archiveLeaveType(archived),
      text: i18n.t('module:Management:LeaveTypes:Staff:Add:archiveModalTitle'),
    })
  }

  render() {
    const {
      errorMessages,
      initialValues,
      isArchived,
      isEdit,
      isFetching,
      isFormLoading,
    } = this.props

    return (
      <StaffLeaveTypesAddView
        errorMessages={errorMessages}
        initialValues={initialValues}
        isArchived={isArchived}
        isEdit={isEdit}
        isFormLoading={isFormLoading}
        isLoading={isFetching}
        onArchiveClick={this.handleArchiveClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  leaveTypesSelectors,
  leaveTypesSingleState,
  params,
}) => ({
  errorMessages: appSelectors.getErrorMessages(leaveTypesSingleState),
  initialValues: leaveTypesSelectors.getInitialValues(state),
  isArchived: leaveTypesSelectors.isArchived(state),
  isEdit: !!params.itemId,
  isFetching: appSelectors.getIsFetching(leaveTypesSingleState),
  isFormLoading: leaveTypesSelectors.getIsSubmitting(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withLeaveTypesService,
  connect(mapState),
)

export default enhance(StaffLeaveTypesAddContainer)
