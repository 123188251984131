import _ from 'lodash'

import React from 'react'

import { INJURIES } from 'services/legacy/injuries/constants'
import { DIETARY_COLORS } from 'services/legacy/child/constants'

import { getAllergyType } from 'services/legacy/child/helpers'

import { ChildrenSlider, CircleIcon, DateString, DefinitionList, GridLayout } from 'components'

import i18n from 'translations'

import InjuryChildForm from '../InjuryChildForm'
import { INJURY_CHILD_FORM } from '../InjuryChildForm/InjuryChildForm'
import { getRoomName } from '../../../SafeguardingHelpers'
import { StyledFormContainer } from './IncidentViewStyled'

const IncidentView = ({
  activeChildIndex,
  formValues,
  initialValues,
  injuryTypeOptions,
  isChangedCoordinate,
  isEditMode,
  isHideBodyMap,
  onChangeMedia,
  onClickCoordinates,
  onClickHideBodyMap,
  onClickSlide,
  onRemoveChild,
  selectedChildren,
  updateSlider,
}) => {
  if (!selectedChildren || !selectedChildren.length) {
    return null
  }

  const activeChild = selectedChildren[activeChildIndex]
  const { birthDate, information, profile } = activeChild
  const { medicalInformation } = profile
  const { allergies, allergiesDetails } = information

  const selectedChild = ({ id }, index) => (
    <InjuryChildForm
      form={`${INJURY_CHILD_FORM}_${id}`}
      formValues={formValues(`${INJURY_CHILD_FORM}_${id}`)}
      initialValues={initialValues[id]}
      injuryTypeOptions={injuryTypeOptions}
      isChangedCoordinate={isChangedCoordinate}
      isEditMode={isEditMode}
      isHideBodyMap={isHideBodyMap}
      key={id}
      selectedChild={selectedChildren.length}
      type={INJURIES.INCIDENT}
      visible={index === activeChildIndex}
      onChangeMedia={(e) => onChangeMedia(e, `${INJURY_CHILD_FORM}_${id}`)}
      onClickCoordinates={(e) => onClickCoordinates(e, `${INJURY_CHILD_FORM}_${id}`)}
      onClickHideBodyMap={onClickHideBodyMap}
      onSubmit={() => { }}
    />
  )

  return (
    <div>
      <ChildrenSlider
        childrenList={selectedChildren}
        selectedSlideIndex={activeChildIndex}
        updateSlider={updateSlider}
        onClickSlide={onClickSlide}
        onRemoveChild={onRemoveChild}
      />
      <StyledFormContainer>
        <GridLayout>
          <GridLayout.Group>
            <GridLayout.Item column>
              <DefinitionList margin="0 0 30px 0" vertical>
                <DefinitionList.Item
                  label={i18n.t('global:dateOfBirthShort')}
                  margin="0"
                  padding="5px 0"
                  value={(activeChild && (
                    <DateString date={birthDate} />
                  )) || '-'}
                />
              </DefinitionList>
            </GridLayout.Item>
            <GridLayout.Item column>
              <DefinitionList margin="0 0 30px 0" vertical>
                <DefinitionList.Item
                  label={_.upperFirst(i18n.t('global:room'))}
                  value={getRoomName(activeChild) || '-'}
                />
              </DefinitionList>
            </GridLayout.Item>
            <GridLayout.Item column>
              <DefinitionList margin="0 0 30px 0" vertical>
                <DefinitionList.Item
                  label={_.upperFirst(i18n.t('global:medicationInformation'))}
                  value={medicalInformation || '-'}
                />
              </DefinitionList>
            </GridLayout.Item>
            <GridLayout.Item column>
              <DefinitionList margin="0 0 30px 0" vertical>
                <DefinitionList.Item
                  label={(
                    <CircleIcon
                      background={DIETARY_COLORS.ALLERGIES}
                      icon="allergies"
                      label={_.upperFirst(i18n.t('global:allergies'))}
                      size={20}
                    />
                  )}
                  value={(activeChild && getAllergyType(allergies, allergiesDetails)) || '-'}
                />
              </DefinitionList>
            </GridLayout.Item>
          </GridLayout.Group>
        </GridLayout>
        {_.map(selectedChildren, selectedChild)}
      </StyledFormContainer>
    </div>
  )
}

export default IncidentView
