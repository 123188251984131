import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'
import { STRIPE_INTEGRATION_TYPE } from 'services/integrationFees/constants'

import { generateRoute } from 'utils/routing'

import { ChildBacsDetail } from 'services/childBacsDetails/models'

import { withAppService, withAppServiceProps } from 'services/app'
import { withChildBacsDetailsService, withChildBacsDetailsServiceProps } from 'services/childBacsDetails'
import { withRouteUtilsProps } from 'services/utils/router'
import { withRouter, withRouterProps } from 'services/router'

import AddBacsDetailsView from './AddBacsDetailsView'

type AddBacsDetailsProps = withAppServiceProps
  & withRouteUtilsProps
  & withRouterProps
  & withChildBacsDetailsServiceProps

const mapState = (state: RootState, {
  appSelectors,
  childBacsDetailsSelectors,
  childBacsDetailsSingleState,
}: AddBacsDetailsProps) => ({
  childBacsDetails: childBacsDetailsSelectors.getChildBacsDetails(state),
  errorMessages: appSelectors.getErrorMessages(childBacsDetailsSingleState),
  isSubmitting: appSelectors.getIsSubmitting(childBacsDetailsSingleState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const AddBacsDetailsContainer: React.FC<AddBacsDetailsProps & PropsFromRedux> = ({
  childBacsDetails,
  childBacsDetailsActions,
  childBacsDetailsSelectors,
  errorMessages,
  isSubmitting,
  navigate,
  params,
}) => {
  const [initialValues, setInitialValues] = useState<ChildBacsDetail>()

  useEffect(() => {
    childBacsDetailsActions.get({
      params: [params.childId],
    })
  }, [])

  useEffect(() => {
    if (!initialValues && childBacsDetails?.enabled) {
      const finalInitialValues = { ...childBacsDetails }

      if (STRIPE_INTEGRATION_TYPE === childBacsDetails?.type) {
        finalInitialValues.accountNumber = `****${finalInitialValues.accountNumber}`
      }

      setInitialValues(finalInitialValues)
    }
  }, [childBacsDetails])

  const handleSubmit = (fields) => {
    const body = childBacsDetailsSelectors.getBody(fields)

    childBacsDetailsActions.create({
      body,
      onSuccess: () => navigate(generateRoute('CHILDREN.CHILD.FINANCE.INVOICING', { childId: params.childId })),
      params: [params.childId],
    })
  }

  const isStripeType = STRIPE_INTEGRATION_TYPE === childBacsDetails?.type

  return (
    <AddBacsDetailsView
      childId={params.childId}
      errorMessages={errorMessages}
      initialValues={initialValues}
      isStripeType={isStripeType}
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withRouter,
  withAppService,
  withChildBacsDetailsService,
  connector,
)

export default enhance(AddBacsDetailsContainer)
