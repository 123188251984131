import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'

import { Select } from 'components'

import i18n from 'translations'

import withInfiniteDropdown from './withInfiniteDropdown'

class NurseriesDropdown extends Component {
  componentDidMount() {
    const { infiniteDropdownHelpers, nurseriesActions, value } = this.props

    if (!_.isArray(value)) {
      return infiniteDropdownHelpers.handleComponentDidMount({
        action: () => nurseriesActions.getDropdownItem(value?.value || value, {
          onFailed: this.notFoundValue,
          onSuccess: infiniteDropdownHelpers.handleComponentDidMountOnSuccess,
        }),
        value,
      })
    }

    return null
  }

  handleLoadMoreElements = async (phrase) => {
    const {
      infiniteDropdownHelpers,
      limit,
      nurseriesActions,
      nurseriesSelectors,
    } = this.props

    return infiniteDropdownHelpers.handleLoadMoreElements({
      clearAction: () => {},
      listAction: ({ newPage, newSearchPhrase, result }) => nurseriesActions.dropdownList({
        onSuccess: (response) => infiniteDropdownHelpers.handleLoadMoreElementsOnSuccess({
          newPage,
          newSearchPhrase,
          response,
          result,
        }),
        params: {
          criteria: nurseriesSelectors.getDropdownListCriteria({
            name: newSearchPhrase,
          }),
          limit,
          page: newPage,
        },
      }),
      phrase,
    })
  }

  notFoundValue = () => {
    const { onChange } = this.props

    onChange(null)
  }

  handleChange = (e) => {
    const { infiniteDropdownHelpers, onChange } = this.props

    infiniteDropdownHelpers.handleOnChange(e, () => onChange(e))
  }

  render() {
    const {
      disabled,
      infiniteDropdownState,
      placeholder,
      rawValue,
      value,
      ...rest
    } = this.props
    const { cacheUniq, options, selectedValue } = infiniteDropdownState

    return (
      <Select
        {...rest}
        cacheUniq={cacheUniq}
        disabled={disabled}
        loadOptions={this.handleLoadMoreElements}
        options={options}
        placeholder={placeholder
        || _.upperFirst(i18n.t('global:Nursery'))}
        rawValue={rawValue || selectedValue || value}
        value={value}
        clearable
        searchable
        onChange={this.handleChange}
      />
    )
  }
}

const enhance = compose(
  withAppService,
  withNurseriesService,
  withInfiniteDropdown,
)

export default enhance(NurseriesDropdown)
