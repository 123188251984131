import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { HEADER_SUBDOMAIN } from 'services/security/authorization/constants'
import { ROLES } from 'constants/security'

import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withSecurityService } from 'services/security'
import { withNurseryFrameworkRelationsService } from 'services/legacy/nurseryFrameworkRelations'

import NurseriesPreviewView from './NurseriesPreviewView'
import NurseryEdit from '../NurseriesEdit'

const FRAMEWORK_NURSERY_RELATION_GROUPS = {
  read: [
    'nurseryFrameworkRelation.framework',
  ],
}

const GROUPS = {
  read: [
    'membership',
    'membership.details',
    'nursery.details',
    'nursery.primaryContact',
    'nursery.settings',
    'nursery.organization',
    'nurserySettings',
    'nurserySettings.localeDetails',
    'organization',
  ],
}

class NurseriesPreviewContainer extends Component {
  componentDidMount() {
    const {
      isAdministrationContext,
      nurseriesActions,
      nurseryFrameworkRelationsActions,
      params: { nurseryId },
    } = this.props

    nurseriesActions.get(nurseryId, {
      onSuccess: (response) => {
        if (isAdministrationContext) {
          nurseriesActions.listFlags(nurseryId)
          nurseryFrameworkRelationsActions.list({
            params: [{
              groups: FRAMEWORK_NURSERY_RELATION_GROUPS,
              headers: {
                [HEADER_SUBDOMAIN]: response?.data?.subdomain,
              },
            }],
          })
        }
      },
      params: { groups: GROUPS },
    })
  }

  componentWillUnmount() {
    const { nurseriesActions } = this.props

    nurseriesActions.clearSingle()
  }

  render() {
    const {
      authAccessMap,
      featureFlags,
      isAdministrationContext,
      isFetching,
      isOrganizationContext,
      nursery,
      nurseryPublicUrl,
    } = this.props

    return (
      <NurseriesPreviewView
        authAccessMap={authAccessMap}
        featureFlags={featureFlags}
        isAdministrationContext={isAdministrationContext}
        isFetching={isFetching}
        isOrganizationContext={isOrganizationContext}
        nursery={nursery}
        nurseryPublicUrl={nurseryPublicUrl}
      />
    )
  }
}

NurseriesPreviewContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
  ],
}

const mapState = (state, {
  appSelectors,
  nurseriesSelectors,
  nurseriesSingleState,
  securitySelectors,
}) => ({
  authAccessMap: {
    module: {
      NurseryEdit: auth.SELECTORS.getComponentIsAuthorised(state, NurseryEdit),
    },
  },
  featureFlags: nurseriesSelectors.getNurseryFlagsSelector(state),
  isAdministrationContext: securitySelectors.isAdministrationContext(state),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
  nursery: nurseriesSelectors.getNurseryData(state),
  nurseryPublicUrl: nurseriesSelectors.getNurseryPublicUrlSelector(state),
})

const enhance = compose(
  withAppService,
  withNurseryFrameworkRelationsService,
  withNurseriesService,
  withSecurityService,
  connect(mapState),
)

export default enhance(NurseriesPreviewContainer)
