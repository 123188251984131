import _ from 'lodash'

import React from 'react'

import { generateRoute } from 'utils/routing'

import { Button } from 'components'

export const getTableData = (data) => _.map(data, ({ id, name }) => ({
  academicYear: name,
  edit: (
    <Button.TableAction
      icon="chevron-right"
      to={generateRoute('MANAGEMENT.CLOSURE_DAYS.CLOSURE_DATES.PREVIEW', { nurseryAcademicYearId: id })}
    />),
  id,
}))
