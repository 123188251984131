import i18n from 'translations'

import AttendanceWidget from './AttendanceWidgetContainer'

export const WIDGET_NAME = {
  component: AttendanceWidget,
  id: 'ATTENDANCE_WIDGET',
  label: i18n.t('module:Dashboard:Widgets:AttendanceWidget:titleInSettings'),
}

export default AttendanceWidget
