import _ from 'lodash'

import React from 'react'
import { Field, FieldArray, reduxForm } from 'redux-form'
import { compose } from 'recompose'

import { Button, Form } from 'components'

import { isRequired } from 'utils/fieldValidation'

const renderTextArea = (isGlobalOptionOn) => (item, index, fields) => {
  const { placeholder } = item

  return (
    <Form.Row key={_.kebabCase(index)}>
      <Form.Row.FlexItem>
        <Field
          component={Form.TextAreaField}
          name={`${item}.note`}
          placeholder={placeholder}
          props={{ disabled: isGlobalOptionOn }}
          validate={isRequired}
        />
      </Form.Row.FlexItem>
      {!isGlobalOptionOn && (
        <Form.Row.FlexItem flex="0 0 40px">
          <Form.RemoveRowButton onClick={() => fields.remove(index)} />
        </Form.Row.FlexItem>
      )}
    </Form.Row>
  )
}

const renderTextAreaItem = ({ fields, isGlobalOptionOn }) => (
  <div>
    {fields.map(renderTextArea(isGlobalOptionOn))}
    {!isGlobalOptionOn && (
      <Form.Row>
        <Form.Row.Item>
          <Button.ActionButton label="Add another note" onClick={() => fields.push({})} />
        </Form.Row.Item>
      </Form.Row>
    )}
  </div>
)

const InvoiceNoteSettingsForm = ({
  globalId,
  handleSubmit,
  hideGlobalOption,
  id,
  isGlobalOptionOn,
  onGlobalOptionClick,
  onSubmit,
}) => (
  <Form flex={1} onSubmit={handleSubmit(onSubmit)}>
    {!hideGlobalOption && (
      <Form.Row>
        <Form.Row.Item>
          <Field
            component={Form.Switch}
            name={globalId}
            onChange={onGlobalOptionClick}
          />
        </Form.Row.Item>
        <Form.Row.Item>
          Use global settings
        </Form.Row.Item>
      </Form.Row>
    )}
    <FieldArray
      component={renderTextAreaItem}
      isGlobalOptionOn={isGlobalOptionOn}
      name={id}
    />
  </Form>
)

const enhance = compose(reduxForm({}))

export default enhance(InvoiceNoteSettingsForm)
