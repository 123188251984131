import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class DailyDiaryActivitiesApiClientV2 extends ServiceBase {
  list = (params) => this.request({
    path: `/v2/activity_records?${getQueryStringV2(params)}`,
  })

  addActivityMedia = (activityId, params, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/batch/activity_records/${activityId}/media?${getQueryStringV2(params)}`,
  })

  removeActivityMedia = (activityId, body) => this.request({
    body,
    method: 'DELETE',
    path: `/v2/batch/activity_records/${activityId}/media`,
  })

  updateActivityMedia = (activityId, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/batch/activity_records/${activityId}/media`,
  })
}

export default DailyDiaryActivitiesApiClientV2
