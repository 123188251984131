import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { ROLES } from 'constants/security'
import { INVOICE_TYPES } from 'services/legacy/invoices/constants'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withInvoicesService } from 'services/legacy/invoices'
import { withFinanceCreditNotesReportService } from 'services/legacy/financeCreditNotesReport'
import { withPaginationUtils } from 'services/utils/pagination'
import { withSecurityService } from 'services/security'
import { withSortingUtils } from 'services/utils/sorting'
import { withRouter } from 'services/router'

import { useBasicReport } from 'hooks/reports'

import {
  Currency,
  DatePicker,
  Form,
  InfiniteDropdowns,
  ReportView,
  StickyFooter,
  Toolbar,
  Typography,
} from 'components'

import i18n from 'translations'

import { getColumns, getStatistics, getTableData } from './CreditNotesHelper'

const CHILDREN_BALANCE_GROUPS = {
  read: [
    'child',
    'creditNote',
    'creditNote.child',
    'creditNote.author',
    'creditNote.invoice',
    'invoice',
    'user',
    'user.details',
  ],
}

const NURSERIES_BALANCE_GROUPS = {
  read: [
    'nursery',
  ],
}

const CreditNotesContainer = ({
  childrenData,
  childrenTotalResults,
  errorMessages,
  financeCreditNotesReportActions,
  financeCreditNotesReportSelectors,
  invoicesActions,
  isFetching,
  isOrganizationContext,
  isStatisticsFetching,
  location,
  modalActions,
  modalConsts,
  nurseriesData,
  nurseriesTotalResults,
  paginationUtils,
  setLocationQuery,
  sortingUtils,
  statisticsData,
}) => {
  const {
    author,
    dateRange,
    onAuthorChange,
    onDateChange,
    onPageChange,
    page,
    pageCount,
    perPage,
    statistics,
    tableColumns,
    tableData,
    totalResults,
  } = useBasicReport({
    actions: financeCreditNotesReportActions,
    callStatisticsAction: true,
    childrenApiGroups: CHILDREN_BALANCE_GROUPS,
    childrenData,
    childrenTotalResults,
    errorMessages,
    getColumns,
    getStatistics,
    getTableData,
    isOrganizationContext,
    location,
    nurseriesApiGroups: NURSERIES_BALANCE_GROUPS,
    nurseriesData,
    nurseriesTotalResults,
    paginationUtils,
    selectors: financeCreditNotesReportSelectors,
    setLocationQuery,
    statisticsData,
  })

  const handleExportClick = () => {
    const { sortField, sortOrder } = sortingUtils

    const criteria = financeCreditNotesReportSelectors.getExportCriteria({
      author: author?.value,
      dateRange: dateRange && {
        after: dateRange[0],
        before: dateRange[1],
      },
      type: INVOICE_TYPES.CREDIT_NOTE,
    })

    const CREDIT_NOTE_TYPE = 'credit-note'

    modalActions.show(modalConsts.TYPES.EXPORT_REPORT, {
      onSendReport: (body, onSuccess, onFailed) => invoicesActions.exportInvoice(CREDIT_NOTE_TYPE, {
        onFailed,
        onSuccess,
        params: { ...body, criteria: [...body.criteria, ...criteria], order: { sortField, sortOrder } },
      }),
      title: i18n.t('module:Finance:Reports:CreditNotes:Export:title'),
      userLabel: i18n.t('module:Finance:Reports:CreditNotes:Export:description'),
    })
  }

  const renderFilters = () => (
    <Toolbar bottomLine>
      <Toolbar.Group>
        <Toolbar.Item>
          <Form.Row
            label={i18n.t('module:Finance:Reports:CreditNotes:Filters:Date:label')}
            verticalLabel
          >
            <DatePicker
              clearable={false}
              value={dateRange}
              range
              onChange={onDateChange}
            />
          </Form.Row>
        </Toolbar.Item>
        {!isOrganizationContext && (
          <Toolbar.Item>
            <Form.Row
              label={i18n.t('module:Finance:Reports:CreditNotes:Filters:CreatedBy:label')}
              verticalLabel
            >
              <InfiniteDropdowns.Users
                placeholder={i18n.t('module:Finance:Reports:CreditNotes:Filters:CreatedBy:placeholder')}
                value={author}
                onChange={onAuthorChange}
              />
            </Form.Row>
          </Toolbar.Item>
        )}
      </Toolbar.Group>
    </Toolbar>
  )

  const renderFooter = () => (
    <StickyFooter>
      <StickyFooter.Flex />
      <Typography fontSize={20} margin="10px 20px" variant="span" bold>
        {`${i18n.t('module:Finance:Reports:CreditNotes:footerLabel')}: `}
        <Currency value={statisticsData?.amount} />
      </Typography>
    </StickyFooter>
  )

  return (
    <ReportView.DetailPage
      description={isOrganizationContext
        ? i18n.t('module:Finance:Reports:CreditNotes:Organization:description')
        : i18n.t('module:Finance:Reports:CreditNotes:description')}
      errorMessages={errorMessages}
      filters={renderFilters()}
      footer={!isOrganizationContext && renderFooter()}
      hideExport={isOrganizationContext}
      isOrganizationContext={isOrganizationContext}
      isPageLoading={isFetching && isStatisticsFetching && 1 === page}
      isStatisticsLoading={isStatisticsFetching}
      isTableLoading={isFetching}
      minWidth={1050}
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      statistics={statistics}
      statisticsGap="60px"
      tableColumns={tableColumns}
      tableData={tableData}
      tableLayout="fixed"
      title={i18n.t('module:Finance:Reports:CreditNotes:title')}
      totalResults={totalResults}
      onExportClick={handleExportClick}
      onPageChange={onPageChange}
    />
  )
}

CreditNotesContainer.authParams = {
  antiRoles: [
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
  ],
}

const mapState = (state, {
  appSelectors,
  financeCreditNotesReportChildrenState,
  financeCreditNotesReportNurseriesState,
  financeCreditNotesReportSelectors,
  financeCreditNotesReportStatisticsState,
  securitySelectors,
}) => ({
  childrenData: financeCreditNotesReportSelectors.getFinanceCreditNotesReportChildrenData(state),
  childrenTotalResults: appSelectors.getTotalResults(financeCreditNotesReportChildrenState),
  errorMessages: appSelectors.getErrorMessages(
    financeCreditNotesReportChildrenState,
    financeCreditNotesReportNurseriesState,
    financeCreditNotesReportStatisticsState,
  ),
  isFetching: appSelectors.getIsFetching(
    financeCreditNotesReportChildrenState,
    financeCreditNotesReportNurseriesState,
  ),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
  isStatisticsFetching: appSelectors.getIsFetching(financeCreditNotesReportStatisticsState),
  nurseriesData: financeCreditNotesReportSelectors.getFinanceCreditNotesReportNurseriesData(state),
  nurseriesTotalResults: appSelectors.getTotalResults(financeCreditNotesReportNurseriesState),
  statisticsData: financeCreditNotesReportSelectors.getFinanceCreditNotesReportStatisticsData(state),
})

const enhance = compose(
  withRouter,
  withAppService,
  withFinanceCreditNotesReportService,
  withInvoicesService,
  withModalService,
  withPaginationUtils,
  withSecurityService,
  withSortingUtils,
  connect(mapState),
)

export default enhance(CreditNotesContainer)
