import React from 'react'

import { Callout, Page, Section, Spinner } from 'components'

import i18n from 'translations'

import EditDeviceForm from './components/EditDeviceForm'

const EditDeviceView = ({
  errorMessages,
  isFetching,
  onRemoveDevice,
  ...rest
}) => {
  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    return (
      <EditDeviceForm
        {...rest}
      />
    )
  }

  return (
    <Page.Section
      actions={(
        <Section.Actions
          options={[{
            icon: 'trash',
            label: i18n.t('global:Remove'),
            onClick: onRemoveDevice,
          }]}
        />
      )}
      isLoading={isFetching}
      title={i18n.t('module:Management:Security:AuthorisedDevices:EditDevice:title')}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default EditDeviceView
