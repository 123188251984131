import React from 'react'

import { generateRoute } from 'utils/routing'

import { Page, Spinner } from 'components'

import i18n from 'translations'

import StaffDetailsBreakdown from 'module/Staff/components/StaffDetailsBreakdown'

const StaffDetailsView = ({
  isAreaManager,
  isFetching,
  isMyDetailsContext,
  isStaffingEnabled,
  membership,
}) => {
  const {
    id,
  } = membership || {}

  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    return (
      <StaffDetailsBreakdown
        editLink={isMyDetailsContext
          ? generateRoute('ACCOUNT.EDIT')
          : generateRoute('STAFF.PROFILE.EDIT', { userId: id })}
        isStaffingEnabled={isStaffingEnabled}
        membership={membership}
        showNurseries={isAreaManager}
        showHeader
      />
    )
  }

  return (
    <Page.Section
      isLoading={isFetching}
      title={isMyDetailsContext
        ? i18n.t('module:Staff:StaffDetails:Title:yourBlossomProfile')
        : i18n.t('module:Staff:StaffDetails:Title:staffMemberDetails')}
    >
      {renderContent()}
    </Page.Section>
  )
}

export default StaffDetailsView
