import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_PERIOD,
  CREATE_PERIOD,
  CREATE_PERIOD_FAILED,
  CREATE_PERIOD_SUCCESS,
  GET_PERIOD,
  GET_PERIOD_FAILED,
  GET_PERIOD_SUCCESS,
  REMOVE_PERIOD,
  REMOVE_PERIOD_FAILED,
  REMOVE_PERIOD_SUCCESS,
  UPDATE_PERIOD,
  UPDATE_PERIOD_FAILED,
  UPDATE_PERIOD_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_PERIOD,
    createFailed: CREATE_PERIOD_FAILED,
    createInit: CREATE_PERIOD,
    createSuccess: CREATE_PERIOD_SUCCESS,
    itemFailed: GET_PERIOD_FAILED,
    itemInit: GET_PERIOD,
    itemSuccess: GET_PERIOD_SUCCESS,
    removeFailed: REMOVE_PERIOD_FAILED,
    removeInit: REMOVE_PERIOD,
    removeSuccess: REMOVE_PERIOD_SUCCESS,
    updateFailed: UPDATE_PERIOD_FAILED,
    updateInit: UPDATE_PERIOD,
    updateSuccess: UPDATE_PERIOD_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
