import _ from 'lodash'

import i18n from 'translations'

export const COMMON_TABLE_COLUMNS = [
  {
    field: 'viewingsAttended',
    sortable: true,
    title: i18n.t('module:Enquiries:Report:ViewingPerformance:Table:Header:viewingsAttended'),
  },
  {
    field: 'viewingsThatLedToEnrollment',
    title: i18n.t('module:Enquiries:Report:ViewingPerformance:Table:Header:viewingsThatLedToEnrollment'),
  },
  {
    field: 'viewingsThatLedToLostEnquires',
    title: i18n.t('module:Enquiries:Report:ViewingPerformance:Table:Header:viewingsThatLedToLostEnquires'),
  },
  {
    field: 'viewingsWithOpenEnquiryStatus',
    title: i18n.t('module:Enquiries:Report:ViewingPerformance:Table:Header:viewingsWithOpenEnquiryStatus'),
  },
]

export const NURSERY_TABLE_COLUMNS = [
  {
    align: 'left',
    field: 'staffName',
    title: i18n.t('module:Enquiries:Report:ViewingPerformance:Table:Header:staffName'),
  },
  ...COMMON_TABLE_COLUMNS,
]

export const GROUP_TABLE_COLUMNS = [
  {
    align: 'left',
    field: 'staffName',
    title: i18n.t('module:Enquiries:Report:ViewingPerformance:Table:Header:staffName'),
  },
  {
    field: 'nurseryName',
    title: _.upperFirst(i18n.t('global:site')),
  },
  ...COMMON_TABLE_COLUMNS,
]
