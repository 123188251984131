import React from 'react'
import DayPicker, { DayModifiers, Modifier, Modifiers } from 'react-day-picker'

interface MultiRangeCalendarDayPickerWrapperProps {
  modifiers?: Partial<Modifiers>
  month?: Date
  onDayClick?: (
    day: Date,
    modifiers: DayModifiers,
    e: React.MouseEvent<HTMLDivElement>
  ) => void
  onDayMouseEnter?: (
    day: Date,
    modifiers: DayModifiers,
    e: React.MouseEvent<HTMLDivElement>
  ) => void
  renderDayComponent?: (date: Date, modifiers: DayModifiers) => React.ReactNode
  selectedDays?: Modifier | Modifier[]
}

const MultiRangeCalendarDayPickerWrapper: React.FC<MultiRangeCalendarDayPickerWrapperProps> = ({
  modifiers,
  month,
  onDayClick,
  onDayMouseEnter,
  renderDayComponent,
  selectedDays,
}) => (
  <DayPicker
    canChangeMonth={false}
    disabledDays={[{ daysOfWeek: [0, 6] }]}
    firstDayOfWeek={1}
    modifiers={modifiers}
    month={month}
    numberOfMonths={1}
    renderDay={renderDayComponent}
    selectedDays={selectedDays}
    onDayClick={onDayClick}
    onDayMouseEnter={onDayMouseEnter}
  />
)

export default React.memo(MultiRangeCalendarDayPickerWrapper)
