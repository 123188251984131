import moment from 'moment'
import _ from 'lodash'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { VIEW_MODE } from 'services/legacy/membershipsShifts/constants'
import {
  AVAILABLE_DAYS,
  SHIFT_MAIN_TYPES,
  SHIFT_MAIN_TYPES_LABELS,
  WORK_SHIFTS,
  WORK_SHIFT_BREAK_PARENT_TYPES,
  WORK_SHIFT_TYPES,
} from 'services/legacy/membershipRegisters/constants'
import { LEAVE_SHIFT_TYPE_ORDER } from 'services/legacy/membershipsLeaves/constants'

import { convertTimeDuration, millisecondsToHoursAndMinutesString } from 'utils/date'
import { sortByKey } from 'utils/data'

import { isShiftBreak } from 'module/Staff/StaffHelpers'

import { Banner, EmptyState, InfiniteScroll, Space, Table, Typography } from 'components'
import { TimeCallout } from 'module/Staff/components'

import i18n from 'translations'

import { COLUMNS_WIDTHS } from '../../StaffRotaView'
import ChartRota from '../ChartRota'
import StaffingFlags from '../StaffingFlags'
import { StyledTableWrapper } from '../../StaffRotaStyled'
import { StyledHeadTr, StyledHeaderWrapper, StyledItemBox, StyledShiftTimesWrapper } from './WeekModeStyled'

const MAIN_SHIFT_TYPE_ORDER = {
  [SHIFT_MAIN_TYPES.WORK]: 1,
  [SHIFT_MAIN_TYPES.LEAVE]: 2,
}

const WORK_SHIFT_TYPE_ORDER = {
  [WORK_SHIFT_TYPES.CONTRACTED]: 1,
  [WORK_SHIFT_TYPES.OVERTIME]: 2,
  [WORK_SHIFT_TYPES.BREAK]: 3,
}

const WeekMode = ({
  authAccessMap,
  date,
  day,
  displayPastWeekBanner,
  earliestTime,
  isFetching,
  latestTime,
  onChangePage,
  onEditShift,
  page,
  pageCount,
  recordContent,
  records,
  room,
  statistics,
}) => {
  const shiftNames = {}

  const showChart = (_.isInteger(+room) && 0 !== room)

  const renderTableHeader = (isSticky) => {
    const nurseryIsClosed = (dayIndex) => {
      const dateDay = moment(date).add(dayIndex, 'day')
      const stats = statistics[dateDay.format(DEFAULT_DATE_FORMAT)]?.[0]

      return stats?.closed
    }

    return (
      <StyledHeadTr isSticky={isSticky}>
        <Table.Th background={NEUTRAL_COLOURS.WHITE} />
        {_.times(AVAILABLE_DAYS, (index) => (
          <Table.Th align="left" background={NEUTRAL_COLOURS.WHITE} key={index}>
            <React.Fragment>
              <Typography transform="uppercase" variant="span" bold>
                {moment(date).add(index, 'days').format('ddd')}
              </Typography>
              <Space margin="0 5px 0 0" inline />
              <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={17} variant="span">
                {moment(date).add(index, 'days').format('DD')}
              </Typography>
              {!isSticky && (
                nurseryIsClosed(index) ? (
                  <React.Fragment>
                    <Space margin="3px 0 0" />
                    <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
                      {i18n.t('module:Staff:StaffRota:nurseryClosed')}
                    </Typography>
                  </React.Fragment>
                ) : (
                  <Space margin="19px 0 0" />
                )
              )}
            </React.Fragment>
          </Table.Th>
        ))}
      </StyledHeadTr>
    )
  }

  const renderMembershipShiftTimes = (groupOfShifts, mainType, recordId, dayIndex) => {
    const currentDay = moment(date).add(dayIndex, 'day').format(DEFAULT_DATE_FORMAT)
    let i = 0

    return _.map(groupOfShifts, (shiftTimesGroupedByClass, type) => {
      i += 1

      return (
        <React.Fragment key={`shiftTime_${recordId}_${currentDay}_${i}`}>
          {_.keys(groupOfShifts)[0] !== type && (
            <Space space="15px" />
          )}
          <Typography fontSize={12} margin="5px 0 0" maxWidth={100} bold ellipsis>
            {mainType === SHIFT_MAIN_TYPES.WORK && WORK_SHIFTS[type]?.label}
            {mainType === SHIFT_MAIN_TYPES.LEAVE && shiftNames[type]}
          </Typography>
          {_.map(shiftTimesGroupedByClass, (shiftTimes, nurseryClassId) => (
            <StyledShiftTimesWrapper
              first={_.keys(shiftTimesGroupedByClass)[0] === nurseryClassId}
              key={`shiftTime_${recordId}_${currentDay}_${nurseryClassId}`}
            >
              {_.map(shiftTimes, ({ endTime, id, startTime, type: shiftType }) => {
                if (WORK_SHIFT_TYPES.BREAK_DURATION === shiftType) {
                  return (
                    <Typography fontSize={14} key={`shiftTime_${id}`}>
                      {convertTimeDuration(endTime, 'milliseconds', 'minutes')}
                      {' '}
                      {i18n.t('global:min')}
                    </Typography>
                  )
                }

                return (
                  <Typography fontSize={14} key={`shiftTime_${id}`}>
                    {moment(startTime).utc().format('HH:mm')}
                    {' - '}
                    {moment(endTime).utc().format('HH:mm')}
                  </Typography>
                )
              })}
              {shiftTimes[0]?.nurseryClass?.name && (
                <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={12} margin="5px 0 0" bold>
                  {shiftTimes[0].nurseryClass.name}
                </Typography>
              )}
            </StyledShiftTimesWrapper>
          ))}
        </React.Fragment>
      )
    })
  }

  const getGroupedShifts = (items, mainType, dayIndex, orderList) => {
    let result

    result = _.concat(
      ..._.map(items, ({ leaveShiftType, membershipShiftTimes }) => (
        _.map(membershipShiftTimes, (item) => {
          if (SHIFT_MAIN_TYPES.WORK === mainType) {
            return item
          }

          shiftNames[leaveShiftType?.code] = leaveShiftType?.name

          return ({
            ...item,
            type: leaveShiftType?.code,
          })
        })
      )),
    )

    // Backend use `class` name for nursery class, but `class` in JS is reserved word and it corrupts code
    result = _.map(result, ({ class: nurseryClass, ...rest }) => ({
      ...rest,
      nurseryClass,
    }))

    result = _.filter(result, (({ day: shiftTimeDay }) => (
      moment(date).add(dayIndex, 'day').format(DEFAULT_DATE_FORMAT)
      === moment(shiftTimeDay).format(DEFAULT_DATE_FORMAT)
    )))

    result = _.groupBy(result, ({ type }) => (
      isShiftBreak(type)
        ? WORK_SHIFT_BREAK_PARENT_TYPES
        : type
    ))

    result = sortByKey(result, orderList)

    result = _.mapValues(result, (groupOfShiftTimes) => (
      _.groupBy(groupOfShiftTimes, ({
        startTime,
        type,
      }) => (isShiftBreak(type)
        ? WORK_SHIFT_BREAK_PARENT_TYPES
        : startTime))
    ))

    return result
  }

  const renderRecordContent = (record, dayIndex) => {
    let { shifts } = record
    const { id: recordId } = record
    let totalDaySummary = 0

    let mainGroupOfShifts = _.groupBy(shifts, 'type') || {}

    mainGroupOfShifts[SHIFT_MAIN_TYPES.WORK] = getGroupedShifts(
      mainGroupOfShifts[SHIFT_MAIN_TYPES.WORK],
      SHIFT_MAIN_TYPES.WORK,
      dayIndex,
      WORK_SHIFT_TYPE_ORDER,
    )
    mainGroupOfShifts[SHIFT_MAIN_TYPES.LEAVE] = getGroupedShifts(
      mainGroupOfShifts[SHIFT_MAIN_TYPES.LEAVE],
      SHIFT_MAIN_TYPES.LEAVE,
      dayIndex,
      LEAVE_SHIFT_TYPE_ORDER,
    )

    mainGroupOfShifts = sortByKey(mainGroupOfShifts, MAIN_SHIFT_TYPE_ORDER)

    shifts = _.map(shifts, ({ membershipShiftTimes, ...rest }) => ({
      ...rest,
      membershipShiftTimes: _.filter(membershipShiftTimes, (({ day: shiftTimeDay }) => (
        moment(date).add(dayIndex, 'day').format(DEFAULT_DATE_FORMAT)
        === moment(shiftTimeDay).format(DEFAULT_DATE_FORMAT)
      ))),
    }))

    _.each(shifts, ({ membershipShiftTimes }) => {
      _.each(membershipShiftTimes, ({ endTime, startTime }) => {
        totalDaySummary += (endTime - startTime)
      })
    })

    if (0 > totalDaySummary) {
      totalDaySummary = 0
    }

    return (
      <StyledItemBox>
        {!totalDaySummary && (
          <Typography margin="2px 4px 6px">
            {`0${i18n.t('global:hourShortcut')}`}
          </Typography>
        )}
        {_.map(mainGroupOfShifts, (groupOfShifts, mainType) => {
          let summary = 0

          _.each(groupOfShifts, (shiftsByNurseryClass) => {
            _.each(shiftsByNurseryClass, (shiftTimes) => {
              _.each(shiftTimes, ({ endTime, startTime, type }) => {
                if (SHIFT_MAIN_TYPES.WORK === mainType) {
                  if (isShiftBreak(type)) {
                    summary -= (endTime - startTime)
                  } else {
                    summary += (endTime - startTime)
                  }
                }

                if (SHIFT_MAIN_TYPES.LEAVE === mainType) {
                  summary += (endTime - startTime)
                }
              })
            })
          })

          return (
            <TimeCallout
              content={renderMembershipShiftTimes(groupOfShifts, mainType, recordId, dayIndex)}
              header={(
                <StyledHeaderWrapper>
                  <Typography fontSize={12} bold>
                    {SHIFT_MAIN_TYPES_LABELS[mainType]}
                  </Typography>
                  <Typography fontSize={12} bold>
                    {millisecondsToHoursAndMinutesString(summary)}
                  </Typography>
                </StyledHeaderWrapper>
              )}
              key={mainType}
              type={mainType}
            />
          )
        })}
      </StyledItemBox>
    )
  }

  const renderTbody = () => {
    if (!records?.length) {
      return (
        <Table.Tbody>
          {showChart && (
            <React.Fragment>
              <ChartRota
                date={date}
                day={day}
                earliestTime={earliestTime}
                latestTime={latestTime}
                room={room}
                statistics={statistics}
                viewMode={VIEW_MODE.WEEK}
              />
              <StaffingFlags
                date={date}
                day={day}
                statistics={statistics}
                viewMode={VIEW_MODE.WEEK}
              />
            </React.Fragment>
          )}
          <Table.Tr>
            <Table.Td colSpan={AVAILABLE_DAYS + 1}>
              <EmptyState
                icon="staff-rota"
                text1={i18n.t('module:Staff:StaffRota:emptyState')}
              />
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      )
    }

    return (
      <Table.Tbody>
        {showChart ? (
          <React.Fragment>
            <ChartRota
              date={date}
              day={day}
              earliestTime={earliestTime}
              latestTime={latestTime}
              room={room}
              statistics={statistics}
              viewMode={VIEW_MODE.WEEK}
            />
            <StaffingFlags
              date={date}
              day={day}
              statistics={statistics}
              viewMode={VIEW_MODE.WEEK}
            />
          </React.Fragment>
        ) : null}
        {_.map(records, recordContent((record) => (
          _.times(AVAILABLE_DAYS, (index) => (
            <Table.Td
              align="left"
              key={index}
              verticalAlign="top"
              width={`${COLUMNS_WIDTHS.RIGHT / AVAILABLE_DAYS}%`}
              onClick={authAccessMap.section.isStaffRotaWritePermissionGrated
                ? () => onEditShift(record)
                : null}
            >
              {renderRecordContent(record, index)}
            </Table.Td>
          ))
        )))}
      </Table.Tbody>
    )
  }

  const renderBanner = () => {
    if (
      (
        moment(date).week() >= moment().week()
        && moment(date).year() === moment().year()
      )
      || moment(date).year() > moment().year()
      || !displayPastWeekBanner
    ) {
      return null
    }

    return (
      <Banner.Warning margin="20px 0 0">
        {i18n.t('module:Staff:StaffRota:pastWeekBanner')}
      </Banner.Warning>
    )
  }

  return (
    <React.Fragment>
      {renderBanner()}
      <InfiniteScroll
        dataLength={records?.length || 0}
        hasMore={page < pageCount}
        isFetching={isFetching}
        next={() => onChangePage((+page) + 1)}
      >
        <StyledTableWrapper overflowPolyfill={showChart}>
          <Table
            minWidth="800"
            renderHeader={renderTableHeader}
            visualType="border"
            disableOverflowPolyfill
          >
            {renderTbody()}
          </Table>
        </StyledTableWrapper>
      </InfiniteScroll>
    </React.Fragment>
  )
}

export default WeekMode
