import { generateBasicActions } from 'utils/service'

import {
  REMOVE_NURSERY_FILE,
  REMOVE_NURSERY_FILE_FAILED,
  REMOVE_NURSERY_FILE_SUCCESS,
  UPDATE_NURSERY_FILE,
  UPDATE_NURSERY_FILE_FAILED,
  UPDATE_NURSERY_FILE_SUCCESS,
} from './constants'

export const removeNurseryFile = (options = {}) => ({ filesApiClient }) => generateBasicActions.remove({
  constants: {
    failed: REMOVE_NURSERY_FILE_FAILED,
    init: REMOVE_NURSERY_FILE,
    success: REMOVE_NURSERY_FILE_SUCCESS,
  },
  options,
  service: filesApiClient,
  serviceMethod: 'removeNurseryFile',
})

export const updateNurseryFile = (options = {}) => ({ filesApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_NURSERY_FILE_FAILED,
    init: UPDATE_NURSERY_FILE,
    success: UPDATE_NURSERY_FILE_SUCCESS,
  },
  options,
  service: filesApiClient,
  serviceMethod: 'updateNurseryFile',
})
