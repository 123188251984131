import _ from 'lodash'

import React, { memo } from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Currency, Typography } from 'components'

import { StyledWrapper } from './PriceStatisticStyled'

const PriceStatistic = ({ label, statistic }) => (
  <StyledWrapper>
    <Currency fontSize={22} value={statistic || 0} bold />
    <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} margin="5px 0 0 0">
      {_.upperCase(label)}
    </Typography>
  </StyledWrapper>
)

export default memo(PriceStatistic)
