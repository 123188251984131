import { generateBasicActions } from 'utils/service'

import { CLEAR_STAFF_LOGS, LIST_STAFF_LOGS, LIST_STAFF_LOGS_FAILED, LIST_STAFF_LOGS_SUCCESS } from './constants'

export const list = (options = {}) => ({ staffLogsApiClient }) => generateBasicActions.list({
  constants: {
    failed: LIST_STAFF_LOGS_FAILED,
    init: LIST_STAFF_LOGS,
    success: LIST_STAFF_LOGS_SUCCESS,
  },
  options,
  service: staffLogsApiClient,
  serviceMethod: 'list',
})

export const clear = () => ({
  type: CLEAR_STAFF_LOGS,
})
