import _ from 'lodash'

import { createSelector } from 'reselect'

import { OTHER_OPTION } from 'services/users/constants'

import { hasOnlySeniorTeacherOrTeacherAccess } from 'services/security/selectors'

import i18n from 'translations'

import { generateValidationErrorMsg } from '../single/selectors'

import {
  INJURIES,
  INJURIES_STATUS,
  OTHER_SIGNATURE_TYPES,
  SIGNATURE_COLLECTING_TYPES,
  SIGNATURE_LABEL_MAPPINGS,
  SIGNATURE_STAFF_MEMBER_LABEL_MAPPINGS,
  SIGNATURE_STAFF_MEMBER_TYPES,
  SIGNATURE_TYPES,
} from '../constants'

const getInjuries = (state) => state.injuries

export const getInjurySignatures = createSelector([getInjuries], (state) => state.signatures.data)

export const injurySignatureIsFetching = createSelector([getInjuries], (state) => state.signatures.isFetching)
export const injurySignatureIsSubmitting = createSelector([getInjuries], (state) => state.signatures.isSubmitting)

const getSignatureAdditionalData = ({ additionalType, id, owner, type }, signatures, signatureCollectingType) => {
  const { child, firstAider, name } = owner
  const { firstName: childFirstName } = child || {}
  const { name: firstAiderFirstName } = firstAider || {}

  if (type === SIGNATURE_TYPES.WITNESS || type === OTHER_SIGNATURE_TYPES.OTHER_WITNESS) {
    const witnessIndex = _.findIndex(
      _.filter(signatures, (item) => item.type === SIGNATURE_TYPES.WITNESS || OTHER_SIGNATURE_TYPES.OTHER_WITNESS),
      { id },
    )

    return {
      label: `${SIGNATURE_LABEL_MAPPINGS[SIGNATURE_TYPES.WITNESS]} ${witnessIndex + 1}`,
      name: type === OTHER_SIGNATURE_TYPES.OTHER_WITNESS ? owner : name,
    }
  }

  if (type === SIGNATURE_TYPES.STAFF_MEMBER || type === OTHER_SIGNATURE_TYPES.OTHER_STAFF_MEMBER) {
    const finalName = type === OTHER_SIGNATURE_TYPES.OTHER_STAFF_MEMBER ? owner : name

    if (additionalType === SIGNATURE_STAFF_MEMBER_TYPES.SENIOR) {
      return {
        label: SIGNATURE_STAFF_MEMBER_LABEL_MAPPINGS[SIGNATURE_STAFF_MEMBER_TYPES.SENIOR],
        name: finalName,
      }
    }

    return {
      label: SIGNATURE_STAFF_MEMBER_LABEL_MAPPINGS[SIGNATURE_STAFF_MEMBER_TYPES.SUPERVISOR],
      name: finalName,
    }
  }

  if (type === SIGNATURE_TYPES.FIRSTAID) {
    return {
      label: `${SIGNATURE_LABEL_MAPPINGS[SIGNATURE_TYPES.FIRSTAID]} for ${childFirstName}`,
      name: firstAiderFirstName,
    }
  }

  // Default for parent
  if (SIGNATURE_COLLECTING_TYPES.DIGITALLY === signatureCollectingType) {
    return { label: i18n.t('module:Injury:Preview:Signatures:carerLabel', { childFirstName }) }
  }

  return { name: i18n.t('module:Injury:Preview:Signatures:carerLabel', { childFirstName }) }
}

const getSignatureCarer = ({ carer, otherCarer }) => {
  if (otherCarer) {
    return {
      ...carer,
      label: OTHER_OPTION.label,
      otherCarer,
      value: OTHER_OPTION.value,
    }
  }

  if (!carer) {
    return null
  }

  if (carer?.value) {
    return carer
  }

  return {
    ...carer,
    avatar: carer.photo,
    label: carer.fullName,
    value: carer.id,
  }
}

const getSignatureOwner = ({ owner, type }) => {
  if (!owner) {
    return null
  }

  if (
    type === OTHER_SIGNATURE_TYPES.OTHER_STAFF_MEMBER
    || type === OTHER_SIGNATURE_TYPES.OTHER_WITNESS
    || owner?.value
  ) {
    return owner
  }

  return {
    ...owner,
    avatar: owner.photo,
    label: owner.name,
    value: owner.id,
  }
}

const isDisabled = (status, signatureType, signatureCollectingType) => (!!(
  SIGNATURE_COLLECTING_TYPES.DIGITALLY === signatureCollectingType
  && SIGNATURE_TYPES.PARENT === signatureType
  && status !== INJURIES_STATUS.APPROVED_AND_SIGNED.value
  && status !== INJURIES_STATUS.SIGNATURES_NEEDED.value
))

const getSignatures = (signatures, signatureCollectingType, status) => _.map(signatures, (signature) => {
  const additionalData = getSignatureAdditionalData(signature, signatures, signatureCollectingType)
  const carer = getSignatureCarer(signature)
  const owner = getSignatureOwner(signature)

  return {
    ...signature,
    additionalData,
    carer,
    disabled: isDisabled(status, signature.type, signatureCollectingType),
    owner,
  }
})

export const getSignatureTotal = (injurySignatureStatistics, type) => _.reduce(
  injurySignatureStatistics,
  (prevValue, currentValue, signatureType) => {
    const newSignatureTotal = {
      all: prevValue.all + currentValue.all,
      signed: prevValue.signed + currentValue.signed,
    }

    if (type === INJURIES.HOME_ACCIDENT && signatureType === SIGNATURE_TYPES.WITNESS) {
      newSignatureTotal.all = prevValue.all + (2 >= currentValue.all ? 2 : currentValue.all)
    }

    return newSignatureTotal
  },
  { all: 0, signed: 0 },
)

export const getStaffMembers = (signatureItem, currentItem, option) => {
  const selectedItem = _.map(signatureItem, (item) => {
    if (item.uid === currentItem.uid && item.id === currentItem.id) {
      return {
        ...item,
        owner: { id: option.value },
      }
    }

    return item
  })

  return _.map(selectedItem, (item) => {
    if (item.owner.id) {
      return { id: item.owner.id }
    }

    return null
  })
}

export const getSignaturesPreview = createSelector(
  [getInjurySignatures],
  (injury) => {
    if (!injury) {
      return null
    }

    const { injurySignatureStatistics, signatureCollectingType, signatures, status, type } = injury
    return {
      ...injury,
      signatureTotal: getSignatureTotal(injurySignatureStatistics, type),
      signatures: getSignatures(signatures, signatureCollectingType, status),
    }
  },
)

export const getCreateSignatureCarerPayload = ({ carer, injury, owner, type }) => ({
  carer: { id: carer },
  injury: { id: Number(injury) },
  owner: { id: owner },
  type,
})

export const getSignaturePayload = createSelector(
  [(values) => values],
  (values) => {
    if (!values) {
      return null
    }

    const {
      injuryId,
      signature,
      signatureCollectingType,
      signatureItem,
      toggleValue,
    } = values
    const { carer, otherCarer, owner, type, uid } = signatureItem

    if (signatureCollectingType === SIGNATURE_COLLECTING_TYPES.ON_PAPER) {
      return {
        signedOnPaper: toggleValue,
        type,
      }
    }

    const digitalCorePayload = {
      carer: carer?.value !== OTHER_OPTION.value ? { id: carer?.value } : null,
      otherCarer: !carer?.id ? otherCarer : undefined,
      signature,
      signedDigitally: !!signature,
      type,
    }

    if (uid) { // for new carer record
      return {
        ...digitalCorePayload,
        injury: injuryId ? { id: injuryId } : undefined,
        owner: owner?.id ? { id: owner.id } : undefined,
      }
    }

    return digitalCorePayload
  },
)

export const getSignatureItem = (signatureId, signatureUid) => createSelector(
  [getSignaturesPreview],
  (injuryPreview) => {
    if (!injuryPreview) {
      return null
    }

    const { signatures } = injuryPreview

    if (signatureUid) {
      return _.find(signatures, { uid: signatureUid })
    }

    return _.find(signatures, { id: signatureId })
  },
)

export const getSignatureSerializationGroups = (signatureCollectingType) => {
  if (SIGNATURE_COLLECTING_TYPES.ON_PAPER === signatureCollectingType) {
    return {
      read: [
        'injury.injurySignatureStatistics',
        'injury.signatures',
        'injury.signatureDocuments',
        'medium',
        'injury.signatures',
        'injurySignature',
        'injurySignature.signature',
        'injurySignature.owner',
        'injurySignature.carer',
        'carer',
        'injured.child',
        'child',
        'injured.firstAider',
        'user',
      ],
    }
  }

  return {
    read: [
      'injury.injurySignatureStatistics',
      'injury.signatures',
      'injurySignature',
      'injurySignature.signature',
      'medium',
      'injurySignature.owner',
      'injurySignature.carer',
      'carer',
      'injured.child',
      'child',
      'injured.firstAider',
      'user',
    ],
  }
}

export const getSignaturesValidationErrorMsg = createSelector(
  [getInjuries],
  (state) => generateValidationErrorMsg(state.signatures),
)

export const isGrantedToUpdateSignature = createSelector(
  [hasOnlySeniorTeacherOrTeacherAccess, getSignaturesPreview],
  (hasOnlySeniorTeacherOrTeacher, injuryPreview) => {
    const { status } = injuryPreview || {}

    return hasOnlySeniorTeacherOrTeacher
      ? status === INJURIES_STATUS.APPROVED_AND_SIGNED.value
      : false
  },
)
