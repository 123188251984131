import React, { PropsWithChildren } from 'react'

import { StyledRow } from './DividedListStyled'

const DividedListRow: React.FC<PropsWithChildren<any>> = ({ children }) => (
  <StyledRow>
    {children}
  </StyledRow>
)

export default DividedListRow
