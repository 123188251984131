import React, { PropsWithChildren, useState } from 'react'
import { LazyComponentProps, LazyLoadImage } from 'react-lazy-load-image-component'

import { noop } from 'constants/global'

interface ImageProps extends LazyComponentProps {
  errorComponent: React.ReactElement
  errorImage?: string
  onLoaded?: (value?: boolean) => {}
  src?: string
}

const Image: React.FC<PropsWithChildren<ImageProps>> = ({
  errorImage,
  errorComponent,
  src,
  onLoaded = noop,
  ...rest
}) => {
  const [imageIsCorrect, setImageIsCorrect] = useState<boolean>(true)
  const image = new window.Image()

  image.onload = () => {
    setImageIsCorrect(true)
    onLoaded(true)
  }

  image.onerror = () => {
    setImageIsCorrect(false)
  }

  if (null === src && imageIsCorrect) {
    setImageIsCorrect(false)
  }

  if (null !== src) {
    image.src = src
  }

  if (errorComponent && !imageIsCorrect) {
    return errorComponent
  }

  return (
    <LazyLoadImage
      src={imageIsCorrect ? src : (errorImage || src)}
      {...rest}
    />
  )
}

export default Image
