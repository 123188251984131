import _ from 'lodash'

import React from 'react'

import { Avatar, ProfileHeader } from 'components'

import i18n from 'translations'

import ChildHeaderSetup from './ChildHeaderSetup'
import { getMenuItems } from './helpers'

const DEFAULT_PHOTO_URL = '/images/placeholders/avatar.jpg'

const ChildHeader = ({
  authAccessMap,
  child,
  hasChildMoves,
  hasRelations,
  hasSessions,
  isFinanceV3Enabled,
}) => {
  const {
    ageMonths,
    firstName,
    id,
    keyWorkers,
    name,
    nurseryClass,
    photo,
    surname,
  } = child
  const { name: roomName } = nurseryClass || {}
  const keyWorker = keyWorkers && keyWorkers[0]

  const renderChildDetails = () => (
    <ProfileHeader.Details>
      <ProfileHeader.Details.Left
        subtitle={i18n.t('global:ageMonths', { ageMonths })}
        title={name}
      />
      <ProfileHeader.Details.Middle />
      <ProfileHeader.Details.Right>
        <ChildHeaderSetup
          authAccessMap={authAccessMap}
          childId={id}
          hasChildMoves={hasChildMoves}
          hasRelations={hasRelations}
          hasSessions={hasSessions}
          isFinanceV3Enabled={isFinanceV3Enabled}
        />
        <ProfileHeader.WhiteBox
          left={() => (
            <Avatar
              avatarSize="medium"
              initials={
                keyWorker?.initials
                  ? [keyWorker.initials[0], keyWorker.initials[1]]
                  : null
              }
              src={keyWorker?.photo || DEFAULT_PHOTO_URL}
            />
          )}
          title={_.startCase(i18n.t('global:KeyPerson'))}
          value={keyWorker?.fullName || '-'}
        />
        <ProfileHeader.WhiteBox
          title={i18n.t('global:Room')}
          value={roomName}
        />
      </ProfileHeader.Details.Right>
    </ProfileHeader.Details>
  )

  const menuItems = getMenuItems(authAccessMap, id)

  const renderMenu = () => <ProfileHeader.Menu menuItems={menuItems} />

  return (
    <ProfileHeader>
      <ProfileHeader.Top>
        <ProfileHeader.Avatar initials={[firstName, surname]} src={photo} />
        {renderChildDetails()}
      </ProfileHeader.Top>
      <ProfileHeader.Bottom>{renderMenu()}</ProfileHeader.Bottom>
    </ProfileHeader>
  )
}

export default ChildHeader
