import { generateBasicActions } from 'utils/service'

import { DROPDOWN_LIST_RELATIONS, DROPDOWN_LIST_RELATIONS_FAILED, DROPDOWN_LIST_RELATIONS_SUCCESS } from './constants'

export const dropdownList = (options = {}) => ({ relationsApiClient }) => generateBasicActions.list({
  constants: {
    failed: DROPDOWN_LIST_RELATIONS_FAILED,
    init: DROPDOWN_LIST_RELATIONS,
    success: DROPDOWN_LIST_RELATIONS_SUCCESS,
  },
  options,
  service: relationsApiClient,
  serviceMethod: 'list',
})

