/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable eqeqeq */

const circular = /\[Circular \((.+)\)\]/
const nestedRe = /(\.|\[)/
const scrub = /]/g

export const seek = (obj, path) => {
  const seekPath = path.replace(scrub, '')

  const pathBits = seekPath.split(nestedRe)
  const len = pathBits.length
  let layer = obj

  for (let i = 0; i < len; i += 2) {
    if (null == layer) return undefined

    const key = pathBits[i]
    layer = layer[key]
  }

  return layer
}

export const flatten = (obj) => {
  const flattened = {}
  const circlular = []
  const circLoc = []

  function _route(prefix, value) {
    let i
    let len
    let keys
    let circularCheck
    let loc

    if (null == value) {
      if ('' === prefix) {
        return
      }
      flattened[prefix] = null
      return
    }

    if ('object' === typeof value) {
      circularCheck = circlular.indexOf(value)

      if (0 <= circularCheck) {
        loc = circLoc[circularCheck] || 'this'
        flattened[prefix] = `[Circular (${loc})]`
        return
      }

      circlular.push(value)
      circLoc.push(prefix)

      if (Array.isArray(value)) {
        len = value.length

        if (0 == len) _route(`${prefix}[]`, null)

        for (i = 0; i < len; i++) {
          _route(`${prefix}[${i}]`, value[i])
        }

        return
      }

      keys = Object.keys(value)
      len = keys.length

      // eslint-disable-next-line no-param-reassign
      if (prefix) prefix += '.'
      if (0 == len) _route(prefix, null)

      for (i = 0; i < len; i++) {
        _route(prefix + keys[i], value[keys[i]])
      }

      return
    }

    flattened[prefix] = value
  }

  _route('', obj)

  return flattened
}

const insert = (target, path, value) => {
  const insertPath = path.replace(scrub, '')

  const pathBits = insertPath.split(nestedRe)
  let parent = target
  const len = pathBits.length
  for (let i = 0; i < len; i += 2) {
    const key = pathBits[i]
    // eslint-disable-next-line no-continue
    if ('__proto__' === key) continue
    // eslint-disable-next-line no-continue
    if ('constructor' === key && 'function' == typeof target[key]) continue
    const type = pathBits[i + 1]

    if (null == type && key) parent[key] = value
    if ('.' == type && null == parent[key]) parent[key] = {}
    if ('[' == type && null == parent[key]) parent[key] = []

    parent = parent[key]
  }
}

export const nest = (obj) => {
  let key
  let i
  const nested = {}

  const keys = Object.keys(obj)
  const len = keys.length
  for (i = 0; i < len; i++) {
    key = keys[i]

    if ('string' == typeof obj[key] && circular.test(obj[key])) {
      const ref = circular.exec(obj[key])[1]
      if ('this' == ref) obj[key] = nested
      else obj[key] = seek(nested, ref)
    }
    insert(nested, key, obj[key])
  }

  return nested
}

