import _ from 'lodash'
import { flatten } from 'utils/flatnest'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { getBrandingColor } from 'utils/branding'

import { Currency, ReportView } from 'components'

import i18n from 'translations'

export const getChart = (agedDebtSummary) => {
  if (!agedDebtSummary) {
    return null
  }

  const { categories, series } = agedDebtSummary
  const firstStepColor = getBrandingColor('primary-color-lighten-20')
  const secondStepColor = getBrandingColor('primary-color')

  return {
    categories,
    chartData: [{
      color: {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1,
        },
        stops: [
          [0, firstStepColor],
          [1, secondStepColor],
        ],
      },
      data: series,
      dataLabels: {
        style: { color: NEUTRAL_COLOURS.GRAY },
      },
      name: i18n.t('module:Finance:Reports:AgedDebt:chartTooltipTitle'),
    }],
  }
}

export const getGroupTableData = ({ data, filters }) => {
  if (!data?.length) {
    return null
  }

  const queryString = new URLSearchParams(flatten({
    date: filters?.date ? filters.date.format(DEFAULT_DATE_FORMAT) : undefined,
  })).toString()

  return _.map(data, (nursery) => {
    const { agedDebtStatistics, id } = nursery
    const { agedDebts, total } = agedDebtStatistics

    return {
      '0-30': <Currency value={agedDebts['0-30']} />,
      '120+': <Currency value={agedDebts['120+']} />,
      '31-60': <Currency value={agedDebts['31-60']} />,
      '61-90': <Currency value={agedDebts['61-90']} />,
      '91-120': <Currency value={agedDebts['91-120']} />,
      id,
      name: <ReportView.NurseryLink
        nursery={nursery}
        queryString={queryString}
        routeKey="FINANCE.REPORT.AGED_DEBT"
      />,
      total: <Currency value={total} />,
    }
  })
}
