import React from 'react'

import { Avatar } from 'components'

import { StyledAvatar, StyledAvatarContainer } from './AvatarWithLabelStyled'

const AvatarWithLabel = ({
  initials,
  label,
  name,
  src,
}) => (
  <StyledAvatarContainer>
    {label}
    <StyledAvatar label={label || name}>
      <Avatar
        avatarSize="small"
        initials={initials}
        src={src}
        variant="avatar"
      />
    </StyledAvatar>
    {name}
  </StyledAvatarContainer>
)

export default AvatarWithLabel
