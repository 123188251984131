import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { StyledDetails } from 'components/Pagination/PaginationStyled'
import TableTr from 'components/Table/TableTr'

export const StyledPagination = styled.div`
  ${StyledDetails} {
    display: flex;
    align-items: center;
    font-weight: 400;
  }
`

export const StyledTableWrapper = styled.div`
  padding: 4px;
  
  @media(max-width: 1140px) {
    overflow-x: auto;
    overflow-y: hidden;
  }
  
  ${({ overflowPolyfill }) => overflowPolyfill && `
    padding-top: 80px;
    margin-top: -80px;
  `}
  
  table {
    width: 100%;
  }
`

export const StyledWeek = styled(TableTr)`
  &:hover {
    & td:not(:nth-of-type(1)) {
      cursor: pointer;
      background: #F5F5F5;
      transition: .2s;
    }
  }
`

export const StyledHeadTr = styled.tr`
  ${({ isSticky }) => isSticky && `
    th {
      border-bottom: 2px solid ${NEUTRAL_COLOURS.GRAY_SECONDARY};
      border-left: 2px solid ${NEUTRAL_COLOURS.GRAY_SECONDARY};
      border-right: 2px solid ${NEUTRAL_COLOURS.GRAY_SECONDARY};
      background: ${NEUTRAL_COLOURS.WHITE};
      padding-top: 8px;
      
      &:nth-of-type(1) {
        border-left: 1px solid ${NEUTRAL_COLOURS.GRAY_SECONDARY};
      }
      
      &:nth-last-of-type(1) {
        border-right: 1px solid ${NEUTRAL_COLOURS.GRAY_SECONDARY};
      }
    }
  `}
`
