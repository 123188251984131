import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledRow = styled.div`
  margin-left: -2px;
  margin-top: -2px;

  .Select__control--is-focused {
    position: relative;
    z-index: 20;
  }

  ${({ border }) => border && `
    height: 44px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    border: 2px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  `}
  
  ${({ center }) => center && `
    display: flex;
    justify-content: center;
  `}
  
  .Select__control {
    width: auto !important;
    min-width: 0;
  }
`

export const StyledDay = styled.div`
  background: #FAFAFA;
  border: 2px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  padding: 10px;
  margin-left: -2px;
  margin-top: -2px;
  
  ${({ noBorder }) => noBorder && `
    border-top: none;
  `}
`

export const StyledCustomTableWrapper = styled.div`
  overflow-y: hidden;
  overflow-x: auto;
  padding-left: 2px;
  
  @media(max-width: 780px) {
    max-width: 82vw;
  }
`

export const StyledCustomTable = styled.div`
  margin-bottom: 7px;
  min-width: 440px;
`

export const StyledRowWrapper = styled.div`
  display: grid;
  grid-template-areas: 'day start end hours actions';
  grid-template-columns: 150px 88px 88px auto 40px;
`

export const StyledRowHeader = styled.div`
  padding: 10px;
  height: 45px;
    
  ${({ center }) => center && `
    width: 100%;
    display: flex;
    justify-content: center;
  `}
`
