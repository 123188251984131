import styled from 'styled-components'
import { Property } from 'csstype'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'
import { typeByObject } from 'utils/typescript'

export const SPINNER_SIZE = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
  STANDARD: 'standard',
} as const

interface StyledContainerProps {
  $position?: Property.JustifyContent
}

export const StyledContainer = styled.div<StyledContainerProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  
  ${({ $position }) => $position && `
    justify-content: ${$position};
  `}
`

export const StyledSpinnerAnimation = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  will-change: transform;
  animation: spinner-animation 600ms infinite linear;
  
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
  }
  
  &:before {
    border: .15em solid ${getBrandingColor('primary-color')};
    opacity: .15;
  }
  
  &:after {
    border: .15em solid transparent;
    border-left-color: ${getBrandingColor('primary-color')};
  }
  
  &,
  &:before,
  &:after {
    width: 1em;
    height: 1em;
  }

  @keyframes spinner-animation {
    0% {
      transform: rotate(0deg);
    }
    
    100% {
      transform: rotate(360deg);
    }
  }
`

interface StyledSpinnerProps {
  $hidden?: boolean
  $light?: boolean
  size: typeByObject<typeof SPINNER_SIZE>
}

export const StyledSpinner = styled.div<StyledSpinnerProps>`
  width: 1em;
  height: 1em;
  position: relative;
  color: ${getBrandingColor('primary-color')};
  display: inline-block;

  ${({ $hidden }) => $hidden && `
    display: none;
  `}

  ${({ $size }) => $size === SPINNER_SIZE.SMALL && `
    font-size: 16px;
  `}
  
  ${({ $size }) => $size === SPINNER_SIZE.MEDIUM && `
    font-size: 24px;
  `}
  
  ${({ $size }) => $size === SPINNER_SIZE.STANDARD && `
    font-size: 30px;
  `}

  ${({ $size }) => $size === SPINNER_SIZE.LARGE && `
    font-size: 40px;
  `}
  
  ${({ $light }) => $light && `
    & > div {
      &:before {
        border-color: ${getBrandingColor('primary-color')} !important;
        opacity: 0.4 !important;
      }
  
      &:after {
        border-left-color: ${NEUTRAL_COLOURS.WHITE} !important;
      }
    }
  `}
`
