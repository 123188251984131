import React from 'react'

import { Callout, Section, Spinner, Typography } from 'components'

import i18n from 'translations'

/* eslint-disable max-len */
/* eslint-disable import-newlines/enforce */
import ManagementNurseryEditForm
  from 'module/Management/ManagementNursery/ManagementNurseryEdit/components/ManagementNurseryEditForm'

/* eslint-enable max-len */
/* eslint-enable import-newlines/enforce */

const SettingsNurseryItemEditView = ({
  cancelLink,
  daysOptions,
  errorMessages,
  financeLocked,
  initialValues,
  isFormLoading,
  isLoading,
  isOrganizationNursery,
  nursery,
  onApplyAll,
  onSubmit,
  openPeriodOptions,
}) => {
  if (isLoading) {
    return <Spinner />
  }

  const { name } = nursery

  return (
    <Section title={name}>
      <Callout content={errorMessages} error />
      <Typography margin="20px 0">
        {i18n.t('module:Management:Nursery:subtitle')}
      </Typography>
      <ManagementNurseryEditForm
        cancelLink={cancelLink}
        daysOptions={daysOptions}
        financeLocked={financeLocked}
        initialValues={initialValues}
        isLoading={isFormLoading}
        isOrganizationNursery={isOrganizationNursery}
        openPeriodOptions={openPeriodOptions}
        showPrimaryManager={false}
        onApplyAll={onApplyAll}
        onSubmit={onSubmit}
      />
    </Section>
  )
}

export default SettingsNurseryItemEditView
