import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'
import { NEUTRAL_COLOURS } from 'constants/colors'
import {
  SHIFT_MAIN_TYPES,
  STAFF_ENTRY_TYPES,
  WORKLOG_RECORD_DAY_TYPE,
} from 'services/legacy/membershipRegisters/constants'

import { millisecondsToHoursAndMinutesString } from 'utils/date'
import { generateRoute } from 'utils/routing'

import { Avatar, Banner, Hyperlink, Page, Section, Space, Spinner, Typography } from 'components'

import i18n from 'translations'

import { getShiftByDay } from 'module/Staff/StaffHelpers'

import StaffWorklogPreviewForm from './components/StaffWorklogPreviewForm'
import PreviousRecordBanner from '../../components/PreviousRecordBanner'

import { StyledHeader, StyledStatistics } from './StaffWorklogPreviewStyled'

const StaffWorklogPreviewView = ({
  editMode,
  formEntries,
  initialValues,
  isEmpty,
  isFetching,
  isSubmitting,
  memberDetails,
  onAddActivity,
  onChangeEditMode,
  onClockOutButtonClick,
  onEndButtonClick,
  onOngoingEntryEndClick,
  onShowChangeLog,
  onSubmit,
  onUpdateEntryTotalTime,
  previousRecord,
  record,
  selectedDate,
  shifts,
  worklogDayType,
}) => {
  const { endDate: previousEndDate, startDate: previousStartDate } = previousRecord || {}
  const { firstName, id: membershipId, name, photo, roleLabels, surname = '' } = memberDetails || {}

  const formSummaryTotal = _.reduce(_.groupBy(formEntries, 'type'), (result, value, key) => {
    let newResult = result

    const totalTime = _.reduce(value, (total, { totalTime: entryTotalTime }) => total + entryTotalTime, 0)

    if (newResult[key]) {
      newResult = newResult[key] + totalTime
    } else {
      newResult[key] = totalTime
    }

    return newResult
  }, {})

  const summaryWorkedMilliseconds = formSummaryTotal[STAFF_ENTRY_TYPES.SIGN_IN] || 0
  const summaryBreakMilliseconds = formSummaryTotal[STAFF_ENTRY_TYPES.BREAK] || 0

  const renderHeader = () => (
    <StyledHeader>
      <Avatar
        gap={15}
        initials={[firstName, surname]}
        src={photo}
        subTitle={(
          <Typography color={NEUTRAL_COLOURS.GRAY}>
            {roleLabels}
          </Typography>
        )}
        title={(
          <Typography fontSize={24} bold>
            {name}
          </Typography>
        )}
      />

      <Typography fontSize={22} variant="span" bold>
        {moment(selectedDate).format('dddd')}
        {', '}
        <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={22} variant="span" medium>
          {moment(selectedDate).format(DISPLAY_SHORT_DATE_FORMAT)}
        </Typography>
        <Space space="10px" />
      </Typography>
    </StyledHeader>
  )

  const renderPreviousRecordBannerText = () => (
    <PreviousRecordBanner
      firstName={firstName}
      membershipId={membershipId}
      previousEndDate={previousEndDate}
      previousStartDate={previousStartDate}
      selectedDate={selectedDate}
      worklogDayType={worklogDayType}
    />
  )

  const renderLeaveGroup = (leaves, i, leavesGroupByCode) => {
    const { leaveShiftType: { name: leaveName } } = leaves?.[0] || {}
    let summary = 0

    const filteredLeaves = _.reduce(leaves, (result, value) => {
      const { membershipShiftTimes } = value

      const filteredShiftTimes = getShiftByDay({ day: selectedDate, membershipShiftTimes })

      if (!filteredShiftTimes?.length) {
        return result
      }

      return [...result, {
        ...value,
        membershipShiftTimes: filteredShiftTimes,
      }]
    }, [])

    const times = _.concat(..._.map(filteredLeaves, ({ membershipShiftTimes }) => (
      _.map(membershipShiftTimes, ({ endTime, startTime }) => {
        summary += (endTime - startTime)

        return (
          moment(startTime).format('HH:mm').concat(' - ').concat(moment(endTime).format('HH:mm'))
        )
      })
    ))).join(', ')

    const lastElement = _.keys(leavesGroupByCode).length - 1 === i ? ` ${i18n.t('global:and')} ` : ', '

    return (
      <React.Fragment>
        <Typography variant="span" bold>
          {millisecondsToHoursAndMinutesString(summary)}
        </Typography>
        {` (${times}) of ${leaveName}`}
        {_.keys(leavesGroupByCode).length !== i
          ? lastElement
          : ''}
      </React.Fragment>
    )
  }

  const renderShiftsBannerText = () => {
    const filteredLeaves = _.reduce(shifts, (result, value) => {
      const { membershipShiftTimes, type } = value

      if (type !== SHIFT_MAIN_TYPES.LEAVE) {
        return result
      }

      const filteredShiftTimes = getShiftByDay({ day: selectedDate, membershipShiftTimes })

      if (!filteredShiftTimes?.length) {
        return result
      }

      return [...result, {
        ...value,
        membershipShiftTimes: filteredShiftTimes,
      }]
    }, [])

    if (!filteredLeaves?.length) {
      return null
    }

    const leavesGroupByCode = _.groupBy(filteredLeaves, ({ leaveShiftType: { code } }) => code)

    let i = 0

    return (
      <React.Fragment>
        {i18n.t('module:Staff:StaffWorklogPreview:ShiftsBanner:namePart', { firstName })}
        {_.map(leavesGroupByCode, (items) => {
          i += 1

          return renderLeaveGroup(items, i, leavesGroupByCode)
        })}
        {i18n.t('module:Staff:StaffWorklogPreview:ShiftsBanner:suffixPart')}
        <Hyperlink
          to={generateRoute('STAFF.PROFILE.LEAVE', { userId: membershipId })}
          inline
          primary
        >
          {i18n.t('module:Staff:StaffWorklogPreview:ShiftsBanner:staffProfile')}
        </Hyperlink>
        {'.'}
      </React.Fragment>
    )
  }

  const renderStatistics = () => (
    <React.Fragment>
      {worklogDayType !== WORKLOG_RECORD_DAY_TYPE.SAME_DAY && (
        <React.Fragment>
          <Banner.Info>
            {renderPreviousRecordBannerText()}
          </Banner.Info>
          {!isEmpty && <Space space="40px" />}
        </React.Fragment>
      )}
      {!!shifts?.length && (
        <React.Fragment>
          <Banner.Info>
            {renderShiftsBannerText()}
          </Banner.Info>
          {!isEmpty && <Space space="40px" />}
        </React.Fragment>
      )}
      {!isEmpty && (
        <StyledStatistics>
          <div>
            <Typography variant="h5">
              {millisecondsToHoursAndMinutesString(summaryWorkedMilliseconds) || 0}
            </Typography>
            <Typography color={NEUTRAL_COLOURS.GRAY} transform="uppercase">
              {i18n.t('module:Staff:StaffWorklogPreview:worked')}
            </Typography>
          </div>
          <div>
            <Typography variant="h5">
              {millisecondsToHoursAndMinutesString(summaryBreakMilliseconds) || 0}
            </Typography>
            <Typography color={NEUTRAL_COLOURS.GRAY} transform="uppercase">
              {i18n.t('module:Staff:StaffWorklogPreview:break')}
            </Typography>
          </div>
          <div>
            <Typography variant="h5">
              {millisecondsToHoursAndMinutesString(summaryWorkedMilliseconds + summaryBreakMilliseconds) || 0}
            </Typography>
            <Typography color={NEUTRAL_COLOURS.GRAY} transform="uppercase">
              {i18n.t('module:Staff:StaffWorklogPreview:total')}
            </Typography>
          </div>
        </StyledStatistics>
      )}
    </React.Fragment>
  )

  const renderActions = () => {
    if (isFetching) {
      return null
    }

    if (editMode) {
      return (
        <Section.Actions
          secondary={{
            icon: 'plus',
            label: i18n.t('module:Staff:StaffWorklogPreview:addActivity'),
            onClick: onAddActivity,
          }}
        />
      )
    }

    return (
      <Section.Actions
        options={[{
          icon: 'change-log',
          label: i18n.t('module:Staff:StaffWorklogPreview:changeLog'),
          onClick: onShowChangeLog,
        }]}
        tertiary={{
          disabled: worklogDayType === WORKLOG_RECORD_DAY_TYPE.IN_BETWEEN_DAY,
          onClick: onChangeEditMode,
        }}
      />
    )
  }

  const renderForm = () => (
    <StaffWorklogPreviewForm
      editMode={editMode}
      formEntries={formEntries}
      initialValues={initialValues}
      isEmpty={isEmpty}
      isSubmitting={isSubmitting}
      memberDetails={memberDetails}
      previousRecord={previousRecord}
      record={record}
      selectedDate={selectedDate}
      worklogDayType={worklogDayType}
      onAddActivity={onAddActivity}
      onChangeEditMode={onChangeEditMode}
      onClockOutButtonClick={onClockOutButtonClick}
      onEndButtonClick={onEndButtonClick}
      onOngoingEntryEndClick={onOngoingEntryEndClick}
      onSubmit={onSubmit}
      onUpdateEntryTotalTime={onUpdateEntryTotalTime}
    />
  )

  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    return (
      <React.Fragment>
        {renderHeader()}
        {renderStatistics()}
        {renderForm()}
      </React.Fragment>
    )
  }

  return (
    <Page.Section
      actions={renderActions()}
      isLoading={isFetching}
      title={editMode
        ? i18n.t('module:Staff:StaffWorklogPreview:editTitle')
        : i18n.t('module:Staff:StaffWorklogPreview:title')}
    >
      {renderContent()}
    </Page.Section>
  )
}

export default StaffWorklogPreviewView
