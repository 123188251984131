import React from 'react'
import i18n from 'translations'

import { InvoiceSettings } from 'services/organization/models'
import { NurseryInvoiceNumberWithDescriptionsProps } from 'services/nurseries/single/selectors/settings'

import { Field, Section, Toolbar } from 'components'

import {
  InvoiceDateSettings,
  InvoiceDetailRightComponent,
  InvoiceDueDateSettings,
  InvoiceNoteSettings,
  InvoicePaymentReminderSettings,
  InvoiceRadioSettings,
} from './components'
import { invoiceDetailOptions } from './constants'
import { StyledOptionsContainer } from './InvoiceSettingStyled'
import InvoiceAnnualisedSettingView from '../InvoiceAnnualisedSettingView'

interface InvoiceSettingV3Props {
  annualisedSettingsConfig?: {
    editButtonLink?: string
    subtitle?: string
    title?: string
  }
  description?: string
  disableEdit?: boolean
  hideAutoCalculateStartDate?: boolean
  hideAutomationOptions?: boolean
  hideGlobalOption?: boolean
  hideInvoiceNumberOptions?: boolean
  hidePaymentReminders?: boolean
  invoiceNumberSettingsConfig?: {
    editButtonLink?: string
    subtitle?: string
    title?: string
  }
  invoiceNumberSettingsData?: NurseryInvoiceNumberWithDescriptionsProps
  invoicesSettings?: InvoiceSettings
  onSubmit?: (values: any) => void
  orgInvoicesSettings?: InvoiceSettings
  paymentReminderTags?: any
  title?: string | JSX.Element
}

const InvoiceSettingV3: React.FC<InvoiceSettingV3Props> = ({
  annualisedSettingsConfig,
  description,
  disableEdit,
  hideAutoCalculateStartDate,
  hideAutomationOptions,
  hideGlobalOption,
  hideInvoiceNumberOptions,
  hidePaymentReminders,
  invoiceNumberSettingsConfig,
  invoiceNumberSettingsData,
  invoicesSettings,
  onSubmit,
  orgInvoicesSettings,
  paymentReminderTags,
  title,
}) => {
  const {
    annualisedInvoiceSettings,
    autocalculationStartDate,
    dueDate,
    dueDateAppliedFromGlobal,
    invoiceLevelDetailDisplay,
    invoiceLevelDetailDisplayAppliedFromGlobal,
    invoiceNotesAppliedFromGlobal,
    notes,
    paymentReminders,
    statementOfAccountNotesAppliedFromGlobal,
  } = invoicesSettings || {}

  const {
    editButtonLink,
    subtitle,
    title: invoiceNumberSettingsTitle,
  } = invoiceNumberSettingsConfig || {}

  const {
    creditNoteNumberDescription,
    invoiceNumberDescription,
    receiptNumberDescription,
  } = invoiceNumberSettingsData || {}

  const {
    editButtonLink: annualisedSettingsEditButtonLink,
    subtitle: annualisedSettingsSubtitle,
    title: annualisedSettingsTitle,
  } = annualisedSettingsConfig || {}

  const {
    dueDate: orgDueDate,
    invoiceLevelDetailDisplay: orgInvoiceLevelDetailDisplay,
    notes: orgNotes,
    paymentReminders: orgPaymentReminders,
  } = orgInvoicesSettings || {}

  return (
    <React.Fragment>
      {!hideAutomationOptions && !hideAutoCalculateStartDate && (
        <Section title={title}>
          <Toolbar>
            <Toolbar.Group>
              <Toolbar.Item>{description}</Toolbar.Item>
            </Toolbar.Group>
          </Toolbar>
          <InvoiceDateSettings
            caption={i18n.t('components:Settings:InvoiceSettings:AutoCalculationDate:caption')}
            disableEdit={disableEdit}
            fields={{
              global: {},
              item: {
                id: 'autocalculationStartDate',
                value: autocalculationStartDate,
              },
            }}
            hideGlobalOption
            onSubmit={onSubmit}
          />
        </Section>
      )}
      <InvoiceAnnualisedSettingView
        annualisedInvoiceSettings={annualisedInvoiceSettings}
        editLink={annualisedSettingsEditButtonLink}
        subtitle={annualisedSettingsSubtitle}
        title={annualisedSettingsTitle}
      />
      {!hideInvoiceNumberOptions && (
        <Section
          actions={editButtonLink && <Section.Actions tertiary={{ to: editButtonLink }} />}
          title={invoiceNumberSettingsTitle}
        >
          {subtitle && (
            <p>
              {subtitle}
            </p>
          )}
          <Field.InlineEdit
            description={invoiceNumberDescription}
            label={i18n.t('components:Settings:InvoiceSettings:InvoiceNumberSequencing:nextInvoiceNumberLabel')}
          />
          <Field.InlineEdit
            description={receiptNumberDescription}
            label={i18n.t('components:Settings:InvoiceSettings:InvoiceNumberSequencing:nextReceiptNumberLabel')}
          />
          <Field.InlineEdit
            description={creditNoteNumberDescription}
            label={i18n.t('components:Settings:InvoiceSettings:InvoiceNumberSequencing:nextCreditNoteNumberLabel')}
          />
        </Section>
      )}
      <Section title={i18n.t('components:Settings:InvoiceSettings:InvoiceDisplay:title')}>
        <InvoiceRadioSettings
          caption={i18n.t('components:Settings:InvoiceSettings:InvoiceDetail:caption')}
          disableEdit={disableEdit}
          fields={{
            global: {
              id: 'invoiceLevelDetailDisplayAppliedFromGlobal',
              value: invoiceLevelDetailDisplayAppliedFromGlobal,
            },
            item: {
              id: 'invoiceLevelDetailDisplay',
              orgValue: orgInvoiceLevelDetailDisplay,
              value: invoiceLevelDetailDisplay,
            },
          }}
          hideGlobalOption={hideGlobalOption}
          options={invoiceDetailOptions}
          rightComponent={(input) => <InvoiceDetailRightComponent {...input} />}
          onSubmit={onSubmit}
        />
      </Section>
      <Section title={i18n.t('components:Settings:InvoiceSettings:InvoiceNotesAndReminders:title')}>
        <StyledOptionsContainer>
          <InvoiceNoteSettings
            caption={i18n.t('components:Settings:InvoiceSettings:InvoiceNote:caption')}
            disableEdit={disableEdit}
            fields={{
              global: {
                id: 'invoiceNotesAppliedFromGlobal',
                value: invoiceNotesAppliedFromGlobal,
              },
              item: {
                id: 'notes',
                orgValue: orgNotes,
                value: notes,
              },
            }}
            hideGlobalOption={hideGlobalOption}
            noteId="INVOICE_NOTES"
            onSubmit={onSubmit}
          />
          <InvoiceNoteSettings
            caption={i18n.t('components:Settings:InvoiceSettings:StatementOfAccount:caption')}
            disableEdit={disableEdit}
            fields={{
              global: {
                id: 'statementOfAccountNotesAppliedFromGlobal',
                value: statementOfAccountNotesAppliedFromGlobal,
              },
              item: {
                id: 'notes',
                orgValue: orgNotes,
                value: notes,
              },
            }}
            hideGlobalOption={hideGlobalOption}
            noteId="STATEMENT_OF_ACCOUNT_NOTES"
            onSubmit={onSubmit}
          />
          <InvoiceDueDateSettings
            caption={i18n.t('components:Settings:InvoiceSettings:InvoiceDueDate:caption')}
            disableEdit={disableEdit}
            fields={{
              global: {
                id: 'dueDateAppliedFromGlobal',
                value: dueDateAppliedFromGlobal,
              },
              item: {
                id: 'dueDate',
                orgValue: orgDueDate,
                value: dueDate,
              },
            }}
            hideGlobalOption={hideGlobalOption}
            tooltip={i18n.t('components:Settings:InvoiceSettings:InvoiceDueDate:tooltip')}
            onSubmit={onSubmit}
          />
          {!hidePaymentReminders && (
            <InvoicePaymentReminderSettings
              disableEdit={disableEdit}
              fields={{
                global: {
                  id: 'paymentRemindersAppliedFromGlobal',
                  value: false,
                },
                item: {
                  id: 'paymentReminders',
                  orgValue: orgPaymentReminders,
                  value: paymentReminders,
                },
              }}
              paymentReminderTags={paymentReminderTags}
              hideGlobalOption
              onSubmit={onSubmit}
            />
          )}
        </StyledOptionsContainer>
      </Section>
    </React.Fragment>
  )
}

export default InvoiceSettingV3
