import React, { useEffect } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'
import { Field, InjectedFormProps, change as changeForm, getFormValues, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { Banner, CircleIcon, Form, Grid, Space, Tooltip, Typography } from 'components'

import i18n from 'translations'

import { CODE_AUTOMATIC_GENERATION, CODE_AUTOMATIC_GENERATION_OPTIONS } from '../../constants'

export const EDIT_REFERENCE_CODE_GENERATION_FORM = 'EditReferenceCodeGenerationForm'

interface EditFormProps {
  cancelLink: string
  formValues: EditFormVales
  isSubmitting: boolean
  onSubmit: (fields: EditFormVales) => void
}

export interface EditFormVales {
  codeAutomaticGeneration?: string
  codePrefix?: string
  codePrefixEnabled?: boolean
  counter?: number
  randomisedBySystem?: string
  sequential?: boolean
  sequentialNumber?: number
}

type EditFormFullProps = InjectedFormProps<{}, EditFormProps> & EditFormProps & PropsFromRedux

const mapDispatch = {
  change: (field, value) => changeForm(EDIT_REFERENCE_CODE_GENERATION_FORM, field, value),
}

const mapState = (state) => ({
  formValues: getFormValues(EDIT_REFERENCE_CODE_GENERATION_FORM)(state),
})

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

const EditForm: React.FC<EditFormFullProps> = ({
  cancelLink,
  change,
  formValues,
  handleSubmit,
  isSubmitting,
  onSubmit,
}) => {
  const { codePrefixEnabled, randomisedBySystem } = formValues || {}
  const isSequentialNumberSelected = randomisedBySystem === CODE_AUTOMATIC_GENERATION.SEQUENTIAL_NUMBERS

  useEffect(() => {
    if (isSequentialNumberSelected) {
      change('codePrefixEnabled', true)
    }
  }, [isSequentialNumberSelected])

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Typography fontSize={18}>
        {i18n.t('module:Management:Finance:ReferenceCodeGeneration:subtitle')}
      </Typography>
      <Space margin="40px 0 0" />
      <Form.Row
        fontWeight="600"
        label={i18n.t('module:Management:Finance:ReferenceCodeGeneration:codeAutomaticGeneration')}
      >
        <Field
          component={Form.RadioGroup}
          name="randomisedBySystem"
          options={CODE_AUTOMATIC_GENERATION_OPTIONS}
          validate={isRequired}
        />
      </Form.Row>
      <Form.Row
        fontWeight="600"
        label={i18n.t('module:Management:Finance:ReferenceCodeGeneration:codePrefix')}
        autoHeight
        multipleFieldsInARow
      >
        <Form.Row.Item margin="5px 0 0">
          <Field
            component={Form.Checkbox}
            disabled={isSequentialNumberSelected}
            name="codePrefixEnabled"
          />
        </Form.Row.Item>
        {codePrefixEnabled && (
          <Grid flex>
            <Grid.Item width={{ desktop: '265px', mobile: 'calc(100% - 50px)' }}>
              <Field
                component={Form.TextField}
                name="codePrefix"
                validate={isRequired}
              />
            </Grid.Item>
            <Grid.Item alignItems={{ desktop: 'center' }}>
              <Tooltip
                placement="right"
                title={i18n.t('module:Management:Finance:ReferenceCodeGeneration:Tooltip:text1')}
              >
                <CircleIcon
                  cursor="pointer"
                  icon="info"
                  iconSize={16}
                  size={24}
                />
              </Tooltip>
            </Grid.Item>
          </Grid>
        )}
      </Form.Row>
      {isSequentialNumberSelected && (
        <Form.Row
          fontWeight="600"
          label={i18n.t('module:Management:Finance:ReferenceCodeGeneration:nextNumbersInSequenceFrom')}
        >
          <Grid flex>
            <Grid.Item width={{ desktop: '300px', mobile: 'calc(100% - 50px)' }}>
              <Field
                component={Form.TextField}
                maxLength={16}
                name="sequentialNumber"
                type="number"
                validate={isRequired}
              />
            </Grid.Item>
            <Grid.Item alignItems={{ desktop: 'center' }}>
              <Tooltip
                placement="right"
                title={i18n.t('module:Management:Finance:ReferenceCodeGeneration:Tooltip:text2')}
              >
                <CircleIcon
                  cursor="pointer"
                  icon="info"
                  iconSize={16}
                  size={24}
                />
              </Tooltip>
            </Grid.Item>
          </Grid>
        </Form.Row>
      )}
      <Space margin="40px 0 0" />
      <Form.Row width={{ field: '100%' }}>
        <Banner.Info noLeftBorder>
          {i18n.t('module:Management:Finance:ReferenceCodeGeneration:BannerInfo:text1')}
        </Banner.Info>
      </Form.Row>
      <Form.FooterActions
        cancelLink={cancelLink}
        isSubmitting={isSubmitting}
        submitLabel={i18n.t('global:Save')}
      />
    </Form>
  )
}

const enhance = compose(
  reduxForm<{}, EditFormProps>({ form: EDIT_REFERENCE_CODE_GENERATION_FORM }),
  connector,
)

export default enhance(EditForm)
