import styled from 'styled-components'

export const StyledItemContentContainer = styled.div`
  display: flex;
  align-items: center;
`

export const StyledItemContent = styled.span`
/* stylelint-disable */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
/* stylelint-enable */
`
