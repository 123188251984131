import { generateBasicActions } from 'utils/service'

export const CLEAR_CHILD_EXTRA_ITEM_PROJECTIONS = 'CLEAR_CHILD_EXTRA_ITEM_PROJECTIONS'

export const LIST_GET_CHILD_EXTRA_ITEM_PROJECTIONS = 'LIST_GET_CHILD_EXTRA_ITEM_PROJECTIONS'
export const LIST_GET_CHILD_EXTRA_ITEM_PROJECTIONS_SUCCESS = 'LIST_GET_CHILD_EXTRA_ITEM_PROJECTIONS_SUCCESS'
export const LIST_GET_CHILD_EXTRA_ITEM_PROJECTIONS_FAILED = 'LIST_GET_CHILD_EXTRA_ITEM_PROJECTIONS_FAILED'

export const listExtraItemProjections = (options = {}) => ({ childExtraItemsApiClient }) => generateBasicActions.list({
  constants: {
    failed: LIST_GET_CHILD_EXTRA_ITEM_PROJECTIONS_FAILED,
    init: LIST_GET_CHILD_EXTRA_ITEM_PROJECTIONS,
    success: LIST_GET_CHILD_EXTRA_ITEM_PROJECTIONS_SUCCESS,
  },
  options,
  service: childExtraItemsApiClient,
  serviceMethod: 'listProjections',
})

export const clearProjections = () => ({
  type: CLEAR_CHILD_EXTRA_ITEM_PROJECTIONS,
})
