import styled from 'styled-components'

export const StyledField = styled.div`
  display: grid;
  grid-template-columns: 160px auto;
  grid-column-gap: 10px;
  margin-bottom: 25px;
`

export const StyledStatus = styled.div`
  display: flex;
  gap: 10px;
`
