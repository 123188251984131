import styled from 'styled-components'

import layout from 'constants/layout'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledContainer = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
`

export const StyledItems = styled.div`
  border: 2px solid ${getBrandingColor('tertiary-color')};
  border-radius: ${layout.borderRadius};
  display: flex;
  align-items: center;
`

interface StyledItemProps {
  $isActive?: boolean
}

export const StyledItem = styled.div<StyledItemProps>`
  display: block;
  height: 44px;
  line-height: 15px;
  color: ${getBrandingColor('tertiary-color')};

  a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    color: ${getBrandingColor('tertiary-color')};
  }
  
  &:hover {
    a {
      color: ${getBrandingColor('tertiary-color')};
    }
  }
  
  & + div {
    border-left: 2px solid ${getBrandingColor('tertiary-color')};
  }
  
  ${({ $isActive }) => $isActive && `
    background: ${getBrandingColor('tertiary-color')};
    
    a {
      color: ${NEUTRAL_COLOURS.WHITE};
    }
    
    &:hover {
      a {
        color: ${NEUTRAL_COLOURS.WHITE};
      }
    }
  `}
`
