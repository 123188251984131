import { generateBasicActions } from 'utils/service'

import {
  CLEAR_CLOSURE_DAY_TYPES_DROPDOWN_LIST,
  CLOSURE_DAY_TYPES_DROPDOWN_LIST,
  CLOSURE_DAY_TYPES_DROPDOWN_LIST_FAILED,
  CLOSURE_DAY_TYPES_DROPDOWN_LIST_SUCCESS,
} from './constants'

export const dropdownList = (options = {}) => ({ closureDayTypesApiClient }) => (
  generateBasicActions.list({
    constants: {
      failed: CLOSURE_DAY_TYPES_DROPDOWN_LIST_FAILED,
      init: CLOSURE_DAY_TYPES_DROPDOWN_LIST,
      success: CLOSURE_DAY_TYPES_DROPDOWN_LIST_SUCCESS,
    },
    options,
    service: closureDayTypesApiClient,
    serviceMethod: 'list',
  })
)

export const clearDropdownList = () => ({
  type: CLEAR_CLOSURE_DAY_TYPES_DROPDOWN_LIST,
})
