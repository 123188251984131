import { generateBasicActions } from 'utils/service'

import {
  CLEAR_ENQUIRY_VIEWING_PERFORMANCE_REPORT,
  GET_ENQUIRY_VIEWING_PERFORMANCE_REPORT,
  GET_ENQUIRY_VIEWING_PERFORMANCE_REPORT_FAILED,
  GET_ENQUIRY_VIEWING_PERFORMANCE_REPORT_SUCCESS,
} from './constants'

export const getViewingPerformance = (options = {}) => ({ enquiryReportsApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_ENQUIRY_VIEWING_PERFORMANCE_REPORT_FAILED,
    init: GET_ENQUIRY_VIEWING_PERFORMANCE_REPORT,
    success: GET_ENQUIRY_VIEWING_PERFORMANCE_REPORT_SUCCESS,
  },
  options,
  service: enquiryReportsApiClient,
  serviceMethod: 'getViewingPerformance',
})

export const clearViewingPerformance = () => ({
  type: CLEAR_ENQUIRY_VIEWING_PERFORMANCE_REPORT,
})
