import {
  CLEAR_SINGLE_CHILD_FUNDING_SESSION_SET,
  CREATE_CHILD_FUNDING_SESSION_SET,
  CREATE_CHILD_FUNDING_SESSION_SET_FAILED,
  CREATE_CHILD_FUNDING_SESSION_SET_SUCCESS,
  DELETE_CHILD_FUNDING_SESSION_SET,
  DELETE_CHILD_FUNDING_SESSION_SET_FAILED,
  DELETE_CHILD_FUNDING_SESSION_SET_SUCCESS,
  GET_CHILD_FUNDING_SESSION_SET,
  GET_CHILD_FUNDING_SESSION_SET_FAILED,
  GET_CHILD_FUNDING_SESSION_SET_SUCCESS,
  UPDATE_CHILD_FUNDING_SESSION_SET,
  UPDATE_CHILD_FUNDING_SESSION_SET_FAILED,
  UPDATE_CHILD_FUNDING_SESSION_SET_SUCCESS,
} from './constants'

export const getChildFundingSessionSet = (id, params, onSuccess) => ({
  childFundingSessionSetApiClient,
}) => (dispatch) => {
  dispatch({
    params,
    type: GET_CHILD_FUNDING_SESSION_SET,
  })

  childFundingSessionSetApiClient.get(id, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: GET_CHILD_FUNDING_SESSION_SET_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: GET_CHILD_FUNDING_SESSION_SET_FAILED,
      })
    },
  )
}

export const createChildFundingSessionSet = (
  payload,
  params,
  onSuccess,
  onFailed,
) => ({ childFundingSessionSetApiClient }) => (dispatch) => {
  dispatch({
    params,
    payload,
    type: CREATE_CHILD_FUNDING_SESSION_SET,
  })

  return childFundingSessionSetApiClient.create(payload, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: CREATE_CHILD_FUNDING_SESSION_SET_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      const errorResponse = error.response

      dispatch({
        error: !errorResponse.extra ? errorResponse : null,
        type: CREATE_CHILD_FUNDING_SESSION_SET_FAILED,
      })

      if (onFailed) {
        onFailed(errorResponse)
      }
    },
  )
}

export const updateChildFundingSessionSet = (
  id,
  payload,
  params,
  onSuccess,
  onFailed,
) => ({ childFundingSessionSetApiClient }) => (dispatch) => {
  dispatch({
    id,
    params,
    payload,
    type: UPDATE_CHILD_FUNDING_SESSION_SET,
  })

  childFundingSessionSetApiClient.update(id, payload, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: UPDATE_CHILD_FUNDING_SESSION_SET_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    (error) => {
      const errorResponse = error.response

      dispatch({
        error: !errorResponse.extra ? errorResponse : null,
        type: UPDATE_CHILD_FUNDING_SESSION_SET_FAILED,
      })

      if (onFailed) {
        onFailed(errorResponse)
      }
    },
  )
}

export const deleteChildFundingSessionSet = (id, params, onSuccess) => ({
  childFundingSessionSetApiClient,
}) => (dispatch) => {
  dispatch({
    id,
    params,
    type: DELETE_CHILD_FUNDING_SESSION_SET,
  })

  childFundingSessionSetApiClient.delete(id, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: DELETE_CHILD_FUNDING_SESSION_SET_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: DELETE_CHILD_FUNDING_SESSION_SET_FAILED,
      })
    },
  )
}

export const clearSingleChildFundingSessionSet = () => ({
  type: CLEAR_SINGLE_CHILD_FUNDING_SESSION_SET,
})
