import React from 'react'

import { generateRoute } from 'utils/routing'

import { Callout, Page, Spinner } from 'components'
import Settings from 'module/Settings/components'

import i18n from 'translations'

const ManagementInvoicesV3View = ({
  FinanceAutomationGranted,
  bankDetails,
  disableEdit,
  errorMessages,
  hideGlobalOption,
  invoiceNumberSettings,
  invoicesSettings,
  isInvoiceNumberReadonly,
  isLoading,
  isUkCountry,
  onSubmit,
  openWeeks,
  orgInvoicesSettings,
  paymentReminderTags,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    const hideAutomationOptions = !FinanceAutomationGranted

    return (
      <React.Fragment>
        <Settings.InvoiceSettingV3
          annualisedSettingsConfig={{
            editButtonLink: generateRoute('MANAGEMENT.FINANCE_SETTINGS.INVOICES.ANNUALISED_EDIT'),
            subtitle: i18n.t('module:Management:Finance:Annualised:subtitle'),
            title: i18n.t('module:Management:Finance:Annualised:title'),
          }}
          description={i18n.t('module:Management:Finance:Invoice:description')}
          disableEdit={disableEdit}
          hideAutomationOptions={hideAutomationOptions}
          hideGlobalOption={hideGlobalOption}
          invoiceNumberSettingsConfig={{
            editButtonLink: (
              !isInvoiceNumberReadonly && generateRoute('MANAGEMENT.FINANCE_SETTINGS.INVOICES.INVOICE_NUMBER_EDIT')
            ),
            subtitle: i18n.t('module:Management:Finance:Invoice:InvoiceNumberSettings:subtitle'),
            title: i18n.t('module:Management:Finance:Invoice:InvoiceNumberSettings:title'),
          }}
          invoiceNumberSettingsData={invoiceNumberSettings}
          invoicesSettings={invoicesSettings}
          openWeeks={openWeeks}
          orgInvoicesSettings={orgInvoicesSettings}
          paymentReminderTags={paymentReminderTags}
          title={i18n.t('module:Management:Finance:Invoice:title')}
          onSubmit={onSubmit}
        />
        <Settings.InvoiceBankSettingView
          bankDetails={bankDetails}
          editLink={generateRoute('MANAGEMENT.FINANCE_SETTINGS.INVOICES.BANK_EDIT')}
          isUkCountry={isUkCountry}
        />
      </React.Fragment>
    )
  }

  return (
    <Page>
      <Page.Content>
        <Callout content={errorMessages} error />
        {renderContent()}
      </Page.Content>
    </Page>
  )
}

export default ManagementInvoicesV3View
