import _ from 'lodash'

import React, { useMemo } from 'react'

import { Typography } from 'components'

import i18n from 'translations'

import {
  PASSWORD_STRENGTH_LEVELS,
  StyledIndicatorValue,
  StyledIndicatorWrapper,
  StyledWrapper,
} from './PasswordStrengthStyled'

interface PasswordStrengthProps {
  password: string
}

const PasswordStrength: React.FC<PasswordStrengthProps> = ({ password }) => {
  const level = useMemo(() => {
    if (!password) {
      return null
    }

    let score = 0
    const letters = {}

    _.times(password.length, (i) => {
      const letter = password[i]

      letters[letter] = (letters[letter] || 0) + 1
      score += 5.0 / letters[letter]
    })

    const featurePatterns = [/\d/, /[a-z]/, /[A-Z]/, /\W/]
    const variationCount = featurePatterns.reduce(
      // @ts-ignore
      (count, pattern) => (count + pattern.test(password) ? 1 : 0),
      0,
    )

    score += (variationCount - 1) * 10
    score = 100 < score ? 100 : score

    return _.find(
      _.orderBy(PASSWORD_STRENGTH_LEVELS, (item) => item.score),
      (item) => Math.floor(score) < item.score,
    )
  }, [password])

  return (
    <StyledWrapper>
      <StyledIndicatorWrapper>
        <StyledIndicatorValue $level={level} />
      </StyledIndicatorWrapper>
      <Typography fontSize={14}>
        {level?.label || i18n.t('module:Authentication:components:PasswordStrength:toShort')}
      </Typography>
    </StyledWrapper>
  )
}

export default PasswordStrength
