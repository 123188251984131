import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_CLOSURE_DAY_TYPE,
  CREATE_CLOSURE_DAY_TYPE,
  CREATE_CLOSURE_DAY_TYPE_FAILED,
  CREATE_CLOSURE_DAY_TYPE_SUCCESS,
  GET_CLOSURE_DAY_TYPE,
  GET_CLOSURE_DAY_TYPE_FAILED,
  GET_CLOSURE_DAY_TYPE_SUCCESS,
  REMOVE_CLOSURE_DAY_TYPE,
  REMOVE_CLOSURE_DAY_TYPE_FAILED,
  REMOVE_CLOSURE_DAY_TYPE_SUCCESS,
  UPDATE_CLOSURE_DAY_TYPE,
  UPDATE_CLOSURE_DAY_TYPE_FAILED,
  UPDATE_CLOSURE_DAY_TYPE_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_CLOSURE_DAY_TYPE,
    createFailed: CREATE_CLOSURE_DAY_TYPE_FAILED,
    createInit: CREATE_CLOSURE_DAY_TYPE,
    createSuccess: CREATE_CLOSURE_DAY_TYPE_SUCCESS,
    itemFailed: GET_CLOSURE_DAY_TYPE_FAILED,
    itemInit: GET_CLOSURE_DAY_TYPE,
    itemSuccess: GET_CLOSURE_DAY_TYPE_SUCCESS,
    removeFailed: REMOVE_CLOSURE_DAY_TYPE_FAILED,
    removeInit: REMOVE_CLOSURE_DAY_TYPE,
    removeSuccess: REMOVE_CLOSURE_DAY_TYPE_SUCCESS,
    updateFailed: UPDATE_CLOSURE_DAY_TYPE_FAILED,
    updateInit: UPDATE_CLOSURE_DAY_TYPE,
    updateSuccess: UPDATE_CLOSURE_DAY_TYPE_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
