import * as availabilityStatisticsActions from './availabilityStatistics/actions'
import * as costStatisticsActions from './costStatistics/actions'
import * as listActions from './list/actions'
import * as singleActions from './single/actions'
import * as exceptionalUnavailableTimesListActions from './settings/unavailableTimes/exceptional/list/actions'
import * as exceptionalUnavailableTimesSingleActions from './settings/unavailableTimes/exceptional/single/actions'
import * as regularUnavailableTimesBatchActions from './settings/unavailableTimes/regular/batch/actions'
import * as regularUnavailableTimesListActions from './settings/unavailableTimes/regular/list/actions'

export default {
  ...availabilityStatisticsActions,
  ...costStatisticsActions,
  ...exceptionalUnavailableTimesListActions,
  ...exceptionalUnavailableTimesSingleActions,
  ...listActions,
  ...regularUnavailableTimesBatchActions,
  ...regularUnavailableTimesListActions,
  ...singleActions,
}
