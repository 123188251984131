import _ from 'lodash'
import { nest } from 'utils/flatnest'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'

import { COMPARISON_REPORT_TYPES } from 'services/legacy/cohortTracking/constants'
import { FRAMEWORK_PROGRESS_LEVELS_TYPES } from 'services/legacy/frameworkProgressLevels/constants'
import { FRAMEWORK_STRUCTURE_APPLICABLE } from 'services/legacy/frameworks/constants'
import { ACTION_PLAN_FORM } from 'module/Learning/CohortTracking/components/ActionPlan/ActionPlan'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withCohortTrackingService } from 'services/legacy/cohortTracking'
import { withEthnicitiesService } from 'services/legacy/ethnicities'
import { withFrameworksService } from 'services/legacy/frameworks'
import { withFrameworkProgressLevelsService } from 'services/legacy/frameworkProgressLevels'
import { withFormativeReportsService } from 'services/legacy/formativeReports'
import { withSnackbarService } from 'services/utils/snackbar'
import { withModalService } from 'services/utils/modal'
import { withRoomsService } from 'services/legacy/rooms'
import { withUsersService } from 'services/users'
import { withRouter } from 'services/router'

import i18n from 'translations'

import ComparisonReportView from './ComparisonReportView'

const FRAMEWORK_GROUPS = {
  read: [
    'framework.areas',
    'frameworkArea',
    'structure',
    'structure.cohort',
    'frameworkArea.groups',
    'frameworkGroup',
    'frameworkGroup.categories',
    'frameworkCategory',
    'framework.default',
  ],
}

class ComparisonReportContainer extends Component {
  constructor(props) {
    super(props)
    const { location: { pathname } } = this.props

    this.state = {
      comparisonTableFilter: null,
      itsUnsavedPreview: generateRoute('LEARNING.COHORT_TRACKING.COMPARISON_REPORT.PREVIEW') === pathname,
      selectedProgressLevel: null,
    }
  }

  async componentDidMount() {
    const {
      ethnicitiesActions,
      formativeReportsActions,
      frameworksActions,
      location,
      roomsActions,
      usersActions,
    } = this.props
    const { query } = location
    const nestedQuery = nest(query)
    const { filters } = nestedQuery || {}

    const frameworkParams = {
      params: [{
        groups: FRAMEWORK_GROUPS,
      }],
    }

    const promiseResults = await Promise.all([
      ethnicitiesActions.list(),
      formativeReportsActions.getTerms(),
      frameworksActions.list(frameworkParams),
      // eslint-disable-next-line no-unsafe-optional-chaining
      filters?.keyWorker ? usersActions.get({ params: [+filters?.keyWorker] }) : null,
      // eslint-disable-next-line no-unsafe-optional-chaining
      filters?.nurseryClass ? roomsActions.get({ params: [+filters?.nurseryClass] }) : null,
    ])

    this.validationQuery({ frameworksList: promiseResults?.[2]?.data })
    this.getReport()
  }

  componentWillUnmount() {
    const {
      cohortTrackingActions,
      ethnicitiesActions,
      formativeReportsActions,
      frameworkProgressLevelsActions,
      frameworksActions,
      roomsActions,
      usersActions,
    } = this.props

    formativeReportsActions.clearTerms()
    ethnicitiesActions.clear()
    frameworksActions.clear()
    frameworkProgressLevelsActions.clear()
    cohortTrackingActions.clearComparisonSingle()
    roomsActions.clearSingle()
    usersActions.clear()
  }

  getReport = () => {
    const { cohortTrackingActions, cohortTrackingSelectors, location, navigate, params } = this.props
    const { itsUnsavedPreview } = this.state
    const { query } = location

    const payload = cohortTrackingSelectors.getCohortTrackingReportsPayload(query)

    if (itsUnsavedPreview) {
      return cohortTrackingActions.getComparisonReportPreview({
        onSuccess: this.handleGetProgressLevels,
        params: [query.type, { extra: payload }],
      })
    }

    return cohortTrackingActions.getComparisonReport({
      onFailed: () => navigate(generateRoute('LEARNING.COHORT_TRACKING')),
      onSuccess: this.handleGetProgressLevels,
      params: [query.type, +params.id],
    })
  }

  invalidQueryParams = () => {
    const { navigate, snackbarActions } = this.props

    snackbarActions.show({
      message: i18n.t('module:Learning:CohortTracking:global:invalidQueryParams'),
    })

    return navigate(generateRoute('LEARNING.COHORT_TRACKING.COMPARISON_REPORT'))
  }

  validationQuery = ({ frameworksList }) => {
    const { cohortTrackingSelectors, location, terms } = this.props
    const { itsUnsavedPreview } = this.state
    const { query } = location

    if (!_.find(_.values(COMPARISON_REPORT_TYPES), (value) => query.type === value)) {
      return this.invalidQueryParams()
    }

    if (!itsUnsavedPreview) {
      return null
    }

    const framework = _.find(frameworksList, ({ id }) => id === +query.framework)
    if (!framework) {
      return this.invalidQueryParams()
    }

    const frameworkGroups = cohortTrackingSelectors.getFrameworkGroupsOptions(framework)

    if (!_.find(frameworkGroups, ({ options, value }) => {
      if (options?.length) {
        return _.find(options, (option) => option.value === +query.frameworkGroup)
      }

      return value === +query.frameworkGroup
    })) {
      return this.invalidQueryParams()
    }

    if (!_.find(terms, ({ value }) => value === query.term)) {
      return this.invalidQueryParams()
    }

    return null
  }

  handleChangeFilters = () => {
    const { location, navigate } = this.props
    const { query } = location

    return navigate(
      `${generateRoute('LEARNING.COHORT_TRACKING.COMPARISON_REPORT')}?params=${JSON.stringify(query)}`,
    )
  }

  handleSaveReport = () => {
    const {
      actionPlanFormValues,
      cohortTrackingActions,
      cohortTrackingSelectors,
      ethnicities,
      keyWorker,
      location,
      navigate,
      nurseryClass,
      report,
    } = this.props
    const { query } = location

    const filterLegacyLabel = cohortTrackingSelectors.generateFiltersLabel(report, {
      ethnicities,
      keyWorker,
      nurseryClass,
    })
    const body = cohortTrackingSelectors.getCohortTrackingReportsPayload(
      query,
      filterLegacyLabel,
      actionPlanFormValues?.actionPlan,
    )

    return cohortTrackingActions.createComparisonReport({
      body,
      onSuccess: () => navigate(generateRoute('LEARNING.COHORT_TRACKING')),
      params: [query.type],
    })
  }

  handleChangeActionPlan = (onSuccess) => setTimeout(() => {
    const { actionPlanFormValues, cohortTrackingActions, location, params } = this.props
    const { query } = location

    const body = {
      actionPlan: actionPlanFormValues?.actionPlan || '',
    }

    cohortTrackingActions.updateComparisonReportActionPlan({
      body,
      onSuccess,
      params: [query.type, +params.id],
    })
  })

  handleGetProgressLevels = ({ data }) => {
    const { frameworkProgressLevelsActions, report } = this.props

    const progressLevelsCriteria = [
      {
        field: 'framework',
        value: report?.framework?.id || data?.framework?.id,
      },
      {
        field: 'type',
        value: FRAMEWORK_PROGRESS_LEVELS_TYPES.FORMATIVE_REPORT_AGE_BAND,
      },
    ]

    frameworkProgressLevelsActions.list({
      onSuccess: this.handleGetProgressLevelSuccess,
      params: {
        criteria: progressLevelsCriteria,
      },
      recursively: true,
    })
  }

  handleGetProgressLevelSuccess = ({ data }) => {
    this.setState({
      selectedProgressLevel: _.get(_.first(data), 'id'),
    })
  }

  handleChangeSelectedProgressLevel = (selectedProgressLevel) => {
    this.setState({
      comparisonTableFilter: null,
      selectedProgressLevel,
    })
  }

  handleFilterComparisonTable = (selectedProgressLevel, comparisonTableFilter) => {
    const {
      comparisonTableFilter: currentComparisonTableFilter,
      selectedProgressLevel: currentSelectedProgressLevel,
    } = this.state

    if (
      currentComparisonTableFilter?.title === comparisonTableFilter.title
      || currentSelectedProgressLevel !== selectedProgressLevel
    ) {
      return this.setState({
        comparisonTableFilter: null,
        selectedProgressLevel,
      })
    }
    return this.setState({
      comparisonTableFilter,
      selectedProgressLevel,
    })
  }

  handleRemoveReportConfirmed = () => {
    const { cohortTrackingActions, report, router } = this.props
    const { location } = router
    const { query } = location

    cohortTrackingActions.removeComparisonReport({
      onSuccess: () => router.push(generateRoute('LEARNING.COHORT_TRACKING')),
      params: [query.type, report.id],
    })
  }

  handleRemoveReport = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      onConfirm: this.handleRemoveReportConfirmed,
      text: i18n.t('module:Learning:CohortTracking:ComparisonReport:confirmRemove'),
    })
  }

  render() {
    const {
      ethnicities,
      frameworksList,
      getComparisonReportTableData,
      isFetching,
      keyWorker,
      location,
      nurseryClass,
      progressLevels,
      report,
      terms,
    } = this.props
    const { comparisonTableFilter, itsUnsavedPreview, selectedProgressLevel } = this.state
    const { query } = location

    const comparisonReportTableData = getComparisonReportTableData(selectedProgressLevel, comparisonTableFilter)

    return (
      <ComparisonReportView
        comparisonReportTableData={comparisonReportTableData}
        ethnicities={ethnicities}
        frameworksList={frameworksList}
        isFetching={isFetching}
        itsUnsavedPreview={itsUnsavedPreview}
        keyWorker={keyWorker}
        nurseryClass={nurseryClass}
        progressLevels={progressLevels}
        report={report}
        reportType={query?.type}
        selectedProgressLevel={selectedProgressLevel}
        terms={terms}
        onChangeActionPlan={this.handleChangeActionPlan}
        onChangeFilters={this.handleChangeFilters}
        onChangeSelectedProgressLevel={this.handleChangeSelectedProgressLevel}
        onFilterComparisonTable={this.handleFilterComparisonTable}
        onRemoveReport={this.handleRemoveReport}
        onSaveReport={this.handleSaveReport}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  cohortTrackingComparisonReportSingleState,
  cohortTrackingSelectors,
  ethnicitiesListState,
  ethnicitiesSelectors,
  formativeReportsSelectors,
  formativeReportsTermsState,
  frameworkProgressLevelsListState,
  frameworkProgressLevelsSelectors,
  frameworksListState,
  frameworksSelectors,
  roomsSelectors,
  roomsSingleState,
  usersSelectors,
  usersSingleState,
}) => ({
  actionPlanFormValues: getFormValues(ACTION_PLAN_FORM)(state),
  ethnicities: ethnicitiesSelectors.getEthnicitiesListDataDropdownSelector(state),
  frameworksList: frameworksSelectors.getFilteredFrameworksRawList(
    FRAMEWORK_STRUCTURE_APPLICABLE.COHORT_TRACKING,
  )(state),
  getComparisonReportTableData: (...params) => (
    cohortTrackingSelectors.getComparisonReportTableData(...params)(state)
  ),
  isFetching: appSelectors.getIsFetching(
    cohortTrackingComparisonReportSingleState,
    ethnicitiesListState,
    frameworksListState,
    frameworkProgressLevelsListState,
    formativeReportsTermsState,
    roomsSingleState,
    usersSingleState,
  ),
  keyWorker: usersSelectors.getUsersSingleDataSelector(state),
  nurseryClass: roomsSelectors.getRoomsSingleDataSelector(state),
  progressLevels: frameworkProgressLevelsSelectors.getFrameworkProgressLevels(state),
  report: cohortTrackingSelectors.getComparisonReport(state),
  terms: formativeReportsSelectors.getUsedTermsDropdownInFormativeReports(state),
})

const enhance = compose(
  withAppService,
  withCohortTrackingService,
  withEthnicitiesService,
  withFrameworksService,
  withFrameworkProgressLevelsService,
  withFormativeReportsService,
  withModalService,
  withSnackbarService,
  withRoomsService,
  withUsersService,
  withRouter,
  connect(mapState),
)

export default enhance(ComparisonReportContainer)
