import {
  CLEAR_CREDIT_NOTE,
  CREATE_BULK_CREDIT_NOTE,
  CREATE_BULK_CREDIT_NOTE_FAILED,
  CREATE_BULK_CREDIT_NOTE_SUCCESS,
  CREATE_CREDIT_NOTE,
  CREATE_CREDIT_NOTE_FAILED,
  CREATE_CREDIT_NOTE_SUCCESS,
  GET_CREDIT_NOTE,
  GET_CREDIT_NOTE_FAILED,
  GET_CREDIT_NOTE_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case CREATE_CREDIT_NOTE:
      return { ...state, isFetching: true }
    case CREATE_CREDIT_NOTE_SUCCESS:
    case CREATE_CREDIT_NOTE_FAILED:
      return { ...state, isFetching: false }
    case GET_CREDIT_NOTE:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_CREDIT_NOTE_SUCCESS:
      return { ...state, data: payload.data, isFetching: false }
    case GET_CREDIT_NOTE_FAILED:
      return { ...state, error, isFetching: false }
    case CREATE_BULK_CREDIT_NOTE:
      return { ...state, error: initialState.error, isSubmitting: true }
    case CREATE_BULK_CREDIT_NOTE_SUCCESS:
      return { ...state, isSubmitting: false }
    case CREATE_BULK_CREDIT_NOTE_FAILED:
      return { ...state, error, isSubmitting: false }
    case CLEAR_CREDIT_NOTE:
      return { state: initialState }
    default:
      return state
  }
}
