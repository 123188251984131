import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { TIME_OPTIONS } from 'services/legacy/dailyDiary/constants'

import { generateRoute } from 'utils/routing'

import { DatePicker, Icon, InfiniteDropdowns, Page, Select, Toolbar, Typography } from 'components'

import i18n from 'translations'

import { StyledHeader, StyledLink, StyledNavigationButton } from './DailyDiaryStyled'

const DailyDiaryView = ({
  children,
  currentPathname,
  date,
  isPastDay,
  onChangeDate,
  onChangeRoom,
  onChangeTimeType,
  room,
  timeType,
}) => {
  const renderButton = ({ icon, title, to }) => (
    <StyledLink to={to}>
      <StyledNavigationButton
        isActive={to === currentPathname}
        to={to}
      >
        <Icon
          color={NEUTRAL_COLOURS.WHITE}
          icon={icon}
        />
        <Typography
          color={NEUTRAL_COLOURS.WHITE}
          fontSize={12}
          lineHeight={12}
          transform="uppercase"
          bold
        >
          {title}
        </Typography>
      </StyledNavigationButton>
    </StyledLink>
  )

  return (
    <Page.Section
      title={i18n.t('module:DailyDiary:DailyDiary:dailyDiary')}
    >
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <DatePicker
              value={date}
              clearable
              onChange={onChangeDate}
            />
          </Toolbar.Item>
          <Toolbar.Item>
            <InfiniteDropdowns.Rooms
              value={room}
              onChange={onChangeRoom}
            />
          </Toolbar.Item>
          <Toolbar.Item>
            <Select
              clearable={false}
              disabled={isPastDay}
              options={TIME_OPTIONS}
              value={timeType}
              onChange={onChangeTimeType}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      <StyledHeader>
        {renderButton({
          icon: 'meals',
          title: i18n.t('module:DailyDiary:DailyDiary:Meals'),
          to: generateRoute('DAILY_DIARY.MEALS', null, { transferOldParams: true }),
        })}
        {renderButton({
          icon: 'snacks',
          title: i18n.t('module:DailyDiary:DailyDiary:Snacks'),
          to: generateRoute('DAILY_DIARY.SNACKS', null, { transferOldParams: true }),
        })}
        {renderButton({
          icon: 'nappy',
          title: i18n.t('module:DailyDiary:DailyDiary:nappiesToilet'),
          to: generateRoute('DAILY_DIARY.NAPPIES', null, { transferOldParams: true }),
        })}
        {renderButton({
          icon: 'sleeps',
          title: i18n.t('module:DailyDiary:DailyDiary:Sleeps'),
          to: generateRoute('DAILY_DIARY.SLEEPS', null, { transferOldParams: true }),
        })}
        {renderButton({
          icon: 'activities',
          title: i18n.t('module:DailyDiary:DailyDiary:Activities'),
          to: generateRoute('DAILY_DIARY.ACTIVITIES', null, { transferOldParams: true }),
        })}
        {renderButton({
          icon: 'bottle',
          title: i18n.t('module:DailyDiary:DailyDiary:Bottles'),
          to: generateRoute('DAILY_DIARY.BOTTLES', null, { transferOldParams: true }),
        })}
      </StyledHeader>
      {children}
    </Page.Section>
  )
}

export default DailyDiaryView
