import React from 'react'
import { reduxForm } from 'redux-form'

import { Button, FooterActions, Form, GridLayout, Typography } from 'components'

import i18n from 'translations'

import NurseryDetailsBreakdown from 'module/Nurseries/components/NurseryDetailsBreakdown'
import StaffDetailsBreakdown from 'module/Staff/components/StaffDetailsBreakdown'

import { NURSERIES_ADD_FORM } from '../../constants'

const ReviewStage = ({
  handleSubmit,
  isStaffingEnabled,
  isSubmitting,
  nursery,
  onPreviousStageClick,
  organizationSuffix,
  primaryContact,
  pristine,
  submitting,
}) => {
  const renderNurseryDetails = () => (
    <NurseryDetailsBreakdown
      nursery={nursery}
      organizationSuffix={organizationSuffix}
      showCenteredLogo
      showHeader
    />
  )

  const renderPrimaryContactDetails = () => (
    <StaffDetailsBreakdown
      isStaffingEnabled={isStaffingEnabled}
      membership={primaryContact}
      showCenteredPhoto
      showPrimaryContactHeader
    />
  )

  const renderDetails = () => (
    <GridLayout>
      <GridLayout.Group noVerticalMargin>
        <GridLayout.Item>
          {renderNurseryDetails()}
        </GridLayout.Item>
        <GridLayout.Item>
          {renderPrimaryContactDetails()}
        </GridLayout.Item>
      </GridLayout.Group>
    </GridLayout>
  )

  const registrationNotice = (
    <Typography align="center" linkColorInherit={false} padding="0 10px">
      {i18n.t('module:Nurseries:Add:RegistrationNotice:text1')}
      {' '}
      {/* eslint-disable-next-line no-unsafe-optional-chaining */}
      {primaryContact?.firstName + (primaryContact?.surname || '')
          || i18n.t('module:Nurseries:Add:RegistrationNotice:text2')}
      {' '}
      {i18n.t('module:Nurseries:Add:RegistrationNotice:text3')}
      {' '}
      <a href={`mailto:${primaryContact?.email}`}>{primaryContact?.email}</a>
      {' '}
      {i18n.t('module:Nurseries:Add:RegistrationNotice:text4')}
    </Typography>
  )

  const renderFooter = () => (
    <FooterActions spaceBetween>
      <FooterActions.Item>
        <Button
          disabled={isSubmitting}
          hierarchy="tertiary"
          label={i18n.t('global:Back')}
          negativeMargins
          onClick={onPreviousStageClick}
        />
      </FooterActions.Item>
      <FooterActions.Item>
        {registrationNotice}
      </FooterActions.Item>
      <FooterActions.Item>
        <Button
          disabled={pristine || submitting}
          isLoading={isSubmitting}
          label={i18n.t('global:Register')}
          negativeMargins
          submit
        />
      </FooterActions.Item>
    </FooterActions>
  )

  return (
    <Form onSubmit={handleSubmit}>
      {renderDetails()}
      {renderFooter()}
    </Form>
  )
}

export default reduxForm({
  destroyOnUnmount: false,
  form: NURSERIES_ADD_FORM,
})(ReviewStage)
