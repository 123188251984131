import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { SHIFT_MAIN_TYPES } from 'services/legacy/membershipRegisters/constants'
import { VIEW_MODE } from 'services/legacy/membershipsShifts/constants'
import { ALL_ROOMS } from 'services/legacy/rooms/constants'
import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'

import { EVENTS, logEvent } from 'analytics'

import { withAppService } from 'services/app'
import { withMembershipsShiftsService } from 'services/legacy/membershipsShifts'
import { withRoomsService } from 'services/legacy/rooms'
import { withNurseriesService } from 'services/nurseries'
import { withModalService } from 'services/utils/modal'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'

import StaffRotaView from './StaffRotaView'

const GROUPS = {
  read: [
    'membershipShift.membershipShiftSettings',
    'membershipShift.parentShift',
    'membershipShift.membershipShiftTimes',
    'membershipShift.dayMembershipShiftTimeStatistics',
    'membershipShift.leaveShiftType',
    'membership',
    'membership.details',
    'membership.profile',
    'membershipShiftSettings',
    'membershipShiftTime',
    'membershipShiftTime.class',
    'membershipShift.leaveShiftType',
    'leaveShiftType',
    'nurseryClass',
  ],
}

const STATISTICS_GROUPS = {
  read: [
    'rotaStatistic.nurseryClass',
    'rotaStatistic.periods',
    'rotaStatisticPeriod',
    'rotaStatisticPeriod.breakdowns',
    'rotaStatisticBreakdown',
    'rotaStatisticPeriod.staffingFlags',
    'staffingFlag',
  ],
}

const NURSERY_SETTINGS_GROUP = {
  read: [
    'nursery.settings',
    'nurserySettings',
    'nurserySettings.staffRota',
    'nurseryStaffRotaSettings',
  ],
}

class StaffRotaContainer extends Component {
  constructor(props) {
    super(props)

    const {
      location: { query: { date, room, viewMode } },
      paginationUtils: { setPageLocationQuery },
    } = props

    this.state = {
      filters: {
        date: date
          ? moment(date, DEFAULT_DATE_FORMAT)
          : moment().startOf('week'),
        day: VIEW_MODE.DAY === viewMode ? moment(date, DEFAULT_DATE_FORMAT) : null,
        room: +room ? room : ALL_ROOMS,
        viewMode: (VIEW_MODE.DAY === viewMode || VIEW_MODE.WEEK === viewMode) ? viewMode : VIEW_MODE.WEEK,
      },
      isDayDataLoading: false,
    }

    setPageLocationQuery(false)
  }

  componentDidMount() {
    const { nurseriesActions, nurseryOptions } = this.props

    nurseriesActions.get(nurseryOptions.id, {
      params: {
        groups: NURSERY_SETTINGS_GROUP,
      },
    })

    this.fetch()

    logEvent(EVENTS.STAFF_ROTA_PAGE_VIEWED)
  }

  fetch = () => {
    const { membershipsShiftsActions, membershipsShiftsSelectors, paginationUtils, setLocationQuery } = this.props
    const { filters } = this.state
    const { date, room } = filters
    const { page } = paginationUtils

    const copyFilters = { ...filters }
    delete copyFilters.day
    delete copyFilters.room

    setLocationQuery({
      ...copyFilters,
      date: moment(copyFilters.date).format(DEFAULT_DATE_FORMAT),
      room: _.isInteger(filters.room?.value) ? filters.room.value : filters.room,
    }, ['room.label', 'room.value'])

    const criteria = membershipsShiftsSelectors.getListCriteria({
      dateFrom: moment(date).startOf('week').format(DEFAULT_DATE_FORMAT),
      dateFromShiftTimes: moment(date).startOf('week').format(DEFAULT_DATE_FORMAT),
      dateTo: moment(date).endOf('week').format(DEFAULT_DATE_FORMAT),
      dateToShiftTimes: moment(date).endOf('week').format(DEFAULT_DATE_FORMAT),
      membershipShiftTimesExists: true,
      room: _.isInteger(filters.room?.value) ? filters.room.value : filters.room,
    })

    membershipsShiftsActions.list({
      mergeResult: 1 !== page,
      params: {
        criteria,
        groups: GROUPS,
        page,
      },
    })

    if (room && 0 !== room?.value) {
      const statisticsCriteria = membershipsShiftsSelectors.getStatisticsCriteria({
        dateFrom: moment(date).endOf('week').format(DEFAULT_DATE_FORMAT),
        room,
      })

      membershipsShiftsActions.getRotaStatistics({
        params: {
          criteria: statisticsCriteria,
          groups: STATISTICS_GROUPS,
        },
      })
    }
  }

  handleChangeDate = (date) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        date: date[0],
        day: VIEW_MODE.DAY === prev.filters.viewMode ? date[0] : null,
      },
    }), () => onPageChange(this.fetch)(1))

    logEvent(EVENTS.STAFF_ROTA_FILTER_USED, { type: 'date' })
  }

  handleChangeRoom = (room) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        room: room?.value,
      },
    }), () => onPageChange(this.fetch)(1))

    logEvent(EVENTS.STAFF_ROTA_FILTER_USED, { type: 'room' })
  }

  handleChangeDay = (index) => {
    this.setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        day: moment(prev.filters.date).add(index, 'days'),
      },
      isDayDataLoading: true,
    }), () => {
      // setTimeout to mimic the day switching in the day view
      // (data are not fetched because they are preloaded form the week view).
      // Without re-rendering the chart was broken due to bug in the library
      // https://github.com/plouc/nivo/issues/1006 => https://share.getcloudapp.com/geu42lb4
      setTimeout(() => {
        this.setState({ isDayDataLoading: false })
      }, 500)
    })

    logEvent(EVENTS.STAFF_ROTA_FILTER_USED, { type: 'day' })
  }

  handleChangeViewMode = (viewMode) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        day: VIEW_MODE.DAY === viewMode?.value
          ? prev.filters.date
          : null,
        viewMode: viewMode?.value,
      },
    }), () => onPageChange(this.fetch)(1))

    logEvent(EVENTS.STAFF_ROTA_FILTER_USED, { type: 'view mode' })
  }

  handleAddShift = () => {
    const { modalActions, modalConsts, nurseryStaffRotaSettings, paginationUtils } = this.props
    const { onPageChange } = paginationUtils
    const { filters: { date } } = this.state

    modalActions.show(modalConsts.TYPES.STAFF_ROTA_SHIFT, {
      disablePastWeek: !nurseryStaffRotaSettings?.pastRotaCanBeEdited,
      initialValues: {
        date: [moment(date).startOf('week'), moment(date).endOf('week')],
      },
      onSuccess: (copy, payload) => {
        logEvent(EVENTS.STAFF_SHIFT_ADDED)

        onPageChange(this.fetch)(1)

        if (copy) {
          modalActions.show(modalConsts.TYPES.STAFF_ROTA_SHIFT_COPY, {
            payload,
          })
        }
      },
    })

    logEvent(EVENTS.STAFF_SHIFT_ADD_CLICKED)
  }

  handleChangePage = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleEditShift = (record) => {
    const { modalActions, modalConsts, nurseryStaffRotaSettings, paginationUtils } = this.props
    const { onPageChange } = paginationUtils
    const { accepted, id: membership, membershipProfile, name, shifts } = record
    const { dateFrom } = _.find(shifts, ({ type }) => SHIFT_MAIN_TYPES.WORK === type) || {}

    modalActions.show(modalConsts.TYPES.STAFF_ROTA_SHIFT, {
      disablePastWeek: !nurseryStaffRotaSettings?.pastRotaCanBeEdited,
      initialValues: {
        date: [moment(dateFrom).startOf('week'), moment(dateFrom).endOf('week')],
        membership: {
          accepted,
          label: name,
          membershipProfile,
          value: membership,
        },
      },
      onSuccess: (copy, payload) => {
        onPageChange(this.fetch)(1)

        if (copy) {
          modalActions.show(modalConsts.TYPES.STAFF_ROTA_SHIFT_COPY, {
            payload,
          })
        }
      },
    })
  }

  handleExportClick = () => {
    const { modalActions, modalConsts } = this.props
    const { filters } = this.state
    const { date } = filters

    logEvent(EVENTS.STAFF_ROTA_EXPORT_BTN_CLICKED)

    modalActions.show(modalConsts.TYPES.STAFF_ROTA_EXPORT, {
      date,
    })
  }

  handleAddLeave = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.STAFF_LEAVE_ADD, {
      onSuccess: this.fetch,
    })
  }

  render() {
    const {
      authAccessMap,
      isFetching,
      nurseryStaffRotaSettings,
      paginationUtils,
      records,
      statistics,
      totalResults,
    } = this.props
    const { filters, isDayDataLoading } = this.state
    const { date, day, room, viewMode } = filters
    const { getPageCount, page } = paginationUtils
    const dateRange = [date, moment(date).endOf('week')]

    const pageCount = getPageCount(totalResults)

    return (
      <StaffRotaView
        authAccessMap={authAccessMap}
        date={date}
        dateRange={dateRange}
        day={day}
        displayPastWeekBanner={!nurseryStaffRotaSettings?.pastRotaCanBeEdited}
        isDayDataLoading={isDayDataLoading}
        isFetching={isFetching}
        page={page}
        pageCount={pageCount}
        records={records}
        room={room}
        statistics={statistics}
        viewMode={viewMode}
        onAddLeave={this.handleAddLeave}
        onAddShift={this.handleAddShift}
        onChangeDate={this.handleChangeDate}
        onChangeDay={this.handleChangeDay}
        onChangePage={this.handleChangePage}
        onChangeRoom={this.handleChangeRoom}
        onChangeViewMode={this.handleChangeViewMode}
        onEditShift={this.handleEditShift}
        onExportClick={this.handleExportClick}
      />
    )
  }
}

StaffRotaContainer.authParams = {
  flags: [FEATURE_FLAGS.STAFF_REGISTER],
  nurseryContext: true,
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.DEPUTY_MANAGER,
    ROLES.ROOM_LEADER,
    ROLES.SENIOR_TEACHER,
    ROLES.TEACHER,
  ],
}

const mapState = (state, {
  appSelectors,
  membershipsShiftsListState,
  membershipsShiftsSelectors,
  membershipsShiftsStatisticsState,
  nurseriesSelectors,
  nurseriesSingleState,
  params,
}) => ({
  authAccessMap: {
    section: {
      isStaffRotaWritePermissionGrated: auth.SELECTORS.getIsAuthorised(state, {
        roles: [
          ROLES.SUPER_ADMIN,
          ROLES.ORGANIZATION_DIRECTOR,
          ROLES.ORGANIZATION_NATIONAL_ADMIN,
          ROLES.ORGANIZATION_FINANCE_ADMIN,
          ROLES.ORGANIZATION_LINE_MANAGER,
          ROLES.NURSERY_MANAGER,
          ROLES.NURSERY_ADMIN,
        ],
      }),
    },
  },
  isFetching: appSelectors.getIsFetching(
    membershipsShiftsListState,
    membershipsShiftsStatisticsState,
    nurseriesSingleState,
  ),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  nurseryStaffRotaSettings: nurseriesSelectors.getNurseryStaffRotaSettings(state),
  records: membershipsShiftsSelectors.getMembershipsShiftsListDataSelector(state),
  statistics: membershipsShiftsSelectors.getRotaStatisticsSelector(state),
  totalResults: appSelectors.getTotalResults(membershipsShiftsListState),
})

const enhance = compose(
  withAppService,
  withNurseriesService,
  withMembershipsShiftsService,
  withModalService,
  withPaginationUtils,
  withRoomsService,
  withRouterUtils,
  connect(mapState),
)

export default enhance(StaffRotaContainer)
