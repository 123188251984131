import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'
// eslint-disable-next-line
import { ItemAddFormValues } from 'module/Management/ManagementFinanceSettings/ManagementItems/v3/ManagementItemsAdd/components/ItemAddForm/ItemAddForm'

import { NurseryItemProduct } from '../models'
import { getPriceChangesBody, getPriceChangesInitialValues } from '../../selectors'

export const getNurseryItemSingleState = (state: RootState) => state.nurseryItem.single

export const getNurseryItemSingleDataState = createSelector(
  [getNurseryItemSingleState],
  (state) => state.data,
)

export const getBody = (params: ItemAddFormValues) => {
  const body = {} as NurseryItemProduct
  const { name, priceChanges } = params || {}

  if (name) {
    body.name = name
  }

  if (priceChanges) {
    const finalPriceChanges = getPriceChangesBody(priceChanges)

    if (finalPriceChanges.length) {
      body.priceChanges = finalPriceChanges
    }
  }

  return body
}

export const getInitialValues = (nurseryFunding: NurseryItemProduct) => {
  const { name, priceChanges } = nurseryFunding || {}
  const initialValues = {} as ItemAddFormValues

  if (name) {
    initialValues.name = name
  }

  initialValues.priceChanges = getPriceChangesInitialValues(priceChanges)

  return initialValues
}
