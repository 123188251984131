import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as dailyDiaryNappiesSelectors from './selectors'

const withDailyDiaryNappiesService = (WrappedComponent) => {
  const mapState = ({ dailyDiaryNappies }) => ({ dailyDiaryNappies })

  const mapDispatch = injectActions('dailyDiaryNappiesActions', actions)

  const Component = ({ dailyDiaryNappies, dailyDiaryNappiesActions, ...others }) => (
    <WrappedComponent
      dailyDiaryNappiesActions={dailyDiaryNappiesActions}
      dailyDiaryNappiesListState={dailyDiaryNappies.list}
      dailyDiaryNappiesListWithoutRecordsState={dailyDiaryNappies.listWithoutRecords}
      dailyDiaryNappiesSelectors={dailyDiaryNappiesSelectors}
      dailyDiaryNappiesSingleState={dailyDiaryNappies.single}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withDailyDiaryNappiesService
