import _ from 'lodash'
import { createSelector } from 'reselect'

const getCarersState = (state) => state.carers

export const getCarersListState = createSelector(
  [getCarersState],
  (state) => state.list.data,
)

export const getCarersOptionsDropdownData = createSelector(
  [getCarersListState],
  (carers) => {
    if (!carers?.length) {
      return null
    }

    return _.map(carers, ({ fullName, id, photo }) => ({
      avatar: photo,
      label: fullName,
      value: id,
    }))
  },
)

export const getListCriteria = (params) => {
  const { childId, parentalResponsibilityOnly, search } = params
  const criteria = []

  if (childId) {
    criteria.push({
      field: 'carerChildRelations.child',
      value: childId,
    })
  }

  if (null !== parentalResponsibilityOnly && undefined !== parentalResponsibilityOnly) {
    criteria.push({
      field: 'carerChildRelations.hasParentalResponsibility',
      value: 1,
    })
  }

  if (search) {
    criteria.push({
      field: 'concatenable',
      value: search,
    })
  }

  return criteria
}
