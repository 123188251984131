import React from 'react'

import { CircleIcon, Typography } from 'components'

import { StyledContainer } from './InfoStyle'

const Info = ({
  color,
  text,
}) => (
  <StyledContainer>
    <CircleIcon
      background={color}
      color="#FFF"
      icon="info"
      iconSize={14}
      size={20}
    />
    <Typography color={color} margin={'0 0 0 10px'} bold>
      {text}
    </Typography>
  </StyledContainer>
)

export default Info
