import _ from 'lodash'

import React from 'react'

import { convertToPercent } from 'utils/data'

import { COMPARISON_REPORT_TYPES } from 'services/legacy/cohortTracking/constants'
import { GENDER } from 'services/legacy/child/constants'

import { Chart, Typography } from 'components'

import i18n from 'translations'

import {
  StyledChartWrapper,
  StyledEmptyState,
  StyledFooterDescription,
  StyledPartWrapper,
} from './ComparisonChartStyled'

const ComparisonChart = ({
  frameworkDetails,
  onFilterComparisonTable,
  progressLevels,
  records,
  report: fullReport,
  reportType,
  terms,
}) => {
  const { structure } = frameworkDetails || {}
  const { cohort } = structure || {}
  const { comparison } = cohort || {}

  const initialData = _.map(progressLevels, ({
    colour,
    id,
    name,
  }) => ({
    color: colour,
    extra: {
      originalId: id,
      records: _.filter(records, ({ report }) => (
        _.find(report.ageBands, (ageBand) => (
          id === ageBand?.progressLevel?.id
        ))
      )),
    },
    id: name,
    label: name,
    progressLevel: id,
  }))

  const getRecords = (condition) => {
    const result = _.map(initialData, (progressLevel) => {
      const rest = { ...progressLevel }
      delete rest.extra

      const value = _.filter(progressLevel.extra.records, condition)

      return {
        ...rest,
        data: value,
        value: value?.length,
      }
    })

    const finalResult = _.filter(result, ({ value }) => 0 < value)
    const totalValue = _.reduce(finalResult, (total, item) => total + item.value, 0)

    return _.map(finalResult, (item) => ({
      ...item,
      percentage: convertToPercent(totalValue, item.value),
    }))
  }

  const generateChartData = () => {
    if (COMPARISON_REPORT_TYPES.GENDER === reportType) {
      return [
        {
          data: getRecords(({ child }) => (
            GENDER.MALE === child.gender
          )),
          title: i18n.t('module:Learning:CohortTracking:ComparisonReport:comparisonChart:Boys'),
        },
        {
          data: getRecords(({ child }) => (
            GENDER.FEMALE === child.gender
          )),
          title: i18n.t('module:Learning:CohortTracking:ComparisonReport:comparisonChart:Girls'),
        },
      ]
    }

    if (COMPARISON_REPORT_TYPES.ETHNICITY === reportType) {
      return [
        {
          data: getRecords(({ report }) => (
            report.childSnapshot?.ethnicity?.id === fullReport.ethnicity.id
          )),
          title: fullReport.ethnicity.name || '-',
        },
        {
          data: getRecords(({ report }) => (
            report.childSnapshot?.ethnicity?.id !== fullReport.ethnicity.id
          )),
          title: _.upperFirst(i18n.t('global:other')),
          total: _.filter(records, ({ report }) => (
            report.childSnapshot?.ethnicity?.id !== fullReport.ethnicity.id
          ))?.length,
        },
      ]
    }

    if (COMPARISON_REPORT_TYPES.EYPP === reportType) {
      return [
        {
          data: getRecords(({ report }) => (
            report?.childSnapshot?.doesReceiveEYPPFunding
          )),
          title: i18n.t('module:Learning:CohortTracking:ComparisonReport:comparisonChart:EYPP'),
        },
        {
          data: getRecords(({ report }) => (
            !report?.childSnapshot?.doesReceiveEYPPFunding
          )),
          title: i18n.t('module:Learning:CohortTracking:ComparisonReport:comparisonChart:nonEYPP'),
        },
      ]
    }

    if (COMPARISON_REPORT_TYPES.SUMMER_BORN === reportType) {
      return [
        {
          data: getRecords(({ child }) => (
            child.isSummerBorn
          )),
          title: i18n.t('module:Learning:CohortTracking:ComparisonReport:comparisonChart:summerBorn'),
        },
        {
          data: getRecords(({ child }) => (
            !child.isSummerBorn
          )),
          title: i18n.t('module:Learning:CohortTracking:ComparisonReport:comparisonChart:nonSummerBorn'),
        },
      ]
    }

    return [initialData, initialData]
  }

  const data = generateChartData()

  const renderEmptyState = (props) => {
    if (props.data.length) {
      return null
    }

    return (
      <StyledEmptyState>
        {i18n.t('module:Learning:CohortTracking:ComparisonReport:comparisonChart:notFound', {
          childrenText: COMPARISON_REPORT_TYPES.GENDER !== reportType
            ? i18n.t('module:Learning:CohortTracking:ComparisonReport:comparisonChart:notFoundChildren')
            : '',
          interpolation: { escapeValue: false },
          label: props.title,
          term: _.find(terms, ({ value }) => fullReport.term === value)?.label,
        })}
      </StyledEmptyState>
    )
  }

  const renderPart = (props) => (
    <StyledPartWrapper key={props.title}>
      {renderEmptyState(props)}
      <Chart.Pie
        {...props}
        title={`${props.title} (${props.total || _.sumBy(props.data, ({ value }) => value)})`}
        withCustomColors
        withoutLegends
        onClick={({ data: { progressLevel } }) => onFilterComparisonTable(progressLevel, props)}
      />
    </StyledPartWrapper>
  )

  const renderFooter = () => {
    let frameworkGroup = null

    const frameworkArea = _.find(frameworkDetails?.areas, ({ groups }) => {
      const result = _.find(groups, ({ id }) => (
        fullReport.frameworkGroup.id === id
      ))

      if (result) {
        frameworkGroup = result
      }

      return !!result
    })

    return (
      <StyledFooterDescription>
        {i18n.t('module:Learning:CohortTracking:ComparisonReport:comparisonChart:footerDescription')}
        <Typography margin="3px 0 0">
          {comparison?.area?.visible && (
            <Typography variant="span" bold>
              {frameworkArea.name}
            </Typography>
          )}
          {comparison?.group?.visible
          && frameworkGroup
          && frameworkGroup?.name !== frameworkArea?.name
          && (
            <Typography variant="span" bold>
              &nbsp;
              {` > ${frameworkGroup.name}`}
            </Typography>
          )}
        </Typography>
      </StyledFooterDescription>
    )
  }

  return (
    <React.Fragment>
      <StyledChartWrapper>
        {_.map(data, renderPart)}
      </StyledChartWrapper>
      {renderFooter()}
    </React.Fragment>
  )
}

export default ComparisonChart
