import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { ALL_STAFF_MEMBERS } from 'module/Modals/Staff/constants'

import { isRequired } from 'utils/fieldValidation'

import { Form } from 'components'

import i18n from 'translations'

import ExportFooterActions from 'module/Modals/Staff/components/ExportFooterActions'

import { BREAKDOWN_OPTIONS } from '../constants'
import { getReportDatesOptions } from '../helpers'

export const STAFF_WORKLOG_EXPORT_MODAL_FORM = 'StaffWorklogExportModalForm'

const ExportModalForm = ({
  formValues,
  handleSubmit,
  isProcessing,
  onBreakdownChange,
  onCloseClick,
  onReportDateChange,
  onSubmit,
}) => {
  const renderForm = () => (
    <React.Fragment>
      <Form.Row
        label={i18n.t('module:Modals:Staff:Export:SendTo:label')}
        vertical
        verticalLabel
      >
        <Field
          component={Form.InfiniteDropdowns.OrganizationMemberships}
          name="sendTo"
          placeholder={i18n.t('module:Modals:Staff:Export:SendTo:placeholder')}
          validate={isRequired}
        />
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Modals:Staff:Export:Breakdown:label')}
        verticalLabel
      >
        <Field
          component={Form.RadioGroup}
          name="breakdown"
          options={BREAKDOWN_OPTIONS}
          validate={isRequired}
          horizontal
          onChange={onBreakdownChange}
        />
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Modals:Staff:Export:StaffMember:label')}
        vertical
        verticalLabel
      >
        <Field
          component={Form.InfiniteDropdowns.Memberships}
          extraOptions={[ALL_STAFF_MEMBERS]}
          extraParams={{
            nonTeaching: true,
          }}
          name="staffMembers"
          placeholder={i18n.t('module:Modals:Staff:Export:StaffMember:placeholder')}
          validate={isRequired}
        />
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Modals:Staff:Export:ReportDates:label')}
        verticalLabel
      >
        <Field
          component={Form.RadioGroup}
          name="reportDates"
          options={getReportDatesOptions(formValues?.breakdown)}
          validate={isRequired}
          horizontal
          onChange={onReportDateChange}
        />
      </Form.Row>
      <Form.Row>
        <Field
          component={Form.DatePicker}
          name="reportDatesRange"
          type={formValues?.reportDates}
          validate={isRequired}
          range
        />
      </Form.Row>
    </React.Fragment>
  )

  const renderFooter = () => (
    <ExportFooterActions
      isProcessing={isProcessing}
      onCloseClick={onCloseClick}
    />
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {renderForm()}
      {renderFooter()}
    </Form>
  )
}

export default reduxForm({ form: STAFF_WORKLOG_EXPORT_MODAL_FORM })(ExportModalForm)
