import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'
import { ModalType } from 'modals'

import { generateRoute } from 'utils/routing'

import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withClosureDayTypesService, withClosureDayTypesServiceProps } from 'services/closureDayTypes'
import { withRouter, withRouterProps } from 'services/router'

import i18n from 'translations'

import AddView from './AddView'
import { getInitialValues } from './AddHelpers'
import { FormFields } from './models'

type AddContainerProps = withClosureDayTypesServiceProps
  & withModalServiceProps
  & withRouterProps

const mapState = (state: RootState, { closureDayTypesSelectors }: AddContainerProps) => ({
  closureDayType: closureDayTypesSelectors.getClosureDayType(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const AddContainer: React.FC<AddContainerProps & PropsFromRedux> = ({
  closureDayTypesActions,
  closureDayTypesSelectors,
  modalActions,
  navigate,
  params,
}) => {
  const [initialValues, setInitialValues] = useState<FormFields>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isArchived, setIsArchived] = useState(false)
  const [isFetching, setIsFetching] = useState(() => !!params.closureDayTypeId)

  useEffect(() => {
    if (params.closureDayTypeId) {
      closureDayTypesActions.get({
        onSuccess: ({ data }) => {
          setIsArchived(data.archived)
          setInitialValues(getInitialValues(data))
          setIsFetching(false)
        },
        onlyData: true,
        params: [params.closureDayTypeId],
      })
    }

    return () => {
      closureDayTypesActions.clearList()
    }
  }, [])

  const handleSubmitSuccess = () => {
    setIsSubmitting(false)
    navigate(generateRoute('MANAGEMENT.CLOSURE_DAYS.CLOSURE_DAY_TYPES'))
  }

  const handleSubmit = (fields) => {
    setIsSubmitting(true)

    const isEditMode = !!params.closureDayTypeId

    const body = closureDayTypesSelectors.getBody(fields)

    if (isEditMode) {
      closureDayTypesActions.update({
        body,
        onFailed: () => setIsSubmitting(false),
        onSuccess: handleSubmitSuccess,
        params: [params.closureDayTypeId],
      })

      return
    }

    closureDayTypesActions.create({
      body,
      onFailed: () => setIsSubmitting(false),
      onSuccess: handleSubmitSuccess,
    })
  }

  const archiveClosureDayType = (archived) => closureDayTypesActions.update({
    body: { archived },
    onSuccess: handleSubmitSuccess,
    params: [params.closureDayTypeId],
  })

  const handleArchiveClick = (archived) => {
    const label = archived ? i18n.t('global:archive') : i18n.t('global:unarchive')
    const text = archived
      ? i18n.t('module:Management:ClosureDays:ClosureDayTypes:Add:archivePopupCopy')
      : i18n.t('module:Management:ClosureDays:ClosureDayTypes:Add:unarchivePopupCopy')

    modalActions.show<ModalType.CONFIRM>(ModalType.CONFIRM, {
      confirmButtonLabel: label,
      icon: label,
      onConfirm: () => archiveClosureDayType(archived),
      text,
    })
  }

  return (
    <AddView
      initialValues={initialValues}
      isArchived={isArchived}
      isEdit={!!params.closureDayTypeId}
      isLoading={isFetching}
      isSubmitting={isSubmitting}
      onArchiveClick={handleArchiveClick}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withModalService,
  withRouter,
  withClosureDayTypesService,
  connector,
)

export default enhance(AddContainer)
