import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_CHILD_BOOKING_LIST,
  GET_CHILD_BOOKING_LIST,
  GET_CHILD_BOOKING_LIST_FAILED,
  GET_CHILD_BOOKING_LIST_SUCCESS,
} from './constants'

export const list = (
  options: BasicActionOptionsProps,
) => ({ childBookingApiClient }) => generateBasicActions.list({
  constants: {
    failed: GET_CHILD_BOOKING_LIST_FAILED,
    init: GET_CHILD_BOOKING_LIST,
    success: GET_CHILD_BOOKING_LIST_SUCCESS,
  },
  options,
  service: childBookingApiClient,
  serviceMethod: 'list',
})

export const clearList = () => ({
  type: CLEAR_CHILD_BOOKING_LIST,
})
