import styled from 'styled-components'

export const StyledActionsContainer = styled.div`
  margin-top: 25px;

  & > div > div > div {
    padding-right: 40px;
  }
`

export const StyledDropdownItemContainer = styled.div`
  padding: 6px 12px;
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 10px;
`
