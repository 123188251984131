import ServiceBase from 'services/ServiceBase'

import { getQueryString, getQueryStringV2 } from 'utils/api'

class ChildAdhocSessionsApiClient extends ServiceBase {
  list = (childId, params, v2) => {
    if (v2) {
      return this.request({
        path: `/v2/children_extra_sessions?${getQueryStringV2(params)}`,
      })
    }

    return this.request({
      path: `/children/${childId}/extras/sessions?${getQueryString(params)}`,
    })
  }

  get = (childId, id, params) => this.request({
    path: `/children/${childId}/extras/sessions/${id}?${getQueryString(params)}`,
  })

  create = (childId, payload) => this.request({
    body: payload,
    method: 'POST',
    path: `/children/${childId}/extras/sessions`,
  })

  update = (childId, id, payload) => this.request({
    body: payload,
    method: 'PATCH',
    path: `/children/${childId}/extras/sessions/${id}`,
  })

  archive = (id, payload) => this.request({
    body: payload,
    method: 'PUT',
    path: `/v2/children_extra_sessions/${id}`,
  })
}

export default ChildAdhocSessionsApiClient
