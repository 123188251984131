import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import auth from 'utils/auth'

import { FieldError, Form, InfiniteDropdowns, Label, TimePicker } from 'components'

import i18n from 'translations'

import {
  StyledContainer,
  StyledContentContainer,
  StyledErrorContainer,
  StyledGroupContainer,
  StyledItemContainer,
} from './FormNurserySessionStyled'

const FormNurserySession = ({
  deletable = true,
  disabled,
  dropdownWidth,
  input: { onChange, value },
  isFinanceV3Enabled,
  meta: { error, submitFailed, valid },
  onDeleteClick,
  showHoursByDefault,
}) => {
  const handleChange = (type) => (val) => {
    let newVal

    if ('sessionId' === type) {
      newVal = { ...value, sessionId: val }
    }

    if ('startTime' === type) {
      newVal = { ...value, startTime: val }
    }

    if ('endTime' === type) {
      newVal = { ...value, endTime: val }
    }

    return onChange && onChange(newVal)
  }

  const { endTime, sessionId, startTime } = value || {}
  const { endTime: sessionEndTime, hourly, isHourly, startTime: sessionStartTime } = sessionId || {}

  const isHourlyFinal = isHourly || hourly
  const newStartTime = isHourlyFinal ? startTime : sessionStartTime
  const newEndTime = isHourlyFinal ? endTime : sessionEndTime

  const renderNurserySessionsDropdown = (props) => {
    if (isFinanceV3Enabled) {
      return (
        <InfiniteDropdowns.NurserySessionsV3 {...props} />
      )
    }

    return (
      <InfiniteDropdowns.NurserySessions {...props} />
    )
  }

  return (
    <StyledContainer>
      <StyledContentContainer>
        <StyledGroupContainer>
          <StyledItemContainer width={dropdownWidth}>
            {renderNurserySessionsDropdown({
              disabled,
              extraFields: [
                'isHourly',
                'hourly',
                'startTime',
                'endTime',
                'costTwoYears',
                'costTwoYearsAbove',
                'costUnderTwo',
              ],
              onChange: handleChange('sessionId'),
              valid: !(submitFailed && !valid),
              value: value.sessionId,
            })}
          </StyledItemContainer>
          {deletable && !disabled && (
            <StyledItemContainer>
              <Form.RemoveRowButton label={false} onClick={onDeleteClick} />
            </StyledItemContainer>
          )}
        </StyledGroupContainer>
        {(showHoursByDefault || isHourlyFinal) && (
          <StyledGroupContainer>
            <StyledItemContainer>
              <Label text={i18n.t('global:From')} />
            </StyledItemContainer>
            <StyledItemContainer>
              <TimePicker
                clearable={false}
                disabled={!isHourlyFinal || disabled}
                local={false}
                valid={!(submitFailed && !valid)}
                value={newStartTime}
                onChange={handleChange('startTime')}
              />
            </StyledItemContainer>
          </StyledGroupContainer>
        )}
        {(showHoursByDefault || isHourlyFinal) && (
          <StyledGroupContainer>
            <StyledItemContainer>
              <Label text={i18n.t('global:To')} />
            </StyledItemContainer>
            <StyledItemContainer>
              <TimePicker
                clearable={false}
                disabled={!isHourlyFinal || disabled}
                local={false}
                valid={!(submitFailed && !valid)}
                value={newEndTime}
                onChange={handleChange('endTime')}
              />
            </StyledItemContainer>
          </StyledGroupContainer>
        )}
      </StyledContentContainer>
      {submitFailed && (
        <StyledErrorContainer>
          <FieldError error={error} />
        </StyledErrorContainer>
      )}
    </StyledContainer>
  )
}

const mapState = (state) => ({
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
})

const enhance = compose(
  connect(mapState),
)

export default enhance(FormNurserySession)
