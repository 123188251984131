import React from 'react'

import { FLAG_COLOURS } from 'constants/colors'

import { Callout, Page, Section, Spinner, Toolbar } from 'components'

import i18n from 'translations'

import StaffLeaveTypesAddForm from './components/StaffLeaveTypesAddForm'

const StaffLeaveTypesAddView = ({
  errorMessages,
  initialValues,
  isArchived,
  isEdit,
  isFormLoading,
  isLoading,
  onArchiveClick,
  onSubmit,
}) => {
  const title = isEdit
    ? i18n.t('module:Management:LeaveTypes:Staff:Edit:title')
    : i18n.t('module:Management:LeaveTypes:Staff:Add:title')
  const subtitle = isEdit
    ? i18n.t('module:Management:LeaveTypes:Staff:Edit:subtitle')
    : i18n.t('module:Management:LeaveTypes:Staff:Add:subtitle')
  const tooltip = isEdit
    && i18n.t('module:Management:LeaveTypes:Staff:Edit:tooltip')

  const renderContent = () => {
    if (isLoading || (isEdit && !initialValues)) {
      return <Spinner />
    }

    return (
      <StaffLeaveTypesAddForm
        initialValues={initialValues}
        isLoading={isFormLoading}
        onSubmit={onSubmit}
      />
    )
  }

  const actions = isEdit && !isLoading && (
    <Section.Actions
      options={[{
        onClick: onArchiveClick(!isArchived),
        type: isArchived ? 'unarchive' : 'archive',
      }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading || (isEdit && !initialValues)}
      title={title}
    >
      <Callout content={errorMessages} error />
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>{subtitle}</Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      {isEdit && (
        <Callout
          color={FLAG_COLOURS.INFO}
          content={tooltip}
          icon="info"
          iconVariant="circle"
        />
      )}
      {renderContent()}
    </Page.Section>
  )
}

export default StaffLeaveTypesAddView
