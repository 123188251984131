import _ from 'lodash'
import moment from 'moment'
import { flatten } from 'utils/flatnest'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { generateRoute } from 'utils/routing'

import { Avatar, Currency, Hyperlink, ReportView, Typography } from 'components'

import i18n from 'translations'

import { NURSERY_TABLE_COLUMNS, ORGANIZATION_TABLE_COLUMNS } from './constants'

export const getColumns = (isOrganizationContext) => {
  if (isOrganizationContext) {
    return ORGANIZATION_TABLE_COLUMNS
  }

  return NURSERY_TABLE_COLUMNS
}

export const getTableData = ({ data, filters, hasError, isOrganizationContext }) => {
  if (!data?.length || hasError) {
    return null
  }

  if (isOrganizationContext) {
    const queryString = new URLSearchParams(flatten({
      date: filters?.date ? {
        after: filters.date.after.format(DEFAULT_DATE_FORMAT),
        before: filters.date.before.format(DEFAULT_DATE_FORMAT),
      } : undefined,
    })).toString()

    return _.map(data, ({
      amount,
      count,
      resource,
    }) => ({
      amount: <Currency value={amount} />,
      count,
      name: (
        <ReportView.NurseryLink
          nursery={resource}
          queryString={queryString}
          routeKey="FINANCE.REPORT.CREDIT_REPORT"
        />
      ),
    }))
  }

  return _.map(data, ({ resource }) => {
    const {
      author,
      child,
      createdAt,
      description,
      id,
      invoice,
      number,
      total,
    } = resource
    const { firstName, id: childId, photo, surname } = child || {}

    return {
      amount: (
        <Currency value={total} />
      ),
      author: author.fullName,
      child: (
        <Avatar
          avatarSize="small"
          initials={[firstName, surname]}
          src={photo}
          title={`${firstName} ${surname}`}
          primary
        />
      ),
      createdAt: moment(createdAt).format('DD/MM/YYYY'),
      creditNoteNumber: (
        <Hyperlink to={generateRoute('CHILDREN.CHILD.FINANCE.CREDIT_NOTES', { childId, creditNoteId: id })} primary>
          {number}
        </Hyperlink>
      ),
      creditNoteReason: (
        <Typography wordBreak="break-word">
          {description}
        </Typography>
      ),
      id,
      invoiceLinked: (
        <Hyperlink
          to={generateRoute('CHILDREN.CHILD.FINANCE.SINGLE_INVOICE', { childId, invoiceId: invoice.id })}
          primary
        >
          {invoice.number}
        </Hyperlink>
      ),
    }
  })
}

export const getStatistics = ({ data, hasError, isOrganizationContext }) => {
  if (!data || !isOrganizationContext || hasError) {
    return null
  }

  return [
    {
      label: i18n.t('module:Finance:Reports:CreditNotes:Organization:Statistics:totalCreditNotes'),
      value: data.count,
    },
    {
      label: i18n.t('module:Finance:Reports:CreditNotes:Organization:Statistics:totalCreditNoteAmount'),
      value: <Currency value={data.amount} />,
    },
  ]
}
