import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withChildService, withLegacyChildBankDetailsService } from 'services/legacy/child'
import { withRouter } from 'services/router'

import { getInitialValues, getPayload } from './ChildFinanceAddBankHelpers'
import ChildFinanceAddBankView from './ChildFinanceAddBankView'

const CHILD_BANK_DETAILS_GROUPS = {
  read: [
    'child.childBankDetail',
    'childBankDetail',
    'childBankDetail.paymentContact',
    'childBankDetail.notes',
    'childBankDetail.usualPaymentMethod',
    'paymentType',
    'carerChildRelation.carer',
  ],
}

class ChildFinanceAddBankContainer extends Component {
  componentDidMount() {
    const { childActions, params } = this.props

    childActions.getBankDetails({
      params: [params.childId, {
        groups: CHILD_BANK_DETAILS_GROUPS,
      }],
    })
  }

  componentWillUnmount() {
    const { childActions } = this.props

    childActions.clearBankDetails()
  }

  goToContactDetails = () => {
    const { navigate, params } = this.props

    navigate(`/children/${params.childId}/finance/invoicing`)
  }

  handleSubmitSuccess = () => {
    this.goToContactDetails()
  }

  handleSubmit = (fields) => {
    const { isEdit, legacyChildBankDetailsActions, params } = this.props

    const payload = getPayload(fields)

    if (isEdit) {
      return legacyChildBankDetailsActions.update(params.childId, payload, this.handleSubmitSuccess)
    }

    return legacyChildBankDetailsActions.create(params.childId, payload, this.handleSubmitSuccess)
  }

  render() {
    const {
      bankData,
      child,
      childBankDetailsState,
      errorMessages,
      isUkCountry,
    } = this.props

    const initialValues = getInitialValues(bankData)
    const isLoading = childBankDetailsState.isLoading || !child
    const isSubmitting = childBankDetailsState.isSubmitting

    return (
      <ChildFinanceAddBankView
        child={child}
        errorMessages={errorMessages}
        initialValues={initialValues}
        isLoading={isLoading}
        isSubmitting={isSubmitting}
        isUkCountry={isUkCountry}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, { appSelectors, childBankDetailsState, childSelectors, nurseriesSelectors }) => ({
  bankData: childSelectors.getBankDetailsSelector(state),
  child: childSelectors.getChildSelector(state),
  errorMessages: appSelectors.getErrorMessages(childBankDetailsState),
  isEdit: childSelectors.isChildPaymentContactExists(state),
  isUkCountry: nurseriesSelectors.getIsUkCountry(state),
})

const enhance = compose(
  withAppService,
  withRouter,
  withNurseriesService,
  withChildService,
  withLegacyChildBankDetailsService,
  connect(mapState),
)

export default enhance(ChildFinanceAddBankContainer)
