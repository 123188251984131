import { generateBasicActions } from 'utils/service'

import {
  AUTO_CALCULATE_INVOICE,
  AUTO_CALCULATE_INVOICE_FAILED,
  AUTO_CALCULATE_INVOICE_SUCCESS,
  AUTO_REFRESH_INVOICE,
  AUTO_REFRESH_INVOICE_FAILED,
  AUTO_REFRESH_INVOICE_SUCCESS,
  CLEAR_INVOICE,
  CREATE_INVOICE,
  CREATE_INVOICE_FAILED,
  CREATE_INVOICE_SUCCESS,
  DELETE_INVOICE,
  DELETE_INVOICE_FAILED,
  DELETE_INVOICE_SUCCESS,
  DOWNLOAD_INVOICE,
  DOWNLOAD_INVOICE_FAILED,
  DOWNLOAD_INVOICE_SUCCESS,
  GET_INVOICE,
  GET_INVOICE_FAILED,
  GET_INVOICE_SUCCESS,
  UPDATE_INVOICE,
  UPDATE_INVOICE_FAILED,
  UPDATE_INVOICE_SUCCESS,
} from './constants'

export const get = (id, params, onSuccess) => ({ invoicesApiClient }) => (dispatch) => {
  dispatch({
    id,
    type: GET_INVOICE,
  })

  invoicesApiClient.get(id, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: GET_INVOICE_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: GET_INVOICE_FAILED,
      })
    },
  )
}

export const create = (payload, params, onSuccess, onConflict) => ({ invoicesApiClient }) => (dispatch) => {
  dispatch({
    params,
    payload,
    type: CREATE_INVOICE,
  })

  invoicesApiClient.create(payload, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: CREATE_INVOICE_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: CREATE_INVOICE_FAILED,
      })

      if (onConflict) {
        onConflict(error.response)
      }
    },
  )
}

export const update = (id, payload, params, onSuccess, onConflict) => ({ invoicesApiClient }) => (dispatch) => {
  dispatch({
    id,
    params,
    payload,
    type: UPDATE_INVOICE,
  })

  invoicesApiClient.update(id, payload, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: UPDATE_INVOICE_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: UPDATE_INVOICE_FAILED,
      })

      if (onConflict) {
        onConflict(error.response)
      }
    },
  )
}

export const remove = (id, onSuccess) => ({ invoicesApiClient }) => (dispatch) => {
  dispatch({
    id,
    type: DELETE_INVOICE,
  })

  invoicesApiClient.delete(id).then(
    (data) => {
      dispatch({
        payload: data,
        type: DELETE_INVOICE_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: DELETE_INVOICE_FAILED,
      })
    },
  )
}

export const download = (id, onSuccess) => ({ invoicesApiClient }) => (dispatch) => {
  dispatch({
    id,
    type: DOWNLOAD_INVOICE,
  })

  invoicesApiClient.download(id).then(
    (data) => {
      dispatch({
        payload: data,
        type: DOWNLOAD_INVOICE_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: DOWNLOAD_INVOICE_FAILED,
      })
    },
  )
}

export const autoCalculate = (options) => ({ invoicesApiClient }) => generateBasicActions.create({
  constants: {
    failed: AUTO_CALCULATE_INVOICE_FAILED,
    init: AUTO_CALCULATE_INVOICE,
    success: AUTO_CALCULATE_INVOICE_SUCCESS,
  },
  options,
  service: invoicesApiClient,
  serviceMethod: 'autoCalculate',
})

export const autoRefresh = (id, params, onSuccess) => ({ invoicesApiClient }) => (dispatch) => {
  dispatch({
    id,
    type: AUTO_REFRESH_INVOICE,
  })

  invoicesApiClient.get(id, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: AUTO_REFRESH_INVOICE_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: AUTO_REFRESH_INVOICE_FAILED,
      })
    },
  )
}

export const clear = () => ({
  type: CLEAR_INVOICE,
})
