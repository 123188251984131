import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 100%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
`
