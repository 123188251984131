import _ from 'lodash'

import React from 'react'

import { DateString } from 'components'

export const getTableData = (data) => _.map(data, ({ closureDayType, id, name, periodTimes }) => {
  const { endDate, startDate } = periodTimes?.[0] || {}

  return {
    closureDayType: closureDayType?.name,
    endDate: <DateString date={endDate} />,
    id,
    name,
    startDate: <DateString date={startDate} />,
  }
})
