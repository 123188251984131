import styled from 'styled-components'

export const StyledContentRow = styled.div`
  display: grid;
  grid-gap: 5px;
  align-items: center;
  width: 100%;
`

export const StyledDetailsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
  align-items: center;
  
  ${({ isArchived }) => isArchived && `
    grid-template-columns: 63px 1fr;
  `}
`

export const StyledNewChannelContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`
