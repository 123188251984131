import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class CommentsApiClient extends ServiceBase {
  list = (params) => this.request({
    path: `/v2/comments?${getQueryStringV2(params)}`,
  })

  create = (params, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/comments?${getQueryStringV2(params)}`,
  })
}

export default CommentsApiClient
