import { GET_ROTA_STATISTICS, GET_ROTA_STATISTICS_FAILED, GET_ROTA_STATISTICS_SUCCESS } from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case GET_ROTA_STATISTICS:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_ROTA_STATISTICS_SUCCESS:
      return { ...state, data: payload.data, isFetching: false }
    case GET_ROTA_STATISTICS_FAILED:
      return { ...state, error, isFetching: false }
    default:
      return state
  }
}
