import { Property } from 'csstype'

import React, { PropsWithChildren } from 'react'

import { typeByObject } from 'utils/typescript'

import { SPINNER_SIZE, StyledContainer, StyledSpinner, StyledSpinnerAnimation } from './SpinnerStyled'

interface SpinnerProps {
  className?: string
  light?: boolean
  position?: Property.JustifyContent
  size?: typeByObject<typeof SPINNER_SIZE>
}

const Spinner: React.FC<PropsWithChildren<SpinnerProps>> = ({
  className,
  light,
  position,
  size = SPINNER_SIZE.STANDARD,
  children,
  ...props
}) => (
  <StyledContainer $position={position}>
    <StyledSpinner
      $light={light}
      $size={size}
      className={className}
      {...props}
    >
      <StyledSpinnerAnimation>
        {children}
      </StyledSpinnerAnimation>
    </StyledSpinner>
  </StyledContainer>
)

export default Spinner
