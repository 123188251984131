import { BasicActionOptionsProps } from 'constants/service'

import {
  CLEAR_ORGANIZATIONS,
  LIST_ORGANIZATIONS,
  LIST_ORGANIZATIONS_FAILED,
  LIST_ORGANIZATIONS_SUCCESS,
} from './constants'

export const list = (options: BasicActionOptionsProps) => ({ organizationApiClient }) => async (dispatch) => {
  const { mergeResult, onFailed, onSuccess, params = {} } = options

  dispatch({ type: LIST_ORGANIZATIONS })

  await organizationApiClient.list(params).then(
    (response) => {
      dispatch({
        mergeResult,
        payload: response,
        type: LIST_ORGANIZATIONS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: LIST_ORGANIZATIONS_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const clearList = () => ({
  type: CLEAR_ORGANIZATIONS,
})
