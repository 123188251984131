import LogTicketForm from 'module/Help/LogTicketForm.js'

export const ROUTE_NAMES = {
  'HELP.LOG_TICKET': 'HELP.LOG_TICKET',
}

const HelpRouting = {
  component: LogTicketForm,
  name: ROUTE_NAMES['HELP.LOG_TICKET'],
  path: 'logTicket',
}

HelpRouting.ROUTE_NAMES = ROUTE_NAMES

export default HelpRouting
