import React from 'react'

import { DetailsBreakdown } from 'components'

const ReviewItem = ({ label, value }) => (
  <DetailsBreakdown.Item
    label={label}
    padding="6px 0"
    value={value}
    withoutBorder
  />
)

export default ReviewItem
