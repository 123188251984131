import { COMMENT_TYPE } from 'services/comments/constants'
import { LIKE_TYPE } from 'services/likes/constants'

export const DAILY_DIARY_TYPE = {
  ACTIVITY: 'activity-record',
  ATTENDANCE_START: 'attendance-start-record',
  ATTENDANCE_STOP: 'attendance-stop-record',
  BOTTLE: 'bottle-feed-record',
  EATING: 'eating-record',
  NAPPY: 'nappy-change-record',
  SLEEP: 'sleep-record',
  TOILET_TRAINING: 'toilet-training-record',
}

export const DAILY_DIARY_TYPE_MAPPING_TO_COMMENT_TYPE = {
  [DAILY_DIARY_TYPE.ACTIVITY]: COMMENT_TYPE.ACTIVITY,
  [DAILY_DIARY_TYPE.ATTENDANCE_START]: COMMENT_TYPE.SIGN_IN,
  [DAILY_DIARY_TYPE.ATTENDANCE_STOP]: COMMENT_TYPE.SIGN_OUT,
  [DAILY_DIARY_TYPE.BOTTLE]: COMMENT_TYPE.BOTTLE,
  [DAILY_DIARY_TYPE.EATING]: COMMENT_TYPE.EATING,
  [DAILY_DIARY_TYPE.NAPPY]: COMMENT_TYPE.NAPPY,
  [DAILY_DIARY_TYPE.SLEEP]: COMMENT_TYPE.SLEEP,
  [DAILY_DIARY_TYPE.TOILET_TRAINING]: COMMENT_TYPE.TOILET,
}

export const DAILY_DIARY_TYPE_MAPPING_TO_LIKE_TYPE = {
  [DAILY_DIARY_TYPE.ACTIVITY]: LIKE_TYPE.ACTIVITY,
  [DAILY_DIARY_TYPE.ATTENDANCE_START]: LIKE_TYPE.SIGN_IN,
  [DAILY_DIARY_TYPE.ATTENDANCE_STOP]: LIKE_TYPE.SIGN_OUT,
  [DAILY_DIARY_TYPE.BOTTLE]: LIKE_TYPE.BOTTLE,
  [DAILY_DIARY_TYPE.EATING]: LIKE_TYPE.EATING,
  [DAILY_DIARY_TYPE.NAPPY]: LIKE_TYPE.NAPPY,
  [DAILY_DIARY_TYPE.SLEEP]: LIKE_TYPE.SLEEP,
  [DAILY_DIARY_TYPE.TOILET_TRAINING]: LIKE_TYPE.TOILET,
}
