import {
  CLEAR_CONTRACT,
  CREATE_CONTRACT,
  CREATE_CONTRACT_FAILED,
  CREATE_CONTRACT_SUCCESS,
  DELETE_CONTRACT_FAILED,
  DELETE_CONTRACT_SUCCESS,
  GET_CONTRACT,
  GET_CONTRACT_FAILED,
  GET_CONTRACT_SUCCESS,
  UPDATE_CONTRACT,
  UPDATE_CONTRACT_FAILED,
  UPDATE_CONTRACT_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case GET_CONTRACT:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_CONTRACT_SUCCESS:
      return { ...state, data: payload.data, isFetching: false }
    case GET_CONTRACT_FAILED:
      return { ...state, error, isFetching: false }
    case CREATE_CONTRACT:
    case UPDATE_CONTRACT:
      return { ...state, error: initialState.error, isSubmitting: true }
    case CREATE_CONTRACT_SUCCESS:
    case UPDATE_CONTRACT_SUCCESS:
      return { ...state, data: payload.data, isSubmitting: false }
    case CREATE_CONTRACT_FAILED:
    case UPDATE_CONTRACT_FAILED:
      return { ...state, error, isSubmitting: false }
    case DELETE_CONTRACT_SUCCESS:
      return initialState
    case DELETE_CONTRACT_FAILED:
      return { ...state, error }
    case CLEAR_CONTRACT:
      return initialState
    default:
      return state
  }
}
