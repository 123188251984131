import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import { UPDATE_REGISTER_REASON, UPDATE_REGISTER_REASON_FAILED, UPDATE_REGISTER_REASON_SUCCESS } from './constants'

const initialState = getBasicSingleInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    updateFailed: UPDATE_REGISTER_REASON_FAILED,
    updateInit: UPDATE_REGISTER_REASON,
    updateSuccess: UPDATE_REGISTER_REASON_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
