import {
  ADD_CHILDREN_TO_INJURY,
  CLEAR_SINGLE_INJURY,
  CREATE_INJURY,
  CREATE_INJURY_FAILED,
  CREATE_INJURY_SUCCESS,
  GET_INJURY,
  GET_INJURY_FAILED,
  GET_INJURY_SUCCESS,
  REMOVE_CHILDREN_FROM_INJURY,
  REMOVE_INJURY,
  REMOVE_INJURY_FAILED,
  REMOVE_INJURY_SUCCESS,
  SEND_INJURY_REPORT,
  SEND_INJURY_REPORT_FAILED,
  SEND_INJURY_REPORT_SUCCESS,
  UPDATE_INJURY,
  UPDATE_INJURY_FAILED,
  UPDATE_INJURY_SIGNATURE_COLLECTING_TYPE,
  UPDATE_INJURY_SIGNATURE_COUNT,
  UPDATE_INJURY_STATUS,
  UPDATE_INJURY_SUCCESS,
  UPDATE_SINGLE_INJURY_SIGNATURE_STATISTICS,
} from './constants'

export const get = (injuryId, options = {}) => ({ injuriesApiClient }) => (dispatch) => {
  const { params, silent = false, onSuccess = () => { }, onFailed = () => { } } = options

  dispatch({ silent, type: GET_INJURY })

  injuriesApiClient.get(injuryId, params).then(
    (response) => {
      dispatch({ payload: response, type: GET_INJURY_SUCCESS })

      return onSuccess(response)
    },
    (error) => {
      dispatch({ payload: error, type: GET_INJURY_FAILED })

      return onFailed(error)
    },
  )
}

export const addChildrenToInjury = (children) => ({
  payload: children,
  type: ADD_CHILDREN_TO_INJURY,
})

export const clearSingleInjury = () => ({
  type: CLEAR_SINGLE_INJURY,
})

export const removeChildFromInjury = (child) => ({
  payload: child,
  type: REMOVE_CHILDREN_FROM_INJURY,
})

export const update = (injuryId, options = {}) => ({ injuriesApiClient }) => (dispatch) => {
  const { body, params, onSuccess = () => { }, onFailed = () => { } } = options

  dispatch({ payload: body, type: UPDATE_INJURY })

  injuriesApiClient.update(injuryId, body, params).then(
    (response) => {
      dispatch({ payload: response, type: UPDATE_INJURY_SUCCESS })

      return onSuccess(response)
    },
    ({ response }) => {
      dispatch({ payload: response, type: UPDATE_INJURY_FAILED })

      return onFailed(response)
    },
  )
}

export const create = (options = {}) => ({ injuriesApiClient }) => (dispatch) => {
  const { body, onSuccess = () => { }, onFailed = () => { }, params } = options

  dispatch({ payload: body, type: CREATE_INJURY })

  injuriesApiClient.create(body, params).then(
    (response) => {
      dispatch({ payload: response, type: CREATE_INJURY_SUCCESS })
      return onSuccess(response)
    },
    ({ response }) => {
      dispatch({ payload: response, type: CREATE_INJURY_FAILED })
      return onFailed(response)
    },
  )
}

export const remove = (injuryId, options = {}) => ({ injuriesApiClient }) => (dispatch) => {
  const { onSuccess = () => { }, onFailed = () => { } } = options

  dispatch({ type: REMOVE_INJURY })

  injuriesApiClient.remove(injuryId).then(
    () => {
      onSuccess()
      return dispatch({ type: REMOVE_INJURY_SUCCESS })
    },
    ({ response }) => {
      onFailed(response)
      return dispatch({ type: REMOVE_INJURY_FAILED })
    },
  )
}

export const sendReport = (injuryId, options = {}) => ({ injuriesApiClient }) => (dispatch) => {
  const { body, onSuccess = () => { }, onFailed = () => { } } = options

  dispatch({ type: SEND_INJURY_REPORT })

  injuriesApiClient.sendReport(injuryId, body).then(
    () => {
      onSuccess()
      return dispatch({ type: SEND_INJURY_REPORT_SUCCESS })
    },
    ({ response }) => {
      onFailed(response)
      return dispatch({ type: SEND_INJURY_REPORT_FAILED })
    },
  )
}

export const updateSignatureCount = ({ newSignatureStatistics, signatureData }) => ({
  payload: { newSignatureStatistics, signatureData },
  type: UPDATE_INJURY_SIGNATURE_COUNT,
})

export const updateStatus = (status) => ({
  payload: { status },
  type: UPDATE_INJURY_STATUS,
})

export const updateSignatureCollectingType = (signatureCollectingType) => ({
  payload: { signatureCollectingType },
  type: UPDATE_INJURY_SIGNATURE_COLLECTING_TYPE,
})

export const updateSingleSignatureStatistics = ({ newSignatureStatistics }) => ({
  payload: { newSignatureStatistics },
  type: UPDATE_SINGLE_INJURY_SIGNATURE_STATISTICS,
})
