import _ from 'lodash'

export const validateRoomMove = (roomMoves) => _.map(roomMoves, (roomMove) => {
  const preparedRoomMove = roomMove
  const { fromNurseryClass, toLeaving, toNurseryClass } = roomMove

  if (!fromNurseryClass || (!toNurseryClass && !toLeaving)) {
    preparedRoomMove.invalid = true

    return preparedRoomMove
  }

  if (
    (fromNurseryClass && (
      !Number.isInteger(fromNurseryClass.startAgeInMonths) || !Number.isInteger(fromNurseryClass.endAgeInMonths)
    ))
    || (toNurseryClass ? (
      !Number.isInteger(toNurseryClass.startAgeInMonths) || !Number.isInteger(toNurseryClass.endAgeInMonths)
    ) : !toLeaving)
  ) {
    preparedRoomMove.invalid = true

    return preparedRoomMove
  }

  if (!toLeaving && fromNurseryClass.endAgeInMonths !== toNurseryClass.startAgeInMonths) {
    preparedRoomMove.invalid = true

    return preparedRoomMove
  }

  return preparedRoomMove
})
