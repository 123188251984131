import {
  CLEAR_CHILDREN_SESSION,
  LIST_CHILDREN_SESSION,
  LIST_CHILDREN_SESSION_FAILED,
  LIST_CHILDREN_SESSION_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: null,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case LIST_CHILDREN_SESSION:
      return { ...state, error: initialState.error, isFetching: true }
    case LIST_CHILDREN_SESSION_SUCCESS:
      return { ...state, data: payload.data, isFetching: false, meta: payload.meta }
    case LIST_CHILDREN_SESSION_FAILED:
      return { ...state, error, isFetching: false }
    case CLEAR_CHILDREN_SESSION:
      return initialState
    default:
      return state
  }
}
