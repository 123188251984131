import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as staffLogsSelectors from './selectors'

const withStaffLogsService = (WrappedComponent) => {
  const mapState = ({ staffLogs }) => ({ staffLogs })

  const mapDispatch = injectActions('staffLogsActions', actions)

  const Component = ({ staffLogs, staffLogsActions, ...others }) => (
    <WrappedComponent
      staffLogsActions={staffLogsActions}
      staffLogsListState={staffLogs.list}
      staffLogsSelectors={staffLogsSelectors}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withStaffLogsService
