import _ from 'lodash'

import { createSelector } from 'reselect'
import React from 'react'

import { RootState } from 'core/reducers'

import { generateRoute } from 'utils/routing'

import { getApplicationProperties } from 'services/app/applicationContext/selectors'
import { findDomainSuffix, getPortSuffix } from 'services/subdomain/single/selectors'

import { Button } from 'components'

import i18n from 'translations'

import { StyledLink, StyledReactLink } from 'module/Organisations/OrganisationsList/OrganisationsListStyled'

const getOrganizationList = (state: RootState) => state.organization.list

export const getOrganizationListData = createSelector([getOrganizationList], (state) => state.data)

export const getOrganizationListMeta = createSelector([getOrganizationList], (state) => state.meta)

export const getCriteria = createSelector([(filters) => filters], (filters) => {
  if (!filters) {
    return null
  }

  const { featureFlags, search, status } = filters
  const criteria = []

  if (0 <= status) {
    criteria.push({
      field: 'archived',
      value: status,
    })
  }

  if (search) {
    criteria.push({
      field: 'name',
      value: search,
    })
  }

  if (featureFlags) {
    _.forEach(featureFlags, (flag) => {
      criteria.push({
        field: 'featureFlags.flag[]',
        value: flag,
      })
    })

    criteria.push({
      field: 'featureFlags.enabled',
      value: 1,
    })
  }

  return criteria
})

export const getOrganizationListTableSelector = createSelector(
  [getOrganizationListData, getApplicationProperties, () => findDomainSuffix(), () => getPortSuffix()],
  (data, appProperties, domainSuffix, portSuffix) => {
    if (!data?.length) {
      return null
    }

    return _.map(data, ({
      archived,
      id,
      name,
      numberOfNurseries,
      subdomain,
    }) => ({
      domain: (
        <StyledLink
          href={appProperties.nurseryDomainPrefix + subdomain + domainSuffix + portSuffix}
          target="_blank"
        >
          {i18n.t('module:Organisations:List:manageThisOrganisation')}
        </StyledLink>
      ),
      edit: (
        <Button.TableAction
          to={generateRoute('ORGANISATIONS.EDIT', { organisationId: id })}
          edit
        />
      ),
      numberOfNurseries,
      organisation: (
        <StyledReactLink
          href={generateRoute('ORGANISATIONS.DETAILS', { organisationId: id })}
        >
          {archived ? `${name} (${i18n.t('global:disabled')})` : name}
        </StyledReactLink>
      ),
    }))
  },
)
