import _ from 'lodash'

import React from 'react'

import { generateRoute } from 'utils/routing'

import { Button } from 'components'

export const getTableData = (extraItemsList) => {
  if (!extraItemsList || !extraItemsList.length) {
    return null
  }

  return _.map(extraItemsList, (extraItem) => {
    const { archived, feeCalculations, id, name, unitPrice } = extraItem

    return {
      feeCalculations: _.upperFirst(feeCalculations),
      name: `${name}${archived ? ' (archived)' : ''}`,
      rowToButton: (
        <Button.TableAction
          to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.ITEMS.EDIT', {
            itemId: id,
          })}
          edit
        />
      ),
      unitPrice,
    }
  })
}
