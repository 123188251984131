import _ from 'lodash'

import React from 'react'

import { Table } from 'components'

import i18n from 'translations'

export const getColumns = (isChildContext) => [
  {
    align: 'left',
    alignTh: 'left',
    field: 'receiptNumber',
    title: i18n.t('module:Finance:Payments:receipt'),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'paymentDate',
    sortable: true,
    title: _.upperFirst(i18n.t('global:date')),
  },
  ...(!isChildContext ? [{
    align: 'left',
    alignTh: 'left',
    field: 'child',
    sortKey: 'child.firstName',
    sortable: true,
    title: _.upperFirst(i18n.t('global:child')),
  }] : []),
  {
    align: 'left',
    alignTh: 'left',
    field: 'amount',
    sortable: true,
    title: _.upperFirst(i18n.t('global:amount')),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'paymentType',
    sortKey: 'paymentType.name',
    sortable: true,
    title: i18n.t('module:Finance:Payments:paymentMethod'),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'status',
    title: _.upperFirst(i18n.t('global:status')),
  },
  ...(isChildContext ? [{
    align: 'left',
    alignTh: 'left',
    field: 'comment',
    title: _.upperFirst(i18n.t('global:comment')),
  }] : []),
  {
    field: 'edit',
  },
]

const FinancePaymentsTable = ({
  isChildContext,
  noDataText,
  onSortChange,
  payments,
  sortField,
  sortOrder,
}) => (
  <Table
    columns={getColumns(isChildContext)}
    data={payments}
    noDataText={noDataText}
    sortField={sortField}
    sortOrder={sortOrder}
    visualType="transparent"
    onSortChange={onSortChange}
  />
)

export default FinancePaymentsTable
