import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withChildRegistersService } from 'services/legacy/childRegisters'
import { withAppService } from 'services/app'
import { withPaginationUtils } from 'services/utils/pagination'
import { withModalService } from 'services/utils/modal'
import { withRouter } from 'services/router'

import ActivitiesStatisticsView from './ActivitiesStatisticsView'

const ActivitiesStatistics = ({ modalActions, modalConsts, records, totalResults }) => {
  const handleShowActivitiesStatistics = (recordsToSkip) => {
    modalActions.show(modalConsts.TYPES.ACTIVITIES_STATISTICS, {
      recordsToSkip,
    })
  }

  return (
    <ActivitiesStatisticsView
      records={records}
      totalResults={totalResults}
      onShowActivitiesStatistics={handleShowActivitiesStatistics}
    />
  )
}

const mapState = (state, {
  appSelectors,
  childRegistersSelectors,
  childRegistersStatisticsState,
}) => ({
  isFetching: appSelectors.getIsFetching(
    childRegistersStatisticsState,
  ),
  records: childRegistersSelectors.getChildRegistersStatisticsData(state),
  totalResults: appSelectors.getTotalResults(childRegistersStatisticsState),
})

const enhance = compose(
  withAppService,
  withChildRegistersService,
  withPaginationUtils,
  withModalService,
  withRouter,
  connect(mapState),
)

export default enhance(ActivitiesStatistics)
