import { Property } from 'csstype'

import React, { PropsWithChildren } from 'react'

import { StyledSpace } from './SpaceStyled'

interface SpaceProps {
  inline?: boolean
  margin?: Property.Margin
  padding?: Property.Padding
  space?: string
}

const Space: React.FC<PropsWithChildren<SpaceProps>> = ({
  inline,
  margin,
  padding,
  space,
}) => (
  <StyledSpace
    $inline={inline}
    $margin={margin}
    $padding={padding}
    $space={space}
  />
)

export default Space
