import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { Form } from 'components'

export const BAD_DEBT_FORM = 'BadDebtForm'

const BadDebtForm = ({
  handleSubmit,
  isSubmitting,
  onCloseClick,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row label="Date">
      <Form.Row.Item>
        <Field
          component={Form.DatePicker}
          name="badDebtDate"
          validate={isRequired}
          clearable
        />
      </Form.Row.Item>
    </Form.Row>
    <Form.Row
      label="Reason or comment"
      verticalLabel
    >
      <Form.Row.FlexItem flex="0 0 420px">
        <Field
          component={Form.TextAreaField}
          name="badDebtReason"
          placeholder="Enter reason or comment to display on invoice."
          validate={isRequired}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.Row>
      <Form.Row.Item>
        Please note: Only the outstanding invoice amount can be marked as bad debt.
      </Form.Row.Item>
    </Form.Row>
    <Form.FooterActions
      isSubmitting={isSubmitting}
      submitLabel="Mark as bad debt"
      onCancelClick={onCloseClick}
    />
  </Form>
)

export default reduxForm({ form: BAD_DEBT_FORM })(BadDebtForm)

