import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'
import { Profile } from 'services/authentication/models'

const getAuthentication = (state: RootState) => state.authentication.common

export const getMeProfile = createSelector([getAuthentication], ({ profile }): Profile => profile)

export const getIsUserLoggedIn = createSelector([getAuthentication], ({ profile, token }): boolean => (
  null !== profile && null !== token
))
