import React from 'react'
import { AutoSizer, InfiniteLoader, List, ListRowRenderer } from 'react-virtualized'

import { StyledListContainer } from './ConversationsStyled'

interface InfiniteLoaderProps {
  items: any[]
  loadMore: () => void
  threshold: number
  totalResult: number
}

interface ConversationListProps {
  cacheCurrent: any
  calculateRowHeight?: number | ((index: number) => number)
  enableInfiniteLoader?: boolean
  infiniteLoaderProps?: InfiniteLoaderProps
  itemsLength: number
  renderRow: ListRowRenderer
}

const ConversationsList: React.FC<ConversationListProps> = ({
  cacheCurrent,
  calculateRowHeight,
  enableInfiniteLoader,
  infiniteLoaderProps,
  itemsLength,
  renderRow,
}) => {
  if (enableInfiniteLoader) {
    return (
      <InfiniteLoader
        isRowLoaded={({ index }) => !!infiniteLoaderProps?.items?.[index]}
        loadMoreRows={infiniteLoaderProps?.loadMore}
        rowCount={infiniteLoaderProps?.totalResult}
        threshold={infiniteLoaderProps?.threshold}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ height, width: autoSizerWidth }) => (
              <List
                deferredMeasurementCache={cacheCurrent}
                height={height}
                overscanRowCount={0}
                ref={registerChild}
                rowCount={itemsLength}
                rowHeight={cacheCurrent.rowHeight}
                rowRenderer={renderRow}
                width={autoSizerWidth}
                onRowsRendered={onRowsRendered}
              />
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    )
  }

  return (
    <StyledListContainer>
      <AutoSizer>
        {({ height, width: autoSizerWidth }) => (
          <List
            deferredMeasurementCache={cacheCurrent}
            height={height}
            overscanRowCount={0}
            rowCount={itemsLength}
            rowHeight={calculateRowHeight || cacheCurrent.rowHeight}
            rowRenderer={renderRow}
            width={autoSizerWidth}
          />
        )}
      </AutoSizer>
    </StyledListContainer>
  )
}

export default ConversationsList
