import ServiceBase from 'services/ServiceBase'

import { QueryPropsV2 } from 'constants/service'

import { getQueryStringV2 } from 'utils/api'

class productPriceGroupsApiClient extends ServiceBase {
  list = (params: QueryPropsV2, customSubdomain) => this.request({
    customSubdomain,
    path: `/v2/product/product_price_groups?${getQueryStringV2(params)}`,
  })
}

export default productPriceGroupsApiClient
