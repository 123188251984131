import React, { useEffect, useRef } from 'react'

import { COMMENT_TYPE } from 'services/comments/constants'

import {
  Avatar,
  Button,
  DateString,
  EmptyState,
  MediaPicker,
  Page,
  Section,
  Space,
  Spinner,
  Typography,
} from 'components'
import Comments from 'module/LikesAndComments/components/Comments'

import i18n from 'translations'

import { StyledComments, StyledDate, StyledHeader } from './HomeObservationsDetailsViewStyled'

const HomeObservationsDetailsView = ({ homeObservation, isLoading, location }) => {
  const commentsRef = useRef(null)

  useEffect(() => {
    if (!isLoading && homeObservation) {
      setTimeout(() => {
        if ('comments' === location?.query?.section) {
          commentsRef?.current?.scrollIntoView()
        }
      }, 200)
    }
  }, [homeObservation])

  const { carer, child, comments, createdAt, id, media, observations } = homeObservation || {}
  const hasObservations = observations && 0 < observations.length

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!homeObservation) {
      return (
        <EmptyState
          icon="parent"
          text1="No observations from home found."
          text2="Let your parents know they can share observations from home with their nursery on the Blossom parent app." // eslint-disable-line
        />
      )
    }

    return (
      <React.Fragment>
        <div>
          <StyledHeader>
            <StyledDate>
              <DateString date={createdAt} />
            </StyledDate>
          </StyledHeader>
          <Section>
            <Avatar
              initials={[child.firstName, child.surname]}
              src={child.photo}
              title={(
                <Typography fontSize={14} primary>
                  {`${child.firstName} ${child.surname}`}
                </Typography>
              )}
            />
          </Section>
          <Section title={i18n.t('module:HomeObservation:Details:parentComment')} secondLebel>
            <Avatar
              avatarSize="medium"
              initials={[carer.firstName, carer.surname]}
              src={carer.photo}
              title={(
                <Typography fontSize={14} primary>
                  {`${carer.firstName} ${carer.surname}`}
                </Typography>
              )}
              borderAvatar
            />
            <StyledComments $noComments={!comments}>
              {comments || i18n.t('module:HomeObservation:Details:noCommentAdded')}
            </StyledComments>
          </Section>
          <Section title={i18n.t('module:HomeObservation:Details:media')} secondLebel>
            <MediaPicker
              value={media}
              tiledMode
            />
          </Section>
          <div ref={commentsRef}>
            <Comments
              commentType={COMMENT_TYPE.HOME_OBSERVATION}
              objectId={id}
              wrapper={(children) => (
                <React.Fragment>
                  <Space space="20px" />
                  {children}
                </React.Fragment>
              )}
              gray
            />
          </div>
        </div>
        {!hasObservations && (
          <Page.BottomBar>
            <Button
              label={i18n.t('module:HomeObservation:Details:makeFullObservation')}
              to={`/learning/observations/add-from-home-observation/${id}?ref=home-observations`}
            />
          </Page.BottomBar>
        )}
      </React.Fragment>
    )
  }

  return (
    <Page>
      <Page.Content>
        {renderContent()}
      </Page.Content>
    </Page>
  )
}

export default HomeObservationsDetailsView
