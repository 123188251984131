import styled from 'styled-components'

export const StyledOption = styled.div`
  display: grid;
  align-items: center;
  grid-column-gap: 10px;
  grid-template-columns: 24px auto;
  
  img {
    width: 24px;
    height: 18px;
  }
`
