import _ from 'lodash'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { ALL_STAFF_MEMBERS } from 'module/Modals/Staff/constants'
import { ALL_ROOMS } from 'services/legacy/rooms/constants'

const getMembershipsShiftsListSelector = (state) => state.membershipsShifts.list

export const getMembershipsShiftsListDataSelector = createSelector(
  [getMembershipsShiftsListSelector],
  (state) => state.data,
)

export const getListCriteria = (params) => {
  const {
    archived,
    dateFrom,
    dateFromShiftTimes,
    dateTo,
    dateToShiftTimes,
    exists,
    leaveShiftType,
    membership,
    membershipShiftTimesExists,
    now,
    room,
    search,
    type,
    withLeave,
  } = params
  const criteria = []

  if (dateFrom) {
    criteria.push({
      comparator: 'after',
      field: 'shifts.dateTo',
      value: dateFrom,
    })
  }

  if (dateFromShiftTimes) {
    criteria.push({
      comparator: 'after',
      field: 'shifts.membershipShiftTimes.day',
      value: dateFromShiftTimes,
    })
  }

  if (dateTo) {
    criteria.push({
      comparator: 'before',
      field: 'shifts.dateFrom',
      value: dateTo,
    })
  }

  if (dateToShiftTimes) {
    criteria.push({
      comparator: 'before',
      field: 'shifts.membershipShiftTimes.day',
      value: dateToShiftTimes,
    })
  }

  if (room) {
    criteria.push({
      field: 'shifts.membershipShiftTimes.class[]',
      value: room,
    })

    criteria.push({
      field: 'shifts.membershipShiftTimes.class[]',
      value: '',
    })
  }

  if (membership) {
    criteria.push({
      field: 'id',
      value: membership,
    })
  }

  if (now) {
    criteria.push({
      field: 'now',
      value: now,
    })
  }

  if (membershipShiftTimesExists) {
    criteria.push({
      field: 'shifts.membershipShiftTimes[exists]',
      value: 1,
    })
  }

  if (withLeave) {
    criteria.push({
      field: 'staffWithoutLeave',
      value: 0,
    })
  } else if (!_.isUndefined(withLeave)) {
    criteria.push({
      field: 'staffWithoutLeave',
      value: 1,
    })
  }

  if (search) {
    criteria.push({
      field: 'concatenable',
      value: search,
    })
  }

  if (_.isBoolean(archived)) {
    criteria.push({
      field: 'archived',
      value: +archived,
    })
  }

  if (exists) {
    criteria.push({
      field: 'shifts[exists]',
      value: 1,
    })
  }

  if (type) {
    criteria.push({
      field: 'shifts.type',
      value: type,
    })
  }

  if (leaveShiftType) {
    criteria.push({
      field: 'shifts.leaveShiftType',
      value: leaveShiftType,
    })
  }

  return criteria
}

export const getRotaPdfExportPayload = ({
  reportDatesRange,
  rooms,
  sendTo,
}) => {
  const payload = {
    dateFrom: reportDatesRange[0].format(DEFAULT_DATE_FORMAT),
    dateTo: reportDatesRange[1].format(DEFAULT_DATE_FORMAT),
  }

  if (rooms && ALL_ROOMS !== rooms) {
    payload.nurseryClass = {
      id: rooms.value,
      type: rooms.type,
    }
  }

  if (sendTo && ALL_STAFF_MEMBERS.value !== sendTo.value) {
    payload.recipient = {
      id: sendTo.value,
    }
  }

  return payload
}
