import _ from 'lodash'

import React from 'react'
import { Field } from 'redux-form'

import { Button, Form } from 'components'

import { isValidDateRange } from 'utils/fieldValidation'

import { StyledContainer } from './FundingExcludePeriodFieldStyled'

const FundingExcludePeriodField = ({ fields }) => {
  const renderItem = (item, index) => (
    <Form.Row key={_.kebabCase(index)}>
      <Form.Row.Item margin="0 5px 0 0">
        <Field
          component={Form.DatePicker}
          name={item}
          validate={[isValidDateRange]}
          clearable
          range
        />
      </Form.Row.Item>
      <Form.Row.Item>
        <Form.RemoveRowButton trash onClick={() => fields.remove(index)} />
      </Form.Row.Item>
    </Form.Row>
  )

  return (
    <StyledContainer>
      {fields.map(renderItem)}
      <Form.Row>
        <Button.ActionButton
          label="Add period"
          onClick={() => fields.push([])}
        />
      </Form.Row>
    </StyledContainer>
  )
}

export default FundingExcludePeriodField
