import styled from 'styled-components'

import layout from 'constants/layout'

export const StyledCollapseWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

interface StyledCollapseContainerProps {
  $withIcon?: boolean
}

export const StyledCollapseContainer = styled.header<StyledCollapseContainerProps>`
  padding-bottom: 15px;
  position: relative;
  
  & > header {
    border: none;
    box-shadow: none;
    appearance: none;
  }
  
  ${({ $withIcon }) => $withIcon && `
    &:before {
      position: absolute;
      left: 15px;
      top: 0;
      bottom: 0;
      width: 3px;
      z-index: 5;
      content: '';
      background: #EDEDED;
    }
    
    &:nth-last-of-type(1) {
      &:before {
        display: none;
      }
    }
  `}
`

export const StyledHeader = styled.header`
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const StyledBox = styled.div`
  display: flex;
  padding: 8px 10px;
  overflow: hidden;
  background: #F5F5F5;
  align-items: center;
  flex: 1;
  border-radius: ${layout.borderRadius};
`

export const StyledTitle = styled.div`
  flex: 1;
  padding-right: 15px;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

interface StyledContentProps {
  $withIcon?: boolean
}

export const StyledContent = styled.div<StyledContentProps>`
  ${({ $withIcon }) => $withIcon && `
    margin-left: 38px;
  `}
`

interface StyledIconProps {
  $background?: string
}

export const StyledIcon = styled.div<StyledIconProps>`
  width: 33px;
  min-width: 33px;
  height: 33px;
  min-height: 33px;
  border-radius: 50%; 
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;
  
  ${({ $background }) => $background && `
    background: ${$background};
  `} 
`
