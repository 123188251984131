import React, { PropsWithChildren, useEffect } from 'react'

import eventBus from 'utils/eventBus'

import DataTableHead from './DataTableHead'
import DataTableContent from './DataTableContent'
import DataTableContentScrollable from './DataTableContentScrollable'
import DataTableColumn from './DataTableColumn'
import DataTableRow from './DataTableRow'
import DataTableGroupHeader from './DataTableGroupHeader'
import DataTableSortable from './DataTableSortable'
import { StyledContainer } from './DataTableStyled'

interface DataTableRoot<T> extends React.FC<PropsWithChildren<T>> {
  Column?: typeof DataTableColumn
  Content?: typeof DataTableContent
  ContentScrollable?: typeof DataTableContentScrollable
  GroupHeader?: typeof DataTableGroupHeader
  Head?: typeof DataTableHead
  Row?: typeof DataTableRow
  Sortable?: typeof DataTableSortable
}

interface DataTableProps {
  minWidth?: number
}

export const CALCULATE_STICKY_POSITION_DATA_TABLE_ROW = 'EVENT/CALCULATE_STICKY_POSITION_DATA_TABLE_ROW'

const DataTable: DataTableRoot<DataTableProps> = React.forwardRef(({
  children,
  minWidth = 440,
}, ref) => {
  const calculatePosition = () => (
    eventBus.dispatch(CALCULATE_STICKY_POSITION_DATA_TABLE_ROW)
  )

  useEffect(() => {
    window.addEventListener('scroll', calculatePosition, { passive: true })
    window.addEventListener('resize', calculatePosition)
    window.addEventListener('orientationchange', calculatePosition)
    calculatePosition()

    return () => {
      window.removeEventListener('scroll', calculatePosition)
      window.removeEventListener('resize', calculatePosition)
      window.removeEventListener('orientationchange', calculatePosition)
    }
  }, [])

  return (
    <StyledContainer $minWidth={minWidth} ref={ref} data-dataTable>
      {children}
    </StyledContainer>
  )
})

export default DataTable
