import { generateBasicActions } from 'utils/service'

import {
  REMOVE_OBSERVATION_MEDIA,
  REMOVE_OBSERVATION_MEDIA_FAILED,
  REMOVE_OBSERVATION_MEDIA_SUCCESS,
  UPDATE_OBSERVATION_MEDIA,
  UPDATE_OBSERVATION_MEDIA_FAILED,
  UPDATE_OBSERVATION_MEDIA_SUCCESS,
} from './constants'

export const removeObservationMedia = (options = {}) => ({ observationsApiClient }) => generateBasicActions.remove({
  constants: {
    failed: REMOVE_OBSERVATION_MEDIA_FAILED,
    init: REMOVE_OBSERVATION_MEDIA,
    success: REMOVE_OBSERVATION_MEDIA_SUCCESS,
  },
  options,
  service: observationsApiClient,
  serviceMethod: 'deleteObservationMedia',
})

export const updateObservationMedia = (options = {}) => ({ observationsApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_OBSERVATION_MEDIA_FAILED,
    init: UPDATE_OBSERVATION_MEDIA,
    success: UPDATE_OBSERVATION_MEDIA_SUCCESS,
  },
  options,
  service: observationsApiClient,
  serviceMethod: 'updateObservationMedia',
})
