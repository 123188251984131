import styled from 'styled-components'

export const StyledHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 160px);
  grid-gap: 15px;
  margin-bottom: 15px;
`

export const StyledColumnWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 160px);
  grid-gap: 15px;  
`

export const StyledWrapper = styled.div`
  padding-bottom: 20px;
  margin-bottom: 30px;
  
  @media(max-width: 1060px) {
    overflow-x: scroll;
  }
`

export const StyledContent = styled.div`
  min-width: 1000px;  
`
