import styled from 'styled-components'

import { Link } from 'react-router'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledChildRow = styled(Link)`
  border-radius: 8px;
  margin: 2px;
  padding: 14px 16px;
  cursor: pointer;
  transition: .2s;
  display: block;
  
  &:hover, &:focus {
    background: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  }
`

export const StyledSubtitle = styled.div`
  ${({ isArchived }) => isArchived && `
    display: grid;
    grid-template-columns: 64px auto;
    align-items: center;
    grid-gap: 5px;
  `}
`
