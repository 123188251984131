import i18n from 'translations'

export enum SessionType {
  FIXED = 'FIXED',
  HOURLY = 'HOURLY',
}

export const SESSION_TYPE_OPTIONS = [
  {
    label: i18n.t('module:Management:Finance:Sessions:Add:Form:SessionType:Options:Fixed:label'),
    value: SessionType.FIXED,
  },
  {
    label: i18n.t('module:Management:Finance:Sessions:Add:Form:SessionType:Options:Hourly:label'),
    value: SessionType.HOURLY,
  },
]

export enum ConsumableChargeableType {
  PER_HOUR = 'PER_HOUR',
  PER_SESSION = 'PER_SESSION',
}

export const CONSUMABLE_CHARGEABLE_OPTIONS = [
  {
    label: i18n.t('module:Management:Finance:Sessions:Add:Consumables:Options:PerHour:label'),
    value: ConsumableChargeableType.PER_HOUR,
  },
  {
    label: i18n.t('module:Management:Finance:Sessions:Add:Consumables:Options:PerSession:label'),
    value: ConsumableChargeableType.PER_SESSION,
  },
]
