export const ENQUIRY_GROUPS = {
  read: [
    'enquiry.data',
    'enquiry.nurseryClass',
    'nurseryClass',
    'enquiry.sessionPlans',
    'nurserySession',
    'enquiry.data.settings',
    'enquirySettings',
  ],
}

export const ENQUIRY_GROUPS_V3 = {
  read: [
    'enquiry.data',
    'enquiry.nurseryClass',
    'nurseryClass',
    'enquiry.sessionProductPlans',
    'enquiry.data.settings',
    'enquirySettings',
    'enquiry.sessionProductPlans.value',
    'nurserySessionProduct',
    'nurserySessionProduct.priceChanges',
    'productPriceChange',
    'productPriceChange.prices',
    'productPrice',
    'productPrice.priceGroup',
    'productPriceGroup',
  ],
}

