import { BasicSingleInitialState, getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_INTEGRATION_FEE,
  CREATE_INTEGRATION_FEE,
  CREATE_INTEGRATION_FEE_FAILED,
  CREATE_INTEGRATION_FEE_SUCCESS,
  GET_INTEGRATION_FEE,
  GET_INTEGRATION_FEE_FAILED,
  GET_INTEGRATION_FEE_SUCCESS,
  REMOVE_INTEGRATION_FEE,
  REMOVE_INTEGRATION_FEE_FAILED,
  REMOVE_INTEGRATION_FEE_SUCCESS,
  UPDATE_INTEGRATION_FEE,
  UPDATE_INTEGRATION_FEE_FAILED,
  UPDATE_INTEGRATION_FEE_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export interface IntegrationFeesSingleState extends BasicSingleInitialState {}

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_INTEGRATION_FEE,
    createFailed: CREATE_INTEGRATION_FEE_FAILED,
    createInit: CREATE_INTEGRATION_FEE,
    createSuccess: CREATE_INTEGRATION_FEE_SUCCESS,
    itemFailed: GET_INTEGRATION_FEE_FAILED,
    itemInit: GET_INTEGRATION_FEE,
    itemSuccess: GET_INTEGRATION_FEE_SUCCESS,
    removeFailed: REMOVE_INTEGRATION_FEE_FAILED,
    removeInit: REMOVE_INTEGRATION_FEE,
    removeSuccess: REMOVE_INTEGRATION_FEE_SUCCESS,
    updateFailed: UPDATE_INTEGRATION_FEE_FAILED,
    updateInit: UPDATE_INTEGRATION_FEE,
    updateSuccess: UPDATE_INTEGRATION_FEE_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
