import { SubmissionError } from 'redux-form'

export const CREATE_CHILD_BANK_DETAILS = 'CREATE_CHILD_BANK_DETAILS'
export const CREATE_CHILD_BANK_DETAILS_SUCCESS = 'CREATE_CHILD_BANK_DETAILS_SUCCESS'
export const CREATE_CHILD_BANK_DETAILS_FAILED = 'CREATE_CHILD_BANK_DETAILS_FAILED'

export const UPDATE_CHILD_BANK_DETAILS = 'UPDATE_CHILD_BANK_DETAILS'
export const UPDATE_CHILD_BANK_DETAILS_SUCCESS = 'UPDATE_CHILD_BANK_DETAILS_SUCCESS'
export const UPDATE_CHILD_BANK_DETAILS_FAILED = 'UPDATE_CHILD_BANK_DETAILS_FAILED'

export const createChildBankDetails = (legacyChildBankDetailsApiClient) => (
  childId,
  payload,
  onSuccess,
  params = { includes: ['childBankDetail', 'childBankDetailNotes'] },
) => (dispatch) => {
  dispatch({
    childId,
    payload,
    type: CREATE_CHILD_BANK_DETAILS,
  })

  return legacyChildBankDetailsApiClient.create(childId, payload, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: CREATE_CHILD_BANK_DETAILS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: CREATE_CHILD_BANK_DETAILS_FAILED,
      })

      throw new SubmissionError({
        ...error.response.extra,
        _error: error.response.message,
      })
    },
  )
}

export const updateChildBankDetails = (legacyChildBankDetailsApiClient) => (
  childId,
  payload,
  onSuccess,
  params = { includes: ['childBankDetail', 'childBankDetailNotes'] },
) => (dispatch) => {
  dispatch({
    childId,
    payload,
    type: UPDATE_CHILD_BANK_DETAILS,
  })

  return legacyChildBankDetailsApiClient.update(childId, payload, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: UPDATE_CHILD_BANK_DETAILS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: UPDATE_CHILD_BANK_DETAILS_FAILED,
      })

      throw new SubmissionError({
        ...error.response.extra,
        _error: error.response.message,
      })
    },
  )
}
