import moment from 'moment'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { generateRoute } from 'utils/routing'
import { objectToQueryString } from 'utils/api'

import { Avatar, Button, Typography } from 'components'

import i18n from 'translations'

import NurseryRotaItemStatistics from './NurseryRotaItemStatistics'
import {
  StyledActionContainer,
  StyledAvatarContainer,
  StyledItemContainer,
  StyledItemTopContainer,
} from './NurseryRotaItemStyled'

const NurseryRotaItem = ({
  date,
  day,
  nursery,
  viewMode,
}) => {
  const {
    activeStaffCount,
    id,
    logo,
    name,
    publicUrl,
  } = nursery

  const attendanceQueryStringObject = {
    date: moment(date).format(DEFAULT_DATE_FORMAT),
  }

  const rotaQueryStringObject = {
    date: moment(date).format(DEFAULT_DATE_FORMAT),
    viewMode,
  }

  if (day) {
    rotaQueryStringObject.day = moment(day).format(DEFAULT_DATE_FORMAT)
  }

  return (
    <StyledItemContainer key={id}>
      <StyledItemTopContainer>
        <StyledAvatarContainer>
          <Avatar
            avatarSize="medium"
            initials={[name]}
            src={logo}
          />
          <div>
            <Typography color={NEUTRAL_COLOURS.BASIC} margin="0 0 2px" bold>
              {name}
            </Typography>
            <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
              {i18n.t('module:Organization:NurseryRota:Nursery:staffMember', {
                staffMembers: activeStaffCount,
              })}
            </Typography>
          </div>
        </StyledAvatarContainer>
        <StyledActionContainer>
          <Button
            hierarchy="tertiary"
            label={i18n.t('module:Organization:NurseryRota:Nursery:staffAttendanceButtonLabel')}
            size="small"
            target="_blank"
            to={`${publicUrl + generateRoute('STAFF.ATTENDANCE')}?${objectToQueryString(attendanceQueryStringObject)}`}
            negativeMargins
          />
          <Button
            hierarchy="secondary"
            label={i18n.t('module:Organization:NurseryRota:Nursery:rotaButtonLabel')}
            size="small"
            target="_blank"
            to={`${publicUrl + generateRoute('STAFF.ROTA')}?${objectToQueryString(rotaQueryStringObject)}`}
            negativeMargins
          />
        </StyledActionContainer>
      </StyledItemTopContainer>
      <NurseryRotaItemStatistics
        date={date}
        day={day}
        nurseryId={id}
        viewMode={viewMode}
      />
    </StyledItemContainer>
  )
}

export default NurseryRotaItem
