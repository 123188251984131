import { createSelector } from 'reselect'
import moment from 'moment'

import { DAILY_DIARY_DATE_FORMAT, TIME_TYPE } from 'services/legacy/dailyDiary/constants'

import { changeHours, isPastDay } from 'utils/date'

const getDailyDiaryFoods = (state) => state.dailyDiaryFoods

export const getDailyDiaryFoodsList = createSelector([getDailyDiaryFoods], (state) => state.list)

export const getDailyDiaryFoodsListData = createSelector([getDailyDiaryFoodsList], (state) => state.data)

export const getListCriteria = (query = {}) => {
  const { date = moment().format(DAILY_DIARY_DATE_FORMAT), room, type, timeType } = query
  const criteria = []

  criteria.push({
    comparator: 'after',
    field: 'entryDate',
    value: date,
  })

  criteria.push({
    comparator: 'before',
    field: 'entryDate',
    value: date,
  })

  if (room) {
    criteria.push({
      field: 'child.nurseryClass',
      value: room,
    })
  }

  if (type) {
    criteria.push({
      field: 'eatingRecord.type',
      value: type,
    })
  }

  if (timeType === TIME_TYPE.CURRENTLY_TIMED_IN && !isPastDay(date)) {
    criteria.push({
      comparator: 'after',
      field: 'signIns.signedOutAt',
      value: changeHours(date, moment()).toISOString(),
    })
  }

  criteria.push({
    field: 'present',
    value: true,
  })

  criteria.push({
    field: 'order[child.firstName]',
    value: 'ASC',
  })

  return criteria
}
