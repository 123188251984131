import color from 'color'
import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

import { StyledButton } from '../ButtonStyled'

interface StyledRadioButtonWrapperProps {
  $isActive?: boolean
}

export const StyledRadioButtonWrapper = styled.div<StyledRadioButtonWrapperProps>`
  ${({ $isActive }) => $isActive && `
    ${StyledButton} {
      background: ${color(getBrandingColor('primary-color')).alpha(0.2).rgb().string()};
    }
  `}
`
