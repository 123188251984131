import {
  CLEAR_PAYMENT_REMINDER_TAGS,
  LIST_PAYMENT_REMINDER_TAGS,
  LIST_PAYMENT_REMINDER_TAGS_FAILED,
  LIST_PAYMENT_REMINDER_TAGS_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case LIST_PAYMENT_REMINDER_TAGS:
      return { ...state, error: initialState.error, isFetching: true }
    case LIST_PAYMENT_REMINDER_TAGS_SUCCESS:
      return { ...state, data: payload.data, isFetching: false }
    case LIST_PAYMENT_REMINDER_TAGS_FAILED:
      return { ...state, error, isFetching: false }
    case CLEAR_PAYMENT_REMINDER_TAGS:
      return initialState
    default:
      return state
  }
}
