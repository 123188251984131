import styled from 'styled-components'
import color from 'color'

import colors from 'constants/colors'
import layout from 'constants/layout'

import { getBrandingColor } from 'utils/branding'

const allCornersRounded = `${layout.borderRadius}`
const border = `1px solid ${colors.inputBorderColor}`
const leftCornersRounded = `${layout.borderRadius} 0 0 ${layout.borderRadius}`
const rightCornersRounded = `0 ${layout.borderRadius} ${layout.borderRadius} 0`

interface StyledContainerProps {
  $numberOfItems?: number
}

export const StyledContainer = styled.div<StyledContainerProps>`
  width: 100%;
  display: grid;

  ${({ $numberOfItems }) => $numberOfItems && `
    @media(min-width: 941px) {
      grid-template-columns: repeat(${$numberOfItems}, 1fr);
    }

    @media(max-width: 940px) and (min-width: calc(${layout.mobileWidthResolution}px + 1px)) {
      grid-template-columns: repeat(5, 1fr);

      & > div {
        &:nth-child(5n+5) {
          border-radius: ${rightCornersRounded};
        }

        &:nth-child(5n+6) {
          border-left: ${border};

          &:not(:last-child) {
            border-radius: ${leftCornersRounded};
          }

          &:last-child {
            border-radius: ${allCornersRounded};
          }
        }
      }
    }

    @media(max-width: ${layout.mobileWidthResolution}px) and (min-width: 581px) {
      grid-template-columns: repeat(4, 1fr);

      & > div {
        &:nth-child(4n+4) {
          border-radius: ${rightCornersRounded};
        }

        &:nth-child(4n+5) {
          border-left: ${border};

          &:not(:last-child) {
            border-radius: ${leftCornersRounded};
          }

          &:last-child {
            border-radius: ${allCornersRounded};
          }
        }
      }
    }

    @media(max-width: 580px) {
      grid-template-columns: repeat(2, 1fr);

      & > div {
        &:nth-child(2n+2) {
          border-radius: ${rightCornersRounded};
        }

        &:nth-child(2n+3) {
          border-left: ${border};

          &:not(:last-child) {
            border-radius: ${leftCornersRounded};
          }

          &:last-child {
            border-radius: ${allCornersRounded};
          }
        }
      }
    }
  `}

  ${({ $numberOfItems }) => 7 < $numberOfItems && `
    @media(min-width: 941px) {
      grid-template-columns: repeat(8, 1fr);

      & > div {
        &:nth-child(8n+8) {
          border-radius: ${rightCornersRounded};
        }

        &:nth-child(8n+9) {
          border-left: ${border};

          &:not(:last-child) {
            border-radius: ${leftCornersRounded};
          }

          &:last-child {
            border-radius: ${allCornersRounded};
          }
        }
      }
    }
  `}

  ${({ $numberOfItems }) => 7 > $numberOfItems && `
    @media(max-width: 940px) and (min-width: calc(${layout.mobileWidthResolution}px + 1px)) {
      grid-template-columns: repeat(${$numberOfItems}, 1fr);
    }
  `}

  ${({ $numberOfItems }) => 4 > $numberOfItems && `
    @media(max-width: ${layout.mobileWidthResolution}px) and (min-width: 581px) {
      grid-template-columns: repeat(${$numberOfItems}, 1fr);
    }
  `}

  ${({ $numberOfItems }) => 2 > $numberOfItems && `
    @media(max-width: 580px) {
      grid-template-columns: repeat(1, 1fr);
    }
  `}
`

interface StyledItemContainerProps {
  $isClicked?: boolean
  $isDisabled?: boolean
  $isLoading?: boolean
}

export const StyledItemContainer = styled.div<StyledItemContainerProps>`
  margin-bottom: 10px;
  padding: 5px 0;
  border-top: ${border};
  border-bottom: ${border};
  border-right: ${border};

  :first-child {
    border-left: ${border};
    border-radius: ${leftCornersRounded};
  }

  :last-child {
    border-radius: ${rightCornersRounded};
  }

  ${({ $isLoading }) => $isLoading && `
    padding-bottom: 9px;
  `}

  ${({ $isDisabled, $isLoading }) => !$isDisabled && !$isLoading && `
    cursor: pointer;

    &:hover {
      background-color: ${color(getBrandingColor('primary-color')).alpha(0.1).rgb().string()};
    }
  `}

  ${({ $isClicked }) => $isClicked && `
    background-color: ${color(getBrandingColor('primary-color')).alpha(0.1).rgb().string()};
    border-bottom: 3px solid ${getBrandingColor('primary-color')};
    padding-bottom: 2px;

    & span {
      color: ${getBrandingColor('primary-color')};
    }
  `}
`

export const StyledItemContent = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledItemTitle = styled.span`
  color: ${colors.gray};
  padding-bottom: 5px;
  text-align: center;
`

export const StyledItemValue = styled.div`
  font-size: 17px;
  font-weight: 600;
`

export const StyledSubtitle = styled.div`
  margin-top: 5px;  
`
