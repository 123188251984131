import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { OBSERVATION_STATUSES } from 'services/legacy/observations/constants'

import { withAppService } from 'services/app'
import { withObservationsService } from 'services/legacy/observations'

import ObservationsWidgetView from './ObservationsWidgetView'

const OBSERVATION_GROUPS = {
  read: [
    'observation.preview',
    'childObservation.child',
    'child',
  ],
}

class ObservationsWidgetContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pendingCount: null,
    }
  }

  componentDidMount() {
    const { observationsActions } = this.props

    const draftParams = {
      criteria: [{
        field: 'status',
        value: [OBSERVATION_STATUSES.DRAFT.value],
      }],
      groups: OBSERVATION_GROUPS,
    }

    const pendingParams = {
      criteria: [{
        field: 'status',
        value: [OBSERVATION_STATUSES.PENDING_APPROVAL.value],
      }],
      limit: 0,
    }

    observationsActions.list({
      onSuccess: ({ meta: { total_results: totalResults } }) => this.setState({ pendingCount: totalResults }),
      onlyData: true,
      params: pendingParams,
    })
    observationsActions.list({ params: draftParams })
  }

  render() {
    const {
      isFetching,
      observations,
    } = this.props
    const { pendingCount } = this.state

    return (
      <ObservationsWidgetView
        isFetching={isFetching}
        observations={observations}
        pendingCount={pendingCount}
      />
    )
  }
}

const mapState = (state, { appSelectors, observationsListState, observationsSelectors }) => ({
  isFetching: appSelectors.getIsFetching(observationsListState),
  observations: observationsSelectors.getObservationsListDataSelector(state),
})

const enhance = compose(
  withAppService,
  withObservationsService,
  connect(mapState),
)

export default enhance(ObservationsWidgetContainer)
