import _ from 'lodash'

import React from 'react'

import { DefinitionList, GridLayout, MediaPicker, SvgInteractive, Typography } from 'components'

import i18n from 'translations'

import image from 'assets/images/body-map.svg'

const HomeAccidentPreview = ({ injuryPreview }) => {
  const { injured } = injuryPreview
  const { accidentDetails, media } = injured[0]
  const {
    accidentDetails: accidentDetail,
    additionalNotes,
    bodyMapCoordinates,
    firstAidProvided,
    injuryDescription,
    injuryType,
  } = accidentDetails

  return (
    <GridLayout>
      <GridLayout.Group noVerticalMargin>
        <GridLayout.Item>
          <DefinitionList margin="0 0 30px 0">
            <DefinitionList.Item
              label={_.upperFirst(i18n.t('module:Safeguarding:Fields:AccidentDetails:label'))}
              value={(
                <Typography>
                  {accidentDetail}
                </Typography>
              )}
              whiteSpace="pre-wrap"
              wordBreak="break-word"
            />
          </DefinitionList>
        </GridLayout.Item>
      </GridLayout.Group>
      <GridLayout.Group noVerticalMargin>
        <GridLayout.Item>
          <DefinitionList margin="0 0 30px 0">
            <DefinitionList.Item
              label={i18n.t('module:Safeguarding:Fields:FirstAidProvided:label')}
              value={firstAidProvided || '-'}
              whiteSpace="pre-wrap"
              wordBreak="break-word"
            />
          </DefinitionList>
        </GridLayout.Item>
      </GridLayout.Group>
      <GridLayout.Group>
        <DefinitionList margin="0 0 10px 0" vertical>
          <DefinitionList.Item
            label={_.upperFirst(i18n.t('module:Safeguarding:Fields:InjuryDetails:label'))}
            size="big"
          />
        </DefinitionList>
      </GridLayout.Group>
      <GridLayout.Group noVerticalMargin>
        <GridLayout.Item>
          <DefinitionList margin="0 0 20px 0" vertical>
            <DefinitionList.Item
              label={_.upperFirst(i18n.t('module:Safeguarding:Fields:Injury:label'))}
              value={injuryType || '-'}
            />
          </DefinitionList>
        </GridLayout.Item>
        <GridLayout.Item />
      </GridLayout.Group>
      <GridLayout.Group noVerticalMargin>
        <GridLayout.Item>
          <DefinitionList margin="0 0 30px 0">
            <DefinitionList.Item
              label={_.upperFirst(i18n.t('module:Safeguarding:Fields:InjuryDescription:label'))}
              value={injuryDescription}
              whiteSpace="pre-wrap"
              wordBreak="break-word"
            />
          </DefinitionList>
        </GridLayout.Item>
      </GridLayout.Group>
      {bodyMapCoordinates?.length ? (
        <React.Fragment>
          <GridLayout.Group noVerticalMargin>
            <GridLayout.Item>
              <DefinitionList vertical>
                <DefinitionList.Item label={i18n.t('module:Safeguarding:Fields:BodyMap:label')} />
              </DefinitionList>
            </GridLayout.Item>
          </GridLayout.Group>
          <div style={{ cursor: 'not-allowed' }}>
            <GridLayout.Group noVerticalMargin>
              <GridLayout.Item>
                <SvgInteractive
                  defaultValues={bodyMapCoordinates}
                  image={image}
                  svgViewBox="0 0 924 535"
                  readOnly
                />
              </GridLayout.Item>
            </GridLayout.Group>
          </div>
        </React.Fragment>
      ) : null }
      <GridLayout.Group noVerticalMargin>
        <GridLayout.Item>
          <DefinitionList margin="0 0 30px 0" vertical>
            <DefinitionList.Item
              label={i18n.t('module:Injury:Preview:Details:attachedMediaLabel')}
              value={(media?.length)
                ? <MediaPicker value={media} disableStick disableSticky disableTagging preview />
                : '-'}
            />
          </DefinitionList>
        </GridLayout.Item>
      </GridLayout.Group>
      <GridLayout.Group noVerticalMargin>
        <GridLayout.Item>
          <DefinitionList margin="0 0 30px 0" vertical>
            <DefinitionList.Item
              label={i18n.t('module:Safeguarding:Fields:AdditionalNotes:label')}
              value={additionalNotes || '-'}
              whiteSpace="pre-wrap"
              wordBreak="break-word"
            />
          </DefinitionList>
        </GridLayout.Item>
      </GridLayout.Group>
    </GridLayout>
  )
}

export default HomeAccidentPreview
