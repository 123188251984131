import withFinanceGateway from 'module/Finance/withFinanceGateway'

import FinanceWrapper from 'module/Finance/index'
import FinanceInvoices from 'module/Finance/FinanceInvoices'
import FinanceReport from 'module/Finance/FinanceReport'
import FinanceReportAgedDebt from 'module/Finance/FinanceReport/AgedDebt'
import FinanceReportBadDebt from 'module/Finance/FinanceReport/BadDebt'
import FinanceReportForecasting from 'module/Finance/FinanceReport/FinanceReportForecasting'
import FinanceReportFundingV2 from 'module/Finance/FinanceReport/Funding/V2'
import FinanceReportFundingV3 from 'module/Finance/FinanceReport/Funding/V3'
import FinanceReportCreditNotes from 'module/Finance/FinanceReport/CreditNotes'
import FinanceReportBalance from 'module/Finance/FinanceReport/Balance'
import FinanceReportRevenuePerRoom from 'module/Finance/FinanceReport/RevenuePerRoom'
import FinanceReportRevenuePerAge from 'module/Finance/FinanceReport/RevenuePerAge'
import FinancePayments from 'module/Finance/FinancePayments'
import RegularBookingsList from 'module/Finance/RegularBookings/RegularBookingsList'
import AddPaymentWrapper from 'module/Finance/FinancePayments/components/AddPaymentWrapper'
import FinancePaymentsImport from 'module/Finance/FinancePayments/FinancePaymentsImport'
import FinanceGenerateInvoices from 'module/Finance/FinanceGenerateInvoices'
import FinancePayouts from 'module/Finance/FinancePayouts'
import FinancePayoutDetail from 'module/Finance/FinancePayouts/Detail'
import FinanceGenerateInvoicesPreview from 'module/Finance/FinanceGenerateInvoices/FinanceGenerateInvoicesPreview'
import FinanceReportDetailedRevenue from 'module/Finance/FinanceReport/FinanceReportDetailedRevenue'
import FinanceReportAnnualRevenue from 'module/Finance/FinanceReport/FinanceReportAnnualRevenue'
import FinanceReportFailedRecurringPayment from 'module/Finance/FinanceReport/FailedRecurringPayment'
import FinanceReportActiveRecurringPayment from 'module/Finance/FinanceReport/ActiveRecurringPayment'
import ChildFinanceInvoicesPayment from 'module/Children/Child/ChildFinance/ChildFinanceInvoicesPayment'
import Funding from 'module/Finance/FinanceFunding'
import PaymentDetails from 'module/Finance/FinancePayments/PaymentDetails'
import DepositsWrapper from 'module/Finance/Deposits/DepositsWrapper'
import DepositsList from 'module/Finance/Deposits/List'
import DepositsAdd from 'module/Finance/Deposits/Add'
import OneOffBookingsWrapper from 'module/Finance/OneOffBookings/OneOffBookingsWrapper'
import OneOffBookingsList from 'module/Finance/OneOffBookings/List'
import OneOffBookingsAdd from 'module/Finance/OneOffBookings/Add'

import i18n from 'translations'

export const ROUTE_NAMES = {
  'FINANCE.BULK': 'FINANCE.BULK',
  'FINANCE.BULK.PREVIEW': 'FINANCE.BULK.PREVIEW',
  'FINANCE.DEPOSITS': 'FINANCE.DEPOSITS',
  'FINANCE.DEPOSITS.ADD': 'FINANCE.DEPOSITS.ADD',
  'FINANCE.DEPOSITS.EDIT': 'FINANCE.DEPOSITS.EDIT',
  'FINANCE.DEPOSITS.REFUND': 'FINANCE.DEPOSITS.REFUND',
  'FINANCE.FUNDING': 'FINANCE.FUNDING',
  'FINANCE.INDEX': 'FINANCE.INDEX',
  'FINANCE.INVOICES': 'FINANCE.INVOICES',
  'FINANCE.ONE_OFF_BOOKINGS': 'FINANCE.ONE_OFF_BOOKINGS',
  'FINANCE.ONE_OFF_BOOKINGS.ADD': 'FINANCE.ONE_OFF_BOOKINGS.ADD',
  'FINANCE.ONE_OFF_BOOKINGS.EDIT': 'FINANCE.ONE_OFF_BOOKINGS.EDIT',
  'FINANCE.PAYMENTS': 'FINANCE.PAYMENTS',
  'FINANCE.PAYMENTS.ADD': 'FINANCE.PAYMENTS.ADD',
  'FINANCE.PAYMENTS.DETAILS': 'FINANCE.PAYMENTS.DETAILS',
  'FINANCE.PAYMENTS.IMPORT': 'FINANCE.PAYMENTS.IMPORT',
  'FINANCE.PAYOUTS': 'FINANCE.PAYOUTS',
  'FINANCE.PAYOUTS.DETAIL': 'FINANCE.PAYOUTS.DETAIL',
  'FINANCE.REGULAR_BOOKINGS': 'FINANCE.REGULAR_BOOKINGS',
  'FINANCE.REPORT': 'FINANCE.REPORT',
  'FINANCE.REPORT.ACCOUNTS': 'FINANCE.REPORT.ACCOUNTS',
  'FINANCE.REPORT.ACCOUNTS.FUNDING': 'FINANCE.REPORT.ACCOUNTS.FUNDING',
  'FINANCE.REPORT.ACCOUNTS.OVERVIEW': 'FINANCE.REPORT.ACCOUNTS.OVERVIEW',
  'FINANCE.REPORT.ACCOUNTS.OVERVIEW.DETAILS': 'FINANCE.REPORT.ACCOUNTS.OVERVIEW.DETAILS',
  'FINANCE.REPORT.ACTIVE_RECURRING_PAYMENT': 'FINANCE.REPORT.ACTIVE_RECURRING_PAYMENT',
  'FINANCE.REPORT.AGED_BAND': 'FINANCE.REPORT.AGED_BAND',
  'FINANCE.REPORT.AGED_BAND.DETAILS': 'FINANCE.REPORT.AGED_BAND.DETAILS',
  'FINANCE.REPORT.AGED_DEBT': 'FINANCE.REPORT.AGED_DEBT',
  'FINANCE.REPORT.ANNUAL_REVENUE': 'FINANCE.REPORT.ANNUAL_REVENUE',
  'FINANCE.REPORT.BAD_DEBT': 'FINANCE.REPORT.BAD_DEBT',
  'FINANCE.REPORT.BALANCE': 'FINANCE.REPORT.BALANCE',
  'FINANCE.REPORT.CREDIT_REPORT': 'FINANCE.REPORT.CREDIT_REPORT',
  'FINANCE.REPORT.DETAILED_REVENUE': 'FINANCE.REPORT.DETAILED_REVENUE',
  'FINANCE.REPORT.FAILED_RECURRING_PAYMENT': 'FINANCE.REPORT.FAILED_RECURRING_PAYMENT',
  'FINANCE.REPORT.FORECASTING': 'FINANCE.REPORT.FORECASTING',
  'FINANCE.REPORT.FUNDING': 'FINANCE.REPORT.FUNDING',
  'FINANCE.REPORT.FUNDING_ELIGIBILITY': 'FINANCE.REPORT.FUNDING_ELIGIBILITY',
  'FINANCE.REPORT.REVENUE_PER_AGE': 'FINANCE.REPORT.REVENUE_PER_AGE',
  'FINANCE.REPORT.REVENUE_PER_ROOM': 'FINANCE.REPORT.REVENUE_PER_ROOM',
}

const FinanceRouting = {
  childRoutes: [
    {
      indexRoute: {
        component: FinanceInvoices,
      },
      name: ROUTE_NAMES['FINANCE.INVOICES'],
      path: 'invoices',
      title: i18n.t('module:Finance:Invoices:title'),
    },
    {
      childRoutes: [
        {
          component: FinanceReportDetailedRevenue,
          name: ROUTE_NAMES['FINANCE.REPORT.DETAILED_REVENUE'],
          path: 'detailed-revenue',
          title: i18n.t('module:Finance:Reports:DetailedRevenue:title'),
        },
        {
          component: FinanceReportAnnualRevenue,
          name: ROUTE_NAMES['FINANCE.REPORT.ANNUAL_REVENUE'],
          path: 'annual-revenue',
          title: i18n.t('module:Finance:Reports:AnnualRevenue:title'),
        },
        {
          component: FinanceReportBadDebt,
          name: ROUTE_NAMES['FINANCE.REPORT.BAD_DEBT'],
          path: 'bad-debt',
          title: i18n.t('module:Finance:Reports:BadDebt:title'),
        },
        {
          component: FinanceReportAgedDebt,
          name: ROUTE_NAMES['FINANCE.REPORT.AGED_DEBT'],
          path: 'aged-debt',
          title: i18n.t('module:Finance:Reports:AgedDebt:title'),
        },
        {
          component: FinanceReportForecasting,
          name: ROUTE_NAMES['FINANCE.REPORT.FORECASTING'],
          path: 'forecasting',
          title: i18n.t('module:Finance:Reports:Forecasting:title'),
        },
        {
          component: withFinanceGateway({ V2: FinanceReportFundingV2, V3: FinanceReportFundingV3 }),
          name: ROUTE_NAMES['FINANCE.REPORT.FUNDING'],
          path: 'funding',
          title: i18n.t('module:Finance:Reports:Funding:title'),
        },
        {
          component: FinanceReportCreditNotes,
          name: ROUTE_NAMES['FINANCE.REPORT.CREDIT_REPORT'],
          path: 'credit-notes',
          title: i18n.t('module:Finance:Reports:CreditNotes:title'),
        },
        {
          component: FinanceReportBalance,
          name: ROUTE_NAMES['FINANCE.REPORT.BALANCE'],
          path: 'balance',
          title: i18n.t('module:Finance:Reports:Balances:title'),
        },
        {
          component: FinanceReportRevenuePerAge,
          name: ROUTE_NAMES['FINANCE.REPORT.REVENUE_PER_AGE'],
          path: 'revenue-per-age',
          title: i18n.t('module:Finance:Reports:RevenuePerAge:title'),
        },
        {
          component: FinanceReportRevenuePerRoom,
          name: ROUTE_NAMES['FINANCE.REPORT.REVENUE_PER_ROOM'],
          path: 'revenue-per-room',
          title: i18n.t('module:Finance:Reports:RevenuePerRoom:title'),
        },
        {
          component: FinanceReportFailedRecurringPayment,
          name: ROUTE_NAMES['FINANCE.REPORT.FAILED_RECURRING_PAYMENT'],
          path: 'failed-recurring-payment',
          title: i18n.t('module:Finance:Reports:FailedRecurringPayment:title'),
        },
        {
          component: FinanceReportActiveRecurringPayment,
          name: ROUTE_NAMES['FINANCE.REPORT.ACTIVE_RECURRING_PAYMENT'],
          path: 'active-recurring-payment',
          title: i18n.t('module:Finance:Reports:ActiveRecurringPayment:title'),
        },
      ],
      indexRoute: {
        component: FinanceReport,
      },
      name: ROUTE_NAMES['FINANCE.REPORT'],
      path: 'report',
      title: i18n.t('module:Finance:Reports:title'),
    },
    {
      childRoutes: [
        {
          component: FinanceGenerateInvoicesPreview,
          name: ROUTE_NAMES['FINANCE.BULK.PREVIEW'],
          path: 'preview',
          title: i18n.t('module:Finance:GenerateInvoices:Preview:breadcrumbsTitle'),
        },
      ],
      indexRoute: {
        component: FinanceGenerateInvoices,
      },
      name: ROUTE_NAMES['FINANCE.BULK'],
      path: 'bulk',
      title: i18n.t('module:Finance:GenerateInvoices:breadcrumbsTitle'),
    },
    {
      childRoutes: [
        {
          component: AddPaymentWrapper,
          indexRoute: {
            component: ChildFinanceInvoicesPayment,
          },
          name: ROUTE_NAMES['FINANCE.PAYMENTS.ADD'],
          path: 'add',
          title: i18n.t('module:Children:Child:Finance:Payment:Add:breadcrumbsTitle'),
        },
        {
          component: PaymentDetails,
          name: ROUTE_NAMES['FINANCE.PAYMENTS.DETAILS'],
          path: ':paymentId/details',
          title: i18n.t('module:Finance:Payments:viewDetails'),
        },
        {
          component: FinancePaymentsImport,
          name: ROUTE_NAMES['FINANCE.PAYMENTS.IMPORT'],
          noBreadcrumb: true,
          path: 'import',
          paymentsImportMainHeader: true,
          title: i18n.t('module:Modals:PaymentsImport:title'),
        },
      ],
      indexRoute: {
        component: FinancePayments,
      },
      name: ROUTE_NAMES['FINANCE.PAYMENTS'],
      path: 'payments',
      title: i18n.t('module:Finance:Payments:title'),
    },
    {
      indexRoute: {
        component: Funding,
      },
      name: ROUTE_NAMES['FINANCE.FUNDING'],
      path: 'funding',
      title: i18n.t('module:Finance:Funding:title'),
    },
    {
      indexRoute: {
        component: RegularBookingsList,
      },
      name: ROUTE_NAMES['FINANCE.REGULAR_BOOKINGS'],
      path: 'regular-bookings',
      title: i18n.t('module:Finance:RegularBookings:title'),
    },
    {
      childRoutes: [
        {
          component: FinancePayoutDetail,
          name: ROUTE_NAMES['FINANCE.PAYOUTS.DETAIL'],
          path: ':payoutId/detail',
          title: i18n.t('module:Finance:Payouts:Detail:routeTitle'),
        },
      ],
      indexRoute: {
        component: FinancePayouts,
      },
      name: ROUTE_NAMES['FINANCE.PAYOUTS'],
      path: 'payouts',
      title: i18n.t('module:Finance:Payouts:title'),
    },
    {
      childRoutes: [
        {
          component: DepositsAdd,
          name: ROUTE_NAMES['FINANCE.DEPOSITS.ADD'],
          path: 'add',
          title: i18n.t('module:Finance:Deposits:Form:Title:add'),
        },
        {
          component: DepositsAdd,
          name: ROUTE_NAMES['FINANCE.DEPOSITS.EDIT'],
          path: ':depositId/edit',
          title: i18n.t('module:Finance:Deposits:Form:Title:edit'),
        },
        {
          component: DepositsAdd,
          name: ROUTE_NAMES['FINANCE.DEPOSITS.REFUND'],
          path: ':depositId/refund',
          title: i18n.t('module:Finance:Deposits:Form:Title:refund'),
        },
      ],
      component: DepositsWrapper,
      indexRoute: {
        component: DepositsList,
      },
      name: ROUTE_NAMES['FINANCE.DEPOSITS'],
      path: 'deposits',
      title: i18n.t('module:Finance:Deposits:title'),
    },
    {
      childRoutes: [
        {
          component: OneOffBookingsAdd,
          name: ROUTE_NAMES['FINANCE.ONE_OFF_BOOKINGS.ADD'],
          path: 'add',
          title: i18n.t('module:Finance:OneOffBookings:Form:Title:add'),
        },
        {
          component: OneOffBookingsAdd,
          name: ROUTE_NAMES['FINANCE.ONE_OFF_BOOKINGS.EDIT'],
          path: ':oneOffBookingId/edit',
          title: i18n.t('module:Finance:OneOffBookings:Form:Title:edit'),
        },
      ],
      component: OneOffBookingsWrapper,
      indexRoute: {
        component: OneOffBookingsList,
      },
      name: ROUTE_NAMES['FINANCE.ONE_OFF_BOOKINGS'],
      path: 'one-off-bookings',
      title: i18n.t('module:Finance:OneOffBookings:title'),
    },
  ],
  component: FinanceWrapper,
  name: ROUTE_NAMES['FINANCE.INDEX'],
  path: 'management/finance',
  title: i18n.t('module:Finance:title'),
}

FinanceRouting.ROUTE_NAMES = ROUTE_NAMES

export default FinanceRouting
