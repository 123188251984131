import React from 'react'

import { generateRoute } from 'utils/routing'

import { EmptyState, Hyperlink, Page, Section, Spinner } from 'components'

import i18n from 'translations'

import ProgressTrackerGeneratorForm from './components/ProgressTrackerGeneratorForm'

const ProgressTrackerGeneratorView = ({
  frameworksList,
  isFetching,
  ...rest
}) => {
  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    if (!frameworksList?.length) {
      return (
        <Section title={i18n.t('module:Learning:CohortTracking:ProgressTrackerGenerator:title')}>
          <EmptyState
            icon="cohort-tracking"
            text1={i18n.t('module:Learning:CohortTracking:ProgressTrackerGenerator:noFrameworksList')}
            text2={(
              <Hyperlink to={generateRoute('MANAGEMENT.LEARNING.CURRICULUMS')} primary>
                {i18n.t('module:Learning:CohortTracking:global:goToSettings')}
              </Hyperlink>
            )}
          />
        </Section>
      )
    }

    return (
      <ProgressTrackerGeneratorForm
        frameworksList={frameworksList}
        {...rest}
      />
    )
  }

  return (
    <Page isLoading={isFetching}>
      <Page.Content>
        {renderContent()}
      </Page.Content>
    </Page>
  )
}

export default ProgressTrackerGeneratorView
