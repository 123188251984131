export const MEMBERSHIP_TYPE = {
  NURSERY: 'nursery',
  ORGANIZATION: 'organization',
  SUPER_ADMIN: 'superAdmin',
}

export const STAFF_MODULE_TYPE = {
  LEAVE: 'leave',
  PAYROLL: 'payroll',
  ROTA: 'rota',
  WORKLOG: 'worklog',
}
