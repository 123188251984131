export const GET_CHILD_BOOKING = 'CHILD_BOOKING/GET_CHILD_BOOKING'
export const GET_CHILD_BOOKING_SUCCESS = 'CHILD_BOOKING/GET_CHILD_BOOKING_SUCCESS'
export const GET_CHILD_BOOKING_FAILED = 'CHILD_BOOKING/GET_CHILD_BOOKING_FAILED'

export const CREATE_CHILD_BOOKING = 'CHILD_BOOKING/CREATE_CHILD_BOOKING'
export const CREATE_CHILD_BOOKING_SUCCESS = 'CHILD_BOOKING/CREATE_CHILD_BOOKING_SUCCESS'
export const CREATE_CHILD_BOOKING_FAILED = 'CHILD_BOOKING/CREATE_CHILD_BOOKING_FAILED'

export const UPDATE_CHILD_BOOKING = 'CHILD_BOOKING/UPDATE_CHILD_BOOKING'
export const UPDATE_CHILD_BOOKING_SUCCESS = 'CHILD_BOOKING/UPDATE_CHILD_BOOKING_SUCCESS'
export const UPDATE_CHILD_BOOKING_FAILED = 'CHILD_BOOKING/UPDATE_CHILD_BOOKING_FAILED'

export const DELETE_CHILD_BOOKING = 'CHILD_BOOKING/DELETE_CHILD_BOOKING'
export const DELETE_CHILD_BOOKING_SUCCESS = 'CHILD_BOOKING/DELETE_CHILD_BOOKING_SUCCESS'
export const DELETE_CHILD_BOOKING_FAILED = 'CHILD_BOOKING/DELETE_CHILD_BOOKING_FAILED'

export const CLEAR_NURSERY_FUNDING_SINGLE = 'CHILD_BOOKING/CLEAR_NURSERY_FUNDING_SINGLE'
