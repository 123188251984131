import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as childRegistersSelectors from './selectors'

const withChildRegistersService = (WrappedComponent) => {
  const mapState = ({ childRegisters }) => ({ childRegisters })

  const mapDispatch = injectActions('childRegistersActions', actions)

  const Component = ({ childRegisters, childRegistersActions, ...others }) => (
    <WrappedComponent
      childRegistersActions={childRegistersActions}
      childRegistersListState={childRegisters.list}
      childRegistersSelectors={childRegistersSelectors}
      childRegistersSingleState={childRegisters.single}
      childRegistersStatisticsState={childRegisters.statistics}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withChildRegistersService
