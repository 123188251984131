import _ from 'lodash'

import React from 'react'

import { generateRoute } from 'utils/routing'

import { DatePicker, EmptyState, Page, Pagination, SearchBar, Section, Select, Spinner, Toolbar } from 'components'

import i18n from 'translations'

import GroupDropdown from '../components/GroupDropdown'
import NewsListItem from './components/NewsListItem'

const NewsListView = ({
  authAccessMap,
  date,
  isFetching,
  news,
  onDateChange,
  onDeleteNewsItem,
  onPageChange,
  onSearchBarChange,
  onSharedWithChange,
  onTypeChange,
  page,
  pageCount,
  searchBarValue,
  sharedWith,
  totalResults,
  type,
  typeOptions,
}) => {
  const actions = authAccessMap.module.NewslettersAdd && (
    <Section.Actions primary={[{ to: generateRoute('NEWSLETTERS.ADD') }]} />
  )

  const renderHeader = () => (
    <Toolbar bottomLine>
      <Toolbar.Group>
        <Toolbar.Item>
          <DatePicker
            value={date}
            clearable
            range
            onChange={onDateChange}
          />
        </Toolbar.Item>
        {authAccessMap.module.NewslettersAdd && (
          <React.Fragment>
            <Toolbar.Item>
              <GroupDropdown
                multi={false}
                placeholder={i18n.t('module:Newsletters:List:sharedWithPlaceholder')}
                value={sharedWith}
                onChange={onSharedWithChange}
              />
            </Toolbar.Item>
            <Toolbar.Item>
              <Select
                clearable={false}
                options={typeOptions}
                placeholder={i18n.t('module:Newsletters:List:typePlaceholder')}
                value={type}
                onChange={onTypeChange}
              />
            </Toolbar.Item>
          </React.Fragment>
        )}
      </Toolbar.Group>
    </Toolbar>
  )

  const renderPagination = () => (
    <Pagination
      page={page}
      pageCount={pageCount}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    if (!isFetching && (!news?.length)) {
      return (
        <EmptyState
          icon="news"
          text1={i18n.t('module:Newsletters:List:emptyStateText')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        {_.map(news, (item) => (
          <NewsListItem
            item={item}
            key={item.id}
            onDeleteNewsItem={onDeleteNewsItem}
          />
        ))}
        {renderPagination()}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <SearchBar
        placeholder={i18n.t('module:Newsletters:List:searchPlaceholder')}
        value={searchBarValue}
        onChange={onSearchBarChange}
      />
      <Page.Section
        actions={actions}
        isLoading={isFetching}
        title={i18n.t('module:Newsletters:List:title')}
      >
        {renderHeader()}
        {renderContent()}
      </Page.Section>
    </React.Fragment>
  )
}

export default NewsListView
