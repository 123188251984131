export const getDropdownListCriteria = (filters) => {
  const { name } = filters || {}

  const criteria = [
    {
      field: 'archived',
      value: false,
    },
  ]

  if (name) {
    criteria.push({
      field: 'name',
      value: name,
    })
  }

  return criteria
}
