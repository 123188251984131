import styled from 'styled-components'

import layout from 'constants/layout'

export const StyledAvatarListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  width: 100%;
  align-items: flex-start;
  grid-gap: 10px;
`

export const StyledColumnContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 25px;
  
  @media(max-width: ${layout.mobileWidthResolution}px) {
    grid-template-columns: auto;
  }
`

export const StyledCarerInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledCarerDropdown = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;

  > a {
    align-items: flex-start;
    margin-top: 5px;
  }
`
