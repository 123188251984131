import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_NURSERY_FRAMEWORK_RELATION,
  CREATE_NURSERY_FRAMEWORK_RELATION,
  CREATE_NURSERY_FRAMEWORK_RELATION_FAILED,
  CREATE_NURSERY_FRAMEWORK_RELATION_SUCCESS,
  REMOVE_NURSERY_FRAMEWORK_RELATION,
  REMOVE_NURSERY_FRAMEWORK_RELATION_FAILED,
  REMOVE_NURSERY_FRAMEWORK_RELATION_SUCCESS,
  UPDATE_NURSERY_FRAMEWORK_RELATION,
  UPDATE_NURSERY_FRAMEWORK_RELATION_FAILED,
  UPDATE_NURSERY_FRAMEWORK_RELATION_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_NURSERY_FRAMEWORK_RELATION,
    createFailed: CREATE_NURSERY_FRAMEWORK_RELATION_FAILED,
    createInit: CREATE_NURSERY_FRAMEWORK_RELATION,
    createSuccess: CREATE_NURSERY_FRAMEWORK_RELATION_SUCCESS,
    removeFailed: REMOVE_NURSERY_FRAMEWORK_RELATION_FAILED,
    removeInit: REMOVE_NURSERY_FRAMEWORK_RELATION,
    removeSuccess: REMOVE_NURSERY_FRAMEWORK_RELATION_SUCCESS,
    updateFailed: UPDATE_NURSERY_FRAMEWORK_RELATION_FAILED,
    updateInit: UPDATE_NURSERY_FRAMEWORK_RELATION,
    updateSuccess: UPDATE_NURSERY_FRAMEWORK_RELATION_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
