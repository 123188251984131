import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledTable = styled.table`
  border-spacing: 0 10px;
`

export const StyledTr = styled.tr`
  padding: 0;
`

export const StyledTh = styled.th`
  font-size: 16px;
  color: ${getBrandingColor('primary-color')};
  font-weight: 600;
  padding-right: 10px;
  text-align: left;
`

export const StyledTd = styled.td`
  font-size: 16px;
`
