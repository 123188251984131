import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getFormValues, initialize, isValid as isValidForm, submit as submitForm } from 'redux-form'

import { withUploadService } from 'services/legacy/upload'

import MediaTagModalView from './MediaTagModalView'
import { MEDIA_TAG_MODAL_FORM } from './components/MediaTagModalForm'

class MediaTagModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fileIndex: 0,
      fileName: '',
      files: [],
      selectAll: false,
      selectedChildren: [],
    }
  }

  componentDidMount() {
    const { files, initializeValues } = this.props
    const { fileIndex } = this.state

    const file = files[fileIndex]

    initializeValues({
      name: file?.name,
    })

    this.setState({
      files,
      selectedChildren: file?.taggedChildren || [],
    })
  }

  handleSubmit = async () => {
    const { formValues, initializeValues, isValid, onSuccess, submit, sync, uploadActions } = this.props
    const { fileIndex, selectedChildren } = this.state
    let { files } = this.state

    submit()

    if (isValid) {
      const { name } = formValues
      const file = files[fileIndex]

      if (file?.itemFromBe && onSuccess && !sync) {
        this.handleCloseClick()

        return onSuccess({ file, name, selectedChildren })
      }

      if (sync) {
        files = _.map(files, (item) => {
          const updatedFile = { ...item }

          if (file.id === updatedFile.id) {
            updatedFile.name = name
            updatedFile.children = selectedChildren
          }

          return updatedFile
        })

        onSuccess({ file, name, selectedChildren }, files)
      } else {
        uploadActions.updateUploadFile(file.id, {
          initialization: {
            onFe: true,
          },
          name,
          taggedChildren: selectedChildren,
        })
      }

      const nextIndex = fileIndex + 1
      const nextFile = files[nextIndex]

      if (nextFile) {
        initializeValues({
          name: nextFile.name,
        })

        return this.setState({
          fileIndex: nextIndex,
          files,
          selectAll: false,
          selectedChildren: [],
        })
      }

      return this.handleCloseClick()
    }

    return false
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSelectAll = (value) => {
    const { childrenList } = this.props
    let childrenCanBePhotographed = []
    let childrenCannotBeOnGroupPhotos = []

    if (value) {
      childrenCanBePhotographed = _.filter(childrenList, (child) => {
        const { information } = child || {}
        const { canBePhotographed, canBeTaggedInGroupPhotos } = information

        return canBePhotographed && canBeTaggedInGroupPhotos
      })
      childrenCannotBeOnGroupPhotos = _.filter(childrenList, (child) => {
        const { information } = child || {}
        const { canBePhotographed, canBeTaggedInGroupPhotos } = information

        return !canBeTaggedInGroupPhotos && canBePhotographed
      })
    }

    if (value && !childrenCanBePhotographed.length && 1 === childrenCannotBeOnGroupPhotos.length) {
      return this.setState({
        selectAll: value,
        selectedChildren: childrenCannotBeOnGroupPhotos,
      })
    }

    return this.setState({
      selectAll: value,
      selectedChildren: childrenCanBePhotographed,
    })
  }

  handleSelectChild = (child) => {
    const { childrenList } = this.props
    let { selectAll, selectedChildren } = this.state

    if (_.find(selectedChildren, ({ id: childId }) => child.id === childId)) {
      selectedChildren = _.filter(selectedChildren, ({ id: childId }) => child.id !== childId)
      selectAll = false
    } else if (!child.information.canBeTaggedInGroupPhotos) {
      selectedChildren = [child]
      selectAll = false
    } else {
      selectedChildren = _.filter(selectedChildren, ({ id, information }) => {
        const { canBeTaggedInGroupPhotos } = information

        if (_.isUndefined(canBeTaggedInGroupPhotos)) {
          const fullChild = _.find(childrenList, (item) => id === item.id)

          return fullChild?.canBeTaggedInGroupPhotos
        }

        return canBeTaggedInGroupPhotos
      })

      selectedChildren.push(child)
    }

    this.setState({ selectAll, selectedChildren })
  }

  render() {
    const { childrenList, disableTagging, files } = this.props
    const { fileIndex, fileName, selectAll, selectedChildren } = this.state

    return (
      <MediaTagModalView
        childrenList={childrenList}
        disableTagging={disableTagging}
        fileIndex={fileIndex}
        fileName={fileName}
        files={files}
        selectAll={selectAll}
        selectedChildren={selectedChildren}
        onCloseClick={this.handleCloseClick}
        onSelectAll={this.handleSelectAll}
        onSelectChild={this.handleSelectChild}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  initializeValues: (values) => initialize(MEDIA_TAG_MODAL_FORM, values),
  submit: () => submitForm(MEDIA_TAG_MODAL_FORM),
}

const mapState = (state) => ({
  formValues: getFormValues(MEDIA_TAG_MODAL_FORM)(state),
  isValid: isValidForm(MEDIA_TAG_MODAL_FORM)(state),
})

const enhance = compose(
  withUploadService,
  connect(mapState, mapDispatch),
)

export default enhance(MediaTagModal)
