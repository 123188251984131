import InvoiceAnnualisedSettingForm from './InvoiceAnnualisedSettingForm'
import InvoiceAnnualisedSettingView from './InvoiceAnnualisedSettingView'
import InvoiceBankSettingForm from './InvoiceBankSettingForm'
import InvoiceBankSettingView from './InvoiceBankSettingView'
import InvoiceNumbersSettingForm from './InvoiceNumbersSettingForm'
import InvoiceNumbersSettingView from './InvoiceNumbersSettingView'
import InvoiceSettingV2 from './InvoiceSetting/InvoiceSettingV2'
import InvoiceSettingV3 from './InvoiceSetting/InvoiceSettingV3'

interface SettingsProps {
  InvoiceAnnualisedSettingForm?: typeof InvoiceAnnualisedSettingForm
  InvoiceAnnualisedSettingView?: typeof InvoiceAnnualisedSettingView
  InvoiceBankSettingForm?: typeof InvoiceBankSettingForm
  InvoiceBankSettingView?: typeof InvoiceBankSettingView
  InvoiceNumbersSettingForm?: typeof InvoiceNumbersSettingForm
  InvoiceNumbersSettingView?: typeof InvoiceNumbersSettingView
  InvoiceSettingV2?: typeof InvoiceSettingV2
  InvoiceSettingV3?: typeof InvoiceSettingV3
}

const Settings: SettingsProps = {}

Settings.InvoiceBankSettingForm = InvoiceBankSettingForm
Settings.InvoiceBankSettingView = InvoiceBankSettingView
Settings.InvoiceNumbersSettingForm = InvoiceNumbersSettingForm
Settings.InvoiceNumbersSettingView = InvoiceNumbersSettingView
Settings.InvoiceSettingV2 = InvoiceSettingV2
Settings.InvoiceSettingV3 = InvoiceSettingV3
Settings.InvoiceAnnualisedSettingForm = InvoiceAnnualisedSettingForm
Settings.InvoiceAnnualisedSettingView = InvoiceAnnualisedSettingView

export default Settings

