import _ from 'lodash'
import { flatten, nest } from 'utils/flatnest'
import moment from 'moment'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import {
  ALLERGY_TYPES,
  DIETARY_NO_PREFERENCE,
  DIETARY_OTHER,
  DIETARY_PESCATARIAN,
  DIETARY_VEGAN,
  DIETARY_VEGETARIAN,
} from 'services/legacy/child/constants'
import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'

import { withChildService, withChildStatisticsService } from 'services/legacy/child'
import { withModalService } from 'services/utils/modal'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withRouter } from 'services/router'

import ChildAdd from 'module/Children/Child/ChildAdd'

import DietaryReportView from './DietaryReportView'
import { DIETARY_REPORT_LIST_COLUMNS } from './tableConfig'
import { getDietaryTableLists } from './helpers'

const GROUPS = {
  read: [
    'child.childrenSessions:array',
    'childInformation',
  ],
}
const DATE_FORMAT = 'YYYY-MM-DD'
const CHILDREN_OPTIONS = [
  { label: 'All children', value: false },
  { label: 'All children due in', value: true },
]

class DietaryReportContainer extends Component {
  constructor(props) {
    super(props)

    const { query } = props.location
    const {
      allergies,
      missingInfo,
      noAllergies,
      noPreference,
      notSpecifiedAllergies,
      other,
      pescatarian,
      registerDate,
      room,
      vegan,
      vegetarian,
    } = nest(query)

    this.state = {
      filters: {
        allergies: allergies || '',
        childrenDueIn: !!registerDate,
        missingInfo: missingInfo || '',
        noAllergies: noAllergies || '',
        noPreference: noPreference || '',
        notSpecifiedAllergies: notSpecifiedAllergies || '',
        other: other || '',
        pescatarian: pescatarian || '',
        registerDate: registerDate || null,
        room: room || '',
        vegan: vegan || '',
        vegetarian: vegetarian || '',
      },
      isInitialDataLoading: true,
      showMissingInfoFilter: false,
    }
  }

  componentDidMount() {
    this.fetchOnComponentDidMount()
  }

  componentWillUnmount() {
    const { childActions } = this.props

    childActions.clear()
  }

  fetch = (applyStatsFilters = false) => {
    if (applyStatsFilters) {
      this.fetchDietaryStatistics(applyStatsFilters)
    }

    this.fetchChildren()
  }

  fetchOnComponentDidMount = () => {
    this.fetchDietaryStatistics(false, (response) => {
      this.setState({
        isInitialDataLoading: false,
        showMissingInfoFilter: 0 !== response.data.totalMissingInformation,
      }, () => {
        this.fetchDietaryStatistics()
        this.fetchChildren()
      })
    })
  }

  fetchDietaryStatistics = (applyFilters = true, cb) => {
    const { childSelectors, childStatisticsActions, paginationUtils } = this.props
    const { getCriteria } = childSelectors
    const { filters } = this.state

    const { page, perPage } = paginationUtils

    let criteriaFilters = {
      childrenDueIn: false,
      room: '',
    }

    if (applyFilters) {
      criteriaFilters = {
        childrenDueIn: filters.childrenDueIn,
        registerDate: filters.registerDate,
        room: filters.room,
      }
    }

    const criteria = getCriteria(criteriaFilters)
    const params = {
      criteria,
      groups: GROUPS,
      limit: perPage,
      page,
    }

    childStatisticsActions.getDietaryStatistics(params, cb)
  }

  fetchChildren = () => {
    const { childActions, childSelectors, paginationUtils, setLocationQuery } = this.props
    const { getCriteria } = childSelectors
    const { filters } = this.state

    const { page, perPage } = paginationUtils

    setLocationQuery(flatten(filters))

    const criteria = getCriteria(filters)
    const params = {
      criteria,
      groups: GROUPS,
      limit: perPage,
      page,
    }

    childActions.list({ params })
  }

  handleRoomChange = (room) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, room: room ? room.value : '' },
      }),
      () => onPageChange(() => this.fetch(true))(1),
    )
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleChildrenDueInChange = (childrenDueIn) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          childrenDueIn: childrenDueIn?.value || false,
          registerDate: childrenDueIn?.value ? moment().format(DATE_FORMAT) : null,
        },
      }),
      () => onPageChange(() => this.fetch(true))(1),
    )
  }

  handleRegisterDateChange = (registerDate) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          registerDate: registerDate ? moment(registerDate).format(DATE_FORMAT) : null,
        },
      }),
      () => onPageChange(() => this.fetch(true))(1),
    )
  }

  handleSendReport = () => {
    const { modalActions, modalConsts } = this.props
    const { filters } = this.state

    modalActions.show(modalConsts.TYPES.DIETARY_REPORT_EXPORT, { filters: { filters } })
  }

  handleNoPreferenceClick = () => {
    const { filters } = this.state
    const { noPreference } = filters
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          noPreference: !noPreference ? DIETARY_NO_PREFERENCE : '',
        },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handleVegetarianClick = () => {
    const { filters } = this.state
    const { vegetarian } = filters
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          vegetarian: !vegetarian ? DIETARY_VEGETARIAN : '',
        },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handleVeganClick = () => {
    const { filters } = this.state
    const { vegan } = filters
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          vegan: !vegan ? DIETARY_VEGAN : '',
        },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handleOtherClick = () => {
    const { filters } = this.state
    const { other } = filters
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          other: !other ? DIETARY_OTHER : '',
        },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handleAllergiesClick = () => {
    const { filters } = this.state
    const { allergies } = filters
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          allergies: !allergies ? ALLERGY_TYPES.HAS_ALLERGY : '',
        },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handleNoAllergiesClick = () => {
    const { filters } = this.state
    const { noAllergies } = filters
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          noAllergies: !noAllergies ? ALLERGY_TYPES.NO_ALLERGY : '',
        },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handleMissingInfoClick = () => {
    const { filters } = this.state
    const { missingInfo, notSpecifiedAllergies } = filters
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          missingInfo: !missingInfo ? true : '',
          notSpecifiedAllergies: !notSpecifiedAllergies ? ALLERGY_TYPES.NOT_SPECIFIED : '',
        },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handlePescatarianClick = () => {
    const { paginationUtils } = this.props
    const { filters } = this.state
    const { pescatarian } = filters
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          pescatarian: !pescatarian ? DIETARY_PESCATARIAN : '',
        },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  getColumns = () => {
    const { profileFlags } = this.props

    return _.filter(DIETARY_REPORT_LIST_COLUMNS, ({ requiredFlags }) => (
      !requiredFlags || (requiredFlags && _.every(requiredFlags, (flag) => profileFlags.includes(flag)))
    ))
  }

  render() {
    const {
      childAddGranted,
      childListState: { isFetching, meta },
      childrenList,
      dietaryReportExportGranted,
      paginationUtils,
      statistics,
    } = this.props
    const { filters, isInitialDataLoading, showMissingInfoFilter } = this.state
    const { getPageCount, page, perPage } = paginationUtils
    const {
      allergies,
      childrenDueIn,
      missingInfo,
      noAllergies,
      noPreference,
      other,
      pescatarian,
      registerDate,
      room,
      vegan,
      vegetarian,
    } = filters

    const isClicked = {
      allergies: !!allergies,
      missingInfo: !!missingInfo,
      noAllergies: !!noAllergies,
      noPreference: !!noPreference,
      other: !!other,
      pescatarian: !!pescatarian,
      vegan: !!vegan,
      vegetarian: !!vegetarian,
    }

    const pageCount = getPageCount(meta.total_results)
    const reports = getDietaryTableLists(childrenList, childAddGranted)

    return (
      <DietaryReportView
        childrenOptions={CHILDREN_OPTIONS}
        childrenValue={childrenDueIn}
        columns={this.getColumns()}
        dietaryReportExportGranted={dietaryReportExportGranted}
        isClicked={isClicked}
        isInitialDataLoading={isInitialDataLoading}
        isLoading={isFetching}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        registerDate={registerDate}
        reports={reports}
        room={room}
        showMissingInfoFilter={showMissingInfoFilter}
        statistics={statistics}
        totalResults={meta.total_results}
        onAllergiesClick={this.handleAllergiesClick}
        onChildrenDueInChange={this.handleChildrenDueInChange}
        onMissingInfoClick={this.handleMissingInfoClick}
        onNoAllergiesClick={this.handleNoAllergiesClick}
        onNoPreferenceClick={this.handleNoPreferenceClick}
        onOtherClick={this.handleOtherClick}
        onPageChange={this.handlePageChange}
        onPescatarianClick={this.handlePescatarianClick}
        onRegisterDateChange={this.handleRegisterDateChange}
        onRoomChange={this.handleRoomChange}
        onSendReport={this.handleSendReport}
        onVeganClick={this.handleVeganClick}
        onVegetarianClick={this.handleVegetarianClick}
      />
    )
  }
}

const mapState = (state, { childSelectors, childStatisticsSelectors }) => ({
  childAddGranted: auth.SELECTORS.getComponentIsAuthorised(state, ChildAdd),
  childrenList: childSelectors.getChildListDataSelector(state),
  dietaryReportExportGranted: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.LJ_REPORTS],
    roles: [
      ROLES.SUPER_ADMIN,
      ROLES.ORGANIZATION_NATIONAL_ADMIN,
      ROLES.ORGANIZATION_FINANCE_ADMIN,
      ROLES.ORGANIZATION_DIRECTOR,
      ROLES.ORGANIZATION_LINE_MANAGER,
      ROLES.DEPUTY_MANAGER,
      ROLES.NURSERY_MANAGER,
      ROLES.NURSERY_ADMIN,
    ],
  }),
  profileFlags: auth.SELECTORS.getAuthenticationProfileFeatureFlags(state),
  statistics: childStatisticsSelectors.getDietaryStatistics(state),
})

const enhance = compose(
  withRouter,
  withChildService,
  withChildStatisticsService,
  withPaginationUtils,
  withRouterUtils,
  withModalService,
  connect(mapState),
)

DietaryReportContainer.authParams = {
  flags: [FEATURE_FLAGS.LJ_REPORTS],
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.ROOM_LEADER,
    ROLES.SENIOR_TEACHER,
    ROLES.TEACHER,
  ],
}

export default enhance(DietaryReportContainer)
