import { Property } from 'csstype'

import React, { PropsWithChildren } from 'react'

import { Typography } from 'components'

import { StyledButton, StyledItem, StyledLabel, StyledValue } from './DetailsBreakdownStyled'

interface DetailsBreakdownItemProps {
  buttonComponent?: null | React.ReactNode
  label: string
  padding?: Property.Padding
  proportion?: Property.GridTemplateColumns
  value: React.ReactNode | string
  valueAlign?: Property.AlignSelf
  withButton?: boolean
  withoutBorder?: boolean
}

const DetailsBreakdownItem: React.FC<PropsWithChildren<DetailsBreakdownItemProps>> = ({
  buttonComponent = null,
  children,
  label,
  padding,
  proportion,
  value,
  valueAlign,
  withButton = false,
  withoutBorder,
}) => {
  if (children) {
    return (
      <StyledItem $center>
        {children}
      </StyledItem>
    )
  }

  return (
    <StyledItem $padding={padding} $proportion={proportion} $withoutBorder={withoutBorder}>
      <StyledLabel>
        <Typography bold>{label}</Typography>
      </StyledLabel>
      <StyledValue $valueAlign={valueAlign}>
        {value}
      </StyledValue>
      {withButton && (
        <StyledButton>
          {buttonComponent}
        </StyledButton>
      )}
    </StyledItem>
  )
}

export default DetailsBreakdownItem
