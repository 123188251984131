import _ from 'lodash'

import { mergeData } from 'utils/data'

import {
  CLEAR_ORGANIZATION_MEMBERSHIPS,
  GET_ORGANIZATION_MEMBERSHIPS,
  GET_ORGANIZATION_MEMBERSHIPS_FAILED,
  GET_ORGANIZATION_MEMBERSHIPS_SUCCESS,
} from './constants'
import { GET_ORGANIZATION_MEMBERSHIP_SUCCESS } from '../single/constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
}

export default (state = initialState, { addToList, error, mergeResult, payload, type }) => {
  switch (type) {
    case GET_ORGANIZATION_MEMBERSHIPS:
      return {
        ...initialState,
        error: null,
        isFetching: true,
      }
    case GET_ORGANIZATION_MEMBERSHIPS_SUCCESS: {
      return {
        ...initialState,
        data: _.uniqBy(mergeData(state.data, payload.data, mergeResult), ({ id }) => id),
        isFetching: false,
        meta: payload.meta,
      }
    }
    case GET_ORGANIZATION_MEMBERSHIPS_FAILED:
      return {
        ...initialState,
        error,
        isFetching: false,
      }
    case GET_ORGANIZATION_MEMBERSHIP_SUCCESS:
      if (addToList) {
        return {
          ...state,
          data: [
            payload?.data,
          ],
        }
      }

      return {
        ...state,
      }
    case CLEAR_ORGANIZATION_MEMBERSHIPS:
      return initialState
    default:
      return state
  }
}
