import styled from 'styled-components'

export const StyledSubContentWrapper = styled.div`
  ${({ $hasAccessToLikesAndComments }) => $hasAccessToLikesAndComments && `
    display: grid;
    grid-template-columns: auto 120px;
    grid-gap: 10px;
  `}
`

export const StyledLikesWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 20px;
  padding-top: 2px;
`
