import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class AuthApiClient extends ServiceBase {
  createPin = (payload, token) => this.request({
    body: payload,
    method: 'PUT',
    path: '/v2/me/pin',
    token,
  })

  me = (token, params = {}) => {
    const apiParams = {
      path: `/v2/me?${getQueryStringV2(params)}`,
      token,
    }

    if (!LOCAL_VERSION) {
      apiParams.credentials = 'include'
    }

    return this.request(apiParams)
  }

  pinLogin = (payload) => this.request({
    body: payload,
    method: 'POST',
    path: '/v2/auth/login/pin',
  })
}

export default AuthApiClient
