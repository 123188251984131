import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'
import { STATUS_TYPE } from '../constants'

const getNurseryIntegrationsActiveRecurringPaymentState = (
  state: RootState,
) => state.nurseryIntegrations.activeRecurringPayment

export const getActiveRecurringPaymentMeta = createSelector(
  [getNurseryIntegrationsActiveRecurringPaymentState],
  ({ meta }) => meta,
)

export const getActiveRecurringPayment = createSelector(
  [getNurseryIntegrationsActiveRecurringPaymentState],
  ({ data }) => data,
)

export const getActiveRecurringPaymentNextPageId = createSelector(
  [getActiveRecurringPaymentMeta],
  (meta) => meta.next_page,
)

export const getActiveRecurringPaymentHasMore = createSelector(
  [getActiveRecurringPaymentMeta],
  (meta) => meta.has_more,
)

export const getActiveRecurringPaymentCriteria = ({ nursery, paymentMethod, room, status }) => {
  const criteria = [{
    field: 'isArchived',
    value: '0',
  }]

  if (room) {
    criteria.push({
      field: 'nurseryClass',
      value: room?.value || room,
    })
  }

  if (paymentMethod) {
    criteria.push({
      field: 'paymentMethod',
      value: paymentMethod?.value || paymentMethod,
    })
  }

  if (nursery) {
    criteria.push({
      field: 'nursery',
      value: nursery?.value || nursery,
    })
  }

  if (undefined !== status && null !== status) {
    criteria.push({
      field: 'activated',
      value: (status?.value || status) === STATUS_TYPE.ACTIVE ? 1 : 0 as any,
    })
  }

  return criteria
}
