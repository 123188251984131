export const GET_ROOM = 'GET_ROOM'
export const GET_ROOM_SUCCESS = 'GET_ROOM_SUCCESS'
export const GET_ROOM_FAILED = 'GET_ROOM_FAILED'

export const CREATE_ROOM = 'CREATE_ROOM'
export const CREATE_ROOM_SUCCESS = 'CREATE_ROOM_SUCCESS'
export const CREATE_ROOM_FAILED = 'CREATE_ROOM_FAILED'

export const UPDATE_ROOM = 'UPDATE_ROOM'
export const UPDATE_ROOM_SUCCESS = 'UPDATE_ROOM_SUCCESS'
export const UPDATE_ROOM_FAILED = 'UPDATE_ROOM_FAILED'

export const CLEAR_ROOM = 'CLEAR_ROOM'
