import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as cohortTrackingSelectors from './selectors'

const withCohortTrackingService = (WrappedComponent) => {
  const mapState = ({ cohortTracking }) => ({ cohortTracking })

  const mapDispatch = injectActions('cohortTrackingActions', actions)

  const Component = ({ cohortTracking, cohortTrackingActions, ...others }) => (
    <WrappedComponent
      cohortTrackingActions={cohortTrackingActions}
      cohortTrackingComparisonReportSingleState={cohortTracking.comparisonReportSingle}
      cohortTrackingListState={cohortTracking.list}
      cohortTrackingProgressTrackerSingleState={cohortTracking.progressTrackerSingle}
      cohortTrackingSelectors={cohortTrackingSelectors}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withCohortTrackingService
