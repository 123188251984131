import _ from 'lodash'

import { createSelector } from 'reselect'

const getEthnicities = (state) => state.ethnicities

export const getEthnicitiesListSelector = createSelector(
  [getEthnicities],
  (state) => {
    if (!state) {
      return null
    }

    return state.list
  },
)

export const getEthnicitiesListDataSelector = createSelector(
  [getEthnicitiesListSelector],
  (response) => response && response.data,
)

export const getEthnicitiesListDataDropdownSelector = createSelector(
  [getEthnicitiesListDataSelector],
  (data) => _.map(data, ({ id, name }) => ({
    label: name,
    value: id,
  })),
)
