import _ from 'lodash'
import moment from 'moment'

import React, { useState } from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Icon, Typography } from 'components'

import { StyledMonth, StyledMonthGrid, StyledMonthRangeWrapper, StyledYearWrapper } from './DatePickerStyled'
import { isDateDisabled } from './DatePickerHelper'

const MONTH_DATE_FORMAT = 'YYYYMM'

interface DatePickerMonthProps {
  disabledDays: string[]
  numberOfYears: number
  onChangeMonth: () => void
  range: boolean
  value: string
}

const DatePickerMonth = ({
  disabledDays,
  numberOfYears = 1,
  onChangeMonth,
  range,
  value,
}): React.ReactElement<DatePickerMonthProps> => {
  const [localValue, setLocalValue] = useState(value)
  const [startYear, setStartYear] = useState(range ? value[0] : value)

  const handleYearChange = (years) => {
    setStartYear(moment(startYear).add('years', years))
  }

  const handleMonthChange = (selectedMonth) => {
    if (range) {
      const [from, to] = localValue

      if (from && to) {
        return setLocalValue([selectedMonth, null])
      }

      if (+from.format(MONTH_DATE_FORMAT) > selectedMonth.format(MONTH_DATE_FORMAT)) {
        return onChangeMonth([selectedMonth, from])
      }

      return onChangeMonth([from, selectedMonth])
    }

    return onChangeMonth(selectedMonth)
  }

  const renderNavigation = (lastHeader) => {
    if (!lastHeader) {
      return null
    }

    return (
      <React.Fragment>
        <Icon
          color={NEUTRAL_COLOURS.BASIC}
          cursor="pointer"
          height={14}
          icon="chevron-left"
          onClick={() => handleYearChange(-numberOfYears)}
        />
        <Icon
          color={NEUTRAL_COLOURS.BASIC}
          cursor="pointer"
          height={14}
          icon="chevron-right"
          onClick={() => handleYearChange(numberOfYears)}
        />
      </React.Fragment>
    )
  }

  const renderMonth = (index) => {
    const currentYearMoment = moment(startYear).add('years', index)

    return (
      <StyledMonthGrid>
        <StyledYearWrapper>
          <Typography fontSize={20} bold>
            {currentYearMoment.format('YYYY')}
          </Typography>
          {renderNavigation(numberOfYears === index + 1)}
        </StyledYearWrapper>
        {_.times(12, (i) => {
          const currentMonthMoment = moment().set({ month: i, year: currentYearMoment.year() })
          const disabled = isDateDisabled(disabledDays, currentMonthMoment.startOf('month'), 1)

          let monthIsSelected = false
          let isInBetween = false

          if (range) {
            monthIsSelected = (
              +moment(localValue[0]).format(MONTH_DATE_FORMAT) === +currentMonthMoment.format(MONTH_DATE_FORMAT)
            ) || +moment(localValue[1]).format(MONTH_DATE_FORMAT) === +currentMonthMoment.format(MONTH_DATE_FORMAT)
            isInBetween = (
              +currentMonthMoment.format(MONTH_DATE_FORMAT) > +moment(localValue[0]).format(MONTH_DATE_FORMAT)
            ) && +currentMonthMoment.format(MONTH_DATE_FORMAT) < +moment(localValue[1]).format(MONTH_DATE_FORMAT)
          } else {
            monthIsSelected = (
              +moment(localValue).format(MONTH_DATE_FORMAT) === +currentMonthMoment.format(MONTH_DATE_FORMAT)
            )
          }

          return (
            <StyledMonth
              $disabled={disabled}
              $inBetween={isInBetween}
              $selected={monthIsSelected}
              key={moment().set('month', i).format('MMMM')}
              onClick={() => !disabled && handleMonthChange(currentMonthMoment)}
            >
              {currentMonthMoment.format('MMMM')}
            </StyledMonth>
          )
        })}
      </StyledMonthGrid>
    )
  }

  return (
    <StyledMonthRangeWrapper>
      {_.times(numberOfYears, renderMonth)}
    </StyledMonthRangeWrapper>
  )
}

export default DatePickerMonth
