import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_NEXT_MONTESSORI_ACTIVITIES_SINGLE,
  CREATE_NEXT_MONTESSORI_ACTIVITIES,
  CREATE_NEXT_MONTESSORI_ACTIVITIES_FAILED,
  CREATE_NEXT_MONTESSORI_ACTIVITIES_SUCCESS,
  GET_NEXT_MONTESSORI_ACTIVITIES,
  GET_NEXT_MONTESSORI_ACTIVITIES_FAILED,
  GET_NEXT_MONTESSORI_ACTIVITIES_SUCCESS,
  UPDATE_NEXT_MONTESSORI_ACTIVITIES,
  UPDATE_NEXT_MONTESSORI_ACTIVITIES_FAILED,
  UPDATE_NEXT_MONTESSORI_ACTIVITIES_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_NEXT_MONTESSORI_ACTIVITIES_SINGLE,
    createFailed: CREATE_NEXT_MONTESSORI_ACTIVITIES_FAILED,
    createInit: CREATE_NEXT_MONTESSORI_ACTIVITIES,
    createSuccess: CREATE_NEXT_MONTESSORI_ACTIVITIES_SUCCESS,
    itemFailed: GET_NEXT_MONTESSORI_ACTIVITIES_FAILED,
    itemInit: GET_NEXT_MONTESSORI_ACTIVITIES,
    itemSuccess: GET_NEXT_MONTESSORI_ACTIVITIES_SUCCESS,
    updateFailed: UPDATE_NEXT_MONTESSORI_ACTIVITIES_FAILED,
    updateInit: UPDATE_NEXT_MONTESSORI_ACTIVITIES,
    updateSuccess: UPDATE_NEXT_MONTESSORI_ACTIVITIES_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
