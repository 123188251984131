import React from 'react'

import { Callout, ModalBox, Spinner } from 'components'

import i18n from 'translations'

import ExceptionalUnavailableTimesModalForm from './components/ExceptionalUnavailableTimesModalForm'

const ExceptionalUnavailableTimesModalView = ({
  errorMessages,
  isEditMode,
  isFetching,
  onCloseClick,
  ...rest
}) => {
  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    return (
      <ExceptionalUnavailableTimesModalForm
        onCloseClick={onCloseClick}
        {...rest}
      />
    )
  }

  return (
    <ModalBox
      title={isEditMode
        ? i18n.t('module:Modals:RequestableExtraSessions:ExceptionalUnavailableTimes:Title:edit')
        : i18n.t('module:Modals:RequestableExtraSessions:ExceptionalUnavailableTimes:Title:add')}
      onCloseClick={onCloseClick}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </ModalBox>
  )
}

export default ExceptionalUnavailableTimesModalView
