import React, { useState } from 'react'

const withChildFundingAllocation = (WrappedComponent) => (props) => {
  const [selectedSessionSet, setSelectedSessionSet] = useState(null)
  const [selectedWeekDays, setSelectedWeekDays] = useState(null)
  const [selectedTimeLine, setSelectedTimeLine] = useState(null)
  const [customErrorMessage, setCustomErrorMessage] = useState(null)

  return (
    <WrappedComponent
      {...props}
      customErrorMessage={customErrorMessage}
      selectedSessionSet={selectedSessionSet}
      selectedTimeLine={selectedTimeLine}
      selectedWeekDays={selectedWeekDays}
      setCustomErrorMessage={setCustomErrorMessage}
      setSelectedSessionSet={setSelectedSessionSet}
      setSelectedTimeLine={setSelectedTimeLine}
      setSelectedWeekDays={setSelectedWeekDays}
    />
  )
}

export default withChildFundingAllocation
