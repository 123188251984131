import React from 'react'
import { compose } from 'recompose'

import { withModalService } from 'services/utils/modal'
import { withSubdomainService } from 'services/subdomain'

import colors from 'constants/colors'

import { ModalBox, Typography } from 'components'

import i18n from 'translations'

import { properties } from 'app-config'

import {
  StyledAdditionalFeaturesTitle,
  StyledBoxContent,
  StyledBoxHeader,
  StyledContactInfo,
  StyledContainer,
  StyledFooter,
  StyledFooterTitle,
  StyledGrid,
  StyledList,
} from './PremiumPlanModalStyled'

const PremiumPlanModal = ({ hideModal }) => (
  <ModalBox
    title={i18n.t('module:Modals:PremiumPlanModal:headerTitle')}
    onCloseClick={hideModal}
  >
    <StyledContainer>
      <Typography align="left" margin="0 0 32px 0" variant="h1">
        {i18n.t('module:Modals:PremiumPlanModal:title')}
      </Typography>
      <StyledAdditionalFeaturesTitle>
        <Typography align="left" color={colors.gray} fontSize="18" margin="0 0 12px 3px" variant="h2">
          {i18n.t('module:Modals:PremiumPlanModal:additionalFeatures')}
        </Typography>
      </StyledAdditionalFeaturesTitle>
      <StyledGrid>
        <div>
          <StyledBoxHeader $primary>
            <Typography fontSize="22" variant="h3">
              {i18n.t('module:Modals:PremiumPlanModal:occupancy:title')}
            </Typography>
          </StyledBoxHeader>
          <StyledBoxContent>
            <StyledList $primary>
              <li>
                {i18n.t('module:Modals:PremiumPlanModal:occupancy:weeklyOccupancyReports')}
              </li>
              <li>
                {i18n.t('module:Modals:PremiumPlanModal:occupancy:ftePercentages')}
              </li>
              <li>
                {i18n.t('module:Modals:PremiumPlanModal:occupancy:occupancyForecast')}
              </li>
              <li>
                {i18n.t('module:Modals:PremiumPlanModal:occupancy:nurserySpacesPlanner')}
              </li>
              <li>
                {i18n.t('module:Modals:PremiumPlanModal:occupancy:roomMoves')}
              </li>
            </StyledList>
          </StyledBoxContent>
        </div>
        <div>
          <StyledBoxHeader $primary>
            <Typography fontSize="22" variant="h3">
              {i18n.t('module:Modals:PremiumPlanModal:finance:title')}
            </Typography>
          </StyledBoxHeader>
          <StyledBoxContent>
            <StyledList $primary>
              <li>
                {i18n.t('module:Modals:PremiumPlanModal:finance:bespokeInvoiceSettings')}
              </li>
              <li>
                {i18n.t('module:Modals:PremiumPlanModal:finance:generateAndSendInvoices')}
              </li>
              <li>
                {i18n.t('module:Modals:PremiumPlanModal:finance:fundingGrants')}
              </li>
              <li>
                {i18n.t('module:Modals:PremiumPlanModal:finance:reportingAndForecasting')}
              </li>
              <li>
                {i18n.t('module:Modals:PremiumPlanModal:finance:monitorTransactions')}
              </li>
              <li>
                {i18n.t('module:Modals:PremiumPlanModal:finance:detailedAccounts')}
              </li>
            </StyledList>
          </StyledBoxContent>
        </div>
      </StyledGrid>
      <StyledFooter>
        <StyledGrid>
          <StyledFooterTitle>
            <Typography fontSize="26" variant="h2">
              {i18n.t('module:Modals:PremiumPlanModal:contact:title')}
            </Typography>
            <Typography fontSize="18">
              {i18n.t('module:Modals:PremiumPlanModal:contact:subtitle')}
            </Typography>
          </StyledFooterTitle>
          <StyledContactInfo>
            <Typography bold>
              {`${i18n.t('module:Modals:PremiumPlanModal:contact:phoneLabel')}: `}
            </Typography>
            <Typography>
              <a href={`tel:${properties.helpDeskNumber}`}>{`${properties.helpDeskNumber}, Opt. 1`}</a>
            </Typography>
            <Typography bold>
              {`${i18n.t('module:Modals:PremiumPlanModal:contact:emailLabel')}: `}
            </Typography>
            <Typography>
              <a href={`mailto:${properties.supportEmail}`}>
                {properties.supportEmail}
              </a>
            </Typography>
          </StyledContactInfo>
        </StyledGrid>
      </StyledFooter>
    </StyledContainer>
  </ModalBox>
)

const enhance = compose(
  withSubdomainService,
  withModalService,
)

export default enhance(PremiumPlanModal)
