import { compose } from 'recompose'

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { ROLES } from 'constants/security'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import { withShellService } from 'services/shell'
import { withRouter } from 'services/router'

import i18n from 'translations'

import SettingsSessionsWrapper from 'module/Settings/SettingsSessions/SettingsSessionsWrapper'
import SettingsInvoicesWrapper from 'module/Settings/SettingsInvoices/SettingsInvoicesWrapper'
import SettingsInvoiceNumbersWrapper from 'module/Settings/SettingsInvoiceNumbers/SettingsInvoiceNumbersWrapper'
import SettingsSecurityWrapper from 'module/Settings/Security/SecurityWrapper'

import SettingsWrapperView from './SettingsWrapperView'
// import NewFinance from './NewFinance'

class SettingsWrapperContainer extends Component {
  componentDidMount() {
    const {
      FinanceV3Enabled,
      InvoiceNumberEnabled,
      InvoicesEnabled,
      SecurityEnabled,
      SessionsEnabled,
      shellActions,
    } = this.props

    shellActions.setSubMenu([
      {
        icon: 'nursery',
        label: 'Nursery',
        to: '/settings/nursery',
      },
      {
        hidden: !SessionsEnabled,
        icon: 'sessions',
        label: 'Sessions',
        to: '/settings/sessions',
      },
      {
        hidden: !InvoicesEnabled,
        icon: 'invoices',
        label: 'Invoices',
        to: '/settings/invoices',
      },
      {
        hidden: !InvoiceNumberEnabled,
        icon: 'invoices',
        label: 'Invoice numbers',
        to: '/settings/invoice-numbers',
      },
      {
        hidden: !SecurityEnabled,
        icon: 'security',
        label: i18n.t('module:Settings:Security:title'),
        to: generateRoute('SETTINGS.SECURITY.INDEX'),
      },
      {
        hidden: !FinanceV3Enabled,
        icon: 'security',
        label: i18n.t('module:Management:Finance:NewFinance:subMenuTitle'),
        to: generateRoute('SETTINGS.NEW_FINANCE'),
      },
    ])
  }

  shouldComponentUpdate(nextProps) {
    const { location: { pathname }, navigate } = nextProps

    if (generateRoute('SETTINGS.INDEX') === pathname) {
      navigate(generateRoute('SETTINGS.NURSERY'))
    }

    return true
  }

  render() {
    const { children } = this.props

    return (
      <SettingsWrapperView>
        {children}
      </SettingsWrapperView>
    )
  }
}

SettingsWrapperContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
  ],
}

const mapState = (state) => ({
  // NOTE: This is to hide new finance toggle for MVP version
  FinanceV3Enabled: false,
  // FinanceV3Enabled: auth.SELECTORS.getComponentIsAuthorised(state, NewFinance),
  InvoiceNumberEnabled: auth.SELECTORS.getComponentIsAuthorised(state, SettingsInvoiceNumbersWrapper),
  InvoicesEnabled: auth.SELECTORS.getComponentIsAuthorised(state, SettingsInvoicesWrapper),
  SecurityEnabled: auth.SELECTORS.getComponentIsAuthorised(state, SettingsSecurityWrapper),
  SessionsEnabled: auth.SELECTORS.getComponentIsAuthorised(state, SettingsSessionsWrapper),
})

const enhance = compose(
  withShellService,
  withRouter,
  connect(mapState),
)

export default enhance(SettingsWrapperContainer)
