export const REQUEST_EXTRA_SESSION_GROUPS = {
  read: [
    'carer',
    'child',
    'childExtraSession',
    'nurseryClass',
    'nurserySession',
    'requestExtraSession.child',
    'requestExtraSession.childExtraSession',
    'requestExtraSession.logs',
    'requestExtraSession.nurseryClass',
    'requestExtraSession.nurserySession',
    'requestExtraSession.nurserySessionSnapshot',
    'requestExtraSession.requestedBy',
    'requestExtraSession.statistics',
    'requestExtraSessionLog',
  ],
}

export const REQUEST_EXTRA_SESSION_GROUPS_V3 = {
  read: [
    'carer',
    'child',
    'extraSessionChildProduct',
    'nurseryClass',
    'nurserySessionProduct',
    'requestExtraSession.child',
    'requestExtraSession.extraSessionChildProduct',
    'requestExtraSession.logs',
    'requestExtraSession.nurseryClass',
    'requestExtraSession.nurserySessionProduct',
    'requestExtraSession.nurserySessionProductSnapshot',
    'requestExtraSession.requestedBy',
    'requestExtraSession.statistics',
    'requestExtraSessionLog',
  ],
}

