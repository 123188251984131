import React from 'react'

import { LIST_STATUS_FILTERS_DROPDOWN } from 'constants/filters'

import { generateRoute } from 'utils/routing'

import { Callout, EmptyState, Page, Pagination, Section, Select, Spinner, Table, Toolbar } from 'components'

import i18n from 'translations'

const COLUMNS = [
  {
    align: 'left',
    field: 'name',
    title: i18n.t('module:Management:LeaveTypes:Child:absenceReason'),
  },
  {
    field: 'rowToButton',
  },
]

const ChildAbsenceReasonsView = ({
  data,
  errorMessages,
  isFetching,
  onPageChange,
  onStatusChange,
  page,
  pageCount,
  perPage,
  status,
  totalResults,
}) => {
  const renderPagination = () => (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('global:pagination:entries')}
      titleSingular={i18n.t('global:pagination:entry')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderToolbar = () => (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <Select
            clearable={false}
            options={LIST_STATUS_FILTERS_DROPDOWN}
            searchable={false}
            value={status}
            onChange={onStatusChange}
          />
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )

  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    if (!data?.length) {
      return (
        <EmptyState
          icon="children"
          text1={i18n.t('global:EmptyState:long')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={COLUMNS}
          data={data}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const actions = (
    <Section.Actions
      primary={[{
        to: generateRoute('MANAGEMENT.LEAVE_TYPES.CHILD_ABSENCE_REASONS.ADD'),
      }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isFetching}
      title={i18n.t('module:Management:LeaveTypes:Child:title')}
    >
      <Callout content={errorMessages} error />
      {renderToolbar()}
      {renderContent()}
    </Page.Section>
  )
}

export default ChildAbsenceReasonsView
