import _ from 'lodash'

import React from 'react'

import PlanView from 'module/Finance/components/PlanView'

import i18n from 'translations'

const MAX_VISIBLE_DISCOUNTS = 2

const FinanceCurrentPlanDiscounts = ({ childDiscounts }) => {
  if (!childDiscounts || !childDiscounts.length) {
    return (
      <PlanView>
        <PlanView.Icon icon="discount" />
        <PlanView.Content>
          <PlanView.SubTitle
            subtitle={i18n.t('module:Children:Child:Finance:PlanView:Summary:discountEmptyState')}
          />
        </PlanView.Content>
      </PlanView>
    )
  }

  const moreDiscountCount = childDiscounts.length - MAX_VISIBLE_DISCOUNTS
  const visibleDiscounts = childDiscounts.slice(0, MAX_VISIBLE_DISCOUNTS)

  const renderDiscount = ({ id, description: customDescription, nurseryDiscount = {} }) => {
    const { description } = nurseryDiscount

    return (
      <PlanView.Item key={id} wrapText>
        {customDescription || description}
      </PlanView.Item>
    )
  }

  const renderMoreDiscounts = () => {
    if (0 >= moreDiscountCount) {
      return null
    }

    return (
      <PlanView.Item>
        <PlanView.Highlight>
          {i18n.t('module:Children:Child:Finance:PlanView:Summary:more', { count: moreDiscountCount })}
          &nbsp;
        </PlanView.Highlight>
        {i18n.t('module:Children:Child:Finance:PlanView:Summary:discount')}
      </PlanView.Item>
    )
  }

  return (
    <PlanView>
      <PlanView.Icon icon="discount" />
      <PlanView.Content>
        {_.map(visibleDiscounts, renderDiscount)}
        {renderMoreDiscounts()}
      </PlanView.Content>
    </PlanView>
  )
}

export default FinanceCurrentPlanDiscounts
