import { generateBasicActions } from 'utils/service'

import {
  UPDATE_BATCH_REGULAR_UNAVAILABLE_TIMES,
  UPDATE_BATCH_REGULAR_UNAVAILABLE_TIMES_FAILED,
  UPDATE_BATCH_REGULAR_UNAVAILABLE_TIMES_SUCCESS,
} from './constants'

export const updateBatchRegularUnavailableTimes = (options = {}) => ({ requestedExtraSessionsApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_BATCH_REGULAR_UNAVAILABLE_TIMES_FAILED,
      init: UPDATE_BATCH_REGULAR_UNAVAILABLE_TIMES,
      success: UPDATE_BATCH_REGULAR_UNAVAILABLE_TIMES_SUCCESS,
    },
    options,
    service: requestedExtraSessionsApiClient,
    serviceMethod: 'updateBatchRegularUnavailableTimes',
  })
)
