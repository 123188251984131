import { REFERENCE_ASYNC_PAGE_TYPES } from 'services/legacy/upload/constants'

export const getMembershipFileValuesForm = (values) => {
  const { membership, mimeType, name, size, url } = values
  const body = {
    type: REFERENCE_ASYNC_PAGE_TYPES.MEMBERSHIP,
  }

  if (name) {
    body.name = name
  }

  if (membership) {
    body.membership = {
      id: membership,
    }
  }

  if (mimeType || url) {
    body.file = {
      mimeType,
      size,
      url,
    }
  }

  return body
}
