import {
  CLEAR_MEMBERSHIP_SHIFT,
  CREATE_MEMBERSHIP_SHIFT,
  CREATE_MEMBERSHIP_SHIFT_FAILED,
  CREATE_MEMBERSHIP_SHIFT_SUCCESS,
  REMOVE_MEMBERSHIP_SHIFT,
  REMOVE_MEMBERSHIP_SHIFT_FAILED,
  REMOVE_MEMBERSHIP_SHIFT_SUCCESS,
  UPDATE_MEMBERSHIP_SHIFT,
  UPDATE_MEMBERSHIP_SHIFT_FAILED,
  UPDATE_MEMBERSHIP_SHIFT_SUCCESS,
} from './constants'

export const create = (options = {}) => ({ membershipsShiftsApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params, payload = {} } = options
  dispatch({ type: CREATE_MEMBERSHIP_SHIFT })

  return membershipsShiftsApiClient.create(payload, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: CREATE_MEMBERSHIP_SHIFT_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: CREATE_MEMBERSHIP_SHIFT_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const update = (id, options = {}) => ({ membershipsShiftsApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params, payload = {} } = options
  dispatch({ type: UPDATE_MEMBERSHIP_SHIFT })

  return membershipsShiftsApiClient.update(id, payload, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: UPDATE_MEMBERSHIP_SHIFT_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: UPDATE_MEMBERSHIP_SHIFT_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const remove = (id, options = {}) => ({ membershipsShiftsApiClient }) => (dispatch) => {
  const { onFailed, onSuccess } = options
  dispatch({ type: REMOVE_MEMBERSHIP_SHIFT })

  return membershipsShiftsApiClient.remove(id).then(
    (response) => {
      dispatch({
        payload: response,
        type: REMOVE_MEMBERSHIP_SHIFT_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: REMOVE_MEMBERSHIP_SHIFT_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const clearSingle = () => ({
  type: CLEAR_MEMBERSHIP_SHIFT,
})
