import {
  CLEAR_CREDIT_NOTES_STATISTICS,
  CREDIT_NOTES_STATISTICS,
  CREDIT_NOTES_STATISTICS_FAILED,
  CREDIT_NOTES_STATISTICS_SUCCESS,
} from './constants'

export const getStatistics = (params) => ({ creditNotesApiClient }) => (dispatch) => {
  dispatch({
    params,
    type: CREDIT_NOTES_STATISTICS,
  })

  creditNotesApiClient.statistics(params).then(
    (data) => {
      dispatch({
        payload: data,
        type: CREDIT_NOTES_STATISTICS_SUCCESS,
      })
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: CREDIT_NOTES_STATISTICS_FAILED,
      })
    },
  )
}

export const clearChildFundingStatistics = () => ({
  type: CLEAR_CREDIT_NOTES_STATISTICS,
})
