import { generateBasicActions } from 'utils/service'

import {
  CLEAR_LIST_OF_PROGRESS_HISTORY,
  LIST_OF_PROGRESS_HISTORY,
  LIST_OF_PROGRESS_HISTORY_FAILED,
  LIST_OF_PROGRESS_HISTORY_SUCCESS,
} from './constants'

export const getProgressHistory = (options = {}) => ({ childFrameworkProgressApiClient }) => (
  generateBasicActions.list({
    constants: {
      failed: LIST_OF_PROGRESS_HISTORY_FAILED,
      init: LIST_OF_PROGRESS_HISTORY,
      success: LIST_OF_PROGRESS_HISTORY_SUCCESS,
    },
    options,
    service: childFrameworkProgressApiClient,
    serviceMethod: 'getProgressHistory',
  })
)

export const clearProgressHistory = () => ({
  type: CLEAR_LIST_OF_PROGRESS_HISTORY,
})
