import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Typography } from 'components'
import InlineEditableField from 'module/Management/components/InlineEditableField'

import i18n from 'translations'

import UseOnlySecureToMarkProgressForm, {
  USE_ONLY_SECURE_TO_MARK_PROGRESS_FORM,
} from './UseOnlySecureToMarkProgressForm'

const UseOnlySecureToMarkProgress = ({ onSubmit, value }) => {
  const initialValues = { useOnlySecureToMarkProgress: value }

  return (
    <InlineEditableField
      editableComponent={(callback) => (
        <UseOnlySecureToMarkProgressForm
          initialValues={initialValues}
          onSubmit={callback}
        />
      )}
      formName={USE_ONLY_SECURE_TO_MARK_PROGRESS_FORM}
      title={(
        <React.Fragment>
          <Typography bold>
            {i18n.t('module:Management:General:LearningTrackingReporting:UseOnlySecureToMarkProgress:title')}
          </Typography>
          <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={15} margin="5px 0 0">
            {i18n.t('module:Management:General:LearningTrackingReporting:UseOnlySecureToMarkProgress:subTitle')}
          </Typography>
        </React.Fragment>
      )}
      value={value}
      onSubmit={onSubmit}
    />
  )
}

export default UseOnlySecureToMarkProgress
