export const GET_PERIOD_TIME = 'PERIODS/GET_PERIOD_TIME'
export const GET_PERIOD_TIME_FAILED = 'PERIODS/GET_PERIOD_TIME_FAILED'
export const GET_PERIOD_TIME_SUCCESS = 'PERIODS/GET_PERIOD_TIME_SUCCESS'

export const CREATE_PERIOD_TIME = 'PERIODS/CREATE_PERIOD_TIME'
export const CREATE_PERIOD_TIME_FAILED = 'PERIODS/CREATE_PERIOD_TIME_FAILED'
export const CREATE_PERIOD_TIME_SUCCESS = 'PERIODS/CREATE_PERIOD_TIME_SUCCESS'

export const UPDATE_PERIOD_TIME = 'PERIODS/UPDATE_PERIOD_TIME'
export const UPDATE_PERIOD_TIME_FAILED = 'PERIODS/UPDATE_PERIOD_TIME_FAILED'
export const UPDATE_PERIOD_TIME_SUCCESS = 'PERIODS/UPDATE_PERIOD_TIME_SUCCESS'

export const REMOVE_PERIOD_TIME = 'PERIODS/REMOVE_PERIOD_TIME'
export const REMOVE_PERIOD_TIME_FAILED = 'PERIODS/REMOVE_PERIOD_TIME_FAILED'
export const REMOVE_PERIOD_TIME_SUCCESS = 'PERIODS/REMOVE_PERIOD_TIME_SUCCESS'

export const CLEAR_PERIOD_TIME = 'PERIODS/CLEAR_PERIOD_TIME'
