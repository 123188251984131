import _ from 'lodash'
import { v4 } from 'uuid'

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IntegrationFeeModel } from 'services/integrationFees/constants'
import { noop } from 'constants/global'

import { usePrevious } from 'utils/hooks'

import { withAppService } from 'services/app'
import { withIntegrationFeesService, withIntegrationFeesServiceProps } from 'services/integrationFees'
import { withSnackbarService, withSnackbarServiceProps } from 'services/utils/snackbar'

import { Button, Field, Section, Space, Spinner, Typography } from 'components'

import i18n from 'translations'

import { CUSTOM_FEE_FORM } from './components/FeeItemForm/FeeItemForm'
import FeeItemForm from './components/FeeItemForm'
import { StyledColumnWrapper, StyledContent, StyledHeader, StyledWrapper } from './FeesSectionStyled'

interface FeesSectionProps {
  customFees: IntegrationFeeModel[]
  defaultFees: IntegrationFeeModel[]
  isFetching: boolean
  nursery: any
}

type FeesSectionFinalProps = withIntegrationFeesServiceProps
  & withSnackbarServiceProps
  & FeesSectionProps

const FeesSection: React.FC<FeesSectionFinalProps> = ({
  customFees,
  defaultFees,
  integrationFeesActions,
  integrationFeesSelectors,
  isFetching,
  nursery,
  snackbarActions,
}) => {
  const [customFeesState, setCustomFeesState] = useState<IntegrationFeeModel[]>(customFees)
  const prevState = usePrevious({ isFetching })

  useEffect(() => {
    // @ts-ignore
    if (prevState?.isFetching && !isFetching) {
      setCustomFeesState(customFees)
    }
  }, [customFees])

  useEffect(() => {
    if (nursery?.id) {
      const criteria = integrationFeesSelectors.getIntegrationFeesListCriteria({
        nursery: nursery?.id,
      })

      integrationFeesActions.getIntegrationFees({
        params: { criteria },
      })
    }

    return () => {
      if (nursery?.id) {
        integrationFeesActions.clearIntegrationFees()
      }
    }
  }, [nursery])

  const renderTableHeader = () => (
    <StyledHeader>
      <Typography fontSize={15} bold>
        {i18n.t('module:Nurseries:FeesSection:paymentType')}
      </Typography>
      <Typography fontSize={15} bold>
        {i18n.t('module:Nurseries:FeesSection:minAmount')}
      </Typography>
      <Typography fontSize={15} bold>
        {i18n.t('module:Nurseries:FeesSection:maxAmount')}
      </Typography>
      <Typography fontSize={15} bold>
        {i18n.t('module:Nurseries:FeesSection:percentage')}
      </Typography>
      <Typography fontSize={15} bold>
        {i18n.t('module:Nurseries:FeesSection:cap')}
      </Typography>
    </StyledHeader>
  )

  const onAddNewCustomFee = () => {
    setCustomFeesState([
      ...customFeesState,
      {
        id: v4(),
        isDraft: true,
      },
    ])
  }

  const handleCloseDraft = (fee: IntegrationFeeModel) => {
    setCustomFeesState(
      _.filter(customFeesState, (item: IntegrationFeeModel) => (
        item.id !== fee.id
      )),
    )
  }

  const renderDefaultTable = () => {
    if (!defaultFees.length) {
      return (
        <Typography>
          {i18n.t('module:Nurseries:FeesSection:notFoundDefaultFees')}
        </Typography>
      )
    }

    return (
      <React.Fragment>
        {renderTableHeader()}
        {_.map(defaultFees, (fee: IntegrationFeeModel) => (
          <Field.InlineEdit
            description={(
              <StyledColumnWrapper>
                <div>
                  {fee.minAmount || '-'}
                </div>
                <div>
                  {fee.maxAmount || '-'}
                </div>
                <div>
                  {`${fee.percentage}%` || '-'}
                </div>
                <div>
                  {fee.extraAmount || '-'}
                </div>
              </StyledColumnWrapper>
            )}
            key={fee.id}
            label={(
              <Typography fontSize={15} bold>
                {i18n.t(`module:Nurseries:FeesSection:paymentTypes:${fee.paymentType}`)}
              </Typography>
            )}
          />
        ))}
      </React.Fragment>
    )
  }

  const handleDeleteFeeSuccess = (feeToDelete: IntegrationFeeModel) => {
    snackbarActions.show({
      message: i18n.t('module:Nurseries:FeesSection:newFeeWasRemoved'),
    })

    setCustomFeesState(
      _.filter(customFeesState, (item: IntegrationFeeModel) => item.id !== feeToDelete.id),
    )
  }

  const handleUpdateFeeSuccess = (response, fee: IntegrationFeeModel, onSuccess) => {
    snackbarActions.show({
      message: i18n.t('module:Nurseries:FeesSection:updateFeeSuccess'),
    })

    setCustomFeesState(
      _.map(customFeesState, (item: IntegrationFeeModel) => {
        if (item.id === fee.id) {
          return response.data
        }

        return item
      }),
    )

    onSuccess()
  }

  const handleCreateFeeSuccess = (response, oldFee: IntegrationFeeModel, onSuccess) => {
    snackbarActions.show({
      message: i18n.t('module:Nurseries:FeesSection:newFeeWasCreated'),
    })

    setCustomFeesState(
      [
        ..._.filter(customFeesState, (item: IntegrationFeeModel) => item.id !== oldFee.id),
        {
          ...response.data,
        },
      ],
    )

    onSuccess()
  }

  const renderCustomTable = () => {
    if (!customFeesState.length) {
      return (
        <React.Fragment>
          <Typography>
            {i18n.t('module:Nurseries:FeesSection:notFoundCustomFees')}
          </Typography>
          <Space space="10px" />
          <Button.ActionButton
            label={i18n.t('module:Nurseries:FeesSection:addCustomFee')}
            onClick={onAddNewCustomFee}
          />
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        {renderTableHeader()}
        {_.map(customFeesState, (fee: IntegrationFeeModel) => (
          <FeeItemForm
            // @ts-ignore
            defaultFees={defaultFees}
            fee={fee}
            form={`${CUSTOM_FEE_FORM}_${fee.id}`}
            initialValues={integrationFeesSelectors.getInitialValues(fee)}
            key={fee.id}
            nursery={nursery}
            onCloseDraft={handleCloseDraft}
            onCreateFeeSuccess={handleCreateFeeSuccess}
            onDeleteFeeSuccess={handleDeleteFeeSuccess}
            onSubmit={noop}
            onUpdateFeeSuccess={handleUpdateFeeSuccess}
          />
        ))}
        <Button.ActionButton
          label={i18n.t('module:Nurseries:FeesSection:addCustomFee')}
          onClick={onAddNewCustomFee}
        />
      </React.Fragment>
    )
  }

  return (
    <StyledWrapper>
      <StyledContent>
        <Space margin="50px" />
        <Section
          title={i18n.t('module:Nurseries:FeesSection:defaultFees')}
          variant="small"
        >
          {isFetching ? <Spinner /> : renderDefaultTable()}
        </Section>
        <Space margin="40px" />
        <Section
          title={i18n.t('module:Nurseries:FeesSection:customFees')}
          variant="small"
        >
          {isFetching ? <Spinner /> : renderCustomTable()}
        </Section>
      </StyledContent>
    </StyledWrapper>
  )
}

const mapState = (state, { appSelectors, integrationFeesListState, integrationFeesSelectors }) => ({
  customFees: integrationFeesSelectors.getCustomIntegrationFees(state),
  defaultFees: integrationFeesSelectors.getDefaultIntegrationFees(state),
  isFetching: appSelectors.getIsFetching(integrationFeesListState),
})

const enhance = compose(
  withAppService,
  withIntegrationFeesService,
  withSnackbarService,
  connect(mapState),
)

export default enhance(FeesSection)
