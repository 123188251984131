import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class AbsenceReasonsApiClient extends ServiceBase {
  list = (params = {}) => this.request({
    path: `/v2/absence_reasons?${getQueryStringV2(params)}`,
    turnOnInOfflineMode: true,
  })

  get = (id, params = {}) => this.request({
    path: `/v2/absence_reasons/${id}?${getQueryStringV2(params)}`,
  })

  create = (params = {}, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/absence_reasons?${getQueryStringV2(params)}`,
  })

  update = (id, params = {}, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/absence_reasons/${id}?${getQueryStringV2(params)}`,
  })
}

export default AbsenceReasonsApiClient
