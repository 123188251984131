import React from 'react'

import { Callout, Page, Spinner } from 'components'

import {
  DevelopmentInformationForm,
  EnrolmentDetailsForm,
  FamilyInformationForm,
  GeneralInformationForm,
  HealthAndDietInformationForm,
  LikesDislikesInformationForm,
  PermissionsInformationForm,
} from '../components/ChildForms'

import { CHILD_EDIT_FORM_TYPE } from '../constants'

import { getTitle } from './helpers'

const ChildEditView = ({
  childId,
  drinkingMethodsDropdownOptions,
  errorMessages,
  ethnicitiesOptions,
  initialValues,
  isChildArchived,
  isLoading,
  isSubmitting,
  languagesOptions,
  maritalStatusDropdownOptions,
  onArchiveClick,
  onSubmit,
  permissionToArchive,
  toiletTrainingDropdownOptions,
  type,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    switch (type) {
      case CHILD_EDIT_FORM_TYPE.GENERAL_INFO:
        return (
          <GeneralInformationForm
            childId={childId}
            ethnicitiesOptions={ethnicitiesOptions}
            initialValues={initialValues}
            isChildArchived={isChildArchived}
            isSubmitting={isSubmitting}
            permissionToArchive={permissionToArchive}
            isEditMode
            onArchiveClick={onArchiveClick}
            onSubmit={onSubmit}
          />
        )
      case CHILD_EDIT_FORM_TYPE.FAMILY_INFO:
        return (
          <FamilyInformationForm
            childId={childId}
            initialValues={initialValues}
            isChildArchived={isChildArchived}
            isSubmitting={isSubmitting}
            languagesOptions={languagesOptions}
            maritalStatusDropdownOptions={maritalStatusDropdownOptions}
            permissionToArchive={permissionToArchive}
            isEditMode
            onArchiveClick={onArchiveClick}
            onSubmit={onSubmit}
          />
        )
      case CHILD_EDIT_FORM_TYPE.LIKES_DISLIKES_INFO:
        return (
          <LikesDislikesInformationForm
            childId={childId}
            initialValues={initialValues}
            isChildArchived={isChildArchived}
            isSubmitting={isSubmitting}
            languagesOptions={languagesOptions}
            maritalStatusDropdownOptions={maritalStatusDropdownOptions}
            permissionToArchive={permissionToArchive}
            isEditMode
            onArchiveClick={onArchiveClick}
            onSubmit={onSubmit}
          />
        )
      case CHILD_EDIT_FORM_TYPE.ENROLMENT_DETAILS:
        return (
          <EnrolmentDetailsForm
            childId={childId}
            initialValues={initialValues}
            isChildArchived={isChildArchived}
            isSubmitting={isSubmitting}
            permissionToArchive={permissionToArchive}
            isEditMode
            onArchiveClick={onArchiveClick}
            onSubmit={onSubmit}
          />
        )
      case CHILD_EDIT_FORM_TYPE.DEVELOPMENT_INFO:
        return (
          <DevelopmentInformationForm
            childId={childId}
            drinkingMethodsDropdownOptions={drinkingMethodsDropdownOptions}
            initialValues={initialValues}
            isChildArchived={isChildArchived}
            isSubmitting={isSubmitting}
            permissionToArchive={permissionToArchive}
            toiletTrainingDropdownOptions={toiletTrainingDropdownOptions}
            isEditMode
            onArchiveClick={onArchiveClick}
            onSubmit={onSubmit}
          />
        )
      case CHILD_EDIT_FORM_TYPE.HEALTH_AND_DIET_INFO:
        return (
          <HealthAndDietInformationForm
            childId={childId}
            initialValues={initialValues}
            isChildArchived={isChildArchived}
            isSubmitting={isSubmitting}
            permissionToArchive={permissionToArchive}
            isEditMode
            onArchiveClick={onArchiveClick}
            onSubmit={onSubmit}
          />
        )
      case CHILD_EDIT_FORM_TYPE.PERMISSIONS_INFO:
        return (
          <PermissionsInformationForm
            childId={childId}
            initialValues={initialValues}
            isChildArchived={isChildArchived}
            isSubmitting={isSubmitting}
            permissionToArchive={permissionToArchive}
            isEditMode
            onArchiveClick={onArchiveClick}
            onSubmit={onSubmit}
          />
        )

      default:
        return null
    }
  }

  return (
    <Page.Section
      isLoading={isLoading}
      title={getTitle(type)}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default ChildEditView
