import { GET_ROTA_STATISTICS, GET_ROTA_STATISTICS_FAILED, GET_ROTA_STATISTICS_SUCCESS } from './constants'

export const getRotaStatistics = (options = {}) => ({ membershipsShiftsApiClient }) => async (dispatch) => {
  const { onFailed, onSuccess, onlyData, params } = options

  if (!onlyData) {
    dispatch({ type: GET_ROTA_STATISTICS })
  }

  return membershipsShiftsApiClient.getRotaStatistics(params).then(
    (response) => {
      if (!onlyData) {
        dispatch({
          payload: response,
          type: GET_ROTA_STATISTICS_SUCCESS,
        })
      }

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      if (!onlyData) {
        dispatch({
          error: response,
          type: GET_ROTA_STATISTICS_FAILED,
        })
      }

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}
