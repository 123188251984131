import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  GET_NURSERY_SETTINGS,
  GET_NURSERY_SETTINGS_FAILED,
  GET_NURSERY_SETTINGS_SUCCESS,
  REMOVE_STRIPE_INTEGRATION,
} from './constants'

export const getSettings = (options: BasicActionOptionsProps) => ({
  nurseriesApiClient,
}) => generateBasicActions.get({
  constants: {
    failed: GET_NURSERY_SETTINGS_FAILED,
    init: GET_NURSERY_SETTINGS,
    success: GET_NURSERY_SETTINGS_SUCCESS,
  },
  options,
  service: nurseriesApiClient,
  serviceMethod: 'get',
})

export const removeStripeIntegration = () => ({
  type: REMOVE_STRIPE_INTEGRATION,
})
