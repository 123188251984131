export const GET_NURSERY_ITEM = 'NURSERY_ITEM/GET_NURSERY_ITEM'
export const GET_NURSERY_ITEM_SUCCESS = 'NURSERY_ITEM/GET_NURSERY_ITEM_SUCCESS'
export const GET_NURSERY_ITEM_FAILED = 'NURSERY_ITEM/GET_NURSERY_ITEM_FAILED'

export const CREATE_NURSERY_ITEM = 'NURSERY_ITEM/CREATE_NURSERY_ITEM'
export const CREATE_NURSERY_ITEM_SUCCESS = 'NURSERY_ITEM/CREATE_NURSERY_ITEM_SUCCESS'
export const CREATE_NURSERY_ITEM_FAILED = 'NURSERY_ITEM/CREATE_NURSERY_ITEM_FAILED'

export const UPDATE_NURSERY_ITEM = 'NURSERY_ITEM/UPDATE_NURSERY_ITEM'
export const UPDATE_NURSERY_ITEM_SUCCESS = 'NURSERY_ITEM/UPDATE_NURSERY_ITEM_SUCCESS'
export const UPDATE_NURSERY_ITEM_FAILED = 'NURSERY_ITEM/UPDATE_NURSERY_ITEM_FAILED'

export const CLEAR_NURSERY_ITEM_SINGLE = 'NURSERY_ITEM/CLEAR_NURSERY_ITEM_SINGLE'
