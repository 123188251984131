import styled from 'styled-components'

export const StyledContentContainer = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledSignatureContainer = styled.div`
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;

  & img {
    height: 50px;
    width: 200px;
    object-fit: contain;
  }
`

export const StyledActionButtonContainer = styled.div`
  padding: 10px 0 0 10px;
`

export const StyledParentSignatureContainer = styled.div`
  display: ${({ isDisplay }) => isDisplay && 'table-header-group'};
`
