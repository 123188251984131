import _ from 'lodash'

export const getInitialValues = (nurseries, isEdit, session, formValues) => {
  if (!nurseries) {
    return null
  }

  if (isEdit && !session) {
    return null
  }

  let initialValues = {
    nurserySessions: formValues?.nurserySessions ? formValues.nurserySessions : [],
  }

  if (isEdit) {
    const { endTime, isHourly, name, startTime } = session

    initialValues = {
      ...initialValues,
      endTime,
      isHourly,
      name,
      startTime,
    }
  }

  return initialValues
}

export const getPayload = (fields, sameFeesForAll) => {
  const mapNurserySession = (nurserySession) => {
    const newNurserySession = sameFeesForAll ? fields.nurserySessions[0] : nurserySession

    const { costTwoYears, costTwoYearsAbove, costUnderTwo } = newNurserySession || {}

    return {
      ...nurserySession,
      costTwoYears: costTwoYears ? parseFloat(costTwoYears) : 0,
      costTwoYearsAbove: costTwoYearsAbove ? parseFloat(costTwoYearsAbove) : 0,
      costUnderTwo: costUnderTwo ? parseFloat(costUnderTwo) : 0,
    }
  }

  const { endTime, isHourly, startTime } = fields

  return {
    ...fields,
    endTime: !isHourly ? endTime : null,
    nurserySessions: _.map(fields.nurserySessions, mapNurserySession),
    startTime: !isHourly ? startTime : null,
  }
}
