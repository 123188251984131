import React from 'react'

import { generateRoute } from 'utils/routing'

import { DetailsBreakdown, Typography } from 'components'

const BreakdownHeader = ({
  childFormRouteType,
  childId,
  isFormContext,
  permissionToEdit,
  title,
}) => (
  <DetailsBreakdown.Header
    edit={!isFormContext && {
      auth: permissionToEdit,
      to: generateRoute('CHILDREN.EDIT', { childId, type: childFormRouteType }),
    }}
  >
    <Typography fontSize={28} fontWeight={300}>
      {title}
    </Typography>
  </DetailsBreakdown.Header>
)

export default BreakdownHeader
