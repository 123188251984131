import { flatten, nest } from 'utils/flatnest'
import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withChildExtraItemsService } from 'services/legacy/childExtraItems'
import { withNurseryExtraItemsService } from 'services/legacy/nurseryExtraItems'
import { CHILD_EXTRA_ITEMS_FILTERS, CHILD_EXTRA_ITEMS_FILTER_OPTIONS } from 'services/legacy/childExtraItems/constants'

import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withSortingUtils } from 'services/utils/sorting'

import ChildExtraItemsView from './ChildExtraItemsView'
import { getTableData } from './helpers'

const CHILD_EXTRA_ITEMS_GROUPS = {
  read: ['childExtraItem.extraItem', 'extraItem'],
}

class ChildExtraItemsContainer extends Component {
  constructor(props) {
    super(props)

    const { dateRange, status } = nest(props.location.query)

    this.state = {
      filters: {
        dateRange: dateRange || [undefined, undefined],
        status: status || CHILD_EXTRA_ITEMS_FILTERS.ACTIVE,
      },
    }
  }

  componentDidMount() {
    const { nurseryExtraItemsActions, nurseryExtraItemsSelectors } = this.props

    const nurseryExtraItemsCriteria = nurseryExtraItemsSelectors.getCriteriaSelector()

    nurseryExtraItemsActions.list({
      params: { criteria: nurseryExtraItemsCriteria },
    })
    this.fetch()
  }

  componentWillUnmount() {
    const { childExtraItemsActions, nurseryExtraItemsActions } = this.props

    childExtraItemsActions.clear()
    nurseryExtraItemsActions.clearList()
  }

  fetch = () => {
    const {
      childExtraItemsActions,
      childExtraItemsSelectors,
      paginationUtils,
      params,
      setLocationQuery,
      sortingUtils,
    } = this.props
    const { filters } = this.state
    const { page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils
    const { childId } = params

    const criteria = childExtraItemsSelectors.getCriteriaSelector({ ...filters, childId })
    const apiParams = {
      criteria,
      groups: CHILD_EXTRA_ITEMS_GROUPS,
      order: { sortField, sortOrder },
      page,
    }

    setLocationQuery(flatten(filters))
    childExtraItemsActions.list(apiParams)
  }

  handleDateRangeChange = (dateRange) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        dateRange,
      },
    }), () => this.fetch())
  }

  handleStatusFilterChange = (status) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        status: status ? status.value : null,
      },
    }), () => this.fetch())
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleSortChange = (key) => {
    const { paginationUtils, sortingUtils } = this.props
    const { onSortChange } = sortingUtils
    const { onPageChange } = paginationUtils

    onSortChange(onPageChange(this.fetch)(1))(key)
  }

  render() {
    const {
      child,
      childExtraItemsListState,
      errorMessages,
      extraItemsFormattedData,
      isFetching,
      isNurseryExtraItemsEmpty,
      paginationUtils,
      params,
      sortingUtils,
    } = this.props
    const { filters: { dateRange, status } } = this.state
    const { getPageCount, page, perPage } = paginationUtils
    const { sortField, sortOrder } = sortingUtils
    const { childId } = params

    const isLoading = isFetching || !extraItemsFormattedData
    const isEmpty = childExtraItemsListState.isEmpty

    const tableData = getTableData(extraItemsFormattedData, childId)
    const pageCount = getPageCount(childExtraItemsListState.meta.total_results)

    return (
      <ChildExtraItemsView
        child={child}
        dateRange={dateRange}
        errorMessages={errorMessages}
        isEmpty={isEmpty}
        isLoading={isLoading}
        isNurseryExtraItemsEmpty={isNurseryExtraItemsEmpty}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        sortField={sortField}
        sortOrder={sortOrder}
        status={status}
        statusOptions={CHILD_EXTRA_ITEMS_FILTER_OPTIONS}
        tableData={tableData}
        totalResults={childExtraItemsListState.meta.total_results}
        onDateRangeChange={this.handleDateRangeChange}
        onPageChange={this.handlePageChange}
        onSortChange={this.handleSortChange}
        onStatusFilterChange={this.handleStatusFilterChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childExtraItemsListState,
  childExtraItemsSelectors,
  childSelectors,
  nurseryExtraItemsSelectors,
  params,
}) => ({
  child: childSelectors.getChildSelector(state),
  errorMessages: appSelectors.getErrorMessages(childExtraItemsListState),
  extraItemsFormattedData: childExtraItemsSelectors.getChildExtraItemsFormattedListDataSelectors(state),
  isFetching: appSelectors.getIsFetching(childExtraItemsListState),
  isNurseryExtraItemsEmpty: nurseryExtraItemsSelectors.isNurseryExtraItemsEmpty(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const enhance = compose(
  withAppService,
  withChildService,
  withChildExtraItemsService,
  withNurseryExtraItemsService,
  withPaginationUtils,
  withRouterUtils,
  withSortingUtils,
  connect(mapState),
)

export default enhance(ChildExtraItemsContainer)
