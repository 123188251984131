import { combineReducers } from 'redux'

import childSingle from './childSingle/reducer'
import nurserySingle from './nurserySingle/reducer'
import membershipSingle from './membershipSingle/reducer'

export default combineReducers({
  childSingle,
  membershipSingle,
  nurserySingle,
})
