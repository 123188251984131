import { combineReducers } from 'redux'

import list from './list/reducer'
import single from './single/reducer'
import statistics from './statistics/reducer'

export default combineReducers({
  list,
  single,
  statistics,
})
