import React from 'react'

import { generateRoute } from 'utils/routing'

import { Button, Field, Section, Spinner, Table, Toolbar } from 'components'
import NurseriesListTable from 'module/Settings/components/NurseriesListTable'

import i18n from 'translations'

const SettingsInvoicesView = ({
  globalTypeDescription,
  hideGlobalSettings,
  hideNurseries,
  isEmpty,
  isLoading,
  nurseries,
  onPageChange,
  page,
  pageCount,
  perPage,
  totalResults,
}) => {
  if ((isLoading || !nurseries) && 1 === page) {
    return (
      <Section>
        <Spinner />
      </Section>
    )
  }

  if (isEmpty) {
    return null
  }

  return (
    <div>
      <Section title="Invoice settings">
        <Toolbar bottomLine>
          <Toolbar.Group>
            <Toolbar.Item>
              {i18n.t('module:Settings:Invoice:subtitle')}
            </Toolbar.Item>
          </Toolbar.Group>
        </Toolbar>
        <Field.InlineEdit
          actions={(
            <Button
              hierarchy="tertiary"
              label={i18n.t('global:Edit')}
              size="small"
              to={generateRoute('SETTINGS.INVOICES.GLOBAL_EDIT')}
              negativeMargins
            />
          )}
          description={globalTypeDescription}
          label={i18n.t('module:Settings:Invoice:Global:label')}
        />
        {!hideGlobalSettings && (
          <Table>
            <Table.Tr>
              <Table.Td align="left">
                Your global settings
              </Table.Td>
              <Table.Td>
                <Button.TableAction to={generateRoute('SETTINGS.INVOICES.VIEW')} />
              </Table.Td>
            </Table.Tr>
          </Table>
        )}
      </Section>
      {!hideNurseries && (
        <Section title="Individual nursery invoice settings">
          <NurseriesListTable
            getActionRouting={({ id }) => generateRoute('SETTINGS.INVOICES.DETAILS', { nurseryId: id })}
            isFetching={isLoading}
            noDataIcon="invoices"
            nurseries={nurseries}
            page={page}
            pageCount={pageCount}
            perPage={perPage}
            totalResults={totalResults}
            onPageChange={onPageChange}
          />
        </Section>
      )}
    </div>
  )
}

export default SettingsInvoicesView
