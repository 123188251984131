import _ from 'lodash'

import { bindActionCreators } from 'redux'

import { BasicActionProps } from 'constants/service'

export const injectActions = (name: string, actions) => (dispatch) => ({
  [name]: _.mapValues(actions, (action) => bindActionCreators(action, dispatch)),
})

export const injectMultipleActions = (actionParams) => (dispatch) => actionParams.reduce(
  (prevObj, actionParam) => {
    const [name, actions] = actionParam
    const newObj = prevObj

    newObj[name] = _.mapValues(actions, (action) => bindActionCreators(action, dispatch))

    return newObj
  },
  {},
)

export interface BasicActionOptionsProps<B = any> {
  addToList?: boolean
  body?: B
  mergeResult?: boolean
  onFailed?: (response: any) => any
  onSuccess?: (response: any) => any
  onlyData?: boolean
  params?: any
  silent?: boolean
}

const basicAction = <B = null>(props: BasicActionProps<B>) => (dispatch) => {
  const { constants, options, service, serviceMethod } = props || {}
  const { body, onFailed, onSuccess, onlyData, params, silent } = options

  let finalParams = params || [] as any

  if (!_.isArray(params) && params) {
    finalParams = [params]
  }

  if (!onlyData) {
    dispatch({ payload: finalParams, silent, type: constants.init })
  }

  return service[serviceMethod](...finalParams, body)
    .then(
      (response) => {
        if (!onlyData) {
          dispatch({
            payload: response,
            type: constants.success,
          })
        }

        if (onSuccess) {
          onSuccess(response)
        }

        return response
      },
      ({ response }) => {
        if (!onlyData) {
          dispatch({
            error: response,
            type: constants.failed,
          })
        }

        if (onFailed) {
          onFailed(response)
        }

        return response
      },
    )
}

const removeAction = (props) => async (dispatch) => {
  const { constants, options, service, serviceMethod } = props || {}
  const { body, onFailed, onSuccess, onlyData, params } = options

  let finalParams = params

  if (!_.isArray(params)) {
    finalParams = [params]
  }

  if (!onlyData) {
    dispatch({ type: constants.init })
  }

  await service[serviceMethod](...finalParams, body).then(
    (response) => {
      if (!onlyData) {
        dispatch({ payload: finalParams, type: constants.success })
      }

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      if (!onlyData) {
        dispatch({ type: constants.failed })
      }

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

const listActionMethod = async (dispatch, props) => {
  const { constants, options, service, serviceMethod } = props || {}
  const { mergeResult, onFailed, onSuccess, onlyData, params, recursively, silent } = options

  let finalParams = params

  if (!_.isArray(params)) {
    finalParams = [params]
  }

  if (!onlyData) {
    dispatch({
      silent,
      type: constants.init,
    })
  }

  if (!service) {
    throw new Error('Service is not defined. Please import service in core/services')
  }

  try {
    const response = await service[serviceMethod](...finalParams)
    const { meta } = response || {}
    const { limit, start, total_results: totalResults } = meta || {}

    if (!onlyData) {
      dispatch({
        mergeResult,
        payload: response,
        type: constants.success,
      })
    }

    if (recursively && start * limit < totalResults) {
      return listActionMethod(dispatch, {
        ...props,
        options: {
          ...props.options,
          mergeResult: true,
          params: {
            ...props.options.params,
            page: (params.page || 1) + 1,
          },
        },
      })
    }

    if (onSuccess) {
      onSuccess(response)
    }

    return response
  } catch (e) {
    if (!onlyData) {
      dispatch({
        error: e.response,
        type: constants.failed,
      })
    }

    if (onFailed) {
      onFailed(e.response)
    }
  }

  return null
}

const listAction = (props) => async (dispatch) => listActionMethod(dispatch, props)

export const generateBasicActions = {
  create: basicAction,
  get: basicAction,
  list: listAction,
  remove: removeAction,
  update: basicAction,
}
