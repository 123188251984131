import React from 'react'

import { ModalBox } from 'components'

import i18n from 'translations'

import ShiftCopyModalForm from './components/ShiftCopyModalForm'

const ShiftCopyModalView = ({
  onCloseClick,
  ...rest
}) => (
  <ModalBox
    scrollToBody={false}
    title={i18n.t('module:Modals:Staff:ShiftCopy:title')}
    onCloseClick={onCloseClick}
  >
    <ShiftCopyModalForm
      onCloseClick={onCloseClick}
      {...rest}
    />
  </ModalBox>
)

export default ShiftCopyModalView
