import i18n from 'translations'

import TimeWidget from './TimeWidgetContainer'

export const WIDGET_NAME = {
  component: TimeWidget,
  id: 'TIME_WIDGET',
  label: i18n.t('module:Dashboard:Widgets:TimeWidget:titleInSettings'),
}

export default TimeWidget
