import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as newsSelectors from './selectors'

const withNewsletterService = (WrappedComponent) => {
  const mapState = ({ newsletters }) => ({ newsletters })

  const mapDispatch = injectActions('newsActions', actions)

  const Component = ({ newsActions, newsletters, ...other }) => (
    <WrappedComponent
      newsActions={newsActions}
      newsListState={newsletters.list}
      newsSelectors={newsSelectors}
      newsSingleState={newsletters.single}
      {...other}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withNewsletterService
