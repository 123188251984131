import moment from 'moment'

export const getDailyDiaryCriteria = (params) => {
  const { date, room } = params
  const criteria = []

  criteria.push({
    comparator: 'eq',
    field: 'entryDate',
    value: date,
  })

  criteria.push({
    comparator: 'eq',
    field: 'present',
    value: true,
  })

  if (room) {
    criteria.push({
      comparator: 'eq',
      field: 'room',
      value: room,
    })
  }

  return criteria
}

export const getCriteria = (state) => {
  const { date, present, room, session } = state
  const criteria = []

  if (date) {
    criteria.push({
      field: 'entryDate',
      value: moment(date).format('YYYY-MM-DD'),
    })
  }

  if (room) {
    criteria.push({
      field: 'room',
      value: room,
    })
  }

  if (session) {
    criteria.push({
      field: 'session',
      value: session,
    })
  }

  if (null !== present && present !== undefined) {
    criteria.push({
      field: 'present',
      value: present ? 1 : 0,
    })
  }

  return criteria
}

export const getReportCriteria = (params) => {
  const criteria = []
  const extra = {}

  const { entryDate, includeComments, multipleRows, room, session } = params

  if (entryDate) {
    if (entryDate[0]) {
      criteria.push({
        comparator: 'gte',
        field: 'entryDate',
        value: moment(entryDate[0]).format('DD-MM-YYYY'),
      })
    }

    if (entryDate[1]) {
      criteria.push({
        comparator: 'lte',
        field: 'entryDate',
        value: moment(entryDate[1]).format('DD-MM-YYYY'),
      })
    }
  }

  if (session) {
    criteria.push({
      field: 'session',
      value: session.value,
    })
  }

  if (room?.value) {
    criteria.push({
      field: 'room',
      value: room.value,
    })
  }

  if (multipleRows) {
    extra.multipleRows = 1
  }

  if (includeComments) {
    extra.includeComments = 1
  }

  return {
    criteria,
    extra,
  }
}
