import _ from 'lodash'

import React, { useMemo, useState } from 'react'

import { FEATURE_FLAGS, ROLES } from 'constants/security'

import { generateRoute } from 'utils/routing'

import { isOrganizationContext } from 'services/security/selectors'

import i18n from 'translations'

import ReportView from './ReportView'

const ReportContainer = () => {
  const [search, setSearch] = useState('')

  const items = useMemo(() => {
    const reportItems = [
      {
        description: i18n.t('module:Enquiries:Report:ConversionRate:description'),
        title: i18n.t('module:Enquiries:Report:ConversionRate:title'),
        to: generateRoute('ENQUIRIES.REPORT.CONVERSION_RATE'),
      },
      {
        description: i18n.t('module:Enquiries:Report:Performance:description'),
        title: i18n.t('module:Enquiries:Report:Performance:title'),
        to: generateRoute('ENQUIRIES.REPORT.PERFORMANCE'),
      },
      {
        description: i18n.t('module:Enquiries:Report:ViewingPerformance:description'),
        title: i18n.t('module:Enquiries:Report:ViewingPerformance:title'),
        to: generateRoute('ENQUIRIES.REPORT.VIEWING_PERFORMANCE'),
      },
    ]

    if (search) {
      return _.filter(reportItems, ({ description, title }) => (
        title.toLowerCase().includes(search.toLowerCase())
        || description.toLowerCase().includes(search.toLowerCase())
      ))
    }

    return reportItems
  }, [search])

  return (
    <ReportView items={items} onSearchChange={setSearch} />
  )
}

ReportContainer.authParams = (state) => {
  const isOrganization = isOrganizationContext(state)

  if (isOrganization) {
    return {
      flags: [FEATURE_FLAGS.GROUP_REPORTING_V2],
      organizationContext: true,
      roles: [
        ROLES.SUPER_ADMIN,
        ROLES.ORGANIZATION_DIRECTOR,
        ROLES.ORGANIZATION_NATIONAL_ADMIN,
        ROLES.ORGANIZATION_FINANCE_ADMIN,
      ],
    }
  }

  return {
    flags: [FEATURE_FLAGS.GROUP_REPORTING_V2],
    roles: [
      ROLES.SUPER_ADMIN,
      ROLES.ORGANIZATION_DIRECTOR,
      ROLES.ORGANIZATION_NATIONAL_ADMIN,
      ROLES.ORGANIZATION_FINANCE_ADMIN,
      ROLES.ORGANIZATION_LINE_MANAGER,
      ROLES.DEPUTY_MANAGER,
      ROLES.NURSERY_MANAGER,
      ROLES.NURSERY_ADMIN,
    ],
  }
}

export default ReportContainer
