import { createSelector } from 'reselect'

const getObservationFeedback = (state) => state.observationFeedback

export const getObservationFeedbackSingleSelector = createSelector(
  [getObservationFeedback],
  (state) => {
    if (!state) {
      return null
    }

    return state.single
  },
)

export const getValuesForm = (id, values) => {
  const { comment, improvements } = values || {}
  const body = {
    observation: {
      id,
    },
  }

  if (comment) {
    body.comment = comment
  }

  if (improvements) {
    body.improvements = improvements
  }

  return body
}

export const getUpdateCriteria = (params) => {
  const { observation } = params
  const body = {}

  if (observation) {
    body.observation = observation
  }

  return body
}
