import React, { Component } from 'react'

import { generateRoute } from 'utils/routing'

const withStaffHoc = (WrappedComponent) => {
  class CustomComponent extends Component {
    constructor(props) {
      super(props)

      const { location: { pathname } } = this.props

      this.state = {
        isMyDetailsContext: pathname.startsWith(generateRoute('ACCOUNT.INDEX')),
      }
    }

    render() {
      const { ...other } = this.props
      const { isMyDetailsContext } = this.state

      return (
        <WrappedComponent
          {...other}
          isMyDetailsContext={isMyDetailsContext}
        />
      )
    }
  }

  CustomComponent.authParams = WrappedComponent.authParams

  return CustomComponent
}

export default withStaffHoc
