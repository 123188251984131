import React from 'react'
import { connect } from 'react-redux'

import { RootState } from 'core/reducers'
import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'
import selectors from './selectors'
import * as helpers from './helpers'
import { NurseryFundingListState } from './list/reducer'
import { NurseryFundingSingleState } from './single/reducer'

interface getAllNurseryFundingOptionProps {
  cb?: (data: any) => void
  nurseryId?: string
  page?: number
  params?: any
}

export interface withNurseryFundingServiceProps {
  nurseryFundingActions: typeof actions
  nurseryFundingHelpers: {
    getAllNurseryFunding?: ({ cb, nurseryId, page, params }: getAllNurseryFundingOptionProps) => void
  }
  nurseryFundingListState: NurseryFundingListState
  nurseryFundingSelectors: typeof selectors
  nurseryFundingSingleState: NurseryFundingSingleState
}

const withNurseryFundingService = <P extends withNurseryFundingServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ nurseryFunding }: RootState) => ({ nurseryFunding })

  const mapDispatch = injectActions('nurseryFundingActions', actions)

  const Component = ({ nurseryFunding, nurseryFundingActions, ...others }) => (
    <WrappedComponent
      nurseryFundingActions={nurseryFundingActions}
      nurseryFundingHelpers={{ ...helpers }}
      nurseryFundingListState={nurseryFunding.list}
      nurseryFundingSelectors={selectors}
      nurseryFundingSingleState={nurseryFunding.single}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withNurseryFundingService
