import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

import { Button } from 'components'
import { StyledButtonWrapper } from 'components/Button/ButtonStyled'

const BORDER_BOTTOM = `solid 2px ${NEUTRAL_COLOURS.WHITE}`
const BOX_SHADOW = '5px 0 3px rgba(0,0,0,0.3)'

export const StyledFrameworkTableWrapper = styled.div`
  display: grid;
  grid-template-columns: 140px 220px auto;
  min-height: 46vh;
  grid-template-rows: 1fr;
  
  ${StyledButtonWrapper} {
    width: 100%;
  }
  
  @media(max-width: 1024px) {
    grid-template-columns: 100px 150px auto;
  }
  
  @media(max-width: 840px) {
    grid-template-columns: auto;
    grid-template-rows: 80px 80px auto;
  }
  
  ${({ areaVisible }) => !areaVisible && `
    grid-template-columns: 220px auto;
    
    @media(max-width: 1024px) {
      grid-template-columns: 150px auto;
    }
    
    @media(max-width: 840px) {
      grid-template-rows: 80px auto;
      grid-template-columns: auto;
    }
  `}
  
  ${({ groupVisible }) => !groupVisible && `
    grid-template-columns: 220px auto;
    
    @media(max-width: 1024px) {
      grid-template-columns: 100px auto;
    }
    
    @media(max-width: 840px) {
      grid-template-rows: 80px auto;
      grid-template-columns: auto;
    }
  `}

  ${({ areaVisible, groupVisible }) => !areaVisible && !groupVisible && `
    grid-template-columns: auto;
        
    @media(max-width: 1024px) {
      grid-template-columns: auto;
    }
    
    @media(max-width: 840px) {
      grid-template-rows: auto;
    }
  `}
`

export const StyledButton = styled(Button)`
  border-radius: 0;
  margin: 0;
  width: 100%;
  height: auto;
  padding: 6px 24px;
  text-transform: none;
  line-height: 18px;
  min-height: 60px;
`

export const StyledAreasSection = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: ${BOX_SHADOW};
  z-index: 3;
  
  @media(max-width: 840px) {
    flex-direction: row;
  }

  & > div:not(:last-child) {
    border-bottom: ${BORDER_BOTTOM};
  }
  
  ${StyledButtonWrapper} {
    flex: 1;
  }
  
  ${StyledButton} {
    height: 100%;
    
    .MuiButton-label {
      white-space: pre-line;
    }
  }
`

export const StyledGroupsSection = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: ${BOX_SHADOW};
  z-index: 2;
  
  @media(max-width: 840px) {
    flex-direction: row;
  }

  & > div:not(:last-child) {
    border-bottom: ${BORDER_BOTTOM};
  }
  
  ${StyledButtonWrapper} {
    flex: 1;
  }
  
  ${StyledButton} {
    text-transform: none;
    height: 100%;
    
    .MuiButton-label {
      white-space: pre-line;
    }
  }
`

export const StyledCategoryWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  max-height: 670px;
`

export const StyledCategoryHeader = styled.header`
  padding: 12px 15px;
  color: ${NEUTRAL_COLOURS.WHITE};
  background: ${getBrandingColor('tertiary-color')};
`

export const StyledButtonLabel = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
  align-items: center;  
`

export const StyledAreaGroupWrapper = styled.div`
  padding: 5px;
  border: 2px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  display: flex;
  flex-direction: column;
  justify-content: center;
`
