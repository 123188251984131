import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`

export const StyledAreaHeader = styled.div`
  border-left: 8px solid;
  padding: 15px;
  background: ${NEUTRAL_COLOURS.GRAY_QUINARY};

  ${({ color }) => color && `
    border-left-color: ${color};
  `}

  & + div {
    margin-top: 20px;
  }
`

export const StyledGroupHeader = styled.div`
  margin-bottom: 20px;
  margin-top: 5px;
`

export const StyledCategoryWrapper = styled.div`
  display: grid;
  grid-template-columns: 180px 1fr auto;
  grid-column-gap: 10px;
  margin-bottom: 20px;
  padding-right: 10px;
  
  @media(max-width: 780px) {
    grid-template-columns: auto 100px;
    grid-template-rows: auto auto;
    grid-row-gap: 5px;
    grid-template-areas: 'category secure'
      'description nothing';
    
    & > p:nth-of-type(1) {
      grid-area: category;
      font-weight: 600;
      font-size: 15px;
    }
    
    & > p:nth-of-type(2) {
      grid-area: description;
    }
    
    & > p:nth-of-type(3) {
      grid-area: secure;
    }
  }
  
  @media(max-width: 480px) {
    grid-template-columns: auto 60px;
  }
  
  ${({ categoryVisible }) => !categoryVisible && `
    grid-template-columns: auto 100px;
  `}
`

export const StyledGroupsWrapper = styled.div`
  margin-left: 23px;

  &:not(:last-child) {
    & ${StyledCategoryWrapper}:last-child {
      margin-bottom: 40px;
    }
  }
`
