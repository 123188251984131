import { Property } from 'csstype'
import styled from 'styled-components'

interface SpaceStyledProps {
  $inline?: boolean
  $margin?: Property.Margin
  $padding?: Property.Padding
  $space?: string
}

export const StyledSpace = styled.div<SpaceStyledProps>`
  ${({ $margin }) => $margin && `
    margin: ${$margin};
  `}
  
  ${({ $padding }) => $padding && `
    padding: ${$padding};
  `}
  
  ${({ $space }) => $space && `
    margin-bottom: ${$space};
  `}  
  
  ${({ $inline }) => $inline && `
    display: inline;
  `}
`
