import {
  CLEAR_TERM,
  CREATE_TERM,
  CREATE_TERM_FAILED,
  CREATE_TERM_SUCCESS,
  GET_TERM,
  GET_TERM_FAILED,
  GET_TERM_SUCCESS,
  UPDATE_TERM,
  UPDATE_TERM_FAILED,
  UPDATE_TERM_SUCCESS,
} from './actions'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case GET_TERM:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_TERM_SUCCESS:
      return { ...state, data: payload.data, isFetching: false }
    case GET_TERM_FAILED:
      return { ...state, error, isFetching: false }

    case CREATE_TERM:
      return { ...state, error: initialState.error, isSubmitting: true }
    case CREATE_TERM_SUCCESS:
    case CREATE_TERM_FAILED:
      return { ...state, error, isSubmitting: false }

    case UPDATE_TERM:
      return { ...state, error: initialState.error, isSubmitting: true }
    case UPDATE_TERM_SUCCESS:
    case UPDATE_TERM_FAILED:
      return { ...state, error, isSubmitting: false }

    case CLEAR_TERM:
      return initialState

    default:
      return state
  }
}
