import styled from 'styled-components'

export const StyledContainer = styled.div`
  padding: 20px;
`

export const StyledFieldsContainer = styled.div`
  display: flex;
`

export const StyledSelectsContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-right: 20px;
`

export const StyledTextAreaContainer = styled.div`
  flex: 1;
  height: 100%;
`

export const StyledFooter = styled.div`
  margin-top: 20px;
`
