import _ from 'lodash'
import moment from 'moment'
import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

import React, { useEffect, useState } from 'react'
import { Field, InjectedFormProps, change, formValueSelector, reduxForm } from 'redux-form'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { Option } from 'constants/models'
import { NurseryDiscountSettings, NurseryDiscountTypesV3 } from 'services/product/nurseryDiscountsV3/constants'
import { NurseryConsumableSettings } from 'services/product/nurseryConsumablesV3/models'
import {
  ChildProductTypes,
  ONE_OFF_BOOKING_TYPE_OPTIONS,
  OneOffBookingTypes,
} from 'services/booking/childProducts/constants'
import { ChildProductPriceCalculatorDataState } from 'services/child/models'
// eslint-disable-next-line max-len, import-newlines/enforce
import { AddDiscountFormValues } from 'module/Management/ManagementFinanceSettings/ManagementDiscounts/v3/ManagementDiscountsV3Add/components/AddDiscountForm'

import { createTypeFromEnumValues } from 'utils/typescript'
import { isRequired } from 'utils/fieldValidation'

import { generateRoute } from 'utils/routing'

import { EmptyState, Form } from 'components'

import i18n from 'translations'

import SessionFormFields from './SessionFormFields'
import ItemFormFields from './ItemFormFields'
import DiscountFormFields, { DiscountItemOption } from './DiscountFormFields'
import FundingFormFields, { FundingItemOption } from './FundingFormFields'
import PriceCalculator from './PriceCalculator'

interface childExtendedOption {
  avatar?: string
}

export interface FormItemOptionProps extends Option {
  hourly?: boolean
  settings?: NurseryDiscountSettings | NurseryConsumableSettings
  type?: string | createTypeFromEnumValues<NurseryDiscountTypesV3>
}

type DiscountValueType = createTypeFromEnumValues<NurseryDiscountTypesV3>

export interface AddFormFieldProps {
  child?: Option & childExtendedOption
  date?: moment.Moment
  // deductFromParentInvoice?: boolean
  discountForm?: AddDiscountFormValues
  endTime?: number
  includeConsumables?: boolean
  item?: FormItemOptionProps
  quantity?: number
  settings?: any
  startTime?: number
  type: Option<createTypeFromEnumValues<OneOffBookingTypes>>
  value?: number
}

export interface AddFormProps {
  child?: Option
  childId: number
  // discountSessions?: boolean
  discountType?: DiscountValueType
  isChildContext?: boolean
  isEdit: boolean
  isFetchingPrice: boolean
  isInvoiced: boolean
  isSubmitting?: boolean
  isValid: boolean
  item: FundingItemOption | DiscountItemOption | Option
  linkedToRequestedExtraSession?: boolean
  onSubmit: (values: AddFormFieldProps) => any
  priceCalculator: ChildProductPriceCalculatorDataState
  settings?: any
  type: Option<createTypeFromEnumValues<OneOffBookingTypes>>
  updateField: (fieldName: string, fieldValue: any) => void
}

export const ADD_ONE_OFF_BOOKING_FORM = 'AddOneOffBookingForm'

type AddFormFullProps = InjectedFormProps<{} & AddFormFieldProps> & AddFormProps

const AddForm: React.FC<AddFormFullProps> = ({
  child,
  childId,
  // discountSessions,
  discountType,
  handleSubmit,
  isChildContext,
  isEdit,
  isFetchingPrice,
  isInvoiced,
  isSubmitting,
  isValid,
  item,
  linkedToRequestedExtraSession,
  onSubmit,
  priceCalculator,
  type,
  updateField,
}) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false)

  useEffect(() => {
    if (type?.value === ChildProductTypes.ONE_OFF_FUNDING) {
      if (item && 'settings' in item && 'total' in item.settings) {
        updateField('value', Math.abs(item.settings.total / 100))
      } else {
        updateField('value', undefined)
      }
    }
  }, [type, item])

  useEffect(() => {
    updateField('item', undefined)
  }, [type])

  useEffect(() => {
    if (isInitialized && type?.value === ChildProductTypes.ONE_OFF_DISCOUNT && (item as any)?.settings?.allowOverride) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      updateField('value', (item as any)?.settings?.value / 100)
    }

    setIsInitialized(true)
  }, [item])

  const allowOverride = (
    type?.value === ChildProductTypes.ONE_OFF_DISCOUNT
    && (item as any)?.settings?.allowOverride
  )

  const renderChildSelect = () => (
    <Form.Row label={_.upperFirst(i18n.t('global:child'))} required verticalLabel>
      <Form.Row.FlexItem flex="0 0 300px" mobileFull>
        <Field
          component={Form.InfiniteDropdowns.Children}
          disabled={isChildContext || isEdit || linkedToRequestedExtraSession}
          name="child"
          validate={isRequired}
        />
      </Form.Row.FlexItem>
    </Form.Row>
  )

  const renderForm = () => (
    <React.Fragment>
      <Form.Row label={i18n.t('global:Date')} required verticalLabel>
        <Form.Row.FlexItem flex="0 0 300px" mobileFull>
          <Field
            component={Form.DatePicker}
            disabled={isInvoiced || linkedToRequestedExtraSession}
            name="date"
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('global:Type')} required verticalLabel>
        <Form.Row.FlexItem flex="0 0 300px" mobileFull>
          <Field
            clearable={false}
            component={Form.Select}
            disabled={isEdit || linkedToRequestedExtraSession}
            name="type"
            options={ONE_OFF_BOOKING_TYPE_OPTIONS}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      {type?.value === ChildProductTypes.EXTRA_SESSION && (
        <SessionFormFields
          isInvoiced={isInvoiced}
          item={item}
          linkedToRequestedExtraSession={linkedToRequestedExtraSession}
        />
      )}
      {type?.value === ChildProductTypes.EXTRA_ITEM && <ItemFormFields isInvoiced={isInvoiced} />}
      {type?.value === ChildProductTypes.ONE_OFF_DISCOUNT && (
        <DiscountFormFields
          allowOverride={allowOverride}
          isInvoiced={isInvoiced}
          item={item as DiscountItemOption}
          // sessions={discountSessions}
          type={discountType as DiscountValueType}
        />
      )}
      {type?.value === ChildProductTypes.ONE_OFF_FUNDING && (
        <FundingFormFields
          isInvoiced={isInvoiced}
          item={item as FundingItemOption}
        />
      )}
      {allowOverride && (
        <Form.Row label={i18n.t('global:Value')} width={{ field: '140px' }} required verticalLabel>
          <SubdomainCurrencyProvider>
            {({ currencySymbol }) => (
              <Field
                component={Form.TextField}
                name="value"
                placeholder="0.00"
                prefix={currencySymbol}
                prefixWidth="30px"
                type="number"
                validate={isRequired}
              />
            )}
          </SubdomainCurrencyProvider>
        </Form.Row>
      )}
      {!allowOverride
      && (type?.value === ChildProductTypes.EXTRA_SESSION || type?.value === ChildProductTypes.EXTRA_ITEM)
        ? <PriceCalculator data={priceCalculator} isLoading={isFetchingPrice} />
        : null}
    </React.Fragment>
  )

  const renderEmptyState = () => (
    <EmptyState
      icon="children"
      text1={i18n.t('module:Finance:OneOffBookings:Form:emptyState')}
    />
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {renderChildSelect()}
      {child ? renderForm() : renderEmptyState()}
      <Form.FooterActions
        cancelLink={isChildContext
          ? generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.ONE_OFF_BOOKINGS', { childId })
          : generateRoute('FINANCE.ONE_OFF_BOOKINGS')}
        isSubmitting={isSubmitting}
        submitDisabled={(isEdit ? isInvoiced : !isValid) || linkedToRequestedExtraSession}
        submitLabel={isEdit
          ? i18n.t('module:Finance:OneOffBookings:Form:saveChanges')
          : i18n.t('module:Finance:OneOffBookings:Form:addBooking')}
      />
    </Form>
  )
}

const mapState = (state) => ({
  child: formValueSelector(ADD_ONE_OFF_BOOKING_FORM)(state, 'child'),
  discountSessions: formValueSelector(ADD_ONE_OFF_BOOKING_FORM)(state, 'discountForm.sessions'),
  discountType: formValueSelector(ADD_ONE_OFF_BOOKING_FORM)(state, 'discountForm.type'),
  item: formValueSelector(ADD_ONE_OFF_BOOKING_FORM)(state, 'item'),
  type: formValueSelector(ADD_ONE_OFF_BOOKING_FORM)(state, 'type'),
})

const mapDispatch = {
  updateField: (fieldName, fieldValue) => change(ADD_ONE_OFF_BOOKING_FORM, fieldName, fieldValue),
}

const validate = (fields: AddFormFieldProps) => {
  const { endTime, startTime } = fields || {}

  const errors = { endTime: null }

  if (startTime && endTime && endTime <= startTime) {
    errors.endTime = i18n.t('utils:Validation:isEndTimeGreaterThanStartTime')
  }

  return errors
}

const enhance = compose(
  reduxForm<{}, AddFormProps>({ form: ADD_ONE_OFF_BOOKING_FORM, validate }),
  connect(mapState, mapDispatch),
)

export default enhance(AddForm)
