export const CREATE_RELATION = 'CREATE_RELATION'
export const CREATE_RELATION_SUCCESS = 'CREATE_RELATION_SUCCESS'
export const CREATE_RELATION_FAILED = 'CREATE_RELATION_FAILED'

export const GET_RELATION = 'GET_RELATION'
export const GET_RELATION_SUCCESS = 'GET_RELATION_SUCCESS'
export const GET_RELATION_FAILED = 'GET_RELATION_FAILED'

export const UPDATE_RELATION = 'UPDATE_RELATION'
export const UPDATE_RELATION_SUCCESS = 'UPDATE_RELATION_SUCCESS'
export const UPDATE_RELATION_FAILED = 'UPDATE_RELATION_FAILED'

export const REMOVE_RELATION = 'REMOVE_RELATION'
export const REMOVE_RELATION_SUCCESS = 'REMOVE_RELATION_SUCCESS'
export const REMOVE_RELATION_FAILED = 'REMOVE_RELATION_FAILED'

export const CLEAR_RELATION = 'CLEAR_RELATION'
