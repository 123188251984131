import {
  CHILDREN_STARTING_REPORT_STATISTICS,
  CHILDREN_STARTING_REPORT_STATISTICS_FAILED,
  CHILDREN_STARTING_REPORT_STATISTICS_SUCCESS,
  CLEAR_CHILDREN_STARTING_REPORT_STATISTICS,
} from 'services/childrenStartingReport/statistics/constants'

import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

export const getStatistics = (options: BasicActionOptionsProps) => ({
  childrenStartingReportApiClient,
}) => generateBasicActions.list({
  constants: {
    failed: CHILDREN_STARTING_REPORT_STATISTICS_FAILED,
    init: CHILDREN_STARTING_REPORT_STATISTICS,
    success: CHILDREN_STARTING_REPORT_STATISTICS_SUCCESS,
  },
  options,
  service: childrenStartingReportApiClient,
  serviceMethod: 'getStatistics',
})

export const clearStatistics = () => ({
  type: CLEAR_CHILDREN_STARTING_REPORT_STATISTICS,
})

