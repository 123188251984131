import { BasicShellSettings, SubMenuItem } from 'services/shell/constants'

import {
  CLEAR_SUBMENU,
  HIDE_SUBMENU,
  SET_ROUTE_TITLE,
  SET_SUBMENU,
  SHELL_SET_SETTINGS,
  SHOW_SUBMENU,
  STICKY_FOOTER_MOUNTED,
  STICKY_FOOTER_UNMOUNTED,
} from './constants'

export const setSettings = (settings: BasicShellSettings) => ({
  payload: settings,
  type: SHELL_SET_SETTINGS,
})

export const setSubMenu = (payload: SubMenuItem[]) => ({
  payload,
  type: SET_SUBMENU,
})

export const clearSubMenu = () => ({
  type: CLEAR_SUBMENU,
})

export const setRouteTitle = (payload: { name: string, title: string }) => ({
  payload,
  type: SET_ROUTE_TITLE,
})

export const stickyFooterMounted = () => ({
  type: STICKY_FOOTER_MOUNTED,
})

export const stickyFooterUnmounted = () => ({
  type: STICKY_FOOTER_UNMOUNTED,
})

export const hideSubMenu = () => ({
  type: HIDE_SUBMENU,
})

export const showSubMenu = () => ({
  type: SHOW_SUBMENU,
})
