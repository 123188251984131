import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { stopSubmit } from 'redux-form'

import invoicesConstants from 'services/legacy/invoices/constants'

import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withNurseriesService } from 'services/nurseries'
import { withInvoicesService } from 'services/legacy/invoices'

import SendDraftInvoiceModalView from './SendDraftInvoiceModalView'
import { SEND_INVOICE_MODAL_FORM_NAME } from './components/SendDraftInvoiceModalForm'

const CHILD_FINANCE_DETAILS_GROUPS = {
  read: [
    'child.finance',
  ],
}

class SendDraftInvoiceModalContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      initialValues: null,
    }
  }

  componentDidMount() {
    const { childActions, childId } = this.props

    if (childId) {
      childActions.getFinanceDetails({
        params: [childId, {
          groups: CHILD_FINANCE_DETAILS_GROUPS,
        }],
      })
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { nurseryInvoiceNumberWithDescriptions } = props
    const { initialValues } = state

    if (!initialValues && nurseryInvoiceNumberWithDescriptions) {
      const { invoiceNumberDescription } = nurseryInvoiceNumberWithDescriptions

      return {
        initialValues: {
          invoiceNumberOption: invoicesConstants.SEND_INVOICE_NUMBER_TYPES.AUTOMATIC,
          number: invoiceNumberDescription,
        },
      }
    }

    return null
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleErrorCallback = (error) => {
    const { hideModal, stopSubmitForm } = this.props

    const errors = getBackendErrors(error)

    stopSubmitForm(errors)

    if (!errors) {
      hideModal()
    }
  }

  handleSubmit = (fields) => {
    const { onContinueClick } = this.props

    const { invoiceNumberOption, number } = fields

    if (invoiceNumberOption === invoicesConstants.SEND_INVOICE_NUMBER_TYPES.AUTOMATIC) {
      return onContinueClick(null, this.handleErrorCallback)
    }

    return onContinueClick(number, this.handleErrorCallback)
  }

  render() {
    const { childFinanceDetails, firstName, isLoading, isSubmitting } = this.props
    const { balance } = childFinanceDetails

    const { initialValues } = this.state

    return (
      <SendDraftInvoiceModalView
        balance={balance}
        childFirstName={firstName}
        initialValues={initialValues}
        isLoading={isLoading}
        isSubmitting={isSubmitting}
        onCloseClick={this.handleCloseClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  stopSubmitForm: (fieldErrors) => stopSubmit(SEND_INVOICE_MODAL_FORM_NAME, fieldErrors),
}

const mapState = (state, {
  appSelectors,
  childFinanceDetailsState,
  childSelectors,
  invoicesSingleState,
  nurseriesSelectors,
}) => ({
  childFinanceDetails: childSelectors.getFinanceDetailsSelector(state),
  isLoading: appSelectors.getIsFetching(childFinanceDetailsState),
  isSubmitting: appSelectors.getIsSubmitting(invoicesSingleState),
  nurseryInvoiceNumberWithDescriptions: nurseriesSelectors.getNurseryInvoiceNumberWithDescriptions(state),
})

const enhance = compose(
  withAppService,
  withChildService,
  withNurseriesService,
  withInvoicesService,
  connect(mapState, mapDispatch),
)

export default enhance(SendDraftInvoiceModalContainer)
