import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { ABSENCE_TYPE, ABSENCE_TYPE_OPTIONS } from 'module/Register/constants'

import { Callout, EmptyState, Page, Section, Spinner, TimeString, Tooltip } from 'components'

import i18n from 'translations'

import RegisterHeader from './components/RegisterHeader'
import RegisterList from './components/RegisterList'
import { getExtraSessionStartAndEndTime } from './helpers'
import RegisterItem from './components/RegisterItem/RegisterItem'
import { StyledTooltipWrapper } from './RegisterStyled'

const RegisterView = ({
  ExtraSessionsIsReadOnly,
  attendanceSummary,
  date,
  entryDate,
  errorMessages,
  isEmpty,
  isFinanceV3Enabled,
  isHoliday,
  isLoading,
  isNurseryClosed,
  isOffline,
  offlineTodayData,
  onAddExtraSessionClick,
  onDateChange,
  onDownloadButtonClick,
  onItemAbsenceClick,
  onItemAbsenceDeleteClick,
  onItemAbsenceEditClick,
  onItemResetClick,
  onItemSaveClick,
  onItemSignInClick,
  onItemSignOutClick,
  onItemStaffLogClick,
  onRoomChange,
  onSessionChange,
  onStatusChange,
  registerList,
  room,
  session,
  showWidget,
  status,
}) => {
  const renderEmptyState = () => {
    if (isEmpty) {
      return (
        <EmptyState
          icon="circle-tick"
          text1="There are no sessions set for today."
          text2="Add sessions to children in their profile or add extra sessions for them to appear on the register."
        />
      )
    }

    return (
      <EmptyState
        icon="circle-tick"
        text1="There are no results that match your filters"
      />
    )
  }

  const renderItem = (item) => {
    const {
      errorMessage,
      extraSessions,
      isLoading: isItemLoading,
      isSubmitting,
      registers,
      sessions,
    } = item

    const empty = {
      absence: null,
      comments: null,
      id: null,
      present: null,
      signIns: null,
    }

    const subtitle = () => {
      if (sessions && !sessions.length) {
        return null
      }

      return (
        <div>
          {_.map(sessions, (singleSession) => (
            _.map(singleSession.plans, (plan) => (
              <div key={plan.id}>
                <TimeString date={plan.startTime} local={false} />
                <span>
                  {' '}
                  -
                  {' '}
                </span>
                <TimeString date={plan.endTime} local={false} />
              </div>
            ))
          ))}
          {_.map(extraSessions, (singleSession) => {
            const startAndEndTime = getExtraSessionStartAndEndTime(singleSession)

            return startAndEndTime && startAndEndTime.startTime && startAndEndTime.endTime && (
              <div key={singleSession.id}>
                <TimeString date={startAndEndTime.startTime} local={false} />
                <span>
                  {' '}
                  -
                  {' '}
                </span>
                <TimeString date={startAndEndTime.endTime} local={false} />
                {' (E)'}
              </div>
            )
          })}
        </div>
      )
    }

    const register = registers && 0 < registers.length ? registers[0] : empty
    const { absence, comments, id, present, signIns } = register

    return (
      <RegisterList.Item key={`${moment(date).format(DEFAULT_DATE_FORMAT)}_${item.id}`}>
        <RegisterItem
          absence={absence}
          absenceTypeConts={ABSENCE_TYPE}
          absenceTypeOptions={ABSENCE_TYPE_OPTIONS}
          child={item}
          comments={comments}
          errorMessage={errorMessage}
          extraSessions={extraSessions}
          id={id}
          isLoading={isItemLoading}
          isSubmitting={isSubmitting}
          present={present}
          registerDate={entryDate}
          sessions={sessions}
          signIns={signIns}
          subtitle={subtitle()}
          onAbsenceClick={onItemAbsenceClick}
          onAbsenceDeleteClick={onItemAbsenceDeleteClick}
          onAbsenceEditClick={onItemAbsenceEditClick}
          onResetClick={onItemResetClick}
          onSaveClick={onItemSaveClick}
          onSignInClick={onItemSignInClick}
          onSignOutClick={onItemSignOutClick}
          onStaffLogClick={onItemStaffLogClick}
        />
      </RegisterList.Item>
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (isHoliday) {
      return (
        <EmptyState
          icon="sun"
          text1="Today your nursery is closed."
          text2="You can change your nursery closure dates in the term dates settings."
        />
      )
    }

    if (isNurseryClosed) {
      return (
        <EmptyState
          icon="sun"
          text1="Today your nursery is closed."
          text2="You can change your nursery opening days in the nursery general settings."
        />
      )
    }

    if (isEmpty || !registerList || 0 === registerList.length) {
      return renderEmptyState()
    }

    return (
      <RegisterList>
        {_.map(registerList, renderItem)}
      </RegisterList>
    )
  }

  const actions = (
    <Tooltip title={isOffline && i18n.t('module:Register:youCanNotDownloadTheRegisterWhenOffline')}>
      <StyledTooltipWrapper $isOffline={isOffline}>
        <Section.Actions
          disabled={isOffline}
          options={[{
            onClick: onDownloadButtonClick,
            type: 'download',
          }]}
        />
      </StyledTooltipWrapper>
    </Tooltip>
  )

  return (
    <Page.Section
      actions={actions}
      title="Register"
    >
      <Callout content={errorMessages} error />
      <RegisterHeader
        ExtraSessionsIsReadOnly={ExtraSessionsIsReadOnly}
        attendanceSummary={attendanceSummary}
        date={date}
        isFinanceV3Enabled={isFinanceV3Enabled}
        isOffline={isOffline}
        offlineTodayData={offlineTodayData}
        room={room}
        session={session}
        showWidget={showWidget}
        status={status}
        totalCount={registerList?.length}
        onAddExtraSessionClick={onAddExtraSessionClick}
        onDateChange={onDateChange}
        onRoomChange={onRoomChange}
        onSessionChange={onSessionChange}
        onStatusChange={onStatusChange}
      />
      {renderContent()}
    </Page.Section>
  )
}

export default RegisterView
