import React from 'react'

import { Callout, Page } from 'components'

import GenerateInvoicesForm from './components/GenerateInvoicesForm'

const FinanceGenerateInvoicesView = ({
  childrenPickerOptions,
  childrenPickerRoomValue,
  childrenPickerSelectedAll,
  childrenPickerValue,
  errorMessages,
  invoicePeriodDropdown,
  invoicePeriodOptions,
  isChildrenPickerVisible,
  isLoading,
  onChildrenPickerChange,
  onChildrenPickerSelectAll,
  onIssueDateChange,
  onPageChange,
  onRoomChange,
  onSubmit,
  page,
  pageCount,
  selectChildrenOptions,
}) => (
  <Page>
    <Page.Content>
      <Callout content={errorMessages} error />
      <GenerateInvoicesForm
        childrenPickerOptions={childrenPickerOptions}
        childrenPickerRoomValue={childrenPickerRoomValue}
        childrenPickerSelectedAll={childrenPickerSelectedAll}
        childrenPickerValue={childrenPickerValue}
        invoicePeriodDropdown={invoicePeriodDropdown}
        invoicePeriodOptions={invoicePeriodOptions}
        isChildrenLoading={isLoading}
        isChildrenPickerVisible={isChildrenPickerVisible}
        page={page}
        pageCount={pageCount}
        selectChildrenOptions={selectChildrenOptions}
        onChildrenPickerChange={onChildrenPickerChange}
        onChildrenPickerSelectAll={onChildrenPickerSelectAll}
        onIssueDateChange={onIssueDateChange}
        onPageChange={onPageChange}
        onRoomChange={onRoomChange}
        onSubmit={onSubmit}
      />
    </Page.Content>
  </Page>
)

export default FinanceGenerateInvoicesView
