import React, { PropsWithChildren } from 'react'

import ListAdvancedRow from './ListAdvancedRow'
import { StyledList } from './ListAdvancedStyled'

interface ListAdvancedRoot extends React.FC<PropsWithChildren<any>> {
  Row?: typeof ListAdvancedRow
}

const ListAdvanced: ListAdvancedRoot = ({ children }) => (
  <StyledList>
    {children}
  </StyledList>
)

export default ListAdvanced
