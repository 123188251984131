import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { FEATURE_FLAGS, ROLES } from 'constants/security'

import { getAuthUser } from 'services/security/selectors'
import { withChildService } from 'services/legacy/child'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withSortingUtils } from 'services/utils/sorting'
import { withModalService } from 'services/utils/modal'
import { withRouter } from 'services/router'

import i18n from 'translations'

import KeyPersonsReportView from './KeyPersonsReportView'

const KEY_PERSONS_REPORTS_LIST_COLUMNS = [
  {
    align: 'left',
    field: 'child',
    sortKey: 'firstName',
    sortable: true,
    title: _.upperFirst(i18n.t('global:child')),
  },
  {
    align: 'center',
    field: 'birthDate',
    sortKey: '',
    sortable: true,
    title: i18n.t('global:dobAge'),
  },
  {
    field: 'room',
    sortKey: 'nurseryClass.name',
    sortable: true,
    title: _.upperFirst(i18n.t('global:room')),
  },
]

const GROUPS = {
  read: [
    'child.nurseryClass',
    'nurseryClass',
  ],
}

const SEND_REPORT_GROUPS = {
  empty: ['export:child:keyPersons'],
}

class KeyPersonsReportContainer extends Component {
  constructor(props) {
    super(props)

    const { query } = props.location
    const { keyWorker } = query

    this.state = {
      filters: {
        keyWorker: +keyWorker || undefined,
      },
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { childActions } = this.props

    childActions.clear()
  }

  fetch = () => {
    const { childActions, childSelectors, paginationUtils, setLocationQuery, sortingUtils } = this.props
    const { getCriteria } = childSelectors
    const { filters } = this.state

    const { page, perPage } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    setLocationQuery(filters)

    const criteria = getCriteria(filters)
    const params = {
      criteria,
      groups: GROUPS,
      limit: perPage,
      order: { sortField, sortOrder },
      page,
    }

    childActions.list({ params })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleKeyWorkerChange = (keyWorker) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, keyWorker: keyWorker && keyWorker.value },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handleSortChange = (key) => {
    const { paginationUtils, sortingUtils } = this.props
    const { onSortChange } = sortingUtils
    const { onPageChange } = paginationUtils

    onSortChange(onPageChange(this.fetch)(1))(key)
  }

  handleSendReport = () => {
    const { modalActions, modalConsts, sortingUtils } = this.props
    const { filters } = this.state
    const { sortField, sortOrder } = sortingUtils

    modalActions.show(modalConsts.TYPES.EXPORT_REPORT, {
      filters: { ...filters, type: 'key-person' },
      keyWorkers: true,
      params: {
        groups: SEND_REPORT_GROUPS,
        order: { sortField, sortOrder },
      },
      title: 'Export your Key Person list',
      userLabel: 'Select a manager or administrator to send the export to',
    })
  }

  render() {
    const { childListState: { isFetching, meta }, paginationUtils, reports, sortingUtils } = this.props
    const { filters: { keyWorker } } = this.state
    const { getPageCount, page, perPage } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const pageCount = getPageCount(meta.total_results)

    return (
      <KeyPersonsReportView
        columns={KEY_PERSONS_REPORTS_LIST_COLUMNS}
        isLoading={isFetching}
        keyWorker={keyWorker}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        reports={reports}
        sortField={sortField}
        sortOrder={sortOrder}
        totalResults={meta.total_results}
        onAgeChange={this.handleAgeChange}
        onKeyWorkerChange={this.handleKeyWorkerChange}
        onPageChange={this.handlePageChange}
        onSendReport={this.handleSendReport}
        onTableSortChange={this.handleSortChange}
      />
    )
  }
}

const mapState = (state, { childSelectors }) => ({
  currentUser: getAuthUser(state),
  reports: childSelectors.getChildReportTableListsSelector(state),
})

const enhance = compose(
  withRouter,
  withChildService,
  withPaginationUtils,
  withRouterUtils,
  withSortingUtils,
  withModalService,
  connect(mapState),
)

KeyPersonsReportContainer.authParams = {
  flags: [FEATURE_FLAGS.LJ_REPORTS],
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

export default enhance(KeyPersonsReportContainer)
