import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as filesSelectors from './selectors'

const withFilesService = (WrappedComponent) => {
  const mapState = ({ files }) => ({ files })

  const mapDispatch = injectActions('filesActions', actions)

  const Component = ({ filesActions, ...others }) => (
    <WrappedComponent
      filesActions={filesActions}
      filesSelectors={filesSelectors}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withFilesService
