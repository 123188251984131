import _ from 'lodash'
import moment from 'moment'

import React, { useEffect, useMemo } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'
import { change, getFormValues, stopSubmit } from 'redux-form'

import { ModalType } from 'modals'

import { scrollToErrorFields } from 'utils/dom'
import { generateRoute } from 'utils/routing'
import { getBackendErrors } from 'utils/backendErrors'

import { withAppService, withAppServiceProps } from 'services/app'
import { withShellService, withShellServiceProps } from 'services/shell'
import { withNurseryItemService, withNurseryItemServiceProps } from 'services/product/nurseryItem'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withRouter, withRouterProps } from 'services/router'

import i18n from 'translations'

import { ITEM_ADD_FORM, ItemAddFormValues } from './components/ItemAddForm/ItemAddForm'
import ManagementItemAddView from './ManagementItemAddView'

const mapDispatch = {
  changeField: (field, value) => change(ITEM_ADD_FORM, field, value),
  injectValidation: (formName, data) => stopSubmit(formName, data),
}

const mapState = (state, {
  appSelectors,
  nurseryItemSelectors,
  nurseryItemSingleState,
}) => ({
  errorMessages: appSelectors.getErrorMessages(nurseryItemSingleState),
  formValues: getFormValues(ITEM_ADD_FORM)(state) as ItemAddFormValues,
  isFetching: appSelectors.getIsFetching(nurseryItemSingleState),
  isSubmitting: appSelectors.getIsSubmitting(nurseryItemSingleState),
  nurseryItem: nurseryItemSelectors.getNurseryItemSingleDataState(state),
})

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type ManagementItemAddContainerProps = PropsFromRedux
  & withAppServiceProps
  & withRouterProps
  & withShellServiceProps
  & withNurseryItemServiceProps
  & withModalServiceProps

const NURSERY_ITEM_GROUPS = {
  read: [
    'productPrice',
    'productPriceChange',
    'productPriceChange.prices',
    'nurseryItemProduct.priceChanges',
  ],
}

const ManagementItemAddContainer: React.FC<ManagementItemAddContainerProps> = ({
  changeField,
  errorMessages,
  formValues,
  injectValidation,
  isFetching,
  isSubmitting,
  location,
  modalActions,
  nurseryItem,
  nurseryItemActions,
  nurseryItemSelectors,
  params,
  router,
  shellActions,
}) => {
  const { pathname } = location
  const isEdit = pathname === generateRoute('MANAGEMENT.FINANCE_SETTINGS.ITEMS.EDIT', {
    itemId: params.itemId,
  })

  useEffect(() => {
    shellActions.setRouteTitle({
      name: 'MANAGEMENT.FINANCE_SETTINGS.ITEMS',
      title: i18n.t('module:Management:Finance:Item:title'),
    })
  }, [])

  useEffect(() => {
    if (isEdit) {
      nurseryItemActions.get({
        params: [+params.itemId, {
          groups: NURSERY_ITEM_GROUPS,
        }],
      })
    }
  }, [isEdit])

  const handleSubmitFailed = (response) => {
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    const finalErrors = { ...errors } as any

    _.each(errors?.priceChanges, (priceChange, i) => {
      if (_.isString(priceChange)) {
        finalErrors.priceChanges[i] = {
          startDate: priceChange,
        }
      }
    })

    scrollToErrorFields()

    return injectValidation(ITEM_ADD_FORM, finalErrors)
  }

  const handleSubmitSuccess = () => {
    router.replace(generateRoute('MANAGEMENT.FINANCE_SETTINGS.ITEMS'))
  }

  const handleChangeStartDate = (value, index) => {
    changeField(`priceChanges[${index - 1}].endDate`, moment(value).subtract(1, 'days'))
  }

  const handleRemovePriceChangeRow = (i) => {
    const newPriceChanges = [...formValues?.priceChanges || []]
    newPriceChanges.splice(i, 1)

    if (1 === newPriceChanges?.length) {
      changeField('priceChanges[0].endDate', undefined)
    }

    changeField(`priceChanges[${newPriceChanges.length - 1}].endDate`, null)

    _.each(_.reverse(newPriceChanges), (priceChange, index) => {
      if (priceChange?.startDate) {
        changeField(
          `priceChanges[${newPriceChanges.length - 2 - index}].endDate`,
          moment(priceChange.startDate).subtract(1, 'days'),
        )
      }
    })
  }

  const handleSubmit = (values: ItemAddFormValues) => {
    const body = nurseryItemSelectors.getBody(values)

    if (isEdit) {
      nurseryItemActions.update({
        body,
        onFailed: handleSubmitFailed,
        onSuccess: handleSubmitSuccess,
        params: [+params.itemId],
      })

      return
    }

    nurseryItemActions.create({
      body,
      onFailed: handleSubmitFailed,
      onSuccess: handleSubmitSuccess,
    })
  }

  const handleArchiveItem = (archived) => {
    nurseryItemActions.update({
      body: { archived: !archived },
      onFailed: handleSubmitFailed,
      onSuccess: handleSubmitSuccess,
      params: [+params.itemId],
    })
  }

  const handleArchiveClick = (archived) => {
    const label = archived ? _.lowerCase(i18n.t('global:Unarchive')) : _.lowerCase(i18n.t('global:Archive'))

    modalActions.show<ModalType.CONFIRM>(ModalType.CONFIRM, {
      confirmButtonLabel: label,
      icon: archived ? 'unarchive' : 'archive',
      onConfirm: () => handleArchiveItem(archived),
      text: (
        <div>
          {i18n.t('module:Management:Finance:Item:Add:archiveText1', { label })}
          <br />
          {i18n.t('module:Management:Finance:Item:Add:archiveText2', { label })}
        </div>
      ),
    })
  }

  const initialValues = useMemo(() => {
    if (isEdit) {
      return nurseryItemSelectors.getInitialValues(nurseryItem)
    }

    return {
      priceChanges: [{
        prices: [{
          price: 0,
        }],
      }],
    }
  }, [isEdit, nurseryItem])

  const isArchived = useMemo<boolean>(() => nurseryItem?.archived, [nurseryItem])

  return (
    <ManagementItemAddView
      errorMessages={errorMessages}
      initialValues={initialValues}
      isArchived={isArchived}
      isEdit={isEdit}
      isFetching={isFetching && isEdit}
      isSubmitting={isSubmitting}
      onArchiveClick={handleArchiveClick}
      onChangeStartDate={handleChangeStartDate}
      onRemovePriceChangeRow={handleRemovePriceChangeRow}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withAppService,
  withNurseryItemService,
  withRouter,
  withShellService,
  withModalService,
  connector,
)

export default enhance(ManagementItemAddContainer)
