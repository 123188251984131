import React from 'react'

import { Callout, Page, Spinner, StagesIndicator } from 'components'

import i18n from 'translations'

import NurseryStage from './components/NurseryStage'
import PrimaryContactStage from './components/PrimaryContactStage'
import ReviewStage from './components/ReviewStage'

const NurseriesAddView = ({
  currentStage,
  errorMessages,
  formValues,
  highestAvailableStage,
  initialValues,
  isAdministrationContext,
  isFetching,
  isOrganizationContext,
  isStaffingEnabled,
  isSubmitting,
  membership,
  onExistingPrimaryContactSelect,
  onNurseryNameChange,
  onNurserySubmit,
  onPreviousStageClick,
  onPrimaryContactNameChange,
  onPrimaryContactSubmit,
  onStageChange,
  onSubmit,
  organizationSuffix,
  prefix,
  suffix,
  titlesOptions,
}) => {
  const { existingPrimaryContact, newPrimaryContact, nursery } = formValues || {}

  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    switch (currentStage) {
      case 1:
        return (
          <NurseryStage
            initialValues={initialValues}
            isAdministrationContext={isAdministrationContext}
            isLoading={isSubmitting}
            isOrganizationContext={isOrganizationContext}
            prefix={prefix}
            suffix={suffix}
            onNameChange={onNurseryNameChange}
            onSubmit={onNurserySubmit}
          />
        )
      case 2:
        return (
          <PrimaryContactStage
            existingPrimaryContact={existingPrimaryContact}
            isLoading={isSubmitting}
            isStaffingEnabled={isStaffingEnabled}
            showPrimaryContactsDropdown={isOrganizationContext}
            titlesOptions={titlesOptions}
            onExistingPrimaryContactSelect={onExistingPrimaryContactSelect}
            onNameChange={onPrimaryContactNameChange}
            onPreviousStageClick={onPreviousStageClick}
            onSubmit={onPrimaryContactSubmit}
          />
        )
      case 3:
        return (
          <ReviewStage
            isStaffingEnabled={isStaffingEnabled}
            isSubmitting={isSubmitting}
            nursery={nursery}
            organizationSuffix={organizationSuffix}
            primaryContact={existingPrimaryContact ? membership : newPrimaryContact}
            onPreviousStageClick={onPreviousStageClick}
            onSubmit={onSubmit}
          />
        )
      default:
        return null
    }
  }

  const renderStagesIndicator = () => (
    <StagesIndicator
      currentStage={currentStage}
      highestAvailableStage={highestAvailableStage}
      stages={[
        { label: i18n.t('module:Nurseries:Add:NurseryDetails:title') },
        { label: i18n.t('module:Nurseries:Add:PrimaryContactDetails:title') },
        { label: i18n.t('module:Nurseries:Add:Review:title') },
      ]}
      onStageChange={onStageChange}
    />
  )

  return (
    <Page.Section
      isLoading={isFetching}
      title={i18n.t('module:Nurseries:Add:title')}
    >
      <Callout content={errorMessages} error />
      {renderStagesIndicator()}
      {renderContent()}
    </Page.Section>
  )
}

export default NurseriesAddView
