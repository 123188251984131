import { generateBasicActions } from 'utils/service'

import {
  CLEAR_NURSERY_ACADEMIC_YEAR,
  CREATE_NURSERY_ACADEMIC_YEAR,
  CREATE_NURSERY_ACADEMIC_YEAR_FAILED,
  CREATE_NURSERY_ACADEMIC_YEAR_SUCCESS,
  GET_NURSERY_ACADEMIC_YEAR,
  GET_NURSERY_ACADEMIC_YEAR_FAILED,
  GET_NURSERY_ACADEMIC_YEAR_SUCCESS,
  REMOVE_NURSERY_ACADEMIC_YEAR,
  REMOVE_NURSERY_ACADEMIC_YEAR_FAILED,
  REMOVE_NURSERY_ACADEMIC_YEAR_SUCCESS,
  UPDATE_NURSERY_ACADEMIC_YEAR,
  UPDATE_NURSERY_ACADEMIC_YEAR_FAILED,
  UPDATE_NURSERY_ACADEMIC_YEAR_SUCCESS,
} from './constants'

export const get = (options = {}) => ({ nurseryAcademicYearsApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: GET_NURSERY_ACADEMIC_YEAR_FAILED,
      init: GET_NURSERY_ACADEMIC_YEAR,
      success: GET_NURSERY_ACADEMIC_YEAR_SUCCESS,
    },
    options,
    service: nurseryAcademicYearsApiClient,
    serviceMethod: 'get',
  })
)

export const create = (options = {}) => ({ nurseryAcademicYearsApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: CREATE_NURSERY_ACADEMIC_YEAR_FAILED,
      init: CREATE_NURSERY_ACADEMIC_YEAR,
      success: CREATE_NURSERY_ACADEMIC_YEAR_SUCCESS,
    },
    options,
    service: nurseryAcademicYearsApiClient,
    serviceMethod: 'create',
  })
)

export const update = (options = {}) => ({ nurseryAcademicYearsApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_NURSERY_ACADEMIC_YEAR_FAILED,
      init: UPDATE_NURSERY_ACADEMIC_YEAR,
      success: UPDATE_NURSERY_ACADEMIC_YEAR_SUCCESS,
    },
    options,
    service: nurseryAcademicYearsApiClient,
    serviceMethod: 'update',
  })
)

export const remove = (options = {}) => ({ nurseryAcademicYearsApiClient }) => (
  generateBasicActions.remove({
    constants: {
      failed: REMOVE_NURSERY_ACADEMIC_YEAR_FAILED,
      init: REMOVE_NURSERY_ACADEMIC_YEAR,
      success: REMOVE_NURSERY_ACADEMIC_YEAR_SUCCESS,
    },
    options,
    service: nurseryAcademicYearsApiClient,
    serviceMethod: 'remove',
  })
)

export const clear = () => ({
  type: CLEAR_NURSERY_ACADEMIC_YEAR,
})
