import _ from 'lodash'

import { TableColumn } from 'components/Table/Table'

import i18n from 'translations'

export const TABLE_COLUMNS_FIRST_PART: TableColumn[] = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'child',
    title: _.upperFirst(i18n.t('global:child')),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'codeReference',
    title: _.upperFirst(i18n.t('global:Child:childReferenceCode')),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'paymentRunDate',
    title: i18n.t('module:Finance:Reports:FailedRecurringPayment:Table:Header:PaymentRunDate:label'),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'amount',
    title: _.upperFirst(i18n.t('global:amount')),
  },
]

export const TABLE_COLUMNS_SECOND_PART: TableColumn[] = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'paymentMethod',
    title: i18n.t('module:Finance:Reports:FailedRecurringPayment:Table:Header:PaymentMethod:label'),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'accountHolder',
    title: i18n.t('module:Finance:Reports:FailedRecurringPayment:Table:Header:AccountHolder:label'),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'failedReason',
    title: i18n.t('module:Finance:Reports:FailedRecurringPayment:Table:Header:FailedReason:label'),
  },
]

export const NURSERY_TABLE_COLUMNS: TableColumn[] = [
  ...TABLE_COLUMNS_FIRST_PART,
  ...TABLE_COLUMNS_SECOND_PART,
]

export const ORGANIZATION_TABLE_COLUMNS: TableColumn[] = [
  ...TABLE_COLUMNS_FIRST_PART,
  {
    align: 'left',
    alignTh: 'left',
    field: 'nursery',
    title: i18n.t('module:Finance:Reports:FailedRecurringPayment:Table:Header:Site:label'),
  },
  ...TABLE_COLUMNS_SECOND_PART,
]
