import { BasicListInitialState, getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_STRIPE_INTEGRATION,
  CREATE_STRIPE_INTEGRATION,
  CREATE_STRIPE_INTEGRATION_FAILED,
  CREATE_STRIPE_INTEGRATION_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export interface NurseryIntegrationsStripeState extends BasicListInitialState {}

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_STRIPE_INTEGRATION,
    createFailed: CREATE_STRIPE_INTEGRATION_FAILED,
    createInit: CREATE_STRIPE_INTEGRATION,
    createSuccess: CREATE_STRIPE_INTEGRATION_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
