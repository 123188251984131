import { NurseryFundingProduct } from 'services/product/nurseryFundingV3/constants'
import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_NURSERY_FUNDING_SINGLE_V3,
  CREATE_NURSERY_FUNDING_V3,
  CREATE_NURSERY_FUNDING_V3_FAILED,
  CREATE_NURSERY_FUNDING_V3_SUCCESS,
  GET_NURSERY_FUNDING_V3,
  GET_NURSERY_FUNDING_V3_FAILED,
  GET_NURSERY_FUNDING_V3_SUCCESS,
  UPDATE_NURSERY_FUNDING_V3,
  UPDATE_NURSERY_FUNDING_V3_FAILED,
  UPDATE_NURSERY_FUNDING_V3_SUCCESS,
} from './constants'

export const get = (options: BasicActionOptionsProps) => ({ nurseryFundingV3ApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: GET_NURSERY_FUNDING_V3_FAILED,
      init: GET_NURSERY_FUNDING_V3,
      success: GET_NURSERY_FUNDING_V3_SUCCESS,
    },
    options,
    service: nurseryFundingV3ApiClient,
    serviceMethod: 'get',
  })
)

export const create = (
  options: BasicActionOptionsProps<NurseryFundingProduct>,
) => ({ nurseryFundingV3ApiClient }) => generateBasicActions.create({
  constants: {
    failed: CREATE_NURSERY_FUNDING_V3_FAILED,
    init: CREATE_NURSERY_FUNDING_V3,
    success: CREATE_NURSERY_FUNDING_V3_SUCCESS,
  },
  options,
  service: nurseryFundingV3ApiClient,
  serviceMethod: 'create',
})

export const update = (
  options: BasicActionOptionsProps<NurseryFundingProduct>,
) => ({ nurseryFundingV3ApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_NURSERY_FUNDING_V3_FAILED,
    init: UPDATE_NURSERY_FUNDING_V3,
    success: UPDATE_NURSERY_FUNDING_V3_SUCCESS,
  },
  options,
  service: nurseryFundingV3ApiClient,
  serviceMethod: 'update',
})

export const clear = () => ({
  type: CLEAR_NURSERY_FUNDING_SINGLE_V3,
})
