import React, { PropsWithChildren } from 'react'

import colors from 'constants/colors'

import i18n from 'translations'

import { StyledContainer, StyledCount, StyledText } from './MaxCharactersTextStyled'

interface MaxCharactersTextProps {
  charactersCount: number
  maxCharacters: number
}

const MaxCharactersText: React.FC<PropsWithChildren<MaxCharactersTextProps>> = ({
  charactersCount = 0,
  maxCharacters = 0,
}) => {
  const remainingCharacters = maxCharacters - charactersCount

  const getColor = () => {
    if (!remainingCharacters || 95 <= (100 * charactersCount) / maxCharacters) {
      return colors.remove
    }

    return null
  }

  return (
    <StyledContainer>
      <StyledText>
        <StyledCount $color={getColor()}>
          {remainingCharacters}
        </StyledCount>
        {i18n.t('components:MaxCharactersText:remaining')}
      </StyledText>
    </StyledContainer>
  )
}

export default MaxCharactersText
