import _ from 'lodash'
import moment from 'moment'

import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { useInView } from 'react-intersection-observer'

import { SHIFT_MAIN_TYPES, SHIFT_MAIN_TYPES_LABELS } from 'services/legacy/membershipRegisters/constants'
import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { withMembershipsShiftsService } from 'services/legacy/membershipsShifts'

import { Spinner } from 'components'
import UserTableRowSummary from 'module/Staff/components/UserTableRowSummary'

import { StyledTimeWork } from './TimeStatisticsStyled'

const GROUPS = {
  read: [
    'membershipShift.dayMembershipShiftTimeStatistics',
  ],
}

const TimeStatistics = ({
  date,
  membership,
  membershipsShiftsActions,
  membershipsShiftsSelectors,
}) => {
  const [statistics, setStatistics] = useState(null)
  const [statisticsDownloaded, setStatisticsDownloaded] = useState(false)
  const [ref, inView] = useInView({
    threshold: 0,
  })

  useEffect(() => {
    if (inView && !statisticsDownloaded) {
      setStatisticsDownloaded(true)

      const criteria = membershipsShiftsSelectors.getListCriteria({
        dateFrom: moment(date).startOf('week').format(DEFAULT_DATE_FORMAT),
        dateTo: moment(date).endOf('week').format(DEFAULT_DATE_FORMAT),
        membership,
        membershipShiftTimesExists: true,
        type: SHIFT_MAIN_TYPES.WORK,
      })

      membershipsShiftsActions.list({
        onSuccess: (response) => {
          const { data } = response

          const shiftItem = data?.[0]?.shifts?.[0]
          const result = _.values(shiftItem?.dayMembershipShiftTimeStatistics)
          const times = _.sumBy(result, ({ break: breakTime = 0, contracted = 0, overtime = 0 }) => (
            contracted + overtime - breakTime
          ))

          setStatistics(times)
        },
        onlyData: true,
        params: {
          criteria,
          groups: GROUPS,
        },
      })
    }
  }, [date, inView, membership, membershipsShiftsActions, membershipsShiftsSelectors, statisticsDownloaded])

  if (_.isNull(statistics)) {
    return (
      <div ref={ref}>
        <Spinner
          position="flex-start"
          size="small"
        />
      </div>
    )
  }

  return (
    <StyledTimeWork>
      <UserTableRowSummary
        label={SHIFT_MAIN_TYPES_LABELS[SHIFT_MAIN_TYPES.WORK]}
        time={statistics}
        type={SHIFT_MAIN_TYPES.WORK}
      />
    </StyledTimeWork>
  )
}

const enhance = compose(
  withMembershipsShiftsService,
)

export default enhance(TimeStatistics)
