import styled from 'styled-components'

import layout from 'constants/layout'

import { StyledBoxContainer } from 'components/Box/BoxStyled'

import tabletImage from 'assets/images/tablet.png'

export const StyledContainer = styled.div`
  display: flex;
  min-height: calc(100vh - 60px);
`

export const StyledLeftItemContainer = styled.div`
  flex: 1;
`

export const StyledRightItemContainer = styled.div`
  flex: 1;
  display: block;

  @media(max-width: 1024px) {
    display: none
  }
`

export const StyledContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 350px;
  margin: 15px auto;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    margin: 15px auto;
  }
`

export const StyledBox = styled.div`
  ${StyledBoxContainer} {
    border-radius: 12px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .12);
    
    & > div {
      border-radius: 12px;
      min-height: 400px;
    }
  }
`

export const StyledImage = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(${tabletImage});
  background-position: 50% center;
  background-repeat: no-repeat; 
  background-size: cover;
`

export const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
