import { createSelector } from 'reselect'

import { getFilesInitialValues } from 'services/legacy/files/selectors'

const getMembershipsState = (state) => state.memberships

export const getMembershipFilesList = createSelector([getMembershipsState], (state) => state.files)

export const getMembershipFilesListData = createSelector([getMembershipFilesList], (state) => state?.data)

export const getMembershipFilesInitialValuesSelector = createSelector(
  [getMembershipFilesListData],
  getFilesInitialValues,
)
