import React from 'react'

const split = (lines) => lines
  .filter((line) => null !== line)
  .join('\n')
  .split(/\n/g)

const FormatText = ({ lines }) => (
  <span>
    {split(lines).map((line, i) => (
      <span key={i}>
        {line}
        <br />
      </span>
    ))}
  </span>
)

export default FormatText
