import { createSelector } from 'reselect'

const getNewsletterRecipients = (state) => state.newsletters

export const getNewsletterRecipientsSingleSelector = createSelector(
  [getNewsletterRecipients],
  (state) => state?.single,
)

export const getSingleNewsletterRecipientsData = createSelector(
  [getNewsletterRecipientsSingleSelector],
  (state) => state.data,
)
