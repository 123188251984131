import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { Button, Form, Space } from 'components'

import i18n from 'translations'

export const LOGIN_FORM = 'LOGIN_FORM'

interface LoginFormProps {
  isFetching?: boolean
  onSubmit: (values: any) => void
}

type LoginFormFullProps = InjectedFormProps<{}, LoginFormProps> & LoginFormProps

export interface LoginFormValues {
  identifier: string
  password: string
}

const LoginForm: React.FC<LoginFormFullProps> = ({
  handleSubmit,
  isFetching,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Space space="20px" />
    <Form.Row margin="0 0 10px" width={{ field: '100%' }}>
      <Field
        component={Form.TextField}
        disabled={isFetching}
        name="identifier"
        placeholder={i18n.t('module:Authentication:Login:identifier')}
        validate={isRequired}
      />
    </Form.Row>
    <Form.Row margin="0 0 10px" width={{ field: '100%' }}>
      <Field
        component={Form.TextField}
        disabled={isFetching}
        name="password"
        placeholder={i18n.t('module:Authentication:Login:password')}
        type="password"
        validate={isRequired}
      />
    </Form.Row>
    <Button
      isLoading={isFetching}
      label={i18n.t('module:Authentication:Login:logIn')}
      fullWidth
      submit
    />
  </Form>
)

export default reduxForm<{}, LoginFormProps>({ form: LOGIN_FORM })(LoginForm)
