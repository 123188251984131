import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as occupancySelectors from './selectors'

const withOccupancyService = (WrappedComponent) => {
  const mapState = ({ occupancy }) => ({ occupancy })

  const mapDispatch = injectActions('occupancyActions', actions)

  const Component = ({ occupancy, occupancyActions, ...others }) => (
    <WrappedComponent
      occupancyActions={occupancyActions}
      occupancyReportState={occupancy.report}
      occupancySelectors={occupancySelectors}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withOccupancyService
