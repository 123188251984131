import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change, getFormValues, reset, stopSubmit } from 'redux-form'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import {
  AVAILABLE_DAYS,
  PLANNED_TYPE_OPTIONS,
  SHIFT_MAIN_TYPES,
  WORK_SHIFT_TYPES,
} from 'services/legacy/membershipRegisters/constants'
import { RATIO_OPTIONS, RATIO_VALUES } from 'services/legacy/membershipsShifts/constants'

import { getBackendErrors } from 'utils/backendErrors'
import { addMillisecondsFromMidnight, convertTimeDuration, isSameDay } from 'utils/date'

import { withAppService } from 'services/app'
import { withRoomsService } from 'services/legacy/rooms'
import { withContractsService } from 'services/legacy/contracts'
import { withMembershipsShiftsService } from 'services/legacy/membershipsShifts'
import { withMembershipsLeavesService } from 'services/legacy/membershipsLeaves'
import { withModalService } from 'services/utils/modal'
import { withMembershipRegistersService } from 'services/legacy/membershipRegisters'
import { parseCurrentRegisters } from 'services/legacy/membershipRegisters/list/selectors'

import ShiftModalView from './ShiftModalView'
import { STAFF_ROTA_SHIFT_MODAL_FORM } from './components/ShiftModalForm/ShiftModalForm'
import { prepareBackendErrors } from '../StaffModalHelper'

const GROUPS_FOR_LEAVES = {
  read: [
    'membershipShift.membershipShiftTimeStatistics',
    'membershipShift.membershipShiftTimes',
    'membershipShiftSettings',
    'membershipShiftTime',
    'membershipShiftTime.class',
    'membership.details',
    'membershipShift.leaveShiftType',
    'leaveShiftType',
  ],
}

const GROUPS_FOR_LIST = {
  read: [
    'membershipShift.membershipShiftTimes',
    'membershipShiftTime',
    'membershipShiftTime.class',
    'nurseryClass',
    'membership.profile',
    'membership.details',
  ],
}

export const MEMBERSHIP_REGISTERS_GROUP = {
  read: [
    'membershipRegisterEntry.nurseryClass',
    'nurseryClass',
  ],
}

const STAFF_CONTRACT_GROUP = {
  read: [
    'contract.breakAllowances',
    'breakAllowance',
  ],
}

export const SHIFT_MODAL_MODE = {
  ACTUAL: 'actual',
  PLANNED: 'planned',
}

export const DEFAULT_STATE_VALUES = {
  currentRegisters: null,
  isEditMode: false,
  memberDetails: null,
  shiftId: null,
  shiftTimesErrors: null,
  statistics: null,
}

class ShiftModalContainer extends Component {
  constructor(props) {
    super(props)

    const { attendanceView, initialValues } = this.props

    const date = [moment().startOf('week'), moment().endOf('week')]

    this.state = {
      currentRegisters: null,
      date,
      formErrors: null,
      isEditMode: false,
      isFetching: !!initialValues,
      isFetchingStatistics: false,
      isQualifiedExpert: false,
      memberDetails: null,
      membership: null,
      mode: attendanceView ? SHIFT_MODAL_MODE.ACTUAL : SHIFT_MODAL_MODE.PLANNED,
      shiftId: null,
      shiftTimesErrors: null,
      statistics: null,
    }
  }

  componentDidMount() {
    const { initialValues, roomsActions } = this.props
    const { mode } = this.state

    if (initialValues) {
      const { date, membership } = initialValues

      this.setState({
        date,
        membership,
      })
      this.handleChangeMainField({ date, membership, mode })
    }

    const apiParams = {
      criteria: [{
        field: 'archived',
        value: 0,
      }],
    }

    roomsActions.listRecurrency(apiParams)
  }

  componentWillUnmount() {
    const {
      contractsActions,
      membershipsLeavesActions,
      membershipsShiftsActions,
      resetForm,
    } = this.props

    contractsActions.clearList()
    membershipsShiftsActions.clearSingle()
    membershipsLeavesActions.clearList()
    resetForm()
  }

  getMembershipContracts = (membershipId, initialDate, page) => {
    const { contractsActions, contractsSelectors } = this.props
    const { date } = this.state
    const finalDate = initialDate || date

    const criteria = contractsSelectors.getListCriteria({
      endDate: moment(finalDate?.[1]).format(DEFAULT_DATE_FORMAT),
      membership: membershipId,
      startDate: moment(finalDate?.[0]).format(DEFAULT_DATE_FORMAT),
    })

    contractsActions.list({
      mergeResult: 1 !== page,
      params: { criteria, groups: STAFF_CONTRACT_GROUP, page },
    })
  }

  prepareErrors = (errors) => {
    const newErrors = _.cloneDeep(errors)

    if (!_.isString(newErrors?.membershipShiftTimes)) {
      delete newErrors?.membershipShiftTimes
    }

    return newErrors
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSubmitFailed = (response, payload, values) => {
    const { injectValidation } = this.props
    const { membershipShiftTimes } = values

    const errorsForForm = prepareBackendErrors(response, membershipShiftTimes)
    const backendErrors = getBackendErrors(response)?.membershipShiftTimes

    let shiftTimesErrors = _.map(payload?.membershipShiftTimes, (item, index) => {
      const newItem = { ...item }

      if (backendErrors?.[index]) {
        newItem.errors = backendErrors[index]
      }

      return newItem
    })

    shiftTimesErrors = _.filter(shiftTimesErrors, (e) => e.errors)
    shiftTimesErrors = _.groupBy(shiftTimesErrors, 'day')
    shiftTimesErrors = _.mapValues(shiftTimesErrors, (e) => (
      _.uniq(_.flatten(_.map(e, ({ errors: subError }) => _.values(subError))))))

    this.setState({
      shiftTimesErrors,
    })

    return setTimeout(() => injectValidation(errorsForForm))
  }

  handleSuccess = (copy, payload) => {
    const { onSuccess } = this.props

    if (!copy) {
      onSuccess()
      this.handleCloseClick()
    } else {
      onSuccess(copy, payload)
    }
  }

  handleSubmitActualFailed = (response) => {
    const { injectValidation } = this.props
    const { data } = response
    const shiftTimesErrors = {}
    const errors = {
      membershipShiftTimes: {},
    }

    _.each(data, ({ exception, membershipRegister }) => {
      const { date } = membershipRegister
      const recordDate = moment(date).format(DEFAULT_DATE_FORMAT)

      errors.membershipShiftTimes[recordDate] = []
      const currentError = errors.membershipShiftTimes[recordDate]
      const subErrors = getBackendErrors(exception?.error)

      if (subErrors?.entries) {
        _.each(subErrors?.entries, (groupErrors, index) => {
          const { signInAt, signOutAt, ...rest } = groupErrors || {}
          currentError[index] = {
            ...rest,
          }

          if (signOutAt) {
            currentError[index].endTime = signOutAt
          }

          if (signInAt) {
            currentError[index].startTime = signInAt
          }

          if (!shiftTimesErrors[recordDate]) {
            shiftTimesErrors[recordDate] = []
          }

          _.each(groupErrors, (value) => {
            shiftTimesErrors[recordDate].push(value)
          })

          shiftTimesErrors[recordDate] = _.uniq(shiftTimesErrors[recordDate])
        })
      }
    })

    this.setState({
      formErrors: errors,
      shiftTimesErrors,
    })

    return setTimeout(() => injectValidation(errors))
  }

  // Batch method always returns `201` status code, so we need checking manually if errors exist
  handleSubmitActualFinished = (response) => {
    const { data } = response

    if (_.find(data, ({ exception }) => exception)) {
      return this.handleSubmitActualFailed(response)
    }

    return this.handleSuccess()
  }

  handleSubmitActual = (values) => {
    const { membershipRegistersActions, membershipRegistersSelectors } = this.props
    const { currentRegisters } = this.state
    const body = membershipRegistersSelectors.getStaffAttendancePayload(values, currentRegisters)

    membershipRegistersActions.updateBatch({
      body,
      onFailed: this.handleSubmitActualFinished,
      onSuccess: this.handleSubmitActualFinished,
    })
  }

  handleSubmitPlanned = (values) => {
    const { membershipsShiftsActions, membershipsShiftsSelectors } = this.props
    const { initialValues, isEditMode, shiftId } = this.state

    const payload = membershipsShiftsSelectors.getValuesForm(values, isEditMode, initialValues)
    const copy = !!values.copy

    if (isEditMode) {
      return membershipsShiftsActions.update(shiftId, {
        onFailed: (response) => this.handleSubmitFailed(response, payload, values),
        onSuccess: () => this.handleSuccess(copy, payload),
        params: {},
        payload,
      })
    }

    return membershipsShiftsActions.create({
      onFailed: (response) => this.handleSubmitFailed(response, payload, values),
      onSuccess: () => this.handleSuccess(copy, payload),
      params: {},
      payload,
    })
  }

  handleSubmit = (values) => {
    const { date, membership, mode } = this.state
    const newValues = {
      ...values,
      date,
      membership,
    }

    if (mode === SHIFT_MODAL_MODE.ACTUAL) {
      this.handleSubmitActual(newValues)
    }

    if (mode === SHIFT_MODAL_MODE.PLANNED) {
      this.handleSubmitPlanned(newValues)
    }
  }

  handleChangeField = (fieldName, value) => {
    const { changeFieldValue } = this.props

    changeFieldValue(fieldName, value)
  }

  getMembershipRecords = (times, date, result = () => [{}]) => _.mapValues(
    _.mapKeys(
      _.times(AVAILABLE_DAYS, (index) => moment(date).add(index, 'day').format(DEFAULT_DATE_FORMAT)),
      (value) => value,
    ),
    result,
  )

  handleChangeDate = (date) => {
    const { membership, mode } = this.state

    this.setState({ date, isFetching: true, isFetchingStatistics: true })
    this.handleChangeMainField({ date, membership, mode })
  }

  handleChangeType = (type, date, index) => {
    const { formValues } = this.props
    const { mode } = this.state

    const { membershipShiftTimes = {} } = formValues || {}

    if (WORK_SHIFT_TYPES.BREAK === type?.value) {
      delete membershipShiftTimes?.[date]?.[index].location
      delete membershipShiftTimes?.[date]?.[index].ratio

      this.handleChangeField('membershipShiftTimes', membershipShiftTimes)

      return null
    }

    if (
      mode === SHIFT_MODAL_MODE.PLANNED
      && (
        WORK_SHIFT_TYPES.OVERTIME === type?.value
        || WORK_SHIFT_TYPES.CONTRACTED === type?.value
      )
    ) {
      if (!membershipShiftTimes?.[date]?.[index].ratio) {
        membershipShiftTimes[date][index].ratio = (
          _.find(RATIO_OPTIONS, ({ value }) => RATIO_VALUES.STANDARD === value)
        )
      }

      this.handleChangeField('membershipShiftTimes', membershipShiftTimes)

      return null
    }

    return type
  }

  handleCopyToAll = (dayShifts) => {
    const { formValues: { membershipShiftTimes } } = this.props

    _.each(membershipShiftTimes, (items, date) => {
      const newDayShifts = _.map(dayShifts, (item) => {
        const newItem = { ...item }
        delete newItem.id

        return newItem
      })

      membershipShiftTimes[date] = _.cloneDeep(newDayShifts)
    })

    this.handleChangeField('membershipShiftTimes', membershipShiftTimes)
  }

  handleDeleteAll = () => {
    const { date } = this.state

    this.handleChangeField('membershipShiftTimes', this.getMembershipRecords(AVAILABLE_DAYS, date[0]))
  }

  getActualRecordsSuccess = ({ asStatistic, date: dateFormValue }) => ({ data }) => {
    const actualData = data?.[0]
    const { currentRegisters, id: membershipId, name } = actualData || {}

    const records = this.getMembershipRecords(AVAILABLE_DAYS, dateFormValue[0], () => [])
    const newStateValues = {
      memberDetails: { id: membershipId, name },
    }

    if (currentRegisters?.length && !asStatistic) {
      newStateValues.currentRegisters = currentRegisters
      newStateValues.isEditMode = true
    }

    _.each(currentRegisters, ({ date, entries, ongoing }) => {
      _.each(entries, ({ id, nurseryClass, signInAt, signOutAt, type }) => {
        const item = {
          date,
          endTime: signOutAt ? addMillisecondsFromMidnight(signOutAt) : null,
          id,
          ongoing,
          startTime: signInAt ? addMillisecondsFromMidnight(signInAt) : null,
          type,
        }

        const isSameDate = isSameDay(moment(signOutAt), moment(signInAt))
        const dayDiff = moment(signOutAt).startOf('day').diff(moment(signInAt).startOf('day'), 'days')

        if (!isSameDate && signOutAt) {
          item.endDateInFuture = true
          item.endTime = moment(date).add(dayDiff, 'days')
            .set('hours', moment(signOutAt).format('HH'))
            .set('minutes', moment(signOutAt).format('mm'))
        }

        if (nurseryClass) {
          item.location = {
            label: nurseryClass?.name,
            value: nurseryClass?.id,
          }
        }

        if (records[moment(date).format(DEFAULT_DATE_FORMAT)]) {
          records[moment(date).format(DEFAULT_DATE_FORMAT)].push(item)
        }
      })
    })

    if (asStatistic) {
      return this.setState({
        ...newStateValues,
        isFetchingStatistics: false,
        statistics: records,
      })
    }

    _.times(AVAILABLE_DAYS, (index) => {
      const dateItem = moment(dateFormValue[0]).add(index, 'day').format(DEFAULT_DATE_FORMAT)

      if (!records[dateItem]?.length) {
        records[dateItem] = [{}]
      }
    })

    return this.setState({
      ...DEFAULT_STATE_VALUES,
      ...newStateValues,
      initialValues: {
        membershipShiftTimes: records,
      },
      isFetching: false,
    })
  }

  getActualRecords = ({ asStatistic, date, membership }) => {
    const { membershipRegistersActions, membershipRegistersSelectors } = this.props

    const criteria = membershipRegistersSelectors.getMembershipRegistersListCriteria({
      endDate: moment(date[0]).endOf('week').format(DEFAULT_DATE_FORMAT),
      id: membership.value || membership,
      startDate: moment(date[1]).startOf('week').format(DEFAULT_DATE_FORMAT),
    })

    return membershipRegistersActions.list({
      onSuccess: this.getActualRecordsSuccess({ asStatistic, date }),
      onlyData: true,
      params: {
        criteria,
        groups: MEMBERSHIP_REGISTERS_GROUP,
      },
    })
  }

  getLeavesRecords = ({ date, membership }) => {
    const { membershipsLeavesActions, membershipsShiftsSelectors } = this.props

    const criteriaForLeaves = membershipsShiftsSelectors.getListCriteria({
      dateFrom: date[0].format(DEFAULT_DATE_FORMAT),
      dateTo: date[1].format(DEFAULT_DATE_FORMAT),
      membership: membership.value || membership,
    })

    membershipsLeavesActions.list({
      params: {
        criteria: criteriaForLeaves,
        groups: GROUPS_FOR_LEAVES,
      },
    })
  }

  getPlannedRecordsSuccess = ({ asStatistic, date, membership }) => (response) => {
    const { data } = response

    const isQualifiedExpert = membership ? membership.membershipProfile.expert : false
    const { accepted, id: membershipId, membershipProfile, name, photo, shifts } = data?.[0] || {}
    const { id, membershipShiftTimes } = _.find(shifts, ({ type }) => SHIFT_MAIN_TYPES.WORK === type) || {}
    const records = this.getMembershipRecords(AVAILABLE_DAYS, date[0], () => [])
    const newState = {}

    if (!asStatistic) {
      newState.isQualifiedExpert = isQualifiedExpert
    }

    if (membershipId && !asStatistic) {
      newState.membership = {
        accepted,
        avatar: photo,
        label: name,
        membershipProfile,
        value: membershipId,
      }
    }

    if (id && !asStatistic) {
      newState.isEditMode = true
    }

    _.each(membershipShiftTimes, ({ class: nurseryClass, day, endTime, id: itemId, ratio, startTime, type }) => {
      const item = {
        endTime: moment(endTime).utc(),
        id: itemId,
        ratio: ratio || RATIO_VALUES.STANDARD,
        startTime: moment(startTime).utc(),
        type: _.find(PLANNED_TYPE_OPTIONS, ({ value }) => type === value),
      }

      if (type === WORK_SHIFT_TYPES.BREAK_DURATION) {
        delete item.startTime
        delete item.endTime

        item.duration = convertTimeDuration(endTime, 'milliseconds', 'minutes')
      }

      if (nurseryClass) {
        item.location = {
          label: nurseryClass?.name,
          value: nurseryClass?.id,
        }
      }

      if (!records[moment(day).format(DEFAULT_DATE_FORMAT)]?.length) {
        records[moment(day).format(DEFAULT_DATE_FORMAT)] = []
      }

      records[moment(day).format(DEFAULT_DATE_FORMAT)].push(item)
    })

    if (asStatistic) {
      return this.setState({
        ...newState,
        isFetchingStatistics: false,
        statistics: records,
      })
    }

    _.times(AVAILABLE_DAYS, (index) => {
      const dateItem = moment(date[0]).add(index, 'day').format(DEFAULT_DATE_FORMAT)

      if (!records[dateItem]?.length) {
        records[dateItem] = [{}]
      }
    })

    if (membershipShiftTimes) {
      return this.setState({
        ...DEFAULT_STATE_VALUES,
        ...newState,
        initialValues: {
          membershipShiftTimes: records,
        },
        isFetching: false,
        shiftId: id,
      })
    }

    return this.setState({
      ...DEFAULT_STATE_VALUES,
      ...newState,
      initialValues: {
        membershipShiftTimes: this.getMembershipRecords(AVAILABLE_DAYS, date[0]),
      },
      isFetching: false,
      shiftId: id,
    })
  }

  getPlannedRecords = ({ asStatistic, date, membership }) => {
    const { membershipsShiftsActions, membershipsShiftsSelectors } = this.props

    const criteria = membershipsShiftsSelectors.getListCriteria({
      dateFrom: date[0].format(DEFAULT_DATE_FORMAT),
      dateTo: date[1].format(DEFAULT_DATE_FORMAT),
      membership: membership.value || membership,
    })

    return membershipsShiftsActions.list({
      onSuccess: this.getPlannedRecordsSuccess({ asStatistic, date, membership }),
      onlyData: true,
      params: {
        criteria,
        groups: GROUPS_FOR_LIST,
      },
    })
  }

  handleChangeMainField = ({ date, membership, mode }) => {
    if (!date || !membership) {
      this.setState({
        ...DEFAULT_STATE_VALUES,
        isFetching: false,
        isFetchingStatistics: false,
      })

      return false
    }

    this.getMembershipContracts(membership?.value || membership, date, 1)

    if (mode === SHIFT_MODAL_MODE.ACTUAL) {
      this.getActualRecords({ asStatistic: false, date, membership })
      this.getPlannedRecords({ asStatistic: true, date, membership })
    }

    if (mode === SHIFT_MODAL_MODE.PLANNED) {
      this.getPlannedRecords({ asStatistic: false, date, membership })
      this.getActualRecords({ asStatistic: true, date, membership })
    }

    return this.getLeavesRecords({ date, membership })
  }

  handleChangeModalMode = (mode) => {
    const { date, membership } = this.state

    this.setState({ isFetching: true, isFetchingStatistics: true, mode })
    this.handleChangeMainField({ date, membership, mode })
  }

  handleShowChangeLog = ({ date }) => {
    const { currentRegisters, membership } = this.state

    const { modalActions, modalConsts } = this.props
    const { currentRegisters: formattedCurrentRegisters } = parseCurrentRegisters(currentRegisters)
    const record = _.find(currentRegisters, ({ date: currentRegisterDate }) => (
      moment(currentRegisterDate).format(DEFAULT_DATE_FORMAT) === date
    ))

    const worklogItems = _.filter(formattedCurrentRegisters, ({ endDate, startDate }) => (
      moment(startDate).format(DEFAULT_DATE_FORMAT) <= moment(date, DEFAULT_DATE_FORMAT).format(DEFAULT_DATE_FORMAT)
      && moment(endDate).format(DEFAULT_DATE_FORMAT) >= moment(date, DEFAULT_DATE_FORMAT).format(DEFAULT_DATE_FORMAT)
    ))
    const minDate = _.minBy(worklogItems, 'startDate')?.startDate || moment(date, DEFAULT_DATE_FORMAT)
    const startDate = moment(minDate).format(DEFAULT_DATE_FORMAT)

    modalActions.show(modalConsts.TYPES.STAFF_CHANGE_LOG, {
      membership: membership?.value,
      record,
      selectedDate: date,
      startDate,
    })
  }

  handleSetEndTime = (date, index) => {
    const { formValues } = this.props
    const { membershipShiftTimes } = formValues
    const copyShiftTimes = { ...membershipShiftTimes }
    const newDate = moment()
    const recordDate = moment(date, DEFAULT_DATE_FORMAT)
    const isFuture = 0 < newDate.diff(recordDate, 'days')

    copyShiftTimes[date][index].endTime = newDate

    if (isFuture) {
      copyShiftTimes[date][index].endDateInFuture = true
    }

    this.handleChangeField('membershipShiftTimes', copyShiftTimes)
  }

  handleChangeRecord = (date) => {
    const { injectValidation } = this.props
    const { formErrors, shiftTimesErrors } = this.state
    const updatedShiftTimesErrors = { ...shiftTimesErrors }

    if (date && updatedShiftTimesErrors[date]) {
      delete updatedShiftTimesErrors[date]
      delete formErrors?.membershipShiftTimes[date]

      setTimeout(() => injectValidation(formErrors))
      this.setState({ shiftTimesErrors: updatedShiftTimesErrors })
    }
  }

  handleChangeMembership = (membership) => {
    const { date, mode } = this.state

    this.setState({ isFetching: true, isFetchingStatistics: true, membership })
    this.handleChangeMainField({ date, membership, mode })
  }

  render() {
    const {
      attendanceView,
      contracts,
      disablePastWeek,
      formValues,
      getPreviousRecord,
      isSubmitting,
      leaves,
      membershipsShiftsErrors,
      roomsOptions,
    } = this.props
    const {
      currentRegisters,
      date,
      initialValues,
      isEditMode,
      isFetching,
      isFetchingStatistics,
      isQualifiedExpert,
      memberDetails,
      membership,
      mode,
      shiftTimesErrors,
      statistics,
    } = this.state
    const errors = this.prepareErrors(getBackendErrors(membershipsShiftsErrors || {}))

    return (
      <ShiftModalView
        attendanceView={attendanceView}
        contracts={contracts}
        currentRegisters={currentRegisters}
        date={date}
        disablePastWeek={disablePastWeek}
        errorMessages={errors}
        formValues={formValues}
        getPreviousRecord={getPreviousRecord}
        initialValues={initialValues}
        isEditMode={isEditMode}
        isFetching={isFetching || isFetchingStatistics}
        isQualifiedExpert={isQualifiedExpert}
        isSubmitting={isSubmitting}
        leaves={leaves}
        memberDetails={memberDetails}
        membership={membership}
        mode={mode}
        roomsOptions={roomsOptions}
        shiftTimesErrors={shiftTimesErrors}
        statistics={statistics}
        onChangeDate={this.handleChangeDate}
        onChangeField={this.handleChangeField}
        onChangeMembership={this.handleChangeMembership}
        onChangeModalMode={this.handleChangeModalMode}
        onChangeRecord={this.handleChangeRecord}
        onChangeType={this.handleChangeType}
        onCloseClick={this.handleCloseClick}
        onCopyToAll={this.handleCopyToAll}
        onDeleteAll={this.handleDeleteAll}
        onSetEndTime={this.handleSetEndTime}
        onShowChangeLog={this.handleShowChangeLog}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  changeFieldValue: (field, value) => change(STAFF_ROTA_SHIFT_MODAL_FORM, field, value),
  injectValidation: (data) => stopSubmit(STAFF_ROTA_SHIFT_MODAL_FORM, data),
  resetForm: () => reset(STAFF_ROTA_SHIFT_MODAL_FORM),
}

const mapState = (state, {
  appSelectors,
  contractsSelectors,
  membershipRegistersSelectors,
  membershipRegistersSingleState,
  membershipsLeavesSelectors,
  membershipsShiftsSelectors,
  membershipsShiftsSingleState,
  roomsSelectors,
}) => ({
  contracts: contractsSelectors.getContractsDataSelector(state),
  formValues: getFormValues(STAFF_ROTA_SHIFT_MODAL_FORM)(state),
  getPreviousRecord: (endDate, currentRegisters) => (
    membershipRegistersSelectors.getListWorklogByPreviousDayOngoing(endDate, currentRegisters)(state)
  ),
  isSubmitting: appSelectors.getIsSubmitting(
    membershipRegistersSingleState,
    membershipsShiftsSingleState,
  ),
  leaves: membershipsLeavesSelectors.getConcatenatedLeaveTimesGroupedByDaySelector(state),
  membershipsShiftsErrors: membershipsShiftsSelectors.getMembershipsShiftsSingleErrorSelector(state),
  roomsOptions: roomsSelectors.getRoomsOptionsDropdownData(state),
})

const enhance = compose(
  withAppService,
  withRoomsService,
  withContractsService,
  withMembershipsShiftsService,
  withMembershipsLeavesService,
  withMembershipRegistersService,
  withModalService,
  connect(mapState, mapDispatch),
)

export default enhance(ShiftModalContainer)
