import i18n from 'translations'

import ParentInboxWrapper from 'module/ParentInbox/index'
import HomeObservationsList from 'module/HomeObservations/HomeObservationsList'
import HomeObservationsDetails from 'module/HomeObservations/HomeObservationsDetails'
import RequestedExtraSessionsWrapper from 'module/RequestedExtraSessions/RequestedExtraSessionsWrapper'
import RequestedExtraSessionsList from 'module/RequestedExtraSessions/RequestedExtraSessionsList'
import RequestedExtraSessionsDetails from 'module/RequestedExtraSessions/RequestedExtraSessionsDetails'

export const ROUTE_NAMES = {
  'PARENT_INBOX.HOME_OBSERVATIONS.DETAILS': 'PARENT_INBOX.HOME_OBSERVATIONS.DETAILS',
  'PARENT_INBOX.HOME_OBSERVATIONS.LIST': 'PARENT_INBOX.HOME_OBSERVATIONS.LIST',
  'PARENT_INBOX.INDEX': 'PARENT_INBOX.INDEX',
  'PARENT_INBOX.REQUESTED_EXTRA_SESSIONS.DETAILS': 'PARENT_INBOX.REQUESTED_EXTRA_SESSIONS.DETAILS',
  'PARENT_INBOX.REQUESTED_EXTRA_SESSIONS.LIST': 'PARENT_INBOX.REQUESTED_EXTRA_SESSIONS.LIST',
}

const ParentInboxRouting = {
  childRoutes: [
    {
      childRoutes: [
        {
          component: HomeObservationsDetails,
          name: ROUTE_NAMES['PARENT_INBOX.HOME_OBSERVATIONS.DETAILS'],
          path: ':id',
          title: i18n.t('module:Parent:HomeObservations:HomeObservationsDetails:title'),
        },
      ],
      indexRoute: {
        component: HomeObservationsList,
      },
      name: ROUTE_NAMES['PARENT_INBOX.HOME_OBSERVATIONS.LIST'],
      path: 'home-observations',
      title: i18n.t('module:Parent:HomeObservations:title'),
    },
    {
      childRoutes: [
        {
          component: RequestedExtraSessionsDetails,
          name: ROUTE_NAMES['PARENT_INBOX.REQUESTED_EXTRA_SESSIONS.DETAILS'],
          path: ':id/details',
          title: i18n.t('module:Parent:RequestedSessions:Details:title'),
        },
      ],
      component: RequestedExtraSessionsWrapper,
      indexRoute: {
        component: RequestedExtraSessionsList,
      },
      name: ROUTE_NAMES['PARENT_INBOX.REQUESTED_EXTRA_SESSIONS.LIST'],
      path: 'requested-extra-sessions',
      title: i18n.t('module:Parent:RequestedSessions:List:title'),
    },
  ],
  component: ParentInboxWrapper,
  name: ROUTE_NAMES['PARENT_INBOX.INDEX'],
  path: 'parent-inbox',
  title: i18n.t('module:Parent:title'),
}

ParentInboxRouting.ROUTE_NAMES = ROUTE_NAMES

export default ParentInboxRouting
