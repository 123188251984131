import { createSelector } from 'reselect'

const getObservationFeedback = (state) => state.observationFeedback

export const getObservationFeedbackListSelector = createSelector(
  [getObservationFeedback],
  (state) => {
    if (!state) {
      return null
    }

    return state.list
  },
)

export const getObservationFeedbackList = createSelector(
  [getObservationFeedbackListSelector],
  (state) => state.data,
)

export const getListCriteria = (params) => {
  const { author, observation } = params
  const criteria = []

  if (observation) {
    criteria.push({
      field: 'observation',
      value: observation,
    })
  }

  if (author) {
    criteria.push({
      field: 'observation.author',
      value: author,
    })
  }

  return criteria
}
