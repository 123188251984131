import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as authenticationLogsSelectors from './selectors'

const withAuthenticationLogsService = (WrappedComponent) => {
  const mapState = ({ authenticationLogs }) => ({ authenticationLogs })

  const mapDispatch = injectActions('authenticationLogsActions', actions)

  const Component = ({ authenticationLogs, authenticationLogsActions, ...others }) => (
    <WrappedComponent
      authenticationLogsActions={authenticationLogsActions}
      authenticationLogsListState={authenticationLogs.list}
      authenticationLogsSelectors={authenticationLogsSelectors}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withAuthenticationLogsService
