import _ from 'lodash'

import React from 'react'

import { Table } from 'components'

import {
  StyledContainer,
  StyledItemContainer,
  StyledTableContainer,
  StyledTitleContainer,
} from './PreviewOptionsStyled'

const PreviewOptions = ({
  fundingLineItemOptions,
  fundingNamePreview,
  previewOptions,
}) => (
  <StyledContainer>
    {_.map(previewOptions, ({ fundingValue, mergeFundingCell }, index) => (
      <StyledItemContainer>
        <StyledTitleContainer>
          {`Option ${index + 1}: ${fundingLineItemOptions[index].label}`}
        </StyledTitleContainer>
        <StyledTableContainer>
          <Table>
            <Table.Tr style={{ height: 70 }}>
              <Table.Td style={{ width: 150 }}>
                Funding
              </Table.Td>
              <Table.Td align="left" colspan={mergeFundingCell && 2}>
                {fundingNamePreview}
              </Table.Td>
              {!mergeFundingCell && (
                <Table.Td>
                  {fundingValue}
                </Table.Td>
              )}
            </Table.Tr>
          </Table>
        </StyledTableContainer>
      </StyledItemContainer>
    ))}
  </StyledContainer>
)

export default PreviewOptions
