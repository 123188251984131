import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import { ChildBacsDetail } from '../models'

import {
  CLEAR_CHILD_BACS_DETAIL,
  CREATE_CHILD_BACS_DETAIL,
  CREATE_CHILD_BACS_DETAIL_FAILED,
  CREATE_CHILD_BACS_DETAIL_SUCCESS,
  GET_CHILD_BACS_DETAIL,
  GET_CHILD_BACS_DETAIL_FAILED,
  GET_CHILD_BACS_DETAIL_SUCCESS,
} from './constants'

export const get = (options: BasicActionOptionsProps) => ({ childBacsDetailsApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: GET_CHILD_BACS_DETAIL_FAILED,
      init: GET_CHILD_BACS_DETAIL,
      success: GET_CHILD_BACS_DETAIL_SUCCESS,
    },
    options,
    service: childBacsDetailsApiClient,
    serviceMethod: 'get',
  })
)

export const create = (options: BasicActionOptionsProps<ChildBacsDetail>) => ({ childBacsDetailsApiClient }) => (
  generateBasicActions.create<ChildBacsDetail>({
    constants: {
      failed: CREATE_CHILD_BACS_DETAIL_FAILED,
      init: CREATE_CHILD_BACS_DETAIL,
      success: CREATE_CHILD_BACS_DETAIL_SUCCESS,
    },
    options,
    service: childBacsDetailsApiClient,
    serviceMethod: 'create',
  })
)

export const clear = () => ({
  type: CLEAR_CHILD_BACS_DETAIL,
})
