import styled from 'styled-components'
import colorLibrary from 'color'

import { Link } from 'react-router'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const MENU_ITEM_WIDTH = 120

interface StyledMenuCircleProps {
  $inline?: boolean
  $lock?: boolean
}

export const StyledMenuCircle = styled.div<StyledMenuCircleProps>`
  width: 42px;
  height: 42px;
  min-width: 42px;
  min-height: 42px;
  border-radius: 50%;
  background: ${getBrandingColor('senary-color')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: ${NEUTRAL_COLOURS.WHITE};
  transition: .2s;
  position: relative;
  
  ${({ $lock }) => ($lock ? `
    background: ${colorLibrary(getBrandingColor('senary-color')).alpha(0.6).string()}; 
    
    & > div > .webfont {
      opacity: .6;
    }
  ` : `
    &:hover, &:focus {
      background: ${colorLibrary(getBrandingColor('primary-color'))
      .mix(colorLibrary(NEUTRAL_COLOURS.WHITE), 0.1)
      .string()};
    } 
  `)}
  
  ${({ $disabled }) => $disabled && `
    &:hover, &:focus {
      background: ${getBrandingColor('senary-color')};
    }
  `}
  
  ${({ $inline }) => $inline && `
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
  `}
`

interface StyledLabelProps {
  $hiddenTitle?: boolean
  $inline?: boolean
  $lock?: boolean
}

export const StyledLabel = styled.p<StyledLabelProps>`
  color: ${NEUTRAL_COLOURS.WHITE};
  margin: 5px 0 0;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  text-align: center;
  white-space: nowrap;

  ${({ $lock }) => $lock && `
    opacity: .6;
  `}
  
  ${({ $inline }) => $inline && `
    margin: 0 0 0 10px;
  `}
  
  ${({ $hiddenTitle }) => $hiddenTitle && `
    display: none;
    
    @media(min-width: 1480px) {
      display: block;
    }
  `}
`

interface StyledLinkProps {
  $hiddenTitle?: boolean
  $inline?: boolean
  $rightActive?: boolean
}

export const StyledLink = styled(Link)<StyledLinkProps>`
  min-width: ${MENU_ITEM_WIDTH}px;
  max-width: ${MENU_ITEM_WIDTH}px;
  min-height: 52px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 7px;
  position: relative;
  cursor: pointer;
  
  &.active {
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 18px;
      left: 16px;
      height: 7px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background: ${NEUTRAL_COLOURS.WHITE};
    }
    
    & > div {
      background: ${NEUTRAL_COLOURS.WHITE};
      
      & > div > .webfont {
        color: ${getBrandingColor('primary-color')} !important;          
      }
    }
  }
  
  ${({ $inline }) => $inline && `
    max-width: none;
    height: auto;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px 15px 10px 20px;
    
    &.active {
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        width: 7px;
        border-top-left-radius: 0;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        background: ${NEUTRAL_COLOURS.WHITE};
        height: 100%;
      }
    }
  `}
  
  ${({ $rightActive }) => $rightActive && `
    padding: 10px 20px 10px 15px;
    min-height: 62px;
    
    &.active {
      &:before {
        left: auto;
        right: 0;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;      
      }
    }
  `}
  
  ${({ $hiddenTitle }) => $hiddenTitle && `
    min-width: auto;
  `}

  ${({ $disabled }) => $disabled && `
    opacity: .4;
    cursor: not-allowed;

    &:active {
      pointer-events: none;
    }
  `}
`

interface StyledBadgeProps {
  $color?: boolean
  $inline?: boolean
}

export const StyledBadge = styled.div<StyledBadgeProps>`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  position: absolute;
  top: -4px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  
  ${({ $color }) => $color && `
    background: ${$color};
  `}
  
  ${({ $inline }) => $inline && `
    width: 18px;
    height: 18px;
    top: -4px;
    right: -3px;
    font-size: 14px;
  `}
`

export const StyledNewBadge = styled.div`
  background: ${NEUTRAL_COLOURS.WHITE};  
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  border-radius: 20px;
  z-index: 10;
`
