import React from 'react'
import { WrappedFieldProps } from 'redux-form'

import { DatePickerProps } from 'components/DatePicker/DatePicker'

import { DatePicker } from 'components'

type FormDatePickerFullProps = DatePickerProps & WrappedFieldProps

const FormDatePicker: React.FC<FormDatePickerFullProps> = ({
  input: { onChange, value },
  meta: { error, submitFailed },
  ...rest
}) => (
  <DatePicker
    {...rest}
    error={submitFailed && error}
    value={value}
    onChange={onChange}
  />
)

export default FormDatePicker
