import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { mergeData } from 'utils/data'

const getBirthdaysList = (state) => state.child.birthdaysList

const getThisYearBirthdaysList = createSelector([getBirthdaysList], (state) => state.thisYear)

const getNextYearBirthdaysList = createSelector([getBirthdaysList], (state) => state.nextYear)

const getThisYearDataSelector = createSelector([getThisYearBirthdaysList], (state) => state.data)

const getNextYearDataSelector = createSelector([getNextYearBirthdaysList], (state) => state.data)

const getThisYearMetaSelector = createSelector([getThisYearBirthdaysList], (state) => state.meta)

const getNextYearMetaSelector = createSelector([getNextYearBirthdaysList], (state) => state.meta)

const getBirthdaysListDataSelector = createSelector(
  [getThisYearDataSelector, getNextYearDataSelector],
  (thisYearData, nextYearData) => mergeData(thisYearData, nextYearData, true),
)

export const getIsThisYearFetched = createSelector(
  [getThisYearDataSelector, getThisYearMetaSelector], // eslint-disable-next-line camelcase
  (data, meta) => (data?.length >= meta?.total_results),
)

export const getIsNextYearFetched = createSelector(
  [getNextYearDataSelector, getNextYearMetaSelector], // eslint-disable-next-line camelcase
  (data, meta) => (data?.length >= meta?.total_results),
)

export const getTodayBirthdays = createSelector(
  [getBirthdaysListDataSelector],
  (data) => _.filter(data, (child) => moment(child.birthDate).format('D M') === moment().format('D M')),
)

export const getUpcomingBirthdays = createSelector(
  [getBirthdaysListDataSelector],
  (data) => _.filter(data, (child) => moment(child.birthDate).format('D M') !== moment().format('D M')),
)

export const getBirthdaysCriteria = createSelector([(filters) => filters], (filters) => {
  if (!filters) {
    return null
  }

  const {
    after,
    before,
    isArchived,
    order,
  } = filters
  const criteria = []

  if (after) {
    criteria.push({
      field: 'format[birthDate][m-d][after]',
      value: after,
    })
  }

  if (before) {
    criteria.push({
      field: 'format[birthDate][m-d][before]',
      value: before,
    })
  }

  if (null !== isArchived && undefined !== isArchived) {
    criteria.push({
      field: 'isArchived',
      value: isArchived,
    })
  }

  if (order) {
    criteria.push({
      field: 'formatOrder[birthDate][m-d]',
      value: order,
    })
  }

  return criteria
})
