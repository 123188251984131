import React, { PropsWithChildren } from 'react'

import ProfileHeaderAvatar from './ProfileHeaderAvatar'
import ProfileHeaderBottom from './ProfileHeaderBottom'
import ProfileHeaderDetails from './ProfileHeaderDetails'
import ProfileHeaderMenu from './ProfileHeaderMenu'
import ProfileHeaderTop from './ProfileHeaderTop'
import ProfileHeaderWhiteBox from './ProfileHeaderWhiteBox'
import { StyledProfileHeader } from './ProfileHeaderStyled'

interface ProfileHeaderRoot extends React.FC<PropsWithChildren<any>> {
  Avatar?: typeof ProfileHeaderAvatar
  Bottom?: typeof ProfileHeaderBottom
  Details?: typeof ProfileHeaderDetails
  Menu?: typeof ProfileHeaderMenu
  Top?: typeof ProfileHeaderTop
  WhiteBox?: typeof ProfileHeaderWhiteBox
}

const ProfileHeader: ProfileHeaderRoot = ({ children }) => (
  <StyledProfileHeader>
    {children}
    <div id="profileHeaderBottom" />
  </StyledProfileHeader>
)

export default ProfileHeader
