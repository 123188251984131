import { GET_DIETARY_STATISTICS, GET_DIETARY_STATISTICS_FAILED, GET_DIETARY_STATISTICS_SUCCESS } from './constants'

const initialState = {
  data: {
    totalAllergies: 0,
    totalMissingInformation: 0,
    totalNoPreferences: 0,
    totalOthers: 0,
    totalVegans: 0,
    totalVegetarians: 0,
  },
  isFetching: false,
}

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_DIETARY_STATISTICS:
      return {
        ...state,
        isFetching: true,
      }
    case GET_DIETARY_STATISTICS_SUCCESS:
      return {
        ...state,
        data: payload.data,
        isFetching: false,
      }
    case GET_DIETARY_STATISTICS_FAILED:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
