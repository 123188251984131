import {
  AGED_DEBT_STATISTICS,
  AGED_DEBT_STATISTICS_FAILED,
  AGED_DEBT_STATISTICS_SUCCESS,
  CLEAR_AGED_DEBT_STATISTICS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case AGED_DEBT_STATISTICS:
      return { ...state, error: initialState.error, isFetching: true }
    case AGED_DEBT_STATISTICS_SUCCESS:
      return { ...state, data: payload.data, isFetching: false }
    case AGED_DEBT_STATISTICS_FAILED:
      return { ...state, error, isFetching: false }
    case CLEAR_AGED_DEBT_STATISTICS:
      return initialState
    default:
      return state
  }
}
