import React, { PropsWithChildren } from 'react'

import colors from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

import { CircleIcon, Icon, Toolbar, Tooltip } from 'components'

interface ConfirmedIconProps {
  isCompleted: boolean
  tooltip?: string
}

const ConfirmedIcon: React.FC<PropsWithChildren<ConfirmedIconProps>> = ({ isCompleted, tooltip }) => (
  <Toolbar noMargin>
    <Toolbar.Group>
      {tooltip && (
        <Toolbar.Item>
          <Tooltip
            placement="bottom-end"
            title={tooltip}
          >
            <CircleIcon
              cursor="pointer"
              icon="info"
              iconSize={16}
              size={24}
            />
          </Tooltip>
        </Toolbar.Item>
      )}
      <Toolbar.Item>
        <Icon
          color={isCompleted ? getBrandingColor('primary-color-lighten-20') : colors.disabled}
          icon="confirmed"
        />
      </Toolbar.Item>
    </Toolbar.Group>
  </Toolbar>
)

export default ConfirmedIcon
