import _ from 'lodash'

import React from 'react'

import { generateRoute } from 'utils/routing'

import { Banner, FolderList, Hyperlink, Page, Space, Spinner, Switch, Typography } from 'components'

import i18n from 'translations'

import { StyledAlignCenterDiv } from './RequestableExtraSessionsStyled'

const RequestableExtraSessionsView = ({
  isEnabled,
  isFetching,
  isSubmitting,
  onToggleChange,
  reDisabled,
  reEnabled,
}) => {
  const renderList = () => {
    if (isSubmitting) {
      return <Spinner />
    }

    return (
      <React.Fragment>
        {reEnabled && (
          <Banner.Info>
            <Typography>
              {i18n.t('module:Management:ParentApp:RequestableExtraSessions:Banner:reEnabled')}
            </Typography>
          </Banner.Info>
        )}
        {reDisabled && (
          <Banner.Info>
            <Typography>
              {i18n.t('module:Management:ParentApp:RequestableExtraSessions:Banner:ReDisabled:1')}
              {' '}
              <Hyperlink to={generateRoute('PARENT_INBOX.REQUESTED_EXTRA_SESSIONS.LIST')} variant="span" primary>
                {i18n.t('module:Management:ParentApp:RequestableExtraSessions:Banner:ReDisabled:2')}
              </Hyperlink>
              {' '}
              {i18n.t('module:Management:ParentApp:RequestableExtraSessions:Banner:ReDisabled:3')}
            </Typography>
            <Typography>
              {i18n.t('module:Management:ParentApp:RequestableExtraSessions:Banner:ReDisabled:4')}
            </Typography>
          </Banner.Info>
        )}
        <Space space="20px" />
        {isEnabled && (
          <FolderList>
            <FolderList.Item
              icon="sessions"
              title={i18n.t('module:Management:ParentApp:RequestableExtraSessions:CorePreferences:title')}
              to={generateRoute('MANAGEMENT.PARENT_APP.REQUESTABLE_EXTRA_SESSIONS.CORE_PREFERENCES')}
            />
            <FolderList.Item
              icon="sessions"
              title={i18n.t('module:Management:ParentApp:RequestableExtraSessions:UnavailableTimes:title')}
              to={generateRoute('MANAGEMENT.PARENT_APP.REQUESTABLE_EXTRA_SESSIONS.UNAVAILABLE_TIMES')}
            />
          </FolderList>
        )}
      </React.Fragment>
    )
  }

  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    const isBannerDisplayed = reEnabled || reDisabled

    return (
      <React.Fragment>
        <Typography margin="20px 0">
          {i18n.t('module:Management:ParentApp:RequestableExtraSessions:subtitle')}
        </Typography>
        <StyledAlignCenterDiv>
          <Switch
            value={isEnabled}
            onChange={onToggleChange}
          />
          {!isSubmitting && (
            <Typography margin="0 0 0 10px">
              {isEnabled ? _.upperFirst(i18n.t('global:enabled')) : _.upperFirst(i18n.t('global:disabled'))}
            </Typography>
          )}
        </StyledAlignCenterDiv>
        <Space space={isBannerDisplayed ? '20px' : '30px'} />
        {renderList()}
      </React.Fragment>
    )
  }

  return (
    <Page.Section
      isLoading={isFetching}
      title={i18n.t('module:Management:ParentApp:RequestableExtraSessions:title')}
    >
      {renderContent()}
    </Page.Section>
  )
}

export default RequestableExtraSessionsView
