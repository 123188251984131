import i18n from 'translations'

export const DEPOSIT_TYPES = {
  DEPOSIT: 'deposit',
  REFUND: 'refund',
}

export const DEPOSIT_TYPE_OPTIONS = [
  {
    label: i18n.t('services:Deposits:TypeOptions:deposit'),
    value: DEPOSIT_TYPES.DEPOSIT,
  },
  {
    label: i18n.t('services:Deposits:TypeOptions:depositRefund'),
    value: DEPOSIT_TYPES.REFUND,
  },
]

export const STATISTIC_TYPES = {
  HELD: 'held',
  PENDING_DEPOSITS: 'pendingDeposits',
  PENDING_REFUNDS: 'pendingRefunds',
  RAISED: 'raised',
  REFUNDED: 'refunded',
}

export const DEPOSIT_USED = {
  ADD_DEPOSIT_TO_NEXT_INVOICE: 'addDepositToNextInvoice',
  MARK_AS_PAID: 'markAsPaid',
}

export const DEPOSIT_USED_OPTIONS = [
  {
    label: i18n.t('module:Finance:Deposits:Form:addDepositToNextInvoice'),
    value: DEPOSIT_USED.ADD_DEPOSIT_TO_NEXT_INVOICE,
  },
  {
    label: i18n.t('module:Finance:Deposits:Form:markAsPaid'),
    value: DEPOSIT_USED.MARK_AS_PAID,
  },
]

export const DEPOSIT_REFUND_USED = {
  ADD_REFUND_TO_NEXT_INVOICE: 'addRefundToNextInvoice',
  MARK_AS_REFUNDED: 'markAsRefunded',
}

export const DEPOSIT_REFUND_USED_OPTIONS = [
  {
    label: i18n.t('module:Modals:DepositRefund:addRefundToNextInvoice'),
    value: DEPOSIT_REFUND_USED.ADD_REFUND_TO_NEXT_INVOICE,
  },
  {
    label: i18n.t('module:Modals:DepositRefund:markAsRefunded'),
    value: DEPOSIT_REFUND_USED.MARK_AS_REFUNDED,
  },
]
