import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  margin: 15px 0 30px;
  
  @media(max-width: 550px) {
    display: none;
  }
`

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;  
`

export const StyledChart = styled.div`
  flex: 1;
  height: 100%;
`
