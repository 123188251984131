import styled from 'styled-components'

import layout from 'constants/layout'

export const StyledWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: 75% max-content;
  align-items: center;
  white-space: nowrap;
`

export const StyledTooltipWrapper = styled.div`
  min-width: 170px;
`

export const StyledStatisticsWrapper = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;
  margin: 25px 0;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    width: 100%;
  }
`
