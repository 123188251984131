import React from 'react'

import { generateRoute } from 'utils/routing'

import { Callout, EmptyState, Page, Pagination, Section, Select, Spinner, Table, Toolbar, Typography } from 'components'

import i18n from 'translations'

const StaffLeaveTypesView = ({
  errorMessages,
  isEmpty,
  isLoading,
  leaveTypeStatus,
  leaveTypeStatusOptions,
  onLeaveTypeStatusFilterChange,
  onPageChange,
  page,
  pageCount,
  perPage,
  tableData,
  totalResults,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('global:pagination:entries')}
      titleSingular={i18n.t('global:pagination:entry')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (isEmpty) {
      return (
        <EmptyState
          icon="plus"
          text1={i18n.t('module:Management:LeaveTypes:Staff:emptyStateTitle')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={[
            {
              align: 'left',
              field: 'name',
              title: i18n.t('module:Management:LeaveTypes:Staff:tableTitle'),
            },
            {
              field: 'rowToButton',
            },
          ]}
          data={tableData}
          sticky
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const renderToolbar = () => (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <Select
            options={leaveTypeStatusOptions}
            placeholder={i18n.t('global:Active')}
            searchable={false}
            value={leaveTypeStatus}
            onChange={onLeaveTypeStatusFilterChange}
          />
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )

  const actions = (
    <Section.Actions
      primary={[{
        label: i18n.t('module:Management:LeaveTypes:Staff:addLeaveTypeButtonTitle'),
        to: generateRoute('MANAGEMENT.LEAVE_TYPES.STAFF_LEAVE_TYPES.ADD'),
      }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title={i18n.t('module:Management:LeaveTypes:Staff:title')}
    >
      <Callout content={errorMessages} error />
      {renderToolbar()}
      <Typography margin="20px 0">
        {i18n.t('module:Management:LeaveTypes:Staff:subtitle')}
      </Typography>
      {renderContent()}
    </Page.Section>
  )
}

export default StaffLeaveTypesView
