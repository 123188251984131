import React from 'react'

import { ModalBox } from 'components'

import i18n from 'translations'

import ChildHeader from './components/ChildHeader'
import ReportDetail from './components/ReportDetail'
import FormativeReportsSendForm from './components/FormativeReportsSendForm'

const FormativeReportsSendModalView = ({
  child = {},
  report,
  onCloseClick,
  onSubmit,
}) => (
  <ModalBox
    title={i18n.t('module:Modals:FormativeReportsSendModal:title')}
    autoHeight
    onCloseClick={onCloseClick}
  >
    <ChildHeader child={child} />
    <ReportDetail report={report} />
    <FormativeReportsSendForm
      child={child}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    />
  </ModalBox>
)

export default FormativeReportsSendModalView
