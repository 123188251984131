import _ from 'lodash'
import { v4 } from 'uuid'

import React, { PropsWithChildren } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

type DraggableItem = {
  draggableId: string
  id: number
  order?: number
}

export interface DraggableListProps {
  disabled?: boolean
  droppableId?: string
  itemComponent?: (item: DraggableItem, index: number) => React.ReactNode | null
  items: DraggableItem[]
  onChange?: (items: DraggableItem[], e: any) => void
}

const DraggableList: React.FC<PropsWithChildren<DraggableListProps>> = ({
  disabled,
  droppableId,
  itemComponent,
  items,
  onChange,
}) => {
  const elements = _.map(items, (item, index) => {
    const newItem = { ...item }
    newItem.order = index

    return newItem
  })

  const reorder = (list, startIndex, endIndex) => {
    let result = _.values(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    result = _.map(result, (item, index) => {
      const newItem = { ...item }
      newItem.order = index

      return newItem
    })

    return result
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const { destination, source } = result

    if (source.index === destination.index && source.droppableId === destination.droppableId) {
      return
    }

    const reorderedItems = reorder(
      elements,
      result.source.index,
      result.destination.index,
    )

    onChange(reorderedItems, result)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId || v4()} isDropDisabled={disabled}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {_.map(elements, (item, index) => (
              <Draggable
                draggableId={item.draggableId}
                index={item.order}
                isDragDisabled={disabled}
                key={item.id}
              >
                {(parent) => (
                  <div
                    ref={parent.innerRef}
                    {...parent.dragHandleProps}
                    {...parent.draggableProps}
                  >
                    {itemComponent(item, index)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DraggableList
