import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import ChildExtraItemsWrapper from 'module/Children/Child/ChildBookingPattern/ChildExtraItems/ChildExtraItemsWrapper'
import ChildFundingWrapper from 'module/Children/Child/ChildBookingPattern/ChildFunding/ChildFundingWrapper'
import FinanceContainer from 'module/Finance'
import InjuryContainer from 'module/Safeguarding/Injury/InjuryWrapper'
import OccupancyContainer from 'module/Occupancy'

import { RESPONSE } from 'constants/http'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'
import eventBus from 'utils/eventBus'

import { withAppService } from 'services/app'
import { withShellService } from 'services/shell'
import { withChildService } from 'services/legacy/child'
import { withChildSessionsService } from 'services/legacy/childSessions'
import { withChildBookingService } from 'services/booking/childBooking'
import { withChildMovesService } from 'services/legacy/childMoves'
import { withRouter } from 'services/router'

import { EmptyState } from 'components'

import i18n from 'translations'

import ChildAdhocSessionsContainer from './ChildBookingPattern/ChildAdhocSessions'
import ChildSessionsContainer from './ChildBookingPattern/ChildSessions'
import ChildLearningJourneyContainer from './ChildLearningJourney'
import ChildView from './ChildView'

export const UPDATE_CHILD_SESSION_SETUP = 'EVENT/UPDATE_CHILD_SESSION_SETUP'
export const UPDATE_CHILD_CONTACT_SETUP = 'EVENT/UPDATE_CHILD_CONTACT_SETUP'

const CHILD_GROUPS = {
  read: [
    'child.keyWorkers',
    'user',
    'user.details',
    'child.extend',
    'child.nurseryClass',
    'nurseryClass',
    'child.carerChildRelations',
    'carerChildRelation.carer',
    'user.details',
    'carer',
  ],
}

class ChildContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      child: null,
      hasChildDetailPermission: true,
    }

    this.indexRedirect(props)
  }

  componentDidMount() {
    this.fetch()
  }

  shouldComponentUpdate(nextProps) {
    this.indexRedirect(nextProps)

    return true
  }

  componentWillUnmount() {
    const { childActions } = this.props
    eventBus.remove(UPDATE_CHILD_SESSION_SETUP, this.getChildSessionsSetup)
    eventBus.remove(UPDATE_CHILD_CONTACT_SETUP, this.getChild)

    childActions.clearSingle()
  }

  componentDidUpdate(prevProps) {
    const { params } = this.props
    const { params: prevParams } = prevProps
    const { childId } = params
    const { childId: prevChildId } = prevParams

    if (prevChildId && childId && prevChildId !== childId) {
      this.fetch()
    }
  }

  fetch = () => {
    const {
      Occupancy,
      childMovesActions,
      params: { childId },
    } = this.props

    eventBus.on(UPDATE_CHILD_SESSION_SETUP, this.getChildSessionsSetup)
    eventBus.on(UPDATE_CHILD_CONTACT_SETUP, this.getChild)

    this.getChild()
    this.getChildSessionsSetup()

    if (Occupancy) {
      childMovesActions.listSetup(childId)
    }
  }

  getChild = () => {
    const { childActions, params: { childId } } = this.props

    childActions.get({
      onFailed: (error) => {
        if (error?.code === RESPONSE.HTTP_404_NOT_FOUND) {
          this.setState({ hasChildDetailPermission: false })
        }
      },
      onSuccess: this.getChildSuccess,
      params: [childId, {
        groups: CHILD_GROUPS,
      }],
    })
  }

  getChildSessionsSetup = () => {
    const { childBookingSelectors, childSessionsActions, isFinanceV3Enabled, params: { childId } } = this.props

    if (isFinanceV3Enabled) {
      const criteria = childBookingSelectors.getCriteria({
        childId,
      })

      return childSessionsActions.listSetupV3(childId, {
        criteria,
      })
    }

    return childSessionsActions.listSetup(childId, {
      criteria: [{
        field: 'archived',
        value: 0,
      }],
      limit: 0,
    })
  }

  getChildSuccess = ({ data }) => {
    const { shellActions } = this.props

    this.setState({ child: data })

    shellActions.setRouteTitle({
      name: 'CHILDREN.CHILD',
      title: data?.name,
    })
  }

  indexRedirect = (props) => {
    const {
      location: { pathname },
      navigate,
      params: { childId },
    } = props

    if (generateRoute('CHILDREN.CHILD', { childId }) === pathname) {
      navigate(generateRoute('CHILDREN.CHILD.ABOUT.PROFILE', { childId }))
    }
  }

  render() {
    const {
      ChildAdhocSessions,
      ChildExtraItems,
      ChildFunding,
      ChildLearningJourney,
      ChildSessions,
      Finance,
      Injury,
      Occupancy,
      childSetup,
      children,
      isFinanceV3Enabled,
    } = this.props
    const { child, hasChildDetailPermission } = this.state
    const authAccessMap = {
      ChildAdhocSessions,
      ChildExtraItems,
      ChildFunding,
      ChildLearningJourney,
      ChildSessions,
      Finance,
      Injury,
      Occupancy,
    }

    if (!hasChildDetailPermission) {
      return (
        <EmptyState
          icon="visibility-off"
          iconSize={80}
          text1={i18n.t('module:Children:Child:accessDeniedEmptyText')}
        />
      )
    }

    if (!child) {
      return null
    }

    return (
      <ChildView
        authAccessMap={authAccessMap}
        child={child}
        hasChildMoves={!!childSetup.roomMoves}
        hasRelations={!!childSetup.contacts}
        hasSessions={!!childSetup.sessions}
        isFinanceV3Enabled={isFinanceV3Enabled}
      >
        {children}
      </ChildView>
    )
  }
}

const mapState = (state, { childSelectors }) => ({
  ChildAdhocSessions: auth.SELECTORS.getComponentIsAuthorised(state, ChildAdhocSessionsContainer),
  ChildExtraItems: auth.SELECTORS.getComponentIsAuthorised(state, ChildExtraItemsWrapper),
  ChildFunding: auth.SELECTORS.getComponentIsAuthorised(state, ChildFundingWrapper),
  ChildLearningJourney: auth.SELECTORS.getComponentIsAuthorised(state, ChildLearningJourneyContainer),
  ChildSessions: auth.SELECTORS.getComponentIsAuthorised(state, ChildSessionsContainer),
  Finance: auth.SELECTORS.getComponentIsAuthorised(state, FinanceContainer),
  Injury: auth.SELECTORS.getComponentIsAuthorised(state, InjuryContainer),
  Occupancy: auth.SELECTORS.getComponentIsAuthorised(state, OccupancyContainer),
  childSetup: childSelectors.getChildSetup(state),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
})

const enhance = compose(
  withAppService,
  withChildService,
  withChildBookingService,
  withChildSessionsService,
  withChildMovesService,
  withRouter,
  withShellService,
  connect(mapState),
)

export default enhance(ChildContainer)
