import * as listActions from './list/actions'
import * as singleActions from './single/actions'
import * as statisticsActions from './statistics/actions'
import * as dropdownListActions from './dropdownList/actions'

export default {
  ...dropdownListActions,
  ...listActions,
  ...singleActions,
  ...statisticsActions,
}
