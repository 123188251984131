import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { LEAVE_OPTION } from 'services/legacy/rooms/constants'

import { isRequired } from 'utils/fieldValidation'

import { Form, GridLayout, Toolbar } from 'components'

import i18n from 'translations'

import { GrayBox } from 'module/Rooms/RoomsMoves/components'

import ErrorAlert from './components/ErrorAlert'

import { StyledRowContainer, StyledToolbarContainer } from './EditRoomMoveFormStyled'

export const EDIT_ROOM_MOVE_FORM = 'EditRoomMoveForm'

const EditRoomMoveForm = ({
  StaffingEnabled,
  handleSubmit,
  isProcessing,
  onChangeMoveTo,
  onCloseClick,
  onSubmit,
  roomsWithLeaving,
  selectedMoveTo,
  showWarning,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <GridLayout>
      <GridLayout.Group noVerticalMargin>
        <GridLayout.Item flex="0 0 240px" mobileFull>
          <StyledRowContainer>
            <Form.Row
              label={i18n.t('module:Modals:EditRoomMoveModal:MoveTo:label')}
              margin="0 0 10px 0"
              verticalLabel
            >
              <Field
                clearable={false}
                component={Form.InfiniteDropdowns.Rooms}
                extraOptions={[LEAVE_OPTION]}
                name="toNurseryClass"
                options={roomsWithLeaving}
                placeholder={i18n.t('module:Modals:EditRoomMoveModal:MoveTo:placeholder')}
                validate={isRequired}
                onChange={onChangeMoveTo}
              />
            </Form.Row>
            {selectedMoveTo && (
              <StyledToolbarContainer>
                <Toolbar>
                  {selectedMoveTo.ratio && !StaffingEnabled ? (
                    <Toolbar.Item>
                      <GrayBox
                        title={i18n.t('module:Modals:EditRoomMoveModal:Ratio:label')}
                        value={selectedMoveTo.ratio}
                      />
                    </Toolbar.Item>
                  ) : null}
                  {(selectedMoveTo.startAgeInMonths || 0 === selectedMoveTo.startAgeInMonths)
                    && (selectedMoveTo.endAgeInMonths || 0 === selectedMoveTo.endAgeInMonths)
                    ? (
                      <Toolbar.Item>
                        <GrayBox
                          title={i18n.t('module:Modals:EditRoomMoveModal:TypicalAge:label')}
                          value={i18n.t('module:Modals:EditRoomMoveModal:TypicalAge:value', {
                            endAge: selectedMoveTo.endAgeInMonths,
                            startAge: selectedMoveTo.startAgeInMonths,
                          })}
                        />
                      </Toolbar.Item>
                    ) : null}
                </Toolbar>
              </StyledToolbarContainer>
            )}
          </StyledRowContainer>
        </GridLayout.Item>
        <GridLayout.Item>
          <StyledRowContainer>
            <Form.Row
              label={i18n.t('module:Modals:EditRoomMoveModal:MoveOn:label')}
              verticalLabel
            >
              <Field
                component={Form.DatePicker}
                name="moveOn"
                placeholder={i18n.t('module:Modals:EditRoomMoveModal:MoveOn:placeholder')}
                validate={isRequired}
              />
            </Form.Row>
          </StyledRowContainer>
        </GridLayout.Item>
      </GridLayout.Group>
      <Form.Row
        label={i18n.t('module:Modals:EditRoomMoveModal:AssignNewKeyPerson:label')}
        margin="0 0 10px 0"
        verticalLabel
      >
        <Field
          component={Form.InfiniteDropdowns.Users}
          name="assignedTeachers"
          placeholder={i18n.t('module:Modals:EditRoomMoveModal:AssignNewKeyPerson:placeholder')}
          multi
        />
      </Form.Row>
    </GridLayout>
    {showWarning && (
      <ErrorAlert message={i18n.t('module:Modals:EditRoomMoveModal:errorMessage')} />
    )}
    <Form.FooterActions
      isSubmitting={isProcessing}
      onCancelClick={onCloseClick}
    />
  </Form>
)

export default reduxForm({ form: EDIT_ROOM_MOVE_FORM })(EditRoomMoveForm)
