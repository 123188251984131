export const LIST_CHILD_FUNDING = 'LIST_CHILD_FUNDING'
export const LIST_CHILD_FUNDING_SUCCESS = 'LIST_CHILD_FUNDING_SUCCESS'
export const LIST_CHILD_FUNDING_FAILED = 'LIST_CHILD_FUNDING_FAILED'
export const EXPORT_LIST_CHILD_FUNDING = 'EXPORT_LIST_CHILD_FUNDING'
export const EXPORT_LIST_CHILD_FUNDING_SUCCESS = 'EXPORT_LIST_CHILD_FUNDING_SUCCESS'
export const EXPORT_LIST_CHILD_FUNDING_FAILED = 'EXPORT_LIST_CHILD_FUNDING_FAILED'

export const UPDATE_CHILD_FUNDING_LIST = 'UPDATE_CHILD_FUNDING_LIST'

export const CLEAR_CHILD_FUNDING = 'CLEAR_CHILD_FUNDING'

export const listChildFunding = (params, mergeResult, onSuccess) => ({ childFundingApiClient }) => (dispatch) => {
  dispatch({
    params,
    type: LIST_CHILD_FUNDING,
  })

  childFundingApiClient.list(params).then(
    (data) => {
      dispatch({
        mergeResult,
        payload: data,
        type: LIST_CHILD_FUNDING_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: LIST_CHILD_FUNDING_FAILED,
      })
    },
  )
}

export const exportReport = (params, onSuccess, onFailed) => ({ childFundingApiClient }) => (dispatch) => {
  dispatch({
    params,
    type: EXPORT_LIST_CHILD_FUNDING,
  })

  childFundingApiClient.exportReport(params).then(
    (data) => {
      dispatch({
        payload: data,
        type: EXPORT_LIST_CHILD_FUNDING_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: EXPORT_LIST_CHILD_FUNDING_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const updateChildFundingList = (response) => ({
  payload: response,
  type: UPDATE_CHILD_FUNDING_LIST,
})

export const clearChildFunding = () => ({
  type: CLEAR_CHILD_FUNDING,
})
