export const ADD_FOOD = 'ADD_FOOD'
export const ADD_FOOD_SUCCESS = 'ADD_FOOD_SUCCESS'
export const ADD_FOOD_FAILED = 'ADD_FOOD_FAILED'

export const UPDATE_FOOD = 'UPDATE_FOOD'
export const UPDATE_FOOD_SUCCESS = 'UPDATE_FOOD_SUCCESS'
export const UPDATE_FOOD_FAILED = 'UPDATE_FOOD_FAILED'

export const REMOVE_FOOD = 'REMOVE_FOOD'
export const REMOVE_FOOD_SUCCESS = 'REMOVE_FOOD_SUCCESS'
export const REMOVE_FOOD_FAILED = 'REMOVE_FOOD_FAILED'
