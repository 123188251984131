import React from 'react'
import { connect } from 'react-redux'

import { RootState } from 'core/reducers'

import { injectActions } from 'utils/service'

import selectors from './selectors'
import actions from './actions'

export interface withAppServiceProps {
  appActions: typeof actions
  appSelectors: typeof selectors
}

const withAppService = <P extends withAppServiceProps>(WrappedComponent) => {
  const mapState = ({ app }: RootState) => ({ app })

  const mapDispatch = injectActions('appActions', actions)

  const Component = ({ appActions, ...others }) => (
    <WrappedComponent
      appActions={appActions}
      appSelectors={selectors}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withAppService
