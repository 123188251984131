import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'
import layout from 'constants/layout'

export const StyledWrapper = styled.div`
  padding: 0 80px 30px;

  ${({ $gray }) => $gray && `
    background: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
    border-radius: 12px;
    padding: 30px 80px;
  `}

  @media(max-width: ${layout.mobileWidthResolution}px) {
    padding: 20px 20px 10px;
  }
`

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;  
`
