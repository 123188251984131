import React, { Component } from 'react'
import { Field as FormField, reduxForm } from 'redux-form'
import { compose } from 'recompose'

import { AMOUNT_DRUNK_MARKS } from 'services/legacy/dailyDiaryBottles/constants'

import { isRequired } from 'utils/fieldValidation'

import { DefinitionList, Field, Form, TimeString, Typography } from 'components'

import i18n from 'translations'

import withDailyDiaryRecordHandlers from '../../../withDailyDiaryRecordHandlers'
import { StyledFieldWrapper, StyledVisibleBody } from './BottleRecordStyled'

class BottleRecord extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isEditMode: false,
    }
  }

  handleEditMode = (isEditMode) => {
    const { item, onCancelEdit } = this.props

    if (!isEditMode && onCancelEdit) {
      onCancelEdit(item)
    }

    this.setState({ isEditMode })
  }

  handleSubmitClick = (e) => {
    const { dailyDiaryRecordHelpers: { handleSubmitClick } } = this.props

    handleSubmitClick(e, {
      onFailed: this.handleEditMode,
    })
  }

  render() {
    const { dailyDiaryRecordState, item, onRemoveRecord } = this.props
    const { isProcessing } = dailyDiaryRecordState
    const { isEditMode } = this.state

    const { amount, id, record: { comments, recordedAt } } = item

    const actions = (
      <Field.InlineEdit.Actions
        isEdit={isEditMode}
        isProcessing={isProcessing}
        onCancelClick={() => this.handleEditMode(false)}
        onDeleteClick={() => onRemoveRecord(id)}
        onEditClick={() => this.handleEditMode(true)}
        onSaveClick={this.handleSubmitClick}
      />
    )

    const renderEditableComponent = (
      <StyledVisibleBody>
        <DefinitionList vertical>
          <DefinitionList.Item
            label={(
              <Typography bold primary>
                {i18n.t('module:DailyDiary:Bottles:amountDrunk')}
              </Typography>
            )}
            value={(
              <FormField
                component={Form.Slider}
                disabled={isProcessing}
                marks={AMOUNT_DRUNK_MARKS}
                max={10}
                min={0}
                name="amount"
                validate={isRequired}
                valueFormat={(value) => `${value}${i18n.t('global:oz')}`}
              />
            )}
          />
        </DefinitionList>
        <DefinitionList vertical>
          <DefinitionList.Item
            label={(
              <Typography bold primary>
                {i18n.t('global:Time')}
              </Typography>
            )}
            value={(
              <FormField
                component={Form.TimePicker}
                disabled={isProcessing}
                name="recordedAt"
                validate={isRequired}
              />
            )}
          />
        </DefinitionList>
        <DefinitionList vertical>
          <DefinitionList.Item
            label={(
              <Typography bold primary>
                {i18n.t('module:DailyDiary:global:additionalNotes')}
              </Typography>
            )}
            value={(
              <FormField
                component={Form.TextAreaField}
                disabled={isProcessing}
                name="comments"
                placeholder={i18n.t('module:DailyDiary:global:enterAdditionalNotesHere')}
              />
            )}
            whiteSpace="pre-line"
          />
        </DefinitionList>
      </StyledVisibleBody>
    )

    const renderVisibleComponent = (
      <StyledVisibleBody>
        <DefinitionList vertical>
          <DefinitionList.Item
            label={(
              <Typography bold primary>
                {i18n.t('module:DailyDiary:Bottles:amountDrunk')}
              </Typography>
            )}
            value={amount}
          />
        </DefinitionList>
        <DefinitionList vertical>
          <DefinitionList.Item
            label={(
              <Typography bold primary>
                {i18n.t('global:Time')}
              </Typography>
            )}
            value={(
              <TimeString date={recordedAt} />
            )}
          />
        </DefinitionList>
        <DefinitionList vertical>
          <DefinitionList.Item
            label={(
              <Typography bold primary>
                {i18n.t('module:DailyDiary:global:additionalNotes')}
              </Typography>
            )}
            value={comments || '-'}
            whiteSpace="pre-line"
            wordBreak="break-word"
          />
        </DefinitionList>
      </StyledVisibleBody>
    )

    return (
      <StyledFieldWrapper>
        <Form onSubmit={this.handleSubmitClick}>
          <Field.InlineEdit
            actions={actions}
            description={renderVisibleComponent}
            editableComponent={renderEditableComponent}
            isEditMode={isEditMode}
            noPadding
          />
        </Form>
      </StyledFieldWrapper>
    )
  }
}

const enhance = compose(
  withDailyDiaryRecordHandlers,
)

export default reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: true,
})(enhance(BottleRecord))
