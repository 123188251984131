import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withNextStepsService } from 'services/legacy/nextSteps'
import { withSecurityService } from 'services/security'

import NextStepsWidgetView from './NextStepsWidgetView'

const NEXT_STEPS_GROUPS = {
  read: [
    'child',
    'childObservation.child',
    'childObservation.observation',
    'frameworkArea',
    'nextMontessoriActivity',
    'nextMontessoriActivity.childObservation',
    'nextStep',
    'nextStep.childObservation',
    'nextStep.frameworkAreas',
    'nextStep.nextObservation',
    'observation',
    'observation.author',
    'user',
  ],
}

class NextStepsWidgetContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 0,
    }
  }

  componentDidMount() {
    this.getNextSteps()
  }

  componentWillUnmount() {
    const { nextStepsActions } = this.props

    nextStepsActions.clearNextSteps()
  }

  handleGetNextStepsSuccess = () => {
    const { page } = this.state

    this.setState({
      page: page + 1,
    })
  }

  getNextSteps = () => {
    const { nextStepsActions, nextStepsSelectors, user } = this.props
    const { page } = this.state

    const criteria = nextStepsSelectors.getNextStepsListCriteria({
      isChildArchived: false,
      keyPerson: user.id,
    })

    const params = {
      criteria,
      groups: NEXT_STEPS_GROUPS,
      page,
    }

    return nextStepsActions.list({
      mergeResult: true,
      onSuccess: this.handleGetNextStepsSuccess,
      params,
    })
  }

  render() {
    const { isFetching, nextSteps } = this.props
    const { page } = this.state

    return (
      <NextStepsWidgetView
        isLoading={isFetching && !page}
        nextSteps={nextSteps}
        onLoadNextPage={this.getNextSteps}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nextStepsListState,
  nextStepsSelectors,
  securitySelectors,
}) => ({
  isFetching: appSelectors.getIsFetching(nextStepsListState),
  nextSteps: nextStepsSelectors.getNextStepsListDataState(state),
  user: securitySelectors.getAuthUser(state),
})

const enhance = compose(
  withAppService,
  withNextStepsService,
  withSecurityService,
  connect(mapState),
)

export default enhance(NextStepsWidgetContainer)
