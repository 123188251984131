import styled from 'styled-components'
import color from 'color'

import colors, { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledPinpadWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 35px 0;
  flex-direction: column;
`

interface StyledPinNumberContainerProps {
  $error?: string
  $length?: number
}

export const StyledPinNumberContainer = styled.div<StyledPinNumberContainerProps>`
  display: grid;
  grid-column-gap: 25px;
  justify-content: center;
  
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  ${({ $length }) => $length && `
    grid-template-columns: repeat(${$length}, auto);
  `}

  ${({ $error }) => $error && `
    animation: shake 0.7s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  `}
`

export const StyledErrorContainer = styled.div`
  margin: 15px 0;
  height: 20px;
  text-align: center;
  color: ${colors.remove};
  font-weight: 600;
  font-size: 12px;
`

interface StyledPinDotProps {
  $hasValue?: boolean
  $invalid?: boolean
  $isLastSelected?: boolean
}

export const StyledPinDot = styled.div<StyledPinDotProps>`
  width: 16px;
  height: 16px;
  border: solid 1px ${getBrandingColor('primary-color')};
  background: ${NEUTRAL_COLOURS.WHITE};
  transition: .2s;
  border-radius: 50%;
  position: relative;
  
  &:before {
    position: absolute;
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
    background: ${color(getBrandingColor('primary-color')).alpha(0.2).rgb().string()};
    content: '';
    border-radius: 50%;
    z-index: 5;
    animation: rippleEffect .2s;
    display: none;
  }
  
  @keyframes rippleEffect {
    from {
      transform: scale3d(.8, .8, .8);
    }
    
    to {
      transform: scale3d(1, 1, 1);
    }
  }
  
  ${({ $hasValue }) => $hasValue && `
    background: ${getBrandingColor('primary-color')};
  `}
  
  ${({ $isLastSelected }) => $isLastSelected && `
    &:before {
      display: block;
    }
  `}
  
  ${({ $invalid }) => $invalid && `
    border-color: ${colors.remove};
  `}
`

export const StyledNumericKeypad = styled.div`
  display: grid;
  grid-column-gap: 43px;
  grid-row-gap: 24px;
  grid-template-columns: repeat(3, 56px);
  grid-template-rows: repeat(4, 56px);
  justify-content: center;
  position: relative;
`

interface StyledNumericButtonProps {
  $isDone?: boolean
}

export const StyledNumericButton = styled.div<StyledNumericButtonProps>`
  min-width: 56px;
  height: 56px;
  border-radius: 50%;
  transition: .2s;
  position: relative;
  
  @keyframes rippleEffect {
    from {
      transform: scale3d(.8, .8, .8);
    }
    
    to {
      transform: scale3d(1, 1, 1);
    }
  }
  
  &:before {
    position: absolute;
    top: -12px;
    left: -12px;
    right: -12px;
    bottom: -12px;
    background: ${color(getBrandingColor('primary-color')).alpha(0.2).rgb().string()};
    content: '';
    border-radius: 50%;
    z-index: 5;
    animation: rippleEffect .2s;
    display: none;
  }
  
  &:active {
    &:before {
      display: block;
    }
  }
  
  ${({ $isDone }) => $isDone && `
    cursor: not-allowed;
    
    &:active {
      &:before {
        display: none;
      }
    }
  `}
`

interface StyledNumericButtonContentProps {
  $isDone?: boolean
}

export const StyledNumericButtonContent = styled.div<StyledNumericButtonContentProps>`
  min-width: 56px;
  height: 56px;
  border: solid 1px #D8D8D8;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: ${NEUTRAL_COLOURS.BASIC};
  border-radius: 50%;
  cursor: pointer;
  transition: .2s;
  position: relative;
  z-index: 10;
  background: ${NEUTRAL_COLOURS.WHITE};
  user-select: none;
    
  ${({ $isDone }) => !$isDone && `
    &:hover {
      background: ${getBrandingColor('primary-color')};
      color: ${NEUTRAL_COLOURS.WHITE};
    }
  `}
  
  ${({ $isDone }) => $isDone && `
    opacity: .4;
    cursor: not-allowed;
  `}
`

interface StyledBackspaceProps {
  $isDone?: boolean
}

export const StyledBackspace = styled.div<StyledBackspaceProps>`
  min-width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
    
  ${({ $isDone }) => $isDone && `
    opacity: .4;
    cursor: not-allowed;
  `}
`

export const StyledSpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, .8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
`
