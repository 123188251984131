import { compose } from 'recompose'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import { withShellService } from 'services/shell'
import { withRouter } from 'services/router'

import RoomsWrapperView from './RoomsWrapperView'
import DefaultRoomMoves from './DefaultRoomMoves'
import RoomsMoves from './RoomsMoves'

class RoomsWrapperContainer extends Component {
  componentDidMount() {
    const { authAccessMap, shellActions } = this.props
    const { section: { PremiumSection } } = authAccessMap

    if (!PremiumSection) {
      return false
    }

    return shellActions.setSubMenu([
      {
        icon: 'rooms',
        label: 'Rooms',
        to: '/rooms/list',
      },
      {
        hidden: !authAccessMap.module.RoomsMoves,
        icon: 'room-moves',
        label: 'Room moves',
        to: '/rooms/rooms-moves',
      },
      {
        hidden: !authAccessMap.module.DefaultRoomMoves,
        icon: 'default-room-moves',
        label: 'Default Moves',
        to: '/rooms/default-room-moves',
      },

    ])
  }

  shouldComponentUpdate(nextProps) {
    const { location: { pathname }, navigate } = nextProps

    if (generateRoute('ROOMS.INDEX') === pathname) {
      navigate(generateRoute('ROOMS.LIST'))
    }

    return true
  }

  componentWillUnmount() {
    const { shellActions } = this.props

    shellActions.clearSubMenu()
  }

  render() {
    const { children } = this.props

    return (
      <RoomsWrapperView>
        {children}
      </RoomsWrapperView>
    )
  }
}

RoomsWrapperContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

const mapState = (state) => ({
  authAccessMap: {
    module: {
      DefaultRoomMoves: auth.SELECTORS.getComponentIsAuthorised(state, DefaultRoomMoves),
      RoomsMoves: auth.SELECTORS.getComponentIsAuthorised(state, RoomsMoves),
    },
    section: {
      PremiumSection: auth.SELECTORS.getIsAuthorised(state, {
        flags: [FEATURE_FLAGS.OCCUPANCY_ENABLED],
      }),
    },
  },
})

const enhance = compose(
  withRouter,
  withShellService,
  connect(mapState),
)

export default enhance(RoomsWrapperContainer)
