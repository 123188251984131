import Newsletters from 'module/Newsletters/index'
import NewslettersList from 'module/Newsletters/NewslettersList'
import NewslettersAdd from 'module/Newsletters/NewslettersAdd'
import PreviewNewsletter from 'module/Newsletters/PreviewNewsletter'

import i18n from 'translations'

export const ROUTE_NAMES = {
  'NEWSLETTERS.ADD': 'NEWSLETTERS.ADD',
  'NEWSLETTERS.EDIT': 'NEWSLETTERS.EDIT',
  'NEWSLETTERS.INDEX': 'NEWSLETTERS.INDEX',
  'NEWSLETTERS.PREVIEW': 'NEWSLETTERS.PREVIEW',
}

const NewslettersRouting = {
  childRoutes: [
    {
      component: NewslettersAdd,
      name: ROUTE_NAMES['NEWSLETTERS.ADD'],
      path: 'add',
      title: i18n.t('module:Newsletters:Routing:Add:title'),
    },
    {
      component: NewslettersAdd,
      name: ROUTE_NAMES['NEWSLETTERS.EDIT'],
      path: ':newsId/edit',
      title: i18n.t('module:Newsletters:Routing:Edit:title'),
    },
    {
      component: PreviewNewsletter,
      name: ROUTE_NAMES['NEWSLETTERS.PREVIEW'],
      path: ':newsId/preview',
      title: i18n.t('module:Newsletters:Routing:Preview:title'),
    },
  ],
  component: Newsletters,
  indexRoute: {
    component: NewslettersList,
  },
  name: ROUTE_NAMES['NEWSLETTERS.INDEX'],
  path: 'newsletters',
  title: i18n.t('module:Newsletters:Routing:title'),
}

NewslettersRouting.ROUTE_NAMES = ROUTE_NAMES

export default NewslettersRouting
