import {
  CLEAR_FOOD_MENU_LIST,
  GET_FOOD_MENU_LIST,
  GET_FOOD_MENU_LIST_FAILED,
  GET_FOOD_MENU_LIST_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ foodMenuApiClient }) => (dispatch) => {
  const { onSuccess, params } = options

  dispatch({
    type: GET_FOOD_MENU_LIST,
  })

  foodMenuApiClient.list(params).then(
    (data) => {
      dispatch({
        payload: data,
        type: GET_FOOD_MENU_LIST_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: GET_FOOD_MENU_LIST_FAILED,
      })
    },
  )
}

export const clearList = () => ({
  type: CLEAR_FOOD_MENU_LIST,
})
