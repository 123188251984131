import _ from 'lodash'

import { ALLERGY_OPTIONS, ALLERGY_TYPES, DIETARY_REQUIREMENTS_OPTIONS } from 'services/legacy/child/constants'
import { CHILD_EDIT_FORM_ROUTE_TYPE } from 'module/Children/Child/constants'
import colors from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { Avatar, Typography } from 'components'

import i18n from 'translations'

import MissingInformationDietary from './components/MissingInformationDietary'
import SessionDayStartEndDetails from './components/SessionDayStartEndDetails'

export const getAllergyTypeLabel = (allergyType) => _.get(_.find(
  ALLERGY_OPTIONS,
  (option) => option.value === allergyType,
), 'label')

export const getAllergyType = (allergyType, allergiesDetails) => {
  if (!allergyType && !allergiesDetails) {
    return getAllergyTypeLabel(ALLERGY_TYPES.NOT_SPECIFIED)
  }

  if (ALLERGY_TYPES.HAS_ALLERGY === allergyType) {
    return allergiesDetails
  }

  return getAllergyTypeLabel(allergyType)
}

export const getAllergyTypeLabelColor = (allergyType) => (
  ALLERGY_TYPES.NO_ALLERGY === allergyType ? colors.gray : colors.remove
)

export const getDietaryTableLists = (children, childAddGranted) => _.map(children, (child) => {
  const {
    firstName,
    id,
    information,
    photo,
    sessionsArray,
    surname,
  } = child
  const {
    allergies,
    allergiesDetails,
    dietaryReq,
    dietaryReqDesc,
  } = information

  const allergyType = getAllergyType(allergies, allergiesDetails)
  const childMedicalInfoRoute = generateRoute('CHILDREN.EDIT', {
    childId: id,
    type: CHILD_EDIT_FORM_ROUTE_TYPE.HEALTH_AND_DIET_INFO,
  })

  return {
    allergies: (
      <div>
        {ALLERGY_TYPES.NOT_SPECIFIED === allergies
          ? (
            <MissingInformationDietary
              childAddGranted={childAddGranted}
              linkLabel={i18n.t('global:EditAllergy')}
              titleLabel={allergyType}
              to={childMedicalInfoRoute}
            />
          )
          : (
            <Typography
              color={getAllergyTypeLabelColor(allergies)}
              width={300}
              wordBreak="break-word"
            >
              {allergyType}
            </Typography>
          )}
      </div>
    ),
    child: (
      <Avatar
        avatarSize="medium"
        initials={[firstName, surname]}
        minWidth={250}
        src={photo}
        title={(
          <Typography>
            {`${firstName} ${surname}`}
          </Typography>
        )}
        to={generateRoute('CHILDREN.CHILD', { childId: id })}
      />
    ),
    dietaryPreference: dietaryReq
      ? (
        <div>
          <Typography width={300} bold>
            {_.get(_.find(DIETARY_REQUIREMENTS_OPTIONS, (option) => option.value === dietaryReq), 'label')}
          </Typography>
          <Typography width={300} wordBreak="break-word">
            {dietaryReqDesc}
          </Typography>
        </div>
      )
      : (
        <MissingInformationDietary
          childAddGranted={childAddGranted}
          linkLabel={i18n.t('global:EditPreferences')}
          titleLabel={i18n.t('module:Children:Constants:DietaryRequirementsOptions:unknownDietaryPreference')}
          to={childMedicalInfoRoute}
        />
      ),
    id,
    sessions: <SessionDayStartEndDetails alignItems="center" sessions={sessionsArray} width={150} />,
  }
})
