import React, { PropsWithChildren } from 'react'

import { Button } from 'components'

import i18n from 'translations'

import { StyledHeader } from './DetailsBreakdownStyled'

interface DetailsBreakdownHeaderProps {
  borderBottom?: boolean
  edit?: {
    auth?: boolean
    onClick?: () => {}
    to?: string
  }
}

const DetailsBreakdownHeader: React.FC<PropsWithChildren<DetailsBreakdownHeaderProps>> = ({
  borderBottom,
  children,
  edit,
}) => (
  <StyledHeader borderBottom={borderBottom}>
    {children}
    {edit && false !== edit?.auth && (
      <Button
        hierarchy="tertiary"
        label={i18n.t('global:Edit')}
        margin="-8px 0"
        to={edit?.to}
        onClick={edit?.onClick}
      />
    )}
  </StyledHeader>
)

export default DetailsBreakdownHeader
