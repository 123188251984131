import { createSelector } from 'reselect'

import { toFloat } from 'utils/data'

import nurseriesSelectors from 'services/nurseries/selectors'

import { FEES_CALCULATION } from '../constants'

export const getNurseryExtraItemsSelector = (state) => state.nurseryExtraItems

export const getNurseryExtraItemsSingleSelector = createSelector(
  [getNurseryExtraItemsSelector],
  (state) => state.single.data,
)

export const getInitialValues = (isEdit) => createSelector(
  [getNurseryExtraItemsSingleSelector, nurseriesSelectors.getExtraItemCalculation],
  (extraItem, nurseryExtraItemCalculation) => {
    if (!isEdit && nurseryExtraItemCalculation) {
      return {
        feeCalculations: nurseryExtraItemCalculation.split('_')[0],
      }
    }

    if (!extraItem) {
      return null
    }

    const { feeCalculations, name, unitPrice } = extraItem

    return {
      feeCalculations,
      name,
      unitPrice,
    }
  },
)

export const getPayload = createSelector(
  [((fields) => fields)],
  (fields) => {
    if (!fields) {
      return null
    }

    const { unitPrice } = fields

    return {
      ...fields,
      type: 'nursery',
      unitPrice: toFloat(unitPrice),
    }
  },
)

export const getArchivePayload = createSelector(
  [((fields) => fields)],
  (fields) => {
    if (!fields) {
      return null
    }

    const { archived } = fields

    return {
      archived,
      type: 'nursery',
    }
  },
)

export const getFeeCalculationsOptions = createSelector(
  [nurseriesSelectors.getSessionCalculationWeeksAndDays],
  (sessionCalculationWeeksAndDays) => {
    if (!sessionCalculationWeeksAndDays) {
      return null
    }

    const { days, weeks } = sessionCalculationWeeksAndDays

    return [
      {
        label: 'Actual sessions',
        tooltip: 'This option will proportionally allocate extra item cost for your invoice period,'
          .concat(' based upon the number of days the nursery is open for the time period. ')
          .concat('Nursery closure dates are not included for this calculation.'),
        value: FEES_CALCULATION.ACTUAL,
      },
      {
        label: `Average over ${weeks} weeks and ${days} days`,
        tooltip: 'This option will equally divide out the extra item costs across the '
          .concat('different months covered by the invoice period. You must select full months for this option ')
          .concat('to be applicable – partial months being select will mean that the extra item revert to being ')
          .concat('calculated on ‘actuals’ (the option above) rather than this option.'),
        value: FEES_CALCULATION.AVERAGE,
      },
    ]
  },
)
