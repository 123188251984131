import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change, formValueSelector, stopSubmit } from 'redux-form'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { FEATURE_FLAGS, ROLES } from 'constants/security'

import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withMembershipsService } from 'services/legacy/memberships'
import { withMembershipRegistersService } from 'services/legacy/membershipRegisters'
import { getWorklogDayType } from 'services/legacy/membershipRegisters/list/selectors'

import StaffWorklogPreviewView from './StaffWorklogPreviewView'

import { STAFF_WORKLOG_PREVIEW_FORM } from './components/StaffWorklogPreviewForm/StaffWorklogPreviewForm'

const MEMBERSHIP_REGISTER_GROUPS = {
  read: [
    'membershipRegister.entries',
    'membershipRegister.membership',
    'membershipRegisterEntry',
    'membershipRegister.worklog',
    'membershipRegisterEntry.nurseryClass',
    'membershipRegisterEntry.totalTime',
    'membership.details',
    'nurseryClass',
  ],
}

const STAFF_WORK_LOG_BY_DAY_GROUPS = {
  read: [
    'membershipRegister.worklog',
    'membershipRegisterEntry.nurseryClass',
    'nurseryClass',
    'membershipRegisterEntry.totalTime',
    'membership.details',
    'membership.shifts',
    'membershipShift',
    'membershipShift.membershipShiftTimes',
    'membershipShiftTime',
    'membershipShift.membershipShiftTimeStatistics',
    'membershipShift.leaveShiftType',
    'leaveShiftType',
  ],
}

class StaffWorklogPreviewContainer extends Component {
  constructor(props) {
    super(props)

    const { params } = props
    const { endDate } = params || {}

    this.state = {
      editMode: false,
      selectedDate: moment(endDate).format(DEFAULT_DATE_FORMAT) === moment().format(DEFAULT_DATE_FORMAT)
        ? moment()
        : moment(endDate).endOf('day'),
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    this.clearStore()
  }

  clearStore = () => {
    const { membershipRegistersActions, membershipsActions } = this.props

    membershipsActions.clear()
    membershipRegistersActions.clear()
    membershipRegistersActions.clearListByDay()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { params } = nextProps
    const { endDate } = params || {}
    const { selectedDate } = prevState

    if (endDate !== moment(selectedDate).format(DEFAULT_DATE_FORMAT)) {
      return { selectedDate: moment(endDate) }
    }

    return null
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedDate } = this.state
    const { selectedDate: prevStateSelectedDate } = prevState

    if (
      prevStateSelectedDate
      && selectedDate
      && prevStateSelectedDate.format(DEFAULT_DATE_FORMAT) !== selectedDate.format(DEFAULT_DATE_FORMAT)
    ) {
      this.clearStore()
      this.fetch()
    }
  }

  fetch = () => {
    const { membershipRegistersActions, membershipRegistersSelectors, params } = this.props
    const { endDate, membershipId, startDate } = params

    const criteria = membershipRegistersSelectors.getMembershipRegistersListCriteria({
      endDate,
      id: membershipId,
      startDate,
    })

    membershipRegistersActions.listByDay({
      onSuccess: this.updateMembershipRegisterSingleStore,
      params: {
        criteria,
        groups: STAFF_WORK_LOG_BY_DAY_GROUPS,
      },
    })
  }

  updateMembershipRegisterSingleStore = ({ data }) => {
    if (!data?.length) {
      return
    }

    const { membershipRegistersActions, membershipsActions, params } = this.props
    const { endDate } = params

    const membership = data[0]
    const { currentRegisters, ...membershipDetails } = membership

    const register = _.find(currentRegisters, ({ date: registerDate }) => ((
      endDate === moment(registerDate).format(DEFAULT_DATE_FORMAT)
    )))

    membershipsActions.setSelectedStaff(membershipDetails)

    if (register) {
      membershipRegistersActions.updateManually({
        data: register,
      })
    }
  }

  handleChangeEditMode = () => {
    const { initialValues, updateFormField } = this.props
    const { editMode } = this.state
    const { entries } = initialValues || {}

    updateFormField('entries', entries)
    updateFormField('changeComment', null)
    this.setState({ editMode: !editMode })
  }

  handleSubmitFailed = (response) => {
    const { injectValidation } = this.props
    const errors = getBackendErrors(response || {})

    if (!errors) {
      return false
    }

    return injectValidation(errors)
  }

  handleSubmit = (fields) => {
    const { memberDetails, membershipRegistersSelectors, record } = this.props
    const { date, id } = record || {}
    const { id: membershipId } = memberDetails || {}
    const { selectedDate } = this.state

    const payload = membershipRegistersSelectors.getMembershipRegisterPayload({
      ...fields,
      date: date || selectedDate,
      id,
      membershipId,
    })

    this.updateMembershipEntries(
      id,
      payload,
      () => this.setState({ editMode: false }),
      this.handleSubmitFailed,
    )
  }

  updateMembershipEntries = (id, payload, onSuccess, onFailed) => {
    const { membershipRegistersActions } = this.props

    if (!id) {
      return membershipRegistersActions.create({
        onFailed,
        onSuccess,
        params: {
          groups: MEMBERSHIP_REGISTER_GROUPS,
        },
        payload,
      })
    }

    return membershipRegistersActions.update(id, {
      onFailed,
      onSuccess,
      params: {
        groups: MEMBERSHIP_REGISTER_GROUPS,
      },
      payload,
    })
  }

  handleAddActivitySuccess = (values) => {
    const { formEntries, updateFormField } = this.props

    updateFormField('entries', [...(formEntries || []), values])
  }

  handleAddActivity = () => {
    const { modalActions, modalConsts } = this.props
    const { selectedDate } = this.state

    modalActions.show(modalConsts.TYPES.STAFF_WORKLOG_ADD_TIME, {
      onSubmit: this.handleAddActivitySuccess,
      selectedDate,
    })
  }

  handleShowChangeLog = () => {
    const { memberDetails, modalActions, modalConsts, previousRecord, record } = this.props
    const { selectedDate } = this.state

    const worklogDayType = getWorklogDayType(previousRecord, selectedDate)

    modalActions.show(modalConsts.TYPES.STAFF_LEGACY_CHANGE_LOG, {
      memberDetails,
      onConfirm: () => { },
      previousRecord,
      record,
      selectedDate,
      worklogDayType,
    })
  }

  handleEndTimeSuccess = (entryId) => (fields) => {
    const { membershipRegistersSelectors, modalActions, record } = this.props
    const { date, entries, id } = record

    const { signOutDate, signOutTime } = fields

    const payload = membershipRegistersSelectors.getMembershipRegisterPayloadWithNewEndtime({
      date,
      entries,
      entryId,
      id,
      signOutDate,
      signOutTime,
    })

    this.updateMembershipEntries(id, payload, () => modalActions.hide())
  }

  handleEndButtonClick = (id, date, signInAt) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.STAFF_WORKLOG_END_TIME, {
      date,
      onSubmit: this.handleEndTimeSuccess(id),
      signInAt,
    })
  }

  handleOngoingEntryEndClick = (index) => {
    const { updateFormField } = this.props

    updateFormField(`entries[${index}].signOutAt`, moment())
  }

  handleUpdateEntryTotalTime = (index, signInAt, signOutAt) => {
    const { updateFormField } = this.props

    const totalTime = moment(signOutAt).diff(signInAt, 'milliseconds')

    updateFormField(`entries[${index}].totalTime`, totalTime)
  }

  handleClockOutUpdateSuccess = () => {
    const { modalActions } = this.props

    modalActions.hide()
    this.clearStore()
    this.fetch()
  }

  handleClockOutSuccess = (entryId) => (fields) => {
    const { membershipRegistersSelectors, previousRecord } = this.props
    const { date, entries, id } = previousRecord

    const { signOutDate, signOutTime } = fields

    const payload = membershipRegistersSelectors.getMembershipRegisterPayloadWithNewEndtime({
      date,
      entries,
      entryId,
      id,
      signOutDate,
      signOutTime,
    })

    this.updateMembershipEntries(id, payload, this.handleClockOutUpdateSuccess)
  }

  handleClockOutButtonClick = () => {
    const { modalActions, modalConsts, previousRecord } = this.props
    const { entries } = previousRecord
    const lastEntry = _.find(entries, ({ signOutAt }) => !signOutAt)

    modalActions.show(modalConsts.TYPES.STAFF_WORKLOG_END_TIME, {
      onSubmit: this.handleClockOutSuccess(lastEntry.id),
    })
  }

  render() {
    const {
      formEntries,
      initialValues,
      isFetching,
      isSubmitting,
      memberDetails,
      previousRecord,
      record,
      shifts,
    } = this.props
    const { editMode, selectedDate } = this.state
    const isEmpty = !record?.entries?.length

    return (
      <StaffWorklogPreviewView
        editMode={editMode}
        formEntries={formEntries}
        initialValues={initialValues}
        isEmpty={isEmpty}
        isFetching={isFetching}
        isSubmitting={isSubmitting}
        memberDetails={memberDetails}
        previousRecord={previousRecord}
        record={record}
        selectedDate={selectedDate}
        shifts={shifts}
        worklogDayType={getWorklogDayType(previousRecord, selectedDate)}
        onAddActivity={this.handleAddActivity}
        onChangeEditMode={this.handleChangeEditMode}
        onClockOutButtonClick={this.handleClockOutButtonClick}
        onEndButtonClick={this.handleEndButtonClick}
        onOngoingEntryEndClick={this.handleOngoingEntryEndClick}
        onShowChangeLog={this.handleShowChangeLog}
        onSubmit={this.handleSubmit}
        onUpdateEntryTotalTime={this.handleUpdateEntryTotalTime}
      />
    )
  }
}

StaffWorklogPreviewContainer.authParams = {
  flags: [FEATURE_FLAGS.STAFF_REGISTER],
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

const mapDispatch = {
  injectValidation: (errors) => stopSubmit(STAFF_WORKLOG_PREVIEW_FORM, errors),
  updateFormField: (fieldName, value) => change(STAFF_WORKLOG_PREVIEW_FORM, fieldName, value),
}

const mapState = (state, {
  appSelectors,
  membershipRegistersListWorklogByDayState,
  membershipRegistersSelectors,
  membershipRegistersSingleState,
  membershipsSelectors,
  params,
}) => ({
  formEntries: formValueSelector(STAFF_WORKLOG_PREVIEW_FORM)(state, 'entries'),
  initialValues: membershipRegistersSelectors.getMembershipRegisterSingleWorklogInitialValues(state),
  isFetching: appSelectors.getIsFetching(membershipRegistersSingleState, membershipRegistersListWorklogByDayState),
  isSubmitting: appSelectors.getIsSubmitting(membershipRegistersSingleState),
  memberDetails: membershipsSelectors.getMembershipFormattedDataSelector(state),
  previousRecord: membershipRegistersSelectors.getListWorklogByPreviousDayOngoing(params.endDate)(state),
  record: membershipRegistersSelectors.getMembershipRegisterSingleWorklogData(state),
  shifts: membershipRegistersSelectors.getListWorklogByCurrentShifts(state),
})

const enhance = compose(
  withAppService,
  withModalService,
  withMembershipsService,
  withMembershipRegistersService,
  connect(mapState, mapDispatch),
)

export default enhance(StaffWorklogPreviewContainer)
