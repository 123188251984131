import moment from 'moment'
import _ from 'lodash'

import React, { useMemo } from 'react'

import { WORK_SHIFT_TYPES } from 'services/legacy/membershipRegisters/constants'

import { convertTimeDuration, millisecondsToHoursAndMinutesString, removeDate, roundMilliseconds } from 'utils/date'

import { isShiftBreak } from 'module/Staff/StaffHelpers'

import { SHIFT_MODAL_MODE } from '../../ShiftModalContainer'
import ShiftModalFormFieldArrayItem from './ShiftModalFormFieldArrayItem'

const ShiftModalFormFieldArray = ({
  date: dateElement,
  disablePastWeek,
  fields,
  isPastWeek,
  isQualifiedExpert,
  isSubmitting,
  mode,
  onChangeField,
  onChangeRecord,
  onChangeType,
  onCopyToAll,
  onSetEndTime,
  onShowChangeLog,
  roomsOptions,
  selectedDate,
  weekIndex,
}) => {
  const dayShifts = fields.getAll()

  const memorizedSummaryTimes = useMemo(() => {
    let summaryTimes = 0

    _.each(dayShifts, (shift = {}) => {
      const finalShiftType = shift.type?.value || shift.type
      const isBreakType = isShiftBreak(finalShiftType)
      const isBreakDurationType = WORK_SHIFT_TYPES.BREAK_DURATION === finalShiftType

      if (!finalShiftType
      || (!isBreakType && (!shift.endTime || !shift.startTime))
      || (WORK_SHIFT_TYPES.BREAK === finalShiftType && (!shift.endTime || !shift.startTime))
      || (isBreakDurationType && !shift.duration)
      ) {
        return
      }

      if (shift.endDateInFuture) {
        const finalStartTime = moment(shift.date)
          .set('hours', moment(shift.startTime).format('HH'))
          .set('minutes', moment(shift.startTime).format('mm'))
          .set('seconds', moment(shift.startTime).format('ss'))

        const result = isBreakDurationType
          ? convertTimeDuration(+shift.duration)
          : moment(shift.endTime).diff(finalStartTime)

        if (!isBreakType) {
          summaryTimes += result
        } else if (mode === SHIFT_MODAL_MODE.PLANNED && isBreakType) {
          summaryTimes -= result
        }
      } else {
        const summaryResult = isBreakDurationType
          ? convertTimeDuration(+shift.duration)
          : moment(removeDate(shift.endTime)).diff(moment(removeDate(shift.startTime)))

        if (!isBreakType) {
          summaryTimes += summaryResult
        } else if (mode === SHIFT_MODAL_MODE.PLANNED && isBreakType) {
          summaryTimes -= summaryResult
        }
      }
    })

    if (0 > summaryTimes) {
      summaryTimes = 0
    }

    summaryTimes = summaryTimes
      ? millisecondsToHoursAndMinutesString(
        roundMilliseconds(summaryTimes),
        false,
        true,
      )
      : '-'

    return summaryTimes
  }, [dayShifts, mode])

  return fields.map((item, index) => {
    const field = dayShifts?.[index] || {}

    return (
      <ShiftModalFormFieldArrayItem
        dateElement={dateElement}
        dayShifts={dayShifts}
        disablePastWeek={disablePastWeek}
        field={field}
        fields={fields}
        index={index}
        isPastWeek={isPastWeek}
        isQualifiedExpert={isQualifiedExpert}
        isSubmitting={isSubmitting}
        item={item}
        key={`shiftTime_${index}`}
        memorizedSummaryTimes={memorizedSummaryTimes}
        mode={mode}
        roomsOptions={roomsOptions}
        selectedDate={selectedDate}
        weekIndex={weekIndex}
        onChangeField={onChangeField}
        onChangeRecord={onChangeRecord}
        onChangeType={onChangeType}
        onCopyToAll={onCopyToAll}
        onSetEndTime={onSetEndTime}
        onShowChangeLog={onShowChangeLog}
      />
    )
  })
}

export default React.memo(ShiftModalFormFieldArray)
