import { FEATURE_FLAGS, ROLES } from 'constants/security'

const RequestedExtraSessionsWrapper = ({ children }) => children

RequestedExtraSessionsWrapper.authParams = {
  flags: [FEATURE_FLAGS.REQUESTING_EXTRA_SESSIONS],
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.DEPUTY_MANAGER,
  ],
}

export default RequestedExtraSessionsWrapper
