import { generateBasicActions } from 'utils/service'

import {
  CLEAR_MONTHLY_FTE_CHANGE_REPORT_STATISTICS,
  MONTHLY_FTE_CHANGE_REPORT_STATISTICS,
  MONTHLY_FTE_CHANGE_REPORT_STATISTICS_FAILED,
  MONTHLY_FTE_CHANGE_REPORT_STATISTICS_SUCCESS,
} from './constants'

export const getStatistics = (options = {}) => ({ monthlyFteChangeReportApiClient }) => generateBasicActions.list({
  constants: {
    failed: MONTHLY_FTE_CHANGE_REPORT_STATISTICS_FAILED,
    init: MONTHLY_FTE_CHANGE_REPORT_STATISTICS,
    success: MONTHLY_FTE_CHANGE_REPORT_STATISTICS_SUCCESS,
  },
  options,
  service: monthlyFteChangeReportApiClient,
  serviceMethod: 'getStatistics',
})

export const clearStatistics = () => ({
  type: CLEAR_MONTHLY_FTE_CHANGE_REPORT_STATISTICS,
})

