import {
  CLEAR_ROOM_MOVE,
  CREATE_ROOM_MOVE,
  CREATE_ROOM_MOVE_FAILED,
  CREATE_ROOM_MOVE_SUCCESS,
  DELETE_ROOM_MOVE,
  DELETE_ROOM_MOVE_FAILED,
  DELETE_ROOM_MOVE_SUCCESS,
  GET_ROOM_MOVE,
  GET_ROOM_MOVE_FAILED,
  GET_ROOM_MOVE_SUCCESS,
  UPDATE_ROOM_MOVE,
  UPDATE_ROOM_MOVE_FAILED,
  UPDATE_ROOM_MOVE_SUCCESS,
} from './constants'

export const getRoomMove = (id, params) => ({ roomMoveApiClient }) => (dispatch) => {
  dispatch({ id, type: GET_ROOM_MOVE })

  roomMoveApiClient.get(id, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: GET_ROOM_MOVE_SUCCESS,
      })
    },
    (error) => {
      dispatch({
        error: error.response,
        type: GET_ROOM_MOVE_FAILED,
      })
    },
  )
}

export const createRoomMove = (payload, onSuccess, onFailed) => ({ roomMoveApiClient }) => (dispatch) => {
  dispatch({
    payload,
    type: CREATE_ROOM_MOVE,
  })

  roomMoveApiClient.create(payload).then(
    (data) => {
      dispatch({
        payload: data,
        type: CREATE_ROOM_MOVE_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: CREATE_ROOM_MOVE_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const updateRoomMove = (id, payload, onSuccess, onFailed) => ({ roomMoveApiClient }) => (dispatch) => {
  dispatch({
    id,
    payload,
    type: UPDATE_ROOM_MOVE,
  })

  roomMoveApiClient.update(id, payload).then(
    (data) => {
      dispatch({
        payload: data,
        type: UPDATE_ROOM_MOVE_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: UPDATE_ROOM_MOVE_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const deleteRoomMove = (id, onSuccess) => ({ roomMoveApiClient }) => (dispatch) => {
  dispatch({
    id,
    type: DELETE_ROOM_MOVE,
  })

  roomMoveApiClient.remove(id).then(
    (data) => {
      dispatch({
        payload: data,
        type: DELETE_ROOM_MOVE_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: DELETE_ROOM_MOVE_FAILED,
      })
    },
  )
}

export const clearRoomMove = () => ({
  type: CLEAR_ROOM_MOVE,
})
