import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Callout, Spinner, Typography } from 'components'
import Settings from 'module/Settings/components'

const SettingsInvoiceNumbersItemView = ({
  errorMessages,
  invoiceNumberSettings,
  isLoading,
  nurseryId,
  nurseryName,
}) => {
  if (isLoading) {
    return <Spinner />
  }

  const renderContent = () => {
    const {
      creditNoteNumberDescription,
      invoiceNumberDescription,
      receiptNumberDescription,
    } = invoiceNumberSettings

    const title = (
      <span>
        {'Invoice number sequencing for '}
        <Typography color={NEUTRAL_COLOURS.BLUE} fontSize="inherit" variant="span" bold>
          {nurseryName}
        </Typography>
      </span>
    )

    return (
      <Settings.InvoiceNumbersSettingView
        creditNoteNumberDescription={creditNoteNumberDescription}
        editButtonLink={`/settings/invoice-numbers/${nurseryId}/edit`}
        invoiceNumberDescription={invoiceNumberDescription}
        receiptNumberDescription={receiptNumberDescription}
        subtitle="This is where you can set the automated number sequencing to generate invoice,
        receipt and credit note numbers."
        title={title}
      />
    )
  }

  return (
    <div>
      <Callout content={errorMessages} error />
      {renderContent()}
    </div>
  )
}

export default SettingsInvoiceNumbersItemView
