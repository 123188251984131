/* eslint-disable typescript-sort-keys/string-enum */
export enum EVENTS {
  AUTHENTICATION_SIGNED_IN = 'authentication signed in', // context: login page, register page
  AUTHENTICATION_SIGNED_OUT = 'authentication signed out',
  // BALANCE ADJUSTMENT
  BALANCE_ADJUSTMENT_ADDED = 'balance adjustment added',
  BALANCE_ADJUSTMENT_ADD_BTN_CLICKED = 'balance adjustment add button clicked', // add context: transaction too
  // CHILD
  CHILD_ADD_BUTTON_CLICKED = 'child add button clicked', // context: list
  CHILD_ENROLLED = 'child enrolled', // add context: enquiry, main form
  CHILD_LIST_EXPORT_BTN_CLICKED = 'child list export button clicked', // add context: list
  CHILD_LIST_EXPORTED = 'child list exported',
  CHILD_PROFILE_EDIT_PAGE_VIEWED = 'child profile edit page viewed', // add information what section been viewed
  CHILD_PROFILE_UPDATED = 'child profile updated', // add context: section
  // DIARY
  DIARY_ADD_ITEM_BTN_CLICKED = 'diary add item button clicked', // context: meals, sleep record, nappy, toilet training,
  DIARY_EDIT_ITEM_BTN_CLICKED = 'diary edit item button clicked', // context: meals, activity, etc
  DIARY_FILTER_USED = 'diary filters used', // type: date, child
  DIARY_FOOD_TAB_CHANGED = 'diary food tab changed',
  DIARY_ITEM_ADDED = 'diary item added', // add type: meals,
  DIARY_ITEM_REMOVED = 'diary item removed',
  DIARY_ITEM_UPDATED = 'diary item updated',
  DIARY_PAGE_VIEWED = 'diary page viewed',
  DIARY_REMOVE_ITEM_BTN_CLICKED = 'diary remove item button clicked', // context: meals, activity, etc
  DIARY_RESET_CONFIRMED = 'diary reset confirmed',
  DIARY_RESET_BTN_CLICKED = 'diary reset button clicked',
  DIARY_SEND_BTN_CLICKED = 'diary send button clicked',
  DIARY_SENT = 'diary sent',
  // INVOICE
  INVOICE_ADD_MANUAL_ITEM_CLICKED = 'invoice add manual item clicked',
  INVOICE_ADD_PAGE_VIEWED = 'invoice add page viewed',
  INVOICE_AUTOCALCULATION_APPLIED = 'invoice autocalucation applied',
  INVOICE_AUTOCALCULATION_BTN_CLICKED = 'invoice autocalucation button clicked',
  INVOICE_DOWNLOADED = 'invoice downloaded',
  INVOICE_DRAFT_CREATED = 'invoice draft created',
  INVOICE_ISSUE_CREDIT_NOTE_CLICKED = 'invoice issue credit note clicked',
  INVOICE_ISSUE_CREDIT_NOTE_SUBMITTED = 'invoice issue credit note submitted',
  INVOICE_LIST_EXPORTED = 'invoice list exported', // add context: child profile, list
  INVOICE_LIST_EXPORT_CLICKED = 'invoice list export clicked', // add context: child profile, list
  INVOICE_LIST_FILTER_USED = 'invoice list filter used', // type
  INVOICE_LIST_PAGE_VIEWED = 'invoice list page viewed',
  INVOICE_MANUAL_ITEM_ADDED = 'invoice manual item added',
  INVOICE_MARK_BAD_DEBT_BTN_CLICKED = 'invoice mark as bad debt button clicked',
  INVOICE_MARK_BAD_DEBT_SUBMITTED = 'invoice mark as bad debt submitted',
  INVOICE_MARK_SENT_CLICKED = 'invoice mark as sent clicked',
  INVOICE_MARK_SENT_SUCCESSFUL = 'invoice mark as sent successful',
  INVOICE_PREVIEW_PAGE_VIEWED = 'invoice preview page viewed',
  INVOICE_REMOVED = 'invoice removed', // context
  INVOICE_REPEAT_BTN_CLICKED = 'invoice repeat button clicked',
  INVOICE_RESEND_BTN_CLICKED = 'invoice resend button clicked',
  INVOICE_RESEND_SUBMITTED = 'invoice resend submitted',
  INVOICE_SEND_BTN_CLICKED = 'invoice send button clicked', // type invoice,credit note
  INVOICE_SEND_SUBMITTED = 'invoice send submitted', // type invoice,credit note
  INVOICE_UNMARK_BAD_DEBT_BTN_CLICKED = 'invoice unmark as bad debt button clicked',
  INVOICE_UNMARK_BAD_DEBT_SUBMITTED = 'invoice unmark as bad debt submitted',
  INVOICE_UPDATED = 'invoice updated', // context
  // OBSERVATION
  OBSERVATION_ADD_BTN_CLICKED = 'observation add button clicked', // widget make observation,widget obser.,list,child
  OBSERVATION_ADD_PAGE_VIEWED = 'observation add page viewed',
  OBSERVATION_APPROVED = 'observation approved',
  OBSERVATION_DELETED = 'observation deleted',
  OBSERVATION_DELETE_BTN_CLICKED = 'observation delete button clicked',
  OBSERVATION_EDIT_BTN_CLICKED = 'observation edit button clicked',
  OBSERVATION_LIST_EXPORTED = 'observation list exported', // add from property: child profile, list
  OBSERVATION_LIST_EXPORT_CLICKED = 'observation list export clicked', // add from property: child profile, list
  OBSERVATION_LIST_FILTER_USED = 'observation list filter used', // add filter and from property: child profile, list
  OBSERVATION_LIST_PAGE_VIEWED = 'observation list page viewed', // add from property: child profile, list
  OBSERVATION_PREVIEW_PAGE_VIEWED = 'observation preview page viewed', // add from property: child profile, list
  OBSERVATION_REVIEW_BTN_CLICKED = 'observation review btn clicked',
  // REGISTER
  REGISTER_ABSENT_BTN_CLICKED = 'register absent button clicked',
  REGISTER_ABSENT_ADDED = 'register absent added',
  REGISTER_COMMENT_BTN_CLICKED = 'register comment button clicked',
  REGISTER_ENTRY_SAVED = 'register entry saved',
  REGISTER_FILTER_USED = 'register filter used',
  REGISTER_DOWNLOADED = 'register downloaded',
  REGISTER_DOWNLOAD_BTN_CLICKED = 'register download button clicked',
  REGISTER_ENTRY_EDIT_BTN_CLICKED = 'register entry edit button clicked',
  REGISTER_EXTRA_SESSION_BTN_CLICKED = 'register extra session button clicked',
  REGISTER_EXTRA_SESSION_ADDED = 'register extra session added',
  REGISTER_PAGE_VIEWED = 'register page viewed',
  REGISTER_RESET_BTN_CLICKED = 'register reset button clicked',
  REGISTER_RESET = 'register reset',
  REGISTER_STAFF_LOG_VIEWED = 'register staff log viewed',
  REGISTER_TIME_IN_BTN_CLICKED = 'register time in button clicked',
  REGISTER_TIME_OUT_BTN_CLICKED = 'register time out button clicked',
  // REGISTER OFFLINE
  REGISTER_ENABLE_OFFLINE_MODE = 'register enable offline mode',
  REGISTER_DISABLE_OFFLINE_MODE = 'register disable offline mode',
  REGISTER_SYNC_OFFLINE_ACTIONS = 'register sync offline actions',
  // SESSION
  SESSION_STARTED = 'session started',
  // STAFF
  STAFF_ADDED = 'staff added', // roles
  STAFF_ADD_BTN_CLICKED = 'staff add button clicked',
  STAFF_DEACTIVATE_BTN_CLICKED = 'staff deactivate button clicked',
  STAFF_EDIT_PAGE_VIEWED = 'staff edit page viewed',
  STAFF_LIST_EXPORTED = 'staff list exported',
  STAFF_LIST_EXPORT_BTN_CLICKED = 'staff list export button clicked',
  STAFF_LIST_PAGE_VIEWED = 'staff list page viewed',
  STAFF_PREVIEW_PAGE_VIEWED = 'staff preview page viewed',
  STAFF_UPDATED = 'staff updated',
  // STAFF ATTENDANCE
  STAFF_ATTENDNACE_PAGE_VIEWED = 'staff attendance page viewed',
  STAFF_ATTENDNACE_FILTER_USED = 'staff attendance filter used',
  // STAFF LEAVE
  STAFF_LEAVE_ADDED = 'staff leave added',
  STAFF_LEAVE_ADD_BTN_CLICKED = 'staff leave add button clicked', // context
  STAFF_LEAVE_ENTITLEMENT_ADDED = 'staff leave entitlement added',
  STAFF_LEAVE_ENTITLEMENT_ADD_BTN_CLICKED = 'staff leave entitlement add button clicked',
  STAFF_LEAVE_EXPORTED = 'staff leave exported',
  STAFF_LEAVE_EXPORT_BTN_CLICKED = 'staff leave export button clicked', // context
  STAFF_LEAVE_LIST_FILTER_USED = 'staff leave list filter used', // context
  STAFF_LEAVE_PAGE_VIEWED = 'staff leave page viewed', // context: staff/overall
  // STAFF REGISTER
  STAFF_REGISTER_BREAK_STARTED = 'staff register break started',
  STAFF_REGISTER_MODE_CLICKED = 'staff register mode clicked',
  STAFF_REGISTER_MODE_ENABLED = 'staff register mode enabled',
  STAFF_REGISTER_LOCATION_CHANGE_CLICKED = 'staff register location change clicked',
  STAFF_REGISTER_LOCATION_CHANGED = 'staff register location changed',
  STAFF_REGISTER_CLOCKED_IN = 'staff register clocked in',
  STAFF_REGISTER_CLOCKED_OUT = 'staff register clocked out',
  STAFF_REGISTER_CLOCK_IN_CLICKED = 'staff register clock in clicked',
  STAFF_REGISTER_FILTER_USED = 'staff register filter used', // filter/search
  STAFF_REGISTER_FORGOT_PIN_CLICKED = 'staff register forgot pin clicked',
  STAFF_REGISTER_PAGE_VIEWED = 'staff register page viewed',
  STAFF_REGISTER_BREAK_ENDED = 'staff register break ended',
  STAFF_REGISTER_PIN_AUTHENTICATED = 'staff register pin authenticated',
  STAFF_REGISTER_PIN_AUTHENTICATED_FAILED = 'staff register pin authenticated failed',
  STAFF_REGISTER_PIN_CREATED = 'staff register pin created',
  // STAFF ROTA
  STAFF_ROTA_EXPORTED = 'staff rota exported',
  STAFF_ROTA_EXPORT_BTN_CLICKED = 'staff rota export button clicked',
  STAFF_ROTA_FILTER_USED = 'staff rota filter used',
  STAFF_ROTA_PAGE_VIEWED = 'staff rota page viewed',
  // STAFF SHIFT
  STAFF_SHIFT_ADDED = 'staff shift added',
  STAFF_SHIFT_ADD_CLICKED = 'staff shift add button clicked',
  // STAFF WORKLOG
  STAFF_WORKLOG_EXPORTED = 'staff worklog exported',
  STAFF_WORKLOG_EXPORT_CLICKED = 'staff worklog export button clicked',
  STAFF_WORKLOG_FILTER_USED = 'staff worklog fitlter used',
  STAFF_WORKLOG_PAGE_VIEWED = 'staff worklog page viewed',
  // TRANSACTIONS
  TRANSACTION_LIST_DOWNLOADED = 'transaction list downloaded',
  TRANSACTION_LIST_DOWNLOAD_BTN_CLICKED = 'transaction list download button clicked',
  TRANSACTION_PAGE_VIEWED = 'transaction page viewed',
  TRANSACTION_SEND_CLICKED = 'transation send button clicked',
  TRANSACTION_SEND_SUBMITTED = 'transaction send submitted',
}
