import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_ACTIVE_RECURRING_PAYMENT,
  GET_ACTIVE_RECURRING_PAYMENT,
  GET_ACTIVE_RECURRING_PAYMENT_EXPORT,
  GET_ACTIVE_RECURRING_PAYMENT_EXPORT_FAILED,
  GET_ACTIVE_RECURRING_PAYMENT_EXPORT_SUCCESS,
  GET_ACTIVE_RECURRING_PAYMENT_FAILED,
  GET_ACTIVE_RECURRING_PAYMENT_SUCCESS,
} from './constants'

export const getActiveRecurringPayment = (
  options: BasicActionOptionsProps,
) => ({ nurseryIntegrationsApiClient }) => generateBasicActions.list({
  constants: {
    failed: GET_ACTIVE_RECURRING_PAYMENT_FAILED,
    init: GET_ACTIVE_RECURRING_PAYMENT,
    success: GET_ACTIVE_RECURRING_PAYMENT_SUCCESS,
  },
  options,
  service: nurseryIntegrationsApiClient,
  serviceMethod: 'activeRecurringPayment',
})

export const exportActiveRecurringPayment = (options = {}) => ({
  nurseryIntegrationsApiClient,
}) => generateBasicActions.list({
  constants: {
    failed: GET_ACTIVE_RECURRING_PAYMENT_EXPORT_FAILED,
    init: GET_ACTIVE_RECURRING_PAYMENT_EXPORT,
    success: GET_ACTIVE_RECURRING_PAYMENT_EXPORT_SUCCESS,
  },
  options,
  service: nurseryIntegrationsApiClient,
  serviceMethod: 'exportActiveRecurringPayment',
})

export const clearActiveRecurringPayment = () => ({
  type: CLEAR_ACTIVE_RECURRING_PAYMENT,
})
