import _ from 'lodash'

import i18n from 'translations'

export const COMMON_TABLE_COLUMNS = [
  {
    field: 'win',
    title: i18n.t('module:Enquiries:Report:Performance:Table:Header:won'),
  },
  {
    field: 'lost',
    title: i18n.t('module:Enquiries:Report:Performance:Table:Header:lost'),
  },
  {
    field: 'open',
    title: i18n.t('module:Enquiries:Report:Performance:Table:Header:open'),
  },
  {
    field: 'total',
    title: i18n.t('module:Enquiries:Report:Performance:Table:Header:total'),
  },
]

export const BREAKDOWN_MODE_TYPE = {
  MONTH: 'month',
  WEEK: 'week',
}

export const BREAKDOWN_MODE_OPTIONS = [
  {
    label: _.upperFirst(i18n.t('global:month')),
    value: BREAKDOWN_MODE_TYPE.MONTH,
  },
  {
    label: _.upperFirst(i18n.t('global:week')),
    value: BREAKDOWN_MODE_TYPE.WEEK,
  },
]
