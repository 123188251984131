import _ from 'lodash'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { MENU_ITEMS_STATUS } from 'services/legacy/foodTypes/constants'

import { change } from 'redux-form'

import { withAppService } from 'services/app'
import { withFoodTypesService } from 'services/legacy/foodTypes'

import { Select } from 'components'

import i18n from 'translations'

import withInfiniteDropdown from './withInfiniteDropdown'

class MenuItemsDropdown extends Component {
  componentDidMount() {
    const { foodTypesActions, infiniteDropdownHelpers, value } = this.props

    if (!_.isArray(value)) {
      return infiniteDropdownHelpers.handleComponentDidMount({
        action: () => foodTypesActions.getDropdownItem({
          onFailed: this.notFoundValue,
          onSuccess: infiniteDropdownHelpers.handleComponentDidMountOnSuccess,
          params: [value?.value || value],
        }),
        value,
      })
    }

    return null
  }

  handleLoadMoreElements = async (phrase) => {
    const {
      foodTypesActions,
      foodTypesSelectors,
      infiniteDropdownHelpers,
      menuItemCategory,
    } = this.props

    return infiniteDropdownHelpers.handleLoadMoreElements({
      clearAction: foodTypesActions.clearDropdownList,
      listAction: ({ newPage, newSearchPhrase, result }) => foodTypesActions.dropdownList({
        onSuccess: (response) => infiniteDropdownHelpers.handleLoadMoreElementsOnSuccess({
          newPage,
          newSearchPhrase,
          response,
          result,
        }),
        onlyData: true,
        params: {
          criteria: foodTypesSelectors.getListCriteria({
            category: menuItemCategory,
            search: newSearchPhrase,
            status: MENU_ITEMS_STATUS.ACTIVE,
          }),
          page: newPage,
        },
      }),
      phrase,
    })
  }

  notFoundValue = () => {
    const { onChange } = this.props

    onChange(null)
  }

  handleChange = (e) => {
    const { infiniteDropdownHelpers, onChange } = this.props

    infiniteDropdownHelpers.handleOnChange(e, () => onChange(e))
  }

  handleCreateOptionSuccess = ({ data }) => {
    const {
      changeFieldValue,
      fieldName,
      formName,
      infiniteDropdownHelpers,
    } = this.props

    infiniteDropdownHelpers.handleResetCacheUniq()
    changeFieldValue(formName, fieldName, {
      label: data.name,
      value: data.id,
    })
  }

  handleCreateOption = (value) => {
    const {
      foodTypesActions,
      menuItemCategory,
    } = this.props

    const body = {
      category: menuItemCategory,
      name: value,
    }

    foodTypesActions.create({
      body,
      onSuccess: this.handleCreateOptionSuccess,
      params: {},
    })
  }

  render() {
    const {
      disabled,
      infiniteDropdownState,
      placeholder,
      rawValue,
      value,
      ...rest
    } = this.props
    const { cacheUniq, options, selectedValue } = infiniteDropdownState

    return (
      <Select
        {...rest}
        cacheUniq={cacheUniq}
        disabled={disabled}
        loadOptions={this.handleLoadMoreElements}
        options={options}
        placeholder={placeholder
        || i18n.t('module:DailyDiary:Foods:foodMenu:typeAFoodOption')}
        rawValue={rawValue || selectedValue || value}
        value={value}
        clearable
        searchable
        onChange={this.handleChange}
        onCreateOption={this.handleCreateOption}
      />
    )
  }
}

const mapDispatch = {
  changeFieldValue: (form, field, value) => change(form, field, value),
}

const enhance = compose(
  withAppService,
  withFoodTypesService,
  withInfiniteDropdown,
  connect(null, mapDispatch),
)

export default enhance(MenuItemsDropdown)
