import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import {
  CLEAR_LIST_CHILDREN,
  LIST_CHILDREN,
  LIST_CHILDREN_FAILED,
  LIST_CHILDREN_SUCCESS,
  SEND_FORMATIVE_REPORT,
  SEND_FORMATIVE_REPORT_FAILED,
  SEND_FORMATIVE_REPORT_SUCCESS,
  SEND_REPORT,
  SEND_REPORT_FAILED,
  SEND_REPORT_SUCCESS,
} from './constants'

const initialState = {
  ...getBasicListInitialState,
  reportIsFetching: false,
}

export default (...props) => {
  const [state = initialState, { type }] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_LIST_CHILDREN,
    failed: LIST_CHILDREN_FAILED,
    init: LIST_CHILDREN,
    success: LIST_CHILDREN_SUCCESS,
  }, initialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  switch (type) {
    case SEND_REPORT:
    case SEND_FORMATIVE_REPORT:
      return { ...state, reportIsFetching: true }
    case SEND_REPORT_SUCCESS:
    case SEND_REPORT_FAILED:
    case SEND_FORMATIVE_REPORT_SUCCESS:
    case SEND_FORMATIVE_REPORT_FAILED:
      return { ...state, reportIsFetching: false }
    default:
      return state
  }
}
