import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { CHILD_FUNDING_FILTERS } from 'services/legacy/childFunding/constants'

const getFinanceReports = (state) => state.financeFundingReport

export const getFinanceFundingReportChildren = createSelector(
  [getFinanceReports],
  (state) => state.children,
)

export const getFinanceFundingReportChildrenData = createSelector(
  [getFinanceFundingReportChildren],
  (state) => state.data,
)

export const getCriteria = ({ ageRange, child, fundingTypes, nursery, room, status }) => {
  const criteria = []

  if (status && status !== CHILD_FUNDING_FILTERS.ALL) {
    criteria.push({
      field: 'archived',
      value: status === CHILD_FUNDING_FILTERS.ARCHIVED ? 1 : 0,
    })
  }

  if (ageRange) {
    const { from, to } = ageRange

    if (from) {
      criteria.push({
        comparator: 'before',
        field: 'child.birthDate',
        value: moment().add(-from, 'months').format(DEFAULT_DATE_FORMAT),
      })
    }

    if (to) {
      criteria.push({
        comparator: 'after',
        field: 'child.birthDate',
        value: moment().add(-to, 'months').add(-1, 'day').format(DEFAULT_DATE_FORMAT),
      })
    }
  }

  if (nursery) {
    criteria.push({
      field: 'nursery',
      value: nursery.value,
    })
  }

  if (room) {
    criteria.push({
      field: 'nurseryClass',
      value: room,
    })

    criteria.push({
      field: 'child.nurseryClass',
      value: room,
    })
  }

  if (child) {
    criteria.push({
      field: 'child',
      value: child,
    })
  }

  if (fundingTypes) {
    if (_.isArray(fundingTypes)) {
      _.each(fundingTypes, (id) => {
        if (id) {
          criteria.push({
            field: 'funding[]',
            value: id,
          })
        }
      })
    } else {
      criteria.push({
        field: 'funding[]',
        value: fundingTypes,
      })
    }
  }

  return criteria
}

