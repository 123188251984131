import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { MENU_ITEMS_CATEGORY } from 'services/legacy/foodTypes/constants'

import { withAppService } from 'services/app'
import { withFoodTypesService } from 'services/legacy/foodTypes'
import { withSnackbarService } from 'services/utils/snackbar'
import { withModalService } from 'services/utils/modal'
import { withRouter } from 'services/router'

import { getIsMealsContext, getRoute, getTransPrefix, handleArchiveClick } from '../ManagementMenuItemsHelpers'
import MenuItemsAddView from './MenuItemsAddView'

const GROUPS = {
  read: [
    'foodType.hasBeenUsed',
  ],
}

class MenuItemsAddContainer extends Component {
  constructor(props) {
    super(props)

    const { location: { pathname }, params: { id } } = this.props

    const isMealsContext = getIsMealsContext(pathname)

    this.state = {
      isEdit: !!id,
      isMealsContext,
      isUsedInDiaryEntry: false,
      prefix: getTransPrefix(isMealsContext),
    }
  }

  componentDidMount() {
    const { isEdit } = this.state

    if (isEdit) {
      this.fetch()
    }
  }

  componentWillUnmount() {
    const { foodTypesActions } = this.props

    foodTypesActions.clear()
    foodTypesActions.clearList()
  }

  handleFetchSuccess = ({ data }) => {
    const { hasBeenUsed } = data

    this.setState({ isUsedInDiaryEntry: hasBeenUsed })
  }

  fetch = () => {
    const { foodTypesActions, params } = this.props
    const { id } = params

    return foodTypesActions.get({
      onSuccess: this.handleFetchSuccess,
      params: [id, { groups: GROUPS }],
    })
  }

  goToList = () => {
    const { navigate } = this.props
    const { isMealsContext } = this.state

    return navigate(getRoute({ isMealsContext }))
  }

  handleSubmit = (fields) => {
    const { foodTypesActions, params } = this.props
    const { isEdit, isMealsContext } = this.state
    const { id } = params

    const body = {
      ...fields,
      category: isMealsContext ? MENU_ITEMS_CATEGORY.MEAL : MENU_ITEMS_CATEGORY.SNACK,
    }

    if (isEdit) {
      return foodTypesActions.update({
        body,
        onSuccess: this.goToList,
        params: [id, {}],
      })
    }

    return foodTypesActions.create({
      body,
      onSuccess: this.goToList,
      params: {},
    })
  }

  handleArchiveClick = () => {
    const {
      data,
      foodTypesActions,
      modalActions,
      modalConsts,
      params,
      snackbarActions,
    } = this.props
    const { prefix } = this.state
    const { id } = params
    const { archived, name } = data

    return handleArchiveClick({
      foodTypesActions,
      isArchived: archived,
      isSingleRecord: true,
      modalActions,
      modalConsts,
      name,
      onSuccess: this.goToList,
      prefix,
      selected: [id],
      snackbarActions,
    })
  }

  render() {
    const {
      data,
      errorMessages,
      initialValues,
      isFetching,
      isSubmitting,
    } = this.props
    const {
      isEdit,
      isMealsContext,
      isUsedInDiaryEntry,
      prefix,
    } = this.state

    return (
      <MenuItemsAddView
        data={data}
        errorMessages={errorMessages}
        initialValues={initialValues}
        isEdit={isEdit}
        isFetching={isFetching}
        isMealsContext={isMealsContext}
        isSubmitting={isSubmitting}
        isUsedInDiaryEntry={isUsedInDiaryEntry}
        prefix={prefix}
        onArchiveClick={this.handleArchiveClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  foodTypesListState,
  foodTypesSelectors,
  foodTypesSingleState,
}) => ({
  data: foodTypesSelectors.getFoodTypesSingleDataSelector(state),
  errorMessages: appSelectors.getErrorMessages(foodTypesSingleState, foodTypesListState),
  initialValues: foodTypesSelectors.getInitialValues(state),
  isFetching: appSelectors.getIsFetching(foodTypesSingleState, foodTypesListState),
  isSubmitting: appSelectors.getIsSubmitting(foodTypesSingleState),
})

const enhance = compose(
  withAppService,
  withFoodTypesService,
  withModalService,
  withRouter,
  withSnackbarService,
  connect(mapState),
)

export default enhance(MenuItemsAddContainer)
