import React, { useRef, useState } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getFormValues as getFormValuesForm } from 'redux-form'

import { Field, Typography } from 'components'

import FinanceNotesForm from './FinanceNotesForm'

const FinanceNotes = ({
  childBankNotes,
  childId,
  getFormValues,
  onSubmit,
}) => {
  const [isEdit, setEdit] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)

  const myForm = useRef()

  const handleClose = () => {
    setEdit(false)
  }

  const handleSuccess = () => {
    setEdit(false)
    setSubmitting(false)
  }

  const handleSubmit = (fields) => {
    setSubmitting(true)

    onSubmit(fields)(handleSuccess)
  }

  const handleEditClick = () => {
    if (isEdit) {
      const formValues = getFormValues(`${childId}_NOTES`)

      handleSubmit(formValues)
    } else {
      setEdit(true)
    }
  }

  return (
    <Field.InlineEdit
      actions={(
        <Field.InlineEdit.Actions
          isEdit={isEdit}
          onCancelClick={handleClose}
          onEditClick={handleEditClick}
          onSaveClick={handleEditClick}
        />
      )}
      description={(
        <Typography wordBreak="break-all">
          {childBankNotes}
        </Typography>
      )}
      editableComponent={(
        <FinanceNotesForm
          form={`${childId}_NOTES`}
          initialValues={{ notes: childBankNotes }}
          ref={myForm}
          onSubmit={handleSubmit}
        />
      )}
      isEditMode={isEdit}
      isLoading={isSubmitting}
      noPadding
    />
  )
}

const mapState = (state) => ({
  getFormValues: (formName) => getFormValuesForm(formName)(state),
})

const enhance = compose(
  connect(mapState),
)

export default enhance(FinanceNotes)
