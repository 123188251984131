import _ from 'lodash'

import React from 'react'

import { GENDER_DROPDOWN_WITH_OTHER } from 'services/legacy/child/constants'

import { generateRoute } from 'utils/routing'

import { Panel } from 'components'

import i18n from 'translations'

const Contact = ({ enquiry }) => {
  const { contactName, data, id } = enquiry
  const { contact } = data
  const {
    address,
    email,
    gender,
    relation,
    telephone,
  } = contact

  return (
    <Panel
      title={i18n.t('global:Contact')}
      to={`${generateRoute('ENQUIRIES.EDIT', { enquiryId: id })}?s=contact`}
    >
      <Panel.Item
        label={i18n.t('global:Name')}
        value={contactName}
      />
      <Panel.Item
        label={i18n.t('module:Enquiries:List:Add:ContactInformation:Gender:label')}
        value={_.get(_.find(GENDER_DROPDOWN_WITH_OTHER, ({ value }) => value === gender), 'label')}
      />
      <Panel.Item
        label={i18n.t('module:Enquiries:List:Add:ContactInformation:RelationToChild:label')}
        value={relation}
      />
      <Panel.Item
        label={i18n.t('module:Enquiries:List:Add:ContactInformation:Phone:label')}
        value={telephone}
      />
      <Panel.Item
        label={i18n.t('module:Enquiries:List:Add:ContactInformation:Email:label')}
        value={email}
      />
      <Panel.Item
        label={i18n.t('module:Enquiries:List:Add:ContactInformation:Address:label')}
        value={address}
      />
    </Panel>
  )
}

export default Contact
