import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'

import { BUTTON_SIZE } from 'components/Button/constants'

import { Field } from 'components'

import withInvoiceSettingsComponent from '../withInvoiceSettingsComponent'
import InvoiceDateSettingsForm from './InvoiceDateSettingsForm'
import InvoiceActionButton from '../InvoiceActionButton'

const FORM_NAME_PREFIX = 'InvoiceRadioSettingsForm'

class InvoiceDateSettings extends Component {
  constructor(props) {
    super(props)

    const { fields: { item: { id } } } = props

    this.state = {
      formName: `${FORM_NAME_PREFIX}_${id}`,
    }
  }

  handleSubmit = (fields) => {
    const { fields: { item: { id } }, onSubmit } = this.props

    const payload = {
      ...fields,
      [id]: fields[id] ? moment(fields[id]).format('YYYY-MM-DD') : null,
    }

    onSubmit(payload)
  }

  handleGlobalOptionClick = (globalValue) => {
    const { fields: { item: { id, orgValue } }, updateOrgValue } = this.props

    const { formName } = this.state

    if (globalValue) {
      updateOrgValue(formName, id, orgValue)
    }
  }

  render() {
    const {
      caption,
      disableEdit,
      fields: { global, item },
      hideGlobalOption,
      isEdit,
      isGlobalOptionOn,
      isSubmitting,
      onCancelClick,
      onEditClick,
    } = this.props
    const { id, value } = item
    const { id: globalId, value: globalValue } = global

    const initialValues = globalId ? { [globalId]: globalValue, [id]: value } : { [id]: value }
    const label = value ? moment(value).format('DD/MM/YYYY') : null

    return (
      <Field.InlineEdit
        actions={(
          <InvoiceActionButton
            buttonSize={BUTTON_SIZE.MEDIUM}
            disableEdit={disableEdit}
            formRef={this.myForm}
            isEditMode={isEdit}
            onCancelClick={onCancelClick}
            onClick={onEditClick}
          />
        )}
        description={label}
        editableComponent={(
          <InvoiceDateSettingsForm
            form={`${FORM_NAME_PREFIX}_${id}`}
            globalId={globalId}
            hideGlobalOption={hideGlobalOption}
            id={id}
            initialValues={initialValues}
            isGlobalOptionOn={isGlobalOptionOn}
            ref={(ref) => { this.myForm = ref }}
            onGlobalOptionClick={this.handleGlobalOptionClick}
            onSubmit={this.handleSubmit}
          />
        )}
        isEditMode={isEdit}
        isLoading={isSubmitting}
        label={caption}
      />
    )
  }
}

const enhance = compose(withInvoiceSettingsComponent(FORM_NAME_PREFIX))

export default enhance(InvoiceDateSettings)
