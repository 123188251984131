import { MODULE_TYPE } from './constants'

export const getTransPrefix = (moduleType) => {
  if (MODULE_TYPE.ENQUIRY === moduleType) {
    return 'module:Modals:Enquiry:Export:Enquiry:'
  }

  return 'module:Modals:Enquiry:Export:Viewing:'
}

