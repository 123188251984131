import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`

export const StyledLabel = styled.div`
  margin-left: 10px;
  font-size: 14px;  
`

export const StyledCircle = styled.div`
  background: ${({ background }) => background};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  color: ${NEUTRAL_COLOURS.WHITE};
  font-size: 12px;
  justify-content: center;
  font-weight: 600;
`
