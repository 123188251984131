import { generateBasicActions } from 'utils/service'

import {
  CLEAR_ENQUIRY_CONVERSION_RATE_REPORT,
  GET_ENQUIRY_CONVERSION_RATE_REPORT,
  GET_ENQUIRY_CONVERSION_RATE_REPORT_FAILED,
  GET_ENQUIRY_CONVERSION_RATE_REPORT_SUCCESS,
} from './constants'

export const getConversionRate = (options = {}) => ({ enquiryReportsApiClient }) => generateBasicActions.list({
  constants: {
    failed: GET_ENQUIRY_CONVERSION_RATE_REPORT_FAILED,
    init: GET_ENQUIRY_CONVERSION_RATE_REPORT,
    success: GET_ENQUIRY_CONVERSION_RATE_REPORT_SUCCESS,
  },
  options,
  service: enquiryReportsApiClient,
  serviceMethod: 'getConversionRate',
})

export const clearConversionRate = () => ({
  type: CLEAR_ENQUIRY_CONVERSION_RATE_REPORT,
})
