import _ from 'lodash'

import i18n from 'translations'

export const PHOTO_PERMISSIONS_REPORT_LIST_COLUMNS = [
  {
    align: 'left',
    field: 'child',
    sortKey: 'firstName',
    sortable: true,
    title: _.upperFirst(i18n.t('global:child')),
  },
  {
    align: 'center',
    field: 'birthDate',
    sortKey: '',
    sortable: true,
    title: i18n.t('global:dobAge'),
  },
  {
    align: 'center',
    field: 'photoPermission',
    sortKey: 'canBePhotographed',
    sortable: true,
    title: i18n.t('module:Reports:PhotoPermissions:TableHeaders:photoPermission'),
  },
  {
    align: 'center',
    field: 'groupPhotoPermission',
    sortKey: 'canBeTaggedInGroupPhotos',
    sortable: true,
    title: i18n.t('module:Reports:PhotoPermissions:TableHeaders:groupPhotoPermission'),
  },
  {
    align: 'center',
    field: 'shareOnSocialMedia',
    sortKey: 'socialMediaPhotoSharingPermitted',
    sortable: true,
    title: i18n.t('module:Reports:PhotoPermissions:TableHeaders:shareOnSocialMedia'),
  },
  {
    align: 'center',
    field: 'closedSocialMediaPhotoSharingPermitted',
    sortKey: 'closedSocialMediaPhotoSharingPermitted',
    sortable: true,
    title: i18n.t('module:Reports:PhotoPermissions:TableHeaders:closedSocialMediaPhotoSharingPermitted'),
  },
  {
    align: 'center',
    field: 'useOnSiteMediaPermitted',
    sortKey: 'useOnSiteMediaPermitted',
    sortable: true,
    title: i18n.t('module:Reports:PhotoPermissions:TableHeaders:useOnSiteMediaPermitted'),
  },
  {
    align: 'center',
    field: 'shareOnOtherMarketingMaterial',
    sortKey: 'otherMarketingMaterialPhotoSharingPermitted',
    sortable: true,
    title: i18n.t('module:Reports:PhotoPermissions:TableHeaders:shareOnOtherMarketingMaterial'),
  },
]
