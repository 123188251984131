import styled from 'styled-components'

import hexagon from 'assets/images/hexagon.svg'

export const StyledHexagon = styled.div`
  width: 50px;
  height: 42px;
  background: url(${hexagon}) no-repeat center center;
  background-size: auto 100%;
  padding: 8px 8px 8px 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
`
