import React from 'react'

import { Callout, EmptyState, Page, Section, Space, Spinner, Typography } from 'components'

import i18n from 'translations'

import ExceptionalUnavailableTimesTable from './components/ExceptionalUnavailableTimesTable'
import RegularUnavailableTimesTable from './components/RegularUnavailableTimesTable'
import { StyledSpinnerWrapper } from './UnavailableTimesStyled'

const UnavailableTimesView = ({
  errorMessages,
  exceptionalUnavailableTimes,
  isExceptionalUnavailableTimesFetching,
  isNurseryFetching,
  isRegularUnavailableTimesFetching,
  onAddExceptionalUnavailableTime,
  onEditRegularUnavailableTimes,
  onPageChange,
  onSortChange,
  openingDays,
  page,
  pageCount,
  regularUnavailableTimes,
  sortField,
  sortOrder,
}) => {
  const renderRegularUnavailableTimesTable = () => {
    if (isRegularUnavailableTimesFetching) {
      return <Spinner />
    }

    return (
      <RegularUnavailableTimesTable
        data={regularUnavailableTimes}
        openingDays={openingDays}
      />
    )
  }

  const renderExceptionalUnavailableTimesTable = () => {
    if (isExceptionalUnavailableTimesFetching && 1 === page) {
      return (
        <StyledSpinnerWrapper>
          <Spinner />
        </StyledSpinnerWrapper>
      )
    }

    if (!exceptionalUnavailableTimes?.length) {
      return (
        <EmptyState
          icon="time"
          text1={
            i18n.t('module:Management:ParentApp:RequestableExtraSessions:UnavailableTimes:Exceptional:emptyState')
          }
        />
      )
    }

    return (
      <ExceptionalUnavailableTimesTable
        data={exceptionalUnavailableTimes}
        isFetching={isExceptionalUnavailableTimesFetching}
        page={page}
        pageCount={pageCount}
        sortField={sortField}
        sortOrder={sortOrder}
        onPageChange={onPageChange}
        onSortChange={onSortChange}
      />
    )
  }

  const renderContent = () => {
    if (isNurseryFetching) {
      return <Spinner />
    }

    return (
      <React.Fragment>
        <Section
          title={i18n.t('module:Management:ParentApp:RequestableExtraSessions:UnavailableTimes:TermTimes:title')}
          variant="small"
          noBorder
        >
          <Typography>
            {i18n.t('module:Management:ParentApp:RequestableExtraSessions:UnavailableTimes:TermTimes:text')}
          </Typography>
        </Section>
        <Space space="35px" />
        <Section
          title={i18n.t('module:Management:ParentApp:RequestableExtraSessions:UnavailableTimes:ClosureDates:title')}
          variant="small"
          noBorder
        >
          <Typography>
            {i18n.t('module:Management:ParentApp:RequestableExtraSessions:UnavailableTimes:ClosureDates:text')}
          </Typography>
        </Section>
        <Space space="20px" />
        <Section
          actions={(
            <Section.Actions
              tertiary={{
                label: i18n.t('module:Management:ParentApp:RequestableExtraSessions:UnavailableTimes:Regular:setTimes'),
                onClick: onEditRegularUnavailableTimes,
              }}
            />
          )}
          title={i18n.t('module:Management:ParentApp:RequestableExtraSessions:UnavailableTimes:Regular:title')}
          tooltip={i18n.t('module:Management:ParentApp:RequestableExtraSessions:UnavailableTimes:Regular:tooltip')}
          variant="small"
          noBorder
        >
          {renderRegularUnavailableTimesTable()}
        </Section>
        <Space space="20px" />
        <Section
          actions={(
            <Section.Actions
              tertiary={{
                label:
                  i18n.t('module:Management:ParentApp:RequestableExtraSessions:UnavailableTimes:Exceptional:addTime'),
                onClick: onAddExceptionalUnavailableTime,
              }}
            />
          )}
          title={i18n.t('module:Management:ParentApp:RequestableExtraSessions:UnavailableTimes:Exceptional:title')}
          tooltip={i18n.t('module:Management:ParentApp:RequestableExtraSessions:UnavailableTimes:Exceptional:tooltip')}
          variant="small"
          noBorder
        >
          {renderExceptionalUnavailableTimesTable()}
        </Section>
      </React.Fragment>
    )
  }

  return (
    <Page.Section
      isLoading={isNurseryFetching}
      title={i18n.t('module:Management:ParentApp:RequestableExtraSessions:UnavailableTimes:title')}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default UnavailableTimesView
