import { createGlobalStyle } from 'styled-components'

export const FlagsStyled = createGlobalStyle`
  .flags {
    width: 24px;
    height: 18px;
    background-repeat: no-repeat;
    background-image: url('/images/flags.svg');
  }
  
  .flags--ac {
    background-position: 0 0;
  }
  
  .flags--ad {
    background-position: 7.142857142857143% 0;
  }
  
  .flags--ae {
    background-position: 0 5.882352941176471%;
  }
  
  .flags--af {
    background-position: 7.142857142857143% 5.882352941176471%;
  }
  
  .flags--ag {
    background-position: 14.285714285714286% 0;
  }
  
  .flags--ai {
    background-position: 14.285714285714286% 5.882352941176471%;
  }
  
  .flags--al {
    background-position: 0 11.764705882352942%;
  }
  
  .flags--am {
    background-position: 7.142857142857143% 11.764705882352942%;
  }
  
  .flags--an {
    background-position: 14.285714285714286% 11.764705882352942%;
  }
  
  .flags--ao {
    background-position: 0 17.647058823529413%;
  }
  
  .flags--aq {
    background-position: 7.142857142857143% 17.647058823529413%;
  }
  
  .flags--ar {
    background-position: 14.285714285714286% 17.647058823529413%;
  }
  
  .flags--as {
    background-position: 21.428571428571427% 0;
  }
  
  .flags--at {
    background-position: 21.428571428571427% 5.882352941176471%;
  }
  
  .flags--au {
    background-position: 21.428571428571427% 11.764705882352942%;
  }
  
  .flags--aw {
    background-position: 21.428571428571427% 17.647058823529413%;
  }
  
  .flags--ax {
    background-position: 0 23.529411764705884%;
  }
  
  .flags--az {
    background-position: 7.142857142857143% 23.529411764705884%;
  }
  
  .flags--ba {
    background-position: 14.285714285714286% 23.529411764705884%;
  }
  
  .flags--bb {
    background-position: 21.428571428571427% 23.529411764705884%;
  }
  
  .flags--bd {
    background-position: 28.571428571428573% 0;
  }
  
  .flags--be {
    background-position: 28.571428571428573% 5.882352941176471%;
  }
  
  .flags--bf {
    background-position: 28.571428571428573% 11.764705882352942%;
  }
  
  .flags--bg {
    background-position: 28.571428571428573% 17.647058823529413%;
  }
  
  .flags--bh {
    background-position: 28.571428571428573% 23.529411764705884%;
  }
  
  .flags--bi {
    background-position: 0 29.41176470588235%;
  }
  
  .flags--bj {
    background-position: 7.142857142857143% 29.41176470588235%;
  }
  
  .flags--bl {
    background-position: 14.285714285714286% 29.41176470588235%;
  }
  
  .flags--bm {
    background-position: 21.428571428571427% 29.41176470588235%;
  }
  
  .flags--bn {
    background-position: 28.571428571428573% 29.41176470588235%;
  }
  
  .flags--bo {
    background-position: 35.714285714285715% 0;
  }
  
  .flags--bq {
    background-position: 35.714285714285715% 5.882352941176471%;
  }
  
  .flags--br {
    background-position: 35.714285714285715% 11.764705882352942%;
  }
  
  .flags--bs {
    background-position: 35.714285714285715% 17.647058823529413%;
  }
  
  .flags--bt {
    background-position: 35.714285714285715% 23.529411764705884%;
  }
  
  .flags--bv {
    background-position: 35.714285714285715% 29.41176470588235%;
  }
  
  .flags--bw {
    background-position: 0 35.294117647058826%;
  }
  
  .flags--by {
    background-position: 7.142857142857143% 35.294117647058826%;
  }
  
  .flags--bz {
    background-position: 14.285714285714286% 35.294117647058826%;
  }
  
  .flags--ca {
    background-position: 21.428571428571427% 35.294117647058826%;
  }
  
  .flags--cc {
    background-position: 28.571428571428573% 35.294117647058826%;
  }
  
  .flags--cd {
    background-position: 35.714285714285715% 35.294117647058826%;
  }
  
  .flags--cf {
    background-position: 0 41.1764705882353%;
  }
  
  .flags--cg {
    background-position: 7.142857142857143% 41.1764705882353%;
  }
  
  .flags--ch {
    background-position: 14.285714285714286% 41.1764705882353%;
  }
  
  .flags--ci {
    background-position: 21.428571428571427% 41.1764705882353%;
  }
  
  .flags--ck {
    background-position: 28.571428571428573% 41.1764705882353%;
  }
  
  .flags--cl {
    background-position: 35.714285714285715% 41.1764705882353%;
  }
  
  .flags--cm {
    background-position: 42.857142857142854% 0;
  }
  
  .flags--cn {
    background-position: 42.857142857142854% 5.882352941176471%;
  }
  
  .flags--co {
    background-position: 42.857142857142854% 11.764705882352942%;
  }
  
  .flags--cp {
    background-position: 42.857142857142854% 17.647058823529413%;
  }
  
  .flags--cr {
    background-position: 42.857142857142854% 23.529411764705884%;
  }
  
  .flags--cu {
    background-position: 42.857142857142854% 29.41176470588235%;
  }
  
  .flags--cv {
    background-position: 42.857142857142854% 35.294117647058826%;
  }
  
  .flags--cw {
    background-position: 42.857142857142854% 41.1764705882353%;
  }
  
  .flags--cx {
    background-position: 0 47.05882352941177%;
  }
  
  .flags--cy {
    background-position: 7.142857142857143% 47.05882352941177%;
  }
  
  .flags--cz {
    background-position: 14.285714285714286% 47.05882352941177%;
  }
  
  .flags--de {
    background-position: 21.428571428571427% 47.05882352941177%;
  }
  
  .flags--dg {
    background-position: 28.571428571428573% 47.05882352941177%;
  }
  
  .flags--dj {
    background-position: 35.714285714285715% 47.05882352941177%;
  }
  
  .flags--dk {
    background-position: 42.857142857142854% 47.05882352941177%;
  }
  
  .flags--dm {
    background-position: 50% 0;
  }
  
  .flags--do {
    background-position: 50% 5.882352941176471%;
  }
  
  .flags--dz {
    background-position: 50% 11.764705882352942%;
  }
  
  .flags--ea {
    background-position: 50% 17.647058823529413%;
  }
  
  .flags--ec {
    background-position: 50% 23.529411764705884%;
  }
  
  .flags--ee {
    background-position: 50% 29.41176470588235%;
  }
  
  .flags--eg {
    background-position: 50% 35.294117647058826%;
  }
  
  .flags--eh {
    background-position: 50% 41.1764705882353%;
  }
  
  .flags--er {
    background-position: 50% 47.05882352941177%;
  }
  
  .flags--es {
    background-position: 0 52.94117647058823%;
  }
  
  .flags--es-ct {
    background-position: 7.142857142857143% 52.94117647058823%;
  }
  
  .flags--es-ga {
    background-position: 14.285714285714286% 52.94117647058823%;
  }
  
  .flags--et {
    background-position: 21.428571428571427% 52.94117647058823%;
  }
  
  .flags--eu {
    background-position: 28.571428571428573% 52.94117647058823%;
  }
  
  .flags--fi {
    background-position: 35.714285714285715% 52.94117647058823%;
  }
  
  .flags--fj {
    background-position: 42.857142857142854% 52.94117647058823%;
  }
  
  .flags--fk {
    background-position: 50% 52.94117647058823%;
  }
  
  .flags--fm {
    background-position: 57.142857142857146% 0;
  }
  
  .flags--fo {
    background-position: 57.142857142857146% 5.882352941176471%;
  }
  
  .flags--fr {
    background-position: 57.142857142857146% 11.764705882352942%;
  }
  
  .flags--ga {
    background-position: 57.142857142857146% 17.647058823529413%;
  }
  
  .flags--gb {
    background-position: 57.142857142857146% 23.529411764705884%;
  }
  
  .flags--gb-eng {
    background-position: 57.142857142857146% 29.41176470588235%;
  }
  
  .flags--gb-nir {
    background-position: 57.142857142857146% 35.294117647058826%;
  }
  
  .flags--gb-sct {
    background-position: 57.142857142857146% 41.1764705882353%;
  }
  
  .flags--gb-wls {
    background-position: 57.142857142857146% 47.05882352941177%;
  }
  
  .flags--gd {
    background-position: 57.142857142857146% 52.94117647058823%;
  }
  
  .flags--ge {
    background-position: 0 58.8235294117647%;
  }
  
  .flags--gf {
    background-position: 7.142857142857143% 58.8235294117647%;
  }
  
  .flags--gg {
    background-position: 14.285714285714286% 58.8235294117647%;
  }
  
  .flags--gh {
    background-position: 21.428571428571427% 58.8235294117647%;
  }
  
  .flags--gi {
    background-position: 28.571428571428573% 58.8235294117647%;
  }
  
  .flags--gl {
    background-position: 35.714285714285715% 58.8235294117647%;
  }
  
  .flags--gm {
    background-position: 42.857142857142854% 58.8235294117647%;
  }
  
  .flags--gn {
    background-position: 50% 58.8235294117647%;
  }
  
  .flags--gp {
    background-position: 57.142857142857146% 58.8235294117647%;
  }
  
  .flags--gq {
    background-position: 0 64.70588235294117%;
  }
  
  .flags--gr {
    background-position: 7.142857142857143% 64.70588235294117%;
  }
  
  .flags--gs {
    background-position: 14.285714285714286% 64.70588235294117%;
  }
  
  .flags--gt {
    background-position: 21.428571428571427% 64.70588235294117%;
  }
  
  .flags--gu {
    background-position: 28.571428571428573% 64.70588235294117%;
  }
  
  .flags--gw {
    background-position: 35.714285714285715% 64.70588235294117%;
  }
  
  .flags--gy {
    background-position: 42.857142857142854% 64.70588235294117%;
  }
  
  .flags--hk {
    background-position: 50% 64.70588235294117%;
  }
  
  .flags--hm {
    background-position: 57.142857142857146% 64.70588235294117%;
  }
  
  .flags--hn {
    background-position: 64.28571428571429% 0;
  }
  
  .flags--hr {
    background-position: 64.28571428571429% 5.882352941176471%;
  }
  
  .flags--ht {
    background-position: 64.28571428571429% 11.764705882352942%;
  }
  
  .flags--hu {
    background-position: 64.28571428571429% 17.647058823529413%;
  }
  
  .flags--ic {
    background-position: 64.28571428571429% 23.529411764705884%;
  }
  
  .flags--id {
    background-position: 64.28571428571429% 29.41176470588235%;
  }
  
  .flags--ie {
    background-position: 64.28571428571429% 35.294117647058826%;
  }
  
  .flags--il {
    background-position: 64.28571428571429% 41.1764705882353%;
  }
  
  .flags--im {
    background-position: 64.28571428571429% 47.05882352941177%;
  }
  
  .flags--in {
    background-position: 64.28571428571429% 52.94117647058823%;
  }
  
  .flags--io {
    background-position: 64.28571428571429% 58.8235294117647%;
  }
  
  .flags--iq {
    background-position: 64.28571428571429% 64.70588235294117%;
  }
  
  .flags--ir {
    background-position: 0 70.58823529411765%;
  }
  
  .flags--is {
    background-position: 7.142857142857143% 70.58823529411765%;
  }
  
  .flags--it {
    background-position: 14.285714285714286% 70.58823529411765%;
  }
  
  .flags--je {
    background-position: 21.428571428571427% 70.58823529411765%;
  }
  
  .flags--jm {
    background-position: 28.571428571428573% 70.58823529411765%;
  }
  
  .flags--jo {
    background-position: 35.714285714285715% 70.58823529411765%;
  }
  
  .flags--jp {
    background-position: 42.857142857142854% 70.58823529411765%;
  }
  
  .flags--ke {
    background-position: 50% 70.58823529411765%;
  }
  
  .flags--kg {
    background-position: 57.142857142857146% 70.58823529411765%;
  }
  
  .flags--kh {
    background-position: 64.28571428571429% 70.58823529411765%;
  }
  
  .flags--ki {
    background-position: 71.42857142857143% 0;
  }
  
  .flags--km {
    background-position: 71.42857142857143% 5.882352941176471%;
  }
  
  .flags--kn {
    background-position: 71.42857142857143% 11.764705882352942%;
  }
  
  .flags--kp {
    background-position: 71.42857142857143% 17.647058823529413%;
  }
  
  .flags--kr {
    background-position: 71.42857142857143% 23.529411764705884%;
  }
  
  .flags--kw {
    background-position: 71.42857142857143% 29.41176470588235%;
  }
  
  .flags--ky {
    background-position: 71.42857142857143% 35.294117647058826%;
  }
  
  .flags--kz {
    background-position: 71.42857142857143% 41.1764705882353%;
  }
  
  .flags--la {
    background-position: 71.42857142857143% 47.05882352941177%;
  }
  
  .flags--lb {
    background-position: 71.42857142857143% 52.94117647058823%;
  }
  
  .flags--lc {
    background-position: 71.42857142857143% 58.8235294117647%;
  }
  
  .flags--li {
    background-position: 71.42857142857143% 64.70588235294117%;
  }
  
  .flags--lk {
    background-position: 71.42857142857143% 70.58823529411765%;
  }
  
  .flags--lr {
    background-position: 0 76.47058823529412%;
  }
  
  .flags--ls {
    background-position: 7.142857142857143% 76.47058823529412%;
  }
  
  .flags--lt {
    background-position: 14.285714285714286% 76.47058823529412%;
  }
  
  .flags--lu {
    background-position: 21.428571428571427% 76.47058823529412%;
  }
  
  .flags--lv {
    background-position: 28.571428571428573% 76.47058823529412%;
  }
  
  .flags--ly {
    background-position: 35.714285714285715% 76.47058823529412%;
  }
  
  .flags--ma {
    background-position: 42.857142857142854% 76.47058823529412%;
  }
  
  .flags--mc {
    background-position: 50% 76.47058823529412%;
  }
  
  .flags--md {
    background-position: 57.142857142857146% 76.47058823529412%;
  }
  
  .flags--me {
    background-position: 64.28571428571429% 76.47058823529412%;
  }
  
  .flags--mf {
    background-position: 71.42857142857143% 76.47058823529412%;
  }
  
  .flags--mg {
    background-position: 78.57142857142857% 0;
  }
  
  .flags--mh {
    background-position: 78.57142857142857% 5.882352941176471%;
  }
  
  .flags--mk {
    background-position: 78.57142857142857% 11.764705882352942%;
  }
  
  .flags--ml {
    background-position: 78.57142857142857% 17.647058823529413%;
  }
  
  .flags--mm {
    background-position: 78.57142857142857% 23.529411764705884%;
  }
  
  .flags--mn {
    background-position: 78.57142857142857% 29.41176470588235%;
  }
  
  .flags--mo {
    background-position: 78.57142857142857% 35.294117647058826%;
  }
  
  .flags--mp {
    background-position: 78.57142857142857% 41.1764705882353%;
  }
  
  .flags--mq {
    background-position: 78.57142857142857% 47.05882352941177%;
  }
  
  .flags--mr {
    background-position: 78.57142857142857% 52.94117647058823%;
  }
  
  .flags--ms {
    background-position: 78.57142857142857% 58.8235294117647%;
  }
  
  .flags--mt {
    background-position: 78.57142857142857% 64.70588235294117%;
  }
  
  .flags--mu {
    background-position: 78.57142857142857% 70.58823529411765%;
  }
  
  .flags--mv {
    background-position: 78.57142857142857% 76.47058823529412%;
  }
  
  .flags--mw {
    background-position: 0 82.3529411764706%;
  }
  
  .flags--mx {
    background-position: 7.142857142857143% 82.3529411764706%;
  }
  
  .flags--my {
    background-position: 14.285714285714286% 82.3529411764706%;
  }
  
  .flags--mz {
    background-position: 21.428571428571427% 82.3529411764706%;
  }
  
  .flags--na {
    background-position: 28.571428571428573% 82.3529411764706%;
  }
  
  .flags--nc {
    background-position: 35.714285714285715% 82.3529411764706%;
  }
  
  .flags--ne {
    background-position: 42.857142857142854% 82.3529411764706%;
  }
  
  .flags--nf {
    background-position: 50% 82.3529411764706%;
  }
  
  .flags--ng {
    background-position: 57.142857142857146% 82.3529411764706%;
  }
  
  .flags--ni {
    background-position: 64.28571428571429% 82.3529411764706%;
  }
  
  .flags--nl {
    background-position: 71.42857142857143% 82.3529411764706%;
  }
  
  .flags--no {
    background-position: 78.57142857142857% 82.3529411764706%;
  }
  
  .flags--np {
    background-position: 0 88.23529411764706%;
  }
  
  .flags--nr {
    background-position: 7.142857142857143% 88.23529411764706%;
  }
  
  .flags--nu {
    background-position: 14.285714285714286% 88.23529411764706%;
  }
  
  .flags--nz {
    background-position: 21.428571428571427% 88.23529411764706%;
  }
  
  .flags--om {
    background-position: 28.571428571428573% 88.23529411764706%;
  }
  
  .flags--pa {
    background-position: 35.714285714285715% 88.23529411764706%;
  }
  
  .flags--pe {
    background-position: 42.857142857142854% 88.23529411764706%;
  }
  
  .flags--pf {
    background-position: 50% 88.23529411764706%;
  }
  
  .flags--pg {
    background-position: 57.142857142857146% 88.23529411764706%;
  }
  
  .flags--ph {
    background-position: 64.28571428571429% 88.23529411764706%;
  }
  
  .flags--pk {
    background-position: 71.42857142857143% 88.23529411764706%;
  }
  
  .flags--pl {
    background-position: 78.57142857142857% 88.23529411764706%;
  }
  
  .flags--pm {
    background-position: 85.71428571428571% 0;
  }
  
  .flags--pn {
    background-position: 85.71428571428571% 5.882352941176471%;
  }
  
  .flags--pr {
    background-position: 85.71428571428571% 11.764705882352942%;
  }
  
  .flags--ps {
    background-position: 85.71428571428571% 17.647058823529413%;
  }
  
  .flags--pt {
    background-position: 85.71428571428571% 23.529411764705884%;
  }
  
  .flags--pw {
    background-position: 85.71428571428571% 29.41176470588235%;
  }
  
  .flags--py {
    background-position: 85.71428571428571% 35.294117647058826%;
  }
  
  .flags--qa {
    background-position: 85.71428571428571% 41.1764705882353%;
  }
  
  .flags--re {
    background-position: 85.71428571428571% 47.05882352941177%;
  }
  
  .flags--ro {
    background-position: 85.71428571428571% 52.94117647058823%;
  }
  
  .flags--rs {
    background-position: 85.71428571428571% 58.8235294117647%;
  }
  
  .flags--ru {
    background-position: 85.71428571428571% 64.70588235294117%;
  }
  
  .flags--rw {
    background-position: 85.71428571428571% 70.58823529411765%;
  }
  
  .flags--sa {
    background-position: 85.71428571428571% 76.47058823529412%;
  }
  
  .flags--sb {
    background-position: 85.71428571428571% 82.3529411764706%;
  }
  
  .flags--sc {
    background-position: 85.71428571428571% 88.23529411764706%;
  }
  
  .flags--sd {
    background-position: 0 94.11764705882354%;
  }
  
  .flags--se {
    background-position: 7.142857142857143% 94.11764705882354%;
  }
  
  .flags--sg {
    background-position: 14.285714285714286% 94.11764705882354%;
  }
  
  .flags--sh {
    background-position: 21.428571428571427% 94.11764705882354%;
  }
  
  .flags--si {
    background-position: 28.571428571428573% 94.11764705882354%;
  }
  
  .flags--sj {
    background-position: 35.714285714285715% 94.11764705882354%;
  }
  
  .flags--sk {
    background-position: 42.857142857142854% 94.11764705882354%;
  }
  
  .flags--sl {
    background-position: 50% 94.11764705882354%;
  }
  
  .flags--sm {
    background-position: 57.142857142857146% 94.11764705882354%;
  }
  
  .flags--sn {
    background-position: 64.28571428571429% 94.11764705882354%;
  }
  
  .flags--so {
    background-position: 71.42857142857143% 94.11764705882354%;
  }
  
  .flags--sr {
    background-position: 78.57142857142857% 94.11764705882354%;
  }
  
  .flags--ss {
    background-position: 85.71428571428571% 94.11764705882354%;
  }
  
  .flags--st {
    background-position: 92.85714285714286% 0;
  }
  
  .flags--sv {
    background-position: 92.85714285714286% 5.882352941176471%;
  }
  
  .flags--sx {
    background-position: 92.85714285714286% 11.764705882352942%;
  }
  
  .flags--sy {
    background-position: 92.85714285714286% 17.647058823529413%;
  }
  
  .flags--sz {
    background-position: 92.85714285714286% 23.529411764705884%;
  }
  
  .flags--ta {
    background-position: 92.85714285714286% 29.41176470588235%;
  }
  
  .flags--tc {
    background-position: 92.85714285714286% 35.294117647058826%;
  }
  
  .flags--td {
    background-position: 92.85714285714286% 41.1764705882353%;
  }
  
  .flags--tf {
    background-position: 92.85714285714286% 47.05882352941177%;
  }
  
  .flags--tg {
    background-position: 92.85714285714286% 52.94117647058823%;
  }
  
  .flags--th {
    background-position: 92.85714285714286% 58.8235294117647%;
  }
  
  .flags--tj {
    background-position: 92.85714285714286% 64.70588235294117%;
  }
  
  .flags--tk {
    background-position: 92.85714285714286% 70.58823529411765%;
  }
  
  .flags--tl {
    background-position: 92.85714285714286% 76.47058823529412%;
  }
  
  .flags--tm {
    background-position: 92.85714285714286% 82.3529411764706%;
  }
  
  .flags--tn {
    background-position: 92.85714285714286% 88.23529411764706%;
  }
  
  .flags--to {
    background-position: 92.85714285714286% 94.11764705882354%;
  }
  
  .flags--tr {
    background-position: 0 100%;
  }
  
  .flags--tt {
    background-position: 7.142857142857143% 100%;
  }
  
  .flags--tv {
    background-position: 14.285714285714286% 100%;
  }
  
  .flags--tw {
    background-position: 21.428571428571427% 100%;
  }
  
  .flags--tz {
    background-position: 28.571428571428573% 100%;
  }
  
  .flags--ua {
    background-position: 35.714285714285715% 100%;
  }
  
  .flags--ug {
    background-position: 42.857142857142854% 100%;
  }
  
  .flags--um {
    background-position: 50% 100%;
  }
  
  .flags--un {
    background-position: 57.142857142857146% 100%;
  }
  
  .flags--us {
    background-position: 64.28571428571429% 100%;
  }
  
  .flags--uy {
    background-position: 71.42857142857143% 100%;
  }
  
  .flags--uz {
    background-position: 78.57142857142857% 100%;
  }
  
  .flags--va {
    background-position: 85.71428571428571% 100%;
  }
  
  .flags--vc {
    background-position: 92.85714285714286% 100%;
  }
  
  .flags--ve {
    background-position: 100% 0;
  }
  
  .flags--vg {
    background-position: 100% 5.882352941176471%;
  }
  
  .flags--vi {
    background-position: 100% 11.764705882352942%;
  }
  
  .flags--vn {
    background-position: 100% 17.647058823529413%;
  }
  
  .flags--vu {
    background-position: 100% 23.529411764705884%;
  }
  
  .flags--wf {
    background-position: 100% 29.41176470588235%;
  }
  
  .flags--ws {
    background-position: 100% 35.294117647058826%;
  }
  
  .flags--xk {
    background-position: 100% 41.1764705882353%;
  }
  
  .flags--xx {
    background-position: 100% 47.05882352941177%;
  }
  
  .flags--ye {
    background-position: 100% 52.94117647058823%;
  }
  
  .flags--yt {
    background-position: 100% 58.8235294117647%;
  }
  
  .flags--za {
    background-position: 100% 64.70588235294117%;
  }
  
  .flags--zm {
    background-position: 100% 70.58823529411765%;
  }
  
  .flags--zw {
    background-position: 100% 76.47058823529412%;
  }
`
