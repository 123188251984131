import { generateBasicActions } from 'utils/service'

import {
  CLEAR_NURSERY_ACADEMIC_YEARS_LIST,
  GET_NURSERY_ACADEMIC_YEARS_LIST,
  GET_NURSERY_ACADEMIC_YEARS_LIST_FAILED,
  GET_NURSERY_ACADEMIC_YEARS_LIST_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ nurseryAcademicYearsApiClient }) => (
  generateBasicActions.list({
    constants: {
      failed: GET_NURSERY_ACADEMIC_YEARS_LIST_FAILED,
      init: GET_NURSERY_ACADEMIC_YEARS_LIST,
      success: GET_NURSERY_ACADEMIC_YEARS_LIST_SUCCESS,
    },
    options,
    service: nurseryAcademicYearsApiClient,
    serviceMethod: 'list',
  })
)

export const clearList = () => ({
  type: CLEAR_NURSERY_ACADEMIC_YEARS_LIST,
})
