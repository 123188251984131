import React, { useEffect } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { ChildNote } from 'services/childNotes/models'

import { withChildNotesService, withChildNotesServiceProps } from 'services/childNotes'
import { withAppService, withAppServiceProps } from 'services/app'
import { withRouter, withRouterProps } from 'services/router'

import ChildNotesListView from './ChildNotesListView'

interface ChildNotesListContainerProps {
  isFetching: boolean
  notes: ChildNote[]
  params: {
    childId: string
  }
}

type ChildNotesListContainerFullProps = ChildNotesListContainerProps
  & withChildNotesServiceProps
  & withAppServiceProps
  & withRouterProps

const ChildNotesListContainer: React.FC<ChildNotesListContainerFullProps> = ({
  childNotesActions,
  isFetching,
  notes,
  params,
}) => {
  const { childId } = params

  useEffect(() => {
    childNotesActions.list({
      params: [childId],
    })
  }, [])

  return (
    <ChildNotesListView
      childId={+childId}
      isFetching={isFetching}
      notes={notes}
    />
  )
}

const mapState = (state, { appSelectors, childNotesListState, childNotesSelectors }) => ({
  isFetching: appSelectors.getIsFetching(childNotesListState),
  notes: childNotesSelectors.getChildNotesList(state),
})

const enhance = compose(
  withAppService,
  withChildNotesService,
  withRouter,
  connect(mapState),
)

export default enhance(ChildNotesListContainer)
