import _ from 'lodash'

import React from 'react'

import { FieldError, Radio, Space } from 'components'

import { StyledButtonStyleWrapper, StyledContainer, StyledItemContainer, StyledItems } from './FormRadioGroupStyled'

const FormRadioGroup = ({
  buttonStyle,
  disabled,
  fullWidth,
  horizontal,
  input: {
    onChange,
    ...restInput
  },
  meta: { error, touched },
  onItemClick,
  options,
  renderItem,
  rightComponent,
}) => {
  const renderRadio = (option) => (
    <Radio
      {...restInput}
      checked={option.value === restInput.value}
      disabled={disabled}
      label={option.label}
      rightComponent={rightComponent}
      tooltip={option.tooltip}
      tooltipPlacement={option.tooltipPlacement}
      value={option.value}
      onChange={(e) => {
        onChange(e)
        onItemClick?.(e)
      }}
    />
  )

  const renderSingleItem = (option) => {
    if (renderItem) {
      const radio = (
        <Radio
          {...restInput}
          checked={option.value === restInput.value}
          disabled={disabled}
          tooltip={option.tooltip}
          tooltipPlacement={option.tooltipPlacement}
          value={option.value}
          onChange={(e) => {
            onChange(e)
            onItemClick?.(e)
          }}
        />
      )

      return (
        <div onClick={() => {
          onChange(option.value)
          onItemClick?.(option.value)
        }}
        >
          {renderItem(radio, option)}
        </div>
      )
    }

    return renderRadio(option)
  }

  const renderOptions = (option, index) => (
    <StyledItemContainer
      buttonStyle={buttonStyle}
      fullWidth={fullWidth || !option.tooltip}
      horizontal={horizontal}
      isRenderItem={renderItem}
      key={option.value}
      lastChild={index + 1 === options.length}
    >
      {buttonStyle
        ? (
          <StyledButtonStyleWrapper
            $checked={option.value === restInput.value}
            $disabled={disabled}
            onClick={disabled ? undefined : () => {
              onChange(option.value)
              onItemClick?.(option.value)
            }}
          >
            {renderSingleItem(option)}
          </StyledButtonStyleWrapper>
        )
        : renderSingleItem(option)}
    </StyledItemContainer>
  )

  return (
    <StyledContainer>
      <StyledItems horizontal={horizontal}>
        {_.map(options, renderOptions)}
      </StyledItems>
      {error && touched && (
        <React.Fragment>
          <Space space="5px" />
          <FieldError error={error} />
        </React.Fragment>
      )}
    </StyledContainer>
  )
}

export default FormRadioGroup
