import React from 'react'

import { EditChildNoteFormValues } from 'services/childNotes/models'

import { Page, Section, Spinner } from 'components'

import i18n from 'translations'

import EditChildNoteForm from './components/EditChildNoteForm'

interface EditChildNoteViewProps {
  childId: number
  initialValues: EditChildNoteFormValues
  isEditContext: boolean
  isFetching: boolean
  isSubmitting: boolean
  onDeleteNote: () => void
  onSubmit: (values: EditChildNoteFormValues) => any
}

const EditChildNoteView: React.FC<EditChildNoteViewProps> = ({
  childId,
  initialValues,
  isEditContext,
  isFetching,
  isSubmitting,
  onDeleteNote,
  onSubmit,
}) => {
  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    return (
      <EditChildNoteForm
        childId={childId}
        initialValues={initialValues}
        isEditContext={isEditContext}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      />
    )
  }

  return (
    <Page.Section
      actions={isEditContext && (
        <Section.Actions
          options={[{
            onClick: onDeleteNote,
            type: 'remove',
          }]}
        />
      )}
      isLoading={isFetching}
      title={isEditContext
        ? i18n.t('module:Children:Child:Notes:Edit:titleEdit')
        : i18n.t('module:Children:Child:Notes:Edit:titleAdd')}
    >
      {renderContent()}
    </Page.Section>
  )
}

export default EditChildNoteView
