import moment from 'moment'

export const getCriteria = (filters = {}, childId) => {
  if (!filters) {
    return null
  }

  const { archived, child, date, invoiceItem } = filters
  const { after, before } = date || {}

  const criteria = []

  if (null !== invoiceItem && undefined !== invoiceItem) {
    criteria.push({
      comparator: 'exists',
      field: 'invoiceItem',
      value: invoiceItem,
    })
  }

  if (child || childId) {
    criteria.push({
      field: 'child',
      value: child || childId,
    })
  }

  if (null !== archived && undefined !== archived) {
    criteria.push({
      field: 'archived',
      value: +archived,
    })
  }

  if (after) {
    criteria.push({
      comparator: 'after',
      field: 'takenDate',
      value: moment(after).format('YYYY-MM-DD'),
    })
  }

  if (before) {
    criteria.push({
      comparator: 'before',
      field: 'takenDate',
      value: moment(before).format('YYYY-MM-DD'),
    })
  }

  return criteria
}
