import React from 'react'
import { connect } from 'react-redux'

import actions from './actions'
import * as selectors from './selectors'
import * as constants from './constants'

const withFundingTypesService = (WrappedComponent) => {
  const mapState = ({ fundingTypes }) => ({ fundingTypes })

  const mapDispatch = {
    clear: actions.clearFundingTypes,
    list: () => ({ fundingTypesApiClient }) => actions.listFundingTypes(fundingTypesApiClient)(),
  }

  const Component = (props) => {
    const { clear, fundingTypes, list, ...other } = props

    const fundingTypesState = fundingTypes
    const fundingTypesActions = { clear, list }
    const fundingTypesSelectors = selectors
    const fundingTypesConstants = constants

    return (
      <WrappedComponent
        fundingTypesActions={fundingTypesActions}
        fundingTypesConstants={fundingTypesConstants}
        fundingTypesSelectors={fundingTypesSelectors}
        fundingTypesState={fundingTypesState}
        {...other}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withFundingTypesService
