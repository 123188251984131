import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { ENQUIRY_STATUS_CODES } from 'services/legacy/enquiries/constants'
import { STATUS_TYPES } from 'services/legacy/enquiries/settings/constants'
import { TYPE_TO_ROUTE_URL_MAPPINGS } from 'module/Management/EnquiriesSettings/Common/constants'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withEnquiriesService, withEnquiryStatusTypesService } from 'services/legacy/enquiries'
import { withRouter } from 'services/router'

import StatusChangeCommentModalView from './StatusChangeCommentModalView'

class StatusChangeCommentModalContainer extends Component {
  constructor(props) {
    super(props)

    const { status } = props

    this.state = {
      isLostEnquiryStatus: status?.code === ENQUIRY_STATUS_CODES.LOST_ENQUIRY,
    }
  }

  componentDidMount() {
    const {
      enquiryStatusTypesActions,
      enquiryStatusTypesSelectors,
    } = this.props
    const { isLostEnquiryStatus } = this.state

    const criteria = enquiryStatusTypesSelectors.getCriteria({
      code: isLostEnquiryStatus
        ? ENQUIRY_STATUS_CODES.LOST_ENQUIRY
        : ENQUIRY_STATUS_CODES.NO_SPACE_AVAILABLE,
    })

    enquiryStatusTypesActions.list({
      params: {
        criteria,
      },
    })
  }

  componentWillUnmount() {
    const { enquiryStatusTypesActions } = this.props

    enquiryStatusTypesActions.clear()
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleMarkAsEnrolledClickSuccess = () => {
    const { onStatusUpdatedSuccess } = this.props

    if (onStatusUpdatedSuccess) {
      onStatusUpdatedSuccess()
    }

    this.handleCloseClick()
  }

  getBody = (formValues) => {
    const { lostStatusType, noSpaceStatusType } = this.props
    const { isLostEnquiryStatus } = this.state

    if (isLostEnquiryStatus) {
      return {
        lostReason: { id: formValues?.lostReason?.value },
        status: { id: lostStatusType?.id },
        statusNote: formValues?.statusNote,
      }
    }

    return {
      status: { id: noSpaceStatusType?.id },
      statusNote: formValues?.statusNote,
    }
  }

  handleSubmit = (formValues) => {
    const { enquiriesActions, enquiry } = this.props
    const { id } = enquiry

    enquiriesActions.update({
      body: this.getBody(formValues),
      onSuccess: this.handleMarkAsEnrolledClickSuccess,
      params: [id, {}],
    })
  }

  handleCancelClick = () => {
    const { onCancelClick } = this.props

    if (onCancelClick) {
      onCancelClick()
    }

    this.handleCloseClick()
  }

  handleNurserySettingsClick = () => {
    const { navigate } = this.props

    this.handleCloseClick()

    navigate(generateRoute('MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_COMMON.INDEX', {
      type: TYPE_TO_ROUTE_URL_MAPPINGS[STATUS_TYPES.LOST_REASON],
    }))
  }

  render() {
    const { errorMessages, isSubmitting } = this.props
    const { isLostEnquiryStatus } = this.state

    return (
      <StatusChangeCommentModalView
        errorMessages={errorMessages}
        isLostEnquiryStatus={isLostEnquiryStatus}
        isSubmitting={isSubmitting}
        onCancelClick={this.handleCancelClick}
        onNurserySettingsClick={this.handleNurserySettingsClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  enquiriesSelectors,
  enquiriesSingleState,
  enquiryStatusTypesSelectors,
}) => ({
  enquiry: enquiriesSelectors.getEnquiriesSingleDataSelector(state),
  errorMessages: appSelectors.getErrorMessages(enquiriesSingleState),
  isSubmitting: appSelectors.getIsSubmitting(enquiriesSingleState),
  lostStatusType: enquiryStatusTypesSelectors.getLostEnquiryStatusTypeFromList(state),
  noSpaceStatusType: enquiryStatusTypesSelectors.getNoSpaceEnquiryStatusTypeFromList(state),
})

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withEnquiriesService,
  withEnquiryStatusTypesService,
  connect(mapState),
)

export default enhance(StatusChangeCommentModalContainer)
