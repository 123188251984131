import { generateBasicActions } from 'utils/service'

import {
  CLEAR_FINANCE_REPORTS_CHILDREN_REVENUE_PER_AGE,
  FINANCE_REPORTS_CHILDREN_REVENUE_PER_AGE,
  FINANCE_REPORTS_CHILDREN_REVENUE_PER_AGE_FAILED,
  FINANCE_REPORTS_CHILDREN_REVENUE_PER_AGE_SUCCESS,
} from './constants'

export const getChildren = (options = {}) => ({ financeRevenuePerAgeReportApiClient }) => generateBasicActions.list({
  constants: {
    failed: FINANCE_REPORTS_CHILDREN_REVENUE_PER_AGE_FAILED,
    init: FINANCE_REPORTS_CHILDREN_REVENUE_PER_AGE,
    success: FINANCE_REPORTS_CHILDREN_REVENUE_PER_AGE_SUCCESS,
  },
  options,
  service: financeRevenuePerAgeReportApiClient,
  serviceMethod: 'getChildren',
})

export const clearChildren = () => ({
  type: CLEAR_FINANCE_REPORTS_CHILDREN_REVENUE_PER_AGE,
})
