import { generateBasicActions } from 'utils/service'

import {
  CLEAR_SINGLE_DEVICE_TOKENS,
  CREATE_DEVICE_TOKENS,
  CREATE_DEVICE_TOKENS_FAILED,
  CREATE_DEVICE_TOKENS_SUCCESS,
  GET_DEVICE_TOKEN,
  GET_DEVICE_TOKEN_FAILED,
  GET_DEVICE_TOKEN_SUCCESS,
  INSTALL_DEVICE_TOKEN,
  INSTALL_DEVICE_TOKEN_FAILED,
  INSTALL_DEVICE_TOKEN_SUCCESS,
  REMOVE_DEVICE_TOKENS,
  REMOVE_DEVICE_TOKENS_FAILED,
  REMOVE_DEVICE_TOKENS_SUCCESS,
  UPDATE_DEVICE_TOKENS,
  UPDATE_DEVICE_TOKENS_FAILED,
  UPDATE_DEVICE_TOKENS_SUCCESS,
} from './constants'

export const getDeviceToken = (options = {}) => ({ deviceTokensApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: GET_DEVICE_TOKEN_FAILED,
      init: GET_DEVICE_TOKEN,
      success: GET_DEVICE_TOKEN_SUCCESS,
    },
    options,
    service: deviceTokensApiClient,
    serviceMethod: 'get',
  })
)

export const createDeviceTokens = (options = {}) => ({ deviceTokensApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: CREATE_DEVICE_TOKENS_FAILED,
      init: CREATE_DEVICE_TOKENS,
      success: CREATE_DEVICE_TOKENS_SUCCESS,
    },
    options,
    service: deviceTokensApiClient,
    serviceMethod: 'create',
  })
)

export const installDeviceToken = (options = {}) => ({ deviceTokensApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: INSTALL_DEVICE_TOKEN_FAILED,
      init: INSTALL_DEVICE_TOKEN,
      success: INSTALL_DEVICE_TOKEN_SUCCESS,
    },
    options,
    service: deviceTokensApiClient,
    serviceMethod: 'install',
  })
)

export const updateDeviceToken = (options = {}) => ({ deviceTokensApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_DEVICE_TOKENS_FAILED,
      init: UPDATE_DEVICE_TOKENS,
      success: UPDATE_DEVICE_TOKENS_SUCCESS,
    },
    options,
    service: deviceTokensApiClient,
    serviceMethod: 'update',
  })
)

export const removeDeviceToken = (options = {}) => ({ deviceTokensApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: REMOVE_DEVICE_TOKENS_FAILED,
      init: REMOVE_DEVICE_TOKENS,
      success: REMOVE_DEVICE_TOKENS_SUCCESS,
    },
    options,
    service: deviceTokensApiClient,
    serviceMethod: 'remove',
  })
)

export const clearSingle = () => ({
  type: CLEAR_SINGLE_DEVICE_TOKENS,
})
