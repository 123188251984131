import _ from 'lodash'
import { createSelector } from 'reselect'

export const getFundingTypesSelectors = (state) => state.fundingTypes

export const getFundingTypesDataSelectors = createSelector(
  [getFundingTypesSelectors],
  (state) => state.data,
)

export const getFundingTypesOptionsSelectors = createSelector(
  [getFundingTypesDataSelectors],
  (fundingTypes) => {
    if (!fundingTypes || !fundingTypes.length) {
      return null
    }

    return _.map(fundingTypes, ({ code, id, name }) => ({
      code,
      label: name,
      value: id,
    }))
  },
)
