import _ from 'lodash'

import {
  ALLERGY_OPTIONS,
  APPLY_PERMISSIONS,
  CHILD_CHANGELOG_SECTIONS,
  DIETARY_REQUIREMENTS_OPTIONS,
  GENDER_DROPDOWN,
  TRAVEL_PERMISSIONS,
} from 'services/legacy/child/constants'

import i18n from 'translations'

const CHILD_FIELDS = {
  [CHILD_CHANGELOG_SECTIONS.GENERAL]: {
    birthDate: {
      trans: i18n.t('module:Children:Child:Add:GeneralInformation:Form:DateOfBirth:label'),
    },
    codeReference: {
      trans: i18n.t('module:Children:Child:Add:GeneralInformation:Form:ReferenceCode:label'),
    },
    displayName: {
      trans: i18n.t('module:Children:Child:Add:GeneralInformation:Form:DisplayName:label'),
    },
    ethnicity: {
      format: (value) => value?.name,
      trans: i18n.t('module:Children:Child:Add:GeneralInformation:Form:Ethnicity:label'),
    },
    firstName: {
      trans: i18n.t('module:Children:Child:Add:GeneralInformation:Form:FirstName:label'),
    },
    gender: {
      format: (gender) => _.get(_.find(GENDER_DROPDOWN, ({ value }) => value === gender), 'label'),
      trans: i18n.t('module:Children:Child:Add:GeneralInformation:Form:Gender:label'),
    },
    'information.address': {
      trans: i18n.t('module:Children:Child:Add:GeneralInformation:Form:Address:label'),
    },
    'information.postcode': {
      trans: i18n.t('module:Children:Child:Add:GeneralInformation:Form:Postcode:label'),
    },
    middleNames: {
      trans: i18n.t('module:Children:Child:Add:GeneralInformation:Form:MiddleName:label'),
    },
    photo: {
      formatGroup: (values) => {
        const { new: newPhoto, old } = values

        if (newPhoto && !old) {
          return [i18n.t('module:Children:Child:About:ProfileHistory:photoUploaded'), false]
        }

        if (!newPhoto && old) {
          return [i18n.t('module:Children:Child:About:ProfileHistory:photoRemoved'), true]
        }

        return [i18n.t('module:Children:Child:About:ProfileHistory:photoUpdated'), false]
      },
      trans: i18n.t('module:Children:Child:Add:GeneralInformation:Form:Photo:label'),
    },
    surname: {
      trans: i18n.t('module:Children:Child:Add:GeneralInformation:Form:Surname:label'),
    },
  },
  [CHILD_CHANGELOG_SECTIONS.FAMILY]: {
    'profile.familyMaritalStatus': {
      format: (value, { maritalStatuses }) => maritalStatuses[value],
      trans: i18n.t('module:Children:Child:Add:Family:Form:FamilyMaritalStatus:label'),
    },
    'profile.familyPosition': {
      trans: i18n.t('module:Children:Child:Add:Family:Form:FamilyPosition:label'),
    },
    'profile.familySpokenLanguage': {
      format: (value) => value?.replaceAll(',', ', '),
      trans: i18n.t('module:Children:Child:Add:Family:Form:FamilyLanguage:label'),
    },
    'profile.parentsAreServingArmedForcesPersonnel': {
      format: (value) => (value ? i18n.t('global:Yes') : i18n.t('global:No')),
      trans: i18n.t('module:Children:Child:Add:Family:Form:ParentArmedForcesPersonnel:label'),
    },
    'profile.siblings': {
      trans: i18n.t('module:Children:Child:Add:Family:Form:Siblings:label'),
    },
  },
  [CHILD_CHANGELOG_SECTIONS.ENROLMENT]: {
    keyWorkers: {
      format: (keyWorkers) => _.map(keyWorkers, ({ username }) => username).join(', '),
      trans: i18n.t('module:Children:Child:Add:EnrolmentDetails:Subsections:Attendance:Form:User:label'),
    },
    leavingDate: {
      trans: i18n.t('module:Children:Child:Add:EnrolmentDetails:Subsections:Attendance:Form:LeavingDate:label'),
    },
    nurseryClass: {
      format: (value) => value?.name,
      trans: i18n.t('module:Children:Child:Add:EnrolmentDetails:Subsections:Attendance:Form:Room:label'),
    },
    'profile.doesReceiveEYPPFunding': {
      format: (value) => (value ? i18n.t('global:Yes') : i18n.t('global:No')),
      trans: i18n.t('module:Children:Child:Add:EnrolmentDetails:Subsections:Funding:Form:EYPPFunding:label'),
    },
    'profile.doesReceiveTwoYearFunding': {
      format: (value) => (value ? i18n.t('global:Yes') : i18n.t('global:No')),
      trans: i18n.t('module:Children:Child:Add:EnrolmentDetails:Subsections:Funding:Form:TwoYearFunding:label'),
    },
    registeredAt: {
      trans: i18n.t('module:Children:Child:Add:EnrolmentDetails:Subsections:Attendance:Form:RegistrationDate:label'),
    },
    startDate: {
      trans: i18n.t('module:Children:Child:Add:EnrolmentDetails:Subsections:Attendance:Form:StartDate:label'),
    },
  },
  [CHILD_CHANGELOG_SECTIONS.PERMISSIONS]: {
    'information.canBePhotographed': {
      format: (value) => (value ? i18n.t('global:Yes') : i18n.t('global:No')),
      trans: i18n.t('module:Children:Child:Add:Permissions:Subsections:Media:Form:CanBePhotographed:label'),
    },
    'information.canBeTaggedInGroupPhotos': {
      format: (value) => (value ? i18n.t('global:Yes') : i18n.t('global:No')),
      trans: i18n.t('module:Children:Child:Add:Permissions:Subsections:Media:Form:CanBeTaggedInGroupPhotos:label'),
    },
    'information.otherMarketingMaterialPhotoSharingPermitted': {
      format: (value) => (value ? i18n.t('global:Yes') : i18n.t('global:No')),
      trans: (
        i18n.t('module:Children:Child:Add:Permissions:Subsections:Media:Form:OtherMarketingMaterialPhotoSharing:label')
      ),
    },
    'information.socialMediaPhotoSharingPermitted': {
      format: (value) => (value ? i18n.t('global:Yes') : i18n.t('global:No')),
      trans: i18n.t('module:Children:Child:Add:Permissions:Subsections:Media:Form:SocialMediaPhotoSharing:label'),
    },
    'information.travelPermissions.canGoToExternalOutgoings': {
      format: (value) => (value ? i18n.t('global:Yes') : i18n.t('global:No')),
      trans: i18n.t('module:Children:Child:Add:Permissions:Subsections:Play:Form:CanGoToExternalOutgoings:label'),
    },
    'information.travelPermissions.canTakeTheBus': {
      format: (value) => (value ? i18n.t('global:Yes') : i18n.t('global:No')),
      trans: i18n.t('module:Children:Child:Add:Permissions:Subsections:Safety:Form:CanTakeTheBus:label'),
    },
  },
  [CHILD_CHANGELOG_SECTIONS.HEALTH]: {
    'information.allergies': {
      format: (value) => (
        _.get(_.find(ALLERGY_OPTIONS, (option) => option.value === value), 'label')
      ),
      trans: i18n.t('module:Children:Child:Add:HealthAndDiet:Subsections:Health:Form:Allergies:label'),
    },
    'information.allergiesDetails': {
      trans: i18n.t('module:Children:Child:Add:HealthAndDiet:Subsections:Health:Form:AllergiesDetails:label'),
    },
    'information.applyPermissions.facePaint': {
      format: (value) => (value ? i18n.t('global:Yes') : i18n.t('global:No')),
      trans: i18n.t('module:Children:Child:Add:HealthAndDiet:Subsections:Health:Form:FacePaint:label'),
    },
    'information.applyPermissions.nappyCream': {
      format: (value) => (value ? i18n.t('global:Yes') : i18n.t('global:No')),
      trans: i18n.t('module:Children:Child:Add:HealthAndDiet:Subsections:Health:Form:NappyCream:label'),
    },
    'information.applyPermissions.patches': {
      format: (value) => (value ? i18n.t('global:Yes') : i18n.t('global:No')),
      trans: i18n.t('module:Children:Child:Add:HealthAndDiet:Subsections:Health:Form:Patches:label'),
    },
    'information.applyPermissions.sunCream': {
      format: (value) => (value ? i18n.t('global:Yes') : i18n.t('global:No')),
      trans: i18n.t('module:Children:Child:Add:HealthAndDiet:Subsections:Health:Form:SunCream:label'),
    },
    'information.canConsumeFoodWithNutTraces': {
      format: (value) => (value ? i18n.t('global:Yes') : i18n.t('global:No')),
      trans: (
        i18n.t('module:Children:Child:Add:HealthAndDiet:Subsections:Health:Form:CanConsumeFoodWithNutTraces:label')
      ),
    },
    'information.canReceiveMedicine': {
      format: (value) => (value ? i18n.t('global:Yes') : i18n.t('global:No')),
      trans: i18n.t('module:Children:Child:Add:HealthAndDiet:Subsections:Health:Form:CanReceiveMedicine:label'),
    },
    'information.dietaryReq': {
      format: (value) => _.get(
        _.find(DIETARY_REQUIREMENTS_OPTIONS, (option) => option.value === value),
        'label',
      ),
      trans: i18n.t('module:Children:Child:Add:HealthAndDiet:Subsections:Diet:Form:DietaryPreference:label'),
    },
    'information.dietaryReqDesc': {
      trans: i18n.t('module:Children:Child:Add:HealthAndDiet:Subsections:Diet:Form:AdditionalNotes:label'),
    },
    'information.isVegetarian': {
      trans: i18n.t('services:Child:dietary:Vegetarian'),
    },
    'profile.isFussyEater': {
      format: (value) => (value ? i18n.t('global:Yes') : i18n.t('global:No')),
      trans: i18n.t('module:Children:Child:Add:HealthAndDiet:Subsections:Diet:Form:FussyEater:label'),
    },
    'profile.medicalInformation': {
      trans: i18n.t('module:Children:Child:Add:HealthAndDiet:Subsections:Diet:Form:AdditionalNotes:label'),
    },
  },
  [CHILD_CHANGELOG_SECTIONS.DEVELOPMENT]: {
    'information.daytimeSleepingBehavior': {
      trans: i18n.t('module:Children:Child:Add:Development:Subsections:Routine:Form:DayTimeSleep:label'),
    },
    'information.drinkingMethod': {
      format: (value, { drinkingMethods }) => (drinkingMethods[value]),
      trans: i18n.t('module:Children:Child:Add:Development:Subsections:Skills:Form:DrinkingMethods:label'),
    },
    'information.toiletTrained': {
      format: (value, { toiletTrainingOptions }) => (toiletTrainingOptions[value]),
      trans: i18n.t('module:Children:Child:Add:Development:Subsections:Skills:Form:Toileting:label'),
    },
    'profile.attendsOtherSetting': {
      trans: i18n.t('module:Children:Child:Add:Development:Subsections:Routine:Form:AttendsOtherSettings:label'),
    },
    'profile.childBehavior': {
      trans: i18n.t('module:Children:Child:Add:Development:Subsections:Skills:Form:ChildBehavior:label'),
    },
    'profile.englishAsAdditionalLanguage': {
      trans: i18n.t('module:Children:Child:Add:Development:Subsections:Skills:Form:EnglishAdditionalLanguage:label'),
    },
    'profile.languageCapability': {
      trans: i18n.t('module:Children:Child:Add:Development:Subsections:Skills:Form:LanguageCapability:label'),
    },
    'profile.movementCapability': {
      trans: i18n.t('module:Children:Child:Add:Development:Subsections:Skills:Form:MovementCapability:label'),
    },
    'profile.routine': {
      trans: i18n.t('module:Children:Child:Add:Development:Subsections:Routine:Form:Routine:label'),
    },
    'profile.specialEducationalNeeds': {
      trans: i18n.t('module:Children:Child:Add:Development:Subsections:Skills:Form:SpecialEducation:label'),
    },
  },
  [CHILD_CHANGELOG_SECTIONS.LIKES]: {
    'profile.booksPreference': {
      trans: i18n.t('module:Children:Child:Add:LikesDislikes:Form:Books:label'),
    },
    'profile.foodsPreference': {
      trans: i18n.t('module:Children:Child:Add:LikesDislikes:Form:Food:label'),
    },
    'profile.otherPreference': {
      trans: i18n.t('module:Children:Child:Add:LikesDislikes:Form:Interests:label'),
    },
    'profile.toysPreference': {
      trans: i18n.t('module:Children:Child:Add:LikesDislikes:Form:Toys:label'),
    },
  },
}

const NEW_KEY = 'new'
const OLD_KEY = 'old'

const formatArrayTypeField = (subItem, itemKey, subItemKey, permission) => {
  const result = {}

  if (_.includes(subItem.new, permission)) {
    const fullKey = `${itemKey}.${subItemKey}.${permission}`

    if (!result[fullKey]) {
      result[fullKey] = {}
    }

    result[fullKey] = {
      ...result[fullKey],
      new: true,
    }
  }

  if (_.includes(subItem.old, permission)) {
    const fullKey = `${itemKey}.${subItemKey}.${permission}`

    if (!result[fullKey]) {
      result[fullKey] = {}
    }

    result[fullKey] = {
      ...result[fullKey],
      old: true,
    }
  }

  return result
}

const flatten = (data) => {
  let result = {}

  _.each(data, (item, itemKey) => {
    if (
      'object' === (typeof item)
      && !(item instanceof Date)
      && !Array.isArray(item)
      && !(item.new || item.old)
    ) {
      const temp = flatten(item)

      _.each(temp, (subItem, subItemKey) => {
        if ('information.applyPermissions' === `${itemKey}.${subItemKey}`) {
          _.each(APPLY_PERMISSIONS, (permission) => {
            result = {
              ...result,
              ...formatArrayTypeField(subItem, itemKey, subItemKey, permission),
            }
          })
        } else if ('information.travelPermissions' === `${itemKey}.${subItemKey}`) {
          _.each(TRAVEL_PERMISSIONS, (permission) => {
            result = {
              ...result,
              ...formatArrayTypeField(subItem, itemKey, subItemKey, permission),
            }
          })
        } else if (NEW_KEY === subItemKey || OLD_KEY === subItemKey) {
          if (!result[`${itemKey}`]) {
            result[`${itemKey}`] = {}
          }

          result[itemKey][subItemKey] = subItem
        } else {
          result[`${itemKey}.${subItemKey}`] = subItem
        }
      })
    } else {
      result[itemKey] = item
    }
  })

  return result
}

export const getFormattedFields = (data, props) => {
  const sections = []

  return [_.map(flatten(data), (itemValue, itemKey) => {
    let result = {}

    _.each(CHILD_FIELDS, (fields, sectionName) => {
      _.each(fields, (field, fieldKey) => {
        if (fieldKey === itemKey) {
          if (!_.includes(sections, sectionName)) {
            sections.push(sectionName)
          }

          if (field.formatGroup) {
            result = {
              label: field.trans,
              value: field.formatGroup(itemValue, props),
            }
          } else {
            result = {
              label: field.trans,
              newValue: field.format ? field.format(itemValue.new, props) : itemValue.new,
              oldValue: field.format ? field.format(itemValue.old, props) : itemValue.old,
            }
          }
        }
      })
    })

    return result
  }), sections]
}
