import React, { useEffect, useMemo, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'
import { NURSERY_DISCOUNTS_FILTER } from 'services/product/nurseryDiscountsV3/constants'

import { withAppService, withAppServiceProps } from 'services/app'
import { withNurseryDiscountsV3Service, withNurseryDiscountsV3ServiceProps } from 'services/product/nurseryDiscountsV3'

import { withPaginationUtils, withPaginationUtilsProps } from 'services/utils/pagination'
import { withRouteUtilsProps, withRouterUtils } from 'services/utils/router'
import { withSortUtilsProps, withSortingUtils } from 'services/utils/sorting'
import { withRouterProps } from 'services/router/constants'

import ManagementDiscountsView from './ManagementDiscountsV3View'
import { getTableData } from './ManagementDiscountsV3Helpers'

type ManagementDiscountsV3ContainerProps = withAppServiceProps
  & withNurseryDiscountsV3ServiceProps
  & withRouteUtilsProps
  & withSortUtilsProps
  & withPaginationUtilsProps
  & withRouterProps

const NURSERY_DISCOUNTS_GROUPS = {
  read: [
    'nurseryDiscountProduct.settings',
    'nurseryDiscountProduct.nursery',
    'nurseryDiscountProductSettings',
  ],
}

const mapState = (state: RootState, {
  appSelectors,
  nurseryDiscountsV3ListState,
  nurseryDiscountsV3Selectors,
  params,
}: ManagementDiscountsV3ContainerProps) => ({
  errorMessages: appSelectors.getErrorMessages(nurseryDiscountsV3ListState),
  isFetching: appSelectors.getIsFetching(nurseryDiscountsV3ListState),
  nurseryDiscounts: nurseryDiscountsV3Selectors.getNurseryDiscountsListSelector(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  totalResults: appSelectors.getTotalResults(nurseryDiscountsV3ListState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const ManagementDiscountsV3Container: React.FC<ManagementDiscountsV3ContainerProps & PropsFromRedux> = ({
  errorMessages,
  isFetching,
  location,
  nurseryDiscounts,
  nurseryDiscountsV3Actions,
  nurseryDiscountsV3Selectors,
  nurseryOptions,
  paginationUtils,
  setLocationQuery,
  sortingUtils,
  totalResults,
}) => {
  const { getPaginationDetails, onPageChange, page } = paginationUtils
  const { onSortChange, sortField, sortOrder } = sortingUtils
  const routePrefix = nurseryOptions.prefix
  const { statusFilter: statusFilterQuery } = location.query
  const [statusFilter, setStatusFilter] = useState(statusFilterQuery || NURSERY_DISCOUNTS_FILTER.ACTIVE)

  const tableData = useMemo(() => getTableData(nurseryDiscounts), [nurseryDiscounts])

  const fetchData = () => {
    const criteria = nurseryDiscountsV3Selectors.getCriteriaSelector({ statusFilter })
    const apiParams = {
      criteria,
      groups: NURSERY_DISCOUNTS_GROUPS,
      order: { sortField, sortOrder },
      page,
    }

    setLocationQuery({ statusFilter })
    nurseryDiscountsV3Actions.list({ params: apiParams })
  }

  const handleStatusFilterChange = (statusFilters) => {
    setStatusFilter(statusFilters ? statusFilters.value : null)
  }

  useEffect(() => {
    fetchData()

    return () => {
      nurseryDiscountsV3Actions.clearList()
    }
  }, [statusFilter, sortOrder, page])

  const handleSortChange = (key) => {
    onSortChange(onPageChange()(1))(key)
  }

  return (
    <ManagementDiscountsView
      discounts={tableData}
      errorMessages={errorMessages}
      isLoading={isFetching}
      pagination={getPaginationDetails(totalResults)}
      routePrefix={routePrefix}
      sortField={sortField}
      sortOrder={sortOrder}
      statusFilter={statusFilter}
      onSortChange={handleSortChange}
      onStatusFilterChange={handleStatusFilterChange}
    />
  )
}

const enhance = compose(
  withAppService,
  withNurseryDiscountsV3Service,
  withPaginationUtils,
  withRouterUtils,
  withSortingUtils,
  connector,
)

export default enhance(ManagementDiscountsV3Container)
