import moment from 'moment'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

export const getPublicEnquiryRequestBody = ({ captcha, fields }) => {
  const {
    childBirthDate,
    childDesiredStartDate,
    childFirstName,
    childGender,
    childSurname,
    email,
    firstName,
    phoneNumber,
    sendConfirmationEmail,
    source,
    surname,
  } = fields

  return {
    captcha,
    data: {
      child: {
        birthDate: moment(childBirthDate).format(DEFAULT_DATE_FORMAT),
        firstName: childFirstName,
        gender: childGender?.value,
        surname: childSurname,
      },
      contact: {
        email,
        firstName,
        surname,
        telephone: phoneNumber,
      },
      source: source ? { id: source.value } : undefined,
    },
    sendConfirmationEmail,
    startDate: childDesiredStartDate ? moment(childDesiredStartDate).format(DEFAULT_DATE_FORMAT) : null,
  }
}
