import React, { PropsWithChildren } from 'react'

import AccordionItem from './AccordionItem'

interface AccordionRoot extends React.FC<PropsWithChildren<any>> {
  Item?: typeof AccordionItem
}

const Accordion: AccordionRoot = ({ children }): any => children

export default Accordion
