import React from 'react'
import { Field } from 'redux-form'

import { DEPOSIT_TYPES } from 'services/legacy/deposits/constants'

import { isRequired } from 'utils/fieldValidation'

import { Form } from 'components'

import i18n from 'translations'

import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

interface DepositRefundFormFieldsProps {
  childId?: string
  isReadOnly?: boolean
}

export interface DepositRefundFormProps {
  deposit?: string
  total?: number
}

const DepositRefundFormFields = ({
  childId,
  isReadOnly,
}: DepositRefundFormFieldsProps): JSX.Element => (
  <React.Fragment>
    <Form.Row
      label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:RefundDeposit:label')}
      width={{ field: '400px' }}
      required
    >
      <Field
        childId={childId}
        component={Form.InfiniteDropdowns.Deposits}
        disabled={isReadOnly}
        maxMenuHeight={150}
        name="deposit"
        placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:RefundDeposit:placeholder')}
        type={DEPOSIT_TYPES.REFUND}
        validate={isRequired}
        pendingOnly
      />
    </Form.Row>
    <Form.Row
      label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Amount:label')}
      width={{ field: '150px' }}
      required
    >
      <SubdomainCurrencyProvider>
        {({ currencySymbol }) => (
          <Field
            component={Form.TextField}
            name="total"
            placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:Amount:placeholder')}
            prefix={currencySymbol}
            prefixWidth="30px"
            type="number"
            validate={isRequired}
            disabled
          />
        )}
      </SubdomainCurrencyProvider>
    </Form.Row>
  </React.Fragment>
)

export default DepositRefundFormFields
