import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'
import layout from 'constants/layout'

export const StyledBox = styled.div`
  background-color: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  display: flex;
  padding: 15px;
  width: 100%;
  margin-bottom: 10px;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    flex-direction: column;
  }
`

export const StyledFirstColumn = styled.div`
  display: block;
  width: 160px;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    width: 100%;
  }

  & > div:not(:last-child) {
    margin-bottom: 15px;
  }
`

export const StyledSecondColumn = styled.div`
  padding-left: 25px;
  width: 100%;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    padding: 20px 0 0;
  }
`

export const StyledNotesRow = styled.div`
  width: 100%;

  & > div {
    margin-bottom: 0;

    textarea {
      height: 93px;
    }
  }

  & > div > div > div {
    width: 100%;
  }
`

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 10px;

  & > div {
    margin-left: 5px;
    margin-right: 5px;
  }

  @media(max-width: ${layout.mobileWidthResolution}px) {
    justify-content: flex-start;
  }
`
