import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import * as constants from 'services/legacy/rooms/constants'

import { withAppService } from 'services/app'
import { withRoomsService } from 'services/legacy/rooms'

import { Select } from 'components'

import i18n from 'translations'

import withInfiniteDropdown from './withInfiniteDropdown'

class RoomsDropdown extends Component {
  componentDidMount() {
    const { infiniteDropdownHelpers, roomsActions, value } = this.props

    if (!_.isArray(value)) {
      return infiniteDropdownHelpers.handleComponentDidMount({
        action: () => roomsActions.get({
          addToList: true,
          onFailed: this.notFoundValue,
          onSuccess: infiniteDropdownHelpers.handleComponentDidMountOnSuccess,
          params: [(value?.value || value)],
        }),
        value,
      })
    }

    return null
  }

  componentWillUnmount() {
    const { roomsActions } = this.props

    roomsActions.clear()
  }

  handleLoadMoreElements = async (phrase) => {
    const { allRoomTypes, infiniteDropdownHelpers, limit, roomsActions, roomsSelectors } = this.props

    return infiniteDropdownHelpers.handleLoadMoreElements({
      clearAction: roomsActions.clear,
      listAction: ({ newPage, newSearchPhrase, result }) => roomsActions.list({
        criteria: roomsSelectors.getCriteriaSelector({
          phrase: newSearchPhrase,
          roomType: allRoomTypes ? constants.ROOM_TYPES.ALL : undefined,
        }),
        limit,
        page: newPage,
      }, true, (response) => infiniteDropdownHelpers.handleLoadMoreElementsOnSuccess({
        extraFields: ['type'],
        newPage,
        newSearchPhrase,
        response,
        result,
      })),
      phrase,
    })
  }

  notFoundValue = () => {
    const { onChange } = this.props

    onChange(null)
  }

  handleChange = (e) => {
    const { infiniteDropdownHelpers, onChange } = this.props

    infiniteDropdownHelpers.handleOnChange(e, () => onChange(e))
  }

  render() {
    const {
      disabled,
      infiniteDropdownState,
      isFetching,
      placeholder,
      rawValue,
      value,
      ...rest
    } = this.props
    const { cacheUniq, options, selectedValue } = infiniteDropdownState

    return (
      <Select
        {...rest}
        cacheUniq={cacheUniq}
        disabled={disabled || isFetching}
        loadOptions={this.handleLoadMoreElements}
        options={options}
        placeholder={placeholder || _.upperFirst(i18n.t('global:room'))}
        rawValue={rawValue || selectedValue || value}
        value={value}
        searchable
        onChange={this.handleChange}
      />
    )
  }
}

const mapState = (state, { appSelectors, roomsSingleState }) => ({
  isFetching: appSelectors.getIsFetching(roomsSingleState),
})

const enhance = compose(
  withAppService,
  withRoomsService,
  withInfiniteDropdown,
  connect(mapState),
)

export default enhance(RoomsDropdown)
