import ServiceBase from 'services/ServiceBase'

import { getQueryString } from 'utils/api'

class ChildBankDetailsApiClient extends ServiceBase {
  create = (childId, payload, params) => this.request({
    body: payload,
    method: 'POST',
    path: `/children/${childId}/bank-details?${getQueryString(params)}`,
  })

  update = (childId, payload, params) => this.request({
    body: payload,
    method: 'PATCH',
    path: `/children/${childId}/bank-details?${getQueryString(params)}`,
  })
}

export default ChildBankDetailsApiClient
