export const CREATE_ENQUIRIES = 'CREATE_ENQUIRIES'
export const CREATE_ENQUIRIES_SUCCESS = 'CREATE_ENQUIRIES_SUCCESS'
export const CREATE_ENQUIRIES_FAILED = 'CREATE_ENQUIRIES_FAILED'

export const GET_ENQUIRIES = 'GET_ENQUIRIES'
export const GET_ENQUIRIES_SUCCESS = 'GET_ENQUIRIES_SUCCESS'
export const GET_ENQUIRIES_FAILED = 'GET_ENQUIRIES_FAILED'

export const UPDATE_ENQUIRIES = 'UPDATE_ENQUIRIES'
export const UPDATE_ENQUIRIES_SUCCESS = 'UPDATE_ENQUIRIES_SUCCESS'
export const UPDATE_ENQUIRIES_FAILED = 'UPDATE_ENQUIRIES_FAILED'

export const CLEAR_ENQUIRIES_SINGLE = 'CLEAR_ENQUIRIES_SINGLE'
