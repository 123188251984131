import { generateBasicActions } from 'utils/service'

import {
  CLEAR_CHILD_BANK_DETAILS,
  GET_CHILD_BANK_DETAILS,
  GET_CHILD_BANK_DETAILS_FAILED,
  GET_CHILD_BANK_DETAILS_SUCCESS,
} from './constants'

export const getBankDetails = (options = {}) => ({ childApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_CHILD_BANK_DETAILS_FAILED,
    init: GET_CHILD_BANK_DETAILS,
    success: GET_CHILD_BANK_DETAILS_SUCCESS,
  },
  options,
  service: childApiClient,
  serviceMethod: 'get',
})

export const clearBankDetails = () => ({
  type: CLEAR_CHILD_BANK_DETAILS,
})
