import {
  ADD_NAPPIE,
  ADD_NAPPIE_FAILED,
  ADD_NAPPIE_SUCCESS,
  REMOVE_NAPPIE,
  REMOVE_NAPPIE_FAILED,
  REMOVE_NAPPIE_SUCCESS,
  UPDATE_NAPPIE,
  UPDATE_NAPPIE_FAILED,
  UPDATE_NAPPIE_SUCCESS,
} from './constants'

const initialState = {
  isSubmitting: false,
}

export default (state = initialState, { type }) => {
  switch (type) {
    case ADD_NAPPIE:
    case REMOVE_NAPPIE:
    case UPDATE_NAPPIE:
      return { ...state, isSubmitting: true }
    case REMOVE_NAPPIE_FAILED:
    case REMOVE_NAPPIE_SUCCESS:
    case ADD_NAPPIE_FAILED:
    case ADD_NAPPIE_SUCCESS:
    case UPDATE_NAPPIE_FAILED:
    case UPDATE_NAPPIE_SUCCESS:
      return { ...state, isSubmitting: false }
    default:
      return state
  }
}
