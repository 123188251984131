export const MAX_FILE_AGE_BEFORE_MARKED_FAILED = 4 * 60 * 60

export const UPLOAD_DIRECTORY = {
  DOCUMENTS: 'documents',
  IMAGES: 'images',
  PUBLIC_IMAGES: 'images/public',
}

export const REFERENCE_ASYNC_PAGE_TYPES = {
  ACTIVITY: 'activityRecord',
  CHILD: 'child',
  MEMBERSHIP: 'membership',
  NURSERY: 'nursery',
  OBSERVATION: 'observation',
}

export const UPLOADED_FILE_STATUS = {
  FAILED: 'failed',
  PROCESSING: 'processing',
  READY: 'ready',
  UPLOADING: 'uploading',
}

export const UPDATE_PROGRESS_EVENT = 'EVENT/UPDATE_PROGRESS'
