import React, { Component } from 'react'
import { compose } from 'recompose'

import { EVENTS, logEvent } from 'analytics'

import { withModalService } from 'services/utils/modal'

import ObservationDiaryButtonsWidgetView from './ObservationDiaryButtonsWidgetView'

class ObservationDiaryButtonsWidget extends Component {
  handleApproveDailyDiarySuccess = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.ALERT, {
      text: 'Diary has been approved.',
    })
  }

  handleSendDailyDiaryButtonClick = () => {
    const { modalActions, modalConsts } = this.props

    logEvent(EVENTS.DIARY_SEND_BTN_CLICKED, { context: 'dashboard' })

    modalActions.show(modalConsts.TYPES.DAILY_DIARY, {
      onSuccess: this.handleApproveDailyDiarySuccess,
    })
  }

  render() {
    return (
      <ObservationDiaryButtonsWidgetView
        onSendDailyDiaryButtonClick={this.handleSendDailyDiaryButtonClick}
      />
    )
  }
}

const enhance = compose(withModalService)

export default enhance(ObservationDiaryButtonsWidget)
