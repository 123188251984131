import _ from 'lodash'

import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { PAYOUTS_STATUSES } from 'services/nurseryIntegrations/constants'
import { ROLES } from 'constants/security'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withNurseryIntegrationsService } from 'services/nurseryIntegrations'
import { withPaginationUtils } from 'services/utils/pagination'

import FinancePayoutsView from './FinancePayoutsView'

const NURSERY_SETTINGS_GROUPS = {
  read: [
    'nurseryIntegration',
    'nurseryIntegrations.stripe',
  ],
}

const FinancePayoutsContainer = ({
  currenciesList,
  errorMessages,
  isFetchingStatistics,
  location,
  nurseriesActions,
  nurseryIntegrationsActions,
  nurseryIntegrationsSelectors,
  nurseryOptions,
  paginationUtils,
  records,
  setLocationQuery,
  statistics,
  totalResults,
}) => {
  const [dateRange, setDateRange] = useState(null)
  const [selectedCurrency, setFilterCurrency] = useState(null)
  const [isFetching, setIsFetching] = useState(true)
  const [isFetchingCurrency, setIsFetchingCurrency] = useState(false)
  const { getPageCount, onPageChange, page, perPage, setPageLocationQuery } = paginationUtils
  const { query } = location || {}
  const queryStatus = query?.status?.split(',')

  const [statusInitialized, setStatusInitialized] = useState(false)
  const [status, setStatus] = useState(
    _.mapValues(_.mapKeys(PAYOUTS_STATUSES, ({ value }) => value), ({ value }) => (
      _.includes(queryStatus, value)
    )),
  )

  useEffect(() => {
    setPageLocationQuery(false)
  }, [])

  const pageCount = getPageCount(totalResults)

  const handleChangeFilterDateChange = (value) => setDateRange(value)

  const handleChangeCurrencyTab = (currency) => {
    setIsFetchingCurrency(true)
    setFilterCurrency(currency)
  }

  const fetch = () => {
    const criteria = nurseryIntegrationsSelectors.getCriteria({
      currency: selectedCurrency,
      dateRange,
      status,
    })

    setLocationQuery({
      status: _.join(_.keys(_.pickBy(status, (value) => value))),
    })

    nurseryIntegrationsActions.getPayouts({
      mergeResult: 1 !== page,
      onSuccess: () => {
        if (1 !== page) {
          setIsFetching(false)
        }
      },
      params: {
        criteria,
        page,
      },
    })

    if (1 === page) {
      const statisticsCriteria = nurseryIntegrationsSelectors.getCriteria({
        currency: selectedCurrency,
        dateRange,
      })

      nurseryIntegrationsActions.getPayoutsStatistics({
        onSuccess: () => {
          setIsFetching(false)
          setIsFetchingCurrency(false)
        },
        params: {
          criteria: statisticsCriteria,
        },
      })
    } else {
      setIsFetchingCurrency(false)
    }
  }

  const getNurserySettings = ({ data: responseCurrencies }) => {
    nurseriesActions.get(nurseryOptions.id, {
      onSuccess: ({ data }) => {
        const { currency } = data?.stripeNurseryIntegration || {}

        if (currency) {
          setFilterCurrency(currency)
        } else if (responseCurrencies?.length) {
          setFilterCurrency(_.first(responseCurrencies))
        } else {
          fetch()
        }
      },
      params: {
        groups: NURSERY_SETTINGS_GROUPS,
      },
    })
  }

  useEffect(() => {
    if (selectedCurrency) {
      fetch()
    }
  }, [selectedCurrency, dateRange])

  useEffect(() => {
    nurseryIntegrationsActions.getPayoutCurrencies({
      onSuccess: getNurserySettings,
    })

    return () => {
      nurseryIntegrationsActions.clearPayouts()
      nurseryIntegrationsActions.clearPayoutCurrencies()
      nurseryIntegrationsActions.clearPayoutsStatistics()
    }
  }, [])

  const handlePageChange = (newPage) => onPageChange(fetch)(newPage)

  useEffect(() => {
    if (!statusInitialized) {
      return setStatusInitialized(true)
    }

    setIsFetching(true)
    return handlePageChange(1)
  }, [status])

  const handleChangeFilterStatus = (label, value) => {
    setStatus((prev) => ({
      ...prev,
      [label]: value,
    }))
  }

  const pagination = {
    onPageChange: handlePageChange,
    page,
    pageCount,
    perPage,
  }

  return (
    <FinancePayoutsView
      currenciesList={currenciesList}
      dateRange={dateRange}
      errorMessages={errorMessages}
      isFetching={isFetching}
      isFetchingCurrency={isFetchingCurrency}
      isFetchingStatistics={isFetchingStatistics}
      pagination={pagination}
      records={records}
      selectedCurrency={selectedCurrency}
      statistics={statistics}
      status={status}
      totalResults={totalResults}
      onChangeFilterCurrency={handleChangeCurrencyTab}
      onChangeFilterDateChange={handleChangeFilterDateChange}
      onChangeFilterStatus={handleChangeFilterStatus}
    />
  )
}

const mapState = (state, {
  appSelectors,
  nurseryIntegrationsListState,
  nurseryIntegrationsSelectors,
  nurseryIntegrationsStatisticsState,
  params,
}) => ({
  currenciesList: nurseryIntegrationsSelectors.getNurseryIntegrationsCurrenciesList(state),
  errorMessages: appSelectors.getErrorMessages(nurseryIntegrationsListState),
  isFetchingStatistics: appSelectors.getIsFetching(nurseryIntegrationsStatisticsState),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  records: nurseryIntegrationsSelectors.getPayouts(state),
  statistics: nurseryIntegrationsSelectors.getPayoutsStatistics(state),
  totalResults: appSelectors.getTotalResults(nurseryIntegrationsListState),
})

FinancePayoutsContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

const enhance = compose(
  withAppService,
  withNurseriesService,
  withNurseryIntegrationsService,
  withPaginationUtils,
  connect(mapState),
)

export default enhance(FinancePayoutsContainer)
