import React from 'react'
import { ReactSVG } from 'react-svg'

import { Page, Typography } from 'components'

import i18n from 'translations'

import logo from 'assets/images/blossom-logo.svg'

import { StyledContainer, StyledLinkExpired, StyledLogo } from './DiaryLinkExpiredStyled'

const DiaryLinkExpiredView = ({
  customLogo,
}) => (
  <Page>
    <Page.Content>
      <StyledContainer>
        <StyledLogo
          src={customLogo || logo}
        />
        <StyledLinkExpired>
          <ReactSVG src="/images/link-expired.svg" />
        </StyledLinkExpired>
        <Typography
          variant="h2"
          primary
        >
          {i18n.t('module:Various:DiaryLinkExpired:text_1')}
        </Typography>
        <Typography
          margin="50px 0 20px 0"
          opacity="0.6"
          variant="h5"
          primary
        >
          {i18n.t('module:Various:DiaryLinkExpired:text_2')}
        </Typography>
        <Typography
          variant="h3"
          primary
        >
          {i18n.t('module:Various:DiaryLinkExpired:text_3')}
        </Typography>
      </StyledContainer>
    </Page.Content>
  </Page>
)

export default DiaryLinkExpiredView
