/* eslint-disable max-len */
/* eslint-disable import-newlines/enforce */

import ManagementFundingAddContainer
  from 'module/Management/ManagementFinanceSettings/ManagementFunding/v2/ManagementFundingAdd'
import ManagementFundingV3AddContainer
  from 'module/Management/ManagementFinanceSettings/ManagementFunding/v3/ManagementFundingAdd'
import ChildFunding from 'module/Children/Child/ChildBookingPattern/ChildFunding/ChildFundingAdd'
import ChildFundingAllocation from 'module/Children/Child/ChildBookingPattern/ChildFunding/ChildFundingAllocation'

/* eslint-enable max-len */
/* eslint-enable import-newlines/enforce */

export const FUNDING_MODAL_TYPE = {
  ADD_CHILD_FUNDING: 'ADD_CHILD_FUNDING',
  ADD_NURSERY_FUNDING: 'ADD_NURSERY_FUNDING',
  ADD_NURSERY_FUNDING_V3: 'ADD_NURSERY_FUNDING_V3',
  ALLOCATE_CHILD_FUNDING: 'ALLOCATE_CHILD_FUNDING',
}

export const FUNDING_MODAL_COMPONENT = {
  [FUNDING_MODAL_TYPE.ADD_CHILD_FUNDING]: ChildFunding,
  [FUNDING_MODAL_TYPE.ADD_NURSERY_FUNDING]: ManagementFundingAddContainer,
  [FUNDING_MODAL_TYPE.ADD_NURSERY_FUNDING_V3]: ManagementFundingV3AddContainer,
  [FUNDING_MODAL_TYPE.ALLOCATE_CHILD_FUNDING]: ChildFundingAllocation,
}
