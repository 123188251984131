import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class EnquiryReportsApiClient extends ServiceBase {
  getConversionRate = (params = {}) => this.request({
    path: `/v2/statistics/enquiry_conversion_rate?${getQueryStringV2(params)}`,
  })

  getConversionRateStatistics = (params = {}) => this.request({
    path: `/v2/statistics/enquiry_conversion_rate/total?${getQueryStringV2(params)}`,
  })

  getPerformance = (params = {}) => this.request({
    path: `/v2/statistics/enquiry_performance?${getQueryStringV2(params)}`,
  })

  getViewingPerformance = (params = {}) => this.request({
    path: `/v2/statistics/enquiry_viewing_performance?${getQueryStringV2(params)}`,
  })

  getViewingPerformanceStatistics = (params = {}) => this.request({
    path: `/v2/statistics/enquiry_viewing_performance/total?${getQueryStringV2(params)}`,
  })
}

export default EnquiryReportsApiClient
