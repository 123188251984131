import React, { useEffect } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'
import { formValueSelector, getFormValues, stopSubmit } from 'redux-form'

import { ModalType } from 'modals'
import { RootState } from 'core/reducers'

import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withAppService, withAppServiceProps } from 'services/app'
import { withRouteUtilsProps, withRouterUtils } from 'services/utils/router'
import { withNurseryDiscountsV3Service, withNurseryDiscountsV3ServiceProps } from 'services/product/nurseryDiscountsV3'
import { withRouter, withRouterProps } from 'services/router'

import { generateRoute } from 'utils/routing'
import { getBackendErrors } from 'utils/backendErrors'

import i18n from 'translations'

import { ADD_DISCOUNT_FORM } from './components/AddDiscountForm'
import ManagementDiscountsAddV3View from './ManagementDiscountsAddV3View'

const NURSERY_DISCOUNTS_GROUPS = {
  read: [
    'nurseryDiscountProduct.settings',
    'nurseryDiscountProduct.nursery',
    'nurseryDiscountProductSettings',
  ],
}

type ManagementDiscountsAddV3ContainerProps = withAppServiceProps
  & withNurseryDiscountsV3ServiceProps
  & withRouteUtilsProps
  & withRouterProps
  & withModalServiceProps

const mapDispatch = {
  injectValidation: (formName, params) => stopSubmit(formName, params),
}

const mapState = (state: RootState, {
  appSelectors,
  nurseryDiscountsV3Selectors,
  nurseryDiscountsV3SingleState,
}: ManagementDiscountsAddV3ContainerProps) => ({
  discount: nurseryDiscountsV3Selectors.getNurseryDiscountsSingleSelector(state),
  errorMessages: appSelectors.getErrorMessages(nurseryDiscountsV3SingleState),
  formValues: getFormValues(ADD_DISCOUNT_FORM)(state),
  isFetching: appSelectors.getIsFetching(nurseryDiscountsV3SingleState),
  isSubmitting: appSelectors.getIsSubmitting(nurseryDiscountsV3SingleState),
  type: formValueSelector(ADD_DISCOUNT_FORM)(state, 'type'),
})

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

const ManagementDiscountsAddV3Container: React.FC<ManagementDiscountsAddV3ContainerProps & PropsFromRedux> = ({
  discount,
  errorMessages,
  formValues,
  injectValidation,
  isFetching,
  isSubmitting,
  modalActions,
  navigate,
  nurseryDiscountsV3Actions,
  nurseryDiscountsV3Selectors,
  params,
  type,
}) => {
  const { itemId } = params

  useEffect(() => {
    if (params.itemId) {
      nurseryDiscountsV3Actions.get({
        params: [itemId, {
          groups: NURSERY_DISCOUNTS_GROUPS,
        }],
      })
    }

    return () => {
      nurseryDiscountsV3Actions.clear()
    }
  }, [])

  const handleSubmitSuccess = () => {
    navigate(generateRoute('MANAGEMENT.FINANCE_SETTINGS.DISCOUNTS'))
  }

  const handleSubmitFailed = (response) => {
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    return injectValidation(ADD_DISCOUNT_FORM, errors)
  }

  const handleSubmit = (fields) => {
    const body = nurseryDiscountsV3Selectors.getBody(fields)

    if (params.itemId) {
      return nurseryDiscountsV3Actions.update({
        body,
        onSuccess: handleSubmitSuccess,
        params: [itemId],
      })
    }

    return nurseryDiscountsV3Actions.create({
      body,
      onFailed: handleSubmitFailed,
      onSuccess: handleSubmitSuccess,
      params: { groups: NURSERY_DISCOUNTS_GROUPS },
    })
  }

  const archiveDiscount = (archived) => {
    const body = { archived, type }

    return nurseryDiscountsV3Actions.update({
      body,
      onFailed: handleSubmitFailed,
      onSuccess: handleSubmitSuccess,
      params: [itemId],
    })
  }

  const handleArchiveClick = (archived) => {
    const label = archived ? i18n.t('global:archive') : i18n.t('global:unarchive')
    const text = archived
      ? i18n.t('module:Management:Finance:Discounts:archivedText', { label })
      : i18n.t('module:Management:Finance:Discounts:unArchivedText')

    modalActions.show<ModalType.CONFIRM>(ModalType.CONFIRM, {
      confirmButtonLabel: label,
      icon: label,
      onConfirm: () => archiveDiscount(archived),
      text,
    })
  }

  const initialValues = nurseryDiscountsV3Selectors.getInitialValues(discount, params.itemId)

  return (
    <ManagementDiscountsAddV3View
      discount={discount}
      errorMessages={errorMessages}
      formValues={formValues}
      initialValues={initialValues}
      isEdit={params.itemId}
      isFormLoading={isSubmitting}
      isLoading={isFetching}
      onArchiveClick={handleArchiveClick}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withRouter,
  withModalService,
  withAppService,
  withRouterUtils,
  withNurseryDiscountsV3Service,
  connector,
)

export default enhance(ManagementDiscountsAddV3Container)
