import React from 'react'

import { Spinner } from 'components'

import { StyledContent } from './WidgetStyled'

const WidgetContent = ({
  children,
  horizontalAlign,
  id,
  isLoading,
  noPadding,
  primary,
  verticalAlign,
}) => (
  <StyledContent
    horizontalAlign={horizontalAlign}
    id={id}
    isLoading={isLoading}
    noPadding={noPadding}
    primary={primary}
    verticalAlign={verticalAlign}
  >
    {isLoading ? (
      <Spinner
        light
      />
    ) : children}
  </StyledContent>
)

export default WidgetContent
