import {
  CLEAR_NURSERIES_STARTING_REPORT,
  NURSERIES_STARTING_REPORT,
  NURSERIES_STARTING_REPORT_FAILED,
  NURSERIES_STARTING_REPORT_SUCCESS,
} from 'services/childrenStartingReport/nurseries/constants'

import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

export default (...props) => {
  const [state = getBasicListInitialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_NURSERIES_STARTING_REPORT,
    failed: NURSERIES_STARTING_REPORT_FAILED,
    init: NURSERIES_STARTING_REPORT,
    success: NURSERIES_STARTING_REPORT_SUCCESS,
  }, getBasicListInitialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
