import { generateBasicActions } from 'utils/service'

import {
  ADD_SLEEP,
  ADD_SLEEP_FAILED,
  ADD_SLEEP_SUCCESS,
  REMOVE_SLEEP,
  REMOVE_SLEEP_FAILED,
  REMOVE_SLEEP_SUCCESS,
  UPDATE_SLEEP,
  UPDATE_SLEEP_FAILED,
  UPDATE_SLEEP_SUCCESS,
} from './constants'

export const addRecord = (options = {}) => ({ dailyDiarySleepsApiClient }) => generateBasicActions.create({
  constants: {
    failed: ADD_SLEEP_FAILED,
    init: ADD_SLEEP,
    success: ADD_SLEEP_SUCCESS,
  },
  options,
  service: dailyDiarySleepsApiClient,
  serviceMethod: 'addRecord',
})

export const removeRecord = (id, onSuccess, onFailed) => ({ dailyDiarySleepsApiClient }) => (dispatch) => {
  dispatch({ id, type: REMOVE_SLEEP })

  dailyDiarySleepsApiClient.removeRecord(id).then(
    () => {
      dispatch({ id, type: REMOVE_SLEEP_SUCCESS })

      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: REMOVE_SLEEP_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const updateRecord = (id, body, onSuccess, onFailed) => ({ dailyDiarySleepsApiClient }) => (dispatch) => {
  dispatch({ type: UPDATE_SLEEP })

  dailyDiarySleepsApiClient.updateRecord(id, body).then(
    ({ data }) => {
      dispatch({ payload: data, type: UPDATE_SLEEP_SUCCESS })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: UPDATE_SLEEP_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}
