import { compose } from 'recompose'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withCreditNotesService } from 'services/legacy/creditNotes'
import { withModalService } from 'services/utils/modal'
import { withRouter } from 'services/router'

import i18n from 'translations'

import ChildFinanceCreditNotesPreviewView from './ChildFinanceCreditNotesPreviewView'

const groups = {
  read: [
    'creditNote.invoice',
    'creditNote.author',
    'child',
    'invoice',
    'user',
  ],
}

class FinanceInvoicesPreviewContainer extends Component {
  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { creditNotesActions } = this.props

    creditNotesActions.clear()
  }

  fetch = () => {
    const { creditNotesActions, params } = this.props
    const { creditNoteId } = params

    creditNotesActions.get(creditNoteId, { groups })
  }

  handleDownloadButtonClick = () => {
    const { creditNotesActions, modalActions, modalConsts, params } = this.props
    const { creditNoteId } = params

    modalActions.show(modalConsts.TYPES.ALERT, {
      text: i18n.t('module:Children:Child:Finance:CreditNotes:downloading'),
    })

    creditNotesActions.download(creditNoteId, () => {
      modalActions.show(modalConsts.TYPES.ALERT, {
        text: i18n.t('module:Children:Child:Finance:CreditNotes:downloadComplete'),
      })
    })
  }

  handleSendButtonClick = () => {
    const { creditNote, creditNotesActions, modalActions, modalConsts, params } = this.props
    const { sentAt } = creditNote
    const { creditNoteId } = params

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('global:Yes'),
      icon: sentAt ? 'resend' : 'send',
      onConfirm: () => {
        creditNotesActions.send(
          creditNoteId,
          () => {
            modalActions.show(modalConsts.TYPES.ALERT, {
              text: i18n.t('module:Children:Child:Finance:CreditNotes:sendComplete'),
            })
          },
          ({ response }) => {
            modalActions.show(modalConsts.TYPES.ALERT, {
              icon: 'warning',
              text: response?.message,
            })
          },
        )
      },
      text: sentAt
        ? i18n.t('module:Children:Child:Finance:CreditNotes:resendAlertText')
        : i18n.t('module:Children:Child:Finance:CreditNotes:sendAlertText'),
    })
  }

  render() {
    const {
      creditNote,
      errorMessages,
      isFetching,
      location,
    } = this.props
    const locationQuery = location ? location.query : null

    return (
      <ChildFinanceCreditNotesPreviewView
        creditNote={creditNote}
        errorMessages={errorMessages}
        isLoading={isFetching}
        locationQuery={locationQuery}
        onDownloadButtonClick={this.handleDownloadButtonClick}
        onSendButtonClick={this.handleSendButtonClick}
      />
    )
  }
}

const mapState = (state, { appSelectors, creditNotesSelectors, creditNotesSingleState }) => ({
  creditNote: creditNotesSelectors.getCreditNote(state),
  errorMessages: appSelectors.getErrorMessages(creditNotesSingleState),
  isFetching: creditNotesSelectors.getCreditNotesSingleIsFetching(state),
})

const enhance = compose(
  withAppService,
  withRouter,
  withCreditNotesService,
  withModalService,
  connect(mapState),
)

export default enhance(FinanceInvoicesPreviewContainer)
