import Login from './Login'
import ResetPassword from './ResetPassword'
import Invitation from './Invitation'

export const ROUTE_NAMES = {
  'AUTHENTICATION.INVITATION': 'AUTHENTICATION.INVITATION',
  'AUTHENTICATION.LOGIN': 'AUTHENTICATION.LOGIN',
  'AUTHENTICATION.RECOVER_PARENT': 'AUTHENTICATION.RECOVER_PARENT',
  'AUTHENTICATION.RESET': 'AUTHENTICATION.RESET',
  'AUTHENTICATION.RESET_PARENT': 'AUTHENTICATION.RESET_PARENT',
}

const AuthenticationRouting = [
  {
    component: ResetPassword,
    disableAuth: true,
    name: ROUTE_NAMES['AUTHENTICATION.RESET_PARENT'],
    noBreadcrumb: true,
    path: 'parent/password',
  },
  {
    component: ResetPassword,
    disableAuth: true,
    name: ROUTE_NAMES['AUTHENTICATION.RECOVER_PARENT'],
    noBreadcrumb: true,
    path: 'parent/password/recover',
  },
  {
    component: Invitation,
    disableAuth: true,
    noBreadcrumb: true,
    path: 'invitation',
  },
  {
    childRoutes: [
      {
        component: ResetPassword,
        name: ROUTE_NAMES['AUTHENTICATION.RESET'],
        path: 'reset',
      },
    ],
    indexRoute: {
      component: Login,
      disableAuth: true,
      noBreadcrumb: true,
    },
    name: ROUTE_NAMES['AUTHENTICATION.LOGIN'],
    path: 'login',
  },
]

// @ts-ignore
AuthenticationRouting.ROUTE_NAMES = ROUTE_NAMES

export default AuthenticationRouting
