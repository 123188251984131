import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledStatusWrapper = styled.div`
  flex-direction: row;
  display: flex;
`

export const StyledPendingStatusDot = styled.div`
  height: 16px;
  width: 16px;
  background-color: ${NEUTRAL_COLOURS.BLUE_SECONDARY};
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
`
