import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withMembershipRegistersService } from 'services/legacy/membershipRegisters'

import EndTimeModalView from './EndTimeModalView'

class EndTimeModalContainer extends Component {
  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSubmit = (fields) => {
    const { onSubmit } = this.props

    onSubmit(fields)
  }

  render() {
    const { date, errorMessages, isSubmitting, signInAt } = this.props

    const initialValues = {
      signOutDate: moment(),
      signOutTime: moment(moment().diff(moment().startOf('day'))).utc(),
    }

    return (
      <EndTimeModalView
        date={date}
        errorMessages={errorMessages}
        initialValues={initialValues}
        isSubmitting={isSubmitting}
        signInAt={signInAt}
        onCloseClick={this.handleCloseClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  membershipRegistersSingleState,
}) => ({
  errorMessages: appSelectors.getErrorMessages(membershipRegistersSingleState),
  isSubmitting: appSelectors.getIsSubmitting(membershipRegistersSingleState),
})

const enhance = compose(
  withAppService,
  withModalService,
  withMembershipRegistersService,
  connect(mapState),
)

export default enhance(EndTimeModalContainer)
