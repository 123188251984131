import styled from 'styled-components'

import { Button } from 'components'

export const StyledContentWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const StyledCopyWrapper = styled.div`
  text-align: center;
`

export const StyledButton = styled(Button)`
  margin: 32px 0 0;
`

export const StyledWrapper = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-rows: 0fr auto 0fr;
`
