import _ from 'lodash'

import React from 'react'
import { FormSection } from 'redux-form'

import { FEATURE_FLAGS_OPTIONS } from 'services/legacy/featureFlags/constants'

import nurseryIntegrationsSelectors from 'services/nurseryIntegrations/selectors'

import { Section } from 'components'

import i18n from 'translations'
import FeatureFlagItemField from './FeatureFlagItemField'

const FeatureFlagsFormSection = ({
  formValues,
  isOrganizationContext,
  nursery,
  onFeatureFlagChange,
}) => {
  let featureFlags = FEATURE_FLAGS_OPTIONS

  if (isOrganizationContext) {
    featureFlags = _.filter(FEATURE_FLAGS_OPTIONS, ({ hideForOrganization }) => !hideForOrganization)
  }

  const renderFeatureFlags = () => _.map(featureFlags, ({ label, value }) => (
    <FeatureFlagItemField
      formValues={formValues}
      key={value}
      label={label}
      nursery={nursery}
      nurseryIntegrationsSelectors={nurseryIntegrationsSelectors}
      value={value}
      onFeatureFlagChange={onFeatureFlagChange}
    />
  ))

  return (
    <Section
      title={_.upperFirst(i18n.t('global:featureFlags'))}
      variant="small"
    >
      <FormSection name="featureFlags">
        {renderFeatureFlags()}
      </FormSection>
    </Section>
  )
}

export default FeatureFlagsFormSection
