import _ from 'lodash'

import React from 'react'

import { OBSERVATION_FEEDBACK_STATUSES } from 'services/legacy/observationFeedback/constants'

import { CommentDrawer, Typography } from 'components'

import i18n from 'translations'

import AddFeedbackForm from './components/AddFeedbackForm'

const FeedbackView = ({
  authUser,
  formEnabled,
  hasOnlySeniorTeacherOrTeacherAccess,
  isFetching,
  isSubmitting,
  observation,
  observationFeedbackLists,
  onAddFeedbackClick,
  onApproveFeedback,
  onCancelClick,
  onSubmit,
}) => {
  const { author, createdAt, feedbackCounter } = observation || {}

  if (hasOnlySeniorTeacherOrTeacherAccess && (authUser.id !== author?.id || !feedbackCounter)) {
    return null
  }

  if (!observation?.id) {
    return null
  }

  const renderObservationDetails = () => author && (
    <CommentDrawer.DetailsHeader
      author={author}
      createdAt={createdAt}
      label={i18n.t('module:Learning:Observations:ObservationsFeedback:observationBy')}
    />
  )

  const renderList = () => (
    <CommentDrawer.List
      isFetching={isFetching}
    >
      {renderObservationDetails()}
      {_.map(observationFeedbackLists, ({
        author: authorFeedback,
        comment,
        createdAt: createdAtFeedback,
        improvements,
        updatedAt,
        updatedBy,
      }) => (
        <CommentDrawer.List.Item
          avatars={[
            {
              author: authorFeedback,
              label: i18n.t('module:Learning:Observations:ObservationsFeedback:feedbackBy'),
            },
            ...(updatedBy ? [{
              author: updatedBy,
              label: i18n.t('module:Learning:Observations:ObservationsFeedback:updatedBy'),
            }] : []),
          ]}
          defaultIsOpen={!updatedBy}
          details={[
            {
              label: i18n.t('module:Learning:Observations:ObservationsFeedback:comments'),
              text: comment,
            },
            {
              label: i18n.t('module:Learning:Observations:ObservationsFeedback:improvements'),
              text: (
                <React.Fragment>
                  <Typography>
                    {improvements.observationDetails
                      && i18n.t('module:Learning:Observations:ObservationsFeedback:Improvements:observationDetails')}
                  </Typography>
                  <Typography>
                    {improvements.spellingAndGrammar
                      && i18n.t('module:Learning:Observations:ObservationsFeedback:Improvements:spellingAndGrammar')}
                  </Typography>
                  <Typography>
                    {improvements.linksToEyfs
                      && i18n.t('module:Learning:Observations:ObservationsFeedback:Improvements:linksToFramework')}
                  </Typography>
                  <Typography>
                    {improvements.nextSteps
                      && i18n.t('module:Learning:Observations:ObservationsFeedback:Improvements:nextSteps')}
                  </Typography>
                </React.Fragment>
              ),
            },
          ]}
          header={{
            date: updatedBy
              ? updatedAt
              : createdAtFeedback,
            text: updatedBy
              ? OBSERVATION_FEEDBACK_STATUSES.CHANGES_MADE.label
              : OBSERVATION_FEEDBACK_STATUSES.CHANGES_REQUESTED.label,
          }}
          headerColor={updatedBy
            ? OBSERVATION_FEEDBACK_STATUSES.CHANGES_MADE.color
            : OBSERVATION_FEEDBACK_STATUSES.CHANGES_REQUESTED.color}
          headerIcon={updatedBy
            ? 'feedback-changes-made'
            : 'feedback'}
        />
      ))}
    </CommentDrawer.List>
  )

  const renderFooter = () => {
    if (!hasOnlySeniorTeacherOrTeacherAccess) {
      return (
        <CommentDrawer.Footer
          disabled={isSubmitting}
          label={i18n.t('module:Learning:Observations:ObservationsFeedback:addFeedback')}
          onClick={onAddFeedbackClick}
        />
      )
    }

    if (0 < feedbackCounter
      && hasOnlySeniorTeacherOrTeacherAccess
      && _.find(observationFeedbackLists, (e) => !e.updatedBy)
    ) {
      return (
        <CommentDrawer.Footer
          isLoading={isSubmitting}
          label={i18n.t('module:Learning:Observations:ObservationsFeedback:sendForApproval')}
          onClick={onApproveFeedback}
        />
      )
    }

    return null
  }

  const renderForm = () => (
    <CommentDrawer.Form>
      <AddFeedbackForm
        header={renderObservationDetails()}
        isSubmitting={isSubmitting}
        onCancelClick={onCancelClick}
        onSubmit={onSubmit}
      />
    </CommentDrawer.Form>
  )

  return (
    <CommentDrawer
      defaultOpen={0 < feedbackCounter}
      footer={renderFooter()}
      form={renderForm()}
      formEnabled={formEnabled}
      list={renderList()}
      title={i18n.t('module:Learning:Observations:ObservationsFeedback:title')}
    />
  )
}

export default FeedbackView
