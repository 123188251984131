import _ from 'lodash'

import { mergeData } from 'utils/data'

import {
  ADD_BOTTLE_TO_LIST,
  CLEAR_DAILY_DIARY_BOTTLES,
  GET_DAILY_DIARY_BOTTLES,
  GET_DAILY_DIARY_BOTTLES_FAILED,
  GET_DAILY_DIARY_BOTTLES_SUCCESS,
} from './constants'

import { REMOVE_BOTTLE_SUCCESS, UPDATE_BOTTLE_SUCCESS } from '../single/constants'

import { addDiaryBottleRecord, removeDiaryBottleRecord, updateDiaryBottleRecord } from './helpers'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: {},
}

export default (state = initialState, { error, id, mergeResult, payload, type }) => {
  switch (type) {
    case GET_DAILY_DIARY_BOTTLES:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_DAILY_DIARY_BOTTLES_SUCCESS:
      return {
        ...state,
        data: mergeData(state.data, payload.data, mergeResult),
        isFetching: false,
        meta: payload.meta,
      }
    case GET_DAILY_DIARY_BOTTLES_FAILED:
      return { ...state, error, isFetching: false }
    case ADD_BOTTLE_TO_LIST: {
      let result = { ...state }

      _.each(payload, (record) => {
        result = addDiaryBottleRecord(result, record)
      })

      return result
    }
    case UPDATE_BOTTLE_SUCCESS:
      return updateDiaryBottleRecord(state, payload)
    case REMOVE_BOTTLE_SUCCESS:
      return removeDiaryBottleRecord(state, id)
    case CLEAR_DAILY_DIARY_BOTTLES:
      return initialState
    default:
      return state
  }
}
