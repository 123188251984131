export const ENQUIRY_GROUPS = {
  read: [
    'enquiry.status',
    'enquiryStatusType',
    'enquiry.data',
    'enquiry.nurseryClass',
    'nurseryClass',
    'enquiry.sessionPlans',
    'enquiry.weeklyValue',
    'enquiry.data.settings',
    'enquirySettings',
    'nurserySession',
    'enquiry.child',
    'child',
    'child.extend',
  ],
}

export const ENQUIRY_GROUPS_V3 = {
  read: [
    'enquiry.status',
    'enquiryStatusType',
    'enquiry.data',
    'enquiry.nurseryClass',
    'nurseryClass',
    'enquiry.sessionProductPlans',
    'enquiry.weeklyValue',
    'enquiry.data.settings',
    'enquirySettings',
    'nurserySessionProduct',
    'enquiry.child',
    'child',
    'child.extend',
  ],
}
