import React from 'react'

import { ChildProductPriceCalculatorState } from 'services/child/models'

import { convertPayloadToDisplayValue } from 'utils/data'

import { Currency, Form, Spinner, TextField, Typography } from 'components'

import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'
import i18n from 'translations'

import { StyledAmountItem, StyledContainer, StyledDiscountContainer } from './PriceCalculatorStyled'

interface PriceCalculatorProps extends ChildProductPriceCalculatorState {
  isLoading: boolean
}

const PriceCalculator = ({ data, isLoading }: PriceCalculatorProps): JSX.Element => {
  const { associations, total, value } = data || {}

  const renderAssociations = () => (
    <div>
      <hr />
      <StyledDiscountContainer>
        <Typography margin="0 0 6px 0">
          {i18n.t('module:Finance:OneOffBookings:PeriodCalculator:appliedDiscountLabel')}
        </Typography>
        {associations.map((association) => (
          <StyledAmountItem>
            <Typography>
              <Currency value={convertPayloadToDisplayValue(association.value)} bold />
            </Typography>
            {association.name}
          </StyledAmountItem>
        ))}
      </StyledDiscountContainer>
      <hr />
      <StyledAmountItem>
        <Typography>
          <Currency value={convertPayloadToDisplayValue(total)} bold />
        </Typography>
        {i18n.t('module:Finance:OneOffBookings:PeriodCalculator:valueAfterDiscountLabel')}
      </StyledAmountItem>
    </div>
  )

  const renderValue = () => {
    if (isLoading) {
      return <Spinner position="flex-start" size="small" />
    }

    return (
      <SubdomainCurrencyProvider>
        {({ currencySymbol }) => (
          <TextField
            prefix={currencySymbol}
            prefixWidth="30px"
            value={convertPayloadToDisplayValue(value) || '-'}
            disabled
          />
        )}
      </SubdomainCurrencyProvider>
    )
  }

  return (
    <StyledContainer>
      <Form.Row label={i18n.t('global:Value')} width={{ field: '140px' }} verticalLabel>
        {renderValue()}
      </Form.Row>
      {associations?.length && !isLoading ? renderAssociations() : null}
    </StyledContainer>
  )
}

export default PriceCalculator
