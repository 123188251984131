import _ from 'lodash'

import React from 'react'

import { Spinner, Typography } from 'components'
import InlineEditableField from 'module/Management/components/InlineEditableField'

import i18n from 'translations'

import StaffRequiredForm, { STAFF_REQUIRED_FORM } from './StaffRequiredForm'

const STAFF_REQUIRED = {
  PER_AGE_GROUP: 'PER_AGE_GROUP',
  TOTAL_AT_THE_END: 'TOTAL_AT_THE_END',
}

const STAFF_REQUIRED_OPTIONS = [
  {
    label: i18n.t('module:Management:General:RotaAndOccupancy:StaffRequired:Options:PerAgeGroup:label'),
    tooltip: (
      <React.Fragment>
        <Typography fontSize={14} margin="0 0 14px">
          {i18n.t('module:Management:General:RotaAndOccupancy:StaffRequired:Options:PerAgeGroup:Tooltip:1')}
        </Typography>
        <Typography fontSize={14}>
          {i18n.t('module:Management:General:RotaAndOccupancy:StaffRequired:Options:PerAgeGroup:Tooltip:2')}
        </Typography>
        <Typography fontSize={14}>
          {i18n.t('module:Management:General:RotaAndOccupancy:StaffRequired:Options:PerAgeGroup:Tooltip:3')}
        </Typography>
        <Typography fontSize={14}>
          {i18n.t('module:Management:General:RotaAndOccupancy:StaffRequired:Options:PerAgeGroup:Tooltip:4')}
        </Typography>
      </React.Fragment>
    ),
    value: STAFF_REQUIRED.PER_AGE_GROUP,
  },
  {
    label: i18n.t('module:Management:General:RotaAndOccupancy:StaffRequired:Options:TotalAtTheEnd:label'),
    tooltip: (
      <React.Fragment>
        <Typography fontSize={14} margin="0 0 14px">
          {i18n.t('module:Management:General:RotaAndOccupancy:StaffRequired:Options:TotalAtTheEnd:Tooltip:1')}
        </Typography>
        <Typography fontSize={14}>
          {i18n.t('module:Management:General:RotaAndOccupancy:StaffRequired:Options:TotalAtTheEnd:Tooltip:2')}
        </Typography>
        <Typography fontSize={14}>
          {i18n.t('module:Management:General:RotaAndOccupancy:StaffRequired:Options:TotalAtTheEnd:Tooltip:3')}
        </Typography>
        <Typography fontSize={14}>
          {i18n.t('module:Management:General:RotaAndOccupancy:StaffRequired:Options:TotalAtTheEnd:Tooltip:4')}
        </Typography>
      </React.Fragment>
    ),
    value: STAFF_REQUIRED.TOTAL_AT_THE_END,
  },
]

const StaffRequired = ({ isFetching, onSubmit, value }) => {
  const initialValues = { staffRequiredCalculation: value }

  const renderStaffRequired = () => _.find(STAFF_REQUIRED_OPTIONS, ['value', value])?.label

  return (
    <InlineEditableField
      description={isFetching ? (
        <Spinner
          position="flex-start"
        />
      ) : renderStaffRequired()}
      editableComponent={(callback, isSubmitting) => (
        <StaffRequiredForm
          initialValues={initialValues}
          isSubmitting={isSubmitting}
          options={STAFF_REQUIRED_OPTIONS}
          onSubmit={callback}
        />
      )}
      formName={STAFF_REQUIRED_FORM}
      title={i18n.t('module:Management:General:RotaAndOccupancy:StaffRequired:title')}
      value={value}
      enableHandleFailed
      onSubmit={onSubmit}
    />
  )
}

export default StaffRequired

