import _ from 'lodash'

import React from 'react'

import { getRoomName } from 'services/legacy/rooms/selectors'

import { DateString, DetailsBreakdown } from 'components'

import i18n from 'translations'

import { CHILD_EDIT_FORM_ROUTE_TYPE } from '../../../constants'
import BreakdownHeader from './BreakdownHeader'
import BreakdownSubHeader from './BreakdownSubHeader'
import BreakdownItem from './BreakdownItem'

const TRANS_PREFIX = 'module:Children:Child:Add:EnrolmentDetails'

const EnrolmentBreakdown = ({
  child,
  isFormContext,
  permissionToEdit,
}) => {
  const {
    id,
    keyWorkers,
    leavingDate,
    nurseryClass,
    profile,
    registeredAt,
    startDate,
  } = child
  const { doesReceiveEYPPFunding, doesReceiveTwoYearFunding } = profile || {}

  const renderAttendance = () => (
    <React.Fragment>
      <BreakdownSubHeader
        subtitle={i18n.t(`${TRANS_PREFIX}:Subsections:Attendance:title`)}
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Attendance:Form:Room:label`)}
        padding="15px 0"
        value={nurseryClass?.label || getRoomName(nurseryClass)}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Attendance:Form:User:label`)}
        padding="15px 0"
        value={keyWorkers?.length ? _.map(keyWorkers, (keyPerson, index) => (
          <React.Fragment>
            {keyPerson?.fullName}
            {keyWorkers.length - 1 !== index && (
              <React.Fragment>
                ,
                <br />
              </React.Fragment>
            )}
          </React.Fragment>
        )) : null}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Attendance:Form:StartDate:label`)}
        padding="15px 0"
        value={<DateString date={startDate} />}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Attendance:Form:LeavingDate:label`)}
        padding="15px 0"
        value={leavingDate && <DateString date={leavingDate} />}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Attendance:Form:RegistrationDate:label`)}
        padding="15px 0"
        value={registeredAt && <DateString date={registeredAt} />}
        withoutBorder
      />
    </React.Fragment>
  )

  const renderFunding = () => (
    <React.Fragment>
      <BreakdownSubHeader
        subtitle={i18n.t(`${TRANS_PREFIX}:Subsections:Funding:title`)}
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Funding:Form:TwoYearFunding:label`)}
        padding="15px 0"
        value={doesReceiveTwoYearFunding ? i18n.t('global:Yes') : i18n.t('global:No')}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Funding:Form:EYPPFunding:label`)}
        padding="15px 0"
        value={doesReceiveEYPPFunding ? i18n.t('global:Yes') : i18n.t('global:No')}
        withoutBorder
      />
    </React.Fragment>
  )

  return (
    <DetailsBreakdown marginBottom>
      <DetailsBreakdown.Section>
        <BreakdownHeader
          childFormRouteType={CHILD_EDIT_FORM_ROUTE_TYPE.ENROLMENT_DETAILS}
          childId={id}
          isFormContext={isFormContext}
          permissionToEdit={permissionToEdit}
          title={i18n.t(`${TRANS_PREFIX}:title`)}
        />
        {renderAttendance()}
        {renderFunding()}
      </DetailsBreakdown.Section>
    </DetailsBreakdown>
  )
}

export default EnrolmentBreakdown
