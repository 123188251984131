import React from 'react'

import { generateRoute } from 'utils/routing'

import { Callout, Page, Spinner } from 'components'

import i18n from 'translations'

import NurseriesEditForm from './components/NurseriesEditForm'

const NurseriesEditView = ({
  errorMessages,
  formValues,
  frameworkPagination,
  frameworks,
  initialValues,
  isAdministrationContext,
  isArchived,
  isFetching,
  isOrganizationContext,
  isSubmitting,
  nursery,
  nurseryId,
  nurserySettings,
  onApplyAll,
  onDisableClick,
  onFeatureFlagChange,
  onFrameworkPageChange,
  onSubmit,
}) => {
  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    return (
      <NurseriesEditForm
        cancelButtonLink={isOrganizationContext
          ? generateRoute('NURSERIES.DETAILS', { nurseryId })
          : generateRoute('NURSERIES.INDEX')}
        formValues={formValues}
        frameworkPagination={frameworkPagination}
        frameworks={frameworks}
        initialValues={initialValues}
        isAdministrationContext={isAdministrationContext}
        isArchived={isArchived}
        isOrganizationContext={isOrganizationContext}
        isSubmitting={isSubmitting}
        nursery={nursery}
        nurserySettings={nurserySettings}
        showFramework
        onApplyAll={onApplyAll}
        onDisableClick={onDisableClick}
        onFeatureFlagChange={onFeatureFlagChange}
        onFrameworkPageChange={onFrameworkPageChange}
        onSubmit={onSubmit}
      />
    )
  }

  return (
    <Page.Section
      isLoading={isFetching}
      title={i18n.t('module:Nurseries:Edit:title')}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default NurseriesEditView
