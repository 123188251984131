import _ from 'lodash'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { Currency, EmptyState, Typography, XMasIcon } from 'components'
import Widget from 'module/Dashboard/components/Widget'

import { StyledSummaryOutstanding, StyledTitle } from './TopOutstandingAccountsWidgetStyled'

const TopOutstandingAccountsWidgetView = ({
  childrenList,
  isLoading,
  totalOutstanding,
}) => {
  const renderListItem = (child, index) => {
    const { balance, firstName, id, surname } = child

    const to = generateRoute('CHILDREN.CHILD.FINANCE.TRANSACTIONS', { childId: id })

    const renderName = () => {
      if (!firstName && !surname) {
        return null
      }

      return (
        <React.Fragment>
          {firstName || ''}
          {' '}
          {surname || ''}
        </React.Fragment>
      )
    }

    return (
      <Widget.List.Row
        childList={[child]}
        index={index}
        key={id}
        leftColumn={(
          <Typography
            color={NEUTRAL_COLOURS.WHITE}
            ellipsis
          >
            {renderName()}
          </Typography>
        )}
        rightColumn={(
          <Currency value={balance ? balance * -1 : 0} />
        )}
        to={to}
        reverse
      />
    )
  }

  const renderEmptyState = (icon, text1, text2) => (
    <EmptyState
      icon={icon}
      text1={(
        <React.Fragment>
          {text1}
          <br />
          {text2}
        </React.Fragment>
      )}
      iconComponentSmallMargin
    />
  )

  const renderContent = () => {
    if (!childrenList || !childrenList.length) {
      return renderEmptyState('invoice-paid', 'Woohoo!', 'You have no overdue invoices.')
    }

    return (
      <Widget.List reverse>
        {_.map(childrenList, renderListItem)}
      </Widget.List>
    )
  }

  const renderContentHeader = () => (
    <React.Fragment>
      <StyledSummaryOutstanding>
        <Currency
          value={totalOutstanding}
        />
      </StyledSummaryOutstanding>
      <StyledTitle>
        TOP 10 OUTSTANDING BALANCES
      </StyledTitle>
    </React.Fragment>
  )

  return (
    <Widget>
      <Widget.Header>
        Total Outstanding
      </Widget.Header>
      <Widget.Content isLoading={isLoading}>
        <XMasIcon height="50px" icon="tree" marginLeft="15px" marginTop="-12px" width="36px" />
        {renderContentHeader()}
        {renderContent()}
        <XMasIcon height="50px" icon="tree" marginLeft="255px" marginTop="-12px" width="36px" />
      </Widget.Content>
    </Widget>
  )
}

export default TopOutstandingAccountsWidgetView
