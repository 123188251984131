import React from 'react'
import i18n from 'translations'

import { STRIPE_INTEGRATION_TYPE } from 'services/integrationFees/constants'

import { generateRoute } from 'utils/routing'
import { addNumberSuffix } from 'utils/data'

import { Button, DefinitionList } from 'components'

import { StyledContainer, StyledContent } from './BacsDetailsStyled'

interface ChildBacsDetailsProps {
  accountHolder?: string
  accountNumber?: string
  bankName?: string
  chargeDay?: number
  enabled?: boolean
  sortCode?: string
  type?: string
}

interface BacsDetailsProps {
  childBacsDetails: ChildBacsDetailsProps
  childId: string
}

const BacsDetails: React.FC<BacsDetailsProps> = ({ childBacsDetails, childId }) => {
  const {
    accountHolder,
    accountNumber,
    bankName,
    chargeDay,
    enabled,
    sortCode,
    type,
  } = childBacsDetails || {}
  const isStripeType = STRIPE_INTEGRATION_TYPE === type

  const renderContent = () => {
    if (enabled) {
      return (
        <DefinitionList labelWidth="200px" vertical>
          <DefinitionList.Item
            label={i18n.t('module:Children:Child:Finance:BacsDetails:accountHolder')}
            value={accountHolder}
            horizontal
          />
          <DefinitionList.Item
            label={i18n.t('module:Children:Child:Finance:BacsDetails:accountNo')}
            value={isStripeType ? `****${accountNumber}` : accountNumber}
            horizontal
          />
          <DefinitionList.Item
            label={i18n.t('module:Children:Child:Finance:BacsDetails:sortCode')}
            value={sortCode}
            horizontal
          />
          {!isStripeType && (
            <DefinitionList.Item
              label={i18n.t('module:Children:Child:Finance:BacsDetails:bankName')}
              value={bankName}
              horizontal
            />
          )}
          <DefinitionList.Item
            label={i18n.t('module:Children:Child:Finance:BacsDetails:startMonthlyDirectDebitRunOn')}
            value={addNumberSuffix(chargeDay as any)}
            horizontal
          />
        </DefinitionList>
      )
    }

    return i18n.t('module:Children:Child:Finance:BacsDetails:EmptyState:text')
  }

  return (
    <StyledContainer>
      <StyledContent>
        {renderContent()}
      </StyledContent>
      <StyledContent $flex={0}>
        <Button
          hierarchy="tertiary"
          label={i18n.t('global:Edit')}
          size="small"
          to={generateRoute('CHILDREN.CHILD.FINANCE.BACS_DETAILS', { childId })}
          negativeMargins
        />
      </StyledContent>
    </StyledContainer>
  )
}

export default BacsDetails
