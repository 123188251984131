import React from 'react'

import { generateRoute } from 'utils/routing'

import { FolderList, Page } from 'components'

import i18n from 'translations'

const ManagementParentAppView = ({
  BookingAbsenceGranted,
  ParentCommunicationGranted,
  RequestableExtraSessionsGranted,
}) => (
  <Page.Section title={i18n.t('module:Management:ParentApp:Title:long')}>
    <FolderList>
      {RequestableExtraSessionsGranted && (
        <FolderList.Item
          icon="sessions"
          title={i18n.t('module:Management:ParentApp:RequestableExtraSessions:title')}
          to={generateRoute('MANAGEMENT.PARENT_APP.REQUESTABLE_EXTRA_SESSIONS')}
        />
      )}
      {BookingAbsenceGranted && (
        <FolderList.Item
          icon="sessions"
          title={i18n.t('module:Management:ParentApp:BookingAbsence:title')}
          to={generateRoute('MANAGEMENT.PARENT_APP.BOOKING_ABSENCE')}
        />
      )}
      {ParentCommunicationGranted && (
        <FolderList.Item
          icon="messaging"
          title={i18n.t('module:Management:ParentApp:ParentCommunication:title')}
          to={generateRoute('MANAGEMENT.PARENT_APP.PARENT_COMMUNICATION')}
        />
      )}
    </FolderList>
  </Page.Section>
)

export default ManagementParentAppView
