export const GET_NURSERY_CONSUMABLE = 'GET_NURSERY_CONSUMABLE'
export const GET_NURSERY_CONSUMABLE_SUCCESS = 'GET_NURSERY_CONSUMABLE_SUCCESS'
export const GET_NURSERY_CONSUMABLE_FAILED = 'GET_NURSERY_CONSUMABLE_FAILED'

export const CREATE_NURSERY_CONSUMABLE = 'CREATE_NURSERY_CONSUMABLE'
export const CREATE_NURSERY_CONSUMABLE_SUCCESS = 'CREATE_NURSERY_CONSUMABLE_SUCCESS'
export const CREATE_NURSERY_CONSUMABLE_FAILED = 'CREATE_NURSERY_CONSUMABLE_FAILED'

export const UPDATE_NURSERY_CONSUMABLE = 'UPDATE_NURSERY_CONSUMABLE'
export const UPDATE_NURSERY_CONSUMABLE_SUCCESS = 'UPDATE_NURSERY_CONSUMABLE_SUCCESS'
export const UPDATE_NURSERY_CONSUMABLE_FAILED = 'UPDATE_NURSERY_CONSUMABLE_FAILED'

export const CLEAR_NURSERY_CONSUMABLE = 'CLEAR_NURSERY_CONSUMABLE'
