import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  GET_DISCONNECT_CONDITIONS,
  GET_DISCONNECT_CONDITIONS_FAILED,
  GET_DISCONNECT_CONDITIONS_SUCCESS,
} from './constants'

export const getDisconnectConditions = (options: BasicActionOptionsProps) => ({
  nurseryIntegrationsApiClient,
}) => generateBasicActions.list({
  constants: {
    failed: GET_DISCONNECT_CONDITIONS_FAILED,
    init: GET_DISCONNECT_CONDITIONS,
    success: GET_DISCONNECT_CONDITIONS_SUCCESS,
  },
  options,
  service: nurseryIntegrationsApiClient,
  serviceMethod: 'getDisconnectConditions',
})
