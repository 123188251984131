import _ from 'lodash'

import { mergeData } from 'utils/data'

import {
  CLEAR_LIST_AUTHENTICATION_LOGS,
  LIST_AUTHENTICATION_LOGS,
  LIST_AUTHENTICATION_LOGS_FAILED,
  LIST_AUTHENTICATION_LOGS_SUCCESS,
} from './constants'

const initialState = {
  data: [],
  error: null,
  isFetching: false,
  meta: {},
}

export default (state = initialState, { mergeResult, payload, type }) => {
  switch (type) {
    case LIST_AUTHENTICATION_LOGS:
      return { ...state, error: initialState.error, isFetching: true }
    case LIST_AUTHENTICATION_LOGS_SUCCESS: {
      const { data, meta } = payload

      return {
        ...state,
        data: _.uniqBy(mergeData(state.data, data, mergeResult), ({ id }) => id),
        isFetching: false,
        meta,
      }
    }
    case LIST_AUTHENTICATION_LOGS_FAILED:
      return { ...state, error: payload.error, isFetching: false }
    case CLEAR_LIST_AUTHENTICATION_LOGS:
      return initialState
    default:
      return state
  }
}
