import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { Callout, Spinner, Typography } from 'components'
import Settings from 'module/Settings/components'

import i18n from 'translations'

import { SettingsInvoicesItemDetailsV3ContainerFullProps } from './SettingsInvoicesItemDetailsV3Container'

interface SettingsInvoicesItemDetailsV3ViewProps extends Pick< SettingsInvoicesItemDetailsV3ContainerFullProps,
  'FinanceAutomation'
  | 'bankDetails'
  | 'errorMessages'
  | 'nurseryName'
  | 'nurseryInvoiceSettings'
  | 'organizationInvoiceSettings'
> {
  disableEdit?: boolean
  hideGlobalOption?: boolean
  isLoading?: boolean
  isUkCountry?: boolean
  nurseryId?: string
  onSubmit?: (value: any) => void
}

const SettingsInvoicesItemDetailsV3View: React.FC<SettingsInvoicesItemDetailsV3ViewProps> = ({
  FinanceAutomation,
  bankDetails,
  disableEdit,
  errorMessages,
  hideGlobalOption,
  isLoading,
  isUkCountry,
  nurseryId,
  nurseryInvoiceSettings,
  nurseryName,
  onSubmit,
  organizationInvoiceSettings,
}) => {
  if (isLoading) {
    return <Spinner />
  }

  const hideAutomationOptions = !FinanceAutomation

  const title = (
    <span>
      {i18n.t('module:Settings:Invoice:InvoiceDetails:nurseryTitle')}
      {' '}
      <Typography color={NEUTRAL_COLOURS.BLUE} fontSize="inherit" variant="span" bold>
        {nurseryName}
      </Typography>
    </span>
  )

  return (
    <div>
      <Callout content={errorMessages} error />
      <Settings.InvoiceSettingV3
        annualisedSettingsConfig={{
          editButtonLink: generateRoute('SETTINGS.INVOICES.ANNUALISED_ITEM_EDIT', { nurseryId }),
          subtitle: i18n.t('module:Management:Finance:Annualised:subtitle'),
          title: i18n.t('module:Management:Finance:Annualised:title'),
        }}
        description={i18n.t('module:Settings:Invoice:InvoiceDetails:description')}
        disableEdit={disableEdit}
        hideAutomationOptions={hideAutomationOptions}
        hideGlobalOption={hideGlobalOption}
        invoicesSettings={nurseryInvoiceSettings}
        orgInvoicesSettings={organizationInvoiceSettings}
        title={title}
        hideInvoiceNumberOptions
        onSubmit={onSubmit}
      />
      <Settings.InvoiceBankSettingView
        bankDetails={bankDetails}
        disableEdit={disableEdit}
        editLink={generateRoute('SETTINGS.INVOICES.EDIT_BANK', { nurseryId })}
        isUkCountry={isUkCountry}
      />
    </div>
  )
}

export default SettingsInvoicesItemDetailsV3View
