import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class AssessmentTrackerApiClient extends ServiceBase {
  list = (params) => this.request({
    path: `/v2/children/assessment_tracker?${getQueryStringV2(params)}`,
  })
}

export default AssessmentTrackerApiClient
