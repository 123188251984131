import { generateBasicActions } from 'utils/service'

import {
  CLEAR_NURSERY_FRAMEWORK_RELATION,
  CREATE_NURSERY_FRAMEWORK_RELATION,
  CREATE_NURSERY_FRAMEWORK_RELATION_FAILED,
  CREATE_NURSERY_FRAMEWORK_RELATION_SUCCESS,
  REMOVE_NURSERY_FRAMEWORK_RELATION,
  REMOVE_NURSERY_FRAMEWORK_RELATION_FAILED,
  REMOVE_NURSERY_FRAMEWORK_RELATION_SUCCESS,
  UPDATE_NURSERY_FRAMEWORK_RELATION,
  UPDATE_NURSERY_FRAMEWORK_RELATION_FAILED,
  UPDATE_NURSERY_FRAMEWORK_RELATION_SUCCESS,
} from './constants'

export const create = (options = {}) => ({ nurseryFrameworkRelationsApiClient }) => generateBasicActions.create({
  constants: {
    failed: CREATE_NURSERY_FRAMEWORK_RELATION_FAILED,
    init: CREATE_NURSERY_FRAMEWORK_RELATION,
    success: CREATE_NURSERY_FRAMEWORK_RELATION_SUCCESS,
  },
  options,
  service: nurseryFrameworkRelationsApiClient,
  serviceMethod: 'create',
})

export const update = (options = {}) => ({ nurseryFrameworkRelationsApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_NURSERY_FRAMEWORK_RELATION_FAILED,
    init: UPDATE_NURSERY_FRAMEWORK_RELATION,
    success: UPDATE_NURSERY_FRAMEWORK_RELATION_SUCCESS,
  },
  options,
  service: nurseryFrameworkRelationsApiClient,
  serviceMethod: 'update',
})

export const remove = (options = {}) => ({ nurseryFrameworkRelationsApiClient }) => generateBasicActions.remove({
  constants: {
    failed: REMOVE_NURSERY_FRAMEWORK_RELATION_FAILED,
    init: REMOVE_NURSERY_FRAMEWORK_RELATION,
    success: REMOVE_NURSERY_FRAMEWORK_RELATION_SUCCESS,
  },
  options,
  service: nurseryFrameworkRelationsApiClient,
  serviceMethod: 'remove',
})

export const clear = () => ({
  type: CLEAR_NURSERY_FRAMEWORK_RELATION,
})
