import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as carerChildRelationsSelectors from './selectors'

const withCarerChildRelationsService = (WrappedComponent) => {
  const mapState = ({ carerChildRelations }) => ({ carerChildRelations })

  const mapDispatch = injectActions('carerChildRelationsActions', actions)

  const Component = ({ carerChildRelations, carerChildRelationsActions, ...others }) => (
    <WrappedComponent
      carerChildRelationsActions={carerChildRelationsActions}
      carerChildRelationsSelectors={carerChildRelationsSelectors}
      carerChildRelationsSingleState={carerChildRelations.single}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withCarerChildRelationsService
