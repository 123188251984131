import styled from 'styled-components'

import { ZINDEX_ORDER } from 'constants/layout'

export const StyledBreadcrumb = styled.ul`
  display: flex;
  margin: 0;
  list-style-type: none;
  padding: 20px;
  padding-bottom: 10px;
  float: left;
  
  .dd-menu.dd-menu-left .dd-menu-items {
    margin-left: -20px;
  }
  
  .dd-menu .dd-menu-items {
    z-index: ${ZINDEX_ORDER.BASIC};
    right: auto !important;
    left: -50%;
    transform: translateX(-50%);
  }
  
  .dd-menu .dd-menu-items ul:after {
    right: auto;
    left: 126px;
  }
  
  @media(max-width: 480px) {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    text-align: center;
  }
`

export const StyledItem = styled.li`
  color: #646464;
  display: flex;
  align-items: center;
    
  &:after {
    content: '›';
    padding: 0 15px;
    
    @media(max-width: 480px) {
      transform: rotate(90deg);
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 3px;
    }
  }
  
  a {
    color: #979797;
    text-decoration: underline;
    white-space: nowrap;
    
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
  
  &:nth-last-of-type(1) {
    a {
      color: #646464;
      text-decoration: none;    
    }
  
    &:after {
      display: none;
    }
  }
  
  @media(max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
`

export const StyledButton = styled.div`
  height: 24px;
  width: 24px;
  background: #EBEBEC;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgb(0,0,0,.25);
`
