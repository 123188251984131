import React from 'react'

import { generateRoute } from 'utils/routing'

import { Callout, EmptyState, Page, Pagination, Section, Select, Spinner, Table, Toolbar } from 'components'

const ManagementFundingListV2View = ({
  errorMessages,
  fundingFilterOptions,
  isEmpty,
  isLoading,
  onPageChange,
  onSortChange,
  onStatusFilterChange,
  page,
  pageCount,
  perPage,
  sortField,
  sortOrder,
  status,
  tableData,
  totalResults,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (isEmpty) {
      return (
        <EmptyState
          icon="funding"
          text1="There are no funding types created."
        />
      )
    }

    return (
      <div>
        {renderPagination()}
        <Table
          columns={[
            {
              align: 'left',
              field: 'fundingType',
              sortKey: 'fundingType.name',
              sortable: true,
              title: 'Funding type',
            },
            {
              align: 'left',
              field: 'label',
              title: 'Unique label',
            },
            {
              field: 'period',
              sortKey: 'startDate',
              sortable: true,
              title: 'Period',
            },
            {
              field: 'hourlyRate',
              sortable: true,
              title: 'Hourly rate',
            },
            {
              field: 'totalHours',
              sortable: true,
              title: 'Total hours',
            },
            {
              field: 'total',
              sortable: true,
              title: 'Total funding value',
            },
            {
              field: 'deductedFromInvoice',
              title: 'Discount on invoices',
            },
            {
              align: 'center',
              field: 'rowToButton',
            },
          ]}
          data={tableData}
          sortField={sortField}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </div>
    )
  }

  const actions = (
    <Section.Actions primary={[{ to: generateRoute('MANAGEMENT.FINANCE_SETTINGS.FUNDING.ADD') }]} />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title="Funding"
    >
      <Callout content={errorMessages} error />
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <Select
              options={fundingFilterOptions}
              placeholder="Status"
              searchable={false}
              value={status}
              onChange={onStatusFilterChange}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      {renderContent()}
    </Page.Section>
  )
}

export default ManagementFundingListV2View
