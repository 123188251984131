import _ from 'lodash'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { Button, EmptyState, Section, Space, Spinner, StickyFooter, Table, Typography } from 'components'

import i18n from 'translations'

const TRANS_PREFIX = 'module:Children:Child:BookingPattern:AutoCreditNoting:ReAllocateFunding'

const ReAllocateFundingView = ({
  childFundingData,
  childId,
  isLoading,
  onAllocateChildFundingClick,
  onContinueClick,
}) => {
  if (isLoading) {
    return <Spinner />
  }

  const renderRow = ({
    displayName,
    fundingAllocationLabel,
    hoursAllocated,
    id: childFundingId,
    isManualType,
    period,
    totalHours,
  }) => (
    <Table.Tr>
      <Table.Td align="left">
        {displayName}
      </Table.Td>
      <Table.Td align="left">
        {period}
      </Table.Td>
      <Table.Td align="left">
        <Typography minWidth={90} variant="span">
          {isManualType
            ? `${hoursAllocated}${i18n.t('global:hourShortcut')} / ${totalHours}${i18n.t('global:hourShortcut')}`
            : `${totalHours}${i18n.t('global:hourShortcut')}`}
        </Typography>
        <Space space="5px" />
        <Typography
          align="center"
          background={NEUTRAL_COLOURS.SILVER}
          padding="3px 5px"
          variant="span"
          width={80}
        >
          {fundingAllocationLabel}
        </Typography>
      </Table.Td>
      <Table.Td align="left">
        {isManualType
          ? (
            <Button
              hierarchy="tertiary"
              label={i18n.t(`${TRANS_PREFIX}:Table:allocateButtonLabel`)}
              size="small"
              negativeMargins
              onClick={() => onAllocateChildFundingClick(childId, childFundingId)}
            />
          )
          : i18n.t(`${TRANS_PREFIX}:Table:automaticallyReallocated`)}
      </Table.Td>
    </Table.Tr>
  )

  const renderContent = () => {
    if (!childFundingData) {
      return (
        <EmptyState
          icon="funding"
          iconSize={80}
          text1={i18n.t(`${TRANS_PREFIX}:emptyStateText`)}
        />
      )
    }

    return (
      <React.Fragment>
        <Typography>
          {i18n.t(`${TRANS_PREFIX}:subtitle`)}
        </Typography>
        <Space space="10px" />
        <Table visualType="transparent">
          <Table.Tr>
            <Table.Th align="left">
              {i18n.t(`${TRANS_PREFIX}:Table:Header:fundingType`)}
            </Table.Th>
            <Table.Th align="left">
              {i18n.t(`${TRANS_PREFIX}:Table:Header:fundingPeriod`)}
            </Table.Th>
            <Table.Th align="left">
              {i18n.t(`${TRANS_PREFIX}:Table:Header:fundingAllocation`)}
            </Table.Th>
            <Table.Th />
          </Table.Tr>
          {_.map(childFundingData, renderRow)}
        </Table>
      </React.Fragment>
    )
  }

  const renderFooter = () => (
    <StickyFooter>
      <Button
        hierarchy="tertiary"
        label={i18n.t('global:Cancel')}
        to={generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS', { childId })}
        negativeMargins
      />
      <StickyFooter.Flex />
      <Button
        label={i18n.t('global:continue')}
        negativeMargins
        onClick={onContinueClick}
      />
    </StickyFooter>
  )

  return (
    <Section title={i18n.t(`${TRANS_PREFIX}:title`)}>
      {renderContent()}
      {renderFooter()}
    </Section>
  )
}

export default ReAllocateFundingView
