import React from 'react'

import {
  DatePicker,
  EmptyState,
  Page,
  Pagination,
  SearchBar,
  Section,
  Space,
  Spinner,
  Table,
  Toolbar,
} from 'components'

import i18n from 'translations'

const COLUMNS = [
  {
    align: 'left',
    field: 'viewingDate',
    sortKey: 'viewingDate',
    sortable: true,
    title: i18n.t('module:Enquiries:Viewings:Table:Header:Date'),
  },
  {
    align: 'left',
    field: 'viewingTime',
    sortKey: 'viewingDate',
    sortable: true,
    title: i18n.t('module:Enquiries:Viewings:Table:Header:Time'),
  },
  {
    align: 'left',
    field: 'contactName',
    sortKey: 'enquiry.contactName',
    sortable: true,
    title: i18n.t('module:Enquiries:Viewings:Table:Header:Contact'),
  },
  {
    align: 'left',
    field: 'childName',
    sortKey: 'enquiry.childName',
    sortable: true,
    title: i18n.t('module:Enquiries:Viewings:Table:Header:Child'),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'childAgeMonths',
    title: i18n.t('module:Enquiries:Viewings:Table:Header:ChildAge'),
  },
  {
    align: 'left',
    field: 'staffName',
    sortKey: 'assignedPerson.firstName',
    sortable: true,
    title: i18n.t('module:Enquiries:Viewings:Table:Header:AssignedPerson'),
  },
  {
    align: 'left',
    field: 'status',
    sortable: true,
    title: i18n.t('module:Enquiries:Viewings:Table:Header:Status'),
  },
  {
    field: 'edit',
  },
]

const ViewingsView = ({
  isLoading,
  onExportClick,
  onPageChange,
  onSearchChange,
  onShowAroundDateChange,
  onSortChange,
  page,
  pageCount,
  perPage,
  search,
  showAroundDate,
  sortField,
  sortOrder,
  totalResults,
  viewings,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('module:Enquiries:Viewings:viewings')}
      titleSingular={i18n.t('module:Enquiries:Viewings:viewing')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!isLoading && !viewings?.length) {
      return (
        <EmptyState
          icon="enquiry-show-around"
          text1={i18n.t('module:Enquiries:Viewings:emptyState')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={COLUMNS}
          data={viewings}
          sortField={sortField}
          sortOrder={sortOrder}
          visualType="transparent"
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const renderHeader = () => (
    <React.Fragment>
      <SearchBar
        placeholder={i18n.t('module:Enquiries:Viewings:searchPlaceholder')}
        value={search}
        variant="standard"
        noBackground
        onChange={onSearchChange}
      />
      <Space margin="0 0 10px" />
      <Toolbar>
        <Toolbar.Group />
        <Toolbar.Group>
          <Toolbar.Item>
            <DatePicker
              type="week"
              value={showAroundDate}
              range
              onChange={onShowAroundDateChange}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
    </React.Fragment>
  )

  const actions = (
    <Section.Actions
      options={[{ onClick: onExportClick, type: 'export' }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title={i18n.t('module:Enquiries:Viewings:title')}
    >
      {renderHeader()}
      {renderContent()}
    </Page.Section>
  )
}

export default ViewingsView
