import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { DAILY_DIARY_RECORD_MODAL_VARIANTS } from 'module/Modals/DailyDiaryRecordModal/DailyDiaryRecordModalContainer'
import { DRINKING_METHOD } from 'services/legacy/child/constants'
import { DAILY_DIARY_DATE_FORMAT, DAILY_DIARY_LIST_GROUPS } from 'services/legacy/dailyDiary/constants'

import { EVENTS, logEvent } from 'analytics'

import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withDailyDiaryBottlesService } from 'services/legacy/dailyDiaryBottles'
import { withModalService } from 'services/utils/modal'
import { withSnackbarService } from 'services/utils/snackbar'
import { withRegisterService } from 'services/legacy/register'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withRouter } from 'services/router'

import i18n from 'translations'

import BottlesView, { BOTTLE_RECORD_EDIT_FORM } from './BottlesView'

const LIST_LIMIT = 10

class BottlesContainer extends Component {
  constructor(props) {
    super(props)

    const {
      paginationUtils: { setPageLocationQuery },
    } = props

    setPageLocationQuery(false)
  }

  componentDidMount() {
    const { paginationUtils } = this.props
    const { setPerPage } = paginationUtils

    logEvent(EVENTS.DIARY_PAGE_VIEWED, { context: 'bottles' })

    setPerPage(LIST_LIMIT, this.fetch)
  }

  shouldComponentUpdate(nextProps) {
    const { location: { query: nextQuery } } = nextProps
    const { location: { query } } = this.props

    if (
      query.date !== nextQuery.date
      || query.room !== nextQuery.room
      || query.timeType !== nextQuery.timeType
    ) {
      this.fetch(nextQuery)
    }

    return true
  }

  componentWillUnmount() {
    const { dailyDiaryBottlesActions } = this.props

    dailyDiaryBottlesActions.clearList()
    dailyDiaryBottlesActions.clearListWithoutRecords()
  }

  fetch = (nextQuery) => {
    const {
      dailyDiaryBottlesActions,
      dailyDiaryBottlesSelectors,
      location: { query },
      paginationUtils,
      setLocationQuery,
    } = this.props
    const { page } = paginationUtils

    const { date, room, timeType } = (nextQuery || query)

    setLocationQuery({ date, room, timeType })

    dailyDiaryBottlesActions.list({
      mergeResult: 1 !== page,
      params: {
        criteria: dailyDiaryBottlesSelectors.getListCriteria({
          entryDate: date,
          recordsAndPlaceholders: true,
          room,
          timeType,
        }),
        groups: DAILY_DIARY_LIST_GROUPS,
        limit: LIST_LIMIT,
        page,
      },
    })

    if (1 === page) {
      this.fetchWithoutRecordList(nextQuery)
    }
  }

  fetchWithoutRecordList = (nextQuery) => {
    const {
      dailyDiaryBottlesActions,
      dailyDiaryBottlesSelectors,
      location: { query },
    } = this.props

    const { date } = (nextQuery || query)

    dailyDiaryBottlesActions.childrenListWithoutRecords({
      params: {
        criteria: dailyDiaryBottlesSelectors.getListCriteria({
          childrenWithoutRecords: true,
          entryDate: date,
          not: {
            drinkingMethod: DRINKING_METHOD.BOTTLE,
          },
        }),
        groups: DAILY_DIARY_LIST_GROUPS,
        limit: 0,
        page: 1,
      },
    })
  }

  handleRemoveRecordSuccess = () => {
    const { snackbarActions } = this.props

    logEvent(EVENTS.DIARY_ITEM_REMOVED, { context: 'bottles' })

    this.fetchWithoutRecordList()

    snackbarActions.show({
      message: i18n.t('module:DailyDiary:Bottles:removedSuccess'),
    })
  }

  handleUpdateRecordSuccess = (callback) => {
    const { snackbarActions } = this.props

    logEvent(EVENTS.DIARY_ITEM_UPDATED, { context: 'bottles' })

    if (callback) {
      callback()
    }

    snackbarActions.show({
      message: i18n.t('module:DailyDiary:Bottles:updatedSuccess'),
    })
  }

  handleRemoveRecordConfirmed = (bottle) => {
    const { dailyDiaryBottlesActions } = this.props

    dailyDiaryBottlesActions.removeRecord(
      bottle,
      this.handleRemoveRecordSuccess,
    )
  }

  handleRemoveRecord = (bottle) => {
    const { modalActions, modalConsts } = this.props

    logEvent(EVENTS.DIARY_REMOVE_ITEM_BTN_CLICKED, { context: 'bottles' })

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'trash',
      onConfirm: () => this.handleRemoveRecordConfirmed(bottle),
      text: i18n.t('module:DailyDiary:Bottles:confirmRemove'),
    })
  }

  handleAddRecordSuccess = ({ records }) => {
    const { dailyDiaryBottlesActions, snackbarActions } = this.props

    dailyDiaryBottlesActions.addToList(records)
    this.fetchWithoutRecordList()

    logEvent(EVENTS.DIARY_ITEM_ADDED, { context: 'bottles' })

    snackbarActions.show({
      message: i18n.t('module:DailyDiary:Bottles:addedSuccess'),
    })
  }

  handleAddRecord = (child) => {
    const {
      modalActions,
      modalConsts,
      location: {
        query: {
          date = moment().format(DAILY_DIARY_DATE_FORMAT),
          timeType,
        },
      },
    } = this.props

    logEvent(EVENTS.DIARY_ADD_ITEM_BTN_CLICKED, { context: 'bottles' })

    modalActions.show(modalConsts.TYPES.DAILY_DIARY_RECORD, {
      date,
      onSuccess: this.handleAddRecordSuccess,
      selectedChildren: child ? [child] : [],
      timeType,
      variant: DAILY_DIARY_RECORD_MODAL_VARIANTS.BOTTLE,
    })
  }

  handleUpdateRecordFailed = (response, record, callback) => {
    const { injectValidation } = this.props
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    callback?.(true)

    return injectValidation(`${BOTTLE_RECORD_EDIT_FORM}_${record.id}`, errors)
  }

  handleUpdateRecord = (record, values, callback) => {
    const { dailyDiaryBottlesActions, dailyDiaryBottlesSelectors } = this.props
    const body = dailyDiaryBottlesSelectors.getValuesForm(values)

    logEvent(EVENTS.DIARY_EDIT_ITEM_BTN_CLICKED, { context: 'bottles' })

    dailyDiaryBottlesActions.updateRecord(
      record.id,
      body,
      () => this.handleUpdateRecordSuccess(callback),
      (response) => this.handleUpdateRecordFailed(response, record, callback),
    )
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  render() {
    const {
      hasNoRecordsData,
      isFetching,
      isFetchingChildrenWithNoRecords,
      isOffline,
      isSubmitting,
      location: { query },
      paginationUtils,
      records,
      registers,
      totalResults,
    } = this.props
    const { room } = query
    const { getPageCount, page } = paginationUtils

    const pageCount = getPageCount(totalResults)

    return (
      <BottlesView
        hasNoRecordsData={hasNoRecordsData}
        isFetching={isFetching}
        isFetchingChildrenWithNoRecords={isFetchingChildrenWithNoRecords}
        isOffline={isOffline}
        isSubmitting={isSubmitting}
        page={page}
        pageCount={pageCount}
        records={records}
        registers={registers}
        room={room && JSON.parse(room)?.value}
        totalResults={totalResults}
        onAddRecord={this.handleAddRecord}
        onPageChange={this.handlePageChange}
        onRemoveRecord={this.handleRemoveRecord}
        onUpdateRecord={this.handleUpdateRecord}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  dailyDiaryBottlesListState,
  dailyDiaryBottlesListWithoutRecordsState,
  dailyDiaryBottlesSelectors,
  dailyDiaryBottlesSingleState,
  registerSelectors,
  registerState,
}) => ({
  hasNoRecordsData: dailyDiaryBottlesSelectors.hasDailyDiaryBottlesListWithoutRecordsData(state),
  isFetching: appSelectors.getIsFetching(
    registerState,
  ),
  isFetchingChildrenWithNoRecords: appSelectors.getIsFetching(
    dailyDiaryBottlesListWithoutRecordsState,
  ),
  isOffline: appSelectors.getAppIsOffline(state),
  isSubmitting: appSelectors.getIsSubmitting(
    dailyDiaryBottlesSingleState,
  ),
  records: dailyDiaryBottlesSelectors.getDailyDiaryBottlesListData(state),
  registers: registerSelectors.getRegisterDataSelector(state),
  totalResults: appSelectors.getTotalResults(dailyDiaryBottlesListState),
})

const enhance = compose(
  withAppService,
  withDailyDiaryBottlesService,
  withModalService,
  withPaginationUtils,
  withRegisterService,
  withRouter,
  withRouterUtils,
  withSnackbarService,
  connect(mapState),
)

export default enhance(BottlesContainer)
