import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getFormValues, startSubmit, stopSubmit } from 'redux-form'

import { ROOM_TYPES } from 'services/legacy/rooms/constants'
import { FEATURE_FLAGS } from 'constants/security'

import auth from 'utils/auth'
import { getBackendErrors } from 'utils/backendErrors'
import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withRoomsService } from 'services/legacy/rooms'
import { withRouter } from 'services/router'

import i18n from 'translations'

import RoomsAddView from './RoomsAddView'
import { ROOM_FORM } from './components/RoomsAddForm'

const GROUPS = {
  read: ['staffRequirement'],
}

class RoomsAddContainer extends Component {
  componentDidMount() {
    const { isEdit, params, roomsActions } = this.props

    if (isEdit) {
      roomsActions.get({
        params: [params.id, { groups: GROUPS }],
      })
    }
  }

  componentWillUnmount() {
    const { roomsActions } = this.props

    roomsActions.clearSingle()
  }

  handleSubmitSuccess = () => {
    const { navigate } = this.props

    navigate(generateRoute('ROOMS.INDEX'))
  }

  handleSubmitFailed = (response = {}) => {
    const { injectValidation, startSubmitForm } = this.props
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    startSubmitForm(ROOM_FORM)
    return setTimeout(() => injectValidation(ROOM_FORM, errors))
  }

  handleSubmit = (fields) => {
    const {
      authAccessMap: { section: { PremiumSection, StaffingEnabled } },
      isEdit,
      modalActions,
      modalConsts,
      params,
      roomData,
      roomsActions,
      roomsSelectors,
    } = this.props
    const currentFormValues = roomsSelectors.getValuesForm(fields, isEdit)

    const handleUpdateRoom = () => roomsActions.update({
      onFailed: this.handleSubmitFailed,
      onSuccess: this.handleSubmitSuccess,
      params: [params.id, {
        ...currentFormValues,
      }],
    })

    if (isEdit) {
      if (PremiumSection
        && ((
          roomData.startAgeInMonths !== undefined
          && roomData.startAgeInMonths !== currentFormValues.startAgeInMonths
        )
        || (
          roomData.endAgeInMonths !== undefined
          && roomData.endAgeInMonths !== currentFormValues.endAgeInMonths
        ))
      ) {
        return modalActions.show(modalConsts.TYPES.CONFIRM, {
          confirmButtonLabel: i18n.t('global:Confirm'),
          icon: 'edit',
          onConfirm: handleUpdateRoom,
          text: i18n.t('module:Rooms:RoomsAdd:Confirm:changeAgeBand'),
        })
      }

      return handleUpdateRoom()
    }

    // NOTE: TO BE REMOVED AFTER STAFFING ENABLED TO EVERYONE
    const payload = StaffingEnabled ? {
      ...currentFormValues,
      ratio: 0,
    } : {
      ...currentFormValues,
      type: ROOM_TYPES.TEACHING,
    }

    return roomsActions.create({
      onFailed: this.handleSubmitFailed,
      onSuccess: this.handleSubmitSuccess,
      params: [payload],
    })
  }

  archiveRoom = (archived) => {
    const { params, roomsActions } = this.props

    return roomsActions.archive({
      onFailed: this.handleSubmitFailed,
      onSuccess: this.handleSubmitSuccess,
      params: [params.id, {
        archived,
      }],
    })
  }

  getArchiveModalLabel = (action) => {
    const { authAccessMap: { section: { PremiumSection } } } = this.props

    if (PremiumSection) {
      return i18n.t('module:Rooms:RoomsAdd:Confirm:archiveForOccupancyFlag', { action })
    }

    return i18n.t('module:Rooms:RoomsAdd:Confirm:archive', { action })
  }

  handleArchiveClick = (archived) => () => {
    const { modalActions, modalConsts } = this.props
    const action = archived ? i18n.t('global:archive') : i18n.t('global:unarchive')

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('global:Continue'),
      icon: action,
      onConfirm: () => this.archiveRoom(archived),
      text: this.getArchiveModalLabel(action),
    })
  }

  render() {
    const {
      authAccessMap: { section: { PremiumSection, StaffingEnabled } },
      errorMessages,
      formValues,
      initialValues,
      isEdit,
      isFetching,
      isSubmitting,
      roomData,
    } = this.props

    const isArchived = roomData?.archived

    return (
      <RoomsAddView
        errorMessages={errorMessages}
        formValues={formValues}
        initialValues={initialValues}
        isArchived={isArchived}
        isEdit={isEdit}
        isFormLoading={isSubmitting}
        isLoading={isFetching}
        isStaffingEnabled={StaffingEnabled}
        permissionToPremiumSection={PremiumSection}
        onArchiveClick={this.handleArchiveClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, { appSelectors, params, roomsSelectors, roomsSingleState }) => ({
  authAccessMap: {
    section: {
      PremiumSection: auth.SELECTORS.getIsAuthorised(state, {
        flags: [FEATURE_FLAGS.OCCUPANCY_ENABLED],
      }),
      StaffingEnabled: auth.SELECTORS.getIsAuthorised(state, {
        flags: [FEATURE_FLAGS.STAFF_REGISTER],
      }),
    },
  },
  errorMessages: appSelectors.getErrorMessages(roomsSingleState),
  formValues: getFormValues(ROOM_FORM)(state),
  initialValues: roomsSelectors.getInitialValuesSelector(state),
  isEdit: !!params.id,
  isFetching: appSelectors.getIsFetching(roomsSingleState),
  isSubmitting: appSelectors.getIsSubmitting(roomsSingleState),
  roomData: roomsSelectors.getRoomsSingleDataSelector(state),
})

const mapDispatch = {
  injectValidation: (formName, data) => stopSubmit(formName, data),
  startSubmitForm: (formName) => startSubmit(formName),
}

const enhance = compose(
  withAppService,
  withModalService,
  withRoomsService,
  withRouter,
  connect(mapState, mapDispatch),
)

export default enhance(RoomsAddContainer)
