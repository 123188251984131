import _ from 'lodash'

import { ACTION_TYPE, MENU_ITEMS_CATEGORY_PLURAL } from 'services/legacy/foodTypes/constants'

import { generateRoute } from 'utils/routing'

import i18n from 'translations'

export const getTransPrefix = (isMealsContext) => (isMealsContext
  ? 'module:Management:MenuItems:Meals:'
  : 'module:Management:MenuItems:Snacks:')

export const getIsMealsContext = (pathname) => pathname.startsWith(generateRoute('MANAGEMENT.MENU_ITEMS.MEALS'))

export const getRoute = ({ action, id, isMealsContext }) => {
  const menuItemType = isMealsContext
    ? MENU_ITEMS_CATEGORY_PLURAL.MEALS
    : MENU_ITEMS_CATEGORY_PLURAL.SNACKS

  if (ACTION_TYPE.ADD === action) {
    return generateRoute(`MANAGEMENT.MENU_ITEMS.${menuItemType}.ADD`)
  }

  if (ACTION_TYPE.EDIT === action) {
    return generateRoute(`MANAGEMENT.MENU_ITEMS.${menuItemType}.EDIT`, { id })
  }

  return generateRoute(`MANAGEMENT.MENU_ITEMS.${menuItemType}`)
}

const handleArchiveMenuItemsSuccess = ({
  archive,
  isSingleRecord,
  onSuccess,
  prefix,
  snackbarActions,
}) => {
  const action = archive ? i18n.t('global:archived') : i18n.t('global:unarchived')
  const message = isSingleRecord
    ? i18n.t(prefix.concat('Snackbar:singular'), { action })
    : i18n.t(prefix.concat('Snackbar:plural'), { action })

  onSuccess()

  snackbarActions.show({ message })
}

const handleArchiveMenuItems = ({
  archive,
  foodTypesActions,
  isSingleRecord,
  onSuccess,
  prefix,
  selected,
  snackbarActions,
}) => {
  if (isSingleRecord) {
    const id = selected[0]
    const body = { archived: archive }

    return foodTypesActions.update({
      body,
      onSuccess: () => handleArchiveMenuItemsSuccess({
        archive,
        isSingleRecord,
        onSuccess,
        prefix,
        snackbarActions,
      }),
      params: [id, {}],
    })
  }

  const body = _.map(selected, (id) => ({
    archived: archive,
    id,
  }))

  return foodTypesActions.updateBatch({
    body,
    onSuccess: () => handleArchiveMenuItemsSuccess({
      archive,
      isSingleRecord,
      onSuccess,
      prefix,
      snackbarActions,
    }),
    params: [{}],
  })
}

export const handleArchiveClick = ({
  foodTypesActions,
  isArchived,
  isSingleRecord,
  modalActions,
  modalConsts,
  name,
  onSuccess,
  params,
  prefix,
  selected,
  snackbarActions,
}) => {
  const confirmButtonLabel = isArchived ? i18n.t('global:Unarchive') : i18n.t('global:Archive')
  const icon = isArchived ? 'unarchive' : 'archive'

  const action = isArchived ? i18n.t('global:unarchived') : i18n.t('global:archived')
  const singularText = i18n.t(prefix.concat('Modal:singular'), { action, name })
  const pluralText = i18n.t(prefix.concat('Modal:plural'), { action })
  const pastEntriesText = i18n.t(prefix.concat('Modal:pastEntries'))
  const archiveText = isSingleRecord
    ? singularText.concat(' ', pastEntriesText)
    : pluralText.concat(' ', pastEntriesText)
  const unarchiveText = isSingleRecord
    ? singularText
    : pluralText
  const text = isArchived ? unarchiveText : archiveText

  modalActions.show(modalConsts.TYPES.CONFIRM, {
    confirmButtonLabel,
    icon,
    onConfirm: () => handleArchiveMenuItems({
      archive: !isArchived,
      foodTypesActions,
      isSingleRecord,
      onSuccess,
      params,
      prefix,
      selected,
      snackbarActions,
    }),
    text,
  })
}
