import React from 'react'
import { connect } from 'react-redux'

import { RootState } from 'core/reducers'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as snackbarSelectors from './selectors'
import { SnackbarState } from './reducer'

export interface withSnackbarServiceProps {
  snackbarActions: typeof actions
  snackbarSelectors: typeof snackbarSelectors
  snackbarState: SnackbarState
}

const withSnackbarService = (WrappedComponent) => {
  const mapState = ({ snackbar }: RootState) => ({ snackbar })

  const mapDispatch = injectActions('snackbarActions', actions)

  const Component = ({ snackbar, snackbarActions, ...others }) => (
    <WrappedComponent
      snackbarActions={snackbarActions}
      snackbarSelectors={snackbarSelectors}
      snackbarState={snackbar}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withSnackbarService
