import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  ARCHIVE_NURSERY_FUNDING,
  ARCHIVE_NURSERY_FUNDING_FAILED,
  ARCHIVE_NURSERY_FUNDING_SUCCESS,
  CLEAR_NURSERY_FUNDING,
  CREATE_NURSERY_FUNDING,
  CREATE_NURSERY_FUNDING_FAILED,
  CREATE_NURSERY_FUNDING_SUCCESS,
  GET_NURSERY_FUNDING,
  GET_NURSERY_FUNDING_FAILED,
  GET_NURSERY_FUNDING_SUCCESS,
  UPDATE_NURSERY_FUNDING,
  UPDATE_NURSERY_FUNDING_FAILED,
  UPDATE_NURSERY_FUNDING_SUCCESS,
} from './constants'

export const get = (options: BasicActionOptionsProps) => ({ nurseryFundingApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: GET_NURSERY_FUNDING_FAILED,
      init: GET_NURSERY_FUNDING,
      success: GET_NURSERY_FUNDING_SUCCESS,
    },
    options,
    service: nurseryFundingApiClient,
    serviceMethod: 'get',
  })
)

export const create = (options: BasicActionOptionsProps) => ({ nurseryFundingApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: CREATE_NURSERY_FUNDING_FAILED,
      init: CREATE_NURSERY_FUNDING,
      success: CREATE_NURSERY_FUNDING_SUCCESS,
    },
    options,
    service: nurseryFundingApiClient,
    serviceMethod: 'create',
  })
)

export const update = (options: BasicActionOptionsProps) => ({ nurseryFundingApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_NURSERY_FUNDING_FAILED,
      init: UPDATE_NURSERY_FUNDING,
      success: UPDATE_NURSERY_FUNDING_SUCCESS,
    },
    options,
    service: nurseryFundingApiClient,
    serviceMethod: 'update',
  })
)

export const archive = (options: BasicActionOptionsProps) => ({ nurseryFundingApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: ARCHIVE_NURSERY_FUNDING_FAILED,
      init: ARCHIVE_NURSERY_FUNDING,
      success: ARCHIVE_NURSERY_FUNDING_SUCCESS,
    },
    options,
    service: nurseryFundingApiClient,
    serviceMethod: 'update',
  })
)

export const clear = () => ({
  type: CLEAR_NURSERY_FUNDING,
})
