import _ from 'lodash'
import moment from 'moment'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { WORK_SHIFT_TYPES } from 'services/legacy/membershipRegisters/constants'

import { convertTimeDuration, millisecondsToHoursAndMinutesString, removeDate } from 'utils/date'

import {
  getBreakHoursTotalForPeriod,
  getContractedHoursTotalForPeriod,
} from 'services/legacy/membershipRegisters/single/selectors'
import { isShiftBreak } from 'module/Staff/StaffHelpers'

import i18n from 'translations'

import { SHIFT_MODAL_MODE } from '../../ShiftModalContainer'

export const getWorkingHours = (currentContract, contractChanges, formValues, mode) => {
  if (mode === SHIFT_MODAL_MODE.ACTUAL) {
    let summaryTimes = 0
    const { membershipShiftTimes = {} } = formValues || {}

    _.each(membershipShiftTimes, (shifts) => {
      _.each(shifts, (shift = {}) => {
        const finalShiftType = shift.type?.value || shift.type
        let result = 0

        if (shift.endDateInFuture) {
          const finalStartTime = moment(shift.date)
            .set('hours', moment(shift.startTime).format('HH'))
            .set('minutes', moment(shift.startTime).format('mm'))
            .set('seconds', moment(shift.startTime).format('ss'))

          result = moment(shift.endTime).set('seconds', 0).diff(finalStartTime)
        } else if (shift.startTime && shift.endTime) {
          const finalEndTime = moment(0)
            .set('hours', moment(shift.endTime).format('HH'))
            .set('minutes', moment(shift.endTime).format('mm'))

          result = moment(removeDate(finalEndTime).set('seconds', 0))
            .diff(moment(removeDate(shift.startTime)).set('seconds', 0))
        }

        if (!isShiftBreak(finalShiftType)) {
          summaryTimes += result
        }
      })
    })

    return (
      summaryTimes ? millisecondsToHoursAndMinutesString(summaryTimes) : `0${i18n.t('global:hourShortcut')}`
    )
  }

  let totalWorks = 0
  const { membershipShiftTimes } = formValues || {}

  _.each(membershipShiftTimes, (dayItems) => {
    _.each(dayItems, ({ duration, endTime, startTime, type }) => {
      const finalType = type?.value

      if (
        (WORK_SHIFT_TYPES.CONTRACTED === finalType || WORK_SHIFT_TYPES.OVERTIME === finalType)
        && startTime && endTime
      ) {
        totalWorks += moment(moment(endTime).set('seconds', 0)).diff(moment(startTime).set('seconds', 0))
      }

      if (WORK_SHIFT_TYPES.BREAK === finalType && startTime && endTime) {
        totalWorks -= moment(moment(endTime).set('seconds', 0)).diff(moment(startTime).set('seconds', 0))
      }

      if (WORK_SHIFT_TYPES.BREAK_DURATION === finalType && duration) {
        totalWorks -= convertTimeDuration(+duration)
      }
    })
  })

  let result = totalWorks && 0 < totalWorks
    ? millisecondsToHoursAndMinutesString(totalWorks)
    : `0${i18n.t('global:hourShortcut')}`

  if (!contractChanges && currentContract) {
    const contractedHours = (
      currentContract?.contractedHours
        ? millisecondsToHoursAndMinutesString(currentContract?.contractedHours)
        : `0${i18n.t('global:hourShortcut')}`
    )

    result = `${result} / ${contractedHours}`
  }

  return result
}

export const getAvailableBreakAllowances = ({ contracts, date, formValues }) => {
  const { membershipShiftTimes = {} } = formValues || {}

  const contract = _.find(contracts, ({ endDate, ongoing, startDate }) => (
    date >= moment(startDate).format(DEFAULT_DATE_FORMAT)
      && (
        ongoing || date <= moment(endDate).format(DEFAULT_DATE_FORMAT)
      )
  ))

  const { breakAllowances } = contract || {}

  const contractedWorkSum = getContractedHoursTotalForPeriod({
    endDate: date,
    membershipShiftTimes,
    startDate: date,
  })

  if (!contractedWorkSum) {
    return null
  }

  const isStartTimeSameAsEndTime = (startTime) => _.some(breakAllowances, ['endTime', startTime])

  return _.filter(breakAllowances, ({ endTime, startTime }) => {
    const foundLinkedRecord = isStartTimeSameAsEndTime(startTime)

    if (foundLinkedRecord) {
      if (contractedWorkSum === endTime) {
        return true
      }

      return contractedWorkSum > startTime && contractedWorkSum < endTime
    }

    return contractedWorkSum >= startTime && contractedWorkSum <= endTime
  })
}

export const getBreakBanners = ({ contracts, date, formValues }) => {
  if (!contracts?.length) {
    return null
  }

  const { membershipShiftTimes = {} } = formValues || {}

  const availableBreakAllowances = getAvailableBreakAllowances({ contracts, date, formValues })

  const breakSum = getBreakHoursTotalForPeriod({
    endDate: date,
    membershipShiftTimes,
    startDate: date,
  })

  const availableBreakAllowancesSum = _.sum(_.map(availableBreakAllowances, ({ time }) => +time))

  return availableBreakAllowancesSum && breakSum !== availableBreakAllowancesSum
    ? availableBreakAllowances
    : null
}
