import _ from 'lodash'
import moment from 'moment'

import React, { useMemo } from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { WORK_SHIFT_TYPES } from 'services/legacy/membershipRegisters/constants'
import { RATIO_OPTIONS } from 'services/legacy/membershipsShifts/constants'

import { convertTimeDuration, millisecondsToHoursAndMinutesString } from 'utils/date'

import { isShiftBreak } from 'module/Staff/StaffHelpers'

import { Space, Typography } from 'components'

import i18n from 'translations'

import { SHIFT_MODAL_MODE } from '../../ShiftModalContainer'
import {
  StyledDay,
  StyledRenderStatisticsGrid,
  StyledRowExtraWrapper,
  StyledStatisticRow,
} from './ShiftModalFormStyled'

const ShiftModalFormStatistics = ({
  dayStatistics,
  mode,
}) => {
  const formStatistics = useMemo(() => {
    let totalWorks = 0

    _.each(dayStatistics, ({ date, duration, endDateInFuture, endTime, startTime, type }) => {
      if (!duration && (!startTime || !endTime)) {
        return
      }

      const finalType = type?.value || type

      if (endDateInFuture
      && mode === SHIFT_MODAL_MODE.PLANNED
      && !isShiftBreak(finalType)) {
        const finalStartTime = moment(date)
          .set('hours', moment(startTime).format('HH'))
          .set('minutes', moment(startTime).format('mm'))
          .set('seconds', moment(startTime).format('ss'))

        const result = moment(endTime).diff(finalStartTime)

        if (finalType !== WORK_SHIFT_TYPES.BREAK) {
          totalWorks += result

          return
        }
      }

      if (mode === SHIFT_MODAL_MODE.ACTUAL
        && isShiftBreak(finalType)
      ) {
        if (WORK_SHIFT_TYPES.BREAK_DURATION === finalType) {
          totalWorks -= convertTimeDuration(duration)

          return
        }

        totalWorks -= moment(endTime).diff(moment(startTime))

        return
      }

      if (
        mode === SHIFT_MODAL_MODE.ACTUAL
          || (mode === SHIFT_MODAL_MODE.PLANNED
            && !isShiftBreak(finalType)
          )
      ) {
        totalWorks += moment(endTime).diff(moment(startTime))
      }
    })

    return {
      totalWorks,
    }
  }, [dayStatistics, mode])

  return (
    <StyledRowExtraWrapper>
      <StyledDay noBorder />
      <StyledDay noPadding>
        <Typography color={NEUTRAL_COLOURS.GRAY} margin="8px 8px 2px" bold>
          {mode === SHIFT_MODAL_MODE.PLANNED && i18n.t('module:Modals:Staff:Shift:actual')}
          {mode === SHIFT_MODAL_MODE.ACTUAL && i18n.t('module:Modals:Staff:Shift:planned')}
        </Typography>
        {!dayStatistics?.length ? (
          <Typography color={NEUTRAL_COLOURS.GRAY} margin="8px 8px 0">
            {mode === SHIFT_MODAL_MODE.PLANNED && i18n.t('module:Modals:Staff:Shift:noTimeLogged')}
            {mode === SHIFT_MODAL_MODE.ACTUAL && i18n.t('module:Modals:Staff:Shift:noShiftsPlanned')}
          </Typography>
        ) : null}
        {_.map(dayStatistics, ({ duration, endTime, id, location, ratio, startTime, type }, index) => (
          <StyledRenderStatisticsGrid key={`statistic_${id}`} mode={mode}>
            <StyledStatisticRow>
              {mode === SHIFT_MODAL_MODE.ACTUAL && type?.label}
              {mode === SHIFT_MODAL_MODE.PLANNED && i18n.t(`module:Modals:Staff:Shift:actualTypes:${type}`)}
            </StyledStatisticRow>
            <StyledStatisticRow>
              {location?.label || ''}
            </StyledStatisticRow>
            {type?.value === WORK_SHIFT_TYPES.BREAK_DURATION
              ? (
                <StyledStatisticRow colspan={2}>
                  {duration}
                  {' '}
                  {i18n.t('global:min')}
                </StyledStatisticRow>
              )
              : (
                <React.Fragment>
                  <StyledStatisticRow>
                    {moment(startTime).format('HH:mm')}
                  </StyledStatisticRow>
                  <StyledStatisticRow>
                    {endTime ? moment(endTime).format('HH:mm') : '...'}
                  </StyledStatisticRow>
                </React.Fragment>
              )}
            {mode === SHIFT_MODAL_MODE.ACTUAL && (
              <StyledStatisticRow>
                {_.get(_.find(RATIO_OPTIONS, ({ value }) => +ratio === value), 'label', '')}
              </StyledStatisticRow>
            )}
            <StyledStatisticRow>
              {0 === index ? (
                <Typography align="right" bold>
                  {millisecondsToHoursAndMinutesString(formStatistics.totalWorks)}
                </Typography>
              ) : ' '}
            </StyledStatisticRow>
          </StyledRenderStatisticsGrid>
        ))}
        <Space space="5px" />
      </StyledDay>
    </StyledRowExtraWrapper>
  )
}

export default ShiftModalFormStatistics
