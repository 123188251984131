export const GET_NURSERY_CONSUMABLE = 'BOOKING/GET_NURSERY_CONSUMABLE'
export const GET_NURSERY_CONSUMABLE_SUCCESS = 'BOOKING/GET_NURSERY_CONSUMABLE_SUCCESS'
export const GET_NURSERY_CONSUMABLE_FAILED = 'BOOKING/GET_NURSERY_CONSUMABLE_FAILED'

export const CREATE_NURSERY_CONSUMABLE = 'BOOKING/CREATE_NURSERY_CONSUMABLE'
export const CREATE_NURSERY_CONSUMABLE_SUCCESS = 'BOOKING/CREATE_NURSERY_CONSUMABLE_SUCCESS'
export const CREATE_NURSERY_CONSUMABLE_FAILED = 'BOOKING/CREATE_NURSERY_CONSUMABLE_FAILED'

export const UPDATE_NURSERY_CONSUMABLE = 'BOOKING/UPDATE_NURSERY_CONSUMABLE'
export const UPDATE_NURSERY_CONSUMABLE_SUCCESS = 'BOOKING/UPDATE_NURSERY_CONSUMABLE_SUCCESS'
export const UPDATE_NURSERY_CONSUMABLE_FAILED = 'BOOKING/UPDATE_NURSERY_CONSUMABLE_FAILED'

export const CLEAR_NURSERY_CONSUMABLE = 'BOOKING/CLEAR_NURSERY_CONSUMABLE'
