import React from 'react'
import { Field } from 'redux-form'

import { Form, Section, Space } from 'components'

const Comments = ({ isGrantedToEditFormativeReport, onFormCommentsBlur, onFormCommentsChange }) => (
  <React.Fragment>
    <Section
      title="Child's voice"
      variant="small"
      noBorder
      noMobileVersion
    >
      <Field
        component={Form.TextAreaField}
        disabled={!isGrantedToEditFormativeReport}
        name="childVoice"
        placeholder="Enter comment"
        onBlur={onFormCommentsBlur}
        onChange={onFormCommentsChange}
      />
    </Section>
    <Space space="20px" />
    <Section
      title="Key Person Comment"
      variant="small"
      noBorder
      noMobileVersion
    >
      <Field
        component={Form.TextAreaField}
        disabled={!isGrantedToEditFormativeReport}
        name="nurseryComments"
        placeholder="Enter comment"
        onBlur={onFormCommentsBlur}
        onChange={onFormCommentsChange}
      />
    </Section>
  </React.Fragment>
)

export default Comments
