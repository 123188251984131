import React from 'react'

import { Callout, Section, Spinner } from 'components'

import i18n from 'translations'

import ChildFinanceInvoiceForm from './components/ChildFinanceInvoiceForm'

const ChildFinanceInvoiceView = ({
  authAccessMap,
  child,
  displayAutoCalculate,
  errorMessages,
  initialValues,
  invoiceId,
  invoicePeriodOptions,
  invoicePreview,
  isDraft,
  isEdit,
  isInsideModal,
  isLoading,
  isSubmitting,
  onAddLineItemClick,
  onAutoCalculateClick,
  onDeleteClick,
  onDownloadButtonClick,
  onEditLineItemClick,
  onIssueDateChange,
  onMarkAsSentClick,
  onModalCancelClick,
  onRemoveLineItemClick,
  onSendButtonClick,
  onSubmit,
  onSubmitForm,
  repeatInvoiceId,
}) => {
  if (isLoading) {
    return <Spinner />
  }

  const title = isEdit
    ? i18n.t('module:Children:Child:Finance:Invoice:Add:editTitle')
    : i18n.t('module:Children:Child:Finance:Invoice:Add:addTitle')

  const actions = (
    <Section.Actions
      options={!isInsideModal ? [{
        auth: isDraft || !isEdit,
        icon: 'mark-as-sent',
        label: i18n.t('global:MarkAsSent'),
        onClick: onMarkAsSentClick,
      }, {
        onClick: onDownloadButtonClick,
        type: 'download',
      }, {
        auth: isEdit,
        onClick: onDeleteClick,
        type: 'delete',
      }] : []}
      secondary={{
        disabled: !(invoicePreview?.items?.length),
        isLoading: isSubmitting,
        label: i18n.t('global:SaveDraft'),
        onClick: onSubmitForm,
      }}
    />
  )

  const renderForm = () => (
    <ChildFinanceInvoiceForm
      authAccessMap={authAccessMap}
      child={child}
      displayAutoCalculate={displayAutoCalculate}
      initialValues={initialValues}
      invoiceId={invoiceId}
      invoicePeriodOptions={invoicePeriodOptions}
      invoicePreview={invoicePreview}
      isDraft={isDraft}
      isEdit={isEdit}
      isInsideModal={isInsideModal}
      isLoading={isSubmitting}
      repeatInvoiceId={repeatInvoiceId}
      onAddLineItemClick={onAddLineItemClick}
      onAutoCalculateClick={onAutoCalculateClick}
      onEditLineItemClick={onEditLineItemClick}
      onIssueDateChange={onIssueDateChange}
      onModalCancelClick={onModalCancelClick}
      onRemoveLineItemClick={onRemoveLineItemClick}
      onSendButtonClick={onSendButtonClick}
      onSubmit={onSubmit}
      onSubmitForm={onSubmitForm}
    />
  )

  if (isInsideModal) {
    return (
      <React.Fragment>
        <Callout content={errorMessages} error />
        {renderForm()}
      </React.Fragment>
    )
  }

  return (
    <div>
      <Callout content={errorMessages} error />
      <Section actions={actions} title={title}>
        {renderForm()}
      </Section>
    </div>
  )
}

export default ChildFinanceInvoiceView
