import React, { PropsWithChildren } from 'react'

import { CursorTypes } from 'constants/css'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

import { Icon } from 'components'

import { StyledContainer, StyledIconContainer, StyledLabel, StyledLetter } from './CircleIconStyled'

interface CircleIconProps {
  background?: string
  center?: boolean
  color?: string
  cursor?: CursorTypes
  icon?: IconType
  iconSize?: number
  label?: string
  letter?: string
  roundedSquare?: boolean
  secondary?: boolean
  size?: number
}

const CircleIcon: React.FC<PropsWithChildren<CircleIconProps>> = ({
  background = getBrandingColor('primary-color'),
  center,
  color = NEUTRAL_COLOURS.WHITE,
  cursor,
  icon,
  iconSize,
  label,
  letter,
  roundedSquare,
  size = 40,
  secondary,
}) => {
  const getIconHeight = () => {
    const height = size * 0.5

    return 2 * Math.round(height / 2)
  }

  const renderIcon = () => {
    const iconHeight = iconSize || getIconHeight()

    return (
      <Icon
        color={color}
        height={iconHeight}
        icon={icon}
      />
    )
  }

  return (
    <StyledContainer
      $center={center}
      $cursor={cursor}
    >
      <StyledIconContainer
        $background={background}
        $roundedSquare={roundedSquare}
        $secondary={secondary}
        $size={size}
      >
        {renderIcon()}
        {letter && (
          <StyledLetter $color={color}>
            {letter}
          </StyledLetter>
        )}
      </StyledIconContainer>
      {label && (
        <StyledLabel>
          {label}
        </StyledLabel>
      )}
    </StyledContainer>
  )
}

export default CircleIcon
