import _ from 'lodash'

import React, { useState } from 'react'

import { Hyperlink, Space, Typography } from 'components'

import i18n from 'translations'

import { StyledWhiteBar } from './ActivityNoteStyled'

const MAX_CHARACTERS = 300

const ActivityNote = ({ color, text }) => {
  const [isPartiallyHidden, setPartiallyHidden] = useState(MAX_CHARACTERS < text?.length)

  if (!text) {
    return null
  }

  const renderNote = () => (isPartiallyHidden ? (
    <React.Fragment>
      <Typography color={color} wordBreak="break-all" prewrap>
        {_.truncate(text, { length: MAX_CHARACTERS })}
      </Typography>
      <StyledWhiteBar />
    </React.Fragment>
  ) : (
    <Typography color={color} wordBreak="break-all" prewrap>
      {text}
    </Typography>
  ))

  const renderSeeMoreButton = () => MAX_CHARACTERS < text?.length && (
    <React.Fragment>
      <Space space={isPartiallyHidden ? '-6px' : '16px'} />
      <Hyperlink
        bold
        inline
        primary
        onClick={() => setPartiallyHidden((oldPartiallyHidden) => !oldPartiallyHidden)}
      >
        {isPartiallyHidden
          ? i18n.t('module:Enquiries:List:Detail:Activity:seeMore')
          : i18n.t('module:Enquiries:List:Detail:Activity:seeLess')}
      </Hyperlink>
    </React.Fragment>
  )

  return (
    <div>
      {renderNote()}
      {renderSeeMoreButton()}
    </div>
  )
}

export default ActivityNote
