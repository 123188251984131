import { nest } from 'utils/flatnest'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { getFormValues, stopSubmit, submit } from 'redux-form'

import constants from 'services/nurseries/constants'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withSnackbarService } from 'services/utils/snackbar'

import { copyToClipboard } from 'utils/dom'

import i18n from 'translations'

import FormView, { ENQUIRY_SETTINGS_FORM } from './FormView'

export const NURSERY_GROUPS = {
  read: [
    'nursery.enquiryToken',
    'nursery.settings',
    'nurserySettings',
    'nurserySettings.enquiry',
    'nurseryEnquirySettings',
  ],
}
class FormContainer extends Component {
  componentDidMount() {
    const { nurseriesActions, nurseryOptions: { id } } = this.props

    const nurseryApiParams = { groups: NURSERY_GROUPS }

    nurseriesActions.get(id, {
      params: nurseryApiParams,
    })
  }

  componentWillUnmount() {
    const { nurseriesActions } = this.props

    nurseriesActions.clearSingle()
  }

  handleCopyToClipboard = (link) => {
    const { snackbarActions } = this.props

    copyToClipboard(link)

    snackbarActions.show({
      message: i18n.t('global:clipboardCopy'),
    })
  }

  handleFailedUpdate = (error) => {
    const { injectValidation } = this.props

    const { extra } = error || {}
    const nestErrors = nest(extra)

    const redirectUrlErrorMessage = nestErrors?.nurserySettings?.enquiry?.redirectUrl
    const customThankYouPageErrorMessage = nestErrors?.nurserySettings?.enquiry?.customThankYouPage

    if (redirectUrlErrorMessage || customThankYouPageErrorMessage) {
      setTimeout(() => {
        injectValidation(ENQUIRY_SETTINGS_FORM, {
          customThankYouPage: customThankYouPageErrorMessage,
          redirectUrl: redirectUrlErrorMessage,
        })
      })
    }
  }

  handleRedirectLinkBlur = () => {
    setTimeout(() => {
      const { submitForm } = this.props

      submitForm()
    })
  }

  handleSubmit = (values) => {
    const { customThankYouPage, redirectUrl, thankYouPageOption } = values
    const {
      nurseriesActions,
      nurseriesSelectors,
      nurseryOptions: { id },
      nurserySettings,
    } = this.props

    const useCustomThankYouPage = thankYouPageOption === constants.FORM_THANKYOU_PAGE_TYPE.CUSTOM
    const payload = nurseriesSelectors.getEnquiryPayload({
      nurserySettings,
      payload: {
        customThankYouPage: useCustomThankYouPage ? customThankYouPage : undefined,
        redirectUrl,
        useCustomThankYouPage,
      },
    })

    nurseriesActions.update(id, {
      onFailed: this.handleFailedUpdate,
      params: { groups: NURSERY_GROUPS },
      payload,
    })
  }

  render() {
    const {
      formValues,
      initialValues,
      isFetching,
      isSubmitting,
      nurseryEnquiryToken,
      nurseryPublicUrl,
    } = this.props

    const formLink = `${nurseryPublicUrl}/enquiry-form?t=${nurseryEnquiryToken}`

    return (
      <FormView
        formLink={formLink}
        formValues={formValues}
        initialValues={initialValues}
        isLoading={isFetching}
        isSubmitting={isSubmitting}
        onCopyToClipboard={this.handleCopyToClipboard}
        onRedirectLinkBlur={this.handleRedirectLinkBlur}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  injectValidation: (formName, data) => stopSubmit(formName, data),
  submitForm: () => submit(ENQUIRY_SETTINGS_FORM),
}

const mapState = (state, {
  appSelectors,
  nurseriesSelectors,
  nurseriesSingleState,
  params,
}) => ({
  formValues: getFormValues(ENQUIRY_SETTINGS_FORM)(state),
  initialValues: nurseriesSelectors.getEnquirySettingsInitialValues(state),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState),
  isSubmitting: appSelectors.getIsSubmitting(nurseriesSingleState),
  nurseryEnquiryToken: nurseriesSelectors.getNurseryEnquiryTokenSelector(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  nurseryPublicUrl: nurseriesSelectors.getNurseryPublicUrlSelector(state),
  nurserySettings: nurseriesSelectors.getNurserySettings(state),
})

const enhance = compose(
  withAppService,
  withNurseriesService,
  withSnackbarService,
  connect(mapState, mapDispatch),
)

export default enhance(FormContainer)
