import React from 'react'

import { Page, Spinner } from 'components'

import { StyledSpinnerContainer } from './ObservationsAddStyled'

const ObservationsAddView = () => (
  <Page.Section title="Make observation">
    <StyledSpinnerContainer>
      <Spinner />
    </StyledSpinnerContainer>
  </Page.Section>
)

export default ObservationsAddView
