import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_CHILD_FUNDINGS_LIST,
  EXPORT_CHILD_FUNDINGS,
  EXPORT_CHILD_FUNDINGS_FAILED,
  EXPORT_CHILD_FUNDINGS_SUCCESS,
  GET_CHILD_FUNDINGS_LIST,
  GET_CHILD_FUNDINGS_LIST_FAILED,
  GET_CHILD_FUNDINGS_LIST_SUCCESS,
} from './constants'

export const list = (
  options: BasicActionOptionsProps,
) => ({ childFundingsApiClient }) => generateBasicActions.list({
  constants: {
    failed: GET_CHILD_FUNDINGS_LIST_FAILED,
    init: GET_CHILD_FUNDINGS_LIST,
    success: GET_CHILD_FUNDINGS_LIST_SUCCESS,
  },
  options,
  service: childFundingsApiClient,
  serviceMethod: 'list',
})

export const exportReport = (options = {}) => ({ childFundingsApiClient }) => generateBasicActions.list({
  constants: {
    failed: EXPORT_CHILD_FUNDINGS_FAILED,
    init: EXPORT_CHILD_FUNDINGS,
    success: EXPORT_CHILD_FUNDINGS_SUCCESS,
  },
  options,
  service: childFundingsApiClient,
  serviceMethod: 'export',
})

export const clearList = () => ({
  type: CLEAR_CHILD_FUNDINGS_LIST,
})
