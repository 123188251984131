export const EXPORT_GROUP_REVENUE = 'EXPORT_GROUP_REVENUE'
export const EXPORT_GROUP_REVENUE_SUCCESS = 'EXPORT_GROUP_REVENUE'
export const EXPORT_GROUP_REVENUE_FAILED = 'EXPORT_GROUP_REVENUE_FAILED'

export const LIST_NURSERIES = 'LIST_NURSERIES'
export const LIST_NURSERIES_SUCCESS = 'LIST_NURSERIES_SUCCESS'
export const LIST_NURSERIES_FAILED = 'LIST_NURSERIES_FAILED'

export const SEND_NURSERIES_FORECAST_REPORT = 'SEND_NURSERIES_FORECAST_REPORT'
export const SEND_NURSERIES_FORECAST_REPORT_SUCCESS = 'SEND_NURSERIES_FORECAST_REPORT_SUCCESS'
export const SEND_NURSERIES_FORECAST_REPORT_FAILED = 'SEND_NURSERIES_FORECAST_REPORT_FAILED'

export const CLEAR_NURSERIES = 'CLEAR_NURSERIES'
