import React, { useMemo } from 'react'

import { EDITING_STAFF_ROTA } from 'services/nurseries/constants'

import { Page, Section } from 'components'

import i18n from 'translations'

import { EditingStaffRota, StaffChildRatios, StaffRequired } from './components'

const ManagementGeneralSettingsView = ({
  injectValidation,
  isFetching,
  isStaffingEnabled,
  nurseryOccupancySettings,
  nurseryStaffRotaSettings,
  onSubmit,
  startSubmitForm,
}) => {
  const { staffChildRatios = [], staffRequiredCalculation } = nurseryOccupancySettings
  const editingStaffRota = useMemo(() => (nurseryStaffRotaSettings.pastRotaCanBeEdited
    ? EDITING_STAFF_ROTA.PAST_WEEK_CAN_EDITED
    : EDITING_STAFF_ROTA.PAST_WEEK_CANNOT_EDITED),
  [nurseryStaffRotaSettings.pastRotaCanBeEdited])

  return (
    <Page isLoading={isFetching}>
      <Page.Content>
        <Section title={i18n.t('module:Management:General:title')}>
          <p>
            {i18n.t('module:Management:General:subtitle')}
          </p>
        </Section>
        {isStaffingEnabled && (
          <Section
            title={i18n.t('module:Management:General:RotaAndOccupancy:title')}
          >
            <StaffChildRatios
              injectValidation={injectValidation}
              isFetching={isFetching}
              startSubmitForm={startSubmitForm}
              value={staffChildRatios}
              onSubmit={onSubmit}
            />
            <StaffRequired
              injectValidation={injectValidation}
              isFetching={isFetching}
              startSubmitForm={startSubmitForm}
              value={staffRequiredCalculation}
              onSubmit={onSubmit}
            />
            <EditingStaffRota
              injectValidation={injectValidation}
              isFetching={isFetching}
              startSubmitForm={startSubmitForm}
              value={editingStaffRota}
              onSubmit={onSubmit}
            />
          </Section>
        )}
      </Page.Content>
    </Page>
  )
}

export default ManagementGeneralSettingsView
