import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { ChildNote } from 'services/childNotes/models'
import { DISPLAY_DATE_TIME_FORMAT } from 'constants/date'

import { generateRoute } from 'utils/routing'

import { EmptyState, List, Page, Section, Spinner, Typography } from 'components'

import i18n from 'translations'

interface ChildNotesListViewProps {
  childId: number
  isFetching: boolean
  notes: ChildNote[]
}

const ChildNotesListView: React.FC<ChildNotesListViewProps> = ({ childId, isFetching, notes }) => {
  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    if (!notes?.length) {
      return (
        <EmptyState
          icon="notes"
          text1={i18n.t('module:Children:Child:Notes:List:notFound')}
        />
      )
    }

    return (
      <List.Standard>
        {_.map(notes, (note: ChildNote) => (
          <List.Standard.Row
            content={note.content}
            key={note.id}
            title={(
              <div>
                <Typography>
                  <Typography fontSize={15} variant="span" bold>
                    {note.author.fullName}
                  </Typography>
                  {' '}
                  &bull;
                  {' '}
                  {moment(note.date).format(DISPLAY_DATE_TIME_FORMAT)}
                </Typography>
                <Typography fontSize={15} margin="10px 0 0" bold tertiary>
                  {note.title}
                </Typography>
              </div>
            )}
            to={generateRoute('CHILDREN.CHILD.NOTES.EDIT', { childId, noteId: note.id })}
          />
        ))}
      </List.Standard>
    )
  }

  return (
    <Page.Section
      actions={(
        <Section.Actions
          primary={[{
            label: i18n.t('module:Children:Child:Notes:List:addANewNote'),
            to: generateRoute('CHILDREN.CHILD.NOTES.ADD', { childId }),
          }]}
        />
      )}
      isLoading={isFetching}
      title={i18n.t('module:Children:Child:Notes:List:title')}
    >
      {renderContent()}
    </Page.Section>
  )
}

export default ChildNotesListView
