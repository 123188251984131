import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import { Form } from 'components'

export const ENABLE_LIKES_AND_COMMENTS_FORM = 'learning_enableLikesAndComments'

interface EnableLikesAndCommentsFormProps {
  onSubmit: (values: EnableLikesAndCommentsFormValues) => void
}

type EnableLikesAndCommentsFormFullProps = EnableLikesAndCommentsFormProps
  & InjectedFormProps<{}, EnableLikesAndCommentsFormProps>

export interface EnableLikesAndCommentsFormValues {
  enabled: boolean
}

const EnableLikesAndCommentsForm: React.FC<EnableLikesAndCommentsFormFullProps> = ({
  handleSubmit,
  onSubmit,
}) => (
  <Form flex={1} onSubmit={handleSubmit(onSubmit)}>
    <Form.Row>
      <Form.Row.Item>
        <Field
          component={Form.Switch}
          name="enabled"
        />
      </Form.Row.Item>
    </Form.Row>
  </Form>
)

export default reduxForm<{}, EnableLikesAndCommentsFormProps>({
  form: ENABLE_LIKES_AND_COMMENTS_FORM,
})(EnableLikesAndCommentsForm)
