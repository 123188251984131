import _ from 'lodash'

import React, { memo } from 'react'

import { STATUS_TYPES, STATUS_TYPES_DROPDOWN } from 'services/legacy/requestedExtraSessions/constants'

import { StyledPendingStatusDot, StyledStatusWrapper } from './StatusStyled'

const Status = ({ status }) => {
  const statusLabel = _.find(STATUS_TYPES_DROPDOWN, (item) => item.value === status)?.label

  return (
    <StyledStatusWrapper>
      {status === STATUS_TYPES.PENDING ? <StyledPendingStatusDot /> : null }
      {statusLabel}
    </StyledStatusWrapper>
  )
}

export default memo(Status)
