import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as membershipRegistersSelectors from './selectors'

const withMembershipRegistersService = (WrappedComponent) => {
  const mapState = ({ membershipRegisters }) => ({ membershipRegisters })

  const mapDispatch = injectActions('membershipRegistersActions', actions)

  const Component = ({ membershipRegisters, membershipRegistersActions, ...others }) => (
    <WrappedComponent
      membershipRegistersActions={membershipRegistersActions}
      membershipRegistersChangelogState={membershipRegisters.changelog}
      membershipRegistersListState={membershipRegisters.list}
      membershipRegistersListWorklogByDayState={membershipRegisters.listWorklogByDay}
      membershipRegistersOrganizationListState={membershipRegisters.organizationList}
      membershipRegistersSelectors={membershipRegistersSelectors}
      membershipRegistersSingleState={membershipRegisters.single}
      membershipRegistersStatisticsState={membershipRegisters.statistics}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withMembershipRegistersService
