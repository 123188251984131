import { BasicSingleInitialState, getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_PAYOUT,
  GET_PAYOUT,
  GET_PAYOUT_FAILED,
  GET_PAYOUT_SUCCESS,
  REMOVE_INTEGRATION,
  REMOVE_INTEGRATION_FAILED,
  REMOVE_INTEGRATION_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export interface NurseryIntegrationsSingleState extends BasicSingleInitialState {}

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_PAYOUT,
    itemFailed: GET_PAYOUT_FAILED,
    itemInit: GET_PAYOUT,
    itemSuccess: GET_PAYOUT_SUCCESS,
    removeFailed: REMOVE_INTEGRATION_FAILED,
    removeInit: REMOVE_INTEGRATION,
    removeSuccess: REMOVE_INTEGRATION_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
