import Conversations from './Conversations'
import ConversationsHeader from './ConversationsHeader'
import ConversationsList from './ConversationsList'
import ConversationsListItem from './ConversationsListItem'

Conversations.Header = ConversationsHeader
Conversations.List = ConversationsList
Conversations.ListItem = ConversationsListItem

export default Conversations
