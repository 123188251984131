import styled from 'styled-components'

import { Link } from 'react-router'

import layout from 'constants/layout'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledHeader = styled.header`
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: repeat(6, 1fr);
  margin-bottom: 25px;
  
  @media(max-width: 780px) {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 10px;
    grid-auto-flow: column;
  }
  
  @media(max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
`

export const StyledLink = styled(Link)`
  grid-row: 1;
  overflow: hidden;
  
  @media(max-width: 780px) {
    grid-row: auto;
  }
`

export const StyledNavigationButton = styled.div`
  background-color: ${getBrandingColor('primary-color')};
  border-radius: ${layout.borderRadius};
  padding: 15px 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  color: ${NEUTRAL_COLOURS.WHITE};
  min-height: 92px;
  transition: .2s;
  
  ${({ isActive }) => isActive && `
    background-color: ${getBrandingColor('secondary-color')};
  `}
`
