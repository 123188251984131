import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withModalService } from 'services/utils/modal'
import { withChildAdhocSessionsService } from 'services/legacy/childAdhocSessions'
import { withRouter } from 'services/router'

import ChildAdhocSessionsAddView from './ChildAdhocSessionsAddView'

import { FORM_NAME } from './components/ChildAdhocSessionsAddForm/ChildAdhocSessionsAddForm'

class ChildAdhocSessionsAddContainer extends Component {
  componentDidMount() {
    const {
      childAdhocSessionsActions,
      isEdit,
      params,
    } = this.props

    if (isEdit) {
      childAdhocSessionsActions.get({
        params: [params.childId, params.id, {
          includes: ['requestExtraSession'],
        }],
      })
    }
  }

  componentWillUnmount() {
    const { childAdhocSessionsActions } = this.props

    childAdhocSessionsActions.clearSingle()
  }

  goToAdhocSessions = () => {
    const { navigate, params } = this.props

    navigate(`/children/${params.childId}/booking-pattern/extra-sessions`)
  }

  handleSubmitSuccess = () => {
    this.goToAdhocSessions()
  }

  handleSubmit = (fields) => {
    const {
      childAdhocSessionsActions,
      childAdhocSessionsSelectors,
      childAdhocSessionsSingleState,
      isEdit,
      params,
    } = this.props

    const payload = childAdhocSessionsSelectors.getPayload(fields)

    if (isEdit) {
      return childAdhocSessionsActions.update(
        params.childId,
        childAdhocSessionsSingleState.data.id,
        payload,
        this.handleSubmitSuccess,
      )
    }

    return childAdhocSessionsActions.create(params.childId, payload, this.handleSubmitSuccess)
  }

  archiveAdhocSession = (archived) => () => {
    const { childAdhocSessionsActions, params } = this.props
    const { id } = params

    return childAdhocSessionsActions.archive(id, { archived }, this.handleSubmitSuccess)
  }

  handleArchiveClick = (archived) => () => {
    const { modalActions, modalConsts } = this.props

    const label = archived ? 'archive' : 'unarchive'

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: label,
      icon: label,
      onConfirm: this.archiveAdhocSession(archived),
      text: `Are you sure you want to ${label} this extra session?`,
    })
  }

  render() {
    const {
      child,
      childAdHocSession,
      childAdhocSessionsSingleState,
      cost,
      errorMessages,
      initialValues,
      isArchived,
      isEdit,
      isRequestingExtraSessionsFFOn,
    } = this.props

    const isLoading = childAdhocSessionsSingleState.isFetching || (isEdit && !initialValues)
    const isFormLoading = childAdhocSessionsSingleState.isSubmitting

    return (
      <ChildAdhocSessionsAddView
        child={child}
        childAdHocSession={childAdHocSession}
        cost={cost}
        errorMessages={errorMessages}
        initialValues={initialValues}
        isArchived={isArchived}
        isEdit={isEdit}
        isFormLoading={isFormLoading}
        isLoading={isLoading}
        isRequestingExtraSessionsFFOn={isRequestingExtraSessionsFFOn}
        onArchiveClick={this.handleArchiveClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

// ROOM_LEADER and TEACHER can only see, but cannot edit
// that why we have override params on wrapper
// if someone go through url
ChildAdhocSessionsAddContainer.authParams = {
  roles: [
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.NURSERY_MANAGER,
    ROLES.SUPER_ADMIN,
  ],
}

const mapState = (state, {
  appSelectors,
  childAdhocSessionsSelectors,
  childAdhocSessionsSingleState,
  childSelectors,
  params,
}) => ({
  child: childSelectors.getChildSelector(state),
  childAdHocSession: childAdhocSessionsSelectors.getSingleData(state),
  cost: childAdhocSessionsSelectors.getRuntimeCost(FORM_NAME)(state),
  errorMessages: appSelectors.getErrorMessages(childAdhocSessionsSingleState),
  initialValues: childAdhocSessionsSelectors.getInitialValues(!!params.id)(state),
  isArchived: childAdhocSessionsSelectors.isArchivedSelector(state),
  isEdit: !!params.id,
  isRequestingExtraSessionsFFOn: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.REQUESTING_EXTRA_SESSIONS],
  }),
})

const enhance = compose(
  withRouter,
  withAppService,
  withChildService,
  withModalService,
  withChildAdhocSessionsService,
  connect(mapState),
)

export default enhance(ChildAdhocSessionsAddContainer)
