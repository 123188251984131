import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { STATUS_DROPDOWN, TYPE_OF_REPORT_DROPDOWN, TYPE_OF_TRACKING } from 'services/legacy/formativeReports/constants'

import {
  DefinitionList,
  EmptyState,
  GroupAvatars,
  InfiniteDropdowns,
  List,
  Page,
  Pagination,
  SearchBar,
  Section,
  Select,
  Spinner,
  Toolbar,
  Typography,
} from 'components'

import i18n from 'translations'

import { FORMATIVE_REPORTS_PAGE_TYPE } from 'module/Learning/FormativeReports/constants'

import { StyledListContent } from './FormativeReportsListStyled'

const FormativeReportsListView = ({
  authAccessMap,
  childId,
  filters,
  isFetching,
  items,
  onExportClick,
  onPageChange,
  onReportTypeChange,
  onRoomChange,
  onSearchPhraseChange,
  onStatusChange,
  onTermChange,
  page,
  pageCount,
  pageType,
  perPage,
  termOptions,
  totalResults,
}) => {
  const {
    reportType,
    room,
    status,
    term,
  } = filters

  const isApprovalsPage = pageType === FORMATIVE_REPORTS_PAGE_TYPE.APPROVALS
  const isLearningPage = pageType === FORMATIVE_REPORTS_PAGE_TYPE.LEARNING

  const renderPagination = () => !isFetching && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('global:pagination:reports')}
      titleSingular={i18n.t('global:pagination:report')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    if (!items || !items.length) {
      return (
        <EmptyState
          icon="formative-reports"
          text1={i18n.t('module:Learning:FormativeReports:FormativeReportsList:emptyState')}
        />
      )
    }

    const renderRow = (item) => {
      const { borderColor, borderTitle, id, raw } = item
      const { author, child, childSnapshot, createdAt, dateTo, type, period = {} } = raw

      const report = _.find(TYPE_OF_REPORT_DROPDOWN, ({ value }) => value === type)

      const getPeriodLabelFromPeriodKey = () => {
        const periodKey = (period.key).split('_')

        periodKey[0] = _.upperFirst(periodKey[0])
        periodKey[1] = _.upperFirst(periodKey[1])

        return periodKey.join(' ')
      }

      const getPeriodLabel = () => {
        if (period.type === TYPE_OF_TRACKING.TERM) {
          return period.custom
            ? `${period.label} (${i18n.t('global:custom')})`
            : getPeriodLabelFromPeriodKey()
        }

        return period.custom
          ? `${period.label} (${i18n.t('global:custom')})`
          : period.label
      }

      const renderListContent = (
        <StyledListContent>
          <Toolbar noMargin>
            <Toolbar.Group fullWidth>
              <DefinitionList vertical>
                <DefinitionList.Item
                  label={moment(createdAt).format('DD/MM/YYYY')}
                  margin="0 0 15px 0"
                />
                <DefinitionList.Item
                  label={(
                    <Typography bold primary>
                      {period.type === TYPE_OF_TRACKING.ASSESSMENT
                        ? i18n.t('module:Learning:FormativeReports:assessmentPeriod')
                        : i18n.t('module:Learning:FormativeReports:term')}
                    </Typography>
                  )}
                  value={getPeriodLabel()}
                />
              </DefinitionList>
            </Toolbar.Group>
            <Toolbar.Group fullWidth>
              <DefinitionList vertical>
                <DefinitionList.Item
                  label={(
                    <Typography bold primary>
                      {_.upperFirst(i18n.t('global:room'))}
                    </Typography>
                  )}
                  value={childSnapshot.nurseryClass?.name}
                />
                {report && (
                  <DefinitionList.Item
                    label={(
                      <Typography bold primary>
                        {i18n.t('module:Learning:FormativeReports:reportType')}
                      </Typography>
                    )}
                    value={report.label}
                  />
                )}
              </DefinitionList>
            </Toolbar.Group>
            <Toolbar.Group fullWidth>
              <DefinitionList vertical>
                <DefinitionList.Item
                  label={(
                    <Typography bold primary>
                      {_.upperFirst(i18n.t('global:keyPerson'))}
                    </Typography>
                  )}
                  value={childSnapshot.keyWorkers.length
                    ? _.map(
                      childSnapshot.keyWorkers,
                      ({ fullName, name }, index) => (
                        `${name || fullName}${index + 1 !== childSnapshot.keyWorkers.length ? ', ' : ''}`),
                    )
                    : '-'}
                  whiteSpace="initial"
                />
                <DefinitionList.Item
                  label={(
                    <Typography bold primary>
                      {i18n.t('global:createdBy')}
                    </Typography>
                  )}
                  value={author?.fullName || author?.name}
                />
              </DefinitionList>
            </Toolbar.Group>
          </Toolbar>
        </StyledListContent>
      )

      const getPreviewRoute = () => {
        if (isLearningPage) {
          return generateRoute('LEARNING.FORMATIVE_REPORTS.PREVIEW', {
            formativeReportId: id,
          })
        }

        if (isApprovalsPage) {
          return generateRoute('APPROVALS.FORMATIVE_REPORTS.PREVIEW', {
            formativeReportId: id,
          })
        }

        return generateRoute('CHILDREN.CHILD.LEARNING_JOURNEY.FORMATIVE_REPORTS.PREVIEW', {
          childId: childId || child?.id,
          formativeReportId: id,
        })
      }

      const onRecalculateAgeMonth = (childObject) => (
        moment(dateTo).diff(childObject.birthDate, 'months')
      )

      return (
        <List.Standard.Row
          borderColor={borderColor}
          borderTitle={borderTitle}
          content={renderListContent}
          key={id}
          leftColumn={(
            <GroupAvatars
              backgroundColor={borderColor}
              borderColor={NEUTRAL_COLOURS.SILVER}
              persons={[child]}
              renderUrl={(element) => generateRoute('CHILDREN.CHILD', { childId: element.id })}
              onRecalculateAgeMonth={onRecalculateAgeMonth}
            />
          )}
          to={getPreviewRoute()}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <List.Standard>
          {_.map(items, renderRow)}
        </List.Standard>
        {renderPagination()}
      </React.Fragment>
    )
  }

  const getCreateRoute = () => {
    if (isLearningPage || isApprovalsPage) {
      return generateRoute('LEARNING.FORMATIVE_REPORTS.CREATE')
    }

    return generateRoute('CHILDREN.CHILD.LEARNING_JOURNEY.FORMATIVE_REPORTS.CREATE', {
      childId,
    })
  }

  const actions = !isApprovalsPage && (
    <Section.Actions
      options={[{
        auth: authAccessMap.section.pdfAutomation,
        onClick: onExportClick,
        type: 'export',
      }]}
      primary={[{
        label: i18n.t('module:Learning:FormativeReports:ReportTypes:formativeReport'),
        to: getCreateRoute(),
      }]}
    />
  )

  const renderLearningToolbar = () => (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <InfiniteDropdowns.Rooms
            value={room}
            onChange={onRoomChange}
          />
        </Toolbar.Item>
        <Toolbar.Item>
          <Select
            options={TYPE_OF_REPORT_DROPDOWN}
            placeholder={i18n.t('module:Learning:FormativeReports:reportType')}
            value={reportType}
            onChange={onReportTypeChange}
          />
        </Toolbar.Item>
        <Toolbar.Item>
          <Select
            options={termOptions}
            placeholder={i18n.t('module:Learning:FormativeReports:term')}
            value={term}
            onChange={onTermChange}
          />
        </Toolbar.Item>
        <Toolbar.Item>
          <Select
            options={STATUS_DROPDOWN}
            placeholder={_.upperFirst(i18n.t('global:status'))}
            value={status}
            onChange={onStatusChange}
          />
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )

  const renderToolbar = () => (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <Select
            options={termOptions}
            placeholder={i18n.t('module:Learning:FormativeReports:term')}
            value={term}
            onChange={onTermChange}
          />
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )

  const renderLearningFormativeReports = () => (
    <React.Fragment>
      <SearchBar
        placeholder={i18n.t('module:Learning:FormativeReports:FormativeReportsList:searchBarPlaceholder')}
        onChange={onSearchPhraseChange}
      />
      <Page.Section
        actions={actions}
        isLoading={isFetching}
        title={i18n.t('module:Learning:FormativeReports:title')}
      >
        {renderLearningToolbar()}
        {renderContent()}
      </Page.Section>
    </React.Fragment>
  )

  const renderChildFormativeReports = () => (
    <Page.Section
      actions={actions}
      isLoading={isFetching}
      title={i18n.t('module:Learning:FormativeReports:title')}
    >
      <Typography margin="20px 0">
        {i18n.t('module:Learning:FormativeReports:FormativeReportsList:subtitle')}
      </Typography>
      {renderToolbar()}
      {renderContent()}
    </Page.Section>
  )

  const renderApprovalsFormativeReports = () => (
    <Page.Section
      isLoading={isFetching}
      title={`${i18n.t('module:Approvals:title')}:`}
      titleSecond={i18n.t('module:Learning:FormativeReports:title')}
    >
      {renderToolbar()}
      {renderContent()}
    </Page.Section>
  )

  return (
    <React.Fragment>
      {isLearningPage && renderLearningFormativeReports()}
      {isApprovalsPage && renderApprovalsFormativeReports()}
      {!isLearningPage && !isApprovalsPage && renderChildFormativeReports()}
    </React.Fragment>
  )
}

export default FormativeReportsListView
