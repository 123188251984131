import moment from 'moment'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Typography } from 'components'

import i18n from 'translations'

// @ts-ignore
import PoweredByBlossom from 'assets/images/powered-by-blossom.png'

import { StyledBlossomImage, StyledFooterWrapper } from './PoweredByBlossomFooterStyled'

const PoweredByBlossomFooter = () => (
  <StyledFooterWrapper>
    <Typography color={NEUTRAL_COLOURS.GRAY} variant="span">
      {i18n.t('module:EnquiryPublicForm:copyright', {
        company: i18n.t('global:companyName'),
        year: moment().format('YYYY'),
      })}
    </Typography>
    <StyledBlossomImage
      src={PoweredByBlossom}
    />
  </StyledFooterWrapper>
)

export default PoweredByBlossomFooter
