import React from 'react'

import { Callout, Spinner } from 'components'
import Settings from 'module/Settings/components'

import { InitialValuesResponseProps } from './helpers'

interface AnnualisedSettingsViewProps {
  cancelLink?: string
  errorMessages?: any[]
  initialValues?: InitialValuesResponseProps
  isFormLoading?: boolean
  isLoading?: boolean
  onSubmit?: (fields) => void
}

const SettingsInvoicesV3EditAnnualisedView: React.FC<AnnualisedSettingsViewProps> = ({
  cancelLink,
  errorMessages,
  initialValues,
  isFormLoading,
  isLoading,
  onSubmit,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <Settings.InvoiceAnnualisedSettingForm
        cancelLink={cancelLink}
        initialValues={initialValues}
        isLoading={isFormLoading}
        hideGlobalOption
        onSubmit={onSubmit}
      />
    )
  }

  return (
    <React.Fragment>
      <Callout content={errorMessages} error />
      {renderContent()}
    </React.Fragment>
  )
}

export default SettingsInvoicesV3EditAnnualisedView
