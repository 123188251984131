// This code need to be move to service once we start binding api
import { PeriodType } from 'services/booking/periods/models'

import i18n from 'translations'

export const STATUS_FILTER = {
  ACTIVE: 'ACTIVE',
  ALL: 'ALL',
  ARCHIVED: 'ARCHIVED',
}

export const STATUS_OPTIONS = [
  { label: i18n.t('global:Active'), value: STATUS_FILTER.ACTIVE },
  { label: i18n.t('global:All'), value: STATUS_FILTER.ALL },
  { label: i18n.t('global:Archived'), value: STATUS_FILTER.ARCHIVED },
]

export const ATTENDANCE_PERIOD_TYPE_OPTIONS = [
  {
    label: i18n.t('module:Management:AttendancePeriods:PeriodOptions:AllYearRound:label'),
    tooltip: i18n.t('module:Management:AttendancePeriods:PeriodOptions:AllYearRound:tooltip'),
    value: PeriodType.ALL_YEAR_ROUND,
  },
  {
    label: i18n.t('module:Management:AttendancePeriods:PeriodOptions:TermDates:label'),
    tooltip: i18n.t('module:Management:AttendancePeriods:PeriodOptions:TermDates:tooltip'),
    value: PeriodType.TERM_DATES,
  },
]
