import { generateBasicActions } from 'utils/service'

import {
  UPDATE_LIKE_AND_COMMENTS_MEMBERS,
  UPDATE_LIKE_AND_COMMENTS_MEMBERS_FAILED,
  UPDATE_LIKE_AND_COMMENTS_MEMBERS_SUCCESS,
} from './constants'

export const updateLikesAndCommentsMembers = (options) => ({ nurseriesApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_LIKE_AND_COMMENTS_MEMBERS_FAILED,
      init: UPDATE_LIKE_AND_COMMENTS_MEMBERS,
      success: UPDATE_LIKE_AND_COMMENTS_MEMBERS_SUCCESS,
    },
    options,
    service: nurseriesApiClient,
    serviceMethod: 'updateLikesAndCommentsMembers',
  })
)
