export const GET_CHILD_FUNDING_SESSION_SET = 'GET_CHILD_FUNDING_SESSION_SET'
export const GET_CHILD_FUNDING_SESSION_SET_SUCCESS = 'GET_CHILD_FUNDING_SESSION_SET_SUCCESS'
export const GET_CHILD_FUNDING_SESSION_SET_FAILED = 'GET_CHILD_FUNDING_SESSION_SET_FAILED'

export const CREATE_CHILD_FUNDING_SESSION_SET = 'CREATE_CHILD_FUNDING_SESSION_SET'
export const CREATE_CHILD_FUNDING_SESSION_SET_SUCCESS = 'CREATE_CHILD_FUNDING_SESSION_SET_SUCCESS'
export const CREATE_CHILD_FUNDING_SESSION_SET_FAILED = 'CREATE_CHILD_FUNDING_SESSION_SET_FAILED'

export const UPDATE_CHILD_FUNDING_SESSION_SET = 'UPDATE_CHILD_FUNDING_SESSION_SET'
export const UPDATE_CHILD_FUNDING_SESSION_SET_SUCCESS = 'UPDATE_CHILD_FUNDING_SESSION_SET_SUCCESS'
export const UPDATE_CHILD_FUNDING_SESSION_SET_FAILED = 'UPDATE_CHILD_FUNDING_SESSION_SET_FAILED'

export const DELETE_CHILD_FUNDING_SESSION_SET = 'DELETE_CHILD_FUNDING_SESSION_SET'
export const DELETE_CHILD_FUNDING_SESSION_SET_SUCCESS = 'DELETE_CHILD_FUNDING_SESSION_SET_SUCCESS'
export const DELETE_CHILD_FUNDING_SESSION_SET_FAILED = 'DELETE_CHILD_FUNDING_SESSION_SET_FAILED'

export const CLEAR_SINGLE_CHILD_FUNDING_SESSION_SET = 'CLEAR_SINGLE_CHILD_FUNDING_SESSION_SET'
