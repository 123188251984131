import { NotificationTagEnum, useNotifications, useSync } from '@blossomdev/sync'

import React, { useEffect, useState } from 'react'

import { ZINDEX_ORDER } from 'constants/layout'
import { LIST_TAB_NAMES } from 'module/Notification/components/NotificationList/Constants'

import { generateRoute } from 'utils/routing'
import { useWindowSize } from 'utils/hooks'

import { Button, Conversations, DropdownMenu, Page, Tabs } from 'components'

import i18n from 'translations'

import NotificationList from 'module/Notification/components/NotificationList'

export const ITEM_HEIGHT = 78
export const PHOTO_ALT_KEY = 'photoAlt'

interface ListViewProps {
  entryTab: string
  isPopoverContext: boolean
  onChangeListTab: (name: string) => void
  onRedirectListItem: () => void
}

const ListView = ({ entryTab, isPopoverContext, onChangeListTab, onRedirectListItem }: ListViewProps) => {
  const { height: windowHeight } = useWindowSize()
  const {
    clearAllNotifications,
    markAllNotificationsAsRead,
  } = useSync()
  const [isDisabled, setIsDisabled] = useState(false)
  const { initialized, notifications } = useNotifications(NotificationTagEnum.ALL)

  useEffect(() => {
    if (initialized && !notifications.length) {
      setIsDisabled(true)
    }
  }, [notifications])

  const actions = (
    <React.Fragment>
      {isPopoverContext && (
        <Button
          hierarchy="tertiary"
          icon="expand-view"
          size="small"
          to={generateRoute('NOTIFICATION.INDEX')}
          negativeMargins
        />
      )}
      <DropdownMenu
        disabled={isDisabled}
        parentZindex={ZINDEX_ORDER.NOTIFICATION}
        zIndex={ZINDEX_ORDER.NOTIFICATION_MENU}
      >
        <DropdownMenu.Item
          key="mark_as_read"
          label={i18n.t('module:Notifications:Actions:MarkAllAsRead:label')}
          onClick={() => markAllNotificationsAsRead()}
        />
        <DropdownMenu.Item
          key="clear_all"
          label={i18n.t('module:Notifications:Actions:ClearAll:label')}
          onClick={() => clearAllNotifications()}
        />
      </DropdownMenu>
    </React.Fragment>
  )

  const notificationItemListContent = (notificationTagEnum) => (
    <NotificationList notificationTagType={notificationTagEnum} onRedirect={onRedirectListItem} />
  )

  const renderContent = () => (
    <Conversations
      height={windowHeight}
      itemsLength={7}
      width={isPopoverContext ? 500 : undefined}
    >
      <Conversations.Header
        actions={actions}
        title={i18n.t('module:Notifications:List:title')}
      />
      <Tabs
        fullWidth={false}
        value={entryTab}
        variant="scrollable"
        fullHeight
        onChange={onChangeListTab}
      >
        <Tabs.Item
          minWidth="70px"
          name={LIST_TAB_NAMES.ALL}
          title={i18n.t('module:Notifications:List:Tabs:All:label')}
        >
          {entryTab === LIST_TAB_NAMES.ALL && notificationItemListContent(NotificationTagEnum.ALL)}
        </Tabs.Item>
        <Tabs.Item
          minWidth="70px"
          name={LIST_TAB_NAMES.TASKS}
          title={i18n.t('module:Notifications:List:Tabs:Tasks:label')}
        >
          {entryTab === LIST_TAB_NAMES.TASKS && notificationItemListContent(NotificationTagEnum.TASK)}
        </Tabs.Item>
        <Tabs.Item
          minWidth="70px"
          name={LIST_TAB_NAMES.REMINDERS}
          title={i18n.t('module:Notifications:List:Tabs:Reminders:label')}
        >
          {entryTab === LIST_TAB_NAMES.REMINDERS
            && notificationItemListContent(NotificationTagEnum.REMINDER)}
        </Tabs.Item>
        <Tabs.Item
          minWidth="70px"
          name={LIST_TAB_NAMES.UPDATES}
          title={i18n.t('module:Notifications:List:Tabs:Updates:label')}
        >
          {entryTab === LIST_TAB_NAMES.UPDATES && notificationItemListContent(NotificationTagEnum.UPDATE)}
        </Tabs.Item>
      </Tabs>
    </Conversations>
  )

  if (isPopoverContext) {
    return renderContent()
  }

  return (
    <Page>
      <Page.Content>
        {renderContent()}
      </Page.Content>
    </Page>
  )
}

export default ListView
