import { generateBasicActions } from 'utils/service'

import {
  CLEAR_NURSERY_PAYMENT_TYPE,
  CREATE_NURSERY_PAYMENT_TYPE,
  CREATE_NURSERY_PAYMENT_TYPE_FAILED,
  CREATE_NURSERY_PAYMENT_TYPE_SUCCESS,
  GET_NURSERY_PAYMENT_TYPE,
  GET_NURSERY_PAYMENT_TYPE_FAILED,
  GET_NURSERY_PAYMENT_TYPE_SUCCESS,
  UPDATE_NURSERY_PAYMENT_TYPE,
  UPDATE_NURSERY_PAYMENT_TYPE_FAILED,
  UPDATE_NURSERY_PAYMENT_TYPE_SUCCESS,
} from './constants'

export const get = (options = {}) => ({ nurseryPaymentTypesApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_NURSERY_PAYMENT_TYPE_FAILED,
    init: GET_NURSERY_PAYMENT_TYPE,
    success: GET_NURSERY_PAYMENT_TYPE_SUCCESS,
  },
  options,
  service: nurseryPaymentTypesApiClient,
  serviceMethod: 'get',
})

export const create = (options = {}) => ({ nurseryPaymentTypesApiClient }) => generateBasicActions.create({
  constants: {
    failed: CREATE_NURSERY_PAYMENT_TYPE_FAILED,
    init: CREATE_NURSERY_PAYMENT_TYPE,
    success: CREATE_NURSERY_PAYMENT_TYPE_SUCCESS,
  },
  options,
  service: nurseryPaymentTypesApiClient,
  serviceMethod: 'create',
})

export const update = (options = {}) => ({ nurseryPaymentTypesApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_NURSERY_PAYMENT_TYPE_FAILED,
    init: UPDATE_NURSERY_PAYMENT_TYPE,
    success: UPDATE_NURSERY_PAYMENT_TYPE_SUCCESS,
  },
  options,
  service: nurseryPaymentTypesApiClient,
  serviceMethod: 'update',
})

export const clear = () => ({
  type: CLEAR_NURSERY_PAYMENT_TYPE,
})
