import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class MembershipRegistersApiClient extends ServiceBase {
  list = (params = {}) => this.request({
    path: `/v2/memberships/registers?${getQueryStringV2(params)}`,
  })

  get = (id, date, params = {}) => {
    if (date) {
      return this.request({
        path: `/v2/membership_registers/${id}/${date}?${getQueryStringV2(params)}`,
      })
    }

    return this.request({
      path: `/v2/membership_registers/${id}?${getQueryStringV2(params)}`,
    })
  }

  getChangelog = (id, params = {}) => this.request({
    path: `/v2/membership_registers/${id}/changelog?${getQueryStringV2(params)}`,
  })

  create = (payload, params) => this.request({
    body: payload,
    method: 'POST',
    path: `/v2/membership_registers?${getQueryStringV2(params)}`,
  })

  update = (id, payload, params) => this.request({
    body: payload,
    method: 'PUT',
    path: `/v2/membership_registers/${id}?${getQueryStringV2(params)}`,
  })

  updateBatch = (body) => this.request({
    body,
    method: 'POST',
    path: '/v2/batch/membership_registers',
  })

  createEntry = (payload, params) => this.request({
    body: payload,
    method: 'POST',
    path: `/v2/membership_register_entries?${getQueryStringV2(params)}`,
  })

  updateEntry = (id, payload, params) => this.request({
    body: payload,
    method: 'PUT',
    path: `/v2/membership_register_entries/${id}?${getQueryStringV2(params)}`,
  })

  statistics = (params = {}) => this.request({
    path: `/v2/statistics/membership_registers/status?${getQueryStringV2(params)}`,
  })
}

export default MembershipRegistersApiClient
