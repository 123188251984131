import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as homeObservationsSelectors from './selectors'

const withHomeObservationsService = (WrappedComponent) => {
  const mapState = ({ homeObservations }) => ({ homeObservations })

  const mapDispatch = injectActions('homeObservationsActions', actions)

  const Component = ({ homeObservations, homeObservationsActions, ...others }) => (
    <WrappedComponent
      homeObservationsActions={homeObservationsActions}
      homeObservationsListState={homeObservations.list}
      homeObservationsSelectors={homeObservationsSelectors}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withHomeObservationsService
