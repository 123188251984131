import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledLink = styled.a`
  color: ${getBrandingColor('primary-color')} !important;  
`

export const StyledLinkExpired = styled.div`
  padding: 20px;
  width: 100vw;
  min-height: 100vh;
  display: grid;
  flex-direction: column;
  grid-gap: 20px;
  grid-template-rows: auto 1fr;
  padding-top: 200px;
`

export const StyledLinkExpiredContainer = styled.div`
  display: flex;
  justify-content: center;

  svg {
    max-width: 600px;
    max-height: 300px;
    
    @media(max-width: 700px) {
      width: 100%;
      height: 50vw;
    }
  }

  .linkExpiredPrimary {
    fill: ${getBrandingColor('primary-color')}
  }
  
  .linkExpiredTertiary {
    fill: ${getBrandingColor('tertiary-color')}
  }
`
