import moment from 'moment'

import React from 'react'

import colors from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

import { Avatar, Currency, DefinitionList, Form, ModalBox, Spinner, Toolbar, Typography } from 'components'

import BalanceAdjustmentForm from './components/BalanceAdjustmentModalForm'

const BalanceAdjustmentModalView = ({
  authUser,
  child,
  isFetching,
  isSubmitting,
  item,
  newBalance,
  onCloseClick,
  onSubmit,
}) => {
  const { balance, firstName, photo, surname } = child

  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    if (item && item.id) {
      const { raw } = item
      const { data } = raw
      const { author = {}, comment, createdAt, total } = data
      const { fullName, name, photo: authorPhoto } = author

      return (
        <React.Fragment>
          <Toolbar>
            <Toolbar.Group>
              <Toolbar.Item>
                <Avatar
                  initials={[firstName, surname]}
                  src={photo}
                  title={(
                    <Typography fontSize={14} primary>
                      {`${firstName} ${surname}`}
                    </Typography>
                  )}
                />
              </Toolbar.Item>
            </Toolbar.Group>
          </Toolbar>
          <Form.Row
            label="Adjustment by"
          >
            <Avatar
              avatarSize="medium"
              initials={[name]}
              src={authorPhoto}
              title={`${fullName} on ${moment(createdAt).format('DD/MM/YYYY')}`}
              borderAvatar
            />
          </Form.Row>
          <DefinitionList
            margin="0 0 30px 0"
            vertical
          >
            <DefinitionList.Item
              label={(
                <Typography color={0 < total ? colors.finance.credit : colors.finance.debit} bold>
                  {0 < total ? 'Credit' : 'Debit'}
                </Typography>
              )}
              value={<Currency value={Math.abs(total)} />}
            />
            <DefinitionList.Item
              label="Balance after adjustment"
              value={(
                <Currency
                  color={0 > raw.balance && getBrandingColor('tertiary-color')}
                  value={raw.balance}
                  showDebitCredit
                />
              )}
            />
            <DefinitionList.Item
              label="Comment"
              value={comment || '-'}
            />
          </DefinitionList>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <Toolbar>
          <Toolbar.Group>
            <Toolbar.Item>
              <Avatar
                initials={[firstName, surname]}
                src={photo}
                title={(
                  <Typography fontSize={14} primary>
                    {`${firstName} ${surname}`}
                  </Typography>
                )}
              />
            </Toolbar.Item>
          </Toolbar.Group>
        </Toolbar>
        <BalanceAdjustmentForm
          authUser={authUser}
          balance={balance}
          isSubmitting={isSubmitting}
          newBalance={newBalance}
          onCloseClick={onCloseClick}
          onSubmit={onSubmit}
        />
      </React.Fragment>
    )
  }

  return (
    <ModalBox
      title="Balance adjustment"
      autoHeight
      onCloseClick={onCloseClick}
    >
      {renderContent()}
    </ModalBox>
  )
}

export default BalanceAdjustmentModalView
