import React from 'react'

import { StyledContainer } from './RegisterActivityNameStyled'

const RegisterActivityName = ({ children, color }) => (
  <StyledContainer color={color}>
    {children}
  </StyledContainer>
)

export default RegisterActivityName
