import React from 'react'

import ImagesSize from 'constants/imagesSize'

import { Avatar, Callout, DateString, DefinitionList, ModalBox, Spinner, Toolbar, Typography } from 'components'

import i18n from 'translations'

import {
  PlanDetailAdhocSessions,
  PlanDetailDiscounts,
  PlanDetailExtraItems,
  PlanDetailFundings,
  PlanDetailSessions,
} from './components'

import { StyledContainer, StyledViewContainer } from './PlanDetailsModalStyled'

const PlanDetailsModalView = ({
  child,
  childAdhocSessions,
  childDiscounts,
  childExtraItems,
  childFundings,
  childSessions,
  endDate,
  errorMessages,
  isLoading,
  onCloseClick,
  startDate,
}) => {
  const { ageMonths, firstName, photo, surname } = child

  const renderChildHeader = () => (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <Avatar avatarSize={ImagesSize.AVATARS.STANDARD.width} initials={[firstName, surname]} src={photo} />
        </Toolbar.Item>
        <Toolbar.Item>
          <DefinitionList>
            <DefinitionList.Item
              label={`${firstName} ${surname}`}
              value={(
                <Typography primary>
                  {i18n.t('global:ageMonths', { ageMonths })}
                </Typography>
              )}
            />
          </DefinitionList>
        </Toolbar.Item>
      </Toolbar.Group>
      <Toolbar.Group>
        <Toolbar.Item>
          <DateString date={startDate} />
          &nbsp;
          {'-'}
          &nbsp;
          <DateString date={endDate} />
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <StyledContainer>
        <StyledViewContainer>
          <PlanDetailSessions childSessions={childSessions} />
          <PlanDetailAdhocSessions childAdhocSessions={childAdhocSessions} />
        </StyledViewContainer>
        <StyledViewContainer>
          <PlanDetailExtraItems childExtraItems={childExtraItems} />
          <PlanDetailDiscounts childDiscounts={childDiscounts} />
          <PlanDetailFundings childFundings={childFundings} />
        </StyledViewContainer>
      </StyledContainer>
    )
  }

  return (
    <ModalBox
      title={i18n.t('module:Children:Child:Finance:PlanView:tabTitle')}
      autoHeight
      onCloseClick={onCloseClick}
    >
      <Callout content={errorMessages} error />
      {renderChildHeader()}
      {renderContent()}
    </ModalBox>
  )
}

export default PlanDetailsModalView
