import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { BasicModalProps } from 'modals'

import { Button, FooterActions, MinimalModalBox } from 'components'

import i18n from 'i18next'

import { StyledContent, StyledRoot } from './ConfirmModalStyled'

export interface ConfirmModalProps {
  cancelButtonLabel?: string | React.ReactNode
  confirmButtonLabel?: string | React.ReactNode
  content?: string | React.ReactNode
  header?: string | React.ReactNode
  icon?: IconType
  onCancel?: () => void
  onConfirm?: () => void
  text?: string | React.ReactNode
}

const ConfirmModal: React.FC<BasicModalProps & ConfirmModalProps> = ({
  cancelButtonLabel,
  confirmButtonLabel,
  content,
  header,
  hideModal,
  icon,
  onCancel,
  onConfirm,
  text,
}) => {
  const handleCancelClick = () => {
    hideModal()

    if (onCancel) {
      onCancel()
    }
  }

  const handleConfirmClick = () => {
    hideModal()

    if (onConfirm) {
      onConfirm()
    }
  }

  return (
    <MinimalModalBox>
      <StyledRoot>
        {header}
        {icon && <MinimalModalBox.TopIcon color={NEUTRAL_COLOURS.WHITE} icon={icon} />}
        <StyledContent>
          {text}
          {content}
        </StyledContent>
        <FooterActions
          noBorder
          noMargin
        >
          <FooterActions.Item>
            <Button
              hierarchy="secondary"
              label={cancelButtonLabel || i18n.t('global:Cancel')}
              inverted
              negativeMargins
              onClick={handleCancelClick}
            />
          </FooterActions.Item>
          <FooterActions.Item>
            <Button
              color="tertiary"
              label={confirmButtonLabel || i18n.t('global:Ok')}
              inverted
              negativeMargins
              onClick={handleConfirmClick}
            />
          </FooterActions.Item>
        </FooterActions>
      </StyledRoot>
    </MinimalModalBox>
  )
}

export default ConfirmModal
