import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT } from 'constants/date'

import { getAvailabilityStatisticData } from 'module/RequestedExtraSessions/RequestedExtraSessionsHelpers'

import { Avatar, Currency, Spinner, Typography } from 'components'

import i18n from 'translations'

import { ColumnTitle, Session, StaffRequiredTooltip, Status } from './components'

export const getTableData = ({
  isAvailabilityStatisticsFetching,
  isFinanceV3Enabled,
  onClick,
  requestedExtraSessions,
  statistics,
}) => {
  if (!requestedExtraSessions?.length) {
    return []
  }

  return _.map(requestedExtraSessions, (extraSession) => {
    const { child, cost, createdAt, date, id, requestedBy, status } = extraSession

    const { firstName, name, photo, surname } = child
    const { firstName: requesterFirstName, name: requesterName, photo: requesterPhoto } = requestedBy

    const statistic = _.find(statistics, ({ parentRequestExtraSession }) => (id === parentRequestExtraSession?.id))
    const { availabilityStatistics, staffRequiredStatistics } = statistic || {}
    const { availabilityPercent } = availabilityStatistics || {}

    const {
      label: availabilityLabel,
    } = getAvailabilityStatisticData(availabilityPercent)

    return {
      availability: !isAvailabilityStatisticsFetching ? availabilityLabel : <Spinner light />,
      cost: <Currency value={cost} />,
      date: moment(date).format(DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT),
      id,
      name: (
        <Avatar
          avatarSize="small"
          initials={[firstName, surname]}
          src={photo}
          title={(
            <Typography fontSize={16} ellipsis>
              {name}
            </Typography>
          )}
        />
      ),
      onClick: () => onClick(id),
      requestDateTime: moment(createdAt).format('DD MMM YYYY HH:mm'),
      requestedBy: (
        <Avatar
          avatarSize="small"
          initials={[requesterFirstName]}
          src={requesterPhoto}
          title={(
            <Typography ellipsis>
              {requesterName}
            </Typography>
          )}
        />
      ),
      session: <Session extraSession={extraSession} isFinanceV3Enabled={isFinanceV3Enabled} />,
      staff: !isAvailabilityStatisticsFetching ? (
        <StaffRequiredTooltip staffRequiredStatistics={staffRequiredStatistics} />
      ) : <Spinner light />,
      status: <Status status={status} />,
    }
  })
}

export const getTableColumns = (isFinanceV3Enabled) => [
  {
    align: 'left',
    field: 'date',
    justifyContent: 'flex-start',
    sortable: true,
    title: (
      <ColumnTitle
        title={i18n.t('module:Parent:RequestedSessions:List:columns:date')}
      />
    ),
    width: '160',
  },
  {
    align: 'left',
    field: 'session',
    justifyContent: 'flex-start',
    sortKey: isFinanceV3Enabled ? 'nurserySessionProduct.name' : 'nurserySession.name',
    sortable: true,
    title: (
      <ColumnTitle
        title={i18n.t('module:Parent:RequestedSessions:List:columns:session')}
      />
    ),
    width: '160',
  },
  {
    align: 'center',
    field: 'cost',
    justifyContent: 'center',
    sortable: true,
    title: (
      <ColumnTitle
        title={i18n.t('module:Parent:RequestedSessions:List:columns:cost')}
      />
    ),
    width: '120',
  },
  {
    align: 'center',
    field: 'availability',
    justifyContent: 'center',
    title: (
      <ColumnTitle
        title={i18n.t('module:Parent:RequestedSessions:List:columns:availability')}
      />
    ),
    width: '120',
  },
  {
    align: 'center',
    field: 'staff',
    justifyContent: 'center',
    title: (
      <ColumnTitle
        title={i18n.t('module:Parent:RequestedSessions:List:columns:staffRequired')}
      />
    ),
    width: '140',
  },
  {
    align: 'left',
    field: 'status',
    justifyContent: 'flex-start',
    sortable: true,
    title: (
      <ColumnTitle
        title={i18n.t('module:Parent:RequestedSessions:List:columns:status')}
      />
    ),
    width: '120',
  },
  {
    align: 'left',
    field: 'requestedBy',
    justifyContent: 'flex-start',
    title: (
      <ColumnTitle
        title={i18n.t('module:Parent:RequestedSessions:List:columns:requestedBy')}
      />
    ),
    width: '220',
  },
  {
    align: 'left',
    field: 'requestDateTime',
    justifyContent: 'flex-start',
    title: (
      <ColumnTitle
        title={i18n.t('module:Parent:RequestedSessions:List:columns:requestDateTime')}
      />
    ),
    width: '180',
  },
]
