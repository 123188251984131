import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import {
  CLEAR_ENQUIRY_CONVERSION_RATE_REPORT,
  GET_ENQUIRY_CONVERSION_RATE_REPORT,
  GET_ENQUIRY_CONVERSION_RATE_REPORT_FAILED,
  GET_ENQUIRY_CONVERSION_RATE_REPORT_SUCCESS,
} from './constants'

export default (...props) => {
  const [state = getBasicListInitialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_ENQUIRY_CONVERSION_RATE_REPORT,
    failed: GET_ENQUIRY_CONVERSION_RATE_REPORT_FAILED,
    init: GET_ENQUIRY_CONVERSION_RATE_REPORT,
    success: GET_ENQUIRY_CONVERSION_RATE_REPORT_SUCCESS,
  }, getBasicListInitialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
