import React, { PropsWithChildren } from 'react'

import ConversationsHeader from './ConversationsHeader'
import ConversationsList from './ConversationsList'
import ConversationsListItem from './ConversationsListItem'

import { StyledContainer } from './ConversationsStyled'

export const ITEM_HEIGHT: number = 78

interface ConversationsProps {
  height?: number
  itemsLength?: number
  width?: number
}

interface ConversationsComponentProps extends React.FC<PropsWithChildren<ConversationsProps>> {
  Header?: typeof ConversationsHeader
  List?: typeof ConversationsList
  ListItem?: typeof ConversationsListItem
}

const Conversations: ConversationsComponentProps = ({
  children,
  height,
  itemsLength,
  width,
}) => (
  <StyledContainer
    $height={height}
    $itemsLength={itemsLength}
    $width={width}
  >
    {children}
  </StyledContainer>
)

export default Conversations
