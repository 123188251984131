import moment from 'moment'
import _ from 'lodash'

import React from 'react'

import { CHANGELOG_MADE_BY_OPTIONS, CHANGELOG_MADE_BY_OPTIONS_OPTIONS } from 'services/legacy/child/constants'
import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { EmptyState, Form, InfiniteScroll, Page, Select, Spinner, Toolbar, Typography } from 'components'

import i18n from 'translations'

import { getFormattedFields } from './ChildProfileHistoryHelpers'
import {
  StyledContent,
  StyledField,
  StyledHeader,
  StyledItem,
  StyledRightColumn,
  StyledValue,
} from './ChildProfileHistoryStyled'

const AUTHOR_TYPE = {
  MEMBER: 'member',
  PARENT: 'parent',
}

const ACTION_VARIANTS = {
  CREATE: 'create',
}

const ChildProfileHistoryView = ({
  childLogs,
  drinkingMethods,
  filters,
  isFetching,
  maritalStatuses,
  onChangeMadeBy,
  onChangeSection,
  onPageChange,
  page,
  pageCount,
  toiletTrainingOptions,
}) => {
  const renderValue = (value) => {
    if (!value) {
      return '-'
    }

    if (value instanceof Date) {
      return moment(value).format(DISPLAY_SHORT_DATE_FORMAT)
    }

    return value
  }

  const renderHeader = ({ author, loggedAt, type }) => (
    <React.Fragment>
      <Typography color={NEUTRAL_COLOURS.GRAY}>
        {author?.userName}
        {AUTHOR_TYPE.PARENT === type && (
          <React.Fragment>
            {' ('}
            {i18n.t('module:Children:Child:About:ProfileHistory:parent')}
            {')'}
          </React.Fragment>
        )}
      </Typography>
      <Typography color={NEUTRAL_COLOURS.GRAY}>
        {moment(loggedAt).format('DD MMM YYYY, HH:mm:ss')}
      </Typography>
    </React.Fragment>
  )

  const renderItem = (item) => {
    const { action, data } = item || {}
    const [items, sections] = getFormattedFields(data, {
      drinkingMethods,
      maritalStatuses,
      toiletTrainingOptions,
    })

    if (ACTION_VARIANTS.CREATE === action) {
      return (
        <StyledItem>
          <StyledHeader>
            <Typography color={NEUTRAL_COLOURS.GRAY} bold>
              {i18n.t('module:Children:Child:About:ProfileHistory:childProfileCreated')}
            </Typography>
            {renderHeader(item)}
          </StyledHeader>
        </StyledItem>
      )
    }

    return (
      <StyledItem>
        <StyledHeader>
          <Typography color={NEUTRAL_COLOURS.GRAY} bold>
            {_.map(sections, (section) => (
              _.get(_.find(CHANGELOG_MADE_BY_OPTIONS_OPTIONS, ({ value }) => section === value), 'label')
            )).join(', ')}
          </Typography>
          {renderHeader(item)}
        </StyledHeader>
        <StyledContent>
          {_.map(items, (field = {}) => (
            <StyledField>
              <Typography fontSize={14} bold>
                {field.label}
                {':'}
              </Typography>
              <StyledRightColumn>
                {!field.value ? (
                  <React.Fragment>
                    <StyledValue old>
                      {renderValue(field.oldValue)}
                    </StyledValue>
                    <StyledValue>
                      {renderValue(field.newValue)}
                    </StyledValue>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <StyledValue old={field.value[1]}>
                      {renderValue(field.value[0])}
                    </StyledValue>
                    <div />
                  </React.Fragment>
                )}
                <div />
              </StyledRightColumn>
            </StyledField>
          ))}
        </StyledContent>
      </StyledItem>
    )
  }

  const renderContent = () => {
    if (isFetching && 1 === page) {
      return (
        <Spinner />
      )
    }

    if (!childLogs?.length) {
      return (
        <EmptyState
          icon="children"
          text1={i18n.t('module:Children:Child:About:ProfileHistory:emptyState')}
        />
      )
    }

    return (
      <InfiniteScroll
        dataLength={childLogs?.length || 0}
        hasMore={page < pageCount}
        isFetching={isFetching}
        next={() => onPageChange((+page) + 1)}
      >
        {_.map(childLogs, renderItem)}
      </InfiniteScroll>
    )
  }

  return (
    <Page.Section
      isLoading={isFetching && 1 === page}
      title={i18n.t('module:Children:Routing:Child:Profile:ProfileHistory')}
    >
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <Form.Row
              label={i18n.t('module:Children:Child:About:ProfileHistory:updatedSectionPlaceholder')}
              verticalLabel
            >
              <Select
                options={CHANGELOG_MADE_BY_OPTIONS_OPTIONS}
                placeholder={i18n.t('module:Children:Child:About:ProfileHistory:updatedSectionPlaceholder')}
                value={filters.section}
                width={250}
                onChange={onChangeSection}
              />
            </Form.Row>
          </Toolbar.Item>
          <Toolbar.Item>
            <Form.Row
              label={i18n.t('module:Children:Child:About:ProfileHistory:updatedMadeBy')}
              verticalLabel
            >
              <Select
                options={CHANGELOG_MADE_BY_OPTIONS}
                placeholder={i18n.t('module:Children:Child:About:ProfileHistory:updatedMadeBy')}
                value={filters.madeBy}
                width={200}
                onChange={onChangeMadeBy}
              />
            </Form.Row>
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      {renderContent()}
    </Page.Section>
  )
}

export default ChildProfileHistoryView
