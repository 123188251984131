import styled, { css } from 'styled-components'

import layout from 'constants/layout'

export const StyledContainer = styled.div`
  @media(max-width: 900px) {
    & > div > div {
      flex-direction: column;
    }
  }
`

const commonCss = css`
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 40px 60px auto 48px;
`

export const StyledDraggableBox = styled.div`
  ${commonCss}
  border-radius: ${layout.borderRadius};
  padding: 4px 15px;
  background: #F7F7F7;
  align-items: center;
  min-height: 44px;
  transition: .4s;
`

export const StyledDraggableBoxContainer = styled.div`
  padding-bottom: 8px;
`

export const StyledDragg = styled.div`
  cursor: grab;
  opacity: .4;
`

export const StyledHeader = styled.header`
  ${commonCss}
  padding: 15px;
`

export const StyledContentWrapper = styled.div`
  width: 100%
`
