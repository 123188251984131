import { LIST_SESSIONS, LIST_SESSIONS_FAILED, LIST_SESSIONS_SUCCESS } from './actions'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case LIST_SESSIONS:
      return { ...state, error: initialState.error, isFetching: true }
    case LIST_SESSIONS_SUCCESS:
      return { ...state, data: payload.data, isFetching: false }
    case LIST_SESSIONS_FAILED:
      return { ...state, error, isFetching: false }
    default:
      return state
  }
}
