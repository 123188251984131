import React, { PropsWithChildren } from 'react'

import { Field, FieldError } from 'components'

import { StyledAffix, StyledAutosizeInput, StyledContainer } from './TemplateTextFieldStyled'

export interface TemplateTextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string
  label?: string
  suffix?: string
  valid?: boolean
}

const TemplateTextField: React.FC<PropsWithChildren<TemplateTextFieldProps>> = ({
  disabled,
  error,
  label,
  max,
  min,
  onBlur,
  onChange,
  placeholder,
  prefix,
  step,
  suffix,
  type = 'text',
  valid,
  value,
}) => (
  <React.Fragment>
    {label && <Field.TopLabel hidden={!value} label={label} />}
    <StyledContainer
      $disabled={disabled}
      $valid={valid}
    >
      {prefix ? (
        <StyledAffix $disabled={disabled}>
          {prefix}
        </StyledAffix>
      ) : null}
      <StyledAutosizeInput
        disabled={disabled}
        max={max}
        min={min}
        placeholder={placeholder}
        step={step}
        type={type}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
      />
      {suffix ? (
        <StyledAffix $disabled={disabled}>
          {suffix}
        </StyledAffix>
      ) : null}
    </StyledContainer>
    <FieldError error={error} />
  </React.Fragment>
)

export default TemplateTextField
