import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withDeviceTokensService } from 'services/legacy/deviceTokens'
import { withShellService } from 'services/shell'
import { withRouter } from 'services/router'

import DeviceDetailsView from './DeviceDetailsView'

class DeviceDetailsContainer extends Component {
  componentDidMount() {
    const { deviceTokensActions, params, shellActions } = this.props

    deviceTokensActions.getDeviceToken({
      params: [params.deviceId],
    })
    shellActions.setSettings({
      suppressDeviceWarning: true,
    })
  }

  componentWillUnmount() {
    const { deviceTokensActions } = this.props

    deviceTokensActions.clearSingle()
  }

  render() {
    const { deviceItem, errorMessages, isFetching } = this.props

    return (
      <DeviceDetailsView
        deviceItem={deviceItem}
        errorMessages={errorMessages}
        isFetching={isFetching}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  deviceTokensSelectors,
  deviceTokensSingleState,
}) => ({
  deviceItem: deviceTokensSelectors.getDeviceToken(state),
  errorMessages: appSelectors.getErrorMessages(deviceTokensSingleState),
  isFetching: appSelectors.getIsFetching(deviceTokensSingleState),
})

const enhance = compose(
  withAppService,
  withDeviceTokensService,
  withShellService,
  withRouter,
  connect(mapState),
)

export default enhance(DeviceDetailsContainer)
