import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as contractsSelectors from './selectors'

const withContractsService = (WrappedComponent) => {
  const mapState = ({ contracts }) => ({ contracts })

  const mapDispatch = injectActions('contractsActions', actions)

  const Component = ({ contracts, contractsActions, ...others }) => (
    <WrappedComponent
      contractsActions={contractsActions}
      contractsListState={contracts.list}
      contractsSelectors={contractsSelectors}
      contractsSingleState={contracts.single}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withContractsService
