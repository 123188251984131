import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'recompose'

import { isRequired } from 'utils/fieldValidation'

import { Form } from 'components'

const InvoiceRadioSettingsForm = ({
  globalId,
  handleSubmit,
  hideGlobalOption,
  id,
  isGlobalOptionOn,
  onGlobalOptionClick,
  onSubmit,
  options,
  rightComponent,
}) => (
  <Form flex={1} onSubmit={handleSubmit(onSubmit)}>
    {!hideGlobalOption && (
      <Form.Row>
        <Form.Row.Item>
          <Field
            component={Form.Switch}
            name={globalId}
            onChange={onGlobalOptionClick}
          />
        </Form.Row.Item>
        <Form.Row.Item>
          Use global settings
        </Form.Row.Item>
      </Form.Row>
    )}
    <Form.Row>
      <Form.Row.FlexItem>
        <Field
          component={Form.RadioGroup}
          disabled={isGlobalOptionOn}
          name={id}
          options={options}
          rightComponent={rightComponent}
          validate={isRequired}
          fullWidth
        />
      </Form.Row.FlexItem>
    </Form.Row>
  </Form>
)

const enhance = compose(reduxForm())

export default enhance(InvoiceRadioSettingsForm)
