import { createSelector } from 'reselect'

const getEnquiryReports = (state) => state.enquiryReports

export const getEnquiryReportsConversionRateStatisticsSelector = createSelector(
  [getEnquiryReports],
  (state) => state?.conversionRateStatistics,
)

export const getEnquiryReportsConversionRateStatisticsDataSelector = createSelector(
  [getEnquiryReportsConversionRateStatisticsSelector],
  (state) => state.data,
)
