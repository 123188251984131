import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, getFormValues, stopSubmit } from 'redux-form'
import { compose } from 'recompose'

import { INJURIES, INJURIES_STATUS, INJURY_TYPE_OPTIONS, TAB_NAMES } from 'services/legacy/injuries/constants'
import { ROLES } from 'constants/security'

import { CLEAR_ALL } from 'components/SvgInteractive/SvgInteractive'

import { getBackendErrors } from 'utils/backendErrors'
import { generateRoute } from 'utils/routing'

import { withChildService } from 'services/legacy/child'
import { withInjuriesService } from 'services/legacy/injuries'
import { withModalService } from 'services/utils/modal'
import { withShellService } from 'services/shell'
import { hasOnlySeniorTeacherOrTeacherAccess } from 'services/security/selectors'
import { withRouter } from 'services/router'

import i18n from 'translations'

import { INJURY_BASIC_FORM } from '../components/InjuryBasicForm/InjuryBasicForm'
import { INJURY_CHILD_FORM } from '../components/InjuryChildForm/InjuryChildForm'
import LogInjuryView from './LogInjuryView'

const GROUPS = {
  read: [
    'injury.injurySignatureStatistics',
    'injured.media',
    'medium',
    'injury.injured',
    'injured',
    'injured.medium',
    'injured.child',
    'injured.firstAider',
    'child',
    'child.nurseryClass',
    'nurseryClass',
    'childProfile',
    'childInformation',
    'injury.witnesses',
    'injury.supervisor',
    'injury.senior',
    'injured.accidentDetails',
    'injured.contactPerson',
    'user',
  ],
}

const CHILDREN_GROUPS = {
  read: [
    'childProfile',
    'childInformation',
    'child.nurseryClass',
    'nurseryClass',
  ],
}

const HOME_ACCIDENT_GROUPS = {
  read: [
    'injury.injurySignatureStatistics',
    'injury',
    'injury.injured',
    'injured',
    'injured.media',
    'injured.medium',
    'medium',
    'injured.accidentDetails',
    'user',
    'child',
    'injured.child',
  ],
}

class LogInjuryContainer extends Component {
  constructor(props) {
    super(props)

    const { location, params } = props
    const { pathname } = location
    const { childId } = params

    this.state = {
      activeChildIndex: 0,
      coordinateList: [],
      isChangedCoordinate: false,
      isChildContext: !!childId,
      isHideBodyMap: false,
      isHomeAccidents: pathname.includes(generateRoute('SAFEGUARDING.HOME_ACCIDENTS.LIST')),
      updateSlider: moment().format('x'),
    }
  }

  componentDidMount() {
    const {
      injuriesActions,
      params: { childId, injuryId },
    } = this.props
    const { isHomeAccidents } = this.state

    if (injuryId) {
      const params = {
        groups: isHomeAccidents ? HOME_ACCIDENT_GROUPS : GROUPS,
      }

      injuriesActions.get(injuryId, {
        onSuccess: ({ data }) => {
          const { location: { pathname }, params: { injuryType }, shellActions } = this.props
          const { type } = data

          shellActions.setRouteTitle({
            name: isHomeAccidents ? 'SAFEGUARDING.HOME_ACCIDENTS.SINGLE_EDIT' : 'SAFEGUARDING.INJURY.SINGLE_EDIT',
            title: isHomeAccidents
              ? i18n.t('module:Safeguarding:Add:editTitle')
              : i18n.t('module:Injury:Add:editTitle', { type: type.toLowerCase() }),
          })

          shellActions.setRouteTitle({
            name: 'APPROVALS.INJURY.EDIT',
            title: i18n.t('module:Injury:Add:editTitle', { type: type.toLowerCase() }),
          })

          // ******* Update parent breadcrumbs on refresh *******
          if (generateRoute('APPROVALS.INJURY.PREVIEW', { injuryId, injuryType }) === pathname) {
            return shellActions.setRouteTitle({
              name: 'APPROVALS.INJURY.PREVIEW',
              title: type === INJURIES.INCIDENT
                ? i18n.t('module:Injury:Preview:incidentRecordTitle')
                : i18n.t('module:Injury:Preview:accidentRecordTitle'),
            })
          }

          return shellActions.setRouteTitle({
            name: 'SAFEGUARDING.INJURY.SINGLE_PREVIEW',
            title: type === INJURIES.INCIDENT
              ? i18n.t('module:Injury:Preview:incidentRecordTitle')
              : i18n.t('module:Injury:Preview:accidentRecordTitle'),
          })
          // ********************************************************
        },
        params,
      })
    }

    if (childId && !injuryId) {
      this.injectChild(childId)
    }
  }

  componentWillUnmount() {
    const { childActions, injuriesActions } = this.props

    injuriesActions.clearSingleInjury()
    childActions.clear()
  }

  injectChild = (childId) => {
    const { childActions, injuriesActions, selectedChildren } = this.props

    const childIsNotAdded = !_.find(selectedChildren, ({ id }) => id === +childId)

    if (childIsNotAdded) {
      childActions.get({
        onSuccess: ({ data }) => {
          injuriesActions.addChildrenToInjury([{ child: data }])
        },
        onlyData: true,
        params: [childId, {
          groups: CHILDREN_GROUPS,
        }],
      })
    }
  }

  handleSelectChild = () => {
    const {
      modalActions,
      modalConsts,
      selectedChildren,
    } = this.props

    modalActions.show(modalConsts.TYPES.CHILD_PICKER, {
      childrenGroups: CHILDREN_GROUPS,
      disableSelectAll: true,
      emptyStateText: i18n.t('module:Injury:Add:childPickerEmptyStateText'),
      multiple: true,
      onConfirm: this.handleSelectChildrenSuccess,
      selected: selectedChildren,
    })
  }

  handleSelectChildrenSuccess = (selectedChild) => {
    const { injuriesActions } = this.props

    this.setState({
      activeChildIndex: 0,
    })

    injuriesActions.addChildrenToInjury(_.map(selectedChild, (child) => ({ child })))
  }

  handleClickSlide = (index) => {
    this.setState({ activeChildIndex: index })
  }

  handleRemoveChild = (e, child) => {
    const { injuriesActions } = this.props

    e.stopPropagation()
    injuriesActions.removeChildFromInjury(child)
    this.setState({ activeChildIndex: 0 })
  }

  handleRemoveInjury = () => {
    const {
      injuryPreview: { id, type },
      modalActions,
      modalConsts,
    } = this.props
    const { isHomeAccidents } = this.state

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('global:Yes'),
      icon: 'trash',
      onConfirm: () => this.handleConfirmRemoveInjury(id),
      text: isHomeAccidents
        ? i18n.t('module:Safeguarding:Add:deletePopupCopy')
        : i18n.t('module:Injury:Add:deletePopupCopy', { type: type.toLowerCase() }),
    })
  }

  handleConfirmRemoveInjury = (injury) => {
    const { injuriesActions } = this.props

    injuriesActions.remove(injury, {
      onFailed: this.handleChangeInjuryFailed,
      onSuccess: this.redirectToList,
    })
  }

  handleSubmitAsApproved = () => this.handleSubmit(INJURIES_STATUS.SIGNATURES_NEEDED)

  handleSubmitAsPendingApproval = () => {
    const { injuryPreview, modalActions, modalConsts } = this.props

    if (injuryPreview?.status === INJURIES_STATUS.PENDING_APPROVAL.value) {
      return this.handleSubmit(INJURIES_STATUS.PENDING_APPROVAL)
    }

    const onConfirm = () => this.handleSubmit(INJURIES_STATUS.PENDING_APPROVAL)

    return modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: 'Send',
      onConfirm,
      text: i18n.t('module:Injury:Add:sendPopupCopy', {
        type: i18n.t(`module:Injury:Common:${1 >= injuryPreview?.injured?.length ? 'accident' : 'incident'}`)
          .toLowerCase(),
      }),
    })
  }

  redirectToList = (id, queryString) => {
    const { isChildContext, isHomeAccidents } = this.state
    const { injuriesActions, location, navigate, params } = this.props
    const { pathname } = location
    const { childId } = params

    injuriesActions.clearSingleInjury()

    const navigateHomeAccidents = isChildContext
      ? generateRoute('CHILDREN.CHILD.SAFEGUARDING.HOME_ACCIDENTS.SINGLE_PREVIEW', { childId, injuryId: id })
      : generateRoute('SAFEGUARDING.HOME_ACCIDENTS.SINGLE_PREVIEW', { injuryId: id })

    const navigateAccidentIncident = isChildContext
      ? generateRoute('CHILDREN.CHILD.SAFEGUARDING.INJURY.SINGLE_PREVIEW', { childId, injuryId: id })
      : generateRoute('SAFEGUARDING.INJURY.SINGLE_PREVIEW', { injuryId: id })

    if (id && childId && queryString) {
      return navigate(isHomeAccidents
        ? navigateHomeAccidents + (queryString ? `?${queryString}` : '')
        : navigateAccidentIncident + (queryString ? `?${queryString}` : ''))
    }

    if (id) {
      return navigate(generateRoute(isHomeAccidents
        ? 'SAFEGUARDING.HOME_ACCIDENTS.SINGLE_PREVIEW'
        : 'SAFEGUARDING.INJURY.SINGLE_PREVIEW', {
        injuryId: id,
      }) + (queryString ? `?${queryString}` : ''))
    }

    if (childId) {
      return navigate(generateRoute(isHomeAccidents
        ? 'CHILDREN.CHILD.SAFEGUARDING.HOME_ACCIDENTS'
        : 'CHILDREN.CHILD.SAFEGUARDING.INJURY', { childId }),
      )
    }

    if (pathname === generateRoute('APPROVALS.INJURY.EDIT', { injuryId: params?.injuryId })) {
      return navigate(generateRoute('APPROVALS.INJURY'))
    }

    return navigate(generateRoute(isHomeAccidents ? 'SAFEGUARDING.HOME_ACCIDENTS.LIST' : 'SAFEGUARDING.INJURY.LIST'))
  }

  handleSubmitSuccess = (status) => {
    const {
      formValues,
      injuriesActions,
      injuriesSelectors,
      injuryPreview: { id },
      params,
      selectedChildren,
    } = this.props
    const { coordinateList, isChangedCoordinate, isHomeAccidents } = this.state
    const { childId } = params

    const injury = {
      status: status.value,
    }

    let body = {}
    if (isHomeAccidents) {
      body = injuriesSelectors.getValuesHomeAccidentForm({
        basicForm: formValues(INJURY_BASIC_FORM),
        childId,
        coordinateList,
        editMode: !!id,
        injury,
        isChangedCoordinate,
      })
    } else {
      body = injuriesSelectors.getValuesForm({
        basicForm: formValues(INJURY_BASIC_FORM),
        childrenForms: _.map(selectedChildren, (child) => formValues(`${INJURY_CHILD_FORM}_${child.id}`)),
        editMode: !!id,
        injury,
      })
    }

    if (id) {
      return injuriesActions.update(id, {
        body,
        onFailed: this.handleChangeInjuryFailed,
        onSuccess: (response) => this.handleChangeInjurySuccess({ isEditMode: true, response, status }),
        params: { groups: isHomeAccidents ? HOME_ACCIDENT_GROUPS : GROUPS },
      })
    }

    return injuriesActions.create({
      body,
      onFailed: this.handleChangeInjuryFailed,
      onSuccess: (response) => this.handleChangeInjurySuccess({ isEditMode: false, response, status }),
      params: { groups: isHomeAccidents ? HOME_ACCIDENT_GROUPS : GROUPS },
    })
  }

  handleSubmit = (status) => {
    const { injuryPreview, isDirty, modalActions, modalConsts } = this.props
    const { hasSignatures, id, isChildRemoved } = injuryPreview || {}
    const { isHomeAccidents } = this.state

    const isFormDirty = isDirty || isChildRemoved

    if (id && hasSignatures && isFormDirty) {
      return modalActions.show(modalConsts.TYPES.CONFIRM, {
        icon: 'edit',
        onConfirm: () => this.handleSubmitSuccess(status),
        text: isHomeAccidents
          ? i18n.t('module:Safeguarding:Add:signatureWarningOnEdit')
          : i18n.t('module:Injury:Add:signatureWarningOnEdit'),
      })
    }

    return this.handleSubmitSuccess(status)
  }

  handleChangeInjurySuccess = ({ isEditMode, response, status }) => {
    const { data } = response || {}
    const { id } = data || {}

    if (status === INJURIES_STATUS.APPROVED_AND_SIGNED) {
      return this.redirectToList(id)
    }

    if (!isEditMode && status !== INJURIES_STATUS.DRAFT) {
      const { modalActions, modalConsts } = this.props

      return modalActions.show(modalConsts.TYPES.CONFIRM, {
        cancelButtonLabel: i18n.t('global:later'),
        confirmButtonLabel: i18n.t('global:Now'),
        onCancel: () => this.redirectToList(),
        onConfirm: () => this.redirectToList(id, `tab=${TAB_NAMES.SIGNATURES}`),
        text: i18n.t('module:Injury:Add:collectSignaturePopupCopy'),
      })
    }

    return this.redirectToList()
  }

  handleChangeInjuryFailed = (response = {}) => {
    const { injectValidation, modalActions, modalConsts, selectedChildren } = this.props
    const { isHomeAccidents } = this.state
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    if (errors.injured && _.isString(errors.injured)) {
      modalActions.show(modalConsts.TYPES.ALERT, {
        text: isHomeAccidents
          ? i18n.t('module:Safeguarding:Add:noChildrenError')
          : i18n.t('module:Injury:Add:noChildrenErrorCopy'),
      })
    }

    const { injured, ...basic } = errors
    let slideUpdate = false

    if (!isHomeAccidents && injured) {
      _.each(injured, (child, index) => {
        if (!selectedChildren[index] || !child) {
          return
        }

        if (!slideUpdate && 1 < selectedChildren.length) {
          slideUpdate = true
          this.setState({
            activeChildIndex: index,
            updateSlider: moment().format('x'),
          })
        }

        const { id } = selectedChildren[index]

        injectValidation(`${INJURY_CHILD_FORM}_${id}`, child)
      })
    }

    injectValidation(INJURY_BASIC_FORM, basic)

    if (isHomeAccidents && injured) {
      _.each(injured, (accidentDetails) => {
        _.each(accidentDetails, (accidentDetail) => {
          injectValidation(INJURY_BASIC_FORM, { ...accidentDetail, ...basic })
        })
      })
    }

    return true
  }

  handleChangeMedia = (media, formName) => {
    const { changeFieldValue } = this.props

    changeFieldValue(formName, 'media', media)
  }

  handleCancelClick = () => {
    const { injuryPreview: { id } } = this.props

    this.redirectToList(id)
  }

  handleCoordinates = (coordinate, formName) => {
    const { changeFieldValue } = this.props
    const coordinateList = { coordinateList: coordinate.toString().replaceAll(',', '') === CLEAR_ALL ? [] : coordinate }

    if (formName) {
      changeFieldValue(formName, 'bodyMapCoordinates', coordinateList)
    }

    this.setState(coordinateList)
    this.setState({ isChangedCoordinate: true })
  }

  handleClickHideBodyMap = () => {
    const { isHideBodyMap } = this.state

    this.setState({ isHideBodyMap: !isHideBodyMap })
  }

  render() {
    const {
      authAccessMap,
      basicFormInitialValues,
      childrenFormInitialValues,
      formValues,
      injuryPreview,
      isFetching,
      isGrantedEdit,
      isGrantedSaveAndRequestForApproval,
      isGrantedSaveAsDraft,
      isRemovedInProgress,
      isSubmittingAsApproved,
      isSubmittingAsDraft,
      params: { childId, injuryId },
      selectedChildren,
    } = this.props
    const {
      activeChildIndex,
      coordinateList,
      isChangedCoordinate,
      isHideBodyMap,
      isHomeAccidents,
      updateSlider,
    } = this.state

    const isEditMode = Boolean(injuryId)

    return (
      <LogInjuryView
        activeChildIndex={activeChildIndex}
        authAccessMap={authAccessMap}
        basicFormInitialValues={basicFormInitialValues}
        childId={childId}
        childrenFormInitialValues={childrenFormInitialValues}
        defaultBodyMapCoordinates={coordinateList}
        formValues={formValues}
        injuryPreview={injuryPreview}
        injuryTypeOptions={INJURY_TYPE_OPTIONS}
        isChangedCoordinate={isChangedCoordinate}
        isEditMode={isEditMode}
        isGrantedEdit={isGrantedEdit}
        isGrantedSaveAndRequestForApproval={isGrantedSaveAndRequestForApproval}
        isGrantedSaveAsDraft={isGrantedSaveAsDraft}
        isHideBodyMap={isHideBodyMap}
        isHomeAccidents={isHomeAccidents}
        isLoading={isEditMode && isFetching}
        isRemovedInProgress={isRemovedInProgress}
        isSubmittingAsApproved={isSubmittingAsApproved}
        isSubmittingAsDraft={isSubmittingAsDraft}
        selectedChildren={selectedChildren}
        updateSlider={updateSlider}
        onCancelClick={this.handleCancelClick}
        onChangeMedia={this.handleChangeMedia}
        onClickCoordinates={this.handleCoordinates}
        onClickHideBodyMap={this.handleClickHideBodyMap}
        onClickSlide={this.handleClickSlide}
        onRemoveChild={this.handleRemoveChild}
        onRemoveInjury={this.handleRemoveInjury}
        onSelectChild={this.handleSelectChild}
        onSubmitAsApproved={this.handleSubmitAsApproved}
        onSubmitAsDraft={() => this.handleSubmit(INJURIES_STATUS.DRAFT)}
        onSubmitAsPendingApproval={this.handleSubmitAsPendingApproval}
      />
    )
  }
}

const mapState = (state, {
  injuriesSelectors,
}) => ({
  authAccessMap: {
    section: {
      hasOnlySeniorTeacherOrTeacherAccess: hasOnlySeniorTeacherOrTeacherAccess(state),
    },
  },
  basicFormInitialValues: injuriesSelectors.getInitialValuesForBasicForm(state),
  childrenFormInitialValues: injuriesSelectors.getInitialValuesForChildrenForm(state),
  formValues: (formName) => getFormValues(formName)(state),
  injuryPreview: injuriesSelectors.getPreview(state),
  isDirty: injuriesSelectors.isDirty(INJURY_BASIC_FORM, INJURY_CHILD_FORM)(state),
  isFetching: injuriesSelectors.injuryIsFetching(state),
  isGrantedEdit: injuriesSelectors.isGrantedEdit(state),
  isGrantedSaveAndRequestForApproval: injuriesSelectors.isGrantedSaveAndRequestForApproval(state),
  isGrantedSaveAsDraft: injuriesSelectors.isGrantedSaveAsDraft(state),
  isRemovedInProgress: injuriesSelectors.injuryIsRemovedInProgress(state),
  isSubmittingAsApproved: injuriesSelectors.injuryIsSubmittingAsApproved(state),
  isSubmittingAsDraft: injuriesSelectors.injuryIsSubmittingAsDraft(state),
  selectedChildren: injuriesSelectors.getSelectedChildren(state),
  validationErrorMsg: injuriesSelectors.getValidationErrorMsg(state),
})

const mapDispatch = {
  changeFieldValue: (formName, field, value) => change(formName, field, value),
  injectValidation: (formName, data) => stopSubmit(formName, data),
}

LogInjuryContainer.authParams = {
  roles: [
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.SUPER_ADMIN,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.ROOM_LEADER,
    ROLES.SENIOR_TEACHER,
    ROLES.TEACHER,
  ],
}

const enhance = compose(
  withRouter,
  withChildService,
  withInjuriesService,
  withModalService,
  withShellService,
  connect(mapState, mapDispatch),
)

export default enhance(LogInjuryContainer)
