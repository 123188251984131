import {
  CLEAR_LEAVE_TYPES_LIST,
  LIST_LEAVE_TYPES,
  LIST_LEAVE_TYPES_FAILED,
  LIST_LEAVE_TYPES_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: {},
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case LIST_LEAVE_TYPES:
      return {
        ...state,
        error: initialState.error,
        isFetching: true,
      }
    case LIST_LEAVE_TYPES_SUCCESS:
      return {
        ...state,
        data: payload.data,
        isFetching: false,
        meta: payload.meta,
      }
    case LIST_LEAVE_TYPES_FAILED:
      return {
        ...state,
        error,
        isFetching: false,
      }
    case CLEAR_LEAVE_TYPES_LIST:
      return initialState
    default:
      return state
  }
}
