import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class PeriodTimesApiClient extends ServiceBase {
  list = (params = {}) => this.request({
    path: `/v2/period_times?${getQueryStringV2(params)}`,
  })

  batch = (body) => this.request({
    body,
    method: 'POST',
    path: '/v2/batch/period_time',
  })

  get = (periodId) => this.request({
    path: `/v2/period_times/${periodId}`,
  })

  create = (body) => this.request({
    body,
    method: 'POST',
    path: '/v2/period_times',
  })

  update = (periodId, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/period_times/${periodId}`,
  })

  remove = (periodId) => this.request({
    method: 'DELETE',
    path: `/v2/period_times/${periodId}`,
  })
}

export default PeriodTimesApiClient
