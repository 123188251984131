export const CREATE_NURSERY_FRAMEWORK_RELATION = 'CREATE_NURSERY_FRAMEWORK_RELATION'
export const CREATE_NURSERY_FRAMEWORK_RELATION_SUCCESS = 'CREATE_NURSERY_FRAMEWORK_RELATION_SUCCESS'
export const CREATE_NURSERY_FRAMEWORK_RELATION_FAILED = 'CREATE_NURSERY_FRAMEWORK_RELATION_FAILED'

export const UPDATE_NURSERY_FRAMEWORK_RELATION = 'UPDATE_NURSERY_FRAMEWORK_RELATION'
export const UPDATE_NURSERY_FRAMEWORK_RELATION_SUCCESS = 'UPDATE_NURSERY_FRAMEWORK_RELATION_SUCCESS'
export const UPDATE_NURSERY_FRAMEWORK_RELATION_FAILED = 'UPDATE_NURSERY_FRAMEWORK_RELATION_FAILED'

export const REMOVE_NURSERY_FRAMEWORK_RELATION = 'REMOVE_NURSERY_FRAMEWORK_RELATION'
export const REMOVE_NURSERY_FRAMEWORK_RELATION_SUCCESS = 'REMOVE_NURSERY_FRAMEWORK_RELATION_SUCCESS'
export const REMOVE_NURSERY_FRAMEWORK_RELATION_FAILED = 'REMOVE_NURSERY_FRAMEWORK_RELATION_FAILED'

export const CLEAR_NURSERY_FRAMEWORK_RELATION = 'CLEAR_NURSERY_FRAMEWORK_RELATION'
