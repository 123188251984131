import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { generateRoute } from 'utils/routing'

import { Space, Spinner } from 'components'

import { withAppService } from 'services/app'
import { withMessagingService } from 'services/legacy/messaging'
import { withSecurityService } from 'services/security'
import { withModalService } from 'services/utils/modal'
import { withNurseriesService } from 'services/nurseries'
import { withRouter } from 'services/router'

import i18n from 'translations'

import MessagingListView from './MessagingListView'

const GROUPS_NURSERY_SETTINGS = {
  read: [
    'nursery.settings',
    'nurserySettings.messaging',
  ],
}

class MessagingListContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isMessagingEnabled: false,
      settingsFetching: true,
    }
  }

  componentDidMount() {
    this.fetchNurserySettings()
  }

  isNewChannelPage = () => {
    const { location: { pathname } } = this.props

    return pathname === generateRoute('MESSAGING.CHANNEL_NEW')
  }

  fetchNurserySettings = () => {
    const { nurseriesActions, nurseryOptions } = this.props

    if (!nurseryOptions.id) {
      return null
    }

    return nurseriesActions.get(nurseryOptions.id, {
      onSuccess: (response) => {
        const { data } = response
        const { nurserySettings: { messaging } } = data || {}
        const { enabledForParents } = messaging || {}

        this.setState({
          isMessagingEnabled: !!enabledForParents,
          settingsFetching: false,
        })
      },
      onlyData: true,
      params: { groups: GROUPS_NURSERY_SETTINGS },
    })
  }

  handleShowConversationMembers = (channel, channelMembers) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONVERSATION_MEMBERS, {
      channel,
      channelMembers,
    })
  }

  handleCreateNewChannelSuccess = (response) => {
    const { navigate } = this.props
    const { data: { id } } = response

    navigate(generateRoute('MESSAGING.CHANNEL', { channelId: id }))
  }

  handleCreateNewChannel = () => {
    const { location, messagingActions } = this.props
    const { query: { childId } } = location

    if (!childId) {
      return null
    }

    return messagingActions.createNewChannel({
      body: {
        child: { id: parseInt(childId) },
        type: 'child',
      },
      onSuccess: this.handleCreateNewChannelSuccess,
      params: [],
    })
  }

  handleChildSelect = (child) => {
    const { navigate } = this.props
    const { id, uuid } = child

    navigate(`${generateRoute('MESSAGING.CHANNEL', { channelId: uuid })}?childId=${id}`)
  }

  handleLeaveChannel = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'warning',
      onConfirm: this.handleLeaveChannelAccepted,
      text: i18n.t('module:Messaging:MessagingList:confirmLeaveConversation'),
    })
  }

  handleLeaveChannelFailed = (response) => {
    const { modalActions, modalConsts } = this.props

    if (response?.extra) {
      const messages = _.toArray(response?.extra)

      modalActions.show(modalConsts.TYPES.ALERT, {
        icon: 'warning',
        text: (
          _.map(messages, (message, index) => (
            <React.Fragment>
              {message}
              {index !== messages.length - 1 && (
                <Space space="10px" />
              )}
            </React.Fragment>
          ))
        ),
      })
    }
  }

  handleLeaveChannelAccepted = () => {
    const { messagingActions, params } = this.props
    const { channelId } = params

    messagingActions.leaveChannel({
      body: {
        id: channelId,
        type: 'child',
      },
      onFailed: this.handleLeaveChannelFailed,
      params: [],
    })
  }

  handleUnsubscribe = (nextChannel) => {
    const { router } = this.props

    if (nextChannel?.id) {
      return router.replace(
        generateRoute('MESSAGING.CHANNEL', { channelId: nextChannel.id }),
      )
    }

    return router.replace(generateRoute('MESSAGING.INDEX'))
  }

  render() {
    const { authUser, location, params } = this.props
    const { isMessagingEnabled, settingsFetching } = this.state
    const { channelId } = params

    // NOTE: should be in the MessagingListView but due to heavy re-rendering problem in the messaging
    // it has been moved up
    if (settingsFetching) {
      return (
        <React.Fragment>
          <Space space="20px" />
          <Spinner />
        </React.Fragment>
      )
    }

    return (
      <MessagingListView
        authUser={authUser}
        channelId={channelId}
        isMessagingEnabled={isMessagingEnabled}
        isNewChannelPage={this.isNewChannelPage()}
        location={location}
        onChildSelect={this.handleChildSelect}
        onCreateNewChannel={this.handleCreateNewChannel}
        onLeaveChannel={this.handleLeaveChannel}
        onShowConversationMembers={this.handleShowConversationMembers}
        onUnsubscribe={this.handleUnsubscribe}
      />
    )
  }
}

const mapState = (state, { appSelectors, params, securitySelectors }) => ({
  authUser: securitySelectors.getAuthUser(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const enhance = compose(
  withAppService,
  withMessagingService,
  withModalService,
  withNurseriesService,
  withRouter,
  withSecurityService,
  connect(mapState),
)

export default enhance(MessagingListContainer)
