import React from 'react'
import { connect } from 'react-redux'

import * as actions from './legacyBankDetails/actions'
import * as selectors from './legacyBankDetails/selectors'

export interface withLegacyChildBankDetailsServiceProps {
  legacyChildBankDetailsActions: any
  legacyChildBankDetailsSelectors: any
  legacyChildBankDetailsState: any
}

const withLegacyChildBankDetailsService = (WrappedComponent) => {
  const mapState = ({ child }) => ({ child })

  const mapDispatch = {
    create: (childId, payload, onSuccess) => ({ legacyChildBankDetailsApiClient }) => (
      actions.createChildBankDetails(legacyChildBankDetailsApiClient)(childId, payload, onSuccess)
    ),
    update: (childId, payload, onSuccess) => ({ legacyChildBankDetailsApiClient }) => (
      actions.updateChildBankDetails(legacyChildBankDetailsApiClient)(childId, payload, onSuccess)
    ),
  }

  const Component = (props) => {
    const { child, clear, create, get, update, ...other } = props

    const legacyChildBankDetailsState = child.legacyBankDetails
    const legacyChildBankDetailsActions = { clear, create, get, update }
    const legacyChildBankDetailsSelectors = selectors

    return (
      <WrappedComponent
        legacyChildBankDetailsActions={legacyChildBankDetailsActions}
        legacyChildBankDetailsSelectors={legacyChildBankDetailsSelectors}
        legacyChildBankDetailsState={legacyChildBankDetailsState}
        {...other}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withLegacyChildBankDetailsService
