import React from 'react'

import { StyledContainer } from './ChildSessionsGridStyled'

const ChildSessionsGrid = ({ children }) => (
  <StyledContainer>
    {children}
  </StyledContainer>
)

export default ChildSessionsGrid
