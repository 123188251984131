import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { stopSubmit } from 'redux-form'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withRouter } from 'services/router'

import SettingsInvoiceNumbersItemEditView, { FORM_NAME } from './SettingsInvoiceNumbersItemEditView'

const NURSERY_INVOICE_NUMBER_SETTINGS_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings.invoice',
    'nurseryInvoiceSettings',
    'nurseryInvoiceSettings.numbers',
    'invoiceNumbers',
    'nurseryInvoiceSettings.paymentReminders',
    'nurseryInvoiceSettings.accountCodes',
    'accountCodes',
    'nurseryInvoiceSettings.annualisedInvoiceSettings',
    'annualisedInvoiceSettings',
  ],
}

class SettingsInvoiceNumbersItemEditContainer extends Component {
  componentDidMount() {
    const {
      nurseriesActions,
      params,
    } = this.props
    const { nurseryId } = params

    const nurseryApiParams = { groups: NURSERY_INVOICE_NUMBER_SETTINGS_GROUPS }

    nurseriesActions.get(nurseryId, {
      params: nurseryApiParams,
    })
  }

  componentWillUnmount() {
    const { nurseriesActions } = this.props

    nurseriesActions.clearSingle()
  }

  handleSuccess = () => {
    const { navigate, params } = this.props
    const { nurseryId } = params

    navigate(`/settings/invoice-numbers/${nurseryId}/view`)
  }

  handleFail = (error) => {
    const { appSelectors, stopSubmitForm } = this.props

    const fieldValidations = appSelectors.getFieldValidationFromError(error)

    stopSubmitForm(fieldValidations)
  }

  handleSubmit = (fields) => {
    const {
      nurseriesActions,
      nurseriesSelectors,
      nurseriesSingleState,
      params,
    } = this.props
    const { nurseryId } = params

    const apiParams = { groups: NURSERY_INVOICE_NUMBER_SETTINGS_GROUPS }
    const payload = nurseriesSelectors.getInvoiceNumberSettingsPayload({
      fields,
      nurseriesSingleState,
    })

    nurseriesActions.update(nurseryId, {
      onFailed: this.handleFail,
      onSuccess: this.handleSuccess,
      params: apiParams,
      payload,
    })
  }

  render() {
    const {
      errorMessages,
      initialValues,
      isFetching,
      nurseriesSingleState,
      nurseryName,
      params,
    } = this.props
    const { nurseryId } = params
    const { isSubmitting } = nurseriesSingleState

    return (
      <SettingsInvoiceNumbersItemEditView
        errorMessages={errorMessages}
        initialValues={initialValues}
        isFormLoading={isSubmitting}
        isLoading={isFetching || !initialValues}
        nurseryId={nurseryId}
        nurseryName={nurseryName}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseriesSelectors,
  nurseriesSingleState,
}) => ({
  errorMessages: appSelectors.getErrorMessages(nurseriesSingleState),
  initialValues: nurseriesSelectors.getNurseryInvoiceNumberSettings(state),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState),
  nurseryName: nurseriesSelectors.getNurseryName(state),
})

const mapDispatch = {
  stopSubmitForm: (fieldErrors) => stopSubmit(FORM_NAME, fieldErrors),
}

const enhance = compose(
  withRouter,
  withAppService,
  withNurseriesService,
  connect(mapState, mapDispatch),
)

export default enhance(SettingsInvoiceNumbersItemEditContainer)
