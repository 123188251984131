import _ from 'lodash'
import moment from 'moment'

import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { STAFF_ENTRY_OPTIONS, STAFF_ENTRY_TYPES } from 'services/legacy/membershipRegisters/constants'

import { isRequired } from 'utils/fieldValidation'

import { Form, Typography } from 'components'

import i18n from 'translations'

export const STAFF_WORKLOG_ADD_TIME_MODAL_FORM = 'StaffWorklogAddTimeModalForm'

const AddTimeModalForm = ({
  formValues,
  handleSubmit,
  isProcessing,
  onCalculateTimeRange,
  onCloseClick,
  onSubmit,
  timeRange,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row>
      <Field
        component={Form.RadioGroup}
        name="type"
        options={STAFF_ENTRY_OPTIONS}
        validate={isRequired}
        buttonStyle
        horizontal
      />
    </Form.Row>
    <Form.Row
      label={i18n.t('module:Modals:Staff:Worklog:AddTime:times')}
      verticalLabel
    >
      <Field
        component={Form.TimePicker}
        name="signInAt"
        validate={isRequired}
        onChange={(value) => onCalculateTimeRange(value, null)}
      />
      <Typography margin="9px 10px 0" variant="span">
        {i18n.t('global:to')}
      </Typography>
      <Field
        component={Form.TimePicker}
        name="signOutAt"
        validate={isRequired}
        onChange={(value) => onCalculateTimeRange(null, value)}
      />
      {timeRange ? (
        <Typography margin="9px 0 0 10px" variant="span">
          {moment.utc(timeRange).format('H')}
          {`${i18n.t('global:hourShortcut')} `}
          {moment.utc(timeRange).format('m')}
          {i18n.t('global:minuteShortcut')}
        </Typography>
      ) : null}
    </Form.Row>
    <Form.Row
      label={_.upperFirst(i18n.t('global:location'))}
      verticalLabel
    >
      <Field
        component={Form.InfiniteDropdowns.Rooms}
        name="nurseryClass"
        validate={formValues && formValues.type === STAFF_ENTRY_TYPES.SIGN_IN && isRequired}
        allRoomTypes
      />
    </Form.Row>
    <Form.FooterActions
      isSubmitting={isProcessing}
      submitLabel={i18n.t('module:Modals:Staff:Worklog:AddTime:addActivity')}
      onCancelClick={onCloseClick}
    />
  </Form>
)

export default reduxForm({ form: STAFF_WORKLOG_ADD_TIME_MODAL_FORM })(AddTimeModalForm)
