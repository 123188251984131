import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { StyledInputWrapper, StyledWrapper } from 'components/DatePicker/DatePickerStyled'
import { StyledScrollContent } from 'components/ModalBox/ModalBoxStyled'

export const StyledDatePickerWrapper = styled.div`
  ${StyledWrapper} {
    background: transparent;
  }
  
  ${StyledInputWrapper} {
    background: ${NEUTRAL_COLOURS.WHITE};
  }
`

export const StyledFormWrapper = styled.div`
  height: 100%;

  form {
    height: 100%;
    display: flex;
    flex-direction: column;

    ${StyledScrollContent} {
      flex: 1;
    }
  } 
`
