import { generateBasicActions } from 'utils/service'

import {
  CHILD_PRODUCTS_DROPDOWN_LIST,
  CHILD_PRODUCTS_DROPDOWN_LIST_FAILED,
  CHILD_PRODUCTS_DROPDOWN_LIST_SUCCESS,
  CLEAR_CHILD_PRODUCTS_DROPDOWN_LIST,
  GET_CHILD_PRODUCTS_DROPDOWN_ITEM,
  GET_CHILD_PRODUCTS_DROPDOWN_ITEM_FAILED,
  GET_CHILD_PRODUCTS_DROPDOWN_ITEM_SUCCESS,
} from './constants'

export const dropdownList = (options = {}) => ({ childProductsApiClient }) => (
  generateBasicActions.list({
    constants: {
      failed: CHILD_PRODUCTS_DROPDOWN_LIST_FAILED,
      init: CHILD_PRODUCTS_DROPDOWN_LIST,
      success: CHILD_PRODUCTS_DROPDOWN_LIST_SUCCESS,
    },
    options,
    service: childProductsApiClient,
    serviceMethod: 'list',
  })
)

export const getDropdownItem = (options = {}) => ({ childProductsApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: GET_CHILD_PRODUCTS_DROPDOWN_ITEM_FAILED,
      init: GET_CHILD_PRODUCTS_DROPDOWN_ITEM,
      success: GET_CHILD_PRODUCTS_DROPDOWN_ITEM_SUCCESS,
    },
    options,
    service: childProductsApiClient,
    serviceMethod: 'get',
  })
)

export const clearDropdownList = () => ({
  type: CLEAR_CHILD_PRODUCTS_DROPDOWN_LIST,
})
