import {
  CLEAR_LIST_CHILD_DAILY_DIARY,
  LIST_CHILD_DAILY_DIARY,
  LIST_CHILD_DAILY_DIARY_FAILED,
  LIST_CHILD_DAILY_DIARY_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: null,
}

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case LIST_CHILD_DAILY_DIARY:
      return { ...state, error: initialState.error, isFetching: true }
    case LIST_CHILD_DAILY_DIARY_SUCCESS:
      return { ...state, data: payload.data, isFetching: false, meta: payload.meta }
    case LIST_CHILD_DAILY_DIARY_FAILED:
      return { ...state, error: payload.error, isFetching: false }
    case CLEAR_LIST_CHILD_DAILY_DIARY:
      return initialState
    default:
      return state
  }
}
