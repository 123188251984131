import moment from 'moment'

import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isPositiveNumber, isRequired } from 'utils/fieldValidation'

import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

import { Avatar, CircleIcon, Currency, Form, Tooltip } from 'components'

import i18n from 'translations'

import { StyledGrayBox } from '../BalanceAdjustmentModalStyled'
import FormSwitchDebitCredit from './FormSwitchDebitCredit'

export const BALANCE_ADJUSTMENT_FORM = 'BalanceAdjustmentForm'

export const isPositiveNumberLocal = isPositiveNumber(2)

const BalanceAdjustmentForm = ({
  authUser,
  balance,
  handleSubmit,
  isSubmitting,
  newBalance,
  onCloseClick,
  onSubmit,
}) => {
  const { fullName, name, photo } = authUser

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Row
        label="Adjustment by"
      >
        <Avatar
          avatarSize="medium"
          initials={[name]}
          src={photo}
          title={`${fullName} on ${moment().format('DD/MM/YYYY')}`}
        />
      </Form.Row>
      <Form.Row>
        <Form.Row.Item>
          Please enter the amount you wish to increase or decrease the total balance by.
        </Form.Row.Item>
        <Form.Row.Item>
          <Tooltip
            placement="right"
            title={'You will only be able to enter positive values. '.concat(
              'A debit will increase the balance and a credit will decrease the balance',
            )}
          >
            <CircleIcon
              cursor="pointer"
              icon="info"
              iconSize={16}
              size={24}
            />
          </Tooltip>
        </Form.Row.Item>
      </Form.Row>
      <Form.Row label="Current balance">
        <Form.Row.FlexItem flex="0 0 120px">
          <StyledGrayBox value={balance}>
            <Currency value={balance} showDebitCredit />
          </StyledGrayBox>
        </Form.Row.FlexItem>
      </Form.Row>
      <SubdomainCurrencyProvider>
        {({ getFormattedCurrencyValue }) => (
          <Form.Row label="Amount" required>
            <Form.Row.FlexItem flex="0 0 150px">
              <Field
                component={Form.TextField}
                name="total"
                placeholder={getFormattedCurrencyValue(0.00)}
                step="any"
                type="number"
                validate={[isRequired, isPositiveNumberLocal]}
              />
            </Form.Row.FlexItem>
            <Form.Row.Item>
              <Field
                component={FormSwitchDebitCredit}
                name="isCredit"
              />
            </Form.Row.Item>
          </Form.Row>
        )}
      </SubdomainCurrencyProvider>
      <Form.Row label="New balance">
        <Form.Row.FlexItem flex="0 0 120px">
          <StyledGrayBox value={newBalance}>
            <Currency value={newBalance} showDebitCredit />
          </StyledGrayBox>
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label="Comment"
      >
        <Form.Row.FlexItem flex="0 0 400px">
          <Field
            component={Form.TextAreaField}
            name="comment"
            placeholder="Enter comment"
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.FooterActions
        isSubmitting={isSubmitting}
        submitLabel={i18n.t('global:Confirm')}
        onCancelClick={onCloseClick}
      />
    </Form>
  )
}

export default reduxForm({ form: BALANCE_ADJUSTMENT_FORM })(BalanceAdjustmentForm)
