import DeviceIdentityService from 'core/services/DeviceIdentityService'

import { CHANGE_DEVICE_IDENTITY, CHANGE_DEVICE_REAL_IDENTITY } from './constants'

export const changeDeviceIdentity = (payload) => {
  DeviceIdentityService.setIdentity(payload)

  return ({
    payload,
    type: CHANGE_DEVICE_IDENTITY,
  })
}

export const changeDeviceRealIdentity = (payload) => {
  DeviceIdentityService.setRealIdentity(payload)

  return ({
    payload,
    type: CHANGE_DEVICE_REAL_IDENTITY,
  })
}
