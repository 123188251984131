import { update } from 'idb-keyval'

import { Dispatch, Middleware, MiddlewareAPI } from 'redux'

import JsonBigint from 'json-bigint'

import { STORE_KEY_NAME_IN_INDEX_DB } from 'constants/global'

const SyncWithIndexDBMiddleware = (store: MiddlewareAPI) => (next: Dispatch) => (action): Middleware => {
  const result = next(action)
  const state = store.getState()

  update(STORE_KEY_NAME_IN_INDEX_DB, () => JsonBigint.stringify(state)).then()

  return result
}

export default SyncWithIndexDBMiddleware
