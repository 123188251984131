import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'
import { convertTimeDuration, millisecondsToHoursAndMinutes } from 'utils/date'

import { BREAK_ALLOWANCE_OPTIONS, CONTRACT_TYPE_OPTIONS, OVERTIME_TREATMENT_OPTIONS } from '../constants'

const getContractSelector = (state) => state.contracts.single

export const getContractDataSelector = createSelector([getContractSelector], (state) => state.data)

export const getInitialValues = createSelector(
  [getContractDataSelector],
  (data) => {
    if (!data) {
      return ({
        endDate: null,
        startDate: null,
        type: CONTRACT_TYPE_OPTIONS[0],
      })
    }

    const {
      breakAllowances,
      contractedHours,
      endDate,
      ongoing,
      overtimeTreatment,
      startDate,
      type,
    } = data

    return ({
      ...data,
      breakAllowances: _.map(breakAllowances, ({ time, type: allowanceType, ...rest }) => ({
        ...rest,
        time: convertTimeDuration(+time, 'milliseconds', 'minutes'),
        type: _.find(BREAK_ALLOWANCE_OPTIONS, { value: allowanceType }),
      })),
      contractedHours: millisecondsToHoursAndMinutes(contractedHours),
      endDate: endDate ? moment(endDate) : null,
      ongoing,
      overtimeTreatment: _.find(OVERTIME_TREATMENT_OPTIONS, { value: overtimeTreatment }),
      startDate: startDate ? moment(startDate) : null,
      type: _.find(CONTRACT_TYPE_OPTIONS, { value: type }),
    })
  },
)

export const getPayload = (fields, membershipId) => {
  if (!fields) {
    return null
  }

  const {
    breakAllowances,
    contractedHours,
    endDate,
    hourlySalary,
    ongoing,
    overtimeTreatment,
    salary,
    startDate,
    type,
  } = fields

  const endDateValue = moment(endDate).isValid() ? moment(endDate).format(DEFAULT_DATE_FORMAT) : undefined

  return {
    ...fields,
    breakAllowances: _.map(breakAllowances, ({ time, type: allowanceType, ...rest }) => ({
      ...rest,
      time: convertTimeDuration(+time),
      type: allowanceType.value,
    })),
    contractedHours: contractedHours && convertTimeDuration(contractedHours, null, 'milliseconds'),
    endDate: ongoing ? null : endDateValue,
    hourlySalary: hourlySalary && Number(hourlySalary),
    membership: membershipId ? { id: +membershipId } : undefined,
    overtimeTreatment: overtimeTreatment?.value,
    salary: salary && Number(salary),
    startDate: moment(startDate).isValid() ? moment(startDate).format(DEFAULT_DATE_FORMAT) : undefined,
    type: type?.value,
  }
}

export const getYearlySalary = createSelector(
  [getContractDataSelector],
  (data) => data?.salary,
)

export const getSalaryPerMonth = (salary) => (salary || 0) / 12

export const getIsGrantedEdit = (state) => auth.SELECTORS.getIsAuthorised(state, {
  flags: [FEATURE_FLAGS.STAFF_REGISTER],
  nurseryContext: true,
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
})
