import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as dailyDiaryToiletTrainingSelectors from './selectors'

const withDailyDiaryToiletTrainingService = (WrappedComponent) => {
  const mapState = ({ dailyDiaryToiletTraining }) => ({ dailyDiaryToiletTraining })

  const mapDispatch = injectActions('dailyDiaryToiletTrainingActions', actions)

  const Component = ({ dailyDiaryToiletTraining, dailyDiaryToiletTrainingActions, ...others }) => (
    <WrappedComponent
      dailyDiaryToiletTrainingActions={dailyDiaryToiletTrainingActions}
      dailyDiaryToiletTrainingListState={dailyDiaryToiletTraining.list}
      dailyDiaryToiletTrainingListWithoutRecordsState={dailyDiaryToiletTraining.listWithoutRecords}
      dailyDiaryToiletTrainingSelectors={dailyDiaryToiletTrainingSelectors}
      dailyDiaryToiletTrainingSingleState={dailyDiaryToiletTraining.single}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withDailyDiaryToiletTrainingService
