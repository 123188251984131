import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { StyledAccordion } from 'components/Accordion/AccordionStyled'
import { StyledItemContainer } from 'components/legacy/Form/fields/FormRadioGroup/FormRadioGroupStyled'

export const StyledRadioWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 20px;
  grid-gap: 20px;
  background: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  padding: 12px 16px;
  border-radius: 4px;
  min-height: 50px;
  align-items: center;
  margin-bottom: -4px;
  
  ${({ withLastProgress }) => withLastProgress && `
    grid-template-columns: auto 95px 20px;
  `}
  
  @media(max-width: 480px) {
    grid-gap: 10px;
    grid-template-columns: auto 20px;
    grid-template-rows: auto auto;
  }
`

export const StyledLastProgress = styled.div`
  background: ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  border-radius: 12px;
  padding: 7px 12px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  
  @media(max-width: 480px) {
    order: 3;
    grid-column-start: 1;
    grid-column-end: 3;
  }
`

export const StyledWrapper = styled.div`
  flex-direction: column;
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  
  ${({ noDescription }) => noDescription && `
    grid-template-rows: auto 1fr auto;
  `}
  
  ${StyledAccordion} {
    margin-bottom: 0;
  }
`

export const StyledRadioGroupWrapper = styled.div`
  ${StyledItemContainer} {
    margin-bottom: 8px;
  }
`
