import React, { Component } from 'react'

import { Page } from 'components'

class LogTicketForm extends Component {
  constructor() {
    super()

    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'

    this.state = {
      script,
    }
  }

  componentDidMount() {
    const { script } = this.state

    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          formId: '484b73aa-65b8-45c3-84b5-aaff97a2fceb',
          portalId: '2694700',
          target: '#standalone-form',
        })
      }
    })
  }

  componentWillUnmount() {
    const { script } = this.state

    document.body.removeChild(script)
  }

  render() {
    return (
      <Page>
        <Page.Content>
          <div id="standalone-form" />
        </Page.Content>
      </Page>
    )
  }
}

export default LogTicketForm
