import React from 'react'

import { BasicViewProps } from 'constants/models'

import { Page, Spinner } from 'components'

import i18n from 'translations'
import PractitionerChildAccessForm, { PractitionerChildAccessFormVales } from './components/PractitionerChildAccessForm'

export interface PractitionerChildAccessViewProps extends BasicViewProps {
  initialValues: PractitionerChildAccessFormVales
  isFetching?: boolean
  isRoomFetching?: boolean
  isSubmitting?: boolean
  onAccessViewTypeChange?: (value: string) => void
  onRoomListReload?: () => void
  rooms?: any
}

const PractitionerChildAccessView: React.FC<PractitionerChildAccessViewProps> = ({
  initialValues,
  isFetching,
  isRoomFetching,
  isSubmitting,
  onAccessViewTypeChange,
  onRoomListReload,
  rooms,
}) => {
  const renderContent = () => {
    if (isFetching && isRoomFetching) {
      return <Spinner />
    }

    return (
      <PractitionerChildAccessForm
        initialValues={initialValues}
        isRoomFetching={isRoomFetching}
        isSubmitting={isSubmitting}
        rooms={rooms}
        onAccessViewTypeChange={onAccessViewTypeChange}
        onRoomListReload={onRoomListReload}
      />
    )
  }

  return (
    <Page.Section title={i18n.t('module:Management:Security:PractitionerChildAccess:title')}>
      {renderContent()}
    </Page.Section>
  )
}

export default PractitionerChildAccessView
