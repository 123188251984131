import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import {
  CLEAR_LIST_ASSESSMENT_TRACKER,
  LIST_ASSESSMENT_TRACKER,
  LIST_ASSESSMENT_TRACKER_FAILED,
  LIST_ASSESSMENT_TRACKER_SUCCESS,
} from './constants'

const initialState = getBasicListInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_LIST_ASSESSMENT_TRACKER,
    failed: LIST_ASSESSMENT_TRACKER_FAILED,
    init: LIST_ASSESSMENT_TRACKER,
    success: LIST_ASSESSMENT_TRACKER_SUCCESS,
  }, initialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
