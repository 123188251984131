import styled from 'styled-components'

import layout from 'constants/layout'

export const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
  
  @media(max-width: ${layout.mobileWidthResolution}px) {
    grid-template-columns: auto;
  }
`

export const StyledItemContainer = styled.div`
  overflow: hidden;
`

export const StyledPrimaryValue = styled.p`
  margin: 5px 0;
`
