import React, { PropsWithChildren } from 'react'

import { StyledAutosizeInput, StyledInput, StyledSizer } from './AutosizeInputStyled'

interface AutosizeInputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const AutosizeInput: React.FC<PropsWithChildren<AutosizeInputProps>> = ({
  disabled,
  max,
  min,
  onBlur,
  onChange,
  placeholder,
  step,
  type,
  value,
}) => (
  <StyledAutosizeInput>
    <StyledInput
      disabled={disabled}
      max={max}
      min={min}
      placeholder={placeholder}
      step={step}
      type={type}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
    />
    <StyledSizer>
      {value || placeholder}
    </StyledSizer>
  </StyledAutosizeInput>
)

export default AutosizeInput
