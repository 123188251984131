import i18n from 'translations'

export const DAILY_DIARY_DATE_FORMAT = 'YYYY-MM-DD'

export const DAILY_DIARY_LIST_GROUPS = {
  read: [
    'childRegister',
    'childRegister.child',
    'child',
    'child.profile',
    'child.nurseryClass',
    'childRegisterDiaryRecordsResult.diaryRecords',
    'childRegisterDiaryRecordsResult.diaryRecords.items',
    'childRegisterDiaryRecordsResult.childRegister',
  ],
}

export const TIME_TYPE = {
  CURRENTLY_TIMED_IN: 'CURRENTLY_TIMED_IN',
  TIMED_IN_FOR_THE_DAY: 'TIMED_IN_FOR_THE_DAY',
}

export const TIME_OPTIONS = [
  {
    label: i18n.t('services:DailyDiary:Filters:Time:TimedInForDay:label'),
    value: TIME_TYPE.TIMED_IN_FOR_THE_DAY,
  },
  {
    label: i18n.t('services:DailyDiary:Filters:Time:CurrentlyTimedIn:label'),
    value: TIME_TYPE.CURRENTLY_TIMED_IN,
  },
]
