import { mergeData } from 'utils/data'

import {
  CLEAR_CHILD_REGISTERS,
  GET_CHILD_REGISTERS,
  GET_CHILD_REGISTERS_FAILED,
  GET_CHILD_REGISTERS_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: {},
}

export default (state = initialState, { error, mergeResult, payload, type }) => {
  switch (type) {
    case GET_CHILD_REGISTERS:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_CHILD_REGISTERS_SUCCESS:
      return {
        ...state,
        data: mergeData(state.data, payload.data, mergeResult),
        isFetching: false,
        meta: payload.meta,
      }
    case GET_CHILD_REGISTERS_FAILED:
      return { ...state, error, isFetching: false }
    case CLEAR_CHILD_REGISTERS:
      return initialState
    default:
      return state
  }
}
