import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'
import selectors from './selectors'
import { NurseryItemListState } from './models'

export interface withNurseryItemServiceProps {
  nurseryItemActions: typeof actions
  nurseryItemListState: NurseryItemListState
  nurseryItemSelectors: typeof selectors
}

const withNurseryItemService = <P extends withNurseryItemServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ nurseryItem }) => ({ nurseryItem })

  const mapDispatch = injectActions('nurseryItemActions', actions)

  const Component = ({ nurseryItem, nurseryItemActions, ...other }) => (
    <WrappedComponent
      nurseryItemActions={nurseryItemActions}
      nurseryItemListState={nurseryItem.list}
      nurseryItemSelectors={selectors}
      nurseryItemSingleState={nurseryItem.single}
      {...other as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withNurseryItemService
