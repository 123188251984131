import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledFieldWrapper = styled.div`
  margin-bottom: 40px;
  padding-bottom: 25px;
  border-bottom: 1px dashed ${NEUTRAL_COLOURS.SILVER};
`

export const StyledRoomsList = styled.div`
  display: grid;
  flex-direction: row;
  grid-row-gap: 25px;
  margin-top: 20px;
`

export const StyledRoomRow = styled.div`
  display: grid;  
  grid-row-gap: 10px;
`

export const StyledAction = styled.div`
  display: flex;
  justify-content: flex-end;
`
