import React from 'react'

import { generateRoute } from 'utils/routing'

import { Page, Section, Spinner } from 'components'

import i18n from 'translations'

import { ChildPreview } from '../../components'

const ChildProfileView = ({
  child = {},
  drinkingMethods,
  isLoading,
  maritalStatuses,
  permissionToEdit,
  permissionToProfileHistory,
  toiletTrainingOptions,
}) => {
  const renderContent = () => {
    if (isLoading || !child) {
      return <Spinner />
    }

    return (
      <ChildPreview
        child={child}
        drinkingMethods={drinkingMethods}
        maritalStatuses={maritalStatuses}
        permissionToEdit={permissionToEdit}
        toiletTrainingOptions={toiletTrainingOptions}
      />
    )
  }
  return (
    <Page.Section
      actions={(
        <Section.Actions
          options={[{
            auth: permissionToProfileHistory,
            icon: 'profile',
            label: i18n.t('module:Children:Routing:Child:Profile:ProfileHistory'),
            to: generateRoute('CHILDREN.CHILD.ABOUT.PROFILE_HISTORY', { childId: child.id }),
          }]}
        />
      )}
      isLoading={isLoading || !child}
      title={i18n.t('module:Children:Routing:Child:Profile:Profile')}
    >
      {renderContent()}
    </Page.Section>
  )
}

export default ChildProfileView
