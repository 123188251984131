import { createSelector } from 'reselect'

import { ChildrenSignedUpReportRootState } from '../models'

const getChildrenSignedUpReport = (state: ChildrenSignedUpReportRootState) => state.childrenSignedUpReport

export const getChildrenSignedUpReportStatistics = createSelector(
  [getChildrenSignedUpReport],
  (state) => state.statistics,
)

export const getChildrenSignedUpReportStatisticsData = createSelector(
  [getChildrenSignedUpReportStatistics],
  (state) => state.data,
)
