import React from 'react'

import { TimeString } from 'components'

import i18n from 'translations'

import RegisterItemMainActions from '../RegisterItemMainActions'
import RegisterItemAction from '../RegisterItemAction'

import { REGISTER_COLORS } from '../../../constants'

const RegisterItemContentSignin = ({
  index,
  isFutureDate,
  isSignInLoading,
  isSignOutLoading,
  onSignIn,
  onSignOut,
  signIn,
  signInsIndex,
  size,
}) => {
  const { id, signedInAt, signedOutAt } = signIn

  const signedInTime = signedInAt ? <TimeString date={signedInAt} /> : null
  const signedOutTime = signedOutAt ? <TimeString date={signedOutAt} /> : null

  return (
    <RegisterItemMainActions.ItemWrapper key={id}>
      <RegisterItemMainActions.Item size={size}>
        <RegisterItemAction
          color={REGISTER_COLORS.timeIn}
          disabled={isFutureDate}
          hasData={!!signedInTime}
          icon="check"
          isLoading={isSignInLoading && signInsIndex === index}
          time={signedInTime}
          title="Time in"
          tooltip={!signedInTime && isFutureDate && i18n.t('module:Register:futureDateTimeInTooltip')}
          onClick={onSignIn(index)}
        />
      </RegisterItemMainActions.Item>
      {signedInTime && (
        <RegisterItemMainActions.Item size={size}>
          <RegisterItemAction
            color={REGISTER_COLORS.timeOut}
            disabled={isFutureDate}
            hasData={!!signedOutTime}
            icon="close"
            isLoading={isSignOutLoading && signInsIndex === index}
            time={signedOutTime}
            title="Time out"
            tooltip={!signedOutTime && isFutureDate && i18n.t('module:Register:futureDateTimeOutTooltip')}
            onClick={onSignOut(signIn, index)}
          />
        </RegisterItemMainActions.Item>
      )}
    </RegisterItemMainActions.ItemWrapper>
  )
}

export default RegisterItemContentSignin
