import color from 'color'
import styled from 'styled-components'

import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'

import { StyledCallout } from 'components/Callout/CalloutStyled'
import { StyledInputWrapper } from 'components/DatePicker/DatePickerStyled'

import { SHIFT_MODAL_MODE } from '../../ShiftModalContainer'

export const StyledRow = styled.div`
  margin-left: -2px;
  margin-top: -2px;

  .Select__control--is-focused {
    position: relative;
    z-index: 20;
  }

  ${({ border }) => border && `
    height: 44px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    border: 2px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  `}
  
  ${({ yellow }) => yellow && `
    background: ${color(FLAG_COLOURS.WARNING).mix(color(NEUTRAL_COLOURS.WHITE), 0.8).rgb().string()};
  `}
  
  ${({ center }) => center && `
    display: flex;
    justify-content: center;
  `}
  
  ${({ colspan }) => colspan && `
    grid-column: span ${colspan};
  `}

  ${StyledInputWrapper} {
    border-bottom: 0;
  }
  
  .Select__control {
    width: auto !important;
    min-width: 0;
  }
`

export const StyledDay = styled.div`
  background: #FAFAFA;
  border: 2px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  padding: 10px;
  margin-left: -2px;
  margin-top: -2px;
  
  ${({ white }) => white && `
    background: ${NEUTRAL_COLOURS.WHITE};
  `}    
  
  ${({ noPadding }) => noPadding && `
    padding: 0;
  `}  
  
  ${({ noBorder }) => noBorder && `
    border-top: none;
  `}
`

export const StyledCustomTableWrapper = styled.div`
  @media(max-width: 820px) {
    overflow: auto;
    padding-left: 2px;
    max-width: 89vw;
  }
`

export const StyledCustomTable = styled.div`
  margin-bottom: 7px;
  min-width: 740px;
`

export const StyledRowWrapper = styled.div`
  display: grid;
  grid-template-areas: 'day activity location start end ratio hours actions';
  grid-template-columns: 100px 180px 180px 88px 88px 150px auto 40px;
  
  ${({ mode }) => mode === SHIFT_MODAL_MODE.ACTUAL && `
    grid-template-areas: 'day activity location start end hours actions';
    grid-template-columns: 100px 180px 180px 88px 88px auto 40px;
  `}  
  
  ${({ endDateVersion }) => endDateVersion && `
    grid-template-areas: 'day activity empty';
    grid-template-columns: 100px 180px 180px auto;
  `}
`

export const StyledRowExtraWrapper = styled.div`
  display: grid;
  grid-template-areas: 'day content';
  grid-template-columns: 100px 1fr;
`

export const StyledRowHeader = styled.div`
  padding: 10px;
  height: 45px;
  display: flex;
  align-items: center;
    
  ${({ center }) => center && `
    width: 100%;
    justify-content: center;
  `}

  ${({ colspan }) => colspan && `
    grid-column: span ${colspan};
  `}
`

export const StyledTooltipContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
`

export const StyledRenderStatisticsGrid = styled.div`
  display: grid;
  grid-template-areas: 'activity location start end hours';
  grid-template-columns: 180px 180px 88px 88px 260px;
  
  ${({ mode }) => mode === SHIFT_MODAL_MODE.ACTUAL && `
    grid-template-areas: 'activity location start end ratio hours';
    grid-template-columns: 180px 180px 88px 88px 150px 110px;
  `}
`

export const StyledStatisticRow = styled.div`
  padding: 2px 8px;
  color: ${NEUTRAL_COLOURS.GRAY};
`

export const StyledEnd = styled.div`
  border-top: 2px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  & > div {
    margin-top: -10px;
  }
`

export const StyledCharWrapper = styled.div`
  ${({ showChar }) => showChar && `
    .rc-time-picker:after {
      content: '*';
      display: block;
      font-weight: 600;
      color: ${FLAG_COLOURS.ERROR};
      position: absolute;
      top: 10px;
      right: 10px;
    }
  `}
`

export const StyledCustomBanner = styled.div`
  ${StyledCallout} {
    border-radius: 0;
    margin-bottom: 0;
    border-left: 0;
    
    .webfont {
      font-size: 13px;
      margin-left: 1px;
      margin-top: -2px;
    }
  }
`
