import React from 'react'

import { FLAG_COLOURS } from 'constants/colors'

import { Callout } from 'components'

const BannerInfo = ({ children, ...rest }) => (
  <Callout
    {...rest}
    color={FLAG_COLOURS.INFO}
    content={children}
    icon="info"
    iconVariant="circle"
  />
)

export default BannerInfo
