import _ from 'lodash'

import React, { useCallback } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { DEFAULT_CURRENCY, DEFAULT_LOCALE } from 'constants/locale'
import { WrappedComponentType } from 'constants/types'

import { withShellService, withShellServiceProps } from 'services/shell'

export interface CurrencyUtilsProps {
  getCurrencySymbol: () => string
  getNumberParts: (numberValue: number, customCurrency?: string) => Intl.NumberFormatPart[]
}

export interface WithCurrencyUtilsProps {
  currencyUtils: CurrencyUtilsProps
  subdomainCurrency: string
  subdomainLocale: string
}

const mapState = (state, { shellSelectors }) => ({
  subdomainCurrency: shellSelectors.getSubdomainCurrency(state),
  subdomainLocale: shellSelectors.getSubdomainLocale(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type withCurrencyUtilsFinalProps = WithCurrencyUtilsProps
  & withShellServiceProps
  & PropsFromRedux

const withCurrency = <P extends withCurrencyUtilsFinalProps> (
  WrappedComponent: WrappedComponentType<P>,
) => {
  const Component: WrappedComponentType<P & withCurrencyUtilsFinalProps> = ({
    subdomainCurrency,
    subdomainLocale,
    ...others
  }) => {
    const getCurrencySymbol = useCallback((customCurrency: string) => {
      const finalCurrency = customCurrency || subdomainCurrency || DEFAULT_CURRENCY
      const finalLocale = (subdomainLocale || DEFAULT_LOCALE)?.replace('_', '-')

      const formatter = new Intl.NumberFormat(finalLocale, {
        currency: finalCurrency,
        style: 'currency',
      })

      return _.find(formatter.formatToParts(0), { type: 'currency' })?.value
    }, [subdomainCurrency, subdomainLocale])

    const getNumberParts = useCallback((numberValue: number, customCurrency?: string): Intl.NumberFormatPart[] => {
      const finalCurrency = customCurrency || subdomainCurrency || DEFAULT_CURRENCY
      const finalLocale = (subdomainLocale || DEFAULT_LOCALE)?.replace('_', '-')

      const formatter = new Intl.NumberFormat(finalLocale, {
        currency: finalCurrency,
        style: 'currency',
      })

      return formatter.formatToParts(Number(numberValue))
    }, [subdomainCurrency, subdomainLocale])

    const currencyUtils = {
      getCurrencySymbol,
      getNumberParts,
    }

    return (
      <WrappedComponent
        currencyUtils={currencyUtils}
        subdomainCurrency={subdomainCurrency}
        subdomainLocale={subdomainLocale}
        {...others as P}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  const enhance = compose(
    withShellService,
    connect(mapState),
  )

  return enhance(Component)
}

export default withCurrency
