import { FEATURE_FLAGS, ROLES } from 'constants/security'

const OccupancyWrapperContainer = ({ children }) => children

OccupancyWrapperContainer.authParams = {
  flags: [FEATURE_FLAGS.OCCUPANCY_ENABLED],
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

export default OccupancyWrapperContainer
