import _ from 'lodash'

import { QueryPropsV1, QueryPropsV2 } from 'constants/service'

export const getQueryString = ({
  archived,
  criteria,
  extra,
  from,
  includes,
  limit,
  order,
  start,
  to,
}: QueryPropsV1 = {}) => {
  const parts = []

  if (includes && 0 < includes.length) {
    parts.push(`filter[include]=${includes.join(',')}`)
  }

  if (criteria) {
    _.forEach(criteria, ({ comparator = 'eq', field, value }) => {
      parts.push(`filter[where][${field}][${comparator}]=${encodeURIComponent(value)}`)
    })
  }

  if (order) {
    if (Array.isArray(order)) {
      _.each(order, (item) => {
        parts.push(`filter[order][]=${item}`)
      })
    } else {
      parts.push(`filter[order]=${order}`)
    }
  }

  if (from) {
    parts.push(`from=${from}`)
  }

  if (extra) {
    if (extra.concatenable) {
      // @ts-ignore
      const { concatenable } = { ...extra }
      // eslint-disable-next-line no-param-reassign
      delete extra.concatenable

      parts.push(`concatenable=${encodeURIComponent(concatenable)}`)
    }

    _.each(extra, (value, key) => {
      if (_.isArray(value)) {
        _.each(value, (subValue) => {
          parts.push(`${key}=${subValue}`)
        })
      } else {
        parts.push(`${key}=${value}`)
      }
    })
  }

  if (to) {
    parts.push(`to=${to}`)
  }

  if (limit) {
    parts.push(`filter[limit]=${limit}`)
  }

  if (start) {
    parts.push(`filter[start]=${start}`)
  }

  if (archived) {
    parts.push('archived')
  }

  return parts.join('&')
}

const getComparator = (comparator, value) => {
  if (!comparator && !value) {
    return ''
  }

  if (comparator) {
    return `[${comparator}]`
  }

  if (!comparator && _.isArray(value)) {
    return '[]'
  }

  return ''
}

const getCriteria = (field, comparator = '', value) => {
  if (!_.isArray(value) && 'not' === comparator) {
    return `not[${field}]=${encodeURIComponent(value)}`
  }

  return `${field}${getComparator(comparator, value)}=${encodeURIComponent(value)}`
}

const getOrder = (order) => {
  const { sortField, sortOrder } = order

  if (sortField) {
    return `order[${sortField}]=${sortOrder || 'ASC'}`
  }

  return null
}

export const getQueryStringV2 = ({ criteria, groups, limit, nextPage, order, page }: QueryPropsV2 = {}) => {
  const queryString = []

  if (groups?.read) {
    queryString.push(...groups.read.map((item) => `groups[]=read:${item}`))
  }

  if (groups?.write) {
    queryString.push(...groups.write.map((item) => `groups[]=write:${item}`))
  }

  if (groups?.empty) {
    queryString.push(...groups.empty.map((item) => `groups[]=${item}`))
  }

  if (criteria && criteria.length) {
    _.forEach(criteria, ({ comparator, field, value }) => {
      queryString.push(getCriteria(field, comparator, value))
    })
  }

  if (order) {
    if (_.isArray(order)) {
      _.each(order, (orderItem) => {
        const orderString = getOrder(orderItem)

        if (orderString) {
          queryString.push(orderString)
        }
      })
    } else {
      const orderString = getOrder(order)

      if (orderString) {
        queryString.push(orderString)
      }
    }
  }

  if (null !== limit && limit !== undefined) {
    queryString.push(`itemsPerPage=${limit}`)
  }

  if (page) {
    queryString.push(`page=${page}`)
  }

  if (nextPage) {
    queryString.push(`next_page=${nextPage}`)
  }

  return queryString.join('&')
}

export const objectToQueryString = (params: any) => (
  Object.keys(params).map((key) => `${key}=${params[key]}`).join('&')
)
