import { createSelector } from 'reselect'

import { NurseryAcademicYearsRootState } from '../models'

const getNurseryAcademicYearsState = (state: NurseryAcademicYearsRootState) => state?.nurseryAcademicYears

export const getNurseryAcademicYearsList = createSelector([getNurseryAcademicYearsState], (state) => state?.list?.data)

export interface CriteriaParamsProps {
  periodExists?: number
}

export const getCriteria = ({ periodExists = 1 }: CriteriaParamsProps) => {
  const criteria = []

  if (null !== periodExists && undefined !== periodExists) {
    criteria.push({
      field: 'periods[exists]',
      value: periodExists,
    })
  }

  return criteria
}
