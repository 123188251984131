import { generateBasicActions } from 'utils/service'

import {
  CLEAR_CLOSURE_DAY_TYPES_LIST,
  CREATE_CLOSURE_DAY_TYPES_BATCH,
  CREATE_CLOSURE_DAY_TYPES_BATCH_FAILED,
  CREATE_CLOSURE_DAY_TYPES_BATCH_SUCCESS,
  GET_CLOSURE_DAY_TYPES_LIST,
  GET_CLOSURE_DAY_TYPES_LIST_FAILED,
  GET_CLOSURE_DAY_TYPES_LIST_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ closureDayTypesApiClient }) => (
  generateBasicActions.list({
    constants: {
      failed: GET_CLOSURE_DAY_TYPES_LIST_FAILED,
      init: GET_CLOSURE_DAY_TYPES_LIST,
      success: GET_CLOSURE_DAY_TYPES_LIST_SUCCESS,
    },
    options,
    service: closureDayTypesApiClient,
    serviceMethod: 'list',
  })
)

export const batch = (options = {}) => ({ closureDayTypesApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: CREATE_CLOSURE_DAY_TYPES_BATCH_FAILED,
      init: CREATE_CLOSURE_DAY_TYPES_BATCH,
      success: CREATE_CLOSURE_DAY_TYPES_BATCH_SUCCESS,
    },
    options,
    service: closureDayTypesApiClient,
    serviceMethod: 'batch',
  })
)

export const clearList = () => ({
  type: CLEAR_CLOSURE_DAY_TYPES_LIST,
})
