import React from 'react'

import { BasicViewProps } from 'constants/models'
import { TYPES } from 'components/DropdownMenu/constants'
import { ChildProductPriceCalculatorDataState } from 'services/child/models'

import { Banner, Callout, Page, Section, Spinner } from 'components'

import i18n from 'translations'

import AddForm, { AddFormFieldProps } from './components/AddForm'

interface AddViewProps extends BasicViewProps {
  childId?: number
  initialValues: AddFormFieldProps
  isArchived?: boolean
  isChildContext?: boolean
  isFetchingPrice: boolean
  isInvoiced?: boolean
  isValid: boolean
  linkedToRequestedExtraSession?: boolean
  onArchiveClick: () => void
  priceCalculator: ChildProductPriceCalculatorDataState
}

const AddView: React.FC<AddViewProps> = ({
  childId,
  errorMessages,
  initialValues,
  isArchived,
  isChildContext,
  isEdit,
  isFetchingPrice,
  isInvoiced,
  isLoading,
  isSubmitting,
  isValid,
  linkedToRequestedExtraSession,
  onArchiveClick,
  onSubmit,
  priceCalculator,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <AddForm
        childId={childId}
        initialValues={initialValues}
        isChildContext={isChildContext}
        isEdit={isEdit}
        isFetchingPrice={isFetchingPrice}
        isInvoiced={isInvoiced}
        isSubmitting={isSubmitting}
        isValid={isValid}
        linkedToRequestedExtraSession={linkedToRequestedExtraSession}
        priceCalculator={priceCalculator}
        onSubmit={onSubmit}
      />
    )
  }

  const actions = isEdit && (
    <Section.Actions
      options={[{
        onClick: onArchiveClick,
        type: isArchived ? TYPES.UNARCHIVE : TYPES.ARCHIVE,
      }]}
    />
  )

  return (
    <Page>
      <Page.Content>
        <Section
          actions={actions}
          title={isEdit
            ? i18n.t('module:Finance:OneOffBookings:Form:Title:edit')
            : i18n.t('module:Finance:OneOffBookings:Form:Title:add')}
        >
          <Callout content={errorMessages} error />
          {linkedToRequestedExtraSession && (
            <Banner.Info>
              {i18n.t('module:Finance:OneOffBookings:LinkedToRequestedExtraSession:banner')}
            </Banner.Info>
          )}
          {renderContent()}
        </Section>
      </Page.Content>
    </Page>
  )
}

export default AddView
