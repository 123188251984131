import styled from 'styled-components'

export const StyledActionButtonContainer = styled.div`
  margin-bottom: 20px;
  display: block;

  ${({ textAlign }) => textAlign && `
    text-align: ${textAlign};
  `}
`
