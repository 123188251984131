export const GET_CHILD = 'GET_CHILD'
export const GET_CHILD_SUCCESS = 'GET_CHILD_SUCCESS'
export const GET_CHILD_FAILED = 'GET_CHILD_FAILED'

export const CREATE_CHILD = 'CREATE_CHILD'
export const CREATE_CHILD_SUCCESS = 'CREATE_CHILD_SUCCESS'
export const CREATE_CHILD_FAILED = 'CREATE_CHILD_FAILED'

export const UPDATE_CHILD = 'UPDATE_CHILD'
export const UPDATE_CHILD_SUCCESS = 'UPDATE_CHILD_SUCCESS'
export const UPDATE_CHILD_FAILED = 'UPDATE_CHILD_FAILED'

export const CLEAR_CHILD = 'CLEAR_CHILD'
