import React, { PropsWithChildren } from 'react'

import { Button } from 'components'

import { ButtonProps } from '../Button'
import { StyledRadioButtonWrapper } from './RadioButtonStyled'

interface RadioButtonProps extends ButtonProps {
  isActive?: boolean
}

const RadioButton: React.FC<PropsWithChildren<RadioButtonProps>> = ({
  isActive,
  ...rest
}) => (
  <StyledRadioButtonWrapper $isActive={isActive}>
    <Button
      {...rest}
      hierarchy="secondary"
      negativeMargins
    />
  </StyledRadioButtonWrapper>
)

export default RadioButton
