import _ from 'lodash'
import { ResponsivePie } from '@nivo/pie'

import React, { PropsWithChildren, useCallback, useMemo } from 'react'

import { ComputedDatum } from '@nivo/pie/dist/types/types'
import colors, { NEUTRAL_COLOURS } from 'constants/colors'
import layout from 'constants/layout'

import { Typography } from 'components'

import {
  StyledChart,
  StyledChartContainer,
  StyledContainer,
  StyledItemTopContainer,
  StyledLegend,
  StyledLegendContainer,
  StyledSubtitleContainer,
  StyledTitleContainer,
  StyledTitleWrapper,
} from './PieChartStyled'
import PieChartTooltip from './PieChartTooltip'

const theme = {
  labels: {
    text: {
      fontFamily: layout.fontFamily,
      fontSize: 14,
      fontWeight: 600,
    },
  },
  tooltip: {
    container: {
      background: NEUTRAL_COLOURS.BASIC,
      borderRadius: '12px',
      boxShadow: '0 2px 10px 0 rgba(0, 0, 0, .25)',
      color: NEUTRAL_COLOURS.WHITE,
      fontSize: '16px',
      padding: '12px 16px',
    },
  },
}

export type DataItem = {
  amount: number
  color?: string
  id?: number
  isCurrencyValue?: boolean
  percentage?: number
  value: number
}

interface PieChartProps {
  arcLabel?: (item: ComputedDatum<DataItem>) => string
  data: DataItem[]
  legendValueField?: string
  subtitle?: string
  title?: string
  withCustomColors?: boolean
  withoutLegends?: boolean
}

const PieChart: React.FC<PropsWithChildren<PieChartProps>> = ({
  arcLabel,
  data,
  legendValueField = 'value',
  subtitle,
  title,
  withCustomColors,
  withoutLegends,
}) => {
  const standardColors = useMemo(() => _.map(colors.pieChartColors, (e, i) => `url(#gradient_${i})`), [])

  const renderGradients = () => {
    const gradProps = {
      x1: 0,
      x2: 0,
      y1: 0,
      y2: 1,
    }

    return (
      <svg height="0" width="0">
        <defs>
          {_.map(colors.pieChartColors, (item, i) => {
            const [startColor, endColor] = colors.pieChartColors[i]

            return (
              <linearGradient id={`gradient_${i}`} {...gradProps}>
                <stop offset="0%" stopColor={startColor} />
                <stop offset="100%" stopColor={endColor} />
              </linearGradient>
            )
          })}
        </defs>
      </svg>
    )
  }

  const renderTitle = useCallback(() => {
    if (!title && !subtitle) {
      return null
    }

    return (
      <StyledTitleWrapper>
        <StyledTitleContainer>
          {title}
          <StyledSubtitleContainer>
            {subtitle}
          </StyledSubtitleContainer>
        </StyledTitleContainer>
      </StyledTitleWrapper>
    )
  }, [title, subtitle])

  const renderLegends = () => {
    if (withoutLegends) {
      return null
    }

    return (
      <StyledLegendContainer>
        {_.map(data, (series, i) => (
          <StyledLegend $withSecondRow={!!legendValueField} key={_.kebabCase(`${series.id}_${i}`)}>
            <StyledItemTopContainer>
              <svg height={20} viewBox="0 0 20 20" width={20}>
                <rect fill={`url(#gradient_${i})`} height={20} rx={5} ry={5} width={20} />
              </svg>
              <Typography fontSize={14} bold>
                {series.id}
              </Typography>
            </StyledItemTopContainer>
            {legendValueField && (
              <Typography variant="h6">
                {series[legendValueField]}
              </Typography>
            )}
          </StyledLegend>
        ))}
      </StyledLegendContainer>
    )
  }

  const pieColors = withCustomColors
    ? _.map(data, ({ color }) => color)
    : standardColors

  return (
    <StyledContainer>
      <StyledChartContainer>
        <StyledChart>
          {renderGradients()}
          <ResponsivePie
            arcLabel={arcLabel}
            arcLinkLabel="amount"
            borderColor="black"
            colors={pieColors}
            data={_.map(data, (e, i) => ({ ...e, id: e.id || i }))}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            innerRadius={0.55}
            margin={{
              bottom: 30,
              left: 30,
              right: 30,
              top: 30,
            }}
            padAngle={1}
            startAngle={-180}
            theme={theme}
            tooltip={PieChartTooltip}
            animate
          />
        </StyledChart>
        {renderTitle()}
      </StyledChartContainer>
      {renderLegends()}
    </StyledContainer>
  )
}

export default PieChart
