import React, { useEffect } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'

import { RootState } from 'core/reducers'

import { generateRoute } from 'utils/routing'

import { withAppService, withAppServiceProps } from 'services/app'
import { withPaginationUtils, withPaginationUtilsProps } from 'services/utils/pagination'
import { withOrganizationService, withOrganizationServiceProps } from 'services/organization'
import { withNurseriesService, withNurseriesServiceProps } from 'services/nurseries'
import { getAuthOrganization } from 'services/security/selectors'
import { withRouter, withRouterProps } from 'services/router'

import SecurityView from './SecurityView'

const ORGANIZATION_SECURITY_SETTINGS_GROUPS = {
  read: [
    'organization.organizationSettings',
    'organizationSettings',
    'organizationSettings.security',
    'organizationSecuritySettings',
  ],
}

type SecurityContainerProps = withOrganizationServiceProps
  & withAppServiceProps
  & withNurseriesServiceProps
  & withPaginationUtilsProps
  & withRouterProps

const mapState = (state: RootState, {
  appSelectors,
  nurseriesListState,
  nurseriesSelectors,
  organizationSelectors,
  organizationSingleState,
}: SecurityContainerProps) => ({
  authOrganization: getAuthOrganization(state),
  errorMessages: appSelectors.getErrorMessages(nurseriesListState, organizationSingleState),
  globalSecurityTypeDescription: organizationSelectors.getGlobalSecurityTypeDescription(state),
  isFetching: appSelectors.getIsFetching(organizationSingleState, nurseriesListState),
  isGlobalInheritanceEnabled: organizationSelectors.getSecurityIsGlobalInheritanceEnabled(state),
  nurseries: nurseriesSelectors.getNurseriesListDataSelector(state),
  securitySettings: organizationSelectors.getSecuritySettings(state),
  totalResults: appSelectors.getTotalResults(nurseriesListState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const SecurityContainer: React.FC<SecurityContainerProps & PropsFromRedux> = ({
  authOrganization,
  errorMessages,
  globalSecurityTypeDescription,
  isFetching,
  isGlobalInheritanceEnabled,
  navigate,
  nurseries,
  nurseriesActions,
  organizationActions,
  organizationSelectors,
  organizationSingleState,
  paginationUtils,
  securitySettings,
  totalResults,
}) => {
  const { getPageCount, onPageChange, page, perPage } = paginationUtils
  const pageCount = getPageCount(totalResults)

  useEffect(() => {
    organizationActions.get(authOrganization.id, {
      params: {
        groups: ORGANIZATION_SECURITY_SETTINGS_GROUPS,
      },
    })

    return () => {
      organizationActions.clearSingle()
      nurseriesActions.clear()
    }
  }, [])

  const fetch = () => {
    nurseriesActions.list({
      mergeResult: 1 !== page,
      params: { page },
      recurrency: true,
    })
  }

  useEffect(() => {
    if (false === isGlobalInheritanceEnabled && !nurseries?.length) {
      fetch()
    }
  }, [isGlobalInheritanceEnabled])

  useEffect(() => {
    if (1 !== page) {
      fetch()
    }
  }, [page])

  const handleGlobalSettingsEditClick = () => {
    navigate(generateRoute('SETTINGS.SECURITY.GLOBAL_EDIT'))
  }

  const handleSessionTimeoutSubmit = (fields) => (successCallback, failedCallback = () => {}) => {
    const payload = organizationSelectors.getSecuritySettingsPayload({
      fields,
      organizationSingleState,
    })

    organizationActions.update(authOrganization.id, {
      onFailed: failedCallback,
      onSuccess: successCallback,
      params: {
        groups: ORGANIZATION_SECURITY_SETTINGS_GROUPS,
      },
      payload,
    })
  }

  const handlePageChange = (newPage) => {
    onPageChange()(newPage)
  }

  return (
    <SecurityView
      errorMessages={errorMessages}
      globalSecurityTypeDescription={globalSecurityTypeDescription}
      isGlobalInheritanceEnabled={isGlobalInheritanceEnabled}
      isLoading={isFetching}
      nurseries={nurseries}
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      securitySettings={securitySettings}
      totalResults={totalResults}
      onEditClick={handleGlobalSettingsEditClick}
      onPageChange={handlePageChange}
      onSessionTimeoutSubmit={handleSessionTimeoutSubmit}
    />
  )
}

const enhance = compose(
  withRouter,
  withAppService,
  withNurseriesService,
  withOrganizationService,
  withPaginationUtils,
  connector,
)

export default enhance(SecurityContainer)
