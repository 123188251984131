import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { VIEWING_STATUSES, VIEWING_STATUS_OPTIONS } from 'services/legacy/enquiries/viewings/constants'

import { Typography } from 'components'

import i18n from 'translations'

import { STATUS_TAG_COLORS } from './constants'
import { StyledStatusContainer, StyledTag } from './StatusStyled'

const getBackgroundColor = (status, isPastDate) => {
  switch (status) {
    case VIEWING_STATUSES.ATTENDED:
      return STATUS_TAG_COLORS.ATTENDED
    case VIEWING_STATUSES.BOOKED:
      if (isPastDate) {
        return STATUS_TAG_COLORS.PAST
      }

      return STATUS_TAG_COLORS.UPCOMING
    case VIEWING_STATUSES.NOSHOW:
      return STATUS_TAG_COLORS.NOSHOW
    case VIEWING_STATUSES.CANCELLED_BY_NURSERY:
    case VIEWING_STATUSES.CANCELLED_BY_PARENT:
      return STATUS_TAG_COLORS.CANCELLED
    default:
      return STATUS_TAG_COLORS.CANCELLED
  }
}

export const getStatus = (status, isPastDate) => {
  if (VIEWING_STATUSES.BOOKED === status) {
    if (isPastDate) {
      return i18n.t('module:Enquiries:Viewings:Status:past')
    }

    return _.upperFirst(i18n.t('global:upcoming'))
  }

  return _.find(VIEWING_STATUS_OPTIONS, { value: status })?.label
}

const Status = ({ showAroundDate, status }) => {
  const isPastDate = moment().isAfter(showAroundDate)

  return (
    <StyledStatusContainer>
      <StyledTag backgroundColor={getBackgroundColor(status, isPastDate)} />
      <Typography inline>
        {getStatus(status, isPastDate)}
      </Typography>
    </StyledStatusContainer>
  )
}

export default Status
