import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledGrid = styled.div`
  display: grid;  
  grid-column-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  
  @media(max-width: 780px) {
    grid-template-columns: 1fr;
  }
`

export const StyledValue = styled.p`
  margin: 0;
  text-transform: uppercase;
  font-weight: 600;
  color: ${getBrandingColor('tertiary-color')};
  
  ${({ withMargin }) => withMargin && `
    margin-bottom: 15px;
  `}
`

export const StyledTitle = styled.p`
  margin: 0;
  font-weight: 600;
  margin-bottom: 4px;
`

export const StyledDoubleColumn = styled.div`
  display: grid;  
  grid-column-gap: 10px;
  grid-template-columns: 2fr 1fr;
  
  @media(max-width: 780px) {
    grid-template-columns: 1fr;
    
    & > div {
      width: 100%;
    }
  }
`

export const StyledAttendanceChart = styled.div`
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

export const StyledAttendanceGrid = styled.div`
  display: grid;  
  grid-column-gap: 20px;
  grid-template-columns: 1fr 1fr;
`

export const StyledAttendanceTable = styled.div`
  display: grid;  
  align-items: center;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  grid-template-columns: 1fr auto;
`
