import styled from 'styled-components'

import ReactModal from 'react-modal'

export const StyledReactModal = styled(ReactModal)`
  flex: 1;
  display: flex;
  justify-content: center;
  max-height: 100%;
`
