import _ from 'lodash'

import { createSelector } from 'reselect'

import { ClosureDayTypesRootState } from '../models'

const getClosureDayTypesState = (state: ClosureDayTypesRootState) => state?.closureDayTypes

export const getClosureDayType = createSelector([getClosureDayTypesState], (state) => state?.single?.data)

export const getBody = (field) => {
  const { chargeFor, name } = field

  const chargeForKeys = _.keys(chargeFor)

  const chargeForArray = _.reduce(chargeForKeys, (result, key) => {
    const newResult = [...result]

    if (chargeFor[key]) {
      newResult.push(key)
    }

    return newResult
  }, [])

  return {
    chargeFor: chargeForArray,
    name,
  }
}
