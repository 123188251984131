import ServiceBase from 'services/ServiceBase'

import { getQueryString } from 'utils/api'

class DeviceTokensApiClient extends ServiceBase {
  get = (id) => this.request({
    path: `/device-tokens/${id}`,
  })

  create = (body) => this.request({
    body,
    method: 'POST',
    path: '/device-tokens',
  })

  update = (id, body) => this.request({
    body,
    method: 'PATCH',
    path: `/device-tokens/${id}`,
  })

  remove = (id) => this.request({
    method: 'DELETE',
    path: `/device-tokens/${id}`,
  })

  install = (id, body) => this.request({
    body,
    method: 'POST',
    path: `/device-tokens/${id}/install`,
  })

  list = (params) => this.request({
    path: `/device-tokens?${getQueryString(params)}`,
  })
}

export default DeviceTokensApiClient
