import i18n from 'translations'

export enum BeforeAfterType {
  AFTER = 'AFTER',
  BEFORE = 'BEFORE'
}

export const BEFORE_AFTER_TYPE_OPTIONS = [
  {
    label: i18n.t('global:Before'),
    value: BeforeAfterType.BEFORE,
  },
  {
    label: i18n.t('global:After'),
    value: BeforeAfterType.AFTER,
  },
]
