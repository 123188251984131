import { generateBasicActions } from 'utils/service'

import { CLEAR_NEXT_STEPS, GET_NEXT_STEPS, GET_NEXT_STEPS_FAILED, GET_NEXT_STEPS_SUCCESS } from './constants'

export const list = (options = {}) => ({ nextStepsApiClient }) => generateBasicActions.list({
  constants: {
    failed: GET_NEXT_STEPS_FAILED,
    init: GET_NEXT_STEPS,
    success: GET_NEXT_STEPS_SUCCESS,
  },
  options,
  service: nextStepsApiClient,
  serviceMethod: 'list',
})

export const listByChild = (childId, params, mergeResult, onSuccess, onFailed) => ({
  nextStepsApiClient,
}) => (dispatch) => {
  dispatch({ type: GET_NEXT_STEPS })

  nextStepsApiClient.listByChild(childId, params).then(
    (response) => {
      dispatch({
        mergeResult,
        payload: response,
        type: GET_NEXT_STEPS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: GET_NEXT_STEPS_FAILED,
      })

      if (onFailed) {
        onFailed()
      }
    },
  )
}

export const clearNextSteps = () => ({
  type: CLEAR_NEXT_STEPS,
})
