import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { toNestedGroups } from 'utils/data'

import * as OccupancyConstants from '../constants'

const getOccupancy = (state) => state.occupancy

export const getOccupancyReportSelector = createSelector(
  [getOccupancy],
  (state) => {
    if (!state) {
      return null
    }

    return state.report
  },
)

export const getIsOverviewReport = createSelector(
  [getOccupancyReportSelector],
  (state) => {
    if (!state) {
      return null
    }

    return state.isOverviewReport
  },
)

export const getOccupancyReportDataSelector = createSelector(
  [getOccupancyReportSelector],
  (state) => {
    if (!state) {
      return null
    }

    return state.data
  },
)

const injectBreakdownsCount = (values) => {
  const { children, periods } = values
  const childrenByOrder = _.orderBy(children, ['displayName'], ['asc'])

  return {
    ...values,
    children: _.map(childrenByOrder, (child) => {
      let rowCount = 0
      let breakDownRowCount = 0

      _.each(periods, ({ breakdowns, closed }) => {
        if (closed) {
          return
        }

        _.each(breakdowns, ({ result }) => {
          const foundChild = _.find(result.children, ({ id }) => id === child.id)

          breakDownRowCount = 0

          _.each(foundChild, (childBreakdown) => {
            if (_.isArray(childBreakdown)) {
              breakDownRowCount += childBreakdown.length
            }
          })

          if (breakDownRowCount > rowCount) {
            rowCount = breakDownRowCount
          }
        })
      })

      const rowHeight = 1 >= rowCount ? 44 : (74 + (28 * (rowCount - 1)))

      return {
        ...child,
        rowCount,
        rowHeight,
      }
    }),
  }
}
export const getOccupancyReportFormattedDataSelector = createSelector(
  [getOccupancyReportDataSelector, getIsOverviewReport],
  (reportData, isOverviewReport) => {
    if (!reportData?.length) {
      return null
    }

    if (isOverviewReport) {
      return _.reduce(
        toNestedGroups(reportData, ['date', 'nurseryClass.id']),
        (result, value, dateKey) => {
          const newDateResult = { ...result }

          newDateResult[dateKey] = _.reduce(value, (classResult, classValue, classKey) => {
            const newClassResult = { ...classResult }

            newClassResult[classKey] = classValue[0]

            return newClassResult
          }, {})

          return newDateResult
        },
        {},
      )
    }

    const tempGroupedData = _.groupBy(reportData, 'nurseryClass.id')

    return _.reduce(
      tempGroupedData,
      (result, value, key) => {
        const newDateResult = { ...result }

        newDateResult[key] = injectBreakdownsCount(value[0])

        return newDateResult
      },
      {},
    )
  },
)

export const getOccupancyReportRoomsSelector = createSelector(
  [getOccupancyReportDataSelector],
  (reportData) => {
    if (!reportData?.length) {
      return null
    }

    return _.uniqBy(_.map(reportData, ({ nurseryClass }) => nurseryClass), 'id')
  },
)

export const getOccupancyReportHeaderSelector = createSelector(
  [getOccupancyReportDataSelector],
  (state) => {
    if (!state?.length) {
      return null
    }

    return state[0].periods
  },
)

export const getOccupancyReportChartSelector = createSelector(
  [getOccupancyReportDataSelector, getOccupancyReportHeaderSelector, getIsOverviewReport],
  (reportData, reportHeader, isOverviewReport) => {
    if (!reportData?.length || isOverviewReport) {
      return null
    }

    let response = {}

    _.each(reportData, (data) => {
      _.each(data.periods, ({ breakdowns, closed, result }, dayOfWeek) => {
        if (!response[dayOfWeek]) {
          response[dayOfWeek] = []
        }

        if (closed) {
          response[dayOfWeek].push(null)

          return
        }

        let summary = _.sumBy(breakdowns, ({ result: { fte } }) => fte) / breakdowns.length
        if (result) {
          summary = result.fte
        }

        response[dayOfWeek].push(summary)
      })
    })

    response = _.map(response, (data) => (
      parseInt(_.sum(data) / data.length)
    ))

    return {
      data: response,
      header: _.map(reportHeader, ({ label }) => label),
    }
  },
)

export const getCriteriaSelector = (params) => {
  const {
    breakdown,
    date,
    rooms,
    splitAt,
    type,
    week,
  } = params

  const criteria = [
    {
      comparator: 'startAgeInMonths',
      field: 'order',
      value: 'asc',
    },
    {
      comparator: 'endAgeInMonths',
      field: 'order',
      value: 'asc',
    },
    {
      field: 'archived',
      value: 0,
    },
  ]

  if (breakdown) {
    criteria.push({
      comparator: 'breakdown',
      field: 'occupancyRequest',
      value: breakdown,
    })
  }

  if (null !== week && undefined !== week) {
    criteria.push({
      comparator: 'date',
      field: 'occupancyRequest',
      value: moment(date).add(week, 'week').startOf('week').format('YYYY/MM/DD'),
    })
  } else if (date) {
    criteria.push({
      comparator: 'date',
      field: 'occupancyRequest',
      value: moment(date).format('YYYY/MM/DD'),
    })
  }

  if (splitAt) {
    criteria.push({
      comparator: 'splitAt',
      field: 'occupancyRequest',
      value: moment(splitAt).format('HH:mm'),
    })
  }

  if (type) {
    criteria.push({
      comparator: 'type',
      field: 'occupancyRequest',
      value: type,
    })
  }

  if (rooms) {
    _.each(rooms.split(','), (room) => {
      criteria.push({
        field: 'id',
        value: room,
      })
    })
  }

  return criteria
}

export const getReportParams = (data) => {
  const {
    absences,
    breakdown,
    date,
    extraSessions,
    holidays,
    rooms,
    splitAt,
    type,
  } = data
  const params = {
    absences,
    extraSessions,
    holidays,
  }

  if (breakdown) {
    params.breakdown = breakdown.value
  }

  if (rooms) {
    params.rooms = rooms.value ? rooms.value : rooms
  }

  if (type) {
    params.type = type.value
  }

  if (date) {
    params.date = moment(date).format('YYYY/MM/DD')
  }

  if (splitAt) {
    params.splitAt = +moment(0, 'x')
      .set('hour', moment(splitAt).get('hour'))
      .set('minute', moment(splitAt).get('minute'))
      .format('x')
  }

  if (type === OccupancyConstants.OCCUPANCY_REQUEST_TYPE.OVERVIEW) {
    params.date = moment().format('YYYY/MM/DD')
    params.breakdown = OccupancyConstants.OCCUPANCY_REQUEST_BREAKDOWN.AM_PM
  }

  return Object.keys(params).map((key) => `${key}=${params[key]}`).join('&')
}

export const getInitialValuesForReportGenerator = (query = {}) => {
  const {
    absences,
    breakdown,
    date,
    extraSessions,
    holidays,
    rooms,
    splitAt,
    type,
  } = query

  const newTypeOption = _.find(OccupancyConstants.OCCUPANCY_TYPE_DROPDOWN, ({ value }) => (
    value === (type || OccupancyConstants.OCCUPANCY_REQUEST_TYPE.WEEKLY)
  ))

  const newBreakdown = newTypeOption.value !== OccupancyConstants.OCCUPANCY_REQUEST_TYPE.YEARLY
    ? (_.find(OccupancyConstants.OCCUPANCY_REQUEST_BREAKDOWN_DROPDOWN, ({ value }) => (value === breakdown))
      || OccupancyConstants.OCCUPANCY_REQUEST_BREAKDOWN_DROPDOWN[0])
    : null

  return {
    absences: absences ? JSON.parse(absences) : false,
    breakdown: newBreakdown,
    date: moment(date),
    extraSessions: extraSessions ? JSON.parse(extraSessions) : true,
    holidays: holidays ? JSON.parse(holidays) : true,
    rooms,
    splitAt: splitAt ? moment(splitAt, 'x') : null,
    type: newTypeOption,
  }
}
