import React from 'react'

import { Callout, Page, Spinner } from 'components'

import { AddFundingForm } from './components'

const ManagementFundingAddV2View = ({
  discountOptions,
  errorMessages,
  fundingCalculationOptions,
  fundingDeductionOptions,
  fundingFeesOptions,
  fundingLineItemOptions,
  fundingTypeOptions,
  initialValues,
  isActualSelected,
  isArchived,
  isCheapestSessionSelected,
  isDeductedFromInvoice,
  isDeficitChanged,
  isEdit,
  isFormLoading,
  isInsideModal,
  isLoading,
  isOtherFundingTypeSelected,
  isSubstractFundingSelected,
  onArchiveClick,
  onCancelClick,
  onPreviewClick,
  onSubmit,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <AddFundingForm
        discountOptions={discountOptions}
        fundingCalculationOptions={fundingCalculationOptions}
        fundingDeductionOptions={fundingDeductionOptions}
        fundingFeesOptions={fundingFeesOptions}
        fundingLineItemOptions={fundingLineItemOptions}
        fundingTypeOptions={fundingTypeOptions}
        initialValues={initialValues}
        isActualSelected={isActualSelected}
        isArchived={isArchived}
        isCheapestSessionSelected={isCheapestSessionSelected}
        isDeductedFromInvoice={isDeductedFromInvoice}
        isDeficitChanged={isDeficitChanged}
        isEdit={isEdit}
        isInsideModal={isInsideModal}
        isLoading={isFormLoading}
        isOtherFundingTypeSelected={isOtherFundingTypeSelected}
        isSubstractFundingSelected={isSubstractFundingSelected}
        onArchiveClick={onArchiveClick}
        onCancelClick={onCancelClick}
        onPreviewClick={onPreviewClick}
        onSubmit={onSubmit}
      />
    )
  }

  return (
    <Page isLoading={isLoading}>
      <Page.Content>
        <Callout content={errorMessages} error />
        {renderContent()}
      </Page.Content>
    </Page>
  )
}

export default ManagementFundingAddV2View
