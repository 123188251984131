import React from 'react'

import { BasicViewProps, Nullable, Option } from 'constants/models'
import { TYPES } from 'components/DropdownMenu/constants'

import { Page, Section, Spinner } from 'components'

import i18n from 'translations'

import { FormFields } from './models'
import AddForm from './components/AddForm'

export interface AddViewProps extends BasicViewProps {
  academicYearOptions?: Option[]
  initialValues: Nullable<FormFields>
  nurseryAcademicYearId?: number
  onDeleteClick: () => void
  onDeleteItemClick: (fields: FormFields, index: number) => void
}

const AddView = ({
  academicYearOptions,
  initialValues,
  isEdit,
  isLoading,
  isSubmitting,
  nurseryAcademicYearId,
  onDeleteClick,
  onDeleteItemClick,
  onSubmit,
}: AddViewProps) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <AddForm
        academicYearOptions={academicYearOptions}
        initialValues={initialValues}
        isEdit={isEdit}
        isSubmitting={isSubmitting}
        nurseryAcademicYearId={nurseryAcademicYearId}
        onDeleteItemClick={onDeleteItemClick}
        onSubmit={onSubmit}
      />
    )
  }
  return (
    <Page.Section
      actions={initialValues?.closurePeriods?.length && (
        <Section.Actions
          options={[{
            onClick: onDeleteClick,
            type: TYPES.DELETE,
          }]}
        />
      )}
      title={i18n.t(isEdit
        ? 'module:Management:ClosureDays:ClosureDates:Add:editTitle'
        : 'module:Management:ClosureDays:ClosureDates:Add:title')}
    >
      {renderContent()}
    </Page.Section>
  )
}

export default AddView
