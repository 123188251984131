import _ from 'lodash'

import AbsenceReasonsDropdown from '../legacy/InfiniteDropdowns/AbsenceReasonsDropdown'
import CarersDropdown from '../legacy/InfiniteDropdowns/CarersDropdown'
import ChildrenDropdown from '../legacy/InfiniteDropdowns/ChildrenDropdown'
import ChildExtraItemsDropdown from '../legacy/InfiniteDropdowns/ChildExtraItemsDropdown'
import DepositsDropdown from '../legacy/InfiniteDropdowns/DepositsDropdown'
import EnquiryStatusTypesDropdown from '../legacy/InfiniteDropdowns/EnquiryStatusTypesDropdown'
import LeaveTypesDropdown from '../legacy/InfiniteDropdowns/LeaveTypesDropdown'
import MembershipsDropdown from '../legacy/InfiniteDropdowns/MembershipsDropdown'
import MenuItemsDropdown from '../legacy/InfiniteDropdowns/MenuItemsDropdown'
import NurseriesDropdown from '../legacy/InfiniteDropdowns/NurseriesDropdown'
import NurseryDiscountsDropdown from '../legacy/InfiniteDropdowns/NurseryDiscountsDropdown'
import NurseryFundingDropdown from '../legacy/InfiniteDropdowns/NurseryFundingDropdown'
import NurserySessionsDropdown from '../legacy/InfiniteDropdowns/NurserySessionsDropdown'
import OrganizationMembershipsDropdown from '../legacy/InfiniteDropdowns/OrganizationMembershipsDropdown'
import PaymentTypesDropdown from '../legacy/InfiniteDropdowns/PaymentTypesDropdown'
import PublicEnquirySourceDropdown from '../legacy/InfiniteDropdowns/PublicEnquirySourceDropdown'
import RelationsDropdown from '../legacy/InfiniteDropdowns/RelationsDropdown'
import RoomsDropdown from '../legacy/InfiniteDropdowns/RoomsDropdown'
import SupportedCurrencyDropdown from '../legacy/InfiniteDropdowns/SupportedCurrencyDropdown'
import SupportedLocaleDropdown from '../legacy/InfiniteDropdowns/SupportedLocaleDropdown'
import UsersDropdown from '../legacy/InfiniteDropdowns/UsersDropdown'

import NurseryItemsV3Dropdown from './dropdowns/NurseryItemsV3Dropdown'
import PeriodsDropdown from './dropdowns/PeriodsDropdown'
import ChildProductsDropdown from './dropdowns/ChildProductsDropdown'
import EnquirySettingsDropdown from './dropdowns/EnquirySettingsDropdown'
import OrganisationsDropdown from './dropdowns/OrganisationsDropdown'
import NurseryDiscountsV3Dropdown from './dropdowns/NurseryDiscountsV3Dropdown'
import NurserySessionsV3Dropdown from './dropdowns/NurserySessionsV3Dropdown'
import NurseryFundingsV3Dropdown from './dropdowns/NurseryFundingsV3Dropdown'
import ClosureDayTypesDropdown from './dropdowns/ClosureDayTypesDropdown'

interface InfiniteDropdownsType {
  AbsenceReasons?: typeof AbsenceReasonsDropdown
  Carers?: typeof CarersDropdown
  ChildExtraItems?: typeof ChildExtraItemsDropdown
  ChildProducts?: typeof ChildProductsDropdown
  Children?: typeof ChildrenDropdown
  ClosureDayTypes?: typeof ClosureDayTypesDropdown
  Deposits?: typeof DepositsDropdown
  EnquirySettings?: typeof EnquirySettingsDropdown
  EnquiryStatusTypes?: typeof EnquiryStatusTypesDropdown
  LeaveTypes?: typeof LeaveTypesDropdown
  Memberships?: typeof MembershipsDropdown
  MenuItems?: typeof MenuItemsDropdown
  Nurseries?: typeof NurseriesDropdown
  NurseryDiscounts?: typeof NurseryDiscountsDropdown
  NurseryDiscountsV3?: typeof NurseryDiscountsV3Dropdown
  NurseryFunding?: typeof NurseryFundingDropdown
  NurseryFundingsV3?: typeof NurseryFundingsV3Dropdown
  NurseryItemsV3?: typeof NurseryItemsV3Dropdown
  NurserySessions?: typeof NurserySessionsDropdown
  NurserySessionsV3?: typeof NurserySessionsV3Dropdown
  Organisations?: typeof OrganisationsDropdown
  OrganizationMemberships?: typeof OrganizationMembershipsDropdown
  PaymentTypes?: typeof PaymentTypesDropdown
  Periods?: typeof PeriodsDropdown
  PublicEnquirySource?: typeof PublicEnquirySourceDropdown
  Relations?: typeof RelationsDropdown
  Rooms?: typeof RoomsDropdown
  SupportedCurrency?: typeof SupportedCurrencyDropdown
  SupportedLocale?: typeof SupportedLocaleDropdown
  Users?: typeof UsersDropdown
}

const InfiniteDropdowns: InfiniteDropdownsType = {}

export const InfiniteDropdownsList = {
  AbsenceReasons: AbsenceReasonsDropdown,
  Carers: CarersDropdown,
  ChildExtraItems: ChildExtraItemsDropdown,
  ChildProducts: ChildProductsDropdown,
  Children: ChildrenDropdown,
  ClosureDayTypes: ClosureDayTypesDropdown,
  Deposits: DepositsDropdown,
  EnquirySettings: EnquirySettingsDropdown,
  EnquiryStatusTypes: EnquiryStatusTypesDropdown,
  LeaveTypes: LeaveTypesDropdown,
  Memberships: MembershipsDropdown,
  MenuItems: MenuItemsDropdown,
  Nurseries: NurseriesDropdown,
  NurseryDiscounts: NurseryDiscountsDropdown,
  NurseryDiscountsV3: NurseryDiscountsV3Dropdown,
  NurseryFunding: NurseryFundingDropdown,
  NurseryFundingsV3: NurseryFundingsV3Dropdown,
  NurseryItemsV3: NurseryItemsV3Dropdown,
  NurserySessions: NurserySessionsDropdown,
  NurserySessionsV3: NurserySessionsV3Dropdown,
  Organisations: OrganisationsDropdown,
  OrganizationMemberships: OrganizationMembershipsDropdown,
  PaymentTypes: PaymentTypesDropdown,
  Periods: PeriodsDropdown,
  PublicEnquirySource: PublicEnquirySourceDropdown,
  Relations: RelationsDropdown,
  Rooms: RoomsDropdown,
  SupportedCurrency: SupportedCurrencyDropdown,
  SupportedLocale: SupportedLocaleDropdown,
  Users: UsersDropdown,
}

_.forOwn(InfiniteDropdownsList, (value, key) => {
  InfiniteDropdowns[key] = value
})

export default InfiniteDropdowns
