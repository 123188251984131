import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'
import { STATUS_TYPES } from 'services/legacy/requestedExtraSessions/constants'

const STATUS_MAP = {
  [STATUS_TYPES.APPROVED]: FLAG_COLOURS.SUCCESS,
  [STATUS_TYPES.DECLINED]: FLAG_COLOURS.ERROR,
  [STATUS_TYPES.PENDING]: NEUTRAL_COLOURS.BASIC,
}

export const getStatusColor = (status) => STATUS_MAP[status]
