import React from 'react'

import { Icon } from 'components'

import { StyledContainer, StyledIconContainer, StyledPreviewLineContainer } from './FundingLineItemLabelStyled'

const FundingLineItemLabel = ({ label, onPreviewClick }) => (
  <StyledContainer>
    {label}
    <StyledPreviewLineContainer onClick={onPreviewClick}>
      <StyledIconContainer>
        <Icon height={20} icon="details-view" />
      </StyledIconContainer>
      Preview line items
    </StyledPreviewLineContainer>
  </StyledContainer>
)

export default FundingLineItemLabel
