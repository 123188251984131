import moment from 'moment'

import React from 'react'

import { NEXT_STEP_TYPE } from 'services/legacy/nextSteps/constants'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { Button, DateString, GroupAvatars, List, Toolbar, Typography } from 'components'

import i18n from 'translations'

import NextStepsFrameworkSubject from 'module/Learning/NextSteps/components/NextStepsFrameworkSubject'

import NextStepsDateAchievedForm, {
  NEXT_STEPS_DATE_ACHIEVED_FORM,
} from './components/NextStepsDateAchievedForm/NextStepsDateAchievedForm'
import {
  StyledAchievedForm,
  StyledAvatar,
  StyledComment,
  StyledContainer,
  StyledFrameworkAreas,
  StyledMainContent,
  StyledObservationDetails,
  StyledToolbar,
} from './NextStepRecordStyled'

const NextStepRecordView = ({
  isChildContext,
  isDateAchievedEditMode,
  isDateAchievedLoading,
  item,
  onDateAchievedCancelClick,
  onDateAchievedSaveClick,
  onMarkAsAchieved,
  onSubmit,
  setIsDateAchievedEditMode,
  singleItemIsFetching,
}) => {
  const {
    achievedAt,
    childObservation,
    comments,
    frameworkAreas,
    id,
    montessoriActivity,
    nextObservation,
  } = item

  const { child, observation } = childObservation

  const {
    author,
    id: observationId,
    observationDate,
  } = observation

  const type = !montessoriActivity ? NEXT_STEP_TYPE.EYFS : NEXT_STEP_TYPE.MONTESSORI

  const renderDateAchievedForm = () => (
    <Toolbar.Item>
      <StyledAchievedForm>
        <NextStepsDateAchievedForm
          form={`${NEXT_STEPS_DATE_ACHIEVED_FORM}_${id}`}
          initialValues={{ date: moment(achievedAt).utc() }}
          isEditMode={isDateAchievedEditMode}
          isLoading={isDateAchievedLoading}
          item={item}
          singleItemIsFetching={singleItemIsFetching}
          onCancelClick={onDateAchievedCancelClick}
          onSaveClick={onDateAchievedSaveClick}
          onSubmit={onSubmit}
        />
      </StyledAchievedForm>
    </Toolbar.Item>
  )

  const renderObservationDetails = () => (
    <StyledObservationDetails>
      <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} variant="span">
        <DateString date={observationDate} />
        {author && (
          <React.Fragment>
            {' '}
            {i18n.t('module:Learning:NextSteps:NextStepsList:by')}
            {' '}
            {author.name}
          </React.Fragment>
        )}
      </Typography>
    </StyledObservationDetails>
  )

  const renderRowContent = (
    <StyledContainer>
      <StyledMainContent>
        {renderObservationDetails()}
        <StyledComment>
          <Typography>
            {comments}
          </Typography>
        </StyledComment>
        <StyledFrameworkAreas>
          <NextStepsFrameworkSubject frameworkAreas={frameworkAreas} />
        </StyledFrameworkAreas>
        <StyledToolbar isDateAchievedEditMode={isDateAchievedEditMode}>
          <Toolbar>
            <Toolbar.Group alignItems="center">
              {!achievedAt && (
                <Toolbar.Item>
                  <Button
                    disabled={singleItemIsFetching}
                    label={i18n.t('module:Learning:NextSteps:NextStepsList:markAsAchieved')}
                    size="small"
                    negativeMargins
                    onClick={() => onMarkAsAchieved(item)}
                  />
                </Toolbar.Item>
              )}
              {achievedAt && nextObservation && (
                <Toolbar.Item>
                  <Button
                    disabled={singleItemIsFetching}
                    hierarchy="secondary"
                    label={i18n.t('module:Learning:NextSteps:NextStepsList:viewFollowUpObservation')}
                    size="small"
                    to={generateRoute('LEARNING.OBSERVATIONS.REVIEW', { observationId: nextObservation.id })}
                    negativeMargins
                  />
                </Toolbar.Item>
              )}
              {achievedAt && !nextObservation && !isDateAchievedEditMode && (
                <Toolbar.Item>
                  <Button
                    disabled={singleItemIsFetching}
                    hierarchy="secondary"
                    label={i18n.t('module:Learning:NextSteps:NextStepsList:makeObservation')}
                    size="small"
                    to={`${generateRoute('LEARNING.OBSERVATIONS.ADD')}?ns=${id}&type=${type}`}
                    negativeMargins
                  />
                </Toolbar.Item>
              )}
              {!isDateAchievedEditMode && (
                <Toolbar.Item>
                  <Button
                    hierarchy="tertiary"
                    label={i18n.t('module:Learning:NextSteps:NextStepsList:viewDetails')}
                    size="small"
                    to={generateRoute('LEARNING.OBSERVATIONS.REVIEW', { observationId })}
                    negativeMargins
                  />
                </Toolbar.Item>
              )}
            </Toolbar.Group>
            {achievedAt && (
              <Toolbar.Group alignItems="center">
                {renderDateAchievedForm()}
                {!isDateAchievedEditMode && !nextObservation && (
                  <Toolbar.Item>
                    <Button
                      disabled={singleItemIsFetching}
                      hierarchy="tertiary"
                      icon="edit"
                      size="small"
                      negativeMargins
                      onClick={() => setIsDateAchievedEditMode(true)}
                    />
                  </Toolbar.Item>
                )}
              </Toolbar.Group>
            )}
          </Toolbar>
        </StyledToolbar>
      </StyledMainContent>
    </StyledContainer>
  )

  return (
    <List.Standard.Row
      customContent={renderRowContent}
      key={id}
      leftColumn={!isChildContext && (
        <StyledAvatar>
          <GroupAvatars
            borderColor={NEUTRAL_COLOURS.SILVER}
            persons={[child]}
            renderUrl={({ id: childId }) => generateRoute('CHILDREN.CHILD.LEARNING_JOURNEY.NEXT_STEPS', { childId })}
          />
        </StyledAvatar>
      )}
    />
  )
}

export default NextStepRecordView
