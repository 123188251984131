import { Snackbar } from '@material-ui/core'

import React, { useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { FEATURE_FLAGS } from 'constants/security'

import eventBus from 'utils/eventBus'
import auth from 'utils/auth'

import { Icon, Typography } from 'components'

import i18n from 'translations'

import { StyledSnackbarContainer, StyledWrapper } from './NetworkConnectionBarStyled'

export const SHOW_SYNC_BAR_EVENT = 'EVENT/SHOW_SYNC_BAR_EVENT'

const mapState = (state) => ({
  OfflineModeEnabled: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.OFFLINE_MODE],
  }),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const NetworkConnectionBar: React.FC<PropsFromRedux> = ({
  OfflineModeEnabled,
}) => {
  const [isOnline, setIsOnline] = useState<boolean>(true)
  const [isVisibleSyncBarVersion, setIsVisibleSyncBarVersion] = useState<boolean>(false)

  const beforeUnload = (e) => {
    if (isVisibleSyncBarVersion) {
      e.preventDefault()
      e.returnValue = ''
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload, false)

    return () => {
      window.removeEventListener('beforeunload', beforeUnload)
    }
  }, [isVisibleSyncBarVersion])

  const syncBarAction = (e) => setIsVisibleSyncBarVersion(e.detail)

  const setAsOffline = () => setIsOnline(false)

  const setAsOnline = () => setIsOnline(true)

  useEffect(() => {
    if (!navigator.onLine) {
      setIsOnline(false)
    }

    window.addEventListener('offline', setAsOffline)
    window.addEventListener('online', setAsOnline)
    eventBus.on(SHOW_SYNC_BAR_EVENT, syncBarAction)

    return () => {
      window.removeEventListener('offline', setAsOffline)
      window.removeEventListener('online', setAsOnline)
      eventBus.remove(SHOW_SYNC_BAR_EVENT, syncBarAction)
    }
  }, [])

  if (isOnline && !isVisibleSyncBarVersion) {
    return null
  }

  const renderMessage = () => {
    if (isVisibleSyncBarVersion) {
      return (
        <Typography>
          {i18n.t('module:Shell:NetworkConnectionBar:dataIsSyncing')}
        </Typography>
      )
    }

    return (
      <StyledWrapper>
        <Icon
          color={NEUTRAL_COLOURS.WHITE}
          height={20}
          icon="offline"
        />
        <Typography>
          {OfflineModeEnabled
            ? (i18n.t('module:Shell:NetworkConnectionBar:youAreOfflineAndCanOnlyUse'))
            : i18n.t('module:Shell:NetworkConnectionBar:youAreOffline')}
        </Typography>
      </StyledWrapper>
    )
  }

  return (
    <StyledSnackbarContainer>
      <Snackbar
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        message={renderMessage()}
        open
      />
    </StyledSnackbarContainer>
  )
}

const enhance = compose(
  connector,
)

export default enhance(NetworkConnectionBar)
