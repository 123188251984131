import React from 'react'

import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'
import { FILE_TYPES } from 'constants/mimetypes'

import { AlertBox, Callout, Form, MediaPicker, NumberedContent, Typography } from 'components'

import i18n from 'translations'

import { StyledLink, StyledList, StyledListItem, StyledSectionContent } from '../FinancePaymentsImportStyled'

const ImportIntro = ({
  errorMessages,
  files,
  isProcessing,
  onCancelClick,
  onDownloadTemplateClick,
  onFilesChange,
  onImportClick,
  onRemoveFile,
}) => {
  const renderFirstSectionContent = () => (
    <StyledSectionContent>
      <Typography>
        {i18n.t('module:Modals:PaymentsImport:Intro:Section1:text')}
      </Typography>
      <Typography linkColorInherit={false}>
        <StyledLink onClick={onDownloadTemplateClick}>
          {i18n.t('module:Modals:PaymentsImport:Intro:Section1:link')}
        </StyledLink>
      </Typography>
    </StyledSectionContent>
  )

  const renderSecondSectionContent = () => (
    <StyledSectionContent>
      <Typography>
        {i18n.t('module:Modals:PaymentsImport:Intro:Section2:Text:1')}
      </Typography>
      <Typography>
        {i18n.t('module:Modals:PaymentsImport:Intro:Section2:Text:2')}
      </Typography>
      <AlertBox
        color={FLAG_COLOURS.ERROR}
        content={(
          <StyledList>
            <StyledListItem>{i18n.t('module:Modals:PaymentsImport:Intro:Section2:Important:List:1')}</StyledListItem>
            <StyledListItem>{i18n.t('module:Modals:PaymentsImport:Intro:Section2:Important:List:2')}</StyledListItem>
            <StyledListItem>{i18n.t('module:Modals:PaymentsImport:Intro:Section2:Important:List:3')}</StyledListItem>
          </StyledList>
        )}
        title={i18n.t('module:Modals:PaymentsImport:Intro:Section2:Important:title')}
      />
    </StyledSectionContent>
  )

  const renderThirdSectionContent = () => (
    <StyledSectionContent>
      <MediaPicker
        accept={[FILE_TYPES.CSV, FILE_TYPES.XLS]}
        buttonLabel={i18n.t('module:Modals:PaymentsImport:Intro:Section3:button')}
        filesLimit={1}
        totalResults={files?.length}
        value={files}
        buttonSecondary
        disableMediaTag
        disableTagging
        editMode
        hideButtonWhenFileLimitExceeded
        hideEditOption
        hideStatistics
        hideSupportedFileTypes
        noCloudUpload
        sync
        onChange={onFilesChange}
        onRemoveFile={onRemoveFile}
      />
      <Typography color={NEUTRAL_COLOURS.GRAY} margin="15px 0 0">
        {i18n.t('module:Modals:PaymentsImport:Intro:Section3:text')}
      </Typography>
    </StyledSectionContent>
  )

  const renderContent = () => (
    <NumberedContent>
      <NumberedContent.Item
        content={renderFirstSectionContent()}
        title={i18n.t('module:Modals:PaymentsImport:Intro:Section1:title')}
      />
      <NumberedContent.Item
        content={renderSecondSectionContent()}
        title={i18n.t('module:Modals:PaymentsImport:Intro:Section2:title')}
      />
      <NumberedContent.Item
        content={renderThirdSectionContent()}
        title={i18n.t('module:Modals:PaymentsImport:Intro:Section3:title')}
      />
    </NumberedContent>
  )

  const renderFooter = () => (
    <Form.StickyFooter
      isSubmitting={isProcessing}
      submitDisabled={!files?.length || errorMessages}
      submitLabel={i18n.t('global:import')}
      onCancelClick={onCancelClick}
      onSubmitClick={() => onImportClick(false)}
    />
  )

  return (
    <React.Fragment>
      <Callout content={errorMessages} error />
      {renderContent()}
      {renderFooter()}
    </React.Fragment>
  )
}

export default ImportIntro
