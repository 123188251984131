import { compose } from 'recompose'

import { FEATURE_FLAGS, ROLES } from 'constants/security'

import { withRouter } from 'services/router'

const MessagingWrapperContainer = ({ children }) => children

MessagingWrapperContainer.authParams = {
  flags: [FEATURE_FLAGS.MESSAGING],
  nurseryContext: true,
  roles: [
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.NURSERY_MANAGER,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ROOM_LEADER,
    ROLES.SENIOR_TEACHER,
    ROLES.SUPER_ADMIN,
    ROLES.TEACHER,
  ],
}

const enhance = compose(
  withRouter,
)

export default enhance(MessagingWrapperContainer)
