import styled from 'styled-components'

export const StyledField = styled.div`
  display: grid;
  grid-template-columns: 160px auto;
  grid-column-gap: 10px;
  margin-bottom: 25px;
`

export const StyledDotStatus = styled.div`
  display: inline-block;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  
  ${({ color }) => color && `
    background: ${color};
  `}    
`
