import _ from 'lodash'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { withChildService } from 'services/legacy/child'
import { withRouter } from 'services/router'

import * as helpers from './ChildPickerModalHelpers'

import ChildPickerModalView from './ChildPickerModalView'

const CHILDREN_INCREASED_LIMIT = 40

class ChildPickerModalContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      childrenIsFetching: false,
      roomFilter: null,
      search: '',
      selected: props.selected ? _.map(props.selected, ({ id }) => id) : [],
    }
  }

  componentDidMount() {
    const { childHelpers, childSelectors, childrenGroups, childrenList } = this.props

    if (!childrenList) {
      this.setState({ childrenIsFetching: true })

      const criteria = childSelectors.getCriteria({ isArchived: false })

      childHelpers.getAllChildren({
        criteria,
        groups: childrenGroups,
        limit: CHILDREN_INCREASED_LIMIT,
      }, 1, () => {
        this.setState({ childrenIsFetching: false })
      })
    }
  }

  getFilteredChildrenList = () => {
    const { childrenList } = this.props
    const { roomFilter, search } = this.state

    return helpers.getFilteredChildrenList(childrenList, search, roomFilter)
  }

  getItemSelected = (id) => {
    const { selected } = this.state

    return _.includes(selected, id)
  }

  selectItem = (id) => {
    const { multiple = true } = this.props

    this.setState((prevState) => {
      if (!multiple) {
        if (_.includes(prevState.selected, id)) {
          return { selected: [] }
        }

        return { selected: [id] }
      }

      if (_.includes(prevState.selected, id)) {
        return { selected: _.remove(prevState.selected, (n) => n !== id) }
      }

      return { selected: [...prevState.selected, id] }
    })
  }

  handleSearchChange = (search) => {
    this.setState({ search })
  }

  handleRoomFilterChange = (roomFilter) => {
    this.setState({ roomFilter })
  }

  handleSelectAllChange = (checked) => {
    const filteredChildrenList = this.getFilteredChildrenList()
    const filteredChildrenListIds = _.map(filteredChildrenList, (item) => item.id)

    if (checked) {
      return this.setState((prevState) => ({ selected: _.uniq([...prevState.selected, ...filteredChildrenListIds]) }))
    }

    return this.setState((prevState) => ({
      selected: _.remove(prevState.selected, (n) => !_.includes(filteredChildrenListIds, n)),
    }))
  }

  handleItemClick = (id) => {
    this.selectItem(id)
  }

  handleConfirmClick = () => {
    const { childrenList, hideModal, onConfirm, selected: originalSelected } = this.props
    const { selected } = this.state

    const selectedChildren = helpers.getSelectedChildren(childrenList, selected, originalSelected)

    onConfirm(selectedChildren)
    hideModal()
  }

  render() {
    const { childrenList, disableSelectAll, emptyStateText } = this.props
    const { childrenIsFetching, roomFilter, search, selected } = this.state

    const filteredChildrenList = this.getFilteredChildrenList()
    const roomFilterOptions = helpers.getRoomFilterOptions(childrenList)
    const selectAll = helpers.getSelectAll(selected, filteredChildrenList)
    const isEmpty = childrenList && 0 === childrenList.length

    return (
      <ChildPickerModalView
        disableSelectAll={disableSelectAll}
        emptyStateText={emptyStateText}
        filteredChildrenList={filteredChildrenList}
        getItemSelected={this.getItemSelected}
        isEmpty={isEmpty}
        isLoading={childrenIsFetching}
        roomFilter={roomFilter}
        roomFilterOptions={roomFilterOptions}
        search={search}
        selectAll={selectAll}
        onConfirmClick={this.handleConfirmClick}
        onItemClick={this.handleItemClick}
        onRoomFilterChange={this.handleRoomFilterChange}
        onSearchChange={this.handleSearchChange}
        onSelectAllChange={this.handleSelectAllChange}
      />
    )
  }
}

const mapState = (state, { childSelectors }) => ({
  childrenList: childSelectors.getChildListDataSelector(state),
})

const enhance = compose(
  withChildService,
  withRouter,
  connect(mapState),
)

export default enhance(ChildPickerModalContainer)
