import _ from 'lodash'

import { generateRoute } from 'utils/routing'

import { Button } from 'components'

import i18n from 'translations'

export const getNurseryConsumablesTableData = (data) => _.map(data, ({ archived, id, name }) => ({
  action: (
    <Button.TableAction
      to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.CONSUMABLES.EDIT', { itemId: id })}
      edit
    />
  ),
  id,
  name: `${name}${archived ? ` (${i18n.t('global:archived')})` : ''}`,
}))
