import React from 'react'

import { Icon } from 'components'

import i18n from 'translations'

import { StyledContainer, StyledIconContainer, StyledPreviewLineContainer } from './FundingLineItemLabelStyled'

interface FundingLineItemLabelProps {
  label: string
  onPreviewClick?: (type: string) => void
}

const FundingLineItemLabel = ({ label, onPreviewClick }: FundingLineItemLabelProps): JSX.Element => (
  <StyledContainer>
    {label}
    <StyledPreviewLineContainer onClick={onPreviewClick}>
      <StyledIconContainer>
        <Icon height={20} icon="details-view" />
      </StyledIconContainer>
      {i18n.t('module:Management:Finance:Funding:Add:InvoiceDisplayOptions:previewLineItem')}
    </StyledPreviewLineContainer>
  </StyledContainer>
)

export default FundingLineItemLabel
