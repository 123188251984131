import moment from 'moment'

const DATE_FORMAT = 'YYYY-MM-DD'

export const getInvoicePeriodDropdown = () => {
  const startDate = moment().add(-6, 'M').startOf('month')
  const endDate = moment().add(+5, 'M').startOf('month')

  const options = []

  while (endDate > startDate || startDate.format('M') === endDate.format('M')) {
    const optionStartDate = moment(startDate).startOf('month').format(DATE_FORMAT)
    const optionEndDate = moment(startDate).endOf('month').format(DATE_FORMAT)

    options.push({
      endDate: optionEndDate,
      label: startDate.format('MMMM YYYY'),
      startDate: optionStartDate,
      value: `${optionStartDate}|${optionEndDate}`,
    })

    startDate.add(1, 'month')
  }

  return options
}
