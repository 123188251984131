import { generateBasicActions } from 'utils/service'

import {
  CLEAR_FINANCE_REPORTS_NURSERIES_AGE_DEBT,
  FINANCE_REPORTS_NURSERIES_AGE_DEBT,
  FINANCE_REPORTS_NURSERIES_AGE_DEBT_FAILED,
  FINANCE_REPORTS_NURSERIES_AGE_DEBT_SUCCESS,
} from './constants'

export const getNurseries = (options = {}) => ({ financeBadDebtReportApiClient }) => generateBasicActions.list({
  constants: {
    failed: FINANCE_REPORTS_NURSERIES_AGE_DEBT_FAILED,
    init: FINANCE_REPORTS_NURSERIES_AGE_DEBT,
    success: FINANCE_REPORTS_NURSERIES_AGE_DEBT_SUCCESS,
  },
  options,
  service: financeBadDebtReportApiClient,
  serviceMethod: 'getNurseries',
})

export const clearNurseries = () => ({
  type: CLEAR_FINANCE_REPORTS_NURSERIES_AGE_DEBT,
})
