import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withNurseriesService } from 'services/nurseries'
import { withChildSessionsService } from 'services/legacy/childSessions'
import { withRouter } from 'services/router'

import ChildSessionsAdd from 'module/Children/Child/ChildBookingPattern/ChildSessions/ChildSessionsAdd'

import ChildSessionsHistoryView from './ChildSessionsHistoryView'

const NURSERY_SETTINGS_GROUPS = { read: ['nursery.settings', 'nurserySettings'] }

class ChildSessionsHistoryContainer extends Component {
  componentDidMount() {
    const {
      childSessionsActions,
      childSessionsSelectors,
      nurseriesActions,
      nurseryOptions,
      params,
    } = this.props
    const { childId } = params

    const apiParams = { groups: NURSERY_SETTINGS_GROUPS }

    nurseriesActions.get(nurseryOptions.id, {
      params: apiParams,
    })

    const criteria = childSessionsSelectors.getCriteria({ archived: 0 })

    childSessionsActions.groupedByList(childId, {
      criteria,
      includes: ['attendancePeriod', 'invoiced', 'fundingAssigned'],
    })
  }

  render() {
    const {
      authAccessMap,
      child,
      childSessions,
      childSessionsListState,
      errorMessages,
      isFetching,
      isOpeningDaysEmpty,
    } = this.props

    const childHistorySessions = childSessions ? childSessions.past : null

    const isHistoryEmpty = childSessionsListState.isPastEmpty

    return (
      <ChildSessionsHistoryView
        authAccessMap={authAccessMap}
        child={child}
        childHistorySessions={childHistorySessions}
        errorMessages={errorMessages}
        isHistoryEmpty={isHistoryEmpty}
        isLoading={isFetching}
        isOpeningDaysEmpty={isOpeningDaysEmpty}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childSelectors,
  childSessionsListState,
  childSessionsSelectors,
  nurseriesSelectors,
  params,
}) => ({
  authAccessMap: {
    module: {
      ChildSessionsAdd: auth.SELECTORS.getComponentIsAuthorised(state, ChildSessionsAdd),
    },
  },
  child: childSelectors.getChildSelector(state),
  childSessions: childSessionsSelectors.getChildSessionsListSelectors(state),
  errorMessages: appSelectors.getErrorMessages(childSessionsListState),
  isAllYearRoundSelected: nurseriesSelectors.isAllYearRoundSelected(state),
  isFetching: appSelectors.getIsFetching(childSessionsListState),
  isOpeningDaysEmpty: nurseriesSelectors.isOpeningDaysEmpty(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const enhance = compose(
  withRouter,
  withAppService,
  withChildService,
  withNurseriesService,
  withChildSessionsService,
  connect(mapState),
)

export default enhance(ChildSessionsHistoryContainer)
