import {
  GET_FORMATIVE_REPORTS_PERIODS,
  GET_FORMATIVE_REPORTS_PERIODS_FAILED,
  GET_FORMATIVE_REPORTS_PERIODS_SUCCESS,
} from './constants'

export const getFormativeReportsPeriods = (params) => ({ formativeReportsApiClient }) => (dispatch) => {
  dispatch({ params, type: GET_FORMATIVE_REPORTS_PERIODS })

  formativeReportsApiClient.getPeriodLabels(params).then(
    (data) => {
      dispatch({
        payload: data,
        type: GET_FORMATIVE_REPORTS_PERIODS_SUCCESS,
      })
    },
    (error) => {
      dispatch({
        error: error.response,
        type: GET_FORMATIVE_REPORTS_PERIODS_FAILED,
      })
    },
  )
}
