import { Request } from 'constants/http'
import { HEADER_DEVICE_TOKEN, HEADER_SUBDOMAIN, HEADER_VERSION_HASH } from 'services/security/authorization/constants'
import { findDomainSuffix, getEffectiveAuthenticationSubdomain, hostname } from 'services/subdomain/single/selectors'
import DeviceIdentityService from 'core/services/DeviceIdentityService'
import CookieIdentityService from 'core/services/CookieIdentityService'

const attachHeaders = (request, versionHash) => {
  const domainSuffix = findDomainSuffix(hostname)
  const authSubdomain = getEffectiveAuthenticationSubdomain(hostname, domainSuffix)

  const headers = {}

  if (!request.subdomainNotRequired && (authSubdomain || request.customSubdomain)) {
    headers[HEADER_SUBDOMAIN] = request.customSubdomain || authSubdomain
  }

  const deviceToken = DeviceIdentityService.getIdentity()

  if (!request.deviceTokenNotRequired && deviceToken) {
    headers[HEADER_DEVICE_TOKEN] = deviceToken
  }

  if (!versionHash) {
    console.warn('[VERSION_HASH] missing version.') // eslint-disable-line
  } else {
    headers[HEADER_VERSION_HASH] = versionHash
  }

  return {
    ...request.headers,
    ...headers,
  }
}

const AuthorizationMiddleware = (versionHash) => async (request: Request, next) => {
  if (request.uri) {
    return next(request)
  }

  const identity = CookieIdentityService.getIdentity()
  const pinIdentity = CookieIdentityService.getPinIdentity()

  const token = request.token || identity?.token || pinIdentity?.token
  const headers = attachHeaders(request, versionHash)

  if (!token) {
    return next({ ...request, headers })
  }

  const authorization = `Bearer ${token}`

  return next({
    ...request,
    headers: {
      ...headers,
      authorization,
    },
  })
}

export default AuthorizationMiddleware
