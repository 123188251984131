import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { ROLES, RolesDetails } from 'constants/security'
import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { getDateString } from 'utils/date'

import { getAvailableRoles } from 'services/security/user/selectors'
import { getUserTitlesOptions } from 'services/app/applicationContext/selectors'
import { isAdministrationContext } from 'services/security/authorization/selectors'

import { MEMBERSHIP_TYPE } from '../constants'

const getMembershipSelector = (state) => state.memberships.single

export const getMembershipDataSelector = createSelector([getMembershipSelector], (state) => state.data)

export const getMembershipIsAcceptedSelector = createSelector([getMembershipDataSelector], (data) => data?.accepted)

export const getMembershipIsArchivedSelector = createSelector([getMembershipDataSelector], (data) => data?.archived)

export const getInitialValues = createSelector(
  [getMembershipDataSelector, getAvailableRoles, getUserTitlesOptions, isAdministrationContext],
  (data, availableRoles, userTitles, adminContext) => ({
    ...data,
    nurseries: _.map(data?.nurseries || [], (nursery) => ({
      label: nursery.name,
      value: nursery.id,
    })),
    role: (data?.roles?.length && _.find(availableRoles, (role) => role.value === data?.roles[0]))
      || (adminContext && availableRoles[0])
      || null,
    title: _.find(userTitles, (title) => title.value === data?.title),
  }),
)

export const getFormData = (
  data,
  isCreating = false,
  isAdminContext = false,
) => {
  const {
    birthDate,
    membershipProfile,
    nurseries,
    password,
    role,
    title,
  } = data

  const { dbsCheck } = membershipProfile || {}

  const {
    certificateId,
    reviewBy,
    reviewDate,
  } = dbsCheck || {}

  const body = {
    ...data,
    birthDate: getDateString(birthDate),
    membershipProfile: membershipProfile || {},
    nurseries: nurseries && _.map(nurseries || [], (nursery) => ({ id: nursery?.value })),
    plainPassword: password,
    role: role?.value,
    title: title?.value || null,
  }

  if (!certificateId && !reviewBy && (!reviewDate || !moment(reviewDate).isValid())) {
    if (isCreating) {
      delete body?.membershipProfile?.dbsCheck
    } else {
      body.membershipProfile.dbsCheck = null
    }
  }

  if (body.membershipProfile?.dbsCheck?.reviewDate) {
    body.membershipProfile.dbsCheck.reviewDate = moment(reviewDate).format(DEFAULT_DATE_FORMAT)
  }

  if (_.isEmpty(body.membershipProfile) && isCreating) {
    body.membershipProfile = {}
  }

  if (isAdminContext) {
    delete body.role
  }

  return body
}

export const getIsAreaManager = createSelector(
  [getMembershipDataSelector],
  (data) => 0 < data?.roles?.length && data.roles[0] === RolesDetails[ROLES.ORGANIZATION_LINE_MANAGER].value,
)

export const getMembershipFormattedDataSelector = createSelector([getMembershipDataSelector], (data) => {
  if (!data) {
    return null
  }

  const { roles } = data

  return {
    ...data,
    roleLabels: _.join(_.map(roles, (role) => RolesDetails[role]?.label), ', '),
  }
})

export const isPinSetForMembership = createSelector(
  [getMembershipDataSelector],
  (memberShipData) => memberShipData?.hasPin,
)

export const isOrganizationMember = createSelector(
  [getMembershipDataSelector],
  (memberShipData) => MEMBERSHIP_TYPE.ORGANIZATION === memberShipData?.type,
)
