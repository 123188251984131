import _ from 'lodash'

import React from 'react'

import { generateRoute } from 'utils/routing'

import { Avatar, Button, Typography } from 'components'

import i18n from 'translations'

export const getTableData = (carers, childId) => {
  if (!carers?.length) {
    return null
  }

  return _.map(carers, (carer) => {
    const {
      carerChildRelations,
      firstName,
      fullName,
      id,
      photo,
      surname,
    } = carer
    const {
      hasAccessToChildInParentApp,
      hasParentalResponsibility,
      relation: childRelation,
    } = carerChildRelations[0]

    return {
      name: (
        <Avatar
          initials={[firstName, surname]}
          src={photo}
          title={(
            <Typography primary>
              {fullName}
            </Typography>
          )}
          to={`${generateRoute('CHILDREN.CHILD.CONTACTS.EDIT', {
            carerId: id,
            childId,
          })}`}
        />
      ),
      parentAppAccess: hasAccessToChildInParentApp ? i18n.t('module:Children:Child:Contacts:List:Access') : '-',
      parentalResponsibility: hasParentalResponsibility ? i18n.t('global:Yes') : '-',
      relation: childRelation || '-',
      rowToButton: (
        <Button.TableAction
          to={`${generateRoute('CHILDREN.CHILD.CONTACTS.EDIT', {
            carerId: id,
            childId,
          })}`}
          edit
        />
      ),
    }
  })
}
