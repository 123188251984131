import _ from 'lodash'

import { Link } from 'react-router'

import { typeByObject } from 'utils/typescript'

import { BUTTON_HIERARCHY, CUSTOM_COLORS_OPTIONS } from './constants'

export const getVariant = (hierarchy: typeByObject<typeof BUTTON_HIERARCHY>) => ({
  [BUTTON_HIERARCHY.PRIMARY]: 'contained',
  [BUTTON_HIERARCHY.SECONDARY]: 'outlined',
  [BUTTON_HIERARCHY.TERTIARY]: 'text',
}[hierarchy])

export const getCustomColor = (color: string) => _.find(CUSTOM_COLORS_OPTIONS, { value: color })?.color || color

export const getComponent = (target, to: string) => {
  if (to && target) {
    return 'a'
  }

  if (to) {
    return Link
  }

  return 'button'
}
