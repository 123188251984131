import _ from 'lodash'

import React from 'react'

import { StyledSessionContainer, StyledSessionRow, StyledWeekDay } from './SessionDetailsStyled'

const renderSession = ({ day, end, start }) => {
  if (!start && !end) {
    return null
  }

  return (
    <StyledSessionRow key={day}>
      <StyledWeekDay>
        { day.substring(0, 3) }
      </StyledWeekDay>
      <span>
        {start}
        &nbsp;-&nbsp;
        {end}
      </span>
    </StyledSessionRow>
  )
}

const SessionDayStartEndDetails = ({ alignItems, minWidth, sessions }) => (
  <StyledSessionContainer alignItems={alignItems} minWidth={minWidth}>
    {_.map(sessions, renderSession)}
  </StyledSessionContainer>
)

export default SessionDayStartEndDetails
