import styled from 'styled-components'

export const StyledTimeAndDate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const StyledTimeSpan = styled.span`
  font-size: 90px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: .8;
  margin: 0;
`

export const StyledDateSpan = styled.span`
  font-size: 26px;
  line-height: .8;
  margin: 20px 0;
  text-align: center;
  letter-spacing: 0;
`
