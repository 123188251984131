import i18n from 'translations'

export const LEAVE_TYPE_STATUSES = {
  ACTIVE: 'ACTIVE',
  ALL_LEAVE_TYPES: 'ALL_LEAVE_TYPES',
  ARCHIVED: 'ARCHIVED',
}

export const LEAVE_TYPE_STATUSES_DROPDOWN = [
  {
    label: i18n.t('global:Active'),
    value: LEAVE_TYPE_STATUSES.ACTIVE,
  },
  {
    label: i18n.t('module:Management:LeaveTypes:Staff:allLeaveTypes'),
    value: LEAVE_TYPE_STATUSES.ALL_LEAVE_TYPES,
  },
  {
    label: i18n.t('global:Archived'),
    value: LEAVE_TYPE_STATUSES.ARCHIVED,
  },
]
