import React from 'react'

import { Callout, Page, Section, Spinner } from 'components'

import ChildDiscountsAddForm from './components/ChildDiscountsAddForm'

const ChildDiscountsAddView = ({
  child,
  discountTypeOptions,
  errorMessages,
  formValues,
  initialValues,
  isArchived,
  isEdit,
  isFormLoading,
  isLoading,
  isOtherSelected,
  isPercentageSelected,
  nurseryDiscountDescription,
  onArchiveClick,
  onSubmit,
}) => {
  const { birthDate: childDob, id: childId } = child

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <ChildDiscountsAddForm
        childDob={childDob}
        childId={childId}
        discountTypeOptions={discountTypeOptions}
        formValues={formValues}
        initialValues={initialValues}
        isEdit={isEdit}
        isLoading={isFormLoading}
        isOtherSelected={isOtherSelected}
        isPercentageSelected={isPercentageSelected}
        nurseryDiscountDescription={nurseryDiscountDescription}
        onSubmit={onSubmit}
      />
    )
  }

  return (
    <Page.Section
      actions={isEdit && (
        <Section.Actions
          options={[{
            onClick: onArchiveClick(!isArchived),
            type: isArchived ? 'unarchive' : 'archive',
          }]}
        />
      )}
      isLoading={isLoading}
      title={`${isEdit ? 'Edit' : 'Add'} discount`}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default ChildDiscountsAddView
