import _ from 'lodash'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { STATUS_OPTIONS_VALUES } from 'services/users/constants'
import { ROLES, RolesDetails } from 'constants/security'

import { getCurrentUserHighestRole } from 'services/security/user/selectors'

const getMembershipsListSelector = (state) => state.memberships.list

export const getMembershipsListDataSelector = createSelector([getMembershipsListSelector], (state) => state.data)

const isEditableForCurrentUser = (membershipRoles, currentUserRoleHierarchy, currentUserRoleValue) => {
  if (_.isEmpty(membershipRoles)) {
    return true
  }

  const membershipRoleValue = membershipRoles[0]
  const membershipRoleHierarchy = _.values(RolesDetails).find(
    (role) => role.value === membershipRoleValue,
  )?.roleHierarchy

  if (membershipRoleHierarchy === currentUserRoleHierarchy) {
    return membershipRoleValue === currentUserRoleValue
  }

  return membershipRoleHierarchy < currentUserRoleHierarchy
}

export const getMembershipsListDataWithIsEditable = createSelector(
  [getMembershipsListDataSelector, getCurrentUserHighestRole],
  (memberships, { roleHierarchy, value }) => _.map(memberships, (membership) => ({
    ...membership,
    isEditableForCurrentUser: isEditableForCurrentUser(
      membership.roles,
      roleHierarchy,
      value,
    ),
  })),
)

export const getDropdownMemberships = createSelector([getMembershipsListDataSelector], (data) => _.map(
  data,
  (item) => ({
    avatar: item.photo,
    email: item.email,
    label: item.name,
    value: item.id,
  }),
))
export const getOrganizationMembershipsListCriteria = (params) => {
  const { includeDeputyManager = false, search } = params

  const criteria = [{
    field: 'archived',
    value: 0,
  }]

  if (search) {
    criteria.push({
      field: 'concatenable',
      value: search,
    })
  }

  if (!includeDeputyManager) {
    criteria.push({
      field: 'not[roles]',
      value: ROLES.DEPUTY_MANAGER,
    })
  }

  return criteria
}

export const getMembershipsListCriteria = (params) => {
  const {
    disablePendingFilter,
    email,
    nonTeaching = false,
    search,
    status = STATUS_OPTIONS_VALUES.ACTIVE,
    type,
    username,
    nursery,
    organization,
    role,
  } = params
  const criteria = []

  if (search) {
    criteria.push({
      field: 'concatenable',
      value: search,
    })
  }

  if (STATUS_OPTIONS_VALUES.ACTIVE === status) {
    criteria.push({
      field: 'archived',
      value: 0,
    })

    if (!disablePendingFilter) {
      criteria.push({
        field: 'accepted',
        value: 1,
      })
    }
  }

  if (STATUS_OPTIONS_VALUES.DISABLED === status) {
    criteria.push({
      field: 'archived',
      value: 1,
    })
  }

  if (STATUS_OPTIONS_VALUES.PENDING === status) {
    criteria.push({
      field: 'archived',
      value: 0,
    })

    if (!disablePendingFilter) {
      criteria.push({
        field: 'accepted',
        value: 0,
      })
    }
  }

  if (type) {
    criteria.push({
      field: 'type',
      value: 'superAdmin',
    })
  }

  if (email) {
    criteria.push({
      field: 'email',
      value: email,
    })
  }

  if (username) {
    criteria.push({
      field: 'username',
      value: username,
    })
  }

  if (!nonTeaching) {
    criteria.push({
      field: 'not[roles]',
      value: ROLES.NON_TEACHING,
    })
  }

  if (nursery) {
    criteria.push({
      field: 'nursery',
      value: nursery,
    })
  }

  if (role) {
    if (_.isArray(role)) {
      _.forEach(role, (roleType) => {
        criteria.push({
          field: 'roles[]',
          value: roleType,
        })
      })
    } else {
      criteria.push({
        field: 'roles',
        value: role,
      })
    }
  }

  if (organization) {
    criteria.push({
      field: 'organization',
      value: organization,
    })
  }

  return criteria
}

export const getStaffRotaCriteria = ({ reportDatesRange, sendTo, staffMembers }) => {
  const criteria = []

  if (sendTo) {
    criteria.push({
      field: 'membership',
      value: sendTo?.value,
    })
  }

  if (reportDatesRange?.length) {
    const [startDate, endDate] = reportDatesRange

    criteria.push(
      {
        field: 'shifts.dateTo[after]',
        value: startDate.format(DEFAULT_DATE_FORMAT),
      },
      {
        field: 'shifts.dateFrom[before]',
        value: endDate.format(DEFAULT_DATE_FORMAT),
      },
      {
        field: 'shifts.membershipShiftTimes.day[after]',
        value: startDate.format(DEFAULT_DATE_FORMAT),
      },
      {
        field: 'shifts.membershipShiftTimes.day[before]',
        value: endDate.format(DEFAULT_DATE_FORMAT),
      },
    )
  }

  if (staffMembers?.value) {
    criteria.push({
      field: 'id[]',
      value: staffMembers.value,
    })
  }

  return criteria
}

export const getStaffWorklogCriteria = ({ reportDatesRange, sendTo, staffMembers }) => {
  const criteria = []

  if (sendTo?.value) {
    criteria.push({
      field: 'membership',
      value: sendTo.value,
    })
  }

  if (reportDatesRange?.length) {
    const [startDate, endDate] = reportDatesRange

    criteria.push(
      {
        field: 'currentRegisters.date[after]',
        value: startDate.format(DEFAULT_DATE_FORMAT),
      },
      {
        field: 'currentRegisters.date[before]',
        value: endDate.format(DEFAULT_DATE_FORMAT),
      },
    )
  }

  if (staffMembers?.value) {
    criteria.push({
      field: 'id[]',
      value: staffMembers.value,
    })
  }

  return criteria
}

export const getStaffPayrollCriteria = ({ reportDatesRange, sendTo, staffMembers }) => {
  const criteria = []

  if (sendTo) {
    criteria.push({
      field: 'membership',
      value: sendTo?.value,
    })
  }

  if (reportDatesRange?.length) {
    const [startDate, endDate] = reportDatesRange

    criteria.push(
      {
        field: 'startDate',
        value: startDate.format(DEFAULT_DATE_FORMAT),
      },
      {
        field: 'endDate',
        value: endDate.format(DEFAULT_DATE_FORMAT),
      },
    )
  }

  if (staffMembers?.value) {
    criteria.push({
      field: 'id',
      value: staffMembers.value,
    })
  }

  return criteria
}
