import React from 'react'
import { WrappedFieldProps } from 'redux-form'

import { FieldError, Form, Label, Select, TimePicker } from 'components'

import i18n from 'translations'

import {
  StyledContainer,
  StyledContentContainer,
  StyledErrorContainer,
  StyledGroupContainer,
  StyledItemContainer,
} from './FormChildSessionStyled'

type FormChildSessionFullProps = WrappedFieldProps & {
  deletable?: boolean
  disabled?: boolean
  dropdownWidth?: string
  onDeleteClick?: () => void
  options: any[]
  showHoursByDefault?: boolean
}

const FormChildSession: React.FC<FormChildSessionFullProps> = ({
  deletable = true,
  disabled,
  dropdownWidth,
  input: { onChange, value },
  meta: { error, submitFailed },
  onDeleteClick,
  options,
  showHoursByDefault,
}) => {
  const handleChange = (type) => (val) => {
    let newVal

    if ('sessionId' === type) {
      newVal = { ...value, sessionId: val }
    }

    if ('startTime' === type) {
      newVal = { ...value, startTime: val }
    }

    if ('endTime' === type) {
      newVal = { ...value, endTime: val }
    }

    return onChange && onChange(newVal)
  }

  const { endTime, sessionId, startTime } = value || {}
  const { endTime: sessionEndTime, isHourly, startTime: sessionStartTime } = sessionId || {}

  const newStartTime = isHourly ? startTime : sessionStartTime
  const newEndTime = isHourly ? endTime : sessionEndTime

  return (
    <StyledContainer>
      <StyledContentContainer>
        <StyledGroupContainer>
          <StyledItemContainer $width={dropdownWidth}>
            <Select
              disabled={disabled}
              options={options}
              searchable={false}
              value={value.sessionId}
              onChange={handleChange('sessionId')}
            />
          </StyledItemContainer>
          {deletable && !disabled && (
            <StyledItemContainer>
              <Form.RemoveRowButton onClick={onDeleteClick} />
            </StyledItemContainer>
          )}
        </StyledGroupContainer>
        {(showHoursByDefault || isHourly) && (
          <StyledGroupContainer>
            <StyledItemContainer>
              <Label text={i18n.t('global:From')} />
            </StyledItemContainer>
            <StyledItemContainer>
              <TimePicker
                disabled={!isHourly || disabled}
                local={false}
                value={newStartTime}
                onChange={handleChange('startTime')}
              />
            </StyledItemContainer>
          </StyledGroupContainer>
        )}
        {(showHoursByDefault || isHourly) && (
          <StyledGroupContainer>
            <StyledItemContainer>
              <Label text={i18n.t('global:To')} />
            </StyledItemContainer>
            <StyledItemContainer>
              <TimePicker
                disabled={!isHourly || disabled}
                local={false}
                value={newEndTime}
                onChange={handleChange('endTime')}
              />
            </StyledItemContainer>
          </StyledGroupContainer>
        )}
      </StyledContentContainer>
      {submitFailed && (
        <StyledErrorContainer>
          <FieldError error={error} />
        </StyledErrorContainer>
      )}
    </StyledContainer>
  )
}

export default FormChildSession
