import _ from 'lodash'

import {
  CLEAR_CHILD_FUNDING,
  LIST_CHILD_FUNDING,
  LIST_CHILD_FUNDING_FAILED,
  LIST_CHILD_FUNDING_SUCCESS,
  UPDATE_CHILD_FUNDING_LIST,
} from './actions'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: {},
}

export default (state = initialState, { error, mergeResult, payload, type }) => {
  switch (type) {
    case LIST_CHILD_FUNDING:
      return { ...state, error: initialState.error, isFetching: true }
    case LIST_CHILD_FUNDING_SUCCESS:
      return {
        ...state,
        data: !mergeResult || !state.data ? payload.data : [...state.data, ...payload.data],
        isFetching: false,
        meta: payload.meta,
      }
    case LIST_CHILD_FUNDING_FAILED:
      return { ...state, error, isFetching: false }

    case UPDATE_CHILD_FUNDING_LIST:
      return {
        ...state,
        data: [
          ..._.reject(state.data, (item) => item.id === payload.data.funding.id),
          payload.data.funding,
        ],
      }
    case CLEAR_CHILD_FUNDING:
      return initialState
    default:
      return state
  }
}
