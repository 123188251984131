import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withInvoicePaymentsService } from 'services/legacy/invoicePayments'
import { withSnackbarService } from 'services/utils/snackbar'
import { withShellService } from 'services/shell'
import { withRouter } from 'services/router'

import i18n from 'translations'

import { properties } from 'app-config'

import FinancePaymentsImportView from './FinancePaymentsImportView'

import { PAGE } from './constants'

class FinancePaymentsImportContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentPage: PAGE.INTRO,
      duplicates: {},
      errors: {},
      files: [],
    }
  }

  componentDidMount() {
    const { shellActions } = this.props

    shellActions.hideSubMenu()
  }

  componentWillUnmount() {
    const { invoicePaymentsActions, shellActions } = this.props

    invoicePaymentsActions.clearImportPayments()
    shellActions.showSubMenu()
  }

  handleCancel = () => {
    const { navigate } = this.props

    navigate(generateRoute('FINANCE.PAYMENTS'))
  }

  handleReturnToCsvUpload = () => {
    this.setState({
      currentPage: PAGE.INTRO,
      duplicates: {},
      errors: {},
      files: [],
    })
  }

  handleFilesChange = (files) => this.setState({ files })

  handleRemoveFile = () => {
    const { invoicePaymentsActions } = this.props

    invoicePaymentsActions.clearImportPayments()
  }

  handleSuccessfulImport = (number) => {
    const {
      navigate,
      snackbarActions,
    } = this.props

    navigate(generateRoute('FINANCE.PAYMENTS'))

    return snackbarActions.show({
      message: i18n.t('module:Modals:PaymentsImport:onSuccessMessage', { number }),
    })
  }

  handleValidationErrors = (data) => {
    const { invoicePaymentsSelectors } = this.props

    const { duplicates, errors } = invoicePaymentsSelectors.getErrorsAndWarnings(data)

    return this.setState({
      currentPage: _.isEmpty(errors) ? PAGE.DUPLICATES : PAGE.ERRORS,
      duplicates,
      errors,
    })
  }

  handleImportOnSuccess = (response) => {
    const { data, totalInsertedPayments } = response || {}

    if (0 <= totalInsertedPayments) {
      return this.handleSuccessfulImport(totalInsertedPayments)
    }

    return this.handleValidationErrors(data)
  }

  handleImport = (ignoreWarnings = false) => {
    const { invoicePaymentsActions, invoicePaymentsSelectors } = this.props
    const { files } = this.state

    const criteria = invoicePaymentsSelectors.getImportCriteriaSelector({ ignoreWarnings })
    const params = { criteria }

    const payload = new FormData()
    const file = files[0]

    payload.append(file.name, file.file)

    return invoicePaymentsActions.importPayments({
      onSuccess: this.handleImportOnSuccess,
      params,
      payload,
    })
  }

  handleDownloadTemplate = () => {
    const { invoicePaymentsActions } = this.props

    invoicePaymentsActions.downloadTemplate({
      params: {
        filename: properties.paymentsImportTemplateFileName,
      },
    })
  }

  render() {
    const {
      errorMessages,
      isSubmitting,
    } = this.props
    const {
      currentPage,
      duplicates,
      errors,
      files,
    } = this.state

    return (
      <FinancePaymentsImportView
        currentPage={currentPage}
        duplicates={duplicates}
        errorMessages={errorMessages}
        errors={{
          ...errors,
          ...duplicates,
        }}
        files={files}
        isProcessing={isSubmitting}
        onCancelClick={this.handleCancel}
        onDownloadTemplateClick={this.handleDownloadTemplate}
        onFilesChange={this.handleFilesChange}
        onImportClick={this.handleImport}
        onRemoveFile={this.handleRemoveFile}
        onReturnToCsvUploadClick={this.handleReturnToCsvUpload}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  invoicePaymentsImportPaymentsState,
}) => ({
  errorMessages: appSelectors.getErrorMessages(invoicePaymentsImportPaymentsState),
  isSubmitting: appSelectors.getIsSubmitting(invoicePaymentsImportPaymentsState),
})

const enhance = compose(
  withAppService,
  withInvoicePaymentsService,
  withRouter,
  withShellService,
  withSnackbarService,
  withInvoicePaymentsService,
  connect(mapState),
)

export default enhance(FinancePaymentsImportContainer)
