import React from 'react'

import { generateRoute } from 'utils/routing'

import { FolderList, Page, Section } from 'components'

import i18n from 'translations'

const ManagementFinanceSettingsView = ({
  ManagementAccountCodesGranted,
  ManagementConsumablesGranted,
  ManagementDiscountsGranted,
  ManagementExtraItemsGranted,
  ManagementFundingGranted,
  ManagementInvoicesGranted,
  ManagementNewFinanceGranted,
  ManagementPaymentGatewayGranted,
  ManagementPaymentTypesGranted,
  ManagementReferenceCodesGranted,
  ManagementSessionsGranted,
  isFinanceV3Enabled,
  params,
}) => {
  const nurseryId = params && params.nurseryId
  const routeParams = !nurseryId ? {} : ({ nurseryId })

  return (
    <Page.Section title={i18n.t('module:Management:Finance:title')}>
      <Section title={i18n.t('module:Management:Finance:LineItems:title')}>
        <FolderList>
          {ManagementSessionsGranted && (
            <FolderList.Item
              icon="sessions"
              title={i18n.t('module:Management:Finance:Sessions:title')}
              to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.SESSIONS', routeParams)}
            />
          )}
          {ManagementExtraItemsGranted && (
            <FolderList.Item
              icon="plus"
              title={
                isFinanceV3Enabled
                  ? i18n.t('module:Management:Finance:Item:title')
                  : i18n.t('module:Management:Finance:Item:legacyTitle')
              }
              to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.ITEMS', routeParams)}
            />
          )}
          {ManagementConsumablesGranted && (
            <FolderList.Item
              icon="consumables"
              title={i18n.t('module:Management:Finance:Consumables:title')}
              to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.CONSUMABLES', routeParams)}
            />
          )}
          {ManagementFundingGranted && (
            <FolderList.Item
              icon="funding"
              title={i18n.t('module:Management:Finance:Funding:title')}
              to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.FUNDING', routeParams)}
            />
          )}
          {ManagementDiscountsGranted && (
            <FolderList.Item
              icon="discount"
              title={i18n.t('module:Management:Finance:Discounts:title')}
              to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.DISCOUNTS', routeParams)}
            />
          )}
        </FolderList>
      </Section>
      <Section title={i18n.t('module:Management:Finance:Accounting:title')}>
        <FolderList>
          {ManagementReferenceCodesGranted && (
            <FolderList.Item
              icon="reference-codes"
              title={i18n.t('global:Child:childReferenceCodeGeneration')}
              to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.REFERENCE_CODE_GENERATION', routeParams)}
            />
          )}
          {ManagementReferenceCodesGranted && (
            <FolderList.Item
              icon="reference-codes"
              title={i18n.t('global:Child:childReferenceCodes')}
              to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.REFERENCE_CODES', routeParams)}
            />
          )}
          {ManagementAccountCodesGranted && (
            <FolderList.Item
              icon="funding"
              title={i18n.t('module:Management:Finance:AccountCodes:title')}
              to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.ACCOUNT_CODES', routeParams)}
            />
          )}
        </FolderList>
      </Section>
      {ManagementInvoicesGranted && (
        <Section title={i18n.t('module:Management:Finance:Invoicing:title')}>
          <FolderList>
            <FolderList.Item
              icon="invoices"
              title={i18n.t('module:Management:Finance:Invoice:title')}
              to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.INVOICES', routeParams)}
            />
          </FolderList>
        </Section>
      )}
      <Section title={i18n.t('module:Management:Finance:Payment:title')}>
        <FolderList>
          {ManagementNewFinanceGranted && (
            <FolderList.Item
              icon="payment-types"
              title={i18n.t('module:Management:Finance:NewFinance:title')}
              to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.NEW_FINANCE', routeParams)}
            />
          )}
          {ManagementPaymentGatewayGranted && (
            <FolderList.Item
              icon="payment-types"
              title={i18n.t('module:Management:Finance:PaymentGateway:title')}
              to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.PAYMENT_GATEWAY', routeParams)}
            />
          )}
          {ManagementPaymentTypesGranted && (
            <FolderList.Item
              icon="payment-types"
              title={i18n.t('module:Management:ManagementPaymentTypes:title')}
              to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.PAYMENT_TYPES', routeParams)}
            />
          )}
        </FolderList>
      </Section>
    </Page.Section>
  )
}

export default ManagementFinanceSettingsView
