import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { Option } from 'constants/models'
import { NurseryFundingProductType } from 'services/product/nurseryFundingV3/constants'
import { NurseryDiscount, OTHER } from 'services/product/nurseryDiscountsV3/constants'
// import { NurseryDiscountTypesV3, OTHER } from 'services/product/nurseryDiscountsV3/constants'
// eslint-disable-next-line max-len, import-newlines/enforce
import { AddDiscountFormValues } from 'module/Management/ManagementFinanceSettings/ManagementDiscounts/v3/ManagementDiscountsV3Add/components/AddDiscountForm'

import { createTypeFromEnumValues } from 'utils/typescript'
import { toFloat, uuidV6 } from 'utils/data'

import { AddFormFieldProps, FormItemOptionProps } from 'module/Finance/OneOffBookings/Add/components/AddForm'
import { getBody } from 'services/product/nurseryDiscountsV3/single/selectors'

import { ChildProductTypes, ChildProductsRootState, OneOffBookingTypes, OneOffCustomDiscountTypes } from '../constants'

export const getChildProductsSelector = (state: ChildProductsRootState) => state.childProducts

export const getChildProductsSingleSelector = createSelector(
  [getChildProductsSelector],
  (state) => state.single.data,
)

interface GetTypeProps {
  // deductFromParentInvoice: boolean
  discountBody?: AddDiscountFormValues
  item?: FormItemOptionProps
  type: createTypeFromEnumValues<ChildProductTypes>
}

const getType = ({
  // deductFromParentInvoice,
  // discountBody,
  item,
  type,
}: GetTypeProps) => {
  if (
    type === ChildProductTypes.ONE_OFF_FUNDING
    && item.type === NurseryFundingProductType.NURSERY_NO_DEDUCT_ONE_OFF_FUNDING
  ) {
    return ChildProductTypes.ONE_OFF_NO_DEDUCT_FUNDING
  }

  if (type === ChildProductTypes.ONE_OFF_DISCOUNT) {
    if (item.value === OTHER) {
      return OneOffCustomDiscountTypes.ONE_OFF_AMOUNT_DISCOUNT

      // if (discountBody.type === NurseryDiscountTypesV3.AMOUNT) {
      //   return OneOffCustomDiscountTypes.ONE_OFF_AMOUNT_DISCOUNT
      // }

      // return OneOffCustomDiscountTypes.ONE_OFF_PERCENTAGE_DISCOUNT
    }

    return OneOffCustomDiscountTypes.ONE_OFF_LINK_DISCOUNT
  }

  return type
}

const getTotal = (value: number, type: Option<createTypeFromEnumValues<OneOffBookingTypes>>) => {
  if (type?.value === ChildProductTypes.ONE_OFF_FUNDING) {
    return +toFloat(+value) * 100
  }

  return value ? +value : undefined
}

export const getPayload = (id: string, fields: AddFormFieldProps) => {
  const {
    child,
    date,
    // deductFromParentInvoice
    discountForm,
    endTime,
    includeConsumables,
    item,
    quantity,
    startTime,
    type,
    value,
  } = fields

  let discountBody

  if (type.value === ChildProductTypes.ONE_OFF_DISCOUNT && item.value === OTHER) {
    discountBody = getBody(discountForm)
  }

  const payload = {
    child: { id: child.value },
    date: moment(date).format(DEFAULT_DATE_FORMAT),
    endTime: type.value === ChildProductTypes.EXTRA_SESSION && item?.hourly
      ? endTime
      : undefined,
    id: id || uuidV6(),
    product: type.value === ChildProductTypes.ONE_OFF_DISCOUNT && item.value === OTHER ? undefined : { id: item.value },
    settings: {
      applicable: discountBody?.settings?.applicable,
      consumablesIncluded: includeConsumables ?? false,
      name: discountBody?.name,
      quantity: quantity ? +quantity : undefined,
      total: getTotal(value, type),
      value: discountBody?.settings?.value,
    },
    startTime: type.value === ChildProductTypes.EXTRA_SESSION && item?.hourly
      ? startTime
      : undefined,
    type: getType({
      // deductFromParentInvoice,
      // discountBody,
      item,
      type: type.value as createTypeFromEnumValues<OneOffBookingTypes>,
    }),
  }

  if ((item as NurseryDiscount)?.settings?.allowOverride) {
    payload.settings = {
      ...payload.settings,
      overwritten: true,
      value: 100 * +value,
    } as any
  }

  return payload
}
