import _ from 'lodash'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { Button, Tooltip, Typography } from 'components'

import i18n from 'translations'

export const getTableData = (data) => {
  if (!data?.length) {
    return null
  }

  return _.map(data, ({ archived, editable, id, name }) => ({
    name: archived ? (
      <Typography>
        {name}
        {' '}
        <Typography
          color={NEUTRAL_COLOURS.GRAY}
          variant="span"
        >
          {`(${i18n.t('global:Archived')})`}
        </Typography>
      </Typography>
    ) : name,
    rowToButton: (
      <Tooltip
        placement="left"
        title={!editable ? i18n.t('module:Management:LeaveTypes:Child:Table:tooltip') : ''}
        inline
      >
        <Button.TableAction
          color={!editable && 'gray'}
          disabled={!editable}
          icon={editable ? 'edit' : 'padlock'}
          to={generateRoute('MANAGEMENT.LEAVE_TYPES.CHILD_ABSENCE_REASONS.EDIT', {
            itemId: id,
          })}
        />
      </Tooltip>
    ),
  }))
}
