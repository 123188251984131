import _ from 'lodash'

import React from 'react'

import { OCCUPANCY_REQUEST_BREAKDOWN } from 'services/legacy/occupancy/constants'
import { CHILD_PREVIEW_TYPES } from 'services/legacy/child/constants'

import { Icon, Tooltip, Typography } from 'components'

import {
  StyledChildContentContainer,
  StyledChildItemContainer,
  StyledLabelContainer,
  StyledSeparator,
} from './ReportContentStyled'

const ReportChildPreviewContent = ({
  breakdown,
  childId,
  childList,
}) => {
  if (!childList?.length) {
    return null
  }

  const child = _.find(childList, ({ id }) => childId === id)

  if (!child) {
    return null
  }

  const getChildPreviewType = ({
    absences,
    extraSessions,
    holidays,
    sessions,
  }) => {
    let childPreviewType = null

    if (extraSessions?.length) {
      childPreviewType = CHILD_PREVIEW_TYPES.extraSession
    }

    if (sessions?.length) {
      childPreviewType = CHILD_PREVIEW_TYPES.session
    }

    if (absences?.length) {
      childPreviewType = CHILD_PREVIEW_TYPES.absence
    }

    if (holidays?.length) {
      childPreviewType = CHILD_PREVIEW_TYPES.holiday
    }

    return childPreviewType
  }

  const renderIcon = (childPreviewType) => (
    <Icon
      color={childPreviewType.iconColor}
      height={16}
      icon={childPreviewType.icon}
    />
  )

  if (breakdown === OCCUPANCY_REQUEST_BREAKDOWN.SESSIONS) {
    const {
      absences,
      extraSessions,
      holidays,
      sessions,
    } = child

    const childPreviewType = getChildPreviewType({
      absences,
      extraSessions,
      holidays,
      sessions,
    })

    if (!childPreviewType) {
      return null
    }

    return (
      <Tooltip title={childPreviewType.tooltip}>
        {renderIcon(childPreviewType)}
      </Tooltip>
    )
  }

  const childPreviewWithoutEmpty = _.reduce(child, (result, values, key) => {
    const newResult = { ...result }

    if (values?.length) {
      newResult[key] = values
    }

    return newResult
  }, {})

  const renderLabel = (label) => {
    if (-1 === (label || '').indexOf(' - ')) {
      return label
    }

    const sessionTimeRange = label.split(' - ')

    return (
      <StyledLabelContainer>
        <Typography fontSize={12}>
          {sessionTimeRange[0]}
        </Typography>
        <StyledSeparator>
          {' - '}
        </StyledSeparator>
        <Typography fontSize={12}>
          {sessionTimeRange[1]}
        </Typography>
      </StyledLabelContainer>
    )
  }

  return (
    <StyledChildContentContainer>
      {_.map(childPreviewWithoutEmpty, (values, key) => {
        const childPreviewType = getChildPreviewType({ [key]: values })

        return _.map(values, ({ label }) => (
          <Tooltip key={label} title={childPreviewType.tooltip}>
            <StyledChildItemContainer>
              {renderIcon(childPreviewType)}
              <Typography align="left" fontSize={13} nowrap>
                {'holidays' === key || 'absences' === key ? childPreviewType.tooltip : renderLabel(label)}
              </Typography>
            </StyledChildItemContainer>
          </Tooltip>
        ))
      })}
    </StyledChildContentContainer>
  )
}

export default ReportChildPreviewContent
