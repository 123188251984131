import { getQueryStringV2 } from 'utils/api'

import ServiceBase from 'services/ServiceBase'

class FilesApiClient extends ServiceBase {
  createMembershipFile = (params, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/membership_files?${getQueryStringV2(params)}`,
  })

  removeMembershipFile = (id) => this.request({
    method: 'DELETE',
    path: `/v2/membership_files/${id}`,
  })

  updateMembershipFile = (id, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/membership_files/${id}`,
  })

  createChildFile = (params, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/child_files?${getQueryStringV2(params)}`,
  })

  removeChildFile = (id) => this.request({
    method: 'DELETE',
    path: `/v2/child_files/${id}`,
  })

  updateChildFile = (id, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/child_files/${id}`,
  })

  createNurseryFile = (params, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/nursery_files?${getQueryStringV2(params)}`,
  })

  removeNurseryFile = (id) => this.request({
    method: 'DELETE',
    path: `/v2/nursery_files/${id}`,
  })

  updateNurseryFile = (id, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/nursery_files/${id}`,
  })
}

export default FilesApiClient
