import _ from 'lodash'
import moment from 'moment'
import { flatten, nest } from 'utils/flatnest'
import { compose } from 'recompose'

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'
import { getYearOptions } from 'utils/date'

import { withAppService } from 'services/app'
import { withRouterUtils } from 'services/utils/router'
import { withPaginationUtils } from 'services/utils/pagination'
import { withModalService } from 'services/utils/modal'
import { withInvoicesForecastService } from 'services/legacy/invoices'
import { withChildService } from 'services/legacy/child'
import { withRouter } from 'services/router'

import i18n from 'translations'

import FinanceReportForecastingView from './FinanceReportForecastingView'

const CHART_GROUPS = {
  read: [
    'forecastStatistics.forecast',
    'forecastStatistics.invoices',
    'statistics.invoice.items',
    'statistics.invoice.income',
  ],
}
const TABLE_GROUPS = {
  read: [
    'childMonthlyForecastStatistics',
    'forecastStatistics',
    'forecastStatistics.forecast',
    'forecastStatistics.invoices',
    'statistics.invoice.summary',
    'statistics.invoice.items',
    'statistics.invoice.income',
    'child.extend',
  ],
}

class FinanceReportForecastingContainer extends Component {
  constructor(props) {
    super(props)

    const { location, paginationUtils } = props
    const { query = {} } = location
    const { year: queryYear } = nest(query)
    const { setPageLocationQuery } = paginationUtils

    const yearsOptions = getYearOptions(1)

    this.state = {
      year: this.getYearOption(queryYear, yearsOptions) || this.getYearOption(moment().format('YYYY'), yearsOptions),
      yearsOptions,
    }

    setPageLocationQuery(false)
  }

  getYearOption = (value, yearsOptions) => (value ? _.find(yearsOptions, { value }) : undefined)

  componentDidMount() {
    this.fetchForecast()
  }

  componentWillUnmount() {
    const { childActions, invoicesForecastActions } = this.props

    invoicesForecastActions.clearInvoicesForecast()
    childActions.clear()
  }

  fetchForecast = (forecastParams = {}) => {
    const { mergeChildren = true, withoutChartData = false } = forecastParams
    const {
      childActions,
      childSelectors,
      invoicesForecastActions,
      invoicesForecastSelectors,
      paginationUtils,
      setLocationQuery,
    } = this.props

    const { year } = this.state

    const { dateRange, value } = year

    setLocationQuery(flatten({ year: value }))

    if (!withoutChartData) {
      const chartCriteria = invoicesForecastSelectors.getCriteria({ date: dateRange })

      const chartApiParams = {
        criteria: chartCriteria,
        groups: CHART_GROUPS,
      }

      invoicesForecastActions.getForecast(chartApiParams)
    }

    const tableCriteria = childSelectors.getCriteria({
      archivedAtAfter: moment(dateRange.after),
      forecastDateRange: dateRange,
      isArchived: null,
    })
    const { page } = paginationUtils

    const tableApiParams = {
      criteria: tableCriteria,
      groups: TABLE_GROUPS,
      page,
    }

    childActions.list({ mergeResult: mergeChildren, params: tableApiParams })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(() => this.fetchForecast({ withoutChartData: true }))(page)
  }

  handleYearChange = (year) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState({ year }, () => onPageChange(() => this.fetchForecast({ mergeChildren: false }))(1))
  }

  handleExportModalClick = (body, onSuccessCb) => {
    const { childActions, childHelpers } = this.props
    const { year } = this.state

    const { dateRange } = year
    const { criteria } = body

    const recipients = _.find(criteria, { field: 'membership' })

    const payload = childHelpers.getForecastingExportPayload({
      endDate: dateRange.before,
      recipients,
      startDate: dateRange.after,
    })

    childActions.sendForecastReport(payload, onSuccessCb)
  }

  handleSendReport = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.EXPORT_REPORT, {
      onSendReport: this.handleExportModalClick,
      title: i18n.t('module:Finance:Reports:Forecasting:sendPopupTitle'),
      userLabel: i18n.t('module:Modals:ExportReportModal:User:Label:export'),
    })
  }

  render() {
    const {
      chart,
      childList,
      childTotalResult,
      isFetchingChartData,
      isFetchingTableData,
      isFinanceV3Enabled,
      paginationUtils,
      statisticsData,
    } = this.props
    const { year, yearsOptions } = this.state

    const { getPageCount, page } = paginationUtils

    const pageCount = getPageCount(childTotalResult)

    return (
      <FinanceReportForecastingView
        chart={chart}
        forecastData={childList}
        isFetchingChartData={isFetchingChartData}
        isFetchingTableData={isFetchingTableData}
        isFinanceV3Enabled={isFinanceV3Enabled}
        page={page}
        pageCount={pageCount}
        statisticsData={statisticsData}
        year={year}
        yearsOptions={yearsOptions}
        onPageChange={this.handlePageChange}
        onSendReport={this.handleSendReport}
        onYearChange={this.handleYearChange}
      />
    )
  }
}

FinanceReportForecastingContainer.authParams = {
  flags: [FEATURE_FLAGS.FINANCE_AUTOMATION],
  roles: [ROLES.SUPER_ADMIN, ROLES.NURSERY_ADMIN, ROLES.NURSERY_MANAGER, ROLES.ORGANIZATION_FINANCE_ADMIN],
}

const mapState = (
  state,
  { appSelectors, childListState, childSelectors, invoicesForecastSelectors, invoicesForecastState },
) => ({
  chart: invoicesForecastSelectors.getChartData(state),
  childList: childSelectors.getForecastingReportTableDataSelector(state),
  childTotalResult: childSelectors.getChildListMetaSelectorTotalResults(state),
  isFetchingChartData: appSelectors.getIsFetching(invoicesForecastState),
  isFetchingTableData: appSelectors.getIsFetching(childListState),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
  statisticsData: invoicesForecastSelectors.getForecastingReportTableDataSelector(state),
})

const enhance = compose(
  withAppService,
  withChildService,
  withInvoicesForecastService,
  withPaginationUtils,
  withModalService,
  withRouterUtils,
  connect(mapState),
  withRouter,
)

export default enhance(FinanceReportForecastingContainer)
