import { flatten, nest } from 'utils/flatnest'

import { compose } from 'recompose'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { LIST_STATUS_FILTERS, LIST_STATUS_FILTERS_DROPDOWN } from 'constants/filters'

import { withAppService } from 'services/app'
import { withNurseryConsumablesService } from 'services/nurseryConsumables'
import { withPaginationUtils } from 'services/utils/pagination'

import { getNurseryConsumablesTableData } from './ManagementConsumablesV2Helpers'
import ManagementConsumablesV2View from './ManagementConsumablesV2View'

const CONSUMABLES_COLUMNS = [
  {
    align: 'left',
    field: 'name',
    title: 'Consumable',
  },
  {
    align: 'center',
    field: 'action',
    title: '',
  },
]

const CONSUMABLES_GROUP = {
  read: [
    'nurseryConsumable',
    'nurseryConsumable.settings',
  ],
}

class ManagementConsumablesV2Container extends Component {
  constructor(props) {
    super(props)

    const { query } = props.location
    const { active } = nest(query)

    this.state = {
      filters: {
        active: active || LIST_STATUS_FILTERS.ACTIVE,
      },
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const {
      nurseryConsumablesActions,
    } = this.props

    nurseryConsumablesActions.clearList()
  }

  fetch = () => {
    const {
      nurseryConsumablesActions,
      nurseryConsumablesSelectors,
      paginationUtils,
      setLocationQuery,
    } = this.props
    const { filters } = this.state

    const { page } = paginationUtils

    const criteria = nurseryConsumablesSelectors.getCriteria(filters)

    setLocationQuery(flatten(filters))

    nurseryConsumablesActions.list({
      params: {
        criteria,
        groups: CONSUMABLES_GROUP,
        page,
      },
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleActiveChange = (active) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, active: active?.value },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  render() {
    const {
      errorMessages,
      isFetching,
      nurseryConsumablesListState,
      paginationUtils,
      records,
    } = this.props
    const { filters } = this.state
    const { active } = filters

    const tableData = getNurseryConsumablesTableData(records)
    const { getPageCount, page, perPage } = paginationUtils
    const pageCount = getPageCount(nurseryConsumablesListState.meta.total_results)

    return (
      <ManagementConsumablesV2View
        active={active}
        activeOptions={LIST_STATUS_FILTERS_DROPDOWN}
        columns={CONSUMABLES_COLUMNS}
        errorMessages={errorMessages}
        isLoading={isFetching}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        tableData={tableData}
        totalResults={nurseryConsumablesListState.meta.total_results}
        onActiveFilterChange={this.handleActiveChange}
        onPageChange={this.handlePageChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseryConsumablesListState,
  nurseryConsumablesSelectors,
}) => ({
  errorMessages: appSelectors.getErrorMessages(nurseryConsumablesListState),
  isFetching: appSelectors.getIsFetching(nurseryConsumablesListState),
  records: nurseryConsumablesSelectors.getNurseryConsumablesListSelector(state),
})

const enhance = compose(
  withAppService,
  withPaginationUtils,
  withNurseryConsumablesService,
  connect(mapState),
)

export default enhance(ManagementConsumablesV2Container)
