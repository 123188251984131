import { generateBasicActions } from 'utils/service'

import {
  CHILDREN_LEAVING_REPORT,
  CHILDREN_LEAVING_REPORT_FAILED,
  CHILDREN_LEAVING_REPORT_SUCCESS,
  CLEAR_CHILDREN_LEAVING_REPORT,
} from './constants'

export const getChildren = (options = {}) => ({ childrenLeavingReportApiClient }) => generateBasicActions.list({
  constants: {
    failed: CHILDREN_LEAVING_REPORT_FAILED,
    init: CHILDREN_LEAVING_REPORT,
    success: CHILDREN_LEAVING_REPORT_SUCCESS,
  },
  options,
  service: childrenLeavingReportApiClient,
  serviceMethod: 'getChildrenLeaving',
})

export const clearChildren = () => ({
  type: CLEAR_CHILDREN_LEAVING_REPORT,
})
