export const GET_INVOICE_PAYMENT = 'GET_INVOICE_PAYMENT'
export const GET_INVOICE_PAYMENT_SUCCESS = 'GET_INVOICE_PAYMENT_SUCCESS'
export const GET_INVOICE_PAYMENT_FAILED = 'GET_INVOICE_PAYMENT_FAILED'

export const CREATE_INVOICE_PAYMENT = 'CREATE_INVOICE_PAYMENT'
export const CREATE_INVOICE_PAYMENT_SUCCESS = 'CREATE_INVOICE_PAYMENT_SUCCESS'
export const CREATE_INVOICE_PAYMENT_FAILED = 'CREATE_INVOICE_PAYMENT_FAILED'

export const DELETE_INVOICE_PAYMENT = 'DELETE_INVOICE_PAYMENT'
export const DELETE_INVOICE_PAYMENT_SUCCESS = 'DELETE_INVOICE_PAYMENT_SUCCESS'
export const DELETE_INVOICE_PAYMENT_FAILED = 'DELETE_INVOICE_PAYMENT_FAILED'

export const SEND_INVOICE_PAYMENT = 'SEND_INVOICE_PAYMENT'
export const SEND_INVOICE_PAYMENT_SUCCESS = 'SEND_INVOICE_PAYMENT_SUCCESS'
export const SEND_INVOICE_PAYMENT_FAILED = 'SEND_INVOICE_PAYMENT_FAILED'

export const UPDATE_INVOICE_PAYMENT = 'UPDATE_INVOICE_PAYMENT'
export const UPDATE_INVOICE_PAYMENT_SUCCESS = 'UPDATE_INVOICE_PAYMENT_SUCCESS'
export const UPDATE_INVOICE_PAYMENT_FAILED = 'UPDATE_INVOICE_PAYMENT_FAILED'

export const DOWNLOAD_INVOICE_PAYMENT = 'DOWNLOAD_INVOICE_PAYMENT'
export const DOWNLOAD_INVOICE_PAYMENT_SUCCESS = 'DOWNLOAD_INVOICE_PAYMENT_SUCCESS'
export const DOWNLOAD_INVOICE_PAYMENT_FAILED = 'DOWNLOAD_INVOICE_PAYMENT_FAILED'

export const CLEAR_INVOICE_PAYMENT_SINGLE = 'CLEAR_INVOICE_PAYMENT_SINGLE'

export const getInvoicePayment = (paymentId, params) => ({
  invoicePaymentsApiClient,
}) => (dispatch) => {
  dispatch({
    params,
    paymentId,
    type: GET_INVOICE_PAYMENT,
  })

  invoicePaymentsApiClient.get(paymentId, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: GET_INVOICE_PAYMENT_SUCCESS,
      })
    },
    (error) => {
      dispatch({
        error: error.response,
        type: GET_INVOICE_PAYMENT_FAILED,
      })
    },
  )
}

export const downloadInvoicePayment = (id) => ({ invoicePaymentsApiClient }) => (dispatch) => {
  dispatch({
    id,
    invoicePaymentsApiClient,
    type: DOWNLOAD_INVOICE_PAYMENT,
  })

  invoicePaymentsApiClient.download(id).then(
    (data) => {
      dispatch({
        payload: data,
        type: DOWNLOAD_INVOICE_PAYMENT_SUCCESS,
      })
    },
    (error) => {
      dispatch({
        error: error.response,
        type: DOWNLOAD_INVOICE_PAYMENT_FAILED,
      })
    },
  )
}

export const createInvoicePayment = (payload, onSuccess, onSendReceiptFailure) => ({
  invoicePaymentsApiClient,
}) => (dispatch) => {
  dispatch({
    payload,
    type: CREATE_INVOICE_PAYMENT,
  })

  return invoicePaymentsApiClient.create(payload).then(
    (data) => {
      dispatch({
        payload: data,
        type: CREATE_INVOICE_PAYMENT_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => {
      if (409 === response.code) {
        dispatch({
          type: CREATE_INVOICE_PAYMENT_SUCCESS,
        })

        if (onSendReceiptFailure) {
          onSendReceiptFailure()
        }
      } else {
        dispatch({
          error: response,
          type: CREATE_INVOICE_PAYMENT_FAILED,
        })

        if (onSendReceiptFailure) {
          onSendReceiptFailure(response)
        }
      }
    },
  )
}

export const deleteInvoicePayment = (paymentId, onSuccess) => ({ invoicePaymentsApiClient }) => (dispatch) => {
  dispatch({
    paymentId,
    type: DELETE_INVOICE_PAYMENT,
  })

  invoicePaymentsApiClient.delete(paymentId).then(
    (data) => {
      dispatch({
        payload: data,
        type: DELETE_INVOICE_PAYMENT_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: DELETE_INVOICE_PAYMENT_FAILED,
      })
    },
  )
}

export const sendInvoicePayment = (id, onSuccess, onFailedure) => ({ invoicePaymentsApiClient }) => (dispatch) => {
  dispatch({
    id,
    type: SEND_INVOICE_PAYMENT,
  })

  invoicePaymentsApiClient.send(id).then(
    (data) => {
      dispatch({
        payload: data,
        type: SEND_INVOICE_PAYMENT_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: SEND_INVOICE_PAYMENT_FAILED,
      })

      if (onFailedure) {
        onFailedure(error.response)
      }
    },
  )
}

export const clearInvoicePaymentSingle = () => ({
  type: CLEAR_INVOICE_PAYMENT_SINGLE,
})
