import BannerError from './BannerError'
import BannerInfo from './BannerInfo'
import BannerSuccess from './BannerSuccess'
import BannerWarning from './BannerWarning'

type BannerRoot = {
  Error?: typeof BannerError
  Info?: typeof BannerInfo
  Success?: typeof BannerSuccess
  Warning?: typeof BannerWarning
}

const Banner: BannerRoot = {}

Banner.Error = BannerError
Banner.Info = BannerInfo
Banner.Success = BannerSuccess
Banner.Warning = BannerWarning

export default Banner
