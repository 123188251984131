import React from 'react'

import { FLAG_COLOURS } from 'constants/colors'

import { Typography } from 'components'

import { StyledErrorInfoIcon } from './ErrorAlertStyled'

const ErrorAlert = ({ message }) => (
  <Typography
    background={FLAG_COLOURS.INFO}
    margin="10px 0 5px 0"
    padding="10px 10px"
    variant="div"
  >
    <StyledErrorInfoIcon />
    <Typography variant="span">{message}</Typography>
  </Typography>
)

export default ErrorAlert
