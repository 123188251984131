import React from 'react'

import { DetailsBreakdown } from 'components'

const BreakdownItem = ({ label, value }) => (
  <DetailsBreakdown.Item
    label={label}
    padding="15px 0"
    value={value || '-'}
    withoutBorder
  />
)

export default BreakdownItem
