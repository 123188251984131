import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

const getAssessmentTrackerListState = (state) => state.assessmentTracker.list

export const getAssessmentTrackerListDataState = createSelector(
  [getAssessmentTrackerListState],
  (state) => state.data,
)

export const getListCriteria = (filters) => {
  const {
    archived = false,
    dateFrom,
    dateTo,
    framework,
    keyWorker,
    nurseryClass,
    search,
  } = filters
  const criteria = []

  if (_.isInteger(framework)) {
    criteria.push({
      field: 'framework',
      value: framework,
    })
  }

  if (_.isInteger(keyWorker)) {
    criteria.push({
      field: 'keyWorkers[]',
      value: keyWorker,
    })
  }

  if (_.isInteger(nurseryClass)) {
    criteria.push({
      field: 'nurseryClass',
      value: nurseryClass,
    })
  }

  if (dateFrom) {
    criteria.push({
      field: 'dateFrom',
      value: moment(dateFrom).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (dateTo) {
    criteria.push({
      field: 'dateTo',
      value: moment(dateTo).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (search) {
    criteria.push({
      field: 'concatenable',
      value: search,
    })
  }

  if (null !== archived && undefined !== archived) {
    criteria.push({
      field: 'isArchived',
      value: +archived,
    })
  }

  return criteria
}

