import _ from 'lodash'

import React from 'react'

import { generateRoute } from 'utils/routing'

import {
  Avatar,
  DateString,
  EmptyState,
  Icon,
  InfiniteDropdowns,
  Page,
  Pagination,
  Section,
  Select,
  Spinner,
  Table,
  Toolbar,
  Typography,
} from 'components'

import i18n from 'translations'

const PhotoPermissionsReportView = ({
  age,
  ageOptions,
  columns,
  data,
  isLoading,
  onAgeChange,
  onPageChange,
  onRoomChange,
  onSendReport,
  onTableSortChange,
  page,
  pageCount,
  perPage,
  room,
  sortField,
  sortOrder,
  title,
  totalResults,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('global:pagination:children')}
      titleSingular={i18n.t('global:pagination:child')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const getReports = () => (
    _.map(data, (child) => {
      const {
        ageMonths,
        birthDate,
        firstName,
        id,
        information,
        photo,
        surname,
      } = child
      const {
        canBePhotographed,
        canBeTaggedInGroupPhotos,
        closedSocialMediaPhotoSharingPermitted,
        otherMarketingMaterialPhotoSharingPermitted,
        socialMediaPhotoSharingPermitted,
        useOnSiteMediaPermitted,
      } = information || {}

      return {
        birthDate: birthDate ? (
          <Typography>
            <DateString date={birthDate} />
            <br />
            {i18n.t('global:ageMonths', { ageMonths })}
          </Typography>
        ) : '-',
        child: (
          <Avatar
            avatarSize="small"
            initials={[firstName, surname]}
            src={photo}
            title={(
              <Typography fontSize={14} primary>
                {`${firstName} ${surname}`}
              </Typography>
            )}
            to={generateRoute('CHILDREN.CHILD', { childId: id })}
          />
        ),
        closedSocialMediaPhotoSharingPermitted: closedSocialMediaPhotoSharingPermitted && (
          <Icon icon="check" justify="center" />
        ),
        groupPhotoPermission: canBeTaggedInGroupPhotos && <Icon icon="check" justify="center" />,
        id,
        photoPermission: canBePhotographed && <Icon icon="check" justify="center" />,
        shareOnOtherMarketingMaterial: otherMarketingMaterialPhotoSharingPermitted && (
          <Icon icon="check" justify="center" />
        ),
        shareOnSocialMedia: socialMediaPhotoSharingPermitted && <Icon icon="check" justify="center" />,
        useOnSiteMediaPermitted: useOnSiteMediaPermitted && <Icon icon="check" justify="center" />,
      }
    })
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!data?.length) {
      return (
        <EmptyState
          icon="camera"
          text1={i18n.t('module:Reports:emptyStateText')}
          iconComponentSmallMargin
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={columns}
          data={getReports()}
          sortField={sortField}
          sortOrder={sortOrder}
          onSortChange={onTableSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const actions = <Section.Actions options={[{ onClick: onSendReport, type: 'export' }]} />

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title={title}
    >
      <Toolbar bottomLine>
        <Toolbar.Group>
          <Toolbar.Item>
            <InfiniteDropdowns.Rooms
              placeholder={i18n.t('global:AllRooms')}
              value={room}
              onChange={onRoomChange}
            />
          </Toolbar.Item>
          <Toolbar.Item>
            <Select
              options={ageOptions}
              placeholder={i18n.t('global:AllAges')}
              value={age}
              onChange={onAgeChange}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      {renderContent()}
    </Page.Section>
  )
}

export default PhotoPermissionsReportView
