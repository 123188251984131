export const GET_NURSERY_FILES_LIST = 'GET_NURSERY_FILES_LIST'
export const GET_NURSERY_FILES_LIST_SUCCESS = 'GET_NURSERY_FILES_LIST_SUCCESS'
export const GET_NURSERY_FILES_LIST_FAILED = 'GET_NURSERY_FILES_LIST_FAILED'

export const GET_NURSERY_FILES_TOTAL_RESULTS_LIST = 'GET_NURSERY_FILES_TOTAL_RESULTS_LIST'
export const GET_NURSERY_FILES_TOTAL_RESULTS_LIST_SUCCESS = 'GET_NURSERY_FILES_TOTAL_RESULTS_LIST_SUCCESS'
export const GET_NURSERY_FILES_TOTAL_RESULTS_LIST_FAILED = 'GET_NURSERY_FILES_TOTAL_RESULTS_LIST_FAILED'

export const CLEAR_NURSERY_FILES_LIST = 'CLEAR_NURSERY_FILES_LIST'

export const ADD_MANUALLY_NURSERY_FILES = 'ADD_MANUALLY_NURSERY_FILES'

export const REMOVE_FILE_FROM_LIST = 'NURSERIES/REMOVE_FILE_FROM_LIST'
