import React, { useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'

import { RootState } from 'core/reducers'
import { withRouteUtilsProps } from 'services/utils/router'
import { OrganizationSettings } from 'services/organization/models'

import { generateRoute } from 'utils/routing'

import { withAppService, withAppServiceProps } from 'services/app'
import { withOrganizationService, withOrganizationServiceProps } from 'services/organization'
import { getAuthOrganization } from 'services/security/selectors'
import { withRouter, withRouterProps } from 'services/router'

import { InitialValuesResponseProps, getInitialValues } from './helpers'
import SettingsInvoicesV3EditAnnualisedView from './SettingsInvoicesV3EditAnnualisedView'

const ORGANIZATION_INVOICE_SETTINGS_GROUPS = {
  read: [
    'organization.organizationSettings',
    'organizationSettings.invoice',
    'organizationInvoiceSettings',
    'organizationInvoiceSettings.numbers',
    'invoiceNumbers',
    'organization.bankDetails',
    'organizationInvoiceSettings.annualisedInvoiceSettings',
    'annualisedInvoiceSettings',
  ],
}

type SettingsInvoicesV3EditAnnualisedContainerProps = withAppServiceProps
  & withRouteUtilsProps
  & withRouterProps
  & withOrganizationServiceProps

const mapState = (state: RootState, {
  appSelectors,
  organizationSingleState,
}: SettingsInvoicesV3EditAnnualisedContainerProps) => ({
  authOrganization: getAuthOrganization(state),
  errorMessages: appSelectors.getErrorMessages(organizationSingleState),
  isSubmitting: appSelectors.getIsSubmitting(organizationSingleState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const SettingsInvoicesV3EditAnnualisedContainer: React.FC<
  SettingsInvoicesV3EditAnnualisedContainerProps
  & PropsFromRedux
> = ({
  authOrganization,
  errorMessages,
  isSubmitting,
  navigate,
  organizationActions,
  organizationSelectors,
}) => {
  const [initialValues, setInitialValues] = useState<InitialValuesResponseProps>()
  const [organizationSettingsData, setOrganizationSettingsData] = useState<OrganizationSettings>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const handleGetOrganizationSuccess = (response) => {
    setOrganizationSettingsData(response?.organizationSettings)
    setInitialValues(getInitialValues(response))
    setIsLoading(false)
  }

  useEffect(() => {
    organizationActions.get(authOrganization.id, {
      onSuccess: handleGetOrganizationSuccess,
      onlyData: true,
      params: { groups: ORGANIZATION_INVOICE_SETTINGS_GROUPS },
    })
  }, [])

  const handleSubmitSuccess = () => {
    navigate(generateRoute('SETTINGS.INVOICES.VIEW'))
  }

  const handleSubmit = (fields) => {
    const payload = organizationSelectors.getAnnualisedPayload(fields, organizationSettingsData)

    organizationActions.update(authOrganization.id, {
      onSuccess: handleSubmitSuccess,
      payload,
    })
  }

  return (
    <SettingsInvoicesV3EditAnnualisedView
      cancelLink={generateRoute('SETTINGS.INVOICES.VIEW')}
      errorMessages={errorMessages}
      initialValues={initialValues}
      isFormLoading={isSubmitting}
      isLoading={isLoading}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withRouter,
  withAppService,
  withOrganizationService,
  connector,
)

export default enhance(SettingsInvoicesV3EditAnnualisedContainer)
