import { createSelector } from 'reselect'

export const getLeaveType = (state) => state.leaveTypes.single

export const getLeaveTypesSingleDataSelector = createSelector(
  [getLeaveType],
  (state) => state.data,
)

export const getInitialValues = createSelector(
  [getLeaveTypesSingleDataSelector],
  (leaveType) => {
    if (!leaveType) {
      return null
    }

    return { name: leaveType.name }
  },
)

export const getIsSubmitting = createSelector(
  [getLeaveType],
  (state) => state.isSubmitting,
)

export const isArchived = createSelector(
  [getLeaveTypesSingleDataSelector],
  (leaveType) => {
    if (!leaveType) {
      return null
    }

    return leaveType.archived
  },
)
