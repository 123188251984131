import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { PAYMENT_STATUSES_OPTIONS, PAYMENT_TYPE_VALUES } from 'services/legacy/invoicePayments/constants'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { Callout, Currency, Page, Section, Space, Spinner, Timeline, Typography } from 'components'

import i18n from 'translations'

import { StyledDotStatus, StyledField } from './PaymentDetailsStyled'

const PaymentDetailsView = ({
  errorMessages,
  invoicePayment = {},
  isFetching,
  onDownloadPayment,
  onRemovePayment,
  onSendPayment,
}) => {
  const { integrationId, timelineEntries } = invoicePayment || {}

  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    const status = _.find(PAYMENT_STATUSES_OPTIONS, ({ value }) => (
      value === invoicePayment.status
    )) || {}

    return (
      <React.Fragment>
        <Typography fontSize={18} margin="0 0 25px" bold>
          {i18n.t('module:Finance:Payments:Details:paymentDetails')}
        </Typography>
        <StyledField>
          <Typography bold>
            {i18n.t('global:Date')}
          </Typography>
          <div>
            {moment(invoicePayment.paymentDate).format('DD MMM YYYY')}
            {invoicePayment.integrationId ? ` ${moment(invoicePayment.paymentDate).format('HH:mm')}` : null}
          </div>
        </StyledField>
        <StyledField>
          <Typography bold>
            {i18n.t('module:Finance:Payments:Details:paymentStatus')}
          </Typography>
          <div>
            <StyledDotStatus color={status.color} />
            {status.label}
            {integrationId ? ` (${i18n.t('module:Finance:Payments:Details:viaParentApp')})` : ''}
          </div>
        </StyledField>
        <StyledField>
          <Typography bold>
            {i18n.t('module:Finance:Payments:Details:reference')}
          </Typography>
          <div>
            {invoicePayment.number}
          </div>
        </StyledField>
        <StyledField>
          <Typography bold>
            {i18n.t('module:Finance:Payments:Details:paymentMethod')}
          </Typography>
          <div>
            {invoicePayment.paymentType?.name}
            {invoicePayment.integrationId && invoicePayment?.paymentDetails && (
              <React.Fragment>
                {' - '}
                {_.capitalize(invoicePayment.paymentDetails.network)}
                {' '}
                {`**** ${invoicePayment.paymentDetails.lastDigits}`}
                {' '}
              </React.Fragment>
            )}
          </div>
        </StyledField>
        {invoicePayment?.paymentType?.code === PAYMENT_TYPE_VALUES.CHILD_CARE_VOUCHER && (
          <StyledField>
            <Typography bold>
              {i18n.t('module:Finance:Payments:Details:voucherName')}
            </Typography>
            <div>
              {invoicePayment.name}
            </div>
          </StyledField>
        )}
        <StyledField>
          <Typography bold>
            {i18n.t('module:Finance:Payments:Details:amount')}
          </Typography>
          <Typography bold>
            <Currency value={invoicePayment.amount} />
          </Typography>
        </StyledField>
        {!invoicePayment.integrationId && (
          <React.Fragment>
            <Typography margin="25px 0 10px" bold>
              {i18n.t('module:Finance:Payments:Details:comment')}
            </Typography>
            <Typography wordBreak="break-word">
              {invoicePayment.comment || '-'}
            </Typography>
          </React.Fragment>
        )}
        {timelineEntries?.length ? (
          <React.Fragment>
            <Space space="40px" />
            <Typography fontSize={18} margin="0 0 25px" bold>
              {i18n.t('module:Finance:Payments:Details:paymentTimeline')}
            </Typography>
            <Timeline>
              {_.map(timelineEntries, (timeline) => {
                const timelineStatus = _.find(PAYMENT_STATUSES_OPTIONS, ({ value }) => (
                  value === timeline.status
                )) || {}

                return (
                  <Timeline.Item
                    date={moment(timeline.date)}
                    text={(
                      <React.Fragment>
                        <StyledDotStatus color={timelineStatus.color} />
                        {timelineStatus.label}
                      </React.Fragment>
                    )}
                  />
                )
              })}
            </Timeline>
          </React.Fragment>
        ) : null}
        {invoicePayment.integrationId ? (
          <React.Fragment>
            <Space space="30px" />
            <Typography color={NEUTRAL_COLOURS.GRAY}>
              {i18n.t('module:Finance:Payments:Details:transactionId')}
              {': '}
              {invoicePayment.integrationId}
            </Typography>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    )
  }

  const actions = [
    {
      icon: 'download',
      label: i18n.t('global:Download'),
      onClick: onDownloadPayment,
    },
    {
      icon: 'resend-receipt',
      label: i18n.t('global:Resend'),
      onClick: onSendPayment,
    },
  ]

  if (!invoicePayment.integrationId) {
    actions.push({
      icon: 'trash',
      label: i18n.t('global:Remove'),
      onClick: onRemovePayment,
    })
  }

  return (
    <Page.Section
      actions={(
        <Section.Actions
          options={actions}
        />
      )}
      isLoading={isFetching}
      subtitle={(
        <Currency fontSize={22} value={invoicePayment.amount} />
      )}
      title={
        invoicePayment.integrationId
          ? i18n.t('module:Finance:Payments:Details:mobileTitle')
          : i18n.t('module:Finance:Payments:Details:title')
      }
    >
      <Callout content={errorMessages} />
      {renderContent()}
    </Page.Section>
  )
}

export default PaymentDetailsView
