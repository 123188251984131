import { FEATURE_FLAGS, ROLES } from 'constants/security'

const CohortTrackingWrapper = ({ children }) => children

CohortTrackingWrapper.authParams = {
  flags: [FEATURE_FLAGS.COHORT_TRACKER],
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.ROOM_LEADER,
    ROLES.SENIOR_TEACHER,
    ROLES.TEACHER,
  ],
}

export default CohortTrackingWrapper
