import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const StyledItemContainer = styled.div`
  margin-bottom: 20px;
`

export const StyledTitleContainer = styled.div`
  font-weight: 600;
`

export const StyledTableContainer = styled.div`
  display: block;
`
