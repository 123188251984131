import _ from 'lodash'

import React from 'react'

import { Callout, EmptyState, Page, Pagination, SearchBar, Spinner, Table } from 'components'

import i18n from 'translations'

const COLUMNS = [
  {
    align: 'left',
    field: 'child',
    sortKey: 'firstName',
    sortable: true,
    title: _.upperFirst(i18n.t('global:child')),
  },
  {
    field: 'age',
    title: i18n.t('global:Age'),
  },
  {
    field: 'room',
    title: i18n.t('global:Room'),
  },
  {
    field: 'startDate',
    title: _.upperFirst(i18n.t('global:startDate')),
  },
  {
    field: 'codeReference',
    title: i18n.t('global:Child:referenceCode'),
  },
]

const ManagementReferenceCodesView = ({
  errorMessages,
  isLoading,
  onPageChange,
  onSearchChange,
  onSortChange,
  page,
  pageCount,
  sortField,
  sortOrder,
  tableData,
  totalResults,
}) => {
  const renderPagination = () => (
    <Pagination
      page={page}
      pageCount={pageCount}
      titlePlural={i18n.t('global:pagination:children')}
      titleSingular={i18n.t('global:pagination:child')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!isLoading && !tableData?.length) {
      return (
        <EmptyState
          icon="children"
          text1={i18n.t('module:Children:List:emptyStateText')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={COLUMNS}
          data={tableData}
          sortField={sortField}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <SearchBar
        placeholder={i18n.t('module:Management:Finance:ReferenceCodes:searchBarPlaceholder')}
        onChange={onSearchChange}
      />
      <Page.Section
        id="referenceCodesScrollableTarget"
        isLoading={isLoading}
        title={i18n.t('global:Child:childReferenceCodes')}
      >
        <Callout content={errorMessages} error />
        {renderContent()}
      </Page.Section>
    </React.Fragment>
  )
}

export default ManagementReferenceCodesView
