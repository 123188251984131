import { BasicActionOptionsProps } from 'constants/service'

import {
  CLEAR_NURSERIES,
  EXPORT_GROUP_REVENUE,
  EXPORT_GROUP_REVENUE_FAILED,
  EXPORT_GROUP_REVENUE_SUCCESS,
  LIST_NURSERIES,
  LIST_NURSERIES_FAILED,
  LIST_NURSERIES_SUCCESS,
  SEND_NURSERIES_FORECAST_REPORT,
  SEND_NURSERIES_FORECAST_REPORT_FAILED,
  SEND_NURSERIES_FORECAST_REPORT_SUCCESS,
} from './constants'

interface NurseriesListActionsProps extends BasicActionOptionsProps {
  recurrency?: boolean
}

export const list = (options: NurseriesListActionsProps = {}) => ({ nurseriesApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {}, recurrency } = options

  dispatch({ type: LIST_NURSERIES })

  return nurseriesApiClient.list(params).then(
    (response) => {
      dispatch({ payload: { recurrency, response }, type: LIST_NURSERIES_SUCCESS })

      if (onSuccess) {
        onSuccess(response)
      }

      return response
    },
    (error) => {
      dispatch({ payload: error, type: LIST_NURSERIES_FAILED })

      if (onFailed) {
        onFailed(error.response)
      }

      return error
    },
  )
}

export const exportGroupRevenue = (params, onSuccess, onFailed) => ({ nurseriesApiClient }) => (dispatch) => {
  dispatch({ type: EXPORT_GROUP_REVENUE })

  nurseriesApiClient.exportGroupRevenue(params).then(
    (response) => {
      dispatch({ type: EXPORT_GROUP_REVENUE_SUCCESS })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({ type: EXPORT_GROUP_REVENUE_FAILED })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const clear = () => ({
  type: CLEAR_NURSERIES,
})

export const sendForecastReport = (params, onSuccess = () => {}, onFailed) => ({
  nurseriesApiClient,
}) => (dispatch) => {
  dispatch({ type: SEND_NURSERIES_FORECAST_REPORT })

  return nurseriesApiClient.sendForecastReport(params).then(
    () => {
      dispatch({ type: SEND_NURSERIES_FORECAST_REPORT_SUCCESS })

      onSuccess()
    },
    ({ response }) => {
      dispatch({ type: SEND_NURSERIES_FORECAST_REPORT_FAILED })

      onFailed?.(response)
    },
  )
}
