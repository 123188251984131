import moment from 'moment'
import { useMessages, useSync } from '@blossomdev/sync'

import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { AutoSizer, CellMeasurer, CellMeasurerCache, InfiniteLoader, List } from 'react-virtualized'

import { MESSAGE_USER_TYPE } from 'services/legacy/messaging/constants'
import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { Space, Spinner } from 'components'

import Separator from '../Separator'
import Message from '../Message'
import ObserverLastUnreadMessage from '../ObserverLastUnreadMessage'
import { StyledMessagesWrapper } from './MessagesStyled'

const cache = new CellMeasurerCache({
  defaultHeight: 52,
  fixedWidth: true,
})

const Messages = ({
  authUser,
  channel,
  channelId,
  isNewChannelPage,
  onInitialized,
}) => {
  const { updateLastReadMessage } = useSync()
  const { initialized, loadMore, messages } = useMessages(channel)
  const { unreadMessagesCount = 0 } = channel || {}

  const [isInnerInitialized, setIsInnerInitialized] = useState(false)
  const [listRendered, setListRendered] = useState(false)

  const listRef = useRef()
  const loadingMore = useRef(0)
  const [loadedMore, setLoadedMore] = useState(false)
  const bottomPosition = useRef(true)
  const scrollTopPosition = useRef(0)

  useLayoutEffect(() => {
    loadingMore.current = 0
    scrollTopPosition.current = 0
    bottomPosition.current = true
    setLoadedMore(false)
    setListRendered(false)
    setIsInnerInitialized(false)
    cache.clearAll()
  }, [channelId])

  const sortedMessages = useMemo(() => {
    const sorted = [...messages].reverse()

    return () => sorted
  }, [messages.length])

  useEffect(() => {
    if (initialized) {
      onInitialized()
    }
  }, [initialized])

  useLayoutEffect(() => {
    if (!isInnerInitialized) {
      setIsInnerInitialized(true)
    }

    if (isInnerInitialized && !bottomPosition.current && 1 === loadingMore.current) {
      cache.clearAll()
      listRef.current?.recomputeRowHeights()

      loadingMore.current = 2
    }
  }, [messages.length])

  useLayoutEffect(() => {
    if (!loadedMore) {
      return
    }

    listRef.current?.recomputeRowHeights()
    const position = listRef.current?.getOffsetForRow({ alignment: 'start', index: 20 })

    if (position) {
      listRef.current?.scrollToPosition(position + scrollTopPosition.current)
    }

    setLoadedMore(false)
  }, [loadedMore])

  const isRowLoaded = useCallback(({ index }) => 0 < index, [channelId])

  const handleLoadMore = useCallback(() => {
    if (isInnerInitialized && listRendered) {
      loadingMore.current = 1
      loadMore()
    }
  }, [isInnerInitialized, listRendered])

  const handleRowsRendered = useCallback(({ onRowsRendered }) => (params) => {
    onRowsRendered(params)

    if (2 === loadingMore.current) {
      loadingMore.current = 0
      setLoadedMore(true)
    }

    if (!listRendered) {
      setListRendered(true)
    }
  }, [listRendered])

  const handleScroll = useCallback(({ clientHeight, scrollHeight, scrollTop }) => {
    if (!listRendered) {
      return
    }

    bottomPosition.current = clientHeight + scrollTop >= scrollHeight - 150
    scrollTopPosition.current = scrollTop
  }, [listRendered])

  const renderRow = useCallback(({ index, parent, style }) => {
    const messageList = sortedMessages()
    const { author, content, createdAt, id } = messageList[index]
    const nextMessage = messageList[index + 1]
    const prevMessage = messageList[index - 1]
    const isNextMessageSameAuthor = author?.id === nextMessage?.author?.id
    const isPreviousMessageSameAuthor = author?.id === prevMessage?.author?.id
    const isNotSameDateAsPrev = (
      moment(prevMessage?.createdAt || 0).format(DEFAULT_DATE_FORMAT) !== moment(createdAt).format(DEFAULT_DATE_FORMAT)
    )

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={id}
        parent={parent}
        rowIndex={index}
      >
        {({ measure }) => (
          <div
            style={style}
            // eslint-disable-next-line react/no-unknown-property
            onLoad={measure}
          >
            {isNotSameDateAsPrev && (
              <Separator
                date={createdAt}
              />
            )}
            <Message
              author={author}
              content={content}
              createdAt={createdAt}
              first={!isPreviousMessageSameAuthor || isNotSameDateAsPrev}
              isMe={author?.type === MESSAGE_USER_TYPE.USER && author.id === authUser?.id}
              last={!isNextMessageSameAuthor}
              left={author?.type === MESSAGE_USER_TYPE.PARENT}
            />
          </div>
        )}
      </CellMeasurer>
    )
  }, [messages.length])

  const renderAutoSizer = useCallback(({ onRowsRendered, registerChild }) => {
    const messageList = sortedMessages()

    return (
      <AutoSizer>
        {({ height, width }) => (
          <List
            deferredMeasurementCache={cache}
            height={height}
            overscanRowCount={1}
            ref={(ref) => {
              registerChild(ref)
              listRef.current = ref
            }}
            rowCount={messageList.length}
            rowGetter={({ index }) => messageList[index]}
            rowHeight={cache.rowHeight}
            rowRenderer={renderRow}
            scrollToIndex={bottomPosition.current ? messageList.length - 1 : undefined}
            width={width}
            onRowsRendered={handleRowsRendered({ onRowsRendered })}
            onScroll={handleScroll}
          />
        )}
      </AutoSizer>
    )
  }, [listRendered, messages.length])

  const handleView = useMemo(() => {
    let once = false

    return () => {
      if (once) return
      once = true

      if (!unreadMessagesCount || !channel?.lastMessage) {
        return
      }

      setTimeout(() => updateLastReadMessage(channel, !channel?.lastMessage.createdAt), 100)
    }
  }, [unreadMessagesCount])

  if (isNewChannelPage) {
    return <div />
  }

  if (!initialized) {
    return (
      <div>
        <Space space="20px" />
        <Spinner />
      </div>
    )
  }

  if (!messages?.length || !isInnerInitialized) {
    return <div />
  }

  return (
    <StyledMessagesWrapper>
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={handleLoadMore}
        rowCount={messages.length}
        threshold={1}
      >
        {renderAutoSizer}
      </InfiniteLoader>
      <ObserverLastUnreadMessage
        lastMessageRead={0 === unreadMessagesCount}
        onView={handleView}
      />
    </StyledMessagesWrapper>
  )
}

export default Messages
