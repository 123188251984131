import React from 'react'

import { BUTTON_SIZE } from 'components/Button/constants'

import { typeByObject } from 'utils/typescript'

import { Button, DropdownMenu, Toolbar } from 'components'

import i18n from 'translations'

interface InlineEditActionsProps {
  buttonSize?: typeByObject<typeof BUTTON_SIZE>
  disabled?: boolean
  isEdit?: boolean
  isProcessing?: boolean
  onCancelClick?: () => {}
  onDeleteClick?: () => {}
  onEditClick?: () => void
  onSaveClick?: () => {}
  submit?: boolean
}

const InlineEditActions: React.FC<InlineEditActionsProps> = ({
  buttonSize = BUTTON_SIZE.SMALL,
  disabled,
  isEdit,
  isProcessing,
  onCancelClick,
  onDeleteClick,
  onEditClick,
  onSaveClick,
  submit,
}) => (
  <Toolbar noMargin>
    <Toolbar.Group>
      {isEdit && (
        <React.Fragment>
          <Toolbar.Item>
            <Button
              hierarchy="secondary"
              isLoading={isProcessing}
              label={i18n.t('global:Save')}
              size={buttonSize}
              submit={submit}
              negativeMargins
              onClick={onSaveClick}
            />
          </Toolbar.Item>
          <Toolbar.Item>
            <Button
              disabled={isProcessing}
              hierarchy="tertiary"
              label={i18n.t('global:Cancel')}
              size={buttonSize}
              negativeMargins
              onClick={onCancelClick}
            />
          </Toolbar.Item>
        </React.Fragment>
      )}
      {!isEdit && (
        <React.Fragment>
          <Toolbar.Item>
            <Button
              disabled={disabled}
              hierarchy="tertiary"
              label={i18n.t('global:Edit')}
              size={buttonSize}
              negativeMargins
              onClick={onEditClick}
            />
          </Toolbar.Item>
          {onDeleteClick && (
            <Toolbar.Item>
              <DropdownMenu
                disabled={disabled}
                small
              >
                <DropdownMenu.Item
                  type="delete"
                  onClick={onDeleteClick}
                />
              </DropdownMenu>
            </Toolbar.Item>
          )}
        </React.Fragment>
      )}
    </Toolbar.Group>
  </Toolbar>
)

export default InlineEditActions
