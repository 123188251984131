import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledContractPeriod = styled.div`
  display: flex;
  align-items: center;

  ${({ wrap }) => wrap && `
    flex-wrap: wrap;
  `}
`

export const StyledStatus = styled.span`
  border-radius: 11px;
  padding: 1px 16px 2px;
  background-color: ${NEUTRAL_COLOURS.GRAY_SECONDARY};

  ${({ green }) => green && `
    background-color: ${NEUTRAL_COLOURS.GREEN_SECONDARY};
    color: ${NEUTRAL_COLOURS.WHITE};
  `}
`
