import moment from 'moment'
import _ from 'lodash'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { VIEW_MODE } from 'services/legacy/membershipsShifts/constants'
import { AVAILABLE_DAYS, SHIFT_MAIN_TYPES, WORK_SHIFT_TYPES } from 'services/legacy/membershipRegisters/constants'

import { getBrandingColor } from 'utils/branding'
import { isSameDay, millisecondsToHoursAndMinutesString } from 'utils/date'

import { isShiftBreak } from 'module/Staff/StaffHelpers'

import { Banner, EmptyState, InfiniteScroll, Spinner, Table, Typography } from 'components'

import i18n from 'translations'

import { COLUMNS_WIDTHS, HOUR_WIDTH_CONSTANT, TIMELINE_MIDNIGHT_START } from '../../StaffRotaView'
import ChartRota from '../ChartRota'
import StaffingFlags from '../StaffingFlags'
import CalloutContent from '../CalloutContent'
import { StyledTableWrapper } from '../../StaffRotaStyled'
import {
  StyledDayBox,
  StyledDayItemWrapper,
  StyledDayListContainer,
  StyledDayTimelineWrapper,
  StyledHourLine,
  StyledShiftsWrapper,
  StyledTotalBreakWrapper,
} from './DayModeStyled'

const DayMode = ({
  authAccessMap,
  date,
  day,
  displayPastWeekBanner,
  earliestTime,
  isDayDataLoading,
  isFetching,
  latestTime,
  onChangeDay,
  onChangePage,
  onEditShift,
  page,
  pageCount,
  recordContent,
  records,
  room,
  statistics,
}) => {
  const showChart = (_.isInteger(+room) && 0 !== room)

  const filteredRecords = _.filter(records, ({ shifts }) => (
    _.find(shifts, ({ membershipShiftTimes }) => _.find(membershipShiftTimes, ({ day: shiftDate }) => (
      moment(shiftDate).format(DEFAULT_DATE_FORMAT) === moment(day).format(DEFAULT_DATE_FORMAT)
    )))
  ))

  const cutOverlappingElements = (items) => {
    const cutItems = []

    _.each(items, (item) => {
      const { id, startTime, type } = item

      if (WORK_SHIFT_TYPES.CONTRACTED === type || WORK_SHIFT_TYPES.OVERTIME === type) {
        cutItems.push(item)

        return
      }

      const sortedItems = _.sortBy(items, 'startTime')

      const overlappingItems = _.filter(sortedItems, ({ endTime: subEndTime, id: subId }) => (
        id !== subId && subEndTime > startTime
      ))

      if (!overlappingItems?.length) {
        cutItems.push(item)

        return
      }

      const cutPart = [item]
      _.each(overlappingItems, (overlappingItem) => {
        _.each(cutPart, (subItem, index) => {
          if (subItem.endTime > overlappingItem.startTime && overlappingItem.endTime < subItem.endTime) {
            cutPart.splice(
              index,
              1,
              {
                ...item,
                endTime: overlappingItem.startTime,
                startTime: subItem.startTime,
              },
              {
                ...item,
                endTime: subItem.endTime,
                startTime: overlappingItem.endTime,
              },
            )
          }
        })
      })

      cutItems.push(...cutPart)
    })

    return cutItems
  }

  const renderDayList = () => (
    <StyledDayListContainer>
      {_.times(AVAILABLE_DAYS, (i) => {
        const isActive = moment(date).add(i, 'days')
          .format(DEFAULT_DATE_FORMAT) === moment(day).format(DEFAULT_DATE_FORMAT)

        const stats = statistics[moment(date).add(i, 'day').format(DEFAULT_DATE_FORMAT)]?.[0]
        const { staffingFlags: staffingFlagsStats } = stats || {}

        return (
          <StyledDayBox
            isActive={isActive}
            key={`DAY_${i}`}
            onClick={() => onChangeDay(i)}
          >
            <Typography
              color={isActive ? getBrandingColor('primary-color') : NEUTRAL_COLOURS.BASIC}
              transform="uppercase"
            >
              <Typography variant="span" bold>
                {moment(date).add(i, 'days').format('ddd')}
              </Typography>
              {' '}
              <Typography
                color={isActive ? getBrandingColor('primary-color') : NEUTRAL_COLOURS.GRAY}
                fontSize={17}
                variant="span"
              >
                {moment(date).add(i, 'days').format('DD')}
              </Typography>
            </Typography>
            {showChart ? (
              <Typography margin="4px 0 0">
                <Typography variant="span" bold>
                  {staffingFlagsStats?.length}
                </Typography>
                {' '}
                {i18n.t('module:Staff:StaffRota:flags')}
              </Typography>
            ) : null}
          </StyledDayBox>
        )
      })}
    </StyledDayListContainer>
  )

  const renderTimeRow = () => {
    const hourWidth = 100 / (latestTime - earliestTime)

    return (
      <Table.Tr>
        <Table.Td align="left" width={`${COLUMNS_WIDTHS.LEFT}px`}>
          <Typography>
            {i18n.t('module:Staff:StaffRota:times')}
          </Typography>
        </Table.Td>
        <Table.Td relative>
          <StyledDayTimelineWrapper>
            {_.times(latestTime - earliestTime, (i) => (
              <StyledHourLine
                hourWidth={hourWidth}
                index={i}
                key={i}
                time
              >
                <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
                  {`${`${TIMELINE_MIDNIGHT_START === earliestTime + i
                    ? 0
                    : earliestTime + i}`.padStart(2, '0')}:00`}
                </Typography>
              </StyledHourLine>
            ))}
          </StyledDayTimelineWrapper>
        </Table.Td>
      </Table.Tr>
    )
  }

  const renderDayContent = (record) => {
    const { shifts } = record
    let shiftTimes = []
    let leaveTimes = []

    _.each(shifts, (shift) => {
      const { membershipShiftTimes, type: mainType } = shift

      if (SHIFT_MAIN_TYPES.LEAVE === mainType) {
        leaveTimes = [
          ...leaveTimes,
          ..._.map(membershipShiftTimes, (item) => ({
            ...item,
            type: mainType,
          })),
        ]
      } else {
        shiftTimes = [
          ...shiftTimes,
          ..._.map(membershipShiftTimes, (item) => ({
            ...item,
          })),
        ]
      }
    })

    const totalBreak = _.sumBy(_.filter(shiftTimes, ({ day: shiftDay, type }) => (
      isSameDay(shiftDay, day) && isShiftBreak(type)
    )), ({ endTime, startTime }) => endTime - startTime)

    shiftTimes = _.filter(shiftTimes, ({ day: shiftDay, type }) => (
      isSameDay(shiftDay, day) && type !== WORK_SHIFT_TYPES.BREAK_DURATION
    ))
    leaveTimes = _.filter(leaveTimes, ({ day: shiftDay }) => isSameDay(shiftDay, day))

    const totalBreakStartTime = shiftTimes?.length ? shiftTimes[0].startTime : 0

    const hourWidth = 100 / (latestTime - earliestTime)
    const minuteWidth = hourWidth / 60

    shiftTimes = cutOverlappingElements(shiftTimes)

    return (
      <StyledDayTimelineWrapper>
        {_.times(latestTime - earliestTime, (i) => (
          <StyledHourLine
            hourWidth={hourWidth}
            index={i}
          />
        ))}
        <StyledShiftsWrapper
          hasLeaves={!!leaveTimes.length}
          hasShifts={!!shiftTimes?.length}
          hasTotalBreak={!!totalBreak}
          first
        >
          {_.map(shiftTimes, ({ endTime, startTime, type }) => (WORK_SHIFT_TYPES.BREAK === type ? (
            <StyledDayItemWrapper
              endTime={moment(endTime).utc()}
              hasBreak={!!totalBreak}
              hourWidth={hourWidth}
              mainType={SHIFT_MAIN_TYPES.WORK}
              maxValue={latestTime}
              minValue={earliestTime}
              minuteWidth={minuteWidth}
              startTime={moment(startTime).utc()}
              type={type}
            />
          ) : (
            <React.Fragment>
              <StyledDayItemWrapper
                endTime={moment(endTime).utc()}
                hasBreak={!!totalBreak}
                hourWidth={hourWidth}
                mainType={SHIFT_MAIN_TYPES.WORK}
                maxValue={latestTime}
                minValue={earliestTime}
                minuteWidth={minuteWidth}
                startTime={moment(startTime).utc()}
                type={type}
              />
              <StyledDayItemWrapper
                endTime={moment(endTime).utc()}
                hasBreak={!!totalBreak}
                hourWidth={hourWidth}
                mainType={SHIFT_MAIN_TYPES.WORK}
                maxValue={latestTime}
                minValue={earliestTime}
                minuteWidth={minuteWidth}
                startTime={moment(startTime).utc()}
                hasContent
              >
                <CalloutContent>
                  <Typography fontSize={14} nowrap>
                    {moment(startTime).utc().format('HH:mm')}
                    {' - '}
                    {moment(endTime).utc().format('HH:mm')}
                  </Typography>
                </CalloutContent>
              </StyledDayItemWrapper>
            </React.Fragment>
          )))}
          {!!totalBreak && (
            <StyledTotalBreakWrapper
              hourWidth={hourWidth}
              mainType={SHIFT_MAIN_TYPES.WORK}
              maxValue={latestTime}
              minValue={earliestTime}
              minuteWidth={minuteWidth}
              startTime={moment(totalBreakStartTime).utc()}
              hasContent
            >
              <React.Fragment>
                <Typography fontSize={14} variant="div" bold inline>
                  {i18n.t('module:Modals:Staff:Shift:break')}
                </Typography>
                {' '}
                <Typography fontSize={14} variant="span" nowrap>
                  {millisecondsToHoursAndMinutesString(totalBreak)}
                </Typography>
              </React.Fragment>
            </StyledTotalBreakWrapper>
          )}
        </StyledShiftsWrapper>
        {leaveTimes?.length ? (
          <StyledShiftsWrapper
            hasLeaves={!!leaveTimes.length}
            hasShifts={!!shiftTimes?.length}
            hasTotalBreak={!!totalBreak}
            second
          >
            {_.map(leaveTimes, ({ endTime, startTime, type }) => (
              <StyledDayItemWrapper
                endTime={moment(endTime).utc()}
                hourWidth={hourWidth}
                maxValue={latestTime}
                minValue={earliestTime}
                minuteWidth={minuteWidth}
                startTime={moment(startTime).utc()}
                type={type}
              >
                <CalloutContent>
                  <Typography fontSize={14} nowrap>
                    {moment(startTime).utc().format('HH:mm')}
                    {' - '}
                    {moment(endTime).utc().format('HH:mm')}
                  </Typography>
                </CalloutContent>
              </StyledDayItemWrapper>
            ))}
          </StyledShiftsWrapper>
        ) : null}
      </StyledDayTimelineWrapper>
    )
  }

  const renderTbody = () => {
    if (!filteredRecords?.length) {
      return (
        <Table.Tbody>
          {showChart ? (
            <ChartRota
              date={date}
              day={day}
              earliestTime={earliestTime}
              latestTime={latestTime}
              room={room}
              statistics={statistics}
              viewMode={VIEW_MODE.DAY}
            />
          ) : null}
          <Table.Tr>
            <Table.Td colSpan="2">
              <EmptyState
                icon="staff-rota"
                text1={i18n.t('module:Staff:StaffRota:emptyState')}
              />
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      )
    }

    return (
      <Table.Tbody>
        {showChart ? (
          <ChartRota
            date={date}
            day={day}
            earliestTime={earliestTime}
            latestTime={latestTime}
            room={room}
            statistics={statistics}
            viewMode={VIEW_MODE.DAY}
          />
        ) : null}
        {renderTimeRow()}
        {showChart ? (
          <StaffingFlags
            date={date}
            day={day}
            earliestTime={earliestTime}
            latestTime={latestTime}
            statistics={statistics}
            viewMode={VIEW_MODE.DAY}
          />
        ) : null}
        {_.map(filteredRecords, recordContent((record) => (
          <Table.Td
            width={`${((latestTime - earliestTime) + 1) * HOUR_WIDTH_CONSTANT}px`}
            relative
            onClick={authAccessMap.section.isStaffRotaWritePermissionGrated
              ? () => onEditShift(record)
              : null}
          >
            {renderDayContent(record)}
          </Table.Td>
        )))}
      </Table.Tbody>
    )
  }

  const renderTable = () => {
    if (isDayDataLoading) {
      return <Spinner />
    }

    return (
      <InfiniteScroll
        dataLength={filteredRecords?.length || 0}
        hasMore={page < pageCount}
        isFetching={isFetching}
        next={() => onChangePage((+page) + 1)}
      >
        <StyledTableWrapper overflowPolyfill={showChart}>
          <Table
            minWidth={800}
            tableLayout="fixed"
            visualType="border"
            width="auto"
            disableOverflowPolyfill
          >
            {renderTbody()}
          </Table>
        </StyledTableWrapper>
      </InfiniteScroll>
    )
  }

  const renderBanner = () => {
    if (
      (
        moment(date).week() >= moment().week()
        && moment(date).year() === moment().year()
      )
      || moment(date).year() > moment().year()
      || !displayPastWeekBanner
    ) {
      return null
    }

    return (
      <Banner.Warning margin="20px 0 0">
        {i18n.t('module:Staff:StaffRota:pastWeekBanner')}
      </Banner.Warning>
    )
  }

  return (
    <React.Fragment>
      {renderBanner()}
      {renderDayList()}
      {renderTable()}
    </React.Fragment>
  )
}

export default DayMode
