import _ from 'lodash'

import JsonBigint from 'json-bigint'

import { Request } from 'constants/http'

import { isPlainObject } from 'utils/data'

const normalizeEmptyStrings = (object, replacer) => {
  if (isPlainObject(object)) {
    return Object.keys(object).reduce((copy, key) => {
      let value = object[key]
      const newCopy = { ...copy }

      if ('' === value) {
        value = replacer(value)
      }

      newCopy[key] = normalizeEmptyStrings(value, replacer)

      return newCopy
    }, {})
  }

  if (Array.isArray(object)) {
    return object.map((item) => normalizeEmptyStrings(item, replacer))
  }

  return object
}

// if this is an object of some kind then iterate its properties and return an object without empty strings
const NormalizeEmptyStringsMiddleware = (request: Request, next) => {
  let finalRequest = { ...request } as Request

  if (finalRequest.body && (isPlainObject(finalRequest.body) || _.isArray(finalRequest.body))) {
    const replacer = 'POST' === finalRequest.method
      ? () => undefined
      : () => null

    finalRequest = {
      ...finalRequest,
      body: JsonBigint.stringify(normalizeEmptyStrings(finalRequest.body, replacer)),
    }
  }

  return next(finalRequest)
}

export default NormalizeEmptyStringsMiddleware
