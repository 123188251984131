import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import selectors from './selectors'
import actions from './actions'
import * as constants from './constants'
import { OrganizationListState } from './list/reducer'
import { OrganizationSingleState } from './single/reducer'

export interface withOrganizationServiceProps {
  organizationActions: typeof actions
  organizationListState: OrganizationListState
  organizationSelectors: typeof selectors
  organizationSingleState: OrganizationSingleState
}

const withOrganizationService = <P extends withOrganizationServiceProps> (
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ organization }) => ({ organization })

  const mapDispatch = injectActions('organizationActions', actions)

  const Component = ({ organization, organizationActions, ...other }) => (
    <WrappedComponent
      organizationActions={organizationActions}
      organizationConstants={constants}
      organizationListState={organization.list}
      organizationSelectors={selectors}
      organizationSingleState={organization.single}
      {...other as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withOrganizationService
