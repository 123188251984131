import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { Field as FormField, change, getFormValues, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { isRequired } from 'utils/fieldValidation'

import { Callout, DefinitionList, Field, Form, TimeString, Typography } from 'components'

import i18n from 'translations'

import withDailyDiaryRecordHandlers from '../../../withDailyDiaryRecordHandlers'
import { StyledFieldWrapper, StyledVisibleBody } from './SleepRecordStyled'

export const SLEEP_RECORD_EDIT_FORM = 'SleepRecordEditForm'

class SleepRecord extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isEditMode: false,
    }
  }

  handleEditMode = (isEditMode) => {
    const { item, onCancelEdit } = this.props

    if (!isEditMode && onCancelEdit) {
      onCancelEdit(item)
    }

    this.setState({ isEditMode })
  }

  handleChangeDidNotSleep = () => {
    const { changeFieldValue, item } = this.props

    changeFieldValue(`${SLEEP_RECORD_EDIT_FORM}_${item.id}`, 'endTime', null)
  }

  handleSubmitClick = (e) => {
    const { dailyDiaryRecordHelpers: { handleSubmitClick } } = this.props

    handleSubmitClick(e, {
      onFailed: this.handleEditMode,
    })
  }

  render() {
    const { dailyDiaryRecordState, error, formValues, item, onRemoveRecord } = this.props
    const { isProcessing } = dailyDiaryRecordState
    const { isEditMode } = this.state

    const { didNotSleep, endTime, id, record, startTime } = item
    const { comments } = record || {}

    const duration = endTime - startTime
    const durationLabel = _.isNaN(duration) || 0 > duration ? null : moment.preciseDiff(startTime, endTime)

    const actions = (
      <Field.InlineEdit.Actions
        isEdit={isEditMode}
        isProcessing={isProcessing}
        onCancelClick={() => this.handleEditMode(false)}
        onDeleteClick={() => onRemoveRecord(id)}
        onEditClick={() => this.handleEditMode(true)}
        onSaveClick={this.handleSubmitClick}
      />
    )

    const renderEditableComponent = (
      <StyledVisibleBody>
        {(formValues && formValues.didNotSleep) ? (
          <DefinitionList vertical>
            <DefinitionList.Item
              label={(
                <Typography bold primary>
                  {i18n.t('global:Time')}
                </Typography>
              )}
              value={(
                <FormField
                  component={Form.TimePicker}
                  disabled={isProcessing}
                  name="time"
                  validate={isRequired}
                />
              )}
            />
          </DefinitionList>
        ) : (
          <DefinitionList vertical>
            <DefinitionList.Item
              label={(
                <Typography bold primary>
                  {i18n.t('module:DailyDiary:Sleeps:fellAsleep')}
                </Typography>
              )}
              margin="0 0 10px"
              value={(
                <FormField
                  component={Form.TimePicker}
                  disabled={isProcessing}
                  name="startTime"
                  validate={isRequired}
                />
              )}
            />
            <DefinitionList.Item
              label={(
                <Typography bold primary>
                  {i18n.t('module:DailyDiary:Sleeps:wokeUp')}
                </Typography>
              )}
              value={(
                <FormField
                  component={Form.TimePicker}
                  disabled={isProcessing}
                  name="endTime"
                />
              )}
            />
          </DefinitionList>
        )}
        <DefinitionList vertical>
          <DefinitionList.Item
            label={(
              <Typography bold primary>
                {i18n.t('module:DailyDiary:Sleeps:childDidNotSleep')}
              </Typography>
            )}
            value={(
              <FormField
                component={Form.Switch}
                disabled={isProcessing}
                name="didNotSleep"
                onChange={this.handleChangeDidNotSleep}
              />
            )}
          />
        </DefinitionList>
        <DefinitionList vertical>
          <DefinitionList.Item
            label={(
              <Typography bold primary>
                {i18n.t('module:DailyDiary:global:additionalNotes')}
              </Typography>
            )}
            value={(
              <FormField
                component={Form.TextAreaField}
                disabled={isProcessing}
                height={90}
                maxCharacters={2000}
                name="comments"
                placeholder={i18n.t('module:DailyDiary:global:enterAdditionalNotesHere')}
                autosize
              />
            )}
            whiteSpace="pre-line"
          />
        </DefinitionList>
      </StyledVisibleBody>
    )

    const renderVisibleComponent = (
      <StyledVisibleBody>
        <DefinitionList vertical>
          {didNotSleep && (
            <Typography
              margin="0 10px"
              italic
            >
              {i18n.t('module:DailyDiary:Sleeps:childDidNotSleep')}
            </Typography>
          )}
          {!didNotSleep && (
            <React.Fragment>
              <DefinitionList.Item
                label={(
                  <Typography bold primary>
                    {i18n.t('module:DailyDiary:Sleeps:fellAsleep')}
                  </Typography>
                )}
                margin="0 0 10px"
                value={(
                  <TimeString date={startTime} local={false} />
                )}
              />
              <DefinitionList.Item
                label={(
                  <Typography bold primary>
                    {i18n.t('module:DailyDiary:Sleeps:wokeUp')}
                  </Typography>
                )}
                value={endTime ? (
                  <TimeString date={endTime} local={false} />
                ) : '-'}
              />
            </React.Fragment>
          )}
        </DefinitionList>
        <DefinitionList vertical>
          {!didNotSleep && (
            <DefinitionList.Item
              label={(
                <Typography bold primary>
                  {i18n.t('module:DailyDiary:Sleeps:totalTime')}
                </Typography>
              )}
              value={durationLabel || '-'}
            />
          )}
        </DefinitionList>
        <DefinitionList vertical>
          <DefinitionList.Item
            label={(
              <Typography bold primary>
                {i18n.t('module:DailyDiary:global:additionalNotes')}
              </Typography>
            )}
            value={comments || '-'}
            whiteSpace="pre-line"
            wordBreak="break-word"
          />
        </DefinitionList>
      </StyledVisibleBody>
    )

    return (
      <StyledFieldWrapper>
        {isEditMode && <Callout content={error} error />}
        <Form onSubmit={this.handleSubmitClick}>
          <Field.InlineEdit
            actions={actions}
            description={renderVisibleComponent}
            editableComponent={renderEditableComponent}
            isEditMode={isEditMode}
            noPadding
          />
        </Form>
      </StyledFieldWrapper>
    )
  }
}

const mapDispatch = {
  changeFieldValue: (formName, field, value) => change(formName, field, value),
}

const mapState = (state, { item }) => ({
  formValues: getFormValues(`${SLEEP_RECORD_EDIT_FORM}_${item.id}`)(state),
})

const enhance = compose(
  withDailyDiaryRecordHandlers,
)

export default reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: true,
})(enhance(connect(mapState, mapDispatch)(SleepRecord)))
