export const GET_OBSERVATION_FEEDBACK = 'GET_OBSERVATION_FEEDBACK'
export const GET_OBSERVATION_FEEDBACK_SUCCESS = 'GET_OBSERVATION_FEEDBACK_SUCCESS'
export const GET_OBSERVATION_FEEDBACK_FAILED = 'GET_OBSERVATION_FEEDBACK_FAILED'

export const CREATE_OBSERVATION_FEEDBACK = 'CREATE_OBSERVATION_FEEDBACK'
export const CREATE_OBSERVATION_FEEDBACK_SUCCESS = 'CREATE_OBSERVATION_FEEDBACK_SUCCESS'
export const CREATE_OBSERVATION_FEEDBACK_FAILED = 'CREATE_OBSERVATION_FEEDBACK_FAILED'

export const UPDATE_OBSERVATION_FEEDBACK = 'UPDATE_OBSERVATION_FEEDBACK'
export const UPDATE_OBSERVATION_FEEDBACK_SUCCESS = 'UPDATE_OBSERVATION_FEEDBACK_SUCCESS'
export const UPDATE_OBSERVATION_FEEDBACK_FAILED = 'UPDATE_OBSERVATION_FEEDBACK_FAILED'

export const CLEAR_OBSERVATION_FEEDBACK = 'CLEAR_ROOM'
