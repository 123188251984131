import _ from 'lodash'

import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { CHILD_FUNDING_FILTERS, CHILD_FUNDING_FILTER_OPTIONS } from 'services/legacy/childFunding/constants'
import { CHILD_AGES_OPTIONS } from 'services/legacy/child/constants'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withFinanceFundingReportService } from 'services/legacy/financeFundingReport'
import { withPaginationUtils } from 'services/utils/pagination'
import { withSecurityService } from 'services/security'
import { withRouter } from 'services/router'

import { useBasicReport } from 'hooks/reports'

import { Form, InfiniteDropdowns, ReportView, Select, Toolbar } from 'components'

import i18n from 'translations'

import { FinanceReportFundingChart } from './components'
import { getColumns, getStatistics, getTableData } from './FundingV2Helper'

const CHILDREN_BALANCE_GROUPS = {
  read: [
    'childFunding.child',
    'childFunding.funding',
    'child',
    'nurseryFunding',
    'nurseryFunding.fundingType',
    'fundingType',
  ],
}

const NURSERIES_BALANCE_GROUPS = {
  read: [
    'nursery',
    'fundingType',
  ],
}

const FundingV2Container = ({
  childrenData,
  childrenTotalResults,
  errorMessages,
  financeFundingReportActions,
  financeFundingReportSelectors,
  isFetching,
  isOrganizationContext,
  isStatisticsFetching,
  location,
  modalActions,
  modalConsts,
  nurseriesData,
  nurseriesTotalResults,
  paginationUtils,
  setLocationQuery,
  statisticsData,
}) => {
  const {
    age,
    child,
    fundingTypes,
    nursery,
    onAgeChange,
    onChildChange,
    onFundingTypesChange,
    onNurseryChange,
    onPageChange,
    onRoomChange,
    onStatusChange,
    page,
    pageCount,
    perPage,
    room,
    statistics,
    status,
    tableColumns,
    tableData,
    totalResults,
  } = useBasicReport({
    actions: financeFundingReportActions,
    ageOptions: CHILD_AGES_OPTIONS,
    callStatisticsAction: true,
    childrenApiGroups: CHILDREN_BALANCE_GROUPS,
    childrenData,
    childrenTotalResults,
    defaultFilters: {
      status: _.find(CHILD_FUNDING_FILTER_OPTIONS, { value: CHILD_FUNDING_FILTERS.ACTIVE }),
    },
    errorMessages,
    getColumns,
    getStatistics,
    getTableData,
    isOrganizationContext,
    location,
    nurseriesApiGroups: NURSERIES_BALANCE_GROUPS,
    nurseriesData,
    nurseriesTotalResults,
    paginationUtils,
    selectors: financeFundingReportSelectors,
    setLocationQuery,
    statisticsApiGroups: NURSERIES_BALANCE_GROUPS,
    statisticsData,
    statusOptions: CHILD_FUNDING_FILTER_OPTIONS,
  })

  const handleExportClick = () => {
    const criteria = financeFundingReportSelectors.getCriteria({
      ageRange: age?.ageRange,
      nursery,
      room: room?.value,
      status: status?.value,
    })
    const order = { sortField: 'child.firstName' }

    modalActions.show(modalConsts.TYPES.EXPORT_REPORT, {
      filters: {},
      onSendReport: (body, onSuccess, onFailed) => financeFundingReportActions.exportReport({
        onFailed,
        onSuccess,
        params: [{
          ...body,
          criteria: [...body.criteria, ...criteria],
          groups: CHILDREN_BALANCE_GROUPS,
          order,
          page,
        }],
      }),
      title: i18n.t('module:Finance:Reports:Funding:Export:title'),
      userLabel: i18n.t('module:Finance:Reports:Funding:Export:description'),
    })
  }

  const renderFilters = () => (
    <Toolbar bottomLine>
      <Toolbar.Group>
        {isOrganizationContext && (
          <Toolbar.Item>
            <Form.Row
              label={i18n.t('module:Finance:Reports:Funding:Filters:Site:label')}
              verticalLabel
            >
              <InfiniteDropdowns.Nurseries
                value={nursery}
                onChange={onNurseryChange}
              />
            </Form.Row>
          </Toolbar.Item>
        )}
        <Toolbar.Item>
          <Form.Row
            label={i18n.t('module:Finance:Reports:Funding:Filters:Age:label')}
            verticalLabel
          >
            <Select
              options={CHILD_AGES_OPTIONS}
              placeholder={i18n.t('global:AllAges')}
              value={age}
              onChange={onAgeChange}
            />
          </Form.Row>
        </Toolbar.Item>
        <Toolbar.Item>
          <Form.Row
            label={i18n.t('module:Finance:Reports:Funding:Filters:Status:label')}
            verticalLabel
          >
            <Select
              options={CHILD_FUNDING_FILTER_OPTIONS}
              placeholder={i18n.t('module:Finance:Funding:allFundingPlaceholder')}
              value={status}
              onChange={onStatusChange}
            />
          </Form.Row>
        </Toolbar.Item>
        {!isOrganizationContext && (
          <Toolbar.Item>
            <Form.Row
              label={i18n.t('module:Finance:Reports:Funding:Filters:Rooms:label')}
              verticalLabel
            >
              <InfiniteDropdowns.Rooms
                placeholder={i18n.t('global:AllRooms')}
                value={room}
                onChange={onRoomChange}
              />
            </Form.Row>
          </Toolbar.Item>
        )}
        {!isOrganizationContext && (
          <Toolbar.Item>
            <Form.Row
              label={i18n.t('module:Finance:Reports:Funding:Filters:Child:label')}
              verticalLabel
            >
              <InfiniteDropdowns.Children
                value={child}
                onChange={onChildChange}
              />
            </Form.Row>
          </Toolbar.Item>
        )}
        {!isOrganizationContext && (
          <Toolbar.Item>
            <Form.Row
              label={i18n.t('module:Finance:Reports:Funding:Filters:FundingTypes:label')}
              verticalLabel
            >
              <InfiniteDropdowns.NurseryFunding
                placeholder={i18n.t('module:Finance:Reports:Funding:Filters:FundingTypes:placeholder')}
                value={fundingTypes}
                multi
                onChange={onFundingTypesChange}
              />
            </Form.Row>
          </Toolbar.Item>
        )}
      </Toolbar.Group>
    </Toolbar>
  )

  return (
    <ReportView.DetailPage
      chart={(
        <FinanceReportFundingChart
          chart={statistics}
        />
      )}
      description={isOrganizationContext
        ? i18n.t('module:Finance:Reports:Funding:Organization:description')
        : i18n.t('module:Finance:Reports:Funding:description')}
      errorMessages={errorMessages}
      filters={renderFilters()}
      hideExport={isOrganizationContext}
      isChartLoading={isStatisticsFetching}
      isOrganizationContext={isOrganizationContext}
      isPageLoading={isFetching && isStatisticsFetching && 1 === page}
      isTableLoading={isFetching}
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      statisticsGap="60px"
      tableColumns={tableColumns}
      tableData={tableData}
      title={i18n.t('module:Finance:Reports:Funding:title')}
      totalResults={totalResults}
      onExportClick={handleExportClick}
      onPageChange={onPageChange}
    />
  )
}

const mapState = (state, {
  appSelectors,
  financeFundingReportChildrenState,
  financeFundingReportNurseriesState,
  financeFundingReportSelectors,
  financeFundingReportStatisticsState,
  securitySelectors,
}) => ({
  childrenData: financeFundingReportSelectors.getFinanceFundingReportChildrenData(state),
  childrenTotalResults: appSelectors.getTotalResults(financeFundingReportChildrenState),
  errorMessages: appSelectors.getErrorMessages(
    financeFundingReportChildrenState,
    financeFundingReportNurseriesState,
    financeFundingReportStatisticsState,
  ),
  isFetching: appSelectors.getIsFetching(
    financeFundingReportChildrenState,
    financeFundingReportNurseriesState,
  ),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
  isStatisticsFetching: appSelectors.getIsFetching(financeFundingReportStatisticsState),
  nurseriesData: financeFundingReportSelectors.getFinanceFundingReportNurseriesData(state),
  nurseriesTotalResults: appSelectors.getTotalResults(financeFundingReportNurseriesState),
  statisticsData: financeFundingReportSelectors.getFinanceFundingReportStatisticsData(state),
})

const enhance = compose(
  withRouter,
  withAppService,
  withFinanceFundingReportService,
  withModalService,
  withPaginationUtils,
  withSecurityService,
  connect(mapState),
)

export default enhance(FundingV2Container)
