import React, { useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { change, getFormValues } from 'redux-form'

import { NEXT_STEPS_DATE_ACHIEVED_FORM } from './components/NextStepsDateAchievedForm/NextStepsDateAchievedForm'
import NextStepRecordView from './NextStepRecordView'

const NextStepRecordContainer = (props) => {
  const {
    formValues,
    isChildContext,
    item,
    onMarkAsAchieved,
    onReset,
    onSubmitAchieved,
    singleItemIsFetching,
  } = props

  const [isDateAchievedEditMode, setIsDateAchievedEditMode] = useState(false)
  const [isDateAchievedLoading, setIsDateAchievedLoading] = useState(false)

  const { achievedAt, id } = item

  const handleRestoreInitialValues = () => {
    const { changeValue } = props

    changeValue(id, 'date', achievedAt)
  }

  const handleSubmitSuccess = (values, callback) => {
    const { changeValue } = props
    const { date } = values

    changeValue(id, 'date', date)

    return callback()
  }

  const handleSubmit = (callback) => {
    const values = formValues(id)

    if (!values.date) {
      return onReset(item, callback)
    }

    return onSubmitAchieved(item, values, () => handleSubmitSuccess(values, callback))
  }

  const handleDateAchievedSaveClick = () => {
    setIsDateAchievedLoading(true)
    handleSubmit(() => {
      setIsDateAchievedEditMode(false)
      setIsDateAchievedLoading(false)
    })
  }

  const handleDateAchievedCancelClick = () => {
    handleRestoreInitialValues()
    setIsDateAchievedEditMode(false)
    setIsDateAchievedLoading(false)
  }

  return (
    <NextStepRecordView
      isChildContext={isChildContext}
      isDateAchievedEditMode={isDateAchievedEditMode}
      isDateAchievedLoading={isDateAchievedLoading}
      item={item}
      setIsDateAchievedEditMode={setIsDateAchievedEditMode}
      singleItemIsFetching={singleItemIsFetching}
      onDateAchievedCancelClick={handleDateAchievedCancelClick}
      onDateAchievedSaveClick={handleDateAchievedSaveClick}
      onMarkAsAchieved={onMarkAsAchieved}
      onSubmit={handleSubmit}
    />
  )
}

const mapDispatch = {
  changeValue: (id, field, value) => change(`${NEXT_STEPS_DATE_ACHIEVED_FORM}_${id}`, field, value),
}

const mapState = (state) => ({
  formValues: (id) => getFormValues(`${NEXT_STEPS_DATE_ACHIEVED_FORM}_${id}`)(state),
})

const enhance = compose(
  connect(mapState, mapDispatch),
)

export default enhance(NextStepRecordContainer)
