import { combineReducers } from 'redux'

import dropdownList from './dropdownList/reducer'
import list from './list/reducer'
import single from './single/reducer'

export default combineReducers({
  dropdownList,
  list,
  single,
})
