import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_FAILED_RECURRING_PAYMENT,
  GET_FAILED_RECURRING_PAYMENT,
  GET_FAILED_RECURRING_PAYMENT_EXPORT,
  GET_FAILED_RECURRING_PAYMENT_EXPORT_FAILED,
  GET_FAILED_RECURRING_PAYMENT_EXPORT_SUCCESS,
  GET_FAILED_RECURRING_PAYMENT_FAILED,
  GET_FAILED_RECURRING_PAYMENT_SUCCESS,
} from './constants'

export const getFailedRecurringPayment = (
  options: BasicActionOptionsProps,
) => ({ nurseryIntegrationsApiClient }) => generateBasicActions.list({
  constants: {
    failed: GET_FAILED_RECURRING_PAYMENT_FAILED,
    init: GET_FAILED_RECURRING_PAYMENT,
    success: GET_FAILED_RECURRING_PAYMENT_SUCCESS,
  },
  options,
  service: nurseryIntegrationsApiClient,
  serviceMethod: 'failedRecurringPayment',
})

export const exportFailedRecurringPayment = (options = {}) => ({
  nurseryIntegrationsApiClient,
}) => generateBasicActions.list({
  constants: {
    failed: GET_FAILED_RECURRING_PAYMENT_EXPORT_FAILED,
    init: GET_FAILED_RECURRING_PAYMENT_EXPORT,
    success: GET_FAILED_RECURRING_PAYMENT_EXPORT_SUCCESS,
  },
  options,
  service: nurseryIntegrationsApiClient,
  serviceMethod: 'exportFailedRecurringPayment',
})

export const clearFailedRecurringPayment = () => ({
  type: CLEAR_FAILED_RECURRING_PAYMENT,
})
