import React from 'react'
import { Field } from 'redux-form'

import { NurseryFundingProductSettings, NurseryFundingProductType } from 'services/product/nurseryFundingV3/constants'

import { Option } from 'constants/models'

import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

import { isRequired } from 'utils/fieldValidation'
import { createTypeFromEnumValues } from 'utils/typescript'

import { Banner, Form } from 'components'

import i18n from 'translations'

export interface FundingItemOption extends Option {
  settings?: NurseryFundingProductSettings
  type?: createTypeFromEnumValues<NurseryFundingProductType>
}

interface FundingFormFieldsProps {
  isInvoiced: boolean
  item: FundingItemOption
}

const FundingFormFields = ({
  isInvoiced,
  item,
}: FundingFormFieldsProps): JSX.Element => (
  <React.Fragment>
    <Form.Row label={i18n.t('global:Item')} required verticalLabel>
      <Form.Row.FlexItem flex="0 0 300px" mobileFull>
        <Field
          component={Form.InfiniteDropdowns.NurseryFundingsV3}
          criteria={[{
            field: 'type',
            value: NurseryFundingProductType.ABSTRACT_NURSERY_ONE_OFF_FUNDING,
          }]}
          disabled={isInvoiced}
          extraFields={['type', 'settings.total']}
          name="item"
          validate={isRequired}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <SubdomainCurrencyProvider>
      {({ currencySymbol }) => (
        <Form.Row label={i18n.t('module:Finance:OneOffBookings:Form:Funding:valueLabel')} required verticalLabel>
          <Form.Row.FlexItem flex="0 0 300px" mobileFull>
            <Field
              component={Form.TextField}
              disabled={isInvoiced}
              name="value"
              prefix={currencySymbol}
              prefixWidth="30px"
              type="number"
              validate={isRequired}
            />
          </Form.Row.FlexItem>
        </Form.Row>
      )}
    </SubdomainCurrencyProvider>
    {/* <Form.Row label={i18n.t('module:Finance:OneOffBookings:Form:Funding:deductFromParentInvoice')} verticalLabel>
      <Form.Row.FlexItem flex="0 0 300px" mobileFull>
        <Field
          component={Form.Switch}
          name="deductFromParentInvoice"
        />
      </Form.Row.FlexItem>
    </Form.Row> */}
    {item?.type === NurseryFundingProductType.NURSERY_NO_DEDUCT_ONE_OFF_FUNDING && (
      <Banner.Info>
        {i18n.t('module:Finance:OneOffBookings:Form:Funding:banner1Text')}
      </Banner.Info>
    )}
    {item?.type === NurseryFundingProductType.NURSERY_ONE_OFF_FUNDING && (
      <Banner.Info>
        {i18n.t('module:Finance:OneOffBookings:Form:Funding:banner2Text')}
      </Banner.Info>
    )}
  </React.Fragment>
)

export default FundingFormFields
