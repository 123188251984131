import moment from 'moment'
import _ from 'lodash'

import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Banner, Button, Checkbox, DetailsBreakdown, FooterActions, Form, Space, Typography } from 'components'

import i18n from 'translations'

import TimeStatistics from './components/TimeStatistics'
import { StyledWrapper } from './ShiftCopyModalFormStyled'

export const NUMBER_OF_WEEKS = 26

export const STAFF_ROTA_SHIFT_COPY_MODAL_FORM = 'ShiftCopyModalForm'

const ShiftCopyModalForm = ({
  formValues,
  handleSubmit,
  isSubmitting,
  membership,
  onCloseClick,
  onSelectAll,
  onSubmit,
  selectedAll,
  startedWeek,
}) => {
  const renderRow = (index) => {
    const date = moment(startedWeek).startOf('week').add(index + 1, 'week')

    return (
      <DetailsBreakdown.Item
        label={(() => (
          <Field
            component={Form.Checkbox}
            disabled={isSubmitting}
            label={(
              <React.Fragment>
                <Typography inline>
                  {_.upperFirst(`${i18n.t('global:week')} `)}
                  {date.format('w')}
                </Typography>
                <Space padding="0 15px 0 0" inline />
                <Typography
                  color={NEUTRAL_COLOURS.GRAY}
                  inline
                >
                  {moment(date).startOf('week').format('DD MMM')}
                  {' - '}
                  {moment(date).endOf('week').format('DD MMM')}
                </Typography>
              </React.Fragment>
            )}
            name={`weeks.${index}`}
          />
        ))()}
        padding="10px 0"
        proportion="3fr 2fr"
        value={(
          <TimeStatistics
            date={date}
            membership={membership}
          />
        )}
        valueAlign="center"
      />
    )
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Typography>
        {i18n.t('module:Modals:Staff:ShiftCopy:subtitle')}
      </Typography>
      <Space space="20px" />
      <StyledWrapper>
        <DetailsBreakdown>
          <DetailsBreakdown.Item
            label={(
              <Checkbox
                disabled={isSubmitting}
                label={_.upperFirst(i18n.t('global:week'))}
                value={selectedAll}
                onChange={onSelectAll}
              />
            )}
            proportion="3fr 2fr"
            value={i18n.t('module:Modals:Staff:ShiftCopy:currentlyAllocatedHours')}
            valueAlign="center"
          />
          {_.times(NUMBER_OF_WEEKS, renderRow)}
        </DetailsBreakdown>
      </StyledWrapper>
      <Space space="40px" />
      <Banner.Warning>
        {i18n.t('module:Modals:Staff:ShiftCopy:replacedWarning')}
      </Banner.Warning>
      <FooterActions spaceBetween>
        <FooterActions.Group>
          <FooterActions.Item>
            <Button
              disabled={isSubmitting}
              hierarchy="tertiary"
              label={i18n.t('global:Skip')}
              negativeMargins
              onClick={onCloseClick}
            />
          </FooterActions.Item>
        </FooterActions.Group>
        <FooterActions.Group>
          {_.sumBy(formValues?.weeks, (i) => !!i) ? (
            <FooterActions.Item>
              <Typography color={NEUTRAL_COLOURS.GRAY}>
                {i18n.t('module:Modals:Staff:ShiftCopy:copyRange')}
              </Typography>
              <Space space="2px" />
              <Typography>
                {moment(startedWeek).startOf('week').format('ddd DD MMM')}
                {' - '}
                {moment(startedWeek).endOf('week').format('ddd DD MMM')}
                {` ${i18n.t('global:to')} `}
                {_.sumBy(formValues?.weeks, (i) => !!i) || 0}
                {` ${i18n.t('global:weeks')}`}
              </Typography>
            </FooterActions.Item>
          ) : null}
          <FooterActions.Item>
            <Button
              disabled={!_.sumBy(formValues?.weeks, (i) => !!i)}
              isLoading={isSubmitting}
              label={i18n.t('global:copy')}
              negativeMargins
              submit
            />
          </FooterActions.Item>
        </FooterActions.Group>
      </FooterActions>
    </Form>
  )
}

export default reduxForm({ form: STAFF_ROTA_SHIFT_COPY_MODAL_FORM })(ShiftCopyModalForm)
