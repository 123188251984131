import React from 'react'
import { connect } from 'react-redux'

import actions from './actions'
import selectors from './selectors'

const withChildExtraItemsService = (WrappedComponent) => {
  const mapState = ({ childExtraItems }) => ({ childExtraItems })

  const mapDispatch = {
    clear: actions.clear,
    clearProjections: actions.clearProjections,
    clearSingle: actions.clearExtraItem,
    create: actions.createExtraItem,
    get: actions.getExtraItem,
    list: actions.listExtraItems,
    listProjections: actions.listExtraItemProjections,
    update: actions.updateExtraItem,
  }

  const Component = (props) => {
    const {
      childExtraItems,
      clear,
      clearProjections,
      clearSingle,
      create,
      get,
      list,
      listProjections,
      update,
      ...other
    } = props

    const childExtraItemsListState = {
      ...childExtraItems.list,
      isEmpty: childExtraItems.list.data && 0 === childExtraItems.list.data.length,
    }

    const childExtraItemsActions = { clear, clearProjections, clearSingle, create, get, list, listProjections, update }

    const getAllChildExtraItems = (params, page = 1) => {
      list({ ...params, page }, 1 < page, ({ meta }) => {
        const { limit, start } = meta
        const totalResults = meta.total_results

        if (start * limit < totalResults) {
          getAllChildExtraItems(params, page + 1)
        }
      })
    }

    return (
      <WrappedComponent
        childExtraItemsActions={childExtraItemsActions}
        childExtraItemsHelper={{ getAllChildExtraItems }}
        childExtraItemsListProjectionsState={childExtraItems.listProjections}
        childExtraItemsListState={childExtraItemsListState}
        childExtraItemsSelectors={selectors}
        childExtraItemsSingleState={childExtraItems.single}
        {...other}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withChildExtraItemsService
