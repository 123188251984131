import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { CHILD_FUNDING_FILTERS, CHILD_FUNDING_FILTER_OPTIONS } from 'services/legacy/childFunding/constants'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withChildFundingService } from 'services/legacy/childFunding'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withSortingUtils } from 'services/utils/sorting'

import ChildFundingView from './ChildFundingView'

const CHILD_FUNDING_GROUPS = {
  read: [
    'childFunding.child',
    'childFunding.funding',
    'nurseryFunding',
    'nurseryFunding.fundingType',
    'fundingType',
  ],
}

class ChildFundingContainer extends Component {
  constructor(props) {
    super(props)

    const { status } = props.location.query

    this.state = {
      filters: {
        status: status || CHILD_FUNDING_FILTERS.ACTIVE,
      },
    }
  }

  componentDidMount() {
    const { sortingUtils } = this.props
    const { onSortChange } = sortingUtils

    onSortChange(this.fetch())
  }

  componentWillUnmount() {
    const { childFundingActions } = this.props

    childFundingActions.clear()
  }

  fetch = () => {
    const {
      childFundingActions,
      childFundingHelpers,
      paginationUtils,
      params,
      setLocationQuery,
      sortingUtils,
    } = this.props
    const { filters } = this.state
    const { page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils
    const { childId } = params

    const criteria = childFundingHelpers.getCriteria(filters, childId)

    setLocationQuery(filters)

    const apiParams = {
      criteria,
      groups: CHILD_FUNDING_GROUPS,
      order: { sortField, sortOrder },
      page,
    }

    childFundingActions.list(apiParams)
  }

  handleStatusFilterChange = (status) => {
    const { paginationUtils, sortingUtils } = this.props

    const { onPageChange } = paginationUtils
    const { onSortChange } = sortingUtils

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          status: status ? status.value : null,
        },
      }),
      () => onSortChange(onPageChange(this.fetch)(1)),
    )
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleSortChange = (key) => {
    const { paginationUtils, sortingUtils } = this.props
    const { onSortChange } = sortingUtils
    const { onPageChange } = paginationUtils

    onSortChange(onPageChange(this.fetch)(1))(key)
  }

  render() {
    const {
      child,
      childFundingListState,
      childFundingTableData,
      errorMessages,
      isFetching,
      paginationUtils,
      sortingUtils,
    } = this.props
    const { filters: { status } } = this.state
    const { getPageCount, page, perPage } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const isEmpty = childFundingListState.isEmpty
    const isLoading = isFetching
    const fundingFilterOptions = CHILD_FUNDING_FILTER_OPTIONS
    const pageCount = getPageCount(childFundingListState.meta.total_results)

    return (
      <ChildFundingView
        child={child}
        errorMessages={errorMessages}
        fundingFilterOptions={fundingFilterOptions}
        isEmpty={isEmpty}
        isLoading={isLoading}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        sortField={sortField}
        sortOrder={sortOrder}
        status={status}
        tableData={childFundingTableData}
        totalResults={childFundingListState.meta.total_results}
        onPageChange={this.handlePageChange}
        onSortChange={this.handleSortChange}
        onStatusFilterChange={this.handleStatusFilterChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childFundingListState,
  childFundingSelectors,
  childSelectors,
}) => ({
  child: childSelectors.getChildSelector(state),
  childFundingTableData: childFundingSelectors.getListTableDataSelectors(state),
  errorMessages: appSelectors.getErrorMessages(childFundingListState),
  isFetching: appSelectors.getIsFetching(childFundingListState),
})

const enhance = compose(
  withAppService,
  withChildService,
  withChildFundingService,
  withPaginationUtils,
  withRouterUtils,
  withSortingUtils,
  connect(mapState),
)

export default enhance(ChildFundingContainer)
