import _ from 'lodash'

import React, { useState } from 'react'
import {
  Field as FormField,
  InjectedFormProps,
  change,
  getFormValues as getFormValuesForm,
  reduxForm,
} from 'redux-form'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { BACS_TYPE, IntegrationFeeModel } from 'services/integrationFees/constants'

import { isRequired, isValidInteger } from 'utils/fieldValidation'
import { getBackendErrors } from 'utils/backendErrors'

import { withIntegrationFeesService, withIntegrationFeesServiceProps } from 'services/integrationFees'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { ModalType } from 'modals'

import { Form, Typography } from 'components'
import InlineEditableField from 'module/Management/components/InlineEditableField'

import i18n from 'translations'

import { StyledCustomFeeWrapper, StyledCustomFormWrapper } from './FeeItemFormStyled'

export const CUSTOM_FEE_FORM = 'CUSTOM_FEE_FORM'

interface FeeItemFormProps {
  changeField: (formName: string, fieldName: string) => void
  defaultFees: IntegrationFeeModel[]
  fee: IntegrationFeeModel
  form: string
  getFormValues: any
  nursery: any
  onCloseDraft: (fee: IntegrationFeeModel) => void
  onCreateFeeSuccess: (response: any, fee: IntegrationFeeModel, onSuccess: () => void) => void
  onDeleteFeeSuccess: (fee: IntegrationFeeModel) => void
  onSubmit: () => void
  onUpdateFeeSuccess: (response: any, fee: IntegrationFeeModel, onSuccess: () => void) => void
}

type FeeItemFormFullProps = InjectedFormProps<{} & FeeItemFormProps>
  & withIntegrationFeesServiceProps
  & withModalServiceProps
  & FeeItemFormProps

const FeeItemForm: React.FC<FeeItemFormFullProps> = ({
  changeField,
  defaultFees,
  fee,
  form,
  getFormValues,
  handleSubmit,
  integrationFeesActions,
  integrationFeesSelectors,
  modalActions,
  nursery,
  onCloseDraft,
  onCreateFeeSuccess,
  onDeleteFeeSuccess,
  onSubmit,
  onUpdateFeeSuccess,
}) => {
  const [isDisabled, setIsDisabled] = useState(false)

  const handleClose = () => {
    if (fee.isDraft && onCloseDraft) {
      onCloseDraft(fee)
    }
  }

  const onChangePaymentType = (e) => {
    if (e.value === BACS_TYPE) {
      changeField(form, 'extraAmount')
    }

    if (e.value !== BACS_TYPE) {
      changeField(form, 'maxAmount')
      changeField(form, 'minAmount')
    }
  }

  const handleFormSubmitFailed = (onFailed) => (response) => {
    const errors = getBackendErrors(response)
    setIsDisabled(false)

    onFailed(errors)
  }

  const handleFormSubmit = () => (onSuccess, onFailed) => {
    setIsDisabled(true)

    const values = getFormValues(form)
    const body = integrationFeesSelectors.getFinalBody(values, nursery?.id)

    if (fee.isDraft) {
      return integrationFeesActions.create({
        body,
        onFailed: handleFormSubmitFailed(onFailed),
        onSuccess: (response) => {
          setIsDisabled(false)
          onCreateFeeSuccess(response, fee, onSuccess)
        },
      })
    }

    return integrationFeesActions.update({
      body,
      onFailed: handleFormSubmitFailed(onFailed),
      onSuccess: (response) => {
        setIsDisabled(false)
        onUpdateFeeSuccess(response, fee, onSuccess)
      },
      params: [fee.id],
    })
  }

  const handleDeleteClickConfirmed = () => {
    integrationFeesActions.remove({
      onSuccess: () => onDeleteFeeSuccess(fee),
      params: [fee.id],
    })
  }

  const handleDeleteClick = () => {
    modalActions.show<ModalType.CONFIRM>(ModalType.CONFIRM, {
      confirmButtonLabel: i18n.t('global:Delete'),
      icon: 'trash',
      onConfirm: handleDeleteClickConfirmed,
      text: i18n.t('module:Nurseries:FeesSection:deleteFee'),
    })
  }

  const values = getFormValues(form)
  const isBacs = BACS_TYPE === values?.paymentType?.value || BACS_TYPE === values?.paymentType

  return (
    <InlineEditableField
      description={(
        <StyledCustomFeeWrapper>
          <Typography fontSize={15} bold>
            {i18n.t(`module:Nurseries:FeesSection:paymentTypes:${fee.paymentType}`) || '-'}
          </Typography>
          <div>
            {fee.minAmount || '-'}
          </div>
          <div>
            {fee.maxAmount || '-'}
          </div>
          <div>
            {`${fee.percentage}%` || '-'}
          </div>
          <div>
            {fee.extraAmount || '-'}
          </div>
        </StyledCustomFeeWrapper>
      )}
      editableComponent={() => (
        // @ts-ignore
        <Form onSubmit={handleSubmit(onSubmit)}>
          <StyledCustomFormWrapper>
            <div>
              <FormField
                // @ts-ignore
                component={Form.Select}
                disabled={isDisabled}
                name="paymentType"
                options={_.map(defaultFees, (item: IntegrationFeeModel) => ({
                  label: i18n.t(`module:Nurseries:FeesSection:paymentTypes:${item.paymentType}`),
                  value: item.paymentType,
                }))}
                placeholder={i18n.t('module:Nurseries:FeesSection:paymentType')}
                validate={[isRequired]}
                width="160"
                onChange={onChangePaymentType}
              />
            </div>
            <div>
              <FormField
                // @ts-ignore
                component={Form.TextField}
                disabled={isDisabled || !isBacs}
                name="minAmount"
                placeholder={i18n.t('module:Nurseries:FeesSection:minAmount')}
                type="number"
                validate={isBacs && [isRequired, isValidInteger]}
              />
            </div>
            <div>
              <FormField
                // @ts-ignore
                component={Form.TextField}
                disabled={isDisabled || !isBacs}
                name="maxAmount"
                placeholder={i18n.t('module:Nurseries:FeesSection:maxAmount')}
                type="number"
                validate={isBacs && [isRequired, isValidInteger]}
              />
            </div>
            <div>
              <FormField
                // @ts-ignore
                component={Form.TextField}
                disabled={isDisabled}
                name="percentage"
                placeholder={i18n.t('module:Nurseries:FeesSection:percentage')}
                type="number"
                validate={[isRequired, isValidInteger]}
              />
            </div>
            <div>
              <FormField
                // @ts-ignore
                component={Form.TextField}
                disabled={isDisabled || isBacs}
                name="extraAmount"
                placeholder={i18n.t('module:Nurseries:FeesSection:cap')}
                type="number"
                validate={!isBacs && [isRequired, isValidInteger]}
              />
            </div>
          </StyledCustomFormWrapper>
        </Form>
      )}
      formName={form}
      isEdit={fee.isDraft}
      key={fee.id}
      enableHandleFailed
      onClose={handleClose}
      onDeleteClick={handleDeleteClick}
      onSubmit={handleFormSubmit}
    />
  )
}

const mapDispatch = {
  changeField: (formName, field, value) => change(formName, field, value),
}

const mapState = (state) => ({
  getFormValues: (formName) => getFormValuesForm(formName)(state),
})

const enhance = compose(
  connect(mapState, mapDispatch),
  withIntegrationFeesService,
  withModalService,
)

export default reduxForm({})(enhance(FeeItemForm))
