import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { startSubmit, stopSubmit } from 'redux-form'

import { UPDATE_NURSERY_SETTINGS_EVENT } from 'services/nurseries/constants'
import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'
import eventBus from 'utils/eventBus'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withSecurityService } from 'services/security'

import ManagementGeneralSettingsView from './ManagementGeneralSettingsView'

const NURSERY_SETTINGS_GROUP = {
  read: [
    'assessmentPeriodSettings',
    'nursery.settings',
    'nurserySettings.learning',
    'nurseryLearningSettings',
    'nursery.details',
    'nurserySettings',
    'nurserySettings.occupancy',
    'nurserySettings.staffRota',
    'nurserySettings.messaging',
    'nurseryOccupancySettings',
    'nurseryOccupancySettings.staffChildRatios',
    'nurseryStaffRotaSettings',
    'staffChildRatioSettings',
    'membership',
  ],
}

class ManagementGeneralSettingsContainer extends Component {
  componentDidMount() {
    this.getNurseryData()
  }

  componentWillUnmount() {
    const { nurseriesActions } = this.props

    nurseriesActions.clearSingle()
  }

  getNurseryData = () => {
    const { nurseriesActions, nurseryOptions } = this.props

    nurseriesActions.get(nurseryOptions.id, {
      onSuccess: (response) => {
        eventBus.dispatch(UPDATE_NURSERY_SETTINGS_EVENT, response)
      },
      params: { groups: NURSERY_SETTINGS_GROUP },
    })
  }

  handleSubmitSuccess = (callback) => (response) => {
    this.getNurseryData()

    callback(response)
  }

  handleSubmit = (fields) => (successCallback, failedCallback = () => {}) => {
    const {
      nurseriesActions,
      nurseriesSelectors,
      nurseriesSingleState,
      nurseryOptions,
    } = this.props

    const payload = nurseriesSelectors.getNurserySettingsPayload({
      fields,
      nurseriesSingleState,
    })

    nurseriesActions.update(nurseryOptions.id, {
      onFailed: failedCallback,
      onSuccess: this.handleSubmitSuccess(successCallback),
      payload,
      silent: true,
    })
  }

  render() {
    const {
      authAccessMap: { section: { StaffingEnabled } },
      injectValidation,
      isFetching,
      nurseryOccupancySettings,
      nurseryStaffRotaSettings,
      startSubmitForm,
    } = this.props || {}

    return (
      <ManagementGeneralSettingsView
        injectValidation={injectValidation}
        isFetching={isFetching}
        isStaffingEnabled={StaffingEnabled}
        nurseryOccupancySettings={nurseryOccupancySettings || {}}
        nurseryStaffRotaSettings={nurseryStaffRotaSettings || {}}
        startSubmitForm={startSubmitForm}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

ManagementGeneralSettingsContainer.authParams = {
  roles: [
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.SUPER_ADMIN,
  ],
}

const mapDispatch = {
  injectValidation: (formName, data) => stopSubmit(formName, data),
  startSubmitForm: (formName) => startSubmit(formName),
}

const mapState = (state, {
  appSelectors,
  nurseriesSelectors,
  nurseriesSingleState,
  params,
}) => ({
  authAccessMap: {
    section: {
      StaffingEnabled: auth.SELECTORS.getIsAuthorised(state, {
        flags: [FEATURE_FLAGS.STAFF_REGISTER],
      }),
    },
  },
  errorMessages: appSelectors.getErrorMessages(nurseriesSingleState),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState),
  nurseryOccupancySettings: nurseriesSelectors.getNurseryOccupancySettings(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  nurseryStaffRotaSettings: nurseriesSelectors.getNurseryStaffRotaSettings(state),
})

const enhance = compose(
  withAppService,
  withNurseriesService,
  withSecurityService,
  connect(mapState, mapDispatch),
)

export default enhance(ManagementGeneralSettingsContainer)

