import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { isSameDay } from 'utils/date'

import i18n from 'translations'

import { getStartAndEndDateFromTimeRange } from '../helpers'

import { STAFF_ENTRY_TYPES } from '../constants'
import { getMembershipRegisterSingleWorklogData } from '../single/selectors'

const HOURS_MINUTES_FORMAT = 'HH:mm'
const SHORT_DATE_FORMAT = 'DD/MM/YY'

const getChangelog = (state) => state.membershipRegisters.changelog

const getTimeWithDate = (time, isSameDayEntry) => {
  if (!isSameDayEntry) {
    return _.join([
      moment(time).format(HOURS_MINUTES_FORMAT),
      moment(time).format(SHORT_DATE_FORMAT),
    ], '  ')
  }

  return moment(time).format(HOURS_MINUTES_FORMAT)
}

const getSummary = (entries) => _.join(_.map(entries, (entry) => {
  let summary = ''

  if (entry?.nurseryClass?.new) {
    summary += STAFF_ENTRY_TYPES.BREAK === entry.type
      ? i18n.t('module:Modals:Staff:Worklog:ChangeLog:Type:Break:location')
      : i18n.t('module:Modals:Staff:Worklog:ChangeLog:Type:Work:location')
  }

  if (entry?.signInAt?.new || entry?.signOutAt?.new) {
    summary += summary ? ', ' : ''
    summary += STAFF_ENTRY_TYPES.BREAK === entry.type
      ? i18n.t('module:Modals:Staff:Worklog:ChangeLog:Type:Break:time')
      : i18n.t('module:Modals:Staff:Worklog:ChangeLog:Type:Work:time')
  }

  if (entry?.signInAt?.old && entry?.signOutAt?.old && !entry?.signInAt?.new && !entry?.signOutAt?.new) {
    summary += summary ? ', ' : ''
    summary += i18n.t('module:Modals:Staff:Worklog:ChangeLog:Type:activityRemoved')
  }

  if (null === entry) {
    summary += summary ? ', ' : ''
    summary += i18n.t('module:Modals:Staff:Worklog:ChangeLog:Type:activityRemoved')
  }

  return summary
}), ', ')

const getEntries = (entries, date) => _.flatten(_.map(entries, (entry) => {
  const {
    nurseryClass,
    signInAt,
    signOutAt,
    type,
  } = entry || {}

  const {
    new: nurseryClassNew,
    old: nurseryClassOld,
  } = nurseryClass || {}

  const {
    name: nurseryClassNewName,
  } = nurseryClassNew || {}

  const {
    name: nurseryClassOldName,
  } = nurseryClassOld || {}

  const {
    endDate: signOutAtNew,
    startDate: signInAtNew,
  } = getStartAndEndDateFromTimeRange(date, signInAt?.new, signOutAt?.new)

  const {
    endDate: signOutAtOld,
    startDate: signInAtOld,
  } = getStartAndEndDateFromTimeRange(date, signInAt?.old, signOutAt?.old)

  const isSameDayEntryOld = isSameDay(signInAtOld, signOutAtOld)
  const isSameDayEntryNew = isSameDay(signInAtNew, signOutAtNew)

  const changeBothTimes = signInAtNew && signOutAtNew
  const changeSignInTime = signInAtNew && !signOutAtNew
  const changeSignOutTime = !signInAtNew && signOutAtNew
  const removeActivity = signInAtOld && !signInAtNew
  const changeLocation = (nurseryClassNewName || nurseryClassOldName) && !removeActivity
  const isBreak = STAFF_ENTRY_TYPES.BREAK === type

  return [
    ...changeBothTimes ? [{
      newValue: _.join([
        ...signInAtNew ? [
          moment(signInAtNew).format(HOURS_MINUTES_FORMAT),
        ] : [],
        ...signOutAtNew ? [
          getTimeWithDate(signOutAtNew, isSameDayEntryNew),
        ] : [],
      ], ' - ') || '-',
      oldValue: signOutAt?.old ? _.join([
        ...signInAtOld ? [
          moment(signInAtOld).format(HOURS_MINUTES_FORMAT),
        ] : [],
        ...signOutAtOld ? [
          getTimeWithDate(signOutAtOld, isSameDayEntryOld),
        ] : [signInAtOld && _.upperFirst(i18n.t('global:ongoing'))],
      ], ' - ') : '-',
      type: isBreak
        ? i18n.t('module:Modals:Staff:Worklog:ChangeLog:Type:Break:time')
        : i18n.t('module:Modals:Staff:Worklog:ChangeLog:Type:Work:time'),
    }] : [],
    ...changeSignInTime ? [{
      newValue: signInAtNew && moment(signInAtNew).format(HOURS_MINUTES_FORMAT),
      oldValue: signInAtOld ? moment(signInAtOld).format(HOURS_MINUTES_FORMAT) : '-',
      type: isBreak
        ? i18n.t('module:Modals:Staff:Worklog:ChangeLog:Type:Break:clockInTime')
        : i18n.t('module:Modals:Staff:Worklog:ChangeLog:Type:Work:clockInTime'),
    }] : [],
    ...changeSignOutTime ? [{
      newValue: signOutAtNew
        ? getTimeWithDate(signOutAtNew, isSameDayEntryNew)
        : '-',
      oldValue: signOutAtOld
        ? getTimeWithDate(signOutAtOld, isSameDayEntryOld)
        : '-',
      type: isBreak
        ? i18n.t('module:Modals:Staff:Worklog:ChangeLog:Type:Break:clockOutTime')
        : i18n.t('module:Modals:Staff:Worklog:ChangeLog:Type:Work:clockOutTime'),
    }] : [],
    ...removeActivity ? [{
      newValue: i18n.t('module:Modals:Staff:Worklog:ChangeLog:removed'),
      oldValue: _.join([
        moment(signInAtOld).format(HOURS_MINUTES_FORMAT),
        getTimeWithDate(signOutAt?.old, isSameDayEntryOld),
      ], ' - ') || '-',
      type: isBreak
        ? i18n.t('module:Modals:Staff:Worklog:ChangeLog:Type:Break:time')
        : i18n.t('module:Modals:Staff:Worklog:ChangeLog:Type:Work:time'),
    }] : [],
    ...changeLocation ? [{
      newValue: nurseryClassNewName || '-',
      oldValue: nurseryClassOldName || '-',
      type: isBreak
        ? i18n.t('module:Modals:Staff:Worklog:ChangeLog:Type:Break:location')
        : i18n.t('module:Modals:Staff:Worklog:ChangeLog:Type:Work:location'),
    }] : [],
    ...null === entry ? [{
      type: i18n.t('module:Modals:Staff:Worklog:ChangeLog:Type:activityRemoved'),
    }] : [],
  ]
}))

export const getChangelogDataSelector = createSelector(
  [getChangelog, getMembershipRegisterSingleWorklogData],
  (changelog, record) => _.map(changelog.data, (item) => ({
    ...item,
    data: {
      ...item?.data,
      entries: getEntries(item?.data.entries, record?.date),
      summary: getSummary(item?.data.entries),
    },
  })),
)
