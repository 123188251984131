import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'

import { STAFF_ENTRY_TYPES } from 'services/legacy/membershipRegisters/constants'

import { changeHours } from 'utils/date'

import { withModalService } from 'services/utils/modal'
import { withMembershipsService } from 'services/legacy/memberships'
import { withMembershipRegistersService } from 'services/legacy/membershipRegisters'

import AddTimeModalView from './AddTimeModalView'
import { STAFF_WORKLOG_ADD_TIME_MODAL_FORM } from './components/AddTimeModalForm'

class AddTimeModalContainer extends Component {
  constructor(props) {
    super(props)

    const { selectedDate } = props

    const signOutAt = moment(selectedDate).set({
      hour: moment().hour(),
      minute: moment().minute(),
      second: 0,
    })

    this.state = {
      initialValues: {
        signInAt: moment(signOutAt).add(-1, 'hours'),
        signOutAt,
        type: STAFF_ENTRY_TYPES.SIGN_IN,
      },
      timeRange: null,
    }
  }

  componentDidMount() {
    const { initialValues } = this.state
    const { signInAt, signOutAt } = initialValues

    this.handleCalculateTimeRange(signInAt, signOutAt)
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSubmit = (fields) => {
    const { hideModal, onSubmit, selectedDate } = this.props
    const { timeRange } = this.state
    const finalSignOutAt = changeHours(selectedDate, fields.signOutAt)
    const finalSignInAt = changeHours(selectedDate, fields.signInAt)

    onSubmit({
      ...fields,
      signInAt: finalSignInAt,
      signOutAt: finalSignOutAt,
      totalTime: timeRange,
    })
    hideModal()
  }

  handleCalculateTimeRange = (signInAt, signOutAt) => {
    const { formValues, selectedDate } = this.props
    const { signInAt: formSignInAt, signOutAt: formSignOutAt } = formValues || {}
    const finalSignOutAt = changeHours(selectedDate, signOutAt || formSignOutAt)
    const finalSignInAt = changeHours(selectedDate, signInAt || formSignInAt)
    const timeRange = finalSignOutAt.diff(finalSignInAt)

    this.setState({
      timeRange: 0 > timeRange ? null : timeRange,
    })
  }

  render() {
    const { formValues, memberDetails, selectedDate } = this.props
    const { initialValues, timeRange } = this.state

    return (
      <AddTimeModalView
        formValues={formValues}
        initialValues={initialValues}
        memberDetails={memberDetails}
        selectedDate={selectedDate}
        timeRange={timeRange}
        onCalculateTimeRange={this.handleCalculateTimeRange}
        onCloseClick={this.handleCloseClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, { membershipsSelectors }) => ({
  formValues: getFormValues(STAFF_WORKLOG_ADD_TIME_MODAL_FORM)(state),
  memberDetails: membershipsSelectors.getMembershipFormattedDataSelector(state),
})

const enhance = compose(
  withModalService,
  withMembershipsService,
  withMembershipRegistersService,
  connect(mapState),
)

export default enhance(AddTimeModalContainer)
