import { generateBasicActions } from 'utils/service'

import {
  CLEAR_NURSERY_PAYMENT_TYPES_LIST,
  LIST_NURSERY_PAYMENT_TYPES,
  LIST_NURSERY_PAYMENT_TYPES_FAILED,
  LIST_NURSERY_PAYMENT_TYPES_SUCCESS,
} from './constants'

export const list = (options = {}) => ({
  nurseryPaymentTypesApiClient,
}) => generateBasicActions.list({
  constants: {
    failed: LIST_NURSERY_PAYMENT_TYPES_FAILED,
    init: LIST_NURSERY_PAYMENT_TYPES,
    success: LIST_NURSERY_PAYMENT_TYPES_SUCCESS,
  },
  options,
  service: nurseryPaymentTypesApiClient,
  serviceMethod: 'list',
})

export const clearList = () => ({
  type: CLEAR_NURSERY_PAYMENT_TYPES_LIST,
})
