import { v4 } from 'uuid'

import React from 'react'

import { Button, InfiniteScroll, SearchBar, Spinner, Table, Toolbar } from 'components'

import i18n from 'translations'

import { getTableData } from './helpers'

const TABLE_NAME_ID = v4()

const renderColumns = (totalResults) => ([
  {
    align: 'left',
    alignTh: 'left',
    field: 'name',
    id: TABLE_NAME_ID,
    title: i18n.t('module:Newsletters:Preview:Recipients:Table:recipients', { total: totalResults }),
  },
  {
    field: 'sendDate',
    sortKey: 'sendDate',
    sortable: true,
    title: i18n.t('module:Newsletters:Preview:Recipients:Table:lastSent'),
  },
  {
    field: 'resend',
    title: '',
  },
  {
    field: 'remove',
    title: '',
  },
])

const Recipients = ({
  isFetching,
  onAddNewRecipients,
  onPageChange,
  onRemoveItemClick,
  onResendClick,
  onSearchChange,
  onSortChange,
  page,
  pageCount,
  sortField,
  sortOrder,
  tableData,
  totalResults,
}) => {
  const tableFormattedData = getTableData({ onRemoveItemClick, onResendClick, tableData })

  const renderContent = () => {
    if (isFetching && 1 === page) {
      return <Spinner />
    }

    return (
      <InfiniteScroll
        dataLength={tableData ? tableData.length : 0}
        hasMore={page < pageCount}
        next={() => onPageChange(+page + 1)}
      >
        <Table
          columns={renderColumns(totalResults)}
          data={tableFormattedData}
          sortField={sortField}
          sortOrder={sortOrder}
          visualType="flat"
          onSortChange={onSortChange}
        />
      </InfiniteScroll>
    )
  }

  return (
    <div>
      <Toolbar>
        <Toolbar.Group />
        <Toolbar.Group>
          <Toolbar.Item>
            <Button.ActionButton
              label={i18n.t('module:Newsletters:Preview:Recipients:addButtonLabel')}
              onClick={onAddNewRecipients}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      <SearchBar
        placeholder={i18n.t('module:Newsletters:Preview:Recipients:searchBarPlaceholder')}
        variant="standard"
        noBackground
        onChange={onSearchChange}
      />
      {renderContent()}
    </div>
  )
}

export default Recipients
