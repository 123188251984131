import React from 'react'

import { ModalBox } from 'components'

import i18n from 'translations'

import EnterStaffRegisterModalForm from './components/EnterStaffRegisterModalForm'

const EnterStaffRegisterModalView = ({
  onCancelClick,
  onCloseClick,
  onSubmit,
}) => (
  <ModalBox
    maxWidth={400}
    title={i18n.t('module:Modals:EnterStaffRegisterModal:title')}
    autoHeight
    onCloseClick={onCloseClick}
  >
    <EnterStaffRegisterModalForm
      onCancelClick={onCancelClick}
      onSubmit={onSubmit}
    />
  </ModalBox>
)

export default EnterStaffRegisterModalView
