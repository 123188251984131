import React from 'react'
import { WrappedFieldProps } from 'redux-form'

import { TextEditorFieldProps } from 'components/TextEditorField/TextEditorField'

import { TextEditorField } from 'components'

type FormTextEditorFieldFullProps = TextEditorFieldProps & WrappedFieldProps

const FormTextEditorField: React.FC<FormTextEditorFieldFullProps> = ({
  input: { onChange, value },
  meta: { error, submitFailed, valid },
  ...rest
}) => (
  <TextEditorField
    {...rest}
    error={submitFailed && error}
    valid={!(submitFailed && !valid)}
    value={value}
    onChange={onChange}
  />
)

export default FormTextEditorField
