import {
  CLEAR_INVOICE_PAYMENT_SINGLE,
  CREATE_INVOICE_PAYMENT,
  CREATE_INVOICE_PAYMENT_FAILED,
  CREATE_INVOICE_PAYMENT_SUCCESS,
  GET_INVOICE_PAYMENT,
  GET_INVOICE_PAYMENT_FAILED,
  GET_INVOICE_PAYMENT_SUCCESS,
  UPDATE_INVOICE_PAYMENT,
  UPDATE_INVOICE_PAYMENT_FAILED,
  UPDATE_INVOICE_PAYMENT_SUCCESS,
} from './actions'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case GET_INVOICE_PAYMENT:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_INVOICE_PAYMENT_SUCCESS:
      return { ...state, data: payload.data, isFetching: false }
    case GET_INVOICE_PAYMENT_FAILED:
      return { ...state, error, isFetching: false }
    case CREATE_INVOICE_PAYMENT:
      return { ...state, error: initialState.error, isSubmitting: true }
    case CREATE_INVOICE_PAYMENT_SUCCESS:
    case CREATE_INVOICE_PAYMENT_FAILED:
      return { ...state, error, isSubmitting: false }
    case UPDATE_INVOICE_PAYMENT:
      return { ...state, error: initialState.error, isSubmitting: true }
    case UPDATE_INVOICE_PAYMENT_SUCCESS:
    case UPDATE_INVOICE_PAYMENT_FAILED:
      return { ...state, error, isSubmitting: false }
    case CLEAR_INVOICE_PAYMENT_SINGLE:
      return initialState
    default:
      return state
  }
}
