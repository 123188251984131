import { UPDATE_CHILDREN_SESSION, UPDATE_CHILDREN_SESSION_FAILED, UPDATE_CHILDREN_SESSION_SUCCESS } from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false,
}

export default (state = initialState, { error, type }) => {
  switch (type) {
    case UPDATE_CHILDREN_SESSION:
      return { ...state, error: initialState.error, isSubmitting: true }
    case UPDATE_CHILDREN_SESSION_SUCCESS:
    case UPDATE_CHILDREN_SESSION_FAILED:
      return { ...state, error, isSubmitting: false }
    default:
      return state
  }
}
