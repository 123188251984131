import React, { PropsWithChildren } from 'react'
import { Link } from 'react-router'

import { StyledItem } from './PageTabNavStyled'

interface PageTabNavItemProps {
  isActive?: boolean
  label: string
  to: string
}

const PageTabNavItem: React.FC<PropsWithChildren<PageTabNavItemProps>> = ({ isActive, label, to }) => (
  <StyledItem $isActive={isActive}>
    <Link to={to}>
      {label}
    </Link>
  </StyledItem>
)

export default PageTabNavItem
