export const GET_DEVICE_TOKEN = 'GET_DEVICE_TOKEN'
export const GET_DEVICE_TOKEN_SUCCESS = 'GET_DEVICE_TOKEN_SUCCESS'
export const GET_DEVICE_TOKEN_FAILED = 'GET_DEVICE_TOKEN_FAILED'

export const CREATE_DEVICE_TOKENS = 'CREATE_DEVICE_TOKENS'
export const CREATE_DEVICE_TOKENS_SUCCESS = 'CREATE_DEVICE_TOKENS_SUCCESS'
export const CREATE_DEVICE_TOKENS_FAILED = 'CREATE_DEVICE_TOKENS_FAILED'

export const INSTALL_DEVICE_TOKEN = 'INSTALL_DEVICE_TOKEN'
export const INSTALL_DEVICE_TOKEN_SUCCESS = 'INSTALL_DEVICE_TOKEN_SUCCESS'
export const INSTALL_DEVICE_TOKEN_FAILED = 'INSTALL_DEVICE_TOKEN_FAILED'

export const UPDATE_DEVICE_TOKENS = 'UPDATE_DEVICE_TOKENS'
export const UPDATE_DEVICE_TOKENS_SUCCESS = 'UPDATE_DEVICE_TOKENS_SUCCESS'
export const UPDATE_DEVICE_TOKENS_FAILED = 'UPDATE_DEVICE_TOKENS_FAILED'

export const REMOVE_DEVICE_TOKENS = 'REMOVE_DEVICE_TOKENS'
export const REMOVE_DEVICE_TOKENS_SUCCESS = 'REMOVE_DEVICE_TOKENS_SUCCESS'
export const REMOVE_DEVICE_TOKENS_FAILED = 'REMOVE_DEVICE_TOKENS_FAILED'

export const CLEAR_SINGLE_DEVICE_TOKENS = 'CLEAR_SINGLE_DEVICE_TOKENS'
