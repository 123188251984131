import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class MembershipsApiClient extends ServiceBase {
  confirmGdpr = (params = {}) => this.request({
    method: 'PUT',
    path: `/v2/me/gdpr?${getQueryStringV2(params)}`,
  })

  create = (payload = {}) => this.request({
    body: payload,
    method: 'POST',
    path: '/v2/memberships',
  })

  get = (id, params = {}) => this.request({
    path: `/v2/memberships/${id}?${getQueryStringV2(params)}`,
  })

  getMe = (params = {}) => this.request({
    path: `/v2/me?${getQueryStringV2(params)}`,
  })

  list = (params = {}) => this.request({
    path: `/v2/memberships?${getQueryStringV2(params)}`,
  })

  listOrganizationMemberships = (params = {}) => this.request({
    path: `/v2/organization-memberships?${getQueryStringV2(params)}`,
  })

  resendInvitation = (id) => this.request({
    method: 'PUT',
    path: `/v2/memberships/${id}/resend-invitation`,
  })

  sendReport = (moduleType, type, params = {}) => this.request({
    path: `/v2/memberships/${moduleType}/report/${type}?${getQueryStringV2(params)}`,
  })

  exportStaffList = (params: any = {}) => this.request({
    headers: params.headers || {
      Accept: 'text/csv',
    },
    path: `/v2/memberships/report?${getQueryStringV2(params)}`,
  })

  update = (id, payload = {}, params = {}) => this.request({
    body: payload,
    method: 'PUT',
    path: `/v2/memberships/${id}?${getQueryStringV2(params)}`,
  })

  updateMe = (payload = {}, params = {}) => this.request({
    body: payload,
    method: 'PUT',
    path: `/v2/me?${getQueryStringV2(params)}`,
  })

  updatePassword = (payload = {}) => this.request({
    body: payload,
    method: 'PUT',
    path: '/v2/me',
  })

  listMembershipFiles = (id, params) => this.request({
    path: `/v2/memberships/${id}/files?${getQueryStringV2(params)}`,
  })
}

export default MembershipsApiClient
