import {
  ARCHIVE_CHILD_ADHOC_SESSION,
  ARCHIVE_CHILD_ADHOC_SESSION_FAILED,
  ARCHIVE_CHILD_ADHOC_SESSION_SUCCESS,
  CLEAR_CHILD_ADHOC_SESSION,
  CREATE_BULK_CHILD_ADHOC_SESSION,
  CREATE_BULK_CHILD_ADHOC_SESSION_FAILED,
  CREATE_BULK_CHILD_ADHOC_SESSION_SUCCESS,
  CREATE_CHILD_ADHOC_SESSION,
  CREATE_CHILD_ADHOC_SESSION_FAILED,
  CREATE_CHILD_ADHOC_SESSION_SUCCESS,
  GET_CHILD_ADHOC_SESSION,
  GET_CHILD_ADHOC_SESSION_FAILED,
  GET_CHILD_ADHOC_SESSION_SUCCESS,
  UPDATE_CHILD_ADHOC_SESSION,
  UPDATE_CHILD_ADHOC_SESSION_FAILED,
  UPDATE_CHILD_ADHOC_SESSION_SUCCESS,
} from './actions'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case GET_CHILD_ADHOC_SESSION:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_CHILD_ADHOC_SESSION_SUCCESS:
      return { ...state, data: payload.data, isFetching: false }
    case GET_CHILD_ADHOC_SESSION_FAILED:
      return { ...state, error, isFetching: false }
    case CREATE_BULK_CHILD_ADHOC_SESSION:
    case CREATE_CHILD_ADHOC_SESSION:
      return { ...state, error: initialState.error, isSubmitting: true }
    case CREATE_BULK_CHILD_ADHOC_SESSION_SUCCESS:
    case CREATE_BULK_CHILD_ADHOC_SESSION_FAILED:
    case CREATE_CHILD_ADHOC_SESSION_SUCCESS:
    case CREATE_CHILD_ADHOC_SESSION_FAILED:
      return { ...state, error, isSubmitting: false }
    case UPDATE_CHILD_ADHOC_SESSION:
    case ARCHIVE_CHILD_ADHOC_SESSION:
      return { ...state, error: initialState.error, isSubmitting: true }
    case UPDATE_CHILD_ADHOC_SESSION_SUCCESS:
    case UPDATE_CHILD_ADHOC_SESSION_FAILED:
    case ARCHIVE_CHILD_ADHOC_SESSION_SUCCESS:
    case ARCHIVE_CHILD_ADHOC_SESSION_FAILED:
      return { ...state, error, isSubmitting: false }
    case CLEAR_CHILD_ADHOC_SESSION:
      return initialState
    default:
      return state
  }
}
