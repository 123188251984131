import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

const getFinanceReports = (state) => state.financeRevenuePerRoomReport

export const getFinanceRevenuePerRoomReportChildren = createSelector(
  [getFinanceReports],
  (state) => state.children,
)

export const getFinanceRevenuePerRoomReportChildrenData = createSelector(
  [getFinanceRevenuePerRoomReportChildren],
  (state) => state.data,
)

export const getCriteria = ({ dateRange }) => {
  const criteria = []

  if (dateRange?.after && dateRange?.before) {
    criteria.push({
      field: 'startDate',
      value: moment(dateRange.after).format(DEFAULT_DATE_FORMAT),
    })

    criteria.push({
      field: 'endDate',
      value: moment(dateRange.before).format(DEFAULT_DATE_FORMAT),
    })
  }

  return criteria
}

