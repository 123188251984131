import { REMOVE_NURSERY_FILE, REMOVE_NURSERY_FILE_FAILED, REMOVE_NURSERY_FILE_SUCCESS } from './constants'

const initialState = {
  isFetching: false,
}

export default (state = initialState, { type }) => {
  switch (type) {
    case REMOVE_NURSERY_FILE:
      return { ...state, isFetching: true }
    case REMOVE_NURSERY_FILE_FAILED:
    case REMOVE_NURSERY_FILE_SUCCESS:
      return { ...state, isFetching: false }
    default:
      return state
  }
}
