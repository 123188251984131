import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

interface StyledRowProps {
  header?: boolean
}

export const StyledRow = styled.div<StyledRowProps>`
  padding: 10px 15px;
  background: #F5F6F6;
  
  ${({ $header }) => $header && `
    background: ${getBrandingColor('primary-color')};
    color: ${NEUTRAL_COLOURS.WHITE};
  `}
`

export const StyledDividedList = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;

  ${StyledRow}:nth-child(2n) {
    background: #EDEDED;
  }
`
