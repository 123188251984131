import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_EXCEPTIONAL_UNAVAILABLE_TIME,
  CREATE_EXCEPTIONAL_UNAVAILABLE_TIME,
  CREATE_EXCEPTIONAL_UNAVAILABLE_TIME_FAILED,
  CREATE_EXCEPTIONAL_UNAVAILABLE_TIME_SUCCESS,
  DELETE_EXCEPTIONAL_UNAVAILABLE_TIME,
  DELETE_EXCEPTIONAL_UNAVAILABLE_TIME_FAILED,
  DELETE_EXCEPTIONAL_UNAVAILABLE_TIME_SUCCESS,
  GET_EXCEPTIONAL_UNAVAILABLE_TIME,
  GET_EXCEPTIONAL_UNAVAILABLE_TIME_FAILED,
  GET_EXCEPTIONAL_UNAVAILABLE_TIME_SUCCESS,
  UPDATE_EXCEPTIONAL_UNAVAILABLE_TIME,
  UPDATE_EXCEPTIONAL_UNAVAILABLE_TIME_FAILED,
  UPDATE_EXCEPTIONAL_UNAVAILABLE_TIME_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_EXCEPTIONAL_UNAVAILABLE_TIME,
    createFailed: CREATE_EXCEPTIONAL_UNAVAILABLE_TIME_FAILED,
    createInit: CREATE_EXCEPTIONAL_UNAVAILABLE_TIME,
    createSuccess: CREATE_EXCEPTIONAL_UNAVAILABLE_TIME_SUCCESS,
    itemFailed: GET_EXCEPTIONAL_UNAVAILABLE_TIME_FAILED,
    itemInit: GET_EXCEPTIONAL_UNAVAILABLE_TIME,
    itemSuccess: GET_EXCEPTIONAL_UNAVAILABLE_TIME_SUCCESS,
    removeFailed: DELETE_EXCEPTIONAL_UNAVAILABLE_TIME_FAILED,
    removeInit: DELETE_EXCEPTIONAL_UNAVAILABLE_TIME,
    removeSuccess: DELETE_EXCEPTIONAL_UNAVAILABLE_TIME_SUCCESS,
    updateFailed: UPDATE_EXCEPTIONAL_UNAVAILABLE_TIME_FAILED,
    updateInit: UPDATE_EXCEPTIONAL_UNAVAILABLE_TIME,
    updateSuccess: UPDATE_EXCEPTIONAL_UNAVAILABLE_TIME_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
