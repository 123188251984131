import React from 'react'

import { Banner } from 'components'

import i18n from 'translations'

const BottomBanners = ({
  archivedAt,
  childName,
  isMessagingEnabled,
  isNewChannelPage,
  numberOfParents,
  unsubscribedAt,
}) => {
  if (isNewChannelPage) {
    return null
  }

  const getCopy = () => {
    if (unsubscribedAt) {
      return i18n.t('module:Messaging:MessagingList:channelLeave')
    }

    if (archivedAt) {
      return i18n.t('module:Messaging:MessagingList:archivedChild', { child: childName })
    }

    if (!numberOfParents) {
      return i18n.t('module:Messaging:MessagingList:noParentAccess', { child: childName })
    }

    if (!isMessagingEnabled) {
      return i18n.t('module:Messaging:MessagingList:messagingDisabled')
    }

    return null
  }

  return (
    <Banner.Warning
      margin="10px"
      noBottomMargin
      transparent
    >
      {getCopy()}
    </Banner.Warning>
  )
}

export default React.memo(BottomBanners)
