import { combineReducers } from 'redux'

import children from './children/reducer'
import nurseries from './nurseries/reducer'
import statistics from './statistics/reducer'

export default combineReducers({
  children,
  nurseries,
  statistics,
})
