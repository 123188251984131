export const GET_PERIOD = 'PERIODS/GET_PERIOD'
export const GET_PERIOD_FAILED = 'PERIODS/GET_PERIOD_FAILED'
export const GET_PERIOD_SUCCESS = 'PERIODS/GET_PERIOD_SUCCESS'

export const CREATE_PERIOD = 'PERIODS/CREATE_PERIOD'
export const CREATE_PERIOD_FAILED = 'PERIODS/CREATE_PERIOD_FAILED'
export const CREATE_PERIOD_SUCCESS = 'PERIODS/CREATE_PERIOD_SUCCESS'

export const UPDATE_PERIOD = 'PERIODS/UPDATE_PERIOD'
export const UPDATE_PERIOD_FAILED = 'PERIODS/UPDATE_PERIOD_FAILED'
export const UPDATE_PERIOD_SUCCESS = 'PERIODS/UPDATE_PERIOD_SUCCESS'

export const REMOVE_PERIOD = 'PERIODS/REMOVE_PERIOD'
export const REMOVE_PERIOD_FAILED = 'PERIODS/REMOVE_PERIOD_FAILED'
export const REMOVE_PERIOD_SUCCESS = 'PERIODS/REMOVE_PERIOD_SUCCESS'

export const CLEAR_PERIOD = 'PERIODS/CLEAR_PERIOD'
