import React from 'react'
import { connect } from 'react-redux'

import actions from './actions'
import selectors from './selectors'

const withOrganizationSessionsService = (WrappedComponent) => {
  const mapState = ({ organizationSessions }) => ({ organizationSessions })

  const mapDispatch = {
    archiveSession: actions.archiveSession,
    clearSession: actions.clearSession,
    createSession: actions.createSession,
    getSession: actions.getSession,
    listSessions: actions.listSessions,
    updateSession: actions.updateSession,
  }

  const Component = (props) => {
    const {
      archiveSession,
      clearSession,
      createSession,
      getSession,
      listSessions,
      organizationSessions,
      updateSession,
      ...other
    } = props

    const organizationSessionsListState = {
      ...organizationSessions.list,
      isEmpty: organizationSessions.list.data && 0 === organizationSessions.list.data.length,
    }
    const organizationSessionsSingleState = organizationSessions.single
    const organizationSessionsActions = {
      archiveSession,
      clearSession,
      createSession,
      getSession,
      listSessions,
      updateSession,
    }
    const organizationSessionsSelectors = selectors

    return (
      <WrappedComponent
        organizationSessionsActions={organizationSessionsActions}
        organizationSessionsListState={organizationSessionsListState}
        organizationSessionsSelectors={organizationSessionsSelectors}
        organizationSessionsSingleState={organizationSessionsSingleState}
        {...other}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withOrganizationSessionsService
