import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withNurseryDiscountsService } from 'services/legacy/nurseryDiscounts'
import { NURSERY_DISCOUNTS_FILTER } from 'services/product/nurseryDiscountsV3/constants'

import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withSortingUtils } from 'services/utils/sorting'

import ManagementDiscountsV2View from './ManagementDiscountsV2View'
import { getTableData } from './ManagementDiscountsV2Helpers'

class ManagementDiscountsV2Container extends Component {
  constructor(props) {
    super(props)

    const { statusFilter } = props.location.query

    this.state = {
      statusFilter: statusFilter || NURSERY_DISCOUNTS_FILTER.ACTIVE,
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { nurseryDiscountsActions } = this.props

    nurseryDiscountsActions.clearList()
  }

  fetch = () => {
    const {
      nurseryDiscountsActions,
      nurseryDiscountsSelectors,
      paginationUtils,
      setLocationQuery,
      sortingUtils,
    } = this.props
    const { statusFilter } = this.state
    const { page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const criteria = nurseryDiscountsSelectors.getCriteriaSelector({ statusFilter })
    const apiParams = {
      criteria,
      order: { sortField, sortOrder },
      page,
    }

    setLocationQuery({ statusFilter })
    nurseryDiscountsActions.list({ params: apiParams })
  }

  handleStatusFilterChange = (statusFilter) => {
    this.setState({
      statusFilter: statusFilter ? statusFilter.value : null,
    }, this.fetch)
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleSortChange = (key) => {
    const { paginationUtils, sortingUtils } = this.props
    const { onSortChange } = sortingUtils
    const { onPageChange } = paginationUtils

    onSortChange(onPageChange(this.fetch)(1))(key)
  }

  render() {
    const {
      errorMessages,
      isEmpty,
      isFetching,
      nurseryDiscountsListState,
      nurseryOptions,
      paginationUtils,
      sortingUtils,
    } = this.props
    const { statusFilter } = this.state
    const { getPageCount, page, perPage } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const routePrefix = nurseryOptions.prefix
    const discountsTableData = getTableData(nurseryDiscountsListState.data)
    const pageCount = getPageCount(nurseryDiscountsListState.meta.total_results)

    return (
      <ManagementDiscountsV2View
        discounts={discountsTableData}
        errorMessages={errorMessages}
        isEmpty={isEmpty}
        isLoading={isFetching}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        routePrefix={routePrefix}
        sortField={sortField}
        sortOrder={sortOrder}
        statusFilter={statusFilter}
        totalResults={nurseryDiscountsListState.meta.total_results}
        onPageChange={this.handlePageChange}
        onSortChange={this.handleSortChange}
        onStatusFilterChange={this.handleStatusFilterChange}
      />
    )
  }
}

const mapState = (state, { appSelectors, nurseryDiscountsListState, nurseryDiscountsSelectors, params }) => ({
  errorMessages: appSelectors.getErrorMessages(nurseryDiscountsListState),
  isEmpty: nurseryDiscountsSelectors.isNurseryDiscountsEmpty(state),
  isFetching: appSelectors.getIsFetching(nurseryDiscountsListState),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const enhance = compose(
  withAppService,
  withNurseryDiscountsService,
  withPaginationUtils,
  withRouterUtils,
  withSortingUtils,
  connect(mapState),
)

export default enhance(ManagementDiscountsV2Container)

