import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'recompose'

import { Form, Section, Toolbar } from 'components'

import { isRequired } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'

import i18n from 'translations'

const GlobalInvoiceNumberSettingsAddForm = ({
  globalNumberSettingsOptions,
  handleSubmit,
  isLoading,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Section title="Use global settings">
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            How would you like to apply sequencing of invoice, receipt and credit note numbers?
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      <Form.Row>
        <Form.Row.FlexItem>
          <Field
            component={Form.RadioGroup}
            name="invoiceNumbersSequencingType"
            options={globalNumberSettingsOptions}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
    </Section>
    <Form.FooterActions
      cancelLink={generateRoute('SETTINGS.INVOICE_NUMBERS')}
      isSubmitting={isLoading}
      submitLabel={i18n.t('global:Save')}
    />
  </Form>
)

const enhance = compose(reduxForm({ form: 'GlobalInvoiceNumberSettingsAddForm' }))

export default enhance(GlobalInvoiceNumberSettingsAddForm)
