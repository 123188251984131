import { IntegrationFeeModel, STRIPE_INTEGRATION_TYPE } from 'services/integrationFees/constants'

export const getFinalBody = (params, nurseryId) => {
  const { extraAmount, maxAmount, minAmount, paymentType, percentage } = params
  const body = {
    nursery: {
      id: nurseryId,
    },
    type: STRIPE_INTEGRATION_TYPE,
  } as any

  if (extraAmount) {
    body.extraAmount = +extraAmount
  }

  if (maxAmount) {
    body.maxAmount = +maxAmount
  }

  if (minAmount) {
    body.minAmount = +minAmount
  }

  if (percentage) {
    body.percentage = +percentage
  }

  if (paymentType?.value) {
    body.paymentType = paymentType.value
  }

  return body
}

export const getInitialValues = (fee: IntegrationFeeModel) => {
  const { extraAmount, maxAmount, minAmount, paymentType, percentage } = fee
  const values = {} as any

  if (paymentType) {
    values.paymentType = paymentType
  }

  if (maxAmount) {
    values.maxAmount = maxAmount
  }

  if (minAmount) {
    values.minAmount = minAmount
  }

  if (percentage) {
    values.percentage = percentage
  }

  if (extraAmount) {
    values.extraAmount = extraAmount
  }

  return values
}
