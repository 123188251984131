import { flatten } from 'utils/flatnest'
import moment from 'moment'

import { SELECT_CHILDREN_TYPES } from './constants'

const DATE_FORMAT = 'YYYY-MM-DD'

export const getQueryString = (fields, selectedChildren) => {
  const { dueDate, invoiceName, invoicePeriod, issueDate, selectChildren } = fields

  const [startDate, endDate] = invoicePeriod

  return flatten({
    children: SELECT_CHILDREN_TYPES.GROUP === selectChildren ? selectedChildren.join(',') : '',
    dueDate: moment(dueDate).format(DATE_FORMAT),
    issueDate: moment(issueDate).format(DATE_FORMAT),
    name: invoiceName,
    period: {
      after: moment(startDate).format(DATE_FORMAT),
      before: moment(endDate).format(DATE_FORMAT),
    },
  })
}

