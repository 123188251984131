import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

const ITEM_BACK_GROUND_COLOR = '#F7F7F7'
const WHITE = '#fff'

export const StyledItemGroup = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 250px;
  padding: 10px 15px;
  background: ${({ isSelected }) => (isSelected ? getBrandingColor('primary-color-rgba-1') : ITEM_BACK_GROUND_COLOR)};
  border-radius: 20px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  border: 2px solid ${({ isSelected }) => (
    isSelected
      ? getBrandingColor('primary-color')
      : ITEM_BACK_GROUND_COLOR
  )};

  & ${StyledItemGroup} ~ ${StyledItemGroup} {
    padding-top: 10px;
  }
`

export const StyledContainer = styled.div`
  display: flex;
  overflow: auto;
  
  & ${StyledItemContainer} ~ ${StyledItemContainer} {
    margin-left: 15px;
  }
`

export const StyledTitleContainer = styled.div`
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  ${({ isSelected }) => (isSelected && `
    color: var(--primary-color);
  `)};
`

export const StyledContentContainer = styled.div`
  color: ${getBrandingColor('primary-color')};
`

export const StyledTag = styled.div`
  color: ${({ color = WHITE }) => color};
  padding: 0 10px;
  background-color: ${({ backgroundColor = getBrandingColor('primary-color') }) => backgroundColor};
  border-radius: 9px;
  height: 18px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
`
