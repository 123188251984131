import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import {
  Button,
  DraggableList,
  EmptyState,
  GridLayout,
  Icon,
  InfiniteScroll,
  Page,
  Section,
  Spinner,
  Tooltip,
} from 'components'

import i18n from 'translations'

import {
  StyledContainer,
  StyledContentWrapper,
  StyledDragg,
  StyledDraggableBox,
  StyledDraggableBoxContainer,
  StyledHeader,
} from './PipelineViewStyled'

const PipelineView = ({
  disableDragging,
  droppableId,
  enquiryStatusTypes,
  isLoading,
  onItemDragged,
  onPageChange,
  page,
  pageCount,
  type,
}) => {
  const actions = (
    <Section.Actions
      primary={[{
        label: i18n.t('global:Create'),
        to: generateRoute('MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_PIPELINE.ADD', { type }),
      }]}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!isLoading && !enquiryStatusTypes?.length) {
      return (
        <EmptyState
          icon="enquiries"
          text1={i18n.t('module:Enquiries:List:emptyState')}
        />
      )
    }

    return (
      <InfiniteScroll
        dataLength={enquiryStatusTypes?.length || 0}
        hasMore={page < pageCount}
        next={() => onPageChange((+page) + 1)}
      >
        <StyledContainer>
          <GridLayout>
            <GridLayout.Group noVerticalMargin>
              <GridLayout.Item>
                <StyledContentWrapper>
                  <StyledHeader>
                    <span />
                    <span>{i18n.t('module:Management:Enquiries:EnquiryPipeline:Table:Header:order')}</span>
                    <span>{i18n.t('module:Management:Enquiries:EnquiryPipeline:Table:Header:stage')}</span>
                    <span />
                  </StyledHeader>
                  <DraggableList
                    disabled={disableDragging}
                    droppableId={droppableId}
                    itemComponent={(item) => (
                      <StyledDraggableBoxContainer>
                        <StyledDraggableBox
                          key={item.id}
                        >
                          <StyledDragg>
                            <Icon
                              color={NEUTRAL_COLOURS.GRAY}
                              height={18}
                              icon="menu"
                            />
                          </StyledDragg>
                          <span>
                            {item.order + 1}
                          </span>
                          <span>
                            {item.name}
                          </span>
                          <Tooltip
                            placement="left"
                            title={!item.editable
                              ? i18n.t('module:Management:Enquiries:EnquiryPipeline:tooltip')
                              : ''}
                            inline
                          >
                            <Button.TableAction
                              color={!item.editable && 'gray'}
                              disabled={!item.editable}
                              icon={item.editable ? 'chevron-right' : 'padlock'}
                              to={generateRoute('MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_PIPELINE.EDIT', {
                                enquiryStatusTypeId: item.id,
                              })}
                            />
                          </Tooltip>
                        </StyledDraggableBox>
                      </StyledDraggableBoxContainer>
                    )}
                    items={enquiryStatusTypes}
                    onChange={onItemDragged}
                  />
                </StyledContentWrapper>
              </GridLayout.Item>
              <GridLayout.Item />
            </GridLayout.Group>
          </GridLayout>
        </StyledContainer>
      </InfiniteScroll>
    )
  }

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title={i18n.t('module:Management:Enquiries:EnquiryPipeline:title')}
    >
      {renderContent()}
    </Page.Section>
  )
}

export default PipelineView
