import _ from 'lodash'

import { ALLERGY_OPTIONS, ALLERGY_TYPES } from 'services/legacy/child/constants'
import colors from 'constants/colors'

export const getAllergyTypeLabel = (allergyType) => _.get(_.find(
  ALLERGY_OPTIONS,
  (option) => option.value === allergyType,
), 'label')

export const getAllergyType = (allergyType, allergiesDetails) => {
  if (!allergyType && !allergiesDetails) {
    return getAllergyTypeLabel(ALLERGY_TYPES.NOT_SPECIFIED)
  }

  if (ALLERGY_TYPES.HAS_ALLERGY === allergyType) {
    return allergiesDetails
  }

  return getAllergyTypeLabel(allergyType)
}

export const getAllergyTypeLabelColor = (allergyType) => (
  ALLERGY_TYPES.NO_ALLERGY === allergyType ? colors.gray : colors.remove
)

