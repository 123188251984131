import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withChildExtraItemsService } from 'services/legacy/childExtraItems'

import { Select } from 'components'

import i18n from 'translations'

import { CHILD_EXTRA_ITEMS_TYPE_OPTIONS } from 'services/legacy/childExtraItems/constants'
import { toFloat, toInt } from 'utils/data'
import moment from 'moment'
import withInfiniteDropdownHelpers from './withInfiniteDropdown'

const GROUPS = {
  read: [
    'childExtraItemProjection.childExtraItem',
    'childExtraItem',
    'childExtraItem.extraItem',
    'extraItem',
  ],
}

const CHILD_EXTRA_ITEM_PROJECTIONS_SORT = {
  sortField: 'childExtraItem.extraItem.name',
  sortOrder: 'DESC',
}

class ChildExtraItemsDropdown extends Component {
  componentDidMount() {
    const { extraItemsActions, infiniteDropdownHelpers, value } = this.props

    if (value?.value && value?.label) {
      return null
    }

    if (!_.isArray(value)) {
      return infiniteDropdownHelpers.handleComponentDidMount({
        action: () => extraItemsActions.get({
          addToList: true,
          onFailed: this.notFoundValue,
          onSuccess: infiniteDropdownHelpers.handleComponentDidMountOnSuccess,
          params: [(value?.value || value)],
        }),
        value,
      })
    }

    return null
  }

  componentWillUnmount() {
    const { childExtraItemsActions } = this.props

    childExtraItemsActions.clearProjections()
  }

  handleLoadMoreElements = async (phrase) => {
    try {
      const {
        childExtraItemsActions,
        childExtraItemsSelectors,
        childId,
        extraFields,
        infiniteDropdownHelpers,
        limit,
      } = this.props

      return infiniteDropdownHelpers.handleLoadMoreElements({
        clearAction: childExtraItemsActions.clearProjections,
        listAction: ({ newPage, newSearchPhrase, result }) => childExtraItemsActions.listProjections({
          onSuccess: ({ data, meta }) => {
            const newData = _.map(data, (item) => {
              const { childExtraItem, date, id } = item
              const { extraItem, quantity, type: frequency } = childExtraItem || {}
              const { archived, name, unitPrice } = extraItem || {}
              const total = toInt(quantity || 1) * toFloat(unitPrice)
              const frequencyOption = _.find(CHILD_EXTRA_ITEMS_TYPE_OPTIONS, { value: frequency })

              return {
                frequency: frequencyOption,
                id,
                name: `${name}${archived ? ' (archived)' : ''} - ${moment(date).format('DD/MM/YYYY')}`,
                quantity: quantity || 1,
                total,
                unitPrice,
              }
            })

            return infiniteDropdownHelpers.handleLoadMoreElementsOnSuccess({
              extraFields,
              newPage,
              newSearchPhrase,
              response: { data: newData, meta },
              result,
            })
          },
          onlyData: true,
          params: {
            criteria: childExtraItemsSelectors.getProjectionCriteriaSelector({
              childExtraItem: { child: childId },
              invoiceItem: false,
              search: newSearchPhrase,
            }),
            groups: GROUPS,
            limit,
            order: CHILD_EXTRA_ITEM_PROJECTIONS_SORT,
            page: newPage,
          },
          silent: true,
        }),
        phrase,
      })
    } catch (err) {
      throw new Error(err)
    }
  }

  notFoundValue = () => {
    const { onChange } = this.props

    onChange(null)
  }

  handleChange = (e) => {
    const { infiniteDropdownHelpers, onChange } = this.props

    infiniteDropdownHelpers.handleOnChange(e, () => onChange(e))
  }

  render() {
    const {
      disabled,
      infiniteDropdownState,
      isFetching,
      placeholder,
      rawValue,
      value,
      ...rest
    } = this.props
    const { cacheUniq, options, selectedValue } = infiniteDropdownState

    return (
      <Select
        {...rest}
        cacheUniq={cacheUniq}
        disabled={disabled || isFetching}
        loadOptions={this.handleLoadMoreElements}
        options={options}
        placeholder={placeholder || i18n.t('global:KeyPerson')}
        rawValue={rawValue || selectedValue || value}
        value={value}
        searchable
        onChange={this.handleChange}
      />
    )
  }
}

const mapState = (state, { appSelectors, childExtraItemsSingleState }) => ({
  isFetching: appSelectors.getIsFetching(childExtraItemsSingleState),
})

const enhance = compose(
  withAppService,
  withChildExtraItemsService,
  withInfiniteDropdownHelpers,
  connect(mapState),
)

export default enhance(ChildExtraItemsDropdown)
