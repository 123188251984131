export const TRANSACTIONS_TYPES = {
  BALANCE_ADJUSTMENT: 'BALANCE_ADJUSTMENT',
  CREDIT_NOTE: 'CREDIT_NOTE',
  INVOICE: 'INVOICE',
  PAYMENT: 'PAYMENT',
}

export const ACTIONS_TYPES = {
  DELETE: 'DELETE',
}
