import React from 'react'

import { generateRoute } from 'utils/routing'

import { FolderList, Page } from 'components'

import i18n from 'translations'

const ManagementLearningView = () => (
  <Page.Section title={i18n.t('module:Management:Learning:Title:long')}>
    <FolderList>
      <FolderList.Item
        icon="framework"
        title={i18n.t('module:Management:Learning:Curriculums:title')}
        to={generateRoute('MANAGEMENT.LEARNING.CURRICULUMS')}
      />
      <FolderList.Item
        icon="learning"
        title={i18n.t('module:Management:Learning:General:title')}
        to={generateRoute('MANAGEMENT.LEARNING.GENERAL')}
      />
    </FolderList>
  </Page.Section>
)

export default ManagementLearningView
