import _ from 'lodash'

import React from 'react'
import { Field, FieldArray, InjectedFormProps, reduxForm } from 'redux-form'

import { isMinLengthGreaterThan, isRequired, isRequiredDateRange, isValidDateRange } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'

import { Button, Callout, Form, Grid, Space, Typography } from 'components'

import i18n from 'translations'

import { AddViewProps } from '../AddView'

export const CLOSURE_DATE_SETTING_FORM = 'ClosureDateSettingForm'

const isMinLength = isMinLengthGreaterThan(3)

interface AddFormProps extends Omit<AddViewProps, 'onDeleteClick'> {}

type AddFormFullProps = InjectedFormProps<{}, AddFormProps> & AddFormProps

const renderItem = (fields, onDeleteItemClick) => (item, index) => (
  <Grid key={_.kebabCase(index)}>
    <Grid.Item width={{ desktop: '200px' }}>
      <Field component={Form.TextField} name={`${item}.id`} type="hidden" />
      <Field
        component={Form.TextField}
        name={`${item}.name`}
        placeholder={i18n.t('module:Management:ClosureDays:ClosureDates:Add:Form:Description:placeholder')}
        validate={[isRequired, isMinLength]}
      />
    </Grid.Item>
    <Grid.Item width={{ desktop: '250px' }}>
      <Field
        component={Form.DatePicker}
        name={`${item}.dateRange`}
        placeholder={i18n.t('module:Management:ClosureDays:ClosureDates:Add:Form:Dates:placeholder')}
        validate={[isRequiredDateRange, isValidDateRange]}
        range
      />
    </Grid.Item>
    <Grid.Item width={{ desktop: '220px' }}>
      <Field
        component={Form.InfiniteDropdowns.ClosureDayTypes}
        name={`${item}.closureDayType`}
        validate={isRequired}
      />
    </Grid.Item>
    <Grid.Item width={{ desktop: '50px' }}>
      <Space margin="5px 0 0" />
      <Form.RemoveRowButton onClick={() => onDeleteItemClick(fields, index)} />
    </Grid.Item>
    <Grid.Item flex={{ desktop: 'auto' }} />
  </Grid>
)

const renderHeader = (label) => (
  <Typography fontSize={14}>
    {label}
  </Typography>
)

const renderDates = ({ fields, onDeleteItemClick }) => (
  <React.Fragment>
    {fields.length ? (
      <Grid>
        <Grid.Item width={{ desktop: '200px' }}>
          {renderHeader(i18n.t('module:Management:ClosureDays:ClosureDates:Add:Form:Description:label'))}
        </Grid.Item>
        <Grid.Item width={{ desktop: '250px' }}>
          {renderHeader(i18n.t('module:Management:ClosureDays:ClosureDates:Add:Form:Dates:label'))}
        </Grid.Item>
        <Grid.Item width={{ desktop: '220px' }}>
          {renderHeader(i18n.t('module:Management:ClosureDays:ClosureDates:Add:Form:ClosureDayType:label'))}
        </Grid.Item>
        <Grid.Item width={{ desktop: '50px' }} />
        <Grid.Item flex={{ desktop: 'auto' }} />
      </Grid>
    ) : null}
    {fields.map(renderItem(fields, onDeleteItemClick))}
    <Form.Row>
      <Form.Row.FlexItem flex="0 0 520px">
        <Button.ActionButton
          label={i18n.t('module:Management:ClosureDays:ClosureDates:Add:addDatesItemLabel')}
          onClick={() => fields.push({})}
        />
      </Form.Row.FlexItem>
    </Form.Row>
  </React.Fragment>
)

const AddForm: React.FC<AddFormFullProps> = ({
  academicYearOptions,
  error,
  handleSubmit,
  isEdit,
  isSubmitting,
  nurseryAcademicYearId,
  onDeleteItemClick,
  onSubmit,
  submitFailed,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    {submitFailed && <Callout content={error} error />}
    <Form.Row
      label={i18n.t('module:Management:ClosureDays:ClosureDates:Add:Form:AcademicYear:label')}
      margin="0 0 20px"
      width={{ field: '300px' }}
      verticalLabel
    >
      <Field
        component={Form.Select}
        disabled={isEdit}
        name="nurseryAcademicYear"
        options={academicYearOptions}
        validate={isRequired}
      />
    </Form.Row>
    <FieldArray
      component={renderDates}
      name="closurePeriods"
      onDeleteItemClick={onDeleteItemClick}
    />
    <Form.FooterActions
      cancelLink={
        isEdit
          ? generateRoute('MANAGEMENT.CLOSURE_DAYS.CLOSURE_DATES.PREVIEW', { nurseryAcademicYearId })
          : generateRoute('MANAGEMENT.CLOSURE_DAYS.CLOSURE_DATES')
      }
      isSubmitting={isSubmitting}
      submitLabel={i18n.t('global:Save')}
    />
  </Form>
)

export default reduxForm<{}, AddFormProps>({ form: CLOSURE_DATE_SETTING_FORM })(AddForm)
