import _ from 'lodash'

import React, { PropsWithChildren } from 'react'
import { compose } from 'recompose'

import { WithCurrencyUtilsProps, withCurrencyUtils } from 'services/utils/currency'

import { StyledContainer, StyledSmallText } from './CurrencyStyled'

interface CurrencyProps {
  bold?: boolean
  color?: string
  customCurrency?: string
  fontSize?: number
  highlight?: boolean
  oneSize?: boolean
  showDebitCredit?: boolean
  value: number
}

type CurrencyFullProps = CurrencyProps & WithCurrencyUtilsProps

const Currency: React.FC<PropsWithChildren<CurrencyFullProps>> = ({
  bold,
  color,
  currencyUtils,
  customCurrency,
  fontSize,
  highlight,
  oneSize,
  showDebitCredit,
  value = 0,
}) => {
  const renderContent = (numberValue: number) => {
    const parts = currencyUtils.getNumberParts(numberValue, customCurrency)

    return (
      <React.Fragment>
        {_.map(parts, ({ type, value: partValue }) => {
          if ('currency' === type) {
            if (oneSize) {
              return partValue
            }

            return (
              <StyledSmallText key={partValue}>
                {partValue}
              </StyledSmallText>
            )
          }

          if ('fraction' === type) {
            return oneSize ? partValue : (
              <StyledSmallText key="fraction">
                {partValue}
              </StyledSmallText>
            )
          }

          return partValue
        })}
      </React.Fragment>
    )
  }

  if (showDebitCredit) {
    const drCr = 0 > value ? '' : 'CR'

    return (
      <StyledContainer
        $bold={bold}
        $color={color}
        $fontSize={fontSize}
        $highlight={highlight}
      >
        {renderContent(Math.abs(value))}
        {drCr && ' '}
        {drCr}
      </StyledContainer>
    )
  }

  return (
    <StyledContainer
      $bold={bold}
      $color={color}
      $fontSize={fontSize}
      $highlight={highlight}
    >
      {renderContent(value)}
    </StyledContainer>
  )
}

const enhance = compose<{}, CurrencyProps>(
  withCurrencyUtils,
)

export default enhance(Currency)
