import _ from 'lodash'

import i18n from 'translations'

export const RATIO_VALUES = {
  STANDARD: 'standard',
}

export const RATIO_OPTIONS = [
  {
    label: '-',
    value: RATIO_VALUES.STANDARD,
  },
  {
    label: '1:13',
    value: 13,
  },
  {
    label: '1:12',
    value: 12,
  },
  {
    label: '1:11',
    value: 11,
  },
  {
    label: '1:10',
    value: 10,
  },
  {
    label: '1:9',
    value: 9,
  },
  {
    label: '1:8',
    value: 8,
  },
]

export const STAFF_COLOURS = {
  EXPECTED: '#0091da',
  REQUIRED: '#ff5b00',
}

export const VIEW_MODE = {
  DAY: 'day',
  WEEK: 'week',
}

export const VIEW_MODE_OPTIONS = [
  {
    label: _.upperFirst(i18n.t('global:week')),
    value: VIEW_MODE.WEEK,
  },
  {
    label: _.upperFirst(i18n.t('global:day')),
    value: VIEW_MODE.DAY,
  },
]

export const ROTA_STATISTICS_TYPE = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
}
