import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import auth from 'utils/auth'

import * as roomsConstants from 'services/legacy/rooms/constants'
import { FEATURE_FLAGS } from 'constants/security'

import { withAppService } from 'services/app'
import { withRoomsService } from 'services/legacy/rooms'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withSortingUtils } from 'services/utils/sorting'

import { getColumns } from './RoomsListHelper'
import RoomsView from './RoomsView'

const GROUPS = {
  read: ['staffRequirement'],
}

const DEFAULT_SORT = {
  sortField: 'startAgeInMonths',
  sortOrder: 'ASC',
}

class RoomsContainer extends Component {
  constructor(props) {
    super(props)

    const { ROOM_FILTER, ROOM_TYPES } = roomsConstants
    const { roomFilter, roomType } = props.location.query

    this.state = {
      filters: {
        roomFilter: roomFilter || ROOM_FILTER.ACTIVE,
        roomType: roomType || ROOM_TYPES.ALL,
      },
    }
  }

  componentDidMount() {
    this.fetch()
  }

  fetch = () => {
    const {
      paginationUtils,
      roomsActions,
      roomsSelectors,
      setLocationQuery,
      sortingUtils,
    } = this.props
    const { filters } = this.state

    const { page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils
    const { getCriteriaSelector } = roomsSelectors

    const criteria = getCriteriaSelector(filters)

    setLocationQuery(filters)

    const apiParams = {
      criteria,
      groups: GROUPS,
      order: {
        sortField: sortField || DEFAULT_SORT.sortField,
        sortOrder: sortField ? sortOrder : DEFAULT_SORT.sortOrder,
      },
      page,
    }

    roomsActions.list(apiParams)
  }

  handleRoomFilterChange = (roomFilter) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        roomFilter: roomFilter ? roomFilter.value : null,
      },
    }), () => this.handlePageChange(1))
  }

  handleRoomTypeChange = (roomType) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        roomType: roomType?.value || null,
      },
    }), () => this.handlePageChange(1))
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleSortChange = (key) => {
    const { paginationUtils, sortingUtils } = this.props
    const { onSortChange } = sortingUtils
    const { onPageChange } = paginationUtils

    onSortChange(onPageChange(this.fetch)(1))(key)
  }

  render() {
    const {
      authAccessMap,
      isFetching,
      paginationUtils,
      roomsTable,
      sortingUtils,
      totalResults,
    } = this.props
    const { filters } = this.state

    const { roomFilter, roomType } = filters
    const { getPageCount, page, perPage } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const { ROOM_FILTER_OPTIONS, ROOM_TYPES_OPTIONS_WITH_ALL } = roomsConstants
    const pageCount = getPageCount(totalResults)

    return (
      <RoomsView
        columns={getColumns(authAccessMap)}
        isLoading={isFetching}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        roomFilter={roomFilter}
        roomFilterOptions={ROOM_FILTER_OPTIONS}
        roomType={roomType}
        roomTypeOptions={ROOM_TYPES_OPTIONS_WITH_ALL}
        roomsTable={roomsTable}
        sortField={sortField || DEFAULT_SORT.sortField}
        sortOrder={sortField ? sortOrder : DEFAULT_SORT.sortOrder}
        totalResults={totalResults}
        onPageChange={this.handlePageChange}
        onRoomFilterChange={this.handleRoomFilterChange}
        onRoomTypeChange={this.handleRoomTypeChange}
        onSortChange={this.handleSortChange}
      />
    )
  }
}

const mapState = (state, { appSelectors, roomsListState, roomsSelectors }) => ({
  authAccessMap: {
    section: {
      PremiumSection: auth.SELECTORS.getIsAuthorised(state, {
        flags: [FEATURE_FLAGS.OCCUPANCY_ENABLED],
      }),
      StaffingEnabled: auth.SELECTORS.getIsAuthorised(state, {
        flags: [FEATURE_FLAGS.STAFF_REGISTER],
      }),
    },
  },
  isFetching: appSelectors.getIsFetching(roomsListState),
  roomsTable: roomsSelectors.getRoomsListTableSelector(state),
  totalResults: appSelectors.getTotalResults(roomsListState),
})

const enhance = compose(
  withAppService,
  withRoomsService,
  withPaginationUtils,
  withRouterUtils,
  withSortingUtils,
  connect(mapState),
)

export default enhance(RoomsContainer)
