import { getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_PUBLIC_ENQUIRY,
  CREATE_PUBLIC_ENQUIRY,
  CREATE_PUBLIC_ENQUIRY_FAILED,
  CREATE_PUBLIC_ENQUIRY_SUCCESS,
} from './constants'

const initialState = {
  error: null,
  isSubmitting: false,
}

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_PUBLIC_ENQUIRY,
    createFailed: CREATE_PUBLIC_ENQUIRY_FAILED,
    createInit: CREATE_PUBLIC_ENQUIRY,
    createSuccess: CREATE_PUBLIC_ENQUIRY_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
