import React from 'react'

import { Avatar, DefinitionList, Toolbar, Typography } from 'components'

import i18n from 'translations'

const ChildHeader = ({ child }) => {
  const { ageMonths, class: room, firstName, photo, surname } = child || {}
  const { name: roomName } = room || {}

  return (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <Avatar
            initials={[firstName, surname]}
            src={photo}
          />
        </Toolbar.Item>
        <Toolbar.Item>
          <DefinitionList>
            <DefinitionList.Item
              label={`${firstName} ${surname}`}
              value={(
                <Typography primary>
                  {i18n.t('global:ageMonths', { ageMonths })}
                </Typography>
              )}
            />
          </DefinitionList>
        </Toolbar.Item>
        {roomName && (
          <Toolbar.Item>
            <DefinitionList>
              <DefinitionList.Item
                label="Room"
                value={roomName}
              />
            </DefinitionList>
          </Toolbar.Item>
        )}
      </Toolbar.Group>
    </Toolbar>
  )
}

export default ChildHeader
