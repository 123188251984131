import React from 'react'

import ChildPreview from 'module/Safeguarding/Injury/components/ChildPreview'
import HomeAccidentPreview from 'module/Safeguarding/Injury/components/HomeAccidentPreview'

import { INJURIES } from 'services/legacy/injuries/constants'

import { Button, FooterActions } from 'components'

import i18n from 'translations'

const ChildReport = ({ childId, injuryPreview, onContinueClick }) => (
  <React.Fragment>
    {INJURIES.HOME_ACCIDENT === injuryPreview.type
      ? <HomeAccidentPreview injuryPreview={injuryPreview} />
      : (
        <ChildPreview
          childId={childId}
          injuryPreview={injuryPreview}
          topLine={false}
          showInjuryPreviewDetail
        />
      )}
    <FooterActions>
      <FooterActions.Item>
        <Button
          label={i18n.t('module:Injury:Preview:Signatures:Digital:addButtonLabel')}
          negativeMargins
          onClick={onContinueClick}
        />
      </FooterActions.Item>
    </FooterActions>
  </React.Fragment>
)
export default ChildReport
