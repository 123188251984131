import { createSelector } from 'reselect'

const getMonthlyFteChangeReport = (state) => state.monthlyFteChangeReport

export const getMonthlyFteChangeReportStatistics = createSelector(
  [getMonthlyFteChangeReport],
  (state) => state.statistics,
)

export const getMonthlyFteChangeReportStatisticsData = createSelector(
  [getMonthlyFteChangeReportStatistics],
  (state) => state.data,
)
