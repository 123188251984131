import _ from 'lodash'

import { authContainer } from 'utils/auth'

const injectAuthRecursive = (item) => {
  const route = { ...item }

  if (route.component && !route.disableAuth) {
    route.component = authContainer(route.component)
  }

  if (route.indexRoute && route.indexRoute.component && !route.indexRoute.disableAuth) {
    route.indexRoute.component = authContainer(route.indexRoute.component)
  }

  if (route.childRoutes) {
    route.childRoutes = _.map(route.childRoutes, injectAuthRecursive)
  }

  return route
}

export const injectAuthContainer = (routes) => injectAuthRecursive(routes)

const findRoute = (route, searchName, previous = [], onFinded) => {
  if (!route) {
    return null
  }

  const current = [...previous]
  current.push(route)

  if (route.name === searchName) {
    return onFinded(current)
  }

  return _.each(route.childRoutes, (childRoute) => {
    findRoute(childRoute, searchName, current, onFinded)
  })
}

interface RouteOptions {
  transferOldParams?: boolean
}

export const generateRoute = (routeName, routeParams?, options?: RouteOptions) => {
  const { Routing } = require('core')
  let route = null

  findRoute(Routing, routeName, [], (routeCollection) => {
    const routeArray = _.filter(_.map(routeCollection, (i) => i.path), (e) => '' !== e)

    route = routeArray.join('/').replace('//', '/') || '/'
  })

  _.each(routeParams, (value, key) => {
    route = route && route.replace(`:${key}`, value)
  })

  if (options?.transferOldParams) {
    route += window.location.search
  }

  return route
}
