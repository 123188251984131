import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as financeRevenuePerAgeReportSelectors from './selectors'

const withFinanceRevenuePerAgeReportService = (WrappedComponent) => {
  const mapState = ({ financeRevenuePerAgeReport }) => ({ financeRevenuePerAgeReport })

  const mapDispatch = injectActions('financeRevenuePerAgeReportActions', actions)

  const Component = ({ financeRevenuePerAgeReport, financeRevenuePerAgeReportActions, ...others }) => (
    <WrappedComponent
      financeRevenuePerAgeReportActions={financeRevenuePerAgeReportActions}
      financeRevenuePerAgeReportChildrenState={financeRevenuePerAgeReport.children}
      financeRevenuePerAgeReportNurseriesState={financeRevenuePerAgeReport.nurseries}
      financeRevenuePerAgeReportSelectors={financeRevenuePerAgeReportSelectors}
      financeRevenuePerAgeReportStatisticsState={financeRevenuePerAgeReport.statistics}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withFinanceRevenuePerAgeReportService
