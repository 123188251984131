import styled from 'styled-components'

import layout from 'constants/layout'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const StyledItemContainer = styled.div`
  flex: ${({ $flex }) => $flex};
  display: flex;
  min-width: 0;
  padding: ${({ $padding }) => $padding || '5px 15px'};
  flex-direction: ${({ $column }) => ($column ? 'column' : 'row')};

  ${({ $mobileFull }) => $mobileFull && `
    @media(max-width: ${layout.mobileWidthResolution}px) {
      flex: 1;
    }
  `}

  ${({ $bottomMarginOnMobile }) => $bottomMarginOnMobile && `
    @media(max-width: ${layout.mobileWidthResolution}px) {
      margin-bottom: 20px;
    }
  `}

  ${({ $disableRwd }) => !$disableRwd && `
    @media(max-width: ${layout.mobileWidthResolution}px) {
      padding-left: 0;
      padding-right: 0;
    }
  `}
  
  ${({ $alignItems }) => $alignItems && `
    align-items: ${$alignItems};
  `}

  ${({ $overflow }) => $overflow && `
    overflow: ${$overflow};
  `}
`

export const StyledRowContainer = styled.div`
  display: flex;

  ${({ $overflow }) => $overflow && `
      overflow: ${$overflow};
  `}
  
  ${({ $noVerticalMargin }) => $noVerticalMargin && `
      margin: 0px -15px;
  `}
  
  ${({ $disableRwd }) => !$disableRwd && `
    @media(max-width: ${layout.mobileWidthResolution}px) {
      flex-direction: column;
      margin: 0;
    }
  `}
`
