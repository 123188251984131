import React, { PropsWithChildren } from 'react'

interface TabsItemProps {
  disabled?: boolean
  minWidth?: string
  name?: string
  title?: React.ReactNode
  tooltip?: React.ReactNode
}

// @ts-ignore
const TabsItem: React.FC<PropsWithChildren<TabsItemProps>> = ({ children }) => children

export default TabsItem
