import React from 'react'

import { DatePicker, Select, Toolbar } from 'components'

import i18n from 'translations'

const ChildAttendanceHeader = ({ filters, onDateRangeChange, onTypeChange, typeOptions }) => {
  const { dateRange, type } = filters

  return (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <DatePicker
            value={dateRange}
            clearable
            range
            onChange={onDateRangeChange}
          />
        </Toolbar.Item>
        <Toolbar.Item>
          <Select
            options={typeOptions}
            placeholder={i18n.t('global:AllEntries')}
            searchable={false}
            value={type}
            onChange={onTypeChange}
          />
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )
}

export default ChildAttendanceHeader
