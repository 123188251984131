import { generateBasicActions } from 'utils/service'

import { getErrorMessage } from './selectors'
import {
  CLEAR_LIST_OBSERVATIONS,
  EXPORT_OBSERVATIONS_REPORT,
  EXPORT_OBSERVATIONS_REPORT_FAILED,
  EXPORT_OBSERVATIONS_REPORT_SUCCESS,
  LIST_OBSERVATIONS,
  LIST_OBSERVATIONS_FAILED,
  LIST_OBSERVATIONS_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ observationsApiClient }) => generateBasicActions.list({
  constants: {
    failed: LIST_OBSERVATIONS_FAILED,
    init: LIST_OBSERVATIONS,
    success: LIST_OBSERVATIONS_SUCCESS,
  },
  options,
  service: observationsApiClient,
  serviceMethod: 'list',
})

export const clearList = () => ({
  type: CLEAR_LIST_OBSERVATIONS,
})

export const exportReport = (params, onSuccess) => ({ observationsApiClient }) => (dispatch) => {
  dispatch({ type: EXPORT_OBSERVATIONS_REPORT })

  observationsApiClient.export(params).then(
    () => {
      dispatch({ type: EXPORT_OBSERVATIONS_REPORT_SUCCESS })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      const errorMessage = getErrorMessage(error.response, params)

      dispatch({
        error: {
          ...error.response,
          message: errorMessage,
        },
        type: EXPORT_OBSERVATIONS_REPORT_FAILED,
      })
    },
  )
}
