import { generateBasicActions } from 'utils/service'

import {
  CLEAR_SUPPORTED_LOCALE,
  GET_SUPPORTED_LOCALE,
  GET_SUPPORTED_LOCALE_FAILED,
  GET_SUPPORTED_LOCALE_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ supportedLocaleApiClient }) => generateBasicActions.list({
  constants: {
    failed: GET_SUPPORTED_LOCALE_FAILED,
    init: GET_SUPPORTED_LOCALE,
    success: GET_SUPPORTED_LOCALE_SUCCESS,
  },
  options,
  service: supportedLocaleApiClient,
  serviceMethod: 'list',
})

export const clear = () => ({
  type: CLEAR_SUPPORTED_LOCALE,
})
