import _ from 'lodash'

import { createSelector } from 'reselect'

const getOrganizationMembershipsList = (state) => state.memberships.organizationList

export const getOrganizationMembershipsListData = createSelector(
  [getOrganizationMembershipsList],
  (state) => state.data,
)

export const getDropdownOrganizationMemberships = createSelector([getOrganizationMembershipsListData], (data) => _.map(
  data,
  (item) => ({
    avatar: item.photo,
    email: item.email,
    label: item.name,
    value: item.id,
  }),
))

