import React from 'react'

import { ModalBox } from 'components'

import i18n from 'translations'

import ExportModalForm from './components/ExportModalForm'

const ExportModalView = ({
  onCloseClick,
  ...rest
}) => (
  <ModalBox
    maxWidth={480}
    title={i18n.t('module:Modals:Staff:Worklog:Export:title')}
    autoHeight
    onCloseClick={onCloseClick}
  >
    <ExportModalForm
      onCloseClick={onCloseClick}
      {...rest}
    />
  </ModalBox>
)

export default ExportModalView
