import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import { withSecurityService } from 'services/security'
import { withShellService } from 'services/shell'
import { withRouter } from 'services/router'

import i18n from 'translations'

import StaffWorklogList from 'module/Staff/StaffWorklog/StaffWorklogList'
import StaffRegisterWrapper from 'module/Staff/StaffRegisterWrapper'

import NurseryRota from 'module/Organization/NurseryRota'
import StaffWrapperView from './StaffWrapperView'
import StaffAttendance from './StaffAttendance'
import StaffRota from './StaffRota'
import StaffLeave from './StaffLeave'
import StaffList from './StaffList'

class StaffWrapperContainer extends Component {
  componentDidMount() {
    const { authAccessMap, shellActions } = this.props

    shellActions.setSubMenu([
      {
        hidden: !authAccessMap.module.StaffList,
        icon: 'staff',
        label: i18n.t('module:Staff:StaffLists:staffList'),
        to: generateRoute('STAFF.LIST'),
      },
      {
        hidden: !authAccessMap.module.StaffRegister,
        icon: 'staff-register',
        label: i18n.t('module:StaffRegister:StaffRegisterList:title'),
        to: generateRoute('STAFF.REGISTER'),
      },
      {
        hidden: !authAccessMap.module.StaffWorklog,
        icon: 'staff-worklog',
        label: i18n.t('module:Staff:StaffWorklog:title'),
        to: generateRoute('STAFF.WORKLOG'),
      },
      {
        hidden: !authAccessMap.module.StaffRota,
        icon: 'staff-rota',
        label: i18n.t('module:Staff:StaffRota:title'),
        to: generateRoute('STAFF.ROTA'),
      },
      {
        hidden: !authAccessMap.module.StaffLeave,
        icon: 'staff-leave',
        label: i18n.t('module:Staff:StaffLeave:title'),
        to: generateRoute('STAFF.LEAVE'),
      },
      {
        hidden: !authAccessMap.module.StaffAttendance,
        icon: 'staff-worklog',
        label: i18n.t('module:Staff:StaffAttendance:title'),
        to: generateRoute('STAFF.ATTENDANCE'),
      },
      {
        hidden: !authAccessMap.module.OrganizationNurseryRota,
        icon: 'staff-rota',
        label: i18n.t('module:Organization:NurseryRota:breadcrumbTitle'),
        to: generateRoute('STAFF.ORGANIZATION_NURSERY_ROTA'),
      },
    ])
  }

  shouldComponentUpdate(nextProps) {
    const { location: { pathname }, navigate } = nextProps
    const {
      authAccessMap: { module: { StaffList: isStaffListGranted, StaffRota: isStaffRotaGranted } },
      isOrganizationContext,
    } = this.props

    if (generateRoute('STAFF.INDEX') === pathname && isStaffListGranted) {
      navigate(generateRoute('STAFF.LIST'))

      return true
    }

    if (generateRoute('STAFF.INDEX') === pathname && isStaffRotaGranted) {
      navigate(generateRoute('STAFF.ROTA'))

      return true
    }

    if (generateRoute('STAFF.INDEX') === pathname && !isStaffListGranted && isOrganizationContext) {
      navigate(generateRoute('STAFF.ORGANIZATION_NURSERY_ROTA'))

      return true
    }

    if (generateRoute('STAFF.INDEX') === pathname && !isStaffListGranted) {
      navigate(generateRoute('STAFF.REGISTER'))

      return true
    }

    return true
  }

  componentWillUnmount() {
    const { shellActions } = this.props

    shellActions.clearSubMenu()
  }

  render() {
    const { children } = this.props

    return (
      <StaffWrapperView>
        {children}
      </StaffWrapperView>
    )
  }
}

const mapState = (state, { securitySelectors }) => ({
  authAccessMap: {
    module: {
      OrganizationNurseryRota: auth.SELECTORS.getComponentIsAuthorised(state, NurseryRota),
      StaffAttendance: auth.SELECTORS.getComponentIsAuthorised(state, StaffAttendance),
      StaffLeave: auth.SELECTORS.getComponentIsAuthorised(state, StaffLeave),
      StaffList: auth.SELECTORS.getComponentIsAuthorised(state, StaffList),
      StaffRegister: auth.SELECTORS.getComponentIsAuthorised(state, StaffRegisterWrapper),
      StaffRota: auth.SELECTORS.getComponentIsAuthorised(state, StaffRota),
      StaffWorklog: auth.SELECTORS.getComponentIsAuthorised(state, StaffWorklogList),
    },
  },
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
})

const enhance = compose(
  withRouter,
  withSecurityService,
  withShellService,
  connect(mapState),
)

export default enhance(StaffWrapperContainer)
