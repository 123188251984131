import React from 'react'

import { Page, Section, Select, Spinner, Toolbar, Typography } from 'components'

import i18n from 'translations'

import RevenueChart from 'module/Finance/FinanceReport/FinanceReportAnnualRevenue/components/RevenueChart'
import RevenueTable from 'module/Finance/FinanceReport/FinanceReportAnnualRevenue/components/RevenueTable'

const AnnualRevenueView = ({
  chart,
  isFetching,
  isFinanceV3Enabled,
  onSendReport,
  onYearChange,
  revenueData,
  year,
  yearsOptions,
}) => {
  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    const renderChart = () => <RevenueChart chart={chart} year={year && year.value} />

    const renderTable = () => (
      <RevenueTable
        isFinanceV3Enabled={isFinanceV3Enabled}
        monthList={year && year.monthList}
        revenueData={revenueData}
      />
    )

    return (
      <React.Fragment>
        {renderChart()}
        {renderTable()}
      </React.Fragment>
    )
  }

  const actions = <Section.Actions options={[{ onClick: onSendReport, type: 'export' }]} />

  return (
    <Page.Section
      actions={actions}
      isLoading={isFetching}
      title={i18n.t('module:Organization:Reports:AnnualRevenue:title')}
    >
      <Toolbar bottomLine>
        <Toolbar.Group>
          <Toolbar.Item>
            <Select
              clearable={false}
              options={yearsOptions}
              searchable={false}
              value={year}
              onChange={onYearChange}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      <Typography margin="20px 0">{i18n.t('module:Organization:Reports:AnnualRevenue:reportTitle')}</Typography>
      {renderContent()}
    </Page.Section>
  )
}

export default AnnualRevenueView
