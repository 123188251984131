import React from 'react'
import { Field } from 'redux-form'

import { Option } from 'constants/models'

import { isRequired } from 'utils/fieldValidation'

import { Form } from 'components'

import i18n from 'translations'

const GROUPS = {
  read: ['nurserySessionProduct.items'],
}

export interface SessionItemOption extends Option {
  hourly?: boolean
  items?: any[]
}

interface SessionFormFieldProps {
  isInvoiced: boolean
  item: SessionItemOption
  linkedToRequestedExtraSession?: boolean
}

const SessionFormFields: React.FC<SessionFormFieldProps> = ({ isInvoiced, item, linkedToRequestedExtraSession }) => (
  <React.Fragment>
    <Form.Row label={i18n.t('global:Item')} required verticalLabel>
      <Form.Row.FlexItem flex="0 0 300px" mobileFull>
        <Field
          component={Form.InfiniteDropdowns.NurserySessionsV3}
          disabled={isInvoiced || linkedToRequestedExtraSession}
          extraFields={['hourly', 'items']}
          groups={GROUPS}
          name="item"
          validate={isRequired}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    {item?.hourly && (
      <Form.Row
        label={i18n.t('global:Time')}
        multipleFieldsInARow
        required
        verticalLabel
      >
        <Form.Row.FlexItem flex="0 0 130px" mobileFull>
          <Field
            component={Form.TimePicker}
            disabled={isInvoiced || linkedToRequestedExtraSession}
            local={false}
            name="startTime"
            validate={isRequired}
          />
        </Form.Row.FlexItem>
        <Form.Row.TextItem text="-" />
        <Form.Row.FlexItem flex="0 0 130px" mobileFull>
          <Field
            component={Form.TimePicker || linkedToRequestedExtraSession}
            disabled={isInvoiced}
            local={false}
            name="endTime"
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
    )}
    {item?.items?.length ? (
      <Form.Row label={i18n.t('module:Finance:OneOffBookings:Form:includeConsumables')} required verticalLabel>
        <Form.Row.FlexItem flex="0 0 300px" mobileFull>
          <Field
            component={Form.Switch}
            disabled={isInvoiced || linkedToRequestedExtraSession}
            name="includeConsumables"
          />
        </Form.Row.FlexItem>
      </Form.Row>
    ) : null}
  </React.Fragment>
)

export default SessionFormFields
