import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'

import { DAILY_DIARY_DATE_FORMAT } from 'services/legacy/dailyDiary/constants'

import { withModalService } from 'services/utils/modal'

import i18n from 'translations'

import DailyDiaryRecordModalView from './DailyDiaryRecordModalView'

export const DAILY_DIARY_RECORD_MODAL_VARIANTS = {
  ACTIVITY: 'ACTIVITY',
  BOTTLE: 'BOTTLE',
  NAPPY: 'NAPPY',
  SLEEP: 'SLEEP',
  TOILET_TRAINING: 'TOILET_TRAINING',
}

class DailyDiaryRecordModalContainer extends Component {
  constructor(props) {
    super(props)

    const { date, onlyChildPicker, selectedChildren, timeType } = this.props

    this.state = {
      childPickerTabVisible: onlyChildPicker || !(selectedChildren && selectedChildren.length),
      date: moment(date, DAILY_DIARY_DATE_FORMAT).format(DAILY_DIARY_DATE_FORMAT),
      forSpecificChild: !!(selectedChildren && selectedChildren.length),
      selectedChildren: selectedChildren && selectedChildren.length ? selectedChildren : [],
      timeType,
    }
  }

  handleCloseClick = (disableModal) => {
    const { hideModal, modalActions, modalConsts, variant } = this.props
    const { childPickerTabVisible } = this.state

    if (DAILY_DIARY_RECORD_MODAL_VARIANTS.ACTIVITY === variant && !childPickerTabVisible && !disableModal) {
      return modalActions.show(modalConsts.TYPES.CONFIRM, {
        confirmButtonLabel: i18n.t('global:Discard'),
        onConfirm: () => hideModal(),
        text: i18n.t('module:Modals:DailyDiaryRecordModal:confirmDiscard'),
      })
    }

    return hideModal()
  }

  handleCancel = () => {
    this.setState({
      childPickerTabVisible: true,
    })
  }

  handleNext = (selectedChildren) => {
    const { onSuccess, onlyChildPicker } = this.props

    if (onlyChildPicker) {
      this.handleCloseClick()

      return onSuccess({ selectedChildren })
    }

    return this.setState({
      childPickerTabVisible: false,
      selectedChildren,
    })
  }

  handleSuccess = ({ ...args } = {}) => {
    const { onSuccess } = this.props

    this.handleCloseClick(true)

    if (onSuccess) {
      onSuccess(args)
    }
  }

  render() {
    const { onlyChildPicker, variant } = this.props
    const { childPickerTabVisible, date, forSpecificChild, selectedChildren, timeType } = this.state

    return (
      <DailyDiaryRecordModalView
        childPickerTabVisible={childPickerTabVisible}
        date={date}
        forSpecificChild={forSpecificChild}
        onlyChildPicker={onlyChildPicker}
        selectedChildren={selectedChildren}
        timeType={timeType}
        variant={variant}
        onCancel={this.handleCancel}
        onCloseClick={this.handleCloseClick}
        onNext={this.handleNext}
        onSubmit={this.handleSubmit}
        onSuccess={this.handleSuccess}
      />
    )
  }
}

const enhance = compose(
  withModalService,
)

export default enhance(DailyDiaryRecordModalContainer)
