import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import selectors from './selectors'

const withChildAbsencesService = (WrappedComponent) => {
  const mapState = ({ childAbsences }) => ({ childAbsences })

  const mapDispatch = injectActions('childAbsencesActions', actions)

  const Component = (props) => {
    const { childAbsences, childAbsencesActions, ...other } = props

    return (
      <WrappedComponent
        childAbsencesActions={childAbsencesActions}
        childAbsencesListState={childAbsences.list}
        childAbsencesSelectors={selectors}
        childAbsencesSingleState={childAbsences.single}
        {...other}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withChildAbsencesService
