import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledFundingDetailGroup = styled.div`
  display: flex;
  flex-direction: column;
  ${({ color }) => color && `color: ${color};`}
  font-weight: 600;
`

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  & ${StyledFundingDetailGroup} ~ ${StyledFundingDetailGroup} {
    padding-top: 10px;
  }
`

export const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: ${getBrandingColor('primary-color')};
  padding-bottom: 5px;

  & div ~ div {
    padding-left: 10px;
  }
`

export const StyledDateContainer = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: 600;
`

export const StyledFundingDetailItem = styled.div`
  display: flex;
  justify-content: space-between;
`
