export const GET_LOGIN = 'AUTHENTICATION/GET_LOGIN'
export const GET_LOGIN_FAILED = 'AUTHENTICATION/GET_LOGIN_FAILED'
export const GET_LOGIN_SUCCESS = 'AUTHENTICATION/GET_LOGIN_SUCCESS'

export const GET_AUTH_ME = 'AUTHENTICATION/GET_AUTH_ME'
export const GET_AUTH_ME_FAILED = 'AUTHENTICATION/GET_AUTH_ME_FAILED'
export const GET_AUTH_ME_SUCCESS = 'AUTHENTICATION/GET_AUTH_ME_SUCCESS'

export const SET_IDENTITY = 'AUTHENTICATION/SET_IDENTITY'
export const REMOVE_IDENTITY = 'AUTHENTICATION/REMOVE_IDENTITY'

export const INSTALL_DEVICE_TOKEN = 'AUTHENTICATION/INSTALL_DEVICE_TOKEN'
export const INSTALL_DEVICE_TOKEN_FAILED = 'AUTHENTICATION/INSTALL_DEVICE_TOKEN_FAILED'
export const INSTALL_DEVICE_TOKEN_SUCCESS = 'AUTHENTICATION/INSTALL_DEVICE_TOKEN_SUCCESS'

export const RESET_PASSWORD = 'AUTHENTICATION/RESET_PASSWORD'
export const RESET_PASSWORD_FAILED = 'AUTHENTICATION/RESET_PASSWORD_FAILED'
export const RESET_PASSWORD_SUCCESS = 'AUTHENTICATION/RESET_PASSWORD_SUCCESS'

export const CHANGE_PASSWORD = 'AUTHENTICATION/CHANGE_PASSWORD'
export const CHANGE_PASSWORD_FAILED = 'AUTHENTICATION/CHANGE_PASSWORD_FAILED'
export const CHANGE_PASSWORD_SUCCESS = 'AUTHENTICATION/CHANGE_PASSWORD_SUCCESS'

export const REGISTER = 'AUTHENTICATION/REGISTER'
export const REGISTER_FAILED = 'AUTHENTICATION/REGISTER_FAILED'
export const REGISTER_SUCCESS = 'AUTHENTICATION/REGISTER_SUCCESS'
