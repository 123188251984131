import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_CHILD_BOOKING_PREVIEW,
  GET_CHILD_BOOKING_PREVIEW,
  GET_CHILD_BOOKING_PREVIEW_FAILED,
  GET_CHILD_BOOKING_PREVIEW_SUCCESS,
} from './constants'

export const preview = (options: BasicActionOptionsProps) => ({ childBookingApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: GET_CHILD_BOOKING_PREVIEW_FAILED,
      init: GET_CHILD_BOOKING_PREVIEW,
      success: GET_CHILD_BOOKING_PREVIEW_SUCCESS,
    },
    options,
    service: childBookingApiClient,
    serviceMethod: 'preview',
  })
)

export const clearPreview = () => ({
  type: CLEAR_CHILD_BOOKING_PREVIEW,
})
