import styled from 'styled-components'

export const StyledRoot = styled.div`
  max-width: 420px;
`

export const StyledContent = styled.div`
  font-size: 20px;
  padding: 0 20px;
  text-align: center;
  line-height: 1.5;
`
