import React from 'react'

import { Callout, ModalBox } from 'components'

import i18n from 'translations'

import DepositRefundModalForm from './components/DepositRefundModalForm'

const DepositRefundModalView = ({
  errorMessages,
  onCloseClick,
  ...rest
}) => (
  <ModalBox
    title={i18n.t('module:Modals:DepositRefund:title')}
    autoHeight
    onCloseClick={onCloseClick}
  >
    <Callout content={errorMessages} error />
    <DepositRefundModalForm
      {...rest}
      onCloseClick={onCloseClick}
    />
  </ModalBox>
)

export default DepositRefundModalView
