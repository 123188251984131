import React from 'react'

import AddPaymentWrapperView from './AddPaymentWrapperView'

const AddPaymentWrapperContainer = ({ children }) => (
  <AddPaymentWrapperView>
    {children}
  </AddPaymentWrapperView>
)

export default AddPaymentWrapperContainer
