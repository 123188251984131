import _ from 'lodash'
import moment from 'moment'

import i18n from 'translations'

import {
  ADD_FILE_TO_REFERENCE_PAGE_SUCCESS,
  ADD_UPLOAD_FILE,
  CLEAR_UPLOADED_FILES,
  FILE_SIZE_TOO_LARGE,
  MARK_FILE_TO_REMOVE,
  MARK_UPLOADED_FILE_TO_REMOVE,
  UPDATE_REFERENCE_PAGES_STORAGE,
  UPDATE_UPLOAD_FILE,
  UPDATE_UPLOAD_FILES,
} from './constants'

const initialState = {
  files: [],
  referencePagesStorage: {},
}

export default (state = initialState, { file, payload, type }) => {
  switch (type) {
    case ADD_UPLOAD_FILE:
      return {
        ...state,
        files: [
          ...state.files,
          {
            awsConfig: null,
            canceled: false,
            createdAt: moment(),
            dummy: false,
            errors: [],
            file: payload.file,
            id: payload.id,
            inQueue: true,
            initialization: {
              onFe: false,
              onFeSecond: false,
            },
            isUploaded: false,
            itemFromBe: false,
            lambdaStatus: null,
            mimeType: payload.mimeType,
            name: payload.name,
            referencePage: payload.referencePage,
            size: payload.size,
            stopped: false,
            taggedChildren: [],
            thumbnail: payload.thumbnail,
            uploadedData: 0,
            uploadingInProgress: false,
            waitingForProcessing: false,
          },
        ],
      }
    case UPDATE_UPLOAD_FILE: {
      const { changes, id } = payload

      const files = _.map(state.files, (item) => {
        if (id === item.id) {
          return _.merge(item, changes)
        }

        return item
      })

      return {
        ...state,
        files,
      }
    }
    case FILE_SIZE_TOO_LARGE: {
      const { id } = payload

      const files = _.map(state.files, (item) => {
        if (id === item.id) {
          return {
            ...item,
            canceled: true,
            errors: [
              ...item.errors,
              i18n.t('services:Files:fileSizeTooLarge'),
            ],
            uploadingInProgress: false,
          }
        }

        return item
      })

      return {
        ...state,
        files,
      }
    }
    case ADD_FILE_TO_REFERENCE_PAGE_SUCCESS: {
      const { beId, id } = file

      const files = _.map(state.files, (item) => {
        if (id === item.id) {
          return _.merge(item, {
            beId,
          })
        }

        return item
      })

      return {
        ...state,
        files,
      }
    }
    case MARK_FILE_TO_REMOVE: {
      const files = _.filter(state.files, (item) => (
        item.id !== payload
      ))

      return {
        ...state,
        files,
      }
    }
    case MARK_UPLOADED_FILE_TO_REMOVE: {
      const files = _.filter(state.files, (item) => {
        const { awsConfig } = item || {}
        const { payload: awsPayload } = awsConfig || {}
        const { key } = awsPayload || {}
        const { file: payloadFile, url } = payload || {}

        if (url) {
          return -1 === url.indexOf(key)
        }

        if (payloadFile && !(payloadFile instanceof window.File)) {
          return -1 === payloadFile?.indexOf(key)
        }

        return true
      })

      return {
        ...state,
        files,
      }
    }
    case CLEAR_UPLOADED_FILES: {
      return {
        ...state,
        files: _.filter(state.files, ({ canceled, dummy, waitingForProcessing }) => (
          !dummy && !waitingForProcessing && !canceled
        )),
      }
    }
    case UPDATE_REFERENCE_PAGES_STORAGE: {
      const { id, name, type: pageType } = payload

      return {
        ...state,
        referencePagesStorage: {
          ...state.referencePagesStorage,
          [pageType]: {
            ...state.referencePagesStorage?.[pageType],
            [id]: name,
          },
        },
      }
    }
    case UPDATE_UPLOAD_FILES: {
      return {
        ...state,
        files: _.map(state.files, (item) => {
          let updatedFile = item

          _.each(payload, ({ changes, id }) => {
            if (+item.id === +id) {
              updatedFile = {
                ...updatedFile,
                ...changes,
              }

              if (changes.uploadedData) {
                updatedFile.uploadedData = changes.uploadedData > item.uploadedData
                  ? changes.uploadedData
                  : item.uploadedData
              }
            }
          })

          return updatedFile
        }),
      }
    }
    default:
      return state
  }
}
