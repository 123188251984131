import React from 'react'
import { Field, InjectedFormProps, formValueSelector, reduxForm } from 'redux-form'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { isRequired, isSortCodeValid, isValidBankAccountNumber } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'
import { getCalendarDayOptions } from 'utils/date'

import { Banner, Form, Space, Typography } from 'components'

import i18n from 'translations'

const ADD_BACS_DETAILS_FORM = 'AddBacsDetailsForm'

interface BacsDetailsFormValues {
  accountHolder?: string
  accountNumber?: string
  bankName?: string
  chargeDay?: string | object
  enabled: boolean
  sortCode?: string
}

interface AddBacsDetailsFormProps {
  childId: number
  enabled: boolean
  isStripeType: boolean
  isSubmitting?: boolean
  onSubmit: (values: BacsDetailsFormValues) => void
}

type AddBacsDetailsFormPropsFullProps = AddBacsDetailsFormProps & InjectedFormProps<{}, AddBacsDetailsFormProps>

const AddBacsDetailsForm: React.FC<AddBacsDetailsFormPropsFullProps> = ({
  childId,
  enabled,
  handleSubmit,
  isStripeType,
  isSubmitting,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    {isStripeType && (
      <React.Fragment>
        <Banner.Info>
          {i18n.t('module:Children:Child:Finance:BacsDetails:disabledInfo')}
        </Banner.Info>
        <Space space="20px" />
      </React.Fragment>
    )}
    <Form.Row label={i18n.t('module:Children:Child:Finance:BacsDetails:Form:ActiveBacsMandate:label')} required>
      <Form.Row.Item>
        <Field
          component={Form.Switch}
          disabled={isSubmitting || isStripeType}
          name="enabled"
          validate={isRequired}
        />
      </Form.Row.Item>
      <Form.Row.TextItem text={enabled ? i18n.t('global:On') : i18n.t('global:Off')} />
    </Form.Row>
    {enabled && (
      <React.Fragment>
        <Form.Row label={i18n.t('module:Children:Child:Finance:BacsDetails:Form:AccountHolder:label')} required>
          <Form.Row.FlexItem flex="0 0 400px">
            <Field
              component={Form.TextField}
              disabled={isSubmitting || isStripeType}
              name="accountHolder"
              placeholder={i18n.t('module:Children:Child:Finance:BacsDetails:Form:AccountHolder:placeholder')}
              validate={isRequired}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row label={i18n.t('module:Children:Child:Finance:BacsDetails:Form:AccountNo:label')} required>
          <Form.Row.FlexItem flex="0 0 400px">
            <Field
              component={Form.TextField}
              disabled={isSubmitting || isStripeType}
              name="accountNumber"
              placeholder={i18n.t('module:Children:Child:Finance:BacsDetails:Form:AccountNo:placeholder')}
              validate={[isRequired, isValidBankAccountNumber]}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row label={i18n.t('module:Children:Child:Finance:BacsDetails:Form:SortCode:label')} required>
          <Form.Row.FlexItem flex="0 0 400px">
            <Field
              component={Form.TextField}
              disabled={isSubmitting || isStripeType}
              name="sortCode"
              placeholder={i18n.t('module:Children:Child:Finance:BacsDetails:Form:SortCode:placeholder')}
              validate={[isRequired, isSortCodeValid]}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        {!isStripeType && (
          <Form.Row label={i18n.t('module:Children:Child:Finance:BacsDetails:Form:BankName:label')} required>
            <Form.Row.FlexItem flex="0 0 400px">
              <Field
                component={Form.TextField}
                disabled={isSubmitting || isStripeType}
                name="bankName"
                placeholder={i18n.t('module:Children:Child:Finance:BacsDetails:Form:BankName:placeholder')}
                validate={isRequired}
              />
            </Form.Row.FlexItem>
          </Form.Row>
        )}
        <Form.Row
          label={i18n.t('module:Children:Child:Finance:BacsDetails:Form:StartMonthlyDirectDebitRunOn:label')}
          required
        >
          <Form.Row.FlexItem flex="0 0 400px">
            <Field
              component={Form.Select}
              disabled={isSubmitting || isStripeType}
              name="chargeDay"
              options={getCalendarDayOptions()}
              placeholder={
                i18n.t('module:Children:Child:Finance:BacsDetails:Form:StartMonthlyDirectDebitRunOn:placeholder')
              }
              validate={isRequired}
            />
            <Typography fontSize={14} margin="5px 0 0" variant="span">
              {i18n.t('module:Children:Child:Finance:BacsDetails:Form:StartMonthlyDirectDebitRunOn:tooltip')}
            </Typography>
          </Form.Row.FlexItem>
        </Form.Row>
      </React.Fragment>
    )}
    <Form.FooterActions
      cancelLink={generateRoute('CHILDREN.CHILD.FINANCE.INVOICING', { childId })}
      isSubmitting={isSubmitting}
      submitDisabled={isStripeType}
      submitLabel={i18n.t('global:Save')}
    />
  </Form>
)

const mapState = (state) => ({
  enabled: formValueSelector(ADD_BACS_DETAILS_FORM)(state, 'enabled'),
})

const enhance = compose(
  reduxForm({ form: ADD_BACS_DETAILS_FORM }),
  connect(mapState),
)

export default enhance(AddBacsDetailsForm)

