import React, { PropsWithChildren } from 'react'

import { typeByObject } from 'utils/typescript'

import { CircleIcon, Tooltip } from 'components'

import SectionActions from './SectionActions'
import SectionDivider from './SectionDivider'
import {
  StyledActionsContainer,
  StyledContainer,
  StyledHeader,
  StyledHeaderContainer,
  StyledSubtitleContainer,
  StyledTitleSecond,
  StyledTooltipContainer,
} from './SectionStyled'

export const VARIANT = {
  MEDIUM: 'medium',
  SMALL: 'small',
} as const

interface SectionRoot<T> extends React.FC<PropsWithChildren<T>> {
  Actions?: typeof SectionActions
  Divider?: typeof SectionDivider
}

export interface SectionProps {
  actions?: any
  noBorder?: boolean
  noMargin?: boolean
  noMobileVersion?: boolean
  secondLevel?: boolean
  subtitle?: string
  title?: string | React.ReactNode
  titleColor?: string
  titleSecond?: string
  tooltip?: string
  variant?: typeByObject<typeof VARIANT>
}

const Section: SectionRoot<SectionProps> = ({
  actions,
  children,
  noBorder,
  noMargin,
  noMobileVersion,
  secondLevel,
  subtitle,
  title,
  titleColor,
  titleSecond,
  tooltip,
  variant = VARIANT.MEDIUM,
}) => {
  const renderHeaderActions = () => {
    if (!actions) {
      return null
    }

    return (
      <StyledActionsContainer>
        {actions}
      </StyledActionsContainer>
    )
  }

  const renderHeader = () => {
    if (!title) {
      return null
    }

    return (
      <StyledHeaderContainer
        $noBorder={noBorder}
        $noMobileVersion={noMobileVersion}
        $variant={variant}
      >
        <StyledHeader
          $titleColor={titleColor}
          $variant={variant}
        >
          {title}
          {titleSecond && (
            <StyledTitleSecond>
              {titleSecond}
            </StyledTitleSecond>
          )}
          {subtitle && (
            <StyledSubtitleContainer>
              {subtitle}
            </StyledSubtitleContainer>
          )}
          {tooltip && (
            <StyledTooltipContainer>
              <Tooltip title={tooltip}>
                <CircleIcon
                  cursor="pointer"
                  icon="info"
                  iconSize={16}
                  size={24}
                />
              </Tooltip>
            </StyledTooltipContainer>
          )}
        </StyledHeader>
        {renderHeaderActions()}
      </StyledHeaderContainer>
    )
  }

  return (
    <StyledContainer
      $noMargin={noMargin}
      $secondLevel={secondLevel}
    >
      {renderHeader()}
      <div>
        {children}
      </div>
    </StyledContainer>
  )
}

export default Section
