import React, { PropsWithChildren } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import ImagesSize from 'constants/imagesSize'
import { Subdomain } from 'services/subdomain/models'

import { getOptimizedImage } from 'utils/image'

import { Page } from 'components'

import {
  StyledHeaderContentWrapper,
  StyledHeaderNurseryInfoWrapper,
  StyledHeaderWrapper,
  StyledNurseryContactDetails,
  StyledNurseryLogoWrapper,
  StyledNurseryName,
} from './NurseryHeaderStyled'

interface NurseryHeaderProps {
  subdomainData: Subdomain
}

const NurseryHeader: React.FC<PropsWithChildren<NurseryHeaderProps>> = ({
  subdomainData: { address, logo, name, postCode, telephone },
}) => {
  const renderLogo = () => {
    if (!logo) {
      return null
    }

    return (
      <StyledNurseryLogoWrapper>
        <LazyLoadImage
          alt="nursery-logo"
          effect="opacity"
          height={ImagesSize.NURSERY_LOGO.height}
          src={getOptimizedImage(logo, ImagesSize.NURSERY_LOGO)}
          width={ImagesSize.NURSERY_LOGO.width}
        />
      </StyledNurseryLogoWrapper>
    )
  }

  return (
    <StyledHeaderWrapper>
      <Page.Content>
        <StyledHeaderContentWrapper>
          {renderLogo()}
          <StyledHeaderNurseryInfoWrapper>
            <StyledNurseryName>{name}</StyledNurseryName>
            <StyledNurseryContactDetails>{`${address}, ${postCode}`}</StyledNurseryContactDetails>
            <StyledNurseryContactDetails>{telephone}</StyledNurseryContactDetails>
          </StyledHeaderNurseryInfoWrapper>
        </StyledHeaderContentWrapper>
      </Page.Content>
    </StyledHeaderWrapper>
  )
}

export default NurseryHeader
