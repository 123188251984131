import { createSelector } from 'reselect'
import moment from 'moment'
import _ from 'lodash'

import { TOILET_TRAINING_OPTIONS, TOILET_TRAINING_TYPES } from '../constants'

export const getInitialValues = (params) => {
  const initialValues = {}
  const { accident, child, date, record, type } = params
  const { comments, recordedAt } = record || {}

  if (child) {
    const { firstName, id, photo, surname } = child

    initialValues.child = {
      avatar: photo,
      label: `${firstName} ${surname}`,
      value: id,
    }
  }

  if (comments) {
    initialValues.comments = comments
  }

  initialValues.accident = accident || false

  initialValues.type = type || TOILET_TRAINING_TYPES.POTTY

  initialValues.recordedAt = moment(recordedAt)

  if (date) {
    initialValues.recordedAt = moment(date)
      .set('hour', moment().format('HH'))
      .set('minute', moment().format('mm'))
  }

  return initialValues
}

export const getValuesForm = (values) => {
  const body = {}
  const {
    accident,
    child,
    comments,
    recordedAt,
    type,
  } = values

  body.comments = comments || ' '
  body.accident = accident

  if (child) {
    body.child = child.value
  }

  if (type) {
    body.type = type
  }

  if (recordedAt) {
    body.recordedAt = moment(recordedAt).toISOString()
  }

  return body
}

const getTypeValue = (
  item,
) => _.get(_.find(TOILET_TRAINING_OPTIONS, ({ value }) => item.type === value), 'label')

export const makeGetTypeValue = () => createSelector(
  [getTypeValue],
  (value) => value,
)
