import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_ABSENCE_REASON,
  CREATE_ABSENCE_REASON,
  CREATE_ABSENCE_REASON_FAILED,
  CREATE_ABSENCE_REASON_SUCCESS,
  GET_ABSENCE_REASON,
  GET_ABSENCE_REASON_FAILED,
  GET_ABSENCE_REASON_SUCCESS,
  UPDATE_ABSENCE_REASON,
  UPDATE_ABSENCE_REASON_FAILED,
  UPDATE_ABSENCE_REASON_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_ABSENCE_REASON,
    createFailed: CREATE_ABSENCE_REASON_FAILED,
    createInit: CREATE_ABSENCE_REASON,
    createSuccess: CREATE_ABSENCE_REASON_SUCCESS,
    itemFailed: GET_ABSENCE_REASON_FAILED,
    itemInit: GET_ABSENCE_REASON,
    itemSuccess: GET_ABSENCE_REASON_SUCCESS,
    updateFailed: UPDATE_ABSENCE_REASON_FAILED,
    updateInit: UPDATE_ABSENCE_REASON,
    updateSuccess: UPDATE_ABSENCE_REASON_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
