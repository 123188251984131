import _ from 'lodash'

import { createSelector } from 'reselect'

import i18n from 'translations'

import { TYPES } from '../constants'

const getNewsletterRecipients = (state) => state.newsletterRecipients

export const getNewsletterRecipientsListSelector = createSelector([getNewsletterRecipients], (state) => {
  if (!state) {
    return null
  }

  return state.list
})

export const getNewsRecipientsListDataSelector = createSelector(
  [getNewsletterRecipientsListSelector],
  (state) => state.data,
)

export const getNewsRecipientsListMetaSelector = createSelector(
  [getNewsletterRecipientsListSelector],
  (state) => state.meta,
)

export const getNewsRecipientsTableTotalResultsSelector = createSelector(
  [getNewsRecipientsListMetaSelector],
  (meta) => meta.total_results,
)

export const getNewsRecipientsTableListSelector = createSelector([getNewsRecipientsListDataSelector], (state) => {
  if (!state || !state.length) {
    return null
  }

  const getRecipient = (item) => {
    if (!item) {
      return null
    }

    if (item.type === TYPES.CARER) {
      const { carer, child } = item
      const { fullName: title, photo } = carer || {}
      const { firstNameWithSurname: subTitle } = child || {}

      return {
        initials: _.slice(_.split(title, ' '), 0, 2),
        photo,
        subTitle,
        title,
      }
    }

    const { user } = item
    const { name: title, photo, roles } = user
    const [subTitle] = _.map(roles, (key) => i18n.t(`global:roles:${key}`))

    return {
      initials: _.slice(_.split(title, ' '), 0, 2),
      photo,
      subTitle,
      title,
    }
  }

  return _.map(state, (item) => ({
    ...item,
    ...getRecipient(item),
  }))
})

export const getCriteriaSelector = createSelector(
  [(filters) => filters],
  (filters) => {
    if (!filters) {
      return null
    }

    const { newsId, search } = filters

    const criteria = []

    if (newsId) {
      criteria.push({
        field: 'newsletter',
        value: newsId,
      })
    }

    if (search) {
      criteria.push({
        field: 'concatenable',
        value: search,
      })
    }

    return criteria
  },
)
