import _ from 'lodash'

import React, { useEffect, useMemo, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'
import { stopSubmit } from 'redux-form'

import { ModalType } from 'modals'

import { RootState } from 'core/reducers'

import { Type } from 'services/booking/periods/models'

import { generateRoute } from 'utils/routing'

import { withAppService, withAppServiceProps } from 'services/app'
import { withNurseryAcademicYearsService, withNurseryAcademicYearsServiceProps } from 'services/nurseryAcademicYears'
import { withPeriodsService, withPeriodsServiceProps } from 'services/booking/periods'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withRouter, withRouterProps } from 'services/router'
import { withSnackbarService, withSnackbarServiceProps } from 'services/utils/snackbar'

import i18n from 'translations'

import AddView from './AddView'
import { CLOSURE_DATE_SETTING_FORM } from './components/AddForm'
import { getAcademicYearOptions, getFormErrors, getInitialValues } from './AddHelpers'
import { FormFields } from './models'

const PERIOD_GROUPS = {
  read: [
    'period.periodTimes',
    'periodTime',
    'period.nurseryAcademicYear',
    'nurseryAcademicYear',
    'period.closureDayType',
    'closureDayType',
  ],
}

type AddContainerProps = withAppServiceProps
  & withNurseryAcademicYearsServiceProps
  & withPeriodsServiceProps
  & withRouterProps
  & withModalServiceProps
  & withSnackbarServiceProps

const mapState = (state: RootState, {
  appSelectors,
  nurseryAcademicYearsListState,
  nurseryAcademicYearsSelectors,
  params,
  periodsListState,
  periodsSelectors,
}: AddContainerProps) => ({
  isFetching: appSelectors.getIsFetching(nurseryAcademicYearsListState, periodsListState),
  nurseryAcademicYear: nurseryAcademicYearsSelectors.getNurseryAcademicYear(state),
  nurseryAcademicYearList: nurseryAcademicYearsSelectors.getNurseryAcademicYearsList(state),
  // @ts-ignore
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  periods: periodsSelectors.getPeriodsList(state),
})

const mapDispatch = {
  stopSubmitForm: (params) => stopSubmit(CLOSURE_DATE_SETTING_FORM, params),
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

const AddContainer: React.FC<AddContainerProps & PropsFromRedux> = ({
  isFetching,
  modalActions,
  navigate,
  nurseryAcademicYear,
  nurseryAcademicYearList,
  nurseryAcademicYearsActions,
  nurseryAcademicYearsListState,
  nurseryAcademicYearsSelectors,
  nurseryOptions,
  params,
  periods,
  periodsActions,
  periodsSelectors,
  snackbarActions,
  stopSubmitForm,
}) => {
  const [initialValues, setInitialValues] = useState<FormFields>(null)
  const [deletedItems, setDeletedItems] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const isEdit = !!params.nurseryAcademicYearId

  useEffect(() => {
    nurseryAcademicYearsActions.list({
      params: [{
        criteria: nurseryAcademicYearsSelectors.getCriteria({ periodExists: params.nurseryAcademicYearId ? 1 : 0 }),
      }],
    })

    if (params.nurseryAcademicYearId) {
      nurseryAcademicYearsActions.get({
        params: [params.nurseryAcademicYearId],
      })

      const criteria = periodsSelectors.getCriteria({
        archived: 0,
        nurseryAcademicYearId: params.nurseryAcademicYearId,
        type: Type.closure,
      })

      periodsActions.list({
        params: {
          criteria,
          groups: PERIOD_GROUPS,
        },
      })
    }

    return () => {
      periodsActions.clearList()
      nurseryAcademicYearsActions.clear()
      nurseryAcademicYearsActions.clearList()
    }
  }, [])

  useEffect(() => {
    if (nurseryAcademicYearList?.length && nurseryAcademicYear && periods && !initialValues) {
      setInitialValues(getInitialValues(periods, nurseryAcademicYear))
    }
  }, [nurseryAcademicYearList, nurseryAcademicYear, periods])

  const academicYearOptions = useMemo(() => {
    if (nurseryAcademicYearsListState.data) {
      return getAcademicYearOptions(nurseryAcademicYearsListState.data)
    }

    return null
  }, [nurseryAcademicYearsListState.data])

  const handleSubmitSuccess = (closurePeriods) => ({ data }) => {
    setIsSubmitting(false)

    const hasError = data.some(({ exception }) => !!exception)

    if (!hasError) {
      const mode = params.nurseryAcademicYearId
        ? _.lowerCase(i18n.t('global:Updated'))
        : _.lowerCase(i18n.t('global:Added'))

      snackbarActions.show({
        message: i18n.t('module:Management:ClosureDays:ClosureDates:Add:snackbarLabel', { mode }),
      })

      navigate(params.nurseryAcademicYearId
        ? generateRoute('MANAGEMENT.CLOSURE_DAYS.CLOSURE_DATES.PREVIEW', {
          nurseryAcademicYearId: params.nurseryAcademicYearId,
        })
        : generateRoute('MANAGEMENT.CLOSURE_DAYS.CLOSURE_DATES'))

      return
    }

    const formErrors = getFormErrors(data, closurePeriods)

    stopSubmitForm(formErrors)
  }

  const submit = ({
    closurePeriods,
    formNurseryAcademicYear,
  }) => {
    setIsSubmitting(true)

    const body = periodsSelectors.getBatchBody({
      closurePeriods,
      deletedItems,
      nurseryAcademicYear: formNurseryAcademicYear,
      nurseryId: nurseryOptions.id,
      periods,
    })

    periodsActions.batch({
      body,
      onFailed: () => setIsSubmitting(false),
      onSuccess: handleSubmitSuccess(closurePeriods),
    })
  }

  const handleSubmit = ({ closurePeriods, nurseryAcademicYear: formNurseryAcademicYear }) => {
    if (params.nurseryAcademicYearId) {
      return modalActions.show<ModalType.CONFIRM>(ModalType.CONFIRM, {
        confirmButtonLabel: i18n.t('global:Update'),
        icon: 'warning',
        onConfirm: () => submit({ closurePeriods, formNurseryAcademicYear }),
        text: i18n.t('module:Management:ClosureDays:ClosureDates:Add:updateText'),
      })
    }

    return submit({ closurePeriods, formNurseryAcademicYear })
  }

  const handleDeleteItemClick = (fields, index) => {
    const item = fields.get(index)

    if (item.id) {
      setDeletedItems((oldValue) => [...oldValue, item.id])
    }

    fields.remove(index)
  }

  const handleDelete = () => {
    const { nurseryAcademicYear: formNurseryAcademicYear } = initialValues

    setIsSubmitting(true)

    const body = periodsSelectors.getBatchBody({
      deletedItems: initialValues.closurePeriods.map(({ id }) => id),
      nurseryAcademicYear: formNurseryAcademicYear,
      nurseryId: nurseryOptions.id,
      periods,
    })

    periodsActions.batch({
      body,
      onFailed: () => setIsSubmitting(false),
      onSuccess: () => {
        const mode = _.lowerCase(i18n.t('global:Deleted'))

        snackbarActions.show({
          message: i18n.t('module:Management:ClosureDays:ClosureDates:Add:snackbarLabel', { mode }),
        })

        navigate(generateRoute('MANAGEMENT.CLOSURE_DAYS.CLOSURE_DATES'))
      },
    })
  }

  const handleDeleteClick = () => {
    modalActions.show<ModalType.CONFIRM>(ModalType.CONFIRM, {
      confirmButtonLabel: i18n.t('global:Delete'),
      icon: 'trash',
      onConfirm: handleDelete,
      text: i18n.t('module:Management:ClosureDays:ClosureDates:Add:deleteText', {
        academicYear: nurseryAcademicYear.name,
      }),
    })
  }

  return (
    <AddView
      academicYearOptions={academicYearOptions}
      initialValues={initialValues}
      isEdit={isEdit}
      isLoading={isFetching}
      isSubmitting={isSubmitting}
      nurseryAcademicYearId={params.nurseryAcademicYearId}
      onDeleteClick={handleDeleteClick}
      onDeleteItemClick={handleDeleteItemClick}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withRouter,
  withAppService,
  withPeriodsService,
  withNurseryAcademicYearsService,
  withModalService,
  withSnackbarService,
  connector,
)

export default enhance(AddContainer)
