import React from 'react'

import { Page } from 'components'

const ChildFinanceView = ({ children }) => (
  <Page>
    <Page.Content>
      {children}
    </Page.Content>
  </Page>
)

export default ChildFinanceView
