import styled from 'styled-components'

import React from 'react'

import layout from 'constants/layout'
import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledList = styled.div`
  margin: 10px 0;
`

export const StyledColumn = styled.div`
  display: flex;
  height: 100%;
  background: ${({ $background }) => $background || '#F5F5F5'};
  padding: ${({ $noPadding }) => ($noPadding ? 0 : 15)}px;
  flex-direction: column;
  overflow: hidden;
  word-break: break-word;
  
  ${({ $noAlign }) => !$noAlign && `
    justify-content: center;
    align-items: center;
  `}
`

export const StyledContentColumn = styled(StyledColumn)`
  grid-area: content;
  max-width: 100%;
`

interface StyledRowProps {
  $customContent?: React.ReactNode
  $existActionColumn?: boolean
  $existBorderColumn?: boolean
  $existLeftColumn?: boolean
  $onlyContentExists?: boolean
}

export const StyledRow = styled.article<StyledRowProps>`
  margin-bottom: 20px;
  display: grid;
  align-items: center;
  min-height: 140px;
  grid-template-columns: 22px 120px auto 100px;
  grid-template-areas: 'border child content action';
  
  @media(max-width: ${layout.mobileWidthResolution}px) {
    grid-template-columns: 22px 120px auto 80px;
    grid-template-rows: 80px auto;
    grid-template-areas: 'border child child child'
                         'border content content action';
  }
  
  ${({ $existLeftColumn }) => !$existLeftColumn && `
    grid-template-columns: 22px auto 100px;
    grid-template-areas: 'border content action';
    
    @media(max-width: ${layout.mobileWidthResolution}px) {
      grid-template-columns: 22px auto 80px;
      grid-template-rows: auto;
      grid-template-areas: 'border content action';
    }
  `}
  
  ${({ $customContent }) => $customContent && `
    grid-template-columns: 22px 120px auto;
    grid-template-areas: 'border child content';
    
    @media(max-width: ${layout.mobileWidthResolution}px) {
      grid-template-columns: 22px 120px auto;
      grid-template-rows: 80px auto;
      grid-template-areas: 'border child child'
                           'border content content';
    }
  `}

  ${({ $existBorderColumn }) => !$existBorderColumn && `
    grid-template-columns: 120px auto 100px;
    grid-template-areas: 'child content action';
    
    @media(max-width: ${layout.mobileWidthResolution}px) {
      grid-template-columns: 120px auto 80px;
    }
  `}
  
  ${({ $existActionColumn }) => !$existActionColumn && `
    grid-template-columns: 22px 120px auto;
    grid-template-areas: 'border child content';
    
    @media(max-width: ${layout.mobileWidthResolution}px) {
      grid-template-columns: 22px 120px auto;
      grid-template-rows: 80px auto;
      grid-template-areas: 'border child child'
                           'border content content';
    }
  `}

  ${({ $existBorderColumn, $existLeftColumn }) => !$existLeftColumn && !$existBorderColumn && `
    grid-template-columns: auto 100px;
    grid-template-areas: 'content action';
    
    @media(max-width: ${layout.mobileWidthResolution}px) {
      grid-template-columns: auto 80px;
      grid-template-rows: auto;
    }
  `}
  
  ${({ $existActionColumn, $existBorderColumn }) => !$existActionColumn && !$existBorderColumn && `
    grid-template-columns: 120px auto;
    grid-template-areas: 'child content';
    
    @media(max-width: ${layout.mobileWidthResolution}px) {
      grid-template-columns: 120px auto;
      grid-template-rows: 80px auto;
      grid-template-areas: 'child'
                           'content';
    }
  `}
  
  ${({ $onlyContentExists }) => $onlyContentExists && `
    grid-template-columns: 180px auto;
    grid-template-areas: 'child content';
    
    @media(max-width: ${layout.mobileWidthResolution}px) {
      grid-template-columns: auto;
      grid-template-rows: 80px auto;
      grid-template-areas: 'child'
                           'content';
    }
        
    ${StyledContentColumn} {
      overflow: visible;
    }
  `}
  
  ${({ $customContent, $existLeftColumn }) => $customContent && !$existLeftColumn && `
    grid-template-columns: 22px auto;
    grid-template-areas: 'border content';
    
    @media(max-width: ${layout.mobileWidthResolution}px) {
      grid-template-columns: 22px auto;
      grid-template-rows: 80px auto;
      grid-template-areas: 'border content';
    }
  `}

  ${({
    $customContent,
    $existBorderColumn,
    $existLeftColumn,
  }) => $customContent && $existLeftColumn && !$existBorderColumn && `
    grid-template-columns: 180px auto;
    grid-template-areas: 'child content';

    @media(max-width: ${layout.mobileWidthResolution}px) {
      display: block;
    }
  `}

  ${({
    $customContent,
    $existBorderColumn,
    $existLeftColumn,
  }) => $customContent && !$existLeftColumn && !$existBorderColumn && `
    display: block;
  `}
`

export const StyledBorderColumn = styled(StyledColumn)`
  grid-area: border;
`

export const StyledChildColumn = styled(StyledColumn)`
  grid-area: child;
  
  @media(max-width: ${layout.mobileWidthResolution}px) {
    align-items: flex-start;
  }
`

export const StyledActionColumn = styled(StyledColumn)`
  grid-area: action;
`

export const StyledVerticalText = styled.p`
  color: ${NEUTRAL_COLOURS.WHITE};
  transform: rotate(-90deg);
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
`

interface StyledTitleContainerProps {
  $withIcon?: boolean
}

export const StyledTitleContainer = styled.div<StyledTitleContainerProps>`
  display: flex;
  align-items: center;
  margin-top: ${({ $withIcon }) => ($withIcon ? -6 : 0)}px;
  margin-bottom: 10px;
`

export const StyledTitle = styled.p`
  margin: 0 8px 0 0;
  display: flex;
  
  & > * {
    margin: inherit;
  }
`

export const StyledIconContainer = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: ${NEUTRAL_COLOURS.WHITE};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`

interface StyledContentProps {
  $contentFull?: boolean
}

export const StyledContent = styled.div<StyledContentProps>`
  margin: 0;

  ${({ $contentFull }) => $contentFull && `
    height: 100%;
    min-height: 100%;
  `}
`

interface StyledSubContentProps {
  $mainContentExists?: boolean
}

export const StyledSubContent = styled.div<StyledSubContentProps>`
  white-space: pre-line;
  max-height: 108px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 780px;
  display: inline-block;

  ${({ $mainContentExists }) => $mainContentExists && `
    margin-top: 10px;
  `}
`

export const StyledAction = styled.div`
  margin-right: 8px;
`

interface StyledExtraTitleProps {
  $color?: string
}

export const StyledExtraTitle = styled.p<StyledExtraTitleProps>`
  margin: 0;
  
  ${({ $color }) => $color && `
    color: ${$color};
  `}
`
