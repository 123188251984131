import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as financeDepositsReportSelectors from './selectors'

const withFinanceDepositsReportService = (WrappedComponent) => {
  const mapState = ({ financeDepositsReport }) => ({ financeDepositsReport })

  const mapDispatch = injectActions('financeDepositsReportActions', actions)

  const Component = ({ financeDepositsReport, financeDepositsReportActions, ...others }) => (
    <WrappedComponent
      financeDepositsReportActions={financeDepositsReportActions}
      financeDepositsReportChildrenState={financeDepositsReport.children}
      financeDepositsReportNurseriesState={financeDepositsReport.nurseries}
      financeDepositsReportSelectors={financeDepositsReportSelectors}
      financeDepositsReportStatisticsState={financeDepositsReport.statistics}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withFinanceDepositsReportService
