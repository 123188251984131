import _ from 'lodash'

import React from 'react'

import { RECURRING_PAYMENT_METHOD_LABEL } from 'services/nurseryIntegrations/constants'

import { generateRoute } from 'utils/routing'

import { getNurseryPublicUrl } from 'services/nurseries/single/selectors/single'

import { Avatar, Currency, DateString, Tooltip, Typography } from 'components'

export const getTableData = ({ data, hasError, isOrganizationContext }) => {
  if (!data?.length || hasError) {
    return null
  }

  return _.map(data, ({
    amount,
    child,
    failedReason,
    failedReasonDescription,
    paymentMethod,
    paymentRunDate,
    ...rest
  }) => {
    const { codeReference, firstName, id: childId, nursery, photo, surname } = child || {}
    const { name: nurseryName } = nursery || {}

    const str = failedReason.replace(/_/g, ' ')
    const finalFailedReason = str.charAt(0).toUpperCase() + str.slice(1)
    let avatarLink = generateRoute('CHILDREN.CHILD.FINANCE.INVOICING', { childId })

    if (isOrganizationContext) {
      avatarLink = `${getNurseryPublicUrl(nursery)}${avatarLink}`
    }

    return {
      ...rest,
      amount: <Currency value={amount} />,
      child: (
        <Avatar
          avatarSize="small"
          initials={[firstName, surname]}
          src={photo}
          target={isOrganizationContext ? '_blank' : '_self'}
          title={(
            <Typography fontSize={14} primary>
              {`${firstName} ${surname}`}
            </Typography>
          )}
          to={avatarLink}
        />
      ),
      codeReference,
      failedReason: <Tooltip title={failedReasonDescription}>{finalFailedReason}</Tooltip>,
      nursery: nurseryName,
      paymentMethod: RECURRING_PAYMENT_METHOD_LABEL[paymentMethod],
      paymentRunDate: <DateString date={paymentRunDate} />,
    }
  })
}
