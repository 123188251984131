import React, { useEffect } from 'react'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import {
  ALLERGY_OPTIONS,
  ALLERGY_TYPES,
  DIETARY_NO_PREFERENCE,
  DIETARY_OTHER,
  DIETARY_REQUIREMENTS_OPTIONS,
  GENDER_DROPDOWN,
} from 'services/legacy/child/constants'

import { isRequired } from 'utils/fieldValidation'

import { Form, Section } from 'components'

import i18n from 'translations'

import { CHILD_ADD_FORM } from '../../components/ChildForms/constants'

const GeneralInformationForm = ({
  allergies,
  cancelLink,
  dietaryReq,
  handleSubmit,
  isFinanceV3Enabled,
  isSubmitting,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const renderAttendanceDetail = () => (
    <Section title={i18n.t('module:Children:Child:Add:EnrolmentDetails:Sections:attendance')}>
      <Form.Row
        label={i18n.t('module:Children:Child:Add:EnrolmentDetails:Subsections:Attendance:Form:StartDate:label')}
        required
      >
        <Form.Row.Item>
          <Field
            component={Form.DatePicker}
            name="startDate"
            placeholder={
                i18n.t('module:Children:Child:Add:EnrolmentDetails:Subsections:Attendance:Form:StartDate:placeholder')
              }
            validate={isRequired}
          />
        </Form.Row.Item>
      </Form.Row>
      <Form.Row
        label={
            i18n.t('module:Children:Child:Add:EnrolmentDetails:Subsections:Attendance:Form:RegistrationDate:label')
          }
      >
        <Form.Row.Item>
          <Field
            component={Form.DatePicker}
            name="registeredAt"
            placeholder={i18n.t(
              'module:Children:Child:Add:EnrolmentDetails:Subsections:Attendance:Form:RegistrationDate:placeholder',
            )}
          />
        </Form.Row.Item>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Children:Child:Add:EnrolmentDetails:Subsections:Attendance:Form:Room:label')}
        required
      >
        <Form.Row.Item>
          <Field
            component={Form.InfiniteDropdowns.Rooms}
            name="nurseryClass"
            placeholder={
                i18n.t('module:Children:Child:Add:EnrolmentDetails:Subsections:Attendance:Form:Room:placeholder')
              }
            validate={isRequired}
          />
        </Form.Row.Item>
      </Form.Row>
    </Section>
  )

  const renderForm = () => (
    <React.Fragment>
      <Section title={i18n.t('module:Children:Child:Add:GeneralInformation:Sections:personalInformation')}>
        <Form.Row
          label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:FirstName:label')}
          required
        >
          <Form.Row.FlexItem mobileFull>
            <Field
              component={Form.TextField}
              name="firstName"
              placeholder={i18n.t('module:Children:Child:Add:GeneralInformation:Form:FirstName:placeholder')}
              validate={isRequired}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row
          label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:MiddleName:label')}
        >
          <Form.Row.FlexItem mobileFull>
            <Field
              component={Form.TextField}
              name="middleNames"
              placeholder={i18n.t('module:Children:Child:Add:GeneralInformation:Form:MiddleName:placeholder')}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row
          label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:Surname:label')}
          required
        >
          <Form.Row.FlexItem mobileFull>
            <Field
              component={Form.TextField}
              name="surname"
              placeholder={i18n.t('module:Children:Child:Add:GeneralInformation:Form:Surname:placeholder')}
              validate={isRequired}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row
          label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:DisplayName:label')}
          required
        >
          <Form.Row.FlexItem mobileFull>
            <Field
              component={Form.TextField}
              name="displayName"
              placeholder={i18n.t('module:Children:Child:Add:GeneralInformation:Form:DisplayName:placeholder')}
              validate={isRequired}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row
          label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:Gender:label')}
          required
        >
          <Form.Row.FlexItem flex="0 0 170px">
            <Field
              component={Form.Select}
              name="gender"
              options={GENDER_DROPDOWN}
              validate={isRequired}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row
          label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:DateOfBirth:label')}
          required
        >
          <Form.Row.Item>
            <Field
              component={Form.DatePicker}
              name="birthDate"
              placeholder={i18n.t('module:Children:Child:Add:GeneralInformation:Form:DateOfBirth:placeholder')}
              validate={isRequired}
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row
          label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:Address:label')}
          required
        >
          <Form.Row.FlexItem mobileFull>
            <Field
              component={Form.TextAreaField}
              name="information.address"
              placeholder={i18n.t('module:Children:Child:Add:GeneralInformation:Form:Address:placeholder')}
              validate={isRequired}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row
          label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:Postcode:label')}
          required
        >
          <Form.Row.FlexItem mobileFull>
            <Field
              component={Form.TextField}
              name="information.postcode"
              placeholder={i18n.t('module:Children:Child:Add:GeneralInformation:Form:Postcode:placeholder')}
              validate={isRequired}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row
          label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:ReferenceCode:label')}
        >
          <Form.Row.FlexItem mobileFull>
            <Field
              component={Form.TextField}
              name="codeReference"
              placeholder={i18n.t('module:Children:Child:Add:GeneralInformation:Form:ReferenceCode:placeholder')}
            />
          </Form.Row.FlexItem>
        </Form.Row>
      </Section>
      <Section title={i18n.t('module:Children:Child:Add:GeneralInformation:Sections:allergiesPreferences')}>
        <Form.Row
          label={i18n.t('module:Children:Child:Add:HealthAndDiet:Subsections:Diet:Form:DietaryPreference:label')}
          required
        >
          <Form.Row.Item>
            <Field
              component={Form.Select}
              name="information.dietaryReq"
              options={DIETARY_REQUIREMENTS_OPTIONS}
              placeholder={
                i18n.t('module:Children:Child:Add:HealthAndDiet:Subsections:Diet:Form:DietaryPreference:placeholder')
              }
              validate={isRequired}
            />
          </Form.Row.Item>
        </Form.Row>
        {dietaryReq && DIETARY_NO_PREFERENCE !== dietaryReq?.value && (
          <Form.Row
            label={i18n.t('module:Children:Child:Add:HealthAndDiet:Subsections:Diet:Form:AdditionalNotes:label')}
            required={DIETARY_OTHER === dietaryReq?.value}
          >
            <Form.Row.FlexItem mobileFull>
              <Field
                component={Form.TextAreaField}
                name="information.dietaryReqDesc"
                placeholder={
                  i18n.t('module:Children:Child:Add:HealthAndDiet:Subsections:Diet:Form:AdditionalNotes:placeholder')
                }
                validate={DIETARY_OTHER === dietaryReq?.value && isRequired}
              />
            </Form.Row.FlexItem>
          </Form.Row>
        )}
        <Form.Row
          label={i18n.t('module:Children:Child:Add:HealthAndDiet:Subsections:Health:Form:Allergies:label')}
        >
          <Form.Row.Item>
            <Field
              component={Form.Select}
              name="information.allergies"
              options={ALLERGY_OPTIONS}
              placeholder={
                i18n.t('module:Children:Child:Add:HealthAndDiet:Subsections:Health:Form:Allergies:placeholder')
              }
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row label>
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              label={i18n.t(
                'module:Children:Child:Add:HealthAndDiet:Subsections:Health:Form:CanConsumeFoodWithNutTraces:label',
              )}
              name="information.canConsumeFoodWithNutTraces"
            />
          </Form.Row.Item>
        </Form.Row>
        {ALLERGY_TYPES.HAS_ALLERGY === allergies?.value && (
          <Form.Row
            label={i18n.t('module:Children:Child:Add:HealthAndDiet:Subsections:Health:Form:AllergiesDetails:label')}
            required
          >
            <Form.Row.FlexItem mobileFull>
              <Field
                component={Form.TextAreaField}
                name="information.allergiesDetails"
                placeholder={
                  i18n.t('module:Children:Child:Add:HealthAndDiet:Subsections:Health:Form:AllergiesDetails:placeholder')
                }
                validate={isRequired}
              />
            </Form.Row.FlexItem>
          </Form.Row>
        )}
      </Section>
      {isFinanceV3Enabled && renderAttendanceDetail()}
    </React.Fragment>
  )

  const renderFooter = () => (
    <Form.FooterActions
      cancelLink={cancelLink}
      isSubmitting={isSubmitting}
      submitLabel={i18n.t('global:Next')}
    />
  )

  return (
    <Form onSubmit={handleSubmit}>
      {renderForm()}
      {renderFooter()}
    </Form>
  )
}

const mapState = (state) => ({
  allergies: formValueSelector(CHILD_ADD_FORM)(state, 'information.allergies'),
  dietaryReq: formValueSelector(CHILD_ADD_FORM)(state, 'information.dietaryReq'),
})

const enhance = compose(
  reduxForm({ destroyOnUnmount: false, form: CHILD_ADD_FORM }),
  connect(mapState),
)

export default enhance(GeneralInformationForm)

