import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

interface StyledFolderListContainerProps {
  $noBorder?: boolean
  $smallPadding?: boolean
}

export const StyledFolderListContainer = styled.div<StyledFolderListContainerProps>`
  display: grid;
  grid-row-gap: ${({ $smallPadding }) => ($smallPadding ? 5 : 15)}px;
  
  & a ~ a {
    padding-top: 15px;
    ${({ $noBorder }) => !$noBorder && `
      border-top: 1px dashed #E3E3E3;
    `} 
  }

  &:last-child {
    padding-bottom: 15px;
  }
`

export const StyledListItem = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 42px 1fr auto;
  align-items: center;
`

export const StyledIconContainer = styled.div`
  position: relative;

  & > a {
    min-width: 42px;
    min-height: 42px;
    width: 42px;
    height: 42px;
  }
`

export const StyledTitleContainer = styled.div`
  color: ${NEUTRAL_COLOURS.BASIC};
  font-size: 18px;
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: auto 1fr;
  align-items: center;
`

export const StyledActionsContainer = styled.div`
  display: flex;
`

export const StyledBadgeContainer = styled.div`
  position: absolute;
  top: -4px;
  right: -2px;
  font-size: 13px;
  font-weight: 600;
  color: ${NEUTRAL_COLOURS.WHITE};
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  padding: 2px;
`
