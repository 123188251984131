import styled from 'styled-components'

const PAGE_BOTTOM_BAR_HEIGHT = 65

interface StyledContainerProps {
  $alwaysExpanded?: boolean
  $expanded?: boolean
  $subMenu?: boolean
}

export const StyledPageContainer = styled.div`
  flex: 1;
  padding: 20px;
  width: 100%;
  
  @media(max-width: 480px) {
    padding: 10px;
  }
`

export const StyledExpandPageButtonContainer = styled.div`
  display: none;

  @media(min-width: 1025px) {
    display: block;
  }
`

export const StyledPlaceFiller = styled.div`
  height: ${PAGE_BOTTOM_BAR_HEIGHT}px;
`

export const StyledBottomBarContainer = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  height: ${PAGE_BOTTOM_BAR_HEIGHT}px;
  width: 100vw;
  background: #F5F5F5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  z-index: 40;
`

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
`

export const StyledBreadcrumb = styled.div``

export const StyledContainer = styled.div<StyledContainerProps>`
  flex: 1;
  display: flex;
  
  ${({ $alwaysExpanded, $subMenu }) => $alwaysExpanded && `
    ${StyledBreadcrumb} {
      padding: 0 120px;
      
      @media(max-width: 1450px) {
        padding: 0;
      
        ${$subMenu && `
          padding-left: 80px;
          
          @media(max-width: 1260px) {
            padding-left: 0;
          }
        `}
      }
    }
  
    ${StyledPageContainer} {
      padding: 20px 140px;
      
      @media(max-width: 1450px) {
        padding: 20px;
      }

      ${$subMenu && `
        padding-left: 87px;
        
        @media(max-width: 1260px) {
          padding-left: 20px;
        }
      `}
    }
  `}
  
  ${({ $expanded, $subMenu }) => $expanded && $subMenu && `
    ${StyledPageContainer}, ${StyledBreadcrumb} {
      padding-left: 87px;
    }
  `}
`
