import moment from 'moment'
import React from 'react'

import RegisterItemMainActions from '../RegisterItemMainActions'
import RegisterItemTimeField from '../RegisterItemTimeField'

import { REGISTER_COLORS } from '../../../constants'

const RegisterItemContentEditSignin = ({
  index,
  onTimeChange,
  signIn,
  size,
}) => {
  const { id, signedInAt, signedOutAt } = signIn

  return (
    <RegisterItemMainActions.ItemWrapper key={id}>
      <RegisterItemMainActions.Item size={size}>
        <RegisterItemTimeField
          color={REGISTER_COLORS.timeIn}
          label="Time in"
          value={signedInAt ? moment(signedInAt).valueOf() : null}
          onChange={onTimeChange('signedInAt', index)}
        />
      </RegisterItemMainActions.Item>
      {signedOutAt && (
        <RegisterItemMainActions.Item size={size}>
          <RegisterItemTimeField
            color={REGISTER_COLORS.timeOut}
            label="Time out"
            value={signedOutAt ? moment(signedOutAt).valueOf() : null}
            onChange={onTimeChange('signedOutAt', index)}
          />
        </RegisterItemMainActions.Item>
      )}
    </RegisterItemMainActions.ItemWrapper>
  )
}

export default RegisterItemContentEditSignin
