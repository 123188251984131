import React from 'react'

import { StyledContainer } from './ContentContainerStyled'

const ContentContainer = ({ children }) => (
  <StyledContainer>
    {children}
  </StyledContainer>
)

export default ContentContainer
