import React from 'react'

import { Avatar } from 'components'

import ConfirmModal from '../../../modals/ConfirmModal'
import { StyledContentContainer } from './RegisterResetModalStyled'

const RegisterResetModal = ({ child, hideModal, onConfirm }) => {
  const { firstName, photo, surname } = child

  const content = (
    <StyledContentContainer>
      <Avatar
        initials={[firstName, surname]}
        src={photo}
      />
    </StyledContentContainer>
  )

  return (
    <ConfirmModal
      confirmButtonLabel="Reset"
      content={content}
      hideModal={hideModal}
      text="Are you sure you want to reset the register for"
      onConfirm={onConfirm}
    />
  )
}

export default RegisterResetModal
