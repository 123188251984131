import { generateBasicActions } from 'utils/service'

import {
  UPDATE_FRAMEWORK_CHILD_OBSERVATION_PROGRESS,
  UPDATE_FRAMEWORK_CHILD_OBSERVATION_PROGRESS_FAILED,
  UPDATE_FRAMEWORK_CHILD_OBSERVATION_PROGRESS_SUCCESS,
  UPDATE_MANUALLY_FRAMEWORK_CHILD_OBSERVATION_PROGRESS,
} from './constants'

export const updateObservationFrameworkProgress = (options = {}) => ({ childObservationsApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_FRAMEWORK_CHILD_OBSERVATION_PROGRESS_FAILED,
      init: UPDATE_FRAMEWORK_CHILD_OBSERVATION_PROGRESS,
      success: UPDATE_FRAMEWORK_CHILD_OBSERVATION_PROGRESS_SUCCESS,
    },
    options,
    service: childObservationsApiClient,
    serviceMethod: 'updateObservationFrameworkProgress',
  })
)

export const manuallyUpdateObservationFrameworkProgress = (payload) => ({
  payload,
  type: UPDATE_MANUALLY_FRAMEWORK_CHILD_OBSERVATION_PROGRESS,
})
