import { createSelector } from 'reselect'

const getFinanceReports = (state) => state.financeBalanceReport

export const getFinanceBalanceReportNurseries = createSelector(
  [getFinanceReports],
  (state) => state.nurseries,
)

export const getFinanceBalanceReportNurseriesData = createSelector(
  [getFinanceBalanceReportNurseries],
  (state) => state.data,
)
