import styled from 'styled-components'

import colors, { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'
import { ZINDEX_ORDER } from 'constants/layout'

import { getBrandingColor } from 'utils/branding'

import { Select } from './SelectHelpers'

export const VISUAL_TYPE = {
  MODERN: 'modern',
  TRANSPARENT: 'transparent',
}

interface StyledSelectContainerProps {
  $disabled?: boolean
  $fullWidth?: boolean
  $specificWidth?: number
}

export const StyledSelectContainer = styled.div<StyledSelectContainerProps>`
  ${({ $fullWidth }) => $fullWidth && `
    width: 100%;
  `}  
  
  ${({ $specificWidth }) => $specificWidth && `
    width: ${$specificWidth}px;
  `}
    
  ${({ $disabled }) => $disabled && `
    cursor: not-allowed;
  `}

  @media(max-width: 780px) {
    width: 100%;
  
    & > div {
      width: 100%;
    }
  }
`

export const StyledSelect = styled(Select)`
  & .Select__indicator-separator {
    background: transparent;
  }

  & .Select__single-value {
    color: #000;

    ${({ $visualType }) => VISUAL_TYPE.TRANSPARENT === $visualType && `
      margin: 0;
    `}
  }
  
  & .Select__placeholder {
    color: #CACACA;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100% - 15px);
    white-space: nowrap;

    ${({ $visualType }) => VISUAL_TYPE.TRANSPARENT === $visualType && `
      margin: 0;
    `}
  }
  
  & .Select__menu::-webkit-scrollbar-track,
  & .Select__menu-list::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: ${NEUTRAL_COLOURS.WHITE};
  }
  
  & .Select__menu::-webkit-scrollbar,
  & .Select__menu-list::-webkit-scrollbar {
    width: 8px;
    background-color: ${NEUTRAL_COLOURS.WHITE};
  }
  
  & .Select__menu::-webkit-scrollbar-thumb,
  & .Select__menu-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #D8D8D8;
  }
  
  & .Select__dropdown-indicator {
    color: ${NEUTRAL_COLOURS.WHITE};
    background: ${getBrandingColor('primary-color')};
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 2px solid ${NEUTRAL_COLOURS.WHITE};
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;

    &:hover {
      color: ${NEUTRAL_COLOURS.WHITE};
    }
        
    ${({ $visualType }) => [VISUAL_TYPE.MODERN, VISUAL_TYPE.TRANSPARENT].includes($visualType) && `
      background: none;
      color: ${getBrandingColor('primary-color')};
      padding-left: 0;
      
      &:hover {
        color: ${getBrandingColor('primary-color')};
      }
    `}
  }
  
  & .Select__control {
    border-color: ${colors.inputBorderColor};
    box-shadow: none;
    min-width: 180px;
    
    ${({ $visualType }) => VISUAL_TYPE.MODERN === $visualType && `
      border: 2px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};
      border-radius: 0;
      
      &--is-focused {
        border-color: ${getBrandingColor('primary-color')} !important;
      }
    `}

    ${({ $visualType }) => VISUAL_TYPE.TRANSPARENT === $visualType && `
      border: 2px solid transparent;
      border-radius: 0;
      
      &--is-focused {
        border-color: none; !important;
      }
    `}
    
    ${({ $error, $visualType }) => VISUAL_TYPE.MODERN === $visualType && $error && `
      position: relative;
      z-index: 20;
    `}

    ${({ $width }) => $width && `
      min-width: ${$width}px;
    `}

    ${({ $fullWidth }) => $fullWidth && `
      min-width: auto;
      width: 100%;
    `}

    ${({ $visualType }) => VISUAL_TYPE.TRANSPARENT === $visualType && `
      &:hover { 
        border-color: transparent;
      };
    `}

    ${({ $visualType }) => VISUAL_TYPE.TRANSPARENT !== $visualType && `
      &:hover { 
        border-color: ${colors.inputBorderColor};
      };
    `}

    &--menu-is-open {
      .Select__dropdown-indicator svg {
        transform: rotate(180deg);
      }
    }
    
    &--is-disabled {
      cursor: not-allowed;
    
      * {
        cursor: not-allowed;
      }
    
      .Select__dropdown-indicator {
        opacity: .4;
        border-color: #F1F1F1;
      }
    }
  }
  
  & .Select__menu {
    z-index: ${ZINDEX_ORDER.SELECT};
    margin-top: 4px;
    margin-left: 1px;
    
    ${({ $visualType }) => VISUAL_TYPE.MODERN === $visualType && `
      margin-top: 0;
      border-radius: 0;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
    `}
  }
  
  & .Select__option {
    &:active {
      background: ${NEUTRAL_COLOURS.GRAY_TERTIARY};
    }
  
    &--is-focused {
      background: ${NEUTRAL_COLOURS.GRAY_TERTIARY};
    }
    
    &--is-selected {
      background: ${NEUTRAL_COLOURS.GRAY_SECONDARY};
    }
  }
  
  & .Select__menu-notice--no-options {
    color: ${NEUTRAL_COLOURS.GRAY};
    font-weight: 400;
  }
  
  & .Select__clear-indicator {
    color: #999;
    padding: 0;
    cursor: pointer;
  }
  
  & .Select__multi {
    &-value {
      background: #F3F3F3;
      direction: rtl;
      
      &__label {
        color: #565656;
        font-weight: 600;
        font-size: 14px;
        padding: 6px 12px 6px 0;
        direction: ltr;
      }
      
      &__remove {
        cursor: pointer;
        
        &:hover {
          background: none;
        }
      
        svg {
          fill: #E20714;
          width: 22px;
          height: 22px;
        }
      }
    }
  }
  
  & .Select__value-container {
    min-height: 30px;
    
    ${({ $visualType }) => VISUAL_TYPE.TRANSPARENT === $visualType && `
      padding: 0;
    `}

    &--is-multi.Select__value-container--has-value {
      padding: 2px;
    }
  }

  ${({ $error }) => $error && `
    & .Select__control {
      border-color: ${FLAG_COLOURS.ERROR} !important;
      
      ${({ $visualType }) => VISUAL_TYPE.MODERN === $visualType && `
        border-color: ${FLAG_COLOURS.ERROR} !important;
      `}
    }
  `}
`

interface StyledOptionProps {
  $withAvatars?: boolean
}

export const StyledOption = styled.div<StyledOptionProps>`
  display: grid;
  align-items: center;
  grid-column-gap: 10px;
  
  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  ${({ $withAvatars }) => $withAvatars && `
    grid-template-columns: 30px auto;
  `}
`
