import {
  CLEAR_NURSERIES_STARTING_REPORT,
  NURSERIES_STARTING_REPORT,
  NURSERIES_STARTING_REPORT_FAILED,
  NURSERIES_STARTING_REPORT_SUCCESS,
} from 'services/childrenStartingReport/nurseries/constants'

import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

export const getNurseries = (options: BasicActionOptionsProps) => ({
  childrenStartingReportApiClient,
}) => generateBasicActions.list({
  constants: {
    failed: NURSERIES_STARTING_REPORT_FAILED,
    init: NURSERIES_STARTING_REPORT,
    success: NURSERIES_STARTING_REPORT_SUCCESS,
  },
  options,
  service: childrenStartingReportApiClient,
  serviceMethod: 'getNurseriesStarting',
})

export const clearNurseries = () => ({
  type: CLEAR_NURSERIES_STARTING_REPORT,
})
