import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledPageWrapper = styled.div`
  max-width: 700px;  
`

export const StyledStripeWrapper = styled.div`
  display: flex;
  margin-bottom: 25px;
  align-items: center;

  img {
    width: 90px;
    max-width: 90px;
    margin-right: 20px;
  }
  
  @media(max-width: 780px) {
    flex-direction: column;
    grid-template-columns: auto;
    text-align: center;
  }
`

export const StyledWhiteBox = styled.div`
  border-radius: 4px;
  padding: 24px;
  background: ${NEUTRAL_COLOURS.WHITE};
  
  @media(max-width: 550px) {
    padding: 30px 15px 20px;
  }
`

export const StyledInsideBox = styled.div`
  display: grid;
  align-items: center;
  grid-gap: 15px;
  grid-template-columns: 36px 1fr 60px auto;

  @media(max-width: 550px) {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas: 'icon title title title title' 
                         'enabled enabled enabled enabled switch';
    
    & *:nth-child(1) {
      grid-area: icon;
    }
    
    & *:nth-child(2) {
      grid-area: title;
      grid-column-start: 2;
      grid-column-end: 6;
    }
    
    & *:nth-child(3) {
      grid-area: enabled;
    }
    
    & *:nth-child(4) {
      grid-area: switch;
    }
  }
`

export const StyledSwitchWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  grid-gap: 15px;
  padding-right: 22px;
  
  @media(max-width: 550px) {
    display: flex;
    padding-right: 0;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  
  @media(max-width: 480px) {
    margin-top: 10px;
  }
`

export const StyledSwitchBox = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 15px;
  align-items: center;
`

export const StyledTitleHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 25px;
  align-items: center; 
  padding: 0 0 5px 0;
`

export const StyledStatus = styled.div`
  padding: 6px 12px;
  border-radius: 8px;
  width: min-content;
  font-size: 14px;
  white-space: nowrap;
  
  ${({ background }) => background && `
    background: ${background};
  `}
  
  ${({ color }) => color && `
    color: ${color};
  `}
`

export const StyledButtonContainer = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  grid-gap: 15px;
  
  @media(max-width: 700px) {
    grid-template-columns: auto;
    grid-gap: 5px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const StyledReceiveText = styled.div`
  margin-top: 10px;
  
  @media(max-width: 550px) {
    text-align: center;
    margin-top: 20px;
  }
`

export const StyledCardLogosWrapper = styled.div`
  margin: 4px 0 5px -6px;
`

export const StyledCard = styled.div`
  width: 36px;
  height: 24px;
  border-radius: 6px;
  border: 1px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  background: ${NEUTRAL_COLOURS.WHITE};
  background-size: 100%;
  display: inline-block;
  margin: 6px 0 0 6px;

  ${({ $src }) => $src && `
    background-image: url(${$src});
  `}
`

export const StyledCardLogo = styled.div`
  background-size: cover;
  border-radius: 4px;
  border: 1px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  background-color: ${NEUTRAL_COLOURS.WHITE};
  width: 36px;
  height: 24px;
  
  ${({ $src }) => $src && `
    background-image: url(${$src});
  `}
`
