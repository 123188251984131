import styled from 'styled-components'

import colors, { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledContainer = styled.div`
  display: flex;
  overflow: hidden;

  &:not(:first-child) {
    margin-top: 5px;
  }
`

export const StyledIconContainer = styled.div`
  margin: 5px 0;
`

export const StyledIconWrapper = styled.div`
  display: flex;
  background-color: ${colors.lightGray};
  padding: 5px;
  border-radius: 50%;
`

export const StyledSubContainer = styled.div`
  display: flex;  
`

export const StyledItemContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ wrapText }) => wrapText && `
    white-space: normal;
  `}
`

export const StyledContentContainer = styled.div`
  padding: 8px;
  overflow: hidden;

  & ${StyledSubContainer} ~ ${StyledSubContainer} {
    margin-top: 15px;
  }

  & ${StyledItemContainer} ~ ${StyledItemContainer} {
    margin-top: 5px;
  }
`

export const StyledHighlight = styled.span`
  color: #00A3E6;
  ${({ minWidth }) => `min-width: ${minWidth}px;`}
`

export const StyledTitleContainer = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
`

export const StyledSubGroupContentContainer = styled.div`
  padding: 0 5px; 
  overflow: hidden;
`

export const StyledTagWrapper = styled.div`
  margin-right: 5px;
`

export const StyledTagContainer = styled.div`
  display: flex;
  padding: 2px 3px;
  background-color: ${colors.gray};
  color: ${NEUTRAL_COLOURS.SILVER};
  min-width: 25px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`
