export const LEAVE_TYPES = {
  DAILY: 'leave-daily',
  GROUPED: 'leave-grouped',
  TOTAL: 'leave-total',
}

export const LEAVE_SHIFT_TYPES = {
  HOLIDAY: 'holiday',
  OTHER: 'other',
  SICK: 'sickLeave',
}

export const LEAVE_TYPES_COLOURS = {
  [LEAVE_SHIFT_TYPES.HOLIDAY]: {
    basic: '#fCDB7F',
    header: '#FDECBB',
  },
  [LEAVE_SHIFT_TYPES.SICK]: {
    basic: '#FCB6C7',
    header: '#FDD8E1',
  },
  [LEAVE_SHIFT_TYPES.OTHER]: {
    basic: '#FFB2A3',
    header: '#FFE0D9',
  },
}

export const LEAVE_SHIFT_TYPE_ORDER = {
  [LEAVE_SHIFT_TYPES.SICK]: 1,
  [LEAVE_SHIFT_TYPES.HOLIDAY]: 2,
}
