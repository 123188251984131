import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class ChildProductsApiClient extends ServiceBase {
  list = (params) => this.request({
    path: `/v2/booking/child_products?${getQueryStringV2(params)}`,
  })

  get = (id, params) => this.request({
    path: `/v2/booking/child_products/${id}?${getQueryStringV2(params)}`,
  })

  create = (params, payload) => this.request({
    body: payload,
    method: 'POST',
    path: `/v2/booking/child_products?${getQueryStringV2(params)}`,
  })

  update = (id, payload) => this.request({
    body: payload,
    method: 'PUT',
    path: `/v2/booking/child_products/${id}`,
  })
}

export default ChildProductsApiClient
