import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { FEATURE_FLAGS } from 'constants/security'

import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withOrganizationService } from 'services/organization'
import { getAuthOrganization } from 'services/security/selectors'

import SettingsInvoicesDetailsV2View from './SettingsInvoicesDetailsV2View'

const ORGANIZATION_INVOICE_SETTINGS_GROUPS = {
  read: [
    'organization.organizationSettings',
    'organizationSettings.invoice',
    'organizationInvoiceSettings',
    'organizationInvoiceSettings.numbers',
    'invoiceNumbers',
    'organization.bankDetails',
  ],
}

class SettingsInvoicesDetailsV2Container extends Component {
  componentDidMount() {
    const { authOrganization, organizationActions } = this.props

    const apiParams = { groups: ORGANIZATION_INVOICE_SETTINGS_GROUPS }

    organizationActions.get(authOrganization.id, { params: apiParams })
  }

  componentWillUnmount() {
    const { organizationActions } = this.props

    organizationActions.clearSingle()
  }

  handleSubmit = (fields) => (successCallback) => {
    const {
      authOrganization,
      organizationActions,
      organizationSelectors,
      organizationSingleState,
    } = this.props

    const apiParams = { groups: ORGANIZATION_INVOICE_SETTINGS_GROUPS }
    const payload = organizationSelectors.getPayload({
      fields,
      organizationSingleState,
    })

    organizationActions.update(authOrganization.id, {
      onFailed: successCallback,
      onSuccess: successCallback,
      params: apiParams,
      payload,
    })
  }

  render() {
    const {
      authAccessMap,
      errorMessages,
      isFetching,
      organizationInvoiceSettings,
    } = this.props

    return (
      <SettingsInvoicesDetailsV2View
        authAccessMap={authAccessMap}
        errorMessages={errorMessages}
        invoicesSettings={organizationInvoiceSettings}
        isLoading={isFetching}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  organizationSelectors,
  organizationSingleState,
}) => ({
  authAccessMap: {
    section: {
      FinanceAutomation: auth.SELECTORS.getIsAuthorised(state, {
        flags: [FEATURE_FLAGS.FINANCE_AUTOMATION],
      }),
    },
  },
  authOrganization: getAuthOrganization(state),
  errorMessages: appSelectors.getErrorMessages(organizationSingleState),
  isFetching: appSelectors.getIsFetching(organizationSingleState),
  organizationInvoiceSettings: organizationSelectors.getOrganizationInvoiceSettings(state),
})

const enhance = compose(withAppService, withOrganizationService, connect(mapState))

export default enhance(SettingsInvoicesDetailsV2Container)
