import _ from 'lodash'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'

import RequestableExtraSessionsView from './RequestableExtraSessionsView'

const GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings.requestExtraSession',
    'requestExtraSessionSettings',
    'requestableSessionsSettings',
  ],
}

class RequestableExtraSessionsContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isEnabled: false,
      neverEnabled: false,
      reDisabled: false,
      reEnabled: false,
    }
  }

  componentDidMount() {
    const { nurseriesActions, nurseryOptions } = this.props

    nurseriesActions.get(nurseryOptions.id, {
      onSuccess: this.handleGetNurserySuccess,
      params: { groups: GROUPS },
    })
  }

  componentWillUnmount() {
    const { nurseriesActions } = this.props

    nurseriesActions.clearSingle()
  }

  handleGetNurserySuccess = ({ data }) => {
    const { nurserySettings } = data || {}
    const { requestExtraSession } = nurserySettings || {}

    this.setState({
      isEnabled: requestExtraSession?.enable || false,
      neverEnabled: _.isEmpty(requestExtraSession),
    })
  }

  handleToggleChange = () => {
    const {
      nurseriesActions,
      nurseriesSelectors,
      nurseryOptions,
      nurserySettings,
    } = this.props
    const { isEnabled } = this.state

    const payload = nurseriesSelectors.getEnableRequestableExtraSessionsPayload({
      enable: !isEnabled,
      nurserySettings,
    })

    nurseriesActions.update(nurseryOptions.id, {
      onSuccess: this.handleUpdateNurserySuccess,
      params: { groups: GROUPS },
      payload,
    })
  }

  handleUpdateNurserySuccess = () => {
    this.setState(({ isEnabled, neverEnabled }) => ({
      isEnabled: !isEnabled,
      neverEnabled: false,
      reDisabled: isEnabled,
      reEnabled: !neverEnabled && !isEnabled,
    }))
  }

  render() {
    const { isFetching, isSubmitting } = this.props
    const {
      isEnabled,
      neverEnabled,
      reDisabled,
      reEnabled,
    } = this.state

    return (
      <RequestableExtraSessionsView
        isEnabled={isEnabled}
        isFetching={isFetching}
        isSubmitting={isSubmitting}
        neverEnabled={neverEnabled}
        reDisabled={reDisabled}
        reEnabled={reEnabled}
        onToggleChange={this.handleToggleChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseriesSelectors,
  nurseriesSingleState,
  params,
}) => ({
  errorMessages: appSelectors.getErrorMessages(nurseriesSingleState),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState),
  isSubmitting: appSelectors.getIsSubmitting(nurseriesSingleState),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  nurserySettings: nurseriesSelectors.getNurserySettings(state),
})

const enhance = compose(
  withAppService,
  withNurseriesService,
  connect(mapState),
)

export default enhance(RequestableExtraSessionsContainer)
