import styled from 'styled-components'

import colors, { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

interface StyledContainerProps {
  $disabled?: boolean
}

export const StyledContainer = styled.div<StyledContainerProps>`
  .MuiTouchRipple-root {
    display: none;
  }

  .MuiSwitch-root {
    width: 47px;
    height: 26px;
    padding: 1px;
  }
  
  .MuiSwitch-switchBase {
    color: #CCC;
    padding: 0;
    margin: 3px;
  }

  .MuiSwitch-track {
    border-radius: 12px;
    border: 1px solid rgb(204, 204, 204) !important;
    background: ${NEUTRAL_COLOURS.WHITE} !important;
    opacity: 1 !important;
  }

  ${({ $disabled }) => $disabled && `
    cursor: not-allowed;
    
    .MuiSwitch-track {
      background: ${colors.disabled} !important;
    }
  `}

  .MuiSwitch-thumb {
    box-shadow: none;
  }

  .MuiSwitch-switchBase.Mui-checked {
    color: ${getBrandingColor('primary-color')};
    transform: translateX(100%);
  }
`
