import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as roomsSelectors from './selectors'

const withRoomsService = (WrappedComponent) => {
  const mapState = ({ newRooms }) => ({ newRooms })

  const mapDispatch = injectActions('roomsActions', actions)

  const Component = ({ newRooms, roomsActions, ...others }) => (
    <WrappedComponent
      roomsActions={roomsActions}
      roomsListState={newRooms.list}
      roomsSelectors={roomsSelectors}
      roomsSingleState={newRooms.single}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withRoomsService
