import React, { PropsWithChildren } from 'react'

import { StyledPageContainer } from './PageStyled'

const PageContent: React.FC<PropsWithChildren<any>> = ({ children }) => (
  <StyledPageContainer>
    {children}
  </StyledPageContainer>
)

export default PageContent
