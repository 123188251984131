import React from 'react'
import { WrappedFieldProps } from 'redux-form'

import { PinpadProps } from 'components/Pinpad/Pinpad'

import { Pinpad } from 'components'

type FormPinpadFullProps = PinpadProps & WrappedFieldProps

const FormPinpad: React.FC<FormPinpadFullProps> = ({
  input: { onChange, value },
  meta: { error, submitFailed, valid },
  ...rest
}) => (
  <Pinpad
    {...rest}
    error={submitFailed && error}
    valid={!(submitFailed && !valid)}
    value={value}
    onSubmit={onChange as any}
  />
)

export default FormPinpad
