import { createSelector } from 'reselect'

import { ChildrenStartingReportRootState } from '../models'

const getChildrenStartingReport = (state: ChildrenStartingReportRootState) => state.childrenStartingReport

export const getChildrenStartingReportNurseries = createSelector(
  [getChildrenStartingReport],
  (state) => state.nurseries,
)

export const getChildrenStartingReportNurseriesData = createSelector(
  [getChildrenStartingReportNurseries],
  (state) => state.data,
)
