import _ from 'lodash'
import moment from 'moment'
import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { toFloat, toInt } from 'utils/data'

import * as constants from '../constants'

export const getChildExtraItemsSelectors = (state) => state.childExtraItems

export const getChildExtraItemsListDataSelectors = createSelector(
  [getChildExtraItemsSelectors],
  (state) => state.list.data,
)

export const getChildExtraItemsFormattedListDataSelectors = createSelector(
  [getChildExtraItemsListDataSelectors],
  (listData) => {
    if (!listData) {
      return null
    }

    return _.map(listData, (item) => {
      const { archived, extraItem, quantity } = item
      const { name, unitPrice } = extraItem || {}
      const total = toInt(quantity || 1) * toFloat(unitPrice)

      return {
        ...item,
        name: `${name}${archived ? ' (archived)' : ''}`,
        total,
        unitPrice,
      }
    })
  },
)

export const getCriteriaSelector = (filters) => {
  const { childExtraItem, childId, dateRange, invoiceItem, or, status } = filters || {}
  const [startDate, endDate] = dateRange || []

  const criteria = []

  if (childId) {
    criteria.push({
      field: 'child',
      value: childId,
    })
  }

  if (status && status !== constants.CHILD_EXTRA_ITEMS_FILTERS.ALL) {
    criteria.push({
      field: 'archived',
      value: status === constants.CHILD_EXTRA_ITEMS_FILTERS.ARCHIVED ? 1 : 0,
    })
  }

  if (startDate) {
    criteria.push({
      comparator: 'after',
      field: 'startDate',
      value: moment(startDate).format('YYYY-MM-DD'),
    })
  }

  if (endDate) {
    criteria.push({
      comparator: 'before',
      field: 'startDate',
      value: moment(endDate).format('YYYY-MM-DD'),
    })
  }

  if (null !== invoiceItem && undefined !== invoiceItem) {
    criteria.push({
      comparator: 'exists',
      field: 'invoiceItem',
      value: invoiceItem,
    })
  }

  if (childExtraItem && childExtraItem.child) {
    criteria.push({
      field: 'childExtraItem.child',
      value: childExtraItem.child,
    })
  }

  if (or) {
    const { ongoing, startDate: orStartDate } = or

    if (orStartDate) {
      criteria.push({
        field: 'or[0][endDate][gte]',
        value: moment(orStartDate).format(DEFAULT_DATE_FORMAT),
      })
    }

    if (ongoing) {
      criteria.push({
        field: 'or[0][ongoing][eq]',
        value: ongoing,
      })
    }
  }

  return criteria
}

export const getChildExtraItemsListSummaryByNameAndType = createSelector(
  [getChildExtraItemsListDataSelectors],
  (listData) => {
    if (!listData) {
      return null
    }

    const groupedData = _.groupBy(listData, (item) => `${item.type}_${item.extraItem.id}`)

    return _.map(groupedData, (value) => {
      const { extraItem, type } = value[0]
      const { name } = extraItem
      const typeOption = _.find(constants.CHILD_EXTRA_ITEMS_TYPE_OPTIONS, { value: type })

      return {
        count: value.length,
        name,
        type: typeOption ? typeOption.label : null,
      }
    })
  },
)
