import { generateBasicActions } from 'utils/service'

import {
  CLEAR_CARER,
  CREATE_CARER,
  CREATE_CARER_FAILED,
  CREATE_CARER_SUCCESS,
  GET_CARER,
  GET_CARER_FAILED,
  GET_CARER_SUCCESS,
  REMOVE_CARER,
  REMOVE_CARER_FAILED,
  REMOVE_CARER_SUCCESS,
  RESEND_PARENT_APP_INVITATION,
  RESEND_PARENT_APP_INVITATION_FAILED,
  RESEND_PARENT_APP_INVITATION_SUCCESS,
  UPDATE_CARER,
  UPDATE_CARER_FAILED,
  UPDATE_CARER_SUCCESS,
} from './constants'

export const create = (options = {}) => ({ carersApiClient }) => generateBasicActions.create({
  constants: {
    failed: CREATE_CARER_FAILED,
    init: CREATE_CARER,
    success: CREATE_CARER_SUCCESS,
  },
  options,
  service: carersApiClient,
  serviceMethod: 'create',
})

export const get = (options = {}) => ({ carersApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_CARER_FAILED,
    init: GET_CARER,
    success: GET_CARER_SUCCESS,
  },
  options,
  service: carersApiClient,
  serviceMethod: 'get',
})

export const update = (options = {}) => ({ carersApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_CARER_FAILED,
    init: UPDATE_CARER,
    success: UPDATE_CARER_SUCCESS,
  },
  options,
  service: carersApiClient,
  serviceMethod: 'update',
})

export const remove = (options = {}) => ({ carersApiClient }) => generateBasicActions.remove({
  constants: {
    failed: REMOVE_CARER_FAILED,
    init: REMOVE_CARER,
    success: REMOVE_CARER_SUCCESS,
  },
  options,
  service: carersApiClient,
  serviceMethod: 'remove',
})

export const clearSingle = () => ({
  type: CLEAR_CARER,
})

/*
* NOTE: need to be converted as generic one for v2,
* once michal create new endpoint for resend invitation
*/
export const resendParentAppInvitation = (
  carerId,
  childId,
  options = {},
) => ({ carersApiClient }) => (dispatch) => {
  const { onFailed, onSuccess } = options

  dispatch({
    payload: { carerId },
    type: RESEND_PARENT_APP_INVITATION,
  })

  carersApiClient.resendParentAppInvitation(carerId, childId).then(
    () => {
      dispatch({
        type: RESEND_PARENT_APP_INVITATION_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    () => {
      dispatch({
        type: RESEND_PARENT_APP_INVITATION_FAILED,
      })

      if (onFailed) {
        onFailed()
      }
    },
  )
}
