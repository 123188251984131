import { combineReducers } from 'redux'

import list from './list/reducer'
import single from './single/reducer'
import form from './form/reducer'

export default combineReducers({
  form,
  list,
  single,
})
