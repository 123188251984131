import _ from 'lodash'

import React from 'react'

import ChildFundingSessionPanelItem from './ChildFundingSessionPanelItem'

import { StyledContainer } from './ChildFundingSessionPanelStyled'

const ChildFundingSessionPanel = ({ onItemClick, sessionSets, showWeeklyAllocation }) => {
  const handleItemClick = (sessionSet) => () => {
    onItemClick(sessionSet)
  }

  return (
    <StyledContainer>
      {_.map(sessionSets, (sessionSet) => {
        const { amountUsed, hoursUsed, id, isSelected, session } = sessionSet

        const {
          amountAllocated,
          calculation,
          endDate,
          hoursAllocated,
          isOngoing,
          startDate,
          type,
          weeklyHours,
          weeklyHoursInvoiced,
        } = session
        return (
          <ChildFundingSessionPanelItem
            amountAllocated={amountAllocated}
            amountInvoiced={amountUsed}
            calculation={calculation}
            endDate={endDate}
            hoursAllocated={hoursAllocated}
            hoursInvoiced={hoursUsed}
            isOngoing={isOngoing}
            isSelected={isSelected}
            key={id}
            showWeeklyAllocation={showWeeklyAllocation}
            startDate={startDate}
            type={type}
            weeklyHours={weeklyHours}
            weeklyHoursInvoiced={weeklyHoursInvoiced}
            onClick={onItemClick ? handleItemClick(sessionSet) : null}
          />
        )
      })}
    </StyledContainer>
  )
}

export default ChildFundingSessionPanel
