import React from 'react'

import { Icon } from 'components'

import { StyledTopIcon } from './MinimalModalBoxStyled'

interface MinimalModalBoxTopIconProps {
  color?: string
  height?: number
  icon: IconType
}

const MinimalModalBoxTopIcon: React.FC<MinimalModalBoxTopIconProps> = ({ color, height, icon }) => (
  <StyledTopIcon>
    <Icon color={color} height={height} icon={icon} />
  </StyledTopIcon>
)

export default MinimalModalBoxTopIcon
