import { REFERENCE_ASYNC_PAGE_TYPES } from 'services/legacy/upload/constants'

export const getChildFileValuesForm = (values) => {
  const { child, mimeType, name, size, url } = values
  const body = {
    type: REFERENCE_ASYNC_PAGE_TYPES.CHILD,
  }

  if (name) {
    body.name = name
  }

  if (child) {
    body.child = {
      id: child,
    }
  }

  if (mimeType || url) {
    body.file = {
      mimeType,
      size,
      url,
    }
  }

  return body
}
