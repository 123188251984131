import { ROLES } from 'constants/security'
import moment from 'moment'
import { flatten, nest } from 'utils/flatnest'

import React, { useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { WrappedComponentType } from 'constants/types'

import auth from 'utils/auth'

import { withAppService, withAppServiceProps } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withChildBookingService, withChildBookingServiceProps } from 'services/booking/childBooking'
import { withPaginationUtils, withPaginationUtilsProps } from 'services/utils/pagination'
import { withRouteUtilsProps, withRouterUtils } from 'services/utils/router'
import { withRouter, withRouterProps } from 'services/router'

import RegularBookingsListView from './RegularBookingsListView'

type RegularBookingsListContainerProps = withAppServiceProps
  & withChildBookingServiceProps
  & withPaginationUtilsProps
  & withRouteUtilsProps
  & withRouterProps

const mapState = (state, {
  appSelectors,
  childBookingListState,
  childBookingSelectors,
  childSelectors,
}) => ({
  child: childSelectors.getChildSelector(state),
  errorMessages: appSelectors.getErrorMessages(childBookingListState),
  hasAccessToEditRegularBooking: auth.SELECTORS.getIsAuthorised(state, {
    roles: [
      ROLES.ORGANIZATION_DIRECTOR,
      ROLES.ORGANIZATION_NATIONAL_ADMIN,
      ROLES.ORGANIZATION_FINANCE_ADMIN,
      ROLES.ORGANIZATION_LINE_MANAGER,
      ROLES.DEPUTY_MANAGER,
      ROLES.NURSERY_MANAGER,
      ROLES.NURSERY_ADMIN,
      ROLES.SUPER_ADMIN,
    ],
  }),
  isFetching: appSelectors.getIsFetching(childBookingListState),
  records: childBookingSelectors.getChildBookingListDataState(state),
  totalResults: appSelectors.getTotalResults(childBookingListState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const CHILD_BOOKING_GROUPS = {
  read: [
    'child',
    'period',
    'childBooking',
    'childBooking.attendancePeriod',
    'childBooking.child',
    'childBooking.childProducts',
    'childBookingPreview.product',
    'regularChildProduct',
    'regularChildProduct.product',
    'nurserySessionProduct',
    'nurserySessionProduct.items',
    'nurseryDiscountProduct',
    'nurseryItemProduct',
    'nurseryFundingProduct',
    'oneOffChildProduct.product',
    'regularChildProduct.settings',
    'regularChildProductSettings',
  ],
}

export interface RegularBookingsListFilters {
  endAge?: number
  fundingType: number
  planActiveOn: moment.Moment
  room?: number
  startAge?: number
}

const RegularBookingsListContainer: WrappedComponentType<RegularBookingsListContainerProps & PropsFromRedux> = ({
  child,
  childBookingActions,
  childBookingSelectors,
  errorMessages,
  hasAccessToEditRegularBooking,
  isFetching,
  location,
  paginationUtils,
  params,
  records,
  setLocationQuery,
  totalResults,
}) => {
  const { getPaginationDetails, onPageChange, page } = paginationUtils
  const { childId } = params
  const { query } = location
  const { endAge, fundingType, planActiveOn, room, startAge } = nest(query) || {}

  const isChildContext = !!childId

  const [filters, setFilters] = useState<RegularBookingsListFilters>({
    endAge: endAge || null,
    fundingType: +fundingType || null,
    planActiveOn: planActiveOn ? moment(planActiveOn, DEFAULT_DATE_FORMAT) : null,
    room: +room || null,
    startAge: startAge || null,
  })
  const [search, setSearch] = useState<string>()

  const fetch = () => {
    const criteria = childBookingSelectors.getCriteria({
      childId: isChildContext ? +childId : null,
      filters,
      search,
    })

    let locationQuery = {
      fundingType: filters.fundingType,
      page,
      search,
    } as any

    if (filters.planActiveOn) {
      locationQuery.planActiveOn = moment(filters.planActiveOn).format(DEFAULT_DATE_FORMAT)
    }

    if (!isChildContext) {
      locationQuery = {
        endAge: filters.endAge,
        room: filters.room,
        startAge: filters.startAge,
        ...locationQuery,
      }
    }

    setLocationQuery(flatten(locationQuery))

    childBookingActions.list({
      params: {
        criteria,
        groups: CHILD_BOOKING_GROUPS,
        order: {
          sortField: 'startDate',
          sortOrder: 'DESC',
        },
        page,
      },
    })
  }

  useEffect(() => () => {
    childBookingActions.clearList()
  }, [])

  useEffect(() => {
    fetch()
  }, [page, filters, search])

  const handleFiltersChange = (filterName: keyof RegularBookingsListFilters) => (filterValue) => {
    if ('planActiveOn' === filterName && !filterValue) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        room: null,
      }))
    }

    onPageChange()(1)
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: filterValue,
    }))
  }

  const handleSearchChange = (newSearch) => {
    setSearch(newSearch)
  }

  return (
    <RegularBookingsListView
      child={child}
      errorMessages={errorMessages}
      filters={filters}
      hasAccessToEditRegularBooking={hasAccessToEditRegularBooking}
      isChildContext={isChildContext}
      isFetching={isFetching}
      pagination={getPaginationDetails(totalResults)}
      records={records}
      search={search}
      onFilterChange={handleFiltersChange}
      onSearchChange={handleSearchChange}
    />
  )
}

RegularBookingsListContainer.authParams = { allowFinanceF3: true }

const enhance = compose(
  withAppService,
  withChildService,
  withChildBookingService,
  withPaginationUtils,
  withRouter,
  withRouterUtils,
  connector,
)

export default enhance(RegularBookingsListContainer)
