import { Nullable } from 'constants/models'
import { ClosureDayType } from 'services/closureDayTypes/models'
import { Nursery } from 'services/nurseries/models'
import { NurseryAcademicYear } from 'services/nurseryAcademicYears/models'
import { PeriodTime } from 'services/periodTimes/models'

import { BasicListInitialState, BasicSingleInitialState } from 'utils/reducer'

export enum ActionType {
  create = 'create',
  delete = 'delete',
  update = 'update'
}

export enum Type {
  attendance = 'attendance',
  closure = 'closure'
}

export enum PeriodType {
  ALL_YEAR_ROUND = 'ALL_YEAR_ROUND',
  TERM_DATES = 'TERM_DATES',
}

export interface Period {
  archived?: boolean
  archivedAt?: Date
  closureDayType?: ClosureDayType
  endDate?: Date
  id?: number
  name?: string
  nursery?: Nursery
  nurseryAcademicYear?: NurseryAcademicYear
  periodTimes?: PeriodTime[]
  periodType?: PeriodType
  startDate?: Date
  type?: Type
}

export interface BatchBody {
  action: ActionType
  period: Period
}

export interface PeriodsListState extends BasicListInitialState {
  data: Nullable<Period[]>
}

export interface PeriodsSingleState extends BasicSingleInitialState {
  data: Period
}

export interface PeriodsState {
  list: PeriodsListState
  single: PeriodsSingleState
}

export interface PeriodsRootState {
  periods: PeriodsState
}
