import styled from 'styled-components'

import layout from 'constants/layout'

import { StyledContainer } from 'components/DataTable/DataTableStyled'

export const StyledValue = styled.div`
  border-radius: ${layout.borderRadius};
  padding: 7px 2px;
  min-width: 35px;
  
  ${({ aqua }) => aqua && `
    background: #B9F4EF;
  `}  
  
  ${({ orange }) => orange && `
    background: #FFD795;
  `}
  
  ${({ pink }) => pink && `
    background: #FFC8CF;
  `}
`

export const StyledLegend = styled.div`
  display: grid;
  grid-column-gap: 25px;
  grid-template-columns: auto auto auto;
  
  @media(max-width: 740px) {
    flex-direction: column;
    grid-template-columns: auto;
    grid-row-gap: 10px;
  }
`

export const StyledLegendContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 25px;
  
  @media(max-width: 740px) {
    justify-content: center;
  }
`

export const StyledLegendItem = styled.div`
  display: flex;
  align-items: center;
`

export const StyledLegendIcon = styled.div`
  width: 35px;
  height: 30px;
  border-radius: ${layout.borderRadius};
  
  ${({ aqua }) => aqua && `
    background: #B9F4EF;
  `}  
  
  ${({ orange }) => orange && `
    background: #FFD795;
  `}
  
  ${({ pink }) => pink && `
    background: #FFC8CF;
  `}
`

export const StyledTitle = styled.span`
  margin-left: 10px;  
`

export const StyledFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;  
`

export const StyledOverviewContainer = styled.div`
  ${StyledContainer} {
    margin-right: -20px;
  }
`
