import Widget from './Widget'
import WidgetHeader from './WidgetHeader'
import WidgetContent from './WidgetContent'
import WidgetList from './WidgetList'
import WidgetPart from './WidgetPart'

Widget.Header = WidgetHeader
Widget.Content = WidgetContent
Widget.List = WidgetList
Widget.Part = WidgetPart

export default Widget
