import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_INTEGRATION_FEE,
  CREATE_INTEGRATION_FEE,
  CREATE_INTEGRATION_FEE_FAILED,
  CREATE_INTEGRATION_FEE_SUCCESS,
  GET_INTEGRATION_FEE,
  GET_INTEGRATION_FEE_FAILED,
  GET_INTEGRATION_FEE_SUCCESS,
  REMOVE_INTEGRATION_FEE,
  REMOVE_INTEGRATION_FEE_FAILED,
  REMOVE_INTEGRATION_FEE_SUCCESS,
  UPDATE_INTEGRATION_FEE,
  UPDATE_INTEGRATION_FEE_FAILED,
  UPDATE_INTEGRATION_FEE_SUCCESS,
} from './constants'

export const get = (options: BasicActionOptionsProps) => ({ integrationFeesApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: GET_INTEGRATION_FEE_FAILED,
      init: GET_INTEGRATION_FEE,
      success: GET_INTEGRATION_FEE_SUCCESS,
    },
    options,
    service: integrationFeesApiClient,
    serviceMethod: 'get',
  })
)

export const create = (options: BasicActionOptionsProps) => ({ integrationFeesApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: CREATE_INTEGRATION_FEE_FAILED,
      init: CREATE_INTEGRATION_FEE,
      success: CREATE_INTEGRATION_FEE_SUCCESS,
    },
    options,
    service: integrationFeesApiClient,
    serviceMethod: 'create',
  })
)

export const update = (options: BasicActionOptionsProps) => ({ integrationFeesApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_INTEGRATION_FEE_FAILED,
      init: UPDATE_INTEGRATION_FEE,
      success: UPDATE_INTEGRATION_FEE_SUCCESS,
    },
    options,
    service: integrationFeesApiClient,
    serviceMethod: 'update',
  })
)

export const remove = (options: BasicActionOptionsProps) => ({ integrationFeesApiClient }) => (
  generateBasicActions.remove({
    constants: {
      failed: REMOVE_INTEGRATION_FEE_FAILED,
      init: REMOVE_INTEGRATION_FEE,
      success: REMOVE_INTEGRATION_FEE_SUCCESS,
    },
    options,
    service: integrationFeesApiClient,
    serviceMethod: 'remove',
  })
)

export const clear = () => ({
  type: CLEAR_INTEGRATION_FEE,
})
