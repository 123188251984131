import React from 'react'

import { StyledContainer } from './ChildFundingAllocatedWidgetStyled'
import ChildFundingAllocatedWidgetItem from '../ChildFundingAllocatedWidgetItem'

const ChildFundingAllocatedWidget = ({
  amountUsed,
  hoursUsed,
  totalAmount,
  totalHours,
}) => (
  <StyledContainer>
    <ChildFundingAllocatedWidgetItem
      progress={hoursUsed}
      title="Hours invoiced"
      total={totalHours}
    />
    <ChildFundingAllocatedWidgetItem
      progress={amountUsed}
      title="Amount invoiced"
      total={totalAmount}
      showSymbol
    />
  </StyledContainer>
)

export default ChildFundingAllocatedWidget
