import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class NextStepsApiClient extends ServiceBase {
  list = (params = {}) => this.request({
    path: `/v2/next_steps?${getQueryStringV2(params)}`,
  })

  create = (body) => this.request({
    body,
    method: 'POST',
    path: '/v2/next_steps',
  })

  update = (id, params = {}, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/next_steps/${id}?${getQueryStringV2(params)}`,
  })

  remove = (id, params = {}) => this.request({
    method: 'DELETE',
    path: `/v2/next_steps/${id}?${getQueryStringV2(params)}`,
  })

  get = (id, params) => this.request({
    path: `/v2/next_steps/${id}?${getQueryStringV2(params)}`,
  })
}

export default NextStepsApiClient
