import moment from 'moment'

import React, { PropsWithChildren } from 'react'

import { DateString } from 'components'

import TimeStringRange from './TimeStringRange'

interface TimeStringRoot<T> extends React.FC<PropsWithChildren<T>> {
  Range?: typeof TimeStringRange
}

interface TimeStringProps {
  date: moment.Moment | number
  local?: boolean
}

const TimeString: TimeStringRoot<TimeStringProps> = ({ date, local = true }) => (
  <DateString date={date} format="HH:mm" local={local} />
)

export default TimeString
