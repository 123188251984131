import _ from 'lodash'

import React from 'react'

import { AlertBox } from 'components'

import i18n from 'translations'

import { ERRORS } from './constants'
import { StyledAlertBox } from './FinancePaymentsImportStyled'

const renderRow = ({ message, row }) => (
  <li key={row}>
    {message
      ? `${i18n.t('module:Modals:PaymentsImport:row')} ${row}: ${message}`
      : `${i18n.t('module:Modals:PaymentsImport:row')} ${row}`}
  </li>
)

const renderAlertBox = ({ color, rows, text, title }) => (
  <StyledAlertBox>
    <AlertBox
      color={color}
      content={(
        <React.Fragment>
          {text}
          {rows && (
            <ul>
              {_.map(rows, renderRow)}
            </ul>
          )}
        </React.Fragment>
      )}
      title={title}
    />
  </StyledAlertBox>
)

export const renderContent = (errors) => _.map(errors, (error, errorType) => {
  const errorDetails = _.find(ERRORS, (value, key) => errorType === key)

  if (_.isArray(error)) {
    return renderAlertBox({
      ...errorDetails,
      rows: error,
    })
  }

  return renderAlertBox(errorDetails)
})
