import { RESPONSE, Request } from 'constants/http'

import authenticationActions from 'services/authentication/actions'

const UnauthorizedAccessMiddleware = (dispatch) => async (request: Request, next) => {
  const response = await next(request)

  if (
    RESPONSE.HTTP_401_UNAUTHORIZED === response.status
    && !request.public
    && -1 === request.path.search('/v2/auth/login/pin') // skip staff register pin fail
  ) {
    dispatch(authenticationActions.logout())
  }

  return response
}

export default UnauthorizedAccessMiddleware
