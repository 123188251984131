import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'

const getNurseryIntegrationsPayoutTransactionsState = (
  state: RootState,
) => state.nurseryIntegrations.payoutTransactions

export const getPayoutTransactionsMeta = createSelector(
  [getNurseryIntegrationsPayoutTransactionsState],
  ({ meta }) => meta,
)

export const getPayoutTransactions = createSelector(
  [getNurseryIntegrationsPayoutTransactionsState],
  ({ data }) => data,
)

export const getPayoutTransactionsNextPageId = createSelector(
  [getPayoutTransactionsMeta],
  (meta) => meta.next_page,
)

export const getPayoutTransactionsHasMore = createSelector(
  [getPayoutTransactionsMeta],
  (meta) => meta.has_more,
)
