import React from 'react'

import { Page, StagesIndicator } from 'components'

import i18n from 'translations'

import { CreditNoteInvoices, ReAllocateFunding, ReIssueInvoices } from './components'

const STAGES = {
  CREDIT_NOTE_INVOICES: 2,
  REALLOCATE_FUNDING: 1,
  REISSUE_INVOICES: 3,
}

const AutoCreditNotingView = ({
  childId,
  currentStage,
  endDate,
  fundingEndDate,
  fundingStartDate,
  highestAvailableStage,
  onAllocateFundingContinueClick,
  onIssueCreditNoteContinueClick,
  onReIssueInvoiceContinueClick,
  onStageChange,
  startDate,
}) => {
  const renderContent = () => {
    switch (currentStage) {
      case STAGES.REALLOCATE_FUNDING:
        return (
          <ReAllocateFunding
            childId={childId}
            endDate={endDate}
            startDate={startDate}
            onContinueClick={onAllocateFundingContinueClick}
          />
        )
      case STAGES.CREDIT_NOTE_INVOICES:
        return (
          <CreditNoteInvoices
            childId={childId}
            endDate={endDate}
            fundingEndDate={fundingEndDate}
            fundingStartDate={fundingStartDate}
            startDate={startDate}
            onContinueClick={onIssueCreditNoteContinueClick}
          />
        )
      case STAGES.REISSUE_INVOICES:
        return (
          <ReIssueInvoices
            childId={childId}
            endDate={endDate}
            fundingEndDate={fundingEndDate}
            fundingStartDate={fundingStartDate}
            startDate={startDate}
            onContinueClick={onReIssueInvoiceContinueClick}
          />
        )
      default:
        return null
    }
  }

  const renderStagesIndicator = () => (
    <StagesIndicator
      currentStage={currentStage}
      highestAvailableStage={highestAvailableStage}
      stages={[
        {
          disabled: currentStage !== STAGES.REALLOCATE_FUNDING,
          label: i18n.t('module:Children:Child:BookingPattern:AutoCreditNoting:ReAllocateFunding:title'),
        },
        {
          disabled: currentStage !== STAGES.CREDIT_NOTE_INVOICES,
          label: i18n.t('module:Children:Child:BookingPattern:AutoCreditNoting:CreditNoteInvoices:title'),
        },
        {
          disabled: currentStage !== STAGES.REISSUE_INVOICES,
          label: i18n.t('module:Children:Child:BookingPattern:AutoCreditNoting:ReIssueInvoices:title'),
        },
      ]}
      onStageChange={onStageChange}
    />
  )

  return (
    <Page.Section
      title={i18n.t('module:Children:Child:BookingPattern:AutoCreditNoting:title')}
    >
      {renderStagesIndicator()}
      {renderContent()}
    </Page.Section>
  )
}

export default AutoCreditNotingView
