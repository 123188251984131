import _ from 'lodash'

import { ENQUIRY_STATUS_CODES } from 'services/legacy/enquiries/constants'

import { createSelector } from 'reselect'

const getEnquiryStatusTypes = (state) => state.enquiryStatusTypes

export const getEnquiryStatusTypesDropdownListSelector = createSelector(
  [getEnquiryStatusTypes],
  (state) => state?.dropdownList,
)

export const getEnquiryStatusTypesListSelector = createSelector(
  [getEnquiryStatusTypes],
  (state) => state?.list,
)

export const getEnquiryStatusTypesListDataSelector = createSelector(
  [getEnquiryStatusTypesListSelector],
  (state) => state.data,
)

const getStatusType = (code) => (statusTypesList) => {
  if (!statusTypesList) {
    return null
  }

  return _.find(statusTypesList, { code })
}

export const getEnrolledStatusTypeFromList = createSelector(
  [getEnquiryStatusTypesListDataSelector],
  getStatusType(ENQUIRY_STATUS_CODES.ENROLLED),
)

export const getLostEnquiryStatusTypeFromList = createSelector(
  [getEnquiryStatusTypesListDataSelector],
  getStatusType(ENQUIRY_STATUS_CODES.LOST_ENQUIRY),
)

export const getNoSpaceEnquiryStatusTypeFromList = createSelector(
  [getEnquiryStatusTypesListDataSelector],
  getStatusType(ENQUIRY_STATUS_CODES.NO_SPACE_AVAILABLE),
)
