import { combineReducers } from 'redux'

import availabilityStatistics from './availabilityStatistics/reducer'
import costStatistics from './costStatistics/reducer'
import list from './list/reducer'
import single from './single/reducer'
import exceptionalUnavailableTimesList from './settings/unavailableTimes/exceptional/list/reducer'
import exceptionalUnavailableTimesSingle from './settings/unavailableTimes/exceptional/single/reducer'
import regularUnavailableTimesBatch from './settings/unavailableTimes/regular/batch/reducer'
import regularUnavailableTimesList from './settings/unavailableTimes/regular/list/reducer'

export default combineReducers({
  availabilityStatistics,
  costStatistics,
  exceptionalUnavailableTimesList,
  exceptionalUnavailableTimesSingle,
  list,
  regularUnavailableTimesBatch,
  regularUnavailableTimesList,
  single,
})
