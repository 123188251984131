import moment from 'moment'
import _ from 'lodash'
import color from 'color'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'

import { getBrandingColor } from 'utils/branding'
import { convertTimeDuration } from 'utils/date'

import { getNurseryPublicUrl } from 'services/nurseries/single/selectors/single'
import invoicesConstants from 'services/legacy/invoices/constants'
import { getToBeInvoicedFromForecastList } from 'services/legacy/child/helpers'

export const getNurseriesListSelector = (state: RootState) => state.nurseries.list

export const getOrganizationNurseriesDataSelector = createSelector(
  [getNurseriesListSelector],
  (state) => state.data,
)

export const getNurseriesListDataSelector = createSelector(
  [getNurseriesListSelector],
  (state) => state.data,
)

export const getNurseriesListMetaSelector = createSelector(
  [getNurseriesListSelector],
  (state) => state.meta,
)

export const getOrganizationNurseriesDataForChartSelector = createSelector(
  [getOrganizationNurseriesDataSelector],
  (nurseries) => ({
    data: [
      {
        color: getBrandingColor('primary-color'),
        data: _.map(nurseries, ({ invoiceStatistics }) => invoiceStatistics?.totalFundingIncome),
        name: 'Funding income',
      },
      {
        color: color(getBrandingColor('primary-color')).lighten(0.6).hex(),
        data: _.map(nurseries, ({ invoiceStatistics }) => invoiceStatistics?.totalInvoices),
        name: 'Fee income',
      },
    ],
    header: _.map(nurseries, ({ id, name }) => ({ id, name })),
  }),
)

export const getOrganizationNurseriesDataSelectorDropdown = createSelector(
  [getOrganizationNurseriesDataSelector],
  (list) => _.map(list, ({ id, name }) => ({
    label: name,
    value: id,
  })),
)

export const getCriteria = (filters, forStatistics = null) => {
  const {
    date,
    dateType,
    featureFlags,
    invoiceStatus,
    nursery,
    nurseryName,
    search,
    subdomain,
    type,
  } = filters || {}

  const criteria = []

  if (date) {
    const { date: { after, before } } = filters
    const { INVOICE_DATE_TYPES } = invoicesConstants

    const dateTypeField = dateType || INVOICE_DATE_TYPES.createdAt
    const field = `${forStatistics ? 'invoiceStatistics.' : ''}${dateTypeField}`

    if (after) {
      criteria.push({
        comparator: 'after',
        field,
        value: dateTypeField === INVOICE_DATE_TYPES.processedDate
          ? moment(after).startOf('day').toISOString()
          : moment(after).format(DEFAULT_DATE_FORMAT),
      })
    }

    if (before) {
      criteria.push({
        comparator: 'before',
        field,
        value: dateTypeField === INVOICE_DATE_TYPES.processedDate
          ? moment(before).endOf('day').toISOString()
          : moment(before).format(DEFAULT_DATE_FORMAT),
      })
    }
  }

  if (nursery) {
    criteria.push({
      field: 'id',
      value: nursery,
    })
  }

  if (nurseryName) {
    criteria.push({
      field: 'name',
      value: nurseryName,
    })
  }

  if (subdomain) {
    criteria.push({
      field: 'subdomain',
      value: subdomain,
    })
  }

  if (invoiceStatus) {
    criteria.push({
      field: `${forStatistics ? 'invoiceStatistics.not[status]' : 'not[status]'}`,
      value: invoiceStatus,
    })
  }

  if (0 <= type) {
    criteria.push({
      field: 'archived',
      value: type,
    })
  }

  if (search) {
    criteria.push({
      field: 'name',
      value: search,
    })
  }

  if (featureFlags?.length) {
    _.forEach(featureFlags, (flag) => {
      criteria.push({
        field: 'featureFlags.flag[]',
        value: flag,
      })
    })

    criteria.push({
      field: 'featureFlags.enabled',
      value: 1,
    })
  }

  return criteria
}

export const getForecastCriteria = ({ date, ...rest }) => {
  const criteria = []

  if (date) {
    const { after, before } = date

    if (after) {
      criteria.push({
        comparator: 'after',
        field: 'nurseryMonthlyForecastStatistics.date',
        value: after,
      })
    }

    if (before) {
      criteria.push({
        comparator: 'before',
        field: 'nurseryMonthlyForecastStatistics.date',
        value: before,
      })
    }
  }

  criteria.push(...getCriteria(rest))

  return criteria
}

export const getPreviousPeriodDatesFilters = (filters) => {
  const { date } = _.cloneDeep(filters)

  if (date) {
    const days = convertTimeDuration(moment(date.before).diff(date.after), null, 'days')

    date.before = moment(date.before).add('days', -days).format('YYYY-MM-DD')
    date.after = moment(date.after).add('days', -days).format('YYYY-MM-DD')
  }

  return {
    ...filters,
    date,
  }
}

export const getOrganizationNurseriesDataWithPublicUrl = createSelector(
  [getOrganizationNurseriesDataSelector],
  (nurseries) => {
    if (!nurseries || !nurseries.length) {
      return null
    }

    return _.map(nurseries, (nursery) => ({ ...nursery, publicUrl: getNurseryPublicUrl(nursery) }))
  },
)

export const getForecastingReportTableDataSelector = createSelector(
  [getOrganizationNurseriesDataWithPublicUrl],
  (nurseries) => {
    if (!nurseries?.length) {
      return null
    }

    return getToBeInvoicedFromForecastList(nurseries)
  },
)

export const getOrganizationNurseriesOptions = createSelector(
  [getOrganizationNurseriesDataSelector],
  (nurseries) => _.map(nurseries, ({ id, name }) => ({
    label: name,
    value: id,
  })),
)

export const formattedNurseriesOptions = (nurseries) => {
  if (!nurseries?.length) {
    return []
  }

  return _.map(nurseries, ({ id, name }) => ({
    label: name,
    value: id,
  }))
}

export const isListEmpty = createSelector(
  [getNurseriesListDataSelector],
  (data) => !data?.length,
)

export const getDropdownListCriteria = ({ name }) => {
  const criteria = []

  if (name) {
    criteria.push({
      field: 'name',
      value: name,
    })
  }

  return criteria
}
