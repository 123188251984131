import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired, isValidPhotoUploadType } from 'utils/fieldValidation'

import { GENDER_DROPDOWN } from 'services/legacy/child/constants'

import { Form } from 'components'

import i18n from 'translations'

import { CHILD_ADD_FORM } from './constants'
import ChildFormFooter from './ChildFormFooter'

const GeneralInformationForm = ({
  childId,
  ethnicitiesOptions,
  handleSubmit,
  isChildArchived,
  isEditMode,
  isSubmitting,
  onArchiveClick,
  permissionToArchive,
}) => {
  const renderForm = () => (
    <React.Fragment>
      <Form.Row
        label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:FirstName:label')}
        required
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextField}
            name="firstName"
            placeholder={i18n.t('module:Children:Child:Add:GeneralInformation:Form:FirstName:placeholder')}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:MiddleName:label')}
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextField}
            name="middleNames"
            placeholder={i18n.t('module:Children:Child:Add:GeneralInformation:Form:MiddleName:placeholder')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:Surname:label')}
        required
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextField}
            name="surname"
            placeholder={i18n.t('module:Children:Child:Add:GeneralInformation:Form:Surname:placeholder')}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:DisplayName:label')}
        required
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextField}
            name="displayName"
            placeholder={i18n.t('module:Children:Child:Add:GeneralInformation:Form:DisplayName:placeholder')}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:Address:label')}
        required
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextAreaField}
            name="information.address"
            placeholder={i18n.t('module:Children:Child:Add:GeneralInformation:Form:Address:placeholder')}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:Postcode:label')}
        required
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextField}
            name="information.postcode"
            placeholder={i18n.t('module:Children:Child:Add:GeneralInformation:Form:Postcode:placeholder')}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:Photo:label')}
      >
        <Form.Row.FlexItem flex="0 0 120px" mobileFull>
          <Field
            component={Form.PhotoUpload}
            name="photo"
            validate={isValidPhotoUploadType}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:DateOfBirth:label')}
        required
      >
        <Form.Row.Item>
          <Field
            component={Form.DatePicker}
            name="birthDate"
            placeholder={i18n.t('module:Children:Child:Add:GeneralInformation:Form:DateOfBirth:placeholder')}
            validate={isRequired}
          />
        </Form.Row.Item>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:Gender:label')}
        required
      >
        <Form.Row.Item>
          <Field
            component={Form.RadioGroup}
            name="gender"
            options={GENDER_DROPDOWN}
            validate={isRequired}
            buttonStyle
            horizontal
          />
        </Form.Row.Item>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:Ethnicity:label')}
      >
        <Form.Row.FlexItem flex="0 0 300px">
          <Field
            component={Form.Select}
            name="ethnicity"
            options={ethnicitiesOptions}
            placeholder={i18n.t('module:Children:Child:Add:GeneralInformation:Form:Ethnicity:placeholder')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Children:Child:Add:GeneralInformation:Form:ReferenceCode:label')}
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextField}
            name="codeReference"
            placeholder={i18n.t('module:Children:Child:Add:GeneralInformation:Form:ReferenceCode:placeholder')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
    </React.Fragment>
  )

  const renderFooter = () => (
    <ChildFormFooter
      childId={childId}
      isChildArchived={isChildArchived}
      isEditMode={isEditMode}
      isSubmitting={isSubmitting}
      permissionToArchive={permissionToArchive}
      withoutBackButton
      onArchiveClick={onArchiveClick}
    />
  )

  return (
    <Form onSubmit={handleSubmit}>
      {renderForm()}
      {renderFooter()}
    </Form>
  )
}

export default reduxForm({
  destroyOnUnmount: false,
  form: CHILD_ADD_FORM,
})(GeneralInformationForm)
