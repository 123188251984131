import React from 'react'

import { InfiniteScroll, Page, Section, Select, Spinner, Toolbar, Typography } from 'components'

import i18n from 'translations'

import ForecastChart from 'module/Finance/FinanceReport/FinanceReportForecasting/components/ForecastChart'
import ForecastTable from 'module/Finance/FinanceReport/FinanceReportForecasting/components/ForecastTable'

const ForecastingView = ({
  chart,
  forecastData,
  isFetchingChartData,
  isFetchingTableData,
  isFinanceV3Enabled,
  onPageChange,
  onSendReport,
  onYearChange,
  page,
  pageCount,
  statisticsData,
  year,
  yearsOptions,
}) => {
  const renderContent = () => {
    if (isFetchingChartData && isFetchingTableData && 1 === page) {
      return <Spinner />
    }

    const renderChart = () => {
      if (isFetchingChartData && 1 === page) {
        return <Spinner />
      }

      return <ForecastChart chart={chart} year={year && year.value} />
    }

    const renderTable = () => {
      if (isFetchingTableData && 1 === page) {
        return <Spinner />
      }

      return (
        <InfiniteScroll
          dataLength={forecastData ? forecastData.length : 0}
          hasMore={page < pageCount}
          next={() => onPageChange(+page + 1)}
        >
          <ForecastTable
            forecastData={forecastData}
            isFinanceV3Enabled={isFinanceV3Enabled}
            monthList={year && year.monthList}
            statisticsData={statisticsData}
            year={year}
            isOrganizationContext
          />
        </InfiniteScroll>
      )
    }

    return (
      <React.Fragment>
        {renderChart()}
        {renderTable()}
      </React.Fragment>
    )
  }

  const actions = <Section.Actions options={[{ onClick: onSendReport, type: 'export' }]} />

  return (
    <Page isLoading={isFetchingChartData && isFetchingTableData && 1 === page} alwaysExpanded>
      <Page.Content>
        <Section
          actions={actions}
          title={i18n.t('module:Organization:Reports:Forecasting:title')}
        >
          <Toolbar>
            <Toolbar.Group>
              <Toolbar.Item>
                <Select
                  clearable={false}
                  options={yearsOptions}
                  searchable={false}
                  value={year}
                  onChange={onYearChange}
                />
              </Toolbar.Item>
            </Toolbar.Group>
          </Toolbar>
          <Typography margin="20px 0">
            {i18n.t('module:Organization:Reports:Forecasting:description')}
          </Typography>
          {renderContent()}
        </Section>
      </Page.Content>
    </Page>
  )
}

export default ForecastingView
