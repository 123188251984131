import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'
import { WrappedComponentType } from 'constants/types'
import { LIST_TAB_NAMES } from 'module/Notification/components/NotificationList/Constants'

import { withAppService, withAppServiceProps } from 'services/app'
import { withNurseriesService, withNurseriesServiceProps } from 'services/nurseries'

import { Space, Spinner } from 'components'

import ListView from './ListView'

const GROUPS_NURSERY_SETTINGS = {
  read: [
    'nursery.settings',
    'nurserySettings.messaging',
  ],
}

interface ListContainsProps {
  isPopoverContext: boolean
  setIsOpen: any
}

type ListContainerProps = ListContainsProps
  & withAppServiceProps
  & withNurseriesServiceProps

const mapState = (state: RootState, { appSelectors, params }) => ({
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const ListContainer: WrappedComponentType<ListContainerProps & PropsFromRedux> = ({
  isPopoverContext,
  nurseriesActions,
  nurseryOptions,
  setIsOpen,
}) => {
  const [entryTab, setEntryTab] = useState<string>(LIST_TAB_NAMES.ALL)
  const [settingsFetching, setSettingsFetching] = useState<boolean>(true)

  const handleChangeListTab = (name: string) => {
    setEntryTab(name)
  }

  const handleRedirectListItem = () => {
    if (isPopoverContext) {
      setIsOpen(false)
    }
  }

  const fetchNurserySettings = () => {
    if (!nurseryOptions.id) {
      return null
    }

    return nurseriesActions.get(nurseryOptions.id, {
      onSuccess: () => {
        setSettingsFetching(false)
      },
      onlyData: true,
      params: { groups: GROUPS_NURSERY_SETTINGS },
    })
  }

  useEffect(() => {
    if (!isPopoverContext) {
      fetchNurserySettings()
    }
  }, [nurseriesActions, nurseryOptions])

  if (settingsFetching && !isPopoverContext) {
    return (
      <React.Fragment>
        <Space space="20px" />
        <Spinner />
      </React.Fragment>
    )
  }

  return (
    <ListView
      entryTab={entryTab}
      isPopoverContext={isPopoverContext}
      onChangeListTab={handleChangeListTab}
      onRedirectListItem={handleRedirectListItem}
    />
  )
}

ListContainer.authParams = {
  nurseryContext: true,
}

const enhance = compose(
  withAppService,
  withNurseriesService,
  connector,
)

export default enhance(ListContainer)
