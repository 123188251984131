import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import selectors from './selectors'

const withChildDiscountsService = (WrappedComponent) => {
  const mapState = ({ childDiscounts }) => ({ childDiscounts })

  const mapDispatch = injectActions('discountActions', actions)

  const Component = (props) => {
    const { childDiscounts, discountActions, ...other } = props

    const childDiscountsListState = childDiscounts.list
    const childDiscountsSingleState = childDiscounts.single
    const childDiscountsActions = discountActions
    const childDiscountsSelectors = selectors

    const getAllChildDiscounts = (params, page = 1) => {
      discountActions.list({ ...params, page }, 1 < page, ({ meta }) => {
        const { limit, start } = meta
        const totalResults = meta.total_results

        if (start * limit < totalResults) {
          getAllChildDiscounts(params, page + 1)
        }
      })
    }

    return (
      <WrappedComponent
        childDiscountsActions={childDiscountsActions}
        childDiscountsHelpers={{ getAllChildDiscounts }}
        childDiscountsListState={childDiscountsListState}
        childDiscountsSelectors={childDiscountsSelectors}
        childDiscountsSingleState={childDiscountsSingleState}
        {...other}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withChildDiscountsService
