import { createSelector } from 'reselect'

const getDeviceTokens = (state) => state.deviceTokens

export const getDeviceToken = createSelector([getDeviceTokens], (state) => state.single.data)

export const getDeviceTokensValuesForm = (values) => {
  const { method, name, regeneratePin, user } = values
  const body = {}

  if (name) {
    body.name = name
  }

  if (method) {
    body.method = method
  }

  if (user) {
    body.user = user?.value
  }

  if (regeneratePin) {
    body.regeneratePin = regeneratePin
  }

  return body
}

export const getInitialValues = createSelector([getDeviceToken], (device) => {
  const { name, pin } = device || {}
  const initialValues = {}

  if (name) {
    initialValues.name = name
  }

  if (pin?.user) {
    initialValues.user = {
      value: pin.user.id,
    }
  }

  return initialValues
})
