export const ADD_UPLOAD_FILE = 'UPLOAD/ADD_UPLOAD_FILE'

export const UPDATE_UPLOAD_FILE = 'UPLOAD/UPDATE_UPLOAD_FILE'
export const UPDATE_UPLOAD_FILE_SUCCESS = 'UPLOAD/UPDATE_UPLOAD_FILE_SUCCESS'

export const UPDATE_UPLOAD_FILES = 'UPLOAD/UPDATE_UPLOAD_FILES'

export const START_UPLOAD_VIDEO = 'UPLOAD/START_UPLOAD_VIDEO'

export const FILE_SIZE_TOO_LARGE = 'UPLOAD/FILE_SIZE_TOO_LARGE'

export const ADD_FILE_TO_REFERENCE_PAGE = 'UPLOAD/ADD_FILE_TO_REFERENCE_PAGE'
export const ADD_FILE_TO_REFERENCE_PAGE_SUCCESS = 'UPLOAD/ADD_FILE_TO_REFERENCE_PAGE_SUCCESS'

export const UPDATE_REFERENCE_PAGES_STORAGE = 'UPLOAD/UPDATE_REFERENCE_PAGES_STORAGE'

export const MARK_FILE_TO_REMOVE = 'UPLOAD/MARK_FILE_TO_REMOVE'

export const MARK_UPLOADED_FILE_TO_REMOVE = 'UPLOAD/MARK_UPLOADED_FILE_TO_REMOVE'

export const UPLOADED_FILE_VALIDATION_ERROR = 'UPLOAD/UPLOADED_FILE_VALIDATION_ERROR'

export const CLEAR_UPLOADED_FILES = 'UPLOAD/CLEAR_UPLOADED_FILES'
