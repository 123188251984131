import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledSeparator = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  margin: 20px 0;
  padding: 0 32px;
`

export const StyledContent = styled.div`
  padding: 0 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
`

export const StyledBorder = styled.div`
  height: 20px;
  position: relative;
  
  &:after {
    content: '';
    height: 1px;
    background: ${NEUTRAL_COLOURS.GRAY_SECONDARY};
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`
