import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledAccordion = styled.div`
  border-radius: 6px;
  margin-bottom: 8px;
`

interface StyledHeaderProps {
  $isOpen?: boolean
}

export const StyledHeader = styled.div<StyledHeaderProps>`
  padding: 12px 20px;
  display: grid;
  grid-template-columns: auto 32px;
  background: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  align-items: center;

  ${({ $isOpen }) => !$isOpen && `
    border-radius: 6px;
  `}
`

export const StyledContent = styled.div`
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: none;
  border: solid 2px ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  padding: 15px;
`
