import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import { GENDER_DROPDOWN } from 'services/legacy/child/constants'

import { isEmailValid, isRequired } from 'utils/fieldValidation'

import { Callout, ExternalPage, Form, Page, Typography } from 'components'

import i18n from 'translations'

import { StyledButton, StyledWrapper } from './EnquiryPublicFormStyled'

export const ENQUIRY_PUBLIC_FORM = 'EnquiryPublicForm'

const EnquiryPublicForm = ({
  errorMessages,
  handleSubmit,
  isSubmitting,
  onRecaptchaVerify,
  onSubmit,
  subdomainData,
  token,
}) => {
  const { recaptchaKey } = subdomainData

  const renderContactInformationForm = () => (
    <React.Fragment>
      <Typography variant="h6">{i18n.t('module:EnquiryPublicForm:contactInformation')}</Typography>
      <Form.Row
        label={i18n.t('module:EnquiryPublicForm:firstName')}
        margin="15px 0 0"
        required
        verticalLabel
      >
        <Form.Row.FlexItem>
          <Field
            component={Form.TextField}
            name="firstName"
            validate={isRequired}
            required
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:EnquiryPublicForm:surname')}
        margin="15px 0 0"
        required
        verticalLabel
      >
        <Form.Row.FlexItem>
          <Field
            component={Form.TextField}
            name="surname"
            validate={isRequired}
            required
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:EnquiryPublicForm:phoneNumber')}
        margin="15px 0 0"
        required
        verticalLabel
      >
        <Form.Row.FlexItem>
          <Field
            component={Form.TextField}
            name="phoneNumber"
            validate={isRequired}
            required
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:EnquiryPublicForm:email')}
        margin="15px 0 40px"
        required
        verticalLabel
      >
        <Form.Row.FlexItem>
          <Field
            component={Form.TextField}
            name="email"
            validate={[isRequired, isEmailValid]}
            required
          />
        </Form.Row.FlexItem>
      </Form.Row>
    </React.Fragment>
  )

  const renderChildInformationForm = () => (
    <React.Fragment>
      <Typography variant="h6">{i18n.t('module:EnquiryPublicForm:childInformation')}</Typography>
      <Form.Row
        label={i18n.t('module:EnquiryPublicForm:firstName')}
        margin="15px 0 0"
        required
        verticalLabel
      >
        <Form.Row.FlexItem>
          <Field
            component={Form.TextField}
            name="childFirstName"
            validate={isRequired}
            required
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:EnquiryPublicForm:surname')}
        margin="15px 0 0"
        required
        verticalLabel
      >
        <Form.Row.FlexItem>
          <Field
            component={Form.TextField}
            name="childSurname"
            validate={isRequired}
            required
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:EnquiryPublicForm:gender')}
        margin="15px 0 0"
        verticalLabel
      >
        <Form.Row.FlexItem>
          <Field
            component={Form.Select}
            name="childGender"
            options={GENDER_DROPDOWN}
            searchable={false}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:EnquiryPublicForm:birthDate')}
        margin="15px 0 0"
        required
        verticalLabel
      >
        <Form.Row.Item>
          <Field
            component={Form.DatePicker}
            name="childBirthDate"
            validate={isRequired}
          />
        </Form.Row.Item>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:EnquiryPublicForm:desiredStartDate')}
        margin="15px 0 0"
        verticalLabel
      >
        <Form.Row.Item>
          <Field
            component={Form.DatePicker}
            name="childDesiredStartDate"
          />
        </Form.Row.Item>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Enquiries:List:Add:EnquiryDetails:Source:label')}
        margin="15px 0 0"
        verticalLabel
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.InfiniteDropdowns.PublicEnquirySource}
            name="source"
            placeholder={i18n.t('module:Enquiries:List:Add:EnquiryDetails:Source:placeholder')}
            token={token}
            clearable
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        margin="40px 0 0"
        verticalLabel
      >
        <Form.Row.Item>
          <Field
            component={Form.Checkbox}
            label={i18n.t('module:EnquiryPublicForm:sendConfirmationEmail')}
            name="sendConfirmationEmail"
          />
        </Form.Row.Item>
      </Form.Row>
    </React.Fragment>
  )

  const renderContent = () => (
    <Page.Content>
      <StyledWrapper>
        <Callout content={errorMessages} error />
        <Typography fontSize={24} margin="15px 0 40px" bold>{i18n.t('module:EnquiryPublicForm:title')}</Typography>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {renderContactInformationForm()}
          {renderChildInformationForm()}
          <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
            <GoogleReCaptcha onVerify={onRecaptchaVerify} />
          </GoogleReCaptchaProvider>
          <StyledButton
            isLoading={isSubmitting}
            label={i18n.t('module:EnquiryPublicForm:sendEnquiry')}
            fullWidth
            submit
          />
        </Form>
      </StyledWrapper>
    </Page.Content>
  )

  return (
    <React.Fragment>
      <ExternalPage.NurseryHeader subdomainData={subdomainData} />
      {renderContent()}
      <ExternalPage.PoweredByBlossomFooter />
    </React.Fragment>
  )
}

export default reduxForm({ form: ENQUIRY_PUBLIC_FORM })(EnquiryPublicForm)
