import ListAdvanced from './Advanced'
import ListStandard from './Standard'

const List = {} as {
  Advanced?: typeof ListAdvanced
  Standard?: typeof ListStandard
}

List.Advanced = ListAdvanced
List.Standard = ListStandard

export default List
