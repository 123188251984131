import { generateBasicActions } from 'utils/service'

import {
  CLEAR_FINANCE_REPORTS_NURSERIES_REVENUE_PER_AGE,
  FINANCE_REPORTS_NURSERIES_REVENUE_PER_AGE,
  FINANCE_REPORTS_NURSERIES_REVENUE_PER_AGE_FAILED,
  FINANCE_REPORTS_NURSERIES_REVENUE_PER_AGE_SUCCESS,
} from './constants'

export const getNurseries = (options = {}) => ({ financeRevenuePerAgeReportApiClient }) => generateBasicActions.list({
  constants: {
    failed: FINANCE_REPORTS_NURSERIES_REVENUE_PER_AGE_FAILED,
    init: FINANCE_REPORTS_NURSERIES_REVENUE_PER_AGE,
    success: FINANCE_REPORTS_NURSERIES_REVENUE_PER_AGE_SUCCESS,
  },
  options,
  service: financeRevenuePerAgeReportApiClient,
  serviceMethod: 'getNurseries',
})

export const clearNurseries = () => ({
  type: CLEAR_FINANCE_REPORTS_NURSERIES_REVENUE_PER_AGE,
})
