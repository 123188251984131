import styled from 'styled-components'

import AutosizeInput from 'react-input-autosize'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { StyledScrollbar } from 'styled/abstract'

export const StyledChildSelectWrapper = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
  align-items: center;
`

export const StyledInput = styled(AutosizeInput)`
  font-size: 18px;
  max-width: calc(100vw - 320px - 70px);
  
  &::placeholder {
    color: ${NEUTRAL_COLOURS.GRAY};
  }
`

export const StyledInputWrapper = styled.div`
  user-select: none;
`

export const StyledChildList = styled(StyledScrollbar)`
  width: 320px;
  max-height: 425px;
  overflow-y: scroll;
  margin: 5px 5px 5px 0;
`

export const StyledChildRow = styled.div`
  border-radius: 8px;
  margin: 8px 10px;
  padding: 14px 12px;
  cursor: pointer;
  transition: .2s;
  
  &:hover, &:focus {
    background: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  }
`

export const StyledLabel = styled.div`
  padding: 1px 8px;
  border-radius: 4px;
  font-size: 14px;
  color: #FFF;
  background: ${NEUTRAL_COLOURS.GRAY};  
`

export const StyledSubtitle = styled.div`
  ${({ isArchived }) => isArchived && `
    display: grid;
    grid-template-columns: 64px auto;
    align-items: center;
    grid-gap: 5px;
  `}
`
