import React, { useEffect } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'
import { getFormInitialValues } from 'redux-form'

import { RootState } from 'core/reducers'
import { ModalType } from 'modals'
import { LIST_STATUS_FILTERS } from 'constants/filters'
import { FUNDING_FEES_TYPES } from 'services/nurseryFunding/constants'
import { FEATURE_FLAGS } from 'constants/security'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import { withAppService, withAppServiceProps } from 'services/app'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withNurseryConsumablesService, withNurseryConsumablesServiceProps } from 'services/nurseryConsumables'
import { withNurserySessionsService, withNurserySessionsServiceProps } from 'services/nurserySessions'
import { withNurseryFundingService, withNurseryFundingServiceProps } from 'services/nurseryFunding'
import { withRouter, withRouterProps } from 'services/router'

import i18n from 'translations'

import { FORM_NAME } from './components/AddSessionForm'
import { SessionFormField } from './components/models'
import { getInitialValues, getPayload } from './AddHelpers'
import AddView from './AddView'

const SESSIONS_GROUPS = {
  read: [
    'nurserySession.nurseryConsumablesExtraItems',
    'nurserySession.organizationSession',
    'nurseryConsumable',
  ],
}

interface AddContainerProps {
  formInitialValues: SessionFormField
}

type AddContainerFullProps = withNurserySessionsServiceProps
  & withAppServiceProps
  & withModalServiceProps
  & withNurseryConsumablesServiceProps
  & withNurseryFundingServiceProps
  & withRouterProps
  & AddContainerProps

const mapState = (state: RootState, {
  appSelectors,
  nurseryConsumablesListState,
  nurseryConsumablesSelectors,
  nurseryFundingSelectors,
  nurserySessionsSelectors,
  nurserySessionsSingleState,
}: AddContainerFullProps) => ({
  FinanceAutomationGranted: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.FINANCE_AUTOMATION],
  }),
  consumables: nurseryConsumablesSelectors.getConsumablesListDropdown(state),
  errorMessages: appSelectors.getErrorMessages(nurserySessionsSingleState),
  formInitialValues: getFormInitialValues(FORM_NAME)(state),
  hasNurseryLevelManualFunding: nurseryFundingSelectors.hasNurseryLevelManualFunding(state),
  isFetching: appSelectors.getIsFetching(nurseryConsumablesListState, nurserySessionsSingleState),
  isInheritedFromOrganization: nurserySessionsSelectors.isInheritedFromOrganizationSelector(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const AddContainer: React.FC<AddContainerFullProps & PropsFromRedux> = ({
  FinanceAutomationGranted,
  consumables,
  errorMessages,
  formInitialValues,
  hasNurseryLevelManualFunding,
  isFetching,
  isInheritedFromOrganization,
  modalActions,
  navigate,
  nurseryConsumablesActions,
  nurseryConsumablesSelectors,
  nurseryFundingActions,
  nurserySessionsActions,
  nurserySessionsSingleState,
  params,
}) => {
  const isEdit = !!params.sessionId

  useEffect(() => {
    if (isEdit) {
      const criteria = []

      criteria.push({
        field: 'type',
        value: FUNDING_FEES_TYPES.MANUAL_HOURS,
      })

      nurseryFundingActions.list({
        params: {
          criteria,
          limit: 0,
        },
      })

      nurserySessionsActions.get({
        params: [params.sessionId, {
          groups: SESSIONS_GROUPS,
        }],
      })
    }

    nurseryConsumablesActions.list({
      params: {
        criteria: nurseryConsumablesSelectors.getCriteria({ active: LIST_STATUS_FILTERS.ACTIVE }),
      },
    })

    return () => {
      nurseryConsumablesActions.clearList()
      nurserySessionsActions.clear()
      nurseryFundingActions.clearList()
    }
  }, [])

  const handleSubmitSuccess = () => {
    navigate(generateRoute('MANAGEMENT.FINANCE_SETTINGS.SESSIONS'))
  }

  const handleSubmit = (fields) => {
    const payload = getPayload(fields)

    if (isEdit) {
      const { endTime, isHourly, startTime } = fields
      const { endTime: initialEndTime, isHourly: initialIsHourly, startTime: initialStartTime } = formInitialValues

      if (hasNurseryLevelManualFunding && (
        startTime !== initialStartTime
        || endTime !== initialEndTime
        || isHourly !== initialIsHourly
      )) {
        return modalActions.show<ModalType.CONFIRM>(ModalType.CONFIRM, {
          confirmButtonLabel: i18n.t('global:Update'),
          icon: 'warning',
          onConfirm: () => nurserySessionsActions.update({
            body: payload,
            onSuccess: handleSubmitSuccess,
            params: [params.sessionId],
          }),
          text: i18n.t('module:Management:Finance:Sessions:Add:fundingPopupCopy'),
        })
      }

      return nurserySessionsActions.update({
        body: payload,
        onSuccess: handleSubmitSuccess,
        params: [params.sessionId],
      })
    }

    return nurserySessionsActions.create({
      body: payload,
      onSuccess: handleSubmitSuccess,
    })
  }

  const archiveSession = (archived) => {
    nurserySessionsActions.archive({
      body: { archived },
      onSuccess: handleSubmitSuccess,
      params: [params.sessionId],
    })
  }

  const handleArchiveClick = (archived) => () => {
    const label = archived ? i18n.t('global:archive') : i18n.t('global:unarchive')
    const text = archived
      ? i18n.t('module:Management:Finance:Sessions:Add:archivePopupCopy')
      : i18n.t('module:Management:Finance:Sessions:Add:unarchivePopupCopy')

    modalActions.show<ModalType.CONFIRM>(ModalType.CONFIRM, {
      confirmButtonLabel: label,
      icon: label,
      onConfirm: () => archiveSession(archived),
      text,
    })
  }

  const session = nurserySessionsSingleState.data
  const initialValues = getInitialValues(session)
  const isArchived = nurserySessionsSingleState.data && nurserySessionsSingleState.data.archived
  const isFormLoading = nurserySessionsSingleState.isSubmitting

  return (
    <AddView
      FinanceAutomationGranted={FinanceAutomationGranted}
      cancelLink={generateRoute('MANAGEMENT.FINANCE_SETTINGS.SESSIONS')}
      consumables={consumables}
      errorMessages={errorMessages}
      initialValues={initialValues}
      isArchived={isArchived}
      isEdit={isEdit}
      isFormLoading={isFormLoading}
      isInheritedFromOrganization={isInheritedFromOrganization}
      isLoading={isFetching}
      onArchiveClick={handleArchiveClick}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withRouter,
  withAppService,
  withNurserySessionsService,
  withModalService,
  withNurseryConsumablesService,
  withNurseryFundingService,
  connector,
)

export default enhance(AddContainer)
