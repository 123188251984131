import i18n from 'translations'

export const BREAKDOWN_TYPE = {
  PERCENTAGE: 'PERCENTAGE',
  TOTALS: 'TOTALS',
}

export const BREAKDOWN_OPTIONS = [
  {
    label: i18n.t('module:Organization:Reports:Components:Breakdown:percentage'),
    value: BREAKDOWN_TYPE.PERCENTAGE,
  },
  {
    label: i18n.t('module:Organization:Reports:Components:Breakdown:totals'),
    value: BREAKDOWN_TYPE.TOTALS,
  },
]
