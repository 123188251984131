import moment from 'moment'

import React from 'react'

import { generateRoute } from 'utils/routing'

import {
  Avatar,
  Banner,
  Button,
  CircleIcon,
  EmptyState,
  Form,
  Hyperlink,
  LastSaveDateTime,
  Page,
  Select,
  Spinner,
  StickyFooter,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
} from 'components'

import i18n from 'translations'

import FrameworkTable from './components/FrameworkTable'
import { StyledFrameworkSelectWrapper, StyledSwitchWrapper, StyledTogglesWrapper } from './ObservationFrameworksStyled'

const ObservationFrameworksView = ({
  childObservation,
  existSuggestedLinks,
  framework,
  frameworkDetails,
  frameworksList,
  isFetching,
  isFetchingChildFrameworkProgress,
  isFetchingFramework,
  itemsDuringProcessing,
  nurseryLearningSettings,
  observation,
  onChangeFramework,
  onChangeItemsDuringProcessing,
  onChangeOnlyShowSuggestedLinks,
  onChangeShowCompletedStatements,
  onlyShowSuggestedLinks,
  progressLevels,
  showCompletedStatements,
}) => {
  const { child } = childObservation || {}
  const { ageMonths, firstName, id: childId, photo, surname } = child || {}
  const { structure } = frameworkDetails || {}
  const {
    observationLinks,
    showCompletedStatementsToggle,
    showSuggestedLinksToggle,
  } = structure || {}
  const { default: groupedBy } = observationLinks || {}

  const renderToolbar = () => (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <StyledFrameworkSelectWrapper>
            <Form.Row
              label={i18n.t('module:Learning:Observations:ObservationFrameworks:frameworks')}
              margin="0"
              verticalLabel
            >
              <Form.Row.FlexItem flex="0 0 420px" mobileFull>
                <Select
                  clearable={false}
                  options={frameworksList}
                  placeholder={i18n.t('module:Learning:Observations:ObservationFrameworks:frameworks')}
                  value={framework}
                  onChange={onChangeFramework}
                />
              </Form.Row.FlexItem>
            </Form.Row>
          </StyledFrameworkSelectWrapper>
        </Toolbar.Item>
      </Toolbar.Group>
      {((showSuggestedLinksToggle && existSuggestedLinks) || showCompletedStatementsToggle) && (
        <Toolbar.Group>
          <Toolbar.Item>
            <StyledTogglesWrapper>
              {showSuggestedLinksToggle && existSuggestedLinks && (
                <Form.Row
                  label={(
                    <Typography cursor="pointer" fontSize={14}>
                      {i18n.t('module:Learning:Observations:ObservationFrameworks:showSuggestedLinks')}
                    </Typography>
                  )}
                  margin="0"
                  onClick={onChangeOnlyShowSuggestedLinks}
                >
                  <Switch
                    value={onlyShowSuggestedLinks}
                    onChange={onChangeOnlyShowSuggestedLinks}
                  />
                </Form.Row>
              )}
              {showCompletedStatementsToggle && (
                <StyledSwitchWrapper>
                  <Form.Row
                    label={(
                      <Typography cursor="pointer" fontSize={14}>
                        {i18n.t('module:Learning:Observations:ObservationFrameworks:showCompletedStatements')}
                      </Typography>
                    )}
                    margin="0"
                    onClick={onChangeShowCompletedStatements}
                  >
                    <Switch
                      disabled={isFetchingChildFrameworkProgress}
                      value={showCompletedStatements}
                      onChange={onChangeShowCompletedStatements}
                    />
                  </Form.Row>
                  <Tooltip
                    placement="left"
                    title={i18n.t('module:Learning:Observations:ObservationFrameworks:statementsInfo')}
                  >
                    <CircleIcon
                      cursor="pointer"
                      icon="info"
                      iconSize={16}
                      size={24}
                    />
                  </Tooltip>
                </StyledSwitchWrapper>
              )}
            </StyledTogglesWrapper>
          </Toolbar.Item>
        </Toolbar.Group>
      )}
    </Toolbar>
  )

  const renderBanner = () => {
    if (frameworkDetails?.structure?.observationLinks?.showBanner) {
      return (
        <Banner.Info>
          {i18n.t('module:Learning:Observations:ObservationFrameworks:bannerInfo')}
        </Banner.Info>
      )
    }

    return null
  }

  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    if (!frameworksList?.length) {
      return (
        <EmptyState
          icon="observations"
          text1={i18n.t('module:Learning:Observations:ObservationFrameworks:emptyState')}
          text2={(
            <Hyperlink to={generateRoute('LEARNING.OBSERVATIONS.EDIT', { observationId: observation.id })} primary>
              {i18n.t('module:Learning:Observations:ObservationFrameworks:returnToObservation')}
            </Hyperlink>
          )}
        />
      )
    }

    return (
      <React.Fragment>
        {renderToolbar()}
        {renderBanner()}
        <FrameworkTable
          childObservation={childObservation}
          framework={framework}
          frameworkDetails={frameworkDetails}
          groupedBy={groupedBy}
          isFetching={isFetchingFramework}
          isFetchingChildFrameworkProgress={isFetchingChildFrameworkProgress}
          nurseryLearningSettings={nurseryLearningSettings}
          observation={observation}
          progressLevels={progressLevels}
          showCompletedStatements={showCompletedStatements}
          onChangeItemsDuringProcessing={onChangeItemsDuringProcessing}
        />
        <StickyFooter>
          <Button
            hierarchy="tertiary"
            label={i18n.t('global:Cancel')}
            to={generateRoute('LEARNING.OBSERVATIONS.EDIT', { observationId: observation.id })}
          />
          {observation?.id && (
            <LastSaveDateTime
              authorName={observation?.author?.name}
              lastSaveDateTime={observation && moment(observation.updatedAt)}
            />
          )}
          <StickyFooter.Flex />
          <Button
            disabled={itemsDuringProcessing}
            hierarchy="primary"
            isLoading={itemsDuringProcessing}
            label={i18n.t('global:Save')}
            to={generateRoute('LEARNING.OBSERVATIONS.EDIT', { observationId: observation.id })}
          />
        </StickyFooter>
      </React.Fragment>
    )
  }

  return (
    <Page.Section
      actions={!isFetching && (
        <Avatar
          avatarSize="medium"
          initials={[firstName, surname]}
          src={photo}
          subTitle={`${i18n.t('global:ageMonths', { ageMonths })}`}
          title={(
            <Typography fontSize={14} primary>
              {`${firstName} ${surname}`}
            </Typography>
          )}
          to={generateRoute('CHILDREN.CHILD.ABOUT.PROFILE', { childId })}
        />
      )}
      isLoading={isFetching}
      title={i18n.t('module:Learning:Observations:ObservationFrameworks:title')}
    >
      {renderContent()}
    </Page.Section>
  )
}

export default ObservationFrameworksView
