import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { NEUTRAL_COLOURS } from 'constants/colors'
import { MilkReportEntry } from 'services/milkReport/models'

import { Typography } from 'components'

export interface TableDataProps extends MilkReportEntry {
  day?: string
  totalChildren?: number | JSX.Element
}

export const getTableData = (data: MilkReportEntry[]): TableDataProps[] => _.map(data, ({ entryDate, total }) => ({
  day: moment(entryDate, DEFAULT_DATE_FORMAT).format('ddd'),
  entryDate: moment(entryDate, DEFAULT_DATE_FORMAT).format('D MMMM'),
  totalChildren: total || <Typography color={NEUTRAL_COLOURS.GRAY}>0</Typography>,
}))
