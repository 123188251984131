import Register from 'module/Register/index'

export const ROUTE_NAMES = {
  'REGISTER.INDEX': 'REGISTER.INDEX',
}

const RegisterRouting = {
  component: Register,
  name: ROUTE_NAMES['REGISTER.INDEX'],
  path: 'attendance',
  title: 'Register',
}

RegisterRouting.ROUTE_NAMES = ROUTE_NAMES

export default RegisterRouting
