import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Field, FieldArray, InjectedFormProps, formValueSelector, reduxForm } from 'redux-form'

import { TYPES } from 'components/DropdownMenu/constants'

import { createTypeFromEnumValues } from 'utils/typescript'

import { isRequired, isValidNumber } from 'utils/fieldValidation'

import { Banner, Button, Callout, Form, Section, Toolbar, Typography } from 'components'

import i18n from 'translations'

import SessionFees from './SessionFees'
import { AddViewProps } from '../AddView'

import { SessionFormField } from './models'
import { CONSUMABLE_CHARGEABLE_OPTIONS, SESSION_TYPE_OPTIONS, SessionType } from './constants'

export const FORM_NAME = 'AddNurserySessionForm'

interface AddSessionFormProps extends AddViewProps {
  sessionType?: createTypeFromEnumValues<SessionType>
}

const renderConsumableRow = (item, i, fields, consumables, sessionType) => (
  <Form.Row key={i} margin="0 0 5px">
    <Form.Row.FlexItem flex="0 0 180px" margin="0 10px 5px 0">
      <Field
        component={Form.Select}
        name={`${item}.consumable`}
        options={consumables}
        placeholder={i18n.t('module:Management:Finance:Sessions:Add:Consumables:Form:Consumable:placeholder')}
      />
    </Form.Row.FlexItem>
    <Form.Row.FlexItem flex="0 0 100px" margin="0 10px 5px 0">
      <Field
        component={Form.TextField}
        name={`${item}.unitPrice`}
        placeholder={i18n.t('module:Management:Finance:Sessions:Add:Consumables:Form:Price:placeholder')}
        step="any"
        type="number"
      />
    </Form.Row.FlexItem>
    {sessionType === SessionType.HOURLY && (
      <Form.Row.FlexItem flex="0 0 180px" margin="0 0 5px 0">
        <Field
          component={Form.Select}
          name={`${item}.chargeable`}
          options={CONSUMABLE_CHARGEABLE_OPTIONS}
          placeholder={i18n.t('module:Management:Finance:Sessions:Add:Consumables:Form:Chargeable:placeholder')}
        />
      </Form.Row.FlexItem>
    )}
    <Form.Row.FlexItem margin="0 0 5px">
      <Form.RemoveRowButton
        trash
        onClick={() => fields.remove(i)}
      />
    </Form.Row.FlexItem>
  </Form.Row>
)

const renderConsumables = ({ consumables, fields, sessionType }) => (
  <React.Fragment>
    <Form.Row margin="0">
      <Form.Row.FlexItem flex="0 0 180px" margin="0 10px 0 0" mobileFull>
        <Typography>
          {i18n.t('module:Management:Finance:Sessions:Add:Consumables:Form:Consumable:label')}
        </Typography>
      </Form.Row.FlexItem>
      <Form.Row.FlexItem flex="0 0 100px" margin="0 10px 0 0" mobileFull>
        <Typography>
          {i18n.t('module:Management:Finance:Sessions:Add:Consumables:Form:Price:label')}
        </Typography>
      </Form.Row.FlexItem>
      {sessionType === SessionType.HOURLY && (
        <Form.Row.FlexItem flex="0 0 100px" margin="0 10px 0 0" mobileFull>
          <Typography>
            {i18n.t('module:Management:Finance:Sessions:Add:Consumables:Form:Chargeable:label')}
          </Typography>
        </Form.Row.FlexItem>
      )}
    </Form.Row>
    {fields.map((item, i) => renderConsumableRow(item, i, fields, consumables, sessionType))}
    <Button.ActionButton
      label={i18n.t('module:Management:Finance:Sessions:Add:Consumables:addButtonLabel')}
      onClick={() => fields.push({ consumable: null, unitPrice: null })}
    />
  </React.Fragment>
)

type AddSessionFormFullProps = InjectedFormProps<{} & AddSessionFormProps> & AddSessionFormProps

const AddSessionForm: React.FC<AddSessionFormFullProps> = ({
  FinanceAutomationGranted,
  cancelLink,
  consumables,
  error,
  handleSubmit,
  isArchived,
  isEdit,
  isFormLoading,
  isInheritedFromOrganization,
  onArchiveClick,
  onSubmit,
  sessionType,
}) => {
  const title = isEdit
    ? i18n.t('module:Management:Finance:Sessions:Add:editTitle')
    : i18n.t('module:Management:Finance:Sessions:Add:addTitle')
  const actions = isEdit && !isInheritedFromOrganization && (
    <Section.Actions
      options={[{
        onClick: onArchiveClick(!isArchived),
        type: isArchived ? TYPES.UNARCHIVE : TYPES.ARCHIVE,
      }]}
    />
  )

  const renderConsumablesSection = () => (
    <Section
      title={i18n.t('module:Management:Finance:Sessions:Add:Consumables:title')}
      tooltip={i18n.t('module:Management:Finance:Sessions:Add:Consumables:tooltip')}
    >
      {isInheritedFromOrganization ? (
        <Typography margin="20px 0">
          {i18n.t('module:Management:Finance:Sessions:Add:Consumables:organizationInheritBanner')}
        </Typography>
      ) : (
        <FieldArray
          component={renderConsumables}
          name="nurseryConsumablesExtraItems"
          props={{ consumables, sessionType }}
        />
      )}
    </Section>
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Callout content={error} error />
      <Section actions={actions} title={title}>
        {isInheritedFromOrganization && (
          <Form.Row>
            <Banner.Info>
              {i18n.t('module:Management:Finance:Sessions:Add:organizationInheritBanner1')}
              {i18n.t('module:Management:Finance:Sessions:Add:organizationInheritBanner2')}
            </Banner.Info>
          </Form.Row>
        )}
        <Form.Row
          label={i18n.t('module:Management:Finance:Sessions:Add:Form:SessionName:label')}
          width={{ field: '320px' }}
          verticalLabel
        >
          <Field
            component={Form.TextField}
            disabled={isInheritedFromOrganization}
            name="name"
            placeholder={i18n.t('module:Management:Finance:Sessions:Add:Form:SessionName:placeholder')}
            validate={isRequired}
          />
        </Form.Row>
        <Form.Row
          label={i18n.t('module:Management:Finance:Sessions:Add:Form:SessionType:label')}
          verticalLabel
        >
          <Field
            component={Form.RadioGroup}
            disabled={isEdit}
            name="type"
            options={SESSION_TYPE_OPTIONS}
            validate={isRequired}
            buttonStyle
            horizontal
          />
        </Form.Row>
        {sessionType === SessionType.FIXED && (
          <Toolbar noMargin>
            <Toolbar.Group>
              <Toolbar.Item>
                <Form.Row
                  label={i18n.t('module:Management:Finance:Sessions:Add:Form:StartTime:label')}
                  verticalLabel
                >
                  <Field
                    component={Form.TimePicker}
                    disabled={isInheritedFromOrganization}
                    local={false}
                    name="startTime"
                  />
                </Form.Row>
              </Toolbar.Item>
              <Toolbar.Item>
                <Form.Row
                  label={i18n.t('module:Management:Finance:Sessions:Add:Form:FinishTime:label')}
                  verticalLabel
                >
                  <Field
                    component={Form.TimePicker}
                    disabled={isInheritedFromOrganization}
                    local={false}
                    name="endTime"
                  />
                </Form.Row>
              </Toolbar.Item>
            </Toolbar.Group>
          </Toolbar>
        )}
        {isEdit && (
          <Banner.Info>
            {i18n.t('module:Management:Finance:Sessions:Add:Form:Banner:label')}
          </Banner.Info>
        )}
      </Section>
      {FinanceAutomationGranted
        && <SessionFees isInheritedFromOrganization={isInheritedFromOrganization} />}
      {renderConsumablesSection()}
      <Form.FooterActions
        cancelLink={cancelLink}
        isSubmitting={isFormLoading}
        submitDisabled={isInheritedFromOrganization}
        submitLabel={isEdit ? i18n.t('global:Update') : i18n.t('global:Save')}
      />
    </Form>
  )
}

const validate = (fields) => {
  const errors = { endTime: null, nurseryConsumablesExtraItems: [], startTime: null }

  if (fields.type === SessionType.HOURLY) {
    if (!fields.startTime) {
      errors.startTime = i18n.t('utils:Validation:isRequired')
    }

    if (!fields.endTime) {
      errors.endTime = i18n.t('utils:Validation:isRequired')
    }
  }

  if (fields?.nurseryConsumablesExtraItems?.length) {
    fields.nurseryConsumablesExtraItems.forEach(({ chargeable, consumable, unitPrice }, index) => {
      errors.nurseryConsumablesExtraItems[index] = {
        chargeable: isRequired(chargeable),
        consumable: isRequired(consumable),
        unitPrice: isRequired(unitPrice) || isValidNumber(2)(unitPrice),
      }
    })
  }

  return errors
}

const mapState = (state) => ({
  sessionType: formValueSelector(FORM_NAME)(state, 'type'),
})

const enhance = compose(
  reduxForm<SessionFormField, AddSessionFormProps>({ form: FORM_NAME, validate }),
  connect(mapState),
)

export default enhance(AddSessionForm)
