import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getFormValues, reduxForm } from 'redux-form'

import { Form } from 'components'

import i18n from 'translations'

import ChildFormFooter from './ChildFormFooter'

import { CHILD_ADD_FORM } from './constants'
import ChildPreview from '../ChildPreview'

const ReviewPage = ({
  drinkingMethods,
  formValues,
  handleSubmit,
  isSubmitting,
  onPreviousStageClick,
  toiletTrainingOptions,
}) => {
  const renderFooter = () => (
    <ChildFormFooter
      isSubmitting={isSubmitting}
      submitLabel={i18n.t('module:Children:Child:Add:createChildButtonLabel')}
      onPreviousStageClick={onPreviousStageClick}
    />
  )

  return (
    <Form onSubmit={handleSubmit}>
      <ChildPreview
        child={formValues}
        drinkingMethods={drinkingMethods}
        toiletTrainingOptions={toiletTrainingOptions}
        isFormContext
      />
      {renderFooter()}
    </Form>
  )
}

const mapState = (state) => ({
  formValues: getFormValues(CHILD_ADD_FORM)(state),
})

const enhance = compose(
  reduxForm({ destroyOnUnmount: false, form: CHILD_ADD_FORM }),
  connect(mapState),
)

export default enhance(ReviewPage)
