import _ from 'lodash'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators'

import React, { useEffect, useRef, useState } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { withChildService } from 'services/legacy/child'
import { withAppService } from 'services/app'
import { renderCarers } from 'module/Messaging/MessagingHelpers'
import { withRouter } from 'services/router'

import { Avatar, InfiniteScroll, Popover, Typography } from 'components'
import Archived from 'module/Messaging/components/Archived'

import i18n from 'translations'

import {
  StyledChildList,
  StyledChildRow,
  StyledChildSelectWrapper,
  StyledInput,
  StyledInputWrapper,
  StyledSubtitle,
} from './ChildSelectStyled'

const LIMIT = 20

const onSearch$ = new Subject()

const GROUPS = {
  read: [
    'carer',
    'carerChildRelation',
    'carerChildRelation.carer',
    'child.carerChildRelations',
    'child.sync',
  ],
}

const ChildSelect = ({
  childActions,
  childSelectors,
  location,
  onChildSelect,
}) => {
  const [search, changeSearch] = useState('')
  const [page, changePage] = useState(1)
  const [perPage, changePerPage] = useState(1)
  const [isOpen, setIsOpen] = useState(false)
  const [childrenList, setChildrenList] = useState([])
  const [isInputFocused, setIsInputFocused] = useState(false)
  const inputRef = useRef()

  const isNewChannelPage = () => {
    const { pathname } = location

    return pathname === generateRoute('MESSAGING.CHANNEL_NEW')
  }

  const getChildrenListSuccess = (response) => {
    const { data, meta } = response
    const { start, total_results: totalResults } = meta

    changePerPage(Math.ceil(totalResults / LIMIT))

    setChildrenList(
      1 === start
        ? data
        : [...childrenList, ...data],
    )
  }

  const getChildrenList = (finalPage, value = '') => {
    changePage(finalPage)

    const params = {
      criteria: childSelectors.getCriteria({
        archived: null,
        isArchived: null,
        search: value,
      }),
      groups: GROUPS,
      limit: LIMIT,
      page: finalPage,
    }

    childActions.dropdownList(
      params,
      1 !== finalPage,
      getChildrenListSuccess,
    )
  }

  useEffect(() => {
    const subscription = onSearch$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      map((value) => {
        changeSearch(value)
        changePage(1)
        changePerPage(1)
        getChildrenList(1, value)
      }),
    )
      .subscribe()

    return () => subscription.unsubscribe()
  }, [])

  if (isNewChannelPage) {
    if (!isInputFocused) {
      setIsInputFocused(true)

      setTimeout(() => {
        if (inputRef?.current) {
          setIsOpen(true)
          getChildrenList(1)
          inputRef.current.focus()
        }
      })
    }
  } else if (isInputFocused) {
    setIsInputFocused(false)
  }

  const handlePageChange = () => {
    getChildrenList(page + 1, search)
  }

  const renderChildRow = (child) => {
    const { archivedAt, carerChildRelations, firstName, id, photo, surname } = child

    return (
      <StyledChildRow
        key={id}
        onClick={() => {
          onChildSelect(child)
          changeSearch('')
          changePage(1)
          changePerPage(1)
          setIsOpen(false)
        }}
      >
        <Avatar
          avatarSize="medium_big"
          initials={[firstName, surname]}
          src={photo}
          subTitle={(
            <StyledSubtitle isArchived={archivedAt}>
              {archivedAt && <Archived />}
              {renderCarers(carerChildRelations)}
            </StyledSubtitle>
          )}
          title={(
            <Typography color={NEUTRAL_COLOURS.BASIC} margin="0 0 5px" variant="span">
              {`${firstName} ${surname}`}
            </Typography>
          )}
        />
      </StyledChildRow>
    )
  }

  return (
    <StyledChildSelectWrapper>
      <Typography
        color={NEUTRAL_COLOURS.GRAY}
        fontSize={18}
        userSelect="none"
      >
        {i18n.t('global:To')}
        {':'}
      </Typography>
      <StyledInputWrapper>
        <Popover
          button={(
            <StyledInput
              inputStyle={{ fontSize: 18 }}
              placeholder={i18n.t('module:Messaging:MessagingList:components:ChildSelect:placeholder')}
              ref={inputRef}
              type="text"
              value={search}
              onChange={(e) => {
                changeSearch(e.target.value)
                onSearch$.next(e.target.value)
              }}
            />
          )}
          isOpen={isOpen}
          margin="0 0 0 -20px"
          placement="bottom-start"
          disableArrow
          disableCloseInside
          onClick={() => setIsOpen((oldValue) => !oldValue)}
          onOutsideClick={() => setIsOpen(false)}
        >
          <Typography margin="16px 24px 10px" padding="20px 0 0" bold>
            {i18n.t('global:Children')}
          </Typography>
          <StyledChildList id="scrollableWrapper">
            {!childrenList?.length ? (
              <Typography padding="5px 24px 15px" width="auto">
                {i18n.t('module:Messaging:MessagingList:components:ChildSelect:notFound')}
              </Typography>
            ) : null}
            <InfiniteScroll
              dataLength={childrenList ? childrenList.length : 0}
              hasMore={page < perPage}
              next={handlePageChange}
              scrollableTarget="scrollableWrapper"
            >
              {_.map(childrenList, renderChildRow)}
            </InfiniteScroll>
          </StyledChildList>
        </Popover>
      </StyledInputWrapper>
    </StyledChildSelectWrapper>
  )
}

const mapState = (state, { appSelectors, childListState, childSelectors }) => ({
  childrenList: childSelectors.getChildListDataSelector(state),
  isFetching: appSelectors.getIsFetching(childListState),
})

const enhance = compose(
  withAppService,
  withChildService,
  withRouter,
  connect(mapState),
)

export default enhance(ChildSelect)
