export const LIST_TERMS = 'LIST_TERMS'
export const LIST_TERMS_SUCCESS = 'LIST_TERMS_SUCCESS'
export const LIST_TERMS_FAILED = 'LIST_TERMS_FAILED'

export const CLEAR_TERMS = 'CLEAR_TERMS'

export const listTerms = (nurseryId, params) => ({ organizationTermsApiClient }) => (dispatch) => {
  dispatch({
    nurseryId,
    params,
    type: LIST_TERMS,
  })

  organizationTermsApiClient.list(nurseryId, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: LIST_TERMS_SUCCESS,
      })
    },
    (error) => {
      dispatch({
        error: error.response,
        type: LIST_TERMS_FAILED,
      })
    },
  )
}

export const clearTerms = () => ({
  type: CLEAR_TERMS,
})
