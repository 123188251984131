import _ from 'lodash'

import React from 'react'

import { Spinner } from 'components'
import InlineEditableField from 'module/Management/components/InlineEditableField'

import i18n from 'translations'

import InstantMessengerForm, { INSTANT_MESSENGER_FORM } from './InstantMessengerForm'

const InstantMessenger = ({ isFetching, onSubmit, value }) => {
  const initialValues = { enabledForParents: value }
  const description = _.upperFirst(value ? i18n.t('global:yes') : i18n.t('global:no'))

  return (
    <InlineEditableField
      description={isFetching ? (
        <Spinner
          position="flex-start"
        />
      ) : description}
      editableComponent={(callback, isSubmitting) => (
        <InstantMessengerForm
          initialValues={initialValues}
          isSubmitting={isSubmitting}
          onSubmit={callback}
        />
      )}
      formName={INSTANT_MESSENGER_FORM}
      subtitle={i18n.t('module:Management:General:Messaging:InstantMessenger:EnableInstantMessenger:subtitle')}
      title={i18n.t('module:Management:General:Messaging:InstantMessenger:EnableInstantMessenger:title')}
      value={value}
      enableHandleFailed
      inline
      onSubmit={onSubmit}
    />
  )
}

export default InstantMessenger

