import _ from 'lodash'
import { v4 } from 'uuid'

import { browserHistory } from 'react-router'

import JsonBigint from 'json-bigint'

import { HTTP_HEADERS, RESPONSE, Request } from 'constants/http'

import eventBus from 'utils/eventBus'

import { findDomainSuffix, hostname } from 'services/subdomain/single/selectors'
import authenticationActions from 'services/authentication/actions'

import { properties } from 'app-config'

export const RUN_REQUEST_EVENT = 'EVENT/RUN_REQUEST_EVENT'

let isInitialized = false
let timerEnabled = false
let requestsQueue = []
let isNetwork = navigator.onLine

window.addEventListener('load', () => {
  window.addEventListener('online', () => {
    isNetwork = true
  })

  window.addEventListener('offline', () => {
    isNetwork = false
  })
})

const fetchRetry = require('fetch-retry')(window.fetch, {
  retries: 10,
  retryDelay: 3000,
  retryOn: [106],
})

// eslint-disable-next-line no-promise-executor-return
const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))

class ServiceBase {
  protected fetch: any

  protected dispatch: any

  static getBaseUrl = (): string => {
    const domainSuffix = findDomainSuffix(hostname)

    return properties.baseApiUri || `https://api${domainSuffix}`
  }

  static getAcceptHeader = (request: Request): string => {
    if (0 === request.path.indexOf('/v2')) {
      return 'application/blossom+json'
    }

    return 'application/json'
  }

  static createFetchingFunctionFromMiddleware = (httpMiddleware) => async (mainRequest) => {
    const chain = [...httpMiddleware]

    try {
      const next = (request: Request) => {
        const handler = chain.shift()

        const finalRequest = {
          ...request,
          headers: !request?.autoGeneratedHeaders ? {
            [HTTP_HEADERS.ACCEPT]: ServiceBase.getAcceptHeader(request),
            [HTTP_HEADERS.CONTENT_TYPE]: 'application/json',
            ...request.headers,
          } : request.headers,
        } as Request

        if (!finalRequest.headers) {
          delete finalRequest.headers
        }

        if (!_.isNull(handler) && !_.isUndefined(handler)) {
          return handler(finalRequest, next)
        }

        const finalUrl = finalRequest.uri || ServiceBase.getBaseUrl() + finalRequest.path

        if ('undefined' === typeof window) {
          return fetch(finalUrl, finalRequest)
        }

        return fetchRetry(finalUrl, finalRequest)
      }

      return await next(mainRequest)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)

      throw new Error(err)
    }
  }

  constructor(httpMiddleware = [], dispatch?) {
    this.fetch = ServiceBase.createFetchingFunctionFromMiddleware(httpMiddleware)
    this.dispatch = dispatch

    if (!isInitialized) {
      isInitialized = true
      timerEnabled = true

      this.runRequestsQueue()
    }
  }

  runRequestsQueue() {
    if (timerEnabled && requestsQueue.length) {
      timerEnabled = false
      const { id, request } = requestsQueue[0]

      const callback = (success) => (response) => {
        eventBus.dispatch(RUN_REQUEST_EVENT, { id, response, success })

        timerEnabled = true
        this.runRequestsQueue()
      }

      this.runSingleRequest({
        callbackFailed: callback(false),
        callbackSuccess: callback(true),
        id,
        request,
      })
    }
  }

  request(request: Request) {
    const id = v4()
    requestsQueue.push({ id, request })
    this.runRequestsQueue()

    return new Promise((resolve, reject) => {
      const action = (e) => {
        if (e.detail.id !== id) {
          return false
        }

        eventBus.remove(RUN_REQUEST_EVENT, action)

        if (e.detail.success) {
          return resolve(e.detail.response)
        }

        // eslint-disable-next-line prefer-promise-reject-errors
        return reject({
          response: e.detail.response,
        })
      }

      eventBus.on(RUN_REQUEST_EVENT, action)
    })
  }

  async runSingleRequest({ callbackFailed, callbackSuccess, id, request }) {
    let response
    let finalResponse

    try {
      while (!isNetwork && !request?.turnOnInOfflineMode) {
        // eslint-disable-next-line no-await-in-loop
        await sleep(5000)
      }

      requestsQueue = _.filter(requestsQueue, (item) => item.id !== id)
      response = await this.fetch(request)

      if (RESPONSE.HTTP_204_NO_CONTENT === response?.status) {
        return callbackSuccess()
      }

      if (!request.downloadBlob) {
        if (request.parseBigint) {
          const responseText = await response.text()

          finalResponse = JsonBigint.parse(responseText)
        } else {
          finalResponse = await response.json()
        }
      }

      if (!response.ok && '/auth/me' === request.path) {
        if (window.location.href.includes('/staff-register/')) {
          browserHistory.replace('/staff-register')
        } else {
          browserHistory.replace('/login')
        }

        callbackFailed()

        this.dispatch(authenticationActions.logout())
      }

      if (!response.ok) {
        throw new Error()
      }

      if (request.download) {
        // NOTE: window.open(url) does not work that is why below solution
        const a = document.createElement('a')
        a.href = finalResponse.data.url
        a.click()

        callbackSuccess(finalResponse)

        return false
      }

      if (request.downloadBlob) {
        return await response.blob().then((data) => {
          const a = document.createElement('a')

          callbackSuccess()

          a.href = URL.createObjectURL(data)
          a.download = request.filename
          a.click()
        })
      }

      callbackSuccess(finalResponse)

      return finalResponse
    } catch (error) {
      callbackFailed(finalResponse?.error || finalResponse)

      if (_.includes(request.disableErrors as any, response?.status)) {
        return finalResponse?.error || finalResponse
      }

      const finalError = new Error(
        `SERVICE_CALL_ERROR: ${(response?.message) || ''} [status ${response?.status}]`,
      ) as any

      finalError.response = finalResponse?.error || {}
      finalError.url = response?.url
      finalError.status = response?.status
      finalError.reportPayload = {
        request,
        response: {
          body: finalResponse,
          status: response?.status,
        },
      }

      return finalError
    }
  }
}

export default ServiceBase
