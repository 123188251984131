import { SubmissionError } from 'redux-form'

export const GET_CHILD_EXTRA_ITEM = 'GET_CHILD_EXTRA_ITEM'
export const GET_CHILD_EXTRA_ITEM_SUCCESS = 'GET_CHILD_EXTRA_ITEM_SUCCESS'
export const GET_CHILD_EXTRA_ITEM_FAILED = 'GET_CHILD_EXTRA_ITEM_FAILED'

export const CREATE_CHILD_EXTRA_ITEM = 'CREATE_CHILD_EXTRA_ITEM'
export const CREATE_CHILD_EXTRA_ITEM_SUCCESS = 'CREATE_CHILD_EXTRA_ITEM_SUCCESS'
export const CREATE_CHILD_EXTRA_ITEM_FAILED = 'CREATE_CHILD_EXTRA_ITEM_FAILED'

export const UPDATE_CHILD_EXTRA_ITEM = 'UPDATE_CHILD_EXTRA_ITEM'
export const UPDATE_CHILD_EXTRA_ITEM_SUCCESS = 'UPDATE_CHILD_EXTRA_ITEM_SUCCESS'
export const UPDATE_CHILD_EXTRA_ITEM_FAILED = 'UPDATE_CHILD_EXTRA_ITEM_FAILED'

export const DELETE_CHILD_EXTRA_ITEM = 'DELETE_CHILD_EXTRA_ITEM'
export const DELETE_CHILD_EXTRA_ITEM_SUCCESS = 'DELETE_CHILD_EXTRA_ITEM_SUCCESS'
export const DELETE_CHILD_EXTRA_ITEM_FAILED = 'DELETE_CHILD_EXTRA_ITEM_FAILED'

export const CLEAR_CHILD_EXTRA_ITEM = 'CLEAR_CHILD_EXTRA_ITEM'

export const getExtraItem = (childId, id) => ({ childExtraItemsApiClient }) => (dispatch) => {
  dispatch({
    childId,
    id,
    type: GET_CHILD_EXTRA_ITEM,
  })

  childExtraItemsApiClient.get(childId, id).then(
    (data) => {
      dispatch({
        payload: data,
        type: GET_CHILD_EXTRA_ITEM_SUCCESS,
      })
    },
    (error) => {
      dispatch({
        error: error.response,
        type: GET_CHILD_EXTRA_ITEM_FAILED,
      })
    },
  )
}

export const createExtraItem = (payload, params, onSuccess) => ({ childExtraItemsApiClient }) => (dispatch) => {
  dispatch({
    params,
    payload,
    type: CREATE_CHILD_EXTRA_ITEM,
  })

  return childExtraItemsApiClient.create(payload, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: CREATE_CHILD_EXTRA_ITEM_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: CREATE_CHILD_EXTRA_ITEM_FAILED,
      })

      throw new SubmissionError({
        ...error.response.extra,
        _error: error.response.message,
      })
    },
  )
}

export const updateExtraItem = (childId, id, payload, onSuccess) => ({ childExtraItemsApiClient }) => (dispatch) => {
  dispatch({
    childId,
    id,
    payload,
    type: UPDATE_CHILD_EXTRA_ITEM,
  })

  childExtraItemsApiClient.update(childId, id, payload).then(
    (data) => {
      dispatch({
        payload: data,
        type: UPDATE_CHILD_EXTRA_ITEM_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: UPDATE_CHILD_EXTRA_ITEM_FAILED,
      })

      throw new SubmissionError({
        ...error.response.extra,
        _error: error.response.message,
      })
    },
  )
}

export const deleteExtraItem = (childId, id, onSuccess) => ({ childExtraItemsApiClient }) => (dispatch) => {
  dispatch({
    childId,
    id,
    type: DELETE_CHILD_EXTRA_ITEM,
  })

  childExtraItemsApiClient.delete(childId, id).then(
    (data) => {
      dispatch({
        payload: data,
        type: DELETE_CHILD_EXTRA_ITEM_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: DELETE_CHILD_EXTRA_ITEM_FAILED,
      })
    },
  )
}

export const clearExtraItem = () => ({
  type: CLEAR_CHILD_EXTRA_ITEM,
})
