import { FEATURE_FLAGS, ROLES } from 'constants/security'

import * as securitySelectors from 'services/security/selectors'

const ManagementConsumablesWrapper = ({ children }) => children

ManagementConsumablesWrapper.authParams = (state) => {
  const isOrganisationNursery = securitySelectors.isOrganisationNursery(state.authentication)

  if (isOrganisationNursery) {
    return {
      flags: [FEATURE_FLAGS.FINANCE_MVP],
      roles: [
        ROLES.ORGANIZATION_DIRECTOR,
        ROLES.ORGANIZATION_NATIONAL_ADMIN,
        ROLES.ORGANIZATION_FINANCE_ADMIN,
        ROLES.ORGANIZATION_LINE_MANAGER,
        ROLES.NURSERY_MANAGER,
        ROLES.NURSERY_ADMIN,
        ROLES.SUPER_ADMIN,
      ],
    }
  }

  return {
    flags: [FEATURE_FLAGS.FINANCE_MVP],
    roles: [ROLES.NURSERY_MANAGER, ROLES.NURSERY_ADMIN, ROLES.SUPER_ADMIN],
  }
}

export default ManagementConsumablesWrapper
