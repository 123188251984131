import _ from 'lodash'

import React from 'react'

import { Spinner, Switch } from 'components'

export const getTableData = ({
  data,
  onFrameworkToggleChange,
  submittingItems,
}) => _.map(data, (item) => ({
  id: item.id,
  name: item.name,
  switch: submittingItems[item.id] ? <Spinner size="medium" /> : (
    <Switch
      value={item.enabled}
      simple
      onChange={() => onFrameworkToggleChange(item)}
    />
  ),
}))
