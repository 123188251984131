import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'

import { NURSERY_DISCOUNT_TYPE_OPTIONS } from 'services/product/nurseryDiscountsV3/constants'

import { generateRoute } from 'utils/routing'

import { withModalService } from 'services/utils/modal'
import { withAppService } from 'services/app'
import { withNurseryDiscountsService } from 'services/legacy/nurseryDiscounts'
import { withRouter } from 'services/router'

import { FORM_NAME } from './components/AddDiscountForm'

import ManagementDiscountsAddV2View from './ManagementDiscountsAddV2View'

const NURSERY_DISCOUNTS_GROUPS = {
  read: ['nurseryDiscount.nursery'],
}

class ManagementDiscountsAddV2Container extends Component {
  componentDidMount() {
    const { isEdit, nurseryDiscountsActions, params } = this.props
    const { itemId } = params

    if (isEdit) {
      nurseryDiscountsActions.get(itemId)
    }
  }

  componentWillUnmount() {
    const { nurseryDiscountsActions } = this.props

    nurseryDiscountsActions.clear()
  }

  goToDiscounts = () => {
    const { navigate } = this.props

    navigate(generateRoute('MANAGEMENT.FINANCE_SETTINGS.DISCOUNTS'))
  }

  handleSubmitSuccess = () => {
    this.goToDiscounts()
  }

  handleSubmit = (fields) => {
    const { isEdit, nurseryDiscountsActions, nurseryDiscountsSelectors, params } = this.props
    const { itemId } = params

    const payload = nurseryDiscountsSelectors.getPayload(fields)
    const apiParams = { groups: NURSERY_DISCOUNTS_GROUPS }

    if (isEdit) {
      return nurseryDiscountsActions.update(itemId, payload, apiParams, this.handleSubmitSuccess)
    }

    return nurseryDiscountsActions.create(payload, apiParams, this.handleSubmitSuccess)
  }

  archiveDiscount = (archived) => () => {
    const { nurseryDiscountsActions, nurseryDiscountsSelectors, params, type } = this.props
    const { itemId } = params

    const payload = nurseryDiscountsSelectors.getArchivePayload({ archived, type })

    return nurseryDiscountsActions.update(itemId, payload, {}, this.handleSubmitSuccess)
  }

  handleArchiveClick = (archived) => () => {
    const { modalActions, modalConsts } = this.props

    const label = archived ? 'archive' : 'unarchive'
    const text = archived
      ? `Are you sure you want to ${label} this discount? `
        .concat('Please note: this discount already allocated to children will not be affected.')
      : 'Are you sure you want to unarchive this discount ? You can archive it again at any time.'

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: label,
      icon: label,
      onConfirm: this.archiveDiscount(archived),
      text,
    })
  }

  render() {
    const {
      errorMessages,
      formValues,
      initialValues,
      isArchived,
      isEdit,
      isFetching,
      isPercentageSelected,
      nurseryDiscountsSingleState,
      nurseryOptions,
    } = this.props

    const isFormLoading = nurseryDiscountsSingleState.isSubmitting

    return (
      <ManagementDiscountsAddV2View
        discountTypeOptions={NURSERY_DISCOUNT_TYPE_OPTIONS}
        errorMessages={errorMessages}
        formValues={formValues}
        initialValues={initialValues}
        isArchived={isArchived}
        isEdit={isEdit}
        isFormLoading={isFormLoading}
        isLoading={isFetching}
        isPercentageSelected={isPercentageSelected}
        nurseryOptions={nurseryOptions}
        onArchiveClick={this.handleArchiveClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseryDiscountsSelectors,
  nurseryDiscountsSingleState,
  params,
}) => ({
  errorMessages: appSelectors.getErrorMessages(nurseryDiscountsSingleState),
  formValues: getFormValues(FORM_NAME)(state),
  initialValues: nurseryDiscountsSelectors.getInitialValues(!!params.itemId)(state),
  isArchived: nurseryDiscountsSelectors.isArchived(state),
  isEdit: !!params.itemId,
  isFetching: appSelectors.getIsFetching(nurseryDiscountsSingleState),
  isPercentageSelected: nurseryDiscountsSelectors.isPercentageSelected(FORM_NAME)(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  type: nurseryDiscountsSelectors.typeSelector(FORM_NAME)(state),
})

const enhance = compose(
  withRouter,
  withModalService,
  withAppService,
  withNurseryDiscountsService,
  connect(mapState),
)

export default enhance(ManagementDiscountsAddV2Container)
