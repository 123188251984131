import { compose } from 'recompose'

import React, { PropsWithChildren, useEffect, useLayoutEffect } from 'react'
import { ConnectedProps, connect } from 'react-redux'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import i18n from 'translations'

import { withShellService, withShellServiceProps } from 'services/shell'
import { withRouter, withRouterProps } from 'services/router'

import ChildExtraItemsWrapper from 'module/Children/Child/ChildBookingPattern/ChildExtraItems/ChildExtraItemsWrapper'
import ChildFundingWrapper from 'module/Children/Child/ChildBookingPattern/ChildFunding/ChildFundingWrapper'
import OneOffBookings from 'module/Finance/OneOffBookings/List'
import DepositsWrapper from 'module/Finance/Deposits/DepositsWrapper'

import ChildAdhocSessionsWrapper from './ChildAdhocSessions/ChildAdhocSessionsWrapper'
import ChildSessionsWrapper from './ChildSessions/ChildSessionsWrapperContainer'
import ChildDiscountsWrapper from './ChildDiscounts/ChildDiscountsWrapper'

type ChildBookingPatternContainerProps = withShellServiceProps
  & withRouterProps

const mapState = (state) => ({
  AdhocSessionsGranted: auth.SELECTORS.getComponentIsAuthorised(state, ChildAdhocSessionsWrapper),
  DepositsGranted: auth.SELECTORS.getComponentIsAuthorised(state, DepositsWrapper),
  DiscountsGranted: auth.SELECTORS.getComponentIsAuthorised(state, ChildDiscountsWrapper),
  ExtraItemsGranted: auth.SELECTORS.getComponentIsAuthorised(state, ChildExtraItemsWrapper),
  FundingGranted: auth.SELECTORS.getComponentIsAuthorised(state, ChildFundingWrapper),
  OneOffGranted: auth.SELECTORS.getComponentIsAuthorised(state, OneOffBookings),
  SessionsGranted: auth.SELECTORS.getComponentIsAuthorised(state, ChildSessionsWrapper),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const ChildBookingPatternContainer: React.FC<PropsWithChildren<ChildBookingPatternContainerProps & PropsFromRedux>> = ({
  AdhocSessionsGranted,
  DepositsGranted,
  DiscountsGranted,
  ExtraItemsGranted,
  FundingGranted,
  OneOffGranted,
  SessionsGranted,
  children,
  isFinanceV3Enabled,
  location,
  navigate,
  params,
  shellActions,
}) => {
  const { childId } = params
  const { pathname } = location

  const setMenu = () => {
    shellActions.setSubMenu([
      {
        icon: 'time',
        label: i18n.t('module:Finance:RegularBookings:title'),
        to: generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS', { childId }),
      },
      {
        hidden: !OneOffGranted,
        icon: 'sessions',
        label: i18n.t('module:Finance:OneOffBookings:title'),
        to: generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.ONE_OFF_BOOKINGS', { childId }),
      },
      {
        hidden: !DepositsGranted,
        icon: 'deposit',
        label: i18n.t('module:Finance:Deposits:title'),
        to: generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.DEPOSITS', { childId }),
      },
      {
        hidden: !FundingGranted,
        icon: 'funding',
        label: i18n.t('module:Children:Child:BookingPattern:Funding:title'),
        to: generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.FUNDING', { childId }),
      },
    ])
  }

  const setLegacyMenu = () => {
    shellActions.setSubMenu([
      {
        hidden: !SessionsGranted,
        icon: 'sessions',
        label: i18n.t('module:Children:Child:BookingPattern:Sessions:title'),
        to: generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS', { childId }),
      },
      {
        hidden: !AdhocSessionsGranted,
        icon: 'extra-sessions',
        label: i18n.t('module:Children:Child:BookingPattern:ExtraSessions:title'),
        to: generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_SESSIONS', { childId }),
      },
      {
        hidden: !ExtraItemsGranted,
        icon: 'plus',
        label: i18n.t('module:Children:Child:BookingPattern:ExtraItems:title'),
        to: generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_ITEMS', { childId }),
      },
      {
        hidden: !DiscountsGranted,
        icon: 'discount',
        label: i18n.t('module:Children:Child:BookingPattern:Discounts:title'),
        to: generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.DISCOUNTS', { childId }),
      },
      {
        hidden: !FundingGranted,
        icon: 'funding',
        label: i18n.t('module:Children:Child:BookingPattern:Funding:title'),
        to: generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.FUNDING', { childId }),
      },
      {
        hidden: !DepositsGranted,
        icon: 'deposit',
        label: i18n.t('module:Finance:Deposits:title'),
        to: generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.DEPOSITS', { childId }),
      },
    ])
  }

  useEffect(() => {
    if (undefined !== isFinanceV3Enabled && null !== isFinanceV3Enabled) {
      (isFinanceV3Enabled ? setMenu : setLegacyMenu)()
    }
  }, [isFinanceV3Enabled])

  useLayoutEffect(() => () => {
    shellActions.clearSubMenu()
  }, [])

  useEffect(() => {
    if (undefined !== isFinanceV3Enabled && null !== isFinanceV3Enabled) {
      if (isFinanceV3Enabled && generateRoute('CHILDREN.CHILD.BOOKING_PATTERN', { childId }) === pathname) {
        navigate(generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS', { childId }))
      }

      if (!isFinanceV3Enabled && generateRoute('CHILDREN.CHILD.BOOKING_PATTERN', { childId }) === pathname) {
        navigate(generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS', { childId }))
      }
    }
  }, [pathname, isFinanceV3Enabled])

  if (undefined === isFinanceV3Enabled || null === isFinanceV3Enabled) {
    return null
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <React.Fragment>
      {children}
    </React.Fragment>
  )
}

const enhance = compose(
  withRouter,
  withShellService,
  connect(mapState),
)

export default enhance(ChildBookingPatternContainer)
