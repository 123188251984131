/* eslint-disable no-useless-escape */
export const getSubdomainFromName = (name) => {
  if (!name) {
    return ''
  }

  return name
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with singleLegacy -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export const getUsernameFromName = (name) => {
  if (!name) {
    return ''
  }

  return name
    .toLowerCase()
    .replace(/\s+/g, '') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with singleLegacy -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}
