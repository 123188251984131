import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as roomMoveSelectors from './selectors'

const withRoomMoveService = (WrappedComponent) => {
  const mapState = ({ roomMove }) => ({ roomMove })

  const mapDispatch = injectActions('roomMoveActions', actions)

  const Component = ({ roomMoveActions, ...others }) => (
    <WrappedComponent
      roomMoveActions={roomMoveActions}
      roomMoveSelectors={roomMoveSelectors}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withRoomMoveService
