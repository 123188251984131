import {
  CLEAR_DAILY_DIARY_ACTIVITIES,
  GET_DAILY_DIARY_ACTIVITIES,
  GET_DAILY_DIARY_ACTIVITIES_FAILED,
  GET_DAILY_DIARY_ACTIVITIES_SUCCESS,
  UPDATE_ACTIVITY_MEDIA,
} from './constants'

export const list = (options = {}) => ({ dailyDiaryActivitiesApiClientV2 }) => (dispatch) => {
  const { mergeResult, onSuccess, params, silent } = options

  dispatch({
    silent,
    type: GET_DAILY_DIARY_ACTIVITIES,
  })

  dailyDiaryActivitiesApiClientV2.list(params).then(
    (response) => {
      dispatch({
        mergeResult,
        payload: response,
        type: GET_DAILY_DIARY_ACTIVITIES_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: GET_DAILY_DIARY_ACTIVITIES_FAILED,
      })
    },
  )
}

export const clearList = () => ({
  type: CLEAR_DAILY_DIARY_ACTIVITIES,
})

export const updateActivityMedia = (activityId, media) => ({
  payload: { activityId, media },
  type: UPDATE_ACTIVITY_MEDIA,
})
