import styled from 'styled-components'

export const StyledWidgetContainer = styled.section`
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(4, 340px);
  justify-content: center;
  
  @media(max-width: 1470px) {
    grid-template-columns: repeat(3, 340px);
  }
  
  @media(max-width: 1080px) {
    grid-template-columns: 50% 50%;
  }
  
  @media(max-width: 680px) {
    grid-template-columns: 100%;
  }
`
