import React from 'react'

import RegisterItemMainActions from '../RegisterItemMainActions'
import RegisterItemAction from '../RegisterItemAction'

import { REGISTER_ACTION_TYPES, REGISTER_COLORS } from '../../../constants'

const RegisterItemContentAbsence = ({
  absence,
  expandType,
  onAbsenceActionClick,
  present,
  signIns,
  size,
}) => {
  const getAbsenceTitle = () => {
    if (absence) {
      return {
        ABSENCE: 'Absent details',
        HOLIDAY: 'Holiday details',
      }[absence.absenceType]
    }

    return 'Absent'
  }

  const absenceTitle = getAbsenceTitle()

  if (signIns) {
    return null
  }

  const { ABSENCE } = REGISTER_ACTION_TYPES

  // NOTE: initially present is null therefore explicitly expecting bool value true/false
  if (false === present && expandType === ABSENCE) {
    return (
      <RegisterItemMainActions.Item size={size}>
        <RegisterItemAction
          color={REGISTER_COLORS.absence}
          icon="chevron-up"
          title="Hide"
          hasData
          onClick={onAbsenceActionClick}
        />
      </RegisterItemMainActions.Item>
    )
  }

  return (
    <RegisterItemMainActions.Item size={size}>
      <RegisterItemAction
        color={REGISTER_COLORS.absence}
        hasData={!!absence || expandType === ABSENCE}
        icon="minus"
        title={absenceTitle}
        onClick={onAbsenceActionClick}
      />
    </RegisterItemMainActions.Item>
  )
}

export default RegisterItemContentAbsence
