import React from 'react'
import { connect } from 'react-redux'

import actions from './actions'
import * as helpers from './helpers'
import selectors from './selectors'

const withChildAdhocSessionsService = (WrappedComponent) => {
  const mapState = ({ childAdhocSessions }) => ({ childAdhocSessions })

  const mapDispatch = {
    archive: actions.archiveAdhocSession,
    clear: actions.clearAdhocSession,
    clearSingle: actions.clearAdhocSessionSingle,
    create: actions.createAdhocSession,
    createBulk: actions.createBulkAdhocSession,
    get: actions.getAdhocSession,
    list: actions.listAdhocSessions,
    update: actions.updateAdhocSession,
  }

  const Component = (props) => {
    const { archive, childAdhocSessions, clear, clearSingle, create, createBulk, get, list, update, ...other } = props

    const childAdhocSessionsListState = {
      ...childAdhocSessions.list,
      isEmpty: childAdhocSessions.list.data && 0 === childAdhocSessions.list.data.length,
    }

    const getAllChildAdhocSessions = (childId, params, v2, page = 1) => {
      list(childId, { ...params, page }, v2, 1 < page, ({ meta }) => {
        const { limit, start } = meta
        const totalResults = meta.total_results

        if (start * limit < totalResults) {
          getAllChildAdhocSessions(childId, params, v2, page + 1)
        }
      })
    }

    const childAdhocSessionsSingleState = childAdhocSessions.single
    const childAdhocSessionsActions = { archive, clear, clearSingle, create, createBulk, get, list, update }
    const childAdhocSessionsHelpers = { ...helpers, getAllChildAdhocSessions }
    const childAdhocSessionsSelectors = selectors

    return (
      <WrappedComponent
        childAdhocSessionsActions={childAdhocSessionsActions}
        childAdhocSessionsHelpers={childAdhocSessionsHelpers}
        childAdhocSessionsListState={childAdhocSessionsListState}
        childAdhocSessionsSelectors={childAdhocSessionsSelectors}
        childAdhocSessionsSingleState={childAdhocSessionsSingleState}
        {...other}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withChildAdhocSessionsService
