import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as membershipsLeavesSelectors from './selectors'

const withMembershipsLeavesService = (WrappedComponent) => {
  const mapState = ({ membershipsLeaves }) => ({ membershipsLeaves })

  const mapDispatch = injectActions('membershipsLeavesActions', actions)

  const Component = ({ membershipsLeaves, membershipsLeavesActions, ...others }) => (
    <WrappedComponent
      membershipsLeavesActions={membershipsLeavesActions}
      membershipsLeavesListState={membershipsLeaves.list}
      membershipsLeavesSelectors={membershipsLeavesSelectors}
      membershipsLeavesStatisticsState={membershipsLeaves.statistics}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withMembershipsLeavesService
