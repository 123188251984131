import {
  CLEAR_NEWSLETTERS_LIST,
  GET_NEWSLETTERS_LIST,
  GET_NEWSLETTERS_LIST_FAILED,
  GET_NEWSLETTERS_LIST_SUCCESS,
  REMOVE_NEWS_ITEM,
} from './constants'

export const list = (params, mergeResult, onSuccess = () => {}) => ({ newslettersApiClient }) => (dispatch) => {
  dispatch({
    type: GET_NEWSLETTERS_LIST,
  })

  newslettersApiClient.list(params).then(
    (response) => {
      dispatch({
        mergeResult,
        payload: response,
        type: GET_NEWSLETTERS_LIST_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => dispatch({
      error: response || {},
      type: GET_NEWSLETTERS_LIST_FAILED,
    }),
  )
}

export const removeNewsItem = (newsId) => ({
  newsId,
  type: REMOVE_NEWS_ITEM,
})

export const clear = () => ({
  type: CLEAR_NEWSLETTERS_LIST,
})
