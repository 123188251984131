import React from 'react'

import { Page, ReportView as ReportViewComponent, SearchBar } from 'components'

import i18n from 'translations'

const ReportView = ({ items, onSearchChange }) => (
  <React.Fragment>
    <SearchBar
      placeholder={i18n.t('module:Enquiries:Report:searchPlaceholder')}
      onChange={onSearchChange}
    />
    <Page.Section title={i18n.t('module:Enquiries:Report:title')}>
      <ReportViewComponent items={items} />
    </Page.Section>
  </React.Fragment>

)

export default ReportView
