import colorLibrary from 'color'
import styled from 'styled-components'
import { Tab, Tabs } from '@material-ui/core'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

import { StyledWrapper } from 'components/Tooltip/TooltipStyled'

interface StyledTabsContainerProps {
  $noMargin?: boolean
}

export const StyledTabsContainer = styled.div<StyledTabsContainerProps>`
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  overflow: hidden;
  
  ${({ $noMargin }) => $noMargin && `
    margin-bottom: 0;
  `}
`

interface StyledTabsProps {
  $buttonType?: boolean
  $gray?: boolean
}

export const StyledTabs = styled(Tabs)<StyledTabsProps>`
  && {
    border-bottom: 1px solid #989898;
    
    ${({ $gray }) => $gray && `
       border-bottom: none;
    `}
  
    .MuiTabs-indicator {
      background-color: ${getBrandingColor('primary-color')};
    }
    
    .MuiTabs-scrollButtons:not(.MuiButtonBase-root) {
      width: 0;
    }
    
    ${({ $buttonType }) => $buttonType && `
      border-bottom: 0;
      margin: 0 -5px;

      .MuiTabs-indicator {
        display: none;
      }
    `}
  }
`

interface StyledTabProps {
  $buttonType?: boolean
  $itemFullWidth?: boolean
}

export const StyledTabWrapper = styled.div`
  padding: 15px 25px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledTab = styled(Tab)<StyledTabProps>`
  && {
    color: #ADAEAD;
    padding: 0;
    font-weight: 600;
    font-family: inherit;
    font-size: inherit;
    text-transform: none;
    opacity: 1;
    min-width: ${({ $minWidth }) => $minWidth || '140px'};
    flex: ${({ $itemFullWidth }) => ($itemFullWidth ? 1 : 'none')};
    line-height: 18px;
    pointer-events: auto !important;
    
    ${StyledWrapper} {
      height: 100%;
      width: 100%;
    }
    
    ${({ $itemFullWidth }) => $itemFullWidth && `
      max-width: none;    
    `};

    &.Mui-selected {
      color: ${getBrandingColor('primary-color')};
      background: ${colorLibrary(getBrandingColor('primary-color')).alpha(0.2).rgb().string()};
    }

    &.Mui-disabled {
      cursor: pointer;
    }
    
    ${({ disabled }) => disabled && `
      color: ${NEUTRAL_COLOURS.GRAY_SECONDARY};
    `}

    ${({ $buttonType }) => $buttonType && `
      border-radius: 22px;
      padding: 15px 25px 12px;
      margin: 0 5px;
      background: ${getBrandingColor('primary-color')};
      color: ${NEUTRAL_COLOURS.WHITE};
      text-transform: uppercase;
      max-width: none;

      &.Mui-selected {
        color: ${NEUTRAL_COLOURS.WHITE};
        background: ${getBrandingColor('senary-color')};
      }
    `}
    
    .MuiTab-wrapper {
      height: 100%;
    }
  }
`

interface StyledBodyContentProps {
  $fullHeight?: boolean
  $gray?: boolean
  $noContentPadding?: boolean
  $visible?: boolean
}

export const StyledBodyContent = styled.div<StyledBodyContentProps>`
  padding: ${({ $noContentPadding }) => ($noContentPadding ? '0' : '17px 0')} ;
  display: none;

  ${({ $visible }) => $visible && `
    display: block;
  `}
  
  ${({ $gray }) => $gray && `
    padding-left: 20px;
    padding-right: 20px;
    background: #EDEDED;
  `}

  ${({ $fullHeight }) => $fullHeight && `
    height: 100%;
  `}
`
