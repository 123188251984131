import React from 'react'

import { StyledContainer } from './RegisterItemMainActionsStyled'

const RegisterItemMainActions = ({ children, isEditing, showSeparator, size }) => (
  <StyledContainer isEditing={isEditing} showSeparator={showSeparator} size={size}>
    {children}
  </StyledContainer>
)

export default RegisterItemMainActions
