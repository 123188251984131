import _ from 'lodash'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { COMPLETED_STATE, WORKING_ON_STATE } from 'services/legacy/frameworkProgressLevels/constants'

import { generateRoute } from 'utils/routing'

import { filterGenderComparisonStatements } from 'services/legacy/cohortTracking/comparisonReportSingle/selectors'

import { Avatar, Space, Tabs, Typography } from 'components'

import i18n from 'translations'

import {
  StyledRectangle,
  StyledStatementRow,
  StyledStatementsWrapper,
  StyledTabsWrapper,
  StyledTitleWrapper,
} from './ComparisonTableStyled'

const ComparisonTable = ({
  comparisonReportTableData,
  frameworkDetails,
  frameworkGroup,
  onChangeSelectedProgressLevel,
  progressLevels,
  selectedProgressLevel,
}) => {
  const { structure } = frameworkDetails || {}
  const { cohort } = structure || {}
  const { comparison } = cohort || {}

  if (!progressLevels?.length || !selectedProgressLevel) {
    return null
  }

  const renderStatementRow = ({ frameworkItem, progressLevel }) => (
    <StyledStatementRow
      key={frameworkItem.id}
      oneColumn={!comparison?.category?.visible || !comparison?.item?.visible}
    >
      {comparison?.category?.visible && (
        <Typography color={progressLevel.colour}>
          {frameworkItem.category.name.replace(` ${i18n.t('global:months')}`, '')}
        </Typography>
      )}
      {comparison?.item?.visible && (
        <Typography>
          {frameworkItem.description}
        </Typography>
      )}
    </StyledStatementRow>
  )

  const renderStatements = (report) => {
    if (!comparison?.item?.visible && !comparison?.category?.visible) {
      return null
    }

    let fullFrameworkGroup = {}
    _.each(frameworkDetails.areas, ({ groups }) => {
      _.each(groups, (group) => {
        if (group.id === frameworkGroup.id) {
          fullFrameworkGroup = group
        }
      })
    })

    const statements = filterGenderComparisonStatements(report, fullFrameworkGroup)

    if (!statements[WORKING_ON_STATE].length && !statements[COMPLETED_STATE].length) {
      return (
        <StyledStatementsWrapper>
          {i18n.t('module:Learning:CohortTracking:ComparisonReport:comparisonTable:noStatementsFound')}
        </StyledStatementsWrapper>
      )
    }

    return (
      <StyledStatementsWrapper>
        {statements[WORKING_ON_STATE]?.length ? (
          <Typography margin="0 0 15px" bold>
            {i18n.t('module:Learning:CohortTracking:ComparisonReport:comparisonTable:workingOn')}
          </Typography>
        ) : null}
        {_.map(statements[WORKING_ON_STATE], renderStatementRow)}
        {statements[WORKING_ON_STATE]?.length ? (
          <Space space="40px" />
        ) : null}
        {statements[COMPLETED_STATE]?.length ? (
          <Typography margin="0 0 15px" bold>
            {i18n.t('module:Learning:CohortTracking:ComparisonReport:comparisonTable:completed')}
          </Typography>
        ) : null}
        {_.map(statements[COMPLETED_STATE], renderStatementRow)}
      </StyledStatementsWrapper>
    )
  }

  const renderRow = ({ child, report }) => (
    <React.Fragment key={child.id}>
      <Space space="20px" />
      <div>
        <Avatar
          initials={[child.firstName, child.surname]}
          src={child.photo}
          subTitle={(
            <Typography color={NEUTRAL_COLOURS.BASIC}>
              {i18n.t('global:ageMonths', { ageMonths: report.childSnapshot.ageMonths })}
            </Typography>
          )}
          target="_blank"
          title={(
            <Typography fontSize={17} bold>
              {`${child.firstName} ${child.surname}`}
            </Typography>
          )}
          to={generateRoute('CHILDREN.CHILD', { childId: child.id })}
        />
        {renderStatements(report)}
      </div>
    </React.Fragment>
  )

  const renderContent = () => (
    <React.Fragment>
      <Typography bold>
        {i18n.t('module:Learning:CohortTracking:ComparisonReport:comparisonTable:totalChildren')}
        {': '}
        {comparisonReportTableData.length}
      </Typography>
      {_.map(comparisonReportTableData, renderRow)}
    </React.Fragment>
  )

  return (
    <StyledTabsWrapper>
      <Tabs
        value={selectedProgressLevel}
        fullWidth
        gray
        onChange={onChangeSelectedProgressLevel}
      >
        {_.map(progressLevels, (level) => (
          <Tabs.Item
            key={level.id}
            name={level.id}
            title={(
              <StyledTitleWrapper>
                <StyledRectangle color={level.colour} />
                <Typography
                  color={NEUTRAL_COLOURS.BASIC}
                  bold
                >
                  {level.name}
                </Typography>
              </StyledTitleWrapper>
            )}
          >
            {renderContent()}
          </Tabs.Item>
        ))}
      </Tabs>
    </StyledTabsWrapper>
  )
}

export default ComparisonTable
