import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_ENQUIRY_SETTINGS_SINGLE,
  CREATE_ENQUIRY_SETTINGS,
  CREATE_ENQUIRY_SETTINGS_FAILED,
  CREATE_ENQUIRY_SETTINGS_SUCCESS,
  GET_ENQUIRY_SETTINGS,
  GET_ENQUIRY_SETTINGS_FAILED,
  GET_ENQUIRY_SETTINGS_SUCCESS,
  UPDATE_ENQUIRY_SETTINGS,
  UPDATE_ENQUIRY_SETTINGS_FAILED,
  UPDATE_ENQUIRY_SETTINGS_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_ENQUIRY_SETTINGS_SINGLE,
    createFailed: CREATE_ENQUIRY_SETTINGS_FAILED,
    createInit: CREATE_ENQUIRY_SETTINGS,
    createSuccess: CREATE_ENQUIRY_SETTINGS_SUCCESS,
    itemFailed: GET_ENQUIRY_SETTINGS_FAILED,
    itemInit: GET_ENQUIRY_SETTINGS,
    itemSuccess: GET_ENQUIRY_SETTINGS_SUCCESS,
    updateFailed: UPDATE_ENQUIRY_SETTINGS_FAILED,
    updateInit: UPDATE_ENQUIRY_SETTINGS,
    updateSuccess: UPDATE_ENQUIRY_SETTINGS_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
