import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { NEUTRAL_COLOURS } from 'constants/colors'
import { SHIFT_MAIN_TYPES } from 'services/legacy/membershipRegisters/constants'
import { LEAVE_SHIFT_TYPE_ORDER } from 'services/legacy/membershipsLeaves/constants'

import { addMillisecondsFromMidnight, millisecondsToHoursAndMinutesString } from 'utils/date'
import { sortByKey } from 'utils/data'

import { Space, Typography } from 'components'

import i18n from 'translations'

import { TimeCallout } from 'module/Staff/components'

import { StyledHeaderWrapper, StyledItemBox, StyledShiftTimesWrapper } from './RecordContentStyled'

const HOURS_MINUTES_FORMAT = 'HH:mm'
const SHORT_DAY_FORMAT = 'ddd'

const RecordContent = ({
  currentDate,
  leaves,
  week,
  worklogItems,
}) => {
  const date = moment(currentDate).format(DEFAULT_DATE_FORMAT)
  const shiftNames = {}
  const record = {
    leaves: {},
    worklog: {
      signin: [],
      break: [], // eslint-disable-line sort-keys, sort-keys-fix/sort-keys-fix
    },
  }

  let isOverlappingDay = false
  const isStartOfWeekDay = moment(week).format(DEFAULT_DATE_FORMAT) === date
  let wasOngoing = false
  let ongoing = false
  let summaryWork = 0
  let summaryLeave = 0

  const renderShiftTime = (shiftDate, isSameDayEntry) => {
    if (isSameDayEntry) {
      return moment(shiftDate).format(HOURS_MINUTES_FORMAT)
    }

    return `${moment(shiftDate).format(HOURS_MINUTES_FORMAT)} (${moment(shiftDate).format(SHORT_DAY_FORMAT)})`
  }

  const renderContent = (groupOfShifts, mainType) => _.map(groupOfShifts, (group, type) => {
    if (!_.keys(group).length) {
      return null
    }

    return (
      <React.Fragment>
        {_.keys(groupOfShifts)[0] !== type && (
          <Space space="15px" />
        )}
        <Typography fontSize={12} margin="5px 0 0" maxWidth={100} bold ellipsis>
          {mainType === SHIFT_MAIN_TYPES.WORK && i18n.t(`module:Modals:Staff:Shift:actualTypes:${type}`)}
          {mainType === SHIFT_MAIN_TYPES.LEAVE && shiftNames[type]}
        </Typography>
        {_.map(_.keys(group).sort(), (nurseryClassId) => {
          const shiftTimes = group[nurseryClassId]

          return (
            <StyledShiftTimesWrapper
              first={_.keys(group)[0] === nurseryClassId}
            >
              {mainType === SHIFT_MAIN_TYPES.WORK && _.map(shiftTimes, ({ isSameDayEntry, signInAt, signOutAt }) => (
                <Typography fontSize={14}>
                  {renderShiftTime(signInAt, isSameDayEntry)}
                  {' - '}
                  {signOutAt
                    ? renderShiftTime(signOutAt, isSameDayEntry)
                    : i18n.t('module:Staff:StaffAttendance:ongoing')}
                </Typography>
              ))}
              {mainType === SHIFT_MAIN_TYPES.LEAVE && _.map(shiftTimes, ({ endTime, startTime }) => (
                <Typography fontSize={14}>
                  {addMillisecondsFromMidnight(startTime).format(HOURS_MINUTES_FORMAT)}
                  {' - '}
                  {addMillisecondsFromMidnight(endTime).format(HOURS_MINUTES_FORMAT)}
                </Typography>
              ))}
              {shiftTimes[0]?.nurseryClass?.label && (
                <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={12} margin="5px 0 0" bold>
                  {shiftTimes[0].nurseryClass.label}
                </Typography>
              )}
            </StyledShiftTimesWrapper>
          )
        })}
      </React.Fragment>
    )
  })

  _.each(worklogItems, (dayRegister) => {
    const { ongoing: ongoingItem, worklog } = dayRegister

    if (ongoingItem) {
      ongoing = true
    }

    if (
      date < worklog?.signin?.endDate.format(DEFAULT_DATE_FORMAT)
      || date < worklog?.break?.endDate.format(DEFAULT_DATE_FORMAT)) {
      wasOngoing = true
    }

    if (
      date > worklog?.signin?.startDate.format(DEFAULT_DATE_FORMAT)
      || date > worklog?.break?.startDate.format(DEFAULT_DATE_FORMAT)) {
      isOverlappingDay = true
    }

    if (worklog?.signin?.entries?.length) {
      record.worklog.signin = [
        ...(record.worklog.signin || []),
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...worklog.signin?.entries,
      ]
    }

    if (worklog?.break?.entries?.length) {
      record.worklog.break = [
        ...record.worklog.break,
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...worklog.break?.entries,
      ]
    }
  })

  record.leaves = _.concat(
    ..._.map(leaves, ({ leaveShiftType, membershipShiftTimes }) => (
      _.map(membershipShiftTimes, (item) => {
        shiftNames[leaveShiftType?.code] = leaveShiftType?.name

        return ({
          ...item,
          type: leaveShiftType?.code,
        })
      })
    )),
  )

  record.leaves = _.map(record.leaves, ({ class: nurseryClass, ...rest }) => ({
    ...rest,
    nurseryClass,
  }))

  record.leaves = _.filter(record.leaves, (({ day: shiftTimeDay }) => (
    moment(date).format(DEFAULT_DATE_FORMAT)
    === moment(shiftTimeDay).format(DEFAULT_DATE_FORMAT)
  )))

  _.each(record.leaves, ({ endTime, startTime }) => {
    summaryLeave += (endTime - startTime)
  })

  record.leaves = _.groupBy(record.leaves, 'type')
  record.leaves = sortByKey(record.leaves, LEAVE_SHIFT_TYPE_ORDER)
  record.leaves = _.mapValues(record.leaves, (groupOfShiftTimes) => (
    _.groupBy(groupOfShiftTimes, ({ nurseryClass }) => nurseryClass?.id || 0)
  ))

  _.each(record.worklog.signin, ({ signInAt, signOutAt }) => {
    if (signOutAt) {
      summaryWork += moment(signOutAt).diff(moment(signInAt))
    }
  })

  record.worklog.signin = _.groupBy(
    _.sortBy(record.worklog.signin, ({ signInAt }) => signInAt.valueOf()),
    ({ signInAt }) => signInAt.valueOf(),
  )
  record.worklog.break = _.groupBy(
    _.sortBy(record.worklog.break, ({ signInAt }) => signInAt.valueOf()),
    ({ signInAt }) => signInAt.valueOf(),
  )

  if (!_.keys(record.worklog.signin).length) {
    delete record.worklog.signin
  }

  if (!_.keys(record.worklog.break).length) {
    delete record.worklog.break
  }

  const workContent = renderContent(record.worklog, SHIFT_MAIN_TYPES.WORK)
  const leaveContent = renderContent(record.leaves, SHIFT_MAIN_TYPES.LEAVE)

  if (!workContent?.length && !leaveContent?.length) {
    return `0${i18n.t('global:hourShortcut')}`
  }

  return (
    <StyledItemBox>
      <TimeCallout
        content={workContent}
        header={(
          <StyledHeaderWrapper>
            <Typography fontSize={12} bold>
              {i18n.t('module:Modals:Staff:Shift:actualTypes:signin')}
            </Typography>
            <Typography fontSize={12} bold>
              {ongoing
                ? i18n.t('module:Staff:StaffAttendance:ongoing')
                : millisecondsToHoursAndMinutesString(summaryWork) || `0${i18n.t('global:hourShortcut')}`}
            </Typography>
          </StyledHeaderWrapper>
        )}
        isOngoing={ongoing || wasOngoing}
        isOverlapping={isOverlappingDay && !isStartOfWeekDay}
        type={SHIFT_MAIN_TYPES.WORK}
      />
      <TimeCallout
        content={leaveContent}
        header={(
          <StyledHeaderWrapper>
            <Typography fontSize={12} bold>
              {i18n.t('services:MembershipRegisters:mainTypes:leave')}
            </Typography>
            <Typography fontSize={12} bold>
              {millisecondsToHoursAndMinutesString(summaryLeave) || `0${i18n.t('global:hourShortcut')}`}
            </Typography>
          </StyledHeaderWrapper>
        )}
        type={SHIFT_MAIN_TYPES.LEAVE}
      />
    </StyledItemBox>
  )
}

export default RecordContent

