import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { StyledLoader } from 'components/InfiniteScroll/InfiniteScrollStyled'

export const StyledEmptyState = styled.div`
  display: grid;
  grid-row-gap: 15px;
  text-align: center;
  max-width: 280px;
  line-height: 24px;
  margin: 40px auto 0;
`

export const StyledWrapper = styled.div`
  ${StyledLoader} {
    margin-top: 15px;
  }
`

export const StyledAreaContainer = styled.div`
  margin: 5px 0 5px -8px;
  display: flex;
`

export const StyledArea = styled.div`
  margin-left: 8px;
  display: grid;
  align-items: center;
  grid-template-columns: 15px auto;
  grid-column-gap: 4px;
`

export const StyledSquare = styled.div`
  height: 15px;
  width: 15px;
  border: 2px solid ${NEUTRAL_COLOURS.WHITE};
  border-radius: 4px;
  background: ${({ color }) => color};
`
