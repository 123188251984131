export const ADD_CHILDREN_TO_INJURY = 'ADD_CHILDREN_TO_INJURY'
export const CLEAR_SINGLE_INJURY = 'CLEAR_SINGLE_INJURY'

export const GET_INJURY = 'GET_INJURY'
export const GET_INJURY_SUCCESS = 'GET_INJURY_SUCCESS'
export const GET_INJURY_FAILED = 'GET_INJURY_FAILED'

export const REMOVE_CHILDREN_FROM_INJURY = 'REMOVE_CHILDREN_FROM_INJURY'

export const UPDATE_INJURY = 'UPDATE_INJURY'
export const UPDATE_INJURY_SUCCESS = 'UPDATE_INJURY_SUCCESS'
export const UPDATE_INJURY_FAILED = 'UPDATE_INJURY_FAILED'

export const CREATE_INJURY = 'CREATE_INJURY'
export const CREATE_INJURY_SUCCESS = 'CREATE_INJURY_SUCCESS'
export const CREATE_INJURY_FAILED = 'CREATE_INJURY_FAILED'

export const REMOVE_INJURY = 'REMOVE_INJURY'
export const REMOVE_INJURY_SUCCESS = 'REMOVE_INJURY_SUCCESS'
export const REMOVE_INJURY_FAILED = 'REMOVE_INJURY_FAILED'

export const SEND_INJURY_REPORT = 'SEND_INJURY_REPORT'
export const SEND_INJURY_REPORT_SUCCESS = 'SEND_INJURY_REPORT_SUCCESS'
export const SEND_INJURY_REPORT_FAILED = 'SEND_INJURY_REPORT_FAILED'

export const UPDATE_INJURY_SIGNATURE_COUNT = 'UPDATE_INJURY_SIGNATURE_COUNT'
export const UPDATE_INJURY_STATUS = 'UPDATE_INJURY_STATUS'
export const UPDATE_INJURY_SIGNATURE_COLLECTING_TYPE = 'UPDATE_INJURY_SIGNATURE_COLLECTING_TYPE'
export const UPDATE_SINGLE_INJURY_SIGNATURE_STATISTICS = 'UPDATE_SINGLE_INJURY_SIGNATURE_STATISTICS'
