import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as subdomainSelectors from './single/selectors'

export interface withSubdomainServiceProps {
  subdomainActions: typeof actions
  subdomainSelectors: typeof subdomainSelectors
}

const withSubdomainService = <P extends withSubdomainServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapDispatch = injectActions('subdomainActions', actions)

  const Component = ({ subdomainActions, ...others }) => (
    <WrappedComponent
      subdomainActions={subdomainActions}
      subdomainSelectors={subdomainSelectors}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(null, mapDispatch)(Component)
}

export default withSubdomainService
