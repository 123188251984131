import React, { useMemo } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Field, InjectedFormProps, formValueSelector, reduxForm } from 'redux-form'

import { PeriodType } from 'services/booking/periods/models'

import { isRequired } from 'utils/fieldValidation'

import { Form } from 'components'

import i18n from 'translations'

import { AddViewProps } from '../AddView'
import { ATTENDANCE_PERIOD_TYPE_OPTIONS } from '../../constant'

export const ADD_ATTENDANCE_PERIOD = 'ADD_ATTENDANCE_PERIOD'

interface AddFormProps extends AddViewProps {
  cancelLink: string
  periodType?: string
}

type AddFormFullProps = InjectedFormProps<{}, AddFormProps> & AddFormProps

const AddForm: React.FC<AddFormFullProps> = ({
  cancelLink,
  handleSubmit,
  isEdit,
  isSubmitting,
  onSubmit,
  periodType,
}) => {
  const submitLabel = useMemo(() => {
    if (periodType === PeriodType.TERM_DATES) {
      return isEdit
        ? i18n.t('module:Management:AttendancePeriods:Add:termDateEditSubmitLabel')
        : i18n.t('module:Management:AttendancePeriods:Add:termDateAddSubmitLabel')
    }

    return isEdit ? i18n.t('global:Update') : i18n.t('global:Save')
  }, [periodType])

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Row label={i18n.t('module:Management:AttendancePeriods:Add:Form:Name:label')} width={{ field: '320px' }}>
        <Field
          component={Form.TextField}
          name="name"
          placeholder={i18n.t('module:Management:AttendancePeriods:Add:Form:Name:placeholder')}
          validate={isRequired}
        />
      </Form.Row>
      <Form.Row label={i18n.t('module:Management:AttendancePeriods:Add:Form:Period:label')} width={{ field: '200px' }}>
        <Field
          component={Form.RadioGroup}
          name="periodType"
          options={ATTENDANCE_PERIOD_TYPE_OPTIONS}
          validate={isRequired}
        />
      </Form.Row>
      <Form.FooterActions
        cancelLink={cancelLink}
        isSubmitting={isSubmitting}
        submitLabel={submitLabel}
      />
    </Form>
  )
}

const mapState = (state) => ({
  periodType: formValueSelector(ADD_ATTENDANCE_PERIOD)(state, 'periodType'),
})

const enhance = compose(
  reduxForm({ form: ADD_ATTENDANCE_PERIOD }),
  connect(mapState),
)

export default enhance(AddForm)
