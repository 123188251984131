import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Banner, Page, Section, Typography } from 'components'

import i18n from 'translations'

import { SessionTimeout } from './components'

export interface SecuritySettingsProps {
  sessionExpireTime: number
  sessionExpireTimeEnabled: boolean
}

interface GeneralSecuritySettingsViewProps {
  isFetching?: boolean
  isInheritedFromOrganization?: boolean
  isOrganizationContext?: boolean
  nurseryName?: string
  onSessionTimeoutSubmit: (formValues: any) => void
  securitySettings: SecuritySettingsProps
}

const GeneralSecuritySettingsView: React.FC<GeneralSecuritySettingsViewProps> = ({
  isFetching,
  isInheritedFromOrganization,
  isOrganizationContext,
  nurseryName,
  onSessionTimeoutSubmit,
  securitySettings,
}) => {
  if (isOrganizationContext) {
    const title = (
      <span>
        {i18n.t('module:Settings:Security:Item:title')}
        {' '}
        <Typography color={NEUTRAL_COLOURS.BLUE} fontSize="inherit" variant="span" bold>
          {nurseryName}
        </Typography>
      </span>
    )

    return (
      <Section title={title}>
        <SessionTimeout
          isFetching={isFetching}
          values={securitySettings}
          onSubmit={onSessionTimeoutSubmit}
        />
      </Section>
    )
  }

  return (
    <Page.Section
      title={i18n.t('module:Management:Security:GeneralSecuritySettings:title')}
    >
      {isInheritedFromOrganization && (
        <Banner.Info>
          {i18n.t('module:Management:Security:GeneralSecuritySettings:organizationInheritBanner')}
        </Banner.Info>
      )}
      <SessionTimeout
        isFetching={isFetching}
        isInheritedFromOrganization={isInheritedFromOrganization}
        values={securitySettings}
        onSubmit={onSessionTimeoutSubmit}
      />
    </Page.Section>
  )
}

export default GeneralSecuritySettingsView
