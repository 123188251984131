import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_ENQUIRY_CONVERSION_RATE_STATISTICS,
  GET_ENQUIRY_CONVERSION_RATE_STATISTICS,
  GET_ENQUIRY_CONVERSION_RATE_STATISTICS_FAILED,
  GET_ENQUIRY_CONVERSION_RATE_STATISTICS_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_ENQUIRY_CONVERSION_RATE_STATISTICS,
    itemFailed: GET_ENQUIRY_CONVERSION_RATE_STATISTICS_FAILED,
    itemInit: GET_ENQUIRY_CONVERSION_RATE_STATISTICS,
    itemSuccess: GET_ENQUIRY_CONVERSION_RATE_STATISTICS_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
