import _ from 'lodash'
import { createSelector } from 'reselect'

const getDailyDiarySleeps = (state) => state.dailyDiarySleeps

export const getDailyDiarySleepsListWithoutRecords = createSelector(
  [getDailyDiarySleeps],
  (state) => state.listWithoutRecords,
)

export const getDailyDiarySleepsListWithoutRecordsData = createSelector(
  [getDailyDiarySleepsListWithoutRecords],
  (state) => state.data,
)

export const getDailyDiarySleepsListWithoutRecordsMeta = createSelector(
  [getDailyDiarySleepsListWithoutRecords],
  (state) => state.meta,
)

export const getChildrenDailyDiarySleepsListWithoutRecordsData = createSelector(
  [getDailyDiarySleepsListWithoutRecordsData],
  (items) => _.map(items, ({ childRegister: { child } }) => child),
)

export const hasDailyDiarySleepsListWithoutRecordsData = createSelector(
  [getDailyDiarySleepsListWithoutRecordsMeta],
  (meta) => meta?.total_results > 0, // eslint-disable-line
)
