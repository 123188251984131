import React from 'react'
import i18n from 'translations'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

import { ProgressBar } from 'components'

import { StyledContainer, StyledProgressPercentageText } from './ProgressBarWithCopyStyled'

const ProgressBarWithCopy = ({ width }) => (
  <StyledContainer>
    <ProgressBar
      backgroundColor={NEUTRAL_COLOURS.SILVER}
      fillColor={getBrandingColor('primary-color-lighten-20')}
      height={26}
      width={width}
    />
    <StyledProgressPercentageText color={getBrandingColor('primary-color-lighten-20')}>
      {i18n.t('module:Management:GettingStarted:progressCopy', { width })}
    </StyledProgressPercentageText>
  </StyledContainer>
)

export default ProgressBarWithCopy
