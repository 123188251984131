import { createSelector } from 'reselect'

const getFinanceReports = (state) => state.financeCreditNotesReport

export const getFinanceCreditNotesReportNurseries = createSelector(
  [getFinanceReports],
  (state) => state.nurseries,
)

export const getFinanceCreditNotesReportNurseriesData = createSelector(
  [getFinanceCreditNotesReportNurseries],
  (state) => state.data,
)
