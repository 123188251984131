import {
  CLEAR_PAYMENT_REMINDER_TAGS,
  LIST_PAYMENT_REMINDER_TAGS,
  LIST_PAYMENT_REMINDER_TAGS_FAILED,
  LIST_PAYMENT_REMINDER_TAGS_SUCCESS,
} from './constants'

export const list = () => ({ paymentReminderTagsApiClient }) => (dispatch) => {
  dispatch({
    type: LIST_PAYMENT_REMINDER_TAGS,
  })

  paymentReminderTagsApiClient.list().then(
    (data) => {
      dispatch({
        payload: data,
        type: LIST_PAYMENT_REMINDER_TAGS_SUCCESS,
      })
    },
    (error) => {
      dispatch({
        error: error.response,
        type: LIST_PAYMENT_REMINDER_TAGS_FAILED,
      })
    },
  )
}

export const clear = () => ({
  type: CLEAR_PAYMENT_REMINDER_TAGS,
})
