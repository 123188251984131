import React from 'react'

import { Callout, EmptyState, Page, Spinner, Table, Typography } from 'components'

import i18n from 'translations'

const COLUMNS = [
  {
    align: 'left',
    field: 'itemCategory',
    title: i18n.t('module:Management:Finance:AccountCodes:TableHeaders:itemCategory'),
  },
  {
    field: 'accountCode',
    title: i18n.t('module:Management:Finance:AccountCodes:TableHeaders:accountCode'),
  },
]

const ManagementAccountCodesView = ({
  data,
  errorMessages,
  isLoading,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!data?.length) {
      return (
        <EmptyState
          icon="funding"
          text1={i18n.t('module:Management:Finance:AccountCodes:emptyState')}
        />
      )
    }

    return (
      <Table
        columns={COLUMNS}
        data={data}
      />
    )
  }

  return (
    <React.Fragment>
      <Callout content={errorMessages} error />
      <Page.Section
        isLoading={isLoading}
        title={i18n.t('module:Management:Finance:AccountCodes:title')}
      >
        <Typography margin="20px 0">
          {i18n.t('module:Management:Finance:AccountCodes:subtitle')}
        </Typography>
        {renderContent()}
      </Page.Section>
    </React.Fragment>
  )
}

export default ManagementAccountCodesView
