import React from 'react'
import { WrappedFieldProps } from 'redux-form'

import { SliderFinalProps } from 'components/Slider/Slider'

import { Slider } from 'components'

type FormSliderFullProps = SliderFinalProps & WrappedFieldProps

const FormSlider: React.FC<FormSliderFullProps> = ({
  input: { onChange, value },
  ...rest
}) => (
  <Slider
    {...rest}
    value={value || 0}
    onChange={onChange}
  />
)

export default FormSlider
