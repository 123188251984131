import {
  CLEAR_OBSERVATION_FEEDBACK,
  CREATE_OBSERVATION_FEEDBACK,
  CREATE_OBSERVATION_FEEDBACK_FAILED,
  CREATE_OBSERVATION_FEEDBACK_SUCCESS,
  GET_OBSERVATION_FEEDBACK,
  GET_OBSERVATION_FEEDBACK_FAILED,
  GET_OBSERVATION_FEEDBACK_SUCCESS,
  UPDATE_OBSERVATION_FEEDBACK,
  UPDATE_OBSERVATION_FEEDBACK_FAILED,
  UPDATE_OBSERVATION_FEEDBACK_SUCCESS,
} from './constants'

export const getObservationFeedback = (id, params) => ({ observationFeedbackApiClient }) => (dispatch) => {
  dispatch({ id, type: GET_OBSERVATION_FEEDBACK })

  observationFeedbackApiClient.get(id, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: GET_OBSERVATION_FEEDBACK_SUCCESS,
      })
    },
    (error) => {
      dispatch({
        error: error.response,
        type: GET_OBSERVATION_FEEDBACK_FAILED,
      })
    },
  )
}

export const createObservationFeedback = (payload, onSuccess, onFailed) => ({
  observationFeedbackApiClient,
}) => (dispatch) => {
  dispatch({
    payload,
    type: CREATE_OBSERVATION_FEEDBACK,
  })

  observationFeedbackApiClient.create(payload).then(
    (data) => {
      dispatch({
        payload: data,
        type: CREATE_OBSERVATION_FEEDBACK_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: CREATE_OBSERVATION_FEEDBACK_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const updateObservationFeedback = (id, payload, onSuccess, onFailed) => ({
  observationFeedbackApiClient,
}) => (dispatch) => {
  dispatch({
    id,
    payload,
    type: UPDATE_OBSERVATION_FEEDBACK,
  })

  observationFeedbackApiClient.update(id, payload).then(
    (data) => {
      dispatch({
        payload: data,
        type: UPDATE_OBSERVATION_FEEDBACK_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: UPDATE_OBSERVATION_FEEDBACK_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const clearObservationFeedback = () => ({
  type: CLEAR_OBSERVATION_FEEDBACK,
})
