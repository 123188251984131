import _ from 'lodash'
import moment from 'moment'
import color from 'color'

import React from 'react'

import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'
import { DEFAULT_DATE_FORMAT, DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'

import { addMillisecondsFromMidnight, isSameDay, millisecondsToHoursAndMinutesString } from 'utils/date'
import { generateRoute } from 'utils/routing'

import {
  Avatar,
  DataTable,
  DatePicker,
  DropdownMenu,
  EmptyState,
  Form,
  Icon,
  InfiniteDropdowns,
  InfiniteScroll,
  Page,
  SearchBar,
  Section,
  Space,
  Spinner,
  Switch,
  Toolbar,
  Typography,
} from 'components'
import { ShiftLabel } from 'module/Staff/components'

import i18n from 'translations'

import { getStaffLeaveCellHeight } from './helpers'
import { DATE_FORMAT } from './StaffLeaveContainer'
import {
  StyledAddLog,
  StyledBorderWrapper,
  StyledCircle,
  StyledLeaveBox,
  StyledLeaveBoxWrapper,
  StyledLeaveDetails,
  StyledShiftTimesWrapper,
  StyledStickyHeaderWrapper,
  StyledTitle,
} from './StaffLeaveStyled'

const StaffLeaveView = ({
  StaffLeaveAddGranted,
  date,
  isFetching,
  onAddLeave,
  onChangeDate,
  onChangePage,
  onChangeSearch,
  onChangeType,
  onChangeWithLeave,
  onDeleteLeave,
  onEditLeave,
  onExportClick,
  page,
  pageCount,
  records,
  type,
  withLeave,
}) => {
  const renderChildrenCells = () => _.map(records, ({ id, name, photo, shifts }, i) => {
    const leaves = _.filter(shifts, ({ leaveShiftType }) => leaveShiftType)

    let summary = 0

    _.each(leaves, ({ membershipShiftTimes }) => {
      _.each(membershipShiftTimes, (item) => {
        summary += (item.endTime - item.startTime)
      })
    })

    return (
      <StyledBorderWrapper
        bottomBorder={i + 1 === records.length}
        key={`member_${id}`}
        topBorder={!i}
        leftBorder
      >
        <DataTable.Row
          border={`1px solid ${NEUTRAL_COLOURS.WHITE}`}
          height={getStaffLeaveCellHeight(leaves.length)}
          darkGray
        >
          <Avatar
            avatarSize="small"
            initials={name}
            src={photo}
            subTitle={(
              <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={12}>
                {millisecondsToHoursAndMinutesString(summary)}
              </Typography>
            )}
            title={(
              <StyledTitle>
                {name}
              </StyledTitle>
            )}
            to={generateRoute('STAFF.PROFILE.ABOUT', { userId: id })}
          />
        </DataTable.Row>
      </StyledBorderWrapper>
    )
  })

  const renderCellContent = (leave, record, leaves, clickedItemDate, button) => {
    const { dateFrom, dateTo, id: leaveId, membershipShiftTimeStatistics } = leave
    const dayDiffs = moment(dateTo).diff(dateFrom, 'days') + 1
    let filteredMembershipShiftTimes = []

    _.each(leaves, (leaveItem) => {
      const { id, membershipShiftTimes: leaveMembershipShiftTimes } = leaveItem

      _.each(leaveMembershipShiftTimes, (membershipTime) => {
        const { day } = membershipTime

        if (
          id === leaveId
          && isSameDay(day, clickedItemDate)
        ) {
          filteredMembershipShiftTimes.push(membershipTime)
        }
      })
    })

    filteredMembershipShiftTimes = _.uniqBy(filteredMembershipShiftTimes, ({ id }) => id)

    return (
      <DropdownMenu
        button={button}
        minWidth="250"
        placement="bottom-center"
      >
        <StyledLeaveDetails>
          <ShiftLabel code={leave.leaveShiftType.code}>
            {leave.leaveShiftType.name}
          </ShiftLabel>
          <Space space="12px" />
          <Typography bold>
            {moment(dateFrom).format(DISPLAY_SHORT_DATE_FORMAT)}
            {' - '}
            {moment(dateTo).format(DISPLAY_SHORT_DATE_FORMAT)}
          </Typography>
          <Space space="2px" />
          <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
            {dayDiffs}
            {' '}
            {1 === dayDiffs ? i18n.t('global:day') : i18n.t('global:days')}
            {` / ${millisecondsToHoursAndMinutesString(membershipShiftTimeStatistics.leave.leave)}`}
          </Typography>
          <Space space="10px" />
          <StyledShiftTimesWrapper>
            {_.map(filteredMembershipShiftTimes, ({ day, endTime, startTime }) => (
              <React.Fragment>
                <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} transform="uppercase" bold>
                  {moment(day).format('ddd DD/MM')}
                </Typography>
                <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
                  {addMillisecondsFromMidnight(startTime).format('HH:mm')}
                  {' - '}
                  {addMillisecondsFromMidnight(endTime).format('HH:mm')}
                  {' ('}
                  {millisecondsToHoursAndMinutesString(endTime - startTime)}
                  {')'}
                </Typography>
                <Space space="8px" />
              </React.Fragment>
            ))}
          </StyledShiftTimesWrapper>
        </StyledLeaveDetails>
        <DropdownMenu.Divider />
        <DropdownMenu.Item
          icon="edit"
          label={i18n.t('module:Staff:StaffLeave:editLeave')}
          onClick={() => onEditLeave(record, leave)}
        />
        <DropdownMenu.Item
          type="delete"
          onClick={() => onDeleteLeave(leave)}
        />
      </DropdownMenu>
    )
  }

  const renderCell = (record, itemDate, index) => {
    const { shifts } = record

    const leaves = _.filter(shifts, ({ leaveShiftType }) => leaveShiftType)
    const results = {}

    _.each(leaves, (leave) => {
      const { id } = leave

      results[id] = 0
    })

    _.each(leaves, (leave) => {
      const { id, membershipShiftTimes } = leave

      _.each(membershipShiftTimes, (item) => {
        const { day } = item

        if (isSameDay(day, itemDate)) {
          results[id] += (item.endTime - item.startTime)
        }
      })
    })

    const filteredLeaves = _.filter(leaves, (item) => 0 < results[item.id])
    const filteredLeavesLength = filteredLeaves.length

    const isWeekend = 0 === +moment(itemDate).format('d') || 6 === +moment(itemDate).format('d')
    const isToday = isSameDay(itemDate, moment())

    let background = NEUTRAL_COLOURS.WHITE

    if (isWeekend) {
      background = NEUTRAL_COLOURS.GRAY_QUATERNARY
    }

    if (isToday) {
      background = color(FLAG_COLOURS.INFO).mix(color(NEUTRAL_COLOURS.WHITE), 0.8).rgb().string()
    }

    return (
      <StyledBorderWrapper
        bottomBorder={index + 1 === records.length}
        key={`${moment(itemDate).format(DEFAULT_DATE_FORMAT)}_${record.id}_${index}`}
        rightBorder={(
          moment(itemDate).format(DEFAULT_DATE_FORMAT)
          === moment(itemDate).endOf('month').format(DEFAULT_DATE_FORMAT)
        )}
      >
        <DataTable.Row
          background={background}
          border={`1px solid ${background}`}
          height={getStaffLeaveCellHeight(leaves.length)}
          minWidth="50"
          width="50"
          noPadding
        >
          <StyledLeaveBoxWrapper>
            {_.map(filteredLeaves, (item, itemIndex) => renderCellContent(
              item,
              record,
              leaves,
              itemDate, (
                <StyledLeaveBox
                  borderTop={itemIndex && `2px solid ${background}`}
                  code={item.leaveShiftType.code}
                  smallerLetterSpacing
                >
                  {millisecondsToHoursAndMinutesString(results[item.id])}
                </StyledLeaveBox>
              ),
            ))}
            {!filteredLeavesLength
              ? (
                <StyledAddLog
                  onClick={() => onAddLeave(record, itemDate)}
                >
                  <Icon
                    height={24}
                    icon="plus"
                  />
                </StyledAddLog>
              ) : null}
          </StyledLeaveBoxWrapper>
        </DataTable.Row>
      </StyledBorderWrapper>
    )
  }

  const renderDaysOfMonth = (withoutContent) => _.times(moment(date, DATE_FORMAT).daysInMonth(), (i) => {
    const isToday = (
      moment(date, DATE_FORMAT).set('date', i + 1).format(DEFAULT_DATE_FORMAT)
      === moment().format(DEFAULT_DATE_FORMAT)
    )

    return (
      <DataTable.Column noBorder>
        <StyledBorderWrapper bottomBorder>
          <DataTable.Row
            border={isToday ? '0' : `1px solid ${NEUTRAL_COLOURS.WHITE}`}
            height="60"
            minWidth="50"
            width="50"
            column
          >
            <StyledCircle isToday={isToday}>
              <Typography color={NEUTRAL_COLOURS.GRAY}>
                {moment(date, DATE_FORMAT).set('date', i + 1).format('dd').substring(0, 1)}
              </Typography>
              <Typography bold>
                {moment(date, DATE_FORMAT).set('date', i + 1).format('DD')}
              </Typography>
            </StyledCircle>
          </DataTable.Row>
        </StyledBorderWrapper>
        {!withoutContent && (
          _.map(records, (record, index) => renderCell(record, moment(date, DATE_FORMAT).set('date', i + 1), index))
        )}
      </DataTable.Column>
    )
  })

  const renderTable = () => (
    <DataTable>
      <DataTable.Head>
        <DataTable.Row
          border={`1px solid ${NEUTRAL_COLOURS.WHITE}`}
          height="60"
          stickyElement
        />
        {renderChildrenCells()}
      </DataTable.Head>
      <DataTable.ContentScrollable
        data={[{
          data: renderDaysOfMonth(),
          stickyHeader: () => [(
            <StyledStickyHeaderWrapper>
              {renderDaysOfMonth(true)}
            </StyledStickyHeaderWrapper>
          )],
        }]}
        enableCustomScrollbar
        hideSeparator
      />
    </DataTable>
  )

  const renderHeader = () => (
    <React.Fragment>
      <SearchBar
        placeholder={i18n.t('module:Staff:StaffLeave:searchByName')}
        variant="standard"
        noBackground
        onChange={onChangeSearch}
      />
      <Space space="5px" />
      <Toolbar>
        <Toolbar.Group alignItems="flex-start">
          <Toolbar.Item>
            <Form.Row label={i18n.t('module:Staff:StaffLeave:leaveType')} verticalLabel>
              <InfiniteDropdowns.LeaveTypes
                value={type}
                onChange={onChangeType}
              />
            </Form.Row>
          </Toolbar.Item>
          <Toolbar.Item>
            <Form.Row label={i18n.t('module:Staff:StaffLeave:staffWithLeave')} verticalLabel>
              <Switch
                value={Boolean(withLeave)}
                onChange={onChangeWithLeave}
              />
            </Form.Row>
          </Toolbar.Item>
        </Toolbar.Group>
        <Toolbar.Group>
          <Toolbar.Item>
            <DatePicker
              type="month"
              value={date}
              onChange={onChangeDate}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
    </React.Fragment>
  )

  const renderContent = () => {
    if (isFetching && 1 === page) {
      return (
        <Spinner />
      )
    }

    if (!isFetching && !records?.length) {
      return (
        <EmptyState
          icon="staff-leave"
          text1={i18n.t('module:Staff:StaffLeave:notFound')}
        />
      )
    }

    return (
      <InfiniteScroll
        dataLength={records?.length || 0}
        hasMore={page < pageCount}
        isFetching={isFetching}
        next={() => onChangePage((+page) + 1)}
      >
        {renderTable()}
      </InfiniteScroll>
    )
  }

  const actions = (
    <Section.Actions
      options={[{ onClick: onExportClick, type: 'export' }]}
      primary={[{
        auth: StaffLeaveAddGranted,
        label: i18n.t('module:Staff:StaffLeave:addLeave'),
        onClick: () => onAddLeave(),
      }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isFetching && 1 === page}
      title={i18n.t('module:Staff:StaffLeave:title')}
    >
      {renderHeader()}
      {renderContent()}
    </Page.Section>
  )
}

export default StaffLeaveView
