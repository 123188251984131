import _ from 'lodash'
import { v4 } from 'uuid'

import React from 'react'

import { OTHER_OPTION } from 'services/users/constants'

import { hasMoreRecords } from 'utils/pagination'
import eventBus from 'utils/eventBus'

import i18n from 'translations'

import {
  InfiniteDropdownHelpersProps,
  Option,
  withInfiniteDropdownHelpersState,
} from '../../InfiniteDropdowns/InfiniteDropdownsModel'

export interface withInfiniteDropdownHelpersProps {
  infiniteDropdownHelpers: InfiniteDropdownHelpersProps
  infiniteDropdownState: withInfiniteDropdownHelpersState
  limit?: number
}

interface withInfiniteDropdownHelpersInsideProps {
  extraOptions?: Option[]
  limit?: number
  overloadValueEventName?: string
  value: Option | string | number | null
}

/* eslint-disable react/no-unused-state */
const withInfiniteDropdownHelpers = <
  P extends withInfiniteDropdownHelpersProps & withInfiniteDropdownHelpersInsideProps
>(
    WrappedComponent: React.ComponentType<P>,
  ) => class extends React.Component<
  P,
  withInfiniteDropdownHelpersState
> {
    constructor(props) {
      super(props)

      this.state = {
        cacheUniq: v4(),
        oldSearchedPhrase: null,
        options: [],
        page: 1,
        selectedValue: null,
      }
    }

    componentDidMount() {
      const { overloadValueEventName } = this.props

      if (overloadValueEventName) {
        eventBus.on(overloadValueEventName, this.handleOverloadSelectedValue)
      }
    }

    componentWillUnmount() {
      const { overloadValueEventName } = this.props

      if (overloadValueEventName) {
        eventBus.remove(overloadValueEventName, this.handleOverloadSelectedValue)
      }
    }

    handleOverloadSelectedValue = () => setTimeout(() => {
      const { value } = this.props

      this.setState({ selectedValue: value })
    })

    handleComponentDidMount = ({ action, value }) => {
      if (!value || (_.has(value, 'label') && _.has(value, 'value'))) {
        return null
      }

      return action()
    }

    handleComponentDidMountOnSuccess = (response) => {
      const data = response?.data || {}

      const option = {
        label: data.name,
        value: data.id,
      } as Option

      if (data.photo) {
        option.avatar = data.photo
      }

      if (data.email) {
        option.email = data.email
      }

      this.setState({
        selectedValue: option,
      })
    }

    getOptions = ({ data, extraOptions, page, extraOptionsOnEnd, totalPages, extraFields = ['email'] }) => {
      const newOptions = _.map(data, ({ accepted, description, id, name, photo, ...rest }) => {
        const option = { label: name || description, value: id } as Option

        if (photo) {
          option.avatar = photo
        }

        if (false === accepted) {
          option.label = `${option.label} (${i18n.t('global:pending')})`
        }

        _.each(extraFields, (field) => {
          const extraField = _.get(rest, field)

          if (extraField !== undefined) {
            _.set(option, field, extraField)
          }
        })

        return option
      })

      if (0 === totalPages && extraOptions?.length) {
        return [...extraOptions]
      }

      if (extraOptionsOnEnd && page === totalPages) {
        return [...(newOptions || []), ...extraOptions]
      }

      if (1 === page && extraOptions?.length) {
        return extraOptions[0]?.label === OTHER_OPTION.label
          ? [...(newOptions || []), ...extraOptions]
          : [...extraOptions, ...(newOptions || [])]
      }

      return newOptions
    }

    handleLoadMoreElementsOnSuccessV2 = (params) => (response) => (
      this.handleLoadMoreElementsOnSuccess({
        ...params,
        response,
      })
    )

    // NOTE: result object is passed from handleLoadMoreElements as a reference object to update the options
    // collection
    handleLoadMoreElementsOnSuccess = ({
      extraFields,
      extraOptionsOnEnd,
      newPage,
      newSearchPhrase,
      response,
      result,
    }) => {
      const { data, meta } = response
      const { limit, total_results: totalResults } = meta || {}
      const { extraOptions = [] } = this.props

      const totalPages = Math.ceil(totalResults || 0 / limit || 0)

      /* eslint-disable no-param-reassign */
      result.hasMore = hasMoreRecords(meta)
      result.options = this.getOptions({
        data,
        extraFields,
        extraOptions,
        extraOptionsOnEnd,
        page: newPage,
        totalPages,
      })
      // eslint-enable no-param-reassign

      this.setState((prevState) => ({
        cacheUniq: false,
        oldSearchedPhrase: newSearchPhrase,
        options: 1 !== newPage ? [...prevState.options, ...result.options] : result.options,
        page: newPage + 1,
      }))
    }

    handleLoadMoreElements = async ({ clearAction, listAction, phrase }: {
      clearAction?: any
      listAction: any
      phrase?: any
    }) => {
      try {
        const { oldSearchedPhrase, page: oldPage } = this.state

        let newSearchPhrase = oldSearchedPhrase
        let newPage = oldPage

        if (oldSearchedPhrase !== phrase) {
          if (clearAction) {
            clearAction()
          }

          newSearchPhrase = phrase
          newPage = 1
        }

        const result = { hasMore: false, options: [] }

        await listAction({ newPage, newSearchPhrase, result })

        return result
      } catch (error) {
        throw new Error(error)
      }
    }

    handleLoadMoreElementsV2 = ({ ...rest }) => (phrase) => this.handleLoadMoreElements({ ...rest, phrase } as any)

    handleOnChange = (e, handleOnChange) => {
      this.setState({ cacheUniq: false, page: 1, selectedValue: e }, handleOnChange)
    }

    handleOnChangeV2 = (handleOnChange) => (e) => {
      this.setState({ cacheUniq: false, page: 1, selectedValue: e }, () => handleOnChange(e))
    }

    handleResetCacheUniq = () => {
      this.setState({ cacheUniq: v4() })
    }

    getSelectRestParams = (rest) => {
      const { cacheUniq, options, selectedValue } = this.state
      const { onChange, rawValue, value } = rest

      return {
        ...rest,
        cacheUniq,
        onChange: (e) => this.handleOnChangeV2(onChange)(e),
        options,
        rawValue: rawValue || selectedValue || value,
        value,
      }
    }

    render() {
      const { ...others } = this.props

      const infiniteDropdownHelpers = {
        getOptions: this.getOptions,
        getSelectRestParams: this.getSelectRestParams,
        handleComponentDidMount: this.handleComponentDidMount,
        handleComponentDidMountOnSuccess: this.handleComponentDidMountOnSuccess,
        handleLoadMoreElements: this.handleLoadMoreElements,
        handleLoadMoreElementsOnSuccess: this.handleLoadMoreElementsOnSuccess,
        handleLoadMoreElementsOnSuccessV2: this.handleLoadMoreElementsOnSuccessV2,
        handleLoadMoreElementsV2: this.handleLoadMoreElementsV2,
        handleOnChange: this.handleOnChange,
        handleOnChangeV2: this.handleOnChangeV2,
        handleResetCacheUniq: this.handleResetCacheUniq,
      }

      return (
        <WrappedComponent
          {...others as P}
          infiniteDropdownHelpers={infiniteDropdownHelpers}
          infiniteDropdownState={this.state}
        />
      )
    }
  }

export default withInfiniteDropdownHelpers
