import _ from 'lodash'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { change, getFormValues } from 'redux-form'

import { generateRoute } from 'utils/routing'

import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withNurserySessionsService } from 'services/nurserySessions'
import { withNurserySessionsV3Service } from 'services/product/nurserySessionsV3'
import { withPaginationUtils } from 'services/utils/pagination'
import { withSortingUtils } from 'services/utils/sorting'

import CorePreferencesView from './CorePreferencesView'
import { REQUESTABLE_EXTRA_SESSIONS_CORE_PREFERENCES_FORM } from './components/CorePreferencesForm'

const GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings.requestExtraSession',
    'requestExtraSessionSettings',
    'requestableSessionsSettings',
  ],
}

class CorePreferencesContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      changedSessions: [],
      isSessionsFetching: false,
      sessions: [],
      totalResults: 0,
    }

    const { paginationUtils: { setPageLocationQuery } } = props

    setPageLocationQuery(false)
  }

  componentDidMount() {
    const { nurseriesActions, nurseryOptions } = this.props

    return nurseriesActions.get(nurseryOptions.id, {
      onSuccess: this.fetchSessions,
      params: { groups: GROUPS },
    })
  }

  componentWillUnmount() {
    const { nurseriesActions, nurserySessionsActions } = this.props

    nurseriesActions.clear()
    nurserySessionsActions.clear()
  }

  handleNurserySessionSuccess = (page) => ({ data, meta: { total_results: totalResults } }) => {
    this.setState((prevState) => ({
      isSessionsFetching: false,
      sessions: 1 === page ? [...data] : [...prevState.sessions, ...data],
      totalResults,
    }))

    if (1 !== page) {
      const { changeFieldValue, formValues } = this.props

      changeFieldValue('requestableSessions', [
        ...formValues.requestableSessions,
        ...data,
      ])
    }
  }

  fetchSessions = () => {
    const {
      isFinanceV3Enabled,
      nurserySessionsActions,
      nurserySessionsV3Actions,
      paginationUtils,
      sortingUtils,
    } = this.props
    const { page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    this.setState({ isSessionsFetching: true })

    const criteria = [{
      field: 'archived',
      value: 0,
    }, {
      field: isFinanceV3Enabled ? 'hourly' : 'isHourly',
      value: 0,
    }]
    const params = {
      criteria,
      order: sortField ? {
        sortField,
        sortOrder,
      } : undefined,
      page,
    }

    if (isFinanceV3Enabled) {
      nurserySessionsV3Actions.list({
        onSuccess: this.handleNurserySessionSuccess(page),
        onlyData: true,
        params,
      })
    } else {
      nurserySessionsActions.list({
        onSuccess: this.handleNurserySessionSuccess(page),
        onlyData: true,
        params,
      })
    }
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetchSessions)(page)
  }

  handleSortChange = (key) => {
    const { paginationUtils, sortingUtils } = this.props
    const { onSortChange } = sortingUtils
    const { onPageChange } = paginationUtils

    onSortChange(onPageChange(this.fetchSessions)(1))(key)
  }

  handleSubmitSuccess = () => {
    const { navigate } = this.props

    navigate(generateRoute('MANAGEMENT.PARENT_APP.REQUESTABLE_EXTRA_SESSIONS'))
  }

  handleSubmit = (values) => {
    const {
      isFinanceV3Enabled,
      nurseriesActions,
      nurseriesSelectors,
      nurseryOptions,
      nurserySessionsActions,
      nurserySessionsV3Actions,
      nurserySettings,
    } = this.props
    const { changedSessions } = this.state

    if (!_.isEmpty(changedSessions)) {
      const batchPayload = _.map(changedSessions, (value, key) => ({
        id: +key,
        requestable: value,
      }))

      if (isFinanceV3Enabled) {
        nurserySessionsV3Actions.updateBatch({
          body: batchPayload,
          params: [],
        })
      } else {
        nurserySessionsActions.updateBatch({
          body: batchPayload,
          params: [],
        })
      }
    }

    const payload = nurseriesSelectors.getRequestableExtraSessionsPayload({
      nurserySettings,
      values,
    })

    return nurseriesActions.update(nurseryOptions.id, {
      onSuccess: this.handleSubmitSuccess,
      payload,
    })
  }

  handleSessionClick = (value, id) => {
    const { changedSessions } = this.state

    this.setState({
      changedSessions: {
        ...changedSessions,
        [id]: value,
      },
    })
  }

  render() {
    const {
      errorMessages,
      isNurserySettingsFetching,
      isSubmitting,
      nurseriesSelectors,
      paginationUtils,
      requestableExtraSessions,
      sortingUtils,
    } = this.props
    const { isSessionsFetching, sessions, totalResults } = this.state

    const { getPageCount, page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const pageCount = getPageCount(totalResults)

    const initialValues = nurseriesSelectors.getRequestableExtraSessionsInitialValues({
      requestableExtraSessions,
      sessions,
    })

    return (
      <CorePreferencesView
        errorMessages={errorMessages}
        initialValues={initialValues}
        isNurserySettingsFetching={isNurserySettingsFetching}
        isSessionsFetching={isSessionsFetching}
        isSubmitting={isSubmitting}
        page={page}
        pageCount={pageCount}
        sessions={sessions}
        sortField={sortField}
        sortOrder={sortOrder}
        onPageChange={this.handlePageChange}
        onSessionClick={this.handleSessionClick}
        onSortChange={this.handleSortChange}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseriesSelectors,
  nurseriesSingleState,
  nurserySessionsListState,
  nurserySessionsSingleState,
  params,
}) => ({
  errorMessages: appSelectors.getErrorMessages(nurseriesSingleState, nurserySessionsListState),
  formValues: getFormValues(REQUESTABLE_EXTRA_SESSIONS_CORE_PREFERENCES_FORM)(state),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
  isNurserySettingsFetching: appSelectors.getIsFetching(nurseriesSingleState),
  isSubmitting: appSelectors.getIsSubmitting(nurseriesSingleState, nurserySessionsSingleState),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  nurserySettings: nurseriesSelectors.getNurserySettings(state),
  requestableExtraSessions: nurseriesSelectors.getNurseryRequestableExtraSessionsSettings(state),
})

const mapDispatch = {
  changeFieldValue: (field, value) => change(REQUESTABLE_EXTRA_SESSIONS_CORE_PREFERENCES_FORM, field, value),
}

const enhance = compose(
  withAppService,
  withNurseriesService,
  withNurserySessionsService,
  withNurserySessionsV3Service,
  withPaginationUtils,
  withSortingUtils,
  connect(mapState, mapDispatch),
)

export default enhance(CorePreferencesContainer)
