import { Component } from 'react'
import { connect } from 'react-redux'

import auth from 'utils/auth'

import ChildrenList from './ChildrenList'

class OrganizationChildrenWrapperContainer extends Component {
  render() {
    const { children } = this.props

    return children
  }
}

const mapState = (state) => ({
  authAccessMap: {
    module: {
      ChildrenList: auth.SELECTORS.getComponentIsAuthorised(state, ChildrenList),
    },
  },
})

export default connect(mapState)(OrganizationChildrenWrapperContainer)
