import React from 'react'
import { compose } from 'recompose'

import { generateRoute } from 'utils/routing'

import withStaffRegisterWrapper from '../withStaffRegisterWrapper'
import ChooseNewPinSuccessView from './ChooseNewPinSuccessView'

const ChooseNewPinSuccessContainer = ({
  clearStoreAndRedirectToStaffList,
  router,
}) => {
  const handleContinueButtonClick = () => {
    clearStoreAndRedirectToStaffList(() => router.replace(generateRoute('STAFF_REGISTER.LIST')))
  }

  return (
    <ChooseNewPinSuccessView onContinueButtonClick={handleContinueButtonClick} />
  )
}

const enhance = compose(
  withStaffRegisterWrapper,
)

export default enhance(ChooseNewPinSuccessContainer)
