import React from 'react'

import { StyledHeader } from './WidgetStyled'

const WidgetHeader = ({ children }) => (
  <StyledHeader>
    {children}
  </StyledHeader>
)

export default WidgetHeader
