/* eslint-disable max-len */
export const GET_PAYOUT_TRANSACTIONS = 'NURSERY_INTEGRATIONS/GET_PAYOUT_TRANSACTIONS'
export const GET_PAYOUT_TRANSACTIONS_SUCCESS = 'NURSERY_INTEGRATIONS/GET_PAYOUT_TRANSACTIONS_SUCCESS'
export const GET_PAYOUT_TRANSACTIONS_FAILED = 'NURSERY_INTEGRATIONS/GET_PAYOUT_TRANSACTIONS_FAILED'

export const GET_PAYOUT_TRANSACTIONS_EXPORT = 'NURSERY_INTEGRATIONS/GET_PAYOUT_TRANSACTIONS_EXPORT'
export const GET_PAYOUT_TRANSACTIONS_EXPORT_SUCCESS = 'NURSERY_INTEGRATIONS/GET_PAYOUT_TRANSACTIONS_EXPORT_SUCCESS'
export const GET_PAYOUT_TRANSACTIONS_EXPORT_FAILED = 'NURSERY_INTEGRATIONS/GET_PAYOUT_TRANSACTIONS_EXPORT_FAILED'

export const CLEAR_PAYOUT_TRANSACTIONS = 'NURSERY_INTEGRATIONS/CLEAR_PAYOUT_TRANSACTIONS'
