import React from 'react'

import { generateRoute } from 'utils/routing'

import { Button, Callout, Spinner } from 'components'

import i18n from 'translations'

import { Booking, Child, Contact, Enquiry } from './components'

import { StyledContentContainer } from './ChildDetailStyled'
import { StyledBodyContainer, StyledHeader } from '../DetailStyled'

const ChildDetailView = ({
  enquiry,
  errorMessages,
  isFetching,
  isFinanceV3Enabled,
  openingDays,
}) => {
  const renderContent = () => {
    if (isFetching || !enquiry) {
      return <Spinner />
    }

    return (
      <StyledContentContainer>
        <Child enquiry={enquiry} />
        <Contact enquiry={enquiry} />
        <Booking enquiry={enquiry} isFinanceV3Enabled={isFinanceV3Enabled} openingDays={openingDays} />
        <Enquiry enquiry={enquiry} />
      </StyledContentContainer>
    )
  }

  return (
    <StyledBodyContainer>
      <Callout content={errorMessages} error />
      <StyledHeader>
        <Button
          hierarchy="secondary"
          icon="chevron-left"
          label={i18n.t('module:Enquiries:title')}
          size="small"
          to={generateRoute('ENQUIRIES.LIST')}
        />
      </StyledHeader>
      {renderContent()}
    </StyledBodyContainer>
  )
}

export default ChildDetailView
