import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withContractsService } from 'services/legacy/contracts'

import withStaffHoc from 'module/Staff/withStaffHoc'

import StaffContractsPreviewView from './StaffContractsPreviewView'

const GROUPS = {
  read: [
    'contract.breakAllowances',
    'breakAllowance',
  ],
}

class StaffContractsPreviewContainer extends Component {
  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { contractsActions } = this.props

    contractsActions.clear()
  }

  fetch = () => {
    const {
      contractsActions,
      params: { contractId },
    } = this.props

    return contractsActions.get(contractId, {
      params: { groups: GROUPS },
    })
  }

  render() {
    const {
      contract,
      contractsSelectors,
      errorMessages,
      isFetching,
      isGrantedEdit,
      isMyDetailsContext,
      params: { contractId, userId },
      yearlySalary,
    } = this.props

    const editLink = isMyDetailsContext
      ? generateRoute('ACCOUNT.CONTRACTS.EDIT', { contractId })
      : generateRoute('STAFF.PROFILE.CONTRACTS.EDIT', { contractId, userId })
    const salaryPerMonth = contractsSelectors.getSalaryPerMonth(yearlySalary)

    return (
      <StaffContractsPreviewView
        contract={contract}
        editLink={editLink}
        errorMessages={errorMessages}
        isFetching={isFetching}
        isGrantedEdit={isGrantedEdit}
        salaryPerMonth={salaryPerMonth}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  contractsSelectors,
  contractsSingleState,
}) => ({
  contract: contractsSelectors.getContractDataSelector(state),
  errorMessages: appSelectors.getErrorMessages(contractsSingleState),
  isFetching: appSelectors.getIsFetching(contractsSingleState),
  isGrantedEdit: contractsSelectors.getIsGrantedEdit(state),
  yearlySalary: contractsSelectors.getYearlySalary(state),
})

const enhance = compose(
  withAppService,
  withContractsService,
  withStaffHoc,
  connect(mapState),
)

export default enhance(StaffContractsPreviewContainer)
