import ServiceBase from 'services/ServiceBase'

import { QueryPropsV2 } from 'constants/service'

import { getQueryStringV2 } from 'utils/api'

class NurseryFundingApiClient extends ServiceBase {
  list = (params: QueryPropsV2, customSubdomain) => this.request({
    customSubdomain,
    path: `/v2/nursery_fundings?${getQueryStringV2(params)}`,
  })

  get = (id: string, params: QueryPropsV2) => this.request({
    path: `/v2/nursery_fundings/${id}?${getQueryStringV2(params)}`,
  })

  create = (params: QueryPropsV2, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/nursery_fundings?${getQueryStringV2(params)}`,
  })

  update = (id: string, params: QueryPropsV2, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/nursery_fundings/${id}?${getQueryStringV2(params)}`,
  })

  delete = (id: string, params: QueryPropsV2) => this.request({
    method: 'DELETE',
    path: `/v2/nursery_fundings/${id}?${getQueryStringV2(params)}`,
  })
}

export default NurseryFundingApiClient
