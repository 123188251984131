import React from 'react'
import { Link } from 'react-router'

import { getBrandingColor } from 'utils/branding'
import { generateRoute } from 'utils/routing'

import { getNurseryPublicUrl } from 'services/nurseries/single/selectors/single'

import { Typography } from 'components'

interface ReportViewNurseryLinkProps {
  nursery?: any
  queryString?: string
  routeKey?: string
}

const ReportViewNurseryLink: React.FC<ReportViewNurseryLinkProps> = ({
  nursery,
  queryString,
  routeKey,
}) => {
  const { name } = nursery
  const finalQueryString = queryString ? `?${queryString}` : ''

  return (
    <Link
      target="_blank"
      to={`${getNurseryPublicUrl(nursery)}${generateRoute(routeKey)}${finalQueryString}`}
    >
      <Typography color={getBrandingColor('primary-color')} fontSize={14} bold>
        {name}
      </Typography>
    </Link>
  )
}

export default ReportViewNurseryLink
