import styled from 'styled-components'

import colors from 'constants/colors'

export const StyledTextContainer = styled.div`
  padding-top: 30px;
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: ${colors.gray}
`
