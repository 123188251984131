import _ from 'lodash'
import moment from 'moment'

import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { VIEW_MODE } from 'services/legacy/membershipsShifts/constants'

import { millisecondsToHoursAndMinutesString } from 'utils/date'

import { withNurseriesService } from 'services/nurseries'

import { Banner, Space, Spinner, Typography } from 'components'

import i18n from 'translations'

import { StaffStatisticsColumn, StyledStatisticsContainer } from './NurseryRotaItemStyled'

const DATE_STATUS = {
  CURRENT: 'CURRENT',
  FUTURE: 'FUTURE',
  PAST: 'PAST',
}

const NurseryRotaItemStatistics = ({
  date,
  day,
  nurseriesActions,
  nurseriesSelectors,
  nurseryId,
  viewMode,
}) => {
  const [statistics, setStatistics] = useState(null)
  const [statisticsDownloaded, setStatisticsDownloaded] = useState(false)
  const [dateStatus, setDateStatus] = useState(DATE_STATUS.PAST)

  const [ref, inView] = useInView({
    threshold: 0,
  })

  useEffect(() => {
    setStatisticsDownloaded(false)
    setStatistics(null)
  }, [nurseryId, day, date, viewMode])

  useEffect(() => {
    if (inView && !statisticsDownloaded) {
      setStatisticsDownloaded(true)

      const selectedDate = VIEW_MODE.DAY === viewMode ? moment(day) : moment(date)
      const dateToCompare = VIEW_MODE.DAY === viewMode ? moment() : moment().startOf('week')

      if (selectedDate.isSame(dateToCompare, 'day')) {
        setDateStatus(DATE_STATUS.CURRENT)
      }

      if (selectedDate.isBefore(dateToCompare, 'day')) {
        setDateStatus(DATE_STATUS.PAST)
      }

      if (selectedDate.isAfter(dateToCompare, 'day')) {
        setDateStatus(DATE_STATUS.FUTURE)
      }

      nurseriesActions.getRotaStatistics(nurseryId, {
        onSuccess: setStatistics,
        onlyData: true,
        params: {
          criteria: nurseriesSelectors.getRotaStatisticsCriteria({
            date: selectedDate,
            viewMode,
          }),
        },
      })
    }
  }, [
    inView,
    statisticsDownloaded,
    nurseriesActions,
    nurseryId,
    nurseriesSelectors,
    viewMode,
    date,
    day,
  ])

  if (_.isNull(statistics)) {
    return (
      <StyledStatisticsContainer
        alignItems="center"
        minHeight={80}
        ref={ref}
      >
        <Spinner />
      </StyledStatisticsContainer>
    )
  }

  const {
    bankStaffHoursActual,
    bankStaffHoursPlanned,
    breaksActual,
    breaksPlanned,
    leaveHoliday,
    leaveOther,
    leaveSickLeave,
    noContractsHoursActual,
    noContractsHoursPlanned,
    overstaffedActual,
    overstaffedPlanned,
    permanentStaffHoursActual,
    permanentStaffHoursPlanned,
    understaffedActual,
    understaffedPlanned,
  } = statistics

  const renderKeyValue = (key, value, margin = '0 0 2px') => (
    <Typography margin={margin} variant="div">
      <Typography fontSize={14} variant="div" inline>
        {`${key}:`}
        {' '}
      </Typography>
      <Typography fontSize={14} variant="div" bold inline>
        {!_.isNumber(value) ? value : (millisecondsToHoursAndMinutesString(value) || 0) }
      </Typography>
    </Typography>
  )

  const renderActualHours = (pastValue) => (
    <React.Fragment>
      {DATE_STATUS.PAST === dateStatus && renderKeyValue(i18n.t('global:Actual'), pastValue)}
      {DATE_STATUS.CURRENT === dateStatus && renderKeyValue(i18n.t('global:Actual'), i18n.t('global:ongoing'))}
    </React.Fragment>
  )

  const renderPermanentStaffColumn = () => (
    <StaffStatisticsColumn>
      <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
        {i18n.t('module:Organization:NurseryRota:TableHeaders:permanent')}
      </Typography>
      <Space space="10px" />
      {renderKeyValue(i18n.t('global:Planned'), permanentStaffHoursPlanned)}
      {renderActualHours(permanentStaffHoursActual)}
    </StaffStatisticsColumn>
  )

  const renderBankColumn = () => (
    <StaffStatisticsColumn>
      <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
        {i18n.t('module:Organization:NurseryRota:TableHeaders:bank')}
      </Typography>
      <Space space="10px" />
      {renderKeyValue(i18n.t('global:Planned'), bankStaffHoursPlanned)}
      {renderActualHours(bankStaffHoursActual)}
    </StaffStatisticsColumn>
  )

  const renderBreaksColumn = () => (
    <StaffStatisticsColumn>
      <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
        {i18n.t('module:Organization:NurseryRota:TableHeaders:breaks')}
      </Typography>
      <Space space="10px" />
      {renderKeyValue(i18n.t('global:Planned'), breaksPlanned)}
      {renderActualHours(breaksActual)}
    </StaffStatisticsColumn>
  )

  const renderUnderstaffedColumn = () => (
    <StaffStatisticsColumn>
      <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
        {i18n.t('module:Organization:NurseryRota:TableHeaders:understaffed')}
      </Typography>
      <Space space="10px" />
      {renderKeyValue(i18n.t('global:Planned'), understaffedPlanned)}
      {renderActualHours(understaffedActual)}
    </StaffStatisticsColumn>
  )

  const renderOverstaffedColumn = () => (
    <StaffStatisticsColumn>
      <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
        {i18n.t('module:Organization:NurseryRota:TableHeaders:overstaffed')}
      </Typography>
      <Space space="10px" />
      {renderKeyValue(i18n.t('global:Planned'), overstaffedPlanned)}
      {renderActualHours(overstaffedActual)}
    </StaffStatisticsColumn>
  )

  const renderLeaveColumn = () => (
    <StaffStatisticsColumn>
      <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
        {i18n.t('module:Organization:NurseryRota:TableHeaders:leave')}
      </Typography>
      <Space space="10px" />
      {renderKeyValue(i18n.t('module:Organization:NurseryRota:holiday'), leaveHoliday)}
      {renderKeyValue(i18n.t('module:Organization:NurseryRota:sickLeave'), leaveSickLeave)}
      {renderKeyValue(i18n.t('global:Other'), leaveOther)}
    </StaffStatisticsColumn>
  )

  const renderBanner = () => {
    if (
      (DATE_STATUS.PAST !== dateStatus && !noContractsHoursPlanned)
      || (!noContractsHoursPlanned && !noContractsHoursActual)
    ) {
      return <Space space="25px" />
    }

    return (
      <Banner.Warning margin="15px 0 0" noLeftBorder>
        <React.Fragment>
          <Typography bold inline>
            {i18n.t('module:Organization:NurseryRota:withoutContractCopy')}
          </Typography>
          <Typography fontSize={14} margin="0 0 0 15px" variant="div" inline>
            {`${i18n.t('global:Planned')}:`}
            {' '}
          </Typography>
          <Typography fontSize={14} variant="div" bold inline>
            {millisecondsToHoursAndMinutesString(noContractsHoursPlanned) || 0}
          </Typography>
          {(DATE_STATUS.PAST === dateStatus || DATE_STATUS.CURRENT === dateStatus) && (
            <React.Fragment>
              <Typography fontSize={14} margin="0 0 0 15px" variant="div" inline>
                {`${i18n.t('global:Actual')}:`}
                {' '}
              </Typography>
              <Typography fontSize={14} variant="div" bold inline>
                {DATE_STATUS.CURRENT === dateStatus
                  ? i18n.t('global:ongoing')
                  : (millisecondsToHoursAndMinutesString(noContractsHoursActual) || 0)}
              </Typography>
            </React.Fragment>
          )}
        </React.Fragment>
      </Banner.Warning>
    )
  }

  return (
    <React.Fragment>
      <StyledStatisticsContainer>
        {renderPermanentStaffColumn()}
        {renderBankColumn()}
        {renderBreaksColumn()}
        {renderUnderstaffedColumn()}
        {renderOverstaffedColumn()}
        {renderLeaveColumn()}
      </StyledStatisticsContainer>
      {renderBanner()}
    </React.Fragment>
  )
}

const mapState = (state, {
  nurseriesSelectors,
}) => ({
  nurseries: nurseriesSelectors.getNurseryRotaStatisticsDataSelector(state),
})

const enhance = compose(
  withNurseriesService,
  connect(mapState),
)

export default enhance(NurseryRotaItemStatistics)
