import React, { PropsWithChildren } from 'react'

import { StyledHexagon } from './HexagonStyled'

const Hexagon: React.FC<PropsWithChildren<any>> = ({ children }) => (
  <StyledHexagon>
    {children}
  </StyledHexagon>
)

export default Hexagon
