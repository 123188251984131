import _ from 'lodash'
import moment from 'moment'

import colors from 'constants/colors'

import i18n from 'translations'
import { BREAKDOWN_MODE_TYPE, COMMON_TABLE_COLUMNS } from './constants'

export const getTableData = ({ data, filters, hasError }) => {
  if (!data?.length || hasError) {
    return null
  }

  return _.map(data, ({ breakdown, ...rest }) => ({
    breakdown: BREAKDOWN_MODE_TYPE.MONTH === filters?.breakdownMode
      ? moment(breakdown).format('MMMM YYYY')
      : `${_.upperFirst(i18n.t('global:week'))} ${breakdown.split('-')[1]} - ${breakdown.split('-')[0]}`,
    ...rest,
  }))
}

export const getChartData = ({ data, filters, hasError }) => {
  if (!data?.length || hasError) {
    return null
  }

  return {
    categories: _.map(data, ({ breakdown }) => (
      BREAKDOWN_MODE_TYPE.MONTH === filters?.breakdownMode
        ? moment(breakdown).format('MMMM YYYY')
        : `${_.upperFirst(i18n.t('global:week'))} ${breakdown.split('-')[1]} - ${breakdown.split('-')[0]}`
    )),
    data: [
      {
        color: colors.standardPieChartColors[0],
        data: _.map(data, ({ win }) => win),
        name: i18n.t('module:Enquiries:Report:Performance:DataLabels:won'),
      },
      {
        color: colors.standardPieChartColors[1],
        data: _.map(data, ({ lost }) => lost),
        name: i18n.t('module:Enquiries:Report:Performance:DataLabels:lost'),
      },
      {
        color: colors.standardPieChartColors[4],
        data: _.map(data, ({ open }) => open),
        name: i18n.t('module:Enquiries:Report:Performance:DataLabels:open'),
      },
    ],
  }
}

export const getColumns = (
  isOrganizationContext,
  data,
  statisticsData,
  filters,
) => [
  {
    align: 'left',
    alignTh: 'left',
    field: 'breakdown',
    title: BREAKDOWN_MODE_TYPE.WEEK === filters?.breakdownMode
      ? i18n.t('module:Enquiries:Report:Performance:Table:Header:week')
      : i18n.t('module:Enquiries:Report:Performance:Table:Header:month'),
  },
  ...COMMON_TABLE_COLUMNS,
]
