import { LIST_INJURY_RECORDS, LIST_INJURY_RECORDS_FAILED, LIST_INJURY_RECORDS_SUCCESS } from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: {},
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case LIST_INJURY_RECORDS:
      return { ...state, error: initialState.error, isFetching: true }
    case LIST_INJURY_RECORDS_SUCCESS:
      return { ...state, data: payload.data, isFetching: false, meta: payload.meta }
    case LIST_INJURY_RECORDS_FAILED:
      return { ...state, error, isFetching: false }
    default:
      return state
  }
}
