import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { isRequired } from 'utils/fieldValidation'

import { Form, Icon, Space, Typography } from 'components'

import i18n from 'translations'

import {
  StyledContainer,
  StyledIcon,
  StyledSourceLabel,
  StyledSourceValue,
  StyledTargetLabel,
  StyledTargetValue,
} from './StatusTypeMigrationModalFormStyled'

export const ENQUIRY_STATUS_TYPE_MIGRATION_FORM = 'enquiryStatusTypeMigrationForm'

const StatusTypeMigrationModalForm = ({
  enquiryStatusType,
  handleSubmit,
  isPreviewMode,
  isSubmitting,
  newStage,
  onCancelClick,
  onSubmit,
}) => {
  const { enquiriesCount, name: statusName } = enquiryStatusType || {}

  const renderHeader = (title, subTitle) => (
    <React.Fragment>
      <Typography bold>
        {title}
      </Typography>
      <Typography color={NEUTRAL_COLOURS.GRAY}>
        {subTitle}
      </Typography>
    </React.Fragment>
  )

  const renderSubtitle = () => {
    const enquiryLabel = 1 < enquiriesCount
      ? i18n.t('module:Enquiries:List:enquiries')
      : i18n.t('module:Enquiries:List:enquiry')

    const subTitle2 = 1 < enquiriesCount
      ? i18n.t('module:Modals:Enquiry:StatusTypeMigration:subtitle2Plural')
      : i18n.t('module:Modals:Enquiry:StatusTypeMigration:subtitle2Singular')

    if (isPreviewMode) {
      return (
        <Typography variant="span" bold>
          {i18n.t('module:Modals:Enquiry:StatusTypeMigration:previewSubtitle', {
            enquiriesCount,
            enquiryLabel,
          })}
        </Typography>
      )
    }

    return (
      <React.Fragment>
        {i18n.t('module:Modals:Enquiry:StatusTypeMigration:subtitle1', { statusName })}
        {' '}
        <Typography variant="span" bold>
          {`${enquiriesCount} ${enquiryLabel}`}
        </Typography>
        {' '}
        {subTitle2}
      </React.Fragment>
    )
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {renderSubtitle()}
      <Space margin="30px" />
      <StyledContainer>
        <StyledSourceLabel>
          {renderHeader(
            i18n.t('module:Modals:Enquiry:StatusTypeMigration:Form:SourceLabel:title'),
            i18n.t('module:Modals:Enquiry:StatusTypeMigration:Form:SourceLabel:subtitle'),
          )}
        </StyledSourceLabel>
        <StyledTargetLabel>
          {renderHeader(
            i18n.t('module:Modals:Enquiry:StatusTypeMigration:Form:TargetLabel:title'),
            i18n.t('module:Modals:Enquiry:StatusTypeMigration:Form:TargetLabel:subtitle'),
          )}
        </StyledTargetLabel>
        <StyledSourceValue>
          {statusName}
        </StyledSourceValue>
        <StyledIcon>
          <Icon
            color={NEUTRAL_COLOURS.GRAY}
            height={18}
            icon="arrow_forward"
          />
        </StyledIcon>
        <StyledTargetValue>
          {isPreviewMode
            ? newStage?.label
            : (
              <Field
                component={Form.InfiniteDropdowns.EnquiryStatusTypes}
                name="newStage"
                placeholder={i18n.t('module:Modals:Enquiry:StatusTypeMigration:Form:TargetStatusType:placeholder')}
                validate={isRequired}
              />
            )}
        </StyledTargetValue>
      </StyledContainer>
      <Form.FooterActions
        cancelLabel={isPreviewMode ? i18n.t('global:Back') : undefined}
        isSubmitting={isSubmitting}
        submitLabel={isPreviewMode ? i18n.t('global:Migrate') : i18n.t('global:Next')}
        onCancelClick={onCancelClick}
      />
    </Form>
  )
}

const validate = (fields, props) => {
  const errors = {}
  const { newStage } = fields
  const { value: newStageId } = newStage || {}

  const { enquiryStatusType } = props
  const { id: oldStageId } = enquiryStatusType || {}

  if (newStageId && oldStageId && newStageId === oldStageId) {
    errors.newStage = i18n.t('module:Modals:Enquiry:StatusTypeMigration:Form:sameTypeValidationMessage')
  }

  return errors
}

export default reduxForm({ form: ENQUIRY_STATUS_TYPE_MIGRATION_FORM, validate })(StatusTypeMigrationModalForm)
