import _ from 'lodash'

import React, { PropsWithChildren, useCallback } from 'react'

import { typeByObject } from 'utils/typescript'

import { Field, FieldError, Icon, Typography } from 'components'

import {
  StyledIconContainer,
  StyledInput,
  StyledInputContainer,
  StyledPrefix,
  StyledSuffix,
  VISUAL_TYPE,
} from './TextFieldStyled'

export interface TextFieldProps {
  disabled?: boolean
  error?: string | React.ReactNode
  hideError?: boolean
  icon?: IconType
  label?: string
  max?: number | Date
  maxLength?: number
  min?: number | Date
  onBlur?: React.EventHandler<any>
  onChange?: (value: any) => void
  onFocus?: React.EventHandler<any>
  placeholder?: string
  prefix?: string
  prefixWidth?: string
  step?: string
  suffix?: string
  suffixWidth?: string
  type?: string
  valid?: boolean
  value?: string | number
  visualType?: typeByObject<typeof VISUAL_TYPE>
  withoutDecimal?: boolean
}

const TextField: React.FC<PropsWithChildren<TextFieldProps>> = ({
  disabled,
  error,
  hideError,
  icon,
  label,
  max,
  maxLength,
  min,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  prefix,
  prefixWidth,
  step,
  suffix,
  suffixWidth,
  type = 'text',
  valid,
  value,
  visualType,
  withoutDecimal,
}) => {
  const handleOnChange = (e) => {
    const updatedE = _.cloneDeep(e)

    if (maxLength && e.target.value?.length > maxLength) {
      updatedE.target.value = e.target.value.slice(0, maxLength)
    }

    onChange(updatedE)
  }

  const renderIcon = useCallback(() => {
    if (!icon) {
      return null
    }

    return (
      <StyledIconContainer>
        <Icon height={20} icon={icon} />
      </StyledIconContainer>
    )
  }, [icon])

  return (
    <React.Fragment>
      {label && <Field.TopLabel hidden={!value} label={label} />}
      <StyledInputContainer $valid={valid} $visualType={visualType}>
        {prefix && (
          <StyledPrefix>
            <Typography fontSize={14} variant="span">
              {prefix}
            </Typography>
          </StyledPrefix>
        )}
        <StyledInput
          $hasIcon={!!icon}
          $paddingLeft={prefixWidth}
          $paddingRight={suffixWidth}
          $valid={valid}
          $visualType={visualType}
          disabled={disabled}
          max={max}
          maxLength={maxLength}
          min={min}
          placeholder={placeholder}
          step={step}
          type={type}
          value={value}
          onBlur={onBlur}
          onChange={handleOnChange}
          onFocus={onFocus}
          onKeyDown={(e) => {
            if (!withoutDecimal) {
              return true
            }
            // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
            let checkIfNum

            if (e.key !== undefined) {
              // Check if it's a "e", ".", "+" or "-"
              checkIfNum = 'e' === e.key || '.' === e.key || '+' === e.key || '-' === e.key
            } else if (e.keyCode !== undefined) {
              // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
              checkIfNum = 69 === e.keyCode || 190 === e.keyCode || 187 === e.keyCode || 189 === e.keyCode
            }

            return checkIfNum && e.preventDefault()
          }}
        />
        {suffix && (
          <StyledSuffix>
            <Typography fontSize={14} variant="span">
              {suffix}
            </Typography>
          </StyledSuffix>
        )}
        {renderIcon()}
      </StyledInputContainer>
      <FieldError error={!hideError && error} />
    </React.Fragment>
  )
}

export default TextField
