import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withUsersService } from 'services/users'
import { withSecurityService } from 'services/security'
import { withRouter } from 'services/router'

import NurseryDashboardView from './NurseryDashboardView'

class NurseryDashboardContainer extends Component {
  componentDidMount() {
    const { currentUser, navigate, usersActions } = this.props

    if (currentUser?.user?.id) {
      usersActions.getUserSettings(currentUser?.user?.id)
    } else {
      navigate(generateRoute('AUTHENTICATION.LOGIN'))
    }
  }

  componentWillUnmount() {
    const { usersActions } = this.props

    usersActions.clearUserSettings()
  }

  render() {
    const { authAccessMap, isFetching, isOffline, selectedWidgets, user } = this.props

    return (
      <NurseryDashboardView
        authAccessMap={authAccessMap}
        isFetching={isFetching}
        isOffline={isOffline}
        selectedWidgets={selectedWidgets}
        user={user}
      />
    )
  }
}

const mapState = (state, { appSelectors, securitySelectors, usersSelectors, usersSettingsState }) => ({
  authAccessMap: {
    section: usersSelectors.getWidgetsPermission(state),
  },
  currentUser: securitySelectors.getAuthProfile(state),
  isFetching: appSelectors.getIsFetching(usersSettingsState),
  isOffline: appSelectors.getAppIsOffline(state),
  selectedWidgets: usersSelectors.getNurserySelectedWidgets(state),
})

const enhance = compose(
  withAppService,
  withUsersService,
  withSecurityService,
  withRouter,
  connect(mapState),
)

export default enhance(NurseryDashboardContainer)
