import React from 'react'

import { Callout, Page, Section, Spinner } from 'components'

import ChildExtraItemsAddForm from './components/ChildExtraItemsAddForm'

const ChildExtraItemsAddView = ({
  childId,
  cost,
  description,
  disableEndDate,
  errorMessages,
  feeCalculationOptions,
  formValues,
  frequencyOptions,
  initialValues,
  isArchived,
  isEdit,
  isFormLoading,
  isLoading,
  onArchiveClick,
  onLoadMoreExtraItems,
  onSubmit,
  showDateRange,
  showFeeCalculation,
  showQuantity,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <Section
        actions={isEdit && (
          <Section.Actions
            options={[{
              onClick: onArchiveClick(!isArchived, initialValues?.type.value),
              type: isArchived ? 'unarchive' : 'archive',
            }]}
          />
        )}
        title={isEdit ? 'Edit an extra item' : 'Add an extra item'}
      >
        <ChildExtraItemsAddForm
          childId={childId}
          cost={cost}
          description={description}
          disableEndDate={disableEndDate}
          feeCalculationOptions={feeCalculationOptions}
          formValues={formValues}
          frequencyOptions={frequencyOptions}
          initialValues={initialValues}
          isEdit={isEdit}
          isLoading={isFormLoading}
          showDateRange={showDateRange}
          showFeeCalculation={showFeeCalculation}
          showQuantity={showQuantity}
          onLoadMoreExtraItems={onLoadMoreExtraItems}
          onSubmit={onSubmit}
        />
      </Section>
    )
  }

  return (
    <Page isLoading={isLoading}>
      <Page.Content>
        <Callout content={errorMessages} error />
        {renderContent()}
      </Page.Content>
    </Page>
  )
}

export default ChildExtraItemsAddView
