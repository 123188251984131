import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'

import { Button, Field, FieldError, Icon } from 'components'

import i18n from 'translations'

import { DATE_PICKER_TYPE } from '../DatePickerConstants'
import { isDateDisabled } from '../DatePickerHelper'

import {
  StyledButtonContainer,
  StyledFlex,
  StyledInputWrapper,
  StyledLabel,
  StyledPlaceholder,
  StyledWrapper,
  VISUAL_TYPE,
} from '../DatePickerStyled'

const PopoverButton = ({
  clearable,
  currentValue,
  disabled,
  disabledDays,
  error,
  hideNavigationButton,
  label,
  localDateValue,
  onArrowClick,
  onChange,
  placeholder,
  range,
  type,
  value,
  visualType,
  width,
}) => {
  const handleBackDate = (e) => {
    e.stopPropagation()

    const newDate = moment(localDateValue[0] || localDateValue).add(-7, 'days')

    if (onArrowClick) {
      const result = onArrowClick(-1, localDateValue[0] || localDateValue, onChange)

      if (result) {
        return false
      }
    }

    if (disabledDays && isDateDisabled(disabledDays, newDate)) {
      return false
    }

    if (DATE_PICKER_TYPE.MONTH === type) {
      return onChange(moment(localDateValue).add(-1, 'month'))
    }

    return onChange([newDate, moment(newDate).endOf('week')])
  }

  const handleNextDate = (e) => {
    e.stopPropagation()

    const newDate = moment(localDateValue[0]).add(7, 'days')

    if (onArrowClick) {
      const result = onArrowClick(1, localDateValue[0], onChange)

      if (result) {
        return false
      }
    }

    if (DATE_PICKER_TYPE.MONTH === type) {
      return onChange(moment(localDateValue).add(1, 'month'))
    }

    return onChange([newDate, moment(newDate).endOf('week')])
  }

  const renderIcon = () => {
    if (!clearable || !currentValue) {
      return (
        <Icon
          height={VISUAL_TYPE.TRANSPARENT === visualType ? 22 : 26}
          icon="calendar"
        />
      )
    }

    return (
      <Icon
        height={24}
        icon="close"
        onClick={(e) => {
          e.stopPropagation()

          onChange(_.isArray(localDateValue) ? [undefined, undefined] : null)
        }}
      />
    )
  }

  const getLabel = () => {
    if (!currentValue) {
      return (
        <StyledPlaceholder>
          {placeholder || (
            _.isArray(localDateValue)
              ? i18n.t('components:DatePicker:dateRangePlaceholder')
              : i18n.t('components:DatePicker:singleDatePlaceholder')
          )}
        </StyledPlaceholder>
      )
    }

    if (DATE_PICKER_TYPE.MONTH === type && !range) {
      return (
        <StyledLabel>
          {moment(localDateValue).format('MMMM YYYY')}
        </StyledLabel>
      )
    }

    if (DATE_PICKER_TYPE.MONTH === type && range) {
      const [from, to] = localDateValue

      return (
        <StyledLabel>
          <span>
            {from && moment(from).format('MM/YYYY')}
          </span>
          <span>
            {' - '}
          </span>
          <span>
            {to && moment(to).format('MM/YYYY')}
          </span>
        </StyledLabel>
      )
    }

    if (range) {
      const [from, to] = localDateValue

      return (
        <StyledLabel>
          <span>
            {from
              ? moment(from).format(DISPLAY_SHORT_DATE_FORMAT)
              : _.upperFirst(i18n.t('global:startDate'))}
          </span>
          <span>
            {' - '}
          </span>
          <span>
            {to
              ? moment(to).format(DISPLAY_SHORT_DATE_FORMAT)
              : _.upperFirst(i18n.t('global:endDate'))}
          </span>
        </StyledLabel>
      )
    }

    const localDate = moment(localDateValue)

    return localDate.isValid() ? localDate.format(DISPLAY_SHORT_DATE_FORMAT) : '-'
  }

  const getDisabledFlag = (direction = 1) => {
    if (disabled) {
      return disabled
    }

    if (DATE_PICKER_TYPE.MONTH === type && !range) {
      isDateDisabled(disabledDays, moment(localDateValue), direction)
    }

    return isDateDisabled(disabledDays, moment(localDateValue).add(7 * direction, 'days'), direction)
  }

  return (
    <StyledButtonContainer $width={width}>
      {label && <Field.TopLabel hidden={!value} label={label} />}
      <StyledWrapper
        $threeColumns={
          !hideNavigationButton && (DATE_PICKER_TYPE.WEEK === type || (DATE_PICKER_TYPE.MONTH === type && !range))
        }
      >
        {!hideNavigationButton && (DATE_PICKER_TYPE.WEEK === type || (DATE_PICKER_TYPE.MONTH === type && !range)) && (
          <Button
            disabled={getDisabledFlag(-1)}
            hierarchy="secondary"
            icon="chevron-left"
            size="small"
            onClick={handleBackDate}
          />
        )}
        <StyledInputWrapper
          $disabled={disabled}
          $error={error}
          $range={range}
          $visualType={visualType}
        >
          {getLabel()}
          <StyledFlex />
          {renderIcon()}
        </StyledInputWrapper>
        {!hideNavigationButton && (DATE_PICKER_TYPE.WEEK === type || (DATE_PICKER_TYPE.MONTH === type && !range)) && (
          <Button
            disabled={getDisabledFlag()}
            hierarchy="secondary"
            icon="chevron-right"
            size="small"
            onClick={handleNextDate}
          />
        )}
      </StyledWrapper>
      {VISUAL_TYPE.MODERN !== visualType && (
        <FieldError error={error} />
      )}
    </StyledButtonContainer>
  )
}

export default PopoverButton
