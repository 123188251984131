import styled from 'styled-components'

interface StyledContainerProps {
  $padding?: string
}

export const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: ${({ $padding }) => $padding || '50px 0'};
`

interface StyledIconContainerProps {
  $iconComponentSmallMargin?: boolean
}

export const StyledIconContainer = styled.div<StyledIconContainerProps>`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ $iconComponentSmallMargin }) => ($iconComponentSmallMargin ? '15px' : '30px')};
`

export const StyledIconImage = styled.img`
  display: block;
`

export const StyledContent = styled.div`
  text-align: center;
  max-width: 400px;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 1.4;

  & > div {
    margin: 0;
  }

  & > div + p {
    margin-top: 15px;
  }
`
export const StyledActionsContainer = styled.div`
  margin-top: 30px;
`
