import { Option } from 'constants/models'
import i18n from 'translations'

import { createTypeFromEnumValues } from 'utils/typescript'

export enum SESSION_TYPES {
  FIXED_SESSION_TIME = 'FIXED_SESSION_TIME',
  FLEXIBLE_HOURLY_SESSION = 'FLEXIBLE_HOURLY_SESSION',
}

export const SESSION_TYPE_OPTIONS = [
  {
    label: i18n.t('module:Management:Finance:SessionsV3:Add:Form:SessionType:OptionLabels:fixedSessionTime'),
    value: SESSION_TYPES.FIXED_SESSION_TIME,
  },
  {
    label: i18n.t('module:Management:Finance:SessionsV3:Add:Form:SessionType:OptionLabels:flexibleHourlySession'),
    value: SESSION_TYPES.FLEXIBLE_HOURLY_SESSION,
  },
]

export enum CONSUMABLE_CHARGEABLE_TYPE {
  PER_HOUR = 'hour',
  PER_SESSION ='session',
}

export const CONSUMABLE_CHARGEABLE_OPTIONS = [
  {
    label: i18n.t('module:Management:Finance:SessionsV3:Add:Consumables:Options:PerHour:label'),
    value: CONSUMABLE_CHARGEABLE_TYPE.PER_HOUR,
  },
  {
    label: i18n.t('module:Management:Finance:SessionsV3:Add:Consumables:Options:PerSession:label'),
    value: CONSUMABLE_CHARGEABLE_TYPE.PER_SESSION,
  },
]

interface NurseryConsumablesExtraItem {
  chargeable?: Option
  consumable?: Option
}

export interface NurseryFees {
  [key: string]: any
  endDate?: Date
  startDate?: Date

}
export interface SessionAddFormValues {
  endTime?: number
  name: string
  nurseryConsumablesExtraItems?: NurseryConsumablesExtraItem[]
  nurseryFees: NurseryFees[]
  sessionType?: createTypeFromEnumValues<SESSION_TYPES>
  startTime?: number
}
