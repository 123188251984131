import styled from 'styled-components'

import { getSpinner } from './LastSaveDateTimeHelpers'

export const StyledContainer = styled.div`
  color: #6D6E70;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 10px;
`

export const StyledSpinnerContainer = styled(getSpinner)`
  color: #6D6E70 !important;
  line-height: 16px;
  padding-left: 25px;
`

export const StyledDateContainer = styled.div`
  font-size: 18px;
  font-weight: 600;
  display: flex;
`

export const StyledByLabelContainer = styled.span`
  font-weight: 400;
  padding: 0 5px;
`

export const StyledSaveLabelContainer = styled.div`
  font-weight: 400;
  font-size: 16px;
`
