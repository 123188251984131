import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as depositsSelectors from './selectors'

const withDepositsService = (WrappedComponent) => {
  const mapState = ({ deposits }) => ({ deposits })

  const mapDispatch = injectActions('depositsActions', actions)

  const Component = ({ deposits, depositsActions, ...others }) => (
    <WrappedComponent
      depositsActions={depositsActions}
      depositsListState={deposits.list}
      depositsSelectors={depositsSelectors}
      depositsSingleState={deposits.single}
      depositsStatisticsState={deposits.statistics}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withDepositsService
