import _ from 'lodash'

import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import {
  CLEAR_LIST_OF_FRAMEWORK_PROGRESS,
  LIST_OF_FRAMEWORK_PROGRESS,
  LIST_OF_FRAMEWORK_PROGRESS_FAILED,
  LIST_OF_FRAMEWORK_PROGRESS_SUCCESS,
  UPDATE_BATCH_FRAMEWORK_PROGRESS,
  UPDATE_BATCH_FRAMEWORK_PROGRESS_FAILED,
  UPDATE_BATCH_FRAMEWORK_PROGRESS_SUCCESS,
  UPDATE_SINGLE_PROGRESS,
} from './constants'

export default (...props) => {
  const [state = {
    ...getBasicListInitialState,
    isSubmitting: false,
  }, { payload, type }] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_LIST_OF_FRAMEWORK_PROGRESS,
    failed: LIST_OF_FRAMEWORK_PROGRESS_FAILED,
    init: LIST_OF_FRAMEWORK_PROGRESS,
  }, getBasicListInitialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  if (type === UPDATE_BATCH_FRAMEWORK_PROGRESS) {
    return {
      ...state,
      isSubmitting: true,
    }
  }

  if (type === UPDATE_BATCH_FRAMEWORK_PROGRESS_FAILED) {
    return {
      ...state,
      isSubmitting: false,
    }
  }

  if (type === UPDATE_BATCH_FRAMEWORK_PROGRESS_SUCCESS) {
    if (_.find(payload.data, (i) => i.exception?.error)) {
      return {
        ...state,
        isSubmitting: false,
      }
    }

    const { data: [{ childFrameworkItemEvent }] } = payload
    const { frameworkItem } = childFrameworkItemEvent || {}
    const { linkedItems } = frameworkItem || {}

    let data = {
      ...state.data,
      [childFrameworkItemEvent.frameworkItem.id]: childFrameworkItemEvent,
    }

    if (!childFrameworkItemEvent.progressLevel.state) {
      data = _.pickBy(state.data, (value, key) => (
        +childFrameworkItemEvent.frameworkItem.id !== +key
      ))

      _.each(linkedItems, ({ id: linkedItemId }) => {
        data = _.pickBy(data, (value, key) => (
          linkedItemId !== +key
        ))
      })
    } else {
      _.each(linkedItems, ({ id: linkedItemId }) => {
        data[linkedItemId] = {
          ...childFrameworkItemEvent,
          id: null,
        }
      })
    }

    return {
      ...state,
      data,
      isSubmitting: false,
    }
  }

  if (type === UPDATE_SINGLE_PROGRESS && payload?.frameworkItem?.id) {
    return {
      ...state,
      data: {
        ...state.data,
        [payload.frameworkItem.id]: {
          [payload.state]: {
            state: payload.state,
          },
        },
      },
    }
  }

  if (type === LIST_OF_FRAMEWORK_PROGRESS_SUCCESS) {
    const data = {}

    _.each(payload.data?.groups, ({ categories }) => {
      _.each(categories, ({ items }) => {
        _.each(items, (item) => {
          if (_.keys(item?.progress?.progress).length) {
            data[item.id] = item?.progress?.progress?.[0]
          }
        })
      })
    })

    return {
      data,
      error: null,
      isFetching: false,
      meta: {
        ...state.meta,
        ...payload.meta,
      },
    }
  }

  return state
}
