import React, { useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'

import { RootState } from 'core/reducers'
import { Option } from 'constants/models'
import { LIST_STATUS_FILTERS, LIST_STATUS_FILTERS_DROPDOWN } from 'constants/filters'
import { OneOffBookingTypes } from 'services/booking/childProducts/constants'
import { WrappedComponentType } from 'constants/types'
import { ROLES } from 'constants/security'

import { createTypeFromEnumValues } from 'utils/typescript'

import { withAppService, withAppServiceProps } from 'services/app'
import { withPaginationUtils, withPaginationUtilsProps } from 'services/utils/pagination'
import { withRouteUtilsProps, withRouterUtils } from 'services/utils/router'
import { withChildProductsService, withChildProductsServiceProps } from 'services/booking/childProducts'
import { withRouter, withRouterProps } from 'services/router'

import { getTableData } from './ListHelpers'
import ListView from './ListView'

export type TypeOption = Option<createTypeFromEnumValues<OneOffBookingTypes>>
export type StatusOptionType = Option<createTypeFromEnumValues<LIST_STATUS_FILTERS>>

interface FilterProps {
  dateRange?: string[]
  invoiceStatus?: Option
  status?: StatusOptionType
  type?: TypeOption
}

const DEFAULT_SORT = {
  sortField: 'date',
  sortOrder: 'desc',
}

const ONE_OFF_BOOKING_GROUPS = {
  read: [
    'oneOffChildProduct.child',
    'child',
    'oneOffChildProduct.product',
    'nurserySessionProduct',
    'nurseryItemProduct',
    'nurseryDiscountProduct',
    'nurseryFundingProduct',
    'oneOffChildProduct.settings',
    'oneOffChildProductSettings',
    'oneOffChildProduct.listDetails',
    'oneOffChildProduct.invoice',
    'invoice',
  ],
}

type ListContainerProps = withChildProductsServiceProps
  & withAppServiceProps
  & withRouteUtilsProps
  & withPaginationUtilsProps
  & withRouterProps

const mapState = (state: RootState, {
  appSelectors,
  childProductsListState,
  childProductsSelectors,
}: ListContainerProps) => ({
  childProducts: childProductsSelectors.getChildProductsListSelector(state),
  errorMessages: appSelectors.getErrorMessages(childProductsListState),
  isFetching: appSelectors.getIsFetching(childProductsListState),
  totalResults: appSelectors.getTotalResults(childProductsListState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const ListContainer: WrappedComponentType<ListContainerProps & PropsFromRedux> = ({
  childProducts,
  childProductsActions,
  childProductsSelectors,
  errorMessages,
  isFetching,
  paginationUtils,
  params,
  totalResults,
}) => {
  const { childId } = params || {}
  const [search, setSearch] = useState<string>()
  const [filters, setFilters] = useState<FilterProps>({
    dateRange: [undefined, undefined],
    status: LIST_STATUS_FILTERS_DROPDOWN.find(({ value }) => value === LIST_STATUS_FILTERS.ACTIVE),
  })

  const { getPageCount, onPageChange, page, perPage } = paginationUtils

  const fetchData = () => {
    const { dateRange, invoiceStatus, status, type } = filters

    const criteria = childProductsSelectors.getCriteria({
      childId,
      dateRange,
      invoiceStatus: invoiceStatus?.value,
      search,
      status: status?.value,
      type: type?.value,
    })

    childProductsActions.list({
      params: {
        criteria,
        groups: ONE_OFF_BOOKING_GROUPS,
        limit: perPage,
        order: {
          sortField: DEFAULT_SORT.sortField,
          sortOrder: DEFAULT_SORT.sortOrder,
        },
        page,
      },
    })
  }

  useEffect(() => {
    fetchData()
  }, [
    page,
    search,
    filters.dateRange[0],
    filters.dateRange[1],
    filters.type,
    filters.status,
    filters.invoiceStatus,
  ])

  const handlePageChange = (newPage) => {
    onPageChange()(newPage)
  }

  const handleSearchChange = (newSearch) => {
    setSearch(newSearch)
  }

  const handleDateRangeChange = (dateRange) => {
    setFilters((oldFilters) => ({
      ...oldFilters,
      dateRange,
    }))
  }

  const handleTypeChange = (newType: TypeOption) => {
    setFilters((oldFilters) => ({
      ...oldFilters,
      type: newType,
    }))
  }

  const handleStatusChange = (newStatus: StatusOptionType) => {
    setFilters((oldFilters) => ({
      ...oldFilters,
      status: newStatus,
    }))
  }

  const handleInvoiceStatusChange = (newInvoiceStatus: Option) => {
    setFilters((oldFilters) => ({
      ...oldFilters,
      invoiceStatus: newInvoiceStatus,
    }))
  }

  const childProductsTableData = getTableData(childProducts, childId)
  const pageCount = getPageCount(totalResults)

  return (
    <ListView
      childId={childId}
      childProducts={childProductsTableData}
      dateRange={filters?.dateRange}
      errorMessages={errorMessages}
      invoiceStatus={filters.invoiceStatus}
      isLoading={isFetching}
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      search={search}
      status={filters.status}
      totalResults={totalResults}
      type={filters?.type}
      onDateRangeChange={handleDateRangeChange}
      onInvoiceStatusChange={handleInvoiceStatusChange}
      onPageChange={handlePageChange}
      onSearchChange={handleSearchChange}
      onStatusChange={handleStatusChange}
      onTypeChange={handleTypeChange}
    />
  )
}

ListContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.DEPUTY_MANAGER,
  ],
}

const enhance = compose(
  withAppService,
  withChildProductsService,
  withRouter,
  withRouterUtils,
  withPaginationUtils,
  connector,
)

export default enhance(ListContainer)
