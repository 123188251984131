import { CLEAR_IMPORT_PAYMENTS, IMPORT_PAYMENTS, IMPORT_PAYMENTS_FAILED, IMPORT_PAYMENTS_SUCCESS } from './constants'

const initialState = {
  data: null,
  error: null,
  isSubmitting: false,
}

export default (state = initialState, { error, type }) => {
  switch (type) {
    case IMPORT_PAYMENTS:
      return { ...state, error: initialState.error, isSubmitting: true }
    case IMPORT_PAYMENTS_SUCCESS:
      return { ...state, isSubmitting: false }
    case IMPORT_PAYMENTS_FAILED:
      return { ...state, error, isSubmitting: false }
    case CLEAR_IMPORT_PAYMENTS:
      return initialState
    default:
      return state
  }
}
