import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

const getInvoicesState = (state) => state.invoices

export const getInvoicesBadDebtData = createSelector(
  [getInvoicesState],
  (state) => state.badDebt.data,
)

export const getInvoicesBadDebtCriteriaV2 = (params) => {
  const criteria = []

  const { badDebtDate, child, dateType, room } = params
  const { after, before } = badDebtDate

  if (dateType) {
    criteria.push({
      field: 'dateField',
      value: dateType,
    })
  }

  if (after) {
    criteria.push({
      field: 'startDate',
      value: moment(after).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (before) {
    criteria.push({
      field: 'endDate',
      value: moment(before).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (room) {
    criteria.push({
      field: 'nurseryClass',
      value: room,
    })
  }

  if (child) {
    criteria.push({
      field: 'child',
      value: child,
    })
  }

  return criteria
}
