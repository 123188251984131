import { BasicListInitialState } from 'utils/reducer'

import {
  CLEAR_NURSERIES_STATISTICS,
  NURSERIES_STATISTICS,
  NURSERIES_STATISTICS_FAILED,
  NURSERIES_STATISTICS_SUCCESS,
} from './constants'

export interface NurseriesStatisticsState extends Omit<BasicListInitialState, 'meta'> {}

const initialState: NurseriesStatisticsState = {
  data: null,
  error: null,
  isFetching: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case NURSERIES_STATISTICS:
      return { ...state, error: initialState.error, isFetching: true }
    case NURSERIES_STATISTICS_SUCCESS:
      return { ...state, data: payload.data, isFetching: false }
    case NURSERIES_STATISTICS_FAILED:
      return { ...state, error, isFetching: false }
    case CLEAR_NURSERIES_STATISTICS:
      return initialState
    default:
      return state
  }
}
