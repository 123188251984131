import { flatten, nest } from 'utils/flatnest'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withPaginationUtils } from 'services/utils/pagination'
import { withSortingUtils } from 'services/utils/sorting'
import { withDepositsService } from 'services/legacy/deposits'
import { withRouter } from 'services/router'

import ListView from './ListView'
import { getColumns, getTableData } from './ListHelpers'

const DEPOSIT_LIST_GROUPS = {
  read: [
    'deposit',
    'deposit.child',
    'child',
    'deposit.lastInvoice',
    'invoice',
    'deposit.originalDeposit',
  ],
}

const DEFAULT_SORT = {
  sortField: 'date',
  sortOrder: 'desc',
}

class ListContainer extends Component {
  constructor(props) {
    super(props)

    const { location, params } = props
    const { query } = location
    const { childId } = params
    const { search, selectedFilter } = nest(query)

    this.state = {
      childId,
      columns: getColumns(!!childId),
      isChildContext: !!childId,
      search,
      selectedFilter,
    }
  }

  componentDidMount() {
    this.fetch()
    this.fetchStatistics()
  }

  fetch = () => {
    const {
      depositsActions,
      depositsSelectors,
      paginationUtils,
      setLocationQuery,
      sortingUtils,
    } = this.props
    const { childId, search, selectedFilter } = this.state

    const { page, perPage } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    setLocationQuery(flatten({ search, selectedFilter }))

    const criteria = depositsSelectors.getCriteria({
      childId,
      search,
      statisticsType: selectedFilter,
    })

    depositsActions.list({
      params: [{
        criteria,
        groups: DEPOSIT_LIST_GROUPS,
        limit: perPage,
        order: {
          sortField: sortField || DEFAULT_SORT.sortField,
          sortOrder: sortField ? sortOrder : DEFAULT_SORT.sortOrder,
        },
        page,
      }],
    })
  }

  fetchStatistics = () => {
    const { depositsActions, depositsSelectors } = this.props
    const { childId } = this.state

    const criteria = depositsSelectors.getStatisticsCriteria({
      childId,
    })

    depositsActions.getStatistics({
      params: [{
        criteria,
      }],
    })
  }

  handleSortChange = (key) => {
    const { sortingUtils } = this.props
    const { onSortChange } = sortingUtils

    onSortChange(() => this.handlePageChange(1))(key)
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleStatisticsFilterClick = (key) => {
    this.setState((prevValue) => ({
      selectedFilter: prevValue.selectedFilter === key ? null : key,
    }), () => this.handlePageChange(1))
  }

  handleSearchChange = (search) => {
    this.setState({ search }, () => this.handlePageChange(1))
  }

  handleExportClick = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.DEPOSITS_EXPORT)
  }

  render() {
    const {
      deposits,
      errorMessages,
      isListFetching,
      isStatisticsFetching,
      paginationUtils,
      params,
      sortingUtils,
      statistics,
      totalResults,
    } = this.props
    const { columns, isChildContext, selectedFilter } = this.state

    const { getPageCount, page, perPage } = paginationUtils
    const { sortField, sortOrder } = sortingUtils
    const { childId } = params

    const pageCount = getPageCount(totalResults)
    const tableData = getTableData({ deposits, params })

    return (
      <ListView
        childId={childId}
        columns={columns}
        errorMessages={errorMessages}
        isChildContext={isChildContext}
        isListLoading={isListFetching}
        isStatisticsLoading={isStatisticsFetching}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        selectedFilter={selectedFilter}
        sortField={sortField || DEFAULT_SORT.sortField}
        sortOrder={sortField ? sortOrder : DEFAULT_SORT.sortOrder}
        statistics={statistics}
        tableData={tableData}
        totalResults={totalResults}
        onExportClick={this.handleExportClick}
        onPageChange={this.handlePageChange}
        onSearchChange={this.handleSearchChange}
        onSortChange={this.handleSortChange}
        onStatisticsFilterClick={this.handleStatisticsFilterClick}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  depositsListState,
  depositsSelectors,
  depositsStatisticsState,
}) => ({
  deposits: depositsSelectors.getListDataSelector(state),
  errorMessages: appSelectors.getErrorMessages(depositsListState, depositsStatisticsState),
  isListFetching: appSelectors.getIsFetching(depositsListState),
  isStatisticsFetching: appSelectors.getIsFetching(depositsStatisticsState),
  statistics: depositsSelectors.getStatisticsSelector(state),
  totalResults: appSelectors.getTotalResults(depositsListState),
})

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withPaginationUtils,
  withSortingUtils,
  withDepositsService,
  connect(mapState),
)

export default enhance(ListContainer)
