import React from 'react'

import { Box } from 'components'

import tabletImage from 'assets/images/tablet.png'

import {
  StyledBox,
  StyledContainer,
  StyledContentContainer,
  StyledFooter,
  StyledImage,
  StyledLeftItemContainer,
  StyledRightItemContainer,
} from './BoxWrapperStyled'

const BoxWrapper = ({ children, footer }) => (
  <StyledContainer>
    <StyledLeftItemContainer>
      <StyledContentContainer>
        <StyledBox>
          <Box
            white
            withPadding
            withShadow
          >
            {children}
          </Box>
        </StyledBox>
        {footer && (
          <StyledFooter>
            {footer}
          </StyledFooter>
        )}
      </StyledContentContainer>
    </StyledLeftItemContainer>
    <StyledRightItemContainer>
      <StyledImage src={tabletImage} />
    </StyledRightItemContainer>
  </StyledContainer>
)

export default BoxWrapper
