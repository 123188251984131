import _ from 'lodash'

import React, { useEffect, useState } from 'react'
import { Field, FieldArray, reduxForm } from 'redux-form'

import { noop } from 'constants/global'
import { FRAMEWORK_TYPE_MONTESSORI } from 'services/legacy/frameworks/constants'

import { generateRoute } from 'utils/routing'

import { Button, Form, LastSaveDateTime, StickyFooter, Typography } from 'components'

import i18n from 'translations'

import { StyledFormRow } from './ObservationNextStepsFormStyled'

export const OBSERVATION_NEXT_STEPS_FORM = 'ObservationNextStepsForm'

const NextStepRow = ({
  formValues = {},
  index,
  item,
  isNurseryMontessori,
  frameworksList,
  montessoriCategories,
  onAutoSave,
  onFrameworkChange,
  onMontessoriFrameworkChange,
  onGetFrameworkAreas,
  onRemoveFramework,
}) => {
  const itemFormValues = formValues?.nextSteps?.[index] || {}

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [nextStepId, setNextStepId] = useState(itemFormValues?.id || null)
  const [oldFramework, setOldFramework] = useState(itemFormValues?.framework)

  const framework = itemFormValues?.framework
  const isFrameworkMontessori = FRAMEWORK_TYPE_MONTESSORI === framework?.value

  const areas = isFrameworkMontessori
    ? montessoriCategories
    : onGetFrameworkAreas(framework)

  const isSelectedMontessoriValueInForm = !!_.find(formValues.nextSteps, ({ framework: frameworkValue }) => (
    frameworkValue?.value === FRAMEWORK_TYPE_MONTESSORI
  ))

  const finalFrameworksList = isNurseryMontessori && (!isSelectedMontessoriValueInForm || isFrameworkMontessori)
    ? [
      ...frameworksList,
      {
        label: i18n.t('module:Learning:Observations:ObservationNextSteps:montessori'),
        value: FRAMEWORK_TYPE_MONTESSORI,
      },
    ]
    : frameworksList

  useEffect(() => {
    setNextStepId(itemFormValues.id)
  }, [itemFormValues.id])

  useEffect(() => {
    if (!oldFramework && itemFormValues.framework) {
      setOldFramework(itemFormValues.framework)
    }
  }, [oldFramework, itemFormValues.framework])

  const handleSuccess = (id) => {
    setIsSubmitting(false)

    if (id) {
      setNextStepId(id)
    }
  }

  const handleAutoSave = () => {
    setIsSubmitting(true)

    if (isFrameworkMontessori) {
      return onMontessoriFrameworkChange(index, handleSuccess)
    }

    return onAutoSave(nextStepId, index, handleSuccess)
  }

  const handleRemove = () => {
    setIsSubmitting(true)
    onRemoveFramework(nextStepId, index, isFrameworkMontessori, () => setIsSubmitting(false))
  }

  const handleFrameworkChange = (newFramework) => {
    setIsSubmitting(true)
    onFrameworkChange({
      index,
      newFramework,
      nextStepId,
      oldFramework,
      onSuccess: () => {
        setIsSubmitting(false)
        setOldFramework(newFramework)
      },
    })
  }

  return (
    <StyledFormRow>
      <Typography fontSize={21} bold>
        {index + 1}
      </Typography>
      <div>
        <Form.Row multipleFieldsInARow>
          <Form.Row.FlexItem flex="0 0 400px" mobileFull>
            <Field
              clearable={false}
              component={Form.Select}
              disabled={isSubmitting}
              label={i18n.t('global:Framework')}
              name={`${item}.framework`}
              options={finalFrameworksList}
              placeholder={i18n.t('global:Framework')}
              onChange={handleFrameworkChange}
            />
          </Form.Row.FlexItem>
          <Form.Row.FlexItem flex="0 0 400px" mobileFull>
            <Field
              component={Form.Select}
              disabled={isSubmitting || !itemFormValues?.framework?.value}
              label={isFrameworkMontessori
                ? i18n.t('module:Learning:Observations:ObservationNextSteps:nextActivities')
                : i18n.t('module:Learning:Observations:ObservationNextSteps:area')}
              name={`${item}.frameworkAreas`}
              options={areas}
              placeholder={isFrameworkMontessori
                ? i18n.t('module:Learning:Observations:ObservationNextSteps:nextActivities')
                : i18n.t('module:Learning:Observations:ObservationNextSteps:area')}
              multi
              onChange={handleAutoSave}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        {!isFrameworkMontessori && (
          <Form.Row
            label={i18n.t('module:Learning:Observations:ObservationNextSteps:nextStepsDetails')}
            verticalLabel
          >
            <Form.Row.FlexItem flex="1" mobileFull>
              <Field
                component={Form.TextAreaField}
                disabled={isSubmitting}
                maxCharacters={5000}
                name={`${item}.comments`}
                placeholder={i18n.t('module:Learning:Observations:ObservationNextSteps:detailsPlaceholder')}
                onBlur={handleAutoSave}
              />
            </Form.Row.FlexItem>
          </Form.Row>
        )}
      </div>
      <Button
        hierarchy="tertiary"
        icon="trash"
        size="medium"
        negativeMargins
        onClick={handleRemove}
      />
    </StyledFormRow>
  )
}

const renderRows = ({
  fields,
  formValues,
  ...rest
}) => (
  <React.Fragment>
    <div>
      {fields.map((item, index) => (
        <NextStepRow
          formValues={formValues}
          index={index}
          item={item}
          {...rest}
        />
      ))}
    </div>
    <Button.ActionButton
      label={i18n.t('module:Learning:Observations:ObservationNextSteps:addNextStep')}
      onClick={() => fields.push({})}
    />
  </React.Fragment>
)

const ObservationNextStepsForm = ({
  formValues,
  frameworksList,
  handleSubmit,
  isNurseryMontessori,
  lastUpdatedAt,
  montessoriCategories,
  observationId,
  onAutoSave,
  onFrameworkChange,
  onGetFrameworkAreas,
  onMontessoriFrameworkChange,
  onRemoveFramework,
}) => (
  <Form onSubmit={handleSubmit(noop)}>
    <FieldArray
      component={renderRows}
      name="nextSteps"
      props={{
        formValues,
        frameworksList,
        isNurseryMontessori,
        montessoriCategories,
        onAutoSave,
        onFrameworkChange,
        onGetFrameworkAreas,
        onMontessoriFrameworkChange,
        onRemoveFramework,
      }}
    />
    <StickyFooter>
      <Button
        hierarchy="tertiary"
        label={i18n.t('global:Cancel')}
        to={generateRoute('LEARNING.OBSERVATIONS.EDIT', { observationId })}
      />
      {lastUpdatedAt && (
        <LastSaveDateTime
          lastSaveDateTime={lastUpdatedAt}
        />
      )}
      <StickyFooter.Flex />
      <Button
        hierarchy="primary"
        label={i18n.t('global:Save')}
        to={generateRoute('LEARNING.OBSERVATIONS.EDIT', { observationId })}
      />
    </StickyFooter>
  </Form>
)

export default reduxForm({ form: OBSERVATION_NEXT_STEPS_FORM })(ObservationNextStepsForm)
