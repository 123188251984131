import _ from 'lodash'

import React from 'react'

import { EDITING_STAFF_ROTA_OPTIONS } from 'services/nurseries/constants'

import { Spinner } from 'components'
import InlineEditableField from 'module/Management/components/InlineEditableField'

import i18n from 'translations'

import EditingStaffRotaForm, { EDITING_STAFF_ROTA_FORM } from './EditingStaffRotaForm'

interface EditingStaffRotaProps {
  isFetching?: boolean
  onSubmit?: () => void
  value?: boolean
}

const EditingStaffRota: React.FC<EditingStaffRotaProps> = ({ isFetching, onSubmit, value }) => {
  const initialValues = { editingStaffRota: value }

  const renderEditingStaffRota = () => _.find(EDITING_STAFF_ROTA_OPTIONS, ['value', value])?.label

  return (
    <InlineEditableField
      description={isFetching ? (
        <Spinner
          position="flex-start"
        />
      ) : renderEditingStaffRota()}
      editableComponent={(callback, isSubmitting) => (
        <EditingStaffRotaForm
          initialValues={initialValues}
          isSubmitting={isSubmitting}
          options={EDITING_STAFF_ROTA_OPTIONS}
          onSubmit={callback}
        />
      )}
      formName={EDITING_STAFF_ROTA_FORM}
      title={i18n.t('module:Management:General:RotaAndOccupancy:EditingStaffRota:title')}
      value={value}
      enableHandleFailed
      onSubmit={onSubmit}
    />
  )
}

export default EditingStaffRota

