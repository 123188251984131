import { createSelector } from 'reselect'
import moment from 'moment'

import { RootState } from 'core/reducers'
import {
  RegularBookingsListFilters,
} from 'module/Finance/RegularBookings/RegularBookingsList/RegularBookingsListContainer'
import { DEFAULT_DATE_FORMAT } from 'constants/date'

export const getChildBookingListState = (state: RootState) => state.childBooking.list

export const getChildBookingListDataState = createSelector(
  [getChildBookingListState],
  (state) => state.data,
)

interface GetCriteriaProps {
  childId?: number
  filters?: RegularBookingsListFilters
  search?: string
}

export const getCriteria = ({ childId, filters, search }: GetCriteriaProps) => {
  const { endAge, fundingType, planActiveOn, room, startAge } = filters || {}

  const criteria = [{
    field: 'archived',
    value: false,
  }] as any

  if (planActiveOn) {
    criteria.push(
      {
        field: 'bookingDate',
        value: moment(planActiveOn).format(DEFAULT_DATE_FORMAT),
      },
    )
  }

  if (childId) {
    criteria.push({
      field: 'child',
      value: childId,
    })
  }

  if (startAge && !childId) {
    criteria.push({
      field: 'child.birthDate[before]',
      value: moment()
        .add(-startAge, 'months')
        .format(DEFAULT_DATE_FORMAT),
    })
  }

  if (endAge && !childId) {
    criteria.push({
      field: 'child.birthDate[after]',
      value: moment()
        .add(-endAge, 'months')
        .format(DEFAULT_DATE_FORMAT),
    })
  }

  if (room && planActiveOn && !childId) {
    criteria.push({
      field: 'child.childMoves',
      value: room,
    })
  }

  if (fundingType) {
    criteria.push({
      field: 'childProducts.product',
      value: fundingType,
    })
  }

  if (search) {
    criteria.push({
      field: 'concatenable',
      value: search,
    })
  }

  return criteria
}
