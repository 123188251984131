import React from 'react'
import { compose } from 'recompose'

import { withModalService } from 'services/utils/modal'

import EnterStaffRegisterModalView from './EnterStaffRegisterModalView'

const EnterStaffRegisterModalContainer = ({ onCancelClick, onContinueClick }) => (
  <EnterStaffRegisterModalView
    onCancelClick={onCancelClick}
    onCloseClick={onCancelClick}
    onSubmit={onContinueClick}
  />
)

const enhance = compose(
  withModalService,
)

export default enhance(EnterStaffRegisterModalContainer)
