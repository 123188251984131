import React from 'react'

import { StyledFlex } from './StickyFooterStyled'

/**
 * @desc Use this component to separate the left part from the right
 */
export const StickyFooterFlex = () => (
  <StyledFlex />
)

export default StickyFooterFlex
