import React from 'react'

import { generateRoute } from 'utils/routing'

import { Callout, DefinitionList, Page, Section, Spinner } from 'components'

const ManagementFundingItemView = ({
  errorMessages,
  isLoading,
  previewData,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    const {
      deductedFromInvoice,
      excludedPeriods,
      feesAllocation,
      fundingCalculation,
      fundingType,
      hourlyRate,
      hoursPerWeek,
      id,
      invoiceLineItemDeduction,
      invoiceLineItemDisplay,
      label,
      maxHoursPerDay,
      period,
      total,
      totalHours,
    } = previewData || {}

    const actions = (
      <Section.Actions
        tertiary={{
          to: generateRoute('MANAGEMENT.FINANCE_SETTINGS.FUNDING.EDIT', { nurseryFundingId: id }),
        }}
      />
    )

    return (
      <Section
        actions={actions}
        title={fundingType}
      >
        <DefinitionList margin="0 0 30px 0" vertical>
          <DefinitionList.Item label="Unique label" value={label} />
          <DefinitionList.Item label="Period" value={period} />
          <DefinitionList.Item label="Exclusion periods" value={excludedPeriods} />
          <DefinitionList.Item label="Hourly rate" value={hourlyRate} />
          <DefinitionList.Item label="Total hours" value={totalHours} />
          {hoursPerWeek && <DefinitionList.Item label="Hours per week" value={hoursPerWeek} />}
          {maxHoursPerDay && <DefinitionList.Item label="Max hours per day" value={maxHoursPerDay} />}
          <DefinitionList.Item label="Total funding value" value={total} />
          <DefinitionList.Item label="Does this funding deduct from invoices" value={deductedFromInvoice} />
          <DefinitionList.Item label="Funding allocation fees" value={feesAllocation} />
          <DefinitionList.Item label="Funding calculation" value={fundingCalculation} />
          <DefinitionList.Item label="Funding deduction on invoices" value={invoiceLineItemDeduction} />
          <DefinitionList.Item label="Funding line item on invoices" value={invoiceLineItemDisplay} />
        </DefinitionList>
      </Section>
    )
  }

  return (
    <Page isLoading={isLoading}>
      <Page.Content>
        <Callout content={errorMessages} error />
        {renderContent()}
      </Page.Content>
    </Page>
  )
}

export default ManagementFundingItemView
