import { mergeData } from 'utils/data'

import { BasicListInitialState } from 'utils/reducer'

import { CLEAR_NURSERIES, LIST_NURSERIES, LIST_NURSERIES_FAILED, LIST_NURSERIES_SUCCESS } from './constants'

export interface NurseriesListState extends BasicListInitialState {}

const initialState: NurseriesListState = {
  data: null,
  error: null,
  isFetching: false,
  meta: {},
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case LIST_NURSERIES:
      return { ...state, error: initialState.error, isFetching: true }
    case LIST_NURSERIES_SUCCESS: {
      const { recurrency, response: { data, meta } } = payload

      return {
        ...state,
        data: mergeData(state.data || [], data, recurrency),
        isFetching: false,
        meta,
      }
    }
    case LIST_NURSERIES_FAILED:
      return { ...state, error, isFetching: false }

    case CLEAR_NURSERIES:
      return initialState

    default:
      return state
  }
}
