export const FORMATIVE_REPORT_GROUPS_BASIC = {
  read: [
    'child',
    'carerChildRelations',
    'childSnapshot',
    'formativeReport.author',
    'formativeReport.child',
    'formativeReport.childSnapshot',
    'nurseryClass',
    'user',
    'user.details',
  ],
}

export const NURSERY_SETTINGS_GROUP = {
  read: [
    'assessmentPeriodSettings',
    'nursery.settings',
    'nurserySettings.learning',
    'nurseryLearningSettings',
  ],
}

export const TABS_TYPES = {
  CHILD_OVERVIEW: 'CHILD_OVERVIEW',
  COMMENTS: 'COMMENTS',
  FRAMEWORK: 'FRAMEWORK',
  MONTESSORI: 'MONTESSORI',
}

export const NON_FRAMEWORK_TABS = [
  TABS_TYPES.CHILD_OVERVIEW,
  TABS_TYPES.COMMENTS,
  TABS_TYPES.MONTESSORI,
]

export const TABS_GROUPS = {
  [TABS_TYPES.CHILD_OVERVIEW]: {},
  [TABS_TYPES.FRAMEWORK]: {
    read: [
      'formativeReport.attainmentLevels',
      'formativeReport.comments',
      'formativeReportComment',
      'frameworkGroup',
      'frameworkArea',
      'formativeReportComment.frameworkArea',
      'formativeReportAttainmentLevel',
      'formativeReportAttainmentLevel.frameworkGroup',
      'formativeReportAttainmentLevel.progressLevel',
      'formativeReport',
      'formativeReport.period',
      'formativeReport.period.type',
      'formativeReportAgeBand',
    ],
  },
  [TABS_TYPES.COMMENTS]: {},
  [TABS_TYPES.MONTESSORI]: {},
}

export const FORMATIVE_REPORT_ITEMS_GROUPS = {
  read: [
    'frameworkItem',
    'frameworkItem.category',
    'frameworkCategory',
    'frameworkCategory.group',
    'frameworkGroup',
    'frameworkGroup.area',
    'frameworkArea',
    'frameworkProgressLevel',
    'formativeReportItem',
    'formativeReportItem.frameworkItem',
    'formativeReportItem.progressLevel',
  ],
}

export const FORMATIVE_REPORT_MONTESSORI_ITEMS_GROUPS = {
  read: [
    'formativeReportMontessoriItem.activity',
    'montessoriActivity',
    'montessoriActivity.subcategory',
    'montessoriSubcategory',
    'montessoriSubcategory.category',
    'montessoriCategory',
  ],
}
