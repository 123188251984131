import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { RootState } from 'core/reducers'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as closureDayTypesSelectors from './selectors'
import { ClosureDayTypesListState, ClosureDayTypesSingleState } from './models'

export interface withClosureDayTypesServiceProps {
  closureDayTypesActions: typeof actions
  closureDayTypesListState: ClosureDayTypesListState
  closureDayTypesSelectors: typeof closureDayTypesSelectors
  closureDayTypesSingleState: ClosureDayTypesSingleState
}

const withClosureDayTypesService = <P extends withClosureDayTypesServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ closureDayTypes }: RootState) => ({ closureDayTypes })

  const mapDispatch = injectActions('closureDayTypesActions', actions)

  const Component = ({ closureDayTypes, closureDayTypesActions, ...others }) => (
    <WrappedComponent
      closureDayTypesActions={closureDayTypesActions}
      closureDayTypesListState={closureDayTypes.list}
      closureDayTypesSelectors={closureDayTypesSelectors}
      closureDayTypesSingleState={closureDayTypes.single}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withClosureDayTypesService
