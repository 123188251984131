import _ from 'lodash'

import React from 'react'
import { Field } from 'redux-form'

import { CircleIcon, FieldError, Form, Radio, Tooltip } from 'components'

import {
  StyledContainer,
  StyledExtraFieldContainer,
  StyledExtraFieldItemContainer,
  StyledExtraFieldItemContentContainer,
  StyledItemContainer,
} from './FundingFeesFieldStyled'

const FundingFeesField = ({
  disabled,
  input,
  isDeficitChanged,
  meta: { error, touched },
  options,
}) => {
  const renderOptions = (option, index) => (
    <StyledItemContainer key={option.value}>
      <div>
        <Radio
          {...input}
          checked={option.value === input.value}
          disabled={disabled}
          label={option.label}
          tooltip={option.tooltip}
          value={option.value}
        />
      </div>
      {(0 === index || 2 === index) && option.value === input.value && (
        <StyledExtraFieldContainer>
          <StyledExtraFieldItemContainer>
            <StyledExtraFieldItemContentContainer>
              <Field
                component={Form.Checkbox}
                disabled={disabled}
                label="Charge funding deficit on invoice"
                name="settings.deficitCharged"
              />
            </StyledExtraFieldItemContentContainer>
            <Tooltip
              placement="right"
              title={'Checking this box will calculate the difference between full priced '.concat(
                'fees and fees with funded hours deducted, and then automatically add the difference as a line item',
              )}
            >
              <CircleIcon
                cursor="pointer"
                icon="info"
                iconSize={16}
                size={24}
              />
            </Tooltip>
          </StyledExtraFieldItemContainer>
          <StyledExtraFieldItemContainer>
            <StyledExtraFieldItemContentContainer>
              <Field
                component={Form.TextField}
                disabled={!isDeficitChanged || disabled}
                name="settings.deficitLineItemName"
                placeholder="Invoice line item name for deficit"
              />
            </StyledExtraFieldItemContentContainer>
            <Tooltip
              placement="right"
              title={'This is the line item description for the funding deficit charge that is displayed '.concat(
                'on invoices for this funding type',
              )}
            >
              <CircleIcon
                cursor="pointer"
                icon="info"
                iconSize={16}
                size={24}
              />
            </Tooltip>
          </StyledExtraFieldItemContainer>
        </StyledExtraFieldContainer>
      )}
    </StyledItemContainer>
  )

  return (
    <StyledContainer>
      {_.map(options, renderOptions)}
      {touched && <FieldError error={error} />}
    </StyledContainer>
  )
}

export default FundingFeesField
