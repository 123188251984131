import Field from './Field'
import InlineEdit from './InlineEdit'
import InlineEditActions from './InlineEditActions'
import FieldTopLabel from './FieldTopLabel'

Field.InlineEdit = InlineEdit
Field.InlineEdit.Actions = InlineEditActions
Field.TopLabel = FieldTopLabel

export default Field
