import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { ZINDEX_ORDER } from 'constants/layout'

interface StyledBoxProps {
  $margin?: string
  $zIndex?: number
}

export const StyledBox = styled.div<StyledBoxProps>`
  border-radius: 14px;
  box-shadow: 0 0 20px 0 rgba(89, 89, 89, .5);
  background: ${NEUTRAL_COLOURS.WHITE};
  min-height: 35px;
  
  ${({ $zIndex }) => `
    position: relative;
    z-index: ${$zIndex || ZINDEX_ORDER.POPOVER};
  `}
  
  ${({ $margin }) => $margin && `
    margin: ${$margin};
  `}
`

interface StyledButtonProps {
  $buttonFull?: boolean
}

export const StyledButton = styled.div<StyledButtonProps>`
  ${({ $buttonFull }) => $buttonFull && `
    @media(max-width: 780px) {
      width: 100%;
    } 
  `}
`

export const StyledStorybookWrapper = styled.div`
  height: 500px;
  width: 500px;
  overflow: scroll;
`

export const StyledStorybookContainer = styled.div`
  width: 1000px;
  height: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
`
