import { mergeData } from 'utils/data'

import {
  CLEAR_LIST_OBSERVATIONS,
  EXPORT_OBSERVATIONS_REPORT,
  EXPORT_OBSERVATIONS_REPORT_FAILED,
  EXPORT_OBSERVATIONS_REPORT_SUCCESS,
  LIST_OBSERVATIONS,
  LIST_OBSERVATIONS_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isExporting: false,
  isFetching: false,
  meta: null,
}

export default (state = initialState, { error, mergeResult, payload, type }) => {
  switch (type) {
    case LIST_OBSERVATIONS:
      return { ...state, error: initialState.error, isFetching: true }
    case LIST_OBSERVATIONS_SUCCESS: {
      return {
        data: mergeData(state.data, payload.data, mergeResult),
        error: null,
        isFetching: false,
        meta: payload.meta,
      }
    }
    case EXPORT_OBSERVATIONS_REPORT:
      return {
        ...state,
        error: null,
        isExporting: true,
      }
    case EXPORT_OBSERVATIONS_REPORT_SUCCESS:
      return {
        ...state,
        error: null,
        isExporting: false,
      }
    case EXPORT_OBSERVATIONS_REPORT_FAILED:
      return {
        ...state,
        error,
        isExporting: false,
      }
    case CLEAR_LIST_OBSERVATIONS:
      return initialState
    default:
      return state
  }
}
