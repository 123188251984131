import React from 'react'

import { STATUS_TYPES } from 'services/legacy/invoices/constants'

import { Currency } from 'components'

import i18n from 'translations'

import { StyledInCreditAccount } from './AccountInCreditStyled'

const AccountInCredit = ({ status, valueAfterInvoice }) => {
  if (
    status === STATUS_TYPES.DRAFT
    || status === STATUS_TYPES.CANCELLED
    || !valueAfterInvoice
    || 0 >= valueAfterInvoice
  ) {
    return null
  }

  return (
    <StyledInCreditAccount>
      {i18n.t('components:Finance:AccountInCredit:label')}
      &nbsp;
      <Currency value={valueAfterInvoice} />
    </StyledInCreditAccount>
  )
}

export default AccountInCredit
