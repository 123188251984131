import {
  CLEAR_NURSERY_DISCOUNT,
  CREATE_NURSERY_DISCOUNT,
  CREATE_NURSERY_DISCOUNT_FAILED,
  CREATE_NURSERY_DISCOUNT_SUCCESS,
  GET_NURSERY_DISCOUNT,
  GET_NURSERY_DISCOUNT_FAILED,
  GET_NURSERY_DISCOUNT_SUCCESS,
  UPDATE_NURSERY_DISCOUNT,
  UPDATE_NURSERY_DISCOUNT_FAILED,
  UPDATE_NURSERY_DISCOUNT_SUCCESS,
} from './constants'

export const get = (id, params) => ({ nurseryDiscountsApiClient }) => (dispatch) => {
  dispatch({
    type: GET_NURSERY_DISCOUNT,
  })

  nurseryDiscountsApiClient.get(id, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: GET_NURSERY_DISCOUNT_SUCCESS,
      })
    },
    (error) => {
      dispatch({
        error,
        type: GET_NURSERY_DISCOUNT_FAILED,
      })
    },
  )
}

export const create = (payload, params, onSuccess) => ({ nurseryDiscountsApiClient }) => (dispatch) => {
  dispatch({
    params,
    payload,
    type: CREATE_NURSERY_DISCOUNT,
  })

  return nurseryDiscountsApiClient.create(payload, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: CREATE_NURSERY_DISCOUNT_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: CREATE_NURSERY_DISCOUNT_FAILED,
      })
    },
  )
}

export const update = (id, payload, params, onSuccess) => ({ nurseryDiscountsApiClient }) => (dispatch) => {
  dispatch({
    id,
    params,
    payload,
    type: UPDATE_NURSERY_DISCOUNT,
  })

  nurseryDiscountsApiClient.update(id, payload, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: UPDATE_NURSERY_DISCOUNT_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: UPDATE_NURSERY_DISCOUNT_FAILED,
      })
    },
  )
}

export const clear = () => ({
  type: CLEAR_NURSERY_DISCOUNT,
})
