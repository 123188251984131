import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import selectors from './selectors'

const withChildBulkFundingService = (WrappedComponent) => {
  const mapState = ({ childBulkFunding }) => ({ childBulkFunding })

  const mapDispatch = injectActions('childBulkFundingActions', actions)

  const Component = ({ childBulkFunding, childBulkFundingActions, ...other }) => (
    <WrappedComponent
      childBulkFundingActions={childBulkFundingActions}
      childBulkFundingListState={childBulkFunding.list}
      childBulkFundingSelectors={selectors}
      {...other}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withChildBulkFundingService
