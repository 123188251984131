import i18n from 'translations'
import nurseriesConstants from 'services/nurseries/constants'

const { INVOICE_LEVEL_DETAIL_DISPLAY } = nurseriesConstants

/* eslint sort-keys:0 */
export const sessionCalculationOptions = [
  {
    label: i18n.t('components:Settings:InvoiceSettings:SessionCalculation.ActualSessions:title'),
    tooltip: i18n.t('components:Settings:InvoiceSettings:SessionCalculation.ActualSessions:tooltip'),
    value: 'ACTUAL_SESSIONS',
  },
  {
    label: i18n.t('components:Settings:InvoiceSettings:SessionCalculation.AverageCost:title'),
    tooltip: i18n.t('components:Settings:InvoiceSettings:SessionCalculation.AverageCost:tooltip'),
    value: 'AVERAGE_COST',
  },
]

export const extraItemsCalculationOptions = [
  {
    label: i18n.t('components:Settings:InvoiceSettings:ExtraItems.ActualCost:title'),
    tooltip: i18n.t('components:Settings:InvoiceSettings:ExtraItems.ActualCost:tooltip'),
    value: 'ACTUAL_SESSIONS',
  },
  {
    label: i18n.t('components:Settings:InvoiceSettings:ExtraItems.AverageCost:title'),
    tooltip: i18n.t('components:Settings:InvoiceSettings:ExtraItems.AverageCost:tooltip'),
    value: 'AVERAGE_COST',
  },
]

export const invoiceDetailOptions = [
  {
    label: i18n.t('components:Settings:InvoiceSettings:InvoiceDetail.GroupWithoutCost:title'),
    tooltip: i18n.t('components:Settings:InvoiceSettings:InvoiceDetail.GroupWithoutCost:tooltip'),
    value: INVOICE_LEVEL_DETAIL_DISPLAY.GROUPED_ITEM_TYPES_WITHOUT_COST_BREAKDOWN,
  },
  {
    label: i18n.t('components:Settings:InvoiceSettings:InvoiceDetail.WithCost:title'),
    tooltip: i18n.t('components:Settings:InvoiceSettings:InvoiceDetail.WithCost:tooltip'),
    value: INVOICE_LEVEL_DETAIL_DISPLAY.ITEM_TYPES_WITH_COST_BREAKDOWN,
  },
]
