import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { withAppService } from 'services/app'
import { withPaginationUtils } from 'services/utils/pagination'
import { withOrganizationService } from 'services/organization'
import { withNurseriesService } from 'services/nurseries'
import { getAuthOrganization } from 'services/security/selectors'

import SettingsInvoicesView from './SettingsInvoicesView'

const ORGANIZATION_INVOICE_SETTINGS_GROUPS = {
  read: [
    'organization.organizationSettings',
    'organizationSettings.invoice',
    'organizationInvoiceSettings',
    'organizationInvoiceSettings.numbers',
    'invoiceNumbers',
  ],
}

class SettingsInvoicesContainer extends Component {
  componentDidMount() {
    const { authOrganization, organizationActions } = this.props

    const apiParams = { groups: ORGANIZATION_INVOICE_SETTINGS_GROUPS }

    organizationActions.get(authOrganization.id, { params: apiParams })
    this.fetch()
  }

  UNSAFE_componentWillMount() {
    const { nurseriesActions, organizationActions } = this.props

    nurseriesActions.clear()
    organizationActions.clearSingle()
  }

  fetch = () => {
    const { nurseriesActions, paginationUtils: { page } } = this.props

    nurseriesActions.list({
      params: { page },
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  render() {
    const {
      globalType,
      globalTypeDescription,
      isFetching,
      nurseriesListState,
      organizationConstants,
      paginationUtils,
      totalResults,
    } = this.props

    const { data, isEmpty } = nurseriesListState
    const { GLOBAL_SETTINGS } = organizationConstants
    const { getPageCount, page, perPage } = paginationUtils

    const pageCount = getPageCount(totalResults)
    const hideNurseries = globalType === GLOBAL_SETTINGS.APPLY_NON_CUSTOMIZABLE
    const hideGlobalSettings = globalType === GLOBAL_SETTINGS.DO_NOT_APPLY

    return (
      <SettingsInvoicesView
        globalTypeDescription={globalTypeDescription}
        hideGlobalSettings={hideGlobalSettings}
        hideNurseries={hideNurseries}
        isEmpty={isEmpty}
        isLoading={isFetching}
        nurseries={data}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        totalResults={totalResults}
        onPageChange={this.handlePageChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseriesListState,
  organizationSelectors,
  organizationSingleState,
}) => ({
  authOrganization: getAuthOrganization(state),
  globalType: organizationSelectors.getGlobalType(state),
  globalTypeDescription: organizationSelectors.getGlobalTypeDescription(state),
  isFetching: appSelectors.getIsFetching(organizationSingleState, nurseriesListState),
  totalResults: appSelectors.getTotalResults(nurseriesListState),
})

const enhance = compose(
  withAppService,
  withNurseriesService,
  withOrganizationService,
  withPaginationUtils,
  connect(mapState),
)

export default enhance(SettingsInvoicesContainer)
