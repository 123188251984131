import { ROLES } from 'constants/security'

const OrganisationsWrapper = ({ children }) => children

OrganisationsWrapper.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
  ],
}

export default OrganisationsWrapper
