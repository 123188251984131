import React, { PropsWithChildren } from 'react'

import NumberedContentItem from './NumberedContentItem'
import { StyledWrapper } from './NumberedContentStyled'

interface NumberedContentRoot extends React.FC<PropsWithChildren<any>> {
  Item?: typeof NumberedContentItem
}

const NumberedContent: NumberedContentRoot = ({ children }) => (
  <StyledWrapper>
    {children}
  </StyledWrapper>
)

export default NumberedContent
