import styled from 'styled-components'

import layout from 'constants/layout'
import { NEUTRAL_COLOURS } from 'constants/colors'

interface StyledLegendProps {
  $noMarginBottom?: boolean
}

export const StyledLegend = styled.div<StyledLegendProps>`
  background: #F6F6F6;
  border-radius: ${layout.borderRadius};
  margin-bottom: 10px;
  padding: 12px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  ${({ $noMarginBottom }) => $noMarginBottom && `
    margin-bottom: 0;
  `}
  
  @media(max-width: 920px) {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(3, 33.33%);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
  
  @media(max-width: 600px) {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
`

export const StyledLegendItem = styled.div`
  display: flex;
  margin: 0 14px;
  align-items: center;
  
  @media(max-width: 600px) {
    margin: 0 5px;
  }
`

export const StyledLegendLabel = styled.div`
  margin-left: 7px;
  font-size: 14px;
  font-weight: 600;
  color: #949494;
`

export const StyledLegendTitle = styled(StyledLegendLabel)`
  @media(max-width: 920px) and (min-width: 600px) {
    grid-column-start: 1;
    grid-column-end: 4;
    text-align: center;
  }
`

interface StyledTextProps {
  $background?: string
}

export const StyledText = styled.div<StyledTextProps>`
  text-align: center;
  font-weight: 600;
  color: ${NEUTRAL_COLOURS.WHITE};
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  
  ${({ $background }) => $background && `
    background: ${$background};
  `}
`
