import _ from 'lodash'

import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'
import { REFERENCE_ASYNC_PAGE_TYPES, UPDATE_PROGRESS_EVENT } from 'services/legacy/upload/constants'
import { SUPPORTED_FILE_TYPES } from 'constants/mimetypes'
import { ZINDEX_ORDER } from 'constants/layout'

import { formatBytes } from 'utils/data'
import { generateRoute } from 'utils/routing'
import { usePrevious } from 'utils/hooks'
import { isDocumentMimeType, isPdfMimeType, isVideoMimeType } from 'utils/attachment'
import eventBus from 'utils/eventBus'

import { withChildService } from 'services/legacy/child'
import { withUploadService } from 'services/legacy/upload'
import { withModalService } from 'services/utils/modal'
import { getFileFullName } from 'services/legacy/upload/common/selectors'
import { withObservationsService } from 'services/legacy/observations'
import { withSecurityService } from 'services/security'
import { withDailyDiaryActivitiesService } from 'services/legacy/dailyDiaryActivities'
import { withMembershipsService } from 'services/legacy/memberships'
import { withAppService } from 'services/app'

import { Button, CircleIcon, EmptyState, Hyperlink, Icon, LoadingDots, Popover, Spinner, Typography } from 'components'

import i18n from 'translations'

import {
  StyledBadge,
  StyledButton,
  StyledEmptySpinner,
  StyledFileBox,
  StyledFileDetails,
  StyledFileSectionHeader,
  StyledLink,
  StyledSpinnerWrapper,
  StyledUploadBar,
  StyledUploadBox,
  StyledUploadBoxContent,
  StyledUploadBoxFooter,
  StyledUploadBoxHeader,
  StyledUploadProgress,
} from './UploadRootStyled'

export const OPEN_PREVIEW_PATH = 'openPreview'

const referencePagesStorageHelper = {}

const OBSERVATION_GROUPS = {
  read: [
    'child',
    'childObservation',
    'childObservation.child',
    'observation.childObservations',
  ],
}

const UploadRoot = ({
  childActions,
  dailyDiaryActivitiesActions,
  files,
  isOffline,
  membershipsActions,
  modalActions,
  modalConsts,
  nursery,
  observationsActions,
  referencePagesStorage,
  uploadActions,
  uploadSelectors,
}) => {
  const [isCleared, setIsCleared] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [uploadedData, setUploadedData] = useState({})
  const filesGroupedByReference = uploadSelectors.getFilesGroupedByReference(files)
  const existUploadedFile = _.find(files, ({ canceled, isUploaded }) => canceled || isUploaded)
  const existUploadingFile = _.find(files, ({ uploadingInProgress }) => uploadingInProgress)
  const allFilesAreSuccessfullyUploaded = !_.find(files, ({ canceled, isUploaded }) => (
    !isUploaded && !canceled
  ))

  const handleClearUploadedFiles = () => {
    setIsCleared(true)

    return uploadActions.clearUploadedFiles()
  }

  const beforeUnload = (e) => {
    if (existUploadingFile) {
      e.preventDefault()
      e.returnValue = ''
    }
  }

  useEffect(() => {
    const updateProgress = (e) => {
      setUploadedData((prevValue) => ({
        ...prevValue,
        [e.detail.id]: e.detail.uploadedData,
      }))
    }

    eventBus.on(UPDATE_PROGRESS_EVENT, updateProgress)

    return () => {
      eventBus.remove(UPDATE_PROGRESS_EVENT, updateProgress)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload, false)

    return () => {
      window.removeEventListener('beforeunload', beforeUnload)
    }
  })

  useEffect(() => {
    _.each(filesGroupedByReference, async ({ name: [pageType, pageId] }) => {
      if (!referencePagesStorageHelper?.[pageType]?.[pageId]) {
        if (!referencePagesStorageHelper[pageType]) {
          referencePagesStorageHelper[pageType] = {}
        }

        referencePagesStorageHelper[pageType][pageId] = true

        if (REFERENCE_ASYNC_PAGE_TYPES.OBSERVATION === pageType) {
          let parsedChildNames = []

          observationsActions.get({
            onSuccess: ({ data }) => {
              const { childObservations } = data

              parsedChildNames = _.map(childObservations, ({ child: { displayName } }) => displayName).join(', ')
            },
            onlyData: true,
            params: [pageId, { groups: OBSERVATION_GROUPS }],
          })

          uploadActions.updateReferencePage({
            id: pageId,
            name: `${i18n.t('module:Shell:UploadRoot:types:observationFor')} ${parsedChildNames}`,
            type: REFERENCE_ASYNC_PAGE_TYPES.OBSERVATION,
          })
        }

        if (REFERENCE_ASYNC_PAGE_TYPES.ACTIVITY === pageType) {
          const activity = await dailyDiaryActivitiesActions.getRecord(pageId, { onlyData: true })
          const { data } = activity || {}
          const { name } = data || {}

          uploadActions.updateReferencePage({
            id: pageId,
            name: name
              ? `${i18n.t('module:DailyDiary:Activities:activity')}: ${name}`
              : i18n.t('module:DailyDiary:Activities:activity'),
            type: REFERENCE_ASYNC_PAGE_TYPES.ACTIVITY,
          })
        }

        if (REFERENCE_ASYNC_PAGE_TYPES.MEMBERSHIP === pageType) {
          const membership = await membershipsActions.get(pageId, { onlyData: true })
          const { data: { name } } = membership || {}

          uploadActions.updateReferencePage({
            id: pageId,
            name: `${name} ${i18n.t('global:profile')}`,
            type: REFERENCE_ASYNC_PAGE_TYPES.MEMBERSHIP,
          })
        }

        if (REFERENCE_ASYNC_PAGE_TYPES.CHILD === pageType) {
          const child = await childActions.get({ onlyData: true, params: [pageId] })
          const { data: { name } } = child

          uploadActions.updateReferencePage({
            id: pageId,
            name: `${name} ${i18n.t('global:profile')}`,
            type: REFERENCE_ASYNC_PAGE_TYPES.CHILD,
          })
        }

        if (REFERENCE_ASYNC_PAGE_TYPES.NURSERY === pageType) {
          const { name } = nursery

          uploadActions.updateReferencePage({
            id: pageId,
            name: `${name} ${i18n.t('global:nursery')}`,
            type: REFERENCE_ASYNC_PAGE_TYPES.NURSERY,
          })
        }
      }
    })
  }, [
    childActions,
    dailyDiaryActivitiesActions,
    filesGroupedByReference,
    membershipsActions,
    nursery,
    observationsActions,
    referencePagesStorage,
    uploadActions,
  ])

  const prevState = usePrevious({ allFilesAreSuccessfullyUploaded })
  useEffect(() => {
    if (prevState?.allFilesAreSuccessfullyUploaded && !allFilesAreSuccessfullyUploaded) {
      setIsOpen(true)
    }
  }, [allFilesAreSuccessfullyUploaded, prevState])

  if (!files?.length && !isCleared) {
    return null
  }

  const filesToUpload = _.filter(files, ({ canceled, isUploaded }) => !canceled && !isUploaded)?.length || 0

  const renderBadge = () => {
    const foundFileWithError = _.find(files, ({ errors }) => !!errors?.length)

    if (foundFileWithError) {
      return (
        <CircleIcon
          background={FLAG_COLOURS.ERROR}
          icon="exclamation-mark"
          size={18}
        />
      )
    }

    if (allFilesAreSuccessfullyUploaded) {
      return (
        <CircleIcon
          background={FLAG_COLOURS.SUCCESS}
          icon="check"
          size={18}
        />
      )
    }

    return (
      <CircleIcon
        background={FLAG_COLOURS.WARNING}
        letter={filesToUpload}
        size={18}
      />
    )
  }

  const renderButton = (
    <StyledButton>
      <StyledBadge>
        {renderBadge()}
      </StyledBadge>
      <StyledSpinnerWrapper>
        {!allFilesAreSuccessfullyUploaded && (
          <Spinner light />
        )}
        {allFilesAreSuccessfullyUploaded && (
          <StyledEmptySpinner />
        )}
      </StyledSpinnerWrapper>
    </StyledButton>
  )

  const renderReferenceTitle = (reference) => {
    const [pageType, pageId] = reference

    if (!referencePagesStorage?.[pageType]?.[pageId]) {
      return (
        <LoadingDots />
      )
    }

    return referencePagesStorage[pageType][pageId]
  }

  const getFileIcon = (file) => {
    if (isVideoMimeType(file.type)) {
      return (
        <Icon
          color="#29ABE2"
          height={24}
          icon="video-file"
        />
      )
    }

    if (isPdfMimeType(file.type) || isDocumentMimeType(file.type)) {
      const document = _.find([
        ...SUPPORTED_FILE_TYPES.DOCUMENTS,
        ...SUPPORTED_FILE_TYPES.PDF,
      ], ({ mimeTypes }) => _.includes(mimeTypes, file.type))

      return (
        <Icon
          color={document.color}
          height={24}
          icon={document.icon}
        />
      )
    }

    return (
      <Icon
        color="#4FB150"
        height={24}
        icon="image-file"
      />
    )
  }

  const renderFileDetails = ({ errors, id, inQueue, isUploaded, size }) => {
    if (errors?.length) {
      return (
        <Typography color={FLAG_COLOURS.ERROR}>
          {_.map(errors, (error) => (
            <React.Fragment>
              {error}
              <br />
            </React.Fragment>
          ))}
        </Typography>
      )
    }

    if (inQueue && !isUploaded) {
      return (
        <Typography
          color={NEUTRAL_COLOURS.GRAY}
          fontSize={14}
        >
          {i18n.t('components:MediaPicker:waiting')}
        </Typography>
      )
    }

    if (isUploaded) {
      return (
        <Typography
          color={NEUTRAL_COLOURS.GRAY}
          fontSize={14}
        >
          {i18n.t('module:Shell:UploadRoot:Uploaded')}
        </Typography>
      )
    }

    if (isOffline) {
      return (
        <Typography color={FLAG_COLOURS.ERROR} ellipsis>
          {i18n.t('components:MediaPicker:noInternet')}
        </Typography>
      )
    }

    return (
      <Typography
        color={NEUTRAL_COLOURS.GRAY}
        fontSize={14}
      >
        {`${formatBytes(uploadedData?.[id] || 0)} / ${formatBytes(size)} ${i18n.t('global:uploading')}`}
      </Typography>
    )
  }

  const handleRemoveFileAccepted = (file) => {
    const { id } = file

    uploadActions.markFileToRemove(id)
  }

  const handleRemoveFile = (file) => {
    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('global:Delete'),
      icon: 'trash',
      onConfirm: () => handleRemoveFileAccepted(file),
      text: i18n.t('components:MediaPicker:deleteFile'),
    })
  }

  const renderActionIcon = (file) => {
    if (file.isUploaded) {
      return (
        <Icon
          color={NEUTRAL_COLOURS.SUCCESS}
          height={18}
          icon="confirmed"
        />
      )
    }

    return (
      <Icon
        height={18}
        icon="close"
        onClick={() => handleRemoveFile(file)}
      />
    )
  }

  const generatePathToReferencePage = ([pageType, pageId], withOpenPreview) => {
    let route = null

    if (REFERENCE_ASYNC_PAGE_TYPES.OBSERVATION === pageType) {
      route = generateRoute('LEARNING.OBSERVATIONS.EDIT', { observationId: pageId })
    }

    if (REFERENCE_ASYNC_PAGE_TYPES.MEMBERSHIP === pageType) {
      route = generateRoute('STAFF.PROFILE.FILES', { userId: pageId })
    }

    if (REFERENCE_ASYNC_PAGE_TYPES.CHILD === pageType) {
      route = generateRoute('CHILDREN.CHILD.ABOUT.FILES', { childId: pageId })
    }

    if (REFERENCE_ASYNC_PAGE_TYPES.ACTIVITY === pageType) {
      route = generateRoute('DAILY_DIARY.ACTIVITIES')
    }

    if (REFERENCE_ASYNC_PAGE_TYPES.NURSERY === pageType) {
      route = generateRoute('NURSERY_FILES.LIST')
    }

    if (withOpenPreview) {
      route += `?${OPEN_PREVIEW_PATH}=${pageType},${pageId}`
    }

    return route
  }

  const renderContent = () => {
    if (!filesGroupedByReference?.length) {
      return (
        <EmptyState
          icon="document"
          padding="20px 0"
          text1={(
            <Typography color={NEUTRAL_COLOURS.GRAY} margin="-15px 0 0">
              {i18n.t('module:Shell:UploadRoot:noFilesUploading')}
            </Typography>
          )}
        />
      )
    }

    return _.map(filesGroupedByReference, ({ name, values }, index) => (
      <React.Fragment key={`${_.camelCase(name)}_${index}`}>
        <StyledFileSectionHeader>
          <Typography
            ellipsis
            nowrap
          >
            {renderReferenceTitle(name)}
          </Typography>
          <Hyperlink to={generatePathToReferencePage(name)} primary onClick={() => setIsOpen(false)}>
            {i18n.t('global:View')}
          </Hyperlink>
        </StyledFileSectionHeader>
        {_.map(values, (file) => (
          <StyledLink
            key={file.id}
            to={file.isUploaded && generatePathToReferencePage(name, true)}
            onClick={() => file.isUploaded && setIsOpen(false)}
          >
            <StyledFileBox isUploaded={file.isUploaded}>
              {getFileIcon(file)}
              <StyledFileDetails>
                <Typography ellipsis>
                  {getFileFullName(file)}
                </Typography>
                <StyledUploadBar>
                  <StyledUploadProgress
                    // eslint-disable-next-line no-unsafe-optional-chaining
                    percentage={file.isUploaded ? 100 : (uploadedData?.[file.id] * 100) / file.size}
                  />
                </StyledUploadBar>
                {renderFileDetails(file)}
              </StyledFileDetails>
              {renderActionIcon(file)}
            </StyledFileBox>
          </StyledLink>
        ))}
      </React.Fragment>
    ))
  }

  return (
    <Popover
      button={renderButton}
      isOpen={isOpen}
      placement="bottom-end"
      zIndex={ZINDEX_ORDER.UPLOAD_POPOVER}
      onClick={() => setIsOpen((oldValue) => !oldValue)}
      onOutsideClick={() => setIsOpen(false)}
    >
      <StyledUploadBox>
        <StyledUploadBoxHeader>
          <Typography variant="h6">
            {`${i18n.t('module:Shell:UploadRoot:filesUploading')}`}
            {` (${filesToUpload || 0})`}
          </Typography>
        </StyledUploadBoxHeader>
        <StyledUploadBoxContent>
          {renderContent()}
        </StyledUploadBoxContent>
        <StyledUploadBoxFooter>
          <Button
            disabled={!existUploadedFile}
            hierarchy="secondary"
            label={_.upperFirst(i18n.t('global:clear'))}
            size="small"
            negativeMargins
            onClick={handleClearUploadedFiles}
          />
        </StyledUploadBoxFooter>
      </StyledUploadBox>
    </Popover>
  )
}

const mapState = (state, { appSelectors, securitySelectors, uploadSelectors }) => ({
  files: uploadSelectors.getFiles(state),
  isOffline: appSelectors.getAppIsOffline(state),
  nursery: securitySelectors.getAuthNursery(state),
  referencePagesStorage: uploadSelectors.getReferencePagesStorage(state),
})

const enhance = compose(
  withAppService,
  withChildService,
  withModalService,
  withUploadService,
  withSecurityService,
  withObservationsService,
  withDailyDiaryActivitiesService,
  withMembershipsService,
  connect(mapState),
)

export default enhance(UploadRoot)
