import _ from 'lodash'

import React from 'react'

import { ENQUIRY_STATUS_CODES } from 'services/legacy/enquiries/constants'

import { generateRoute } from 'utils/routing'

import { Button, Currency, DateString, Hyperlink } from 'components'

import i18n from 'translations'

import { StyledNewTag } from './ListStyled'

const getViewingStatus = (showAroundCount, showAroundStatus) => {
  if (!showAroundCount) {
    return i18n.t('module:Enquiries:List:Table:noViewingCountText')
  }

  if (1 === showAroundCount) {
    return showAroundStatus
  }

  return showAroundCount
}

export const getEnquiries = (data) => _.map(data, ({
  childName,
  contactName,
  data: { child },
  enquiryDate,
  id,
  showAroundCount,
  showAroundStatus,
  startDate,
  status,
  weeklyValue,
  ...rest
}) => {
  const { birthDate } = child

  return ({
    ...rest,
    birthDate: <DateString date={birthDate} />,
    childName: (
      <Hyperlink to={generateRoute('ENQUIRIES.DETAIL', { enquiryId: id })} primary>
        {childName}
      </Hyperlink>
    ),
    contactName: (
      <Hyperlink to={generateRoute('ENQUIRIES.DETAIL', { enquiryId: id })} primary>
        {contactName}
      </Hyperlink>
    ),
    edit: (
      <Button.TableAction
        to={generateRoute('ENQUIRIES.DETAIL', { enquiryId: id })}
      />
    ),
    enquiryDate: <DateString date={enquiryDate} />,
    startDate: startDate ? <DateString date={startDate} /> : '-',
    status: status ? (
      <React.Fragment>
        {ENQUIRY_STATUS_CODES.NEW_ENQUIRY === status.code && <StyledNewTag />}
        {status.name}
      </React.Fragment>
    ) : undefined,
    viewingCount: getViewingStatus(showAroundCount, showAroundStatus),
    weeklyValue: weeklyValue ? <Currency value={weeklyValue} /> : '-',
  })
})
