import _ from 'lodash'
import moment from 'moment'

import React, { useState } from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { ACTIVITY_EVENT_TYPE, ACTIVITY_TYPE } from 'services/legacy/enquiries/activities/constants'

import { Button, DropdownMenu, Icon, Typography } from 'components'

import i18n from 'translations'

import ActivityDetailAdd from '../../ActivityDetailAdd'
import {
  StyledActionContentContainer,
  StyledDateContentContainer,
  StyledIconContainer,
  StyledItemContainer,
  StyledMainContentContainer,
  StyledNoteContainer,
} from './ActivityListStyled'
import ActivityListItemContent from './ActivityListItemContent'
import { ACTIVITY_MAPPING } from '../../constants'

const ActivityListItem = ({
  enquiryActivity,
  enquiryId,
  onDeleteClick,
  onSuccess,
}) => {
  const [isInlineEditMode, setIsInlineEditMode] = useState()

  const {
    activityData,
    activityDate,
    author,
    event,
    id,
    isLostEnquiry,
    note,
    showAround,
    type,
  } = enquiryActivity
  const { showAroundDate } = showAround || {}
  const { status } = activityData || {}
  const { name } = author || {}

  const hideName = type === ACTIVITY_TYPE.CREATE.value && ACTIVITY_EVENT_TYPE.SUBMITTED_BY_PARENT === event

  const handleEditClick = () => {
    setIsInlineEditMode((old) => !old)
  }

  const handleSuccess = () => {
    if (onSuccess) {
      onSuccess()
    }

    setIsInlineEditMode(false)
  }

  const renderContent = () => {
    if (isInlineEditMode) {
      return (
        <StyledNoteContainer>
          <ActivityDetailAdd
            enquiryId={enquiryId}
            formName={`${ACTIVITY_MAPPING[type].formName}_INLINE_EDIT`}
            id={id}
            initialValues={{
              ...enquiryActivity,
              activityTime: moment(activityDate),
            }}
            isInlineEditMode={isInlineEditMode}
            notePlaceholder={
            i18n.t(`module:Enquiries:List:Detail:Activity:Entry:Tabs:${_.upperFirst(type)}:Note:placeholder`)
          }
            submitButtonLabel={
            i18n.t(`module:Enquiries:List:Detail:Activity:Entry:Tabs:${_.upperFirst(type)}:submitButtonLabel`)
          }
            type={type}
            onCancelClick={() => setIsInlineEditMode(false)}
            onSuccess={handleSuccess}
          />
        </StyledNoteContainer>
      )
    }

    return (
      <ActivityListItemContent
        activityData={activityData}
        isLostEnquiry={isLostEnquiry}
        note={note}
        type={type}
      />
    )
  }

  const renderSubTitle = () => {
    if (type === ACTIVITY_TYPE.VIEWING.value) {
      return i18n.t(`module:Enquiries:List:Detail:Activity:List:ActivitySubtitle:viewing${_.upperFirst(event)}`)
    }

    if (type === ACTIVITY_TYPE.STATUS_CHANGE.value) {
      if (ACTIVITY_EVENT_TYPE.MIGRATE === event) {
        return i18n.t('module:Enquiries:List:Detail:Activity:List:ActivitySubtitle:statusMigrate', {
          newStatus: status?.newValue?.name,
          oldStatus: status?.oldValue?.name,
        })
      }

      return i18n.t('module:Enquiries:List:Detail:Activity:List:ActivitySubtitle:statusChange', {
        status: status?.newValue?.name,
      })
    }

    if (type === ACTIVITY_TYPE.CREATE.value) {
      if (ACTIVITY_EVENT_TYPE.LOGGED_BY_MEMBER === event) {
        return i18n.t('module:Enquiries:List:Detail:Activity:List:ActivitySubtitle:create')
      }

      return i18n.t('module:Enquiries:List:Detail:Activity:List:ActivitySubtitle:createFromPublicForm')
    }

    return ACTIVITY_MAPPING[type]?.subtitle
  }

  return (
    <StyledItemContainer hideNote={!note && !showAroundDate} isInlineEditMode={isInlineEditMode}>
      <StyledIconContainer>
        <Icon
          color={NEUTRAL_COLOURS.BASIC}
          height={22}
          icon={ACTIVITY_MAPPING[type]?.icon}
        />
      </StyledIconContainer>
      <StyledMainContentContainer>
        <Typography bold>
          {!hideName && name}
          <Typography margin={!hideName && '0 0 0 5px'} inline>
            {renderSubTitle()}
          </Typography>
        </Typography>
      </StyledMainContentContainer>
      <StyledDateContentContainer>
        <Typography
          align="right"
          color={NEUTRAL_COLOURS.GRAY}
          fontSize={14}
        >
          {!isInlineEditMode && moment(activityDate).format('DD MMM YYYY [at] HH:mm')}
        </Typography>
      </StyledDateContentContainer>
      <StyledActionContentContainer>
        {!isInlineEditMode && _.find(ACTIVITY_TYPE, { value: type })?.editable && (
          <DropdownMenu
            button={(
              <Button
                hierarchy="tertiary"
                icon="dots-vertical"
                margin="0"
                size="small"
              />
            )}
          >
            <DropdownMenu.Item
              type="edit"
              onClick={handleEditClick}
            />
            <DropdownMenu.Item
              type="delete"
              onClick={() => onDeleteClick(id)}
            />
          </DropdownMenu>
        )}
      </StyledActionContentContainer>
      {(note || showAroundDate) && renderContent()}
    </StyledItemContainer>
  )
}

export default ActivityListItem
