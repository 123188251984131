import _ from 'lodash'

import React from 'react'
import isEqual from 'react-fast-compare'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { STATUS_TYPES, STATUS_TYPES_OPTIONS } from 'services/legacy/requestedExtraSessions/constants'

import { generateRoute } from 'utils/routing'

import { Avatar, Banner, Button, Callout, Page, Section, Spinner, StickyFooter, Toolbar, Typography } from 'components'

import i18n from 'translations'

import { getStatusColor } from './RequestedExtraSessionsDetailsHelpers'
import RequestedExtraSessionsDetailsBreakdown from './components/RequestedExtraSessionsDetailsBreakdown'
import { StyledAlignCenterDiv, StyledPendingStatusDot } from './RequestedExtraSessionsDetailsStyled'

const RequestedExtraSessionsDetailsView = ({
  data,
  errorMessages,
  errorStatistics,
  isAvailabilityStaffRequirementsChanged,
  isFetching,
  isFinanceV3Enabled,
  isSessionDetailsChanged,
  isSubmitting,
  onApproveClick,
  onDeclineClick,
}) => {
  const {
    child,
    childExtraSession,
    extraSessionChildProduct,
    nurseryClass,
    nurserySession,
    status,
  } = data || {}
  const {
    ageMonths,
    firstName,
    id: childId,
    isArchived: isChildArchived,
    name,
    photo,
    surname,
  } = child || {}
  const { archived: isSessionArchived } = nurserySession || {}
  const { archived: isChildExtraSessionArchived, id: childExtraSessionId } = childExtraSession || {}
  const { archived: isRoomArchived } = nurseryClass || {}

  const renderToolbar = () => (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <Avatar
            initials={[firstName, surname]}
            src={photo}
            subTitle={ageMonths && (
              <Typography color={NEUTRAL_COLOURS.GRAY}>
                {`${ageMonths} ${i18n.t('global:months')}`}
              </Typography>
            )}
            title={(
              <Typography fontSize={18}>
                {name}
              </Typography>
            )}
            to={generateRoute('CHILDREN.CHILD.ABOUT', { childId })}
          />
        </Toolbar.Item>
      </Toolbar.Group>
      <Toolbar.Group>
        <Toolbar.Item>
          <Typography>
            {i18n.t('module:Parent:RequestedSessions:Details:Label:status')}
          </Typography>
          <StyledAlignCenterDiv>
            {STATUS_TYPES.PENDING === status && <StyledPendingStatusDot />}
            <Typography color={getStatusColor(status)} fontSize={20} bold>
              {_.find(STATUS_TYPES_OPTIONS, { value: status })?.label}
            </Typography>
          </StyledAlignCenterDiv>
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )

  const renderAvailabilityStaffRequirementsChangedBanner = () => isAvailabilityStaffRequirementsChanged && (
    <Banner.Error>
      {i18n.t('module:Parent:RequestedSessions:Details:Banner:availabilityStaffRequirementsChanged')}
    </Banner.Error>
  )

  const renderArchivedRoomBanner = () => isRoomArchived && STATUS_TYPES.PENDING === status && (
    <Banner.Warning>
      {i18n.t('module:Parent:RequestedSessions:Details:Banner:archivedRoom')}
    </Banner.Warning>
  )

  const renderArchivedSessionBanner = () => isSessionArchived && STATUS_TYPES.PENDING === status && (
    <Banner.Warning>
      {i18n.t('module:Parent:RequestedSessions:Details:Banner:archivedSession')}
    </Banner.Warning>
  )

  const renderArchivedChildExtraSessionBanner = () => isChildExtraSessionArchived
    && STATUS_TYPES.DECLINED === status && (
      <Banner.Warning>
        {i18n.t('module:Parent:RequestedSessions:Details:Banner:archivedExtraSession')}
      </Banner.Warning>
  )

  const renderSessionDetailsChangedBanner = () => isSessionDetailsChanged && (
    <Banner.Warning>
      {i18n.t('module:Parent:RequestedSessions:Details:Banner:sessionDetailsChanged')}
    </Banner.Warning>
  )

  const renderArchivedChildBanner = () => isChildArchived && STATUS_TYPES.PENDING === status && (
    <Banner.Warning>
      {i18n.t('module:Parent:RequestedSessions:Details:Banner:archivedChild')}
    </Banner.Warning>
  )

  const renderFooter = () => {
    if (STATUS_TYPES.PENDING === status) {
      return (
        <StickyFooter>
          <Button
            hierarchy="tertiary"
            label={i18n.t('global:Back')}
            to={generateRoute('PARENT_INBOX.REQUESTED_EXTRA_SESSIONS.LIST')}
            negativeMargins
          />
          <StickyFooter.Flex />
          <Button
            hierarchy="secondary"
            label={i18n.t('module:Parent:RequestedSessions:Details:Actions:decline')}
            negativeMargins
            onClick={onDeclineClick}
          />
          <Button
            disabled={isChildArchived}
            label={i18n.t('module:Parent:RequestedSessions:Details:Actions:approve')}
            negativeMargins
            onClick={onApproveClick}
          />
        </StickyFooter>
      )
    }

    if (STATUS_TYPES.APPROVED === status) {
      let path = generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.EXTRA_SESSIONS.EDIT', {
        childId,
        id: childExtraSessionId,
      })

      if (isFinanceV3Enabled) {
        path = generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.ONE_OFF_BOOKINGS.EDIT', {
          childId,
          oneOffBookingId: extraSessionChildProduct.id,
        })
      }

      return (
        <StickyFooter>
          <Button
            hierarchy="tertiary"
            label={i18n.t('global:Back')}
            to={generateRoute('PARENT_INBOX.REQUESTED_EXTRA_SESSIONS.LIST')}
            negativeMargins
          />
          <StickyFooter.Flex />
          <Button
            hierarchy="secondary"
            label={i18n.t('module:Parent:RequestedSessions:Details:viewInChildProfile')}
            to={path}
            negativeMargins
          />
        </StickyFooter>
      )
    }

    return (
      <StickyFooter>
        <Button
          hierarchy="tertiary"
          label={i18n.t('global:Back')}
          to={generateRoute('PARENT_INBOX.REQUESTED_EXTRA_SESSIONS.LIST')}
          negativeMargins
        />
        <StickyFooter.Flex />
      </StickyFooter>
    )
  }

  const renderContent = () => {
    if (isFetching || isSubmitting) {
      return <Spinner />
    }

    return (
      <React.Fragment>
        {renderToolbar()}
        <Callout content={errorMessages} error />
        {renderAvailabilityStaffRequirementsChangedBanner()}
        {renderArchivedRoomBanner()}
        {renderArchivedSessionBanner()}
        {renderArchivedChildExtraSessionBanner()}
        {renderSessionDetailsChangedBanner()}
        {renderArchivedChildBanner()}
        <Typography color={NEUTRAL_COLOURS.GRAY} margin="20px 0">
          {i18n.t('module:Parent:RequestedSessions:Details:subtitle')}
        </Typography>
        <RequestedExtraSessionsDetailsBreakdown
          data={data}
          errorStatistics={errorStatistics}
        />
        {renderFooter()}
      </React.Fragment>
    )
  }

  const actions = !isFetching && !isSubmitting && STATUS_TYPES.PENDING !== status && (
    <Section.Actions
      options={[{
        icon: STATUS_TYPES.APPROVED === status ? 'circle-close' : 'confirmed',
        label: STATUS_TYPES.APPROVED === status
          ? i18n.t('module:Parent:RequestedSessions:Details:Actions:decline')
          : i18n.t('module:Parent:RequestedSessions:Details:Actions:approve'),
        onClick: STATUS_TYPES.APPROVED === status ? onDeclineClick : onApproveClick,
      }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isFetching || isSubmitting}
      title={i18n.t('module:Parent:RequestedSessions:Details:title')}
    >
      {renderContent()}
    </Page.Section>
  )
}

export default React.memo(RequestedExtraSessionsDetailsView, isEqual)
