import _ from 'lodash'

import {
  addCarer,
  addStaff,
  createInjurySignatureForCarer,
  createInjurySignatureForCarerError,
  removeCarer,
  removeStaff,
  updateCarerDropdown,
  updateNewCarer,
  updateNewCarerError,
  updateOtherCarerDropdown,
  updateSignature,
  updateSignatureId,
  updateSignatureIdError,
  updateSignatureStatistics,
  updateStaffDropdown,
} from './helpers'

import {
  ADD_INJURY_SIGNATURE_CARER,
  ADD_INJURY_SIGNATURE_STAFF_MEMBER,
  CLEAR_INJURY_SIGNATURES,
  CREATE_INJURY_SIGNATURE_FAILED,
  CREATE_INJURY_SIGNATURE_ID_FAILED,
  CREATE_INJURY_SIGNATURE_ID_SUCCESS,
  CREATE_INJURY_SIGNATURE_SUCCESS,
  GET_INJURY_SIGNATURES,
  GET_INJURY_SIGNATURES_FAILED,
  GET_INJURY_SIGNATURES_SUCCESS,
  REMOVE_INJURY_SIGNATURE_STAFF_SUCCESS,
  REMOVE_INJURY_SIGNATURE_SUCCESS,
  TOGGLE_ON_PAPER_SIGNATURE_ITEM_VALUE,
  UPDATE_INJURY_SIGNATURE,
  UPDATE_INJURY_SIGNATURES,
  UPDATE_INJURY_SIGNATURES_FAILED,
  UPDATE_INJURY_SIGNATURES_SUCCESS,
  UPDATE_INJURY_SIGNATURE_CARER,
  UPDATE_INJURY_SIGNATURE_DOCUMENTS,
  UPDATE_INJURY_SIGNATURE_FAILED,
  UPDATE_INJURY_SIGNATURE_ID_FAILED,
  UPDATE_INJURY_SIGNATURE_ID_SUCCESS,
  UPDATE_INJURY_SIGNATURE_MANUALLY,
  UPDATE_INJURY_SIGNATURE_OTHER_CARER,
  UPDATE_INJURY_SIGNATURE_STAFF_MEMBER,
  UPDATE_INJURY_SIGNATURE_STATISTICS,
  UPDATE_INJURY_SIGNATURE_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false,
}

export default (state = initialState, {
  error,
  payload,
  silent,
  type,
  uid,
  updateInjuryRecord,
  updateToSignatureList,
}) => {
  switch (type) {
    case GET_INJURY_SIGNATURES:
      return { ...state, error: initialState.error, isFetching: !silent }
    case GET_INJURY_SIGNATURES_SUCCESS:
      return { ...state, data: payload.data, isFetching: false }
    case GET_INJURY_SIGNATURES_FAILED:
      return { ...state, error, isFetching: false }
    case UPDATE_INJURY_SIGNATURE_ID_SUCCESS:
      return updateSignatureId(state, payload.item, payload.response)
    case UPDATE_INJURY_SIGNATURE_ID_FAILED:
      return updateSignatureIdError(state, error, uid)
    case UPDATE_INJURY_SIGNATURES:
      return {
        ...state,
        isSubmitting: true,
      }
    case UPDATE_INJURY_SIGNATURES_SUCCESS:
      return {
        ...state,
        data: updateInjuryRecord ? payload.data : state.data,
        isSubmitting: false,
      }
    case UPDATE_INJURY_SIGNATURES_FAILED:
      return {
        ...state,
        error: payload,
        isSubmitting: false,
      }
    case UPDATE_INJURY_SIGNATURE:
      return {
        ...state,
        data: {
          ...state.data,
          signatures: _.map(state.data.signatures, (signature) => {
            if (signature.id === payload.id) {
              return {
                ...signature,
                error: undefined,
                isSubmitting: true,
              }
            }

            return signature
          }),
        },
      }
    case UPDATE_INJURY_SIGNATURE_SUCCESS:
      return updateSignature(state, payload, updateToSignatureList)
    case UPDATE_INJURY_SIGNATURE_FAILED:
      return {
        ...state,
        data: {
          ...state.data,
          signatures: _.map(state.data.signatures, (signature) => {
            if (signature.id === payload.id) {
              return {
                ...signature,
                error: payload.response,
                isSubmitting: false,
              }
            }

            return signature
          }),
        },
      }
    case ADD_INJURY_SIGNATURE_CARER:
      return addCarer(state, payload.child, payload.isHomeAccidents)
    case ADD_INJURY_SIGNATURE_STAFF_MEMBER:
      return addStaff(state, payload.staff, payload.isRemoveSignature)
    case UPDATE_INJURY_SIGNATURE_CARER:
      return updateCarerDropdown(state, payload.item, payload.option)
    case UPDATE_INJURY_SIGNATURE_OTHER_CARER:
      return updateOtherCarerDropdown(state, payload.item, payload.value)
    case UPDATE_INJURY_SIGNATURE_STAFF_MEMBER:
      return updateStaffDropdown(state, payload.item, payload.option)
    case CREATE_INJURY_SIGNATURE_ID_SUCCESS:
      return createInjurySignatureForCarer(state, payload.item, payload.response)
    case CREATE_INJURY_SIGNATURE_ID_FAILED:
      return createInjurySignatureForCarerError(state, error, uid)
    case CREATE_INJURY_SIGNATURE_SUCCESS:
      return updateNewCarer(state, payload.data, uid)
    case CREATE_INJURY_SIGNATURE_FAILED:
      return updateNewCarerError(state, error, uid)
    case REMOVE_INJURY_SIGNATURE_SUCCESS:
      return removeCarer(state, payload?.signatureId, uid)
    case REMOVE_INJURY_SIGNATURE_STAFF_SUCCESS:
      return removeStaff(state, payload?.signatureId, uid)
    case TOGGLE_ON_PAPER_SIGNATURE_ITEM_VALUE:
      return {
        ...state,
        data: {
          ...state.data,
          signatures: _.map(state.data.signatures, (signature) => {
            if (signature.id === payload.signatureId) {
              return {
                ...signature,
                signedOnPaper: !signature.signedOnPaper,
              }
            }

            return signature
          }),
        },
      }
    case UPDATE_INJURY_SIGNATURE_DOCUMENTS:
      return {
        ...state,
        data: {
          ...state.data,
          signatureDocuments: payload.signatureDocuments,
        },
      }
    case UPDATE_INJURY_SIGNATURE_MANUALLY:
      return {
        ...state,
        data: {
          ...state.data,
          signatures: _.map(state.data.signatures, (signature) => {
            if (signature.id === payload.signatureId) {
              return {
                ...signature,
                ...payload.body,
              }
            }

            return signature
          }),
        },
      }
    case UPDATE_INJURY_SIGNATURE_STATISTICS:
      return updateSignatureStatistics(state, payload.newSignatureStatistics, payload.status)
    case CLEAR_INJURY_SIGNATURES:
      return { ...initialState }
    default:
      return state
  }
}
