import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { DEPOSIT_REFUND_USED_OPTIONS } from 'services/legacy/deposits/constants'

import { isRequired } from 'utils/fieldValidation'

import { Form } from 'components'

import i18n from 'translations'

export const DEPOSIT_REFUND_MODAL_FORM = 'DepositRefundModalForm'

const DepositRefundModalForm = ({
  handleSubmit,
  isSubmitting,
  onCloseClick,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row>
      <Form.Row.FlexItem flex="0 0 300px" mobileFull>
        <Field
          component={Form.RadioGroup}
          name="used"
          options={DEPOSIT_REFUND_USED_OPTIONS}
          validate={isRequired}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.FooterActions
      isSubmitting={isSubmitting}
      submitLabel={i18n.t('module:Modals:DepositRefund:refund')}
      onCancelClick={onCloseClick}
    />
  </Form>
)

export default reduxForm({ form: DEPOSIT_REFUND_MODAL_FORM })(DepositRefundModalForm)
