import moment from 'moment'
import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { stopSubmit } from 'redux-form'

import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withDailyDiaryNappiesService } from 'services/legacy/dailyDiaryNappies'
import { withModalService } from 'services/utils/modal'

import NappiesTabView from './NappiesTabView'
import { NAPPIES_TAB_FORM } from './components/NappiesTabForm'

const GROUPS = {
  read: ['nappyChangeRecord'],
}

class NappiesTabContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      trackToiletTraining: false,
    }
  }

  handleSubmitFailed = (response) => {
    const { injectValidation } = this.props
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    return injectValidation(errors)
  }

  handleSubmitSuccess = (selectedChildren) => ({ data }) => {
    const { dailyDiaryNappiesListWithoutRecordsState, onSuccess } = this.props
    const { trackToiletTraining } = this.state
    const { data: withoutRecords } = dailyDiaryNappiesListWithoutRecordsState

    const records = _.map(data, ({ child, record }) => ({
      data: {
        ...record,
        child: {
          id: child.id,
        },
      },
      record: _.find(withoutRecords, ({ childRegister }) => childRegister.child.id === child.id),
    }))

    onSuccess({ records, selectedChildren, trackToiletTraining })
  }

  handleSubmit = (values) => {
    const { dailyDiaryNappiesActions, dailyDiaryNappiesSelectors, selectedChildren } = this.props
    const body = dailyDiaryNappiesSelectors.getValuesForm(values)

    const criteria = _.map(selectedChildren, (child, index) => ({
      field: `id[${index}]`,
      value: child.id,
    }))

    dailyDiaryNappiesActions.addRecord({
      body: {
        recordedAt: moment().toISOString(),
        ...body,
      },
      onFailed: this.handleSubmitFailed,
      onSuccess: this.handleSubmitSuccess(selectedChildren),
      params: [{
        criteria,
        groups: GROUPS,
      }],
    })
  }

  handleToiletTraining = () => this.setState((state) => ({ trackToiletTraining: !state.trackToiletTraining }))

  render() {
    const {
      date,
      getInitialValues,
      isSubmitting,
      onCancel,
      selectedChildren,
    } = this.props
    const { trackToiletTraining } = this.state

    const initialValues = getInitialValues({ date })

    return (
      <NappiesTabView
        initialValues={initialValues}
        isSubmitting={isSubmitting}
        selectedChildren={selectedChildren}
        trackToiletTraining={trackToiletTraining}
        onCancel={onCancel}
        onRemoveChild={this.handleRemoveChild}
        onSubmit={this.handleSubmit}
        onToiletTrainingChange={this.handleToiletTraining}
      />
    )
  }
}

const mapDispatch = {
  injectValidation: (params) => stopSubmit(NAPPIES_TAB_FORM, params),
}

const mapState = (state, {
  appSelectors,
  dailyDiaryNappiesSelectors,
  dailyDiaryNappiesSingleState,
}) => ({
  getInitialValues: (params) => dailyDiaryNappiesSelectors.getInitialValues(params),
  isSubmitting: appSelectors.getIsSubmitting(
    dailyDiaryNappiesSingleState,
  ),
})

const enhance = compose(
  withAppService,
  withDailyDiaryNappiesService,
  withModalService,
  connect(mapState, mapDispatch),
)

export default enhance(NappiesTabContainer)
