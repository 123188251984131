import _ from 'lodash'

import { createSelector } from 'reselect'

const getSupportedLocaleState = (state) => state.supportedLocale

export const getSupportedLocaleListData = createSelector(
  [getSupportedLocaleState],
  (state) => state.list.data,
)

export const getSupportedLocaleListOptions = createSelector(
  [getSupportedLocaleListData],
  (state) => _.map(state, ({ code, name }) => ({ label: name, value: code })),
)
