import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'

import { Form } from 'components'

import i18n from 'translations'

export const STAFF_LEAVE_TYPES_ADD_FORM = 'StaffLeaveTypesAddForm'

const StaffLeaveTypesAddForm = ({
  handleSubmit,
  isLoading,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row
      label={`${i18n.t('module:Management:LeaveTypes:Staff:Add:fieldTitle')}`}
      margin="20px 0 0"
      required
    >
      <Form.Row.FlexItem flex="0 0 250px">
        <Field
          component={Form.TextField}
          name="name"
          placeholder={i18n.t('module:Management:LeaveTypes:Staff:tableTitle')}
          validate={isRequired}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.FooterActions
      cancelLink={generateRoute('MANAGEMENT.LEAVE_TYPES.STAFF_LEAVE_TYPES')}
      isSubmitting={isLoading}
      submitLabel={i18n.t('global:Save')}
    />
  </Form>
)

export default reduxForm({ form: STAFF_LEAVE_TYPES_ADD_FORM })(StaffLeaveTypesAddForm)
