import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

export const getChildAbsencesSelector = (state) => state.childAbsences

export const getChildAbsencesListDataSelector = createSelector(
  [getChildAbsencesSelector],
  (state) => state.list.data,
)

export const getListCriteria = (filters) => {
  const { childId, dateRange, type } = filters || {}

  const criteria = []

  const [startDate, endDate] = dateRange

  if (childId) {
    criteria.push({
      field: 'child',
      value: childId,
    })
  }

  if (type) {
    criteria.push({
      field: 'absenceType',
      value: type?.value,
    })
  }

  if (startDate) {
    criteria.push({
      field: 'endDate[after]',
      value: moment(startDate).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (endDate) {
    criteria.push({
      field: 'startDate[before]',
      value: moment(endDate).format(DEFAULT_DATE_FORMAT),
    })
  }

  return criteria
}

