import React from 'react'

import {
  Button,
  Callout,
  Checkbox,
  DatePicker,
  EmptyState,
  FooterActions,
  InfiniteDropdowns,
  InfiniteScroll,
  ModalBox,
  Pagination,
  Spinner,
  Table,
  Toolbar,
} from 'components'

import i18n from 'translations'

import { COLUMNS } from './constants'
import { StyledContainer, StyledTableContainer } from './DailyDiaryModalStyled'

const DailyDiaryModalView = ({
  data,
  error,
  filters,
  isExporting,
  isFetching,
  isSubmitDisabled,
  onCancelClick,
  onDateChange,
  onPageChange,
  onRoomChange,
  onSelectAllClick,
  onSortChange,
  onSubmit,
  page,
  pageCount,
  sortField,
  sortOrder,
  totalResults,
}) => {
  const {
    all,
    date,
    room,
  } = filters

  const renderToolbar = () => (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <InfiniteDropdowns.Rooms
            value={room}
            onChange={onRoomChange}
          />
        </Toolbar.Item>
        <Toolbar.Item>
          <DatePicker
            value={date}
            onChange={onDateChange}
          />
        </Toolbar.Item>
      </Toolbar.Group>
      <Toolbar.Group>
        <Toolbar.Item>
          <Checkbox
            disabled={!data?.length}
            label={i18n.t('global:selectAll')}
            value={all}
            inverted
            onChange={onSelectAllClick}
          />
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )

  const renderContent = () => {
    if (isFetching && 1 === page) {
      return (
        <StyledContainer>
          <Spinner />
        </StyledContainer>
      )
    }
    if (!data?.length) {
      return (
        <StyledContainer>
          <EmptyState
            icon="daily-diary"
            text1={i18n.t('module:Modals:DailyDiary:emptyState')}
          />
        </StyledContainer>
      )
    }

    return (
      <React.Fragment>
        <Pagination
          titlePlural={i18n.t('module:Modals:DailyDiary:Pagination:plural')}
          titleSingular={i18n.t('module:Modals:DailyDiary:Pagination:singular')}
          totalResults={totalResults}
        />
        <StyledTableContainer id="dailyDiariesScrollableTarget">
          <InfiniteScroll
            dataLength={data?.length || 0}
            hasMore={page < pageCount}
            isFetching={isFetching}
            next={() => onPageChange((+page) + 1)}
            scrollableTarget="dailyDiariesScrollableTarget"
            lightSpinner
          >
            <Table
              columns={COLUMNS}
              data={data}
              sortField={sortField}
              sortOrder={sortOrder}
              onSortChange={onSortChange}
            />
          </InfiniteScroll>
        </StyledTableContainer>
      </React.Fragment>
    )
  }

  const renderFooter = () => (
    <FooterActions spaceBetween>
      <FooterActions.Item>
        <Button
          hierarchy="tertiary"
          label={i18n.t('global:Cancel')}
          negativeMargins
          onClick={onCancelClick}
        />
      </FooterActions.Item>
      <FooterActions.Item>
        <Button
          disabled={isSubmitDisabled}
          isLoading={isExporting}
          label={i18n.t('module:Modals:DailyDiary:approveDiary')}
          negativeMargins
          onClick={onSubmit}
        />
      </FooterActions.Item>
    </FooterActions>
  )

  return (
    <ModalBox
      autoHeight
      noHeader
    >
      <Callout content={error} error />
      {renderToolbar()}
      {renderContent()}
      {renderFooter()}
    </ModalBox>
  )
}

export default DailyDiaryModalView
