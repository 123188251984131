import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'
import layout from 'constants/layout'

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`

export const StyledBoxContainer = styled.div`
  width: 400px;
  display: grid;
  grid-row-gap: 50px;
`

export const StyledBox = styled.div`
  background: ${NEUTRAL_COLOURS.GRAY_QUINARY};
  padding: 20px;
  border-radius: ${layout.borderRadius};
`

export const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
  min-height: 60px;
`

export const StyledLogo = styled.img`
  max-width: 234px;
  max-height: 100px;
  
  ${({ custom }) => !custom && `
    margin: 20px 0;
  `}
`

export const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
  
  img {
    width: 257px;
  }
`

export const StyledFooterLink = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;  
`
