import { createSelector } from 'reselect'
import moment from 'moment'
import _ from 'lodash'

import { DAILY_DIARY_DATE_FORMAT, TIME_TYPE } from 'services/legacy/dailyDiary/constants'

import { changeHours, isPastDay } from 'utils/date'

const getDailyDiaryActivities = (state) => state.dailyDiaryActivities

export const getDailyDiaryActivitiesList = createSelector([getDailyDiaryActivities], (state) => state.list)

export const getDailyDiaryActivitiesListData = createSelector([getDailyDiaryActivitiesList], (state) => {
  const items = _.map(state.data, (item) => {
    const newItem = { ...item }

    newItem.media = _.map(newItem.media, (media) => {
      const newMedia = { ...media }

      newMedia.children = _.map(newMedia.children, (child) => {
        const childObject = _.find(item.children, ({ id }) => child.id === id)

        return {
          ...childObject,
          id: child.id,
        }
      })

      return newMedia
    })

    return newItem
  })

  return _.sortBy(items, ['record.recordedAt'])
})

export const getListCriteria = (query = {}) => {
  const { date = moment().format(DAILY_DIARY_DATE_FORMAT), room, timeType } = query
  const criteria = []

  criteria.push({
    comparator: 'after',
    field: 'record.recordedAt',
    value: moment(changeHours(moment(date, DAILY_DIARY_DATE_FORMAT), new Date()))
      .utc()
      .startOf('day')
      .toISOString(),
  })

  criteria.push({
    comparator: 'before',
    field: 'record.recordedAt',
    value: moment(changeHours(moment(date, DAILY_DIARY_DATE_FORMAT), new Date()))
      .utc()
      .endOf('day')
      .toISOString(),
  })

  if (room) {
    criteria.push({
      field: 'activityRecordChildren.child.nurseryClass',
      value: room,
    })
  }

  if (timeType === TIME_TYPE.CURRENTLY_TIMED_IN && !isPastDay(date)) {
    criteria.push({
      comparator: 'after',
      field: 'signIns.signedOutAt',
      value: changeHours(date, moment()).toISOString(),
    })
  }

  return criteria
}
