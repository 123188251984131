import React from 'react'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { Form, Section } from 'components'

import i18n from 'translations'

import { CHILD_ADD_FORM } from './constants'
import ChildFormFooter from './ChildFormFooter'

const TRANS_PREFIX = 'module:Children:Child:Add:Permissions'

const PermissionsInformationForm = ({
  canBePhotographed,
  childId,
  handleSubmit,
  isChildArchived,
  isEditMode,
  isSubmitting,
  onArchiveClick,
  onPreviousStageClick,
  permissionToArchive,
}) => {
  const renderForm = () => {
    const renderMedia = () => (
      <Section title={i18n.t(`${TRANS_PREFIX}:Subsections:Media:title`)}>
        <Form.Row autoHeight label>
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              label={i18n.t(`${TRANS_PREFIX}:Subsections:Media:Form:CanBePhotographed:label`)}
              name="information.canBePhotographed"
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row autoHeight label>
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              disabled={!canBePhotographed}
              label={i18n.t(`${TRANS_PREFIX}:Subsections:Media:Form:CanBeTaggedInGroupPhotos:label`)}
              name="information.canBeTaggedInGroupPhotos"
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row autoHeight label>
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              label={i18n.t(`${TRANS_PREFIX}:Subsections:Media:Form:SocialMediaPhotoSharing:label`)}
              name="information.socialMediaPhotoSharingPermitted"
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row autoHeight label>
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              label={i18n.t(`${TRANS_PREFIX}:Subsections:Media:Form:ClosedSocialMediaPhotoSharingPermitted:label`)}
              name="information.closedSocialMediaPhotoSharingPermitted"
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row autoHeight label>
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              label={i18n.t(`${TRANS_PREFIX}:Subsections:Media:Form:UseOnSiteMediaPermitted:label`)}
              name="information.useOnSiteMediaPermitted"
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row autoHeight label>
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              label={i18n.t(`${TRANS_PREFIX}:Subsections:Media:Form:OtherMarketingMaterialPhotoSharing:label`)}
              name="information.otherMarketingMaterialPhotoSharingPermitted"
            />
          </Form.Row.Item>
        </Form.Row>
      </Section>
    )

    const renderPlay = () => (
      <Section title={i18n.t(`${TRANS_PREFIX}:Subsections:Play:title`)}>
        <Form.Row autoHeight label>
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              label={i18n.t(`${TRANS_PREFIX}:Subsections:Play:Form:CanGoToExternalOutgoings:label`)}
              name="information.canGoToExternalOutgoings"
            />
          </Form.Row.Item>
        </Form.Row>
      </Section>
    )

    const renderSafety = () => (
      <Section title={i18n.t(`${TRANS_PREFIX}:Subsections:Safety:title`)}>
        <Form.Row autoHeight label>
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              label={i18n.t(`${TRANS_PREFIX}:Subsections:Safety:Form:CanTakeTheBus:label`)}
              name="information.canTakeTheBus"
            />
          </Form.Row.Item>
        </Form.Row>
      </Section>
    )

    return (
      <React.Fragment>
        {renderMedia()}
        {renderPlay()}
        {renderSafety()}
      </React.Fragment>
    )
  }

  const renderFooter = () => (
    <ChildFormFooter
      childId={childId}
      isChildArchived={isChildArchived}
      isEditMode={isEditMode}
      isSubmitting={isSubmitting}
      permissionToArchive={permissionToArchive}
      onArchiveClick={onArchiveClick}
      onPreviousStageClick={onPreviousStageClick}
    />
  )

  return (
    <Form onSubmit={handleSubmit}>
      {renderForm()}
      {renderFooter()}
    </Form>
  )
}

const mapState = (state) => ({
  canBePhotographed: formValueSelector(CHILD_ADD_FORM)(state, 'information.canBePhotographed'),
})

const enhance = compose(
  reduxForm({ destroyOnUnmount: false, form: CHILD_ADD_FORM }),
  connect(mapState),
)

export default enhance(PermissionsInformationForm)
