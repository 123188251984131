import styled from 'styled-components'

import layout from 'constants/layout'

export const StyledMediaPickerWrapper = styled.div`
  max-width: 100%;
  margin-bottom: 10px;
`

export const StyledTilesContainer = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
`

export const StyledTile = styled.div`
  border-radius: ${layout.borderRadius};
  background-color: #F7F7F7;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 10px 10px 0;
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 220px;
  height: 135px;
  vertical-align: top;

  .mediaPickerImage,
  .mediaPickerImage img {
    border-radius: ${layout.borderRadius};
    object-fit: cover;
  }
`

export const StyledTileContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  flex-direction: column;
  text-align: center;
  height: 100%;
`

export const StyledTitle = styled.div`
  max-width: 100%;
  font-weight: 600;
  margin-bottom: 3px;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis; 
  white-space: nowrap;
`

export const StyledSize = styled.div`
  color: #9D9D9D;
`

export const StyledTileOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  content: '';
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  background: rgba(0,0,0,.4);
`

export const StyledSectionActionButton = styled.div`
  display: flex;
  justify-content: flex-end;  
`

export const StyledRemoveButton = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
`

