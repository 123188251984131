import _ from 'lodash'
import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT } from 'constants/date'
import { ACTIVITY_TYPE } from 'services/legacy/enquiries/activities/constants'
import { VIEWING_STATUS_OPTIONS } from 'services/legacy/enquiries/viewings/constants'

import { getDateString } from 'utils/date'

import { Icon, Space, Typography } from 'components'

import i18n from 'translations'

import {
  StyledActivityChangeItemContainer,
  StyledActivityChangeItemValue,
  StyledNoteContainer,
  StyledShowAroundActivityContainer,
  StyledShowAroundContainer,
} from './ActivityListStyled'

import ActivityNote from './components/ActivityNote'

const ActivityListItemContent = ({
  activityData,
  isLostEnquiry,
  note,
  type,
}) => {
  const {
    assignedPerson,
    lostReason,
    newNoteValue,
    oldNoteValue,
    showAroundDate,
    status,
  } = activityData || {}

  if (type === ACTIVITY_TYPE.VIEWING.value) {
    const activityChangeList = [
      {
        label: i18n.t('module:Enquiries:List:Detail:Activity:List:ShowAround:viewingDate'),
        newValue: getDateString(showAroundDate?.newValue, DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT),
        oldValue: getDateString(showAroundDate?.oldValue, DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT),
      },
      {
        label: i18n.t('module:Enquiries:List:Detail:Activity:List:ShowAround:viewingTime'),
        newValue: getDateString(showAroundDate?.newValue, 'HH:mm'),
        oldValue: getDateString(showAroundDate?.oldValue, 'HH:mm'),
      },
      {
        label: i18n.t('module:Enquiries:List:Detail:Activity:List:ShowAround:visitTakenBy'),
        newValue: assignedPerson?.newValue?.name,
        oldValue: assignedPerson?.oldValue?.name,
      },
      {
        label: i18n.t('module:Enquiries:List:Detail:Activity:List:ShowAround:status'),
        newValue: status?.newValue ? _.find(VIEWING_STATUS_OPTIONS, { value: status?.newValue })?.label : null,
        oldValue: status?.oldValue ? _.find(VIEWING_STATUS_OPTIONS, { value: status?.oldValue })?.label : null,
      },
    ]

    const renderActivityChange = ({ label, newValue, oldValue }) => (
      <StyledActivityChangeItemContainer key={_.kebabCase(label)}>
        <Typography variant="span">{label}</Typography>
          &nbsp;&nbsp;
        <StyledActivityChangeItemValue>
          {oldValue && newValue !== oldValue && (
            <React.Fragment>
              <Typography color={NEUTRAL_COLOURS.GRAY} variant="span">
                {oldValue}
              </Typography>
              <Icon
                color={NEUTRAL_COLOURS.GRAY}
                height={12}
                icon="arrow_forward"
              />
            </React.Fragment>
          )}
          <Typography variant="span">{newValue || '-'}</Typography>
        </StyledActivityChangeItemValue>
      </StyledActivityChangeItemContainer>
    )

    return (
      <StyledShowAroundContainer>
        <StyledShowAroundActivityContainer>
          {activityChangeList.map(renderActivityChange)}
        </StyledShowAroundActivityContainer>
        <ActivityNote text={newNoteValue} />
        <ActivityNote color={NEUTRAL_COLOURS.GRAY} text={oldNoteValue} />
      </StyledShowAroundContainer>
    )
  }

  if (isLostEnquiry) {
    return (
      <StyledNoteContainer>
        {i18n.t('module:Enquiries:List:Detail:Activity:List:lostReasonNote', {
          lostReason: lostReason.newValue?.name,
        })}
        <Space space="20px" />
        <ActivityNote text={note} />
      </StyledNoteContainer>
    )
  }

  return <StyledNoteContainer><ActivityNote text={note} /></StyledNoteContainer>
}

export default ActivityListItemContent
