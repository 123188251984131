import {
  BIRTHDAYS_LIST_NEXT_YEAR,
  BIRTHDAYS_LIST_NEXT_YEAR_FAILED,
  BIRTHDAYS_LIST_NEXT_YEAR_SUCCESS,
  BIRTHDAYS_LIST_THIS_YEAR,
  BIRTHDAYS_LIST_THIS_YEAR_FAILED,
  BIRTHDAYS_LIST_THIS_YEAR_SUCCESS,
  CLEAR_BIRTHDAYS_LIST,
} from './constants'

export const getBirthdaysUntilEndOfYear = (options = {}) => ({ childApiClient }) => async (dispatch) => {
  const { mergeResult, onSuccess, params = {} } = options

  dispatch({ payload: null, type: BIRTHDAYS_LIST_THIS_YEAR })

  await childApiClient.list(params).then(
    (data) => {
      dispatch({
        mergeResult,
        payload: data,
        type: BIRTHDAYS_LIST_THIS_YEAR_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: BIRTHDAYS_LIST_THIS_YEAR_FAILED,
      })
    },
  )
}

export const getBirthdaysUntilYesterday = (options = {}) => ({ childApiClient }) => async (dispatch) => {
  const { mergeResult, onSuccess, params = {} } = options

  dispatch({ payload: null, type: BIRTHDAYS_LIST_NEXT_YEAR })

  await childApiClient.list(params).then(
    (data) => {
      dispatch({
        mergeResult,
        payload: data,
        type: BIRTHDAYS_LIST_NEXT_YEAR_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: BIRTHDAYS_LIST_NEXT_YEAR_FAILED,
      })
    },
  )
}

export const clearBirthdaysList = () => ({
  type: CLEAR_BIRTHDAYS_LIST,
})
