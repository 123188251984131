import i18n from 'translations'

export const CHILD_LEAVE_TYPE = {
  ABSENCE: 'ABSENCE',
  HOLIDAY: 'HOLIDAY',
}

export const CHILD_LEAVE_TYPE_OPTIONS = [
  {
    label: i18n.t('global:Holiday'),
    value: CHILD_LEAVE_TYPE.HOLIDAY,
  },
  {
    label: i18n.t('global:Absence'),
    value: CHILD_LEAVE_TYPE.ABSENCE,
  },
]
