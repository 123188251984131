export const GET_NURSERY_CONSUMABLE_V3 = 'NURSERY_CONSUMABLES_V3/GET_NURSERY_CONSUMABLE'
export const GET_NURSERY_CONSUMABLE_V3_SUCCESS = 'NURSERY_CONSUMABLES_V3/GET_NURSERY_CONSUMABLE_V3_SUCCESS'
export const GET_NURSERY_CONSUMABLE_V3_FAILED = 'NURSERY_CONSUMABLES_V3/GET_NURSERY_CONSUMABLE_V3_FAILED'

export const CREATE_NURSERY_CONSUMABLE_V3 = 'NURSERY_CONSUMABLES_V3/CREATE_NURSERY_CONSUMABLE_V3'
export const CREATE_NURSERY_CONSUMABLE_V3_SUCCESS = 'NURSERY_CONSUMABLES_V3/CREATE_NURSERY_CONSUMABLE_V3_SUCCESS'
export const CREATE_NURSERY_CONSUMABLE_V3_FAILED = 'NURSERY_CONSUMABLES_V3/CREATE_NURSERY_CONSUMABLE_V3_FAILED'

export const UPDATE_NURSERY_CONSUMABLE_V3 = 'NURSERY_CONSUMABLES_V3/UPDATE_NURSERY_CONSUMABLE_V3'
export const UPDATE_NURSERY_CONSUMABLE_V3_SUCCESS = 'NURSERY_CONSUMABLES_V3/UPDATE_NURSERY_CONSUMABLE_V3_SUCCESS'
export const UPDATE_NURSERY_CONSUMABLE_V3_FAILED = 'NURSERY_CONSUMABLES_V3/UPDATE_NURSERY_CONSUMABLE_V3_FAILED'

export const CLEAR_NURSERY_CONSUMABLE_V3 = 'NURSERY_CONSUMABLES_V3/CLEAR_NURSERY_CONSUMABLE_V3'
