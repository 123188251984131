export const GET_NEXT_MONTESSORI_ACTIVITIES = 'GET_NEXT_MONTESSORI_ACTIVITIES'
export const GET_NEXT_MONTESSORI_ACTIVITIES_SUCCESS = 'GET_NEXT_MONTESSORI_ACTIVITIES_SUCCESS'
export const GET_NEXT_MONTESSORI_ACTIVITIES_FAILED = 'GET_NEXT_MONTESSORI_ACTIVITIES_FAILED'

export const CREATE_NEXT_MONTESSORI_ACTIVITIES = 'CREATE_NEXT_MONTESSORI_ACTIVITIES'
export const CREATE_NEXT_MONTESSORI_ACTIVITIES_SUCCESS = 'CREATE_NEXT_MONTESSORI_ACTIVITIES_SUCCESS'
export const CREATE_NEXT_MONTESSORI_ACTIVITIES_FAILED = 'CREATE_NEXT_MONTESSORI_ACTIVITIES_FAILED'

export const UPDATE_NEXT_MONTESSORI_ACTIVITIES = 'UPDATE_NEXT_MONTESSORI_ACTIVITIES'
export const UPDATE_NEXT_MONTESSORI_ACTIVITIES_SUCCESS = 'UPDATE_NEXT_MONTESSORI_ACTIVITIES_SUCCESS'
export const UPDATE_NEXT_MONTESSORI_ACTIVITIES_FAILED = 'UPDATE_NEXT_MONTESSORI_ACTIVITIES_FAILED'

export const REMOVE_NEXT_MONTESSORI_ACTIVITIES = 'REMOVE_NEXT_MONTESSORI_ACTIVITIES'
export const REMOVE_NEXT_MONTESSORI_ACTIVITIES_SUCCESS = 'REMOVE_NEXT_MONTESSORI_ACTIVITIES_SUCCESS'
export const REMOVE_NEXT_MONTESSORI_ACTIVITIES_FAILED = 'REMOVE_NEXT_MONTESSORI_ACTIVITIES_FAILED'

export const CLEAR_NEXT_MONTESSORI_ACTIVITIES_SINGLE = 'CLEAR_NEXT_MONTESSORI_ACTIVITIES_SINGLE'
