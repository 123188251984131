import ServiceBase from 'services/ServiceBase'

class AuthenticationApiClient extends ServiceBase {
  login = (body) => this.request({
    body,
    deviceTokenNotRequired: false,
    method: 'POST',
    path: '/auth/login',
    public: true,
  })

  installDeviceToken = (body) => this.request({
    body,
    deviceTokenNotRequired: true,
    method: 'POST',
    path: '/auth/install-device-token',
    public: true,
  })

  resetPassword = (body) => this.request({
    body,
    deviceTokenNotRequired: true,
    method: 'POST',
    path: '/auth/passwd/recover',
    public: true,
  })

  changePassword = (headers, body) => this.request({
    body,
    deviceTokenNotRequired: true,
    headers,
    method: 'POST',
    path: '/auth/passwd/change',
    public: true,
  })

  register = (body) => this.request({
    body,
    deviceTokenNotRequired: true,
    method: 'POST',
    path: '/auth/register',
    public: true,
  })

  authMe = (token: string) => {
    if (!LOCAL_VERSION) {
      return this.request({
        credentials: 'include',
        path: '/auth/me',
        token,
      })
    }

    return this.request({
      path: '/auth/me',
      token,
    })
  }
}

export default AuthenticationApiClient
