import React from 'react'

import { ModalBox } from 'components'

import i18n from 'translations'

import DownloadRegisterModalForm from './components/DownloadRegisterModalForm'

const DownloadRegisterModalView = ({
  initialValues,
  isFinanceV3Enabled,
  isProcessing,
  onCloseClick,
  onSubmit,
}) => (
  <ModalBox
    title={i18n.t('module:Modals:DownloadRegister:title')}
    autoHeight
    onCloseClick={onCloseClick}
  >
    <DownloadRegisterModalForm
      initialValues={initialValues}
      isFinanceV3Enabled={isFinanceV3Enabled}
      isProcessing={isProcessing}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    />
  </ModalBox>
)

export default DownloadRegisterModalView
