import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { Form } from 'components'

import i18n from 'translations'

export const FILE_DETAILS_FORM = 'FileDetailsForm'

const FileDetailsForm = ({
  handleSubmit,
  isProcessing,
  onCloseClick,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row label={i18n.t('module:Modals:FileDetailsModal:fileName')}>
      <Form.Row.FlexItem>
        <Field
          component={Form.TextField}
          disabled={isProcessing}
          name="name"
          placeholder={i18n.t('module:Modals:FileDetailsModal:fileName')}
          validate={isRequired}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.Row label={i18n.t('global:Description')}>
      <Form.Row.FlexItem>
        <Field
          component={Form.TextAreaField}
          disabled={isProcessing}
          maxCharacters={350}
          name="description"
          placeholder={i18n.t('module:Modals:FileDetailsModal:enterDescription')}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.FooterActions
      isSubmitting={isProcessing}
      submitLabel={i18n.t('global:Update')}
      onCancelClick={onCloseClick}
    />
  </Form>
)

export default reduxForm({ form: FILE_DETAILS_FORM })(FileDetailsForm)
