import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_DEPOSITS_STATISTICS,
  DEPOSITS_STATISTICS,
  DEPOSITS_STATISTICS_FAILED,
  DEPOSITS_STATISTICS_SUCCESS,
} from './constants'

export default (...props) => {
  const [state = getBasicSingleInitialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_DEPOSITS_STATISTICS,
    itemFailed: DEPOSITS_STATISTICS_FAILED,
    itemInit: DEPOSITS_STATISTICS,
    itemSuccess: DEPOSITS_STATISTICS_SUCCESS,
  }, getBasicSingleInitialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
