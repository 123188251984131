import React from 'react'

import { Banner, Callout, ModalBox, Toolbar } from 'components'
import { ChildFundingAllocatedWidget } from 'module/Children/Child/ChildBookingPattern/ChildFunding/components'

import i18n from 'translations'

import AddInvoiceItemModalForm from './components/AddInvoiceItemModalForm'

const AddInvoiceItemModalView = ({
  childAdhocSessionOptions,
  childFundingOptions,
  childId,
  errorMessages,
  extraItem,
  funding,
  includeUsedDepositIds,
  initialValues,
  invoicesSelectors,
  isItemEdit,
  isReadOnly,
  itemType,
  lineItemOptions,
  onCancelClick,
  onSubmit,
  skipPendingDepositIds,
}) => {
  const title = isItemEdit
    ? i18n.t('module:Modals:Invoice:AddInvoiceItemModal:editTitle')
    : i18n.t('module:Modals:Invoice:AddInvoiceItemModal:addTitle')
  const { amountUsed, hoursUsed, totalAmount, totalHours } = funding || {}

  const isExtraSessionSelected = invoicesSelectors.isExtraSessionSelected(itemType)
  const isExtraItemSelected = invoicesSelectors.isExtraItemSelected(itemType)
  const isFundingSelected = invoicesSelectors.isFundingSelected(itemType)
  const isLegacyItemSelected = invoicesSelectors.isLegacyItemSelected(itemType)
  const isFundingDeficit = invoicesSelectors.isFundingDeficit(itemType)
  const isDepositSelected = invoicesSelectors.isDepositSelected(itemType)
  const isRefundDepositSelected = invoicesSelectors.isRefundDepositSelected(itemType)
  const showCost = itemType?.showCost

  return (
    <ModalBox
      scrollToBody={false}
      title={title}
      iosPolyfill
      onCloseClick={onCancelClick}
    >
      {isReadOnly && (
        <Banner.Info margin="0 0 20px">
          {i18n.t('module:Modals:Invoice:AddInvoiceItemModal:readOnlyBannerText')}
        </Banner.Info>
      )}
      <Callout content={errorMessages} error />
      <Toolbar>
        {isFundingSelected && !isFundingDeficit && (
          <Toolbar.Group>
            <Toolbar.Item>
              <ChildFundingAllocatedWidget
                amountUsed={amountUsed}
                hoursUsed={hoursUsed}
                totalAmount={totalAmount}
                totalHours={totalHours}
              />
            </Toolbar.Item>
          </Toolbar.Group>
        )}
      </Toolbar>
      <AddInvoiceItemModalForm
        childAdhocSessionOptions={childAdhocSessionOptions}
        childFundingOptions={childFundingOptions}
        childId={childId}
        extraItem={extraItem}
        includeUsedDepositIds={includeUsedDepositIds}
        initialValues={initialValues}
        isDepositSelected={isDepositSelected}
        isExtraItemSelected={isExtraItemSelected}
        isExtraSessionSelected={isExtraSessionSelected}
        isFundingDeficit={isFundingDeficit}
        isFundingSelected={isFundingSelected}
        isItemEdit={isItemEdit}
        isLegacyItemSelected={isLegacyItemSelected}
        isReadOnly={isReadOnly}
        isRefundDepositSelected={isRefundDepositSelected}
        lineItemOptions={lineItemOptions}
        showCost={showCost}
        skipPendingDepositIds={skipPendingDepositIds}
        onCancelClick={onCancelClick}
        onSubmit={onSubmit}
      />
    </ModalBox>
  )
}

export default AddInvoiceItemModalView
