import {
  CLEAR_NEWSLETTERS_SINGLE,
  CREATE_NEWSLETTERS,
  CREATE_NEWSLETTERS_FAILED,
  CREATE_NEWSLETTERS_SUCCESS,
  GET_NEWSLETTERS,
  GET_NEWSLETTERS_FAILED,
  GET_NEWSLETTERS_SUCCESS,
  REMOVE_NEWSLETTERS,
  REMOVE_NEWSLETTERS_FAILED,
  REMOVE_NEWSLETTERS_SUCCESS,
  UPDATE_NEWSLETTERS,
  UPDATE_NEWSLETTERS_FAILED,
  UPDATE_NEWSLETTERS_SUCCESS,
} from './constants'

export const create = (body, params, onSuccess = () => {}, onFailed = () => {}) => ({
  newslettersApiClient,
}) => (dispatch) => {
  dispatch({
    type: CREATE_NEWSLETTERS,
  })

  newslettersApiClient.create(body, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: CREATE_NEWSLETTERS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: CREATE_NEWSLETTERS_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const get = (id, params, onSuccess = () => {}) => ({ newslettersApiClient }) => (dispatch) => {
  dispatch({
    type: GET_NEWSLETTERS,
  })

  newslettersApiClient.get(id, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: GET_NEWSLETTERS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => dispatch({
      error: response,
      type: GET_NEWSLETTERS_FAILED,
    }),
  )
}

export const update = (id, payload, params, onSuccess = () => {}, onFailed = () => {}) => ({
  newslettersApiClient,
}) => (dispatch) => {
  dispatch({
    id,
    params,
    payload,
    type: UPDATE_NEWSLETTERS,
  })

  newslettersApiClient.update(id, payload, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: UPDATE_NEWSLETTERS_SUCCESS,
      })

      onSuccess()
    },
    (error) => {
      dispatch({
        error: error.response,
        type: UPDATE_NEWSLETTERS_FAILED,
      })

      if (onFailed) {
        onFailed(onFailed)
      }
    },
  )
}

export const remove = (id, onSuccess = () => {}, onFailed = () => {}) => ({
  newslettersApiClient,
}) => (dispatch) => {
  dispatch({
    id,
    type: REMOVE_NEWSLETTERS,
  })

  newslettersApiClient.update(id, { archived: true }).then(
    (data) => {
      dispatch({
        payload: data,
        type: REMOVE_NEWSLETTERS_SUCCESS,
      })

      onSuccess()
    },
    (error) => {
      dispatch({
        error: error.response,
        type: REMOVE_NEWSLETTERS_FAILED,
      })

      onFailed()
    },
  )
}

export const clearSingle = () => ({
  type: CLEAR_NEWSLETTERS_SINGLE,
})
