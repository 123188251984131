import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'
import selectors from './selectors'
import constants from './constants'
import { NurseriesSingleState } from './single/reducer'
import { NurseriesListState } from './list/reducer'

export interface withNurseriesServiceProps {
  nurseriesActions: typeof actions
  nurseriesListState: NurseriesListState
  nurseriesSelectors: typeof selectors
  nurseriesSingleState: NurseriesSingleState
}

const withNurseriesService = <P extends withNurseriesServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ nurseries }) => ({ nurseries })

  const mapDispatch = injectActions('nurseriesActions', actions)

  const Component = ({ nurseries, nurseriesActions, ...other }) => (
    <WrappedComponent
      nurseriesActions={nurseriesActions}
      nurseriesConstants={constants}
      nurseriesFilesState={nurseries.files}
      nurseriesListState={nurseries.list}
      nurseriesPublicState={nurseries.publicNursery}
      nurseriesRotaStatisticsState={nurseries.rotaStatistics}
      nurseriesSelectors={selectors}
      nurseriesSingleState={nurseries.single}
      {...other as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withNurseriesService
