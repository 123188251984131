import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

const ITEM_BACK_GROUND_COLOR = '#F7F7F7'

export const StyledItemContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ isSelected }) => (isSelected && `color: ${getBrandingColor('primary-color')};`)}
  background: ${({ isSelected }) => (isSelected ? getBrandingColor('primary-color-rgba-1') : ITEM_BACK_GROUND_COLOR)};
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
`

export const StyledContainer = styled.div`
  display: flex;
  overflow: auto;
  padding: 10px 0;
  
  & ${StyledItemContainer} ~ ${StyledItemContainer} {
    margin-left: 10px;
  }
`

export const StyledItemContentContainer = styled.div`
  padding-left: 10px;
`
