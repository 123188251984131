import styled from 'styled-components'

import layout from 'constants/layout'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledContainer = styled.ol`
  padding: 0;
  margin: 0 0 20px;
  list-style: none;
  cursor: default;
  display: flex;
  justify-content: space-between;
  
  @media(max-width: ${layout.mobileWidthResolution}px) {
    flex-direction: column;
  }
`

export const StyledListItem = styled.li`
  counter-increment: stages-indicator;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    text-align: center;
    display: flex;
    justify-content: center;
  }
`

interface StyledItemProps {
  $active: boolean
  $disabled: boolean
  $interactive: boolean
}

export const StyledItem = styled.div<StyledItemProps>`
  flex: auto;
  display: flex;
  align-items: center;
  padding: 20px;
  max-width: 205px;
  font-size: 18px;
  height: 100%;
  width: max-content;
  transform: translateY(-1px);
  cursor: pointer;
  transition: .2s background-color;

  @media screen and (max-width: ${layout.layoutWidth}px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
  }

  @media screen and (max-width: ${layout.mobileWidthResolution}px) {
    font-size: 14px;
  }

  &:not(:last-child) {
    border-right: 1px solid #DDD;
  }

  &:before {
    content: counter(stages-indicator);
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    border: 2px solid;
    font-weight: bold;
    width: 32px;
    height: 32px;
    margin-right: 15px;
    transition: .2s;

    @media screen and (max-width: ${layout.layoutWidth}px) {
      margin-right: 0;
      margin-bottom: 10px;
    }
    
    ${({ $active }) => $active && `
      color: ${getBrandingColor('primary-color-contrasted')};
      background-color: ${getBrandingColor('primary-color')};
      border-color: ${getBrandingColor('primary-color')};
    `}

    ${({ $active, $interactive }) => $interactive && !$active && `
      border-color: ${getBrandingColor('primary-color')};
      color: ${getBrandingColor('primary-color')};
    `}
  }

  ${({ $active }) => $active && `
    border-right-color: ${getBrandingColor('primary-color')};
  `}

  ${({ $interactive }) => $interactive && `
    cursor: pointer;
  `}

  ${({ $active, $interactive }) => $interactive && !$active && `
    &:hover {
      background-color: ${getBrandingColor('primary-color-rgba-1')};
    }
  `}

  ${({ $disabled }) => $disabled && `
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.4;
  `}

  ${({ $past }) => $past && `
    &:before {
      border-color: ${getBrandingColor('primary-color')};
      color: ${getBrandingColor('primary-color')};
    }
  `}

  &:hover {
    &:before {
      ${({ $active }) => !$active && `
        border-color: ${getBrandingColor('quinary-color')};
        background: ${getBrandingColor('quinary-color')};
        color: ${getBrandingColor('primary-color')};
      `}
    }
  }
`

interface StyledDividerProps {
  $active: boolean
}

export const StyledDivider = styled.hr<StyledDividerProps>`
  border: 0 none;
  background: none;
  height: 0;
  display: flex;
  align-self: center;
  width: 100%;
  position: relative;
  margin: 0;
  
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 3px;
    transform: translateY(-50%);
    background: ${NEUTRAL_COLOURS.SILVER};
  }

  ${({ $active }) => $active && `
    border-color: ${getBrandingColor('primary-color')};
  `}

  @media(max-width: ${layout.mobileWidthResolution}px) {
    width: 200px;
  }
`
