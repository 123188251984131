import _ from 'lodash'
import moment from 'moment'

import React, { useEffect, useState } from 'react'

import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'
import { FLAG_COLOURS } from 'constants/colors'
import { UPDATE_PROGRESS_EVENT, UPLOADED_FILE_STATUS } from 'services/legacy/upload/constants'
import ImagesSize from 'constants/imagesSize'

import { isImageMimeType, isVideoMimeType } from 'utils/attachment'
import { getImageExist, getOptimizedImage } from 'utils/image'
import eventBus from 'utils/eventBus'

import { Button, CircleIcon, DropdownMenu, Table, Typography } from 'components'

import i18n from 'translations'

import NameColumn from '../NameColumn'
import FileDetails from '../FileDetails'
import Description from '../Description'
import { StyledNoTaggedChildren } from './FileRowStyled'

const FileRow = ({
  childrenList,
  disableTagging,
  disabled,
  file,
  getTableColumns,
  hideEditOption,
  isOffline,
  noCloudUpload,
  onDescriptionChange,
  onDownloadFile,
  onEditFile,
  onLightBoxOpen,
  onRemoveFile,
  preview,
  uploadDateColumn,
}) => {
  const {
    beId,
    createdAt,
    description,
    id,
    isUploaded,
    itemFromBe,
    lambdaStatus,
    mimeType,
    size,
    taggedChildren,
  } = file
  const amountColumns = getTableColumns(disableTagging, preview, uploadDateColumn).length
  const isVideo = isVideoMimeType(mimeType)
  const isImage = isImageMimeType(mimeType)
  const isFailed = (
    (UPLOADED_FILE_STATUS.FAILED === lambdaStatus)
    || (isImage && (null === size || undefined === size) && itemFromBe)
  )

  const [fileExist, setFileExist] = useState(true)
  const [uploadedData, setUploadedData] = useState(0)

  useEffect(() => {
    const updateProgress = (e) => {
      if (id === e.detail.id) {
        setUploadedData(e.detail.uploadedData)
      }
    }

    eventBus.on(UPDATE_PROGRESS_EVENT, updateProgress)

    return () => {
      eventBus.remove(UPDATE_PROGRESS_EVENT, updateProgress)
    }
  }, [id])

  useEffect(() => {
    if (itemFromBe && isImage) {
      getImageExist(getOptimizedImage(file.file, ImagesSize.MEDIA_PICKER_THUMBNAIL))
        .then(null, () => {
          setFileExist(false)
        })
    }
  }, [file.file, file.lambdaStatus, isImage, isVideo, itemFromBe])

  const renderTaggingColumnContent = () => {
    if (taggedChildren?.length) {
      return _.map(taggedChildren, ({ firstName, id: childId, surname }, i) => {
        let finalFirstName = firstName
        let finalSurname = surname

        if (!firstName && !surname) {
          const child = _.find(childrenList, (item) => (
            item.id === childId
          ))

          finalFirstName = child?.firstName
          finalSurname = child?.surname
        }

        return (
          `${finalFirstName} ${finalSurname}${i !== taggedChildren.length - 1 ? ', ' : ' '}`
        )
      })
    }

    if (preview) {
      return (
        <StyledNoTaggedChildren>
          <CircleIcon
            background={FLAG_COLOURS.WARNING}
            icon="exclamation-mark"
            size={20}
          />
          <Typography fontSize={14}>
            {i18n.t('components:MediaPicker:noChildrenTagged')}
          </Typography>
        </StyledNoTaggedChildren>
      )
    }

    return (
      <Typography
        cursor={(!isUploaded || isFailed) ? 'not-allowed' : 'pointer'}
        opacity={(!isUploaded || isFailed) ? 0.6 : 1}
        bold
        primary
        onClick={() => !isFailed && isUploaded && onEditFile(file)}
      >
        {i18n.t('components:MediaPicker:tagChildren')}
      </Typography>
    )
  }

  const renderTaggingColumn = () => {
    if (disableTagging) {
      return null
    }

    return (
      <Table.Td align="left" noBorder={!!description} whiteSpace="pre-line">
        {renderTaggingColumnContent(file, isFailed)}
      </Table.Td>
    )
  }

  return (
    <React.Fragment key={id}>
      <Table.Tr>
        <Table.Td align="left" noBorder={!!description} whiteSpace="pre-line">
          <NameColumn
            file={file}
            noCloudUpload={noCloudUpload}
            onDownloadFile={onDownloadFile}
            onLightBoxOpen={onLightBoxOpen}
          />
        </Table.Td>
        {renderTaggingColumn(file, isFailed)}
        <Table.Td align="left" noBorder={!!description}>
          <FileDetails
            file={file}
            fileExist={isFailed ? false : fileExist}
            isOffline={isOffline}
            uploadedData={uploadedData}
          />
        </Table.Td>
        {uploadDateColumn && (
          <Table.Td align="left" noBorder={!!description}>
            {moment(createdAt).format(DISPLAY_SHORT_DATE_FORMAT)}
          </Table.Td>
        )}
        {!preview && (
          <Table.Td noBorder={!!description}>
            {isUploaded ? (
              <DropdownMenu
                disabled={disabled}
                zIndex={301}
              >
                {!isFailed && !hideEditOption && (
                  <DropdownMenu.Item
                    type="edit"
                    onClick={() => (onDescriptionChange ? onDescriptionChange(file) : onEditFile(file))}
                  />
                )}
                <DropdownMenu.Item
                  type="delete"
                  onClick={() => onRemoveFile(file)}
                />
              </DropdownMenu>
            ) : (
              <Button
                disabled={!beId && _.isString(id)}
                hierarchy="tertiary"
                icon="close"
                negativeMargins
                onClick={() => onRemoveFile(file)}
              />
            )}
          </Table.Td>
        )}
      </Table.Tr>
      {description && (
        <Table.Tr>
          <Table.Td align="left" colSpan={amountColumns - 1}>
            <Description content={description} />
          </Table.Td>
          <Table.Td />
        </Table.Tr>
      )}
    </React.Fragment>
  )
}

export default FileRow
