import { generateBasicActions } from 'utils/service'

import {
  CLEAR_LIST_OF_FRAMEWORK_PROGRESS,
  LIST_OF_FRAMEWORK_PROGRESS,
  LIST_OF_FRAMEWORK_PROGRESS_FAILED,
  LIST_OF_FRAMEWORK_PROGRESS_SUCCESS,
  REMOVE_PROGRESS_EVENT,
  REMOVE_PROGRESS_EVENT_FAILED,
  REMOVE_PROGRESS_EVENT_SUCCESS,
  UPDATE_BATCH_FRAMEWORK_PROGRESS,
  UPDATE_BATCH_FRAMEWORK_PROGRESS_FAILED,
  UPDATE_BATCH_FRAMEWORK_PROGRESS_SUCCESS,
  UPDATE_SINGLE_PROGRESS,
} from './constants'

export const listOfProgress = (options = {}) => ({ childFrameworkProgressApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: LIST_OF_FRAMEWORK_PROGRESS_FAILED,
      init: LIST_OF_FRAMEWORK_PROGRESS,
      success: LIST_OF_FRAMEWORK_PROGRESS_SUCCESS,
    },
    options,
    service: childFrameworkProgressApiClient,
    serviceMethod: 'listOfProgress',
  })
)

export const batchUpdate = (options = {}) => ({ childFrameworkProgressApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_BATCH_FRAMEWORK_PROGRESS_FAILED,
      init: UPDATE_BATCH_FRAMEWORK_PROGRESS,
      success: UPDATE_BATCH_FRAMEWORK_PROGRESS_SUCCESS,
    },
    options,
    service: childFrameworkProgressApiClient,
    serviceMethod: 'batchUpdate',
  })
)

export const removeEvent = (options = {}) => ({ childFrameworkProgressApiClient }) => (
  generateBasicActions.remove({
    constants: {
      failed: REMOVE_PROGRESS_EVENT_FAILED,
      init: REMOVE_PROGRESS_EVENT,
      success: REMOVE_PROGRESS_EVENT_SUCCESS,
    },
    options,
    service: childFrameworkProgressApiClient,
    serviceMethod: 'removeEvent',
  })
)

export const updateSingleProgress = (payload) => ({
  payload,
  type: UPDATE_SINGLE_PROGRESS,
})

export const clear = () => ({
  type: CLEAR_LIST_OF_FRAMEWORK_PROGRESS,
})
