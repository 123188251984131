import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { FEATURE_FLAGS, ROLES } from 'constants/security'

import { generateRoute } from 'utils/routing'

import { EVENTS, logEvent } from 'analytics'

import { withAppService } from 'services/app'
import { withMembershipRegistersService } from 'services/legacy/membershipRegisters'
import { withModalService } from 'services/utils/modal'
import { withNurseriesService } from 'services/nurseries'
import { withPaginationUtils } from 'services/utils/pagination'

import StaffWorklogView from './StaffWorklogView'

const STAFF_WORK_LOG_GROUPS = {
  read: [
    'membershipRegister.worklog',
    'membership.currentContract',
    'membership.details',
    'membership.shifts',
    'membershipShift',
    'membershipShift.membershipShiftTimes',
    'membershipShiftTime',
    'membershipRegisterEntry.nurseryClass',
    'membershipShift.membershipShiftTimeStatistics',
    'membershipShift.leaveShiftType',
    'leaveShiftType',
    'nurseryClass',
    'contract',
  ],
}

const NURSERY_SETTINGS_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings',
  ],
}

class StaffWorklogContainer extends Component {
  constructor(props) {
    super(props)

    const { location, paginationUtils } = props
    const { setPageLocationQuery } = paginationUtils
    const { search, week } = location.query

    this.state = {
      filters: {
        search,
        week: week
          ? moment(week, DEFAULT_DATE_FORMAT).startOf('week')
          : moment().startOf('week'),
      },
    }

    setPageLocationQuery(false)
  }

  componentDidMount() {
    const { nurseriesActions, nurseryOptions } = this.props

    nurseriesActions.get(nurseryOptions.id, {
      params: {
        groups: NURSERY_SETTINGS_GROUPS,
      },
    })

    this.fetch()

    logEvent(EVENTS.STAFF_WORKLOG_PAGE_VIEWED)
  }

  componentWillUnmount() {
    const { membershipRegistersActions } = this.props

    membershipRegistersActions.clearList()
  }

  fetch = () => {
    const {
      membershipRegistersActions,
      membershipRegistersSelectors,
      paginationUtils: { page },
      setLocationQuery,
    } = this.props
    const { filters } = this.state
    const { search, week } = filters

    setLocationQuery({
      search,
      week: week ? moment(week).format(DEFAULT_DATE_FORMAT) : null,
    })

    const startDate = moment(week)
    const endDate = moment(week).add('d', 6)

    const criteria = membershipRegistersSelectors.getMembershipRegistersListCriteria({
      endDate,
      searchBarText: search,
      startDate,
    })

    return membershipRegistersActions.list({
      mergeResult: 1 !== page,
      params: {
        criteria,
        groups: STAFF_WORK_LOG_GROUPS,
        page,
      },
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleSearchChange = (search) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        search,
      },
    }), () => onPageChange(this.fetch)(1))

    logEvent(EVENTS.STAFF_WORKLOG_FILTER_USED, { type: 'search' })
  }

  handleWeekChange = (week) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        week: week[0],
      },
    }), onPageChange(this.fetch)(1))

    logEvent(EVENTS.STAFF_WORKLOG_FILTER_USED, { type: 'week' })
  }

  handleExportClick = () => {
    const { modalActions, modalConsts } = this.props

    logEvent(EVENTS.STAFF_WORKLOG_EXPORT_CLICKED)

    modalActions.show(modalConsts.TYPES.STAFF_WORKLOG_EXPORT)
  }

  handleTableCellClick = ({
    endDate,
    membershipId,
    startDate,
  }) => {
    const { navigate } = this.props

    navigate(generateRoute('STAFF.WORKLOG_PREVIEW', { endDate, membershipId, startDate }))
  }

  render() {
    const { isFetching, nurseryData, paginationUtils, records, totalResults } = this.props
    const { filters } = this.state
    const { search, week } = filters
    const { getPageCount, page } = paginationUtils
    const dateRange = [week, moment(week).endOf('week')]
    const pageCount = getPageCount(totalResults)

    return (
      <StaffWorklogView
        dateRange={dateRange}
        isFetching={isFetching}
        nurseryData={nurseryData}
        page={page}
        pageCount={pageCount}
        records={records}
        search={search}
        totalResults={totalResults}
        week={week}
        onExportClick={this.handleExportClick}
        onPageChange={this.handlePageChange}
        onSearchChange={this.handleSearchChange}
        onTableCellClick={this.handleTableCellClick}
        onWeekChange={this.handleWeekChange}
      />
    )
  }
}

StaffWorklogContainer.authParams = {
  flags: [FEATURE_FLAGS.STAFF_REGISTER],
  nurseryContext: true,
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

const mapState = (state, {
  appSelectors,
  membershipRegistersListState,
  membershipRegistersSelectors,
  nurseriesSelectors,
  nurseriesSingleState,
  params,
}) => ({
  isFetching: appSelectors.getIsFetching(membershipRegistersListState, nurseriesSingleState),
  nurseryData: nurseriesSelectors.getNurseryData(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  records: membershipRegistersSelectors.getMembershipRegistersWorklogListSelector(state),
  totalResults: appSelectors.getTotalResults(membershipRegistersListState),
})

const enhance = compose(
  withAppService,
  withModalService,
  withMembershipRegistersService,
  withNurseriesService,
  withPaginationUtils,
  connect(mapState),
)

export default enhance(StaffWorklogContainer)
