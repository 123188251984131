import _ from 'lodash'

import React, { useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'

import { RootState } from 'core/reducers'
import { Nullable, Option } from 'constants/models'
import { WrappedComponentType } from 'constants/types'
import { NURSERY_SESSIONS_FILTER, NURSERY_SESSIONS_FILTER_OPTIONS } from 'services/nurserySessions/constants'
import { withRouterProps } from 'services/router/constants'
import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'

import { withAppService, withAppServiceProps } from 'services/app'
import { withNurserySessionsService, withNurserySessionsServiceProps } from 'services/nurserySessions'
import { withPaginationUtils, withPaginationUtilsProps } from 'services/utils/pagination'
import { withRouteUtilsProps, withRouterUtils } from 'services/utils/router'
import { withSortUtilsProps, withSortingUtils } from 'services/utils/sorting'

import { getSessionsTableData } from './SessionsV2Helpers'
import SessionsV2View from './SessionsV2View'

const SESSIONS_GROUPS = {
  read: [
    'nurserySession.nurseryConsumablesExtraItems',
    'nurserySession.organizationSession',
  ],
}

type SessionsV2ContainerProps = withNurserySessionsServiceProps
  & withAppServiceProps
  & withRouteUtilsProps
  & withSortUtilsProps
  & withPaginationUtilsProps
  & withRouterProps

const mapState = (state: RootState, { appSelectors, nurserySessionsListState, params }: SessionsV2ContainerProps) => ({
  FinanceAutomationGranted: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.FINANCE_AUTOMATION],
  }),
  errorMessages: appSelectors.getErrorMessages(nurserySessionsListState),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  totalResults: appSelectors.getTotalResults(nurserySessionsListState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const SessionsV2Container: WrappedComponentType<SessionsV2ContainerProps & PropsFromRedux> = ({
  FinanceAutomationGranted,
  errorMessages,
  location,
  nurseryOptions,
  nurserySessionsActions,
  nurserySessionsListState,
  nurserySessionsSelectors,
  paginationUtils,
  setLocationQuery,
  sortingUtils,
  totalResults,
}) => {
  const { getPaginationDetails, onPageChange, page } = paginationUtils
  const { onSortChange, sortField, sortOrder } = sortingUtils
  const { query } = location

  const [statusFilter, setStatusFilter] = useState<Nullable<Option>>(() => _.find(NURSERY_SESSIONS_FILTER_OPTIONS, {
    value: query.statusFilter || NURSERY_SESSIONS_FILTER.ACTIVE,
  }))

  const fetch = () => {
    const criteria = nurserySessionsSelectors.getCriteria({ statusFilter: statusFilter?.value })

    setLocationQuery({ statusFilter: statusFilter.value })
    nurserySessionsActions.list({
      params: {
        criteria,
        groups: SESSIONS_GROUPS,
        order: sortField ? {
          sortField,
          sortOrder,
        } : undefined,
        page,
      },
    })
  }

  useEffect(() => {
    fetch()
  }, [statusFilter, page])

  const handleStatusFilterChange = (newStatusFilter) => {
    setStatusFilter(newStatusFilter)
  }

  const handleSortChange = (key) => {
    onSortChange(onPageChange()(1))(key)
  }

  const routePrefix = nurseryOptions.prefix
  const sessionsTableData = getSessionsTableData(nurserySessionsListState.data, routePrefix)
  const isLoading = nurserySessionsListState.isFetching || !nurserySessionsListState.data
  const isEmpty = !nurserySessionsListState?.data?.length && !statusFilter

  return (
    <SessionsV2View
      FinanceAutomationGranted={FinanceAutomationGranted}
      errorMessages={errorMessages}
      isEmpty={isEmpty}
      isLoading={isLoading}
      pagination={getPaginationDetails(totalResults)}
      routePrefix={routePrefix}
      sessions={sessionsTableData}
      sortField={sortField}
      sortOrder={sortOrder}
      statusFilter={statusFilter}
      onSortChange={handleSortChange}
      onStatusFilterChange={handleStatusFilterChange}
    />
  )
}

SessionsV2Container.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

const enhance = compose(
  withAppService,
  withNurserySessionsService,
  withRouterUtils,
  withPaginationUtils,
  withSortingUtils,
  connector,
)

export default enhance(SessionsV2Container)
