import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledDivider = styled.hr`
  border: 0 none;
  border-top: 1px dashed ${NEUTRAL_COLOURS.SILVER};
  background: none;
  height: 0;
  margin: 20px 0;
`
