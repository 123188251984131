import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'
import { formValueSelector, getFormInitialValues } from 'redux-form'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { hasFutureConfirmedRoomMovesSelector } from '../list/selectors'

const getChildMoves = (state) => state.childMoves

export const getChildMovesSingleSelector = createSelector(
  [getChildMoves],
  (state) => {
    if (!state) {
      return null
    }

    return state.single
  },
)

export const getChildMovesSingleIsFetchingSelector = createSelector(
  [getChildMovesSingleSelector],
  ({ isFetching }) => isFetching,
)

export const getChildMovesSingleDataSelector = createSelector(
  [getChildMovesSingleSelector],
  (single) => single && single.data,
)

export const getChildMoveValuesForm = (values) => {
  const { assignedTeachers, moveOn, toNurseryClass } = values
  const body = {}

  if (assignedTeachers && assignedTeachers.length) {
    body.assignedTeachers = _.map(assignedTeachers, ({ value }) => ({
      id: value,
    }))
  } else {
    body.assignedTeachers = []
  }

  if (moveOn) {
    body.moveOn = moment(moveOn).format(DEFAULT_DATE_FORMAT)
  } else {
    body.moveOn = 0
  }

  if (toNurseryClass && toNurseryClass.value) {
    body.toNurseryClass = {
      id: toNurseryClass.value,
    }
  }

  if (toNurseryClass && 0 === toNurseryClass.value) {
    body.toLeaving = true
  }

  return body
}

const getMoveOnCurrentValue = (FORM_NAME) => (state) => formValueSelector(FORM_NAME)(state, 'moveOn')
const getInitialValues = (FORM_NAME) => (state) => getFormInitialValues(FORM_NAME)(state)

export const getWarningVisibilityFlag = (childMoveId, FORM_NAME) => createSelector(
  [
    hasFutureConfirmedRoomMovesSelector(childMoveId),
    getInitialValues(FORM_NAME),
    getMoveOnCurrentValue(FORM_NAME),
  ],
  (hasFutureConfirmedRoomMoves, initialValues, currentMoveOn) => {
    if (!hasFutureConfirmedRoomMoves || !initialValues) {
      return false
    }

    const { moveOn: initialMoveOn } = initialValues

    return moment(initialMoveOn).format('YYYY-MM-DD') !== moment(currentMoveOn).format('YYYY-MM-DD')
  },
)
