import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { generateRoute } from 'utils/routing'

import { EVENTS, logEvent } from 'analytics'

import { withAppService } from 'services/app'

import i18n from 'translations'

import withStaffRegisterWrapper from '../withStaffRegisterWrapper'
import ChooseNewPinView from './ChooseNewPinView'

class ChooseNewPinContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formError: null,
      isLoading: false,
      pin: {
        first: null,
        second: null,
      },
    }
  }

  redirect = () => {
    const { navigate } = this.props

    navigate(generateRoute('STAFF_REGISTER.LIST'))
  }

  redirectSuccess = () => {
    const { navigate } = this.props

    logEvent(EVENTS.STAFF_REGISTER_PIN_CREATED)

    if (this.isCreatePinPage()) {
      navigate(generateRoute('STAFF_REGISTER.CREATE_PIN.CHOOSE_NEW_PIN.SUCCESS'))
    } else {
      navigate(generateRoute('STAFF_REGISTER.FORGOT_PIN.CHOOSE_NEW_PIN.SUCCESS'))
    }
  }

  isCreatePinPage = () => {
    const { location: { pathname } } = this.props

    return pathname === generateRoute('STAFF_REGISTER.CREATE_PIN.CHOOSE_NEW_PIN')
  }

  isConfirmPage = () => {
    const { pin: { first } } = this.state

    return !_.isNull(first)
  }

  handleSubmit = (pin, { reset }) => {
    const {
      authActions,
      authToken,
    } = this.props
    const { pin: { first, second } } = this.state

    if (_.isNull(first)) {
      this.setState({ isLoading: true })

      return setTimeout(() => {
        reset()

        this.setState({
          isLoading: false,
          pin: {
            first: pin,
            second: null,
          },
        })
      }, 500)
    }

    if (!_.isNull(second)) {
      return null
    }

    if (first !== pin) {
      return setTimeout(() => {
        this.setState({
          formError: i18n.t('module:StaffRegister:ChooseNewPin:pinNotMatch'),
        })
        reset()
      })
    }

    return authActions.createPin({ plainPin: pin }, authToken, {
      onSuccess: this.redirectSuccess,
    })
  }

  handleBackButtonClick = () => {
    const { clearStoreAndRedirectToStaffList } = this.props

    clearStoreAndRedirectToStaffList(this.redirect)
  }

  render() {
    const { isAuthFetching, user } = this.props
    const { formError, isLoading } = this.state

    return (
      <ChooseNewPinView
        formError={formError}
        isConfirmPage={this.isConfirmPage()}
        isCreatePinPage={this.isCreatePinPage()}
        isLoading={isAuthFetching || isLoading}
        user={user}
        onBackButtonClick={this.handleBackButtonClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  authPinAuthenticationState,
  authSelectors,
}) => ({
  authToken: authSelectors.getAuthRegisterToken(state),
  isAuthFetching: appSelectors.getIsFetching(authPinAuthenticationState),
  user: authSelectors.getAuthRegisterProfileUser(state),
})

const enhance = compose(
  withAppService,
  withStaffRegisterWrapper,
  connect(mapState),
)

export default enhance(ChooseNewPinContainer)
