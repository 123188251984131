import React, { Component } from 'react'
import { compose } from 'recompose'

import { withAppService } from 'services/app'
import { withEnquirySettingsService } from 'services/legacy/enquiries'

import { Select } from 'components'

import withInfiniteDropdown from './withInfiniteDropdown'

class PublicEnquirySourceDropdown extends Component {
  handleLoadMoreElements = async (phrase) => {
    const {
      enquirySettingsActions,
      enquirySettingsSelectors,
      infiniteDropdownHelpers,
      limit,
      token,
    } = this.props

    return infiniteDropdownHelpers.handleLoadMoreElements({
      listAction: ({ newPage, newSearchPhrase, result }) => enquirySettingsActions.dropdownListPublicEnquirySource({
        onSuccess: (response) => infiniteDropdownHelpers.handleLoadMoreElementsOnSuccess({
          newPage,
          newSearchPhrase,
          response,
          result,
        }),
        params: {
          criteria: enquirySettingsSelectors.getCriteria({ name: newSearchPhrase }),
          limit,
          order: {
            sortField: 'name',
            sortOrder: 'ASC',
          },
          page: newPage,
          token,
        },
      }),
      phrase,
    })
  }

  handleChange = (e) => {
    const { infiniteDropdownHelpers, onChange } = this.props

    infiniteDropdownHelpers.handleOnChange(e, () => onChange(e))
  }

  render() {
    const {
      disabled,
      infiniteDropdownState,
      placeholder,
      rawValue,
      value,
      ...rest
    } = this.props
    const { cacheUniq, options, selectedValue } = infiniteDropdownState

    return (
      <Select
        {...rest}
        cacheUniq={cacheUniq}
        disabled={disabled}
        loadOptions={this.handleLoadMoreElements}
        options={options}
        placeholder={placeholder}
        rawValue={rawValue || selectedValue || value}
        value={value}
        searchable
        onChange={this.handleChange}
      />
    )
  }
}

const enhance = compose(
  withAppService,
  withEnquirySettingsService,
  withInfiniteDropdown,
)

export default enhance(PublicEnquirySourceDropdown)
