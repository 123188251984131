import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { Form } from 'components'

import i18n from 'translations'

import { StyledForm } from './ExportReportFormStyled'
import { ALL_KEY_PERSONS } from '../Constants'

export const EXPORT_REPORT_FORM = 'ExportReportForm'

const ExportReportForm = ({
  handleSubmit,
  includeDeputyManager,
  isProcessing,
  keyWorkers,
  onCloseClick,
  onSubmit,
  userLabel,
}) => {
  const renderKeyWorkerField = () => (
    <Form.Row
      label={i18n.t('module:Modals:ExportReportModal:KeyWorker:label')}
      required
      verticalLabel
    >
      <Form.Row.Item>
        <Field
          component={Form.InfiniteDropdowns.Users}
          extraOptions={[ALL_KEY_PERSONS]}
          name="keyWorker"
          placeholder={i18n.t('module:Modals:ExportReportModal:KeyWorker:placeholder')}
          validate={isRequired}
        />
      </Form.Row.Item>
    </Form.Row>
  )

  return (
    <StyledForm>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {keyWorkers && renderKeyWorkerField()}
        <Form.Row
          label={userLabel}
          verticalLabel
        >
          <Field
            component={Form.InfiniteDropdowns.OrganizationMemberships}
            includeDeputyManager={includeDeputyManager}
            name="membership"
            placeholder={userLabel}
            validate={isRequired}
          />
        </Form.Row>
        <Form.FooterActions
          isSubmitting={isProcessing}
          submitLabel={i18n.t('global:Export')}
          onCancelClick={onCloseClick}
        />
      </Form>
    </StyledForm>
  )
}

export default reduxForm({ form: EXPORT_REPORT_FORM })(ExportReportForm)

