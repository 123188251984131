import _ from 'lodash'

import React, { useEffect } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { DetailsBreakdown, Typography } from 'components'

import { withAppService } from 'services/app'
import { withFrameworksService } from 'services/legacy/frameworks'
import { withSecurityService } from 'services/security'
import { withNurseryFrameworkRelationsService } from 'services/legacy/nurseryFrameworkRelations'

import i18n from 'translations'

const FrameworkSection = ({
  frameworks,
  frameworksActions,
  frameworksRelations,
  isAdministrationContext,
}) => {
  useEffect(() => {
    frameworksActions.legacyList()
  }, [frameworksActions])

  let finalFrameworks = frameworks

  if (isAdministrationContext) {
    finalFrameworks = _.map(frameworks, (framework) => ({
      ...framework,
      enabled: !!_.find(
        frameworksRelations,
        (relation) => (relation?.framework?.id || relation?.id) === framework?.id,
      ),
    }))
  }

  const renderFrameworksFields = () => _.map(finalFrameworks, (framework) => (
    <DetailsBreakdown.Item
      label={framework.name}
      proportion="4fr 1fr"
      value={(
        <Typography align="right">
          {framework.enabled
            ? i18n.t('global:On')
            : i18n.t('global:Off')}
        </Typography>
      )}
    />
  ))

  return (
    <DetailsBreakdown.Section>
      <DetailsBreakdown.Header>
        <Typography fontSize={20}>
          {_.upperFirst(i18n.t('global:frameworks'))}
        </Typography>
      </DetailsBreakdown.Header>
      {renderFrameworksFields()}
    </DetailsBreakdown.Section>
  )
}

const mapState = (state, {
  appSelectors,
  frameworksLegacyListState,
  frameworksSelectors,
  nurseryFrameworkRelationsSelectors,
  securitySelectors,
}) => ({
  frameworks: frameworksSelectors.getFrameworksLegacyListWithEnabledFlag(state),
  frameworksRelations: nurseryFrameworkRelationsSelectors.getNurseryFrameworkRelationsListDataState(state),
  isAdministrationContext: securitySelectors.isAdministrationContext(state),
  isFetching: appSelectors.getIsFetching(frameworksLegacyListState),
})

const enhance = compose(
  withAppService,
  withFrameworksService,
  withSecurityService,
  withNurseryFrameworkRelationsService,
  connect(mapState),
)

export default enhance(FrameworkSection)
