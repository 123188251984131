import _ from 'lodash'

import { LOCATION_CHANGE } from 'react-router-redux'

import { BasicShellSettings, SubMenuItem } from 'services/shell/constants'

import {
  CLEAR_SUBMENU,
  HIDE_SUBMENU,
  SET_ROUTE_TITLE,
  SET_SUBMENU,
  SHELL_SET_SETTINGS,
  SHOW_SUBMENU,
  STICKY_FOOTER_MOUNTED,
  STICKY_FOOTER_UNMOUNTED,
} from './constants'

export interface ShellCommonState extends BasicShellSettings {
  /**
   * Object with list of menu elements viewed on <SubMenuWrapper />
   */
  subMenu: SubMenuItem[] | null
}

const initialState = {
  currency: null,
  hideSubMenu: false,
  locale: null,
  minimal: false,
  routeTitles: {},
  stickyFooterMounted: false,
  subMenu: null,
  suppressDeviceWarning: false,
}

export default (state: ShellCommonState = initialState, { payload, type }) => {
  switch (type) {
    case SET_SUBMENU: {
      return {
        ...state,
        subMenu: _.filter(payload, ({ hidden, premium }) => !hidden || premium),
      }
    }
    case CLEAR_SUBMENU: {
      return { ...state, subMenu: null }
    }
    case SHELL_SET_SETTINGS: {
      return { ...state, ...payload }
    }
    case SET_ROUTE_TITLE: {
      const { name, title } = payload

      return {
        ...state,
        routeTitles: {
          ...state.routeTitles,
          [name]: title,
        },
      }
    }
    case LOCATION_CHANGE: {
      return {
        currency: state.currency,
        hideSubMenu: state.hideSubMenu,
        locale: state.locale,
        routeTitles: state.routeTitles,
        stickyFooterMounted: state.stickyFooterMounted,
        subMenu: state.subMenu,
      }
    }
    case STICKY_FOOTER_MOUNTED: {
      return {
        ...state,
        stickyFooterMounted: true,
      }
    }
    case STICKY_FOOTER_UNMOUNTED: {
      return {
        ...state,
        stickyFooterMounted: false,
      }
    }
    case HIDE_SUBMENU: {
      return {
        ...state,
        hideSubMenu: true,
      }
    }
    case SHOW_SUBMENU: {
      return {
        ...state,
        hideSubMenu: false,
      }
    }
    default: {
      return state
    }
  }
}
