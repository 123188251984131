import {
  CLEAR_DAILY_DIARY_FOODS,
  GET_DAILY_DIARY_FOODS,
  GET_DAILY_DIARY_FOODS_FAILED,
  GET_DAILY_DIARY_FOODS_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ dailyDiaryFoodsApiClient }) => (dispatch) => {
  const { mergeResult, onSuccess, params, silent } = options

  dispatch({
    silent,
    type: GET_DAILY_DIARY_FOODS,
  })

  dailyDiaryFoodsApiClient.list(params).then(
    (response) => {
      dispatch({
        mergeResult,
        payload: response,
        type: GET_DAILY_DIARY_FOODS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: GET_DAILY_DIARY_FOODS_FAILED,
      })
    },
  )
}

export const clearList = () => ({
  type: CLEAR_DAILY_DIARY_FOODS,
})
