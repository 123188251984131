import React from 'react'

import { Button, FooterActions, Tooltip } from 'components'

import i18n from 'translations'

interface FormFooterActionsProps {
  cancelLabel?: string
  cancelLink?: string
  isSubmitting?: boolean
  noBorder?: boolean
  noMargin?: boolean
  noPadding?: boolean
  onCancelClick?: () => void
  onSubmitClick?: (fields: any) => void
  secondary?: boolean
  submitAuth?: boolean
  submitDisabled?: boolean
  submitLabel?: string
  submitTooltip?: string
}

const FormFooterActions: React.FC<FormFooterActionsProps> = ({
  cancelLabel = i18n.t('global:Cancel'),
  cancelLink,
  isSubmitting,
  noBorder,
  noMargin,
  noPadding,
  secondary,
  submitAuth,
  submitDisabled,
  submitLabel = i18n.t('global:Submit'),
  submitTooltip,
  onCancelClick,
  onSubmitClick,
}: any) => (
  <FooterActions
    noBorder={noBorder}
    noMargin={noMargin}
    noPadding={noPadding}
    spaceBetween
  >
    <FooterActions.Group>
      <FooterActions.Item>
        <Button
          disabled={isSubmitting}
          hierarchy="tertiary"
          label={cancelLabel}
          to={cancelLink}
          negativeMargins
          onClick={onCancelClick}
        />
      </FooterActions.Item>
    </FooterActions.Group>
    <FooterActions.Group>
      {secondary && false !== secondary?.auth && (
        <FooterActions.Item>
          <Tooltip
            placement="top"
            title={secondary.tooltip}
          >
            <Button
              component={secondary.disabled ? 'div' : undefined}
              hierarchy="secondary"
              negativeMargins
              {...secondary}
            />
          </Tooltip>
        </FooterActions.Item>
      )}
      {false !== submitAuth && (
        <FooterActions.Item>
          <Tooltip
            placement="top"
            title={submitTooltip}
          >
            <Button
              component={submitDisabled ? 'div' : undefined}
              disabled={submitDisabled}
              isLoading={isSubmitting}
              label={submitLabel}
              submit={!onSubmitClick}
              negativeMargins
              onClick={onSubmitClick}
            />
          </Tooltip>
        </FooterActions.Item>
      )}
    </FooterActions.Group>
  </FooterActions>
)

export default FormFooterActions
