import { DAY_RANGE, MONTH_RANGE, WEEK_RANGE } from 'module/Modals/Staff/constants'

import { BREAKDOWN } from './constants'

export const getReportDatesOptions = (breakdown) => {
  switch (breakdown) {
    case BREAKDOWN.DAILY:
    case BREAKDOWN.TOTAL:
    case BREAKDOWN.PAYROLL:
      return [
        MONTH_RANGE,
        DAY_RANGE,
      ]
    case BREAKDOWN.WEEKLY:
      return [
        MONTH_RANGE,
        WEEK_RANGE,
      ]
    default:
      return []
  }
}
