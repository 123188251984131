import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledContainer = styled.section`
  display: grid;
  grid-gap: 20px;
`

export const StyledItemContainer = styled.article`
  display: grid;
  grid-gap: 20px;
  grid-template-areas: 'icon main date action' 'empty note note note';
  grid-template-columns: auto 1fr auto 20px;
  background-color: ${NEUTRAL_COLOURS.WHITE};
  border-radius: 4px;
  padding: ${({ isInlineEditMode }) => (isInlineEditMode ? '30px 25px 10px' : '30px 25px')};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .1);

  ${({ hideNote }) => hideNote && `
    grid-template-areas: "icon main date action";
  `}
`

export const StyledIconContainer = styled.div`
  grid-area: icon;
`

export const StyledMainContentContainer = styled.div`
  grid-area: main;
  display: flex;
  align-items: center;
`

export const StyledDateContentContainer = styled.div`
  grid-area: date;
`

export const StyledActionContentContainer = styled.div`
  grid-area: action;
`

export const StyledNoteContainer = styled.div`
  grid-area: note;
  white-space: pre-wrap;
  text-align: justify;
  word-break: normal;
  overflow-wrap: anywhere;
`

export const StyledShowAroundContainer = styled.section`
  grid-area: note;
  display: grid;
  grid-gap: 20px;
`

export const StyledShowAroundActivityContainer = styled.div`
  display: grid;
  grid-gap: 5px;
`

export const StyledActivityChangeItemContainer = styled.div`
  display: flex;
`

export const StyledActivityChangeItemValue = styled.div`
  display: flex;
  align-items: center;

  & * ~ * {
    margin-left: 8px;
  }
`
