import { generateBasicActions } from 'utils/service'

import {
  ADD_BOTTLE,
  ADD_BOTTLE_FAILED,
  ADD_BOTTLE_SUCCESS,
  REMOVE_BOTTLE,
  REMOVE_BOTTLE_FAILED,
  REMOVE_BOTTLE_SUCCESS,
  UPDATE_BOTTLE,
  UPDATE_BOTTLE_FAILED,
  UPDATE_BOTTLE_SUCCESS,
} from './constants'

export const addRecord = (options = {}) => ({ dailyDiaryBottlesApiClient }) => generateBasicActions.create({
  constants: {
    failed: ADD_BOTTLE_FAILED,
    init: ADD_BOTTLE,
    success: ADD_BOTTLE_SUCCESS,
  },
  options,
  service: dailyDiaryBottlesApiClient,
  serviceMethod: 'addRecord',
})

export const removeRecord = (id, onSuccess, onFailed) => ({ dailyDiaryBottlesApiClient }) => (dispatch) => {
  dispatch({ id, type: REMOVE_BOTTLE })

  dailyDiaryBottlesApiClient.removeRecord(id).then(
    () => {
      dispatch({ id, type: REMOVE_BOTTLE_SUCCESS })

      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: REMOVE_BOTTLE_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const updateRecord = (id, body, onSuccess, onFailed) => ({ dailyDiaryBottlesApiClient }) => (dispatch) => {
  dispatch({ type: UPDATE_BOTTLE })

  dailyDiaryBottlesApiClient.updateRecord(id, body).then(
    ({ data }) => {
      dispatch({ payload: data, type: UPDATE_BOTTLE_SUCCESS })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: UPDATE_BOTTLE_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}
