import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledItemContainer = styled.div`
  display: flex;
  align-items: center;
`

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  & ${StyledItemContainer} ~ ${StyledItemContainer} {
    margin-top: 10px;
  }
`

export const StyledBadgeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 27px;
  width: 27px;
  border-radius: 27px;
  background-color: ${getBrandingColor('tertiary-color')};
  border: 1px solid ${getBrandingColor('tertiary-color')};
  color: ${NEUTRAL_COLOURS.WHITE};
  font-weight: 600;
  margin-right: 20px;
`

export const StyledTimeContainer = styled.div`
  display: flex;
`
