import React from 'react'

import { generateRoute } from 'utils/routing'

import { DateString, Panel } from 'components'

import i18n from 'translations'

const Enquiry = ({ enquiry }) => {
  const { data, enquiryDate, id } = enquiry
  const {
    enquiryReason,
    enquiryReasonOther,
    isOtherReasonSelected,
    isOtherSourceSelected,
    otherConsideredNurseries,
    source,
    sourceOther,
  } = data

  let reasonLabel = enquiryReason?.name
  let sourceLabel = source?.name

  if (isOtherReasonSelected) {
    reasonLabel += ` - ${enquiryReasonOther}`
  }

  if (isOtherSourceSelected) {
    sourceLabel += ` - ${sourceOther}`
  }

  return (
    <Panel
      title={i18n.t('module:Enquiries:List:Add:EnquiryDetails:title')}
      to={`${generateRoute('ENQUIRIES.EDIT', { enquiryId: id })}?s=enquiry`}
    >
      <Panel.Item
        label={i18n.t('module:Enquiries:List:Add:EnquiryDetails:EnquiryDate:label')}
        value={<DateString date={enquiryDate} />}
      />
      <Panel.Item
        label={i18n.t('module:Enquiries:List:Add:EnquiryDetails:EnquiryReason:label')}
        value={reasonLabel}
      />
      <Panel.Item
        label={i18n.t('module:Enquiries:List:Add:EnquiryDetails:Source:label')}
        value={sourceLabel}
      />
      <Panel.Item
        label={i18n.t('module:Enquiries:List:Add:EnquiryDetails:OtherConsideredNurseries:label')}
        value={otherConsideredNurseries}
      />
    </Panel>
  )
}

export default Enquiry
