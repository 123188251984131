import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getOrdinalNumber } from 'utils/string'

import { EmptyState, Icon, InfiniteScroll, Typography, XMasIcon } from 'components'
import Widget from 'module/Dashboard/components/Widget'

import i18n from 'translations'

import { StyledContent, StyledEmptyState, StyledHeader, StyledIcon, StyledWrapper } from './BirthdaysWidgetStyled'

const BirthdaysWidgetView = ({
  hasMore,
  isLoading,
  onLoadNextPage,
  todayBirthdays,
  upcomingBirthdays,
}) => {
  const renderRightColumn = (birthDate, isToday) => (
    isToday
      ? (
        <StyledIcon>
          <Icon
            color={NEUTRAL_COLOURS.WHITE}
            height={24}
            icon="birthday"
          />
        </StyledIcon>
      )
      : (
        <Typography
          margin="0 5px 0 0"
          nowrap
        >
          {moment(birthDate).format('D MMM')}
        </Typography>
      )
  )

  const renderLeftColumn = (birthDate, name, isToday) => {
    const numberOfYears = isToday
      ? moment().diff(moment(birthDate), 'years')
      : moment().diff(moment(birthDate), 'years') + 1
    const whichBirthday = i18n.t(
      'module:Dashboard:Widgets:BirthdaysWidget:whichBirthday',
      { ordinalNumber: getOrdinalNumber(numberOfYears) },
    )

    return (
      <React.Fragment>
        <Typography
          color={NEUTRAL_COLOURS.WHITE}
          ellipsis
        >
          {name}
        </Typography>
        <Typography
          color={NEUTRAL_COLOURS.WHITE}
          ellipsis
        >
          {whichBirthday}
        </Typography>
      </React.Fragment>
    )
  }

  const renderListRow = (child, index, reverse) => {
    const { birthDate, id, name } = child
    const isToday = moment(birthDate).format('D M') === moment().format('D M')

    return (
      <Widget.List.Row
        childList={[child]}
        index={index}
        key={id}
        leftColumn={renderLeftColumn(birthDate, name, isToday)}
        reverse={reverse}
        rightColumn={renderRightColumn(birthDate, isToday)}
      />
    )
  }

  const renderBirthdaysList = (title, birthdaysList, reverse = false) => (
    <React.Fragment>
      <StyledHeader reverse={reverse}>
        <Typography
          align="center"
          bold
        >
          {title}
        </Typography>
      </StyledHeader>
      <Widget.List reverse={reverse}>
        {_.map(birthdaysList, (item, index) => renderListRow(item, index, reverse))}
      </Widget.List>
    </React.Fragment>
  )

  const renderTodayBirthdays = () => renderBirthdaysList(
    _.upperFirst(i18n.t('global:today')),
    todayBirthdays,
  )

  const renderUpcomingBirthdays = () => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const reverse = (0 !== todayBirthdays?.length) && (0 === todayBirthdays?.length % 2)

    return renderBirthdaysList(
      _.upperFirst(i18n.t('global:upcoming')),
      upcomingBirthdays,
      reverse,
    )
  }

  const renderEmptyState = () => (
    <StyledEmptyState>
      <EmptyState
        iconComponent={(
          <Icon
            color={NEUTRAL_COLOURS.WHITE}
            height={60}
            icon="birthday"
          />
        )}
        text1={(
          <React.Fragment>
            <Typography bold>
              {i18n.t('module:Dashboard:Widgets:BirthdaysWidget:EmptyState:text1')}
            </Typography>
            <Typography>
              {i18n.t('module:Dashboard:Widgets:BirthdaysWidget:EmptyState:text2')}
            </Typography>
          </React.Fragment>
        )}
        iconComponentSmallMargin
      />
    </StyledEmptyState>
  )

  const renderContent = () => (
    !todayBirthdays?.length && !upcomingBirthdays?.length
      ? renderEmptyState()
      : (
        <InfiniteScroll
          dataLength={todayBirthdays.length + upcomingBirthdays.length}
          hasMore={hasMore}
          next={onLoadNextPage}
          scrollableTarget="birthdaysScrollableTarget"
          lightSpinner
        >
          <StyledContent>
            {0 < todayBirthdays?.length && (
              <div>
                <XMasIcon
                  height="24px"
                  icon="snowflake-star-variant-2"
                  marginLeft="250px"
                  marginTop="8px"
                  width="23px"
                />
              </div>
            )}
            {0 < todayBirthdays?.length && renderTodayBirthdays()}
            {0 < upcomingBirthdays?.length && (
              <div>
                <XMasIcon height="25px" icon="snowflake-variant-3" marginLeft="50px" marginTop="-3px" width="25px" />
              </div>
            )}
            {0 < upcomingBirthdays?.length && renderUpcomingBirthdays()}
          </StyledContent>
        </InfiniteScroll>
      )
  )

  return (
    <Widget>
      <Widget.Header>
        <XMasIcon height="28px" icon="snowflake-variant-2" marginLeft="-275px" marginTop="4px" width="25px" />
        {i18n.t('module:Dashboard:Widgets:BirthdaysWidget:title')}
      </Widget.Header>
      <Widget.Content
        id="birthdaysScrollableTarget"
        isLoading={isLoading}
        primary
      >
        <StyledWrapper>
          {renderContent()}
        </StyledWrapper>
      </Widget.Content>
    </Widget>
  )
}

export default BirthdaysWidgetView
