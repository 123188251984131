import styled from 'styled-components'

import layout, { ZINDEX_ORDER } from 'constants/layout'
import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledMainHeader = styled.header`
  display: flex;
  flex-direction: column;
  color: ${NEUTRAL_COLOURS.WHITE};
  padding-top: ${layout.topBarHeight}px;
  z-index: ${ZINDEX_ORDER.MAIN_HEADER};
  
  .headroom {
    z-index: ${ZINDEX_ORDER.MAIN_HEADER} !important;
  }
  
  .headroom--pinned {
    top: ${layout.topBarHeight}px !important;
  }
`

export const StyledContainer = styled.div`
  position: relative;  
`

export const StyledShellView = styled.div`
  min-height: 100%;   

  ${({ backgroundFilter }) => backgroundFilter && `
    filter: ${backgroundFilter.type}(${backgroundFilter.value});
  `}
`

export const StyledSpinnerWrapper = styled.div`
  padding-top: 40px;
`
