import moment from 'moment'

import React from 'react'
import { Field, FormSection, reduxForm } from 'redux-form'

import { Form, Typography } from 'components'

import i18n from 'translations'

import StaffForm from 'module/Staff/components/StaffForm'

import { StyledPrimaryContactFormSection } from './PrimaryContactStageStyled'
import { NURSERIES_ADD_FORM } from '../../constants'

const PrimaryContactStage = ({
  existingPrimaryContact,
  handleSubmit,
  isLoading,
  isStaffingEnabled,
  onExistingPrimaryContactSelect,
  onNameChange,
  onPreviousStageClick,
  showPrimaryContactsDropdown,
  titlesOptions,
}) => {
  const disabled = !!existingPrimaryContact

  const renderPrimaryContactsDropdown = () => showPrimaryContactsDropdown && (
    <React.Fragment>
      <Typography fontSize={18} margin="15px 0" bold tertiary>
        {i18n.t('module:Nurseries:Add:PrimaryContactDetails:Form:ManagersDropdown:text1')}
      </Typography>
      <Form.Row>
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.InfiniteDropdowns.OrganizationMemberships}
            name="existingPrimaryContact"
            placeholder={i18n.t('module:Nurseries:Add:PrimaryContactDetails:Form:ManagersDropdown:placeholder')}
            onChange={onExistingPrimaryContactSelect}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Typography fontSize={18} margin="15px 0" bold tertiary>
        {i18n.t('module:Nurseries:Add:PrimaryContactDetails:Form:ManagersDropdown:text2')}
      </Typography>
    </React.Fragment>
  )

  const renderForm = () => (
    <StyledPrimaryContactFormSection
      disabled={disabled}
    >
      <FormSection name="newPrimaryContact">
        <StaffForm
          disabled={disabled}
          isStaffingEnabled={isStaffingEnabled}
          titlesOptions={titlesOptions}
          isCreatingContext
          onNameChange={onNameChange}
        />
      </FormSection>
    </StyledPrimaryContactFormSection>
  )

  const renderFooter = () => (
    <Form.FooterActions
      cancelLabel={i18n.t('global:Back')}
      isSubmitting={isLoading}
      submitLabel={i18n.t('global:Next')}
      onCancelClick={onPreviousStageClick}
    />
  )

  return (
    <Form onSubmit={handleSubmit}>
      {renderPrimaryContactsDropdown()}
      {renderForm()}
      {renderFooter()}
    </Form>
  )
}

const validate = (values) => {
  const errors = {}

  const { newPrimaryContact } = values

  const { membershipProfile } = newPrimaryContact || {}

  const { dbsCheck } = membershipProfile || {}

  const {
    certificateId,
    reviewBy,
    reviewDate,
  } = dbsCheck || {}

  let certificateIdError = null
  let reviewByError = null
  let reviewDateError = null

  if ((reviewBy || reviewDate) && !certificateId) {
    certificateIdError = i18n.t('module:Staff:StaffAdd:Errors:dbsCertificateId')
  }

  if ((certificateId || reviewDate) && !reviewBy) {
    reviewByError = i18n.t('module:Staff:StaffAdd:Errors:dbsReviewBy')
  }

  if ((certificateId || reviewBy) && (!reviewDate || !moment(reviewDate).isValid())) {
    reviewDateError = i18n.t('module:Staff:StaffAdd:Errors:dbsReviewDate')
  }

  const dbsCheckErrors = certificateIdError || reviewByError || reviewDateError
    ? {
      certificateId: certificateIdError,
      reviewBy: reviewByError,
      reviewDate: reviewDateError,
    } : null
  const membershipProfileErrors = dbsCheckErrors
    ? { dbsCheck: dbsCheckErrors }
    : null

  if (membershipProfileErrors) {
    errors.newPrimaryContact = { membershipProfile: membershipProfileErrors }
  }

  return errors
}

export default reduxForm({
  destroyOnUnmount: false,
  form: NURSERIES_ADD_FORM,
  validate,
})(PrimaryContactStage)
