import i18n from 'translations'

export const ITEM_STATUS_FILTERS = {
  ACTIVE: 'ACTIVE',
  ALL: 'ALL',
  ARCHIVED: 'ARCHIVED',
}

export const ITEM_FILTER_OPTIONS = [
  {
    label: i18n.t('global:Active'),
    value: ITEM_STATUS_FILTERS.ACTIVE,
  },
  {
    label: i18n.t('global:All'),
    value: ITEM_STATUS_FILTERS.ALL,
  },
  {
    label: i18n.t('global:Archived'),
    value: ITEM_STATUS_FILTERS.ARCHIVED,
  },
]
