import ServiceBase from 'services/ServiceBase'

class DeviceApiClient extends ServiceBase {
  update = (body) => this.request({
    body,
    method: 'PATCH',
    path: '/device',
  })
}

export default DeviceApiClient
