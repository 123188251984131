import React from 'react'

import { StyledHr } from './HrStyled'

interface HrProps {
  color?: string
  margin?: string
}

const Hr: React.FC<HrProps> = ({ color, margin }) => (
  <StyledHr $color={color} $margin={margin} />
)

export default Hr
