import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withRouter } from 'services/router'

import SettingsInvoicesItemEditBankView from './SettingsInvoicesItemEditBankView'

const groups = {
  read: ['nursery.bankDetails'],
  write: ['nursery.bankDetails'],
}

class SettingsInvoicesEditBankContainer extends Component {
  componentDidMount() {
    const { nurseriesActions, params } = this.props
    const { nurseryId } = params

    const nurseryApiParams = { groups }

    nurseriesActions.get(nurseryId, {
      params: nurseryApiParams,
    })
  }

  handleSubmitSuccess = () => {
    const { navigate, params } = this.props
    const { nurseryId } = params

    navigate(`/settings/invoices/${nurseryId}/view`)
  }

  handleSubmit = (fields) => {
    const { nurseriesActions, params } = this.props
    const { nurseryId } = params

    nurseriesActions.update(nurseryId, {
      onSuccess: this.handleSubmitSuccess,
      params: { groups },
      payload: fields,
    })
  }

  render() {
    const { bankDetails, errorMessages, isFetching, nurseriesSingleState, nurseryName, params } = this.props
    const { isSubmitting } = nurseriesSingleState
    const { nurseryId } = params

    return (
      <SettingsInvoicesItemEditBankView
        cancelLink={`/settings/invoices/${nurseryId}/view`}
        errorMessages={errorMessages}
        initialValues={bankDetails}
        isFormLoading={isSubmitting}
        isLoading={isFetching}
        nurseryId={nurseryId}
        nurseryName={nurseryName}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseriesSelectors,
  nurseriesSingleState,
}) => ({
  bankDetails: nurseriesSelectors.getBankDetails(state),
  errorMessages: appSelectors.getErrorMessages(nurseriesSingleState),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState),
  nurseryName: nurseriesSelectors.getNurseryName(state),
})

const enhance = compose(
  withAppService,
  withRouter,
  withNurseriesService,
  connect(mapState),
)

export default enhance(SettingsInvoicesEditBankContainer)
