export const LIST_INVOICE_PAYMENTS = 'LIST_INVOICE_PAYMENTS'
export const LIST_INVOICE_PAYMENTS_SUCCESS = 'LIST_INVOICE_PAYMENTS_SUCCESS'
export const LIST_INVOICE_PAYMENTS_FAILED = 'LIST_INVOICE_PAYMENTS_FAILED'

export const EXPORT_INVOICE_PAYMENTS = 'EXPORT_INVOICE_PAYMENTS'
export const EXPORT_INVOICE_PAYMENTS_SUCCESS = 'EXPORT_INVOICE_PAYMENTS_SUCCESS'
export const EXPORT_INVOICE_PAYMENTS_FAILED = 'EXPORT_INVOICE_PAYMENTS_FAILED'

export const CLEAR_INVOICE_PAYMENT = 'CLEAR_INVOICE_PAYMENT'

export const listInvoicePayments = (params, mergeResult) => ({ invoicePaymentsApiClient }) => (dispatch) => {
  dispatch({
    type: LIST_INVOICE_PAYMENTS,
  })

  invoicePaymentsApiClient.list(params).then(
    (data) => {
      dispatch({
        mergeResult,
        payload: data,
        type: LIST_INVOICE_PAYMENTS_SUCCESS,
      })
    },
    (error) => {
      dispatch({
        error: error.response,
        type: LIST_INVOICE_PAYMENTS_FAILED,
      })
    },
  )
}

export const exportInvoicePayments = (params, onSuccess, onFailed) => ({ invoicePaymentsApiClient }) => (dispatch) => {
  dispatch({
    params,
    type: EXPORT_INVOICE_PAYMENTS,
  })

  invoicePaymentsApiClient.export(params).then(
    (data) => {
      dispatch({
        payload: data,
        type: EXPORT_INVOICE_PAYMENTS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: EXPORT_INVOICE_PAYMENTS_FAILED,
      })

      if (onFailed) {
        onFailed(error.response)
      }
    },
  )
}

export const clearInvoicePayment = () => ({
  type: CLEAR_INVOICE_PAYMENT,
})
