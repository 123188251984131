import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Typography } from 'components'

import { StyledStatusBox, StyledStatusBoxIcon, StyledText } from './ProgressLabelStyled'

const ProgressLabel = ({
  fontSize,
  subTitle,
  value,
}) => {
  if (!value) {
    return null
  }

  return (
    <StyledStatusBox>
      {(value.abbreviation && value.colour) && (
        <StyledStatusBoxIcon
          background={value.colour}
        >
          {value.abbreviation}
        </StyledStatusBoxIcon>
      )}
      <StyledText>
        <Typography
          color={value.colour}
          fontSize={fontSize || 15}
          lineHeight={18}
          bold
        >
          {value.name || value.label}
        </Typography>
        <Typography
          color={NEUTRAL_COLOURS.GRAY}
          fontSize={14}
        >
          {subTitle}
        </Typography>
      </StyledText>
    </StyledStatusBox>
  )
}

export default ProgressLabel
