import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'

import { ITEM_STATUS_FILTERS } from '../constants'

export const getNurseryItemListState = (state: RootState) => state.nurseryItem.list

export const getNurseryItemListDataState = createSelector(
  [getNurseryItemListState],
  (state) => state.data,
)

export const getCriteria = (filters) => {
  const { status } = filters
  const criteria = []

  if (status && status !== ITEM_STATUS_FILTERS.ALL) {
    criteria.push({
      field: 'archived',
      value: status === ITEM_STATUS_FILTERS.ARCHIVED,
    })
  }

  return criteria
}
