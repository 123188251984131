import React, { Component } from 'react'
import { compose } from 'recompose'

import { withAppService } from 'services/app'
import { withInvoicesService } from 'services/legacy/invoices'

import { getCriteria } from 'module/Finance/FinanceInvoices/helpers'

import DraftInvoiceWidgetView from './DraftInvoiceWidgetView'

const GROUPS = { read: ['child'] }

class DraftInvoiceWidgetContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      invoiceList: null,
      isLoading: true,
    }
  }

  componentDidMount() {
    const { invoicesActions, invoicesConstants } = this.props

    const criteria = getCriteria({ status: invoicesConstants.STATUS_TYPES.DRAFT })

    const params = {
      criteria,
      groups: GROUPS,
      limit: 20,
      order: {
        sortField: 'issueDate',
        sortOrder: 'DESC',
      },
    }

    invoicesActions.list(params, false, ({ data }) => {
      this.setState({
        invoiceList: data,
        isLoading: false,
      })
    })
  }

  render() {
    const { invoiceList, isLoading } = this.state

    return (
      <DraftInvoiceWidgetView
        invoiceList={invoiceList}
        isLoading={isLoading}
      />
    )
  }
}

const enhance = compose(
  withAppService,
  withInvoicesService,
)

export default enhance(DraftInvoiceWidgetContainer)
