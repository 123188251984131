import React from 'react'

import { generateRoute } from 'utils/routing'

import { EmptyState, InfiniteScroll, Page, SearchBar, Section, Select, Spinner, Table, Toolbar } from 'components'

import i18n from 'translations'

import { COLUMNS } from './constants'

const OrganisationsListView = ({
  featureFlagsOptions,
  filters,
  isFetching,
  onFeatureFlagsChange,
  onPageChange,
  onSearchChange,
  onStatusChange,
  organisations,
  page,
  pageCount,
  statusOptions,
}) => {
  const { featureFlags, search, status } = filters

  const actions = (
    <Section.Actions
      primary={[{
        label: i18n.t('module:Organisations:List:AddOrganisationButton:label'),
        to: generateRoute('ORGANISATIONS.ADD'),
      }]}
    />
  )

  const renderContent = () => {
    if (isFetching && 1 === page) {
      return (
        <Spinner />
      )
    }

    if (!isFetching && !organisations?.length) {
      return (
        <EmptyState
          icon="nursery"
          text1={i18n.t('module:Organisations:List:EmptyState:text1')}
          text2={i18n.t('module:Organisations:List:EmptyState:text2')}
        />
      )
    }

    return (
      <InfiniteScroll
        dataLength={organisations?.length || 0}
        hasMore={page < pageCount}
        next={() => onPageChange((+page) + 1)}
      >
        <Table
          columns={COLUMNS}
          data={organisations}
        />
      </InfiniteScroll>
    )
  }

  return (
    <React.Fragment>
      <SearchBar
        placeholder={i18n.t('module:Organisations:List:OrganisationsSearchBar:placeholder')}
        value={search}
        onChange={onSearchChange}
      />
      <Page.Section
        actions={actions}
        isLoading={isFetching && 1 === page}
        title={i18n.t('module:Organisations:title')}
      >
        <Toolbar bottomLine>
          <Toolbar.Group>
            <Toolbar.Item>
              <Select
                clearable={false}
                options={statusOptions}
                value={status}
                onChange={onStatusChange}
              />
            </Toolbar.Item>
            <Toolbar.Item>
              <Select
                options={featureFlagsOptions}
                placeholder={i18n.t('module:Organisations:List:FeatureFlagsDropdown:placeholder')}
                value={featureFlags}
                multi
                onChange={onFeatureFlagsChange}
              />
            </Toolbar.Item>
          </Toolbar.Group>
        </Toolbar>
        {renderContent()}
      </Page.Section>
    </React.Fragment>
  )
}

export default OrganisationsListView
