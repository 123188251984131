import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 10px;
  background-color: ${getBrandingColor('primary-color')};
  height: 100%;
`

export const StyledTitle = styled.div`
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: 3px;
  text-transform: uppercase;
`

export const StyledTotal = styled.div`
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  margin: 3px 0;
`

export const StyledHeaderContainer = styled.div`
  background-color: ${getBrandingColor('primary-color')};
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`

export const StyledHeaderGroup = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${getBrandingColor('senary-color')};
  border-radius: 5px;
  text-align: center;
  padding: 5px 0;
  
  &:not(:first-child) {
    margin-left: 10px;
  }
`

export const StyledHeader = styled.div`
  font-size: 16px;
`

export const StyledValue = styled.div`
  font-size: 18px;
  margin-top: 3px;
`

export const StyledContainerSpinner = styled.div`
   display: flex;
   align-items: center;
   width: 100%;
   height: 100%;
   justify-content: center;
`
