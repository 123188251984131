import styled from 'styled-components'

import colors, { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledContainer = styled.div`
  text-align: center;
  background-color: ${colors.remove};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 18px;
  line-height: 22px;
  cursor: default;
  min-width: 20px;
  min-height: 20px;
  margin-right: 8px;
  
  &:before {
    content: '!';
    color: ${NEUTRAL_COLOURS.SILVER};
  }
`
