import _ from 'lodash'

import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { LIKE_TYPE } from 'services/likes/constants'
import { COMMENT_TYPE } from 'services/comments/constants'
import { AuthorType, Like as LikeModel } from 'services/likes/models'

import { typeByObject } from 'utils/typescript'

import { withLikesService, withLikesServiceProps } from 'services/likes'

import { Avatar, InfiniteScroll, Popover, Space, Spinner, Typography } from 'components'

import i18n from 'translations'

import {
  StyledContent,
  StyledHeader,
  StyledIconFilled,
  StyledIconNotFilled,
  StyledIconWrapper,
  StyledItem,
  StyledPopover,
  StyledWrapper,
} from './LikeStyled'

interface Filter {
  commentType?: typeByObject<typeof COMMENT_TYPE>
  id: number
  likeType: typeByObject<typeof LIKE_TYPE>
}

interface LikeProps {
  commentType: typeByObject<typeof COMMENT_TYPE>
  filters?: Filter[]
  isLikedByMyself: boolean
  likeCount?: number
  likeType: typeByObject<typeof LIKE_TYPE>
  objectId: number
  onChange: (direction: boolean, response?: LikeModel | null) => void
  preview?: boolean
  reverted?: boolean
  small?: boolean
  userLike?: LikeModel | null
}

type LikeFullProps = LikeProps
  & withLikesServiceProps

const LIKE_GROUPS = {
  read: [
    'author',
    'like.author',
  ],
}

const LIMIT = 20

const Like: React.FC<LikeFullProps> = ({
  commentType,
  filters,
  likeCount = 0,
  likeType,
  likesActions,
  objectId,
  onChange,
  preview,
  reverted,
  small,
  userLike = null,
}) => {
  const [page, setPage] = useState<number>(1)
  const [totalResults, setTotalResults] = useState<number>(0)
  const [hideAnimation, setHideAnimation] = useState<boolean>(true)
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isDownloaded, setIsDownloaded] = useState<boolean>(false)
  const [isLikedByMyself, setIsLikedByMyself] = useState<boolean>(false)
  const [userLikeFinal, setUserLikeFinal] = useState<LikeModel | null>(userLike)
  const [likes, setLikes] = useState<LikeModel[]>([])

  useEffect(() => {
    if (hideAnimation) {
      setTimeout(() => {
        setHideAnimation(false)
      }, 300)
    }
  }, [userLikeFinal])

  useEffect(() => {
    setUserLikeFinal(userLike)

    if (userLike && !isLikedByMyself) {
      setIsLikedByMyself(true)
    }
  }, [userLike])

  const getLikeDetailsSuccess = (response) => {
    const { data, meta } = response

    setTotalResults(meta.total_results)
    setIsFetching(false)
    setLikes(1 === meta.start ? data : [...likes, ...data])
  }

  const getLikesList = (currentPage) => {
    const criteria = []

    const getLikeFieldFilterKey = (likeTypeParam, commentTypeParam) => {
      let likeFieldFilterKey = `likes[${likeTypeParam}]`

      if (commentTypeParam) {
        likeFieldFilterKey += `[${commentTypeParam}]`
      }

      likeFieldFilterKey += '[]'

      return likeFieldFilterKey
    }

    if (filters?.length) {
      _.each(filters, (filter) => {
        criteria.push({
          field: getLikeFieldFilterKey(filter?.likeType, filter.commentType),
          value: filter.id,
        })
      })
    } else {
      criteria.push({
        field: getLikeFieldFilterKey(likeType, commentType),
        value: objectId,
      })
    }

    setPage(currentPage)

    likesActions.list({
      onSuccess: getLikeDetailsSuccess,
      onlyData: true,
      params: {
        criteria,
        groups: LIKE_GROUPS,
        limit: LIMIT,
        page: currentPage,
      },
    })
  }

  const getLikeDetails = (forced?: boolean) => {
    if (isDownloaded && !forced) {
      return false
    }

    setIsDownloaded(true)

    return getLikesList(1)
  }

  const handleCreateLikeSuccess = (response) => {
    if (isDownloaded) {
      getLikeDetails(true)
    }

    onChange?.(true, response.data)
    setIsSubmitting(false)
  }

  const handleRemoveLikeSuccess = () => {
    if (isDownloaded) {
      getLikeDetails(true)
    }

    onChange?.(false)
    setIsSubmitting(false)
  }

  const handleOnClick = () => {
    const newValue = !isLikedByMyself

    if (isSubmitting || hideAnimation) {
      return false
    }

    setIsSubmitting(true)
    setIsLikedByMyself(newValue)

    if (newValue) {
      const body = {
        objectId,
        type: likeType,
      }

      return likesActions.create({
        // @ts-ignore
        body,
        onSuccess: handleCreateLikeSuccess,
      })
    }

    return likesActions.remove({
      onSuccess: handleRemoveLikeSuccess,
      params: [userLikeFinal.id],
    })
  }

  const renderTitle = (item: LikeModel) => {
    if (AuthorType.DEACTIVATED === item.author?.type) {
      return (
        <Typography color={NEUTRAL_COLOURS.GRAY} italic>
          {i18n.t('module:LikesAndComments:authorDeactivated')}
        </Typography>
      )
    }

    return item.author?.fullName || '-'
  }

  const renderLikeItem = (item: LikeModel) => (
    <StyledItem key={item.id}>
      <Avatar
        avatarSize="small"
        src={item.author?.photo}
        title={renderTitle(item)}
      />
    </StyledItem>
  )

  const renderContentPopover = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    return (
      <InfiniteScroll
        dataLength={likes ? likes.length : 0}
        hasMore={page < Math.ceil(totalResults / LIMIT)}
        next={() => getLikesList((+page) + 1)}
        scrollableTarget="scrollableDiv"
      >
        {_.map(likes, renderLikeItem)}
      </InfiniteScroll>
    )
  }

  const renderContentWrapperPopover = () => (
    <StyledPopover>
      <StyledHeader>
        <Typography fontSize={16} margin="10px 0" bold>
          {i18n.t('module:LikesAndComments:peopleWhoLiked')}
        </Typography>
      </StyledHeader>
      <StyledContent id="scrollableDiv">
        {renderContentPopover()}
      </StyledContent>
      <Space padding="5px" />
    </StyledPopover>
  )

  const renderPopover = () => {
    if (!likeCount && preview) {
      return null
    }

    if (!likeCount) {
      return (
        <Typography color={NEUTRAL_COLOURS.GRAY} opacity={0} nowrap>
          {i18n.t('module:LikesAndComments:likes', { amount: 0 })}
        </Typography>
      )
    }

    return (
      <Popover
        button={(
          <Typography color={NEUTRAL_COLOURS.GRAY} cursor="pointer" minWidth={45} nowrap>
            {1 === likeCount
              ? i18n.t('module:LikesAndComments:like')
              : i18n.t('module:LikesAndComments:likes', { amount: likeCount })}
          </Typography>
        )}
        placement="top-end"
        disableCloseInside
        onOpen={getLikeDetails}
      >
        {renderContentWrapperPopover()}
      </Popover>
    )
  }

  const renderLikeHeart = () => (
    <StyledIconWrapper
      $small={small}
      onClick={handleOnClick}
    >
      <StyledIconFilled
        $hidden={!isLikedByMyself}
        $hideAnimation={hideAnimation}
        $small={small}
        fill="none"
        height="19"
        viewBox="0 0 20 19"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.5 0C12.76 0 11.09 0.81 10 2.09C8.91 0.81 7.24 0 5.5 0C2.42 0 0 2.42 0 5.5C0 9.28 3.4 12.36 8.55
          17.04L10 18.35L11.45 17.03C16.6 12.36 20 9.28 20 5.5C20 2.42 17.58 0 14.5 0Z"
          fill="#F76C6C"
        />
      </StyledIconFilled>
      <StyledIconNotFilled
        $small={small}
        fill="none"
        height="19"
        viewBox="0 0 20 19"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.81728 2.2456L10 2.46018L10.1827 2.2456C11.2287 1.01734 12.8327 0.24 14.5 0.24C17.4475 0.24 19.76
          2.55255 19.76 5.5C19.76 7.31116 18.9476 8.97335 17.4705 10.7793C15.9906 12.5886 13.8686 14.5128 11.2888
          16.8522L11.2884 16.8525L9.99939 18.026L8.71141 16.8624L8.71089 16.8619L8.68454 16.838C6.11679 14.5046
          4.00439 12.5849 2.52957 10.7806C1.05238 8.97336 0.24 7.31116 0.24 5.5C0.24 2.55255 2.55255 0.24 5.5
          0.24C7.16733 0.24 8.77134 1.01734 9.81728 2.2456ZM3.98266 10.1485C5.37397 11.7743 7.36066 13.5745 9.73451
          15.7239L9.83029 15.8197L10 15.9894L10.1697 15.8197L10.2655 15.7239C12.6393 13.5745 14.626 11.7743 16.0173
          10.1485C17.4072 8.52455 18.24 7.03358 18.24 5.5C18.24 3.36745 16.6325 1.76 14.5 1.76C12.9258 1.76 11.3868
          2.73193 10.7789 4.12H9.2301C8.61356 2.73266 7.07504 1.76 5.5 1.76C3.36745 1.76 1.76 3.36745 1.76 5.5C1.76
          7.03358 2.59282 8.52455 3.98266 10.1485Z"
          fill="#F76C6C"
          stroke="white"
          strokeWidth="0.48"
        />
      </StyledIconNotFilled>
    </StyledIconWrapper>
  )

  const renderContent = () => {
    if (reverted) {
      return (
        <StyledWrapper $reverted={reverted} $small={small}>
          {!preview && renderLikeHeart()}
          {renderPopover()}
        </StyledWrapper>
      )
    }

    return (
      <StyledWrapper $reverted={reverted} $small={small}>
        {renderPopover()}
        {!preview && renderLikeHeart()}
      </StyledWrapper>
    )
  }

  return renderContent()
}

const enhance = compose(
  withLikesService,
)

export default enhance(Like)
