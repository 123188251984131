import { combineReducers } from 'redux'

import offline, { AppOfflineState } from './offline/reducer'

export default combineReducers({
  offline,
})

export interface AppState {
  offline: AppOfflineState
}
