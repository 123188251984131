import React from 'react'

import colors from 'constants/colors'

import { Icon, Typography } from 'components'

import { StyledIconContainer, StyledTextContainer } from './InfoStyled'

const InfoContentItem = ({
  columnIndex,
  icon,
  text,
}) => (
  <React.Fragment>
    <StyledIconContainer columnIndex={columnIndex}>
      <Icon color={colors.lightGray} height={80} icon={icon} />
    </StyledIconContainer>
    <StyledTextContainer columnIndex={columnIndex}>
      <Typography fontSize={20}>{text}</Typography>
    </StyledTextContainer>
  </React.Fragment>
)

export default InfoContentItem
