import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as childrenSessionsSelectors from './selectors'

const withChildrenSessionsService = (WrappedComponent) => {
  const mapState = ({ childrenSessions }) => ({ childrenSessions })

  const mapDispatch = injectActions('childrenSessionsActions', actions)

  const Component = ({ childrenSessions, childrenSessionsActions, ...other }) => (
    <WrappedComponent
      childrenSessionsActions={childrenSessionsActions}
      childrenSessionsListState={childrenSessions.list}
      childrenSessionsSelectors={childrenSessionsSelectors}
      {...other}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withChildrenSessionsService
