import styled from 'styled-components'

import colors from 'constants/colors'

export const StyledAbsoluteContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
`

export const StyledAbsoluteContent = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-left: 14px;
  z-index: 10;
  
  ${({ column }) => column && `
    flex-direction: column;
    justify-content: center;
  `}
`

export const StyledNurseryDetails = styled.div`
  display: flex;
`

export const StyledNurseryDetailsItem = styled.div`
  white-space: nowrap;
  margin-right: 40px;
`

export const StyledError = styled.div`
  white-space: nowrap;
  color: ${colors.remove};
`

export const StyledHeaderLabel = styled.div`
  ${({ gray }) => gray && `
    color: #9A9A9A;
  `}
`

export const StyledExpandableCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;

  > div:nth-child(2) {
    height: 20px;
  }
`
