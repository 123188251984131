import styled from 'styled-components'
import { Property } from 'csstype'

import layout from 'constants/layout'
import { NEUTRAL_COLOURS } from 'constants/colors'

interface StyledItemContainerProps {
  $justifyContentOnMobile?: Property.JustifyContent
  $noMargin?: boolean
}

export const StyledItemContainer = styled.div<StyledItemContainerProps>`
  padding: 0 5px;
  margin-bottom: 10px;
  
  @media(max-width: ${layout.mobileWidthResolution}px) {
    margin: 5px 0;
    width: 100%;
    display: flex;
    justify-content: ${({ $justifyContentOnMobile }) => $justifyContentOnMobile || 'center'};
  }
  
  ${({ $noMargin }) => $noMargin && `
    margin-bottom: 0;
  `}
`

interface StyledGroupContainerProps {
  $alignItems?: Property.AlignItems
  $fullWidth?: boolean
  $justifyContent?: Property.JustifyContent
}

export const StyledGroupContainer = styled.div<StyledGroupContainerProps>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 0; /* -10px + 10px */
  
  ${({ $alignItems }) => $alignItems && `
    align-items: ${$alignItems};
  `}

  ${({ $justifyContent }) => $justifyContent && `
    justify-content: ${$justifyContent};
    width: 100%;
  `}
  
  ${({ $fullWidth }) => $fullWidth && `
    flex: 1;
    
    & ${StyledItemContainer} {
      flex: 1;
    }
  
    @media (min-width: 1024px) {
      flex-direction: row;
    }
  `}
  
  @media(max-width: ${layout.mobileWidthResolution}px) {
    flex-direction: column;
    width: auto;
  }
`

interface StyledContainerProps {
  $bottomLine?: boolean
  $noMargin?: boolean
}

export const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;

  ${({ $bottomLine }) => $bottomLine && `
    border-bottom: 1px dashed ${NEUTRAL_COLOURS.SILVER};
  `}

  ${({ $noMargin }) => $noMargin && `
    margin-bottom: 0;

    & ${StyledGroupContainer} {
      margin-bottom: 0;
    }

    & ${StyledItemContainer} {
      margin-bottom: 0;
    }
  `}

  @media(max-width: ${layout.mobileWidthResolution}px) {
    flex-direction: column;
    width: 100%;
    padding-bottom: 10px;
  }
`
