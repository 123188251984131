import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change, getFormValues, stopSubmit } from 'redux-form'

import { HEADER_SUBDOMAIN } from 'services/security/authorization/constants'
import { FEATURE_FLAGS_OPTIONS } from 'services/legacy/featureFlags/constants'
import { FEATURE_FLAGS, ROLES } from 'constants/security'
import { UPLOAD_DIRECTORY } from 'services/legacy/upload/constants'

import { getBackendErrors } from 'utils/backendErrors'
import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withFrameworksService } from 'services/legacy/frameworks'
import { withNurseryFrameworkRelationsService } from 'services/legacy/nurseryFrameworkRelations'
import { withNurseriesService } from 'services/nurseries'
import { withSecurityService } from 'services/security'
import { withSnackbarService } from 'services/utils/snackbar'
import { withPaginationUtils } from 'services/utils/pagination'
import { withUploadService } from 'services/legacy/upload'
import { withRouter } from 'services/router'

import i18n from 'translations'

import { NURSERIES_EDIT_FORM } from './components/NurseriesEditForm'
import NurseriesEditView from './NurseriesEditView'
import { getTableData } from './NurseriesEditHelpers'

const ORG_CONTEXT_GROUPS = {
  read: [
    'capability',
    'nursery.settings',
    'nurserySettings',
    'nurserySettings.localeDetails',
    'nurseryIntegration',
    'nurseryIntegrations.stripe',
    'nurseryIntegration.capabilities',
  ],
}

const NURSERY_SETTINGS_GROUPS = {
  read: [
    'capability',
    'nurserySettings',
    'nurserySettings.localeDetails',
    'nursery.settings',
    'nursery.organization',
    'nurseryIntegration',
    'nurseryIntegrations.stripe',
    'nurseryIntegration.capabilities',
    'organization',
  ],
}

const FRAMEWORK_NURSERY_RELATION_GROUPS = {
  read: [
    'nurseryFrameworkRelation.framework',
  ],
}

class NurseriesEditContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      imageUploading: false,
      nurseryId: null,
      nurserySettings: null,
      requestHeaders: {},
      submittingItems: {},
    }
  }

  componentDidMount() {
    const { params: { nurseryId } } = this.props

    if (nurseryId) {
      this.getNurserySettings()
    }
  }

  componentWillUnmount() {
    const { nurseriesActions } = this.props

    nurseriesActions.clearSingle()
  }

  getNurserySettingsSuccess = (response) => {
    const {
      frameworksActions,
      isAdministrationContext,
      nurseriesActions,
      nurseryFrameworkRelationsActions,
      params: { nurseryId },
    } = this.props

    this.setState({
      nurseryId: response?.data?.id,
      nurserySettings: response?.data?.nurserySettings,
      requestHeaders: {
        [HEADER_SUBDOMAIN]: response?.data?.subdomain,
      },
    }, () => {
      const { requestHeaders } = this.state

      if (isAdministrationContext) {
        nurseriesActions.listFlags(nurseryId)
        frameworksActions.legacyList({ params: [{}] })
        nurseryFrameworkRelationsActions.list({
          params: [{
            groups: FRAMEWORK_NURSERY_RELATION_GROUPS,
            headers: requestHeaders,
          }],
        })
      }
    })
  }

  getNurserySettings = () => {
    const { isOrganizationContext, nurseriesActions, params: { nurseryId } } = this.props
    const params = isOrganizationContext
      ? { groups: ORG_CONTEXT_GROUPS }
      : { groups: NURSERY_SETTINGS_GROUPS }

    nurseriesActions.get(nurseryId, {
      onSuccess: (response) => {
        const { data: { subdomain } } = response

        return nurseriesActions.get(nurseryId, {
          headers: {
            [HEADER_SUBDOMAIN]: subdomain,
          },
          onSuccess: this.getNurserySettingsSuccess,
          params,
        })
      },
      onlyData: true,
      params,
    })
  }

  uploadFile = async (body) => {
    const { uploadActions } = this.props

    this.setState({ imageUploading: true })

    const url = await uploadActions.uploadFile(body.logo.file, body.logo.file.name, UPLOAD_DIRECTORY.PUBLIC_IMAGES)

    this.setState({ imageUploading: false })

    return url
  }

  handleSubmitSuccess = ({ data }) => {
    const { navigate } = this.props

    navigate(generateRoute('NURSERIES.DETAILS', { nurseryId: data.id }))
  }

  handleSubmitFailed = (response) => {
    const { injectValidation } = this.props

    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    return injectValidation(NURSERIES_EDIT_FORM, errors)
  }

  handleSubmit = async (fields) => {
    const {
      changeField,
      nurseriesActions,
      nurseriesSelectors,
      params: { nurseryId },
    } = this.props

    const payload = nurseriesSelectors.getEditNurseryPayload(fields)

    if (false === payload?.logo?.isUploaded) {
      payload.logo = await this.uploadFile(payload)

      changeField('logo', {
        isUploaded: true,
        value: payload.logo,
      })
    } else {
      payload.logo = payload?.logo?.value
    }

    if (nurseryId) {
      return nurseriesActions.update(nurseryId, {
        onFailed: this.handleSubmitFailed,
        onSuccess: this.handleSubmitSuccess,
        payload,
      })
    }

    return nurseriesActions.create({
      onFailed: this.handleSubmitFailed,
      onSuccess: this.handleSubmitSuccess,
      payload,
    })
  }

  showSnackBarMessage = ({ enabled, label }) => {
    const { snackbarActions } = this.props

    snackbarActions.show({
      message: i18n.t('module:Organisations:Edit:OnFeatureFlagChange:successMessage', {
        action: enabled ? i18n.t('global:enabled') : i18n.t('global:disabled'),
        label,
      }),
    })
  }

  handleUpdateFinanceV3FF = ({ enabled, label }) => {
    const {
      modalActions,
      nurseriesActions,
      params: { nurseryId },
    } = this.props

    nurseriesActions.updateFlag(nurseryId, FEATURE_FLAGS.FINANCE_V3, {
      onSuccess: () => {
        modalActions.hide()
        this.showSnackBarMessage({ enabled, label })
      },
      payload: { enabled },
    })
  }

  handleMigrationFailed = (enabled) => {
    const { changeField, modalActions, modalConsts } = this.props

    changeField(`featureFlags.${FEATURE_FLAGS.FINANCE_V3}`, !enabled)

    modalActions.show(modalConsts.TYPES.ALERT, {
      text: i18n.t('module:Organisations:Add:FinanceV3FF:errorMessage'),
    })
  }

  migrateFinanceV3 = ({ enabled, label }) => {
    const {
      modalActions,
      modalConsts,
      nurseriesActions,
      params: { nurseryId },
    } = this.props
    const { requestHeaders } = this.state

    modalActions.show(modalConsts.TYPES.ALERT, {
      hideButton: true,
      text: i18n.t(enabled
        ? 'module:Organisations:Add:FinanceV3FF:activateAlertMessage'
        : 'module:Organisations:Add:FinanceV3FF:deactivateAlertMessage',
      ),
    })

    nurseriesActions.updateFinanceMigration({
      body: { newFinanceEnabled: enabled },
      onFailed: () => this.handleMigrationFailed(enabled),
      onSuccess: () => this.handleUpdateFinanceV3FF({ enabled, label }),
      params: [nurseryId, {}, requestHeaders[HEADER_SUBDOMAIN]],
    })
  }

  handleMigrateFinanceV3 = ({ enabled, label }) => {
    const { changeField, modalActions, modalConsts } = this.props

    if (enabled) {
      this.migrateFinanceV3({ enabled, label })

      return
    }

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('module:Organisations:Add:FinanceV3FF:deactivateConfirmButton'),
      onCancel: () => changeField(`featureFlags.${FEATURE_FLAGS.FINANCE_V3}`, true),
      onConfirm: () => this.migrateFinanceV3({ enabled, label }),
      text: i18n.t('module:Organisations:Add:FinanceV3FF:deactivateMessage'),
    })
  }

  updateFlag = ({ enabled, hideSnackbar, label, value }) => {
    const {
      changeField,
      formValues,
      nurseriesActions,
      params: { nurseryId },
      snackbarActions,
    } = this.props

    if (value === FEATURE_FLAGS.FINANCE_V3) {
      this.handleMigrateFinanceV3({ enabled, label })

      return
    }

    nurseriesActions.updateFlag(nurseryId, value, {
      onSuccess: () => {
        if (!hideSnackbar) {
          snackbarActions.show({
            message: i18n.t('module:Nurseries:Edit:OnFeatureFlagChange:successMessage', {
              action: enabled ? i18n.t('global:enabled') : i18n.t('global:disabled'),
              label,
            }),
          })
        }

        if (value === FEATURE_FLAGS.FINANCE_MVP && !enabled && formValues.featureFlags[FEATURE_FLAGS.FINANCE_V3]) {
          this.updateFlag({
            enabled: false,
            hideSnackbar: true,
            label: _.find(FEATURE_FLAGS_OPTIONS, { value: FEATURE_FLAGS.FINANCE_V3 })?.label,
            value: FEATURE_FLAGS.FINANCE_V3,
          })

          changeField(`featureFlags.${FEATURE_FLAGS.FINANCE_V3}`, false)
        }
      },
      payload: { enabled },
    })
  }

  handleFeatureFlagChange = (label, value) => {
    const {
      formValues,
      isAdministrationContext,
    } = this.props

    const enabled = !formValues.featureFlags[value]

    if (isAdministrationContext) {
      return this.updateFlag({ enabled, label, value })
    }

    return null
  }

  handleDisableNursery = () => {
    const {
      isNurseryArchived,
      navigate,
      nurseriesActions,
      params: { nurseryId },
    } = this.props

    nurseriesActions.update(nurseryId, {
      onSuccess: () => navigate(generateRoute('NURSERIES.INDEX')),
      payload: { archived: !isNurseryArchived },
    })
  }

  handleDisableClick = () => {
    const { isNurseryArchived, modalActions, modalConsts } = this.props

    const text = isNurseryArchived
      ? i18n.t('module:Nurseries:Edit:Enable:confirm')
      : i18n.t('module:Nurseries:Edit:Disable:confirm')

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('global:Yes'),
      onConfirm: this.handleDisableNursery,
      text,
    })
  }

  handleApplyAll = (startTime = null, endTime = null, fields) => {
    const { changeField } = this.props

    fields.forEach((item, i) => {
      if (fields.get(i).isChecked) {
        changeField(`${item}.startTime`, startTime)
        changeField(`${item}.endTime`, endTime)
      }
    })
  }

  handleFrameworkPageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  updateSubmittingItemFlag = (id, value, callback) => {
    this.setState((prevState) => ({
      submittingItems: {
        ...prevState.submittingItems,
        [id]: value,
      },
    }), callback)
  }

  handleCreateRelationSuccess = (id) => (response) => {
    const { nurseryFrameworkRelationsActions, snackbarActions } = this.props

    snackbarActions.show({
      message: i18n.t('module:Nurseries:Edit:Framework:addSuccessMessage'),
    })

    nurseryFrameworkRelationsActions.addToList(response?.data)
    this.updateSubmittingItemFlag(id, false)
  }

  handleRemoveRelationSuccess = (id, nurseryRelationId) => () => {
    const { nurseryFrameworkRelationsActions, snackbarActions } = this.props

    snackbarActions.show({
      message: i18n.t('module:Nurseries:Edit:Framework:removedSuccessMessage'),
    })

    nurseryFrameworkRelationsActions.removeFromList(nurseryRelationId || id)
    this.updateSubmittingItemFlag(id, false)
  }

  handleFrameworkToggleChange = (item) => {
    const { nurseryFrameworkRelationsActions, nurseryFrameworkRelationsSelectors } = this.props
    const { nurseryId, requestHeaders } = this.state
    const { enabled, id, isMontessori, nurseryRelationId } = item

    this.updateSubmittingItemFlag(id, true, () => {
      if (!enabled) {
        if (isMontessori) {
          nurseryFrameworkRelationsActions.update({
            body: { enabled: true },
            onSuccess: !enabled
              ? this.handleCreateRelationSuccess(id)
              : this.handleRemoveRelationSuccess(id),
            onlyData: true,
            params: [id, {
              headers: requestHeaders,
            }],
          })

          return
        }

        nurseryFrameworkRelationsActions.create({
          body: nurseryFrameworkRelationsSelectors.getPayload({
            enabled: true,
            frameworkId: id,
            nurseryId,
          }),
          onSuccess: this.handleCreateRelationSuccess(id),
          onlyData: true,
          params: [{
            groups: FRAMEWORK_NURSERY_RELATION_GROUPS,
            headers: requestHeaders,
          }],
        })

        return
      }

      const finalNurseryRelationId = isMontessori ? id : nurseryRelationId

      nurseryFrameworkRelationsActions.remove({
        onSuccess: this.handleRemoveRelationSuccess(id, finalNurseryRelationId),
        onlyData: true,
        params: [finalNurseryRelationId, {
          headers: requestHeaders,
        }],
      })
    })
  }

  render() {
    const {
      errorMessages,
      formValues,
      frameworks,
      frameworksRelations,
      initialValues,
      isAdministrationContext,
      isFetching,
      isFrameworksLoading,
      isNurseryArchived,
      isOrganizationContext,
      isSubmitting,
      nursery,
      paginationUtils,
      params: { nurseryId },
      totalFrameworkResults,
    } = this.props
    const { imageUploading, nurserySettings, submittingItems } = this.state

    const { getPageCount, page } = paginationUtils
    const pageCount = getPageCount(totalFrameworkResults)
    let finalFrameworks = frameworks

    if (isAdministrationContext) {
      finalFrameworks = _.map(frameworks, (framework) => ({
        ...framework,
        enabled: !!_.find(
          frameworksRelations,
          (relation) => (relation?.framework?.id || relation?.id) === framework?.id,
        ),
      }))
    }

    const frameworksTableData = getTableData({
      data: finalFrameworks,
      onFrameworkToggleChange: this.handleFrameworkToggleChange,
      submittingItems,
    })

    return (
      <NurseriesEditView
        errorMessages={errorMessages}
        formValues={formValues}
        frameworkPagination={{ isLoading: isFrameworksLoading, page, pageCount }}
        frameworks={frameworksTableData}
        initialValues={initialValues}
        isAdministrationContext={isAdministrationContext}
        isArchived={isNurseryArchived}
        isFetching={isFetching}
        isOrganizationContext={isOrganizationContext}
        isSubmitting={isSubmitting || imageUploading}
        nursery={nursery}
        nurseryId={nurseryId}
        nurserySettings={nurserySettings}
        onApplyAll={this.handleApplyAll}
        onDisableClick={this.handleDisableClick}
        onFeatureFlagChange={this.handleFeatureFlagChange}
        onFrameworkPageChange={this.handleFrameworkPageChange}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

NurseriesEditContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
  ],
}

const mapDispatch = {
  changeField: (field, value) => change(NURSERIES_EDIT_FORM, field, value),
  injectValidation: (formName, errors) => stopSubmit(formName, errors),
}

const mapState = (state, {
  appSelectors,
  frameworksLegacyListState,
  frameworksSelectors,
  nurseriesSelectors,
  nurseriesSingleState,
  nurseryFrameworkRelationsSelectors,
  securitySelectors,
}) => ({
  errorMessages: appSelectors.getErrorMessages(nurseriesSingleState),
  formValues: getFormValues(NURSERIES_EDIT_FORM)(state),
  frameworks: frameworksSelectors.getFrameworksLegacyListWithEnabledFlag(state),
  frameworksRelations: nurseryFrameworkRelationsSelectors.getNurseryFrameworkRelationsListDataState(state),
  initialValues: nurseriesSelectors.getInitialValues(state),
  isAdministrationContext: securitySelectors.isAdministrationContext(state),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState),
  isFrameworksLoading: appSelectors.getIsFetching(frameworksLegacyListState),
  isNurseryArchived: nurseriesSelectors.getNurseryIsArchived(state),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
  isSubmitting: appSelectors.getIsSubmitting(nurseriesSingleState),
  nursery: nurseriesSelectors.getNurseryData(state),
  totalFrameworkResults: appSelectors.getTotalResults(frameworksLegacyListState),
})

const enhance = compose(
  withAppService,
  withModalService,
  withNurseriesService,
  withFrameworksService,
  withNurseryFrameworkRelationsService,
  withPaginationUtils,
  withRouter,
  withSecurityService,
  withSnackbarService,
  withUploadService,
  connect(mapState, mapDispatch),
)

export default enhance(NurseriesEditContainer)
