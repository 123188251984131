import color from 'color'
import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledContainer = styled.a`

  width: calc(100% - 40px);
  border-radius: 8px;
  background-color: ${getBrandingColor('senary-color')};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  color: ${NEUTRAL_COLOURS.WHITE};
  margin: 40px 20px 20px;

  &:hover {
    color: ${NEUTRAL_COLOURS.WHITE};
    background: ${color(getBrandingColor('senary-color')).darken(0.05).hex()}
  }
`

export const StyledLogo = styled.img`
  height: 30px;
  margin-left: 5px;
  margin-right: 20px;
`
