import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class EnquiriesApiClient extends ServiceBase {
  get = (id, params = {}) => this.request({
    path: `/v2/enquiries/${id}?${getQueryStringV2(params)}`,
  })

  list = (params = {}) => this.request({
    path: `/v2/enquiries?${getQueryStringV2(params)}`,
  })

  create = (params = {}, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/enquiries?${getQueryStringV2(params)}`,
  })

  createPublicEnquiry = (params = { token: '' }, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/enquiries/form?nt=${params.token}`,
  })

  update = (id, params = {}, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/enquiries/${id}?${getQueryStringV2(params)}`,
  })

  export = (params = {}) => this.request({
    path: `/v2/enquiries/report/enquiries?${getQueryStringV2(params)}`,
  })
}

export default EnquiriesApiClient
