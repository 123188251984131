import React from 'react'
import { Field as FormField, reduxForm } from 'redux-form'
import { compose } from 'recompose'

import { isRequired } from 'utils/fieldValidation'

import { Field, Form } from 'components'

import i18n from 'translations'

export const CREDIT_NOTE_INVOICE_FORM = 'CreditNoteInvoicesForm'
const TRANS_PREFIX = 'module:Children:Child:BookingPattern:AutoCreditNoting:CreditNoteInvoices'

const CreditNoteInvoicesForm = ({
  handleSubmit,
  hasOnlyDraftInvoices,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    {!hasOnlyDraftInvoices && (
      <React.Fragment>
        <Form.Row>
          <Form.Row.FlexItem flex="0 0 100px">
            <Field.TopLabel label={i18n.t(`${TRANS_PREFIX}:Form:CreditNoteDate:label`)} required />
            <FormField
              component={Form.DatePicker}
              name="issueDate"
              placeholder={i18n.t(`${TRANS_PREFIX}:Form:CreditNoteDate:placeholder`)}
              placement="right-center"
              validate={isRequired}
            />
          </Form.Row.FlexItem>
        </Form.Row>
        <Form.Row>
          <Form.Row.FlexItem>
            <Field.TopLabel label={i18n.t(`${TRANS_PREFIX}:Form:CreditNoteReason:label`)} required />
            <FormField
              component={Form.TextAreaField}
              name="description"
              placeholder={i18n.t(`${TRANS_PREFIX}:Form:CreditNoteReason:placeholder`)}
              validate={isRequired}
            />
          </Form.Row.FlexItem>
        </Form.Row>
      </React.Fragment>
    )}
  </Form>
)

const enhance = compose(reduxForm({ form: CREDIT_NOTE_INVOICE_FORM }))

export default enhance(CreditNoteInvoicesForm)
