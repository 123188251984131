import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import {
  Button,
  CircleIcon,
  Currency,
  EmptyState,
  Section,
  Space,
  Spinner,
  StickyFooter,
  Toolbar,
  Tooltip,
  Typography,
} from 'components'

import i18n from 'translations'

import ErrorSummary from './components/ErrorSummary'
import ReIssueInvoicesForm from './components/ReIssueInvoicesForm'
import { StyledAmountContainer } from './ReissueInvoicesStyled'

const TRANS_PREFIX = 'module:Children:Child:BookingPattern:AutoCreditNoting:ReIssueInvoices'

const ReIssueInvoicesView = ({
  batchInvoiceErrors,
  batchInvoicesTotal,
  childId,
  initialValues,
  isSubmitting,
  onAddInvoiceClick,
  onContinueClick,
  onDeleteInvoiceClick,
  onEditInvoiceClick,
  onInvoiceItemClick,
  onPageChange,
  onSubmit,
}) => {
  if (!initialValues?.invoices?.length && isSubmitting) {
    return <Spinner />
  }

  const renderContent = () => {
    if (!initialValues?.invoices?.length) {
      return (
        <EmptyState
          icon="invoices"
          iconSize={80}
          text1={i18n.t(`${TRANS_PREFIX}:emptyStateText`)}
        />
      )
    }

    return (
      <React.Fragment>
        <Typography>
          {i18n.t(`${TRANS_PREFIX}:subtitle`)}
        </Typography>
        <Space space="20px" />
        {batchInvoiceErrors?.length ? (
          <React.Fragment>
            <ErrorSummary errors={batchInvoiceErrors} />
            <Space space="20px" />
          </React.Fragment>
        ) : null}
        <Toolbar>
          <Toolbar.Group>
            <Toolbar.Item>
              <StyledAmountContainer>
                <Typography fontSize={22} margin="0 0 3px" variant="div" bold inline>
                  <Currency value={batchInvoicesTotal} />
                  <Tooltip
                    placement="bottom-end"
                    title={i18n.t(`${TRANS_PREFIX}:invoicesTotalTooltip`)}
                  >
                    <CircleIcon
                      cursor="pointer"
                      icon="info"
                      iconSize={14}
                      size={20}
                    />
                  </Tooltip>
                </Typography>
              </StyledAmountContainer>
              <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} transform="uppercase" variant="div">
                {i18n.t(`${TRANS_PREFIX}:invoicesTotalLabel`)}
              </Typography>
            </Toolbar.Item>
          </Toolbar.Group>
          <Toolbar.Group>
            <Toolbar.Item>
              <Button
                label={i18n.t(`${TRANS_PREFIX}:addInvoiceButtonLabel`)}
                negativeMargins
                onClick={onAddInvoiceClick}
              />
            </Toolbar.Item>
          </Toolbar.Group>
        </Toolbar>
        <Space space="10px" />
        <ReIssueInvoicesForm
          childId={childId}
          initialValues={initialValues}
          onDeleteInvoiceClick={onDeleteInvoiceClick}
          onEditInvoiceClick={onEditInvoiceClick}
          onInvoiceItemClick={onInvoiceItemClick}
          onPageChange={onPageChange}
          onSubmit={onSubmit}
        />
      </React.Fragment>
    )
  }

  const renderFooter = () => (
    <StickyFooter>
      <Button
        hierarchy="tertiary"
        label={i18n.t('global:Cancel')}
        to={generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS', { childId })}
        negativeMargins
      />
      <StickyFooter.Flex />
      <Button
        isLoading={isSubmitting}
        label={i18n.t('global:SaveAndContinue')}
        negativeMargins
        onClick={onContinueClick}
      />
    </StickyFooter>
  )

  return (
    <Section title={i18n.t(`${TRANS_PREFIX}:title`)}>
      {renderContent()}
      {renderFooter()}
    </Section>
  )
}

export default ReIssueInvoicesView
