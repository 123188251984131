import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { GENDER_DROPDOWN, GENDER_DROPDOWN_WITH_OTHER } from 'services/legacy/child/constants'
import { ENQUIRY_REASON_LABELS, SOURCE_LABELS } from 'services/legacy/enquiries/constants'

import { getDateString } from 'utils/date'

import { getNurseryData } from 'services/nurseries/single/selectors/single'
import { getIsFinanceV3Enabled } from 'utils/auth/selectors'

import i18n from 'translations'

const getEnquiries = (state) => state.enquiries

export const getEnquiriesSingleSelector = createSelector(
  [getEnquiries],
  (state) => state?.single,
)

export const getEnquiriesSingleDataSelector = createSelector(
  [getEnquiriesSingleSelector],
  (state) => state.data,
)

export const getPayload = (formValues, isFinanceV3Enabled) => {
  if (!formValues) {
    return null
  }

  const { data, enquiryDate, nurseryClass, sessionPlans, startDate } = formValues
  const {
    child,
    contact,
    enquiryReason,
    enquiryReasonOther,
    otherConsideredNurseries,
    source,
    sourceOther,
  } = data || {}
  const {
    address,
    email,
    firstName: contactFirstName,
    gender: contactGender,
    postCode,
    relation,
    surname: contactSurname,
    telephone,
  } = contact

  const {
    birthDate,
    firstName: childFirstName,
    gender: childGender,
    surname: childSurname,
  } = child

  const finalSessionPlans = []

  _.forEach(sessionPlans, (dayPlans, dayOfWeek) => {
    _.forEach(dayPlans, ({ endTime, sessionId, startTime }) => {
      if (sessionId) {
        finalSessionPlans.push({
          dayOfWeek,
          endTime,
          session: { id: sessionId.value },
          startTime,
        })
      }
    })
  })

  return {
    data: {
      child: {
        birthDate: birthDate ? getDateString(birthDate) : undefined,
        firstName: childFirstName,
        gender: childGender ? childGender.value : null,
        surname: childSurname,
      },
      contact: {
        address,
        email,
        firstName: contactFirstName,
        gender: contactGender ? contactGender.value : null,
        postCode,
        relation: relation?.label,
        surname: contactSurname,
        telephone,
      },
      enquiryReason: enquiryReason ? { id: enquiryReason.value } : undefined,
      enquiryReasonOther: enquiryReason ? enquiryReasonOther : undefined,
      otherConsideredNurseries,
      source: source ? { id: source.value } : undefined,
      sourceOther: source ? sourceOther : undefined,
    },
    enquiryDate: enquiryDate ? getDateString(enquiryDate) : undefined,
    nurseryClass: nurseryClass ? { id: nurseryClass.value } : undefined,
    sessionPlans: !isFinanceV3Enabled && finalSessionPlans.length ? finalSessionPlans : undefined,
    sessionProductPlans: isFinanceV3Enabled && finalSessionPlans.length ? finalSessionPlans : undefined,
    startDate: startDate ? getDateString(startDate) : undefined,
  }
}

const getPlans = (formattedOpeningDays, grouped = {}) => {
  const mapPlan = (plan) => {
    const { endTime, session, startTime } = plan
    const {
      archived,
      endTime: sessionEndTime,
      hourly,
      id,
      isHourly,
      name,
      priceChanges,
      startTime: sessionStartTime,
    } = session

    return {
      ...plan,
      endTime,
      sessionId: {
        endTime: sessionEndTime,
        isHourly: isHourly || hourly,
        label: `${name}${archived ? ` (${i18n.t('global:archived')})` : ''}`,
        priceChanges,
        startTime: sessionStartTime,
        value: id,
      },
      startTime,
    }
  }

  return _.reduce(formattedOpeningDays, (result, openingDay) => {
    const newResult = { ...result }

    newResult[openingDay] = grouped[openingDay] ? grouped[openingDay].map(mapPlan) : [{}]

    return newResult
  }, {})
}

export const getInitialValues = (isEditMode) => createSelector(
  [getNurseryData, getEnquiriesSingleDataSelector, getIsFinanceV3Enabled],
  (nurseryDetail, enquiry, isFinanceV3Enabled) => {
    if (!nurseryDetail || (isEditMode && !enquiry)) {
      return null
    }

    const { nurserySettings } = nurseryDetail || {}
    const { formattedOpeningDays } = nurserySettings

    if (!isEditMode) {
      return {
        enquiryDate: moment(),
        sessionPlans: getPlans(formattedOpeningDays),
      }
    }

    const { data, enquiryDate, nurseryClass, sessionPlans, sessionProductPlans, startDate } = enquiry || {}
    const {
      child,
      contact,
      enquiryReason,
      enquiryReasonOther,
      otherConsideredNurseries,
      source,
      sourceOther,
    } = data || {}
    const {
      address,
      email,
      firstName: contactFirstName,
      gender: contactGender,
      postCode,
      relation,
      surname: contactSurname,
      telephone,
    } = contact || {}
    const {
      birthDate,
      firstName: childFirstName,
      gender: childGender,
      surname: childSurname,
    } = child || {}

    const plans = isFinanceV3Enabled ? sessionProductPlans : sessionPlans

    return {
      data: {
        child: {
          birthDate: birthDate ? moment(birthDate) : undefined,
          firstName: childFirstName,
          gender: _.find(GENDER_DROPDOWN, { value: childGender }),
          surname: childSurname,
        },
        contact: {
          address,
          email,
          firstName: contactFirstName,
          gender: _.find(GENDER_DROPDOWN_WITH_OTHER, { value: contactGender }),
          postCode,
          relation: relation ? { label: relation, value: relation } : null,
          surname: contactSurname,
          telephone,
        },
        enquiryReason: enquiryReason ? { label: enquiryReason.name, value: enquiryReason.id } : undefined,
        enquiryReasonOther: enquiryReason ? enquiryReasonOther : undefined,
        otherConsideredNurseries,
        source: source ? { label: source.name, value: source.id } : undefined,
        sourceOther: source ? sourceOther : undefined,
      },
      enquiryDate: enquiryDate ? moment(enquiryDate) : undefined,
      nurseryClass: nurseryClass ? {
        label: `${nurseryClass.name}${nurseryClass.archived ? ` (${i18n.t('global:archived')})` : ''}`,
        value: nurseryClass.id,
      } : undefined,
      sessionPlans: plans?.length
        ? getPlans(formattedOpeningDays, _.groupBy(plans, 'dayOfWeek'))
        : undefined,
      startDate: startDate ? moment(startDate) : undefined,
    }
  },
)

export const getEnquiriesFormattedSingleDataSelector = createSelector(
  [getEnquiriesSingleDataSelector],
  (enquiryData) => {
    if (!enquiryData) {
      return null
    }

    const { data } = enquiryData
    const { enquiryReason, source } = data

    return {
      ...enquiryData,
      data: {
        ...enquiryData.data,
        isOtherReasonSelected: ENQUIRY_REASON_LABELS.Other === enquiryReason?.name,
        isOtherSourceSelected: SOURCE_LABELS.Other === source?.name,
      },
    }
  },
)
