import React from 'react'

import { generateRoute } from 'utils/routing'

import { FolderList, Page } from 'components'

import i18n from 'translations'

import { ROUTE_URL_TYPE } from './Common/constants'

const EnquiriesSettingsView = () => (
  <Page.Section title={i18n.t('module:Management:Enquiries:title')}>
    <FolderList>
      <FolderList.Item
        icon="enquiry-form"
        title={i18n.t('module:Management:Enquiries:EnquiryForm:title')}
        to={generateRoute('MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_FORM')}
      />
      <FolderList.Item
        icon="pipeline"
        title={i18n.t('module:Management:Enquiries:EnquiryPipeline:title')}
        to={generateRoute('MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_PIPELINE.INDEX')}
      />
      <FolderList.Item
        icon="source"
        title={i18n.t('module:Management:Enquiries:EnquirySource:title')}
        to={generateRoute('MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_COMMON.INDEX', {
          type: ROUTE_URL_TYPE.SOURCE,
        })}
      />
      <FolderList.Item
        icon="thumb-down"
        title={i18n.t('module:Management:Enquiries:LostReason:title')}
        to={generateRoute('MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_COMMON.INDEX', {
          type: ROUTE_URL_TYPE.LOST_REASON,
        })}
      />
      <FolderList.Item
        icon="reason"
        title={i18n.t('module:Management:Enquiries:EnquiryReason:title')}
        to={generateRoute('MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_COMMON.INDEX', {
          type: ROUTE_URL_TYPE.REASON,
        })}
      />
    </FolderList>
  </Page.Section>
)

export default EnquiriesSettingsView
