import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledLoader = styled.div`
  padding: 10px 5px 5px
`

export const StyledWrapperStory = styled.div`
  height: 400px;
  overflow-y: scroll;
`

export const StyledItemStory = styled.div`
  padding: 15px;
  margin-bottom: 5px;
  background: ${NEUTRAL_COLOURS.GRAY_TERTIARY};  
`
