import React, { useState } from 'react'
import { Link } from 'react-router'

import { COMMENT_TYPE } from 'services/comments/constants'
import { HomeObservation } from 'services/legacy/homeObservations/models'
import { Like as LikeModel } from 'services/likes/models'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'
import { generateRoute } from 'utils/routing'

import { Avatar, DateString, GroupAvatars, List, Space, Typography } from 'components'
import Like from 'module/LikesAndComments/components/Like/Like'

import i18n from 'translations'

import {
  StyledCarerContainer,
  StyledCarerText,
  StyledContainer,
  StyledLetter,
  StyledLikesWrapper,
  StyledObservationBadge,
  StyledSubContentWrapper,
  StyledText,
  StyledTextContainer,
} from './HomeObservationItemStyled'

interface HomeObservationItemProps {
  hasAccessToLikesAndComments: boolean
  hasAccessToLikesAndCommentsSettings: boolean
  observation: HomeObservation
}

const HomeObservationItem: React.FC<HomeObservationItemProps> = ({
  hasAccessToLikesAndComments,
  hasAccessToLikesAndCommentsSettings,
  observation,
}) => {
  const { carer, child, commentCount, comments, createdAt, id, likeCount, observations, userLike } = observation
  const { firstName, fullName, photo, surname } = carer

  const [likeCountFinal, setLikeCountFinal] = useState<number>(likeCount)
  const [userLikeFinal, setUserLikeFinal] = useState<LikeModel>(userLike)

  const hasObservations = observations && 0 < observations.length
  const borderColor = hasObservations ? getBrandingColor('tertiary-color') : getBrandingColor('primary-color')
  const to = hasObservations
    ? { pathname: `/learning/observations/${observations[0].id}`, query: { ref: 'home-observations' } }
    : null

  const handleLikeChange = (direction, response?) => {
    setLikeCountFinal(likeCountFinal + (direction ? 1 : -1))
    setUserLikeFinal(direction ? response : null)
  }

  const renderItemContent = (
    <StyledSubContentWrapper $hasAccessToLikesAndComments={hasAccessToLikesAndComments}>
      <div>
        <StyledContainer>
          <StyledCarerContainer>
            <Avatar
              avatarSize="small"
              initials={[firstName, surname]}
              src={photo}
            />
            <StyledCarerText>
              {fullName}
              {i18n.t('module:HomeObservation:List:addedAnObservationFromHome')}
            </StyledCarerText>
          </StyledCarerContainer>
          {to && (
            <div>
              <Link to={to}>
                <StyledTextContainer>
                  <StyledLetter>
                    <StyledObservationBadge>
                      {i18n.t('module:HomeObservation:List:P')}
                    </StyledObservationBadge>
                  </StyledLetter>
                  <StyledText>
                    {i18n.t('module:HomeObservation:List:viewFullObservation')}
                  </StyledText>
                </StyledTextContainer>
              </Link>
            </div>
          )}
        </StyledContainer>
        {comments}
      </div>
      {hasAccessToLikesAndComments && (
        <div>
          <StyledLikesWrapper>
            <Like
              likeCount={likeCountFinal}
              likeType={COMMENT_TYPE.HOME_OBSERVATION}
              objectId={id}
              preview={!hasAccessToLikesAndCommentsSettings}
              userLike={userLikeFinal}
              onChange={handleLikeChange}
            />
          </StyledLikesWrapper>
          <Space space="10px" />
          {(hasAccessToLikesAndCommentsSettings || (!hasAccessToLikesAndCommentsSettings && commentCount)) ? (
            <Link to={`${generateRoute('PARENT_INBOX.HOME_OBSERVATIONS.DETAILS', { id })}?section=comments`}>
              <Typography bold nowrap primary>
                {`${i18n.t('global:Comments')} (${commentCount})`}
              </Typography>
            </Link>
          ) : null}
        </div>
      )}
    </StyledSubContentWrapper>
  )

  return (
    <List.Standard.Row
      borderColor={borderColor}
      borderTitle={i18n.t('module:HomeObservation:List:observationFromHome')}
      content={renderItemContent}
      key={id}
      leftColumn={(
        <GroupAvatars
          backgroundColor={borderColor}
          borderColor={NEUTRAL_COLOURS.SILVER}
          persons={[child]}
          renderUrl={(element) => generateRoute('CHILDREN.CHILD', { childId: element.id })}
        />
      )}
      title={<DateString date={createdAt} />}
      to={generateRoute('PARENT_INBOX.HOME_OBSERVATIONS.DETAILS', { id })}
    />
  )
}

export default HomeObservationItem
