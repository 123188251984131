import _ from 'lodash'
import moment from 'moment'

export const getUpdateRegisterData = (state, childId, id) => {
  const mapIdItem = (item) => ({
    ...item,
    isSubmitting: _.some(item.registers, { id }),
  })

  const mapChildIdItem = (item) => {
    if (item.id === childId) {
      return { ...item, isSubmitting: true }
    }

    return item
  }

  return id ? _.map(state.data, mapIdItem) : _.map(state.data, mapChildIdItem)
}

export const getUpdateRegisterSuccessData = (state, childId, id, payload, isChildRegister) => {
  const mapIdItem = (item) => {
    if (item.id === id) {
      return {
        ...item,
        ...payload.data,
        date: moment().format('x'),
        isSubmitting: false,
      }
    }

    return item
  }

  const mapChildIdItem = (child) => {
    if (child.id === childId) {
      return {
        ...child,
        date: moment().format('x'),
        isSubmitting: false,
        registers: [{ ...payload.data }],
      }
    }

    return child
  }

  return isChildRegister ? _.map(state.data, mapIdItem) : _.map(state.data, mapChildIdItem)
}

export const addOrUpdateAbsenceToList = (state, childId, absenceResponse) => _.map(state.data, (item) => {
  if (item.id === childId) {
    const { registers, ...absence } = absenceResponse

    return {
      ...item,
      date: moment().format('x'),
      registers: [{
        ...(registers?.[0] || {}),
        absence,
      }],
    }
  }

  return item
})

export const removeAbsenceFromList = (state, childId) => _.map(state.data, (item) => {
  if (item.id === childId) {
    return {
      ...item,
      date: moment().format('x'),
      registers: [{
        ...item.registers[0],
        absence: undefined,
        present: null,
      }],
    }
  }

  return item
})

export const resetListItem = (state, childId, id, resetResponse) => _.map(state.data, (item) => {
  if (item.id === childId) {
    return {
      ...item,
      date: moment().format('x'),
      registers: [{
        ...item.registers[0],
        ...resetResponse,
        comments: '',
        signIns: undefined,
      }],
    }
  }

  return item
})

export const removeItemFromList = (state, childId) => _.filter(state.data, (item) => item.id !== childId)
