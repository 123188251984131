import React, { PropsWithChildren } from 'react'

import { Button, Typography } from 'components'

import i18n from 'translations'

import PanelItem from './PanelItem'
import { StyledPanelBody, StyledPanelContainer, StyledPanelHeader } from './PanelStyled'

interface PanelRoot<T> extends React.FC<PropsWithChildren<T>> {
  Item?: typeof PanelItem
}

interface PanelProps {
  title?: string
  to?: string
}

const Panel: PanelRoot<PanelProps> = ({
  children,
  title,
  to,
}) => (
  <StyledPanelContainer>
    <StyledPanelHeader>
      <Typography bold>
        {title}
      </Typography>
      <Button
        hierarchy="tertiary"
        label={i18n.t('global:Edit')}
        size="small"
        to={to}
      />
    </StyledPanelHeader>
    <StyledPanelBody>
      {children}
    </StyledPanelBody>
  </StyledPanelContainer>
)

export default Panel
