import React from 'react'

import { generateRoute } from 'utils/routing'

import { Button, DetailsBreakdown, NurseryLogo, Page, Section, Spinner } from 'components'

import i18n from 'translations'

import FeatureFlagsSection from './components/FeatureFlagsSection'

import { StyledAddNurseryWrapper } from './OrganisationsPreviewStyled'

const OrganisationsPreviewView = ({ featureFlags, isFetching, organisation }) => {
  const { id, logo, name, numberOfNurseries, organizationSettings, subdomain, subdomainSuffix } = organisation || {}
  const { countryName, currencyName } = organizationSettings || {}

  const renderDetails = () => (
    <DetailsBreakdown>
      <DetailsBreakdown.Section>
        <DetailsBreakdown.Item
          label={i18n.t('global:Name')}
          value={name}
        />
        <DetailsBreakdown.Item
          label={i18n.t('global:Subdomain')}
          value={subdomain}
        />
        <DetailsBreakdown.Item
          label={i18n.t('module:Organisations:Add:Form:country')}
          value={countryName}
        />
        <DetailsBreakdown.Item
          label={i18n.t('module:Organisations:Add:Form:currency')}
          value={currencyName}
        />
        <DetailsBreakdown.Item
          label={i18n.t('global:Logo')}
          value={<NurseryLogo alt={name} logo={logo} />}
        />
        <DetailsBreakdown.Item
          label={i18n.t('module:Organisations:Add:Form:numberOfNurseries')}
          value={(
            <StyledAddNurseryWrapper>
              {numberOfNurseries}
              <Button
                hierarchy="secondary"
                icon="plus"
                label={i18n.t('module:Organisations:Add:Form:addNursery')}
                to={`${generateRoute('NURSERIES.ADD')}?organisation=${id},${subdomainSuffix},${name}`}
                negativeVerticalMargins
              />
            </StyledAddNurseryWrapper>
          )}
        />
      </DetailsBreakdown.Section>
      <FeatureFlagsSection featureFlags={featureFlags} isOrganizationContext />
    </DetailsBreakdown>
  )

  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    return renderDetails()
  }

  const actions = (
    <Section.Actions tertiary={{ to: generateRoute('ORGANISATIONS.EDIT', { organisationId: id }) }} />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isFetching}
      title={i18n.t('module:Organisations:Preview:title')}
    >
      {renderContent()}
    </Page.Section>
  )
}

export default OrganisationsPreviewView
