export const GET_PAYOUT_SUCCESS = 'NURSERY_INTEGRATIONS/GET_PAYOUT_SUCCESS'
export const GET_PAYOUT_FAILED = 'NURSERY_INTEGRATIONS/GET_PAYOUT_FAILED'
export const GET_PAYOUT = 'NURSERY_INTEGRATIONS/GET_PAYOUT'

export const REMOVE_INTEGRATION = 'NURSERY_INTEGRATIONS/REMOVE_INTEGRATION'
export const REMOVE_INTEGRATION_SUCCESS = 'NURSERY_INTEGRATIONS/REMOVE_INTEGRATION_SUCCESS'
export const REMOVE_INTEGRATION_FAILED = 'NURSERY_INTEGRATIONS/REMOVE_INTEGRATION_FAILED'

export const UPDATE_INTEGRATION = 'NURSERY_INTEGRATIONS/UPDATE_INTEGRATION'
export const UPDATE_INTEGRATION_SUCCESS = 'NURSERY_INTEGRATIONS/UPDATE_INTEGRATION_SUCCESS'
export const UPDATE_INTEGRATION_FAILED = 'NURSERY_INTEGRATIONS/UPDATE_INTEGRATION_FAILED'

export const CLEAR_PAYOUT = 'NURSERY_INTEGRATIONS/CLEAR_PAYOUT'
