import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class TransactionsApiClient extends ServiceBase {
  list = (params) => this.request({
    path: `/v2/transactions?${getQueryStringV2(params)}`,
  })

  download = (childId, params) => this.request({
    download: true,
    path: `/v2/children/${childId}/report/statement-of-account/download?${getQueryStringV2(params)}`,
  })

  send = (childId, params) => this.request({
    path: `/v2/children/${childId}/statement_of_account/send?${getQueryStringV2(params)}`,
  })
}

export default TransactionsApiClient
