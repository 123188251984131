import i18n from 'translations'

import { STATISTIC_TYPES } from 'services/legacy/deposits/constants'

export const STATISTIC_OPTIONS = [
  {
    key: STATISTIC_TYPES.RAISED,
    label: i18n.t('module:Finance:Deposits:List:StatisticsOptions:raised'),
  },
  {
    key: STATISTIC_TYPES.HELD,
    label: i18n.t('module:Finance:Deposits:List:StatisticsOptions:held'),
  },
  {
    key: STATISTIC_TYPES.REFUNDED,
    label: i18n.t('module:Finance:Deposits:List:StatisticsOptions:refunded'),
  },
  {
    key: STATISTIC_TYPES.PENDING_DEPOSITS,
    label: i18n.t('module:Finance:Deposits:List:StatisticsOptions:pendingDeposits'),
  },
  {
    key: STATISTIC_TYPES.PENDING_REFUNDS,
    label: i18n.t('module:Finance:Deposits:List:StatisticsOptions:pendingRefunds'),
  },
]

