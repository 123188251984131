import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledActionPlan = styled.div`
  display: flex;
  margin: -10px 0 20px;
  flex-direction: column;
`

export const StyledHeader = styled.header`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-column-gap: 20px;  
  margin-bottom: 10px;
`

export const StyledPreview = styled.div`
  padding: 15px;
  background: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};  
`
