import _ from 'lodash'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

import { Avatar, DropdownMenu, GroupAvatars, Typography } from 'components'

import i18n from 'translations'

import ChildSelect from '../ChildSelect'

import {
  StyledChannelHeader,
  StyledConversationMembers,
  StyledDropdownWrapper,
  StyledGroupAvatarsContainer,
} from './ChatHeaderStyled'

const ChatHeader = ({
  channel,
  channelMembers,
  isNewChannelPage,
  onChildSelect,
  onLeaveChannel,
  onShowConversationMembers,
  unsubscribedAt,
}) => {
  if (isNewChannelPage) {
    return (
      <StyledChannelHeader>
        <ChildSelect
          onChildSelect={onChildSelect}
        />
      </StyledChannelHeader>
    )
  }

  return (
    <StyledChannelHeader
      hiddenDropdown={unsubscribedAt}
    >
      <Avatar
        avatarSize="medium_big"
        initials={channel?.name?.split(' ')}
        src={channel?.photo}
        title={(
          <Typography color={NEUTRAL_COLOURS.BASIC} fontSize={18} variant="span">
            {channel?.name}
          </Typography>
        )}
      />
      <StyledGroupAvatarsContainer
        amount={channelMembers.length}
        onClick={onShowConversationMembers}
      >
        <GroupAvatars
          avatarSize="small"
          borderColor={NEUTRAL_COLOURS.GRAY_TERTIARY}
          borderWidth={2}
          count={channelMembers.length}
          persons={_.map(channelMembers, ({ member }) => member)}
          disableRestAmount
          withoutText
        />
        <Typography
          align="left"
          color={getBrandingColor('primary-color')}
          margin="0 15px 0 0"
        >
          {channelMembers.length}
        </Typography>
      </StyledGroupAvatarsContainer>
      <StyledDropdownWrapper hidden={unsubscribedAt}>
        <DropdownMenu
          minWidth={240}
        >
          <StyledConversationMembers>
            <DropdownMenu.Item
              icon="contact"
              label={i18n.t('module:Messaging:MessagingList:conversationMembers')}
              onClick={onShowConversationMembers}
            />
          </StyledConversationMembers>
          {!unsubscribedAt && (
            <DropdownMenu.Item
              icon="leave"
              label={i18n.t('module:Messaging:MessagingList:leaveConversation')}
              onClick={onLeaveChannel}
            />
          )}
        </DropdownMenu>
      </StyledDropdownWrapper>
    </StyledChannelHeader>
  )
}

export default React.memo(ChatHeader)
