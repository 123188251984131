import React from 'react'

import { DISPLAY_DATE_TIME_FORMAT } from 'constants/date'

import { toFloat } from 'utils/data'

import constants from 'services/legacy/invoices/constants'

import { Currency, DateString, LoadingDots } from 'components'

import {
  StyledCellHeaderContainer,
  StyledCellValueContainer,
  StyledContainer,
  StyledRowContainer,
  StyledStatus,
  StyledStatusContainer,
  StyledStatusTitle,
  StyledTable,
  StyledTablesContainer,
} from './InvoiceHeaderStyled'

const InvoiceHeader = ({ invoicePreview }) => {
  const { invoice = {} } = invoicePreview || {}
  const {
    creditCarried,
    dueDate,
    issueDate,
    number,
    paymentsTotal,
    sentAt,
    status,
    total,
    totalOutstanding,
  } = invoice

  const renderRow = (label, value) => (
    <StyledRowContainer>
      <StyledCellHeaderContainer>
        {label}
      </StyledCellHeaderContainer>
      <StyledCellValueContainer>
        {value}
      </StyledCellValueContainer>
    </StyledRowContainer>
  )

  const renderDetails = () => (
    <StyledTable right>
      <tbody>
        <tr>
          <th>
            Date invoiced
          </th>
          <td>
            {<DateString date={issueDate} /> || '-'}
          </td>
        </tr>
        <tr>
          <th>
            Due date
          </th>
          <td>
            {<DateString date={dueDate} /> || '-'}
          </td>
        </tr>
        <tr>
          <th>
            Sent date
          </th>
          <td>
            {sentAt ? <DateString date={sentAt} format={DISPLAY_DATE_TIME_FORMAT} /> : '-'}
          </td>
        </tr>
      </tbody>
    </StyledTable>
  )

  const creditCarriedVal = 0 > creditCarried ? 0 : creditCarried
  const paidAmount = toFloat(paymentsTotal) + toFloat(creditCarriedVal)

  return (
    <StyledContainer>
      <StyledStatusContainer>
        <StyledStatusTitle>
          Invoice status
        </StyledStatusTitle>
        <StyledStatus status={status && status.toUpperCase()}>
          {constants.STATUSES[status]}
          {status === constants.STATUS_TYPES.SENDING && (
            <LoadingDots />
          )}
        </StyledStatus>
        <br />
        {renderRow('Paid', <Currency value={paidAmount} />)}
        {renderRow('Unpaid', 0 < totalOutstanding ? <Currency value={totalOutstanding} /> : '-')}
        {renderRow((<strong>Total</strong>), <Currency value={total} bold />)}
      </StyledStatusContainer>
      <StyledTablesContainer>
        <StyledTable>
          <tbody>
            <tr>
              <th>
                Invoice number
              </th>
              <td>
                {number || '-'}
              </td>
            </tr>
          </tbody>
        </StyledTable>
        {renderDetails()}
      </StyledTablesContainer>
    </StyledContainer>
  )
}

export default InvoiceHeader
