import React from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'
import { Field, InjectedFormProps, getFormValues, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { Form, Section, Space, Spinner, Typography } from 'components'

import i18n from 'translations'

import { StaffAssignedToRooms } from 'module/Management/ManagementParentApp/ParentCommunication/components'
import { NEUTRAL_COLOURS } from 'constants/colors'
import { ROLES } from 'constants/security'
import { ACCESS_VIEW_OPTIONS, ACCESS_VIEW_TYPES } from './constants'

export const PRACTITIONER_CHILD_ACCESS_FORM = 'PractitionerChildAccessForm'

interface PractitionerChildAccessFormProps {
  formValues: PractitionerChildAccessFormVales
  isRoomFetching?: boolean
  onAccessViewTypeChange?: (value: string) => void
  onRoomListReload?: () => void
  rooms?: any
}

export interface PractitionerChildAccessFormVales {
  accessViewType?: string
}

type PractitionerChildAccessFormFullProps = InjectedFormProps<{}, PractitionerChildAccessFormProps>
  & PractitionerChildAccessFormProps
  & PropsFromRedux

const mapState = (state) => ({
  formValues: getFormValues(PRACTITIONER_CHILD_ACCESS_FORM)(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const PractitionerChildAccessForm: React.FC<PractitionerChildAccessFormFullProps> = ({
  formValues,
  isRoomFetching,
  onAccessViewTypeChange,
  onRoomListReload,
  rooms,
}) => {
  const { accessViewType } = formValues || {}

  return (
    <Form onSubmit={() => {}}>
      <Typography fontSize={18}>
        {i18n.t('module:Management:Security:PractitionerChildAccess:subtitle')}
      </Typography>
      <Space margin="20px 0 0" />
      <Form.Row>
        <Field
          component={Form.RadioGroup}
          name="accessViewType"
          options={ACCESS_VIEW_OPTIONS}
          validate={isRequired}
          onItemClick={onAccessViewTypeChange}
        />
      </Form.Row>
      {accessViewType === ACCESS_VIEW_TYPES.ONLY_OWN_ROOMS && (
        <Section title={i18n.t('global:Rooms')}>
          <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
            {i18n.t('module:Management:Security:PractitionerChildAccess:Rooms:subtitle')}
          </Typography>
          {isRoomFetching
            ? <Spinner />
            : (
              <StaffAssignedToRooms
                membershipRoles={[
                  ROLES.TEACHER,
                  ROLES.SENIOR_TEACHER,
                  ROLES.ROOM_LEADER,
                ]}
                rooms={rooms}
                sourceModule="PractitionerAccess"
                onRoomListsReload={onRoomListReload}
              />
            )}
        </Section>
      )}
    </Form>
  )
}

const enhance = compose(
  reduxForm<{}, PractitionerChildAccessFormProps>({ form: PRACTITIONER_CHILD_ACCESS_FORM }),
  connector,
)

export default enhance(PractitionerChildAccessForm)
