import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center; 
  margin: 16px 0;
`

export const StyledLogoContainer = styled.div`
  position: relative;
`

export const StyledHexagon = styled.img`
  height: 70px;
  width: 70px;
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, .4));
`

export const StyledLogo = styled.img`
  position: absolute;
  height: 45px;
  left: 19px;
  top: 12px;
`

export const StyledContentContainer = styled.div`
  position: relative;
  display: flex;
`

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  font-size: 20px;
  padding: 0 20px;
  border-radius: 20px;
  box-shadow: -2px 1px 14px 0 rgba(0,0,0,.4);

  &:before {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    left: 0;
    border: 10px solid transparent;
    border-right-color: ${NEUTRAL_COLOURS.WHITE};
  }
`

export const StyledPrimaryText = styled.div`
  display: flex;
  color: ${getBrandingColor('primary-color')}
`
