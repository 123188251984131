import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledContainer = styled.div`
  position: relative;

  &:before {
    display: block;
    content: '';
    width: 3px;
    background: ${getBrandingColor('primary-color')};
    height: calc(100% - 10px);
    position: absolute;
    top: 5px;
    left: 22px;
  }
`
