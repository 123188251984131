import _ from 'lodash'
import i18n from 'translations'

const TABLE_COLUMNS = [
  {
    field: 'openingBalance',
    title: i18n.t('module:Finance:Reports:Balances:Table:Header:openingBalance'),
  },
  {
    field: 'invoices',
    title: i18n.t('module:Finance:Reports:Balances:Table:Header:invoices'),
  },
  {
    field: 'creditNotes',
    title: i18n.t('module:Finance:Reports:Balances:Table:Header:creditNotes'),
  },
  {
    field: 'balanceAdjustments',
    title: i18n.t('module:Finance:Reports:Balances:Table:Header:balanceAdjustments'),
  },
  {
    field: 'payments',
    title: i18n.t('module:Finance:Reports:Balances:Table:Header:payments'),
  },
  {
    field: 'closingBalance',
    title: i18n.t('module:Finance:Reports:Balances:Table:Header:closingBalance'),
  },
]

export const NURSERY_TABLE_COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'name',
    title: _.upperFirst(i18n.t('global:child')),
  },
  ...TABLE_COLUMNS,
]

export const ORGANIZATION_TABLE_COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'name',
    title: _.upperFirst(i18n.t('global:site')),
  },
  ...TABLE_COLUMNS,
]
