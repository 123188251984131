import { generateBasicActions } from 'utils/service'

import {
  ADD_TOILET_TRAINING,
  ADD_TOILET_TRAINING_FAILED,
  ADD_TOILET_TRAINING_SUCCESS,
  REMOVE_TOILET_TRAINING,
  REMOVE_TOILET_TRAINING_FAILED,
  REMOVE_TOILET_TRAINING_SUCCESS,
  UPDATE_TOILET_TRAINING,
  UPDATE_TOILET_TRAINING_FAILED,
  UPDATE_TOILET_TRAINING_SUCCESS,
} from './constants'

export const addRecord = (options = {}) => ({ dailyDiaryToiletTrainingApiClient }) => generateBasicActions.create({
  constants: {
    failed: ADD_TOILET_TRAINING_FAILED,
    init: ADD_TOILET_TRAINING,
    success: ADD_TOILET_TRAINING_SUCCESS,
  },
  options,
  service: dailyDiaryToiletTrainingApiClient,
  serviceMethod: 'addRecord',
})

export const removeRecord = (id, options = {}) => ({ dailyDiaryToiletTrainingApiClient }) => (dispatch) => {
  const { onSuccess } = options

  dispatch({ id, type: REMOVE_TOILET_TRAINING })

  dailyDiaryToiletTrainingApiClient.removeRecord(id).then(
    () => {
      dispatch({ id, type: REMOVE_TOILET_TRAINING_SUCCESS })

      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: REMOVE_TOILET_TRAINING_FAILED,
      })
    },
  )
}

export const updateRecord = (id, options = {}) => ({ dailyDiaryToiletTrainingApiClient }) => (dispatch) => {
  const { body, onFailed, onSuccess } = options

  dispatch({ type: UPDATE_TOILET_TRAINING })

  dailyDiaryToiletTrainingApiClient.updateRecord(id, body).then(
    ({ data }) => {
      dispatch({ payload: data, type: UPDATE_TOILET_TRAINING_SUCCESS })

      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: UPDATE_TOILET_TRAINING_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}
