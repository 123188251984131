import styled from 'styled-components'

import layout from 'constants/layout'

export const StyledRemoveButton = styled.div`
  margin-top: 25px;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    margin-top: 0;
  }
`

export const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 200px 200px 200px;
  grid-column-gap: 10px;

  @media(max-width: 960px) {
    grid-template-columns: auto auto auto;
  }
`

export const StyledColumn = styled.div`
  margin-bottom: 5px;
  
  ${({ marginBottom }) => marginBottom && `
    margin-bottom: 15px;
  `}
`

export const StyledAgeGroups = styled.div`
  display: flex;
  flex-direction: row;

  @media(max-width: 400px) {
    flex-direction: column;
  }
`

export const StyledPause = styled.div`
  font-size: 14px;
  margin: 14px 10px 0 10px;

  @media(max-width: 400px) {
    margin: 5px 10px;
  }
`

export const StyledRatio = styled.div`
  display: flex;
  flex-direction: row;
`

export const StyledRatioSuffix = styled.div`
  font-size: 14px;
  margin: 14px 10px 0 5px;
`

export const StyledUsedForExpertLabel = styled.div`
  display: flex;
`

export const StyledTooltip = styled.div`
  margin-left: 5px;
`
