import styled from 'styled-components'

const widgetBackground = '#ededed'
const titleBackground = '#f5f6f6'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${widgetBackground};
  min-width: 150px;
  border-radius: 5px;
  overflow: hidden;
  padding-bottom: 10px;

  &:not(:first-child) {
    margin-left: 15px;
  }
`

export const StyledTitleContainer = styled.div`
  display: flex;
  height: 28px;
  background-color: ${titleBackground};
  justify-content: center;
  align-items: center;
`

export const StyledProgressContainer = styled.div`
  margin: 0 8px;
`

export const StyledTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
`

export const StyledProgressTextContainer = styled.div`
  color: #000;
  font-weight: 600;
`
