import React, { useEffect } from 'react'
import { compose } from 'recompose'

import { SelectProps } from 'components/Select/Select'
import { InfinityDropdownProps } from 'components/InfiniteDropdowns/InfiniteDropdownsModel'

import { withNurserySessionsV3Service, withNurserySessionsV3ServiceProps } from 'services/product/nurserySessionsV3'

import { Select } from 'components'

import i18n from 'translations'

// eslint-disable-next-line import-newlines/enforce, max-len
import withInfiniteDropdownHelpers, { withInfiniteDropdownHelpersProps } from '../../legacy/InfiniteDropdowns/withInfiniteDropdown'

export type NurserySessionsV3DropdownProps = withInfiniteDropdownHelpersProps
  & withNurserySessionsV3ServiceProps
  & SelectProps
  & InfinityDropdownProps

const NurserySessionsV3Dropdown: React.FC<NurserySessionsV3DropdownProps> = ({
  extraFields = [],
  groups,
  infiniteDropdownHelpers,
  nurserySessionsV3Actions,
  onChange,
  value,
  placeholder,
  ...rest
}) => {
  useEffect(() => {
    infiniteDropdownHelpers.handleComponentDidMount({
      action: () => nurserySessionsV3Actions.get({
        onFailed: () => onChange(null),
        onSuccess: infiniteDropdownHelpers.handleComponentDidMountOnSuccess,
        onlyData: true,
        params: [(value as any)?.value || value],
      }),
      value,
    })
  }, [])

  const listAction = ({ newPage, newSearchPhrase, ...others }) => {
    const criteria = [
      {
        field: 'archived',
        value: 0,
      },
    ]

    if (newSearchPhrase) {
      criteria.push({
        field: 'name',
        value: newSearchPhrase,
      })
    }

    return nurserySessionsV3Actions.dropdownList({
      onSuccess: infiniteDropdownHelpers.handleLoadMoreElementsOnSuccessV2({
        extraFields,
        newPage,
        newSearchPhrase,
        ...others,
      }),
      params: {
        criteria,
        groups,
        page: newPage,
      },
    })
  }

  return (
    <Select
      {...infiniteDropdownHelpers.getSelectRestParams({ onChange, value, ...rest })}
      loadOptions={infiniteDropdownHelpers.handleLoadMoreElementsV2({ listAction })}
      placeholder={placeholder || i18n.t('components:InfiniteDropdowns:NurserySessionsV3:placeholder')}
    />
  )
}

const enhance = compose(
  withNurserySessionsV3Service,
  withInfiniteDropdownHelpers,
)

export default enhance(NurserySessionsV3Dropdown)
