import _ from 'lodash'

import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'
// eslint-disable-next-line max-len, import-newlines/enforce
import { AddDiscountFormValues } from 'module/Management/ManagementFinanceSettings/ManagementDiscounts/v3/ManagementDiscountsV3Add/components/AddDiscountForm'

import { convertDecimalToInteger, toFloat } from 'utils/data'
import { createTypeFromEnumValues } from 'utils/typescript'

import { ApplicableValues, NURSERY_DISCOUNT_BEFORE_AFTER, NurseryDiscountTypesV3 } from '../constants'

interface BodyReturnProps {
  name?: string
  settings: {
    allowOverride?: boolean
    applicable: createTypeFromEnumValues<ApplicableValues>[]
    applicableBeforeFunding?: boolean
    value: number
  }
  type?: string
}

export const getNurseryDiscountsSelector = (state: RootState) => state.nurseryDiscountsV3

export const getNurseryDiscountsSingleSelector = createSelector(
  [getNurseryDiscountsSelector],
  (state) => state.single.data,
)

export const getBody = (fields: AddDiscountFormValues): BodyReturnProps => {
  if (!fields) {
    return null
  }

  const {
    allowOverride,
    applicableBeforeFunding,
    consumables,
    extraItems,
    extraSessions,
    name,
    regularItems,
    sessions,
    type,
    value,
  } = fields

  const applicable = []

  if (NurseryDiscountTypesV3.PERCENTAGE === type) {
    if (sessions) {
      applicable.push(ApplicableValues.SESSIONS)
    }

    if (extraSessions) {
      applicable.push(ApplicableValues.EXTRA_SESSIONS)
    }

    if (regularItems) {
      applicable.push(ApplicableValues.REGULAR_ITEMS)
    }

    if (extraItems) {
      applicable.push(ApplicableValues.EXTRA_ITEMS)
    }

    if (consumables && sessions) {
      applicable.push(ApplicableValues.CONSUMABLES)
    }
  }

  return {
    name,
    settings: {
      allowOverride,
      applicable,
      applicableBeforeFunding: NurseryDiscountTypesV3.PERCENTAGE === type && sessions && applicableBeforeFunding
        ? applicableBeforeFunding !== NURSERY_DISCOUNT_BEFORE_AFTER.AFTER
        : undefined,
      value: NurseryDiscountTypesV3.AMOUNT === type ? convertDecimalToInteger(+value) : toFloat(value) * 100,
    },
    type,
  }
}

export const getArchivePayload = (fields) => {
  if (!fields) {
    return null
  }

  const { archived, type } = fields

  return {
    archived,
    type,
  }
}

export const isArchived = createSelector(
  [getNurseryDiscountsSingleSelector],
  (discount) => {
    if (!discount) {
      return null
    }

    return discount.archived
  },
)

export const getFormInitialValues = (discount) => {
  const { name, settings, type } = discount

  if (NurseryDiscountTypesV3.PERCENTAGE === type) {
    return {
      allowOverride: settings.allowOverride,
      applicableBeforeFunding: settings.applicableBeforeFunding
        ? NURSERY_DISCOUNT_BEFORE_AFTER.BEFORE
        : NURSERY_DISCOUNT_BEFORE_AFTER.AFTER,
      consumables: _.includes(settings.applicable, ApplicableValues.CONSUMABLES),
      extraItems: _.includes(settings.applicable, ApplicableValues.EXTRA_ITEMS),
      extraSessions: _.includes(settings.applicable, ApplicableValues.EXTRA_SESSIONS),
      name,
      regularItems: _.includes(settings.applicable, ApplicableValues.REGULAR_ITEMS),
      sessions: _.includes(settings.applicable, ApplicableValues.SESSIONS),
      type,
      value: toFloat(settings.value) / 100,
    }
  }

  return {
    allowOverride: settings.allowOverride,
    name,
    type,
    value: NurseryDiscountTypesV3.AMOUNT === type ? settings.value / 100 : toFloat(settings.value),
  }
}

export const getDefaultFormValues = () => ({
  applicableBeforeFunding: NURSERY_DISCOUNT_BEFORE_AFTER.AFTER,
  consumables: true,
  extraItems: true,
  extraSessions: true,
  regularItems: true,
  sessions: true,
  type: NurseryDiscountTypesV3.PERCENTAGE,
  value: 0,
})

export const getInitialValues = (discount, isEdit) => {
  if (!isEdit) {
    return getDefaultFormValues()
  }

  if (!discount) {
    return null
  }

  return getFormInitialValues(discount)
}
