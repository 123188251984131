import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

interface StyledContainerProps {
  $disabled?: boolean
  $isSelected?: boolean
}

export const StyledContainer = styled.div<StyledContainerProps>`
  display: grid;
  grid-template-rows: auto auto;
  min-width: 292px;
  background-color: ${({ $disabled, $isSelected }) => {
    if ($isSelected) {
      return getBrandingColor('primary-color')
    }

    if ($disabled) {
      return NEUTRAL_COLOURS.GRAY_SECONDARY
    }

    return NEUTRAL_COLOURS.GRAY_TERTIARY
  }};
  border-radius: 8px;
  align-items: center;
  height: 229px;
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  padding: 20px;

  && .webfont {
    color: ${({ $isSelected }) => ($isSelected ? NEUTRAL_COLOURS.WHITE : NEUTRAL_COLOURS.GRAY)} !important;
  }

  && > p {
    color: ${({ $isSelected }) => ($isSelected ? NEUTRAL_COLOURS.WHITE : NEUTRAL_COLOURS.BASIC)} !important;
  }
`

export const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: center;
`
