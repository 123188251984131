import React from 'react'

import { generateRoute } from 'utils/routing'

import { FolderList, Page } from 'components'

import i18n from 'translations'

const SecurityView = ({ PractitionerChildAccess }) => (
  <Page.Section title={i18n.t('module:Management:Security:title')}>
    <FolderList>
      <FolderList.Item
        icon="settings"
        title={i18n.t('module:Management:Security:GeneralSecuritySettings:title')}
        to={generateRoute('MANAGEMENT.SECURITY.SECURITY_SETTINGS')}
      />
      <FolderList.Item
        icon="authorised-devices"
        title={i18n.t('module:Management:Security:AuthorisedDevices:AuthorisedDevicesList:title')}
        to={generateRoute('MANAGEMENT.SECURITY.DEVICES')}
      />
      <FolderList.Item
        icon="access-logs"
        title={i18n.t('module:Management:Security:AccessLogsList:title')}
        to={generateRoute('MANAGEMENT.SECURITY.AUTHENTICATION_LOGS')}
      />
      {PractitionerChildAccess && (
        <FolderList.Item
          icon="observations"
          title={i18n.t('module:Management:Security:PractitionerChildAccess:title')}
          to={generateRoute('MANAGEMENT.SECURITY.PRACTITIONER_CHILD_ACCESS')}
        />
      )}
    </FolderList>
  </Page.Section>
)

export default SecurityView
