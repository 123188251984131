import moment from 'moment'

import { createSelector } from 'reselect'

import { ACTIVITY_TYPE } from '../constants'

const getEnquiryActivities = (state) => state.enquiryActivities

export const getEnquiryActivitiesSingleSelector = createSelector(
  [getEnquiryActivities],
  (state) => state?.single,
)

export const getPayload = (formValues) => {
  if (!formValues) {
    return null
  }

  const {
    activityDate,
    activityTime,
    enquiryId,
    note,
    type,
  } = formValues

  return {
    activityDate: moment(activityDate)
      .set({
        hour: activityTime.get('hour'),
        minute: activityTime.get('minute'),
        second: activityTime.get('second'),
      })
      .toISOString(),
    enquiry: {
      id: +enquiryId,
    },
    note,
    type,
  }
}

export const isActivityViewingType = (type) => ACTIVITY_TYPE.VIEWING.value === type
