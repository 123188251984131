import React, { PropsWithChildren } from 'react'

import { SearchBar, Typography } from 'components'
import { SearchBarProps } from 'components/SearchBar'

import { StyledActionContainer, StyledHeader, StyledSearchWrapper, StyledTitle } from './ConversationsStyled'

interface ConversationsHeaderProps {
  actions?: React.ReactNode
  searchBarProps?: SearchBarProps
  title?: string | React.ReactNode
}

const ConversationsHeader: React.FC<PropsWithChildren<ConversationsHeaderProps>> = ({
  actions,
  searchBarProps,
  title,
}) => (
  <StyledHeader>
    <StyledTitle>
      <Typography fontSize={24} variant="h4">
        {title}
      </Typography>
      {actions && (
        <StyledActionContainer>
          {actions}
        </StyledActionContainer>
      )}
    </StyledTitle>
    {searchBarProps ? (
      <StyledSearchWrapper>
        <SearchBar {...searchBarProps} />
      </StyledSearchWrapper>
    ) : <div />}
  </StyledHeader>
)

export default ConversationsHeader
