import React, { PropsWithChildren } from 'react'

import { StyledRowItem } from './FormRowStyled'

interface FormRowItemProps {
  margin?: string
}

const FormRowItem: React.FC<PropsWithChildren<FormRowItemProps>> = ({ children, margin }) => (
  <StyledRowItem $margin={margin}>
    {children}
  </StyledRowItem>
)

export default FormRowItem
