import { getBasicListInitialState, getBasicListReducers } from 'utils/reducer'

import {
  CLEAR_NURSERIES_MONTHLY_FTE_CHANGE_REPORT,
  NURSERIES_MONTHLY_FTE_CHANGE_REPORT,
  NURSERIES_MONTHLY_FTE_CHANGE_REPORT_FAILED,
  NURSERIES_MONTHLY_FTE_CHANGE_REPORT_SUCCESS,
} from './constants'

export default (...props) => {
  const [state = getBasicListInitialState] = props

  const basicListReducer = getBasicListReducers({
    clear: CLEAR_NURSERIES_MONTHLY_FTE_CHANGE_REPORT,
    failed: NURSERIES_MONTHLY_FTE_CHANGE_REPORT_FAILED,
    init: NURSERIES_MONTHLY_FTE_CHANGE_REPORT,
    success: NURSERIES_MONTHLY_FTE_CHANGE_REPORT_SUCCESS,
  }, getBasicListInitialState, props)

  if (null !== basicListReducer) {
    return basicListReducer
  }

  return state
}
