import React, { PropsWithChildren } from 'react'

import ProfileHeaderDetailsLeft from './ProfileHeaderDetailsLeft'
import ProfileHeaderDetailsMiddle from './ProfileHeaderDetailsMiddle'
import ProfileHeaderDetailsRight from './ProfileHeaderDetailsRight'
import { StyledChildDetails } from './ProfileHeaderStyled'

interface ProfileHeaderDetailsRoot extends React.FC<PropsWithChildren<any>> {
  Left?: typeof ProfileHeaderDetailsLeft
  Middle?: typeof ProfileHeaderDetailsMiddle
  Right?: typeof ProfileHeaderDetailsRight
}

const ProfileHeaderDetails: ProfileHeaderDetailsRoot = ({ children }) => (
  <StyledChildDetails>
    {children}
  </StyledChildDetails>
)

export default ProfileHeaderDetails
