import _ from 'lodash'

import i18n from 'translations'

export const getColumns = (hasPermissionToEdit) => ([
  {
    align: 'left',
    alignTh: 'left',
    field: 'type',
    title: _.upperFirst(i18n.t('global:type')),
    width: '200px',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'date',
    title: _.upperFirst(i18n.t('global:date')),
    width: '220px',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'hours',
    title: _.upperFirst(i18n.t('global:hours')),
    width: '80px',
  },
  ...hasPermissionToEdit ? [{
    field: 'action',
    width: '40px',
  }] : [],
])
