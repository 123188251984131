import React from 'react'

import { BasicViewProps, Nullable } from 'constants/models'
import { TYPES } from 'components/DropdownMenu/constants'
import { Page, Section, Spinner } from 'components'

import i18n from 'translations'

import { FormFields } from './models'
import AddForm from './components/AddForm'

export interface AddViewProps extends BasicViewProps {
  initialValues: Nullable<FormFields>
  isArchived?: Nullable<boolean>
  onArchiveClick?: (archive: boolean) => any
}

const AddView = ({
  initialValues,
  isArchived,
  isEdit,
  isLoading,
  isSubmitting,
  onArchiveClick,
  onSubmit,
}: AddViewProps) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <AddForm
        initialValues={initialValues}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      />
    )
  }

  const actions = isEdit && (
    <Section.Actions
      options={[{
        onClick: () => onArchiveClick(!isArchived),
        type: isArchived ? TYPES.UNARCHIVE : TYPES.ARCHIVE,
      }]}
    />
  )

  return (
    <Page>
      <Page.Content>
        <Section
          actions={actions}
          title={i18n.t(isEdit
            ? 'module:Management:ClosureDays:ClosureDates:Add:editTitle'
            : 'module:Management:ClosureDays:ClosureDates:Add:title')}
        >
          {renderContent()}
        </Section>
      </Page.Content>
    </Page>
  )
}

export default AddView
