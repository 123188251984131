import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class DailyDiaryFoodsApiClient extends ServiceBase {
  list = (params) => this.request({
    path: `/v2/child_registers/diary_records/eating_records?${getQueryStringV2(params)}`,
  })

  addRecord = (body) => this.request({
    body,
    method: 'POST',
    path: '/eating-records',
  })

  updateRecord = (id, body) => this.request({
    body,
    method: 'PATCH',
    path: `/eating-records/${id}`,
  })

  removeRecord = (id) => this.request({
    method: 'DELETE',
    path: `/eating-records/${id}`,
  })
}

export default DailyDiaryFoodsApiClient
