import React from 'react'

import { ModalBox } from 'components'

import i18n from 'translations'

import AddModalForm from './components/AddModalForm'

const AddModalView = ({
  isEditMode,
  onCloseClick,
  ...rest
}) => (
  <ModalBox
    maxWidth="600"
    scrollToBody={false}
    title={isEditMode
      ? i18n.t('module:Modals:Staff:Leave:Add:editTitle')
      : i18n.t('module:Modals:Staff:Leave:Add:title')}
    onCloseClick={onCloseClick}
  >
    <AddModalForm
      isEditMode={isEditMode}
      onCloseClick={onCloseClick}
      {...rest}
    />
  </ModalBox>
)

export default AddModalView
