import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledWhiteBar = styled.div`
  width: 100%;
  height: 16px;
  position: relative;
  top: -16px;
  background: linear-gradient(transparent, ${NEUTRAL_COLOURS.WHITE});
`
