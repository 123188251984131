import {
  CLEAR_ETHNICITIES_LISTS,
  LIST_ETHNICITIES,
  LIST_ETHNICITIES_FAILED,
  LIST_ETHNICITIES_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: {},
}

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case LIST_ETHNICITIES:
      return { ...state, error: initialState.error, isFetching: true }
    case LIST_ETHNICITIES_SUCCESS: {
      const { data, meta } = payload

      return {
        ...state,
        data,
        isFetching: false,
        meta,
      }
    }
    case LIST_ETHNICITIES_FAILED:
      return { ...state, error: payload.error, isFetching: false }
    case CLEAR_ETHNICITIES_LISTS:
      return initialState
    default:
      return state
  }
}
