import _ from 'lodash'

import React from 'react'
import { WrappedFieldProps } from 'redux-form'

import { Button, FieldError } from 'components'

import { StyledContainer, StyledItems } from './FormButtonGroupStyled'

type Option = {
  disabled?: boolean
  label: string
  value: string | number
}

interface FormRadioGroupProps {
  disabled?: boolean
  multi?: boolean
  options: Option[]
}

type FormRadioGroupFullProps = FormRadioGroupProps & WrappedFieldProps

const FormRadioGroup: React.FC<FormRadioGroupFullProps> = ({
  disabled,
  input: { onChange, value },
  meta: { error, touched },
  multi,
  options,
}) => (
  <StyledContainer>
    <StyledItems $optionsLength={options.length}>
      {_.map(options, (option) => (
        <Button
          disabled={disabled || option.disabled}
          hierarchy={(multi ? _.includes(value, option.value) : (option.value === value)) ? 'primary' : 'secondary'}
          key={option.value}
          label={option.label}
          size="small"
          negativeMargins
          onClick={() => !disabled && onChange(multi ? _.xor(value, [option.value]) : option.value)}
        />
      ))}
    </StyledItems>
    {error && touched && <FieldError error={error} />}
  </StyledContainer>
)

export default FormRadioGroup
