import React from 'react'

import { Toolbar, Typography } from 'components'

import i18n from 'translations'

import { StyledContainer } from './ChildFundingDescriptionStyled'

const ChildFundingDescription = ({ childFunding }) => {
  if (!childFunding) {
    return null
  }

  const { funding } = childFunding
  const { label } = funding

  return (
    <StyledContainer>
      <Toolbar fullWidth noMargin>
        <Toolbar.Group>
          <Toolbar.Item>
            <Typography variant="span" inline>
              {i18n.t('module:Children:Child:BookingPattern:Funding:Allocation:Description:subtitle')}
              {' '}
              <Typography variant="span" bold inline primary>
                {label}
              </Typography>
            </Typography>
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
    </StyledContainer>
  )
}

export default ChildFundingDescription
