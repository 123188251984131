import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change } from 'redux-form'

import { UPLOAD_DIRECTORY } from 'services/legacy/upload/constants'
import { DAYS_OPTIONS } from 'constants/sessionDays'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withUploadService } from 'services/legacy/upload'
import { withRouter } from 'services/router'

import { MANAGEMENT_NURSERY_EDIT_FORM } from './components/ManagementNurseryEditForm'

import * as helpers from './helpers'

import ManagementNurseryEditView from './ManagementNurseryEditView'

const groups = {
  read: [
    'nursery.primaryContact',
    'nursery.details',
    'nursery.settings',
    'nursery.organization',
    'organization',
    'nurserySettings',
    'nurserySettings.localeDetails',
    'membership',
    'membership.primaryManager',
  ],
}

class ManagementNurseryEditContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      imageUploading: false,
    }
  }

  componentDidMount() {
    const { nurseriesActions, nurseryOptions } = this.props

    nurseriesActions.get(nurseryOptions.id, {
      params: { groups },
    })
  }

  componentWillUnmount() {
    const { nurseriesActions } = this.props

    nurseriesActions.clearSingle()
  }

  handleSubmitSuccess = () => {
    const { navigate } = this.props

    navigate(generateRoute('MANAGEMENT.NURSERY'))
  }

  uploadFile = async (file) => {
    if (!file) {
      return null
    }

    const { uploadActions } = this.props

    this.setState({ imageUploading: true })

    const url = await uploadActions.uploadFile(file, file.name, UPLOAD_DIRECTORY.PUBLIC_IMAGES)

    this.setState({ imageUploading: false })

    return url
  }

  handleSubmit = async (fields) => {
    const { nurseriesActions, nurseryOptions } = this.props

    const logo = await this.uploadFile(fields?.logo?.file)
    const payload = helpers.getPayload(fields, logo)

    nurseriesActions.update(nurseryOptions.id, {
      onSuccess: this.handleSubmitSuccess,
      params: { groups },
      payload,
    })
  }

  handleApplyAll = (startTime = null, endTime = null, fields) => {
    const { changeFieldValue } = this.props

    fields.forEach((item) => {
      changeFieldValue(MANAGEMENT_NURSERY_EDIT_FORM, `${item}.startTime`, startTime)
      changeFieldValue(MANAGEMENT_NURSERY_EDIT_FORM, `${item}.endTime`, endTime)
    })
  }

  render() {
    const { errorMessages, isFetching, isSubmitting, nursery } = this.props
    const { imageUploading } = this.state
    const { nurserySettings, organization } = nursery || {}

    const initialValues = helpers.getInitialValues(nursery, DAYS_OPTIONS)

    return (
      <ManagementNurseryEditView
        daysOptions={DAYS_OPTIONS}
        errorMessages={errorMessages}
        financeLocked={nurserySettings?.financeLocked}
        initialValues={initialValues}
        isFormLoading={isSubmitting || imageUploading}
        isLoading={isFetching}
        isOrganizationNursery={!!organization}
        onApplyAll={this.handleApplyAll}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, { appSelectors, nurseriesSelectors, nurseriesSingleState }) => ({
  errorMessages: appSelectors.getErrorMessages(nurseriesSingleState),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState),
  isSubmitting: appSelectors.getIsSubmitting(nurseriesSingleState),
  nursery: nurseriesSelectors.getNurseriesSingleDataSelector(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state),
})

const mapDispatch = {
  changeFieldValue: (formName, field, value) => change(formName, field, value),
}

const enhance = compose(
  withAppService,
  withNurseriesService,
  withUploadService,
  withRouter,
  connect(mapState, mapDispatch),
)

export default enhance(ManagementNurseryEditContainer)
