import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { FEATURE_FLAGS } from 'constants/security'

import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withOrganizationService } from 'services/organization'
import { getAuthOrganization } from 'services/security/selectors'

import SettingsInvoicesDetailsV3View from './SettingsInvoicesDetailsV3View'

const ORGANIZATION_INVOICE_SETTINGS_GROUPS = {
  read: [
    'organization.organizationSettings',
    'organizationSettings.invoice',
    'organizationInvoiceSettings',
    'organizationInvoiceSettings.numbers',
    'invoiceNumbers',
    'organization.bankDetails',
    'organizationInvoiceSettings.annualisedInvoiceSettings',
    'annualisedInvoiceSettings',
  ],
}

const SettingsInvoicesDetailsV3Container = ({
  FinanceAutomationGranted,
  authOrganization,
  errorMessages,
  isFetching,
  organizationActions,
  organizationInvoiceSettings,
  organizationSelectors,
  organizationSingleState,
}) => {
  useEffect(() => {
    const apiParams = { groups: ORGANIZATION_INVOICE_SETTINGS_GROUPS }

    organizationActions.get(authOrganization.id, { params: apiParams })

    return () => {
      organizationActions.clearSingle()
    }
  }, [])

  const handleSubmit = (fields) => (successCallback) => {
    const apiParams = { groups: ORGANIZATION_INVOICE_SETTINGS_GROUPS }
    const payload = organizationSelectors.getPayload({
      fields,
      organizationSingleState,
    })

    organizationActions.update(authOrganization.id, {
      onFailed: successCallback,
      onSuccess: successCallback,
      params: apiParams,
      payload,
    })
  }

  return (
    <SettingsInvoicesDetailsV3View
      FinanceAutomationGranted={FinanceAutomationGranted}
      errorMessages={errorMessages}
      invoicesSettings={organizationInvoiceSettings}
      isLoading={isFetching}
      onSubmit={handleSubmit}
    />
  )
}

const mapState = (state, {
  appSelectors,
  organizationSelectors,
  organizationSingleState,
}) => ({
  FinanceAutomationGranted: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.FINANCE_AUTOMATION],
  }),
  authOrganization: getAuthOrganization(state),
  errorMessages: appSelectors.getErrorMessages(organizationSingleState),
  isFetching: appSelectors.getIsFetching(organizationSingleState),
  organizationInvoiceSettings: organizationSelectors.getOrganizationInvoiceSettings(state),
})

const enhance = compose(withAppService, withOrganizationService, connect(mapState))

export default enhance(SettingsInvoicesDetailsV3Container)
