import React from 'react'

import { generateRoute } from 'utils/routing'

import { NURSERY_DISCOUNTS_FILTER_OPTIONS } from 'services/product/nurseryDiscountsV3/constants'
import { PaginationDetails } from 'services/utils/pagination/constants'

import { Callout, EmptyState, Page, Pagination, Section, Select, Spinner, Table, Toolbar, Typography } from 'components'

import i18n from 'translations'

import { DiscountListTableDataProps } from './ManagementDiscountsV3Helpers'
import { COLUMNS } from './constants'

interface ManagementDiscountsV3ViewProps {
  discounts: DiscountListTableDataProps[]
  errorMessages: string[]
  isLoading: boolean
  onSortChange: (key: string) => void
  onStatusFilterChange: (statusFilters: any) => void
  pagination: PaginationDetails
  routePrefix: string
  sortField: string
  sortOrder: string
  statusFilter: string
}

const ManagementDiscountsV3View: React.FC<ManagementDiscountsV3ViewProps> = ({
  discounts,
  errorMessages,
  isLoading,
  onSortChange,
  onStatusFilterChange,
  pagination,
  sortField,
  sortOrder,
  statusFilter,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination
      {...pagination}
      titlePlural={i18n.t('module:Children:Child:BookingPattern:Discounts:discounts')}
      titleSingular={i18n.t('module:Children:Child:BookingPattern:Discounts:discount')}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!discounts?.length) {
      return (
        <EmptyState
          icon="discount"
          text1={i18n.t('module:Management:Finance:Discounts:discountEmptyState')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={COLUMNS}
          data={discounts}
          sortField={sortField}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const actions = <Section.Actions primary={[{ to: generateRoute('MANAGEMENT.FINANCE_SETTINGS.DISCOUNTS.ADD') }]} />

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title={i18n.t('module:Management:Finance:Discounts:yourDiscounts')}
    >
      <Callout content={errorMessages} error />
      <Typography margin="20px 0">
        {i18n.t('module:Management:Finance:Discounts:listTitle')}
      </Typography>
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <Select
              clearable={false}
              options={NURSERY_DISCOUNTS_FILTER_OPTIONS}
              searchable={false}
              value={statusFilter}
              onChange={onStatusFilterChange}
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      {renderContent()}
    </Page.Section>
  )
}

export default ManagementDiscountsV3View
