import React, { PropsWithChildren } from 'react'

import ListStandardRow from './ListStandardRow'
import ListStandardAction from './ListStandardAction'
import { StyledList } from './ListStandardStyled'

interface ListStandardRoot extends React.FC<PropsWithChildren<any>> {
  Action?: typeof ListStandardAction
  Row?: typeof ListStandardRow
}

const ListStandard: ListStandardRoot = ({ children }) => (
  <StyledList>
    {children}
  </StyledList>
)

export default ListStandard
