import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT } from 'constants/date'
import { ChildProductTypes, ONE_OFF_BOOKING_LABELS } from 'services/booking/childProducts/constants'
import { ChildProduct } from 'services/booking/childBooking/constants'

import { generateRoute } from 'utils/routing'

import { Avatar, Button, Currency } from 'components'
import InvoiceStatus from 'module/Finance/components/InvoiceStatus'

export interface TableDataProps {
  child: JSX.Element
  date: string
}

const getType = (type: ChildProductTypes) => {
  if (
    type === ChildProductTypes.ONE_OFF_AMOUNT_DISCOUNT
    || type === ChildProductTypes.ONE_OFF_LINK_DISCOUNT
    || type === ChildProductTypes.ONE_OFF_PERCENTAGE_DISCOUNT
  ) {
    return ChildProductTypes.ONE_OFF_DISCOUNT
  }

  return type
}

const getPrice = ({ price, settings, type }) => {
  if ([ChildProductTypes.ONE_OFF_FUNDING, ChildProductTypes.ONE_OFF_NO_DEDUCT_FUNDING].includes(type)) {
    return settings.total / 100
  }

  return price?.total ? price.total / 100 : null
}

export const getTableData = (childProducts: ChildProduct[], childId?: number): TableDataProps[] => {
  if (!childProducts?.length) {
    return null
  }

  // @ts-ignore
  return _.map(childProducts, ({ child, date, id, invoice, price, product, settings, type }) => ({
    child: !childId ? (
      <Avatar
        avatarSize="small"
        initials={[child.firstName, child.surname]}
        src={child.photo}
        title={`${child.firstName} ${child.surname}`}
      />
    ) : undefined,
    date: moment(date).format(DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT),
    // @ts-ignore
    description: product?.name || settings?.name,
    edit: (
      <Button.TableAction
        to={childId
          ? generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.ONE_OFF_BOOKINGS.EDIT', { childId, oneOffBookingId: id })
          : generateRoute('FINANCE.ONE_OFF_BOOKINGS.EDIT', { oneOffBookingId: id })}
      />
    ),
    // @ts-ignore
    invoice: <InvoiceStatus
      childId={child?.id}
      invoice={invoice}
      product={product}
    />,
    price: <Currency value={getPrice({ price, settings, type })} />,
    // @ts-ignore
    quantity: settings?.quantity || 1,
    type: ONE_OFF_BOOKING_LABELS[getType(type)],
  }))
}
