import { combineReducers } from 'redux'

import list from './list/reducer'
import listWithoutRecords from './listWithoutRecords/reducer'
import single from './single/reducer'

export default combineReducers({
  list,
  listWithoutRecords,
  single,
})
