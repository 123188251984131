import i18n from 'translations'

export const FUNDING_STATUS_TYPES = {
  ALLOCATION_NEEDED: 'ALLOCATION_NEEDED',
  FUNDING_ALLOCATED: 'FUNDING_ALLOCATED',
  NO_FUNDING: 'NO_FUNDING',
}

export const FUNDING_STATUS_OPTIONS = [
  {
    label: i18n.t('module:Finance:Funding:Constants:FundingStatusOptions:fundingAllocated'),
    value: FUNDING_STATUS_TYPES.FUNDING_ALLOCATED,
  },
  {
    label: i18n.t('module:Finance:Funding:Constants:FundingStatusOptions:allocationNeeded'),
    value: FUNDING_STATUS_TYPES.ALLOCATION_NEEDED,
  },
  {
    label: i18n.t('module:Finance:Funding:Constants:FundingStatusOptions:noFunding'),
    value: FUNDING_STATUS_TYPES.NO_FUNDING,
  },
]

export const FUNDING_ALLOCATION_LABELS = {
  AUTOMATIC: i18n.t('module:Finance:Funding:Constants:FundingAllocationLabels:automatic'),
  MANUAL: i18n.t('module:Finance:Funding:Constants:FundingAllocationLabels:manual'),
}
