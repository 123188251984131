import _ from 'lodash'

import React from 'react'

import { generateRoute } from 'utils/routing'

import { Button, Icon, TimeString } from 'components'

import { StyledHourly } from './SettingsSessionsStyled'

export const getSessionsTableData = (sessions) => {
  if (!sessions || !sessions.length) {
    return []
  }

  return _.map(sessions, (session) => {
    const { archived, endTime, id, isHourly, name, startTime } = session

    return {
      endTime: <TimeString date={endTime} local={false} />,
      isHourly: isHourly && (
        <StyledHourly>
          <Icon icon="check" />
        </StyledHourly>
      ),
      name: archived ? `${name} (Archived)` : name,
      rowToButton: <Button.TableAction to={generateRoute('SETTINGS.SESSIONS.ITEM', { sessionId: id })} />,
      startTime: <TimeString date={startTime} local={false} />,
    }
  })
}
