import _ from 'lodash'

import i18n from 'translations'

import { createSelector } from 'reselect'
import { TERM_CUSTOM, TERM_STARTING_POINT } from '../constants'

const getFormativeReports = (state) => state.formativeReports

export const getUsedTermsInFormativeReports = createSelector(
  [getFormativeReports],
  (state) => state.terms.data,
)

export const getUsedTermsDropdownInFormativeReports = createSelector(
  [getUsedTermsInFormativeReports],
  (terms) => _.map(terms, (value) => {
    if (TERM_STARTING_POINT === value) {
      return ({
        label: i18n.t('module:Learning:CohortTracking:global:startingPoint'),
        value,
      })
    }

    const label = value.split('_')

    return ({
      label: `${
        label[1].charAt(0).toUpperCase() + label[1].toLowerCase().slice(1)
      } ${label[0]}${label[2] ? ` ${label[2]}` : ''}`,
      value,
    })
  }),
)

export const getUsedTermsDropdownWithCustomInFormativeReports = createSelector(
  [getUsedTermsDropdownInFormativeReports],
  (terms) => (terms ? [...terms, {
    label: 'Custom',
    value: TERM_CUSTOM,
  }] : []),
)
