import _ from 'lodash'
import moment from 'moment'

import React from 'react'
import { Link } from 'react-router'

import {
  ChildBooking,
  ChildProductRegularAmountDiscount,
  ChildProductRegularPercentageDiscount,
} from 'services/booking/childBooking/constants'
import { ChildProductTypes } from 'services/booking/childProducts/constants'
import { NurseryFundingProductType } from 'services/product/nurseryFundingV3/constants'
import { PaginationDetails } from 'services/utils/pagination/constants'
import { AGE_IN_MONTHS_OPTIONS } from 'services/legacy/child/constants'
import { Child } from 'services/child/models'
import { NEUTRAL_COLOURS } from 'constants/colors'
import { DAYS_OF_WEEK_LIST } from 'constants/date'
import {
  CHILD_BOOKING_QUERY_NAME,
} from 'module/Children/Child/ChildBookingPattern/RegularBookings/RegularBookingsAdd/RegularBookingsAddContainer'

import { generateRoute } from 'utils/routing'

import {
  Avatar,
  Box,
  Button,
  Callout,
  DatePicker,
  EmptyState,
  Form,
  Grid,
  InfiniteDropdowns,
  Page,
  Pagination,
  SearchBar,
  Section,
  Select,
  Space,
  Spinner,
  Tabs,
  Toolbar,
  Typography,
} from 'components'
import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

import i18n from 'translations'

import { RegularBookingsListFilters } from './RegularBookingsListContainer'
import { StyledButtonWrapper } from './RegularBookingsListStyled'

interface RegularBookingsListViewProps {
  child: Child
  errorMessages: string[]
  filters: RegularBookingsListFilters
  hasAccessToEditRegularBooking: boolean
  isChildContext: boolean
  isFetching: boolean
  onFilterChange: <K extends keyof RegularBookingsListFilters>(key: K) => (value: RegularBookingsListFilters[K]) => void
  onSearchChange?: (search: string) => void
  pagination: PaginationDetails
  records: ChildBooking[]
  search?: string
}

const RegularBookingsListView: React.FC<RegularBookingsListViewProps> = ({
  child,
  errorMessages,
  filters,
  hasAccessToEditRegularBooking,
  isChildContext,
  isFetching,
  onFilterChange,
  onSearchChange,
  pagination,
  records,
  search,
}) => {
  const renderPagination = () => !isFetching && (
    <Pagination {...pagination} />
  )

  const renderTab = (record: ChildBooking, alternate: number) => {
    const { childProducts } = record || {}

    const childProductsFilteredByAlternate = _.filter(childProducts, (item) => (
      alternate + 1 === item.alternate
    ))

    const sortDayOfWeeks = (items) => _.sortBy(
      _.map(items, (i) => i.dayOfWeek),
      (day) => (
        _.findIndex(DAYS_OF_WEEK_LIST, (i) => (day === i))
      ),
    )

    const regularItems = _.map(
      _.flow(
        (i) => _.groupBy(i, (item) => item.product.id),
        (groupedItems) => _.mapValues(groupedItems, (elements) => ({
          ...elements[0],
          dayOfWeek: sortDayOfWeeks(elements),
          totalQuantity: _.sumBy(elements, (element) => element.settings.quantity),
        })),
      )(
        _.filter(childProductsFilteredByAlternate, (item) => (
          ChildProductTypes.REGULAR_ITEM === item.type
        )),
      ),
    )

    const sessions = _.map(
      _.flow(
        (i) => _.groupBy(i, (item) => item.product.id),
        (groupedItems) => _.mapValues(groupedItems, (elements) => ({
          ...elements[0],
          dayOfWeek: sortDayOfWeeks(elements),
        })),
      )(
        _.filter(childProductsFilteredByAlternate, (item) => (
          ChildProductTypes.REGULAR_SESSION === item.type
        )),
      ),
    )

    const fundings = _.map(_.flow(
      (i) => _.groupBy(i, (item) => item.product.id),
      (groupedItems) => groupedItems,
    )(_.filter(childProductsFilteredByAlternate, (item) => ChildProductTypes.REGULAR_ALLOCATED_FUNDING === item.type)))

    const discounts = _.map(
      _.flow(
        (i) => _.groupBy(i, (item) => item.product.id),
        (groupedItems) => _.mapValues(groupedItems, (elements) => ({
          ...elements[0],
          dayOfWeek: sortDayOfWeeks(elements),
        })),
      )(
        _.filter(childProductsFilteredByAlternate, (item) => (
          ChildProductTypes.REGULAR_LINK_DISCOUNT === item.type
        )),
      ),
    )

    const customDiscounts = _.map(
      _.flow(
        (i) => _.groupBy(i, (item) => `${item.settings.name}_${item.settings.value}`),
        (groupedItems) => _.mapValues(groupedItems, (elements) => ({
          ...elements[0],
          dayOfWeek: sortDayOfWeeks(elements),
        })),
      )(
        _.filter(childProductsFilteredByAlternate, (item) => (
          ChildProductTypes.REGULAR_AMOUNT_DISCOUNT === item.type
          || ChildProductTypes.REGULAR_PERCENTAGE_DISCOUNT === item.type
        )),
      ),
    )

    const renderList = (items, hideEmptyState?, showQuantity?) => (
      <React.Fragment>
        <Space space="10px" />
        <Typography fontSize={14} wordBreak="break-word">
          {!hideEmptyState && !items.length ? '-' : null}
          {_.map(items, (item) => (
            <div key={item.id}>
              {showQuantity && `${item.totalQuantity || item.dayOfWeek.length}x `}
              {item.product.name}
              {' ('}
              {_.map(item.dayOfWeek, (dayOfWeek, index) => (
                <React.Fragment key={dayOfWeek}>
                  {i18n.t(`global:shortDayNames:${_.findIndex(DAYS_OF_WEEK_LIST, (i) => i === dayOfWeek) + 1}`)}
                  {index + 1 !== item.dayOfWeek.length && ', '}
                </React.Fragment>
              ))}
              {')'}
            </div>
          ))}
        </Typography>
      </React.Fragment>
    )

    const renderCustomDiscounts = () => (
      <Typography fontSize={14} wordBreak="break-word">
        {!customDiscounts.length && !discounts.length ? '-' : null}
        {_.map(customDiscounts, (item: ChildProductRegularPercentageDiscount | ChildProductRegularAmountDiscount) => (
          <SubdomainCurrencyProvider>
            {({ getFormattedCurrencyValue }) => (
              <div key={item.id}>
                {`${item.dayOfWeek.length}x `}
                {item.settings.name}
                {' '}
                {item.type === ChildProductTypes.REGULAR_AMOUNT_DISCOUNT
                  ? getFormattedCurrencyValue(item.settings.value / 100)
                  : `${item.settings.value / 100}%`}
                {' ('}
                {_.map(item.dayOfWeek, (dayOfWeek, index) => (
                  <React.Fragment key={dayOfWeek}>
                    {i18n.t(`global:shortDayNames:${_.findIndex(DAYS_OF_WEEK_LIST, (i) => i === dayOfWeek) + 1}`)}
                    {index + 1 !== item.dayOfWeek.length && ', '}
                  </React.Fragment>
                ))}
                {')'}
              </div>
            )}
          </SubdomainCurrencyProvider>
        ))}
      </Typography>
    )

    const renderFunding = () => (
      <React.Fragment>
        <Space space="10px" />
        <Typography fontSize={14}>
          {!fundings.length ? '-' : null}
          {_.map(fundings, (fundingsGroup) => {
            let times = 0
            const item = fundingsGroup[0]

            _.each(fundingsGroup, (element) => {
              const { settings } = element || {}
              const { defaultAllocations } = settings || {}

              _.each(defaultAllocations, (allocation) => {
                _.each(allocation?.times, (time) => {
                  times += time.endTime - time.startTime
                })
              })
            })

            const hours = Math.floor(Math.round((times / 1000 / 60 / 60) * 100) / 100)
            const minutes = +moment(times).utc().format('m')

            return (
              <div key={item.id}>
                {hours ? `${i18n.t('global:Hours', { value: hours })}` : null}
                {minutes ? ` ${i18n.t('global:Minutes', { value: minutes })}` : null}
                {hours || minutes ? ' - ' : null}
                {item.product.name}
              </div>
            )
          })}
        </Typography>
      </React.Fragment>
    )

    return (
      <Grid spacing={20}>
        <Grid.Item flex={{ desktop: 1 }} width={{ mobile: '100%' }}>
          <Typography bold>
            {i18n.t('module:Finance:RegularBookings:List:sessions')}
          </Typography>
          {renderList(sessions, false, true)}
        </Grid.Item>
        <Grid.Item flex={{ desktop: 1 }} width={{ mobile: '100%' }}>
          <Typography bold>
            {i18n.t('module:Finance:RegularBookings:List:items')}
          </Typography>
          {renderList(regularItems, false, true)}
        </Grid.Item>
        <Grid.Item flex={{ desktop: 1 }} width={{ mobile: '100%' }}>
          <Typography bold>
            {i18n.t('module:Finance:RegularBookings:List:funding')}
          </Typography>
          <Space space="15px" />
          {renderFunding()}
        </Grid.Item>
        <Grid.Item flex={{ desktop: 1 }} width={{ mobile: '100%' }}>
          <Typography bold>
            {i18n.t('module:Finance:RegularBookings:List:discounts')}
          </Typography>
          {renderList(discounts, true, true)}
          {renderCustomDiscounts()}
        </Grid.Item>
      </Grid>
    )
  }

  const renderRecord = (record: ChildBooking) => {
    const { alternates, attendancePeriod, child: recordChild, endDate, id, startDate } = record || {}
    const { firstName, leavingDate, photo, surname } = recordChild || {}

    return (
      <Box
        key={record.id}
        withPadding
        withShadow
      >
        {!isChildContext && (
          <Grid>
            <Grid.Item flex={{ desktop: 1 }} justifyContent={{ mobile: 'center' }}>
              <Avatar
                avatarSize="small"
                initials={[firstName, surname]}
                src={photo}
                title={`${firstName} ${surname}`}
              />
              <Space space="5px" />
            </Grid.Item>
          </Grid>
        )}
        <Grid>
          <Grid.Item
            alignItems={{ desktop: 'flex-end' }}
            justifyContent={{ mobile: 'center' }}
            width={{ mobile: '100%' }}
          >
            {hasAccessToEditRegularBooking ? (
              <Link
                to={generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS.PREVIEW', {
                  bookingId: id,
                  childId: recordChild?.id,
                })}
              >
                <Typography fontSize={18} bold primary>
                  {moment(startDate).format('DD MMM YYYY')}
                  {' - '}
                  {endDate
                    ? moment(endDate).format('DD MMM YYYY')
                    : (
                      <Typography fontSize={18} bold inline primary>
                        {moment(leavingDate).format('DD MMM YYYY')}
                        <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} bold inline>
                          {' ('}
                          {i18n.t('module:Finance:RegularBookings:List:untilChildLeaves')}
                          {')'}
                        </Typography>
                      </Typography>
                    )}
                </Typography>
              </Link>
            ) : (
              <Typography fontSize={18} bold primary>
                {moment(startDate).format('DD MMM YYYY')}
                {' - '}
                {endDate
                  ? moment(endDate).format('DD MMM YYYY')
                  : (
                    <Typography fontSize={18} bold inline primary>
                      {moment(leavingDate).format('DD MMM YYYY')}
                      <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} bold inline>
                        {' ('}
                        {i18n.t('module:Finance:RegularBookings:List:untilChildLeaves')}
                        {')'}
                      </Typography>
                    </Typography>
                  )}
              </Typography>
            )}
          </Grid.Item>
          <Grid.Item
            alignItems={{ desktop: 'flex-end' }}
            flex={{ desktop: 1 }}
            textAlign={{ mobile: 'center' }}
            width={{ mobile: '100%' }}
          >
            <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} bold>
              {attendancePeriod?.name}
            </Typography>
          </Grid.Item>
          {hasAccessToEditRegularBooking && (
            <Grid.Item
              justifyContent={{ desktop: 'flex-end', mobile: 'center' }}
              width={{ mobile: '100%' }}
            >
              <StyledButtonWrapper>
                <Button
                  hierarchy="secondary"
                  label={i18n.t('module:Finance:RegularBookings:List:scheduleBookingPatternChange')}
                  margin="0"
                  size="small"
                  to={`${generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS.ADD', {
                    childId: recordChild?.id,
                  })}?${CHILD_BOOKING_QUERY_NAME}=${id}`}
                />
              </StyledButtonWrapper>
            </Grid.Item>
          )}
        </Grid>
        <Space space="20px" />
        <Tabs fullWidth={false} gray noMargin>
          {_.times(alternates, (index) => (
            <Tabs.Item
              key={index}
              title={(
                <Typography fontSize={14} bold>
                  {i18n.t('module:Finance:RegularBookings:List:week', { index: index + 1 })}
                </Typography>
              )}
            >
              {renderTab(record, index)}
            </Tabs.Item>
          ))}
        </Tabs>
      </Box>
    )
  }

  const renderFilters = () => (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <Form.Row
            label={i18n.t('module:Finance:RegularBookings:List:planActiveOn')}
            verticalLabel
          >
            <DatePicker
              value={filters.planActiveOn}
              clearable
              onChange={onFilterChange('planActiveOn')}
            />
          </Form.Row>
        </Toolbar.Item>
        {!isChildContext && (
          <Toolbar.Item>
            <Form.Row
              label={i18n.t('global:Room')}
              verticalLabel
            >
              <InfiniteDropdowns.Rooms
                disabled={!filters.planActiveOn}
                placeholder={i18n.t('global:Room')}
                value={filters.room}
                v2
                onChange={onFilterChange('room')}
              />
            </Form.Row>
          </Toolbar.Item>
        )}
        <Toolbar.Item>
          <Form.Row
            label={i18n.t('module:Finance:RegularBookings:List:fundingType')}
            verticalLabel
          >
            <InfiniteDropdowns.NurseryFundingsV3
              criteria={[{
                field: 'type',
                value: [
                  NurseryFundingProductType.NURSERY_REGULAR_FUNDING,
                ],
              }]}
              placeholder={i18n.t('module:Finance:RegularBookings:List:fundingType')}
              value={filters.fundingType}
              v2
              onChange={onFilterChange('fundingType')}
            />
          </Form.Row>
        </Toolbar.Item>
        {!isChildContext && (
          <React.Fragment>
            <Toolbar.Item>
              <Form.Row
                label={i18n.t('module:Finance:RegularBookings:List:startAgeInMonths')}
                verticalLabel
              >
                <Select
                  options={AGE_IN_MONTHS_OPTIONS}
                  placeholder={i18n.t('module:Finance:RegularBookings:List:startAgeInMonths')}
                  value={filters.startAge}
                  v2
                  onChange={onFilterChange('startAge')}
                />
              </Form.Row>
            </Toolbar.Item>
            <Toolbar.Item>
              <Form.Row
                label={i18n.t('module:Finance:RegularBookings:List:endAgeInMonths')}
                verticalLabel
              >
                <Select
                  options={AGE_IN_MONTHS_OPTIONS}
                  placeholder={i18n.t('module:Finance:RegularBookings:List:endAgeInMonths')}
                  value={filters.endAge}
                  v2
                  onChange={onFilterChange('endAge')}
                />
              </Form.Row>
            </Toolbar.Item>
          </React.Fragment>
        )}
      </Toolbar.Group>
    </Toolbar>
  )

  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    if (!isFetching && !records?.length) {
      return (
        <EmptyState
          actions={isChildContext && hasAccessToEditRegularBooking && (
            <Button
              label={i18n.t('module:Finance:RegularBookings:List:createFirstRegularBooking')}
              to={generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS.ADD', {
                childId: child?.id,
              })}
            />
          )}
          icon="time"
          text1={isChildContext
            ? i18n.t('module:Finance:RegularBookings:List:notFoundChildContext', {
              childName: `${child.firstName} ${child.surname}`,
            })
            : i18n.t('module:Finance:RegularBookings:List:notFound')}
        />
      )
    }

    return (
      <div>
        {renderPagination()}
        <Space space="15px" />
        {_.map(records, renderRecord)}
        {renderPagination()}
      </div>
    )
  }

  const actions = (
    <Section.Actions
      primary={[{
        to: generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS.ADD', {
          childId: child?.id,
        }),
      }]}
    />
  )

  return (
    <React.Fragment>
      {!isChildContext && (
        <SearchBar
          placeholder={i18n.t('module:Finance:OneOffBookings:Filters:Search:label')}
          value={search}
          onChange={onSearchChange}
        />
      )}
      <Page.Section
        actions={isChildContext && hasAccessToEditRegularBooking && actions}
        isLoading={isFetching}
        title={i18n.t('module:Finance:RegularBookings:title')}
      >
        <Callout content={errorMessages} error />
        {renderFilters()}
        {renderContent()}
      </Page.Section>
    </React.Fragment>
  )
}

export default RegularBookingsListView
