import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledRowContainer = styled.div`
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: 1fr 1fr;
  
  @media(max-width: 860px) {
    grid-template-columns: 1fr;
    grid-row-gap: 15px;
    
    & > div:nth-child(1) {
      margin-bottom: 0;
    }
  }
`

export const StyledActivitiesList = styled.ol`
  list-style-type: disc;
  margin: 0 0 0 20px;
  padding: 0;
`

export const StyledActivity = styled.li`
  font-weight: 600;
  line-height: 26px;
`

export const StyledSubCategoryContainer = styled.div`
  margin-bottom: 25px;
`

export const StyledSubCategoryName = styled.h3`
  font-weight: 600;
  font-size: 20px;
  color: ${getBrandingColor('tertiary-color')};
  margin-bottom: 10px;
`
