import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as creditNotesSelectors from './selectors'

const withCreditNotesService = (WrappedComponent) => {
  const mapState = ({ creditNotes }) => ({ creditNotes })

  const mapDispatch = injectActions('creditNotesActions', actions)

  const Component = ({ creditNotes, creditNotesActions, ...others }) => (
    <WrappedComponent
      creditNotesActions={creditNotesActions}
      creditNotesSelectors={creditNotesSelectors}
      creditNotesSingleState={creditNotes.single}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withCreditNotesService
