import i18n from 'translations'

export const CODE_AUTOMATIC_GENERATION = {
  RENDOMISED_BY_SYSTEM: 'rendomisedBySystem',
  SEQUENTIAL_NUMBERS: 'sequentialNumbers',
}

export const CODE_AUTOMATIC_GENERATION_OPTIONS = [
  {
    label: i18n.t('module:Management:Finance:ReferenceCodeGeneration:randomisedBySystem'),
    value: CODE_AUTOMATIC_GENERATION.RENDOMISED_BY_SYSTEM,
  },
  {
    label: i18n.t('module:Management:Finance:ReferenceCodeGeneration:sequentialNumbers'),
    value: CODE_AUTOMATIC_GENERATION.SEQUENTIAL_NUMBERS,
  },
]
