import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { Form } from 'components'

export const BOOKING_ABSENCE_FORM = 'BOOKING_ABSENCE_FORM'

const BookingAbsenceForm = ({
  handleSubmit,
  onSubmit,
}) => (
  <Form flex={1} onSubmit={handleSubmit(onSubmit)}>
    <Form.Row>
      <Form.Row.Item>
        <Field
          component={Form.Switch}
          name="allowParentBookingAbsence"
        />
      </Form.Row.Item>
    </Form.Row>
  </Form>
)

export default reduxForm({ form: BOOKING_ABSENCE_FORM })(BookingAbsenceForm)
