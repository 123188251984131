import styled from 'styled-components'
import color from 'color'

import { AVAILABLE_DAYS } from 'services/legacy/membershipRegisters/constants'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

export const StyledDayBox = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  margin-right: 8px;
  margin-bottom: 8px;
  border: 2px solid ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  background: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  padding: 10px 15px;
  cursor: pointer;
  min-height: 65px;
  justify-content: center;
  
  ${({ isActive }) => isActive && `
    background: ${color(getBrandingColor('primary-color')).alpha(0.2).rgb().string()};
    border-color: ${getBrandingColor('primary-color')};
  `}
`

export const StyledDayListContainer = styled.div`
  margin: 15px 0;
  display: grid;
  grid-template-columns: repeat(${AVAILABLE_DAYS}, 14.28%);
  position: relative;
  
  @media(max-width: 980px) {
    grid-template-columns: repeat(4, 25%);
  }
    
  @media(max-width: 640px) {
    grid-template-columns: repeat(2, 50%);
  }
      
  @media(max-width: 420px) {
    grid-template-columns: auto;
  }
  
  &:hover {
    z-index: 280;
  }
`
