import React from 'react'
import { WrappedFieldProps } from 'redux-form'

import { SelectProps } from 'components/Select/Select'

import { Select } from 'components'

type FormSelectFullProps = SelectProps & WrappedFieldProps

const FormSelect: React.FC<FormSelectFullProps> = ({
  input: { onChange, value },
  meta: { error, submitFailed },
  ...rest
}) => (
  <Select
    {...rest}
    error={submitFailed && error}
    value={value && value.value ? value.value : value}
    onChange={onChange}
  />
)

export default FormSelect
