import { UPDATE_CHILD_FUNDING_SESSION_SET_SUCCESS } from 'services/legacy/childFundingSessionSet/single/constants'
import {
  CREATE_CHILD_FUNDING_SUCCESS,
  UPDATE_CHILD_FUNDING_SUCCESS,
} from 'services/legacy/childFunding/single/constants'

import {
  CLEAR_CHILD_BULK_FUNDING,
  CREATE_CHILD_BULK_FUNDING,
  CREATE_CHILD_BULK_FUNDING_FAILED,
  CREATE_CHILD_BULK_FUNDING_SUCCESS,
  LIST_CHILD_BULK_FUNDING,
  LIST_CHILD_BULK_FUNDING_FAILED,
  LIST_CHILD_BULK_FUNDING_SUCCESS,
  UPDATE_CHILD_BULK_FUNDING,
  UPDATE_CHILD_BULK_FUNDING_FAILED,
  UPDATE_CHILD_BULK_FUNDING_SUCCESS,
} from './constants'

import { assignChildFunding, updateChildFunding, updateChildFundingAllocation } from './helpers'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false,
  meta: {},
}

export default (state = initialState, { error, payload, silent, type }) => {
  switch (type) {
    case LIST_CHILD_BULK_FUNDING:
      return { ...state, error: initialState.error, isFetching: !silent }
    case LIST_CHILD_BULK_FUNDING_SUCCESS:
      return {
        ...state,
        data: payload.data,
        isFetching: false,
        meta: payload.meta,
      }
    case LIST_CHILD_BULK_FUNDING_FAILED:
      return { ...state, error, isFetching: false }
    case CREATE_CHILD_BULK_FUNDING:
    case UPDATE_CHILD_BULK_FUNDING:
      return { ...state, error: initialState.error, isSubmitting: true }
    case CREATE_CHILD_BULK_FUNDING_SUCCESS:
    case UPDATE_CHILD_BULK_FUNDING_SUCCESS:
      return { ...state, isSubmitting: false }
    case CREATE_CHILD_BULK_FUNDING_FAILED:
    case UPDATE_CHILD_BULK_FUNDING_FAILED:
      return { ...state, error, isSubmitting: false }
    case UPDATE_CHILD_FUNDING_SESSION_SET_SUCCESS:
      return updateChildFundingAllocation(state, payload)
    case CREATE_CHILD_FUNDING_SUCCESS:
      return assignChildFunding(state, payload)
    case UPDATE_CHILD_FUNDING_SUCCESS:
      return updateChildFunding(state, payload)
    case CLEAR_CHILD_BULK_FUNDING:
      return initialState
    default:
      return state
  }
}
