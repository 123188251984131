import { CLEAR_LEAVE_NOTE, CREATE_LEAVE_NOTE, CREATE_LEAVE_NOTE_FAILED, CREATE_LEAVE_NOTE_SUCCESS } from './constants'

export const create = (options = {}) => ({ leaveNotesApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {}, payload = {} } = options

  dispatch({ type: CREATE_LEAVE_NOTE })

  leaveNotesApiClient.create(payload, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: CREATE_LEAVE_NOTE_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: CREATE_LEAVE_NOTE_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const clear = () => ({
  type: CLEAR_LEAVE_NOTE,
})
