import { FILE_TYPES } from 'constants/mimetypes'

import i18n from 'translations'

export const ALL_STAFF_MEMBERS = {
  label: i18n.t('module:Modals:Staff:global:allStaffMembers'),
  noAvatar: true,
  value: 0,
}

export const EXPORT_FILE_TYPE_OPTIONS = [
  {
    label: i18n.t('global:FileType:PDF'),
    value: FILE_TYPES.PDF.acceptHeader,
  },
  {
    label: i18n.t('global:FileType:CSV'),
    value: FILE_TYPES.CSV.acceptHeader,
  },
]

const SEND = {
  ALL: 'all',
  INDIVIDUAL: 'individual',
}

export const SEND_OPTIONS = [
  {
    label: i18n.t('module:Modals:Staff:Export:Shifts:individualShifts'),
    value: SEND.INDIVIDUAL,
  },
  {
    label: i18n.t('module:Modals:Staff:Export:Shifts:allStaffShifts'),
    value: SEND.ALL,
  },
]

export const REPORT_DATES = {
  DAY: 'day',
  MONTH: 'month',
  WEEK: 'week',
}

export const DAY_RANGE = {
  label: i18n.t('module:Modals:Staff:Export:ReportDates:DayRange:label'),
  value: REPORT_DATES.DAY,
}

export const MONTH_RANGE = {
  label: i18n.t('module:Modals:Staff:Export:ReportDates:MonthRange:label'),
  value: REPORT_DATES.MONTH,
}

export const WEEK_RANGE = {
  label: i18n.t('module:Modals:Staff:Export:ReportDates:WeekRange:label'),
  value: REPORT_DATES.WEEK,
}
