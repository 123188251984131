import React from 'react'

import { DetailsBreakdown } from 'components'

import i18n from 'translations'

import { CHILD_EDIT_FORM_ROUTE_TYPE } from '../../../constants'

import BreakdownHeader from './BreakdownHeader'
import BreakdownItem from './BreakdownItem'

const FamilyBreakdown = ({
  child,
  isFormContext,
  maritalStatuses,
  permissionToEdit,
}) => {
  const {
    id,
    profile,
  } = child
  const {
    familyMaritalStatus,
    familyPosition,
    familySpokenLanguage,
    parentsAreServingArmedForcesPersonnel,
    siblings,
  } = profile || {}

  return (
    <DetailsBreakdown marginBottom>
      <DetailsBreakdown.Section>
        <BreakdownHeader
          childFormRouteType={CHILD_EDIT_FORM_ROUTE_TYPE.FAMILY_INFO}
          childId={id}
          isFormContext={isFormContext}
          permissionToEdit={permissionToEdit}
          title={i18n.t('module:Children:Child:Add:Family:title')}
        />
        <BreakdownItem
          label={i18n.t('module:Children:Child:Add:Family:Form:Siblings:label')}
          padding="15px 0"
          value={siblings}
          withoutBorder
        />
        <BreakdownItem
          label={i18n.t('module:Children:Child:Add:Family:Form:FamilyPosition:label')}
          padding="15px 0"
          value={isFormContext ? familyPosition?.label : familyPosition}
          withoutBorder
        />
        <BreakdownItem
          label={i18n.t('module:Children:Child:Add:Family:Form:FamilyMaritalStatus:label')}
          padding="15px 0"
          value={isFormContext ? familyMaritalStatus?.label : maritalStatuses[familyMaritalStatus]}
          withoutBorder
        />
        <BreakdownItem
          label={i18n.t('module:Children:Child:Add:Family:Form:FamilyLanguage:label')}
          padding="15px 0"
          value={isFormContext
            ? familySpokenLanguage?.map(({ label }) => label)?.join(',')
            : familySpokenLanguage}
          withoutBorder
        />
        <BreakdownItem
          label={i18n.t('module:Children:Child:Add:Family:Form:ParentArmedForcesPersonnel:label')}
          padding="15px 0"
          value={parentsAreServingArmedForcesPersonnel ? i18n.t('global:Yes') : i18n.t('global:No')}
          withoutBorder
        />
      </DetailsBreakdown.Section>
    </DetailsBreakdown>
  )
}

export default FamilyBreakdown
