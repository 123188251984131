import styled from 'styled-components'

import layout from 'constants/layout'

import { StyledContainer, StyledDetails } from 'components/Avatar/AvatarStyled'
import { StyledRow } from 'components/List/Standard/ListStandardStyled'

export const StyledDietaryAvatar = styled.div`
  width: 100%;
  height: 100%;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    ${StyledContainer} {
      grid-template-columns: auto auto;
      grid-column-gap: 10px;
    }
    
    ${StyledDetails} {
      text-align: left;
      align-items: flex-start;
    }
  }
`

export const StyledListRow = styled.div`
  ${StyledRow} {
    grid-template-rows: 1fr auto;
  }
`

export const StyledSpace = styled.div`
  margin-top: 15px
`

export const StyledSpinner = styled.div`
  display: flex;
  justify-content: flex-start;  
`
