import _ from 'lodash'
import { flatten } from 'utils/flatnest'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { Currency, ReportView } from 'components'

import i18n from 'translations'

import { NURSERY_TABLE_COLUMNS, ORGANIZATION_TABLE_COLUMNS } from './constants'

export const getColumns = (isOrganizationContext) => {
  if (isOrganizationContext) {
    return ORGANIZATION_TABLE_COLUMNS
  }

  return NURSERY_TABLE_COLUMNS
}

const getNurseryLink = (nursery, appProperties, queryString) => (
  <ReportView.NurseryLink
    nursery={nursery}
    queryString={queryString}
    routeKey="FINANCE.REPORT.REVENUE_PER_ROOM"
  />
)

export const getTableData = ({ appProperties, data, filters, hasError, isOrganizationContext }) => {
  if (!data?.length || hasError) {
    return null
  }

  const queryString = new URLSearchParams(flatten({
    date: filters?.date ? {
      after: filters.date.after.format(DEFAULT_DATE_FORMAT),
      before: filters.date.before.format(DEFAULT_DATE_FORMAT),
    } : undefined,
  })).toString()

  return _.map(data, ({
    resource,
    total,
  }) => ({
    name: isOrganizationContext ? getNurseryLink(resource, appProperties, queryString) : resource.name,
    revenue: <Currency value={total} />,
  }))
}

export const getStatistics = ({ data, hasError, isOrganizationContext }) => {
  if (!data || !isOrganizationContext || hasError) {
    return null
  }

  return [
    {
      label: i18n.t('global:AllSites'),
      value: <Currency value={data.total} />,
    },
  ]
}
