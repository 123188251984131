import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as dailyDiarySelectors from './selectors'

const withDailyDiaryService = (WrappedComponent) => {
  const mapState = ({ newDailyDiary }) => ({ newDailyDiary })

  const mapDispatch = injectActions('dailyDiaryActions', actions)

  const Component = (props) => {
    const { dailyDiaryActions, newDailyDiary, ...other } = props

    return (
      <WrappedComponent
        dailyDiaryActions={dailyDiaryActions}
        dailyDiaryListState={newDailyDiary.list}
        dailyDiaryMyDailyDiaryState={newDailyDiary.myDailyDiary}
        dailyDiarySelectors={dailyDiarySelectors}
        {...other}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withDailyDiaryService
