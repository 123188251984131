import React, { PropsWithChildren, useState } from 'react'

import { StyledContainer, StyledTooltip } from './EllipsisWithTooltipStyled'

interface EllipsisWithTooltipProps {
  text: string
  width: number
}

const EllipsisWithTooltip: React.FC<PropsWithChildren<EllipsisWithTooltipProps>> = ({ text, width }) => {
  const [isOverFlow, setOverFlow] = useState<boolean>(false)

  const updateOverFlow = (e) => {
    setOverFlow(!isOverFlow && e.currentTarget.scrollWidth > e.currentTarget.clientWidth)
  }

  if (isOverFlow) {
    return (
      <StyledTooltip title={<span>{text}</span>}>
        <StyledContainer $width={width}>
          {text}
        </StyledContainer>
      </StyledTooltip>
    )
  }

  return (
    <StyledContainer $width={width} onMouseEnter={updateOverFlow} onTouchStart={updateOverFlow}>
      {text}
    </StyledContainer>
  )
}

export default EllipsisWithTooltip
