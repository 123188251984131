import React from 'react'

import { DetailsBreakdown } from 'components'

import i18n from 'translations'

import { CHILD_EDIT_FORM_ROUTE_TYPE } from '../../../constants'
import BreakdownHeader from './BreakdownHeader'
import BreakdownSubHeader from './BreakdownSubHeader'
import BreakdownItem from './BreakdownItem'

const TRANS_PREFIX = 'module:Children:Child:Add:Development'

const DevelopmentBreakdown = ({
  child,
  drinkingMethods,
  isFormContext,
  permissionToEdit,
  toiletTrainingOptions,
}) => {
  const {
    id,
    information,
    profile,
  } = child
  const {
    daytimeSleepingBehavior,
    daytimeSleepingBehaviorDetails,
    drinkingMethod,
    toiletTrained,
  } = information || {}
  const {
    attendsOtherSetting,
    childBehavior,
    englishAsAdditionalLanguage,
    englishAsAdditionalLanguageDetails,
    languageCapability,
    movementCapability,
    routine,
    specialEducationalNeeds,
    specialEducationalNeedsDetails,
  } = profile || {}

  const getFlagWithOtherInformation = (flag, otherInformation) => {
    if (isFormContext && flag) {
      return `${i18n.t('global:Yes')} - ${otherInformation || ''}`
    }

    if (flag) {
      return flag
    }

    return i18n.t('global:No')
  }

  const renderSkills = () => (
    <React.Fragment>
      <BreakdownSubHeader
        subtitle={i18n.t(`${TRANS_PREFIX}:Subsections:Skills:title`)}
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Skills:Form:Toileting:label`)}
        padding="15px 0"
        value={toiletTrainingOptions[toiletTrained?.value || toiletTrained]}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Skills:Form:DrinkingMethods:label`)}
        padding="15px 0"
        value={drinkingMethods[drinkingMethod?.value || drinkingMethod]}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Skills:Form:SpecialEducation:label`)}
        padding="15px 0"
        value={getFlagWithOtherInformation(specialEducationalNeeds, specialEducationalNeedsDetails)}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Skills:Form:EnglishAdditionalLanguage:label`)}
        padding="15px 0"
        value={getFlagWithOtherInformation(englishAsAdditionalLanguage, englishAsAdditionalLanguageDetails)}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Skills:Form:LanguageCapability:label`)}
        padding="15px 0"
        value={languageCapability}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Skills:Form:MovementCapability:label`)}
        padding="15px 0"
        value={movementCapability}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Skills:Form:ChildBehavior:label`)}
        padding="15px 0"
        value={childBehavior}
        withoutBorder
      />
    </React.Fragment>
  )

  const renderRoutines = () => (
    <React.Fragment>
      <BreakdownSubHeader
        subtitle={i18n.t(`${TRANS_PREFIX}:Subsections:Routine:title`)}
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Routine:Form:DayTimeSleep:label`)}
        padding="15px 0"
        value={getFlagWithOtherInformation(daytimeSleepingBehavior, daytimeSleepingBehaviorDetails)}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Routine:Form:Routine:label`)}
        padding="15px 0"
        value={routine}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Routine:Form:AttendsOtherSettings:label`)}
        padding="15px 0"
        value={attendsOtherSetting}
        withoutBorder
      />
    </React.Fragment>
  )

  return (
    <DetailsBreakdown
      padding="15px 0"
      marginBottom
      withoutBorder
    >
      <DetailsBreakdown.Section>
        <BreakdownHeader
          childFormRouteType={CHILD_EDIT_FORM_ROUTE_TYPE.DEVELOPMENT_INFO}
          childId={id}
          isFormContext={isFormContext}
          permissionToEdit={permissionToEdit}
          title={i18n.t(`${TRANS_PREFIX}:title`)}
        />
        {renderSkills()}
        {renderRoutines()}
      </DetailsBreakdown.Section>
    </DetailsBreakdown>
  )
}

export default DevelopmentBreakdown
