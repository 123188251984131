import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledPreviewLineContainer = styled.div`
  padding-top: 15px;
  display: flex;
  color: ${getBrandingColor('primary-color')};
  font-weight: 400;
  cursor: pointer;
`

export const StyledIconContainer = styled.div`
  display: flex;
  padding-right: 5px;
`
