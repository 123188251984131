import React from 'react'

import { StyledItemContainer } from './RegisterItemMainActionsStyled'

const RegisterItemMainActions = ({ children, size }) => (
  <StyledItemContainer size={size}>
    {children}
  </StyledItemContainer>
)

export default RegisterItemMainActions
