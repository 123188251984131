import moment from 'moment'

import { createSelector } from 'reselect'

const getCreditNotes = (state) => state.creditNotes

export const getCreditNotesList = createSelector([getCreditNotes], (state) => state.list)

export const getCreditNotesListData = createSelector([getCreditNotesList], (state) => state.data)

export const getCreditNotesListMeta = createSelector([getCreditNotesList], (state) => state.meta)

export const getCreditNotesListIsFetching = createSelector([getCreditNotesList], (state) => state.isFetching)

const getFilters = (filters) => filters

export const getCriteriaSelector = createSelector([getFilters], (filters) => {
  if (!filters) {
    return null
  }

  const criteria = []

  const {
    author,
    createdAt,
  } = filters

  if (createdAt) {
    const { createdAt: { after, before } } = filters

    if (after) {
      criteria.push({
        field: 'startDate',
        value: moment(after).startOf('day').toISOString(),
      })
    }

    if (before) {
      criteria.push({
        field: 'endDate',
        value: moment(before).endOf('day').toISOString(),
      })
    }
  }

  if (author) {
    criteria.push({
      field: 'creator',
      value: author,
    })
  }

  return criteria
})
