import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { Button, FooterActions, Form } from 'components'

import i18n from 'translations'

export const ADD_DEVICE_FORM = 'AddDeviceForm'

const AddDeviceForm = ({
  handleSubmit,
  isSubmitting,
  onSubmit,
  pinVersion,
}) => {
  const renderPinSection = () => {
    if (!pinVersion) {
      return null
    }

    return (
      <Form.Row
        label={i18n.t('module:Management:Security:AuthorisedDevices:global:staffMember')}
        width={{ field: '300px' }}
        required
      >
        <Field
          component={Form.InfiniteDropdowns.Users}
          disabled={isSubmitting}
          name="user"
          placeholder={i18n.t('module:Management:Security:AuthorisedDevices:global:staffMember')}
          validate={isRequired}
        />
      </Form.Row>
    )
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Row
        label={i18n.t('module:Management:Security:AuthorisedDevices:global:name')}
        width={{ field: '400px' }}
        required
      >
        <Field
          component={Form.TextField}
          disabled={isSubmitting}
          name="name"
          placeholder={i18n.t('module:Management:Security:AuthorisedDevices:global:name')}
          validate={isRequired}
        />
      </Form.Row>
      {renderPinSection()}
      <FooterActions noBorder>
        <FooterActions.Group>
          <FooterActions.Item>
            <Button
              isLoading={isSubmitting}
              label={pinVersion
                ? i18n.t('module:Management:Security:AuthorisedDevices:AddDevice:generatePin')
                : i18n.t('module:Management:Security:AuthorisedDevices:AddDevice:approveThisDevice')}
              submit
            />
          </FooterActions.Item>
        </FooterActions.Group>
      </FooterActions>
    </Form>
  )
}

export default reduxForm({
  form: ADD_DEVICE_FORM,
})(AddDeviceForm)
