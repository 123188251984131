import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'
import selectors from './selectors'
import { NurseryFundingV3ListState } from './constants'

export interface withNurseryFundingV3ServiceProps {
  nurseryFundingV3Actions: typeof actions
  nurseryFundingV3ListState: NurseryFundingV3ListState
  nurseryFundingV3Selectors: typeof selectors
}

const withNurseryFundingV3Service = <P extends withNurseryFundingV3ServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ nurseryFundingV3 }) => ({ nurseryFundingV3 })

  const mapDispatch = injectActions('nurseryFundingV3Actions', actions)

  const Component = ({ nurseryFundingV3, nurseryFundingV3Actions, ...other }) => (
    <WrappedComponent
      nurseryFundingV3Actions={nurseryFundingV3Actions}
      nurseryFundingV3ListState={nurseryFundingV3.list}
      nurseryFundingV3Selectors={selectors}
      nurseryFundingV3SingleState={nurseryFundingV3.single}
      {...other as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withNurseryFundingV3Service
