import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { DEFAULT_PER_PAGE } from 'constants/pagination'

import { withAppService } from 'services/app'
import { withAuthenticationLogsService } from 'services/legacy/authenticationLogs'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'

import AccessLogsListView from './AccessLogsListView'

class AccessLogsListContainer extends Component {
  constructor(props) {
    super(props)

    const { paginationUtils } = props
    const { setPageLocationQuery } = paginationUtils

    setPageLocationQuery(false)
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { authenticationLogsActions } = this.props

    authenticationLogsActions.clearList()
  }

  fetch = () => {
    const { authenticationLogsActions, paginationUtils } = this.props
    const { page } = paginationUtils

    authenticationLogsActions.list({
      limit: DEFAULT_PER_PAGE,
      order: 'date DESC',
      page,
      start: (page - 1) * DEFAULT_PER_PAGE,
    }, 1 !== page)
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  render() {
    const { accessLogs, authenticationLogsListState, isFetching, paginationUtils } = this.props
    const { getPageCount, page } = paginationUtils

    const pageCount = getPageCount(authenticationLogsListState && authenticationLogsListState.meta.total_results)

    return (
      <AccessLogsListView
        accessLogs={accessLogs}
        isFetching={isFetching}
        page={page}
        pageCount={pageCount}
        totalResults={authenticationLogsListState && authenticationLogsListState.meta.total_results}
        onPageChange={this.handlePageChange}
      />
    )
  }
}

const mapState = (state, { appSelectors, authenticationLogsListState, authenticationLogsSelectors }) => ({
  accessLogs: authenticationLogsSelectors.getAuthenticationLogsData(state),
  isFetching: appSelectors.getIsFetching(authenticationLogsListState),
})

const enhance = compose(
  withAppService,
  withAuthenticationLogsService,
  withPaginationUtils,
  withRouterUtils,
  connect(mapState),
)

export default enhance(AccessLogsListContainer)
