import * as batchActions from './batch/actions'
import * as dropdownList from './dropdownList/actions'
import * as listActions from './list/actions'
import * as singleActions from './single/actions'

export default {
  ...batchActions,
  ...dropdownList,
  ...listActions,
  ...singleActions,
}
