import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'

import { FUNDING_FILTERS } from 'services/nurseryFunding/constants'

import { withAppService } from 'services/app'
import { withNurseryFundingService } from 'services/nurseryFunding'

import { Select, Typography } from 'components'

import i18n from 'translations'

import withInfiniteDropdownHelpers from './withInfiniteDropdown'

const GROUPS = {
  read: [
    'nurseryFunding.settings',
    'nurseryFundingSettings',
    'nurseryFunding.nursery',
    'nurseryFunding.fundingType',
    'fundingType',
  ],
}

class NurseryFundingDropdown extends Component {
  componentDidMount() {
    const { infiniteDropdownHelpers, nurseryFundingActions, value } = this.props

    if (!_.isArray(value)) {
      return infiniteDropdownHelpers.handleComponentDidMount({
        action: () => nurseryFundingActions.get((value?.value || value), {
          addToList: true,
          onFailed: this.notFoundValue,
          onSuccess: infiniteDropdownHelpers.handleComponentDidMountOnSuccess,
        }),
        value,
      })
    }

    return null
  }

  componentWillUnmount() {
    const { nurseryFundingActions } = this.props

    nurseryFundingActions.clearDropdownList()
  }

  handleLoadMoreElements = async (phrase) => {
    const { infiniteDropdownHelpers, limit, nurseryFundingActions, nurseryFundingHelpers } = this.props

    return infiniteDropdownHelpers.handleLoadMoreElements({
      clearAction: nurseryFundingActions.clearDropdownList,
      listAction: ({ newPage, newSearchPhrase, result }) => nurseryFundingActions.dropdownList({
        onSuccess: (response) => {
          const { data } = response

          const newData = _.map(data, (item) => {
            const { fundingType, label } = item
            const { name } = fundingType || {}

            return {
              ...item,
              name: (
                <React.Fragment>
                  <Typography variant="div">{label}</Typography>
                  <Typography
                    fontSize={14}
                    padding="2px 0 0"
                    variant="div"
                    ellipsis
                  >
                    {name}
                  </Typography>
                </React.Fragment>
              ),
            }
          })

          return infiniteDropdownHelpers.handleLoadMoreElementsOnSuccess({
            extraFields: ['type'],
            newPage,
            newSearchPhrase,
            response: {
              ...response,
              data: newData,
            },
            result,
          })
        },
        params: {
          criteria: nurseryFundingHelpers.getCriteria({
            search: newSearchPhrase,
            status: FUNDING_FILTERS.ACTIVE,
          }),
          groups: GROUPS,
          limit,
          page: newPage,
        },
      }),
      phrase,
    })
  }

  notFoundValue = () => {
    const { onChange } = this.props

    onChange(null)
  }

  handleChange = (e) => {
    const { infiniteDropdownHelpers, onChange } = this.props

    infiniteDropdownHelpers.handleOnChange(e, () => onChange(e))
  }

  render() {
    const {
      disabled,
      infiniteDropdownState,
      placeholder,
      rawValue,
      value,
      ...rest
    } = this.props
    const { cacheUniq, options, selectedValue } = infiniteDropdownState

    return (
      <Select
        {...rest}
        cacheUniq={cacheUniq}
        disabled={disabled}
        loadOptions={this.handleLoadMoreElements}
        options={options}
        placeholder={placeholder || i18n.t('global:NurseryFunding')}
        rawValue={rawValue || selectedValue || value}
        value={value}
        searchable
        onChange={this.handleChange}
      />
    )
  }
}

const enhance = compose(
  withAppService,
  withNurseryFundingService,
  withInfiniteDropdownHelpers,
)

export default enhance(NurseryFundingDropdown)
