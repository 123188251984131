import styled from 'styled-components'

export const StyledActionButtonContainer = styled.div`
  display: inline-block;
  margin-bottom: 40px;
  
  ${({ selectedChildren }) => selectedChildren && `
    margin-top: 20px;
  `}
`

export const StyledAdditionalObservationContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  & > div:nth-last-of-type(1) {
    margin-bottom: 0;
  }
`
