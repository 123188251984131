import React, { ChangeEvent, FocusEvent, PropsWithChildren } from 'react'
import { EventOrValueHandler } from 'redux-form'

import { FieldError, MaxCharactersText } from 'components'

import { StyledAutosizeTextArea, StyledInputContainer, StyledTextArea } from './TextAreaFieldStyled'

export interface TextAreaFieldProps {
  autosize?: boolean
  disableFieldError?: boolean
  disabled?: boolean
  error?: string
  height?: number
  maxCharacters?: number
  maxHeight?: string
  onBlur?: (event: EventOrValueHandler<FocusEvent<any, Element>>) => void
  onChange?: (event: EventOrValueHandler<ChangeEvent<any>>) => void
  padding?: string
  placeholder?: string
  valid?: boolean
  value?: string
}

const TextAreaField: React.FC<PropsWithChildren<TextAreaFieldProps>> = ({
  autosize,
  disableFieldError,
  disabled,
  error,
  height,
  maxCharacters,
  maxHeight,
  onBlur,
  onChange,
  padding,
  placeholder,
  valid,
  value,
  ...rest
}) => (
  <React.Fragment>
    <StyledInputContainer>
      {autosize
        ? (
          <StyledAutosizeTextArea
            $disabled={disabled}
            $height={height}
            $maxHeight={maxHeight}
            $padding={padding}
            $valid={valid}
            maxLength={maxCharacters}
            placeholder={placeholder}
            valid={valid}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            {...rest}
          />
        ) : (
          <StyledTextArea
            $disabled={disabled}
            $height={height}
            $maxHeight={maxHeight}
            $padding={padding}
            $valid={valid}
            maxLength={maxCharacters}
            placeholder={placeholder}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            {...rest}
          />
        )}
    </StyledInputContainer>
    {maxCharacters && (
      <MaxCharactersText
        charactersCount={value.length}
        maxCharacters={maxCharacters}
      />
    )}
    {!disableFieldError && (
      <FieldError error={error} />
    )}
  </React.Fragment>
)

export default TextAreaField
