import React from 'react'
import { compose } from 'recompose'

import { withModalService } from 'services/utils/modal'
import { withRouter } from 'services/router'

import { Button, ModalBox } from 'components'

import i18n from 'translations'

import { properties } from 'app-config'

import { StyledRowContainer, StyledTextContainer, StyledTitle } from './HelpModalStyled'

const HelpModal = ({ hideModal, navigate }) => {
  const handleCloseClick = () => {
    hideModal()
  }

  const handleLogTicketClick = () => {
    handleCloseClick()

    navigate('/logTicket')
  }

  return (
    <ModalBox
      maxWidth="600"
      title={i18n.t('module:Modals:Help:title')}
      iosPolyfill
      onCloseClick={handleCloseClick}
    >
      <StyledRowContainer>
        <StyledTitle>
          {i18n.t('module:Modals:Help:KnowledgeBase:title')}
        </StyledTitle>
        <StyledTextContainer>
          <p>
            {i18n.t('module:Modals:Help:KnowledgeBase:description')}
          </p>
        </StyledTextContainer>
        <Button
          label={i18n.t('module:Modals:Help:KnowledgeBase:buttonLabel')}
          target="_blank"
          to={properties.knowledgeBase}
        />
      </StyledRowContainer>
      <StyledRowContainer>
        <StyledTitle>
          {i18n.t('module:Modals:Help:HelpDesk:title')}
        </StyledTitle>
        <StyledTextContainer>
          <p>
            {i18n.t('module:Modals:Help:HelpDesk:description1')}
            <span>
              {properties.helpDeskNumber}
            </span>
          </p>
          <p>
            {i18n.t('module:Modals:Help:HelpDesk:description2')}
          </p>
        </StyledTextContainer>
        <Button
          hierarchy={'secondary'}
          label={i18n.t('module:Modals:Help:HelpDesk:buttonLabel')}
          onClick={handleLogTicketClick}
        />
      </StyledRowContainer>
    </ModalBox>
  )
}

const enhance = compose(
  withModalService,
  withRouter,
)

export default enhance(HelpModal)
