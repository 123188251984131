import moment from 'moment'
import _ from 'lodash'

import React from 'react'
import { Field, FieldArray, reduxForm } from 'redux-form'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { isRequired } from 'utils/fieldValidation'

import { millisecondsToHoursAndMinutesString } from 'utils/date'

import { Callout, DropdownMenu, Form, Space, Spinner, Typography } from 'components'
import LeaveBanner from 'module/Modals/Staff/components/LeaveBanner'

import i18n from 'translations'

import {
  StyledCustomTable,
  StyledCustomTableWrapper,
  StyledDay,
  StyledRow,
  StyledRowHeader,
  StyledRowWrapper,
} from './AddModalFormStyled'

export const STAFF_LEAVE_ADD_MODAL_FORM = 'AddModalForm'

const renderRowContent = ({
  dayIndex,
  fields,
  formErrors,
  formValues,
  isFetching,
  isSubmitting,
  onChangeField,
  onCopyToAll,
}) => (item, index) => {
  const { date, membershipShiftTimes = {} } = formValues || {}
  const dateFrom = date?.[0] || moment()
  const day = moment(dateFrom).add('day', dayIndex).format(DEFAULT_DATE_FORMAT)

  const dayShifts = membershipShiftTimes[day]
  const shiftTimeItem = dayShifts?.[index] || {}
  const { endTime, startTime } = shiftTimeItem
  let timeDiff = null

  if (startTime && endTime) {
    timeDiff = moment(moment(endTime).set('seconds', 0)).diff(moment(startTime).set('seconds', 0), true)
  }

  if (0 > timeDiff) {
    onChangeField(`membershipShiftTimes.${day}.endTime`, null)

    timeDiff = null
  }

  let rowErrors = []
  _.each(formErrors?.membershipShiftTimes?.[day]?.[0], (value) => rowErrors.push(value))

  rowErrors = _.uniq(rowErrors)

  return (
    <React.Fragment>
      <StyledRowWrapper>
        {0 === index
          ? (
            <StyledDay>
              <React.Fragment>
                <Typography transform="uppercase" variant="span" bold>
                  {moment(day).format('ddd')}
                </Typography>
                {' '}
                <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={17} variant="span">
                  {moment(day).format('D MMM')}
                </Typography>
              </React.Fragment>
            </StyledDay>
          )
          : (
            <StyledDay noBorder />
          )}
        <StyledRow>
          <Field
            component={Form.TimePicker}
            disabled={isSubmitting || isFetching}
            name={`${item}.startTime`}
            validate={isRequired}
            visualType="modern"
          />
        </StyledRow>
        <StyledRow>
          <Field
            component={Form.TimePicker}
            disabled={isSubmitting || isFetching}
            name={`${item}.endTime`}
            validate={isRequired}
            visualType="modern"
          />
        </StyledRow>
        <StyledRow border>
          <Typography color={NEUTRAL_COLOURS.GRAY}>
            {timeDiff ? millisecondsToHoursAndMinutesString(timeDiff) : '-'}
          </Typography>
        </StyledRow>
        <StyledRow border center>
          <DropdownMenu
            disabled={isSubmitting || isFetching}
            small
          >
            <DropdownMenu.Item
              icon="plus"
              label={i18n.t('module:Modals:Staff:Leave:Add:addRow')}
              onClick={() => fields.push({})}
            />
            <DropdownMenu.Item
              icon="repeat"
              label={i18n.t('module:Modals:Staff:Leave:Add:copyToAll')}
              onClick={() => onCopyToAll(dayShifts)}
            />
            {1 < fields.length && (
              <React.Fragment>
                <DropdownMenu.Divider />
                <DropdownMenu.Item
                  type="delete"
                  onClick={() => fields.remove(index)}
                />
              </React.Fragment>
            )}
          </DropdownMenu>
        </StyledRow>
      </StyledRowWrapper>
      <Callout
        content={rowErrors}
        icon="exclamation-mark"
        iconVariant="circle"
        error
      />
    </React.Fragment>
  )
}

const renderRow = (props) => {
  const { dayIndex, fields, formValues, leaves } = props
  const { date, membershipShiftTimes = {} } = formValues || {}
  const dateFrom = date?.[0] || moment()
  const day = moment(dateFrom).add('day', dayIndex).format(DEFAULT_DATE_FORMAT)
  const shiftTimeItem = membershipShiftTimes[day] || {}

  return (
    <React.Fragment>
      {fields.map(renderRowContent(props))}
      <LeaveBanner
        date={day}
        formValues={shiftTimeItem}
        leaves={leaves}
      />
    </React.Fragment>
  )
}

const AddModalForm = ({
  errorMessages,
  formErrors,
  formValues,
  handleSubmit,
  isEditMode,
  isFetching,
  isInitialized,
  isSubmitting,
  leaves,
  onChangeDate,
  onChangeField,
  onChangeMainField,
  onCloseClick,
  onCopyToAll,
  onSubmit,
}) => {
  if (!isInitialized) {
    return (
      <Spinner />
    )
  }

  const { date } = formValues || {}
  const dateFrom = moment(date?.[0]) || moment()
  const dateTo = moment(date?.[1]) || moment()
  const daysDiffs = moment(dateTo).startOf('day')
    .diff(moment(dateFrom).startOf('day'), 'days') + 1

  const renderTable = () => (
    <StyledCustomTableWrapper>
      {0 < daysDiffs && _.times(daysDiffs, (dayIndex) => (
        <StyledCustomTable key={dayIndex}>
          {!dayIndex && (
            <StyledRowWrapper>
              <StyledRowHeader>
                <Typography>
                  {_.upperFirst(i18n.t('global:day'))}
                </Typography>
              </StyledRowHeader>
              <StyledRowHeader>
                <Typography>
                  {_.upperFirst(i18n.t('global:start'))}
                </Typography>
              </StyledRowHeader>
              <StyledRowHeader>
                <Typography>
                  {_.upperFirst(i18n.t('global:end'))}
                </Typography>
              </StyledRowHeader>
              <StyledRowHeader>
                <Typography>
                  {_.upperFirst(i18n.t('global:hours'))}
                </Typography>
              </StyledRowHeader>
              <StyledRowHeader />
            </StyledRowWrapper>
          )}
          <FieldArray
            component={renderRow}
            name={`membershipShiftTimes.${
              moment(dateFrom).add('day', dayIndex).format(DEFAULT_DATE_FORMAT)
            }`}
            props={{
              dayIndex,
              formErrors,
              formValues,
              isFetching,
              isSubmitting,
              leaves,
              onChangeField,
              onCopyToAll,
            }}
          />
        </StyledCustomTable>
      ))}
    </StyledCustomTableWrapper>
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Callout content={errorMessages?.membershipShiftTimes} error />
      {errorMessages?.membershipShiftTimes && (
        <Space space="20px" />
      )}
      <Form.Row label={_.upperFirst(i18n.t('global:staffMember'))} verticalLabel>
        <Form.Row.FlexItem flex="1">
          <Field
            component={Form.InfiniteDropdowns.Memberships}
            disabled={isEditMode || isSubmitting || isFetching}
            extraParams={{
              disablePendingFilter: true,
              nonTeaching: true,
            }}
            groups={{ read: ['membership.details'] }}
            name="membership"
            placeholder={_.upperFirst(i18n.t('global:staffMember'))}
            validate={isRequired}
            bodyTarget
            onChange={onChangeMainField}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:Staff:StaffLeave:leaveType')} verticalLabel>
        <Form.Row.FlexItem flex="1">
          <Field
            component={Form.InfiniteDropdowns.LeaveTypes}
            disabled={isEditMode || isSubmitting || isFetching}
            name="leaveShiftType"
            placeholder={i18n.t('module:Staff:StaffLeave:leaveType')}
            validate={isRequired}
            bodyTarget
          />
        </Form.Row.FlexItem>
      </Form.Row>
      {formValues?.membership ? (
        <React.Fragment>
          <Form.Row label={i18n.t('global:dateRange')} verticalLabel>
            <Field
              component={Form.DatePicker}
              disabled={isSubmitting || isFetching}
              disabledDays={[{
                after: moment(formValues.date?.[0]).add(1, 'year').toDate(),
              }]}
              name="date"
              validate={isRequired}
              range
              onChange={onChangeDate}
            />
          </Form.Row>
          {renderTable()}
        </React.Fragment>
      ) : (
        <Space space="60px" />
      )}
      <Form.FooterActions
        isSubmitting={isSubmitting}
        submitLabel={isEditMode
          ? i18n.t('module:Modals:Staff:Leave:Add:editTitle')
          : i18n.t('module:Modals:Staff:Leave:Add:title')}
        onCancelClick={onCloseClick}
      />
    </Form>
  )
}

export default reduxForm({ form: STAFF_LEAVE_ADD_MODAL_FORM })(AddModalForm)
