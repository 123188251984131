import React from 'react'

import { BasicViewProps } from 'constants/models'
import { generateRoute } from 'utils/routing'

import { Callout, Page, Spinner } from 'components'

import i18n from 'translations'

import EditForm, { EditFormVales } from './components/EditForm'

export interface EditViewProps extends BasicViewProps {
  initialValues?: EditFormVales
  isFetching?: boolean
}

const EditView: React.FC<EditViewProps> = ({
  errorMessages,
  initialValues,
  isFetching,
  isSubmitting,
  onSubmit,
}) => {
  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    return (
      <EditForm
        cancelLink={generateRoute('MANAGEMENT.FINANCE_SETTINGS.REFERENCE_CODE_GENERATION')}
        initialValues={initialValues}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      />
    )
  }

  return (
    <Page.Section
      title={i18n.t('module:Management:Finance:ReferenceCodeGeneration:title')}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default EditView
