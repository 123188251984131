import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as childDailyDiarySelectors from './selectors'

const withChildDailyDiaryService = (WrappedComponent) => {
  const mapState = ({ childDailyDiary }) => ({ childDailyDiary })

  const mapDispatch = injectActions('childDailyDiaryActions', actions)

  const Component = ({ childDailyDiary, childDailyDiaryActions, ...others }) => (
    <WrappedComponent
      childDailyDiaryActions={childDailyDiaryActions}
      childDailyDiaryListState={childDailyDiary.list}
      childDailyDiarySelectors={childDailyDiarySelectors}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withChildDailyDiaryService
