import styled from 'styled-components'

import layout from 'constants/layout'

import { StyledContainer, StyledContentContainer } from 'components/Field/InlineEdit/InlineEditStyled'
import { StyledRowLabelContainer } from 'components/Form/helpers/FormRow/FormRowStyled'

export const StyledVisibleBody = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 350px minmax(0,400px);
  margin: 0 -15px;
  
  @media(max-width: ${layout.mobileWidthResolution}px) {
    grid-template-columns: auto;
    grid-row-gap: 10px;
  }

  @media(max-width: 600px) {
    margin: -15px 0 0;
  }
`

export const StyledRemoveButton = styled.div`
  margin-top: 33px;
  
  ${({ withoutLabel }) => withoutLabel && `
    margin-top: 5px;
  `}
  
  @media (max-width: 740px) {
    margin-top: 0;
  }
`

export const StyledInlineEdit = styled.div`
  ${({ isEditMode }) => isEditMode && `
    @media (max-width: 740px) {
      ${StyledContentContainer} {
        margin: 0;
      }   
    }
    
    @media (max-width: 480px) {
      ${StyledContainer} {
        flex-direction: column-reverse;
        
        & > div {
          width: 100%;
          
          &:nth-of-type(2) {
            justify-content: flex-end;
            margin-left: auto;
            margin-right: 20px;
          }
        }
      }  
    }
  `}
`

export const StyledEditableComponent = styled.div`
  @media (max-width: 740px) {
    ${StyledRowLabelContainer} {
      display: none;
    }
    
    & > div {
      display: grid;
      align-items: center;
      grid-column-gap: 10px;
      grid-template-columns: auto 48px;
      margin-bottom: 25px;
      
      & > div:nth-of-type(3) {
        grid-column-start: 2;
        grid-row-start: 1;
        grid-row-end: 3;
      }
    }
  }
`
