import {
  CLEAR_DAILY_DIARIES,
  LIST_DAILY_DIARIES,
  LIST_DAILY_DIARIES_FAILED,
  LIST_DAILY_DIARIES_SUCCESS,
  SEND_DAILY_DIARIES,
  SEND_DAILY_DIARIES_FAILED,
  SEND_DAILY_DIARIES_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ dailyDiaryApiClient }) => (dispatch) => {
  const { mergeResult, onSuccess, params = {} } = options

  dispatch({
    type: LIST_DAILY_DIARIES,
  })

  dailyDiaryApiClient.list(params).then(
    (response) => {
      dispatch({
        mergeResult,
        payload: response,
        type: LIST_DAILY_DIARIES_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: LIST_DAILY_DIARIES_FAILED,
      })
    },
  )
}

export const send = (options = {}) => ({ dailyDiaryApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {} } = options

  dispatch({
    type: SEND_DAILY_DIARIES,
  })

  dailyDiaryApiClient.send(params).then(
    (response) => {
      dispatch({
        payload: response,
        type: SEND_DAILY_DIARIES_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: SEND_DAILY_DIARIES_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const clearList = () => ({
  type: CLEAR_DAILY_DIARIES,
})
