import { generateBasicActions } from 'utils/service'

import {
  CLEAR_EXCEPTIONAL_UNAVAILABLE_TIMES_LIST,
  LIST_EXCEPTIONAL_UNAVAILABLE_TIMES,
  LIST_EXCEPTIONAL_UNAVAILABLE_TIMES_FAILED,
  LIST_EXCEPTIONAL_UNAVAILABLE_TIMES_SUCCESS,
} from './constants'

export const listExceptionalUnavailableTimes = (options = {}) => ({ requestedExtraSessionsApiClient }) => (
  generateBasicActions.list({
    constants: {
      failed: LIST_EXCEPTIONAL_UNAVAILABLE_TIMES_FAILED,
      init: LIST_EXCEPTIONAL_UNAVAILABLE_TIMES,
      success: LIST_EXCEPTIONAL_UNAVAILABLE_TIMES_SUCCESS,
    },
    options,
    service: requestedExtraSessionsApiClient,
    serviceMethod: 'listExceptionalUnavailableTimes',
  })
)

export const clearExceptionalUnavailableTimesList = () => ({
  type: CLEAR_EXCEPTIONAL_UNAVAILABLE_TIMES_LIST,
})
