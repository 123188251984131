import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CHILDREN_STARTING_REPORT,
  CHILDREN_STARTING_REPORT_FAILED,
  CHILDREN_STARTING_REPORT_SUCCESS,
  CLEAR_CHILDREN_STARTING_REPORT,
} from './constants'

export const getChildren = (options: BasicActionOptionsProps) => ({
  childrenStartingReportApiClient,
}) => generateBasicActions.list({
  constants: {
    failed: CHILDREN_STARTING_REPORT_FAILED,
    init: CHILDREN_STARTING_REPORT,
    success: CHILDREN_STARTING_REPORT_SUCCESS,
  },
  options,
  service: childrenStartingReportApiClient,
  serviceMethod: 'getChildrenStarting',
})

export const clearChildren = () => ({
  type: CLEAR_CHILDREN_STARTING_REPORT,
})
