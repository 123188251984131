import styled from 'styled-components'

import { Link } from 'react-router'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledContainer = styled.div`
  width: 120px;
  background: ${NEUTRAL_COLOURS.WHITE};
  padding: 10px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px 0 0 20px;

  @media (min-width: 768px) {
    ${({ size }) => 'small' !== size && 'padding: 10px;'}
    ${({ size }) => 'small' !== size && 'width: 120px;'}
  }

  @media (min-width: 1024px) {
    width: 170px;
  }
`

export const StyledProfileInner = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const StyledDate = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: ${NEUTRAL_COLOURS.BASIC};
`

export const StyledProfileName = styled.div`
  color: ${NEUTRAL_COLOURS.BASIC};
  margin-top: 8px;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  word-break: break-word;
`

export const StyledSubtitle = styled.div`
  color: #00A0E0;
  font-size: 14px;
  margin-top: 3px;
`
export const StyledAvatarContainer = styled.div`
  position: relative;
`

export const StyledBadgeContainer = styled.div`
  position: absolute;
  top: -4px;
  right: -2px;
  font-size: 13px;
  font-weight: 600;
  background: ${({ color }) => color};
  color: ${NEUTRAL_COLOURS.WHITE};
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  padding: 2px;
`
