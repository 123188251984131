export const GET_NEWSLETTER_RECIPIENT_LIST = 'GET_NEWSLETTER_RECIPIENT_LIST'
export const GET_NEWSLETTER_RECIPIENT_LIST_SUCCESS = 'GET_NEWSLETTER_RECIPIENT_LIST_SUCCESS'
export const GET_NEWSLETTER_RECIPIENT_LIST_FAILED = 'GET_NEWSLETTER_RECIPIENT_LIST_FAILED'

export const RESEND_NEWSLETTER_RECIPIENT = 'RESEND_NEWSLETTER_RECIPIENT'
export const RESEND_NEWSLETTER_RECIPIENT_SUCCESS = 'RESEND_NEWSLETTER_RECIPIENT_SUCCESS'
export const RESEND_NEWSLETTER_RECIPIENT_FAILED = 'RESEND_NEWSLETTER_RECIPIENT_FAILED'

export const DELETE_NEWSLETTER_RECIPIENT = 'DELETE_NEWSLETTER_RECIPIENT'
export const DELETE_NEWSLETTER_RECIPIENT_SUCCESS = 'DELETE_NEWSLETTER_RECIPIENT_SUCCESS'
export const DELETE_NEWSLETTER_RECIPIENT_FAILED = 'DELETE_NEWSLETTER_RECIPIENT_FAILED'

export const CLEAR_NEWSLETTER_RECIPIENT_LIST = 'CLEAR_NEWSLETTER_RECIPIENT_LIST'
