import React from 'react'

import { StyledContainer } from './RoomListsStyled'

const RoomLists = ({ children }) => (
  <StyledContainer>
    {children}
  </StyledContainer>
)

export default RoomLists
