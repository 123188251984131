import moment from 'moment'
import { compose } from 'recompose'

import React, { useCallback, useEffect, useState } from 'react'

import { constants, withInvoicesService } from 'services/legacy/invoices'

import MonthlyIncomeWidgetView from './MonthlyIncomeWidgetView'

const GROUPS_STATISTICS = {
  read: [
    'invoiceInvoiceStatistics',
    'statistics.invoice.income',
  ],
}
const DATE_FORMAT = 'YYYY-MM-DD'

const STATISTIC_MONTH = {
  CURRENT: 'CURRENT',
  PREVIOUS: 'PREVIOUS',
}

const INITIAL_STATE = {
  data: null,
  isLoading: true,
  monthName: null,
}

const MonthlyIncomeWidgetContainer = ({
  invoicesActions,
  invoicesSelectors,
}) => {
  const [currentStatistics, setCurrentStatistics] = useState(INITIAL_STATE)
  const [previousStatistics, setPreviousStatistics] = useState(INITIAL_STATE)

  const updateStatistics = (statisticMonth, monthName) => ({ data }) => {
    if (statisticMonth === STATISTIC_MONTH.CURRENT) {
      setCurrentStatistics({ data, isLoading: false, monthName })

      return
    }

    setPreviousStatistics({ data, isLoading: false, monthName })
  }

  const getRevenueCriteria = useCallback(invoicesSelectors.getRevenueCriteria, [])
  const getStatistics = useCallback(invoicesActions.getStatistics, [])

  useEffect(() => {
    const fetchStatistics = (startDate, endDate, callback) => {
      const { INVOICE_DATE_TYPES } = constants

      const criteria = getRevenueCriteria({
        date: {
          after: startDate,
          before: endDate,
        },
        dateType: INVOICE_DATE_TYPES.issueDate,
      })

      const statisticsParams = {
        criteria,
        groups: GROUPS_STATISTICS,
      }

      getStatistics(statisticsParams, callback)
    }

    const currentStartDate = moment().startOf('month').format(DATE_FORMAT)
    const currentEndDate = moment().endOf('month').format(DATE_FORMAT)
    const currentMonthFullName = moment(currentStartDate).format('MMMM')

    fetchStatistics(
      currentStartDate,
      currentEndDate,
      updateStatistics(STATISTIC_MONTH.CURRENT, currentMonthFullName),
    )

    const previousStartDate = moment().add(-1, 'month').startOf('month').format(DATE_FORMAT)
    const previousEndDate = moment().add(-1, 'month').endOf('month').format(DATE_FORMAT)
    const previousFullMonthName = moment(previousStartDate).format('MMMM')

    fetchStatistics(
      previousStartDate,
      previousEndDate,
      updateStatistics(STATISTIC_MONTH.PREVIOUS, previousFullMonthName),
    )
  }, [getRevenueCriteria, getStatistics])

  return (
    <MonthlyIncomeWidgetView
      currentStatistics={currentStatistics}
      previousStatistics={previousStatistics}
    />
  )
}

const enhance = compose(withInvoicesService)

export default enhance(MonthlyIncomeWidgetContainer)
