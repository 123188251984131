import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_REQUESTED_EXTRA_SESSION,
  GET_REQUESTED_EXTRA_SESSION,
  GET_REQUESTED_EXTRA_SESSION_FAILED,
  GET_REQUESTED_EXTRA_SESSION_SUCCESS,
  UPDATE_REQUESTED_EXTRA_SESSION,
  UPDATE_REQUESTED_EXTRA_SESSION_FAILED,
  UPDATE_REQUESTED_EXTRA_SESSION_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_REQUESTED_EXTRA_SESSION,
    itemFailed: GET_REQUESTED_EXTRA_SESSION_FAILED,
    itemInit: GET_REQUESTED_EXTRA_SESSION,
    itemSuccess: GET_REQUESTED_EXTRA_SESSION_SUCCESS,
    updateFailed: UPDATE_REQUESTED_EXTRA_SESSION_FAILED,
    updateInit: UPDATE_REQUESTED_EXTRA_SESSION,
    updateSuccess: UPDATE_REQUESTED_EXTRA_SESSION_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
