import _ from 'lodash'

import i18n from 'translations'

export const NURSERY_TABLE_COLUMNS = [
  {
    align: 'left',
    field: 'child',
    title: _.upperFirst(i18n.t('global:child')),
  },
  {
    align: 'left',
    field: 'creditNoteNumber',
    title: i18n.t('module:Finance:Reports:CreditNotes:Table:Header:creditNoteNumber'),
    width: '100px',
  },
  {
    align: 'left',
    field: 'invoiceLinked',
    title: i18n.t('module:Finance:Reports:CreditNotes:Table:Header:invoiceLinked'),
    width: '80px',
  },
  {
    align: 'center',
    field: 'amount',
    title: _.upperFirst(i18n.t('global:amount')),
    width: '120px',
  },
  {
    align: 'left',
    field: 'author',
    title: i18n.t('module:Finance:Reports:CreditNotes:Table:Header:createdBy'),
    width: '220px',
  },
  {
    field: 'createdAt',
    title: i18n.t('module:Finance:Reports:CreditNotes:Table:Header:createdDate'),
    width: '100px',
  },
  {
    align: 'left',
    field: 'creditNoteReason',
    title: i18n.t('module:Finance:Reports:CreditNotes:Table:Header:creditNoteReason'),
  },
]

export const ORGANIZATION_TABLE_COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'name',
    title: _.upperFirst(i18n.t('global:site')),
  },
  {
    field: 'count',
    title: i18n.t('module:Finance:Reports:CreditNotes:Organization:Table:Header:creditNotes'),
  },
  {
    field: 'amount',
    title: i18n.t('module:Finance:Reports:CreditNotes:Organization:Table:Header:creditNotesAmount'),
  },
]
