import i18n from 'translations'

export const STATUS_OPTIONS_VALUES = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
  PENDING: 'PENDING',
}

export const STATUS_OPTIONS_DROPDOWN = [
  {
    label: i18n.t('global:Active'),
    value: STATUS_OPTIONS_VALUES.ACTIVE,
  },
  {
    label: i18n.t('global:Pending'),
    value: STATUS_OPTIONS_VALUES.PENDING,
  },
  {
    label: i18n.t('global:Archived'),
    value: STATUS_OPTIONS_VALUES.DISABLED,
  },
]

export const OTHER_OPTION = {
  label: i18n.t('global:Other'),
  value: 'other',
}
