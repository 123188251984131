import {
  CLEAR_LIST_HOME_OBSERVATIONS,
  LIST_HOME_OBSERVATIONS,
  LIST_HOME_OBSERVATIONS_FAILED,
  LIST_HOME_OBSERVATIONS_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: {},
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case LIST_HOME_OBSERVATIONS:
      return {
        ...state,
        error: null,
        isFetching: true,
      }
    case LIST_HOME_OBSERVATIONS_SUCCESS:
      return {
        ...state,
        data: payload.data,
        error: null,
        isFetching: false,
        meta: payload.meta,
      }
    case LIST_HOME_OBSERVATIONS_FAILED:
      return {
        ...state,
        error,
        isFetching: false,
      }
    case CLEAR_LIST_HOME_OBSERVATIONS:
      return initialState
    default:
      return state
  }
}
