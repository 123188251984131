import _ from 'lodash'

import React, { useState } from 'react'
import { Link } from 'react-router'

import { COMMENT_TYPE } from 'services/comments/constants'
import { OBSERVATIONS_PAGE_TYPE } from 'module/Learning/Observations/constants'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'
import { getBrandingColor } from 'utils/branding'
import { typeByObject } from 'utils/typescript'

import { CircleIcon, DateString, GroupAvatars, List, Space, Typography } from 'components'
import Like from 'module/LikesAndComments/components/Like/Like'

import i18n from 'translations'

import { StyledLikesWrapper, StyledSubContentWrapper } from './ObservationItemStyled'

interface ObservationItemProps {
  hasAccessToLikesAndComments: boolean
  hasAccessToLikesAndCommentsSettings: boolean
  observation: any
  pageType: typeByObject<typeof OBSERVATIONS_PAGE_TYPE>
}

const ObservationItem: React.FC<ObservationItemProps> = ({
  hasAccessToLikesAndComments,
  hasAccessToLikesAndCommentsSettings,
  observation,
  pageType,
}) => {
  const {
    borderColor,
    borderTitle,
    childObservationsCount,
    commentCount,
    comments,
    content,
    extraTitle,
    extraTitleColor,
    homeObservationExists,
    id,
    likeCount,
    observationDate,
    previewChildObservations,
    previewChildren,
    titleIcon,
    titleIconColor,
    userLike,
  } = observation
  const [likeCountFinal, setLikeCountFinal] = useState<number>(likeCount)
  const [userLikeFinal, setUserLikeFinal] = useState<boolean>(userLike)

  const handleLikeChange = (direction, response?) => {
    setLikeCountFinal(likeCountFinal + (direction ? 1 : -1))
    setUserLikeFinal(direction ? response : null)
  }

  const renderContent = (
    <StyledSubContentWrapper $hasAccessToLikesAndComments={hasAccessToLikesAndComments}>
      <div>
        {content}
        {comments && (
          <React.Fragment>
            <br />
            <br />
            {comments}
            <br />
            <br />
          </React.Fragment>
        )}
        {1 === previewChildObservations?.length && (
          <React.Fragment>
            {previewChildObservations[0].comments}
            <br />
          </React.Fragment>
        )}
      </div>
      {hasAccessToLikesAndComments && (
        <div>
          <StyledLikesWrapper>
            <Like
              filters={[
                {
                  id,
                  likeType: COMMENT_TYPE.OBSERVATION,
                },
                ..._.map(previewChildObservations, (i) => ({
                  id: i.id,
                  likeType: COMMENT_TYPE.CHILD_OBSERVATION,
                })),
              ]}
              likeCount={likeCountFinal}
              likeType={COMMENT_TYPE.OBSERVATION}
              objectId={id}
              preview={!hasAccessToLikesAndCommentsSettings}
              userLike={userLikeFinal}
              onChange={handleLikeChange}
            />
          </StyledLikesWrapper>
          <Space space="10px" />
          {(hasAccessToLikesAndCommentsSettings || (!hasAccessToLikesAndCommentsSettings && commentCount)) ? (
            <Link
              to={`${pageType === OBSERVATIONS_PAGE_TYPE.APPROVALS
                ? generateRoute('APPROVALS.OBSERVATIONS.REVIEW', { observationId: id })
                : generateRoute('LEARNING.OBSERVATIONS.REVIEW', { observationId: id })}?section=comments`}
            >
              <Typography bold nowrap primary>
                {`${i18n.t('global:Comments')} (${commentCount || 0})`}
              </Typography>
            </Link>
          ) : null}
        </div>
      )}
    </StyledSubContentWrapper>
  )

  return (
    <List.Standard.Row
      borderColor={borderColor}
      borderTitle={borderTitle}
      content={renderContent}
      extraTitle={extraTitle}
      extraTitleColor={extraTitleColor}
      key={id}
      leftColumn={(
        <GroupAvatars
          backgroundColor={borderColor}
          borderColor={NEUTRAL_COLOURS.SILVER}
          countOverride={childObservationsCount}
          persons={previewChildren}
          renderUrl={(element) => generateRoute('CHILDREN.CHILD', { childId: element.id })}
        />
      )}
      title={(
        <React.Fragment>
          {homeObservationExists && (
            <CircleIcon
              background={getBrandingColor('tertiary-color')}
              letter={i18n.t('module:Learning:Observations:ObservationsList:P')}
              size={20}
            />
          )}
          <DateString date={observationDate} relative />
        </React.Fragment>
      )}
      titleIcon={titleIcon}
      titleIconColor={titleIconColor}
      to={pageType === OBSERVATIONS_PAGE_TYPE.APPROVALS
        ? generateRoute('APPROVALS.OBSERVATIONS.REVIEW', { observationId: id })
        : generateRoute('LEARNING.OBSERVATIONS.REVIEW', { observationId: id })}
    />
  )
}

export default ObservationItem
