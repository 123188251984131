import React from 'react'

import { Avatar, Callout, Page, Spinner, Typography } from 'components'

import i18n from 'translations'

import ObservationNextStepsForm from './components/ObservationNextStepsForm'

const ObservationNextStepsView = ({
  child,
  errorMessages,
  isFetching,
  ...rest
}) => {
  const { ageMonths, firstName, photo, surname } = child || {}

  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    return (
      <ObservationNextStepsForm
        {...rest}
      />
    )
  }

  return (
    <Page.Section
      actions={!isFetching && (
        <Avatar
          avatarSize="medium"
          initials={[firstName, surname]}
          src={photo}
          subTitle={`${i18n.t('global:ageMonths', { ageMonths })}`}
          title={(
            <Typography fontSize={14} primary>
              {`${firstName} ${surname}`}
            </Typography>
          )}
        />
      )}
      isLoading={isFetching}
      title={i18n.t('module:Learning:Observations:ObservationNextSteps:title')}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default ObservationNextStepsView
