import { BasicSingleInitialState } from 'utils/reducer'

import {
  ADD_INVOICE_ITEM,
  CLEAR_INVOICE_PREVIEW,
  GET_INVOICE_PREVIEW,
  GET_INVOICE_PREVIEW_FAILED,
  GET_INVOICE_PREVIEW_SUCCESS,
  REMOVE_INVOICE_ITEM,
  REPEAT_INVOICE_PREVIEW,
  REPEAT_INVOICE_PREVIEW_FAILED,
  REPEAT_INVOICE_PREVIEW_SUCCESS,
  SET_INITIAL_VALUES,
} from './constants'

import {
  AUTO_CALCULATE_INVOICE,
  AUTO_CALCULATE_INVOICE_FAILED,
  AUTO_CALCULATE_INVOICE_SUCCESS,
  AUTO_REFRESH_INVOICE_SUCCESS,
  UPDATE_INVOICE_SUCCESS,
} from '../single/constants'

import {
  removeItem,
  setInitialValues,
  updateAutoCalculatedItems,
  updateItem,
  updateItemV3,
  updateRepeatInvoice,
} from './helpers'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false,
}

export interface InvoicesPreviewState extends BasicSingleInitialState {}

export default (state = initialState, {
  error,
  invoiceSettings,
  isFinanceV3Enabled,
  isNotOriginalType,
  item,
  key,
  oldTotal,
  payload,
  totalDifference,
  type,
}) => {
  switch (type) {
    case GET_INVOICE_PREVIEW:
    case REPEAT_INVOICE_PREVIEW:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_INVOICE_PREVIEW_SUCCESS:
      return { ...state, data: payload.data, isFetching: false }
    case REPEAT_INVOICE_PREVIEW_SUCCESS:
      return updateRepeatInvoice(state, payload)
    case GET_INVOICE_PREVIEW_FAILED:
    case REPEAT_INVOICE_PREVIEW_FAILED:
      return { ...state, error, isFetching: false }
    case AUTO_CALCULATE_INVOICE:
      return { ...state, error: initialState.error }
    case AUTO_CALCULATE_INVOICE_SUCCESS:
      return updateAutoCalculatedItems(state, payload.data.preview)
    case AUTO_CALCULATE_INVOICE_FAILED:
      return { ...state, error }
    case UPDATE_INVOICE_SUCCESS:
    case AUTO_REFRESH_INVOICE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          invoice: {
            ...state.data?.invoice || {},
            ...payload.data,
          },
          sendLogs: payload.data.sendLogs,
        },
      }
    case ADD_INVOICE_ITEM:
      if (isFinanceV3Enabled) {
        return updateItemV3(state, item, totalDifference, oldTotal, isNotOriginalType)
      }

      return updateItem(state, item, totalDifference, oldTotal, isNotOriginalType)
    case REMOVE_INVOICE_ITEM:
      return removeItem(state, key, totalDifference)
    case SET_INITIAL_VALUES:
      return setInitialValues(initialState, invoiceSettings)
    case CLEAR_INVOICE_PREVIEW:
      return initialState
    default:
      return state
  }
}
