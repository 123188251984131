import styled from 'styled-components'

export const StyledTableCell = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
`

export const StyledEmptyCell = styled.div`
  height: 36px;
`

export const StyledSpinnerWrapper = styled.div`
  height: 280px;
`
