import React from 'react'

import { Callout, Form, Page, Spinner, TextField, Typography } from 'components'

import i18n from 'translations'

import PinBox from './components/PinBox'

const DeviceDetailsView = ({
  deviceItem,
  errorMessages,
  isFetching,
}) => {
  const renderForm = () => (
    <React.Fragment>
      <Form.Row
        label={i18n.t('module:Management:Security:AuthorisedDevices:global:name')}
        width={{ field: '400px' }}
        required
      >
        <TextField
          value={deviceItem?.name}
          disabled
        />
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Management:Security:AuthorisedDevices:global:staffMember')}
        width={{ field: '300px' }}
        required
      >
        <TextField
          value={deviceItem?.pin.user.name}
          disabled
        />
      </Form.Row>
    </React.Fragment>
  )

  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    return (
      <React.Fragment>
        {renderForm()}
        <PinBox
          deviceItem={deviceItem}
        />
      </React.Fragment>
    )
  }

  return (
    <Page.Section title={i18n.t('module:Management:Security:AuthorisedDevices:DeviceDetails:title')}>
      <Callout content={errorMessages} error />
      <Typography margin="0 0 25px" bold>
        {i18n.t('module:Management:Security:AuthorisedDevices:global:description')}
      </Typography>
      {renderContent()}
    </Page.Section>
  )
}

export default DeviceDetailsView
