import React, { PropsWithChildren, useEffect } from 'react'
import ReactInfiniteScroll from 'react-infinite-scroll-component'
import { useInView } from 'react-intersection-observer'

import { Spinner } from 'components'

import { StyledLoader } from './InfiniteScrollStyled'

interface InfiniteScrollProps {
  dataLength: number
  hasMore: boolean
  isFetching?: boolean
  lightSpinner?: boolean
  next: () => any
  scrollableTarget?: string
}

const InfiniteScroll: React.FC<PropsWithChildren<InfiniteScrollProps>> = ({
  children,
  dataLength,
  hasMore,
  isFetching,
  lightSpinner,
  next,
  scrollableTarget,
}) => {
  const style = {
    overflow: 'inherit',
    width: '100%',
  }

  const [ref, inView] = useInView({
    threshold: 0,
  })

  useEffect(() => {
    if (
      !isFetching
      && hasMore
      && inView
      && (!scrollableTarget ? !(document.body.scrollHeight > window.innerHeight) : true)
    ) {
      next()
    }
  }, [isFetching, inView])

  const renderLoader = () => (
    <StyledLoader ref={ref}>
      <Spinner light={lightSpinner} />
    </StyledLoader>
  )

  return (
    <ReactInfiniteScroll
      dataLength={dataLength}
      hasMore={hasMore}
      loader={renderLoader()}
      next={next}
      scrollableTarget={scrollableTarget}
      style={style}
    >
      {children}
    </ReactInfiniteScroll>
  )
}

export default InfiniteScroll
