import { createSelector } from 'reselect'

const getChildFrameworkProgress = (state) => state.childFrameworkProgress

export const getChildFrameworkProgressListMontessoriState = createSelector(
  [getChildFrameworkProgress],
  (state) => state.listMontessori,
)

export const getChildFrameworkProgressListMontessoriDataState = createSelector(
  [getChildFrameworkProgressListMontessoriState],
  (state) => state.data,
)
