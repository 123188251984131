import ServiceBase from 'services/ServiceBase'

import { getQueryString } from 'utils/api'

class ChildDailyDiaryApiClient extends ServiceBase {
  list = (childId, params = {}) => this.request({
    path: `/children/${childId}/daily-diary?${getQueryString(params)}`,
  })
}

export default ChildDailyDiaryApiClient
