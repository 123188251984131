import styled from 'styled-components'

import { StyledRightContainer } from 'components/Field/InlineEdit/InlineEditStyled'

export const StyledTopBody = styled.div`
  display: grid;
  grid-column-gap: 40px;
  grid-template-columns: 300px minmax(0, 400px);
  margin: 0 -15px;
  
  @media(max-width: 600px) {
    grid-template-columns: auto;
    margin: 0;
    margin-top: -10px;
    grid-row-gap: 10px;
  }
`

export const StyledBottomBody = styled.div`
  margin: 10px -15px 0 !important;
  
  @media(max-width: 600px) {
    margin: 0 !important;
    margin-top: 20px !important;
  }
`

export const StyledFieldWrapper = styled.div`
  @media(max-width: 480px) {
    ${StyledRightContainer} {
      margin-left: 0;
      text-align: right;
    }
  }
`

export const StyledChildrenList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
`

export const StyledChildRow = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: auto auto;
  align-items: center;
  padding-right: 20px;
  margin-bottom: 15px;
`
