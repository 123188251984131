import _ from 'lodash'

import React from 'react'

import { NurseryConsumable } from 'services/product/nurseryConsumablesV3/models'

import { generateRoute } from 'utils/routing'

import { Button } from 'components'

import i18n from 'translations'

interface NurseryConsumablesTableDataProps {
  action: React.ReactElement
  id: number
  name: string
}

export const getNurseryConsumablesTableData = (data: NurseryConsumable[]) => (
  _.map(data, ({ archived, id, name }): NurseryConsumablesTableDataProps => ({
    action: (
      <Button.TableAction
        to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.CONSUMABLES.EDIT', { itemId: id })}
        edit
      />
    ),
    id,
    name: `${name}${archived ? ` (${i18n.t('global:archived')})` : ''}`,
  }))
)
