import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class ChildFundingApiClient extends ServiceBase {
  list = (params) => this.request({
    path: `/v2/child_fundings?${getQueryStringV2(params)}`,
  })

  exportReport = (params) => this.request({
    path: `/v2/child_fundings/report/funding?${getQueryStringV2(params)}`,
  })

  get = (id, params) => this.request({
    path: `/v2/child_fundings/${id}?${getQueryStringV2(params)}`,
  })

  create = (payload, params) => this.request({
    body: payload,
    method: 'POST',
    path: `/v2/child_fundings?${getQueryStringV2(params)}`,
  })

  update = (id, payload, params) => this.request({
    body: payload,
    method: 'PUT',
    path: `/v2/child_fundings/${id}?${getQueryStringV2(params)}`,
  })

  delete = (id, params) => this.request({
    method: 'DELETE',
    path: `/v2/child_fundings/${id}?${getQueryStringV2(params)}`,
  })

  statistics = (params) => this.request({
    path: `/v2/statistics/funding_endpoint?${getQueryStringV2(params)}`,
  })
}

export default ChildFundingApiClient
