import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'
import { getFormInitialValues } from 'redux-form'

import { FEATURE_FLAGS } from 'constants/security'
import { Nullable } from 'constants/models'

import { EVENTS, logEvent } from 'analytics'

import auth from 'utils/auth'

import { withAppService, withAppServiceProps } from 'services/app'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withInvoicesService, withInvoicesServiceProps } from 'services/legacy/invoices'

import { ADD_ITEM_MODAL_FORM, AddInvoiceItemModalV3FormValues } from './components/AddInvoiceItemModalV3Form'
import { getInitialValues } from './AddInvoiceItemModalV3Helpers'
import AddInvoiceItemModalV3View from './AddInvoiceItemModalV3View'

interface AddInvoiceItemModalV3AdditionalProps {
  childId?: string
  includeUsedDepositIds?: number[]
  itemIndex?: string
  onAddItemSuccess?: (fields: AddInvoiceItemModalV3FormValues) => void
  readOnly?: boolean
  skipPendingDepositIds?: number[]
}

const mapState = (state, { invoicesSelectors }) => ({
  formInitialValues: getFormInitialValues(ADD_ITEM_MODAL_FORM)(state),
  invoicePreview: invoicesSelectors.getInvoicePreviewData(state),
  isDepositEnabled: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.DEPOSITS],
  }),
  isFinanceAutomationEnabled: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.FINANCE_AUTOMATION],
  }),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type AddInvoiceItemModalV3ContainerProps = withAppServiceProps
  & withModalServiceProps
  & withInvoicesServiceProps
  & AddInvoiceItemModalV3AdditionalProps
  & PropsFromRedux

const AddInvoiceItemModalV3Container = ({
  childId,
  formInitialValues,
  includeUsedDepositIds,
  invoicePreview,
  invoicesActions,
  invoicesSelectors,
  isDepositEnabled,
  isFinanceAutomationEnabled,
  isFinanceV3Enabled,
  itemIndex,
  modalActions,
  onAddItemSuccess,
  readOnly,
  skipPendingDepositIds,
}: AddInvoiceItemModalV3ContainerProps): JSX.Element => {
  const [initialValues, setInitialValues] = useState<Nullable<AddInvoiceItemModalV3FormValues>>(null)

  const lineItemOptions = invoicesSelectors.getLineItemOptionsV3({
    isDepositEnabled,
    isFinanceAutomationEnabled,
    readOnly,
  })

  useEffect(() => {
    setInitialValues(getInitialValues(invoicePreview, itemIndex))
  }, [])

  const handleCancelClick = () => {
    modalActions.hide()
  }

  const handleSubmit = (fields: AddInvoiceItemModalV3FormValues) => {
    const { total, type } = formInitialValues as AddInvoiceItemModalV3FormValues || {}
    let updatedTotal = invoicesSelectors.getTotal(fields.total, fields.type)
    let isNotOriginalType = false

    if (total) {
      const initialValueTotal = invoicesSelectors.getTotal(total, type)
      const fieldTotal = invoicesSelectors.getTotal(fields.total, fields.type)

      updatedTotal = fieldTotal - initialValueTotal
      isNotOriginalType = type.value !== fields.type.value
    }

    invoicesActions.addItem(fields, updatedTotal, total, isNotOriginalType, isFinanceV3Enabled)

    onAddItemSuccess?.(fields)

    logEvent(EVENTS.INVOICE_MANUAL_ITEM_ADDED)

    modalActions.hide()
  }

  return (
    <AddInvoiceItemModalV3View
      childId={childId}
      includeUsedDepositIds={includeUsedDepositIds}
      initialValues={initialValues}
      isItemEdit={!!itemIndex}
      isReadOnly={readOnly}
      lineItemOptions={lineItemOptions}
      skipPendingDepositIds={skipPendingDepositIds}
      onCancelClick={handleCancelClick}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withAppService,
  withModalService,
  withInvoicesService,
  connector,
)

export default enhance(AddInvoiceItemModalV3Container)
