import _ from 'lodash'
import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'
import { FEATURE_FLAGS } from 'constants/security'

import auth from 'utils/auth'

import { getAuthUser, isSuperAdmin } from 'services/security/user/selectors'
import {
  getMembersWithAccessToLikesAndComments,
  getNurseryLikeAndCommentSettings,
} from 'services/nurseryContext/common/selectors'

export const hasAccessToLikesAndCommentsSettings = createSelector(
  [getNurseryLikeAndCommentSettings],
  (state): boolean => state?.enabled || false,
)

export const hasAccessToLikesAndComments = (state: RootState): boolean => {
  const featureFlagIsEnabled = auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.LIKES_AND_COMMENTS],
  })

  if (!featureFlagIsEnabled) {
    return false
  }

  if (isSuperAdmin(state)) {
    return true
  }

  const memberWithAccess = getMembersWithAccessToLikesAndComments(state)
  const authUser = getAuthUser(state)

  return !!_.find(memberWithAccess, ({ id }) => id === authUser?.membership.id)
}
