import {
  CLEAR_ETHNICITIES_LISTS,
  LIST_ETHNICITIES,
  LIST_ETHNICITIES_FAILED,
  LIST_ETHNICITIES_SUCCESS,
} from './constants'

export const list = () => ({ ethnicitiesApiClient }) => (dispatch) => {
  dispatch({
    type: LIST_ETHNICITIES,
  })

  ethnicitiesApiClient.list().then(
    (response) => {
      dispatch({
        payload: response,
        type: LIST_ETHNICITIES_SUCCESS,
      })
    },
    ({ response }) => dispatch({
      error: response,
      type: LIST_ETHNICITIES_FAILED,
    }),
  )
}

export const clear = () => ({
  type: CLEAR_ETHNICITIES_LISTS,
})
