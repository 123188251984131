import moment from 'moment'

import React from 'react'
import { compose } from 'recompose'

import { WORKLOG_RECORD_DAY_TYPE } from 'services/legacy/membershipRegisters/constants'
import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { generateRoute } from 'utils/routing'

import { withModalService } from 'services/utils/modal'
import { withRouter } from 'services/router'

import { getTimeRangeString as getTimeRangeStringFromHelper } from 'services/legacy/membershipRegisters/helpers'

import { Hyperlink } from 'components'

import i18n from 'translations'

const PreviousRecordBanner = ({
  firstName,
  membershipId,
  modalActions,
  navigate,
  previousEndDate,
  previousStartDate,
  selectedDate,
  worklogDayType,
}) => {
  const getTimeRangeString = () => {
    const currentStartDate = moment(selectedDate).startOf('day')
    const currentEndDate = worklogDayType === WORKLOG_RECORD_DAY_TYPE.IN_BETWEEN_DAY
      ? moment(selectedDate)
      : moment(previousEndDate)

    return getTimeRangeStringFromHelper(currentStartDate, currentEndDate)
  }

  const handleLinkClick = () => {
    modalActions.hideAll()

    navigate(generateRoute('STAFF.WORKLOG_PREVIEW', {
      endDate: moment(previousStartDate).format(DEFAULT_DATE_FORMAT),
      membershipId,
      startDate: moment(previousStartDate).format(DEFAULT_DATE_FORMAT),
    }))
  }

  return (
    <React.Fragment>
      {i18n.t('module:Staff:StaffWorklogPreview:previousRecordBannerText', {
        firstName,
        timeRangeString: getTimeRangeString(),
      })}
      &nbsp;
      <Hyperlink
        fontSize={15}
        bold
        inline
        primary
        onClick={handleLinkClick}
      >
        {moment(previousStartDate).format('dddd DD MMMM YYYY[.]')}
      </Hyperlink>
    </React.Fragment>
  )
}

const enhance = compose(
  withModalService,
  withRouter,
)

export default enhance(PreviousRecordBanner)
