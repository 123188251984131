import _ from 'lodash'

import { INJURIES_STATUS } from 'services/legacy/injuries/constants'

import { updateSignatureCount } from './helpers'

import {
  ADD_CHILDREN_TO_INJURY,
  CLEAR_SINGLE_INJURY,
  CREATE_INJURY,
  CREATE_INJURY_FAILED,
  CREATE_INJURY_SUCCESS,
  GET_INJURY,
  GET_INJURY_FAILED,
  GET_INJURY_SUCCESS,
  REMOVE_CHILDREN_FROM_INJURY,
  REMOVE_INJURY,
  REMOVE_INJURY_SUCCESS,
  UPDATE_INJURY,
  UPDATE_INJURY_FAILED,
  UPDATE_INJURY_SIGNATURE_COLLECTING_TYPE,
  UPDATE_INJURY_SIGNATURE_COUNT,
  UPDATE_INJURY_STATUS,
  UPDATE_INJURY_SUCCESS,
  UPDATE_SINGLE_INJURY_SIGNATURE_STATISTICS,
} from './constants'

const initialState = {
  data: {
    injured: [],
  },
  error: null,
  isFetching: true,
  isRemovedInProgress: false,
  isSubmittingAsApproved: false,
  isSubmittingAsDraft: false,
  isSubmittingUpdateStatus: false,
}

export default (state = initialState, {
  error,
  payload,
  silent,
  type,
}) => {
  switch (type) {
    case GET_INJURY:
      return { ...state, error: initialState.error, isFetching: !silent }
    case GET_INJURY_SUCCESS:
      return { ...state, data: payload.data, isFetching: false }
    case GET_INJURY_FAILED:
      return { ...state, error, isFetching: false }
    case ADD_CHILDREN_TO_INJURY:
      return {
        ...state,
        data: {
          ...state.data,
          injured: payload,
        },
      }
    case REMOVE_CHILDREN_FROM_INJURY:
      return {
        ...state,
        data: {
          ...state.data,
          injured: _.filter(state.data.injured, (injury) => injury.child.id !== payload),
          isChildRemoved: true,
        },
      }
    case UPDATE_INJURY:
    case CREATE_INJURY:
      return {
        ...state,
        isSubmittingAsApproved: (
          payload.status === INJURIES_STATUS.APPROVED_AND_SIGNED.value
          || payload.status === INJURIES_STATUS.PENDING_APPROVAL.value
        ),
        isSubmittingAsDraft: payload.status === INJURIES_STATUS.DRAFT.value,
        isSubmittingUpdateStatus: true,
      }
    case CREATE_INJURY_SUCCESS:
    case UPDATE_INJURY_SUCCESS:
      return {
        ...state,
        data: payload.data,
        isSubmittingAsApproved: false,
        isSubmittingAsDraft: false,
        isSubmittingUpdateStatus: false,
      }
    case UPDATE_INJURY_FAILED:
    case CREATE_INJURY_FAILED:
      return {
        ...state,
        error: payload,
        isSubmittingAsApproved: false,
        isSubmittingAsDraft: false,
        isSubmittingUpdateStatus: false,
      }
    case REMOVE_INJURY:
      return { ...state, isRemovedInProgress: true }
    case UPDATE_INJURY_SIGNATURE_COUNT:
      return updateSignatureCount(state, payload.signatureData, payload.newSignatureStatistics)
    case UPDATE_INJURY_STATUS:
      return {
        ...state,
        data: {
          ...state.data,
          status: payload.status,
        },
      }
    case UPDATE_INJURY_SIGNATURE_COLLECTING_TYPE:
      return {
        ...state,
        data: {
          ...state.data,
          signatureCollectingType: payload.signatureCollectingType,
        },
      }
    case UPDATE_SINGLE_INJURY_SIGNATURE_STATISTICS:
      return {
        ...state,
        data: {
          ...state.data,
          injurySignatureStatistics: payload.newSignatureStatistics,
        },
      }
    case CLEAR_SINGLE_INJURY:
    case REMOVE_INJURY_SUCCESS:
      return { ...initialState }
    default:
      return state
  }
}
