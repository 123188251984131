import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as observationFeedbackSelectors from './selectors'

const withObservationFeedbackService = (WrappedComponent) => {
  const mapState = ({ observationFeedback }) => ({ observationFeedback })

  const mapDispatch = injectActions('observationFeedbackActions', actions)

  const Component = ({ observationFeedback, observationFeedbackActions, ...others }) => (
    <WrappedComponent
      observationFeedbackActions={observationFeedbackActions}
      observationFeedbackListState={observationFeedback.list}
      observationFeedbackSelectors={observationFeedbackSelectors}
      observationFeedbackSingleState={observationFeedback.single}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withObservationFeedbackService
