import { createSelector } from 'reselect'

const getExceptionalUnavailableTimesListSelector = (state) => (
  state.requestedExtraSessions.exceptionalUnavailableTimesList
)

export const getExceptionalUnavailableTimesListData = createSelector(
  [getExceptionalUnavailableTimesListSelector],
  (state) => state.data,
)

export const getExceptionalUnavailableTimesListCriteria = () => [
  {
    field: 'or[0][nurseryClass.archived][eq]',
    value: 0,
  },
  {
    field: 'or[0][nurseryClass][eq]',
    value: null,
  },
]
