import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import {
  NURSERY_EXTRA_ITEMS_FILTER,
  NURSERY_EXTRA_ITEMS_FILTER_OPTIONS,
} from 'services/legacy/nurseryExtraItems/constants'

import { withAppService } from 'services/app'
import { withShellService } from 'services/shell'
import { withNurseryExtraItemsService } from 'services/legacy/nurseryExtraItems'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withSortingUtils } from 'services/utils/sorting'

import i18n from 'translations'

import ManagementExtraItemsView from './ManagementExtraItemsView'
import { getTableData } from './helpers'

class ManagementExtraItemsContainer extends Component {
  constructor(props) {
    super(props)

    const { statusFilter } = props.location.query

    this.state = {
      filters: {
        statusFilter: statusFilter || NURSERY_EXTRA_ITEMS_FILTER.ACTIVE,
      },
    }
  }

  componentDidMount() {
    const { shellActions } = this.props

    shellActions.setRouteTitle({
      name: 'MANAGEMENT.FINANCE_SETTINGS.ITEMS',
      title: i18n.t('module:Management:Finance:Item:legacyTitle'),
    })

    this.fetch()
  }

  componentWillUnmount() {
    const { nurseryExtraItemsActions } = this.props

    nurseryExtraItemsActions.clearList()
  }

  fetch = () => {
    const {
      nurseryExtraItemsActions,
      nurseryExtraItemsSelectors,
      paginationUtils,
      setLocationQuery,
      sortingUtils,
    } = this.props
    const { filters } = this.state
    const { page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const criteria = nurseryExtraItemsSelectors.getCriteriaSelector(filters)
    const apiParams = {
      criteria,
      order: { sortField, sortOrder },
      page,
    }

    setLocationQuery(filters)
    nurseryExtraItemsActions.list({ params: apiParams })
  }

  handleStatusFilterChange = (statusFilter) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        statusFilter: statusFilter ? statusFilter.value : null,
      },
    }), () => this.fetch())
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleSortChange = (key) => {
    const { paginationUtils, sortingUtils } = this.props
    const { onSortChange } = sortingUtils
    const { onPageChange } = paginationUtils

    onSortChange(onPageChange(this.fetch)(1))(key)
  }

  render() {
    const {
      isFetching,
      nurseryExtraItemsListState,
      nurseryOptions,
      paginationUtils,
      sortingUtils,
    } = this.props
    const { filters } = this.state
    const { statusFilter } = filters
    const { getPageCount, page, perPage } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const extraItems = nurseryExtraItemsListState.data
    const isEmpty = (!extraItems || 0 === extraItems.length)
    const tableData = getTableData(extraItems)
    const pageCount = getPageCount(nurseryExtraItemsListState.meta.total_results)

    return (
      <ManagementExtraItemsView
        extraItems={tableData}
        isEmpty={isEmpty}
        isLoading={isFetching}
        nurseryOptions={nurseryOptions}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        sortField={sortField}
        sortOrder={sortOrder}
        statusFilter={statusFilter}
        statusFilterOptions={NURSERY_EXTRA_ITEMS_FILTER_OPTIONS}
        totalResults={nurseryExtraItemsListState.meta.total_results}
        onPageChange={this.handlePageChange}
        onSortChange={this.handleSortChange}
        onStatusFilterChange={this.handleStatusFilterChange}
      />
    )
  }
}

const mapState = (state, { appSelectors, nurseryExtraItemsListState, params }) => ({
  errorMessages: appSelectors.getErrorMessages(nurseryExtraItemsListState),
  isFetching: appSelectors.getIsFetching(nurseryExtraItemsListState),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const enhance = compose(
  withAppService,
  withNurseryExtraItemsService,
  withPaginationUtils,
  withRouterUtils,
  withShellService,
  withSortingUtils,
  connect(mapState),
)

export default enhance(ManagementExtraItemsContainer)
