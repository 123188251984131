export const getPreviewOptions = (fundingName) => (getFormattedCurrencyValue) => [
  {
    fundingDeductedFromInvoice: true,
    items: [
      {
        description: 'AM Session',
        type: 'Fees',
        value: getFormattedCurrencyValue(30.00),
      },
      {
        description: fundingName,
        type: 'Funding',
        value: '2 hours',
      },
      {
        description: 'Total',
        isTotal: true,
        value: getFormattedCurrencyValue(20.00),
      },
    ],
    title: 'Funding deducted from invoice total',
  },
  {
    items: [
      {
        description: 'AM Session',
        type: 'Fees',
        value: getFormattedCurrencyValue(20.00),
      },
      {
        description: fundingName,
        type: 'Funding',
        value: '2 hours',
      },
      {
        description: 'Total',
        isTotal: true,
        value: getFormattedCurrencyValue(20.00),
      },
    ],
    title: 'Funding deducted from session line items',
  },
]
