import _ from 'lodash'

import React, { PropsWithChildren } from 'react'

import TabFilterItem from './TabFilterItem'
import { StyledContainer } from './TabFilterStyled'

const numberOfChildren = (children) => _.filter(children, (child) => false !== child).length

interface TabFilterRoot extends React.FC<PropsWithChildren<any>> {
  Item?: typeof TabFilterItem
}

const TabFilter: TabFilterRoot = ({ children }) => (
  <StyledContainer $numberOfItems={numberOfChildren(children)}>
    {children}
  </StyledContainer>
)

export default TabFilter
