import _ from 'lodash'

import React from 'react'
import { CONTRACT_TYPE_OPTIONS_SHORT } from 'services/legacy/contracts/constants'

import { millisecondsToHoursAndMinutesString } from 'utils/date'
import { generateRoute } from 'utils/routing'

import { Button, Currency, Typography } from 'components'

import ContractPeriod from 'module/Staff/StaffContracts/components/ContractPeriod'

import { StyledJobTitle } from './StaffContractsListStyled'

export const getTableData = (data, isMyDetailsContext, userId) => {
  if (!data?.length) {
    return []
  }

  return _.map(data, (contract) => {
    const {
      contractedHours,
      endDate,
      hourlySalary,
      id,
      jobTitle,
      ongoing,
      startDate,
      status,
      type,
    } = contract || {}

    return {
      action: (
        <Button.TableAction
          to={isMyDetailsContext
            ? generateRoute('ACCOUNT.CONTRACTS.PREVIEW', { contractId: id })
            : generateRoute('STAFF.PROFILE.CONTRACTS.PREVIEW', { contractId: id, userId })}
        />
      ),
      contractPeriod: startDate && (
        <ContractPeriod
          endDate={endDate}
          ongoing={ongoing}
          startDate={startDate}
          status={status}
        />
      ),
      contractedHours: contractedHours ? millisecondsToHoursAndMinutesString(contractedHours) : '-',
      hourlySalary: hourlySalary ? (
        <Currency value={hourlySalary} />
      ) : '-',
      jobTitle: (
        <StyledJobTitle>
          <Typography ellipsis>
            {jobTitle}
          </Typography>
        </StyledJobTitle>
      ),
      key: id,
      type: _.find(CONTRACT_TYPE_OPTIONS_SHORT, { value: type })?.label,
    }
  })
}
