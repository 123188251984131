import _ from 'lodash'
import React from 'react'

import { StyledTextContainer } from './InvoiceDeliveryRefNoStyled'
import { extractDeliveryRefNo } from './InvoiceDeliveryRefNoHelpers'

const InvoiceDeliveryRefNo = ({
  invoicePreview,
}) => {
  const { invoice = {} } = invoicePreview || {}
  const { sendLogs } = invoice

  const latestDelRefNO = _.last(_.filter(sendLogs, ({ messageId }) => !!messageId))

  if (!latestDelRefNO) {
    return null
  }

  return (
    <StyledTextContainer>
      {`Delivery ref: ${extractDeliveryRefNo(latestDelRefNO.messageId)}`}
    </StyledTextContainer>
  )
}

export default InvoiceDeliveryRefNo
