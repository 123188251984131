import React from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { withShellService, withShellServiceProps } from 'services/shell'

const mapState = (state, { shellSelectors }) => ({
  currency: shellSelectors.getSubdomainCurrency(state),
  currencySymbol: shellSelectors.getSubdomainCurrencySymbol(state),
  subdomainLocale: shellSelectors.getSubdomainLocale(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

interface SubdomainCurrencyProviderResponseProps {
  currencySymbol: PropsFromRedux['currencySymbol']
  getFormattedCurrencyValue: (value: number) => string
}

interface SubdomainCurrencyProviderProps {
  children: (response: SubdomainCurrencyProviderResponseProps & any) => React.ReactElement
}

type SubdomainCurrencyProviderFullProps = PropsFromRedux
  & SubdomainCurrencyProviderProps
  & withShellServiceProps

const SubdomainCurrencyProvider: React.FC<SubdomainCurrencyProviderFullProps> = ({
  children,
  currency,
  currencySymbol,
  subdomainLocale,
}) => {
  const finalSubdomainLocale = subdomainLocale?.replace('_', '-')

  const getFormattedCurrencyValue = (value) => {
    if (!currency || !finalSubdomainLocale) {
      return value
    }

    const options = {
      currency,
      currencyDisplay: 'symbol',
      locale: finalSubdomainLocale,
      style: 'currency',
    }

    return Number(value).toLocaleString(finalSubdomainLocale, options)
  }

  return children({
    currency,
    currencySymbol,
    getFormattedCurrencyValue,
  })
}

const enhance = compose(
  withShellService,
  connect(mapState),
)

export default enhance(SubdomainCurrencyProvider)
