import { Property } from 'csstype'

import React, { PropsWithChildren } from 'react'

import { StyledActions, StyledBoxContainer, StyledBoxLinkContainer, StyledContent, StyledHeader } from './BoxStyled'

interface BoxProps {
  actions?: any
  headerAlign?: Property.JustifyContent
  headerBackground?: Property.Background
  headerFontSize?: number
  marginBottom?: number
  title?: string | React.ReactNode
  to?: string
  white?: boolean
  withPadding?: boolean
  withShadow?: boolean
}

const Box: React.FC<PropsWithChildren<BoxProps>> = ({
  actions,
  children,
  headerAlign,
  headerBackground,
  headerFontSize,
  marginBottom,
  title,
  to,
  white,
  withPadding,
  withShadow,
}) => {
  const renderContent = () => (
    <React.Fragment>
      {title && (
        <StyledHeader
          $headerAlign={headerAlign}
          $headerBackground={headerBackground}
          $headerFontSize={headerFontSize}
        >
          {title}
          {actions && (
            <StyledActions>
              {actions}
            </StyledActions>
          )}
        </StyledHeader>
      )}
      <StyledContent
        $hasHeader={!!title}
        $white={white}
        $withPadding={withPadding}
      >
        {children}
      </StyledContent>
    </React.Fragment>
  )

  if (to) {
    return (
      <StyledBoxLinkContainer
        $marginBottom={marginBottom}
        $withShadow={withShadow}
        to={to}
      >
        {renderContent()}
      </StyledBoxLinkContainer>
    )
  }

  return (
    <StyledBoxContainer
      $marginBottom={marginBottom}
      $withShadow={withShadow}
    >
      {renderContent()}
    </StyledBoxContainer>
  )
}

export default Box
