/* eslint-disable max-len */
/* eslint-disable import-newlines/enforce */

import AbsenceReasonsApiClient from 'services/legacy/absenceReasons/AbsenceReasonsApiClient'
import AgedDebtApiClient from 'services/legacy/agedDebt/AgedDebtApiClient'
import AuthenticationApiClient from 'services/authentication/AuthenticationApiClient'
import AuthenticationLogsApiClient from 'services/legacy/authenticationLogs/AuthenticationLogsApiClient'
import AuthApiClient from 'services/legacy/auth/AuthApiClient'
import AssessmentTrackerApiClient from 'services/legacy/assessmentTracker/AssessmentTrackerApiClient'
import BalanceAdjustmentsApiClient from 'services/legacy/balanceAdjustments/BalanceAdjustmentsApiClient'
import CarerChildRelationsApiClient from 'services/legacy/carerChildRelations/CarerChildRelationsApiClient'
import CarersApiClient from 'services/legacy/carers/CarersApiClient'
import ChildAdhocSessionsApiClient from 'services/legacy/childAdhocSessions/ChildAdhocSessionsApiClient'
import CommentsApiClient from 'services/comments/CommentsApiClient'
import LegacyChildBankDetailsApiClient from 'services/legacy/child/LegacyChildBankDetailsApiClient'
import ChildAbsencesApiClient from 'services/legacy/childAbsences/ChildAbsencesApiClient'
import ChildBulkFundingApiClient from 'services/legacy/childBulkFunding/ChildBulkFundingApiClient'
import ChildBacsDetailsApiClient from 'services/childBacsDetails/ChildBacsDetailsApiClient'
import ChildBookingApiClient from 'services/booking/childBooking/ChildBookingApiClient'
import ChildDailyDiaryApiClient from 'services/legacy/childDailyDiary/ChildDailyDiaryApiClient'
import ChildDiscountsApiClient from 'services/legacy/childDiscounts/ChildDiscountsApiClient'
import ChildExtraItemsApiClient from 'services/legacy/childExtraItems/ChildExtraItemsApiClient'
import ChildFrameworkProgressApiClient from 'services/legacy/childFrameworkProgress/ChildFrameworkProgressApiClient'
import ChildFundingApiClient from 'services/legacy/childFunding/ChildFundingApiClient'
import ChildFundingsApiClient from 'services/booking/childFundings/ChildFundingsApiClient'
import ChildFundingSessionSetApiClient from 'services/legacy/childFundingSessionSet/ChildFundingSessionSetApiClient'
import ChildMovesApiClient from 'services/legacy/childMoves/ChildMovesApiClient'
import ChildNotesApiClient from 'services/childNotes/ChildNotesApiClient'
import ChildProductsApiClient from 'services/booking/childProducts/ChildProductsApiClient'
import ChildSessionsApiClient from 'services/legacy/childSessions/ChildSessionsApiClient'
import ChildrenStartingReportApiClient from 'services/childrenStartingReport/ChildrenStartingReportApiClient'
import ChildrenSignedUpReportApiClient from 'services/childrenSignedUpReport/ChildrenSignedUpReportApiClient'
import ChildrenLeavingReportApiClient from 'services/legacy/childrenLeavingReport/ChildrenLeavingReportApiClient'
import ChildObservationsApiClient from 'services/legacy/childObservations/ChildObservationsApiClient'
import ChildRegistersApiClient from 'services/legacy/childRegisters/ChildRegistersApiClient'
import ChildrenSessionsApiClient from 'services/legacy/childrenSessions/ChildrenSessionsApiClient'
import ChildApiClient from 'services/legacy/child/ChildApiClient'
import ClosureDayTypesApiClient from 'services/closureDayTypes/ClosureDayTypesApiClient'
import CohortTrackingApiClient from 'services/legacy/cohortTracking/CohortTrackingApiClient'
import ContractsApiClient from 'services/legacy/contracts/ContractsApiClient'
import CreditNotesApiClient from 'services/legacy/creditNotes/CreditNotesApiClient'
import DailyDiaryActivitiesApiClient from 'services/legacy/dailyDiaryActivities/DailyDiaryActivitiesApiClient'
import DailyDiaryActivitiesApiClientV2 from 'services/legacy/dailyDiaryActivities/DailyDiaryActivitiesApiClientV2'
import DailyDiaryApiClient from 'services/legacy/dailyDiary/DailyDiaryApiClient'
import DailyDiaryBottlesApiClient from 'services/legacy/dailyDiaryBottles/DailyDiaryBottlesApiClient'
import DailyDiaryFoodsApiClient from 'services/legacy/dailyDiaryFoods/DailyDiaryFoodsApiClient'
import DailyDiaryNappiesApiClient from 'services/legacy/dailyDiaryNappies/DailyDiaryNappiesApiClient'
import DailyDiarySleepsApiClient from 'services/legacy/dailyDiarySleeps/DailyDiarySleepsApiClient'
import DailyDiaryToiletTrainingApiClient
  from 'services/legacy/dailyDiaryToiletTraining/DailyDiaryToiletTrainingApiClient'
import DeviceApiClient from 'services/device/DeviceApiClient'
import DeviceTokensApiClient from 'services/legacy/deviceTokens/DeviceTokensApiClient'
import DepositsApiClient from 'services/legacy/deposits/DepositsApiClient'
import EnquiriesApiClient from 'services/legacy/enquiries/EnquiriesApiClient'
import EnquiryActivitiesApiClient from 'services/legacy/enquiries/activities/EnquiryActivitiesApiClient'
import EnquiryReportsApiClient from 'services/legacy/enquiries/reports/EnquiryReportsApiClient'
import EnquiryViewingsApiClient from 'services/legacy/enquiries/viewings/EnquiryViewingsApiClient'
import EnquiryStatusTypesApiClient from 'services/legacy/enquiries/statusTypes/EnquiryStatusTypesApiClient'
import EnquirySettingsApiClient from 'services/legacy/enquiries/settings/EnquirySettingsApiClient'
import EthnicitiesApiClient from 'services/legacy/ethnicities/EthnicitiesApiClient'
import FilesApiClient from 'services/legacy/files/FilesApiClient'
import FinanceBadDebtReportApiClient from 'services/legacy/financeBadDebtReport/FinanceBadDebtReportApiClient'
import FinanceBalanceReportApiClient from 'services/legacy/financeBalanceReport/FinanceBalanceReportApiClient'
import FinanceCreditNotesReportApiClient
  from 'services/legacy/financeCreditNotesReport/FinanceCreditNotesReportApiClient'
import FinanceDepositsReportApiClient from 'services/legacy/financeDepositsReport/FinanceDepositsReportApiClient'
import FinanceFundingReportApiClient from 'services/legacy/financeFundingReport/FinanceFundingReportApiClient'
import FinanceRevenuePerRoomReportApiClient
  from 'services/legacy/financeRevenuePerRoomReport/FinanceRevenuePerRoomReportApiClient'
import FinanceRevenuePerAgeReportApiClient
  from 'services/legacy/financeRevenuePerAgeReport/FinanceRevenuePerAgeReportApiClient'
import FoodMenuApiClient from 'services/legacy/foodMenu/FoodMenuApiClient'
import FoodTypesApiClient from 'services/legacy/foodTypes/FoodTypesApiClient'
import FormativeReportsApiClient from 'services/legacy/formativeReports/FormativeReportsApiClient'
import FrameworksApiClient from 'services/legacy/frameworks/FrameworksApiClient'
import FrameworkProgressLevelsApiClient from 'services/legacy/frameworkProgressLevels/FrameworkProgressLevelsApiClient'
import FundingTypesApiClient from 'services/legacy/fundingTypes/FundingTypesApiClient'
import HomeObservationsApiClient from 'services/legacy/homeObservations/homeObservationsApiClient'
import InjuriesApiClient from 'services/legacy/injuries/injuriesApiClient'
import IntegrationFeesApiClient from 'services/integrationFees/IntegrationFeesApiClient'
import InvoicesApiClient from 'services/legacy/invoices/InvoicesApiClient'
import InvoicePaymentsApiClient from 'services/legacy/invoicePayments/InvoicePaymentsApiClient'
import LeaveNotesApiClient from 'services/legacy/leaveNotes/LeaveNotesApiClient'
import LeaveTypesApiClient from 'services/legacy/leaveTypes/LeaveTypesApiClient'
import LikesApiClient from 'services/likes/LikesApiClient'
import MembershipRegistersApiClient from 'services/legacy/membershipRegisters/MembershipRegistersApiClient'
import MembershipsApiClient from 'services/legacy/memberships/MembershipsApiClient'
import MilkReportApiClient from 'services/milkReport/MilkReportApiClient'
import NurseriesApiClient from 'services/nurseries/NurseriesApiClient'
import MembershipsLeavesApiClient from 'services/legacy/membershipsLeaves/MembershipsLeavesApiClient'
import MembershipsShiftsApiClient from 'services/legacy/membershipsShifts/MembershipsShiftsApiClient'
import MessagingApiClient from 'services/legacy/messaging/MessagingApiClient'
import MontessoriCategoriesApiClient from 'services/legacy/montessoriCategories/MontessoriCategoriesApiClient'
import MonthlyFteChangeReportApiClient from 'services/legacy/monthlyFteChangeReport/MonthlyFteChangeReportApiClient'
import NewsletterRecipientsApiClient from 'services/legacy/newsletterRecipients/NewsletterRecipientsApiClient'
import NewslettersApiClient from 'services/legacy/newsletters/NewslettersApiClient'
import NextStepsApiClient from 'services/legacy/nextSteps/NextStepsApiClient'
import NextMontessoriActivitiesApiClient
  from 'services/legacy/nextMontessoriActivities/NextMontessoriActivitiesApiClient'
import NurseryConsumablesApiClient from 'services/nurseryConsumables/NurseryConsumablesApiClient'
import NurseryConsumablesV3ApiClient from 'services/product/nurseryConsumablesV3//NurseryConsumablesV3ApiClient'
import NurseryDiscountsApiClient from 'services/legacy/nurseryDiscounts/NurseryDiscountsApiClient'
import NurseryDiscountsV3ApiClient from 'services/product/nurseryDiscountsV3/NurseryDiscountsV3ApiClient'
import NurseryAcademicYearsApiClient from 'services/nurseryAcademicYears/NurseryAcademicYearsApiClient'
import NurseryExtraItemsApiClient from 'services/legacy/nurseryExtraItems/NurseryExtraItemsApiClient'
import NurseryIntegrationsApiClient from 'services/nurseryIntegrations/NurseryIntegrationsApiClient'
import NurseryItemApiClient from 'services/product/nurseryItem/NurseryItemApiClient'
import NurseryFundingApiClient from 'services/nurseryFunding/NurseryFundingApiClient'
import NurseryFrameworkRelationsApiClient
  from 'services/legacy/nurseryFrameworkRelations/NurseryFrameworkRelationsApiClient'
import NurseryFundingV3ApiClient from 'services/product/nurseryFundingV3/NurseryFundingV3ApiClient'
import NurseryPaymentTypesApiClient from 'services/legacy/nurseryPaymentTypes/NurseryPaymentTypesApiClient'
import NurserySessionsApiClient from 'services/nurserySessions/NurserySessionsApiClient'
import NurserySessionsV3ApiClient from 'services/product/nurserySessionsV3/NurserySessionsV3ApiClient'

import ObservationsApiClient from 'services/legacy/observations/ObservationsApiClient'
import ObservationFeedbackApiClient from 'services/legacy/observationFeedback/ObservationFeedbackApiClient'
import OccupancyApiClient from 'services/legacy/occupancy/OccupancyApiClient'
import OrganizationSessionsApiClient from 'services/legacy/organizationSessions/OrganizationSessionsApiClient'
import OrganizationTermsApiClient from 'services/legacy/organizationTerms/OrganizationTermsApiClient'
import OrganizationApiClient from 'services/organization/OrganizationApiClient'
import PeriodsApiClient from 'services/booking/periods/PeriodsApiClient'
import ProductPriceGroupsApiClient from 'services/product/productPriceGroups/ProductPriceGroupsApiClient'
import PaymentReminderTagsApiClient from 'services/legacy/paymentReminderTags/PaymentReminderTagsApiClient'
import PeriodTimesApiClient from 'services/periodTimes/PeriodTimesApiClient'
import RelationsApiClient from 'services/legacy/relations/RelationsApiClient'
import RegisterApiClient from 'services/legacy/register/RegisterApiClient'
import RoomsApiClient from 'services/legacy/rooms/RoomsApiClient'
import RegisterStatisticsApiClient from 'services/legacy/registerStatistics/RegisterStatisticsApiClient'
import RequestedExtraSessionsApiClient from 'services/legacy/requestedExtraSessions/RequestedExtraSessionsApiClient'
import RoomMoveApiClient from 'services/legacy/roomMove/RoomMoveApiClient'
import StaffLogsApiClient from 'services/legacy/staffLogs/StaffLogsApiClient'
import SubdomainApiClient from 'services/subdomain/SubdomainApiClient'
import SupportedLocaleApiClient from 'services/legacy/supportedLocale/SupportedLocaleApiClient'
import SupportedCurrencyApiClient from 'services/legacy/supportedCurrency/SupportedCurrencyApiClient'
import TransactionsApiClient from 'services/legacy/transactions/TransactionsApiClient'
import UploadApiClient from 'services/legacy/upload/UploadApiClient'
import UsersApiClient from 'services/users/UsersApiClient'

import AccessForbiddenMiddleware from 'core/httpMiddleware/AccessForbiddenMiddleware'
import AuthorizationMiddleware from 'core/httpMiddleware/AuthorizationMiddleware'
import NormalizeCollectionErrorsMiddleware from 'core/httpMiddleware/NormalizeCollectionErrorsMiddleware'
import NormalizeDatesMiddleware from 'core/httpMiddleware/NormalizeDatesMiddleware'
import NormalizeEmptyStringsMiddleware from 'core/httpMiddleware/NormalizeEmptyStringsMiddleware'
import RemoveNullResponseValuesMiddleware from 'core/httpMiddleware/RemoveNullResponseValuesMiddleware'
import RemoveRedundantQuestionMarkMiddleware from 'core/httpMiddleware/RemoveRedundantQuestionMarkMiddleware'
import UnauthorizedAccessMiddleware from 'core/httpMiddleware/UnauthorizedAccessMiddleware'
import VersionControlMiddleware from 'core/httpMiddleware/VersionControlMiddleware'
/* eslint-enable max-len */
import { properties } from 'app-config'

export const createServices = (dispatch) => {
  const httpMiddleware = [
    AuthorizationMiddleware(properties.codeVersion),
    AccessForbiddenMiddleware(dispatch),
    NormalizeCollectionErrorsMiddleware,
    NormalizeDatesMiddleware,
    NormalizeEmptyStringsMiddleware,
    RemoveNullResponseValuesMiddleware,
    RemoveRedundantQuestionMarkMiddleware,
    UnauthorizedAccessMiddleware(dispatch),
    VersionControlMiddleware(properties.codeVersion, dispatch),
  ]

  const services = {
    absenceReasonsApiClient: new AbsenceReasonsApiClient(httpMiddleware),
    agedDebtApiClient: new AgedDebtApiClient(httpMiddleware),
    assessmentTrackerApiClient: new AssessmentTrackerApiClient(httpMiddleware),
    authApiClient: new AuthApiClient(httpMiddleware),
    authenticationApiClient: new AuthenticationApiClient(httpMiddleware),
    authenticationLogsApiClient: new AuthenticationLogsApiClient(httpMiddleware),
    balanceAdjustmentsApiClient: new BalanceAdjustmentsApiClient(httpMiddleware),
    carerChildRelationsApiClient: new CarerChildRelationsApiClient(httpMiddleware),
    carersApiClient: new CarersApiClient(httpMiddleware),
    childAbsencesApiClient: new ChildAbsencesApiClient(httpMiddleware),
    childAdhocSessionsApiClient: new ChildAdhocSessionsApiClient(httpMiddleware),
    childApiClient: new ChildApiClient(httpMiddleware),
    childBacsDetailsApiClient: new ChildBacsDetailsApiClient(httpMiddleware),
    childBookingApiClient: new ChildBookingApiClient(httpMiddleware),
    childBulkFundingApiClient: new ChildBulkFundingApiClient(httpMiddleware),
    childDailyDiaryApiClient: new ChildDailyDiaryApiClient(httpMiddleware),
    childDiscountsApiClient: new ChildDiscountsApiClient(httpMiddleware),
    childExtraItemsApiClient: new ChildExtraItemsApiClient(httpMiddleware),
    childFrameworkProgressApiClient: new ChildFrameworkProgressApiClient(httpMiddleware),
    childFundingApiClient: new ChildFundingApiClient(httpMiddleware),
    childFundingSessionSetApiClient: new ChildFundingSessionSetApiClient(httpMiddleware),
    childFundingsApiClient: new ChildFundingsApiClient(httpMiddleware),
    childMovesApiClient: new ChildMovesApiClient(httpMiddleware),
    childNotesApiClient: new ChildNotesApiClient(httpMiddleware),
    childObservationsApiClient: new ChildObservationsApiClient(httpMiddleware),
    childProductsApiClient: new ChildProductsApiClient(httpMiddleware),
    childRegistersApiClient: new ChildRegistersApiClient(httpMiddleware),
    childSessionsApiClient: new ChildSessionsApiClient(httpMiddleware),
    childrenLeavingReportApiClient: new ChildrenLeavingReportApiClient(httpMiddleware),
    childrenSessionsApiClient: new ChildrenSessionsApiClient(httpMiddleware),
    childrenSignedUpReportApiClient: new ChildrenSignedUpReportApiClient(httpMiddleware),
    childrenStartingReportApiClient: new ChildrenStartingReportApiClient(httpMiddleware),
    closureDayTypesApiClient: new ClosureDayTypesApiClient(httpMiddleware),
    cohortTrackingApiClient: new CohortTrackingApiClient(httpMiddleware),
    commentsApiClient: new CommentsApiClient(httpMiddleware),
    contractsApiClient: new ContractsApiClient(httpMiddleware),
    creditNotesApiClient: new CreditNotesApiClient(httpMiddleware),
    dailyDiaryActivitiesApiClient: new DailyDiaryActivitiesApiClient(httpMiddleware),
    dailyDiaryActivitiesApiClientV2: new DailyDiaryActivitiesApiClientV2(httpMiddleware),
    dailyDiaryApiClient: new DailyDiaryApiClient(httpMiddleware),
    dailyDiaryBottlesApiClient: new DailyDiaryBottlesApiClient(httpMiddleware),
    dailyDiaryFoodsApiClient: new DailyDiaryFoodsApiClient(httpMiddleware),
    dailyDiaryNappiesApiClient: new DailyDiaryNappiesApiClient(httpMiddleware),
    dailyDiarySleepsApiClient: new DailyDiarySleepsApiClient(httpMiddleware),
    dailyDiaryToiletTrainingApiClient: new DailyDiaryToiletTrainingApiClient(httpMiddleware),
    depositsApiClient: new DepositsApiClient(httpMiddleware),
    deviceApiClient: new DeviceApiClient(httpMiddleware),
    deviceTokensApiClient: new DeviceTokensApiClient(httpMiddleware),
    enquiriesApiClient: new EnquiriesApiClient(httpMiddleware),
    enquiryActivitiesApiClient: new EnquiryActivitiesApiClient(httpMiddleware),
    enquiryReportsApiClient: new EnquiryReportsApiClient(httpMiddleware),
    enquirySettingsApiClient: new EnquirySettingsApiClient(httpMiddleware),
    enquiryStatusTypesApiClient: new EnquiryStatusTypesApiClient(httpMiddleware),
    enquiryViewingsApiClient: new EnquiryViewingsApiClient(httpMiddleware),
    ethnicitiesApiClient: new EthnicitiesApiClient(httpMiddleware),
    filesApiClient: new FilesApiClient(httpMiddleware),
    financeBadDebtReportApiClient: new FinanceBadDebtReportApiClient(httpMiddleware),
    financeBalanceReportApiClient: new FinanceBalanceReportApiClient(httpMiddleware),
    financeCreditNotesReportApiClient: new FinanceCreditNotesReportApiClient(httpMiddleware),
    financeDepositsReportApiClient: new FinanceDepositsReportApiClient(httpMiddleware),
    financeFundingReportApiClient: new FinanceFundingReportApiClient(httpMiddleware),
    financeRevenuePerAgeReportApiClient: new FinanceRevenuePerAgeReportApiClient(httpMiddleware),
    financeRevenuePerRoomReportApiClient: new FinanceRevenuePerRoomReportApiClient(httpMiddleware),
    foodMenuApiClient: new FoodMenuApiClient(httpMiddleware),
    foodTypesApiClient: new FoodTypesApiClient(httpMiddleware),
    formativeReportsApiClient: new FormativeReportsApiClient(httpMiddleware),
    frameworkProgressLevelsApiClient: new FrameworkProgressLevelsApiClient(httpMiddleware),
    frameworksApiClient: new FrameworksApiClient(httpMiddleware),
    fundingTypesApiClient: new FundingTypesApiClient(httpMiddleware),
    homeObservationsApiClient: new HomeObservationsApiClient(httpMiddleware),
    injuriesApiClient: new InjuriesApiClient(httpMiddleware),
    integrationFeesApiClient: new IntegrationFeesApiClient(httpMiddleware),
    invoicePaymentsApiClient: new InvoicePaymentsApiClient(httpMiddleware),
    invoicesApiClient: new InvoicesApiClient(httpMiddleware),
    leaveNotesApiClient: new LeaveNotesApiClient(httpMiddleware),
    leaveTypesApiClient: new LeaveTypesApiClient(httpMiddleware),
    legacyChildBankDetailsApiClient: new LegacyChildBankDetailsApiClient(httpMiddleware),
    likesApiClient: new LikesApiClient(httpMiddleware),
    membershipRegistersApiClient: new MembershipRegistersApiClient(httpMiddleware),
    membershipsApiClient: new MembershipsApiClient(httpMiddleware),
    membershipsLeavesApiClient: new MembershipsLeavesApiClient(httpMiddleware),
    membershipsShiftsApiClient: new MembershipsShiftsApiClient(httpMiddleware),
    messagingApiClient: new MessagingApiClient(httpMiddleware),
    milkReportApiClient: new MilkReportApiClient(httpMiddleware),
    montessoriCategoriesApiClient: new MontessoriCategoriesApiClient(httpMiddleware),
    monthlyFteChangeReportApiClient: new MonthlyFteChangeReportApiClient(httpMiddleware),
    newsletterRecipientsApiClient: new NewsletterRecipientsApiClient(httpMiddleware),
    newslettersApiClient: new NewslettersApiClient(httpMiddleware),
    nextMontessoriActivitiesApiClient: new NextMontessoriActivitiesApiClient(httpMiddleware),
    nextStepsApiClient: new NextStepsApiClient(httpMiddleware),
    nurseriesApiClient: new NurseriesApiClient(httpMiddleware),
    nurseryAcademicYearsApiClient: new NurseryAcademicYearsApiClient(httpMiddleware),
    nurseryConsumablesApiClient: new NurseryConsumablesApiClient(httpMiddleware),
    nurseryConsumablesV3ApiClient: new NurseryConsumablesV3ApiClient(httpMiddleware),
    nurseryDiscountsApiClient: new NurseryDiscountsApiClient(httpMiddleware),
    nurseryDiscountsV3ApiClient: new NurseryDiscountsV3ApiClient(httpMiddleware),
    nurseryExtraItemsApiClient: new NurseryExtraItemsApiClient(httpMiddleware),
    nurseryFrameworkRelationsApiClient: new NurseryFrameworkRelationsApiClient(httpMiddleware),
    nurseryFundingApiClient: new NurseryFundingApiClient(httpMiddleware),
    nurseryFundingV3ApiClient: new NurseryFundingV3ApiClient(httpMiddleware),
    nurseryIntegrationsApiClient: new NurseryIntegrationsApiClient(httpMiddleware),
    nurseryItemApiClient: new NurseryItemApiClient(httpMiddleware),
    nurseryPaymentTypesApiClient: new NurseryPaymentTypesApiClient(httpMiddleware),
    nurserySessionsApiClient: new NurserySessionsApiClient(httpMiddleware),
    nurserySessionsV3ApiClient: new NurserySessionsV3ApiClient(httpMiddleware),
    observationFeedbackApiClient: new ObservationFeedbackApiClient(httpMiddleware),
    observationsApiClient: new ObservationsApiClient(httpMiddleware),
    occupancyApiClient: new OccupancyApiClient(httpMiddleware),
    organizationApiClient: new OrganizationApiClient(httpMiddleware),
    organizationSessionsApiClient: new OrganizationSessionsApiClient(httpMiddleware),
    organizationTermsApiClient: new OrganizationTermsApiClient(httpMiddleware),
    paymentReminderTagsApiClient: new PaymentReminderTagsApiClient(httpMiddleware),
    periodTimesApiClient: new PeriodTimesApiClient(httpMiddleware),
    periodsApiClient: new PeriodsApiClient(httpMiddleware),
    productPriceGroupsApiClient: new ProductPriceGroupsApiClient(httpMiddleware),
    registerApiClient: new RegisterApiClient(httpMiddleware),
    registerStatisticsApiClient: new RegisterStatisticsApiClient(httpMiddleware),
    relationsApiClient: new RelationsApiClient(httpMiddleware),
    requestedExtraSessionsApiClient: new RequestedExtraSessionsApiClient(httpMiddleware),
    roomMoveApiClient: new RoomMoveApiClient(httpMiddleware),
    roomsApiClient: new RoomsApiClient(httpMiddleware),
    staffLogsApiClient: new StaffLogsApiClient(httpMiddleware),
    subdomainApiClient: new SubdomainApiClient(httpMiddleware),
    supportedCurrencyApiClient: new SupportedCurrencyApiClient(httpMiddleware),
    supportedLocaleApiClient: new SupportedLocaleApiClient(httpMiddleware),
    transactionsApiClient: new TransactionsApiClient(httpMiddleware),
    uploadApiClient: new UploadApiClient(httpMiddleware),
    usersApiClient: new UsersApiClient(httpMiddleware),
  }

  return { dispatch, properties, ...services }
}

interface LegacyServices {
  dispatch: any
  uploadingService: any
}

export interface Services extends LegacyServices {
  NurseryFundingV3ApiClient: typeof NurseryFundingV3ApiClient
  NurseryItemApiClient: typeof NurseryItemApiClient
  absenceReasonsApiClient: typeof AbsenceReasonsApiClient
  agedDebtApiClient: typeof AgedDebtApiClient
  assessmentTrackerApiClient: typeof AssessmentTrackerApiClient
  authApiClient: typeof AuthApiClient
  authenticationLogsApiClient: typeof AuthenticationLogsApiClient
  balanceAdjustmentsApiClient: typeof BalanceAdjustmentsApiClient
  carerChildRelationsApiClient: typeof CarerChildRelationsApiClient
  carersApiClient: typeof CarersApiClient
  childAbsencesApiClient: typeof ChildAbsencesApiClient
  childAdhocSessionsApiClient: typeof ChildAdhocSessionsApiClient
  childApiClient: typeof ChildApiClient
  childBulkFundingApiClient: typeof ChildBulkFundingApiClient
  childDailyDiaryApiClient: typeof ChildDailyDiaryApiClient
  childDiscountsApiClient: typeof ChildDiscountsApiClient
  childExtraItemsApiClient: typeof ChildExtraItemsApiClient
  childFrameworkProgressApiClient: typeof ChildFrameworkProgressApiClient
  childFundingApiClient: typeof ChildFundingApiClient
  childFundingSessionSetApiClient: typeof ChildFundingSessionSetApiClient
  childMovesApiClient: typeof ChildMovesApiClient
  childNotesApiClient: typeof ChildNotesApiClient
  childObservationsApiClient: typeof ChildObservationsApiClient
  childRegistersApiClient: typeof ChildRegistersApiClient
  childSessionsApiClient: typeof ChildSessionsApiClient
  childrenLeavingReportApiClient: typeof ChildrenLeavingReportApiClient
  childrenSessionsApiClient: typeof ChildrenSessionsApiClient
  childrenSignedUpReportApiClient: typeof ChildrenSignedUpReportApiClient
  childrenStartingReportApiClient: typeof ChildrenStartingReportApiClient
  cohortTrackingApiClient: typeof CohortTrackingApiClient
  commentsApiClient: typeof CommentsApiClient
  contractsApiClient: typeof ContractsApiClient
  creditNotesApiClient: typeof CreditNotesApiClient
  dailyDiaryActivitiesApiClient: typeof DailyDiaryActivitiesApiClient
  dailyDiaryActivitiesApiClientV2: typeof DailyDiaryActivitiesApiClientV2
  dailyDiaryApiClient: typeof DailyDiaryApiClient
  dailyDiaryBottlesApiClient: typeof DailyDiaryBottlesApiClient
  dailyDiaryFoodsApiClient: typeof DailyDiaryFoodsApiClient
  dailyDiaryNappiesApiClient: typeof DailyDiaryNappiesApiClient
  dailyDiarySleepsApiClient: typeof DailyDiarySleepsApiClient
  dailyDiaryToiletTrainingApiClient: typeof DailyDiaryToiletTrainingApiClient
  depositsApiClient: typeof DepositsApiClient
  deviceApiClient: typeof DeviceApiClient
  deviceTokensApiClient: typeof DeviceTokensApiClient
  enquiriesApiClient: typeof EnquiriesApiClient
  enquiryActivitiesApiClient: typeof EnquiryActivitiesApiClient
  enquiryReportsApiClient: typeof EnquiryReportsApiClient
  enquirySettingsApiClient: typeof EnquirySettingsApiClient
  enquiryStatusTypesApiClient: typeof EnquiryStatusTypesApiClient
  enquiryViewingsApiClient: typeof EnquiryViewingsApiClient
  ethnicitiesApiClient: typeof EthnicitiesApiClient
  filesApiClient: typeof FilesApiClient
  financeBadDebtReportApiClient: typeof FinanceBadDebtReportApiClient
  financeBalanceReportApiClient: typeof FinanceBalanceReportApiClient
  financeCreditNotesReportApiClient: typeof FinanceCreditNotesReportApiClient
  financeDepositsReportApiClient: typeof FinanceDepositsReportApiClient
  financeFundingReportApiClient: typeof FinanceFundingReportApiClient
  financeRevenuePerAgeReportApiClient: typeof FinanceRevenuePerAgeReportApiClient
  financeRevenuePerRoomReportApiClient: typeof FinanceRevenuePerRoomReportApiClient
  foodMenuApiClient: typeof FoodMenuApiClient
  foodTypesApiClient: typeof FoodTypesApiClient
  formativeReportsApiClient: typeof FormativeReportsApiClient
  frameworkProgressLevelsApiClient: typeof FrameworkProgressLevelsApiClient
  frameworksApiClient: typeof FrameworksApiClient
  fundingTypesApiClient: typeof FundingTypesApiClient
  homeObservationsApiClient: typeof HomeObservationsApiClient
  injuriesApiClient: typeof InjuriesApiClient
  integrationFeesApiClient: typeof IntegrationFeesApiClient
  invoicePaymentsApiClient: typeof InvoicePaymentsApiClient
  invoicesApiClient: typeof InvoicesApiClient
  leaveNotesApiClient: typeof LeaveNotesApiClient
  leaveTypesApiClient: typeof LeaveTypesApiClient
  legacyChildBankDetailsApiClient: typeof LegacyChildBankDetailsApiClient
  likesApiClient: typeof LikesApiClient
  membershipRegistersApiClient: typeof MembershipRegistersApiClient
  membershipsApiClient: typeof MembershipsApiClient
  membershipsLeavesApiClient: typeof MembershipsLeavesApiClient
  membershipsShiftsApiClient: typeof MembershipsShiftsApiClient
  messagingApiClient: typeof MessagingApiClient
  milkReportApiClient: typeof MilkReportApiClient
  montessoriCategoriesApiClient: typeof MontessoriCategoriesApiClient
  monthlyFteChangeReportApiClient: typeof MonthlyFteChangeReportApiClient
  newsletterRecipientsApiClient: typeof NewsletterRecipientsApiClient
  newslettersApiClient: typeof NewslettersApiClient
  nextMontessoriActivitiesApiClient: typeof NextMontessoriActivitiesApiClient
  nextStepsApiClient: typeof NextStepsApiClient
  nurseriesApiClient: typeof NurseriesApiClient
  nurseryAcademicYearsApiClient: typeof NurseryAcademicYearsApiClient
  nurseryConsumablesApiClient: typeof NurseryConsumablesApiClient
  nurseryConsumablesV3ApiClient: typeof NurseryConsumablesV3ApiClient
  nurseryDiscountsApiClient: typeof NurseryDiscountsApiClient
  nurseryDiscountsV3ApiClient: typeof NurseryDiscountsV3ApiClient
  nurseryExtraItemsApiClient: typeof NurseryExtraItemsApiClient
  nurseryFrameworkRelationsApiClient: typeof NurseryFrameworkRelationsApiClient
  nurseryFundingApiClient: typeof NurseryFundingApiClient
  nurseryItemApiClient: typeof NurseryItemApiClient
  nurseryPaymentTypesApiClient: typeof NurseryPaymentTypesApiClient
  nurserySessionsApiClient: typeof NurserySessionsApiClient
  nurserySessionsV3ApiClient: typeof NurserySessionsV3ApiClient
  observationFeedbackApiClient: typeof ObservationFeedbackApiClient
  observationsApiClient: typeof ObservationsApiClient
  occupancyApiClient: typeof OccupancyApiClient
  organizationApiClient: typeof OrganizationApiClient
  organizationSessionsApiClient: typeof OrganizationSessionsApiClient
  organizationTermsApiClient: typeof OrganizationTermsApiClient
  paymentReminderTagsApiClient: typeof PaymentReminderTagsApiClient
  periodsApiClient: typeof PeriodsApiClient
  productPriceGroups: typeof ProductPriceGroupsApiClient
  registerApiClient: typeof RegisterApiClient
  registerStatisticsApiClient: typeof RegisterStatisticsApiClient
  relationsApiClient: typeof RelationsApiClient
  requestedExtraSessionsApiClient: typeof RequestedExtraSessionsApiClient
  roomMoveApiClient: typeof RoomMoveApiClient
  roomsApiClient: typeof RoomsApiClient
  staffLogsApiClient: typeof StaffLogsApiClient
  subdomainApiClient: typeof SubdomainApiClient
  transactionsApiClient: typeof TransactionsApiClient
  uploadApiClient: typeof UploadApiClient
  usersApiClient: typeof UsersApiClient
}
