import React from 'react'

import { SubMenuWrapper } from 'components'

import { ChildHeader } from './components'

const ChildView = ({
  authAccessMap,
  child,
  children,
  hasChildMoves,
  hasRelations,
  hasSessions,
  isFinanceV3Enabled,
}) => (
  <SubMenuWrapper
    header={() => (
      <ChildHeader
        authAccessMap={authAccessMap}
        child={child}
        hasChildMoves={hasChildMoves}
        hasRelations={hasRelations}
        hasSessions={hasSessions}
        isFinanceV3Enabled={isFinanceV3Enabled}
      />
    )}
  >
    {children}
  </SubMenuWrapper>
)

export default ChildView
