import styled from 'styled-components'

import { LEAVE_SHIFT_TYPES, LEAVE_TYPES_COLOURS } from 'services/legacy/membershipsLeaves/constants'
import { SHIFT_MAIN_TYPES_COLOURS } from 'services/legacy/membershipRegisters/constants'

export const StyledLabel = styled.div`
  border-radius: 12px;
  padding: 3px 7px;
  margin-right: 5px;
  font-size: 14px;
  display: inline-block;
  text-align: left;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis; 
  white-space: nowrap;
  
  ${({ center }) => center && `
    text-align: center;
  `}

  ${({ code }) => code && `
    background: ${LEAVE_TYPES_COLOURS[code]?.header || LEAVE_TYPES_COLOURS[LEAVE_SHIFT_TYPES.OTHER].header};
  `}

  ${({ type }) => type && `
    background: ${SHIFT_MAIN_TYPES_COLOURS[type]};
  `}
`
