import * as ageBandsActions from './ageBands/actions'
import * as listActions from './list/actions'
import * as singleActions from './single/actions'
import * as legacyList from './legacyList/actions'

export default {
  ...ageBandsActions,
  ...listActions,
  ...singleActions,
  ...legacyList,
}
