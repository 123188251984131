export const CHILD_EDIT_FORM_TYPE = {
  DEVELOPMENT_INFO: 'DEVELOPMENT_INFO',
  ENROLMENT_DETAILS: 'ENROLMENT_DETAILS',
  FAMILY_INFO: 'FAMILY_INFO',
  GENERAL_INFO: 'GENERAL_INFO',
  HEALTH_AND_DIET_INFO: 'HEALTH_AND_DIET_INFO',
  LIKES_DISLIKES_INFO: 'LIKES_DISLIKES_INFO',
  PERMISSIONS_INFO: 'PERMISSIONS_INFO',
}

export const CHILD_EDIT_FORM_ROUTE_TYPE = {
  DEVELOPMENT_INFO: 'development-info',
  ENROLMENT_DETAILS: 'enrolment-details',
  FAMILY_INFO: 'family-info',
  GENERAL_INFO: 'general-info',
  HEALTH_AND_DIET_INFO: 'health-and-diet-info',
  LIKES_DISLIKES_INFO: 'likes-dislikes-info',
  PERMISSIONS_INFO: 'permissions-info',
}

export const CHILD_EDIT_FORM_ROUTE_MAPPING = {
  [CHILD_EDIT_FORM_ROUTE_TYPE.FAMILY_INFO]: CHILD_EDIT_FORM_TYPE.FAMILY_INFO,
  [CHILD_EDIT_FORM_ROUTE_TYPE.GENERAL_INFO]: CHILD_EDIT_FORM_TYPE.GENERAL_INFO,
  [CHILD_EDIT_FORM_ROUTE_TYPE.DEVELOPMENT_INFO]: CHILD_EDIT_FORM_TYPE.DEVELOPMENT_INFO,
  [CHILD_EDIT_FORM_ROUTE_TYPE.ENROLMENT_DETAILS]: CHILD_EDIT_FORM_TYPE.ENROLMENT_DETAILS,
  [CHILD_EDIT_FORM_ROUTE_TYPE.PERMISSIONS_INFO]: CHILD_EDIT_FORM_TYPE.PERMISSIONS_INFO,
  [CHILD_EDIT_FORM_ROUTE_TYPE.LIKES_DISLIKES_INFO]: CHILD_EDIT_FORM_TYPE.LIKES_DISLIKES_INFO,
  [CHILD_EDIT_FORM_ROUTE_TYPE.HEALTH_AND_DIET_INFO]: CHILD_EDIT_FORM_TYPE.HEALTH_AND_DIET_INFO,
}
