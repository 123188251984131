import _ from 'lodash'
import moment from 'moment'
import { createSelector } from 'reselect'

import { DAILY_DIARY_DATE_FORMAT, TIME_TYPE } from 'services/legacy/dailyDiary/constants'

import { changeHours, isPastDay } from 'utils/date'

const getChildRegisters = (state) => state.childRegisters

export const getChildRegistersList = createSelector([getChildRegisters], (state) => state.list)

export const getChildRegistersListData = createSelector([getChildRegistersList], (state) => state.data)

export const getChildRegistersListDataGroupedByChild = createSelector(
  [getChildRegistersListData],
  (items) => _.map(items, ({ child }) => child),
)

export const getListCriteria = (query = {}) => {
  const { entryDate = moment().format(DAILY_DIARY_DATE_FORMAT), room, timeType } = query
  const criteria = []

  criteria.push({
    comparator: 'after',
    field: 'entryDate',
    value: entryDate,
  })

  criteria.push({
    comparator: 'before',
    field: 'entryDate',
    value: entryDate,
  })

  if (room) {
    criteria.push({
      field: 'child.nurseryClass',
      value: room,
    })
  }

  if (timeType === TIME_TYPE.CURRENTLY_TIMED_IN && !isPastDay(entryDate)) {
    criteria.push({
      comparator: 'after',
      field: 'signIns.signedOutAt',
      value: changeHours(entryDate, moment()).toISOString(),
    })
  }

  criteria.push({
    field: 'present',
    value: true,
  })

  return criteria
}
