import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { Spinner } from 'components'

import {
  StyledCell,
  StyledColumnHeader,
  StyledColumnHeaderWrapper,
  StyledContainer,
  StyledRow,
  StyledRowContent,
  StyledRowHeader,
  StyledRowSubHeader,
  StyledTable,
} from './WeeklySchedulerStyled'
import WeeklySchedulerProgress from './WeeklySchedulerProgress'

import {
  getHourDiffBetweenTime,
  getIsDateBetweenSessions,
  getMaxNestedProgressCount,
  getProgressListWithOverlap,
  isOverLapping,
} from './utils/dateUtils'
import { CELL_WIDTH, HEADER_OFFSET, MIN_ROW_HEIGHT, ROW_HEADER_WIDTH } from './constants'

const WeeklyScheduler = ({
  dateRange,
  endTime,
  isLoading,
  onItemClick,
  selectedWeekDays,
  startTime,
}) => {
  if (isLoading) {
    return <Spinner />
  }

  const sessions = selectedWeekDays?.sessions
  const startTimeWithDate = moment().set({ hour: startTime, minute: 0, second: 0 })
  const hourDifference = getHourDiffBetweenTime(startTime, endTime)

  const timeRange = []
  for (let index = 0; index < hourDifference; index++) { // eslint-disable-line
    timeRange.push(moment(startTimeWithDate).add(index + 1, 'hour'))
  }

  const timeHeaderRange = [
    moment(startTimeWithDate),
    ...timeRange,
  ]

  const renderColumnHeader = () => (
    <StyledColumnHeaderWrapper>
      {_.map(timeHeaderRange, (timeWithDate, index) => {
        const left = 0 === index ? HEADER_OFFSET : HEADER_OFFSET + (index * CELL_WIDTH)
        const leftBorderWidth = Math.abs(left / CELL_WIDTH)

        return (
          <StyledColumnHeader key={timeWithDate.valueOf()} left={left + leftBorderWidth}>
            {timeWithDate.format('HH:mm')}
          </StyledColumnHeader>
        )
      })}
    </StyledColumnHeaderWrapper>
  )

  const handleItemClick = (date, progress) => () => {
    onItemClick(date, progress)
  }

  const renderRows = () => _.map(dateRange, ({ date, progressList }) => {
    const progressWithOverlapData = getProgressListWithOverlap(progressList)
    const isOverlapping = isOverLapping(progressWithOverlapData)
    const maxNestedProgressCount = getMaxNestedProgressCount(progressWithOverlapData)
    const isDateBetweenSessions = getIsDateBetweenSessions(date, sessions)

    const renderProgress = (progress, index) => (
      <WeeklySchedulerProgress
        endTime={endTime}
        key={index}
        progress={progress}
        startTime={startTime}
        onItemClick={handleItemClick(date, progress)}
      />
    )

    return (
      <StyledRow
        height={isOverlapping ? (MIN_ROW_HEIGHT + (maxNestedProgressCount * 50) + 10) : null}
        key={moment(date).valueOf()}
      >
        <StyledRowContent>
          <StyledCell width={ROW_HEADER_WIDTH}>
            <StyledRowHeader>
              {moment(date).format('dddd').slice(0, 3).toUpperCase()}
              <StyledRowSubHeader>
                {moment(date).format('DD/MM')}
              </StyledRowSubHeader>
            </StyledRowHeader>
          </StyledCell>
          {_.map(timeRange, (time, index) => <StyledCell key={index} />)}
          {isDateBetweenSessions ? _.map(progressWithOverlapData, renderProgress) : null}
        </StyledRowContent>
      </StyledRow>
    )
  })

  return (
    <StyledContainer>
      <StyledTable>
        <StyledRow>
          {renderColumnHeader()}
        </StyledRow>
        {renderRows()}
      </StyledTable>
    </StyledContainer>
  )
}

export default WeeklyScheduler
