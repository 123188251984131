import React from 'react'
import regeneratorRuntime from 'regenerator-runtime-only'
import ResizeObserver from 'resize-observer-polyfill'
import 'intersection-observer'
import 'whatwg-fetch'
import 'fullscreen-api-polyfill'
import '@azure/core-asynciterator-polyfill'

// eslint-disable-next-line import/extensions
import 'polyfill/canvas-to-blob.min'
import 'polyfill/momentPreciseRange'

// devnote: currently our babel stack is transforming JSX syntax to React calls, as such
// there is an expectation that React is in scope as 'React', the easiest way to do this
// is to ensure React is defined on the global object.
// in the future we should look to transform JSX to JSX-IR (or similar), and work to have
// react support either this syntax, or an agreed schema
window.React = React
window.regeneratorRuntime = regeneratorRuntime
window.ResizeObserver = ResizeObserver
window.$RefreshReg$ = () => {}
window.$RefreshSig$ = () => {}

if (!Object.fromEntries) {
  Object.defineProperty(Object, 'fromEntries', {
    value(entries) {
      if (!entries || !entries[Symbol.iterator]) {
        throw new Error('Object.fromEntries() requires a single iterable argument')
      }

      const o = {}

      Object.keys(entries).forEach((key) => {
        const [k, v] = entries[key]

        o[k] = v
      })

      return o
    },
  })
}

if (Intl.NumberFormat.prototype.formatToParts === undefined) {
  // eslint-disable-next-line no-inner-declarations
  function getSample(numberFormat) {
    const options = numberFormat.resolvedOptions()
    const sampleFormat = new Intl.NumberFormat(options.locale)

    return sampleFormat.format(-1234567.89)
  }

  // eslint-disable-next-line no-inner-declarations
  function findGroupSymbol(sample) {
    for (let i = 0; i < sample.length; i += 1) {
      const ch = sample[i]

      if (-1 !== ['.', ',', ' ', String.fromCharCode(8239)].indexOf(ch)) {
        return ch
      }
    }

    return null
  }

  // eslint-disable-next-line no-inner-declarations
  function findDecimalSymbol(sample) {
    const indexComma = sample.lastIndexOf(',')
    const indexDot = sample.lastIndexOf('.')

    return indexComma > indexDot ? ',' : '.'
  }

  // eslint-disable-next-line func-names
  Intl.NumberFormat.prototype.formatToParts = function (number) {
    const sample = getSample(this)
    const decimalSymbol = findDecimalSymbol(sample)
    const groupSymbol = findGroupSymbol(sample)
    const parts = []

    const str = this.format(number)
    let inFraction = false

    for (let i = 0; i < str.length; i += 1) {
      const ch = str[i]

      if ('-' === ch) {
        parts.push({ type: 'minusSign', value: '-' })
      } else if ('0' <= ch && '9' >= ch) {
        if (inFraction) {
          parts.push({ type: 'fraction', value: ch })
        } else {
          parts.push({ type: 'integer', value: ch })
        }
      } else if (ch === decimalSymbol) {
        inFraction = true
        parts.push({ type: 'decimal', value: ch })
      } else if (ch === groupSymbol) {
        parts.push({ type: 'group', value: ch })
      } else if (-1 !== [',', '.', ' ', String.fromCharCode(160), String.fromCharCode(8239)].indexOf(ch)) {
        parts.push({ type: 'literal', value: ch })
      } else {
        parts.push({ type: 'currency', value: ch })
      }
    }

    let last = parts[0]
    const normalized = [last]

    for (let i = 1; i < parts.length; i += 1) {
      const part = parts[i]

      if (last.type === part.type || ('currency' === last.type && 'literal' === part.type && '.' === part.value)) {
        last.value += part.value
      } else {
        last = part
        normalized.push(last)
      }
    }

    return normalized
  }
}

if (!String.prototype.replaceAll) {
  // eslint-disable-next-line no-extend-native, func-names
  String.prototype.replaceAll = function (str, newStr) {
    if ('[object regexp]' === Object.prototype.toString.call(str).toLowerCase()) {
      return this.replace(str, newStr)
    }

    return this.replace(new RegExp(str, 'g'), newStr)
  }
}
