import _ from 'lodash'

import React from 'react'
import { Field, FieldArray, reduxForm } from 'redux-form'

import {
  BREAK_ALLOWANCE_OPTIONS,
  CONTRACT_TYPE,
  CONTRACT_TYPE_OPTIONS,
  OVERTIME_TREATMENT_OPTIONS,
} from 'services/legacy/contracts/constants'

import {
  isEndTimeGreaterThanStartTime,
  isRequired,
  isRequiredTime,
  isStartDateBeforeEndDate,
  isValidInteger,
} from 'utils/fieldValidation'

import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

import { Button, Currency, Form, Section, Typography } from 'components'

import i18n from 'translations'

export const STAFF_CONTRACTS_FORM = 'StaffContractsForm'

const renderRow = (item, i, fields, isSubmitting) => (
  <Form.Row key={_.kebabCase(i)} margin="0 0 15px">
    <Form.Row.FlexItem flex="0 0 150px" margin="0 15px 5px 0">
      <Field
        component={Form.TextField}
        name={`${item}.time`}
        suffix={i18n.t('global:minutes')}
        suffixWidth="60px"
        type="number"
        validate={[isRequired, isValidInteger]}
      />
    </Form.Row.FlexItem>
    <Form.Row.FlexItem flex="0 0 170px" margin="0 0 5px">
      <Field
        component={Form.Select}
        name={`${item}.type`}
        options={BREAK_ALLOWANCE_OPTIONS}
        validate={isRequired}
        width="170"
      />
    </Form.Row.FlexItem>
    <Form.Row.TextItem
      flex="none"
      margin="0 0 5px"
      text={i18n.t('module:Staff:StaffContracts:DailyBreakAllowance:conditionText')}
    />
    <Form.Row.FlexItem flex="0 0 70px" margin="0 0 5px">
      <Field
        component={Form.TimePicker}
        local={false}
        name={`${item}.startTime`}
        placeholder={_.upperFirst(i18n.t('global:start'))}
        validate={isRequiredTime}
      />
    </Form.Row.FlexItem>
    <Form.Row.TextItem flex="none" margin="0 0 5px" text="-" />
    <Form.Row.FlexItem flex="0 0 70px" margin="0 0 5px">
      <Field
        component={Form.TimePicker}
        local={false}
        name={`${item}.endTime`}
        placeholder={_.upperFirst(i18n.t('global:end'))}
        validate={isRequiredTime}
      />
    </Form.Row.FlexItem>
    <Form.Row.TextItem
      flex="none"
      margin="0 0 5px"
      text={i18n.t('module:Staff:StaffContracts:DailyBreakAllowance:workingTime')}
    />
    <Form.Row.FlexItem margin="0 0 5px">
      <Form.RemoveRowButton
        disabled={isSubmitting}
        trash
        onClick={() => fields.remove(i)}
      />
    </Form.Row.FlexItem>
  </Form.Row>
)

const renderBreakAllowance = ({ fields, isSubmitting }) => (
  <React.Fragment>
    {fields.map((item, i) => renderRow(item, i, fields, isSubmitting))}
    <Button.ActionButton
      disabled={isSubmitting}
      label={i18n.t('module:Staff:StaffContracts:DailyBreakAllowance:addBreakAllowanceButtonLabel')}
      addBreakAllowanceButtonLabel
      onClick={() => fields.push({})}
    />
  </React.Fragment>
)

const StaffContractsAddForm = ({
  cancelLink,
  formValues,
  handleSubmit,
  isCreatingContext,
  isSubmitting,
  onOngoingClick,
  onSubmit,
  salaryPerMonth,
}) => {
  const renderForm = () => (
    <SubdomainCurrencyProvider>
      {({ getFormattedCurrencyValue }) => (
        <React.Fragment>
          <Form.Row
            label={i18n.t('module:Staff:StaffContracts:Labels:type')}
          >
            <Form.Row.FlexItem flex="0 0 300px" mobileFull>
              <Field
                clearable={false}
                component={Form.Select}
                disabled={!isCreatingContext}
                name="type"
                options={CONTRACT_TYPE_OPTIONS}
                placeholder={i18n.t('module:Staff:StaffContracts:Labels:type')}
              />
            </Form.Row.FlexItem>
          </Form.Row>
          <Form.Row
            label={i18n.t('module:Staff:StaffContracts:Labels:jobTitle')}
          >
            <Form.Row.FlexItem flex="0 0 300px" mobileFull>
              <Field
                component={Form.TextField}
                name="jobTitle"
                placeholder={i18n.t('module:Staff:StaffContracts:Labels:jobTitle')}
              />
            </Form.Row.FlexItem>
          </Form.Row>
          <Form.Row
            label={i18n.t('module:Staff:StaffContracts:Labels:contractDate')}
            multipleFieldsInARow
          >
            <Form.Row.FlexItem flex="0 0 170px" mobileFull>
              <Field
                component={Form.DatePicker}
                name="startDate"
              />
            </Form.Row.FlexItem>
            <Form.Row.TextItem text={i18n.t('global:to')} />
            <Form.Row.FlexItem flex="0 0 170px" mobileFull>
              <Field
                component={Form.DatePicker}
                disabled={formValues?.ongoing}
                name="endDate"
              />
            </Form.Row.FlexItem>
            <Form.Row.TextItem text={i18n.t('global:or')} />
            <Form.Row.FlexItem mobileFull>
              <Field
                component={Form.Checkbox}
                disabled={!formValues?.startDate}
                label={i18n.t('global:ongoing')}
                name="ongoing"
                onClick={onOngoingClick}
              />
            </Form.Row.FlexItem>
          </Form.Row>
          {formValues?.type?.value === CONTRACT_TYPE.PERMANENT && (
            <React.Fragment>
              <Form.Row
                label={i18n.t('module:Staff:StaffContracts:Labels:ContractedHours:long')}
                multipleFieldsInARow
              >
                <Form.Row.FlexItem flex="0 0 110px" mobileFull>
                  <Field
                    component={Form.TextField}
                    min="0"
                    name="contractedHours.hours"
                    placeholder="00"
                    suffix={i18n.t('global:hours')}
                    suffixWidth="50px"
                    type="number"
                  />
                </Form.Row.FlexItem>
                <Form.Row.FlexItem flex="0 0 110px" mobileFull>
                  <Field
                    component={Form.TextField}
                    max="59"
                    min="0"
                    name="contractedHours.minutes"
                    placeholder="00"
                    suffix={i18n.t('global:minutes')}
                    suffixWidth="60px"
                    type="number"
                  />
                </Form.Row.FlexItem>
              </Form.Row>
              <Form.Row
                label={i18n.t('module:Staff:StaffContracts:Labels:salary')}
                multipleFieldsInARow
              >
                <Form.Row.FlexItem flex="0 0 170px" mobileFull>
                  <Field
                    component={Form.TextField}
                    max="99999999"
                    min="0"
                    name="salary"
                    placeholder={getFormattedCurrencyValue(0.00)}
                    step="0.01"
                    suffix={`/ ${i18n.t('global:year')}`}
                    suffixWidth="50px"
                    type="number"
                  />
                </Form.Row.FlexItem>
                <Form.Row.TextItem
                  text={(
                    <Typography fontSize={14}>
                      <Currency value={salaryPerMonth} />
                      {` / ${i18n.t('global:month')}`}
                    </Typography>
                  )}
                />
              </Form.Row>
            </React.Fragment>
          )}
          <Form.Row
            label={i18n.t('module:Staff:StaffContracts:Labels:hourlySalary')}
          >
            <Form.Row.FlexItem flex="0 0 170px" mobileFull>
              <Field
                component={Form.TextField}
                max="99999999"
                min="0"
                name="hourlySalary"
                placeholder={getFormattedCurrencyValue(0.00)}
                step="0.01"
                suffix={`/ ${i18n.t('global:hour')}`}
                suffixWidth="50px"
                type="number"
              />
            </Form.Row.FlexItem>
          </Form.Row>
          {formValues?.type?.value === CONTRACT_TYPE.PERMANENT && (
            <Form.Row
              label={i18n.t('module:Staff:StaffContracts:Labels:overtimeTreatment')}
            >
              <Form.Row.FlexItem flex="0 0 300px" mobileFull>
                <Field
                  component={Form.Select}
                  name="overtimeTreatment"
                  options={OVERTIME_TREATMENT_OPTIONS}
                  placeholder={i18n.t('module:Staff:StaffContracts:Labels:overtimeTreatment')}
                />
              </Form.Row.FlexItem>
            </Form.Row>
          )}
          <Section title={i18n.t('module:Staff:StaffContracts:DailyBreakAllowance:label')}>
            <FieldArray
              component={renderBreakAllowance}
              isSubmitting={isSubmitting}
              name="breakAllowances"
            />
          </Section>
        </React.Fragment>
      )}
    </SubdomainCurrencyProvider>
  )

  const renderFooter = () => (
    <Form.FooterActions
      cancelLink={cancelLink}
      isSubmitting={isSubmitting}
      submitLabel={isCreatingContext
        ? i18n.t('module:Staff:StaffContracts:Add:submitButton')
        : i18n.t('module:Staff:StaffContracts:Edit:submitButton')}
    />
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {renderForm()}
      {renderFooter()}
    </Form>
  )
}

const validate = (values) => {
  const errors = { breakAllowances: [] }

  if (!values) {
    return errors
  }

  const { breakAllowances, endDate, ongoing, startDate } = values

  errors.startDate = isStartDateBeforeEndDate(startDate, endDate)

  if (startDate && !ongoing && !endDate) {
    errors.endDate = i18n.t('module:Staff:StaffContracts:Add:dateValidationError')
  }

  if (breakAllowances?.length) {
    _.forEach(breakAllowances, (item) => {
      errors.breakAllowances.push({ endTime: isEndTimeGreaterThanStartTime(item) })
    })
  }

  return errors
}

export default reduxForm({ form: STAFF_CONTRACTS_FORM, validate })(StaffContractsAddForm)
