import styled from 'styled-components'

import { Link } from 'react-router'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledContainer = styled(Link)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  color: ${NEUTRAL_COLOURS.WHITE};

  &:hover {
    color: ${NEUTRAL_COLOURS.WHITE};
  }
`

export const StyledLabelContainer = styled.div`
  font-size: 20px;
  text-align: center;
`

export const StyledNavContainer = styled.div`
  padding-left: 10px;
`
