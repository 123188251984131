import React, { useState } from 'react'
import { Field, change, reduxForm, submit } from 'redux-form'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { Button, Form, Spinner, Toolbar, Typography } from 'components'

import i18n from 'translations'

import { StyledActionPlan, StyledHeader, StyledPreview } from './ActionPlanStyled'

export const ACTION_PLAN_FORM = 'ActionPlanForm'

const ActionPlan = ({
  changeActionPlanValue,
  defaultValue,
  handleSubmit,
  itsUnsavedPreview,
  onChangeActionPlan,
  onSubmit,
}) => {
  const [isPreview, setIsPreview] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const deleteActionPlan = () => {
    changeActionPlanValue('')
    setIsSubmitting(true)

    if (!itsUnsavedPreview) {
      onChangeActionPlan(() => {
        setIsSubmitting(false)
        setIsPreview(true)
      })
    } else {
      setIsPreview(true)
    }
  }

  const saveActionPlan = () => {
    setIsSubmitting(true)

    onChangeActionPlan(() => {
      setIsSubmitting(false)
      setIsPreview(true)
    })
  }

  const cancelActionPlan = () => {
    changeActionPlanValue(defaultValue)
    setIsPreview(true)
  }

  const renderField = () => {
    if (isSubmitting) {
      return null
    }

    return (
      <Field
        component={Form.TextAreaField}
        name="actionPlan"
        placeholder={i18n.t('module:Learning:CohortTracking:components:ActionPlan:placeholder')}
      />
    )
  }

  const renderContent = () => {
    if (itsUnsavedPreview) {
      if (isPreview) {
        return (
          <Button.ActionButton
            label={i18n.t('module:Learning:CohortTracking:components:ActionPlan:addActionPlan')}
            onClick={() => setIsPreview(false)}
          />
        )
      }

      return (
        <React.Fragment>
          <Button.ActionButton
            icon="close"
            label={i18n.t('module:Learning:CohortTracking:components:ActionPlan:deleteActionPlan')}
            onClick={deleteActionPlan}
          />
          <Field
            component={Form.TextAreaField}
            name="actionPlan"
            placeholder={i18n.t('module:Learning:CohortTracking:components:ActionPlan:placeholder')}
          />
        </React.Fragment>
      )
    }

    const renderToolbar = () => {
      if (isSubmitting) {
        return (
          <Spinner position="flex-start" size="small" />
        )
      }

      return (
        <Toolbar noMargin>
          {isPreview && (
            <Toolbar.Group>
              <Toolbar.Item noMargin>
                <Button
                  hierarchy="tertiary"
                  label={i18n.t('global:Edit')}
                  size="small"
                  negativeMargins
                  onClick={() => setIsPreview(false)}
                />
              </Toolbar.Item>
            </Toolbar.Group>
          )}
          {!isPreview && (
            <Toolbar.Group>
              <Toolbar.Item noMargin>
                <Button
                  hierarchy="secondary"
                  label={i18n.t('global:Save')}
                  size="small"
                  negativeMargins
                  onClick={saveActionPlan}
                />
              </Toolbar.Item>
              <Toolbar.Item noMargin>
                <Button
                  hierarchy="secondary"
                  label={i18n.t('global:Cancel')}
                  size="small"
                  negativeMargins
                  onClick={cancelActionPlan}
                />
              </Toolbar.Item>
              <Toolbar.Item noMargin>
                <Button
                  hierarchy="tertiary"
                  icon="trash"
                  margin="-8px -4px"
                  size="small"
                  onClick={deleteActionPlan}
                />
              </Toolbar.Item>
            </Toolbar.Group>
          )}
        </Toolbar>
      )
    }

    return (
      <React.Fragment>
        <StyledHeader>
          <Typography bold>
            {i18n.t('module:Learning:CohortTracking:components:ActionPlan:actionPlan')}
          </Typography>
          {renderToolbar()}
        </StyledHeader>
        {!isPreview && renderField()}
        {isPreview && defaultValue && (
          <StyledPreview>
            {defaultValue || '-'}
          </StyledPreview>
        )}
      </React.Fragment>
    )
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <StyledActionPlan>
        {renderContent()}
      </StyledActionPlan>
    </Form>
  )
}

const mapDispatch = {
  changeActionPlanValue: (value) => change(ACTION_PLAN_FORM, 'actionPlan', value),
  submitForm: () => submit(ACTION_PLAN_FORM),
}

const enhance = compose(
  connect(null, mapDispatch),
)

export default reduxForm({
  form: ACTION_PLAN_FORM,
})(enhance(ActionPlan))
