import _ from 'lodash'
import { v4 } from 'uuid'

import {
  CLEAR_REGISTER,
  CREATE_REGISTER,
  CREATE_REGISTER_FAILED,
  CREATE_REGISTER_SUCCESS,
  UPDATE_REGISTER,
  UPDATE_REGISTER_FAILED,
  UPDATE_REGISTER_SUCCESS,
} from './constants'

export const create = (childId, payload, onSuccess) => ({ registerApiClient }) => async (dispatch) => {
  dispatch({
    childId,
    payload,
    type: CREATE_REGISTER,
  })

  await registerApiClient.create(childId, payload).then(
    (data) => {
      dispatch({
        childId,
        payload: {
          ...data,
          entryDate: payload.entryDate,
        },
        type: CREATE_REGISTER_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        childId,
        error: error.response,
        type: CREATE_REGISTER_FAILED,
      })
    },
  )
}

export const update = (childId, id, payload, onSuccess, isChildRegister) => ({ registerApiClient }) => async (
  dispatch,
) => {
  dispatch({
    childId,
    id,
    payload,
    type: UPDATE_REGISTER,
  })

  await registerApiClient.update(childId, id, payload).then(
    (data) => {
      dispatch({
        childId,
        id,
        isChildRegister,
        payload: {
          ...data,
          data: {
            ...data.data,
            comments: data.data.comments || null,
          },
          entryDate: payload.entryDate,
        },
        type: UPDATE_REGISTER_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        childId,
        error: error.response,
        isChildRegister,
        type: UPDATE_REGISTER_FAILED,
      })
    },
  )
}

export const clear = () => ({
  type: CLEAR_REGISTER,
})

export const offlineUpdate = ({
  childId,
  id,
  isChildRegister,
  payload,
}) => {
  const newPayload = { ...payload }

  if (newPayload.signIns?.length) {
    newPayload.signIns = _.map(newPayload.signIns, (item) => {
      const newItem = { ...item }

      if (!newItem.id) {
        newItem.id = v4()
      }

      return newItem
    })
  }

  return ({
    childId,
    id,
    isChildRegister,
    payload: {
      data: newPayload,
    },
    type: UPDATE_REGISTER_SUCCESS,
  })
}

export const offlineCreate = (childId, payload) => {
  const newPayload = { ...payload }

  if (newPayload.signIns?.length) {
    newPayload.signIns = _.map(newPayload.signIns, (item) => {
      const newItem = { ...item }

      if (!newItem.id) {
        newItem.id = v4()
      }

      return newItem
    })
  }

  return ({
    childId,
    payload: {
      data: newPayload,
    },
    type: CREATE_REGISTER_SUCCESS,
  })
}
