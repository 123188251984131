import {
  ADD_TOILET_TRAINING,
  ADD_TOILET_TRAINING_FAILED,
  ADD_TOILET_TRAINING_SUCCESS,
  REMOVE_TOILET_TRAINING,
  REMOVE_TOILET_TRAINING_FAILED,
  REMOVE_TOILET_TRAINING_SUCCESS,
  UPDATE_TOILET_TRAINING,
  UPDATE_TOILET_TRAINING_FAILED,
  UPDATE_TOILET_TRAINING_SUCCESS,
} from './constants'

const initialState = {
  isSubmitting: false,
}

export default (state = initialState, { type }) => {
  switch (type) {
    case ADD_TOILET_TRAINING:
    case REMOVE_TOILET_TRAINING:
    case UPDATE_TOILET_TRAINING:
      return { ...state, isSubmitting: true }
    case REMOVE_TOILET_TRAINING_FAILED:
    case REMOVE_TOILET_TRAINING_SUCCESS:
    case ADD_TOILET_TRAINING_FAILED:
    case ADD_TOILET_TRAINING_SUCCESS:
    case UPDATE_TOILET_TRAINING_FAILED:
    case UPDATE_TOILET_TRAINING_SUCCESS:
      return { ...state, isSubmitting: false }
    default:
      return state
  }
}
