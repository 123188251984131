import { generateBasicActions } from 'utils/service'

import {
  CLEAR_FINANCE_REPORTS_CHILDREN_DEPOSITS,
  FINANCE_REPORTS_CHILDREN_DEPOSITS,
  FINANCE_REPORTS_CHILDREN_DEPOSITS_FAILED,
  FINANCE_REPORTS_CHILDREN_DEPOSITS_SUCCESS,
} from './constants'

export const getChildren = (options = {}) => ({ financeDepositsReportApiClient }) => generateBasicActions.list({
  constants: {
    failed: FINANCE_REPORTS_CHILDREN_DEPOSITS_FAILED,
    init: FINANCE_REPORTS_CHILDREN_DEPOSITS,
    success: FINANCE_REPORTS_CHILDREN_DEPOSITS_SUCCESS,
  },
  options,
  service: financeDepositsReportApiClient,
  serviceMethod: 'getChildren',
})

export const clearChildren = () => ({
  type: CLEAR_FINANCE_REPORTS_CHILDREN_DEPOSITS,
})
