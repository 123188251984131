import React from 'react'
import { Field, FieldArray, reduxForm } from 'redux-form'

import { generateRoute } from 'utils/routing'

import { Form, InfiniteScroll, Section, Space, Table, TimeString, Typography } from 'components'

import i18n from 'translations'

import { StyledTableWrapper } from '../CorePreferencesStyled'

export const REQUESTABLE_EXTRA_SESSIONS_CORE_PREFERENCES_FORM = 'RequestableExtraSessionsCorePreferencesForm'

const COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'name',
    sortKey: 'name',
    sortable: true,
    title: i18n.t('global:Session'),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'time',
    sortKey: 'startTime',
    sortable: true,
    title: i18n.t('global:Time'),
  },
]

const renderRows = ({ fields, onSessionClick }) => fields.map((field, i) => {
  const fieldValues = fields.get(i)
  const { endTime, id, name, startTime } = fieldValues

  return (
    <Table.Tr key={id}>
      <Table.Td align="left">
        <Field
          component={Form.Checkbox}
          label={name}
          name={`${field}.requestable`}
          padding="0 0 0 10px"
          onChange={(value) => onSessionClick(value, id)}
        />
      </Table.Td>
      <Table.Td align="left">
        <Typography>
          <TimeString.Range end={endTime} local={false} start={startTime} />
        </Typography>
      </Table.Td>
    </Table.Tr>
  )
})

const CorePreferencesForm = ({
  handleSubmit,
  isSessionsFetching,
  isSubmitting,
  onPageChange,
  onSessionClick,
  onSortChange,
  onSubmit,
  page,
  pageCount,
  sessions,
  sortField,
  sortOrder,
}) => {
  const renderRequestableSessions = () => (
    <Section
      id="requestableSessionsScrollableTarget"
      title={
        i18n.t('module:Management:ParentApp:RequestableExtraSessions:CorePreferences:requestableSessions')
      }
      variant="small"
      noBorder
    >
      <StyledTableWrapper>
        <InfiniteScroll
          dataLength={sessions?.length || 0}
          hasMore={page < pageCount}
          isFetching={isSessionsFetching}
          next={() => onPageChange((+page) + 1)}
          scrollableTarget="requestableSessionsScrollableTarget"
          lightSpinner
        >
          <Table
            columns={COLUMNS}
            sortField={sortField}
            sortOrder={sortOrder}
            visualType="transparent"
            disableSticky
            noMargin
            onSortChange={onSortChange}
          >
            <tbody>
              <FieldArray
                component={renderRows}
                name="requestableSessions"
                props={{
                  onSessionClick,
                }}
                rerenderOnEveryChange
              />
            </tbody>
          </Table>
        </InfiniteScroll>
      </StyledTableWrapper>
    </Section>
  )

  const renderAdditionalPreferences = () => (
    <Section
      title={
        i18n.t('module:Management:ParentApp:RequestableExtraSessions:CorePreferences:additionalPreferences')
      }
      variant="small"
      noBorder
    >
      <Form.Row
        label={i18n.t('module:Management:ParentApp:RequestableExtraSessions:CorePreferences:MinRequestNotice:label')}
        tooltip={{ title: i18n.t(
          'module:Management:ParentApp:RequestableExtraSessions:CorePreferences:MinRequestNotice:tooltip',
        ) }}
        verticalLabel
      >
        <Form.Row.FlexItem flex="0 0 150px">
          <Field
            component={Form.TextField}
            min={0}
            name="minRequestNoticeDays"
            placeholder="0"
            suffix={i18n.t('global:Days')}
            suffixWidth="45px"
            type="number"
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={
          i18n.t('module:Management:ParentApp:RequestableExtraSessions:CorePreferences:MaxAdvanceRequests:label')
        }
        tooltip={{ title: i18n.t(
          'module:Management:ParentApp:RequestableExtraSessions:CorePreferences:MaxAdvanceRequests:tooltip',
        ) }}
        verticalLabel
      >
        <Form.Row.FlexItem flex="0 0 150px">
          <Field
            component={Form.TextField}
            min={0}
            name="maxAdvanceRequestsDays"
            placeholder="90"
            suffix={i18n.t('global:Days')}
            suffixWidth="45px"
            type="number"
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Management:ParentApp:RequestableExtraSessions:CorePreferences:includeChildrensHolidays')}
        verticalLabel
      >
        <Form.Row.FlexItem>
          <Field
            component={Form.Switch}
            name="holidaysAndSickLeaveAsAvailableSpaces"
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Management:ParentApp:RequestableExtraSessions:CorePreferences:ParentsCanOnly:label')}
        tooltip={{
          title: i18n.t(
            'module:Management:ParentApp:RequestableExtraSessions:CorePreferences:ParentsCanOnly:tooltip',
          ),
        }}
        verticalLabel
      >
        <Form.Row.FlexItem>
          <Field
            component={Form.Switch}
            name="notNeedAnAdditionalStaffMember"
          />
        </Form.Row.FlexItem>
      </Form.Row>
    </Section>
  )

  const renderForm = () => (
    <React.Fragment>
      {renderRequestableSessions()}
      <Space space="35px" />
      {renderAdditionalPreferences()}
    </React.Fragment>
  )

  const renderFooter = () => (
    <Form.StickyFooter
      cancelLink={generateRoute('MANAGEMENT.PARENT_APP.REQUESTABLE_EXTRA_SESSIONS')}
      isSubmitting={isSubmitting}
      submitLabel={i18n.t('global:Save')}
    />
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {renderForm()}
      {renderFooter()}
    </Form>
  )
}

export default reduxForm({ form: REQUESTABLE_EXTRA_SESSIONS_CORE_PREFERENCES_FORM })(CorePreferencesForm)
