import _ from 'lodash'

import React from 'react'

import { Typography } from 'components'

import { StyledDot, StyledSubject, StyledWrapper } from './NextStepsFrameworkSubjectStyled'

const NextStepsFrameworkSubject = ({ frameworkAreas }) => (!_.isEmpty(frameworkAreas) ? (
  <StyledWrapper>
    {_.map(frameworkAreas, (frameworkArea) => (
      <StyledSubject key={frameworkArea.id}>
        {frameworkArea?.colour && (
          <StyledDot background={frameworkArea.colour} />
        )}
        <Typography variant="span">
          {frameworkArea.name}
        </Typography>
      </StyledSubject>
    ))}
  </StyledWrapper>
) : null)

export default NextStepsFrameworkSubject
