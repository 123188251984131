import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { UPLOAD_DIRECTORY } from 'services/legacy/upload/constants'

import { withAppService } from 'services/app'
import { withInjuriesService } from 'services/legacy/injuries'
import { withUploadService } from 'services/legacy/upload'

import { INJURY_SIGNATURE_STEPS } from './injurySignatureModalConstants'
import InjurySignatureModalView from './InjurySignatureModalView'

const GROUPS = {
  read: [
    'injurySignature',
    'injurySignature.injury',
    'injurySignature.signature',
    'medium',
    'injurySignature.owner',
    'injurySignature.carer',
    'carer',
    'injured.child',
    'child',
    'injurySignature.injury',
    'injury',
    'injury.injurySignatureStatistics',
    'injured.firstAider',
    'user',
  ],
}

class InjurySignatureModalContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hideWaterMark: false,
      isSubmitting: false,
      signatureUploaded: false,
      stepIndex: 0,
    }
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleContinueClick = () => {
    this.setState((prevState) => ({ hideWaterMark: false, stepIndex: prevState.stepIndex + 1 }))
  }

  handleBackClick = () => {
    this.setState((prevState) => ({ hideWaterMark: false, stepIndex: prevState.stepIndex - 1 }))
  }

  handleAddSignatureSuccess = (response) => {
    const { onSuccess } = this.props

    this.setState({ isSubmitting: false, signatureUploaded: true })

    if (onSuccess) {
      onSuccess(response)
    }
  }

  handleAddSignatureFailed = () => {
    this.setState({
      hideWaterMark: false,
      isSubmitting: false,
      signatureUploaded: false,
    })
  }

  handleAddSignatureClick = async (file, signatureItem) => {
    const { injuriesActions, injuriesSelectors, injuryId, uploadActions } = this.props
    const { id, uid } = signatureItem

    this.setState({ isSubmitting: true })

    const signature = {
      mimeType: file.type,
      size: file.size,
      url: await uploadActions.uploadFile(file, file.name, UPLOAD_DIRECTORY.IMAGES),
    }

    const body = injuriesSelectors.getSignaturePayload({
      injuryId,
      signature,
      signatureItem,
    })

    const apiParams = {
      groups: GROUPS,
    }

    if (id) {
      return injuriesActions.updateSignature(id, {
        body,
        onFailed: this.handleAddSignatureFailed,
        onSuccess: this.handleAddSignatureSuccess,
        params: apiParams,
        updateToSignatureList: true,
      })
    }

    return injuriesActions.createSignature({
      body,
      onFailed: this.handleAddSignatureFailed,
      onSuccess: this.handleAddSignatureSuccess,
      params: apiParams,
      uid,
    })
  }

  handleHideWaterMark = (hideWaterMark) => {
    this.setState({ hideWaterMark })
  }

  render() {
    const { appSelectors, injuryPreview, signatureItem } = this.props
    const { hideWaterMark, isSubmitting, signatureUploaded, stepIndex } = this.state
    const errorMessage = appSelectors.getErrorMessage(signatureItem)

    return (
      <InjurySignatureModalView
        currentStep={INJURY_SIGNATURE_STEPS[stepIndex]}
        errorMessage={errorMessage}
        hideWaterMark={hideWaterMark}
        injuryPreview={injuryPreview}
        isSubmitting={isSubmitting}
        signatureItem={signatureItem}
        signatureUploaded={signatureUploaded}
        onAddSignatureClick={this.handleAddSignatureClick}
        onBackClick={this.handleBackClick}
        onCloseClick={this.handleCloseClick}
        onContinueClick={this.handleContinueClick}
        onHideWaterMark={this.handleHideWaterMark}
      />
    )
  }
}

const mapState = (state, { injuriesSelectors, signatureId, signatureUid }) => ({
  injuryPreview: injuriesSelectors.getPreview(state),
  signatureItem: injuriesSelectors.getSignatureItem(signatureId, signatureUid)(state),
})

const enhance = compose(
  withAppService,
  withInjuriesService,
  withUploadService,
  connect(mapState),
)

export default enhance(InjurySignatureModalContainer)
