import styled from 'styled-components'

import { CursorTypes } from 'constants/css'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

interface StyledContainerProps {
  $center?: boolean
  $cursor?: CursorTypes
}

export const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  align-items: center;
  
  ${({ $center }) => $center && `
    justify-content: center;
  `}
  
  ${({ $cursor }) => $cursor && `
    cursor: ${$cursor};
  `}
`

interface StyledIconContainerProps {
  $background?: string
  $roundedSquare?: boolean
  $secondary?: boolean
  $size: number
}

export const StyledIconContainer = styled.div<StyledIconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ $roundedSquare }) => ($roundedSquare ? '4px' : '50%')};

  ${({ $size }) => $size && `
    width: ${$size}px;
    height: ${$size}px;
  `}
  
  ${({ $background }) => $background && `
    background: ${$background}
  `};

  ${({ $secondary }) => $secondary && `
    background: ${getBrandingColor('secondary-color')};
  `};
  
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const StyledLabel = styled.div`
  font-weight: 600;
  margin-left: 10px;
`

interface StyledLetterProps {
  $color?: string
}

export const StyledLetter = styled.span<StyledLetterProps>`
  text-transform: uppercase;
  color: ${NEUTRAL_COLOURS.WHITE};
  font-weight: 600;
  text-align: center;
  font-size: 14px;
  
  ${({ $color }) => $color && `
    color: ${$color};
  `};
`
