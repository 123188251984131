import { compose } from 'recompose'

import { Component } from 'react'

import { generateRoute } from 'utils/routing'

import { withRouter } from 'services/router'

class ChildSessionsWrapperContainer extends Component {
  constructor(props) {
    super(props)

    this.indexRedirect(props)
  }

  shouldComponentUpdate(nextProps) {
    this.indexRedirect(nextProps)

    return true
  }

  indexRedirect = (props) => {
    const { location: { pathname }, navigate, params: { childId } } = props

    if (generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS', { childId }) === pathname) {
      navigate(generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS.VIEW', { childId }))
    }
  }

  render() {
    const { children } = this.props

    return children
  }
}

ChildSessionsWrapperContainer.authParams = { allowFinanceF3: false }

const enhance = compose(
  withRouter,
)

export default enhance(ChildSessionsWrapperContainer)
