import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'
import layout from 'constants/layout'

export const StyledHeaderWrapper = styled.div`
  background-color: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
`

export const StyledHeaderContentWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 12%;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    padding: 0;
  }
`

export const StyledNurseryLogoWrapper = styled.div`
  width: 70px;
  min-width: 70px;
  height: 70px;
  background: ${NEUTRAL_COLOURS.WHITE};
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    object-fit: contain;
  }
`

export const StyledNurseryName = styled.p`
  font-size: 26px;
  font-weight: 600;
  margin: 0 0 4px;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    font-size: 16px;
  }
`

export const StyledNurseryContactDetails = styled.p`
  font-size: 16px;
  margin: 0;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    font-size: 14px;
  }
`

export const StyledHeaderNurseryInfoWrapper = styled.div`
  margin: 0 0 0 20px;

  & ${StyledNurseryContactDetails} ~ ${StyledNurseryContactDetails} {
    margin-top: 4px;
  }
`

