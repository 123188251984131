import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as supportedCurrencySelectors from './selectors'

const withSupportedCurrencyService = (WrappedComponent) => {
  const mapState = ({ supportedCurrency }) => ({ supportedCurrency })

  const mapDispatch = injectActions('supportedCurrencyActions', actions)

  const Component = ({ supportedCurrency, supportedCurrencyActions, ...others }) => (
    <WrappedComponent
      supportedCurrencyActions={supportedCurrencyActions}
      supportedCurrencyListState={supportedCurrency.list}
      supportedCurrencySelectors={supportedCurrencySelectors}
      {...others}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withSupportedCurrencyService
