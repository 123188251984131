import _ from 'lodash'

import React, { PropsWithChildren, useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'

import { RootState } from 'core/reducers'

import { Button, EmptyState, LayoutContainer } from 'components'
import Breadcrumb from 'module/Shell/components/Breadcrumb'

import { withShellService, withShellServiceProps } from 'services/shell'

import i18n from 'translations'

import PageBottomBar from './PageBottomBar'
import PageContent from './PageContent'
import PageSection from './PageSection'
import {
  StyledBreadcrumb,
  StyledContainer,
  StyledContentContainer,
  StyledExpandPageButtonContainer,
} from './PageStyled'

interface PageRoot<T> extends React.FC<T> {
  BottomBar?: typeof PageBottomBar
  Content?: typeof PageContent
  Section?: typeof PageSection
}

export interface PageProps {
  alwaysExpanded?: boolean
  children: (button: React.ReactNode, expanded: boolean) => React.ReactNode
  disableBreadcrumb?: boolean
  isLoading?: boolean
}

type PageServiceProps = withShellServiceProps

const mapState = (state: RootState, { shellSelectors }: PageServiceProps) => ({
  subMenu: shellSelectors.getSubMenu(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type PageInnerProps = PropsFromRedux & PageServiceProps

type PageOuterProps = PropsWithChildren<PageProps>

const Page: PageRoot<PageInnerProps & PageOuterProps> = ({
  alwaysExpanded,
  children,
  disableBreadcrumb,
  isLoading,
  subMenu,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const [isOffline, setIsOffline] = useState<boolean>(false)

  const setAsOffline = () => setIsOffline(true)
  const setAsOnline = () => setIsOffline(false)

  useEffect(() => {
    if (!navigator.onLine) {
      setIsOffline(true)
    }

    window.addEventListener('offline', setAsOffline)
    window.addEventListener('online', setAsOnline)

    return () => {
      window.removeEventListener('offline', setAsOffline)
      window.removeEventListener('online', setAsOnline)
    }
  }, [])

  const renderExpandPageButton = () => {
    const label = expanded ? i18n.t('components:Page:defaultView') : i18n.t('components:Page:expandView')

    return (
      <StyledExpandPageButtonContainer>
        <Button
          hierarchy="tertiary"
          icon="table"
          label={label}
          negativeMargins
          onClick={() => setExpanded(!expanded)}
        />
      </StyledExpandPageButtonContainer>
    )
  }

  const renderChildren = () => {
    if (isLoading && isOffline) {
      return (
        <EmptyState
          icon="offline"
          text1={i18n.t('global:youAreOffline')}
        />
      )
    }

    if (_.isFunction(children)) {
      const expandPageButton = renderExpandPageButton()

      return children(expandPageButton, expanded)
    }

    return children
  }

  return (
    <StyledContainer
      $alwaysExpanded={alwaysExpanded}
      $expanded={expanded}
      $subMenu={!!subMenu?.length}
    >
      <LayoutContainer expanded={alwaysExpanded || expanded}>
        <StyledContentContainer>
          <StyledBreadcrumb>
            {('storybook' !== process.env.NODE_ENV && !disableBreadcrumb) && (
              <Breadcrumb />
            )}
          </StyledBreadcrumb>
          {renderChildren()}
        </StyledContentContainer>
      </LayoutContainer>
    </StyledContainer>
  )
}

const enhance = compose<PageOuterProps, Omit<PageOuterProps, keyof PageInnerProps>>(
  withShellService,
  connector,
)

export default enhance(Page)
