import { generateBasicActions } from 'utils/service'

import {
  CLEAR_CHILD_CHANGELOGS,
  GET_CHILD_CHANGELOGS,
  GET_CHILD_CHANGELOGS_FAILED,
  GET_CHILD_CHANGELOGS_SUCCESS,
} from './constants'

export const getChildChangelogs = (options = {}) => ({ childApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_CHILD_CHANGELOGS_FAILED,
    init: GET_CHILD_CHANGELOGS,
    success: GET_CHILD_CHANGELOGS_SUCCESS,
  },
  options,
  service: childApiClient,
  serviceMethod: 'listChildChangelogs',
})

export const clearChildChangelogs = () => ({
  type: CLEAR_CHILD_CHANGELOGS,
})
