import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class FrameworksApiClient extends ServiceBase {
  list = (options = {}) => this.request({
    path: `/v2/frameworks?${getQueryStringV2(options)}`,
  })

  legacyList = (options = {}) => this.request({
    path: `/v2/legacy-frameworks?${getQueryStringV2(options)}`,
  })

  get = (id, options = {}) => this.request({
    path: `/v2/frameworks/${id}?${getQueryStringV2(options)}`,
  })

  getAgeBands = (id, options = {}) => this.request({
    path: `/v2/frameworks/${id}/age-bands?${getQueryStringV2(options)}`,
  })
}

export default FrameworksApiClient
