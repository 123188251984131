import _ from 'lodash'
import moment from 'moment'
import { createSelector } from 'reselect'

import React from 'react'

import { toInt } from 'utils/data'
import { generateRoute } from 'utils/routing'

import { Button, CircleIcon, Currency } from 'components'
import colors from 'constants/colors'

import { TYPES } from '../constants'
import { NurseryFunding, NurseryFundingRootState } from '../models'

export const getNurseryFundingSelectors = (state: NurseryFundingRootState) => state.nurseryFunding

export const getNurseryFundingListDataSelectors = createSelector(
  [getNurseryFundingSelectors],
  (state) => state.list.data,
)

export const getNurseryFundingListMetaSelectors = createSelector(
  [getNurseryFundingSelectors],
  (state) => state.list.meta,
)

export const getNurseryFundingListTableDataSelectors = createSelector(
  [getNurseryFundingListDataSelectors],
  (listData) => {
    if (!listData || !listData.length) {
      return null
    }

    return _.map(listData, (item) => {
      const {
        archived,
        endDate,
        fundingType: { name: fundingName },
        hourlyRate,
        id,
        label,
        startDate,
        total,
        totalHours,
        type,
      } = item

      const deductedFromInvoice = type !== TYPES.NO_DEDUCT

      return {
        deductedFromInvoice:
          deductedFromInvoice
            ? <CircleIcon background={colors.pink} icon="deductible-tag" size={40} center />
            : null,
        fundingType: `${fundingName}${archived ? ' (Archived)' : ''}`,
        hourlyRate: hourlyRate ? <Currency value={hourlyRate} /> : null,
        id,
        label,
        period: `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`,
        rowToButton: (
          <Button.TableAction
            to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.FUNDING.VIEW', { nurseryFundingId: id })}
          />
        ),
        total: <Currency value={total} />,
        totalHours: toInt(totalHours) || 0,
      }
    })
  },
)

export const getNurseryFundingListForReportTableDataSelectors = createSelector(
  [getNurseryFundingListDataSelectors],
  (listData) => {
    if (!listData || !listData.length) {
      return null
    }

    return _.map(listData, (item) => {
      const {
        archived,
        endDate,
        fundingType: { name: fundingName },
        hourlyRate,
        id,
        label,
        startDate,
        total,
        totalHours,
        type,
      } = item

      const deductedFromInvoice = type !== TYPES.NO_DEDUCT

      return {
        deductedFromInvoice:
          deductedFromInvoice
            ? <CircleIcon background={colors.pink} icon="deductible-tag" size={40} center />
            : null,
        fundingType: `${fundingName}${archived ? ' (Archived)' : ''}`,
        hourlyRate: hourlyRate ? <Currency value={hourlyRate} /> : null,
        id,
        label,
        period: `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`,
        total: <Currency value={total} />,
        totalHours: toInt(totalHours) || 0,
      }
    })
  },
)

export const getFundingOption = ({
  archived,
  endDate,
  fundingType,
  hourlyRate,
  id,
  label,
  settings,
  startDate,
  total,
  totalHours,
  type,
}: NurseryFunding) => {
  const { code, name } = fundingType || {}
  const { excludedPeriods, hoursPerWeek, maxHoursPerDay } = settings || {}

  return {
    archived,
    code,
    endDate,
    excludedPeriods,
    hourlyRate,
    hoursPerWeek,
    label: `${label} - ${name}`,
    maxHoursPerDay,
    startDate,
    total,
    totalHours,
    type,
    value: id,
  }
}

export const getNurseryFundingTypesOptionsSelectors = createSelector(
  [getNurseryFundingListDataSelectors],
  (listData) => {
    if (!listData || !listData.length) {
      return null
    }

    return _.map(listData, (item) => getFundingOption(item))
  },
)

export const hasNurseryLevelManualFunding = createSelector(
  [getNurseryFundingListMetaSelectors],
  (metaData) => {
    if (!metaData) {
      return false
    }

    return !!metaData.total_results
  },
)
