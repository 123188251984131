import React from 'react'
import { Field } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { Banner, Form } from 'components'

const DiscountFormFields = ({ discountTypeOptions, formValues, isEdit, isPercentageSelected }) => (
  <React.Fragment>
    <Form.Row label="Description">
      <Form.Row.FlexItem flex="0 0 250px">
        <Field
          component={Form.TextField}
          name="description"
          placeholder="Description"
          validate={isRequired}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.Row label="Total discount">
      <Form.Row.FlexItem>
        <Field
          component={Form.RadioGroup}
          disabled={isEdit}
          name="type"
          options={discountTypeOptions}
          validate={isRequired}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.Row label>
      <Form.Row.FlexItem flex="0 0 120px">
        <Field
          component={Form.TextField}
          name="value"
          placeholder={!isPercentageSelected ? '0.00' : '%'}
          step="any"
          type="number"
          validate={isRequired}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    {isPercentageSelected && (
      <React.Fragment>
        <Form.Row autoHeight label>
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              label="Normal sessions"
              name="sessions"
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row autoHeight label>
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              label="Extra sessions"
              name="extraSessions"
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row autoHeight label>
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              label="Extra items"
              name="extraItems"
            />
          </Form.Row.Item>
        </Form.Row>
        {formValues && formValues.sessions && (
          <Form.Row autoHeight label>
            <Form.Row.Item>
              <Field
                component={Form.Checkbox}
                label="Consumables added to sessions"
                name="consumables"
              />
            </Form.Row.Item>
          </Form.Row>
        )}
      </React.Fragment>
    )}
    {!isPercentageSelected && (
      <Form.Row label>
        <Form.Row.Item>
          <Banner.Info>
            The discount entered will be deducted from the invoice total.
            <br />
            {
            'However, the amount being subtracted cannot be more than the invoice total'
              .concat('and must be manually edited on the invoice.')
          }
          </Banner.Info>
        </Form.Row.Item>
      </Form.Row>
    )}
  </React.Fragment>
)

export default DiscountFormFields
