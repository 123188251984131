import { AlertModalProps } from 'modals/AlertModal/AlertModal'
import { ConfirmModalProps } from 'modals/ConfirmModal/ConfirmModal'
import { ExportReportModalProps } from 'module/Modals/ExportReportModal/ExportReportModalContainerModel'
import {
  AccessToLikesAndCommentsModalProps,
} from 'modals/AccessToLikesAndCommentsModal/AccessToLikesAndCommentsModalContainer'
import {
  AddSessionToRegularBookingsModalProps,
} from 'modals/RegularBookings/AddSessionToRegularBookingsModal/AddSessionToRegularBookingsModalContainer'
import {
  AddRegularItemsToRegularBookingsModalProps,
} from 'modals/RegularBookings/AddRegularItemsToRegularBookingsModal/AddRegularItemsToRegularBookingsModalContainer'
import {
  AddFundingToRegularBookingsModalProps,
} from 'modals/RegularBookings/AddFundingToRegularBookingsModal/AddFundingToRegularBookingsModalContainer'
import {
  AddDiscountToRegularBookingsModalProps,
} from 'modals/RegularBookings/AddDiscountToRegularBookingsModal/AddDiscountToRegularBookingsModalContainer'
import { FundingLoopExportModalProps } from 'modals/FundingLoopExportModal/FundingLoopExportModalContainer'

import ActivitiesStatisticsModal from 'module/Modals/ActivitiesStatisticsModal'
import AddNewRecipientsModal from 'module/Modals/AddNewRecipientsModal'
import AlertModal from 'modals/AlertModal'
import AssignStaffMembersModal from 'module/Modals/AssignStaffMembersModal'
import ConversationMembersModal from 'module/Modals/Messaging/ConversationMembersModal'
import BadDebtModal from 'module/Modals/BadDebtModal'
import BalanceAdjustmentModal from 'module/Modals/BalanceAdjustmentModal'
import DailyDiaryRecordModal from 'module/Modals/DailyDiaryRecordModal'
import ChildLeaveModal from 'module/Modals/ChildLeaveModal'
import DepositsExportModal from 'module/Modals/DepositsExportModal'
import ChildPickerModal from 'module/Modals/ChildPickerModal'
import ChildrenExportModal from 'module/Modals/ChildrenExportModal'
import DailyDiaryModal from 'module/Modals/DailyDiaryModal'
import DepositRefundModal from 'module/Modals/DepositRefundModal'
import DownloadRegisterModal from 'module/Modals/DownloadRegisterModal'
import DietaryReportExportModal from 'module/Modals/DietaryReportExportModal'
import EditRoomMoveModal from 'module/Modals/EditRoomMoveModal'
import EmergencyContactListsModal from 'module/Modals/EmergencyContactListsModal'
import EnrolChildModal from 'module/Modals/Enquiry/EnrolChildModal'
import ViewingEditModal from 'module/Modals/Enquiry/ViewingEditModal'
import EnquiryExportModal from 'module/Modals/Enquiry/ExportModal'
import StatusChangeCommentModal from 'module/Modals/Enquiry/StatusChangeCommentModal'
import StatusTypeMigrationModal from 'module/Modals/Enquiry/StatusTypeMigrationModal'
import ExportReportModal from 'module/Modals/ExportReportModal'
import FileDetailsModal from 'module/Modals/FileDetailsModal'
import FormativeReportsExportModal from 'module/Modals/FormativeReportsExportModal'
import FormativeReportsSendModal from 'module/Modals/FormativeReportsSendModal'
import FrameworkSelectProgressModal from 'module/Modals/FrameworkSelectProgressModal'
import HelpModal from 'module/Modals/HelpModal'
import IssueCreditNoteModal from 'module/Modals/IssueCreditNoteModal'
import MediaTagModal from 'module/Modals/MediaTagModal'
import ObservationExportModal from 'module/Modals/ObservationsExportModal'
import PlanDetailsModal from 'module/Modals/PlanDetailsModal'
import PreviewInvoiceDeductionModal from 'module/Modals/PreviewInvoiceDeductionModal'
import PreviewLineItemModal from 'module/Modals/PreviewLineItemModal'
import RegisterExtraSessionModal from 'module/Modals/RegisterExtraSessionModal'
import RegisterResetModal from 'module/Modals/RegisterResetModal'
import RegisterStaffLogModal from 'module/Register/components/RegisterStaffLogModal'
import RequestedExtraSessionModal from 'module/Modals/RequestedExtraSessionModal'
import TermsUpdateModal from 'module/Modals/TermsUpdateModal'
import InvoiceAddModal from 'module/Modals/Invoice/AddModal'
import InvoiceAddInvoiceItemModal from 'module/Modals/Invoice/AddInvoiceItemModal'
import InvoiceAddInvoiceItemModalV3 from 'modals/Invoice/AddInvoiceItemModalV3'
import InvoiceDetailPreviewModal from 'module/Modals/Invoice/DetailPreviewModal'
import InvoiceExportModal from 'module/Modals/Invoice/ExportModal'
import FundingLoopExportModal from 'modals/FundingLoopExportModal'
import InvoicePreviewModal from 'module/Modals/Invoice/PreviewModal'
import FrameworkBulkMarkModal from 'module/Modals/FrameworkBulkMarkModal'
import FundingModal from 'module/Modals/FundingModal'
import InjurySignatureModal from 'module/Modals/InjurySignatureModal'
import SendDraftInvoiceModal from 'module/Modals/SendDraftInvoiceModal'
import SelectFormatModal from 'module/Modals/SelectFormatModal'
import SessionsetImpactModal from 'module/Modals/SessionSetImpactModal'
import SessionTimeoutModal from 'module/Modals/SessionTimeoutModal'
import StaffWorklogAddTimeModal from 'module/Modals/Staff/Worklog/AddTimeModal'
import StaffChangeLogModal from 'module/Modals/Staff/ChangeLogModal'
import StaffWorklogChangeLogModal from 'module/Modals/Staff/Worklog/ChangeLogLegacyModal'
import StaffWorklogEndTimeModal from 'module/Modals/Staff/Worklog/EndTimeModal'
import StaffWorklogExportModal from 'module/Modals/Staff/Worklog/ExportModal'
import StaffRotaChildrenExpectedModal from 'module/Modals/Staff/Rota/ChildrenExpectedModal'
import StaffRotaExportModal from 'module/Modals/Staff/Rota/ExportModal'
import StaffRotaShiftModal from 'module/Modals/Staff/ShiftModal'
import StaffRotaShiftCopyModal from 'module/Modals/Staff/Rota/ShiftCopyModal'
import StaffLeaveAddModal from 'module/Modals/Staff/Leave/AddModal'
import StaffLeaveExportModal from 'module/Modals/Staff/Leave/ExportModal'
import StripeDisconnectConditionsModal from 'module/Modals/StripeDisconnectConditionsModal'
import EnterStaffRegisterModal from 'module/Modals/EnterStaffRegisterModal'
import ExceptionalUnavailableTimesModal from 'module/Modals/RequestableExtraSessions/ExceptionalUnavailableTimesModal'
import RegularUnavailableTimesModal from 'module/Modals/RequestableExtraSessions/RegularUnavailableTimesModal'

import AddDiscountToRegularBookingsModal from 'modals/RegularBookings/AddDiscountToRegularBookingsModal'
import AddFundingToRegularBookingsModal from 'modals/RegularBookings/AddFundingToRegularBookingsModal'
import AddSessionToRegularBookingsModal from 'modals/RegularBookings/AddSessionToRegularBookingsModal'
import AddRegularItemsToRegularBookingsModal from 'modals/RegularBookings/AddRegularItemsToRegularBookingsModal'
import AccessToLikesAndCommentsModal from 'modals/AccessToLikesAndCommentsModal'
import ConfirmModal from 'modals/ConfirmModal'
import PremiumPlanModal from 'modals/PremiumPlanModal'

export enum ModalType {
  ACCESS_TO_LIKES_AND_COMMENTS = 'ACCESS_TO_LIKES_AND_COMMENTS',
  ACTIVITIES_STATISTICS = 'ACTIVITIES_STATISTICS',
  ADD_DISCOUNT_TO_REGULAR_BOOKINGS = 'ADD_DISCOUNT_TO_REGULAR_BOOKINGS',
  ADD_FUNDING_TO_REGULAR_BOOKINGS = 'ADD_FUNDING_TO_REGULAR_BOOKINGS',
  ADD_RECIPIENTS = 'ADD_RECIPIENTS',
  ADD_REGULAR_ITEMS_TO_REGULAR_BOOKINGS = 'ADD_REGULAR_ITEMS_TO_REGULAR_BOOKINGS',
  ADD_SESSION_TO_REGULAR_BOOKINGS = 'ADD_SESSION_TO_REGULAR_BOOKINGS',
  ALERT = 'ALERT',
  ASSIGN_STAFF_MEMBERS = 'ASSIGN_STAFF_MEMBERS',
  BAD_DEBT = 'BAD_DEBT',
  BALANCE_ADJUSTMENT = 'BALANCE_ADJUSTMENT',
  CHILDREN_EXPORT = 'CHILDREN_EXPORT',
  CHILD_LEAVE = 'CHILD_LEAVE',
  CHILD_PICKER = 'CHILD_PICKER',
  CONFIRM = 'CONFIRM',
  CONVERSATION_MEMBERS = 'CONVERSATION_MEMBERS',
  DAILY_DIARY = 'DAILY_DIARY',
  DAILY_DIARY_RECORD = 'DAILY_DIARY_RECORD',
  DEPOSITS_EXPORT = 'DEPOSIT_EXPORT',
  DEPOSIT_REFUND = 'DEPOSIT_REFUND',
  DIETARY_REPORT_EXPORT = 'DIETARY_REPORT_EXPORT',
  DOWNLOAD_REGISTER = 'DOWNLOAD_REGISTER',
  EDIT_ROOM_MOVE = 'EDIT_ROOM_MOVE',
  EMERGENCY_CONTACT_LISTS = 'EMERGENCY_CONTACT_LISTS',
  ENQUIRY_ENROL_CHILD = 'ENQUIRY_ENROL_CHILD',
  ENQUIRY_EXPORT = 'ENQUIRY_EXPORT',
  ENQUIRY_STATUS_CHANGE = 'ENQUIRY_STATUS_CHANGE',
  ENQUIRY_STATUS_TYPE_MIGRATION = 'ENQUIRY_STATUS_TYPE_MIGRATION',
  ENQUIRY_VIEWING_EDIT = 'ENQUIRY_VIEWING_EDIT',
  ENTER_STAFF_REGISTER = 'ENTER_STAFF_REGISTER',
  EXCEPTIONAL_UNAVAILABLE_TIMES = 'EXCEPTIONAL_UNAVAILABLE_TIMES',
  EXPORT_REPORT = 'EXPORT_REPORT',
  FILE_DETAILS = 'FILE_DETAILS',
  FORMATIVE_REPORTS_EXPORT = 'FORMATIVE_REPORTS_EXPORT',
  FORMATIVE_REPORTS_SEND = 'FORMATIVE_REPORTS_SEND',
  FRAMEWORK_BULK_MARK = 'FRAMEWORK_BULK_MARK',
  FRAMEWORK_SELECT_PROGRESS = 'FRAMEWORK_SELECT_PROGRESS',
  FUNDING = 'FUNDING',
  FUNDING_LOOP_EXPORT = 'FUNDING_LOOP_EXPORT',
  HELP = 'HELP',
  INJURY_SIGNATURE = 'INJURY_SIGNATURE',
  INVOICE_ADD = 'INVOICE_ADD',
  INVOICE_ADD_ITEM = 'INVOICE_ADD_ITEM',
  INVOICE_ADD_ITEM_V3 = 'INVOICE_ADD_ITEM_V3',
  INVOICE_DETAIL_PREVIEW = 'INVOICE__DETAIL_PREVIEW',
  INVOICE_EXPORT = 'INVOICE_EXPORT',
  INVOICE_PREVIEW = 'INVOICE_PREVIEW',
  ISSUE_CREDIT_NOTE = 'ISSUE_CREDIT_NOTE',
  MEDIA_TAG = 'MEDIA_TAG',
  OBSERVATIONS_EXPORT = 'OBSERVATIONS_EXPORT',
  PLAN_DETAILS = 'PLAN_DETAILS',
  PREMIUM_PLAN = 'PREMIUM_PLAN',
  PREVIEW_INVOICE_DEDUCTION = 'PREVIEW_INVOICE_DEDUCTION',
  PREVIEW_LINE_ITEM = 'PREVIEW_LINE_ITEM',
  REGISTER_EXTRA_SESSION = 'REGISTER_EXTRA_SESSION',
  REGISTER_RESET = 'REGISTER_RESET',
  REGISTER_STAFF_LOG = 'REGISTER_STAFF_LOG',
  REGULAR_UNAVAILABLE_TIMES = 'REGULAR_UNAVAILABLE_TIMES',
  REQUESTED_EXTRA_SESSION = 'REQUESTED_EXTRA_SESSION',
  SELECT_FORMAT = 'SELECT_FORMAT',
  SEND_DRAFT_INVOICE = 'SEND_DRAFT_INVOICE',
  SESSION_SET_IMPACT = 'SESSION_SET_IMPACT',
  SESSION_TIMEOUT = 'SESSION_TIMEOUT',
  STAFF_CHANGE_LOG = 'STAFF_CHANGE_LOG',
  STAFF_LEAVE_ADD = 'STAFF_LEAVE_ADD',
  STAFF_LEAVE_EXPORT = 'STAFF_LEAVE_EXPORT',
  STAFF_LEGACY_CHANGE_LOG = 'STAFF_LEGACY_CHANGE_LOG',
  STAFF_ROTA_CHILDREN_EXPECTED = 'STAFF_ROTA_CHILDREN_EXPECTED',
  STAFF_ROTA_EXPORT = 'STAFF_ROTA_EXPORT',
  STAFF_ROTA_SHIFT = 'STAFF_ROTA_SHIFT',
  STAFF_ROTA_SHIFT_COPY = 'STAFF_ROTA_SHIFT_COPY',
  STAFF_WORKLOG_ADD_TIME = 'STAFF_WORKLOG_ADD_TIME',
  STAFF_WORKLOG_END_TIME = 'STAFF_WORKLOG_END_TIME',
  STAFF_WORKLOG_EXPORT = 'STAFF_WORKLOG_EXPORT',
  STRIPE_DISCONNECT_CONDITIONS = 'STRIPE_DISCONNECT_CONDITIONS',
  TERMS_UPDATE = 'TERMS_UPDATE',
}

export const MODAL_COMPONENTS = {
  [ModalType.ACCESS_TO_LIKES_AND_COMMENTS]: AccessToLikesAndCommentsModal,
  [ModalType.ADD_DISCOUNT_TO_REGULAR_BOOKINGS]: AddDiscountToRegularBookingsModal,
  [ModalType.ADD_FUNDING_TO_REGULAR_BOOKINGS]: AddFundingToRegularBookingsModal,
  [ModalType.ADD_REGULAR_ITEMS_TO_REGULAR_BOOKINGS]: AddRegularItemsToRegularBookingsModal,
  [ModalType.ADD_SESSION_TO_REGULAR_BOOKINGS]: AddSessionToRegularBookingsModal,
  [ModalType.ACTIVITIES_STATISTICS]: ActivitiesStatisticsModal,
  [ModalType.ADD_RECIPIENTS]: AddNewRecipientsModal,
  [ModalType.ALERT]: AlertModal,
  [ModalType.ASSIGN_STAFF_MEMBERS]: AssignStaffMembersModal,
  [ModalType.BAD_DEBT]: BadDebtModal,
  [ModalType.BALANCE_ADJUSTMENT]: BalanceAdjustmentModal,
  [ModalType.CHILD_LEAVE]: ChildLeaveModal,
  [ModalType.CHILD_PICKER]: ChildPickerModal,
  [ModalType.CHILDREN_EXPORT]: ChildrenExportModal,
  [ModalType.CONFIRM]: ConfirmModal,
  [ModalType.CONVERSATION_MEMBERS]: ConversationMembersModal,
  [ModalType.DAILY_DIARY]: DailyDiaryModal,
  [ModalType.DAILY_DIARY_RECORD]: DailyDiaryRecordModal,
  [ModalType.DEPOSITS_EXPORT]: DepositsExportModal,
  [ModalType.DEPOSIT_REFUND]: DepositRefundModal,
  [ModalType.DIETARY_REPORT_EXPORT]: DietaryReportExportModal,
  [ModalType.DOWNLOAD_REGISTER]: DownloadRegisterModal,
  [ModalType.EDIT_ROOM_MOVE]: EditRoomMoveModal,
  [ModalType.EMERGENCY_CONTACT_LISTS]: EmergencyContactListsModal,
  [ModalType.ENQUIRY_ENROL_CHILD]: EnrolChildModal,
  [ModalType.ENQUIRY_VIEWING_EDIT]: ViewingEditModal,
  [ModalType.ENQUIRY_EXPORT]: EnquiryExportModal,
  [ModalType.ENQUIRY_STATUS_CHANGE]: StatusChangeCommentModal,
  [ModalType.ENQUIRY_STATUS_TYPE_MIGRATION]: StatusTypeMigrationModal,
  [ModalType.ENTER_STAFF_REGISTER]: EnterStaffRegisterModal,
  [ModalType.EXCEPTIONAL_UNAVAILABLE_TIMES]: ExceptionalUnavailableTimesModal,
  [ModalType.EXPORT_REPORT]: ExportReportModal,
  [ModalType.FILE_DETAILS]: FileDetailsModal,
  [ModalType.FORMATIVE_REPORTS_EXPORT]: FormativeReportsExportModal,
  [ModalType.FORMATIVE_REPORTS_SEND]: FormativeReportsSendModal,
  [ModalType.FRAMEWORK_BULK_MARK]: FrameworkBulkMarkModal,
  [ModalType.FRAMEWORK_SELECT_PROGRESS]: FrameworkSelectProgressModal,
  [ModalType.FUNDING]: FundingModal,
  [ModalType.HELP]: HelpModal,
  [ModalType.ISSUE_CREDIT_NOTE]: IssueCreditNoteModal,
  [ModalType.INVOICE_ADD]: InvoiceAddModal,
  [ModalType.INVOICE_ADD_ITEM]: InvoiceAddInvoiceItemModal,
  [ModalType.INVOICE_ADD_ITEM_V3]: InvoiceAddInvoiceItemModalV3,
  [ModalType.INVOICE_DETAIL_PREVIEW]: InvoiceDetailPreviewModal,
  [ModalType.MEDIA_TAG]: MediaTagModal,
  [ModalType.OBSERVATIONS_EXPORT]: ObservationExportModal,
  [ModalType.PLAN_DETAILS]: PlanDetailsModal,
  [ModalType.PREMIUM_PLAN]: PremiumPlanModal,
  [ModalType.PREVIEW_INVOICE_DEDUCTION]: PreviewInvoiceDeductionModal,
  [ModalType.PREVIEW_LINE_ITEM]: PreviewLineItemModal,
  [ModalType.REGISTER_EXTRA_SESSION]: RegisterExtraSessionModal,
  [ModalType.REGISTER_RESET]: RegisterResetModal,
  [ModalType.REGISTER_STAFF_LOG]: RegisterStaffLogModal,
  [ModalType.REGULAR_UNAVAILABLE_TIMES]: RegularUnavailableTimesModal,
  [ModalType.REQUESTED_EXTRA_SESSION]: RequestedExtraSessionModal,
  [ModalType.TERMS_UPDATE]: TermsUpdateModal,
  [ModalType.INVOICE_PREVIEW]: InvoicePreviewModal,
  [ModalType.INVOICE_EXPORT]: InvoiceExportModal,
  [ModalType.FUNDING_LOOP_EXPORT]: FundingLoopExportModal,
  [ModalType.SELECT_FORMAT]: SelectFormatModal,
  [ModalType.SESSION_SET_IMPACT]: SessionsetImpactModal,
  [ModalType.SESSION_TIMEOUT]: SessionTimeoutModal,
  [ModalType.STAFF_LEAVE_ADD]: StaffLeaveAddModal,
  [ModalType.STAFF_LEAVE_EXPORT]: StaffLeaveExportModal,
  [ModalType.STAFF_ROTA_CHILDREN_EXPECTED]: StaffRotaChildrenExpectedModal,
  [ModalType.STAFF_ROTA_EXPORT]: StaffRotaExportModal,
  [ModalType.STAFF_ROTA_SHIFT]: StaffRotaShiftModal,
  [ModalType.STAFF_ROTA_SHIFT_COPY]: StaffRotaShiftCopyModal,
  [ModalType.STAFF_WORKLOG_ADD_TIME]: StaffWorklogAddTimeModal,
  [ModalType.STAFF_CHANGE_LOG]: StaffChangeLogModal,
  [ModalType.STAFF_LEGACY_CHANGE_LOG]: StaffWorklogChangeLogModal,
  [ModalType.STAFF_WORKLOG_END_TIME]: StaffWorklogEndTimeModal,
  [ModalType.STAFF_WORKLOG_EXPORT]: StaffWorklogExportModal,
  [ModalType.INJURY_SIGNATURE]: InjurySignatureModal,
  [ModalType.SEND_DRAFT_INVOICE]: SendDraftInvoiceModal,
  [ModalType.STRIPE_DISCONNECT_CONDITIONS]: StripeDisconnectConditionsModal,
}

export interface BasicModalProps {
  hideModal?: () => void
  onRequestClose?: (next: () => {}) => {}
  zIndex?: number
}

export interface ModalProps {
  [ModalType.ALERT]: AlertModalProps
  [ModalType.ACCESS_TO_LIKES_AND_COMMENTS]: AccessToLikesAndCommentsModalProps
  [ModalType.ADD_DISCOUNT_TO_REGULAR_BOOKINGS]: AddDiscountToRegularBookingsModalProps
  [ModalType.ADD_FUNDING_TO_REGULAR_BOOKINGS]: AddFundingToRegularBookingsModalProps
  [ModalType.ADD_REGULAR_ITEMS_TO_REGULAR_BOOKINGS]: AddRegularItemsToRegularBookingsModalProps
  [ModalType.ADD_SESSION_TO_REGULAR_BOOKINGS]: AddSessionToRegularBookingsModalProps
  [ModalType.CONFIRM]: ConfirmModalProps
  [ModalType.EXPORT_REPORT]: ExportReportModalProps
  [ModalType.FUNDING_LOOP_EXPORT]: FundingLoopExportModalProps
}
