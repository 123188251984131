import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_NEXT_STEP,
  CREATE_NEXT_STEP,
  CREATE_NEXT_STEP_FAILED,
  CREATE_NEXT_STEP_SUCCESS,
  GET_NEXT_STEP,
  GET_NEXT_STEP_FAILED,
  GET_NEXT_STEP_SUCCESS,
  UPDATE_NEXT_STEP,
  UPDATE_NEXT_STEP_FAILED,
  UPDATE_NEXT_STEP_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_NEXT_STEP,
    createFailed: CREATE_NEXT_STEP_FAILED,
    createInit: CREATE_NEXT_STEP,
    createSuccess: CREATE_NEXT_STEP_SUCCESS,
    itemFailed: GET_NEXT_STEP_FAILED,
    itemInit: GET_NEXT_STEP,
    itemSuccess: GET_NEXT_STEP_SUCCESS,
    updateFailed: UPDATE_NEXT_STEP_FAILED,
    updateInit: UPDATE_NEXT_STEP,
    updateSuccess: UPDATE_NEXT_STEP_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
