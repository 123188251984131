import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import { Avatar, Button, Form } from 'components'

import { isRequiredForTrimmed } from 'utils/fieldValidation'

import i18n from 'translations'

import { StyledFormWrapper, StyledTextAreaField } from './CommentsFormStyled'

export const COMMENTS_FORM = 'CommentsForm'

interface CommentsFormProps {
  authUser: any
  isSubmitting?: boolean
  onSubmit: (values: CommentsFormValues) => void
}

type CommentsFormFullProps = CommentsFormProps & InjectedFormProps<{}, CommentsFormProps>

export interface CommentsFormValues {
  comment: string
}

const CommentsForm: React.FC<CommentsFormFullProps> = ({
  authUser,
  handleSubmit,
  isSubmitting,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <StyledFormWrapper>
      <Avatar avatarSize="medium" src={authUser.photo} />
      <StyledTextAreaField>
        <Field
          component={Form.TextAreaField}
          disabled={isSubmitting}
          name="comment"
          placeholder={i18n.t('module:LikesAndComments:writeNewComment')}
          validate={isRequiredForTrimmed}
          autosize
        />
      </StyledTextAreaField>
      <Button
        isLoading={isSubmitting}
        label={i18n.t('module:LikesAndComments:post')}
        submit
      />
    </StyledFormWrapper>
  </Form>
)

export default reduxForm<{}, CommentsFormProps>({})(CommentsForm)
