import React from 'react'

import { generateRoute } from 'utils/routing'

import { FolderList, Page, Section, Typography } from 'components'

import i18n from 'translations'

const ClosureDaysView = () => (
  <Page>
    <Page.Content>
      <Section
        title={i18n.t('module:Management:ClosureDays:title')}
      >
        <Typography margin="20px 0">
          {i18n.t('module:Management:ClosureDays:subtitle')}
        </Typography>
        <FolderList>
          <FolderList.Item
            icon="blocked"
            title={i18n.t('module:Management:ClosureDays:ClosureDayTypes:title')}
            to={generateRoute('MANAGEMENT.CLOSURE_DAYS.CLOSURE_DAY_TYPES')}
          />
          <FolderList.Item
            icon="calendar"
            title={i18n.t('module:Management:ClosureDays:ClosureDates:title')}
            to={generateRoute('MANAGEMENT.CLOSURE_DAYS.CLOSURE_DATES')}
          />
        </FolderList>
      </Section>
    </Page.Content>
  </Page>
)

export default ClosureDaysView
