import React from 'react'
import { ReactSVG } from 'react-svg'

import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'

import ModalRoot from 'module/Shell/components/ModalRoot'
import AlertModal from 'modals/AlertModal'

import { Typography } from 'components'

import i18n from 'translations'

// import { Spinner } from 'components'

import AlertBar from './components/AlertBar'
import TopBar from './components/TopBar'
import MenuBar from './components/MenuBar'
import Modal from './components/Modal'
import SnackbarRoot from './components/SnackbarRoot'
import NetworkConnectionBar from './components/NetworkConnectionBar'
import { StyledContainer, StyledMainHeader, StyledShellView } from './ShellStyled'

const ShellView = ({
  authUser,
  backButtonActive,
  backgroundFilter,
  children,
  currentRoute,
  dashboardIcon,
  dashboardTitle,
  hasVersionMismatch,
  isBranded,
  // isFinanceV3Enabled,
  isMessagingEnabled,
  // isNurseryContext,
  isOffline,
  logo,
  menuItems,
  menuItemsToShow,
  minimal,
  onBackButton,
  onBeBannerCloseClick,
  onChangeSidebar,
  onHelpClick,
  onLogout,
  onMaintenanceBannerCloseClick,
  onPin,
  onReload,
  onShowPremiumModal,
  onUnfix,
  onUnpin,
  showInfoBanner,
  showMaintenanceBanner,
  sidebarIsOpen,
  title,
}) => {
  // This comments will be removed after deployed APIs for Finance V3

  // if (isNurseryContext && authUser && (undefined === isFinanceV3Enabled || null === isFinanceV3Enabled)) {
  //   return (
  //     <StyledSpinnerWrapper>
  //       <Spinner />
  //     </StyledSpinnerWrapper>
  //   )
  // }

  const renderMainHeader = () => {
    if (currentRoute?.enableUnauthorizedMainHeader) {
      return (
        <StyledMainHeader>
          <TopBar
            authUser={authUser || {}}
            backButtonActive={false}
            dashboardIcon={dashboardIcon}
            dashboardTitle={dashboardTitle}
            isBranded={isBranded}
            isMessagingEnabled={isMessagingEnabled}
            isOffline={isOffline}
            logo={logo}
            logoutLabel="Exit register"
            sidebarIsOpen={sidebarIsOpen}
            title={title}
            hideProfile
            logoutWithHelp
            onBackButton={onBackButton}
            onChangeSidebar={onChangeSidebar}
            onHelpClick={onHelpClick}
            onLogout={onLogout}
          />
        </StyledMainHeader>
      )
    }

    if (currentRoute?.paymentsImportMainHeader) {
      return (
        <StyledMainHeader>
          <TopBar
            authUser={authUser || {}}
            backButtonActive={false}
            dashboardIcon={dashboardIcon}
            dashboardTitle={dashboardTitle}
            isBranded={isBranded}
            isMessagingEnabled={isMessagingEnabled}
            isOffline={isOffline}
            logo={logo}
            title={title}
            backToPayments
            hideProfile
          />
        </StyledMainHeader>
      )
    }

    if (!authUser) {
      return null
    }

    return (
      <StyledMainHeader>
        <TopBar
          authUser={authUser}
          backButtonActive={backButtonActive}
          dashboardIcon={dashboardIcon}
          dashboardTitle={dashboardTitle}
          isBranded={isBranded}
          isMessagingEnabled={isMessagingEnabled}
          isOffline={isOffline}
          logo={logo}
          sidebarIsOpen={sidebarIsOpen}
          title={title}
          logoutWithHelp
          onBackButton={onBackButton}
          onChangeSidebar={onChangeSidebar}
          onHelpClick={onHelpClick}
          onLogout={onLogout}
        />
        <MenuBar
          authUser={authUser}
          dashboardIcon={dashboardIcon}
          dashboardTitle={dashboardTitle}
          isOffline={isOffline}
          menuItems={menuItems}
          menuItemsToShow={menuItemsToShow}
          sidebarIsOpen={sidebarIsOpen}
          onChangeSidebar={onChangeSidebar}
          onHelpClick={onHelpClick}
          onLogout={onLogout}
          onPin={onPin}
          onShowPremiumModal={onShowPremiumModal}
          onUnfix={onUnfix}
          onUnpin={onUnpin}
        />
        {showInfoBanner && (
          <AlertBar
            icon="info"
            message={i18n.t('module:Shell:activeParentPay')}
            url="https://blossomeducational.com/features/mobile-payment/"
            onCloseClick={onBeBannerCloseClick}
          />
        )}
        {showMaintenanceBanner && (
          <AlertBar
            buttonColor={NEUTRAL_COLOURS.GRAY}
            color={FLAG_COLOURS.WARNING}
            iconComponent={(
              <ReactSVG src="/images/traffic-cone.svg" />
            )}
            message={(
              <React.Fragment>
                <Typography bold>{i18n.t('module:Shell:MaintenanceBar:title')}</Typography>
                {' '}
                {i18n.t('module:Shell:MaintenanceBar:message')}
              </React.Fragment>
            )}
            onCloseClick={onMaintenanceBannerCloseClick}
          />
        )}
      </StyledMainHeader>
    )
  }

  return (
    <StyledShellView backgroundFilter={backgroundFilter}>
      <ModalRoot />
      <SnackbarRoot />
      <NetworkConnectionBar />
      {!minimal && renderMainHeader()}
      <Modal open={hasVersionMismatch}>
        <AlertModal
          label="Reload"
          text="We have been blossoming. Reload to see all updates!"
          onClick={onReload}
        />
      </Modal>
      <StyledContainer>
        {children}
      </StyledContainer>
    </StyledShellView>
  )
}

export default ShellView
