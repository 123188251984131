import _ from 'lodash'

import { TableColumn } from 'components/Table/Table'

import i18n from 'translations'

export const NURSERY_TABLE_COLUMNS: TableColumn[] = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'name',
    title: _.upperFirst(i18n.t('global:child')),
  },
  {
    field: 'roomName',
    title: i18n.t('global:Room'),
  },
  {
    field: 'dob',
    title: i18n.t('global:dateOfBirthShort'),
  },
  {
    field: 'ageMonths',
    title: i18n.t('global:Age'),
  },
  {
    field: 'codeReference',
    title: i18n.t('global:Child:referenceCode'),
  },
  {
    field: 'registeredAt',
    title: i18n.t('global:Child:RegistrationDate'),
  },
  {
    field: 'startDate',
    title: i18n.t('global:Child:StartDate'),
  },
  {
    field: 'leavingDate',
    title: i18n.t('global:Child:LeaveDate'),
  },
  {
    field: 'fte',
    title: i18n.t('global:fte'),
  },
  {
    field: 'depositPaid',
    title: i18n.t('module:Reports:Common:depositPaid'),
  },
  {
    field: 'weeklyFee',
    title: i18n.t('module:Reports:Common:weeklyFee'),
  },
]

export const ORGANIZATION_TABLE_COLUMNS: TableColumn[] = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'name',
    title: _.upperFirst(i18n.t('global:site')),
  },
  {
    field: 'starters',
    title: i18n.t('module:Reports:ChildrenStarting:Organization:Table:Header:starters'),
  },
  {
    field: 'fte',
    title: i18n.t('global:fte'),
  },
  {
    field: 'weeklyFee',
    title: i18n.t('module:Reports:Common:weeklyFee'),
  },
]
