import { combineReducers } from 'redux'

import invitation from './resendInvitation/reducer'
import list from './list/reducer'
import organizationList from './organizationList/reducer'
import single from './single/reducer'
import files from './files/reducer'

export default combineReducers({
  files,
  invitation,
  list,
  organizationList,
  single,
})
