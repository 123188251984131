import { ThemeProvider } from '@material-ui/core/styles'

import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { CircleIcon, FieldError, Tooltip, Typography } from 'components'

import { StyledCheckbox, StyledContainer, StyledLabelContainer, StyledSpacer, theme } from './CheckboxStyled'

export interface CheckboxProps {
  centered?: boolean
  disabled?: boolean
  error?: string
  label?: string | React.ReactNode
  onChange: (value: boolean) => void
  padding?: string
  tooltip?: string
  value: boolean
}

const Checkbox: React.FC<CheckboxProps> = ({
  centered,
  disabled,
  error,
  label,
  onChange,
  padding,
  tooltip,
  value = false,
}) => (
  <ThemeProvider theme={theme}>
    <StyledContainer>
      <StyledLabelContainer $centered={centered} $disabled={disabled} $padding={padding}>
        <StyledCheckbox
          checked={value}
          color="primary"
          disabled={disabled}
          inputProps={{ 'aria-label': 'controlled' }}
          onChange={(event) => onChange(event.target.checked)}
        />
        {label && (
          <Typography
            color={disabled ? NEUTRAL_COLOURS.GRAY : undefined}
            variant="small"
            medium
          >
            {label}
          </Typography>
        )}
        {tooltip && (
          <React.Fragment>
            <StyledSpacer />
            <Tooltip
              placement="bottom-end"
              title={tooltip}
            >
              <CircleIcon
                cursor="pointer"
                icon="info"
                iconSize={16}
                size={24}
              />
            </Tooltip>
          </React.Fragment>
        )}
      </StyledLabelContainer>
      <FieldError error={error} />
    </StyledContainer>
  </ThemeProvider>
)

export default Checkbox
