import React, { PropsWithChildren } from 'react'

import DividedListRow from './DividedListRow'
import { StyledDividedList, StyledRow } from './DividedListStyled'

interface DividedListRoot<T> extends React.FC<PropsWithChildren<T>> {
  Row?: typeof DividedListRow
}

interface DividedListProps {
  title?: string
}

const DividedList: DividedListRoot<DividedListProps> = ({ children, title }) => (
  <StyledDividedList>
    {title && (
      <StyledRow $header>
        {title}
      </StyledRow>
    )}
    {children}
  </StyledDividedList>
)

export default DividedList
