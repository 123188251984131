import { createSelector } from 'reselect'

const getRegisterState = (state) => state.register

export const getRegisterGlobal = createSelector([getRegisterState], (state) => state?.global)

export const getRegisterGlobalList = createSelector([getRegisterGlobal], (state) => ({
  data: state?.data,
}))

export const getRegisterOfflineActions = createSelector([getRegisterGlobal], (state) => state.offlineActions)
