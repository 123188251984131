import { generateBasicActions } from 'utils/service'

import {
  ADD_MANUALLY_MEMBERSHIP_FILES,
  CLEAR_MEMBERSHIP_FILES_LIST,
  GET_MEMBERSHIP_FILES_LIST,
  GET_MEMBERSHIP_FILES_LIST_FAILED,
  GET_MEMBERSHIP_FILES_LIST_SUCCESS,
  GET_MEMBERSHIP_FILES_TOTAL_RESULTS_LIST,
  GET_MEMBERSHIP_FILES_TOTAL_RESULTS_LIST_FAILED,
  GET_MEMBERSHIP_FILES_TOTAL_RESULTS_LIST_SUCCESS,
  REMOVE_FILE_FROM_LIST,
} from './constants'

export const listMembershipFiles = (options = {}) => ({ membershipsApiClient }) => generateBasicActions.list({
  constants: {
    failed: GET_MEMBERSHIP_FILES_LIST_FAILED,
    init: GET_MEMBERSHIP_FILES_LIST,
    success: GET_MEMBERSHIP_FILES_LIST_SUCCESS,
  },
  options,
  service: membershipsApiClient,
  serviceMethod: 'listMembershipFiles',
})

export const listMembershipFilesForTotalResults = (options = {}) => ({
  membershipsApiClient,
}) => generateBasicActions.list({
  constants: {
    failed: GET_MEMBERSHIP_FILES_TOTAL_RESULTS_LIST_FAILED,
    init: GET_MEMBERSHIP_FILES_TOTAL_RESULTS_LIST,
    success: GET_MEMBERSHIP_FILES_TOTAL_RESULTS_LIST_SUCCESS,
  },
  options,
  service: membershipsApiClient,
  serviceMethod: 'listMembershipFiles',
})

export const clearFilesList = () => ({
  type: CLEAR_MEMBERSHIP_FILES_LIST,
})

export const addManuallyMembershipFiles = (files) => ({
  payload: files,
  type: ADD_MANUALLY_MEMBERSHIP_FILES,
})

export const removeFileFromList = (file) => ({
  payload: file,
  type: REMOVE_FILE_FROM_LIST,
})
