import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledComment = styled.div`
  word-break: normal;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  position: relative;
  padding-bottom: 8px;

  &:before {
    content: counter(next-step-counter) '. ';
    font-weight: 600;
    position: absolute;
    left: -20px;
  }
`

export const StyledNextStepWrapper = styled.div`
  counter-increment: next-step-counter;
  padding: 15px 15px 5px 35px;
  background: ${NEUTRAL_COLOURS.GRAY_QUINARY};

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`
