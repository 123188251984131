export const GET_CHILD_NOTE = 'CHILD_NOTES/GET_CHILD_NOTE'
export const GET_CHILD_NOTE_FAILED = 'CHILD_NOTES/GET_CHILD_NOTE_FAILED'
export const GET_CHILD_NOTE_SUCCESS = 'CHILD_NOTES/GET_CHILD_NOTE_SUCCESS'

export const CREATE_CHILD_NOTE = 'CHILD_NOTES/CREATE_CHILD_NOTE'
export const CREATE_CHILD_NOTE_FAILED = 'CHILD_NOTES/CREATE_CHILD_NOTE_FAILED'
export const CREATE_CHILD_NOTE_SUCCESS = 'CHILD_NOTES/CREATE_CHILD_NOTE_SUCCESS'

export const UPDATE_CHILD_NOTE = 'CHILD_NOTES/UPDATE_CHILD_NOTE'
export const UPDATE_CHILD_NOTE_FAILED = 'CHILD_NOTES/UPDATE_CHILD_NOTE_FAILED'
export const UPDATE_CHILD_NOTE_SUCCESS = 'CHILD_NOTES/UPDATE_CHILD_NOTE_SUCCESS'

export const REMOVE_CHILD_NOTE = 'CHILD_NOTES/REMOVE_CHILD_NOTE'
export const REMOVE_CHILD_NOTE_FAILED = 'CHILD_NOTES/REMOVE_CHILD_NOTE_FAILED'
export const REMOVE_CHILD_NOTE_SUCCESS = 'CHILD_NOTES/REMOVE_CHILD_NOTE_SUCCESS'

export const CLEAR_CHILD_NOTE = 'CHILD_NOTES/CLEAR_CHILD_NOTE'
