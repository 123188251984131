import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  margin-bottom: 20px;
`

export const StyledItemTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px 25px 0;
`

export const StyledAvatarContainer = styled.div`
  display: flex;

  & div ~ div {
    margin-left: 15px;
  }
`

export const StyledActionContainer = styled.div`
  display: flex;
  align-items: center;

  & * ~ * {
    margin-left: 15px;
  }
`

export const StyledStatisticsContainer = styled.div`
  display: flex;
  padding: 0 25px;
  
  ${({ minHeight }) => minHeight && `
    min-height: ${minHeight}px;
  `}

  ${({ alignItems }) => alignItems && `
    align-items: ${alignItems};
  `}
`

export const StaffStatisticsColumn = styled.div`
  flex: 1;
`
