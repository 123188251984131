import { EVENTS, logEvent } from 'analytics'

import {
  CLEAR_BALANCE_ADJUSTMENT,
  CREATE_BALANCE_ADJUSTMENT,
  CREATE_BALANCE_ADJUSTMENT_FAILED,
  CREATE_BALANCE_ADJUSTMENT_SUCCESS,
  GET_BALANCE_ADJUSTMENT,
  GET_BALANCE_ADJUSTMENT_FAILED,
  GET_BALANCE_ADJUSTMENT_SUCCESS,
} from './constants'

export const getBalanceAdjustment = (id) => ({ balanceAdjustmentsApiClient }) => (dispatch) => {
  dispatch({ id, type: GET_BALANCE_ADJUSTMENT })

  balanceAdjustmentsApiClient.get(id).then(
    (data) => {
      dispatch({
        payload: data,
        type: GET_BALANCE_ADJUSTMENT_SUCCESS,
      })
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: GET_BALANCE_ADJUSTMENT_FAILED,
      })
    },
  )
}

export const createBalanceAdjustment = (
  body,
  onSuccess,
  onFailed,
) => ({ balanceAdjustmentsApiClient }) => (dispatch) => {
  dispatch({
    type: CREATE_BALANCE_ADJUSTMENT,
  })

  balanceAdjustmentsApiClient.create(body).then(
    (data) => {
      dispatch({
        payload: data,
        type: CREATE_BALANCE_ADJUSTMENT_SUCCESS,
      })

      logEvent(EVENTS.BALANCE_ADJUSTMENT_ADDED)

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: CREATE_BALANCE_ADJUSTMENT_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const clearBalanceAdjustment = () => ({
  type: CLEAR_BALANCE_ADJUSTMENT,
})
