import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { stopSubmit } from 'redux-form'

import { generateRoute } from 'utils/routing'
import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withEnquiryStatusTypesService } from 'services/legacy/enquiries'
import { withSnackbarService } from 'services/utils/snackbar'
import { withModalService } from 'services/utils/modal'
import { withRouter } from 'services/router'

import i18n from 'translations'

import AddView from './AddView'
import { ENQUIRY_PIPELINE_ADD_FORM } from './AddConstants'

const ENQUIRY_PIPELINE_GROUPS = {
  read: [
    'enquiryStatusType.enquiriesCount',
  ],
}

class AddContainer extends Component {
  constructor(props) {
    super(props)

    const { params } = props
    const { enquiryStatusTypeId } = params

    this.state = {
      enquiryStatusTypeId,
      isEditMode: !!enquiryStatusTypeId,
    }
  }

  componentDidMount() {
    const { enquiryStatusTypesActions } = this.props
    const { enquiryStatusTypeId } = this.state

    if (enquiryStatusTypeId) {
      enquiryStatusTypesActions.get({
        params: [enquiryStatusTypeId, {
          groups: ENQUIRY_PIPELINE_GROUPS,
        }],
      })
    }
  }

  componentWillUnmount() {
    const { enquiryStatusTypesActions } = this.props

    enquiryStatusTypesActions.clearSingle()
  }

  handleSubmitFailed = (response) => {
    const { injectValidation } = this.props
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    return injectValidation(ENQUIRY_PIPELINE_ADD_FORM, errors)
  }

  handleRedirect = () => {
    const { navigate } = this.props

    navigate(generateRoute('MANAGEMENT.ENQUIRIES_SETTINGS.ENQUIRY_PIPELINE.INDEX'))
  }

  handleSuccessMessage = () => {
    const { snackbarActions } = this.props
    const { isEditMode } = this.state

    snackbarActions.show({
      message: i18n.t(`module:Management:Enquiries:EnquiryPipeline:Add:${
        isEditMode ? 'updateSuccessMessage' : 'createSuccessMessage'
      }`),
    })

    this.handleRedirect()
  }

  handleSubmit = ({ name }) => {
    const { enquiryStatusTypesActions } = this.props
    const { enquiryStatusTypeId, isEditMode, type } = this.state

    const body = { name, type }

    if (isEditMode) {
      enquiryStatusTypesActions.update({
        body,
        onFailed: this.handleSubmitFailed,
        onSuccess: this.handleSuccessMessage,
        params: [enquiryStatusTypeId, {}],
      })

      return
    }

    enquiryStatusTypesActions.create({
      body,
      onFailed: this.handleSubmitFailed,
      onSuccess: this.handleSuccessMessage,
      params: [{}],
    })
  }

  handleRemoveSuccess = () => {
    const { initialValues, snackbarActions } = this.props
    const { name } = initialValues || {}

    snackbarActions.show({
      message: i18n.t('module:Management:Enquiries:EnquiryPipeline:Add:deleteSuccessMessage', { name }),
    })

    this.handleRedirect()
  }

  handleMigrateSuccess = () => {
    const { enquiryStatusType, snackbarActions } = this.props
    const { enquiriesCount } = enquiryStatusType || {}

    const message = 1 < enquiriesCount
      ? i18n.t('module:Management:Enquiries:EnquiryPipeline:Add:migrateSuccessMessagePlural', { enquiriesCount })
      : i18n.t('module:Management:Enquiries:EnquiryPipeline:Add:migrateSuccessMessageSingular', { enquiriesCount })

    snackbarActions.show({ message })

    this.handleRedirect()
  }

  removeItem = () => {
    const {
      enquiryStatusType,
      enquiryStatusTypesActions,
      modalActions,
      modalConsts,
    } = this.props
    const { enquiryStatusTypeId } = this.state
    const { enquiriesCount } = enquiryStatusType

    if (!enquiriesCount) {
      return enquiryStatusTypesActions.update({
        body: { archived: true },
        onSuccess: this.handleRemoveSuccess,
        params: [enquiryStatusTypeId, {}],
      })
    }

    return modalActions.show(modalConsts.TYPES.ENQUIRY_STATUS_TYPE_MIGRATION, {
      onMigrateSuccess: this.handleMigrateSuccess,
    })
  }

  handleDeleteClick = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('global:Delete'),
      icon: 'trash',
      onConfirm: this.removeItem,
      text: (
        <React.Fragment>
          {i18n.t('module:Management:Enquiries:EnquiryPipeline:deleteMessage1')}
          <br />
          {i18n.t('module:Management:Enquiries:EnquiryPipeline:deleteMessage2')}
        </React.Fragment>
      ),
    })
  }

  render() {
    const {
      errorMessages,
      initialValues,
      isFetching,
      isSubmitting,
    } = this.props
    const { enquiryStatusTypeId, isEditMode, type } = this.state

    return (
      <AddView
        enquiryStatusTypeId={enquiryStatusTypeId}
        errorMessages={errorMessages}
        initialValues={initialValues}
        isEditMode={isEditMode}
        isLoading={isFetching}
        isSubmitting={isSubmitting}
        type={type}
        onDeleteClick={this.handleDeleteClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  injectValidation: (formName, params) => stopSubmit(formName, params),
}

const mapState = (state, {
  appSelectors,
  enquiryStatusTypesSelectors,
  enquiryStatusTypesSingleState,
  params,
}) => ({
  enquiryStatusType: enquiryStatusTypesSelectors.getEnquiryStatusTypesSingleDataSelector(state),
  errorMessages: appSelectors.getErrorMessages(enquiryStatusTypesSingleState),
  initialValues: enquiryStatusTypesSelectors.getInitialValues(!!params.enquiryStatusTypeId)(state),
  isFetching: appSelectors.getIsFetching(enquiryStatusTypesSingleState),
  isSubmitting: appSelectors.getIsSubmitting(enquiryStatusTypesSingleState),
})

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withSnackbarService,
  withEnquiryStatusTypesService,
  connect(mapState, mapDispatch),
)

export default enhance(AddContainer)
