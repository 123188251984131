import { generateBasicActions } from 'utils/service'

import {
  CLEAR_FOOD_TYPES_DROPDOWN_LIST,
  FOOD_TYPES_DROPDOWN_LIST,
  FOOD_TYPES_DROPDOWN_LIST_FAILED,
  FOOD_TYPES_DROPDOWN_LIST_SUCCESS,
  GET_FOOD_TYPES_DROPDOWN_ITEM,
  GET_FOOD_TYPES_DROPDOWN_ITEM_FAILED,
  GET_FOOD_TYPES_DROPDOWN_ITEM_SUCCESS,
} from './constants'

export const dropdownList = (options = {}) => ({ foodTypesApiClient }) => generateBasicActions.list({
  constants: {
    failed: FOOD_TYPES_DROPDOWN_LIST_FAILED,
    init: FOOD_TYPES_DROPDOWN_LIST,
    success: FOOD_TYPES_DROPDOWN_LIST_SUCCESS,
  },
  options,
  service: foodTypesApiClient,
  serviceMethod: 'list',
})

export const getDropdownItem = (options = {}) => ({ foodTypesApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_FOOD_TYPES_DROPDOWN_ITEM_FAILED,
    init: GET_FOOD_TYPES_DROPDOWN_ITEM,
    success: GET_FOOD_TYPES_DROPDOWN_ITEM_SUCCESS,
  },
  options,
  service: foodTypesApiClient,
  serviceMethod: 'get',
})

export const clearDropdownList = () => ({
  type: CLEAR_FOOD_TYPES_DROPDOWN_LIST,
})
