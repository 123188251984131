import { BasicSingleInitialState, getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_CHILD_NOTE,
  CREATE_CHILD_NOTE,
  CREATE_CHILD_NOTE_FAILED,
  CREATE_CHILD_NOTE_SUCCESS,
  GET_CHILD_NOTE,
  GET_CHILD_NOTE_FAILED,
  GET_CHILD_NOTE_SUCCESS,
  REMOVE_CHILD_NOTE,
  REMOVE_CHILD_NOTE_FAILED,
  REMOVE_CHILD_NOTE_SUCCESS,
  UPDATE_CHILD_NOTE,
  UPDATE_CHILD_NOTE_FAILED,
  UPDATE_CHILD_NOTE_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export interface ChildNotesSingleState extends BasicSingleInitialState {}

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_CHILD_NOTE,
    createFailed: CREATE_CHILD_NOTE_FAILED,
    createInit: CREATE_CHILD_NOTE,
    createSuccess: CREATE_CHILD_NOTE_SUCCESS,
    itemFailed: GET_CHILD_NOTE_FAILED,
    itemInit: GET_CHILD_NOTE,
    itemSuccess: GET_CHILD_NOTE_SUCCESS,
    removeFailed: REMOVE_CHILD_NOTE_FAILED,
    removeInit: REMOVE_CHILD_NOTE,
    removeSuccess: REMOVE_CHILD_NOTE_SUCCESS,
    updateFailed: UPDATE_CHILD_NOTE_FAILED,
    updateInit: UPDATE_CHILD_NOTE,
    updateSuccess: UPDATE_CHILD_NOTE_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
