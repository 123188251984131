import _ from 'lodash'
import { createSelector } from 'reselect'
import { formValueSelector } from 'redux-form'

import { toFloat } from 'utils/data'

import { ApplicableValues, NURSERY_DISCOUNT_TYPES } from 'services/product/nurseryDiscountsV3/constants'

export const getNurseryDiscountsSelector = (state) => state.nurseryDiscounts

export const getNurseryDiscountsSingleSelector = createSelector(
  [getNurseryDiscountsSelector],
  (state) => state.single.data,
)

export const getFormInitialValues = (discount) => {
  const { applicable, applicableBeforeFunding, description, type, value } = discount

  if (NURSERY_DISCOUNT_TYPES.PERCENTAGE === type) {
    return {
      applicableBeforeFunding,
      consumables: _.includes(applicable, ApplicableValues.CONSUMABLES),
      description,
      extraItems: _.includes(applicable, ApplicableValues.EXTRA_ITEMS),
      extraSessions: _.includes(applicable, ApplicableValues.EXTRA_SESSIONS),
      sessions: _.includes(applicable, ApplicableValues.SESSIONS),
      type,
      value,
    }
  }

  return {
    description,
    type,
    value,
  }
}

export const getDefaultFormValues = () => ({
  consumables: true,
  extraItems: true,
  extraSessions: true,
  sessions: true,
  type: NURSERY_DISCOUNT_TYPES.PERCENTAGE,
})

export const getInitialValues = (isEdit) => createSelector(
  [getNurseryDiscountsSingleSelector],
  (discount) => {
    if (!isEdit) {
      return getDefaultFormValues()
    }

    if (!discount) {
      return null
    }

    return getFormInitialValues(discount)
  },
)

const formSelector = (formName) => formValueSelector(formName)

export const typeSelector = (formName) => (state) => formSelector(formName)(state, 'type')

export const isPercentageSelected = (formName) => createSelector(
  [typeSelector(formName)],
  (type) => {
    if (!type) {
      return null
    }

    return NURSERY_DISCOUNT_TYPES.PERCENTAGE === type
  },
)

export const getPayload = createSelector(
  [(fields) => fields],
  (fields) => {
    if (!fields) {
      return null
    }

    const { consumables, description, extraItems, extraSessions, sessions, type, value } = fields

    if (NURSERY_DISCOUNT_TYPES.PERCENTAGE === type) {
      const applicable = []

      if (sessions) {
        applicable.push(ApplicableValues.SESSIONS)
      }

      if (extraSessions) {
        applicable.push(ApplicableValues.EXTRA_SESSIONS)
      }

      if (extraItems) {
        applicable.push(ApplicableValues.EXTRA_ITEMS)
      }

      if (consumables && sessions) {
        applicable.push(ApplicableValues.CONSUMABLES)
      }

      return {
        applicable,
        description,
        type,
        value: toFloat(value),
      }
    }

    return {
      description,
      type,
      value: toFloat(value),
    }
  },
)

export const getArchivePayload = createSelector(
  [((fields) => fields)],
  (fields) => {
    if (!fields) {
      return null
    }

    const { archived, type } = fields

    return {
      archived,
      type,
    }
  },
)

export const isArchived = createSelector(
  [getNurseryDiscountsSingleSelector],
  (discount) => {
    if (!discount) {
      return null
    }

    return discount.archived
  },
)

export const getDropdownDescription = (item) => {
  if (!item) {
    return null
  }

  const descriptionSuffix = item.type === NURSERY_DISCOUNT_TYPES.PERCENTAGE ? '%' : ''

  return `${item.description} (${item.value}${descriptionSuffix})`
}
