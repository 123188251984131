import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CREATE_LIKE,
  CREATE_LIKE_FAILED,
  CREATE_LIKE_SUCCESS,
  REMOVE_LIKE,
  REMOVE_LIKE_FAILED,
  REMOVE_LIKE_SUCCESS,
} from './constants'

export const create = (options: BasicActionOptionsProps) => ({ likesApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: CREATE_LIKE_FAILED,
      init: CREATE_LIKE,
      success: CREATE_LIKE_SUCCESS,
    },
    options,
    service: likesApiClient,
    serviceMethod: 'create',
  })
)

export const remove = (options: BasicActionOptionsProps) => ({ likesApiClient }) => (
  generateBasicActions.remove({
    constants: {
      failed: REMOVE_LIKE_FAILED,
      init: REMOVE_LIKE,
      success: REMOVE_LIKE_SUCCESS,
    },
    options,
    service: likesApiClient,
    serviceMethod: 'remove',
  })
)
