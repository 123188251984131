import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withUsersService, withUsersServiceProps } from 'services/users'
import { STATUS_OPTIONS_VALUES } from 'services/users/constants'
import { typeByObject } from 'utils/typescript'

import { Select } from 'components'

import i18n from 'translations'

import { DropdownInterface, DropdownProps } from '../../InfiniteDropdowns/InfiniteDropdownsModel'
import withInfiniteDropdownHelpers, { withInfiniteDropdownHelpersProps } from './withInfiniteDropdown'

type UsersDropdownProps = {
  isFetching: boolean
  status?: typeByObject<typeof STATUS_OPTIONS_VALUES>
} & withInfiniteDropdownHelpersProps
  & withUsersServiceProps
  & DropdownProps

class UsersDropdown extends Component<UsersDropdownProps> implements DropdownInterface {
  componentDidMount() {
    // @ts-ignore
    const { infiniteDropdownHelpers, usersActions, value } = this.props

    if (value && {}.hasOwnProperty.call(value, 'value') && {}.hasOwnProperty.call(value, 'label')) {
      return null
    }

    if (!_.isArray(value)) {
      return infiniteDropdownHelpers.handleComponentDidMount({
        action: () => usersActions.get({
          addToList: true,
          onFailed: this.notFoundValue,
          onSuccess: infiniteDropdownHelpers.handleComponentDidMountOnSuccess,
          // @ts-ignore
          params: [value?.value || value],
        }),
        value,
      })
    }

    return null
  }

  componentWillUnmount() {
    const { usersActions } = this.props

    usersActions.clearList()
  }

  handleLoadMoreElements = async (phrase) => {
    // @ts-ignore
    const { infiniteDropdownHelpers, limit, skipIds, status, usersActions, usersSelectors } = this.props

    return infiniteDropdownHelpers.handleLoadMoreElements({
      clearAction: usersActions.clearList,
      listAction: ({ newPage, newSearchPhrase, result }) => usersActions.list({
        mergeResult: true,
        onSuccess: (response) => {
          let newResponse = response

          if (skipIds) {
            newResponse = {
              ...response,
              data: _.filter(response.data, ({ id }) => (!skipIds.includes(id))),
            }
          }

          infiniteDropdownHelpers.handleLoadMoreElementsOnSuccess({
            extraFields: ['email'],
            newPage,
            newSearchPhrase,
            response: newResponse,
            result,
          })
        },
        params: {
          criteria: usersSelectors.getUsersListCriteria({
            search: newSearchPhrase,
            status,
          }),
          limit,
          page: newPage,
        },
        silent: true,
      }),
      phrase,
    })
  }

  notFoundValue = () => {
    const { onChange } = this.props

    onChange(null)
  }

  handleChange = (e) => {
    const { infiniteDropdownHelpers, onChange } = this.props

    infiniteDropdownHelpers.handleOnChange(e, () => onChange(e))
  }

  render() {
    const {
      disabled,
      infiniteDropdownState,
      isFetching,
      maxMenuHeight,
      placeholder,
      rawValue,
      // @ts-ignore
      value,
      ...rest
    } = this.props
    const { cacheUniq, options, selectedValue } = infiniteDropdownState

    return (
      <Select
        {...rest}
        cacheUniq={cacheUniq}
        disabled={disabled || isFetching}
        loadOptions={this.handleLoadMoreElements}
        maxMenuHeight={maxMenuHeight}
        options={options}
        placeholder={placeholder || i18n.t('global:KeyPerson')}
        rawValue={rawValue || selectedValue || value}
        value={value}
        searchable
        withAvatars
        onChange={this.handleChange}
      />
    )
  }
}

const mapState = (state, { appSelectors, usersSingleState }) => ({
  isFetching: appSelectors.getIsFetching(usersSingleState),
})

const enhance = compose(
  withAppService,
  withUsersService,
  withInfiniteDropdownHelpers,
  connect(mapState),
)

export default enhance(UsersDropdown)
