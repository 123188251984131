import moment from 'moment'

import React from 'react'

import { getBrandingColor } from 'utils/branding'
import { generateRoute } from 'utils/routing'

import { Hyperlink, Space, Typography } from 'components'

import i18n from 'translations'

import { StyledPinBox, StyledPinBoxWrapper } from './PinBoxStyled'

const PinBox = ({ deviceItem }) => (
  <StyledPinBoxWrapper>
    <StyledPinBox>
      <Typography>
        {i18n.t('module:Management:Security:AuthorisedDevices:components:PinBox:pin')}
      </Typography>
      <Typography color={getBrandingColor('tertiary-color')} margin="15px 0" variant="h4">
        {deviceItem?.pin.digits}
      </Typography>
      <Typography color={getBrandingColor('tertiary-color')}>
        {i18n.t('module:Management:Security:AuthorisedDevices:components:PinBox:pinExpireDate', {
          components: [(
            <Typography variant="span" bold />
          )],
          date: moment(deviceItem?.pin.expiresAt)
            .calendar(),
        })}
      </Typography>
    </StyledPinBox>
    <Space space="10px" />
    <Hyperlink to={generateRoute('MANAGEMENT.SECURITY.DEVICES')} primary>
      {i18n.t('module:Management:Security:AuthorisedDevices:components:PinBox:backToDevices')}
    </Hyperlink>
  </StyledPinBoxWrapper>
)

export default PinBox
