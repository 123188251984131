import styled from 'styled-components'

export const StyledContactContent = styled.div`
  display: flex;
  align-items: flex-start;

  ${({ isFirstItem }) => isFirstItem && `
    margin-bottom: 10px;
  `}
`
