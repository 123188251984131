import { compose } from 'recompose'

import { Component } from 'react'
import { connect } from 'react-redux'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import { withShellService } from 'services/shell'
import { withRouter } from 'services/router'

import RoomsMoves from 'module/Rooms/RoomsMoves'
import ChildFiles from 'module/Children/Child/ChildAbout/ChildFiles'

import i18n from 'translations'

class ChildAboutContainer extends Component {
  constructor(props) {
    super(props)

    this.indexRedirect(props)
  }

  componentDidMount() {
    const {
      authAccessMap,
      params: { childId },
      shellActions,
    } = this.props

    shellActions.setSubMenu([
      {
        icon: 'profile',
        label: 'Profile',
        to: generateRoute('CHILDREN.CHILD.ABOUT.PROFILE', { childId }),
      },
      {
        hidden: !authAccessMap.module.RoomsMoves,
        icon: 'room-moves',
        label: 'Room moves',
        to: generateRoute('CHILDREN.CHILD.ABOUT.ROOMS_MOVES', { childId }),
      },
      {
        icon: 'chart',
        label: 'Attendance',
        to: generateRoute('CHILDREN.CHILD.ABOUT.ATTENDANCE', { childId }),
      },
      {
        icon: 'absence',
        label: i18n.t('module:Children:Child:About:Absences:Title:short'),
        to: generateRoute('CHILDREN.CHILD.ABOUT.ABSENCES', { childId }),
      },
      {
        hidden: !authAccessMap.module.ChildFiles,
        icon: 'document',
        label: i18n.t('services:Files:title'),
        to: generateRoute('CHILDREN.CHILD.ABOUT.FILES', { childId }),
      },
    ])
  }

  shouldComponentUpdate(nextProps) {
    this.indexRedirect(nextProps)

    return true
  }

  componentWillUnmount() {
    const { shellActions } = this.props

    shellActions.clearSubMenu()
  }

  indexRedirect = (props) => {
    const { location: { pathname }, navigate, params: { childId } } = props

    if (generateRoute('CHILDREN.CHILD.ABOUT', { childId }) === pathname) {
      navigate(generateRoute('CHILDREN.CHILD.ABOUT.PROFILE', { childId }))
    }
  }

  render() {
    const { children } = this.props

    return children
  }
}

const mapState = (state) => ({
  authAccessMap: {
    module: {
      ChildFiles: auth.SELECTORS.getComponentIsAuthorised(state, ChildFiles),
      RoomsMoves: auth.SELECTORS.getComponentIsAuthorised(state, RoomsMoves),
    },
  },
})

const enhance = compose(
  withRouter,
  withShellService,
  connect(mapState),
)

export default enhance(ChildAboutContainer)
