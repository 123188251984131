import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'
import layout from 'constants/layout'

import { getBrandingColor } from 'utils/branding'

export const StyledContainer = styled.div`
  background-color: ${getBrandingColor('primary-color')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${layout.borderRadius};
  flex-direction: column;
  overflow: auto;
  color: ${NEUTRAL_COLOURS.WHITE};
  font-size: 22px;
  height: 280px;
  
  @media(min-width: 1100px) {
    max-width: 340px;
  }

  ${({ part }) => part && `
    display: grid;
    grid-row-gap: 20px;
    grid-template-columns: 1fr;
    background-color: transparent;
  `}
`

export const StyledHeader = styled.div`
  color: ${NEUTRAL_COLOURS.WHITE};
  display: flex;
  text-transform: uppercase;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  height: 45px;
`

export const StyledContent = styled.div`
  height: 100%;
  width: 100%;
  color: ${NEUTRAL_COLOURS.WHITE};
  background-color: ${getBrandingColor('senary-color')};
  overflow: auto;
  position: relative;
  padding: 10px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  
  ${({ isLoading }) => isLoading && `
    display: flex;
    align-items: center;
    justify-content: center;
  `}
  
  ${({ primary }) => primary && `
    background-color: ${getBrandingColor('primary-color')};
  `}
  
  ${({ horizontalAlign }) => horizontalAlign && `
    justify-content: ${horizontalAlign};
  `}
  
  ${({ verticalAlign }) => verticalAlign && `
    align-items: ${verticalAlign};
  `}
  
  ${({ noPadding }) => noPadding && `
    padding: 0;
  `}
`
