import React, { PropsWithChildren } from 'react'

import { Collapse, Spinner } from 'components'

import CommentDrawerListItem from './CommentDrawerListItem'
import { StyledContent } from './CommentDrawerStyled'

interface CommentDrawerListRoot<T> extends React.FC<PropsWithChildren<T>> {
  Item?: typeof CommentDrawerListItem
}

interface CommentDrawerListProps {
  isFetching?: boolean
  scrollableId?: string
}

const CommentDrawerList: CommentDrawerListRoot<CommentDrawerListProps> = ({
  children,
  isFetching,
  scrollableId,
}) => {
  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    return (
      <Collapse>
        {children}
      </Collapse>
    )
  }

  return (
    <StyledContent id={scrollableId}>
      {renderContent()}
    </StyledContent>
  )
}

export default CommentDrawerList
