import _ from 'lodash'

import { Option } from 'constants/models'
import { Nursery } from 'services/nurseries/models'
import { getMonthOptions } from 'utils/date'

export interface InitialValuesResponseProps {
  allowOverwrite?: boolean
  annualisedInvoiceSettingsAppliedFromGlobal?: boolean
  excludedMonths?: Option[]
  months?: number
  weeks?: number
}

export const getInitialValues = (response: { data?: Nursery }): InitialValuesResponseProps => {
  if (!response?.data?.nurserySettings?.invoice?.annualisedInvoiceSettings) {
    return null
  }

  const {
    annualisedInvoiceSettings,
    annualisedInvoiceSettingsAppliedFromGlobal,
  } = response.data.nurserySettings.invoice

  const {
    allowOverwriteOnChildLevel: allowOverwrite,
    excludedMonths,
    months,
    weeks,
  } = annualisedInvoiceSettings

  const monthOptions = getMonthOptions()

  return {
    allowOverwrite,
    annualisedInvoiceSettingsAppliedFromGlobal,
    excludedMonths: _.map(excludedMonths, (month) => _.find(monthOptions, { value: month })),
    months,
    weeks,
  }
}
