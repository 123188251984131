import { DEVICE_NOT_AUTHORISED_ERROR_CODE } from 'services/authentication/constants'
import { CONFIRM_GDPR_SUCCESS } from 'services/legacy/memberships/single/constants'
import { GET_NURSERY_SUCCESS } from 'services/nurseries/single/constants'

import { Profile } from '../models'
import { CHANGE_PASSWORD_FAILED, GET_LOGIN_FAILED, REMOVE_IDENTITY, SET_IDENTITY } from './constants'

export interface AuthenticationCommonState {
  error: any
  profile: Profile | null
  token: string | null
}

const initialState = {
  error: null,
  profile: null,
  token: null,
}

export default (...props) => {
  const [state = initialState, { error, payload, type }] = props

  if (type === CONFIRM_GDPR_SUCCESS) {
    return {
      ...state,
      profile: {
        ...state.profile,
        user: {
          ...state.profile.user,
          gdprAcceptedAt: payload.data.gdprAcceptedAt,
        },
      },
    }
  }

  if (type === GET_NURSERY_SUCCESS) {
    return {
      ...state,
      profile: {
        ...state.profile,
        nursery: {
          ...state.profile.nursery,
          ...payload.data,
        },
      },
    }
  }

  if (type === GET_LOGIN_FAILED) {
    if (error.code === DEVICE_NOT_AUTHORISED_ERROR_CODE) {
      return { ...state }
    }

    return {
      ...state,
      error,
    }
  }

  if (type === CHANGE_PASSWORD_FAILED) {
    return {
      ...state,
      error,
    }
  }

  if (type === SET_IDENTITY) {
    return {
      ...state,
      ...payload,
    }
  }

  if (type === REMOVE_IDENTITY) {
    return initialState
  }

  return state
}
