import React from 'react'

import { ModalBox } from 'components'

import i18n from 'translations'

import EmergencyContactsListForm from './components/EmergencyContactsListForm'

const EmergencyContactListsModalView = ({
  initialValues,
  isProcessing,
  onCloseClick,
  onSubmit,
}) => (
  <ModalBox
    title={i18n.t('module:Modals:EmergencyContacts:title')}
    autoHeight
    onCloseClick={onCloseClick}
  >
    <EmergencyContactsListForm
      initialValues={initialValues}
      isProcessing={isProcessing}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    />
  </ModalBox>
)

export default EmergencyContactListsModalView
