import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledLink = styled.div`
  a {
    color: ${getBrandingColor('primary-color')};
    text-decoration: underline;
  } 
`
