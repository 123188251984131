import _ from 'lodash'

import React from 'react'

import { generateRoute } from 'utils/routing'

import { GENDER_DROPDOWN } from 'services/legacy/child/constants'

import { DateString, Panel } from 'components'

import i18n from 'translations'

const Child = ({ enquiry }) => {
  const { childName, data, id } = enquiry
  const { child } = data
  const { birthDate, gender } = child

  return (
    <Panel
      title={_.upperFirst(i18n.t('global:child'))}
      to={`${generateRoute('ENQUIRIES.EDIT', { enquiryId: id })}?s=child`}
    >
      <Panel.Item
        label={i18n.t('global:Name')}
        value={childName}
        valueVariant="h5"
      />
      <Panel.Item
        label={i18n.t('module:Enquiries:List:Add:ChildInformation:Gender:label')}
        value={_.get(_.find(GENDER_DROPDOWN, ({ value }) => value === gender), 'label')}
      />
      <Panel.Item
        label={i18n.t('module:Enquiries:List:Add:ChildInformation:DateOfBirth:label')}
        value={<DateString date={birthDate} />}
      />
    </Panel>
  )
}

export default Child
