import styled from 'styled-components'

import colors, { NEUTRAL_COLOURS } from 'constants/colors'

import { CELL_WIDTH, MIN_ROW_HEIGHT, ROW_HEADER_WIDTH } from './constants'

const SILVER = 'silver'
const GRAY = 'gray'

export const StyledContainer = styled.div`
  display: flex;
  overflow: auto;
  margin: 10px 0 15px 0;
`

export const StyledColumnHeaderWrapper = styled.div`
  display: flex;
  min-height: 60px;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  color: ${GRAY};
  position: relative;
`

export const StyledColumnHeader = styled.div`
  position: absolute;
  ${({ left }) => left && `left: ${left}px;`};
`

export const StyledRowHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  width: ${ROW_HEADER_WIDTH}px;
`

export const StyledRowSubHeader = styled.div`
  font-size: 16px;
  color: ${colors.gray};
`

export const StyledCell = styled.div`
  display: flex;
  width: ${({ width = CELL_WIDTH }) => width}px;
  border-right: 1px dotted ${SILVER};
`

export const StyledRow = styled.div`
  display: flex;
  min-height: ${MIN_ROW_HEIGHT}px;

  ${({ height }) => height && `
    height: ${height}px;
  `}
`

export const StyledTable = styled.div`
  display: flex;
  flex-direction: column;

  & ${StyledRow} ~ ${StyledRow} {
    border-bottom: 1px dotted ${SILVER};
  }
`

export const StyledProgressContainer = styled.div`
  display: flex;
  position: absolute;
  width: ${({ width = 50 }) => width}px;
  min-height: 40px;
  ${({ height }) => height && `height: ${height}px;`}
  left: ${({ left = 0 }) => left}px;
  top: ${({ top = 0 }) => top}px;
  opacity: .9;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
`

export const StyledProgress = styled.div`
  display: flex;
  position: absolute;
  background: ${({ background }) => background};
  min-height: 40px;
  ${({ height }) => height && `height: ${height}px;`}
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  top: ${({ top = 0 }) => top}px;
  left: ${({ left = 0 }) => left}px;
  width: ${({ width = 50 }) => width}px;
  opacity: .9;
`

export const StyledRowContent = styled.div`
  display: flex;
  position: relative;
`

export const StyledProgressText = styled.div`
  z-index: 3;
  font-weight: 600;
  color: ${({ color = NEUTRAL_COLOURS.WHITE }) => color};
  overflow: hidden;
  white-space: nowrap;
  width: ${({ width = 50 }) => width}px;
  text-overflow: ellipsis;
  padding: 10px;
  text-align: center;
  position: absolute;
  opacity: ${({ opacity = 1 }) => opacity};
  left: ${({ left = 0 }) => left}px;
  top: ${({ top = 0 }) => top}px;
`
