import { BasicSingleInitialState } from 'utils/reducer'

import {
  AUTO_REFRESH_INVOICE,
  AUTO_REFRESH_INVOICE_FAILED,
  AUTO_REFRESH_INVOICE_SUCCESS,
  CLEAR_INVOICE,
  CREATE_INVOICE,
  CREATE_INVOICE_FAILED,
  CREATE_INVOICE_SUCCESS,
  DELETE_INVOICE_FAILED,
  DELETE_INVOICE_SUCCESS,
  GET_INVOICE,
  GET_INVOICE_FAILED,
  GET_INVOICE_SUCCESS,
  UPDATE_INVOICE,
  UPDATE_INVOICE_FAILED,
  UPDATE_INVOICE_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false,
}

export interface InvoicesSingleState extends BasicSingleInitialState {}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case GET_INVOICE:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_INVOICE_SUCCESS:
      return { ...state, data: payload.data, isFetching: false }
    case GET_INVOICE_FAILED:
      return { ...state, error, isFetching: false }
    case CREATE_INVOICE:
    case UPDATE_INVOICE:
    case AUTO_REFRESH_INVOICE:
      return { ...state, error: initialState.error, isSubmitting: true }
    case CREATE_INVOICE_SUCCESS:
    case UPDATE_INVOICE_SUCCESS:
    case AUTO_REFRESH_INVOICE_SUCCESS:
      return { ...state, data: payload.data, isSubmitting: false }
    case CREATE_INVOICE_FAILED:
    case UPDATE_INVOICE_FAILED:
    case AUTO_REFRESH_INVOICE_FAILED:
      return { ...state, error, isSubmitting: false }
    case DELETE_INVOICE_SUCCESS:
      return initialState
    case DELETE_INVOICE_FAILED:
      return { ...state, error }
    case CLEAR_INVOICE:
      return initialState
    default:
      return state
  }
}
