import React from 'react'

import { Page, ReportView, SearchBar, Typography } from 'components'

import i18n from 'translations'

const ReportsView = ({ items, onSearchChange }) => (
  <React.Fragment>
    <SearchBar
      placeholder={i18n.t('module:Reports:searchPlaceholder')}
      onChange={onSearchChange}
    />
    <Page.Section title={i18n.t('module:Reports:title')}>
      <Typography margin="20px 0">
        {i18n.t('module:Reports:subtitle')}
      </Typography>
      <ReportView items={items} />
    </Page.Section>
  </React.Fragment>

)

export default ReportsView
