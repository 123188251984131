import React from 'react'
import { Field } from 'redux-form'

import { NURSERY_DISCOUNT_TYPE_OPTIONS_V3, NurseryDiscountTypesV3 } from 'services/product/nurseryDiscountsV3/constants'

import { createTypeFromEnumValues } from 'utils/typescript'
import { isRequired } from 'utils/fieldValidation'

import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

import { Banner, Form, Grid, Space, Typography } from 'components'

import i18n from 'translations'

import { BEFORE_AFTER_TYPE_OPTIONS } from './constants'
import { AddDiscountFormValues } from './AddDiscountForm'

export interface AddDiscountFormFieldValues {
  formValues?: AddDiscountFormValues
  isEdit?: boolean
  type?: createTypeFromEnumValues<NurseryDiscountTypesV3>
  verticalLabel?: boolean
}

const AddDiscountFormFields: React.FC<AddDiscountFormFieldValues> = ({
  formValues,
  isEdit,
  type,
  verticalLabel,
}) => {
  const padding = !verticalLabel ? '0px 0px 20px 120px' : undefined

  return (
    <React.Fragment>
      <Form.Row
        label={i18n.t('module:Management:Finance:Discounts:discountName')}
        verticalLabel={verticalLabel}
        width={{ field: '250px' }}
      >
        <Field
          component={Form.TextField}
          name="name"
          placeholder={i18n.t('module:Management:Finance:Discounts:discountName')}
          validate={isRequired}
        />
      </Form.Row>
      <Form.Row label={i18n.t('module:Management:Finance:Discounts:discountType')} verticalLabel={verticalLabel}>
        <Field
          component={Form.RadioGroup}
          disabled={isEdit}
          name="type"
          options={NURSERY_DISCOUNT_TYPE_OPTIONS_V3}
          validate={isRequired}
        />
      </Form.Row>
      <Form.Row margin="0" padding={padding} verticalLabel={verticalLabel} width={{ field: '120px' }}>
        <SubdomainCurrencyProvider>
          {({ currencySymbol }) => (
            <Field
              component={Form.TextField}
              name="value"
              placeholder={type !== NurseryDiscountTypesV3.PERCENTAGE ? '0.00' : '%'}
              prefix={type !== NurseryDiscountTypesV3.PERCENTAGE ? currencySymbol : null}
              prefixWidth={type !== NurseryDiscountTypesV3.PERCENTAGE ? '30px' : null}
              step="any"
              type="number"
              validate={isRequired}
            />
          )}
        </SubdomainCurrencyProvider>
      </Form.Row>
      <Form.Row margin="-10px 0 0" padding={padding}>
        <Grid>
          <Grid.Item>
            <Field
              component={Form.Switch}
              name="allowOverride"
            />
          </Grid.Item>
          <Grid.Item alignItems={{ desktop: 'center' }}>
            <Typography fontSize={14}>
              {i18n.t('module:Management:Finance:Discounts:enableOverwriting')}
            </Typography>
          </Grid.Item>
        </Grid>
      </Form.Row>
      <Space space="15px" />
      {type === NurseryDiscountTypesV3.PERCENTAGE && (
        <React.Fragment>
          <Form.Row margin="0" padding={padding} verticalLabel={verticalLabel} autoHeight>
            <Field
              component={Form.Checkbox}
              label={i18n.t('module:Management:Finance:AccountCodes:ItemCategories:regularSessions')}
              name="sessions"
            />
          </Form.Row>
          <Form.Row margin="0" padding={padding} verticalLabel={verticalLabel} autoHeight>
            <Field
              component={Form.Checkbox}
              label={i18n.t('module:Management:Finance:AccountCodes:ItemCategories:extraSessions')}
              name="extraSessions"
            />
          </Form.Row>
          <Form.Row margin="0" padding={padding} verticalLabel={verticalLabel} autoHeight>
            <Field
              component={Form.Checkbox}
              label={i18n.t('module:Management:Finance:AccountCodes:ItemCategories:extraItems')}
              name="extraItems"
            />
          </Form.Row>
          <Form.Row margin="0" padding={padding} verticalLabel={verticalLabel} autoHeight>
            <Field
              component={Form.Checkbox}
              label={i18n.t('module:Management:Finance:AccountCodes:ItemCategories:regularItems')}
              name="regularItems"
            />
          </Form.Row>
          {formValues?.sessions && (
            <Form.Row margin="0" padding={padding} verticalLabel={verticalLabel} autoHeight>
              <Field
                component={Form.Checkbox}
                label={i18n.t('module:Management:Finance:AccountCodes:ItemCategories:consumablesAddedToSessions')}
                name="consumables"
              />
            </Form.Row>
          )}
        </React.Fragment>
      )}
      {type !== NurseryDiscountTypesV3.AMOUNT && formValues?.sessions && (
        <Form.Row
          label={i18n.t('module:Management:Finance:Discounts:Form:BeforeAfterFunding:label')}
          verticalLabel
        >
          <Field
            component={Form.RadioGroup}
            name="applicableBeforeFunding"
            options={BEFORE_AFTER_TYPE_OPTIONS}
            validate={isRequired}
            buttonStyle
            horizontal
          />
        </Form.Row>
      )}
      {type !== NurseryDiscountTypesV3.PERCENTAGE && (
        <Form.Row padding={padding} verticalLabel={verticalLabel}>
          <Banner.Info>
            {i18n.t('module:Management:Finance:Discounts:fixedAmountDiscountInfoTitle')}
            <br />
            {i18n.t('module:Management:Finance:Discounts:fixedAmountDiscountInfoSubTitle')}
          </Banner.Info>
        </Form.Row>
      )}
    </React.Fragment>
  )
}

export default AddDiscountFormFields
