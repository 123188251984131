import _ from 'lodash'

import React from 'react'

import { generateRoute } from 'utils/routing'

import { Button, DropdownMenu, Hyperlink, Toolbar } from 'components'

import i18n from 'translations'

import FundingTableDetailAllocationCell from './FundingTableDetailAllocationCell'
import { StyledDetailsTable, StyledFundingDetailsTableContainer } from './FundingTableStyled'

const FundingTableDetailsTable = ({
  childId,
  currentChildFundings,
  onAllocateChildFundingClick,
  onArchiveChildFundingClick,
  onAssignChildFundingClick,
  onEditChildFundingClick,
  onShowAllFundingClick,
}) => (
  <StyledFundingDetailsTableContainer>
    <StyledDetailsTable>
      <tr>
        <th>
          {i18n.t('module:Finance:Funding:DetailTable:Header:currentFunding')}
        </th>
        <th>
          {i18n.t('module:Finance:Funding:DetailTable:Header:fundingPeriod')}
        </th>
        <th>
          {i18n.t('module:Finance:Funding:DetailTable:Header:fundingAllocation')}
        </th>
        <th>{''}</th>
        <th>{''}</th>
      </tr>
      {_.map(currentChildFundings, ({
        displayName,
        fundingAllocationLabel,
        hoursAllocated,
        id: childFundingId,
        isManualType,
        period,
        totalHours,
        type,
      }) => (
        <tr key={childFundingId}>
          <td>
            <Hyperlink to={generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.FUNDING', { childId })} primary>
              {displayName}
            </Hyperlink>
          </td>
          <td>{period}</td>
          <td>
            <FundingTableDetailAllocationCell
              childFundingId={childFundingId}
              childId={childId}
              fundingAllocationLabel={fundingAllocationLabel}
              hoursAllocated={hoursAllocated}
              isManualType={isManualType}
              totalHours={totalHours}
              onAllocateChildFundingClick={onAllocateChildFundingClick}
            />
          </td>
          <td>
            <DropdownMenu small>
              <DropdownMenu.Item
                type="edit"
                onClick={() => onEditChildFundingClick(childId, childFundingId)}
              />
              <DropdownMenu.Item
                type="archive"
                onClick={() => onArchiveChildFundingClick(childFundingId, type)}
              />
            </DropdownMenu>
          </td>
        </tr>
      ))}
    </StyledDetailsTable>
    <Toolbar noMargin>
      <Toolbar.Group>
        <Toolbar.Item>
          <Button
            label={i18n.t('module:Finance:Funding:assignFundingTypeLabel')}
            size="small"
            negativeMargins
            onClick={() => onAssignChildFundingClick(childId)}
          />
        </Toolbar.Item>
        <Toolbar.Item>
          <Button
            hierarchy="secondary"
            label={i18n.t('global:ShowAll')}
            size="small"
            negativeMargins
            onClick={() => onShowAllFundingClick(childId)}
          />
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  </StyledFundingDetailsTableContainer>
)

export default FundingTableDetailsTable
