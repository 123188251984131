import styled from 'styled-components'
import colorLibrary from 'color'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { typeByObject } from 'utils/typescript'
import { BROWSERS } from 'constants/browsers'

export const ICON_VARIANT = {
  CIRCLE: 'circle',
  DEFAULT: 'default',
} as const

interface StyledCalloutProps {
  $color?: string
  $hasIcon?: boolean
  $iconVariant?: typeByObject<typeof ICON_VARIANT>
  $margin?: string
  $noBottomMargin?: boolean
  $noLeftBorder?: boolean
  $transparent?: boolean
}

export const StyledCallout = styled.div<StyledCalloutProps>`
  display: grid;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 6px 6px 6px 8px;

  ${({ $noBottomMargin }) => !$noBottomMargin && `
    margin-bottom: 10px;
  `}

  ${({ $color }) => `
    background: ${colorLibrary($color).mix(colorLibrary(NEUTRAL_COLOURS.WHITE), 0.8).rgb().string()};
    border-left: 2px solid ${$color};
  `}
  
  ${({ $hasIcon, $iconVariant }) => $hasIcon && `
    grid-column-gap: 10px;
    grid-template-columns: ${$iconVariant === ICON_VARIANT.CIRCLE ? '20px' : '12px'} auto;
  `}
 
  ${({ $margin }) => $margin && `
    margin: ${$margin};
  `}
 
  ${({ $transparent }) => $transparent && `
    background: transparent;
    border-left: none;
  `}

  ${({ $noLeftBorder }) => $noLeftBorder && `
    border-left: none;
  `}
`

export const StyledIcon = styled.div`
  margin-top: 2px;
`

export const StyledContent = styled.div`
  color: ${NEUTRAL_COLOURS.BASIC};
  max-width: 100%;
  ${({ browserName }) => browserName !== BROWSERS.SAFARI && `
    overflow: hidden;
  `}
  white-space: pre-wrap;
`

interface StyledIconWrapperProps {
  $iconOnTop?: boolean
}

export const StyledIconWrapper = styled.div<StyledIconWrapperProps>`
  align-self: center;

  ${({ $iconOnTop }) => $iconOnTop && `
    align-self: flex-start;
  `}
`
