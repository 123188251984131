import styled from 'styled-components'

import colors from 'constants/colors'
import layout from 'constants/layout'

export const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 100px;
  background: #F5F5F5;
  height: 100%;
  width: 100%;
  padding: 15px 0 15px 15px;
  grid-area: content;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    grid-template-columns: 1fr auto 80px;
    padding: 10px 0 10px 10px;
  }
`

export const StyledMainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  border-right: 1px dotted ${colors.lightGray};
`

export const StyledSignatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  border-right: 1px dotted ${colors.lightGray};
  overflow: hidden;
  min-width: 150px;

  & div {
    margin-top: 2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

export const StyledTypeContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    flex-direction: column;
  }
`

export const StyledTitleContainer = styled.p`
  margin: 10px 0 0;
  word-break: break-all;
`

export const StyledActionColumn = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 15px;
  flex-direction: column;
  overflow: hidden;
  word-break: break-word;

  @media(max-width: ${layout.mobileWidthResolution}px) {
    padding: 10px;
  }
`

