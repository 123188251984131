export const MOVE_ON_DROPDOWN = [
  {
    label: 'Immediately at room end age',
    value: 'MOVE_IMMEDIATELY',
  },
  {
    label: 'End of month after room end age',
    value: 'ON_END_OF_MONTH',
  },
]
