import { useUnreadMessagesCount } from '@blossomdev/sync'

import React from 'react'

import { ZINDEX_ORDER } from 'constants/layout'

import ChannelList from 'module/Messaging/components/ChannelList'
import ConversationMenu from 'module/Shell/components/ConversationMenu'

const MessagingRoot = ({ isOffline }) => {
  const { unreadMessagesCount } = useUnreadMessagesCount()

  const onRedirect = (setIsOpen) => () => {
    setIsOpen(false)
  }

  return (
    <ConversationMenu
      buttonIcon="feedback"
      isOffline={isOffline}
      margin="0 0 0 -5px"
      unreadCount={unreadMessagesCount}
      zIndex={ZINDEX_ORDER.MESSAGING}
    >
      {({ setIsOpen, width }) => (
        <ChannelList
          width={width}
          root
          onRedirect={onRedirect(setIsOpen)}
        />
      )}
    </ConversationMenu>
  )
}

export default MessagingRoot
