import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { submit } from 'redux-form'

import { generateRoute } from 'utils/routing'

import { EVENTS, logEvent } from 'analytics'

import { withAppService } from 'services/app'

import withStaffRegisterWrapper from '../withStaffRegisterWrapper'
import CredentialsView from './CredentialsView'
import { CREDENTIALS_FORM } from './components/CredentialsForm'

export const MEMBERSHIP_REGISTERS_GROUP = {
  read: [
    'membershipRegisterEntry.nurseryClass',
    'nurseryClass',
    'membership.pin',
    'membership.details',
  ],
}

class CredentialsContainer extends Component {
  componentDidMount() {
    const { memberDetails } = this.props

    if (!memberDetails) {
      this.fetchSingle()
    }

    if (!this.isCreatePinPage()) {
      logEvent(EVENTS.STAFF_REGISTER_FORGOT_PIN_CLICKED)
    }
  }

  fetchSingle = () => {
    const {
      location,
      membershipRegistersActions,
      membershipRegistersSelectors,
    } = this.props
    const { query } = location || {}
    const { staff } = query || {}

    const criteria = membershipRegistersSelectors.getMembershipRegistersListCriteria({
      endDate: moment(),
      id: staff,
      startDate: moment(),
    })

    membershipRegistersActions.list({
      onSuccess: this.handleListSuccess,
      params: {
        criteria,
        groups: MEMBERSHIP_REGISTERS_GROUP,
      },
    })
  }

  handleListSuccess = ({ data }) => {
    const { membershipsActions } = this.props
    const item = data.length ? data[0] : null

    if (item) {
      membershipsActions.setSelectedStaff(item)
    }
  }

  isCreatePinPage = () => {
    const { location: { pathname } } = this.props

    return pathname === generateRoute('STAFF_REGISTER.CREATE_PIN')
  }

  handleSubmit = ({ password, username }) => {
    const { authActions } = this.props

    authActions.loginStaffRegister(username, password, {
      onSuccess: this.handleGetMe,
    })
  }

  handleGetMe = (response) => {
    const { authenticationActions } = this.props
    const { data: { token } } = response

    authenticationActions.authMe({
      onSuccess: this.redirect,
      params: [token],
    })
  }

  redirect = ({ data }) => {
    const { navigate } = this.props
    const { user } = data || {}
    const { membership } = user || {}
    const { id } = membership || {}

    if (this.isCreatePinPage()) {
      return navigate(`${generateRoute('STAFF_REGISTER.CREATE_PIN.CHOOSE_NEW_PIN')}?staff=${id}`)
    }

    return navigate(`${generateRoute('STAFF_REGISTER.FORGOT_PIN.CHOOSE_NEW_PIN')}?staff=${id}`)
  }

  handleCancelButtonClick = () => {
    const { location, memberDetails, navigate } = this.props
    const { query } = location
    const { staff } = query
    const { hasPin } = memberDetails

    if (hasPin) {
      return navigate(`${generateRoute('STAFF_REGISTER.AUTHENTICATION')}?staff=${staff}`)
    }

    return navigate(generateRoute('STAFF_REGISTER.LIST'))
  }

  handleContinueButtonClick = () => {
    const { submitForm } = this.props

    submitForm()
  }

  render() {
    const { errorMessage, isAuthFetching, isFetching, memberDetails } = this.props

    return (
      <CredentialsView
        errorMessage={errorMessage}
        isAuthFetching={isAuthFetching}
        isCreatePinPage={this.isCreatePinPage()}
        isFetching={isFetching}
        memberDetails={memberDetails}
        onCancelButtonClick={this.handleCancelButtonClick}
        onContinueButtonClick={this.handleContinueButtonClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  authAuthenticationState,
  membershipRegistersListState,
  membershipsSelectors,
}) => ({
  errorMessage: appSelectors.getErrorMessages(authAuthenticationState),
  isAuthFetching: appSelectors.getIsFetching(authAuthenticationState),
  isFetching: appSelectors.getIsFetching(membershipRegistersListState),
  memberDetails: membershipsSelectors.getMembershipFormattedDataSelector(state),
})

const mapDispatch = {
  submitForm: () => submit(CREDENTIALS_FORM),
}

const enhance = compose(
  withAppService,
  withStaffRegisterWrapper,
  connect(mapState, mapDispatch),
)

export default enhance(CredentialsContainer)
