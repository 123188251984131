import moment from 'moment'
import _ from 'lodash'

import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'
import { DEFAULT_DATE_FORMAT } from 'constants/date'

const getNurseryIntegrationsPayoutsState = (state: RootState) => state.nurseryIntegrations.list

export const getPayouts = createSelector(
  [getNurseryIntegrationsPayoutsState],
  ({ data }) => data,
)

export const getCriteria = ({ currency, dateRange, status }) => {
  let statusIndex = 0
  const criteria = []

  _.each(status, (value, key) => {
    if (value) {
      criteria.push({
        field: `status[${statusIndex}]`,
        value: key,
      })

      statusIndex += 1
    }
  })

  if (currency) {
    criteria.push({
      field: 'currency',
      value: currency,
    })
  }

  if (dateRange) {
    const [after, before] = dateRange

    if (after) {
      criteria.push({
        comparator: 'after',
        field: 'arrival',
        value: moment(after).format(DEFAULT_DATE_FORMAT),
      })
    }

    if (before) {
      criteria.push({
        comparator: 'before',
        field: 'arrival',
        value: moment(before).format(DEFAULT_DATE_FORMAT),
      })
    }
  }

  return criteria
}
