import React, { PropsWithChildren } from 'react'

import { StyledTdSummary } from './TableStyled'

interface TableSummaryTdProps {
  colSpan?: number
}

const TableSummaryTd: React.FC<PropsWithChildren<TableSummaryTdProps>> = ({ children, colSpan }) => (
  <StyledTdSummary colSpan={colSpan}>
    {children}
  </StyledTdSummary>
)

export default TableSummaryTd
