import {
  CLEAR_REGISTER_STATISTICS,
  GET_REGISTER_STATISTICS,
  GET_REGISTER_STATISTICS_FAILED,
  GET_REGISTER_STATISTICS_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case GET_REGISTER_STATISTICS:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_REGISTER_STATISTICS_SUCCESS:
      return { ...state, data: payload.data, isFetching: false }
    case GET_REGISTER_STATISTICS_FAILED:
      return { ...state, error, isFetching: false }
    case CLEAR_REGISTER_STATISTICS:
      return initialState
    default:
      return state
  }
}
