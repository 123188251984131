import React, { PropsWithChildren } from 'react'
import { Link } from 'react-router'

import { Icon } from 'components'

import { StyledAction } from './ListStandardStyled'

interface ListStandardActionProps {
  icon: IconType
  onClick?: () => void
  to?: string
}

const ListStandardAction: React.FC<PropsWithChildren<ListStandardActionProps>> = ({
  icon,
  onClick,
  to,
}) => (
  <StyledAction>
    <Link to={to} onClick={onClick}>
      <Icon
        height={20}
        icon={icon}
      />
    </Link>
  </StyledAction>
)

export default ListStandardAction
