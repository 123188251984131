import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

const getEnquiryReports = (state) => state.enquiryReports

export const getEnquiryReportsViewingPerformanceSelector = createSelector(
  [getEnquiryReports],
  (state) => state?.viewingPerformance,
)

export const getViewingPerformanceCriteria = ({ dateRange, nursery }) => {
  const criteria = []

  if (dateRange?.after && dateRange?.before) {
    criteria.push({
      field: 'dateFrom',
      value: moment(dateRange.after).format(DEFAULT_DATE_FORMAT),
    })

    criteria.push({
      field: 'dateTo',
      value: moment(dateRange.before).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (nursery) {
    criteria.push({
      field: 'nursery',
      value: nursery.value,
    })
  }

  return criteria
}

export const getEnquiryReportsViewingPerformanceDataSelector = createSelector(
  [getEnquiryReportsViewingPerformanceSelector],
  (state) => state.data,
)
