import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { getFormValues } from 'redux-form'

import { NURSERY_DISCOUNT_TYPE_OPTIONS } from 'services/product/nurseryDiscountsV3/constants'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withModalService } from 'services/utils/modal'
import { withNurseryDiscountsService } from 'services/legacy/nurseryDiscounts'
import { withChildDiscountsService } from 'services/legacy/childDiscounts'
import { withRouter } from 'services/router'

import ChildDiscountsAddView from './ChildDiscountsAddView'

import { FORM_NAME } from './components/ChildDiscountsAddForm/ChildDiscountsAddForm'

const CHILD_DISCOUNTS_GROUPS = {
  read: [
    'childDiscount.nurseryDiscount',
    'childDiscount.child',
    'nurseryDiscount',
  ],
}

class ChildDiscountsAddContainer extends Component {
  componentDidMount() {
    const {
      childDiscountsActions,
      isEdit,
      params,
    } = this.props

    if (isEdit) {
      childDiscountsActions.get(params.id, { groups: CHILD_DISCOUNTS_GROUPS })
    }
  }

  componentWillUnmount() {
    const { childDiscountsActions } = this.props

    childDiscountsActions.clear()
  }

  goToDiscounts = () => {
    const { navigate, params } = this.props

    navigate(generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.DISCOUNTS', { childId: params.childId }))
  }

  handleSubmitSuccess = () => {
    this.goToDiscounts()
  }

  handleSubmit = (fields) => {
    const {
      childDiscountsActions,
      childDiscountsSelectors,
      childDiscountsSingleState,
      isEdit,
      params,
    } = this.props
    const { childId } = params

    const payload = childDiscountsSelectors.getPayload({ ...fields, childId })
    const apiParams = { groups: CHILD_DISCOUNTS_GROUPS }

    if (isEdit) {
      return childDiscountsActions.update(
        childDiscountsSingleState.data.id,
        payload,
        apiParams,
        this.handleSubmitSuccess,
      )
    }

    return childDiscountsActions.create(payload, apiParams, this.handleSubmitSuccess)
  }

  archiveDiscount = (archived) => () => {
    const {
      childDiscountsActions,
      childDiscountsSelectors,
      nurseryDiscount,
      params,
      type,
    } = this.props
    const { id } = params

    const payload = childDiscountsSelectors.getArchivePayload({ archived, id, nurseryDiscount, type })
    const apiParams = { groups: CHILD_DISCOUNTS_GROUPS }

    return childDiscountsActions.update(id, payload, apiParams, this.handleSubmitSuccess)
  }

  getArchiveText = (archived) => {
    if (archived) {
      return 'Are you sure you want to archive this discount? '
        .concat('Please note: past invoices showing this discount will not be affected.')
    }

    return 'Are you sure you want to unarchive this discount? You can archive it again at any time.'
  }

  handleArchiveClick = (archived) => () => {
    const { modalActions, modalConsts } = this.props

    const label = archived ? 'archive' : 'unarchive'
    const text = this.getArchiveText(archived)

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: label,
      icon: label,
      onConfirm: this.archiveDiscount(archived),
      text,
    })
  }

  render() {
    const {
      child,
      childDiscountsSingleState,
      errorMessages,
      formValues,
      initialValues,
      isArchived,
      isEdit,
      isOtherSelected,
      isPercentageSelected,
      nurseryDiscountDescription,
    } = this.props

    const isLoading = childDiscountsSingleState.isFetching || (isEdit && !initialValues)
    const isFormLoading = childDiscountsSingleState.isSubmitting

    return (
      <ChildDiscountsAddView
        child={child}
        discountTypeOptions={NURSERY_DISCOUNT_TYPE_OPTIONS}
        errorMessages={errorMessages}
        formValues={formValues}
        initialValues={initialValues}
        isArchived={isArchived}
        isEdit={isEdit}
        isFormLoading={isFormLoading}
        isLoading={isLoading}
        isOtherSelected={isOtherSelected}
        isPercentageSelected={isPercentageSelected}
        nurseryDiscountDescription={nurseryDiscountDescription}
        onArchiveClick={this.handleArchiveClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childDiscountsSelectors,
  childDiscountsSingleState,
  childSelectors,
  nurseryDiscountsSelectors,
  params,
}) => ({
  child: childSelectors.getChildSelector(state),
  errorMessages: appSelectors.getErrorMessages(childDiscountsSingleState),
  formValues: getFormValues(FORM_NAME)(state),
  initialValues: childDiscountsSelectors.getInitialValues(!!params.id)(state),
  isArchived: childDiscountsSelectors.isArchivedSelector(state),
  isEdit: !!params.id,
  isOtherSelected: childDiscountsSelectors.isOtherSelected(FORM_NAME)(state),
  isPercentageSelected: nurseryDiscountsSelectors.isPercentageSelected(FORM_NAME)(state),
  nurseryDiscount: childDiscountsSelectors.nurseryDiscountSelector(FORM_NAME)(state),
  nurseryDiscountDescription: childDiscountsSelectors.getNurseryDiscountOptionDescription(FORM_NAME)(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  type: childDiscountsSelectors.typeSelector(FORM_NAME)(state),
})

const enhance = compose(
  withRouter,
  withAppService,
  withChildService,
  withModalService,
  withNurseryDiscountsService,
  withChildDiscountsService,
  connect(mapState),
)

export default enhance(ChildDiscountsAddContainer)
