import styled from 'styled-components'

export const StyledHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 15px;
  margin-bottom: 20px;
  
  @media(max-width: 680px) {
    flex-direction: column-reverse;
    display: flex;
    align-items: center;
  }
`
