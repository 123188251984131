import { createSelector } from 'reselect'

const getCreditNotes = (state) => state.creditNotes

const getCreditNotesStatistics = createSelector([getCreditNotes], (state) => state.statistics)

export const getTotalCreditNoteAmount = createSelector(
  [getCreditNotesStatistics],
  ({ data }) => data?.amount,
)
