import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  UPDATE_BATCH_NURSERY_SESSIONS,
  UPDATE_BATCH_NURSERY_SESSIONS_FAILED,
  UPDATE_BATCH_NURSERY_SESSIONS_SUCCESS,
} from './constants'

export const updateBatch = (options: BasicActionOptionsProps) => ({
  nurserySessionsApiClient,
}) => generateBasicActions.update({
  constants: {
    failed: UPDATE_BATCH_NURSERY_SESSIONS_FAILED,
    init: UPDATE_BATCH_NURSERY_SESSIONS,
    success: UPDATE_BATCH_NURSERY_SESSIONS_SUCCESS,
  },
  options,
  service: nurserySessionsApiClient,
  serviceMethod: 'updateBatch',
})
