import { flatten, nest } from 'utils/flatnest'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { LEAVE_TYPE_STATUSES, LEAVE_TYPE_STATUSES_DROPDOWN } from 'services/legacy/leaveTypes/constants'
import { FEATURE_FLAGS, ROLES } from 'constants/security'

import { withAppService } from 'services/app'
import { withPaginationUtils } from 'services/utils/pagination'
import { withLeaveTypesService } from 'services/legacy/leaveTypes'

import { getTableData } from './StaffLeaveTypesHelpers'

import StaffLeaveTypesView from './StaffLeaveTypesView'

class StaffLeaveTypesContainer extends Component {
  constructor(props) {
    super(props)

    const { query } = props.location
    const { leaveTypeStatus } = nest(query)

    this.state = {
      filters: {
        leaveTypeStatus: leaveTypeStatus || LEAVE_TYPE_STATUSES.ACTIVE,
      },
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { leaveTypesActions } = this.props

    leaveTypesActions.clearList()
  }

  fetch = () => {
    const {
      leaveTypesActions,
      leaveTypesSelectors,
      paginationUtils,
      setLocationQuery,
    } = this.props
    const { filters } = this.state
    const { page } = paginationUtils

    const criteria = leaveTypesSelectors.getListCriteria({ filters })

    setLocationQuery(flatten(filters))

    leaveTypesActions.list({
      params: {
        criteria,
        order: {
          sortField: 'name',
          sortOrder: 'asc',
        },
        page,
      },
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleLeaveTypeStatusChange = (leaveTypeStatus) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          leaveTypeStatus: leaveTypeStatus?.value,
        },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  render() {
    const {
      errorMessages,
      isFetching,
      leaveTypesTableData,
      paginationUtils,
      totalResults,
    } = this.props
    const { filters } = this.state
    const { leaveTypeStatus } = filters
    const { getPageCount, page, perPage } = paginationUtils
    const pageCount = getPageCount(totalResults)
    const tableData = getTableData(leaveTypesTableData)
    const isEmpty = !leaveTypesTableData?.length

    return (
      <StaffLeaveTypesView
        errorMessages={errorMessages}
        isEmpty={isEmpty}
        isLoading={isFetching}
        leaveTypeStatus={leaveTypeStatus}
        leaveTypeStatusOptions={LEAVE_TYPE_STATUSES_DROPDOWN}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        tableData={tableData}
        totalResults={totalResults}
        onLeaveTypeStatusFilterChange={this.handleLeaveTypeStatusChange}
        onPageChange={this.handlePageChange}
      />
    )
  }
}

StaffLeaveTypesContainer.authParams = {
  flags: [FEATURE_FLAGS.STAFF_REGISTER],
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

const mapState = (state, {
  appSelectors,
  leaveTypesListState,
  leaveTypesSelectors,
}) => ({
  errorMessages: appSelectors.getErrorMessages(leaveTypesListState),
  isFetching: appSelectors.getIsFetching(leaveTypesListState),
  leaveTypesTableData: leaveTypesSelectors.getLeaveTypesListDataSelector(state),
  totalResults: leaveTypesSelectors.getLeaveTypesTotalResultsSelector(state),
})

const enhance = compose(
  withAppService,
  withPaginationUtils,
  withLeaveTypesService,
  connect(mapState),
)

export default enhance(StaffLeaveTypesContainer)
