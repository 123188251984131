import { generateBasicActions } from 'utils/service'

import { CREATE_COMMENT, CREATE_COMMENT_FAILED, CREATE_COMMENT_SUCCESS } from './constants'

export const create = (options = {}) => ({ commentsApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: CREATE_COMMENT_FAILED,
      init: CREATE_COMMENT,
      success: CREATE_COMMENT_SUCCESS,
    },
    options,
    service: commentsApiClient,
    serviceMethod: 'create',
  })
)
