import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'
import selectors from './selectors'

import { NurseryContextCommonState } from './common/reducer'

export interface withNurseryContextServiceProps {
  nurseryContextActions: typeof actions
  nurseryContextCommonState: NurseryContextCommonState
  nurseryContextSelectors: typeof selectors
}

const withNurseryContextService = <P extends withNurseryContextServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ nurseryContext }) => ({ nurseryContext })

  const mapDispatch = injectActions('nurseryContextActions', actions)

  const Component = ({ nurseryContext, nurseryContextActions, ...other }) => (
    <WrappedComponent
      nurseryContextActions={nurseryContextActions}
      nurseryContextCommonState={nurseryContext.common}
      nurseryContextSelectors={selectors}
      {...other as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withNurseryContextService
