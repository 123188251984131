import { createSelector } from 'reselect'

const getEnquirySettings = (state) => state.enquirySettings

export const getEnquirySettingsListSelector = createSelector(
  [getEnquirySettings],
  (state) => state?.list,
)

export const getEnquirySettingsListDataSelector = createSelector(
  [getEnquirySettingsListSelector],
  (state) => state.data,
)

export const getCriteria = (filters) => {
  const { archived = false, name, type } = filters || {}

  const criteria = []

  if (type) {
    criteria.push({
      field: 'type',
      value: type,
    })
  }

  if (null !== archived && undefined !== archived) {
    criteria.push({
      field: 'archived',
      value: +archived,
    })
  }

  if (name) {
    criteria.push({
      field: 'name',
      value: name,
    })
  }

  return criteria
}
