import { QueryPropsV2 } from 'constants/service'
import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

/* eslint sort-keys:0 */
class OrganizationApiClient extends ServiceBase {
  create = (payload) => this.request({
    body: payload,
    method: 'POST',
    path: '/v2/organizations',
  })

  get = (organizationId, params = {}) => this.request({
    path: `/v2/organizations/${organizationId}?${getQueryStringV2(params)}`,
  })

  listFlags = (organizationId, params = {}) => this.request({
    path: `/v2/organizations/${organizationId}/flags?${getQueryStringV2(params)}`,
  })

  list = (params = {}) => this.request({
    path: `/v2/organizations?${getQueryStringV2(params)}`,
  })

  update = (organizationId, params = {}, payload) => this.request({
    body: payload,
    method: 'PUT',
    path: `/v2/organizations/${organizationId}?${getQueryStringV2(params)}`,
  })

  updateFlag = (organizationId, flag, params = {}, payload) => this.request({
    body: payload,
    method: 'PUT',
    path: `/v2/organizations/${organizationId}/flags/${flag}?${getQueryStringV2(params)}`,
  })

  updateNewFinanceMigration = (id: string, params: QueryPropsV2, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/organizations/${id}/migrate_finance?${getQueryStringV2(params)}`,
  })
}

export default OrganizationApiClient
