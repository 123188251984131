import OrganisationsWrapper from 'module/Organisations/index'
import OrganisationsList from 'module/Organisations/OrganisationsList'
import OrganisationsAdd from 'module/Organisations/OrganisationsAdd'
import OrganisationsPreview from 'module/Organisations/OrganisationsPreview'

import i18n from 'translations'

export const ROUTE_NAMES = {
  'ORGANISATIONS.ADD': 'ORGANISATIONS.ADD',
  'ORGANISATIONS.DETAILS': 'ORGANISATIONS.DETAILS',
  'ORGANISATIONS.EDIT': 'ORGANISATIONS.EDIT',
  'ORGANISATIONS.INDEX': 'ORGANISATIONS.INDEX',
}

const OrganisationsRouting = {
  childRoutes: [
    {
      component: OrganisationsAdd,
      name: ROUTE_NAMES['ORGANISATIONS.ADD'],
      path: 'add',
      title: i18n.t('module:Organisations:Add:title'),
    },
    {
      childRoutes: [
        {
          component: OrganisationsAdd,
          name: ROUTE_NAMES['ORGANISATIONS.EDIT'],
          path: 'edit',
          title: i18n.t('module:Organisations:Edit:title'),
        },
      ],
      indexRoute: {
        component: OrganisationsPreview,
      },
      name: ROUTE_NAMES['ORGANISATIONS.DETAILS'],
      path: ':organisationId',
      title: i18n.t('module:Organisations:Preview:title'),
    },
  ],
  component: OrganisationsWrapper,
  indexRoute: {
    component: OrganisationsList,
  },
  name: ROUTE_NAMES['ORGANISATIONS.INDEX'],
  path: 'organisations',
  title: i18n.t('module:Organisations:title'),
}

OrganisationsRouting.ROUTE_NAMES = ROUTE_NAMES

export default OrganisationsRouting
