import _ from 'lodash'

import { mergeData } from 'utils/data'
import { getBasicListInitialState } from 'utils/reducer'

import {
  CLEAR_REGULAR_UNAVAILABLE_TIMES_LIST,
  LIST_REGULAR_UNAVAILABLE_TIMES,
  LIST_REGULAR_UNAVAILABLE_TIMES_FAILED,
  LIST_REGULAR_UNAVAILABLE_TIMES_SUCCESS,
} from './constants'

const initialState = getBasicListInitialState

export default (state = initialState, { error, mergeResult, payload, type }) => {
  switch (type) {
    case LIST_REGULAR_UNAVAILABLE_TIMES:
      return { ...state, error: initialState.error, isFetching: true }
    case LIST_REGULAR_UNAVAILABLE_TIMES_SUCCESS: {
      const { done, response: { data, meta } } = payload

      return {
        ...state,
        data: _.uniqBy(mergeData(state.data, data, mergeResult), ({ id }) => id),
        isFetching: !done,
        meta,
      }
    }
    case LIST_REGULAR_UNAVAILABLE_TIMES_FAILED:
      return { ...state, error, isFetching: false }
    case CLEAR_REGULAR_UNAVAILABLE_TIMES_LIST:
      return initialState
    default:
      return state
  }
}
