import { Moment } from 'moment'
import _ from 'lodash'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { getDateString } from 'utils/date'

import { PeriodFields } from 'module/Management/ClosureDays/ClosureDates/Add/models'
import { Option } from 'constants/models'
import { ClosureDayType } from 'services/closureDayTypes/models'
import { ActionType, BatchBody, Period, PeriodsRootState, Type } from '../models'

const getPeriodsState = (state: PeriodsRootState) => state?.periods

export const getPeriodsList = createSelector([getPeriodsState], (state) => state?.list?.data)

interface GetCriteriaProp {
  archived?: number
  name?: string
  nurseryAcademicYearId?: string
  type?: Type
}

export const getCriteria = ({ archived, name, nurseryAcademicYearId, type }: GetCriteriaProp) => {
  const criteria = []

  if (null !== archived && undefined !== archived) {
    criteria.push({
      field: 'archived',
      value: archived,
    })
  }

  if (type) {
    criteria.push({
      field: 'type',
      value: type,
    })
  }

  if (nurseryAcademicYearId) {
    criteria.push({
      field: 'nurseryAcademicYear',
      value: nurseryAcademicYearId,
    })
  }

  if (name) {
    criteria.push({
      field: 'name',
      value: name,
    })
  }

  return criteria
}

interface GetPeriodBodyProps {
  action?: ActionType
  closureDayType?: ClosureDayType
  dateRange?: Moment[]
  id?: number
  name?: string
  nurseryAcademicYearId?: number | string
  nurseryId?: number
}

const getPeriodBody = ({
  action,
  closureDayType,
  dateRange,
  id,
  name,
  nurseryAcademicYearId,
  nurseryId,
}: GetPeriodBodyProps): BatchBody => ({
  action,
  period: {
    closureDayType,
    id,
    name,
    nursery: { id: nurseryId },
    nurseryAcademicYear: { id: nurseryAcademicYearId },
    periodTimes: [
      {
        endDate: dateRange[1].format(DEFAULT_DATE_FORMAT),
        startDate: dateRange[0].format(DEFAULT_DATE_FORMAT),
      },
    ],
    type: Type.closure,
  },
})

interface GetBatchBody {
  closurePeriods?: PeriodFields[]
  deletedItems?: number[]
  nurseryAcademicYear?: Option
  nurseryId?: number
  periods?: Period[]
}

export const getBatchBody = ({
  closurePeriods,
  deletedItems,
  nurseryAcademicYear,
  nurseryId,
  periods,
}: GetBatchBody): BatchBody[] => [
  ..._.reduce(closurePeriods, (updatedClosurePeriods, { closureDayType, dateRange, id, name }) => {
    if (!id) {
      return [
        ...updatedClosurePeriods,
        getPeriodBody({
          action: ActionType.create,
          closureDayType: { id: +closureDayType.value },
          dateRange,
          id: undefined,
          name,
          nurseryAcademicYearId: nurseryAcademicYear?.value,
          nurseryId,
        }),
      ]
    }

    const existingPeriod = _.find(periods, { id })

    if (existingPeriod && !(
      getDateString(existingPeriod.periodTimes[0].startDate) === getDateString(dateRange[0])
      && getDateString(existingPeriod.periodTimes[0].endDate) === getDateString(dateRange[1])
      && existingPeriod.closureDayType.id === +closureDayType.value
    )) {
      return [
        ...updatedClosurePeriods,
        getPeriodBody({
          action: ActionType.update,
          closureDayType: { id: +closureDayType.value },
          dateRange,
          id,
          name,
          nurseryAcademicYearId: nurseryAcademicYear?.value,
          nurseryId,
        }),
      ]
    }

    return [...updatedClosurePeriods]
  }, []),
  ..._.map(deletedItems, (id) => ({
    action: ActionType.delete,
    period: {
      id,
    },
  })),
]
