import {
  CHILDREN_DROPDOWN_LIST,
  CHILDREN_DROPDOWN_LIST_FAILED,
  CHILDREN_DROPDOWN_LIST_SUCCESS,
  CLEAR_CHILDREN_DROPDOWN_LIST,
} from './constants'

export const dropdownList = (params = {}, mergeResult, onSuccess) => ({ childApiClient }) => async (dispatch) => {
  dispatch({ payload: null, type: CHILDREN_DROPDOWN_LIST })

  await childApiClient.list(params).then(
    (data) => {
      dispatch({
        mergeResult,
        payload: data,
        type: CHILDREN_DROPDOWN_LIST_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: CHILDREN_DROPDOWN_LIST_FAILED,
      })
    },
  )
}

export const clearDropdownList = () => ({
  type: CLEAR_CHILDREN_DROPDOWN_LIST,
})
