import Table, { TableColumn } from './Table'
import TableBlankTd from './TableBlankTd'
import TableBlankTh from './TableBlankTh'
import TableSortableTh from './TableSortableTh'
import TableSummaryTd from './TableSummaryTd'
import TableTd from './TableTd'
import TableTh from './TableTh'
import TableTbody from './TableTbody'
import TableThead from './TableThead'
import TableTr from './TableTr'

Table.BlankTd = TableBlankTd
Table.BlankTh = TableBlankTh
Table.SortableTh = TableSortableTh
Table.SummaryTd = TableSummaryTd
Table.Tbody = TableTbody
Table.Thead = TableThead
Table.Td = TableTd
Table.Th = TableTh
Table.Tr = TableTr

export { TableColumn }
export default Table
