import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'
import { RootState } from 'core/reducers'

import { injectActions } from 'utils/service'

import actions from './actions'
import selectors from './selectors'
import { NurserySessionsListState } from './list/reducer'
import { NurserySessionsSingleState } from './single/reducer'

export interface withNurserySessionsV3ServiceProps {
  nurserySessionsV3Actions: typeof actions
  nurserySessionsV3ListState: NurserySessionsListState
  nurserySessionsV3Selectors: typeof selectors
  nurserySessionsV3SingleState: NurserySessionsSingleState
}

const withNurserySessionsV3Service = <P extends withNurserySessionsV3ServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ nurserySessionsV3 }: RootState) => ({ nurserySessionsV3 })

  const mapDispatch = injectActions('nurserySessionsV3Actions', actions)

  const Component = ({ nurserySessionsV3, nurserySessionsV3Actions, ...others }) => (
    <WrappedComponent
      nurserySessionsV3Actions={nurserySessionsV3Actions}
      nurserySessionsV3ListState={nurserySessionsV3.list}
      nurserySessionsV3Selectors={selectors}
      nurserySessionsV3SingleState={nurserySessionsV3.single}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withNurserySessionsV3Service
