import React from 'react'

import { SESSION_TYPE } from 'services/legacy/childAdhocSessions/constants'

import { generateRoute } from 'utils/routing'

import { Banner, Button, Callout, Page, Section, Spinner, StickyFooter } from 'components'

import i18n from 'translations'

import ChildAdhocSessionsAddForm from './components/ChildAdhocSessionsAddForm'

const prefix = 'module:Children:Child:BookingPattern:ExtraSessions:Add:'

const ChildAdhocSessionsAddView = ({
  child,
  childAdHocSession,
  cost,
  errorMessages,
  initialValues,
  isArchived,
  isEdit,
  isFormLoading,
  isLoading,
  isRequestingExtraSessionsFFOn,
  onArchiveClick,
  onSubmit,
}) => {
  const { childExtraSession } = childAdHocSession || {}
  const { id: requestedExtraSessionId, type } = childExtraSession || {}
  const isLinkedToRequestedExtraSession = !!requestedExtraSessionId
    && SESSION_TYPE.PARENT === type
    && isRequestingExtraSessionsFFOn

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    const { birthDate: childDob, id: childId } = child

    return (
      <Section
        actions={isEdit && (
          <Section.Actions
            options={[{
              onClick: onArchiveClick(!isArchived),
              type: isArchived ? 'unarchive' : 'archive',
            }]}
          />
        )}
        title={isEdit
          ? i18n.t(prefix.concat('Title:edit'))
          : i18n.t(prefix.concat('Title:add'))}
      >
        <Callout content={errorMessages} error />
        {isLinkedToRequestedExtraSession && (
          <Banner.Info>
            {i18n.t(prefix.concat('LinkedToRequestedExtraSession:banner'))}
          </Banner.Info>
        )}
        <ChildAdhocSessionsAddForm
          childDob={childDob}
          childId={childId}
          cost={cost}
          initialValues={initialValues}
          isLinkedToRequestedExtraSession={isLinkedToRequestedExtraSession}
          isLoading={isFormLoading}
          onSubmit={onSubmit}
        />
        {isLinkedToRequestedExtraSession && (
          <StickyFooter>
            <StickyFooter.Flex />
            <Button
              hierarchy="secondary"
              label={i18n.t(prefix.concat('LinkedToRequestedExtraSession:viewRequest'))}
              to={generateRoute('PARENT_INBOX.REQUESTED_EXTRA_SESSIONS.DETAILS', { id: requestedExtraSessionId })}
              negativeMargins
            />
          </StickyFooter>
        )}
      </Section>
    )
  }

  return (
    <Page isLoading={isLoading}>
      <Page.Content>
        {renderContent()}
      </Page.Content>
    </Page>
  )
}

export default ChildAdhocSessionsAddView
