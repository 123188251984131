import React, { useEffect } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'

import { Type } from 'services/booking/periods/models'

import { withAppService, withAppServiceProps } from 'services/app'
import { withPaginationUtils, withPaginationUtilsProps } from 'services/utils/pagination'
import { withSortUtilsProps, withSortingUtils } from 'services/utils/sorting'
import { withShellService, withShellServiceProps } from 'services/shell'
import { withNurseryAcademicYearsService, withNurseryAcademicYearsServiceProps } from 'services/nurseryAcademicYears'
import { withPeriodsService, withPeriodsServiceProps } from 'services/booking/periods'
import { withRouter, withRouterProps } from 'services/router'

import PreviewView from './PreviewView'
import { getTableData } from './PreviewHelpers'

const PERIOD_GROUPS = {
  read: [
    'period.periodTimes',
    'periodTime',
    'period.closureDayType',
    'closureDayType',
  ],
}

type PreviewContainerProps = withAppServiceProps
  & withPaginationUtilsProps
  & withSortUtilsProps
  & withShellServiceProps
  & withNurseryAcademicYearsServiceProps
  & withPeriodsServiceProps
  & withRouterProps

const mapState = (state: RootState, {
  appSelectors,
  nurseryAcademicYearsSelectors,
  nurseryAcademicYearsSingleState,
  periodsListState,
  periodsSelectors,
}: PreviewContainerProps) => ({
  errorMessages: appSelectors.getErrorMessages(nurseryAcademicYearsSingleState, periodsListState),
  isFetching: appSelectors.getIsFetching(nurseryAcademicYearsSingleState, periodsListState),
  nurseryAcademicYear: nurseryAcademicYearsSelectors.getNurseryAcademicYear(state),
  periods: periodsSelectors.getPeriodsList(state),
  totalResults: appSelectors.getTotalResults(periodsListState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const PreviewContainer: React.FC<PreviewContainerProps & PropsFromRedux> = ({
  errorMessages,
  isFetching,
  nurseryAcademicYear,
  nurseryAcademicYearsActions,
  paginationUtils,
  params,
  periods,
  periodsActions,
  periodsSelectors,
  shellActions,
  sortingUtils,
  totalResults,
}) => {
  const { sortField, sortOrder } = sortingUtils
  const { getPageCount, page, perPage } = paginationUtils

  const fetchData = () => {
    const criteria = periodsSelectors.getCriteria({
      archived: 0,
      nurseryAcademicYearId: params.nurseryAcademicYearId,
      type: Type.closure,
    })

    periodsActions.list({
      params: {
        criteria,
        groups: PERIOD_GROUPS,
        order: {
          sortField,
          sortOrder,
        },
      },
    })
  }

  useEffect(() => {
    nurseryAcademicYearsActions.get({
      onSuccess: ({ data }) => shellActions.setRouteTitle({
        name: 'MANAGEMENT.CLOSURE_DAYS.CLOSURE_DATES.PREVIEW',
        title: data.name,
      }),
      params: [params.nurseryAcademicYearId],
    })

    fetchData()

    return () => {
      nurseryAcademicYearsActions.clear()
      periodsActions.clearList()
    }
  }, [])

  const handlePageChange = (newPage) => {
    const { onPageChange } = paginationUtils

    onPageChange(fetchData)(newPage)
  }

  const handleSortChange = (key) => {
    const { onSortChange } = sortingUtils

    onSortChange(() => handlePageChange(1))(key)
  }

  const tableData = getTableData(periods)
  const pageCount = getPageCount(totalResults)

  return (
    <PreviewView
      errorMessages={errorMessages}
      isLoading={isFetching}
      nurseryAcademicYear={nurseryAcademicYear}
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      sortField={sortField}
      sortOrder={sortOrder}
      tableData={tableData}
      totalResults={totalResults}
      onPageChange={handlePageChange}
      onSortChange={handleSortChange}
    />
  )
}

const enhance = compose(
  withAppService,
  withRouter,
  withPaginationUtils,
  withSortingUtils,
  withNurseryAcademicYearsService,
  withPeriodsService,
  withShellService,
  connector,
)

export default enhance(PreviewContainer)
