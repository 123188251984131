import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Icon } from 'components'

import { StyledIconContainer, StyledIconWrapper } from './PlanViewStyled'

const PlanViewIcon = ({ icon }) => (
  <StyledIconContainer>
    <StyledIconWrapper>
      <Icon color={NEUTRAL_COLOURS.WHITE} height={15} icon={icon} />
    </StyledIconWrapper>
  </StyledIconContainer>
)

export default PlanViewIcon
