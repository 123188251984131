import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { withAppService } from 'services/app'
import { withOrganizationService } from 'services/organization'

import OrganisationsPreviewView from './OrganisationsPreviewView'

const GROUPS = {
  read: [
    'organization.numberOfNurseries',
    'organization.details',
    'organization.organizationSettings',
    'organizationSettings',
    'organizationSettings.localeDetails',
  ],
}

class OrganisationsPreviewContainer extends Component {
  componentDidMount() {
    const { organizationActions, params: { organisationId } } = this.props

    organizationActions.get(organisationId, {
      onSuccess: () => organizationActions.listFlags(organisationId),
      params: { groups: GROUPS },
    })
  }

  componentWillUnmount() {
    const { organizationActions } = this.props

    organizationActions.clearSingle()
  }

  render() {
    const { featureFlags, isFetching, organisation } = this.props

    return (
      <OrganisationsPreviewView
        featureFlags={featureFlags}
        isFetching={isFetching}
        organisation={organisation}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  organizationSelectors,
  organizationSingleState,
}) => ({
  featureFlags: organizationSelectors.getOrganizationFlags(state),
  isFetching: appSelectors.getIsFetching(organizationSingleState),
  organisation: organizationSelectors.getOrganizationData(state),
})

const enhance = compose(
  withAppService,
  withOrganizationService,
  connect(mapState),
)

export default enhance(OrganisationsPreviewContainer)
