import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { stopSubmit } from 'redux-form'

import { withAppService } from 'services/app'
import { withOrganizationService } from 'services/organization'
import { getAuthOrganization } from 'services/security/selectors'
import { withRouter } from 'services/router'

import SettingsInvoiceNumbersEditView, { FORM_NAME } from './SettingsInvoiceNumbersEditView'

const ORGANIZATION_INVOICE_NUMBER_SETTINGS_GROUPS = {
  read: [
    'organization.organizationSettings',
    'organizationSettings.invoice',
    'organizationInvoiceSettings',
    'organizationInvoiceSettings.numbers',
    'invoiceNumbers',
  ],
}

class SettingsInvoiceNumbersEditContainer extends Component {
  componentDidMount() {
    const { authOrganization, organizationActions } = this.props

    const organizationApiParams = { groups: ORGANIZATION_INVOICE_NUMBER_SETTINGS_GROUPS }

    organizationActions.get(authOrganization.id, { params: organizationApiParams })
  }

  handleSuccess = () => {
    const { navigate } = this.props

    navigate('/settings/invoice-numbers')
  }

  handleFail = (error) => {
    const { appSelectors, stopSubmitForm } = this.props

    const fieldValidations = appSelectors.getFieldValidationFromError(error)

    stopSubmitForm(fieldValidations)
  }

  handleSubmit = (fields) => {
    const {
      authOrganization,
      organizationActions,
      organizationSelectors,
      organizationSingleState,
    } = this.props

    const apiParams = { groups: ORGANIZATION_INVOICE_NUMBER_SETTINGS_GROUPS }
    const payload = organizationSelectors.getInvoiceNumberSettingsPayload({
      fields,
      organizationSingleState,
    })

    organizationActions.update(authOrganization.id, {
      onFailed: this.handleFail,
      onSuccess: this.handleSuccess,
      params: apiParams,
      payload,
    })
  }

  render() {
    const {
      errorMessages,
      initialValues,
      isFetching,
      organizationSingleState,
    } = this.props

    return (
      <SettingsInvoiceNumbersEditView
        errorMessages={errorMessages}
        initialValues={initialValues}
        isFormLoading={organizationSingleState.isSubmitting}
        isLoading={isFetching || !initialValues}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  organizationSelectors,
  organizationSingleState,
}) => ({
  authOrganization: getAuthOrganization(state),
  errorMessages: appSelectors.getErrorMessages(organizationSingleState),
  initialValues: organizationSelectors.getInvoiceNumberSettings(state),
  isFetching: appSelectors.getIsFetching(organizationSingleState),
})

const mapDispatch = {
  stopSubmitForm: (fieldErrors) => stopSubmit(FORM_NAME, fieldErrors),
}

const enhance = compose(
  withRouter,
  withAppService,
  withOrganizationService,
  connect(mapState, mapDispatch),
)

export default enhance(SettingsInvoiceNumbersEditContainer)
