import { createSelector } from 'reselect'

const getBalanceAdjustments = (state) => state.balanceAdjustments

export const getBalanceAdjustmentsSingleSelector = createSelector(
  [getBalanceAdjustments],
  (state) => {
    if (!state) {
      return null
    }

    return state.single
  },
)

export const getBalanceAdjustmentItem = createSelector(
  [getBalanceAdjustmentsSingleSelector],
  ({ data }) => data,
)

export const getBody = (data, child) => {
  const body = {}
  const { comment, isCredit, total } = data

  if (child) {
    body.child = {
      id: child.id,
    }
  }

  if (total) {
    body.total = isCredit ? +total : -total
  }

  if (comment) {
    body.comment = comment
  }

  return body
}
