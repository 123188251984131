import React, { useEffect } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { FEATURE_FLAGS, ROLES } from 'constants/security'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'

import BookingAbsenceView from './BookingAbsenceView'

const NURSERY_SETTINGS_GROUP = {
  read: [
    'nursery.settings',
    'nurserySettings.register',
    'nurseryRegisterSettings',
  ],
}

const BookingAbsenceContainer = ({
  nurseriesActions,
  nurseriesSelectors,
  nurseriesSingleState,
  nurseryOptions,
}) => {
  const getNurseryData = () => {
    const nurseryApiParams = { groups: NURSERY_SETTINGS_GROUP }

    nurseriesActions.get(nurseryOptions.id, {
      params: nurseryApiParams,
    })
  }

  useEffect(() => {
    getNurseryData()
  }, [])

  const handleSubmitSuccess = (callback) => (response) => {
    getNurseryData()

    callback(response)
  }

  const handleSubmit = (fields) => (successCallback, failedCallback = () => {}) => {
    const payload = nurseriesSelectors.getNurserySettingsRegisterPayload({
      fields,
      nurseriesSingleState,
    })

    nurseriesActions.update(nurseryOptions.id, {
      onFailed: failedCallback,
      onSuccess: handleSubmitSuccess(successCallback),
      payload,
      silent: true,
    })
  }

  const initialValues = {
    allowParentBookingAbsence: nurseriesSingleState.data?.nurserySettings?.register?.allowParentBookingAbsence,
  }
  const value = nurseriesSingleState.data?.nurserySettings?.register?.allowParentBookingAbsence

  return (
    <BookingAbsenceView
      initialValues={initialValues}
      value={value}
      onSubmit={handleSubmit}
    />
  )
}

BookingAbsenceContainer.authParams = {
  flags: [FEATURE_FLAGS.PARENT_BOOKING_CHILD_LIVES],
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

const mapState = (state, {
  appSelectors,
  nurseriesSingleState,
  params,
}) => ({
  errorMessages: appSelectors.getErrorMessages(nurseriesSingleState),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const enhance = compose(
  withAppService,
  withNurseriesService,
  connect(mapState),
)

export default enhance(BookingAbsenceContainer)

