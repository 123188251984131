import React from 'react'
import i18n from 'translations'

import colors from 'constants/colors'

import { Icon } from 'components'

import {
  StyledContainer,
  StyledIconContainer,
  StyledLeftContainer,
  StyledRightContainer,
  StyledTopTipCopyContainer,
} from './TopTipStyled'

const TopTip = () => (
  <StyledContainer>
    <StyledLeftContainer>
      <StyledIconContainer>
        <Icon color={colors.gold} height={40} icon="star" />
      </StyledIconContainer>
      <StyledTopTipCopyContainer>
        <div>{i18n.t('module:Management:GettingStarted:topTipTitle1')}</div>
        <div>{i18n.t('module:Management:GettingStarted:topTipTitle2')}</div>
      </StyledTopTipCopyContainer>
    </StyledLeftContainer>
    <StyledRightContainer>
      {i18n.t('module:Management:GettingStarted:topTipCopy1')}
      <br />
      {i18n.t('module:Management:GettingStarted:topTipCopy2')}
    </StyledRightContainer>
  </StyledContainer>
)

export default TopTip
