import { Property } from 'csstype'

import React, { PropsWithChildren } from 'react'

import { typeByObject } from 'utils/typescript'

import {
  SIZE_VARIANTS,
  StyledItemContainer,
  StyledItemLabelContainer,
  StyledItemValueContainer,
} from './DefinitionListStyled'

interface DefinitionListItemProps {
  align?: Property.AlignItems
  horizontal?: boolean
  label?: string | React.ReactNode
  margin?: string
  size?: typeByObject<typeof SIZE_VARIANTS>
  textOverflow?: Property.TextOverflow
  value: string | React.ReactNode
  whiteSpace?: Property.WhiteSpace
  wordBreak?: Property.WordBreak
}

const DefinitionListItem: React.FC<PropsWithChildren<DefinitionListItemProps>> = ({
  align,
  horizontal,
  label,
  margin,
  size,
  textOverflow,
  value,
  whiteSpace,
  wordBreak,
}) => (
  <StyledItemContainer
    $align={align}
    $horizontal={horizontal}
    $margin={margin}
    $size={size}
  >
    <StyledItemLabelContainer>
      {label}
    </StyledItemLabelContainer>
    <StyledItemValueContainer
      $horizontal={horizontal}
      $textOverflow={textOverflow}
      $whiteSpace={whiteSpace}
      $wordBreak={wordBreak}
    >
      {value}
    </StyledItemValueContainer>
  </StyledItemContainer>
)

export default DefinitionListItem
