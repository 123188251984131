import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledItemBox = styled.div`
  display: block;
  height: 100%;
  margin: -10px;
  padding: 10px;  
  transition: .2s;
`

export const StyledShiftTimesWrapper = styled.div`
  border-left: 2px solid ${NEUTRAL_COLOURS.GRAY};
  padding-left: 6px;
  margin-top: 10px;
  
  ${({ first }) => first && `
    margin-top: 5px;
  `}
`

export const StyledHeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`
