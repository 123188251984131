import React, { PropsWithChildren, useCallback } from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Icon, Spinner, Typography } from 'components'

import { StyledContainer, StyledIconContainer } from './BigButtonStyled'

interface BigButtonProps {
  disabled?: boolean
  icon?: IconType
  iconHeight?: number
  isSelected?: boolean
  isSubmitting?: boolean
  label?: string
  onChange?: (value: string) => void
  value?: string
}

const BigButton: React.FC<PropsWithChildren<BigButtonProps>> = ({
  disabled,
  icon,
  iconHeight = 70,
  isSelected,
  isSubmitting,
  label,
  onChange,
  value,
}) => {
  const handleChange = useCallback(
    () => {
      if (!isSubmitting && !disabled) {
        return onChange(value)
      }

      return undefined
    },
    [disabled, isSubmitting, onChange, value],
  )

  return (
    <StyledContainer
      $disabled={isSubmitting || disabled}
      $isSelected={isSelected}
      onClick={handleChange}
    >
      <StyledIconContainer>
        {isSelected && isSubmitting
          ? <Spinner light />
          : <Icon color={NEUTRAL_COLOURS.GRAY} height={iconHeight} icon={icon} />}
      </StyledIconContainer>
      <Typography align="center" fontSize={18} padding="20px 20px 10px">
        {label}
      </Typography>
    </StyledContainer>
  )
}

export default BigButton
