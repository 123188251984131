import DetailsBreakdown from './DetailsBreakdown'
import DetailsBreakdownFooter from './DetailsBreakdownFooter'
import DetailsBreakdownHeader from './DetailsBreakdownHeader'
import DetailsBreakdownItem from './DetailsBreakdownItem'
import DetailsBreakdownSection from './DetailsBreakdownSection'

DetailsBreakdown.Footer = DetailsBreakdownFooter
DetailsBreakdown.Header = DetailsBreakdownHeader
DetailsBreakdown.Item = DetailsBreakdownItem
DetailsBreakdown.Section = DetailsBreakdownSection

export default DetailsBreakdown
