import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

const getMembershipRegistersStatisticsSelector = (state) => state.membershipRegisters.statistics

export const getMembershipRegistersStatisticsDataSelector = createSelector(
  [getMembershipRegistersStatisticsSelector],
  (state) => state.data,
)

export const getMembershipRegistersStatisticsCriteria = (filters) => {
  const { endDate, searchBarText, startDate } = filters

  const criteria = [
    {
      field: 'accepted',
      value: 1,
    },
    {
      field: 'archived',
      value: 0,
    },
  ]

  if (startDate) {
    criteria.push({
      comparator: 'after',
      field: 'currentRegisters.date',
      value: moment(startDate).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (endDate) {
    criteria.push({
      comparator: 'before',
      field: 'currentRegisters.date',
      value: moment(endDate).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (searchBarText) {
    criteria.push({
      field: 'concatenable',
      value: searchBarText,
    })
  }

  return criteria
}
