import React from 'react'

import { properties } from 'app-config'

import { StyledContainer } from './XMasIconStyled'

interface XMasIconProps {
  bottom?: string
  height: string
  icon?: string
  marginLeft?: string
  marginTop?: string
  right?: string
  width: string
}

const XMasIcon = ({ bottom, height = '25px', icon, marginLeft, marginTop, right, width = '25px' }: XMasIconProps) => {
  if (!properties.xMasFestiveEnabled) {
    return null
  }

  return (
    <StyledContainer
      $bottom={bottom}
      $height={height}
      $icon={icon}
      $marginLeft={marginLeft}
      $marginTop={marginTop}
      $right={right}
      $width={width}
    />
  )
}

export default XMasIcon
