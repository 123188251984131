import { createSelector } from 'reselect'

const getChildrenLeavingReport = (state) => state.childrenLeavingReport

export const getChildrenLeavingReportNurseries = createSelector(
  [getChildrenLeavingReport],
  (state) => state.nurseries,
)

export const getChildrenLeavingReportNurseriesData = createSelector(
  [getChildrenLeavingReportNurseries],
  (state) => state.data,
)
