export const UPDATE_BATCH_FRAMEWORK_PROGRESS = 'UPDATE_BATCH_FRAMEWORK_PROGRESS'
export const UPDATE_BATCH_FRAMEWORK_PROGRESS_SUCCESS = 'UPDATE_BATCH_FRAMEWORK_PROGRESS_SUCCESS'
export const UPDATE_BATCH_FRAMEWORK_PROGRESS_FAILED = 'UPDATE_BATCH_FRAMEWORK_PROGRESS_FAILED'

export const LIST_OF_FRAMEWORK_PROGRESS = 'LIST_OF_FRAMEWORK_PROGRESS'
export const LIST_OF_FRAMEWORK_PROGRESS_SUCCESS = 'LIST_OF_FRAMEWORK_PROGRESS_SUCCESS'
export const LIST_OF_FRAMEWORK_PROGRESS_FAILED = 'LIST_OF_FRAMEWORK_PROGRESS_FAILED'

export const REMOVE_PROGRESS_EVENT = 'REMOVE_PROGRESS_EVENT'
export const REMOVE_PROGRESS_EVENT_SUCCESS = 'REMOVE_PROGRESS_EVENT_SUCCESS'
export const REMOVE_PROGRESS_EVENT_FAILED = 'REMOVE_PROGRESS_EVENT_FAILED'

export const CLEAR_LIST_OF_FRAMEWORK_PROGRESS = 'CLEAR_LIST_OF_FRAMEWORK_PROGRESS'

export const UPDATE_SINGLE_PROGRESS = 'UPDATE_SINGLE_PROGRESS'
