import _ from 'lodash'
import moment from 'moment'

import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { DEPOSIT_USED_OPTIONS } from 'services/legacy/deposits/constants'
import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'

import { isPositiveNumber, isRequired } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'

import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

import { EmptyState, Form, Hyperlink, Space, Spinner, Typography } from 'components'

import i18n from 'translations'

import InvoiceStatus from '../../components/InvoiceStatus'

export const isPositiveNumberLocal = isPositiveNumber(2)

export const DEPOSITS_ADD_FORM = 'DepositsAddForm'

const AddForm = ({
  childId,
  data,
  depositEditingDisabled,
  depositRefundInvoiced,
  formValues,
  handleSubmit,
  isChildContext,
  isEdit,
  isGeneratingDescription,
  isRefund,
  isSubmitting,
  onRefundClick,
  onSelectChild,
  onSubmit,
  refundDisabled,
}) => {
  const { child, invoices, originalDeposit, type, used } = data || {}

  const renderOriginalDeposit = () => isRefund && (
    <React.Fragment>
      <Typography margin="30px 0 8px" bold>
        {i18n.t('module:Finance:Deposits:Form:originalDeposit')}
      </Typography>
      <Hyperlink
        to={isChildContext
          ? generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.DEPOSITS.EDIT', { childId, depositId: originalDeposit.id })
          : generateRoute('FINANCE.DEPOSITS.EDIT', { depositId: originalDeposit.id })}
        inline
        primary
      >
        {moment(originalDeposit.date).format(DISPLAY_SHORT_DATE_FORMAT)}
        {' '}
        {originalDeposit.description}
      </Hyperlink>
    </React.Fragment>
  )

  const renderInvoice = (invoice) => (
    <Typography margin="0 0 10px">
      <InvoiceStatus
        childId={child?.id}
        key={invoice.id}
        lastInvoice={invoice}
        originalDeposit={originalDeposit}
        type={type}
        used={used}
        isDetailMode
      />
    </Typography>
  )

  const renderInvoiceStatus = () => isEdit && used && (
    <React.Fragment>
      <Typography margin="30px 0 8px" bold>
        {i18n.t('module:Finance:Deposits:Form:invoiceStatus')}
      </Typography>
      {invoices?.length ? _.map(invoices, renderInvoice) : (
        <Typography margin="0 0 10px">
          {i18n.t('module:Finance:Deposits:Form:pendingInvoice')}
        </Typography>
      )}
      <Space space="30px" />
    </React.Fragment>
  )

  const renderForm = () => (
    <React.Fragment>
      <Form.Row label={i18n.t('module:Finance:Deposits:Form:invoiceDescription')}>
        <Form.Row.FlexItem flex="0 0 300px" mobileFull>
          {isGeneratingDescription ? <Spinner /> : (
            <Field
              component={Form.TextField}
              disabled={depositEditingDisabled || depositRefundInvoiced}
              name="description"
              validate={isRequired}
            />
          )}
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={isRefund
          ? i18n.t('module:Finance:Deposits:Form:refundDate')
          : i18n.t('module:Finance:Deposits:Form:depositDate')}
      >
        <Form.Row.FlexItem flex="0 0 300px" mobileFull>
          <Field
            component={Form.DatePicker}
            disabled={depositEditingDisabled || depositRefundInvoiced}
            disabledDays={isRefund && {
              before: originalDeposit.date,
            }}
            name="date"
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <SubdomainCurrencyProvider>
        {({ currencySymbol }) => (
          <Form.Row label={_.upperFirst(i18n.t('global:amount'))}>
            <Form.Row.FlexItem flex="0 0 300px" mobileFull>
              <Field
                component={Form.TextField}
                disabled={depositEditingDisabled || isRefund}
                name="amount"
                placeholder={0.00}
                prefix={currencySymbol}
                prefixWidth="30px"
                type="number"
                validate={(depositEditingDisabled || isRefund)
                  ? undefined
                  : [isRequired, isPositiveNumberLocal]}
              />
            </Form.Row.FlexItem>
          </Form.Row>
        )}
      </SubdomainCurrencyProvider>
      <Form.Row>
        <Form.Row.FlexItem flex="0 0 300px" mobileFull>
          <Field
            component={Form.RadioGroup}
            disabled={depositEditingDisabled || depositRefundInvoiced}
            name="used"
            options={DEPOSIT_USED_OPTIONS}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
    </React.Fragment>
  )

  const renderEmptyState = () => (
    <EmptyState
      icon="children"
      text1={i18n.t('module:Finance:Deposits:Form:emptyState')}
    />
  )

  const renderChildSelect = () => (
    <Form.Row label={_.upperFirst(i18n.t('global:child'))}>
      <Form.Row.FlexItem flex="0 0 300px" mobileFull>
        <Field
          component={Form.InfiniteDropdowns.Children}
          disabled={depositEditingDisabled || isChildContext || isEdit}
          name="child"
          validate={isRequired}
          onChange={onSelectChild}
        />
      </Form.Row.FlexItem>
    </Form.Row>
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {renderChildSelect()}
      {formValues?.child ? renderForm() : renderEmptyState()}
      {renderOriginalDeposit()}
      {renderInvoiceStatus()}
      <Form.FooterActions
        cancelLink={isChildContext
          ? generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.DEPOSITS', { childId })
          : generateRoute('FINANCE.DEPOSITS')}
        isSubmitting={isSubmitting}
        secondary={{
          auth: isEdit && !isRefund,
          disabled: refundDisabled,
          label: i18n.t('module:Finance:Deposits:Form:refund'),
          onClick: onRefundClick,
          tooltip: refundDisabled && i18n.t('module:Finance:Deposits:Form:refundTooltip'),
        }}
        submitAuth={!depositRefundInvoiced}
        submitDisabled={depositEditingDisabled || isGeneratingDescription}
        submitLabel={isEdit
          ? i18n.t('module:Finance:Deposits:Form:saveChanges')
          : i18n.t('module:Finance:Deposits:Form:addDeposit')}
        submitTooltip={depositEditingDisabled && i18n.t('module:Finance:Deposits:Form:addDepositTooltip')}
      />
    </Form>
  )
}

export default reduxForm({ form: DEPOSITS_ADD_FORM })(AddForm)
