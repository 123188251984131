import _ from 'lodash'

import { compose } from 'recompose'

import { Component } from 'react'

import { generateRoute } from 'utils/routing'

import { withShellService } from 'services/shell'
import { withRouter } from 'services/router'

import i18n from 'translations'

import ChildInjuryView from './ChildSafeguardingView'

class ChildInjuryContainer extends Component {
  constructor(props) {
    super(props)

    this.indexRedirect(props)
  }

  componentDidMount() {
    const {
      params: { childId },
      shellActions,
    } = this.props

    shellActions.setSubMenu([
      {
        icon: 'injury-records',
        label: _.startCase(i18n.t('module:Safeguarding:Routing:accidentsIncidents')),
        to: generateRoute('CHILDREN.CHILD.SAFEGUARDING.INJURY', { childId }),
      },
      {
        icon: 'home-accidents',
        label: _.startCase(i18n.t('module:Safeguarding:Routing:homeAccidents')),
        to: generateRoute('CHILDREN.CHILD.SAFEGUARDING.HOME_ACCIDENTS', { childId }),
      },
    ])
  }

  shouldComponentUpdate(nextProps) {
    this.indexRedirect(nextProps)

    return true
  }

  componentWillUnmount() {
    const { shellActions } = this.props

    shellActions.clearSubMenu()
  }

  indexRedirect = (props) => {
    const { location: { pathname }, navigate, params: { childId } } = props

    if (generateRoute('CHILDREN.CHILD.SAFEGUARDING', { childId }) === pathname) {
      navigate(generateRoute('CHILDREN.CHILD.SAFEGUARDING.INJURY', { childId }))
    }
  }

  render() {
    const { children } = this.props

    return (
      <ChildInjuryView>
        {children}
      </ChildInjuryView>
    )
  }
}

const enhance = compose(
  withRouter,
  withShellService,
)

export default enhance(ChildInjuryContainer)
