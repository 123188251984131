import React, { PropsWithChildren } from 'react'

import { StyledGroupContainer } from './FooterActionsStyled'

const FooterActionsGroup: React.FC<PropsWithChildren<any>> = ({ children }) => (
  <StyledGroupContainer>
    {children}
  </StyledGroupContainer>
)

export default FooterActionsGroup
