import _ from 'lodash'

import React, { useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'
import { getFormValues } from 'redux-form'

import { BasicModalProps } from 'modals'
import { Child } from 'services/child/models'
// eslint-disable-next-line max-len, import-newlines/enforce
import { RegularBookingsAddFormValues } from 'module/Children/Child/ChildBookingPattern/RegularBookings/RegularBookingsAdd/components/RegularBookingsAddForm/RegularBookingsAddForm'
import { ChildProductTypes } from 'services/booking/childProducts/constants'
import { DAYS_OF_WEEK_LIST } from 'constants/date'
import { NURSERY_SESSIONS_FILTER } from 'services/product/nurserySessionsV3/constants'
import { ROLES } from 'constants/security'

import auth from 'utils/auth'
import { checkTimeRangeOverlap, removeDate, removeSeconds } from 'utils/date'

import { withChildService, withChildServiceProps } from 'services/legacy/child'
import { withChildBookingService, withChildBookingServiceProps } from 'services/booking/childBooking'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withNurserySessionsV3Service, withNurserySessionsV3ServiceProps } from 'services/product/nurserySessionsV3'
import { withNurseryContextService, withNurseryContextServiceProps } from 'services/nurseryContext'

import AddSessionToRegularBookingsModalView from './AddSessionToRegularBookingsModalView'
import {
  ADD_SESSION_TO_REGULAR_BOOKINGS_MODAL_FORM,
  AddSessionToRegularBookingsModalFormValues,
} from './components/AddSessionToRegularBookingsModalForm'

const SESSIONS_GROUPS = {
  read: ['nurserySession'],
}

export interface AddSessionToRegularBookingsModalProps {
  alternate: number
  child: Child
  onSuccess: (values: AddSessionToRegularBookingsModalFormValues, price: number) => void
  regularBookingsFormValues: RegularBookingsAddFormValues
}

const mapState = (state, { nurseryContextSelectors }) => ({
  formValues: getFormValues(
    ADD_SESSION_TO_REGULAR_BOOKINGS_MODAL_FORM,
  )(state) as AddSessionToRegularBookingsModalFormValues,
  hasAccessToViewPrice: auth.SELECTORS.getIsAuthorised(state, {
    roles: [
      ROLES.ORGANIZATION_DIRECTOR,
      ROLES.ORGANIZATION_NATIONAL_ADMIN,
      ROLES.ORGANIZATION_FINANCE_ADMIN,
      ROLES.ORGANIZATION_LINE_MANAGER,
      ROLES.NURSERY_MANAGER,
      ROLES.NURSERY_ADMIN,
      ROLES.SUPER_ADMIN,
    ],
  }),
  nurseryOpeningDays: nurseryContextSelectors.getNurseryOpeningDays(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type AddSessionToRegularBookingsModalContainerFullProps = PropsFromRedux
  & AddSessionToRegularBookingsModalProps
  & BasicModalProps
  & withChildServiceProps
  & withChildBookingServiceProps
  & withModalServiceProps
  & withNurserySessionsV3ServiceProps
  & withNurseryContextServiceProps

const AddSessionToRegularBookingsModalContainer: React.FC<AddSessionToRegularBookingsModalContainerFullProps> = ({
  alternate,
  child,
  childActions,
  childBookingSelectors,
  formValues,
  hasAccessToViewPrice,
  hideModal,
  nurseryOpeningDays,
  nurserySessionsV3Actions,
  nurserySessionsV3Selectors,
  onSuccess,
  regularBookingsFormValues,
}) => {
  const [hoursOverlap, setHoursOverlap] = useState<boolean>(false)
  const [dayOfWeeksOverlap, setDayOfWeeksOverlap] = useState<number[]>([])
  const [initialValues, setInitialValues] = useState<AddSessionToRegularBookingsModalFormValues>({
    includeConsumables: true,
  })
  const [price, setPrice] = useState<number>(null)
  const [isFetchingPrice, setIsFetchingPrice] = useState<boolean>(false)

  useEffect(() => {
    const criteria = nurserySessionsV3Selectors.getCriteria({ statusFilter: NURSERY_SESSIONS_FILTER.ACTIVE })

    nurserySessionsV3Actions.list({
      onSuccess: ({ data }) => {
        if (data?.length) {
          setInitialValues({
            includeConsumables: true,
            session: {
              endTime: data[0].endTime,
              hourly: data[0].hourly,
              label: data[0].name,
              startTime: data[0].startTime,
              value: data[0].id,
            },
          })
        }
      },
      onlyData: true,
      params: {
        criteria,
        groups: SESSIONS_GROUPS,
        limit: 1,
      },
    })
  }, [])

  useEffect(() => {
    if (
      !regularBookingsFormValues.startDate
      || !formValues
      || !formValues.session
      || (formValues.session.hourly && (!formValues?.startTime || !formValues?.endTime))
      || !hasAccessToViewPrice
    ) {
      setPrice(null)

      return
    }

    setIsFetchingPrice(true)

    const body = childBookingSelectors.getSessionBodyForPriceCalculator({
      alternate,
      date: regularBookingsFormValues.startDate,
      formValues,
    })

    childActions.getProductPriceCalculator({
      body,
      onFailed: () => {
        setPrice(null)
        setIsFetchingPrice(false)
      },
      onSuccess: (response) => {
        const { data } = response
        const { value } = data

        setPrice(value)
        setIsFetchingPrice(false)
      },
      params: [child.id, {}],
    })
  }, [
    formValues?.session,
    formValues?.includeConsumables,
    formValues?.startTime,
    formValues?.endTime,
  ])

  const handleCloseClick = () => {
    hideModal()
  }

  const handleSubmit = (values: AddSessionToRegularBookingsModalFormValues) => {
    const { childProducts } = regularBookingsFormValues

    const filteredChildProducts = _.filter(childProducts, (childProduct) => (
      childProduct.type === ChildProductTypes.REGULAR_SESSION
      && alternate === childProduct.alternate
    ))
    let initHoursOverlap = false
    const daysOverlap = []

    _.each(filteredChildProducts, (childProduct) => {
      const { daysApplicable, session } = values

      _.each(daysApplicable, (dayOfWeek) => {
        const dayOfWeekOfChildProduct = _.findIndex(DAYS_OF_WEEK_LIST, (value) => value === childProduct.dayOfWeek)

        if (
          dayOfWeekOfChildProduct === dayOfWeek
          && checkTimeRangeOverlap(
            childProduct.product.hourly
              ? [childProduct.startTime, childProduct.endTime]
              : [childProduct.product.startTime, childProduct.product.endTime],
            session.hourly
              ? [
                +removeSeconds(removeDate(values.startTime, { utc: true })).format('x'),
                +removeSeconds(removeDate(values.endTime, { utc: true })).format('x'),
              ]
              : [session.startTime, session.endTime],
          )
        ) {
          daysOverlap.push(dayOfWeek)
          initHoursOverlap = true
        }
      })
    })

    setDayOfWeeksOverlap(daysOverlap)

    if (!initHoursOverlap && hoursOverlap) {
      setHoursOverlap(false)
    } else if (initHoursOverlap && !hoursOverlap) {
      setHoursOverlap(true)
    }

    if (!initHoursOverlap) {
      onSuccess(values, price)
      hideModal()
    }
  }

  return (
    <AddSessionToRegularBookingsModalView
      dayOfWeeksOverlap={dayOfWeeksOverlap}
      formValues={formValues}
      hasAccessToViewPrice={hasAccessToViewPrice}
      hoursOverlap={hoursOverlap}
      initialValues={initialValues}
      isFetchingPrice={isFetchingPrice}
      nurseryOpeningDays={nurseryOpeningDays}
      price={price}
      onCloseClick={handleCloseClick}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withChildService,
  withModalService,
  withNurserySessionsV3Service,
  withChildBookingService,
  withNurseryContextService,
  connector,
)

export default enhance(AddSessionToRegularBookingsModalContainer)
