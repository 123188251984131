import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'

import SettingsInvoiceNumbersItemView from './SettingsInvoiceNumbersItemView'

const NURSERY_INVOICE_NUMBER_SETTINGS_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings.invoice',
    'nurseryInvoiceSettings',
    'nurseryInvoiceSettings.numbers',
    'invoiceNumbers',
  ],
}

class SettingsInvoiceNumbersItemContainer extends Component {
  componentDidMount() {
    const {
      nurseriesActions,
      params,
    } = this.props
    const { nurseryId } = params

    const nurseryApiParams = { groups: NURSERY_INVOICE_NUMBER_SETTINGS_GROUPS }

    nurseriesActions.get(nurseryId, {
      params: nurseryApiParams,
    })
  }

  componentWillUnmount() {
    const { nurseriesActions } = this.props

    nurseriesActions.clearSingle()
  }

  render() {
    const {
      errorMessages,
      invoiceNumberSettings,
      isFetching,
      nurseryName,
      params,
    } = this.props
    const { nurseryId } = params

    return (
      <SettingsInvoiceNumbersItemView
        errorMessages={errorMessages}
        invoiceNumberSettings={invoiceNumberSettings}
        isLoading={isFetching}
        nurseryId={nurseryId}
        nurseryName={nurseryName}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseriesSelectors,
  nurseriesSingleState,
}) => ({
  errorMessages: appSelectors.getErrorMessages(nurseriesSingleState),
  invoiceNumberSettings: nurseriesSelectors.getNurseryInvoiceNumberWithDescriptions(state),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState),
  nurseryName: nurseriesSelectors.getNurseryName(state),
})

const enhance = compose(
  withAppService,
  withNurseriesService,
  connect(mapState),
)

export default enhance(SettingsInvoiceNumbersItemContainer)
