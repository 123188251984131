import React from 'react'

import { Field } from 'components'

const InvoiceActionButton = ({
  buttonSize,
  disableEdit,
  formRef,
  isEditMode,
  onCancelClick,
  onClick,
}) => {
  if (disableEdit) {
    return null
  }

  return (
    <Field.InlineEdit.Actions
      buttonSize={buttonSize}
      isEdit={isEditMode}
      onCancelClick={onCancelClick}
      onEditClick={onClick(formRef)}
      onSaveClick={onClick(formRef)}
    />
  )
}

export default InvoiceActionButton
