import React from 'react'
import { Field, InjectedFormProps, getFormValues, reduxForm } from 'redux-form'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { RootState } from 'core/reducers'

import { isRequired } from 'utils/fieldValidation'

import { Button, Form, Space, Typography } from 'components'

import i18n from 'translations'

import PasswordStrength from '../PasswordStrength'

export const SET_PASSWORD_FORM = 'SET_PASSWORD_FORM'

interface SetPasswordFormProps {
  footerText?: React.ReactNode
  formValues?: any
  isFetching?: boolean
  onSubmit: (values: any) => void
  submitLabel: string
}

type SetPasswordFormFullProps = InjectedFormProps<{} & SetPasswordFormProps> & SetPasswordFormProps

export interface SetPasswordFormValues {
  confirmPassword: string
  password: string
}

const SetPasswordForm: React.FC<SetPasswordFormFullProps> = ({
  footerText,
  formValues,
  handleSubmit,
  isFetching,
  onSubmit,
  submitLabel,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Space space="20px" />
    <Form.Row margin="0 0 10px" width={{ field: '100%' }}>
      <Field
        component={Form.TextField}
        disabled={isFetching}
        name="password"
        placeholder={i18n.t('module:Authentication:SetPassword:newPassword')}
        type="password"
        validate={isRequired}
      />
    </Form.Row>
    <PasswordStrength password={formValues?.password} />
    <Typography
      align="justify"
      fontSize={12}
      lineHeight={18}
      margin="0 0 15px"
      padding="0 2px"
    >
      {i18n.t('module:Authentication:passwordDetails')}
    </Typography>
    <Form.Row margin="0 0 10px" width={{ field: '100%' }}>
      <Field
        component={Form.TextField}
        disabled={isFetching}
        name="confirmPassword"
        placeholder={i18n.t('module:Authentication:SetPassword:confirmPassword')}
        type="password"
        validate={isRequired}
      />
    </Form.Row>
    {footerText}
    <Button
      isLoading={isFetching}
      label={submitLabel}
      fullWidth
      submit
    />
  </Form>
)

const validate = ({ confirmPassword, password }: SetPasswordFormValues) => {
  const errors = {
    confirmPassword: '',
    password: '',
  }

  if (confirmPassword !== password) {
    errors.confirmPassword = i18n.t('module:Authentication:SetPassword:yourRetypedPasswordDidNotMatch')
  }

  if (8 > password?.length) {
    errors.password = i18n.t('module:Authentication:SetPassword:passwordMustBeAtLeast8Characters')
  }

  return errors
}

const mapState = (state: RootState) => ({
  formValues: getFormValues(SET_PASSWORD_FORM)(state),
})

const enhance = compose(
  connect(mapState),
)

export default reduxForm<{}, SetPasswordFormProps>({ form: SET_PASSWORD_FORM, validate })(
  enhance(SetPasswordForm),
)
