import { combineReducers } from 'redux'

import list from './list/reducer'
import listMontessori from './listMontessori/reducer'
import history from './history/reducer'
import historyMontessori from './historyMontessori/reducer'

export default combineReducers({
  history,
  historyMontessori,
  list,
  listMontessori,
})
