import React from 'react'

import { ModalBox } from 'components'

import i18n from 'translations'

import AddRegularItemsToRegularBookingsModalForm, {
  AddRegularItemsToRegularBookingsModalFormProps,
  AddRegularItemsToRegularBookingsModalFormValues,
} from './components/AddRegularItemsToRegularBookingsModalForm'

const AddRegularItemsToRegularBookingsModalView: React.FC<
  AddRegularItemsToRegularBookingsModalFormProps & { initialValues: AddRegularItemsToRegularBookingsModalFormValues }
> = ({
  onCloseClick,
  ...rest
}) => (
  <ModalBox
    maxWidth={600}
    title={i18n.t('modals:AddRegularItemsToRegularBookings:title')}
    onCloseClick={onCloseClick}
  >
    <AddRegularItemsToRegularBookingsModalForm
      onCloseClick={onCloseClick}
      {...rest}
    />
  </ModalBox>
)

export default AddRegularItemsToRegularBookingsModalView
