import React from 'react'

import { Callout, Form, Section, Typography } from 'components'

import i18n from 'translations'

import { renderContent } from '../FinancePaymentsImportHelpers'

const ImportDuplicates = ({
  duplicates,
  errorMessages,
  isProcessing,
  onCancelClick,
  onImportClick,
}) => {
  const renderFooter = () => (
    <Form.StickyFooter
      isSubmitting={isProcessing}
      submitLabel={i18n.t('module:Modals:PaymentsImport:Duplicates:importAnyway')}
      onCancelClick={onCancelClick}
      onSubmitClick={() => onImportClick(true)}
    />
  )

  return (
    <Section
      title={i18n.t('module:Modals:PaymentsImport:Duplicates:title')}
      variant="small"
      noBorder
    >
      <Callout content={errorMessages} error />
      <Typography margin="0 0 40px">
        {i18n.t('module:Modals:PaymentsImport:Duplicates:subtitle')}
      </Typography>
      <div>
        {renderContent(duplicates)}
      </div>
      {renderFooter()}
    </Section>
  )
}

export default ImportDuplicates
