import i18n from 'translations'

export const ORGANIZATION_TABLE_COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'nurseryName',
    title: i18n.t('module:Enquiries:Report:ConversionRate:Organization:Table:Header:site'),
  },
  {
    field: 'total',
    title: i18n.t('module:Enquiries:Report:ConversionRate:Organization:Table:Header:allEnquiries'),
  },
  {
    field: 'win',
    title: i18n.t('module:Enquiries:Report:ConversionRate:Organization:Table:Header:won'),
  },
  {
    field: 'lost',
    title: i18n.t('module:Enquiries:Report:ConversionRate:Organization:Table:Header:lost'),
  },
  {
    field: 'open',
    title: i18n.t('module:Enquiries:Report:ConversionRate:Organization:Table:Header:open'),
  },
]

export const NURSERY_TABLE_COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'stage',
    title: i18n.t('module:Enquiries:Report:ConversionRate:Table:Header:stage'),
  },
  {
    field: 'value',
    title: i18n.t('module:Enquiries:Report:ConversionRate:Table:Header:value'),
  },
  {
    field: 'percentage',
    title: i18n.t('module:Enquiries:Report:ConversionRate:Table:Header:percent'),
  },
]
