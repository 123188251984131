import { createSelector } from 'reselect'

const getFinanceReports = (state) => state.financeFundingReport

export const getFinanceFundingReportNurseries = createSelector(
  [getFinanceReports],
  (state) => state.nurseries,
)

export const getFinanceFundingReportNurseriesData = createSelector(
  [getFinanceFundingReportNurseries],
  (state) => state.data,
)
