import moment from 'moment'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { ChildProductTypes } from 'services/booking/childProducts/constants'
import { AddFormFieldProps } from 'module/Finance/OneOffBookings/Add/components/AddForm'
import { ChildProductExtraItem, ChildProductExtraSession } from 'services/booking/childBooking/constants'

type ProductPriceCalculatorChildProduct = (ChildProductExtraItem | ChildProductExtraSession) & { date?: string}

interface ProductPriceCalculatorBodyProps {
  childProduct?: ProductPriceCalculatorChildProduct
  date?: string
}

export const getProductPriceCalculatorBody = ({
  date,
  endTime,
  includeConsumables,
  item,
  quantity,
  startTime,
  type,
}: AddFormFieldProps) => {
  let body: ProductPriceCalculatorBodyProps = {}

  if (type.value === ChildProductTypes.EXTRA_SESSION) {
    body = {
      childProduct: {
        date: moment(date).format(DEFAULT_DATE_FORMAT),
        endTime: item.hourly ? endTime : null,
        product: { id: item.value },
        settings: { consumablesIncluded: includeConsumables || false },
        startTime: item.hourly ? startTime : null,
        type: type.value,
      },
      date: moment(date).format(DEFAULT_DATE_FORMAT),
    }
  }

  if (type.value === ChildProductTypes.EXTRA_ITEM) {
    body = {
      childProduct: {
        date: moment(date).format(DEFAULT_DATE_FORMAT),
        product: { id: item.value as number },
        settings: { quantity: +quantity },
        type: type.value,
      },
      date: moment(date).format(DEFAULT_DATE_FORMAT),
    }
  }

  return body
}
