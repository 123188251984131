export const ADD_SLEEP = 'ADD_SLEEP'
export const ADD_SLEEP_SUCCESS = 'ADD_SLEEP_SUCCESS'
export const ADD_SLEEP_FAILED = 'ADD_SLEEP_FAILED'

export const REMOVE_SLEEP = 'REMOVE_SLEEP'
export const REMOVE_SLEEP_SUCCESS = 'REMOVE_SLEEP_SUCCESS'
export const REMOVE_SLEEP_FAILED = 'REMOVE_SLEEP_FAILED'

export const UPDATE_SLEEP = 'UPDATE_SLEEP'
export const UPDATE_SLEEP_SUCCESS = 'UPDATE_SLEEP_SUCCESS'
export const UPDATE_SLEEP_FAILED = 'UPDATE_SLEEP_FAILED'
