import styled from 'styled-components'

import { Link } from 'react-router'

import { getBrandingColor } from 'utils/branding'

export const StyledLink = styled.a`
  color: ${getBrandingColor('secondary-color')};

  &:hover, &:focus {
    color: ${getBrandingColor('secondary-color')};
  }
`

export const StyledReactLink = styled(Link)`
  color: ${getBrandingColor('secondary-color')};

  &:hover, &:focus {
    color: ${getBrandingColor('secondary-color')};
  }
`
