import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired, isSortCodeValid, isValidBankAccountNumber } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'

import { Form } from 'components'

import i18n from 'translations'

const ChildFinanceAddBankForm = ({ childId, handleSubmit, isLoading, isUkCountry, onSubmit }) => (
  <div>
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Field
        component={Form.TextField}
        name="carerId"
        type="hidden"
      />
      <Form.Row label={i18n.t('module:Children:Child:Finance:BankDetails:Form:AccountName:label')} required>
        <Form.Row.FlexItem flex="0 0 400px">
          <Field
            component={Form.TextField}
            name="accountName"
            placeholder={i18n.t('module:Children:Child:Finance:BankDetails:Form:AccountName:placeholder')}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      {isUkCountry && (
        <Form.Row label={i18n.t('module:Children:Child:Finance:BankDetails:Form:SortCode:label')}>
          <Form.Row.FlexItem flex="0 0 200px">
            <Field
              component={Form.TextField}
              name="sortCode"
              placeholder={i18n.t('module:Children:Child:Finance:BankDetails:Form:SortCode:placeholder')}
              validate={isSortCodeValid}
            />
          </Form.Row.FlexItem>
        </Form.Row>
      )}
      <Form.Row label={i18n.t('module:Children:Child:Finance:BankDetails:Form:AccountNumber:label')} required>
        <Form.Row.FlexItem flex="0 0 200px">
          <Field
            component={Form.TextField}
            name="accountNumber"
            placeholder={i18n.t('module:Children:Child:Finance:BankDetails:Form:AccountNumber:placeholder')}
            validate={isUkCountry ? [isRequired, isValidBankAccountNumber] : isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      {!isUkCountry && (
        <Form.Row label={i18n.t('module:Children:Child:Finance:BankDetails:Form:Notes:label')}>
          <Form.Row.FlexItem mobileFull>
            <Field
              component={Form.TextAreaField}
              name="bankAccountNotes"
              placeholder={i18n.t('module:Children:Child:Finance:BankDetails:Form:Notes:placeholder')}
            />
          </Form.Row.FlexItem>
        </Form.Row>
      )}
      <Form.Row label={i18n.t('module:Children:Child:Finance:BankDetails:Form:BankName:label')} required>
        <Form.Row.FlexItem>
          <Field
            component={Form.TextField}
            name="bankName"
            placeholder={i18n.t('module:Children:Child:Finance:BankDetails:Form:BankName:placeholder')}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Children:Child:Finance:BankDetails:Form:UsualPaymentMethod:placeholder')}
        required
      >
        <Form.Row.FlexItem flex="0 0 250px">
          <Field
            component={Form.InfiniteDropdowns.PaymentTypes}
            name="usualPaymentMethod"
            placeholder={i18n.t('module:Children:Child:Finance:BankDetails:Form:UsualPaymentMethod:placeholder')}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.FooterActions
        cancelLink={generateRoute('CHILDREN.CHILD.FINANCE.INVOICING', { childId })}
        isSubmitting={isLoading}
        submitLabel={i18n.t('global:Save')}
      />
    </Form>
  </div>
)

export default reduxForm({ form: 'ChildFinanceAddBankForm' })(ChildFinanceAddBankForm)
