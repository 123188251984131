import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'
import { CONTRACT_STATUS, CONTRACT_STATUS_OPTIONS } from 'services/legacy/contracts/constants'

import { Typography } from 'components'

import i18n from 'translations'

import { StyledContractPeriod, StyledStatus } from './ContractPeriodStyled'

const ContractPeriod = ({
  endDate,
  ongoing,
  startDate,
  status,
  wrap,
}) => {
  const start = moment(startDate).format(DISPLAY_SHORT_DATE_FORMAT)
  const end = ongoing
    ? _.upperFirst(i18n.t('global:ongoing'))
    : moment(endDate).format(DISPLAY_SHORT_DATE_FORMAT)

  return (
    <StyledContractPeriod wrap={wrap}>
      <Typography margin="1px 8px 2px 0" variant="span" ellipsis>
        {`${start} - ${end}`}
      </Typography>
      <StyledStatus green={CONTRACT_STATUS.CURRENT === status}>
        {_.find(CONTRACT_STATUS_OPTIONS, { value: status })?.label}
      </StyledStatus>
    </StyledContractPeriod>
  )
}

export default ContractPeriod
