import React from 'react'

import { ModalBox } from 'components'

import i18n from 'translations'

import ChildFinanceInvoice from 'module/Children/Child/ChildFinance/ChildFinanceInvoice'

const AddModalView = ({
  invoiceId,
  onCancelClick,
  onSaveSuccess,
}) => (
  <ModalBox
    maxWidth={900}
    scrollToBody={false}
    title={invoiceId
      ? i18n.t('module:Children:Child:Finance:Invoice:Add:editTitle')
      : i18n.t('module:Children:Child:Finance:Invoice:Add:addTitle')}
    iosPolyfill
    onCloseClick={onCancelClick}
  >
    <ChildFinanceInvoice
      invoiceId={invoiceId}
      isInsideModal
      onModalCancelClick={onCancelClick}
      onModalSaveSuccess={onSaveSuccess}
    />
  </ModalBox>
)

export default AddModalView
