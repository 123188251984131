import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { Form, Typography } from 'components'

import i18n from 'translations'

export const ENTER_STAFF_REGISTER_MODAL_FORM = 'EnterStaffRegisterModal'

const EnterStaffRegisterModalForm = ({ handleSubmit, onCancelClick, onSubmit }) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row>
      <Typography fontSize="14" bold>
        {i18n.t('module:Modals:EnterStaffRegisterModal:subtitle')}
      </Typography>
    </Form.Row>
    <Form.Row>
      <Field
        component={Form.Checkbox}
        label={i18n.t('module:Modals:EnterStaffRegisterModal:dontShowLabel')}
        name="doNotShowNextTime"
      />
    </Form.Row>
    <Form.FooterActions
      submitLabel={i18n.t('global:Continue')}
      onCancelClick={onCancelClick}
    />
  </Form>
)

export default reduxForm({ form: ENTER_STAFF_REGISTER_MODAL_FORM })(EnterStaffRegisterModalForm)
