import React, { PropsWithChildren } from 'react'

import { Avatar, Checkbox } from 'components'

interface GridChildrenPickerItemProps {
  active: boolean
  id: number
  initials?: string | string[]
  label: string
  onClick: (id: number) => void
  photo?: string
}

const GridChildrenPickerItem: React.FC<PropsWithChildren<GridChildrenPickerItemProps>> = ({
  active,
  id,
  initials,
  label,
  onClick,
  photo,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick(id)
    }
  }

  return (
    <Checkbox
      label={(
        <Avatar
          avatarSize="small"
          initials={initials}
          src={photo}
          title={label}
        />
      )}
      value={active}
      onChange={handleClick}
    />
  )
}

export default GridChildrenPickerItem
