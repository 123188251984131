import _ from 'lodash'

import React from 'react'

import PlanView from 'module/Finance/components/PlanView'

import i18n from 'translations'

const PlanDetailExtraItems = ({ childExtraItems }) => {
  if (!childExtraItems || !childExtraItems.length) {
    return (
      <PlanView>
        <PlanView.Icon icon="plus" />
        <PlanView.Content>
          <PlanView.SubTitle
            subtitle={i18n.t('module:Children:Child:Finance:PlanView:Summary:extraItemsEmptyState')}
          />
        </PlanView.Content>
      </PlanView>
    )
  }

  const renderExtraItem = ({ count, name, type }, index) => (
    <PlanView.Item key={_.kebabCase(index)}>
      <PlanView.Highlight>
        {`${count} x ${type}`}
          &nbsp;
      </PlanView.Highlight>
      {name}
    </PlanView.Item>
  )

  return (
    <PlanView>
      <PlanView.Icon icon="plus" />
      <PlanView.Content>
        <PlanView.Title title={i18n.t('module:Children:Child:Finance:PlanView:Summary:extraItems')} />
        {_.map(childExtraItems, renderExtraItem)}
      </PlanView.Content>
    </PlanView>
  )
}

export default PlanDetailExtraItems
