import React, { PropsWithChildren } from 'react'

import { StyledRequiredChar, StyledTopLabelContainer, StyledTopLabelEmptyContainer } from './FieldStyled'

interface FieldTopLabelProps {
  hidden?: boolean
  label?: string
  required?: boolean
}

const FieldTopLabel: React.FC<PropsWithChildren<FieldTopLabelProps>> = ({ hidden, label, required }) => {
  if (hidden) {
    return <StyledTopLabelEmptyContainer />
  }

  return (
    <StyledTopLabelContainer>
      {label}
      {required && (
        <StyledRequiredChar>
          *
        </StyledRequiredChar>
      )}
    </StyledTopLabelContainer>
  )
}

export default FieldTopLabel
