import React from 'react'

import styled from 'styled-components'

import Tooltip from '@material-ui/core/Tooltip'

interface StyledContainerProps {
  $width?: number
}

export const StyledContainer = styled.div<StyledContainerProps>`
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  
  ${({ $width }) => $width && `width: ${$width}px;`}
`

export const StyledTooltip = styled((props) => (
  <Tooltip
    classes={{ popper: props.className, tooltip: 'tooltip' }}
    {...props}
  />
))`
  & .tooltip {
    font-size: 12px;
  }
`
