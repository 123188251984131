import { createSelector } from 'reselect'

const getChildFrameworkProgress = (state) => state.childFrameworkProgress

export const getChildFrameworkProgressHistoryState = createSelector(
  [getChildFrameworkProgress],
  (state) => state.history,
)

export const getChildFrameworkProgressHistoryDataState = createSelector(
  [getChildFrameworkProgressHistoryState],
  (state) => state.data,
)
