import {
  AUTO_CALCULATE_BULK_INVOICE,
  AUTO_CALCULATE_BULK_INVOICE_FAILED,
  AUTO_CALCULATE_BULK_INVOICE_SUCCESS,
  BULK_INVOICE_CLEAR_LIST,
  BULK_INVOICE_LIST_CHILDREN,
  BULK_INVOICE_LIST_CHILDREN_FAILED,
  BULK_INVOICE_LIST_CHILDREN_SUCCESS,
  BULK_PREVIOUS_INVOICE_LIST,
  BULK_PREVIOUS_INVOICE_LIST_FAILED,
  BULK_PREVIOUS_INVOICE_LIST_SUCCESS,
  CREATE_BULK_INVOICES,
  CREATE_BULK_INVOICES_FAILED,
  CREATE_BULK_INVOICES_SUCCESS,
  UPDATE_BULK_INVOICES_LIST,
} from './constants'

export const listChildren = (params, onSuccess, refreshData) => ({ childApiClient }) => (dispatch) => {
  dispatch({
    params,
    type: BULK_INVOICE_LIST_CHILDREN,
  })

  childApiClient.list(params).then(
    (data) => {
      dispatch({
        payload: data,
        refreshData,
        type: BULK_INVOICE_LIST_CHILDREN_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data, refreshData)
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: BULK_INVOICE_LIST_CHILDREN_FAILED,
      })
    },
  )
}

export const autoCalculateInvoice = (childId, params, body, onSuccess, onFailed) => ({
  invoicesApiClient,
}) => (dispatch) => {
  dispatch({
    childId,
    params,
    type: AUTO_CALCULATE_BULK_INVOICE,
  })

  invoicesApiClient.autoCalculate(childId, params, body).then(
    (data) => {
      dispatch({
        childId,
        payload: data,
        type: AUTO_CALCULATE_BULK_INVOICE_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data, childId)
      }
    },
    (error) => {
      const errorResponse = error.response

      dispatch({
        childId,
        error: errorResponse,
        type: AUTO_CALCULATE_BULK_INVOICE_FAILED,
      })

      if (onFailed) {
        onFailed(errorResponse)
      }
    },
  )
}

export const listInvoices = (childId, params) => ({ invoicesApiClient }) => (dispatch) => {
  dispatch({
    params,
    type: BULK_PREVIOUS_INVOICE_LIST,
  })

  invoicesApiClient.list(params).then(
    (data) => {
      dispatch({
        childId,
        payload: data,
        type: BULK_PREVIOUS_INVOICE_LIST_SUCCESS,
      })
    },
    (error) => {
      dispatch({
        childId,
        error: error.response,
        type: BULK_PREVIOUS_INVOICE_LIST_FAILED,
      })
    },
  )
}

export const createBulkInvoices = (
  payload,
  params,
  invoiceType,
  onSuccess,
  onFailed,
) => ({ invoicesApiClient }) => (dispatch) => {
  dispatch({
    invoiceType,
    params,
    payload,
    type: CREATE_BULK_INVOICES,
  })

  invoicesApiClient.createBulk(payload, params).then(
    (data) => {
      dispatch({
        payload: data,
        type: CREATE_BULK_INVOICES_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data, invoiceType)
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        invoiceType,
        type: CREATE_BULK_INVOICES_FAILED,
      })

      if (onFailed) {
        onFailed(error.response, invoiceType)
      }
    },
  )
}

export const updateBulkInvoices = (response) => ({
  payload: response,
  type: UPDATE_BULK_INVOICES_LIST,
})

export const clear = () => ({
  type: BULK_INVOICE_CLEAR_LIST,
})

