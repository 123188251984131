import RoomsWrapper from 'module/Rooms/index'
import Rooms from 'module/Rooms/Rooms'
import RoomsAdd from 'module/Rooms/Rooms/RoomsAdd'
import RoomsMoves from 'module/Rooms/RoomsMoves'
import DefaultRoomMoves from 'module/Rooms/DefaultRoomMoves'

export const ROUTE_NAMES = {
  'ROOMS.ADD': 'ROOMS.ADD',
  'ROOMS.DEFAULT_ROOM_MOVES': 'ROOMS.DEFAULT_ROOM_MOVES',
  'ROOMS.EDIT': 'ROOMS.EDIT',
  'ROOMS.INDEX': 'ROOMS.INDEX',
  'ROOMS.LIST': 'ROOMS.LIST',
  'ROOMS.ROOMS_MOVES': 'ROOMS.ROOMS_MOVES',
}

const RoomsRouting = {
  childRoutes: [
    {
      component: Rooms,
      name: ROUTE_NAMES['ROOMS.LIST'],
      path: 'list',
      title: 'Rooms list',
    },
    {
      component: RoomsAdd,
      name: ROUTE_NAMES['ROOMS.ADD'],
      path: 'add',
      title: 'Add room',
    },
    {
      component: RoomsAdd,
      name: ROUTE_NAMES['ROOMS.EDIT'],
      path: 'edit/:id',
      title: 'Edit room',
    },
    {
      component: DefaultRoomMoves,
      name: ROUTE_NAMES['ROOMS.DEFAULT_ROOM_MOVES'],
      path: 'default-room-moves',
      title: 'Default room moves',
    },
    {
      component: RoomsMoves,
      name: ROUTE_NAMES['ROOMS.ROOMS_MOVES'],
      path: 'rooms-moves',
      title: 'Room moves',
    },
  ],
  component: RoomsWrapper,
  name: ROUTE_NAMES['ROOMS.INDEX'],
  path: 'rooms',
  title: 'Rooms',
}

RoomsRouting.ROUTE_NAMES = ROUTE_NAMES

export default RoomsRouting
