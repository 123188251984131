import moment from 'moment'
import _ from 'lodash'

import React from 'react'

import * as OccupancyConstants from 'services/legacy/occupancy/constants'

import { Banner, DefinitionList, Toolbar } from 'components'

const ReportFilter = ({
  filters,
  isOverviewReport,
  rooms,
}) => {
  const {
    absences,
    breakdown,
    date,
    extraSessions,
    holidays,
    nurseryClass,
    splitAt,
    type,
  } = filters

  const breakdownBy = _.get(
    _.find(OccupancyConstants.OCCUPANCY_REQUEST_BREAKDOWN_DROPDOWN, ({ value }) => value === breakdown),
    'label',
  )

  const showOptions = []

  if (absences) {
    showOptions.push('Absences')
  }

  if (holidays) {
    showOptions.push('Holidays')
  }

  if (extraSessions) {
    showOptions.push('Extra sessions')
  }

  return (
    <React.Fragment>
      <Toolbar bottomLine>
        <Toolbar.Group alignItems="flex-start">
          <Toolbar.Item>
            <DefinitionList vertical>
              <DefinitionList.Item
                label="Type of report"
                value={_.get(
                  _.find(OccupancyConstants.OCCUPANCY_TYPE_DROPDOWN, ({ value }) => value === type),
                  'label',
                )}
              />
            </DefinitionList>
          </Toolbar.Item>
          {breakdownBy && (
            <Toolbar.Item>
              <DefinitionList vertical>
                <DefinitionList.Item
                  label="View by"
                  value={`${breakdownBy} ${
                    splitAt
                    && breakdown === OccupancyConstants.OCCUPANCY_REQUEST_BREAKDOWN.AM_PM
                      ? `(Split at ${moment(splitAt).format('HH:mm')})` : ''}`}
                />
              </DefinitionList>
            </Toolbar.Item>
          )}
          {!isOverviewReport && date && (
            <Toolbar.Item>
              <DefinitionList vertical>
                <DefinitionList.Item
                  label="Date"
                  value={(
                    <React.Fragment>
                      {type === OccupancyConstants.OCCUPANCY_REQUEST_TYPE.WEEKLY && moment(date).format('DD/MM/YYYY')}
                      {type === OccupancyConstants.OCCUPANCY_REQUEST_TYPE.MONTHLY && moment(date).format('MMM YYYY')}
                      {type === OccupancyConstants.OCCUPANCY_REQUEST_TYPE.YEARLY && moment(date).format('YYYY')}
                    </React.Fragment>
                  )}
                />
              </DefinitionList>
            </Toolbar.Item>
          )}
          {nurseryClass && (
            <Toolbar.Item>
              <DefinitionList vertical>
                <DefinitionList.Item
                  label="For room"
                  value={_.get(
                    _.find(rooms, ({ id }) => id === +nurseryClass),
                    'name',
                  )}
                />
              </DefinitionList>
            </Toolbar.Item>
          )}
          {showOptions.length && type === OccupancyConstants.OCCUPANCY_REQUEST_TYPE.WEEKLY
            ? (
              <Toolbar.Item>
                <DefinitionList vertical>
                  <DefinitionList.Item
                    label="Show"
                    value={_.map(showOptions, (option) => <div>{option}</div>)}
                  />
                </DefinitionList>
              </Toolbar.Item>
            )
            : null}
        </Toolbar.Group>
      </Toolbar>
      {showOptions.length && type === OccupancyConstants.OCCUPANCY_REQUEST_TYPE.WEEKLY
        ? (
          <Banner.Info>
            {'FTE percentages are calculated based on children’s session sets '
              .concat('and do not factor in extra sessions, holidays and absences.')}
          </Banner.Info>
        ) : null}
    </React.Fragment>
  )
}

export default ReportFilter
