import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledPinBoxWrapper = styled.div`
  margin: 50px auto 0;
  max-width: 400px;
  text-align: center;
`

export const StyledPinBox = styled.div`
  padding: 20px;
  background: ${NEUTRAL_COLOURS.GRAY_TERTIARY};
`
