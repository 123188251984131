import i18n from 'translations'

export const ALL_VALUES = {
  ALL_CHILDREN: 'allChildren',
  ALL_STAFF: 'allStaff',
}

export const ALL_OPTIONS = [
  {
    label: i18n.t('module:Newsletters:Constants:AllOptions:allChildrenLabel'),
    value: ALL_VALUES.ALL_CHILDREN,
  },
  {
    label: i18n.t('module:Newsletters:Constants:AllOptions:allStaffLabel'),
    value: ALL_VALUES.ALL_STAFF,
  },
]

export const NEWS_STATUSES = {
  ALL: 'ALL',
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
}

export const TYPE_OPTIONS = [
  {
    label: i18n.t('module:Newsletters:Constants:TypeOptions:allPostLabel'),
    value: NEWS_STATUSES.ALL,
  },
  {
    label: i18n.t('module:Newsletters:Constants:TypeOptions:draftLabel'),
    value: NEWS_STATUSES.DRAFT,
  },
  {
    label: i18n.t('module:Newsletters:Constants:TypeOptions:publishedLabel'),
    value: NEWS_STATUSES.PUBLISHED,
  },
]

export const ERROR_LIST = {
  GROUPS: ' ',
  INDIVIDUALS: i18n.t('module:Newsletters:Constants:ErrorList:individuals'),
  TITLE: i18n.t('module:Newsletters:Constants:ErrorList:title'),
}

export const TAB_NAMES = {
  OVERVIEW: 'overview',
  RECIPIENTS: 'recipients',
}
