import React from 'react'

import { LabelProps } from 'components/Label/Label'

import { Label } from 'components'

import { StyledRowTextItem } from './FormRowStyled'

interface FormRowTextItemProps extends LabelProps {
  flex?: string
  margin?: string
}

const FormRowTextItem: React.FC<FormRowTextItemProps> = ({ flex, margin, ...rest }) => (
  <StyledRowTextItem $flex={flex} $margin={margin}>
    <Label {...rest} />
  </StyledRowTextItem>
)

export default FormRowTextItem
