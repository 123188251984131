import React from 'react'

import InjuryPreviewWrapperView from './InjuryPreviewWrapperView'

const InjuryPreviewWrapperContainer = ({ children }) => (
  <InjuryPreviewWrapperView>
    {children}
  </InjuryPreviewWrapperView>
)

export default InjuryPreviewWrapperContainer
