import _ from 'lodash'

export const addFoodMenu = (state, data) => ({
  ...state,
  data: [
    ...state.data,
    data,
  ],
})

export const updateFoodMenu = (state, data) => ({
  ...state,
  data: _.map(state.data, (item) => {
    if (data.id === item.id) {
      return data
    }

    return item
  }),
})
