import { createSelector } from 'reselect'

const getFinanceReports = (state) => state.financeRevenuePerRoomReport

export const getFinanceRevenuePerRoomReportStatistics = createSelector(
  [getFinanceReports],
  (state) => state.statistics,
)

export const getFinanceRevenuePerRoomReportStatisticsData = createSelector(
  [getFinanceRevenuePerRoomReportStatistics],
  (state) => state.data,
)

