import _ from 'lodash'

import React, { useEffect, useRef, useState } from 'react'
import { Field } from 'redux-form'

import { Option } from 'constants/models'

import { isRequired } from 'utils/fieldValidation'
import { getMonthOptions } from 'utils/date'

import { Form, Grid, Typography } from 'components'

import i18n from 'translations'

interface ExcludedMonthsProps {
  disabled: boolean
  months: number
  name: string
  usedMonths: Option[]
}

const ExcludedMonths: React.FC<ExcludedMonthsProps> = ({
  disabled,
  months,
  name,
  usedMonths,
}) => {
  const getRemainingMonths = (allowedMonths) => {
    let finalRemainingMonths = 0

    if (1 <= allowedMonths && 12 >= allowedMonths) {
      finalRemainingMonths = 12 - allowedMonths
    }

    return finalRemainingMonths
  }

  const [remainingMonths, setRemainingMonths] = useState(getRemainingMonths(months))
  const monthOptions = useRef(getMonthOptions())

  useEffect(() => {
    setRemainingMonths(getRemainingMonths(months))
  }, [months])

  const renderMonth = (index) => {
    const finalMonthOptions = _.filter(
      monthOptions.current,
      ({ value }) => !_.find(usedMonths, (item, subIndex) => (
        item?.value === value && subIndex !== index
      )),
    )

    return (
      <Form.Row
        key={_.kebabCase(index)}
        label={!index && i18n.t('module:Management:Finance:Annualised:components:ExcludedMonths:title')}
        margin="0 0 5px 0"
        width={{ field: '100%' }}
        verticalLabel
      >
        <Grid>
          <Grid.Item width={{ desktop: '30px' }}>
            <Typography padding="12px 0 0 0" bold>
              {index + 1}
              {'.'}
            </Typography>
          </Grid.Item>
          <Grid.Item>
            <Field
              component={Form.Select}
              disabled={disabled}
              name={`${name}[${index}]`}
              options={finalMonthOptions}
              placeholder={_.upperFirst(i18n.t('global:month'))}
              validate={[isRequired]}
            />
          </Grid.Item>
        </Grid>
      </Form.Row>
    )
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <React.Fragment>
      {remainingMonths ? _.times(remainingMonths, renderMonth) : null}
    </React.Fragment>
  )
}

export default ExcludedMonths
