import {
  ADD_BOTTLE,
  ADD_BOTTLE_FAILED,
  ADD_BOTTLE_SUCCESS,
  REMOVE_BOTTLE,
  REMOVE_BOTTLE_FAILED,
  REMOVE_BOTTLE_SUCCESS,
  UPDATE_BOTTLE,
  UPDATE_BOTTLE_FAILED,
  UPDATE_BOTTLE_SUCCESS,
} from './constants'

const initialState = {
  isSubmitting: false,
}

export default (state = initialState, { type }) => {
  switch (type) {
    case ADD_BOTTLE:
    case REMOVE_BOTTLE:
    case UPDATE_BOTTLE:
      return { ...state, isSubmitting: true }
    case REMOVE_BOTTLE_FAILED:
    case REMOVE_BOTTLE_SUCCESS:
    case ADD_BOTTLE_FAILED:
    case ADD_BOTTLE_SUCCESS:
    case UPDATE_BOTTLE_FAILED:
    case UPDATE_BOTTLE_SUCCESS:
      return { ...state, isSubmitting: false }
    default:
      return state
  }
}
