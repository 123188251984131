import styled from 'styled-components'

export const StyledAvatarContainer = styled.div`
  display: flex;
  align-items: center;
`

export const StyledAvatar = styled.div`
  margin: 0 ${({ label }) => (label ? 10 : 0)}px;
`
