import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const StyledContentContainer = styled.div`
  display: flex;
  flex: 1;
`

export const StyledFooterContainer = styled.div`
  display: flex;
  flex: 0;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: ${getBrandingColor('primary-color')};
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`

export const StyledContentViewContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 5px;
  min-width: 0;
`
