import React from 'react'

import { Callout, Section, Spinner } from 'components'

import ChildFinanceAddBankForm from './components/ChildFinanceAddBankForm'

const ChildFinanceAddBankView = ({
  child,
  errorMessages,
  initialValues,
  isLoading,
  isSubmitting,
  isUkCountry,
  onSubmit,
}) => {
  if (isLoading) {
    return <Spinner />
  }

  const { id } = child

  return (
    <Section title="Bank Details">
      <Callout content={errorMessages} error />
      <ChildFinanceAddBankForm
        childId={id}
        initialValues={initialValues}
        isLoading={isSubmitting}
        isUkCountry={isUkCountry}
        onSubmit={onSubmit}
      />
    </Section>
  )
}

export default ChildFinanceAddBankView
