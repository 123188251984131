import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledContent = styled.div`
  min-width: 560px;
  width: 100%;
  padding: 15px;
`

export const StyledConditionItem = styled.div`
  display: grid;
  grid-template-columns: 32px auto;
  grid-gap: 16px;
  align-items: center;
  margin-bottom: 20px;
`

export const StyledFooter = styled.footer`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`

export const StyledEmptyCircle = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid ${NEUTRAL_COLOURS.WHITE};
`
