import _ from 'lodash'

import i18n from 'translations'

export const DIETARY_REPORT_LIST_COLUMNS = [
  {
    align: 'left',
    field: 'child',
    title: _.upperFirst(i18n.t('global:child')),
    verticalAlign: 'top',
    width: '20%',
  },
  {
    align: 'left',
    field: 'sessions',
    title: _.upperFirst(i18n.t('global:sessions')),
    verticalAlign: 'top',
    width: '20%',
  },
  {
    align: 'left',
    field: 'allergies',
    title: _.upperFirst(i18n.t('global:allergies')),
    verticalAlign: 'top',
    width: '30%',
  },
  {
    align: 'left',
    field: 'dietaryPreference',
    title: _.upperFirst(i18n.t('global:dietaryPreference')),
    verticalAlign: 'top',
    width: '30%',
  },
]
