import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { Callout, Spinner, Typography } from 'components'
import Settings from 'module/Settings/components'

const SettingsInvoicesItemDetailsV2View = ({
  authAccessMap,
  bankDetails,
  disableEdit,
  errorMessages,
  hideGlobalOption,
  invoiceSettings,
  isLoading,
  nurseryId,
  nurseryName,
  onSubmit,
  openWeeks,
  orgInvoicesSettings,
}) => {
  if (isLoading) {
    return <Spinner />
  }

  const hideAutomationOptions = !authAccessMap.section.FinanceAutomation

  const title = (
    <span>
      Your invoice settings for
      {' '}
      <Typography color={NEUTRAL_COLOURS.BLUE} fontSize="inherit" variant="span" bold>
        {nurseryName}
      </Typography>
    </span>
  )

  return (
    <div>
      <Callout content={errorMessages} error />
      <Settings.InvoiceSettingV2
        description="This is where you can set your default settings and apply these to all of your nurseries.
        Bank details for individual nurseries can be added in the nursery’s settings."
        disableEdit={disableEdit}
        hideAutomationOptions={hideAutomationOptions}
        hideGlobalOption={hideGlobalOption}
        invoicesSettings={invoiceSettings}
        openWeeks={openWeeks}
        orgInvoicesSettings={orgInvoicesSettings}
        title={title}
        hideInvoiceNumberOptions
        onSubmit={onSubmit}
      />
      <Settings.InvoiceBankSettingView
        bankDetails={bankDetails}
        disableEdit={disableEdit}
        editLink={`/settings/invoices/${nurseryId}/bank/edit`}
      />
    </div>
  )
}

export default SettingsInvoicesItemDetailsV2View
