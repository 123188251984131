import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class ChildrenLeavingReportApiClient extends ServiceBase {
  getChildrenLeaving = (params) => this.request({
    path: `/v2/children/statistics/leavers?${getQueryStringV2(params)}`,
  })

  getNurseriesLeaving = (params) => this.request({
    path: `/v2/nurseries/statistics/leavers?${getQueryStringV2(params)}`,
  })

  getStatistics = (params) => this.request({
    path: `/v2/statistics/leavers?${getQueryStringV2(params)}`,
  })
}

export default ChildrenLeavingReportApiClient
