import _ from 'lodash'

import { mergeData } from 'utils/data'
import { BasicListInitialState } from 'utils/reducer'

import {
  CLEAR_ORGANIZATIONS,
  LIST_ORGANIZATIONS,
  LIST_ORGANIZATIONS_FAILED,
  LIST_ORGANIZATIONS_SUCCESS,
} from './constants'
import { GET_ORGANIZATION_SUCCESS } from '../single/constants'

export interface OrganizationListState extends BasicListInitialState {}

const initialState: OrganizationListState = {
  data: null,
  error: null,
  isFetching: false,
  meta: {},
}

export default (state = initialState, { addToList, error, mergeResult, payload, type }) => {
  switch (type) {
    case LIST_ORGANIZATIONS:
      return {
        ...state,
        error: initialState.error,
        isFetching: true,
      }
    case LIST_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        data: _.uniqBy(mergeData(state.data, payload.data, mergeResult), ({ id }) => id),
        error: initialState.error,
        isFetching: false,
        meta: payload.meta,
      }
    case LIST_ORGANIZATIONS_FAILED:
      return {
        ...state,
        error,
        isFetching: false,
      }
    case GET_ORGANIZATION_SUCCESS:
      if (addToList) {
        return {
          ...state,
          data: _.uniqBy(mergeData(state.data, [payload?.data], true), ({ id }) => id),
        }
      }

      return {
        ...state,
      }
    case CLEAR_ORGANIZATIONS:
      return initialState
    default:
      return state
  }
}
