import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { AUTH_TYPE_DIRECT, AUTH_TYPE_PIN } from 'services/device/constants'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withDeviceService } from 'services/device'
import { withDeviceTokensService } from 'services/legacy/deviceTokens'
import { withShellService } from 'services/shell'
import { withModalService } from 'services/utils/modal'
import { withRouter } from 'services/router'

import i18n from 'translations'

import EditDeviceView from './EditDeviceView'

class EditDeviceContainer extends Component {
  componentDidMount() {
    const { deviceTokensActions, navigate, params, shellActions } = this.props

    deviceTokensActions.getDeviceToken({
      onFailed: () => navigate(generateRoute('MANAGEMENT.SECURITY.DEVICES')),
      params: [params.deviceId],
    })
    shellActions.setSettings({
      suppressDeviceWarning: true,
    })
  }

  componentWillUnmount() {
    const { deviceTokensActions } = this.props

    deviceTokensActions.clearSingle()
  }

  handleUpdateDeviceSuccess = (response) => {
    const { deviceActions, navigate } = this.props
    const { data } = response

    deviceActions.changeDeviceIdentity(data.token)
    navigate(generateRoute('MANAGEMENT.SECURITY.DEVICES'))
  }

  handleDeviceDirectCreateSuccess = (response) => {
    const { deviceActions, deviceTokensActions } = this.props
    const { data } = response

    deviceActions.changeDeviceRealIdentity(data.token)
    deviceTokensActions.installDeviceToken({
      body: data,
      onSuccess: this.handleUpdateDeviceSuccess,
      params: [data.id],
    })
  }

  handleDevicePinCreateSuccess = (response) => {
    const { navigate } = this.props
    const { data } = response

    navigate(generateRoute('MANAGEMENT.SECURITY.DEVICES.DETAILS', { deviceId: data.id }))
  }

  handleSubmit = (values) => {
    const { deviceItem, deviceTokensActions, deviceTokensSelectors } = this.props

    const body = deviceTokensSelectors.getDeviceTokensValuesForm({
      ...values,
      regeneratePin: AUTH_TYPE_PIN === deviceItem?.method,
    })

    deviceTokensActions.updateDeviceToken({
      body,
      onSuccess: AUTH_TYPE_PIN === deviceItem?.method
        ? this.handleDevicePinCreateSuccess
        : this.handleDeviceDirectCreateSuccess,
      params: [deviceItem.id],
    })
  }

  handleRemoveDeviceSuccess = () => {
    const { deviceActions, deviceItem, deviceRealIdentity, navigate } = this.props

    // NOTE: When removing device, check if it isn't current device. If it is, remove from localStorage
    if (deviceItem?.token === deviceRealIdentity && AUTH_TYPE_DIRECT === deviceItem?.method) {
      deviceActions.changeDeviceRealIdentity(null)
      deviceActions.changeDeviceIdentity(null)
    }

    navigate(generateRoute('MANAGEMENT.SECURITY.DEVICES'))
  }

  handleRemoveDeviceConfirmed = () => {
    const { deviceItem, deviceTokensActions } = this.props

    deviceTokensActions.removeDeviceToken({
      onSuccess: this.handleRemoveDeviceSuccess,
      params: [deviceItem.id],
    })
  }

  handleRemoveDevice = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      onConfirm: this.handleRemoveDeviceConfirmed,
      text: i18n.t('module:Management:Security:AuthorisedDevices:EditDevice:removeDevice'),
    })
  }

  render() {
    const { deviceItem, initialValues, isFetching, isSubmitting } = this.props

    return (
      <EditDeviceView
        deviceItem={deviceItem}
        initialValues={initialValues}
        isFetching={isFetching}
        isSubmitting={isSubmitting}
        onRemoveDevice={this.handleRemoveDevice}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  deviceSelectors,
  deviceTokensSelectors,
  deviceTokensSingleState,
}) => ({
  deviceItem: deviceTokensSelectors.getDeviceToken(state),
  deviceRealIdentity: deviceSelectors.getDeviceRealIdentity(state),
  errorMessages: appSelectors.getErrorMessages(deviceTokensSingleState),
  initialValues: deviceTokensSelectors.getInitialValues(state),
  isFetching: appSelectors.getIsFetching(deviceTokensSingleState),
  isSubmitting: appSelectors.getIsSubmitting(deviceTokensSingleState),
})

const enhance = compose(
  withAppService,
  withDeviceService,
  withDeviceTokensService,
  withModalService,
  withShellService,
  withRouter,
  connect(mapState),
)

export default enhance(EditDeviceContainer)
