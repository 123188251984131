import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withMembershipsService } from 'services/legacy/memberships'
import { withRoomsService } from 'services/legacy/rooms'
import { withPaginationUtils } from 'services/utils/pagination'

import AssignStaffMembersModalView from './AssignStaffMembersModalView'

const GROUPS = {
  read: [
    'membership.details',
  ],
}

class AssignStaffMembersModalContainer extends Component {
  constructor(props) {
    super(props)

    const { paginationUtils: { setPageLocationQuery }, room, sourceModule } = this.props

    const roomMembers = 'PractitionerAccess' === sourceModule
      ? room?.classMemberships
      : room?.messagingRecipients

    this.state = {
      errors: [],
      isSubmitting: false,
      selectedAll: false,
      selectedMembers: _.map(roomMembers, ({ id }) => id),
    }

    setPageLocationQuery(false)
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { membershipsActions } = this.props

    membershipsActions.clearList()
  }

  fetch = () => {
    const {
      membershipRoles,
      membershipsActions,
      membershipsSelectors,
      paginationUtils,
    } = this.props
    const { page } = paginationUtils
    const { search } = this.state
    const criteria = membershipsSelectors.getMembershipsListCriteria({
      role: membershipRoles,
      search,
    })

    return membershipsActions.list({
      mergeResult: 1 !== page,
      onSuccess: this.handleMembershipsListSuccess,
      params: {
        criteria,
        groups: GROUPS,
        order: 'id ASC',
        page,
      },
    })
  }

  handleMembershipsListSuccess = () => {
    const { memberships } = this.props
    const { selectedAll } = this.state

    if (!selectedAll) {
      return null
    }

    return this.setState({
      selectedMembers: _.map(memberships, ({ id }) => id),
    })
  }

  handleCloseClick = () => {
    const { hideModal, onRoomListsReload } = this.props

    onRoomListsReload()
    hideModal()
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleChangeSearch = (search) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState({ search })
    setTimeout(() => onPageChange(this.fetch)(1))
  }

  handleSelectAll = () => {
    const { memberships } = this.props
    const { selectedAll } = this.state

    this.setState({
      selectedAll: !selectedAll,
      selectedMembers: !selectedAll ? _.map(memberships, ({ id }) => id) : [],
    })
  }

  handleSubmitFailed = (response) => {
    const { extra } = response
    const errors = []

    _.each(extra, (value) => {
      errors.push(value)
    })

    this.setState({ errors, isSubmitting: false })
  }

  handleSubmit = () => {
    const { room, roomsActions, sourceModule } = this.props
    const { selectedMembers } = this.state

    this.setState({ errors: [], isSubmitting: true })

    if ('PractitionerAccess' === sourceModule) {
      roomsActions.updateMemberships({
        onFailed: this.handleSubmitFailed,
        onSuccess: this.handleCloseClick,
        params: [room.id, {
          classMemberships: _.map(selectedMembers, (id) => ({ id })),
        }],
      })
    } else {
      roomsActions.updateMessagingRecipients({
        onFailed: this.handleSubmitFailed,
        onSuccess: this.handleCloseClick,
        params: [room.id, {
          messagingRecipients: _.map(selectedMembers, (id) => ({ id })),
        }],
      })
    }
  }

  handleChangeSelectedMembers = (id) => {
    let { selectedMembers } = this.state

    if (_.find(selectedMembers, (i) => i === id)) {
      selectedMembers = _.filter(selectedMembers, (i) => i !== id)
    } else {
      selectedMembers = [
        ...selectedMembers,
        id,
      ]
    }

    this.setState({ selectedMembers })
  }

  render() {
    const {
      isFetching,
      memberships,
      paginationUtils,
      room,
      totalResults,
    } = this.props
    const { errors, isSubmitting, search, selectedAll, selectedMembers } = this.state
    const { getPageCount, page } = paginationUtils
    const pageCount = getPageCount(totalResults)

    return (
      <AssignStaffMembersModalView
        errors={errors}
        isFetching={isFetching}
        isSubmitting={isSubmitting}
        memberships={memberships}
        page={page}
        pageCount={pageCount}
        room={room}
        search={search}
        selectedAll={selectedAll}
        selectedMembers={selectedMembers}
        onChangeSearch={this.handleChangeSearch}
        onChangeSelectedMembers={this.handleChangeSelectedMembers}
        onCloseClick={this.handleCloseClick}
        onPageChange={this.handlePageChange}
        onSelectAll={this.handleSelectAll}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, { appSelectors, membershipsListState, membershipsSelectors }) => ({
  isFetching: appSelectors.getIsFetching(
    membershipsListState,
  ),
  memberships: membershipsSelectors.getMembershipsListDataSelector(state),
  totalResults: appSelectors.getTotalResults(membershipsListState),
})

const enhance = compose(
  withAppService,
  withMembershipsService,
  withModalService,
  withPaginationUtils,
  withRoomsService,
  connect(mapState),
)

export default enhance(AssignStaffMembersModalContainer)
