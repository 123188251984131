import { createSelector } from 'reselect'

const getContractsSelector = (state) => state.contracts.list

export const getContractsDataSelector = createSelector([getContractsSelector], (state) => state.data)

export const getListCriteria = (values) => {
  const criteria = []
  const { endDate, membership, startDate, status } = values

  if (startDate && endDate) {
    criteria.push({
      comparator: 'before',
      field: 'startDate',
      value: endDate,
    })

    criteria.push({
      field: 'or[0][endDate][eq]',
      value: null,
    })

    criteria.push({
      field: 'or[0][endDate][gte]',
      value: startDate,
    })
  }

  if (membership) {
    criteria.push({
      field: 'membership',
      value: membership,
    })
  }

  if (status) {
    criteria.push({
      field: 'status',
      value: status,
    })
  }

  return criteria
}
