import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class FinanceReportsApiClient extends ServiceBase {
  getChildren = (params) => this.request({
    path: `/v2/teaching_nursery_classes/statistics/revenue?${getQueryStringV2(params)}`,
  })

  getNurseries = (params) => this.request({
    path: `/v2/nurseries/statistics/revenue?${getQueryStringV2(params)}`,
  })

  getStatistics = (params) => this.request({
    path: `/v2/statistics/revenue_per_room?${getQueryStringV2(params)}`,
  })
}

export default FinanceReportsApiClient
