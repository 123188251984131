import styled from 'styled-components'

export const StyledContainer = styled.div`
  height: 440px;
  width: 760px;
  position: relative;
  margin: 0 auto;
  justify-content: center;
  overflow: hidden;
  display: flex;
  background: #000;
  background-size: cover;
  
  @media(max-width: 800px) {
    width: auto;
  }
`

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  margin: 0 auto;
`
