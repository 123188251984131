import React, { PropsWithChildren } from 'react'
import { PlacementType } from 'react-laag/dist/PlacementType'

import { Button, Popover } from 'components'

import DropdownMenuDivider from './DropdownMenuDivider'
import DropdownMenuItem from './DropdownMenuItem'
import { StyledButtonWrapper, StyledDropdownMenu } from './DropdownMenuStyled'

interface DropdownMenuRoot<T> extends React.FC<PropsWithChildren<T>> {
  Divider?: typeof DropdownMenuDivider
  Item?: typeof DropdownMenuItem
}

interface DropdownMenuProps {
  button?: React.ReactNode
  color?: string
  disabled?: boolean
  minWidth?: number
  parentZindex?: number
  placement?: PlacementType
  small?: boolean
  zIndex?: number
}

const DropdownMenu: DropdownMenuRoot<DropdownMenuProps> = ({
  button,
  children,
  color,
  disabled,
  minWidth,
  parentZindex,
  placement,
  small,
  zIndex,
}) => (
  <Popover
    button={button ? (
      <StyledButtonWrapper $disabled={disabled}>
        {button}
      </StyledButtonWrapper>
    ) : (
      <Button
        color={color}
        disabled={disabled}
        hierarchy="tertiary"
        icon="dots-vertical"
        size={small ? 'small' : undefined}
        negativeMargins
      />
    )}
    disabled={disabled}
    parentZindex={parentZindex}
    placement={placement}
    zIndex={zIndex}
  >
    <StyledDropdownMenu $minWidth={minWidth}>
      {children}
    </StyledDropdownMenu>
  </Popover>
)

export default DropdownMenu
