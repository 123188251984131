import { generateBasicActions } from 'utils/service'

import {
  CLEAR_PERIOD_TIME,
  CREATE_PERIOD_TIME,
  CREATE_PERIOD_TIME_FAILED,
  CREATE_PERIOD_TIME_SUCCESS,
  GET_PERIOD_TIME,
  GET_PERIOD_TIME_FAILED,
  GET_PERIOD_TIME_SUCCESS,
  REMOVE_PERIOD_TIME,
  REMOVE_PERIOD_TIME_FAILED,
  REMOVE_PERIOD_TIME_SUCCESS,
  UPDATE_PERIOD_TIME,
  UPDATE_PERIOD_TIME_FAILED,
  UPDATE_PERIOD_TIME_SUCCESS,
} from './constants'

export const get = (options = {}) => ({ periodTimesApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: GET_PERIOD_TIME_FAILED,
      init: GET_PERIOD_TIME,
      success: GET_PERIOD_TIME_SUCCESS,
    },
    options,
    service: periodTimesApiClient,
    serviceMethod: 'get',
  })
)

export const create = (options = {}) => ({ periodTimesApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: CREATE_PERIOD_TIME_FAILED,
      init: CREATE_PERIOD_TIME,
      success: CREATE_PERIOD_TIME_SUCCESS,
    },
    options,
    service: periodTimesApiClient,
    serviceMethod: 'create',
  })
)

export const update = (options = {}) => ({ periodTimesApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_PERIOD_TIME_FAILED,
      init: UPDATE_PERIOD_TIME,
      success: UPDATE_PERIOD_TIME_SUCCESS,
    },
    options,
    service: periodTimesApiClient,
    serviceMethod: 'update',
  })
)

export const remove = (options = {}) => ({ periodTimesApiClient }) => (
  generateBasicActions.remove({
    constants: {
      failed: REMOVE_PERIOD_TIME_FAILED,
      init: REMOVE_PERIOD_TIME,
      success: REMOVE_PERIOD_TIME_SUCCESS,
    },
    options,
    service: periodTimesApiClient,
    serviceMethod: 'remove',
  })
)

export const clear = () => ({
  type: CLEAR_PERIOD_TIME,
})
