import {
  CLEAR_LEAVE_TYPE,
  CREATE_LEAVE_TYPE,
  CREATE_LEAVE_TYPE_FAILED,
  CREATE_LEAVE_TYPE_SUCCESS,
  GET_LEAVE_TYPE,
  GET_LEAVE_TYPE_FAILED,
  GET_LEAVE_TYPE_SUCCESS,
  UPDATE_LEAVE_TYPE,
  UPDATE_LEAVE_TYPE_FAILED,
  UPDATE_LEAVE_TYPE_SUCCESS,
} from './constants'

export const get = (options) => ({ leaveTypesApiClient }) => (dispatch) => {
  const { id } = options

  dispatch({
    type: GET_LEAVE_TYPE,
  })

  leaveTypesApiClient.get(id).then(
    (response) => {
      dispatch({
        payload: response,
        type: GET_LEAVE_TYPE_SUCCESS,
      })
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: GET_LEAVE_TYPE_FAILED,
      })
    },
  )
}

export const create = (options) => ({ leaveTypesApiClient }) => (dispatch) => {
  const { onSuccess, payload } = options

  dispatch({
    payload,
    type: CREATE_LEAVE_TYPE,
  })

  return leaveTypesApiClient.create(payload).then(
    (response) => {
      dispatch({
        payload: response,
        type: CREATE_LEAVE_TYPE_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: CREATE_LEAVE_TYPE_FAILED,
      })
    },
  )
}

export const update = (options) => ({ leaveTypesApiClient }) => (dispatch) => {
  const { id, onSuccess, payload } = options

  dispatch({
    payload,
    type: UPDATE_LEAVE_TYPE,
  })

  leaveTypesApiClient.update(id, payload).then(
    (response) => {
      dispatch({
        payload: response,
        type: UPDATE_LEAVE_TYPE_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: UPDATE_LEAVE_TYPE_FAILED,
      })
    },
  )
}

export const clear = () => ({
  type: CLEAR_LEAVE_TYPE,
})
