import React, { PropsWithChildren } from 'react'

import FolderListItem from './FolderListItem'
import { StyledFolderListContainer } from './FolderListStyled'

interface FolderListRoot<T> extends React.FC<PropsWithChildren<T>> {
  Item?: typeof FolderListItem
}

interface FolderListProps {
  noBorder?: boolean
  smallPadding?: boolean
}

const FolderList: FolderListRoot<FolderListProps> = ({ children, noBorder, smallPadding }) => (
  <StyledFolderListContainer $noBorder={noBorder} $smallPadding={smallPadding}>
    {children}
  </StyledFolderListContainer>
)

export default FolderList
