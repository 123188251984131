import _ from 'lodash'

import React from 'react'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { OTHER_OPTION, STATUS_OPTIONS_VALUES } from 'services/users/constants'

import { isRequired } from 'utils/fieldValidation'

import { Form, Space, Typography } from 'components'

import i18n from 'translations'

import { StyledContainerForm } from './InjuryBasicFormStyled'

export const INJURY_BASIC_FORM = 'InjuryBasicForm'

const MAX_MENU_HEIGHT = 150

const InjuryBasicForm = ({
  handleSubmit,
  onSubmit,
  senior,
  supervisor,
  witnesses,
}) => {
  const isOtherOptionExists = _.some(witnesses, (item) => _.includes(OTHER_OPTION.value, item.value))

  return (
    <StyledContainerForm>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row
          label={i18n.t('module:Injury:Preview:Details:dateTimeLabel')}
          width={{ field: '300px', label: '125px' }}
        >
          <Field
            component={Form.DateTimePicker}
            name="date"
          />
        </Form.Row>
        <Form.Row
          label={i18n.t('module:Injury:Preview:Details:locationLabel')}
          labelMargin="0 20px 0 0"
          width={{ field: '300px', label: '125px' }}
          required
        >
          <Field
            component={Form.TextField}
            name="location"
            placeholder={i18n.t('module:Injury:Preview:Details:locationLabel')}
          />
        </Form.Row>
        <Form.Row>
          <Typography fontSize="20" bold>{i18n.t('global:Staff')}</Typography>
        </Form.Row>
        <Form.Row
          label={i18n.t('module:Injury:Preview:Details:staffSupervisingLabel')}
          labelMargin="0 20px 0 0"
          width={{ field: '300px', label: '125px' }}
          required
        >
          <Field
            component={Form.InfiniteDropdowns.Users}
            extraOptions={[OTHER_OPTION]}
            maxMenuHeight={MAX_MENU_HEIGHT}
            name="supervisor"
            placeholder={i18n.t('module:Injury:Preview:Details:staffSupervisingLabel')}
            status={STATUS_OPTIONS_VALUES.ACTIVE}
            searchable
          />
        </Form.Row>
        {OTHER_OPTION.value === supervisor?.value && (
          <Form.Row
            label={i18n.t('module:Injury:Fields:OtherStaffSupervising:label')}
            labelMargin="0 20px 0 0"
            width={{ field: '300px', label: '125px' }}
            required
          >
            <Field
              component={Form.TextField}
              name="otherStaffSupervising"
              placeholder={i18n.t('module:Injury:Fields:OtherStaffSupervising:placeholder')}
              validate={isRequired}
            />
          </Form.Row>
        )}
        <Form.Row
          label={i18n.t('module:Injury:Preview:Details:seniorInChargeLabel')}
          labelMargin="0 20px 0 0"
          width={{ field: '300px', label: '125px' }}
          required
        >
          <Field
            component={Form.InfiniteDropdowns.Users}
            extraOptions={[OTHER_OPTION]}
            maxMenuHeight={MAX_MENU_HEIGHT}
            name="senior"
            placeholder={i18n.t('module:Injury:Preview:Details:seniorInChargeLabel')}
            status={STATUS_OPTIONS_VALUES.ACTIVE}
            searchable
          />
        </Form.Row>
        {OTHER_OPTION.value === senior?.value && (
          <Form.Row
            label={i18n.t('module:Injury:Fields:OtherSeniorIncharge:label')}
            labelMargin="0 20px 0 0"
            width={{ field: '300px', label: '125px' }}
            required
          >
            <Field
              component={Form.TextField}
              name="otherSeniorInCharge"
              placeholder={i18n.t('module:Injury:Fields:OtherSeniorIncharge:placeholder')}
              validate={isRequired}
            />
          </Form.Row>
        )}
        <Form.Row
          label={i18n.t('module:Injury:Preview:Details:witnessedByLabel')}
          labelMargin="0 20px 0 0"
          width={{ field: '300px', label: '125px' }}
          required
        >
          <Field
            component={Form.InfiniteDropdowns.Users}
            extraOptions={[OTHER_OPTION]}
            maxMenuHeight={100}
            name="witnesses"
            placeholder={i18n.t('module:Injury:Preview:Details:witnessedByLabel')}
            status={STATUS_OPTIONS_VALUES.ACTIVE}
            autosize
            multi
            searchable
          />
        </Form.Row>
        {isOtherOptionExists && (
          <Form.Row
            label={i18n.t('module:Injury:Fields:OtherWitnessedBy:label')}
            labelMargin="0 20px 0 0"
            width={{ field: '190px', label: '125px' }}
            required
          >
            <Field
              component={Form.TextField}
              name="otherWitness"
              placeholder={i18n.t('module:Injury:Fields:OtherWitnessedBy:placeholder')}
              validate={isRequired}
            />
          </Form.Row>
        )}
      </Form>
      <Space space="78px" />
    </StyledContainerForm>
  )
}

const mapState = (state) => ({
  senior: formValueSelector(INJURY_BASIC_FORM)(state, 'senior'),
  supervisor: formValueSelector(INJURY_BASIC_FORM)(state, 'supervisor'),
  witnesses: formValueSelector(INJURY_BASIC_FORM)(state, 'witnesses'),
})

const enhance = compose(
  reduxForm({ form: INJURY_BASIC_FORM }),
  connect(mapState),
)

export default enhance(InjuryBasicForm)

