import { generateBasicActions } from 'utils/service'

import {
  CLEAR_NEXT_STEP,
  CREATE_NEXT_STEP,
  CREATE_NEXT_STEP_FAILED,
  CREATE_NEXT_STEP_SUCCESS,
  GET_NEXT_STEP,
  GET_NEXT_STEP_FAILED,
  GET_NEXT_STEP_SUCCESS,
  REMOVE_NEXT_STEP,
  REMOVE_NEXT_STEP_FAILED,
  REMOVE_NEXT_STEP_SUCCESS,
  UPDATE_NEXT_STEP,
  UPDATE_NEXT_STEP_FAILED,
  UPDATE_NEXT_STEP_SUCCESS,
} from './constants'

export const create = (options = {}) => ({ nextStepsApiClient }) => generateBasicActions.create({
  constants: {
    failed: CREATE_NEXT_STEP_FAILED,
    init: CREATE_NEXT_STEP,
    success: CREATE_NEXT_STEP_SUCCESS,
  },
  options,
  service: nextStepsApiClient,
  serviceMethod: 'create',
})

export const get = (options = {}) => ({ nextStepsApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_NEXT_STEP_FAILED,
    init: GET_NEXT_STEP,
    success: GET_NEXT_STEP_SUCCESS,
  },
  options,
  service: nextStepsApiClient,
  serviceMethod: 'get',
})

export const remove = (options = {}) => ({ nextStepsApiClient }) => generateBasicActions.remove({
  constants: {
    failed: REMOVE_NEXT_STEP_FAILED,
    init: REMOVE_NEXT_STEP,
    success: REMOVE_NEXT_STEP_SUCCESS,
  },
  options,
  service: nextStepsApiClient,
  serviceMethod: 'remove',
})

export const update = (options = {}) => ({ nextStepsApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_NEXT_STEP_FAILED,
    init: UPDATE_NEXT_STEP,
    success: UPDATE_NEXT_STEP_SUCCESS,
  },
  options,
  service: nextStepsApiClient,
  serviceMethod: 'update',
})

export const clear = () => ({
  type: CLEAR_NEXT_STEP,
})
