export const customChartConfig = {
  plotOptions: {
    column: {
      dataLabels: {
        formatter: function () { // eslint-disable-line
          return `${this.y}%`
        },
      },
    },
  },
  tooltip: {
    pointFormatter: function () { // eslint-disable-line
      return `<br/><p>${this.series.name}: <span style="font-weight:600">${this.y}%</span></p>`
    },
  },
  yAxis: {
    labels: {
      formatter: function () { // eslint-disable-line
        return `${this.value}%`
      },
    },
    max: 100,
    stackLabels: {
      formatter: function () { // eslint-disable-line
        return `${this.total}%`
      },
    },
  },
}
