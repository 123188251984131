import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { CircleIcon, Form, Tooltip } from 'components'

const NumberSettingsFormRow = ({ component, label, tooltip }) => (
  <Form.Row>
    <Form.Row.FlexItem flex="0 0 300px">
      {label}
    </Form.Row.FlexItem>
    <Form.Row.Item>
      {component}
    </Form.Row.Item>
    <Form.Row.Item margin="0 0 0 5px">
      <Tooltip
        placement="right"
        title={tooltip}
      >
        <CircleIcon
          color={NEUTRAL_COLOURS.WHITE}
          cursor="pointer"
          icon="info"
          size={24}
          secondary
        />
      </Tooltip>
    </Form.Row.Item>
  </Form.Row>
)

export default NumberSettingsFormRow
