import { compose } from 'recompose'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getFormValues, stopSubmit } from 'redux-form'

import { constants as invoicesConstants } from 'services/legacy/invoices'

import { getBackendErrors } from 'utils/backendErrors'
import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withInvoicePaymentsService } from 'services/legacy/invoicePayments'
import { withNurseriesService } from 'services/nurseries'
import { withModalService } from 'services/utils/modal'
import { withRouter } from 'services/router'

import i18n from 'translations'

import { getNumberWithPrefix } from 'services/nurseries/single/selectors/settings'
import ChildFinanceInvoicesPaymentView from './ChildFinanceInvoicesPaymentView'
import { CHILD_FINANCE_INVOICES_PAYMENT_FORM } from './components/ChildFinanceInvoicesPaymentForm'

const NURSERY_INVOICE_SETTINGS_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings.invoice',
    'nurseryInvoiceSettings',
    'nurserySettings.localeDetails',
    'nurseryInvoiceSettings.numbers',
    'invoiceNumbers',
    'nursery.organizationSettings',
    'organizationSettings',
    'organizationSettings.invoice',
    'organizationInvoiceSettings',
  ],
}

const CHILD_FINANCE_DETAILS_GROUPS = {
  read: [
    'child.finance',
  ],
}

class ChildFinanceInvoicesPaymentContainer extends Component {
  constructor(props) {
    super(props)

    const { params: { childId } } = this.props

    this.state = {
      isChildContext: !!childId,
      receiptNumber: null,
    }
  }

  componentDidMount() {
    const {
      nurseriesActions,
      nurseryOptions,
      params: { childId },
    } = this.props
    const { isChildContext } = this.state

    if (isChildContext) {
      this.fetchChildFinanceDetails(childId)
    }

    nurseriesActions.get(nurseryOptions.id, {
      onSuccess: this.handleGetNurserySuccess,
      onlyData: true,
      params: { groups: NURSERY_INVOICE_SETTINGS_GROUPS },
    })
  }

  componentWillUnmount() {
    const { childActions } = this.props

    childActions.clearFinanceDetails()
  }

  handleGetNurserySuccess = ({ data }) => {
    const { nurserySettings } = data
    const { invoice } = nurserySettings
    const { invoiceNumbers } = invoice
    const { receiptNumber, receiptNumberPrefix } = invoiceNumbers
    const receiptNumberWithPrefix = getNumberWithPrefix(receiptNumberPrefix, receiptNumber)

    this.setState({ receiptNumber: receiptNumberWithPrefix })
  }

  fetchChildFinanceDetails = (childId) => {
    const { childActions } = this.props

    childActions.getFinanceDetails({
      params: [childId, {
        groups: CHILD_FINANCE_DETAILS_GROUPS,
      }],
    })
  }

  getRoute = () => {
    const { location: { pathname }, params: { childId } } = this.props

    switch (pathname) {
      case generateRoute('FINANCE.PAYMENTS.ADD'):
        return generateRoute('FINANCE.PAYMENTS')
      case generateRoute('CHILDREN.CHILD.FINANCE.PAYMENT_HISTORY.ADD_PAYMENT', { childId }):
        return generateRoute('CHILDREN.CHILD.FINANCE.PAYMENT_HISTORY', { childId })
      default:
        return generateRoute('CHILDREN.CHILD.FINANCE', { childId })
    }
  }

  handleChildChange = (child) => {
    const { childActions } = this.props

    if (child) {
      this.fetchChildFinanceDetails(child.value)
    } else {
      childActions.clearFinanceDetails()
    }
  }

  handleSubmitSuccess = () => {
    const { navigate } = this.props

    navigate(this.getRoute())
  }

  handleSubmitFailed = (response = {}) => {
    const { injectValidation, modalActions, modalConsts } = this.props
    const { code } = response

    if (invoicesConstants.ERROR_CODES.MISSING_PAYMENT_CONTACT === code) {
      return modalActions.show(modalConsts.TYPES.ALERT, {
        text: i18n.t('module:Children:Child:Finance:Payment:Add:sendReceiptErrorAlert'),
      })
    }

    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    return injectValidation(CHILD_FINANCE_INVOICES_PAYMENT_FORM, errors)
  }

  handleSubmit = (fields) => {
    const { childFinanceDetails, invoicePaymentsActions, invoicePaymentsSelectors } = this.props

    const body = invoicePaymentsSelectors.getInvoicePaymentValuesForm({
      ...fields,
      child: childFinanceDetails?.id,
    })

    invoicePaymentsActions.create(
      body,
      this.handleSubmitSuccess,
      this.handleSubmitFailed,
    )
  }

  render() {
    const {
      childFinanceDetails,
      errorMessages,
      formValues,
      initialValues,
      isChildFetching,
      isSubmitting,
    } = this.props
    const { isChildContext, receiptNumber } = this.state

    return (
      <ChildFinanceInvoicesPaymentView
        balance={childFinanceDetails?.balance}
        cancelButtonLink={this.getRoute()}
        child={childFinanceDetails}
        errorMessages={errorMessages}
        formValues={formValues}
        initialValues={initialValues}
        isChildFetching={isChildFetching}
        isSubmitting={isSubmitting}
        receiptNumber={receiptNumber}
        showChildrenDropdown={!isChildContext}
        onChildChange={this.handleChildChange}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  injectValidation: (formName, data) => stopSubmit(formName, data),
}

const mapState = (state, {
  appSelectors,
  childSelectors,
  childSingleState,
  invoicePaymentsSelectors,
  nurseriesSingleState,
  params,
}) => ({
  childFinanceDetails: childSelectors.getFinanceDetailsSelector(state),
  errorMessages: appSelectors.getErrorMessages(
    childSingleState,
    nurseriesSingleState,
  ),
  formValues: getFormValues(CHILD_FINANCE_INVOICES_PAYMENT_FORM)(state),
  initialValues: invoicePaymentsSelectors.getInitialValuesForPaymentForm(state),
  isChildFetching: childSelectors.getChildIsFetching(state),
  isEdit: !!params.paymentId,
  isSubmitting: invoicePaymentsSelectors.getInvoicePaymentsSingleIsSubmitting(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const enhance = compose(
  withAppService,
  withChildService,
  withModalService,
  withInvoicePaymentsService,
  withNurseriesService,
  withRouter,
  connect(mapState, mapDispatch),
)

export default enhance(ChildFinanceInvoicesPaymentContainer)
