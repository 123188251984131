import _ from 'lodash'
import { compose } from 'recompose'
import { flatten, nest } from 'utils/flatnest'

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { FILE_TYPES } from 'constants/mimetypes'
import { CHILD_AGES_OPTIONS, REPORT_TYPES } from 'services/legacy/child/constants'
import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withChildService, withChildStatisticsService } from 'services/legacy/child'
import { withModalService } from 'services/utils/modal'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withSortingUtils } from 'services/utils/sorting'
import { withRouter } from 'services/router'

import i18n from 'translations'

import { PHOTO_PERMISSIONS_REPORT_LIST_COLUMNS } from './tableConfig'

import PhotoPermissionsReportView from './PhotoPermissionsReportView'

const GROUPS = {
  read: [
    'child.childrenSessions:array',
    'childInformation',
  ],
}
const SEND_REPORT_GROUPS = {
  empty: ['export:child:photo-permissions'],
  read: ['child', 'child.childrenSessions:array'],
}

class PhotoPermissionsReportContainer extends Component {
  constructor(props) {
    super(props)

    const { query } = props.location
    const { age, room } = nest(query)

    this.state = {
      filters: {
        age: age || '',
        room: room || '',
      },
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { childActions } = this.props

    childActions.clear()
  }

  fetch = () => {
    const { childActions, childSelectors, paginationUtils, setLocationQuery, sortingUtils } = this.props
    const { getAgeRange, getCriteria } = childSelectors
    const { filters } = this.state
    const { age, room } = filters

    const { page, perPage } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    setLocationQuery(flatten(filters))

    const params = {
      criteria: getCriteria({
        ageRange: getAgeRange(age),
        room,
      }),
      groups: GROUPS,
      limit: perPage,
      order: { sortField, sortOrder },
      page,
    }

    childActions.list({ params })
  }

  handleAgeChange = (age) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, age: age?.value || '' },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handleRoomChange = (room) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, room: room?.value || '' },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleSortChange = (key) => {
    const { paginationUtils, sortingUtils } = this.props
    const { onSortChange } = sortingUtils
    const { onPageChange } = paginationUtils

    onSortChange(onPageChange(this.fetch)(1))(key)
  }

  handleSendReport = () => {
    const { childSelectors, modalActions, modalConsts, sortingUtils } = this.props
    const { getAgeRange } = childSelectors
    const { filters } = this.state
    const { age, room } = filters
    const { sortField, sortOrder } = sortingUtils

    modalActions.show(modalConsts.TYPES.EXPORT_REPORT, {
      filters: {
        ageRange: getAgeRange(age),
        room,
        type: REPORT_TYPES.PHOTO_PERMISSIONS,
      },
      headers: { Accept: FILE_TYPES.PDF.acceptHeader },
      params: {
        groups: SEND_REPORT_GROUPS,
        order: { sortField, sortOrder },
      },
      title: i18n.t(
        'module:Reports:exportReportTitle',
        { type: i18n.t('module:Reports:PhotoPermissions:title') },
      ),
      userLabel: i18n.t('module:Modals:ExportReportModal:User:Label:pdf'),
    })
  }

  getColumns = () => {
    const { authAccessMap: { profileFlags } } = this.props

    return _.filter(PHOTO_PERMISSIONS_REPORT_LIST_COLUMNS, ({ requiredFlags }) => (
      !requiredFlags || (requiredFlags && _.every(requiredFlags, (flag) => profileFlags.includes(flag)))
    ))
  }

  render() {
    const {
      childListState: { isFetching },
      data,
      paginationUtils,
      sortingUtils,
      totalResults,
    } = this.props
    const { filters } = this.state

    const { getPageCount, page, perPage } = paginationUtils
    const { sortField, sortOrder } = sortingUtils
    const { age, room } = filters

    const pageCount = getPageCount(totalResults)

    return (
      <PhotoPermissionsReportView
        age={age}
        ageOptions={CHILD_AGES_OPTIONS}
        columns={this.getColumns()}
        data={data}
        isLoading={isFetching}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        room={room}
        sortField={sortField}
        sortOrder={sortOrder}
        title={i18n.t(
          'module:Reports:reportTitle',
          { type: _.upperFirst(i18n.t('module:Reports:PhotoPermissions:title')) },
        )}
        totalResults={totalResults}
        onAgeChange={this.handleAgeChange}
        onPageChange={this.handlePageChange}
        onRoomChange={this.handleRoomChange}
        onSendReport={this.handleSendReport}
        onTableSortChange={this.handleSortChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childListState,
  childSelectors,
}) => ({
  authAccessMap: {
    profileFlags: auth.SELECTORS.getAuthenticationProfileFeatureFlags(state),
  },
  data: childSelectors.getChildListDataSelector(state),
  totalResults: appSelectors.getTotalResults(childListState),
})

const enhance = compose(
  withAppService,
  withRouter,
  withChildService,
  withChildStatisticsService,
  withPaginationUtils,
  withRouterUtils,
  withSortingUtils,
  withModalService,
  connect(mapState),
)

PhotoPermissionsReportContainer.authParams = {
  flags: [FEATURE_FLAGS.LJ_REPORTS],
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

export default enhance(PhotoPermissionsReportContainer)
