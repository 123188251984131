import { generateBasicActions } from 'utils/service'

import {
  CLEAR_LIST_ASSESSMENT_TRACKER,
  LIST_ASSESSMENT_TRACKER,
  LIST_ASSESSMENT_TRACKER_FAILED,
  LIST_ASSESSMENT_TRACKER_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ assessmentTrackerApiClient }) => generateBasicActions.list({
  constants: {
    failed: LIST_ASSESSMENT_TRACKER_FAILED,
    init: LIST_ASSESSMENT_TRACKER,
    success: LIST_ASSESSMENT_TRACKER_SUCCESS,
  },
  options,
  service: assessmentTrackerApiClient,
  serviceMethod: 'list',
})

export const clearList = () => ({
  type: CLEAR_LIST_ASSESSMENT_TRACKER,
})
