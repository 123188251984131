import _ from 'lodash'

import { createSelector } from 'reselect'

import { ALL_OPTIONS, ERROR_LIST } from '../constants'
import { getFilterOptions, getSelectedOptions } from '../helpers'

const getNews = (state) => state.newsletters

export const getNewsSingleSelector = createSelector(
  [getNews],
  (state) => {
    if (!state) {
      return null
    }

    return state.single
  },
)

export const getSingleNewsData = createSelector(
  [getNewsSingleSelector],
  (state) => state.data,
)

export const getSingleNewsPayload = (values) => {
  if (!values) {
    return null
  }

  const {
    content,
    groups,
    individuals,
    newsletterMedia: media,
    publish,
    sendViaEmail,
    sharedWithParents,
    title,
  } = values

  // Merge group and individual dropdown selected option
  const allFilters = [...(groups || []), ...(individuals || [])]
  // Remove and shift ALL_GROUPS from allFilters
  // key allChildren:<bool>|allStaff:<bool> is in root of object
  const allGroups = _.remove(allFilters, (option) => 0 <= _.findIndex(ALL_OPTIONS, { value: option.value }))

  // map allChildren:<bool>|allStaff:<bool>
  const allVariables = _.reduce(allGroups, (result, item) => {
    const newResult = { ...result }

    newResult[item.value] = true

    return newResult
  }, {})

  _.each(ALL_OPTIONS, ({ value }) => {
    if (!allVariables[value]) {
      allVariables[value] = false
    }
  })

  // map remaining filters array
  const filters = _.map(allFilters, (filter) => {
    const { filterId, type, value } = filter

    return {
      id: filterId,
      [type]: { id: value },
      type,
    }
  })

  const newsletterMedia = media
    ? _.map(media, ({ compositeId, mimeType, name, size, url }) => {
      if (compositeId) {
        return {
          id: compositeId,
          type: 'attachment',
        }
      }

      return {
        medium: {
          mimeType,
          name,
          size,
          url,
        },
        type: 'attachment',
      }
    })
    : undefined

  return {
    ...allVariables,
    content,
    filters,
    newsletterMedia,
    publishNewsletter: publish ? true : undefined,
    sendViaEmail,
    sharedWithParents,
    title,
  }
}

export const getSingleNewsInitialValuesSelectors = createSelector(
  [getSingleNewsData],
  (singleNewsData) => {
    if (!singleNewsData) {
      return null
    }

    const {
      allChildren,
      allStaff,
      content,
      filters,
      newsletterMedia,
      sendViaEmail,
      sharedWithParents,
      title,
    } = singleNewsData

    const groups = []
    const individuals = []

    if (allChildren) {
      groups.push(_.find(ALL_OPTIONS, { value: 'allChildren' }))
    }

    if (allStaff) {
      groups.push(_.find(ALL_OPTIONS, { value: 'allStaff' }))
    }

    const selectedOptions = getSelectedOptions(filters)

    const selectedRooms = _.filter(selectedOptions, { type: 'class' })

    if (selectedRooms?.length) {
      groups.push(...selectedRooms)
    }

    const selectedUsers = _.filter(selectedOptions, { type: 'user' })

    if (selectedUsers?.length) {
      individuals.push(...selectedUsers)
    }

    const selectedChildren = _.filter(selectedOptions, { type: 'child' })

    if (selectedChildren?.length) {
      individuals.push(...selectedChildren)
    }

    const media = _.map(newsletterMedia, ({ id, medium }) => ({
      ...medium,
      compositeId: id,
      type: medium.mimeType,
    }))

    return {
      content,
      groups,
      individuals,
      newsletterMedia: media,
      sendViaEmail,
      sharedWithParents,
      title,
    }
  },
)

export const getSingleNewsPreviewDataSelectors = createSelector(
  [getSingleNewsData],
  (singleNewsData) => {
    if (!singleNewsData) {
      return null
    }

    const { allChildren, allStaff, filters } = singleNewsData

    const displayFiltersOptions = getFilterOptions({
      allChildren,
      allStaff,
      filters,
    })

    const displayFilters = _.join(
      _.map(displayFiltersOptions, ({ label }) => label),
      ', ',
    )

    return {
      ...singleNewsData,
      displayFilters,
    }
  },
)

export const getPublishFormErrors = createSelector(
  [(formValues) => formValues],
  (formValues) => {
    if (!formValues) {
      return {
        groups: ERROR_LIST.GROUPS,
        individuals: ERROR_LIST.INDIVIDUALS,
        title: ERROR_LIST.TITLE,
      }
    }

    const { groups, individuals, title } = formValues

    const errors = {}

    if ((!groups?.length) && (!individuals?.length)) {
      errors.groups = ERROR_LIST.GROUPS
      errors.individuals = ERROR_LIST.INDIVIDUALS
    }

    if (!title) {
      errors.title = ERROR_LIST.TITLE
    }

    return errors
  },
)
