import React from 'react'

import { StyledLabel } from './ShiftLabelStyled'

const ShiftLabel = ({ center, children, code, type }) => (
  <StyledLabel center={center} code={code} type={type}>
    {children}
  </StyledLabel>
)

export default ShiftLabel
