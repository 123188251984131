import _ from 'lodash'

import { compose } from 'recompose'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { INJURIES, INJURIES_STATUS, SIGNATURE_TYPES, TAB_NAMES } from 'services/legacy/injuries/constants'

import { generateRoute } from 'utils/routing'

import { withInjuriesService } from 'services/legacy/injuries'
import { withModalService } from 'services/utils/modal'
import { withShellService } from 'services/shell'
import { hasOnlySeniorTeacherOrTeacherAccess } from 'services/security/user/selectors'
import { generateValidationErrorMsg } from 'services/legacy/injuries/selectors'
import { withRouter } from 'services/router'

import i18n from 'translations'

import { INJURY_PREVIEW_PAGE_TYPE } from './constants'

import InjurySingleRecordView from './InjurySingleRecordView'

export const GROUPS = {
  read: [
    'injured.media',
    'medium',
    'injury.injurySignatureStatistics',
    'injury.injured',
    'injured',
    'injured.medium',
    'injured.child',
    'injured.firstAider',
    'child',
    'child.nurseryClass',
    'nurseryClass',
    'childProfile',
    'childInformation',
    'injury.witnesses',
    'injury.supervisor',
    'injury.senior',
    'injured.accidentDetails',
    'user',
  ],
}

export const HOME_ACCIDENT_GROUPS = {
  read: [
    'injured.child',
    'injured.accidentDetails',
    'injury.injurySignatureStatistics',
    'injury.injured',
    'medium',
    'injured',
    'injury.signatureDocuments',
    'injured.media',
    'injury.signatures',
    'child',
    'childProfile',
  ],
}

class InjurySingleRecordContainer extends Component {
  constructor(props) {
    super(props)

    const { location, params } = props
    const { pathname, query } = location
    const { tab } = query
    const { childId } = params

    this.state = {
      isChildContext: !!childId,
      isHomeAccidents: pathname.includes(generateRoute('SAFEGUARDING.HOME_ACCIDENTS.LIST')),
      pageType: this.getPageType(),
      tab: tab || TAB_NAMES.DETAILS,
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { injuriesActions } = this.props

    injuriesActions.clearSingleInjury()
    injuriesActions.clearInjurySignatures()
  }

  componentDidUpdate(prevProps) {
    const { params } = this.props
    const { params: prevParams } = prevProps
    const { injuryId } = params
    const { injuryId: prevInjuryId } = prevParams

    if (prevInjuryId && injuryId && prevInjuryId !== injuryId) {
      this.fetch()
    }
  }

  fetch = () => {
    const {
      injuriesActions,
      params: { injuryId },
    } = this.props
    const { isHomeAccidents, tab } = this.state

    const params = {
      groups: isHomeAccidents ? HOME_ACCIDENT_GROUPS : GROUPS,
    }

    injuriesActions.get(injuryId, {
      onFailed: this.handleChangeInjurySuccess,
      onSuccess: ({ data }) => {
        const { location: { pathname }, params: { injuryType }, shellActions } = this.props
        const { signatureCollectingType, type } = data
        const routeInjuryTitle = type === INJURIES.INCIDENT
          ? i18n.t('module:Injury:Preview:incidentRecordTitle')
          : i18n.t('module:Injury:Preview:accidentRecordTitle')

        if (TAB_NAMES.SIGNATURES === tab) {
          this.fetchSignatures(signatureCollectingType, type)
        }

        if (generateRoute('APPROVALS.INJURY.PREVIEW', { injuryId, injuryType }) === pathname) {
          return shellActions.setRouteTitle({
            name: 'APPROVALS.INJURY.PREVIEW',
            title: type === INJURIES.INCIDENT
              ? i18n.t('module:Injury:Preview:incidentRecordTitle')
              : i18n.t('module:Injury:Preview:accidentRecordTitle'),
          })
        }

        return shellActions.setRouteTitle({
          name: type === INJURIES.HOME_ACCIDENT
            ? 'SAFEGUARDING.HOME_ACCIDENTS.SINGLE_PREVIEW'
            : 'SAFEGUARDING.INJURY.SINGLE_PREVIEW',
          title: type === INJURIES.HOME_ACCIDENT ? i18n.t('module:Safeguarding:List:previewTitle') : routeInjuryTitle,
        })
      },
      params,
    })
  }

  fetchSignatures = (signatureCollectingType, type) => {
    const {
      injuriesActions,
      injuriesSelectors,
      params: { injuryId },
    } = this.props

    if (signatureCollectingType && type) {
      const params = {
        groups: injuriesSelectors.getSignatureSerializationGroups(signatureCollectingType, type),
      }

      injuriesActions.getSignatures(injuryId, {
        onSuccess: (response) => this.handleAddDefaultStaffMemberClick(response.data),
        params,
      })
    }
  }

  getPageType = () => {
    const { location: { pathname }, params: { injuryId, injuryType } } = this.props

    if (generateRoute('APPROVALS.INJURY.PREVIEW', { injuryId, injuryType }) === pathname) {
      return INJURY_PREVIEW_PAGE_TYPE.APPROVALS
    }

    if (generateRoute('SAFEGUARDING.INJURY.SINGLE_PREVIEW', { injuryId, injuryType }) === pathname) {
      return INJURY_PREVIEW_PAGE_TYPE.INJURY
    }

    return INJURY_PREVIEW_PAGE_TYPE.CHILD
  }

  handleSendReportModalClick = (body, onSuccess, onFailed) => {
    const {
      injuriesActions,
      injuryPreview: { id },
    } = this.props

    injuriesActions.sendReport(id, {
      body, onFailed, onSuccess,
    })
  }

  handleSendReport = () => {
    const {
      injuryPreview: { type },
      modalActions,
      modalConsts,
    } = this.props
    const { isHomeAccidents } = this.state

    modalActions.show(modalConsts.TYPES.EXPORT_REPORT, {
      filters: { isArchived: true, type: type.toLowerCase() },
      onSendReport: this.handleSendReportModalClick,
      title: i18n.t(isHomeAccidents
        ? 'module:Safeguarding:Preview:exportTitle'
        : 'module:Injury:Preview:exportTitle', { type: type.toLowerCase() }),
      userLabel: i18n.t('module:Injury:Preview:exportUserLabel'),
    })
  }

  handleUpdateStatus = (status) => {
    const {
      injuryPreview: { type },
      modalActions,
      modalConsts,
    } = this.props
    const { isHomeAccidents } = this.state

    const approvePopupCopy = isHomeAccidents
      ? i18n.t('module:Safeguarding:Preview:approvePopupCopy')
      : i18n.t('module:Injury:Preview:approvePopupCopy', { type: type.toLowerCase() })

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: (
        status === INJURIES_STATUS.APPROVED_AND_SIGNED.value || status === INJURIES_STATUS.SIGNATURES_NEEDED.value
          ? i18n.t('module:Injury:Common:approve')
          : i18n.t('global:send')
      ),
      onConfirm: () => this.handleUpdateStatusConfirmed(status),
      text: status === INJURIES_STATUS.APPROVED_AND_SIGNED.value || status === INJURIES_STATUS.SIGNATURES_NEEDED.value
        ? approvePopupCopy
        : i18n.t('module:Injury:Preview:pendingPopupCopy'),
    })
  }

  handleChangeInjurySuccess = () => {
    const { navigate, params } = this.props
    const { isChildContext, isHomeAccidents, pageType } = this.state
    const { childId } = params

    const isApprovalsContext = pageType === INJURY_PREVIEW_PAGE_TYPE.APPROVALS

    const navigateHomeAccidents = isChildContext
      ? generateRoute('CHILDREN.CHILD.SAFEGUARDING.HOME_ACCIDENTS', { childId })
      : generateRoute('SAFEGUARDING.HOME_ACCIDENTS', { childId })

    const navigateAccidentIncident = isChildContext
      ? generateRoute('CHILDREN.CHILD.SAFEGUARDING.INJURY', { childId })
      : generateRoute('SAFEGUARDING.INJURY', { childId })

    const navigationToList = isHomeAccidents
      ? generateRoute(isChildContext
        ? 'CHILDREN.CHILD.SAFEGUARDING.HOME_ACCIDENTS'
        : 'SAFEGUARDING.HOME_ACCIDENTS.LIST')
      : generateRoute(isChildContext ? 'CHILDREN.CHILD.SAFEGUARDING.INJURY' : 'SAFEGUARDING.INJURY.LIST')

    if (childId) {
      return navigate(isHomeAccidents ? navigateHomeAccidents : navigateAccidentIncident)
    }

    return navigate(isApprovalsContext
      ? generateRoute('APPROVALS.INJURY')
      : navigationToList)
  }

  handleChangeInjuryFailed = (error) => {
    const { injuryPreview, modalActions, modalConsts } = this.props
    const { isHomeAccidents } = this.state
    const errorText = generateValidationErrorMsg({ data: injuryPreview, error, isHomeAccidents })

    modalActions.show(modalConsts.TYPES.ALERT, {
      text: errorText,
    })
  }

  handleUpdateStatusConfirmed = (status) => {
    const {
      injuriesActions,
      injuryPreview: { id },
    } = this.props

    return injuriesActions.update(id, {
      body: { status },
      onFailed: this.handleChangeInjuryFailed,
      onSuccess: this.handleChangeInjurySuccess,
      params: { groups: GROUPS },
    })
  }

  handleRemove = () => {
    const {
      injuryPreview: { id, type },
      modalActions,
      modalConsts,
    } = this.props
    const { isHomeAccidents } = this.state

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      confirmButtonLabel: i18n.t('global:Yes'),
      icon: 'trash',
      onConfirm: () => this.handleConfirmRemove(id),
      text: isHomeAccidents
        ? i18n.t('module:Safeguarding:Add:deletePopupCopy')
        : i18n.t('module:Injury:Add:deletePopupCopy', { type: type.toLowerCase() }),
    })
  }

  handleConfirmRemove = (injury) => {
    const { injuriesActions } = this.props

    injuriesActions.remove(injury, {
      onSuccess: this.handleChangeInjurySuccess,
    })
  }

  handleChangeTab = (newValue) => {
    const { injuriesSignaturesState, injuryPreview } = this.props
    const { signatureCollectingType, type } = injuryPreview

    if (TAB_NAMES.SIGNATURES === newValue && !injuriesSignaturesState?.data) {
      this.fetchSignatures(signatureCollectingType, type)
    }

    this.setState({ tab: newValue })
  }

  handleAddDefaultStaffMemberClick = (data) => {
    const { injuriesActions } = this.props
    const { isHomeAccidents } = this.state
    const { signatures } = data
    const isRemoveSignature = true

    const staffSignatures = _.filter(signatures, (item) => (item.type === SIGNATURE_TYPES.WITNESS))

    if (isHomeAccidents) {
      for (let i = 0; (2 - staffSignatures.length) > i; i += 1) {
        injuriesActions.addStaffMemberItem({ owner: {} }, isRemoveSignature)
      }
    }
  }

  render() {
    const {
      authAccessMap,
      injuryPreview,
      isFetching,
      isGrantedEdit,
      isGrantedRequestForApproval,
      isSubmittingUpdateStatus,
      params,
    } = this.props

    const { isChildContext, isHomeAccidents, pageType, tab } = this.state
    const { childId } = params

    return (
      <InjurySingleRecordView
        authAccessMap={authAccessMap}
        childId={childId}
        injuryPreview={injuryPreview}
        isChildContext={isChildContext}
        isFetching={isFetching}
        isGrantedEdit={isGrantedEdit}
        isGrantedRequestForApproval={isGrantedRequestForApproval}
        isHomeAccidents={isHomeAccidents}
        isSubmittingUpdateStatus={isSubmittingUpdateStatus}
        pageType={pageType}
        tab={tab}
        onChangeTab={this.handleChangeTab}
        onRemove={this.handleRemove}
        onSendReport={this.handleSendReport}
        onUpdateStatus={this.handleUpdateStatus}
      />
    )
  }
}

const mapState = (state, { injuriesSelectors }) => ({
  authAccessMap: {
    section: {
      hasOnlySeniorTeacherOrTeacherAccess: hasOnlySeniorTeacherOrTeacherAccess(state),
    },
  },
  injuryPreview: injuriesSelectors.getPreview(state),
  isFetching: injuriesSelectors.injuryIsFetching(state),
  isGrantedEdit: injuriesSelectors.isGrantedEdit(state),
  isGrantedRequestForApproval: injuriesSelectors.isGrantedRequestForApproval(state),
  isSubmittingUpdateStatus: injuriesSelectors.injuryIsSubmittingUpdateStatus(state),
})

const enhance = compose(
  withRouter,
  withInjuriesService,
  withModalService,
  withShellService,
  connect(mapState),
)

export default enhance(InjurySingleRecordContainer)
