import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import constants from 'services/legacy/invoices/constants'
import { constants as invoicesConstants } from 'services/legacy/invoices'
import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { generateRoute } from 'utils/routing'

import { Button, Currency, DateString } from 'components'
import CreditNoteIcon from 'module/Finance/components/CreditNoteIcon'

import { StyledName } from './ChildFinanceDetailsInvoicesStyled'

export const getInvoices = (invoices) => {
  if (!invoices) {
    return null
  }

  const { STATUSES } = invoicesConstants

  const mapInvoice = (invoice) => {
    const {
      child,
      containCreditNotes,
      id,
      issueDate,
      name,
      number,
      status,
      total,
      totalOutstanding,
      type,
    } = invoice

    let preparedName = name || '-'
    const isCreditNote = type === constants.INVOICE_TYPES.CREDIT_NOTE

    if (isCreditNote) {
      preparedName = 'Credit note'
    }

    if (containCreditNotes) {
      preparedName = (
        <StyledName>
          <span>
            {name}
          </span>
          <CreditNoteIcon />
        </StyledName>
      )
    }

    const statusLabel = STATUSES[status]

    return {
      containCreditNotes,
      id,
      inactive: !!containCreditNotes,
      issueDate: issueDate ? <DateString date={issueDate} /> : '-',
      name: preparedName,
      number: (
        <span>
          {number || '-'}
        </span>
      ),
      rowToButton: isCreditNote ? (
        <Button.TableAction
          to={generateRoute('CHILDREN.CHILD.FINANCE.CREDIT_NOTES', { childId: child.id, creditNoteId: id })}
        />
      ) : (
        <Button.TableAction
          to={generateRoute('CHILDREN.CHILD.FINANCE.SINGLE_INVOICE', { childId: child.id, invoiceId: id })}
        />
      ),
      status: (
        <span>
          {statusLabel || '-'}
        </span>
      ),
      total: <Currency value={total} highlight />,
      totalOutstanding: 0 < totalOutstanding ? <Currency value={totalOutstanding} highlight /> : '-',
    }
  }

  return _.map(invoices, mapInvoice)
}

export const getCriteria = (filters, childId) => {
  const { endDate, startDate, status, type } = filters
  const { STATUS_TYPES } = invoicesConstants
  const criteria = [
    {
      field: 'child',
      value: childId,
    },
  ]

  if (startDate) {
    criteria.push({
      comparator: 'after',
      field: 'issueDate',
      value: moment(startDate).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (endDate) {
    criteria.push({
      comparator: 'before',
      field: 'issueDate',
      value: moment(endDate).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (status && status !== STATUS_TYPES.ALL) {
    criteria.push({
      field: 'status',
      value: status,
    })
  }

  if (type) {
    criteria.push({
      field: 'type',
      value: type,
    })
  }

  return criteria
}

export const getStatusOptions = () => _.keys(invoicesConstants.STATUSES).map((status) => ({
  label: invoicesConstants.STATUSES[status],
  value: status,
}))
