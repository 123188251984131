import React, { Component } from 'react'
import { compose } from 'recompose'

import { generateRoute } from 'utils/routing'

import withStaffRegisterWrapper from './withStaffRegisterWrapper'
import StaffRegisterWrapperView from './StaffRegisterWrapperView'

class StaffRegisterWrapperContainer extends Component {
  componentDidMount() {
    const {
      clearStoreAndRedirectToStaffList,
      location: { pathname },
      router,
    } = this.props

    if (generateRoute('STAFF_REGISTER.INDEX') === pathname) {
      clearStoreAndRedirectToStaffList(() => router.replace(generateRoute('STAFF_REGISTER.LIST')))
    }
  }

  render() {
    const { children } = this.props

    return <StaffRegisterWrapperView>{children}</StaffRegisterWrapperView>
  }
}

const enhance = compose(
  withStaffRegisterWrapper,
)

export default enhance(StaffRegisterWrapperContainer)

