import _ from 'lodash'
import moment from 'moment'

import React from 'react'
import { createSelector } from 'reselect'
import { formValueSelector, isDirty } from 'redux-form'

import { toFloat } from 'utils/data'
import { floatToHoursAndMinutes, hoursAndMinutesToFloat } from 'utils/date'

import colors from 'constants/colors'

import { OTHER_FUNDING_TYPE_CODE } from 'services/legacy/fundingTypes/constants'
import { getFundingTypesOptionsSelectors } from 'services/legacy/fundingTypes/selectors'

import { CircleIcon, Currency, DateString } from 'components'

import i18n from 'translations'

import {
  DISCOUNT_TYPES,
  FUNDING_CALCULATION_OPTIONS,
  FUNDING_CALCULATION_TYPES,
  FUNDING_FEES_OPTIONS,
  FUNDING_FEES_TYPES,
  FUNDING_INVOICE_DEDUCTION_OPTIONS,
  FUNDING_INVOICE_DEDUCTION_TYPES,
  FUNDING_LINE_ITEM_OPTIONS,
  FUNDING_LINE_ITEM_TYPES,
  TYPES,
} from '../constants'

export const getNurseryFundingSelectors = (state) => state.nurseryFunding

export const getNurseryFundingSingleDataSelectors = createSelector(
  [getNurseryFundingSelectors],
  (state) => state.single.data,
)

export const getHoursAndMinutes = (item) => {
  if (!item) {
    return null
  }

  const { hours, minutes } = item

  const returnString = []

  if (hours) {
    returnString.push(`${hours} ${i18n.t('global:hours')}`)
  }

  if (minutes) {
    returnString.push(`${minutes} ${i18n.t('global:minutes')}`)
  }

  if (!returnString.length) {
    return null
  }

  return returnString.join(' ')
}

export const getOverridableString = (item) => {
  if (!item) {
    return null
  }

  const { allowOverride } = item

  if (null !== allowOverride && allowOverride !== undefined) {
    if (allowOverride) {
      return i18n.t('module:Children:Child:BookingPattern:Funding:Allocation:FundingDetail:canOverridden')
    }

    return i18n.t('module:Children:Child:BookingPattern:Funding:Allocation:FundingDetail:cannotOverridden')
  }

  return null
}

export const getPerWeekString = (item) => {
  if (!item) {
    return null
  }

  const perWeekStrings = []

  const hoursAndMinutesString = getHoursAndMinutes(item)
  const overridableString = getOverridableString(item)

  if (hoursAndMinutesString) {
    perWeekStrings.push(hoursAndMinutesString)
  }

  if (overridableString) {
    perWeekStrings.push(`(${overridableString})`)
  }

  if (!perWeekStrings.length) {
    return null
  }

  return perWeekStrings.join(' ')
}

export const getFormattedExcludedPeriods = (excludedPeriods) => {
  if (!excludedPeriods || !excludedPeriods.length) {
    return null
  }

  return _.map(excludedPeriods, ({ finishDate, startDate }) => (
    <div>
      <DateString date={startDate} />
      {' '}
      -
      {' '}
      <DateString date={finishDate} />
    </div>
  ))
}

const getDeductedFromInvoiceElement = (type) => {
  if (type === TYPES.NO_DEDUCT) {
    return null
  }

  return <CircleIcon background={colors.pink} icon="deductible-tag" size={40} />
}

export const getNurseryFundingSinglePreviewDataSelectors = createSelector(
  [getNurseryFundingSingleDataSelectors, getFundingTypesOptionsSelectors],
  (singleData) => {
    if (!singleData) {
      return null
    }

    const {
      archived,
      endDate,
      fundingType: { name: fundingName },
      hourlyRate,
      id,
      label,
      settings: {
        excludedPeriods,
        fundingCalculation,
        hoursPerWeek,
        invoiceLineItemDeduction,
        invoiceLineItemDisplay,
        maxHoursPerDay,
      },
      startDate,
      total,
      totalHours,
      type,
    } = singleData

    const feesAllocation = type !== TYPES.NO_DEDUCT ? type : null
    const feesAllocationOption = _.find(FUNDING_FEES_OPTIONS, { value: feesAllocation })
    const fundingCalculationOption = _.find(FUNDING_CALCULATION_OPTIONS, { value: fundingCalculation })
    const invoiceLineItemDeductionOption = _.find(
      FUNDING_INVOICE_DEDUCTION_OPTIONS,
      { value: invoiceLineItemDeduction },
    )
    const invoiceLineItemDisplayOption = _.find(FUNDING_LINE_ITEM_OPTIONS, { value: invoiceLineItemDisplay })

    return {
      deductedFromInvoice: getDeductedFromInvoiceElement(type),
      excludedPeriods: getFormattedExcludedPeriods(excludedPeriods),
      feesAllocation: feesAllocationOption ? feesAllocationOption.label : null,
      fundingCalculation: fundingCalculationOption ? fundingCalculationOption.label : null,
      fundingType: `${fundingName}${archived ? ' (Archived)' : ''}`,
      hourlyRate: hourlyRate ? <Currency value={hourlyRate} /> : null,
      hoursPerWeek: getPerWeekString(hoursPerWeek),
      id,
      invoiceLineItemDeduction: invoiceLineItemDeductionOption ? invoiceLineItemDeductionOption.label : null,
      invoiceLineItemDisplay: invoiceLineItemDisplayOption ? invoiceLineItemDisplayOption.label : null,
      label,
      maxHoursPerDay: getPerWeekString(maxHoursPerDay),
      period: `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`,
      total: <Currency value={total} />,
      totalHours,
    }
  },
)

const getFormatedExcludedPeriod = (excludedPeriods) => {
  if (!excludedPeriods || !excludedPeriods.length) {
    return undefined
  }

  return _.map(excludedPeriods, ({ finishDate, startDate }) => [startDate, finishDate])
}

export const getInitialValuesSelector = (isEdit) => createSelector(
  [getNurseryFundingSingleDataSelectors, getFundingTypesOptionsSelectors],
  (singleData, fundingTypesOptions) => {
    if (!isEdit) {
      return {
        settings: {
          deductedFromInvoice: DISCOUNT_TYPES.DISCOUNT_APPLIED,
          feesAllocation: FUNDING_FEES_TYPES.MANUAL_HOURS,
          fundingCalculation: FUNDING_CALCULATION_TYPES.ACTUAL_SESSIONS,
          hoursPerWeek: { overridePerDay: true },
          invoiceLineItemDeduction: FUNDING_INVOICE_DEDUCTION_TYPES.INVOICE_TOTAL,
          invoiceLineItemDisplay: FUNDING_LINE_ITEM_TYPES.NUMBER_OF_HOURS,
          maxHoursPerDay: { overridePerWeek: true },
        },
      }
    }

    if (!singleData) {
      return null
    }

    const { endDate, fundingType: { id: fundingType }, settings, startDate, totalHours, type } = singleData
    const {
      deficitCharged,
      deficitLineItemName,
      excludedPeriods,
      fundingCalculation,
      hoursPerWeek,
      invoiceLineItemDeduction,
      invoiceLineItemDisplay,
      maxHoursPerDay,
    } = settings
    const { hours, minutes } = floatToHoursAndMinutes(totalHours)

    const fundingTypeOption = _.find(fundingTypesOptions, { value: fundingType })

    return {
      ...singleData,
      endDate: undefined,
      fundingType: fundingTypeOption,
      period: [startDate, endDate],
      settings: {
        deductedFromInvoice: (
          type !== TYPES.NO_DEDUCT
            ? DISCOUNT_TYPES.DISCOUNT_APPLIED
            : DISCOUNT_TYPES.DISCOUNT_NOT_APPLIED
        ),
        deficitCharged,
        deficitLineItemName,
        excludedPeriods: getFormatedExcludedPeriod(excludedPeriods),
        feesAllocation: type !== TYPES.NO_DEDUCT ? type : null,
        fundingCalculation,
        hoursPerWeek,
        invoiceLineItemDeduction,
        invoiceLineItemDisplay,
        maxHoursPerDay,
      },
      startDate: undefined,
      totalHours: {
        hours,
        minutes,
      },
      type: undefined,
    }
  },
)

export const isArchivedSelector = createSelector(
  [getNurseryFundingSingleDataSelectors],
  (singleData) => {
    if (!singleData) {
      return null
    }

    const { archived } = singleData

    return archived
  },
)

const formSelector = (formName) => formValueSelector(formName)

const fundingSettingsSelector = (formName) => (state) => formSelector(formName)(state, 'settings')
const fundingTypeSelector = (formName) => (state) => formSelector(formName)(state, 'fundingType')
const hourlyRateSelector = (formName) => (state) => formValueSelector(formName)(state, 'hourlyRate')
const totalHoursSelector = (formName) => (state) => formValueSelector(formName)(state, 'totalHours')
const uniqueLabelSelector = (formName) => (state) => formValueSelector(formName)(state, 'label')
const periodSelector = (formName) => (state) => formValueSelector(formName)(state, 'period')
const excludedPeriodsSelector = (formName) => (state) => formValueSelector(formName)(
  state,
  'settings.excludedPeriods',
)
const hoursPerWeekSelector = (formName) => (state) => formValueSelector(formName)(
  state,
  'settings.hoursPerWeek',
)

export const isDeductedFromInvoiceSelector = (formName) => createSelector(
  [fundingSettingsSelector(formName)],
  (fundingSetting) => {
    if (!fundingSetting || !fundingSetting.deductedFromInvoice) {
      return null
    }

    const { deductedFromInvoice } = fundingSetting

    return deductedFromInvoice === DISCOUNT_TYPES.DISCOUNT_APPLIED
  },
)

export const isDeficitChangedSelector = (formName) => createSelector(
  [fundingSettingsSelector(formName)],
  (fundingSetting) => {
    if (!fundingSetting) {
      return null
    }

    const { deficitCharged } = fundingSetting

    return deficitCharged
  },
)

export const isActualSelectedSelector = (formName) => createSelector(
  [fundingSettingsSelector(formName)],
  (fundingSetting) => {
    if (!fundingSetting || !fundingSetting.fundingCalculation) {
      return null
    }

    const { fundingCalculation } = fundingSetting

    return fundingCalculation === FUNDING_CALCULATION_TYPES.ACTUAL_SESSIONS
  },
)

export const isSubstractFundingSelectedSelector = (formName) => createSelector(
  [fundingSettingsSelector(formName)],
  (fundingSetting) => {
    if (!fundingSetting || !fundingSetting.feesAllocation) {
      return null
    }

    const { feesAllocation } = fundingSetting

    return feesAllocation === FUNDING_FEES_TYPES.SUBTRACT_AMOUNT_FROM_INVOICE
  },
)

export const isCheapestSessionSelectedSelector = (formName) => createSelector(
  [fundingSettingsSelector(formName)],
  (fundingSetting) => {
    if (!fundingSetting || !fundingSetting.feesAllocation) {
      return null
    }

    const { feesAllocation } = fundingSetting

    return feesAllocation === FUNDING_FEES_TYPES.CHEAPEST_SESSIONS
  },
)

export const isOtherFundingTypeSelectedSelector = (formName) => createSelector(
  [fundingTypeSelector(formName)],
  (fundingType) => {
    if (!fundingType || !fundingType.code) {
      return null
    }

    const { code } = fundingType

    return OTHER_FUNDING_TYPE_CODE === code
  },
)

export const getHourlyRate = (formName) => createSelector(
  [hourlyRateSelector(formName)],
  (hourlyRate) => {
    if (!hourlyRate) {
      return null
    }

    return hourlyRate
  },
)

export const getTotalHours = (formName) => createSelector(
  [totalHoursSelector(formName)],
  (totalHours) => {
    if (!totalHours) {
      return null
    }

    return totalHours
  },
)

export const isDirtySelector = (formName) => createSelector(
  [isDirty(formName)],
  (dirty) => {
    if (!dirty) {
      return null
    }

    return dirty
  },
)

export const getFundingCalculationOptions = (formName) => createSelector(
  [fundingSettingsSelector(formName)],
  (fundingSetting) => {
    if (!fundingSetting) {
      return null
    }

    const { feesAllocation } = fundingSetting

    if (feesAllocation === FUNDING_FEES_TYPES.MANUAL_HOURS) {
      return _.filter(FUNDING_CALCULATION_OPTIONS, (item) => item.value !== FUNDING_CALCULATION_TYPES.AVERAGE_COST)
    }

    return FUNDING_CALCULATION_OPTIONS
  },
)

export const getFundingLineItemOptions = (formName) => createSelector(
  [fundingSettingsSelector(formName), isDeductedFromInvoiceSelector(formName)],
  (fundingSetting, isDeductedFromInvoice) => {
    if (!isDeductedFromInvoice || !fundingSetting || !fundingSetting.feesAllocation) {
      return FUNDING_LINE_ITEM_OPTIONS.filter((option) => !option.fundingFeesTypes)
    }

    const { feesAllocation } = fundingSetting

    return FUNDING_LINE_ITEM_OPTIONS.filter(
      (option) => !option.fundingFeesTypes || option.fundingFeesTypes.includes(feesAllocation),
    )
  },
)

export const getFundingDeductionOptions = (formName) => createSelector(
  [fundingSettingsSelector(formName), isDeductedFromInvoiceSelector(formName)],
  (fundingSetting, isDeductedFromInvoice) => {
    if (!isDeductedFromInvoice || !fundingSetting || !fundingSetting.feesAllocation) {
      return FUNDING_INVOICE_DEDUCTION_OPTIONS.filter((option) => !option.fundingFeesTypes)
    }

    const { feesAllocation } = fundingSetting

    return FUNDING_INVOICE_DEDUCTION_OPTIONS.filter(
      (option) => !option.fundingFeesTypes || option.fundingFeesTypes.includes(feesAllocation),
    )
  },
)

export const getFundingNamePreview = (formName) => createSelector(
  [fundingTypeSelector(formName), uniqueLabelSelector(formName)],
  (fundingType, uniqueLabel) => {
    if (!fundingType && !uniqueLabel) {
      return undefined
    }

    if (!fundingType && uniqueLabel) {
      return uniqueLabel
    }

    const { label } = fundingType

    if (!uniqueLabel) {
      return label
    }

    return `${uniqueLabel} - ${label}`
  },
)

export const getCalculatedTotalHours = (formName) => createSelector(
  [
    periodSelector(formName),
    isDeductedFromInvoiceSelector(formName),
    isSubstractFundingSelectedSelector(formName),
    isCheapestSessionSelectedSelector(formName),
    hoursPerWeekSelector(formName),
    excludedPeriodsSelector(formName),
  ],
  (
    period,
    isDeductedFromInvoice,
    isSubstractFundingSelected,
    isCheapestSessionSelected,
    hoursPerWeek,
    excludedPeriods,
  ) => {
    if (
      !isDeductedFromInvoice
      || isSubstractFundingSelected
      || isCheapestSessionSelected
      || !hoursPerWeek
      || !period
      || !period.length
      || !period[0]
      || !period[1]
    ) {
      return null
    }

    const { hours, minutes = 0 } = hoursPerWeek
    const [startDate, endDate] = period

    const floatHours = hoursAndMinutesToFloat(hours, minutes)
    const weekCount = Math.ceil(moment(endDate).diff(moment(startDate), 'week', true))

    const excludedFundingWeekCount = _.reduce(excludedPeriods, (excludedWeekCount, excludedPeriod) => {
      const [excludedStartDate, excludedEndDate] = excludedPeriod

      if (!excludedStartDate || !excludedEndDate) {
        return excludedWeekCount
      }

      return excludedWeekCount + Math.ceil(moment(excludedEndDate).diff(moment(excludedStartDate), 'week', true))
    }, 0)

    if (weekCount && floatHours) {
      const floatTotalHours = toFloat((weekCount - excludedFundingWeekCount) * floatHours)
      const totalHours = floatToHoursAndMinutes(floatTotalHours)

      return {
        hours: totalHours.hours,
        minutes: totalHours.minutes,
      }
    }

    return null
  },
)
