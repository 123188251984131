import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledStatusBox = styled.div`
  display: grid;
  grid-template-columns: 25px auto;
  grid-column-gap: 14px;
  align-items: center;
  height: 25px;
  
  .MuiSkeleton-root {
    background: ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  }
  
  ${({ margin }) => margin && `
    margin-top: 10px;
  `}
`

export const StyledStatusBoxIcon = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-weight: 600;
  font-size: 15px;
  
  ${({ background }) => background && `
    background: ${background};
  `}
`

export const StyledText = styled.div`
  align-items: center;
`
