import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withEnquiriesService } from 'services/legacy/enquiries'
import { withNurseriesService } from 'services/nurseries'
import { withRouter } from 'services/router'

import ChildDetailView from './ChildDetailView'
import { ENQUIRY_GROUPS, ENQUIRY_GROUPS_V3 } from './constants'

const NURSERY_SESSIONS_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings',
  ],
}

class ChildDetailContainer extends Component {
  constructor(props) {
    super(props)

    const { isFinanceV3Enabled } = props

    this.state = {
      groups: isFinanceV3Enabled ? ENQUIRY_GROUPS_V3 : ENQUIRY_GROUPS,
    }
  }

  componentDidMount() {
    const { nurseriesActions, nurseryOptions } = this.props

    this.fetch()

    const apiParams = { groups: NURSERY_SESSIONS_GROUPS }
    nurseriesActions.get(nurseryOptions.id, { params: apiParams })
  }

  componentWillUnmount() {
    const { enquiriesActions, nurseriesActions } = this.props

    nurseriesActions.clearSingle()
    enquiriesActions.clear()
  }

  componentDidUpdate(prevProps) {
    const { params } = this.props
    const { params: prevParams } = prevProps
    const { enquiryId } = params
    const { enquiryId: prevEnquiryId } = prevParams

    if (prevEnquiryId && enquiryId && prevEnquiryId !== enquiryId) {
      this.fetch()
    }
  }

  fetch = () => {
    const { enquiriesActions, params } = this.props
    const { enquiryId } = params
    const { groups } = this.state

    enquiriesActions.get({
      params: [enquiryId, {
        groups,
      }],
    })
  }

  render() {
    const { enquiry, errorMessages, isFetching, isFinanceV3Enabled, nursery } = this.props
    const { formattedOpeningDays } = nursery?.nurserySettings || {}

    return (
      <ChildDetailView
        enquiry={enquiry}
        errorMessages={errorMessages}
        isFetching={isFetching}
        isFinanceV3Enabled={isFinanceV3Enabled}
        openingDays={formattedOpeningDays}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  enquiriesSelectors,
  enquiriesSingleState,
  nurseriesSelectors,
  params,
}) => ({
  enquiry: enquiriesSelectors.getEnquiriesFormattedSingleDataSelector(state),
  errorMessages: appSelectors.getErrorMessages(enquiriesSingleState),
  isFetching: appSelectors.getIsFetching(enquiriesSingleState),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
  nursery: nurseriesSelectors.getNurseryData(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const enhance = compose(
  withRouter,
  withAppService,
  withEnquiriesService,
  withNurseriesService,
  connect(mapState),
)

export default enhance(ChildDetailContainer)

