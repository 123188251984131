import _ from 'lodash'

import React from 'react'
import { DefinitionList, Space } from 'components'

import i18n from 'translations'

const getAdditionalCarerList = (carerList, invoiceRecipients) => {
  if (!carerList?.length || !invoiceRecipients?.length) {
    return null
  }

  return invoiceRecipients.map((item) => ({
    ...item,
    address: item.carer.address,
    carer: { label: item.carer.fullName, value: item.carer.id },
    email: item.carer.email,
    postCode: item.carer.postCode,
  }))
}

export const getInitialValues = (childBankData, carerList, isEmailEdited) => {
  if (carerList?.length && !isEmailEdited) {
    const [carerItem] = carerList
    const { carer } = carerItem
    const { fullName, id: carerId } = carer

    return {
      carerId: { label: fullName, value: carerId },
    }
  }

  if (!childBankData) {
    return null
  }

  const { fundingLoopContact, invoiceRecipients, isCustomEmail, paymentContact, paymentEmail } = childBankData

  if (!paymentContact && !paymentEmail) {
    const [carerItem] = carerList || []
    const { carer } = carerItem || {}
    const { fullName, id: carerId } = carer || {}

    return {
      carerId: { label: fullName, value: carerId },
    }
  }

  const carerId = paymentContact?.carer?.id || null
  const carerFullName = paymentContact?.carer?.fullName || null
  const additionalCarerList = getAdditionalCarerList(carerList, invoiceRecipients)
  const fundingLoopContactOption = fundingLoopContact ? {
    label: fundingLoopContact?.carer?.fullName,
    value: fundingLoopContact?.carer?.id,
  } : undefined

  return {
    carerId: { label: carerFullName, value: carerId },
    fundingLoopContact: fundingLoopContactOption,
    invoiceRecipients: additionalCarerList,
    isCustomEmail,
    paymentEmail: isCustomEmail ? paymentEmail : null,
  }
}

export const getCarerInfo = (carerList, carerId) => _.find(carerList, ({ carer }) => carer.id === carerId)?.carer

export const carerInfo = (carerList, carerId) => {
  const item = getCarerInfo(carerList, carerId)

  if (!item) {
    return null
  }

  return (
    <React.Fragment>
      <Space space="20px" />
      <DefinitionList vertical>
        <DefinitionList.Item
          label={i18n.t('module:Children:Child:Finance:ContactDetails:addressLabel')}
          value={item?.address || '-'}
          whiteSpace="pre-wrap"
        />
        <DefinitionList.Item
          label={i18n.t('module:Children:Child:Finance:ContactDetails:postCodeLabel')}
          value={item?.postCode || '-'}
        />
        <DefinitionList.Item
          label={i18n.t('module:Children:Child:Finance:ContactDetails:emailLabel')}
          value={item?.email || '-'}
        />
      </DefinitionList>
      <Space space="20px" />
    </React.Fragment>
  )
}

export const isCustomEmailRequired = (value: string): string => {
  if (null === value || undefined === value || '' === value) {
    return i18n.t('module:Children:Child:Finance:ContactDetails:Validation:customEmailRequired')
  }

  return null
}
