import React, { Component } from 'react'

import AddModalView from './AddModalView'

class AddModalContainer extends Component {
  handleCancelClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSaveSuccess = (data) => {
    const { onSaveSuccess } = this.props

    onSaveSuccess(data)

    this.handleCancelClick()
  }

  render() {
    const { invoiceId } = this.props

    return (
      <AddModalView
        invoiceId={invoiceId}
        onCancelClick={this.handleCancelClick}
        onSaveSuccess={this.handleSaveSuccess}
      />
    )
  }
}

export default AddModalContainer
