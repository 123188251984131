import { createSelector } from 'reselect'
import moment from 'moment'

import { DAILY_DIARY_DATE_FORMAT, TIME_TYPE } from 'services/legacy/dailyDiary/constants'

import { changeHours, isPastDay } from 'utils/date'

import { getDailyDiaryNappiesListData } from 'services/legacy/dailyDiaryNappies/selectors'

import { mergeDailyDiaryRecords } from './helpers'

const getDailyDiaryToiletTraining = (state) => state.dailyDiaryToiletTraining

export const getDailyDiaryToiletTrainingList = createSelector([getDailyDiaryToiletTraining], (state) => state.list)

export const getDailyDiaryToiletTrainingListData = createSelector(
  [getDailyDiaryToiletTrainingList],
  (state) => state.data,
)

export const getDailyDiaryMergedNappiesToiletTrainingList = createSelector(
  [
    getDailyDiaryToiletTrainingListData,
    getDailyDiaryNappiesListData,
  ],
  (toiletTrainingList, nappiesList) => mergeDailyDiaryRecords(toiletTrainingList, nappiesList),
)

export const getListCriteria = (query = {}) => {
  const {
    entryDate = moment().format(DAILY_DIARY_DATE_FORMAT),
    childrenWithoutRecords,
    recordsAndPlaceholders,
    room,
    timeType,
  } = query

  const criteria = [
    {
      comparator: 'after',
      field: 'entryDate',
      value: entryDate,
    },
    {
      comparator: 'before',
      field: 'entryDate',
      value: entryDate,
    },
    {
      field: 'present',
      value: true,
    },
    {
      field: 'order[child.firstName]',
      value: 'ASC',
    },
  ]

  if (room) {
    criteria.push({
      field: 'child.nurseryClass',
      value: room,
    })
  }

  if (recordsAndPlaceholders) {
    criteria.push({
      field: 'or[0][childRegisterDiaryRecordStatistics.toiletTrainingRecordsCount][gt]',
      value: 0,
    })
  }

  if (childrenWithoutRecords) {
    criteria.push({
      field: 'childRegisterDiaryRecordStatistics.toiletTrainingRecordsCount[lt]',
      value: 1,
    })
  }

  if (timeType === TIME_TYPE.CURRENTLY_TIMED_IN && !isPastDay(entryDate)) {
    criteria.push({
      comparator: 'after',
      field: 'signIns.signedOutAt',
      value: changeHours(entryDate, moment()).toISOString(),
    })
  }

  return criteria
}

export const getDailyDiaryToiletTrainingRecordType = createSelector(
  [getDailyDiaryToiletTrainingList],
  (state) => state.data,
)

