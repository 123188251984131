import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { HA_SIGNATURE_LABEL_MAPPINGS, INJURIES, SIGNATURE_LABEL_MAPPINGS } from 'services/legacy/injuries/constants'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { Button, Typography } from 'components'

import i18n from 'translations'

import {
  StyledActionColumn,
  StyledContainer,
  StyledMainContentContainer,
  StyledSignatureContainer,
  StyledTitleContainer,
  StyledTypeContainer,
} from './InjuryListItemStyled'

const InjuryListItemContent = ({
  accidentDetails,
  date,
  description,
  injurySignatureStatistics,
  isHomeAccidents,
  to,
  type,
}) => {
  const InjuryTypeTitle = (type === INJURIES.INCIDENT
    ? i18n.t('module:Injury:Common:incidentDetails')
    : i18n.t('module:Injury:Common:accidentDetails'))

  return (
    <StyledContainer>
      <StyledMainContentContainer>
        <StyledTypeContainer>
          <div>
            {isHomeAccidents
              ? (
                <Typography color={NEUTRAL_COLOURS.GRAY} bold>
                  {i18n.t('module:Safeguarding:Fields:AccidentDetails:label')}
                </Typography>
              )
              : <Typography color={NEUTRAL_COLOURS.GRAY} bold>{InjuryTypeTitle}</Typography>}
          </div>
          <div>
            {date ? `${moment(date).format('DD/MM/YYYY')} ${moment(date).format('HH:mm')}` : '-' }
          </div>
        </StyledTypeContainer>
        <StyledTitleContainer>
          {isHomeAccidents ? accidentDetails[0]?.accidentDetails : description[0]}
        </StyledTitleContainer>
      </StyledMainContentContainer>
      <StyledSignatureContainer>
        <Typography bold>{i18n.t('module:Injury:List:itemSignatureTitle')}</Typography>
        {injurySignatureStatistics?.length
          ? _.map(injurySignatureStatistics, ({ all, key, signed }) => (
            <div key={key}>
              <Typography variant="span" bold>{`${signed}/${all}`}</Typography>
              {' '}
              {isHomeAccidents ? HA_SIGNATURE_LABEL_MAPPINGS[key] : SIGNATURE_LABEL_MAPPINGS[key]}
            </div>
          ))
          : '-'}
      </StyledSignatureContainer>
      <StyledActionColumn>
        <Button
          icon="chevron-right"
          to={to}
        />
      </StyledActionColumn>
    </StyledContainer>
  )
}

export default InjuryListItemContent
