import { UPDATE_CHILD_MOVE, UPDATE_CHILD_MOVE_FAILED, UPDATE_CHILD_MOVE_SUCCESS } from './constants'

const initialState = {
  isFetching: false,
}

export default (state = initialState, { error, type }) => {
  switch (type) {
    case UPDATE_CHILD_MOVE:
      return { ...state, isFetching: true }
    case UPDATE_CHILD_MOVE_SUCCESS:
      return { ...state, isFetching: false }
    case UPDATE_CHILD_MOVE_FAILED:
      return { ...state, error, isFetching: false }
    default:
      return state
  }
}
