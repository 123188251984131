import styled from 'styled-components'

interface StyledWrapperProps {
  $color?: string
  $fitContent?: boolean
}

export const StyledWrapper = styled.div<StyledWrapperProps>`
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  padding: 15px;

  ${({ $color }) => $color && `
    border-color: ${$color};
  `}

  ${({ $fitContent }) => $fitContent && `
    width: fit-content;
    padding-right: 30px;
  `}
`

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

export const StyledContent = styled.div`
  padding-left: 30px;
  font-size: 14px;
`
