import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { ERROR_CODES } from 'services/legacy/invoices/constants'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withModalService } from 'services/utils/modal'
import { withInvoicePaymentsService } from 'services/legacy/invoicePayments'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withSortingUtils } from 'services/utils/sorting'
import { withRouter } from 'services/router'

import { Typography } from 'components'

import i18n from 'translations'

import { getPayments } from './helpers'

import FinancePaymentsView from './FinancePaymentsView'

const PAYMENT_GROUPS = { read: ['payment.invoice', 'invoice', 'payment.child', 'child'] }
const DATE_FORMAT = 'YYYY-MM-DD'
const DEFAULT_SORT = { sortField: 'paymentDate', sortOrder: 'DESC' }

class FinancePaymentsContainer extends Component {
  constructor(props) {
    super(props)

    const {
      location: { query },
      paginationUtils: { setPageLocationQuery },
      params: { childId },
    } = props
    const {
      endDate,
      paymentType,
      startDate,
    } = query

    this.state = {
      filters: {
        endDate,
        paymentType,
        room: null,
        searchPhrase: null,
        startDate,
      },
      isChildContext: !!childId,
    }

    setPageLocationQuery(false)

    this.handleSearchPhraseChange = _.debounce(this.handleSearchPhraseChange, 300)
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { invoicePaymentsActions } = this.props

    invoicePaymentsActions.clear()
  }

  fetch = () => {
    const {
      invoicePaymentsActions,
      invoicePaymentsSelectors,
      paginationUtils,
      params: { childId },
      setLocationQuery,
      sortingUtils,
    } = this.props
    const { filters } = this.state

    const { page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils
    const { getCriteriaSelector } = invoicePaymentsSelectors

    setLocationQuery({
      endDate: filters.endDate,
      paymentType: filters.paymentType,
      startDate: filters.startDate,
    })

    const criteria = getCriteriaSelector({
      ...filters,
      child: childId,
    })

    const params = {
      criteria,
      groups: PAYMENT_GROUPS,
      order: {
        sortField: sortField || DEFAULT_SORT.sortField,
        sortOrder: sortOrder || DEFAULT_SORT.sortOrder,
      },
      page,
    }

    invoicePaymentsActions.list(params)
  }

  handlePageChange = (page) => {
    const { paginationUtils: { onPageChange } } = this.props

    onPageChange(this.fetch)(page)
  }

  handleSortChange = (key) => {
    const {
      paginationUtils: { onPageChange },
      sortingUtils: { onSortChange },
    } = this.props

    onSortChange(onPageChange(this.fetch)(1))(key)
  }

  handleDateRangeChange = (dateRange) => {
    const {
      paginationUtils: { onPageChange },
      sortingUtils: { onSortChange },
    } = this.props

    const [startDate, endDate] = dateRange

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          endDate: endDate ? moment(endDate).format(DATE_FORMAT) : undefined,
          startDate: startDate ? moment(startDate).format(DATE_FORMAT) : undefined,
        },
      }),
      () => {
        if ((startDate && endDate) || (!startDate && !endDate)) {
          onSortChange(onPageChange(this.fetch)(1))
        }
      },
    )
  }

  handlePaymentTypeChange = (paymentType) => {
    const {
      paginationUtils: { onPageChange },
      sortingUtils: { onSortChange },
    } = this.props

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          paymentType: paymentType?.value,
        },
      }),
      () => onSortChange(onPageChange(this.fetch)(1)),
    )
  }

  handleRoomChange = (room) => {
    const {
      paginationUtils: { onPageChange },
      sortingUtils: { onSortChange },
    } = this.props

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, room },
      }),
      () => onSortChange(onPageChange(this.fetch)(1)),
    )
  }

  handleSearchPhraseChange = (searchPhrase) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, searchPhrase },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handleExportModalClick = (body, onSuccessCb) => {
    const { invoicePaymentsActions, invoicePaymentsSelectors } = this.props
    const { filters } = this.state
    const { criteria } = body

    const userCriteria = _.find(criteria, { field: 'membership' })
    const filterCriteria = invoicePaymentsSelectors.getCriteriaSelector(filters)

    const apiParams = {
      criteria: [
        ...[userCriteria],
        ...filterCriteria,
      ],
    }

    invoicePaymentsActions.export(apiParams, onSuccessCb)
  }

  handleExportReport = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.EXPORT_REPORT, {
      onSendReport: this.handleExportModalClick,
      title: i18n.t('module:Finance:Payments:Export:title'),
      userLabel: i18n.t('module:Modals:ExportReportModal:User:Label:export'),
    })
  }

  sendPayment = (paymentId) => {
    const { invoicePaymentsActions } = this.props

    invoicePaymentsActions.send(paymentId, this.handleSendPaymentSuccess, this.handleSendPaymentFailure)
  }

  removePayment = (paymentId) => {
    const { invoicePaymentsActions } = this.props

    invoicePaymentsActions.remove(paymentId, this.handleRemovePaymentSuccess, this.handleRemovePaymentFailure)
  }

  downloadPayment = (paymentId) => {
    const { invoicePaymentsActions } = this.props

    invoicePaymentsActions.download(paymentId)
  }

  handleSendPaymentSuccess = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.ALERT, {
      text: i18n.t('module:Finance:Payments:Resend:successAlert'),
    })
  }

  handleSendPaymentFailure = (error) => {
    const { modalActions, modalConsts } = this.props
    const { code, message } = error || {}

    let text = message || i18n.t('module:Finance:Payments:Resend:failureAlert')

    if (ERROR_CODES.MISSING_PAYMENT_CONTACT === code) {
      text = (
        <React.Fragment>
          <Typography fontSize={18}>
            {i18n.t('module:Finance:Payments:Resend:noRecipients_1')}
          </Typography>
          <Typography fontSize={17}>
            {i18n.t('module:Finance:Payments:Resend:noRecipients_2')}
          </Typography>
        </React.Fragment>
      )
    }

    modalActions.show(modalConsts.TYPES.ALERT, {
      icon: 'warning',
      text,
    })
  }

  handleRemovePaymentSuccess = () => {
    const { modalActions, modalConsts, paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(1)
    modalActions.show(modalConsts.TYPES.ALERT, {
      text: i18n.t('module:Finance:Payments:Remove:successAlert'),
    })
  }

  handleRemovePaymentFailure = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.ALERT, {
      text: i18n.t('module:Finance:Payments:Remove:failureAlert'),
    })
  }

  handlePaymentSent = (paymentId) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'resend-invoice',
      onConfirm: () => this.sendPayment(paymentId),
      text: i18n.t('module:Finance:Payments:Resend:confirm'),
    })
  }

  handlePaymentRemove = (paymentId) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'trash',
      onConfirm: () => this.removePayment(paymentId),
      text: i18n.t('module:Finance:Payments:Remove:confirm'),
    })
  }

  getPayments = () => {
    const { invoicePaymentsListState } = this.props

    const getParams = {
      onPaymentDownload: this.downloadPayment,
      onPaymentRemove: this.handlePaymentRemove,
      onPaymentSend: this.handlePaymentSent,
    }

    return getPayments(invoicePaymentsListState.data, getParams)
  }

  handleImportClick = () => {
    const { navigate } = this.props

    navigate(generateRoute('FINANCE.PAYMENTS.IMPORT'))
  }

  render() {
    const {
      errorMessages,
      invoicePaymentsCount,
      invoicePaymentsListState,
      isFetching,
      paginationUtils,
      params: { childId },
      sortingUtils,
    } = this.props
    const { filters, isChildContext } = this.state
    const { endDate, paymentType, room, startDate } = filters
    const { getPageCount, page, perPage } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const isLoading = isFetching
    const pageCount = getPageCount(invoicePaymentsListState.meta.total_results)
    const dateRange = [startDate, endDate]
    const isEmpty = !isFetching && !invoicePaymentsCount

    return (
      <FinancePaymentsView
        addPaymentRoute={isChildContext
          ? generateRoute('CHILDREN.CHILD.FINANCE.PAYMENT_HISTORY.ADD_PAYMENT', { childId })
          : generateRoute('FINANCE.PAYMENTS.ADD')}
        dateRange={dateRange}
        errorMessages={errorMessages}
        isChildContext={isChildContext}
        isEmpty={isEmpty}
        isLoading={isLoading}
        page={page}
        pageCount={pageCount}
        paymentType={paymentType}
        payments={this.getPayments()}
        perPage={perPage}
        room={room}
        sortField={sortField}
        sortOrder={sortOrder}
        totalResults={invoicePaymentsListState.meta.total_results}
        onDateRangeChange={this.handleDateRangeChange}
        onExportClick={this.handleExportReport}
        onImportClick={this.handleImportClick}
        onPageChange={this.handlePageChange}
        onPaymentTypeChange={this.handlePaymentTypeChange}
        onRoomChange={this.handleRoomChange}
        onSearchPhraseChange={this.handleSearchPhraseChange}
        onSortChange={this.handleSortChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  invoicePaymentsListState,
  invoicePaymentsSelectors,
}) => ({
  errorMessages: appSelectors.getErrorMessages(invoicePaymentsListState),
  invoicePaymentsCount: invoicePaymentsSelectors.getPaymentsListCountSelectors(state),
  isFetching: appSelectors.getIsFetching(invoicePaymentsListState),
})

const enhance = compose(
  withAppService,
  withChildService,
  withRouter,
  withModalService,
  withInvoicePaymentsService,
  withPaginationUtils,
  withRouterUtils,
  withSortingUtils,
  connect(mapState),
)

export default enhance(FinancePaymentsContainer)
