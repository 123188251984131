import {
  CLEAR_OCCUPANCY_REPORT,
  GET_OCCUPANCY_REPORT,
  GET_OCCUPANCY_REPORT_FAILED,
  GET_OCCUPANCY_REPORT_SUCCESS,
} from './constants'

export const report = (params, forWeek, onDone = () => {}) => ({ occupancyApiClient }) => (dispatch) => {
  dispatch({
    type: GET_OCCUPANCY_REPORT,
  })

  occupancyApiClient.report(params).then(
    (response) => {
      dispatch({
        payload: { forWeek, params, response },
        type: GET_OCCUPANCY_REPORT_SUCCESS,
      })

      onDone(response)
    },
    ({ response }) => {
      dispatch({
        payload: { forWeek, params, response },
        type: GET_OCCUPANCY_REPORT_FAILED,
      })
      onDone(response)
    },
  )
}

export const clear = () => ({
  type: CLEAR_OCCUPANCY_REPORT,
})
