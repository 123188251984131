export const OCCUPANCY_REQUEST_TYPE = {
  MONTHLY: 'MONTHLY',
  OVERVIEW: 'OVERVIEW',
  WEEKLY: 'WEEKLY',
  YEARLY: 'YEARLY',
}

export const OCCUPANCY_TYPE_DROPDOWN = [
  {
    label: 'Weekly',
    value: OCCUPANCY_REQUEST_TYPE.WEEKLY,
  },
  {
    label: 'Monthly',
    value: OCCUPANCY_REQUEST_TYPE.MONTHLY,
  },
  {
    label: 'Yearly',
    value: OCCUPANCY_REQUEST_TYPE.YEARLY,
  },
  {
    label: 'Overview planner',
    value: OCCUPANCY_REQUEST_TYPE.OVERVIEW,
  },
]

export const OCCUPANCY_REQUEST_BREAKDOWN = {
  AM_PM: 'AM_PM',
  SESSIONS: 'SESSION',
}

export const OCCUPANCY_REQUEST_BREAKDOWN_DROPDOWN = [
  {
    label: 'AM/PM breakdown',
    value: OCCUPANCY_REQUEST_BREAKDOWN.AM_PM,
  },
  {
    label: 'Session breakdown',
    value: OCCUPANCY_REQUEST_BREAKDOWN.SESSIONS,
  },
]
