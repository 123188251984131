import _ from 'lodash'

import React from 'react'

import { MONTESSORI_AREA } from 'services/legacy/nextSteps/constants'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { Button, Typography, XMasIcon } from 'components'
import Widget from 'module/Dashboard/components/Widget'

import SeeMoreListItem from '../../components/SeeMoreListItem'

import { StyledArea, StyledAreaContainer, StyledEmptyState, StyledSquare, StyledWrapper } from './NextStepsWidgetStyled'

const NextStepsWidgetView = ({
  isLoading,
  nextSteps,
}) => {
  const renderArea = ({ colour, shortName }) => (
    <StyledArea>
      <StyledSquare
        color={colour}
      />
      <Typography
        color={NEUTRAL_COLOURS.WHITE}
        fontSize={13}
        bold
      >
        {shortName}
      </Typography>
    </StyledArea>
  )

  const renderNextStepAreas = (nextStep) => {
    const { frameworkAreas, montessoriActivity } = nextStep
    let areas = []

    if (!montessoriActivity) {
      areas = frameworkAreas
    } else {
      areas = [MONTESSORI_AREA]
    }

    return (
      <StyledAreaContainer>
        {_.map(areas, renderArea)}
      </StyledAreaContainer>
    )
  }

  const renderListItem = (nextStep, index) => {
    const {
      childObservation,
      comments,
      id,
    } = nextStep
    const { child, observation } = childObservation
    const { id: observationId } = observation

    const to = generateRoute('LEARNING.OBSERVATIONS.REVIEW', { observationId })

    return (
      <Widget.List.Row
        childList={[child]}
        index={index}
        key={id}
        leftColumn={(
          <React.Fragment>
            <Typography
              color={NEUTRAL_COLOURS.WHITE}
              bold
              ellipsis
            >
              {child.name}
            </Typography>
            {renderNextStepAreas(nextStep)}
            <Typography
              color={NEUTRAL_COLOURS.WHITE}
              ellipsis
            >
              {comments}
            </Typography>
          </React.Fragment>
        )}
        to={to}
      />
    )
  }

  const renderContent = () => {
    const to = generateRoute('LEARNING.NEXT_STEPS')

    if (!nextSteps || !nextSteps.length) {
      return (
        <StyledEmptyState>
          <Typography bold>
            Make an observation and set next steps for your key children.
          </Typography>
          <Button
            icon="plus"
            label="Make observation"
            to={generateRoute('LEARNING.OBSERVATIONS.ADD')}
            fullWidth
          />
        </StyledEmptyState>
      )
    }

    return (
      <StyledWrapper>
        <Widget.List>
          {_.map(nextSteps, renderListItem)}
          <Widget.List.Row>
            <SeeMoreListItem to={to} />
          </Widget.List.Row>
        </Widget.List>
      </StyledWrapper>
    )
  }

  return (
    <Widget>
      <Widget.Header>
        <XMasIcon height="27px" icon="circle-variant-1" marginLeft="-254px" marginTop="4px" width="25px" />
        Next steps
      </Widget.Header>
      <Widget.Content
        id="nextStepsScrollTarget"
        isLoading={isLoading}
      >
        {renderContent()}
      </Widget.Content>
    </Widget>
  )
}

export default NextStepsWidgetView
