import moment from 'moment'

import { createSelector } from 'reselect'

import { LIST_STATUS_FILTERS } from 'constants/filters'
import { STATUS_TYPES } from 'services/legacy/invoices/constants'

import { ChildProductsRootState } from '../constants'

export const getChildProductsSelector = (state: ChildProductsRootState) => state.childProducts

export const getChildProductsListSelector = createSelector(
  [getChildProductsSelector],
  (state) => state.list.data,
)

export const getCriteria = (filters) => {
  const { childId, dateRange, invoiceStatus, invoiced, search, status, type } = filters || {}

  const criteria = []

  if (childId) {
    criteria.push({
      field: 'child',
      value: childId,
    })
  }

  if (search) {
    criteria.push({
      field: 'concatenable',
      value: search,
    })
  }

  if (dateRange && dateRange[0] && dateRange[1]) {
    criteria.push({
      field: 'date[after]',
      value: moment(dateRange[0]).format('YYYY-MM-DD'),
    })

    criteria.push({
      field: 'date[before]',
      value: moment(dateRange[1]).format('YYYY-MM-DD'),
    })
  }

  if (type) {
    criteria.push({
      field: 'type',
      value: type,
    })
  }

  if (status && status !== LIST_STATUS_FILTERS.ALL) {
    criteria.push({
      field: 'archived',
      value: status === LIST_STATUS_FILTERS.ARCHIVED,
    })
  }

  if (invoiceStatus && invoiceStatus !== STATUS_TYPES.ALL) {
    criteria.push({
      field: 'invoiceItems.invoice.status',
      value: invoiceStatus,
    })
  }

  if (undefined !== invoiced && null !== invoiced) {
    criteria.push({
      field: 'exists[invoiceItems]',
      value: invoiced,
    })
  }

  return criteria
}
