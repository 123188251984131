import { getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_ENQUIRIES_SINGLE,
  CREATE_ENQUIRIES,
  CREATE_ENQUIRIES_FAILED,
  CREATE_ENQUIRIES_SUCCESS,
  GET_ENQUIRIES,
  GET_ENQUIRIES_FAILED,
  GET_ENQUIRIES_SUCCESS,
  UPDATE_ENQUIRIES,
  UPDATE_ENQUIRIES_FAILED,
  UPDATE_ENQUIRIES_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_ENQUIRIES_SINGLE,
    createFailed: CREATE_ENQUIRIES_FAILED,
    createInit: CREATE_ENQUIRIES,
    createSuccess: CREATE_ENQUIRIES_SUCCESS,
    itemFailed: GET_ENQUIRIES_FAILED,
    itemInit: GET_ENQUIRIES,
    itemSuccess: GET_ENQUIRIES_SUCCESS,
    updateFailed: UPDATE_ENQUIRIES_FAILED,
    updateInit: UPDATE_ENQUIRIES,
    updateSuccess: UPDATE_ENQUIRIES_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
