import { BasicSingleInitialState, getBasicSingleInitialState, getBasicSingleReducers } from 'utils/reducer'

import {
  CLEAR_NURSERY_CONSUMABLE,
  CREATE_NURSERY_CONSUMABLE,
  CREATE_NURSERY_CONSUMABLE_FAILED,
  CREATE_NURSERY_CONSUMABLE_SUCCESS,
  GET_NURSERY_CONSUMABLE,
  GET_NURSERY_CONSUMABLE_FAILED,
  GET_NURSERY_CONSUMABLE_SUCCESS,
  UPDATE_NURSERY_CONSUMABLE,
  UPDATE_NURSERY_CONSUMABLE_FAILED,
  UPDATE_NURSERY_CONSUMABLE_SUCCESS,
} from './constants'

const initialState = getBasicSingleInitialState

export interface ChildProductsSingleState extends BasicSingleInitialState {}

export default (...props) => {
  const [state = initialState] = props

  const basicSingleReducer = getBasicSingleReducers({
    clear: CLEAR_NURSERY_CONSUMABLE,
    createFailed: CREATE_NURSERY_CONSUMABLE_FAILED,
    createInit: CREATE_NURSERY_CONSUMABLE,
    createSuccess: CREATE_NURSERY_CONSUMABLE_SUCCESS,
    itemFailed: GET_NURSERY_CONSUMABLE_FAILED,
    itemInit: GET_NURSERY_CONSUMABLE,
    itemSuccess: GET_NURSERY_CONSUMABLE_SUCCESS,
    updateFailed: UPDATE_NURSERY_CONSUMABLE_FAILED,
    updateInit: UPDATE_NURSERY_CONSUMABLE,
    updateSuccess: UPDATE_NURSERY_CONSUMABLE_SUCCESS,
  }, initialState, props)

  if (null !== basicSingleReducer) {
    return basicSingleReducer
  }

  return state
}
