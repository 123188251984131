import _ from 'lodash'
import moment from 'moment'

import { Option } from 'constants/models'
import { Ranges } from 'components/MultiRangeCalendar/MultiRangeCalendar'
import { PeriodTime } from 'services/periodTimes/models'

const DEFAULT_START_MONTH = 9

export const getYearOptions = (): Option[] => {
  const startYear = 2015
  const currentYear = new Date().getFullYear() + 5
  const years = []

  for (let i = startYear; i <= currentYear; i++) { // eslint-disable-line
    years.push({
      label: i,
      value: i,
    })
  }

  return years
}

export const getMonthOptions = (): Option[] => [...Array(12)].map((test, index) => ({
  label: moment().set('month', index).format('MMMM'),
  value: moment().set('month', index).month(),
}))

export const convertData = (data: PeriodTime[]): Ranges[] => {
  if (!data?.length) {
    return null
  }

  return _.map(data, ({ endDate, startDate }) => ({ from: startDate, to: endDate }))
}

export const getErrorsFromResponse = (data) => _.join(_.map(data, ({ exception }) => {
  const { error } = exception
  const { extra } = error
  const { periodTime } = extra

  return periodTime
}), '\n')

export const getDefaultStartDate = () => {
  const defaultDate = moment()

  if ((moment().month() + 1) >= DEFAULT_START_MONTH) {
    defaultDate.set('year', moment().year())
  } else {
    defaultDate.set('year', moment().year() + 1)
  }

  defaultDate.set('month', 7)
  defaultDate.set('date', 1)

  return defaultDate.utc()
}
