import _ from 'lodash'

import React from 'react'
import { Link } from 'react-router'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { FILTERS_MAP } from 'services/legacy/cohortTracking/constants'

import { generateRoute } from 'utils/routing'

import { CircleIcon, DataTable, InfiniteScroll, Tooltip, Typography } from 'components'

import i18n from 'translations'

import { StyledAreaPlaceholder, StyledName } from './TrackerTableStyled'

const GROUP_BY = {
  AREA: 'area',
}

const TrackerTable = ({
  expanded,
  frameworkDetails,
  isFetching,
  onChangePage,
  page,
  pageCount,
  records,
}) => {
  const { structure } = frameworkDetails
  const { observationLinks } = structure || {}

  const renderAgeMonths = (isHeader) => (
    <DataTable.Column noBorder>
      {observationLinks.assessmentTrackerGroupBy !== GROUP_BY.AREA && (
        <DataTable.Row
          background={NEUTRAL_COLOURS.WHITE}
          border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
          height="50"
        />
      )}
      <DataTable.Row
        background={NEUTRAL_COLOURS.WHITE}
        border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
        height="50"
        width="90"
        column
      >
        <Typography fontSize={13} bold>
          {i18n.t('module:Learning:ObservationTracker:ageMonths')}
        </Typography>
      </DataTable.Row>
      {!isHeader && _.map(records, ({ child: { ageMonths, id } }) => (
        <DataTable.Row
          background={NEUTRAL_COLOURS.GRAY_QUATERNARY}
          border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
          height="50"
          key={`ageMonths_${id}`}
        >
          <Typography color={NEUTRAL_COLOURS.BASIC} fontSize={16}>
            {ageMonths}
          </Typography>
        </DataTable.Row>
      ))}
    </DataTable.Column>
  )

  const renderTotalObservations = (isHeader) => (
    <DataTable.Column noBorder>
      {observationLinks.assessmentTrackerGroupBy !== GROUP_BY.AREA && (
        <DataTable.Row
          background={NEUTRAL_COLOURS.WHITE}
          border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
          height="50"
        />
      )}
      <DataTable.Row
        background={NEUTRAL_COLOURS.WHITE}
        border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
        height="50"
        width="105"
        column
      >
        <Typography fontSize={13} bold>
          {i18n.t('module:Learning:ObservationTracker:totalObservations')}
        </Typography>
      </DataTable.Row>
      {!isHeader && _.map(records, ({ child: { id }, observationCount }) => (
        <DataTable.Row
          background={NEUTRAL_COLOURS.GRAY_QUATERNARY}
          border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
          height="50"
          key={`totalObservations_${id}`}
        >
          <Typography color={NEUTRAL_COLOURS.BASIC} fontSize={16}>
            {observationCount}
          </Typography>
        </DataTable.Row>
      ))}
    </DataTable.Column>
  )

  const renderRow = (key, value) => (
    <DataTable.Row
      background={NEUTRAL_COLOURS.GRAY_QUATERNARY}
      border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
      height="50"
      key={key}
      width="90"
    >
      <Typography
        color={!value ? NEUTRAL_COLOURS.GRAY_SECONDARY : NEUTRAL_COLOURS.BASIC}
        fontSize={15}
        bold
      >
        {value}
      </Typography>
    </DataTable.Row>
  )

  const renderAreasAndGroups = (isHeader) => {
    const { areas } = frameworkDetails

    if (observationLinks.assessmentTrackerGroupBy === GROUP_BY.AREA) {
      return _.map(areas, ({ colour, id: areaId, name, shortName }) => (
        <DataTable.Column key={`header_${areaId.id}`} noBorder>
          <DataTable.Row
            background={NEUTRAL_COLOURS.GRAY_QUATERNARY}
            border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
            height="50"
            width="90"
          >
            <Tooltip title={name}>
              <Typography color={colour} fontSize={15} bold>
                {shortName || name}
              </Typography>
            </Tooltip>
          </DataTable.Row>
          {!isHeader && _.map(records, ({ assessments, child: { id: childId } }) => {
            let value = 0

            const item = _.find(assessments, ({ type }) => type?.id === areaId)

            if (item) {
              value = item.count
            }

            return renderRow(`record_${areaId}_${childId}`, value)
          })}
        </DataTable.Column>
      ))
    }

    return _.map(areas, ({ colour, groups, name, shortName }) => (
      _.map(groups, (group, index) => (
        <DataTable.Column key={`header_${group.id}`} noBorder>
          <DataTable.Row
            background={NEUTRAL_COLOURS.GRAY_QUATERNARY}
            border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
            height="50"
            style={{ position: 'relative' }}
            width="90"
          >
            {!index ? (
              <StyledAreaPlaceholder length={groups.length}>
                <Tooltip title={name}>
                  <Typography color={colour} fontSize={15} bold>
                    {shortName || name}
                  </Typography>
                </Tooltip>
              </StyledAreaPlaceholder>
            ) : null}
          </DataTable.Row>
          <DataTable.Row
            background={NEUTRAL_COLOURS.GRAY_QUATERNARY}
            border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
            height="50"
            padding="0 10px"
            width="90"
            column
          >
            <Tooltip title={group.name}>
              <Typography color={colour} fontSize={15} bold>
                {group.acronym}
              </Typography>
            </Tooltip>
          </DataTable.Row>
          {!isHeader && _.map(records, ({ assessments, child: { id: childId } }) => {
            let value = 0

            const item = _.find(assessments, ({ type }) => type?.id === group.id)

            if (item) {
              value = item.count
            }

            return renderRow(`record_${group.id}_${childId}`, value)
          })}
        </DataTable.Column>
      ))
    ))
  }

  const renderChildrenCells = () => _.map(records, ({ child }) => {
    const { id, name } = child
    const childFilters = _.map(
      _.filter(FILTERS_MAP, (filter) => child[filter.value] && 'No' !== child[filter.value]),
      (filter) => filter.label,
    )

    return (
      <DataTable.Row
        background={NEUTRAL_COLOURS.GRAY_QUATERNARY}
        border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
        height="50"
        key={id}
      >
        <Link to={generateRoute('CHILDREN.CHILD.ABOUT', { childId: id })}>
          <StyledName>
            {childFilters?.length ? (
              <Tooltip
                title={
                  _.map(childFilters, (text) => (
                    <Typography key={text}>
                      &bull;
                      {' '}
                      {text}
                    </Typography>
                  ))
                }
              >
                <CircleIcon
                  cursor="pointer"
                  icon="info"
                  iconSize={12}
                  size={20}
                />
              </Tooltip>
            ) : <div />}
            <Typography color={NEUTRAL_COLOURS.BASIC} fontSize={15} bold>
              {name}
            </Typography>
          </StyledName>
        </Link>
      </DataTable.Row>
    )
  })

  const renderTable = () => (
    <DataTable>
      <DataTable.Head>
        {observationLinks.assessmentTrackerGroupBy !== GROUP_BY.AREA && (
          <DataTable.Row
            background={NEUTRAL_COLOURS.WHITE}
            border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
            height="50"
            stickyElement
          />
        )}
        <DataTable.Row
          background={NEUTRAL_COLOURS.WHITE}
          border={`2px solid ${NEUTRAL_COLOURS.WHITE}`}
          height="50"
          stickyElement
        >
          <Typography fontSize={13} bold>
            {i18n.t('module:Learning:ObservationTracker:name')}
          </Typography>
        </DataTable.Row>
        {renderChildrenCells()}
      </DataTable.Head>
      <DataTable.ContentScrollable
        data={[{
          data: [
            renderAgeMonths(),
            ...renderAreasAndGroups(),
            renderTotalObservations(),
          ],
          stickyHeader: () => [[
            renderAgeMonths(true),
            ...renderAreasAndGroups(true),
            renderTotalObservations(true),
          ]],
        }]}
        enableCustomScrollbar={!expanded}
        hideSeparator
      />
    </DataTable>
  )

  return (
    <InfiniteScroll
      dataLength={records?.length || 0}
      hasMore={page < pageCount}
      isFetching={isFetching}
      next={() => onChangePage((+page) + 1)}
    >
      {renderTable()}
    </InfiniteScroll>
  )
}

export default TrackerTable
