import React from 'react'

import { Field } from 'redux-form'

import { STAFF_ENTRY_MAPPINGS } from 'services/legacy/membershipRegisters/constants'

import { isSameDay, millisecondsToHoursAndMinutesString } from 'utils/date'
import { isRequired } from 'utils/fieldValidation'

import { Button, DropdownMenu, Form, Space, Table } from 'components'
import { ShiftLabel } from 'module/Staff/components'

import i18n from 'translations'

import { StyledShiftLabel } from './StaffWorklogPreviewFormStyled'

const StaffWorklogPreviewFormEditRow = ({
  actions,
  fieldName,
  formEntries,
  index,
  onOngoingEntryEndClick,
  onUpdateEntryTotalTime,
}) => {
  const entry = formEntries[index] || {}

  const { signInAt, signOutAt, totalTime, type } = entry
  const isSameDayEntry = isSameDay(signInAt, signOutAt)
  const { label } = STAFF_ENTRY_MAPPINGS[type] || {}

  return (
    <Table.Tr>
      <Table.Td align="left">
        <StyledShiftLabel>
          <ShiftLabel type={type}>
            {label}
          </ShiftLabel>
        </StyledShiftLabel>
      </Table.Td>
      <Table.Td align="left">
        <Field
          clearable={false}
          component={Form.TimePicker}
          name={`${fieldName}.signInAt`}
          validate={isRequired}
          onChange={(value) => onUpdateEntryTotalTime(index, value, signOutAt)}
        />
      </Table.Td>
      <Table.Td align="left">
        {signOutAt
          ? (
            <Field
              clearable={false}
              component={Form.TimePicker}
              name={`${fieldName}.signOutAt`}
              validate={isRequired}
              onChange={(value) => onUpdateEntryTotalTime(index, signInAt, value)}
            />
          )
          : (
            <Button
              hierarchy="secondary"
              label={i18n.t('global:End')}
              size="small"
              negativeMargins
              onClick={() => onOngoingEntryEndClick(index)}
            />
          )}
        {signOutAt && !isSameDayEntry && (
          <React.Fragment>
            <Space space="10px" />
            <Field
              component={Form.DatePicker}
              name={`${fieldName}.signOutAt`}
              validate={isRequired}
            />
          </React.Fragment>
        )}
      </Table.Td>
      <Table.Td align="left">
        {millisecondsToHoursAndMinutesString(totalTime)}
      </Table.Td>
      <Table.Td align="left">
        <Field
          component={Form.InfiniteDropdowns.Rooms}
          name={`${fieldName}.nurseryClass`}
          allRoomTypes
        />
      </Table.Td>
      <Table.Td>
        <DropdownMenu small>
          <DropdownMenu.Item
            type="delete"
            onClick={() => actions.remove(index)}
          />
        </DropdownMenu>
      </Table.Td>
    </Table.Tr>
  )
}

export default StaffWorklogPreviewFormEditRow
