import i18n from 'translations'

export const FRAMEWORK_TYPE_EYFS = 'EYFS'

export const FRAMEWORK_TYPE_MONTESSORI = 'MONTESSORI'

export const ALL_OPTION = {
  label: i18n.t('global:All'),
  value: 'all',
}

export const STARTING_POINT_STATUS = {
  STARTING_POINT: {
    abbreviation: 'SP',
    colour: '#36A9E0',
    label: i18n.t('services:Frameworks:Constants:itemOptions:startingPoint'),
    value: 'startingPoint',
  },
}

export const EMERGING_STATUS = {
  EMERGING: {
    abbreviation: 'E',
    colour: '#E94190',
    label: i18n.t('services:Frameworks:Constants:itemOptions:emerging'),
    value: 'emerging',
  },
}

export const DEVELOPING_STATUS = {
  DEVELOPING: {
    abbreviation: 'D',
    colour: '#ec672f',
    label: i18n.t('services:Frameworks:Constants:itemOptions:developing'),
    value: 'developing',
  },
}

export const SECURE_STATUS = {
  SECURE: {
    abbreviation: 'S',
    colour: '#008798',
    label: i18n.t('services:Frameworks:Constants:itemOptions:secure'),
    value: 'secure',
  },
}

export const OBSERVATION_STATES = {
  ...EMERGING_STATUS,
  ...DEVELOPING_STATUS,
  ...SECURE_STATUS,
}

export const NONE_VALUE = 'none'

export const FRAMEWORK_PROGRESS_STATES = {
  ...STARTING_POINT_STATUS,
  ...OBSERVATION_STATES,
}

export const FRAMEWORK_STRUCTURE_APPLICABLE = {
  COHORT_TRACKING: 'cohortTracking',
  FORMATIVE_REPORT: 'formativeReport',
  FRAMEWORK: 'framework',
  NEXT_STEPS: 'nextSteps',
  OBSERVATION: 'observation',
  OBSERVATION_TRACKER: 'observationTracker',
  PARENT_APP: 'parentApp',
}

export const FRAMEWORK_GROUPED_BY = {
  AREA: 'area',
  GROUP: 'group',
  ITEM: 'item',
}
