import React from 'react'

import { Callout, Page, Section, Spinner } from 'components'

import ManagementConsumablesV2AddForm from './components/ManagementConsumablesV2AddForm'

const ManagementConsumablesV2AddView = ({
  errorMessages,
  initialValues,
  isArchived,
  isEdit,
  isFormLoading,
  isLoading,
  nurseryOptions,
  onArchiveClick,
  onSubmit,
}) => {
  const renderSpinner = () => (
    <Spinner />
  )

  const renderContent = () => {
    if (isLoading) {
      return renderSpinner()
    }

    if (isEdit && !initialValues) {
      return renderSpinner()
    }

    return (
      <ManagementConsumablesV2AddForm
        initialValues={initialValues}
        isLoading={isFormLoading}
        nurseryOptions={nurseryOptions}
        onSubmit={onSubmit}
      />
    )
  }

  const title = isEdit ? 'Edit a consumable' : 'Add a consumable'

  const actions = isEdit && !isLoading && (
    <Section.Actions
      options={[{
        onClick: onArchiveClick(!isArchived),
        type: isArchived ? 'unarchive' : 'archive',
      }]}
    />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isLoading}
      title={title}
    >
      <Callout content={errorMessages} error />
      {renderContent()}
    </Page.Section>
  )
}

export default ManagementConsumablesV2AddView
