import * as listActions from './list/actions'
import * as singleActions from './single/actions'
import * as dropdownListActions from './dropdownList/actions'
import * as batchActions from './batch/actions'

export default {
  ...listActions,
  ...singleActions,
  ...dropdownListActions,
  ...batchActions,
}
