import { compose } from 'recompose'
import { Component } from 'react'

import { ROLES } from 'constants/security'

import { generateRoute } from 'utils/routing'

import { withShellService } from 'services/shell'
import { withRouter } from 'services/router'

class ChildLearningJourneyContainer extends Component {
  constructor(props) {
    super(props)

    this.indexRedirect(props)
  }

  componentDidMount() {
    const {
      params: { childId },
      shellActions,
    } = this.props

    shellActions.setSubMenu([
      {
        icon: 'next-steps',
        label: 'Next steps',
        to: `/children/${childId}/learning-journey/next-steps`,
      },
      {
        icon: 'framework',
        label: 'Framework',
        to: `/children/${childId}/learning-journey/framework`,
      },
      {
        icon: 'observations',
        label: 'Observations',
        to: `/children/${childId}/learning-journey/observations`,
      },
      {
        icon: 'formative-reports',
        label: 'Formative Reports',
        to: `/children/${childId}/learning-journey/formative-reports`,
      },
    ])
  }

  shouldComponentUpdate(nextProps) {
    this.indexRedirect(nextProps)

    return true
  }

  componentWillUnmount() {
    const { shellActions } = this.props

    shellActions.clearSubMenu()
  }

  indexRedirect = (props) => {
    const { location: { pathname }, navigate, params: { childId } } = props

    if (generateRoute('CHILDREN.CHILD.LEARNING_JOURNEY', { childId }) === pathname) {
      navigate(generateRoute('CHILDREN.CHILD.LEARNING_JOURNEY.NEXT_STEPS', { childId }))
    }
  }

  render() {
    const { children } = this.props

    return children
  }
}

ChildLearningJourneyContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.ROOM_LEADER,
    ROLES.SENIOR_TEACHER,
    ROLES.TEACHER,
  ],
}

const enhance = compose(
  withRouter,
  withShellService,
)

export default enhance(ChildLearningJourneyContainer)
