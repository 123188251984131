import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

export const StyledSummaryOutstanding = styled.div`
  background: ${getBrandingColor('primary-color')};
  padding: 0 10px 15px;
  margin: -10px -10px 0;
  font-size: 28px;
  text-align: center;
`

export const StyledTitle = styled.div`
  padding: 10px;
  background: ${getBrandingColor('senary-color')};
  margin-bottom: 10px;
  text-align: center;
`
