import { Property } from 'csstype'
import styled from 'styled-components'

import layout from 'constants/layout'

interface GridItemProps {
  $alignItems?: {
    desktop?: Property.AlignItems
    mobile?: Property.AlignItems
    tablet?: Property.AlignItems
  }
  $justifyContent?: {
    desktop?: Property.JustifyContent
    mobile?: Property.JustifyContent
    tablet?: Property.JustifyContent
  }
  $maxWidth?: {
    desktop?: string
    mobile?: string
    tablet?: string
  }
  $textAlign?: {
    desktop?: Property.TextAlign
    mobile?: Property.TextAlign
    tablet?: Property.TextAlign
  }
  $width?: {
    desktop?: number | string
    mobile?: number | string
    tablet?: number | string
  }
}

export const getMaxWidth = (width) => `
  max-width: ${width};
`

// eslint-disable-next-line max-len
const tabletResolution = `@media (min-width: ${layout.mobileWidthResolution + 1}px) and (max-width: ${layout.tabletWidthResolution - 1}px)`
const desktopResolution = `@media (min-width: ${layout.tabletWidthResolution}px)`
const mobileResolution = `@media (max-width: ${layout.mobileWidthResolution}px)`

export const StyledGridItem = styled.div<GridItemProps>`
  ${({ $flex, $width }) => $width?.desktop && !$flex?.desktop && `
    ${desktopResolution} {
      width: ${$width.desktop};
    }
  `}
  
  ${({ $flex, $width }) => ($width?.tablet || $width?.desktop) && !$flex?.tablet && `
    ${tabletResolution} {
      width: ${$width.tablet || $width.desktop};
    }
  `}
  
  ${({ $flex, $width }) => ($width?.mobile || $width?.tablet || $width?.desktop) && !$flex?.mobile && `
    ${mobileResolution} {
      width: ${$width?.mobile || $width?.tablet || $width?.desktop};
    }
  `}

  ${({ $flex, $width }) => $flex?.desktop && !$width?.desktop && `
    ${desktopResolution} {
      flex: ${$flex.desktop};
    }
  `}

  ${({ $flex, $width }) => ($flex?.tablet || $flex?.desktop) && !$width?.tablet && `
    ${tabletResolution} {
      flex: ${$flex.tablet || $flex.desktop};
    }
  `}

  ${({ $flex, $width }) => ($flex?.mobile || $flex?.tablet || $flex?.desktop) && !$width?.mobile && `
    ${mobileResolution} {
      flex: ${$flex?.mobile || $flex?.tablet || $flex?.desktop};
    }
  `}

  ${({ $maxWidth }) => $maxWidth?.desktop && `
    ${desktopResolution} {
      ${getMaxWidth($maxWidth.desktop)};
    }
  `}

  ${({ $maxWidth }) => ($maxWidth?.tablet || $maxWidth?.desktop) && `
    ${tabletResolution} {
      ${getMaxWidth($maxWidth.tablet || $maxWidth.desktop)};
    }
  `}

  ${({ $maxWidth }) => ($maxWidth?.mobile || $maxWidth?.tablet || $maxWidth?.desktop) && `
    ${mobileResolution} {
      ${getMaxWidth($maxWidth?.mobile || $maxWidth?.tablet || $maxWidth?.desktop)}
    }
  `}
  
  ${({ $alignItems, $justifyContent }) => ($alignItems || $justifyContent) && ` 
    display: flex;
  `}
  
  ${({ $alignItems }) => $alignItems?.desktop && `
    ${desktopResolution} {
      align-items: ${$alignItems.desktop};
    }
  `}
  
  ${({ $alignItems }) => ($alignItems?.tablet || $alignItems?.desktop) && `
    ${tabletResolution} {
      align-items: ${$alignItems.desktop || $alignItems.tablet};
    }
  `}
  
  ${({ $alignItems }) => ($alignItems?.mobile || $alignItems?.tablet || $alignItems?.desktop) && `
    ${mobileResolution} {
      align-items: ${$alignItems?.mobile || $alignItems?.tablet || $alignItems?.desktop};
    }
  `}
  
  ${({ $justifyContent }) => $justifyContent?.desktop && `
    ${desktopResolution} {
      justify-content: ${$justifyContent.desktop};
    }
  `}
  
  ${({ $justifyContent }) => ($justifyContent?.tablet || $justifyContent?.desktop) && `
    ${tabletResolution} {
      justify-content: ${$justifyContent.desktop || $justifyContent.tablet};
    }
  `}
  
  ${({ $justifyContent }) => ($justifyContent?.mobile || $justifyContent?.tablet || $justifyContent?.desktop) && `
    ${mobileResolution} {
      justify-content: ${$justifyContent?.mobile || $justifyContent?.tablet || $justifyContent?.desktop};
    }
  `}

  ${({ $textAlign }) => $textAlign?.desktop && `
    ${desktopResolution} {
      text-align: ${$textAlign.desktop};
    }
  `}

  ${({ $textAlign }) => ($textAlign?.tablet || $textAlign?.desktop) && `
    ${tabletResolution} {
      text-align: ${$textAlign.desktop || $textAlign.tablet};
    }
  `}

  ${({ $textAlign }) => ($textAlign?.mobile || $textAlign?.tablet || $textAlign?.desktop) && `
    ${mobileResolution} {
      text-align: ${$textAlign?.mobile || $textAlign?.tablet || $textAlign?.desktop};
    }
  `}
`

interface StyledGridWrapperProps {
  $flex: boolean
  $spacing: number
  $wrap: Property.FlexWrap
}

export const StyledGridWrapper = styled.div<StyledGridWrapperProps>`
  display: flex;
  flex-wrap: wrap;

  ${({ $wrap }) => $wrap?.desktop && `
    ${desktopResolution} {
      flex-wrap: ${$wrap.desktop};
    }
  `}

  ${({ $wrap }) => ($wrap?.tablet || $wrap?.desktop) && `
    ${tabletResolution} {
      flex-wrap: ${$wrap.desktop || $wrap.tablet};
    }
  `}

  ${({ $wrap }) => ($wrap?.mobile || $wrap?.tablet || $wrap?.desktop) && `
    ${mobileResolution} {
      flex-wrap: ${$wrap?.mobile || $wrap?.tablet || $wrap?.desktop};
    }
  `}

  ${({ $flex }) => $flex && `
    flex: 1;
  `}

  ${({ $spacing }) => $spacing && `
    margin: 0 calc(${$spacing}px / -2);

    ${StyledGridItem} {
      padding: calc(${$spacing}px / 2);
    }
  `}
`
