import _ from 'lodash'
import moment from 'moment'

import React, { useEffect } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { stopSubmit } from 'redux-form'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { Type } from 'services/booking/periods/models'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withNurseriesService } from 'services/nurseries'
import { withChildService } from 'services/legacy/child'
import { withChildFundingService } from 'services/legacy/childFunding'
import { withChildFundingSessionSetService } from 'services/legacy/childFundingSessionSet'
import { withChildSessionsService } from 'services/legacy/childSessions'
import { withPeriodTimesService } from 'services/periodTimes'
import { withRouter } from 'services/router'

import i18n from 'translations'

import withChildFundingAllocation from './withChildFundingAllocation'
import ChildFundingAllocationView from './ChildFundingAllocationView'
import { FUNDING_ALLOCATION_FORM } from './components/ChildFundingAllocationForm/ChildFundingAllocationForm'

const CHILD_FUNDING_GROUPS = {
  read: [
    'childFunding.child',
    'childFunding.funding',
    'nurseryFunding',
    'nurseryFunding.fundingType',
    'nurseryFunding.settings',
    'nurseryFundingSettings',
    'fundingType',
    'childFunding.settings',
    'childFundingSettings',
  ],
}

const CHILD_FUNDING_SESSION_SET_GROUPS = {
  read: [
    'childFundingSessionSet.child',
    'childFundingSessionSet.funding',
    'childFunding',
    'childFunding.funding',
    'nurseryFunding',
    'nurseryFunding.fundingType',
    'fundingType',
    'childFundingSessionSet.session',
    'childrenSessions',
    'childFundingSessionSet.allocations',
  ],
}

const NURSERY_GROUPS = {
  read: ['nursery.settings', 'nurserySettings'],
}

const today = moment().format(DEFAULT_DATE_FORMAT)

const ChildFundingAllocationContainer = ({
  appSelectors,
  child,
  childActions,
  childFunding,
  childFundingActions,
  childFundingSessionSetActions,
  childFundingSessionSetHelpers,
  childFundingSessionSetSelectors,
  childFundingSessionSetSingleState,
  childFundingSessionSets,
  childFundingSingleState,
  childSessionsActions,
  childSessionsSelectors,
  customErrorMessage,
  errorMessages,
  initialValues,
  isFetching,
  isInsideModal,
  modalActions,
  modalChildProps,
  modalConsts,
  navigate,
  nurseriesActions,
  nurseryOptions,
  onModalCancelClick,
  params,
  periodTimesActions,
  periodTimesListState,
  selectedSessionSet,
  selectedTimeLine,
  selectedWeekDays,
  setCustomErrorMessage,
  setSelectedSessionSet,
  setSelectedTimeLine,
  setSelectedWeekDays,
  stopSubmitForm,
  timeLineData,
  weekDays,
}) => {
  const fetchSessions = (sessionIds) => {
    const { childId = modalChildProps.childId } = params

    const criteria = childSessionsSelectors.getCriteria({ archived: 0, ids: sessionIds })

    childSessionsActions.list(childId, { criteria })
  }

  const fetchChildFundingSessionSet = (sessions) => {
    const { childId = modalChildProps.childId } = params

    const sessionSetApiParams = {
      criteria: childFundingSessionSetSelectors.getCriteria({
        childId,
        fundingArchived: 0,
        sessionArchived: 0,
        sessions,
      }),
      groups: CHILD_FUNDING_SESSION_SET_GROUPS,
    }

    childFundingSessionSetHelpers.getAllChildFundingSessionSet(sessionSetApiParams)
  }

  const handleFundingSessionSetSuccess = ({ data }) => {
    if (data?.length) {
      const sessions = data.map((sessionSet) => sessionSet.session.id)

      fetchSessions(sessions)
      fetchChildFundingSessionSet(sessions) // For multiple funding
    }
  }

  const handleFundingSuccess = ({ data }) => {
    if (data) {
      // Get list of closure term dates
      const { endDate, startDate } = data

      const criteria = [
        {
          field: 'startDate',
          value: moment(endDate).format(DEFAULT_DATE_FORMAT),
        },
        {
          field: 'endDate',
          value: moment(startDate).format(DEFAULT_DATE_FORMAT),
        },
        {
          field: 'period.type',
          value: Type.closure,
        },
      ]

      periodTimesActions.list({
        params: {
          criteria,
        },
        recursively: true,
      })
    }
  }

  useEffect(() => {
    const { childId = modalChildProps.childId, childFundingId = modalChildProps.childFundingId } = params

    const apiParams = {
      groups: CHILD_FUNDING_GROUPS,
    }

    const sessionSetApiParams = {
      criteria: childFundingSessionSetSelectors.getCriteria({
        childFundingId,
        childId,
        fundingArchived: 0,
        sessionArchived: 0,
      }),
      groups: CHILD_FUNDING_SESSION_SET_GROUPS,
    }
    const nurseryApiParams = { groups: NURSERY_GROUPS }

    nurseriesActions.get(nurseryOptions.id, {
      params: nurseryApiParams,
    })
    childFundingActions.get(childFundingId, apiParams, handleFundingSuccess)
    childFundingSessionSetHelpers.getAllChildFundingSessionSet(sessionSetApiParams, handleFundingSessionSetSuccess)

    if (isInsideModal) {
      childActions.get({ params: [childId] })
    }

    return () => {
      nurseriesActions.clearSingle()
      childFundingActions.clearSingle()
      childFundingSessionSetActions.clear()
      childSessionsActions.clear()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!selectedSessionSet && childFundingSessionSets?.length) {
      let currentSessionSetIndex = -1

      _.forEach(childFundingSessionSets, ((sessionSet, i) => {
        const { funding, session } = sessionSet
        const { endDate, isOngoing, startDate } = session
        const { endDate: fundingEndDate } = funding

        const getEndDate = () => {
          if (isOngoing) {
            if (today > moment(fundingEndDate).format(DEFAULT_DATE_FORMAT)) {
              return moment(fundingEndDate).format(DEFAULT_DATE_FORMAT)
            }

            return today
          }

          return moment(endDate).format(DEFAULT_DATE_FORMAT)
        }

        const formattedStartDate = moment(startDate).format(DEFAULT_DATE_FORMAT)
        const formattedEndDate = getEndDate()

        if (formattedStartDate <= today && formattedEndDate >= today) {
          currentSessionSetIndex = i

          return false
        }

        return true
      }))

      setSelectedSessionSet(childFundingSessionSets[-1 < currentSessionSetIndex ? currentSessionSetIndex : 0])
    }
    // eslint-disable-next-line
  }, [childFundingSessionSets ? childFundingSessionSets.length : 0])

  useEffect(() => {
    if (!selectedWeekDays && weekDays && weekDays.length) {
      let currentWeekDaysIndex = -1

      _.forEach(weekDays, ((weekDay, i) => {
        const { weekEndDate, weekStartDate } = weekDay

        const formattedWeekStartDate = moment(weekStartDate).format(DEFAULT_DATE_FORMAT)
        const formattedWeekEndDate = moment(weekEndDate).format(DEFAULT_DATE_FORMAT)
        if (formattedWeekStartDate <= today && formattedWeekEndDate >= today) {
          currentWeekDaysIndex = i

          return false
        }

        return true
      }))

      setSelectedWeekDays(weekDays[-1 < currentWeekDaysIndex ? currentWeekDaysIndex : 0])
    }
    // eslint-disable-next-line
  }, [weekDays ? weekDays.length : 0])

  useEffect(() => {
    if (selectedWeekDays && selectedSessionSet) {
      const { sessions } = selectedWeekDays
      const { id: selectedSessionSetId } = selectedSessionSet

      const weekDaysSessionSets = _.filter(
        childFundingSessionSets,
        ({ session }) => _.some(sessions, { id: session.id }),
      )

      const conflictWithCurrentSessionSet = !_.some(weekDaysSessionSets, { id: selectedSessionSetId })

      if (conflictWithCurrentSessionSet) {
        setSelectedSessionSet(weekDaysSessionSets[0])
      }
    }
    // eslint-disable-next-line
  }, [selectedWeekDays])

  useEffect(() => {
    if (selectedSessionSet && selectedWeekDays) {
      const { session: selectedSessionSetSession } = selectedSessionSet
      const { sessions: selectedWeekDaysSessions } = selectedWeekDays

      const conflictWithCurrentWeek = !_.some(
        selectedWeekDaysSessions,
        (session) => session.id === selectedSessionSetSession.id,
      )

      if (conflictWithCurrentWeek) {
        const selectedSessionSetFirstWeek = _.find(
          weekDays,
          ({ sessions }) => _.some(sessions, { id: selectedSessionSetSession.id }),
        )

        setSelectedWeekDays(selectedSessionSetFirstWeek)
      }
    }
    // eslint-disable-next-line
  }, [selectedSessionSet])

  const handleSessionSetClick = (sessionSet) => {
    setSelectedSessionSet(sessionSet)
  }

  const handleWeekDaysClick = (weekDay) => {
    setSelectedWeekDays(weekDay)
    setCustomErrorMessage(null)
  }

  const handleTimeLineItemClick = (date, progress) => {
    setSelectedTimeLine({ date, progress })
    setCustomErrorMessage(null)

    const { childSessionSetId } = progress
    const { id } = selectedSessionSet || {}

    if (childSessionSetId !== id) {
      const sessionSet = _.find(childFundingSessionSets, { id: childSessionSetId })

      setSelectedSessionSet(sessionSet)
    }
  }

  const handleCancelAllocation = () => {
    setSelectedTimeLine(null)
  }

  const handleSuccess = (response) => {
    const { onSaveAllocationSuccess } = modalChildProps || {}
    const { data } = response

    setSelectedTimeLine(null)

    childFundingSessionSetActions.updateList(data)

    if (onSaveAllocationSuccess) {
      onSaveAllocationSuccess(response)
    }
  }

  const handleFail = (error) => {
    stopSubmitForm(childFundingSessionSetSelectors.getFieldValidationSummary(error))
  }

  const handleSaveAllocation = (fields) => {
    const { progress: { childSessionSetId } } = selectedTimeLine

    const sessionSet = _.find(
      childFundingSessionSets,
      (childFundingSessionSet) => childFundingSessionSet.id === childSessionSetId,
    )

    const sessionSetApiParams = {
      groups: CHILD_FUNDING_SESSION_SET_GROUPS,
    }

    const payload = childFundingSessionSetSelectors.getPayload({ fields, selectedTimeLine, sessionSet })

    childFundingSessionSetActions.update(childSessionSetId, payload, sessionSetApiParams, handleSuccess, handleFail)
  }

  const handleApplyFundingSuccess = (data) => {
    modalActions.hide()

    handleSuccess(data)

    modalActions.show(modalConsts.TYPES.ALERT, {
      text: i18n.t('module:Children:Child:BookingPattern:Funding:Allocation:applyFundingSuccessCopy'),
    })
  }

  const handleApplyFundingFail = (error) => {
    setCustomErrorMessage(appSelectors.getErrorMessages({ error }))
    modalActions.hide()
  }

  const handleApply = () => {
    modalActions.show(modalConsts.TYPES.ALERT, {
      hideButton: true,
      text: i18n.t('module:Children:Child:BookingPattern:Funding:Allocation:allocatingCopy'),
    })

    const { sessions } = selectedWeekDays
    const { id: sessionId } = sessions[0]

    const sessionSet = _.find(
      childFundingSessionSets,
      (childSessionSet) => childSessionSet.session.id === sessionId,
    )

    const sessionSetApiParams = {
      groups: CHILD_FUNDING_SESSION_SET_GROUPS,
    }

    const payload = childFundingSessionSetSelectors.getPayloadsForApplyFunding({
      childFundingSessionSets,
      childFundingSingleState,
      periodTimesListState,
      selectedWeekDays,
      weekDays,
    })

    childFundingSessionSetActions.update(
      sessionSet.id,
      payload,
      sessionSetApiParams,
      handleApplyFundingSuccess,
      handleApplyFundingFail,
    )
  }

  const handleApplyFunding = () => {
    modalActions.show(modalConsts.TYPES.CONFIRM, {
      onConfirm: handleApply,
      text: i18n.t('module:Children:Child:BookingPattern:Funding:Allocation:applyFundingWarningCopy'),
    })
  }

  const handleFinishAllocationClick = () => {
    if (onModalCancelClick) {
      return onModalCancelClick()
    }

    return null
  }

  const handleAddSessionClick = (childId) => {
    if (onModalCancelClick) {
      onModalCancelClick()
    }

    navigate(generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.SESSIONS.VIEW', { childId }))
  }

  const isTimeLineLoading = childFundingSessionSetSingleState.isFetching
  const isSubmitting = childFundingSessionSetSingleState.isSubmitting
  const enableApplyButton = selectedWeekDays && selectedWeekDays.sessions && 1 === selectedWeekDays.sessions.length

  return (
    <ChildFundingAllocationView
      child={child}
      childFunding={childFunding}
      childFundingSessionSets={childFundingSessionSets}
      customErrorMessage={customErrorMessage}
      enableApplyButton={enableApplyButton}
      errorMessages={errorMessages}
      initialValues={initialValues}
      isInsideModal={isInsideModal}
      isLoading={isFetching}
      isSubmitting={isSubmitting}
      isTimeLineLoading={isTimeLineLoading}
      selectedTimeLine={selectedTimeLine}
      selectedWeekDays={selectedWeekDays}
      weekDays={weekDays}
      weeklySchedulerData={timeLineData}
      onAddSessionClick={handleAddSessionClick}
      onApplyFundingClick={handleApplyFunding}
      onCancelAllocation={handleCancelAllocation}
      onFinishAllocationClick={handleFinishAllocationClick}
      onSaveAllocation={handleSaveAllocation}
      onSessionSetClick={handleSessionSetClick}
      onTimeLineItemClick={handleTimeLineItemClick}
      onWeekDaysClick={handleWeekDaysClick}
    />
  )
}

const mapState = (state, {
  appSelectors,
  childFundingSelectors,
  childFundingSessionSetListState,
  childFundingSessionSetSelectors,
  childFundingSessionSetSingleState,
  childFundingSingleState,
  childSelectors,
  params,
  selectedSessionSet,
  selectedTimeLine,
  selectedWeekDays,
}) => ({
  child: childSelectors.getChildSelector(state),
  childFunding: childFundingSelectors.getChildFundingWithAllocationSummary(state),
  childFundingPreviewData: childFundingSelectors.getItemPreviewDataSelectors(state),
  childFundingSessionSets: childFundingSessionSetSelectors.getSessionSets(selectedSessionSet, selectedWeekDays)(state),
  errorMessages: appSelectors.getErrorMessages(
    childFundingSingleState,
    childFundingSessionSetListState,
    childFundingSessionSetSingleState,
  ),
  initialValues: childFundingSessionSetSelectors.getInitialValues(selectedTimeLine),
  isFetching: appSelectors.getIsFetching(childFundingSingleState, childFundingSessionSetListState),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  timeLineData: childFundingSessionSetSelectors.getTimeLineData(
    selectedWeekDays,
    selectedTimeLine,
    selectedSessionSet,
  )(state),
  weekDays: childFundingSessionSetSelectors.getFundingWeekDaysList(selectedWeekDays)(state),
})

const mapDispatch = {
  stopSubmitForm: (params) => stopSubmit(FUNDING_ALLOCATION_FORM, params),
}

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withNurseriesService,
  withChildService,
  withChildFundingService,
  withChildFundingSessionSetService,
  withChildSessionsService,
  withPeriodTimesService,
  withChildFundingAllocation,
  connect(mapState, mapDispatch),
)

export default enhance(ChildFundingAllocationContainer)
