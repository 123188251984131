import _ from 'lodash'
import { convertLegacyChildToV2 } from 'services/legacy/child/single/selectors'

const formatData = (data) => {
  const { children, comments, id, media, name, recordedAt } = data

  return {
    children: _.map(children, convertLegacyChildToV2),
    id,
    media,
    name,
    record: {
      comments,
      recordedAt,
    },
  }
}

export const addDiaryActivitiesRecord = (state, data) => {
  const formattedData = formatData(data)

  const updatedData = state?.data?.length
    ? [...state.data, formattedData]
    : [formattedData]

  return {
    ...state,
    data: updatedData,
    meta: {
      ...state.meta,
      total_results: state.meta.total_results + 1, // eslint-disable-line
    },
  }
}

export const updateDiaryActivitiesRecord = (state, data) => {
  const formattedData = formatData(data)

  return {
    ...state,
    data: _.map(state.data, (item) => {
      if (item.id === data.id) {
        return formattedData
      }

      return item
    }),
  }
}

export const removeDiaryActivitiesRecord = (state, id) => {
  const updatedData = _.filter(state.data, (item) => item.id !== id)

  return {
    ...state,
    data: updatedData,
    meta: {
      ...state.meta,
      total_results: state.meta.total_results - 1, // eslint-disable-line
    },
  }
}
