import React from 'react'

import { Spinner } from 'components'

import GlobalInvoiceSettingsAddForm from './components/GlobalInvoiceSettingsAddForm'

const SettingsInvoicesGlobalEditView = ({
  globalSettingsOptions,
  initialValues,
  isFormLoading,
  isLoading,
  onSubmit,
}) => {
  if (isLoading) {
    return <Spinner />
  }

  return (
    <GlobalInvoiceSettingsAddForm
      globalSettingsOptions={globalSettingsOptions}
      initialValues={initialValues}
      isLoading={isFormLoading}
      onSubmit={onSubmit}
    />
  )
}

export default SettingsInvoicesGlobalEditView
