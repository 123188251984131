import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'
import selectors from './selectors'
import { NurseryIntegrationsCurrenciesState } from './currencies/reducer'
import { NurseryIntegrationsListState } from './list/reducer'
import { NurseryIntegrationsSingleState } from './single/reducer'
import { NurseryIntegrationsStatisticsState } from './statistics/reducer'
import { NurseryIntegrationsStripeState } from './stripe/reducer'
import { NurseryIntegrationsFailedRecurringPaymentState } from './failedRecurringPayment/reducer'
import { NurseryIntegrationsActiveRecurringPaymentState } from './activeRecurringPayment/reducer'
import { NurseryIntegrationsPayoutTransactionsState } from './payoutTransactions/reducer'

export interface withNurseryIntegrationsServiceProps {
  nurseryIntegrationsActions: typeof actions
  nurseryIntegrationsActiveRecurringPaymentState: NurseryIntegrationsActiveRecurringPaymentState
  nurseryIntegrationsCurrenciesState: NurseryIntegrationsCurrenciesState
  nurseryIntegrationsFailedRecurringPaymentState: NurseryIntegrationsFailedRecurringPaymentState
  nurseryIntegrationsListState: NurseryIntegrationsListState
  nurseryIntegrationsPayoutTransactionsState: NurseryIntegrationsPayoutTransactionsState
  nurseryIntegrationsSelectors: typeof selectors
  nurseryIntegrationsSingleState: NurseryIntegrationsSingleState
  nurseryIntegrationsStatisticsState: NurseryIntegrationsStatisticsState
  nurseryIntegrationsStripeState: NurseryIntegrationsStripeState
}

const withNurseryIntegrationsService = <P extends withNurseryIntegrationsServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ nurseryIntegrations }) => ({ nurseryIntegrations })

  const mapDispatch = injectActions('nurseryIntegrationsActions', actions)

  const Component = (props) => {
    const { nurseryIntegrations, nurseryIntegrationsActions, ...others } = props

    return (
      <WrappedComponent
        nurseryIntegrationsActions={nurseryIntegrationsActions}
        nurseryIntegrationsActiveRecurringPaymentState={nurseryIntegrations.activeRecurringPayment}
        nurseryIntegrationsCurrenciesState={nurseryIntegrations.currencies}
        nurseryIntegrationsFailedRecurringPaymentState={nurseryIntegrations.failedRecurringPayment}
        nurseryIntegrationsListState={nurseryIntegrations.list}
        nurseryIntegrationsPayoutTransactionsState={nurseryIntegrations.payoutTransactions}
        nurseryIntegrationsSelectors={selectors}
        nurseryIntegrationsSingleState={nurseryIntegrations.single}
        nurseryIntegrationsStatisticsState={nurseryIntegrations.statistics}
        nurseryIntegrationsStripeState={nurseryIntegrations.stripe}
        {...others as P}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withNurseryIntegrationsService
