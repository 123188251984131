import { createSelector } from 'reselect'

import { LEAVE_TYPE_STATUSES } from '../constants'

export const getLeaveTypes = (state) => state.leaveTypes.list

export const getLeaveTypesListDataSelector = createSelector(
  [getLeaveTypes],
  (state) => state.data,
)

export const getLeaveTypesTotalResultsSelector = createSelector(
  [getLeaveTypes],
  (state) => state.meta.total_results,
)

export const getListCriteria = ({ filters }) => {
  const { leaveTypeStatus } = filters || {}

  const criteria = []

  if (
    leaveTypeStatus === LEAVE_TYPE_STATUSES.ARCHIVED
       || leaveTypeStatus === LEAVE_TYPE_STATUSES.ACTIVE
  ) {
    criteria.push({
      field: 'archived',
      value: leaveTypeStatus === LEAVE_TYPE_STATUSES.ARCHIVED,
    })
  }

  return criteria
}

export const getDropdownListCriteria = ({ archived, name }) => {
  const criteria = []

  if (name) {
    criteria.push({
      field: 'name',
      value: name,
    })
  }

  if (archived !== undefined) {
    criteria.push({
      field: 'archived',
      value: archived,
    })
  }

  return criteria
}
