import styled from 'styled-components'

import { Property } from 'csstype'

interface StyledContainerProps {
  justify?: Property.JustifyContent
  onClick?: () => void
}

export const StyledContainer = styled.div<StyledContainerProps>`
  font-size: 0;
  display: flex;

  ${({ onClick }) => onClick && `
    cursor: pointer;
  `}

  ${({ $justify }) => $justify && `
    justify-content: ${$justify};
  `}
`

export const StyledStorybookWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto); 
  grid-gap: 25px;
`

export const StyledStorybookIcon = styled.div`
  display: grid;
  grid-template-columns: 30px auto;
  grid-column-gap: 10px;  
  align-items: center;
`
