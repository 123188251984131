import moment from 'moment'

import React, { PropsWithChildren } from 'react'

import { Avatar, TimeString, Typography } from 'components'

import {
  StyledContainer,
  StyledContent,
  StyledContentContainer,
  StyledContentTextContainer,
  StyledContentUserContainer,
  StyledPolyfill,
  StyledTime,
  StyledTimeContainer,
} from './TimelineItemStyled'

interface TimelineItemProps {
  date: moment.Moment | number
  isLast?: boolean
  text: string
  user: {
    name: string
    photo: string
  }
}

const TimelineItem: React.FC<PropsWithChildren<TimelineItemProps>> = ({ date, isLast, text, user }) => {
  const { name, photo } = user || {}

  return (
    <StyledContainer>
      <StyledTimeContainer>
        <StyledTime>
          <TimeString date={date} />
        </StyledTime>
        {isLast && (
          <StyledPolyfill />
        )}
      </StyledTimeContainer>
      <StyledContentContainer>
        <StyledContent>
          {user && (
            <StyledContentUserContainer>
              <Avatar
                avatarSize="small"
                initials={[name]}
                src={photo}
              />
            </StyledContentUserContainer>
          )}
          <StyledContentTextContainer>
            {text}
          </StyledContentTextContainer>
        </StyledContent>
        <Typography fontSize={14} padding="7px">
          {moment(date).format('MMM DD, YYYY, HH:mm')}
        </Typography>
      </StyledContentContainer>
    </StyledContainer>
  )
}

export default TimelineItem
