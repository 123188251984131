import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { RootState } from 'core/reducers'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as shellSelectors from './selectors'

export interface withShellServiceProps {
  shellActions: typeof actions
  shellSelectors: typeof shellSelectors
}

const withShellService = <P extends withShellServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ shell }: RootState) => ({ shell })

  const mapDispatch = injectActions('shellActions', actions)

  const Component = ({ shellActions, ...others }) => (
    <WrappedComponent
      shellActions={shellActions}
      shellSelectors={shellSelectors}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withShellService
