import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { DEFAULT_DATE_FORMAT, DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT } from 'constants/date'
import { ABSENCE_TYPE } from 'module/Register/constants'
import { NEUTRAL_COLOURS } from 'constants/colors'
import { CHILD_LEAVE_TYPES_COLOURS } from 'services/legacy/child/constants'

import { DateString, DropdownMenu, Icon, Typography } from 'components'

import i18n from 'translations'

import { StyledAbsenceTypeWrapper } from './ChildAbsencesStyled'

export const getTableData = ({ data, onDeleteClick, onEditClick }) => {
  if (!data?.length) {
    return []
  }

  return _.map(data, (item) => {
    const {
      absenceReason,
      absenceType,
      comments,
      endDate,
      id,
      startDate,
    } = item

    const oneDay = moment(startDate).format(DEFAULT_DATE_FORMAT) === moment(endDate).format(DEFAULT_DATE_FORMAT)

    return {
      absenceType: ABSENCE_TYPE.ABSENCE === absenceType ? (
        <StyledAbsenceTypeWrapper>
          <Icon color={CHILD_LEAVE_TYPES_COLOURS.ABSENCE} height={14} icon="absence" />
          <Typography margin="0 0 0 10px" variant="div">
            <Typography>
              {i18n.t('global:Absence')}
            </Typography>
            <Typography color={NEUTRAL_COLOURS.GRAY}>
              {absenceReason?.name}
            </Typography>
          </Typography>
        </StyledAbsenceTypeWrapper>
      ) : (
        <StyledAbsenceTypeWrapper>
          <Icon color={CHILD_LEAVE_TYPES_COLOURS.HOLIDAY} height={14} icon="holiday" />
          <Typography margin="0 0 0 10px">
            {i18n.t('global:Holiday')}
          </Typography>
        </StyledAbsenceTypeWrapper>
      ),
      comments: comments || '-',
      date: oneDay ? <DateString date={startDate} format={DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT} /> : (
        <React.Fragment>
          <DateString date={startDate} format={DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT} />
          {' - '}
          <DateString date={endDate} format={DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT} />
        </React.Fragment>
      ),
      id,
      options: (
        <DropdownMenu small>
          <DropdownMenu.Item
            type="edit"
            onClick={() => onEditClick(id)}
          />
          <DropdownMenu.Item
            type="delete"
            onClick={() => onDeleteClick(id)}
          />
        </DropdownMenu>
      ),
    }
  })
}
