import styled from 'styled-components'
import color from 'color'

import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'
import { typeByObject } from 'utils/typescript'

export const VISUAL_TYPE = {
  MODERN: 'modern',
  TRANSPARENT: 'transparent',
}

interface StyledButtonContainerProps {
  $width: string
}

export const StyledButtonContainer = styled.div<StyledButtonContainerProps>`
  ${({ $width }) => $width && `
    width: ${$width};
  `}
`

/* eslint-disable */
export const StyledDatePicker = styled.div`
  border-radius: 14px;
  position: relative;
  min-height: 260px;
  
  .DayPicker {
    border-radius: 14px;
  }

  .DayPicker-Day {
    min-width: 34px;
    border-radius: 0;
    
    &--today {
      font-weight: 600;
      font-size: 14px;
      background: ${NEUTRAL_COLOURS.GRAY_TERTIARY};
      color: ${NEUTRAL_COLOURS.BASIC};
      border-radius: 5px;
      
      &.DayPicker-Day--outside {
        background: transparent;
      }
      
      &--disabled {
        color: ${color(getBrandingColor('primary-color')).alpha(0.6).rgb().string()};
      }
    }
  }

  .DayPicker-Day--hoverRange {
    background-color: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  }

  .DayPicker:not(.DayPicker--interactionDisabled) 
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: ${NEUTRAL_COLOURS.GRAY_SECONDARY};
  }
  
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selectedRangeStart):not(.DayPicker-Day--selectedRangeEnd):not(.DayPicker-Day--start):not(.DayPicker-Day--end)[aria-selected='true'] {
    color: inherit;
    background: ${NEUTRAL_COLOURS.GRAY_TERTIARY} !important;
    
    &:hover {
      background: ${NEUTRAL_COLOURS.GRAY_SECONDARY} !important;
    }
  }
    
  .DayPicker-Day--selectedRangeStart[aria-selected='true'],
  .DayPicker-Day--selectedRangeEnd[aria-selected='true'],
  .DayPicker-Day--start[aria-selected='true'],
  .DayPicker-Day--end[aria-selected='true'] {
    background: ${NEUTRAL_COLOURS.GRAY_SECONDARY} !important;
  }

  .DayPicker-Day--selectedRangeStart[aria-selected='true'],
  .DayPicker-Day--hoverRangeStart[aria-selected='true'],
  .DayPicker-Day--start[aria-selected='true'] {
    color: inherit;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .DayPicker-Day--selectedRangeEnd[aria-selected='true'],
  .DayPicker-Day--hoverRangeEnd[aria-selected='true'],
  .DayPicker-Day--end[aria-selected='true'] {
    color: inherit;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  
  @media(max-width: 660px) {
    .DayPicker-Months {
      flex-direction: column;
    }
  }
`
/* eslint-enable */

interface StyledInputWrapperProps {
  $disabled?: boolean
  $error?: boolean
  $range?: boolean
  $visualType?: typeByObject<typeof VISUAL_TYPE>
}

export const StyledInputWrapper = styled.div<StyledInputWrapperProps>`
  cursor: pointer;
  height: 42px;
  border: 1px solid #E4E4E4;
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 4px;
  font-size: 16px;
  display: flex;
  align-items: center;
  min-width: ${({ $range }) => ($range ? '220px' : '170px')};
  
  ${({ $error }) => $error && `
    border-color: ${FLAG_COLOURS.ERROR} !important;
  `}
  
  ${({ $disabled }) => $disabled && `
    color: ${NEUTRAL_COLOURS.GRAY};
    background: #F2F2F2;
    cursor: not-allowed;
    border-color: #e4e4e4;
  `}
  
  ${({ $visualType }) => VISUAL_TYPE.MODERN === $visualType && `
    border: 2px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};
    border-radius: 0;
    
    &--is-focused {
      border-color: ${getBrandingColor('primary-color')} !important;
    }
  `}

  ${({ $visualType }) => VISUAL_TYPE.TRANSPARENT === $visualType && `
    border: none;
    border-radius: 0;
    padding: 0 !important;
    color: ${getBrandingColor('primary-color')} !important;
    font-weight: 600 !important;
    min-width: 120px;

    &--is-focused {
      border: none !important;
    }
  `}
`

export const StyledFlex = styled.div`
  min-width: 10px;
  flex: 1;
`

interface StyledWrapperProps {
  $threeColumns?: boolean
}

export const StyledWrapper = styled.div<StyledWrapperProps>`
  display: grid;
  grid-template-columns: auto;
  align-items: center;  
  background: ${NEUTRAL_COLOURS.WHITE};

  ${({ $threeColumns }) => $threeColumns && `
    grid-template-columns: auto auto auto;
  `}
`

export const StyledLabel = styled.div`
  @media(max-width: 420px) {
    display: grid;
    align-items: center;
  
    & span:nth-of-type(2) {
      display: none;
    }
  
    grid-template-rows: auto auto;
    justify-content: center;
    width: 100%;
  }
`

export const StyledPlaceholder = styled.div`
  color: ${NEUTRAL_COLOURS.GRAY};
`

export const StyledMonthGrid = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
`

export const StyledMonthRangeWrapper = styled.div`
  display: flex;
  padding: 15px;

  & ${StyledMonthGrid} ~ ${StyledMonthGrid} {
    padding-left: 20px;
  }
`

interface StyledMonthProps {
  $disabled?: boolean
  $inBetween?: boolean
  $selected?: boolean
}

export const StyledMonth = styled.div<StyledMonthProps>`
  height: 40px;
  min-width: 90px;
  background: ${NEUTRAL_COLOURS.GRAY_QUATERNARY};
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  
  ${({ $selected }) => $selected && `
    font-weight: 600;
    font-size: 15px;
    background: ${NEUTRAL_COLOURS.GRAY_SECONDARY};
    border: 1px solid ${NEUTRAL_COLOURS.GRAY};
  `}

  ${({ $inBetween }) => $inBetween && `
    background: ${NEUTRAL_COLOURS.GRAY_TERTIARY} !important;
    border: 1px solid ${NEUTRAL_COLOURS.GRAY};
  `}
  
  ${({ $disabled }) => $disabled && `
    opacity: 0.5;
    cursor: not-allowed;
  `}
`

export const StyledYearWrapper = styled.div`
  display: grid;
  grid-column-start: 1;
  grid-column-end: 4;
  text-align: left;
  align-items: center;
  grid-template-columns: 1fr auto auto;

  & *:nth-child(2) {
    padding: 0 5px;
  }
    
  & *:nth-child(3) {
    padding: 0 5px;
  }
`
