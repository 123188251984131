import { createSelector } from 'reselect'

const getFinanceReports = (state) => state.financeRevenuePerAgeReport

export const getFinanceRevenuePerAgeReportStatistics = createSelector(
  [getFinanceReports],
  (state) => state.statistics,
)

export const getFinanceRevenuePerAgeReportStatisticsData = createSelector(
  [getFinanceRevenuePerAgeReportStatistics],
  (state) => state.data,
)

