import _ from 'lodash'
import moment from 'moment'

import { DateUtils } from 'react-day-picker'
import { Ranges } from './MultiRangeCalendar'

export const isSelectingFirstDay = (from: any, to: any) => {
  const isRangeSelected: boolean = from && to

  return !from || isRangeSelected
}

const mergeRangesInsideSelection = (tempRange: Ranges, ranges: Ranges[]): Ranges[] => {
  let shouldIncrease = false
  const sortedRanges = _.sortBy(ranges, 'from')

  let newRanges = _.map(sortedRanges, (range) => {
    const { from, to } = range
    const isFromInTempRange = DateUtils.isDayInRange(from, tempRange)
    const isToInTempRange = DateUtils.isDayInRange(to, tempRange)

    if (isFromInTempRange && isToInTempRange) {
      shouldIncrease = true

      return tempRange
    }

    if (isFromInTempRange && !isToInTempRange) {
      shouldIncrease = true

      return { from: tempRange.from, to }
    }

    return range
  })

  newRanges = [...new Set(newRanges)]

  if (shouldIncrease) {
    return newRanges
  }

  return [...newRanges, tempRange]
}

export const updateRanges = (tempRange: Ranges, ranges: Ranges[]): Ranges[] => {
  const finalRanges = mergeRangesInsideSelection(tempRange, ranges)

  // Combining entries if selected dates are next to each other.
  return _.reduce(_.sortBy(finalRanges, 'from'), (result, range) => {
    if (!result.length) {
      return [range]
    }

    const resultCopy = [...result]
    const prevIndex = resultCopy.length - 1
    const { from: prevFrom, to: prevTo } = resultCopy[prevIndex]
    const { from, to } = range

    if (DateUtils.isSameDay(moment(prevTo).add(1, 'd').toDate(), from)) {
      resultCopy[prevIndex] = { from: prevFrom, to }
    } else {
      resultCopy.push(range)
    }

    return resultCopy
  }, [])
}

export const splitRanges = (tempUnSelectedRange: Ranges, ranges: Ranges[]): Ranges[] => {
  if (!ranges.length) {
    return []
  }

  const newRanges = []

  ranges.forEach((range) => {
    const { from, to } = range

    if (from && to) {
      // When un-selected from and to are same as selected range from and to
      if (
        (DateUtils.isSameDay(from, tempUnSelectedRange.from)
          && DateUtils.isSameDay(to, tempUnSelectedRange.to))
        || (DateUtils.isSameDay(tempUnSelectedRange.from, from)
          && DateUtils.isDayAfter(tempUnSelectedRange.to, to))
        || (DateUtils.isDayBefore(tempUnSelectedRange.from, from)
          && DateUtils.isSameDay(tempUnSelectedRange.to, to))
        || (DateUtils.isDayBefore(tempUnSelectedRange.from, from)
          && DateUtils.isDayAfter(tempUnSelectedRange.to, to))
      ) {
        return
      }

      const isFromSameAsTempFrom = DateUtils.isSameDay(from, tempUnSelectedRange.from)
      const isToSameAsTempTo = DateUtils.isSameDay(to, tempUnSelectedRange.to)

      const isFromInTempRange = DateUtils.isDayInRange(tempUnSelectedRange.from, range)
      const isToInTempRange = DateUtils.isDayInRange(tempUnSelectedRange.to, range)

      if (isFromInTempRange && isToInTempRange) {
        const firstTempToDate = moment(tempUnSelectedRange.from)
        const secondTempFromDate = moment(tempUnSelectedRange.to)

        firstTempToDate.add(-1, 'days')
        secondTempFromDate.add(1, 'days')

        if (isFromSameAsTempFrom) {
          newRanges.push({ from: secondTempFromDate.toDate(), to })
        } else if (isToSameAsTempTo) {
          newRanges.push({ from, to: firstTempToDate.toDate() })
        } else {
          newRanges.push({ from, to: firstTempToDate.toDate() })
          newRanges.push({ from: secondTempFromDate.toDate(), to })
        }
      }

      if (isFromInTempRange && !isToInTempRange) {
        const firstTempToDate = moment(tempUnSelectedRange.from).add(-1, 'days')

        newRanges.push({ from, to: firstTempToDate.toDate() })
      }

      if (!isFromInTempRange && isToInTempRange) {
        const secondTempFromDate = moment(tempUnSelectedRange.to).add(1, 'days')

        newRanges.push({ from: secondTempFromDate.toDate, to })
      }

      if (!isFromInTempRange && !isToInTempRange) {
        newRanges.push({ from, to })
      }
    }
  })

  return newRanges
}
