import { generateBasicActions } from 'utils/service'

import {
  CLEAR_PROGRESS_TRACKER_SINGLE,
  CREATE_PROGRESS_TRACKER_REPORT,
  CREATE_PROGRESS_TRACKER_REPORT_FAILED,
  CREATE_PROGRESS_TRACKER_REPORT_SUCCESS,
  GET_PROGRESS_TRACKER_PREVIEW,
  GET_PROGRESS_TRACKER_PREVIEW_FAILED,
  GET_PROGRESS_TRACKER_PREVIEW_SUCCESS,
  GET_PROGRESS_TRACKER_REPORT,
  GET_PROGRESS_TRACKER_REPORT_FAILED,
  GET_PROGRESS_TRACKER_REPORT_SUCCESS,
  REMOVE_PROGRESS_TRACKER_REPORT,
  REMOVE_PROGRESS_TRACKER_REPORT_FAILED,
  REMOVE_PROGRESS_TRACKER_REPORT_SUCCESS,
  UPDATE_PROGRESS_TRACKER_ACTION_PLAN,
  UPDATE_PROGRESS_TRACKER_ACTION_PLAN_FAILED,
  UPDATE_PROGRESS_TRACKER_ACTION_PLAN_SUCCESS,
} from './constants'

export const getProgressTrackerPreview = (options = {}) => ({ cohortTrackingApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_PROGRESS_TRACKER_PREVIEW_FAILED,
    init: GET_PROGRESS_TRACKER_PREVIEW,
    success: GET_PROGRESS_TRACKER_PREVIEW_SUCCESS,
  },
  options,
  service: cohortTrackingApiClient,
  serviceMethod: 'getProgressTrackerPreview',
})

export const getProgressTrackerReport = (options = {}) => ({ cohortTrackingApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_PROGRESS_TRACKER_REPORT_FAILED,
    init: GET_PROGRESS_TRACKER_REPORT,
    success: GET_PROGRESS_TRACKER_REPORT_SUCCESS,
  },
  options,
  service: cohortTrackingApiClient,
  serviceMethod: 'getProgressTrackerReport',
})

export const createProgressTracker = (options = {}) => ({ cohortTrackingApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: CREATE_PROGRESS_TRACKER_REPORT_FAILED,
      init: CREATE_PROGRESS_TRACKER_REPORT,
      success: CREATE_PROGRESS_TRACKER_REPORT_SUCCESS,
    },
    options,
    service: cohortTrackingApiClient,
    serviceMethod: 'createProgressTracker',
  })
)

export const removeProgressTracker = (options = {}) => ({ cohortTrackingApiClient }) => (
  generateBasicActions.remove({
    constants: {
      failed: REMOVE_PROGRESS_TRACKER_REPORT_FAILED,
      init: REMOVE_PROGRESS_TRACKER_REPORT,
      success: REMOVE_PROGRESS_TRACKER_REPORT_SUCCESS,
    },
    options,
    service: cohortTrackingApiClient,
    serviceMethod: 'removeProgressTracker',
  })
)

export const clearProgressTracker = () => ({
  type: CLEAR_PROGRESS_TRACKER_SINGLE,
})

export const updateProgressTrackerActionPlan = (options = {}) => ({ cohortTrackingApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_PROGRESS_TRACKER_ACTION_PLAN_FAILED,
      init: UPDATE_PROGRESS_TRACKER_ACTION_PLAN,
      success: UPDATE_PROGRESS_TRACKER_ACTION_PLAN_SUCCESS,
    },
    options,
    service: cohortTrackingApiClient,
    serviceMethod: 'updateProgressTrackerActionPlan',
  })
)
