import styled from 'styled-components'

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;

  img {
    display: block;
    width: 100%;
    max-width: 100%;
  }
`
