import {
  CLEAR_USER_SETTINGS,
  GET_USER_SETTINGS,
  GET_USER_SETTINGS_FAILED,
  GET_USER_SETTINGS_SUCCESS,
  UPDATE_USER_SETTINGS,
  UPDATE_USER_SETTINGS_FAILED,
  UPDATE_USER_SETTINGS_SUCCESS,
} from './constants'

export const getUserSettings = (id, onSuccess) => ({ usersApiClient }) => (dispatch) => {
  dispatch({ type: GET_USER_SETTINGS })

  usersApiClient.getUserSettings(id).then(
    ({ data }) => {
      dispatch({
        payload: data,
        type: GET_USER_SETTINGS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => {
      dispatch({
        payload: response,
        type: GET_USER_SETTINGS_FAILED,
      })
    },
  )
}

export const updateUserSettings = (id, body, onSuccess) => ({ usersApiClient }) => (dispatch) => {
  dispatch({ type: UPDATE_USER_SETTINGS })

  usersApiClient.updateUserSettings(id, body).then(
    (data) => {
      dispatch({
        payload: data,
        type: UPDATE_USER_SETTINGS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    ({ response }) => {
      dispatch({
        payload: response,
        type: UPDATE_USER_SETTINGS_FAILED,
      })
    },
  )
}

export const clearUserSettings = () => ({
  type: CLEAR_USER_SETTINGS,
})
