import styled from 'styled-components'
import color from 'color'

import layout from 'constants/layout'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

import { StyledDetails } from 'components/Avatar/AvatarStyled'

export const StyledTopBar = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  height: ${layout.topBarHeight}px;
  background: ${color(getBrandingColor('primary-color')).mix(color(NEUTRAL_COLOURS.WHITE), 0.1).string()};
  padding: 0 30px;
  position: fixed;
  z-index: 650;
  top: 0;
  left: 0;
  right: 0;
  
  @media(max-width: 660px) {
    padding: 0 15px;
  }
`

export const StyledLeftColumn = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  
  @media(max-width: 580px) {
    width: 50%;
  }
`

export const StyledMiddleColumn = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  @media(max-width: 1140px) {
    flex: 2;
    max-width: calc(100vw - 450px);
  }
  
  @media(max-width: 700px) {
    display: none;
  }
`

export const StyledRightColumn = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  
  @media(max-width: 580px) {
    width: 50%;
  }
`

export const StyledTitle = styled.h1`
  color: ${NEUTRAL_COLOURS.WHITE};
  font-weight: 600;
  font-size: 26px;
  font-family: ${layout.fontFamily};
  letter-spacing: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px;
  
  @media(max-width: 1140px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100vw - 400px);
  }
`

export const StyledLogoContainer = styled.div`
  width: 92px;
  min-width: 92px;
  height: 52px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  background: ${NEUTRAL_COLOURS.WHITE};
  margin-right: 13px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  & > span,
  img {
    object-fit: contain;
    width: 88px !important;
    height: 48px !important;
  }
  
  @media(max-width: 580px) {
    order: 3;
    margin: 0;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }
`

export const StyledBackButton = styled.div`
  display: flex;
  align-items: center;
  margin-right: 25px;
  cursor: pointer;
  
  @media(max-width: 580px) {
    order: 1;
  }
  
  @media(max-width: 440px) {
    margin-right: 15px;
  }
`

export const StyledByBlossomContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 15px;
  padding-right: 17px;
  
  @media(max-width: 768px) {
    padding-right: 10px;
  }
  
  @media(max-width: 580px) {
    display: none;
  }
  
  img {
    width: 140px;
    height: 36px;
    
    @media(max-width: 768px) {
      width: 120px;
      height: 33px;
    }
  }
`

export const StyledProfile = styled.div`
  display: flex;
  align-items: center;
  
  * {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  @media(max-width: 1100px) {
    width: 40px;
    overflow: hidden;
  }
  
  @media(max-width: 580px) {
    margin-right: 15px;
    order: 2;
    flex: 1;
    
    ${StyledDetails} {
      display: none;
    }
  }
  
  @media(max-width: 440px) {
    display: none;
  }
`

export const StyledMobileMenu = styled.button`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: ${getBrandingColor('senary-color')};
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: ${NEUTRAL_COLOURS.WHITE};
  transition: .2s;
  position: relative;
  display: none;
  
  @media(max-width: 580px) {
    display: flex;
  }
  
  ${({ sidebarIsOpen }) => sidebarIsOpen && `
    background: ${NEUTRAL_COLOURS.WHITE};
  `}
`

export const StyledHexagon = styled.div`
  display: none;
  align-items: center;
  margin-right: 10px;
  order: 2;
  
  @media(max-width: 580px) {
    display: flex;
  }
`
