import ServiceBase from 'services/ServiceBase'

import { getQueryString } from 'utils/api'

class ChildSessionsApiClient extends ServiceBase {
  groupedByList = (childId, params) => this.request({
    path: `/children/${childId}/sessions/groupedby?${getQueryString(params)}`,
  })

  list = (childId, params) => this.request({
    path: `/children/${childId}/sessions?${getQueryString(params)}`,
  })

  get = (childId, id, params) => this.request({
    path: `/children/${childId}/sessions/${id}?${getQueryString(params)}`,
  })

  create = (childId, payload) => this.request({
    body: payload,
    method: 'POST',
    path: `/children/${childId}/sessions`,
  })

  update = (childId, id, payload) => this.request({
    body: payload,
    method: 'PATCH',
    path: `/children/${childId}/sessions/${id}`,
  })
}

export default ChildSessionsApiClient
