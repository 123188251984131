import React, { useEffect } from 'react'
import { compose } from 'recompose'

import { SelectProps } from 'components/Select/Select'
import { InfinityDropdownProps } from 'components/InfiniteDropdowns/InfiniteDropdownsModel'

import { withOrganizationService, withOrganizationServiceProps } from 'services/organization'

import { Select } from 'components'

import i18n from 'translations'

// eslint-disable-next-line import-newlines/enforce, max-len
import withInfiniteDropdownHelpers, { withInfiniteDropdownHelpersProps } from '../../legacy/InfiniteDropdowns/withInfiniteDropdown'

export type OrganisationsDropdownProps = withInfiniteDropdownHelpersProps
  & withOrganizationServiceProps
  & SelectProps
  & InfinityDropdownProps

const GROUPS = {
  read: ['organization', 'organization.details'],
}

const OrganisationsDropdown: React.FC<OrganisationsDropdownProps> = ({
  extraFields = [],
  infiniteDropdownHelpers,
  onChange,
  organizationActions,
  value,
  ...rest
}) => {
  useEffect(() => {
    infiniteDropdownHelpers.handleComponentDidMount({
      action: () => organizationActions.getV2({
        onFailed: () => onChange(null),
        onSuccess: infiniteDropdownHelpers.handleComponentDidMountOnSuccess,
        onlyData: true,
        params: [(value as any)?.value || value],
      }),
      value,
    })
  }, [])

  const listAction = ({ newPage, newSearchPhrase, result }) => {
    const criteria = [
      {
        field: 'archived',
        value: 0,
      },
    ]

    if (newSearchPhrase) {
      criteria.push({
        field: 'name',
        value: newSearchPhrase,
      })
    }

    return organizationActions.dropdownList({
      onSuccess: infiniteDropdownHelpers.handleLoadMoreElementsOnSuccessV2({
        extraFields: [...extraFields, 'subdomainSuffix'],
        newPage,
        newSearchPhrase,
        result,
      }),
      params: {
        criteria,
        groups: GROUPS,
        page: newPage,
      },
    })
  }

  return (
    <Select
      {...infiniteDropdownHelpers.getSelectRestParams({ onChange, value, ...rest })}
      loadOptions={infiniteDropdownHelpers.handleLoadMoreElementsV2({ listAction })}
      placeholder={i18n.t('components:InfiniteDropdowns:Organisations:placeholder')}
      searchable
    />
  )
}

const enhance = compose(
  withOrganizationService,
  withInfiniteDropdownHelpers,
)

export default enhance(OrganisationsDropdown)
