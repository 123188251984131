import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledItem = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: auto 200px;
  transition: .2s;
  
  ${({ progressAsCheckbox }) => progressAsCheckbox && `
    cursor: pointer;
  
    &:hover {
     background: ${NEUTRAL_COLOURS.GRAY_TERTIARY};
    }
  `}
`

export const StyledItemDescription = styled.div`
  padding: 15px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
`

export const StyledItemAction = styled.div`
  padding: 10px 10px 10px 0;
  display: flex;
  justify-content: flex-end;
`
