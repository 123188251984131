import { generateBasicActions } from 'utils/service'

import {
  CLEAR_CHILD,
  CREATE_CHILD,
  CREATE_CHILD_FAILED,
  CREATE_CHILD_SUCCESS,
  GET_CHILD,
  GET_CHILD_FAILED,
  GET_CHILD_SUCCESS,
  UPDATE_CHILD,
  UPDATE_CHILD_FAILED,
  UPDATE_CHILD_SUCCESS,
} from './constants'

export const get = (options = {}) => ({ childApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_CHILD_FAILED,
    init: GET_CHILD,
    success: GET_CHILD_SUCCESS,
  },
  options,
  service: childApiClient,
  serviceMethod: 'get',
})

export const create = (options = {}) => ({ childApiClient }) => generateBasicActions.create({
  constants: {
    failed: CREATE_CHILD_FAILED,
    init: CREATE_CHILD,
    success: CREATE_CHILD_SUCCESS,
  },
  options,
  service: childApiClient,
  serviceMethod: 'create',
})

export const update = (options = {}) => ({ childApiClient }) => generateBasicActions.update({
  constants: {
    failed: UPDATE_CHILD_FAILED,
    init: UPDATE_CHILD,
    success: UPDATE_CHILD_SUCCESS,
  },
  options,
  service: childApiClient,
  serviceMethod: 'update',
})

export const clearSingle = () => ({
  type: CLEAR_CHILD,
})
