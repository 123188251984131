import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { UPDATE_NURSERY_SETTINGS_EVENT } from 'services/nurseries/constants'

import eventBus from 'utils/eventBus'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withRouter } from 'services/router'

import GeneralView from './GeneralView'

const NURSERY_SETTINGS_GROUP = {
  read: [
    'assessmentPeriodSettings',
    'nursery.settings',
    'nurserySettings.learning',
    'nurseryLearningSettings',
    'nursery.details',
    'nurserySettings',
  ],
}

class GeneralContainer extends Component {
  componentDidMount() {
    this.getNurseryData()
  }

  componentWillUnmount() {
    const { nurseriesActions } = this.props

    nurseriesActions.clearSingle()
  }

  getNurseryData = () => {
    const { nurseriesActions, nurseryOptions } = this.props

    nurseriesActions.get(nurseryOptions?.id, {
      onSuccess: (response) => {
        eventBus.dispatch(UPDATE_NURSERY_SETTINGS_EVENT, response)
      },
      params: { groups: NURSERY_SETTINGS_GROUP },
    })
  }

  handleSubmitSuccess = (callback) => (response) => {
    this.getNurseryData()

    callback(response)
  }

  handleSubmit = (fields) => (successCallback, failedCallback) => {
    const {
      nurseriesActions,
      nurseriesSelectors,
      nurseriesSingleState,
      nurseryOptions,
    } = this.props

    const payload = nurseriesSelectors.getNurserySettingsPayload({
      fields,
      nurseriesSingleState,
    })

    nurseriesActions.update(nurseryOptions.id, {
      onFailed: failedCallback,
      onSuccess: this.handleSubmitSuccess(successCallback),
      payload,
      silent: true,
    })
  }

  render() {
    const {
      isFetching,
      nurseryLearningSettings,
    } = this.props

    return (
      <GeneralView
        isFetching={isFetching}
        nurseryLearningSettings={nurseryLearningSettings}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseriesSelectors,
  nurseriesSingleState,
  params,
}) => ({
  errorMessages: appSelectors.getErrorMessages(nurseriesSingleState),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState),
  nurseryLearningSettings: nurseriesSelectors.getNurseryLearningSettings(state),
  nurseryLearningSettingsErrors: nurseriesSelectors.getNurserySettingsFormErrors(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const enhance = compose(
  withAppService,
  withNurseriesService,
  withRouter,
  connect(mapState),
)

export default enhance(GeneralContainer)
