import React, { PropsWithChildren } from 'react'

import { LineSvgProps, ResponsiveLine } from '@nivo/line'

interface LineChartProps extends LineSvgProps {
  height: number
  minAndMaxValue?: [number, number]
  width?: number
}

const LineChart: React.FC<PropsWithChildren<LineChartProps>> = ({
  data,
  height,
  minAndMaxValue,
  width,
  ...options
}) => (
  <div style={{ height, width }}>
    <ResponsiveLine
      animate={false}
      areaBaselineValue={0}
      axisBottom={null}
      axisLeft={null}
      axisRight={null}
      axisTop={null}
      colors={{ scheme: 'nivo' }}
      curve="step"
      data={data}
      enableGridX={false}
      enableGridY={false}
      legends={[]}
      margin={{ bottom: 0, left: 0, right: 0, top: 10 }}
      pointBorderColor={{ from: 'serieColor' }}
      pointBorderWidth={0}
      pointColor={{ theme: 'background' }}
      pointLabel="y"
      pointLabelYOffset={-12}
      pointSize={4}
      xScale={{
        type: 'point',
      }}
      yScale={{
        max: minAndMaxValue?.[1] || 'auto',
        min: minAndMaxValue?.[0] || 'auto',
        reverse: false,
        stacked: false,
        type: 'linear',
      }}
      enableArea
      useMesh
      {...options}
    />
  </div>
)

export default LineChart
