import {
  CLEAR_CONTRACT,
  CREATE_CONTRACT,
  CREATE_CONTRACT_FAILED,
  CREATE_CONTRACT_SUCCESS,
  DELETE_CONTRACT,
  DELETE_CONTRACT_FAILED,
  DELETE_CONTRACT_SUCCESS,
  GET_CONTRACT,
  GET_CONTRACT_FAILED,
  GET_CONTRACT_SUCCESS,
  UPDATE_CONTRACT,
  UPDATE_CONTRACT_FAILED,
  UPDATE_CONTRACT_SUCCESS,
} from './constants'

export const create = (options = {}) => ({ contractsApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, payload = {} } = options

  dispatch({ type: CREATE_CONTRACT })

  contractsApiClient.create(payload).then(
    (response) => {
      dispatch({
        payload: response,
        type: CREATE_CONTRACT_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: CREATE_CONTRACT_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const remove = (id, options = {}) => ({ contractsApiClient }) => (dispatch) => {
  const { onSuccess } = options

  dispatch({ type: DELETE_CONTRACT })

  contractsApiClient.delete(id).then(
    (response) => {
      dispatch({
        payload: response,
        type: DELETE_CONTRACT_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: DELETE_CONTRACT_FAILED,
      })
    },
  )
}

export const get = (id, options = {}) => ({ contractsApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {} } = options

  dispatch({ type: GET_CONTRACT })

  contractsApiClient.get(id, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: GET_CONTRACT_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: GET_CONTRACT_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const update = (id, options = {}) => ({ contractsApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {}, payload = {} } = options

  dispatch({ type: UPDATE_CONTRACT })

  contractsApiClient.update(id, payload, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: UPDATE_CONTRACT_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: UPDATE_CONTRACT_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const clear = () => ({
  type: CLEAR_CONTRACT,
})
