import { generateBasicActions } from 'utils/service'

import { CLEAR_CARERS, LIST_CARERS, LIST_CARERS_FAILED, LIST_CARERS_SUCCESS } from './constants'

export const list = (options = {}) => ({ carersApiClient }) => generateBasicActions.list({
  constants: {
    failed: LIST_CARERS_FAILED,
    init: LIST_CARERS,
    success: LIST_CARERS_SUCCESS,
  },
  options,
  service: carersApiClient,
  serviceMethod: 'list',
})

export const clear = () => ({
  type: CLEAR_CARERS,
})
