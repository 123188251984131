import React from 'react'

import InlineEditableField from 'module/Management/components/InlineEditableField'

import i18n from 'translations'

import ShareObservationWithParentsForm, { SHARE_OBSERVATION_WITH_PARENTS_FORM } from './ShareObservationWithParentsForm'

const ShareObservationWithParents = ({ onSubmit, value }) => {
  const initialValues = { shareAllObservationsWithParents: value }

  return (
    <InlineEditableField
      editableComponent={(callback) => (
        <ShareObservationWithParentsForm
          initialValues={initialValues}
          onSubmit={callback}
        />
      )}
      formName={SHARE_OBSERVATION_WITH_PARENTS_FORM}
      subtitle={i18n.t('module:Management:General:SharingWithParents:ShareObservationWithParents:subtitle')}
      title={i18n.t('module:Management:General:SharingWithParents:ShareObservationWithParents:title')}
      value={value}
      inline
      onSubmit={onSubmit}
    />
  )
}

export default ShareObservationWithParents
