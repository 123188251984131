import styled from 'styled-components'

export const StyledNewTag = styled.span`
  display: inline-block;
  padding: 6px;
  border-radius: 50%;
  background-color: #99CFE9;
  margin-right: 10px;
  vertical-align: middle;
`
