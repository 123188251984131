import { generateBasicActions } from 'utils/service'

import {
  ADD_MANUALLY_CHILD_FILES,
  CLEAR_CHILD_FILES_LIST,
  GET_CHILD_FILES_LIST,
  GET_CHILD_FILES_LIST_FAILED,
  GET_CHILD_FILES_LIST_SUCCESS,
  GET_CHILD_FILES_TOTAL_RESULTS_LIST,
  GET_CHILD_FILES_TOTAL_RESULTS_LIST_FAILED,
  GET_CHILD_FILES_TOTAL_RESULTS_LIST_SUCCESS,
  REMOVE_FILE_FROM_LIST,
} from './constants'

export const listChildFiles = (options = {}) => ({ childApiClient }) => generateBasicActions.list({
  constants: {
    failed: GET_CHILD_FILES_LIST_FAILED,
    init: GET_CHILD_FILES_LIST,
    success: GET_CHILD_FILES_LIST_SUCCESS,
  },
  options,
  service: childApiClient,
  serviceMethod: 'listChildFiles',
})

export const listChildFilesForTotalResults = (options = {}) => ({ childApiClient }) => generateBasicActions.list({
  constants: {
    failed: GET_CHILD_FILES_TOTAL_RESULTS_LIST_FAILED,
    init: GET_CHILD_FILES_TOTAL_RESULTS_LIST,
    success: GET_CHILD_FILES_TOTAL_RESULTS_LIST_SUCCESS,
  },
  options,
  service: childApiClient,
  serviceMethod: 'listChildFiles',
})

export const clearFilesList = () => ({
  type: CLEAR_CHILD_FILES_LIST,
})

export const addManuallyChildFiles = (files) => ({
  payload: files,
  type: ADD_MANUALLY_CHILD_FILES,
})

export const removeFileFromList = (file) => ({
  payload: file,
  type: REMOVE_FILE_FROM_LIST,
})
