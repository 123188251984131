import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class ChildMovesApiClient extends ServiceBase {
  list = (params = {}) => this.request({
    path: `/v2/child-moves?${getQueryStringV2(params)}`,
  })

  update = (id, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/child-moves/${id}`,
  })
}

export default ChildMovesApiClient
