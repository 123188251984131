import { compose } from 'recompose'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { generateRoute } from 'utils/routing'
import auth from 'utils/auth'

import { withShellService } from 'services/shell'
import { withRouter } from 'services/router'

import i18n from 'translations'

import FinanceModule from 'module/Finance'
import ChildTransactions from 'module/Children/Child/ChildFinance/ChildFinanceTransactions'

import ChildFinanceView from './ChildFinanceView'

class ChildFinance extends Component {
  componentDidMount() {
    const {
      FinanceGranted,
      TransactionsGranted,
      params: { childId },
      shellActions,
    } = this.props

    shellActions.setSubMenu([
      {
        hidden: !FinanceGranted,
        icon: 'invoices',
        label: i18n.t('module:Children:Child:Finance:Invoicing:title'),
        to: generateRoute('CHILDREN.CHILD.FINANCE.INVOICING', { childId }),
      },
      {
        hidden: !TransactionsGranted,
        icon: 'transactions',
        label: i18n.t('module:Children:Child:Finance:Transactions:title'),
        to: generateRoute('CHILDREN.CHILD.FINANCE.TRANSACTIONS', { childId }),
      },
      {
        hidden: !FinanceGranted,
        icon: 'payment-history',
        label: i18n.t('module:Children:Child:Finance:PaymentHistory:title'),
        to: generateRoute('CHILDREN.CHILD.FINANCE.PAYMENT_HISTORY', { childId }),
      },
    ])
  }

  shouldComponentUpdate(nextProps) {
    const { location: { pathname }, navigate, params: { childId } } = nextProps

    if (generateRoute('CHILDREN.CHILD.FINANCE', { childId }) === pathname) {
      navigate(generateRoute('CHILDREN.CHILD.FINANCE.INVOICING', { childId }))
    }

    return true
  }

  componentWillUnmount() {
    const { shellActions } = this.props

    shellActions.clearSubMenu()
  }

  render() {
    const { children } = this.props

    return (
      <ChildFinanceView>
        {children}
      </ChildFinanceView>
    )
  }
}

const mapState = (state) => ({
  FinanceGranted: auth.SELECTORS.getComponentIsAuthorised(state, FinanceModule),
  TransactionsGranted: auth.SELECTORS.getComponentIsAuthorised(state, ChildTransactions),
})

const enhance = compose(
  withRouter,
  withShellService,
  connect(mapState),
)

export default enhance(ChildFinance)
