import ServiceBase from 'services/ServiceBase'

class MessagingApiClient extends ServiceBase {
  createNewChannel = (body) => this.request({
    body,
    method: 'POST',
    path: '/v2/sync/messaging/channel',
  })

  leaveChannel = (body) => this.request({
    body,
    method: 'POST',
    path: '/v2/sync/messaging/channel/unsubscribe',
  })
}

export default MessagingApiClient
