import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { RootState } from 'core/reducers'

import { injectActions } from 'utils/service'

import actions from './actions'
import selectors from './selectors'
import { NurserySessionsListState } from './list/reducer'
import { NurserySessionsSingleState } from './single/reducer'

export interface withNurserySessionsServiceProps {
  nurserySessionsActions: typeof actions
  nurserySessionsListState: NurserySessionsListState
  nurserySessionsSelectors: typeof selectors
  nurserySessionsSingleState: NurserySessionsSingleState
}

const withNurserySessionsService = <P extends withNurserySessionsServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ nurserySessions }: RootState) => ({ nurserySessions })

  const mapDispatch = injectActions('nurserySessionsActions', actions)

  const Component = ({ nurserySessions, nurserySessionsActions, ...others }) => (
    <WrappedComponent
      nurserySessionsActions={nurserySessionsActions}
      nurserySessionsListState={nurserySessions.list}
      nurserySessionsSelectors={selectors}
      nurserySessionsSingleState={nurserySessions.single}
      {...others as P}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withNurserySessionsService
