import { createSelector } from 'reselect'

const getInvoices = (state) => state.invoices

export const getInvoicesStatistics = createSelector([getInvoices], (state) => state.statistics && state.statistics.data)

export const getTotalBadDebt = createSelector(
  [getInvoicesStatistics],
  (statistics) => {
    if (!statistics) {
      return null
    }

    return statistics.totalBadDebt
  },
)

export const getTotalOutstanding = createSelector(
  [getInvoicesStatistics],
  (statistics) => {
    if (!statistics) {
      return null
    }

    return statistics.totalOutstanding
  },
)
