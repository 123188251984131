import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class NextMontessoriActivitiesApiClient extends ServiceBase {
  create = (params = {}, body) => this.request({
    body,
    method: 'POST',
    path: `/v2/next_montessori_activities?${getQueryStringV2(params)}`,
  })

  get = (id, params) => this.request({
    path: `/v2/next_montessori_activities/${id}?${getQueryStringV2(params)}`,
  })

  update = (id, params = {}, body) => this.request({
    body,
    method: 'PUT',
    path: `/v2/next_montessori_activities/${id}?${getQueryStringV2(params)}`,
  })

  remove = (id, params = {}) => this.request({
    method: 'DELETE',
    path: `/v2/next_montessori_activities/${id}?${getQueryStringV2(params)}`,
  })
}

export default NextMontessoriActivitiesApiClient
