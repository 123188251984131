import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'
import React from 'react'
import { formValueSelector } from 'redux-form'

import { toFloat } from 'utils/data'
import { floatToHoursAndMinutes } from 'utils/date'

import { getNurseryData } from 'services/nurseries/single/selectors/single'
import { getListDataSelectors, getSumOfAllocations } from 'services/legacy/childFundingSessionSet/list/selectors'
import { getFormattedExcludedPeriods } from 'services/nurseryFunding/single/selectors'
import { getFundingOption, getNurseryFundingTypesOptionsSelectors } from 'services/nurseryFunding/list/selectors'

import { Currency } from 'components'

export const getChildFundingSelectors = (state) => state.childFunding

export const getSingleDataSelectors = createSelector(
  [getChildFundingSelectors],
  (state) => state.single.data,
)

export const getItemPreviewDataSelectors = createSelector(
  [getSingleDataSelectors],
  (singleData) => {
    if (!singleData) {
      return null
    }

    const {
      archived,
      hourlyRate,
      id,
      startDate,
      endDate,
      hoursUsed,
      total,
      totalHours,
      funding: { label: fundingLabel, fundingType = {} },
    } = singleData
    const { name: fundingTypeLabel } = fundingType

    const fundingName = `${fundingLabel} - ${fundingTypeLabel}${archived ? ' (Archived)' : ''}`

    return {
      fundingType: fundingName,
      hourlyRate: hourlyRate ? <Currency value={hourlyRate} /> : null,
      hoursUsed,
      id,
      period: `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`,
      total: <Currency value={total} />,
      totalAmount: toFloat(total) || 0,
      totalHours,
    }
  },
)

export const getItemAllocationDataSelectors = createSelector(
  [getSingleDataSelectors],
  (singleData) => {
    if (!singleData) {
      return null
    }

    const {
      amountUsed,
      hoursUsed,
      total,
      totalHours,
    } = singleData

    return {
      amountUsed,
      hoursUsed,
      total,
      totalHours,
    }
  },
)

export const getNurseryFundingOptionsWithCurrentType = (isEdit) => createSelector(
  [getSingleDataSelectors, getNurseryFundingTypesOptionsSelectors],
  (singleData, nurseryFundingOptions) => {
    if (!isEdit || !singleData) {
      return nurseryFundingOptions
    }

    const { funding } = singleData

    if (-1 === _.findIndex(nurseryFundingOptions, { value: funding.id })) {
      return [
        getFundingOption(funding),
        ...(nurseryFundingOptions || []),
      ]
    }

    return nurseryFundingOptions
  },
)

export const getInitialValuesSelector = (isEdit) => createSelector(
  [getSingleDataSelectors, getNurseryFundingOptionsWithCurrentType(isEdit)],
  (singleData, nurseryFundingTypesOptions) => {
    if (!singleData || !nurseryFundingTypesOptions || !nurseryFundingTypesOptions.length) {
      return null
    }

    const { endDate, funding: { id: fundingType }, startDate, totalHours } = singleData

    const totalHoursAndMinutes = floatToHoursAndMinutes(totalHours)
    const fundingTypeOption = _.find(nurseryFundingTypesOptions, { value: fundingType })

    return {
      ...singleData,
      endDate: undefined,
      funding: undefined,
      fundingType: fundingTypeOption,
      period: [startDate, endDate],
      startDate: undefined,
      totalHours: totalHoursAndMinutes,
    }
  },
)

export const isArchivedSelector = createSelector(
  [getSingleDataSelectors],
  (singleData) => {
    if (!singleData) {
      return null
    }

    const { archived } = singleData

    return archived
  },
)

const formSelector = (formName) => formValueSelector(formName)

const fundingTypeSelector = (formName) => (state) => formSelector(formName)(state, 'fundingType')
const hourlyRateSelector = (formName) => (state) => formSelector(formName)(state, 'hourlyRate')
const hoursPerWeekSelector = (formName) => (state) => formSelector(formName)(state, 'settings.hoursPerWeek')
const maxHoursPerDaySelector = (formName) => (state) => formSelector(formName)(state, 'settings.maxHoursPerDay')
const totalHoursSelector = (formName) => (state) => formSelector(formName)(state, 'totalHours')

export const getFundingType = (formName) => createSelector(
  [fundingTypeSelector(formName)],
  (fundingType) => {
    if (!fundingType) {
      return undefined
    }

    return fundingType
  },
)

export const getHourlyRate = (formName) => createSelector(
  [hourlyRateSelector(formName)],
  (hourlyRate) => {
    if (!hourlyRate) {
      return undefined
    }

    return hourlyRate
  },
)

export const getHoursPerWeek = (formName) => createSelector(
  [hoursPerWeekSelector(formName)],
  (hoursPerWeek) => {
    if (!hoursPerWeek) {
      return undefined
    }

    return hoursPerWeek
  },
)

export const getMaxHoursPerDay = (formName) => createSelector(
  [maxHoursPerDaySelector(formName)],
  (maxHoursPerDay) => {
    if (!maxHoursPerDay) {
      return undefined
    }

    return maxHoursPerDay
  },
)

export const getTotalHours = (formName) => createSelector(
  [totalHoursSelector(formName)],
  (totalHours) => {
    if (!totalHours) {
      return undefined
    }

    return totalHours
  },
)

export const getAllowOverrideHoursPerWeek = (formName) => createSelector(
  [getHoursPerWeek(formName)],
  (hoursPerWeek) => {
    if (!hoursPerWeek) {
      return null
    }

    const { allowOverride } = hoursPerWeek

    return allowOverride
  },
)

export const getAllowOverrideMaxHoursPerDay = (formName) => createSelector(
  [getMaxHoursPerDay(formName)],
  (maxHoursPerDay) => {
    if (!maxHoursPerDay) {
      return null
    }

    const { allowOverride } = maxHoursPerDay

    return allowOverride
  },
)

export const getNurseryExcludedPeriods = (formName) => createSelector(
  [getFundingType(formName)],
  (fundingType) => {
    if (!fundingType) {
      return null
    }

    const { excludedPeriods } = fundingType

    return getFormattedExcludedPeriods(excludedPeriods)
  },
)

const getFundingWorkingStartDate = (startDate, formattedOpeningDays) => {
  const fundingWorkingStartDate = moment(startDate)

  while (!_.includes(formattedOpeningDays, fundingWorkingStartDate.format('dddd').toLocaleLowerCase())) {
    fundingWorkingStartDate.add('d', 1)
  }

  return fundingWorkingStartDate.toDate()
}

export const getWeekDaysList = createSelector(
  [getSingleDataSelectors, getNurseryData],
  (childFundingSingleState, nurseryData) => {
    if (!childFundingSingleState || !nurseryData?.nurserySettings?.formattedOpeningDays) {
      return null
    }

    const { nurserySettings } = nurseryData
    const { formattedOpeningDays } = nurserySettings

    const { endDate, startDate } = childFundingSingleState

    const fundingWorkingStartDate = getFundingWorkingStartDate(startDate, formattedOpeningDays)

    const startDateOfTheWeek = moment(fundingWorkingStartDate).startOf('week')
    const endDateOfTheWeek = moment(endDate).endOf('week')

    const weeksCount = Math.ceil(endDateOfTheWeek.diff(startDateOfTheWeek, 'week', true))
    const weekLastDayName = formattedOpeningDays[formattedOpeningDays.length - 1]
    const weekDaysWeekDaysList = []

    for (let index = 0; index < weeksCount; index++) { // eslint-disable-line
      const weekStartDate = moment(startDateOfTheWeek).add(index, 'week')

      const currentDate = moment(weekStartDate)
      while (weekLastDayName !== currentDate.format('dddd').toLowerCase()) {
        currentDate.add(1, 'd')
      }

      const weekEndDate = moment(currentDate)

      weekDaysWeekDaysList.push({
        id: index,
        weekEndDate: weekEndDate.toDate(),
        weekStartDate: weekStartDate.toDate(),
      })
    }

    return weekDaysWeekDaysList
  },
)

export const getChildFundingWithAllocationSummary = createSelector(
  [getSingleDataSelectors, getListDataSelectors],
  (childFunding, childFundingSessionSets) => {
    if (!childFunding) {
      return null
    }

    if (!childFundingSessionSets || !childFundingSessionSets.length) {
      return childFunding
    }

    const { hourlyRate, id } = childFunding

    const currentFundingSessionSet = _.filter(childFundingSessionSets, { funding: { id } })
    const hoursAllocated = _.reduce(currentFundingSessionSet, (totalHours, { allocations }) => (
      totalHours + getSumOfAllocations(allocations)
    ), 0)

    const amountAllocated = hoursAllocated * hourlyRate

    return {
      ...childFunding,
      amountAllocated,
      hoursAllocated,
    }
  },
)
