import React, { PropsWithChildren } from 'react'

import colors, { NEUTRAL_COLOURS } from 'constants/colors'
import { noop } from 'constants/global'

import { getBrandingColor } from 'utils/branding'

import { Hexagon, Icon } from 'components'

import { StyledBadge, StyledLabel, StyledLink, StyledMenuCircle, StyledNewBadge } from './MenuItemStyled'

interface MenuItemProps {
  badgeColor?: string
  badgeValue?: string | number
  disabled?: boolean
  hidden?: boolean
  hiddenTitle?: boolean
  icon?: IconType
  inline?: boolean
  isNew?: boolean
  label?: string
  logo?: string
  onChangeSidebar?: (value: boolean) => void
  onClick?: () => void
  onShowPremiumModal?: () => void
  premium?: boolean
  rightActive?: boolean
  to?: string
}

const MenuItem: React.FC<PropsWithChildren<MenuItemProps>> = ({
  badgeValue,
  badgeColor = '#d0021b',
  disabled,
  hiddenTitle,
  hidden,
  icon,
  isNew,
  inline,
  label,
  logo,
  rightActive,
  premium,
  to,
  onChangeSidebar,
  onClick = noop,
  onShowPremiumModal,
  ...other
}) => {
  const isIndex = '/' === to
  const activeClassName = isIndex ? null : 'active'
  const isLocked = premium && hidden

  const renderPadlock = () => {
    if (!isLocked) {
      return null
    }

    return (
      <StyledBadge
        $color={colors.lockedColor}
        $inline={inline}
      >
        <Icon
          color={NEUTRAL_COLOURS.WHITE}
          height={inline ? 12 : 14}
          icon="padlock"
        />
      </StyledBadge>
    )
  }

  const renderNewBadge = () => {
    if (!isNew) {
      return null
    }

    return (
      <StyledNewBadge>
        <Icon
          color={getBrandingColor('tertiary-color')}
          height={inline ? 14 : 16}
          icon="new"
        />
      </StyledNewBadge>
    )
  }

  const renderValueBadge = () => {
    if (isLocked || !badgeValue) {
      return null
    }

    return (
      <StyledBadge
        $color={badgeColor}
        $inline={inline}
      >
        {badgeValue}
      </StyledBadge>
    )
  }

  const renderIcon = () => {
    if (logo) {
      return (
        <Hexagon>
          <img
            alt={label}
            src={logo}
          />
        </Hexagon>
      )
    }

    return (
      <StyledMenuCircle
        $disabled={disabled}
        $inline={inline}
        $lock={isLocked}
      >
        {renderNewBadge()}
        {renderValueBadge()}
        {renderPadlock()}
        <Icon
          color={NEUTRAL_COLOURS.WHITE}
          cursor={disabled ? 'not-allowed' : 'pointer'}
          height={inline ? 18 : 24}
          icon={icon}
        />
      </StyledMenuCircle>
    )
  }

  const handleOnClick = () => {
    onChangeSidebar(false)

    if (disabled) {
      return false
    }

    if (isLocked) {
      return onShowPremiumModal()
    }

    return onClick()
  }

  return (
    <StyledLink
      $disabled={disabled}
      $hiddenTitle={hiddenTitle}
      $inline={inline}
      $rightActive={rightActive}
      activeClassName={activeClassName}
      onlyActiveOnIndex={isIndex}
      to={!isLocked && to}
      onClick={handleOnClick}
      {...other}
    >
      {renderIcon()}
      <StyledLabel
        $hiddenTitle={hiddenTitle}
        $inline={inline}
        $lock={isLocked}
      >
        {label}
      </StyledLabel>
    </StyledLink>
  )
}

export default MenuItem
