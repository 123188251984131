import React, { PropsWithChildren } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'

import { RootState } from 'core/reducers'
import { SITE_NAME } from 'constants/global'

import { withSubdomainService, withSubdomainServiceProps } from 'services/subdomain'

import i18n from 'translations'

import {
  StyledBox,
  StyledBoxContainer,
  StyledContainer,
  StyledFooter,
  StyledFooterLink,
  StyledHeader,
  StyledLogo,
} from './BoxWrapperStyled'

interface BoxWrapperProps {
  footer?: JSX.Element
}

type BoxWrapperServiceProps = withSubdomainServiceProps

const mapState = (state: RootState, { subdomainSelectors }: BoxWrapperServiceProps) => ({
  customLogo: subdomainSelectors.getCustomLogo(state),
  isBranded: subdomainSelectors.subdomainIsBranded(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type BoxWrapperInnerProps = PropsFromRedux & BoxWrapperServiceProps

type BoxWrapperOuterProps = PropsWithChildren<BoxWrapperProps>

const BoxWrapper: React.FC<BoxWrapperInnerProps & BoxWrapperOuterProps> = ({
  children,
  customLogo,
  footer,
  isBranded,
}) => {
  const renderLogo = () => (
    <StyledHeader>
      {isBranded && customLogo
        ? <StyledLogo alt={SITE_NAME} src={customLogo} custom />
        : <StyledLogo alt={SITE_NAME} src="/images/blossom-logo.svg" />}
    </StyledHeader>
  )

  const renderByBlossom = () => {
    if (!isBranded || !customLogo) {
      return null
    }

    return (
      <StyledFooter>
        <img
          alt={i18n.t('module:Authentication:components:BoxWrapper:by', { siteName: SITE_NAME })}
          src="/images/by-blossom.svg"
        />
      </StyledFooter>
    )
  }

  return (
    <StyledContainer>
      <StyledBoxContainer>
        <StyledBox>
          {renderLogo()}
          {children}
          {footer && (
            <StyledFooterLink>
              {footer}
            </StyledFooterLink>
          )}
        </StyledBox>
        {renderByBlossom()}
      </StyledBoxContainer>
    </StyledContainer>
  )
}

const enhance = compose(
  withSubdomainService,
  connector,
)

export default enhance(BoxWrapper)
