import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { getBackendErrors } from 'utils/backendErrors'

import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withSortingUtils } from 'services/utils/sorting'
import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withSnackbarService } from 'services/utils/snackbar'

import i18n from 'translations'

import ManagementReferenceCodesView from './ManagementReferenceCodesView'
import { getTableData } from './helpers'

class ManagementReferenceCodesContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      codeReferences: {},
      currentlyEditedValue: null,
      search: null,
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { childActions } = this.props

    childActions.clear()
  }

  fetch = () => {
    const { childActions, childSelectors, paginationUtils, sortingUtils } = this.props
    const { search } = this.state
    const { page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const params = {
      criteria: childSelectors.getCriteria({
        displayName: search,
        isArchived: false,
      }),
      order: { sortField, sortOrder },
      page,
    }

    childActions.list({ params })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleSearchChange = (search) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState({ search }, () => onPageChange(this.fetch)(1))
  }

  handleSortChange = (key) => {
    const { paginationUtils, sortingUtils } = this.props
    const { onSortChange } = sortingUtils
    const { onPageChange } = paginationUtils

    onSortChange(onPageChange(this.fetch)(1))(key)
  }

  handleCodeReferenceFocus = (e) => {
    const value = e.target.value

    this.setState((prevState) => ({
      ...prevState,
      currentlyEditedValue: value,
    }))
  }

  handleCodeReferenceBlurOnSuccess = (name) => {
    const { snackbarActions } = this.props

    snackbarActions.show({
      message: i18n.t('module:Management:Finance:ReferenceCodes:snackbarMessage', { name }),
    })
  }

  handleCodeReferenceBlurOnFail = (response, id) => {
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    if (errors.codeReference) {
      return this.setState((prevState) => ({
        ...prevState,
        codeReferences: {
          ...prevState.codeReferences,
          [id]: {
            ...prevState.codeReferences[id],
            error: errors.codeReference,
          },
        },
      }))
    }

    return null
  }

  handleCodeReferenceBlur = (e, id, name) => {
    const { childActions } = this.props
    const { codeReferences, currentlyEditedValue } = this.state

    const value = e.target.value
    const body = { codeReference: value }

    if (value) {
      if (!codeReferences?.[id]?.edited || currentlyEditedValue === value) {
        return null
      }

      return childActions.update({
        body,
        onFailed: (response) => this.handleCodeReferenceBlurOnFail(response, id),
        onSuccess: () => this.handleCodeReferenceBlurOnSuccess(name),
        onlyData: true,
        params: [id],
      })
    }

    return this.setState((prevState) => ({
      ...prevState,
      codeReferences: {
        ...prevState.codeReferences,
        [id]: {
          ...prevState.codeReferences[id],
          error: i18n.t('utils:Validation:cannotBeBlank'),
        },
      },
    }))
  }

  handleCodeReferenceChange = (e, id) => {
    const value = e.target.value

    this.setState((prevState) => ({
      ...prevState,
      codeReferences: {
        ...prevState.codeReferences,
        [id]: {
          edited: true,
          error: null,
          value,
        },
      },
    }))
  }

  render() {
    const {
      childrenList,
      errorMessages,
      isFetching,
      paginationUtils,
      sortingUtils,
      totalResults,
    } = this.props
    const { codeReferences } = this.state
    const { getPageCount, page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const tableData = getTableData({
      childrenList,
      codeReferences,
      onBlur: this.handleCodeReferenceBlur,
      onChange: this.handleCodeReferenceChange,
      onFocus: this.handleCodeReferenceFocus,
    })
    const pageCount = getPageCount(totalResults)

    return (
      <ManagementReferenceCodesView
        errorMessages={errorMessages}
        isLoading={isFetching}
        page={page}
        pageCount={pageCount}
        sortField={sortField}
        sortOrder={sortOrder}
        tableData={tableData}
        totalResults={totalResults}
        onPageChange={this.handlePageChange}
        onSearchChange={this.handleSearchChange}
        onSortChange={this.handleSortChange}
      />
    )
  }
}

const mapState = (state, { appSelectors, childListState, childSelectors }) => ({
  childrenList: childSelectors.getChildListDataSelector(state),
  errorMessages: appSelectors.getErrorMessages(childListState),
  isFetching: appSelectors.getIsFetching(childListState),
  totalResults: appSelectors.getTotalResults(childListState),
})

const enhance = compose(
  withAppService,
  withChildService,
  withPaginationUtils,
  withRouterUtils,
  withSnackbarService,
  withSortingUtils,
  connect(mapState),
)

export default enhance(ManagementReferenceCodesContainer)
