import { Profile } from 'services/authentication/models'
import { LOGOUT_SUCCESSFUL } from 'services/legacy/auth/constants'

import { generateBasicActions } from 'utils/service'
import eventBus from 'utils/eventBus'

import CookieIdentityService from 'core/services/CookieIdentityService'
import { findDomain, findDomainSuffix, getEffectiveSubdomain, hostname } from 'services/subdomain/single/selectors'

import { identifyAnonymous } from 'analytics'

import { properties } from 'app-config'

import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_SUCCESS,
  GET_AUTH_ME,
  GET_AUTH_ME_FAILED,
  GET_AUTH_ME_SUCCESS,
  GET_LOGIN,
  GET_LOGIN_FAILED,
  GET_LOGIN_SUCCESS,
  INSTALL_DEVICE_TOKEN,
  INSTALL_DEVICE_TOKEN_FAILED,
  INSTALL_DEVICE_TOKEN_SUCCESS,
  REGISTER,
  REGISTER_FAILED,
  REGISTER_SUCCESS,
  REMOVE_IDENTITY,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS,
  SET_IDENTITY,
} from './constants'

const domainSuffix = findDomainSuffix(hostname)
const subdomain = getEffectiveSubdomain(hostname, domainSuffix)

export const login = (options = {}) => ({ authenticationApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: GET_LOGIN_FAILED,
      init: GET_LOGIN,
      success: GET_LOGIN_SUCCESS,
    },
    options,
    service: authenticationApiClient,
    serviceMethod: 'login',
  })
)

export const installDeviceToken = (options = {}) => ({ authenticationApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: INSTALL_DEVICE_TOKEN_FAILED,
      init: INSTALL_DEVICE_TOKEN,
      success: INSTALL_DEVICE_TOKEN_SUCCESS,
    },
    options,
    service: authenticationApiClient,
    serviceMethod: 'installDeviceToken',
  })
)

export const authMe = (options = {}) => ({ authenticationApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: GET_AUTH_ME_FAILED,
      init: GET_AUTH_ME,
      success: GET_AUTH_ME_SUCCESS,
    },
    options,
    service: authenticationApiClient,
    serviceMethod: 'authMe',
  })
)

export const setIdentity = ({ profile, token }: { profile?: Profile, token?: string }) => {
  const payload = {} as { profile: any, token: string }

  if (token) {
    const domain = findDomain()
    payload.token = token

    CookieIdentityService.setIdentity({ token }, { domain })
  }

  if (profile) {
    payload.profile = profile
  }

  return {
    payload,
    type: SET_IDENTITY,
  }
}

export const logout = (params?) => {
  const { key } = params || {}
  const domain = findDomain()

  eventBus.dispatch(LOGOUT_SUCCESSFUL)

  identifyAnonymous(subdomain)
  CookieIdentityService.removeIdentity({ domain, key })
  CookieIdentityService.removeIdentity({
    domain,
    key: properties.pinIdentityKey,
  })

  return {
    type: REMOVE_IDENTITY,
  }
}

export const resetPassword = (options = {}) => ({ authenticationApiClient }) => (
  generateBasicActions.get({
    constants: {
      failed: RESET_PASSWORD_FAILED,
      init: RESET_PASSWORD,
      success: RESET_PASSWORD_SUCCESS,
    },
    options,
    service: authenticationApiClient,
    serviceMethod: 'resetPassword',
  })
)

export const changePassword = (options = {}) => ({ authenticationApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: CHANGE_PASSWORD_FAILED,
      init: CHANGE_PASSWORD,
      success: CHANGE_PASSWORD_SUCCESS,
    },
    options,
    service: authenticationApiClient,
    serviceMethod: 'changePassword',
  })
)

export const register = (options = {}) => ({ authenticationApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: REGISTER_FAILED,
      init: REGISTER,
      success: REGISTER_SUCCESS,
    },
    options,
    service: authenticationApiClient,
    serviceMethod: 'register',
  })
)
