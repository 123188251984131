import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'

import { DAILY_DIARY_DATE_FORMAT, TIME_TYPE } from 'services/legacy/dailyDiary/constants'
import { ROLES } from 'constants/security'

import { EVENTS, logEvent } from 'analytics'

import { generateRoute } from 'utils/routing'
import { isPastDay as isPastDayUtil } from 'utils/date'

import { withFoodMenuService } from 'services/legacy/foodMenu'
import { withShellService } from 'services/shell'
import { withRegisterService } from 'services/legacy/register'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withRouter } from 'services/router'

import DailyDiaryView from './DailyDiaryView'

const DAILY_DIARY_TIME_TYPE_FILTER_KEY = 'DAILY_DIARY_TIME_TYPE_FILTER'

class DailyDiaryContainer extends Component {
  constructor(props) {
    super(props)

    const localStorageTimeTypeFilter = window.localStorage.getItem(DAILY_DIARY_TIME_TYPE_FILTER_KEY)

    const {
      location: {
        query: {
          date,
          room,
          timeType = localStorageTimeTypeFilter || TIME_TYPE.TIMED_IN_FOR_THE_DAY,
        },
      },
      setLocationQuery,
    } = props

    const finalDate = date && moment(date, DAILY_DIARY_DATE_FORMAT).isValid()
      ? date
      : moment().format(DAILY_DIARY_DATE_FORMAT)

    this.state = {
      filters: {
        date: finalDate,
        isPastDay: isPastDayUtil(finalDate),
        room,
        timeType,
      },
    }

    setLocationQuery({ timeType }, ['page'])
    this.indexRedirect(props, { timeType })
  }

  componentDidMount() {
    this.fetchRegisters()
  }

  shouldComponentUpdate(nextProps) {
    this.indexRedirect(nextProps)

    return true
  }

  componentWillUnmount() {
    const { registerActions } = this.props

    registerActions.clear()
  }

  indexRedirect = (props, filters) => {
    const { location: { pathname }, navigate } = props
    const { timeType } = filters || {}

    if (generateRoute('DAILY_DIARY.INDEX') === pathname) {
      navigate({
        pathname: generateRoute('DAILY_DIARY.MEALS'),
        search: timeType ? `?timeType=${timeType}` : '',
      })
    }
  }

  fetchRegisters = () => {
    const { registerActions, registerSelectors } = this.props
    const { filters } = this.state

    const criteria = registerSelectors.getDailyDiaryCriteria(filters)

    const params = {
      criteria,
      includes: ['none'],
    }

    return registerActions.list(params)
  }

  handleChangeDate = (date) => {
    const { setLocationQuery } = this.props

    const finalDate = date ? moment(date).format(DAILY_DIARY_DATE_FORMAT) : moment().format(DAILY_DIARY_DATE_FORMAT)
    const isPastDay = isPastDayUtil(date)

    this.setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        date: finalDate,
        isPastDay,
        timeType: isPastDay ? TIME_TYPE.TIMED_IN_FOR_THE_DAY : prev.filters.timeType,
      },
    }), this.fetchRegisters)

    logEvent(EVENTS.DIARY_FILTER_USED, { type: 'date' })

    setLocationQuery({
      date: finalDate,
    }, ['page'])
  }

  handleChangeRoom = (room) => {
    const { setLocationQuery } = this.props

    this.setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        room: room?.value,
      },
    }), this.fetchRegisters)

    logEvent(EVENTS.DIARY_FILTER_USED, { type: 'room' })

    setLocationQuery({
      room: room?.value,
    }, ['page'])
  }

  handleChangeTimeType = (timeType) => {
    const { setLocationQuery } = this.props

    if (timeType?.value) {
      window.localStorage.setItem(DAILY_DIARY_TIME_TYPE_FILTER_KEY, timeType.value)
    }

    this.setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        timeType: timeType.value,
      },
    }), this.fetchRegisters)

    logEvent(EVENTS.DIARY_FILTER_USED, { type: 'time' })

    setLocationQuery({
      timeType: timeType.value,
    }, ['page'])
  }

  render() {
    const { children, location: { pathname, search } } = this.props
    const {
      filters: {
        date = moment().format(DAILY_DIARY_DATE_FORMAT),
        room,
        timeType,
        isPastDay,
      },
    } = this.state

    return (
      <DailyDiaryView
        currentPathname={`${pathname}${search}`}
        date={moment(date, DAILY_DIARY_DATE_FORMAT)}
        isPastDay={isPastDay}
        room={room}
        timeType={timeType}
        onChangeDate={this.handleChangeDate}
        onChangeRoom={this.handleChangeRoom}
        onChangeTimeType={this.handleChangeTimeType}
      >
        {children}
      </DailyDiaryView>
    )
  }
}

DailyDiaryContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.ROOM_LEADER,
    ROLES.SENIOR_TEACHER,
    ROLES.TEACHER,
    ROLES.NURSERY_ADMIN,
  ],
}

const enhance = compose(
  withFoodMenuService,
  withPaginationUtils,
  withRouterUtils,
  withRegisterService,
  withRouter,
  withShellService,
)

export default enhance(DailyDiaryContainer)
