import _ from 'lodash'

import React from 'react'

import PlanView from 'module/Finance/components/PlanView'

import i18n from 'translations'

const MAX_VISIBLE_FUNDINGS = 1

const FinanceCurrentPlanFundings = ({ childFundings }) => {
  if (!childFundings || !childFundings.length) {
    return (
      <PlanView>
        <PlanView.Icon icon="funding" />
        <PlanView.Content>
          <PlanView.SubTitle
            subtitle={i18n.t('module:Children:Child:Finance:PlanView:Summary:fundingEmptyState')}
          />
        </PlanView.Content>
      </PlanView>
    )
  }

  const moreFundingCount = childFundings.length - MAX_VISIBLE_FUNDINGS
  const visibleFundings = childFundings.slice(0, MAX_VISIBLE_FUNDINGS)

  const renderFunding = ({ id, label }) => (
    <PlanView.Item key={id}>
      {label}
    </PlanView.Item>
  )

  const renderMoreFundings = () => {
    if (0 >= moreFundingCount) {
      return null
    }

    return (
      <PlanView.Item>
        <PlanView.Highlight>
          {i18n.t('module:Children:Child:Finance:PlanView:Summary:more', { count: moreFundingCount })}
          &nbsp;
        </PlanView.Highlight>
        {i18n.t('module:Children:Child:Finance:PlanView:Summary:funding')}
      </PlanView.Item>
    )
  }

  return (
    <PlanView>
      <PlanView.Icon icon="funding" />
      <PlanView.Content>
        {_.map(visibleFundings, renderFunding)}
        {renderMoreFundings()}
      </PlanView.Content>
    </PlanView>
  )
}

export default FinanceCurrentPlanFundings
