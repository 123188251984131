import _ from 'lodash'
import browser from 'browser-detect'

import React, { useEffect, useState } from 'react'

import { BROWSERS } from 'constants/browsers'

import { usePrevious } from 'utils/hooks'

import { Button, TextAreaField, Tooltip } from 'components'

import i18n from 'translations'

import { StyledEditorWrapper, StyledInvalidLabel, StyledRightColumn } from './EditorStyled'

const LS_KEY = 'MESSAGING_EDITOR_VALUE'

const MAX_CHARACTERS = 4000

const Editor = ({
  channelId,
  disabled,
  onSubmit,
}) => {
  const { name } = browser()
  const [value, setValue] = useState('')
  const invalid = value.length > MAX_CHARACTERS
  const initialize = () => {
    const values = localStorage.getItem(LS_KEY)
    try {
      const parsedValues = values ? JSON.parse(values) : {}

      setValue(parsedValues[channelId] || '')
    } catch (e) {}
  }

  const prevState = usePrevious({ channelId })

  useEffect(() => {
    const { channelId: prevChannelId } = prevState || {}

    if (prevChannelId) {
      const values = localStorage.getItem(LS_KEY)
      let parsedValues = JSON.parse(values) || {}
      parsedValues[prevChannelId] = value
      parsedValues = _.pickBy(parsedValues, (v) => !!v)

      localStorage.setItem(LS_KEY, JSON.stringify(parsedValues))
    }

    initialize()
  }, [channelId])

  const handleSubmit = () => {
    if (value && !invalid) {
      onSubmit(value)
      setValue('')
    }
  }

  const handleKeyDown = (e) => {
    if (13 === e.keyCode) {
      e.preventDefault()

      if (false === e.shiftKey && false === e.ctrlKey && false === e.altKey) {
        handleSubmit()
      } else {
        setValue(`${e.target.value}\n`)
      }
    }
  }

  return (
    <StyledEditorWrapper
      browserName={name}
    >
      <TextAreaField
        autosize={name !== BROWSERS.SAFARI}
        disabled={disabled}
        placeholder={i18n.t('module:Messaging:MessagingList:components:Editor:placeholder')}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <StyledRightColumn>
        {invalid && (
          <StyledInvalidLabel>
            {` -${value.length - MAX_CHARACTERS}`}
          </StyledInvalidLabel>
        )}
        <Tooltip
          title={invalid && (
            i18n.t('module:Messaging:MessagingList:components:Editor:maxCharactersError', {
              characters: value.length - MAX_CHARACTERS,
            })
          )}
        >
          <Button
            disabled={disabled || invalid}
            label={i18n.t('global:Send')}
            margin="-4px 0 -2px"
            onClick={handleSubmit}
          />
        </Tooltip>
      </StyledRightColumn>
    </StyledEditorWrapper>
  )
}

export default React.memo(Editor)
