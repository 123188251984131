import _ from 'lodash'
import moment from 'moment'
import { nest } from 'utils/flatnest'

import { Option } from 'constants/models'

import { ActionType } from 'services/booking/periods/models'
import { FormFields } from './models'

export const getAcademicYearOptions = (academicYearList): Option[] => {
  const mapAcademicYear = ({ id, name }) => ({
    label: name,
    value: id,
  })

  return _.map(academicYearList, mapAcademicYear)
}

export const getInitialValues = (periods, nurseryAcademicYear): FormFields => {
  if (!periods?.length && !nurseryAcademicYear) {
    return null
  }

  return {
    closurePeriods: _.map(periods, ({ closureDayType, id, name, periodTimes }) => ({
      closureDayType: {
        label: closureDayType.name,
        value: closureDayType.id,
      },
      dateRange: [moment(periodTimes[0].startDate), moment(periodTimes[0].endDate)],
      id,
      name,
    })),
    nurseryAcademicYear: {
      label: nurseryAcademicYear.name,
      value: nurseryAcademicYear.id,
    },
  }
}

export const getFormErrors = (data, closurePeriods) => {
  const errors = {
    closurePeriods: [],
  }
  const editCount = _.filter(closurePeriods, (({ id }) => !!id))?.length || 0

  _.each(data, ({ exception, key, periodEvent }) => {
    const { action, period: periodData } = periodEvent
    const { error } = exception
    const { extra } = error
    const { id } = periodData

    const { period } = nest(extra)
    const { periodTimes } = period

    const errorIndex = ActionType.create === action
      ? editCount + key
      : _.findIndex(closurePeriods, ({ id: closureId }) => closureId === id)

    errors.closurePeriods[errorIndex] = {
      dateRange: periodTimes[0] || periodTimes[1],
    }
  })

  return errors
}
