import React from 'react'

import { generateRoute } from 'utils/routing'

import { Callout, Spinner } from 'components'
import Settings from 'module/Settings/components'

import i18n from 'translations'

const SettingsInvoicesDetailsV3View = ({
  FinanceAutomationGranted,
  errorMessages,
  invoicesSettings,
  isLoading,
  onSubmit,
}) => {
  if (isLoading) {
    return <Spinner />
  }

  const hideAutomationOptions = !FinanceAutomationGranted

  return (
    <div>
      <Callout content={errorMessages} error />
      <Settings.InvoiceSettingV3
        annualisedSettingsConfig={{
          editButtonLink: generateRoute('SETTINGS.INVOICES.ANNUALISED_EDIT'),
          subtitle: i18n.t('module:Management:Finance:Annualised:subtitle'),
          title: i18n.t('module:Management:Finance:Annualised:title'),
        }}
        description={i18n.t('module:Settings:Invoice:InvoiceDetails:subtile')}
        hideAutomationOptions={hideAutomationOptions}
        invoicesSettings={invoicesSettings}
        title={i18n.t('module:Settings:Invoice:InvoiceDetails:title')}
        hideAutoCalculateStartDate
        hideGlobalOption
        hideInvoiceNumberOptions
        hidePaymentReminders
        onSubmit={onSubmit}
      />
    </div>
  )
}

export default SettingsInvoicesDetailsV3View
