import React from 'react'

import { generateRoute } from 'utils/routing'

import { FolderList, Page } from 'components'

import i18n from 'translations'

const ManagementMenuItemsView = () => (
  <Page.Section title={i18n.t('module:Management:MenuItems:title')}>
    <FolderList>
      <FolderList.Item
        icon="meals"
        title={i18n.t('module:Management:MenuItems:Meals:title')}
        to={generateRoute('MANAGEMENT.MENU_ITEMS.MEALS')}
      />
      <FolderList.Item
        icon="snacks"
        title={i18n.t('module:Management:MenuItems:Snacks:title')}
        to={generateRoute('MANAGEMENT.MENU_ITEMS.SNACKS')}
      />
    </FolderList>
  </Page.Section>
)

export default ManagementMenuItemsView
