import styled from 'styled-components'

interface StyledArcContainerProps {
  $size?: number
}

export const StyledArcContainer = styled.div<StyledArcContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  
  ${({ $size }) => $size && `
    width: ${$size}px;
    height: ${$size}px;
  `}
`

interface StyledContentProps {
  $fontSize: number
}

export const StyledContent = styled.div<StyledContentProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-weight: 600;
  font-size: ${({ $fontSize = 28 }) => $fontSize}px;
`

interface StyledSvgProps {
  $size?: number
}

export const StyledSvg = styled.svg<StyledSvgProps>`
  ${({ $size }) => $size && `
    width: ${$size}px;
    height: ${$size}px;
  `}
`

export const StyledG = styled.g`
  transform: scale(.2) translateX(17px) translateY(20px);
`

interface StyledBackgroundPathProps {
  $strokeBackground?: string
}

export const StyledBackgroundPath = styled.path<StyledBackgroundPathProps>`
  stroke-width: 34;
  stroke-linecap: round;
  fill: none;

  ${({ $strokeBackground }) => $strokeBackground && `
    stroke: ${$strokeBackground};
  `};
`

interface StyledProgressPathProps {
  $strokeBackground?: string
}

export const StyledProgressPath = styled.path<StyledProgressPathProps>`
  stroke-width: 34.5;
  stroke-linecap: round;
  fill: none;
  stroke-dasharray: 396;
  stroke-dashoffset: 396;

  ${({ $strokeBackground }) => $strokeBackground && `
    stroke: ${$strokeBackground};
  `};
`
