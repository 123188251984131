import { BasicActionOptionsProps } from 'constants/service'

import { generateBasicActions } from 'utils/service'

import {
  CLEAR_STRIPE_INTEGRATION,
  CREATE_STRIPE_INTEGRATION,
  CREATE_STRIPE_INTEGRATION_FAILED,
  CREATE_STRIPE_INTEGRATION_SUCCESS,
  UPDATE_STRIPE_CAPABILITY,
  UPDATE_STRIPE_CAPABILITY_FAILED,
  UPDATE_STRIPE_CAPABILITY_SUCCESS,
} from './constants'

export const createStripeIntegration = (options = {}) => ({ nurseryIntegrationsApiClient }) => (
  generateBasicActions.create({
    constants: {
      failed: CREATE_STRIPE_INTEGRATION_FAILED,
      init: CREATE_STRIPE_INTEGRATION,
      success: CREATE_STRIPE_INTEGRATION_SUCCESS,
    },
    options,
    service: nurseryIntegrationsApiClient,
    serviceMethod: 'createStripeIntegration',
  })
)

export const updateStripeCapability = (options: BasicActionOptionsProps) => ({ nurseryIntegrationsApiClient }) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_STRIPE_CAPABILITY_FAILED,
      init: UPDATE_STRIPE_CAPABILITY,
      success: UPDATE_STRIPE_CAPABILITY_SUCCESS,
    },
    options,
    service: nurseryIntegrationsApiClient,
    serviceMethod: 'updateStripeCapability',
  })
)

export const clearStripeIntegration = () => ({
  type: CLEAR_STRIPE_INTEGRATION,
})
