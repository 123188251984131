import { FEATURE_FLAGS } from 'constants/security'
import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withOrganizationSessionsService } from 'services/legacy/organizationSessions'
import { withSecurityService } from 'services/security'
import { withNurseriesService } from 'services/nurseries'
import { withShellService } from 'services/shell'

import { withRouterUtils } from 'services/utils/router'
import SettingsSessionsAdd from 'module/Settings/SettingsSessions/v2/SettingsSessionsAdd'

import i18n from 'translations'

import SettingsSessionsView from './SettingsSessionsView'
import { getSessionsTableData } from './helpers'

const SettingsSessionsContainer = ({
  SettingsSessionsAddGranted,
  errorMessages,
  isFinanceV3Enabled,
  isOrganizationContext,
  location,
  nurseriesActions,
  organizationSessionsActions,
  organizationSessionsListState,
  organizationSessionsSelectors,
  params,
  setLocationQuery,
  shellActions,
}) => {
  const { query } = location || {}
  const { nurseryId } = params || {}
  const { getCriteriaSelector } = organizationSessionsSelectors
  const isFinanceV3 = isOrganizationContext && isFinanceV3Enabled

  const [statusFilter, setStatusFilter] = useState(query?.statusFilter || 'ACTIVE')
  const [nursery, setNursery] = useState(null)

  const fetch = () => {
    const criteria = getCriteriaSelector({ statusFilter })

    setLocationQuery({ statusFilter })
    organizationSessionsActions.listSessions({ criteria })
  }

  useEffect(() => {
    shellActions.setRouteTitle({
      name: 'SETTINGS.SESSIONS.NURSERY',
      title: i18n.t('module:Settings:Sessions:List:sessions'),
    })
  }, [])

  useEffect(() => {
    fetch()
  }, [statusFilter])

  const handleGetNurserySuccess = ({ data }) => {
    setNursery(data)
    shellActions.setRouteTitle({
      name: 'SETTINGS.SESSIONS.NURSERY',
      title: i18n.t('module:Settings:Sessions:List:sessionsFor', { name: data.name }),
    })
  }

  useEffect(() => {
    if (isFinanceV3) {
      nurseriesActions.get(nurseryId, {
        onSuccess: handleGetNurserySuccess,
        onlyData: true,
      })
    }
  }, [isFinanceV3])

  const handleStatusFilterChange = (newStatusFilter) => {
    setStatusFilter(newStatusFilter ? newStatusFilter.value : null)
  }

  const sessions = organizationSessionsListState.data
  const isLoading = organizationSessionsListState.isFetching || !sessions
  const isEmpty = organizationSessionsListState.isEmpty && !statusFilter
  const sessionsTableData = getSessionsTableData(sessions)

  return (
    <SettingsSessionsView
      SettingsSessionsAddGranted={SettingsSessionsAddGranted}
      errorMessages={errorMessages}
      isEmpty={isEmpty}
      isFinanceV3={isFinanceV3}
      isLoading={isLoading}
      nursery={nursery}
      sessions={sessionsTableData}
      statusFilter={statusFilter}
      onStatusFilterChange={handleStatusFilterChange}
    />
  )
}

const mapState = (state, { appSelectors, organizationSessionsListState, securitySelectors }) => ({
  SettingsSessionsAddGranted: auth.SELECTORS.getComponentIsAuthorised(state, SettingsSessionsAdd),
  errorMessages: appSelectors.getErrorMessages(organizationSessionsListState),
  isFinanceV3Enabled: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.FINANCE_V3],
  }),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
})

const enhance = compose(
  withAppService,
  withOrganizationSessionsService,
  withSecurityService,
  withShellService,
  withNurseriesService,
  withRouterUtils,
  connect(mapState),
)

export default enhance(SettingsSessionsContainer)
