import _ from 'lodash'

import { createSelector } from 'reselect'

import { COMPLETED_STATE, WORKING_ON_STATE } from 'services/legacy/frameworkProgressLevels/constants'

const getCohortTracking = (state) => state.cohortTracking

const getCohortTrackingComparisonReportSingleSelector = createSelector(
  [getCohortTracking],
  (state) => state.comparisonReportSingle,
)

export const getComparisonReport = createSelector(
  [getCohortTrackingComparisonReportSingleSelector],
  (state) => state.data || [],
)

export const getComparisonReportTableData = (selectedProgressLevel, comparisonTableFilter) => createSelector(
  [getComparisonReport],
  (state) => {
    if (!state) {
      return {}
    }

    const { data, frameworkGroup, selectedChildStateIds } = state
    const filterByProgressLevel = _.find(comparisonTableFilter?.data, ({ progressLevel }) => (
      selectedProgressLevel === progressLevel
    ))

    const filter = filterByProgressLevel?.data || data

    return _.filter(filter, (item) => {
      const potentialCorrectAgeBand = _.find(item.report.ageBands, (ageBand) => (
        ageBand.frameworkGroup.id === frameworkGroup.id
      ))

      const firstCondition = potentialCorrectAgeBand?.progressLevel?.id === selectedProgressLevel

      if (!selectedChildStateIds?.length) {
        return firstCondition
      }

      return selectedChildStateIds.includes(item?.child?.id) && firstCondition
    })
  },
)

export const filterGenderComparisonStatements = (report, frameworkGroup) => {
  let items = [...report.items]
  const result = {
    [COMPLETED_STATE]: [],
    [WORKING_ON_STATE]: [],
  }

  if (!report) {
    return result
  }

  if (frameworkGroup) {
    const { categories } = frameworkGroup

    items = _.filter(items, ({ frameworkItem: { category } }) => (
      _.find(categories, ({ id }) => (
        category.id === id
      ))
    ))
  }

  result[COMPLETED_STATE] = _.filter(items, ({ progressLevel }) => (
    progressLevel.state === COMPLETED_STATE
  ))
  result[WORKING_ON_STATE] = _.filter(items, ({ progressLevel }) => (
    progressLevel.state === WORKING_ON_STATE
  ))

  return result
}
