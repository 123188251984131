import React from 'react'

import { Button, StickyFooter } from 'components'

import i18n from 'translations'

interface FormStickyFooterProps {
  cancelLabel?: string
  cancelLink?: string
  isSubmitting?: boolean
  onCancelClick?: () => void
  onSubmitClick?: () => void
  secondary?: boolean
  submitDisabled?: boolean
  submitLabel?: string
}

const FormStickyFooter: React.FC<FormStickyFooterProps> = ({
  cancelLabel = i18n.t('global:Cancel'),
  cancelLink,
  isSubmitting,
  secondary,
  submitDisabled,
  submitLabel = i18n.t('global:Submit'),
  onCancelClick,
  onSubmitClick,
}) => (
  <StickyFooter>
    <Button
      disabled={isSubmitting}
      edge="start"
      hierarchy="tertiary"
      label={cancelLabel}
      to={cancelLink}
      negativeMargins
      onClick={onCancelClick}
    />
    <StickyFooter.Flex />
    {secondary && (
      <Button
        hierarchy="secondary"
        negativeMargins
      />
    )}
    <Button
      disabled={submitDisabled}
      isLoading={isSubmitting}
      label={submitLabel}
      submit={!onSubmitClick}
      negativeMargins
      onClick={onSubmitClick}
    />
  </StickyFooter>
)

export default FormStickyFooter
