import React, { PropsWithChildren } from 'react'

import { Avatar, Typography } from 'components'

import { StyledAvatarItem } from '../CommentDrawerStyled'

interface AvatarItemProps {
  author: {
    name?: string
    photo?: string
  }
  label: string
}

const AvatarItem: React.FC<PropsWithChildren<AvatarItemProps>> = ({
  author,
  label,
}) => (
  <StyledAvatarItem>
    <Typography margin="0 10px 0 0" variant="span">
      {label}
    </Typography>
    <Avatar
      avatarSize="small"
      initials={[author.name]}
      src={author.photo}
      title={(
        <Typography fontSize={14} primary>
          {author.name}
        </Typography>
      )}
    />
  </StyledAvatarItem>
)

export default AvatarItem
