import _ from 'lodash'
import color from 'color'

import React from 'react'
import { components } from 'react-select'

import { Option as OptionProps } from 'constants/models'
import colors, { NEUTRAL_COLOURS } from 'constants/colors'
import { ZINDEX_ORDER } from 'constants/layout'

import { getBrandingColor } from 'utils/branding'
import { typeByObject } from 'utils/typescript'

import { Avatar, Field, FieldError, Typography } from 'components'

import { StyledOption, StyledSelect, StyledSelectContainer, VISUAL_TYPE } from './SelectStyled'

export type ValueType<O = undefined> = string | number | OptionProps<O> | OptionProps<O>[]

export interface SelectProps<O = undefined> {
  bodyTarget?: boolean
  cacheUniq?: string | number | boolean
  children?: React.ReactNode
  clearable?: boolean
  creatable?: boolean
  disabled?: boolean
  disabledOptions?: any[]
  error?: string | React.ReactNode
  fullWidth?: boolean
  label?: string
  loadOptions?: (phrase: string) => Promise<any>
  maxMenuHeight?: number
  multi?: boolean
  onChange?: (e: any) => any
  options?: OptionProps<O>[]
  placeholder?: string
  rawValue?: ValueType<O>
  searchable?: boolean
  specificWidth?: number
  /**
   * This prop set value as a final value of selected item, not "Option" object
   * (Instead of "{ label: 'foo', value: 'bar' }" is setting simply "bar").
   *
   * Thanks to this we always a primitive value and this is complies with types and interfaces,
   * and additionally we do not have to add extra logic in selectors to generate payload for POST/PUT methods
   * (where we extract value from Option property).
   */
  v2?: boolean
  value?: ValueType<O>
  visualType?: typeByObject<typeof VISUAL_TYPE>
  width?: number
  withAvatars?: boolean
}

const Select = <O = undefined>({
  bodyTarget,
  cacheUniq,
  clearable = true,
  creatable,
  disabled,
  disabledOptions,
  error,
  fullWidth,
  label,
  loadOptions,
  maxMenuHeight,
  multi,
  onChange,
  options,
  placeholder,
  rawValue,
  searchable = true,
  specificWidth,
  v2,
  value,
  visualType,
  width,
  withAvatars,
  ...other
}: SelectProps<O>) => {
  let foundValue = '' as ValueType<O>

  const findValue = (subOptions, currentValue) => {
    let result = null

    const searchRecursive = (optionsList, originalValue) => {
      _.each(optionsList, (option) => {
        if (option.options) {
          searchRecursive(option.options, originalValue)
        }

        if (
          !option.options
          && (
            option.value === originalValue
            || (originalValue && option.value === +originalValue)
            || (originalValue && option.value === originalValue.value)
            || (originalValue && option.value === +originalValue.value)
          )
        ) {
          result = option
        }
      })
    }

    searchRecursive(subOptions, currentValue)

    return result
  }

  if (_.isArray(value)) {
    foundValue = []
    _.map(value, (item, index) => {
      foundValue[index] = findValue(options, item.value || item)
    })
  } else {
    foundValue = findValue(options, value)
  }

  if (!options?.length) {
    foundValue = value
  }

  if ((!v2 && rawValue) || (v2 && !_.isUndefined(rawValue) && !(_.isString(rawValue) || _.isNumber(rawValue)))) {
    foundValue = rawValue
  }

  const getOptionLabelColor = () => {
    if (disabled) {
      return NEUTRAL_COLOURS.GRAY
    }

    if (VISUAL_TYPE.TRANSPARENT === visualType) {
      return getBrandingColor('primary-color')
    }

    return NEUTRAL_COLOURS.BASIC
  }

  const Option = (item) => {
    const { data } = item

    return (
      <components.Option {...item}>
        <StyledOption $withAvatars={withAvatars && !data.noAvatar}>
          {withAvatars && !data.noAvatar && (
            <Avatar
              avatarSize="small"
              initials={data.label}
              src={data.avatar}
            />
          )}
          {data?.sublabel
            ? (
              <Typography>
                {data.label}
                <Typography color={colors.gray} ellipsis>
                  {data.sublabel}
                </Typography>
              </Typography>
            ) : (
              <Typography color={disabled ? NEUTRAL_COLOURS.GRAY : NEUTRAL_COLOURS.BASIC}>
                {data.label}
              </Typography>
            )}
        </StyledOption>
      </components.Option>
    )
  }

  const SingleValue = (item) => {
    const { data } = item

    return (
      <components.SingleValue {...item}>
        <StyledOption $withAvatars={withAvatars && !data.noAvatar}>
          {withAvatars && !data.noAvatar && (
            <Avatar
              avatarSize="small"
              initials={data.label}
              src={data.avatar}
            />
          )}
          <Typography bold={VISUAL_TYPE.TRANSPARENT === visualType} color={getOptionLabelColor()}>
            {data.label}
          </Typography>
        </StyledOption>
      </components.SingleValue>
    )
  }

  const selectStyles = {
    menu: (base) => ({
      ...base,
      marginTop: 5,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: ZINDEX_ORDER.FIXED_SELECT,
    }),
    singleValue: (base) => ({
      ...base,
    }),
  }

  return (
    <StyledSelectContainer
      $disabled={disabled}
      $fullWidth={fullWidth}
      $specificWidth={specificWidth}
    >
      {label && <Field.TopLabel hidden={!value} label={label} />}
      <StyledSelect
        $error={error}
        $fullWidth={fullWidth}
        $visualType={visualType}
        $width={width}
        cacheUniqs={cacheUniq ? [cacheUniq] : undefined}
        classNamePrefix="Select"
        components={{
          Option,
          SingleValue,
        }}
        creatable={creatable}
        debounceTimeout={500}
        filterOption={disabledOptions?.length
          ? (item) => !_.includes(disabledOptions, item.data.value)
          : undefined}
        isClearable={clearable}
        isDisabled={disabled}
        isMulti={multi}
        isSearchable={searchable}
        loadOptions={loadOptions}
        maxMenuHeight={maxMenuHeight}
        menuPortalTarget={bodyTarget && document.querySelector('body')}
        options={options || []}
        placeholder={placeholder}
        reduceOptions={(previous, loaded) => _.uniqBy([
          ...(previous || []),
          ...(loaded || []),
        ], ({ value: optionValue }) => optionValue)}
        styles={bodyTarget && selectStyles}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: NEUTRAL_COLOURS.GRAY_SECONDARY,
            primary25: NEUTRAL_COLOURS.GRAY_TERTIARY,
            primary50: NEUTRAL_COLOURS.GRAY_TERTIARY,
            primary75: color(getBrandingColor('primary-color')).alpha(0.75).string(),
          },
        })}
        value={foundValue}
        onChange={(result) => {
          if (result?.value && v2) {
            return onChange(result.value)
          }

          return onChange(result)
        }}
        {...other}
      />
      {VISUAL_TYPE.MODERN !== visualType && (
        <FieldError error={error} />
      )}
    </StyledSelectContainer>
  )
}

export default Select
