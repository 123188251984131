export const CLEAR_CHILD_OBSERVATION = 'CLEAR_CHILD_OBSERVATION'

export const GET_CHILD_OBSERVATION = 'GET_CHILD_OBSERVATION'
export const GET_CHILD_OBSERVATION_FAILED = 'GET_CHILD_OBSERVATION_FAILED'
export const GET_CHILD_OBSERVATION_SUCCESS = 'GET_CHILD_OBSERVATION_SUCCESS'

export const REMOVE_CHILD_OBSERVATION = 'REMOVE_CHILD_OBSERVATION'
export const REMOVE_CHILD_OBSERVATION_FAILED = 'REMOVE_CHILD_OBSERVATION_FAILED'
export const REMOVE_CHILD_OBSERVATION_SUCCESS = 'REMOVE_CHILD_OBSERVATION_SUCCESS'

export const UPDATE_CHILD_OBSERVATION = 'UPDATE_CHILD_OBSERVATION'
export const UPDATE_CHILD_OBSERVATION_FAILED = 'UPDATE_CHILD_OBSERVATION_FAILED'
export const UPDATE_CHILD_OBSERVATION_SUCCESS = 'UPDATE_CHILD_OBSERVATION_SUCCESS'

export const REMOVE_MONTESSORI_ACTIVITIES_IN_CHILD_OBSERVATION = 'REMOVE_MONTESSORI_ACTIVITIES_IN_CHILD_OBSERVATION'
// eslint-disable-next-line max-len
export const REMOVE_MONTESSORI_ACTIVITIES_IN_CHILD_OBSERVATION_FAILED = 'REMOVE_MONTESSORI_ACTIVITIES_IN_CHILD_OBSERVATION_FAILED'
// eslint-disable-next-line max-len
export const REMOVE_MONTESSORI_ACTIVITIES_IN_CHILD_OBSERVATION_SUCCESS = 'REMOVE_MONTESSORI_ACTIVITIES_IN_CHILD_OBSERVATION_SUCCESS'

export const UPDATE_MONTESSORI_ACTIVITIES_IN_CHILD_OBSERVATION = 'UPDATE_MONTESSORI_ACTIVITIES_IN_CHILD_OBSERVATION'
