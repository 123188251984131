import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import constants from 'services/nurseries/constants'

import { Form, Page, Spinner, Typography } from 'components'

import i18n from 'translations'

import {
  StyledButtonsWrapper,
  StyledCopyLinkButton,
  StyledFormLinkContentWrapper,
  StyledPreviewButton,
} from './FormStyled'

export const ENQUIRY_SETTINGS_FORM = 'EnquirySettingsForm'

const FormView = ({
  formLink,
  formValues,
  isLoading,
  isSubmitting,
  onCopyToClipboard,
  onRedirectLinkBlur,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <React.Fragment>
        <Typography margin="20px 0">
          {i18n.t('module:Management:Enquiries:EnquiryForm:subtitle')}
        </Typography>
        <Form.Row
          label={<Typography bold>{i18n.t('module:Management:Enquiries:EnquiryForm:formLink')}</Typography>}
          margin="30px 0 0"
          width={{ field: '50%' }}
          verticalLabel
        >
          <Form.Row.Item>
            <StyledFormLinkContentWrapper>
              <Typography>{formLink}</Typography>
            </StyledFormLinkContentWrapper>
          </Form.Row.Item>
        </Form.Row>
        <StyledButtonsWrapper>
          <StyledCopyLinkButton
            label={i18n.t('module:Management:Enquiries:EnquiryForm:copyLink')}
            onClick={() => onCopyToClipboard(formLink)}
          />
          <StyledPreviewButton
            hierarchy="secondary"
            label={i18n.t('module:Management:Enquiries:EnquiryForm:previewForm')}
            target="_blank"
            to={formLink}
          />
        </StyledButtonsWrapper>
        <Form>
          <Form.Row
            label={(
              <Typography bold>
                {i18n.t('module:Management:Enquiries:EnquiryForm:FormSubmissionPage:title')}
              </Typography>
            )}
            margin="30px 0 10px"
            verticalLabel
          />
          <Form.Row
            label={i18n.t('module:Management:Enquiries:EnquiryForm:FormSubmissionPage:subtitle')}
            margin="0 0 10px"
            verticalLabel
          />
          <Form.Row>
            <Form.Row.FlexItem>
              <Field
                component={Form.RadioGroup}
                name="thankYouPageOption"
                options={constants.FORM_THANKYOU_PAGE_OPTIONS}
              />
            </Form.Row.FlexItem>
          </Form.Row>
          {formValues?.thankYouPageOption === constants.FORM_THANKYOU_PAGE_TYPE.DEFAULT && (
            <Form.Row
              label={i18n.t('module:Management:Enquiries:EnquiryForm:FormSubmissionPage:RedirectLink.label')}
              width={{ field: '50%' }}
              vertical
            >
              <Form.Row.Item>
                <Field
                  component={Form.TextField}
                  name="redirectUrl"
                  placeholder={
                    i18n.t('module:Management:Enquiries:EnquiryForm:FormSubmissionPage:RedirectLink.placeholder')
                  }
                  suffix={isSubmitting && <Spinner size="small" />}
                  onBlur={onRedirectLinkBlur}
                />
              </Form.Row.Item>
            </Form.Row>
          )}
          {formValues?.thankYouPageOption === constants.FORM_THANKYOU_PAGE_TYPE.CUSTOM && (
            <Form.Row
              label={i18n.t('module:Management:Enquiries:EnquiryForm:FormSubmissionPage:CustomLink.label')}
              width={{ field: '50%' }}
              vertical
            >
              <Form.Row.Item>
                <Field
                  component={Form.TextField}
                  name="customThankYouPage"
                  placeholder={
                    i18n.t('module:Management:Enquiries:EnquiryForm:FormSubmissionPage:CustomLink.placeholder')
                  }
                  suffix={isSubmitting && <Spinner size="small" />}
                  validate={isRequired}
                  onBlur={onRedirectLinkBlur}
                />
              </Form.Row.Item>
            </Form.Row>
          )}
        </Form>
      </React.Fragment>
    )
  }

  return (
    <Page.Section
      isLoading={isLoading}
      title={i18n.t('module:Management:Enquiries:EnquiryForm:title')}
    >
      {renderContent()}
    </Page.Section>
  )
}

export default reduxForm({ form: ENQUIRY_SETTINGS_FORM, shouldError: () => true })(FormView)
