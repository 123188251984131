import _ from 'lodash'

import { toFloat } from 'utils/data'

import { getConsumablesOption } from 'services/nurseryConsumables/list/selectors'

import { CONSUMABLE_CHARGEABLE_OPTIONS, SessionType } from './components/constants'

export const getInitialValues = (session) => {
  if (!session) {
    return null
  }

  const {
    costTwoYears,
    costTwoYearsAbove,
    costUnderTwo,
    endTime,
    isHourly,
    name,
    nurseryConsumablesExtraItems,
    startTime,
  } = session

  const body = {
    costTwoYears,
    costTwoYearsAbove,
    costUnderTwo,
    endTime,
    name,
    nurseryConsumablesExtraItems: [],
    startTime,
    type: isHourly ? SessionType.HOURLY : SessionType.FIXED,
  }

  _.each(nurseryConsumablesExtraItems, ({ chargeable, nurseryConsumable, unitPrice }, i) => {
    body.nurseryConsumablesExtraItems[i] = {
      chargeable: _.find(CONSUMABLE_CHARGEABLE_OPTIONS, { value: chargeable }),
      consumable: nurseryConsumable && getConsumablesOption(nurseryConsumable),
      unitPrice,
    }
  })

  return body
}

export const getPayload = (fields) => {
  const {
    costTwoYears,
    costTwoYearsAbove,
    costUnderTwo,
    endTime,
    name,
    nurseryConsumablesExtraItems,
    startTime,
    type,
  } = fields
  const isHourly = type === SessionType.HOURLY

  const body = {
    costTwoYears: toFloat(costTwoYears) || 0,
    costTwoYearsAbove: toFloat(costTwoYearsAbove) || 0,
    costUnderTwo: toFloat(costUnderTwo) || 0,
    endTime: !isHourly ? endTime : null,
    isHourly,
    name,
    nurseryConsumablesExtraItems: [],
    startTime: !isHourly ? startTime : null,
  }

  _.each(nurseryConsumablesExtraItems, ({ chargeable, consumable, unitPrice }, i) => {
    body.nurseryConsumablesExtraItems[i] = {
      chargeable: isHourly ? chargeable?.value : undefined,
      nurseryConsumable: {
        id: (consumable && consumable.value) || consumable,
      },
      unitPrice: toFloat(+unitPrice),
    }
  })

  return body
}
