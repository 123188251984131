import { nest } from 'utils/flatnest'

import { RESPONSE } from 'constants/http'

export const getBackendErrors = (response) => {
  const { code, extra } = response || {}

  if (RESPONSE.HTTP_400_BAD_REQUEST !== code) {
    return null
  }

  return nest(extra)
}
