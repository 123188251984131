import {
  CLEAR_LIST_CHILD_DAILY_DIARY,
  LIST_CHILD_DAILY_DIARY,
  LIST_CHILD_DAILY_DIARY_FAILED,
  LIST_CHILD_DAILY_DIARY_SUCCESS,
} from './constants'

export const list = (childId, params, onSuccess = () => {}) => ({ childDailyDiaryApiClient }) => (dispatch) => {
  dispatch({
    type: LIST_CHILD_DAILY_DIARY,
  })

  childDailyDiaryApiClient.list(childId, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: LIST_CHILD_DAILY_DIARY_SUCCESS,
      })
      onSuccess(response)
    },
    ({ response }) => dispatch({
      error: response,
      type: LIST_CHILD_DAILY_DIARY_FAILED,
    }),
  )
}

export const clear = () => ({
  type: CLEAR_LIST_CHILD_DAILY_DIARY,
})
