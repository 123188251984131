import _ from 'lodash'

import { RESPONSE } from 'constants/http'

import {
  CLEAR_FORMATIVE_REPORTS,
  CREATE_FORMATIVE_REPORTS,
  CREATE_FORMATIVE_REPORTS_AGE_BANDS_SUCCESS,
  CREATE_FORMATIVE_REPORTS_COMMENTS_SUCCESS,
  CREATE_FORMATIVE_REPORTS_FAILED,
  CREATE_FORMATIVE_REPORTS_SUCCESS,
  EXPORT_FORMATIVE_REPORTS,
  EXPORT_FORMATIVE_REPORTS_FAILED,
  EXPORT_FORMATIVE_REPORTS_SUCCESS,
  GET_FORMATIVE_REPORTS,
  GET_FORMATIVE_REPORTS_FAILED,
  GET_FORMATIVE_REPORTS_SUCCESS,
  REMOVE_FORMATIVE_REPORTS,
  REMOVE_FORMATIVE_REPORTS_FAILED,
  UPDATE_FORMATIVE_REPORTS,
  UPDATE_FORMATIVE_REPORTS_AGE_BANDS_SUCCESS,
  UPDATE_FORMATIVE_REPORTS_COMMENTS_SUCCESS,
  UPDATE_FORMATIVE_REPORTS_FAILED,
  UPDATE_FORMATIVE_REPORTS_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isExporting: false,
  isFetching: false,
  isSubmitting: false,
  meta: null,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case GET_FORMATIVE_REPORTS:
      return {
        ...state,
        error: initialState.error,
        isFetching: true,
      }
    case GET_FORMATIVE_REPORTS_SUCCESS:
      return {
        ...state,
        data: _.assign({}, state.data, payload.data),
        isFetching: false,
      }
    case GET_FORMATIVE_REPORTS_FAILED:
      return {
        ...state,
        error,
        isFetching: false,
      }
    case EXPORT_FORMATIVE_REPORTS:
      return {
        ...state,
        error: null,
        isExporting: true,
      }
    case EXPORT_FORMATIVE_REPORTS_SUCCESS:
      return {
        ...state,
        error: null,
        isExporting: false,
      }
    case EXPORT_FORMATIVE_REPORTS_FAILED:
      return {
        ...state,
        error,
        isExporting: false,
      }
    case CREATE_FORMATIVE_REPORTS:
    case UPDATE_FORMATIVE_REPORTS:
      return {
        ...state,
        error: initialState.error,
        isSubmitting: true,
      }
    case CREATE_FORMATIVE_REPORTS_SUCCESS:
      return {
        ...state,
        data: payload,
        isSubmitting: false,
      }
    case REMOVE_FORMATIVE_REPORTS:
      return {
        ...state,
        isFetching: true,
      }
    case REMOVE_FORMATIVE_REPORTS_FAILED:
      return {
        ...state,
        isFetching: false,
      }
    case UPDATE_FORMATIVE_REPORTS_SUCCESS:
      return {
        ...state,
        data: _.assign({}, state.data, payload.data),
        isSubmitting: false,
      }
    case CREATE_FORMATIVE_REPORTS_FAILED:
      return {
        ...state,
        error,
        isSubmitting: false,
      }
    case UPDATE_FORMATIVE_REPORTS_FAILED:
      return {
        ...state,
        error: RESPONSE.HTTP_409_CONFLICT === error.code ? error : null,
        isSubmitting: false,
      }
    case UPDATE_FORMATIVE_REPORTS_AGE_BANDS_SUCCESS:
    case UPDATE_FORMATIVE_REPORTS_COMMENTS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          comments: _.map(state?.data?.comments, (comment) => {
            if (payload.data.id === comment.id) {
              return {
                ...comment,
                comment: payload.data.comment,
              }
            }

            return comment
          }),
          updatedAt: new Date(),
        },
        isSubmitting: false,
      }
    case CREATE_FORMATIVE_REPORTS_AGE_BANDS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          attainmentLevels: [
            ...state.data.attainmentLevels,
            payload.data,
          ],
        },
      }
    }
    case CREATE_FORMATIVE_REPORTS_COMMENTS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          comments: [
            ...state.data?.comments || [],
            payload.data,
          ],
        },
      }
    }
    case CLEAR_FORMATIVE_REPORTS:
      return initialState
    default:
      return state
  }
}
