import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { ACTIONS, STATUS_TYPES } from 'services/legacy/requestedExtraSessions/constants'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withRequestedExtraSessionsService } from 'services/legacy/requestedExtraSessions'
import { withSnackbarService } from 'services/utils/snackbar'

import { Space } from 'components'

import i18n from 'translations'

import RequestedExtraSessionModalView from './RequestedExtraSessionModalView'

class RequestedExtraSessionModalContainer extends Component {
  constructor(props) {
    super(props)

    const { action } = props

    this.state = {
      isApproveAction: ACTIONS.APPROVE === action,
    }
  }

  handleUpdateRequestedExtraSessionSuccess = () => {
    const { hideModal, onSuccess, snackbarActions } = this.props
    const { isApproveAction } = this.state

    hideModal()
    onSuccess()

    return snackbarActions.show({
      message: i18n.t('module:Modals:RequestedExtraSession:snackbarMessage', {
        action: isApproveAction
          ? i18n.t('module:Modals:RequestedExtraSession:approved')
          : i18n.t('module:Modals:RequestedExtraSession:declined'),
      }),
    })
  }

  handleUpdateRequestedExtraSessionFailed = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleUpdateRequestedExtraSession = (fields) => {
    const {
      id,
      requestedExtraSessionsActions,
      requestedExtraSessionsSelectors,
      statistics,
    } = this.props
    const { isApproveAction } = this.state

    const body = requestedExtraSessionsSelectors.getUpdateRequestPayload({
      fields,
      isApproveAction,
      statistics,
    })

    return requestedExtraSessionsActions.update({
      body,
      onFailed: this.handleUpdateRequestedExtraSessionFailed,
      onSuccess: this.handleUpdateRequestedExtraSessionSuccess,
      params: [id, {}],
    })
  }

  handleSubmit = (fields) => {
    const { modalActions, modalConsts, status } = this.props

    if (STATUS_TYPES.APPROVED === status) {
      return modalActions.show(modalConsts.TYPES.CONFIRM, {
        confirmButtonLabel: i18n.t('module:Modals:RequestedExtraSession:ConfirmDeclineRequest:confirmButtonLabel'),
        icon: 'exclamation-mark',
        onConfirm: () => this.handleUpdateRequestedExtraSession(fields),
        text: (
          <React.Fragment>
            {i18n.t('module:Modals:RequestedExtraSession:ConfirmDeclineRequest:Text:1')}
            <Space space="20px" />
            {i18n.t('module:Modals:RequestedExtraSession:ConfirmDeclineRequest:Text:2')}
          </React.Fragment>
        ),
      })
    }

    return this.handleUpdateRequestedExtraSession(fields)
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  render() {
    const { isSubmitting } = this.props
    const { isApproveAction } = this.state

    return (
      <RequestedExtraSessionModalView
        isApproveAction={isApproveAction}
        isSubmitting={isSubmitting}
        onCloseClick={this.handleCloseClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  requestedExtraSessionsSingleState,
}) => ({
  isSubmitting: appSelectors.getIsSubmitting(requestedExtraSessionsSingleState),
})

const enhance = compose(
  withAppService,
  withModalService,
  withRequestedExtraSessionsService,
  withSnackbarService,
  connect(mapState),
)

export default enhance(RequestedExtraSessionModalContainer)
