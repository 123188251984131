import moment from 'moment'
import _ from 'lodash'
import { createSelector } from 'reselect'

import React from 'react'

import { Currency, DateString } from 'components'

import { ACTIONS_TYPES, TRANSACTIONS_TYPES } from '../constants'

const getTransactions = (state = {}) => state.transactions

export const getTransactionsList = createSelector([getTransactions], (state = {}) => state.list)

const getDetails = (item) => {
  const { action, data, details, type } = item
  const { invoice, paymentType } = data

  if (type === TRANSACTIONS_TYPES.PAYMENT) {
    if (action === ACTIONS_TYPES.DELETE) {
      return `Removed payment - ${paymentType.name}`
    }

    return `Payment - ${paymentType.name}`
  }

  if (type === TRANSACTIONS_TYPES.CREDIT_NOTE) {
    const { name, number } = invoice

    return `Credit note - ${name} - ${number}`
  }

  return details
}

export const getTransactionsListDataTable = createSelector(
  [getTransactionsList],
  (state = {}) => _.map(state.data, (item) => {
    const { balance, credit, data: { number }, debit, documentDate, id, type } = item
    const details = getDetails(item)

    const debitPositive = 0 < debit ? debit : -1 * debit

    return ({
      balance: (
        <Currency
          value={balance}
          showDebitCredit
        />
      ),
      credit: 'number' === typeof credit && 0 !== credit ? (
        <Currency
          color="#95C29A"
          value={credit}
        />
      ) : null,
      debit: 'number' === typeof debit && 0 !== debit ? (
        <Currency
          color="#D85639"
          value={debitPositive}
        />
      ) : null,
      details,
      documentDate: <DateString date={documentDate} />,
      id,
      number,
      raw: item,
      type,
    })
  }),
)

export const getTransactionsListMeta = createSelector([getTransactionsList], (state = {}) => state.meta || {})

export const getTransactionsListMetaTotalResults = createSelector(
  [getTransactionsListMeta],
  (state = {}) => state.total_results,
)

const getFilters = (filters) => filters

export const getCriteriaSelector = createSelector([getFilters], (filters) => {
  if (!filters) {
    return null
  }

  const criteria = []

  const {
    child,
    documentDate,
    endDate,
    format,
    startDate,
  } = filters

  if (child) {
    criteria.push({
      field: 'child',
      value: child,
    })
  }

  if (documentDate) {
    const { documentDate: { after, before } } = filters

    if (after) {
      criteria.push({
        comparator: 'after',
        field: 'documentDate',
        value: moment(after).set('hour', 0).set('minute', 0).format('YYYY-MM-DD HH:mm'),
      })
    }

    if (before) {
      criteria.push({
        comparator: 'before',
        field: 'documentDate',
        value: moment(before).set('hour', 23).set('minute', 59).format('YYYY-MM-DD HH:mm'),
      })
    }
  }

  if (endDate) {
    criteria.push({
      field: 'endDate',
      value: moment(endDate).set('hour', 23).set('minute', 59).format('YYYY-MM-DD HH:mm'),
    })
  }

  if (startDate) {
    criteria.push({
      field: 'startDate',
      value: moment(startDate).set('hour', 0).set('minute', 0).format('YYYY-MM-DD HH:mm'),
    })
  }

  if (format) {
    criteria.push({
      field: 'format',
      value: format,
    })
  }

  return criteria
})
