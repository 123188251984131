import styled from 'styled-components'

import { getBrandingColor } from 'utils/branding'

interface StyledContainerProps {
  $checked: boolean
}

export const StyledContainer = styled.label<StyledContainerProps>`
  cursor: pointer;
  ${({ $checked }) => $checked && `color: ${getBrandingColor('primary-color')};`};
`
