import _ from 'lodash'

import { FLAG_COLOURS } from 'constants/colors'

import i18n from 'translations'

export const getAvailabilityStatisticData = (percent) => {
  if (!percent) {
    return {
      color: FLAG_COLOURS.ERROR,
      icon: 'unconfirm',
      label: i18n.t('global:No'),
    }
  }

  return {
    color: FLAG_COLOURS.SUCCESS,
    icon: 'confirmed',
    label: i18n.t('global:Yes'),
  }
}

export const getGroupedTimes = (times) => {
  const groupedTimes = {}

  _.forEach(times, (item) => {
    if (item?.staffRequired) {
      if (!groupedTimes?.[item.staffRequired]) {
        groupedTimes[item.staffRequired] = []
      }

      groupedTimes[item.staffRequired].push(item)
    }
  })

  return groupedTimes
}
