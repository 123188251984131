import { generateBasicActions } from 'utils/service'

import { GET_COMMENTS_LIST, GET_COMMENTS_LIST_FAILED, GET_COMMENTS_LIST_SUCCESS } from './constants'

export const list = (options = {}) => ({ commentsApiClient }) => (
  generateBasicActions.list({
    constants: {
      failed: GET_COMMENTS_LIST_FAILED,
      init: GET_COMMENTS_LIST,
      success: GET_COMMENTS_LIST_SUCCESS,
    },
    options,
    service: commentsApiClient,
    serviceMethod: 'list',
  })
)
