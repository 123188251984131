import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'recompose'

import { isRequired } from 'utils/fieldValidation'

import { Form, Section } from 'components'

import i18n from 'translations'

import { CHILD_ADD_FORM } from './constants'
import ChildFormFooter from './ChildFormFooter'

const TRANS_PREFIX = 'module:Children:Child:Add:EnrolmentDetails'

const EnrolmentDetailsForm = ({
  childId,
  handleSubmit,
  isChildArchived,
  isEditMode,
  isSubmitting,
  onArchiveClick,
  onPreviousStageClick,
  permissionToArchive,
}) => {
  const renderForm = () => {
    const renderAttendance = () => (
      <Section title={i18n.t(`${TRANS_PREFIX}:Subsections:Attendance:title`)}>
        <Form.Row
          label={i18n.t(`${TRANS_PREFIX}:Subsections:Attendance:Form:Room:label`)}
          required
        >
          <Form.Row.Item>
            <Field
              component={Form.InfiniteDropdowns.Rooms}
              name="nurseryClass"
              placeholder={i18n.t(`${TRANS_PREFIX}:Subsections:Attendance:Form:Room:placeholder`)}
              validate={isRequired}
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row
          label={i18n.t(`${TRANS_PREFIX}:Subsections:Attendance:Form:User:label`)}
        >
          <Form.Row.Item>
            <Field
              component={Form.InfiniteDropdowns.Users}
              name="keyWorkers"
              placeholder={i18n.t(`${TRANS_PREFIX}:Subsections:Attendance:Form:User:placeholder`)}
              multi
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row
          label={i18n.t(`${TRANS_PREFIX}:Subsections:Attendance:Form:StartDate:label`)}
          required
        >
          <Form.Row.Item>
            <Field
              component={Form.DatePicker}
              name="startDate"
              placeholder={i18n.t(`${TRANS_PREFIX}:Subsections:Attendance:Form:StartDate:placeholder`)}
              validate={isRequired}
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row
          label={i18n.t(`${TRANS_PREFIX}:Subsections:Attendance:Form:LeavingDate:label`)}
        >
          <Form.Row.Item>
            <Field
              component={Form.DatePicker}
              name="leavingDate"
              placeholder={i18n.t(`${TRANS_PREFIX}:Subsections:Attendance:Form:LeavingDate:placeholder`)}
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row
          label={i18n.t(`${TRANS_PREFIX}:Subsections:Attendance:Form:RegistrationDate:label`)}
        >
          <Form.Row.Item>
            <Field
              component={Form.DatePicker}
              name="registeredAt"
              placeholder={i18n.t(`${TRANS_PREFIX}:Subsections:Attendance:Form:RegistrationDate:placeholder`)}
            />
          </Form.Row.Item>
        </Form.Row>
      </Section>
    )

    const renderFunding = () => (
      <Section title={i18n.t(`${TRANS_PREFIX}:Subsections:Funding:title`)}>
        <Form.Row autoHeight label>
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              label={i18n.t(`${TRANS_PREFIX}:Subsections:Funding:Form:TwoYearFunding:label`)}
              name="profile.doesReceiveTwoYearFunding"
            />
          </Form.Row.Item>
        </Form.Row>
        <Form.Row autoHeight label>
          <Form.Row.Item>
            <Field
              component={Form.Checkbox}
              label={i18n.t(`${TRANS_PREFIX}:Subsections:Funding:Form:EYPPFunding:label`)}
              name="profile.doesReceiveEYPPFunding"
            />
          </Form.Row.Item>
        </Form.Row>
      </Section>
    )

    return (
      <React.Fragment>
        {renderAttendance()}
        {renderFunding()}
      </React.Fragment>
    )
  }

  const renderFooter = () => (
    <ChildFormFooter
      childId={childId}
      isChildArchived={isChildArchived}
      isEditMode={isEditMode}
      isSubmitting={isSubmitting}
      permissionToArchive={permissionToArchive}
      onArchiveClick={onArchiveClick}
      onPreviousStageClick={onPreviousStageClick}
    />
  )

  return (
    <Form onSubmit={handleSubmit}>
      {renderForm()}
      {renderFooter()}
    </Form>
  )
}

const enhance = compose(reduxForm({ destroyOnUnmount: false, form: CHILD_ADD_FORM }))

export default enhance(EnrolmentDetailsForm)
