import _ from 'lodash'

import React from 'react'

import { FRAMEWORK_TYPE_MONTESSORI } from 'services/legacy/frameworks/constants'

import { generateRoute } from 'utils/routing'

import { EmptyState, Hyperlink, Page, Section, Select, Space, Spinner } from 'components'

import i18n from 'translations'

import ChildFrameworkTable from './components/ChildFrameworkTable'
import MontessoriTable from './components/MontessoriTable'
import { StyledToolbarWrapper } from './ChildFrameworkStyled'

const ChildFrameworkView = ({
  areaOfLearning,
  childFrameworkMontessoriProgress,
  childFrameworkProgress,
  childId,
  framework,
  frameworkDetails,
  frameworksList,
  isFetching,
  isFetchingFramework,
  montessoriCategories,
  onAreaOfLearningChange,
  onFrameworkChange,
  onShowSelectProgressModal,
  recalculateFrameworkTable,
}) => {
  const areasOptions = (() => {
    if (isFetchingFramework) {
      return []
    }

    if (FRAMEWORK_TYPE_MONTESSORI === framework) {
      return _.map(montessoriCategories, ({ id, name }) => ({ label: name, value: id }))
    }

    return _.map(frameworkDetails?.areas, ({ id, name }) => ({ label: name, value: id }))
  })()

  const renderHeader = () => (
    <StyledToolbarWrapper>
      <Select
        clearable={false}
        options={frameworksList}
        placeholder={i18n.t('module:Children:Child:LearningJourney:Framework:title')}
        value={framework}
        onChange={onFrameworkChange}
      />
      <Select
        clearable={false}
        disabled={isFetchingFramework || !framework}
        options={areasOptions}
        placeholder={i18n.t('module:Children:Child:LearningJourney:Framework:areaOfLearning')}
        value={areaOfLearning}
        onChange={onAreaOfLearningChange}
      />
    </StyledToolbarWrapper>
  )

  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    if (isFetchingFramework) {
      return (
        <React.Fragment>
          {renderHeader()}
          <Space space="20px" />
          <Spinner />
        </React.Fragment>
      )
    }

    if (!frameworksList?.length) {
      return (
        <EmptyState
          icon="framework"
          text1={i18n.t('module:Children:Child:LearningJourney:Framework:noFrameworksList')}
          text2={(
            <Hyperlink to={generateRoute('MANAGEMENT.LEARNING.CURRICULUMS')} primary>
              {i18n.t('module:Learning:CohortTracking:global:goToSettings')}
            </Hyperlink>
          )}
        />
      )
    }

    if (FRAMEWORK_TYPE_MONTESSORI === framework) {
      return (
        <React.Fragment>
          {renderHeader()}
          <MontessoriTable
            category={areaOfLearning}
            childFrameworkMontessoriProgress={childFrameworkMontessoriProgress}
            montessoriCategories={montessoriCategories}
            onShowSelectProgressModal={onShowSelectProgressModal}
          />
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        {renderHeader()}
        <ChildFrameworkTable
          areaOfLearning={areaOfLearning}
          childFrameworkProgress={childFrameworkProgress}
          frameworkDetails={frameworkDetails}
          recalculateFrameworkTable={recalculateFrameworkTable}
          onShowSelectProgressModal={onShowSelectProgressModal}
        />
      </React.Fragment>
    )
  }

  return (
    <Page.Section
      actions={(
        <Section.Actions
          primary={[{
            label: i18n.t('module:Children:Child:LearningJourney:Framework:createFormativeReport'),
            noIcon: true,
            to: generateRoute('CHILDREN.CHILD.LEARNING_JOURNEY.FORMATIVE_REPORTS.CREATE', { childId }),
          }]}
        />
      )}
      isLoading={isFetching}
      title={i18n.t('module:Children:Child:LearningJourney:Framework:title')}
    >
      {renderContent()}
    </Page.Section>
  )
}

export default ChildFrameworkView
