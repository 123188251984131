import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

interface StyledContainerProps {
  $disabled?: boolean
}

export const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  justify-content: space-between;
  
  ${({ $disabled }) => $disabled && `
    * {
      cursor: not-allowed !important;
    }
  `}
`

export const StyledRadioContainer = styled.div`
  display: flex;
  height: 22px;
  width: 22px;
`

export const StyledCheckMarkContainer = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: ${NEUTRAL_COLOURS.WHITE};
  border: 2px solid ${NEUTRAL_COLOURS.GRAY_SECONDARY};
  border-radius: 50%;
  
  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`

export const StyledInputContainer = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  
  &:checked + ${StyledCheckMarkContainer} {
    ${({ disabled }) => !disabled && `
      border-color: ${getBrandingColor('primary-color')};
    `}
  }
`

export const StyledLabelContainer = styled.label`
  cursor: pointer;
  display: block;
  position: relative;
  user-select: none;
  
  & ${StyledInputContainer} {
    &:checked {
      & ~ ${StyledCheckMarkContainer} {
        &:after {
          display: block;
        }
      }
    }
  }

  & ${StyledCheckMarkContainer} {
    &:after {
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: ${getBrandingColor('primary-color')};
    }
  }

  & ${StyledInputContainer} {
    &:disabled {
      & ~ ${StyledCheckMarkContainer} {
        &:after {
          background: #CCC;
        }
      }
    }
  }
`

export const StyledItemContainer = styled.label`
  display: flex;
  cursor: pointer;

  &:not(:first-child) {
    margin-left: 10px;
  }
`

export const StyledGroupContainer = styled.div`
  display: flex;
  align-items: center;
`
