import ServiceBase from 'services/ServiceBase'

import { getQueryStringV2 } from 'utils/api'

class SupportedLocaleApiClient extends ServiceBase {
  list = (options = {}) => this.request({
    path: `/v2/supported_locales?${getQueryStringV2(options)}`,
  })
}

export default SupportedLocaleApiClient
