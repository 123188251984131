import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'recompose'

import { Form } from 'components'

export const STAFF_REQUIRED_FORM = 'STAFF_REQUIRED_FORM'

const StaffRequiredForm = ({
  handleSubmit,
  isSubmitting,
  onSubmit,
  options,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row>
      <Form.Row.FlexItem>
        <Field
          component={Form.RadioGroup}
          disabled={isSubmitting}
          name="staffRequiredCalculation"
          options={options}
        />
      </Form.Row.FlexItem>
    </Form.Row>
  </Form>
)

const enhance = compose(reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: true,
  form: STAFF_REQUIRED_FORM,
}))

export default enhance(StaffRequiredForm)
