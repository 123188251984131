import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { CursorTypes } from 'constants/css'

interface StyledContainerProps {
  $cursor?: CursorTypes
  $disabled?: boolean
  $labelBold?: boolean
  $labelColor?: string
  $labelSize?: number
}

export const StyledContainer = styled.div<StyledContainerProps>`
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  align-items: 'flex-end';
  
  ${({ $labelBold }) => $labelBold && `
    font-weight: 600;
  `}  
  
  ${({ $labelColor }) => $labelColor && `
    color: ${$labelColor};
  `}
  
  ${({ $labelSize }) => $labelSize && `
    font-size: ${$labelSize}px;
  `}

  ${({ $cursor }) => $cursor && `
    cursor: ${$cursor}
  `}

  ${({ $disabled }) => $disabled && `
    cursor: not-allowed;
    color: ${NEUTRAL_COLOURS.GRAY};
  `}
`

export const StyledSmallTextContainer = styled.div`
  font-size: 10px;
  margin-left: 5px;
  font-weight: 400;
`
