import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change, getFormSubmitErrors, getFormValues, reset, stopSubmit } from 'redux-form'

import { DEFAULT_DATE_FORMAT, WEEK_DAYS } from 'constants/date'
import { PLANNED_TYPE_OPTIONS, SHIFT_MAIN_TYPES } from 'services/legacy/membershipRegisters/constants'

import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withMembershipsShiftsService } from 'services/legacy/membershipsShifts'
import { withMembershipsLeavesService } from 'services/legacy/membershipsLeaves'
import { withModalService } from 'services/utils/modal'

import AddView from './AddModalView'
import { STAFF_LEAVE_ADD_MODAL_FORM } from './components/AddModalForm/AddModalForm'
import { prepareBackendErrors } from '../../StaffModalHelper'

const GROUPS = {
  read: [
    'membershipShift.membershipShiftSettings',
    'membershipShift.parentShift',
    'membershipShift.membershipShiftTimes',
    'membershipShiftSettings',
    'membershipShiftTime',
    'membershipShiftTime.class',
    'membershipShift.leaveShiftType',
    'leaveShiftType',
  ],
}

const GROUPS_FOR_LEAVES = {
  read: [
    'membershipShift.membershipShiftTimeStatistics',
    'membershipShift.membershipShiftTimes',
    'membershipShiftSettings',
    'membershipShiftTime',
    'membershipShiftTime.class',
    'membership.details',
    'membershipShift.leaveShiftType',
    'leaveShiftType',
  ],
}

class AddModalContainer extends Component {
  constructor(props) {
    super(props)

    const date = [
      moment(),
      moment().add(7, 'days'),
    ]

    const daysDiffs = moment(date[1]).startOf('day')
      .diff(moment(date[0]).startOf('day'), 'days') + 1

    this.state = {
      initialValues: {
        date,
        membershipShiftTimes: this.getMembershipsShiftTimes(daysDiffs, date[0]),
      },
      isEditMode: false,
      isFetching: false,
      isInitialized: false,
      leaveId: null,
      leaves: {},
    }
  }

  componentDidMount() {
    const { initialValues, leave } = this.props
    const { initialValues: initialValuesState } = this.state

    if (leave?.id) {
      this.setState({
        isEditMode: true,
        leaveId: leave?.id,
      })
    }

    if (initialValues?.membership || initialValues?.date) {
      if (initialValues?.membership) {
        initialValuesState.membership = initialValues.membership
      }

      if (initialValues?.leaveShiftType) {
        initialValuesState.leaveShiftType = initialValues.leaveShiftType
      }

      if (initialValues?.date) {
        const daysDiffs = moment(initialValues.date?.[1]).startOf('day')
          .diff(moment(initialValues.date?.[0]).startOf('day'), 'days') + 1

        initialValuesState.date = [initialValues.date?.[0], initialValues.date?.[1]]
        initialValuesState.membershipShiftTimes = this.getMembershipsShiftTimes(daysDiffs, initialValues.date?.[0])
      }

      if (initialValues?.membershipShiftTimes) {
        initialValuesState.membershipShiftTimes = initialValues.membershipShiftTimes
      }

      this.setState({
        initialValues: initialValuesState,
      }, this.handleChangeMainField)
    }

    this.setState({
      isInitialized: true,
    })
  }

  componentWillUnmount() {
    const { resetForm } = this.props

    resetForm()
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSubmitFailed = (response) => {
    const { formValues, injectValidation } = this.props
    const { membershipShiftTimes } = formValues

    const errors = prepareBackendErrors(response, membershipShiftTimes)

    return setTimeout(() => injectValidation(errors))
  }

  handleSuccess = (payload) => {
    const { onSuccess } = this.props

    onSuccess(payload)

    return this.handleCloseClick()
  }

  handleSubmit = (values) => {
    const { membershipsShiftsActions, membershipsShiftsSelectors } = this.props
    const { isEditMode, leaveId } = this.state

    const payload = membershipsShiftsSelectors.getValuesForm(values)

    if (isEditMode) {
      return membershipsShiftsActions.update(leaveId, {
        onFailed: this.handleSubmitFailed,
        onSuccess: () => this.handleSuccess(payload),
        params: { groups: GROUPS },
        payload,
      })
    }

    return membershipsShiftsActions.create({
      onFailed: this.handleSubmitFailed,
      onSuccess: () => this.handleSuccess(payload),
      params: { groups: GROUPS },
      payload,
    })
  }

  handleChangeField = (fieldName, value) => {
    const { changeFieldValue } = this.props

    changeFieldValue(fieldName, value)
  }

  handleGetShiftsSuccess = (response) => {
    const { formValues } = this.props
    const { membership } = formValues || {}
    const { data } = response
    const member = data?.[0]
    const { shifts } = member || {}

    let shiftTimes = []

    _.each(shifts, ({ membershipShiftTimes, type }) => {
      if (SHIFT_MAIN_TYPES.WORK === type) {
        shiftTimes = [
          ...shiftTimes,
          ...membershipShiftTimes,
        ]
      }
    })

    const { membershipShiftTimes } = membership.shifts?.[0] || {}
    const records = _.mapValues(
      _.mapKeys(WEEK_DAYS, (value) => value),
      () => [],
    )

    if (membershipShiftTimes?.length) {
      this.setState({ isEditMode: true })
    }

    _.each(membershipShiftTimes, ({ class: nurseryClass, day, endTime, startTime, type }) => {
      if (!records[day]) {
        records[day] = []
      }

      records[day].push({
        endTime: moment(endTime),
        location: nurseryClass?.id,
        startTime: moment(startTime),
        type: _.find(PLANNED_TYPE_OPTIONS, ({ value }) => type === value),
      })
    })

    _.each(WEEK_DAYS, (value) => {
      if (!records[value]?.length) {
        records[value] = [{}]
      }
    })

    if (membershipShiftTimes) {
      this.handleChangeField('membershipShiftTimes', records)
    }

    this.setState({ isFetching: false })
  }

  handleGetLeavesSuccess = (response) => {
    const { data } = response
    const { membershipsLeavesSelectors } = this.props
    const leaves = membershipsLeavesSelectors.getConcatenatedLeaveTimesGroupedByDay(data)

    this.setState({ leaves })
  }

  handleChangeMainField = () => setTimeout(() => {
    const { formValues, membershipsLeavesActions, membershipsShiftsActions, membershipsShiftsSelectors } = this.props
    const { date, membership } = formValues || {}

    const membershipId = !_.isObject(membership) ? membership : membership.value

    if (!date || !membershipId) {
      return null
    }

    this.setState({ isFetching: true })

    const criteria = membershipsShiftsSelectors.getListCriteria({
      dateFrom: moment(date[0]).format(DEFAULT_DATE_FORMAT),
      dateTo: moment(date[1]).format(DEFAULT_DATE_FORMAT),
      membership: membershipId,
      membershipShiftTimesExists: true,
    })

    const criteriaForLeaves = membershipsShiftsSelectors.getListCriteria({
      dateFrom: moment(date[0]).format(DEFAULT_DATE_FORMAT),
      dateTo: moment(date[1]).format(DEFAULT_DATE_FORMAT),
      membership: membershipId,
    })

    membershipsLeavesActions.list({
      onSuccess: this.handleGetLeavesSuccess,
      onlyData: true,
      params: {
        criteria: criteriaForLeaves,
        groups: GROUPS_FOR_LEAVES,
      },
    })

    return membershipsShiftsActions.list({
      onSuccess: this.handleGetShiftsSuccess,
      onlyData: true,
      params: {
        criteria,
        groups: GROUPS,
      },
    })
  })

  getMembershipsShiftTimes = (times, date, result = () => [{}]) => _.mapValues(
    _.mapKeys(
      _.times(times, (index) => moment(date).add(index, 'day').format(DEFAULT_DATE_FORMAT)),
      (value) => value,
    ),
    result,
  )

  handleChangeDate = (date) => {
    const { formValues: { membershipShiftTimes } } = this.props
    const daysDiffs = moment(date[1]).startOf('day')
      .diff(moment(date[0]).startOf('day'), 'days') + 1

    this.handleChangeField('membershipShiftTimes', this.getMembershipsShiftTimes(
      daysDiffs,
      date[0],
      (dateItem) => {
        if (membershipShiftTimes[dateItem]?.length) {
          return membershipShiftTimes[dateItem]
        }

        return [{}]
      },
    ))

    this.handleChangeMainField()
  }

  handleCopyToAll = (dayShifts) => {
    const { formValues: { membershipShiftTimes } } = this.props
    const shiftTimes = _.mapValues(membershipShiftTimes, (item) => item)

    _.each(shiftTimes, (items, date) => {
      shiftTimes[date] = _.map(dayShifts, (item, index) => ({
        ...item,
        id: items[index]?.id,
      }))
    })

    this.handleChangeField('membershipShiftTimes', shiftTimes)
  }

  prepareErrors = (errors) => {
    const newErrors = _.cloneDeep(errors)

    if (!_.isString(newErrors?.membershipShiftTimes)) {
      delete newErrors?.membershipShiftTimes
    }

    return newErrors
  }

  render() {
    const { formErrors, formValues, isSubmitting, membershipsShiftsErrors } = this.props
    const { initialValues, isEditMode, isFetching, isInitialized, leaves } = this.state
    const errors = this.prepareErrors(getBackendErrors(membershipsShiftsErrors || {}))

    return (
      <AddView
        errorMessages={errors}
        formErrors={formErrors}
        formValues={formValues}
        initialValues={initialValues}
        isEditMode={isEditMode}
        isFetching={isFetching}
        isInitialized={isInitialized}
        isSubmitting={isSubmitting}
        leaves={leaves}
        onChangeDate={this.handleChangeDate}
        onChangeField={this.handleChangeField}
        onChangeMainField={this.handleChangeMainField}
        onCloseClick={this.handleCloseClick}
        onCopyToAll={this.handleCopyToAll}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  changeFieldValue: (field, value) => change(STAFF_LEAVE_ADD_MODAL_FORM, field, value),
  injectValidation: (data) => stopSubmit(STAFF_LEAVE_ADD_MODAL_FORM, data),
  resetForm: () => reset(STAFF_LEAVE_ADD_MODAL_FORM),
}

const mapState = (state, {
  appSelectors,
  membershipsShiftsSelectors,
  membershipsShiftsSingleState,
}) => ({
  formErrors: getFormSubmitErrors(STAFF_LEAVE_ADD_MODAL_FORM)(state),
  formValues: getFormValues(STAFF_LEAVE_ADD_MODAL_FORM)(state),
  isSubmitting: appSelectors.getIsSubmitting(membershipsShiftsSingleState),
  membershipsShiftsErrors: membershipsShiftsSelectors.getMembershipsShiftsSingleErrorSelector(state),
})

const enhance = compose(
  withAppService,
  withMembershipsShiftsService,
  withMembershipsLeavesService,
  withModalService,
  connect(mapState, mapDispatch),
)

export default enhance(AddModalContainer)
