import _ from 'lodash'

import React from 'react'

import { Button, Checkbox, EmptyState, SearchBar, Select, Spinner, Toolbar, UserTile } from 'components'

import ChildPickerModalChildList from './components/ChildPickerModalChildList'

import {
  StyledContainer,
  StyledFooterContainer,
  StyledMainContainer,
  StyledToolbarContainer,
} from './ChildPickerModalStyled'

const ChildPickerModal = ({
  disableSelectAll,
  emptyStateText = "Look's like you don't have any children yet.",
  filteredChildrenList,
  getItemSelected,
  isEmpty,
  isLoading,
  onConfirmClick,
  onItemClick,
  onRoomFilterChange,
  onSearchChange,
  onSelectAllChange,
  roomFilter,
  roomFilterOptions,
  selectAll,
}) => {
  const renderChildrenListItem = (child) => {
    const { displayName, firstName, id, photo, surname } = child

    const selected = getItemSelected(id)

    return (
      <ChildPickerModalChildList.Item key={id}>
        <UserTile
          firstName={firstName}
          photo={photo}
          selected={selected}
          surname={surname}
          title={displayName}
          onClick={() => onItemClick(id)}
        />
      </ChildPickerModalChildList.Item>
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (isEmpty) {
      return <EmptyState text1={emptyStateText} />
    }

    if (filteredChildrenList && 0 === filteredChildrenList.length) {
      return <EmptyState text1="There are no children matching your search." />
    }

    return (
      <ChildPickerModalChildList>
        {_.map(filteredChildrenList, renderChildrenListItem)}
      </ChildPickerModalChildList>
    )
  }

  return (
    <StyledContainer>
      <SearchBar placeholder="Search child" onChange={onSearchChange} />
      <StyledToolbarContainer>
        <Toolbar>
          <Toolbar.Group>
            <Toolbar.Item>
              <Select
                options={roomFilterOptions}
                placeholder="All children"
                searchable={false}
                value={roomFilter}
                onChange={onRoomFilterChange}
              />
            </Toolbar.Item>
            {!disableSelectAll && (
              <Toolbar.Item>
                <Checkbox label="Select all" value={selectAll} onChange={onSelectAllChange} />
              </Toolbar.Item>
            )}
          </Toolbar.Group>
        </Toolbar>
      </StyledToolbarContainer>
      <StyledMainContainer>{renderContent()}</StyledMainContainer>
      <StyledFooterContainer>
        <Button
          disabled={isLoading}
          label="Confirm selection"
          onClick={onConfirmClick}
        />
      </StyledFooterContainer>
    </StyledContainer>
  )
}

export default ChildPickerModal
