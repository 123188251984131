import { Property } from 'csstype'

import React, { PropsWithChildren } from 'react'

import { StyledGridWrapper } from './GridStyled'
import GridItem from './GridItem'

export interface GridProps {
  flex?: boolean
  /**
   * Spacing between columns in pixels
   */
  spacing?: number
  wrap?: {
    desktop?: Property.FlexWrap
    mobile?: Property.FlexWrap
    table?: Property.FlexWrap
  }
}

interface GridRoot extends React.FC<PropsWithChildren<GridProps>> {
  Item?: typeof GridItem
}

const Grid: GridRoot = ({
  children,
  flex,
  spacing = 10,
  wrap,
}) => (
  <StyledGridWrapper $flex={flex} $spacing={spacing} $wrap={wrap}>
    {children}
  </StyledGridWrapper>
)

export default Grid
