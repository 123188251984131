import _ from 'lodash'

import React, { useMemo } from 'react'
import { Link } from 'react-router'

import { generateRoute } from 'utils/routing'

import { STATUS_TYPES } from 'services/legacy/invoices/constants'

import { Button, Currency, Table } from 'components'

import i18n from 'translations'

import InvoiceTableItem from './InvoiceTableItem'
import { getAmountDueDetail, getCarriedBroughtForwardDetail, getColspan } from './InvoiceTableHelper'

import { StyledCreditNoteLink } from './InvoiceTableStyled'
import AccountInCredit from '../AccountInCredit'

const InvoiceTable = ({
  child,
  invoicePreview,
  isEdit,
  onAddLineItemClick,
  onEditLineItemClick,
  onRemoveLineItemClick,
  preview,
}) => {
  const { invoice = {}, creditNotes = [], items, isGrouped } = invoicePreview || {}
  // NOTE: invoiceChild is needed for bulk preview popup
  const { child: invoiceChild, childBalance, creditCarried, status, total } = invoice
  const { balance, id: childId } = child || invoiceChild || {}

  const amountDueDetails = useMemo(() => getAmountDueDetail({
    balance,
    childBalance,
    ...invoice,
  }), [invoice, balance, childBalance])

  const carriedBroughtForwardDetails = useMemo(
    () => getCarriedBroughtForwardDetail({ amountDueDetails, balance, creditCarried, status, total }),
    [balance, creditCarried, status, total, amountDueDetails],
  )

  const colSpan = useMemo(() => getColspan(preview, isGrouped), [preview, isGrouped])

  const renderHeader = () => (
    <Table.Tr>
      <Table.Th>{i18n.t('components:Finance:InvoiceTable:Header:itemType')}</Table.Th>
      <Table.Th>{i18n.t('global:Description')}</Table.Th>
      {!isGrouped && (
        <React.Fragment>
          <Table.Th>{i18n.t('components:Finance:InvoiceTable:Header:cost')}</Table.Th>
          <Table.Th>{i18n.t('components:Finance:InvoiceTable:Header:quantity')}</Table.Th>
        </React.Fragment>
      )}
      <Table.Th>{i18n.t('components:Finance:InvoiceTable:Header:totalCost')}</Table.Th>
      {!preview && <Table.Th />}
    </Table.Tr>
  )

  const renderItems = () => _.map(items, (item, index) => (
    <InvoiceTableItem
      childId={childId}
      isEdit={isEdit}
      isGrouped={isGrouped}
      item={item}
      key={index}
      preview={preview}
      onEditClick={onEditLineItemClick}
      onRemoveClick={onRemoveLineItemClick}
    />
  ))

  const renderAddNewButton = () => (!preview ? (
    <Table.Tr>
      <Table.Td colSpan={colSpan}>
        <Button.ActionButton
          label={i18n.t('components:Finance:InvoiceTable:addLineItemLabel')}
          onClick={onAddLineItemClick}
        />
      </Table.Td>
    </Table.Tr>
  ) : null)

  const renderTotal = () => (
    <Table.Tr>
      <Table.SummaryTd colSpan={colSpan}>
        {i18n.t('components:Finance:InvoiceTable:totalSummaryLabel')}
        {' '}
        <Currency value={total ?? 0} />
      </Table.SummaryTd>
    </Table.Tr>
  )

  const renderBroughtForwardDetail = () => (
    carriedBroughtForwardDetails
      && items?.length
      && status !== STATUS_TYPES.DRAFT
      ? (
        <Table.Tr>
          <Table.Td />
          <Table.Td align="left" colSpan={colSpan - 2}>
            {carriedBroughtForwardDetails.label}
          </Table.Td>
          <Table.Td align="right">
            <Currency value={carriedBroughtForwardDetails.value} />
          </Table.Td>
        </Table.Tr>
      ) : null)

  const renderCreditNotes = () => (creditNotes?.length ? (
    _.map(creditNotes, ({ description, id: creditNoteId, total: creditNoteTotal }) => (
      <Table.Tr>
        <Table.Td align="left">
          <StyledCreditNoteLink>
            <Link to={generateRoute('CHILDREN.CHILD.FINANCE.CREDIT_NOTES', { childId, creditNoteId })}>
              {i18n.t('components:Finance:InvoiceTable:creditNoteLabel')}
            </Link>
          </StyledCreditNoteLink>
        </Table.Td>
        <Table.Td align="left" colSpan={colSpan - 2}>
          {description}
        </Table.Td>
        <Table.Td align="right">
          <Currency value={-creditNoteTotal} />
        </Table.Td>
      </Table.Tr>
    ))
  ) : null)

  const renderAmountDue = () => (amountDueDetails && items?.length && status !== STATUS_TYPES.DRAFT ? (
    <Table.Tr>
      <Table.SummaryTd colSpan={colSpan}>
        <span>
          {i18n.t('components:Finance:InvoiceTable:amountDue')}
          {' '}
          <Currency value={amountDueDetails.value} />
        </span>
      </Table.SummaryTd>
    </Table.Tr>
  ) : null)

  return (
    <React.Fragment>
      <Table noMargin>
        {renderHeader()}
        {renderItems()}
        {renderAddNewButton()}
        {renderTotal()}
        {renderBroughtForwardDetail()}
        {renderCreditNotes()}
        {renderAmountDue()}
      </Table>
      <AccountInCredit
        status={status}
        valueAfterInvoice={amountDueDetails?.valueAfterInvoice}
      />
    </React.Fragment>
  )
}

export default InvoiceTable
