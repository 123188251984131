let onLine = false
let isNeededToCheck = true

// @ts-ignore
// eslint-disable-next-line no-restricted-properties
navigator.__defineGetter__('onLine', () => {
  if (!isNeededToCheck) {
    return onLine
  }

  const xhr = new XMLHttpRequest()
  xhr.open('HEAD', '/', false)
  isNeededToCheck = false

  try {
    xhr.send()
    onLine = true
  } catch (e) {
    onLine = false
  }

  return onLine
})

window.addEventListener('online', () => {
  isNeededToCheck = true
})

window.addEventListener('offline', () => {
  isNeededToCheck = false
})
