import React, { PropsWithChildren } from 'react'
import { WrappedFieldProps } from 'redux-form'

import { SwitchProps } from 'components/Switch/Switch'

import { Switch } from 'components'

type FormSwitchFullProps = PropsWithChildren<SwitchProps> & WrappedFieldProps

const FormSwitch: React.FC<FormSwitchFullProps> = ({
  input: { onChange, value },
  meta: { error, submitFailed },
  ...rest
}) => (
  <Switch
    {...rest}
    error={submitFailed && error}
    value={value}
    onChange={onChange}
  />
)

export default FormSwitch
