import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { generateRoute } from 'utils/routing'

import { EmptyState, InfiniteScroll, Page, SearchBar, Section, Select, Spinner, Table, Toolbar } from 'components'

import i18n from 'translations'

import OrganizationNurseriesList from 'module/Organization/components/NurseriesList'

import { renderItem } from './NurseriesListHelpers'

const COLUMNS = [
  {
    align: 'left',
    field: 'nursery',
    title: _.upperFirst(i18n.t('global:nursery')),
  },
  {
    field: 'activeChildren',
    title: i18n.t('module:Nurseries:List:TableHeaders:activeChildren', {
      month: moment().format('MMMM'),
    }),
  },
  {
    field: 'domain',
    title: _.upperFirst(i18n.t('global:domain')),
  },
  {
    field: 'edit',
  },
]

const NurseriesListView = ({
  authAccessMap,
  featureFlagOptions,
  filters,
  isFetching,
  isOrganizationContext,
  nurseries,
  onFeatureFlagChange,
  onPageChange,
  onSearchChange,
  onTypeChange,
  page,
  pageCount,
  perPage,
  totalResults,
  typeOptions,
}) => {
  const { featureFlags, search, type } = filters

  const renderContent = () => {
    if (isFetching && (isOrganizationContext || 1 === page)) {
      return (
        <Spinner />
      )
    }

    if (!isFetching && !nurseries?.length) {
      return (
        <EmptyState
          icon="nursery"
          text1={i18n.t('module:Nurseries:List:EmptyState:text1')}
          text2={i18n.t('module:Nurseries:List:EmptyState:text2')}
        />
      )
    }

    if (isOrganizationContext) {
      return (
        <OrganizationNurseriesList
          authAccessMap={authAccessMap}
          isFetching={isFetching}
          nurseries={nurseries}
          page={page}
          pageCount={pageCount}
          perPage={perPage}
          totalResults={totalResults}
          onPageChange={onPageChange}
        />
      )
    }

    return (
      <InfiniteScroll
        dataLength={nurseries ? nurseries.length : 0}
        hasMore={page < pageCount}
        next={() => onPageChange((+page) + 1)}
      >
        <Table
          columns={COLUMNS}
          data={_.map(nurseries, renderItem)}
        />
      </InfiniteScroll>
    )
  }

  const actions = authAccessMap.module.NurseryAdd && (
    <Section.Actions
      primary={[{
        label: _.upperFirst(i18n.t('global:nursery')),
        to: generateRoute('NURSERIES.ADD'),
      }]}
    />
  )

  const renderToolbar = () => (
    <Toolbar bottomLine>
      <Toolbar.Group>
        <Toolbar.Item>
          <Select
            clearable={false}
            options={typeOptions}
            placeholder={i18n.t('module:Nurseries:List:NurseriesType:placeholder')}
            value={type}
            onChange={onTypeChange}
          />
        </Toolbar.Item>
        {!isOrganizationContext && (
          <Toolbar.Item>
            <Select
              options={featureFlagOptions}
              placeholder={i18n.t('module:Nurseries:List:FeatureFlag:placeholder')}
              value={featureFlags}
              multi
              onChange={onFeatureFlagChange}
            />
          </Toolbar.Item>
        )}
      </Toolbar.Group>
    </Toolbar>
  )

  return (
    <React.Fragment>
      <SearchBar
        placeholder={i18n.t('module:Nurseries:List:SearchBar:placeholder')}
        value={search}
        onChange={onSearchChange}
      />
      <Page.Section
        actions={actions}
        isLoading={isFetching && (isOrganizationContext || 1 === page)}
        title={i18n.t('module:Nurseries:title')}
      >
        {renderToolbar()}
        {renderContent()}
      </Page.Section>
    </React.Fragment>
  )
}

export default NurseriesListView
