import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'
import { withDeviceServiceProps } from 'services/device'

import { injectActions } from 'utils/service'

import * as childSelectors from './selectors'
import * as childHelpers from './helpers'
import actions from './actions'

export interface withChildServiceProps {
  childActions: typeof actions
  childSelectors: typeof childSelectors
}

const withChildService = <P extends withDeviceServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ child }) => ({ childState: child })

  const mapDispatch = injectActions('childActions', actions)

  const Component = ({ childActions, childState, ...others }) => {
    const getAllChildren = (params, page = 1, cb) => {
      let allChildrenData = []
      childActions.list({
        mergeResult: true,
        onSuccess: ({ data, meta }) => {
          const { limit, start } = meta
          const totalResults = meta.total_results

          allChildrenData = [
            ...allChildrenData,
            ...data,
          ]

          if (start * limit < totalResults) {
            getAllChildren(params, page + 1, cb)

            return
          }

          cb?.(allChildrenData)
        },
        params: {
          ...params,
          page,
        },
      })
    }

    return (
      <WrappedComponent
        childActions={childActions}
        childBankDetailsState={childState.bankDetails}
        childBirthdaysListState={childState.birthdaysList}
        childChangelogsState={childState.changelogs}
        childDropdownListState={childState.dropdownList}
        childFilesState={childState.files}
        childFinanceDetailsState={childState.financeDetails}
        childFundingSummaryCalculatorState={childState.fundingSummaryCalculator}
        childHelpers={{ ...childHelpers, getAllChildren }}
        childListState={childState.list}
        childSelectors={childSelectors}
        childSetupState={childState.setup}
        childSingleState={childState.single}
        {...others as P}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withChildService
