import React from 'react'
import { useSync, useUnreadNotificationsCount } from '@blossomdev/sync'

import { ZINDEX_ORDER } from 'constants/layout'

import ListContainer from 'module/Notification/List'

import ConversationMenu from '../ConversationMenu'

const NotificationRoot = ({ isOffline }) => {
  const { unreadNotificationsCount } = useUnreadNotificationsCount()
  const { resetNewNotificationsCount } = useSync()

  const handleVisibleChange = (visible) => {
    if (!visible || 0 === unreadNotificationsCount) {
      return
    }

    resetNewNotificationsCount()
  }

  return (
    <ConversationMenu
      buttonIcon="bell"
      isOffline={isOffline}
      margin="0 0 0 -5px"
      unreadCount={unreadNotificationsCount}
      zIndex={ZINDEX_ORDER.NOTIFICATION}
      onVisibleChange={handleVisibleChange}
    >
      {({ setIsOpen }) => (
        <ListContainer
          setIsOpen={setIsOpen}
          isPopoverContext
        />
      )}
    </ConversationMenu>
  )
}

export default NotificationRoot
