import React from 'react'
import { FieldArray, Field as FormField, reduxForm } from 'redux-form'

import { ROOM_TYPES, ROOM_TYPES_OPTIONS } from 'services/legacy/rooms/constants'
import { DAYS_OPTIONS } from 'constants/sessionDays'

import { isRequired, isValidInteger } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'

import { Button, Callout, Field, Form, GridLayout, Page, Section, Tooltip, Typography } from 'components'

import i18n from 'translations'

import {
  StyledDoubleTimePicker,
  StyledMinStaffLabels,
  StyledPause,
  StyledTopLabel,
  StyledTrashIcon,
} from './RoomsAddFormStyled'

export const ROOM_FORM = 'RoomForm'

const renderStaffRequirementsRow = (formValues) => (item, i, fields) => (
  <Form.Row key={i} margin="0 0 15px" multipleFieldsInARow>
    <Form.Row.FlexItem flex="0 0 130px" mobileFull>
      <StyledTopLabel>
        <Field.TopLabel
          hidden={!formValues?.staffRequirements[i].staffRequired}
          label={i18n.t('module:Rooms:RoomsAdd:Fields:StaffingEnabled:minStaff')}
        />
      </StyledTopLabel>
      <FormField
        component={Form.TextField}
        min="0"
        name={`${item}.staffRequired`}
        placeholder={i18n.t('module:Rooms:RoomsAdd:Fields:StaffingEnabled:minStaff')}
        type="number"
      />
    </Form.Row.FlexItem>
    <Form.Row.FlexItem flex="0 0 180px" mobileFull>
      <StyledTopLabel>
        <Field.TopLabel
          hidden={!formValues?.staffRequirements[i].dayOfWeek}
          label={i18n.t('module:Rooms:RoomsAdd:Fields:StaffingEnabled:weekday')}
        />
      </StyledTopLabel>
      <FormField
        component={Form.Select}
        name={`${item}.dayOfWeek`}
        options={DAYS_OPTIONS}
        placeholder={i18n.t('module:Rooms:RoomsAdd:Fields:StaffingEnabled:weekday')}
        validate={isRequired}
        width="100px"
      />
    </Form.Row.FlexItem>
    <Form.Row.FlexItem flex="0 0 285px" mobileFull>
      <StyledTopLabel>
        <Field.TopLabel
          hidden={!formValues?.staffRequirements[i].startTime && !formValues?.staffRequirements[i].endTime}
          label={i18n.t('global:Time')}
        />
      </StyledTopLabel>
      <StyledDoubleTimePicker>
        <FormField
          component={Form.TimePicker}
          name={`${item}.startTime`}
          placeholder={i18n.t('global:Time')}
          validate={isRequired}
        />
        <StyledPause>-</StyledPause>
        <FormField
          component={Form.TimePicker}
          name={`${item}.endTime`}
          placeholder={i18n.t('global:Time')}
          validate={isRequired}
        />
      </StyledDoubleTimePicker>
    </Form.Row.FlexItem>
    <Form.Row.FlexItem mobileFull>
      <StyledTrashIcon>
        <Form.RemoveRowButton trash onClick={() => fields.remove(i)} />
      </StyledTrashIcon>
    </Form.Row.FlexItem>
  </Form.Row>
)

const renderStaffRequirementsLabels = () => (
  <StyledMinStaffLabels>
    <Form.Row margin="0 0 10px 0" multipleFieldsInARow>
      <Form.Row.FlexItem flex="0 0 130px" mobileFull>
        <Typography margin="0 0 0 10px">
          {i18n.t('module:Rooms:RoomsAdd:Fields:StaffingEnabled:minStaff')}
        </Typography>
      </Form.Row.FlexItem>
      <Form.Row.FlexItem flex="0 0 180px" mobileFull>
        <Typography margin="0 0 0 10px">
          {i18n.t('module:Rooms:RoomsAdd:Fields:StaffingEnabled:weekday')}
        </Typography>
      </Form.Row.FlexItem>
      <Form.Row.FlexItem flex="0 0 285px" mobileFull>
        <Typography margin="0 0 0 10px">
          {i18n.t('global:Time')}
        </Typography>
      </Form.Row.FlexItem>
    </Form.Row>
  </StyledMinStaffLabels>
)

const renderStaffRequirements = ({ fields, formValues }) => (
  <React.Fragment>
    {renderStaffRequirementsLabels()}
    {fields.map(renderStaffRequirementsRow(formValues))}
    <Button.ActionButton
      label={i18n.t('module:Rooms:RoomsAdd:Button:addTime')}
      onClick={() => fields.push({
        dayOfWeek: null,
        endTime: null,
        staffRequired: null,
        startTime: null,
      })}
    />
  </React.Fragment>
)

const RoomsAddForm = ({
  errorMessages,
  formValues,
  handleSubmit,
  isArchived,
  isEdit,
  isLoading,
  onArchiveClick,
  onSubmit,
  permissionToPremiumSection,
}) => {
  const renderTeachingRoomOptions = () => (
    <GridLayout>
      <GridLayout.Group noVerticalMargin>
        <GridLayout.Item>
          <Form.Row label={i18n.t('module:Rooms:RoomsAdd:Fields:StaffingEnabled:startAge')} required>
            <Form.Row.FlexItem flex="0 0 110px" mobileFull>
              <FormField
                component={Form.TextField}
                min="0"
                name="startAgeInMonths"
                placeholder={i18n.t('global:Age')}
                type="number"
                validate={[isRequired, isValidInteger]}
              />
            </Form.Row.FlexItem>
            <Form.Row.FlexItem flex="0 0 70px">
              &nbsp;
              {i18n.t('global:months')}
            </Form.Row.FlexItem>
          </Form.Row>
        </GridLayout.Item>
        <GridLayout.Item>
          <Form.Row label={i18n.t('module:Rooms:RoomsAdd:Fields:StaffingEnabled:capacity')} required>
            <Form.Row.FlexItem>
              <FormField
                component={Form.TextField}
                min="0"
                name="capacity"
                placeholder={i18n.t('module:Rooms:RoomsAdd:Fields:StaffingEnabled:capacity')}
                type="number"
                validate={[isRequired, isValidInteger]}
              />
            </Form.Row.FlexItem>
          </Form.Row>
        </GridLayout.Item>
      </GridLayout.Group>
      <GridLayout.Group noVerticalMargin>
        <GridLayout.Item>
          <Form.Row label={i18n.t('module:Rooms:RoomsAdd:Fields:StaffingEnabled:finishAge')} required>
            <Form.Row.FlexItem flex="0 0 110px" mobileFull>
              <FormField
                component={Form.TextField}
                max="216"
                min="0"
                name="endAgeInMonths"
                placeholder={i18n.t('global:Age')}
                type="number"
                validate={[isRequired, isValidInteger]}
              />
            </Form.Row.FlexItem>
            <Form.Row.FlexItem flex="0 0 70px">
              &nbsp;
              {i18n.t('global:months')}
            </Form.Row.FlexItem>
          </Form.Row>
        </GridLayout.Item>
        <GridLayout.Item>
          <Form.Row label={i18n.t('module:Rooms:RoomsAdd:Fields:minimumStaffRequired')}>
            <Form.Row.FlexItem>
              <FormField
                component={Form.TextField}
                min="0"
                name="staffRequired"
                placeholder={i18n.t('module:Rooms:RoomsAdd:Fields:minimumStaffRequired')}
                type="number"
                validate={[isValidInteger]}
              />
            </Form.Row.FlexItem>
          </Form.Row>
        </GridLayout.Item>
      </GridLayout.Group>
    </GridLayout>
  )

  const renderOtherRoomOptions = () => (
    <Form.Row label={i18n.t('module:Rooms:RoomsAdd:Fields:minimumStaffRequired')}>
      <Form.Row.FlexItem>
        <FieldArray
          component={renderStaffRequirements}
          name="staffRequirements"
          props={{ formValues }}
        />
      </Form.Row.FlexItem>
    </Form.Row>
  )

  const renderAdditionalOptions = () => {
    switch (formValues?.type?.value || formValues?.type) {
      case ROOM_TYPES.TEACHING:
        return renderTeachingRoomOptions()
      case ROOM_TYPES.NON_TEACHING:
        return renderOtherRoomOptions()
      case ROOM_TYPES.OUTSIDE:
        return renderOtherRoomOptions()
      default:
        return null
    }
  }

  const renderBasicOptions = () => (
    <React.Fragment>
      <Form.Row label={i18n.t('module:Rooms:RoomsAdd:Fields:StaffingEnabled:roomType')} required>
        <Form.Row.FlexItem flex="0 0 300px" mobileFull>
          <Tooltip
            placement="right"
            title={isEdit
              ? i18n.t('module:Rooms:RoomsAdd:Tooltip:archive')
              : ''}
          >
            <FormField
              clearable={false}
              component={Form.Select}
              disabled={isEdit}
              name="type"
              options={ROOM_TYPES_OPTIONS}
              placeholder={i18n.t('module:Rooms:RoomsAdd:Fields:StaffingEnabled:roomType')}
              validate={isRequired}
            />
          </Tooltip>
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:Rooms:RoomsAdd:Fields:StaffingEnabled:roomName')} required>
        <Form.Row.FlexItem flex="0 0 300px" mobileFull>
          <FormField
            component={Form.TextField}
            name="name"
            placeholder={i18n.t('module:Rooms:RoomsAdd:Fields:StaffingEnabled:roomName')}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
    </React.Fragment>
  )

  const renderFooter = () => (
    <Form.FooterActions
      cancelLink={generateRoute('ROOMS.INDEX')}
      isSubmitting={isLoading}
      submitLabel={isEdit ? i18n.t('global:Save') : i18n.t('global:Create')}
    />
  )

  const actions = (
    <Section.Actions
      options={[{
        onClick: onArchiveClick(!isArchived),
        type: isArchived ? 'unarchive' : 'archive',
      }]}
    />
  )

  return (
    <Page.Section
      actions={isEdit && actions}
      title={isEdit ? i18n.t('module:Rooms:RoomsAdd:Title:edit') : i18n.t('module:Rooms:RoomsAdd:Title:create')}
    >
      <Callout content={errorMessages} error />
      <Form onSubmit={handleSubmit(onSubmit)}>
        {renderBasicOptions()}
        {permissionToPremiumSection && renderAdditionalOptions()}
        {renderFooter()}
      </Form>
    </Page.Section>
  )
}

export default reduxForm({ form: ROOM_FORM })(RoomsAddForm)
