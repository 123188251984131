import _ from 'lodash'

import { generateBasicActions } from 'utils/service'

export const GET_CHILD_ADHOC_SESSION = 'GET_CHILD_ADHOC_SESSION'
export const GET_CHILD_ADHOC_SESSION_SUCCESS = 'GET_CHILD_ADHOC_SESSION_SUCCESS'
export const GET_CHILD_ADHOC_SESSION_FAILED = 'GET_CHILD_ADHOC_SESSION_FAILED'

export const CREATE_BULK_CHILD_ADHOC_SESSION = 'CREATE_BULK_CHILD_ADHOC_SESSION'
export const CREATE_BULK_CHILD_ADHOC_SESSION_SUCCESS = 'CREATE_BULK_CHILD_ADHOC_SESSION_SUCCESS'
export const CREATE_BULK_CHILD_ADHOC_SESSION_FAILED = 'CREATE_BULK_CHILD_ADHOC_SESSION_FAILED'

export const CREATE_CHILD_ADHOC_SESSION = 'CREATE_CHILD_ADHOC_SESSION'
export const CREATE_CHILD_ADHOC_SESSION_SUCCESS = 'CREATE_CHILD_ADHOC_SESSION_SUCCESS'
export const CREATE_CHILD_ADHOC_SESSION_FAILED = 'CREATE_CHILD_ADHOC_SESSION_FAILED'

export const UPDATE_CHILD_ADHOC_SESSION = 'UPDATE_CHILD_ADHOC_SESSION'
export const UPDATE_CHILD_ADHOC_SESSION_SUCCESS = 'UPDATE_CHILD_ADHOC_SESSION_SUCCESS'
export const UPDATE_CHILD_ADHOC_SESSION_FAILED = 'UPDATE_CHILD_ADHOC_SESSION_FAILED'

export const ARCHIVE_CHILD_ADHOC_SESSION = 'ARCHIVE_CHILD_ADHOC_SESSION'
export const ARCHIVE_CHILD_ADHOC_SESSION_SUCCESS = 'ARCHIVE_CHILD_ADHOC_SESSION_SUCCESS'
export const ARCHIVE_CHILD_ADHOC_SESSION_FAILED = 'ARCHIVE_CHILD_ADHOC_SESSION_FAILED'

export const CLEAR_CHILD_ADHOC_SESSION = 'CLEAR_CHILD_ADHOC_SESSION'

export const getAdhocSession = (options = {}) => ({ childAdhocSessionsApiClient }) => generateBasicActions.get({
  constants: {
    failed: GET_CHILD_ADHOC_SESSION_FAILED,
    init: GET_CHILD_ADHOC_SESSION,
    success: GET_CHILD_ADHOC_SESSION_SUCCESS,
  },
  options,
  service: childAdhocSessionsApiClient,
  serviceMethod: 'get',
})

export const createBulkAdhocSession = (payload, onSuccess) => ({ childAdhocSessionsApiClient }) => (dispatch) => {
  dispatch({
    payload,
    type: CREATE_BULK_CHILD_ADHOC_SESSION,
  })

  const promiseList = _.map(payload, (item) => {
    const { childId, session } = item

    return childAdhocSessionsApiClient.create(childId, session)
  })

  return Promise.all(promiseList).then(
    (data) => {
      dispatch({
        payload: data,
        type: CREATE_BULK_CHILD_ADHOC_SESSION_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: CREATE_BULK_CHILD_ADHOC_SESSION_FAILED,
      })
    },
  )
}

export const createAdhocSession = (childId, payload, onSuccess) => ({ childAdhocSessionsApiClient }) => (dispatch) => {
  dispatch({
    childId,
    payload,
    type: CREATE_CHILD_ADHOC_SESSION,
  })

  return childAdhocSessionsApiClient.create(childId, payload).then(
    (data) => {
      dispatch({
        payload: data,
        type: CREATE_CHILD_ADHOC_SESSION_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: CREATE_CHILD_ADHOC_SESSION_FAILED,
      })
    },
  )
}

export const updateAdhocSession = (childId, id, payload, onSuccess) => ({ childAdhocSessionsApiClient }) => (
  dispatch,
) => {
  dispatch({
    childId,
    id,
    payload,
    type: UPDATE_CHILD_ADHOC_SESSION,
  })

  childAdhocSessionsApiClient.update(childId, id, payload).then(
    (data) => {
      dispatch({
        payload: data,
        type: UPDATE_CHILD_ADHOC_SESSION_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: UPDATE_CHILD_ADHOC_SESSION_FAILED,
      })
    },
  )
}

export const archiveAdhocSession = (id, payload, onSuccess) => ({ childAdhocSessionsApiClient }) => (dispatch) => {
  dispatch({
    id,
    payload,
    type: ARCHIVE_CHILD_ADHOC_SESSION,
  })

  childAdhocSessionsApiClient.archive(id, payload).then(
    (data) => {
      dispatch({
        payload: data,
        type: ARCHIVE_CHILD_ADHOC_SESSION_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: ARCHIVE_CHILD_ADHOC_SESSION_FAILED,
      })
    },
  )
}

export const clearAdhocSessionSingle = () => ({
  type: CLEAR_CHILD_ADHOC_SESSION,
})
