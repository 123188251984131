import {
  CLEAR_MEMBERSHIP_REGISTER,
  CREATE_MEMBERSHIP_REGISTER,
  CREATE_MEMBERSHIP_REGISTER_ENTRY,
  CREATE_MEMBERSHIP_REGISTER_ENTRY_FAILED,
  CREATE_MEMBERSHIP_REGISTER_FAILED,
  CREATE_MEMBERSHIP_REGISTER_SUCCESS,
  GET_MEMBERSHIP_REGISTER,
  GET_MEMBERSHIP_REGISTER_FAILED,
  GET_MEMBERSHIP_REGISTER_SUCCESS,
  SELECTED_MEMBERSHIP_REGISTER_STATUS,
  UPDATE_BATCH_MEMBERSHIP_REGISTER,
  UPDATE_BATCH_MEMBERSHIP_REGISTER_FAILED,
  UPDATE_BATCH_MEMBERSHIP_REGISTER_SUCCESS,
  UPDATE_MEMBERSHIP_REGISTER,
  UPDATE_MEMBERSHIP_REGISTER_ENTRY,
  UPDATE_MEMBERSHIP_REGISTER_ENTRY_FAILED,
  UPDATE_MEMBERSHIP_REGISTER_FAILED,
  UPDATE_MEMBERSHIP_REGISTER_MANUALLY,
  UPDATE_MEMBERSHIP_REGISTER_SUCCESS,
} from './constants'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false,
  selectedStatus: null,
}

export default (state = initialState, { error, payload, type }) => {
  switch (type) {
    case GET_MEMBERSHIP_REGISTER:
      return { ...state, error: initialState.error, isFetching: true }
    case GET_MEMBERSHIP_REGISTER_SUCCESS:
      return { ...state, data: payload?.data, isFetching: false }
    case CREATE_MEMBERSHIP_REGISTER:
    case UPDATE_MEMBERSHIP_REGISTER:
      return { ...state, error: initialState.error, isSubmitting: true }
    case CREATE_MEMBERSHIP_REGISTER_SUCCESS:
    case UPDATE_MEMBERSHIP_REGISTER_SUCCESS:
    case UPDATE_MEMBERSHIP_REGISTER_MANUALLY:
      return { ...state, data: payload.data, isSubmitting: false }
    case CREATE_MEMBERSHIP_REGISTER_FAILED:
    case UPDATE_MEMBERSHIP_REGISTER_FAILED:
    case UPDATE_BATCH_MEMBERSHIP_REGISTER_SUCCESS:
      return { ...state, error, isSubmitting: false }
    case GET_MEMBERSHIP_REGISTER_FAILED:
    case CREATE_MEMBERSHIP_REGISTER_ENTRY_FAILED:
    case UPDATE_MEMBERSHIP_REGISTER_ENTRY_FAILED:
    case UPDATE_BATCH_MEMBERSHIP_REGISTER_FAILED:
      return { ...state, error, isFetching: false, isSubmitting: false }
    case CREATE_MEMBERSHIP_REGISTER_ENTRY:
    case UPDATE_MEMBERSHIP_REGISTER_ENTRY:
    case UPDATE_BATCH_MEMBERSHIP_REGISTER:
      return { ...state, error: initialState.error, isSubmitting: true }
    case SELECTED_MEMBERSHIP_REGISTER_STATUS:
      return { ...state, selectedStatus: payload }
    case CLEAR_MEMBERSHIP_REGISTER:
      return initialState
    default:
      return state
  }
}
