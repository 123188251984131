import React from 'react'
import { Field, FieldArray, reduxForm } from 'redux-form'

import { isChildSession, isEndTimeGreaterThanStartTime, isRequired } from 'utils/fieldValidation'

import { Button, Callout, Form, Spinner, Typography } from 'components'

import i18n from 'translations'

import { StyledSessionsContainer } from './RegisterExtraSessionFormStyled'

const renderSession = (onRemoveSession) => (session, index, fields) => (
  <Field
    component={Form.NurserySession}
    dropdownWidth="100%"
    name={session}
    validate={[isChildSession, isEndTimeGreaterThanStartTime]}
    onDeleteClick={onRemoveSession(fields, index)}
  />
)

const renderSessions = ({ fields, onAddSession, onRemoveSession }, index) => (
  <StyledSessionsContainer key={index}>
    <Form.Row.FlexItem flex={1}>
      {fields.map(renderSession(onRemoveSession))}
    </Form.Row.FlexItem>
    <Form.Row.FlexItem flex={1}>
      <Button.ActionButton label="Add another session" onClick={onAddSession(fields)} />
    </Form.Row.FlexItem>
  </StyledSessionsContainer>
)

const renderChild = (onAddSession, onRemoveChild, onRemoveSession) => (
  child,
  index,
  fields,
) => (
  <div key={index}>
    <Form.Row label="Select a child to add an extra session" verticalLabel>
      <Form.Row.FlexItem flex={1}>
        <Field
          component={Form.InfiniteDropdowns.Children}
          name={`${child}.child`}
          placeholder="Search for a child..."
          validate={isRequired}
        />
      </Form.Row.FlexItem>
      <Form.Row.FlexItem flex="0 0 40px">
        <Form.RemoveRowButton onClick={onRemoveChild(fields, index)} />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.Row label="Select 1 or more sessions" verticalLabel>
      <FieldArray
        component={renderSessions}
        name={`${child}.sessions`}
        props={{ onAddSession, onRemoveSession }}
      />
    </Form.Row>
  </div>
)

const renderChildren = ({
  fields,
  onAddChild,
  onAddSession,
  onRemoveChild,
  onRemoveSession,
}) => (
  <div>
    <div>
      {fields.map(renderChild(onAddSession, onRemoveChild, onRemoveSession))}
    </div>
    <div>
      <Typography margin="20px 0">
        Do you want to add an extra session for another child?
      </Typography>
      <Button.ActionButton label="Add another child" onClick={onAddChild(fields)} />
    </div>
  </div>
)

const RegisterExtraSessionForm = ({
  errorMessages,
  handleSubmit,
  isLoading,
  isSubmitting,
  onAddChild,
  onAddSession,
  onCancelClick,
  onRemoveChild,
  onRemoveSession,
  onSubmit,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldArray
          component={renderChildren}
          name="children"
          props={{ onAddChild, onAddSession, onRemoveChild, onRemoveSession }}
        />
        <Form.FooterActions
          isSubmitting={isSubmitting}
          submitLabel={i18n.t('global:Save')}
          onCancelClick={onCancelClick}
        />
      </Form>
    )
  }

  return (
    <div>
      <Callout content={errorMessages} error />
      {renderContent()}
    </div>
  )
}

export default reduxForm({ form: 'RegisterExtraSessionForm' })(RegisterExtraSessionForm)
