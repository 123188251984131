import { createSelector } from 'reselect'

import { getAuthProfile } from '../user/selectors'

export const getAuthNursery = createSelector([getAuthProfile], (authProfile) => {
  if (!authProfile || !authProfile.nursery) {
    return null
  }

  return authProfile.nursery
})

export const isMontessori = createSelector([getAuthNursery], (nursery) => {
  if (!nursery) {
    return false
  }

  return nursery.isMontessori
})

export const isOrganisationNursery = (authentication) => {
  if (!authentication.common || !authentication.common.profile || !authentication.common.profile.nursery) {
    return false
  }

  return !!authentication.common.profile.nursery.organization
}
