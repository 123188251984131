import React, { PropsWithChildren } from 'react'

import { SectionProps } from 'components/Section/Section'
import { PageProps } from 'components/Page/Page'

import { Callout, Page, Section } from 'components'

interface PageSectionProps {
  disableBreadcrumb?: boolean
  errorMessages?: string[]
  isLoading?: boolean
}

type PageSectionFinalProps = PropsWithChildren<PageSectionProps & SectionProps & PageProps>

const PageSection: React.FC<PageSectionFinalProps> = ({
  alwaysExpanded,
  children,
  disableBreadcrumb,
  errorMessages,
  isLoading,
  ...rest
}) => (
  <Page
    alwaysExpanded={alwaysExpanded}
    disableBreadcrumb={disableBreadcrumb}
    isLoading={isLoading}
  >
    <Page.Content>
      {errorMessages && (
        <Callout content={errorMessages} error />
      )}
      <Section {...rest}>
        {children}
      </Section>
    </Page.Content>
  </Page>
)

export default PageSection
