import _ from 'lodash'

import { createSelector } from 'reselect'

const getFrameworkProgressLevelsState = (state) => state.frameworkProgressLevels

export const getFrameworkProgressLevelsListState = createSelector(
  [getFrameworkProgressLevelsState],
  (state) => state.list,
)

export const getFrameworkProgressLevels = createSelector(
  [getFrameworkProgressLevelsListState],
  (state) => _.sortBy(state.data, ({ sortOrder }) => sortOrder),
)
