import { generateBasicActions } from 'utils/service'

import { CLEAR_RELATIONS, LIST_RELATIONS, LIST_RELATIONS_FAILED, LIST_RELATIONS_SUCCESS } from './constants'

export const list = (options = {}) => ({ relationsApiClient }) => generateBasicActions.list({
  constants: {
    failed: LIST_RELATIONS_FAILED,
    init: LIST_RELATIONS,
    success: LIST_RELATIONS_SUCCESS,
  },
  options,
  service: relationsApiClient,
  serviceMethod: 'list',
})

export const clear = () => ({
  type: CLEAR_RELATIONS,
})
