import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change, getFormValues, initialize, stopSubmit } from 'redux-form'

import { TYPE_OF_TRACKING } from 'services/legacy/formativeReports/constants'
import { FRAMEWORK_STRUCTURE_APPLICABLE } from 'services/legacy/frameworks/constants'

import { generateRoute } from 'utils/routing'
import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withModalService } from 'services/utils/modal'
import { withFormativeReportsService } from 'services/legacy/formativeReports'
import { withSecurityService } from 'services/security'
import { withSnackbarService } from 'services/utils/snackbar'
import { withFrameworksService } from 'services/legacy/frameworks'
import { withRouter } from 'services/router'

import i18n from 'translations'

import { FORMATIVE_REPORTS_PAGE_TYPE } from 'module/Learning/FormativeReports/constants'
import FormativeReportsPreviewView from './FormativeReportsPreviewView'
import {
  CHILD_LEARNING_JOURNEY_FORMATIVE_REPORTS_PREVIEW_FORM,
} from './components/FormativeReportsPreviewForm/FormativeReportsPreviewForm'
import {
  FORMATIVE_REPORT_GROUPS_BASIC,
  FORMATIVE_REPORT_ITEMS_GROUPS,
  FORMATIVE_REPORT_MONTESSORI_ITEMS_GROUPS,
  NON_FRAMEWORK_TABS,
  NURSERY_SETTINGS_GROUP,
  TABS_GROUPS,
  TABS_TYPES,
} from './constants'

export const FRAMEWORK_LIST_GROUPS = {
  read: [
    'structure',
  ],
}

const FRAMEWORK_ITEM_GROUPS = {
  read: [
    'framework.areas',
    'structure',
    'structure.formativeReport',
    'frameworkArea',
    'frameworkArea.groups',
    'frameworkGroup',
    'frameworkGroup.categories',
    'frameworkCategory',
    'frameworkCategory.items',
    'frameworkItem',
    'frameworkItem.linkedItems',
  ],
}

const CREATE_AGE_BANDS_GROUPS = {
  read: [
    'frameworkGroup',
    'formativeReportAttainmentLevel',
    'formativeReportAttainmentLevel.progressLevel',
    'formativeReportAttainmentLevel.frameworkGroup',
  ],
}

const CREATE_COMMENT_GROUPS = {
  read: [
    'frameworkArea',
    'formativeReportComment',
    'formativeReportComment.frameworkArea',
  ],
}

const LEGACY_FORMATIVE_REPORT_VERSION = 1

const FORMATIVE_REPORT_ITEMS_LIMIT = 603

const INITIAL_STATE = {
  activeTab: null,
  ageBandsCache: {},
  downloadedFormativeReportItems: {},
  downloadedTabsData: {
    [TABS_TYPES.CHILD_OVERVIEW]: false,
    [TABS_TYPES.COMMENTS]: false,
    [TABS_TYPES.FRAMEWORK]: false,
    [TABS_TYPES.MONTESSORI]: false,
  },
  frameworkDetailsCache: {},
  initialized: false,
  sharedWithParents: false,
}

class FormativeReportsPreviewContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ...INITIAL_STATE,
      pageType: this.getPageType(),
    }
  }

  componentDidMount() {
    const { frameworksActions, nurseriesActions, nurseryOptions } = this.props
    const nurseryApiParams = { groups: NURSERY_SETTINGS_GROUP }

    frameworksActions.list({
      params: [{
        groups: FRAMEWORK_LIST_GROUPS,
      }],
    })
    nurseriesActions.get(nurseryOptions.id, {
      onSuccess: (nurseryData) => {
        this.getFormativeReport(
          FORMATIVE_REPORT_GROUPS_BASIC,
          (formativeReportData) => this.getFormativeReportSuccessInitial(
            formativeReportData.data, nurseryData.data.nurserySettings.learning),
        )
      },
      params: nurseryApiParams,
    })
  }

  componentWillUnmount() {
    const { formativeReportsActions, frameworksActions, nurseriesActions } = this.props

    frameworksActions.clear()
    formativeReportsActions.clear()
    formativeReportsActions.clearFormativeReportItems()
    formativeReportsActions.clearFormativeReportMontessoriItems()
    nurseriesActions.clear()
  }

  componentDidUpdate(prevProps) {
    const { nurseryLearningSettings, params } = this.props
    const { params: prevParams } = prevProps
    const { formativeReportId } = params
    const { formativeReportId: prevFormativeReportId } = prevParams

    if (
      prevFormativeReportId
      && formativeReportId
      && prevFormativeReportId !== formativeReportId
    ) {
      this.setState({ ...INITIAL_STATE }, () => {
        this.getFormativeReport(
          FORMATIVE_REPORT_GROUPS_BASIC,
          (formativeReportData) => this.getFormativeReportSuccessInitial(
            formativeReportData.data, nurseryLearningSettings,
          ),
        )
      })
    }
  }

  getFormativeReport = (groups, onSuccess) => {
    const {
      formativeReportsActions,
      params,
    } = this.props
    const { formativeReportId } = params

    if (groups?.read) {
      formativeReportsActions.getFormativeReport(
        formativeReportId,
        { groups },
        onSuccess,
        this.getRoute,
      )

      return
    }

    onSuccess?.()
  }

  getPageType = () => {
    const { location: { pathname }, params: { formativeReportId } } = this.props

    if (generateRoute('APPROVALS.FORMATIVE_REPORTS.PREVIEW', { formativeReportId }) === pathname) {
      return FORMATIVE_REPORTS_PAGE_TYPE.APPROVALS
    }

    if (generateRoute('LEARNING.FORMATIVE_REPORTS.PREVIEW', { formativeReportId }) === pathname) {
      return FORMATIVE_REPORTS_PAGE_TYPE.LEARNING
    }

    return FORMATIVE_REPORTS_PAGE_TYPE.CHILD
  }

  getRoute = (redirectToChild = true) => {
    const { navigate, params: { childId } } = this.props
    const { pageType } = this.state

    if (pageType === FORMATIVE_REPORTS_PAGE_TYPE.LEARNING) {
      navigate(generateRoute('LEARNING.FORMATIVE_REPORTS'))
    }

    if (pageType === FORMATIVE_REPORTS_PAGE_TYPE.APPROVALS) {
      navigate(generateRoute('APPROVALS.FORMATIVE_REPORTS'))
    }

    if (pageType === FORMATIVE_REPORTS_PAGE_TYPE.CHILD && redirectToChild) {
      navigate(generateRoute('CHILDREN.CHILD.LEARNING_JOURNEY.FORMATIVE_REPORTS', { childId }))
    }
  }

  getFormativeReportSuccessInitial = (formativeReport, nurseryLearningSettings) => {
    const frameworksList = this.getFilteredFrameworksListByFormativeReportVersion()
    const { childOverviewSectionOnFormativeReportsVisible = true } = nurseryLearningSettings || {}
    const { sharedWithParents } = formativeReport || {}

    this.setState({ sharedWithParents })

    if (childOverviewSectionOnFormativeReportsVisible) {
      return this.handleChangeTab(TABS_TYPES.CHILD_OVERVIEW)
    }

    if (!frameworksList.length) {
      return this.handleChangeTab(TABS_TYPES.COMMENTS)
    }

    return this.handleChangeTab(frameworksList[0]?.id)
  }

  handleSubmitSuccess = () => {
    this.getRoute()
  }

  handleSubmitFailed = (response) => {
    const { formativeReport, injectValidation } = this.props

    const errors = getBackendErrors(response)
    if (!errors) {
      return false
    }

    if (errors.dateFrom || errors.dateTo) {
      errors.date = errors.dateFrom || errors.dateTo
    }

    if (formativeReport.period.type === TYPE_OF_TRACKING.TERM) {
      errors.term = errors.period
    }

    return injectValidation(CHILD_LEARNING_JOURNEY_FORMATIVE_REPORTS_PREVIEW_FORM, errors)
  }

  handleSave = () => {
    this.handleSubmit(this.handleSaveSuccess, null, {}, true)
  }

  handleChangeStatus = (status) => {
    const values = {
      status,
    }

    this.handleSubmit(this.handleSubmitSuccess, null, values, true)
  }

  handleFormCommentsBlur = () => {
    const { isSubmitting } = this.props

    if (isSubmitting) {
      return
    }

    this.handleSubmit(null, null, {}, true)
  }

  handleFormCommentsChange = () => _.debounce(this.handleFormCommentsBlur, 5000)

  handleSaveSuccess = () => {
    const { snackbarActions } = this.props

    snackbarActions.show({
      message: i18n.t('module:Learning:FormativeReports:FormativeReportsPreview:messageOnSave'),
    })

    this.getRoute(false)
  }

  handleCommentsBlur = (id, area, type) => {
    const { formValues: { comments }, formativeReport, formativeReportsActions } = this.props
    const currentComment = _.find(formativeReport.comments, (i) => id === i.id)

    const body = {
      // NOTE: workaround for src/services/ServiceBase.ts::normalizeEmptyStrings. I did not want to make
      // core change by removing that normalizer just in case breaking other part of the app. That is why I just added
      // " " instead od "". When moving to axios and getting ride of the service-base we can remove it that too.
      comment: comments?.[area.id]?.[type] || ' ',
      frameworkArea: {
        id: area.id,
      },
      report: {
        id: formativeReport.id,
      },
      type,
    }

    if (id) {
      if (currentComment?.comment === comments?.[area.id]?.[type]) {
        return null
      }

      return formativeReportsActions.updateComments(
        id,
        body,
      )
    }

    return formativeReportsActions.createComment({
      body,
      params: [{
        groups: CREATE_COMMENT_GROUPS,
      }],
    })
  }

  handleCommentsChange = _.debounce(this.handleCommentsBlur, 5000)

  handleAttainmentLevelsSubmitFailed = (response, group) => {
    const { injectValidation } = this.props

    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    const attainmentLevelErrors = {
      attainmentLevels: {
        [group]: errors,
      },
    }

    return injectValidation(CHILD_LEARNING_JOURNEY_FORMATIVE_REPORTS_PREVIEW_FORM, attainmentLevelErrors)
  }

  handleAttainmentLevelsChange = (typeOfProgressLevel, group) => {
    setTimeout(() => {
      const { formValues, formativeReport, formativeReportsActions } = this.props
      const { attainmentLevels } = formativeReport
      const { attainmentLevels: attainmentLevelsFormValue } = formValues
      const attainmentLevel = _.find(attainmentLevels, ({ frameworkGroup }) => group.id === frameworkGroup.id)
      const attainmentLevelFormValue = attainmentLevelsFormValue[group.id]

      const body = {
        frameworkGroup: {
          id: group.id,
        },
        report: {
          id: formativeReport.id,
        },
      }

      if (attainmentLevelFormValue.range?.value || attainmentLevelFormValue.range) {
        body.range = attainmentLevelFormValue.range?.value || attainmentLevelFormValue.range
      }

      if (attainmentLevelFormValue.progressLevel?.value) {
        body.progressLevel = {
          id: attainmentLevelFormValue.progressLevel?.value,
          type: typeOfProgressLevel,
        }
      }

      if (attainmentLevel?.id) {
        if (!body.range) {
          body.range = ''
        }

        return formativeReportsActions.updateAgeBands({
          body,
          onFailed: (response) => this.handleAttainmentLevelsSubmitFailed(response, group.id),
          params: [attainmentLevel.id],
        })
      }

      return formativeReportsActions.createAgeBands({
        body,
        onFailed: (response) => this.handleAttainmentLevelsSubmitFailed(response, group.id),
        params: [{
          groups: CREATE_AGE_BANDS_GROUPS,
        }],
      })
    })
  }

  handleSubmit = (
    handleSubmitSuccess,
    handleSubmitFailed,
    payload,
    manualSubmit = false,
  ) => {
    const {
      assessmentPeriodsDropdown,
      formValues,
      formativeReport,
      formativeReportsActions,
      formativeReportsSelectors,
      injectValidation,
      params,
    } = this.props
    const { formativeReportId } = params
    const { activeTab, sharedWithParents } = this.state

    formValues.typeOfTracking = formativeReport.period.type
    formValues.sharedWithParents = sharedWithParents
    injectValidation(CHILD_LEARNING_JOURNEY_FORMATIVE_REPORTS_PREVIEW_FORM)

    const values = formativeReportsSelectors.getFormData(formValues, null, formativeReport, assessmentPeriodsDropdown)

    const handleSubmitSuccessClb = () => {
      // NOTE: manualSubmit means user click tick or button, not autosave
      if (!manualSubmit) {
        const isFrameworkPage = (
          !_.find(NON_FRAMEWORK_TABS, (value) => value === activeTab)
        )

        if (isFrameworkPage) {
          formativeReportsActions.clearFormativeReportItems()

          this.setState((prevState) => ({
            downloadedFormativeReportItems: {
              ...prevState.downloadedFormativeReportItems,
              [activeTab]: false,
            },
            downloadedTabsData: {
              ...prevState.downloadedTabsData,
              [TABS_TYPES.FRAMEWORK]: false,
            },
          }))
        } else {
          this.setState((prevState) => ({
            downloadedTabsData: {
              ...prevState.downloadedTabsData,
              [activeTab]: false,
            },
          }))
        }
      }

      setTimeout(() => {
        this.handleChangeTab(activeTab)
      })

      if (handleSubmitSuccess) {
        handleSubmitSuccess()
      }
    }

    formativeReportsActions.updateReport(
      formativeReportId,
      { groups: FORMATIVE_REPORT_GROUPS_BASIC },
      { ...payload, ...values },
      handleSubmitSuccessClb,
      handleSubmitFailed || this.handleSubmitFailed,
    )
  }

  handleRestoreOldValue = (fieldName) => {
    const {
      assessmentPeriodsDropdown,
      changeValue,
      formativeReport,
      formativeReportsSelectors,
      params,
      terms,
    } = this.props

    const initialValues = formativeReportsSelectors.getPreviewInitialValues(
      formativeReport,
      assessmentPeriodsDropdown,
      terms,
      params,
    )

    changeValue(fieldName, initialValues[fieldName])
  }

  handleRemoveClickSuccess = () => {
    const {
      formativeReportsActions,
      params,
    } = this.props
    const { formativeReportId } = params

    formativeReportsActions.remove(
      formativeReportId,
      () => this.getRoute(),
    )
  }

  handleRemoveClick = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'trash',
      onConfirm: this.handleRemoveClickSuccess,
      text: i18n.t('module:Learning:FormativeReports:FormativeReportsPreview:messageOnDelete'),
    })
  }

  handleChangeTabSuccess = (tab, tabName) => {
    const {
      assessmentPeriodsDropdown,
      formValues,
      formativeReport,
      formativeReportsSelectors,
      initializeValues,
      params,
      terms,
    } = this.props
    const isFrameworkPage = (
      !_.find(NON_FRAMEWORK_TABS, (value) => value === tabName) && tab === TABS_TYPES.FRAMEWORK
    )

    this.setState((prevState) => ({
      activeTab: isFrameworkPage ? tabName : tab,
      downloadedTabsData: {
        ...prevState.downloadedTabsData,
        [tab]: true,
      },
      initialized: true,
    }))

    const initialValues = formativeReportsSelectors.getPreviewInitialValues(
      formativeReport,
      assessmentPeriodsDropdown,
      terms,
      params,
    )

    initializeValues(_.merge(
      _.cloneDeep(initialValues),
      _.cloneDeep(formValues),
    ))
  }

  handleChangeTab = (tabName) => {
    const { formativeReportsActions, params } = this.props
    const { downloadedTabsData } = this.state
    const { formativeReportId } = params

    this.setState({
      activeTab: tabName,
    })

    _.each(TABS_TYPES, (TAB) => {
      const isFrameworkPage = (
        !_.find(NON_FRAMEWORK_TABS, (value) => value === tabName) && TAB === TABS_TYPES.FRAMEWORK
      )

      if ((tabName === TAB || isFrameworkPage) && !downloadedTabsData[TAB]) {
        if (TAB === TABS_TYPES.MONTESSORI) {
          const criteria = [{
            field: 'report',
            value: +formativeReportId,
          }]

          formativeReportsActions.getFormativeReportMontessoriItems({
            onSuccess: () => this.handleChangeTabSuccess(TAB, tabName),
            params: [{
              criteria,
              groups: FORMATIVE_REPORT_MONTESSORI_ITEMS_GROUPS,
              limit: FORMATIVE_REPORT_ITEMS_LIMIT,
            }],
          })
        } else {
          this.getFormativeReport(
            TABS_GROUPS[TAB],
            () => setTimeout(() => { this.handleChangeTabSuccess(TAB, tabName) }, 300),
          )
        }
      }
    })
  }

  handleSendClick = () => {
    const { formativeReport, modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.FORMATIVE_REPORTS_SEND, {
      report: formativeReport,
    })
  }

  getFilteredFrameworksListByFormativeReportVersion = () => {
    const { formativeReport, frameworksList } = this.props
    const { version } = formativeReport || {}

    if (LEGACY_FORMATIVE_REPORT_VERSION === version) {
      return _.filter(frameworksList, ({ structure }) => structure.formativeReportLegacyFramework)
    }

    return frameworksList
  }

  getFrameworkItems = (frameworkDetails) => {
    const { formativeReportsActions, params } = this.props
    const { downloadedFormativeReportItems } = this.state
    const { formativeReportId } = params
    const { structure } = frameworkDetails
    const { formativeReportGrading, formativeReportItems } = structure

    const criteria = [
      {
        field: 'frameworkItem.category.group.area.framework',
        value: frameworkDetails.id,
      },
      {
        field: 'report',
        value: +formativeReportId,
      },
    ]

    if (
      !downloadedFormativeReportItems[frameworkDetails.id]
      && (formativeReportItems || !formativeReportGrading)
    ) {
      formativeReportsActions.getFormativeReportItems({
        mergeResult: true,
        onSuccess: () => {
          this.setState((prevState) => ({
            downloadedFormativeReportItems: {
              ...prevState.downloadedFormativeReportItems,
              [frameworkDetails.id]: true,
            },
          }))
        },
        params: [{
          criteria,
          groups: FORMATIVE_REPORT_ITEMS_GROUPS,
          limit: FORMATIVE_REPORT_ITEMS_LIMIT,
        }],
      })
    }
  }

  getFrameworkDetails = (framework, onSuccess) => {
    const { frameworksActions } = this.props
    const { frameworkDetailsCache } = this.state

    if (frameworkDetailsCache[framework]) {
      this.getFrameworkItems(frameworkDetailsCache[framework])

      return onSuccess(
        frameworkDetailsCache[framework],
      )
    }

    return frameworksActions.get({
      onSuccess: (response) => {
        const { data } = response
        this.getFrameworkItems(data)

        this.setState((prevValue) => ({
          frameworkDetailsCache: {
            ...prevValue.frameworkDetailsCache,
            [framework]: data,
          },
        }))

        onSuccess(data)
      },
      onlyData: true,
      params: [framework, {
        groups: FRAMEWORK_ITEM_GROUPS,
      }],
    })
  }

  getAgeBands = (framework, onSuccess) => {
    const { frameworksActions } = this.props
    const { ageBandsCache } = this.state

    if (ageBandsCache[framework]) {
      return onSuccess(
        ageBandsCache[framework],
      )
    }

    return frameworksActions.getAgeBands({
      onSuccess: (response) => {
        const { data } = response
        this.setState((prevValue) => ({
          ageBandsCache: {
            ...prevValue.ageBandsCache,
            [framework]: data,
          },
        }))

        onSuccess(data)
      },
      onlyData: true,
      params: [framework],
    })
  }

  handleSharedWithParentsClick = () => {
    this.setState((prevState) => ({ sharedWithParents: !prevState.sharedWithParents }))
  }

  render() {
    const {
      assessmentPeriodsDropdown,
      errorMessages,
      formValues,
      formativeReport,
      formativeReportsSelectors,
      isFetching,
      isFetchingFrameworksList,
      isFetchingNurserySettings,
      isGrantedForSendingToPendingApproval,
      isGrantedToApprove,
      isGrantedToEditFormativeReport,
      isMontessori,
      isSubmitting,
      nurseryLearningSettings,
      params,
      terms,
    } = this.props
    const { activeTab, downloadedTabsData, initialized, pageType, sharedWithParents } = this.state
    const {
      childOverviewSectionOnFormativeReportsVisible = true,
      useOnlySecureToMarkProgress,
    } = nurseryLearningSettings || {}

    const frameworksList = this.getFilteredFrameworksListByFormativeReportVersion()
    const initialValues = formativeReportsSelectors.getPreviewInitialValues(
      formativeReport,
      assessmentPeriodsDropdown,
      terms,
      params,
    )

    return (
      <FormativeReportsPreviewView
        activeTab={activeTab}
        assessmentPeriods={assessmentPeriodsDropdown}
        displayChildOverview={childOverviewSectionOnFormativeReportsVisible}
        downloadedTabsData={downloadedTabsData}
        errorMessages={errorMessages}
        formValues={formValues}
        formativeReport={formativeReport}
        frameworksList={frameworksList}
        initialValues={initialValues}
        initialized={initialized}
        isChildContext={pageType === FORMATIVE_REPORTS_PAGE_TYPE.CHILD}
        isFetching={isFetching}
        isFetchingFrameworksList={isFetchingFrameworksList}
        isFetchingNurserySettings={isFetchingNurserySettings}
        isGrantedForSendingToPendingApproval={isGrantedForSendingToPendingApproval}
        isGrantedToApprove={isGrantedToApprove}
        isGrantedToEditFormativeReport={isGrantedToEditFormativeReport}
        isMontessori={isMontessori}
        isSubmitting={isSubmitting}
        sharedWithParents={sharedWithParents}
        terms={terms}
        useOnlySecureToMarkProgress={useOnlySecureToMarkProgress}
        onAttainmentLevelsChange={this.handleAttainmentLevelsChange}
        onChangeStatus={this.handleChangeStatus}
        onChangeTab={this.handleChangeTab}
        onCommentsBlur={this.handleCommentsBlur}
        onCommentsChange={this.handleCommentsChange}
        onFormCommentsBlur={this.handleFormCommentsBlur}
        onFormCommentsChange={this.handleFormCommentsChange}
        onGetAgeBands={this.getAgeBands}
        onGetFrameworkDetails={this.getFrameworkDetails}
        onRemoveClick={this.handleRemoveClick}
        onRestoreOldValue={this.handleRestoreOldValue}
        onSave={this.handleSave}
        onSendClick={this.handleSendClick}
        onSharedWithParentsClick={this.handleSharedWithParentsClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  changeValue: (field, value) => change(CHILD_LEARNING_JOURNEY_FORMATIVE_REPORTS_PREVIEW_FORM, field, value),
  initializeValues: (values) => initialize(CHILD_LEARNING_JOURNEY_FORMATIVE_REPORTS_PREVIEW_FORM, values),
  injectValidation: (formName, errors = {}) => stopSubmit(formName, errors),
}

const mapState = (state, {
  appSelectors,
  formativeReportsSelectors,
  formativeReportsSingleState,
  frameworksListState,
  frameworksSelectors,
  nurseriesSelectors,
  nurseriesSingleState,
  params,
  securitySelectors,
}) => ({
  assessmentPeriodsDropdown: nurseriesSelectors.getAssessmentPeriodsDropdown(state),
  errorMessages: appSelectors.getErrorMessages(formativeReportsSingleState),
  formValues: getFormValues(CHILD_LEARNING_JOURNEY_FORMATIVE_REPORTS_PREVIEW_FORM)(state),
  formativeReport: formativeReportsSelectors.getFormativeReport(state),
  frameworksList: frameworksSelectors.getFilteredFrameworksRawList(
    FRAMEWORK_STRUCTURE_APPLICABLE.FORMATIVE_REPORT,
  )(state),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState, frameworksListState, formativeReportsSingleState),
  isFetchingFrameworksList: appSelectors.getIsFetching(frameworksListState),
  isFetchingNurserySettings: appSelectors.getIsFetching(nurseriesSingleState),
  isGrantedForSendingToPendingApproval: formativeReportsSelectors.isGrantedForSendingToPendingApproval(state),
  isGrantedToApprove: formativeReportsSelectors.isGrantedToApprove(state),
  isGrantedToEditFormativeReport: formativeReportsSelectors.isGrantedToEditFormativeReport(state),
  isMontessori: securitySelectors.isMontessori(state),
  isSubmitting: appSelectors.getIsSubmitting(formativeReportsSingleState),
  nurseryLearningSettings: nurseriesSelectors.getNurseryLearningSettings(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  terms: formativeReportsSelectors.getTermsDropdown(state),
})

const enhance = compose(
  withAppService,
  withFrameworksService,
  withFormativeReportsService,
  withModalService,
  withNurseriesService,
  withRouter,
  withSecurityService,
  withSnackbarService,
  connect(mapState, mapDispatch),
)

export default enhance(FormativeReportsPreviewContainer)
