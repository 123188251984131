import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { Form } from 'components'

import i18n from 'translations'

export const CREDENTIALS_FORM = 'CredentialsForm'

const CredentialsForm = ({
  handleSubmit,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Row label={i18n.t('module:StaffRegister:Credentials:username')} required verticalLabel>
      <Form.Row.FlexItem>
        <Field
          component={Form.TextField}
          name="username"
          placeholder={i18n.t('module:StaffRegister:Credentials:username')}
          validate={isRequired}
        />
      </Form.Row.FlexItem>
    </Form.Row>
    <Form.Row label={i18n.t('module:StaffRegister:Credentials:password')} required verticalLabel>
      <Form.Row.FlexItem>
        <Field
          component={Form.TextField}
          name="password"
          placeholder={i18n.t('module:StaffRegister:Credentials:password')}
          type="password"
          validate={isRequired}
        />
      </Form.Row.FlexItem>
    </Form.Row>
  </Form>
)

export default reduxForm({ form: CREDENTIALS_FORM })(CredentialsForm)
