import i18n from 'translations'

import TodayAllergiesWidget from './TodayAllergiesWidgetContainer'

export const WIDGET_NAME = {
  component: TodayAllergiesWidget,
  id: 'TODAYS_ALLERGIES_WIDGET',
  label: i18n.t('module:Dashboard:Widgets:TodayAllergiesWidget:titleInSettings'),
}

export default TodayAllergiesWidget
