import React from 'react'

import { StyledContainer } from './GridLayoutStyled'

/**
* @deprecated Please use "Grid" component instead
*/
const GridLayout = ({ children }) => (
  <StyledContainer>
    {children}
  </StyledContainer>
)

export default GridLayout
