import _ from 'lodash'

import React, { useCallback } from 'react'
import { Field, reduxForm } from 'redux-form'

import { ACTIVITY_TYPE } from 'services/legacy/enquiries/activities/constants'
import { VIEWING_STATUS_OPTIONS } from 'services/legacy/enquiries/viewings/constants'

import { isRequired } from 'utils/fieldValidation'

import { isActivityViewingType } from 'services/legacy/enquiries/activities/selectors'

import { Button, Form } from 'components'

import i18n from 'translations'

import {
  StyledFormContainer,
  StyledFormFieldContainer,
  StyledFormFooter,
  StyledRemoveButtonContainer,
} from './ActivityDetailAddFormStyled'

const ActivityAddForm = ({
  formValues,
  handleSubmit,
  isInlineEditMode,
  isSubmitting,
  notePlaceholder,
  onCancelClick,
  onDeleteClick,
  showDelete,
  submitButtonLabel,
  type,
}) => {
  const renderFormFields = useCallback(() => (
    <React.Fragment>
      <Form.Row
        label={_.upperFirst(i18n.t('global:date'))}
        margin="0"
        verticalLabel
      >
        <Form.Row.Item>
          <Field
            component={Form.DatePicker}
            disabled={isSubmitting}
            name="activityDate"
            validate={isRequired}
            visualType="transparent"
          />
        </Form.Row.Item>
      </Form.Row>
      <Form.Row
        label={_.upperFirst(i18n.t('global:Time'))}
        margin="0"
        verticalLabel
      >
        <Form.Row.Item>
          <Field
            component={Form.TimePicker}
            disabled={isSubmitting}
            name="activityTime"
            validate={isRequired}
            visualType="transparent"
          />
        </Form.Row.Item>
      </Form.Row>
    </React.Fragment>
  ), [isSubmitting])

  const renderViewingsFormFields = useCallback(() => (
    <React.Fragment>
      <Form.Row
        label={i18n.t('module:Enquiries:List:Add:ViewingInformation:ViewingDate:label')}
        margin="0"
        verticalLabel
      >
        <Form.Row.Item>
          <Field
            component={Form.DatePicker}
            disabled={isSubmitting}
            name="showAroundDate"
            validate={isRequired}
            visualType="transparent"
          />
        </Form.Row.Item>
      </Form.Row>
      <Form.Row
        label={_.upperFirst(i18n.t('global:Time'))}
        margin="0"
        verticalLabel
      >
        <Form.Row.Item>
          <Field
            component={Form.TimePicker}
            disabled={isSubmitting}
            name="showAroundTime"
            validate={isRequired}
            visualType="transparent"
          />
        </Form.Row.Item>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Enquiries:List:Add:ViewingInformation:Staff:label')}
        margin="0"
        verticalLabel
      >
        <Form.Row.Item>
          <Field
            clearable={false}
            component={Form.InfiniteDropdowns.Memberships}
            name="assignedPerson"
            placeholder={i18n.t('module:Enquiries:List:Add:ViewingInformation:Staff:placeholder')}
            visualType="transparent"
            searchable
          />
        </Form.Row.Item>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Enquiries:List:Add:ViewingInformation:Status:label')}
        margin="0"
        verticalLabel
      >
        <Form.Row.Item>
          <Field
            clearable={false}
            component={Form.Select}
            name="status"
            options={VIEWING_STATUS_OPTIONS}
            placeholder={i18n.t('module:Enquiries:List:Add:ViewingInformation:Status:placeholder')}
            validate={isRequired}
            visualType="transparent"
          />
        </Form.Row.Item>
      </Form.Row>
      {showDelete && (
        <StyledRemoveButtonContainer>
          <Button
            color="error"
            hierarchy="tertiary"
            icon="trash"
            size="large"
            onClick={onDeleteClick}
          />
        </StyledRemoveButtonContainer>
      )}
    </React.Fragment>
  ), [isSubmitting, showDelete, onDeleteClick])

  const isViewingType = isActivityViewingType(type)

  return (
    <StyledFormContainer>
      <Form onSubmit={handleSubmit}>
        <StyledFormFieldContainer isInlineEditMode={isInlineEditMode} showDelete={showDelete}>
          {ACTIVITY_TYPE.VIEWING.value !== type
            ? renderFormFields()
            : renderViewingsFormFields()}
        </StyledFormFieldContainer>
        <Field
          component={Form.TextAreaField}
          disabled={isSubmitting}
          height={100}
          name="note"
          padding="10px 20px"
          placeholder={notePlaceholder}
          validate={ACTIVITY_TYPE.VIEWING.value !== type && isRequired}
        />
        <StyledFormFooter isInlineEditMode={isInlineEditMode}>
          {isInlineEditMode && (
            <Button
              disabled={isSubmitting}
              hierarchy="tertiary"
              label={i18n.t('global:Cancel')}
              size="small"
              onClick={onCancelClick}
            />
          )}
          <Button
            disabled={isViewingType
              ? !(formValues?.showAroundDate && formValues?.showAroundTime && formValues?.status)
              : !(formValues?.activityDate && formValues?.activityTime && formValues?.note)}
            isLoading={isSubmitting}
            label={submitButtonLabel}
            size="small"
            submit
          />
        </StyledFormFooter>
      </Form>
    </StyledFormContainer>
  )
}

export default reduxForm()(ActivityAddForm)
