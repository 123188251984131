import { combineReducers } from 'redux'

import files from './files/reducer'
import list from './list/reducer'
import single from './single/reducer'
import statistics from './statistics/reducer'
import rotaStatistics from './rotaStatistics/reducer'
import publicNursery from './public/reducer'

export default combineReducers({
  files,
  list,
  publicNursery,
  rotaStatistics,
  single,
  statistics,
})
