import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { CriterionV2 } from 'constants/service'

import { MilkReportCriteriaParamProps, MilkReportRootState } from '../models'

const getMilkReportState = (state: MilkReportRootState) => state?.milkReport

export const getMilkReportList = createSelector([getMilkReportState], (state) => state?.list?.data)

export const getTotalChildren = createSelector([getMilkReportList], (data) => data?.total)

export const getTotalCost = createSelector([getMilkReportList], (data) => data?.totalCost)

export const getTotalMilk = createSelector([getMilkReportList], (data) => data?.totalMilk)

export const getEntries = createSelector([getMilkReportList], (data) => data?.entries)

export const getCriteria = ({ date, unitPrice, unitsPerChild }: MilkReportCriteriaParamProps): CriterionV2[] => {
  const criteria = []

  if (date) {
    criteria.push({
      comparator: 'after',
      field: 'entryDate',
      value: date.format(DEFAULT_DATE_FORMAT),
    })
  }

  if (null !== unitPrice && undefined !== unitPrice) {
    criteria.push({
      field: 'unitPrice',
      value: unitPrice,
    })
  }

  if (null !== unitsPerChild && undefined !== unitsPerChild) {
    criteria.push({
      field: 'unitsPerChild',
      value: unitsPerChild,
    })
  }

  return criteria
}
