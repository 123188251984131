import React from 'react'
import { Link } from 'react-router'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import ImagesSize from 'constants/imagesSize'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'
import { generateRoute } from 'utils/routing'
import { getOptimizedImage } from 'utils/image'
import auth from 'utils/auth'

import { withRouter } from 'services/router'

import { Avatar, Button, Hexagon, Icon, Typography } from 'components'
import MessagingWrapper from 'module/Messaging/MessagingWrapperContainer'
import NotificationWrapper from 'module/Notification/NotificationWrapperContainer'

import i18n from 'translations'

import LogoutWithHelp from '../LogoutWithHelp'
import MessagingRoot from '../MessagingRoot'
import UploadRoot from '../UploadRoot'
import NotificationRoot from '../NotificationRoot'
import {
  StyledBackButton,
  StyledByBlossomContainer,
  StyledHexagon,
  StyledLeftColumn,
  StyledLogoContainer,
  StyledMiddleColumn,
  StyledMobileMenu,
  StyledProfile,
  StyledRightColumn,
  StyledTitle,
  StyledTopBar,
} from './TopBarStyled'

const TopBar = ({
  authUser,
  backButtonActive,
  backToPayments,
  dashboardIcon,
  dashboardTitle,
  hideProfile,
  isOffline,
  isBranded,
  location,
  logo,
  logoutLabel = i18n.t('global:Logout'),
  logoutWithHelp,
  isMessagingEnabled,
  messagingAccessGranted,
  sidebarIsOpen,
  notificationAccessGranted,
  title,
  onBackButton,
  onChangeSidebar,
  onHelpClick,
  onLogout,
}) => {
  const isMessagingPage = () => {
    const { pathname } = location

    return -1 < pathname?.indexOf('/messages')
  }

  const isNotificationPage = () => {
    const { pathname } = location

    return -1 < pathname?.indexOf('notifications')
  }

  const renderBackButton = () => {
    if (!backButtonActive || isOffline) {
      return null
    }

    return (
      <StyledBackButton onClick={!isOffline && onBackButton}>
        <Icon
          color={NEUTRAL_COLOURS.WHITE}
          icon="chevron-left"
        />
      </StyledBackButton>
    )
  }

  const renderLogo = () => {
    if (!logo) {
      return null
    }

    return (
      <StyledLogoContainer>
        <LazyLoadImage
          alt="nursery-logo"
          effect="opacity"
          height={ImagesSize.NURSERY_LOGO.height}
          src={getOptimizedImage(logo, ImagesSize.NURSERY_LOGO)}
          width={ImagesSize.NURSERY_LOGO.width}
        />
      </StyledLogoContainer>
    )
  }

  const renderHexagon = () => (
    <StyledHexagon>
      <Link to={isOffline ? '' : '/'}>
        <Hexagon>
          <img
            alt={dashboardTitle}
            src={dashboardIcon}
          />
        </Hexagon>
      </Link>
    </StyledHexagon>
  )

  const renderByBlossomLogo = () => {
    if (!isBranded) {
      return null
    }

    return (
      <StyledByBlossomContainer>
        <img
          alt="By Blossom Educational"
          src="/images/by-blossom-white.svg"
        />
      </StyledByBlossomContainer>
    )
  }

  const renderProfile = () => {
    if (!authUser || hideProfile) {
      return null
    }

    const { fullName, name, photo } = authUser

    return (
      <StyledProfile>
        <Avatar
          avatarSize="medium"
          initials={name}
          src={photo}
          subTitle={(
            <Typography
              color={NEUTRAL_COLOURS.WHITE}
              fontSize={12}
              bold
              ellipsis
            >
              Hi there!
            </Typography>
          )}
          title={(
            <Typography
              color={NEUTRAL_COLOURS.WHITE}
              fontSize={14}
            >
              {fullName}
            </Typography>
          )}
          to={!isOffline && generateRoute('ACCOUNT.INDEX')}
          subTitleAbove
        />
      </StyledProfile>
    )
  }

  const renderMobileMenuButton = () => (
    <StyledMobileMenu
      sidebarIsOpen={sidebarIsOpen}
      onClick={() => onChangeSidebar(!sidebarIsOpen)}
    >
      <Icon
        color={sidebarIsOpen ? getBrandingColor('senary-color') : NEUTRAL_COLOURS.WHITE}
        height={22}
        icon="menu"
      />
    </StyledMobileMenu>
  )

  const renderLeftColumn = () => (
    <StyledLeftColumn>
      {renderBackButton()}
      {renderHexagon()}
      {renderLogo()}
      {renderProfile()}
    </StyledLeftColumn>
  )

  const renderMiddleColumn = () => (
    <StyledMiddleColumn>
      <StyledTitle>
        {title}
      </StyledTitle>
    </StyledMiddleColumn>
  )

  const renderRightColumn = () => {
    if (logoutWithHelp) {
      return (
        <StyledRightColumn>
          {renderByBlossomLogo()}
          <UploadRoot />
          {messagingAccessGranted && isMessagingEnabled && !isMessagingPage() && (
            <MessagingRoot isOffline={isOffline} />
          )}
          {notificationAccessGranted && !isNotificationPage() && (
            <NotificationRoot isOffline={isOffline} />
          )}
          <LogoutWithHelp
            authUser={authUser}
            isOffline={isOffline}
            logoutLabel={logoutLabel}
            hiddenOnMobile
            onHelpClick={onHelpClick}
            onLogout={onLogout}
          />
          {renderMobileMenuButton()}
        </StyledRightColumn>
      )
    }

    if (backToPayments) {
      return (
        <StyledRightColumn>
          <Button
            hierarchy="secondary"
            label={i18n.t('module:Modals:PaymentsImport:backToPayments')}
            to={generateRoute('FINANCE.PAYMENTS')}
            inverted
          />
        </StyledRightColumn>
      )
    }

    return null
  }

  return (
    <StyledTopBar>
      {renderLeftColumn()}
      {renderMiddleColumn()}
      {renderRightColumn()}
    </StyledTopBar>
  )
}

const mapState = (state) => ({
  messagingAccessGranted: auth.SELECTORS.getComponentIsAuthorised(state, MessagingWrapper),
  notificationAccessGranted: auth.SELECTORS.getComponentIsAuthorised(state, NotificationWrapper),
})

const enhance = compose(
  withRouter,
  connect(mapState),
)

export default enhance(TopBar)
